var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("MailBriefVorlage", {
        attrs: { prependActions: _vm.headerActions },
        on: {
          "action-SAVE": function($event) {
            return _vm.saveTemplates()
          },
          "action-REFRESH": function($event) {
            return _vm.onRefresh()
          }
        }
      }),
      _vm.loadingTemplate || !_vm.id
        ? _c("div", { staticClass: "box__container" }, [
            _c(
              "div",
              [
                [
                  _vm.templateData || !_vm.id
                    ? _c(
                        "div",
                        [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col col-md-6" },
                              [
                                _c("ComboBox", {
                                  attrs: {
                                    label: "Freigabe",
                                    values: _vm.grantValues
                                  },
                                  model: {
                                    value: _vm.grant,
                                    callback: function($$v) {
                                      _vm.grant = $$v
                                    },
                                    expression: "grant"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col col-md-6" },
                              [
                                _c("InputField", {
                                  attrs: { label: "Bezeichnung" },
                                  model: {
                                    value: _vm.subject,
                                    callback: function($$v) {
                                      _vm.subject = $$v
                                    },
                                    expression: "subject"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col col-md-6" },
                              [
                                _c("InputField", {
                                  attrs: { label: "Bemerkung" },
                                  model: {
                                    value: _vm.subjectOverwrite,
                                    callback: function($$v) {
                                      _vm.subjectOverwrite = $$v
                                    },
                                    expression: "subjectOverwrite"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _c("div", [
                            _vm.id && _vm.currentFilename
                              ? _c(
                                  "div",
                                  { staticClass: "col col-md-6" },
                                  [
                                    _c("InputField", {
                                      attrs: {
                                        label: "aktueller Dateiname",
                                        disabled: true
                                      },
                                      model: {
                                        value: _vm.currentFilename,
                                        callback: function($$v) {
                                          _vm.currentFilename = $$v
                                        },
                                        expression: "currentFilename"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]),
                          _c(
                            "DragnDropArea",
                            {
                              attrs: { hoverText: "Datei hier ablegen" },
                              on: {
                                files: function($event) {
                                  return _vm.addDocument($event)
                                }
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "d-block justify-content-between"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex d-md-block mt-1 layout__negative-margin--8 antrag-action-button__container justify-content-center"
                                    },
                                    [
                                      _c(
                                        "BaseButton",
                                        {
                                          staticClass: "upload-btn",
                                          attrs: { isSecondary: "" }
                                        },
                                        [
                                          _vm._t("default", function() {
                                            return [_vm._v("Datei hochladen *")]
                                          }),
                                          _c("input", {
                                            attrs: {
                                              type: "file",
                                              accept: _vm.acceptFileTypes
                                            },
                                            on: { change: _vm.onAddFiles }
                                          })
                                        ],
                                        2
                                      ),
                                      _c("div", { staticClass: "mt-2" }, [
                                        _vm._v(_vm._s(_vm.fileName))
                                      ]),
                                      _c("div", { staticClass: "text-small" }, [
                                        _vm._v(
                                          " * Bitte beachten Sie, dass nur Dateien vom Typ .doc akzeptiert werden. "
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]
              ],
              2
            )
          ])
        : _c("GhostLoading", { attrs: { type: "block" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }