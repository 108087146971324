var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Vermögensverwaltungen",
          subtitle: "Strategien",
          defaultMenu: _vm.$appNavigation.currentTabMenu,
          actions: _vm.headerActions
        },
        on: {
          "action-NEW": function($event) {
            return _vm.openNewStrategieModal()
          }
        }
      }),
      _c(
        "BaseFilter",
        {
          ref: "baseFilter",
          attrs: {
            filterId: "searchVVStrategien",
            title:
              "Hier finden Sie die Vermögensverwaltungen, die Ihnen zur Verfügung stehen",
            showSaveButton: "",
            hasSmartSearch: "",
            defaultOptions: _vm.defaultOptions,
            metadata: _vm.searchMenu,
            configFilter: _vm.configFilter,
            predefinedFilter: null
          },
          on: {
            onFilter: function($event) {
              return _vm.handleSearch($event)
            },
            onFilterZurucksetzen: function($event) {
              return _vm.$emit("onFilterZurucksetzen")
            }
          }
        },
        [_vm._t("default")],
        2
      ),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.rows && _vm.rows.length
            ? _c("Table", {
                attrs: {
                  tableId: "9734b8b7-ac53-4017-b77c-4c84e6c651be",
                  title: _vm.TABLE_TITLE,
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowsPerPage: 100,
                  rowId: "schemaId"
                },
                on: {
                  "action-factsheet": _vm.openGenerierteFactsheet,
                  "action-kag": _vm.openHochgeladeneFactsheet,
                  "action-delete": _vm.deleteStrategie,
                  "action-open": _vm.openStrategie,
                  "click-name": _vm.openStrategie
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "isZielmarktVerlassen",
                      fn: function(row) {
                        return [
                          row.isZielmarktVerlassen === "1"
                            ? _c(
                                "div",
                                [
                                  _c("PhWarning", {
                                    directives: [
                                      {
                                        name: "fc-tooltip",
                                        rawName: "v-fc-tooltip",
                                        value:
                                          "Zielmarktdaten der zugelassenen Variante sind überschritten",
                                        expression:
                                          "\n              'Zielmarktdaten der zugelassenen Variante sind überschritten'\n            "
                                      }
                                    ],
                                    staticStyle: { color: "orangered" },
                                    attrs: { size: 24 }
                                  })
                                ],
                                1
                              )
                            : _c("div")
                        ]
                      }
                    },
                    {
                      key: "stati",
                      fn: function(row) {
                        return [
                          row.stati && row.stati.length
                            ? _c(
                                "div",
                                _vm._l(row.stati, function(stat, index) {
                                  return _c(
                                    "div",
                                    { key: index },
                                    [
                                      _c("Pill", {
                                        attrs: {
                                          label: stat.label,
                                          type: stat.value
                                        }
                                      })
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            : _c("div")
                        ]
                      }
                    },
                    {
                      key: "wpVerteilung",
                      fn: function(row) {
                        return [
                          _c("BaseCollapsable", {
                            attrs: { showSection: _vm.showExpanded[row.index] },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "title",
                                  fn: function() {
                                    return [
                                      _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              row.wpVerteilung &&
                                                row.wpVerteilung.length
                                                ? row.wpVerteilung[0]
                                                : ""
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]
                                  },
                                  proxy: true
                                },
                                {
                                  key: "content",
                                  fn: function() {
                                    return _vm._l(
                                      row.wpVerteilung &&
                                        row.wpVerteilung.length > 1
                                        ? row.wpVerteilung.slice(1)
                                        : [],
                                      function(strategie, index) {
                                        return _c("div", { key: index }, [
                                          _c("div", [_vm._v(_vm._s(strategie))])
                                        ])
                                      }
                                    )
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  393977793
                )
              })
            : _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", title: _vm.TABLE_TITLE }
              })
            : _c("NoData", { attrs: { title: _vm.TABLE_TITLE } })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "newVVStrategieModal",
          attrs: {
            labelButtonConfirm: "Strategie anlegen",
            showCancelButton: true,
            size: "sm",
            modalTitle: "Strategie anlegen"
          },
          on: { onConfirmButton: _vm.createNewStrategie }
        },
        [
          _c("InputField", {
            attrs: { label: "Name", type: "text" },
            model: {
              value: _vm.newVVStrategieName,
              callback: function($$v) {
                _vm.newVVStrategieName = $$v
              },
              expression: "newVVStrategieName"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }