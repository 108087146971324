<template>
  <BaseModal
    ref="modal"
    size="lg"
    :showDefaultButtons="false"
    @close="onClose"
  >
    <template #modalTitle>
      <div class="clearfix">Externe Zugänge anpassen</div>
    </template>
    <template #default>
      <Table v-if="!loading && internList.length"
        :headers="headers"
        :rows="internList"
        rowId="label"
        hidePagination
        :rowsPerPage="internList.length"
      >
        <template v-for="userLevel in userLevels" v-slot:[userLevel]="row">
          <div :key="userLevel" class="table-input--centered">
            <InputToggleSwitch v-model="row.config[userLevel]" :disabled="isRowConfigDisabled(userLevel, row)" @input="setSubLevel(row, userLevel, $event)" />
          </div>
        </template>
      </Table>
      <GhostLoading v-else-if="loading" type="table" />
      <NoData v-else noIcon />
    </template>
    <template #footer>
      <BaseButton isSecondary @click="onRestoreDefault(); close();" class="mr-3">Zurücksetzen</BaseButton>
      <BaseButton @click="onSaveConfig(); close();">Schließen</BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { mapGetters } from 'vuex';
import FC_CONFIG_TYPES from '@/store/fcConfig/types';
import CORE_TYPES from '@/store/core/types'

import BaseModal from '@/components/core/BaseModal.vue';
import Table from '@/components/table2/Table.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import BaseButton from '@/components/core/BaseButton.vue';

import { TextColumn, SlotColumn, } from "@/components/table2/table_util.js";
import FC_CONFIG, { FC_CONFIG_USER_LEVEL, } from '@/configs/fcConfig';
import { ROLES, } from '@/router/roles';

const MAP_USER_LEVEL_TO_TITLE = {
  [FC_CONFIG_USER_LEVEL.MAKLER]: 'Vermittler',
  [FC_CONFIG_USER_LEVEL.MAKLER_STRUKTUR]: 'Unterstruktur',
}

const MAP_SUB_USER_LEVEL = {
  [FC_CONFIG_USER_LEVEL.MAKLER]: [FC_CONFIG_USER_LEVEL.MAKLER_STRUKTUR],
}
const MAP_UPPER_USER_LEVEL = {
  [FC_CONFIG_USER_LEVEL.MAKLER_STRUKTUR]: FC_CONFIG_USER_LEVEL.MAKLER,
}

export default {
  props: {
    defaultList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      internList: [],
      loading: false,
      FC_CONFIG_USER_LEVEL,
    };
  },
  computed: {
    ...mapGetters({
      fcConfigByUserLevel: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_BY_USER_LEVEL,
      userLevelDefault: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_USER_LEVEL_DEFAULT,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
    isUserUnternr() {
      return this.hasRoles([ROLES.IS_USER_UNTERNR]);
    },
    userLevels() {
      const userLevels = [FC_CONFIG_USER_LEVEL.MAKLER];

      if(!this.isUserUnternr) {
        userLevels.push(FC_CONFIG_USER_LEVEL.MAKLER_STRUKTUR);
      }

      return userLevels;
    },
    headers() {
      return {
        lockedLeft: [
          TextColumn("company", "Gesellschaft"),
          TextColumn("label", "Bezeichnung", 80).addCellProps({ lineClamp: 4, }).makeAlwaysVisible(),
        ],
        center: [],
        lockedRight: [ 
          ...(this.userLevels.map(userLevel => SlotColumn(userLevel, MAP_USER_LEVEL_TO_TITLE?.[userLevel] || userLevel).makeAlwaysVisible())),
        ],
      };
    },
    listConfig() {
      return this.userLevels.reduce((acc, userLevel) => ({
        ...acc,
        [userLevel]: this.fcConfigByUserLevel(FC_CONFIG.EXTERNE_ZUGANG_BANK_CONFIG, FC_CONFIG.EXTERNE_ZUGANG_BANK_CONFIG, userLevel),
      }), {});
    },
    hasConfiguredList() {
      return Object.values(this.listConfig).some(config => !!config?.content);
    },
    configuredList() {
      if(!this.hasConfiguredList) return [];
      return Object.keys(this.listConfig).reduce((acc, userLevel) => {
        const config = this.listConfig?.[userLevel];
        return {
          ...acc,
          [userLevel]: (!config?.content ? null : this.assignItems(JSON.parse(config?.content))),
        };
      }, {});
    },
  },
  methods: {
    async init() {
      await this.findAllUserLevelConfig(true);
      this.configureInternList();
    },
    open() {
      this.$refs.modal?.open();
      this.init();
    },
    close() {
      this.$refs.modal?.close();
    },
    onClose() {
      this.internList = [];
      this.loading = false;
    },
    assignItems(configuredList) {
      return configuredList.map(configuredItem => {
        const defaultItem = this.defaultList.find(item => item.id == configuredItem.id && item.company == configuredItem.company);
        if(!defaultItem) return null;
        return {
          ...configuredItem,
          ...defaultItem,
        };
      }).filter(item => !!item);
    },
    async findAllUserLevelConfig(forceReload = false) {
      try {
        this.loading = true;

        const promises = this.userLevels.map(userLevel => {
          const payload = {
            configId: FC_CONFIG.EXTERNE_ZUGANG_BANK_CONFIG,
            configType: FC_CONFIG.EXTERNE_ZUGANG_BANK_CONFIG,
            userLevel,
            forceReload,
          };
    
          return this.$store.dispatch(FC_CONFIG_TYPES.ACTIONS.LOAD_FC_CONFIG_BY_USER_LEVEL, payload);
        });
        await Promise.all(promises);
      } finally {
        this.loading = false;
      }
    },
    configureInternList() {
      this.internList = JSON.parse(JSON.stringify([ ...this.defaultList, ])).map(item => {
        const config = this.userLevels?.reduce((acc, userLevel) => {
          let value = this.getConfigItemValue(userLevel, item).visible === false ? false : true;
          if(this.getUpperUserLevelConfigItemValue(userLevel, item) === false) {
            value = false;
          }

          return {
            ...acc,
            [userLevel]: value,
          }
        }, {});

        return {
          ...item,
          config,
        };
      });
    },
    getConfigItemValue(userLevel, item) {
      const { configuredList, } = this;
      return configuredList?.[userLevel]?.find(configured => configured.id == item.id && configured.company == item.company) || {};
    },
    getUpperUserLevelConfigItemValue(userLevel, item) {
      const upperUserLevel = MAP_UPPER_USER_LEVEL?.[userLevel];
      if(upperUserLevel && this.isUserLevelAllowed(upperUserLevel)) {
        const { configuredList, } = this;
        return configuredList?.[upperUserLevel]?.find(configured => configured.id == item.id && configured.company == item.company)?.visible;
      }
      return undefined;
    },
    isRowConfigDisabled(userLevel, row) {
      const upperUserLevel = MAP_UPPER_USER_LEVEL?.[userLevel];
      if(upperUserLevel && this.isUserLevelAllowed(upperUserLevel)) {
        return row?.config?.[upperUserLevel] === false;
      }
      return false;
    },
    setSubLevel(row, userLevel, value) {
      if(!value) {
        MAP_SUB_USER_LEVEL?.[userLevel]
          ?.filter(subUserLevel => this.isUserLevelAllowed(subUserLevel))
          ?.forEach(subUserLevel => this.$set(row.config, subUserLevel, false));
      }
    },
    isUserLevelAllowed(userLevel) {
      return !!this.userLevels?.includes(userLevel);
    },
    async onRestoreDefault() {
      await this.restoreDefault();

      this.$emit('onRestoreDefault');
    },
    async restoreDefault() {
      const promises = this.userLevels.map(userLevel => {
        const deletePayload = {
          configId: FC_CONFIG.EXTERNE_ZUGANG_BANK_CONFIG,
          configType: FC_CONFIG.EXTERNE_ZUGANG_BANK_CONFIG,
          userLevel,
        }

        return this.$store.dispatch(FC_CONFIG_TYPES.ACTIONS.DELETE_ONLY, deletePayload);
      });

      await Promise.all(promises);
    },
    async onSaveConfig() {
      await this.saveConfigEdited();

      this.$emit('onSave');
    },
    async saveConfigEdited() {
      const editedConfig = this.internList.reduce((acc, item) => {
        Object.keys(item.config).forEach(key => {
          acc[key] ??= [];
          acc[key].push({ 
            id: item.id,
            company: item.company,
            label: item.label,
            visible: item.config[key], 
          });
        });

        return acc;
      }, {});

      const promises = Object.keys(editedConfig)
        .map(userLevel => this.saveConfig(editedConfig[userLevel], userLevel));

      await Promise.all(promises);
    },
    async saveConfig(items, userLevel) {
      if(!items || !userLevel) {
        return;
      }

      const payload = {
        configId: FC_CONFIG.EXTERNE_ZUGANG_BANK_CONFIG,
        configType: FC_CONFIG.EXTERNE_ZUGANG_BANK_CONFIG,
        content: JSON.stringify(items),
        userLevel: userLevel,
      };

      await this.$store.dispatch(FC_CONFIG_TYPES.ACTIONS.SAVE_ONLY, payload);
    },
  },
  components: {
    BaseModal,
    Table,
    InputToggleSwitch,
    GhostLoading,
    NoData,
    BaseButton,
  },
}
</script>

<style lang="scss" scoped>
.table-input--centered {
  display: flex;
  justify-content: center;
  align-items: center;
}
.config-list {
  .config-item {
    display: flex;
    justify-content: space-between;

    .config-item--actions {
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: center;
      flex: 0 0 auto;
      margin: 0 6px 0 12px;
    }
  }
}
</style>
