import { mapGetters, } from 'vuex';
import FEE_TYPES from '@/store/fee/types';
import CORE_TYPES from '@/store/core/types';

export default {
  computed: {
    ...mapGetters({
      feeProperties: FEE_TYPES.GETTERS.FEE_PROPERTIES,
      feePropertiesEdited: FEE_TYPES.GETTERS.FEE_PROPERTIES_EDITED,
      feePropertiesCombos: FEE_TYPES.GETTERS.FEE_PROPERTIES_COMBOS,
      isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
      isFA: CORE_TYPES.GETTERS.IS_FA,
    }),
    istMargitLang() {
      return !!this.feePropertiesCombos?.istMargitLang;
    },
    istHonorarberater() {
      return !!this.feePropertiesCombos?.istHonorarberater;
    },
    istErstattungStruktur() {
      return !!this.feePropertiesCombos?.istErstattungStruktur;
    },
  },
  watch: {
    feeProperties: {
      handler() {
        this.setData('feePropertiesForm');
      },
      immediate: true,
    },
    feePropertiesEdited(newValue, oldVale) {
      if(oldVale && !newValue) {
        this.setData('feePropertiesForm');
      }
    },
  },
  methods: {
    setData(formName) {
      const form = this[formName] || {};
      Object.keys(form).forEach(key => {
        const feeProperties = this.feePropertiesEdited || this.feeProperties;
        this.$set(form, key, feeProperties[key]);
      });
    },
    dataChanged() {
      this.setDataChanged('feePropertiesForm');
    },
    dataChangedCombo(key, value) {
      const formName = 'feePropertiesForm';
      const form = this[formName] || {};
      this.$set(form, key, value);
      this.setDataChanged(formName);
    },
    setDataChanged(formName) {
      const form = this[formName] || {};
      this.$store.commit(FEE_TYPES.MUTATIONS.SET_FEE_PROPERTIES_EDITED, {
        ...this.feeProperties,
        ...this.feePropertiesEdited || {},
        ...form,
      });
    },
  },
}
