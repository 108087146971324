import { mapGetters } from 'vuex';
import WERTPAPIERE_ALLE_KUNDEN_TYPES from '@/store/wertpapiereAlleKunden/types';
import CORE_TYPES from '@/store/core/types';

export default {
  watch: {
    'paramModus': 'reset'
  },
  data() {
    return {
      betragModusValues: [ 
        {value: 'VALUE_BETRAG_MODUS_ALLE_ANTEILE', label: 'Alle Anteile'}, 
        {value: 'VALUE_BETRAG_MODUS_PROZENT', label: 'Prozent'}, 
        {value: 'VALUE_BETRAG_MODUS_EURO', label: 'Betrag in EUR'},]
    }
  },
  computed: {
    ...mapGetters({
        lagerstellen: WERTPAPIERE_ALLE_KUNDEN_TYPES.GETTERS.LAGERSTELLEN,
        gesellschaften: WERTPAPIERE_ALLE_KUNDEN_TYPES.GETTERS.GESELLSCHAFTEN,
        kategorien: WERTPAPIERE_ALLE_KUNDEN_TYPES.GETTERS.KATEGORIEN,
        wertpapiere: WERTPAPIERE_ALLE_KUNDEN_TYPES.GETTERS.WERTPAPIERE,
        dataStore: WERTPAPIERE_ALLE_KUNDEN_TYPES.GETTERS.DATA,
        positions: WERTPAPIERE_ALLE_KUNDEN_TYPES.GETTERS.POSITIONS,
        comboboxValues: WERTPAPIERE_ALLE_KUNDEN_TYPES.GETTERS.COMBOBOX_VALUES,
        tempFileIdStore: WERTPAPIERE_ALLE_KUNDEN_TYPES.GETTERS.TEMP_FILE_ID,
        antraegePdf: WERTPAPIERE_ALLE_KUNDEN_TYPES.GETTERS.ANTRAEGE_PDF,
        antraegeEmail: WERTPAPIERE_ALLE_KUNDEN_TYPES.GETTERS.ANTRAEGE_EMAIL,
        emailData: WERTPAPIERE_ALLE_KUNDEN_TYPES.GETTERS.EMAIL_DATA,
    }),
    data() {
      return this.dataStore || {};
    },
    dataHasChanged() {
      return this.data.dataHasChanged || false;
    },
    positionsChanged() {
      return this.data.positionsChanged || this.emailData?.dataHasChanged || false;
    },
    hasAntraege() {
      return (this.antraegeEmail?.lenght || this.antraegePdf?.length) && this.tempFileId;
    },
    isVerkauf() {
        return this.paramModus === 'verkauf';
    },
    paramModus() {
      return this.$route.params.type || '';
    },
    tempFileId() {
      return this.tempFileIdStore || '';
    },
    canOpenFondsfinder() {
      return this.data.TARGET_ISIN && (this.data.wertpapier && this.data.wertpapier !== 'ISIN_KEINE') || this.data.TARGET_KATEGORIE && this.data.kategorie
    },
    canListPositions() {
        return this.canOpenFondsfinder && (this.isVerkauf || this.data.tauschInIsin);
    },
    selectedPositionLabel() {
      let label;
      if (this.data.TARGET_ISIN) {
          label = `das Wertpapier ${this.data.wertpapier && this.data.wertpapier !== 'ISIN_KEINE' 
          && this.wertpapiere?.find(fond => fond.value === this.data.wertpapier)?.label || ''}`;
      } else if (this.data.TARGET_KATEGORIE) {
          label = `Wertpapiere ${this.data.kategorie || ''}`;
      }
      return `${this.isVerkauf ? 'Verkaufsanträge' : 'Tauschanträge'} für ${label}`;
    },
    hasEmailVersand() {
      return this.positions?.some(pos => pos?.aktiv && pos?.mailversand) || false;
    },
    hasActivePositions() {
      return this.positions?.some(pos => pos.aktiv);
    },
  },
  methods: {
    openFondsfinderWarningModal() {
      this.$confirmModal({
          title: "Es fehlen Angaben",
          message: `Bitte wählen Sie ${this.data.TARGET_ISIN ? 'ein Wertpapier' : 'die Kategorie'} aus`,
          labelButtonCancel: "Ok",
          showConfirmButton: false,
      });
    },
    updateStore(data = {}) {
      if (Object.keys(data)?.length) {
          this.$store.commit(WERTPAPIERE_ALLE_KUNDEN_TYPES.MUTATIONS.UPDATE_STORE, data);
      }
    },
    openCustomerNewTab(pos) {
      if (pos?.kundenNr) {
          this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { customerId: pos.kundenNr });
      }
    },
    reset() {
      this.isInit = true;
      this.$store.commit(WERTPAPIERE_ALLE_KUNDEN_TYPES.MUTATIONS.RESET_STATE);
    },
  },
}
