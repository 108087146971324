<template>
  <div class="data_header_container">
    <div class="data_header_wrapper">
      <div class="telefon_email">
        <slot name="username">
          <div>{{maklerInfo.telefon}}</div>
          <div v-if="hasEmailAndTelefon" class="email_telefon_separator">|</div>
          <div>{{emailMakler}}</div>
        </slot>
      </div>
      <div class="links_alltogether">
        <slot name="right">
          <a :href="maklerInfo.mainHomepage" class="anmelden_link" v-if="hasMainHomepage">HOMEPAGE</a>
          <a :href="anmeldenUrl" class="anmelden_link">ANMELDEN</a>
          <div class="editable_social">
            <edit-social-media-src />
          </div>
          <div class="social_links_alltogether">

            <div class="social_link" v-for="social in socialMediaList" :key="social.src">
              <div @click="gotoSite(social.src)" class="social_link_icon">
                <component :is="social.icon" :size="18"/>
              </div>
            </div>
          </div>

        </slot>
      </div>
    </div>
  </div>
</template>
<script>
import EditSocialMediaSrc from "@/components/cms/EditSocialMediaSrc.vue";
import CORE_TYPES from "@/store/core/types";
import { mapGetters } from "vuex";
import { PhFacebookLogo, PhTwitterLogo, PhX, PhLinkedinLogo, PhInstagramLogo } from "phosphor-vue"

export default {
  components: {
    EditSocialMediaSrc,
  },
  computed: {
    ...mapGetters({
      maklerInfo: CORE_TYPES.GETTERS.GET_BROKER_INFORMATION,
      anmeldenUrl: CORE_TYPES.GETTERS.GET_ANMELDEN_URL,
    }),
    emailMakler() {
      if (this.maklerInfo && this.maklerInfo.emailAddress) {
        const email = this.maklerInfo.emailAddress;
        const emailSeparator = /[,; ]/

        if (email && email.search(emailSeparator) > -1) {
          return email.split(emailSeparator)[0];
        }

        return email;
      }
      return '';
    },
    hasEmailAndTelefon() {
      if (this.maklerInfo.emailAddress && this.maklerInfo.telefon) return true;
      else return false;
    },
    socialMediaSrc() {
      return this.maklerInfo && this.maklerInfo.socialMediaSrc
    },
    socialMediaList() {
      if (!this.socialMediaSrc) {
        return []
      }

      const result = [
        { icon: PhFacebookLogo, src: this.socialMediaSrc.facebookSrc },
        { icon: PhTwitterLogo, src: this.socialMediaSrc.twitterSrc },
        { icon: PhX, src: this.socialMediaSrc.xingSrc },
        { icon: PhLinkedinLogo, src: this.socialMediaSrc.linkedinSrc },
        { icon: PhInstagramLogo, src: this.socialMediaSrc.instagramSrc },
      ]

      return result.filter(item => item.src)
    },
    hasMainHomepage() {
      if (this.maklerInfo.mainHomepage) {
        return true;
      } else {
        return false;
      }
    }
  },

  methods: {
    gotoSite(src) {
      var win = window.open(src, '_blank');
      win.focus();
    },
  }
};
</script>

<style scoped>
.social_link_icon {
  padding: 0 0.3em;
  margin: auto;
  display: flex;
}

.data_header_wrapper {
  max-width: 1200px;
  display: grid;
  grid-template-columns: auto auto 40%;
  grid-template-areas: "telefon_email . links_alltogether";
  align-items: center;
  margin: 0 auto;
  padding-right: 1em;
  padding-left: 1em;
}
.email_telefon_separator {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.data_header_container {
  color: var(--color-top-nav-text);
  background: var(--color-top-nav-background);
  height: 49px;
  /* font-size: 0.85em;*/
}
.telefon_email {
  padding-top: 14px;
  padding-bottom: 14px;
  justify-self: left;
  grid-area: telefon_email;
  display: flex;
  align-items: center;
}

.links_alltogether {
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  justify-self: right;
  align-items: right;
  grid-area: links_alltogether;
}

.social_links_alltogether {
  display: flex;
}

.login_link {
  cursor: pointer;
}
.social_link {
  cursor: pointer;
  display: flex;
}
.editable_social {
  justify-content: initial;
}
.anmelden_link {
  color: var(--color-top-nav-text);
  text-decoration: none;
  padding: 9px 20px;
  outline: var(--color-top-nav-background) solid 1px;
}

@media only screen and (max-width: 640px) {
  .data_header_wrapper {
    display: flex;
    margin: 0 auto;
    padding-right: 1em;
    padding-left: 1em;
    justify-content: space-between;
  }
  .links_alltogether {
    justify-self: left;
    align-items: center;
  }
  .social_links_alltogether {
    display: none;
  }
}
@media only screen and (max-width: 640px) {
  .telefon_email {
    display: none;
  }
}
</style>