import EINGEBUNDENE_WERTPAPIERE_TYPES from './types';
import Vue from 'vue';

export default {
  [EINGEBUNDENE_WERTPAPIERE_TYPES.MUTATIONS.EINGEBUNDENE_WERTPAPIERE_EDIT](state, payload) {
    if(payload){
      state.eingebundeneWertpapiereEdit = payload;
    }
  },
  [EINGEBUNDENE_WERTPAPIERE_TYPES.MUTATIONS.CLEAN_EINGEBUNDENE_WERTPAPIERE_EDIT](state, payload) {
    if(payload){
      state.eingebundeneWertpapiereEdit = null;
    }
  },
  [EINGEBUNDENE_WERTPAPIERE_TYPES.MUTATIONS.DELETE_VERLAUF_ANTEILE_SUCCESS](state, response) {
    if(response.verlaufAnteileId) {
      const index = state.eingebundeneWertpapiereEdit?.verlaufAnteile?.findIndex(verlaufAnteile => verlaufAnteile.id === response.verlaufAnteileId);
      if (index > -1) {
        state.eingebundeneWertpapiereEdit?.verlaufAnteile?.splice(index, 1);
      }
    }
  },
  [EINGEBUNDENE_WERTPAPIERE_TYPES.MUTATIONS.SAVE_EINGEBUNDENE_WERTPAPIERE_SUCCESS](state, response) {
    if(response) {
      const index = state.versicherungenDetails?.insurance?.sparten?.findIndex(sparte => sparte.id === response.sparteId);
      if (index > -1) {
        const eingebundeneWertpapiereIndex = state.versicherungenDetails.insurance?.sparten[index]?.eingebundeneWertpapiere
        ?.findIndex(eingebundeneWertpapiere => eingebundeneWertpapiere.id === response.id);

        if (eingebundeneWertpapiereIndex > -1) {
          state.versicherungenDetails.insurance.sparten[index]?.eingebundeneWertpapiere?.splice(eingebundeneWertpapiereIndex, 1, response);
        }
      }
    }
  },
}
