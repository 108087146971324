var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Limits",
          subtitle: "Risikoklassenlimit",
          defaultMenu: _vm.hasRoles([_vm.VIEW_ROLES.VIEW_BROKER])
            ? []
            : _vm.$appNavigation.currentTabMenu,
          actions: _vm.actions
        },
        on: {
          "action-RESET": function($event) {
            return _vm.saveOrReset("reset")
          },
          "action-REMOVE": function($event) {
            return _vm.remove()
          }
        }
      }),
      _c("div", { staticClass: "box__container" }, [
        _c("div", { staticClass: "box__title" }, [
          _vm._v("Limit " + _vm._s(_vm.title) + " " + _vm._s(_vm.titleExtra))
        ]),
        _c("div", [
          _vm._v(
            " Das " +
              _vm._s(_vm.title) +
              "-Limit gibt Ihnen die Möglichkeit, das gewichtete Gesamtrisiko des Kundendepots ständig zu beobachten. Hat Ihr Kunde beispielsweise laut Anlegerprofil eine persönliche " +
              _vm._s(_vm.title) +
              " von 3 (auf der Skala von 1-" +
              _vm._s(_vm.maxValue) +
              "), so können Sie dies beim Ausgangswert eingeben. Mit dem Setzten eines Werts in den Feldern Abweichung nach oben und unten begrenzen Sie die Risikoschwankungsbreite, die das Gesamtdepot haben sollte. Wird durch Wertentwicklung, Kauf oder Zukauf dieser Bereich verlassen, erhalten Sie über das System eine Warnungs-Mail. "
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("div", { staticClass: "info-block" }, [
            !_vm.isCustomerOrigin
              ? _c("div", [_vm._v("Kundennr: " + _vm._s(_vm.limit.kundennr))])
              : _vm._e(),
            !_vm.isCustomerOrigin
              ? _c("div", [_vm._v("Kunde: " + _vm._s(_vm.limit.kundenname))])
              : _vm._e(),
            _c("div", [
              _vm._v(
                "Durchschnittliche " +
                  _vm._s(_vm.title) +
                  " des Depots: " +
                  _vm._s(_vm.limit.mittelwert)
              )
            ]),
            _c("div", [
              _vm._v(
                "Ausgangswert " +
                  _vm._s(_vm.title) +
                  ": " +
                  _vm._s(_vm.limit.ausgangswert)
              )
            ])
          ]),
          _c("InputField", {
            attrs: {
              label: "Abweichung nach unten",
              type: "number",
              precision: 1,
              isComponentHalfSize: ""
            },
            model: {
              value: _vm.form.abweichungLow,
              callback: function($$v) {
                _vm.$set(_vm.form, "abweichungLow", $$v)
              },
              expression: "form.abweichungLow"
            }
          }),
          _c("InputField", {
            attrs: {
              label: "Abweichung nach oben",
              precision: 1,
              type: "number",
              isComponentHalfSize: ""
            },
            model: {
              value: _vm.form.abweichungHigh,
              callback: function($$v) {
                _vm.$set(_vm.form, "abweichungHigh", $$v)
              },
              expression: "form.abweichungHigh"
            }
          }),
          _vm.limit.dateUpdated
            ? _c("div", [
                _vm._v("Letzte Änderung: " + _vm._s(_vm.limit.dateUpdated))
              ])
            : _vm._e(),
          _vm.limit.dateOutOfLimit
            ? _c("div", [
                _vm._v(
                  "Datum der Überschreitung: " +
                    _vm._s(_vm.limit.dateOutOfLimit)
                )
              ])
            : _vm._e(),
          !_vm.isCustomerOrigin
            ? _c("InputTextArea", {
                attrs: {
                  label: "Bemerkung (für Kunden nicht sichtbar)",
                  isComponentHalfSize: ""
                },
                model: {
                  value: _vm.form.bemerkung,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "bemerkung", $$v)
                  },
                  expression: "form.bemerkung"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c("LimitErrorModal", {
        ref: "modalError",
        attrs: { errorText: _vm.errorFromService },
        on: { closeLimit: _vm.confirmNextRoute }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }