var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.positionFields
    ? _c(
        "div",
        _vm._l(_vm.positionFields, function(component, index) {
          return _c("div", { key: index }, [
            !_vm.isComponentHidden(component)
              ? _c(
                  "div",
                  {
                    key: index + component.id,
                    staticClass: "mt-2",
                    class: {
                      indented: component.config && component.config.indented
                    }
                  },
                  [
                    _c("AntragComponent", {
                      attrs: {
                        component: component,
                        antragId: _vm.antragId,
                        data: _vm.positionData,
                        comboboxSelection: _vm.getComboboxSelection(component),
                        isComponentHalfSizeEnabled: "",
                        disabled: _vm.disabled
                      },
                      on: {
                        updateStore: function($event) {
                          return _vm.updatePositionData($event, component)
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }