<template>
  <div class="form-input-dok-node-permission-item__container checkbox-input__container">
    <div v-for="(val, index) in values" :key="`${val.path}-${index}`">
      <InputCheckBoxItem :id="val.path" :label="val.label" :value="value[val.path]" :disabled="disabled || val.disabled" @input="onInput($event, val)"/> 
      <template v-if="isValidationConfigured()">
        <div :key="validation.updated" v-if="!suppressValidationMessage && validation">
          <div class="input-forms__error-message" 
            v-for="error in validation.getErrors(getCheckboxErrorPath(val.path), validateUntouched)" 
            :key="error">
              {{ error }}
          </div>
        </div>
      </template>
      <FormInputDokNodePermissionItem 
        class="form-input-dok-node-permission-item--children" 
        :ref="`children_${val.path}`" 
        :values="val.children" :value="value" :disabled="disabled || isChildrenDisabled(val)" 
        @input="onChildrenInput($event)" />
    </div>
  </div>
</template>

<script>
import InputCheckBoxItem from '@/components/core/forms/checkbox/InputCheckBoxItem.vue'
import validatorComponentUtils from '@/mixins/validator/validator-component-utils'

import { getBooleanValue, isTrue, } from '@/helpers/mapping'

export default {
  name: 'FormInputDokNodePermissionItem',
  mixins: [validatorComponentUtils],
  props: {
    values: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    isChildrenDisabled(val) {
      return !isTrue(this.value?.[val.path]);
    },
    unmarkAll() {
      this.values.forEach(val => {
        if(isTrue(this.value?.[val.path])) {
          this.onInput(getBooleanValue(this.value[val.path], false), val)
        }
      });
    },
    onInput($event, val) {
      if(!isTrue($event)) {
        this.$refs?.['children_' + val.path]?.[0]?.unmarkAll();
      }

      this.$set(this.value, val.path, $event)
      this.$emit('input', { [val.path]: $event, })
      this.$runValidator($event)
    },
    onChildrenInput($event) {
      this.$emit('input', $event);
    },
  },
  components: {
    InputCheckBoxItem,
  },
}
</script>

<style scoped>
.form-input-dok-node-permission-item--children {
  margin: 8px 0 8px 28px;
}
</style>
