<template>
  <div>
    <BaseToolbarButton 
      title="Link"
      :icon="icon"
      :active="active"
      :disabled="disabled"
      class="mr-0"
      @click="action"
      content="Link"
      v-tippy="{ arrow: true }"/>

    <BaseToolbarLinkEditor 
      v-if="showLinkModal" 
      :linkForm="linkForm"
      @close="showLinkModal = false"
      @onConfirmButton="setLink"/>
  </div>
</template>

<script>
import { PhLink } from 'phosphor-vue';
import BaseToolbarButton from '../base-components/BaseToolbarButton.vue';
import BaseToolbarLinkEditor from '../base-components/BaseToolbarLinkEditor.vue';

export default {
  components: {
    BaseToolbarButton,
    BaseToolbarLinkEditor,
  },

  props: {
    editor: {
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      showLinkModal: false,
      linkForm: null,
    }
  },

  computed: {
    icon() {
      return PhLink;
    },
    active() {
      return this.editor?.isActive('link')
    },
  },

  methods: {
    setLink(linkForm) {
      const { url, linkText } = linkForm

      // cancelled
      if (url === null) {
        return
      }

      // empty
      if (url === '') {
        this.editor.chain()
          .focus()
          .extendMarkRange('link')
          .unsetLink()
          .run()

        return
      }

      if (linkText) {
        this.editor.chain()
          .focus()
          .extendMarkRange('link')
          .setLink({ href: url })
          .command(({ tr }) => {
            tr.insertText(linkText)
            return true
          })
          .run()
      } else {
        this.editor.chain()
          .focus()
          .extendMarkRange('link')
          .setLink({ href: url })
          .run()
      }
    },
    action() {
      const previousUrl = this.editor.getAttributes('link').href
      const currentSelectionIsEmpty = this.editor?.view.state.selection.empty

      this.linkForm = {
        previousUrl,
        url: previousUrl,
        currentSelectionIsEmpty
      }

      this.showLinkModal = true;
    },
  }

}
</script>
