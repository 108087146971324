<template>
  <BaseImage v-if="maklerLogo"
    fit="cover-vertically"
    :src="maklerLogo"
    :style="{'text-align': 'center'}"/>
  <UndrawSweetHome v-else/>
</template>

<script>
import MSC_NEWS_TYPES from '@/store/mscnews/types';
import { mapGetters } from 'vuex'
import BaseImage from '@/components/core/BaseImage.vue';
import UndrawSweetHome from '@/components/icons/undraw/UndrawSweetHome.vue';

export default {
  computed: {
    ...mapGetters({
      mscHomeLink: MSC_NEWS_TYPES.GETTERS.MSC_HOME_LINK,
    }),
    maklerLogo() {
      return this.mscHomeLink?.mscMaklerLogo?.trim?.()
    },
  },
  mounted() {
    this.$store.dispatch(MSC_NEWS_TYPES.ACTIONS.GET_MSC_HOME_LINK);
  },
  components: {
    BaseImage,
    UndrawSweetHome,
  }
}
</script>

