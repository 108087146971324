<template>
  <div v-if="searchingPositions">
    <div class="row">
      <div class="col-12">
        <h2>Wertpapiersuche</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <FondsFinderSuchMaske/>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <FondsFinderPositionsList @goBack="addFonds($event)" zurueckButtonText="Abbrechen" :addPositionsOnBack="false"/>
      </div>
    </div>
  </div>
  <div v-else class="input-forms__container">
    <div class="input-forms__label-container">
      <div class="input-forms__label-container">
      <div class="row mt-0">
        <div class="col">
          <div class="input-forms__label-content input-forms__label-content--bigger" v-if="label">
            <ph-bank :size="16" />&nbsp;{{label}}
          </div>
        </div>
         <div class="col-auto">
           <BaseButton @click="openFormsFinder" :disabled="this.disabled">Fond hinzufügen</BaseButton>
           <BaseButton :disabled="this.disabled" @click="searchingPositions=true">Wertpapiere hinzufügen</BaseButton>
         </div>
       </div>
      </div>
    </div>
    <div class="input-forms__input-container">
      <Table
        v-if="tableData && tableData.records && tableData.records.length && !this.disabled"
        :tableData="tableData"
        :cardViewEnabled="false"
        :filterEnabled="false"
        :exportEnabled="false"
        :paginationEnabled="false"
        :actions="columnActions"
        :rowsPerPage="tableData.records.length"
        @execute-action="handleTableAction($event)"
      >
      </Table>

  <DeletePositionModal ref="deleteModal" :position="positionToDelete"
    @delete="doDeletePosition()"></DeletePositionModal>

    </div>
  </div>
</template>

<script>
import ANTRAG_TYPES from '@/store/antrag/types';
import FONDSFINDER_TYPES from '@/store/fondsfinder/types';
import BaseButton from '@/components/core/BaseButton.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputField from '@/components/core/forms/InputField.vue';
import antragNavigationMixin from '@/mixins/antrag/antrag-navigation-mixin.js';
import BaseModal from '@/components/core/BaseModal.vue';
import Table from "@/components/table/Table.vue"
import DeletePositionModal from '@/components/core/forms/antraege/wertpapierAuswahl/DeletePositionModal.vue'
import { mapGetters } from 'vuex';
import antragMixin from '@/mixins/antrag/antrag-mixin.js';
import FondsFinderSuchMaske from '@/components/antrag/fondsfinder/FondsFinderSuchMaske.vue';
import FondsFinderPositionsList from '@/components/antrag/fondsfinder/FondsFinderPositionsList.vue';
import { PhBank } from 'phosphor-vue';


export default {
  mixins: [antragNavigationMixin, antragMixin],
  props: {
    label: {
    },
    antragData: {
    },
    config: {
    },
    antragTyp: {
    },
    antragId: {
    },
    aenderungTyp: {
      type: String,
      default: 'UNBEKANNT'
    },
    comboboxSelections: {
    },
    categoryId: {
    },
    suppressFilterLagerstelle: {
      type: Boolean,
      default: false
    },
    warnings: {
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BaseButton,
    BaseModal,
    ComboBox,
    InputField,
    Table,
    DeletePositionModal,
    FondsFinderSuchMaske,
    FondsFinderPositionsList,
    PhBank,
  },
  data() {
    return {
      columnActions : [
        {
          legend: {
            icon: 'PhTrash',
            index: 1,
            key: 'DELETE',
            label: 'Löschen',
          }
        }
      ],
      positionToDelete : {},
      searchingPositions: false,
    }
  },
  computed: {
    ...mapGetters({
      positionInfo: FONDSFINDER_TYPES.GETTERS.POSITION_INFO,
      positionsAll: ANTRAG_TYPES.GETTERS.POSITIONS,
      positionDataAll: ANTRAG_TYPES.GETTERS.POSITION_DATA,
      antraegeData: ANTRAG_TYPES.GETTERS.ANTRAEGE_DATA,
    }),
    componentData() {
      return this.antraegeData[this.antragId][this.component.id]
    },
    tableData() {
      const records = []
      if (this.antrag)
        for (let i = 1; i <= this.config.anzahlFonds; i++)
          if (this.positionData["isin" + i])
            records.push({
              ISIN: this.positionData["isin" + i],
              Wertpapiername: this.positionData["fondsname" + i],
              Anteile: this.positionData["anteile" + i],
              Gesamtbestand: this.positionData["alle" + i],
              index: i,
            })
      const result = {
        headers: {
          isin: {
            label: "ISIN",
            key: "isin",
            dataType: "String",
            visible: true,
            sum: false,
            fixed: true,
          },
          fondsName: {
            label: "Fondsname",
            key: "fondsName",
            dataType: "String",
            visible: true,
            sum: false,
            fixed: true,
          },
          actions: {
            label: "",
            key: "actions",
            priority: 1,
            dataType: "tableAction",
            visible: true,
            sortable: false,
            filterable: false,
            sum: false,
            fixed: true,
          }
        },
        //records: this.positions && this.positions.map((pos, index) => Object.assign(pos, {index: index, fondsName: this.getFondsName(pos, this.positionInfo), lagerstelle: 'CAPITALBANK'})),
      }
      return result;
    },
    positions() {
      if (this.positionsAll && this.antragId && this.categoryId 
          && this.positionsAll[this.antragId] 
          && this.positionsAll[this.antragId][this.categoryId]) {
        return this.positionsAll[this.antragId][this.categoryId]
      }
    },
    // positionData() {
    //   if (this.positionDataAll && this.antragId && this.categoryId 
    //       && this.positionDataAll[this.antragId]
    //       && this.positionDataAll[this.antragId][this.categoryId]) {
    //     return this.positionDataAll[this.antragId][this.categoryId]
    //   }
    // },
    comboOptions() {
      return [
        {
          value: 'MONATLICH',
          label: 'monatlich'
        },
        {
          value: 'VIERTELJ',
          label: 'quartalsweise'
        },
      ]
    },  
  },
  mounted() {
    this.fondIndex = this.$route.params.fondIndex
    this.categoryId = this.$route.params.categoryId
    this.step = this.$route.params.step
    this.positionData = {
      ...this.componentData[this.fondIndex]
    }
  },
  methods: {
    commitUpdatePositionsFromEvent(positionChange, isin) {
      const payload = {
        id: this.antragId,
        categoryId: this.categoryId,
        fonds: [
          { 
            ...positionChange,
            isin,
          }
        ]
      }
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_POSITIONS, payload)
    },
    onChangeInputField($event, position, field) { 
      const positionChange = { [field]: $event };
      this.commitUpdatePositionsFromEvent(positionChange, position.isin)
    },
    handleTableAction(event) {
      if(event.action?.legend?.key === 'DELETE') {
        this.openModalDeleteFond(event.value);
      }
    },
    openModalDeleteFond(fond) {
      this.positionToDelete = fond;
      this.$refs.deleteModal.open()
    },
    doDeletePosition() {
      this.deletePosition(this.antragId, this.categoryId, this.positionToDelete);
      this.positionToDelete = null;
    },
    openFormsFinder() {
      this.goToFormsFinder(this.categoryId, this.config, this.suppressFilterLagerstelle);
    },
    addFonds(fonds) {
      this.searchingPositions = false
      if (!fonds || fonds.length == 0)
        return
      // combine previously selected fonds with the new fonds
      const positionList = this.getPositions()
      fonds.forEach(fond => {
        positionList.push({
          isin: fond.isin,
          name: fond.wertpapiername,
          anteile: fond.stueckzahl,
        })
      })
      this.setPositions(positionList)
    },
    setPositions(positionList) {
      for (let i = 1; i <= this.config.anzahlFonds; i++) {
        Vue.set(this.positionData, "isin" + i, positionList.length >= i ? positionList[i-1].isin : null)
        Vue.set(this.positionData, "fondsname" + i, positionList.length >= i ? positionList[i-1].name : null)
        Vue.set(this.positionData, "lifecycle" + i, positionList.length >= i ? positionList[i-1].anteile : null)
      }
    },
    getPositions() {
      const positionList = []
      for (let i = 1; i <= this.config.anzahlFonds; i++)
        if (this.positionData["isin" + i])
          positionList.push({
            isin: this.positionData["isin" + i],
            name: this.positionData["fondsname" + i],
            lifecycle: this.positionData["lifecycle" + i],
          })
      return positionList
    },
  }
}
</script>

<style scoped>
  .input-forms__label-content--bigger {
    font-size: 1.5rem;
  }
  .input-forms__label-content--bigger > i {
    font-size: 2rem;
  }
</style>