<template>
  <BaseDropdownMenu placement="bottom-right" containerClass="global-search-help--dropdown" :closeWithInnerClick="false">
    <template #hook-target>
      <button type="button" class="btn-clear clickable"><PhQuestion :size="20" /></button>
    </template>
    <template #default>
      <div class="global-search-help--text">
        <template v-if="isCustomerOnly">
          <p>Hier haben Sie Zugriff auf viele Bereiche der FinanceCloud.</p>
        </template>
        <template v-else>
          <p>
            Hier haben Sie Zugriff auf viele Bereiche des smartMSC. Sie können z. B. nach {{ availableFeatures }} suchen.
          </p>
        </template>

        <div class="global-search-help--info text-small">
          <div class="global-search-help--info-icon">
            <PhInfo :size="18" />
          </div>
          <div class="global-search-help--info-text">
            Die Suche ist interaktiv und beginnt bereits nach wenigen Zeichen die verschiedenen Bereiche zu durchsuchen.
          </div>
        </div>
      </div>
    </template>
  </BaseDropdownMenu>
</template>

<script>
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';

import { PhQuestion, PhInfo } from 'phosphor-vue';
import BaseDropdownMenu from '@/components/core/BaseDropdownMenu.vue';

import { VIEW_ROLES } from '@/router/roles';

export default {
  name: 'GlobalSearchHelpDropdown',
  components: {
    PhQuestion,
    PhInfo,
    BaseDropdownMenu,
  },
  computed: {
    ...mapGetters({
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      hasAccess: CORE_TYPES.GETTERS.HAS_ACCESS,
    }),
    isCustomerOnly() {
      return this.hasRoles([VIEW_ROLES.VIEW_CUSTOMER_ONLY]);
    },
    isCompaniesEnabled() {
      return this.hasRoles([VIEW_ROLES.VIEW_BROKER, VIEW_ROLES.VIEW_INTERN]);
    },
    isFormulareEnabled() {
      return this.hasRoles([VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS]) && this.hasAccess('/beratung/formulare');
    },
    availableFeatures() {
      const { isCompaniesEnabled, isFormulareEnabled, } = this;

      // list
      const features = [
        'Namen',
        (isCompaniesEnabled ? 'Gesellschaften' : null),
        'Produkten',
        (isFormulareEnabled ? 'Formularen' : null),
      ].filter(feature => !!feature);

      // text
      const lastFeature = features.pop();
      return `${features.join(', ')} und ${lastFeature}`;
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .dropdown-menu--hook-target {
  display: flex;
}
.global-search-help--text {
  margin: 0;
  max-width: 314px;

  p {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
.global-search-help--info {
  display: flex;

  .global-search-help--info-icon {
    flex: 0 0 auto;
    margin: 0 .25rem 0 0;
  }
}
</style>

<!-- GLOBAL STYLE -->
<style>
.dropdown-menu__container.global-search-help--dropdown {
  margin-left: 16px;
}

@media screen and (max-width: 460px) {
  .dropdown-menu__container.global-search-help--dropdown.active::after {
    display: none;
  }
}
</style>
