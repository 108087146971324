var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: _vm.subtitle, actions: _vm.headerActions },
        on: {
          "action-BACK": function($event) {
            return _vm.onLeave()
          },
          "action-CANCEL": function($event) {
            return _vm.abbrechen()
          },
          "action-REMOVE": function($event) {
            return _vm.remove()
          }
        }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.loading
            ? _c(
                "GhostLoading",
                [
                  _c("Block", { attrs: { type: "title", width: "100%" } }),
                  _c("Block", { attrs: { type: "title", width: "100%" } }),
                  _c("Block", {
                    staticClass: "mb-3",
                    attrs: { type: "title", width: "100%" }
                  }),
                  _c("Block", { attrs: { width: "50%" } }),
                  _c("Block", { attrs: { type: "form-input" } })
                ],
                1
              )
            : _c(
                "form",
                {
                  attrs: { autocomplete: "off" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _c("div", { staticClass: "box__title" }, [
                    _vm._v(_vm._s(_vm.subtitle))
                  ]),
                  _c("p", { staticClass: "mt-0 mb-2" }, [
                    _vm._v("Id " + _vm._s(_vm.isNew ? "n/a" : _vm.id))
                  ]),
                  _vm._l(_vm.components, function(component, index) {
                    return [
                      _c(
                        "div",
                        {
                          key: index,
                          staticClass: "antrag-component__container"
                        },
                        [
                          !_vm.isComponentHidden(component)
                            ? _c("AntragComponent", {
                                staticClass: "antrag-component",
                                attrs: {
                                  component: component,
                                  data: _vm.allData,
                                  isComponentHalfSizeEnabled: ""
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.onChange(component, $event)
                                  }
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  })
                ],
                2
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }