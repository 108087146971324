<template>
  <div>
    <div class="box__container">
      <div class="box__title">Zielmarkt</div>

      <div class="font-bold">SRI</div>
      <InputSlider
        class="slider-input__container"
        isComponentHalfSize
        v-model="form.sriSetting"
        :values="valuesSri"
        :disabled="form.disabled"
        validateUntouched
        @input="updateStore({ sriSetting: $event }, 'settings')"
      />

      <div class="font-bold">Benötigte Kenntnisse und Erfahrungen</div>
      <ComboBox
        isComponentHalfSize
        v-model="form.keuerSetting"
        :values="valuesKeuer"
        validateUntouched
        :sortComboboxValues="false"
        :disabled="form.disabled"
        @change="updateStore({ keuerSetting: $event }, 'settings')"
      />

      <div class="font-bold">Zeithorizont</div>
      <ComboBox
        isComponentHalfSize
        v-model="form.zeithorizontSetting"
        :values="valuesZeithorizont"
        validateUntouched
        :sortComboboxValues="false"
        :disabled="form.disabled"
        @change="updateStore({ zeithorizontSetting: $event }, 'settings')"
      />

      <div class="font-bold">Anlageziele</div>
      <InputCheckBoxGroup
        :values="valuesAnlageziele"
        :value="form"
        :disabled="form.disabled"
        validateUntouched
        @checkBoxToggle="updateStore($event, 'settings')"
      />

      <div class="font-bold">Verlustbereitschaft</div>
      <ComboBox
        isComponentHalfSize
        v-model="form.verlustSetting"
        :values="valuesVerlust"
        validateUntouched
        :sortComboboxValues="false"
        :disabled="form.disabled"
        @change="updateStore({ verlustSetting: $event }, 'settings')"
      />
    </div>
  </div>
</template>

<script>
import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputSlider from "@/components/core/forms/InputSlider.vue";
import InputCheckBoxGroup from "@/components/core/forms/checkbox/InputCheckBoxGroup.vue";
import mixin from "@/mixins/vermoegensverwaltung/vv-mixin.js";

export default {
  mixins: [mixin],
  components: {
    ComboBox,
    InputSlider,
    InputCheckBoxGroup,
  },
  data() {
    return {
      valuesAnlageziele: [
        { label: "Altersvorsorge (AV)", path: "ALTERSVORSORGE" },
        { label: "Kapitalschutz (KS)", path: "KAPITALSCHUTZ" },
        { label: "Vermögensaufbau (V)", path: "VERMOEGEN" },
        { label: "überproportionale Kursteilnahme (K)", path: "KURSTEILNAHME" },
      ],
      valuesZeithorizont: [
        { label: "Sehr kurzfristig (unter 1 Jahr)", value: "SEHR_KURZFRISTIG" },
        { label: "Kurzfristig (1 bis 3 Jahre)", value: "KURZFRISTIG" },
        { label: "Mittelfristig (3 bis 5 Jahre)", value: "MITTELFRISTIG" },
        { label: "Langfristig (5 bis 10 Jahre)", value: "LANGFRISTIG" },
        {
          label: "Sehr langfristig (über 10 Jahre)",
          value: "SEHR_LANGFRISTIG",
        },
      ],
      valuesKeuer: [
        { label: "keine", value: "KEINE" },
        { label: "Basis", value: "BASIS" },
        { label: "erweitert", value: "ERWEITERT" },
        { label: "umfangreich", value: "UMFANGREICH" },
        { label: "Experte", value: "SPEZIELL" },
      ],
      valuesVerlust: [
        { label: "keine", value: "KEINE" },
        { label: "Gering", value: "GERINGE" },
        { label: "bis zum Kapital", value: "BIS_KAPITAL" },
        { label: "Über Kapital hinaus", value: "UEBER_KAPITAL" },
      ],

      minValue: null,
      maxValue: null,
      valuesSri: [
        { name: "0", value: "0" },
        { name: "1", value: "1" },
        { name: "2", value: "2" },
        { name: "3", value: "3" },
        { name: "4", value: "4" },
        { name: "5", value: "5" },
        { name: "6", value: "6" },
        { name: "7", value: "7" },
      ],
      form: {
        sriSetting: "",
        zeithorizontSetting: "",
        verlustSetting: "",
        keuerSetting: "",
        ALTERSVORSORGE: false,
        KAPITALSCHUTZ: false,
        VERMOEGEN: false,
        KURSTEILNAHME: false,
      },
    };
  },
  mounted() {
    this.initValuesSettings();
    this.updateWarnings(true);
  },
  watch: {
    schemaData() {
      this.initValuesSettings();
    },
  },
};
</script>

<style scoped>
</style>