<template>
  <div class="label lende">
    <div class="result">{{getEnde}} €</div>
    <div class="truelabel">Ende</div>
  </div>
</template>

<script>
//import store from "./../../store.js";
export default {
  name: "LEnde",
  computed: {
    getEnde() {
      return this.$store.getters.getEnde;
    }
  },
};
</script>

<style scoped>
.lende {
  grid-area: lende;
}

</style>