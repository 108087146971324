var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "f2e283ea-0459-447a-9b0b-c99a7bb735de",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        width: "786.97931",
        height: "590.64559",
        viewBox: "0 0 786.97931 590.64559"
      }
    },
    [
      _c("path", {
        attrs: {
          id: "e7a552b9-89f6-403f-a662-c184ed590622",
          "data-name": "Path 133",
          d:
            "M946.77819,690.38938a189.65366,189.65366,0,0,1-8.86044,52.42624c-.11974.39391-.25264.78189-.38195,1.1758H904.47317c.03472-.3532.07064-.74718.10539-1.1758,2.20433-25.34327-10.38947-177.75122-25.58631-204.129C880.32367,540.82632,949.34172,609.86231,946.77819,690.38938Z",
          transform: "translate(-206.17014 -154.54318)",
          fill: "#f0f0f0"
        }
      }),
      _c("path", {
        attrs: {
          id: "b08f6207-3c71-42fa-8723-265175c281aa",
          "data-name": "Path 134",
          d:
            "M944.2972,742.81562c-.27657.39391-.564.78788-.85852,1.1758H918.63424c.188-.3353.40589-.7292.65853-1.1758,4.09735-7.39491,16.22537-29.5053,27.48293-52.42624,12.09331-24.632,23.19759-50.198,22.26244-59.45114C969.328,633.024,977.69983,696.64437,944.2972,742.81562Z",
          transform: "translate(-206.17014 -154.54318)",
          fill: "#f0f0f0"
        }
      }),
      _c("path", {
        attrs: {
          d: "M830.34191,635.03175H206.17014V154.54318H830.34191Z",
          transform: "translate(-206.17014 -154.54318)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M830.34191,635.03175H206.17014V154.54318H830.34191Zm-614.94969-9.22207H821.11983V163.76526H215.39222Z",
          transform: "translate(-206.17014 -154.54318)",
          fill: "#e5e5e5"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M369.52542,516.73978H322.26811a4.83226,4.83226,0,0,1-4.82688-4.82649V446.76885a4.83226,4.83226,0,0,1,4.82688-4.82649h47.25731a4.83226,4.83226,0,0,1,4.82688,4.82649v65.14444A4.83226,4.83226,0,0,1,369.52542,516.73978Z",
          transform: "translate(-206.17014 -154.54318)",
          fill: "#e6e6e6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M455.70505,516.73978H408.44774a4.83226,4.83226,0,0,1-4.82688-4.82649V384.97968a4.83226,4.83226,0,0,1,4.82688-4.82649h47.25731a4.83226,4.83226,0,0,1,4.82688,4.82649V511.91329A4.83226,4.83226,0,0,1,455.70505,516.73978Z",
          transform: "translate(-206.17014 -154.54318)",
          fill: "#e6e6e6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M541.88468,516.73978H494.62737a4.83226,4.83226,0,0,1-4.82688-4.82649V446.76885a4.83226,4.83226,0,0,1,4.82688-4.82649h47.25731a4.83226,4.83226,0,0,1,4.82688,4.82649v65.14444A4.83226,4.83226,0,0,1,541.88468,516.73978Z",
          transform: "translate(-206.17014 -154.54318)",
          fill: "#e6e6e6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M628.06431,516.73978H580.807a4.72664,4.72664,0,0,1-4.82688-4.60784V360.37056a4.72664,4.72664,0,0,1,4.82688-4.60783h47.25731a4.72664,4.72664,0,0,1,4.82688,4.60783V512.13194A4.72664,4.72664,0,0,1,628.06431,516.73978Z",
          transform: "translate(-206.17014 -154.54318)",
          fill: "#e6e6e6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M714.24394,516.73978H666.98663a4.83226,4.83226,0,0,1-4.82688-4.82649V316.68639a4.83226,4.83226,0,0,1,4.82688-4.82649h47.25731a4.83226,4.83226,0,0,1,4.82688,4.82649v195.2269A4.83226,4.83226,0,0,1,714.24394,516.73978Z",
          transform: "translate(-206.17014 -154.54318)",
          fill: "#f2f2f2"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "139.72663",
          cy: "258.13062",
          r: "9.75618",
          fill: "#3f3d56"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "225.90626",
          cy: "194.71542",
          r: "9.75618",
          fill: "#3f3d56"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "312.08589",
          cy: "258.13062",
          r: "9.75618",
          fill: "#3f3d56"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "398.26552",
          cy: "163.82084",
          r: "9.75618",
          fill: "#3f3d56"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "484.44515",
          cy: "128.04816",
          r: "9.75618",
          fill: "#3f3d56"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "312.292 260.316 225.906 195.613 140.702 259.432 138.752 256.829 225.906 191.549 311.88 255.945 397.315 162.451 397.658 162.312 483.837 127.589 485.053 130.606 399.216 165.19 312.292 260.316",
          fill: "#3f3d56"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "604.996 574.714 590.317 574.713 583.333 518.093 604.999 518.094 604.996 574.714",
          fill: "#ffb6b6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M814.90974,743.48659l-47.33243-.00175v-.59868a18.4241,18.4241,0,0,1,18.4231-18.42281h.00117l28.909.00117Z",
          transform: "translate(-206.17014 -154.54318)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M758.70505,489.60686,767.03871,622.389l27.98117,93.46455,21.67845,1.56976L805.81248,603.51508l4.773-75.64658s22.627-16.8667,10.11616-37.16992Z",
          transform: "translate(-206.17014 -154.54318)",
          fill: "#2f2e41"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "614.575 574.714 599.895 574.713 592.912 518.093 614.577 518.094 614.575 574.714",
          fill: "#ffb6b6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M824.4886,743.48659l-47.33243-.00175v-.59868a18.42409,18.42409,0,0,1,18.4231-18.42281h.00117l28.909.00117Z",
          transform: "translate(-206.17014 -154.54318)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M768.2839,489.60686,776.61757,622.389l27.98117,93.46455,21.67845,1.56976L815.39133,603.51508l4.77305-75.64658s22.627-16.8667,10.11616-37.16992Z",
          transform: "translate(-206.17014 -154.54318)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M813.4614,510.1689c-24.998,0-54.41981-15.27682-56.12493-16.17426l-.47415-.24964,57.37257-146.36577L842.649,333.95574a13.596,13.596,0,0,1,19.31966,13.79942L850.69318,448.909l-11.77011,52.3908-.12512.12277C832.30223,507.84084,823.21682,510.1689,813.4614,510.1689Z",
          transform: "translate(-206.17014 -154.54318)",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M721.78115,413.27726a12.04035,12.04035,0,0,1,17.59049,5.6068l42.72367-2.33387-11.20993,19.2019-38.85251-.72758a12.1056,12.1056,0,0,1-10.25172-21.74725Z",
          transform: "translate(-206.17014 -154.54318)",
          fill: "#ffb6b6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M806.094,443.335l-42.81545-1.09271.5525-28.32146,20.24573-6.444,33.20883-46.57765a21.4253,21.4253,0,1,1,34.165,25.83555Z",
          transform: "translate(-206.17014 -154.54318)",
          fill: "#3f3d56"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "624.71343",
          cy: "145.57016",
          r: "28.44921",
          fill: "#ffb6b6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M794.891,276.40569a19.44515,19.44515,0,0,1,10.65651-5.59168c3.95126-.85011,8.28569-1.11353,11.44673-3.59207,3.59207-2.8617,5.17259-8.29767,9.57885-9.507a10.501,10.501,0,0,1,5.73532.455,37.118,37.118,0,0,1,17.96035,11.97357,15.37488,15.37488,0,0,0,4.13089,4.13089,29.93045,29.93045,0,0,1,2.84972,1.38894,7.759,7.759,0,0,1,2.32287,3.02931,29.44287,29.44287,0,0,1,2.05942,20.67834c-1.10154,4.083-3.0892,7.86665-4.26258,11.97357s-6.60024,11.9196-7.86944,9.97989-2.89758-3.74773-3.59207-5.98679c-1.35485-4.40429-.13131-8.83646-3.57729-11.89566-2.13133-1.89181-5.98679-3.161-7.43562-.71842-.5747.94592-.53882,2.14328-1.02971,3.12508-.96985,1.91578-3.7717,2.29894-5.62759,1.19736a8.633,8.633,0,0,1-3.52023-5.22046c-2.78986-9.43518-7.54333-15.37406-17.637-11.339-3.75972,1.50868-8.19,2.5264-11.80592-.05985C790.50869,287.02624,791.47854,280.28511,794.891,276.40569Z",
          transform: "translate(-206.17014 -154.54318)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M731.00694,532.30542H683.74962a4.83226,4.83226,0,0,1-4.82688-4.82649V332.252a4.83226,4.83226,0,0,1,4.82688-4.82648h47.25732a4.83226,4.83226,0,0,1,4.82688,4.82648V527.47893A4.83226,4.83226,0,0,1,731.00694,532.30542Z",
          transform: "translate(-206.17014 -154.54318)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M720.58379,430.04025a12.04038,12.04038,0,0,1,17.5905,5.6068l42.72366-2.33387L769.688,452.51509l-38.85251-.72758a12.10561,12.10561,0,0,1-10.25172-21.74726Z",
          transform: "translate(-206.17014 -154.54318)",
          fill: "#ffb6b6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M804.89662,460.098l-42.81545-1.0927.55249-28.32147,20.24574-6.444,33.20882-46.57766a21.4253,21.4253,0,1,1,34.165,25.83555Z",
          transform: "translate(-206.17014 -154.54318)",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M991.95209,745.18877h-456.193a1.19736,1.19736,0,1,1,0-2.39471h456.193a1.19736,1.19736,0,1,1,0,2.39471Z",
          transform: "translate(-206.17014 -154.54318)",
          fill: "#cacaca"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }