import BIPRO_CATEGORY_TYPES from './types';
import { getInitialState } from './index';
import Vue from 'vue';

export default {
    [BIPRO_CATEGORY_TYPES.MUTATIONS.RESET_STATE](state) {
        Object.assign(state, getInitialState())
    },
    [BIPRO_CATEGORY_TYPES.MUTATIONS.RETRIEVE_BIPRO_CATEGORIES_SUCCESS](state, payload) {
        Vue.set(state, 'biproCategories', payload);
    },
    [BIPRO_CATEGORY_TYPES.MUTATIONS.SELECT_ROW](state, payload) {
        Vue.set(state, 'selectedRow', payload);
    },
       
}
