var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _c(
            "label",
            { staticClass: "mb-0 font-strong", attrs: { for: "knowledge" } },
            [_vm._v("Kenntnisse")]
          ),
          _c("InputSlider", {
            attrs: {
              values: _vm.knowledges,
              disabled: _vm.isDisabled || _vm.isRefusedPage
            },
            on: {
              input: function($event) {
                return _vm.doExperienceCardChange(
                  _vm.form,
                  _vm.category,
                  "knowledge",
                  $event
                )
              }
            },
            model: {
              value: _vm.form.knowledge,
              callback: function($$v) {
                _vm.$set(_vm.form, "knowledge", $$v)
              },
              expression: "form.knowledge"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "label",
            { staticClass: "mb-0 font-strong", attrs: { for: "experience" } },
            [_vm._v("Erfahrungen")]
          ),
          _c("InputSlider", {
            attrs: {
              values: _vm.knowledges,
              disabled: _vm.isDisabled || _vm.isRefusedPage
            },
            on: {
              input: function($event) {
                return _vm.doExperienceCardChange(
                  _vm.form,
                  _vm.category,
                  "experience",
                  $event
                )
              }
            },
            model: {
              value: _vm.form.experience,
              callback: function($$v) {
                _vm.$set(_vm.form, "experience", $$v)
              },
              expression: "form.experience"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-12 mt-3" },
        [
          _c("FormLabel", {
            attrs: {
              config: { bold: true },
              label:
                "Getätigte Geschäfte (Hohe der Anlagesumme, Währungen, Fremdfinanzierungen, Anzahl) in T Euro)"
            }
          })
        ],
        1
      ),
      _c("InputField", {
        attrs: {
          label: "Zeichnungsvolumen gesamt",
          type: "number",
          disabled: _vm.isDisabled || _vm.isRefusedPage
        },
        on: {
          change: function($event) {
            return _vm.doExperienceCardChange(
              _vm.form,
              _vm.category,
              "amountTotal",
              $event
            )
          }
        },
        model: {
          value: _vm.form.amountTotal,
          callback: function($$v) {
            _vm.$set(_vm.form, "amountTotal", $$v)
          },
          expression: "form.amountTotal"
        }
      }),
      _c("FormLabel", {
        staticClass: "col-12 mt-3",
        attrs: { label: "Davon in folgenden Währungen:" }
      }),
      _c("InputField", {
        attrs: {
          label: "EUR",
          type: "number",
          disabled: _vm.isDisabled || _vm.isRefusedPage
        },
        on: {
          change: function($event) {
            return _vm.doExperienceCardChange(
              _vm.form,
              _vm.category,
              "amountEur",
              $event
            )
          }
        },
        model: {
          value: _vm.form.amountEur,
          callback: function($$v) {
            _vm.$set(_vm.form, "amountEur", $$v)
          },
          expression: "form.amountEur"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "USD",
          type: "number",
          disabled: _vm.isDisabled || _vm.isRefusedPage
        },
        on: {
          change: function($event) {
            return _vm.doExperienceCardChange(
              _vm.form,
              _vm.category,
              "amountUsd",
              $event
            )
          }
        },
        model: {
          value: _vm.form.amountUsd,
          callback: function($$v) {
            _vm.$set(_vm.form, "amountUsd", $$v)
          },
          expression: "form.amountUsd"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "GBP",
          type: "number",
          disabled: _vm.isDisabled || _vm.isRefusedPage
        },
        on: {
          change: function($event) {
            return _vm.doExperienceCardChange(
              _vm.form,
              _vm.category,
              "amountGbp",
              $event
            )
          }
        },
        model: {
          value: _vm.form.amountGbp,
          callback: function($$v) {
            _vm.$set(_vm.form, "amountGbp", $$v)
          },
          expression: "form.amountGbp"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Sonstige",
          type: "number",
          disabled: _vm.isDisabled || _vm.isRefusedPage
        },
        on: {
          change: function($event) {
            return _vm.doExperienceCardChange(
              _vm.form,
              _vm.category,
              "amountSonstige",
              $event
            )
          }
        },
        model: {
          value: _vm.form.amountSonstige,
          callback: function($$v) {
            _vm.$set(_vm.form, "amountSonstige", $$v)
          },
          expression: "form.amountSonstige"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Anzahl Transaktionen p.a.",
          type: "number",
          disabled: _vm.isDisabled || _vm.isRefusedPage
        },
        on: {
          change: function($event) {
            return _vm.doExperienceCardChange(
              _vm.form,
              _vm.category,
              "transaction",
              $event
            )
          }
        },
        model: {
          value: _vm.form.transaction,
          callback: function($$v) {
            _vm.$set(_vm.form, "transaction", $$v)
          },
          expression: "form.transaction"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Anmerkungen",
          disabled: _vm.isDisabled || _vm.isRefusedPage
        },
        on: {
          change: function($event) {
            return _vm.doExperienceCardChange(
              _vm.form,
              _vm.category,
              "annotationGFCard",
              $event
            )
          }
        },
        model: {
          value: _vm.form.annotationGFCard,
          callback: function($$v) {
            _vm.$set(_vm.form, "annotationGFCard", $$v)
          },
          expression: "form.annotationGFCard"
        }
      }),
      _vm.isDetailButtonVisible()
        ? _c(
            "BaseButton",
            {
              attrs: {
                disabled: _vm.isDisabled || _vm.isSaving,
                isSecondary: ""
              },
              on: {
                click: function($event) {
                  return _vm.openSaveAllModal()
                }
              }
            },
            [
              _c("span", [
                _vm._v(
                  " Detailangaben für andere Kenntnisse und Erfahrungen übernehmen"
                )
              ])
            ]
          )
        : _vm._e(),
      _c(
        "BaseModal",
        {
          ref: "saveForAll",
          attrs: {
            labelButtonConfirm: "Daten übernehmen",
            labelButtonCancel: "Abbrechen"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.doSaveForAll()
            },
            onCancelButton: function($event) {
              return _vm.closeModal()
            },
            onCloseButton: function($event) {
              return _vm.closeModal()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [_vm._v(" Angaben übernehmen ")]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", [
            _vm._v("Die Angaben von Detailangaben zu "),
            _c("span", { staticStyle: { "font-weight": "bold" } }, [
              _vm._v(_vm._s((_vm.category && _vm.category.label) || ""))
            ]),
            _vm._v(" auch übernehmen für:")
          ]),
          _vm._l(_vm.selectedCategories, function(cat) {
            return _c(
              "div",
              { key: cat.substepKey },
              [
                _c("InputCheckBoxItem", {
                  attrs: { label: cat.title },
                  model: {
                    value: cat.value,
                    callback: function($$v) {
                      _vm.$set(cat, "value", $$v)
                    },
                    expression: "cat.value"
                  }
                })
              ],
              1
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }