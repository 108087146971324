<template>
  <div>
    <div class="box__container">
      <div class="box__title">Vertragsdaten</div>
      <InputField
        label="Kategorie"
        v-model="brokerData.kategorie"
        :isComponentHalfSize="true"
        :disabled="true"
      />
      <ComboBox
        label="Potential"
        v-model="brokerData.potential"
        :values="brokerDataConfig.potentialValues"
        :isComponentHalfSize="true"
        @change="addBrokerDataEditedCombo('brokerData', 'potential', $event)"
      />
      <InputCheckboxItem
        v-model="brokerData.checkMaklerInteressent"
        label="Interessent"
        @input="addBrokerDataEdited('brokerData')"
        @change="addBrokerDataEdited('brokerData')"
      />
      <InputRadioBoxGroup
        v-model="brokerData.radioFAHomepage"
        title="Homepage"
        :inlineLabelValues="false"
        :values="[
          { label: 'Ja', value: 'Ja' },
          { label: 'Nein', value: 'Nein' },
        ]"
        @input="addBrokerDataEdited('brokerData')"
      />
      <InputField
        label="Homepage URL"
        v-model="brokerData.homepageUrl"
        :isComponentHalfSize="true"
        @change="addBrokerDataEdited('brokerData')"
      />
      <DatePickerField
        label="Homepage geprüft"
        v-model="brokerData.homepageGeprueft"
        isValueAsString
        isComponentHalfSize
        @change="addBrokerDataEdited('brokerData')"
      />
      <InputRadioBoxGroup v-if="isStructure10000"
        v-model="brokerData.jclErforderlich"
        title="Jahrescheckliste erforderlich"
        :inlineLabelValues="false"
        :values="[
          { label: 'Ja', value: 'Ja' },
          { label: 'Nein', value: 'Nein' },
        ]"
        @input="addBrokerDataEdited('brokerData')"
      />
      <InputCheckboxItem v-if="isStructure10000"
        v-model="brokerData.persoenlichesGeschaeft"
        label="Persönliches Geschäft erforderlich"
        @input="addBrokerDataEdited('brokerData')"
      />
      <InputCheckboxItem v-if="isStructure10000"
        v-model="brokerData.persoenlichesGeschaeftUnternummer"
        label="Persönliches Geschäft erforderlich (Unternummer)"
        @input="addBrokerDataEdited('brokerData')"
      />
      <FormDivider/>
      <div class="box__title">GISA</div>
      <InputField
        label="GISA 1 Vermögensberater"
        v-model="brokerData.gisa1"
        :isComponentHalfSize="true"
        @change="addBrokerDataEdited('brokerData')"
      />      
      <InputCheckboxItem
        v-model="brokerData.gisa1Checked"
        label="Geprüft"
        @input="addBrokerDataEdited('brokerData')"
      />
      <DatePickerField
        v-model="brokerData.gisa1Date"
        isValueAsString
        isComponentHalfSize
        :disabled="!brokerData.gisa1Checked"
        @change="addBrokerDataEdited('brokerData')"
      />      
      
      <InputField
        label="GISA 2 Vermögensverwalter"
        v-model="brokerData.gisa2"
        :isComponentHalfSize="true"
        @change="addBrokerDataEdited('brokerData')"
      />      
      <InputCheckboxItem
        v-model="brokerData.gisa2Checked"
        label="Geprüft"
        @input="addBrokerDataEdited('brokerData')"
      />
      <DatePickerField
        v-model="brokerData.gisa2Date"
        isValueAsString
        isComponentHalfSize
        :disabled="!brokerData.gisa2Checked"
        @change="addBrokerDataEdited('brokerData')"
      />      
      
      <InputField
        label="GISA 3 Versicherungsmakler"
        v-model="brokerData.gisa3"
        :isComponentHalfSize="true"
        @change="addBrokerDataEdited('brokerData')"
      />      
      <InputCheckboxItem
        v-model="brokerData.gisa3Checked"
        label="Geprüft"
        @input="addBrokerDataEdited('brokerData')"
      />
      <DatePickerField
        v-model="brokerData.gisa3Date"
        isValueAsString
        isComponentHalfSize
        :disabled="!brokerData.gisa3Checked"
        @change="addBrokerDataEdited('brokerData')"
      />      
      
      <InputField
        label="GISA 4 Versicherungsagent"
        v-model="brokerData.gisa4"
        :isComponentHalfSize="true"
        @change="addBrokerDataEdited('brokerData')"
      />      
      <InputCheckboxItem
        v-model="brokerData.gisa4Checked"
        label="Geprüft"
        @input="addBrokerDataEdited('brokerData')"
      />
      <DatePickerField
        v-model="brokerData.gisa4Date"
        isValueAsString
        isComponentHalfSize
        :disabled="!brokerData.gisa4Checked"
        @change="addBrokerDataEdited('brokerData')"
      />
      <InputTextArea
        label="Gewerbeumfang"
        v-model="brokerData.gewerbeumfang"
        isComponentHalfSize
        @input="addBrokerDataEdited('brokerData')"
        />

      <FormDivider />
      <div class="box__title">Vermittlereinstufungen</div>
      <div></div>
      <InputCheckboxItem
        v-model="brokerData.juristischePerson"
        label="Juristische Person"
        @input="addBrokerDataEdited('brokerData')"
      />

      <InputField
        label="Firmenbuch-Nr."
        v-model="brokerData.firmenbuchNr"
        :isComponentHalfSize="true"
        @change="addBrokerDataEdited('brokerData')"
      />
      <InputField
        label="Gericht"
        v-model="brokerData.gericht"
        :isComponentHalfSize="true"
        @change="addBrokerDataEdited('brokerData')"
      />

      <InputRadioBoxGroup
        v-model="brokerData.konzessioniert"
        title="Makler ist Konzessioniert"
        :inlineLabelValues="false"
        :values="[
          { label: 'Ja', value: true },
          { label: 'Nein', value: false },
        ]"
        @input="addBrokerDataEdited('brokerData')"
      />

      <DatePickerField
        label="Stand"
        v-model="brokerData.konzessioniertStand"
        isValueAsString
        isComponentHalfSize
        :disabled="true"
        @change="addBrokerDataEdited('brokerData')"
      />

      <DatePickerField
        label="Ersterfassung"
        v-model="brokerData.ersterfassung"
        isValueAsString
        isComponentHalfSize
        :disabled="true"
        @change="addBrokerDataEdited('brokerData')"
      />

      <InputRadioBoxGroup
        title="Vermittlereinstufung"
        v-model="brokerData.vermittlereinstufung"
        :values="vermittlereinstufungValues"
        :inlineLabelValues="false"
        @input="addBrokerDataEdited('brokerData')"
      />

      <ComboBox
        label="Gewerbeschein"
        v-model="brokerData.maklerGewerbeschein"
        :values="gewerbescheinValues"
        :isComponentHalfSize="true"
        @change="
          addBrokerDataEditedCombo('brokerData', 'maklerGewerbeschein', $event)
        "
      />
      <InputToggleSwitch
        v-model="brokerData.versicherungsmakler"
        label="Versicherungsmakler"
        @input="addBrokerDataEdited('brokerData')"
        :config="{ forceValueAsString: true }"
        :disabled="disableVersMakler"
      />
      <InputToggleSwitch
        v-model="brokerData.versicherungsagent"
        label="Versicherungsagent"
        @input="addBrokerDataEdited('brokerData')"
        :config="{ forceValueAsString: true }"
        :disabled="disableVersAgent"
      />
      <InputToggleSwitch
        v-model="brokerData.fehlendenUnterlagenInaktiv"
        label="Bei fehlenden Unterlagen trotzdem nicht inaktiv setzen"
        @input="addBrokerDataEdited('brokerData')"
        :config="{ forceValueAsString: true }"
      />

      <div class="row mt-3 mb-5">
        <InputRadioBoxGroup
          title="Vermittlung von Privatkrediten"
          v-model="brokerData.vermittlungPrivatkredite"
          :inlineLabelValues="false"
          :values="[
            { label: 'Ja', value: 'Ja' },
            { label: 'Nein', value: 'Nein' },
          ]"
          @input="addBrokerDataEdited('brokerData')"
          @change="addBrokerDataEdited('brokerData')"
        />
      </div>
      <div class="row mt-3 mb-3">
        <div class="col-2">
          <InputCheckboxItem
            v-model="brokerData.strafregisterauszug"
            label="Strafregisterauszug"
            @input="addBrokerDataEdited('brokerData')"
          />
        </div>
        <div class="col-8">
          <DatePickerField
            label=""
            v-model="brokerData.strafregisterauszugDatum"
            isValueAsString
            isComponentHalfSize
            @change="addBrokerDataEdited('brokerData')"
          />
        </div>
      </div>

      <div class="row mt-3 mb-3">
        <div class="col-2">
          <InputCheckboxItem
            v-model="brokerData.gewerbeanmeldung"
            label="Gewerbeanmeldung"
            @input="addBrokerDataEdited('brokerData')"
          />
        </div>
        <div class="col-8">
          <DatePickerField
            label=""
            v-model="brokerData.gewerbeanmeldungDatum"
            isValueAsString
            isComponentHalfSize
            @change="addBrokerDataEdited('brokerData')"
          />
        </div>
      </div>

      <div class="row mt-3 mb-3">
        <div class="col-2">
          <InputCheckboxItem
            v-model="brokerData.kcvAuskunft"
            label="KSV Auskunft"
            isComponentHalfSize
            @input="addBrokerDataEdited('brokerData')"
          />
        </div>
        <div class="col-8">
          <DatePickerField
            label=""
            v-model="brokerData.kcvAuskunftDatum"
            isValueAsString
            isComponentHalfSize
            @change="addBrokerDataEdited('brokerData')"
          />
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-2">Vermögensschadenshaftung</div>
        <div class="col-8">
          <DatePickerField
            label=""
            v-model="brokerData.vertragVMshpflDatum"
            isValueAsString
            isComponentHalfSize
            @change="addBrokerDataEdited('brokerData')"
          />
        </div>
      </div>
      <InputRadioBoxGroup
        v-model="brokerData.vertragVMshpfl"
        :values="vertragVMshpflValues"
        :inlineLabelValues="false"
        @change="addBrokerDataEdited('brokerData')"
      />

      <InputField
        label="Polizzennummer"
        v-model="brokerData.polizzennummer"
        :isComponentHalfSize="true"
        @change="addBrokerDataEdited('brokerData')"
      />

      <InputField
        label="wo eingerichtet"
        v-model="brokerData.woEingerichtet"
        :isComponentHalfSize="true"
        @change="addBrokerDataEdited('brokerData')"
      />
      <FormDivider />
      <div class="box__title">WAG</div>
      <DatePickerField
        label="Vertriebspartnervertrag abgeschickt"
        v-model="brokerData.vertragAusgangDatum"
        isValueAsString
        isComponentHalfSize
        @change="addBrokerDataEdited('brokerData')"
      />
      <DatePickerField
        label="Vertriebspartnervertrag zurückerhalten"
        v-model="brokerData.vertragZurueckDatum"
        isValueAsString
        isComponentHalfSize
        @change="addBrokerDataEdited('brokerData')"
      />
      <DatePickerField
        label="Vertriebspartnervertrag gekündigt"
        v-model="brokerData.vertragGekuendigtDatum"
        isValueAsString
        isComponentHalfSize
        @change="addBrokerDataEdited('brokerData')"
      />
      <div class="row mt-3 mb-3">
        <div class="col-2">
          <InputCheckboxItem
            v-model="brokerData.schulungEinzeltitelChk"
            label="Schulung Einzeltitel"
            @input="addBrokerDataEdited('brokerData')"
            @change="addBrokerDataEdited('brokerData')"
          />
        </div>
        <div class="col-8">
          <DatePickerField
            label=""
            v-model="brokerData.schulungEinzeltitelDatum"
            isValueAsString
            isComponentHalfSize
            @change="addBrokerDataEdited('brokerData')"
          />
        </div>
      </div>
      <FormDivider />
      <div class="box__title">Versicherungen</div>
      <DatePickerField
        label="Vertriebspartnervertrag abgeschickt"
        v-model="brokerData.vertragVersichAusgangDatum"
        isValueAsString
        isComponentHalfSize
        @change="addBrokerDataEdited('brokerData')"
      />
      <DatePickerField
        label="Vertriebspartnervertrag zurückerhalten"
        v-model="brokerData.vertragVersichZurueckDatum"
        isValueAsString
        isComponentHalfSize
        @change="addBrokerDataEdited('brokerData')"
      />
      <DatePickerField
        label="Vertriebspartnervertrag gekündigt"
        v-model="brokerData.vertragVersichGekuendigtDatum"
        isValueAsString
        isComponentHalfSize
        @change="addBrokerDataEdited('brokerData')"
      />
      <div class="row mt-3 mb-3">
        <div class="col-2">
          <InputCheckboxItem
            v-model="brokerData.vertragStatus"
            label="Status"
            @input="addBrokerDataEdited('brokerData')"
          />
        </div>
        <div class="col-8">
          <InputField
            v-model="brokerData.vertragStatusText"
            :isComponentHalfSize="true"
            @change="addBrokerDataEdited('brokerData')"
          />
        </div>
      </div>
      <InputCheckboxItem
        v-model="brokerData.istMehrfachAgent"
        label="Agent"
        @input="addBrokerDataEdited('brokerData')"
      />
      <div class="row mt-3 mb-3">
        <div class="col-2">
          <InputCheckboxItem
            v-model="brokerData.versMehrfachAgentErlaubeAuftrag"
            label="Erlaube Maklerauftrag über"
            @input="addBrokerDataEdited('brokerData')"
          />
        </div>
        <div class="col-8">
          <ComboBox
            label=""
            v-model="brokerData.versMehrfachAgentAuftrag"
            :values="brokerDataConfig.versMehrfachAgentAuftragValues"
            :isComponentHalfSize="true"
            @change="
              addBrokerDataEditedCombo(
                'brokerData',
                'versMehrfachAgentAuftrag',
                $event
              )
            "
          />
        </div>
      </div>
      <FormDivider/>
      <div class="box__title">Vermittlung in Deutschland</div>
      <DatePickerField
        label="Anmeldung bei Behörde in Deutschland"
        v-model="brokerData.vermittlungAnmeldungDate"
        isValueAsString
        isComponentHalfSize
        @change="addBrokerDataEdited('brokerData')"
        />
        <DatePickerField
        label="Bestätigung von Behörde in Deutschland"
        v-model="brokerData.vermittlungBestaetigungDate"
        isValueAsString
        isComponentHalfSize
        @change="addBrokerDataEdited('brokerData')"
        />
        <InputCheckboxItem
        v-model="brokerData.freigabeFormulare"
        label="Formulare von Deutschland freigeben"
        @input="addBrokerDataEdited('brokerData')"
      />
        <DatePickerField
        v-model="brokerData.freigabeFormulareDate"
        isValueAsString
        isComponentHalfSize
        :disabled="!brokerData.freigabeFormulare"
        @change="addBrokerDataEdited('brokerData')"
        />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputField from "@/components/core/forms/InputField.vue";
import InputTextArea from "@/components/core/forms/InputTextArea.vue";
import InputCheckboxItem from "@/components/core/forms/checkbox/InputCheckBoxItem.vue";
import BROKERDATA_TYPES from "@/store/brokerData/types";
import CORE_TYPES from "@/store/core/types";
import vermittlerDatenMixin from "@/views/intern/vermittler-daten-mixin";
import InputRadioBoxGroup from "@/components/core/forms/radiobox/InputRadioBoxGroup.vue";
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import FormDivider from "@/components/core/forms/FormDivider.vue";
import FormHeader from "@/components/core/forms/FormHeader.vue";
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";

import { ROLES } from '@/router/roles'

export default {
  mixins: [vermittlerDatenMixin],
  data() {
    return {
      vermittlereinstufungValues: [
        {
          label: "Vertraglich gebundener Vermittler",
          value: "radiovalue_vertraglichgebunden",
        },
        {
          label: "Wertpapiervermittler",
          value: "radiovalue_wertpapiervermittler",
        },
        { label: "Interessent", value: "radiovalue_interessent" },
        {
          label: "Kooperationspartner",
          value: "radiovalue_kooperationspartner",
        },
      ],

      gewerbescheinValues: [
        { label: "Keine Auswahl", value: "" },
        { label: "Vermögensberater", value: "Vermögensberater" },
        { label: "FDLAss", value: "FDLAss" },
        { label: "Versicherungsmakler", value: "Versicherungsmakler" },
        { label: "Wertpapiervermittler", value: "Wertpapiervermittler" },
      ],

      vertragVMshpflValues: [
        { label: "eigene Versicherung besteht", value: "J" },
        { label: "wird nicht benötigt", value: "N" },
        { label: "Gruppenversicherungstarif", value: "G" }, //nur FA
      ],
      disableVersMakler: false,
      disableVersAgent: false,
    };
  },
  computed: {
    ...mapGetters({
      brokerData: BROKERDATA_TYPES.GETTERS.GET_BROKER_DATA,
      brokerDataConfig: BROKERDATA_TYPES.GETTERS.GET_BROKER_DATA_CONFIG,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      roles: CORE_TYPES.GETTERS.GET_USER_ROLES,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
    isStructure10000(){
      return  this.hasRoles([ROLES.SHOW_EVENTS])
    }
  },
  watch: {
    brokerData: {
      handler() {
        if (this.brokerData?.istMehrfachAgent) {
          this.brokerData.versicherungsmakler = 'Nein';
          this.brokerData.versicherungsagent = 'Ja';
          this.addBrokerDataEdited('brokerData');
          this.disableVersMakler = true;
          this.disableVersAgent = true;
        } else if (this.brokerData.versMehrfachAgentErlaubeAuftrag === 'Ja') {
          this.brokerData.versicherungsmakler = 'Ja';
          this.brokerData.versicherungsagent = 'Nein';
          this.addBrokerDataEdited('brokerData');
          this.disableVersMakler = true;
          this.disableVersAgent = true;
        } else if (this.brokerData?.versicherungsagent == 'Ja') {
          this.disableVersMakler = true;
          this.disableVersAgent = false;
        } else if (this.brokerData?.versicherungsmakler == 'Ja') {
          this.disableVersMakler = false;
          this.disableVersAgent = true;
        } else {
          this.disableVersMakler = false;
          this.disableVersAgent = false;
        }
      },
      immediate: true,
      deep: true,
    }
  },

  components: {
    ComboBox,
    InputField,
    InputCheckboxItem,
    InputRadioBoxGroup,
    DatePickerField,
    FormDivider,
    FormHeader,
    InputToggleSwitch,
    InputTextArea,
  },
};
</script>
