import Vue from 'vue';
import GESELLSCHAFT_TYPES from './types';

export default {

  [GESELLSCHAFT_TYPES.MUTATIONS.SET_GESELLSCHAFT](state, payload) {
    state.gesellschaftData = {...payload}
  },

  [GESELLSCHAFT_TYPES.MUTATIONS.GET_EIGENE_DOKUMENTE_SUCCESS](state, payload) {
    Vue.set(state, 'gesellschaftData', {
      ...state.gesellschaftData,
      nodeId: payload?.nodeId,
    });
  },
  [GESELLSCHAFT_TYPES.MUTATIONS.GET_GESELLSCHAFT_ID_LOGGED_USER_SUCCESS](state, payload) {
    Vue.set(state, 'gesellschaftLoggedUser', payload)
  },

  [GESELLSCHAFT_TYPES.MUTATIONS.GET_GESELLSCHAFT_DATEN_SUCCESS](state, payload) {
    Vue.set(state, 'gesellschaftData', {
      ...state.gesellschaftData,
      daten: payload.daten,
      comboboxSelections: {
        ...state.gesellschaftData?.comboboxSelections,
        ...payload.comboboxSelections,
      }
    });
  },

  [GESELLSCHAFT_TYPES.MUTATIONS.GET_GESELLSCHAFT_LESEZEICHEN_SUCCESS](state, payload) {
    Vue.set(state, 'gesellschaftData', {
      ...state.gesellschaftData,
      lesezeichen: payload.lesezeichen,
      comboboxSelections: {
        ...state.gesellschaftData?.comboboxSelections,
        ...payload.comboboxSelections,
      }
    });
  },

  [GESELLSCHAFT_TYPES.MUTATIONS.GET_GESELLSCHAFT_EINSTELLUNGEN_SUCCESS](state, payload) {
    Vue.set(state.gesellschaftData, 'settings', payload)
  },

  [GESELLSCHAFT_TYPES.MUTATIONS.GET_GESELLSCHAFT_MENU_SUCCESS](state, payload) {
    Vue.set(state, 'gesellschaftData', {
      ...state.gesellschaftData,
      menu: payload.menu,
      ...payload.additionalData,
    });
  },

  [GESELLSCHAFT_TYPES.MUTATIONS.GET_GESELLSCHAFT_AENDERUNGSLOG_SUCCESS](state, payload) {
    Vue.set(state, 'gesellschaftData', {
      ...state.gesellschaftData,
      aenderungslog: payload,
    });
  },

  [GESELLSCHAFT_TYPES.MUTATIONS.GET_GESELLSCHAFT_NUMMERN_SUCCESS](state, payload) {
    Vue.set(state, 'gesellschaftData', {
      ...state.gesellschaftData,
      nummern: payload,
    });
  },

  [GESELLSCHAFT_TYPES.MUTATIONS.GET_GESELLSCHAFT_MEHRFACHPOSITIONEN_SUCCESS](state, payload) {
    Vue.set(state, 'gesellschaftData', {
      ...state.gesellschaftData,
      mehrfachpositionen: payload,
    });
  },

  [GESELLSCHAFT_TYPES.MUTATIONS.GET_GESELLSCHAFT_VERS_SPARTEN_SUCCESS](state, payload) {
    Vue.set(state, 'gesellschaftData', {
      ...state.gesellschaftData,
      versSparten: payload,
    });
  },

  [GESELLSCHAFT_TYPES.MUTATIONS.GET_GESELLSCHAFT_PROVISION_VORGABEN_SUCCESS](state, payload) {
    Vue.set(state, 'gesellschaftData', {
      ...state.gesellschaftData,
      provisionVorgaben: payload,
    });
  },

  [GESELLSCHAFT_TYPES.MUTATIONS.GET_GESELLSCHAFT_FONDSAUFTRAG_SUCCESS](state, payload) {
    Vue.set(state, 'gesellschaftData', {
      ...state.gesellschaftData,
      fondsauftrag: payload,
    });
  },

  [GESELLSCHAFT_TYPES.MUTATIONS.GET_GESELLSCHAFT_AKTIVITAETEN_SUCCESS](state, payload) {
    Vue.set(state, 'gesellschaftData', {
      ...state.gesellschaftData,
      aktivitaeten: payload,
    });
  },

  [GESELLSCHAFT_TYPES.MUTATIONS.GET_GESELLSCHAFT_KENNUNGEN_SUCCESS](state, payload) {
    Vue.set(state, 'gesellschaftData', {
      ...state.gesellschaftData,
      kennungen: payload.kennungen,
      comboboxSelections: {
        ...state.gesellschaftData?.comboboxSelections,
        providerValues: payload.providerValues,
      }
    });
  },

  [GESELLSCHAFT_TYPES.MUTATIONS.GET_GESELLSCHAFT_DOKUMENTE_SUCCESS](state, payload) {
    Vue.set(state, 'gesellschaftData', {
      ...state.gesellschaftData,
      dokumente: payload,
    });
  },

  [GESELLSCHAFT_TYPES.MUTATIONS.GET_GESELLSCHAFT_ANTRAGSBEARBEITUNG_SUCCESS](state, payload) {
    Vue.set(state, 'gesellschaftData', {
      ...state.gesellschaftData,
      antragsbearbeitung: payload.antragsbearbeitung,
      comboboxSelections: {
        ...state.gesellschaftData?.comboboxSelections,
        ...payload.comboboxSelections,
      }
    });
  },

  [GESELLSCHAFT_TYPES.MUTATIONS.GET_GESELLSCHAFT_BEMERKUNGEN_SUCCESS](state, payload) {
    Vue.set(state, 'gesellschaftData', {
      ...state.gesellschaftData,
      bemerkungenData: payload.bemerkungenData,
      comboboxSelections: {
        ...state.gesellschaftData?.comboboxSelections,
        ...payload.comboboxSelections,
      }
    });
  },

  [GESELLSCHAFT_TYPES.MUTATIONS.GET_GESELLSCHAFTS_INFOS_SUCCESS](state, payload) {
    Vue.set(state, 'gesellschaftsInfos', payload)
  },  
}
