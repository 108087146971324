<template>
  <BaseImage 
    class="qr-code__container"
    :src="qrCodeUrl" 
    :placeholder="placeholderSize" 
  />
</template>

<script>
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';

import BaseImage, { createPlaceholderSize } from '@/components/core/BaseImage.vue';

const DEFAULT_SIZE = 100;

export default {
  components: {
    BaseImage,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: DEFAULT_SIZE,
    },
  },
  computed: {
    ...mapGetters({
      apiAddress: CORE_TYPES.GETTERS.API_ADDRESS,
    }),
    qrCodeUrl() {
      const { apiAddress, text, size } = this;
      return `${apiAddress}/qrcode?text=${text}&size=${size}`;
    },
    placeholderSize() {
      const { size } = this;
      return createPlaceholderSize(size, size);
    },
  },
}
</script>
