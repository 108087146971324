export const MUTATION_PREFIX = 'CHAT_TEST_QUERY_';
export const ACTIONS_PREFIX = 'CHAT_TEST_QUERY_';
export const GETTERS_PREFIX = 'CHAT_TEST_QUERY_';

export default {
  MUTATIONS: {
    
    RESULT: MUTATION_PREFIX + 'RESULT',

    },
  ACTIONS: {
    //FIND_CORNERS: ACTIONS_PREFIX + 'FIND_CORNERS',
    GET_RESULT: ACTIONS_PREFIX +"GET_RESULT",
    SET_RESULT:ACTIONS_PREFIX +"SET_RESULT"
    
  },
  GETTERS: {
    
    RESULT:GETTERS_PREFIX + 'RESULT',
  }
}