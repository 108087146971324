var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "modal",
    attrs: { showDefaultButtons: false },
    on: {
      close: _vm.onClose,
      "action-CLOSE": function($event) {
        return _vm.saveConfigEdited()
      },
      onCloseButton: function($event) {
        return _vm.saveConfigEdited()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "modalTitle",
        fn: function() {
          return [
            _c(
              "div",
              {
                staticClass:
                  "dashboard-panel-config-modal--title-container clearfix"
              },
              [
                _c(
                  "span",
                  { staticClass: "dashboard-panel-config-modal--title" },
                  [_vm._v("Verwalten")]
                )
              ]
            )
          ]
        },
        proxy: true
      },
      {
        key: "default",
        fn: function() {
          return [
            _c("div", [
              _vm.localData && _vm.localData.length
                ? _c(
                    "div",
                    {
                      staticClass: "dashboard-panel-config__to-added__container"
                    },
                    [
                      _c(
                        "ul",
                        {
                          staticClass:
                            "dashboard-panel-config__items list-bordered"
                        },
                        [
                          _vm._l(_vm.localData, function(card) {
                            return [
                              _vm.isVisible(card)
                                ? _c(
                                    "li",
                                    {
                                      key: card.title,
                                      staticClass:
                                        "dashboard-panel-config__item list-bordered-item"
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "dashboard-panel-config__item--label"
                                        },
                                        [_vm._v(_vm._s(card.title))]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "dashboard-panel-config__item--actions"
                                        },
                                        [
                                          _vm.isRemovable(card)
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn-clear clickable",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.remove(card)
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("PhTrashSimple", {
                                                    attrs: { size: 16 }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          })
                        ],
                        2
                      )
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "dashboard-panel-config__to-add__container" },
                [
                  _c("h3", { staticClass: "box__title" }, [_vm._v("Auswahl")]),
                  _c(
                    "ul",
                    {
                      staticClass: "dashboard-panel-config__items list-bordered"
                    },
                    [
                      _vm._l(_vm.localData, function(card, index) {
                        return [
                          card.removed
                            ? _c(
                                "li",
                                {
                                  key: index,
                                  staticClass:
                                    "dashboard-panel-config__item list-bordered-item"
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "dashboard-panel-config__item--label"
                                    },
                                    [_vm._v(_vm._s(card.title))]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "dashboard-panel-config__item--actions"
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn-clear clickable",
                                          attrs: { type: "button" },
                                          on: {
                                            click: function($event) {
                                              return _vm.add(card)
                                            }
                                          }
                                        },
                                        [_c("PhPlus")],
                                        1
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      })
                    ],
                    2
                  )
                ]
              ),
              !_vm.localData.length
                ? _c("div", { staticClass: "text-centered" }, [
                    _vm._v("Keine Daten")
                  ])
                : _vm._e()
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }