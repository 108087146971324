var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.vertragsdatenFieldsDefinition
    ? _c("div", [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12 col-sm-6" },
            [
              _c("ComboBox", {
                attrs: { label: "Art", values: _vm.artValues },
                on: {
                  change: function($event) {
                    return _vm.insuranceObjectPropertyDataChanged(
                      "versichertesObjekt",
                      "verssache",
                      $event
                    )
                  }
                },
                model: {
                  value: _vm.versicherung.versichertesObjekt.verssache,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.versicherung.versichertesObjekt,
                      "verssache",
                      $$v
                    )
                  },
                  expression: "versicherung.versichertesObjekt.verssache"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12 col-sm-6" },
            [
              _c("InputField", {
                attrs: { label: "Zusatz" },
                on: {
                  input: function($event) {
                    return _vm.insuranceObjectPropertyDataChanged(
                      "versichertesObjekt",
                      "zusatz",
                      $event
                    )
                  }
                },
                model: {
                  value: _vm.versicherung.versichertesObjekt.zusatz,
                  callback: function($$v) {
                    _vm.$set(_vm.versicherung.versichertesObjekt, "zusatz", $$v)
                  },
                  expression: "versicherung.versichertesObjekt.zusatz"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12 col-sm-6" },
            [
              _c("InputField", {
                attrs: { label: "Straße" },
                on: {
                  input: function($event) {
                    return _vm.insuranceObjectPropertyDataChanged(
                      "versichertesObjekt",
                      "strasse",
                      $event
                    )
                  }
                },
                model: {
                  value: _vm.versicherung.versichertesObjekt.strasse,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.versicherung.versichertesObjekt,
                      "strasse",
                      $$v
                    )
                  },
                  expression: "versicherung.versichertesObjekt.strasse"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12 col-sm-6" },
            [
              _c("InputField", {
                attrs: { label: "Hausnummer" },
                on: {
                  input: function($event) {
                    return _vm.insuranceObjectPropertyDataChanged(
                      "versichertesObjekt",
                      "hausnr",
                      $event
                    )
                  }
                },
                model: {
                  value: _vm.versicherung.versichertesObjekt.hausnr,
                  callback: function($$v) {
                    _vm.$set(_vm.versicherung.versichertesObjekt, "hausnr", $$v)
                  },
                  expression: "versicherung.versichertesObjekt.hausnr"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12 col-sm-6" },
            [
              _c("InputField", {
                attrs: { label: "PLZ" },
                on: {
                  input: function($event) {
                    return _vm.insuranceObjectPropertyDataChanged(
                      "versichertesObjekt",
                      "plz",
                      $event
                    )
                  }
                },
                model: {
                  value: _vm.versicherung.versichertesObjekt.plz,
                  callback: function($$v) {
                    _vm.$set(_vm.versicherung.versichertesObjekt, "plz", $$v)
                  },
                  expression: "versicherung.versichertesObjekt.plz"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12 col-sm-6" },
            [
              _c("InputField", {
                attrs: { label: "Ort" },
                on: {
                  input: function($event) {
                    return _vm.insuranceObjectPropertyDataChanged(
                      "versichertesObjekt",
                      "ort",
                      $event
                    )
                  }
                },
                model: {
                  value: _vm.versicherung.versichertesObjekt.ort,
                  callback: function($$v) {
                    _vm.$set(_vm.versicherung.versichertesObjekt, "ort", $$v)
                  },
                  expression: "versicherung.versichertesObjekt.ort"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12 col-sm-6" },
            [
              _c("InputField", {
                attrs: { label: "Land" },
                on: {
                  input: function($event) {
                    return _vm.insuranceObjectPropertyDataChanged(
                      "versichertesObjekt",
                      "land",
                      $event
                    )
                  }
                },
                model: {
                  value: _vm.versicherung.versichertesObjekt.land,
                  callback: function($$v) {
                    _vm.$set(_vm.versicherung.versichertesObjekt, "land", $$v)
                  },
                  expression: "versicherung.versichertesObjekt.land"
                }
              })
            ],
            1
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }