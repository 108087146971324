var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isInputField(_vm.header, _vm.content)
    ? _c(
        "div",
        [
          _c("InputField", {
            on: {
              input: function($event) {
                return _vm.$emit("input", { header: _vm.header, value: $event })
              }
            },
            model: {
              value: _vm.content.value,
              callback: function($$v) {
                _vm.$set(_vm.content, "value", $$v)
              },
              expression: "content.value"
            }
          })
        ],
        1
      )
    : _vm.header.dataType === "String" &&
      _vm.content &&
      Object.keys(_vm.content).some(function(key) {
        return key === "value"
      })
    ? _c(
        "div",
        {
          class: [_vm.header.align ? "text-" + _vm.header.align : "text-left"]
        },
        [_vm._v(" " + _vm._s(_vm.content.value) + " ")]
      )
    : _vm.header.dataType === "String"
    ? _c(
        "div",
        {
          class: [_vm.header.align ? "text-" + _vm.header.align : "text-left"]
        },
        [_vm._v(" " + _vm._s(_vm.content) + " ")]
      )
    : _vm.header.dataType === "Link"
    ? _c("div", { staticClass: "text-left" }, [
        _c("a", { attrs: { href: _vm.content, target: "_blank" } }, [
          _vm._v(_vm._s(_vm.content))
        ])
      ])
    : _vm.header.dataType === "Currency"
    ? _c(
        "div",
        {
          staticClass: "currency-collumn",
          class: [_vm.keepTextLeft ? "text-left" : "text-right"]
        },
        [
          _vm.isNumber(_vm.content || 0)
            ? _c(
                "span",
                [
                  _c("CurrencyLabel", {
                    attrs: { value: _vm.strToNumber(_vm.content) }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      )
    : _vm.header.dataType === "CurrencyNoLabel"
    ? _c(
        "div",
        {
          staticClass: "currency-collumn",
          class: [_vm.keepTextLeft ? "text-left" : "text-right"]
        },
        [
          _vm.isNumber(_vm.content || 0)
            ? _c(
                "span",
                [
                  _c("CurrencyLabel", {
                    attrs: { value: _vm.strToNumber(_vm.content), symb: "" }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      )
    : _vm.header.dataType === "NumberInt" || _vm.header.dataType === "Integer"
    ? _c("div", { class: [_vm.keepTextLeft ? "text-left" : "text-right"] }, [
        _vm._v(" " + _vm._s(_vm._f("numberInt")(_vm.content)) + " ")
      ])
    : _vm.header.dataType === "Number" || _vm.header.dataType === "Double"
    ? _c("div", { class: [_vm.keepTextLeft ? "text-left" : "text-right"] }, [
        _vm._v(" " + _vm._s(_vm._f("number")(_vm.content)) + " ")
      ])
    : _vm.header.dataType === "Procent"
    ? _c("div", { class: [_vm.keepTextLeft ? "text-left" : "text-right"] }, [
        _vm.isNumber(_vm.content)
          ? _c(
              "span",
              [
                _c("CurrencyLabel", {
                  attrs: { value: _vm.content, symb: "%" }
                })
              ],
              1
            )
          : _c("span", [_vm._v(_vm._s(_vm.content))])
      ])
    : /Decimal\d.*/.test(_vm.header.dataType)
    ? _c("div", { class: [_vm.keepTextLeft ? "text-left" : "text-right"] }, [
        _vm._v(
          " " + _vm._s(_vm.getDecimal(_vm.content, _vm.header.dataType)) + " "
        )
      ])
    : _vm.header.dataType === "DateYMD"
    ? _c("div", [_vm._v(" " + _vm._s(_vm._f("dateYMD")(_vm.content)) + " ")])
    : _vm.header.dataType === "ComboBoxSelection"
    ? _c("div", { class: [_vm.keepTextLeft ? "text-left" : "text-right"] }, [
        _vm._v(" " + _vm._s(_vm.comboBoxLabel(_vm.header, _vm.content)) + " ")
      ])
    : _vm.header.dataType === "Image"
    ? _c("div", { staticClass: "text-left" }, [
        _c("div", { staticClass: "image", style: { background: _vm.content } })
      ])
    : _vm.header.dataType === "Action"
    ? _c("div", [_vm._t("default")], 2)
    : _vm.header.dataType === "tableAction"
    ? _c(
        "div",
        [
          _c("TableActions", {
            attrs: {
              element: _vm.row,
              actions: _vm.actions,
              rowIndex: _vm.rowIndex
            },
            on: { "execute-action": _vm.clickAction }
          })
        ],
        1
      )
    : _vm.header.dataType === "Slot"
    ? _c(
        "div",
        [
          _vm._t(_vm.header.key, null, {
            data: { tableData: _vm.tableData, row: _vm.row }
          })
        ],
        2
      )
    : _vm.header.dataType === "Company"
    ? _c("div", [
        _vm.content.logoUrl
          ? _c("img", {
              attrs: {
                alt: _vm.content.shortName,
                src: _vm.apiAddress + _vm.content.logoUrl,
                title: _vm.content.shortName,
                width: "40"
              }
            })
          : _vm._e()
      ])
    : _vm.header.dataType === "Boolean"
    ? _c(
        "div",
        [
          _c("InputCheckBoxItem", {
            staticClass: "my-0",
            attrs: { value: _vm.content, checked: _vm.content },
            on: {
              input: function($event) {
                return _vm.$emit("selected-cell", $event)
              }
            }
          })
        ],
        1
      )
    : _c("div", [
        typeof _vm.content === "string" && _vm.content
          ? _c("div", [_vm._v(" " + _vm._s(_vm.content) + " ")])
          : _c("div", [_c("p")])
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }