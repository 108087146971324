var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "uploadModal",
      attrs: {
        modalTitle: _vm.modalTitle,
        labelButtonConfirm: _vm.labelButtonConfirm,
        confirmDisabled: !_vm.files.length || _vm.confirmDisabled
      },
      on: { onConfirmButton: _vm.uploadDocument }
    },
    [
      _vm.cameraAvailable && !_vm.disableFotoUpload
        ? _c("FotoButton", {
            staticClass: "mb-2",
            staticStyle: { float: "left" },
            attrs: {
              isSecondary: "",
              edgeCorrection: "",
              highQuality: "",
              camera: "back"
            },
            on: { file: _vm.onFoto }
          })
        : _vm._e(),
      !_vm.disableMultifile
        ? _c("MultiFileSelect", {
            attrs: { value: _vm.files },
            on: { input: _vm.dropFiles }
          })
        : _c("BaseFileSelect", {
            staticClass: "mb-2",
            on: { files: _vm.dropFiles }
          }),
      _vm.files && _vm.files.length
        ? _c("div", [
            _c("hr"),
            _c("div", { staticClass: "flex-layout" }, [
              _c(
                "div",
                { staticClass: "pill__container" },
                [
                  _c("Pill", {
                    attrs: {
                      label: _vm.files[0].name || _vm.files[0].filename,
                      type: "default",
                      showCloseIcon: ""
                    },
                    on: {
                      removePill: function($event) {
                        return _vm.dropFiles([])
                      }
                    }
                  })
                ],
                1
              )
            ]),
            _c("hr")
          ])
        : _vm._e(),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }