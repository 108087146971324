<template>
    <div>
        <PageHeaderTitleNavigation title="Änderungslog" />

        <BaseFilter 
            filterId="2fc8bb01-73b9-4b9b-99bc-b591d586acc3"
            title="Suchen" 
            :metadata="filterMetadata"
            :defaultOptions="defaultOptionsData" 
            @onFilter="filterForm($event)"
            immidiateSearch>
        </BaseFilter>

         <div v-if="filtered" class="box__container">
             <InputCheckboxItem v-if="typ =='vers'"
             v-model="mitSchadensmeldung"
             label="mit Schadensmeldung"/>
            <Table class="table-container" v-if="!loading && rows && rows.length"
                :title="typ ? TABLE_TITLE_VERMITTLER_KUNDEN : TABLE_TITLE"
                tableId="86ecad57-0325-44c3-b6e8-00a1364e7e7d"
                configModalTitle="'Änderungslog für Maklerauftrag"
                 :headers="headers"
                 :rows="rows"
                 :rowsPerPage="25"
                @click-aeunderung="loadAenderung($event)"
            >
           
            </Table>
            <GhostLoading v-else-if="loading" type="table" :title="typ ? TABLE_TITLE_VERMITTLER_KUNDEN : TABLE_TITLE" />
            <NoData v-else :title="typ ? TABLE_TITLE_VERMITTLER_KUNDEN : TABLE_TITLE" />
        </div>
           <BaseModal ref="infoModal" :showDefaultButtons="false">
          <template v-slot:modalTitle>
             Änderung:
          </template>
          <div>
              <span>{{fullText}}</span>
          </div>
      </BaseModal>
    </div>
</template>

<script>

import Table from "@/components/table2/Table.vue";
import {TextColumn, DateTimeColumn} from "@/components/table2/table_util.js";
import AUFTRAEGE_TYPES  from '@/store/maklerauftraege/types';
import CORE_TYPES from '@/store/core/types';
import { mapGetters } from 'vuex';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import BaseFilter from "@/components/core/BaseFilter.vue";
import dayjs from "dayjs";
import InputCheckboxItem from "@/components/core/forms/checkbox/InputCheckBoxItem.vue";
import BaseModal from '@/components/core/BaseModal.vue';

const TABLE_TITLE = 'Änderungslog für Maklerauftrag';
const TABLE_TITLE_VERMITTLER_KUNDEN =  'Änderungslog';
export default {
    props:{
        typ: {
            type: String
        }
    },
    components: {
        Table,
        OptionMenu,
        PageHeaderTitleNavigation,
        GhostLoading,
        NoData,
        BaseFilter,
        InputCheckboxItem,
        BaseModal
    },
    data() {
        return {
            TABLE_TITLE,
            TABLE_TITLE_VERMITTLER_KUNDEN,
            rowsPerPage: 20,
            loading: false,
            filtered: false,
            bemerkungFurStatus: '',
            statusData: {},
            uebertragVertragId: null,
            mitSchadensmeldung:false,
            fullText:'',
        }
    },
    mounted() {
        
    },
    computed: {
        ...mapGetters({
            isFA: CORE_TYPES.GETTERS.IS_FA,
            hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
            aenderungsLog: AUFTRAEGE_TYPES.GETTERS.AENDERUNGS_LOG,
        }),
        defaultOptionsData(){
            return {
                'DATUM':  dayjs().subtract(1, "month").format("DD.MM.YYYY")
            }
        },
        filterMetadata() {
            const filterdata = (this.typ == 'vermittlerdaten' || this.typ == 'kundendaten' || this.typ =='vers') ? 
             [{
                 type: 'group',
                    key: 'allgemein',
                    label: 'Allgemein',
                    menuItems: [
                        {
                            type: 'datepicker',
                            label: 'Ab Datum',
                            key: 'DATUM',
                        },
                        {
                            type: 'text',
                            label: 'Suchen',
                            key: 'FILTER',
                        },
                    ]
             }] 
             :
             [
                {
                    type: 'group',
                    key: 'allgemein',
                    label: 'Allgemein',
                    menuItems: [
                        {
                            type: 'text',
                            label: 'Suchen',
                            key: 'FILTER',
                        },
                    ]
                },

            ]
            return filterdata;
        },

        headers() {

            let result = {
              lockedLeft: [
                  DateTimeColumn("datum", "Datum"),
              ],
              center: [
                  TextColumn("nutzer", "Nutzer"),
                  TextColumn("eintrag", "Eintrag"),
              ],
              lockedRight: [
                TextColumn("aeunderung", "Änderung").makeLink()
              ],
            };

            return result;
        },
        rows() {
            return this.aenderungsLog;
        },
    },
    methods: {
        async filterForm(filters = {}) {
            try {
                this.filtered = true;
                this.loading = true;
                const allgemein = filters.filter(fc => fc.group == "allgemein"); 
                const datumAb = allgemein && allgemein.find(f=>f.key == 'DATUM')?.value || '';
                const payload = {
                    searchkeyId : this.$route.query?.versUebertragId,
                    filter: allgemein && allgemein.find(f=>f.key == 'FILTER')?.value,
                    datum: datumAb instanceof Date ? this.formatDate(datumAb) : datumAb,
                    typ: this.typ,
                    versVetragId:  this.$route.params.id,
                    mitSchadensmeldung: this.mitSchadensmeldung
                }
                
                if(this.typ == 'vers'){
                     filters.searchkeyId = this.$route.params.id;
                }else{
                    filters.searchkeyId = this.$route.query?.versUebertragId;
                }
                await this.$store.dispatch(AUFTRAEGE_TYPES.ACTIONS.GET_AENDERUNGS_LOG, payload); 
            } finally {
                this.loading = false;
            }
        },
        retrieveData() {
            this.$store.dispatch(AUFTRAEGE_TYPES.ACTIONS.GET_AENDERUNGS_LOG, {searchkeyId: this.$route.query?.versUebertragId }).then(response => {
                    console.log(response);
               });
        },
        formatDate(datumAb){
            return dayjs(datumAb).format("DD.MM.YYYY");
        },
        loadAenderung(row){
            this.fullText = row.aeunderung;
            this.$refs.infoModal.open();
        }


    },
    beforeRouteLeave(to, from, next) {
        this.$addBreadcrumb({
            label: 'zurück zu den Änderungslog', 
            to,
            from,
        });
        next()
    }
}
</script>

<style>

</style>