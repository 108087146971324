<template>
  <div>
    <PageHeaderTitleNavigation>
      <template #title>Unterdepot</template>
      <template #subtitle>{{ items && items.input_name || '' }}</template>
    </PageHeaderTitleNavigation>

    <BoxContainer v-if="items" >
      <InputField label="Name" v-model="input_name" isComponentHalfSize/>
      <div class="mb-3">
        <div class="mb-1">Eigenschaften</div>
        <div class="checkbox-input__container">
          <InputCheckBoxItem label="Vermögensverwaltungsdepot" v-model="eigenschaft_vermoegensverwaltung" />
          <InputCheckBoxItem label="Minderjährigendepot" v-model="eigenschaft_minderjaehrig" />
          <InputCheckBoxItem label="verpfändet" v-model="eigenschaft_verpfaendet" />
          <InputCheckBoxItem label="Garantiedepot" v-model="eigenschaft_garantiedepot" />
          <InputCheckBoxItem label="§14 ESTG" v-if="eigenschaft_para14_ekst !== undefined" v-model="eigenschaft_para14_ekst" />
          <InputCheckBoxItem label="Verlassenschaft" v-if="eigenschaft_verlassenschaft !== undefined" v-model="eigenschaft_verlassenschaft" />
        </div>
      </div>
      <InputTextArea label="Beschreibung" v-model="input_beschreibung" isComponentHalfSize/>
    </BoxContainer>
    <GhostLoading v-else useBoxContainer>
      <Block height="264" />
    </GhostLoading>
    <div class="box__container" v-if="rowsCount">
      <InputToggleSwitch
        v-model="nurMitAnteilen"
        label="Nur Depotpositionen mit Anteilen"
        inLineLabel
        />
      <Table v-if="rowsFonds.length"
          :headers="headersFonds"
          :rows="rowsFonds"
          rowId="index"
          :rowsPerPage="20"
          :mobileConfig="{title: 'lagerstelle', headers: ['fondsname', 'wert', 'verwendet']}"
      >
        <template v-slot:zuordnen="row">
          <InputCheckBoxItem v-model="row.zuordnen" @input="chgZuord(row)"/>
        </template>
      </Table>
    </div>
    
    <div class="box__container"  v-if="items">
      <div class="box__title">Automatisch diesem Depot zuordnen</div>
      <BaseButton class="mt-2" isSecondary @click="showAutozuord()">Neue Autozuordnung</BaseButton>
      <Table v-if="rowsAuto.length"
          :headers="headersAuto"
          :rows="rowsAuto"
          rowId="id"
          hidePagination
          :mobileConfig="{title: 'lagerstelle', headers: ['depot_nr']}"
          @action-DELETE="delAutozuord"
          @action-EDIT="showAutozuord"
          @click-lagerstelle="showAutozuord($event)"
      >
      </Table>
      <NoData v-else  />
    </div>
    <BaseModal
      ref="depotAutoZuord"
      modalTitle="Depot automatisch zuordnen"
      @onConfirmButton="saveAutozuord" >
        <template v-slot>
          <HinweiseUndFehler :hints="[{title: 'Depotnummer', message: 'Falls Sie die Depotnummer leer lassen, werden alle eingehenden Bestände dieser Lagerstelle dem Depot zugewiesen.'}]" />
          <ComboBox v-model="lagerstelleId" label="Lagerstelle" :values="lagerstellen" />
          <InputField v-model="depotnummer" label="Depotnummer" />
        </template>
    </BaseModal>
    <BaseModal
      ref="virtuellesDepot"
      modalTitle="Neues virtuelles Depot"
      :autoClose="false"
      @onConfirmButton="createVDepot"
      @close="closeVDepot" >
        <template v-slot>
          <InputField ref="depotname" label="Depotname" v-model="input_name"/>
        </template>
    </BaseModal>
  </div>
</template>

<script>
import VIRTUALDEPOTS_TYPES from "@/store/virtualsubdepots/types";
import { mapGetters } from 'vuex';
import BaseModal from "@/components/core/BaseModal.vue";
import BoxContainer from '@/components/core/BoxContainer.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import InputCheckBoxItem from '@/components/core/forms/checkbox/InputCheckBoxItem.vue';
import ComboBox from '@/components/core/forms/ComboBox';
import InputField from '@/components/core/forms/InputField';
import InputTextArea from '@/components/core/forms/InputTextArea';
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import Table from '@/components/table2/Table.vue';
import {TextColumn, SlotColumn, CurrencyColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import { PhTrash, PhPencil } from 'phosphor-vue';
import HinweiseUndFehler from "@/components/core/HinweiseUndFehler.vue";
import NoData from '@/components/core/NoData.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import validator from "@/mixins/validator";
import { required } from "@/mixins/validator/rules";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';

export default {
  components: {
    BaseModal, BoxContainer, BaseButton, ComboBox, InputCheckBoxItem, InputToggleSwitch,
    InputField, InputTextArea, Table, GhostLoading, Block, NoData, HinweiseUndFehler, OptionMenu, PageHeaderTitleNavigation,
  },
  mixins: [
    validator
  ],
  data: function() {
    return {
      id: null,
      idx: 0,
      input_name: '',
      eigenschaft_vermoegensverwaltung: null,
      eigenschaft_minderjaehrig: null,
      eigenschaft_verpfaendet: null,
      eigenschaft_garantiedepot: null,
      eigenschaft_para14_ekst: null,
      eigenschaft_verlassenschaft: null,
      input_beschreibung: null,
      nurMitAnteilen: true,
      autozuord: {},
      daz_id: null,
      lagerstelleId: null,
      depotnummer: null,
      headersAuto: {
        lockedLeft: [
          TextColumn("lagerstelle", "Lagerstelle").makeAlwaysVisible().makeLink(),
        ],
        center: [
          TextColumn("depot_nr", "Depotnummer", 150),
        ],
        lockedRight: [
          ActionColumn("actions", ""),
        ],
      },
      headersFonds: {
        lockedLeft: [
          SlotColumn("zuordnen", "Zuordnen", 100).makeAlwaysVisible().makeSortable(),
        ],
        center: [
          TextColumn("verwendet", "aktuelle Depotzuordnung"),
          TextColumn("kontonr", "Kontonr"),
          TextColumn("isin", "ISIN"),
          TextColumn("fondsname", "Fondsname"),
          TextColumn("lagerstelle", "Lagerstelle"),
          CurrencyColumn("wert", "Wert")
        ],
        lockedRight: [
        ],
      }
    }
  },
  validators: {
    input_name: [required('Bitte einen Depotname eingeben', {forceTouch: false}), {
      isValid: (value, self) => self.isFieldValid('input_name', value),
      getMessage: (key, value, self) => self.getFieldError('input_name'),
    }],
  },
  computed: {
    ...mapGetters({
      vdepot: VIRTUALDEPOTS_TYPES.GETTERS.GET_VD,
    }),
    subDepot() {
      return this.id == -1 ? null : this.vdepot.data;
    },
    items() {
      return this.subDepot?.items ;
    },
    lagerstellen() {
      return this.subDepot?.lagerstellen || [];
    },
    rowsCount() {
      return this.subDepot  ? this.rowsFonds?.length : null;
    },
    rowsAuto() {
      if (this.subDepot?.rowsAuto?.length) {
        return this.subDepot?.rowsAuto.map(row => ({...row, actions: [SimpleAction("DELETE", PhTrash, "Löschen"), SimpleAction("EDIT", PhPencil, "Bearbeiten")]}))
      }
      return [];
    },
    rowsFonds() {
      if (this.subDepot?.rowsFonds?.length) {
        return this.subDepot.rowsFonds.filter(r => this.nurMitAnteilen ? r.wert !== 0 : true)
      }
      return [];
    },
  },
  watch: {
    vdepot: {
      handler(){
        if ( this.vdepot.id > 0 && this.id == -1 ) {
          this.id = this.vdepot.id;
          this.$refs.virtuellesDepot.close();
        }
      },
      deep: true,
    },
    items() {
      this.input_name = this.items?.input_name
      this.eigenschaft_vermoegensverwaltung = this.items?.eigenschaft_vermoegensverwaltung
      this.eigenschaft_minderjaehrig = this.items?.eigenschaft_minderjaehrig
      this.eigenschaft_verpfaendet = this.items?.eigenschaft_verpfaendet
      this.eigenschaft_garantiedepot = this.items?.eigenschaft_garantiedepot
      this.eigenschaft_para14_ekst = this.items?.eigenschaft_para14_ekst
      this.eigenschaft_verlassenschaft = this.items?.eigenschaft_verlassenschaft
      this.input_beschreibung = this.items?.input_beschreibung
      this.nurMitAnteilen = true
    }
  },
  mounted() {
    this.id = this.$route.params?.id ? parseInt( this.$route.params?.id, 10) : -1;
    if (this.id) {
      if (this.id == -1 ) {
        this.$store.commit(VIRTUALDEPOTS_TYPES.MUTATIONS.SET_VD, {id: this.id });
        this.$refs.virtuellesDepot.open();
      } else if (this.vdepot.id != this.id) {
        this.$store.commit(VIRTUALDEPOTS_TYPES.MUTATIONS.SET_VD, {id: this.id });
        this.$store.dispatch(VIRTUALDEPOTS_TYPES.ACTIONS.GET_VD);
      } else {
        this.$store.dispatch(VIRTUALDEPOTS_TYPES.ACTIONS.GET_VD);
      }
    } else {
        this.$store.commit(VIRTUALDEPOTS_TYPES.MUTATIONS.SET_VD, {id: 0 });
    }
  },
  async beforeRouteLeave(to, from, next) {
    const params = {};
    const items = {};
    if (this.eigenschaft_vermoegensverwaltung != this.items?.eigenschaft_vermoegensverwaltung) {
      items.eigenschaft_vermoegensverwaltung = this.eigenschaft_vermoegensverwaltung;
    }
    if (this.eigenschaft_minderjaehrig != this.items?.eigenschaft_minderjaehrig) {
      items.eigenschaft_minderjaehrig = this.eigenschaft_minderjaehrig;
    }
    if (this.eigenschaft_verpfaendet != this.items?.eigenschaft_verpfaendet) {
      items.eigenschaft_verpfaendet = this.eigenschaft_verpfaendet;
    }
    if (this.eigenschaft_garantiedepot != this.items?.eigenschaft_garantiedepot) {
      items.eigenschaft_garantiedepot = this.eigenschaft_garantiedepot;
    }
    if (this.eigenschaft_para14_ekst != this.items?.eigenschaft_para14_ekst) {
      items.eigenschaft_para14_ekst = this.eigenschaft_para14_ekst;
    }
    if (this.eigenschaft_verlassenschaft != this.items?.eigenschaft_verlassenschaft) {
      items.eigenschaft_verlassenschaft = this.eigenschaft_verlassenschaft;
    }
    if (this.input_beschreibung != this.items?.input_beschreibung) {
      items.input_beschreibung = this.input_beschreibung;
    }
    if (this.input_name != this.items?.input_name) {
      items.input_name = this.input_name;
    }
    if ( Object.keys(items).length) {
      params.items = items;
    }
    
    const zuordnen = [];
    this.rowsFonds.forEach(row => {
      if ( row.zuordnen != row._zuordnen) {
        zuordnen.push({zuordnen: row.zuordnen, kontonr: row.kontonr, bgs_vw: row.bgs_vw});
      }
    })
    if (zuordnen.length) {
      params.zuordnen = zuordnen;
    }
    if ( Object.keys(params).length) {
      params.id = this.id;
      // console.log('params', JSON.parse(JSON.stringify(params)));
      await this.$store.dispatch(VIRTUALDEPOTS_TYPES.ACTIONS.SAVE_VD, params);
    }

    next()
  },
  methods: {
    showAutozuord(row) {
      this.autozuord = row ? row : {daz_id: -1, depot_nr: '', lagerstelleId: ''}
      this.lagerstelleId = this.autozuord.lagerstelleId || '';
      this.depotnummer = this.autozuord.depot_nr || '';
      this.daz_id = this.autozuord.daz_id || -1;
      this.$refs.depotAutoZuord.open();
    },
    saveAutozuord() {
      if (this.lagerstelleId != this.autozuord.lagerstelleId || this.depotnummer != this.autozuord.depot_nr) {
        if (!this.lagerstelleId) {
          return;
        }
        this.$store.dispatch(VIRTUALDEPOTS_TYPES.ACTIONS.SAVE_AUTO, {
          id: this.id, 
          daz_id: this.daz_id, 
          depot_nr: this.depotnummer, 
          lagerstelle: this.lagerstelleId}
        );
      }
    },
    createVDepot() {
      if (!this.input_name) {
        this.$refs.depotname.$props.validateUntouched = true;
        return;
      }
      this.$store.dispatch(VIRTUALDEPOTS_TYPES.ACTIONS.CREATE_VD, {name: this.input_name} );
    },
    closeVDepot() {
      if (this.id == -1 ){
        this.$router.go(-1);
      }
    },
    delAutozuord(row) {
      this.$store.dispatch(VIRTUALDEPOTS_TYPES.ACTIONS.DEL_AUTO, {
        id: this.id, 
        daz_id: row.daz_id}
      );
    },
    chgZuord(row) {
      const zeile = this.rowsFonds.find(r => r.index === row.index);
      if (zeile) {
        zeile.zuordnen = row.zuordnen;
      }
    },
  }
  
}
</script>

<style scoped>
.btn-clean {
  color: var(--color-text);
}
::v-deep .no-data__container .no-data__icon  .no-data__icon--wrap {
  max-width: 150px;
}
</style>
