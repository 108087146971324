var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "formulare-list__container" },
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Wertpapiere für " + (_vm.selectedMusterdepot.label || ""),
          actions: _vm.headerActions
        },
        on: {
          "action-ADD": function($event) {
            return _vm.addFond()
          },
          "action-FONDSVERGLEICH": function($event) {
            return _vm.openFondsvergleich()
          },
          "action-IMPORT": function($event) {
            return _vm.$refs.importWertpapiereModal.open()
          }
        }
      }),
      _c(
        "div",
        {
          staticClass: "box__container",
          attrs: { tid: _vm._generateTidFromString("box__container") }
        },
        [
          _c("InputField", {
            attrs: {
              disabled: _vm.loading,
              isComponentHalfSize: "",
              placeholder: "Suchen nach Wertpapier / Kategorie / ISIN"
            },
            model: {
              value: _vm.descriptionSearch,
              callback: function($$v) {
                _vm.descriptionSearch = $$v
              },
              expression: "descriptionSearch"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.rows && _vm.rows.length
            ? _c("Table", {
                attrs: {
                  rowId: "isin",
                  rowsPerPage: 20,
                  headers: _vm.headers,
                  rows: _vm.rows,
                  headerActions: _vm.tableHeaderActions,
                  exportConfig: _vm.exportConfig,
                  mobileConfig: {
                    title: "bezeichnung",
                    headers: ["kategorie", "isin", "anteilProzent"]
                  }
                },
                on: {
                  "click-bezeichnung": function($event) {
                    return _vm.gotoFondsinfo($event.isin)
                  },
                  "action-DELETE": function($event) {
                    return _vm.deleteWertpapier([$event])
                  },
                  "headerAction-BULK_DELETE": function($event) {
                    return _vm.deleteWertpapier(_vm.selectedRows)
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "fondsAmpel",
                      fn: function(row) {
                        return [
                          _c("div", {
                            class: row.fondsAmpel,
                            staticStyle: { "margin-left": "11px" }
                          })
                        ]
                      }
                    },
                    {
                      key: "anteilProzent",
                      fn: function(row) {
                        return [
                          !row.mobileTableContext
                            ? _c("InputField", {
                                staticStyle: { width: "100px" },
                                attrs: {
                                  type: "percent",
                                  precision: 2,
                                  id: "anteilProzent" + row.index,
                                  value: row.anteilProzent
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.updateAnteilProzent(row, $event)
                                  }
                                }
                              })
                            : _c("span", [
                                _vm._v(_vm._s(row.anteilProzent || 0) + " %")
                              ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3848666190
                ),
                model: {
                  value: _vm.selectedRows,
                  callback: function($$v) {
                    _vm.selectedRows = $$v
                  },
                  expression: "selectedRows"
                }
              })
            : _vm._e(),
          _vm.rows && _vm.rows.length
            ? _c("div", [
                _c("div", { staticClass: "box__title" }, [
                  _vm._v(" Prozent gesamt: "),
                  _c(
                    "span",
                    { class: { "color-danger": _vm.percentSum != 100 } },
                    [_vm._v(_vm._s(_vm.percentSum))]
                  )
                ]),
                _c("div", { staticClass: "box__title" }, [
                  _vm._v(
                    " Durchschnittliche SRI: " + _vm._s(_vm.averageSRI) + " "
                  )
                ])
              ])
            : !_vm.loading
            ? _c("NoData")
            : _c("GhostLoading", {
                attrs: { type: "table", config: { table: { rows: 20 } } }
              })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "importWertpapiereModal",
          attrs: { labelButtonCancel: "Zurück", labelButtonConfirm: "Ok" },
          on: {
            onConfirmButton: function($event) {
              return _vm.importWertpapiere()
            },
            close: function($event) {
              return _vm.resetImportData()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [_vm._v(" Wertpapiere importieren ")]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "DragnDropArea",
            {
              attrs: { hoverText: "Hochladen" },
              on: { files: _vm.onFileChange }
            },
            [
              _vm._v(" ISIN-Liste:"),
              _c("br"),
              _c(
                "BaseFileSelect",
                {
                  class: { "mb-3": !_vm.importData.file },
                  attrs: { accept: ".txt" },
                  on: { files: _vm.onFileChange }
                },
                [_vm._v(" Textdatei hochladen ")]
              ),
              _c("div", [
                _vm.importData.file
                  ? _c(
                      "div",
                      { staticClass: "input-chip" },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.importData.file &&
                                _vm.importData.file.fileName
                            )
                          )
                        ]),
                        _c("ph-x-circle", {
                          staticClass: "clickable ml-1",
                          attrs: { size: 16 },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              _vm.importData.file = null
                            }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]),
              _c("div", { staticClass: "mb-3" }, [
                _c("b", [_vm._v("Dateiformat:")]),
                _vm._v(" Textdatei (.txt)"),
                _c("br"),
                _c("b", [_vm._v("Dateiaufbau:")]),
                _c("br"),
                _c("b", [_vm._v("Beispiel 1:")]),
                _c("br"),
                _vm._v(" ISIN 1"),
                _c("br"),
                _vm._v(" ISIN 2"),
                _c("br"),
                _vm._v(" ISIN ..."),
                _c("br"),
                _c("b", [_vm._v("Beispiel 2:")]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" ISIN 1, ISIN 2, ISIN ..."),
                _c("br")
              ]),
              _c("InputToggleSwitch", {
                staticClass: "mt-2",
                staticStyle: { "padding-bottom": "0px" },
                attrs: {
                  label: "<b>Alle</b> Wertpapiere ersetzen",
                  inLineLabel: ""
                },
                model: {
                  value: _vm.importData.replace,
                  callback: function($$v) {
                    _vm.$set(_vm.importData, "replace", $$v)
                  },
                  expression: "importData.replace"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }