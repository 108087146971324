<template>
  <div>
    <div v-if="positions && positions.length">
     <Table
        tableId="092c3a63-545b-4316-8d02-803a6e7d3258"
        v-if="tableData.records && tableData.records.length"
        :tableData="tableData"
        :cardViewEnabled="false"
        :filterEnabled="false"
        :exportEnabled="false"
        :paginationEnabled="false"
        :actions="columnActions"
        :rowsPerPage="tableData.records.length"
        @execute-action="handleTableAction($event)"
      >
        <template v-slot:betrag="props">
          <InputField :type="betragType" :value="props.data.row[getBetragId]" :id="'betrag' + props.data.row.index" 
            :placeholder="betragPlaceholder"
            @input="onChangeInputField($event, props.data.row, getBetragId)"/>
        </template>
        <template v-slot:aa="props">
          <InputField type="percent" :value="props.data.row.aa" :id="'aa' + props.data.row.index" :placeholder="props.data.row.defaultAA"
            @input="onChangeInputField($event, props.data.row, 'aa')"/>
        </template>
        <template v-slot:startmonth="props">
          <ComboBox type="startmonth" :value="props.data.row.startmonth" :id="'startmonth' + props.data.row.index" :placeholder="''"
            :values="getMonths()"
            @input="onChangeInputField($event, props.data.row, 'startmonth')"/>
        </template>
      </Table>
    </div>
    <div v-else>
      Wertpapierauswahl ist leer
    </div>
      
    <div class="addPositionBtn" v-if="config && config.buttonText && (!positions || positions.length < maxCount)">
      <BaseButton @click="$emit('fondsfinder')">{{config.buttonText}}</BaseButton>
    </div>
     <div class="color-danger" v-if="config && positions && positions.length > maxCount">
     Es sind maximal {{maxCount}} Fonds erlaubt. Bitte löschen Sie die überzählige Fonds! 
    </div>
    <DeletePositionModal ref="deleteModal" :position="positionToDelete"
      @delete="deletePos()"></DeletePositionModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ANTRAG_TYPES from '@/store/antrag/types';
import FONDSFINDER_TYPES from '@/store/fondsfinder/types';
import InputField from "@/components/core/forms/InputField.vue";
// import WertpapierAuswahlPositionsButtons from '@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlPositionsButtons.vue'
import DeletePositionModal from '@/components/core/forms/antraege/wertpapierAuswahl/DeletePositionModal.vue'
import Table from '@/components/table/Table.vue';
import validator from "@/mixins/validator";
import BaseButton from "@/components/core/BaseButton.vue";
import antragMixin from '@/mixins/antrag/antrag-mixin.js';
import {formatNumber} from '@/helpers/number-formatter.js';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import { position } from '@/mixins/positioning-helper';

export default {
  mixins: [validator, antragMixin],
  validators: {},
  props: {
    positions: {
    },
    config: {
    },
    antragId: {
    },
    categoryId: {
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      columnActions : [
        {
          legend: {
            icon: 'PhTrash',
            index: 1,
            key: 'DELETE',
            label: 'Löschen',
          }
        }
      ],
      positionToDelete : {
      },
    }
  },
  watch: {
    positionWarnings: {
      immediate: true,
      handler(newWarnings) {
        if (newWarnings) {
          for (const warn of newWarnings) {
            if (warn.posGrpId) {
              if (!warn.id || warn.id.includes(this.categoryId)) {
                const index = this.positions && this.positions.findIndex(pos => pos.posGrpId === warn.posGrpId || pos.isin === warn.posGrpId);
                if (index >= 0) {
                  this.$pushErrors('betrag' + index, warn.message || '');
                }
              }
            }
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      positionInfo: FONDSFINDER_TYPES.GETTERS.POSITION_INFO,
      antraege: ANTRAG_TYPES.GETTERS.ANTRAEGE,
    }),
    headers() {
      const hdrs = {
        pos: {
          label: '#',
          key: 'pos',
          dataType: 'String',
          sortable: false,
          filterable: false,
          sum: false,
          visible: this.config?.showIndex,
          fixed: true,
        },
        isin: {
          label: 'ISIN',
          key: 'isin',
          dataType: 'String',
          sortable: false,
          filterable: false,
          sum: false,
          visible: true,
          fixed: true,
        },
        fondsname: {
          label: 'Fondsname',
          key: 'fondsname',
          dataType: 'String',
          sortable: false,
          filterable: false,
          sum: false,
          visible: true,
          fixed: true,
        },
        betrag: {
          label: this.betragHeader,
          key: 'betrag',
          dataType: 'Slot',
          sortable: false,
          filterable: false,
          sum: this.config?.hasSumme,
          visible: !this.config?.hideBetrag,
          fixed: true,
        },
        aa: {
          label: this.aaLabel,
          key: 'aa',
          dataType: 'Slot',
          sortable: false,
          filterable: false,
          sum: false,
          visible: this.config?.individualAA,
          fixed: true,
        },
        actions: {
          label: "",
          key: "actions",
          priority: 1,
          dataType: "tableAction",
          visible: true,
          sortable: false,
          filterable: false,
          sum: false,
          fixed: true,
        }
      };
      if (this.config?.hasStartmonth) {
        hdrs.startmonth = {
          label: this.config.startmonth?.label || 'ab M/J',
          key: 'startmonth',
          dataType: 'Slot',
          sortable: false,
          filterable: false,
          sum: false,
          visible: true,
          fixed: true,
        }
      }
      return hdrs;
    },
    tableData() {

      let headersTemp = this.headers
      let recordsTemp = this.positions?.map((pos, index) => Object.assign(pos, { 
            pos: index + 1,
            fondsname: this.getFondsName(pos, this.positionInfo),
            betrag: pos[this.getBetragId] || null,
            index: index,
            aa: pos.aa,
            startmonth: this.config?.hasStartmonth ? (pos.startmonth || '') : undefined,
            defaultAA: this.positionInfo[pos.isin].defaultAusgabeaufschlag ? "" + formatNumber(this.positionInfo[pos.isin].defaultAusgabeaufschlag, 2): "",
          }))
        if(this.config?.sortByIndex){
          recordsTemp.sort((a,b) => a.index - b.index)
        }
      return {
        headers: headersTemp,
        records: recordsTemp
      }
    },
    betragHeader() {
      return this.config && this.config.betrag && this.config.betrag.label || 'Betrag';
    },
    betragType() {
      let type = this.config && this.config.betrag && this.config.betrag.type;
      if ( type === 'TEXT_CURRENCY') {
        type = 'currency';
      } else if ( type === 'TEXT_PROZENT') {
        type = 'percent';
      } else if ( type === 'TEXT_NUMBER') {
        type = 'number';
      }
      return type || 'currency';
    },
    betragPlaceholder() {
      return this.config && this.config.betrag && this.config.betrag.placeholder || '';
    },
    aaLabel() {
      return this.config && this.config.aa && this.config.aa.label || 'Ausgabeaufschlag';
    },
    maxCount() {
      if ( this.config && this.config.maxCountCheckPersonType ) {
        const t = this.antrag.data?.INPUT_MINDERJAEHRIG || 'OHNE';
        return  this.config['maxCount' + t] || 5;
      }
      return this.config && this.config.maxCount || 5;
    },
    antrag() {
      if (this.antraege && this.antraege[this.antragId]) {
        return this.antraege[this.antragId]
      }
      return null
    },
    positionWarnings() {
      if (this.antrag && this.antrag.warnings) {
        return this.antrag.warnings && this.antrag.warnings.positionWarnings;
      }
      return null
    },
    getBetragId() {
      return this.config && this.config.betrag && this.config.betrag.id || 'betrag';
    },
  },
  methods: {
    commitUpdatePositionsFromEvent(positionChange, isin) {
      const payload = {
        id: this.antragId,
        categoryId: this.categoryId,
        fonds: [
          { 
            ...positionChange,
            isin,
          }
        ]
      }
     this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_POSITIONS, payload)
    },
    onChangeInputField($event, position, field) { 
      const positionChange = { [field]: $event }
      this.commitUpdatePositionsFromEvent(positionChange, position.isin)
    },
    handleTableAction(event) {
      if(event.action?.legend?.key === 'DELETE') {
        this.openModalDeleteFond(event.value);
      }
    },
    openModalDeleteFond(fond) {
      this.positionToDelete = fond;
      this.$refs.deleteModal.open()
    },
    deletePos() {
      this.deletePosition(this.antragId, this.categoryId, this.positionToDelete);
      this.positionToDelete = null;
    },
    getMonths() {
      const now = new Date();
      let month = this.config?.hasStartmonth === 'abJanuar' ? 0 : now.getMonth();
      let count = 12 + (this.config?.hasStartmonth !== 'abJanuar' ? 0 : now.getMonth());
      let year = now.getFullYear();
      const result = [{label: '', value: ''}];
      const labels = ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];
      for (var i=0; i < count; i++) {
        result.push({label: labels[month] + '. ' + year, value: (''+(101+month)).substr(1) + '.' + year})
        month++;
        if (month == 12) {
          year++;
          month = 0;
        }
      }
      return result;
    },
  },
  components: {
    InputField,
    // WertpapierAuswahlPositionsButtons,
    DeletePositionModal,
    Table,
    BaseButton,
    ComboBox,
  }
}
</script>

<style scoped>
  .addPositionBtn {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
  }
</style>