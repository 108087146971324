var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "box__title" }, [_vm._v(_vm._s(_vm.title))]),
    _c(
      "div",
      [
        _c("InputField", {
          attrs: {
            label: "Bezeichnung",
            isComponentHalfSize: "",
            validateUntouched: !_vm.disabled,
            disabled: _vm.disabled
          },
          on: {
            change: function($event) {
              return _vm.dataChanged()
            }
          },
          model: {
            value: _vm.form.bezeichnung,
            callback: function($$v) {
              _vm.$set(_vm.form, "bezeichnung", $$v)
            },
            expression: "form.bezeichnung"
          }
        }),
        _c("InputField", {
          attrs: {
            label: "Risikobeschreibung",
            isComponentHalfSize: "",
            disabled: _vm.disabled
          },
          on: {
            change: function($event) {
              return _vm.dataChanged()
            }
          },
          model: {
            value: _vm.form.risikotext,
            callback: function($$v) {
              _vm.$set(_vm.form, "risikotext", $$v)
            },
            expression: "form.risikotext"
          }
        }),
        _c("InputField", {
          attrs: {
            label: "Beschreibung Anfang",
            isComponentHalfSize: "",
            disabled: _vm.disabled
          },
          on: {
            change: function($event) {
              return _vm.dataChanged()
            }
          },
          model: {
            value: _vm.form.beschreibung1,
            callback: function($$v) {
              _vm.$set(_vm.form, "beschreibung1", $$v)
            },
            expression: "form.beschreibung1"
          }
        }),
        _c("InputToggleSwitch", {
          attrs: {
            label: "Maximaler Anteil für Wertpapiere der SRI",
            inLineLabel: "",
            disabled: _vm.disabled
          },
          on: {
            input: function($event) {
              return _vm.dataChanged()
            }
          },
          model: {
            value: _vm.form.grenzeAktiv,
            callback: function($$v) {
              _vm.$set(_vm.form, "grenzeAktiv", $$v)
            },
            expression: "form.grenzeAktiv"
          }
        }),
        _c("div", { staticClass: "forms__input--half-size mb-3" }, [
          _c("div", { staticClass: "row my-0" }, [
            _c(
              "div",
              { staticClass: "col-12 col-sm-3 mb-3 mb-sm-0" },
              [
                _c("ComboBox", {
                  attrs: {
                    label: "von",
                    values: _vm.sriValues,
                    disabled: _vm.isMaxSRIDisabled,
                    validateUntouched: !_vm.isMaxSRIDisabled
                  },
                  on: {
                    change: function($event) {
                      _vm.dataChangedCombo("anteilUG", $event)
                      _vm.validateUgOg($event, _vm.form.anteilOG)
                    }
                  },
                  model: {
                    value: _vm.form.anteilUG,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "anteilUG", $$v)
                    },
                    expression: "form.anteilUG"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-12 col-sm-3 mb-3 mb-sm-0" },
              [
                _c("ComboBox", {
                  attrs: {
                    label: "bis",
                    values: _vm.sriValues,
                    disabled: _vm.isMaxSRIDisabled,
                    validateUntouched: !_vm.isMaxSRIDisabled
                  },
                  on: {
                    change: function($event) {
                      _vm.dataChangedCombo("anteilOG", $event),
                        _vm.validateUgOg(_vm.form.anteilUG, $event)
                    }
                  },
                  model: {
                    value: _vm.form.anteilOG,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "anteilOG", $$v)
                    },
                    expression: "form.anteilOG"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-12 col-sm-6" },
              [
                _c("InputField", {
                  attrs: {
                    label: "am Gesamtdepot:",
                    type: "percent",
                    disabled: _vm.isMaxSRIDisabled,
                    validateUntouched: !_vm.isMaxSRIDisabled
                  },
                  on: {
                    change: function($event) {
                      return _vm.dataChanged()
                    }
                  },
                  model: {
                    value: _vm.form.anteilMaxSatz,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "anteilMaxSatz", $$v)
                    },
                    expression: "form.anteilMaxSatz"
                  }
                })
              ],
              1
            )
          ])
        ]),
        !_vm.isMaxSRIDisabled && _vm.validateUgOgMessage
          ? _c("div", { staticClass: "fc-alert fc-alert-danger" }, [
              _vm._v(_vm._s(_vm.validateUgOgMessage))
            ])
          : _vm._e(),
        _c("div", { staticClass: "mb-3" }, [
          _vm._v(
            "Achtung: Wertpapiere mit Risikoklasse höher als die Obergrenze sind nicht erlaubt."
          )
        ]),
        _c("InputTextArea", {
          attrs: {
            label: "Erklärung der Anteilsregel in Worten",
            rows: 3,
            disabled: _vm.isMaxSRIDisabled,
            validateUntouched: !_vm.isMaxSRIDisabled
          },
          on: {
            change: function($event) {
              return _vm.dataChanged()
            }
          },
          model: {
            value: _vm.form.text,
            callback: function($$v) {
              _vm.$set(_vm.form, "text", $$v)
            },
            expression: "form.text"
          }
        }),
        _c("div", { staticClass: "mb-3" }, [
          _vm._v(
            "** Siehe Erläuterungen unter Risikokennzahl / Risikobereitschaft"
          )
        ]),
        _c("InputTextArea", {
          attrs: {
            label: "Beschreibung Ende",
            rows: 3,
            disabled: _vm.disabled
          },
          on: {
            change: function($event) {
              return _vm.dataChanged()
            }
          },
          model: {
            value: _vm.form.beschreibung2,
            callback: function($$v) {
              _vm.$set(_vm.form, "beschreibung2", $$v)
            },
            expression: "form.beschreibung2"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }