<template>
  <div class="antrag-components__container">
    <div class="box__container">
      <FormLabel label="Bitte tragen Sie hier die Daten des Depots ein, von dem die Wertpapiere übertragen werden sollen."
        :config="{'bold': true}" />
      <FormDivider/>

      <div class="row">
        <div class="col-12">
          <FormLabel :config="{'bold': true}" label="Daten der bisher depotführenden Gesellschaft"/>
        </div>
        <InputField class="col-12 col-md-6" v-model="form.bisherManuellName" label="Name"
          @change="$emit('save', { bisherManuellName: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.bisherManuellStrasse" label="Straße"
          @change="$emit('save', { bisherManuellStrasse: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.bisherManuellPLZ" label="PLZ"
          @change="$emit('save', { bisherManuellPLZ: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.bisherManuellOrt" label="Ort"
          @change="$emit('save', { bisherManuellOrt: $event })"/>
      </div>
      <FormDivider/>

      <div class="row">
        <div class="col-12">
          <FormLabel :config="{'bold': true}" label="Daten des Depots bei der bisher depotführenden Gesellschaft"/>
        </div>
        <InputField v-model="form.bisherDepotnr" label="Depotnummer"
          @change="$emit('save', { bisherDepotnr: $event })"/>
           <InputField v-model="form.bisherKontonr" label="Kontonummer"
          @change="$emit('save', { bisherKontonr: $event })"/>

        <div class="col-12">
          <FormLabel :config="{'bold': true}" label="1. Inhaber"/>
        </div>
        <InputField class="col-12 col-md-6" v-model="form.bisherName" label="Name" id="form.bisherName"
          @change="$emit('save', { bisherName: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.bisherVorname" label="Vorname" id="form.bisherVorname"
          @change="$emit('save', { bisherVorname: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.bisherStrasse" label="Straße Haus-Nr" id="form.bisherStrasse"
          @change="$emit('save', { bisherStrasse: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.bisherPlz" label="PLZ" id="form.bisherPlz"
          @change="$emit('save', { bisherPlz: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.bisherOrt" label="Ort" id="form.bisherOrt"
          @change="$emit('save', { bisherOrt: $event })"/>
        <DatePickerField 
          class="col-12 col-md-6" 
          v-model="form.bisherGebdat" 
          id="form.bisherGebdat"
          label="Geburtsdatum"
          isValueAsString
          @input="$emit('save', { bisherGebdat: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.bisherID" id="form.bisherID" label="TIN"
          @change="$emit('save', { bisherID: $event })"/>
        <div class="col-12">
          <FormLabel :config="{'bold': true}" label="2. Inhaber"/>
        </div>
        <InputField class="col-12 col-md-6" v-model="form.bisherName2" label="Name" id="form.bisherName2"
          @change="$emit('save', { bisherName2: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.bisherVorname2" label="Vorname" id="form.bisherVorname2"
          @change="$emit('save', { bisherVorname2: $event })"/>
        <DatePickerField 
          class="col-12 col-md-6" 
          v-model="form.bisherGebdat2" 
          id="form.bisherGebdat2"
          label="Geburtsdatum"
          isValueAsString
          @input="$emit('save', { bisherGebdat2: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.bisherID2" label="TIN" id="form.bisherID2"
          @change="$emit('save', { bisherID2: $event })"/>

      </div>
      <BaseButton @click="$emit('next')">Weiter</BaseButton>
    </div>
  </div>
</template>
<script>
import FormLabel from '@/components/core/forms/FormLabel.vue';
import InputField from '@/components/core/forms/InputField.vue';
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import FormDivider from '@/components/core/forms/FormDivider.vue';
import validator from "@/mixins/validator";
import antragMixin from '@/mixins/antrag/antrag-mixin.js';

export default {
  mixins: [validator, antragMixin],
  validators: {},
  props: {
    positionData: {
    },
    warnings: {
    },
  },
  components: {
    FormLabel,
    InputField,
    DatePickerField,
    BaseButton,
    FormDivider,
  },
  data() {
    return {
      form: {}
    }
  },
  watch: {
    warnings(value) {
      const errors = this.warningsPositionUebertragungEditor(value, this.form);
      errors?.forEach(err => {
        this.$pushErrors(err.id, err.message);
      });
    }
  },
  mounted() {
    this.form = {
      bisherDepotnr: this.positionData.bisherDepotnr,
      bisherKontonr: this.positionData.bisherKontonr,
      bisherManuellName: this.positionData.bisherManuellName,
      bisherManuellStrasse: this.positionData.bisherManuellStrasse,
      bisherManuellPLZ: this.positionData.bisherManuellPLZ,
      bisherManuellOrt: this.positionData.bisherManuellOrt,

      bisherAnrede: this.positionData.bisherAnrede,
      bisherName: this.positionData.bisherName,
      bisherVorname: this.positionData.bisherVorname,
      bisherID: this.positionData.bisherID,
      bisherGebdat: this.positionData.bisherGebdat,
      bisherStrasse: this.positionData.bisherStrasse,
      bisherPlz: this.positionData.bisherPlz,
      bisherOrt: this.positionData.bisherOrt,
      bisherGebname: this.positionData.bisherGebname,

      bisherAnrede2: this.positionData.bisherAnrede2,
      bisherName2: this.positionData.bisherName2,
      bisherID2: this.positionData.bisherID2,
      bisherVorname2: this.positionData.bisherVorname2,
      bisherGebdat2: this.positionData.bisherGebdat2,
      bisherStrasse2: this.positionData.bisherStrasse2,
      bisherHausnr2: this.positionData.bisherHausnr2,
      bisherPlz2: this.positionData.bisherPlz2,
      bisherOrt2: this.positionData.bisherOrt2,
      bisherGebname2: this.positionData.bisherGebname2,
    };
    const errors = this.warningsPositionUebertragungEditor(this.warnings, this.form);
    this.$emit('hasErrors', errors?.length ? true : false);
  },
  methods: {
  }
}
</script>
<style scoped>

</style>