/**
 * NOT ACCESSIBLE IN PRODUCTION!
 */

export default [
    {
        path: 'tables',
        component: () => import(/* webpackChunkName: "docs" */ '@/views/docs/Tables.vue'),
        children: [
        ]
    },
    {
        path: 'tables-table',
        component: () => import('@/views/docs/componentViewer/tables/TableView.vue'),
        props: route => ({id: route.query.id}),
        meta: {
            docs: true,
        },
    }
];
