<template>
<BaseModal 
    ref="modal"
    modalTitle="Interface Konvertierung verarbeiten"
    :autoClose="false"
    :confirmDisabled="!form.name"
    :showConfirmButton="interfaceEditEnable"
    @onConfirmButton="save"
    @close="close()"
>
    <div>
        <InputField v-model="form.name" :disabled="!interfaceEditEnable" label="Feldname"/>
        <InputRadioBoxGroup v-model="form.art" :disabled="!interfaceEditEnable" :values="artOptions"/>
        <template v-if="form.art == 0">
            <InputField v-model="form.delimiter" :disabled="!interfaceEditEnable" label="Trennzeichen"/>
            <InputField v-model="form.occurance" :disabled="!interfaceEditEnable" :type="'number'" label="Nummer des Felds"/>
        </template>
        <template v-else-if="form.art == 1">
            <InputField v-model="form.position" :disabled="!interfaceEditEnable" :type="'number'" label="Position"/>
            <InputField v-model="form.len" :disabled="!interfaceEditEnable" :type="'number'" label="Feldlänge"/>
        </template>
        <template v-else>
            <InputField v-model="form.selection" :disabled="!interfaceEditEnable" label="XPath"/>
        </template>
        <InputTextArea v-model="form.comment" :disabled="!interfaceEditEnable" label="Bemerkung" autoGrow/>
        <InputTextArea v-model="form.conversion" :disabled="!interfaceEditEnable" label="Konvertierung" autoGrow/>
    </div>
</BaseModal>
</template>
<script>
import CORE_TYPES from '@/store/core/types'

import BaseModal from '@/components/core/BaseModal.vue';
import InputField from '@/components/core/forms/InputField.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import BaseButton from '@/components/core/BaseButton.vue';

import { mapGetters } from 'vuex';
import axios from 'axios';

const config = {
    defaultSpinner: true,
};

export default {
    components: {
        BaseModal,
        InputField,
        InputRadioBoxGroup,
        InputTextArea,
        BaseButton,
    },
    props: {
        interfaceId: {
            type: Number,
            required: true,
        },
        feld: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            oldName: this.feld.name,
            form: {...this.feld},
            artOptions: [
                {value: 0, label: "Text getrennt durch Trennzeichen"},
                {value: 1, label: "Text fester Feldlänge"},
                {value: 2, label: "XML"},
            ],
        };
    },
    computed: {
        ...mapGetters({
            isIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
        }),
        interfaceEditEnable() {
            return this.isIntern;
        },
    },
    mounted() {
        this.$refs.modal.open();
    },
    methods: {
        save() {
            let url = `${process.env.VUE_APP_API}/gdv_interface/save_feld?interface=${this.interfaceId}`;
            if (this.oldName)
                url += `&name=${this.oldName}`;
            axios.post(url, this.form, config)
            .then(_ => {
                this.$refs.modal.close();
                this.$emit("close", true);
            })
        },
        close() {
            this.$emit("close", false);
        },
    }
}
</script>
