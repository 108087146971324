<template>
  <div>
    <GesellschaftTitleNavigation/>
    
    <div class="box__container">
      <div class="box__title mb-3">Einstellungen</div>
      <GhostLoading v-if="loading" type="table" />

      <div v-else>
        <InputToggleSwitch
          label="Alte Depotdaten beim Einlesen löschen"
          v-model="settings.resetDepot"
        />
        <InputToggleSwitch
          label="Anzeige im smartMSC"
          v-model="settings.zeigeInListe"
        />
        <InputToggleSwitch
          label="In Courtageliste dieser Firma zeigen"
          v-model="settings.visibleCourtageliste"
        />
        <InputToggleSwitch
          label="Fondsplattform"
          v-model="settings.multiGesellschaft"
        />
        <InputToggleSwitch
          label="Fonds dieser Gesellschaft übertragsfähig zu AAB"
          v-model="settings.uebertragsfaehigAab"
        />
        <InputToggleSwitch
          label="Fonds dieser Gesellschaft übertragsfähig zu ADIG/ebase"
          v-model="settings.uebertragsfaehigAdigEbase"
        />
        <InputToggleSwitch
          label="Fonds dieser Gesellschaft übertragsfähig zu DWS Fondsplattform"
          v-model="settings.uebertragsfaehigDws"
        />
        <InputToggleSwitch
          label="Fonds dieser Gesellschaft übertragsfähig zu FFB"
          v-model="settings.uebertragsfaehigFfb"
        />
        <InputToggleSwitch
          label="Fonds dieser Gesellschaft übertragsfähig zu Fondsdepotbank"
          v-model="settings.uebertragsfaehigFondsdepotbank"
        />
        <InputToggleSwitch
          label="Bereinigung doppelter Transaktionen durchführen"
          v-model="settings.mergeTransactions"
        />
        <InputToggleSwitch
          label="Reporting erstellen"
          v-model="settings.reporting"
        />
        <InputToggleSwitch
          label="Reporting per E-Mail an Gesellschaft schicken"
          v-model="settings.reportingMail"
        />
        <InputToggleSwitch
          label="Gesellschaft bietet Servicegebühr an"
          v-model="settings.servicegebuehrAvailable"
        />
        <InputToggleSwitch
          label="Gesellschaft nutzt Bannerwerbung auf der Homepage"
          v-model="settings.nutztBannerwerbung"
        />
        <InputToggleSwitch
          label="Gesellschaft nutzt Servicepaket"
          v-model="settings.nutztServicepaket"
        />
        <InputToggleSwitch
          label="Gesellschaft ist Toppartner"
          v-model="settings.topPartner"
        />
        <InputToggleSwitch
          label="Sonstige Vermögenswerte (Genossenschaftsanteile, Genussrechte, etc) nach Gewerbeordnung §34 f Abs. 1 Satz 1 Nr. 3"
          v-model="settings.sonstigeVermoegensanlagen"
        />

        <InputToggleSwitch
          label="Gesellschaft mit Depotstelle"
          v-model="settings.mitDepotstelle"
        />
      </div>
    </div>
  </div>
</template>

<script>
import GESELLSCHAFT_TYPES from '@/store/gesellschaft/types';
import axios from 'axios';
import { mapGetters } from 'vuex';
import { makeQueryParam } from '@/helpers/utils-helper';

import GesellschaftTitleNavigation from '@/views/gesellschaft/intern/GesellschaftTitleNavigation.vue'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue'

const config = {
  defaultSpinner: true
};

export default {
  mixins: [],
  components: {
    GhostLoading,
    InputToggleSwitch,
    GesellschaftTitleNavigation,
  },
  data() {
    return {
      settings: {},
      originalSettings: null,
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      gesellschaft: GESELLSCHAFT_TYPES.GETTERS.GET_GESELLSCHAFT,
    }),
  },
  watch: {
    gesellschaft: {
      handler(newVal) {
        this.settings = newVal.settings || {}

        if (!this.originalSettings && Object.keys(this.settings).length) {
          // Create a deep copy and compare it when trying to save. If no changes were made we don't need to save
          this.originalSettings = JSON.parse(JSON.stringify(this.settings))
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.loading = true

    this.$store.dispatch(GESELLSCHAFT_TYPES.ACTIONS.GET_GESELLSCHAFT_EINSTELLUNGEN, { gesellschaftId: this.$route.params.gesellId })
    .finally(() => this.loading = false);
  },
  methods: {
    saveSettings() {
      if (JSON.stringify(this.originalSettings) === JSON.stringify(this.settings)) {
        return
      }
      this.loading = true

      const params = makeQueryParam({ gesellschaftId: this.$route.params.gesellId }, true)

      axios.post(`${process.env.VUE_APP_API}/gesellschaftenService/settings?${params}`, this.settings, config)
        .finally(() => this.loading = false)
    },
  },
  beforeRouteLeave(to, from, next) {
    this.saveSettings();
    next();
  }
}
</script>