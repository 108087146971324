var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box__container-wrap" }, [
    _vm.showTitle
      ? _c("span", { staticClass: "box__title" }, [_vm._v("Nachrichten")])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "box__container-rows" },
      [
        _vm.isDisabled
          ? _c(
              "div",
              { staticClass: "tickets-nachrichten__disabled" },
              [_c("UndrawChatting")],
              1
            )
          : !_vm.loadingTicketsNachrichten &&
            !_vm.isDisabled &&
            _vm.rows.length > 0
          ? _c("Table", {
              attrs: {
                tableId: "cf1a65f8-3933-4d68-b299-a23edeb56b2a",
                headers: _vm.headers,
                rows: _vm.rows,
                rowsPerPage: 5,
                hidePagination: "",
                mobileConfig: _vm.mobileConfig
              },
              on: {
                "click-thema": _vm.clickThema,
                "click-nameBeteiligter": _vm.openCustomerNewTab
              },
              scopedSlots: _vm._u([
                {
                  key: "status",
                  fn: function(props) {
                    return [
                      _c(
                        "span",
                        { staticClass: "black" },
                        [
                          _c("Pill", {
                            attrs: {
                              label: props.status.label,
                              type: props.status.type
                            }
                          })
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            })
          : _vm.loadingTicketsNachrichten
          ? _c("GhostLoading", {
              attrs: { type: "table", config: { table: { rows: 5 } } }
            })
          : _vm.rows.length == 0
          ? _c("NoData", { attrs: { noIcon: "" } })
          : _vm._e()
      ],
      1
    ),
    !_vm.loadingTicketsNachrichten && !_vm.$isSmallScreen
      ? _c(
          "div",
          { staticClass: "bottom-row text-right mt-3" },
          [
            _c(
              "router-link",
              { attrs: { to: "/communication/postfach/chats" } },
              [
                !_vm.isDisabled
                  ? [
                      _vm._v(
                        _vm._s(
                          _vm.rows.length > 0
                            ? "weitere..."
                            : "Übersicht anzeigen"
                        )
                      )
                    ]
                  : [_vm._v("zu den Nachrichten...")]
              ],
              2
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }