const data = [
  { name: "test1", value: 279 }
];
const geoCoordMap = {
  test2: [125.03, 46.58]
};

function convertData(data) {
  const res = [];
  for (let i = 0; i < data.length; i++) {
    const geoCoord = geoCoordMap[data[i].name];
    if (geoCoord) {
      res.push({
        name: data[i].name,
        value: geoCoord.concat(data[i].value)
      });
    }
  }
  return res;
}

import { getColorLimitedListBasedOnLightingDecreasing } from '@/helpers/colors-helper';
export function mapColors() {
  const colors = getColorLimitedListBasedOnLightingDecreasing(document.documentElement.style.getPropertyValue('--color-primary'), { quantity: 10, fromStepsHigher: 2 }).reverse()
  const result = [colors[0], colors[2], colors[4]]
  for (let index = 0; index < 50; index++) {
    result.push(colors[6])
  }
  return result
}

export default {
  visualMap: {
    show: false,
    left: 'right',
    min: 1,
    max: 53,
    inRange: {
      color: mapColors(),
    },
    text: ['High', 'Low'],
    calculable: false
  },
  tooltip: {
    show: false,
  },
  series: [
    {
      name: '',
      type: 'map',
      map: 'world',
      emphasis: {
        show: false,
        label: {
          show: false
        }
      },
      showSymbol: false,
      silent: true,
      data: [
        { name: 'Canada', value: 1 },
        { name: 'United States of America', value: 2 },
        { name: 'China', value: 3 },
        { name: 'Russia', value: 23 },
        { name: 'Brazil', value: 23 },
      ]
    }
  ]
};
