<template>
  <div v-if="vertragsdatenFieldsDefinition">
    <FlexibleList :rows="eigebundeneWertpapiere" showThirdRow v-if="eigebundeneWertpapiere.length">
      
      <template #title="row">
        {{row.wertpapiername || row.anlageart}}
      </template>

      <template #value="row">
        {{ row.fondswertEuro || row.fondswert | currency }}
      </template>

      <template #thirdRow="row">
        {{ row.isin || row.anlageart }}
      </template>      
    
    </FlexibleList>

    <FlexibleList :rows="rueckkaufswerte"  v-if="rueckkaufswerte.length">
      
      <template #title="row">
        {{row.jahr}}
      </template>

      <template #value="row">
        {{ row.betrag| currency }}
      </template>
    
    
    </FlexibleList>  
  </div>
</template>
<script>

import { mapGetters } from "vuex";
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";
import FlexibleList from "@/components/flexibleList/FlexibleList.vue";
import InsuranceDetailMixin from '@/components/versicherungen/InsuranceDetail-mixin.js'

export default {
  mixins: [InsuranceDetailMixin],
  components: {
    FlexibleList,
  },
  data: function () {
    return {};
  },
  computed: {
    ...mapGetters({
        versicherungenDetails: VERSICHERUNG_TYPES.GETTERS.INSURANCE_DETAILS,
        vertragsdatenFieldsDefinition: VERSICHERUNG_TYPES.GETTERS.INSURANCE_VERTRAGSDATEN_FIELDS_DEFINITION,

    }),
   
  },
  methods: {

  },
  filters: {
    currency(value) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €";
    },
  },  
  mounted() {

  },
};
</script>