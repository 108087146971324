var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-6" },
        [
          _c("PieChart", {
            attrs: {
              chartData: _vm.eChartData,
              customColors: _vm.colors,
              height: "280px",
              isTooltip: "",
              doughnut: ""
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-6 d-fl-c" },
        _vm._l(_vm.labels, function(val, index) {
          return _c("div", { key: index, staticClass: "row" }, [
            _c(
              "span",
              {
                staticClass: "col-auto p-0",
                staticStyle: { "align-self": "center" }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "legend-item",
                    style: { "background-color": _vm.colors[index] }
                  },
                  [_vm._v("   ")]
                )
              ]
            ),
            _c("span", { staticClass: "col" }, [_vm._v(_vm._s(val))])
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }