import Table from '@tiptap/extension-table'
import {
  mergeAttributes,
} from '@tiptap/core'

export const BORDER_OPTIONS = [
  { value: null, label: 'Keine' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
]

function calculateTableWidthStyle(node) {
  let totalWidth = 0;
  let fixedWidth = true;

  try {
    // use first row to determine width of table;
    const tr = node.content.content[0];
    tr.content.content.forEach(td => {
      if (td.attrs.colwidth) {
        td.attrs.colwidth.forEach(col => {
          if (!col) {
            fixedWidth = false;
            totalWidth += this.options.cellMinWidth;
          } else {
            totalWidth += col;
          }
        });
      } else {
        fixedWidth = false;
        const colspan = td.attrs.colspan ? td.attrs.colspan : 1;
        totalWidth += this.options.cellMinWidth * colspan;
      }
    });
  } catch (error) {
    fixedWidth = false;
  }

  let sizeStyle = null;

  if (fixedWidth && totalWidth > 0) {
    sizeStyle = `width: ${totalWidth}px`;
  } else if (totalWidth && totalWidth > 0) {
    sizeStyle = `min-width: ${totalWidth}px`;
  }

  return sizeStyle;
}

export const CustomTable = Table.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      border: {
        default: null,
        renderHTML: attributes => {
          if (!attributes['border']) {
            return {};
          }
          return {
            border: attributes.border,
            style: 'border-collapse: collapse',
          }  
        },
        parseHTML: (element) => element.border,
      },
    };
  },

  renderHTML({ node, HTMLAttributes }) {
    const attributes = {
      style: calculateTableWidthStyle(node)
    };

    return ['div', { class: 'tableWrapper' }, ['table', mergeAttributes(HTMLAttributes, attributes), ['tbody', 0]]];
  },

  addCommands() {
    return {
      ...this.parent?.(),
      setTableBorder: (border) =>
        ({ commands }) => {
          return commands.updateAttributes(this.name, { border });
        },
      unsetTableBorder: () =>
        ({ chain }) => {
          return chain()
            .updateAttributes(this.name, { border: null })
            .run();
        },
    };
  }
});


