var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-forms__container" }, [
    _c("div", { staticClass: "input-forms__label-container" }, [
      _c(
        "div",
        { staticClass: "input-forms__container" },
        [
          _vm.config.beenden
            ? _c("ComboBox", {
                attrs: {
                  label: "Bereits vorhandene Depots",
                  values: _vm.vorhandeneDepotsAuswahl,
                  value: _vm.selectedDepotValue,
                  sortComboboxValues: false
                },
                on: {
                  input: function($event) {
                    return _vm.updateStore(_vm.selectedDepotKey, $event)
                  }
                }
              })
            : _vm._e(),
          _vm.selectedDepot
            ? _c("WertpapierorderAnteileInfo", {
                attrs: { position: _vm.selectedDepot }
              })
            : _vm._e()
        ],
        1
      ),
      _c("div", { staticClass: "input-forms__label-container" }, [
        _c("div", { staticClass: "row mt-0" }, [
          _c("div", { staticClass: "col" }, [
            _vm.label
              ? _c(
                  "div",
                  {
                    staticClass:
                      "input-forms__label-content input-forms__label-content--bigger"
                  },
                  [
                    _c("ph-bank", { attrs: { size: 16 } }),
                    _vm._v(" " + _vm._s(_vm.label) + " ")
                  ],
                  1
                )
              : _vm._e()
          ]),
          _vm.config && _vm.config.buttonText
            ? _c(
                "div",
                { staticClass: "col-auto" },
                [
                  _c("BaseButton", { on: { click: _vm.openFormsFinder } }, [
                    _vm._v(_vm._s(_vm.config.buttonText))
                  ])
                ],
                1
              )
            : _vm._e()
        ])
      ])
    ]),
    _c(
      "div",
      { staticClass: "input-forms__input-container" },
      [
        _vm.rows && _vm.rows.length
          ? _c("Table", {
              attrs: {
                hidePagination: "",
                headers: _vm.headers,
                rows: _vm.rows,
                rowId: "index"
              },
              on: { "action-DELETE": _vm.openModalDeleteFond }
            })
          : _vm._e(),
        _c("DeletePositionModal", {
          ref: "deleteModal",
          attrs: { position: _vm.positionToDelete },
          on: {
            delete: function($event) {
              return _vm.doDeletePosition()
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }