var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: _vm.accessTitle, actions: _vm.headerActions },
        on: {
          "action-BACK": function($event) {
            return _vm.goBack()
          },
          "action-MICROSOFT": function($event) {
            return _vm.getMicrosoftLink()
          },
          "action-GOOGLE": function($event) {
            return _vm.getGoogleCodeURI()
          }
        }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("ComboBox", {
            attrs: {
              label: "Servertyp",
              values: _vm.types,
              firstEmpty: false,
              isComponentHalfSize: ""
            },
            on: {
              change: function($event) {
                return _vm.typeChanged($event)
              }
            },
            model: {
              value: _vm.form.type,
              callback: function($$v) {
                _vm.$set(_vm.form, "type", $$v)
              },
              expression: "form.type"
            }
          }),
          _c("InputField", {
            attrs: {
              label: "Server",
              disabled: _vm.isMicrosoft,
              hidden: _vm.isGoogle,
              isComponentHalfSize: ""
            },
            model: {
              value: _vm.form.server,
              callback: function($$v) {
                _vm.$set(_vm.form, "server", $$v)
              },
              expression: "form.server"
            }
          }),
          _c("InputField", {
            attrs: {
              label: "Port",
              disabled: _vm.isMicrosoft,
              hidden: _vm.isGoogle,
              isComponentHalfSize: ""
            },
            model: {
              value: _vm.form.port,
              callback: function($$v) {
                _vm.$set(_vm.form, "port", $$v)
              },
              expression: "form.port"
            }
          }),
          _c("InputField", {
            attrs: {
              label: "Benutzer",
              id: "form.name",
              disabled: _vm.isGoogle,
              hidden: _vm.isGoogle && this.$route.params.id == 0,
              isComponentHalfSize: "",
              validateUntouched: ""
            },
            on: {
              input: function($event) {
                return _vm.updateMicrosoftConfirmed()
              }
            },
            model: {
              value: _vm.form.name,
              callback: function($$v) {
                _vm.$set(_vm.form, "name", $$v)
              },
              expression: "form.name"
            }
          }),
          _c("InputField", {
            attrs: {
              label: "Passwort",
              type: _vm.passwordType,
              disabled: _vm.isMicrosoft,
              hidden: _vm.isGoogle,
              isComponentHalfSize: ""
            },
            on: {
              focus: function($event) {
                return _vm.handlePswField(true)
              }
            },
            model: {
              value: _vm.form.passwd,
              callback: function($$v) {
                _vm.$set(_vm.form, "passwd", $$v)
              },
              expression: "form.passwd"
            }
          }),
          _c("InputToggleSwitch", {
            attrs: {
              label: "SSL benutzen",
              disabled: _vm.isMicrosoft || _vm.isGoogle
            },
            model: {
              value: _vm.form.ssl,
              callback: function($$v) {
                _vm.$set(_vm.form, "ssl", $$v)
              },
              expression: "form.ssl"
            }
          }),
          _c("InputToggleSwitch", {
            attrs: {
              inLineLabel: "",
              label: "Die E-Mails sind für alle Mitarbeiter sichtbar."
            },
            model: {
              value: _vm.form.freigeschaltet,
              callback: function($$v) {
                _vm.$set(_vm.form, "freigeschaltet", $$v)
              },
              expression: "form.freigeschaltet"
            }
          }),
          _c("InputToggleSwitch", {
            attrs: { inLineLabel: "", label: "aktiv" },
            model: {
              value: _vm.form.aktiv,
              callback: function($$v) {
                _vm.$set(_vm.form, "aktiv", $$v)
              },
              expression: "form.aktiv"
            }
          })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "errorModal",
          attrs: {
            modalTitle: "Speichern nicht möglich",
            labelButtonCancel: "Abbrechen",
            labelButtonConfirm: "Zurück ohne speichern",
            showConfirmButton: true
          },
          on: {
            onCancelButton: function($event) {
              return _vm.$refs.errorModal.close()
            },
            onCloseButton: function($event) {
              return _vm.$refs.errorModal.close()
            },
            onConfirmButton: function($event) {
              _vm.canLeavePage = true
              _vm.goBack()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "accessTitle",
              fn: function() {
                return [_vm._v(" Fehler ")]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", [
            _vm._v(
              " Die Daten können nicht gespeichert werden. Bitte korrigieren Sie die rot umrandeten Bereiche. "
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }