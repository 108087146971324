var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { actions: _vm.headerActions },
        on: { action: _vm.handleAction },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [_vm._v(_vm._s(_vm.title))]
            },
            proxy: true
          },
          {
            key: "subtitle",
            fn: function() {
              return [
                _vm._l(_vm.variante.stati || [], function(status, index) {
                  return [
                    index > 0
                      ? _c("span", { key: index }, [_vm._v(" | ")])
                      : _vm._e(),
                    _c("span", { key: index, class: status.status }, [
                      _vm._v(_vm._s(status.label))
                    ])
                  ]
                })
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.isSchemaDataLoading
            ? _c("GhostLoading", { attrs: { type: "table" } })
            : _vm.variante
            ? _c("VVWertpapiereList", {
                attrs: {
                  positions: (_vm.variante && _vm.variante.positions) || [],
                  isEditor: true,
                  disabled: _vm.disabled
                }
              })
            : _vm._e(),
          _c("InputTextArea", {
            attrs: {
              disabled: !_vm.schemaData.isAdvisor || _vm.disabled,
              label: "Anmerkung des Erstellers"
            },
            model: {
              value: _vm.commentZulassung,
              callback: function($$v) {
                _vm.commentZulassung = $$v
              },
              expression: "commentZulassung"
            }
          }),
          _c("InputTextArea", {
            attrs: {
              disabled: !_vm.schemaData.isInternVV || _vm.disabled,
              label: "Kommentar Vermögensverwalter"
            },
            model: {
              value: _vm.commentVerwalter,
              callback: function($$v) {
                _vm.commentVerwalter = $$v
              },
              expression: "commentVerwalter"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("StrategienZielmarktTable", {
            attrs: {
              zielmarktdaten: _vm.variante.zielmarktdaten,
              disabled: _vm.disabled
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("StrategienRichtbandbreiteTable", {
            attrs: {
              tableRows: _vm.variante.richtbandbreiten || [],
              isAdvisor: _vm.variante.isAdvisor
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }