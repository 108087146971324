var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _c("Table", {
        attrs: {
          title: "Vorlieben",
          rowId: "name",
          rows: _vm.rows,
          headers: _vm.headers,
          headerActions: _vm.tableHeaderActions,
          rowsPerPage: 20
        },
        on: {
          "headerAction-NEUE_VORLIEBE": _vm.openCreateVorliebeModal,
          "action-DELETE": _vm.deleteVorliebe
        }
      }),
      _c(
        "BaseModal",
        {
          ref: "createVorliebeModal",
          attrs: {
            modalTitle: "Neue Vorliebe",
            confirmDisabled: !_vm.neueVorliebeValid
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.addVorliebe(_vm.neueVorliebe)
            }
          }
        },
        [
          _c("InputField", {
            attrs: {
              isComponentHalfSize: "",
              renderDanger: !_vm.neueVorliebeValid
            },
            model: {
              value: _vm.neueVorliebe,
              callback: function($$v) {
                _vm.neueVorliebe = $$v
              },
              expression: "neueVorliebe"
            }
          }),
          !_vm.neueVorliebeValid
            ? _c("div", { staticClass: "fc-danger" }, [
                _vm._v("Vorliebe existiert bereits")
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }