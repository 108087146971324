//
// Autogenerated by Thrift Compiler (1.0.0-soscomp-2)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
import {Thrift} from "./thrift.js";

const AppNotificationUserType = {
  'INTERN' : 0,
  'MAKLER' : 1,
  'KUNDE' : 2,
  'PERSON' : 3
};
const AppNotificationService = {
  'IOS_APNS' : 0,
  'ANDROID_GCM' : 1,
  'PULL' : 2
};
const AppNotificationType = {
  'CHAT_MESSAGE' : 0,
  'CHAT_VERSAND_STATUS' : 1,
  'WEB_RTC_CALL' : 2,
  'UNSUPPORTED' : 3,
  'WEB_RTC_AVAILABILITY' : 4,
  'LOGOUT' : 5,
  'SUGGESTED_ACTION_MESSAGE' : 6,
  'COUNTER_UPDATE' : 7,
  'MFA_CHALLENGE' : 8
};
const AppNotificationKey = {
  'USER_TYPE' : 0,
  'USER_LOGIN_ID' : 1,
  'NOTIFICATION_TYPE' : 2,
  'NOTIFICATION_ID' : 3,
  'NOTIFICATION_ITEM_ID' : 4,
  'OBSOLETE_MAKLERNR' : 5,
  'OBSOLETE_KUNDENNR' : 6,
  'NOTIFICATION_ID_WORKAROUND_DATA' : 7,
  'ABSENDER_NAME' : 8,
  'ABSENDER_TICKET_BETEILIGTER_ID' : 9,
  'ANRUFER_SIGNALING_UUID' : 10,
  'SIGNALING_UUID' : 11,
  'SIGNALING_SERVER_URL' : 12,
  'MEDIA_SERVER_URL' : 13,
  'STUN_SERVER_URL' : 14,
  'TURN_SERVER_URL' : 15,
  'TURN_SERVER_USER' : 16,
  'TURN_SERVER_PASSWORD' : 17,
  'WEB_RTC_STATUS' : 18,
  'IS_VIDEO_CALL' : 19,
  'WEB_RTC_CAll_ID' : 20,
  'WEB_RTC_USER_BILD_URL' : 21,
  'WRA_IS_AVAILABLE' : 22,
  'WRA_CHAT_BETEILIGTER_ID' : 23,
  'ANRUFER_KUNDENNR' : 24,
  'CREATOR_JWT_ID' : 25,
  'SUGGESTED_ACTION_ID' : 26,
  'COUNTER_UPDATE_NAME' : 27
};
const AppNotificationWraOutput = function(args) {
  this.isAvailable = null;
  this.chatBeteiligterId = null;
  if (args) {
    if (args.isAvailable !== undefined && args.isAvailable !== null) {
      this.isAvailable = args.isAvailable;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field isAvailable is unset!');
    }
    if (args.chatBeteiligterId !== undefined && args.chatBeteiligterId !== null) {
      this.chatBeteiligterId = args.chatBeteiligterId;
    }
  }
};
AppNotificationWraOutput.prototype = {};
AppNotificationWraOutput.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.BOOL) {
        this.isAvailable = input.readBool().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I64) {
        this.chatBeteiligterId = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AppNotificationWraOutput.prototype.write = function(output) {
  output.writeStructBegin('AppNotificationWraOutput');
  if (this.isAvailable !== null && this.isAvailable !== undefined) {
    output.writeFieldBegin('isAvailable', Thrift.Type.BOOL, 1);
    output.writeBool(this.isAvailable);
    output.writeFieldEnd();
  }
  if (this.chatBeteiligterId !== null && this.chatBeteiligterId !== undefined) {
    output.writeFieldBegin('chatBeteiligterId', Thrift.Type.I64, 2);
    output.writeI64(this.chatBeteiligterId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

const AppInstallation = function(args) {
  this.deviceId = null;
  this.appName = null;
  if (args) {
    if (args.deviceId !== undefined && args.deviceId !== null) {
      this.deviceId = args.deviceId;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field deviceId is unset!');
    }
    if (args.appName !== undefined && args.appName !== null) {
      this.appName = args.appName;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field appName is unset!');
    }
  }
};
AppInstallation.prototype = {};
AppInstallation.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.deviceId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.appName = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AppInstallation.prototype.write = function(output) {
  output.writeStructBegin('AppInstallation');
  if (this.deviceId !== null && this.deviceId !== undefined) {
    output.writeFieldBegin('deviceId', Thrift.Type.STRING, 1);
    output.writeString(this.deviceId);
    output.writeFieldEnd();
  }
  if (this.appName !== null && this.appName !== undefined) {
    output.writeFieldBegin('appName', Thrift.Type.STRING, 2);
    output.writeString(this.appName);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

const AppNotificationId = function(args) {
  this.id = null;
  this.workaroundData = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field id is unset!');
    }
    if (args.workaroundData !== undefined && args.workaroundData !== null) {
      this.workaroundData = args.workaroundData;
    }
  }
};
AppNotificationId.prototype = {};
AppNotificationId.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.I64) {
        this.id = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.workaroundData = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AppNotificationId.prototype.write = function(output) {
  output.writeStructBegin('AppNotificationId');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.I64, 1);
    output.writeI64(this.id);
    output.writeFieldEnd();
  }
  if (this.workaroundData !== null && this.workaroundData !== undefined) {
    output.writeFieldBegin('workaroundData', Thrift.Type.STRING, 2);
    output.writeString(this.workaroundData);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

const AppNotificationUserId = function(args) {
  this.userId = null;
  this.userType = null;
  this.workaroundData = null;
  if (args) {
    if (args.userId !== undefined && args.userId !== null) {
      this.userId = args.userId;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field userId is unset!');
    }
    if (args.userType !== undefined && args.userType !== null) {
      this.userType = args.userType;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field userType is unset!');
    }
    if (args.workaroundData !== undefined && args.workaroundData !== null) {
      this.workaroundData = args.workaroundData;
    }
  }
};
AppNotificationUserId.prototype = {};
AppNotificationUserId.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.userId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.userType = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.workaroundData = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AppNotificationUserId.prototype.write = function(output) {
  output.writeStructBegin('AppNotificationUserId');
  if (this.userId !== null && this.userId !== undefined) {
    output.writeFieldBegin('userId', Thrift.Type.STRING, 1);
    output.writeString(this.userId);
    output.writeFieldEnd();
  }
  if (this.userType !== null && this.userType !== undefined) {
    output.writeFieldBegin('userType', Thrift.Type.I32, 2);
    output.writeI32(this.userType);
    output.writeFieldEnd();
  }
  if (this.workaroundData !== null && this.workaroundData !== undefined) {
    output.writeFieldBegin('workaroundData', Thrift.Type.STRING, 3);
    output.writeString(this.workaroundData);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};




export {AppNotificationKey, AppNotificationType};