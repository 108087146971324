var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Deckblatt" },
    [
      _c("InsuranceOptionMenu", { attrs: { title: "Deckblatt" } }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("p", [
            _vm._v(
              "Diese Deckblätter dienen nur dem Hochladen von Dokumenten in das Dokumentenarchiv des Kunden im MSC."
            )
          ]),
          _c("InputRadioBoxGroup", {
            attrs: {
              title: "Bitte wählen Sie die Art der Person",
              validateUntouched: true,
              values: _vm.auswahlPersonValues,
              labelClass: "font-bold"
            },
            model: {
              value: _vm.form.auswahlPerson,
              callback: function($$v) {
                _vm.$set(_vm.form, "auswahlPerson", $$v)
              },
              expression: "form.auswahlPerson"
            }
          }),
          _c("ComboBox", {
            attrs: {
              label: "Versicherte Personen",
              values: _vm.personen,
              firstEmpty: false,
              isComponentHalfSize: "",
              disabled: _vm.form.auswahlPerson !== "CHECK_PERSON"
            },
            model: {
              value: _vm.form.person,
              callback: function($$v) {
                _vm.$set(_vm.form, "person", $$v)
              },
              expression: "form.person"
            }
          }),
          _c("InputField", {
            attrs: {
              isComponentHalfSize: "",
              label: "Seitenzahl des hochzuladenden Dokuments"
            },
            model: {
              value: _vm.form.seitenzahl,
              callback: function($$v) {
                _vm.$set(_vm.form, "seitenzahl", $$v)
              },
              expression: "form.seitenzahl"
            }
          }),
          _c("InputToggleSwitch", {
            staticClass: "parameter-search__input-toggle-switch",
            attrs: {
              label:
                "Der Antrag wurde bzw. wird von mir selbst an die Gesellschaft geschickt."
            },
            model: {
              value: _vm.form.bereitsGesendet,
              callback: function($$v) {
                _vm.$set(_vm.form, "bereitsGesendet", $$v)
              },
              expression: "form.bereitsGesendet"
            }
          }),
          _c("p", [_vm._v("Deckblätter Versicherung anlegen")]),
          _vm._l(_vm.layoutData.deckblaetterVersicherung, function(link, idx) {
            return _c(
              "span",
              { key: link.key },
              [
                _c("DownloadLink", {
                  attrs: {
                    downloadServicePath: "/deckblattAlsPDF",
                    title: link.value,
                    filename: "Deckblatt_Versicherungen.pdf",
                    queryParams: {
                      vertragId: _vm.vertragId,
                      action: link.key,
                      showPersonId: _vm.form.person.value,
                      seitenzahl: _vm.form.seitenzahl,
                      bereitsGesendet: _vm.form.bereitsGesendet,
                      textField: encodeURIComponent(_vm.form.textField)
                    }
                  }
                }),
                _c("br")
              ],
              1
            )
          }),
          _c("p", [_vm._v("Deckblätter Versicherungsschaden anlegen")]),
          _c("ComboBox", {
            attrs: {
              label: "Benutze Schadensfall",
              values: _vm.schadensfall,
              firstEmpty: false,
              isComponentHalfSize: ""
            },
            model: {
              value: _vm.form.schadensfall,
              callback: function($$v) {
                _vm.$set(_vm.form, "schadensfall", $$v)
              },
              expression: "form.schadensfall"
            }
          }),
          _vm.form.schadensfall !== "NICHT_VERWENDEN"
            ? _c(
                "div",
                _vm._l(
                  _vm.layoutData.deckblaetterVersicherungsschaden,
                  function(link, idx) {
                    return _c(
                      "span",
                      { key: link.key },
                      [
                        _c("DownloadLink", {
                          attrs: {
                            downloadServicePath: "/deckblattAlsPDF",
                            title: link.value,
                            filename: "Deckblatt_Versicherungsschaden.pdf",
                            queryParams: {
                              vertragId: _vm.vertragId,
                              action: link.key,
                              showPersonId: _vm.form.person.value,
                              seitenzahl: _vm.form.seitenzahl,
                              bereitsGesendet: _vm.form.bereitsGesendet,
                              schadenId: _vm.form.schadensfall,
                              textField: encodeURIComponent(_vm.form.textField)
                            }
                          }
                        }),
                        _c("br")
                      ],
                      1
                    )
                  }
                ),
                0
              )
            : _vm._e(),
          _c("p", [_vm._v("Text der bei Warnungen / Stornos angezeigt wird.")]),
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _vm.rows.length
                ? _c("Table", {
                    staticClass: "col-6 mr-2",
                    attrs: {
                      tableId: "62e38a92-abf1-4c29-b681-ec28d90b96b2",
                      headers: _vm.headers,
                      rows: _vm.rows,
                      rowsPerPage: 20
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "arrowAction",
                          fn: function(row) {
                            return [
                              _c("PhArrowRight", {
                                staticClass: "clickable",
                                attrs: { size: 16 },
                                on: {
                                  click: function($event) {
                                    return _vm.addText(row)
                                  }
                                }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3281609847
                    )
                  })
                : _vm._e(),
              _c("InputTextArea", {
                staticClass: "col-6",
                attrs: {
                  rows: 14,
                  label: "Bitte ergänzen Sie fehlende Angaben:"
                },
                model: {
                  value: _vm.form.textField,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "textField", $$v)
                  },
                  expression: "form.textField"
                }
              })
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }