<template>
  <PhArrowCircleLeft :size="48" weight="fill" :color="color" />
</template>

<script>
import { PhArrowCircleLeft, } from 'phosphor-vue';

export default {
  components: {
    PhArrowCircleLeft,
  },
  computed: {
    color() {
      return document.documentElement.style.getPropertyValue('--color-primary');
    },
  },
}
</script>
