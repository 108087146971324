var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.nodeId
    ? _c(
        "div",
        [
          _c(
            "BaseModal",
            {
              ref: "documentsModal",
              attrs: {
                modalTitle: "",
                closeButtonLabel: "",
                showDefaultButtons: false,
                actions: _vm.headerActions,
                autoCloseOnRouteNavigation: false
              },
              on: {
                action: function($event) {
                  return _vm.executeHeaderAction($event.value)
                },
                close: function($event) {
                  return _vm.$emit("close")
                }
              }
            },
            [
              _vm.selectedNodeViewType === _vm.VIEW_TYPE_NODES &&
              _vm.selectedNodeSubfolderAllowed
                ? _c("PrivateDokNodeTree", { attrs: { nodeId: _vm.nodeId } })
                : _c("DocumentsTable", {
                    attrs: {
                      nodeId: _vm.nodeId,
                      rowsPerPage: _vm.rowsPerPage,
                      linkVertrag: "",
                      loadAntraege: "",
                      enableDraggable: "",
                      isModal: "",
                      showNoDataMessageWithIcon: "",
                      title: _vm.title,
                      noHeaderActions: ""
                    }
                  })
            ],
            1
          ),
          _c(
            "BaseModal",
            {
              ref: "confirmationModal",
              attrs: {
                modalTitle: "Bestätigung",
                labelButtonConfirm: "Ok",
                showCancelButton: true
              },
              on: {
                onConfirmButton: function($event) {
                  return _vm.onConfirmHeaderActionModal()
                }
              }
            },
            [_c("label", [_vm._v(_vm._s(_vm.confirmationMessage))])]
          ),
          _c(
            "BaseModal",
            {
              ref: "sendEmailModal",
              attrs: {
                modalTitle: "Dokumente versenden",
                showDefaultButtons: false
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "footer",
                    fn: function() {
                      return [
                        _c(
                          "BaseButton",
                          {
                            attrs: { isSecondary: "" },
                            on: {
                              click: function($event) {
                                return _vm.closeSendEmailModal()
                              }
                            }
                          },
                          [_vm._v("Abbrechen")]
                        ),
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.confirmSendEmail()
                              }
                            }
                          },
                          [_vm._v("An Kunden")]
                        ),
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.confirmSendEmail(true)
                              }
                            }
                          },
                          [_vm._v("An Gesellschaft")]
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                1266622414
              )
            },
            [
              _vm._v(
                " An wen möchten Sie die ausgewählten Dokumente versenden? "
              )
            ]
          ),
          _c("EmailVersand"),
          _vm.files && _vm.currentAdditionalInputs
            ? _c("DocumentsUploadModal", {
                attrs: {
                  files: _vm.files,
                  additionalInputs: _vm.currentAdditionalInputs,
                  parentId: _vm.nodeId,
                  versicherungId: _vm.versicherungId,
                  schadenId: _vm.schadenId,
                  showOnlySchadenDocs: _vm.showOnlySchadenDocs,
                  maxFileSizeMB: _vm.maxFileSizeMB
                },
                on: {
                  close: function($event) {
                    _vm.files = null
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }