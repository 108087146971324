var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "editModal",
          attrs: { modalTitle: "Meta-Tags", showConfirmButton: false },
          on: {
            onCloseButton: function($event) {
              return _vm.close()
            },
            close: function($event) {
              return _vm.close()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "BaseButton",
                    {
                      on: {
                        click: function($event) {
                          return _vm.$refs.modalAdd.open()
                        }
                      }
                    },
                    [_vm._v("Meta-Tag hinzufügen")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          !_vm.loading && _vm.rows.length
            ? _c("Table", {
                attrs: {
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowsPerPage: 10
                },
                on: { "action-DELETE": _vm.deleteMetaTag }
              })
            : _vm.loading
            ? _c("GhostLoading", { attrs: { type: "table" } })
            : _c("NoData", { attrs: { noIcon: "" } }),
          _c("hr"),
          _c("div", { staticStyle: { "font-size": "0.8rem" } }, [
            _vm._v(" " + _vm._s(_vm.info) + " ")
          ])
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "modalAdd",
          attrs: { modalTitle: "Meta-Tags hinzufügen" },
          on: {
            onConfirmButton: function($event) {
              return _vm.saveMetaTag()
            }
          }
        },
        [
          _c("InputField", {
            attrs: { label: "Name" },
            model: {
              value: _vm.metaTagForm.name,
              callback: function($$v) {
                _vm.$set(_vm.metaTagForm, "name", $$v)
              },
              expression: "metaTagForm.name"
            }
          }),
          _c("InputTextArea", {
            attrs: { label: "Content" },
            model: {
              value: _vm.metaTagForm.content,
              callback: function($$v) {
                _vm.$set(_vm.metaTagForm, "content", $$v)
              },
              expression: "metaTagForm.content"
            }
          }),
          _c("div", { staticStyle: { "font-size": "0.8rem" } }, [
            _vm._v(" " + _vm._s(_vm.info) + " ")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }