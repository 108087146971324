<template>
  <div class="actions_container">
    <div class="action-icons" v-if="actions && actions.length">
      <template v-for="action in buttonsAllwaysVisible">
        <button type="button" class="btn-clean clickable"
          v-if="!showDownloadLink(action)"
          :tid="_generateTidFromString(action.legend.label+rowIndex)"
          v-bind:key="action.legend.label"
          :disabled="action.disabled && action.disabled(element) || action.loading && action.loading(element)"
          @click="executeAction(action)"
          :title="action.legend.label"
        >
          <AnimatedSpinner v-if="action.loading && action.loading(element)" v-bind="iconProperties(action)" />
          <component v-else :is="dynamicIconComponent(action.legend.icon, action)" v-bind="iconProperties(action)"/>
          <span v-if="action.legend.labelShowAlways">{{action.legend.label}}</span>
        </button>
        <template v-else>
          <DownloadLink 
            v-if="showDownloadLink(action)" 
            :key="action.legend.label"
            :href="action.legend.link || action.value" 
            :title="getTitle(action.legend.label)" />
          <a v-else target="_blank" :href="action.legend.link" :key="action.legend.label">
            <AnimatedSpinner v-if="action.loading && action.loading(element)" v-bind="iconProperties(action)" />
            <component v-else :is="dynamicIconComponent(action.legend.icon)" v-bind="iconProperties(action)"/>
            <span>{{action.legend.label}}</span>
          </a>
        </template>
      </template>

      <BaseContextMenu
        v-if="remainingButtons.length > 0"
        :tid="_generateTidFromString('ph-dots-three-vertical'+rowIndex)"
        placement="bottom-right"
      >
        <template #default>
          <template v-for="(action) in remainingButtons">
            <ContextMenuItem 
              v-if="!showDownloadLink(action)"
              :tid="_generateTidFromString('BaseDropdownMenu'+action.legend.label+rowIndex)"
              :key="action.legend.label"
              :disabled="action.disabled && action.disabled(element) || action.loading && action.loading(element)"
              @click="executeAction(action, true)"
            >
                <AnimatedSpinner v-if="action.loading && action.loading(element)" v-bind="iconProperties(action)" />
                <component v-else :is="dynamicIconComponent(action.legend.icon)" v-bind="iconProperties(action)"/>
                <span v-html="createColorizedLabel(action.legend)"> </span>
            </ContextMenuItem>
            <ContextMenuItem v-else :key="action.legend.label">
              <DownloadLink 
                v-if="showDownloadLink(action)" 
                :href="action.legend.link || action.value" 
                :title="getTitle(action.legend.label)" 
                class="btn-clean clickable">
                <AnimatedSpinner v-if="action.loading && action.loading(element)" v-bind="iconProperties(action)" />
                <component v-else :is="dynamicIconComponent(action.legend.icon)" v-bind="iconProperties(action)"/>
                <span>{{action.legend.label}}</span>
              </DownloadLink>
              <a v-else target="_blank" :href="action.legend.link" class="btn-clean clickable" :key="action.legend.label">
                <AnimatedSpinner v-if="action.loading && action.loading(element)" v-bind="iconProperties(action)" />
                <component v-else :is="dynamicIconComponent(action.legend.icon)" v-bind="iconProperties(action)"/>
                <span>{{action.legend.label}}</span>
              </a>
            </ContextMenuItem>
          </template>
        </template>
      </BaseContextMenu>
    </div>
  </div>
</template>

<script>
import * as phosphor from 'phosphor-vue';
import BaseContextMenu from "@/components/core/BaseContextMenu.vue";
import ContextMenuItem from "@/components/core/base-context-menu/ContextMenuItem.vue";
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types'
import DownloadLink from '@/components/core/download/DownloadLink.vue';

export default {
  name: "TableActions",
  mixins: [InteractiveHelpCommonsMixin],
  props: {
    // Table row element
    element: {
      default: null,
    },
    rowIndex: {
      tpy: Number,
      default: -1
    },
    /* 
    * A list of objects to configure each action button
    * Example: 
    * [
    *    {
    *      legend: {
    *        index: "1",
    *        key: "TRANSAKTIONEN",
    *        label: "Transaktionen",
    *        icon: PhArrowsLeftRight,
    *        link: '/home' //optional;
    *        linkType: 'DOWNLOAD' //optional, options = URL or DOWNLOAD default is URL;
    *      }
    *    }
    *  ]
    * 
    * Where: 
    * index: button position in list
    * key: name of event emited when button is clicked
    * label: text to show on button
    * icon: reference to Phosphor component 
    * 
    * Hide and show actions:
    * 
    * It's possible to hide/show actions by using ROLES or defining a visible() function to the action.
    * 
    * by ROLES: 
    * {
    *   roles: {
    *     allowed: [ROLES.FK],
    *     denied: [ROLES.FK],
    *   }
    * }
    * 
    * by visible(): the visible function will be executed
    * 
    * {
    *   visible(row) {
    *     return row.field > 10
    *   }
    * }
    */
    actions: {
      default: [],
    }
  },
  components: {
    BaseContextMenu,
    ContextMenuItem,
    AnimatedSpinner,
    DownloadLink,
  },
  data: function () {
    return {
      showDropdown: false,
      defaultIconProps: {
        size: 24
      }
    };
  },
  computed: {
    ...mapGetters({
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
    actionList() {
      const actions = this.actions?.filter(action => {
        let showAction = true;
        if (!action.roles?.allowed && !action.roles?.denied) {
          return showAction
        }

        if (action.roles?.allowed) {
          showAction = this.hasRoles(action.roles.allowed)
        }

        if (showAction && action.roles?.denied) {
          showAction = !this.hasRoles(action.roles.denied)
        }

        return showAction;
      })


      return actions?.filter((action) => {
        if(action.visible) {
          return action.visible(this.element);
        }
        return true;
      })?.sort((a, b) =>
        a.legend.index < b.legend.index ? -1 : 1
      );
    },
    buttonsAllwaysVisible() {
      return []
    },
    remainingButtons() {
      if(!this.actionList?.length) return [];
      return this.actionList.filter((action) => !this.buttonsAllwaysVisible.some((actionVisible) => action === actionVisible) && (!action.legend || (action.legend && !action.legend.hidden)));
    },
  },
  methods: {
    getTitle(label) {
      return label === 'KID' ? 'PRIIP-BIB' : label;
    },
    showDownloadLink(action) {
      return ['DOWNLOAD', 'KID', 'FACTSHEET_KAG', 'FACTSHEET'].includes(action.legend.key) && (action.legend.link || action.value)
    },
    iconProperties(action){
      const iconProps = action?.legend?.iconProps || this.defaultIconProps
      return { ...iconProps, 'data-svg-action-key': action?.legend?.key};
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    executeAction: function (whatAction, closeDropdown) {
      if (closeDropdown) {
        this.toggleDropdown();
      }
      this.$emit("execute-action", {
        action: whatAction,
        value: this.element,
      });
    },
    selectElement(whatElement) {
      this.$emit("select-element", whatElement);
    },
    createColorizedLabel(element) {
      if(element && element.label && element.sub1 && element.sub2 && element.color){
        var result = this.getColorized(element.label, element.sub1, element.sub2, element.color)
        var final = ''
        result.forEach((item) =>{
          final += item
        })
        return final;
      }
      return element.label;
    },
    dynamicIconComponent(icon) {
      if(typeof icon === 'string'){
        return phosphor[icon];
      }
      return icon;
    },
    getFromBetween:function (sub1,sub2) {
      if(this.string.indexOf(sub1) < 0 || this.string.indexOf(sub2) < 0) return false;
      var SP = this.string.indexOf(sub1)+sub1.length;
      var string1 = this.string.substr(0,SP);
      var string2 = this.string.substr(SP);
      var TP = string1.length + string2.indexOf(sub2);
      var streetaddress= this.string.substr(0, this.string.indexOf(sub1));
      if(!this.isEmpty(streetaddress)){
        this.results.push('<span>' + streetaddress + '</span>')
      }
      return this.string.substring(SP,TP);
    },
    removeFromBetween(result, sub1,sub2) {
        if(this.string.indexOf(sub1) < 0 || this.string.indexOf(sub2) < 0) return false;
        var SP = this.string.indexOf(sub2)+sub2.length;
        var string1 = this.string.substr(0,SP);
        //var removal = sub1+result+sub2;
        this.string = this.string.replace(string1,"");
    },
    getAllResults(sub1,sub2) {
      // first check to see if we do have both substrings
      if(this.string.indexOf(sub1) < 0 || this.string.indexOf(sub2) < 0) {
        this.results.push(this.string)
      return;
      }


      // find one result
      var result = this.getFromBetween(sub1,sub2);

      // push it to the results array
      this.results.push('<span style="color:' + this.color + '">' + result + '</span>');

      // remove everything before the most recently found one from the string
      this.removeFromBetween(result, sub1,sub2);

      // if there's more substrings
      if(this.string.indexOf(sub1) > -1 && this.string.indexOf(sub2) > -1) {
          this.getAllResults(sub1,sub2);
      }
      else if(!this.isEmpty(this.string)){
        this.results.push('<span>' + this.string + '</span>')
      }
        
      return;
    },
    getColorized(string,sub1,sub2, color) {
      this.results = [];
      this.string = string;
      this.color = color;
      this.getAllResults(sub1,sub2);
      return this.results;
    },
    isEmpty(str) {
      return (!str || str.length === 0 );
    }
  },
  
};
</script>

<style scoped>
.actions_container {
  display: flex;
  justify-content: flex-end;
  min-width: max-content !important;
}

.action-texts {
  display: flex;
  flex-direction: column;
  padding: 2px;
  color: var(--color-text);
  justify-content: left;
  font-size: 0.85rem;
}

.action-icons {
  display: flex;
  white-space: nowrap;
  min-width: max-content !important;
  justify-content: flex-end;
}

.btn-clean {
  color: var(--color-text);
  background: none;
  border: none;
  outline: none;
  text-align: left;
  width: max-content;
  display: block
}
a.btn-clean {
  padding: 1px 6px;
}
a.btn-clean:hover {
  text-decoration: none;
}
</style>
