<template>
<div class="pagination">
    <ul class="fc-pagination">
        <li :class="{ 'page-disabled': currentPage <= 0 }">
            <a @click="onClickPreviousPage">
                <PhCaretLeft :size="16" />
                <span class="hide-on-small-screen ml-2">zurück</span>
            </a>
        </li>

        <template v-for="pageId in bigScreenList">
            <li :key="pageId" :class="{ 
                    'page-disabled': currentPage == pageId,
                    'page-active': currentPage == pageId,
                }"
                class="hide-on-small-screen"
            >
                <span class="dotdotdot" v-if="pageId < 0">...</span>
                <a v-else @click="onClickPage(pageId)">
                    {{ pageId + 1 }}
                </a>
            </li>
        </template>

        <template v-for="pageId in smallScreenList">
            <li :key="'small' + pageId" :class="{ 
                    'page-disabled': currentPage == pageId,
                    'page-active': currentPage == pageId,
                }"
                class="hide-on-large-screen"
            >
                <span class="dotdotdot" v-if="pageId < 0">...</span>
                <a v-else @click="onClickPage(pageId)">
                    {{ pageId + 1 }}
                </a>
            </li>
        </template>

        <li :class="{ 'page-disabled': currentPage >= totalPages - 1 }">
            <a @click="onClickNextPage" >
                <span class="hide-on-small-screen mr-2">weiter</span>
                <PhCaretRight :size="16" />
            </a>
        </li>
    </ul>
</div>
</template>

<script>
import { PhCaretLeft, PhCaretRight } from 'phosphor-vue';

export default {
	props: {
        totalPages: {
            type: Number,
            required: true
        },
        currentPage: { // 0-based index
            type: Number,
            required: true
        },
    },
    computed: {
        bigScreenList() {
            return this.calculatePageList(1, 1);
        },
        smallScreenList() {
            return this.calculatePageList(0, 1);
        },
    },
    methods: {
        calculatePageList(additionalNumbersSide, additionalNumbersCenter) {
            const result = [];
            let suppressedByEllipsis = false;
            let ellipsisCode = -1;
            for (let i = 0; i < this.totalPages; i++) {
                const distanceFromSides = Math.min(i, this.totalPages - 1 - i);
                const distanceFromCenter = Math.abs(this.currentPage - i);
                if (distanceFromSides <= additionalNumbersSide || distanceFromCenter <= additionalNumbersCenter) {
                    result.push(i);
                    suppressedByEllipsis = false;
                } else if (!suppressedByEllipsis) {
                    suppressedByEllipsis = true;
                    result.push(ellipsisCode--); // the -- makes sure vue gets unique keys in v-for loop
                }
            }
            return result;
        },
        onClickPage(page) {
            this.$emit('pagechanged', page);
        },
        onClickPreviousPage() {
            this.onClickPage(this.currentPage - 1);
        },
        onClickNextPage() {
            this.onClickPage(this.currentPage + 1);
        },
    },
    components: {
        PhCaretLeft,
        PhCaretRight,
    },
};
</script>

<style scoped>
.pagination {
    flex-grow: 1;
    text-align: right;
    display: flex;
    justify-content: flex-end;
}
ul {
    margin-bottom: 0;
    margin-top: 0;
}
.page-active {
    font-weight: bold;
}
li.page-disabled a {
    cursor: auto;
    color: var(--color-text);
}
li a {
    cursor: pointer;
}
.dotdotdot {
    padding: 5px 2px;
}
.container {
    float: right;
}
</style>
