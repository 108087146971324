import FAHRZEUGDATEN_TYPES from './types';
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";
import CORE_TYPES from '../core/types';
import LOG_TYPES from '@/store/log/types';
import { buildMessage } from "@/helpers/log-message-helper";
import axios from 'axios';

const config = {
  defaultSpinner: true
};

export default {
  [FAHRZEUGDATEN_TYPES.ACTIONS.UPDATE_FAHRZEUGDATEN]({ getters, commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {

      const URL =`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/fahrzeugdaten/updateFahrzeugdaten`;

      let params = {};
      
      if(payload?.versicherungId) {
        params.vertragId = payload.versicherungId;
      }

      axios.put(URL, payload.fahrzeugdaten, {params, config})
        .then((response) => {
          commit(VERSICHERUNG_TYPES.MUTATIONS.UPDATE_FAHRZEUGDATEN_SUCCESS, {...response.data})
          resolve(response?.data);
        })
        .catch((error) => {
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error, 'danger'));
          reject(error);
        });
    });
  },
  [FAHRZEUGDATEN_TYPES.ACTIONS.CREATE_FAHRZEUGDATEN]({ getters, commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {

      const URL =`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/fahrzeugdaten/createFahrzeugdaten`;

      let params = {};
      
      if(payload?.versicherungId) {
        params.vertragId = payload.versicherungId;
      }

      axios.post(URL, payload.fahrzeugdaten, {params, config})
        .then((response) => {
          commit(VERSICHERUNG_TYPES.MUTATIONS.CREATE_FAHRZEUGDATEN_SUCCESS, {...response.data})
          resolve(response?.data);
        })
        .catch((error) => {
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error, 'danger'));
          reject(error);
        });
    });
  },
}