var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isMobileNativeContext
        ? _c(
            "PageHeaderTitleNavigation",
            _vm._g(
              {
                attrs: {
                  title: "Profil",
                  subtitle: "Datenschutzerklärung",
                  actions: _vm.headerActions()
                },
                on: {
                  "action-ABLEHNEN": function($event) {
                    return _vm.$refs.rejectTermsModal.open()
                  },
                  "action-AKZEPTIEREN": function($event) {
                    return _vm.checkAcceptTerms()
                  }
                }
              },
              _vm.$listeners
            )
          )
        : _vm._e(),
      _c("HinweiseUndFehler", { attrs: { hints: _vm.hints } }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("div", {
            domProps: { innerHTML: _vm._s(_vm.mainHtmlText) },
            on: { click: _vm.openLink }
          }),
          !_vm.hideAcceptLabelHtmlText
            ? _c("InputCheckBoxItem", {
                attrs: {
                  label: _vm.acceptLabelHtmlText,
                  disabled: _vm.isKundenzugang
                },
                on: { click: _vm.openLink },
                model: {
                  value: _vm.internalForm.isConfirmed,
                  callback: function($$v) {
                    _vm.$set(_vm.internalForm, "isConfirmed", $$v)
                  },
                  expression: "internalForm.isConfirmed"
                }
              })
            : _vm._e(),
          _vm.isTestCustomer
            ? _c("InputCheckBoxItem", {
                attrs: {
                  label: _vm.erstinformationText,
                  disabled: _vm.isKundenzugang
                },
                on: { click: _vm.openLink },
                model: {
                  value: _vm.internalForm.hasErstinformation,
                  callback: function($$v) {
                    _vm.$set(_vm.internalForm, "hasErstinformation", $$v)
                  },
                  expression: "internalForm.hasErstinformation"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("div", { staticClass: "box__title" }, [
            _vm._v("Ich möchte künftig kontaktiert werden per")
          ]),
          _vm._l(_vm.contactValues, function(contactValue) {
            return _c(
              "div",
              [
                _c("InputCheckBoxItem", {
                  attrs: {
                    label: contactValue.label,
                    disabled:
                      _vm.isKundenzugang ||
                      (_vm.isVVCustomer && contactValue.path === "app")
                  },
                  model: {
                    value: _vm.internalForm.privacyChecks[contactValue.path],
                    callback: function($$v) {
                      _vm.$set(
                        _vm.internalForm.privacyChecks,
                        contactValue.path,
                        $$v
                      )
                    },
                    expression: "internalForm.privacyChecks[contactValue.path]"
                  }
                }),
                _vm.isVVCustomer && contactValue.path === "app"
                  ? _c(
                      "span",
                      { staticStyle: { color: "var(--color-danger)" } },
                      [
                        _vm._v(
                          "Sie sind Kunde der Vermögensverwaltung. Hier herrscht eine gesetzliche Reportingpflicht. Eine Änderung der Einstellung ist nicht möglich. Bei weiteren Fragen wenden Sie sich bitte an Ihren Berater."
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          })
        ],
        2
      ),
      !_vm.isMobileNativeContext
        ? _c("div", { staticClass: "box__container" }, [
            _c("div", [
              _c(
                "div",
                { staticClass: "layout__negative-margin--8" },
                [
                  _c(
                    "BaseButton",
                    {
                      attrs: {
                        isSecondary: "",
                        disabled: !!_vm.isLoading || _vm.isKundenzugang
                      },
                      on: {
                        click: function($event) {
                          return _vm.$refs.rejectTermsModal.open()
                        }
                      }
                    },
                    [_vm._v(" Ablehnen ")]
                  ),
                  _c(
                    "BaseButton",
                    {
                      attrs: {
                        disabled:
                          (!_vm.hideAcceptLabelHtmlText &&
                            _vm.validation.isInvalid("internalForm")) ||
                          !!_vm.isLoading ||
                          _vm.isKundenzugang
                      },
                      on: {
                        click: function($event) {
                          return _vm.checkAcceptTerms()
                        }
                      }
                    },
                    [
                      _vm._v(" Akzeptieren "),
                      !!_vm.isLoading ? _c("AnimatedSpinner") : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _c(
        "BaseModal",
        {
          ref: "rejectTermsModal",
          attrs: { labelButtonConfirm: "Ok", labelButtonCancel: "Abbrechen" },
          on: {
            onConfirmButton: function($event) {
              return _vm.handleTermRejected()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _vm._v(
                    " " +
                      _vm._s(_vm.ablehnenDataschutzerklaerungMessageTitle) +
                      " "
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [_vm._v(" " + _vm._s(_vm.ablehnenDataschutzerklaerungMessage) + " ")]
      ),
      _c(
        "BaseModal",
        {
          ref: "confirmContactModal",
          attrs: { labelButtonConfirm: "Ok", labelButtonCancel: "Abbrechen" },
          on: {
            onConfirmButton: function($event) {
              return _vm.acceptTerms()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _vm._v(" " + _vm._s(_vm.kommunikationMessageTitle) + " ")
                ]
              },
              proxy: true
            }
          ])
        },
        [_vm._v(" " + _vm._s(_vm.kommunikationMessage) + " ")]
      ),
      _c(
        "BaseModal",
        {
          ref: "refuseAppModal",
          attrs: { labelButtonConfirm: "Ok", showCancelButton: false },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [_vm._v(" Achtung, keine App-Kontaktmöglichkeit ")]
              },
              proxy: true
            }
          ])
        },
        [
          _vm._v(
            ' Bitte beachten Sie, dass Sie keine Nachrichten mehr schicken bzw. auf keine Nachrichten antworten können, wenn Sie die Kontaktmöglichkeit "App" abwählen. '
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }