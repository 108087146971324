var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.selectedUserLevel
    ? _c(
        "div",
        { staticClass: "option-menu-edit-page__container" },
        [
          _c("Tabs", {
            staticClass: "mt-1 mb-2",
            attrs: {
              tabList: _vm.tabList,
              selected: _vm.selectedUserLevel,
              disabled: _vm.isLoading
            },
            on: {
              tabSelected: function($event) {
                return _vm.selectUserLevel($event.key)
              }
            }
          }),
          _c(
            "div",
            [
              _vm.isLoading
                ? _c("OptionMenuGhostLoading")
                : _c("OptionMenuConfig", {
                    key: _vm.selectedUserLevel,
                    attrs: {
                      configId: _vm.configId,
                      defaultMenu: _vm.defaultMenu,
                      optionMenu: _vm.optionMenu,
                      userLevel: _vm.selectedUserLevel,
                      configContext: _vm.configContext,
                      currentPath: _vm.currentPath,
                      isPermissionConfigurable: _vm.isPermissionConfigurable,
                      isExpandedLayout: ""
                    }
                  }),
              _c(
                "div",
                {
                  staticClass:
                    "option-menu-edit-page__buttons d-flex mt-3 layout__negative-margin--8"
                },
                [
                  _c(
                    "BaseButton",
                    {
                      attrs: { isSecondary: "", disabled: _vm.isLoading },
                      on: {
                        click: function($event) {
                          return _vm.openResetConfigModal()
                        }
                      }
                    },
                    [_vm._v("Standard wiederherstellen")]
                  ),
                  _vm.selectedUserLevel === _vm.FC_CONFIG_USER_LEVEL.MAKLER
                    ? [
                        _vm.hasUserLevel(_vm.FC_CONFIG_USER_LEVEL.KUNDE)
                          ? _c(
                              "BaseButton",
                              {
                                attrs: {
                                  isSecondary: "",
                                  disabled: _vm.isLoading
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.copyUserLevelTo(
                                      _vm.selectedUserLevel,
                                      _vm.FC_CONFIG_USER_LEVEL.KUNDE
                                    )
                                  }
                                }
                              },
                              [_vm._v("In aktueller Kunde übernehmen")]
                            )
                          : _vm._e(),
                        _vm.hasUserLevel(
                          _vm.FC_CONFIG_USER_LEVEL.MAKLER_STRUKTUR
                        )
                          ? _c(
                              "BaseButton",
                              {
                                attrs: {
                                  isSecondary: "",
                                  disabled: _vm.isLoading
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.copyUserLevelTo(
                                      _vm.selectedUserLevel,
                                      _vm.FC_CONFIG_USER_LEVEL.MAKLER_STRUKTUR
                                    )
                                  }
                                }
                              },
                              [_vm._v("In Unterstruktur übernehmen")]
                            )
                          : _vm._e(),
                        _vm.hasUserLevel(
                          _vm.FC_CONFIG_USER_LEVEL.MAKLER_PERSONEN
                        )
                          ? _c(
                              "BaseButton",
                              {
                                attrs: {
                                  isSecondary: "",
                                  disabled: _vm.isLoading
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.copyUserLevelTo(
                                      _vm.selectedUserLevel,
                                      _vm.FC_CONFIG_USER_LEVEL.MAKLER_PERSONEN
                                    )
                                  }
                                }
                              },
                              [_vm._v("In Mitarbeiter übernehmen")]
                            )
                          : _vm._e()
                      ]
                    : _vm.selectedUserLevel ===
                        _vm.FC_CONFIG_USER_LEVEL.MAKLER_STRUKTUR ||
                      _vm.selectedUserLevel ===
                        _vm.FC_CONFIG_USER_LEVEL.MAKLER_PERSONEN
                    ? [
                        _c("OptionsMenuApplyButton", {
                          attrs: {
                            configContext: _vm.configContext,
                            configId: _vm.configId,
                            userLevel: _vm.selectedUserLevel,
                            disabled: _vm.isLoading
                          }
                        }),
                        _c("OptionsMenuApplyButton", {
                          attrs: {
                            configContext: _vm.configContext,
                            configId: _vm.configId,
                            userLevel: _vm.selectedUserLevel,
                            disabled: _vm.isLoading,
                            isPartial: ""
                          }
                        })
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          ),
          _c("OptionMenuResetConfigModal", {
            ref: "resetConfigModal",
            attrs: {
              configContext: _vm.configContext,
              configContextLabel: _vm.configContextLabel,
              currentMenu: _vm.currentMenu,
              userLevel: _vm.selectedUserLevel,
              enablePermissionConfig: _vm.enablePermissionConfig
            },
            on: { onResetDefault: _vm.onResetConfigPage }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }