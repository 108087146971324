<template>
  <ul class="tree" :class="`level-${_level}`">
    <li v-for="(item, index) in itemsWithParents" :key="index" class="tree__item"
      :class="{
        'has-children': item[childrenKey] && item[childrenKey].length, 
        'selected': (itemSelectedId === item[idKey]),
        'opened': !collapsed(item) && item[childrenKey] && item[childrenKey].length,
      }">
      <div class="tree__item--container" v-draggable-target="item.$droppable" @dropped="$emit('dropped', $event)">
        <button type="button" v-if="item[childrenKey] && item[childrenKey].length" 
          @click="toggleCollapse(item)" class="tree__item--collapse-toggle clickable" :class="{ 'opened': !collapsed(item) }" :tid="_generateTidFromString('btn1'+item.nodeId)" >
          <ph-caret-right :size="24"></ph-caret-right>
        </button>
        <span @click="handleClick(item, $event, true)" class="tree__item--content clickable" :tid="_generateTidFromString(item.nodeId)">
          <slot :item="item"></slot>
        </span>
        <button v-if="showArrowRight" type="button" @click="handleClick(item, $event, true);" class="tree__item--arrow-right clickable" :tid="_generateTidFromString('btn2'+item.nodeId)">
          <ph-caret-right  :size="24"></ph-caret-right>
        </button>
      </div>
      <transition name="collapse">
        <Tree 
          ref="subtree"
          class="subtree" 
          v-if="item[childrenKey] && item[childrenKey].length" 
          v-show="!collapsed(item)" 
          :items="item[childrenKey]" 
          :childrenKey="childrenKey"
          :idKey="idKey"
          :itemSelectedId="itemSelectedId" 
          :showArrowRight="showArrowRight" 
          :defaultCollapsed="defaultCollapsed"
          :uniqueId="uniqueId"
          :_level="_level + 1"
          :isCollapsable="isCollapsable"
          @itemSelect="handleClick"
          @dropped="$emit('dropped', $event)">
          <template #default="{ item }">
            <slot :item="item"></slot>
          </template>
        </Tree>
      </transition>
    </li>
  </ul>
</template>

<script>
import { PhCaretRight } from 'phosphor-vue';
import { mapGetters } from 'vuex';
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import DOCUMENT_TYPES from '@/store/documents/types';

import FC_CONFIG_TYPES from '@/store/fcConfig/types';
import FC_CONFIG from '@/configs/fcConfig.js';

const DATA_TREE_IGNORE_CLICK_ATTR = 'data-tree-ignore-click';

export default {
  name: "Tree",
  mixins: [InteractiveHelpCommonsMixin],
  props: {
    items: {
      type: Array,
    },
    childrenKey: {
      type: String,
      default: 'children',
    },
    idKey: {
      type: String,
      default: 'id',
    },
    itemSelectedId: {
      type: [Number, String],
      default: null,
    },
    showArrowRight: {
      type: Boolean,
      default: true,
    },
    defaultCollapsed: {
      type: Boolean,
      default: true,
    },
    // uniqueId must be unique. This Id is being used to save the collapsed state of the tree
    // a UUID randomly generated is enough. https://www.uuidgenerator.net/version4 or any other generator.
    uniqueId: {
      type: String,
    },

    /* Internal props */
    _level: {
      type: Number,
      default: 0,
    },
    isCollapsable: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['itemSelect'],
  data() {
    return {
      localCollapse: {},
    };
  },
  watch: {
    savedCollapseMap(val) {
      if (this.isRoot && this.uniqueId) {
        if (!val) {
          return
        }

        this.$store.commit(DOCUMENT_TYPES.MUTATIONS.SET_COLLAPSED, {uid: this.uniqueId, data: val});
      }
    },
  },
  computed: {
    ...mapGetters({
      collapseMap: DOCUMENT_TYPES.GETTERS.COLLAPSED,
      collapseConfigs: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_COLLAPSED_TREE,
    }),
    collapse() {
      return this.collapseMap[this.uniqueId]
    },
    savedCollapseMap() {
      if (!this.collapseConfigs) {
        return null
      }
     
      const savedConfig = this.collapseConfigs[this.uniqueId]
      if (!savedConfig?.content) {
        return null
      }

      return JSON.parse(savedConfig.content)
    },
    itemsWithParents() {
      let result = [];
      this.items && this.items.forEach(parent => {
        const hasChildren = parent[this.childrenKey] && parent[this.childrenKey].length;
        if (hasChildren) {
            parent[this.childrenKey].forEach(child => {
              child.parent = parent;
            })
        }

        result.push(parent)
      });
      return result;
    },
    isRoot() {
      return this._level === 0
    },
  },
  mounted() {
    if (this.isRoot && this.uniqueId) {
      const payload = {
        configId: this.uniqueId,
        configType: FC_CONFIG.COLLAPSED_TREE_CONFIG,
      } 
      
      this.$store.dispatch(FC_CONFIG_TYPES.ACTIONS.LOAD_FC_CONFIG, payload)
    }
  },
  beforeDestroy() {
    if (this.isRoot && this.uniqueId) {
      const payload = {
        configId: this.uniqueId,
        configType: FC_CONFIG.COLLAPSED_TREE_CONFIG,
        content: JSON.stringify(this.collapseMap[this.uniqueId]),
      };

      this.$store.dispatch(FC_CONFIG_TYPES.ACTIONS.SAVE_FC_CONFIG, payload);
    }
  },
  methods: {
    collapsed(item) {

      // if no uniqueId is given we save the collapsed status inside the component
      if (!this.uniqueId) {
        if (this.localCollapse?.hasOwnProperty(item[this.idKey])) {
          return this.localCollapse[item[this.idKey]]
        }
      } else if (this.collapse?.hasOwnProperty(item[this.idKey])) {
        return this.collapse[item[this.idKey]]
      }

      return this.defaultCollapsed
    },
    handleClick(item, $event, toggleCollapse) {
      const clickedEl = $event.target.closest(`[${DATA_TREE_IGNORE_CLICK_ATTR}]`) || $event.target;
      if(clickedEl?.hasAttribute(DATA_TREE_IGNORE_CLICK_ATTR)) return;

      if (this.isCollapsable && toggleCollapse && item[this.childrenKey] && item[this.childrenKey].length) {
        this.toggleCollapse(item);
      }

      this.$emit('itemSelect', item, $event);
    },
    toggleCollapse(item) {
      this.setCollapsed(item, !this.collapsed(item))
    },
    expandItem(itemId) {
      if(!itemId) return;

      const item = this.items?.find(item => item[this.idKey] === itemId);
      if(item) {
        this.setCollapsed(item, true)
      } else if(!item && this.$refs.subtree?.length) {
        this.$refs.subtree.forEach(ref => ref.expandItem(itemId));
      }
    },
    setCollapsed(item, value) {
        if (!this.uniqueId) {
          this.$set(this.localCollapse, item[this.idKey], value);
        } else {
          this.$store.commit(DOCUMENT_TYPES.MUTATIONS.UPDATE_COLLAPSED, {uid: this.uniqueId, key: item[this.idKey], value});
        }
    },
  },
  components: {
    PhCaretRight,
  }
}
</script>

<style scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul ul {
  margin-left: 24px;
}
ul button {
  background: none;
  border: none;
  display: flex;
  margin: 0;
  padding: 0;
  outline: none;
}
.tree__item {
  padding: 8px 0 8px 24px;
}
.tree__item:first-child {
  padding-top: 12px;
}
.level-0 > .tree__item:first-child {
  padding-top: 0;
}
.tree__item:last-child {
  padding-bottom: 0;
}
.tree__item.has-children {
  padding-left: 0;
}
.tree__item.opened {
  border-bottom: 1px solid #bbb;
}
.tree__item.opened:last-child {
  border-bottom: none;
}
.tree__item--container {
  display: flex;
}
.tree__item--container.available-target {
  margin-right: 2px;
}
.selected > .tree__item--container {
  font-weight: 600;
}
.tree__item--collapse-toggle,
.tree__item--arrow-right {
  align-self: center;
  flex: 0 0 16px;
}
.tree__item--collapse-toggle {
  margin: 0 8px 0 0;
  transition: transform .3s ease;
}
.tree__item--collapse-toggle.opened {
  transform: rotate(90deg);
}
.tree__item--content {
  flex: 1 1 auto;
  margin: 0;
}
.tree__item--arrow-right {
  margin: 0 0 0 8px;
}
.subtree.collapse-enter-active,
.subtree.collapse-leave-active {
  overflow: hidden;
  transition: max-height .3s ease;
}
.subtree.collapse-enter,
.subtree.collapse-leave-to {
  max-height: 0;
}
.subtree.collapse-enter-to,
.subtree.collapse-leave {
  max-height: 300px;
}
@media screen and (min-width: 769px) {
  .tree__item--arrow-right {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .tree__item--arrow-right {
    display: flex;
  }
}
</style>
