var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BoxContainer",
        { attrs: { title: "Kennnummern und Klassifizierung" } },
        _vm._l(_vm.fundsDetailList, function(idNumbersClassifications, index) {
          return _c(
            "dl",
            {
              key: "idNumbersClassifications" + index,
              staticClass: "row ml-0"
            },
            [
              _c("dt", { staticClass: "pl-0 col-md-3" }, [
                _vm._v(_vm._s(_vm.getDetailLabel(idNumbersClassifications)))
              ]),
              _c("dd", { staticClass: "pl-0 col-md-9" }, [
                idNumbersClassifications.dataType === "Double"
                  ? _c("div", [
                      _c("span", [
                        _vm._v(_vm._s(idNumbersClassifications.value + " €"))
                      ])
                    ])
                  : idNumbersClassifications.dataType === "Procent"
                  ? _c("div", [
                      _c("span", [
                        _vm._v(_vm._s(idNumbersClassifications.value + " %"))
                      ])
                    ])
                  : _c("span", [_vm._v(_vm._s(idNumbersClassifications.value))])
              ])
            ]
          )
        }),
        0
      ),
      _c(
        "BoxContainer",
        { attrs: { title: _vm.schwerpunktLabel } },
        _vm._l(_vm.schwerpunktRecords, function(schwerpunkt, index) {
          return _c("dl", { key: "schwerpunkt" + index, staticClass: "row" }, [
            _c("dt", { staticClass: "col-md-1" }, [
              _vm._v(_vm._s(schwerpunkt.type + ":"))
            ]),
            _c("dd", { staticClass: "col-md-11" }, [
              _vm._v(_vm._s(schwerpunkt.value))
            ])
          ])
        }),
        0
      ),
      _c(
        "BoxContainer",
        { attrs: { title: _vm.kostenLabel } },
        _vm._l(_vm.kostenRecords, function(kosten, index) {
          return _c("dl", { key: "kosten" + index, staticClass: "row" }, [
            _c("dt", { staticClass: "col-sm-3 col-md-5 col-lg-3" }, [
              _vm._v(_vm._s(kosten.type + ":"))
            ]),
            _c("dd", { staticClass: "col-sm-9 col-md-7 col-lg-9" }, [
              _vm._v(_vm._s(kosten.value))
            ])
          ])
        }),
        0
      ),
      _c("div", { staticClass: "box__container" }, [
        _c("div", { staticClass: "box__title" }, [
          _vm._v(_vm._s(_vm.kostenLabel))
        ]),
        _c("div", {
          staticClass: "text_wrap",
          domProps: {
            innerHTML: _vm._s(_vm.sanitize(_vm.allgemeinDescription))
          }
        })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }