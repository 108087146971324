var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.loading && _vm.rows.length
        ? _c("Table", {
            attrs: {
              headers: _vm.headers,
              rows: _vm.rows,
              rowsPerPage: _vm.rowPerPage,
              tableRowsPerPage: [],
              ignoreTablePageSize: "",
              hidePagination: "",
              mobileConfig: _vm.mobileConfig,
              rowId: "index"
            },
            on: {
              "click-name": _vm.openCustomerNewTab,
              "action-email": _vm.createEmail
            }
          })
        : _vm.loading
        ? _c("GhostLoading", {
            attrs: {
              type: "table",
              config: { table: { rows: _vm.rowPerPage } }
            }
          })
        : _c("NoData", { attrs: { noIcon: "" } }),
      !_vm.loading && !_vm.$isSmallScreen
        ? _c(
            "div",
            { staticClass: "bottom-row text-right mt-3" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      name: "customer-search",
                      params: { defaultFilter: _vm.defaultFilters }
                    }
                  }
                },
                [_vm._v("weitere...")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }