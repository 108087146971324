import { mapGetters } from 'vuex'
import CUSTOMERDATA_TYPES from '@/store/customerData/types'

const PERSONALDATAADDRESS_DEFAULT = {
  title: '',
  firstName: '',
  lastName: '',
};

const PERSONALDATABIRTH_DEFAULT = {
  dayOfBirth: '',
};


export default {
  computed: {
    ...mapGetters({
      personId: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_ID,
      customerDataEdited: CUSTOMERDATA_TYPES.GETTERS.GET_CUSTOMER_DATA_EDITED,
      customerDataConfig: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_CONFIG,
    }),
  },
  watch: {
    customerDataEdited: {
      handler() {
        this.copyPersonalDataAddress();
        this.copyPersonalDataBirthIfNeeded();
      },
      immediate: true,
    }
  },
  methods: {
    copyPersonalDataAddress() {
      this.personalDataAddress = Object.assign({}, PERSONALDATAADDRESS_DEFAULT, this.customerDataEdited?.personalDataAddress);
    },
    copyPersonalDataBirthIfNeeded() {
      if(!this.personalDataBirth) return;
      this.personalDataBirth = Object.assign({}, PERSONALDATABIRTH_DEFAULT, this.customerDataEdited?.personalDataBirth);
    },
    focusOnField(field) {
      this.$nextTick(() => {
        field.focus();
      });
    },
    dataChanged() {
      const payload = {
        personId: this.personId,
        personalDataAddress: this.personalDataAddress,
        personalDataBirth: this.personalDataBirth,
      };

      this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.ADD_CUSTOMER_DATA_EDITED, payload);
      this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.LEGITIMATIONSURKUNDE_CHANGED, {legitimationsurkundeChanged : true});
      
    },
  },
}
