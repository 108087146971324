var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showButtonsSelectDeselect
        ? _c("div", { staticClass: "row justify-content-end" }, [
            _c(
              "div",
              { staticClass: "col-auto" },
              [
                _c(
                  "BaseButton",
                  { attrs: { isSecondary: "" }, on: { click: _vm.deleteAll } },
                  [_vm._v("Alle löschen")]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-auto" },
              [
                _c("BaseButton", { on: { click: _vm.selectAll } }, [
                  _vm._v("Alle hinzufügen")
                ])
              ],
              1
            )
          ])
        : _vm._e(),
      _c("div", { staticClass: "input-forms__input-container" }, [
        _c(
          "div",
          [
            _c("Table", {
              attrs: {
                tableData: _vm.tableDataComputed,
                cardViewEnabled: false,
                filterEnabled: false,
                exportEnabled: false,
                paginationEnabled: false,
                actions: _vm.columnActions,
                rowsPerPage: _vm.tableData.records.length
              },
              on: {
                input: function($event) {
                  return _vm.changeInputValue($event)
                },
                "execute-action": function($event) {
                  return _vm.handleTableAction($event)
                }
              }
            })
          ],
          1
        )
      ]),
      _c(
        "BaseModal",
        {
          ref: "deleteModal",
          attrs: {
            modalTitle: "Gesellschaft entfernen?",
            labelButtonConfirm: "Gesellschaft Entfernen"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.deletePosition()
            },
            onCancelButton: function($event) {
              return _vm.onCancelModal()
            }
          }
        },
        [
          _c("div", [
            _vm._v(" Soll die Gesellschaft "),
            _c("span", { staticClass: "font-bold" }, [
              _vm._v(_vm._s(_vm.positionToDelete.name))
            ]),
            _vm._v(" wirklich entfernt werden? ")
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }