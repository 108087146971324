<template>
    <div>
        <div class="box__container">
             <div class="box__title">Einstellungen für Kauf/Nachkauf/Verkauf</div>
             <ComboBox
                v-model="INPUT_HELLOBANK_ANSPRECHPARTNER_COMBO" 
                label="Ansprechpartner"
                :values="comboboxValues ? comboboxValues.INPUT_HELLOBANK_ANSPRECHPARTNER_COMBO : []"
                :disabled="!hasKaufVerkauf"
                @change="updateStoreData({ componentId: 'INPUT_HELLOBANK_ANSPRECHPARTNER_COMBO', value: $event})" />
            <div v-if="data && data.INPUT_HELLOBANK_ANSPRECHPARTNER_COMBO !== FREIE_EINGABE">
                <FormLabel label="Für hier aufgeführte Ansprechpartner muss eine Telefonnummer hinterlegt sein."/>
            </div>
            <div v-if="data && data.INPUT_HELLOBANK_ANSPRECHPARTNER_COMBO === FREIE_EINGABE">
                <InputField
                    label="Name"
                    v-model="INPUT_HELLOBANK_ANSPRECHPARTNER_NAME"
                    :disabled="!hasKaufVerkauf"
                    @change="updateStoreData({ componentId: 'INPUT_HELLOBANK_ANSPRECHPARTNER_NAME', value: $event})" />
            </div>
            <div v-if="data && data.INPUT_HELLOBANK_ANSPRECHPARTNER_COMBO === FREIE_EINGABE">
                <InputField 
                    label="Telefon" 
                    v-model="INPUT_HELLOBANK_ANSPRECHPARTNER_TEL" 
                    :disabled="!hasKaufVerkauf"
                    @change="updateStoreData({ componentId: 'INPUT_HELLOBANK_ANSPRECHPARTNER_TEL', value: $event})"
                    inputmode="tel"
                />
            </div>
            <InputToggleSwitch 
                v-model="INPUT_HELLOBANK_REFERENZKONTO" 
                label="Überweisung des gesamten Verkaufserlöses an mein Referenzkonto" 
                inLineLabel 
                :disabled="!hasKaufVerkauf"
                @input="updateStoreData({ componentId: 'INPUT_HELLOBANK_REFERENZKONTO', value: $event})" />
             <div v-if="data && data.INPUT_HELLOBANK_REFERENZKONTO === '1'">
                <InputField 
                    label="Verkaufserlöses" 
                    v-model="INPUT_HELLOBANK_REFERENZKONTO_BETRAG" 
                    type="currency" 
                    :disabled="!hasKaufVerkauf"
                    @change="updateStoreData({ componentId: 'INPUT_HELLOBANK_REFERENZKONTO_BETRAG', value: $event})" />
            </div>
            <InputTextArea
                label="sonstige Bemerkungen"
                v-model="INPUT_HELLOBANK_BEMERKUNG" 
                :disabled="!hasKaufVerkauf"
                @change="updateStoreData({ componentId: 'INPUT_HELLOBANK_BEMERKUNG', value: $event})" />
        </div>
        <div class="box__container" v-if="hasSparplan">
        <div class="box__title">Angaben für Sparpläne</div>
         <InputToggleSwitch 
                v-model="INPUT_HELLOBANK_CHK_LASTSCHRIFT" 
                label="SEPA-Lastschrift Mandat" 
                inLineLabel 
                @input="updateStoreData({ componentId: 'INPUT_HELLOBANK_CHK_LASTSCHRIFT', value: $event})" />
        <ComboBox
                v-model="INPUT_HELLOBANK_BANKVERBINDUNG" 
                label="IBAN"
                :disabled="INPUT_HELLOBANK_CHK_LASTSCHRIFT == '0'"
                :values="comboboxValues ? comboboxValues.INPUT_HELLOBANK_BANKVERBINDUNG_COMBO : []"
                @change="updateStoreData({ componentId: 'INPUT_HELLOBANK_BANKVERBINDUNG', value: $event})" />
           
            <div>
                <InputField
                    v-model="INPUT_HELLOBANK_BANKVERBINDUNG_TEXT"
                    :disabled="INPUT_HELLOBANK_CHK_LASTSCHRIFT == '0' || INPUT_HELLOBANK_BANKVERBINDUNG != 'freie Eingabe:'"
                    @change="updateStoreData({ componentId: 'INPUT_HELLOBANK_BANKVERBINDUNG_TEXT', value: $event})" />
            </div>        
        </div>
       <!--  <div class="box__container">
            <div class="box__title">Angaben für Sparpläne</div>
            <FormLabel label="Depotinhaber 1" :config="{bold: true}"/>
            <InputToggleSwitch 
                v-model="INPUT_HELLOBANK_PLAN_INH1_F1" 
                label="Ich habe den vorliegenden Fonds/ETF Sparplanvertrag samt Vertragsbestimmungen gelesen und bin damit vollinhaltlich einverstanden." 
                inLineLabel 
                :disabled="!hasSparplan"
                @input="updateStoreData({ componentId: 'INPUT_HELLOBANK_PLAN_INH1_F1', value: $event})" />
            <InputToggleSwitch 
                v-model="INPUT_HELLOBANK_PLAN_INH1_F4" 
                label="Ich habe den „Informationsbogen zur Einlagensicherung“ erhalten und gelesen." 
                inLineLabel 
                :disabled="!hasSparplan"
                @input="updateStoreData({ componentId: 'INPUT_HELLOBANK_PLAN_INH1_F4', value: $event})" />
            <InputToggleSwitch 
                v-model="INPUT_HELLOBANK_PLAN_INH1_F2" 
                label="Ich handle auf eigene Rechnung, im eigenen Auftrag und ich bin der wirtschaftliche Eigentümer; Änderungen hierzu werde ich Ihnen umgehend schriftlich bekannt geben."
                inLineLabel 
                :disabled="!hasSparplan"
                @input="updateStoreData({ componentId: 'INPUT_HELLOBANK_PLAN_INH1_F2', value: $event})" />
            <InputToggleSwitch 
                v-model="INPUT_HELLOBANK_PLAN_INH1_F3" 
                label="Zweck und Art der Geschäftsbeziehung ist die dauerhafte private Inanspruchnahme von Wertpapier(neben)dienstleistungen. Änderungen hierzu werde ich Ihnen umgehend schriftlich bekannt geben."
                inLineLabel
                :disabled="!hasSparplan"
                @input="updateStoreData({ componentId: 'INPUT_HELLOBANK_PLAN_INH1_F3', value: $event})" />
            <InputToggleSwitch 
                v-model="INPUT_HELLOBANK_PLAN_INH1_F5" 
                label="Rücktrittsrecht: Ich erteile gemäß § 8 Abs 5 FernFinG ausdrücklich meine Zustimmung, dass mit der Erfüllung des Vertrages zum Fonds/ETF Sparplan bereits vor Ablauf der 14-tägigen Rücktrittsfrist begonnen wird."
                inLineLabel
                :disabled="!hasSparplan"
                @input="updateStoreData({ componentId: 'INPUT_HELLOBANK_PLAN_INH1_F5', value: $event})" />

            <FormLabel label="Depotinhaber 2 (ggf. eintragen falls vorhanden)" :config="{bold: true}"/>
            <InputToggleSwitch 
                v-model="INPUT_HELLOBANK_PLAN_INH2_F1" 
                label="Ich habe den vorliegenden Fonds/ETF Sparplanvertrag samt Vertragsbestimmungen gelesen und bin damit vollinhaltlich einverstanden." 
                inLineLabel 
                :disabled="!hasSparplan || !hasDepotinhaber2"
                @input="updateStoreData({ componentId: 'INPUT_HELLOBANK_PLAN_INH2_F1', value: $event})" />
            <InputToggleSwitch 
                v-model="INPUT_HELLOBANK_PLAN_INH2_F4" 
                label="Ich habe den „Informationsbogen zur Einlagensicherung“ erhalten und gelesen." 
                inLineLabel 
                :disabled="!hasSparplan || !hasDepotinhaber2"
                @input="updateStoreData({ componentId: 'INPUT_HELLOBANK_PLAN_INH2_F4', value: $event})" />
            <InputToggleSwitch 
                v-model="INPUT_HELLOBANK_PLAN_INH2_F2" 
                label="Ich handle auf eigene Rechnung, im eigenen Auftrag und ich bin der wirtschaftliche Eigentümer; Änderungen hierzu werde ich Ihnen umgehend schriftlich bekannt geben."
                inLineLabel 
                :disabled="!hasSparplan || !hasDepotinhaber2"
                @input="updateStoreData({ componentId: 'INPUT_HELLOBANK_PLAN_INH2_F2', value: $event})" />
            <InputToggleSwitch 
                v-model="INPUT_HELLOBANK_PLAN_INH2_F3" 
                label="Zweck und Art der Geschäftsbeziehung ist die dauerhafte private Inanspruchnahme von Wertpapier(neben)dienstleistungen. Änderungen hierzu werde ich Ihnen umgehend schriftlich bekannt geben."
                inLineLabel
                :disabled="!hasSparplan || !hasDepotinhaber2"
                @input="updateStoreData({ componentId: 'INPUT_HELLOBANK_PLAN_INH2_F3', value: $event})" />
                            <InputToggleSwitch 
                v-model="INPUT_HELLOBANK_PLAN_INH2_F5" 
                label="Rücktrittsrecht: Ich erteile gemäß § 8 Abs 5 FernFinG ausdrücklich meine Zustimmung, dass mit der Erfüllung des Vertrages zum Fonds/ETF Sparplan bereits vor Ablauf der 14-tägigen Rücktrittsfrist begonnen wird."
                inLineLabel
                :disabled="!hasSparplan || !hasDepotinhaber2"
                @input="updateStoreData({ componentId: 'INPUT_HELLOBANK_PLAN_INH2_F5', value: $event})" />
        </div> -->
    </div>
</template>

<script>
import ComponentList from '@/components/wertpapierorder/ComponentList.vue';
import WERTPAPIERORDER_TYPES from '@/store/wertpapierorder/types';
import mixin from '@/mixins/wertpapierorder/mixin.js';
import { mapGetters } from "vuex";
import InputField from '@/components/core/forms/InputField.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import FormLabel from '@/components/core/forms/FormLabel.vue';
import { FREIE_EINGABE } from '@/mixins/wertpapierorder/mixin.js';
import validator from "@/mixins/validator";

export default {
    mixins: [mixin, validator],
    validators: {},
    components: {
        ComponentList,
        InputField,
        InputTextArea,
        InputToggleSwitch,
        ComboBox,
        FormLabel,
    },
    data() {
        return {
            disabled: false,
            form: {},
            FREIE_EINGABE: FREIE_EINGABE,
            INPUT_HELLOBANK_ANSPRECHPARTNER_COMBO: '',
            INPUT_HELLOBANK_ANSPRECHPARTNER_NAME: '',
            INPUT_HELLOBANK_ANSPRECHPARTNER_TEL: '',
            INPUT_HELLOBANK_REFERENZKONTO: '0',
            INPUT_HELLOBANK_REFERENZKONTO_BETRAG: '',
            INPUT_HELLOBANK_BEMERKUNG: '',
            INPUT_HELLOBANK_CHK_LASTSCHRIFT: '0',
            INPUT_HELLOBANK_BANKVERBINDUNG: 'freie Eingabe:',
            INPUT_HELLOBANK_BANKVERBINDUNG_TEXT:'',
            /* INPUT_HELLOBANK_PLAN_INH1_F1: '0',
            INPUT_HELLOBANK_PLAN_INH1_F4: '0',
            INPUT_HELLOBANK_PLAN_INH1_F2: '0',
            INPUT_HELLOBANK_PLAN_INH1_F3: '0',
            INPUT_HELLOBANK_PLAN_INH1_F5: '0',
            INPUT_HELLOBANK_PLAN_INH2_F1: '0',
            INPUT_HELLOBANK_PLAN_INH2_F4: '0',
            INPUT_HELLOBANK_PLAN_INH2_F2: '0',
            INPUT_HELLOBANK_PLAN_INH2_F3: '0',
            INPUT_HELLOBANK_PLAN_INH2_F5: '0', */
            test: '',
            test2: '0',
        }
    },
    mounted() {
        if (!this.hasKaufVerkauf) {
            this.updateStoreDataValues({
                INPUT_HELLOBANK_ANSPRECHPARTNER_COMBO: FREIE_EINGABE, 
                INPUT_HELLOBANK_ANSPRECHPARTNER_NAME: null, 
                INPUT_HELLOBANK_ANSPRECHPARTNER_TEL: null, 
                INPUT_HELLOBANK_REFERENZKONTO: '0', 
                INPUT_HELLOBANK_REFERENZKONTO_BETRAG: null, 
                INPUT_HELLOBANK_BEMERKUNG: null });
        }
       /*  if (!this.hasSparplan) {
            this.updateStoreDataValues({
                INPUT_HELLOBANK_PLAN_INH1_F1: '0', 
                INPUT_HELLOBANK_PLAN_INH1_F2: '0', 
                INPUT_HELLOBANK_PLAN_INH1_F3: '0', 
                INPUT_HELLOBANK_PLAN_INH1_F4: '0', 
                INPUT_HELLOBANK_PLAN_INH2_F1: '0', 
                INPUT_HELLOBANK_PLAN_INH2_F2: '0', 
                INPUT_HELLOBANK_PLAN_INH2_F3: '0', 
                INPUT_HELLOBANK_PLAN_INH2_F4: '0',
                INPUT_HELLOBANK_PLAN_INH1_F5: '0',
                INPUT_HELLOBANK_PLAN_INH2_F5: '0',
                });
        } */
        
        setTimeout(() => {
            this.initForm();
            this.updateWarnings('hellobank');
        }, 600);
    },
    computed: {
        ...mapGetters({
            comboboxValues: WERTPAPIERORDER_TYPES.GETTERS.COMBOBOX_VALUES || {},
            lagerstelle: WERTPAPIERORDER_TYPES.GETTERS.SELECTED_LAGERSTELLE,
        }),
        hasDepotinhaber2() {
            return (this.data?.INPUT_PERSONEN_INHABER2 && this.data?.INPUT_PERSONEN_INHABER2 !== 'NICHT_VERWENDEN') || this.hatZweiterPersonEasybank();
        }
    },
    methods: {
        initForm() {
            this.INPUT_HELLOBANK_ANSPRECHPARTNER_COMBO = this.data?.INPUT_HELLOBANK_ANSPRECHPARTNER_COMBO;
            this.INPUT_HELLOBANK_ANSPRECHPARTNER_NAME = this.data?.INPUT_HELLOBANK_ANSPRECHPARTNER_NAME;
            this.INPUT_HELLOBANK_ANSPRECHPARTNER_TEL = this.data?.INPUT_HELLOBANK_ANSPRECHPARTNER_TEL;
            this.INPUT_HELLOBANK_REFERENZKONTO = !this.data?.INPUT_HELLOBANK_REFERENZKONTO || this.data?.INPUT_HELLOBANK_REFERENZKONTO === '0' ? '0' : '1';
            this.INPUT_HELLOBANK_REFERENZKONTO_BETRAG = this.data?.INPUT_HELLOBANK_REFERENZKONTO_BETRAG;
            this.INPUT_HELLOBANK_BEMERKUNG = this.data?.INPUT_HELLOBANK_BEMERKUNG;
            this.INPUT_HELLOBANK_CHK_LASTSCHRIFT = this.data?.INPUT_HELLOBANK_CHK_LASTSCHRIFT;
            this.INPUT_HELLOBANK_BANKVERBINDUNG_TEXT = this.data?.INPUT_HELLOBANK_BANKVERBINDUNG_TEXT;
            this.INPUT_HELLOBANK_BANKVERBINDUNG = this.data?.INPUT_HELLOBANK_BANKVERBINDUNG;
          /*   this.INPUT_HELLOBANK_PLAN_INH1_F1 = this.data?.INPUT_HELLOBANK_PLAN_INH1_F1;
            this.INPUT_HELLOBANK_PLAN_INH1_F4 = this.data?.INPUT_HELLOBANK_PLAN_INH1_F4;
            this.INPUT_HELLOBANK_PLAN_INH1_F2 = this.data?.INPUT_HELLOBANK_PLAN_INH1_F2;
            this.INPUT_HELLOBANK_PLAN_INH1_F3 = this.data?.INPUT_HELLOBANK_PLAN_INH1_F3;
            this.INPUT_HELLOBANK_PLAN_INH1_F5 = this.data?.INPUT_HELLOBANK_PLAN_INH1_F5;
            this.INPUT_HELLOBANK_PLAN_INH2_F1 = this.hasDepotinhaber2 && this.data?.INPUT_HELLOBANK_PLAN_INH2_F1;
            this.INPUT_HELLOBANK_PLAN_INH2_F4 = this.hasDepotinhaber2 && this.data?.INPUT_HELLOBANK_PLAN_INH2_F4;
            this.INPUT_HELLOBANK_PLAN_INH2_F2 = this.hasDepotinhaber2 && this.data?.INPUT_HELLOBANK_PLAN_INH2_F2;
            this.INPUT_HELLOBANK_PLAN_INH2_F3 = this.hasDepotinhaber2 && this.data?.INPUT_HELLOBANK_PLAN_INH2_F3;
            this.INPUT_HELLOBANK_PLAN_INH2_F5 = this.hasDepotinhaber2 && this.data?.INPUT_HELLOBANK_PLAN_INH2_F5; */

            if (!this.hasDepotinhaber2 && (this.data?.INPUT_HELLOBANK_PLAN_INH2_F1 === '1' 
                || this.data?.INPUT_HELLOBANK_PLAN_INH2_F2 === '1' || this.data?.INPUT_HELLOBANK_PLAN_INH2_F3 === '1'
                || this.data?.INPUT_HELLOBANK_PLAN_INH2_F4 === '1' || this.data?.INPUT_HELLOBANK_PLAN_INH2_F5 === '1')) {
                    this.updateStoreDataValues({
                        INPUT_HELLOBANK_PLAN_INH2_F1: '0', 
                        INPUT_HELLOBANK_PLAN_INH2_F2: '0', 
                        INPUT_HELLOBANK_PLAN_INH2_F3: '0', 
                        INPUT_HELLOBANK_PLAN_INH2_F4: '0',
                        INPUT_HELLOBANK_PLAN_INH2_F5: '0',
                    });
                }
        },
          hatZweiterPersonEasybank(){
             if(this.lagerstelle === 'HELLOBANK' && this.positions.find(p=>p.positionTyp == 'NEU_SPARPLAN' || p.positionTyp == 'ALT_SPARPLAN')){
               return true;
            }else{
              return false;
            }
        }
    },
}
</script>

<style>
    .title-row {
        border-top: 1px solid #f7f3f3;
        background-color: #f7f3f3;
        padding: 1rem;
    }
</style>