import RISIKOKLASSEN_DEFINITION_TYPES from './types';
import { getInitialState } from './index';

import Vue from 'vue';

export default {

  [RISIKOKLASSEN_DEFINITION_TYPES.MUTATIONS.RESET](state) {
    Object.assign(state, getInitialState());
  },

  [RISIKOKLASSEN_DEFINITION_TYPES.MUTATIONS.SET_RISIKOKLASSEN_DEFINITION](state, payload) {
    Vue.set(state, 'risikoklassenDefinition', { ...payload || {}, });
  },

  [RISIKOKLASSEN_DEFINITION_TYPES.MUTATIONS.SET_RISIKOKLASSEN_DEFINITION_EDITED](state, payload) {
    Vue.set(state, 'risikoklassenDefinitionEdited', { ...payload || {}, });
  },

  [RISIKOKLASSEN_DEFINITION_TYPES.MUTATIONS.RESET_RISIKOKLASSEN_DEFINITION_EDITED](state) {
    Vue.set(state, 'risikoklassenDefinitionEdited', null);
  },

  [RISIKOKLASSEN_DEFINITION_TYPES.MUTATIONS.COPY_VORGABE_INTO_EINSTELLUNG](state) {
    Vue.set(state.risikoklassenDefinitionEdited, 'einstellung', {
      ...state.risikoklassenDefinitionEdited?.vorgabe || {},
    });
  },

}