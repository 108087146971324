var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: { modalTitle: "Auswertung", showDefaultButtons: false },
      on: {
        close: function($event) {
          return _vm.close(true)
        }
      }
    },
    [
      _c(
        "div",
        [
          _c("label", [
            _vm._v(" Fortschritt "),
            _c(
              "progress",
              {
                staticStyle: { width: "100%" },
                attrs: { max: _vm.progressBarMaxValue },
                domProps: { value: _vm.progress }
              },
              [_vm._v(" " + _vm._s(_vm.progress) + "% ")]
            ),
            _vm._v(
              " " +
                _vm._s(_vm.progress) +
                "/" +
                _vm._s(_vm.progressBarMaxValue) +
                " "
            )
          ]),
          _c("br"),
          _c("InputTextArea", {
            attrs: { value: _vm.info, autoGrow: "", disabled: "" }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }