import VERTRAG_STATUS_TYPES from './types';
import LOG_TYPES from '@/store/log/types';
import axios from 'axios';
import { buildMessage } from '@/helpers/log-message-helper';

const config = {
  defaultSpinner: true
};

export default {
  [VERTRAG_STATUS_TYPES.ACTIONS.VERTRAG_STATUS_LIST]({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      let url = '/vertragStatus/vertragHistory';

      if(payload && payload.vertragId) {
        url = `${url}?vertragId=${payload.vertragId}`;
      }

      axios.get(rootState.core.apiAddress + url, config).then(response => {
        commit(VERTRAG_STATUS_TYPES.MUTATIONS.VERTRAG_STATUS_LIST_SUCCESS, response?.data || {});
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },
  [VERTRAG_STATUS_TYPES.ACTIONS.VERTRAG_STATUS]({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      let url = '/vertragStatus/vertragStatus';

      const params = {};
      if(payload && payload.vertragId) {
        params.vertragId = payload.vertragId;
      }
      if(payload && payload.statusId) {
        params.statusId = payload.statusId;
      }

      axios.get(rootState.core.apiAddress + url, {params}, config).then(response => {
        commit(VERTRAG_STATUS_TYPES.MUTATIONS.VERTRAG_STATUS_SUCCESS, response?.data || {});
        resolve(response?.data);
      }).catch(error => {
        reject(error);
      });
    });
  },
  [VERTRAG_STATUS_TYPES.ACTIONS.DELETE_VERTRAG_STATUS]({ commit, rootState }, statusId) {
    return new Promise((resolve, reject) => {
      let url = '/vertragStatus';

      const params = {}
      if(statusId) {
        params.statusId = statusId;
      }

      axios.delete(rootState.core.apiAddress + url, {params}, config).then(response => {
        commit(VERTRAG_STATUS_TYPES.MUTATIONS.DELETE_VERTRAG_STATUS, statusId)
        resolve(response?.data);
      }).catch(error => {
        reject(error);
      });
    });
  },
  [VERTRAG_STATUS_TYPES.ACTIONS.SAVE_VERTRAG_STATUS]({ commit, dispatch, rootState }, payload) {
    return new Promise((resolve, reject) => {
      let url = `/vertragStatus?vertragId=${payload.vertragId}`;

      axios.post(rootState.core.apiAddress + url, payload.vertragStatus, config).then(response => {
        let thereAreValidationMessages = false;
        if(response?.data && response?.data?.validationMessages?.length) {
          response.data.validationMessages.forEach(message => {
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(message, 'warning', false));
          });
          thereAreValidationMessages = true;
          return resolve(thereAreValidationMessages)
        }
        commit(VERTRAG_STATUS_TYPES.MUTATIONS.SAVE_VERTRAG_STATUS_SUCCESS, response.data);
        return resolve(thereAreValidationMessages)
      }).catch(error => {
        reject(error);
      });
    });
  },
  [VERTRAG_STATUS_TYPES.ACTIONS.UPDATE_VERTRAG_STATUS]({ commit, dispatch, rootState }, payload) {
    return new Promise((resolve, reject) => {
      let url = `/vertragStatus?vertragId=${payload.vertragId}`;

      axios.put(rootState.core.apiAddress + url, payload.vertragStatus, config).then(response => {
        let thereAreValidationMessages = false;
        if(response?.data && response?.data?.validationMessages?.length) {
          response.data.validationMessages.forEach(message => {
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(message, 'warning', false));
          });
          thereAreValidationMessages = true;
          return resolve(thereAreValidationMessages)
        }
        commit(VERTRAG_STATUS_TYPES.MUTATIONS.UPDATE_VERTRAG_STATUS_SUCCESS, response.data);
        return resolve(thereAreValidationMessages)
      }).catch(error => {
        reject(error);
      });
    });
  },

}
