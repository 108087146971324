import FC_CONFIG_TYPES from './types';
import Vue from 'vue';
import { getInitialState } from './index';

export default {
  [FC_CONFIG_TYPES.MUTATIONS.ADD_FC_CONFIG_LOADING](state, { configType, configId, }) {
    Vue.set(state.fcConfigLoading, configType, {
      ...state.fcConfigLoading?.[configType] || {},
      [configId]: true,
    });
  },
  
  [FC_CONFIG_TYPES.MUTATIONS.REMOVE_FC_CONFIG_LOADING](state, { configType, configId, }) {
    if(configType in state.fcConfigLoading) {
      Vue.delete(state.fcConfigLoading[configType], configId);
    }
  },

  [FC_CONFIG_TYPES.MUTATIONS.LOAD_CONFIG_SUCCESS](state, resultConfigList) {
    if (!Array.isArray(resultConfigList)) {
      return;
    }

    for (const resultConfig of resultConfigList) {
      const config = resultConfig.config
      const configType = resultConfig.configType
      const configId = resultConfig.configId
      
      if (!state.fcConfig[configType]) {
        Vue.set(state.fcConfig, configType, {})
      }

      Vue.set(state.fcConfig[configType], configId, config)
    }
  },
  [FC_CONFIG_TYPES.MUTATIONS.LOAD_CONFIG_BY_USER_LEVEL_SUCCESS](state, resultConfigList) {
    if (!Array.isArray(resultConfigList)) {
      return;
    }

    for (const resultConfig of resultConfigList) {
      const config = resultConfig.config;
      const configType = resultConfig.configType;
      const userLevel = resultConfig.userLevel;
      const configId = resultConfig.configId;

      if (!(configType in state.fcConfigByUserLevel)) {
        Vue.set(state.fcConfigByUserLevel, configType, {});
      }

      if (!(configId in state.fcConfigByUserLevel[configType])) {
        Vue.set(state.fcConfigByUserLevel[configType], configId, {
          ...state.fcConfigByUserLevel[configType]?.[configId] || {},
        });
      }

      Vue.set(state.fcConfigByUserLevel[configType][configId], userLevel, config);
    }
  },
  [FC_CONFIG_TYPES.MUTATIONS.RESET_ALL_LOADED_CONFIG_BY_USER_LEVEL](state, { configId, configType, }) { 
    if(configId && configType && configType in state.fcConfigByUserLevel) {
      Vue.delete(state.fcConfigByUserLevel[configType], configId);
    }
  },
  [FC_CONFIG_TYPES.MUTATIONS.RESET_LOADED_CONFIG_BY_USER_LEVEL](state, { configId, configType, userLevel, }) { 
    if(configId && configType && userLevel && configType in state.fcConfigByUserLevel && configId in state.fcConfigByUserLevel) {
      Vue.delete(state.fcConfigByUserLevel[configType][configId], userLevel);
    }
  },
  [FC_CONFIG_TYPES.MUTATIONS.DELETE_CONFIG_SUCCESS](state, payload) {
    const configType = payload.configType
    const configId = payload.configId
    
    if (!state.fcConfig[configType]) {
      Vue.set(state.fcConfig, configType, {})
    }
    
    Vue.set(state.fcConfig[configType], configId, {})
  },
  [FC_CONFIG_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },
}
