export function parseBackendStrings(value) {
    let result = 0;
    try {
        if (typeof value === 'number') {
            result = value;
        } else {
            result = convertStringToNumber(value);
        }

    } catch (e) {
        result = 0;
    }
    return  result;
}

/**
 * Insurance payments period name
 * @param {string} period
 * @returns {number}
 */
export function getInsurancePeriodLabel(period) {
    if (period === '1/1') {
        return 'jährlich';
    } else if (period === '1/4') {
        return 'vierteljährlich';
    } else if (period === '1/6') {
        return 'halbjährlich';
    } else if (period === '1/12') {
        return 'monatlich';
    }
    return period;
}
