export const MUTATION_PREFIX = 'BRIDGE: ';
export const ACTIONS_PREFIX = 'BRIDGE_';
export const GETTERS_PREFIX = 'BRIDGE_';

export default {
  MUTATIONS: {
    LOAD_LOGIN_DATA_SUCCESS: MUTATION_PREFIX + 'LOAD_LOGIN_DATA_SUCCESS',
    HAS_LOADED_LOGIN_DATA: MUTATION_PREFIX + 'HAS_LOADED_LOGIN_DATA',
    RESET_LOADED_LOGIN_DATA: MUTATION_PREFIX + 'RESET_LOADED_LOGIN_DATA',
    HAS_PASSWORD_PREVIOUSLY_SAVED: MUTATION_PREFIX + 'HAS_PASSWORD_PREVIOUSLY_SAVED',
    RESET_HAS_PASSWORD_PREVIOUSLY_SAVED: MUTATION_PREFIX + 'RESET_HAS_PASSWORD_PREVIOUSLY_SAVED',
    MOBILE_NATIVE_CONTEXT: MUTATION_PREFIX + 'MOBILE_NATIVE_CONTEXT',
    MOBILE_NATIVE_SPEC: MUTATION_PREFIX + 'MOBILE_NATIVE_SPEC',
    SAVE_CLIENT_CALL_ID: MUTATION_PREFIX + 'SAVE_CLIENT_CALL_ID',
    SAVE_RECEIVED_DB_PREFIX: MUTATION_PREFIX + 'SAVE_RECEIVED_DB_PREFIX',
    SAVE_SHOW_BUTTON_SWITCH_AUDIO_OUTPUT: MUTATION_PREFIX + 'SAVE_SHOW_BUTTON_SWITCH_AUDIO_OUTPUT',
    VIRTUAL_KEYBOARD_DETECTED: MUTATION_PREFIX + 'VIRTUAL_KEYBOARD_DETECTED',
    SET_AUDIO_OUTPUT: MUTATION_PREFIX + 'SET_AUDIO_OUTPUT',
    SET_BIOMETRIC_LOGIN_SETTINGS_RESPONSE: MUTATION_PREFIX + 'SET_BIOMETRIC_LOGIN_SETTINGS_RESPONSE',

    SET_DEEPLINK_READY: MUTATION_PREFIX + 'SET_DEEPLINK_READY',
    SET_DEFERRED_DEEPLINK: MUTATION_PREFIX + 'SET_DEFERRED_DEEPLINK',

    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
  },
  ACTIONS: {
    UPDATE_TOKEN: ACTIONS_PREFIX + 'UPDATE_TOKEN',
    SAVE_HASHED_PASSWORD: ACTIONS_PREFIX + 'SAVE_HASHED_PASSWORD',
    LOAD_LOGIN_DATA: ACTIONS_PREFIX + 'LOAD_LOGIN_DATA',
    LOAD_HASHED_PASSWORD: ACTIONS_PREFIX + 'LOAD_HASHED_PASSWORD',
    PREPARE_CREDENTIALS: ACTIONS_PREFIX + 'PREPARE_CREDENTIALS',
    VIEW_DOCUMENT: ACTIONS_PREFIX + 'VIEW_DOCUMENT',
    VIEW_PDF_IN_SIGNO: ACTIONS_PREFIX + 'VIEW_PDF_IN_SIGNO',
    OPEN_EXTRA_WEB_INSTANCE: ACTIONS_PREFIX + 'OPEN_EXTRA_WEB_INSTANCE',
    CLOSE_EXTRA_WEB_INSTANCE: ACTIONS_PREFIX + 'CLOSE_EXTRA_WEB_INSTANCE',
    CLOSE_EXTRA_WEB_INSTANCE_WITH_CONFIRM: ACTIONS_PREFIX + 'CLOSE_EXTRA_WEB_INSTANCE_WITH_CONFIRM',
    LOGGED_OFF: ACTIONS_PREFIX + 'LOGGED_OFF',
    READY_TO_SCREENSHOT_START_PAGE: ACTIONS_PREFIX + 'READY_TO_SCREENSHOT_START_PAGE',
    START_OUTGOING_CALL: ACTIONS_PREFIX + 'START_OUTGOING_CALL',
    STOP_CALL: ACTIONS_PREFIX + 'STOP_CALL',
    TRIGGER_OUTGOING_CALL: ACTIONS_PREFIX + 'TRIGGER_OUTGOING_CALL',
    DELETE_HASHED_PASSWORD: ACTIONS_PREFIX + 'DELETE_HASHED_PASSWORD',
    OUTGOING_CALL_ACCEPTED: ACTIONS_PREFIX + 'OUTGOING_CALL_ACCEPTED',

    COLOR_THEME: ACTIONS_PREFIX + 'COLOR_THEME',
    MOBILE_NATIVE_CONTEXT: ACTIONS_PREFIX + 'MOBILE_NATIVE_CONTEXT',
    MOBILE_NATIVE_SPEC: ACTIONS_PREFIX + 'MOBILE_NATIVE_SPEC',
    HANDLE_CALLS_FROM_NATIVE: ACTIONS_PREFIX + 'HANDLE_CALLS_FROM_NATIVE',
    LOAD_BIOMETRIC_LOGIN_SETTINGS: ACTIONS_PREFIX + 'LOAD_BIOMETRIC_LOGIN_SETTINGS',
    UPDATE_BIOMETRIC_LOGIN_SETTINGS: ACTIONS_PREFIX + 'UPDATE_BIOMETRIC_LOGIN_SETTINGS',

    OPEN_WITHOUT_NAVIGATE: ACTIONS_PREFIX + 'OPEN_WITHOUT_NAVIGATE',

    UPDATE_SETTINGS: ACTIONS_PREFIX + 'UPDATE_SETTINGS',
    TOGGLE_AUDIO_OUTPUT: ACTIONS_PREFIX + 'TOGGLE_AUDIO_OUTPUT',

    OPEN_DEEPLINK: ACTIONS_PREFIX + 'OPEN_DEEPLINK',
    OPEN_DEFERRED_DEEPLINK: ACTIONS_PREFIX + 'OPEN_DEFERRED_DEEPLINK',
  },
  GETTERS: {
    LOADED_LOGIN_DATA: GETTERS_PREFIX + 'LOADED_LOGIN_DATA',
    HAS_LOADED_LOGIN_DATA: GETTERS_PREFIX + 'HAS_LOADED_LOGIN_DATA',
    HAS_MOBILE_DATA_FOR_NATIVE_MEANS_OF_LOGIN: GETTERS_PREFIX + 'HAS_MOBILE_DATA_FOR_NATIVE_MEANS_OF_LOGIN',
    GET_AUTHENTICATION_TYPE_LABEL: GETTERS_PREFIX + 'GET_AUTHENTICATION_TYPE_LABEL',
    GET_AUTHENTICATION_TYPE: GETTERS_PREFIX + 'GET_AUTHENTICATION_TYPE',
    IS_MOBILE_NATIVE_CONTEXT: GETTERS_PREFIX + 'IS_MOBILE_NATIVE_CONTEXT',
    IS_MOBILE_NATIVE_CONTEXT_ANDROID: GETTERS_PREFIX + 'IS_MOBILE_NATIVE_CONTEXT_ANDROID',
    IS_MOBILE_NATIVE_CONTEXT_IOS: GETTERS_PREFIX + 'IS_MOBILE_NATIVE_CONTEXT_IOS',
    MOBILE_NATIVE_SPEC: GETTERS_PREFIX + 'MOBILE_NATIVE_SPEC',
    IS_BROWSER_SIMULATION: GETTERS_PREFIX + 'IS_BROWSER_SIMULATION',
    CLIENT_CALL_ID: GETTERS_PREFIX + 'CLIENT_CALL_ID',
    BIOMETRIC_LOGIN_SETTINGS: GETTERS_PREFIX + 'BIOMETRIC_LOGIN_SETTINGS',

    RECEIVED_DB_PREFIX: GETTERS_PREFIX + 'RECEIVED_DB_PREFIX',
    SHOW_BUTTON_SWITCH_AUDIO_OUTPUT: GETTERS_PREFIX + 'SHOW_BUTTON_SWITCH_AUDIO_OUTPUT',
    IS_VIRTUAL_KEYBOARD_DETECTED: GETTERS_PREFIX + 'IS_VIRTUAL_KEYBOARD_DETECTED',
    IS_AUDIO_OUTPUT_SPEAKER: GETTERS_PREFIX + 'IS_AUDIO_OUTPUT_SPEAKER',
    IS_AUDIO_OUTPUT_RECEIVER: GETTERS_PREFIX + 'IS_AUDIO_OUTPUT_RECEIVER',
    AUDIO_OUTPUT: GETTERS_PREFIX + 'AUDIO_OUTPUT',

    SHOW_CLOSE_EXTRA_WEB_INSTANCE: GETTERS_PREFIX + 'SHOW_CLOSE_EXTRA_WEB_INSTANCE',

    IS_DEEPLINK_READY: GETTERS_PREFIX + 'IS_DEEPLINK_READY',
  },

  HANDLERS: {
    STOP_CALL: 'stop-call',
    APP_RETURNED_FROM_BACKGROUND: 'app-returned-from-background',
    PUSH_NOTIFICATION_RECEIVED: 'push-notification-received',
    PREPARE_INCOMING_CALL: 'prepare-incoming-call',
    ACCEPT_INCOMING_CALL: 'accept-incoming-call',
    BACK_BUTTON_PRESSED: 'back-button-pressed',
    MULTIBANKING_DISMISSED: 'multibanking-dismissed',
    OPEN_DEEPLINK: 'open-deeplink',
    SWITCH_COLOR_THEME: 'switch-color-theme',
  }
}
