<template>
  <div>
    <OptionMenu 
      :id="$appNavigation.currentOptionMenuId" 
      :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation
      :title="$appNavigation.currentMenu.label"
      :id="$appNavigation.currentOptionMenuId"
      :actions="pageHeaderActions"
      v-on="pageHeaderActionListeners">
    </PageHeaderTitleNavigation>

    <div v-if="cardsData && cardsData.length" class="base-form__main">
      <div class="base-form__stages">
        <div v-if="loading.init">
          <GhostLoading useBoxContainer type="block" :config="{ block: { height: 100, }, }" />
          <GhostLoading useBoxContainer type="block" :config="{ block: { height: 100, }, }" />
          <GhostLoading useBoxContainer type="block" :config="{ block: { height: 100, }, }" />
          <GhostLoading useBoxContainer type="block" :config="{ block: { height: 100, }, }" />
          <GhostLoading useBoxContainer type="block" :config="{ block: { height: 100, }, }" />
          <GhostLoading useBoxContainer type="block" :config="{ block: { height: 100, }, }" />
        </div>

        <div v-else>
          <slot name="beforeCards"></slot>
          <BoxList :items="cardsData" @clickItem="handleCardItemClick" >
            <template #icon="{ item }">
              <ph-question v-if="item.status == 'question'" :size="24" class="color-info" />
              <ph-check-circle v-else-if="item.status == 'success'" :size="24" class="color-success" />
              <ph-warning-circle v-else-if="item.status == 'warning'" :size="24" class="color-warning" />
              <ph-warning-circle v-else-if="item.status == 'danger'" :size="24" class="color-danger" />
              <ph-info v-else-if="item.status == 'info'" :size="24" class="color-info" />
              <ph-circle v-else :size="24" class="color-primary" />
            </template>

            <template #default="{ item }">
              <h3 class="mt-0 mb-1" v-if="item.label">{{ item.label }}</h3>
              <p class="mt-0 mb-0" v-if="item.description">
                <small>{{ item.description }}</small>
              </p>
              <p class="mt-0 mb-0" v-for="(warning, index) in item.warnings" :key="index">
                <small :class="getStatusClass(warning.status)"> {{ warning.message }}</small>
              </p>   
            </template>

            <template #link="{ item }">
              <a v-if="item.onClick && item.link" @click="item.onClick">{{ item.linkText }}</a>
              <span v-else>{{item.linkText}}</span>
            </template>
          </BoxList>
          <slot name="afterCards"></slot>
        </div>
      </div>
      <div class="beratung-edit__content">
        <slot name="formContent">
          <GhostLoading useBoxContainer type="block" :config="{ block: { height: 100, }, }" />
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { PhCheckCircle, PhWarningCircle, PhCircle, PhTrash, PhInfo, PhQuestion } from 'phosphor-vue';

import { mapGetters } from 'vuex';
import BoxList from '@/components/core/BoxList.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import Table from "@/components/table2/Table.vue";
import NoData from '@/components/core/NoData.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import CurrencyLabel from '@/components/core/CurrencyLabel.vue';

export default {
  data() {
    return {
      showForms: false,
      antragRows: [],
      referredFromInvestmentAdvice: true,
      positionRows: [],
      investmentAdviceOrderList: [],
      investmentAdviceAntraege: [],
    };
  },
  props: {
    pageHeaderActionListeners: {
      type: Object,
      default: () => ({}),
    },
    pageHeaderActions: {
      type: Array,
      default: () => [],
    },
    cardsData: {
      type: Array,
      default: () => [],
    },
    selectedLagerstelle: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Object,
      default: () => ({}),
    }
  },
  components: {
    Table,
    CurrencyLabel,
    BoxList,
    PhCheckCircle,
    PhWarningCircle,
    PhCircle,
    PhTrash,
    OptionMenu,
    PhInfo,
    PhQuestion,
    PageHeaderTitleNavigation,
    NoData,
    GhostLoading,
  },
  computed: {
    ...mapGetters({
    }),
  },
  methods: {
    handleCardItemClick(card) {
      if(!card) {
        return ;
      }

      if(card.link){
        this.$router.push({path: card.link});
      }
    },
  },
}
</script>

<style scoped>
.header-cell {
    font-weight: 500;
    padding: 1px 1px;
}
.table-cell {
    box-sizing: border-box;
    padding: 8px 4px;
    border-top: 1px solid #C4C4C4;
    overflow: hidden;
}
div.action-buttons > i {
  font-size: 1.8em;
}
.base-form__main {
  display: flex;
}
.base-form__stages {
  flex: 1 0 auto;
  width: 480px;
}
.beratung-edit__content {
  width: calc(92% - 480px);
  margin-left: 28px;
}

.grayed-out {
  color: grey;
}

@media screen and (max-width: 1100px) {
  .base-form__stages {
    width: 380px;
  }
  .beratung-edit__content {
    width: calc(92% - 380px);
  }
}

@media screen and (max-width: 870px) {
  .base-form__stages {
    width: 300px;
  }
  .beratung-edit__content {
    width: calc(92% - 300px);
  }
}

@media screen and (max-width: 767px) {
  .base-form__main {
    flex-direction: column;
  }

  .base-form__stages {
    width: 100%;
  }
  .beratung-edit__content {
    margin-left: 0;
    width: 100%;
  }
}
</style>