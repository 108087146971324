var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "finCalcModal",
      attrs: { modalTitle: "", showDefaultButtons: false }
    },
    [_c("fin-calc-app")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }