import Vue from 'vue';
import MENU_STRUCTURE_TYPES from './types';

import { getInitialState } from './index';
import { v4 as uuidv4 } from 'uuid';

export default {

  [MENU_STRUCTURE_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, { ...getInitialState(), });
  },

  [MENU_STRUCTURE_TYPES.MUTATIONS.SET_MENU_STRUCTURES](state, payload) {
    Vue.set(state, 'menuStructures', [ ...payload || [] ]);
  },

  [MENU_STRUCTURE_TYPES.MUTATIONS.SET_MENU_STRUCTURE_CONTEXT](state, { menuStructure, flatMenuConfigured, flatMenuDefault }) {
    Vue.set(state, 'menuStructureContext', {
      menuStructure, 
      menuStructureEdited: null, 
      flatMenuConfigured, 
      flatMenuConfiguredEdited: null,
      flatMenuDefault, 
    });
  },

  [MENU_STRUCTURE_TYPES.MUTATIONS.SET_MENU_STRUCTURE_EDITED](state, payload) {
    Vue.set(state.menuStructureContext, 'menuStructureEdited', { ...payload });
  },

  [MENU_STRUCTURE_TYPES.MUTATIONS.RESET_MENU_STRUCTURE_CONTEXT_EDITED](state) {
    const { menuStructure, flatMenuConfigured, flatMenuDefault } = state.menuStructureContext;

    Vue.set(state, 'menuStructureContext', {
      menuStructure, 
      menuStructureEdited: null, 
      flatMenuConfigured, 
      flatMenuConfiguredEdited: null,
      flatMenuDefault, 
    });
  },

  [MENU_STRUCTURE_TYPES.MUTATIONS.ADD_MENU](state, { parent, menus }) {
    const mapMenu = menu => ({
      ...menu,
      subMenu: menu.subMenu.map(mapMenu),
    });

    const addMenu = m => { // TODO improve this logic
      if (m?.path === parent?.path) {
        return {
          ...m,
          subMenu: [ 
            ...m.subMenu, 
            ...(menus.map(menu => ({
              ...menu,
              parent: (
                parent 
                  ? { ...parent, subMenu: undefined }
                  : parent
                ),
            }))),
          ].map(addMenu),
        };
      }

      return {
        ...m,
        subMenu: m.subMenu.map(addMenu),
      };
    }


    const { flatMenuConfigured, flatMenuConfiguredEdited } = state.menuStructureContext;
    const configured = [ ...flatMenuConfiguredEdited ?? flatMenuConfigured ?? [] ]
      .map(mapMenu)
      .map(addMenu);

    const newConfiguredMenu = [ 
      ...configured, 
      ...(menus.map(menu => ({
        ...menu, 
        parent: (
          parent 
            ? { ...parent, subMenu: undefined }
            : parent
          ),
      }))),
    ];
    Vue.set(state.menuStructureContext, 'flatMenuConfiguredEdited', newConfiguredMenu);
  },

  [MENU_STRUCTURE_TYPES.MUTATIONS.REMOVE_MENU](state, { parent, menu }) {
    const mapMenu = menu => ({
      ...menu,
      subMenu: menu.subMenu.map(mapMenu),
    });

    const removeMenu = m => { // TODO improve this logic
      return {
        ...m,
        subMenu: [ ...m.subMenu.filter(sm => !(sm.parent?.path === parent?.path && sm.path === menu.path)) ].map(removeMenu),
      };
    };

    const { flatMenuConfigured, flatMenuConfiguredEdited } = state.menuStructureContext;
    const configured = [ ...flatMenuConfiguredEdited ?? flatMenuConfigured ?? [] ]
      .map(mapMenu)
      .filter(sm => !(sm.parent?.path === parent?.path && sm.path === menu.path))
      .map(removeMenu);
    Vue.set(state.menuStructureContext, 'flatMenuConfiguredEdited', configured);
  },

  [MENU_STRUCTURE_TYPES.MUTATIONS.ORDER_CHANGED](state, { parent, menus }) {
    const mapMenu = menu => ({
      ...menu,
      subMenu: menu.subMenu.map(mapMenu),
    });

    const orderMenu = m => { // TODO improve this logic
      if (m?.path === parent?.path) {
        return {
          ...m,
          subMenu: [ ...menus ].map(orderMenu),
        };
      }

      return {
        ...m,
        subMenu: m.subMenu.map(orderMenu),
      };
    };

    const { flatMenuConfigured, flatMenuConfiguredEdited } = state.menuStructureContext;
    const configured = [ ...flatMenuConfiguredEdited ?? flatMenuConfigured ?? [] ].map(mapMenu);
    const newConfiguredMenu = ([{ path: undefined, subMenu: configured}].map(orderMenu))[0].subMenu;
    Vue.set(state.menuStructureContext, 'flatMenuConfiguredEdited', [ ...newConfiguredMenu ]);
  },

  [MENU_STRUCTURE_TYPES.MUTATIONS.SET_MENU_STRUCTURE_CONFIGS](state, payload) {
    Vue.set(state, 'menuStructureConfigs', [ ...payload || [] ]);
  },

  [MENU_STRUCTURE_TYPES.MUTATIONS.ADD_MENU_STRUCTURE_CONFIGS_EDITED](state, payload) {
    if (!payload || !payload.maklernr || !payload.configContext) return;

    const { menuStructureConfigsEdited = [] } = state;

    const index = menuStructureConfigsEdited
      .findIndex(config => config.maklernr === payload.maklernr 
          && config.configContext === payload.configContext);
    if (index >= 0) {
      Vue.set(state.menuStructureConfigsEdited, index, {
        ...(menuStructureConfigsEdited[index]), 
        ...payload, 
      });
    } else {
      Vue.set(state, 'menuStructureConfigsEdited', [ 
        ...menuStructureConfigsEdited, 
        { 
          tempId: uuidv4(), 
          ...payload, 
        }, 
      ]);
    }
  },

  [MENU_STRUCTURE_TYPES.MUTATIONS.REMOVE_MENU_STRUCTURE_CONFIGS_EDITED](state, payload) {
    const { menuStructureConfigs = [], menuStructureConfigsEdited = [] } = state;

    const configIndex = menuStructureConfigs.findIndex(config => config.id === payload.id);
    if (configIndex >= 0) {
      menuStructureConfigs.splice(configIndex, 1);
    }

    const configEditedIndex = menuStructureConfigsEdited.findIndex(config => config.tempId === payload.tempId);
    if (configEditedIndex >= 0) {
      menuStructureConfigsEdited.splice(configEditedIndex, 1);
    }

    Vue.set(state, 'menuStructureConfigs', [ ...menuStructureConfigs ]);
    Vue.set(state, 'menuStructureConfigsEdited', [ ...menuStructureConfigsEdited ]);
  },

  [MENU_STRUCTURE_TYPES.MUTATIONS.RESET_MENU_STRUCTURE_CONFIGS_EDITED](state) {
    Vue.set(state, 'menuStructureConfigsEdited', []);
  },

}
