<template>
<div>
    <BoxContainer :title="selectedTabLabel">
        <Table v-if="rows.length"
            :headers="headers"
            :rows="rows"
            rowId="index"
            hidePagination
        />
        <div v-else>Keine Daten</div>
    </BoxContainer>
</div>
</template>

<script>
import FONDSINFO_TYPES from "@/store/fondsinfo/types";
import BoxContainer from '@/components/core/BoxContainer.vue';
import { mapGetters } from 'vuex';
import Table from "@/components/table2/Table.vue";
import {TextColumn, NumberColumn} from "@/components/table2/table_util.js";

export default {
  components: {
    BoxContainer,
    Table,
  },
  name: "TabMittelherkunft",
  props:{
    selectedTabLabel: {
      type: String
    }
  },
  computed: {
    ...mapGetters({
        mittelherkunft : FONDSINFO_TYPES.GETTERS.FONDSINFO_MITTELHERKUNFT,
    }),
    headers() {
        return {
            center: [
                TextColumn("name", "Beschreibung"),
                NumberColumn("amount", "Betrag", 0),
                TextColumn("currency", "Währung"),
                TextColumn("label", this.mittelherkunft?.label || "in GIK / ex GIK"),
            ],
        };
    },
    rows() {
        return (this.mittelherkunft?.list || []).map((row, index) => ({
            index,
            ...row,
        }));
    },
  },
  mounted() {
    if(!this.mittelherkunft) {
      this.$store.dispatch(FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_MITTELHERKUNFT, 
      {
        isin: this.$route.params?.isin,
        bgsNr: this.$route.params?.bgsNr,
      });
    }
  },

}
</script>

<style>

</style>