<template>
  <div >
    <DBMT query="dbm_rman_backups"/>

  </div>
</template>

<script>

import DBMT from "@/views/dbm/DbmT.vue";



export default {

  components: {
  
    
    DBMT
  },
 
 
  

}

</script>

