<template>
  <div v-if="privacyRegistrationData.config" class="register-privacy-page p-24px">
    <GenericPrivacyTerms
      :info="privacyRegistrationData"
      :contacts="contacts"
      :hideAcceptLabelHtmlText="true"
      @open-link="openLink($event)"
      @terms-accepted="termsAccepted($event)"
      @terms-rejected="termsRejected()"
    />
    <div class="box__container">
      * Ihre persönliche Kundenkennung und den Link zum Kundenzugang erhalten Sie im Zuge der Online-Registrierung per E-Mail.
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';

import {
  isRegisterPrivacyRoute, 
  isRegisterRoute, 
  getRegistrationQuery 
} from '@/router/features-routes/login';

import GenericPrivacyTerms from '@/components/core/privacy/GenericPrivacyTerms.vue';

export default {
  data() {
    return {
      termText: '',
    };
  },
  computed: {
    ...mapGetters({
      registrationData: CORE_TYPES.GETTERS.REGISTRATION_DATA,
      privacyRegistrationData: CORE_TYPES.GETTERS.PRIVACY_REGISTRATION_DATA,
    }),
    contacts() {
      return this.privacyRegistrationData?.contacts || [];
    },
    metaData() {
      return this.privacyRegistrationData?.config?.metaDataConfig || {};
    },
    registerCode() {
      return this.registrationData?.registerCode || '';
    },
  },
  methods: {
    checkIfExistsRegisterCode() {
      if(!this.registerCode) {
        this.$router.push({
          path: '/login/register',
          query: getRegistrationQuery(this.$route),
        });
      }
    },
    getRegistrationPrivacy() {
      this.$store.dispatch(CORE_TYPES.ACTIONS.GET_PRIVACY_REGISTRATION_DATA, {
        code: this.registerCode,
      });
    },
    openLink(event) {
      const text = event?.params?.text;
      const version = event?.params?.version;
      const type = event?.params?.type;

      this.termText = text;
      this.$router.push(`/login/register/privacy/terms-content?version=${version}&type=${type}`);
    },
    termsAccepted(terms) {
      const privacy = {
        ...terms,
        metaData: this.metaData,
      };
      const payload = {
        ...this.registrationData,
        privacy,
        
      };

      this.$store.dispatch(CORE_TYPES.ACTIONS.REGISTRATION, payload)
        .then((loginName) => {
            this.$router.push({
                path: '/login/aktivieren',
                query: {loginName},
            });
        })
        .catch((error) => console.log(error));
    },
    termsRejected() {
      this.$store.commit(CORE_TYPES.MUTATIONS.CLEAR_ALL_REGISTRATION_DATA);
      this.$router.push({
        path: '/login',
        query: getRegistrationQuery(this.$route),
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    if(isRegisterPrivacyRoute(to?.path)) {
      this.$addBreadcrumb({
        to,
        from,
        breadcrumb: this.termText || '',
      });
    } else if(!isRegisterRoute(to?.path)) {
      this.$store.commit(CORE_TYPES.MUTATIONS.CLEAR_ALL_REGISTRATION_DATA);
    }

    next();
  },
  mounted() {
    this.checkIfExistsRegisterCode();
    if (!this.registrationData) {
      this.getRegistrationPrivacy();
    }
  },
  components: {
    GenericPrivacyTerms,
  },
}
</script>
