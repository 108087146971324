var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: "ebase", subtitle: "Übertragung" }
      }),
      _c("div", [
        _vm._m(0),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [_c("FondsFinderSuchMaske")], 1)
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c("FondsFinderPositionsList", {
                attrs: {
                  zurueckButtonText: "Abbrechen",
                  addPositionsOnBack: false
                },
                on: {
                  goBack: function($event) {
                    return _vm.addFonds($event)
                  }
                }
              })
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "antrag-components__container" }, [
        _c(
          "div",
          { staticClass: "box__container" },
          [
            _c("FormHeader", { attrs: { label: "Wertpapiere" } }),
            _c("p"),
            _c("InputField", {
              attrs: { label: "bisherige Depotnummer" },
              model: {
                value: _vm.positionData.bisherDepotnr,
                callback: function($$v) {
                  _vm.$set(_vm.positionData, "bisherDepotnr", $$v)
                },
                expression: "positionData.bisherDepotnr"
              }
            }),
            _c("Table", {
              attrs: {
                tableId: "f168f001-d40a-4a9a-ba2b-aa8d3ce74e30",
                headers: _vm.headers,
                rows: _vm.rows,
                rowId: "index",
                hidePagination: ""
              },
              on: {
                "action-DELETE": function($event) {
                  return _vm.deleteFond($event.index - 1)
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "Anteile",
                  fn: function(row) {
                    return [
                      _c("InputField", {
                        model: {
                          value: _vm.positionData["anteile" + row.index],
                          callback: function($$v) {
                            _vm.$set(
                              _vm.positionData,
                              "anteile" + row.index,
                              $$v
                            )
                          },
                          expression: "positionData['anteile' + row.index]"
                        }
                      })
                    ]
                  }
                },
                {
                  key: "Gesamtbestand",
                  fn: function(row) {
                    return [
                      _c("Input", {
                        attrs: { type: "checkbox" },
                        model: {
                          value: _vm.positionData["alle" + row.index],
                          callback: function($$v) {
                            _vm.$set(_vm.positionData, "alle" + row.index, $$v)
                          },
                          expression: "positionData['alle' + row.index]"
                        }
                      })
                    ]
                  }
                }
              ])
            }),
            _vm.show == true
              ? _c(
                  "BaseButton",
                  {
                    on: {
                      click: function($event) {
                        _vm.searchingPositions = true
                      }
                    }
                  },
                  [_vm._v("Wertpapiere hinzufügen")]
                )
              : _vm._e(),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("BaseButton", { on: { click: _vm.onBackButton } }, [
                    _vm._v("Zurück zum Formular")
                  ])
                ],
                1
              )
            ])
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("h2", [_vm._v("Wertpapiersuche")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }