var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          defaultMenu: _vm.$appNavigation.currentTabMenu,
          title: "GDV Datenimport",
          subtitle: "Dateien in Bearbeitung"
        }
      }),
      _c(
        "BaseFilter",
        {
          attrs: {
            title: "Dateien in Bearbeitung",
            filterId: "Dateien in Bearbeitung",
            configFilter: _vm.filterConfig,
            metadata: _vm.metadata,
            defaultOptions: _vm.defaultOptions,
            showSaveButton: "",
            immidiateSearch: true
          },
          on: { onFilter: _vm.onFilter }
        },
        [
          _vm.isIntern
            ? _c(
                "BaseButton",
                { attrs: { isSecondary: "" }, on: { click: _vm.goToSettings } },
                [_vm._v(" Einstellungen ")]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.loading
            ? _c("GhostLoading", { attrs: { type: "table" } })
            : _vm.rows.length
            ? _c("Table", {
                attrs: {
                  tableId: "fea3ca15-41a8-430f-85d2-d7dde32bg360",
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowsPerPage: 30,
                  showColumnsConfigEvenNoTitle: true,
                  rowId: "index"
                },
                on: {
                  "action-CONFIRM_ERRORS": _vm.confirmErrors,
                  "action-CONFIRM": _vm.confirmInterface,
                  "action-UNLOCK": _vm.unlock,
                  "click-numRowsError": _vm.goToDatenimport,
                  "click-status": _vm.goToDatenimport,
                  "action-INFO": _vm.info
                },
                scopedSlots: _vm._u([
                  {
                    key: "statusColumn",
                    fn: function(row) {
                      return [
                        row.statusIcon === "PhCheck"
                          ? _c("PhCheck", {
                              class: row.statusColor,
                              attrs: { size: 16 }
                            })
                          : _vm._e(),
                        row.statusIcon === "PhX"
                          ? _c("PhX", {
                              class: row.statusColor,
                              attrs: { size: 16 }
                            })
                          : _vm._e(),
                        row.statusIcon === "PhArrowRight"
                          ? _c("PhArrowRight", {
                              class: row.statusColor,
                              attrs: { size: 16 }
                            })
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            : _c("NoData")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }