var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.fragments.length
    ? _c(
        "div",
        { staticClass: "mb-3" },
        [
          _vm._l(_vm.fragments, function(fragment, k) {
            return [
              _c(
                "div",
                { key: k * 100, class: fragment[0].begin },
                [
                  _vm._l(fragment, function(field, idx) {
                    return [
                      _vm.react && field.dataType === "text"
                        ? _c("InputField", {
                            key: k * 100 + idx,
                            class: field.class,
                            attrs: {
                              disabled: field.disabled,
                              validation_path: field.key,
                              isNotReactive: _vm.isNotReactive,
                              label: field.label,
                              isComponentHalfSize: "",
                              validateUntouched: ""
                            },
                            on: {
                              onBlur: function($event) {
                                return _vm.onBlur(field)
                              },
                              change: function($event) {
                                return _vm.$emit("change", {
                                  key: field.key,
                                  value: $event
                                })
                              }
                            },
                            model: {
                              value: _vm.values[field.key],
                              callback: function($$v) {
                                _vm.$set(_vm.values, field.key, $$v)
                              },
                              expression: "values[field.key]"
                            }
                          })
                        : _vm.react && field.dataType === "number"
                        ? _c("InputField", {
                            key: k * 100 + idx,
                            ref: field.key,
                            refInFor: true,
                            class: field.class,
                            attrs: {
                              disabled: field.disabled,
                              validation_path: field.key,
                              isNotReactive: _vm.isNotReactive,
                              label: field.label,
                              value: _vm.values[field.key],
                              isComponentHalfSize: "",
                              validateUntouched: ""
                            },
                            on: {
                              change: function(value) {
                                return _vm.setValue(field, value)
                              },
                              focus: function($event) {
                                return _vm.onNumberFocus(field)
                              },
                              onBlur: function($event) {
                                return _vm.onNumberBlur(field)
                              }
                            }
                          })
                        : _vm.react && field.dataType === "euro"
                        ? _c("InputField", {
                            key: k * 100 + idx,
                            ref: field.key,
                            refInFor: true,
                            class: field.class,
                            attrs: {
                              disabled: field.disabled,
                              validation_path: field.key,
                              isNotReactive: _vm.isNotReactive,
                              label: field.label,
                              type: "currency",
                              value: _vm.values[field.key],
                              isComponentHalfSize: "",
                              validateUntouched: ""
                            },
                            on: {
                              change: function(value) {
                                return _vm.setValue(field, value)
                              },
                              focus: function($event) {
                                return _vm.onNumberFocus(field)
                              },
                              onBlur: function($event) {
                                return _vm.onNumberBlur(field)
                              }
                            }
                          })
                        : _vm.react && field.dataType === "fremdw"
                        ? _c("InputField", {
                            key: k * 100 + idx,
                            ref: field.key,
                            refInFor: true,
                            class: field.class,
                            attrs: {
                              disabled: field.disabled,
                              validation_path: field.key,
                              isNotReactive: _vm.isNotReactive,
                              label: field.label,
                              type: "foreign_cy",
                              foreign_currency: _vm.values[field.fremdw],
                              value: _vm.values[field.key],
                              isComponentHalfSize: "",
                              validateUntouched: ""
                            },
                            on: {
                              change: function(value) {
                                return _vm.setValue(field, value)
                              },
                              focus: function($event) {
                                return _vm.onNumberFocus(field)
                              },
                              onBlur: function($event) {
                                return _vm.onNumberBlur(field)
                              }
                            }
                          })
                        : _vm.react && field.dataType === "proz"
                        ? _c("InputField", {
                            key: k * 100 + idx,
                            ref: field.key,
                            refInFor: true,
                            class: field.class,
                            attrs: {
                              disabled: field.disabled,
                              validation_path: field.key,
                              isNotReactive: _vm.isNotReactive,
                              label: field.label,
                              type: "percent",
                              value: _vm.values[field.key],
                              isComponentHalfSize: "",
                              validateUntouched: ""
                            },
                            on: {
                              change: function(value) {
                                return _vm.setValue(field, value)
                              },
                              focus: function($event) {
                                return _vm.onNumberFocus(field)
                              },
                              onBlur: function($event) {
                                return _vm.onNumberBlur(field)
                              }
                            }
                          })
                        : _vm.react && field.dataType === "label"
                        ? _c("FormLabel", {
                            key: k * 100 + idx,
                            staticClass: "mb-2",
                            class: field.class,
                            attrs: {
                              label: field.label,
                              config: _vm.labelConfig(field)
                            }
                          })
                        : _vm.react && field.dataType === "check"
                        ? _c("InputCheckBoxItem", {
                            key: k * 100 + idx,
                            class: field.class,
                            attrs: {
                              disabled: field.disabled,
                              validation_path: field.key,
                              label: field.label,
                              validateUntouched: ""
                            },
                            on: {
                              input: function($event) {
                                return _vm.$emit("change", {
                                  key: field.key,
                                  value: $event
                                })
                              }
                            },
                            model: {
                              value: _vm.values[field.key],
                              callback: function($$v) {
                                _vm.$set(_vm.values, field.key, $$v)
                              },
                              expression: "values[field.key]"
                            }
                          })
                        : _vm.react && field.dataType === "date"
                        ? _c("DatePickerField", {
                            key: k * 100 + idx,
                            class: field.class,
                            attrs: {
                              disabled: field.disabled,
                              validation_path: field.key,
                              label: field.label,
                              isNotReactive: _vm.isNotReactive,
                              value: _vm.values[field.key],
                              isComponentHalfSize: "",
                              validateUntouched: ""
                            },
                            on: {
                              input: function(datum) {
                                return _vm.setDatum(field.key, datum)
                              },
                              blur: function($event) {
                                return _vm.onBlur(field)
                              },
                              change: function($event) {
                                return _vm.$emit("change", {
                                  key: field.key,
                                  value: $event
                                })
                              }
                            }
                          })
                        : _vm.react && field.dataType === "area"
                        ? _c("InputTextArea", {
                            key: k * 100 + idx,
                            attrs: {
                              disabled: field.disabled,
                              label: field.label,
                              isNotReactive: _vm.isNotReactive,
                              isComponentHalfSize: "",
                              validateUntouched: ""
                            },
                            on: {
                              change: function($event) {
                                return _vm.$emit("change", {
                                  key: field.key,
                                  value: $event
                                })
                              }
                            },
                            model: {
                              value: _vm.values[field.key],
                              callback: function($$v) {
                                _vm.$set(_vm.values, field.key, $$v)
                              },
                              expression: "values[field.key]"
                            }
                          })
                        : _vm.react && field.dataType === "combo"
                        ? _c("ComboBox", {
                            key: k * 100 + idx,
                            class: field.class,
                            attrs: {
                              disabled: field.disabled,
                              validation_path: field.key,
                              label: field.label,
                              values: _vm.getValues(field),
                              isComponentHalfSize: "",
                              validateUntouched: ""
                            },
                            on: {
                              blur: function($event) {
                                return _vm.onBlur(field)
                              },
                              change: function($event) {
                                return _vm.$emit("change", {
                                  key: field.key,
                                  value: $event
                                })
                              }
                            },
                            model: {
                              value: _vm.values[field.key],
                              callback: function($$v) {
                                _vm.$set(_vm.values, field.key, $$v)
                              },
                              expression: "values[field.key]"
                            }
                          })
                        : _vm.react && field.dataType === "radio"
                        ? _c("InputRadioBoxGroup", {
                            key: k * 100 + idx,
                            class: field.class,
                            attrs: {
                              disabled: field.disabled,
                              validation_path: field.key,
                              title: field.label,
                              values: _vm.getValues(field),
                              validateUntouched: ""
                            },
                            on: {
                              input: function($event) {
                                return _vm.$emit("change", {
                                  key: field.key,
                                  value: $event
                                })
                              }
                            },
                            model: {
                              value: _vm.values[field.key],
                              callback: function($$v) {
                                _vm.$set(_vm.values, field.key, $$v)
                              },
                              expression: "values[field.key]"
                            }
                          })
                        : _vm.react && field.dataType === "info"
                        ? _c("BaseCollapsable", {
                            key: k * 100 + idx,
                            staticClass: "mb-3",
                            class: field.class,
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "title",
                                  fn: function() {
                                    return [
                                      _c(
                                        "span",
                                        { staticClass: "box__title" },
                                        [_vm._v(" " + _vm._s(field.key) + " ")]
                                      )
                                    ]
                                  },
                                  proxy: true
                                },
                                {
                                  key: "content",
                                  fn: function() {
                                    return [
                                      _c("div", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.sanitize(field.label)
                                          )
                                        }
                                      })
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              true
                            )
                          })
                        : _vm.react && field.dataType === "button"
                        ? _c(
                            "BaseButton",
                            {
                              key: k * 100 + idx,
                              staticClass: "mb-3",
                              class: field.class,
                              attrs: { isSecondary: "" },
                              on: {
                                click: function($event) {
                                  return _vm.$emit("onBtClick", field)
                                }
                              }
                            },
                            [_vm._v(_vm._s(field.label))]
                          )
                        : _vm.react && field.dataType === "buttonP"
                        ? _c(
                            "BaseButton",
                            {
                              key: k * 100 + idx,
                              staticClass: "mb-3",
                              class: field.class,
                              on: {
                                click: function($event) {
                                  return _vm.$emit("onBtClick", field)
                                }
                              }
                            },
                            [_vm._v(_vm._s(field.label))]
                          )
                        : _vm.react && field.dataType === "slot"
                        ? _c(
                            "div",
                            { key: k * 100 + idx, class: field.class },
                            [
                              _vm._t(field.key, null, {
                                data: { field: field }
                              })
                            ],
                            2
                          )
                        : _vm.react && field.dataType === "table"
                        ? _c(
                            "div",
                            { key: k * 100 + idx, class: field.class },
                            [
                              _vm.rowsCount === 0
                                ? _c("div", [_vm._v("Keine Daten")])
                                : _vm._e(),
                              _vm.rowsCount
                                ? _c(
                                    "div",
                                    {},
                                    [
                                      _c("Table", {
                                        ref: "tableResult",
                                        refInFor: true,
                                        staticClass: "table-container",
                                        attrs: {
                                          title: field.label,
                                          configModalTitle:
                                            "Depotposition_" + field.data2,
                                          tableId: field.data4,
                                          additionalCollapsed: true,
                                          tableData: _vm.tableData,
                                          cardViewEnabled: false,
                                          filterEnabled: false,
                                          exportEnabled: false,
                                          rowsPerPage: 15,
                                          count: _vm.rowsCount
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "editierbar",
                                              fn: function(ref) {
                                                var data = ref.data
                                                return [
                                                  _c(
                                                    "a",
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.$emit(
                                                            "gotoEdit",
                                                            field,
                                                            data.row
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("span", {}, [
                                                        _vm._v(
                                                          _vm._s(
                                                            data.row.editierbar
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                ]
                                              }
                                            },
                                            {
                                              key: "displayCheck",
                                              fn: function(ref) {
                                                var data = ref.data
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      style:
                                                        data.row.displayCheck
                                                    },
                                                    [
                                                      _c("PhCheck", {
                                                        staticClass: "m-1",
                                                        attrs: { size: 24 }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            },
                                            {
                                              key: "actions",
                                              fn: function(ref) {
                                                var data = ref.data
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "action-cell"
                                                    },
                                                    [
                                                      _vm.getAktionLink(
                                                        data.row.actions
                                                      ).length
                                                        ? _c(
                                                            "BaseDropdownMenu",
                                                            {
                                                              staticStyle: {
                                                                "text-align":
                                                                  "right"
                                                              },
                                                              attrs: {
                                                                placement:
                                                                  "bottom-right"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "hook-target",
                                                                    fn: function() {
                                                                      return [
                                                                        _c(
                                                                          "ph-dots-three-vertical",
                                                                          {
                                                                            staticClass:
                                                                              "clickable",
                                                                            attrs: {
                                                                              size: 24
                                                                            }
                                                                          }
                                                                        )
                                                                      ]
                                                                    },
                                                                    proxy: true
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "action-texts"
                                                                },
                                                                [
                                                                  _vm._l(
                                                                    _vm.getAktionLink(
                                                                      data.row
                                                                        .actions
                                                                    ),
                                                                    function(
                                                                      link
                                                                    ) {
                                                                      return [
                                                                        link.type
                                                                          ? _c(
                                                                              "a",
                                                                              {
                                                                                key:
                                                                                  link.key,
                                                                                staticClass:
                                                                                  "btn btn-clean clickable",
                                                                                attrs: {
                                                                                  target:
                                                                                    "_blank",
                                                                                  href:
                                                                                    link.type
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "PhArrowsLeftRight",
                                                                                  {
                                                                                    staticClass:
                                                                                      "mr-1",
                                                                                    attrs: {
                                                                                      size: 24
                                                                                    }
                                                                                  }
                                                                                ),
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    link.label
                                                                                  )
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          : link.key
                                                                          ? _c(
                                                                              "a",
                                                                              {
                                                                                key:
                                                                                  link.key,
                                                                                staticClass:
                                                                                  "btn btn-clean clickable",
                                                                                attrs: {
                                                                                  target:
                                                                                    "_blank"
                                                                                },
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.$emit(
                                                                                      "doAction",
                                                                                      link.key,
                                                                                      data.row
                                                                                    )
                                                                                  }
                                                                                }
                                                                              },
                                                                              [
                                                                                link.key ===
                                                                                "delete"
                                                                                  ? _c(
                                                                                      "PhTrash",
                                                                                      {
                                                                                        staticClass:
                                                                                          "mr-1",
                                                                                        attrs: {
                                                                                          size: 24
                                                                                        }
                                                                                      }
                                                                                    )
                                                                                  : _vm._e(),
                                                                                link.key ===
                                                                                "limit"
                                                                                  ? _c(
                                                                                      "PhGauge",
                                                                                      {
                                                                                        staticClass:
                                                                                          "mr-1",
                                                                                        attrs: {
                                                                                          size: 24
                                                                                        }
                                                                                      }
                                                                                    )
                                                                                  : link.key ===
                                                                                    "courtage"
                                                                                  ? _c(
                                                                                      "PhFileText",
                                                                                      {
                                                                                        staticClass:
                                                                                          "mr-1",
                                                                                        attrs: {
                                                                                          size: 24
                                                                                        }
                                                                                      }
                                                                                    )
                                                                                  : link.key ===
                                                                                    "transakt"
                                                                                  ? _c(
                                                                                      "PhArrowsLeftRight",
                                                                                      {
                                                                                        staticClass:
                                                                                          "mr-1",
                                                                                        attrs: {
                                                                                          size: 24
                                                                                        }
                                                                                      }
                                                                                    )
                                                                                  : link.key ===
                                                                                    "info"
                                                                                  ? _c(
                                                                                      "PhInfo",
                                                                                      {
                                                                                        staticClass:
                                                                                          "mr-1",
                                                                                        attrs: {
                                                                                          size: 24
                                                                                        }
                                                                                      }
                                                                                    )
                                                                                  : link.key ===
                                                                                    "duplicate"
                                                                                  ? _c(
                                                                                      "PhCopy",
                                                                                      {
                                                                                        staticClass:
                                                                                          "mr-1",
                                                                                        attrs: {
                                                                                          size: 24
                                                                                        }
                                                                                      }
                                                                                    )
                                                                                  : link.key ===
                                                                                    "wiedervorlage"
                                                                                  ? _c(
                                                                                      "PhSwatches",
                                                                                      {
                                                                                        staticClass:
                                                                                          "mr-1",
                                                                                        attrs: {
                                                                                          size: 24
                                                                                        }
                                                                                      }
                                                                                    )
                                                                                  : _vm._e(),
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      link.label
                                                                                    )
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _vm._e()
                                                                      ]
                                                                    }
                                                                  )
                                                                ],
                                                                2
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]
                          )
                        : _c(
                            "div",
                            {
                              key: k * 100 + idx,
                              staticStyle: { color: "var(--color-danger)" }
                            },
                            [
                              _vm._v(
                                _vm._s(field.key) +
                                  " " +
                                  _vm._s(field.dataType) +
                                  " " +
                                  _vm._s(field.label)
                              )
                            ]
                          )
                    ]
                  })
                ],
                2
              )
            ]
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }