<template>
    <div>
        <PageHeaderTitleNavigation
            :title="$appNavigation.currentMenu.parent.label"
            :subtitle="$appNavigation.currentMenu.label"
            :defaultMenu="$appNavigation.currentTabMenu"
            :actions="actions"
            @action-NEW="editLimit({isin: 'neu'})"
            @action-REMOVE_OVERTAKEN="$refs.confirmDeleteOvertaken.open()"
            @action-RESET="doConfirm('zurücksetzen')"
        />

        <div class="box__container">
            <div  class="box__title">Wertpapierlimits</div>
            <div>
                Hier können Sie ein globales Limit für die von Ihnen gewählten Fonds über <b>alle Kunden</b>, 
				die diese Fonds haben, setzen. Wird ein Limit erreicht, so werden Sie und auf Wunsch auch Ihre Kunden, per Mail benachrichtigt.
            </div>
            <div class="layout__negative-margin--8 antrag-action-button__container">
                <InputToggleSwitch v-model="includeStruktur" label="Limits der Untermakler anzeigen" inLineLabel suppressValidationMessage @input="reload()"/>
            </div>
        </div>
        <div class="box__container">
            <div v-if="!loading && rows && rows.length">
                <Table
                    tableId="d5fcb6dc-1fd8-493b-86d7-82d1b7d5657c"
                    :title="TABLE_TITLE"
                    :headers="headers"
                    :rows="rows"
                    :rowsPerPage="20"
                    :exportConfig="{roottext: 'Wertpapierlimits'}"
                    :mobileConfig="{title: 'wertpapierName', headers: ['isin', 'aktuellKurs', 'unteresLimit', 'oberesLimit']}"
                    rowId="id"
                    @action-edit="editLimit"
                    @action-delete="selectedLimit = $event; $refs.confirmDelete.open()"
                    @action-factsheet="viewDocument($event, 'FACTSHEET')"
                    @action-kag="viewDocument($event, 'MR')"
                    @action-kid="viewDocument($event, 'KIID')"
                    @action-fondsinfo="gotoFondsinfo($event && $event.isin)"
                >
                </Table>
            </div>
            <GhostLoading v-else-if="loading" type="table" :title="TABLE_TITLE" />
            <NoData v-else :title="TABLE_TITLE" />
        </div>
        <BaseModal ref="confirmDelete" 
            labelButtonConfirm="Ja" 
            labelButtonCancel="Nein" 
            @onCancelButton="selectedLimit = null" 
            @onCloseButton="selectedLimit = null" 
            @onConfirmButton="removeLimit()"
        >
            <template v-slot:modalTitle>
                <PhWarning :size="16" class="mr-2" style="color: orangered"/>Löschen
            </template>
            <div>Wollen Sie wirklich das Limit für das Wertpapier {{selectedLimit && selectedLimit.wertpapierName}} löschen?</div>
        </BaseModal>
        
        <BaseModal ref="confirmDeleteOvertaken" 
            labelButtonConfirm="Ja" 
            labelButtonCancel="Nein" 
            @onConfirmButton="removeOvertaken()"
        >
            <template v-slot:modalTitle>
                <PhWarning :size="16" class="mr-2" style="color: orangered"/>Löschen
            </template>
            <div>Wollen Sie wirklich alle überschrittene Limits löschen?</div>
        </BaseModal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LIMIT_TYPES from '@/store/limit/types';
import BaseButton from '@/components/core/BaseButton.vue';
import Table from "@/components/table2/Table.vue";
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import { PhCheck, PhWarning } from "phosphor-vue";
import mixin from '@/mixins/limit/limit-mixin.js';
import BaseModal from '@/components/core/BaseModal.vue';
import {TextColumn, NumberColumn, SimpleAction, Icon} from "@/components/table2/table_util.js";
import {wpListeMixin} from '@/mixins/wp-suche.js';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';

const TABLE_TITLE = 'Globale Wertpapierlimits';

export default {
    mixins: [mixin, wpListeMixin],
    components: {
        BaseButton,
        Table,
        InputToggleSwitch,
        PhWarning,
        BaseModal,
        PageHeaderTitleNavigation,
        GhostLoading,
        NoData,
    },
    data() {
        return {
            TABLE_TITLE,
            loading: false,
            includeStruktur: '0',
            removeAction: null,
            selectedLimit: null,
            columnLabelAusgangskurs: 'Ausgangskurs',
            modus: 'LIMIT_KURS',
        }
    },
    mounted() {
        this.reload();
    },
    computed: {
        ...mapGetters({
            wertpapierlimits: LIMIT_TYPES.GETTERS.WERTPAPIER_LIMIT_LIST,
        }),
        headers() {
            const headers = this.headersWertpapierLimits();
            headers.center.splice(7, 0,
                TextColumn("isDynamic", "Dynamik"),
                NumberColumn("dynamicValue", "Dynamik Ausgangswert", 2));
            if ('1' === this.includeStruktur) {
                // insert after status
                headers.lockedLeft.splice(1, 0, TextColumn("maklernr", "Maklernr").makeAlwaysVisible());
            }
            return headers;
        },
        rows() {
            const result = !this.wertpapierlimits ? [] : this.wertpapierlimits.map((limit, index) => {
                const actions = [SimpleAction("edit", 'PhPencil', "Limit bearbeiten"), SimpleAction("delete", 'PhTrash', "Limit löschen"),
                    SimpleAction("fondsinfo", "PhInfo", "Fondsinfo")];
                if (limit?.factsheet) {
                    actions.push(SimpleAction("factsheet", 'PhFile', "Factsheet"));
                }
                if (limit?.kag) {
                    actions.push(SimpleAction("kag", 'PhFile', "Factsheet KAG"));
                }
                if (limit?.kid) {
                    actions.push(SimpleAction("kid", 'PhFile', "PRIIP-BIB"));
                }
                return {
                    ...limit,
                    status: [limit.statusStr === 'ok'
                        ? Icon(PhCheck, "Ok", 20, 'bold', 'color-success')
                        : Icon(PhWarning, "Warnung", 20, 'bold', 'color-danger')
                    ],
                    id: limit.isin + index,
                    actions: actions,
                };
            });
            return result;
        },
        actions() {
            return [
                PageHeaderSimpleAction('NEW', 'Neu'),
                PageHeaderSimpleAction('REMOVE_OVERTAKEN', 'Überschrittene Limits löschen'),
                PageHeaderSimpleAction('RESET', 'Überschrittene Limits zurücksetzen').withDisabled(() => !this.rows?.length )
            ]
        }
    },
    methods: {
        editLimit(event) {
            if (event?.isin) {
                this.$addBreadcrumb({
                    label: `zurück zu den Wertpapierlimits`,
                    fullPath: this.$route.fullPath,
                    breadcrumb: 'Wertpapierlimit',
                });
                this.$router.push(`${this.mainPath}/wertpapierlimit/${event.isin}`)
            }
        },
        removeLimit() {
            if (this.selectedLimit?.isin) {
                this.$store.dispatch(LIMIT_TYPES.ACTIONS.DELETE_FONDS_LIMIT, {id: this.selectedLimit.isin, modus: this.modus});
            }
        },
        async reload() {
            try {
                this.loading = true;
                await this.$store.dispatch(LIMIT_TYPES.ACTIONS.GET_WERTPAPIER_LIMIT_LIST, {includeStruktur: this.includeStruktur});
            } finally {
                this.loading = false;
            }
        },
        removeOvertaken() {
            this.$store.dispatch(LIMIT_TYPES.ACTIONS.DELETE_OVERTAKEN_WERTPAPIER_LIMITS, {includeStruktur: this.includeStruktur, modus: this.modus});
        },
        doConfirm(exec) {
            this.doConfirmAction(exec, 'wertpapierLimitList');
        },
    },

}
</script>

<style>

</style>