<template>
  <div class="draggable--container" :style="draggableContainerStyle">
    <div class="draggable--item" ref="draggableItem">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import BrowserSupport from '@/browser-support';
const MOUSE_EVENT_OPTIONS = BrowserSupport.supportsPassive ? { passive: true, capture: false, } : false;
const MOUSE_EVENT_NO_PASSIVE_OPTIONS = BrowserSupport.supportsPassive ? { passive: false, capture: false, } : false;

export default {
  data() {
    return {
      isDragging: false,
      startX: null,
      startY: null,
      ro: null,
      draggableContainerStyle: {
        height: 0,
      }
    }
  },
  methods: {
    onStart(event) {
      this.isDragging = true;
      const draggableDiv = this.$refs.draggableItem;
      this.startX = (event.clientX || event.targetTouches?.[0]?.clientX) - draggableDiv.offsetLeft;
      this.startY = (event.clientY || event.targetTouches?.[0]?.clientY) - draggableDiv.offsetTop;
    },
    onMove(event) {
      if (this.isDragging) {
        const draggableDiv = this.$refs.draggableItem;
        draggableDiv.style.left = (event.clientX || event.targetTouches?.[0]?.clientX) - this.startX + 'px';
        draggableDiv.style.top = (event.clientY || event.targetTouches?.[0]?.clientY) - this.startY + 'px';
      }
    },
    onEnd() {
      this.isDragging = false;
    },
    onResize() {
      this.draggableContainerStyle.height = `${this.$refs.draggableItem.clientHeight}px`;
    }
  },
  mounted() {
    this.ro = new ResizeObserver(this.onResize)
    this.ro.observe(this.$refs.draggableItem);

    const draggableDiv = this.$refs.draggableItem;
    
    draggableDiv.addEventListener('mousedown', this.onStart);
    document.addEventListener('mousemove', this.onMove, MOUSE_EVENT_NO_PASSIVE_OPTIONS);
    document.addEventListener('mouseup', this.onEnd, MOUSE_EVENT_OPTIONS);

    draggableDiv.addEventListener('touchstart', this.onStart);
    document.addEventListener('touchmove', this.onMove, MOUSE_EVENT_NO_PASSIVE_OPTIONS);
    document.addEventListener('touchend', this.onEnd, MOUSE_EVENT_OPTIONS);
  },
  beforeDestroy() {
    this.ro.unobserve(this.$refs.draggableItem);

    const draggableDiv = this.$refs.draggableItem;
    
    draggableDiv.removeEventListener('mousedown', this.onStart);
    document.removeEventListener('mousemove', this.onMove, MOUSE_EVENT_NO_PASSIVE_OPTIONS);
    document.removeEventListener('mouseup', this.onEnd, MOUSE_EVENT_OPTIONS);

    draggableDiv.removeEventListener('touchstart', this.onStart);
    document.removeEventListener('touchmove', this.onMove, MOUSE_EVENT_NO_PASSIVE_OPTIONS);
    document.removeEventListener('touchend', this.onEnd, MOUSE_EVENT_OPTIONS);
  },
}
</script>

<style scoped>

.draggable--container {
  position: relative;
  top: 0;
  left: 0;
}

.draggable--item {
  position: absolute;
  cursor: move;
}

</style>