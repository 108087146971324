<template>
  <div>
    Übersicht externe Konten <ph-warning v-if="showWarningAuthNeeded" :size="24" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MULTIBANKING_TYPES from '@/store/multiBanking/types'
import { PhWarning } from 'phosphor-vue'

export default {
  computed: {
    ...mapGetters({
      accounts: MULTIBANKING_TYPES.GETTERS.ACCOUNTS,
    }),
    showWarningAuthNeeded() {
      return this.accounts?.filter(account => account.authNeeded).length 
    }
  },
  mounted() {
    this.$store.dispatch(MULTIBANKING_TYPES.ACTIONS.LOAD_ALL_MULTIBANKING_DATA)
  },
  components: {
    PhWarning
  }
}
</script>

<style scoped>

</style>