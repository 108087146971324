<template>
  <div class="input-forms__container">
    <div class="input-forms__label-container">
      <div class="row">
        <div class="col">
          <div class="input-forms__label-content input-forms__label-content--bigger" v-if="title">
            {{title}}
          </div>
        </div>
      </div>
    </div>
    <div class="input-forms__input-container">
      <div v-if="value && value.length">
        <div>
            <Table
                tableId="807a4783-84f1-4fe4-a265-4653aeaa4ec5"
                :headers="headers"
                :rows="rows"
                rowId="index"
                hidePagination
                @action-EDIT="openPosition($event.index)"
                @action-DELETE="deletePosition($event.index)"
            />
        </div>
      </div>
      <div v-else>
        <div>Depotauswahl ist leer</div>
      </div>
    </div>
    <hr>
    <div class="row"><div class="col">
      Übertrag eines Depots
    </div></div>
    <div class="row">
      <div class="col">
        <ComboBox :values="config.depots" v-model="depot" firstEmpty/>
      </div>
      <div class="col">
        <BaseButton @click="openNewPosition(depot)" :disabled="!depot">Formular für Depot erstellen</BaseButton>
      </div>
    </div>
    <div class="row"><div class="col">
      Freies Übertragsformular (Hier können Sie alle Daten selbst eingeben.)
    </div></div>
    <div class="row">
      <div class="col">
      </div>
      <div class="col">
        <BaseButton @click="openNewPosition()">Formular erstellen</BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ANTRAG_TYPES from '@/store/antrag/types';
import CUSTOMERDATA_TYPES from "@/store/customerData/types.js";
import BaseButton from '@/components/core/BaseButton.vue';
import antragNavigationMixin from '@/mixins/antrag/antrag-navigation-mixin.js';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, NumberColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import { PhPencil, PhTrash } from 'phosphor-vue';

export default {
  mixins: [antragNavigationMixin],
  components: {
    BaseButton,
    ComboBox,
    Table,
    PhPencil,
    PhTrash,
  },
  props: {
    title: {
    },
    config: {
    },
    value: {
    },
    categoryId: {
    },
    antragId: {
      type: String,
      required: true,
    },
  },
    data() {
        return {
            depot: null,
            gesellschaft: null,
            headers: {
                lockedLeft: [
                    TextColumn("Gesellschaft", "Gesellschaft"),
                ],
                center: [
                    TextColumn("Depotnummer", "Depotnummer"),
                    NumberColumn("Menge", "Menge"),
                ],
                lockedRight: [
                    ActionColumn("actions", "Aktionen"),
                ],
            },
        }
    },
    computed: {
        ...mapGetters({
            antragData: ANTRAG_TYPES.GETTERS.ANTRAEGE_DATA,
            customerData: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA,
            additionalPersons: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_ADDITIONAL_PERSONS,
        }),
        rows() {
            const isinIndices = []
            for (let i = 1; i <= this.config.anzahlFonds; i++)
                isinIndices.push(i)
            const actions = [
                SimpleAction("EDIT", PhPencil, "Bearbeiten"),
                SimpleAction("DELETE", PhTrash, "Löschen"),
            ];
            return this.value.map((position, index) => ({
                Gesellschaft: position.bisherManuellName || position.gesellschaft,
                Depotnummer: position.bisherDepotnr,
                Menge: (isinIndices.find(i => !position["isin" + i]) || (this.config.anzahlFonds + 1)) - 1,
                index,
                actions,
            }));
        },
        persons() {
            const additionalPersons = (this.additionalPersons || []);
            return [this.customerData, ...additionalPersons];
        },
    },
  mounted() {
    this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA);
    this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA_ADDITIONAL_PERSONS);
  },
  methods: {
    openNewPosition(value = null) {
      let data = {}
      if (value != null) {
        data = this.config.gesellschaften.find(g => g.value == value) || this.config.depots.find(g => g.value == value)
      }
      data.alle = false;
     
      for (let i = 1; i <= 2; i++) {
        const personId = this.antragData[this.antragId] && this.antragData[this.antragId]["INPUT_PERSONEN_INHABER" + i]
        if (!personId)
          continue
        const person = this.persons.find(person => person && (person.personId+'') === (personId+''))
        if (person) {
          data["bisherName" + i] = person.personalDataAddress && person.personalDataAddress.lastName
          data["bisherVorname" + i] = person.personalDataAddress && person.personalDataAddress.firstName
          data["bisherID" + i] = person.personalDataTax && person.personalDataTax.taxID
        }
      }
      const positions = this.value ? this.value.slice() : []
      positions.push(data)
      this.$emit('input', positions)
      this.openPosition(positions.length - 1)
    },
    openPosition(index) {
      const query = {}
      this.$router.push({ path: `/beratung/formulare/antrag/${this.$route.params.lagerstelle}/${this.$route.params.antragsName}/${this.$route.params.step}/ebaseedelmetalldepotuebertraginn/${this.categoryId}/${index}`, query})
    },
    deletePosition(index) {
      const positions = this.value.slice()
      positions.splice(index, 1)
      this.$emit('input', positions)
    },
  }
}
</script>

<style scoped>
  .input-forms__label-content--bigger {
    font-size: 1.5rem;
  }
  .input-forms__label-content--bigger > i {
    font-size: 2rem;
  }
</style>