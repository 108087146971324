var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    { staticClass: "btn-clean clickable" },
    [
      _c(_vm.iconComponent, { tag: "component", attrs: { size: _vm.size } }),
      _vm.expanded
        ? _c(
            "span",
            { staticClass: "pl-1" },
            [
              _c("DownloadLink", {
                attrs: {
                  asLabel: "",
                  title: _vm.label,
                  queryParams: _vm.queryParams,
                  filename: _vm.filename,
                  downloadServicePath: _vm.downloadServicePath,
                  href: _vm.href
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }