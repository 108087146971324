<template>
  <div class="stepper-item__container clickable" @click="$emit('click', $event)"
    :class="{ 'stepper-item--selected': isCurrent}"
    style="width:100%"
    @mouseover="handleMouseOver($event)" @mouseleave="handleMouseLeave($event)"
    :tid="_generateTidFromString(step.label)">
    <div class="stepper-item__icon">
      <VerticalStepIcon :stepperStatus="stepperStatus" :isCurrent="isCurrent" :collapsed="collapsed" />
    </div>
    <span class="vertical-stop__label" :class="{ 'vertical-stop__label--selected': isCurrent}">
      <span v-html="sanitize(step.label)"></span> <AnimatedSpinner v-if="showSaveSpinner"/>
    </span>
  </div>
</template>

<script>
import verticalStepperItemMixin from '@/components/vertical-stepper/vertical-stepper-item-mixin';

import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue'
import VerticalStepIcon from '@/components/vertical-stepper/VerticalStepIcon.vue'
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import {sanitize} from '@/helpers/string-helper.js';

export default {
  mixins: [verticalStepperItemMixin, InteractiveHelpCommonsMixin],
  props: {
    step: {
    },
    showSaveSpinner: {
      type: Boolean,
      default: false,
    },
    isCurrent: {
      type: Boolean,
      default: false,
    },
    stepType: {
      type: String,
      default: 'step',
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    stepperStatus() {
      if(this.stepType === 'tab') {
        if (this.step.warning) {
          return 'warning'
        }

        return 'default'
      }
      
      if (this.step.warning) {
        return 'warning'
      } else if (this.step.success) {
        return 'success'
      } else {
        return 'default'
      }
    },
  },
    methods: {
        sanitize(htmlString) {
            return sanitize(htmlString);
        },
    },
  components: {
    AnimatedSpinner,
    VerticalStepIcon
  }
}
</script>

<style src='../vertical-stepper/vertical-stepper.scss' lang='scss' scoped></style>
