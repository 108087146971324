var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseDropdownMenu", {
    staticStyle: { display: "inline-block" },
    attrs: {
      containerClass: "menu-structure__dropdown-menu",
      placement: "bottom-left"
    },
    on: { onShow: _vm.onShow, onHide: _vm.onHide },
    scopedSlots: _vm._u(
      [
        {
          key: "hook-target",
          fn: function() {
            return [_vm._t("default")]
          },
          proxy: true
        },
        {
          key: "default",
          fn: function() {
            return [
              _c(
                "div",
                { attrs: { "data-dropdown-not-close": "" } },
                [
                  _c("InputField", {
                    ref: "filterTextEl",
                    staticClass: "pb-1",
                    attrs: { placeholder: "Zur Suche Text eingeben" },
                    model: {
                      value: _vm.filterText,
                      callback: function($$v) {
                        _vm.filterText = $$v
                      },
                      expression: "filterText"
                    }
                  }),
                  _vm.parent
                    ? _c("ComboBox", {
                        attrs: { values: _vm.parents, placeholder: "Alle" },
                        model: {
                          value: _vm.filterParent,
                          callback: function($$v) {
                            _vm.filterParent = $$v
                          },
                          expression: "filterParent"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm.showList
                ? _c(
                    "div",
                    {
                      staticClass:
                        "menu-structure__dropdown-menu__container my-2"
                    },
                    [
                      _vm.menusGroupedKeysSorted.length
                        ? _vm._l(_vm.menusGroupedKeysSorted, function(key) {
                            return _c("div", { key: key }, [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex justify-content-between"
                                },
                                [
                                  _c("span", {
                                    staticClass: "text-small text-bold",
                                    domProps: {
                                      innerHTML: _vm._s(_vm.$sanitize(key))
                                    }
                                  }),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn-clear clickable mx-2",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function($event) {
                                          return _vm.addMenus(
                                            _vm.menusGrouped[key]
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("+ alle")]
                                  )
                                ]
                              ),
                              _vm.menusGrouped[key].length > 0
                                ? _c(
                                    "ul",
                                    { staticClass: "list my-1" },
                                    _vm._l(_vm.menusGrouped[key], function(
                                      menu,
                                      index
                                    ) {
                                      return _c(
                                        "li",
                                        {
                                          key: index,
                                          staticClass:
                                            "menu-structure__dropdown-menu__item clickable",
                                          on: {
                                            click: function($event) {
                                              return _vm.addMenus([menu])
                                            }
                                          }
                                        },
                                        [
                                          menu.component
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "menu-structure__dropdown-menu__item--icon"
                                                },
                                                [
                                                  _c(
                                                    menu.component,
                                                    _vm._b(
                                                      { tag: "component" },
                                                      "component",
                                                      menu.componentProps,
                                                      false
                                                    )
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "menu-structure__dropdown-menu__item--content"
                                            },
                                            [
                                              _c("span", {
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.$sanitize(menu.label)
                                                  )
                                                }
                                              })
                                            ]
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                : _vm._e()
                            ])
                          })
                        : _c("NoData", { attrs: { noIcon: "" } })
                    ],
                    2
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "d-flex justify-content-end" },
                [
                  _vm.parent
                    ? _c(
                        "BaseButton",
                        {
                          staticClass: "m-0",
                          on: {
                            click: function($event) {
                              return _vm.$emit("addCustom", {
                                parent: _vm.parent
                              })
                            }
                          }
                        },
                        [_vm._v("neues Menü hinzufügen")]
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }