<template>
  <BaseButton
    :tid="tid" 
    :isSecondary="!isMainAction"
    :disabled="isDisabled || isLoading"
    @click="onClick"
    :class="{clickable: !isDisabled && !isLoading, disabled: isDisabled}">
    <component v-if="iconComponent" :is="iconComponent" :size="size"/>
    <AnimatedSpinner v-if="isLoading" :size="size"/>
    {{ responsiveLabel }}
  </BaseButton>
</template>
<script>
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';
import BaseButton from '@/components/core/BaseButton.vue'
import headerActionsMixin from './header-actions-mixin.js'

export default {
  mixins: [headerActionsMixin],
  components: {
    AnimatedSpinner,
    BaseButton,
  },
  props: {
    loading: {
      type: Function,
      default: null,
    },
  },
  computed: {
    isLoading() {
      return this.loading && this.loading(this.actionKey);
    },
  },
}
</script>
<style scoped>
.disabled {
  opacity: 0.5;
}
</style>
