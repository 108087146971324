<template>
  <div class="doughnut-progress__container">
    <v-chart class="chart" :option="option" :theme="themeKey" autoresize />
  </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  MarkPointComponent,
} from "echarts/components";
import VChart from "vue-echarts";
import echartsMixin from '../charts/echarts/echarts-mixin';

use([
  CanvasRenderer,
  PieChart,
  MarkPointComponent
]);

export default {
  name: 'DoughnutProgress',
  mixins: [echartsMixin],
  components: {
    VChart
  },
  props: {
    percentage: {
      type: Number,
      default: 0,
    },
    labelProgress: {
      type: String,
      default: ''
    }
  },
  computed: {
    checkedChartData() {
      const checkedChartData = []
      checkedChartData.push({ value: Math.abs(this.percentage)})
      checkedChartData.push({ value: 100 - Math.abs(this.percentage)})

      return checkedChartData
    },
    option() {
      return {
        color: ['#28a745', '#d9d9d9'],
        title: {
          show: false,
        },
        tooltip: {
          show: false,
        },
        legend: {
          show: false,
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['80%', '100%'],
            data: this.checkedChartData,
            label: {
              show: false,
            },
            markPoint: {
              tooltip: { show: false },
              label: {
                color: document.documentElement.style.getPropertyValue('--color-text') || '#000000',
                show: true,
                fontSize: 14,
              },
              data: [{
                name: this.labelProgress,
                value: this.labelProgress,
                symbol: 'circle',
                itemStyle: { color: 'transparent' },
                x: '50%',
                y: '50%',
              }],
            }
          }
        ]
      }
    },
  },
};
</script>

<style scoped>
.doughnut-progress__container {
  height: 180px;
  width: 180px;
}
</style>