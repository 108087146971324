<template>
  <div>
     <Facsimile suffix="STEMPEL" />
        <Facsimile />
  </div>
</template>
<script>
import BROKERDATA_TYPES from "@/store/brokerData/types";
import { mapGetters } from "vuex";
import CORE_TYPES from "@/store/core/types";
import Facsimile from '@/components/brokerData/Facsimile.vue';

export default {
  data() {
    return {
      sonstiges: {},
    };
  },
  computed: {
    ...mapGetters({
      brokerData: BROKERDATA_TYPES.GETTERS.GET_BROKER_DATA,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
    }),
  },
  mounted() {
    this.getBrokerData();
  },
  components: {
    Facsimile,
  },
  methods: {
    getBrokerData() {
      this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.GET_BROKER_DATA);
    },
  },
};
</script>