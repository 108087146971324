<template>
  <div class="input-forms__container">
    <div class="input-forms__label-container">
      <div class="row mt-0">
        <div class="col">
          <div
            class="
              input-forms__label-content input-forms__label-content--bigger
            "
            v-if="title"
          >
            <ph-bank :size="16" />&nbsp;{{ title }}
          </div>
        </div>
        <div class="col-auto" v-if="config && config.buttonText">
          <BaseButton :disabled="disabled || (maxAnzahlFonds && rows.length >= maxAnzahlFonds)" @click="openFormsFinder">{{config.buttonText}}</BaseButton>
        </div>
      </div>
    </div>
    <div
      class="input-forms__input-container"
      v-if="positions && positions.length"
    >
      <Table
        :headers="headers"
        :rows="rows"
        rowId="rowId"
        hidePagination
        @action-DELETE="openModalDeleteFond"
      >
        <template v-slot:aa="row">
          <InputField
            type="percent"
            :precision="2"
            :value="row.aa"
            :placeholder="row.defaultAA"
            :id="'aa' + row.rowId"
            :disabled="disabled"
            @input="onChangeInputField($event, row, 'aa')"
          />
        </template>

        <template v-slot:betrag="row">
          <InputField
            v-if="row.betragTyp === 'einmalAnteilLast'"
            type="number"
            :precision="2"
            :value="row[row.betragTyp]"
            :disabled="disabled"
            @input="onChangeBetrag($event, row, row.betragTyp)"
          />
          <InputField
            v-else
            type="currency"
            :precision="2"
            :value="row[row.betragTyp]"
            :id="row.betragTyp + row.rowId"
            :placeholder="isSparplan ? 'mind. 25,-EUR' : 'mind. 250,-EUR'"
            :disabled="disabled"
            @input="onChangeBetrag($event, row, row.betragTyp)"
          />
        </template>

        <template v-slot:betragTyp="row">
          <ComboBox
              :value="row.betragTyp" 
              :values="betragTypValues"
              :disabled="disabled"
              @change="onChangeBetragTyp($event, row, row[row.betragTyp])"
          />
        </template>
      </Table>
      <div v-if="config.mitSummenbildung && this.positions && this.positions.length">
        <Summenbildung :antragId="antragId" :summe="summe" noContainer/>
      </div>
      <div v-if="maxAnzahlFonds && rows.length > maxAnzahlFonds" class="color-danger">
        Achtung! Es sind nur maximal {{ maxAnzahlFonds }} Wertpapiere erlaubt.
      </div>

      <WertpapierAuswahlPositionFields
        class="mt-3"
        :config="config"
        :categoryId="categoryId"
        :antragId="antragId"
        :disabled="disabled"
      />
    </div>
    <div v-else>
      <div>Wertpapierauswahl ist leer</div>
    </div>

    <DeletePositionModal
      ref="deleteModal"
      :position="positionToDelete"
      @delete="doDeletePosition()"
    ></DeletePositionModal>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ANTRAG_TYPES from "@/store/antrag/types";
import antragNavigationMixin from "@/mixins/antrag/antrag-navigation-mixin.js";
import antragMixin from "@/mixins/antrag/antrag-mixin.js";
import Table from "@/components/table2/Table.vue";
import {
  TextColumn,
  ActionColumn,
  SlotColumn,
  SimpleAction,
} from "@/components/table2/table_util.js";
import InputField from "@/components/core/forms/InputField.vue";
import WertpapierAuswahlPositionFields from "@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlPositionFields.vue";
import DeletePositionModal from "@/components/core/forms/antraege/wertpapierAuswahl/DeletePositionModal.vue";
import BaseButton from "@/components/core/BaseButton.vue";
import validator from "@/mixins/validator";
import FONDSFINDER_TYPES from "@/store/fondsfinder/types";
import { minValue, maxValue } from "@/mixins/validator/rules";
import { PhBank } from "phosphor-vue";
import Summenbildung from "@/components/antrag/Summenbildung.vue";
import ComboBox from '@/components/core/forms/ComboBox.vue';

export default {
  mixins: [antragNavigationMixin, antragMixin, validator],
  validators: {},
  components: {
    Table,
    InputField,
    WertpapierAuswahlPositionFields,
    BaseButton,
    DeletePositionModal,
    PhBank,
    Summenbildung,
    ComboBox,
  },
  props: {
    title: {
    },
    categoryId: {
    },
    antragId: {
    },
    antragTyp: {
    },
    config: {
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.configureValidators();
  },
  data() {
    return {
      positionToDelete: {},
    };
  },
  watch: {
    rows: {
      handler(newVal, oldVal) {
        if (newVal?.length !== oldVal?.length) {
          this.configureValidators()
        }
      },
    },
    positionWarnings: {
      immediate: true,
      handler(newWarnings) {
        if (newWarnings) {
          for (const warn of newWarnings) {
            if (warn.id && warn.id.includes("§§")) {
              // arr[0] = componentId, arr[1] = posGrpId or ISIN
              const arr = warn.id.split("§§");
              if (arr[0] && arr[1]) {
                const index = this.positions.findIndex(
                  (pos) => pos.posGrpId === arr[1] || pos.isin === arr[1]
                );
                if (index >= 0) {
                  this.$pushErrors(arr[0] + index, warn.message || "");
                }
              }
            }
          }
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      positionsAll: ANTRAG_TYPES.GETTERS.POSITIONS,
      positionInfo: FONDSFINDER_TYPES.GETTERS.POSITION_INFO,
      antraege: ANTRAG_TYPES.GETTERS.ANTRAEGE,
    }),
    headers() {
      return {
        lockedLeft: [
          TextColumn("isin", "ISIN"),
          TextColumn("fondsname", "Fondsname"),
        ],
        lockedRight: [
          SlotColumn("aa", "Ausgabeaufschlag", 130),
          (this.isOnlineDepot || this.isSparplan) ? SlotColumn("betrag", "Betrag", 180) : SlotColumn("betrag", "Betrag/Anteile", 180),
          ...[this.isSparplan ? [] : SlotColumn("betragTyp", "", 180)],
          ActionColumn("actions", null),
        ],
      };
    },
    rows() {
      const actions = [SimpleAction("DELETE", "PhTrash", "Löschen")];
      const rows = this.positions || [];
      return rows.map((row, index) => ({
        ...row,
        rowId: '' + index + this.categoryId,
        betragTyp: this.isSparplan ? 'planBetrag' : row.betragTyp || (this.isOnlineDepot ? row.einmalBetragUeberweisung ? 'einmalBetragUeberweisung' : 'einmalBetragLast' 
        : row.einmalAnteilLast ? 'einmalAnteilLast' : 'einmalBetragLast'),
        actions,
      }));
    },
    isOnlineDepot() {
      return !!this.config.isOnlineDepot
    },
    maxAnzahlFonds() {
      return this.config.maxAnzahlFonds
    },
    isSparplan() {
      return this.categoryId === 'SPARPLAN'
    },
    betragTypValues() {
      return this.isOnlineDepot ? [
        {
          value: "einmalBetragLast",
          label: "per Lastschrift"
        },
        {
          value: "einmalBetragUeberweisung",
          label: "per Überweisung"
        },
      ] : [
        {
          value: "einmalBetragLast",
          label: "Euro"
        },
        {
          value: "einmalAnteilLast",
          label: "Anteile"
        },
      ]
    },
    positions() {
      if (
        this.positionsAll &&
        this.antragId &&
        this.positionsAll[this.antragId]
      ) {
        const positions = this.positionsAll[this.antragId][this.categoryId] || [];
        positions.map((pos, index) =>
          Object.assign(pos, {
            index: index,
            fondsname: this.getFondsName(pos),
            lagerstelle: "FODB",
            defaultAA:
              this.positionInfo[pos.isin]?.defaultAusgabeaufschlag != null
                ? "" + this.positionInfo[pos.isin].defaultAusgabeaufschlag
                : "",
          })
        );

        return positions;
      }
    },
    positionWarnings() {
      return (
        (this.antraege &&
          this.antraege[this.antragId] &&
          this.antraege[this.antragId].warnings &&
          this.antraege[this.antragId].warnings.positionWarnings) ||
        []
      );
    },
    summe() {
      let sum = 0;

      this.rows.forEach(row => {
        sum += row.einmalBetragLast || 0
        sum += row.einmalBetragUeberweisung || 0
        sum += row.planBetrag || 0;
      })
      
      return sum
    }
  },
  methods: {
    configureValidators() {
      if (this.positions && this.positions.length) {
        const valConfig = {};
        this.positions.forEach((pos, index) => {

          if (this.isSparplan) {
            valConfig['planBetrag' + index + this.categoryId] = [minValue(25, 'Mindestplansumme 25 EUR')];
          } else {
            valConfig['einmalBetragLast' + index + this.categoryId] = [minValue(250, 'Mindestanlagesumme 250 EUR')];

            if(!this.isOnlineDepot){
              valConfig['einmalBetragUeberweisung' + index + this.categoryId] = [minValue(250, 'Mindestanlagesumme 250 EUR')];
            }
          }

          valConfig["aa" + index + this.categoryId] = [maxValue(Number(pos.defaultAA) + 0.1, "Maximal Wert ist: " + pos.defaultAA)];
        });
        this.$configureValidators(valConfig);
      }
    },
    getFondsName(pos) {
      return (
        (pos &&
          this.positionInfo &&
          this.positionInfo[pos.isin] &&
          this.positionInfo[pos.isin].wertpapiername) ||
        pos.fondsname ||
        pos.wertpapiername
      );
    },
    openFormsFinder() {
      this.goToFormsFinder(
        this.categoryId,
        this.config,
        this.suppressFilterLagerstelle
      );
    },
    commitUpdatePositionsFromEvent(positionChange, isin) {
      const payload = {
        id: this.antragId,
        categoryId: this.categoryId,
        fonds: [
          {
            ...positionChange,
            isin,
          },
        ],
      };
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_POSITIONS, payload);
    },
    onChangeInputField($event, position, field) {
      const positionChange = { [field]: $event };
      this.commitUpdatePositionsFromEvent(positionChange, position.isin);
      if (field === "monat" || field === "jahr") {
        const abDatum = position.abDatum
          ? position.abDatum.split(".")
          : ["", ""];
        if (field === "monat") {
          abDatum[0] = $event;
        } else if (field === "jahr") {
          abDatum[1] = $event;
        }
        const newDatum = { abDatum: abDatum.join(".") };
        this.commitUpdatePositionsFromEvent(newDatum, position.isin);
      }
    },
    onChangeBetrag($event, position, currentBetragTyp) {
      const positionChange = {
        einmalBetragLast: null,
        einmalBetragUeberweisung: null,
        einmalAnteilLast: null,
        betragTyp: currentBetragTyp,
        [currentBetragTyp]: $event || null,
      }
      this.commitUpdatePositionsFromEvent(positionChange, position.isin);
    },
    onChangeBetragTyp($event, position, currentBetrag) {
      const positionChange = {
        einmalBetragLast: null,
        einmalBetragUeberweisung: null,
        einmalAnteilLast: null,
        betragTyp: $event,
        [$event]: currentBetrag,
      }
      this.commitUpdatePositionsFromEvent(positionChange, position.isin);
    },
    openModalDeleteFond(fond) {
      this.positionToDelete = fond;
      this.$refs.deleteModal.open();
    },
    doDeletePosition() {
      if (!this.disabled) {
        this.deletePosition(this.antragId, this.categoryId, this.positionToDelete);
        this.positionToDelete = null;
      }
    }
  }
}
</script>