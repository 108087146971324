var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-forms__container" }, [
    _c("div", { staticClass: "input-forms__label-container" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 col-lg-8" }, [
          _vm.title
            ? _c(
                "div",
                {
                  staticClass:
                    "input-forms__label-content input-forms__label-content--bigger"
                },
                [_vm._v(" " + _vm._s(_vm.title) + " ")]
              )
            : _vm._e()
        ]),
        _c(
          "div",
          { staticClass: "col-12 col-lg-4" },
          [
            _vm.tableData.records && _vm.tableData.records.length
              ? _c(
                  "BaseButton",
                  {
                    attrs: { isSecondary: "", disabled: _vm.disabled },
                    on: {
                      click: function($event) {
                        return _vm.deleteAllPositions()
                      }
                    }
                  },
                  [_vm._v(" Alle Positionen löschen ")]
                )
              : _vm._e()
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "input-forms__input-container" }, [
      _vm.tableData && _vm.tableData.records && _vm.tableData.records.length
        ? _c("div", [
            _c(
              "div",
              [
                _c("Table", {
                  attrs: {
                    tableId: "982380e1-dfba-49a1-a32b-ad852331a768",
                    headers: _vm.headers,
                    rows: _vm.rows,
                    rowId: "index",
                    hidePagination: ""
                  },
                  on: {
                    "action-edit": function($event) {
                      return _vm.openPosition($event.index)
                    },
                    "action-delete": function($event) {
                      return _vm.deletePosition($event.index)
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "Gesellschaft",
                        fn: function(row) {
                          return [
                            _vm.getWarningMessages(row)
                              ? _c("ph-warning", {
                                  directives: [
                                    {
                                      name: "fc-tooltip",
                                      rawName: "v-fc-tooltip",
                                      value: _vm.getWarningMessages(row),
                                      expression: "getWarningMessages(row)"
                                    }
                                  ],
                                  staticClass: "color-danger",
                                  attrs: { size: 16 }
                                })
                              : _vm._e(),
                            _c("label", [_vm._v(_vm._s(row.Gesellschaft))])
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    116737217
                  )
                })
              ],
              1
            )
          ])
        : _c("div", [_c("div", [_vm._v("Depotauswahl ist leer")])])
    ]),
    _c("hr"),
    _vm._m(0),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col" },
        [
          _c("ComboBox", {
            attrs: {
              values: _vm.config.depots,
              firstEmpty: "",
              disabled: _vm.disabled
            },
            model: {
              value: _vm.depot,
              callback: function($$v) {
                _vm.depot = $$v
              },
              expression: "depot"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col" },
        [
          _c(
            "BaseButton",
            {
              attrs: { disabled: !_vm.depot || _vm.disabled },
              on: {
                click: function($event) {
                  return _vm.openPositionForDepot()
                }
              }
            },
            [_vm._v(_vm._s(_vm.btnTxtFormularDepot))]
          )
        ],
        1
      )
    ]),
    !_vm.config.isIntern
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _vm._v(
              " Übertrag von Gesellschaft (falls das Depot nicht aufgeführt wird) "
            )
          ])
        ])
      : _vm._e(),
    !_vm.config.isIntern
      ? _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col" },
            [
              _c("ComboBox", {
                attrs: {
                  disabled: _vm.disabled,
                  values: _vm.config.gesellschaften,
                  firstEmpty: ""
                },
                model: {
                  value: _vm.gesellschaft,
                  callback: function($$v) {
                    _vm.gesellschaft = $$v
                  },
                  expression: "gesellschaft"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col" },
            [
              _c(
                "BaseButton",
                {
                  attrs: { disabled: !_vm.gesellschaft || _vm.disabled },
                  on: {
                    click: function($event) {
                      return _vm.openPositionForGesellschaft()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.btnTxtFormularGesellschaft))]
              )
            ],
            1
          )
        ])
      : _vm._e(),
    !_vm.config.isIntern
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _vm._v(
              " Freies Übertragsformular (Hier können Sie alle Daten selbst eingeben.) "
            )
          ])
        ])
      : _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _vm._v(" Neues Formular für FFB erstellen ")
          ])
        ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }),
      _c(
        "div",
        { staticClass: "col" },
        [
          _c(
            "BaseButton",
            {
              attrs: { disabled: _vm.disabled },
              on: {
                click: function($event) {
                  return _vm.openNewPosition({ isManuell: true })
                }
              }
            },
            [_vm._v(_vm._s(_vm.btnTxtFormularFree))]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [_vm._v(" Übertrag eines Depots ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }