<template>
  <div>
    <BaseModal 
      ref="editModal"
      modalTitle="Kategorie"
      @onConfirmButton="submit()"
      @close="close()"
    >
      
      <InputField type="currency" :precision="2" label="Budget:" v-model="budget"/>
      
    </BaseModal>
  </div>
</template>

<script>
import MULTIBANKING_TYPES from '@/store/multiBanking/types'
import LOG_TYPES from '@/store/log/types';
import InputField from '../core/forms/InputField'
import BaseModal from '@/components/core/BaseModal.vue'

export default {
  props: {
    category: {
      default: null,
    },
  },

  components: {
    BaseModal,
    InputField,
  },

  data: function () {
    return {
      displayName: this.category.displayName || '',
      budget: this.category.budget || '',
    }
  },

  methods: {
    updateBudget(event) {
      let b = event.target.value
      try {
        b = Math.max(Math.round(parseFloat(b) * 100) / 100, 0)
      } catch (e) {
        b = 0
      }
      if (b > 0)
        this.budget = b
      else
        this.budget = ''
      this.$store.dispatch(LOG_TYPES.ACTIONS.LOG, this.budget);
      event.target.value = this.budget
    },
    submit() {
      let b = 0
      try {
        b = Math.max(Math.round(parseFloat(this.budget) * 100) / 100, 0)
      } catch (e) {
        b = 0
      }
      this.$store.dispatch(MULTIBANKING_TYPES.ACTIONS.UPDATE_CATEGORY, {
          categoryKey: this.category.category,
          displayName: this.displayName,
          budget: b,
        })
      this.close()
    },
    close() {
      this.$emit("close")
    },
  },
  mounted() {
    this.$refs.editModal.open()
  }
}
</script>

<style scoped>

</style>