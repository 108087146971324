import DEPOTPOSITIONS_TYPES from '@/store/depotpositions/types';
import KENNZAHLEN_TYPES from './types';


export default {

  [KENNZAHLEN_TYPES.GETTERS.KENNZAHLEN_DATA](state, getters) {
    const depotId = getters[DEPOTPOSITIONS_TYPES.GETTERS.DEPOT_ID];
    const kennzahlenData = depotId in state.kennzahlenData && state.kennzahlenData[depotId] || {};
    return { ...kennzahlenData };
  },

}
