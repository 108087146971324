<template>
  <div class="config-edit-param-label__container">
    <div class="config-edit-param-label__content">
      <HtmlEditor
        isSimplified
        isSmallTextarea
        :value="value"
        @input="onUpdate($event)"
      />
    </div>

    <div class="config-edit-param-label--actions">
      <BaseButton :disabled="disabledConfirm" @click="onConfirm()">Speichern</BaseButton>
      <BaseButton isSecondary @click="onCancel()">Abbrechen</BaseButton>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/core/BaseButton.vue';
import HtmlEditor from '@/components/html-editor/HtmlEditor.vue';

export const LABEL_UNTITLED = 'Untitled';

export default {
  props: {
    param: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BaseButton,
    HtmlEditor,
  },
  data() {
    return {
      value: '',
      changedValue: '',
      hasChanged: false,
    };
  },
  computed: {
    disabledConfirm() {
      return !this.hasChanged || !this.changedValue?.trim() || /<p>\s*<\/p>/gi.test(this.changedValue);
    },
    isSelectAllOnInit() {
      return this.value === LABEL_UNTITLED;
    },
  },
  methods: {
    onUpdate(value) {
      this.$set(this, 'hasChanged', true);
      this.$set(this, 'changedValue', value);
    },
    onConfirm() {
      this.$emit('confirm', this.changedValue);
    },
    onCancel() {
      this.$emit('cancel');
    },
  },
  mounted() {
    this.$set(this, 'value', this.param?.label ||'');
  },
}
</script>

<style lang="scss" scoped>
.config-edit-param-label__container,
.config-edit-param-label--editor {
  width: 100%;
}

.config-edit-param-label--actions {
  margin: 0 12px 12px;
}

.config-edit-param-label__content {
  margin: 12px;
}
</style>
