import FILEUPLOAD_TYPES from './types';
import Vue from 'vue'
import { getInitialState } from './index'

export default {
  [FILEUPLOAD_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },
  [FILEUPLOAD_TYPES.MUTATIONS.UPLOAD_PDF](state, payload) {
    state.pdfUploadStatus = "Bitte warten Sie während die Seiten analysiert werden."
  },
  [FILEUPLOAD_TYPES.MUTATIONS.SEND_RUN](state, payload) {
    state.SEND_RUN = payload
  },
  async [FILEUPLOAD_TYPES.MUTATIONS.UPLOAD_PDF_SUCCESS](state, payload) {
    if (payload) {
      state.pdfUploads = []
      state.pdfUploads.push(payload);
      //Vue.set(state, 'pdfUploads', [[...state.pdfUploads], payload ])
    } else {
      state.pdfUploads = []
    }
    state.collatedPdfs = [];
    state.pdfUploadStatus = null
    state.pdfUploadDeckblatt = false
  },
  [FILEUPLOAD_TYPES.MUTATIONS.REMOVE_PDF](state, {eingangId, keepAsCollated}) {
    if (eingangId && state.pdfUploads?.length) {
      const index = state.pdfUploads.findIndex(pdf => pdf.id === eingangId);
      if (index >= 0) {
          const removed = state.pdfUploads.splice(index, 1);
          if (keepAsCollated)
            state.collatedPdfs = removed;
      }
    }
    state.pdfUploadStatus = null
    state.pdfUploadDeckblatt = false
  },
  [FILEUPLOAD_TYPES.MUTATIONS.UPDATE_PDF_DECKBLATT](state, payload) {
    state.pdfUploadDeckblatt = true
  },
  [FILEUPLOAD_TYPES.MUTATIONS.RESULT_TABLE](state, payload) {
    state.RESULT_TABLE = payload;
  },
  [FILEUPLOAD_TYPES.MUTATIONS.UPDATE_PDF_PAGE](state, payload) {
    const document = state.pdfUploads.find(doc => doc.id == payload.id)
    if (document) {
      payload.barkodePages.forEach(updatedPage => {
        const pageIndex = document.barkodePages.findIndex(page => page.rownum == updatedPage.rownum)
        document.barkodePages.splice(pageIndex, 1, updatedPage)
      })
    }
  },

  [FILEUPLOAD_TYPES.MUTATIONS.GET_DECKBLATT_COMBOS_SUCCESS](state, payload) {
    Vue.set(state, 'deckblattCombos', { 
      ...state.deckblattCombos,
      ...payload || {},
    });
  },

  [FILEUPLOAD_TYPES.MUTATIONS.GET_COMBO_VALUES_SUCCESS](state, payload) {
    Vue.set(state, 'comboValues', { 
      ...state.comboValues,
      ...payload || {},
    });
  },

}