
/**
 * Linear interpolation functions
 */


export function lerp(x, y, a) {
  return x * (1 - a) + y * a
}

export function clamp(a, min = 0, max = 1) {
  return Math.min(max, Math.max(min, a));
}

export function invlerp(x, y, a) {
  return clamp((a - x) / (y - x));
}

export function range(x1, y1, x2, y2, a) {
  return lerp(x2, y2, invlerp(x1, y1, a));
}