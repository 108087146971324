<template>
  <div>
    <PageHeaderTitleNavigation 
      :actions="headerActions" 
      :noPrimaryAction="!hasAddDocumentHeaderActions"
      @action="executeHeaderAction($event.value)"
    >
      <template #title>
        <span class="clickable" @click="$emit('selectNavigationNode', navigationArray[0])">
          Dokumentenarchiv
        </span>
      </template>
      <template v-if="navigationArray.length > 1 && !$isSmallScreen" #subtitle>
        <DokumenteBreadcrumb
          class="explorer__navigation--breadcrumb"
          @select-navigation-node="$emit('selectNavigationNode', $event)"
          :navigationArray="navigationArray"
          ignoreFirst
        ></DokumenteBreadcrumb>
      </template>
    </PageHeaderTitleNavigation>

    <BaseModal
      ref="confirmationModal"
      modalTitle="Bestätigung"
      labelButtonConfirm="Ok"
      :showCancelButton="true"
      @onConfirmButton="onConfirmHeaderActionModal()"
    >
      <label>{{ confirmationMessage }}</label>
    </BaseModal>

    <BaseModal ref="sendEmailModal" modalTitle="Dokumente versenden" :showDefaultButtons="false">
      An wen möchten Sie die ausgewählten Dokumente versenden?
      <template #footer>
        <BaseButton isSecondary @click="closeSendEmailModal()">Abbrechen</BaseButton>
        <BaseButton @click="confirmSendEmail();">An Kunden</BaseButton>
        <BaseButton @click="confirmSendEmail(true);">An Gesellschaft</BaseButton>
      </template>
    </BaseModal> 

    <EmailVersand/>

    <DocumentsUploadModal
      v-if="files && currentAdditionalInputs"
      :files="files"
      @close="files=null"
      :additionalInputs="currentAdditionalInputs"
      :parentId="nodeId"
      :versicherungId="versicherungId"
      :schadenId="schadenId"
      :showOnlySchadenDocs="showOnlySchadenDocs"
      :maxFileSizeMB="maxFileSizeMB"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DOKUMENTENARCHIV_TYPES from '@/store/dokumentenarchiv/types';
import CORE_TYPES from '@/store/core/types';

import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import DokumenteBreadcrumb from "@/components/core/DokumenteBreadcrumb.vue";
import BaseModal from '@/components/core/BaseModal.vue';
import BaseButton from "@/components/core/BaseButton.vue";
import EmailVersand from "@/components/core/EmailVersand.vue";

import DocumentsUploadModal from '@/components/fileUpload/DocumentsUploadModal.vue';

import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

import documentsHeaderActionsMixin from '@/components/documents/documents-header-actions-mixin';

export default {
  mixins: [documentsHeaderActionsMixin],
  props: {
    navigationArray: {
      type: Array,
      default: () => [],
    },
    // expected to be BaseModalSimpleActions
    additionalActions: {
      type: Array,
      default: () => [],
    },
    maxFileSizeMB: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      selectedNode: DOKUMENTENARCHIV_TYPES.GETTERS.DOCUMENTS,
      isCustomerOnly: CORE_TYPES.GETTERS.IS_CUSTOMER_ONLY,
    }),
    headerActions() {
      if (this.$isSmallScreen) {
        return []
      }

      const headerActions = ([...this.selectedNode?.headerActions || []])
        .filter(a => !this.isCustomerOnly || a.key !== 'MARKIERTE_MAIL_VERSCHICKEN')
        .sort((a, b) => (a.index > b.index) ? -1 : 1);

      // select an add document header action to be the first action position
      let addDocumentHeaderAction = headerActions.find(action => action.key === 'DATEI_HOCHLADEN');
      if(!addDocumentHeaderAction) {
        addDocumentHeaderAction = headerActions.find(action => action.key === 'DATEI_HINZUFUGEN');
      }

      // map BaseModalSimpleActions to PageHeaderSimpleActions
      return this.additionalActions.map(action => 
        PageHeaderSimpleAction(action.actionKey, action.label)
          .withDisabled(() => this.isLoadingNode || (action.disabled && action.disabled(action.actionKey)))
          .withVisible(action.visible)
          .withValue({...action, key: action.actionKey})
      ).concat([
        ...(addDocumentHeaderAction ? [addDocumentHeaderAction] : []),
        ...headerActions.filter(action => action.key !== addDocumentHeaderAction?.key),
      ].map((action) => {
        return PageHeaderSimpleAction(action.key, action.label)
          .withDisabled(() => this.isLoadingNode || this.buttonDisabled(action.key))
          .withValue(action);
      }));
    },
    hasAddDocumentHeaderActions() {
      const headerActions = ([...this.selectedNode?.headerActions || []]);
      return this.additionalActions.length || headerActions.some(action => ['DATEI_HOCHLADEN', 'DATEI_HINZUFUGEN'].indexOf(action.key) >= 0);
    },
  },
  components: {
    PageHeaderTitleNavigation,
    DokumenteBreadcrumb,
    BaseModal,
    DocumentsUploadModal,
    BaseButton,
    EmailVersand,
  },
}
</script>
