var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._v(" Übersicht externe Konten "),
      _vm.showWarningAuthNeeded
        ? _c("ph-warning", { attrs: { size: 24 } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }