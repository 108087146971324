var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "box__title" }, [_vm._v("Bankverbindung")]),
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("InputField", {
          attrs: {
            label: "Kontoinhaber",
            isComponentHalfSize: true,
            disabled: !_vm.brokerData.isKontoEditable
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.konto.kontoinhaber,
            callback: function($$v) {
              _vm.$set(_vm.brokerData.konto, "kontoinhaber", $$v)
            },
            expression: "brokerData.konto.kontoinhaber"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("InputField", {
          attrs: {
            label: "IBAN",
            isComponentHalfSize: true,
            disabled: !_vm.brokerData.isKontoEditable
          },
          on: {
            input: function($event) {
              return _vm.calulateBicAndBank($event, "0")
            },
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.konto.iban,
            callback: function($$v) {
              _vm.$set(_vm.brokerData.konto, "iban", $$v)
            },
            expression: "brokerData.konto.iban"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("InputField", {
          attrs: {
            label: "SWIFT-BIC",
            isComponentHalfSize: true,
            disabled: !_vm.brokerData.isKontoEditable
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.konto.bic,
            callback: function($$v) {
              _vm.$set(_vm.brokerData.konto, "bic", $$v)
            },
            expression: "brokerData.konto.bic"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("InputField", {
          attrs: {
            label: "Bank",
            isComponentHalfSize: true,
            disabled: !_vm.brokerData.isKontoEditable
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.konto.bank,
            callback: function($$v) {
              _vm.$set(_vm.brokerData.konto, "bank", $$v)
            },
            expression: "brokerData.konto.bank"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("InputField", {
          attrs: {
            label: "Kontonummer",
            isComponentHalfSize: true,
            disabled: !_vm.brokerData.isKontoEditable
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.konto.kontonummer,
            callback: function($$v) {
              _vm.$set(_vm.brokerData.konto, "kontonummer", $$v)
            },
            expression: "brokerData.konto.kontonummer"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("InputField", {
          attrs: {
            label: "Bankleitzahl",
            isComponentHalfSize: true,
            disabled: !_vm.brokerData.isKontoEditable
          },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.konto.bankleitzahl,
            callback: function($$v) {
              _vm.$set(_vm.brokerData.konto, "bankleitzahl", $$v)
            },
            expression: "brokerData.konto.bankleitzahl"
          }
        })
      ],
      1
    ),
    _c("div", { staticClass: "box__container" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12 col-sm-6 form-control" },
          [
            _c("InputField", {
              staticClass: "align-end",
              attrs: { label: "Gläubiger-ID" },
              on: {
                change: function($event) {
                  return _vm.addBrokerDataEdited("brokerData")
                }
              },
              model: {
                value: _vm.brokerData.konto.glaeubigerdId,
                callback: function($$v) {
                  _vm.$set(_vm.brokerData.konto, "glaeubigerdId", $$v)
                },
                expression: "brokerData.konto.glaeubigerdId"
              }
            }),
            _c(
              "a",
              {
                staticClass: "align-end",
                staticStyle: { "flex-grow": "0" },
                attrs: {
                  href: "https://www.glaeubiger-id.bundesbank.de",
                  target: "_newtab"
                }
              },
              [_c("BaseButton", [_vm._v("beantragen")])],
              1
            )
          ],
          1
        )
      ])
    ]),
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("InputField", {
          attrs: { label: "Gut beraten ID", isComponentHalfSize: true },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.konto.gutBeratenId,
            callback: function($$v) {
              _vm.$set(_vm.brokerData.konto, "gutBeratenId", $$v)
            },
            expression: "brokerData.konto.gutBeratenId"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("InputField", {
          attrs: { label: "Bemerkung", isComponentHalfSize: true },
          on: {
            change: function($event) {
              return _vm.addBrokerDataEdited("brokerData")
            }
          },
          model: {
            value: _vm.brokerData.konto.bankBemerkung,
            callback: function($$v) {
              _vm.$set(_vm.brokerData.konto, "bankBemerkung", $$v)
            },
            expression: "brokerData.konto.bankBemerkung"
          }
        })
      ],
      1
    ),
    _vm.brokerData.hatVersAccess
      ? _c("div", { staticClass: "box__title" }, [
          _vm._v(
            " Bankverbindung für Versicherung (falls abweichend vom Standard) "
          )
        ])
      : _vm._e(),
    _vm.brokerData.hatVersAccess
      ? _c(
          "div",
          { staticClass: "box__container" },
          [
            _vm.brokerData.hatVersAccess
              ? _c("InputField", {
                  attrs: {
                    label: "Kontoinhaber",
                    isComponentHalfSize: true,
                    disabled: !_vm.brokerData.isKontoEditable
                  },
                  on: {
                    change: function($event) {
                      return _vm.addBrokerDataEdited("brokerData")
                    }
                  },
                  model: {
                    value: _vm.brokerData.konto.versKontoinhaber,
                    callback: function($$v) {
                      _vm.$set(_vm.brokerData.konto, "versKontoinhaber", $$v)
                    },
                    expression: "brokerData.konto.versKontoinhaber"
                  }
                })
              : _vm._e()
          ],
          1
        )
      : _vm._e(),
    _vm.brokerData.hatVersAccess
      ? _c(
          "div",
          { staticClass: "box__container" },
          [
            _vm.brokerData.hatVersAccess
              ? _c("InputField", {
                  attrs: {
                    label: "IBAN",
                    isComponentHalfSize: true,
                    disabled: !_vm.brokerData.isKontoEditable
                  },
                  on: {
                    input: function($event) {
                      return _vm.calulateBicAndBank($event, "1")
                    },
                    change: function($event) {
                      return _vm.addBrokerDataEdited("brokerData")
                    }
                  },
                  model: {
                    value: _vm.brokerData.konto.versIban,
                    callback: function($$v) {
                      _vm.$set(_vm.brokerData.konto, "versIban", $$v)
                    },
                    expression: "brokerData.konto.versIban"
                  }
                })
              : _vm._e()
          ],
          1
        )
      : _vm._e(),
    _vm.brokerData.hatVersAccess
      ? _c(
          "div",
          { staticClass: "box__container" },
          [
            _vm.brokerData.hatVersAccess
              ? _c("InputField", {
                  attrs: {
                    label: "Kontonummer",
                    isComponentHalfSize: true,
                    disabled: !_vm.brokerData.isKontoEditable
                  },
                  on: {
                    change: function($event) {
                      return _vm.addBrokerDataEdited("brokerData")
                    }
                  },
                  model: {
                    value: _vm.brokerData.konto.versKontonummer,
                    callback: function($$v) {
                      _vm.$set(_vm.brokerData.konto, "versKontonummer", $$v)
                    },
                    expression: "brokerData.konto.versKontonummer"
                  }
                })
              : _vm._e()
          ],
          1
        )
      : _vm._e(),
    _vm.brokerData.hatVersAccess
      ? _c(
          "div",
          { staticClass: "box__container" },
          [
            _vm.brokerData.hatVersAccess
              ? _c("InputField", {
                  attrs: {
                    label: "Bank",
                    isComponentHalfSize: true,
                    disabled: !_vm.brokerData.isKontoEditable
                  },
                  on: {
                    change: function($event) {
                      return _vm.addBrokerDataEdited("brokerData")
                    }
                  },
                  model: {
                    value: _vm.brokerData.konto.versBank,
                    callback: function($$v) {
                      _vm.$set(_vm.brokerData.konto, "versBank", $$v)
                    },
                    expression: "brokerData.konto.versBank"
                  }
                })
              : _vm._e()
          ],
          1
        )
      : _vm._e(),
    _vm.brokerData.hatVersAccess
      ? _c(
          "div",
          { staticClass: "box__container" },
          [
            _vm.brokerData.hatVersAccess
              ? _c("InputField", {
                  attrs: {
                    label: "Bankleitzahl",
                    isComponentHalfSize: true,
                    disabled: !_vm.brokerData.isKontoEditable
                  },
                  on: {
                    change: function($event) {
                      return _vm.addBrokerDataEdited("brokerData")
                    }
                  },
                  model: {
                    value: _vm.brokerData.konto.versBankleitzahl,
                    callback: function($$v) {
                      _vm.$set(_vm.brokerData.konto, "versBankleitzahl", $$v)
                    },
                    expression: "brokerData.konto.versBankleitzahl"
                  }
                })
              : _vm._e()
          ],
          1
        )
      : _vm._e(),
    _vm.brokerData.hatVersAccess
      ? _c(
          "div",
          { staticClass: "box__container" },
          [
            _vm.brokerData.hatVersAccess
              ? _c("InputField", {
                  attrs: {
                    label: "SWIFT-BIC",
                    isComponentHalfSize: true,
                    disabled: !_vm.brokerData.isKontoEditable
                  },
                  on: {
                    change: function($event) {
                      return _vm.addBrokerDataEdited("brokerData")
                    }
                  },
                  model: {
                    value: _vm.brokerData.konto.versBic,
                    callback: function($$v) {
                      _vm.$set(_vm.brokerData.konto, "versBic", $$v)
                    },
                    expression: "brokerData.konto.versBic"
                  }
                })
              : _vm._e()
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }