var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.details, function(detail, index) {
        return _c(
          "BoxContainer",
          { key: detail.label + index, attrs: { title: detail.label } },
          [
            _vm._l(detail.records, function(record, index) {
              return _c(
                "dl",
                {
                  key: "record_" + detail.label + index,
                  staticClass: "key-value--item"
                },
                [
                  _c("dt", { staticClass: "value--item-label" }, [
                    _vm._v(_vm._s(record.type + ":"))
                  ]),
                  record.type == "FWW FundStars"
                    ? _c(
                        "div",
                        { staticClass: "value--item-value" },
                        _vm._l(_vm.getStars(record.value), function(
                          star,
                          index
                        ) {
                          return _c("PhStar", {
                            key: "star-" + index,
                            attrs: { size: 16, weight: "fill" }
                          })
                        }),
                        1
                      )
                    : _c("dd", { staticClass: "value--item-value" }, [
                        _vm._v(_vm._s(record.value))
                      ])
                ]
              )
            }),
            detail.footerLabels
              ? _vm._l(detail.footerLabels, function(text, idx) {
                  return _c("div", { key: idx, staticClass: "foot-comment" }, [
                    _vm._v(_vm._s(text))
                  ])
                })
              : _vm._e()
          ],
          2
        )
      }),
      _c("BoxContainer", [
        _c("div", { staticClass: "row" }, [
          _c("div", {
            domProps: {
              innerHTML: _vm._s(_vm.sanitize(_vm.allgemeinDescription))
            }
          })
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }