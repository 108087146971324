<template>
  <div class="box__container-wrap">
    <Transactions v-bind="$attrs" class="box__container-rows" @loading="loading = $event" />

    <div v-if="!loading && !$isSmallScreen" class="bottom-row text-right mt-3">
      <router-link to="/customer/eingegangene-transaktionen">weitere...</router-link>
    </div>
  </div>
</template>

<script>
import Transactions from './Transactions.vue';


export default {
  data() {
    return {
      loading: false,
    };
  },
  components: {
    Transactions,
  },
}
</script>
