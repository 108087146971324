import INFOFILME_TYPES from './types';

export default {
  [INFOFILME_TYPES.GETTERS.GET_INFOFILME](state) {
    return state.infofilme;
  },
  [INFOFILME_TYPES.GETTERS.GET_FILTER](state) {
    const filter = JSON.parse(JSON.stringify(state.filter))
    if (filter.von != undefined && filter.bis != undefined) {
      filter.zeitraum = [{key:'min', value:filter.von}, {key:'max', value:filter.bis}]
      delete filter.von;
      delete filter.bis;
    }
    return filter;
  },
}