var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("div", { staticClass: "box__title" }, [
          _vm._v("Einstellungen für Kauf/Nachkauf/Verkauf")
        ]),
        _c("ComboBox", {
          attrs: {
            label: "Ansprechpartner",
            values: _vm.comboboxValues
              ? _vm.comboboxValues.INPUT_HELLOBANK_ANSPRECHPARTNER_COMBO
              : [],
            disabled: !_vm.hasKaufVerkauf
          },
          on: {
            change: function($event) {
              return _vm.updateStoreData({
                componentId: "INPUT_HELLOBANK_ANSPRECHPARTNER_COMBO",
                value: $event
              })
            }
          },
          model: {
            value: _vm.INPUT_HELLOBANK_ANSPRECHPARTNER_COMBO,
            callback: function($$v) {
              _vm.INPUT_HELLOBANK_ANSPRECHPARTNER_COMBO = $$v
            },
            expression: "INPUT_HELLOBANK_ANSPRECHPARTNER_COMBO"
          }
        }),
        _vm.data &&
        _vm.data.INPUT_HELLOBANK_ANSPRECHPARTNER_COMBO !== _vm.FREIE_EINGABE
          ? _c(
              "div",
              [
                _c("FormLabel", {
                  attrs: {
                    label:
                      "Für hier aufgeführte Ansprechpartner muss eine Telefonnummer hinterlegt sein."
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm.data &&
        _vm.data.INPUT_HELLOBANK_ANSPRECHPARTNER_COMBO === _vm.FREIE_EINGABE
          ? _c(
              "div",
              [
                _c("InputField", {
                  attrs: { label: "Name", disabled: !_vm.hasKaufVerkauf },
                  on: {
                    change: function($event) {
                      return _vm.updateStoreData({
                        componentId: "INPUT_HELLOBANK_ANSPRECHPARTNER_NAME",
                        value: $event
                      })
                    }
                  },
                  model: {
                    value: _vm.INPUT_HELLOBANK_ANSPRECHPARTNER_NAME,
                    callback: function($$v) {
                      _vm.INPUT_HELLOBANK_ANSPRECHPARTNER_NAME = $$v
                    },
                    expression: "INPUT_HELLOBANK_ANSPRECHPARTNER_NAME"
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm.data &&
        _vm.data.INPUT_HELLOBANK_ANSPRECHPARTNER_COMBO === _vm.FREIE_EINGABE
          ? _c(
              "div",
              [
                _c("InputField", {
                  attrs: {
                    label: "Telefon",
                    disabled: !_vm.hasKaufVerkauf,
                    inputmode: "tel"
                  },
                  on: {
                    change: function($event) {
                      return _vm.updateStoreData({
                        componentId: "INPUT_HELLOBANK_ANSPRECHPARTNER_TEL",
                        value: $event
                      })
                    }
                  },
                  model: {
                    value: _vm.INPUT_HELLOBANK_ANSPRECHPARTNER_TEL,
                    callback: function($$v) {
                      _vm.INPUT_HELLOBANK_ANSPRECHPARTNER_TEL = $$v
                    },
                    expression: "INPUT_HELLOBANK_ANSPRECHPARTNER_TEL"
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _c("InputToggleSwitch", {
          attrs: {
            label:
              "Überweisung des gesamten Verkaufserlöses an mein Referenzkonto",
            inLineLabel: "",
            disabled: !_vm.hasKaufVerkauf
          },
          on: {
            input: function($event) {
              return _vm.updateStoreData({
                componentId: "INPUT_HELLOBANK_REFERENZKONTO",
                value: $event
              })
            }
          },
          model: {
            value: _vm.INPUT_HELLOBANK_REFERENZKONTO,
            callback: function($$v) {
              _vm.INPUT_HELLOBANK_REFERENZKONTO = $$v
            },
            expression: "INPUT_HELLOBANK_REFERENZKONTO"
          }
        }),
        _vm.data && _vm.data.INPUT_HELLOBANK_REFERENZKONTO === "1"
          ? _c(
              "div",
              [
                _c("InputField", {
                  attrs: {
                    label: "Verkaufserlöses",
                    type: "currency",
                    disabled: !_vm.hasKaufVerkauf
                  },
                  on: {
                    change: function($event) {
                      return _vm.updateStoreData({
                        componentId: "INPUT_HELLOBANK_REFERENZKONTO_BETRAG",
                        value: $event
                      })
                    }
                  },
                  model: {
                    value: _vm.INPUT_HELLOBANK_REFERENZKONTO_BETRAG,
                    callback: function($$v) {
                      _vm.INPUT_HELLOBANK_REFERENZKONTO_BETRAG = $$v
                    },
                    expression: "INPUT_HELLOBANK_REFERENZKONTO_BETRAG"
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _c("InputTextArea", {
          attrs: {
            label: "sonstige Bemerkungen",
            disabled: !_vm.hasKaufVerkauf
          },
          on: {
            change: function($event) {
              return _vm.updateStoreData({
                componentId: "INPUT_HELLOBANK_BEMERKUNG",
                value: $event
              })
            }
          },
          model: {
            value: _vm.INPUT_HELLOBANK_BEMERKUNG,
            callback: function($$v) {
              _vm.INPUT_HELLOBANK_BEMERKUNG = $$v
            },
            expression: "INPUT_HELLOBANK_BEMERKUNG"
          }
        })
      ],
      1
    ),
    _vm.hasSparplan
      ? _c(
          "div",
          { staticClass: "box__container" },
          [
            _c("div", { staticClass: "box__title" }, [
              _vm._v("Angaben für Sparpläne")
            ]),
            _c("InputToggleSwitch", {
              attrs: { label: "SEPA-Lastschrift Mandat", inLineLabel: "" },
              on: {
                input: function($event) {
                  return _vm.updateStoreData({
                    componentId: "INPUT_HELLOBANK_CHK_LASTSCHRIFT",
                    value: $event
                  })
                }
              },
              model: {
                value: _vm.INPUT_HELLOBANK_CHK_LASTSCHRIFT,
                callback: function($$v) {
                  _vm.INPUT_HELLOBANK_CHK_LASTSCHRIFT = $$v
                },
                expression: "INPUT_HELLOBANK_CHK_LASTSCHRIFT"
              }
            }),
            _c("ComboBox", {
              attrs: {
                label: "IBAN",
                disabled: _vm.INPUT_HELLOBANK_CHK_LASTSCHRIFT == "0",
                values: _vm.comboboxValues
                  ? _vm.comboboxValues.INPUT_HELLOBANK_BANKVERBINDUNG_COMBO
                  : []
              },
              on: {
                change: function($event) {
                  return _vm.updateStoreData({
                    componentId: "INPUT_HELLOBANK_BANKVERBINDUNG",
                    value: $event
                  })
                }
              },
              model: {
                value: _vm.INPUT_HELLOBANK_BANKVERBINDUNG,
                callback: function($$v) {
                  _vm.INPUT_HELLOBANK_BANKVERBINDUNG = $$v
                },
                expression: "INPUT_HELLOBANK_BANKVERBINDUNG"
              }
            }),
            _c(
              "div",
              [
                _c("InputField", {
                  attrs: {
                    disabled:
                      _vm.INPUT_HELLOBANK_CHK_LASTSCHRIFT == "0" ||
                      _vm.INPUT_HELLOBANK_BANKVERBINDUNG != "freie Eingabe:"
                  },
                  on: {
                    change: function($event) {
                      return _vm.updateStoreData({
                        componentId: "INPUT_HELLOBANK_BANKVERBINDUNG_TEXT",
                        value: $event
                      })
                    }
                  },
                  model: {
                    value: _vm.INPUT_HELLOBANK_BANKVERBINDUNG_TEXT,
                    callback: function($$v) {
                      _vm.INPUT_HELLOBANK_BANKVERBINDUNG_TEXT = $$v
                    },
                    expression: "INPUT_HELLOBANK_BANKVERBINDUNG_TEXT"
                  }
                })
              ],
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }