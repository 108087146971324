export const MUTATION_PREFIX = 'BERATUNGSMAPPE_MUTATIONS_';
export const ACTIONS_PREFIX = 'BERATUNGSMAPPE_ACTIONS_';
export const GETTERS_PREFIX = 'BERATUNGSMAPPE_GETTERS_';

export default {
  MUTATIONS: {
    RETRIEVE_BERATUNGSMAPPELIST_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_BERATUNGSMAPPELIST_SUCCESS',
    RESET_DATA: MUTATION_PREFIX + 'RESET_DATA',
    EDIT_BERATUNGSMAPPE_SUCCESS: MUTATION_PREFIX + 'EDIT_BERATUNGSMAPPE_SUCCESS',
    RETRIEVE_BERATUNGSMAPPEACTIONS_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_BERATUNGSMAPPEACTIONS_SUCCESS',
    EXECUTE_BERATUNGSMAPPEACTION_SUCCESS: MUTATION_PREFIX + 'EXECUTE_BERATUNGSMAPPEACTION_SUCCESS',
    DELETE_ANTRAG_SUCCESS: MUTATION_PREFIX + 'DELETE_ANTRAG_SUCCESS',
    RETRIEVE_ORDER_POSITION_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_ORDER_POSITION_SUCCESS',
    UPDATE_ORDER_POSITION: MUTATION_PREFIX + 'UPDATE_ORDER_POSITION',
    RETRIEVE_LAGERSTELLE_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_LAGERSTELLE_SUCCESS',
    RETRIEVE_BERATUNGSMAPPE_SELECTIONS_SUCCESS: ACTIONS_PREFIX + 'RETRIEVE_BERATUNGSMAPPE_SELECTIONS_SUCCESS',
    SAVE_ANTRAGS_POSITION_SUCCESS: MUTATION_PREFIX + 'SAVE_ANTRAGS_POSITION_SUCCESS',
    DELETE_POSITION_SUCCESS: MUTATION_PREFIX + 'DELETE_POSITION_SUCCESS',
    GENERATE_GESELLSCHAFTEN_SUCCESS: ACTIONS_PREFIX + 'GENERATE_GESELLSCHAFTEN_SUCCESS',
  },
  ACTIONS: {
    RETRIEVE_BERATUNGSMAPPELIST: ACTIONS_PREFIX + 'RETRIEVE_BERATUNGSMAPPELIST',
    EDIT_BERATUNGSMAPPE: ACTIONS_PREFIX + 'EDIT_BERATUNGSMAPPE',
    RETRIEVE_BERATUNGSMAPPEACTIONS: ACTIONS_PREFIX + 'RETRIEVE_BERATUNGSMAPPEACTIONS',
    EXECUTE_BERATUNGSMAPPEACTION: ACTIONS_PREFIX + 'EXECUTE_BERATUNGSMAPPEACTION',
    DELETE_BERATUNGSMAPPE: ACTIONS_PREFIX + 'DELETE_BERATUNGSMAPPE',
    CREATE_BERATUNGSMAPPE: ACTIONS_PREFIX + 'CREATE_BERATUNGSMAPPE',
    DELETE_ANTRAG: ACTIONS_PREFIX + 'DELETE_ANTRAG',
    RETRIEVE_ORDER_POSITION: ACTIONS_PREFIX + 'RETRIEVE_ORDER_POSITION',
    COPY_BERATUNGSMAPPE: ACTIONS_PREFIX + 'COPY_BERATUNGSMAPPE',
    RETRIEVE_LAGERSTELLE: ACTIONS_PREFIX + 'RETRIEVE_LAGERSTELLE',
    RETRIEVE_BERATUNGSMAPPE_SELECTIONS: ACTIONS_PREFIX + 'RETRIEVE_BERATUNGSMAPPE_SELECTIONS',
    RETRIEVE_AA: ACTIONS_PREFIX + 'RETRIEVE_AA',
    SAVE_ANTRAGS_POSITION: ACTIONS_PREFIX + 'SAVE_ANTRAGS_POSITION',
    DELETE_POSITION: ACTIONS_PREFIX + 'DELETE_POSITION',
    GENERATE_GESELLSCHAFTEN: ACTIONS_PREFIX + 'GENERATE_GESELLSCHAFTEN',
    RETRIEVE_VERSENDEN_FILES: ACTIONS_PREFIX + 'RETRIEVE_VERSENDEN_FILES',
  },
  GETTERS: {
    BERATUNGSMAPPELIST_DATA: GETTERS_PREFIX + 'BERATUNGSMAPPELIST_DATA',
    BERATUNGSMAPPE_CARDS: GETTERS_PREFIX + 'BERATUNGSMAPPE_CARDS',
    BERATUNGSMAPPE_ACTIONS: GETTERS_PREFIX + 'BERATUNGSMAPPE_ACTIONS',
    EXECUTE_ACTION_RESULT: GETTERS_PREFIX + 'EXECUTE_ACTION_RESULT',
    ORDER_POSITION: GETTERS_PREFIX + 'ORDER_POSITION',
    ORDER_POSITION_HEADERS: GETTERS_PREFIX + 'ORDER_POSITION_HEADERS',
    ORDER_POSITION_RECORDS: GETTERS_PREFIX + 'ORDER_POSITION_RECORDS',
    LAGERSTELLE_LISTE: GETTERS_PREFIX + 'LAGERSTELLE_LISTE',
    BERATUNGSMAPPE_SELECTIONS: GETTERS_PREFIX + 'BERATUNGSMAPPE_SELECTIONS',
    LAST_SAVED_ANTRAGS_POSITION: GETTERS_PREFIX + 'LAST_SAVED_ANTRAGS_POSITION',
    LAST_DELETED_ANTRAGS_POSITION: GETTERS_PREFIX + 'LAST_DELETED_ANTRAGS_POSITION',
    VERSENDEN_FILES: GETTERS_PREFIX + 'VERSENDEN_FILES',
  }
}