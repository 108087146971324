var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.hasDepotChartsData
        ? _c(
            "div",
            { staticClass: "row" },
            _vm._l(_vm.depotCharts, function(chart, key) {
              return _c(
                "div",
                { key: "depot" + key, staticClass: "col-12 col-md-6 d-flex" },
                [
                  key !== "depotid"
                    ? _c("DepotpositionsChart", {
                        attrs: {
                          chart: chart,
                          tid: _vm._generateTidFromString(
                            "chart-container-" + key
                          )
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            }),
            0
          )
        : _vm.loading
        ? _c(
            "div",
            { staticClass: "row" },
            _vm._l(10, function(n) {
              return _c(
                "div",
                { key: n, staticClass: "col-12 col-md-6 mb-4" },
                [
                  _c(
                    "GhostLoading",
                    { attrs: { useBoxContainer: "" } },
                    [
                      _c("Block", { attrs: { type: "title" } }),
                      _c("Block", { attrs: { height: 350 } })
                    ],
                    1
                  )
                ],
                1
              )
            }),
            0
          )
        : _c("NoData", { staticClass: "box__container" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }