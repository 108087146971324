var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "stepper-item__container clickable",
      class: {
        "stepper-item--selected": _vm.isCurrent,
        "stepper-item--disabled": _vm.disabled
      },
      attrs: { tid: _vm._generateTidFromString(_vm.step.label) },
      on: {
        click: function($event) {
          return _vm.$emit("click", $event)
        },
        mouseover: function($event) {
          return _vm.handleMouseOver($event)
        },
        mouseleave: function($event) {
          return _vm.handleMouseLeave($event)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "stepper-item__icon" },
        [
          _c("VerticalStepIcon", {
            attrs: {
              stepperStatus: _vm.stepperStatus,
              isCurrent: _vm.isCurrent,
              collapsed: _vm.collapsed
            }
          })
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "vertical-stop__label",
          class: { "vertical-stop__label--selected": _vm.isCurrent }
        },
        [
          _vm.step.editing
            ? _c("InputField", {
                key: _vm.step.stepKey,
                staticClass: "vertical-stop__label--input p-0 m-0",
                attrs: {
                  autofocus: "",
                  showCleanButtonIcon: false,
                  showConfirmationIcon: false
                },
                on: {
                  change: function($event) {
                    return _vm.$emit("stepEdited", {
                      label: $event,
                      stepKey: _vm.step.stepKey
                    })
                  },
                  onBlur: function($event) {
                    return _vm.$emit("disablesEditStep", _vm.step.stepKey)
                  }
                },
                model: {
                  value: _vm.step.editing.label,
                  callback: function($$v) {
                    _vm.$set(_vm.step.editing, "label", $$v)
                  },
                  expression: "step.editing.label"
                }
              })
            : _vm._e(),
          [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.step.editing,
                    expression: "!step.editing"
                  }
                ],
                staticClass: "vertical-stop__label--content"
              },
              [
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.sanitize(_vm.step.label)) }
                }),
                _vm._v(" "),
                _vm.showSaveSpinner ? _c("AnimatedSpinner") : _vm._e()
              ],
              1
            ),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.step.editing,
                    expression: "!step.editing"
                  }
                ],
                staticClass: "vertical-stop__label--actions"
              },
              [
                _vm.isEditable
                  ? _c(
                      "button",
                      {
                        staticClass: "btn-clear clickable",
                        attrs: { type: "button", disabled: _vm.disabled },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.$emit(
                              "enablesEditStep",
                              _vm.step.stepKey
                            )
                          }
                        }
                      },
                      [_c("PhPencil", { attrs: { size: 16 } })],
                      1
                    )
                  : _vm._e(),
                _vm.isRemovable
                  ? _c(
                      "button",
                      {
                        staticClass: "btn-clear clickable",
                        attrs: { type: "button", disabled: _vm.disabled },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.$emit("removeStep", _vm.step)
                          }
                        }
                      },
                      [_c("PhTrash", { attrs: { size: 16 } })],
                      1
                    )
                  : _vm._e(),
                _vm.isSortable
                  ? _c(
                      "button",
                      {
                        staticClass: "btn-clear",
                        attrs: {
                          type: "button",
                          disabled: _vm.disabled,
                          "data-sortable-item-handler": ""
                        }
                      },
                      [_c("PhList", { attrs: { size: 16 } })],
                      1
                    )
                  : _vm._e()
              ]
            )
          ]
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }