import ANLEGERPROFIL_TYPES from './types';

export default {
  [ANLEGERPROFIL_TYPES.GETTERS.ANLEGERPROFIL](state) {
    return state.data;
  },
  [ANLEGERPROFIL_TYPES.GETTERS.WARNINGS](state) {
    return state.warnings;
  },
  [ANLEGERPROFIL_TYPES.GETTERS.BERATUNGS_MAPPE_ID](state) {
    return state.beratungsMappeId;
  },
  [ANLEGERPROFIL_TYPES.GETTERS.SAVE_ANLEGERPROFIL_STATE](state) {
    return state.saveAnlegerprofilState;
  },
  // used in step Personenauswahl by adding a new person to map it to personType
  [ANLEGERPROFIL_TYPES.GETTERS.SELECTED_PERSON_TYPE](state) {
    return state.selectedPersonType;
  },
  // active personType for the stepper Persons
  [ANLEGERPROFIL_TYPES.GETTERS.PERSON_TYPE](state) {
    return state.personType;
  }
}