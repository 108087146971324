<template>
  <BaseModal 
    ref="showDeeplinksModal" 
    modalTitle="Deeplinks" 
    :showCloseButton="true" 
    :showCancelButton="false" 
    :showConfirmButton="false" 
  >
    <InsuranceDeeplinksList :vertragId="vertragId" />
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/core/BaseModal.vue';
import InsuranceDeeplinksList from '@/views/versicherungen/InsuranceDeeplinksList.vue';

export default {
    components: {
      BaseModal,
      InsuranceDeeplinksList,
    },
    data() {
      return {
        vertragId: null,
      };
    },
    methods: {
      open({vertragId}) {
        this.$refs.showDeeplinksModal.open();
        this.vertragId = vertragId;
      },
    }
}
</script>
