import { render, staticRenderFns } from "./DraggableParentElement.vue?vue&type=template&id=2595ca95&scoped=true&"
import script from "./DraggableParentElement.vue?vue&type=script&lang=js&"
export * from "./DraggableParentElement.vue?vue&type=script&lang=js&"
import style0 from "./DraggableParentElement.vue?vue&type=style&index=0&id=2595ca95&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2595ca95",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("P:\\workspaces\\fk0\\fc\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2595ca95')) {
      api.createRecord('2595ca95', component.options)
    } else {
      api.reload('2595ca95', component.options)
    }
    module.hot.accept("./DraggableParentElement.vue?vue&type=template&id=2595ca95&scoped=true&", function () {
      api.rerender('2595ca95', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/core/DraggableParentElement.vue"
export default component.exports