import axios from 'axios';
import { buildMessage } from '@/helpers/log-message-helper';

import CORE_TYPES from '@/store/core/types';
import LOG_TYPES from '@/store/log/types';
import TRANSAKTION_VERLAUF_TYPES from './types';

import { viewDocument } from '@/components/core/download/DownloadLink.vue';

const SERVICE_PATH = '/transaktionVerlauf'
const config = {
  defaultSpinner: true
};


export default {

  async [TRANSAKTION_VERLAUF_TYPES.ACTIONS.FIND_TRANSACTIONS]({ getters, commit }, { modus, from, to, }) {
    const params = [];
    if(modus) params.push(`modus=${modus}`);
    if(from) params.push(`from=${from}`);
    if(to) params.push(`to=${to}`);

    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/transactions?${params.join('&')}`, config);
    commit(TRANSAKTION_VERLAUF_TYPES.MUTATIONS.FIND_TRANSACTIONS_SUCCESS, response?.data || []);
  },

  async [TRANSAKTION_VERLAUF_TYPES.ACTIONS.DOWNLOAD_FILE]({ getters, dispatch }, { groupId, type }) {
    if(!groupId || !type) return ;

    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/downloadFile/${groupId}?type=${type}`, config);
    if(response?.data?.data) {
      const { data, fileName, contentType } = response.data;
      viewDocument({
        data,
        filename: fileName,
        contentType,
      });
    } else if(response?.data?.errorMessage) {
      dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response?.data?.errorMessage, 'danger'));
    }
  },

}
