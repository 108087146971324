import EXTERNE_ZUGANG_TYPES from '@/store/externeZugang/types'
import { mapGetters } from 'vuex'

import ExterneZugangAskOptions from '@/components/externeZugang/ExterneZugangAskOptions.vue'

export default {
  components: {
    ExterneZugangAskOptions,
  },
  data() {
    return {
      askOptions: null,
      externeZugang: null,
      loading: false,
      data: null,
      actionLabel: "Kunde anlegen",
    }
  },
  computed: {
    ...mapGetters({
      externeZugangList: EXTERNE_ZUGANG_TYPES.GETTERS.EXTERNE_ZUGANG_VERGLEICH_LIST,
    }),
  },
  methods: {
    async getList() {
      try {
        this.loading = true;
        await this.$store.dispatch(EXTERNE_ZUGANG_TYPES.ACTIONS.GET_EXTERNE_ZUGANG_VERGLEICH_LIST);
      } finally {
        this.loading = false;
      }
    },
    askOptionsExterneZugang(externeZugang) {
      switch (externeZugang?.itemLabel) {
        case "Nafi-KFZ":
        case "Finanzportal24":
        case "Franke & Bornberg fb-tools":
        case "Tarifrechner Nürnberger BT4All":
        case "Innosystems":
          if (externeZugang.askOptions) {
            this.externeZugang = { ...externeZugang }
            this.askOptions = [...externeZugang.askOptions]
          }
          this.$router.push({ path: `/beratung/vergleichsrechner/${encodeURIComponent(externeZugang.itemLabel)}`, query: { path: externeZugang.path }});
          break;
        case "Chegg.net":  
          this.openExterneZugang(externeZugang.path);
          break;
        case "Chegg.net Dokumente abholen":
          this.dokumenteAbholen();
          break;
        default:
          this.actionLabel = externeZugang.actionLabel;
          if (externeZugang.askOptions) {
            this.externeZugang = { ...externeZugang }
            this.askOptions = [...externeZugang.askOptions]
          } else {
            this.openExterneZugang(externeZugang.path)
          }
      }
    },
    openExterneZugang(path) {
      this.$store.dispatch(EXTERNE_ZUGANG_TYPES.ACTIONS.OPEN_EXTERNE_ZUGANG, { path })
    },
    openExterneZugangWithIdList(path, idList, options) {
      return this.$store.dispatch(EXTERNE_ZUGANG_TYPES.ACTIONS.OPEN_EXTERNE_ZUGANG, { path, idList, options })
    },    
    askOptionsConfirmed($event) {
      this.$store.dispatch(EXTERNE_ZUGANG_TYPES.ACTIONS.OPEN_EXTERNE_ZUGANG, { path: this.externeZugang?.path, options: $event })
      this.resetExterneZugang()
    },
    resetExterneZugang() {
      this.externeZugang = null
      this.askOptions = null
    },
  },
  mounted() {
    this.getList();
  },
}
