var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ImageUpload", {
        staticClass: "scan-container",
        attrs: {
          tid: _vm._generateTidFromString("user-picture"),
          image: _vm.userPicture,
          title: "Profilbild",
          hasDeleteButton: "",
          loading: _vm.loading,
          config: _vm.imageUploadConfig,
          disabled: _vm.loading || _vm.disabled
        },
        on: {
          file: function($event) {
            return _vm.uploadImage($event)
          },
          change: function($event) {
            return _vm.uploadImage($event)
          },
          delete: _vm.deleteImage
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }