var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-forms__container" }, [
    _c("div", { staticClass: "input-forms__label-container" }, [
      _c(
        "div",
        { staticClass: "input-forms__input-container" },
        [
          _c(
            "BaseButton",
            {
              staticClass: "mr-0",
              attrs: {
                disabled: _vm.disabled || _vm.loading,
                isComponentHalfSize: "",
                isSecondary: ""
              },
              on: {
                click: function($event) {
                  return _vm.executeConfigButton()
                }
              }
            },
            [
              _vm.loading ? _c("AnimatedSpinner") : _vm._e(),
              _vm._v(" " + _vm._s(_vm.label) + " ")
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }