<template>
  <div class="esg_container">
    <div class="esgwrapper">
      <div class="banner">Nachhaltigkeit / ESG</div>
      <hr />
      <div class="esg_text">
        <div class="title mt-0">Allgemein</div>
        <p>
        Nachhaltigkeit geht uns alle an. Sie ist das Bewusstsein, die natürlichen Lebensgrundlagen wie sauberes Wasser und gesunde Luft zu bewahren und den Klimawandel so schnell und so weit wie möglich zu begrenzen. Sämtliches Handeln ist ganzheitlich auf diese Ziele auszurichten, um jetzige und nachfolgende Generationen vor Schaden zu schützen und ihnen eine lebenswerte Welt zu bieten bzw. zu hinterlassen.
        </p><p>
        Zur Zielerreichung müssen nicht nur Staatengemeinschaften und Einzelstaaten sowie ihre Regierungen, Institutionen und Bürger beitragen, sondern auch Unternehmen aus allen Wirtschafts- und Dienstleistungsbereichen. Ein verbales oder geschriebenes Bekenntnis zur Nachhaltigkeit reicht dabei nicht aus. Es geht vielmehr darum, das jeweilige Handeln an Ergebnissen zu messen und diese transparent zu veröffentlichen bzw. anhand von objektiven Maßstäben (Benchmarks) zu vergleichen.
        </p><p>
        Die Europäische Union hat für ihre Mitgliedsstaaten die sogenannte Offenlegungsverordnung (Sustainable Finance Disclosure Regulation – kurz „SFDR“) zum Thema Nachhaltigkeit erlassen, die seit März 2021 in einem mehrstufigen Verfahren umgesetzt werden muss und auch Finanzberater betrifft. Darin werden unter dem Label „ESG“ (Environment / Social / Governance – zu Deutsch: Umwelt / Soziales / Unternehmensführung) drei zentrale Verantwortungsbereiche genannt: Umweltschutz, soziale Nachhaltigkeit und verantwortungsbewusste Unternehmensführung. Nachstehend möchten wir Sie informieren, in welcher Form wir den Bestimmungen der Verordnung nachkommen:
        </p><p>
        Wir nehmen das Thema Nachhaltigkeit sehr ernst und werden selbstverständlich sämtliche Verpflichtungen in enger Zusammenarbeit mit unseren Kooperationspartnern sowie den Produktanbietern vollumfänglich erfüllen. Hierzu wurden und werden vor allem Produktkennzeichnungen und einschlägige Informationen zum Thema Nachhaltigkeit eingeführt und kommuniziert, insbesondere in welchem Umfang Produkte ESG-Kriterien erfüllen oder ob der Nachhaltigkeitsaspekt innerhalb eines Produktes nicht berücksichtigt wird.
        </p><p>
        Darüber hinaus beobachten wir den Markt intensiv im Hinblick auf die dynamische ESG-Thematik. Dies gilt für alle Bestandsprodukte genauso wie auch für zu erwartende Neuentwicklungen aus dem Fonds- und Versicherungsbereich, bei denen wir die Änderungen genau im Blick haben und Ihre diesbezüglichen Wünsche im Zuge eines Beratungstermines selbstverständlich gerne berücksichtigen werden.
        </p>
        <div class="title">Informationen über Nachhaltigkeitsrisiken bei Finanzprodukten</div>
        <p class="title2">Was sind Nachhaltigkeitsrisiken?</p>
        <p>Als Nachhaltigkeitsrisiken (ESG-Risiken) werden Ereignisse oder Bedingungen aus den drei Bereichen Umwelt (Environment), Soziales (Social) und Unternehmensführung (Governance) bezeichnet, deren Eintreten negative Auswirkungen auf den Wert der Investition bzw. Anlage haben könnten. Diese Risiken können einzelne Unternehmen genauso wie ganze Branchen oder Regionen betreffen.</p>
        <p class="title2">Was gibt es für Beispiele für Nachhaltigkeitsrisiken in den drei Bereichen?</p>
        <ul>
          <li>Umwelt: In Folge des Klimawandels könnten vermehrt auftretende Extremwetterereignisse ein Risiko darstellen. Dieses Risiko wird auch physisches Risiko genannt. Ein Beispiel hierfür wäre eine extreme Trockenperiode in einer bestimmten Region. Dadurch könnten Pegel von Transportwegen wie Flüssen so weit sinken, dass der Transport von Waren beeinträchtigt werden könnte.</li>
          <li>Soziales: Hier könnten sich Risiken zum Beispiel aus der Nichteinhaltung von arbeitsrechtlichen Standards oder des Gesundheitsschutzes ergeben. </li>
          <li>Unternehmensführung: Beispiele für Risiken in diesem Bereich sind etwa die Nichteinhaltung der Steuerehrlichkeit oder Korruption in Unternehmen.</li>
        </ul>
        <div class="title">Informationen zur Einbeziehung von Nachhaltigkeitsrisiken bei der Beratungstätigkeit (Art. 3 SFDR)</div>
        <p>Um Nachhaltigkeitsrisiken bei der Beratung einzubeziehen, werden im Rahmen der Auswahl von Produktanbietern (Finanzmarktteilnehmern) und deren Finanzprodukten deren zur Verfügung gestellten Informationen berücksichtigt. Produktanbieter, die erkennbar keine Strategie zur Einbeziehung von Nachhaltigkeitsrisiken in ihre Investitionsentscheidungen haben, werden ggf. nicht angeboten. </p>
        <p>Im Rahmen der Beratung wird ggf. gesondert dargestellt, wenn die Berücksichtigung der Nachhaltigkeitsrisiken bei der Investmententscheidung erkennbare Vor- bzw. Nachteile für Sie bedeuten.</p>
        <p>Über die Berücksichtigung von Nachhaltigkeitsrisiken bei Investitionsentscheidungen des jeweiligen Produktanbieters informiert dieser mit seinen vorvertraglichen Informationen. Fragen dazu können Sie im Vorfeld eines möglichen Abschlusses ansprechen.</p>
        <div class="title">Informationen zur Berücksichtigung nachteiliger Auswirkungen auf Nachhaltigkeitsfaktoren 
(Art. 4 SFDR in Verbindung mit Art. 11 der Ergänzung zur SFDR vom 01. Januar 2023)</div>
        <p class="title2">Erklärung über die Berücksichtigung der wichtigsten nachteiligen Auswirkungen auf Nachhaltigkeitsfaktoren bei der Anlage- und Versicherungsberatung:</p>
        <p>Bei der Beratung ist es unser Ziel, Ihnen ein geeignetes Anlage-/Versicherungsanlageprodukt empfehlen zu können. Dabei berücksichtigen wir auch Ihre Nachhaltigkeitspräferenzen, sofern Sie dies wünschen. Hierbei können Sie festlegen, ob bei Ihrer Anlage ökologische und/oder soziale Werte sowie Grundsätze guter Unternehmensführung und/oder die wichtigsten nachteiligen Auswirkungen von Investitionsentscheidungen auf Nachhaltigkeitsfaktoren berücksichtigt werden sollen. Der Gesetzgeber hat je nach Art des Anlageziels (Investition in Unternehmen, Staaten, Immobilien etc.) in folgenden Bereichen „Indikatoren“ für die wichtigsten nachteiligen Auswirkungen ihrer Investitionsentscheidungen auf Nachhaltigkeitsfaktoren bestimmt:</p>
        <ul>
          <li>Umwelt-, Sozial- und Arbeitnehmerbelange</li>
          <li>Achtung der Menschenrechte</li>
          <li>Bekämpfung von Korruption und Bestechung</li>
        </ul>
        <p>Die Produktanbieter sind gesetzlich verpflichtet, eine Erklärung zu veröffentlichen, welche Strategie sie in Bezug auf die Berücksichtigung der wichtigsten nachteiligen Auswirkungen und den Umgang damit verfolgen. Dies bezieht sich insbesondere auf Treibhausgasemissionen, Wasserverschmutzung, Biodiversität, Abfall, Soziales und Arbeitnehmerbelange (einschließlich Menschenrechte und Korruption). Wenn Sie sich dazu entscheiden, dass die wichtigsten nachteiligen Auswirkungen Ihrer Investitionsentscheidungen auf Nachhaltigkeitsfaktoren bei der Produktauswahl berücksichtigt werden sollen, beachten wir im Rahmen des Auswahlprozesses die von den Produktanbietern bereitgestellten Informationen sowie die von den Produktanbietern dargelegten Strategien. </p>
        <p>Eigene Einstufungs- und Auswahlmethoden zu den Informationen der Produktanbieter wenden wir nicht an. Es erfolgt keine gesonderte Prüfung der Angaben der Produktanbieter in Hinblick auf ihre Plausibilität.</p>
        <div class="title">Informationen zur Vergütungspolitik bei der Berücksichtigung von Nachhaltigkeitsrisiken 
(Art. 5 SFDR)</div>
        <p>Die Vergütung für die Vermittlung von Finanzprodukten wird von möglichen Nachhaltigkeitsrisiken grundsätzlich nicht beeinflusst.</p>
        <p>Es kann vorkommen, dass Produktanbieter die Berücksichtigung von Nachhaltigkeitsrisiken bei Investitionen höher vergüten. Wenn dies dem Kundeninteresse nicht widerspricht, wird die höhere Vergütung angenommen.</p>
      </div>
    </div>
  </div>
</template>
<script>


export default {

  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>

<style scoped>
.esg_container {
  margin: 0 auto;
  padding-left: 1em;
  padding-right: 1em;
  max-width: 1200px;
}
.banner {
  font-size: 26px;
  text-align: left;
  color: var(--color-primary);
}
.title {
  font-size: 18px;
  text-align: left;
  color: var(--color-primary);
  margin-top: 48px;
  font-weight: 700;
}
.title2 {
  text-align: left;
  color: var(--color-primary);
  text-decoration-line: underline;
}
.esg_wrapper {
  margin: 0 auto;
}
.esg_text {
  text-align: justify;
  padding: 2em;
}
@media only screen and (max-width: 1024px) {
  .datenschutz-container {
    margin-top: 17em;
  }
}
</style>