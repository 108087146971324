var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: "Nachrichten", actions: _vm.headerActions },
        on: {
          "action-ADD": function($event) {
            return _vm.newTicket()
          }
        }
      }),
      _c("BaseFilter", {
        attrs: {
          title: "Nachrichtenfilter",
          filterId: "TicketsNachrichten",
          configFilter: _vm.filterConfig,
          metadata: _vm.metadata,
          defaultOptions: _vm.defaultOptions,
          showSaveButton: "",
          hasSmartSearch: "",
          immidiateSearch: true
        },
        on: { onFilter: _vm.onFilter }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.loading
            ? _c("GhostLoading", {
                staticStyle: { clear: "both" },
                attrs: { type: "table", title: _vm.TABLE_TITLE }
              })
            : _vm.isLoaded && !_vm.hasTableData
            ? _c("NoData", { attrs: { title: _vm.TABLE_TITLE } })
            : _vm.hasTableData
            ? _c("Table", {
                attrs: {
                  tableId: "cf1a65f8-3933-4d68-b299-a23edeb56b2b",
                  title: _vm.TABLE_TITLE,
                  headers: _vm.headers,
                  headerActions: _vm.tableHeaderActions,
                  rows: _vm.rows,
                  rowsPerPage: 30,
                  selected: _vm.selectedRows,
                  mobileConfig: {
                    title: "nameBeteiligter",
                    headers: ["thema", "geaendert", "status"]
                  }
                },
                on: {
                  clickRow: _vm.clickThema,
                  selectionAsBlacklist: _vm.selectionAsBlacklist,
                  selected: _vm.selected,
                  "click-thema": _vm.clickThema,
                  "click-nameBeteiligter": _vm.openCustomer,
                  "click-kundennr": _vm.openCustomer,
                  "action-PDF": _vm.getPDF,
                  "headerAction-CLOSE": _vm.handleClosed,
                  "headerAction-MARK_AS_READ": _vm.handleMarkAsRead,
                  "headerAction-MARK_AS_UNREAD": _vm.handleMarkAsUnRead
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }