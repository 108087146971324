<template>
  <div class="form" v-if="cleanMaklername || brokerData && brokerData.cleanMaklername">
    <h1>{{cleanMaklername || brokerData.cleanMaklername}}</h1>
    <p>{{strasse || brokerData.strasse}}</p>
    <p>{{plzOrt || brokerData.plzOrt}}</p>
    <br />
    <p>{{telefon || brokerData.telefon}}</p>
    <p>{{faxStandard || brokerData.faxStandard}}</p>
      <div class="button" id="login_button" >Erstinformationen</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CORE_TYPES from '../../../store/core/types'

export default {
  props:[
    "cleanMaklername",
    "strasse",
    "plzOrt",
    "telefon",
    "faxStandard",
  ],

  computed: {
    ...mapGetters({
      brokerData: CORE_TYPES.GETTERS.GET_BROKER_INFORMATION,
    }),
  },
}
</script>

<style scoped>
.form{
    padding:.5em;
}
</style>