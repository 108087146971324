var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("InputField", {
        attrs: {
          label: "langfristige Kredite / Darlehen",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.isDisabled || _vm.isRefusedPage
        },
        on: {
          input: function($event) {
            return _vm.updateStore("finance", "longTermCredit", $event || 0)
          }
        },
        model: {
          value: _vm.longTermCredit,
          callback: function($$v) {
            _vm.longTermCredit = $$v
          },
          expression: "longTermCredit"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "kurzfristige Kredite (Kontokorrent, Zwischenfinanzierungen)",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.isDisabled || _vm.isRefusedPage
        },
        on: {
          input: function($event) {
            return _vm.updateStore("finance", "shortTermCredit", $event || 0)
          }
        },
        model: {
          value: _vm.shortTermCredit,
          callback: function($$v) {
            _vm.shortTermCredit = $$v
          },
          expression: "shortTermCredit"
        }
      }),
      _c("InputField", {
        attrs: {
          label:
            "Sonstige Verpflichtungen zu Gunsten Dritter (Verpfändungen, Bürgschaften)",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.isDisabled || _vm.isRefusedPage
        },
        on: {
          input: function($event) {
            return _vm.updateStore("finance", "otherCredit", $event || 0)
          }
        },
        model: {
          value: _vm.otherCredit,
          callback: function($$v) {
            _vm.otherCredit = $$v
          },
          expression: "otherCredit"
        }
      }),
      _c("div", { staticClass: "sum d-flex justify-content-between" }, [
        _c("div", { staticClass: "my-2" }, [
          _vm._v("Summe Verbindlichkeiten / Schulden")
        ]),
        _c(
          "div",
          { staticClass: "my-2 pr-3" },
          [_c("CurrencyLabel", { attrs: { value: _vm.summeSchulden } })],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }