import actions from './actions';
import mutations from './mutations';
import getters from './getters';

/**
 * mutations: synchronous operation responsible for changing the state
 * actions: asynchronous operation responsible for calling external resources and committing mutations 
 */

export function getInitialState() {
	return {
		emailNotRecognized: [],
	}
}

export default {
	state: {
		...getInitialState()
	},
	mutations: {
		...mutations
	},
	actions: {
		...actions
	},
	getters: {
		...getters
	}
}