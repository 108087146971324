var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isFK && _vm.isFax
        ? _c("InputField", {
            attrs: { label: "Fax-Nummer" },
            on: {
              change: function($event) {
                return _vm.dataChanged()
              }
            },
            model: {
              value: _vm.form.faxNummer,
              callback: function($$v) {
                _vm.$set(_vm.form, "faxNummer", $$v)
              },
              expression: "form.faxNummer"
            }
          })
        : _vm._e(),
      _vm.isLegitimationsurkunde
        ? [
            _c("DatePickerField", {
              attrs: { label: "Ablaufdatum", isValueAsString: "" },
              on: {
                change: function($event) {
                  _vm.dataChanged()
                  _vm.addAblaufdatumErrorIfNeeded()
                }
              },
              model: {
                value: _vm.form.ablaufdatum,
                callback: function($$v) {
                  _vm.$set(_vm.form, "ablaufdatum", $$v)
                },
                expression: "form.ablaufdatum"
              }
            }),
            _vm.isFA
              ? _c("InputToggleSwitch", {
                  attrs: {
                    label: "Kein Ablaufdatum für die Legitimationsurkunde",
                    inLineLabel: ""
                  },
                  on: {
                    input: function($event) {
                      _vm.dataChanged()
                      _vm.addAblaufdatumErrorIfNeeded()
                    }
                  },
                  model: {
                    value: _vm.form.keinAblaufdatum,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "keinAblaufdatum", $$v)
                    },
                    expression: "form.keinAblaufdatum"
                  }
                })
              : _vm._e()
          ]
        : _vm._e(),
      _c("div", { staticClass: "row mt-0 mb-0" }, [
        _c(
          "div",
          { staticClass: "col col-sm-8 col-12" },
          [
            _c("DatePickerField", {
              attrs: { label: _vm.standVomLabel, isValueAsString: "" },
              on: {
                change: function($event) {
                  return _vm.dataChanged()
                }
              },
              model: {
                value: _vm.form.standVom,
                callback: function($$v) {
                  _vm.$set(_vm.form, "standVom", $$v)
                },
                expression: "form.standVom"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col col-sm-4 col-12" },
          [
            _c("TimePicker", {
              attrs: { label: "Uhrzeit" },
              on: {
                change: function($event) {
                  return _vm.dataChanged()
                }
              },
              model: {
                value: _vm.form.uhrzeit,
                callback: function($$v) {
                  _vm.$set(_vm.form, "uhrzeit", $$v)
                },
                expression: "form.uhrzeit"
              }
            })
          ],
          1
        )
      ]),
      _c("InputField", {
        attrs: { label: "Beschreibung" },
        on: {
          change: function($event) {
            return _vm.dataChanged()
          }
        },
        model: {
          value: _vm.form.beschreibung,
          callback: function($$v) {
            _vm.$set(_vm.form, "beschreibung", $$v)
          },
          expression: "form.beschreibung"
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }