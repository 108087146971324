import DCS_SYNCHRONISATION from './types';

export default{
    [DCS_SYNCHRONISATION.GETTERS.ZUORDNUNGSLISTE](state){
        return state.zuordnungsliste;
    },
    [DCS_SYNCHRONISATION.GETTERS.GET_PRODUCT](state){
        return state.product?.payload;
    },
    [DCS_SYNCHRONISATION.GETTERS.GET_COMBOBOXVALUES](state){
        return state.comboboxValues?.payload;
    }
}