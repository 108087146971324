<template>
  <BaseModal
    ref="modalEl"
    modalTitle="Reihenfolge der Bereiche festlegen"
    labelButtonCancel="Zurücksetzen"
    labelButtonConfirm="Schließen"
    @onCancelButton="restoreSectionsConfig()"
    @onConfirmButton="saveSectionsConfig()"
  >
    <template #default>
      <SortableList :items="sectionsIntern" @orderChanged="sectionsOrderChanged($event)">
        <ul class="dashboard-section-order-config__items list-bordered" data-sortable-container>
          <li v-for="section in sectionsIntern" :key="section.section" class="dashboard-section-order-config__item list-bordered-item" data-sortable-item>
            <span class="dashboard-section-order-config__item--title">{{ section.title }}</span>
            <div class="dashboard-section-order-config__item--actions">
              <button type="button" class="btn-clear" data-sortable-item-handler>
                <PhList :size="16" weight="bold" />
              </button>
            </div>
          </li>
        </ul>
      </SortableList>
    </template>
  </BaseModal>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import FC_CONFIG_TYPES from '@/store/fcConfig/types';
import FC_CONFIG from '@/configs/fcConfig.js';

import { PhList, } from 'phosphor-vue';

import BaseModal from '@/components/core/BaseModal.vue';
import SortableList from '@/components/core/SortableList.vue';


export default {
  components: {
    BaseModal,
    SortableList,
    PhList,
  },
  props: {
    sections: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      sectionsIntern: [],
    };
  },
  watch: {
    sections: {
      handler() {
        const sectionsIntern = this.sections.map(s => ({ 
          id: s.id,
          title: s.title,
          section: s.section,
        }));
        this.sectionsIntern = [ ...sectionsIntern ];
      },
      immediate: true,
    }
  },
  methods: {
    open() {
      this.$refs.modalEl.open();
    },
    close() {
      this.$refs.modalEl.close();
    },
    sectionsOrderChanged(orderedSections) {
      this.sectionsIntern = orderedSections;
    },
    async saveSectionsConfig() {
      const payload = {
        configId: FC_CONFIG.BROKER_DASHBOARD_CONFIG,
        configType: FC_CONFIG.BROKER_DASHBOARD_CONFIG,
        content: JSON.stringify(this.sectionsIntern),
        userLevel: null,
      };

      await this.$store.dispatch(FC_CONFIG_TYPES.ACTIONS.SAVE_FC_CONFIG, payload);
    },
    async restoreSectionsConfig() {
      const deletePayload = {
        configId: FC_CONFIG.BROKER_DASHBOARD_CONFIG,
        configType: FC_CONFIG.BROKER_DASHBOARD_CONFIG,
        userLevel: null,
      };

      await this.$store.dispatch(FC_CONFIG_TYPES.ACTIONS.DELETE_FC_CONFIG, deletePayload);
    },
  },
}
</script>

<style lang="scss" scoped>
.dashboard-section-order-config__item {
  display: flex;
  justify-content: space-between;

  .dashboard-section-order-config__item--title {
    flex: 1 1 auto;

    .dashboard-section-order-config__item--actions {
      flex: 0 0 auto;
    }
  }
}
</style>
