var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    { attrs: { href: "tel:" + _vm.phoneNumber.replace(/\s/g, "") } },
    [_vm._v(" " + _vm._s(_vm.phoneNumber) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }