<template>
  <div>
    <Action v-bind="$props" @action="requestConfirmation"/>
    <BaseModal
      ref="confirmationModal"
      :modalTitle="confirmationTitle"
      :labelButtonConfirm="labelButtonConfirm"
      :showCancelButton="true"
      @onConfirmButton="onClick">
        <label>{{ confirmationMessage }}</label>
    </BaseModal>
  </div>
</template>
<script>
import Action from './Action.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import headerActionsMixin from './header-actions-mixin.js'

export default {
  mixins: [headerActionsMixin],
  components: {
    Action,
    BaseModal,
  },
  props: {
    confirmationTitle: {
      type: String,
      required: true,
    },
    confirmationMessage: {
      type: String,
      required: true,
    },
    labelButtonConfirm: {
      type: String,
      default: 'Ok',
    }
  },
  methods: {
    requestConfirmation() {
      this.$refs.confirmationModal.open();
    },
  },
}
</script>