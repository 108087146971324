<template>
<div>
  <MailBriefVorlage :prependActions="headerActions" 
    @action-SAVE="saveTemplates()"
    @action-REFRESH="onRefresh()"
  />

  <div v-if="loadingTemplate || !id" class="box__container">
    <div>
      <template>
        <div v-if="templateData || !id">
          <div class="row">
            <div class="col col-md-6">
              <ComboBox
                label="Freigabe"
                v-model="grant"
                :values="grantValues"
                :disabled="!template.editable">
              </ComboBox>
            </div>
            <div class="col col-md-6">
              <ComboBox
                label="Typ der Vorlage"
                v-model="type"
                :values="typeValues"
                :disabled="!template.editable">
              </ComboBox>
            </div>
          </div>
          <div class="row">
            <div class="col col-md-6">
              <InputField
                label="Betreff"
                v-model="subject"
                :disabled="!template.editable"
              >
              </InputField>
            </div>
            <div class="col col-md-6">
              <InputField
                label="abw. Bezeichnung"
                v-model="subjectOverwrite"
                :disabled="!template.editable"
              >
              </InputField>
            </div>
          </div>
          <div class="row">
            <div class="col col-md-6">
              <ComboBox
                label="Kategorie"
                v-model="category"
                :values="categoryValues"
                :disabled="!template.editable">
              </ComboBox>
            </div>
          </div>
          <div class="mb-4">
            <b><i>
              Achtung! Bitte keine Inhalte aus Word u.ä. einkopieren, da der Inhalt beim Empfänger nicht korrekt dargestellt wird!
            </i></b>
          </div>
          <template>
            <TemplateHtmlEditor
              v-if="template.editable"
              :placeholders="placeholders || {}"
              v-model="html"
            />
            <div
              v-else
              v-html="html">
            </div>
          </template>
        </div>
      </template>
    </div>
  </div>
  <GhostLoading v-else type="block" />
</div>
</template>

<script>
import TEMPLATES_EDIT_TYPES from '@/store/templatesEdit/types';
import CORE_TYPES from '@/store/core/types';
import { mapGetters } from 'vuex';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import InputField from '@/components/core/forms/InputField.vue';
import TemplateHtmlEditor from './TemplateHtmlEditor.vue';
import MAILCOMPOSER_TYPES from '@/store/mailcomposer/types';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import MailBriefVorlage from './MailBriefVorlage.vue'
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

export function newTemplate() {
  return {
    subject: '',
    html: '',
    grant: 'NO',
    type: 'EMAIL',
    category: null,
    subjectOverwrite: '',
    template: {
      editable: true
    },
  }
}

export default {
  components: {
    GhostLoading,
    InputField,
    TemplateHtmlEditor,
    ComboBox,
    MailBriefVorlage,
  },
  props: {
  },
  data() {
    return {
      isStandardChanged: {},
      typeValues: [
        {value: 'BRIEF', label: 'Brief'},
        {value: 'BRIEF_MIT_DS', label: 'Brief mit Datenschutzerklärung'},
        {value: 'EMAIL', label: 'E-Mail'}
      ],

      ...newTemplate(),
    }
  },
  watch: {
    templateData(val) {
      if (val) {
        this.subject = val.subject;
        this.html = val.html;
        this.grant = val.grant;
        this.type = val.type;
        this.category = val.category;
        this.subjectOverwrite = val.subjectOverwrite;
        this.setHtmlText();
        this.template = val;
      }
    },
    templates(val) {
      if (val) {
        this.subject = {}
        this.html = {}
        val.map((t, i) => {
          this.subject[i] = t.template?.subject;
          this.html[i] = t.template?.html;
          this.isStandardChanged[i] = t.template?.changed;
        })
        this.setHtmlText();
      }
    },
  },
  computed: {
    ...mapGetters({
      isIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
      preview: TEMPLATES_EDIT_TYPES.GETTERS.GET_PREVIEW,
      letterTemplate: TEMPLATES_EDIT_TYPES.GETTERS.GET_LETTER_TEMPLATE_EDIT,
      wordTemplate: TEMPLATES_EDIT_TYPES.GETTERS.GET_WORD_TEMPLATE_EDIT,
      categories: TEMPLATES_EDIT_TYPES.GETTERS.GET_LETTER_CATEGORIES,
      templateData: MAILCOMPOSER_TYPES.GETTERS.GET_HTML_TEMPLATE,
      placeholders: MAILCOMPOSER_TYPES.GETTERS.GET_PLACEHOLDERS,
    }),
    grantValues() {
      const grantValues = [
        {value: 'NO', label: 'Keine Freigabe'},
        {value: 'STRUCTURE', label: 'Freigabe für Struktur'},
      ]

      if (this.isIntern) {
        grantValues.push({value: 'ALL', label: 'Freigabe für Alle Vermittler'});
      }

      return grantValues;
    },
    loadingTemplate() {
      return !this.letterTemplate || !this.wordTemplate;
    },
    categoryValues() {
      return this.categories && this.categories.map(v => ({
        value: v.key,
        label: v.label
      })) || []
    },
    id() {
      return this.$route.params.id !== 'new' ? this.$route.params.id : null;
    },
    headerActions() {
      const { id, } = this;

      const actions = [];
      actions.push(PageHeaderSimpleAction('SAVE', 'Speichern'));
      if(id) {
        actions.push(PageHeaderSimpleAction('REFRESH', 'Aktualisieren'));
      }
      return actions;
    },
  },
  methods: {
    setHtmlText() {
      this.isHtmlLoaded = false;
      this.$nextTick(() => {
        setTimeout(() => {
          this.isHtmlLoaded = true;
        }, 1);
      });
    },
    saveTemplates() {
      this.$store.dispatch(
        TEMPLATES_EDIT_TYPES.ACTIONS.SAVE_LETTER_TEMPLATE,
        {
          category: this.category,
          grant: this.grant || null,
          html: this.html,
          id: this.id,
          subject: this.subject,
          subjectOverwrite: this.subjectOverwrite,
          type: this.type,
        }
      ).then(() => {
        this.$router.push({ path: `../../mailvorlage` });
      })
    },
    onRefresh() {
      this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.GET_HTML_TEMPLATE, this.id);
    },
    
  },
 
  mounted() {
    if (this.id) {
      this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.GET_HTML_TEMPLATE, this.id);
    } else {
      Object.assign(this, newTemplate())
    }
    this.$store.dispatch(TEMPLATES_EDIT_TYPES.ACTIONS.GET_LETTER_CATEGORIES);
    this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.GET_SENDERS);
    this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.GET_PLACEHOLDERS, {insurance: true});
  },
  beforeRouteLeave(to, from, next) {
    next();
  },
  beforeDestroy() {
    this.$store.commit(TEMPLATES_EDIT_TYPES.MUTATIONS.GET_LETTER_TEMPLATE_EDIT, null);

    Object.assign(this, newTemplate())
    this.setHtmlText();
  },
}
</script>

<style scoped>
.top-buttons {
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
}
</style>
