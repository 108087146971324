var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showEditTable
    ? _c(
        "div",
        [
          _c("BaseDropdownMenu", {
            ref: "dropdown",
            attrs: { closeWithInnerClick: false, disabled: _vm.disabled },
            scopedSlots: _vm._u(
              [
                {
                  key: "hook-target",
                  fn: function() {
                    return [
                      _c("BaseToolbarButton", {
                        staticClass: "mr-0",
                        attrs: {
                          title: "Edit Table",
                          icon: _vm.icon,
                          disabled: _vm.disabled,
                          active: true
                        }
                      })
                    ]
                  },
                  proxy: true
                },
                {
                  key: "default",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "tb-buttons__container layout__negative-margin--8 d-flex"
                        },
                        [
                          _c("TBAddColumnBefore", {
                            attrs: {
                              showLabel: "",
                              editor: _vm.editor,
                              disabled: _vm.disabled
                            }
                          }),
                          _c("TBAddColumnAfter", {
                            attrs: {
                              showLabel: "",
                              editor: _vm.editor,
                              disabled: _vm.disabled
                            }
                          }),
                          _c("TBDeleteColumn", {
                            attrs: {
                              showLabel: "",
                              editor: _vm.editor,
                              disabled: _vm.disabled
                            }
                          }),
                          _c("hr", { staticClass: "divider" }),
                          _c("TBAddRowBefore", {
                            attrs: {
                              showLabel: "",
                              editor: _vm.editor,
                              disabled: _vm.disabled
                            }
                          }),
                          _c("TBAddRowAfter", {
                            attrs: {
                              showLabel: "",
                              editor: _vm.editor,
                              disabled: _vm.disabled
                            }
                          }),
                          _c("TBDeleteRow", {
                            attrs: {
                              showLabel: "",
                              editor: _vm.editor,
                              disabled: _vm.disabled
                            }
                          }),
                          _c("hr", { staticClass: "divider" }),
                          _c("TBToggleReaderRow", {
                            attrs: {
                              showLabel: "",
                              editor: _vm.editor,
                              disabled: _vm.disabled
                            }
                          }),
                          _c("TBMergeCells", {
                            attrs: {
                              showLabel: "",
                              editor: _vm.editor,
                              disabled: _vm.disabled
                            }
                          }),
                          _c("TBSplitCells", {
                            attrs: {
                              showLabel: "",
                              editor: _vm.editor,
                              disabled: _vm.disabled
                            }
                          }),
                          _c("TBDeleteTable", {
                            attrs: {
                              showLabel: "",
                              editor: _vm.editor,
                              disabled: _vm.disabled
                            }
                          }),
                          _c("hr", { staticClass: "divider" }),
                          _c("TBAddTable", {
                            attrs: {
                              showLabel: "",
                              forceShowAddTable: "",
                              editor: _vm.editor,
                              disabled: _vm.disabled
                            }
                          }),
                          _c("hr", { staticClass: "divider" }),
                          _c("TBTableBorderSize", {
                            attrs: {
                              showLabel: "",
                              editor: _vm.editor,
                              disabled: _vm.disabled
                            }
                          })
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              2585739159
            )
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }