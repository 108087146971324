<template>
  <BaseToolbarButton
    title="Align left"
    id="tb_align_left"
    :icon="icon"
    :active="active"
    :disabled="disabled"
    @click="action"
    content="Linksbündig ausrichten"
    v-tippy="{ arrow: true }"
  />
</template>

<script>
import { PhTextAlignLeft } from "phosphor-vue";
import BaseToolbarButton from "../base-components/BaseToolbarButton.vue";

export default {
  components: {
    BaseToolbarButton,
  },

  props: {
    editor: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    icon() {
      return PhTextAlignLeft;
    },
    active() {
      return this.editor?.isActive({ textAlign: "left" });
    },
  },

  methods: {
    action() {
      this.editor?.chain().focus().setTextAlign("left").run();
    },
  },
};
</script>
