var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.searchingPositions
    ? _c("div", [
        _vm._m(0),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [_c("FondsFinderSuchMaske")], 1)
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c("FondsFinderPositionsList", {
                attrs: {
                  zurueckButtonText: "Abbrechen",
                  addPositionsOnBack: false
                },
                on: {
                  goBack: function($event) {
                    return _vm.addFonds($event)
                  }
                }
              })
            ],
            1
          )
        ])
      ])
    : _c("div", { staticClass: "antrag-components__container" }, [
        _c(
          "div",
          { staticClass: "box__container" },
          [
            _c("InputRadioBoxGroup", {
              attrs: { values: _vm.allValues },
              model: {
                value: _vm.positionData.allePositionen,
                callback: function($$v) {
                  _vm.$set(_vm.positionData, "allePositionen", $$v)
                },
                expression: "positionData.allePositionen"
              }
            }),
            !_vm.positionData.all
              ? _c("Table", {
                  staticClass: "table-container",
                  attrs: {
                    tableId: "f168f001-d40a-4a9a-ba2b-aa8d3ce74e30",
                    tableData: _vm.tableData,
                    cardViewEnabled: false,
                    filterEnabled: false,
                    exportEnabled: false,
                    paginationEnabled: false
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "action",
                        fn: function(props) {
                          return [
                            props.data.key == "Anteile"
                              ? _c("InputField", {
                                  model: {
                                    value:
                                      _vm.positionData[
                                        "anteile" + props.data.row.index
                                      ],
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.positionData,
                                        "anteile" + props.data.row.index,
                                        $$v
                                      )
                                    },
                                    expression:
                                      "positionData['anteile' + props.data.row.index]"
                                  }
                                })
                              : _vm._e()
                          ]
                        }
                      },
                      {
                        key: "columnActions",
                        fn: function(props) {
                          return [
                            _c(
                              "BaseButton",
                              {
                                attrs: { isSecondary: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.deleteFond(
                                      props.data.row.index - 1
                                    )
                                  }
                                }
                              },
                              [_c("ph-trash", { attrs: { size: 16 } })],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    2610345691
                  )
                })
              : _vm._e(),
            !_vm.positionData.all
              ? _c(
                  "BaseButton",
                  {
                    on: {
                      click: function($event) {
                        _vm.searchingPositions = true
                      }
                    }
                  },
                  [_vm._v("Positionen hinzufügen")]
                )
              : _vm._e(),
            _c("FormDivider"),
            _c("InputToggleSwitch", {
              attrs: {
                label:
                  "Darüber hinaus erteile/n ich/wir Ihnen den Auftrag, das Konto abzuschließen und aufzulösen."
              },
              model: {
                value: _vm.positionData.kontoAufloesen,
                callback: function($$v) {
                  _vm.$set(_vm.positionData, "kontoAufloesen", $$v)
                },
                expression: "positionData.kontoAufloesen"
              }
            }),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("BaseButton", { on: { click: _vm.onBackButton } }, [
                    _vm._v("Zurück zum Formular")
                  ])
                ],
                1
              )
            ])
          ],
          1
        )
      ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("h2", [_vm._v("Wertpapiersuche")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }