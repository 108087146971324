<template>
	<div>
		<GhostLoading v-if="loading" class="box__container">
			<Block height="100" />
		</GhostLoading>              
		<div v-if="params && !loading" class="box__container">    
			<input-field 
				v-model="params.wertsteigerung" label='Berechnete Wertsteigerung p.a.' 
				:isComponentHalfSize="true"
				@change="triggerReload($event, true)"></input-field>
			<InputToggleSwitch v-model="inclJahrescourtage" inLineLabel 
				label="jeweilige Jahrescourtagen anzeigen (Jc)"
				@input="onChangeIncl"
				/>
			<ComboBox
				v-model="jahre"
				:isComponentHalfSize="true"
				:sortComboboxValues="false"
				:value="jahre"
				:values="modes"
				@change="onChangeMode"
			/> 
		</div>
	   
		<div class="box__container">
			<GhostLoading v-if="loading">
				<Block height="100" />
			</GhostLoading>
			<Table v-else-if="rows.length"
				title="Erwartete Courtage"
				:headers="headers"
				:rows="rows"
				rowId="Wertpapier"
				:rowsPerPage="25"
				:headerActions="headerActions"
				@headerAction-PLATTFORM="selectPlattform"
			/>
		</div>
		
    <BaseModal ref="showPlattform" 
      labelButtonConfirm="Speichern"
      labelButtonCancel="Abbrechen"
      @onConfirmButton="onChangePlattform">
      <template v-slot:modalTitle>
        Plattformen
      </template>
      <div>
				<Table
					title=""
					:headers="hdrPlatt"
					:rows="plattformen"
					rowId="lagerstelle"
					:rowsPerPage="25"
					v-model="lagerstellen"
				/>
      </div>
    </BaseModal>
	</div>      
</template>
<script>
import StepMixin from "./StepMixin";
import INVESTMENT_ADVICE from "@/store/investmentAdvice/types";
import BaseButton from '@/components/core/BaseButton.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import StepAllocation from "./StepAllocation";
import Table from "@/components/table2/Table.vue";
import {TextColumn, CurrencyColumn} from "@/components/table2/table_util.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import BaseModal from '@/components/core/BaseModal.vue';

export default {
	components: { BaseButton, InputToggleSwitch, ComboBox, Table, GhostLoading, Block, BaseModal },
	props: {},   
	data() {
		return {
			step: INVESTMENT_ADVICE.STEPS.COURTAGE,
			stepAllocation: [
				new StepAllocation(undefined, null),
			],
			inclJahrescourtage: false,
			jahre: 'J0',
			loading: true,
			courtageRows: [],
			headerActions: [PageHeaderSimpleAction('PLATTFORM', 'Plattform-Auswahl')],
			lagerstellen: [],
			plattformen: [],
			hdrPlatt: {
				lockedLeft: [TextColumn('lagerstelle', 'Lagerstelle'),],
				center: [],
				lockedRight: []
			}
		}
	},
	mixins: [StepMixin],
	computed: {
		params() {
			if (this.stepParameters?.data?.parameterData) {
			 return this.stepParameters.data.parameterData;
			}
			return {};
		},
		modes() {
			if (this.stepParameters?.data?.modes) {
			 return this.stepParameters.data.modes.map(item => ({value: 'J'+item.value, label: item.label}));
			}
			return [];
		},
		rows() {
			if (this.courtageRows.length) {
			 return this.courtageRows.filter( row => 'J' + row.accordance == this.jahre);
			}
			return [];
		},
		headers() {
			const headers = {
				lockedLeft: [
					TextColumn('type', ''),
					TextColumn('Wertpapier', 'Wertpapier'),
					CurrencyColumn('Anlagesumme', 'Anlagesumme / Bestand').withSumFooter(),
				],
				center: [],
				lockedRight: []
			}
			if (this.courtageRows.length) {
				this.courtageRows[0].lagerstellen.forEach(key => {
					headers.center.push(CurrencyColumn(key, key).withSumFooter() );
					if (this.inclJahrescourtage) {
						headers.center.push(CurrencyColumn(key + '_Jahrescourtage', key + '-Jc').withSumFooter() );
					}
				});
			}
			return headers;
		},
	},
	watch: {
		params() {
			if (this.params) {
				this.jahre = 'J' + this.params.mode;
				this.inclJahrescourtage = this.params.inclJahrescourtage;
				this.loading = false;
			}
			if (this.stepParameters?.data?.rows) {
				this.courtageRows = this.stepParameters.data.rows;
			}
			this.setPlttformen();
		},
	},
	methods: {
		parametersChanged(parameters) {
			if (parameters && parameters.data) {
				 this.stepAllocation[0].accordanceCondition = parameters.data.parameterData.mode;
			}
		},
		save(makePersist) {         
		},         
		async triggerReload(event, reset) {
			this.loading = true;
			const resp = await this.$store.dispatch(INVESTMENT_ADVICE.ACTIONS.GET_WERTSTEIGERUNG, {id: this.id, wertsteigerung: event });
			if (resp.data?.length) {
				this.courtageRows = resp.data;
			}
			this.loading = false;
		},
		setPlttformen() {
			if (this.stepParameters?.data?.fondsplattformList) {
				this.lagerstellen = this.stepParameters.data.fondsplattformList.filter(l => l[1] == '1').map(l => l[0]);
				this.plattformen = this.stepParameters.data.fondsplattformList.map(l => ({lagerstelle: l[0]}));
			}
		},
		onChangeMode(value) {
			this.params.mode = value.slice(1);
		},
		onChangeIncl(value) {
			this.params.inclJahrescourtage = value;
		},
		selectPlattform() {
			this.$refs.showPlattform.open();
		},
		async onChangePlattform(value) {
			const lgst = this.lagerstellen.map(l => l.lagerstelle)
			const platts = this.stepParameters.data.fondsplattformList;
			const lsSet = lgst.filter( ls => platts.find(p => p[0] == ls && p[1] == '0'));
			const lsReset = platts.filter(p => (p[1] == '1' && !lgst.find(ls => ls ==p[0]))).map(p => p[0]);
			const params = {wertsteigerung: this.params.wertsteigerung }
			if (lsSet.length) {
				params.lsSet = lsSet.join('\t');
			}
			if (lsReset.length) {
				params.lsReset = lsReset.join('\t');
			}
			this.courtageRows = [];
			const resp = await this.$store.dispatch(INVESTMENT_ADVICE.ACTIONS.SAVE_PLATTFORMEN, {id: this.id, params });
			if (resp.data.rows) {
				this.courtageRows = resp.data.rows;
				if (resp.data.fondsplattformList) {
					this.stepParameters.data.fondsplattformList = resp.data.fondsplattformList;
					this.setPlttformen();
				}
			}
		},
		updated() {
		}
	}
}
</script>

<style scoped>
</style>