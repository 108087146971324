<template>
<div>
    <Table
        :headers="headers"
        :rows="rows"
        v-bind="props"
        :selected="modelSelected ? selected : undefined"
        @selected="selected = $event"
        :scrollHorizontally="scrollHorizontally"
    >
        <template v-slot:text_input="row">
            <InputField
                :value="row.text"
                @input="updateRow(row, 'text', $event)"
            />
        </template>
        <template v-slot:custom="row">
            <ul v-if="row.custom">
                <li v-for="el in row.custom" :key="el">
                    {{el}}
                </li>
            </ul>
        </template>
    </Table>
    <template v-if="modelSelected">
        selected {{selected.length}} rows, namely: {{selected}}
    </template>
</div>
</template>

<script>
import Table from '@/components/table2/Table.vue';
import * as _table_util from "@/components/table2/table_util.js";
import sanitizeHtml from 'sanitize-html';
import viewedComponentMixin from '@/views/docs/componentViewer/viewed-component.js';
import InputField from "@/components/core/forms/InputField.vue";

const table_util = _table_util;

function Icon(...params) {
    return table_util.Icon(...params);
}
function ActionColumn(...params) {
    return table_util.ActionColumn(...params);
}
function CurrencyColumn(...params) {
    return table_util.CurrencyColumn(...params);
}
function DateColumn(...params) {
    return table_util.DateColumn(...params);
}
function DateTimeColumn(...params) {
    return table_util.DateTimeColumn(...params);
}
function HtmlColumn(...params) {
    return table_util.HtmlColumn(...params);
}
function IconColumn(...params) {
    return table_util.IconColumn(...params);
}
function NumberColumn(...params) {
    return table_util.NumberColumn(...params);
}
function PercentageColumn(...params) {
    return table_util.PercentageColumn(...params);
}
function PillColumn(...params) {
    return table_util.PillColumn(...params);
}
function SlotColumn(...params) {
    return table_util.SlotColumn(...params);
}
function TextColumn(...params) {
    return table_util.TextColumn(...params);
}


export default {
    mixins: [viewedComponentMixin],
    components: {
        Table,
        InputField,
    },
    props: {
        id: {
            type: String,
        },
    },
    data() {
        return {
            selected: [],
            scrollHorizontally: false,
            rows: [],
        }
    },
    computed: {
        headers() {
            if (!CODE.hasOwnProperty(this.id))
                return {};
            const headers = sanitizeHtml(CODE[this.id].headers, {allowedTags: []});
            return eval(`const result = {${headers}}; result`).headers;
        },
        props() {
            if (!CODE.hasOwnProperty(this.id))
                return {};
            return CODE[this.id].props;
        },
        modelSelected() {
            if (!CODE.hasOwnProperty(this.id))
                return {};
            return CODE[this.id].selected;
        },
    },
    mounted() {
        if (CODE.hasOwnProperty(this.id)) {
            const rows = CODE[this.id].rows;
            this.rows = eval(`const result = {${rows}}; result`).rows;
        }
    },
    methods: {
        updateRow(row, key, value) {
            const idKey = this.props?.rowId || "id";
            const r = this.rows.find(r => r[idKey] == row[idKey]);
            if (r != null) {
                r[key] = value;
            }
        },
    },
}

function ref(content, id) {
    return `<a id="${id}-code" href="#${id}-note">${content}</a>`;
}

export const CODE = {
    table1: {
        headers: `
            headers: {
                center: [
                    TextColumn("id", "ID"),
                    TextColumn("description", "Beschreibung"),
                ],
            },`,
        rows: `
            rows: [
                {id: 1, description: "asdf"},
                {id: 5, description: "this is the second row's description"},
                {id: 12, description: 12},
            ],`,
        props: {
            title: "Example Table",
        },
    },
    table2: {
        headers: `
            headers: {
                center: [
                    ${ref("NumberColumn", "t2_NumberColumn")}("id", "ID").${ref("alignLeft()", "t2_alignLeft")},
                    TextColumn("description", "Beschreibung"),
                    PercentageColumn("percent", "Prozent"),
                    CurrencyColumn("currency", "Währung"),
                    NumberColumn("precision", "Mehr Nachkommastellen", 3),
                ],
            },`,
        rows: `
            rows: [
                {id: 1, percent: 1, currency: 1, precision: 1, description: "1"},
                {id: 12, percent: 12, currency: 12, precision: 12, description: "12"},
                {id: 5, percent: 5, currency: 5, precision: 5, description: "5"},
                {id: 123456789, percent: 123456789, currency: 123456789, precision: 123456789, description: "123456789"},
                {id: 3.141592, percent: 3.141592, currency: 3.141592, precision: 3.141592, description: "3.141592"},
            ],`,
        props: {
            hidePagination: true,
        },
    },
    table3: {
        headers: `
            headers: {
                center: [
                    NumberColumn("id", "ID").alignLeft(),
                    DateColumn("date", "Datum", null, 1).alignLeft(),
                    DateTimeColumn("dateTime", "Datum + Zeit", null, 1),
                    TextColumn("description", "Beschreibung"),
                ],
            },`,
        rows: `
            rows: [
                {id: 6, date: "12.12.2000 12:31", dateTime: "12.12.2000 12:31", description: "Both values are entered as String, so the DateColumn shows the full value."},
                {id: 5, date: "12.12.2000", dateTime: "12.12.2000", description: "Both values are entered as String, so the DateTimeColumn doesn't have the time!"},
                {id: 1, date: new Date(), dateTime: new Date(), description: "Values constructed by new Date()"},
                {id: 8, date: null, dateTime: null, description: "Values are null"},
                {id: -1, date: 1673513527758, dateTime: 1673513527758, description: "Values are the number 1673513527758"},
                {id: "test", date: "test", dateTime: "test", description: "Values are the string 'test'"},
                {id: 12, date: Date.parse('04 Dec 1995 00:12:00 GMT'), dateTime: Date.parse('04 Dec 1995 00:12:00 GMT'), description: "Values are constructed from Date.parse"},
            ],`,
        props: {
            hidePagination: true,
        },
    },
    table_tableId: {
        headers: `
            headers: {
                lockedLeft: [
                    TextColumn("lockedLeft", "Locked Left"),
                ],
                center: [
                    TextColumn("left", "Second by default"),
                    TextColumn("hidden", "Hidden by default").makeHidden(),
                    TextColumn("right", "Third by default"),
                ],
                lockedRight: [
                    TextColumn("lockedRight", "Locked Right"),
                ],
            },`,
        rows: `
            rows: [
                {lockedLeft: 1, left: 2, hidden: "spooky", right: 3, lockedRight: 4},
                {lockedLeft: 2, left: 2, hidden: "spooky", right: 3, lockedRight: 4},
                {lockedLeft: 3, left: 2, hidden: "spooky", right: 3, lockedRight: 4},
                {lockedLeft: 4, left: 2, hidden: "spooky", right: 3, lockedRight: 4},
                {lockedLeft: 5, left: 2, hidden: "spooky", right: 3, lockedRight: 4},
                {lockedLeft: 6, left: 2, hidden: "spooky", right: 3, lockedRight: 4},
                {lockedLeft: 7, left: 2, hidden: "spooky", right: 3, lockedRight: 4},
                {lockedLeft: 8, left: 2, hidden: "spooky", right: 3, lockedRight: 4},
                {lockedLeft: 9, left: 2, hidden: "spooky", right: 3, lockedRight: 4},
                {lockedLeft: 10, left: 2, hidden: "spooky", right: 3, lockedRight: 4},
            ],`,
        props: {
            title: "Click on this icon ->",
            tableId: "generate id using https://www.uuidgenerator.net/",
            hidePagination: true,
        },
    },
    table_rowsPerPage: {
        headers: `
            headers: {
                center: [
                    NumberColumn("id", "ID"),
                    NumberColumn("page", "Should be on page"),
                ],
            },`,
        rows: `
            rows: [
                {id: 1, page: 1},
                {id: 2, page: 1},
                {id: 3, page: 1},
                {id: 4, page: 1},
                {id: 5, page: 1},
                {id: 6, page: 2},
                {id: 7, page: 2},
                {id: 8, page: 2},
                {id: 9, page: 2},
                {id: 10, page: 2},
                {id: 11, page: 3},
                {id: 12, page: 3},
            ],`,
        props: {
            rowsPerPage: 5,
        },
    },
    table_selected: {
        headers: `
            headers: {
                center: [
                    TextColumn("comment", "Comment"),
                    NumberColumn("id", "ID").alignLeft(),
                    NumberColumn("page", "Should be on page"),
                ],
            },`,
        rows: `
            rows: [
                {id: 1, page: 1},
                {id: 2, page: 1},
                {id: 3, page: 1, selectedHidden: true, comment: "can't be selected"},
                {id: 4, page: 1, selectedDisabled: true, comment: "can't be selected either"},
                {id: 5, page: 1},
                {id: 6, page: 2},
                {id: 7, page: 2},
                {id: 8, page: 2},
                {id: 9, page: 2},
                {id: 10, page: 2},
                {id: 11, page: 3},
                {id: 12, page: 3},
            ],`,
        selected: true,
        html_post: `\n\nselected {{selected.length}} rows, namely: {{selected}}`,
        javascript_post: `\n            selected: []`,
        props: {
            rowsPerPage: 5,
        },
    },
    table_scroll_horizontally: {
        headers: `
            headers: {
                lockedLeft: [
                    TextColumn("lockedLeft", "Locked on the left", 999),
                ],
                center: [
                    NumberColumn("id", "ID", 999).alignLeft(),
                    TextColumn("comment", "Kommentar", 999),
                    DateColumn("date", "Datum", 999, 1).alignLeft(),
                    DateTimeColumn("dateTime", "Datum + Zeit", 999, 1),
                    CurrencyColumn("profit", "Profit", undefined, 999),
                    TextColumn("filler", "Filler", 999),
                    PercentageColumn("roi", "Return on Investment", undefined, 999),
                ],
                lockedRight: [
                    TextColumn("lockedRight", "Locked on the right", 999),
                ],
            },`,
        rows: `
            rows: [
                {id: 6, comment: "important!", profit: 12345, roi: 10.2, filler: "Text to waste space", date: "12.12.2000 12:31", dateTime: "12.12.2000 12:31", lockedLeft: "left", lockedRight: "right"},
                {id: 1, comment: "ignore", profit: 9876.54, roi: 3.4, filler: "Even more text to waste space. Bla bla bla bla bla bla bla bla bla bla bla bla bla", date: new Date(), dateTime: new Date()},
            ],`,
        props: {
            scrollHorizontally: "scrollHorizontally",
            hidePagination: true,
        },
    },
    table_toggle_scrolling: {
        headers: `
            headers: {
                lockedLeft: [
                    TextColumn("lockedLeft", "Locked on the left", 999),
                ],
                center: [
                    NumberColumn("id", "ID", 999).alignLeft(),
                    TextColumn("comment", "Kommentar", 999),
                    DateColumn("date", "Datum", 999, 1).alignLeft(),
                    DateTimeColumn("dateTime", "Datum + Zeit", 999, 1),
                    CurrencyColumn("profit", "Profit", undefined, 999),
                    TextColumn("filler", "Filler", 999),
                    PercentageColumn("roi", "Return on Investment", undefined, 999),
                ],
                lockedRight: [
                    TextColumn("lockedRight", "Locked on the right", 999),
                ],
            },`,
        rows: `
            rows: [
                {id: 6, comment: "important!", profit: 12345, roi: 10.2, filler: "Text to waste space", date: "12.12.2000 12:31", dateTime: "12.12.2000 12:31", lockedLeft: "left", lockedRight: "right"},
                {id: 1, comment: "ignore", profit: 9876.54, roi: 3.4, filler: "Even more text to waste space. Bla bla bla bla bla bla bla bla bla bla bla bla bla", date: new Date(), dateTime: new Date()},
            ],`,
        props: {
            enableToggleHorizontalScrollingConfig: true,
            hidePagination: true,
        },
    },
    table_maxHeaderLines: {
        headers: `
            headers: {
                center: [
                    TextColumn("id", "This is a header that's way longer than necessary. Good thing it can take up 3 lines."),
                    TextColumn("description", "This header is long too! But not as long as the first one.").addCellProps({lineClamp: 4}),
                    TextColumn("description2", "This header is long too! But not as long as the first one."),
                    TextColumn("description3", "This header is long too! But not as long as the first one."),
                ],
            },`,
        rows: `
            rows: [
                {id: 1, description: "asdf"},
                {id: 5, description: "This cell's text can go over multiple lines as well thanks to its column's cell-prop 'lineClamp'"},
                {id: 12, description: 12},
            ],`,
        props: {
            maxHeaderLines: 3,
            hidePagination: true,
        },
    },
    table_html: {
        headers: `
            headers: {
                center: [
                    HtmlColumn("html", "HTML"),
                ],
            },`,
        rows:
"\n            rows: [\n" +
'                {html: "<b>Every <i>word</i></b> is <u>different</u>"},\n' +
'                {html: "XSS attempt via script -> <script>alert(1)<\/script>"},\n' +
'                {html: "XSS attempt via broken image -> <img src=\'/\' onerror=\'alert(1)\'/>"},\n' +
'            ],',
        props: {
            hidePagination: true,
        },
    },
    table_icons: {
        headers: `
            headers: {
                center: [
                    IconColumn("icon", "Icon"),
                    TextColumn("description", "Beschreibung"),
                ],
            },`,
        rows: `
            rows: [
                {icon: Icon("PhFile", "Datei"), description: "one simple icon"},
                {icon: [
                    Icon("PhFile", "Datei"),
                    Icon("PhDownload", "Download")
                ], description: "multiple icons"},
                {icon: null, description: "no icons"},
                {icon: [
                    Icon("PhFile", "Datei", 15),
                    Icon("PhFile", "Datei", undefined, "fill"),
                    Icon("PhFile", "Datei", undefined, undefined, "var(--color-danger)")
                ], description: "different size, weight and class"},
            ],`,
        props: {
            hidePagination: true,
        },
    },
    table_pill: {
        headers: `
            headers: {
                center: [
                    PillColumn("pill", "Pill"),
                    TextColumn("description", "Beschreibung"),
                ],
            },`,
        rows: `
            rows: [
                {pill: {label: "Fehler", type: "danger"}, description: "One row for each pill type"},
                {pill: {label: "Info", type: "info"}, description: "One row for each pill type"},
                {pill: {label: "Standard", type: "primary"}, description: "One row for each pill type"},
                {pill: {label: "Erfolg", type: "success"}, description: "One row for each pill type"},
                {pill: {label: "Fehler", type: "warning"}, description: "One row for each pill type"},
                {pill: null, description: "No Pill"},
            ],`,
        props: {
            hidePagination: true,
        },
    },
    table_slot: {
        headers: `
            headers: {
                center: [
                    NumberColumn("id", "ID").alignLeft(),
                    // This column can be sorted alphabetically
                    SlotColumn("text_input", "Text Input", 300, 1).makeSortable(function (_, row) {return table_util.textToSortable(row.text)}),
                    TextColumn("text", "Text"),
                    SlotColumn("custom", "Custom Content", 200, 1),
                ],
            },`,
        rows: `
            rows: [
                {id: 0, text: "The text this row starts with.", custom: ["you can also", "put arbitrary"]},
                {id: 1, text: "Changes are visible instantly because '@input' is used.", custom: ["html or vue components", "into slots."]},
                {id: 2, text: "These changes are applied in the parent-component because rows aren't v-model."},
            ],`,
        props: {
            hidePagination: true,
            rowId: "id",
        },
        slot: `
    &lt;template v-slot:text_input="row">
        &lt;InputField
            :value="row.text"
            @input="updateRow(row, 'text', $event)"
        />
    &lt;/template>
    &lt;template v-slot:custom="row">
        &lt;ul v-if="row.custom">
            &lt;li v-for="el in row.custom" :key="el">
                {{el}}
            &lt;/li>
        &lt;/ul>
    &lt;/template>
`,
        javascript_post: `
            ...
            updateRow(row, key, value) {
                const idKey = this.props?.rowId || "id";
                const r = this.rows.find(r => r[idKey] == row[idKey]);
                if (r != null) {
                    // don't modify the row given from the table, it's just a copy of your data!
                    r[key] = value;
                }
            },
`,
    },
};
</script>

<style scoped>
::v-deep .red {
    color: var(--color-danger);
}
</style>
