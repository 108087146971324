var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "confirmed-2fa-message_container" }, [
      _c("div", { staticClass: "my-4" }, [
        _c("p", [_vm._v("Die Bestätigung Ihrer Anmeldung ist abgeschlossen.")]),
        _c("p", [
          _vm._v(
            "Bitte kehren Sie zu dorthin zurück wo Sie Ihre Anmeldung begonnen haben."
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }