<template>
  <div class="box__container">
    <div class="box__title">Beruf</div>
    <div class="row">
      <div class="col-12">
        <ComboBox label="Beruf" 
          :labelClass="depoteroeffnungenLabelClass"
          :isComponentHalfSize="true"
          :values="professions"
          v-model="employment.profession"
          :disabled="!isEditable"
          :firstEmpty="true"
          @change="addCustomerDataEditedCombo('employment', 'profession', $event)"/>

        <InputField label="Branche" 
          :labelClass="depoteroeffnungenLabelClass"
          :isComponentHalfSize="true"
          v-model="employment.industry"
          :disabled="!isEditable"
          @change="addCustomerDataEdited('employment')"/>

        <ComboBox label="Arbeitsverhältnis" 
          :isComponentHalfSize="true"
          :values="customerDataConfig.employmentStates"
          v-model="employment.workingCondition"
          :disabled="!isEditable"
          :firstEmpty="true"
          @change="addCustomerDataEditedCombo('employment', 'workingCondition', $event); handleWorkingConditionChange($event)"/>

        <InputField label="Firma" 
          :isComponentHalfSize="true"
          v-if="customerData && customerData.personType && customerData.personType === 'Kontakt'"
          v-model="employment.company"
          :disabled="!isEditable"
          @change="addCustomerDataEdited('employment', 'company', $event)"/>
      </div>
    </div>
  </div>
</template>

<script>
import CUSTOMERDATA_TYPES from '@/store/customerData/types';
import { mapGetters } from 'vuex';
import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';

import persoenlicheDatenMixin from '../persoenliche-daten-mixin';


export default {
  mixins: [persoenlicheDatenMixin],
  data() {
    return {
      ...this.initialState(),
    };
  },
  computed: {
    ...mapGetters({
      customerData: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_DATA,
    }),
    professions() {
      const professions = this.customerDataConfig?.professions || [];
      const profession = this.employment?.profession || '';
      if(profession.trim() && !professions.includes(profession)) {
        return [...professions, profession];
      }
      return [...professions];
    },
  },
  methods: {
    initialState() {
      return {
        employment: {
          profession: '',
          industry: '',
          workingCondition: '',
        },
      };
    },
    updateGroupsIntoData() {
      this.updateGroupIntoData('employment');
    },
    handleWorkingConditionChange(value) {
      if(this.customerDataConfig?.employmentRelationshipDefault?.includes(value)) {
        if(!this.employment?.profession?.trim()) {
          this.employment.profession = value;
        }
        if(!this.employment?.industry?.trim()) {
          this.employment.industry = value;
        }

        this.addCustomerDataEdited('employment');
      }
    },
  },
  mounted() {
    this.updateGroupsIntoData();
  },
  components: {
    InputField,
    ComboBox,
  },
}
</script>
