export const MUTATION_PREFIX = 'EINGEGANGENEDOKUMENTE_MUTATIONS_';
export const ACTIONS_PREFIX = 'EINGEGANGENEDOKUMENTE_ACTIONS_';
export const GETTERS_PREFIX = 'EINGEGANGENEDOKUMENTE_GETTERS_';

export default {
  MUTATIONS: {
    UPDATE_EINGEGANGENES_DOKUMENT_RESULT: MUTATION_PREFIX + 'UPDATE_EINGEGANGENES_DOKUMENT_RESULT',
    UPDATE_EINGEGANGENE_DOKUMENTE: MUTATION_PREFIX + 'UPDATE_EINGEGANGENE_DOKUMENTE',
    UPDATE_SIGNATURES: MUTATION_PREFIX + 'UPDATE_SIGNATURES',
  },
  ACTIONS: {
    GET_EINGEGANGENE_DOKUMENTE: ACTIONS_PREFIX + 'GET_EINGEGANGENE_DOKUMENTE',
    GET_EINGEGANGENES_DOKUMENT_RESULT: ACTIONS_PREFIX + 'GET_EINGEGANGENES_DOKUMENT_RESULT',
    GET_SIGNATURES: ACTIONS_PREFIX + 'GET_SIGNATURES',
    POST_EINGEGANGENES_DOKUMENT: ACTIONS_PREFIX + 'POST_EINGEGANGENES_DOKUMENT',
  },
  GETTERS: {
    EINGEGANGENES_DOKUMENT_RESULT: GETTERS_PREFIX + 'EINGEGANGENES_DOKUMENT_RESULT',
    EINGEGANGENE_DOKUMENTE: GETTERS_PREFIX + 'EINGEGANGENE_DOKUMENTE',
    SIGNATURES: GETTERS_PREFIX + 'SIGNATURES',
  }
}