import LOG_TYPES from './types';
import Vue from 'vue'
import { getInitialState } from './index'

export default {
  [LOG_TYPES.MUTATIONS.RESET_STATE](state, wipeVisibleMessages) {
    // keep messages and history messages when reseting state
    const messages = state.messages
    const historyMessages = state.historyMessages

    Object.assign(state, getInitialState())

    if (!wipeVisibleMessages) {
      Vue.set(state, 'messages', messages);
    }
    
    Vue.set(state, 'historyMessages', historyMessages);
  },

  [LOG_TYPES.MUTATIONS.ADD_LOG](state, payload) {
    state.logs.push(payload);
  },

  [LOG_TYPES.MUTATIONS.ADD_MESSAGE](state, payload) {
    state.messages.push(payload);
    state.historyMessages.push(payload);
  },

  [LOG_TYPES.MUTATIONS.REMOVE_MESSAGE](state, payload) {
    state.messages = state.messages.filter(m => m.id !== payload.id);
  },
}