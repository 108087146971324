import QUARTALSBERICHTE_BESTELLUNGEN_TYPES from './types';
import CORE_TYPES from '../core/types';
import axios from 'axios';
import { buildMessage, } from '../../helpers/log-message-helper';
import * as utils from '../../helpers/commonfunctions';

export default {
  [QUARTALSBERICHTE_BESTELLUNGEN_TYPES.ACTIONS.RETRIEVE_BESTELLUNGEN_DATA]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/customerFilters/getBestellungenData';
      const bestellungModus = payload?.bestellungModus;
      const seqParam = payload?.seq ? '&seq='+payload.seq : '';
      serviceUrl = `${serviceUrl}?bestellungModus=${bestellungModus}${seqParam}`;
  
      axios.post(rootState.core.apiAddress + serviceUrl, payload?.customers, config).then(response => {
        if (response && response.data) {
  
          commit(QUARTALSBERICHTE_BESTELLUNGEN_TYPES.MUTATIONS.RETRIEVE_BESTELLUNGEN_DATA_SUCCESS, response.data);
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });



  },

  [QUARTALSBERICHTE_BESTELLUNGEN_TYPES.ACTIONS.EXECUTE_ORDERS]({ getters, dispatch, commit, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/customerFilters/executeOrders';

      const bestellungModus = payload?.bestellungModus;

      if (bestellungModus) {
        serviceUrl = `${serviceUrl}?bestellungModus=${bestellungModus}`;
      }

      const inputStand = payload?.inputStand;

      if (inputStand) {
        serviceUrl = `${serviceUrl}&inputStand=${inputStand}`;
      }

      axios.post(rootState.core.apiAddress + serviceUrl, payload.customers, config)
        .then((response) => {
          commit(QUARTALSBERICHTE_BESTELLUNGEN_TYPES.MUTATIONS.EXECUTE_ORDERS_SUCCESS, response.data);
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },  


  

}