import PORTFOLIO_TYPES from './types';

export default {
  [PORTFOLIO_TYPES.GETTERS.DEPOT_CONFIG](state) {
    return state.config;
  },
  [PORTFOLIO_TYPES.GETTERS.REALISED_PROFIT](state) {
    return state.realisedProfit;
  },
  [PORTFOLIO_TYPES.GETTERS.HISTORICAL_PERFORMANCE](state) {
    return state.historicalPerformance;
  },
  [PORTFOLIO_TYPES.GETTERS.PERFORMANCE_FILTERED](state) {
    return state.performanceFiltered;
  },
  [PORTFOLIO_TYPES.GETTERS.IS_LOADED](state) {
    return state.isLoaded;
  },
  [PORTFOLIO_TYPES.GETTERS.HISTORICAL_PERFORMANCE_DETAILS](state) {
    return state.historicalPerformanceDetails;
  },
}