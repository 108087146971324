var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.action
        ? _c(
            "BaseButton",
            {
              attrs: {
                disabled:
                  _vm.orderDisabled || _vm.loading || _vm.orderInitialized
              },
              on: {
                click: function($event) {
                  return _vm.toOrder(2)
                }
              }
            },
            [_vm._v(_vm._s(_vm.action))]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }