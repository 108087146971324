<template>
  <div>
    <div class="box__container" tid="973fc88f-1075-4ce9-8363-667e6301b508">
        <div v-if="loading || !rows.length" class="box__title">
            {{title}}
        </div>
        <Table v-if="!loading && rows.length"
            :tableId="tableConfigId"
            :title="title"
            :headers="headers"
            :rows="rows"
            :mobileConfig="{title: 'gesellschaft', headers: ['jahr', 'nummer', 'rw', ]}"
            rowId="index"
            hidePagination
        >
            <template #gesellschaft="row">
                <div class="insurance__table--company" v-if="!row.mobileTableContext">
                    <BaseImage v-if="row.gesellschaft.logoUrl"
                        class="insurance__table--company__logo"
                        :src="`${apiAddressLegacy}/${row.gesellschaft.logoUrl}`"
                        :placeholder="imagePlaceholder"
                    />
                    {{ row.gesellschaft.zweigstelleName || row.gesellschaft.shortName }}
                </div>
              <div v-else>{{row.gesellschaft.zweigstelleName || row.gesellschaft.shortName}}</div>
            </template>
        </Table>
        <GhostLoading v-else-if="loading" type="table" />
        <NoData v-else />
    </div>

  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types'
import { mapGetters } from 'vuex'
import Table from "@/components/table2/Table.vue";
import {TextColumn, SlotColumn, CurrencyColumn} from "@/components/table2/table_util.js";
import BaseImage from '@/components/core/BaseImage.vue'
import { parse } from '@/helpers/number-formatter.js'
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import BALANCE_SHEET_TYPES from '@/store/balance-sheet/types';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';

export default {
  mixins: [InteractiveHelpCommonsMixin],
  props: {
    showOnlyRueckkaufswert: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Versicherungen'
    },
    tableConfigId: {
      type: String,
      default: 'b8e1ebca-7651-4e2c-8f1b-4335e90070df'
    }
  },
  data () {
    return {
        id: 'ALLE_DEPOTS_ANZEIGEN',
        loading: false,
    };

  },
  computed: {
    ...mapGetters({
      insuranceTable: BALANCE_SHEET_TYPES.GETTERS.INSURANCE_TABLE,
      apiAddressLegacy: CORE_TYPES.GETTERS.API_ADDRESS_LEGACY
    }),
    imagePlaceholder() {
      return {
        width: '40px',
        height: '24px',
      }
    },
    vorsorgeRecords() {
      const vorsorgeList = this.insuranceTable?.data?.records?.filter?.(record => record[1] === 'VORSORGE')

      if (!this.showOnlyRueckkaufswert) {
        return vorsorgeList
      }

      return vorsorgeList?.filter?.(record => parse(record[this.columnIndex.rueckkaufswert]) > 0 && !record[this.columnIndex.fondswert]);
    },
    headers() {
        const headers = {
            lockedLeft: [
                SlotColumn("gesellschaft", "Gesellschaft", 200, 1),
            ],
            center: [
                TextColumn("nummer", "Nummer"),
                TextColumn("jahr", "Jahr"),
                CurrencyColumn("fondswert", "Fondswert"),
            ],
            lockedRight: [
                CurrencyColumn("rw", "Rückkaufswerte").withSumFooter(),
            ]
        };
        if (this.showOnlyRueckkaufswert)
            headers.center[2].makeHidden();
        else
            headers.center[1].makeHidden();
        return headers;
    },
    rows() {
        if (!this.insuranceTable?.data?.tableHeaders)
            return [];
        // TODO: the parent component should calculate this on its own. computed should not emit
        this.$emit('totalAmount', this.totalAmount)

        return (this.vorsorgeRecords || []).map((record, index) => ({
            gesellschaft: record[this.columnIndex.gesellschaft],
            nummer: record[this.columnIndex.nummer],
            praemie: record[this.columnIndex.praemie],
            zw: record[this.columnIndex.zw],
            fondswert: record[this.columnIndex.fondswert],
            jahr: record[this.columnIndex.jahr],
            rw: parse(record[this.columnIndex.rueckkaufswert]),
            index,
        }));
    },
    columnIndex() {
      if (!this.insuranceTable?.data?.tableHeaders) {
        return {}
      }

      const headers = this.insuranceTable.data.tableHeaders

      const nummer = this.indexOfRow(headers, 'nummer');
      const gesellschaft = this.indexOfRow(headers, 'gesellschaft');
      const zw = this.indexOfRow(headers, 'zw');
      const praemie = this.indexOfRow(headers, 'praemie');
      const fondswert = this.indexOfRow(headers, 'Fondswert');
      const jahr = this.indexOfRow(headers, 'jahr');
      const rueckkaufswert = this.indexOfRow(headers, 'rueckkaufswert');

      return {
        nummer,
        gesellschaft,
        zw,
        praemie,
        fondswert,
        jahr,
        rueckkaufswert,
      }
    },
    totalAmount() {
      const totalAmount = this.vorsorgeRecords?.map(record => record[this.columnIndex.fondswert] + parse(record[this.columnIndex.rueckkaufswert]))
        .reduce((accu, curr) => accu + curr, 0)

      return totalAmount
    }, 
  },
  mounted: function() {
    this.getInsuranceTable();
  },
  methods: {
    async getInsuranceTable() {
      try {
        this.loading = true;
        await this.$store.dispatch(BALANCE_SHEET_TYPES.ACTIONS.GET_INSURANCE_TABLE, {id: this.id});
      } finally {
        this.loading = false;
      }
    },
    indexOfRow(items, name) {
      return items.findIndex(item => item.key === name);
    },
    openModalEditVersicherungenResultTable() {
      this.$refs.tableResult.openColumnsConfig()
    },
  },
  components: {
    Table,
    BaseImage,
    GhostLoading,
    NoData,
  }

}
</script>

<style scoped>
.insurance__table--company {
  display: flex;
  align-items: center;
}
.insurance__table--company__logo {
  margin: 0 8px 0 0;
  max-width: 40px;
  height: auto;
}

.space-between {
  justify-content: space-between;
}
.flex-item {
  flex: 1 1 auto;
}

.text-center {
  text-align: center;
}
</style>
