var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("div", { staticClass: "box__title" }, [_vm._v("Vertragsdaten")]),
        !_vm.isFA
          ? [
              _c("InputField", {
                attrs: {
                  label: "Bezeichnung der Dienstleistung",
                  isComponentHalfSize: ""
                },
                on: {
                  change: function($event) {
                    return _vm.dataChanged()
                  }
                },
                model: {
                  value: _vm.feePropertiesForm.bezeichnungHonoratyp,
                  callback: function($$v) {
                    _vm.$set(_vm.feePropertiesForm, "bezeichnungHonoratyp", $$v)
                  },
                  expression: "feePropertiesForm.bezeichnungHonoratyp"
                }
              }),
              _c("InputRadioBoxGroup", {
                attrs: {
                  title: "Rechnungsbeträge",
                  values: _vm.feePropertiesCombos.taxType,
                  isComponentHalfSize: ""
                },
                on: {
                  input: function($event) {
                    return _vm.dataChanged()
                  }
                },
                model: {
                  value: _vm.feePropertiesForm.taxType,
                  callback: function($$v) {
                    _vm.$set(_vm.feePropertiesForm, "taxType", $$v)
                  },
                  expression: "feePropertiesForm.taxType"
                }
              })
            ]
          : _vm._e(),
        _c("InputField", {
          attrs: {
            label: "MwSt. Satz",
            type: "percent",
            isComponentHalfSize: "",
            validateUntouched: ""
          },
          on: {
            change: function($event) {
              return _vm.dataChanged()
            }
          },
          model: {
            value: _vm.feePropertiesForm.taxPercentage,
            callback: function($$v) {
              _vm.$set(_vm.feePropertiesForm, "taxPercentage", $$v)
            },
            expression: "feePropertiesForm.taxPercentage"
          }
        }),
        _c("InputToggleSwitch", {
          attrs: {
            label: "Versand der Abrechnung per E-Mail",
            inLineLabel: ""
          },
          on: {
            input: function($event) {
              return _vm.dataChanged()
            }
          },
          model: {
            value: _vm.feePropertiesForm.sendPerEmail,
            callback: function($$v) {
              _vm.$set(_vm.feePropertiesForm, "sendPerEmail", $$v)
            },
            expression: "feePropertiesForm.sendPerEmail"
          }
        }),
        _vm.isCustomer
          ? [
              _c("DatePickerField", {
                attrs: {
                  label: "Vertragsdatum",
                  isValueAsString: "",
                  isComponentHalfSize: "",
                  validateUntouched: ""
                },
                on: {
                  change: function($event) {
                    return _vm.dataChanged()
                  }
                },
                model: {
                  value: _vm.feePropertiesForm.contractDate,
                  callback: function($$v) {
                    _vm.$set(_vm.feePropertiesForm, "contractDate", $$v)
                  },
                  expression: "feePropertiesForm.contractDate"
                }
              })
            ]
          : _vm._e(),
        !_vm.isCustomer && (_vm.istMargitLang || _vm.isFA)
          ? [
              _c("InputField", {
                attrs: {
                  label: "nächste Rechnungsnummer",
                  type: "number",
                  precision: 0,
                  isComponentHalfSize: ""
                },
                on: {
                  change: function($event) {
                    return _vm.dataChanged()
                  }
                },
                model: {
                  value: _vm.feePropertiesForm.nextInvoiceNr,
                  callback: function($$v) {
                    _vm.$set(_vm.feePropertiesForm, "nextInvoiceNr", $$v)
                  },
                  expression: "feePropertiesForm.nextInvoiceNr"
                }
              })
            ]
          : _vm._e(),
        _c("InputField", {
          attrs: {
            label: "Abrechnungsperioden (in Monaten)",
            type: "number",
            precision: 0,
            isComponentHalfSize: "",
            validateUntouched: ""
          },
          on: {
            change: function($event) {
              return _vm.dataChanged()
            }
          },
          model: {
            value: _vm.feePropertiesForm.period,
            callback: function($$v) {
              _vm.$set(_vm.feePropertiesForm, "period", $$v)
            },
            expression: "feePropertiesForm.period"
          }
        }),
        _vm.isCustomer
          ? [
              _vm.istMargitLang
                ? _c("DatePickerField", {
                    attrs: {
                      label: "Vertragsdatum 2",
                      isValueAsString: "",
                      isComponentHalfSize: ""
                    },
                    on: {
                      change: function($event) {
                        return _vm.dataChanged()
                      }
                    },
                    model: {
                      value: _vm.feePropertiesForm.contractDate2,
                      callback: function($$v) {
                        _vm.$set(_vm.feePropertiesForm, "contractDate2", $$v)
                      },
                      expression: "feePropertiesForm.contractDate2"
                    }
                  })
                : _vm._e(),
              _c("DatePickerField", {
                attrs: {
                  label: "Beginn Abrechnungsperiode",
                  isValueAsString: "",
                  isComponentHalfSize: "",
                  validateUntouched: ""
                },
                on: {
                  change: function($event) {
                    return _vm.dataChanged()
                  }
                },
                model: {
                  value: _vm.feePropertiesForm.beginDate,
                  callback: function($$v) {
                    _vm.$set(_vm.feePropertiesForm, "beginDate", $$v)
                  },
                  expression: "feePropertiesForm.beginDate"
                }
              }),
              _c("DatePickerField", {
                attrs: {
                  label: "Ende Abrechnungsperiode",
                  isValueAsString: "",
                  disabled: "",
                  isComponentHalfSize: ""
                },
                on: {
                  change: function($event) {
                    return _vm.dataChanged()
                  }
                },
                model: {
                  value: _vm.endDate,
                  callback: function($$v) {
                    _vm.endDate = $$v
                  },
                  expression: "endDate"
                }
              })
            ]
          : _vm._e()
      ],
      2
    ),
    _vm.isCustomer
      ? _c(
          "div",
          { staticClass: "box__container" },
          [
            _c("FeeAbrechnungenTable", {
              attrs: { title: "Erstellte Abrechnungen" }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }