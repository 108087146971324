<template>
	<CoreCard ref="coreCard" :headers="headers" :rows="rows" :showToolbar="showToolbar" :listView="listView">
		<template v-if="showTitle" v-slot:header>
			{{chartName}}
		</template>
		<template v-slot:alternative>
		</template>
    <div class="content">
      <div v-if="isContainerRendered">
        <div class="legend" v-if="labels">
          <div v-for="(item, index) of labels" class="legend-item" :key="index" :style="{color: getColor(index)}">
            &bull; {{item}}
          </div>
        </div>
        <Chart :data="chartDataEcharts" 
          :xlabels="chartXLabels" 
          type="stacked-bar" 
          :isDataLabels="false" 
          :isLegend="false" 
          :numberFixed="0" 
          :format="valueFormat" 
          :tooltip="tooltip()" 
          :customColors="customColors">
        </Chart>
      </div>
    </div>
	</CoreCard>
</template>

<script>
import CoreCard from '../CoreCard/CoreCard'
import Chart from '../../charts/Chart.vue'
import { getColorsMixedContrastDecreasing } from '@/helpers/colors-helper';
import cardMixin from '../CoreCard/card-mixin';
import CORE_TYPES from '@/store/core/types';
import {mapGetters} from 'vuex';
import {TextColumn, CurrencyColumn, NumberColumn} from "@/components/table2/table_util.js";

export default {
  mixins: [cardMixin],

  data: function() {
    return {
      isContainerRendered: false,
      labels: [],
      colorsApex: getColorsMixedContrastDecreasing(),
    };
  },

  computed: {
    ...mapGetters({
      userid: CORE_TYPES.GETTERS.GET_USER_ID,
    }),
    chartName: function() {
      return this.propsData.option;
    },
    data: function() {
      return this.propsData.data;
    },
    customColors: function() {
      return this.propsData.customColors;
    },
    chartXLabels: function() {
      if (!this.data) {return [];}
      return this.data[this.chartName] && Object.keys(this.data[this.chartName]);
	  },
    chartData: function() {
      if (!this.data) {return {name: '', data: []}}
      let dataByName = {};
      if (this.data[this.chartName]) {
        Object.keys(this.data[this.chartName]).map(year => {
          Object.keys(this.data[this.chartName][year]).map(name => {
            dataByName = {
              ...dataByName,
              [name]: [
                ...(dataByName[name] || []),
                +this.data[this.chartName][year][name]
              ]
            }
          });
        })
      }
      this.labels = Object.keys(dataByName);
      let result = {
        name: this.chartName,
        data: Object.keys(dataByName).map(n => ({
          name: `${n}`,
          data: [...dataByName[n]],
        }))
      };
      return result;
    },
    chartDataEcharts: function() {
      if (!this.data) {return {name: '', data: []}}
      let dataByName = {};
      let years = {};
      if (this.data[this.chartName]) {
        Object.keys(this.data[this.chartName]).map(year => {
          years[year] = true;
          Object.keys(this.data[this.chartName][year]).map(name => {
            dataByName = {
              ...dataByName,
              [name]: [
                ...(dataByName[name] || []),
                +this.data[this.chartName][year][name]
              ]
            }
          });
        })
      }
      this.labels = Object.keys(dataByName);
      let result = {
        name: this.chartName,
        labels: Object.keys(years),
        data: Object.keys(dataByName).map(n => ({
          name: `${n}`,
          data: [...dataByName[n]],
        }))
      };
      return result;
    },
    valueFormat() {
      return this.chartName.includes('Anzahl') ? 'number' : 'currency';
    },
    headers() {
        const headers = {
            lockedLeft: [
                TextColumn("label", ""),
            ],
            lockedRight: [],
        };
        if (this.chartName.includes('Anzahl'))
            headers.lockedRight.push(NumberColumn("value", ""));
        else
            headers.lockedRight.push(CurrencyColumn("value", ""));
        return headers;
    },
    rows() {
        if (this.chartName === 'Anzahl Verträge') {
            return this.chartXLabels.map(key => ({
                label: key,
                value: Object.values(this.data[this.chartName][key]).reduce((acc, curr) => acc + curr[0] || acc, 0),
            }));
        } else {
            return this.chartData.data.map(val => ({
                label: val.name,
                value: val.data.reduce((acc, curr) => !isNaN(curr) && acc + curr || acc, 0),
            }));
        }
    },
  },

  methods: {
    getColor(index) {
      return this.customColors[index] || this.colorsApex[index];
    },
    tooltip() {
      return {
        x: {
          show: false,
        }
      }
    },
  },

  mounted: function () {
    this.$nextTick(function () {
      this.isContainerRendered = true;
    });
  },

  components: {
    CoreCard,
    Chart
  },
}
</script>

<style scoped>
/* .content {
  width: 100%;
  min-width: 300px;
  height: 100%;
  min-height: 350px;
  position: relative;
} */
</style>