import NAFI_TYPES from './types';
import { getInitialState } from './index';
import Vue from 'vue';

export default {
  [NAFI_TYPES.MUTATIONS.GET_NAFI_ZUGANGSDATEN_SUCCESS](state, payload) {
    Vue.set(state, 'nafiZugang', Object.assign({}, payload || {}));
  },
  
  [NAFI_TYPES.MUTATIONS.UPDATE_NAFI_STORE](state, {componentId, value}) {
    if (componentId) {
      state.nafiZugang[componentId] = value;
    }
  },

  [NAFI_TYPES.MUTATIONS.GENERATE_NAFI_PASSWORD_SUCCESS](state, payload) {
    if (payload) {
      Vue.set(state, 'nafiZugang', Object.assign({}, state.nafiZugang || {}, { anwenderKennwort: payload }));
    }
  },
  
  [NAFI_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },
}
