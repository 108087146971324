var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "stepper-item__container",
      class: {
        "stepper-item--selected": _vm.isCurrent,
        clickable: _vm.substep && _vm.substep.title
      },
      attrs: { tid: _vm._generateTidFromString(_vm.substep.title) },
      on: {
        click: function($event) {
          return _vm.$emit("click", $event)
        },
        mouseover: function($event) {
          return _vm.handleMouseOver($event)
        },
        mouseleave: function($event) {
          return _vm.handleMouseLeave($event)
        }
      }
    },
    [
      _c("div", { staticClass: "stepper-item__icon" }, [
        _c(
          "div",
          { staticClass: "vertical-substep__icon-container" },
          [
            _c("span", { class: _vm.substepIconClass }),
            !_vm.collapsed && _vm.hasWarning
              ? _c("PhWarningCircle", {
                  staticClass: "color-danger vertical-stop__icon",
                  attrs: { size: 16 }
                })
              : _vm._e()
          ],
          1
        )
      ]),
      _c(
        "span",
        {
          staticClass: "vertical-substep__label",
          class: { "vertical-substep__label--selected": _vm.isCurrent }
        },
        [
          _c("span", {
            domProps: { innerHTML: _vm._s(_vm.sanitize(_vm.substep.title)) }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }