<template>
  <component v-if="hasComponent" ref="cardComponent" :is="cardComponent" v-bind="cardPropsData" />
</template>

<script>
import Vue from 'vue'

// @cards-todo: add card here
// all available cards list
import BrokerBirthdayListCard from '@/components/dashboard/cards/BrokerBirthdayListCard.vue'
import BrokerOverviewDataCard from '@/components/dashboard/cards/BrokerOverviewDataCard.vue'
import BrokerStackedChart from '@/components/dashboard/cards/BrokerStackedChart.vue'
import BrokerPieChart from '@/components/dashboard/cards/BrokerPieChart.vue'
import BrokerBarChart from '@/components/dashboard/cards/BrokerBarChart.vue'
import BrokerNumberCard from '@/components/dashboard/cards/BrokerNumberCard.vue'
import BrokerNumberLargeCard from '@/components/dashboard/cards/BrokerNumberLargeCard.vue'
import BrokerTableCard from '@/components/dashboard/cards/BrokerTableCard.vue'
import BrokerMultipleCard from '@/components/dashboard/cards/BrokerMultipleCard.vue'
import InvestmentMultipleCard from '@/components/dashboard/cards/InvestmentMultipleCard.vue'
import RadialBarMultipleCard from '@/components/dashboard/cards/RadialBarMultipleCard.vue'
import BrokerAreaChart from '@/components/dashboard/cards/BrokerAreaChart.vue'
import BrokerDonutChart from '@/components/dashboard/cards/BrokerDonutChart.vue'
import BrokerVertragCard from '@/components/dashboard/cards/BrokerVertragCard.vue'
import BrokerInfoInventoryCard from '@/components/dashboard/cards/BrokerInfoInventoryCard.vue'
import Vermogen from '@/components/steckbrief/cards/Vermogen.vue'
import BrokerInfo2Card from '@/components/dashboard/cards/BrokerInfo2Card.vue'

export default {
  name: 'card-factory',
  inject: [
    'getSwitcherOptions',
    'cardSelected',
    'allowConfig',
    'allowListView',
  ],
  props: {
    index: {
      type: String,
      default: '0',
    },
    currentCard: {
      type: Object,
      default: () => ({}),
    },
    currentCardOption: {
      type: String,
      default: '',
    },
    additionalData: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      default: '',
    },
    labels: {
      type: Object,
      default: () => ({}),
    },
    info: {
      type: String,
      default: '',
    },
    infoLabel: {
      type: String,
      default: '',
    },
    customColors: {
      type: Array,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    showToolbar: {
      type: Boolean,
      default: true,
    },
    listView: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    hasComponent() {
      return !!this.$props.currentCard;
    },
    cardComponent() {
      if (!this.hasComponent) return;

      let CardClass;
      switch (this.currentCard.card) {
        // @cards-todo: add card here
        // all available cards list
        case 'BrokerBirthdayListCard':
          CardClass = BrokerBirthdayListCard;
          break;
        case 'BrokerOverviewDataCard':
          CardClass = BrokerOverviewDataCard;
          break;
        case 'BrokerStackedChart':
          CardClass = BrokerStackedChart;
          break;
        case 'BrokerPieChart':
          CardClass = BrokerPieChart;
          break;
        case 'BrokerAreaChart':
          CardClass = BrokerAreaChart;
          break;
        case 'BrokerBarChart':
          CardClass = BrokerBarChart;
          break;
        case 'BrokerNumberCard':
          CardClass = BrokerNumberCard;
          break;
        case 'BrokerNumberLargeCard':
          CardClass = BrokerNumberLargeCard;
          break;
        case 'BrokerVertragCard':
          CardClass = BrokerVertragCard;
          break;
        case 'BrokerInfoInventoryCard':
          CardClass = BrokerInfoInventoryCard;
          break;
        case 'BrokerInfo2Card':
          CardClass = BrokerInfo2Card;
          break;
        case 'BrokerTableCard':
          CardClass = BrokerTableCard;
          break;
        case 'BrokerMultipleCard':
          CardClass = BrokerMultipleCard;
          break;
        case 'InvestmentMultipleCard':
          CardClass = InvestmentMultipleCard;
          break;
        case 'RadialBarMultipleCard':
          CardClass = RadialBarMultipleCard;
          break;
        case 'BrokerDonutChart':
          CardClass = BrokerDonutChart;
          break;
        case 'Vermogen':
          CardClass = Vermogen;
          break;
        default:
          CardClass = BrokerOverviewDataCard;
      }

      return CardClass;
    },
    cardPropsData() {
      return {
        propsData: {
          index: this.$props.index,
          current: this.$props.current,
          getSwitcherOptions: this.getSwitcherOptions,
          cardSelected: this.cardSelected,
          allowConfig: this.allowConfig,
          allowListView: this.allowListView,
          data: this.$props.additionalData || {},
          option: this.$props.currentCardOption,
          props: this.$props.currentCard,
          title: this.$props.title,
          labels: this.$props.labels,
          info: this.$props.info,
          infoLabel: this.$props.infoLabel,
          isError: this.$props.isError,
          customColors: this.$props.customColors,
        },
        data: this.$props.additionalData || {},
        option: this.$props.currentCardOption,
        title: this.$props.title,
        labels: this.$props.labels,
        showTitle: this.$props.showTitle,
        showToolbar: this.$props.showToolbar,
        listView: this.$props.listView,
      };
    },
  },

  methods: {
    openInfoModal() {
      this.$refs?.cardComponent?.openInfoModal();
    },
  },

  components: {
    // @cards-todo: add card here
    BrokerBirthdayListCard,
    BrokerOverviewDataCard,
    BrokerStackedChart,
    BrokerPieChart,
    BrokerAreaChart,
    BrokerBarChart,
    BrokerNumberCard,
    BrokerNumberLargeCard,
    BrokerVertragCard,
    BrokerInfoInventoryCard,
    BrokerTableCard,
    BrokerMultipleCard,
    InvestmentMultipleCard,
    RadialBarMultipleCard,
    BrokerDonutChart,
    Vermogen,
    BrokerInfo2Card,
  },
}
</script>

<style scoped>
</style>