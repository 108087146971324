const DEFAULT_SEARCH_INPUT_KEY = 'defaultSearchInput';
const DEFAULT_PROPERTY_KEY = 'description';

const FILTERS_IS_ARRAY = ['type', 'grant', 'withTag', 'withoutTag'];

export const VALUE_EMPTY = '__EMPTY__'

export const GRANT_VALUES = [
  {value: VALUE_EMPTY, label: ' '},
  {value: 'NO', label: 'Keine Freigabe'},
  {value: 'STRUCTURE', label: 'Freigabe für Struktur'},
  {value: 'ALL', label: 'Freigabe für Alle Vermittler'}
]

export const VORLAGEN_VALUES = [
  {value: VALUE_EMPTY, label: 'Alle'},
  {value: 'OWN', label: 'nur eigene'},
  {value: 'SHARED', label: 'nur vorgegebene'},
]

export function getSearchMetaTag(typeFilterOptions, tagsOption) {
  return [
    {
      type: 'group',
      key: 'allgemein',
      label: 'Allgemein',
      menuItems: [{
        type: 'text',
        label: 'Bezeichnung',
        key: 'description',
      },
      {
        type: 'combobox',
        label: 'Typ',
        key: 'vorlageType',
        allowDuplicated: true,
        maxOccurences: 3,
        comboOptions: [ ...typeFilterOptions || []],
      },
      {
        type: 'combobox',
        label: 'Freigabe',
        key: 'grant',
        allowDuplicated: true,
        maxOccurences: 3,
        comboOptions: [ ...GRANT_VALUES],
      },
      {
        type: 'combobox',
        label: 'Vorlagen',
        key: 'vorlagen',
        comboOptions: [ ...VORLAGEN_VALUES],
      },
      {
        type: 'combobox',
        label: 'Mit Tag',
        key: 'withTag',
        allowDuplicated: true,
        maxOccurences: 3,
        comboOptions: [ ...tagsOption || []],
      },
      {
        type: 'combobox',
        label: 'Ohne Tag',
        key: 'withoutTag',
        allowDuplicated: true,
        maxOccurences: 3,
        comboOptions: [ ...tagsOption || []],
      },
      {
        type: 'default',
        label: 'Nur mit Tags',
        key: 'onlyWithTags',
        emptyDenied: true,
      },
      {
        type: 'default',
        label: 'Nur ohne Tags',
        key: 'onlyWithoutTags',
        emptyDenied: true,
      },
    ],
    }
  ]
}

export function isArrayFilter(key) {
  return FILTERS_IS_ARRAY.indexOf(key) >= 0;
}

export function prepareFilters(filters) {
  if(!filters || !filters.length) {
    return null;
  }

  return filters.reduce((result, f) => {
    const key = f.key;
    if(!key) {
      return result;
    }

    if(key === DEFAULT_SEARCH_INPUT_KEY) {
      result[DEFAULT_PROPERTY_KEY] = f.value;
    } else if(isArrayFilter(key)) {
      if(!result[key]) {
        result[key] = [];
      }
      if(f.value) {
        result[key].push(f.value);
      }
    } else {
      result[key] = f.value;
    }

    return result;
  }, {})
}