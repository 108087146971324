var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "savings-potential-dashboard-card__container" },
    [
      _c("div", { staticClass: "savings-potential-dashboard-card__content" }, [
        _c("div", { staticClass: "row py-2" }, [
          _c(
            "div",
            { staticClass: "col-12 d-flex justify-content-center" },
            [
              _c("div", { staticClass: "mr-1" }, [
                _vm._v("Sie können monatlich")
              ]),
              _c("CurrencyLabel", {
                staticClass: "mr-1",
                attrs: { value: _vm.avgTotal, colored: "" }
              }),
              _c("div", { staticClass: "mr-1" }, [_vm._v("sparen.")])
            ],
            1
          )
        ]),
        !_vm.isMobileNativeContext
          ? _c("div", { staticClass: "row py-2 mx-4" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("ChartHorizontalBullet", {
                    staticClass: "mb-2",
                    attrs: { current: _vm.maxTotal, average: _vm.avgTotal }
                  })
                ],
                1
              )
            ])
          : _vm._e(),
        !_vm.isMobileNativeContext
          ? _c("div", { staticClass: "row mx-4" }, [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("CurrencyLabel", {
                    staticClass: "mr-1",
                    attrs: { value: _vm.minTotal, colored: "" }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col text-right" },
                [
                  _c("CurrencyLabel", {
                    staticClass: "mr-1",
                    attrs: { value: _vm.maxTotal, colored: "" }
                  })
                ],
                1
              )
            ])
          : _vm._e(),
        !_vm.isMobileNativeContext
          ? _c("div", { staticClass: "row mx-4" }, [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("BaseButton", { on: { click: _vm.newTicket } }, [
                    _vm._v("Sparplan anlegen")
                  ])
                ],
                1
              )
            ])
          : _vm._e()
      ]),
      !_vm.isMobileNativeContext
        ? _c(
            "div",
            {
              staticClass:
                "savings-potential-dashboard-card__view-more text-right"
            },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: "/home/multibanking-account/monatliches-sparpotential"
                  }
                },
                [_vm._v("weitere...")]
              )
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }