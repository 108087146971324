<template>
  <div>
    <PageHeaderTitleNavigation 
      title="ToDo-Liste"
      :subtitle="page === 'offene-unterschriften' ? 'Offene Unterschriften' : 'Online Legitimation'"
      :defaultMenu="optionsMenu"
    />

    <div tid="b61ff807-c971-492e-a260-257bfaa8f36c">
      <div v-if="page === 'offene-unterschriften'">
        <div v-if="readOnly"  class="color-danger box__container">
          Die aktuellen Einstellungen erlauben nur einen lesenden Zugriff. Deshalb ist es nicht möglich das Dokument digital zu signieren!
        </div>
        <OffeneUnterschriften v-else @noOpenSigns="changePage('video-ident-vorhanden') " />
      </div>

      <div v-if="page === 'video-ident-vorhanden'" class="box__container">
        <h5>Bitte schliessen Sie folgende Legitimationen ab:</h5>
        <template>
          <Table v-if="rows.length"
            :headers="headers"
            :rows="rows"
            rowId="vorgangsnummer"
            :rowsPerPage="20"
            :hidePagination="rows.length < 21"
            @click-bezeichnung="navigateTo($event.navigateTo)"
          >
            <template #depotnr="row">
              <DownloadLink 
                :title="row.depotnr"
                :href="showPDF(row)"
              />
            </template>
          </Table>
          <div v-else>Keine Daten</div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import ANTRAG_TYPES from '@/store/antrag/types';
import { mapGetters } from 'vuex';
import Table from "@/components/table2/Table.vue";
import {TextColumn, DateTimeColumn,  SlotColumn, numberToSortable} from "@/components/table2/table_util.js";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import UndrawFinance from '@/components/icons/undraw/UndrawFinance.vue';
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import OffeneUnterschriften from '@/components/communication/OffeneUnterschriften.vue';
import DownloadLink from '@/components/core/download/DownloadLink.vue';
import { makeQueryParam } from "@/helpers/utils-helper";

export default {
  props: {
  },
  
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    Table,
    DownloadLink,
    OptionMenu,
    OffeneUnterschriften,
    PageHeaderTitleNavigation,
  },

  data: function () {
    return {
      page: 'offene-unterschriften',
      headers: {
        lockedLeft: [
          TextColumn('gesellschaft', 'Gesellschaft').makeAlwaysVisible(),
        ],
        center: [
          SlotColumn('depotnr', 'Depotnummer (PDF anzeigen)', 200, 1).makeSortable(numberToSortable),
          TextColumn('bezeichnung', 'Antrag abschließen').makeAlwaysVisible().makeLink(),
          DateTimeColumn('updateDate', 'Geändert am'),
          DateTimeColumn('createDate', 'Angelegt am'),
        ],
        lockedRight: [
          // ActionColumn('actions', '')
        ]
      },
    }
  },

  computed: {
    ...mapGetters({
      videoIndent: ANTRAG_TYPES.GETTERS.GET_TODO_VIDEO_INDENT,
      readOnly: CORE_TYPES.GETTERS.IS_OPEN_SIGNATURES_READ_ONLY,
      apiAddress: CORE_TYPES.GETTERS.API_ADDRESS,
      token: CORE_TYPES.GETTERS.GET_TOKEN,
      isFK: CORE_TYPES.GETTERS.IS_FK
    }),
    optionsMenu() {
      return [
        {
          path: '/communication/todo-list/offene-unterschriften',
          label: 'Offene Unterschriften',
          component: UndrawFinance,
          textBold: this.page === 'offene-unterschriften',
        },
      ].concat( this.isFK ? [{
          path: '/communication/todo-list/video-ident-vorhanden',
          label: 'Online Legitimation',
          component: UndrawFinance,
          textBold: this.page === 'video-ident-vorhanden',
        }] : [])
    },
    rows() {
      if (this.videoIndent?.rows?.length){
        const rows = this.videoIndent.rows.map( row => {
          return {
            ...row,
            navigateTo: (row.gesellschaft === 'FFB' ? '/beratung/ffb-video-ident?ffbId=' + row.ffbId : '')
          }
        })
        return rows;
      }
      return []
    },
  },
	mounted() {
    this.page = this.$route.params.page || 'offene-unterschriften';
    this.$store.dispatch(ANTRAG_TYPES.ACTIONS.LOAD_TODO_VIDEO_INDENT);
  },

  beforeRouteLeave (to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zur ToDo-Liste', 
      to,
      from,
    });

    next()
  },
  watch: {
    '$route.params.page'(page) {
      this.page = page;
    },
  },

  methods: {
    navigateTo(event) {     
      this.$router.push({ path: `${event}` });
    },
    changePage(page){
        this.page = page
        this.$router.push({ path: '/communication/todo-list/video-ident-vorhanden' });
    },
    showPDF(row) {
      const apiAddress = this.apiAddress
      const token = this.token
      const input = btoa(JSON.stringify({
        antragsdatenId: row.antragdatenId,
        action: 'pdf'
      }));
      const params = makeQueryParam({ input, token })
      const fileName = encodeURIComponent(row.bezeichnung + '.pdf');
      return `${apiAddress}/download_service/generate_antrag_pdf/${fileName}?${params}`;
    },
  },
}
</script>

<style scoped>
.pl-2 {
  padding-left: 2rem;
}
</style>
