var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Einstellungen",
          subtitle: _vm.isFA
            ? "Dienstleistungsvertrag"
            : "Honorar / Serviceleistung",
          actions: _vm.headerActions
        },
        on: {
          "action-DISCARD": function($event) {
            return _vm.discardChanges()
          },
          "action-DELETE": function($event) {
            return _vm.deleteFeeProperties()
          },
          "action-COPY": function($event) {
            return _vm.copyFeeProperties()
          }
        }
      }),
      _vm.isSmallOrMediumScreen
        ? [
            _vm.loading
              ? _c("GhostLoading", {
                  attrs: {
                    type: "block",
                    config: { block: { height: "120px" } },
                    useBoxContainer: ""
                  }
                })
              : _c("ConfigStepper3", {
                  ref: "stepper",
                  attrs: {
                    configStep: _vm.configStep,
                    selectedStep: _vm.selectedStep,
                    configData: _vm.configData
                  },
                  on: { setStep: _vm.setSelectedStep }
                })
          ]
        : _c("ContentWithStepper", {
            attrs: { stepperCollapsed: _vm.stepperCollapsed },
            scopedSlots: _vm._u([
              {
                key: "stepper",
                fn: function() {
                  return [
                    _c("ConfigVerticalStep", {
                      ref: "stepper",
                      attrs: {
                        configStep: _vm.configStep,
                        configData: _vm.configData,
                        selectedStep: _vm.selectedStep
                      },
                      on: {
                        collapsed: function($event) {
                          _vm.stepperCollapsed = $event
                        },
                        setStep: _vm.setSelectedStep
                      }
                    })
                  ]
                },
                proxy: true
              },
              {
                key: "content",
                fn: function() {
                  return [
                    _c(
                      "div",
                      [
                        _vm.selectedStep === "vertragsdaten"
                          ? _c("Vertragsdaten")
                          : _vm.selectedStep ===
                            "gebuehren_finanzservicevertrag"
                          ? _c("GebuehrenFinanzservicevertrag")
                          : _vm.selectedStep === "gebuehren"
                          ? _c("Gebuehren")
                          : _vm.selectedStep === "provisionserstattung"
                          ? _c("Provisionserstattung")
                          : _vm.selectedStep === "dienstleistungsvertrag"
                          ? _c("Dienstleistungsvertrag")
                          : _vm.selectedStep === "bankdaten"
                          ? _c("Bankdaten")
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ])
          }),
      _c("BaseModal", {
        ref: "editModal",
        attrs: {
          modalTitle: _vm.editModalTitle,
          size: "lg",
          showDefaultButtons: false,
          actions: _vm.modalActions
        },
        on: {
          "action-DISCARD": function($event) {
            return _vm.discardChanges()
          },
          close: _vm.saveChanges
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "div",
                  [
                    _vm.selectedStep === "vertragsdaten"
                      ? _c("Vertragsdaten")
                      : _vm.selectedStep === "gebuehren_finanzservicevertrag"
                      ? _c("GebuehrenFinanzservicevertrag")
                      : _vm.selectedStep === "gebuehren"
                      ? _c("Gebuehren")
                      : _vm.selectedStep === "provisionserstattung"
                      ? _c("Provisionserstattung")
                      : _vm.selectedStep === "dienstleistungsvertrag"
                      ? _c("Dienstleistungsvertrag")
                      : _vm.selectedStep === "bankdaten"
                      ? _c("Bankdaten")
                      : _vm._e()
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "BaseModal",
        {
          ref: "confirmationModal",
          attrs: {
            modalTitle: "Bestätigung",
            labelButtonConfirm: "Ok",
            showCancelButton: true
          },
          on: { onConfirmButton: _vm.onConfirmModal }
        },
        [
          _c("label", [
            _vm._v(
              "Das Formular ist fehlerhaft und kann nicht gespeichert werden, bevor Sie es korrigiert haben. Wollen Sie wirklich gehen?"
            )
          ])
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }