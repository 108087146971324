var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: _vm.pageTitle, actions: _vm.actions },
        on: {
          "action-PRINT": function($event) {
            return _vm.printScreen("printSection")
          }
        }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.loading
            ? _c("GhostLoading", { attrs: { type: "paragraph" } })
            : _vm.result
            ? _c("div", {
                staticClass: "break_word",
                attrs: { id: "printSection" },
                domProps: { innerHTML: _vm._s(_vm.sanitize(_vm.result)) }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }