<template>
    <div
        v-fc-tooltip="tooltip(row)"
        class="text-cell"
        :style="{'-webkit-line-clamp': collapsed ? undefined : lineClamp, 'max-width': maxWidth}"
    >
        <template v-if="this.displayWithClass">
            <span v-for="(item,idx) in displayWithClass(text, row)" :key="idx" :class="item.class">{{item.text}}</span>
        </template>
        <template v-else>{{text}}</template>
    </div>
</template>
<script>
function exportAsString(props) {
    let value = props.row[props.column];
    if (props.filter)
        value = props.filter(value);
    return value == null ? "" : (value + "");
}

export default {
    exportAsString,
    props: {
        column: {
            type: String,
        },
        row: {
            type: Object,
        },
        filter: {
            type: Function,
            default: null,
        },
        lineClamp: {
            type: Number,
            default: 1,
        },
        collapsed: {
            type: Boolean,
            default: false,
        },
        maxWidth: {
            type: String,
            default: "600px",
        },
        tooltip: {
            type: Function,
            default: () => {}
        },
        displayWithClass: {
            type: Function,
            default: null,
        }
    },
    computed: {
        text() {
            return exportAsString(this.$props);
        },
    },
}
</script>
<style scoped>
.text-cell {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}
</style>
