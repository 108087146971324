var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? [
            _c(
              "GhostLoading",
              { attrs: { useBoxContainer: "" } },
              [
                _c("Block", { attrs: { type: "title" } }),
                _c("Block", { attrs: { height: "150" } })
              ],
              1
            )
          ]
        : [
            _c(
              "div",
              { staticClass: "box__container" },
              [
                _c("div", { staticClass: "box__title" }, [
                  _vm._v("E-Mail Anhang")
                ]),
                _vm.dokUrl
                  ? _c("p", [
                      _c(
                        "a",
                        { attrs: { href: _vm.dokUrl, target: "_blank" } },
                        [
                          _vm._v(
                            "Die letzte Anlageempfehlung, erzeugt am " +
                              _vm._s(_vm.dokCreateDate) +
                              "."
                          )
                        ]
                      )
                    ])
                  : _c("p", [
                      _vm._v(
                        " Für diese Anlageempfehlung wurde kein Dokument erzeugt. "
                      )
                    ]),
                _c("InputToggleSwitch", {
                  attrs: {
                    label: "Ein neues Dokument erzeugen",
                    inLineLabel: ""
                  },
                  model: {
                    value: _vm.neuPDF,
                    callback: function($$v) {
                      _vm.neuPDF = $$v
                    },
                    expression: "neuPDF"
                  }
                }),
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c(
                      "BaseButton",
                      {
                        attrs: { disabled: !_vm.dokUrl },
                        on: { click: _vm.startEmail }
                      },
                      [_vm._v("Das letzte Dokument verwenden")]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm.neuPDF
              ? [
                  _c("ActionPdfStep", {
                    attrs: { id: _vm.id, fromEmail: true },
                    on: { createPDF: _vm.setPdfInfo }
                  })
                ]
              : _vm._e()
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }