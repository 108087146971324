var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "fc-tooltip",
          rawName: "v-fc-tooltip",
          value: _vm.tooltip(_vm.row),
          expression: "tooltip(row)"
        }
      ],
      staticClass: "text-cell",
      style: {
        "-webkit-line-clamp": _vm.collapsed ? undefined : _vm.lineClamp,
        "max-width": _vm.maxWidth
      }
    },
    [
      this.displayWithClass
        ? _vm._l(_vm.displayWithClass(_vm.text, _vm.row), function(item, idx) {
            return _c("span", { key: idx, class: item.class }, [
              _vm._v(_vm._s(item.text))
            ])
          })
        : [_vm._v(_vm._s(_vm.text))]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }