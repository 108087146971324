var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "box-list__container" },
    _vm._l(_vm.items, function(item, index) {
      return _c(
        "li",
        {
          key: index,
          staticClass: "box-list__item box__container",
          class: { clickable: item.clickable },
          on: {
            click: function($event) {
              return _vm.handleClick(item)
            }
          }
        },
        [
          _vm.hasIcon
            ? _c(
                "div",
                { staticClass: "box-list__item--icon" },
                [_vm._t("icon", null, { item: item })],
                2
              )
            : _vm._e(),
          _vm.hasContent
            ? _c(
                "div",
                { staticClass: "box-list__item--content" },
                [_vm._t("default", null, { item: item })],
                2
              )
            : _vm._e(),
          _vm.hasLink
            ? _c(
                "div",
                { staticClass: "box-list__item--link" },
                [_vm._t("link", null, { item: item })],
                2
              )
            : _vm._e()
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }