var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.first
      ? _c(
          "div",
          {
            staticClass: "row d-none d-xl-flex justify-content-between header"
          },
          [
            _c("label", { staticClass: "col-lg-2 px-1" }, [_vm._v("Einmalig")]),
            _c("label", { staticClass: "col-lg-2 px-1" }, [
              _vm._v("Ratierlich")
            ]),
            _c("label", { staticClass: "col-lg-2 px-1" }, [
              _vm._v("Zeithorizont")
            ]),
            _c("label", { staticClass: "col-lg-2 px-1" }, [
              _vm._v("Welche Anlageziele haben Sie?")
            ]),
            _c("label", { staticClass: "col-lg-2 px-1" }, [
              _vm._v("Welche speziellen Anlageziele haben Sie?")
            ]),
            _c("label", { staticClass: "col-lg-2 px-1" }, [
              _vm._v("Bereitschaft Verluste zu tragen")
            ])
          ]
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "row justify-content-between" },
      [
        _c("label", { staticClass: "col-12 header" }, [
          _vm._v(_vm._s(_vm.title))
        ]),
        _vm._l(_vm.fields, function(field, index) {
          return _c(
            "div",
            { key: index, staticClass: "col-12 col-lg-2 px-1" },
            [
              field.type === "TEXT_CURRENCY"
                ? _c(
                    "div",
                    [
                      _c("InputField", {
                        attrs: {
                          type: "currency",
                          precision: 2,
                          disabled: _vm.disabled
                        },
                        on: {
                          change: function($event) {
                            return _vm.emitEvent(
                              "save",
                              $event,
                              field.id,
                              _vm.count
                            )
                          }
                        },
                        model: {
                          value: _vm.form[field.id],
                          callback: function($$v) {
                            _vm.$set(_vm.form, field.id, $$v)
                          },
                          expression: "form[field.id]"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              field.type === "COMBOBOX"
                ? _c(
                    "div",
                    [
                      _c("ComboBox", {
                        attrs: {
                          values: field.values || [],
                          disabled: _vm.disabled,
                          firstEmpty: ""
                        },
                        on: {
                          change: function($event) {
                            return _vm.emitEvent(
                              "save",
                              $event,
                              field.id,
                              _vm.count
                            )
                          }
                        },
                        model: {
                          value: _vm.form[field.id],
                          callback: function($$v) {
                            _vm.$set(_vm.form, field.id, $$v)
                          },
                          expression: "form[field.id]"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }