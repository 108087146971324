var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "person-overview__container",
      class: { "is-collapsable": _vm.isCollapsable }
    },
    [
      _c("div", [
        _c(
          "b",
          [
            _vm.isCollapsable
              ? _c("PhCaretRight", {
                  staticClass: "toggle clickable",
                  class: { collapsed: _vm.collapsed },
                  attrs: { size: 16 },
                  on: {
                    click: function($event) {
                      _vm.collapsed = !_vm.collapsed
                    }
                  }
                })
              : _vm._e(),
            _vm.isMainPerson
              ? _c(
                  "span",
                  { staticClass: "mr-1" },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to:
                            "/persoenlichedaten/customer-data/steps/stammdaten"
                        }
                      },
                      [_vm._v(_vm._s(_vm.fullname))]
                    )
                  ],
                  1
                )
              : _c("span", { staticClass: "mr-1" }, [
                  _c("a", { on: { click: _vm.openPersonDetails } }, [
                    _vm._v(_vm._s(_vm.fullname))
                  ])
                ]),
            _vm.dateOfDeath
              ? _c("span", { staticClass: "mr-1" }, [
                  _vm._v("(† " + _vm._s(_vm.dateOfDeath) + ")")
                ])
              : _vm._e(),
            _vm.category
              ? _c("Pill", {
                  attrs: { label: _vm.category, title: "Kundenkategorie" }
                })
              : _vm._e(),
            _vm.anredePronomen
              ? _c(
                  "span",
                  { staticClass: "ml-1", attrs: { title: "Anredeform" } },
                  [
                    _c("Pill", {
                      attrs: {
                        label: "(" + _vm.anredePronomen + ")",
                        title: "Anredeform"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          staticClass: "content",
          class: { collapsed: _vm.isCollapsable && _vm.collapsed }
        },
        [
          _vm.address || _vm.dateBirth
            ? [
                _vm.address
                  ? _c("div", [_vm._v(_vm._s(_vm.address))])
                  : _vm._e(),
                _vm.dateBirth
                  ? _c("div", [_vm._v("*" + _vm._s(_vm.dateBirth) + " ")])
                  : _vm._e()
              ]
            : [_vm._v("Keine Daten")]
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }