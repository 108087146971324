<template>
  <div class="antrag-components__container">
    <div class="box__container">
      <FormLabel label="Pflichtangaben zum Depotübertrag"/>

      <InputRadioBoxGroup v-model="form.uebertragArt" title="Es handelt sich um einen" :values="uebertragArtValues"
        @input="$emit('save', { uebertragArt: $event })"/>

      <FormLabel label="Übertrag der Verlustverrechnungssalden (Nur bei Gesamtübertrag ohne Eigentümerwechsel des Depots möglich.)"/>

      <InputRadioBoxGroup v-model="form.verrechnungssalden" title="Übertrag des Verlustverrechnungssaldos/der Verlustverrechnungssalden wird gewünscht." 
        :values="verrechnungssaldenValues"
        @input="$emit('save', { verrechnungssalden: $event })"/>

      <InputToggleSwitch v-if="form.verrechnungssalden === 'Auswahl'" 
        :value="form.verrechnungssaldo_aktien" 
        label="Verrechnungssalden Aktien"
        @input="$emit('save', { verrechnungssaldo_aktien: $event })"/>
      <InputToggleSwitch v-if="form.verrechnungssalden === 'Auswahl'" 
        :value="form.verrechnungssaldo_sonstige" 
        label="Verrechnungssalden Sonstige"
        @input="$emit('save', { verrechnungssaldo_sonstige: $event })"/>
      <InputToggleSwitch v-if="form.verrechnungssalden === 'Auswahl'" 
        :value="form.verrechnungssaldo_auslq" 
        label="ausl. Quellensteuer"
        @input="$emit('save', { verrechnungssaldo_auslq: $event })"/>

      <BaseButton class="col-12" @click="$emit('back')">Zurück</BaseButton>
    </div>
  </div>
</template>
<script>

import antragNavigationMixin from '@/mixins/antrag/antrag-navigation-mixin.js';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import FormLabel from '@/components/core/forms/FormLabel.vue';

export default {
  mixins: [antragNavigationMixin],
  components: {
    InputToggleSwitch,
    BaseButton,
    InputRadioBoxGroup,
    FormLabel,
  },
  props: {
    positionData: {
    },
    bankverbindungSelection: {
    },
    anzahlFonds: {
      type: Number,
      default: 4,
    },
    warnings: {
    },
  },
  data() {
    return {
      form: {},
      uebertragArtValues: [
        {value: "eigenesDepot", label: "Übertrag auf ein eigenes Depot (Übertrag ohne Eigentümerwechsel) (Überträge auf eigene Depots gelten steuerrechtlich nicht als Eigentümerwechsel und sind daher steuerlich unbeachtlich. Eine Meldung an das Betriebsstättenfinanzamt erfolgt nicht.)"},
        {value: "Erbschaft", label: "Übertrag auf ein Drittdepot aufgrund Erbschaft * (Übertrag mit Eigentümerwechsel) (Überträge auf Drittdepots gelten als Überträge mit Eigentümerwechsel. Werden ab dem 01.01.2009 angeschaffte Bestände aufgrund Erbschaft (unentgeltlich) übertragen, erfolgt die Meldung „unentgeltlicher Übertrag“ an das Betriebsstättenfinanzamt.)"},
        {value: "EheleutenSchenkung", label: "Übertrag zwischen Eheleuten oder aufgrund Schenkung (Übertrag mit Eigentümerwechsel) (Überträge auf Depots Dritter gelten als Überträge mit Eigentümer wechsel. Seit dem 01.01.2010 sind auch Überträge zwischen Ehegattendepots als unentgeltliche Depotüberträge anzusehen. Werden ab dem 01.01.2009 angeschaffte Bestände (unentgeltlich) übertragen, erfolgt die Meldung „unentgeltlicher Übertrag“ an das Betriebsstättenfinanzamt.)"},
        {value: "sonstigen", label: "sonstigen Übertrag auf ein Drittdepot (Übertrag mit Eigentümerwechsel) (Handelt es sich nicht um Erbe oder Schenkung, wird der Übertrag steuerrechtlich wie ein Verkauf behandelt (entgeltlich). Im Falle von hieraus resultierenden Veräußerungs - gewinnen wird die abgebende Bank abzuführende Steuern belasten und Veräußerungsverluste im Rahmen der Verlustverrechnung berücksichtigen.)"},
      ],
      verrechnungssaldenValues: [
        {value: "Alle", label: "alle Verrechnungssalden"},
        {value: "Kein", label: "kein Verrechnungssaldo"},
        {value: "Auswahl", label: "Auswahl"},
      ],
    }
  },
  mounted() {
    this.form = Object.assign({}, this.positionData);
  },
  methods: {
  },
}
</script>
<style scoped>

</style>