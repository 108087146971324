<template>
  <div>
    <div class="box__container">
      <div class="box__title">Bankdaten</div>

      <div v-if="!isFA">
        <p class="text-bold">Bankverbindung des Maklers (nur notwendig, wenn der Kunde die Rechnung überweisen soll)</p>

        <InputToggleSwitch label="Zahlung per Überweisung" inLineLabel
          v-model="feePropertiesForm.zahlungUeberweisung" 
          @input="dataChanged()" />

        <InputField label="Bankname" type="text"
          v-model="feePropertiesForm.maklerBank" isComponentHalfSize 
          @change="dataChanged()" />

        <InputIBAN_BIC label="IBAN" type="IBAN"
          :componentData="feePropertiesForm.maklerIban" isComponentHalfSize 
          validation_path="feePropertiesForm.maklerIban"
          @change="dataChangedCombo('maklerIban', $event)" />

        <InputIBAN_BIC label="BIC" type="BIC"
          :componentData="feePropertiesForm.maklerBic" isComponentHalfSize 
          validation_path="feePropertiesForm.maklerBic"
          @change="dataChangedCombo('maklerBic', $event)" />
      </div>

      <InputField label="Buchungstext Lastschrift" type="text"
          v-model="feePropertiesForm.buchungstextLastschrift" isComponentHalfSize 
          @change="dataChanged()" />
      
      <div v-if="isCustomer">
        <p class="text-bold">Bankverbindung des Kunden (nur notwendig, wenn per SEPA Lastschrift eingezogen werden soll und für Erstattungen an den Kunden)</p>

        <div class="mb-3">
          <BaseButton :disabled="loading" @click="findKundeBankverbindungData()">Von Standardbankverbindung kopieren</BaseButton>
        </div>

        <InputField label="Bankname" type="text"
          v-model="feePropertiesForm.kundenBank" isComponentHalfSize 
          @change="dataChanged()" />

        <InputIBAN_BIC label="IBAN" type="IBAN"
          :componentData="feePropertiesForm.kundenIban" isComponentHalfSize 
          validation_path="feePropertiesForm.kundenIban"
          @change="dataChangedCombo('kundenIban', $event)" />

        <InputIBAN_BIC label="BIC" type="BIC"
          :componentData="feePropertiesForm.kundenBic" isComponentHalfSize 
          validation_path="feePropertiesForm.kundenBic"
          @change="dataChangedCombo('kundenBic', $event)" />
      </div>
    </div>
  </div>
</template>

<script>
import FEE_TYPES from '@/store/fee/types';

import InputField from '@/components/core/forms/InputField.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import InputIBAN_BIC from '@/components/core/forms/InputIBAN_BIC.vue';
import BaseButton from '@/components/core/BaseButton.vue';

import validator from '@/mixins/validator';

import feePropertiesStepMixin from '../fee-properties-step-mixin';

export default {
  mixins: [validator, feePropertiesStepMixin],
  data() {
    return {
      loading: false,
      feePropertiesForm: {
        zahlungUeberweisung: null,
        maklerBank: null,
        maklerIban: null,
        maklerBic: null,
        buchungstextLastschrift: null,
        kundenBank: null,
        kundenIban: null,
        kundenBic: null,
      },
    };
  },
  methods: {
    async findKundeBankverbindungData() {
      try {
        this.loading = true;
        const data = await this.$store.dispatch(FEE_TYPES.ACTIONS.FIND_KUNDE_BANKVERBINDUNG_DATA);
        if(data?.bank || data?.iban || data?.bic) {
          this.$set(this.feePropertiesForm, 'kundenBank', data?.bank);
          this.$set(this.feePropertiesForm, 'kundenIban', data?.iban);
          this.$set(this.feePropertiesForm, 'kundenBic', data?.bic);
          this.dataChanged();
        }
      } finally {
        this.loading = false;
      }
    },
  },
  validators: {},
  components: {
    InputField,
    InputToggleSwitch,
    InputIBAN_BIC,
    BaseButton,
  },
}
</script>
