var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "signo-link__container" }, [
    _vm.isMobileNativeContext
      ? _c(
          "a",
          {
            class: _vm.linkClass,
            on: { click: _vm.callBridgeSignoViewDocument }
          },
          [_vm._v(" " + _vm._s(_vm.title) + " ")]
        )
      : _vm.isDeepLink
      ? _c(
          "a",
          { class: _vm.linkClass, on: { click: _vm.openDeepLinkSignoviewer } },
          [_vm._v(" " + _vm._s(_vm.title) + " ")]
        )
      : _c(
          "a",
          {
            class: _vm.linkClass,
            staticStyle: { "text-overflow": "ellipsis" },
            attrs: {
              target: "_blank",
              rel: "noopener noreferer",
              title: _vm.title,
              href: _vm.href
            }
          },
          [_vm._v(" " + _vm._s(_vm.title) + " ")]
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }