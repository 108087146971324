import ANTRAG_TYPES from './types';
import CORE_TYPES from '@/store/core/types';

export default {
  [ANTRAG_TYPES.GETTERS.ANTRAEGE](state) {
    return state.antraege;
  },
  [ANTRAG_TYPES.GETTERS.POSITIONS](state) {
    return state.positions;
  },
  [ANTRAG_TYPES.GETTERS.POSITION_DATA](state) {
    return state.positionData;
  },
  [ANTRAG_TYPES.GETTERS.ANTRAEGE_DATA](state) {
    return state.antraegeData;
  },
  [ANTRAG_TYPES.GETTERS.REQUESTED_EBASE_DEPOTNUMMER](state) {
    return state.requestedEbaseDepotnummer;
  },
  [ANTRAG_TYPES.GETTERS.REQUESTED_EBASE_AAB_ANLAGESTRATEGIEN](state) {
    return state.requestedEbaseAABAnlagestrategien;
  },
  [ANTRAG_TYPES.GETTERS.ANTRAEGE_RELOAD_PAYLOAD](state) {
    return state.antraegeReloadPayload;
  },
  [ANTRAG_TYPES.GETTERS.SAVE_ANTRAG_STATE](state) {
    return state.saveAntragState;
  },
  [ANTRAG_TYPES.GETTERS.ANTRAG_LIST](state) {
    return { ...state.antragList };
  },
  [ANTRAG_TYPES.GETTERS.ANTRAG_LIST_PROCESS_FORMULARE](state) {
    return { ...state.gesellschaftsFormulareProcess };
  },
  [ANTRAG_TYPES.GETTERS.ANTRAG_LIST_FORMULARE](state) {
    return { ...state.antragListFormulare };
  },
  [ANTRAG_TYPES.GETTERS.GESELLSCHAFTS_FORMULARE](state) {
    return state.gesellschaftsFormulare;
  },
  [ANTRAG_TYPES.GETTERS.GESELLSCHAFTS_FORMULARE_LINKS](state) {
    return state.gesellschaftsFormulareLinks;
  },  
  [ANTRAG_TYPES.GETTERS.GESELLSCHAFTS_MAINSTRUCTURE](state) {
    return state.gesellschaftsMainStructure;
  }, 
  [ANTRAG_TYPES.GETTERS.POSITIONS_FREMDWAEHRUNG](state) {
    return state.positionsFremdwaehrung;
  },
  [ANTRAG_TYPES.GETTERS.CAPITALBANK_DEPOTS_VORHANDEN](state) {
    return state.capitalbankDepotsVorhanden;
  },
  [ANTRAG_TYPES.GETTERS.GET_ATTACHMENTS](state) {
    return state.attachmentsTemp;
  },
  [ANTRAG_TYPES.GETTERS.GET_TODO_VIDEO_INDENT](state) {
    return state.videoindent;
  },
  [ANTRAG_TYPES.GETTERS.IS_ANTRAG_ACTION_LOADING](state) {
    return (action) => state.antragActionLoading?.[action] === true;
  },
  [ANTRAG_TYPES.GETTERS.IS_SOME_ANTRAG_ACTION_LOADING_ACTIVE](state) {
    return Object.values(state.antragActionLoading).some(value => !!value);
  },
}