<template>
  <Table
    tableId="27203091-37e4-46e1-81ab-5ea4bca465c8"
    cardView
    :headers="headers"
    :rows="rows"
    hidePagination
  />
</template>

<script>
import BERICHTE_TYPES from '@/store/berichte/types';
import { TextColumn, CurrencyColumn, DateColumn, NumberColumn } from "@/components/table2/table_util.js";
import Table from "@/components/table2/Table.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Table,
  },
  computed: {
    ...mapGetters({
      altersvorsorge: BERICHTE_TYPES.GETTERS.ALTERSVORSORGE,
    }),
    headers() {
      return {
        center: [
          DateColumn("vertragsbeginn", "Vertragsbeginn"),
          TextColumn("gesellschaft", "Gesellschaft"),
          NumberColumn("renteneintrittsalter", "Rentenalter gemäß Antrag"),
          DateColumn("vertragsende", "Aktuell verein. Renteneintrittstermin"),
          CurrencyColumn("riesterVertragsjahresrate", "Urspr. verein. Jahresbeitrag"),
          CurrencyColumn("riesterVertragsvertragsrate", "aktueller Jahresbeitrag"),
          CurrencyColumn("jahresrate", "Urspr. verein. Jahresb. o. Riestertr."),
          CurrencyColumn("vertragsjahresrate", "aktueller Jahresbeitrag o. Riestertr."),
          CurrencyColumn("garantiesumme", "Aktuelle Beitragsgarantie"),
          NumberColumn("mahnstufe", "Mahnstufe"),
          // PillColumn("ablaufstabilisator", "Ablaufstabilisator gewählt"), // bei hover:  
          DateColumn("beginnAblaufstabilisator", "Ablaufstabilisator aktiv ab"),
          // PillColumn("mitKuendigungsfrist", "Vertrag mit Kündigungsfrist"),
          DateColumn("endeKuendigungsfrist", "Kündigungsfrist läuft"),
          DateColumn("letzterHoechststand", "Datum letzter Höchststand"), // Infobutton:
          CurrencyColumn("hoechststand", "HSS Betrag"),
          // TextColumn("gesellschaft", "Rentenphase aktiv"),
          // DateColumn("gesellschaft", "Beginn der Rentenphase"),
          CurrencyColumn("tilgungsbetrag", "Tilgungsbetrag"),
          CurrencyColumn("stornorisikoGesamt", "Stornorisiko"),
          CurrencyColumn("beitragssumme", "Beitragssumme"),
        ]
      };
    },
    rows() {
      return [
        {...(this.altersvorsorge?.depotZusatz || {})}
      ]
    },
  },
};
</script>