<template>
  <BaseModal
    ref="modal"
    :showDefaultButtons="false"
    @close="onClose"
    @action-CLOSE="saveConfigEdited()"
    @onCloseButton="saveConfigEdited()"
  >
    <template #modalTitle>
      <div class="dashboard-panel-config-modal--title-container clearfix">
        <span class="dashboard-panel-config-modal--title">Verwalten</span>
      </div>
    </template>

    <template #default>
      <div>
        <div v-if="localData && localData.length" class="dashboard-panel-config__to-added__container">
          <ul class="dashboard-panel-config__items list-bordered">
            <template v-for="card in localData">
              <li v-if="isVisible(card)" :key="card.title" class="dashboard-panel-config__item list-bordered-item">
                <span class="dashboard-panel-config__item--label">{{ card.title }}</span>
                <div class="dashboard-panel-config__item--actions">
                  <button v-if="isRemovable(card)" type="button" @click="remove(card)" class="btn-clear clickable">
                    <PhTrashSimple :size="16" />
                  </button>
                </div>
              </li>
            </template>
          </ul>
        </div>

        <div  class="dashboard-panel-config__to-add__container">
          <h3 class="box__title">Auswahl</h3>
          <ul class="dashboard-panel-config__items list-bordered">
            <template v-for="(card, index) in localData">
              <li v-if="card.removed" :key="index" class="dashboard-panel-config__item list-bordered-item">
                <span class="dashboard-panel-config__item--label">{{ card.title }}</span>
                <div class="dashboard-panel-config__item--actions">
                  <button type="button" @click="add(card)" class="btn-clear clickable">
                    <PhPlus />
                  </button>
                </div>
              </li>
            </template>
          </ul>
        </div>

        <div v-if="!localData.length" class="text-centered">Keine Daten</div>
      </div>





    </template>
  </BaseModal>

</template>

<script>
import FC_CONFIG_TYPES from '@/store/fcConfig/types';
import FC_CONFIG from '@/configs/fcConfig.js';
import { mapGetters } from 'vuex';
import BaseModal from "@/components/core/BaseModal.vue";
import { PhTrashSimple, PhPlus } from 'phosphor-vue';

export default {
  name: 'CardOverviewConfigModal',
  components: {
    BaseModal,
    PhTrashSimple,
    PhPlus,
  },
  props: {
    cards: {
      type: Array,
      required: true
    },
    id: {
      type: String,
      required: true,
      validator: (value) => {
        const notEmpty = !!value && !!value.trim();
        return notEmpty;
      },
    },
    hiddenCards: {
      type: Array,
      default: () => [],
    },        
  },
  watch: {
    cards(newValue) {
      this.localData = [...newValue];
    },
  },  
  data() {
    return {
      localData: [],
      loading: false,
      configChanged: false,
    };
  },
  computed: {
    ...mapGetters({
      fcConfigCardsOverview: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_CARDS_OVERVIEW,
    }),
    cardsOverviewConfig() {
      return this.id in this.fcConfigCardsOverview && this.fcConfigCardsOverview[this.id] || null;
    },    
    savedCardOrder() {
      const contentToParse = this.fcConfigCardsOverview[this.id]?.content;
      if (contentToParse) {
        return JSON.parse(contentToParse);
      }

      return null;
    },
  },
  methods: {
    remove(card) {
      this.$set(card, 'removed', true);
      this.configChanged = true;
    },
    add(card) {
      this.$set(card, 'removed', false);
      this.configChanged = true;
    },
    onClose() {

    },
    async saveConfigEdited() {
      if(!this.id || !this.configChanged) {
        return;
      }

      const payload = {
        configId: this.id,
        configType: FC_CONFIG.CARD_OVERVIEW_CONFIG,
        content: this.contentToSave(this.localData),
        userLevel: null,
      };

      await this.$store.dispatch(FC_CONFIG_TYPES.ACTIONS.SAVE_FC_CONFIG, payload);

      this.$emit('saved', [ ...this.localData ]);
    },
    open() {
      this.$refs.modal.open();
    },    
    removeCard(whatCard) {
      // Flatten the array and find the object with the given key
      const flatData = this.localData.flat();
      const target = flatData.find(item => item.key === whatCard.key);

      // Set the "removed" property to false if the object is found
      if (target) {
        // target.removed = true;
        this.$set(target, 'removed', true);
      }
      this.$emit('remove', whatCard);
    },
    isVisible(card) {
      return !!card.removed == false;
    },
    isRemovable(card) {
      return card?.removable !== false;
    },
    async loadConfig(forceReload = false) {
      if (!this.id) {
        return ;
      }

      // console.log("loadConfig called");

      const payload = {
        configId: this.id,
        configType: FC_CONFIG.CARD_OVERVIEW_CONFIG,
        forceReload,
      };

      // console.log("loadConfig payload", payload);

      this.loading = true;
      await this.$store.dispatch(FC_CONFIG_TYPES.ACTIONS.LOAD_FC_CONFIG, payload);
      this.loading = false;
      // console.log("loadConfig finished", this.loading);
    },    
    async saveConfig() {
      if(!this.id) {
        return;
      }

      const payload = {
        configId: this.id,
        configType: FC_CONFIG.CARD_OVERVIEW_CONFIG,
        content: this.contentToSave(this.cardDetails),
        userLevel: null,
      };

      await this.$store.dispatch(FC_CONFIG_TYPES.ACTIONS.SAVE_FC_CONFIG, payload);

      this.$emit('saved', [ ...this.cardDetails ]);
    },
    contentToSave(cardDetails) {
      if(!cardDetails) {
        return '';
      }

      return JSON.stringify(cardDetails);
    },
  },
  async mounted() {
      await this.loadConfig(true);
  },  
};
</script>

<style scoped>
.dashboard-panel-config__item {
  display: flex;
}
.dashboard-panel-config__item--label {
  flex: 1 1 auto;
  word-break: break-word;
}
.dashboard-panel-config__item--actions {
  align-self: center;
  flex: 0 0 auto;
  margin: 0 0 0 12px;
}
.dashboard-panel-config__item--actions > * {
  margin: 0 0 0 16px;
}
.dashboard-panel-config__item--actions > *:first-child {
  margin-left: 0;
}
.dashboard-panel-config__to-add__container {
  margin: 16px 0 0;
}

</style>
