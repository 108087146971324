var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.rows.length
        ? _c("Table", {
            attrs: {
              tableId: "dbbdcfe4-b5c1-4386-86d8-efac26962b2c",
              headers: _vm.headers,
              rows: _vm.rows,
              rowsPerPage: 10,
              headerActions: _vm.tableHeaderActions,
              mobileConfig: {
                title: "meldedatum",
                headers: [
                  "schadennr",
                  "sparte",
                  "schadensursacheText",
                  "bearbstandLabel"
                ]
              },
              rowId: "schadenId"
            },
            on: {
              "click-meldedatum": _vm.openSchaden,
              "headerAction-Schaden-VEREINIGEN": function($event) {
                return _vm.openVertragVereinigenModal()
              },
              "action-DELETE": _vm.deleteSchadensmeldung
            },
            scopedSlots: _vm._u(
              [
                !_vm.insuranceAssigned
                  ? {
                      key: "zurdnenAktion",
                      fn: function(row) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "insurance__table--company",
                              attrs: {
                                title:
                                  "Schaden übernehmen und Dokument direkt zuordnen"
                              }
                            },
                            [
                              _c("PhArrowFatRight", {
                                staticClass: "clickable",
                                attrs: { size: 24 },
                                on: {
                                  click: function($event) {
                                    return _vm.confirmAssignInsurance(row)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  : null
              ],
              null,
              true
            ),
            model: {
              value: _vm.selectedRows,
              callback: function($$v) {
                _vm.selectedRows = $$v
              },
              expression: "selectedRows"
            }
          })
        : _c("div", [_vm._v("Keine Daten")]),
      _c("SchadenVereinigenModal", {
        ref: "SchadenVereinigenModal",
        attrs: { vertragList: _vm.selectedRows },
        on: {
          save: function($event) {
            return _vm.mergeSchaden($event)
          }
        }
      }),
      _c(
        "BaseModal",
        {
          ref: "assignInsuranceConfirmation",
          attrs: {
            modalTitle: "Schaden übernehmen und Dokument direkt zuordnen",
            labelButtonConfirm: "Bestätigen"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.assignInsurance()
            }
          }
        },
        [
          _vm._v(
            " Bitte bestätigen Sie, dass Sie den Schaden annehmen und die Dokumente direkt zuordnen möchten. "
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }