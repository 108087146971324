<template>
  <div class="datenschutz_container">
    <div class="datenschutz_wrapper">
      <div class="banner">DATENSCHUTZERKLÄRUNG</div>
      <hr />
      <editable-frame type="datenschutz_text" />
      <br/>
      <div class="fc-alert fc-alert-warning" v-if="isIntern">Hier kommt die für den Makler bestimmte Datenschutzerklärung.</div>
    </div>
  </div>
</template>
<script>
import CMS_TYPES from "@/store/cms/types";
import CORE_TYPES from "@/store/core/types";
import EditableFrame from "@/components/cms/EditableFrame.vue";
import { mapGetters } from "vuex";

export default {
  components :{
    EditableFrame,
  },
  computed: {
    ...mapGetters({
      getDatenschutz: CMS_TYPES.GETTERS.GET_PRIVACY_LINK,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
    }),
    isDatenschutzReady() {
      return this.getDatenschutz !== "";
    }
  },

  mounted() {
    window.scrollTo(0, 0);
   /*  if (!this.isIntern){
      this.$store.dispatch(
        CMS_TYPES.ACTIONS.RETRIEVE_PRIVACY_LINK,
        "datenschutz"
      );
    } */
  }
};
</script>
<style scoped>
.datenschutz_container {
  margin: 0 auto;
  padding-left: 1em;
  padding-right: 1em;
  max-width: 1200px;
}
.banner {
  font-size: 26px;
  text-align: left;
  color: var(--color-primary);
}
.datenschutz_wrapper {
  margin: 0 auto;
}
.datenschutz-text {
  text-align: justify;
  padding: 2em;
}
@media only screen and (max-width: 1024px) {
  .datenschutz-container {
    margin-top: 17em;
  }
}
</style>