var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseHeaderContainer",
        [
          _c(
            "div",
            { staticClass: "top-navbar__container-bg" },
            [
              _c("Logout", { staticClass: "mr-3", attrs: { size: 20 } }),
              _c("div", { staticClass: "logged-user__container mr-5" }, [
                _vm._v(_vm._s(_vm.loginName))
              ])
            ],
            1
          ),
          _c("BaseTopProgressBar"),
          _c("div", { staticClass: "navbar-workspaces__container-bg" }, [
            _c(
              "div",
              { staticClass: "fc-container navbar-workspaces__container" },
              [
                _c("div", { staticClass: "navbar-container__logo-container" }, [
                  _vm.logoLoginBoxBackground
                    ? _c("img", {
                        staticClass: "container__img",
                        attrs: { src: _vm.logoLoginBoxBackground }
                      })
                    : _vm._e()
                ]),
                _c("div", { staticClass: "login-title" }, [
                  _vm._v(" " + _vm._s(_vm.loginWelcomeTitle) + " ")
                ])
              ]
            )
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "fc-container" },
        [
          _c("SsoHinweise", { attrs: { isSsoKonto: _vm.loginName && true } }),
          _c("SsoKontoOverview")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }