<template>
  <Table
    tableId="de0b0b03-b535-42c1-aeb1-299e6d9553af"
    cardView
    :headers="headers"
    :rows="rows"
    hidePagination
  />
</template>


<script>
import BERICHTE_TYPES from '@/store/berichte/types';
import { TextColumn, CurrencyColumn, DateColumn, NumberColumn, PillColumn } from "@/components/table2/table_util.js";
import { getPillInfo } from "@/views/berichte/Altersvorsorge.vue";
import Table from "@/components/table2/Table.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Table,
  },
  computed: {
    ...mapGetters({
      altersvorsorge: BERICHTE_TYPES.GETTERS.ALTERSVORSORGE,
    }),
    headers() {
      return {
        center: [
          TextColumn("zlBStatus", "Zulagenstatus").addCellProps({lineClamp: 2}),
          TextColumn("zlKommentarstatus", "Kommentar zum Zulagenstatus").addCellProps({lineClamp: 2}),
          CurrencyColumn("zlGezahlteZulagen", "Gezahlte Zulagen"),
          PillColumn("zlUnmittelbarBerichtigt", "Unmittelbar Zulag.berechtigter"),
          PillColumn("zlEhegattendaten", "Ehegattendaten vorhanden"),
          CurrencyColumn("zlBeitrag", "Geleisteter Jahresbeitrag"),
          PillColumn("zlBeamter", "Beamter"),
          TextColumn("zlStatus", "Kinderzulage beantragt"),
          NumberColumn("zlAnzahlKinder", "Zulag.berechtigte Kinder lt. Zfa"),
          // TextColumn("zlDauerantrag", "Dauerzulagenantrag liegt vor"),
          DateColumn("zlDauerantragsdatum", "Dauerzulagenantrag vom"),
          // TextColumn("zlVollmachtdatum", "Freigabe Datenmeldung Sonderausgaben liegt vor"),
          DateColumn("zlVollmachtdatum", "Vollmacht vom"),
          PillColumn("zlAuslandsversicherung", "Pflichtversicherung im Ausland"),
          TextColumn("zlMitgliedsnummer", "Alterskassen Mitgliedsnr"),
        ]
      };
    },
    rows() {
      return [
        {...(this.altersvorsorge?.zulagen || {})}
      ].map(row => ({
        ...row,
        zlUnmittelbarBerichtigt: getPillInfo(row.zlUnmittelbarBerichtigt),
        zlEhegattendaten: getPillInfo(row.zlEhegattendaten),
        zlBeamter: getPillInfo(row.zlBeamter),
        zlAuslandsversicherung: getPillInfo(row.zlAuslandsversicherung),
      }))
    },
  },
};
</script>