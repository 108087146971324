var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.configuringFlatMenu || _vm.configuringForms
        ? _c(
            "GhostLoading",
            { staticClass: "m-0", attrs: { type: "custom" } },
            [_c("Block", { attrs: { type: "form-input" } })],
            1
          )
        : _c(
            "form",
            {
              staticClass: "global-search-form global-search-form-modal",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.noop.apply(null, arguments)
                }
              }
            },
            [
              _c("Autocomplete", {
                attrs: {
                  label: _vm.label,
                  placeholder: "Zur Suche Text eingeben",
                  itemsCustomers: _vm.suggestCustomers[_vm.currentValue] || [],
                  itemsCompanies: _vm.suggestCompanies[_vm.currentValue] || [],
                  itemsRoutes: _vm.suggestRoutes[_vm.currentValue] || [],
                  itemsFonds: _vm.suggestFonds[_vm.currentValue] || [],
                  itemsBrokers: _vm.suggestBrokers[_vm.currentValue] || [],
                  itemsInsurances:
                    _vm.suggestInsurances[_vm.currentValue] || [],
                  itemsForms: _vm.suggestForms[_vm.currentValue] || [],
                  itemsCustomerData:
                    _vm.suggestCustomerData[_vm.currentValue] || [],
                  itemsDepots: _vm.suggestDepots[_vm.currentValue] || [],
                  itemsCustomerSearchCommands:
                    _vm.customerSearchCommands[_vm.currentValue] || [],
                  itemsUnterdepotData:
                    _vm.suggestUnterdepotData[_vm.currentValue] || [],
                  itemsCalendar:
                    _vm.suggestCalendarData[_vm.currentValue] || [],
                  itemsExceptions:
                    _vm.suggestExceptions[_vm.currentValue] || [],
                  flatMenuCustomer: _vm.flatMenuCustomer,
                  isLoading: _vm.lastRequestUrl
                },
                on: {
                  input: _vm.autocompleteInput,
                  selected: _vm.selected,
                  gotosearch: _vm.gotoSearch
                }
              })
            ],
            1
          ),
      _c("RecentMenuOpened", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.value && !_vm.isMobileNativeContext,
            expression: "!value && !isMobileNativeContext"
          }
        ],
        on: {
          clicked: function($event) {
            return _vm.$emit("recentMenuOpened")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }