<template>

  <div>
    <PageHeaderTitleNavigation :title="$appNavigation.currentMenu.label" />

    <div class="box__container" tid="bfff8623-735a-4e23-b7bd-f91c2140d956" v-if="layoutMetadata && loading== false">
      <p>{{layoutMetadata.header}}</p>
      <p>{{layoutMetadata.head1}}</p>
      <p>{{layoutMetadata.text1}}</p>
      <div v-html="layoutMetadata.text2"></div>
      <p v-if="layoutMetadata.text3" v-html="layoutMetadata.text3"></p>
      <DownloadLink 
         v-if="layoutMetadata.url"
        asButton
        :title="layoutMetadata.actionButtonLabel"
        :href="layoutMetadata.url"
      />      
    </div>    
    <GhostLoading v-else-if="loading" type="paragraph" useBoxContainer />
    <NoData v-else />



  </div>
    
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import CORE_TYPES from "@/store/core/types";
import GELDWAESCHEBELEHRUNG_TYPES from "../../store/geldwaeschebelehrung/types";
import { mapGetters } from "vuex";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import DownloadLink from '@/components/core/download/DownloadLink.vue'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';

export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    DownloadLink,
    GhostLoading,
    NoData,
  },
  data: function () {
      return {
        loading: true,
      }
  },
  computed: {
    ...mapGetters({
      layoutMetadata: GELDWAESCHEBELEHRUNG_TYPES.GETTERS.LAYOUT_METADATA,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      isFA: CORE_TYPES.GETTERS.IS_FA,
    }),



  },
  mounted() {
    this.retrieveData();  
  },
  methods: {
    retrieveData() {
      this.loading = true;

      const isMitarbeiter = this.$route.meta.isMitarbeiter;

      this.$store.dispatch(
              GELDWAESCHEBELEHRUNG_TYPES.ACTIONS.GET_LAYOUT_METADATA, {isMitarbeiter}
            ).then(() => {
                this.loading = false;
            });  

    },
  },
  async beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu Geldwäschebelehrung Übersicht', 
      fullPath: from.fullPath,
      breadcrumb: to?.meta?.breadcrumb?.label,
    });


    next();
  },


    
}
</script>