<template>
  <div class="last-customers-opened__container">
    <div v-if="showTitle" class="last-customers-opened--title">Zuletzt geöffnete Kunden</div>
    <Carousel v-if="lastCustomersOpened.length > 0" class="last-customers-opened--carousel" :values="customersOpenedValues" :options="carouselOptions" />
    <NoData v-else noIcon />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CUSTOMER_SEARCH_TYPES from '@/store/customerSearch/types';
import CORE_TYPES from '@/store/core/types';

import Carousel from '@/components/core/carousel/Carousel.vue';
import NoData from '@/components/core/NoData.vue';

export default {
  props: {
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      carouselOptions: {
        item: {
          width: 100,
          height: 116,
        },
      }
    };
  },
  computed: {
    ...mapGetters({
      lastCustomersOpened: CUSTOMER_SEARCH_TYPES.GETTERS.OPEN_CUSTOMERS,
      apiAddressLegacy: CORE_TYPES.GETTERS.API_ADDRESS_LEGACY,
    }),
    customersOpenedValues() {
      return this.lastCustomersOpened.map(item => ({
        component: {
          template: `<div class="options-user-img" :style="{'background-image': 'url(${this.apiAddressLegacy}/${item.data.pictureUrl})'}"></div>`
        },
        label: this.getCustomerLabel(item),
        action: () => {
          this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { customerId: item.data.customerID });
          this.$emit('opened');
        }
      }));
    },
  },
  methods: {
    getCustomerLabel(item) {
      const personalDataAddress = item?.data?.person?.personalDataAddress || null;
      if(personalDataAddress) {
        if(!personalDataAddress?.firstName) {
          return `${personalDataAddress.lastName || ''}<br>`;
        } else {
          return `${personalDataAddress.firstName}<br>${personalDataAddress.lastName}`;
        }
      } else {
        return (item.name || item.customerID || '');
      }
    },
  },
  components: {
    Carousel,
    NoData,
  },
}
</script>

<style lang="scss" scoped>
.last-customers-opened__container {
  .last-customers-opened--title {
    margin: 0 0 6px;
  }

  .last-customers-opened--carousel {
    margin: 16px 0;
  }
}
</style>
<style>
.options-user-img {
  border-radius: 50%;
  width: 68px;
  height: 68px;
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
