import CUSTOMER_FILTERS_TYPES from './types';

export default {
 
  [CUSTOMER_FILTERS_TYPES.GETTERS.GET_CUSTOMER_FILTERS_SETUP](state) {
    return state.customerFiltersSetup;
  },

  [CUSTOMER_FILTERS_TYPES.GETTERS.GET_CFDATA](state) {
    return state.cfdata;
  },
  [CUSTOMER_FILTERS_TYPES.GETTERS.GET_SELECTED_CUSTOMERS](state) {
    return state.selectedCustomers;
  },

}