var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "origin-of-funds-component" },
    _vm._l(_vm.componentFields, function(component) {
      return _c(
        "div",
        {
          key: component.id,
          staticClass: "origin-of-funds-component-input-container"
        },
        [
          component.type === "SWITCHER"
            ? _c("InputCheckBoxItem", {
                staticClass:
                  "origin-of-funds-component-input-switcher-container",
                attrs: {
                  label: component.label,
                  value: _vm.internalValues[component.id],
                  disabled: _vm.disabled[component.id]
                },
                on: {
                  input: function($event) {
                    return _vm.handleInputChange($event, component)
                  }
                }
              })
            : _vm._e(),
          component.type === "TEXT_CURRENCY"
            ? _c("InputField", {
                staticClass:
                  "origin-of-funds-component-input-currency-container",
                attrs: {
                  type: "currency",
                  value: _vm.internalValues[component.id],
                  precision: _vm.precision,
                  disabled: _vm.disabled[component.id],
                  isComponentHalfSize: ""
                },
                on: {
                  change: function($event) {
                    return _vm.handleInputChange($event, component)
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }