<template>

  <div class="Deckblatt">
    <InsuranceOptionMenu title="Deckblatt" />

    <div class="box__container">

     <!--  <InputRadioBoxGroup 
      title="Bitte wählen Sie die Art des Dokuments, für das das Deckblatt erstellt werden soll."
      v-model="form.artDokuments" 
      :validateUntouched="true"
      :values="artDokumentsValues"
      labelClass="font-bold"/> -->

      <p>Diese Deckblätter dienen nur dem Hochladen von Dokumenten in das Dokumentenarchiv des Kunden im MSC.</p>

      <InputRadioBoxGroup 
      title="Bitte wählen Sie die Art der Person"
      v-model="form.auswahlPerson" 
      :validateUntouched="true"
      :values="auswahlPersonValues"
      labelClass="font-bold"/>

      <ComboBox
        label="Versicherte Personen"
        v-model="form.person"
        :values="personen"
        :firstEmpty="false"
        isComponentHalfSize
        :disabled="form.auswahlPerson!=='CHECK_PERSON'"
      />     

      <InputField
        isComponentHalfSize
        label="Seitenzahl des hochzuladenden Dokuments"
        v-model="form.seitenzahl"
      />        

      <InputToggleSwitch
          label="Der Antrag wurde bzw. wird von mir selbst an die Gesellschaft geschickt."
          class="parameter-search__input-toggle-switch"
          v-model="form.bereitsGesendet"
      />      

      <p>Deckblätter Versicherung anlegen</p>

      <span v-for="(link,idx) of layoutData.deckblaetterVersicherung" v-bind:key="link.key">
        <DownloadLink 
          downloadServicePath="/deckblattAlsPDF"
          :title="link.value"
          filename="Deckblatt_Versicherungen.pdf"
          :queryParams="{
              vertragId,
              action: link.key,
              showPersonId: form.person.value,
              seitenzahl: form.seitenzahl,
              bereitsGesendet: form.bereitsGesendet,
              textField: encodeURIComponent(form.textField),

          }"/><br>
      
      </span>

      <p>Deckblätter Versicherungsschaden anlegen</p>

      <ComboBox
        label="Benutze Schadensfall"
        v-model="form.schadensfall"
        :values="schadensfall"
        :firstEmpty="false"
        isComponentHalfSize
      />

      
      <div v-if="form.schadensfall!=='NICHT_VERWENDEN'">
        <span v-for="(link,idx) of layoutData.deckblaetterVersicherungsschaden" v-bind:key="link.key">
          <DownloadLink 
            downloadServicePath="/deckblattAlsPDF"
            :title="link.value"
            filename="Deckblatt_Versicherungsschaden.pdf"
            :queryParams="{
              vertragId,
              action: link.key,
              showPersonId: form.person.value,
              seitenzahl: form.seitenzahl,
              bereitsGesendet: form.bereitsGesendet,
              schadenId: form.schadensfall,
              textField: encodeURIComponent(form.textField),
            }"/><br>
        
        </span>
      </div>

      <p>Text der bei Warnungen / Stornos angezeigt wird.</p>

      <div class="d-flex">
          <Table v-if="rows.length"
              class="col-6 mr-2"
              tableId="62e38a92-abf1-4c29-b681-ec28d90b96b2"
              :headers="headers"
              :rows="rows"
              :rowsPerPage="20">
              <template v-slot:arrowAction="row"> 
                  <PhArrowRight class="clickable" :size="16" @click="addText(row)" />
              </template>        
          </Table>

          <InputTextArea
              class="col-6"
              :rows="14"
              label="Bitte ergänzen Sie fehlende Angaben:"
              v-model="form.textField">
          </InputTextArea>
      </div>

    </div>

  </div>

</template>

<script>
  import { mapGetters } from "vuex";

  import DECKBLATT_VERSICHERUNGEN_TYPES from "@/store/deckblattversicherungen/types";
  import CORE_TYPES from "@/store/core/types";
  import BaseModal from '@/components/core/BaseModal.vue';
  import BaseButton from "@/components/core/BaseButton.vue";
  import DownloadLink from '@/components/core/download/DownloadLink.vue';
  import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
  import Table from "@/components/table2/Table.vue";
  import {TextColumn, DateTimeColumn, NumberColumn, ActionColumn, SimpleAction, SlotColumn} from "@/components/table2/table_util.js";
  import { addParamToURL } from '@/helpers/utils-helper';
  import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
  import InputField from '@/components/core/forms/InputField.vue';
  import ComboBox from '@/components/core/forms/ComboBox.vue';
  import {PhArrowRight} from "phosphor-vue";
  import InputTextArea from '@/components/core/forms/InputTextArea.vue';
  import InsuranceOptionMenu from '@/views/versicherungen/insurance-group/InsuranceOptionMenu.vue';

  export default {

    data() {
      return {
        loading: false,
        form: {
          artDokuments: 'CHECK_PDF_ANZEIGEN',
          auswahlPerson: 'CHECK_KUNDE',
          person: {},
          seitenzahl: 0,
          bereitsGesendet: false,
          schadensfall: "NICHT_VERWENDEN",
          textField: 'Bitte ergänzen Sie fehlende Angaben:',
        },

        artDokumentsValues: [
          {value: 'CHECK_PDF_ANZEIGEN', label: 'Deckblatt anzeigen'},
          {value: 'CHECK_DRUCKEN', label: 'Deckblatt drucken'},
        ],
        auswahlPersonValues: [
            {value: 'CHECK_KUNDE', label: 'Kunde'},
            {value: 'CHECK_PERSON', label: 'Versicherte Personen'},
          ]
        ,        
      };
    },
    components: {
      BaseButton,
      BaseModal,
      DownloadLink,
      InputRadioBoxGroup,
      Table,
      InputToggleSwitch,
      InputField,
      ComboBox,
      PhArrowRight,
      InputTextArea,
      InsuranceOptionMenu,
    },
    watch: {
    },
    computed: {
      ...mapGetters({
        layoutData: DECKBLATT_VERSICHERUNGEN_TYPES.GETTERS.LAYOUT_DATA,
        isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
        isIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
      }),
      vertragId() {
        return this.$route.params.vertragId;
      },
      personen() {
        if (this.layoutData?.personenList) {  
          return this.layoutData?.personenList.map(s => {
            return {
              label: s.value,
              value: s.key,
            }
            })
        }

        return [];

      },
      schadensfall() {

        if (this.layoutData?.schadensfall) {
          return this.layoutData?.schadensfall.map(s => {
          return {
            label: s.value,
            value: s.key,
          }
        })
        }

        return [];
      },    
      rows() {
        if (this.layoutData?.textVersicherungen) {
          return this.layoutData?.textVersicherungen.map(t => {
            return {
              textKurzform: t.key,
              text: t.value,
            };
          })
        }

        return [];
        
      },
      headers() {
        return {
              lockedLeft: [
                  TextColumn("textKurzform", "Text Kurzform"),
                  TextColumn("text", "Text").makeHidden(),
              ],
              lockedRight: [
                  SlotColumn("arrowAction", ""),
              ],
          };        
      }
    },
    mounted() {
      this.retrieveLayout();
    },
    beforeRouteLeave(to, from, next) {
      this.$addBreadcrumb({
        label: 'zurück zu Deckblatt Versicherungen',
        fullPath: from.fullPath,
        breadcrumb: 'Deckblatt Versicherungen',
      }); 

      next();
    },
    methods: {
      retrieveLayout() {
        this.$store.dispatch(DECKBLATT_VERSICHERUNGEN_TYPES.ACTIONS.RETRIEVE_LAYOUT, {
          vertragId: this.vertragId,
        });
      },
      closeModal() {
        this.$refs.modalAdd.close();
      },
      addText(whatRow) {
        this.form.textField = this.form.textField.concat('\r\n').concat(whatRow?.text)
      }
    }
  };
</script>