<template>
  <BaseModal
    ref="modal"
    modalTitle="Makler Kriterien auswählen"
    labelButtonConfirm="Neue markierte hinzufügen"
    labelButtonCancel="Abbrechen"
    @onConfirmButton="emitSave"
  >
    <template #default>
      <Table
        v-if="rows.length"
        tableId="579b1982-470b-4522-8482-1246152081a5"
        rowId="label"
        :headers="headers"
        :rows="rows"
        :rowsPerPage="rows.length"
        :selected="selectedRows"
        hidePagination
        @selected="handleSelected"
      />
      <NoData v-else noIcon />
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/core/BaseModal.vue';
import Table from '@/components/table2/Table.vue';
import { TextColumn, } from '@/components/table2/table_util';
import NoData from '@/components/core/NoData.vue';

export default {
  props: {
    alleStrukturKriterien: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['save'],
  data() {
    return {
      selectedRows: [],
    };
  },
  computed: {
    headers() {
      return {
        lockedLeft: [
          TextColumn('label', 'Makler Kriterien'),
        ],
        center: [],
        lockedRight: [],
      };
    },
    rows() {
      return this.alleStrukturKriterien.map(m => ({ label: m, }));
    },
  },
  methods: {
    open(selectedRows = []) {
      this.$set(this, 'selectedRows', [ ...selectedRows, ]);
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    emitSave() {
      this.$emit('save', [ ...this.selectedRows, ]);
    },
    handleSelected(rows) {
      const newlySelected = rows.map(r => r.label);
      this.$set(this, 'selectedRows', [ ...newlySelected, ]);
    },
  },
  components: {
    BaseModal,
    Table,
    NoData,
  },
}
</script>
