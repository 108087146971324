<template>
    <div>
        <PageHeaderTitleNavigation title="ebase" subtitle="Übertragung" />

        <div>
            <div class="row">
              <div class="col-12">
                <h2>Wertpapiersuche</h2>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <FondsFinderSuchMaske/>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <FondsFinderPositionsList @goBack="addFonds($event)" zurueckButtonText="Abbrechen" :addPositionsOnBack="false"/>
              </div>
            </div>
          </div>
          <div class="antrag-components__container">
            <div class="box__container">
              <FormHeader label="Wertpapiere"/>
              <p></p>
              <InputField v-model="positionData.bisherDepotnr" label="bisherige Depotnummer"/>              
                <Table
                    tableId="f168f001-d40a-4a9a-ba2b-aa8d3ce74e30"
                    :headers="headers"
                    :rows="rows"
                    rowId="index"
                    hidePagination
                    @action-DELETE="deleteFond($event.index - 1)"
                >
                    <template #Anteile="row">
                        <InputField v-model="positionData['anteile' + row.index]"/>
                    </template>
                    <template #Gesamtbestand="row">
                        <Input type="checkbox" v-model="positionData['alle' + row.index]"/>
                    </template>
                </Table>
              <BaseButton v-if="show == true" @click="searchingPositions=true">Wertpapiere hinzufügen</BaseButton>
              <div class="row">
                <div class="col">
                  <BaseButton @click="onBackButton">Zurück zum Formular</BaseButton>
                </div>
              </div>
            </div>
          </div>
    </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex';
import { calculateAntragID } from '@/components/antrag/antrag-utils';
import antragNavigationMixin from '@/mixins/antrag/antrag-navigation-mixin.js';
import ANTRAG_TYPES from '@/store/antrag/types';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import FormHeader from '@/components/core/forms/FormHeader.vue';
import InputField from '@/components/core/forms/InputField.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import FondsFinderSuchMaske from '@/components/antrag/fondsfinder/FondsFinderSuchMaske.vue';
import FondsFinderPositionsList from '@/components/antrag/fondsfinder/FondsFinderPositionsList.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, SlotColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import {PhTrash} from 'phosphor-vue';

export default {
  mixins: [antragNavigationMixin],
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    FormHeader,
    InputField,
    BaseButton,
    FondsFinderSuchMaske,
    FondsFinderPositionsList,
    Table,
    PhTrash,
  },
  computed: {
    ...mapGetters({
      antraege: ANTRAG_TYPES.GETTERS.ANTRAEGE,
      antraegeData: ANTRAG_TYPES.GETTERS.ANTRAEGE_DATA,
    }),
    antrag () {
      return this.antraege[this.antragId]
    },
    component() {
      return this.antrag.components[this.step].find(component => component.id == this.categoryId)
    },
    componentData() {
      return this.antraegeData[this.antragId][this.component.id]
    },
    rows() {
        const actions = [
            SimpleAction("DELETE", PhTrash, "Löschen"),
        ];
        const rows = [];
        for (let i = 1; i <= this.component.config.anzahlFonds; i++) {
            if (this.positionData["isin" + i]) {
                rows.push({
                    Wertpapiername: this.positionData["fondsname" + i],
                    ISIN: this.positionData["isin" + i],
                    Anteile: this.positionData["anteile" + i],
                    index: i,
                    actions,
                })
            }
        }
        return rows;
    },
  },
  data() {
    return {
      antragId: null,
      fondIndex: -1,
      categoryId: null,
      positionData: {
      },
      show: false,
      step: null,
      wertpapierConfig: {
        type: "FFBUEBERTRAGUNG",
        buttonText: "Wertpapier hinzufügen",
      },
      spar: [
        {value: false, label: "nicht übernehmen"},
        {value: true, label: "zu löschen:"},
      ],
      entnahme: [
        {value: false, label: "nicht übernehmen"},
        {value: true, label: "zu löschen:"},
      ],
     
      searchingPositions: true,
        headers: {
            lockedLeft: [
                TextColumn("Wertpapiername", "Wertpapiername"),
            ],
            center: [
                TextColumn("ISIN", "ISIN"),
                SlotColumn("Anteile", "Stückzahl"),
                SlotColumn("Gesamtbestand", "Gesamtbestand"),
            ],
            lockedRight: [
                ActionColumn("actions", "Aktionen"),
            ],
        },
    }
  },
  mounted() {
    const antragsName = this.$route.params.antragsName
    this.antragId = calculateAntragID({antragsName: antragsName, lagerstelle: this.$route.params.lagerstelle})
    this.fondIndex = this.$route.params.fondIndex
    this.categoryId = this.$route.params.categoryId
    this.step = this.$route.params.step
    this.positionData = {
      ...this.componentData[this.fondIndex]
    }
    searchingPositions: true
  },
  methods: {
    onBackButton() {
      this.navigateBackToCurrentStep()
    },
    savePosition() {
      const newData = this.componentData.slice()
      newData[this.fondIndex] = this.positionData
      const payload = {
        id: this.antragId,
        data: {
          [this.component.id]: newData
        }
      }
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_ANTRAG_DATA, payload)
    },
    addFonds(fonds) {
      this.searchingPositions = false
      if (!fonds || fonds.length == 0)
        return
      // combine previously selected fonds with the new fonds
      const positionList = this.getPositions()
      fonds.forEach(fond => {
        positionList.push({
          isin: fond.isin,
          name: fond.wertpapiername,
          anteile: fond.stueckzahl,
          alle: fond.alle,
        })
      })
      this.setPositions(positionList)
    },
    deleteFond(index) {
      const positionList = this.getPositions()
      positionList.splice(index, 1)
      this.setPositions(positionList)
    },
    getPositions() {
      const positionList = []
      for (let i = 1; i <= this.component.config.anzahlFonds; i++)
        if (this.positionData["isin" + i])
          positionList.push({
            isin: this.positionData["isin" + i],
            name: this.positionData["fondsname" + i],
            anteile: this.positionData["anteile" + i],
            alle: this.positionData["alle" + i],
          })
      return positionList
    },
    setPositions(positionList) {
      for (let i = 1; i <= this.component.config.anzahlFonds; i++) {
        Vue.set(this.positionData, "isin" + i, positionList.length >= i ? positionList[i-1].isin : null)
        Vue.set(this.positionData, "fondsname" + i, positionList.length >= i ? positionList[i-1].name : null)
        Vue.set(this.positionData, "anteile" + i, positionList.length >= i ? positionList[i-1].anteile : null)
      }
    },
    sparChanged(event) {
      spar.value = false
     if(positionData.sparUebernehmen == "false")
      positionData.sparLoeschen = true
     else
      positionData.sparLoeschen = false
    },   
    sparChanged() {
    if(data.sparUebernehmen == "false")
      data.sparLoeschen = true
     else
      data.sparLoeschen = false
    },
    onchange() {
     if(data.entUebernehmen == "false")
      data.entLoeschen = true
     else
      data.entLoeschen = false
    },
  },
  beforeDestroy() {
    this.savePosition()
  }
}
</script>
<style scoped>

</style>