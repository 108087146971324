import {
  STAMMDATEN_SUBSTEP, ADRESSE_SUBSTEP, GEBURTSTAG_SUBSTEP, GEBURTSTAG_SUBSTEP_FIRMA,
  BERUF_SUBSTEP, STEUERDATEN_SUBSTEP, LEGITIMATIONSURKUNDE_SUBSTEP,
  MAP_SUBSTEP_KEY_TO_TITLE, MAP_SUBSTEP_KEY_TO_TITLE_FIRMA, MAP_SUBSTEP_KEY_TO_TITLE_FIRMA_ALS_ZUS_PERSON
} from './persoenliche-daten-steps-utils';
import { groupBy } from '@/helpers/commonfunctions';

export const DEPOTEROFFNUNGEN_WARNING_MESSAGE = 'Die gekennzeichneten Daten sind zwingend notwendig für Depoteröffnungen!';
export const DEPOTEROFFNUNGEN_FIELD_MESSAGE = 'Dieses Feld ist zwingend für die Depoteröffnung notwendig.';

export const DEPOTEROFFNUNGEN_CUSTOMER_STEP = '/persoenlichedaten/customer-data/steps/stammdaten';
export const DEPOTEROFFNUNGEN_PERSON_STEP = '/persoenlichedaten/person-data/:personId/stammdaten';

export const MAP_WARNING_ID_TO_PRIVAT_SUBSTEP_KEY = {
  'personalDataAddress.title': STAMMDATEN_SUBSTEP.substepKey,
  'personalDataAddress.lastName': STAMMDATEN_SUBSTEP.substepKey,
  'personalDataAddress.personalStatus': STAMMDATEN_SUBSTEP.substepKey,
  'personalDataAddress.zip': ADRESSE_SUBSTEP.substepKey,
  'personalDataAddress.city': ADRESSE_SUBSTEP.substepKey,
  'personalDataAddress.street': ADRESSE_SUBSTEP.substepKey,
  'personalDataBirth.nationality': GEBURTSTAG_SUBSTEP.substepKey,
  'personalDataBirth.dayOfBirth': GEBURTSTAG_SUBSTEP.substepKey,
  'personalDataBirth.placeOfBirth': GEBURTSTAG_SUBSTEP.substepKey,
  'personalDataBirth.nameAtBirth': GEBURTSTAG_SUBSTEP.substepKey,
  'personalDataBirth.countryOfBirth': GEBURTSTAG_SUBSTEP.substepKey,
  'employment.profession': BERUF_SUBSTEP.substepKey,
  'employment.industry': BERUF_SUBSTEP.substepKey,
  'personalDataTax.maritalPropertyRegime': STEUERDATEN_SUBSTEP.substepKey,
  'personalDataTax.taxResidence': STEUERDATEN_SUBSTEP.substepKey,
  'personalDataTax.taxID': STEUERDATEN_SUBSTEP.substepKey,
  'legitimation.legitimType': LEGITIMATIONSURKUNDE_SUBSTEP.substepKey,
  'legitimation.legitimNumber': LEGITIMATIONSURKUNDE_SUBSTEP.substepKey,
  'legitimation.meldebescheinigung': LEGITIMATIONSURKUNDE_SUBSTEP.substepKey,
  'legitimation.authority': LEGITIMATIONSURKUNDE_SUBSTEP.substepKey,
  'legitimation.dateOfIssue': LEGITIMATIONSURKUNDE_SUBSTEP.substepKey,
  'legitimation.validTo': LEGITIMATIONSURKUNDE_SUBSTEP.substepKey,
};

export const MAP_WARNING_ID_TO_FIRMA_SUBSTEP_KEY = {
  'personalDataAddress.title': STAMMDATEN_SUBSTEP.substepKey,
  'personalDataAddress.lastName': STAMMDATEN_SUBSTEP.substepKey,
  'personalDataAddress.zip': ADRESSE_SUBSTEP.substepKey,
  'personalDataAddress.city': ADRESSE_SUBSTEP.substepKey,
  'personalDataAddress.street': ADRESSE_SUBSTEP.substepKey,
  'personalDataBirth.dayOfBirth': GEBURTSTAG_SUBSTEP_FIRMA.substepKey,
  'employment.industry': GEBURTSTAG_SUBSTEP.substepKey,
  'personalDataTax.taxResidence': STEUERDATEN_SUBSTEP.substepKey,
  'personalDataTax.taxID': STEUERDATEN_SUBSTEP.substepKey,
  'legitimation.legitimNumber': LEGITIMATIONSURKUNDE_SUBSTEP.substepKey,
};

export const MAP_WARNING_ID_TO_FIRMA_ALS_ZUS_PERSONSUBSTEP_KEY = {
  'personalDataAddress.lastName': STAMMDATEN_SUBSTEP.substepKey,
  'personalDataAddress.zip': ADRESSE_SUBSTEP.substepKey,
  'personalDataAddress.city': ADRESSE_SUBSTEP.substepKey,
  'personalDataAddress.street': ADRESSE_SUBSTEP.substepKey, 
};

export const DEPOTEROFFNUNGEN_WARNINGS_ID_VALID_FOR_FIRMA = Object.keys(MAP_WARNING_ID_TO_FIRMA_SUBSTEP_KEY);
export const DEPOTEROFFNUNGEN_WARNINGS_ID_VALID_FOR_FIRMA_ALS_ZUS_PERSON = Object.keys(MAP_WARNING_ID_TO_FIRMA_ALS_ZUS_PERSONSUBSTEP_KEY);

function filterDepoteroffnungenWarnings(depoteroffnungenWarnings = [], isFirma = false, isMainPerson = true) {
  if(isFirma){
    if(isMainPerson){
      return depoteroffnungenWarnings.filter(w => DEPOTEROFFNUNGEN_WARNINGS_ID_VALID_FOR_FIRMA.indexOf(w.id) >= 0) 
    }else{
      return depoteroffnungenWarnings.filter(w => DEPOTEROFFNUNGEN_WARNINGS_ID_VALID_FOR_FIRMA_ALS_ZUS_PERSON.indexOf(w.id) >= 0) 
    }
  }
  return  depoteroffnungenWarnings;
}

function prepareDepoteroffnungenWarnings(stepKey, depoteroffnungenWarnings = [], isFirma = false, isMainPerson = true) {
 const mapper = isFirma ? (isMainPerson ? MAP_SUBSTEP_KEY_TO_TITLE_FIRMA : MAP_SUBSTEP_KEY_TO_TITLE_FIRMA_ALS_ZUS_PERSON ) : MAP_SUBSTEP_KEY_TO_TITLE;

  const filtered = filterDepoteroffnungenWarnings(depoteroffnungenWarnings, isFirma, isMainPerson);
  const warnings = filtered?.map(warning => ({
    message: warning.message ? warning.message : DEPOTEROFFNUNGEN_WARNING_MESSAGE,
    stepKey,
    substepKey: isFirma ? (isMainPerson ? MAP_WARNING_ID_TO_FIRMA_SUBSTEP_KEY[warning.id] : MAP_WARNING_ID_TO_FIRMA_ALS_ZUS_PERSONSUBSTEP_KEY[warning.id]) : MAP_WARNING_ID_TO_PRIVAT_SUBSTEP_KEY[warning.id],
  }))
  .map(error => {
    return {
      ...error,
      title: `Stammdaten > ${mapper[error.substepKey]}`
    };
  });
  const grouped = groupBy(warnings, 'substepKey');
  return Object.keys(grouped).map(key => grouped[key][0]);
}

export function prepareDepoteroffnungenWarningsCustomer(depoteroffnungenWarnings = [], isFirma = false, isMainPerson = true) {
  return prepareDepoteroffnungenWarnings(DEPOTEROFFNUNGEN_CUSTOMER_STEP, depoteroffnungenWarnings, isFirma, isMainPerson);
}

export function prepareDepoteroffnungenWarningsPerson(personId, depoteroffnungenWarnings = [], isFirma = false, isMainPerson = true) {
  if(!personId) return [];
  return prepareDepoteroffnungenWarnings(DEPOTEROFFNUNGEN_PERSON_STEP.replace(/:personId/gi, personId), depoteroffnungenWarnings, isFirma, isMainPerson);
}

export function hasDepoteroffnungenWarnings(depoteroffnungenWarnings = [], stepKey = '', isFirma = false, isMainPerson = true) {
  const warnings = stepKey.includes('/stammdaten') && filterDepoteroffnungenWarnings(depoteroffnungenWarnings, isFirma, isMainPerson);
  return warnings?.length > 0;
}
