var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("InputField", {
        attrs: {
          type: "currency",
          label: "Bruttoprämie gem. Zahlweise",
          isComponentHalfSize: ""
        },
        on: {
          change: function($event) {
            return _vm.inputCurrencyChanged(
              "praemieBtoZahlweise",
              $event,
              _vm.getSparteId
            )
          }
        },
        model: {
          value: _vm.praemieBtoZahlweise,
          callback: function($$v) {
            _vm.praemieBtoZahlweise = $$v
          },
          expression: "praemieBtoZahlweise"
        }
      }),
      _c("InputField", {
        attrs: {
          type: "currency",
          isComponentHalfSize: "",
          precision: 2,
          label: "Prämie (Jahr)",
          disabled: true
        },
        model: {
          value: _vm.praemieBtoPa,
          callback: function($$v) {
            _vm.praemieBtoPa = $$v
          },
          expression: "praemieBtoPa"
        }
      }),
      _c("InputField", {
        attrs: {
          type: "currency",
          isComponentHalfSize: "",
          precision: 2,
          label: "Nettoprämie gem. Zahlweise",
          disabled: _vm.disabled
        },
        on: {
          change: function($event) {
            return _vm.inputCurrencyChanged(
              "praemieNtoZahlweise",
              $event,
              _vm.getSparteId
            )
          }
        },
        model: {
          value: _vm.praemieNtoZahlweise,
          callback: function($$v) {
            _vm.praemieNtoZahlweise = $$v
          },
          expression: "praemieNtoZahlweise"
        }
      }),
      _c("InputField", {
        attrs: {
          type: "currency",
          isComponentHalfSize: "",
          precision: 2,
          label: "Nettoprämie pro Jahr",
          disabled: true
        },
        model: {
          value: _vm.praemieNtoPa,
          callback: function($$v) {
            _vm.praemieNtoPa = $$v
          },
          expression: "praemieNtoPa"
        }
      }),
      _c("DatePickerField", {
        attrs: {
          value: _vm.data && _vm.data.begin,
          isComponentHalfSize: "",
          label: "Sparte Beginn",
          disabled: true
        }
      }),
      _c("EntitySelector", {
        attrs: {
          label: "Tarif",
          values: _vm.getComboboxTarif(_vm.data),
          firstEmpty: true,
          hasEditButton: false,
          isComponentHalfSize: ""
        },
        on: {
          input: function($event) {
            return _vm.inputHauptdataChanged(
              { componentId: "tarif", value: $event },
              _vm.getSparteId
            )
          },
          new: function($event) {
            return _vm.newTarif()
          }
        },
        model: {
          value: _vm.tarif,
          callback: function($$v) {
            _vm.tarif = $$v
          },
          expression: "tarif"
        }
      }),
      _c("InputToggleSwitch", {
        attrs: {
          label: "nur vertriebene Tarife",
          inLineLabel: "",
          suppressValidationMessage: ""
        },
        on: {
          input: function($event) {
            return _vm.inputHauptdataChanged(
              { componentId: "onlyActivTarif", value: $event },
              _vm.getSparteId
            )
          }
        },
        model: {
          value: _vm.onlyActivTarif,
          callback: function($$v) {
            _vm.onlyActivTarif = $$v
          },
          expression: "onlyActivTarif"
        }
      }),
      _vm.stepConfig &&
      _vm.stepConfig.erlaubePerson &&
      _vm.layoutModus &&
      _vm.layoutModus == "SPARTE_PERSON"
        ? _c("EntitySelectorPersonPicker", {
            attrs: {
              label: "Versicherte Person",
              isComponentHalfSize: "",
              values: _vm.getComboboxVersichertePerson()
            },
            on: {
              change: function($event) {
                return _vm.inputHauptdataChanged(
                  { componentId: "versichertePerson", value: $event },
                  _vm.getSparteId
                )
              }
            },
            model: {
              value: _vm.versichertePerson,
              callback: function($$v) {
                _vm.versichertePerson = $$v
              },
              expression: "versichertePerson"
            }
          })
        : _vm._e(),
      _c(
        "BaseModal",
        {
          ref: "tarif",
          attrs: { labelButtonCancel: "Zurück" },
          on: {
            onCancelButton: function($event) {
              return _vm.closeTarifModal()
            },
            onConfirmButton: function($event) {
              return _vm.addTarif()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [_vm._v(" Daten Versicherungstarif ")]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "div",
            [
              _c("InputField", {
                attrs: { label: "Bezeichnung" },
                model: {
                  value: _vm.form.bezeichnung,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "bezeichnung", $$v)
                  },
                  expression: "form.bezeichnung"
                }
              }),
              _c("ComboBox", {
                attrs: {
                  label: "Gesellschaft",
                  values: _vm.comboboxValues
                    ? _vm.comboboxValues.gesellschaft
                    : [],
                  disabled: false
                },
                on: {
                  change: function($event) {
                    return _vm.inputCurrencyChanged(
                      $event,
                      null,
                      _vm.zeithorizontStr
                    )
                  }
                },
                model: {
                  value: _vm.form.gesellschaft,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "gesellschaft", $$v)
                  },
                  expression: "form.gesellschaft"
                }
              }),
              _c("ComboBox", {
                attrs: {
                  label: "Sparte",
                  values: _vm.comboboxValues
                    ? _vm.comboboxValues.gesellschaft
                    : [],
                  disabled: false
                },
                on: {
                  change: function($event) {
                    return _vm.inputCurrencyChanged(
                      $event,
                      null,
                      _vm.zeithorizontStr
                    )
                  }
                },
                model: {
                  value: _vm.form.sparteTarif,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "sparteTarif", $$v)
                  },
                  expression: "form.sparteTarif"
                }
              }),
              _vm.isIntern || _vm.isBroker
                ? _c(
                    "div",
                    [
                      _c("InputField", {
                        attrs: {
                          label: "Sichtbar für",
                          disabled: _vm.isBroker
                        },
                        model: {
                          value: _vm.form.visibility,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "visibility", $$v)
                          },
                          expression: "form.visibility"
                        }
                      }),
                      _c("InputToggleSwitch", {
                        attrs: {
                          label: "inclusive Struktur",
                          inLineLabel: "",
                          suppressValidationMessage: "",
                          disabled: _vm.isBroker
                        },
                        model: {
                          value: _vm.form.struktur,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "struktur", $$v)
                          },
                          expression: "form.struktur"
                        }
                      }),
                      _c(
                        "BaseButton",
                        {
                          attrs: { isSecondary: "" },
                          on: {
                            click: function($event) {
                              return _vm.vorgabeTarifdaten()
                            }
                          }
                        },
                        [_c("span", [_vm._v("Vorgabe Tarifdaten")])]
                      ),
                      _c("InputToggleSwitch", {
                        attrs: {
                          label: "Tarif wird nicht mehr vertrieben",
                          inLineLabel: "",
                          suppressValidationMessage: ""
                        },
                        model: {
                          value: _vm.form.inactive,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "inactive", $$v)
                          },
                          expression: "form.inactive"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }