<template>
  <div class="profile-menu-container">
    <div class="profile-menu-content" @click="openProfileModal">
      <LoggedUserInformation class="mr-2" />
      <UserPicture />
    </div>

    <BaseModal ref="profileModal" :showDefaultButtons="false" :actions="baseModalActions" @action-ABMELDEN="logout"
      @action-KUNDENZUGANG="openKundenzugang" @action-MAKLERZUGANG="openMaklerzugang" @close="onClose">
      <template #modalTitle>
        <div class="dashboard-panel-config-modal--title-container clearfix">
          <span class="dashboard-panel-config-modal--title">{{ loginUsername }}</span>
        </div>
      </template>
      <div>
        <BoxContainer>
          <GlobalSearch :size="24" label="Globale Suche" @close="closeProfileModal" />
        </BoxContainer>

        <BoxContainer title="Mein Betreuer" v-if="isCustomer">
          <AgentContact @click="closeProfileModal" />
        </BoxContainer>

        <BoxContainer>
          <NavigationList :routes="navigationRoutes" />
        </BoxContainer>

        <BoxContainer v-if="showActionButtons">
          <InputToggleSwitch 
            v-if="showToggleProtectYourAccount"
            :disabled="isToggleProtectYourAccountDisabled"
            :label="protectYourAccountLabel"
            :value="biometricLoginSettings.isPermissionGranted"
            @input="toggleBiometricSettingsIsPermissionGranted()" />
        </BoxContainer>

        <div class="d-flex justify-content-center align-items-center text-center">{{ currentVersion }}</div>

      </div>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';
import BRIDGE_TYPES from '@/store/bridge/types';

import LoggedUserInformation from '@/components/core/header/LoggedUserInformation.vue';
import UserPicture from '@/components/core/header/UserPicture.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import GlobalSearch from '@/components/core/header/buttons/GlobalSearch.vue';
import AgentContact from '@/components/agentContact/AgentContact.vue'
import BoxContainer from "@/components/core/BoxContainer.vue";
import NavigationList from "@/components/core/NavigationList.vue";
import { BaseModalSimpleAction } from '@/components/core/base-modal-actions/base-modal-actions-utils';

import { ROLES, VIEW_ROLES, } from '@/router/roles';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import { AUTHENTICATION_TYPES } from '@/store/bridge';

export default {
  components: {
    LoggedUserInformation,
    UserPicture,
    BaseModal,
    GlobalSearch,
    AgentContact,
    BoxContainer,
    NavigationList,
    InputToggleSwitch,
  },
  data() {
    return {
      isLoading: {
        showToggleProtectYourAccount: false,
        biometricSettings: false,
      }
    }
  },
  computed: {
    ...mapGetters({
      isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      biometricLoginSettings: BRIDGE_TYPES.GETTERS.BIOMETRIC_LOGIN_SETTINGS
    }),
    showToggleProtectYourAccount() {
      return this.biometricLoginSettings.isAvailable;
    },
    protectYourAccountLabel() {
      const { authenticationType } = this.biometricLoginSettings;
      return `Zugang per ${AUTHENTICATION_TYPES?.[authenticationType] || ''} schützen`;
    },
    showActionButtons() {
      return this.showToggleProtectYourAccount;
    },
    isToggleProtectYourAccountDisabled() {
      return this.isLoading.showToggleProtectYourAccount || this.isLoading.biometricSettings;
    },
    showLogout() {
      return !this.hasRoles(ROLES.HIDE_LOGOUT_BUTTON);
    },
    loginUsername() {
      return this.loginData?.fullname || this.loginData?.loginUsername;
    },
    currentVersion() {
      const buildVersion = document.documentElement.getAttribute('data-build-timestamp');
      return `v${buildVersion}`
    },
    navigationRoutes() {
      const routes = [];

      if (this.hasRoles([[VIEW_ROLES.VIEW_CUSTOMER]])) {
        routes.push({ label: 'Mein Profil', route: '/persoenlichedaten/customer-data' });
      }

      if (this.hasRoles([[VIEW_ROLES.VIEW_CUSTOMER_ONLY]])) {
        routes.push({ label: 'Datenschutzerklärung', route: '/persoenlichedaten/privacy/main' });
      }

      routes.push({ label: 'Lizenzen', route: "/home/licenses" });
      routes.push({ label: 'SingleSignOn', route: '/home/sso' });

      if (this.hasRoles([[VIEW_ROLES.VIEW_CUSTOMER], [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS]])) {
        const linkDatenschutz = this.hasRoles([ROLES.FA]) ? 'https://www.finanzadmin.at/info-datenschutz'
          : 'https://www.finance-home.de/datenschutz/';
        routes.push({ label: 'Hinweise zum Datenschutz', externalLink: linkDatenschutz });
      }
    
      
      return [...routes,];
  },
  baseModalActions() {
    const modalActions = [];

    if (this.showLogout) {
      modalActions.push(BaseModalSimpleAction('ABMELDEN', 'Abmelden').withPrimary(() => false));
    }

    if (this.hasRoles([VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS])) {
      modalActions.push(BaseModalSimpleAction('KUNDENZUGANG', 'Kundensicht').withPrimary(() => false));
    }

    if (this.hasRoles([VIEW_ROLES.VIEW_BROKER_AS_BYPASS])) {
      modalActions.push(BaseModalSimpleAction('MAKLERZUGANG', 'Maklersicht').withPrimary(() => false));
    }

    return modalActions;
  },
},
methods: {
  async toggleBiometricSettingsIsPermissionGranted() {
    const payload = {
      loginName: this.biometricLoginSettings.loginName,
      isPermissionGranted: !this.biometricLoginSettings.isPermissionGranted,
    }
    
    this.isLoading.showToggleProtectYourAccount = true;
    try {
      await this.$store.dispatch(BRIDGE_TYPES.ACTIONS.UPDATE_BIOMETRIC_LOGIN_SETTINGS, payload)
      await this.loadBiometricLoginSettings();
    } finally {
      this.isLoading.showToggleProtectYourAccount = false;
    }
  },
  async loadBiometricLoginSettings() {
    try {
      this.isLoading.biometricSettings = true;
      const loginName = this.loginData.userid;
      await this.$store.dispatch(BRIDGE_TYPES.ACTIONS.LOAD_BIOMETRIC_LOGIN_SETTINGS, loginName);
    } catch(e) {
      // empty block
    } finally {
      this.isLoading.biometricSettings = false;
    }
  },
  openProfileModal() {
    this.$refs.profileModal.open()
    this.loadBiometricLoginSettings();
    document.addEventListener(BRIDGE_TYPES.HANDLERS.APP_RETURNED_FROM_BACKGROUND, this.loadBiometricLoginSettings);
  },
  closeProfileModal() {
    this.$refs.profileModal.close()
  },
  onClose() {
    document.removeEventListener(BRIDGE_TYPES.HANDLERS.APP_RETURNED_FROM_BACKGROUND, this.loadBiometricLoginSettings);
  },
  logout() {
    this.$store.dispatch(CORE_TYPES.ACTIONS.LOGOUT);
  },
  openKundenzugang() {
    this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_KUNDENZUGANG);
    this.closeProfileModal();
  },
  openMaklerzugang() {
    this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_MAKLERZUGANG);
    this.closeProfileModal();
  },
},
}
</script>

<style scoped>
.profile-menu-container {
  display: flex;
}

.profile-menu-content {
  display: flex;
  justify-content: space-around;
}
</style>