import DOKUMENTENARCHIV_TYPES from './types';
import CORE_TYPES from '@/store/core/types'
import { makeQueryParam } from '@/helpers/utils-helper';
import { prepareFilenameForDownload } from '@/helpers/string-helper';

export default {
  [DOKUMENTENARCHIV_TYPES.GETTERS.DOKUMENTENARCHIV_DATA](state) {
    const { dokumentenarchivData, virtualNumberOfRecords, } = state;

    const mapNumberOfRecords = node => ({
      ...node,
      nodes: node.nodes?.map(mapNumberOfRecords),
      numberOfRecords: (node.numberOfRecords || 0) + (virtualNumberOfRecords?.[node.nodeId] || 0),
    });

    return {
      ...dokumentenarchivData,
      ...('nodes' in dokumentenarchivData ? { nodes: dokumentenarchivData.nodes?.map(mapNumberOfRecords), } : {})
    };
  }, 
  [DOKUMENTENARCHIV_TYPES.GETTERS.DOKUMENT_ACTIONS](state) {
    return state.dokumentActions;
  },    
  [DOKUMENTENARCHIV_TYPES.GETTERS.ADDITIONAL_UPLOAD_INPUTS](state) {
    return state.additionalUploadInputs;
  },    
  [DOKUMENTENARCHIV_TYPES.GETTERS.DOCUMENTS](state) {
    return state.documentNode;
  },   
  [DOKUMENTENARCHIV_TYPES.GETTERS.DOCUMENT_PAGE](state) {
    return state.documentNodePage;
  },   
  [DOKUMENTENARCHIV_TYPES.GETTERS.ADDITIONAL_DOCUMENTS](state) {
    return state.additionalDocuments;
  },   
  [DOKUMENTENARCHIV_TYPES.GETTERS.SELECTED_ROWS](state) {
    return [...state.selectedRows];
  },
  [DOKUMENTENARCHIV_TYPES.GETTERS.GET_ATTACHMENT_LINK_BY_ID](state, getters) {
    return (filename, docId, tempFileId) => {
      const apiAddress = getters[CORE_TYPES.GETTERS.API_ADDRESS]
      const token = getters[CORE_TYPES.GETTERS.GET_TOKEN]

      let params ;
      if(docId==null|| docId==''){
        params = makeQueryParam({  tempFileId, token })
      }  else {
        params = makeQueryParam({ docId, tempFileId, token })
      }
      return `${apiAddress}/download_service/docId/${prepareFilenameForDownload(filename)}?${params}`
    }
  },
  [DOKUMENTENARCHIV_TYPES.GETTERS.IS_LOADING_NODE](state) {
    return !!state.loadingNode;
  },
  [DOKUMENTENARCHIV_TYPES.GETTERS.TREE_LOADING](state) {
    return state.loadingTree;
  },
  [DOKUMENTENARCHIV_TYPES.GETTERS.TEMP_UPLOAD_FILES](state) {
    return state.tempUploadFiles || [];
  },
  [DOKUMENTENARCHIV_TYPES.GETTERS.SHOW_SCHADEN_DOCUMENTS](state) {
    return state.documentsFilter?.showSchaden;
  },
  [DOKUMENTENARCHIV_TYPES.GETTERS.SHOW_DELETED_DOCUMENTS](state) {
    return state.documentsFilter?.showDeleted;
  },
  [DOKUMENTENARCHIV_TYPES.GETTERS.IS_SELECTED_ALL_IN_FOLDER](state) {
    return state.isSelectedAllInFolder;
  },
  [DOKUMENTENARCHIV_TYPES.GETTERS.DOC_LABEL](state) {
    return state.documentsFilter?.docLabel;
  },
  [DOKUMENTENARCHIV_TYPES.GETTERS.SORTING](state) {
    return state.sortKey ? {
        key: state.sortKey,
        sortDirection: state.sortAscending ? 'asc' : 'desc',
    } : undefined;
  },
  [DOKUMENTENARCHIV_TYPES.GETTERS.DOCUMENTS_FILTER](state) {
    return { ...state.documentsFilter || {}, };
  },
  [DOKUMENTENARCHIV_TYPES.GETTERS.IS_DROP_ACTION_ACTIVE](state) {
    return state.dropActionActive === true;
  },
  [DOKUMENTENARCHIV_TYPES.GETTERS.PROHYP_DOCUMENTS](state) {
    return state.prohypDocuments;
  },
}