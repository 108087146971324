<template>
<div>
    <Table
        tableId="ccdb782e-dc29-40ec-973f-eb5ee6bd4f4e"
        :headers="headers"
        :rows="rows"
        rowId="name"
        hidePagination
    >
        <template #value1Year="row">
            <KennzahlenValue :value="row.value1Year" :type="row.type" />
        </template>
        <template #value3Years="row">
            <KennzahlenValue :value="row.value3Years" :type="row.type" />
        </template>
        <template #value5Years="row">
            <KennzahlenValue :value="row.value5Years" :type="row.type" />
        </template>
    </Table>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import KENNZAHLEN_TYPES from '@/store/kennzahlen/types';

import Table from "@/components/table2/Table.vue";
import {TextColumn, SlotColumn} from "@/components/table2/table_util.js";
import KennzahlenValue from './KennzahlenValue.vue';

export default {
    components: {
        Table,
        KennzahlenValue,
    },
    data() {
        return {
            headers: {
                center: [
                    TextColumn("name", "Name"),
                    SlotColumn("value1Year", "1 Jahr", undefined, 1).alignRight(),
                    SlotColumn("value3Years", "3 Jahre", undefined, 1).alignRight(),
                    SlotColumn("value5Years", "5 Jahre", undefined, 1).alignRight(),
                ],
            },
        };
    },
    computed: {
        ...mapGetters({
            kennzahlenData: KENNZAHLEN_TYPES.GETTERS.KENNZAHLEN_DATA,
        }),
        rows() {
            return this.kennzahlenData?.dataFrst || [];
        },
    },
}
</script>
