export const MUTATION_PREFIX = 'KENNZAHLEN: ';
export const ACTIONS_PREFIX = 'KENNZAHLEN_ACTIONS_';
export const GETTERS_PREFIX = 'KENNZAHLEN_GETTERS_';


export default {
  MUTATIONS: {
    FIND_KENNZAHLEN_DATA_SUCCESS: MUTATION_PREFIX + 'FIND_KENNZAHLEN_DATA_SUCCESS',
  },
  ACTIONS: {
    FIND_KENNZAHLEN_DATA: ACTIONS_PREFIX + 'FIND_KENNZAHLEN_DATA',
  },
  GETTERS: {
    KENNZAHLEN_DATA: GETTERS_PREFIX + 'KENNZAHLEN_DATA',
  },
}
