<template>
  <div class="box__container-wrap">
    <div class="box__container-rows">
      <div class="interne-bemerkung-content">
        <span class="interne-bemerkung-text" v-html="interneBemerkungText"></span> <button type="button" class="btn-clear clickable" @click="edit()"><PhPencilLine :size="16" /></button>
      </div>
    </div>

    <BaseModal 
      ref="interneBemerkungEditModal"
      modalTitle="Anmerkungen bearbeiten"
      :confirmDisabled="!edited"
      @onConfirmButton="save()"
    >
      <template #default>
        <InputTextArea label="Anmerkungen (für den Kunden nicht sichtbar)" v-model="interneBemerkungEdit" :rows="5" :maxlength="4000" />
      </template>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CUSTOMERDATA_TYPES from '@/store/customerData/types';

import { PhPencilLine } from 'phosphor-vue';
import BaseModal from '@/components/core/BaseModal.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import {sanitize} from '@/helpers/string-helper.js';


export default {
  data() {
    return {
      interneBemerkungEdit: null,
    };
  },
  computed: {
    ...mapGetters({
      interneBemerkung: CUSTOMERDATA_TYPES.GETTERS.INTERNE_BEMERKUNG,
    }),
    interneBemerkungText() {
      const interneBemerkung = this.interneBemerkung || 'Keine Daten';
      return sanitize(`${interneBemerkung?.replace(/\n/gi, '<br>')}`);
    },
    edited() {
      return this.interneBemerkung !== this.interneBemerkungEdit;
    },
  },
  methods: {
    edit() {
      this.interneBemerkungEdit = this.interneBemerkung;
      this.$refs.interneBemerkungEditModal.open();
    },
    save() {
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.SAVE_INTERNE_BEMERKUNG, this.interneBemerkungEdit);
    },
  },
  mounted() {
    this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_INTERNE_BEMERKUNG);
  },
  components: {
    PhPencilLine,
    BaseModal,
    InputTextArea,
  },
}
</script>

<style src='../steckbrief.scss' lang='scss' scoped></style>

<style scoped>
.interne-bemerkung-text {
  word-break: break-word;
}
</style>
