var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.page
    ? _c(
        "BaseModal",
        {
          ref: "modal",
          attrs: {
            labelButtonCancel: "Schließen",
            confirmDisabled: _vm.saving,
            modalTitle: "Fax/E-Mail manuell zuordnen",
            autoClose: false,
            showConfirmButton: _vm.valid(),
            size: "full"
          },
          on: {
            onCancelButton: _vm.close,
            onCloseButton: _vm.close,
            onConfirmButton: function($event) {
              return _vm.save()
            }
          }
        },
        [
          _c("DownloadLink", {
            attrs: {
              target: "_blank",
              rel: "noopener noreferer",
              title: this.linkTitle,
              downloadServicePath: "/pages_download",
              queryParams: this.getDownloadsPageParams
            }
          }),
          _c("br"),
          _c("InputField", {
            ref: "input_kun",
            attrs: { label: "Kundennr" },
            on: {
              input: function($event) {
                return _vm.onChangeCustomerNr($event)
              }
            },
            model: {
              value: _vm.page.kundennrIn,
              callback: function($$v) {
                _vm.$set(_vm.page, "kundennrIn", $$v)
              },
              expression: "page.kundennrIn"
            }
          }),
          _vm.page.kundennrIn
            ? _c("InputField", {
                ref: "input_name",
                attrs: { disabled: true, validateUntouched: true },
                model: {
                  value: _vm.page.kundenname,
                  callback: function($$v) {
                    _vm.$set(_vm.page, "kundenname", $$v)
                  },
                  expression: "page.kundenname"
                }
              })
            : _vm._e(),
          _c("ComboBox", {
            attrs: {
              label: "Gesellschaft",
              firstEmpty: true,
              values: this.config ? this.config.allItems : null
            },
            on: {
              change: function($event) {
                return _vm.onChangeGesell($event)
              }
            },
            model: {
              value: _vm.page.gesellschaftIn,
              callback: function($$v) {
                _vm.$set(_vm.page, "gesellschaftIn", $$v)
              },
              expression: "page.gesellschaftIn"
            }
          }),
          _c("ComboBox", {
            attrs: { label: "", firstEmpty: true, values: this.commun },
            on: {
              change: function($event) {
                return _vm.onChangeGesellComm($event)
              }
            }
          }),
          _c("ComboBox", {
            attrs: {
              label: "Email/Fax",
              firstEmpty: true,
              validateUntouched: true,
              values: this.config ? this.config.artItems : null
            },
            model: {
              value: _vm.page.kommunikationsArt,
              callback: function($$v) {
                _vm.$set(_vm.page, "kommunikationsArt", $$v)
              },
              expression: "page.kommunikationsArt"
            }
          }),
          _c("InputField", {
            ref: "input_adress",
            attrs: { label: this.getAddressLabel },
            model: {
              value: _vm.page.commAddress,
              callback: function($$v) {
                _vm.$set(_vm.page, "commAddress", $$v)
              },
              expression: "page.commAddress"
            }
          }),
          this.page && this.page.kommunikationsArt == "EMAIL"
            ? _c("InputField", {
                attrs: { label: "Subject" },
                model: {
                  value: _vm.page.subject,
                  callback: function($$v) {
                    _vm.$set(_vm.page, "subject", $$v)
                  },
                  expression: "page.subject"
                }
              })
            : _vm._e(),
          this.page && this.page.kommunikationsArt == "EMAIL"
            ? _c(
                "div",
                [
                  _c("HtmlEditor", {
                    attrs: {
                      value:
                        this.page && this.page.htmlText
                          ? this.page.htmlText
                          : "",
                      isFontParams: ""
                    },
                    on: {
                      input: function($event) {
                        _vm.htmlText = $event
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }