var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "openSignatureAdditionalDocuments",
          attrs: {
            labelButtonConfirm: "Ok",
            modalTitle: "zusätzliche Dokumente",
            showDefaultButtons: false
          },
          on: {
            close: function($event) {
              return _vm.close()
            }
          }
        },
        [
          _c(
            "div",
            [
              _c("div", { staticClass: "mb-4" }, [_vm._t("title")], 2),
              _vm._l(_vm.selectedAdditionalDocuments, function(file, index2) {
                return _c("div", { key: index2, staticClass: "row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex" },
                      [
                        _vm.canGenerateSignoViewerLink(file)
                          ? [
                              _c("PhFile", { attrs: { size: 24 } }),
                              _c("DownloadLink", {
                                attrs: {
                                  title: file.name || "Dokument",
                                  downloadServicePath:
                                    "/get_signo_document_preview",
                                  filename: file.name,
                                  queryParams: {
                                    nodeId: encodeURIComponent(file.nodeId),
                                    viewFileId: encodeURIComponent(
                                      file.viewFileId
                                    )
                                  }
                                }
                              })
                            ]
                          : [_vm._v(" " + _vm._s(file.name) + " ")]
                      ],
                      2
                    ),
                    _c("div", [
                      _c(
                        "div",
                        { staticClass: "row" },
                        _vm._l(file.parameters, function(param, index3) {
                          return _c(
                            "div",
                            { key: index3, staticClass: "col-12" },
                            [
                              param.type === "IMAGE"
                                ? _c("div", [
                                    _vm._v(" " + _vm._s(param.label) + " ")
                                  ])
                                : _vm._e(),
                              param.type !== "IMAGE"
                                ? _c("div", [
                                    _c("span", [
                                      _vm._v(_vm._s(param.label) + ":")
                                    ]),
                                    _vm._v(" "),
                                    _c("span", [_vm._v(_vm._s(param.value))])
                                  ])
                                : _vm._e()
                            ]
                          )
                        }),
                        0
                      )
                    ])
                  ])
                ])
              })
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }