var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h5", { staticClass: "my-4" }, [_vm._v("Zusammenfassung")]),
    _c("div", [
      _c("div", { staticClass: "row py-1 mx-2" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "col-12 col-xl-4 text-right column-amount cell" },
          [_c("CurrencyLabel", { attrs: { value: _vm.summeEinkommen } })],
          1
        )
      ]),
      _c("div", { staticClass: "row py-1  mx-2" }, [
        _vm._m(1),
        _c(
          "div",
          { staticClass: "col-12 col-xl-4 text-right column-amount cell" },
          [_c("CurrencyLabel", { attrs: { value: _vm.summeVermoegen } })],
          1
        )
      ]),
      _c("div", { staticClass: "row py-1  mx-2" }, [
        _vm._m(2),
        _c(
          "div",
          { staticClass: "col-12 col-xl-4 text-right column-amount cell" },
          [_c("CurrencyLabel", { attrs: { value: _vm.sumImmobilien } })],
          1
        )
      ]),
      _c("div", { staticClass: "row py-1  mx-2" }, [
        _vm._m(3),
        _c(
          "div",
          { staticClass: "col-12 col-xl-4 text-right column-amount cell" },
          [_c("CurrencyLabel", { attrs: { value: _vm.summeAnlage } })],
          1
        )
      ]),
      _c("div", { staticClass: "row py-1  mx-2" }, [
        _vm._m(4),
        _c(
          "div",
          { staticClass: "col-12 col-xl-4 text-right column-amount cell" },
          [_c("CurrencyLabel", { attrs: { value: _vm.summeSchulden } })],
          1
        )
      ]),
      _c("div", { staticClass: "row py-1  mx-2" }, [
        _vm._m(5),
        _c(
          "div",
          { staticClass: "col-12 col-xl-4 text-right column-amount cell" },
          [_c("CurrencyLabel", { attrs: { value: _vm.summeAusgaben } })],
          1
        )
      ]),
      _c("div", { staticClass: "row py-1  mx-2 font-bold" }, [
        _vm._m(6),
        _c(
          "div",
          { staticClass: "col-12 col-xl-4 text-right column-amount cell" },
          [_c("CurrencyLabel", { attrs: { value: _vm.summeFreiMonatlich } })],
          1
        )
      ]),
      _c("div", { staticClass: "row py-1  mx-2 font-bold" }, [
        _vm._m(7),
        _c(
          "div",
          { staticClass: "col-12 col-xl-4 text-right column-amount cell  " },
          [_c("CurrencyLabel", { attrs: { value: _vm.sumCapital } })],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 col-xl-8" }, [
      _c("label", { staticClass: "co ml-0" }, [
        _vm._v(" 1. regelmäßiges Einkommen ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 col-xl-8" }, [
      _c("label", { staticClass: "co ml-0" }, [
        _vm._v(" 2. aktuelle liquide Vermögenswerte ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 col-xl-8" }, [
      _c("label", { staticClass: "co ml-0" }, [
        _vm._v(" 3. Immobilienvermögen ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 col-xl-8" }, [
      _c(
        "label",
        {
          staticClass: "co ml-0",
          attrs: { appPopover: "Kapitalanlage / sonstige Vermögenswerte" }
        },
        [_vm._v(" 4. Kapitalanlage / sonstige Vermögenswerte ")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 col-xl-8" }, [
      _c("label", { staticClass: "co ml-0" }, [
        _vm._v(" 5. Verbindlichkeiten / Schulden ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 col-xl-8" }, [
      _c("label", { staticClass: "co ml-0" }, [
        _vm._v(" 6. regelmäßige finanzielle Verpflichtungen ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 col-xl-8" }, [
      _c("label", { staticClass: "col ml-0" }, [
        _vm._v(
          " Summe des monatlich für Anlagezwecke frei zur Verfügung stehenden Einkommens "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 col-xl-8" }, [
      _c("label", { staticClass: "col ml-0" }, [
        _vm._v(
          " Summe des für Anlagezwecke frei zur Verfügung stehenden Kapitals "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }