export const MUTATION_PREFIX = 'SPARTENTYP_EINSTELLUNG_MUTATIONS_';
export const ACTIONS_PREFIX = 'SPARTENTYP_EINSTELLUNG_ACTIONS_';
export const GETTERS_PREFIX = 'SPARTENTYP_EINSTELLUNG_GETTERS_';

export default {
  MUTATIONS: {
    LOAD_SPARTENTYP_LIST_SUCCESS: MUTATION_PREFIX + 'LOAD_SPARTENTYP_LIST_SUCCESS',
    LOAD_SPARTENTYP_SUCCESS: MUTATION_PREFIX + 'LOAD_SPARTENTYP_SUCCESS',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    SELECT_SPARTENTYP: MUTATION_PREFIX + 'SELECT_SPARTENTYP',
    SET_CONFIG_DEFINITION_EDITED: MUTATION_PREFIX + 'SET_CONFIG_DEFINITION_EDITED',
    RESET_CONFIG_DEFINITION_EDITED: MUTATION_PREFIX + 'RESET_CONFIG_DEFINITION_EDITED',
    GET_CONFIG_DEFINITION_SUCCESS: MUTATION_PREFIX + 'GET_CONFIG_DEFINITION_SUCCESS',
    REMOVE_SPARTENTYP_SUCCESS: MUTATION_PREFIX + 'REMOVE_SPARTENTYP_SUCCESS',
  },
  ACTIONS: {
    LOAD_SPARTENTYP_LIST: ACTIONS_PREFIX + 'LOAD_SPARTENTYP_LIST',
    LOAD_SPARTEN_TYPEN: ACTIONS_PREFIX + 'LOAD_SPARTEN_TYPEN',
    SAVE_CONFIG_DEFINITION: ACTIONS_PREFIX + 'SAVE_CONFIG_DEFINITION',
    REMOVE_SPARTENTYP: ACTIONS_PREFIX + 'REMOVE_SPARTENTYP',
    CHANGE_COMPONENT_POSITION: ACTIONS_PREFIX + 'CHANGE_COMPONENT_POSITION',
    RENAME_GROUP: ACTIONS_PREFIX + 'RENAME_GROUP',
    CHANGE_STEP_POSITION: ACTIONS_PREFIX + 'CHANGE_STEP_POSITION',
  },
  GETTERS: {
    SPARTENTYP_LIST: GETTERS_PREFIX + 'SPARTENTYP_LIST',
    SPARTEN_TYPEN: GETTERS_PREFIX + 'SPARTEN_TYPEN',
    SELECTED_SPARTENTYP: GETTERS_PREFIX + 'SELECTED_SPARTENTYP',
    CONFIG_DEFINITION_EDITED: GETTERS_PREFIX + 'CONFIG_DEFINITION_EDITED',
    REMOVED_SPARTENTYP: GETTERS_PREFIX + 'REMOVED_SPARTENTYP',
  }
}