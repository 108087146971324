<template>
  <div v-if="isByPass" class="box__container">
    <div class="box__title">Anschriftfeld</div>
    <template v-if="isMainPerson">
      <div v-if="isEditable" class="mb-3">
        <BaseButton isSecondary @click="copy()">Kundendaten als Anschriftfeld übernehmen</BaseButton>
      </div>
      <div class="row">
        <div class="col-12">
          <InputField label="Anrede" v-model="anschriftfeld.title" :isComponentHalfSize="true"
            :disabled="!isEditable" @change="addCustomerDataEdited('anschriftfeld')" />
          <InputField v-model="anschriftfeld.fullName" :isComponentHalfSize="true" 
            :disabled="!isEditable" @change="addCustomerDataEdited('anschriftfeld')" />
          <InputField v-model="anschriftfeld.co" :isComponentHalfSize="true" 
            :disabled="!isEditable" @change="addCustomerDataEdited('anschriftfeld')" />
          <InputField label="Straße, HNr" v-model="anschriftfeld.street" :isComponentHalfSize="true" 
            :disabled="!isEditable" @change="addCustomerDataEdited('anschriftfeld')" />
          <InputField label="Postleitzahl" v-model="anschriftfeld.zip" :isComponentHalfSize="true" 
            :disabled="!isEditable" @change="addCustomerDataEdited('anschriftfeld')" />
          <InputField label="Ort" v-model="anschriftfeld.city" :isComponentHalfSize="true" 
            :disabled="!isEditable" @change="addCustomerDataEdited('anschriftfeld')" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CUSTOMERDATA_TYPES from '@/store/customerData/types';

import BaseButton from '@/components/core/BaseButton.vue';
import InputField from '@/components/core/forms/InputField.vue';

import persoenlicheDatenMixin from '../persoenliche-daten-mixin';

const HINT_GROUP = 'ANSCHRIFTFELD';

export default {
  mixins: [persoenlicheDatenMixin],
  data() {
    return {
      ...this.initialState(),
    };
  },
  computed: {
    ...mapGetters({
      customerData: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_DATA,
      customerDataEdited: CUSTOMERDATA_TYPES.GETTERS.GET_CUSTOMER_DATA_EDITED,
      isMainPerson: CUSTOMERDATA_TYPES.GETTERS.IS_MAIN_PERSON,
    }),
  },
  methods: {
    initialState() {
      return {
        anschriftfeld: {
          title: '',
          fullName: '',
          co: '',
          street: '',
          zip: '',
          city: '',
        },
      };
    },
    updateGroupsIntoData() {
      this.updateGroupIntoData('anschriftfeld');
    },
    copy() {
      const personalDataAddress = this.customerDataEdited?.personalDataAddress || this.customerData?.personalDataAddress;
      this.anschriftfeld.title = personalDataAddress.title;
      this.anschriftfeld.fullName = `${personalDataAddress.titles || ''} ${personalDataAddress.firstName || ''} ${personalDataAddress.lastName || ''}`.trim();
      this.anschriftfeld.co = personalDataAddress.co;
      this.anschriftfeld.street = personalDataAddress.street;
      this.anschriftfeld.zip = personalDataAddress.zip;
      this.anschriftfeld.city = personalDataAddress.city;
      this.addCustomerDataEdited('anschriftfeld');
    },
    addHint() {
      this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.ADD_HINT, {
        group: HINT_GROUP,
        title: 'Anschriftfeld',
        message: ''
          + 'Hier können Sie ein abweichendes Anschriftenfeld für die Dokumente aus dem smartMSC eintragen. '
          + 'Diese Anschrift kann zum Beispiel für Gemeinschaftsdepots verwendet werden, '
          + 'damit auch der zweite Depotinhaber im Adressfeld genannt wird.',
      });
    },
  },
  mounted() {
    this.addHint();
    this.updateGroupsIntoData();
  },
  beforeDestroy() {
    this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.REMOVE_HINTS, { group: HINT_GROUP, });
  },
  components: {
    BaseButton,
    InputField,
  },
}
</script>
