import TEMPLATES_EDIT_TYPES from './types';
import { getInitialState } from './index'
import Vue from 'vue';
import { getLetterTemplates } from './index';

export default {
  [TEMPLATES_EDIT_TYPES.MUTATIONS.GET_TEMPLATES_TREE](state, payload) {
    Vue.set(state, 'templates', payload);
  },
  [TEMPLATES_EDIT_TYPES.MUTATIONS.GET_TEMPLATES](state, payload) {
    Vue.set(state, 'currentNode', payload);
  },
  [TEMPLATES_EDIT_TYPES.MUTATIONS.GET_PREVIEW](state, payload) {
    Vue.set(state, 'preview', payload);
  },
  [TEMPLATES_EDIT_TYPES.MUTATIONS.GET_STANDARD_TEMPLATE](state, payload) {
    Vue.set(state, 'standard', payload);
  },
  [TEMPLATES_EDIT_TYPES.MUTATIONS.GET_LETTER_TEMPLATES_HTML](state, payload) {
    if (!state.letterTemplates) {
      Vue.set(state, 'letterTemplates', getLetterTemplates());
    }
    Vue.set(state.letterTemplates, 'htmlTemplates', payload);
  },
  [TEMPLATES_EDIT_TYPES.MUTATIONS.GET_LETTER_TEMPLATES_WORD](state, payload) {
    if (!state.letterTemplates) {
      Vue.set(state, 'letterTemplates', getLetterTemplates());
    }
    Vue.set(state.letterTemplates, 'worldTemplates', payload);
  },
  [TEMPLATES_EDIT_TYPES.MUTATIONS.GET_LETTER_TEMPLATE_EDIT](state, payload) {
    Vue.set(state, 'letterTemplate', payload);
  },
  [TEMPLATES_EDIT_TYPES.MUTATIONS.GET_WORD_TEMPLATE_EDIT](state, payload) {
    Vue.set(state, 'wordTemplate', payload);
  },
  [TEMPLATES_EDIT_TYPES.MUTATIONS.GET_LETTER_CATEGORIES](state, payload) {
    Vue.set(state, 'letterCategories', payload);
  },
  [TEMPLATES_EDIT_TYPES.MUTATIONS.UPDATE_TEMPLATE_GRANT](state, payload) {
    let found = state.letterTemplates?.htmlTemplates?.find(template => template.id === payload.id)

    if (!found) {
      found = state.letterTemplates?.worldTemplates?.find(template => template.id === payload.id)
    }

    if (found) {
      Vue.set(found, 'grant', payload.grant)
    }
  },
  [TEMPLATES_EDIT_TYPES.MUTATIONS.UPDATE_TEMPLATE_TAGS](state, payload) {
    let found = state.letterTemplates?.htmlTemplates?.find(template => template.id === payload.id)

    if (!found) {
      found = state.letterTemplates?.worldTemplates?.find(template => template.id === payload.id)
    }

    if (found) {
      Vue.set(found, 'tags', payload.tags)
    }
  },
  [TEMPLATES_EDIT_TYPES.MUTATIONS.UPDATE_TEMPLATE_VISIBLE](state, { id, visible }) {
    let found = state.letterTemplates?.htmlTemplates?.find(template => template.id === id)

    if (!found) {
      found = state.letterTemplates?.worldTemplates?.find(template => template.id === id)
    }

    if (found) {
      Vue.set(found, 'visible', visible)
    }
  },
  [TEMPLATES_EDIT_TYPES.MUTATIONS.GET_BACKGROUND_TEMPLATE](state, payload) {
    Vue.set(state, 'backgroundTemplate', payload)
  },
  [TEMPLATES_EDIT_TYPES.MUTATIONS.DELETE_FILE_BACKGROUND_TEMPLATE](state) {
    const backgroundTemplate = state.backgroundTemplate || {};

    Vue.set(state, 'backgroundTemplate', {
      ...backgroundTemplate,
      backgroundTemplateFileId: null,
      tempBackgroundTemplateFileId: null,
      backgroundTemplateFilename: null,
    })
  },

  [TEMPLATES_EDIT_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },
  [TEMPLATES_EDIT_TYPES.MUTATIONS.SET_HTML_SHORTCUTS](state, payload) {
    Vue.set(state, 'htmlShortcuts', payload);
  },
}
