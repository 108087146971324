var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "card clickable",
      on: {
        click: function($event) {
          return _vm.cardClickEvent()
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "card-content" },
        [
          _vm.title
            ? [
                _vm.titleStyle
                  ? _c("h4", { class: _vm.titleStyle }, [
                      _vm._v(_vm._s(_vm.title))
                    ])
                  : _c("h4", [_vm._v(_vm._s(_vm.title))])
              ]
            : _vm._e(),
          _vm._t("title"),
          _vm._t("default")
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }