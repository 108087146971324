var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mobile-table__container" },
    [
      _vm.selectionActive || _vm.title || _vm.titleActionsIntern.length
        ? _c("PageHeaderTitleNavigation", {
            attrs: {
              isSecondary: "",
              asPrimaryOnly: "",
              actions: _vm.titleActionsIntern
            },
            on: { action: _vm.onHeaderAction },
            scopedSlots: _vm._u(
              [
                {
                  key: "title",
                  fn: function() {
                    return [
                      _vm.hasRows && _vm.selectionActive && !_vm.singleSelection
                        ? _c("InputCheckBoxItem", {
                            staticStyle: { margin: "0px 8px" },
                            attrs: {
                              value: _vm.areAllSelected,
                              indeterminate: _vm.areOnlySomeSelected,
                              disabled: _vm.areAllHiddenDisabled,
                              tid: _vm._generateTidFromString(
                                _vm.tableId + ":__SELECT__"
                              )
                            },
                            on: { input: _vm.onSelectAll }
                          })
                        : _vm._e(),
                      _c("div", { staticClass: "mobile-table__title" }, [
                        _vm._v(" " + _vm._s(_vm.title) + " ")
                      ])
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              256659147
            )
          })
        : _vm._e(),
      !_vm.rows.length
        ? _c("NoData", {
            attrs: { noIcon: !_vm.noDataIcon, content: _vm.noDataContent }
          })
        : [
            _vm._l(_vm.visibleRows, function(ref, index) {
              var _obj

              var row = ref.row
              var id = ref.id
              var customRowStyle = ref.customRowStyle
              return _c(
                "div",
                { key: id + index, staticClass: "row-container d-flex" },
                [
                  _vm.selectionActive
                    ? [
                        _vm.singleSelection && !row.selectedHidden
                          ? _c("InputRadioBoxItem", {
                              staticStyle: {
                                "align-self": "center",
                                margin: "0px 8px"
                              },
                              attrs: {
                                name: "tableSelection",
                                disabled: row.selectedDisabled,
                                value: id,
                                checked: _vm.isSelected[id]
                              },
                              on: {
                                change: function($event) {
                                  return _vm.selectRow(row, id)
                                }
                              }
                            })
                          : !row.selectedHidden
                          ? _c("InputCheckBoxItem", {
                              staticStyle: {
                                "align-self": "center",
                                margin: "0px 8px"
                              },
                              attrs: {
                                disabled: row.selectedDisabled,
                                value: _vm.isCheckmarked(id)
                              },
                              on: {
                                input: function($event) {
                                  return _vm.selectRow(row, id)
                                }
                              }
                            })
                          : _c("div", { staticStyle: { width: "37px" } })
                      ]
                    : _vm._e(),
                  _c(
                    "DownloadLink",
                    _vm._b(
                      {
                        style: _vm.getWidthForIndent(),
                        attrs: {
                          disabled:
                            !row.downloadLinkOptions ||
                            _vm.clickRowDisabled(row),
                          asLabel: "",
                          asLabelNoDisable: ""
                        }
                      },
                      "DownloadLink",
                      row.downloadLinkOptions,
                      false
                    ),
                    [
                      _c(
                        "div",
                        _vm._g(
                          { staticClass: "d-flex" },
                          !row.downloadLinkOptions && !_vm.clickRowDisabled(row)
                            ? {
                                click: function(event) {
                                  return _vm.clickRow(event, row, id)
                                }
                              }
                            : {}
                        ),
                        [
                          _c(
                            "div",
                            {
                              style: Object.assign(
                                {},
                                _vm.getWidthForRow(row),
                                customRowStyle
                              )
                            },
                            [
                              _c(
                                "div",
                                {
                                  class:
                                    ((_obj = { ellipsis: true }),
                                    (_obj["row-title"] = !row.isSubrow),
                                    (_obj["subrow-title"] = row.isSubrow),
                                    _obj)
                                },
                                [
                                  _vm.cardTitleKey
                                    ? _vm._t(
                                        _vm.cardTitleKey,
                                        function() {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(row[_vm.cardTitleKey]) +
                                                " "
                                            )
                                          ]
                                        },
                                        {
                                          row: row,
                                          id: id,
                                          mobileTableContext: true
                                        }
                                      )
                                    : _vm._e()
                                ],
                                2
                              ),
                              _vm._l(_vm.cardHeaders, function(ref) {
                                var key = ref.key
                                var label = ref.label
                                var rowElement = ref.rowElement
                                var props = ref.props
                                var colorFn = ref.colorFn
                                return [
                                  _vm.visibleInCard(key, row)
                                    ? _c(
                                        "div",
                                        { key: key, staticClass: "row-line" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "row-line-label" },
                                            [
                                              _vm._t(
                                                "header_" + key,
                                                function() {
                                                  return [
                                                    _c("span", [
                                                      _vm._v(_vm._s(label))
                                                    ])
                                                  ]
                                                },
                                                null,
                                                { key: key }
                                              )
                                            ],
                                            2
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "row-line-value" },
                                            [
                                              !rowElement
                                                ? _vm._t(key, null, {
                                                    row: row,
                                                    id: id,
                                                    mobileTableContext: true
                                                  })
                                                : _c(
                                                    rowElement,
                                                    _vm._b(
                                                      {
                                                        key: key,
                                                        tag: "component",
                                                        class:
                                                          colorFn &&
                                                          colorFn(row),
                                                        attrs: {
                                                          column: key,
                                                          row: row,
                                                          tid:
                                                            _vm.tableId +
                                                            ":" +
                                                            key +
                                                            ":" +
                                                            id +
                                                            ":"
                                                        },
                                                        on: {
                                                          action: _vm.onAction
                                                        }
                                                      },
                                                      "component",
                                                      props,
                                                      false
                                                    )
                                                  )
                                            ],
                                            2
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              })
                            ],
                            2
                          ),
                          !_vm.clickRowDisabled(row)
                            ? _c(
                                "div",
                                {
                                  staticClass: "ml-1",
                                  staticStyle: { "flex-shrink": "0" }
                                },
                                [
                                  row.downloadLinkOptions
                                    ? _c("ph-file-arrow-down", {
                                        attrs: { size: 20 }
                                      })
                                    : _c("ph-caret-right", {
                                        attrs: { size: 20 }
                                      })
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    ]
                  )
                ],
                2
              )
            }),
            _vm.footer
              ? _vm._l(_vm.footerHeaders, function(ref) {
                  var key = ref.key
                  var label = ref.label
                  var rowElement = ref.rowElement
                  var props = ref.props
                  return _c(
                    "div",
                    { key: key, staticClass: "row-container header-row-line" },
                    [
                      _c("div", { staticClass: "ellipsis" }, [
                        _vm._v(" Summe " + _vm._s(label) + " ")
                      ]),
                      _c(
                        rowElement,
                        _vm._b(
                          {
                            key: key,
                            tag: "component",
                            attrs: {
                              column: key,
                              row: _vm.footer.row,
                              tid:
                                _vm.tableId +
                                ":" +
                                key +
                                ":" +
                                _vm.footer.id +
                                ":"
                            }
                          },
                          "component",
                          props,
                          false
                        )
                      )
                    ],
                    1
                  )
                })
              : _vm._e()
          ],
      !_vm.customRowClick
        ? _c("MobileTableModal", {
            ref: "editModal",
            attrs: {
              headersFlat: _vm.headersFlat,
              row: _vm.openRow,
              tableId: _vm.tableId,
              title: _vm.openRow[_vm.cardTitleKey]
            },
            on: { onAction: _vm.onAction, onClick: _vm.clickCell },
            scopedSlots: _vm._u(
              [
                _vm._l(_vm.headersFlatLabelNull, function(header) {
                  return {
                    key: header.headerKey,
                    fn: function() {
                      return [_vm._t(header.headerKey, null, null, header)]
                    },
                    proxy: true
                  }
                }),
                _vm._l(_vm.headersFlat, function(ref) {
                  var key = ref.key
                  return {
                    key: key,
                    fn: function() {
                      return [
                        _vm._t(key, null, {
                          row: _vm.openRow,
                          id: _vm.openRow.id
                        })
                      ]
                    },
                    proxy: true
                  }
                })
              ],
              null,
              true
            )
          })
        : _vm._e(),
      _vm.mobileTableId
        ? _c("ModalColumnConfig", {
            ref: "columnsConfig",
            attrs: {
              isMobileTable: "",
              title: _vm.title
                ? _vm.title + " - Einstellungen"
                : "Einstellungen",
              tableId: _vm.mobileTableId,
              tableHeaders: _vm.configurableHeaders
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }