<template>
  <Table
    tableId="9f858ff9-182d-4e8d-a16d-e773987b1c74"
    cardView
    :headers="headers"
    :rows="rows"
    hidePagination
  />
</template>


<script>
import BERICHTE_TYPES from '@/store/berichte/types';
import { TextColumn, DateColumn } from "@/components/table2/table_util.js";
import Table from "@/components/table2/Table.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Table,
  },
  computed: {
    ...mapGetters({
      altersvorsorge: BERICHTE_TYPES.GETTERS.ALTERSVORSORGE,
    }),
    headers() {
      return {
        center: [
          TextColumn("mlGrund", "Meldung"),
          TextColumn("mlMerkmal", "Meldungsmerkmal"),
          DateColumn("mlDatum", "Erstellungszeitpunkt"),
          DateColumn("mlErstAntragsdatum", "Erstmaliger Erstellungszeitpunkt"),
          DateColumn("mlAntragsdatum", "Datum des Zulagenantrags"),
          TextColumn("mlAnspruch", "Zulagenanspruch"),
          TextColumn("mlKuerzung", "Zulagenkürzung"),
        ]
      };
    },
    rows() {
      return [
        {...(this.altersvorsorge?.zulagen || {})}
      ]
    },
  },
};
</script>