var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("BaseExplorer", {
        attrs: { additionalActions: _vm.additionalActions },
        on: { "headerAction-CONFIRM_SELECTION": _vm.handleMailComposer }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }