var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.rec && this.rec.barkodePages.length > 0
    ? _c(
        "div",
        [
          _c("PageHeaderTitleNavigation", {
            attrs: { title: "Versandprotokolle", actions: _vm.headerActions },
            on: {
              "action-SEND": function($event) {
                return _vm.sendDaten()
              },
              "action-EDIT_BARCODE": function($event) {
                return _vm.editBarcodes(_vm.selectedRows)
              },
              "action-EDIT_GESELLSCHAFT": function($event) {
                return _vm.editKundeGesell(_vm.selectedRows)
              },
              "action-KUNDE_ZUORDNEN": function($event) {
                return _vm.editKunde(_vm.selectedRows)
              }
            }
          }),
          _c("Table", {
            attrs: {
              "aria-disabled": _vm.runing,
              tableId: "0a852a36-7335-11ed-a1eb-0242ac120002",
              headers: _vm.headers,
              rows: _vm.rows,
              rowsPerPage: 1000,
              rowId: "rowid"
            },
            on: {
              "click-mappe": _vm.clickMappe,
              "action-editBarcodes": function($event) {
                return _vm.editBarcodes([$event])
              },
              "action-editGesellschaft": function($event) {
                return _vm.editKundeGesell([$event])
              },
              "action-editKunde": function($event) {
                return _vm.editKunde([$event])
              },
              "action-deakt": _vm.deakt,
              "click-kundennr": _vm.openCustomerNewTab,
              "click-kundenname": _vm.openCustomerNewTab
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "rownum",
                  fn: function(row) {
                    return [
                      _c("DownloadLink", {
                        attrs: {
                          target: "_blank",
                          rel: "noopener noreferer",
                          title: _vm.str(row.rownum),
                          downloadServicePath: "/page_download",
                          queryParams: {
                            eingangId: row.eingangId,
                            pageNo: row.rownum
                          }
                        }
                      })
                    ]
                  }
                }
              ],
              null,
              false,
              54805772
            ),
            model: {
              value: _vm.selectedRows,
              callback: function($$v) {
                _vm.selectedRows = $$v
              },
              expression: "selectedRows"
            }
          }),
          _c("editBarcodes", { ref: "editBarcodes" }),
          _c("editKundeGesell", { ref: "editKundeGesell" }),
          _c("editKundeDeckBlatt", { ref: "editKundeDeckBlatt" }),
          _c("mappeDialog", { ref: "mappeDialog" })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }