var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("BrokerSearch", {
        attrs: {
          customOptionMenu: _vm.customPageHeaderMenu,
          isExternalSelect: _vm.isExternalSelect,
          filterType: _vm.filterType,
          isWithPerson: false,
          showCheckBoxBulkAction: false,
          singleSelection: true
        },
        on: { handleBackAction: _vm.handleBackAction }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }