<template>
<tr>
    <div class="card-row">
        <div class="card-row-label">
            <slot :name="'header_' + headerKey">
                {{headerKey}}
            </slot>
        </div>
        <div class="card-row-value">
            <slot :name="headerKey">
                {{row[headerKey]}}
            </slot>
        </div>
    </div>
</tr>
</template>

<script>
export default {
    props: {
        headerKey: {
            type: String,
            required: true,
        },
        row: {
            type: Object,
            required: true,
        },
    },
}
</script>

<style scoped>
.key-value--item .key-value--item-label {
    font-size: 1em;
}

tr {
    font-size: 1em;
}

tr > div {
    margin-top: 8px;
}
tr:first-child >div {
    margin-top: 0;
}

.card-row {
    display: flex;
    margin-bottom: 8px;
}
.card-row:last-child {
    margin-bottom: 0;
}
.card-row-label {
    font-weight: 500;
    font-size: 1em;
    flex: 1 1 auto;
    min-width: fit-content;
}
.card-row-value {
    font-size: 1em;
    line-height: 1em;
    flex: 1 1 auto;
    text-align: right;
}

@media screen and (min-width: 280px) {
    .card-row-label {
        line-height: 1.4em;
    }
    .card-row-value {
        line-height: 1.4em;
    }

}

@media screen and (min-width: 480px) {
    tr >div {
        border-bottom: 1px solid var(--color-box-neutral);
    }
    tr:last-child >div {
        border-bottom: 0;
    }

    .card-row {
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;
    }
    .card-row-label {
        line-height: 1.2em;
    }
    .card-row-value {
        line-height: 1.2em;
    }
}
</style>
