var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.parentOptionMenuId,
          defaultMenu: _vm.$appNavigation.parentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu,
          title: _vm.formatName + " Datenimport",
          subtitle: "Daten einlesen",
          actions: _vm.headerActions
        },
        on: {
          "action-IMPORT-FILE": function($event) {
            return _vm.$refs.importFileModal.open()
          },
          "action-STATUS-WERTE": function($event) {
            return _vm.$refs.statusWerteModal.open()
          }
        }
      }),
      _c(
        "DragnDropArea",
        {
          attrs: { hoverText: "Datei hier ablegen" },
          on: {
            files: function($event) {
              return _vm.openImportFileModal($event)
            }
          }
        },
        [
          !_vm.showFullFilter
            ? _c("div", { staticClass: "box__container" }, [
                _c("span", { staticClass: "box__title" }, [
                  _vm._v(" Anleitung ")
                ]),
                _c("br"),
                _c(
                  "div",
                  [
                    _vm._v(
                      " Mit der Funktion Datenimport haben Sie die Möglichkeit, "
                    ),
                    _c("b", [_vm._v("Bestandsdaten")]),
                    _vm._v(" im "),
                    _c("b", [_vm._v(_vm._s(_vm.formatName) + "-Format")]),
                    _vm._v(
                      " einzulesen und zu aktualisieren. Als Formate können " +
                        _vm._s(_vm.formatName) +
                        ", aber auch z.B. Textdateien verwendet werden. Kundendaten werden automatisch angelegt. Somit halten Sie Ihre Versicherungsdaten auf dem aktuellen Stand. "
                    ),
                    _c("b", [
                      _vm._v(
                        "Bitte beachten Sie unbedingt die Anleitung und lesen Sie diese sorgfältig durch."
                      )
                    ]),
                    _c("br"),
                    _c("br"),
                    _vm._v(" Folgendes bitten wir Sie zu beachten: "),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      " Die Nutzung dieser Funktion ist kostenpflichtig und mit der Nutzung erkennen Sie dies an. Zurzeit liegen die Gebühren bei 0,10€ pro angelegtem Vertrag/ Monat zzgl. der gesetzlichen MwSt. Die ersten drei Monate können Sie das Tool kostenfrei nutzen, um sich über die Funktionalitäten einen Überblick zu schaffen. Wir übernehmen keinerlei Haftung für die Qualität der Daten. Falls Sie Fehler entdecken sollten, bitten wir Sie uns eine Nachricht einzustellen, damit wir uns so schnell wie Möglich darum kümmern können. "
                    ),
                    _c("br"),
                    _c("br"),
                    _c("DownloadLink", {
                      attrs: { title: "Anleitung", href: _vm.anleitungLink }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _c(
            "BaseFilter",
            {
              ref: "gdvFileImportViewFilter",
              attrs: {
                title: "Daten anzeigen",
                filterId: "Daten anzeigen",
                configFilter: _vm.filterConfig,
                metadata: _vm.metadata,
                defaultOptions: _vm.defaultOptions,
                immidiateSearch: !!_vm.$route.query.filename,
                showSaveButton: ""
              },
              on: {
                onFilter: _vm.onFilter,
                onFilterZurucksetzen: _vm.onResetFilter
              }
            },
            [
              _vm.filterErrorMessage
                ? _c("span", { staticClass: "color-danger" }, [
                    _vm._v(" " + _vm._s(_vm.filterErrorMessage) + " ")
                  ])
                : _vm._e()
            ]
          ),
          _c(
            "div",
            {
              staticClass: "gdv-file-import-view__content",
              class: { "is-details-panel-visible": _vm.isDetailsPanelVisible }
            },
            [
              _c(
                "div",
                { staticClass: "gdv-file-import-view__table box__container" },
                [
                  _vm.loading || !_vm.rows.length
                    ? _c("div", [
                        _c("span", { staticClass: "box__title" }, [
                          _vm._v(" Daten ")
                        ]),
                        _c("br")
                      ])
                    : _vm._e(),
                  _vm.loading
                    ? _c("GhostLoading", { attrs: { type: "table" } })
                    : _vm.rows.length
                    ? _c("BigTable", {
                        ref: "bigTable",
                        staticStyle: { clear: "both" },
                        attrs: {
                          selectable: "",
                          headers: _vm.headers,
                          headerActions: _vm.tableHeaderActions,
                          title:
                            "Daten Einlesen - Anzahl der Datensätze " +
                            _vm.rows.length,
                          tableId: _vm.tableId,
                          rows: _vm.rows,
                          initialPageSize: _vm.minNumRows,
                          page: _vm.page,
                          sortingState: _vm.sorting
                        },
                        on: {
                          onClickHeader: _vm.onClickHeader,
                          onRowClick: _vm.showLineDetails,
                          "action-EDIT": function($event) {
                            _vm.lineEdit = $event
                          },
                          "click-icon-details-DATENSATZ_ZUORDNEN":
                            _vm.actionDatensatzZuordnen,
                          "click-icon-DATENSATZ_BEARBEITEN": function($event) {
                            _vm.lineEdit = $event
                          },
                          "click-icon-DATENSATZ_LOESCHEN":
                            _vm.confirmRowDeletion,
                          "action-details-DATENSATZ_ZUORDNEN":
                            _vm.actionDatensatzZuordnen,
                          "action-TARIF": _vm.actionTarif,
                          "action-KUNDENDATEN_ZUWEISEN":
                            _vm.actionKundendatenZuweisen,
                          "action-DELETE": _vm.deleteRow,
                          "headerAction-AUSWERTEN": function($event) {
                            return _vm.actionAuswertung(_vm.firstRowObject)
                          },
                          "headerAction-MARKIERTE_LOESCHEN": _vm.deleteSelected,
                          loadMoreRows: _vm.onLoadMoreRows,
                          selectedRow: _vm.onRowSelection,
                          selectedRows: _vm.onAllRowsSelection
                        }
                      })
                    : _c("NoData")
                ],
                1
              ),
              _vm.isDetailsPanelVisible
                ? _c("div", { staticClass: "details__container" }, [
                    _c(
                      "div",
                      { staticClass: "column box__container" },
                      [
                        _vm.readDetails
                          ? _c("GhostLoading", { attrs: { type: "table" } })
                          : !_vm.readDetails && _vm.journalRows
                          ? _c("Table", {
                              staticStyle: { clear: "both" },
                              attrs: {
                                headers: _vm.journalHeaders,
                                rows: _vm.journalRows,
                                rowId: "index",
                                hidePagination: ""
                              }
                            })
                          : _c("NoData")
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "details__column box__container" },
                      [
                        _vm.readDetails
                          ? _c("GhostLoading", { attrs: { type: "table" } })
                          : !_vm.readDetails && _vm.lineDetails.values
                          ? _c("Table", {
                              staticStyle: { clear: "both" },
                              attrs: {
                                headers: _vm.valuesHeaders,
                                rows: _vm.lineDetails.values,
                                rowId: "label",
                                hidePagination: ""
                              }
                            })
                          : _c("NoData")
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ]
          )
        ],
        1
      ),
      _c("ImportFileModal", { ref: "importFileModal" }),
      _vm.lineEdit
        ? _c("FileImportLineEdit", {
            attrs: { line: _vm.lineEdit },
            on: {
              close: function($event) {
                _vm.lineEdit = null
              }
            }
          })
        : _vm._e(),
      _vm.auswertungId
        ? _c("GdvImportAuswertungModal", {
            attrs: { id: _vm.auswertungId },
            on: { close: _vm.closeAuswertung }
          })
        : _vm._e(),
      _c("BaseModal", {
        ref: "infoModal",
        attrs: { showCancelButton: false, labelButtonConfirm: "Schließen" },
        scopedSlots: _vm._u([
          {
            key: "modalTitle",
            fn: function() {
              return [_vm._v("Daten Einlesen")]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _c("div", {
                  domProps: { innerHTML: _vm._s(_vm.sanitize(_vm.message)) }
                })
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("BaseModal", {
        ref: "statusWerteModal",
        attrs: { showCancelButton: false, labelButtonConfirm: "Schließen" },
        scopedSlots: _vm._u([
          {
            key: "modalTitle",
            fn: function() {
              return [_vm._v("Status Werte")]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _c("Table", {
                  staticStyle: { clear: "both" },
                  attrs: {
                    headers: _vm.statusWerteHeaders,
                    rows: _vm.statusWerteRows,
                    rowId: "wert",
                    hidePagination: ""
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }