import actions from './actions';
import mutations from './mutations';
import getters from './getters';

/**
 */
export function getInitialState() {
  return {
    buchungversicherung: {
      buchungsnr : null,
      spartenID : null,
      zeichnungsdatum : null,
      courtageeingDatum : null,
      courtageart : null,
      courtagesumme : null,
      courtagewaehrung : null,
      bewertungssumme : null,
      monatsbeitrag : null,
      defaultStornoreserveSatz : null,
      stornoreserve : null,
      stornoreserveBeiGesellschaft : null,
      defaultVersicherungseinbehaltSatz : null,
      versicherungseinbehalt : null,
      istOverhead : null,
      status : null,
      wechselkurs : null,
      bemerkung : null,
      externeBuchungsnr : null,
      mwstSatz : null,
    },
    buchungFieldsDefinition: {},
    verteilungStruktur: [],
    selectedBuchungDetail: {},
  }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}