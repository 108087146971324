import INSURANCE_BUCHUNG_TYPES from './types';

export default {
  [INSURANCE_BUCHUNG_TYPES.MUTATIONS.BUCHUNG_FIELDS_DEFINITIONS_SUCCESS](state, payload) {
    state.buchungFieldsDefinition = payload;
  },
  [INSURANCE_BUCHUNG_TYPES.MUTATIONS.SAVE_BUCHUNG_SUCCESS](state, payload) {
    state.buchungversicherung = payload;
  },
  [INSURANCE_BUCHUNG_TYPES.MUTATIONS.UPDATE_BUCHUNG_SUCCESS](state, payload) {
    state.buchungversicherung = payload;
  },
  [INSURANCE_BUCHUNG_TYPES.MUTATIONS.INSURANCE_BUCHUNG_VERTEILUNG_STRUKTUR_SUCCESS](state, payload) {
    state.verteilungStruktur = payload;
  },
  [INSURANCE_BUCHUNG_TYPES.MUTATIONS.INSURANCE_BUCHUNG_VERTEILUNG_STRUKTUR_DELETE_SUCCESS](state, response) {
    const index = state.verteilungStruktur?.findIndex(struktur => struktur.lfdNr === response);
    if (index > -1) {
      state.verteilungStruktur?.splice(index, 1);
    }
  },
  [INSURANCE_BUCHUNG_TYPES.MUTATIONS.SELECTED_INSURANCE_BUCHUNG](state, payload) {
    state.selectedBuchungDetail = payload;
  },
  [INSURANCE_BUCHUNG_TYPES.MUTATIONS.RESET_INSURANCE_BUCHUNG_VERTEILUNG_STRUKTUR](state) {
    state.verteilungStruktur = [];
  },
}