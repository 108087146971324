var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "bb6491d8-974c-4727-a107-afc356bed175",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: "932.37538",
        height: "646.21974",
        viewBox: "0 0 932.37538 646.21974"
      }
    },
    [
      _c(
        "defs",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "b10df757-55e5-4d99-9006-dc7249c3a778",
                x1: "401.19868",
                y1: "238.81186",
                x2: "401.19868",
                y2: "191.49163",
                gradientUnits: "userSpaceOnUse"
              }
            },
            [
              _c("stop", {
                attrs: {
                  offset: "0",
                  "stop-color": "gray",
                  "stop-opacity": "0.25"
                }
              }),
              _c("stop", {
                attrs: {
                  offset: "0.53514",
                  "stop-color": "gray",
                  "stop-opacity": "0.12"
                }
              }),
              _c("stop", {
                attrs: {
                  offset: "1",
                  "stop-color": "gray",
                  "stop-opacity": "0.1"
                }
              })
            ],
            1
          ),
          _c("linearGradient", {
            attrs: {
              id: "b7455b34-8431-4804-9964-905371e7992b",
              x1: "548.19868",
              y1: "175.41336",
              x2: "548.19868",
              y2: "144.89013",
              "xlink:href": "#b10df757-55e5-4d99-9006-dc7249c3a778"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "a8bee007-5617-4059-9c68-754326cfe534",
              x1: "819.19868",
              y1: "155.41336",
              x2: "819.19868",
              y2: "124.89013",
              "xlink:href": "#b10df757-55e5-4d99-9006-dc7249c3a778"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "b5786f2b-186c-4311-b51e-a01aadf48633",
              x1: "138.37538",
              y1: "143.68325",
              x2: "138.37538",
              y2: "9.68325",
              "xlink:href": "#b10df757-55e5-4d99-9006-dc7249c3a778"
            }
          })
        ],
        1
      ),
      _c("rect", {
        attrs: {
          x: "172.18769",
          y: "163.10987",
          width: "11",
          height: "147",
          fill: _vm.color,
          stroke: "#6c63ff",
          "stroke-miterlimit": "10"
        }
      }),
      _c("rect", {
        attrs: {
          x: "407.42024",
          y: "265.80813",
          width: "109.26778",
          height: "66.19873",
          fill: "#f55f44"
        }
      }),
      _c("rect", {
        attrs: {
          x: "516.68802",
          y: "265.80813",
          width: "109.26778",
          height: "66.19873",
          fill: "#4d8af0"
        }
      }),
      _c("rect", {
        attrs: {
          x: "625.95581",
          y: "265.80813",
          width: "109.26778",
          height: "66.19873",
          fill: "#3ad29f"
        }
      }),
      _c("rect", {
        attrs: {
          x: "406.62266",
          y: "332.00686",
          width: "109.26778",
          height: "66.19873",
          fill: "#4d8af0"
        }
      }),
      _c("rect", {
        attrs: {
          x: "298.15245",
          y: "332.00686",
          width: "109.26778",
          height: "66.19873",
          fill: "#f55f44"
        }
      }),
      _c("rect", {
        attrs: {
          x: "516.68802",
          y: "332.00686",
          width: "109.26778",
          height: "66.19873",
          fill: "#3ad29f"
        }
      }),
      _c("rect", {
        attrs: {
          x: "625.95581",
          y: "332.00686",
          width: "109.26778",
          height: "66.19873",
          fill: "#fdd835"
        }
      }),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("rect", {
          attrs: {
            x: "426.96083",
            y: "265.40934",
            width: "14.35635",
            height: "66.19873"
          }
        }),
        _c("rect", {
          attrs: {
            x: "454.87595",
            y: "265.40934",
            width: "14.35635",
            height: "66.19873"
          }
        }),
        _c("rect", {
          attrs: {
            x: "482.79108",
            y: "265.40934",
            width: "14.35635",
            height: "66.19873"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("rect", {
          attrs: {
            x: "536.22861",
            y: "265.40934",
            width: "14.35635",
            height: "66.19873"
          }
        }),
        _c("rect", {
          attrs: {
            x: "564.14374",
            y: "265.40934",
            width: "14.35635",
            height: "66.19873"
          }
        }),
        _c("rect", {
          attrs: {
            x: "592.05887",
            y: "265.40934",
            width: "14.35635",
            height: "66.19873"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("rect", {
          attrs: {
            x: "645.49639",
            y: "265.40934",
            width: "14.35635",
            height: "66.19873"
          }
        }),
        _c("rect", {
          attrs: {
            x: "673.41152",
            y: "265.40934",
            width: "14.35635",
            height: "66.19873"
          }
        }),
        _c("rect", {
          attrs: {
            x: "701.32665",
            y: "265.40934",
            width: "14.35635",
            height: "66.19873"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("rect", {
          attrs: {
            x: "426.96083",
            y: "331.60807",
            width: "14.35635",
            height: "66.19873"
          }
        }),
        _c("rect", {
          attrs: {
            x: "454.87595",
            y: "331.60807",
            width: "14.35635",
            height: "66.19873"
          }
        }),
        _c("rect", {
          attrs: {
            x: "482.79108",
            y: "331.60807",
            width: "14.35635",
            height: "66.19873"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("rect", {
          attrs: {
            x: "317.69304",
            y: "331.60807",
            width: "14.35635",
            height: "66.19873"
          }
        }),
        _c("rect", {
          attrs: {
            x: "345.60817",
            y: "331.60807",
            width: "14.35635",
            height: "66.19873"
          }
        }),
        _c("rect", {
          attrs: {
            x: "373.5233",
            y: "331.60807",
            width: "14.35635",
            height: "66.19873"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("rect", {
          attrs: {
            x: "536.22861",
            y: "331.60807",
            width: "14.35635",
            height: "66.19873"
          }
        }),
        _c("rect", {
          attrs: {
            x: "564.14374",
            y: "331.60807",
            width: "14.35635",
            height: "66.19873"
          }
        }),
        _c("rect", {
          attrs: {
            x: "592.05887",
            y: "331.60807",
            width: "14.35635",
            height: "66.19873"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("rect", {
          attrs: {
            x: "645.49639",
            y: "331.60807",
            width: "14.35635",
            height: "66.19873"
          }
        }),
        _c("rect", {
          attrs: {
            x: "673.41152",
            y: "331.60807",
            width: "14.35635",
            height: "66.19873"
          }
        }),
        _c("rect", {
          attrs: {
            x: "701.32665",
            y: "331.60807",
            width: "14.35635",
            height: "66.19873"
          }
        })
      ]),
      _c("rect", {
        attrs: {
          x: "21.68769",
          y: "475.60987",
          width: "846",
          height: "26",
          fill: _vm.color,
          stroke: "#6c63ff",
          "stroke-miterlimit": "10"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M927.5,497.5h-498a12.0353,12.0353,0,0,1-12-12V412.176L223.5,390.5v223h716v-128A12.03523,12.03523,0,0,1,927.5,497.5Z",
          transform: "translate(-136.81231 -124.89013)",
          fill: _vm.color,
          stroke: "#6c63ff",
          "stroke-miterlimit": "10"
        }
      }),
      _c("rect", {
        attrs: {
          x: "86.68769",
          y: "289.60987",
          width: "73",
          height: "42",
          fill: _vm.secondColor
        }
      }),
      _c("circle", {
        attrs: {
          cx: "177.68769",
          cy: "162.60987",
          r: "21",
          fill: _vm.color,
          stroke: "#6c63ff",
          "stroke-miterlimit": "10"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M232.42919,621.629c7.43621-20.076,30.81892-30.532,52.21146-29.69484s41.31818,10.502,60.49411,20.0218,39.03219,19.27834,60.41542,20.32683c47.519,2.33,87.50948-38.12583,134.86347-42.71717,56.29339-5.4581,106.2505,40.38484,162.77635,42.27305,9.94081.33207,20.07934-.76708,29.23352-4.65693,7.96792-3.38576,14.9115-8.7506,22.437-13.03062,37.97935-21.60035,85.48272-13.44466,127.50129-1.46776s85.63709,27.32039,128.34948,18.11942",
          transform: "translate(-136.81231 -124.89013)",
          fill: "#4d8af0"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M136.81231,660.34986c7.43621-20.076,30.81892-30.532,52.21146-29.69484s41.31818,10.502,60.49411,20.02179,39.03219,19.27835,60.41543,20.32683c47.519,2.33,87.50947-38.12583,134.86346-42.71717,56.2934-5.45809,106.2505,40.38484,162.77636,42.27306,9.9408.33207,20.07933-.76709,29.23352-4.65693,7.96791-3.38577,14.91149-8.75061,22.437-13.03063,37.97935-21.60035,85.48272-13.44466,127.50129-1.46776S872.382,678.7246,915.09437,669.52363",
          transform: "translate(-136.81231 -124.89013)",
          fill: "#4d8af0"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M238.751,710.13385c7.43621-20.076,30.81891-30.532,52.21146-29.69484s41.31818,10.502,60.4941,20.0218,39.0322,19.27834,60.41543,20.32683c47.519,2.33,87.50948-38.12583,134.86346-42.71717,56.2934-5.4581,106.25051,40.38483,162.77636,42.27305,9.94081.33207,20.07934-.76708,29.23352-4.65693,7.96792-3.38577,14.9115-8.7506,22.43695-13.03063,37.97936-21.60034,85.48272-13.44466,127.50129-1.46775s85.6371,27.32039,128.34949,18.11941",
          transform: "translate(-136.81231 -124.89013)",
          fill: "#4d8af0"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M290.90563,759.12762c7.43621-20.076,30.81891-30.532,52.21146-29.69484s41.31818,10.502,60.4941,20.0218,39.0322,19.27834,60.41543,20.32683c47.519,2.33,87.50948-38.12583,134.86346-42.71717,56.2934-5.45809,106.25051,40.38484,162.77636,42.27305,9.94081.33207,20.07934-.76708,29.23352-4.65692,7.96792-3.38577,14.9115-8.75061,22.437-13.03063,37.97935-21.60035,85.48271-13.44466,127.50128-1.46776s85.6371,27.32039,128.34949,18.11942",
          transform: "translate(-136.81231 -124.89013)",
          fill: "#4d8af0"
        }
      }),
      _c("g", { attrs: { opacity: "0.5" } }, [
        _c("path", {
          attrs: {
            d:
              "M399.7606,238.81186l-6.89476-1.22015a44.53063,44.53063,0,0,1,.83228-5.06968c1.17046-14.78118-9.07915-30.33524-23.48846-34.25788l1.84394-6.77252c13.12918,3.57419,23.25482,14.56125,27.09635,27.35684a48.51881,48.51881,0,0,1,31.64965-22.94808l1.38809,6.88105c-15.1045,3.04638-27.94292,15.88994-31.53368,30.85849A35.46861,35.46861,0,0,1,399.7606,238.81186Z",
            transform: "translate(-136.81231 -124.89013)",
            fill: "url(#b10df757-55e5-4d99-9006-dc7249c3a778)"
          }
        })
      ]),
      _c("path", {
        attrs: {
          d:
            "M399.96943,235.37611l-5.89356-1.043a38.06323,38.06323,0,0,1,.71143-4.3335c1.00049-12.63476-7.76074-25.93017-20.07764-29.2832l1.57617-5.78906c11.22266,3.05517,19.87793,12.44677,23.16163,23.38427a41.47322,41.47322,0,0,1,27.05371-19.61572l1.18652,5.88184c-12.91113,2.604-23.88525,13.58252-26.95459,26.37744A30.31677,30.31677,0,0,1,399.96943,235.37611Z",
          transform: "translate(-136.81231 -124.89013)",
          fill: "#706a6a"
        }
      }),
      _c("g", { attrs: { opacity: "0.5" } }, [
        _c("path", {
          attrs: {
            d:
              "M547.27107,175.41336l-4.44737-.787a28.726,28.726,0,0,1,.53685-3.27013c.755-9.53438-5.85637-19.5673-15.15089-22.09755l1.18941-4.36851c8.46878,2.30548,15.00018,9.39253,17.47811,17.64613a31.29632,31.29632,0,0,1,20.41514-14.80232l.89537,4.43852c-9.74294,1.965-18.02418,10.24958-20.34034,19.90482A22.87807,22.87807,0,0,1,547.27107,175.41336Z",
            transform: "translate(-136.81231 -124.89013)",
            fill: "url(#b7455b34-8431-4804-9964-905371e7992b)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.5" } }, [
        _c("path", {
          attrs: {
            d:
              "M818.27107,155.41336l-4.44737-.787a28.726,28.726,0,0,1,.53685-3.27013c.755-9.53438-5.85637-19.5673-15.15089-22.09755l1.18941-4.36851c8.46878,2.30548,15.00018,9.39253,17.47811,17.64613a31.29632,31.29632,0,0,1,20.41514-14.80232l.89537,4.43852c-9.74294,1.965-18.02418,10.24958-20.34034,19.90482A22.87807,22.87807,0,0,1,818.27107,155.41336Z",
            transform: "translate(-136.81231 -124.89013)",
            fill: "url(#a8bee007-5617-4059-9c68-754326cfe534)"
          }
        })
      ]),
      _c("path", {
        attrs: {
          d:
            "M547.59591,170.06886l-2.88993-.51143a18.66413,18.66413,0,0,1,.34885-2.125c.49059-6.19552-3.80552-12.715-9.84517-14.35916l.77289-2.83869A16.85575,16.85575,0,0,1,547.34,161.70122a20.33658,20.33658,0,0,1,13.26591-9.61866l.58182,2.88418a17.71236,17.71236,0,0,0-13.21731,12.93431A14.86648,14.86648,0,0,1,547.59591,170.06886Z",
          transform: "translate(-136.81231 -124.89013)",
          fill: "#706a6a"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M818.59591,150.06886l-2.88993-.51143a18.66413,18.66413,0,0,1,.34885-2.125c.49059-6.19552-3.80552-12.715-9.84517-14.35916l.77289-2.83869A16.85575,16.85575,0,0,1,818.34,141.70122a20.33658,20.33658,0,0,1,13.26591-9.61866l.58182,2.88418a17.71236,17.71236,0,0,0-13.21731,12.93431A14.86648,14.86648,0,0,1,818.59591,150.06886Z",
          transform: "translate(-136.81231 -124.89013)",
          fill: "#706a6a"
        }
      }),
      _c("g", { attrs: { opacity: "0.5" } }, [
        _c("circle", {
          attrs: {
            cx: "138.37538",
            cy: "76.68325",
            r: "67",
            fill: "url(#b5786f2b-186c-4311-b51e-a01aadf48633)"
          }
        })
      ]),
      _c("circle", {
        attrs: { cx: "138.37538", cy: "76.68325", r: "62", fill: "#fdd835" }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }