<template>
  <div>
    <div class="box__container" v-if="showHeaderCard" :tid="_generateTidFromString('box__container_header_card')">
      <div class="d-flex justify-content-between" v-if="title">
        <div>
          <span class="box__title">{{title}}</span>
          <span v-if="subtitle">&nbsp;|&nbsp;</span>
          <span v-if="subtitle" v-html="sanitize(subtitle)"></span>
          <span :tid="_generateTidFromString('edit_button_title_beratungsmappe')" class="ml-1 clickable" v-if="editAction" @click="editAction"><ph-pencil-line :size="16" /></span>
          <span v-if="status">&nbsp;|&nbsp;</span>
          <Pill v-if="status" :label="status" type="danger"></Pill>
        </div>
      </div>
      <div v-else-if="$slots.title">
        <slot name="title"></slot>
      </div>
      <div v-if="$slots.actionButtons">
        <div 
          :class="{ 'layout__negative-margin--8': actionButtonNegativeMargin }"  >
          <slot name="actionButtons"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PhPencilLine } from 'phosphor-vue'
import CORE_TYPES from '@/store/core/types'
import { mapGetters } from 'vuex'
import Pill from '@/components/core/Pill.vue'
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import { sanitize } from '@/helpers/string-helper.js';

export default {
  mixins: [InteractiveHelpCommonsMixin],
  props: {
    title: {
    },
    subtitle: {
    },
    editAction: {
      type: Function
    },
    showHeaderCard: {
      default: true
    },
    actionButtonNegativeMargin: {
      default: true
    },
    status: {
    },   
    showBreadcrumb: {
      default: true
    },
  },
  computed: {
    ...mapGetters({
      backToPreviousData: CORE_TYPES.GETTERS.GET_SAVE_BACK_TO_PREVIOUS_PAGE
    }),
    lastBackToPreviousData() {
      return this.backToPreviousData && this.backToPreviousData.length && this.backToPreviousData[this.backToPreviousData.length - 1] || {} 
    },
  },
  methods: {
    sanitize(html) {
      return sanitize(html)
    },
    goToLastAction() {
      this.$router.push({ path: this.lastBackToPreviousData.fullPath, query: { backAction: true}})
      this.$store.commit(CORE_TYPES.MUTATIONS.POP_SAVE_BACK_TO_PREVIOUS_PAGE)
    }
  },
  components: {
    PhPencilLine,
    Pill
  }
}
</script>

<style scoped>

  .page-header__container--margin {
    margin-bottom: 8px;
  }

  .page-header__action-buttons {
    margin: -8px;
  }
  
  .page-header__action-buttons > * {
    margin: 8px;
  }
</style>