<template>
  <div class="mb-4">
    <!-- <DragnDropArea hoverText="Datei hier ablegen" @files="openAddDocumentModal($event)"> -->
    <DashboardPanel
      id="8ee0460c-2a9f-4a6a-ac4b-837b7a5e62c7"
      :data="dashboardData"
      :canEdit="isEditable"
      ignoreUserLevelConfig
      :defaultOptionsMenu="customOptionsMenu"
      is3Columns
      :headerActions="headerActions"
      :noPrimaryAction="false"
      @executeAction="handleExecuteAction($event)"
    >
      <template #title>
        {{ pageMetadata.title }}
      </template>
      <template #subtitle>
        {{ pageMetadata.subtitle }}
      </template>
    </DashboardPanel>

    <BaseModal
      ref="editModal"
      :modalTitle="modalTitle"
      size="lg"
      :showCancelButton="false"
      @onCloseButton="saveChanges()"
      :showConfirmButton="false"
      :actions="baseModalActions"
      @action="handleModalActions"
    >
        <Produktdaten v-if="editComponent=='produktdaten'"></Produktdaten>
        <Anpassungen  v-if="editComponent=='anpassungen'"></Anpassungen>
        <Kommunikationsdaten  v-if="editComponent=='kommunikationsdaten'"></Kommunikationsdaten>
        <Dokumente v-if="editComponent=='dokumente'"></Dokumente>
        <Abrechnung v-if="editComponent=='abrechnung'"></Abrechnung>
        <Aenderungslog v-if="editComponent=='aenderungslog'"></Aenderungslog>
        <ManuelleStruktur v-if="editComponent=='manuelle-struktur'"></ManuelleStruktur>
        <Aktivitaet v-if="editComponent=='aktivitaet'"></Aktivitaet>

    </BaseModal>
  </div>
</template>
  
  <script>
import CORE_TYPES from "@/store/core/types";
import { mapGetters } from "vuex";
import { PhCheck, PhWarning } from "phosphor-vue";
import CurrencyLabel from "@/components/core/CurrencyLabel.vue";
import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import DashboardPanel from "@/components/dashboard/DashboardPanel.vue";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import { ROLES, VIEW_ROLES } from "@/router/roles.js";
import Pill from "@/components/core/Pill.vue";
import DragnDropArea from "@/components/fileUpload/DragnDropArea.vue";
import BaseModal from "@/components/core/BaseModal.vue";
import BaseButton from "@/components/core/BaseButton.vue";
import Produktdaten from "@/components/otherProducts/steps/Produktdaten.vue";
import Anpassungen from "@/components/otherProducts/steps/Anpassungen.vue";
import Dokumente from "@/components/otherProducts/steps/Dokumente.vue";
import Abrechnung from "@/components/otherProducts/steps/Abrechnung.vue";
import Aenderungslog from "@/components/otherProducts/steps/Aenderungslog.vue";
import ManuelleStruktur from "@/components/otherProducts/steps/ManuelleStruktur.vue";
import Aktivitaet from "@/components/otherProducts/steps/Aktivitaet.vue";
import OTHER_PRODUCTS_TYPES from "@/store/otherProducts/types";
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import { BaseModalSimpleAction } from '@/components/core/base-modal-actions/base-modal-actions-utils';

import EVENT_BUS, { ANPASSUNGEN_NEUE_ANPASSUNG, } from '@/event-bus';

import { 
  MAP_TYPE_TO_HOME_PATH,
  MAP_TYPE_TO_TITLE,
  MAP_TYPE_TO_TITLE_SINGULAR,
  WEITERE_VERMOEGEN_TYPE,
} from './otherProductsUtils.js';


export default {
  props: {
    type: {
      type: String,
      default: "",
    },
    useParentOptionMenu: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [InteractiveHelpCommonsMixin],
  validators: {},
  data: function () {
    return {
      showAdditional: false,
      tableData: {
        headers: {},
        records: [],
      },
      selectedRowsValues: [],
      editComponent: null,
      substepProperty: null,
      modalTitle: null,
    };
  },
  computed: {
    ...mapGetters({
      isFA: CORE_TYPES.GETTERS.IS_FA,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
      isCustomerOnly: CORE_TYPES.GETTERS.IS_CUSTOMER_ONLY,
      fullname: CORE_TYPES.GETTERS.GET_USER_FULL_NAME,
      unternr: CORE_TYPES.GETTERS.GET_USER_UNTER_NR,
      userid: CORE_TYPES.GETTERS.GET_USER_ID,
      sprodId: OTHER_PRODUCTS_TYPES.GETTERS.SELECTED_SPROD_ID,
      otherProduct: OTHER_PRODUCTS_TYPES.GETTERS.OTHER_PRODUCT,
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
      combos: OTHER_PRODUCTS_TYPES.GETTERS.COMBOS,
      isByPass: CORE_TYPES.GETTERS.IS_BYPASS,
    }),
    rights() {
      return this.loginData?.rights || {};
    },
    userIdUnterNr() {
      return this.unternr ? `${this.userid}-${this.unternr}` : this.userid;
    },
    isViewCustomerAsBypass() {
      return this.hasRoles([VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS]);
    },
    dashboardData() {
      const data = {
        widgets: [
          {
            name: "produktdaten",
            title: "Produktdaten",
            component: () =>
              import("@/components/otherProducts/cards/Produktdaten.vue"),
            loading: () => false,
            actions: [
              {
                tableContainer: "produktdaten",
                legend: {
                  icon: "PhList",
                  key: "EDIT_COLUMNS",
                  label: "Zeilen anpassen",
                },
                visible: () => true,
              },
            ],
          },
          {
            name: "anpassungen",
            title: "Anpassungen",
            component: () =>
              import("@/components/otherProducts/cards/Anpassungen.vue"),
            loading: () => false,
            actions: [
              {
                legend: {
                  icon: "PhList",
                  key: "EDIT_COLUMNS",
                  label: "Zeilen anpassen",
                },
                visible: () => true,
              },
            ],
          },
        ],
      };

      if (this.isByPass) {
        data.widgets.push(
            {
                name: "aktivitaet",
                title: "Aktivität",
                component: () =>
                  import("@/components/otherProducts/cards/Aktivitaet.vue"),
                loading: () => false,
            },
          );
      }


      if (this.isIntern) {
        data.widgets.push(
          {
            name: "aenderungslog",
            title: "Änderungslog",
            component: () =>
              import("@/components/otherProducts/cards/Aenderungslog.vue"),
            loading: () => false,
          },
        );
      }

      if (this.isIntern || this.combos?.hasRightManuelleCourtage) {
        data.widgets.push(
          {
            name: "manuelle-struktur",
            title: "Manuelle Struktur",
            component: () =>
              import("@/components/otherProducts/cards/ManuelleStruktur.vue"),
            loading: () => false,
          },
        );
      }

      if (
        (this.rights?.showDocumentArchive ||
        this.rights?.updateDocumentArchive) 
      ) {
        data.widgets.push({
          name: "dokumente",
          title: "Dokumente",
          component: () =>
            import("@/components/otherProducts/cards/Dokumente.vue"),
          loading: () => false,
        });
      }

      if (this.isByPass && this.combos?.allowReadVertragAbrechnung) {
        data.widgets.push({
          name: "abrechnung",
          title: "Abrechnung",
          component: () =>
            import("@/components/otherProducts/cards/Abrechnung.vue"),
          loading: () => false,
        });
      }

      return data;
    },
    customOptionsMenu() {
      return [];
    },
    isEditable() {
      return true;
    },
    headerActions() {
      const { isBrokerOrBypass, } = this;

      const actions = [];
      if(isBrokerOrBypass) {
        actions.push(PageHeaderSimpleAction('ADD-AKTIVITAET', 'Aufgabe hinzufügen'));
      }
      return actions;
    },
    baseModalActions() {
      const actions =  [];

      if (this.editComponent == 'anpassungen') {
        actions.push(new BaseModalSimpleAction('NEUE_ANPASSUNG', 'Neue Anpassung'));
      }

      return actions;
    },
/*     pageMetadata() {
      const title = MAP_TYPE_TO_TITLE[this.type];
      const titleSingular = MAP_TYPE_TO_TITLE_SINGULAR[this.type];
      return {
        title,
        searchTitle: `${title} suchen`,
        newTitle: `${titleSingular} hinzufügen`,
        editPath: `${MAP_TYPE_TO_HOME_PATH[this.type]}/edit`,
      };
    }, */
    pageMetadata() {
      return {
        title: this.otherProduct?.produktart || MAP_TYPE_TO_TITLE_SINGULAR?.[this.type] || ' ',
        subtitle: this.otherProduct?.produktbez,
        editPath: `${MAP_TYPE_TO_HOME_PATH[this.type]}/edit`,
      };
    },   
  },
  mounted: async function () {
    await this.init();
  },

  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: "zurück zum Überblick",
      to,
      from,
    });

    next();
  },

  methods: {
    saveChanges() {},
    navigateTo(event) {
      this.$router.push({ path: `${event}` });
    },
    init() {
      this.$store.commit(
        OTHER_PRODUCTS_TYPES.MUTATIONS.SET_CURRENT_TYPE,
        this.type
      );
      this.$store.commit(
        OTHER_PRODUCTS_TYPES.MUTATIONS.SET_SELECTED_SPROD_ID,
        this.$route.params?.sprodId
      );

      this.findCombos();
      this.getOtherProduct(this.sprodId);

      // this.configureSteps();
    },    
    findCombos() {
      this.$store.dispatch(OTHER_PRODUCTS_TYPES.ACTIONS.GET_COMBOS);
    },    
    getOtherProduct() {
      this.$store.dispatch(OTHER_PRODUCTS_TYPES.ACTIONS.GET_OTHER_PRODUCT);
    },
    handleExecuteAction(actionData) {
      if (actionData.action?.legend?.key === "EDIT_COLUMNS") {
        if (actionData?.component?.$refs.table) {
          actionData?.component?.$refs.table.openColumnsConfig();
        } else if (actionData.action?.tableContainer) {
          actionData.component.$refs[
            actionData.action?.tableContainer
          ].$refs.table.openColumnsConfig();
        }
      } else {
        if (actionData.action?.action === "BEARBEITEN_APP") {
          this.editComponent =
            actionData?.widget?.mainStep || actionData?.widget?.name;

          if (actionData?.widget?.mainStep) {
            this.substepProperty = actionData?.widget?.name;
          } else {
            this.substepProperty = null;
          }

          this.modalTitle = actionData?.widget?.title;

          this.$refs.editModal.open();
        } else {

          this.editComponent =
            actionData?.widget?.mainStep || actionData?.widget?.name;

          this.editOtherProduct(this.editComponent)
        }
      }
    },
    editOtherProduct(step) {
      this.$router.push(`${this.pageMetadata.editPath}/${this.sprodId}/${step}`);
    },    
    handleModalActions(actionData) {
      if (actionData?.key === 'NEUE_ANPASSUNG') {
        EVENT_BUS.$emit(ANPASSUNGEN_NEUE_ANPASSUNG);
      }
    },
  },

  filters: {
    currency(value, numberFixed = 2) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value / 1).toFixed(numberFixed).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " € ";
    },
    number(value, numberFixed = 0) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value / 1).toFixed(numberFixed).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."); // + '%';
    },
    percentage(value, numberFixed = 0) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value / 1).toFixed(numberFixed).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " % ";
    },
  },

  components: {
    PhCheck,
    PhWarning,
    CurrencyLabel,
    OptionMenu,
    DashboardPanel,
    Pill,
    DragnDropArea,
    BaseModal,
    BaseButton,
    Produktdaten,
    Anpassungen,
    Dokumente,
    Abrechnung,
    Aenderungslog,
    ManuelleStruktur,
    Aktivitaet

  },
};
</script>
  
  <style lang="scss" scoped>
.subtitle-part {
  word-break: break-word;
}
</style>
  
  