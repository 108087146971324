import SSO_TYPES from './types'
import CORE_TYPES from '@/store/core/types'
import LOG_TYPES from '@/store/log/types'

import { setObject } from '@/helpers/local-storage-helper'
import { buildMessage } from '@/helpers/log-message-helper';
import axios from 'axios'
import router from '@/router';

const spinnerConfig = {
    defaultSpinner: true
};
  
export default {
    [SSO_TYPES.ACTIONS.INVITE_TO_SSO]({ state, rootState }, payload) {
        return axios.post(rootState.core.apiAddress + '/sso/invite_to_sso', payload, {...spinnerConfig, headers: {'sso': state.sessionId}});
    },
    [SSO_TYPES.ACTIONS.GET_SSO_ACCOUNT_INFO]({ commit, dispatch, state, rootState }) {
        axios.get(rootState.core.apiAddress + '/sso/sso_account_info', {...spinnerConfig, headers: {'sso': state.sessionId}}).then(response => {
            if (response?.data) {
                commit(SSO_TYPES.MUTATIONS.GET_SSO_ACCOUNT_INFO, response.data);
            }
        }).catch(error => {
            dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "SSO_TYPES.ACTIONS.GET_SSO_ACCOUNT_INFO", error});
        })
    },
    [SSO_TYPES.ACTIONS.GET_FK_ACCOUNT_INFO]({ commit, dispatch, rootState }) {
        axios.get(rootState.core.apiAddress + '/sso/fk_account_info', spinnerConfig).then(response => {
            if (response?.data) {
                commit(SSO_TYPES.MUTATIONS.GET_FK_ACCOUNT_INFO, response.data);
            }
        }).catch(error => {
            dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "SSO_TYPES.ACTIONS.GET_FK_ACCOUNT_INFO", error});
        })
    },
    [SSO_TYPES.ACTIONS.REQUEST_SMS_CODE]({ state, rootState }, phoneNumber) {
        return axios.post(rootState.core.apiAddress + '/sso/request_2fa', {
            phoneNumber, // should only ever be != null if trying to change the phone number
        }, {...spinnerConfig, headers: {'sso': state.sessionId}})
    },
    [SSO_TYPES.ACTIONS.REQUEST_EMAIL_CODE]({ dispatch, state, rootState }, email) {
        axios.post(rootState.core.apiAddress + '/sso/request_email_code', {
            email, // should only ever be != null if trying to change the email address
        }, {...spinnerConfig, headers: {'sso': state.sessionId}}).then(response => {
            // do nothing
        }).catch(error => {
            dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "SSO_TYPES.ACTIONS.REQUEST_EMAIL_CODE", error});
        })
    },
    [SSO_TYPES.ACTIONS.ACTIVATE_ACCOUNT]({ commit, dispatch, state, rootState }, {emailToken, smsToken}) {
        axios.post(rootState.core.apiAddress + '/sso/activate', {
            emailToken,
            smsToken,
        }, {...spinnerConfig, headers: {'sso': state.sessionId}}).then(response => {
            commit(SSO_TYPES.MUTATIONS.ACTIVATE_ACCOUNT);
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage("Ihr Konto wurde erfolgreich aktiviert.", 'success'));
            dispatch(SSO_TYPES.ACTIONS.GET_SSO_ACCOUNT_INFO);
        }).catch(error => {
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage("Die Aktivierung Ihres SSO-Kontos ist fehlgeschlagen. Bitte versuchen Sie es erneut.", 'danger'));
            dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "SSO_TYPES.ACTIONS.ACTIVATE_ACCOUNT", error});
        })
    },
    async [SSO_TYPES.ACTIONS.REGISTER]({ commit, dispatch, rootState }, {loginName, email, phoneNumber, password}) {
        const payload = {
            loginName,
            email,
            phoneNumber,
            password,
        };
        await axios.post(rootState.core.apiAddress + '/sso/register', payload, {
                ...spinnerConfig, 
                disableDefaultErrorMessage: true,
            }).then(response => {
            if (response?.data) {
                commit(SSO_TYPES.MUTATIONS.LOGIN, {
                    sessionId: response.headers.sso,
                    loginName,
                    ...response.data,
                });
                router.push({name: 'sso-login'});
            }
        }).catch(error => {
            dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "SsoRegistration.register", error});

            const { status, data:responseData } = error?.response ?? {};
            const defaultMessage = "Die Registrierung ist fehlgeschlagen. Vielleicht existiert die Kennung bereits.";
            const message = (status === 400 && responseData?.message?.trim?.()) || defaultMessage;
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(message , 'danger'));
        })
    },
    [SSO_TYPES.ACTIONS.LOGOUT]({ commit, dispatch, state, rootState }, navigateToLogin) {
        if (!state.sessionId)
            return;
        axios.post(rootState.core.apiAddress + '/sso/logout', {}, {...spinnerConfig, headers: {'sso': state.sessionId}})
        .then(response => {
            if (navigateToLogin)
                router.push({name: 'login'});
        }).catch(error => {
            dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "SSO_TYPES.ACTIONS.LOGOUT", error});
        })
        commit(SSO_TYPES.MUTATIONS.LOGOUT);
    },
    [SSO_TYPES.ACTIONS.SET_DEFAULT]({ dispatch, state, rootState }, accessId) {
        return axios.post(rootState.core.apiAddress + '/sso/set_default', {
            id: accessId,
        }, {...spinnerConfig, headers: {'sso': state.sessionId}}).then(response => {
            dispatch(SSO_TYPES.ACTIONS.GET_SSO_ACCOUNT_INFO);
        }).catch(error => {
            dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "SSO_TYPES.ACTIONS.SET_DEFAULT", error});
        })
    },
    [SSO_TYPES.ACTIONS.LOGIN_FK]({ dispatch, state, rootState, getters }, accessId) {
        const referrer = document.referrer;
        return axios.post(rootState.core.apiAddress + '/sso/login_fk', {
            id: accessId,
        }, {...spinnerConfig, headers: {'sso': state.sessionId}}).then(response => {
            const responseData = { ...response.data, currentDate: new Date().getTime()}
            if (getters[CORE_TYPES.GETTERS.STORE_SESSION_INFORMATION]) {
                setObject('loginData', responseData)
            }
            dispatch(CORE_TYPES.ACTIONS.MANAGE_LOGIN_RESPONSE, { data: responseData, referrer, loginSource: 'SSO' })
        }).catch(error => {
            dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "SSO_TYPES.ACTIONS.LOGIN_FK", error});
        })
    },
    [SSO_TYPES.ACTIONS.CREATE_INVITE]({ dispatch, state, rootState }, {ssoName, permission, password}) {
        let config = {
            ...spinnerConfig,
        };
        if (state.sessionId)
            config.headers = {'sso': state.sessionId};
        return axios.post(rootState.core.apiAddress + '/sso/invite', {
            ssoName,
            permission,
            password,
        }, config).then(response => {
            if (!response?.data?.errorStr) {
                dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage("Die Einladung \"" + response.data.loginName + "\" wurde erstellt.", 'success'));
            } else {
                return response?.data?.errorStr;
            }
        })
        .catch(error => {
            dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "SsoInvite.createInvite", error});
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage("Die Einladung konnte nicht versendet werden. Bitte überprüfen Sie ihre Angaben.", 'danger'));
        });
    },
    [SSO_TYPES.ACTIONS.ACCEPT_INVITE]({ state, rootState }, {id, password}) {
        return axios.post(rootState.core.apiAddress + '/sso/accept_invite', {
            id,
            password,
        }, {...spinnerConfig, headers: {'sso': state.sessionId}});
    },
    [SSO_TYPES.ACTIONS.DELETE_INVITE_SSO]({ dispatch, state, rootState, getters }, {id, loginName}) {
        axios.post(rootState.core.apiAddress + '/sso/delete_invite_sso', {
            id,
        }, {...spinnerConfig, headers: {'sso': state.sessionId}}).then(response => {
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage("Die Einladung \"" + loginName + "\" wurde gelöscht.", 'success'));
            dispatch(SSO_TYPES.ACTIONS.GET_SSO_ACCOUNT_INFO);
            if (getters[CORE_TYPES.GETTERS.IS_LOGGED_IN])
                dispatch(SSO_TYPES.ACTIONS.GET_FK_ACCOUNT_INFO);
        }).catch(error => {
            dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "SSO_TYPES.ACTIONS.DELETE_INVITE_SSO", error});
        })
    },
    [SSO_TYPES.ACTIONS.DELETE_INVITE_FK]({ dispatch, rootState, getters }, loginName) {
        axios.post(rootState.core.apiAddress + '/sso/delete_invite_fk', {
            loginName,
        }, spinnerConfig).then(response => {
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage("Die Einladung \"" + loginName + "\" wurde gelöscht.", 'success'));
            if (getters[SSO_TYPES.GETTERS.LOGIN_NAME])
                dispatch(SSO_TYPES.ACTIONS.GET_SSO_ACCOUNT_INFO);
            dispatch(SSO_TYPES.ACTIONS.GET_FK_ACCOUNT_INFO);
        }).catch(error => {
            dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "SSO_TYPES.ACTIONS.DELETE_INVITE_FK", error});
        })
    },
    [SSO_TYPES.ACTIONS.DELETE_ACCESS_SSO]({ dispatch, state, rootState, getters }, id) {
        axios.post(rootState.core.apiAddress + '/sso/delete_access_sso', {
            id,
        }, {...spinnerConfig, headers: {'sso': state.sessionId}}).then(response => {
            dispatch(SSO_TYPES.ACTIONS.GET_SSO_ACCOUNT_INFO);
            if (getters[CORE_TYPES.GETTERS.IS_LOGGED_IN])
                dispatch(SSO_TYPES.ACTIONS.GET_FK_ACCOUNT_INFO);
        }).catch(error => {
            dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "SSO_TYPES.ACTIONS.DELETE_ACCESS_SSO", error});
        })
    },
    [SSO_TYPES.ACTIONS.DELETE_ACCESS_FK]({ dispatch, rootState, getters }, id) {
        axios.post(rootState.core.apiAddress + '/sso/delete_access_fk', {
            id,
        }, spinnerConfig).then(response => {
            if (getters[SSO_TYPES.GETTERS.LOGIN_NAME])
                dispatch(SSO_TYPES.ACTIONS.GET_SSO_ACCOUNT_INFO);
            dispatch(SSO_TYPES.ACTIONS.GET_FK_ACCOUNT_INFO);
        }).catch(error => {
            dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "SSO_TYPES.ACTIONS.DELETE_ACCESS_FK", error});
        })
    },
    [SSO_TYPES.ACTIONS.CHANGE_LOGIN_NAME]({ state, rootState }, payload) {
        return axios.post(rootState.core.apiAddress + '/sso/change_login_name', payload, {...spinnerConfig, headers: {'sso': state.sessionId}});
    },
    async [SSO_TYPES.ACTIONS.CHANGE_PASSWORD]({ state, rootState }, payload) {
        return await axios.post(rootState.core.apiAddress + '/sso/change_password', payload, {...spinnerConfig, headers: {'sso': state.sessionId}});
    },
    [SSO_TYPES.ACTIONS.CHANGE_EMAIL]({ state, rootState }, payload) {
        return axios.post(rootState.core.apiAddress + '/sso/change_email', payload, {...spinnerConfig, headers: {'sso': state.sessionId}});
    },
    [SSO_TYPES.ACTIONS.CHANGE_PHONE_NUMBER]({ state, rootState }, payload) {
        return axios.post(rootState.core.apiAddress + '/sso/change_phone_number', payload, {...spinnerConfig, headers: {'sso': state.sessionId}});
    },

    [SSO_TYPES.ACTIONS.UPDATE_PERMISSION]({ rootState, dispatch }, payload) {
        return axios.post(rootState.core.apiAddress + '/sso/set_permission_type', payload, spinnerConfig)
        .then(response => {
            dispatch(SSO_TYPES.ACTIONS.GET_FK_ACCOUNT_INFO);
            return response;
        })
    },
    [SSO_TYPES.ACTIONS.UPDATE_SHOW_SSO_CUSTOMER_OVERVIEW]({ commit, rootState }, payload) {
        return axios.post(rootState.core.apiAddress + '/sso/show_sso_customer_overview', payload, spinnerConfig)
        .then(response => commit(SSO_TYPES.MUTATIONS.UPDATE_SHOW_SSO_CUSTOMER_OVERVIEW, response.data));
    },

}