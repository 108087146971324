var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "page-header-title-nav__title-container",
      class: {
        "is-wrapped": _vm.isWrapped,
        "no-wrapped": !_vm.isWrapped
      }
    },
    [
      _c("div", { staticClass: "title" }, [
        _c(
          "div",
          { staticClass: "d-flex align-items-center" },
          [
            !_vm.isSecondary && _vm.showBackButton
              ? _c(
                  "BackButtonNavigation",
                  _vm._g(
                    { staticClass: "back-icon hide-on-small-screen" },
                    _vm.$listeners
                  )
                )
              : _vm._e(),
            _vm._t("title", function() {
              return [
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.sanitize(_vm.title)) }
                })
              ]
            })
          ],
          2
        )
      ]),
      _vm.checkIfSlotHasContent(_vm.$slots.subtitle) || _vm.hasSubtitleValue
        ? [
            !_vm.isWrapped
              ? _c("div", { staticClass: "separator" }, [_vm._v(" ")])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "subtitle" },
              [
                _vm._t("subtitle", function() {
                  return [
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(_vm.sanitize(_vm.subtitle))
                      }
                    })
                  ]
                })
              ],
              2
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }