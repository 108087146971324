export const MUTATION_PREFIX = 'VERMITTLERNUMMERN_MUTATIONS_';
export const ACTIONS_PREFIX = 'VERMITTLERNUMMERN_ACTIONS_';
export const GETTERS_PREFIX = 'VERMITTLERNUMMERN_GETTERS_';

export default {
  MUTATIONS: {
    GET_MAIN_LISTING_SUCCESS: MUTATION_PREFIX + 'GET_MAIN_LISTING_SUCCESS',
    GET_DETAILS_LISTING_SUCCESS: MUTATION_PREFIX + 'GET_DETAILS_LISTING_SUCCESS',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
  },
  ACTIONS: {
    GET_MAIN_LISTING: ACTIONS_PREFIX + 'GET_MAIN_LISTING',
    GET_DETAILS_LISTING: ACTIONS_PREFIX + 'GET_DETAILS_LISTING',
    MAKE_ENTRY_MANUELL: ACTIONS_PREFIX + 'MAKE_ENTRY_MANUELL',
  },
  GETTERS: {
    MAIN_LISTING: GETTERS_PREFIX + 'MAIN_LISTING',
    DETAILS_LISTING: GETTERS_PREFIX + 'DETAILS_LISTING',
    TEXT_MODUS: GETTERS_PREFIX + 'TEXT_MODUS',
  }
}