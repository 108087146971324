import PERS_GESCHAEFT_TYPES from './types';

export default {
  [PERS_GESCHAEFT_TYPES.GETTERS.FRAGEBOGEN](state) {
    return state.fragebogen;
  },

  [PERS_GESCHAEFT_TYPES.GETTERS.GESPEICHERTE_FRAGEBOGEN_LIST](state) {
    return state.gespeicherteFragebogenList;
  },

  [PERS_GESCHAEFT_TYPES.GETTERS.OVERVIEW_LIST](state) {
    return state.overviewList;
  },

  [PERS_GESCHAEFT_TYPES.GETTERS.OVERVIEW_FILTERS](state) {
    return state.overviewFilters;
  },

  [PERS_GESCHAEFT_TYPES.GETTERS.OVERVIEW_LIST_PAGE_INDEX](state) {
    return state.overviewListPageIndex;
  },

  [PERS_GESCHAEFT_TYPES.GETTERS.OVERVIEW_LIST_TOTAL_ROWS](state) {
    return state.overviewListTotalRows;
  },

  [PERS_GESCHAEFT_TYPES.GETTERS.OVERVIEW_LIST_IS_LOADING](state) {
    return state.overviewListIsLoading;
  },
}