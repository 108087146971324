var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "explorer__container" },
    [
      _c("DBM", {
        attrs: {
          act: this.act,
          showLoadButton: this.selected != null && this.selected.sid != null
        },
        on: { onLoadButton: _vm.clickSID }
      }),
      this.data1
        ? _c(
            "div",
            [
              _c("Tree", {
                staticClass: "explorer__tree_nodes",
                attrs: {
                  items: this.data1,
                  childrenKey: "children",
                  idKey: "sid",
                  itemSelectedId:
                    this.selected && this.selected.sid && this.selected.serial
                      ? this.selected.sid + ""
                      : null,
                  defaultCollapsed: false
                },
                on: {
                  itemSelect: function($event) {
                    return _vm.selectNode($event)
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "div",
                            { staticClass: "explorer__tree_node_content" },
                            [
                              _c(
                                "span",
                                { staticClass: "explorer__tree_node_label" },
                                [_vm._v(_vm._s(item.txt))]
                              )
                            ]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  95672511
                )
              })
            ],
            1
          )
        : _vm._e(),
      _c("DbmSessionDialog", { ref: "DbmSessionDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }