var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "resize-observer",
          rawName: "v-resize-observer",
          value: _vm.handleResize,
          expression: "handleResize"
        }
      ],
      staticClass: "carousel__container",
      class: _vm.carouselClasses
    },
    [
      _c("div", { staticClass: "carousel__body", style: _vm.bodyStyle }, [
        !_vm.asGrid
          ? _c(
              "div",
              {
                staticClass: "carousel__arrow carousel__arrow--left",
                style: _vm.arrowContainerStyle
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "clickable",
                    attrs: {
                      type: "button",
                      disabled: !_vm.hasPrevItems,
                      tid:
                        _vm._generateTidFromString(
                          _vm.$route.path + "ph-caret-left"
                        ) + "|autoclick"
                    },
                    on: {
                      click: function($event) {
                        return _vm.prevItems()
                      }
                    }
                  },
                  [
                    _c("PhCaretLeft", {
                      attrs: { size: _vm.settings.arrows.size }
                    })
                  ],
                  1
                )
              ]
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "carousel__content",
            on: {
              mousedown: function($event) {
                return _vm.handleInitialScroll($event)
              },
              touchstart: function($event) {
                return _vm.handleInitialScroll($event)
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "carousel__content--scroll",
                class: {
                  "carousel__content--scroll-animated": _vm.isScrollAnimated
                },
                style: _vm.contentScrollStyle
              },
              _vm._l(_vm.items, function(item, index) {
                return _c("CarouselItem", {
                  key: index,
                  attrs: {
                    tid: _vm.getTid(item),
                    item: item,
                    settings: _vm.settings,
                    asGrid: _vm.asGrid
                  }
                })
              }),
              1
            )
          ]
        ),
        !_vm.asGrid
          ? _c(
              "div",
              {
                staticClass: "carousel__arrow carousel__arrow--right",
                style: _vm.arrowContainerStyle
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "clickable",
                    attrs: {
                      type: "button",
                      disabled: !_vm.hasNextItems,
                      tid:
                        _vm._generateTidFromString(
                          _vm.$route.path + "ph-caret-right"
                        ) + "|autoclick"
                    },
                    on: {
                      click: function($event) {
                        return _vm.nextItems()
                      }
                    }
                  },
                  [
                    _c("PhCaretRight", {
                      attrs: { size: _vm.settings.arrows.size }
                    })
                  ],
                  1
                )
              ]
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }