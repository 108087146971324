<template>
  <div>
    <table class="fc-table fc-table-divider fc-table-small">
      <thead>
        <tr>
          <th style="width: 30%;">Tausch Von</th>
          <th style="width: 30%;">Tausch zu</th>
          <th style="width: 30%;">Höhe</th>
          <th style="width: 10%;"></th>
        </tr>
      </thead>
      <tbody v-for="(position, index) in positions" :key="index">
        <tr class="noBorder">
          <td>{{getTauschVonDepotsName(position)}}</td>
          <td>{{getFondsName(position)}}</td>
          <td>
            <div class="row">
              <div class="col-6 px-1">
                <InputField 
                  :type="getType(position)"
                  :isEmbedded="true"
                  :showCleanButtonIcon="false"
                  :disabled="disabled(position)"
                  v-model="position.hoehe" 
                  @change="onChangeInputField($event, position, 'hoehe')"/>
              </div>
              <div class="col-6 px-1">
                <ComboBox
                  v-model="position['modus']" 
                  :isEmbedded="true" 
                  :values="comboboxValuesModus"
                  @change="onChangeInputField($event, position, 'modus')">
                </ComboBox>
              </div>
            </div>
          </td>
          <td>
            <WertpapierAuswahlPositionsButtons 
              :position="position" 
              :positionName="getFondsName(position)"
              :antragId="antragId"
              :categoryId="categoryId"/>
          </td>
        </tr>
        <tr>
          <td>
            <InputField 
              label="Bemerkung"
              v-model="position.bemerkung" 
              :isEmbsedded="true"  
              @change="onChangeInputField($event, position, 'bemerkung')"/>
          </td>
          <td colspan="3">
            <ComboBox
              label="Bestehende Spar-/Auszahlpläne"
              v-model="position.plaene" 
              :isEmbedded="true" 
              :values="comboboxValuesPlaene"
              @change="onChangeInputField($event, position, 'plaene')">
            </ComboBox>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ANTRAG_TYPES from '@/store/antrag/types';
import FONDSFINDER_TYPES from '@/store/fondsfinder/types';
import InputField from "@/components/core/forms/InputField.vue";
import WertpapierAuswahlPositionsButtons from '@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlPositionsButtons.vue'
import ComboBox from '@/components/core/forms/ComboBox.vue';

export default {
  props: {
    positions: {
    },
    config: {
    },
    antragId: {
    },
    categoryId: {
    },
    tauschVonName: {
      type: String,
    },
    tauschZuName: {
      type: String,
    },
  },
  data() {
    return {
      depots: this.config && this.config.depots || {},
      comboboxValuesModus: [{ value: 'ALLE_ANTEILE', label: 'Alle Anteile' },
        { value: 'ANTEILE', label: 'Anteile' }, { value: 'BETRAG', label: 'Betrag' }],
      comboboxValuesPlaene: [{ value: 'PLAENE_UEBERNEHMEN', label: 'in neuen Zielfonds übernehmen' },
        { value: 'PLAENE_BEENDEN', label: 'beenden' }],
    }
  },
  computed: {
    ...mapGetters({
      positionInfo: FONDSFINDER_TYPES.GETTERS.POSITION_INFO
    }),
    getBetragId() {
      return this.config && this.config.betrag && this.config.betrag.id
    },
    getSumme() {
      if (this.config && this.config.hasSumme && Array.isArray(this.positions)) {
        const betragId = this.getBetragId
        return this.positions.map(p => +p[betragId] || 0).reduce((curr, accu) => accu + curr);
      } 
    }
  },
  methods: {
    getType(pos) {
      return pos && (pos.modus === 'BETRAG' ? 'currency' : 'number')
    },
    disabled(pos) {
      return pos && pos.modus === 'ALLE_ANTEILE';
    },
    getFondsName(pos) {
      const wertpapiername = this.positionInfo && this.positionInfo[pos.isin] && this.positionInfo[pos.isin].wertpapiername || pos.fondsname || pos.wertpapiername || pos.fondsNameZu;
      return wertpapiername ? (wertpapiername + ', ISIN: ' + pos.isin) : '';
    },
    getTauschVonDepotsName(pos) {
      if (pos) {
        const bgsNr = pos[pos['tauschVonName']] || pos['bgsVonNr'];
        return bgsNr && (this.depots.find(depot => depot.value === bgsNr) || {}).label || '';
      }
    },
    commitUpdatePositionsFromEvent(positionChange, isin) {
      const payload = {
        id: this.antragId,
        categoryId: this.categoryId,
        fonds: [
          { 
            ...positionChange,
            isin,
          }
        ]
      }
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_POSITIONS, payload)
    },
    onChangeInputField($event, position, field) { 
      const positionChange = { [field]: $event }
      if ($event === 'ALLE_ANTEILE') {
        positionChange['hoehe'] = '';
      }
      this.commitUpdatePositionsFromEvent(positionChange, position.isin)
      this.disabled(position);
    }
  },
  components: {
    InputField,
    ComboBox,
    WertpapierAuswahlPositionsButtons,
  }
}
</script>

<style scoped>
td {
  vertical-align: middle;
}
tr.noBorder td {
  border-bottom-style: hidden;
}
</style>