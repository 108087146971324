var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isDayType
    ? _c(
        "GhostLoading",
        [
          _c(
            "div",
            { staticClass: "calendar-ghost-loading--inline" },
            [
              _c("Block", {
                style: { maxWidth: "77px" },
                attrs: { height: "38" }
              }),
              _c("Block", { attrs: { width: "100%", height: "38" } })
            ],
            1
          ),
          _vm._l(10, function(n) {
            return _c(
              "div",
              { key: n, staticClass: "calendar-ghost-loading--inline" },
              [
                _c("Block", {
                  style: { maxWidth: "77px" },
                  attrs: { height: "64" }
                }),
                _c("Block", { attrs: { width: "100%", height: "64" } })
              ],
              1
            )
          })
        ],
        2
      )
    : _vm.isWeekType
    ? _c(
        "GhostLoading",
        [
          _c(
            "div",
            { staticClass: "calendar-ghost-loading--inline" },
            [
              _c("Block", {
                style: { maxWidth: "77px" },
                attrs: { height: "38" }
              }),
              _vm._l(7, function(n) {
                return _c("Block", {
                  key: n,
                  attrs: { width: "100", height: "38" }
                })
              })
            ],
            2
          ),
          _vm._l(10, function(n) {
            return _c(
              "div",
              { key: n, staticClass: "calendar-ghost-loading--inline" },
              [
                _c("Block", {
                  style: { maxWidth: "77px" },
                  attrs: { height: "64" }
                }),
                _vm._l(7, function(m) {
                  return _c("Block", {
                    key: m,
                    attrs: { width: "100", height: "64" }
                  })
                })
              ],
              2
            )
          })
        ],
        2
      )
    : _vm.isWorkWeekType
    ? _c(
        "GhostLoading",
        [
          _c(
            "div",
            { staticClass: "calendar-ghost-loading--inline" },
            [
              _c("Block", {
                style: { maxWidth: "77px" },
                attrs: { height: "38" }
              }),
              _vm._l(5, function(n) {
                return _c("Block", {
                  key: n,
                  attrs: { width: "100", height: "38" }
                })
              })
            ],
            2
          ),
          _vm._l(10, function(n) {
            return _c(
              "div",
              { key: n, staticClass: "calendar-ghost-loading--inline" },
              [
                _c("Block", {
                  style: { maxWidth: "77px" },
                  attrs: { height: "64" }
                }),
                _vm._l(5, function(m) {
                  return _c("Block", {
                    key: m,
                    attrs: { width: "100", height: "64" }
                  })
                })
              ],
              2
            )
          })
        ],
        2
      )
    : _vm.isMonthType
    ? _c(
        "GhostLoading",
        [
          _c(
            "div",
            { staticClass: "calendar-ghost-loading--inline" },
            _vm._l(7, function(m) {
              return _c("Block", {
                key: m,
                attrs: { width: "150", height: "38" }
              })
            }),
            1
          ),
          _vm._l(5, function(n) {
            return _c(
              "div",
              { key: n, staticClass: "calendar-ghost-loading--inline" },
              _vm._l(7, function(m) {
                return _c("Block", {
                  key: m,
                  attrs: { width: "150", height: "128" }
                })
              }),
              1
            )
          })
        ],
        2
      )
    : _vm.isCalendarType
    ? _c("GhostLoading", [_c("Block", { attrs: { height: "120" } })], 1)
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }