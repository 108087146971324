var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("EntitySelector", {
    attrs: {
      isPersonPicker: "",
      label: _vm.label,
      value: _vm.value,
      isComponentHalfSize: _vm.isComponentHalfSize,
      values: _vm.values,
      disabled: _vm.disabled,
      sortComboboxValues: false
    },
    on: {
      input: function($event) {
        return _vm.$emit("input", $event)
      },
      change: function($event) {
        return _vm.$emit("change", $event)
      },
      new: function($event) {
        return _vm.newPerson()
      },
      edit: function($event) {
        return _vm.editPerson($event)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }