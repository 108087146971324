var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("TeleportToBody", [
    _vm.isOffline
      ? _c("div", { staticClass: "offline__container" }, [
          _c("div", { staticClass: "offline__message" }, [
            _c(
              "div",
              { staticClass: "offline__message--icon" },
              [_c("PhWifiX", { attrs: { size: 36 } })],
              1
            ),
            _c("div", { staticClass: "offline__message--content" }, [
              _c("div", { staticClass: "offline__message--title" }, [
                _vm._v("Sie sind offline")
              ]),
              _c("div", { staticClass: "offline__message--text" }, [
                _vm._v(
                  "Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut"
                )
              ])
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }