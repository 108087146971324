import axios from 'axios';
import BROKER_PERMISSIONS_TYPES, { __MAKLER__, } from './types';
import CORE_TYPES from '@/store/core/types';
import { makeQueryParam } from '@/helpers/utils-helper';

const SERVICE_PATH = '/brokerPermissions'
const config = {
  defaultSpinner: true
};

export default {

  async [BROKER_PERMISSIONS_TYPES.ACTIONS.FIND_COMBO_DATA]({ getters, commit, }) {
    if(Object.keys(getters[BROKER_PERMISSIONS_TYPES.GETTERS.COMBO_DATA]).length > 0) return;

    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/comboData`, config);
    commit(BROKER_PERMISSIONS_TYPES.MUTATIONS.SET_COMBO_DATA, response?.data);
  },

  async [BROKER_PERMISSIONS_TYPES.ACTIONS.FIND_PERMISSIONS]({ getters, commit, }) {
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/permissions`, config);
    commit(BROKER_PERMISSIONS_TYPES.MUTATIONS.SET_PERMISSIONS, response?.data);
  },

  async [BROKER_PERMISSIONS_TYPES.ACTIONS.SAVE_PERMISSIONS]({ getters, commit, }) {
    const edited = getters[BROKER_PERMISSIONS_TYPES.GETTERS.PERMISSIONS_EDITED];
    if(!edited) return;

    const payload = { ...edited, };
    const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/permissions`, payload, config);
    commit(BROKER_PERMISSIONS_TYPES.MUTATIONS.RESET_PERMISSIONS_EDITED);
    commit(BROKER_PERMISSIONS_TYPES.MUTATIONS.SET_PERMISSIONS, response?.data);
  },

  async [BROKER_PERMISSIONS_TYPES.ACTIONS.FIND_ADDITIONAL_PERMISSIONS]({ getters, commit, }, { unternr, } = {}) {
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/additionalPermissions`, {
      ...config,
      params: {
        unternr,
      },
    });
    const data = response?.data || {};
    commit(BROKER_PERMISSIONS_TYPES.MUTATIONS.SET_ADDITIONAL_PERMISSIONS, {
      [data.unternr || __MAKLER__]: { ...data, },
    });
  },

  async [BROKER_PERMISSIONS_TYPES.ACTIONS.SAVE_ADDITIONAL_PERMISSIONS]({ getters, commit, }) {
    const edited = getters[BROKER_PERMISSIONS_TYPES.GETTERS.ADDITIONAL_PERMISSIONS_EDITED];
    if(!edited) return;

    const payload = { ...edited, };
    const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/additionalPermissions`, payload, config);
    const data = response?.data || {};
    commit(BROKER_PERMISSIONS_TYPES.MUTATIONS.RESET_ADDITIONAL_PERMISSIONS_EDITED);
    commit(BROKER_PERMISSIONS_TYPES.MUTATIONS.SET_ADDITIONAL_PERMISSIONS, {
      [data.unternr || __MAKLER__]: { ...data, },
    });
  },

  async [BROKER_PERMISSIONS_TYPES.ACTIONS.RESTORE_DEFAULT_ADDITIONAL_PERMISSIONS]({ getters, commit, }, { unternr, }) {
    const params = makeQueryParam({ unternr})

    const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/restoreDefaultAddPerm?${params}`, config);
    const data = response?.data || {};
    commit(BROKER_PERMISSIONS_TYPES.MUTATIONS.RESET_ADDITIONAL_PERMISSIONS_EDITED);
    commit(BROKER_PERMISSIONS_TYPES.MUTATIONS.SET_ADDITIONAL_PERMISSIONS, {
      [data.unternr || __MAKLER__]: { ...data, },
    });
  },

  async [BROKER_PERMISSIONS_TYPES.ACTIONS.RESTORE_DEFAULT_SUB_BROKERS_ADDITIONAL_PERMISSIONS]({ getters, commit, }) {

    const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/restoreDefaultAddPermSubBroker`, config);
    const data = response?.data || {};
    commit(BROKER_PERMISSIONS_TYPES.MUTATIONS.RESET_ADDITIONAL_PERMISSIONS_EDITED);
    commit(BROKER_PERMISSIONS_TYPES.MUTATIONS.SET_ADDITIONAL_PERMISSIONS, {
      [data.unternr || __MAKLER__]: { ...data, },
    });
  },

}
