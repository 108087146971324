<template>
  <div>
    <MultiBankingNavigation subtitle="Übersicht" />

    <div class="box__container" v-if="!isMobileNativeContext">
      <div class="box__title">Bankkonten</div>
      <KontoverwaltungActions/>
    </div>

    <div class="box__container" v-if="showErlaeuterung">
      <BaseCollapsable :showSection="showExpanded">
        <template v-slot:title>
          <span class="box__title">
            Erläuterung
          </span>
        </template>
        <template v-slot:content>
          <span v-html="sanitize(shareDataHelpText)"></span>
        </template>
      </BaseCollapsable>
    </div>

    <div v-if="isMultibankingDataAllowed">
      <KontoverwaltungAccountList v-if="multibankingAccounts" :showGhost="showGhost"/>
    </div>
    <div v-else class="box__container">
      <div class="box__title">
        Achtung, Zugriff verweigert! 
      </div>
      Sie haben keinen Berechtigung um diese Daten zu sehen.
    </div>

    <BaseModal
      :showDefaultButtons="false"
      :actions="baseModalActions"
      ref="errorModal"
      modalTitle="Fehler"
      @close="removeReentryParameters"
      @action-CLOSE="$refs.errorModal.close()">
      {{errorMessage}}
    </BaseModal>

    <BaseModal
      :showDefaultButtons="false"
      :actions="baseModalActions"
      modalTitle=""
      ref="transferSuccess"
      @close="removeReentryParameters"
      @action-CLOSE="$refs.transferSuccess.close()">  
      Ihre Überweisung wurde erfolgreich übermittelt
    </BaseModal>
  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import MULTIBANKING_TYPES from '@/store/multiBanking/types'
import { mapGetters } from 'vuex';
import KontoverwaltungActions from '@/components/multiBanking/KontoverwaltungActions.vue'
import KontoverwaltungAccountList from '@/components/multiBanking/KontoverwaltungAccountList.vue'
import BaseCollapsable from '@/components/core/BaseCollapsable.vue'
import BaseModal from '@/components/core/BaseModal.vue'
import { ROLES } from '@/router/roles'
import MultiBankingNavigation from '@/components/multiBanking/MultiBankingNavigation.vue';
import {sanitize} from '@/helpers/string-helper.js';
import { BaseModalSimpleAction } from '@/components/core/base-modal-actions/base-modal-actions-utils';
import BRIDGE_TYPES from '@/store/bridge/types';

export default {
  mixins: [],
  components: {
    KontoverwaltungActions,
    KontoverwaltungAccountList,
    BaseCollapsable,
    BaseModal,
    MultiBankingNavigation,
  },
  props: {
    containerWidth: {
      type: Number,
      default: 0
    },
    containerHeight: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      showExpanded: false,
      errorMessage: null,
      showGhost: true,
    }
  },
  computed: {
    ...mapGetters({
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      multibankingAccounts: MULTIBANKING_TYPES.GETTERS.ACCOUNTS,
      parameters: MULTIBANKING_TYPES.GETTERS.PARAMETERS,
      isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
      isMobileNativeContext: BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT,
    }),
    showErlaeuterung() {
      return this.parameters && !this.isBrokerOrBypass
    },
    showAccounts() {
      return this.$route.meta.showAccounts
    },
    shareDataHelpText() {
      return this.parameters?.shareDataHelpText || ''
    },
    isMultibankingDataAllowed() {
      return this.hasRoles?.(ROLES.MULTIBANKING_DATA_ALLOWED)
    },
    baseModalActions() {
      return [
        BaseModalSimpleAction('CLOSE', 'Schließen')
          .withPrimary(() => true)
          .withVisibleOnSmallScreen(() => false),
      ];
    }, 
  },
  async mounted() {

    // check if we returned from adding an account using BanksAPI
    const baReentry = this.$route.query.baReentry
    const accountId = this.$route.query.accountId

    if (baReentry) {
      if (baReentry === 'TRANSFER_SUCCESS') {
        this.$refs.transferSuccess.open()
      } else if (baReentry === 'ACCOUNT_CREATED') {
        if (accountId) {
          await this.$store.dispatch(MULTIBANKING_TYPES.ACTIONS.FINALIZE_ACCOUNT, { baReentry, accountId })
        }
        this.removeReentryParameters()
      } else {
        this.errorMessage = baReentry === 'USER_CANCELLED' ? 
          'Der Vorgang wurde abgebrochen' :
          'Ein Fehler ist aufgetreten. Bitte versuchen Sie es zu einem anderen Zeitpunkt noch einmal!'

        if (accountId) {
          await this.$store.dispatch(MULTIBANKING_TYPES.ACTIONS.ABORT_REGISTER_NEW_ACCOUNT, { baReentry, accountId })
        }
        this.$refs.errorModal.open()
      }
    } else {
      this.$store.commit(MULTIBANKING_TYPES.MUTATIONS.RESET_LOADED);
      await this.$store.dispatch(MULTIBANKING_TYPES.ACTIONS.LOAD_ALL_MULTIBANKING_DATA)
    }

    this.showGhost = false
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zum Multibanking', 
      to, 
      from
    });
    next();
  },
  methods: {
    sanitize(htmlString) {
        return sanitize(htmlString);
    },
    removeReentryParameters() {
      let query = { ...this.$route.query }
      delete query.baReentry;
      delete query.accountId;
      this.$router.replace({ query }).catch(() => {});
    },
  }
}
</script>

<style scoped>
.space-between {
  justify-content: space-between;
}
.flex-item {
  flex: 1 1 auto;
}

.text-center {
  text-align: center;
}
</style>