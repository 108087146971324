import WINDOW_CONTROL_TYPES from './types';
import LOG_TYPES from '@/store/log/types';

export default {

  [WINDOW_CONTROL_TYPES.ACTIONS.OPEN_WINDOW]({ dispatch, getters, commit, }, { id, url, }) {
    if(!id) {
      dispatch(LOG_TYPES.ACTIONS.ERROR, 'WINDOW_CONTROL_TYPES.ACTIONS.OPEN_WINDOW: an "id" must be informed!');
      return;
    }

    if(!url) {
      dispatch(LOG_TYPES.ACTIONS.ERROR, 'WINDOW_CONTROL_TYPES.ACTIONS.OPEN_WINDOW: an "url" must be informed!');
      return;
    }

    const opened = getters[WINDOW_CONTROL_TYPES.GETTERS.OPENED];
    const item = opened?.[id];

    let windowOpened = item?.windowOpened;
    if(!windowOpened) {
      windowOpened = window.open(url, '_blank', 'fullscreen=yes,location=yes');

      // add to store
      commit(WINDOW_CONTROL_TYPES.MUTATIONS.ADD_OPENED, { id, url, windowOpened, });

      windowOpened.addEventListener('load', () => {
        // after new window loads add unload event to remove from store if it closes
        windowOpened.addEventListener('unload', () => dispatch(WINDOW_CONTROL_TYPES.ACTIONS.CLOSE_WINDOW, { id, }));
      });
    }
    windowOpened.focus();
  },

  [WINDOW_CONTROL_TYPES.ACTIONS.CLOSE_WINDOW]({ dispatch, getters, commit, }, { id, }) {
    if(!id) {
      dispatch(LOG_TYPES.ACTIONS.ERROR, 'WINDOW_CONTROL_TYPES.ACTIONS.CLOSE_WINDOW: an "id" must be informed!');
      return;
    }

    const opened = getters[WINDOW_CONTROL_TYPES.GETTERS.OPENED];
    const windowOpened = opened?.[id]?.windowOpened;
    windowOpened?.close();
    commit(WINDOW_CONTROL_TYPES.MUTATIONS.REMOVE_OPENED, { id, });
  },

}
