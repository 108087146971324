import GELDWAESCHEBELEHRUNG_TYPES from './types';

export default {
  [GELDWAESCHEBELEHRUNG_TYPES.MUTATIONS.GET_LAYOUT_METADATA_SUCCESS](state, payload) {
    state.layoutMetadata = payload;
  }, 

  [GELDWAESCHEBELEHRUNG_TYPES.MUTATIONS.GET_WAG_PRUEFUNG_LINK_SUCCESS](state, payload) {
    state.wagPruefungLink = payload;
  }, 

  
}