var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _c("BaseCollapsable", {
        attrs: { showSection: _vm.showExpanded },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _c("span", { staticClass: "box__title" }, [
                  _vm._v(" Hinweise und Fehler ")
                ]),
                _vm.hasHinweis
                  ? _c("span", [
                      _vm._v(
                        " |  (" +
                          _vm._s(_vm.hinweise.length) +
                          " Hinweis" +
                          _vm._s(_vm.hinweise.length > 1 ? "e" : "") +
                          ")"
                      )
                    ])
                  : _vm._e(),
                _vm.hasWarnings
                  ? _c("span", { staticClass: "color-danger" }, [
                      _vm._v(" |  " + _vm._s(_vm.hinweisText))
                    ])
                  : _vm._e()
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                !_vm.hasHinweis && !_vm.hasWarnings
                  ? _c(
                      "div",
                      { staticClass: "antrag-sidetext__container mt-8px" },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _vm._v(" Keine Hinweise vorhanden. ")
                          ])
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm.hasHinweis || _vm.hasWarnings
                  ? _c(
                      "div",
                      { staticClass: "antrag-sidetext__container mt-8px" },
                      [
                        _vm.hasWarnings
                          ? [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c("span", { staticClass: "font-bold" }, [
                                    _vm._v("Fehler:")
                                  ])
                                ])
                              ]),
                              _c(
                                "ul",
                                _vm._l(_vm.sortWarnings, function(
                                  warning,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "row" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "col-12",
                                          class: {
                                            clickable: _vm.isWarningNavigable(
                                              warning
                                            ),
                                            "color-danger":
                                              warning.status === "FEHLER"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.warningClicked(warning)
                                            }
                                          }
                                        },
                                        [
                                          _c("li", [
                                            _c(
                                              "span",
                                              { staticClass: "font-bold" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getWarTitle(warning)
                                                  )
                                                )
                                              ]
                                            ),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getWarnMessage(warning)
                                                )
                                              )
                                            ]),
                                            _vm.isWarningNavigable(warning)
                                              ? _c(
                                                  "span",
                                                  { staticClass: "ml-1" },
                                                  [
                                                    _c("ph-pencil-line", {
                                                      attrs: { size: 16 }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ])
                                        ]
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            ]
                          : _vm._e(),
                        _vm.hasHinweis
                          ? [
                              _c("div", { staticClass: "row" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c("span", { staticClass: "font-bold" }, [
                                    _vm._v("Hinweise:")
                                  ])
                                ])
                              ]),
                              _c(
                                "ul",
                                _vm._l(_vm.hinweise, function(hinweis, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "row" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "col-12",
                                          class: {
                                            clickable: _vm.isWarningNavigable(
                                              hinweis
                                            ),
                                            "loading-active":
                                              hinweis.loading ||
                                              _vm.isSomeActionLoading(hinweis)
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.warningClicked(hinweis)
                                            }
                                          }
                                        },
                                        [
                                          _c("li", [
                                            hinweis.loading
                                              ? _c(
                                                  "span",
                                                  { staticClass: "mr-1" },
                                                  [_c("AnimatedSpinner")],
                                                  1
                                                )
                                              : _vm._e(),
                                            hinweis.label || hinweis.title
                                              ? _c(
                                                  "span",
                                                  { staticClass: "font-bold" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        hinweis.label ||
                                                          hinweis.title
                                                      ) + ": "
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c("span", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.sanitize(
                                                    hinweis.text ||
                                                      hinweis.message
                                                  )
                                                )
                                              }
                                            }),
                                            hinweis.actions &&
                                            hinweis.actions.length
                                              ? _c(
                                                  "span",
                                                  { staticClass: "ml-1" },
                                                  _vm._l(
                                                    hinweis.actions,
                                                    function(action, index) {
                                                      return _c(
                                                        "a",
                                                        {
                                                          key: index,
                                                          class: {
                                                            "ml-2": index > 0
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.executeAction(
                                                                hinweis,
                                                                action
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          action.loading
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "mr-1"
                                                                },
                                                                [
                                                                  _c(
                                                                    "AnimatedSpinner"
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          _vm._v(
                                                            _vm._s(action.label)
                                                          )
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                )
                                              : _vm._e(),
                                            _vm.isWarningNavigable(hinweis)
                                              ? _c(
                                                  "span",
                                                  { staticClass: "ml-1" },
                                                  [
                                                    _c("ph-pencil-line", {
                                                      attrs: { size: 16 }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ])
                                        ]
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }