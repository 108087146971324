var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _c("div", { staticClass: "box__title" }, [
        _vm._v("Datenschutz / Sicherheiten")
      ]),
      _c("InputRadioBoxGroup", {
        attrs: {
          title: "Ist Ihr Mobiltelefon mit einer Sicherheitssperre gesichert?",
          value: _vm.form.INPUT_HANDY_GESICHERT,
          values: _vm.values
        },
        on: {
          input: function($event) {
            return _vm.fieldChanged($event, "INPUT_HANDY_GESICHERT")
          }
        }
      }),
      _c("InputRadioBoxGroup", {
        attrs: {
          title:
            "Sind Ihre elektronischen Geräte (PC, Laptop, Tablett, etc.) zumindest mit einem Passwort geschützt?",
          value: _vm.form.INPUT_GERAETE_MIT_PASSWORT,
          values: _vm.values
        },
        on: {
          input: function($event) {
            return _vm.fieldChanged($event, "INPUT_GERAETE_MIT_PASSWORT")
          }
        }
      }),
      _c("InputRadioBoxGroup", {
        attrs: {
          title:
            "Werden diese Sicherheitsstandards auch von Ihren Assistenten/Mitarbeitern eingehalten?",
          value: _vm.form.INPUT_MITARBEITER_SICHERHEITSSTANDARDS,
          values: _vm.values
        },
        on: {
          input: function($event) {
            return _vm.fieldChanged(
              $event,
              "INPUT_MITARBEITER_SICHERHEITSSTANDARDS"
            )
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }