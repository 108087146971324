var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _c("PageHeaderTitleNavigation", { attrs: { title: _vm.title } }),
          _c("StepperForm", {
            ref: "stepperForm",
            attrs: {
              stepType: "fixed",
              stepperMediator: _vm.tabsStepperMediator,
              showSaveSpinner: _vm.showSaveSpinner,
              selectedStepKey: _vm.currentStep,
              hasFertig: false
            },
            on: {
              "set-step": function($event) {
                return _vm.setStepByKey($event)
              }
            },
            scopedSlots: _vm._u(
              [
                _vm.listOfComponentsByDivider &&
                _vm.listOfComponentsByDivider.length
                  ? {
                      key: "contentTemplate",
                      fn: function() {
                        return [
                          _c("div", { staticClass: "row my-0" }, [
                            _c(
                              "div",
                              { staticClass: "col-12" },
                              [
                                _vm._l(_vm.listOfComponentsByDivider, function(
                                  componentsByDivider,
                                  index
                                ) {
                                  return _c("div", { key: index }, [
                                    _c(
                                      "div",
                                      { staticClass: "box__container" },
                                      [
                                        _vm._l(componentsByDivider, function(
                                          component,
                                          index
                                        ) {
                                          return [
                                            !_vm.isComponentHidden(component)
                                              ? _c(
                                                  "div",
                                                  { key: index + component.id },
                                                  [
                                                    _c("AntragComponent", {
                                                      attrs: {
                                                        component: component,
                                                        comboboxSelection:
                                                          (_vm.comboboxSelections &&
                                                            _vm
                                                              .comboboxSelections[
                                                              component.id
                                                            ]) ||
                                                          [],
                                                        data:
                                                          _vm.dataForCurrentStep ||
                                                          {},
                                                        disabled:
                                                          _vm.disabledForCurrentStep,
                                                        isComponentHalfSizeEnabled:
                                                          ""
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.updateData(
                                                            component.id,
                                                            $event
                                                          )
                                                        }
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _vm._e()
                                          ]
                                        })
                                      ],
                                      2
                                    )
                                  ])
                                }),
                                _c(
                                  "div",
                                  { staticClass: "box__container" },
                                  [
                                    _vm._l(
                                      _vm.buttonMapForCurrentStep,
                                      function(buttonLink) {
                                        return _c(
                                          "div",
                                          { key: buttonLink.buttonId },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: { display: "flex" }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "toggle_switch"
                                                  },
                                                  [
                                                    _c("InputToggleSwitch", {
                                                      attrs: {
                                                        inLineLabel: "",
                                                        disabled:
                                                          _vm.disabledForCurrentStep
                                                      },
                                                      model: {
                                                        value:
                                                          _vm
                                                            .dataForCurrentStep[
                                                            buttonLink.buttonId
                                                          ],
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.dataForCurrentStep,
                                                            buttonLink.buttonId,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "dataForCurrentStep[buttonLink.buttonId]"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c("DownloadLink", {
                                                  attrs: {
                                                    title: buttonLink.label,
                                                    disabled: !buttonLink.erfolg,
                                                    href: _vm.getPDFLink(
                                                      buttonLink
                                                    )
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      }
                                    ),
                                    _c("div", { staticClass: "small" }, [
                                      _vm._v(
                                        " ** In der Erklärung können anhängig gewesene Strafverfahren unberücksichtigt bleiben, die mangels hinreichenden Tatverdacht endgültig eingestellt wurden, mit einem Freispruch endeten oder bei denen eine ergangene Eintragung im Bundeszentralregister entfernt oder getilgt wurde. "
                                      )
                                    ]),
                                    _c(
                                      "BaseButton",
                                      {
                                        attrs: {
                                          disabled:
                                            _vm.disabledForCurrentStep ||
                                            _vm.showSaveSpinner
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.saveNegativerklaerung()
                                          }
                                        }
                                      },
                                      [_vm._v(" OK ")]
                                    )
                                  ],
                                  2
                                )
                              ],
                              2
                            )
                          ])
                        ]
                      },
                      proxy: true
                    }
                  : {
                      key: "buttonsTemplate",
                      fn: function() {
                        return [_c("NoData", { attrs: { noIcon: "" } })]
                      },
                      proxy: true
                    }
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "errorModal",
          attrs: {
            modalTitle: "Fehler",
            labelButtonConfirm: "Ok",
            showCancelButton: false
          }
        },
        [_c("label", [_vm._v(_vm._s(_vm.errorMessage))])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }