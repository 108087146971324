<template>
  <div>
    <BaseModal 
      ref="editModal"
      modalTitle="Link"
      @onCloseButton="close()"
      @close="close()"
      @onConfirmButton="onConfirmButton">

      <ComboBox
        label="Link-Typ"
        v-model="linkType"
        :values="[{value: 'URL', label: 'URL'}, {value: 'Email', label: 'E-Mail'}]"
        :firstEmpty="false"
      />
      <InputField
        v-if="htmlLinkForm.currentSelectionIsEmpty && !htmlLinkForm.previousUrl"
        label="Anzeigentext"
        v-model="htmlLinkForm.linkText"
        placeholder="">
      </InputField>

      <template v-if="linkType === 'URL'">
        <InputField
          label="URL"
          placeholder="https://"
          v-model="htmlLinkForm.url"/>
      </template>
      <template v-else>
        <InputField
          label="E-Mail-Adresse"
          v-model="linkTextEmail"
          placeholder="">
        </InputField>
        <InputField
          label="Betreffzeile"
          v-model="linkTextSubj"
          placeholder="">
        </InputField>
        <InputField
          label="Nachrichtentext"
          v-model="linkTextMsg"
          placeholder="">
        </InputField>
      </template>

    </BaseModal>
  </div>
</template>

<script>
import BaseModal from '@/components/core/BaseModal.vue';
import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';

function htmlLinkForm() {
  return {
    url: '',
    linkText: '',
  }
}

const HAS_PROTOCOL = /(https?|mailto):\/\//i

export default {
  props: {
    linkForm: {
    }
  },

  data() {
    return {
      loading: false,
      htmlLinkForm: htmlLinkForm(),
      linkType: 'URL',
      linkTextEmail: '',
      linkTextMsg: '',
      linkTextSubj: '',
    }
  },

  computed: {
    
  },

  methods: {
    close() {
      this.$emit("close")
    },
    onConfirmButton() {
      if (this.linkType !== 'URL' && this.linkTextEmail !== null) {
        this.htmlLinkForm.url = `mailto:${this.linkTextEmail}${this.linkTextSubj || this.linkTextMsg ? `?subject=${this.linkTextSubj || ''}&body=${this.linkTextMsg || ''}` : ''}`
      }

      if (this.linkType !== 'Email' && !HAS_PROTOCOL.test(this.htmlLinkForm.url)) {
        this.htmlLinkForm.url = `https://${this.htmlLinkForm.url}`;
      }

      // if there is no text selected, use the link as text
      if (this.htmlLinkForm.currentSelectionIsEmpty && !this.htmlLinkForm.previousUrl && !this.htmlLinkForm.linkText) {
        this.htmlLinkForm.linkText = this.htmlLinkForm.url;
      }

      this.$emit('onConfirmButton', this.htmlLinkForm)
    }
  },
  mounted() {
    if (this.linkForm) {
      Object.assign(this.htmlLinkForm, this.linkForm)

      if (this.htmlLinkForm.url?.indexOf('mailto:') >= 0) {
        const urlObject = new URL(this.htmlLinkForm.url);
        this.linkType = 'Email';
        this.linkTextEmail = urlObject.pathname;
        this.linkTextMsg = urlObject.searchParams.get('body') || ''
        this.linkTextSubj = urlObject.searchParams.get('subject') || ''
      }
    }

    this.$refs.editModal.open()
  },

  components: {
    BaseModal,
    InputField,
    ComboBox,
  },
}
</script>
