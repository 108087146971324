var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "updaterModal",
      attrs: {
        modalTitle: "",
        labelButtonCancel: "Schliessen",
        labelButtonConfirm: "Abmelden"
      },
      on: { onConfirmButton: _vm.refreshApp }
    },
    [
      _vm._v(
        " Es gibt eine neues Update. Bitte melden Sie sich ab und erneut an! "
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }