import TAGS_TYPES from './types';
import Vue from 'vue'
import { getInitialState } from './index';

export default {
  [TAGS_TYPES.MUTATIONS.GET_TAGS_SUCCESS](state, data) {
    Vue.set(state, 'tags', data.tags)
  },
  [TAGS_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },

}