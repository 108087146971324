import RISIKOVERTEILUNG_TYPES from './types';
import axios from 'axios';

export default {
  async [RISIKOVERTEILUNG_TYPES.ACTIONS.GET_RISIKOVERTEILUNG]({ commit, rootState }) {

    const path = '/../mrsriskindicator';
    const response = await axios.get(`${rootState.core.apiAddress}${path}`);
    if (response.status === 200) {
      commit(RISIKOVERTEILUNG_TYPES.MUTATIONS.GET_RISIKOVERTEILUNG_SUCCESS, response?.data || {})
    }
  },

}