<template>
	<CoreCard ref="coreCard" :showToolbar="showToolbar" :listView="listView">
		<template v-if="showTitle" v-slot:header>
			{{chartName}}
		</template>
		<template v-slot:alternative>
		</template>
    <div class="content">
      <div v-if="isContainerRendered">
         <div class="d-table">
           <div class="row" v-for="(value, key) in data[`tbl#${chartName}`]" :key="key">
             <div class="cell text-bold">
               {{key}}
             </div>
             <div class="cell">
               {{value}}
             </div>
           </div>
         </div>
      </div>
    </div>
	</CoreCard>
</template>

<script>
import CoreCard from '../CoreCard/CoreCard'
import cardMixin from '../CoreCard/card-mixin';


export default {
  mixins: [cardMixin],

  data: function() {
    return {
      isContainerRendered: false,
    };
  },

  computed: {
    chartName: function() {
      return this.propsData.option;
    },
    data: function() {
      return this.propsData.data;
    },
    customColors: function() {
      return this.propsData.customColors;
    },
  },

  mounted: function () {
    this.$nextTick(function () {
      this.isContainerRendered = true;
    });
  },

  components: {
    CoreCard
  },
}
</script>

<style scoped>
/* .content {
  width: 100%;
  min-width: 300px;
  height: 100%;
  min-height: 350px;
  position: relative;
} */
.d-table {
  display: table;
  width: 100%;
}
.text-bold {
  font-weight: bold;
}
.row {
  display: table-row;
}
.cell {
  display: table-cell;
}
</style>
