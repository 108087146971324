<template>
  <InvestmentAdviceList></InvestmentAdviceList>
</template>

<script>
import InvestmentAdviceList from '../investmentAdvice/InvestmentAdviceList.vue';


export default {
  components: {
    InvestmentAdviceList,
  },
  methods: {
  },
  beforeRouteLeave (to, from, next) {
    this.$addBreadcrumb({
      to,
      from,
    });
    next()
  },
}
</script>

<style scoped>

</style>