import STECKBRIEF_TYPES from './types';

export default {
  [STECKBRIEF_TYPES.GETTERS.TODO_VIDEO_INDENT](state) {
    return state.videoindent;
  },
  [STECKBRIEF_TYPES.GETTERS.TODO_VIDEO_INDENT_COUNT](state) {
    return state.videoIndentCount || 0;
  },
  [STECKBRIEF_TYPES.GETTERS.HAS_VIDEO_INDENT](state) {
    const videoIndentCount = state.videoIndentCount || 0;
    return videoIndentCount > 0;
  },
  [STECKBRIEF_TYPES.GETTERS.STECKBRIEF_CUSTOMER_INFO](state) {
    return state.info;
  },
  [STECKBRIEF_TYPES.GETTERS.STECKBRIEF_BERATUNG_VERMITTLER](state) {
    return state.info?.beratungVermittler?.value;
  },
  [STECKBRIEF_TYPES.GETTERS.GET_INVESTMENT_BESTAND](state) {
    return state.investmentBestand;
  },
  [STECKBRIEF_TYPES.GETTERS.GET_DEPOTPOSITIONS](state) {
    return state.depotpositions;
  },
  [STECKBRIEF_TYPES.GETTERS.GET_MULTIBANKING_DATA](state) {
    return state.multibanking;
  },
  [STECKBRIEF_TYPES.GETTERS.SIMPLY_CHART_GV](state) {
    return state.simplyChartGV;
  },
  [STECKBRIEF_TYPES.GETTERS.FREISTELLUNGSANTRAG_SUBTOTALS](state) {
    return state.freistellungsantragSubtotals;
  },
}