<template>
  <div>
    <PageHeaderTitleNavigation 
      :title="$route.params.lagerstelle" 
      subtitle="Depotübertragung | Übertragungsauftrag bearbeiten" 
    />

    <div v-if="searchingPositions">
      <AntragFormsFondsFinder :addPositionsOnBack="false" @goBack="closeFondsfinder($event)"/>
    </div>
    <div v-else>
        <div class="box__container d-lg-none col-12">
        <Stepper2
          ref="refStepper2"
          stepType="step"
          :visible="true"
          stepperName="uebertragungSmall"
          :selectedStepKey="stepKey"
          :stepperService="stepper"
          @setStep="setStepByKey($event)"
        ></Stepper2>
      </div>

      <ContentWithStepper :stepperCollapsed="stepperCollapsed">
      <template #stepper>
        <div class="antrag-step__container box__container">
          <VerticalStepper
            stepType="tab"
            :visible="true"
            :stepperName="'uebertragung'"
            :selectedStepKey="stepKey"
            :stepperService="stepper"
            :showCollapse="true"
            @setStep="setStepByKey($event)"
            @collapsed="stepperCollapsed = $event"
          ></VerticalStepper>
        </div>
      </template>
      <template #content>
        <div class="box__container">
          <label class="box__title">{{stepLabel}}</label>
        </div>
        <component 
          :is="dynamicTemplate" 
          v-if="dynamicTemplate"
          v-bind="dynamicBindings.props"
          v-on="dynamicBindings.events"
        />
      </template>
      </ContentWithStepper>
      <div class="box__container">
          <BaseButton @click="onBackButton()" :isSecondary="true">Fertigstellen</BaseButton>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ANTRAG_TYPES from '@/store/antrag/types';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import VerticalStepper from '@/components/vertical-stepper/VerticalStepper.vue';
import { StepperMediator } from '@/components/stepper/utils';
import BaseButton from '@/components/core/BaseButton.vue';
import { calculateAntragID } from '@/components/antrag/antrag-utils';
import AntragFormsFondsFinder from '@/components/antrag/fondsfinder/AntragFormsFondsFinder.vue'
import antragMixin from '@/mixins/antrag/antrag-mixin.js';
import ContentWithStepper from '@/components/core/ContentWithStepper.vue';
import Stepper2 from '@/components/stepper/Stepper2.vue';

export default {
  mixins: [antragMixin],
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    VerticalStepper,
    BaseButton,
    AntragFormsFondsFinder,
    ContentWithStepper,
    Stepper2,
  },
  data() {
    return {
      searchingPositions: false,
      antragId: '',
      fondIndex: 0,
      categoryId: '',
      step: '',
      step1Warning: false,
      step2Warning: false,
      step3Warning: false,
      stepperCollapsed: false,
    }
  },
  watch: {
    antraegeData(value) {
      this.componentData;
    }
  },
  mounted() {
    const antragsName = this.$route.params.antragsName
    this.antragId = calculateAntragID({antragsName: antragsName, lagerstelle: this.$route.params.lagerstelle})
    this.fondIndex = this.$route.params.fondIndex
    this.categoryId = this.$route.params.categoryId
    this.step = this.$route.params.step
    this.stepper.progress(this.steps[2].hidden ? 'step2' : 'step3', 3);
  },
  computed: {
    ...mapGetters({
      antraege: ANTRAG_TYPES.GETTERS.ANTRAEGE,
      antraegeData: ANTRAG_TYPES.GETTERS.ANTRAEGE_DATA,
    }),
    component() {
      return this.antrag && this.antrag.components[this.step].find(component => component.id == this.categoryId)
    },
    componentData() {
      return this.antraegeData && this.antraegeData[this.antragId] && this.antraegeData[this.antragId]['INPUT_DATA_POSITIONEN_KSC'] || [];
    },
    positionData() {
      return {
        ...this.componentData[this.fondIndex] || {}
      }
    },
    antrag () {
      return this.antraege && this.antraege[this.antragId]
    },
    comboboxValues() {
      return this.antrag && this.antrag.comboboxSelections;
    },
    bankverbindungSelection() {
      return this.antrag && this.antrag.comboboxSelections && this.antrag.comboboxSelections['INPUT_KONTO'] || [];
    },
    navigateBackToCurrentStep() {
      const navigateTo = `/beratung/formulare/antrag/${this.$route.params.lagerstelle}/${this.$route.params.antragsName}/${this.$route.params.step}`
      if (this.$router.path !== navigateTo) {
        this.$router.push({path: navigateTo, query: { avoidAntragLoading: true, backAction: true, }})
      }
    },
    warnings() {
      return this.warningsPositionUebertragung(this.antrag, this.categoryId);
    },
    stepper() {
      return new StepperMediator(this.steps);
    },
    steps() {
      let hidden = true;
      let step2Label = 'Wertpapiere';
      let step3Label = 'Sonstiges'; 
      switch (this.lagerstelle) {
        case 'COMDIRECT':
          hidden = false;
          break;
        case 'DAB':
          hidden = false;
          step2Label = 'Übertragungsoptionen';
          step3Label = 'einzelne Wertpapiere';
          break;
        case 'DWS':
          hidden = false;
          step3Label = 'Einteilung der Steuerklassen';
          break;
          case 'DWS FONDSPLATTFORM':
          hidden = false;
          step3Label = 'Einteilung der Steuerklassen';
          break;
        default: break;
      }
      return [
        { stepKey: 'depotdaten', label: 'Depotdaten', totalProgress: 1, warning: this.step1Warning, success: !this.step1Warning },
        { stepKey: 'step2', label: step2Label, totalProgress: 1, warning: this.step2Warning, success: !this.step2Warning },
        { stepKey: 'step3', label: step3Label, hidden: hidden, totalProgress: 1, warning: this.step3Warning, success: !this.step3Warning  }
      ];
    },
    stepKey() {
      return this.$route.params.uebertragungStep;
    },
    stepLabel() {
      return this.stepKey && (this.stepper?.getCurrentStepList().find(step => step.stepKey === this.stepKey) || {}).label;
    },
    lagerstelle() {
      const lagerstelle = this.$route.params.lagerstelle;
      return lagerstelle;
    },
    dynamicTemplate() {
      if (this.stepKey && this.lagerstelle) {
        switch (this.lagerstelle) {
          case 'COMDIRECT':
            switch (this.stepKey) {
              case 'depotdaten': 
                return () => import('@/views/antrag/uebertragungsauftrag/ComdirectDepotUebertragungDepotdaten.vue');
              case 'step2':
                return () => import('@/views/antrag/uebertragungsauftrag/ComdirectDepotUebertragungWertpapiere.vue');
              case 'step3':
                return () => import('@/views/antrag/uebertragungsauftrag/ComdirectDepotUebertragungSonstiges.vue');
              default: break;
            }
          case 'DAB':
            switch (this.stepKey) {
              case 'depotdaten': 
                switch (this.antragId) {
                  case 'DAB-uebertragungsauftrag':
                    return () => import('@/views/antrag/uebertragungsauftrag/DABDepotUebertragungDepotdaten.vue');
                  case 'DAB-uebertragungsauftragweg':
                    return () => import('@/views/antrag/uebertragungsauftrag/DABWegDepotUebertragungDepotdaten.vue');
                  default: break;
                }
              case 'step2':
                switch (this.antragId) {
                  case 'DAB-uebertragungsauftrag':
                    return () => import('@/views/antrag/uebertragungsauftrag/DABDepotUebertragungOptionen.vue');
                  case 'DAB-uebertragungsauftragweg':
                    return () => import('@/views/antrag/uebertragungsauftrag/DABWegDepotUebertragungOptionen.vue');
                  default: break;
                }
              case 'step3':
                return () => import('@/views/antrag/uebertragungsauftrag/DABDepotUebertragungWertpapiere.vue');
              default: break;
            }
          case 'DWS':
            switch (this.stepKey) {
              case 'depotdaten': 
                return () => import('@/views/antrag/uebertragungsauftrag/DWSDepotUebertragungDepotdaten.vue');
              case 'step2':
                return () => import('@/views/antrag/uebertragungsauftrag/DWSDepotUebertragungWertpapiere.vue');
              case 'step3':
                return () => import('@/views/antrag/uebertragungsauftrag/DWSDepotUebertragungSonstiges.vue');
              default: break;
            }
          case 'DWS FONDSPLATTFORM':
            switch (this.stepKey) {
              case 'depotdaten': 
                return () => import('@/views/antrag/uebertragungsauftrag/DWSDepotUebertragungDepotdaten.vue');
              case 'step2':
                return () => import('@/views/antrag/uebertragungsauftrag/DWSFondsplattformDepotUebertragungWertpapiere.vue');
                 case 'step3':
                return () => import('@/views/antrag/uebertragungsauftrag/DWSDepotUebertragungSonstiges.vue');
              default: break;
            }
          case 'EBASE': 
          case 'EBASE_AAB':
            switch (this.stepKey) {
              case 'depotdaten': 
                return () => import('@/views/antrag/uebertragungsauftrag/EbaseDepotUebertragungDepotdaten.vue');
              case 'step2':
                return () => import('@/views/antrag/uebertragungsauftrag/EbaseDepotUebertragungWertpapiere.vue');
              default: break;
            }
          case 'FFB':
            switch (this.stepKey) {
              case 'depotdaten': 
                return () => import('@/views/antrag/uebertragungsauftrag/FFBDepotUebertragungDepotdaten.vue');
              case 'step2':
                return () => import('@/views/antrag/uebertragungsauftrag/FFBDepotUebertragungWertpapiere.vue');
              default: break;
            }
          case 'FODB': 
            switch (this.stepKey) {
              case 'depotdaten': 
                return () => import('@/views/antrag/uebertragungsauftrag/FODBDepotUebertragungAusserhalbDepotdaten.vue');
              case 'step2': 
                return () => import('@/views/antrag/uebertragungsauftrag/FODBDepotUebertragungAusserhalbWertpapiere.vue');
              default: break;
            }
          case 'CAPITALBANK': 
            switch (this.stepKey) {
              case 'depotdaten': 
                return () => import('@/views/antrag/uebertragungsauftrag/CapitalbankDepotUebertragungDepotdaten.vue');
              case 'step2': 
                return () => import('@/views/antrag/uebertragungsauftrag/CapitalbankDepotUebertragungWertpapiere.vue');
              default: break;
            }
            case 'HELLOBANK': 
            switch (this.stepKey) {
              case 'depotdaten': 
                return () => import('@/views/antrag/uebertragungsauftrag/HellobankDepotUebertragungDepotdaten.vue');
              case 'step2': 
                return () => import('@/views/antrag/uebertragungsauftrag/HellobankDepotUebertragungWertpapiere.vue');
              default: break;
            }
           default: break; 
        }
      }
    },
     dynamicBindings() {
      if (this.stepKey && this.lagerstelle) {
        switch (this.stepKey) {
          case 'depotdaten': 
            return {
              props: {
                positionData: this.positionData,
                comboboxValues: this.comboboxValues,
                warnings: this.warnings,
              },
              events: {
                next: ($event) => this.setStepByKey('step2'),
                save: ($event) => this.commitUpdateAntragData($event),
                change: () => this.commitUpdateAntragData(),
                hasErrors: ($event) => { this.step1Warning  = $event }//$event ? this.stepper?.markStepAsWarning(this.stepKey) : this.stepper?.markStepAsSuccess(this.stepKey),
              }
            }
          case 'step2':
             return {
              props: {
                positionData: this.positionData,
                anzahlFonds: this.component && this.component.config && this.component.config.anzahlFonds,
                bankverbindungSelection: this.bankverbindungSelection,
                warnings: this.warnings,
              },
              events: {
                back: ($event) => this.setStepByKey('depotdaten'),
                next: ($event) => this.setStepByKey('step3'),
                save: ($event) => this.commitUpdateAntragData($event),
                fondsfinder: ($event) => this.openFondsfinder($event),
                change: () => this.commitUpdateAntragData(),
                hasErrors: ($event) => { this.step2Warning  = $event },
              }
            }
          case 'step3':
            return {
              props: {
                positionData: this.positionData,
                anzahlFonds: this.component && this.component.config && this.component.config.anzahlFonds,
                bankverbindungSelection: this.bankverbindungSelection,
                warnings: this.warnings,
              },
              events: {
                back: ($event) => this.setStepByKey('step2'),
                save: ($event) => this.commitUpdateAntragData($event),
                fondsfinder: ($event) => this.openFondsfinder($event),
                change: () => this.commitUpdateAntragData(),
                hasErrors: ($event) => { this.step3Warning  = $event },
              }
            }
        }
      }
    },
  },
  methods: {
    setStepByKey(stepKey) {
      const path = this.$route.fullPath;
      const step = this.$route.params.uebertragungStep;
      if (step !== stepKey) {
        this.saveAntrag();
        const navigateTo = path.replace(step, stepKey);
        this.$router.push({path: navigateTo, query: { avoidAntragLoading: true }})
      }
    },
    onBackButton() {
      this.saveAntrag();
      this.navigateBackToCurrentStep;
    },
    saveAntrag() {
      this.$store.dispatch(ANTRAG_TYPES.ACTIONS.SAVE_ANTRAG, { id: this.antragId, lagerstelle: this.$route.params.lagerstelle });
    },
    commitUpdateAntragData(data) {
      if (data) {
        this.componentData[this.fondIndex] = Object.assign(this.positionData, data);
        const payload = {
          id: this.antragId,
          data: { [this.categoryId]: this.componentData }
        }
        this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_ANTRAG_DATA, payload);
      }
    },
    openFondsfinder(query) {
      this.saveAntrag();
      this.searchingPositions = true ;
    },
    closeFondsfinder(fonds) {
      if (fonds && fonds.length) {
        let positions = this.componentData[this.fondIndex];
        const anzahlFonds = this.component && this.component.config && this.component.config.anzahlFonds || 4;
        const positionList = this.getPositionsÜbertragung(positions, anzahlFonds);
        // if fonds length + positions length > anzahlFonds, then add only a part of fonds
        // to improve late with dialog or so on
        let rest = anzahlFonds - positionList.length;
        rest = rest < fonds.length ? rest : fonds.length;
        for (let i = 0; i < rest; i++) {
          positionList.push({
            isin: fonds[i].isin,
            name: fonds[i].wertpapiername,
            anteile: '',
            alle: false
          });
        }
        positions = Object.assign(positions, this.setPositionsÜbertragung(positionList, anzahlFonds));
        this.commitUpdateAntragData(positions);
      }
      this.searchingPositions = false;
    },
  }
}
</script>
<style scoped>

</style>