<template>
  <div>
    <PageHeaderTitleNavigation 
      title="Bemerkungen" 
      :actions="headerActions"
      @action-ADD="createBemerkung()"
    />

    <div class="box__container" v-if="isBemerkungSichtbar || !isCustomerOnly">
      <div class="col box__title">Allgemeine Bemerkung</div>
      <CustomerBemerkung />
    </div>

    <BemerkungenList 
      :records="bemerkungen" 
      :loading="loading" 
      :typ="VALUE_TYP"
      @delete="deleteRow"
      @deleteSelected="deleteSelected"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CUSTOMERDATA_TYPES from "@/store/customerData/types";
import CORE_TYPES from "@/store/core/types";
import BemerkungenList from "@/components/persoenliche-daten/BemerkungenList.vue";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import axios from 'axios';
import { makeQueryParam } from '@/helpers/utils-helper';
import CustomerBemerkung from '@/components/steckbrief/cards/CustomerBemerkung.vue';

const VALUE_TYP = 'kunde';

export default {
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    BemerkungenList,
    CustomerBemerkung,
  },
  data() {
    return {
      VALUE_TYP,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      bemerkungen: CUSTOMERDATA_TYPES.GETTERS.BEMERKUNGEN,
      isCustomerOnly: CORE_TYPES.GETTERS.IS_CUSTOMER_ONLY,
      isBemerkungSichtbar: CUSTOMERDATA_TYPES.GETTERS.BEMERKUNG_KUNDE_SICHTBAR,
      Bemerkung: CUSTOMERDATA_TYPES.GETTERS.BEMERKUNG_KUNDE,
    }),
    headerActions() {
      return [
        PageHeaderSimpleAction('ADD', 'Bemerkung hinzufügen').withVisible(() => !this.isCustomerOnly),
      ];
    },
  },
  methods: {
    async findBemerkungen() {
      try {
        this.loading = true;
        if (this.Bemerkung == null) {
          this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_BEMERKUNG_KUNDE);
        }
        if (this.isBemerkungSichtbar == null) {
          this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_BEMERKUNG_KUNDE_SICHTBAR);
        }
        await this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.FIND_BEMERKUNGEN, this.isCustomerOnly);
      } finally {
        this.loading = false;
      }
    },
    createBemerkung() {
      this.$router.push({path: `${this.$route.path}/bemerkung`, query: {typ: VALUE_TYP}});
    },
    deleteRow({id}) {
      const params = makeQueryParam({id, typ: VALUE_TYP})

      axios.delete(`${process.env.VUE_APP_API}/postfachBemerkung/bemerkung?${params}`, {defaultSpinner: true})
      .then(() => this.findBemerkungen())
    },
    deleteSelected({ids, next}) {
      const params = makeQueryParam({ids, typ: VALUE_TYP})

      axios.delete(`${process.env.VUE_APP_API}/postfachBemerkung/bemerkungen?${params}`, {defaultSpinner: true})
      .then(() => this.findBemerkungen().then(() => next()))
    },
  },
  mounted() {
    this.findBemerkungen();
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({ to, from, });
    next();
  },
};
</script>
