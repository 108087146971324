var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box__container" }, [
    _vm.isEmpty
      ? _c("div", [_vm._v("Keine zusatzliche Daten erforderlich")])
      : _c(
          "div",
          [
            _c("div", { staticClass: "box__title" }, [
              _vm._v(
                " Die hier getroffenen Einstellung gelten nur für Edelmetall-Positionen der ebase/ebase Augsburg "
              )
            ]),
            _c("div", { staticClass: "box__title" }, [_vm._v("Kauf")]),
            _c("InputField", {
              attrs: {
                label: "Herkunft der Anlagesumme",
                type: "text",
                disabled: _vm.readOnly
              },
              on: {
                change: function($event) {
                  return _vm.updateStoreData({
                    componentId: "INPUT_EBASE_EDELMETALL_KAUF_MITTELHERKUNFT",
                    value: $event
                  })
                }
              },
              model: {
                value: _vm.form.INPUT_EBASE_EDELMETALL_KAUF_MITTELHERKUNFT,
                callback: function($$v) {
                  _vm.$set(
                    _vm.form,
                    "INPUT_EBASE_EDELMETALL_KAUF_MITTELHERKUNFT",
                    $$v
                  )
                },
                expression: "form.INPUT_EBASE_EDELMETALL_KAUF_MITTELHERKUNFT"
              }
            }),
            _c("InputToggleSwitch", {
              attrs: {
                label: "Dieser Auftrag erfolgt im Rahmen einer Anlageberatung.",
                disabled: _vm.readOnly
              },
              on: {
                input: function($event) {
                  return _vm.updateStoreData({
                    componentId: "INPUT_EBASE_EDELMETALL_KAUF_BERATUNG",
                    value: $event
                  })
                }
              },
              model: {
                value: _vm.form.INPUT_EBASE_EDELMETALL_KAUF_BERATUNG,
                callback: function($$v) {
                  _vm.$set(
                    _vm.form,
                    "INPUT_EBASE_EDELMETALL_KAUF_BERATUNG",
                    $$v
                  )
                },
                expression: "form.INPUT_EBASE_EDELMETALL_KAUF_BERATUNG"
              }
            }),
            _c("div", { staticClass: "box__title" }, [_vm._v("Verkauf")]),
            _c(
              "div",
              { staticClass: "col-12 mt-3" },
              [
                _c("FormLabel", {
                  attrs: {
                    label:
                      "Sofern eine Depot- und Kontokündigung erfolgt bitte das Guthaben vom Konto flex auf folgende abweichende externe Bankverbindung überweisen."
                  }
                })
              ],
              1
            ),
            _c("ComboBox", {
              attrs: {
                label: "externe Bankverbindung",
                disabled: _vm.readOnly,
                values: _vm.comboboxValues
                  ? this.comboboxValues.INPUT_EBASE_EDELMETALL_AUSZAHLKONTO
                  : []
              },
              on: {
                change: function($event) {
                  return _vm.updateStoreData({
                    componentId: "INPUT_EBASE_EDELMETALL_AUSZAHLKONTO",
                    value: $event
                  })
                }
              },
              model: {
                value: _vm.form.INPUT_EBASE_EDELMETALL_AUSZAHLKONTO,
                callback: function($$v) {
                  _vm.$set(_vm.form, "INPUT_EBASE_EDELMETALL_AUSZAHLKONTO", $$v)
                },
                expression: "form.INPUT_EBASE_EDELMETALL_AUSZAHLKONTO"
              }
            }),
            _c("InputField", {
              attrs: {
                label: "Verwendungszweck",
                disabled: _vm.readOnly,
                type: "text"
              },
              on: {
                change: function($event) {
                  return _vm.updateStoreData({
                    componentId:
                      "INPUT_EBASE_EDELMETALL_AUSZAHLONTO_VERWENDUNGSZWECK",
                    value: $event
                  })
                }
              },
              model: {
                value:
                  _vm.form.INPUT_EBASE_EDELMETALL_AUSZAHLONTO_VERWENDUNGSZWECK,
                callback: function($$v) {
                  _vm.$set(
                    _vm.form,
                    "INPUT_EBASE_EDELMETALL_AUSZAHLONTO_VERWENDUNGSZWECK",
                    $$v
                  )
                },
                expression:
                  "form.INPUT_EBASE_EDELMETALL_AUSZAHLONTO_VERWENDUNGSZWECK"
              }
            }),
            _c("div", { staticClass: "box__title" }, [
              _vm._v("physiche Auslieferung")
            ]),
            _c(
              "div",
              { staticClass: "col-12 mt-3" },
              [
                _c("FormLabel", {
                  attrs: {
                    label:
                      "Die Auslieferung von Stücken soll an folgende, bei ebase hinterlegte Adresse erfolgen:"
                  }
                })
              ],
              1
            ),
            _c("InputRadioBoxGroup", {
              attrs: { disabled: _vm.readOnly, values: _vm.adressTypValues },
              on: {
                input: function($event) {
                  return _vm.updateStoreData({
                    componentId: "INPUT_EBASE_EDELMETALL_PHYSAUSL_ADRESSTYP",
                    value: $event
                  })
                }
              },
              model: {
                value: _vm.form.INPUT_EBASE_EDELMETALL_PHYSAUSL_ADRESSTYP,
                callback: function($$v) {
                  _vm.$set(
                    _vm.form,
                    "INPUT_EBASE_EDELMETALL_PHYSAUSL_ADRESSTYP",
                    $$v
                  )
                },
                expression: "form.INPUT_EBASE_EDELMETALL_PHYSAUSL_ADRESSTYP"
              }
            }),
            _c(
              "div",
              { staticClass: "col-12 mt-3" },
              [
                _c("FormLabel", {
                  attrs: { label: "Kontaktdaten für die Kontaktierung" }
                }),
                _c("InputField", {
                  attrs: {
                    label: "E-Mail-Adresse",
                    disabled: _vm.readOnly,
                    type: "text"
                  },
                  on: {
                    change: function($event) {
                      return _vm.updateStoreData({
                        componentId:
                          "INPUT_EBASE_EDELMETALL_PHYAUSL_KONTAKTEMAIL",
                        value: $event
                      })
                    }
                  },
                  model: {
                    value: _vm.form.INPUT_EBASE_EDELMETALL_PHYAUSL_KONTAKTEMAIL,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.form,
                        "INPUT_EBASE_EDELMETALL_PHYAUSL_KONTAKTEMAIL",
                        $$v
                      )
                    },
                    expression:
                      "form.INPUT_EBASE_EDELMETALL_PHYAUSL_KONTAKTEMAIL"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    label: "Telefon",
                    disabled: _vm.readOnly,
                    type: "text",
                    inputmode: "tel"
                  },
                  on: {
                    change: function($event) {
                      return _vm.updateStoreData({
                        componentId:
                          "INPUT_EBASE_EDELMETALL_PHYAUSL_KONTAKTTEL",
                        value: $event
                      })
                    }
                  },
                  model: {
                    value: _vm.form.INPUT_EBASE_EDELMETALL_PHYAUSL_KONTAKTTEL,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.form,
                        "INPUT_EBASE_EDELMETALL_PHYAUSL_KONTAKTTEL",
                        $$v
                      )
                    },
                    expression: "form.INPUT_EBASE_EDELMETALL_PHYAUSL_KONTAKTTEL"
                  }
                })
              ],
              1
            )
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }