import actions from './actions';
import mutations from './mutations';
import getters from './getters';


export function getInitialState() {
  return {
    senders: [],
    placeholders: {},
    unsetPlaceholders: [],
    templates: [],
    template: null,
    text: null,
    data: {},
    preview: '',
    sendMail: '',
    sendTestMail: '',
    appzusenden: null,
    participants: [],
    attachments: [],
    attachmentsCache: [],
    pdf: null,
    fax: null,
    brief: null,
    wordTemplates: null,
    wordTemplate: null,
    testSenders: null,
    init: null,
    linkedObject: null,
    insuranceCancel: null,
    constants: null,
    modus: null,
    mailAntrag: null,
    mailVersDoku: null,
    mailAnsprechpartner: null,
    mailAppointment: null,
    mailSchadensmeldung: null,
    mailAnlageempfehlung: null,
    mailBeratungsmappen: null,
    mailVersUebertragBlockGesell: null,
    errorMessage: [],
    mailAuftragsdokumente: {},
    mailAktuellerQuartalsberichte: null,
    mailHonorareAbrechnungenVerschicken: null,
    ANTRAG_CHECK_MAIL:null,
    mailVertragsspiegel: null,
    mailFondshopAction: null,
  }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}