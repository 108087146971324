var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("div", { staticClass: "box__title" }, [_vm._v("Zielmarkt")]),
        _c("div", { staticClass: "font-bold" }, [_vm._v("SRI")]),
        _c("InputSlider", {
          staticClass: "slider-input__container",
          attrs: {
            isComponentHalfSize: "",
            values: _vm.valuesSri,
            disabled: _vm.form.disabled,
            validateUntouched: ""
          },
          on: {
            input: function($event) {
              return _vm.updateStore({ sriSetting: $event }, "settings")
            }
          },
          model: {
            value: _vm.form.sriSetting,
            callback: function($$v) {
              _vm.$set(_vm.form, "sriSetting", $$v)
            },
            expression: "form.sriSetting"
          }
        }),
        _c("div", { staticClass: "font-bold" }, [
          _vm._v("Benötigte Kenntnisse und Erfahrungen")
        ]),
        _c("ComboBox", {
          attrs: {
            isComponentHalfSize: "",
            values: _vm.valuesKeuer,
            validateUntouched: "",
            sortComboboxValues: false,
            disabled: _vm.form.disabled
          },
          on: {
            change: function($event) {
              return _vm.updateStore({ keuerSetting: $event }, "settings")
            }
          },
          model: {
            value: _vm.form.keuerSetting,
            callback: function($$v) {
              _vm.$set(_vm.form, "keuerSetting", $$v)
            },
            expression: "form.keuerSetting"
          }
        }),
        _c("div", { staticClass: "font-bold" }, [_vm._v("Zeithorizont")]),
        _c("ComboBox", {
          attrs: {
            isComponentHalfSize: "",
            values: _vm.valuesZeithorizont,
            validateUntouched: "",
            sortComboboxValues: false,
            disabled: _vm.form.disabled
          },
          on: {
            change: function($event) {
              return _vm.updateStore(
                { zeithorizontSetting: $event },
                "settings"
              )
            }
          },
          model: {
            value: _vm.form.zeithorizontSetting,
            callback: function($$v) {
              _vm.$set(_vm.form, "zeithorizontSetting", $$v)
            },
            expression: "form.zeithorizontSetting"
          }
        }),
        _c("div", { staticClass: "font-bold" }, [_vm._v("Anlageziele")]),
        _c("InputCheckBoxGroup", {
          attrs: {
            values: _vm.valuesAnlageziele,
            value: _vm.form,
            disabled: _vm.form.disabled,
            validateUntouched: ""
          },
          on: {
            checkBoxToggle: function($event) {
              return _vm.updateStore($event, "settings")
            }
          }
        }),
        _c("div", { staticClass: "font-bold" }, [
          _vm._v("Verlustbereitschaft")
        ]),
        _c("ComboBox", {
          attrs: {
            isComponentHalfSize: "",
            values: _vm.valuesVerlust,
            validateUntouched: "",
            sortComboboxValues: false,
            disabled: _vm.form.disabled
          },
          on: {
            change: function($event) {
              return _vm.updateStore({ verlustSetting: $event }, "settings")
            }
          },
          model: {
            value: _vm.form.verlustSetting,
            callback: function($$v) {
              _vm.$set(_vm.form, "verlustSetting", $$v)
            },
            expression: "form.verlustSetting"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }