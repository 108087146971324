<template>
  <div>
    <PageHeaderTitleNavigation title="Gespräche" />

    <BaseFilter
      v-if="defaultOptions"
      title="Gespräche Filter"
      filterId="PostfachCallsFilter"
      :configFilter="filterConfig"
      :metadata="metadata"
      :defaultOptions="defaultOptions"
      showSaveButton
      hasSmartSearch
      immidiateSearch
      @onFilter="onFilter"
    />

    <div class="box__container">
      <Table 
        v-if="rows.length"
        v-model="selectedRows"
        :headers="headers"
        :rows="rows"
        :rowsPerPage="25"
        rowId="rowid"
        :headerActions="tableHeaderActions"
        :exportConfig="{roottext: 'Postfach'}"
        :mobileConfig="mobileConfig"
        @click-subject="clickBetreff"
        @click-name="openCustomerNewTab($event.nummer)"
        @action-DELETE="deleteRow"
        @headerAction-BULK_DELETE="deleteSelected"
      />
      <GhostLoading v-else-if="loading" type="table" :config="{ table: { rows: 10 } }"/>
      <NoData v-else/>
    </div>
  </div>
</template>

<script>
import Table from "@/components/table2/Table.vue";
import {TextColumn, DateTimeColumn, ConfirmedAction, ActionColumn} from "@/components/table2/table_util.js";
import { PageHeaderConfirmedAction } from '@/components/core/header-title-navigation/page-header-utils';

import postfachTableMixin from '@/views/communication/postfach/tables/postfach-table-mixin.js';

export default {
  mixins: [postfachTableMixin],
  components: {
    Table,
  },
  data() {
    return {
      category: 'talk',
      mailboxLabel: 'Gespräch',
      filterConfig: {
        placeholderForDefSearchInput: 'Betreff',
        defaultSearchInputKeys: ['subject'],
        hideFirstColumn: true,
      },
      mobileConfig: {
        title: 'name',
        headers: ['subject', 'dateTime', 'contact']
      },
    }
  },
  computed: {
    headers() {            
      return {
        lockedLeft: [
          DateTimeColumn("dateTime", "Datum", undefined, 1),
          TextColumn("subject", "Betreff").makeLink(),
        ],
        center: [
            TextColumn("name", "Name").makeConditionalLink('hasNummer').makeAlwaysVisible(),
            TextColumn("contact", "Kontakt"),
        ],
        lockedRight: [
            ActionColumn("actions", "Aktionen"),
        ]
      };
    },
    rows() {
      if (!this.records)
        return [];
      return this.records.map(record => {
        return {
            ...record,
            rowid: (record.commId + this.category + record.date),
            subject: record.subject || "[kein Betreff]",
            dateTime: record.date,
            hasNummer: this.canOpenCustomer(record),
            actions: [
              ConfirmedAction("DELETE", 'PhTrash', "Löschen", `Soll das Gespräch "${record.subject || "[kein Betreff]"}" wirklich gelöscht werden?`, 'Gespräch löschen', "Löschen")
            ]
        }
      })
    },
    metadata() {
      return [
        {
          type: 'group',
          key: 'allgemein',
          label: 'Allgemein',
          menuItems: [
            {
              type: 'text',
              label: 'Betreff',
              key: 'subject',
              emptyDenied: true,
            },
            {
              type: 'text',
              label: 'Name',
              key: 'name',
              emptyDenied: true,
            },
            {
              type: 'dateRange',
              label: 'Zeitraum',
              key: 'zeitraum',
              emptyDenied: true,
            },
            {
              type: 'datepicker',
              label: 'Datum',
              key: 'datum',
              emptyDenied: true,
            },
          ],
        },
      ]
    },
    tableHeaderActions() {
      return [
        PageHeaderConfirmedAction('BULK_DELETE', 'Markierte löschen', 'Sollen die markierten Einträge wirklich gelöscht werden?', 'Markierte löschen', 'Löschen')
        .withDisabled(() => this.selectedRows.length < 1)
      ];
    },
  },
  methods: {
    clickBetreff(row) {
      if (row.commId) {
        this.$router.push(`/communication/postfach/${this.routeStep}/${this.category}/${row.commId}`);
      }
    },
  },
}
</script>