var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _vm.loading
        ? _c("GhostLoading", {
            attrs: { type: "table", title: _vm.TABLE_TITLE }
          })
        : _vm.error
        ? _c("UnexpectedError")
        : !_vm.rowCount
        ? _c("NoData", { attrs: { title: _vm.TABLE_TITLE } })
        : _c(
            "div",
            [
              _c("Table", {
                staticClass: "mt-2",
                attrs: {
                  tableId: "96302160-4c04-4041-939c-70de177e8596",
                  title: _vm.TABLE_TITLE,
                  headers: _vm.headers,
                  rows: _vm.rows,
                  selected: _vm.selectedPeople
                },
                on: {
                  selected: function($event) {
                    _vm.selectedPeople = $event
                  }
                }
              }),
              _c("ComboBox", {
                attrs: {
                  label: "Zielperson",
                  isComponentHalfSize: true,
                  values: _vm.comboBoxZielperson
                },
                model: {
                  value: _vm.zielPerson,
                  callback: function($$v) {
                    _vm.zielPerson = $$v
                  },
                  expression: "zielPerson"
                }
              }),
              _c(
                "div",
                [
                  _c(
                    "BaseButton",
                    {
                      attrs: { disabled: _vm.disableMergeButton },
                      on: { click: _vm.confirmPeopleMerge }
                    },
                    [_vm._v(" Markierte Personen zusammenführen ")]
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }