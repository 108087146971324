var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "modal",
    attrs: { modalTitle: "Textbausteine bearbeiten" },
    on: {
      close: function($event) {
        return _vm.onClose()
      },
      onConfirmButton: function($event) {
        return _vm.save()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function() {
          return [
            _c("InputField", {
              attrs: {
                label: "Textbaustein",
                value: _vm.selectedBereich.beschreibungKurz,
                disabled: ""
              }
            }),
            _c("InputField", {
              attrs: { label: "Betreff", autofocus: "" },
              model: {
                value: _vm.form.betreff,
                callback: function($$v) {
                  _vm.$set(_vm.form, "betreff", $$v)
                },
                expression: "form.betreff"
              }
            }),
            _c("InputToggleSwitch", {
              attrs: {
                label: "Ihrer Unterstruktur zur Verfügung stellen",
                inLineLabel: ""
              },
              model: {
                value: _vm.form.sichtbarStruktur,
                callback: function($$v) {
                  _vm.$set(_vm.form, "sichtbarStruktur", $$v)
                },
                expression: "form.sichtbarStruktur"
              }
            }),
            _c("InputTextArea", {
              attrs: { label: "Text", rows: 6 },
              model: {
                value: _vm.form.text,
                callback: function($$v) {
                  _vm.$set(_vm.form, "text", $$v)
                },
                expression: "form.text"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }