var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "event-body" },
    [
      _c(
        "BaseModal",
        {
          ref: "modal",
          attrs: { autoClose: true, showConfirmButton: false, size: "md" },
          on: { onCancelButton: _vm.close },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [_vm._v("Prüfcenter Beratungsmappe")]
              },
              proxy: true
            }
          ])
        },
        [
          _c("BoxContainer", [
            _c(
              "div",
              [
                _vm.comboData && _vm.comboData.length > 0
                  ? _c("ComboBox", {
                      attrs: { values: _vm.comboData },
                      on: {
                        change: function($event) {
                          return _vm.onChangeMape($event)
                        }
                      },
                      model: {
                        value: _vm.val,
                        callback: function($$v) {
                          _vm.val = $$v
                        },
                        expression: "val"
                      }
                    })
                  : _vm._e(),
                _vm.data && _vm.data.noProfil
                  ? _c("span", { staticClass: "color-danger" }, [
                      _vm._v(_vm._s(_vm.data.noProfil))
                    ])
                  : _vm._e(),
                _vm.data && _vm.data.noProtokol
                  ? _c("span", { staticClass: "color-danger" }, [
                      _vm._v(_vm._s(_vm.data.noProtokol))
                    ])
                  : _vm._e(),
                _vm.data && _vm.data.list && _vm.data.list.length
                  ? _c("Table", {
                      attrs: {
                        headers: _vm.headers,
                        rows: _vm.rows,
                        paginationEnabled: false,
                        hidePagination: true
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }