<template>
  <div>
    <div v-if="hasDepotChartsData" class="row">
      <div v-for="(chart, key) in depotCharts" class="col-12 col-md-6 d-flex" :key="'depot' + key">
        <DepotpositionsChart v-if="key !== 'depotid'" :chart="chart" :tid="_generateTidFromString(`chart-container-${key}`)" />
      </div>
    </div>
    <div v-else-if="loading" class="row">
      <div v-for="n in 10" :key="n" class="col-12 col-md-6 mb-4">
        <GhostLoading useBoxContainer>
          <Block type="title" />
          <Block :height="350" />
        </GhostLoading>
      </div>
    </div>
    <NoData v-else class="box__container" />
  </div>
</template>

<script>
import {mapGetters } from "vuex";
import DEPOTPOSITIONS_TYPES from "@/store/depotpositions/types";

import DepotpositionsChart from './DepotpositionsChart.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import NoData from '@/components/core/NoData.vue';
import CORE_TYPES from '@/store/core/types'
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";


export default {
  components: {
    DepotpositionsChart,
    GhostLoading,
    Block,
    NoData,
  },
  mixins: [InteractiveHelpCommonsMixin],
  data() {
    return {
      loading: false,
    };
  },
  watch: {
    depotid: function () {
      this.retrieveCharts();
      this.$store.commit(DEPOTPOSITIONS_TYPES.MUTATIONS.CHANGE_ID, this.depotid);
    },
    config: 'retrieveCharts',
  },
  computed: {
    ...mapGetters({
      charts: DEPOTPOSITIONS_TYPES.GETTERS.DEPOTPOSITIONS_CHARTS,
      config: DEPOTPOSITIONS_TYPES.GETTERS.DEPOTPOSITIONS_CONFIG,
      depotid: DEPOTPOSITIONS_TYPES.GETTERS.DEPOT_ID,
      paiChart: DEPOTPOSITIONS_TYPES.GETTERS.DEPOTPOSITIONS_ESG_CHART,
      isTest: CORE_TYPES.GETTERS.IS_TEST,
    }),
    depotCharts() {
      const PaiChart = this.paiChart && this.paiChart[this.depotid] || {}
      const SFDRChart = {barChart: false, properties: [], type: 'SFDRChart'}
      const TaxonomieChart = {barChart: false, properties: [], type: 'TaxonomieChart'}
      if (PaiChart && Object.keys(PaiChart).length) {
        const properties = [...PaiChart.properties];
        PaiChart.properties = properties.filter(v => v.key !== 'SFDR' && v.key !== 'Taxonomie')
        SFDRChart.properties = properties.filter(v => v.key == 'SFDR')
        TaxonomieChart.properties = properties.filter(v => v.key == 'Taxonomie')
        if (SFDRChart.properties && SFDRChart.properties.length && SFDRChart.properties[0].value) {
          SFDRChart.properties = [SFDRChart.properties[0], {key: '', value: 1 - SFDRChart.properties[0].value}]
        }
        if (TaxonomieChart.properties && TaxonomieChart.properties.length && TaxonomieChart.properties[0].value) {
          TaxonomieChart.properties = [TaxonomieChart.properties[0], {key: '', value: 1 - TaxonomieChart.properties[0].value}]
        }
      }
      const charts = this.charts && this.charts[this.depotid] && this.charts[this.depotid].charts || {};
      if (!Object.keys(charts).length) {
        return {}
      }
      PaiChart.properties = PaiChart.properties.map(v => ({...v, value: v.value * 100}))
      TaxonomieChart.properties = TaxonomieChart.properties.map(v => ({...v, value: v.value * 100}))
      SFDRChart.properties = SFDRChart.properties.map(v => ({...v, value: v.value * 100}))
      return {
        ...charts,
        TaxonomieChart,
        SFDRChart,
        PaiChart
      }
    },
    hasDepotChartsData() {
      return !!Object.keys(this.depotCharts).length;
    },
  },
  mounted() {
    this.retriveConfig();
    this.retrieveCharts();
  },
  methods: {
    retriveConfig() {
      if (!this.config || !Object.keys(this.config).length) {
        this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.RETRIEVE_DEPOTPOSITIONS_CONFIG, {});
      }
    },
    async retrieveCharts() {
      this.loading = true;
      if (this.depotid && this.charts && !this.charts[this.depotid] && this.config && this.config.charts) {
        await this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.RETRIEVE_DEPOTPOSITIONS_CHARTS, {
          depotid: this.depotid,
          charts: this.config.charts
        })
      }
      if (!this.depotid || !this.paiChart || this.depotid && this.paiChart && !this.paiChart[this.depotid]) {
        await this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.RETRIEVE_DEPOTPOSITIONS_ESG_CHART, {depotid: this.depotid})
      }
      this.loading = false;
    },
  },
};
</script>
