<template>
  <div>
    <GesellschaftTitleNavigation/>

    <div class="box__container" :tid="_generateTidFromString('box__container')">
      <div class="box__title">Gesellschaftsdaten</div>
      <div v-if="gesellschaftInfoField">
        <div>{{ gesellschaftInfoField.name }}</div>
        <div><template v-if="gesellschaftInfoField.strasse">{{ gesellschaftInfoField.strasse }}, </template>{{ gesellschaftInfoField.plz }} {{ gesellschaftInfoField.ort }}</div>
        <div>{{ gesellschaftInfoField.land }}</div>
        <div>{{ gesellschaftInfoField.plzPostfach }} {{ gesellschaftInfoField.postfach }}</div>
        <div class="mt-3">{{ gesellschaftInfoField.beschreibung }}</div>
      </div>
      <GhostLoading v-else-if="loading" type="table" />
    </div>
    <div class="box__container" v-if="vermittlerRecords && vermittlerRecords.length" :tid="_generateTidFromString('cards__container layout__negative-margin--8')">
      <Table 
        title="Vermittler"
        rowId="index"
        hidePagination
        :headers="headers"
        :rows="rows"
        :rowsPerPage="20"
      />
      <div v-if="isUnternrnMoeglich">Unternummern möglich!</div>
    </div>

    <div v-if="biproZugang" class="box__container" :tid="_generateTidFromString('box__container cards__biproZugang')">
      <div class="box__title">BiPRO Zugänge</div>
        <Table
        :headers="headersBiproZugaeng"
        hidePagination
        :rowsPerPage="20"
        :rows="rowsBiproZugaeng"
      />
    </div>        

    <div v-if="gesellschaftDaten.hinweise && gesellschaftDaten.hinweise.length" class="box__container" :tid="_generateTidFromString('box__container cards__hinweise')">
      <div class="box__title">Wichtige Hinweise</div>
      <p v-for="(hinweis, index) in gesellschaftDaten.hinweise" :key="index">{{hinweis}}</p>    
    </div>

    <div v-if="gesellschaftLinks && gesellschaftLinks.length" class="box__container" :tid="_generateTidFromString('box__container cards__itemasdfa')">
      <div class="box__title">Wichtige Links</div>

      <div>
        <a @click="clickGesellschaftLink()">PDF Briefkopf</a>
      </div>
      
      <div v-for="(linkItem, index) in gesellschaftLinks"  :key="index">
        <a target="_blank" :href="linkItem.url">{{linkItem.label}}</a>
      </div>
    </div>

    <div class="box__container" v-if="gesellschaftInfoField">
      <div class="box__title">Notizen</div>
      <InputTextArea
        autoGrow
        v-model="maklerBemerkung"
      />
    </div>
  </div>
</template>

<script>
import GesellschaftTitleNavigation from '@/views/gesellschaft/intern/GesellschaftTitleNavigation.vue'

import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import Table from "@/components/table2/Table.vue";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';

import axios from 'axios';
import MAILCOMPOSER_TYPES from "@/store/mailcomposer/types";
import {TextColumn, PillColumn} from "@/components/table2/table_util.js";
import { makeQueryParam } from '@/helpers/utils-helper';

const config = {
  defaultSpinner: true
};

export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    Table,
    GhostLoading,
    GesellschaftTitleNavigation,
    InputTextArea,
  },
  data() {
    return {
      gesellschaftDaten: {},
      biproZugang: null,
      loading: false,
      originalMaklerBemerkung: null,
      maklerBemerkung: null,
    }
  },
  computed: {
    headersBiproZugaeng() {
      // const { isBiPRO, biproZugangsfehlerColumnVisible, } = this;

      const headers = {
        lockedLeft: [
          TextColumn('title', 'Name').addCellProps({ lineClamp: 2, }),
          PillColumn('validPill', 'Status'),
        ],
        center: [
          TextColumn('login', 'Login'),
          TextColumn('zertifikatValidUntil', 'Zertifikat gültig bis'),
        ],
        lockedRight: [
        ],
      };

      headers.center.push(TextColumn('autoUpdateGdvDataText', 'GDV Daten automatisch einlesen'));
      headers.center.push(TextColumn('id', 'Id'));
      headers.center.push(TextColumn('useGdvMultiinterfaceText', 'Einlesen ohne Fremdanlage'));
       return headers;
    },
    rowsBiproZugaeng() {
      return [ ...this.biproZugang || [], ].map(item => ({
        ...item,
        rowId: `${item.bezeichnung}-${item.id}`,
        validPill: item?.valid ? { label: 'eingerichtet', type: 'success', } : { label: 'nicht eingerichtet', type: 'warning', },
        loginText: item?.vpnr || item?.login,
        zertifikatValidUntil: item?.validUntil ? new Date(item.validUntil).toLocaleDateString('de') : '',
        neustartText: item?.neustart ? 'Ja' : 'Nein',
        autoUpdateGdvDataText: item?.autoUpdateGdvData ? 'Ja' : 'Nein',
        zuordnungsfehlerFreigebenText: item?.zuordnungsfehlerFreigeben ? 'Ja' : 'Nein',
        useGdvMultiinterfaceText: item?.useGdvMultiinterface ? 'Ja' : 'Nein',
      }));
    },    
    headers() {
      let headers = {
          lockedLeft: [
            TextColumn("vermittlerTyp", "VermittlerTyp"),
          ],
          center: [
            TextColumn("vermittlerNr", "Vermittlernummer"),
            TextColumn("email", "E-Mail"),
            TextColumn("hauptNr", "Hauptnummer"),
            TextColumn("zweigstelle", "Zweigstelle"),
          ],
          lockedRight: [],
      }
      return headers
    },
    rows() {
      return this.vermittlerRecords.map((record, index) => ({
        ...record,
        index,
      }))
    },
    gesellschaftInfoField() {
      return this.gesellschaftDaten.hauptDaten
    },
    gesellschaftLinks() {
      return this.gesellschaftDaten.links || []
    },
    vermittlerRecords() {
      return this.gesellschaftDaten.vermittlerRecords || []
    },
    isUnternrnMoeglich() {
      return this.gesellschaftDaten.unternrnMoeglich
    },
  },
  watch: {
    '$route.params.gesellId': function (id) {
      this.getZentrale()
    },
  },
  mounted() {
    this.getZentrale()
  },
  methods: {
    clickGesellschaftLink(){
      if (this.gesellschaftInfoField?.id) {
        this.$addBreadcrumb({
          label: 'zurück zur ' + this.$route.params.gesellId, 
          fullPath: this.$route.fullPath,
          breadcrumb: 'Serienbrief',
        });

        this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_PARTICIPANTS, [{
          personId: this.gesellschaftInfoField.id,
          label: '',
          type: 'PERSON'
        }]);
        this.$router.push({ path: `/communication/mailcomposer-serienbrief`, query: {singleUser: true} })
      }
    },    
    getZentrale() {
      this.loading = true

      const params = makeQueryParam({ gesellschaftId: this.$route.params.gesellId, gesellArt: this.$route.query.gesellArt }, true)

      let serviceUrl = `/gesellschaftenService/zentrale?${params}`;

      axios.get(`${process.env.VUE_APP_API}` + serviceUrl, config).then(response => {
        if (response && response.data) {
          this.biproZugang = response?.data?.biproZugang
          this.maklerBemerkung = this.originalMaklerBemerkung = response.data.maklerBemerkung
          this.gesellschaftDaten = response.data
        } 
      }).finally(() => this.loading = false)
    },
    save() {
      if (this.maklerBemerkung === this.originalMaklerBemerkung) {
        return
      }

      const params = makeQueryParam({ gesellschaftId: this.$route.params.gesellId, bemerkung: this.maklerBemerkung }, true)

      axios.post(`${process.env.VUE_APP_API}/gesellschaftenService/maklerBemerkung?${params}`, config)
    },
  },
  beforeRouteLeave(to, from, next) {
    this.save()

    next();
  },
}
</script>
