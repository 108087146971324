import VERMITTLERWECHSEL_STATUS_TYPES from './types';
import CORE_TYPES from '../core/types';
import axios from 'axios';
import { buildMessage, } from '../../helpers/log-message-helper';

export default {
  [VERMITTLERWECHSEL_STATUS_TYPES.ACTIONS.RETRIEVE_VERMITTLERWECHSEL_STATUS]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/vermittlerwechselstatus/getListing';

      axios.post(rootState.core.apiAddress + serviceUrl, payload, config).then(response => {
        if (response && response.data) {
  
          commit(VERMITTLERWECHSEL_STATUS_TYPES.MUTATIONS.RETRIEVE_VERMITTLERWECHSEL_STATUS_SUCCESS, response.data);
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });



  },
  [VERMITTLERWECHSEL_STATUS_TYPES.ACTIONS.REMOVE_ROW]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };   

    return new Promise((resolve, reject) => {
      let serviceUrl = '/vermittlerwechselstatus/deleteEntry';

      axios.delete(rootState.core.apiAddress + serviceUrl, payload, config).then(response => {
        if (response && response.data) {
  
          commit(VERMITTLERWECHSEL_STATUS_TYPES.MUTATIONS.REMOVE_ROW_SUCCESS, response.data);
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });    

  },
  [VERMITTLERWECHSEL_STATUS_TYPES.ACTIONS.UPDATE_STATUS]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/vermittlerwechselstatus/updateStatus';

      axios.post(rootState.core.apiAddress + serviceUrl, payload, config).then(response => {
        if (response && response.data) {
  
          commit(VERMITTLERWECHSEL_STATUS_TYPES.MUTATIONS.UPDATE_STATUS_SUCCESS, response.data);
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });



  },
  [VERMITTLERWECHSEL_STATUS_TYPES.ACTIONS.GET_DATA_FOR_EDITING]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/vermittlerwechselstatus/getDataForEditing';

      axios.post(rootState.core.apiAddress + serviceUrl, payload, config).then(response => {
        if (response && response.data) {
  
          commit(VERMITTLERWECHSEL_STATUS_TYPES.MUTATIONS.GET_DATA_FOR_EDITING_SUCCESS, response.data);
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });



  },

  [VERMITTLERWECHSEL_STATUS_TYPES.ACTIONS.PERSIST_ROW]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/vermittlerwechselstatus/updateRow';

      axios.post(rootState.core.apiAddress + serviceUrl, payload, config).then(response => {
        if (response && response.data) {
  
          commit(VERMITTLERWECHSEL_STATUS_TYPES.MUTATIONS.PERSIST_ROW_SUCCESS, response.data);
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });

  },

  [VERMITTLERWECHSEL_STATUS_TYPES.ACTIONS.GET_BEISPIELKONTONR]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/vermittlerwechselstatus/getBeispielKontonr';

      const gesellschaft = payload?.gesellschaft;
      if (gesellschaft) {
        serviceUrl = `${serviceUrl}?gesellschaft=${gesellschaft}`;
      }     

      axios.get(rootState.core.apiAddress + serviceUrl, payload, config).then(response => {
        if (response && response.data) {
  
          commit(VERMITTLERWECHSEL_STATUS_TYPES.MUTATIONS.GET_BEISPIELKONTONR_SUCCESS, response.data);
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });



  },  
  

}