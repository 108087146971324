<template>
<BaseModal 
    ref="modal"
    modalTitle="Auswertung"
    :showDefaultButtons="false"
    @close="close(true)"
>
    <div>
        <label>
            Fortschritt
            <progress :value="progress" :max="progressBarMaxValue" style="width: 100%">
                {{progress}}%
            </progress>
            {{progress}}/{{progressBarMaxValue}}
        </label>
        <br>
        <InputTextArea :value="info" autoGrow disabled/>
    </div>
</BaseModal>
</template>
<script>
import LOG_TYPES from '@/store/log/types';

import BaseModal from '@/components/core/BaseModal.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import BaseButton from '@/components/core/BaseButton.vue';

import { buildMessage } from "@/helpers/log-message-helper";
import axios from 'axios';
const config = {
    defaultSpinner: true,
};

export default {
    components: {
        BaseModal,
        InputTextArea,
        BaseButton,
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            progress: 0,
            info: "",
            threadId: null,
            timeout: null,
            success: false,
            progressBarMaxValue: 10000,
        };
    },
    mounted() {
        axios.post(`${process.env.VUE_APP_API}/bipro_import/line_auswertung?id=${this.id}`, {}, config)
        .then(response => {
            this.threadId = response.data;
            this.timeout = setTimeout(() => this.checkStatus(), 1000);
        })
        this.$refs.modal.open();
    },
    methods: {
        checkStatus() {
            axios.get(`${process.env.VUE_APP_API}/bipro_import/line_auswertung?id=${this.threadId}`, config)
            .then(response => {
                if (response?.data?.act >= response?.data?.max) {
                    if (response.data.sLabel)
                        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.sLabel, 'success'));
                    this.success = true;
                    this.close();
                } else if (this.timeout) {
                    // not cancelled or finished yet, check again after 3 more seconds
                    this.timeout = setTimeout(() => this.checkStatus(), 3000);
                }
                this.progress = response.data.act;
                this.progressBarMaxValue = response.data.max;
                this.info = response.data.sInfo;
            }).catch(error => {
                this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error.response.data, 'danger'));
                this.close();
            })
        },
        close(modalClosed = false) {
            if (!modalClosed) {
                this.$refs.modal.close();
            }
            if (this.timeout) {
                clearTimeout(this.timeout);
                this.timeout = null;
            }
            this.$emit("close", this.success);
        },
    }
}
</script>