import COURTAGELISTE_TYPES from './types';
import CORE_TYPES from '../core/types';
import axios from 'axios';
import { buildMessage, } from '../../helpers/log-message-helper';
import * as utils from '../../helpers/commonfunctions';

export default {
  [COURTAGELISTE_TYPES.ACTIONS.RETRIEVE_FONDSWERTE]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/courtagelisteService/getFondswerte';

      const suchGesellschaft = payload?.suchGesellschaft || "";
      serviceUrl = `${serviceUrl}?suchGesellschaft=${suchGesellschaft}`;
  
      axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
        if (response && response.data) {
  
          commit(COURTAGELISTE_TYPES.MUTATIONS.RETRIEVE_FONDSWERTE_SUCCESS, response.data);
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });

  },

  [COURTAGELISTE_TYPES.ACTIONS.RETRIEVE_LAGERSTELLEN]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/courtagelisteService/getLagerstellen';

      axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
        if (response && response.data) {
  
          commit(COURTAGELISTE_TYPES.MUTATIONS.RETRIEVE_LAGERSTELLEN_SUCCESS, response.data);
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });

  },  



   

  

}