var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("table", { staticClass: "fc-table fc-table-divider fc-table-small" }, [
      _vm._m(0),
      _c(
        "tbody",
        _vm._l(_vm.positions, function(position, index) {
          return _c("tr", { key: index }, [
            _c("td", [_vm._v(_vm._s(position.isin))]),
            _c("td", [_vm._v(_vm._s(_vm.getFondsName(position)))]),
            _c(
              "td",
              [
                _c("InputField", {
                  attrs: {
                    type: "currency",
                    isEmbedded: true,
                    disabled: _vm.disabled
                  },
                  on: {
                    change: function($event) {
                      return _vm.onChangeInputField($event, position, "betrag")
                    }
                  },
                  model: {
                    value: position.betrag,
                    callback: function($$v) {
                      _vm.$set(position, "betrag", $$v)
                    },
                    expression: "position.betrag"
                  }
                })
              ],
              1
            ),
            _c(
              "td",
              [
                _c("InputField", {
                  attrs: {
                    type: "number",
                    isEmbedded: true,
                    disabled: _vm.disabled
                  },
                  on: {
                    change: function($event) {
                      return _vm.onChangeInputField(
                        $event,
                        position,
                        "vertragsLaufZeit"
                      )
                    }
                  },
                  model: {
                    value: position.vertragsLaufZeit,
                    callback: function($$v) {
                      _vm.$set(position, "vertragsLaufZeit", $$v)
                    },
                    expression: "position.vertragsLaufZeit"
                  }
                })
              ],
              1
            ),
            _c(
              "td",
              [
                _c("WertpapierAuswahlPositionsButtons", {
                  attrs: {
                    position: position,
                    positionName: _vm.getFondsName(position),
                    antragId: _vm.antragId,
                    categoryId: _vm.categoryId,
                    disabled: _vm.disabled
                  }
                })
              ],
              1
            )
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("ISIN")]),
        _c("th", [_vm._v("Fondsname")]),
        _c("th", [_vm._v("Sparrate in EUR")]),
        _c("th", [_vm._v("Vertragslaufzeit (in Jahren)")]),
        _c("th")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }