var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "editModal",
    attrs: {
      modalTitle: _vm.title,
      closeButtonLabel: "Übersicht",
      showDefaultButtons: false
    },
    scopedSlots: _vm._u(
      [
        _vm.actionHeader
          ? {
              key: "modalActions",
              fn: function() {
                return [
                  _c(
                    _vm.actionHeader.rowElement,
                    _vm._b(
                      {
                        key: _vm.actionHeader.key,
                        tag: "component",
                        class:
                          _vm.actionHeader.colorFn &&
                          _vm.actionHeader.colorFn(_vm.row),
                        attrs: {
                          column: _vm.actionHeader.key,
                          row: _vm.row,
                          tid:
                            _vm.tableId +
                            ":" +
                            _vm.actionHeader.key +
                            ":" +
                            _vm.row.id +
                            ":"
                        },
                        on: { action: _vm.onAction }
                      },
                      "component",
                      _vm.actionHeader.props,
                      false
                    )
                  )
                ]
              },
              proxy: true
            }
          : null,
        {
          key: "default",
          fn: function() {
            return [
              _vm._l(_vm.headersFlat, function(ref) {
                var key = ref.key
                var hideOnModalSheet = ref.hideOnModalSheet
                var label = ref.label
                var rowElement = ref.rowElement
                var props = ref.props
                var colorFn = ref.colorFn
                var isLink = ref.isLink
                var isLinkIf = ref.isLinkIf
                return [
                  !hideOnModalSheet && _vm.visibleInCard(key, _vm.row)
                    ? _c("div", { key: key, staticClass: "row-container" }, [
                        _c(
                          "div",
                          { staticClass: "row-line-label" },
                          [
                            _vm._t(
                              "header_" + key,
                              function() {
                                return [_c("span", [_vm._v(_vm._s(label))])]
                              },
                              null,
                              { key: key }
                            )
                          ],
                          2
                        ),
                        _c(
                          "div",
                          { staticClass: "row-line-value" },
                          [
                            !rowElement
                              ? _vm._t(key, null, {
                                  row: _vm.row,
                                  id: _vm.row.id
                                })
                              : _vm.isRowLink(_vm.row, isLink, isLinkIf)
                              ? _c(
                                  "a",
                                  {
                                    key: key,
                                    attrs: {
                                      tid: _vm._generateTidFromString(
                                        _vm.tableId +
                                          ":" +
                                          key +
                                          ":" +
                                          _vm.row.id
                                      )
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.clickCell(_vm.row, key)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      rowElement,
                                      _vm._b(
                                        {
                                          key: key,
                                          tag: "component",
                                          attrs: {
                                            column: key,
                                            row: _vm.row,
                                            tid:
                                              _vm.tableId +
                                              ":" +
                                              key +
                                              ":" +
                                              _vm.row.id +
                                              ":"
                                          }
                                        },
                                        "component",
                                        props,
                                        false
                                      )
                                    )
                                  ],
                                  1
                                )
                              : _c(
                                  rowElement,
                                  _vm._b(
                                    {
                                      key: key,
                                      tag: "component",
                                      class: colorFn && colorFn(_vm.row),
                                      attrs: {
                                        column: key,
                                        row: _vm.row,
                                        tid:
                                          _vm.tableId +
                                          ":" +
                                          key +
                                          ":" +
                                          _vm.row.id +
                                          ":"
                                      },
                                      on: { action: _vm.onAction }
                                    },
                                    "component",
                                    props,
                                    false
                                  )
                                )
                          ],
                          2
                        )
                      ])
                    : _vm._e()
                ]
              })
            ]
          },
          proxy: true
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }