var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "modal",
    attrs: {
      modalTitle: "Spezialfeiertage",
      confirmLoading: _vm.loading,
      showConfirmButton: false
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function() {
          return [
            _vm.isBesitzer
              ? _c(
                  "div",
                  { staticClass: "mb-3" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "box__title",
                        on: {
                          click: function($event) {
                            return _vm.$refs.feiertagModal.open()
                          }
                        }
                      },
                      [_vm._v("Gruppe: " + _vm._s(_vm.gruppe.bezeichnung))]
                    ),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-12 col-sm-4" },
                        [
                          _c("DatePickerField", {
                            attrs: {
                              label: "Datum",
                              isValueAsString: "",
                              emptyValueAsNull: "",
                              validateUntouched: ""
                            },
                            model: {
                              value: _vm.form.datum,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "datum", $$v)
                              },
                              expression: "form.datum"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12 col-sm-8" },
                        [
                          _c("InputField", {
                            attrs: {
                              label: "Bezeichnung",
                              type: "text",
                              validateUntouched: ""
                            },
                            model: {
                              value: _vm.form.bezeichnung,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "bezeichnung", $$v)
                              },
                              expression: "form.bezeichnung"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("InputToggleSwitch", {
                      staticClass: "pb-2",
                      attrs: { label: "Jährliche Wiederholung" },
                      model: {
                        value: _vm.form.wiederholung,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "wiederholung", $$v)
                        },
                        expression: "form.wiederholung"
                      }
                    }),
                    _vm.feiertag && _vm.feiertag.id > 0
                      ? _c(
                          "BaseButton",
                          {
                            attrs: { isSecondary: "", disabled: _vm.saving },
                            on: { click: _vm.reset }
                          },
                          [_vm._v("Abbrechen")]
                        )
                      : _vm._e(),
                    _c(
                      "BaseButton",
                      {
                        attrs: {
                          disabled:
                            _vm.saving ||
                            !_vm.form.datum ||
                            !_vm.form.bezeichnung
                        },
                        on: { click: _vm.save }
                      },
                      [
                        _vm.saving ? _c("AnimatedSpinner") : _vm._e(),
                        _vm._v(" Speichern ")
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.loading
              ? _c("GhostLoading", { attrs: { type: "table" } })
              : _c("Table", {
                  attrs: {
                    title: _vm.isBesitzer
                      ? "Übersicht meine Spezialfeiertage"
                      : null,
                    headers: _vm.headers,
                    rows: _vm.rows,
                    hidePagination: ""
                  },
                  on: {
                    "action-EDIT": _vm.setEdit,
                    "action-DELETE": _vm.deleteFeiertag
                  }
                })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }