<template>
  <BaseModal 
    ref="modal" 
    size="full"
    :showConfirmButton="false"
    @close="onClose"
    @onConfirmButton="onConfirm()"
  >
    <template #modalTitle>
      <div class="options-menu-config-view-diff-modal--title-container clearfix">
        <span class="options-menu-config-view-diff-modal--title">Konfigurationen vergleichen</span>
        <OptionsMenuConfigViewDiffHelpDropdown class="options-menu-config-view-diff-modal--help-button" />
      </div>
    </template>

    <template #default>
      <div v-if="isListsIdentical" class="fc-alert fc-alert-primary">
        Konfigurationen sind identisch
      </div>
      <div v-else-if="!diff.dest.length" class="fc-alert">
        Konfiguration nicht gefunden
      </div>
      <div v-else class="fc-alert fc-alert-warning">
        Daten-Konflikt
      </div>

      <div class="compare-list">
        <div class="source-list">
          <div class="box__title">Aktuelle Konfiguration</div>
          <div class="compare-list--item">
            <ul class="list list-bordered">
              <li v-for="item in diff.source" :key="item.id" class="list-bordered-item"
                :class="itemClass(item)"
                :data-item="item.id"
              >
                <span v-html="sanitize(item.label)" />
              </li>
            </ul>
          </div>

          <div v-if="diff.sourceToSelect.length > 0" class="compare-list--item">
            <div class="compare-list--item-title">ausgeblendet</div>
            <ul class="list list-bordered">
              <li v-for="item in diff.sourceToSelect" :key="item.id" class="list-bordered-item"
                :class="[`status-${item.status}`]"
                :data-item="item.id"
              >
                <span v-html="sanitize(item.label)" />
              </li>
            </ul>
          </div>
        </div>

        <div class="dest-list">
          <div class="box__title">Konfiguration (Nummer: {{ config.nummer }})</div>
          <template v-if="diff.dest.length > 0 || diff.destToSelect.length > 0">
            <div class="compare-list--item">
              <ul class="list list-bordered">
                <li v-for="item in diff.dest" :key="item.id" class="list-bordered-item"
                  :class="itemClass(item)"
                  :data-item="item.id"
                >
                  <span v-html="sanitize(item.label)" />
                </li>
              </ul>
            </div>

            <div v-if="diff.destToSelect.length > 0" class="compare-list--item">
              <div class="compare-list--item-title">ausgeblendet</div>
              <ul class="list list-bordered">
                <li v-for="item in diff.destToSelect" :key="item.id" class="list-bordered-item"
                  :class="[`status-${item.status}`]"
                  :data-item="item.id"
                >
                  <span v-html="sanitize(item.label)" />
                </li>
              </ul>
            </div>
          </template>
          <NoData v-else noIcon content="Konfiguration nicht gefunden" />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/core/BaseModal.vue';
import OptionsMenuConfigViewDiffHelpDropdown from '@/components/core/option-menu/optionMenuConfig/OptionsMenuConfigViewDiffHelpDropdown.vue';
import NoData from '@/components/core/NoData.vue';

import { OptionsMenuDiffHelper } from '@/menu/menu-config-utils';
import { sanitize } from '@/helpers/string-helper';

const COMPARE_LIST_SELECTOR = '.compare-list';

export default {
  components: {
    BaseModal,
    OptionsMenuConfigViewDiffHelpDropdown,
    NoData,
  },
  data() {
    return {
      config: {},
      sourceList: [],
      destList: [],
    };
  },
  computed: {
    isListsIdentical() {
      return OptionsMenuDiffHelper.isIdentical(this.sourceList, this.destList);
    },
    diff() {
      return OptionsMenuDiffHelper.findDiff(this.sourceList, this.destList);
    },
  },
  methods: {
    open(config, sourceList, destList) {
      this.$refs.modal.open();

      this.config = config;
      this.sourceList = [ ...sourceList || [] ];
      this.destList = [ ...destList || [] ];

      requestAnimationFrame(() => document.querySelector(COMPARE_LIST_SELECTOR).addEventListener('mousemove', this.highlightItem));
    },
    highlightItem(event) {
      const currentEl = document.elementFromPoint(event.clientX, event.clientY);
      const el = currentEl?.closest?.('[data-item]') || currentEl;

      document.querySelectorAll(`[data-item].item-highlighted`).forEach(highEl => {
        if(highEl.dataset?.item !== el.dataset?.item) {
          highEl.classList.remove('item-highlighted');
        }
      });
      if(el.dataset?.item) {
        document.querySelectorAll(`[data-item="${el.dataset?.item}"]`).forEach(el => {
          el.classList.add('item-highlighted');
        });
      }
    },
    close() {
      this.$refs.modal.close();
    },
    onClose() {
      document.querySelector(COMPARE_LIST_SELECTOR).removeEventListener('mousemove', this.highlightItem);
    },
    sanitize(html) {
      return sanitize(html);
    },
    itemClass(item) {
      return {
        [`status-${item.status}`]: true,
        'item-not-allowed': item.allowed === false,
      };
    },
  },
}
</script>

<style lang="scss" scoped>
.options-menu-config-view-diff-modal--title-container {
  .options-menu-config-view-diff-modal--help-button {
    float: right;
  }
}

.compare-list {
  cursor: default;
  display: flex;

  .source-list,
  .dest-list {
    flex: 1 1 50%;
    margin: 0 16px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .compare-list--item {
    margin: 0 0 12px;

    &:last-child {
      margin-bottom: 0;
    }

    .compare-list--item-title {
      font-weight: 600;
    }
  }

  .item-not-allowed {
    text-decoration: line-through;
  }

  .status-added {
    background-color: var(--color-success-background);
    color: var(--color-success);

    &:before {
      content: "+ ";
    }
  }

  .status-changed {
    background-color: var(--color-warning-background);
    color: var(--color-warning);

    &:before {
      content: "~ ";
    }
  }

  .status-removed {
    background-color: var(--color-danger-background);
    color: var(--color-danger);

    &:before {
      content: "- ";
    }
  }

  .status-none {
    background-color: transparent;
    color: var(--color-text);
  }

  .item-highlighted {
    background-color: #f0f0f0;
    transition: background-color .3s ease;
  }
}
</style>
