<template>
  <div class="input-forms__container">
    <div class="input-forms__label-container">
      <div>
        <div class="box__container row layout__negative-margin--8 mb-3">
          <div class="col-12 col-sm-4 col-xl-2 mx-0 ml-3 mb-3">
            <BaseButton
              @click="openFondsfinder('KAUF')"
              isBlock
              isSecondary
              :disabled="
                disabled ||
                  (config.anzahlFonds && positions.length >= config.anzahlFonds)
              "
            >
              Kauf
            </BaseButton>
          </div>
          <div class="col-12 col-sm-4 col-xl-2 mx-0 ml-3 mb-3">
            <BaseButton
              @click="openFondsfinder('SPARPLAN')"
              isBlock
              isSecondary
              :disabled="
                disabled ||
                  (config.anzahlFonds && positions.length >= config.anzahlFonds)
              "
            >
              Sparplan
            </BaseButton>
          </div>
          <FormLabel
            v-if="config.anzahlFonds"
            class="col-12 mt-3"
            :config="{ small: true }"
            :label="'(Maximal ' + config.anzahlFonds + ' Positionen)'"
          />
        </div>
        <template v-for="(pos, index) in positions">
          <FormEbasePositionCards
            :key="index"
            :position="pos"
            :category="category"
            :antragId="antragId"
            :isWebservice="config.isWebservice"
            :disabled="disabled"
          />
        </template>
      </div>
    </div>
    <div
      v-if="config.mitSummenbildung && this.positions && this.positions.length"
    >
      <Summenbildung
        :antragId="antragId"
        :summe="summe"
        :summeSpar="summeSparplan"
        :mitSparplan="true"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BaseButton from "@/components/core/BaseButton.vue";
import FormEbasePositionCards from "@/components/core/forms/antraege/wertpapierAuswahl/FormEbasePositionCards.vue";
import antragNavigationMixin from "@/mixins/antrag/antrag-navigation-mixin.js";
import ANTRAG_TYPES from "@/store/antrag/types";
import FONDSFINDER_TYPES from "@/store/fondsfinder/types";
import Summenbildung from "@/components/antrag/Summenbildung.vue";
import FormLabel from "@/components/core/forms/FormLabel.vue";

export default {
  mixins: [antragNavigationMixin],
  props: {
    title: {},
    antragId: {},
    categoryId: {},
    antragTyp: {},
    config: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      category: "",
      positionDataAdditional: {},
      summe: 0,
      summeSparplan: 0,
    };
  },

  watch: {
    positions: {
      handler() {
        let sumKauf = 0;
        let sumSparplan = 0;
        if (this.positions && this.positions.length) {
          this.positions.forEach((r) => {
            if (r.einmalBetrag) {
              sumKauf += +r.einmalBetrag;
            }
            if (r.sparplanBetrag) {
              sumSparplan += +r.sparplanBetrag;
            }
          });
        }
        this.summe = sumKauf;
        this.summeSparplan = sumSparplan;
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      positionsAll: ANTRAG_TYPES.GETTERS.POSITIONS,
      positionDataAll: ANTRAG_TYPES.GETTERS.POSITION_DATA,
      positionInfo: FONDSFINDER_TYPES.GETTERS.POSITION_INFO,
    }),
    positions() {
      const resultSparplan =
        (this.positionsAll &&
          this.antragId &&
          this.positionsAll[this.antragId] &&
          this.positionsAll[this.antragId]["SPARPLAN"]) ||
        [];
      resultSparplan.map((pos, index) =>
        Object.assign(pos, {
          index: index,
          fondsname: this.getFondsName(pos),
          lagerstelle: this.config.lagerstelle,
          sri: this.getSRI(pos),
          art: "SPARPLAN",
          isSparplanp: true,
          /*   planFrequenz: this.getPlanFrequenz(pos), */
          planTag: this.getPlanTag(pos),
          /*   spKontoFlex: this.getSpKontoFlex(pos), */
          sparplanDynamik: this.getSparplanDynamik(pos),
        })
      );
      const resultKauf =
        (this.positionsAll &&
          this.antragId &&
          this.positionsAll[this.antragId] &&
          this.positionsAll[this.antragId]["KAUF"]) ||
        [];
      resultKauf.map((pos, index) =>
        Object.assign(pos, {
          index: index,
          fondsname: this.getFondsName(pos),
          lagerstelle: this.config.lagerstelle,
          sri: this.getSRI(pos),
          art: "KAUF",
          isEinmalp: true,
        })
      );
      const result = resultKauf.concat(resultSparplan);
      return result;
    },
  },
  components: {
    BaseButton,
    FormEbasePositionCards,
    Summenbildung,
    FormLabel,
  },
  methods: {
    openFondsfinder(category) {
      this.category = category;
      this.goToFormsFinder(
        this.category,
        this.config,
        this.suppressFilterLagerstelle
      );
    },
    getFondsName(pos) {
      return (
        (pos &&
          this.positionInfo &&
          this.positionInfo[pos.isin] &&
          this.positionInfo[pos.isin].wertpapiername) ||
        pos.fondsname ||
        pos.wertpapiername
      );
    },
    getArt(pos) {
      return (
        this.positionsAll &&
        this.positionsAll[pos.isin] &&
        this.positionsAll[pos.isin].art
      );
    },
    getSRI(pos) {
      return (
        pos &&
        this.positionInfo &&
        this.positionInfo[pos.isin] &&
        this.positionInfo[pos.isin].sri
      );
    },
    getPlanTag(pos) {
      if (pos.planTag && pos.planTag != null) {
        if (pos.planTag == 1) {
          return "1";
        } else if (pos.planTag == 15) {
          return "15";
        } else {
          return "";
        }
      }
    },
    getSparplanDynamik(pos) {
      return pos.sparplanDynamik;
    },
    getSpKontoFlex(pos) {
      return pos.spKontoFlex;
    },
    getPlanFrequenz(pos) {
      return pos.planFrequenz;
    },
  },
};
</script>

<style scoped></style>
