var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "entgelt-component" }, [
    _c("label", [_vm._v(_vm._s(_vm.label))]),
    !!_vm.componentNetto
      ? _c(
          "div",
          { staticClass: "entgelt-component-netto-input-container" },
          [
            _c("InputField", {
              attrs: {
                type: "percent",
                label: _vm.componentNetto.label,
                placeholder: _vm.componentNetto.placeholder,
                precision: _vm.precision,
                value: _vm.internalValueNetto,
                disabled: _vm.disabled
              },
              on: {
                input: function($event) {
                  return _vm.handleNettoInput($event)
                },
                change: function($event) {
                  return _vm.handleNettoChange($event)
                }
              }
            })
          ],
          1
        )
      : _vm._e(),
    !!_vm.componentBrutto
      ? _c(
          "div",
          { staticClass: "entgelt-component-brutto-input-container" },
          [
            _c("InputField", {
              attrs: {
                type: "percent",
                label: _vm.componentBrutto.label,
                placeholder: _vm.componentBrutto.placeholder,
                precision: _vm.precision,
                value: _vm.internalValueBrutto,
                disabled: _vm.disabled
              },
              on: {
                input: function($event) {
                  return _vm.handleBruttoInput($event)
                },
                change: function($event) {
                  return _vm.handleBruttoChange($event)
                }
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }