var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "details_container" },
    [
      _c(
        "div",
        { staticClass: "banner" },
        [_c("editable-frame", { attrs: { type: "details_title_page" } })],
        1
      ),
      _c("hr"),
      _c("div", { staticClass: "dreispalten_wrapper" }, [
        _c("div", { staticClass: "dreispalten" }, [
          _c("div", { staticClass: "spalte1" }, [
            _vm._m(0),
            _vm._m(1),
            _c("hr"),
            _c("div", { staticClass: "drei_spalten_text" }, [
              _vm.isIntern
                ? _c("div", { staticClass: "fc-alert fc-alert-warning" }, [
                    _vm._v(
                      "Die Serviceentgelt und Depotführung-Kosten werden automatisch aus dem MSC eingefügt."
                    )
                  ])
                : _vm._e(),
              _c("ul", [
                _c("li", [
                  _vm._v(
                    " 0,– Euro Ausgabeaufschlag (100 % Rabatt auf den Ausgabeaufschlag bei Erstanlage, Zuzahlung, Sparplan und Fondswechsel) "
                  )
                ]),
                _c("li", [
                  _vm._v(
                    " 0,– Euro Transaktionskosten bei Fondswechsel bei Modellportfolio (bei Einzelfonds 2EUR/Transaktion) "
                  )
                ]),
                _c("li", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.serviceentgeltPortfolioTotal) +
                      ",– Euro Serviceentgelt p.a. zzgl. gesetzlicher MWST (" +
                      _vm._s(_vm.serviceentgeltPortfolioPercent) +
                      " % Serviceentgelt p.a. zzgl. gesetzlicher MWST auf den durchschnittlichen Depotbestand bei Modellportfolios.) "
                  )
                ]),
                _c("li", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.serviceentgeltEinzelTotal) +
                      ",– Euro Serviceentgelt p.a. zzgl. gesetzlicher MWST (" +
                      _vm._s(_vm.serviceentgeltEinzelPercent) +
                      " % Serviceentgelt p.a. zzgl. gesetzlicher MWST auf den durchschnittlichen Depotbestand bei Einzelfonds.) "
                  )
                ]),
                _c("li", [
                  _vm._v(
                    " " +
                      _vm._s(_vm.getDepotfuehrungsentgelt) +
                      ",– Euro p.a. Depotgebühr der FFB (FIL Fondsbank GmbH), jedes weitere Depot 12,- Euro p.a. "
                  )
                ]),
                _c("li", [
                  _vm._v(
                    " Bis zu 0,15% p.a. Verwaltungsentgelt (nur bei Modellportfolio) "
                  )
                ]),
                _c("li", [
                  _vm._v(
                    " weitere interne Kosten von Emittenten und Produktanbieter (Details finden Sie im sog. ex-ante Kostenausweis in den Depoteröffnungsunterlagen) "
                  )
                ]),
                _c("li", [
                  _vm._v(
                    " persönliche und individuelle Kosten durch Steuern (z.B. Abgeltungssteuer) und weitere Dienstleister (Steuerberater) "
                  )
                ])
              ])
            ])
          ]),
          _vm._m(2),
          _vm._m(3)
        ])
      ]),
      _c("div", { staticClass: "banner" }, [
        _vm._v("ERLÄUTERUNGEN ZU DEN KOSTEN INNERHALB DES FONDSSHOPS")
      ]),
      _c("hr"),
      _c("div", { staticClass: "text_only_wrapper" }, [
        _c("div", { staticClass: "text_only" }, [
          _vm.isIntern
            ? _c("div", { staticClass: "fc-alert fc-alert-warning" }, [
                _vm._v(
                  "Die Serviceentgelt und Depotführung-Kosten werden automatisch aus dem MSC eingefügt."
                )
              ])
            : _vm._e(),
          _vm._m(4),
          _c("p", [
            _vm._v(
              " Bei Nutzung des Fondsshops bezahlen Sie dauerhaft keine Ausgabeaufschläge und sparen sich so bis zu 6 % banküblicher Kaufgebühren. Dies gilt sowohl für Erstanlage, wie auch für Zuzahlungen oder Sparpläne! "
            )
          ]),
          _vm._m(5),
          _c("p", [
            _vm._v(
              " Als Kunde bezahlen Sie für die Dienstleistung innerhalb des Fondsshops ein Serviceentgelt in Höhe von " +
                _vm._s(_vm.serviceentgeltPortfolioPercent) +
                " % p.a. bei Modellportfolien bzw. " +
                _vm._s(_vm.serviceentgeltEinzelPercent) +
                " % p.a. bei Einzelfondsdepots, jeweils zzgl. MWST. Das Serviceentgelt wird prozentual auf den einmal im Kalenderquartal ermittelten tagesgenauen Durchschnittswert des Depotbestandes berechnet und wird automatisch von der FFB (FIL Fondsbank GmbH) durch Veräußerung von Fondsanteilen aus dem Depot abgebucht. "
            )
          ]),
          _vm._m(6),
          _c("p", [
            _vm._v(
              " Die Kosten für die Depotführung bei der FFB (FIL Fondsbank GmbH) belaufen sich auf " +
                _vm._s(_vm.getDepotfuehrungsentgelt) +
                ",– Euro pro Jahr. Es gilt darüber hinaus das Preis-Leistungs-Verzeichnis der FFB für das FFB Fondsdepot Plus. "
            )
          ]),
          _vm._m(7),
          _c("p", [
            _vm._v(
              " Investmentfonds beinhalten in der Regel interne Kosten, die durch die Verwaltungsgesellschaft der Investmentfonds erhoben werden. Diese Kosten werden nicht direkt dem Kunden in Rechnung gestellt, sondern werden aus dem investierten Vermögen des entsprechenden Investmentfonds entnommen. Die Höhe der laufenden Kosten beträgt in der Regel zwischen 0,00 % bis 1,55 % p.a. bezogen auf das durchschnittliche Fondsvermögen zum jeweiligen Bewertungsstichtag. Details hierzu finden Sie im ex-ante Kostenausweis in den Depoteröffnungsunterlagen. "
            )
          ])
        ])
      ]),
      _c("div", { staticClass: "banner" }, [
        _vm._v("ALLGEMEINE HINWEISE ZU INVESTMENTFONDS")
      ]),
      _c("hr"),
      _vm._m(8),
      _c("div", { staticClass: "banner" }, [
        _vm._v("KEINE ANLAGE IST OHNE RISIKO!")
      ]),
      _c("hr"),
      _vm._m(9),
      _c("risiko-hinweise")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "drei_spalten_image" }, [
      _c("img", {
        attrs: {
          alt: "Icon für die Erklärung",
          src: require("@/../public/img/cms/selectable_images/Portfolios.svg")
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "drei_spalten_banner" }, [
      _c("h3", [
        _vm._v("Ihre jährlichen Kosten bei einer Anlage von 10.000 Euro")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "spalte2" }, [
      _c("div", { staticClass: "drei_spalten_image" }, [
        _c("img", {
          attrs: {
            alt: "Icon für die Erklärung",
            src: require("@/../public/img/cms/selectable_images/Thumbsup.svg")
          }
        })
      ]),
      _c("div", { staticClass: "drei_spalten_banner" }, [
        _c("h3", [_vm._v("Ihre Vorteile und Chancen")])
      ]),
      _c("hr"),
      _c("div", { staticClass: "drei_spalten_text" }, [
        _c("ul", [
          _c("li", [
            _vm._v(" einfaches selbständiges Investieren in drei Schritten ")
          ]),
          _c("li", [_vm._v(" 24/7 Onlinezugang via PC und App ")]),
          _c("li", [
            _vm._v(" Sicherheit Ihrer Daten durch SSL Verschlüsselung ")
          ]),
          _c("li", [
            _vm._v(" freie Fondsauswahl aus Einzelfonds und Modellportfolios ")
          ]),
          _c("li", [
            _vm._v(
              " kostengünstiges und flexibles Investieren durch Einsparung von Ausgabeaufschläge beim Fondskauf "
            )
          ]),
          _c("li", [
            _vm._v(
              " Anlage ab 500 Euro einmalig / 25 Euro monatlich in Einzelfonds / 50 Euro monatlich in Portfolios "
            )
          ]),
          _c("li", [
            _vm._v(
              " Flexibilität durch tägliche Verfügbarkeit Ihrer Fondsanteile "
            )
          ]),
          _c("li", [
            _vm._v(
              " Transparenz durch regelmäßiges Reporting (Quartalsbericht) "
            )
          ]),
          _c("li", [
            _vm._v(
              " Insolvenzschutz Ihrer Investmentfonds, da diese als Sondervermögen gehalten werden "
            )
          ]),
          _c("li", [
            _vm._v(
              " Risikostreuung durch Anlage in viele verschiedene Einzeltitel über einen Investmentfonds "
            )
          ]),
          _c("li", [
            _vm._v(
              " Ertragschancen durch steigende Anteilspreise und mögliche Ausschüttungen "
            )
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "spalte3" }, [
      _c("div", { staticClass: "drei_spalten_image" }, [
        _c("img", {
          attrs: {
            alt: "Icon für die Erklärung",
            src: require("@/../public/img/cms/selectable_images/Vorsicht.svg")
          }
        })
      ]),
      _c("div", { staticClass: "drei_spalten_banner" }, [
        _c("h3", [_vm._v("Ihre Risiken")])
      ]),
      _c("hr"),
      _c("div", { staticClass: "drei_spalten_text" }, [
        _c("ul", [
          _c("li", [_vm._v("Zinsrisiko")]),
          _c("li", [_vm._v("Kursrisiko")]),
          _c("li", [_vm._v("Währungsrisiko")]),
          _c("li", [_vm._v("Bonitätsrisiko")]),
          _c("li", [_vm._v("Emittentenrisiko")]),
          _c("li", [_vm._v("theoretisches Risiko eines Totalverlustes")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_vm._v("Ausgabeaufschläge")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_vm._v("Serviceentgelt")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_vm._v("Depotgebühren")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [
        _vm._v(
          "Interne Kosten von Emittenten und Produktanbietern / Vertriebsfolgeprovision"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text_only_wrapper" }, [
      _c("div", { staticClass: "text_only" }, [
        _c("p", [_c("b", [_vm._v("Allgemeine Informationen des BVI")])]),
        _c("p", [
          _vm._v(
            " Der BVI stellt Ihnen als interessiertem Leser umfangreiches Informationsmaterial zur Verfügung. Neben Basisinformationen zur Kapitalanlage in Investmentfonds bietet der BVI vertiefende Dokumente in verschiedenen Bereichen. "
          )
        ]),
        _c("p", [
          _c(
            "a",
            {
              attrs: {
                href:
                  "https://www.bvi.de/fileadmin/user_upload/Bestellcenter/BVI_Grundlagenbroschuere_2019_Web.pdf",
                target: "_blank"
              }
            },
            [_vm._v("Basisinformationen zu Investmentfonds")]
          )
        ]),
        _c("p", [
          _c(
            "a",
            {
              attrs: {
                href:
                  "https://www.bvi.de/uploads/tx_bvibcenter/Der_BVI._Beraten._Vermitteln._Informieren_.pdf",
                target: "_blank"
              }
            },
            [_vm._v("Investmentfonds und Altersvorsorge")]
          )
        ]),
        _c("p", [_c("b", [_vm._v("Wesentliche Anlegerinformationen")])]),
        _c("p", [
          _vm._v(
            " Die wesentlichen Anlegerinformationen soll dazu dienen, Anlegern auf einen Blick die wesentlichen Chancen und Risiken von Bankprodukten übersichtlich darzustellen. Es soll somit dem Verbraucher ermöglichen, die wesentlichen Eigenschaften des Finanzprodukts schnell zu erfassen und verschiedene Anlageprodukte miteinander leichter zu vergleichen. Gerne stellen wir Ihnen innerhalb unseres Fondsshops diese Informationen über den Button Fonds-Details zur Verfügung. Hier erhalten Sie "
          )
        ]),
        _c("ul", [
          _c("li", [_vm._v("Factsheets zu Einzelfonds und Modellportfolios")]),
          _c("li", [_vm._v("Halbjahresberichte / Jahresberichte")]),
          _c("li", [_vm._v("Verkaufsprospekte")]),
          _c("li", [
            _vm._v(
              "PRIIP-BIB (Packaged Retail and Insurance-based Investment Product - Basisinformationsblatt)"
            )
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text_only_wrapper" }, [
      _c("div", { staticClass: "text_only" }, [
        _c("p", [
          _vm._v(
            " Der Wert eines Investments kann während der Laufzeit einer Vielzahl von Marktfaktoren, wie dem Zinsniveau, der Kursentwicklung, der Schwankungsintensität, der Bonität anderer Marktteilnehmer usw. unterliegen. Aufgrund ungünstiger Entwicklungen dieser Faktoren kann das eingesetzte Kapital zuzüglich der entstandenen Kosten vollständig verloren sein. Bei der Investition in Investmentfonds oder eine Vermögensverwaltung ist das Totalverlustrisiko durch die breite Streuung sehr unwahrscheinlich. Selbst wenn einzelne Wertpapiere einen Totalverlust erleiden sollten, ist ein Gesamtverlust durch die breite Diversifikation unwahrscheinlich. "
          )
        ]),
        _c("p", [_c("b", [_vm._v("Sondervermögen")])]),
        _c("p", [
          _vm._v(
            " Ein Investmentfonds ist ein Sondervermögen, das nach dem Grundsatz der Risikostreuung in verschiedenen Einzelanlagen (z.B. in Aktien, Anleihen, wertpapierähnlichen Anlagen oder Immobilien) investiert ist. Ein Depotinhaber kann mit dem Kauf von Investmentanteilen an der Wertentwicklung sowie den Erträgen des Fondsvermögens teilhaben und trägt anteilig das volle Risiko der durch den Anteilschein repräsentierten Anlagen. "
          )
        ]),
        _c("p", [_c("b", [_vm._v("Währungsrisiken")])]),
        _c("p", [
          _vm._v(
            " Bei Investmentfonds, die auch in auf fremde Währung lautende Wertpapiere investieren bzw. in Fremdwährung geführt werden, muss berücksichtigt werden, dass sich neben der normalen Kursentwicklung auch die Währungsentwicklung negativ im Anteilspreis niederschlagen kann und Länderrisiken auftreten können, auch wenn die Wertpapiere, in die der Investmentfonds investiert, an einer deutschen Börse gehandelt werden. Durch die Aufwertung des Euro (Abwertung der Auslandswährung) verlieren die ausländischen Vermögenspositionen – am Maßstab des Euro betrachtet – an Wert. Zum Kursrisiko ausländischer Wertpapiere kommt damit das Währungsrisiko hinzu. Die Währungsentwicklung kann einen Gewinn aufzehren und die erzielte Rendite so stark beeinträchtigen, dass eine Anlage in Euro oder in Vermögenspositionen der Eurozone unter Umständen vorteilhafter gewesen wäre. "
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }