import ANSPRECHPARTNER_TYPES from './types';

export default {
  [ANSPRECHPARTNER_TYPES.GETTERS.GET_ANSPRECHPARTNER](state) {
    return state.ansprechpartnerData;
  },
  [ANSPRECHPARTNER_TYPES.GETTERS.GET_USER_PICTURE_URL](state) {
    return state.userPicture?.url || null;
  },
  [ANSPRECHPARTNER_TYPES.GETTERS.GET_USER_PICTURE_DATA](state) {
    return state.userPicture || {};
  },
  [ANSPRECHPARTNER_TYPES.GETTERS.GET_TEMP_USER_PICTURE_DATA](state) {
    return state.tempUserPicture;
  },
  [ANSPRECHPARTNER_TYPES.GETTERS.GET_ANSPRECHPARTNER_SELECTIONS](state) {
    return state.comboboxSelections || {};
  },
}