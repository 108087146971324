export const MUTATION_PREFIX = 'MAKLER_TEXTE_TYPES: ';
export const ACTIONS_PREFIX = 'MAKLER_TEXTE_TYPES_ACTIONS_';
export const GETTERS_PREFIX = 'MAKLER_TEXTE_TYPES_GETTERS_';

export default {
  MUTATIONS: {
    SET_MAKLER_TEXT_BEREICH: MUTATION_PREFIX + 'SET_MAKLER_TEXT_BEREICH',
    SET_SELECTED_BEREICH: MUTATION_PREFIX + 'SET_SELECTED_BEREICH',
    SET_LIST_BY_BEREICH: MUTATION_PREFIX + 'SET_LIST_BY_BEREICH',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
  },
  ACTIONS: {
    FIND_MAKLER_TEXT_BEREICH: ACTIONS_PREFIX + 'FIND_MAKLER_TEXT_BEREICH',
    FIND_LIST: ACTIONS_PREFIX + 'FIND_LIST',
    FIND_SELECTED_BEREICH_LIST: ACTIONS_PREFIX + 'FIND_SELECTED_BEREICH_LIST',
    SAVE_MAKLER_TEXTE: ACTIONS_PREFIX + 'SAVE_MAKLER_TEXTE',
    DELETE_MAKLER_TEXTE: ACTIONS_PREFIX + 'DELETE_MAKLER_TEXTE',
    SAVE_SICHTBAR_STRUKTUR: ACTIONS_PREFIX + 'SAVE_SICHTBAR_STRUKTUR',
    SAVE_SICHTBAR_STRUKTUR_FOR_ALL: ACTIONS_PREFIX + 'SAVE_SICHTBAR_STRUKTUR_FOR_ALL',
  },
  GETTERS: {
    MAKLER_TEXT_BEREICH: GETTERS_PREFIX + 'MAKLER_TEXT_BEREICH',
    SELECTED_BEREICH: GETTERS_PREFIX + 'SELECTED_BEREICH',
    LIST_BY_BEREICH: GETTERS_PREFIX + 'LIST_BY_BEREICH',
  },
}
