import FREISTELLUNGSANTRAG_TYPES from './types';
import CORE_TYPES from '../core/types';
import axios from 'axios';
import { buildMessage, } from '../../helpers/log-message-helper';
import * as utils from '../../helpers/commonfunctions';

export default {
  [FREISTELLUNGSANTRAG_TYPES.ACTIONS.RETRIEVE_FREISTELLUNGSANTRAG_DATA]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/freistellungsauftrag/list';

      let params = {};
  
      if (payload?.kundennr) {
        params.kundennr = payload?.kundennr;
      }

      if (payload?.gesellschaft) {
        params.gesellschaft = payload?.gesellschaft;
      }

      axios.get(rootState.core.apiAddress + serviceUrl, {params, config}).then(response => {
        if (response && response.data) {
  
          commit(FREISTELLUNGSANTRAG_TYPES.MUTATIONS.RETRIEVE_FREISTELLUNGSANTRAG_SUCCESS, response.data);
          commit(FREISTELLUNGSANTRAG_TYPES.MUTATIONS.FREISTELLUNGSANTRAG_GESELLSCHAFTEN, response.data?.gesellschaften);

          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });



  },

  [FREISTELLUNGSANTRAG_TYPES.ACTIONS.PERSIST_ROW]({ getters, dispatch, commit }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/freistellungsauftrag/persist`, payload, config)
        .then((response) => {
          commit(FREISTELLUNGSANTRAG_TYPES.MUTATIONS.PERSIST_ROW_SUCCESS, response.data);
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },  

  [FREISTELLUNGSANTRAG_TYPES.ACTIONS.REMOVE_ROW]({ commit, state, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };    
   
    let url = '/freistellungsauftrag/removeItem';

    if(payload && payload.id) {
      url = `${url}?id=${payload.id}`;
    }

    return new Promise((resolve, reject) => {
      axios.delete(rootState.core.apiAddress + url, config).then(response => {
        if (response?.data) {
          commit(FREISTELLUNGSANTRAG_TYPES.MUTATIONS.REMOVE_ROW_SUCCESS, response.data);
          resolve(response.data);
        } else {
          reject();
        }
      })
    });


  
  }, 

 
  

  

}