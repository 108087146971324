<template>
    <BaseFileSelect isClear  @files="onFileSelection($event)" :accept="accept" :disabled="disabled">
                {{ label }}
              </BaseFileSelect>
</template>
<script>
import actionMixin from './header-actions-mixin.js';
import BaseFileSelect from '@/components/fileUpload/BaseFileSelect.vue';

export default {
  mixins: [actionMixin],
  props: {
    label: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false
    },  
    accept: {
      type: String,
      default: "image/png, image/jpeg, .pdf"
    },        
  },
  methods: {
    onFileSelection(files) {
      this.emitAction(files);
    },
  },  
  components: {
    BaseFileSelect,
  }
}
</script>
