<template>

  <div>
    <PageHeaderTitleNavigation 
      :title="dynamicTitle"
      @action-PDF_DOCUMENT_UPLOAD="dropFiles"
      @action-RE_SCAN_ALL_ACTION_VORSCHLAGE="rescannAllAction(true)"
      @action-RE_SCAN_ALL_ACTION="rescannAllAction(false)"
    />

    <DragnDropArea hoverText="Datei hier ablegen" @files="dropFiles">
      <BaseFilter 
        ref="mrMoneyFilterRef"
        filterId="57ca8ee5-d665-4092-be06-fad7ef0a48b76"
        title="Liste filtern" 
        :metadata="filterMetadata" 
        :defaultOptions="defaultOptionsData"
        @onFilter="filterForm($event)">
      </BaseFilter>

      <div class="box__container" tid="3a71e665-4f85-42f6-949c-2bf3ed6674f8">

        <big-table 
            v-if="!loading && tableRows.length"
            :headers="tableHeadersForBackend" 
            :headerActions="bigTableHeaderActions"
            :tableId="tableId"
            title="Liste"
            :rows="tableRows" 
            :initialPageSize="minNumRows"
            :page="page"
            :sortingState="sortingState"
            @onClickHeader="onClickHeader"
            @click-link-dateiName="fetchPDF"
            @click-link-interneNr="openVertragdOverview"
            @action="executeAction"
            @headerAction-RESCANN_ALL="rescannAllAction(true)"
            >

            <template #SHOW_AND_ASSIGN="row">


              <PhArrowBendUpRight style="cursor: pointer" v-if="row.mrMoneyId" :size="24" @click="openAssignInsurance(row)"/>
          
            </template>  

            <template #dateiName="row">
              <DownloadLink
                  v-if="row.mrMoneyId"
                  downloadServicePath="/mrmoneypdf"
                  :filename="row.dateiName || 'MrMoneyPDF.pdf'"
                  :queryParams="{
                      mrMoneyId: row.mrMoneyId,
                  }"
              >

              {{ row.dateiName }}
          
              </DownloadLink>

              <span v-else>
                {{ row.dateiName }}
              </span>
            </template>    
            
            <template v-slot:comment="row">
                <InputField :value="row.comment" @change="saveComment(row, $event)"/>
            </template>        
      
        </big-table>


        <GhostLoading v-if="loading" type="table" :title="TABLE_TITLE" />
        <NoData  v-if="!loading && !tableRows.length" :title="TABLE_TITLE" />
      </div>    

      <BaseModal @onConfirmButton="removeItem()" ref="removeItemConfirmation" modalTitle="Löschen bestätigen" labelButtonConfirm="Löschen">
        Möchten Sie dieses Dokument wirklich von MrMoney PDF-Box löschen?
    </BaseModal>
    </DragnDropArea>

    <PreviewPDF ref="resizablePopup" :pdfUrl="pdfUrl" id="d11dc34a-27d7-4d3a-9c20-69d6601b54e2"></PreviewPDF> 


  </div>
    
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import CORE_TYPES from "@/store/core/types";
import MR_MONEY_TYPES from "@/store/mrmoney/types";
import { mapGetters } from "vuex";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import {SimpleAction, DownloadLinkAction} from "@/components/table2/table_util.js";
import { PhTrash, PhEnvelope, PhArrowBendUpRight} from "phosphor-vue";
import BaseModal from '@/components/core/BaseModal.vue';
import BaseFilter from "@/components/core/BaseFilter.vue";
import {uploadFileChunked} from '@/helpers/upload-helper'
import LOG_TYPES from '@/store/log/types'
import { buildMessage} from '@/helpers/log-message-helper';
import DragnDropArea from '@/components/fileUpload/DragnDropArea.vue';
import DownloadLink from '@/components/core/download/DownloadLink.vue';
import {
  PageHeaderSimpleAction,
} from '@/components/core/header-title-navigation/page-header-utils';
import {STRING_COLUMN, ACTION_COLUMN, SLOT_COLUMN, FIXED_LEFT, LINK_COLUMN} from '@/components/bigTable/BigTable.vue';
import BigTable from '@/components/bigTable/BigTable.vue';
import { downloadLinkMaker } from '@/helpers/utils-helper';
import PreviewPDF from '@/components/core/PreviewPDF.vue';

const ROOT_DOWNLOAD_SERVICE_PATH = '/download_service'

const TABLE_TITLE = 'Liste';

export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    GhostLoading,
    NoData,
    BaseModal,
    BaseFilter,
    DragnDropArea,
    DownloadLink,
    PhArrowBendUpRight,
    BigTable,
    PreviewPDF,
  },
  data: function () {
      return {
        TABLE_TITLE,
        resultCount: null,
        unsignedAttachments: [],
        signedAttachments: [],
        currentFilename: null,
        downloadErrorMessage: null,
        filtered: false,
        loading: true,
        fileList: [],
        defaultPayload: {
          "nichtZugeordneteAnzeigen":true,
          "zugeordneteAnzeigenManuell":false,
          "zugeordneteAnzeigenAuto":false,
          "radioSelection":"RADIO_THIS_YEAR",
          "polNr":"",
          "versGesellschaft":"",
          "firmaOderName":""
        },
        tableHeadersForBackend: [
            { name: "", label: "Zuweisen", key: "SHOW_AND_ASSIGN", width: 50, type: SLOT_COLUMN, position: FIXED_LEFT },
            { name: "Datei Name", label: "Datei Name", key: "dateiName", width: 150, type: SLOT_COLUMN, position: FIXED_LEFT },
            { name: "MrMoney Id", label: "MrMoney Id", key: "mrMoneyId", width: 150, type: STRING_COLUMN, visible: true },
            { name: "Interne Nr.", label: "Interne Nr.", key: "interneNr", width: 150, type: LINK_COLUMN, visible: true },
            { name: "Vers.Nr.", label: "Vers.Nr.", key: "versNr", width: 150, type: STRING_COLUMN, visible: true },
            { name: "Gesellschaft", label: "Gesellschaft", key: "gesellschaft", width: 150, type: STRING_COLUMN, visible: true },
            { name: "Vorschlag Gesellschaften", label: "Vorschlag Gesellschaften", key: "vorschlagGesellschaften", width: 150, type: STRING_COLUMN, visible: true },
            { name: "Gesell. Url", label: "Gesell. Url", key: "gesellUrl", width: 150, type: STRING_COLUMN, visible: true },
            { name: "Kategorie", label: "Kategorie", key: "kategorie", width: 150, type: STRING_COLUMN, visible: true },
            { name: "Vorschlag Kat.", label: "Vorschlag Kat.", key: "vorschlagKat", width: 150, type: STRING_COLUMN, visible: true },
            { name: "Firma", label: "Firma", key: "firma", width: 150, type: STRING_COLUMN, visible: true },
            { name: "Nachname", label: "Nachname", key: "nachname", width: 150, type: STRING_COLUMN, visible: true },
            { name: "Vorname", label: "Vorname", key: "vorname", width: 150, type: STRING_COLUMN, visible: true },
            { name: "Schaden", label: "Schaden", key: "schaden", width: 150, type: STRING_COLUMN, visible: true },
            { name: "MrMoney Status", label: "MrMoney Status", key: "mrMoneyStatus", width: 150, type: STRING_COLUMN, visible: true },
            { name: "auto zugeordnet", label: "auto zugeordnet", key: "autoZugeordnet", width: 150, type: STRING_COLUMN, visible: true },
            { name: "zugeordnet von", label: "zugeordnet von", key: "zugeordnetVon", width: 150, type: STRING_COLUMN, visible: true },
            { name: "Datum zugeordnet", label: "Datum zugeordnet", key: "datumZugeordnet", width: 150, type: STRING_COLUMN, visible: true },
            { name: "Referenz", label: "Referenz", key: "referenceNumber", width: 150, type: STRING_COLUMN, visible: true },
            { name: "KFZ", label: "KFZ", key: "licensePlateNumber", width: 150, type: STRING_COLUMN, visible: true },
            { name: "Ursprung", label: "Ursprung", key: "ursprung", width: 150, type: STRING_COLUMN, visible: true },
            { name: "Unbekannte kennungen", label: "Unbekannte kennungen", key: "unknownVuNrs", width: 150, type: STRING_COLUMN, visible: true },
            { name: "Kundennr", label: "Kundennr", key: "kundennr", width: 150, type: STRING_COLUMN, visible: true },
            { name: "Aktionen", label: "Aktionen", key: "actions", width: 120, type: ACTION_COLUMN },
        ],
        page: 0,
        minNumRows: 200,
        sortingState: { key: "customerName", direction: "desc" },    
        pdfUrl: '',
        tableId: '3774b230-3f3d-44eb-980f-17253a165bf5',  
      }
  },
  computed: {
    ...mapGetters({
      listing: MR_MONEY_TYPES.GETTERS.LISTING,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      selectedRow: MR_MONEY_TYPES.GETTERS.SELECTED_ROW,
    }),
    bigTableHeaderActions(){
      const actions = [];
      actions.push(PageHeaderSimpleAction('RESCANN_ALL', 'Erneut analysieren und autom. zuordnen.'));
      return actions;
    },
    canSeeSpecialColumns() {
      //return this.hasRoles(VIEW_ROLES.VIEW_BROKER_AS_INTERN);
      return true;
    },
    dynamicTitle() {
      let result = "Mr. Money PDF-Box";

      if (this.tableRows?.length) {
          result += ` | ${this.tableRows.length} Anzahl der angezeigten Dokumente`
      }

      return result;

    },    
    tableRows () {
      if (!this.listing?.length ) {
        return [];
      }

      // const fullActions = (row) => [
      //     SimpleAction("REMOVE_ITEM", PhTrash, "Löschen"),
      //     DownloadLinkAction("DOWNLOAD", "PhFile", "Anzeigen", row.dateiName || 'MrMoneyPDF.pdf', '/mrmoneypdf', { mrMoneyId: row.mrMoneyId, }),
      //     SimpleAction("ZUWEISEN", PhEnvelope, "Zuweisen"),
      // ];

      const fullActions = (row) => {
        const actions = [
            SimpleAction("REMOVE_ITEM", PhTrash, "Löschen")
        ];

        if (row.mrMoneyId != null) { // Checks if mrMoneyId is neither null nor undefined
            actions.push(
                DownloadLinkAction("DOWNLOAD", "PhFile", "Anzeigen", row.dateiName || 'MrMoneyPDF.pdf', '/mrmoneypdf', { mrMoneyId: row.mrMoneyId }),
                SimpleAction("ZUWEISEN", PhEnvelope, "Zuweisen")
            );
        }

        return actions;
     };
      



      return this.listing?.map(data => ({
          actions: fullActions(data),
          ...data,
      }));




    },
    defaultOptionsData() {
      return {
        nichtZugeordneteAnzeigen: {
          value: true,
        },
        radioSelection: {
          value: "RADIO_THIS_YEAR",
        },                    
      }; 
    }, 
    filterMetadata() {
         return [
          {
              type: "group",
              key: "allgemein",
              label: "Allgemein",
              menuItems: [
                {
                  type: 'text',
                  key: 'polNr',
                  label: 'Versicherungsnummer',
                },
                {
                  type: "text",
                  key: "versGesellschaft",
                  label: "Gesellschaft",
                },         
                {
                  type: "text",
                  key: "firmaOderName",
                  label: "Firma / Nachname",
                },                         
              ],
            },             
            {
              type: "group",
              key: "status",
              label: "Status",
              menuItems: [
                {
                  type: 'default',
                  key: 'nichtZugeordneteAnzeigen',
                  label: 'Nicht zugeordnete Dokumente anzeigen',
                },
                {
                  type: "default",
                  key: "zugeordneteAnzeigenManuell",
                  label: "Manuell zugeordnete Dokumente anzeigen",
                },         
                {
                  type: "default",
                  key: "zugeordneteAnzeigenAuto",
                  label: "Automatisch zugeordnete Dokumente anzeigen",
                },                         
              ],
            },     
            {
              type: 'group',
              key: 'schonZugeordneteDokumente',
              label: 'bereits zugeordnete Dokumente',
              menuItems: [
                {
                  type: 'default',
                  key: 'RADIO_THIS_YEAR',
                  label: 'von diesem Jahr',
                },
                {
                  type: "integer",
                  key: "RADIO_LAST_MONTH",
                  label: "Die letzen Monate",
                },         
                {
                  type: "dateRange",
                  key: "RADIO_BETWEEN",
                  label: "Zwischen",
                },                         
              ],           
             },               
          ];  
    },
  },
  mounted() {
    this.retrieveData(this.defaultPayload);  
  },
  methods: {
    onPageChange(event) {
      this.page = event;
    },     
    executeAction(actionData) {
      switch (actionData?.key) {
          case "REMOVE_ITEM":
            this.confirmItemRemoval(actionData?.row);
            break;
          case "ZUWEISEN":
              this.openAssignInsurance(actionData?.row);
              break;
      
          default:
              break;
      }
    },       
    fetchPDF(row) {
            const fileName = row?.dateiName;

            const queryParams = {
              mrMoneyId: row?.mrMoneyId,
            };

            const downloadServicePath="/mrmoneypdf";

            const url = downloadLinkMaker(this.$store.getters, `${ROOT_DOWNLOAD_SERVICE_PATH}${downloadServicePath}`, fileName, queryParams);
            this.pdfUrl = url;
            this.$refs.resizablePopup.showPopup();

    },    
    onClickHeader(headerMetadata) {
      this.sortingState = {key: headerMetadata?.header?.key, direction: headerMetadata?.sortingState?.direction };
      // this.$store.commit(BIPRO_TYPES.MUTATIONS.SAVE_SORTING, this.sortingState);
    },    
    openAssignInsurance(row) {
      this.$router.push({
        path: "/intern/mrmoney/list/assign_insurance", 
        query: {
          id: row?.mrMoneyId, 
          insuranceId: row?.interneNr, 
          prfx: "pdfBox",
          isMrMoney: true,
        }
      });
    },
    generateParams(filterConfig) {
      const nichtZugeordneteAnzeigen = filterConfig.find(fc => fc.key=="nichtZugeordneteAnzeigen")?.value || false;
      const zugeordneteAnzeigenManuell = filterConfig.find(fc => fc.key=="zugeordneteAnzeigenManuell")?.value || false;
      const zugeordneteAnzeigenAuto = filterConfig.find(fc => fc.key=="zugeordneteAnzeigenAuto")?.value || false;

      const schonZugeordneteDokumente =  filterConfig.find(fc => fc.group=="schonZugeordneteDokumente");
      const radioSelection =  schonZugeordneteDokumente?.key || "RADIO_THIS_YEAR";

      const polNr =  filterConfig.find(fc => fc.key=="polNr")?.value || "";
      const versGesellschaft =  filterConfig.find(fc => fc.key=="versGesellschaft")?.value || "";
      const firmaOderName =  filterConfig.find(fc => fc.key=="firmaOderName")?.value || "";

      const payload =  {
          nichtZugeordneteAnzeigen, 
          zugeordneteAnzeigenManuell, 
          zugeordneteAnzeigenAuto, 
          radioSelection,
          polNr,
          versGesellschaft,
          firmaOderName
        };

      if (schonZugeordneteDokumente?.key == "RADIO_BETWEEN") {
        payload.von = schonZugeordneteDokumente?.value?.find(v => v.key == 'min')?.value;
        payload.bis = schonZugeordneteDokumente?.value?.find(v => v.key == 'max')?.value;
      }       
        
      if (schonZugeordneteDokumente?.key == "RADIO_LAST_MONTH") {
        payload.monate = schonZugeordneteDokumente?.value;
      }

      return payload;
    }, 
    confirmItemRemoval(row) {
      this.$store.commit(MR_MONEY_TYPES.MUTATIONS.SELECTED_ROW, row);
      this.$refs.removeItemConfirmation.open();
    },
    removeItem() {
      this.$store.dispatch(MR_MONEY_TYPES.ACTIONS.TRASH_PDF, {mrMoneyId: this.selectedRow?.mrMoneyId}).then((response) => {
        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.value, response.key));
        this.retrieveData(this.defaultPayload);  
      }); 
    },
    retrieveData(payload) {


      this.loading = true;

      this.$store.dispatch(
              MR_MONEY_TYPES.ACTIONS.GET_LISTING, payload
            ).then(() => {
                this.loading = false;
            });  

    },
    filterForm(filterConfig) {
      this.retrieveData(this.generateParams(filterConfig));
    },
    rescannAllAction(vorschlageZuordnen) {
      const filterConfig = this.$refs.mrMoneyFilterRef.getFilterConfig();
      this.rescannAll(this.generateParams(filterConfig), vorschlageZuordnen);
    },
    dropFiles(files) {
      this.fileList = files;
      this.doUpload();
    },   
    doUpload() {
      this.fileList.forEach(file => {

        uploadFileChunked(file).then((id) => {
          
          this.loading = true;

          this.$store.dispatch(
              MR_MONEY_TYPES.ACTIONS.UPLOAD_AND_ANALYSE_PDF, {fileId: id}
            ).then((response) => {
                this.loading = false;
                response?.forEach(r => {
                  this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(r.value, r.key))
                });

                
            }); 

        }).catch(error => {
          this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Die Datei kann nicht hochgeladen werden.', 'danger'));
        })

      })
    },     
    rescannAll(payload, vorschlageZuordnen) {
      this.loading = true; 
      
      this.$store.dispatch(
              MR_MONEY_TYPES.ACTIONS.RESCANN_ALL, {...payload, mrMoneyIds: this.listing.map(l => l.mrMoneyId), vorschlageZuordnen}
            ).then((response) => {
                this.loading = false;
                response?.forEach(r => {
                  this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(r.value, r.key))
                });

                
            });       
    },
    openVertragdOverview(row) {
      if(row?.kundennr && row?.interneNr) {
        this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, 
          { customerId: row?.kundennr, insurances: true, path: `/home/versicherungen/insurance-group/overview/${row?.interneNr}` });
      } else {
        this.$router.push(`/home/versicherungen/insurance-group/overview/${row?.interneNr}`);
      }
    },      
  },
  async beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu Mr. Money Übersicht', 
      fullPath: from.fullPath,
      breadcrumb: to?.meta?.breadcrumb?.label,
    });

    next();
  },


    
}
</script>

<style scoped>

.object-container {
  position: relative;
  min-width: 100%;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  padding-top: 56.25%; /* Aspect ratio (example: 16:9) */
  overflow: hidden;
}

.object-container object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
</style>