import { render, staticRenderFns } from "./dataHeader.vue?vue&type=template&id=a7a4c108&scoped=true&"
import script from "./dataHeader.vue?vue&type=script&lang=js&"
export * from "./dataHeader.vue?vue&type=script&lang=js&"
import style0 from "./dataHeader.vue?vue&type=style&index=0&id=a7a4c108&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a7a4c108",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("P:\\workspaces\\fk0\\fc\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a7a4c108')) {
      api.createRecord('a7a4c108', component.options)
    } else {
      api.reload('a7a4c108', component.options)
    }
    module.hot.accept("./dataHeader.vue?vue&type=template&id=a7a4c108&scoped=true&", function () {
      api.rerender('a7a4c108', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/cms/parts/dataHeader.vue"
export default component.exports