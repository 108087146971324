<template>
    <div class="sortable-table__container">
        <PageHeaderTitleNavigation 
            v-if="title || showContextMenu" 
            isSecondary
            asPrimaryOnly
            :actions="headerActionsIntern"
            @action="handleHeaderAction"
        >
            <template #title>
                <slot name="table_title" v-if="!$slots.customTableTitle">{{title}}</slot>
                <slot name="customTableTitle" v-else></slot>
            </template>

            <template #action-TABLE__EXPORT-BUTTONS>
                <ExportButtons 
                    v-if="exportConfig"
                    :headers="visibleHeadersFlat"
                    :rows="exportedRows"
                    :config="exportConfig"
                />
            </template>

            <template #action-TABLE__DROPDOWN-MENU>
                <slot name="dropdown_menu"/>
            </template>
            
            <template v-for="hAction in headerActionsInternSlot" v-slot:[hAction.value]>
                <slot :name="hAction.key" v-bind="hAction"/>
            </template>
        </PageHeaderTitleNavigation>

        <NoData v-if="!rows.length" :noIcon="!noDataIcon" :content="noDataContent" />
        <ResponsiveTable v-else-if="!cardView"
            :headers="visibleHeaders"
            :rows="visibleRows"
            :scrollHorizontally="toggleHorizontalScrolling"
            :loadingCollapsed="loadingCollapsed"
            :sortableRows="sortableRows"
            @files="handleFile"
            @shownHeaders="$emit('shownHeaders', $event)"
            @allHeaders="$emit('allHeaders', $event)"
            @toggleRow="$emit('toggleRow', $event)"
            @enableHorizontalScroll="onEnableHorizontalScroll"
            @orderChanged="onOrderChanged"
        >
            <template v-for="{key, label, toSortable, smallScreenOptions, align, title} in visibleHeadersFlat" v-slot:[`header_${key}`]="{cardView}">
                <div :key="key"
                    @click="() => !cardView && toSortable && onSort(key)"
                    :class="{'header': true, clickable: !cardView && !!toSortable}"
                    :style="{'justify-content': cardView ? 'start' : justifyAlign[align]}"
                    :tid="_generateTidFromString(tableId + ':' + (key || label))"
                >
                    <slot v-if="label == null" :name="'header_' + key" v-bind="{key}"/>
                    <div v-else-if="thHoverDisabled" class="header_label" :style="{'-webkit-line-clamp': maxHeaderLines}">
                        <span :class="{ 'hide-on-small-screen': smallScreenOptions && smallScreenOptions.hideHeaderLabel, }">{{label}}</span>
                    </div>
                    <div v-else class="header_label" :style="{'-webkit-line-clamp': maxHeaderLines}" :title="title || label">
                        <span :class="{ 'hide-on-small-screen': smallScreenOptions && smallScreenOptions.hideHeaderLabel, }">{{label}}</span>
                    </div>
                    <span v-if="!cardView && toSortable && key == sortConfig.key" class="order_button ml-1">
                        <PhCaretUp :size="20" v-if="sortConfig.ascending"/>
                        <PhCaretDown :size="20" v-else/>
                    </span>
                </div>
            </template>
            <template v-for="{key, rowElement, isLink, isLinkIf, footer, colorFn, props} in visibleHeadersFlat" v-slot:[key]="{row, id, collapsed}">
                <template v-if="row.__FOOTER__">
                    <component v-if="footer"
                        :is="rowElement"
                        :key="key"
                        :column="key"
                        :row="row"
                        :tid="tableId + ':' + key + ':' + id + ':'"
                        :collapsed="collapsed"
                        v-bind="props"
                        class="footer"
                    />
                </template>
                <template v-else>
                    <slot v-if="!rowElement" :name="key" v-bind="{row, id, collapsed}"/>
                    <a v-else-if="isRowLink(row, isLink, isLinkIf)" :key="key" @click="clickCell(row, key)"
                        :tid="_generateTidFromString(tableId + ':' + key + ':' + id)"
                    >
                        <component
                            :is="rowElement"
                            :key="key"
                            :column="key"
                            :row="row"
                            :tid="tableId + ':' + key + ':' + id + ':'"
                            :collapsed="collapsed"
                            v-bind="props"
                            class="cell"
                        />
                    </a>
                    <!-- @action: only non-Slot, non-Link columns should ever be actions -->
                    <component v-else
                        :is="rowElement"
                        :key="key"
                        :column="key"
                        :row="row"
                        :tid="tableId + ':' + key + ':' + id + ':'"
                        :collapsed="collapsed"
                        v-bind="props"
                        @action="onAction"
                        class="cell"
                        :class="colorFn && colorFn(row)"
                    />
                </template>
            </template>
            <template v-slot:bottom__table>
              <div class="weitere___link" v-if="(headersSize || pageSize) && showWeiterLink">
                <a @click="navigateToCard()">Weitere...</a>
              </div>
            </template>            
        </ResponsiveTable>

        <SortableList v-else :items="visibleRows" @orderChanged="onOrderChanged">
            <div class="card-container" :data-sortable-container="sortableRows">
                <div
                    v-for="{acceptsFile, id, row, loadingCollapsed} in visibleRows"
                    :key="id"
                    :style="{width: cardWidth + 'px'}"
                    :data-sortable-item="sortableRows && !row.__FOOTER__"
                >
                    <TableCard
                        :topHeaders="visibleHeadersFlatComputed"
                        :loadingCollapsed="loadingCollapsed"
                        :row="row"
                        :acceptsFile="acceptsFile"
                        @files="handleFile"
                    >
                        <template slot="_sortable_handler">
                            <div v-if="sortableRows && !row.__FOOTER__" class="_sortable-handler" data-sortable-item-handler>
                                <PhList :size="16"/>
                            </div>
                        </template>
                        <template v-for="{key, label, smallScreenOptions, title} in visibleHeadersFlatComputed" :slot="'header_' + key">
                            <div :key="key"
                                class="header"
                                :tid="_generateTidFromString(tableId + ':' + (key || label))"
                            >
                                <slot v-if="label == null" :name="'header_' + key" v-bind="{key}"/>
                                <div v-else class="header_label" :style="{'-webkit-line-clamp': maxHeaderLines}" :title="title || label">
                                    <span :class="{ 'hide-on-small-screen': smallScreenOptions && smallScreenOptions.hideHeaderLabel }">{{label}}</span>
                                </div>
                            </div>
                        </template>
                        <template v-for="{key, rowElement, isLink, isLinkIf, colorFn, props} in visibleHeadersFlatComputed" :slot="key">
                            <template>
                                <slot v-if="!rowElement" :name="key" v-bind="{row, id}"/>
                                <a v-else-if="isRowLink(row, isLink, isLinkIf)" :key="key" @click="clickCell(row, key)"
                                    :tid="_generateTidFromString(tableId + ':' + key + ':' + id)"
                                >
                                    <component
                                        :is="rowElement"
                                        :key="key"
                                        :column="key"
                                        :row="row"
                                        :tid="tableId + ':' + key + ':' + id + ':'"
                                        v-bind="props"
                                        class="cell"
                                    />
                                </a>
                                <!-- @action: only non-Slot, non-Link columns should ever be actions -->
                                <component v-else
                                    :is="rowElement"
                                    :key="key"
                                    :column="key"
                                    :row="row"
                                    :tid="tableId + ':' + key + ':' + id + ':'"
                                    v-bind="props"
                                    @action="onAction"
                                    class="cell"
                                    :class="colorFn && colorFn(row)"
                                />
                            </template>
                        </template>
                    </TableCard>
                    <div class="weitere___link" v-if="headersSize && showWeiterLink">
                      <a @click="navigateToCard()">Weitere...</a>
                    </div>                    
                </div>
            </div>
        </SortableList>

        <ModalColumnConfig 
            ref="columnsConfig"
            :title="title ? (title + ' - Einstellungen') : 'Einstellungen'"
            :tableId="tableId"
            :tableHeaders="defaultHeaders"
            :lockedColumns="fakeLockedColumns"
            @close="onCloseColumnsConfig"
            @onFinishConfig="onFinishColumnsConfig"
            @onRestoreDefault="onFinishColumnsConfig"
        />
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import FC_CONFIG from '@/configs/fcConfig.js'
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";

import SEARCH_CONFIGS_TYPES from '@/store/searchConfigs/types'
import FC_CONFIG_TYPES from '@/store/fcConfig/types';

import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import ExportButtons from './ExportButtons.vue';
import ResponsiveTable from './ResponsiveTable.vue';
import TableCard from './TableCard.vue';
import ModalColumnConfig from '@/components/table/ModalColumnConfig.vue'
import { PhCaretUp, PhCaretDown, PhPencilLine, PhList } from 'phosphor-vue';
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import BaseContextMenu from '@/components/core/BaseContextMenu.vue';
import ContextMenuGroup from '@/components/core/base-context-menu/ContextMenuGroup.vue';
import ContextMenuItem from '@/components/core/base-context-menu/ContextMenuItem.vue';
import ScreenSizeMixin from '@/mixins/screen-size';
import SortableList from '@/components/core/SortableList.vue';
import NoData from '@/components/core/NoData.vue';

import { PageHeaderSimpleAction, PageHeaderSlotAction, } from '@/components/core/header-title-navigation/page-header-utils';

const TABLE_ACTIONS_PREFIX = 'TABLE__';

export default {
    mixins: [InteractiveHelpCommonsMixin, ScreenSizeMixin],
    components: {
        PageHeaderTitleNavigation,
        ExportButtons,
        ResponsiveTable,
        TableCard,
        ModalColumnConfig,
        PhCaretUp,
        PhCaretDown,
        PhPencilLine,
        PhList,
        InputToggleSwitch,
        BaseContextMenu,
        ContextMenuGroup,
        ContextMenuItem,
        SortableList,
        NoData,
    },
    props: {
        tableId: {
            // tableId must be unique. This Id is being used to save the column config into backend
            // a UUID randomly generated is enough. https://www.uuidgenerator.net/version4 or any other generator.
            type: String,
        },
        title: {
            type: String,
            default: "",
        },
        headers: { 
            type: Object,
        },
        rows: { 
            type: Array,
            default: () => [],
        },
        page: { // will be ignored if pageSize <= 0
            type: Number,
            default: 0,
        },
        pageSize: {
            type: Number,
            default: 0, // <= 0 means no limit
        },
        // This property is only used when the "cardView" property is true
        // It will be ignored if headersSize <= 0
        headersSize: {
          type: Number,
          default: 0,
        },    
        showWeiterLink: {
            type: Boolean,
            default: false,
        },
        // if the data is sorted: {key: string, ascending: boolean}
        sortedManually: {
            type: Object,
            default: null,
        },
        exportConfig: {
            type: Object,
            default: () => null,
        },
        scrollHorizontally: {
            type: Boolean,
            default: false,
        },
        // don't set cardView and scrollHorizontally to true at the same time
        cardView: {
            type: Boolean,
            default: false,
        },
        maxHeaderLines: {
            type: Number,
            default: 1,
        },
        enableToggleHorizontalScrollingConfig: {
            type: Boolean,
            default: false,
        },
        headerActions: {
            type: Array,
            default: () => [],
        },
        loadingCollapsed: { 
            type: Object,
            default: () => {},
        },
        sortableRows: {
            type: Boolean,
            default: false,
        },
        showColumnsConfigEvenNoTitle: { // TODO improve this prop, maybe should show the edit column action for all table with id, and add a prop to hide the edit column action whether necessary, for example: on dashboard tables
            type: Boolean,
            default: false,
        },
        thHoverDisabled: {
            type: Boolean,
            default: true,
        },
        noDataIcon: {
            type: Boolean,
            default: false,
        },
        noDataContent: {
            type: String,
        },

    },
    data() {
        return {
            sortConfigInternal: {
                key: null,
                ascending: true,
            },
            justifyAlign: {
                left: "start",
                centered: "center",
                right: "end",
            },
            toggleHorizontalScrolling: false,
            horizontalScrollingConfig: this.enableToggleHorizontalScrollingConfig,
            canEnableHorizontalScroll: false,
        };
    },
    computed: {
        ...mapGetters({
            storedColumnsConfig: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_TABLE_COLUMN,
            searchConfigs: SEARCH_CONFIGS_TYPES.GETTERS.TABLE_SORT_OPTIONS,
            isConfigurationReadOnly: FC_CONFIG_TYPES.GETTERS.IS_CONFIGURATION_READ_ONLY,
        }),
        showOpenColumnsConfig() {
            const hasTableId = !!this.tableId;
            return (this.title && hasTableId || this.showColumnsConfigEvenNoTitle && hasTableId) && !this.isConfigurationReadOnly;
        },
        showContextMenu() {
            return this.showOpenColumnsConfig || !!this.$slots.dropdown_menu || this.exportConfig || this.horizontalScrollingConfig || this.hasHeaderActions;
        },
        headerActionsIntern() {
            if (!this.showContextMenu) {
                return [];
            }

            return [
                PageHeaderSimpleAction(`${TABLE_ACTIONS_PREFIX}CONFIG-EDIT`, 'Spalten anpassen')
                    .withVisible(() => this.showOpenColumnsConfig),

                PageHeaderSimpleAction(`${TABLE_ACTIONS_PREFIX}TOGGLE-HORIZONTAL-SCROLLING`, !this.toggleHorizontalScrolling ? 'Horizontales Scrollen aktivieren' : 'Horizontales Scrollen deaktivieren')
                    .withVisible(() => this.horizontalScrollingConfig),

                ...(this.headerActions || []),

                PageHeaderSlotAction(`${TABLE_ACTIONS_PREFIX}EXPORT-BUTTONS`)
                    .withAsGroup()
                    .withVisible(() => this.exportConfig),

                PageHeaderSlotAction(`${TABLE_ACTIONS_PREFIX}DROPDOWN-MENU`)
                    .withAsGroup()
                    .withVisible(() => this.$slots.dropdown_menu),
            ];
        },
        headerActionsInternSlot() {
            return this.headerActionsIntern.filter(ha => this.$slots[ha.actionKey]).map(ha => ({key: ha.actionKey, value: 'action-'+ha.actionKey}))
        },
        configuredHeaders() {
            // if columns have been configured in this instance, use that configuration
            let columnsConfig = null;
            // check if a saved configuration can be found
            if (this.tableId && this.storedColumnsConfig) {
                let savedConfig = this.storedColumnsConfig[this.tableId]?.content;
                if (savedConfig) {
                    columnsConfig = JSON.parse(savedConfig);
                }
            }
            if (columnsConfig) {
                if (typeof(columnsConfig) == "object")
                    columnsConfig = Object.values(columnsConfig);
                // sort out locked headers because 'storedColumnsConfig' may include them
                columnsConfig = columnsConfig
                .filter(conf => {   // sorts out headers that have since been locked or don't exist anymore
                    let header = this.headers.center.find(header => header.key == conf.key);
                    return header && !header.locked;
                });
                let i = 0;
                return {
                    ...this.headers,
                    center: this.headers.center.map(header => {
                        if (!header.locked && columnsConfig.find(c => c.key == header.key)) { // don't change headers that haven't been configured before
                            header = {
                                ...this.getHeader(columnsConfig[i].key),
                                visible: columnsConfig[i].visible,
                            };
                            i++;
                        }
                        return header;
                    }),
                };
            }
            // without explicit configuration, return headers as default
            return this.headers;
        },
        defaultHeaders() {
            return Object.values(this.headers)
                .flat()
                .filter(header => header.label);
        },
        visibleHeaders() {
            return {
                lockedLeft: this.configuredHeaders.lockedLeft.filter(header => header.visible),
                center: this.configuredHeaders.center.filter(header => header.visible),
                lockedRight: this.configuredHeaders.lockedRight.filter(header => header.visible),
            };
        },
        visibleHeadersFlat() {
            return Object.values(this.visibleHeaders).flat();
        },
        visibleHeadersFlatComputed() {
          if (this.headersSize) {
            return Object.values(this.visibleHeaders)
              .flat()
              .slice(0, this.headersSize);
          }
          return Object.values(this.visibleHeaders).flat();
        },        
        sortConfig() {
            return this.sortedManually || this.sortConfigInternal;
        },
        sortedRows() {
            if (this.sortedManually || !this.sortConfig.key)
                return this.rows;
            const header = this.getHeader(this.sortConfig.key);
            return this.rows.slice().sort((a, b) => {
                const sortableA = header.toSortable(a.row[this.sortConfig.key], a.row);
                const sortableB = header.toSortable(b.row[this.sortConfig.key], b.row);
                if (sortableA == sortableB)
                    return 0;
                else if (sortableA == undefined)
                    return 1
                else if (sortableB == undefined)
                    return -1
                else if (sortableA < sortableB)
                    return this.sortConfig.ascending ? -1 : 1;
                else
                    return this.sortConfig.ascending ? 1 : -1;
            });
        },
        footer() {
            if (this.visibleHeadersFlat.find(header => header.footer != null)) {
                let footer = {
                    id: "__FOOTER__",
                    row: {
                        __FOOTER__: true,
                    },
                };
                this.visibleHeadersFlat.forEach(header => {
                    if (header.footer != null) {
                        if(header.footer.fn && typeof(header.footer.fn) === "function") {
                            const reducedValue = this.rows
                                .map(row => row.row[header.key])
                                .reduce(header.footer.fn, header.footer.initialValue);
                            footer.row[header.key] = `${reducedValue} ${header.footer.symbol || ''}`;
                            if(header?.footer?.constant && typeof(header?.footer?.constant) === 'function') {
                                footer.row.constants = {[header.key] : header.footer.constant(reducedValue)};
                            }
                        }
                        if(!header.footer.fn && header?.footer?.constant) {
                            footer.row.constants = {[header.key] : header.footer.constant};
                            footer.row[header.key] = header.footer.constant;
                        }
                    }
                })
                return footer;
            }
            return null;
        },
        visibleRows() {
            let visibleRows = [];
            if (this.pageSize > 0) {
                visibleRows = this.sortedRows.slice(this.page * this.pageSize, (this.page + 1) * this.pageSize);
            } else {
                visibleRows = this.sortedRows.slice();
            }
            visibleRows = visibleRows.flatMap(row => ([
                row,
                ...(row.row.__sub_rows || []).map((subRow, i) => ({
                    row: {
                        ...subRow,
                        selectedHidden: true,
                    },
                    id: row.id + "+" + i,
                    customRowStyle: {
                        fontSize: '0.8rem',
                        border: 0,
                        ...row.customRowStyle || {},
                        ...subRow.customRowStyle || {},
                    },
                })),
            ]))
            if (this.footer)
                visibleRows.push(this.footer);
            return visibleRows;
        },
        exportedRows() {
            let rows = this.sortedRows;
            if (this.footer)
                rows = [...this.sortedRows, this.footer];
            return rows.map(row => row.row);
        },
        fakeLockedColumns() {
            const mapLockedColumn = header => ({
                key: header.key,
                neverHide: header.neverHide,
            });
            return {
                toLeft: this.headers.lockedLeft.map(mapLockedColumn),
                toRight: this.headers.lockedRight.map(mapLockedColumn),
            }
        },
        headerActionsVisible() {
            return this.headerActions?.filter(act => !act.visible || act.visible?.())
        },
        hasHeaderActions() {
            return this.headerActionsVisible?.length > 0;
        },
        headerActionsKey() {
            return this.headerActions
                .map(act => [!act.visible || act.visible?.(), !act.disabled || act.disabled?.()].join('-'))
                .join(':');
        },
        cardWidth() {
            let width = 100;
            this.visibleHeadersFlat.forEach(header => {
                if (header.minWidth > width) // avoiding NaN
                    width = header.minWidth;
            });
            return width * 2;
        },
    },
    mounted() {
        if (this.tableId) {
            this.$store.dispatch(FC_CONFIG_TYPES.ACTIONS.LOAD_FC_CONFIG, {
                configId: this.tableId,
                configType: FC_CONFIG.TABLE_COLUMN_CONFIG,
            });
        }
        this.loadCachedSortOptions();
        this.toggleHorizontalScrolling = this.scrollHorizontally;
        // important to hide the action in the table when resize to small screens and revert the state when resize to large screen
        this.horizontalScrollingConfig = this.enableToggleHorizontalScrollingConfig,
        this.$nextTick(() => {
            this.$addResizeListener((event, currentSize) => {
                this.updateHorizontalScrolling();
            });
        });
        this.updateHorizontalScrolling();
    },
    watch: {
        scrollHorizontally() {
            this.toggleHorizontalScrolling = this.scrollHorizontally;
            this.updateHorizontalScrolling();
        },
    },
    methods: {
        isRowLink(row, isLink, isLinkIf) {
            if (isLink) {
                return true
            }

            if (isLinkIf) {
                if (isLinkIf instanceof Function) {
                    return isLinkIf(row)
                } else {
                    return row[isLinkIf]
                }
            }
            
            return false
        },
        navigateToCard(row) {
            this.$emit("onNavigate", row);
        },
        updateHorizontalScrolling() {
            if(['xs','sm'].includes(this.$getCurrentSize()) || !this.canEnableHorizontalScroll) {
                this.horizontalScrollingConfig = false;
                if(this.toggleHorizontalScrolling) {
                    this.toggleHorizontalScrolling = false;
                }
            } else {
                this.horizontalScrollingConfig = this.enableToggleHorizontalScrollingConfig;
            }
        },
        onEnableHorizontalScroll(allowed) {
            this.canEnableHorizontalScroll = !!allowed;
            this.updateHorizontalScrolling();
        },
        loadCachedSortOptions() {
            if (!this.$route.query?.backAction) {
                return;
            }
            if (this.tableId) {
                const sortOptions = this.searchConfigs?.(this.tableId);
                this.sortConfigInternal.key = sortOptions?.key;
                this.sortConfigInternal.ascending = sortOptions?.sortDirection != "desc";
            }
        },
        onSort(key) {
            let config = {...this.sortConfig};
            if (config.key == key) {
                if (!config.ascending) {
                    config.ascending = true;
                } else {
                    config = {};
                }
            } else {
                config.key = key;
                config.ascending = false;
            }
            if (this.sortedManually){
                this.$emit("sort", config);
            } else {
                this.$emit("localSort", {key: config.key, sortDirection: (config.ascending ? 'asc' : 'desc') });
                this.sortConfigInternal = config;
            }
        },
        getHeader(key) {
            return Object.values(this.headers)
                .flat()
                .find(header => header.key == key);
        },
        openColumnsConfig() {
            this.$emit('isColumnsConfigOpen', true)
            this.$refs.columnsConfig.open()
        },
        onCloseColumnsConfig() {
            this.$emit('isColumnsConfigOpen', false)
        },
        onFinishColumnsConfig() {
            if (this.tableId) {
                const savedConfig = this.storedColumnsConfig[this.tableId];
                const savePayload = {
                    configId: this.tableId,
                    configType: FC_CONFIG.TABLE_COLUMN_CONFIG,
                    content: savedConfig?.content,
                    userLevel: savedConfig?.userLevel || null,
                }
                this.$emit('configColumn', savePayload);
            }
        },
        clickCell(row, columnKey) {
            //this.$emit("click-" + columnKey, row);
            this.$emit("clickCell", {columnKey, row});
        },
        onAction({key, row}) {
            this.$emit("action", {key, row});
        },
        handleFile({row, files}) {
            this.$emit('files', {
                row,
                files,
            });
        },
        handleHeaderAction({ key, value, }) {
            switch (key) {
                case 'TABLE__CONFIG-EDIT':
                    this.openColumnsConfig();
                    break;
                case 'TABLE__TOGGLE-HORIZONTAL-SCROLLING':
                    this.toggleHorizontalScrolling = !this.toggleHorizontalScrolling;
                    break;
                default:
                    this.$emit('headerAction', { key, value, });
                    this.$emit(`headerAction-${key}`, value);
                    break;
            }
        },
        onOrderChanged(orderedRows) {
            let ordered = [];
            if (this.pageSize > 0) {
                const pageStartIndex = this.page * this.pageSize;
                const pageEndIndex = (this.page + 1) * this.pageSize;
                const prevRows = this.sortedRows.slice(0, pageStartIndex);
                const nextRows = this.sortedRows.slice(pageEndIndex, this.sortedRows.length);
                ordered = [ ...prevRows, ...orderedRows, ...nextRows, ];
            } else {
                ordered = [ ...orderedRows, ];
            }

            this.$emit('orderChanged', ordered.map(row => row.row));
        },
    },
}
</script>
<style scoped>
.header {
    padding-right: 0.25rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.header_label {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    max-width: 400px;
}
.order_button {
    display: inline-block;
}
.footer {
    font-weight: bold;
}
.top-to-title {
    transform: translateY(-28px);
    height: 4px;
}

.table--header-actions {
    animation: header-actions-animation 0.8s both;
}

.header-actions-animation-leave-active {
    display: none !important;
}

@keyframes header-actions-animation {
    0%,
    100% {
        transform: rotate(0) scale(1);
        transform-origin: center center;
    }
    15% {
        transform: rotate(8deg) scale(1.2);
    }
    30% {
        transform: rotate(-8deg) scale(1.2);
    }
    45% {
        transform: rotate(6deg) scale(1.2);
    }
    60% {
        transform: rotate(-6deg) scale(1.2);
    }
    75% {
        transform: rotate(0);
    }
}

.card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px
}
.card-container >div {
    flex-grow: 1;
    border-radius: 8px;
    padding-top: 4px;
    box-shadow: 0 -3px 0px rgba(1, 1, 1, 0.05);
}

.box__container .card-container > div {
    box-shadow: none;
    padding-top: 0;
}

._sortable-handler {
    width: 24px;
}

.weitere___link {
  border-top: 1px solid #c4c4c4;
  display: flex;
  justify-content: flex-end;
  font-size: 16px;
}

@media screen and (max-width: 480px) {
    .header_label {
        max-width: 300px;
    }
}

</style>
