<template>
  <div class="label ljahre">
    <div class="result">{{getJahre}}</div>
    <div class="truelabel">Jahre</div>
  </div>
</template>

<script>
//import store from "./../../store.js";
export default {
  name: "LJahre",
  computed: {
    getJahre() {
      return this.$store.getters.getJahre;
    }
  },
};
</script>

<style scoped>
.ljahre {
  grid-area: ljahre;
}

</style>