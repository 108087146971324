<template>
  <div>
    <div v-for="(fieldsRow, index) in form.fieldDefinitions" :key="index">
      <AnlagezieleZeile
        :count="index + 1"
        :antragData="antragData"
        :disabled="disabled"
        :fields="fieldsRow"
        @change="processEvent($event)"
        @save="processSave($event)"/>
    </div>
    <hr />
    <div class="row justify-content-between sums">
      <div class="col-12 col-lg-6 px-1">
        <span class="mr-2">{{config.summeEinmal.label}}:</span>
        <span>{{ summeEinmalig }}</span>
      </div>
      <div class="col-12 col-lg-6 px-1">
        <span class="mr-2">{{config.summeRatierlich.label}}:</span>
        <span>{{ summeRatierlich }}</span>
      </div>
    </div>

    <div v-if="hasExtraFields">
      <hr />
      <InputToggleSwitch
        v-model="form.checkCredit"
        :label="config.checkCredit.label"
        inLineLabel
        suppressValidationMessage
        @input="processSave({type: 'save', fieldId: config.checkCredit.id, value: $event})"
        />
      <InputField
        v-if="form.checkCredit"
        :label="config.creditBetrag.label"
        v-model="form.creditBetrag"
        type="currency"
        :disabled="disabled"
        @change="processSave({type: 'save', fieldId: config.creditBetrag.id, value: $event})">
      </InputField>
      <InputTextArea
        :label="config.bemerkung.label"
        v-model="form.bemerkung"
        @change="processSave({type: 'save', fieldId: config.bemerkung.id, value: $event})">
      </InputTextArea>
    </div>
  </div>
</template>

<script>
import AnlagezieleZeile from '@/components/anlageziele/AnlagezieleZeileForm.vue';
import InputField from '@/components/core/forms/InputField.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import { formatNumber } from '@/helpers/number-formatter.js'

export default {
    props: {
        id: {
            type : String
        },
        label: {
            type: String,
        },
        values: {
            type: Array,
            default: [],
        },
        config: {
            type: Object
        },
        hasExtraFields: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        antragData: {
            type: Object
        }
    },
    components: {
        AnlagezieleZeile,
        InputField,
        InputTextArea,
        InputToggleSwitch,
    },
    mounted() {
        this.createForm(this.chunkArray(this.config.positionFields, 6 ));
    },
    data() {
        return {
            form: {},
            sumsEinmal: {
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
            },
            sumsRatierlich: {
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
            },
        };
    },
    computed: {
        summeEinmalig() {
            const summ = this.sumsEinmal && 
                (Object.values(this.sumsEinmal).map(value => Number.parseFloat(value)).reduce((acc, curr) => acc += curr, 0)) || 0;
            return formatNumber(summ, 2);
        },
        summeRatierlich() {
            const summ = this.sumsRatierlich && 
                (Object.values(this.sumsRatierlich).map(value => Number.parseFloat(value)).reduce((acc, curr) => acc += curr, 0) ) || 0;
            return formatNumber(summ, 2);
        },
    },
    methods: {
        createForm(fieldDefinitions = []) {
            this.form = {
                checkCredit: false,
                creditBetrag: '',
                bemerkung: '',
                summeEinmal: 0,
                summeRatierlich: 0,
                fieldDefinitions
            };
            this.patchForm();
        },
        patchForm() {
            if (this.antragData) {
                this.form.checkCredit = this.antragData[this.config.checkCredit.id];
                this.form.creditBetrag = this.antragData[this.config.creditBetrag.id];
                this.form.bemerkung = this.antragData[this.config.bemerkung.id];
            }
        },
        processEvent (event){
            this.updateSum(event);
        },
        processSave(event){
            this.updateSum(event);
            this.$emit(event.type, {[event.fieldId]: event.value });
        },
        updateSum(event) {
            if (event && event.type) {
                event.value = event.value || 0;
                if(event.fieldId.includes('einmal')) {
                    this.$set(this.sumsEinmal, event.count, event.value);
                } else if(event.fieldId.includes('ratierlich')) {
                    this.$set(this.sumsRatierlich, event.count, event.value);
                }
            }
        },
        chunkArray(array, size, acc = []) {
            if(array.length > size) {
                return this.chunkArray(array.slice(size), size,[...acc, array.slice(0, size)]);
            } else {
                return [...acc, array]
            }
        }
    },
}
</script>

<style>
.sums {
  font-weight: bold;
}
</style>