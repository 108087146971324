var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _c("BaseCollapsable", {
        ref: "baseCollapsable",
        attrs: { showSection: _vm.showExpanded },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _c("span", { staticClass: "box__title" }, [
                  _vm._v("Hinweise / Fehler ")
                ]),
                _vm.hasHints
                  ? _c("span", [
                      _vm._v(
                        " |  (" +
                          _vm._s(_vm.hints.length) +
                          " Hinweis" +
                          _vm._s(_vm.hints.length > 1 ? "e" : "") +
                          ")"
                      )
                    ])
                  : _vm._e(),
                _vm.hasErrors
                  ? _c("span", { staticClass: "color-danger" }, [
                      _vm._v(" |  Das Formular enthält Fehler")
                    ])
                  : _vm._e()
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                !_vm.hasHints && !_vm.hasErrors
                  ? _c("div", [_vm._v(" Keine Hinweise / Fehler vorhanden. ")])
                  : _vm.hasErrors
                  ? _c(
                      "div",
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c("span", { staticClass: "font-bold" }, [
                              _vm._v("Fehler:")
                            ])
                          ])
                        ]),
                        _vm._l(_vm.errors, function(error, index) {
                          return _c("div", { key: index, staticClass: "row" }, [
                            _c(
                              "div",
                              {
                                staticClass: "col-12",
                                class: { clickable: _vm.isNavigable(error) },
                                on: {
                                  click: function($event) {
                                    return _vm.navigateTo(error)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.getDescription(error)) + " "
                                ),
                                _vm.isNavigable(error)
                                  ? _c("PhPencilLine", { attrs: { size: 20 } })
                                  : _vm._e()
                              ],
                              1
                            )
                          ])
                        })
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.hasHints
                  ? _c(
                      "div",
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c("span", { staticClass: "font-bold" }, [
                              _vm._v("Hinweise:")
                            ])
                          ])
                        ]),
                        _vm._l(_vm.hints, function(hint, index) {
                          return _c("div", { key: index, staticClass: "row" }, [
                            _c("div", {
                              staticClass: "col-12",
                              domProps: {
                                innerHTML: _vm._s(_vm.hintHtml(hint))
                              }
                            })
                          ])
                        })
                      ],
                      2
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }