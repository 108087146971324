<template>
  <div>
    <InputField
      label="Name" 
      isComponentHalfSize
      :disabled="nameDisabled"
      :value="daten.name"
      :renderDanger="!daten.name"
      @input="update('name', $event)"
    />
    <InputField
      label="Straße" 
      isComponentHalfSize
      :value="daten.strasse"
      @input="update('strasse', $event)"
    />
    <InputField
      label="Postleitzahl" 
      isComponentHalfSize
      :value="daten.plz"
      @input="update('plz', $event)"
    />
    <InputField
      label="Ort" 
      isComponentHalfSize
      :value="daten.ort"
      @input="update('ort', $event)"
    />
    <ComboBox
      label="Land" 
      isComponentHalfSize
      :sortComboboxValues="false"
      :values="comboboxSelections.countries"
      :value="daten.land"
      @input="update('land', $event)"
    />
    <InputField
      label="Postfach" 
      isComponentHalfSize
      :value="daten.postfach"
      @input="update('postfach', $event)"
    />
    <InputField
      label="Postleitzahl für Postfach" 
      isComponentHalfSize
      :value="daten.plzPostfach"
      @input="update('plzPostfach', $event)"
    />
    <InputField
      label="Beschreibung" 
      isComponentHalfSize
      :value="daten.beschreibung"
      @input="update('beschreibung', $event)"
    />
  </div>
</template>

<script>
import ComboBox from '@/components/core/forms/ComboBox.vue'
import InputField from '@/components/core/forms/InputField.vue'
import InputTextArea from '@/components/core/forms/InputTextArea.vue';

export default {
  mixins: [],
  components: {
    ComboBox,
    InputField,
    InputTextArea,
  },
  model: {
    prop: 'daten',
    event: 'daten',
  },
  props: {
    daten: {
      type: Object,
      default: () => {},
    },
    comboboxSelections: {
      type: Object,
      default: () => {},
    },
    nameDisabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    update(key, value) {
      this.$emit('daten', { ...this.daten, [key]: value })
    },
  },
}
</script>