import ANLAGEZIELE_TYPES from './types';
import ANLEGERPROFIL_TYPES from '@/store/anlegerprofil/types';
import axios from 'axios';

const config = {
  defaultSpinner: true
};

export default {
  [ANLAGEZIELE_TYPES.ACTIONS.GET_ANLAGEZIELE]({ commit, rootState }, payload) {

    const param = Object.entries(payload).map(([key, val]) => `${key}=${val}`).join('&');
    const path = '/../mrsanlageziele' + (param ? `?${param}` : '');
    axios.get(`${rootState.core.apiAddress}${path}`, config).then(response => {
      if (response.status === 200 && response.data) {
        commit(ANLAGEZIELE_TYPES.MUTATIONS.GET_ANLAGEZIELE_SUCCESS, response.data)
      }
    });
  },
  
  [ANLAGEZIELE_TYPES.ACTIONS.GET_ANLAGEZIELE_PLANEN]({ commit, rootState }, payload) {

    const param = Object.entries(payload).map(([key, val]) => `${key}=${val}`).join('&');
    const path = '/../mrsanlagezieleplanen' + (param ? `?${param}` : '');
    axios.get(`${rootState.core.apiAddress}${path}`, config).then(response => {
      if (response.status === 200 && response.data) {
        commit(ANLAGEZIELE_TYPES.MUTATIONS.GET_ANLAGEZIELE_PLANEN_SUCCESS, response.data)
      }
    });
  },
  
  [ANLAGEZIELE_TYPES.ACTIONS.SAVE_ANLAGEZIELE_PLANEN]({ rootState }, payload) {
    axios.post(`${rootState.core.apiAddress}/../mrsanlagezieleplanen`, payload, config);
  },
 
  [ANLAGEZIELE_TYPES.ACTIONS.UPDATE_POSITION]({ commit }, payload) {
    commit(ANLAGEZIELE_TYPES.MUTATIONS.SET_POSITIONS_TEMP);
    commit(ANLAGEZIELE_TYPES.MUTATIONS.UPDATE_POSITION, payload);
  },

}