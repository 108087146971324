<template>
  <div data-app-header class="base-header__container" v-resize-observer="setStyleProperties">
    <header>
      <slot />
    </header>
  </div>
</template>

<script>
export default {
  methods: {
    setStyleProperties() {
      requestAnimationFrame(() => {
        const headerBarContainerEl = this.$el;
        if(!headerBarContainerEl) return;

        const { offsetHeight } = headerBarContainerEl;
        document.documentElement.style.setProperty('--base-header-bar-height', `${offsetHeight}px`);
      });
    },
  },
  mounted() {
    this.$nextTick(() => this.setStyleProperties());
  },
}
</script>

<style scoped>
  header {
    position: relative;
    width: 100%;
    z-index: 1000;
  }

  .base-header__container {
    margin-bottom: 1em;
  }
</style>

<!-- Global Style -->
<style>
html {
  --base-header-bar-height: 136px;
}

@media (max-width: 767px) {
  html {
    --base-header-bar-height: 40px;
  }

  .base-header__container {
    box-shadow: 0 2px 8px 0px rgba(85, 85, 85, 0.5);
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1010;
  }

  .base-header__container:before {
    background-color: var(--color-top-nav-background);
    content: "";
    display: block;
    pointer-events: none;
    position: absolute;
    top: -495px;
    left: 0;
    width: 100%;
    height: 500px;
  }

  .base-header__container ~ [data-app-content] {
    margin-top: calc(var(--base-header-bar-height) + 1em);
  }
}
</style>
