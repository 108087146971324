<template>
  <div class="login-needed-container" v-if="!isBrokerOrBypass && visible">
    <div class="login-needed-message">
      <div>
        Ihre Konto-Umsätze sind nicht mehr aktuell. Sie müssen sich aus Sicherheitsgründen erneut anmelden.&nbsp;
      </div>
      <div style="white-space: nowrap;">
        <BaseButton v-if="selectedAccountsAuthNeededProviderId" @click="authenticateAccount()">Konto aktualisieren</BaseButton>
        <BaseButton v-else @click="goToAccountManagements()">Zur Kontoverwaltung</BaseButton>
        &nbsp;
        <ph-x :size="24" style="margin: -4px;min-width: max-content" @click="hideMessage()"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CORE_TYPES from '@/store/core/types'
import MULTIBANKING_TYPES from '@/store/multiBanking/types'
import BaseButton from '@/components/core/BaseButton.vue'
import { PhX } from 'phosphor-vue'

export default {
  components: {
    BaseButton,
    PhX
  },

  props: {
    shortMessageDisabled: {
      type: Boolean,
      default: false,
    },
    selectedAccounts: {
    }
  },

  computed: {
    ...mapGetters({
      isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
      accounts: MULTIBANKING_TYPES.GETTERS.ACCOUNTS,
    }),
    visible() {
      return !this.hidden && this.accounts?.filter(acc => acc.authNeeded).length > 0
    },
    selectedAccountsAuthNeededProviderId() {
      if (Array.isArray(this.selectedAccounts)) {
        return this.selectedAccounts.find(acc => acc.authNeeded)?.providerId
      } else {
        return this.selectedAccounts?.authNeeded ? this.selectedAccounts?.providerId : ''
      }
    },
  },

  data() {
    return {
      hidden: false, // this.flagsCurrent.isMultibankingLoginNeededMessageHidden
    }
  },

  methods: {
    goToAccountManagements () {
      this.$router.push({name: 'kontoverwaltung', query: { backAction: true }})
    },
    hideMessage () {
      this.hidden = true
    },
    authenticateAccount() {
      const relativePath = this.$route.path
      const callbackPath = window.location.href.substring(0, window.location.href.indexOf(relativePath)) + '/home/multibanking-account'
      this.$store.dispatch(MULTIBANKING_TYPES.ACTIONS.AUTHENTICATE_ACCOUNT, {
        callbackPath,
        providerId: this.selectedAccountsAuthNeededProviderId,
      })
    },
  },
}
</script>

<style scoped>
.login-needed-container {
  color: var(--color-box);
  background: var(--color-danger);
}
.login-needed-message {
  display: flex;
  justify-content: space-between;
  padding: .25rem .75rem;
  margin-bottom: 1rem;
  align-items: center;
}
</style>