var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "legidocs-container" },
    [
      _vm._l(_vm.getLegiDocsForAuthor, function(legiDoc, index) {
        return _c("div", { staticClass: "legidoc-container" }, [
          _c(
            "h5",
            [
              _vm._v(" " + _vm._s(legiDoc.label) + " "),
              _c("PhArrowArcLeft", {
                staticClass: "img-control",
                attrs: { size: "20" },
                on: {
                  click: function($event) {
                    return _vm.rotateCounterClockwise(index)
                  }
                }
              }),
              _c("PhArrowArcRight", {
                staticClass: "img-control",
                attrs: { size: "20" },
                on: {
                  click: function($event) {
                    return _vm.rotateClockwise(index)
                  }
                }
              }),
              _c("PhArrowSquareOut", {
                staticClass: "img-control",
                attrs: { size: "20" },
                on: {
                  click: function($event) {
                    return _vm.openImage(legiDoc)
                  }
                }
              })
            ],
            1
          ),
          _c("img", {
            staticClass: "legidoc-img",
            attrs: { src: legiDoc.img },
            on: {
              click: function($event) {
                return _vm.openImage(legiDoc)
              }
            }
          })
        ])
      }),
      _c("PreviewImage", {
        ref: "legitimationImageModal",
        attrs: {
          modalTitle: _vm.resizableModalOptions.title,
          initialHeight: _vm.resizableModalOptions.height,
          initialWidth: _vm.resizableModalOptions.width,
          startPosition: "TOP_RIGHT",
          imgUrl: _vm.resizableModalOptions.img,
          id: "444d7f03-94f9-4b9a-b7c3-b4f906206163"
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }