<template>
  <div>
    <PageHeaderTitleNavigation
      :title="$appNavigation.currentMenu.label"
      :subtitle="''"
      :actions="actions"
      @action-NEW="setFVP(null)"
      @action-SETTINGS="showSettings">
    </PageHeaderTitleNavigation>

      <BaseFilter
        title="Fondsvergleichsfilter"
        filterId="a3c7c0c6-31cd-40b8-8d1d-0440bae1e485"
        :metadata="filterMetadata"
        :configFilter="configFilter"
        :showSaveButton="true"
        :defaultOptions="filter"
        @onFilter="search($event)"
        @onFilterZurucksetzen="search(null)"
      />

    <div class="box__container">
      <Table v-if="!loading && paramList"
        tableId="da1ef4a1-2271-4732-9133-288a7d980dff"
        :title="TABLE_TITLE"
        :headers="headers"
        :rows="paramList"
        rowId="id"
        :rowsPerPage="20"
        :exportConfig="{roottext: 'Fondsvergleichsliste'}"
        sortableRows
        v-model="selectedRows"
        :headerActions="tableActions"
        :mobileConfig="{title: 'description', headers: ['dateCreated', 'lastUpdate', 'allowedToSee', 'kundenname']}"
        @clickRow="setFVP"
        @click-description="setFVP"
        @action-DELETE_PARAMETER="deleteFVP"
        @action-COPY_PARAMETER="copyFVP"
        @action-SHOW_TO_CUSTOMER="toggleShowToCustomer"
        @orderChanged="orderChanged"
        @headerAction-DELETE="deleteIds"
      />
      <GhostLoading v-else-if="loading" type="table" :title="TABLE_TITLE" />
      <NoData v-else :title="TABLE_TITLE" />
    </div>

    <BaseModal
      ref="messageRef"
      :modalTitle="configMess.text" :showConfirmButton="configMess.showConfirmButton" 
      :labelButtonCancel="configMess.labelButtonCancel"
      labelButtonConfirm='Ja'
      @onConfirmButton="confitmDeleteFVP" >
        <div>
          <div v-if="!paramList">Keine Parameter wurden gespeichert.</div>
        </div>
    </BaseModal>
    <BaseModal
      ref="copiedName"
      modalTitle="" :showConfirmButton="true" 
      labelButtonCancel="Abbrechen"
      labelButtonConfirm='Speichern'
      @onConfirmButton="sendCopyFVP" >
        <div>
          <InputField label="Speichern unter:" placeholder="Unbennant" v-model="saveAs"/>
        </div>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FONDSVERGLEICH_TYPES from "@/store/fondsvergleich/types";
import CORE_TYPES from '@/store/core/types'
import BaseModal from "@/components/core/BaseModal.vue";
import BaseFilter from '@/components/core/BaseFilter.vue';
import Table from "@/components/table2/Table.vue";
import { ActionColumn, DateTimeColumn, PillColumn, TextColumn, SimpleAction } from "@/components/table2/table_util.js";
import { PhTrash, PhEye, PhCopy } from 'phosphor-vue';
import dayjs from 'dayjs';
import ROLES, { VIEW_ROLES } from '@/router/roles';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import InputField from '@/components/core/forms/InputField.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';

const TABLE_TITLE = 'Fondsvergleichsliste';

export default {
  name: 'FondsVergleichList',
  components: {
    BaseModal,
    Table,
    GhostLoading,
    NoData,
    InputField,
    PageHeaderTitleNavigation,
    OptionMenu,
    BaseFilter,
  },
  data() {
    return {
      TABLE_TITLE,
      deleteId: null,
      copyId: null,
      configFilter: {
        checkDefaultSearchInput: false,
        cbDefSearchInput: null,
        hideFirstColumn: true,
        filterZurucksetzen: null,
      },
      configMess: {
        text: '',
        showConfirmButton: false,
        labelButtonCancel: ''
      },
      headers: {
        lockedLeft: [
            TextColumn("description", "Bezeichnung").makeAlwaysVisible().makeLink(),
        ],
        center: [
            DateTimeColumn("dateCreated", "Datum (erstellt)", 150, 1),
        ],
        lockedRight: [
            ActionColumn("actions", ""),
        ],
      },
      loading: false,
      saveAs: '',
      orderHasChanged: false,
      orderedRows: [],
      selectedRows: [],
    }
  },
  computed: {
    ...mapGetters({
      settings: FONDSVERGLEICH_TYPES.GETTERS.SETTINGS,
      session: FONDSVERGLEICH_TYPES.GETTERS.ACT,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      filter: FONDSVERGLEICH_TYPES.GETTERS.GET_FILTER
    }),
    filterMetadata() {
    const menuItems = [
        {type: 'default', key: 'vorlage',     deniable: true,     label: 'Alle Kunden' },
        {type: 'text',    key: 'createMonth', emptyDenied: true,  label: 'Erzeugt in den letzten Monaten' },
        {type: 'text',    key: 'updateMonth', emptyDenied: true,  label: 'Geändert in den letzten Monaten' },
        {type: 'text',    key: 'name',        emptyDenied: true,  label: 'Bezeichnung' },
      ]
      if (this.canChangeForCustomerVisibility) {
        menuItems.push(
          {type: 'default', key: 'kundenSicht', deniable: true,     label: 'Sichtbar für Kunde' },
          {type: 'text',    key: 'kundennr',    emptyDenied: true,  label: 'Kundennummer' },
          {type: 'text',    key: 'kname',       emptyDenied: true,  label: 'Kunden Nachname' },
          {type: 'text',    key: 'kvorname',    emptyDenied: true,  label: 'Kunden Vorname' },
        );
      }
      return [
        {
          type: 'group',
          key: 'allgemein',
          label: 'Allgemein',
          menuItems
        }
      ]
    },
    fv_params() {
      return this.settings?.fv_params;
    },
    canChangeForCustomerVisibility() {
      return this.hasRoles([
        VIEW_ROLES.VIEW_BROKER,
        VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS ])
    },
    fkLockedColumns() {
      return {
        toLeft: [
          {key: 'description'}
        ],
        toRight: [
          {key: 'actions'}
        ]
      }
    },
    paramList() {
      const rows = this.fv_params?.length ? this.fv_params : null;
      if ( rows ) {
        const ph = {PhCopy, PhTrash, PhEye};
        rows.forEach(row => {
          row.actions.forEach((action, idx) => {
            if (action?.length === 3){
              row.actions[idx] = SimpleAction(action[0], ph[action[1]], action[2]);
            }
          });
        });
      }
      return rows;
    },
    rowsFVPCount() {
      return this.paramList ? this.paramList.length : null ;
    },
    actions() {
      return [
        PageHeaderSimpleAction('NEW', 'Neuer Fondsvergleich'),
        PageHeaderSimpleAction('SETTINGS', 'Einstellungen'),
      ]
    },
    tableActions() {
      return [
        PageHeaderSimpleAction('DELETE', 'Die markierten Zeilen löschen').withDisabled(() => !this.selectedRows.length),
      ]
    },
  },
  mounted() {
    this.getSettings();
  },
  methods: {
    search(filters) {
      let searchParams = filters == null ? {updateMonth: '3'} : {};
      if (filters) {
        filters.filter(item => item.value ).forEach(item => {
          if (item.filterNot) {
            searchParams[item.key] = '0'
          } else if (item.value === true) {
            searchParams[item.key] = '1';
          } else if (item.key === 'createMonth' || item.key === 'updateMonth') {
            if (/^\d+$/.test(item.value)) {
              searchParams[item.key] = item.value;
            }
          } else if (item.value) {
            searchParams[item.key] = item.value;
          }
        })
      }
      if (!Object.keys(searchParams).length) {
        searchParams = {updateMonth: ''}
      }
      this.$store.dispatch(FONDSVERGLEICH_TYPES.ACTIONS.FVPREAD, searchParams);
    },
    async getSettings() {
      try {
        this.loading = true;
        this.headers.center.push( DateTimeColumn('lastUpdate', 'letzte Änderung', 200, 1) );
        if (this.canChangeForCustomerVisibility) {
          this.headers.center.push( PillColumn('allowedToSee', 'sichtbar für Kunden') );
          this.headers.center.push( TextColumn('kundenname', 'Kunde') );
        }
        await this.$store.dispatch(FONDSVERGLEICH_TYPES.ACTIONS.SETTINGS, this.filter);
      } finally {
        this.loading = false;
      }
    },
    toggleShowToCustomer(row) {
      const id = row.id;
      const available = row.allowedToSee.label == "Nein";
      this.$store.dispatch(FONDSVERGLEICH_TYPES.ACTIONS.TOGGLE_AVAILABLE_TO_CUSTOMER, {...this.filter, id, available });
    },
    setFVP(row) {
      this.$store.commit(FONDSVERGLEICH_TYPES.MUTATIONS.RESET_ACT );
      const fvp = this.session?.fvp;
      if (null !== row ) {
        fvp.id = row.id;
        fvp.description = row.description;
        fvp.create = row.dateCreated;
        fvp.params = row.params;
      } else {
        fvp.id = null;
        fvp.description = 'Unbenannt';
        fvp.create = dayjs(new Date()).format('DD.MM.YYYY');
      }
      this.$store.commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_ACT, {fvp: fvp});
      const base = this.$route.path.substr(0, this.$route.path.indexOf('fondsvergleich'));      
      this.$addBreadcrumb({
        label: 'zurück zum Fondsvergleich', 
        breadcrumb: 'Fondsauswahl',
        fullPath: base + 'fondsvergleich',
      });
      this.$router.push({ path: base + 'fondsvergleich/' + (fvp.id || '-1') });
    },
    copyFVP(row) {
      this.copyId = '' + row.id;
      this.configMess.text = 'Parameter '+ (row.description || 'Unbenannt') + ' vom '+ row.dateCreated +' löschen?';
      this.configMess.showConfirmButton = true;
      this.$refs.copiedName.open();
    },
    sendCopyFVP() {
      this.$store.dispatch(FONDSVERGLEICH_TYPES.ACTIONS.FVPCOPY, {...this.filter, fv_id: this.copyId, fv_description: this.saveAs });
    },
    deleteFVP(row) {
      this.deleteId = '' + row.id;
      this.configMess.text = 'Parameter '+ (row.description || 'Unbenannt') + ' vom '+ row.dateCreated +' löschen?';
      this.configMess.showConfirmButton = true;
      this.configMess.labelButtonCancel="Nein";
      this.$refs.messageRef.open();
    },
    confitmDeleteFVP() {
      this.$store.dispatch(FONDSVERGLEICH_TYPES.ACTIONS.FVPDEL, {...this.filter, del_id: this.deleteId} );
    },
    deleteIds() {
      const delete_ids = this.selectedRows.map(r => r.id).join(',');
      this.$store.dispatch(FONDSVERGLEICH_TYPES.ACTIONS.FVPDEL, {...this.filter, delete_ids} );
    },
    dateToStr(dat) {
      if (!dat) {
        return '';
      }
      const result = dayjs(dat).format('DD.MM.YYYY');
      return result === '01.01.1970' ? '' : result ;
    },
    showSettings() {
      this.$addBreadcrumb({
        label: 'zurück zum Fondsvergleich', 
        breadcrumb: 'Fondsauswahl',
        fullPath: this.$route.path,
      });
      this.$router.push({ path: this.$route.path + '/fondsvergleicheinstellen' });
    },
    orderChanged(rows) {
      this.orderHasChanged = true
      this.orderedRows = rows
    },
    saveOrder() {
        if (!this.orderHasChanged) {
          return
        }

        this.orderHasChanged = false
        const fvpParams = this.orderedRows.map(row => row.id)

        return this.$store.dispatch(FONDSVERGLEICH_TYPES.ACTIONS.FVPSAVEORDER, {...this.filter, fvpParams});
    }
  },
  async beforeRouteLeave(to, from, next) {
    await this.saveOrder()
    next();
  },
}
</script>


<style scoped>
.btn-clean {
  color: var(--color-text);
}
</style>