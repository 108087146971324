var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.isDialogFVV
        ? _c("InputField", {
            attrs: {
              label: "Bargeld",
              type: "currency",
              disabled: _vm.isDisabled || _vm.isRefusedPage,
              isComponentHalfSize: ""
            },
            on: {
              input: function($event) {
                return _vm.updateStore("finance", "cash", $event || 0)
              }
            },
            model: {
              value: _vm.cash,
              callback: function($$v) {
                _vm.cash = $$v
              },
              expression: "cash"
            }
          })
        : _vm._e(),
      _c("InputField", {
        staticClass: "font-bold",
        attrs: {
          label:
            "täglich verfügbare Bankeinlagen (z.B. Tagesgeld, Kontokorrent)",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.isDisabled || _vm.isRefusedPage
        },
        on: {
          input: function($event) {
            return _vm.updateStore("finance", "freeBankDeposit", $event || 0)
          }
        },
        model: {
          value: _vm.freeBankDeposit,
          callback: function($$v) {
            _vm.freeBankDeposit = $$v
          },
          expression: "freeBankDeposit"
        }
      }),
      _c("div", { staticClass: "sum d-flex justify-content-between" }, [
        _c("div", { staticClass: "my-2" }, [
          _vm._v("Summe aktueller liquider Barvermögen")
        ]),
        _c(
          "div",
          { staticClass: "my-2 pr-3" },
          [_c("CurrencyLabel", { attrs: { value: _vm.summeVermoegen } })],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }