var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isBrokerOrBypass && _vm.visible
    ? _c("div", { staticClass: "login-needed-container" }, [
        _c("div", { staticClass: "login-needed-message" }, [
          _c("div", [
            _vm._v(
              " Ihre Konto-Umsätze sind nicht mehr aktuell. Sie müssen sich aus Sicherheitsgründen erneut anmelden.  "
            )
          ]),
          _c(
            "div",
            { staticStyle: { "white-space": "nowrap" } },
            [
              _vm.selectedAccountsAuthNeededProviderId
                ? _c(
                    "BaseButton",
                    {
                      on: {
                        click: function($event) {
                          return _vm.authenticateAccount()
                        }
                      }
                    },
                    [_vm._v("Konto aktualisieren")]
                  )
                : _c(
                    "BaseButton",
                    {
                      on: {
                        click: function($event) {
                          return _vm.goToAccountManagements()
                        }
                      }
                    },
                    [_vm._v("Zur Kontoverwaltung")]
                  ),
              _vm._v("   "),
              _c("ph-x", {
                staticStyle: { margin: "-4px", "min-width": "max-content" },
                attrs: { size: 24 },
                on: {
                  click: function($event) {
                    return _vm.hideMessage()
                  }
                }
              })
            ],
            1
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }