var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container painter" },
    [
      _c("data-header", {
        attrs: {
          email: _vm.maklerInfo.emailAddress,
          telefon: _vm.maklerInfo.telefon,
          id: "dataheader"
        }
      }),
      _c("navi-header", {
        staticClass: "white-painter",
        attrs: { logoURL: _vm.maklerLogo, id: "naviheader" }
      }),
      _c("fondsshop-element"),
      _c("broker-info", {
        attrs: {
          cleanMaklername: _vm.maklerInfo.cleanMaklername,
          strasse: _vm.maklerInfo.strasse,
          plzOrt: _vm.maklerInfo.plzOrt,
          telefon: _vm.maklerInfo.telefon,
          faxStandard: _vm.maklerInfo.faxStandard,
          id: "brokerinfo"
        }
      }),
      _c("beratungs-form", {
        staticClass: "white-painter",
        attrs: { id: "beratungsform" }
      }),
      _c("editable-frame", {
        staticClass: "white-painter",
        attrs: { type: "ueber_uns", id: "ueberuns" }
      }),
      _c("editable-frame", {
        attrs: { type: "streitbeilegung", id: "streitbeilegung" }
      }),
      _c("lower-div", {
        attrs: { content: _vm.maklerInfo.cleanMaklername, id: "lowerdiv" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }