export const FRONT_CAMERA_MEDIA_DEVICE_VALUE = 'user';
export const BACK_CAMERA_MEDIA_DEVICE_VALUE = 'environment';
export const FRONT_CAMERA_VALUE = 'front';
export const BACK_CAMERA_VALUE = 'back';
export const PROP_CAMERA_VALIDS = [FRONT_CAMERA_VALUE, BACK_CAMERA_VALUE];
export const HIGH_QUALITY_PHOTO_WIDTH = 2048;

export function getCameraMediaDevice(camera) {
  switch(camera) {
    case FRONT_CAMERA_VALUE:
      return FRONT_CAMERA_MEDIA_DEVICE_VALUE;
    case BACK_CAMERA_VALUE:
      return BACK_CAMERA_MEDIA_DEVICE_VALUE;
    default:
      return FRONT_CAMERA_MEDIA_DEVICE_VALUE;
  }
}
