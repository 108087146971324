<template>
  <div class="box__container">
    <DatePickerField
      label="Wann wurde der Schaden verursacht?"
      :isComponentHalfSize="true"
      v-model="form.ereignisdatum"
      @input="dataChanged()"
    ></DatePickerField>

    <TimePicker
      label="Um wieviel Uhr?"
      :isComponentHalfSize="true"
      v-model="form.ereignisUhrzeit"
      @input="dataChanged()"
    ></TimePicker>

    <InputField label="Straße, Hausnummer" :isComponentHalfSize="true" v-model="form.adresseStrasse" @change="dataChanged()" />

    <InputZIP
      label="PLZ"
      :isComponentHalfSize="true"
      v-model="form.adressePlz"
      @change="dataChanged()"
      @city="($event) => {
        form.adresseOrt = $event;
        dataChanged();
      }"/>

    <InputField label="Ort" :isComponentHalfSize="true" v-model="form.adresseOrt" @change="dataChanged()" />
    
    <ComboBox
        label="Land"
        :isComponentHalfSize="true"
        v-model="form.adresseLand" 
        :values="configData.lands"
        :firstEmpty="true"
        @change="dataChanged()">
    </ComboBox>

    <InputTextArea label="Schadensablauf" :isComponentHalfSize="true" v-model="form.schadenKommentar" @change="dataChanged()" ></InputTextArea>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SCHADENSMELDUNG_TYPES from "@/store/schadensmeldung/types";

import validator from "@/mixins/validator";
import { required } from "@/mixins/validator/rules";

import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import TimePicker from '@/components/core/forms/TimePicker.vue';
import InputField from '@/components/core/forms/InputField.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputZIP from '@/components/core/forms/InputZIP.vue';
import CORE_TYPES from "@/store/core/types";

import mainFormMixin from '../main-form-mixin';


export default {
  mixins: [mainFormMixin, validator],
  props: {
    vertragId: {
      type: [Number, String],
    },
    schadenId: {
      type: [Number, String],
    },
  },
  data() {
    return {
      form: {
        ereignisdatum: "",
        ereignisUhrzeit: "",
        adresseStrasse: "",
        adressePlz: "",
        adresseOrt: "",
        adresseLand: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      schadensmeldung: SCHADENSMELDUNG_TYPES.GETTERS.SCHADENSMELDUNG,
      configData: SCHADENSMELDUNG_TYPES.GETTERS.CONFIG_DATA,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      isFK: CORE_TYPES.GETTERS.IS_FK,
    }),
    lands() {
      return this.configData?.lands || [];
    },
  },
  watch: {
    schadensmeldung: {
      handler(newValue) {
        this.setForm(newValue);
      },
      immediate: true,
    },
    configData: {
      handler() {
        if(this.configData?.lands){
          this.configureLandsCombobox();
        }
      },
      immediate: true
    }
  },
  methods: {
    setForm(schadensmeldung) {
      if(!schadensmeldung) {
        return ;
      }
      this.form.ereignisdatum = this.parseDate(schadensmeldung.ereignisdatum);
      this.form.ereignisUhrzeit = schadensmeldung.ereignisUhrzeit;
      this.form.adresseStrasse = schadensmeldung.adresseStrasse;
      this.form.adressePlz = schadensmeldung.adressePlz;
      this.form.adresseOrt = schadensmeldung.adresseOrt;
      this.form.adresseLand = schadensmeldung.adresseLand;
    },
    configureLandsCombobox() {
      let land = "";
      if(this.$route.params?.schadenId === "neuesSchaden"){
        if(this.isFA){
          land = 'Österreich'
        } else if(this.isFK){
          land = 'Deutschland'
        }
        this.form.adresseLand = land;
      }
      return land;
    },
  },
  beforeCreate() {
    this.form = {
        ereignisdatum: "",
        ereignisUhrzeit: "",
        adresseStrasse: "",
        adressePlz: "",
        adresseOrt: "",
        adresseLand: "",
      }
  },
  mounted() {
    // important to allow save schadensmeldung and get the id and in order to load the document header actions component properly
    this.dataChanged();
  },
  validators: {
    form: {
      ereignisdatum: [required()],
      ereignisUhrzeit: [required()],
      adresseStrasse: [required()],
      adressePlz: [required()],
    }
  },
  components: {
    DatePickerField,
    TimePicker,
    InputField,
    ComboBox,
    InputZIP,
    InputTextArea,
  },
}
</script>
