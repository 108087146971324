var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isContainerRendered
      ? _c("div", [
          _c(
            "div",
            { staticClass: "large" },
            [
              _c(
                "CoreCard",
                {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "alternative",
                        fn: function() {
                          return undefined
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    2066962534
                  )
                },
                [
                  _c("div", { staticClass: "content" }, [
                    _c("div", [
                      _c("div", { staticClass: "text-lg" }, [
                        _vm._v(_vm._s(_vm.largeCard.data))
                      ]),
                      _c("div", { staticClass: "text-sm" }, [
                        _vm._v(_vm._s(_vm.largeCard.name))
                      ])
                    ])
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "small grid-container" },
            _vm._l(_vm.smallCards, function(card, index) {
              return _c(
                "div",
                { key: index },
                [
                  _c("CoreCard", [
                    _c("div", { class: ["content", "bottom-" + (index + 1)] }, [
                      _c("div", [
                        _c("div", { staticClass: "text-lg" }, [
                          _vm._v(_vm._s(card.data))
                        ]),
                        _c("div", { staticClass: "text-sm" }, [
                          _vm._v(_vm._s(card.name))
                        ])
                      ])
                    ])
                  ])
                ],
                1
              )
            }),
            0
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }