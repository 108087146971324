<template>
  <div class="container">
    <div class="wrapper2">
      <LAnzahl />
      <LEnde />
      <LJahre />
      <LRate />
      <LStart />
      <LZinsen />
      <HrTag />
      <ResLEinzahlungen />
      <ResLAuszahlungen />
      <ResLZinsen />
    </div>
    <input
      type="string"
      class="screen"
      @input="updateCurrent"
      v-model="getCurrent"
      @keyup.enter="getResult()"
    />
    <div class="wrapper3">
      <Anzahl />
      <Ende />
      <Jahre />
      <Rate />
      <Start />
      <Zins />
    </div>
    <div class="wrapper4">
      <Zahlen class="zahlen" />
      <Equalizer />
      <TotalEraser />
      <PartialEraser />
      <Plus />
      <Minus />
      <Divider />
      <Multiplier />
      <PlusMinus />
    </div>
  </div>
</template>

<script>
import functionMixins from "../functionMixins.js";
import Zahlen from "./Zahlen.vue";
import Plus from "./operators/Plus.vue";
import Minus from "./operators/Minus.vue";
import Divider from "./operators/Divider.vue";
import Multiplier from "./operators/Multiplier.vue";
import PlusMinus from "./operators/PlusMinus.vue";
import Equalizer from "./operators/Equalizer.vue";
import TotalEraser from "./operators/TotalEraser.vue";
import PartialEraser from "./operators/PartialEraser.vue";
import Anzahl from "./spec_buttons/Anzahl.vue";
import Ende from "./spec_buttons/Ende.vue";
import Jahre from "./spec_buttons/Jahre.vue";
import Rate from "./spec_buttons/Rate.vue";
import Start from "./spec_buttons/Start.vue";
import Zins from "./spec_buttons/Zins.vue";
import LAnzahl from "./labels/LAnzahl.vue";
import LEnde from "./labels/LEnde.vue";
import LJahre from "./labels/LJahre.vue";
import LRate from "./labels/LRate.vue";
import LStart from "./labels/LStart.vue";
import LZinsen from "./labels/LZinsen.vue";
import ResLAuszahlungen from "./labels/ResLAuszahlungen.vue";
import ResLEinzahlungen from "./labels/ResLEinzahlungen.vue";
import ResLZinsen from "./labels/ResLZinsen.vue";
import HrTag from "./other/HrTag.vue";

/* 
1.PROBLEM WITH KOMMA
2.NO SYMBOLS IN SPECIAL
 */


export default {
  name: "Rechner",
  mixins: [functionMixins],
  computed: {
    getCurrent() {
      return this.$store.getters.getCurrent;
    }
  },
  methods: {
    updateCurrent(e) {
      this.$store.commit("SET_CURRENT", e.target.value);
    }
  },

  components: {
    Zahlen,
    Plus,
    Minus,
    Divider,
    Multiplier,
    PlusMinus,
    Equalizer,
    TotalEraser,
    PartialEraser,
    Anzahl,
    Ende,
    Jahre,
    Rate,
    Start,
    Zins,
    LAnzahl,
    LEnde,
    LJahre,
    LRate,
    LStart,
    LZinsen,
    ResLEinzahlungen,
    ResLAuszahlungen,
    ResLZinsen,
    HrTag
  }
};
</script>

<style scoped>
.container {
  display: grid;
  grid-gap: 0.5em;
}

.screen {
  height: 60px;
  padding-right: 10px;
  padding-left: 10px;
  color: var(--blueish-dark-color);
  border: 0px;
  border-radius: 5px;
  text-align: right;
  background: var(--blueish-light-color);
}

.wrapper2 {
  display: grid;
  grid-gap: 0.5em;
}

.wrapper3 {
  display: grid;
  grid-gap: 0.5em;
}

.wrapper4 {
  display: grid;
  grid-gap: 0.5em;
}

.hrcolour {
  border: 1px solid var(--blueish-dark-color);
} 
>>> .norm_button {
  background-color: var(--blueish-button-color);
  color: var(--blueish-buttontext-color);
  cursor: pointer;
}
.norm_button:hover {
  background-color: var(--blueish-buttonhover-color);
}

.spec_button {
  background-color: var(--blueish-main-color);
  color: var(--blueish-buttontext-color);
  cursor: pointer;
}
 .label {
  text-align: center;
  color: var(--blueish-labeltext-color);
  
}

.truelabel {
  font-size: 0.7em;
}

>>> .box {
  text-align: center;
  border-radius: 5px;
  padding: 20px;
  font-size: 100%;
 
}

.spec_button:hover {
  background-color: var(--blueish-dark-color);
}

@media only screen and (min-width: 400px) {
  .wrapper2 {
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas:
      "lanzahl lende ljahre"
      "lrate lstart lzinsen"
      "hrtag hrtag hrtag"
      "resleinzahlungen reslauszahlungen reslzinsen";
  }

  .wrapper3 {
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas:
      "anzahl ende jahre"
      "rate start zins";
  }
  .wrapper4 {
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas:
      "zahlen zahlen zahlen plus"
      "zahlen zahlen zahlen minus"
      "zahlen zahlen zahlen multiplier"
      "zahlen zahlen zahlen divider"
      "totaleraser partialeraser plusminus equalizer";
  }
}

@media only screen and (max-width: 400px) {
  .wrapper2 {
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas:
      "lanzahl "
      "lende"
      "ljahre"
      "lrate"
      "lstart"
      "lzinsen"
      "hrtag"
      "resleinzahlungen"
      "reslauszahlungen"
      "reslzinsen";
  }

  .wrapper4 {
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas:
      "zahlen zahlen"
      "zahlen zahlen"
      "zahlen zahlen"
      "zahlen zahlen"
      "zahlen zahlen"
      "plus minus"
      "multiplier divider"
      "plusminus equalizer"
      "totaleraser partialeraser";
  }
}

.zahlen {
  grid-area: zahlen;
}
</style>
 */