var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseCollapsable", {
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function() {
          return [_vm._v(" Erweiterter Filter ")]
        },
        proxy: true
      },
      {
        key: "content",
        fn: function() {
          return [
            _c(
              "div",
              [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("InputField", {
                      staticClass: "col-12 col-md-4",
                      attrs: {
                        placeholder: _vm.nameDescriptionPlaceholder,
                        label: "Text"
                      },
                      on: { input: _vm.emitChange },
                      model: {
                        value: _vm.filterText,
                        callback: function($$v) {
                          _vm.filterText = $$v
                        },
                        expression: "filterText"
                      }
                    }),
                    _c("InputField", {
                      staticClass: "col-12 col-md-4",
                      attrs: { label: "Betrag von", type: "currency" },
                      on: { input: _vm.emitChange },
                      model: {
                        value: _vm.filterMinAmount,
                        callback: function($$v) {
                          _vm.filterMinAmount = $$v
                        },
                        expression: "filterMinAmount"
                      }
                    }),
                    _c("InputField", {
                      staticClass: "col-12 col-md-4",
                      attrs: { label: "Betrag bis", type: "currency" },
                      on: { input: _vm.emitChange },
                      model: {
                        value: _vm.filterMaxAmount,
                        callback: function($$v) {
                          _vm.filterMaxAmount = $$v
                        },
                        expression: "filterMaxAmount"
                      }
                    }),
                    _vm.showTransactions
                      ? _c(
                          "div",
                          { staticClass: "col-12 col-md-4" },
                          [
                            _c("ComboBox", {
                              attrs: {
                                values: _vm.categoryFlatComboValues,
                                firstEmpty: ""
                              },
                              on: { input: _vm.emitChange },
                              model: {
                                value: _vm.filterCategory,
                                callback: function($$v) {
                                  _vm.filterCategory = $$v
                                },
                                expression: "filterCategory"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "BaseButton",
                  {
                    on: {
                      click: function($event) {
                        return _vm.cleanFilters()
                      }
                    }
                  },
                  [_vm._v(" Filter zurücksetzen ")]
                )
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }