
const createDisposableWorker = response => {
  const URL = window.URL || window.webkitURL
  const blob = new Blob([response], { type: 'application/javascript' }) 
  const objectURL = URL.createObjectURL(blob)
  const worker = new Worker(objectURL)

  worker.run = (interval) => {
    return new Promise((resolve, reject) => {
      worker.onmessage = event => {
        URL.revokeObjectURL(objectURL)
        if (event.data === 'done') {
          resolve(event.data)
        } else {
          reject(event.data)
        }
      }
      worker.onerror = e => {
        console.error(`Error: Line ${e.lineno} in ${e.filename}: ${e.message}`)
        reject(e)
      }
      worker.postMessage(interval)
    })
  }

  worker.stop = () => {
    worker.postMessage('close');
  }

  return worker
}

const makeWorkerContent = () => `
  self.onmessage = function(e) {
    const parameter = e.data;
    if (typeof parameter === 'number') {
      if (self.timeoutID) {
        clearTimeout(self.timeoutID)
      }
      self.timeoutID = setTimeout(function() {
        self.postMessage('done');
        close()
      }, parameter);
    } else if (parameter === 'close') {
      clearTimeout(self.timeoutID)
      self.postMessage('close');
      close();
    }
  };
`

/**
 * TimeoutWorker Class
 * This class creates a TimeoutWorker.
 */
export class TimeoutWorker {
  
  constructor() {
    this.worker = createDisposableWorker(makeWorkerContent())
  }

  /**
   * Starts the worker with a specified interval.
   * @param {number} interval - The interval in milliseconds for the worker to run.
   * @return {Promise} Returns a promise that resolves when the worker finishes running.
   */
  run(interval) {
    return this.worker.run(interval)
  }

  /**
   * Stops the worker. Useful when the worker needs to be stopped before the time.
   * 
   * This worker doesn't need to be stopped manually. It's self destroyed after the time.
   */
  stop() {
    this.worker.stop();
  }
}

/**
 * Usage example:
 * 
 * // Creates a new instance of TimeoutWorker
 * const timeoutWorker = new TimeoutWorker();
 * 
 * // Starts the worker with an interval of 1000 milliseconds
 * timeoutWorker.run(1000).then(() => {
 *   console.log('Worker finished running');
 * });
 * 
 * // Stops the worker before the time is over
 * timeoutWorker.stop();
 * 
 */