var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showIf
    ? _c("div", { ref: "box_container", staticClass: "box__container" }, [
        _vm.title
          ? _c("div", { staticClass: "row box__title" }, [
              _c("div", { staticClass: "col-12" }, [
                _vm._v(" " + _vm._s(_vm.title) + " ")
              ])
            ])
          : _vm._e(),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [_vm._t("default")], 2)
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }