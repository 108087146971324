var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.hasYears || _vm.hasPerAnno
      ? _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.eChartSeries.length || _vm.message,
                expression: "eChartSeries.length || message"
              }
            ],
            staticClass: "bar"
          },
          [
            _vm.hasYears
              ? _c(
                  "span",
                  { staticClass: "bar-item" },
                  [
                    _c("ComboBox", {
                      attrs: { value: _vm.year, values: _vm.years },
                      on: { change: _vm.changeYear }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.year !== 1,
                    expression: "year!==1"
                  }
                ],
                staticClass: "bar-item"
              },
              [
                _vm.hasPerAnno
                  ? _c(
                      "span",
                      { staticClass: "per-anno" },
                      [
                        _c("InputToggleSwitch", {
                          attrs: {
                            label: "Rendite p.a.",
                            inLineLabel: "",
                            suppressValidationMessage: ""
                          },
                          on: {
                            input: function($event) {
                              return _vm.togglePerAnno($event)
                            }
                          },
                          model: {
                            value: _vm.perAnno,
                            callback: function($$v) {
                              _vm.perAnno = $$v
                            },
                            expression: "perAnno"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]
            )
          ]
        )
      : _vm._e(),
    _vm.message
      ? _c("div", { staticClass: "m-3 box__title" }, [
          _vm._v(_vm._s(_vm.message))
        ])
      : _c(
          "div",
          { attrs: { id: "chart-yield-risk-funds" } },
          [
            _c("ScatterChart", {
              ref: "scatterChart",
              attrs: {
                customColors: _vm.getColors(_vm.eChartSeries),
                yAxisName: "Performance (%)",
                xAxisName: "Volatilität (%)",
                height: "500px",
                hasLegend: _vm.chartParam.hasLegend,
                series: _vm.eChartSeries,
                changeOption: _vm.changeOption,
                tooltip: _vm.eChartRisicoTooltip
              }
            })
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }