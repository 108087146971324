var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c(
            "div",
            [
              _c("OptionMenu", {
                attrs: {
                  id: _vm.$appNavigation.currentOptionMenuId,
                  defaultMenu: _vm.$appNavigation.currentOptionMenu
                }
              }),
              _c("PageHeaderTitleNavigation", {
                attrs: {
                  title: _vm.$appNavigation.currentMenu.label,
                  id: _vm.$appNavigation.currentOptionMenuId
                }
              }),
              _c(
                "div",
                { staticClass: "box__container" },
                [_c("GhostLoading", { attrs: { type: "table" } })],
                1
              )
            ],
            1
          )
        : _vm.hasMyGoals
        ? _c("MyGoalsList")
        : _c("MyGoalSelectType")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }