import DOCUMENT_TYPES from './types';
import CORE_TYPES from '../core/types';
import axios from 'axios';
import { buildMessage, } from '../../helpers/log-message-helper';

export default {
  [DOCUMENT_TYPES.ACTIONS.RETRIEVE_DOCUMENT]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    let serviceUrl = '/documents/docInfo';


    if (payload) {
      const nodeId = payload.nodeId;
      const documentId = payload.id;

      if (nodeId && documentId) {
        serviceUrl = `${serviceUrl}?docInfo=${nodeId}&docInfo=${documentId}`;
      } else {
         return; 
      }

    }     
   

    axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
      if (response && response.data) {

        commit(DOCUMENT_TYPES.MUTATIONS.RETRIEVE_DOCUMENT_SUCCESS, response.data);

      }      

    })
  },
}