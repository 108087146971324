<template>
  <div class="mb-4">
    <PageHeaderTitleNavigation title="Erfassung Position Papierantrag" />

    <template v-if="searchMode">
      <FondsFinderSuchMaske
        :isZertifikat="fondsArt === 'ZERTIFIKATE'"
        :isBeteiligung="fondsArt === 'GESCHLOSSENEFONDS'"
      />

      <FondsFinderPositionsList
        goBackEnabled
        singleSelectionProp
        @goBack="goBackButton()"
        @getSelectedOutcome="onselectionDone($event)"
      />
    </template>

    <template v-else>
      <div class="box__container">
        <div class="box__title">
          Wertpapierauswahl
        </div>

        <template v-for="fondsArt in fondsArtValues">
          <BaseButton
            :key="fondsArt.value"
            @click="openFormsFinder(fondsArt.value)"
            :label="fondsArt.label"
          />
        </template>
      </div>

      <div class="box__container">
        <WertpapierTable
          v-if="rows.length && headers.length"
          :rows="rows"
          :headers="headers"
        />
        <div v-else class="col-12 color-danger">Bitte wählen Sie eine Position aus.</div>

        <div class="row">
          <div class="col-12 col-lg-3 col-md-3 col-sm-3" >
              <InputField label="Anlagebetrag (Euro)" type = "text" v-model="INPUT_BETRAG" validateUntouched />
          </div>

          <div class="col-12 col-lg-3 col-md-3 col-sm-3">
              <InputField label="Ausgabeaufschlag %" type = "formpercent" v-model="INPUT_AA" />                
          </div>  
        </div>
          
        <InputRadioBoxGroup title="Art" v-model="INPUT_ANLAGE_ART" :values="anlageartValues"/>

        <InputRadioBoxGroup 
          v-if="INPUT_ANLAGE_ART === 'SPARPLAN' || INPUT_ANLAGE_ART === 'ENTNAHMEPLAN'"
          title="Turnus" 
          v-model="INPUT_PLAN_TURNUS" 
          :values="frequenzenValues"
        />                  

        <div class="col-lg-3 col-md-3 col-sm-3">
            <ComboBox label="Bezug (Lagerstelle)" v-model="INPUT_BEZUG_AUSWAHL" :values="bezugAuswahlValues" @change="retrieveAA($event)"/>                
        </div>            
      </div>   
    </template>

    <BaseModal ref="errorModal" modalTitle="Fehler Liste" labelButtonConfirm="Ok" labelButtonCancel="Zurück zur Beratungsmappe"
      @onCancelButton ="cancelSaving()" @onConfirmButton="confirmSaving"
    >
     
       <div v-if="response" >
        <div v-for="errorItem in response.errorTable" :key="errorItem.value">
          <span :class="{'ampel-rot':errorItem.style=='E', 'ampel-gelb':errorItem.style=='W'}" ></span>{{errorItem.label+": " + errorItem.value}}
        </div>
        <div v-if="response.onlyWarn">
            <InputToggleSwitch class="mt-3"
              label="Trotzdem speichern" 
              :inLineLabel="true"
              v-model="response.noWarn" />
        </div>
      </div>
    </BaseModal>

        <div class="col-12 col-md-4 pl-0">
          <BaseButton 
          v-if="!searchMode"
          class="mr-2" 
          @click="cancelSaving()" 
          isSecondary>
            <span>Zurück</span>
          </BaseButton>
          <BaseButton 
            v-if="!searchMode"
            class="mr-2" 
            :disabled="!rows.length || !INPUT_BETRAG"
            @click="doSave(false)">
              <span>Fertigstellen</span>
          </BaseButton>
        </div>
  </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { mapGetters } from 'vuex';
import InputField from "@/components/core/forms/InputField.vue";
import InputRadioBoxGroup from "@/components/core/forms/radiobox/InputRadioBoxGroup.vue";
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import ComboBox from "@/components/core/forms/ComboBox.vue";
import BaseButton from '@/components/core/BaseButton.vue';
import antragNavigationMixin from '@/mixins/antrag/antrag-navigation-mixin.js';
import antragMixin from '@/mixins/antrag/antrag-mixin.js';
import FondsFinderSuchMaske from '@/components/antrag/fondsfinder/FondsFinderSuchMaske.vue';
import FondsFinderPositionsList from '@/components/antrag/fondsfinder/FondsFinderPositionsList.vue';
import BERATUNGSMAPPE_TYPES from "@/store/beratungsmappe/types";
import BaseModal from '@/components/core/BaseModal.vue';
import { required, regex } from "@/mixins/validator/rules";
import validator from "@/mixins/validator";
import WertpapierTable from '@/views/brokerData/WertpapierTable.vue'

export default {
  mixins: [antragNavigationMixin, antragMixin, validator],
  validators: {
     INPUT_BETRAG: [required('Anlagebetrag ist erforderlich!'), regex(/^[1-9]\d*(\,\d+)?$/, ' ')],
  },
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    InputField,
    InputRadioBoxGroup,
    InputToggleSwitch,
    ComboBox,
    BaseButton,
    FondsFinderSuchMaske,
    FondsFinderPositionsList,
    BaseModal,
    WertpapierTable,
  },
  data: function() {
    return {
      outcome: {},
      positionId: null,
      INPUT_BETRAG: null,
      INPUT_AA: null,
      INPUT_ANLAGE_ART: "KAUF",
      INPUT_PLAN_TURNUS: null,
      INPUT_BEZUG_AUSWAHL: null,
      fondsArt: "INVESTMENTFONDS_OHNE_ZERTIFIKAT",
      anlageartValues: [
        {label:'Einmalanlage', value: 'KAUF'}, 
        {label:'Verkauf', value: 'VERKAUF'}, 
        {label:'Sparplan', value: 'SPARPLAN'},
        {label:'Entnahmeplan', value: 'ENTNAHMEPLAN'},
        ],
      frequenzenValues: [
        {label:'monatlich', value: 'MONATLICH'}, 
        {label:'vierteljährlich', value: 'VIERTELJ'}, 
        {label:'halbjährlich', value: 'HALBJ'},
        {label:'einmalig', value: 'EINMALIG'},
        {label:'jährlich', value: 'JAEHRLICH'},
        ],
        bezugAuswahlValues :[
          {label:'keine Angabe', value: 'VALUE_KEINE_AUSWAHL'}, 
        ],
        fondsArtValues:[
          {label:'Investmentfonds', value: 'INVESTMENTFONDS_OHNE_ZERTIFIKAT'}, 
          {label:'Beteiligungen', value: 'GESCHLOSSENEFONDS'},
          {label:'Zertifikate', value: 'ZERTIFIKATE'},
        ],
      searchMode: false,
      response: null,
      formSaved: false,
      mandatoryFields: [],
        
    }
  },
  watch: {
    lagerstelleListe: function (newValue, oldValue) {
      this.bezugAuswahlValues = newValue;

    },
    lastSavedAntragsPosition: function (newValue, oldValue) {
      this.response = newValue;
      if (this.response && this.response.errorTable && this.response.errorTable.length>0) {
        this.response.onlyWarn = !this.response.errorTable.find(item => item.style == 'E');
        this.response.noWarn = false;
        this.$refs.errorModal.open();
      }

      if (this.response && this.response.errorTable.length==0) {
        this.formSaved = true;
         this.$router.go(-1); 
      }

    },
    orderPosition: function (newValue, oldValue) {
      if (newValue?.positionId) {
        this.bezugAuswahlValues = newValue.lagerstelle; 
        this.positionId = newValue.positionId;
        this.INPUT_BETRAG = newValue.betrag || null;
        this.INPUT_AA = newValue.aa || null;
        this.INPUT_ANLAGE_ART = newValue.anlageArt || null;
        this.INPUT_PLAN_TURNUS = newValue.frequenz || null;
        this.INPUT_BEZUG_AUSWAHL = newValue.bezugAuswahl || null;
        this.fondsArt = newValue.fondsArt || null;
        this.isin = newValue.isin || null;
      }
    },
  },
  mounted() {
    const params = this.$route.query.positionId ? {
      positionId: this.$route.query.positionId,
      beratungsMappeId: this.$route.query.beratungsMappeId,
      isin: this.$route.query.isin
    } : null;

    this.$store.dispatch(BERATUNGSMAPPE_TYPES.ACTIONS.RETRIEVE_ORDER_POSITION, params);
  },
  computed: {
    ...mapGetters({
      lagerstelleListe: BERATUNGSMAPPE_TYPES.GETTERS.LAGERSTELLE_LISTE,
      lastSavedAntragsPosition: BERATUNGSMAPPE_TYPES.GETTERS.LAST_SAVED_ANTRAGS_POSITION,
      orderPosition: BERATUNGSMAPPE_TYPES.GETTERS.ORDER_POSITION,
      orderPositionHeaders: BERATUNGSMAPPE_TYPES.GETTERS.ORDER_POSITION_HEADERS,
      orderPositionRecords: BERATUNGSMAPPE_TYPES.GETTERS.ORDER_POSITION_RECORDS,
    }),
    rows() {
      return this.orderPositionRecords || []
    },
    headers() {
      return this.orderPositionHeaders || []
    },
  },
  methods: {
    goBackButton(){
      this.searchMode = false;
    },
    cancelSaving() {
      this.$router.go(-1);
    },
    confirmSaving() {
      if (this.response.onlyWarn && this.response.noWarn) {
        this.doSave(true);
      }
    },
    openFormsFinder(fondsArt) {
      this.fondsArt = fondsArt;
      this.searchMode = true;
    },  
    onselectionDone(data)  {
      this.searchMode = false;
      if(!data){
        return
      }
      if (data.records[0] && data.records[0]._fondsData.isin) {
        this.isin = data.records[0]._fondsData.isin;
        this.retrieveLagerstelle(data.records[0]._fondsData.isin);
        this.$store.commit(BERATUNGSMAPPE_TYPES.MUTATIONS.UPDATE_ORDER_POSITION, { 
          position: data.records[0],
          headers: data.headers,
          records: data.records,
        });
      }
      this.retrieveAA('NICHT_VERWENDEN')
    },
    retrieveLagerstelle(isin) {
      this.$store.dispatch(
        BERATUNGSMAPPE_TYPES.ACTIONS.RETRIEVE_LAGERSTELLE,
        {
          isin: isin,
        }
      );
    },
    doSave(noWarn){
      if (!this.formSaved) {
        this.$store.dispatch(
          BERATUNGSMAPPE_TYPES.ACTIONS.SAVE_ANTRAGS_POSITION,
          {
            isin: this.isin,
            positionId: this.positionId,
            betrag: this.INPUT_BETRAG,
            anlageArt: this.INPUT_ANLAGE_ART,
            aa: this.INPUT_AA,
            frequenz: this.INPUT_PLAN_TURNUS,
            bezugAuswahl: this.INPUT_BEZUG_AUSWAHL,
            beratungsmappeId: this.$route.query.beratungsMappeId,
            fondsArt: this.fondsArt,
            noWarn
          }
        );
      }
    },
    retrieveAA(event) {
      if(this.isin){
        this.$store.dispatch(
          BERATUNGSMAPPE_TYPES.ACTIONS.RETRIEVE_AA, { isin: this.isin, bezugAuswahl: event}).then(response => this.INPUT_AA = response.data)
      }
    },
  },
}
</script>