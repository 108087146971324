<template>
    <div>
        <BaseModal ref="confirmVorlage" labelButtonConfirm="Ja" labelButtonCancel="Nein"
            @onConfirmButton="removeVorlage()">
            <template v-slot:modalTitle>
                <ph-warning :size="16" class="mr-2" style="color: orangered"/>
                Änderung der Vorlage zur Unterschrift
            </template>
            <div>
                <p>
                    Soll das Dokument nicht mehr in der "Offene Unterschriften" Liste (weder beim Vermittler noch beim Kunden) angezeigt werden?
                </p>
                <p>
                    Sollten bereits Unterschriften getätigt worden sein, so werden diese im Dokumentenarchiv gespeichert. Der Abschluss des Dokumentes ist erforderlich, wenn eine Weiterleitung erfolgen soll. 
                </p>
            </div>
        </BaseModal>
    </div>
</template>

<script>
import BaseModal from '@/components/core/BaseModal.vue';
import OPEN_SIGNS_TYPES from '@/store/openSigns/types';
import { PhWarning } from 'phosphor-vue'

export default {
    props: {
        selectedElement: {
        },
        isBroker: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        BaseModal,
        PhWarning,
    },
    computed: {
        dokId() {
            return this.selectedElement?.dokId;
        }
    },
    methods: {
         openVorlageModal() {
            this.$refs.confirmVorlage.open();
        },
        removeVorlage() {
            if (this.dokId) {
                this.$store.dispatch(OPEN_SIGNS_TYPES.ACTIONS.OPEN_SIGNATURES_REMOVE_VORLAGE, { dokId: this.dokId, kundennr: this.isBroker ? this.selectedElement?.kundennr : null }).then(errorStr => {
                    if (errorStr) {
                        this.$emit('error', errorStr);
                    }
                    this.$refs.confirmVorlage.close();
                });

            }
        }
    }
}
</script>

<style>

</style>