import MAKLER34F_TYPES from './types';

export default {

  [MAKLER34F_TYPES.GETTERS.IS_LOADING_DATA](state) {
    return state.loadingData === true;
  },

  [MAKLER34F_TYPES.GETTERS.DATA](state) {
    return { ...state.data || {}, };
  },

  [MAKLER34F_TYPES.GETTERS.GESELLSCHAFT34F_TYP_SELECTED](state) {
    const id = state.gesellschaft34fTypSelected;
    const { gesellschaften34fTyp } = state.data || {};
    const { gesellschaften34fTypEdited } = state;
    const gesellschaft34fTyp = gesellschaften34fTyp?.find(gesellschaft34fTyp => gesellschaft34fTyp.id === id);
    const erlaubnis = id in (gesellschaften34fTypEdited || {}) ? gesellschaften34fTypEdited[id] : gesellschaft34fTyp?.erlaubnis;

    return gesellschaft34fTyp && {
      ...gesellschaft34fTyp,
      erlaubnis,
    } || {};
  },

  [MAKLER34F_TYPES.GETTERS.GESELLSCHAFTEN34F_TYP_EDITED](state) {
    return { ...state.gesellschaften34fTypEdited || {}, };
  },

  [MAKLER34F_TYPES.GETTERS.MAKLER34F_GESELLSCHAFTEN_EDITED](state) {
    return { ...state.makler34fGesellschaftenEdited || {}, };
  },

}
