
export const insuranceActiveStatuses = [
  'aktiv', 
  'Angebot',
  'angebot',
  'Switch',
  'beantragt',
  'Mahnung',
  'Nachbearbeitung'

];

export function isInsuranceActive(status) {
  return insuranceActiveStatuses.indexOf(status) >= 0;
}

export function getInsurancePeriod(period) {
  switch (period) {
      case 'monatlich':
          return 12;
      case 'zweimonatlich':
          return 6;
      case 'vierteljährlich':
          return 4;
      case 'halbjährlich':
          return 2;
      case 'jährlich':
          return 1;
      default:
          return 0;
  }
}

export const insuranceCategoriesDisplayName = {
    'VORSORGE': 'Vorsorge',
    'GESUNDHEIT': 'Gesundheit und Existenz',
    'SACHEN': 'Besitz und Eigentum',
    'BETRIEBLICH': 'Betrieb',
    'SONSTIG': 'Sonstige',
    'NEW': "Neuer Vertrag"
};

export function getInsurancePeriodLabel(period) {
  if (period === '1/1') {
      return 'jährlich';
  } else if (period === '1/4') {
      return 'vierteljährlich';
  } else if (period === '1/6') {
      return 'halbjährlich';
  } else if (period === '1/12') {
      return 'monatlich';
  }
  return period;
}

export class InsuranceTable {
  static getTableHeader() {
        return [
            {key: 'bezeichnung', priority: 1},
            {key: 'gesellschaft', priority: 3, template: 'gesellschaft'},
            {key: 'status', priority: 2, template: 'status'},
            {key: 'vtgGruppe', priority: 4},
            {key: 'versObject', priority: 5},
            {key: 'praemie', priority: 2},
            {key: 'tarif', priority: 6},
            {key: 'kategorie', priority: 6},
            {key: 'nummer', priority: 6},
            {key: 'beginn', priority: 6},
            {key: 'ende', priority: 6},
            {key: 'zw', priority: 6, template: 'zw'},
            {key: 'rueckkaufswert', priority: 6},
            {key: 'produktbez', priority: 6},
            {key: 'doks', priority: 6, hidden: true},
            {key: 'sparten', priority: 6, hidden: true},
            {key: 'versicherungsspate_original', priority: 6},
            {key: 'id', priority: 6, template: 'id'}
        ];
  }

  static getGroup() {
    return [
      {key: 'bezeichnung', groupClass: ['col-12', '', 'row nav-tabs']},
      {key: 'id', groupClass: ['col-12', '', 'row nav-tabs']}
    ];
  }

  static getDepotAuszugHeaders() {
    return [
      {key: 'WERTPAPIER_NAME', priority: 1},
      {key: 'ANTEILE', priority: 3},
      {key: 'GUV', priority: 1},
      {key: 'GUV_P', priority: 1},
      {key: 'GUV_PA', priority: 1},
      {key: 'Euro', priority: 1},
      {key: 'LAGERSTELLE', priority: 5},
      {key: 'GESELLSCHAFT', priority: 5},
      {key: 'WAEHRUNG', priority: 5},
      {key: 'WECHSELKURS', priority: 5},
      {key: 'WERT_FW', priority: 5, format: ['WAEHRUNG', 'symbol', '1.2-2']},
      {key: 'PREIS', priority: 5, format: ['WAEHRUNG', 'symbol', '1.2-2']},
      {key: 'ISIN', priority: 6},
      {key: 'KATEGORIE', priority: 6},
      {key: 'STAND_BEW', priority: 6},
      {key: 'STAND_ANTEILE', priority: 6},
      {key: 'PROZ_ANTEIL', priority: 6},
      {key: 'ANLAGE', priority: 6},
      {key: 'RK', priority: 6},
      {key: 'RABATT', priority: 6},
      {key: 'LEER1', priority: 6, hidden: true},
      {key: 'LEER2', priority: 6, hidden: true},
      {key: 'WKN', priority: 6},
      {key: 'RATING', priority: 6},
    ];
  }

  static getGuVHeaders() {
    return [
      { label: 'GuV €', key: 'GUV', dataType: 'Currency' },
      { label: 'GuV %', key: 'GUV_P', dataType: 'Percentage' },
      { label: 'GuV % p.a.', key: 'GUV_PA', dataType: 'Percentage' }
    ];
  }
}