export const MUTATION_PREFIX = 'VV: ';
export const ACTIONS_PREFIX = 'VV_ACTIONS_';
export const GETTERS_PREFIX = 'VV_GETTERS_';

export default {
  MUTATIONS: {
    GET_MENU_SUCCESS: MUTATION_PREFIX + 'GET_MENU_SUCCESS',
    GET_STRATEGIEN_SUCCESS: MUTATION_PREFIX + 'GET_STRATEGIEN_SUCCESS',
    SET_STRATEGIEN_FILTERS: MUTATION_PREFIX + 'SET_STRATEGIEN_FILTERS',
    GET_STRATEGIEN_FILTER_ITEMS_SUCCESS: MUTATION_PREFIX + 'GET_STRATEGIEN_FILTER_ITEMS_SUCCESS',
    GET_STRATEGIEN_SCHEMA_DATA_SUCCESS: MUTATION_PREFIX + 'GET_STRATEGIEN_SCHEMA_DATA_SUCCESS',
    GET_STRATEGIEN_SCHEMA_DATA_LOADING: MUTATION_PREFIX + 'GET_STRATEGIEN_SCHEMA_DATA_LOADING',
    GET_STRATEGIEN_POSITIONS_SUCCESS: MUTATION_PREFIX + 'GET_STRATEGIEN_POSITIONS_SUCCESS',
    GET_MISSING_LEGITIMATIONS_SUCCESS: MUTATION_PREFIX + 'GET_MISSING_LEGITIMATIONS_SUCCESS',
    PUT_SUBSCRIPTION: MUTATION_PREFIX + 'PUT_SUBSCRIPTION',
    RESET_STRATEGIEN_SCHEMA_DATA: MUTATION_PREFIX + 'RESET_STRATEGIEN_SCHEMA_DATA',
    UPLOAD_SCHEMA_DOCUMENT_SUCCESS: MUTATION_PREFIX + 'UPLOAD_SCHEMA_DOCUMENT_SUCCESS',
    SAVE_COMMENT_SUCCESS: MUTATION_PREFIX + 'SAVE_COMMENT_SUCCESS',
    UPDATE_SCHEMA_DATA_STORE: MUTATION_PREFIX + 'UPDATE_SCHEMA_DATA_STORE',
    UPDATE_SCHEMA_DATA_VARIANTE_STORE: MUTATION_PREFIX + 'UPDATE_SCHEMA_DATA_VARIANTE_STORE',
    UPDATE_SCHEMA_DATA_VARIANTE_POSITION_STORE: MUTATION_PREFIX + 'UPDATE_SCHEMA_DATA_VARIANTE_POSITION_STORE',
    SET_UPDATE_VARIANTEN_STEP: MUTATION_PREFIX + 'SET_UPDATE_VARIANTEN_STEP',
    GET_ARCHIV_LIST_SUCCESS: MUTATION_PREFIX + 'GET_ARCHIV_LIST_SUCCESS',
    WERTPAPIER_BEANTRAGEN_DATA_SUCCESS: MUTATION_PREFIX + 'WERTPAPIER_BEANTRAGEN_DATA_SUCCESS',
    SAVE_BESCHWERDE_SUCCESS: MUTATION_PREFIX + 'SAVE_BESCHWERDE_SUCCESS',
    RESET_BESCHWERDE: MUTATION_PREFIX + 'RESET_BESCHWERDE',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    DELETE_WERTPAPIER_SUCCESS: MUTATION_PREFIX + 'DELETE_WERTPAPIER_SUCCESS',
    GET_ZIELMARTKT_DATA_UPDATE_SUCCESS: MUTATION_PREFIX + 'GET_ZIELMARTKT_DATA_UPDATE_SUCCESS',
    GET_SUITABILITYRESULTLIST_SUCCESS: MUTATION_PREFIX + 'GET_SUITABILITYRESULTLIST_SUCCESS',
    GET_SUITABILITYRESULT_SUCCESS: MUTATION_PREFIX + 'GET_SUITABILITYRESULT_SUCCESS',
    GET_SUITABILITYSIGNATURES_SUCCESS: MUTATION_PREFIX + 'GET_SUITABILITYSIGNATURES_SUCCESS',
    UPDATE_VERWAHRSTELLE_SETTINGS: MUTATION_PREFIX + 'UPDATE_VERWAHRSTELLE_SETTINGS',
  },
  ACTIONS: {
    GET_MENU: ACTIONS_PREFIX + 'GET_MENU',
    GET_STRATEGIEN: ACTIONS_PREFIX + 'GET_STRATEGIEN',
    GET_STRATEGIEN_FILTER_ITEMS: ACTIONS_PREFIX + 'GET_STRATEGIEN_FILTER_ITEMS',
    GET_GENERIERTE_FACTSCHEET_PDF: ACTIONS_PREFIX + 'GET_GENERIERTE_FACTSCHEET_PDF',
    GET_HOCHGELADENE_FACTSCHEET_PDF: ACTIONS_PREFIX + 'GET_HOCHGELADENE_FACTSCHEET_PDF',
    GET_STRATEGIEN_SCHEMA_DATA: ACTIONS_PREFIX + 'GET_STRATEGIEN_SCHEMA_DATA',
    GET_STRATEGIEN_POSITIONS: ACTIONS_PREFIX + 'GET_STRATEGIEN_POSITIONS',
    GET_MISSING_LEGITIMATIONS: ACTIONS_PREFIX + 'GET_MISSING_LEGITIMATIONS',
    LOAD_SUBSCRIPTION: ACTIONS_PREFIX + 'LOAD_SUBSCRIPTION',
    UPDATE_SUBSCRIPTION: ACTIONS_PREFIX + 'UPDATE_SUBSCRIPTION',
    UPLOAD_SCHEMA_DOCUMENT: ACTIONS_PREFIX + 'UPLOAD_SCHEMA_DOCUMENT',
    DELETE_SCHEMA_DOCUMENT: ACTIONS_PREFIX + 'DELETE_SCHEMA_DOCUMENT',
    OPEN_SCHEMA_DOCUMENT_PDF: ACTIONS_PREFIX + 'OPEN_SCHEMA_DOCUMENT_PDF',
    SAVE_COMMENT: ACTIONS_PREFIX + 'SAVE_COMMENT',
    GET_PDF_MANUAL: ACTIONS_PREFIX + 'GET_PDF_MANUAL',
    GET_PDF_WK_KUNDE_DEPOTAUSZUG: ACTIONS_PREFIX + 'GET_PDF_WK_KUNDE_DEPOTAUSZUG',
    GET_PDF_FACTSHEET_VV_CALC: ACTIONS_PREFIX + 'GET_PDF_FACTSHEET_VV_CALC',
    GET_PDF_FACTSHEET: ACTIONS_PREFIX + 'GET_PDF_FACTSHEET',
    SAVE_SCHEMA: ACTIONS_PREFIX + 'SAVE_SCHEMA',
    SAVE_SCHEMA_VERSION: ACTIONS_PREFIX + 'SAVE_SCHEMA_VERSION',
    MAKE_NEW_VERSION: ACTIONS_PREFIX + 'MAKE_NEW_VERSION',
    DELETE_SCHEMA_VERSION: ACTIONS_PREFIX + 'DELETE_SCHEMA_VERSION',
    CLONE_SCHEMA_VERSION: ACTIONS_PREFIX + 'CLONE_SCHEMA_VERSION',
    FACTSHEET_HISTORY: ACTIONS_PREFIX + 'FACTSHEET_HISTORY',
    NEW_FACTSHEET: ACTIONS_PREFIX + 'NEW_FACTSHEET',
    CONVERT_PDF: ACTIONS_PREFIX + 'CONVERT_PDF',
    GET_COMBO_VALUES_VERSIONEN_PREVIOUS: ACTIONS_PREFIX + 'GET_COMBO_VALUES_VERSIONEN_PREVIOUS',
    GET_ARCHIV_LIST: ACTIONS_PREFIX + 'GET_ARCHIV_LIST',
    GET_ARCHIV_VARIANTE: ACTIONS_PREFIX + 'GET_ARCHIV_VARIANTE',
    MOVE_VARIANTE_INTO_ARCHIV: ACTIONS_PREFIX + 'MOVE_VARIANTE_INTO_ARCHIV',
    VV_GET_AVAILABLE_FONDS: ACTIONS_PREFIX + 'VV_GET_AVAILABLE_FONDS',
    VV_GET_NEW_FONDS: ACTIONS_PREFIX + 'VV_GET_NEW_FONDS',
    ADD_GENEHMIGT_WERTPAPIERE: ACTIONS_PREFIX + 'ADD_GENEHMIGT_WERTPAPIERE',
    DELETE_WERTPAPIER: ACTIONS_PREFIX + 'DELETE_WERTPAPIER',
    WERTPAPIER_BEANTRAGEN: ACTIONS_PREFIX + 'WERTPAPIER_BEANTRAGEN',
    SAVE_WERTPAPIER_BEANTRAGEN: ACTIONS_PREFIX + 'SAVE_WERTPAPIER_BEANTRAGEN',
    ZIELMARKT_UEBERNEHMEN: ACTIONS_PREFIX + 'ZIELMARKT_UEBERNEHMEN',
    SAVE_BESCHWERDE: ACTIONS_PREFIX + 'SAVE_BESCHWERDE',
    DELETE_STRATEGIE: ACTIONS_PREFIX + 'DELETE_STRATEGIE',
    CREATE_STRATEGIE: ACTIONS_PREFIX + 'CREATE_STRATEGIE',
    VERSION_STATUSCHANGE_CHECK: ACTIONS_PREFIX + 'VERSION_STATUSCHANGE_CHECK',
    VERSION_STATUSCHANGE: ACTIONS_PREFIX + 'VERSION_STATUSCHANGE',
    CALC_ZIELMARKT_DATA: ACTIONS_PREFIX + 'CALC_ZIELMARKT_DATA',
    CALC_ZIELMARKT_DATA_UPDATE: ACTIONS_PREFIX + 'CALC_ZIELMARKT_DATA_UPDATE',
    GET_SUITABILITYRESULTLIST: ACTIONS_PREFIX + 'GET_SUITABILITYRESULTLIST',
    GET_SUITABILITYRESULT: ACTIONS_PREFIX + 'GET_SUITABILITYRESULT',
    POST_SUITABILITYRESULT: ACTIONS_PREFIX + 'POST_SUITABILITYRESULT',
    GET_SUITABILITYSIGNATURES: ACTIONS_PREFIX + 'GET_SUITABILITYSIGNATURES',
    UPDATE_VERWAHRSTELLE_SETTINGS: ACTIONS_PREFIX + 'UPDATE_VERWAHRSTELLE_SETTINGS',
    GET_GEBUEHREN_MODELL_DATA: ACTIONS_PREFIX + 'GET_GEBUEHREN_MODELL_DATA',
  },
  GETTERS: {
    MENU: GETTERS_PREFIX + 'MENU',
    STRATEGIEN: GETTERS_PREFIX + 'STRATEGIEN',
    STRATEGIEN_FILTERS: GETTERS_PREFIX + 'STRATEGIEN_FILTERS',
    STRATEGIEN_EDIT_RIGHTS: GETTERS_PREFIX + 'STRATEGIEN_EDIT_RIGHTS',
    STRATEGIEN_MENU_ITEMS: GETTERS_PREFIX + 'STRATEGIEN_MENU_ITEMS',
    STRATEGIEN_SCHEMA_DATA: GETTERS_PREFIX + 'STRATEGIEN_SCHEMA_DATA',
    STRATEGIEN_SCHEMA_DATA_LOADING: GETTERS_PREFIX + 'STRATEGIEN_SCHEMA_DATA_LOADING',
    STRATEGIEN_POSITIONS: GETTERS_PREFIX + 'STRATEGIEN_POSITIONS',
    MISSING_LEGITIMATIONS: GETTERS_PREFIX + 'MISSING_LEGITIMATIONS',
    GET_SUBSCRIPTION: GETTERS_PREFIX + 'GET_SUBSCRIPTION',
    VV_ARCHIV: GETTERS_PREFIX + 'VV_ARCHIV',
    WERTPAPIER_BEANTRAGEN_DATA: GETTERS_PREFIX + 'WERTPAPIER_BEANTRAGEN_DATA',
    BESCHWERDE: GETTERS_PREFIX + 'BESCHWERDE',
    HAT_ADVISOR_RECHTE: GETTERS_PREFIX + 'HAT_ADVISOR_RECHTE',
    SUITABILITYRESULTLIST: GETTERS_PREFIX + 'SUITABILITYRESULTLIST',
    SUITABILITYRESULT: GETTERS_PREFIX + 'SUITABILITYRESULT',
    SUITABILITYSIGNATURES: GETTERS_PREFIX + 'SUITABILITYSIGNATURES'
  }
}
