var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.$appNavigation.currentMenu.label,
          subtitle: _vm.fvp.description + " " + _vm.fvp.create,
          actions: _vm.actions
        },
        on: {
          "action-PDF": function($event) {
            return _vm.fvpAction("pdf")
          },
          "action-XLS": function($event) {
            return _vm.fvpAction("xls")
          },
          "action-PDF_MAIL": function($event) {
            return _vm.fvpAction("mail")
          },
          "action-AN_EMP": function($event) {
            return _vm.fvpAction("an_emp")
          },
          "action-ORDER": function($event) {
            return _vm.fvpAction("order")
          },
          "action-EDIT_DESCRIPTION": _vm.editFVDesc
        }
      }),
      _vm.steps && _vm.isSmallOrMediumScreen
        ? _c(
            "BoxContainer",
            [
              _c("Stepper3", {
                attrs: {
                  stepperName: "fondsvergleich",
                  selectedStepKey: _vm.selectedStepKey,
                  selectedSubstepKey: _vm.selectedSubstepKey,
                  stepperService: _vm.steps
                },
                on: {
                  setStep: function($event) {
                    return _vm.setStepByKeyOrObject($event)
                  },
                  setSubstep: function($event) {
                    return _vm.setStepByKeyOrObject($event)
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.selected && !_vm.isSmallOrMediumScreen
        ? _c(
            "BoxContainer",
            [
              _c("Table", {
                attrs: {
                  title: "Ausgewählte Investmentfonds",
                  headers: _vm.selectedFundsHeaders,
                  mobileConfig: {
                    title: "Ausgewählte Investmentfonds",
                    headers: ["fondsname", "isin", "actions"],
                    disableClickRow: true
                  },
                  rows: _vm.fundsRows,
                  headerActions: _vm.tableActions,
                  rowId: "isin",
                  hidePagination: ""
                },
                on: {
                  "action-DELETE": function($event) {
                    return _vm.delFunds($event)
                  },
                  headerAction: _vm.handleHeaderAction
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "fondsname",
                      fn: function(ref) {
                        var fondsname = ref.fondsname
                        var isin = ref.isin
                        return [
                          _c(
                            "a",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.gotoFondsinfo(isin)
                                }
                              }
                            },
                            [_vm._v(_vm._s(fondsname))]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  739024575
                ),
                model: {
                  value: _vm.delSelection,
                  callback: function($$v) {
                    _vm.delSelection = $$v
                  },
                  expression: "delSelection"
                }
              })
            ],
            1
          )
        : _vm._e(),
      !_vm.isSmallOrMediumScreen
        ? _c("ContentWithStepper", {
            attrs: { stepperCollapsed: _vm.stepperCollapsed },
            scopedSlots: _vm._u(
              [
                {
                  key: "stepper",
                  fn: function() {
                    return [
                      _c(
                        "BoxContainer",
                        [
                          _c("VerticalStepper", {
                            attrs: {
                              stepType: "tab",
                              visible: true,
                              stepperName: "fondsvergleich",
                              selectedStepKey: _vm.selectedStepKey,
                              selectedSubstepKey: _vm.selectedSubstepKey,
                              stepperService: _vm.steps,
                              showCollapse: true
                            },
                            on: {
                              setStep: function($event) {
                                return _vm.setStep($event)
                              },
                              setSubstep: function($event) {
                                return _vm.setStep($event)
                              },
                              collapsed: function($event) {
                                _vm.stepperCollapsed = $event
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "content",
                  fn: function() {
                    return [
                      _c("CompareFunds", {
                        ref: "cmpFunds",
                        attrs: {
                          colorsOrder: _vm.colorsOrder,
                          setColorsOrder: _vm.setColorsOrder,
                          stepKey: _vm.selected,
                          settings: _vm.config
                        },
                        on: {
                          setStep: function($event) {
                            return _vm.setStep($event)
                          },
                          done: function($event) {
                            return _vm.doneStep($event)
                          }
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              3559020881
            )
          })
        : _vm._e(),
      _c(
        "BaseModal",
        {
          ref: "editBezeichnung",
          attrs: {
            modalTitle: "Fondsvergleich Bezeichnung",
            labelButtonConfirm: "Übernehmen",
            labelButtonCancel: "Abbrechen",
            size: "sm"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.onChangeDesc()
            }
          }
        },
        [
          _c("InputField", {
            attrs: { label: "" },
            model: {
              value: _vm.fvp.description,
              callback: function($$v) {
                _vm.$set(_vm.fvp, "description", $$v)
              },
              expression: "fvp.description"
            }
          })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "modalRef",
          attrs: {
            modalTitle: "Bitte einen Fondsvergleich starten",
            showConfirmButton: false,
            labelButtonCancel: "Schließen",
            isModalAsFullPage: false
          }
        },
        [_c("div")]
      ),
      _c(
        "BaseModal",
        {
          ref: "editModal",
          attrs: {
            modalTitle: _vm.editModalTitle,
            size: "lg",
            showCancelButton: false,
            actions: _vm.baseModalActions,
            showConfirmButton: false,
            autoCloseOnRouteNavigation: false
          },
          on: {
            onCloseButton: function($event) {
              return _vm.onCloseEditModal()
            },
            action: _vm.handleModalActions
          }
        },
        [
          _c("CompareFunds", {
            ref: "cmpFunds",
            attrs: {
              colorsOrder: _vm.colorsOrder,
              setColorsOrder: _vm.setColorsOrder,
              stepKey: _vm.selected,
              settings: _vm.config,
              source: "modal"
            },
            on: {
              setStep: function($event) {
                return _vm.setStep($event)
              },
              done: function($event) {
                return _vm.doneStep($event)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }