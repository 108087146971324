var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("span", { staticClass: "d-none d-lg-inline" }, [
      _vm._v("Anzahl angezeigter Datensätze: ")
    ]),
    _c("span", { staticClass: "d-none d-md-inline d-lg-none" }, [
      _vm._v("Anzahl Datensätze: ")
    ]),
    _c("span", { staticClass: "d-md-none" }, [_vm._v("Anzahl ")]),
    _vm._v(
      " " + _vm._s(_vm.displayedItems) + " von " + _vm._s(_vm.totalRows) + " "
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }