<template>
  <div>
    <div class="scans-container">
      <div class="box__container">
        <!-- <div class="box__title">Facsimile mit Stempel</div> -->
        <!-- <div style="font-size: 12px; font-style: italic;">Dieses Facsimile wird auf Formulare und Verträge aufgedruckt.</div> -->

        <ImageUpload
          class="scan-container"
          :title="`Facsimile ${suffix === 'STEMPEL' ? 'mit' : 'ohne'} Stempel`"
          hoverText="Facsimile mit Stempel hier ablegen"
          edgeCorrection
          :image="image"
          :loading="loading"
          :disabled="loading"
          :config="imageUploadConfig"
          uploadButtonText="Transparentes Facsimile hochladen"
          :hasEditTool="false"
          :hasPhotoButton="false"
          :hasUploadButton="false"
          @file="onFileChange"
          :actions="imageUploadActions"
          @change="onFileChange"
          @action-FORMBLATT_ERSTELLEN="openFacsimile()"
          @action-TRANSPARENTES_FACSIMILE_HOCHLADEN="onFilesChange"
          @action-FACSIMILE_PDF_HOCHLADEN="onFilesChange"
          @action-FACSIMILE_LOESCHEN="removeFile"
        />
        <div class="mt-4">
          Für die korrekte Verwendung des Facsimile muss der komplette Hintergrund
          gefärbt werden.
        </div>
        <div class="font-bold mt-2" v-if="uploadStatus">
          Bitte warten Sie während die Seiten analysiert werden.<AnimatedSpinner />
        </div>
        <BaseButtonsContainer class="mt-4" v-if="!isSmallScreen">
          <BaseButton @click="openFacsimile()">Formblatt erstellen</BaseButton>
          <BaseFileSelect
            accept=".gif, .png, .jpg, .jpeg"
            isSecondary
            @files="onFilesChange"
            >Transparentes Facsimile hochladen</BaseFileSelect
          >
          <BaseFileSelect accept=".pdf" isSecondary @files="onFilesChange"
            >Facsimile PDF hochladen</BaseFileSelect
          >
          <BaseButton accept=".pdf" isSecondary @click="removeFile"
            >Facsimile löschen</BaseButton
          >
        </BaseButtonsContainer>

        <!-- <div class="mt-2">{{filename}}</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/core/BaseButton.vue";
import BaseButtonsContainer from "@/components/core/BaseButtonsContainer.vue";
import BROKERDATA_TYPES from "@/store/brokerData/types";
import DragnDropArea from "@/components/fileUpload/DragnDropArea.vue";
import BaseFileSelect from "@/components/fileUpload/BaseFileSelect.vue";
import AnimatedSpinner from "@/components/core/AnimatedSpinner.vue";
import ImageUpload from "@/components/fileUpload/ImageUpload.vue";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import { mapGetters } from "vuex";
import { uploadFileChunked } from "@/helpers/upload-helper";
import { PhTrash } from "phosphor-vue";
import { DropdownBaseFileSelectAction, DropdownSimpleAction } from "../core/dropdown-actions/dropdown-actions-utils";
import CORE_TYPES from "@/store/core/types";

export default {
  mixins: [InteractiveHelpCommonsMixin],
  props: {
    personId: {
      type: String,
      default: "",
    },
    suffix: {
      type: String,
      default: "",
    },
  },
  components: {
    BaseButton,
    BaseButtonsContainer,
    DragnDropArea,
    BaseFileSelect,
    AnimatedSpinner,
    ImageUpload,
    PhTrash,
  },
  data() {
    return {
      imageUploadConfig: {
        height: "120px",
        fit: "contain",
      },
      uploadStatus: false,
      filename: "",
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      facsimileStore: BROKERDATA_TYPES.GETTERS.FACSIMILE,
      isSmallScreen: CORE_TYPES.GETTERS.IS_SMALL_SCREEN,
    }),
    imageUploadActions() {
      const actions = [];
      actions.push(new DropdownSimpleAction('FORMBLATT_ERSTELLEN', 'Formblatt erstellen'));
      actions.push(new DropdownBaseFileSelectAction('TRANSPARENTES_FACSIMILE_HOCHLADEN', 'Transparentes Facsimile hochladen', '.gif, .png, .jpg, .jpeg'));
      actions.push(new DropdownBaseFileSelectAction('FACSIMILE_PDF_HOCHLADEN', 'Facsimile PDF hochladen', '.pdf'));
      actions.push(new DropdownSimpleAction('FACSIMILE_LOESCHEN', 'Facsimile löschen'));
      
      return actions;
    },
    facsimile() {
      return this.facsimileStore || {};
    },
    image() {
      return (
        this.getImgSrc(
          this.suffix === "STEMPEL"
            ? this.facsimile.fileStempel
            : this.facsimile.fileOhneStempel
        ) || null
      );
    },
  },
  mounted() {
    if (this.$route.path.includes('ansprechpartner')) {
      var payload = { suffix: this.suffix, personId: this.personId };
      this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.LOAD_SAVED_FACSIMILE_MITARBEITER, payload);
    } else {
      this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.GET_FACSIMILE_MAKLER);
    }
  },
  methods: {
    removeFile() {
      if (this.image) {
        var payload = {
          suffix: this.suffix || "ohne",
          personId: this.personId || "",
        };
        this.$store.dispatch(
          BROKERDATA_TYPES.ACTIONS.REMOVE_FACSIMILE,
          payload
        );
      }
    },
    openFacsimile() {
      var payload = { suffix: this.suffix, personId: this.personId };
      this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.CREATE_FACSIMILE, payload);
    },
    onFilesChange(files) {
      const file = files?.length && files[0];
      file && this.onFileChange(file);
    },
    onFileChange(file) {
      if (file) {
        this.uploadStatus = true;
        uploadFileChunked(file).then((id) => {
          const payload = {
            fileId: id + "",
            personId: this.personId,
            suffix: this.suffix,
          };
          if (file.name?.endsWith(".pdf")) {
            this.$store
              .dispatch(BROKERDATA_TYPES.ACTIONS.UPLOAD_FACSIMILE_PDF, payload)
              .then((response) => {
                this.uploadStatus = null;
                const payload = {
                  suffix: this.suffix,
                  personId: this.personId,
                };
                this.$store.dispatch(
                  BROKERDATA_TYPES.ACTIONS.LOAD_SAVED_FACSIMILE_MITARBEITER,
                  payload
                );
              });
          } else {
            this.$store
              .dispatch(
                BROKERDATA_TYPES.ACTIONS.UPLOAD_FACSIMILE_IMAGE,
                payload
              )
              .then((response) => {
                this.uploadStatus = null;
              });
          }
        });
      }
    },
    getImgSrc(image) {
      if (image) {
        var byteCharacters = atob(image.bytes);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const typedArray = new Uint8Array(byteNumbers);
        const blob = new Blob([typedArray], { type: image.contentType });
        return URL.createObjectURL(blob);
      }
      return "";
    },
  },
};
</script>

<style scoped>
  .scans-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: center;
    gap: 10px;
  }
  .scans-container > div {
    flex: 1 1 344px;
    max-width: 100%;
  }
  .scan-container {
    margin: 0;
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }

  .scan-container.no-image {
    max-width: 344px;
  }
</style>