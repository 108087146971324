var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "ab6fc918-4b91-4509-b18c-ea99d764448f",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        width: "847",
        height: "623.67314",
        viewBox: "0 0 847 623.67314"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M786.62156,353.71108H494.78318a17.52,17.52,0,0,1-17.5-17.5V164.05728a13.103,13.103,0,0,1,13.08789-13.08838H790.82444a13.31242,13.31242,0,0,1,13.29712,13.29736V336.21108A17.52,17.52,0,0,1,786.62156,353.71108Z",
          transform: "translate(-176.5 -138.16343)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M786.62156,353.71108H494.78318a17.52,17.52,0,0,1-17.5-17.5V164.05728a13.103,13.103,0,0,1,13.08789-13.08838H790.82444a13.31242,13.31242,0,0,1,13.29712,13.29736V336.21108A17.52,17.52,0,0,1,786.62156,353.71108ZM490.37107,152.9689a11.10054,11.10054,0,0,0-11.08789,11.08838v172.1538a15.51752,15.51752,0,0,0,15.5,15.5H786.62156a15.51752,15.51752,0,0,0,15.5-15.5V164.26626a11.31,11.31,0,0,0-11.29712-11.29736Z",
          transform: "translate(-176.5 -138.16343)",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M510.78315,336.21128h259.8385a16,16,0,0,0,16-16V194.66343a16,16,0,0,0-16-16H510.78315a16,16,0,0,0-16,16V320.21128A16,16,0,0,0,510.78315,336.21128Z",
          transform: "translate(-176.5 -138.16343)",
          fill: "#f2f2f2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M804.55906,163.11362h-328v-8.40039a16.66558,16.66558,0,0,1,16.74184-16.5498H787.81721a16.66551,16.66551,0,0,1,16.74185,16.5498Z",
          transform: "translate(-176.5 -138.16343)",
          fill: _vm.color
        }
      }),
      _c("circle", {
        attrs: {
          cx: "331.64694",
          cy: "12.5",
          r: "4.28342",
          fill: _vm.secondColor
        }
      }),
      _c("circle", {
        attrs: {
          cx: "347.90575",
          cy: "12.5",
          r: "4.28342",
          fill: _vm.secondColor
        }
      }),
      _c("circle", {
        attrs: {
          cx: "364.16455",
          cy: "12.5",
          r: "4.28342",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M557.0331,290.43735a33,33,0,1,1,33-33A33.03734,33.03734,0,0,1,557.0331,290.43735Z",
          transform: "translate(-176.5 -138.16343)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M749.37171,284.43721H621.65412a8.18906,8.18906,0,0,1-8.26414-7.452,8.00872,8.00872,0,0,1,7.98173-8.548l127.68316,0a8.212,8.212,0,0,1,8.30064,7.48591A8.00869,8.00869,0,0,1,749.37171,284.43721Z",
          transform: "translate(-176.5 -138.16343)",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M682.37171,250.43721H621.65412a8.18906,8.18906,0,0,1-8.26414-7.452,8.00872,8.00872,0,0,1,7.98173-8.548l60.68316,0a8.212,8.212,0,0,1,8.30064,7.48591A8.00869,8.00869,0,0,1,682.37171,250.43721Z",
          transform: "translate(-176.5 -138.16343)",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M936.59569,590.56453a10.05576,10.05576,0,0,0-7.83053-13.283L922.73563,542.059l-13.55516,12.69217,7.80389,31.502a10.11028,10.11028,0,0,0,19.61133,4.31131Z",
          transform: "translate(-176.5 -138.16343)",
          fill: "#ffb8b8"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M927.58909,573.71275l-13.308,3.49237a2.5104,2.5104,0,0,1-3.04025-1.73785l-23.91451-84.7306-7.22508-21.615a13.01889,13.01889,0,0,1,13.90716-17.05259h0a13.00985,13.00985,0,0,1,10.92414,9.23037l19.10962,64.59317,5.39471,45.10542a2.51079,2.51079,0,0,1-1.31461,2.507A2.46213,2.46213,0,0,1,927.58909,573.71275Z",
          transform: "translate(-176.5 -138.16343)",
          fill: "#ccc"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "702.45333",
          cy: "275.05226",
          r: "24.56103",
          fill: "#ffb8b8"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "630.137 611.049 618.386 607.553 626.28 560.565 643.623 565.725 630.137 611.049",
          fill: "#ffb8b8"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M806.24467,761.49492l-37.88894-11.27341.14257-.47925a15.38606,15.38606,0,0,1,19.135-10.36l.00093.00028,23.14116,6.88547Z",
          transform: "translate(-176.5 -138.16343)",
          fill: "#2f2e41"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "758.875 610.447 746.615 610.447 740.783 563.159 752.877 563.16 758.875 610.447",
          fill: "#ffb8b8"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M938.50168,760.49492l-39.53051-.00146v-.5a15.38605,15.38605,0,0,1,15.38647-15.38624h.001l24.1438.001Z",
          transform: "translate(-176.5 -138.16343)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M811.15723,735.31018H798.84766a4.50092,4.50092,0,0,1-4.426-5.31348c-7.84961-3.49194,34.416-206.18506,44.40234-202.25586l.32862-.47363,50.28711,4.10351c22.52392,45.07935,36.78222,119.6731,50.123,197.544a4.49968,4.49968,0,0,1-4.14331,5.60156l-13.83056.6709a4.4788,4.4788,0,0,1-4.39649-2.82324l-52.989-132.46778a1.433,1.433,0,0,0-1.427-.94238,1.4705,1.4705,0,0,0-1.38233,1.00684L815.408,732.28772A4.50394,4.50394,0,0,1,811.15723,735.31018Z",
          transform: "translate(-176.5 -138.16343)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M890.49292,535.33752l-.38428-.02832-53.54052-4.041,9.39135-59.72949a31.273,31.273,0,0,1,26.72168-26.21484h0q.61707-.08643,1.24243-.16895a30.90083,30.90083,0,0,1,28.08594,11.24024,31.28947,31.28947,0,0,1,5.35449,30.16894Z",
          transform: "translate(-176.5 -138.16343)",
          fill: "#ccc"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M926.64624,441.31018c-16.88867,7.7257-39.80322,6.34338-66,0,3.62511-9.92952,6.45016-19.52042,3.24588-26.56067a21.4429,21.4429,0,0,1-.70354-17.16329,33.00958,33.00958,0,0,1,30.45763-20.276h.00006a33,33,0,0,1,33,33Z",
          transform: "translate(-176.5 -138.16343)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M693.14622,241.39236h21a0,0,0,0,1,0,0v25.82456a0,0,0,0,1,0,0h-44a0,0,0,0,1,0,0v-2.82458A23,23,0,0,1,693.14622,241.39236Z",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M835.41054,596.12a10.05577,10.05577,0,0,0-.69264-15.40376l11.17984-33.941-17.92249,4.85985L820.1064,583.121a10.11027,10.11027,0,0,0,15.30414,12.999Z",
          transform: "translate(-176.5 -138.16343)",
          fill: "#ffb8b8"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M835.3514,577.01248l-13.39283-3.15137a2.51038,2.51038,0,0,1-1.87133-2.96l18.58129-86.05762,3.74683-22.48046a13.0189,13.0189,0,0,1,20.27686-8.54688h0a13.00985,13.00985,0,0,1,5.3247,13.27344L854.628,533.10623l-16.37207,42.374a2.51078,2.51078,0,0,1-2.33618,1.59864A2.46225,2.46225,0,0,1,835.3514,577.01248Z",
          transform: "translate(-176.5 -138.16343)",
          fill: "#ccc"
        }
      }),
      _c("path", {
        attrs: {
          d: "M1022.5,761.83657h-381a1,1,0,0,1,0-2h381a1,1,0,0,1,0,2Z",
          transform: "translate(-176.5 -138.16343)",
          fill: "#ccc"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M311.534,523.10719a10.52541,10.52541,0,0,0-1.5682.53668l-41.67414-26.88411.57487-12.03428-18.04848-3.24138L247.73,502.68954a8,8,0,0,0,4.59431,8.43043l51.32653,23.43035a10.49709,10.49709,0,1,0,7.88321-11.44313Z",
          transform: "translate(-176.5 -138.16343)",
          fill: "#ffb8b8"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M266.59137,496.01878l-22.34987-4.126a4.5,4.5,0,0,1-3.44084-5.88157l7.33449-21.43879a12.49741,12.49741,0,0,1,24.566,4.60961l-.79572,22.57144a4.5,4.5,0,0,1-5.31406,4.26532Z",
          transform: "translate(-176.5 -138.16343)",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M701.83075,433.85665h-381a18.88956,18.88956,0,0,0-9.36035,2.47,18.52688,18.52688,0,0,0-1.96972,1.29,18.96417,18.96417,0,0,0-7.66993,15.24v198a19.01691,19.01691,0,0,0,19,19h381a19.01722,19.01722,0,0,0,19-19v-198A19.01722,19.01722,0,0,0,701.83075,433.85665Z",
          transform: "translate(-176.5 -138.16343)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M701.83075,433.85665h-381a18.88956,18.88956,0,0,0-9.36035,2.47,18.52688,18.52688,0,0,0-1.96972,1.29,18.96417,18.96417,0,0,0-7.66993,15.24v198a19.01691,19.01691,0,0,0,19,19h381a19.01722,19.01722,0,0,0,19-19v-198A19.01722,19.01722,0,0,0,701.83075,433.85665Zm17,217a17.02443,17.02443,0,0,1-17,17h-381a17.02412,17.02412,0,0,1-17-17v-198a17.09478,17.09478,0,0,1,11.77979-16.18c.14014-.04.27-.09.41016-.13a16.99642,16.99642,0,0,1,4.81005-.69h381a17.02442,17.02442,0,0,1,17,17Z",
          transform: "translate(-176.5 -138.16343)",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M510.91423,551.77248a8.98713,8.98713,0,0,1-4.33887-1.11865L307.53214,441.10109a.99991.99991,0,0,1,.96436-1.752L507.53971,548.90187a6.98192,6.98192,0,0,0,6.64136.0586L716.50065,442.231a.99981.99981,0,1,1,.93311,1.76855L515.11418,550.729A8.99244,8.99244,0,0,1,510.91423,551.77248Z",
          transform: "translate(-176.5 -138.16343)",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d: "M365.39738,619.85646h-28a8,8,0,0,1,0-16h28a8,8,0,0,1,0,16Z",
          transform: "translate(-176.5 -138.16343)",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d: "M398.39738,646.85646h-61a8,8,0,0,1,0-16h61a8,8,0,0,1,0,16Z",
          transform: "translate(-176.5 -138.16343)",
          fill: "#3f3d56"
        }
      }),
      _c("circle", {
        attrs: { cx: "334.33439", cy: "413.69304", r: "32", fill: _vm.color }
      }),
      _c("path", {
        attrs: {
          d:
            "M365.77675,452.68236l141.0238,58.83775a2,2,0,0,0,1.52036.00815l145.392-58.83774a2,2,0,0,0-.75026-3.85395H366.54685A2,2,0,0,0,365.77675,452.68236Z",
          transform: "translate(-176.5 -138.16343)",
          fill: "#f2f2f2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M344.30855,437.86922a10.52722,10.52722,0,0,0-.2393,1.64013l-42.95745,24.782-10.44141-6.01093-11.13116,14.57227,17.45034,12.43755a8,8,0,0,0,9.59819-.23384l44.29652-34.94583a10.4971,10.4971,0,1,0-6.57573-12.24133Z",
          transform: "translate(-176.5 -138.16343)",
          fill: "#ffb8b8"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M299.67569,465.46508l-13.88374,17.994a4.5,4.5,0,0,1-6.80392.37267L263.2707,467.51051a12.49742,12.49742,0,0,1,15.32664-19.74414l19.713,11.02279a4.5,4.5,0,0,1,1.36538,6.67592Z",
          transform: "translate(-176.5 -138.16343)",
          fill: "#3f3d56"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "66.32068",
          cy: "259.77667",
          r: "24.56103",
          fill: "#ffb8b8"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "89.505 611.447 101.765 611.447 107.597 564.159 89.503 564.16 89.505 611.447",
          fill: "#ffb8b8"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M262.87789,745.6082l24.1438-.001h.001a15.38605,15.38605,0,0,1,15.38648,15.38624v.5l-39.53052.00146Z",
          transform: "translate(-176.5 -138.16343)",
          fill: "#2f2e41"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "47.505 611.447 59.765 611.447 65.597 564.159 47.503 564.16 47.505 611.447",
          fill: "#ffb8b8"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M220.87789,745.6082l24.1438-.001h.001a15.38605,15.38605,0,0,1,15.38648,15.38624v.5l-39.53052.00146Z",
          transform: "translate(-176.5 -138.16343)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M262.32655,731.664l-12.50537-96.75391a.492.492,0,0,0-.5061-.46679.47536.47536,0,0,0-.49244.48144l-3.59375,95.34961a4.48624,4.48624,0,0,1-4.49707,4.33106H225.88221a4.4738,4.4738,0,0,1-4.49853-4.3916l-3.855-158.86524,66.45605.66406,1.65894,159.376a4.5034,4.5034,0,0,1-1.57837,3.32031,4.43823,4.43823,0,0,1-3.45923,1.04l-14.40063.04A4.5042,4.5042,0,0,1,262.32655,731.664Z",
          transform: "translate(-176.5 -138.16343)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M216.54432,575.69333a4.49718,4.49718,0,0,1-2.83984-3.53028l-.66333-87.30957c-2.937-19.43945-6.41089-38.48828,10.58569-48.33789l1.49756-4.49316a2.51013,2.51013,0,0,1,2.30029-1.709l32.24268-.92089a2.47891,2.47891,0,0,1,2.36914,1.51367l2.39819,5.59668.85157.48535a47.41234,47.41234,0,0,1,23.59839,46.80957l-1.84351,15.56445-1.30957,72.73828a4.539,4.539,0,0,1-2.36206,3.88672c-9.3667,5.12891-19.74365,6.88282-29.56275,6.88282C237.30873,582.87009,222.393,577.915,216.54432,575.69333Z",
          transform: "translate(-176.5 -138.16343)",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M242.021,423.41106a26.205,26.205,0,1,1,4.91017-51.8253c5.40118-3.27952,11.6809-5.48628,17.97479-4.925s12.51834,4.29789,14.87192,10.16207-.17488,13.59412-6.02709,15.97731c-3.75183,1.52787-7.98866.78692-11.99254.1708s-8.37171-1.01817-11.85635,1.04774-5.05106,7.60548-1.93025,10.18835a10.73849,10.73849,0,0,1,3.0842,11.82027C249.48733,420.0836,245.20644,423.34225,242.021,423.41106Z",
          transform: "translate(-176.5 -138.16343)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d: "M333.5,761.83657h-156a1,1,0,0,1,0-2h156a1,1,0,0,1,0,2Z",
          transform: "translate(-176.5 -138.16343)",
          fill: "#ccc"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }