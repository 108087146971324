var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "einzelfonds_container" },
    [
      _c(
        "div",
        { staticClass: "banner" },
        [_c("editable-frame", { attrs: { type: "einzelfonds_title_page" } })],
        1
      ),
      _c("hr"),
      _c("div", { staticClass: "text_only_wrapper" }, [
        _c(
          "div",
          { staticClass: "text_only" },
          [_c("editable-frame", { attrs: { type: "einzelfonds_intro_text" } })],
          1
        )
      ]),
      _c("div", { staticClass: "banner" }, [
        _vm._v(" EINZELFONDS AUSWÄHLEN, ANSEHEN, IN DEN WARENKORB LEGEN! ")
      ]),
      _c("hr"),
      !_vm.isIntern
        ? _c(
            "div",
            { staticClass: "einzelfonds" },
            [_c("fondsshop-element", { attrs: { zeige: "nurFonds" } })],
            1
          )
        : _vm._e(),
      _vm.isIntern
        ? _c("div", { staticClass: "fc-alert fc-alert-warning" }, [
            _vm._v("Hier kommt der Einzelfondsanteil.")
          ])
        : _vm._e(),
      _c("risiko-hinweise")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }