var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _vm.isReady
        ? [
            _c("SimpleAdditionalDocumentsTable", {
              attrs: {
                nodeId: _vm.nodeId,
                listViewOnly: false,
                fileId: _vm.fileId,
                uploadType: _vm.uploadType
              }
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }