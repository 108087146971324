<template>
  <div>
    <InputField
      v-if="isFK && isFax"
      label="Fax-Nummer"
      v-model="form.faxNummer"
      @change="dataChanged();"
    />

    <template v-if="isLegitimationsurkunde">
      <DatePickerField
        label="Ablaufdatum"
        isValueAsString
        v-model="form.ablaufdatum"
        @change="dataChanged(); addAblaufdatumErrorIfNeeded();"
      />

      <InputToggleSwitch
        v-if="isFA"
        label="Kein Ablaufdatum für die Legitimationsurkunde"
        inLineLabel
        v-model="form.keinAblaufdatum"
        @input="dataChanged(); addAblaufdatumErrorIfNeeded();"
      />
    </template>

    <div class="row mt-0 mb-0">
      <div class="col col-sm-8 col-12">
        <DatePickerField
          :label="standVomLabel"
          isValueAsString
          v-model="form.standVom"
          @change="dataChanged();"
        />
      </div>
      <div class="col col-sm-4 col-12">
        <TimePicker
          label="Uhrzeit"
          v-model="form.uhrzeit"
          @change="dataChanged();"
        />
      </div>
    </div>

    <InputField
      label="Beschreibung"
      v-model="form.beschreibung"
      @change="dataChanged();"
    />
  </div>
</template>

<script>
import validator from '@/mixins/validator';

import TimePicker from '@/components/core/forms/TimePicker.vue';
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import InputField from '@/components/core/forms/InputField.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import customerDocumentUploadMixin from './customer-document-upload-mixin';

const ABLAUFDATUM_ERROR_MESSAGE = "Ein Ablaufdatum muss angegeben werden oder das Häkchen für 'Kein Ablaufdatum' muss gesetzt sein!";

export default {
  mixins: [validator, customerDocumentUploadMixin],
  computed: {
    isFax() {
      return this.deckblattType === 'FAX';
    },
    isLegitimationsurkunde() {
      return this.deckblattType === 'LEGITIMATIONSURKUNDE';
    },
    standVomLabel() {
      return this.isFK ? 'Stand vom / Fax ges. am' : 'Stand vom';
    },
  },
  methods: {
    addAblaufdatumErrorIfNeeded() {
      if(!this.isFA || !this.isLegitimationsurkunde) return;

      if(!this.form.keinAblaufdatum && !this.form.ablaufdatum) {
        this.$nextTick(() => this.$pushErrors('form.ablaufdatum', ABLAUFDATUM_ERROR_MESSAGE));
      } else {
        this.$nextTick(() => this.validation.removeError('form.ablaufdatum', ABLAUFDATUM_ERROR_MESSAGE));
      }
    },
  },
  mounted() {
    this.addAblaufdatumErrorIfNeeded();
    this.setStandDatumZeit();
  },
  validators: {},
  components: {
    TimePicker,
    DatePickerField,
    InputField,
    InputToggleSwitch,
  },
}
</script>
