import { ROLES } from '@/router/roles'

import UndrawDiary from '@/components/icons/undraw/UndrawDiary.vue'
import UndrawInvest from '@/components/icons/undraw/UndrawInvest.vue'
const DBM_COMPONENT_HOME = () => import(/* webpackChunkName: "communicationoverview" */ '@/views/dbm/DbmOverview.vue');

export default [
  {
    path: '',
    redirect: 'dbm'
  },
  {
    path: 'dbm',
    component: DBM_COMPONENT_HOME,
    meta: {
      routeId: '8dcadb56-f983-11ec-b939-0242ac120002',
      roles: {
        allowed: [
          [ROLES.DBM],

        ]
      },
      breadcrumb: {
        reset: true,
      },
    }
  },

  {
    path: 'dbm_tree',
    name: 'dbm_tree',
    component: () => import(
      '@/views/dbm/DbmTree.vue'),
    meta: {

      menu: {
        label: 'blockers',
        component: UndrawDiary,
      },
      roles: {
        allowed: [

          [ROLES.DBM]
        ]
      },

      breadcrumb: {
        label: 'blockers',
      },
    },
  },
  {
    path: 'dbm_table_active',
    name: 'active sessions',
    component: () => import(
      '@/views/dbm/DbmActive.vue'),
    meta: {

      menu: {
        label: 'active sessions',
        component: UndrawDiary,
      },
      roles: {
        allowed: [

          [ROLES.DBM]
        ]
      },

      breadcrumb: {
        label: 'active sessions',
      },
    },
  },
  {
    path: 'dbm_runned_interfaces',
    name: 'runned interfaces',
    component: () => import(
      '@/views/dbm/DbmRunnedInterfaces.vue'),
    meta: {

      menu: {
        label: 'runned interfaces',
        component: UndrawDiary,
      },
      roles: {
        allowed: [

          [ROLES.DBM]
        ]
      },

      breadcrumb: {
        label: 'runned interfaces',
      },
    },
  },
  
  {
    path: 'dbm_undo',
    name: 'Undo',
    component: () => import(
      '@/views/dbm/DbmUndo.vue'),
    meta: {

      menu: {
        label: 'Undo',
        component: UndrawDiary,
      },
      roles: {
        allowed: [

          [ROLES.DBM]
        ]
      },

      breadcrumb: {
        label: 'Undo',
      },
    },
  },


  {
    path: 'dbm_all_sessions',
    name: 'dbm_all_sessions',
    component: () => import(
      '@/views/dbm/DbmAll.vue'),
    meta: {
      menu: {
        label: 'all sessions',
        component: UndrawDiary,
      },
      roles: {
        allowed: [

          [ROLES.DBM]
        ]
      },

      breadcrumb: {
        label: 'all sessions',
      },
    },
  },
  {
    path: 'dbm_transactions',
    name: 'dbm_transactions',
    component: () => import(
      '@/views/dbm/DbmTransactions'),
    meta: {

      menu: {
        label: 'transactions',
        component: UndrawDiary,
      },
      roles: {
        allowed: [
          [ROLES.DBM]
        ]
      },

      breadcrumb: {
        label: 'transactions',
      },
    },
  },
  {
    path: 'dbm_standby',
    name: 'dbm_standby',
    component: () => import(
      '@/views/dbm/DbmStandby'),
    meta: {

      menu: {
        label: 'Standby',
        component: UndrawDiary,
      },
      roles: {
        allowed: [
          [ROLES.DBM]
        ]
      },

      breadcrumb: {
        label: 'Standby',
      },
    },
  },
  {
    path: 'dbm_cache',
    name: 'dbm_cache',
    component: () => import(
      '@/views/dbm/DbmCache'),
    meta: {

      menu: {
        label: 'Objects in cache',
        component: UndrawDiary,
      },
      roles: {
        allowed: [
          [ROLES.DBM]
        ]
      },

    },
    breadcrumb: {
      label: 'Objects in cache',

    }
  },
  {
    path: 'dbm_freespace',
    name: 'dbm_freespace',
    component: () => import(
      '@/views/dbm/DbmFreeSpace'),
    meta: {

      menu: {
        label: 'Free space',
        component: UndrawDiary,
      },
      roles: {
        allowed: [
          [ROLES.DBM]
        ]
      },

    },
    breadcrumb: {
      label: 'Free space',

    }
  },
  {
    path: 'dbm_unused_space',
    name: 'dbm_unused_space',
    component: () => import(
      '@/views/dbm/DbmUnusedSpace'),
    meta: {

      menu: {
        label: 'unused space',
        component: UndrawDiary,
      },
      roles: {
        allowed: [
          [ROLES.DBM]
        ]
      },

    },
    breadcrumb: {
      label: 'Unused space',

    }
  },
  {
    path: 'dbm_longops',
    name: 'dbm_longops',
    component: () => import(
      '@/views/dbm/DbmLongops'),
    meta: {

      menu: {
        label: 'Long operations',
        component: UndrawDiary,
      },
      roles: {
        allowed: [
          [ROLES.DBM]
        ]
      },

    },
    breadcrumb: {
      label: 'Long operations',

    }
  },
  {
    path: 'dbm_rman_backups',
    name: 'dbm_rman_backups',
    component: () => import(
      '@/views/dbm/DbmRmanBackups'),
    meta: {

      menu: {
        label: 'Backups',
        component: UndrawDiary,
      },
      roles: {
        allowed: [
          [ROLES.DBM]
        ]
      },

    },
    breadcrumb: {
      label: 'Backups',

    }
  },
  {
    path: 'dbm_g',
    name: 'dbm_g',
    props: route => ({ statId: 'IO', }),
    component: () => import( '@/views/dbm/DbmG'),
    meta: {
      menu: {
        label: 'Diagram',
        component: UndrawInvest,
      },
      roles: {
        allowed: [
          [ROLES.DBM]
        ]
      },
    },    
  },

  {
    path: 'avg_response_time',
    name: 'avg_response_time',
    component: () => import('@/views/dbm/AvgResponseTime.vue'),
    meta: {
      roles: {
        allowed: [
          [ROLES.DBM]
        ]
      },
    }
  },

  {
    path: 'avg_response_time_per_endpoint',
    name: 'avg_response_time_per_endpoint',
    component: () => import('@/views/dbm/AvgResponseTimePerEndpoint.vue'),
    meta: {
      roles: {
        allowed: [
          [ROLES.DBM]
        ]
      },
    }
  },
  
 /* {
    path: 'dbm_g_rio',
    name: 'dbm_g_rio',
    props: route => ({ statId: 'READ_IO', }),
    component: () => import( '@/views/dbm/DbmG'),
    meta: {
      menu: {
        label: 'Diagram Read IO',
        component: UndrawInvest,
      },
      roles: {
        allowed: [
          [ROLES.DBM]
        ]
      },
    },    
  },
  {
    path: 'dbm_g_wio',
    name: 'dbm_g_wio',
    props: route => ({ statId: 'WRITE_IO', }),
    component: () => import( '@/views/dbm/DbmG'),
    meta: {
      menu: {
        label: 'Diagram Write IO',
        component: UndrawInvest,
      },
      roles: {
        allowed: [
          [ROLES.DBM]
        ]
      },
    },    
  },

  {
    path: 'dbm_g_cb',
    name: 'dbm_g_cb',
    props: route => ({ statId: 'CHANGED_BLOCKS', }),
    component: () => import( '@/views/dbm/DbmG'),
    meta: {
      menu: {
        label: 'Diagram changed blocks',
        component: UndrawInvest,
      },
      roles: {
        allowed: [
          [ROLES.DBM]
        ]
      },
    },    
  },
  {
    path: 'dbm_g_pr',
    name: 'dbm_g_pr',
    props: route => ({ statId: 'CPU', }),
    component: () => import( '@/views/dbm/DbmG'),
    meta: {
      menu: {
        label: 'Diagram CPU',
        component: UndrawInvest,
      },
      roles: {
        allowed: [
          [ROLES.DBM]
        ]
      },
    },    
  }*/

]