<template>
  <button v-if="isBackButtonEnabled" v-cloak type="button" 
    class="back-button-navigation__container btn-clear clickable" @click="goToLastAction()">
    <PhCaretLeft :size="18" class="back-button-navigation--icon" />
  </button>
</template>

<script>
import { PhCaretLeft, } from 'phosphor-vue';

export default {
  name: 'BackButtonNavigation',
  components: {
    PhCaretLeft,
  },
  computed: {
    isBackButtonEnabled() {
      return this.$breadcrumbs?.length > 0;
    },
  },
  methods: {
    goToLastAction() {
      this.$goToLastPage();

      this.$emit('backTrigged');
    },
  },
}
</script>

<style lang="scss" scoped>
.back-button-navigation__container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
