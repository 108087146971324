var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box__container" }, [
    _c("div", { staticClass: "box__title" }, [_vm._v("Bankverbindung")]),
    _vm.isMainPerson
      ? _c(
          "div",
          [
            _vm.isEditable
              ? _c(
                  "BaseButton",
                  {
                    staticClass: "mb-3",
                    on: {
                      click: function($event) {
                        return _vm.openModalEditBankAccount(true)
                      }
                    }
                  },
                  [_vm._v("Hinzufügen")]
                )
              : _vm._e(),
            _c("Table", {
              attrs: {
                tableId: "46c30c0a-aa2b-4885-b3ef-cfedaeec59ce",
                headers: _vm.headers,
                rows: _vm.rows,
                mobileConfig: {
                  title: "Bankverbindung",
                  headers: ["bankName", "owner", "iban"]
                },
                exportConfig: { roottext: "Bankverbindung" }
              },
              on: {
                "action-EDIT": function($event) {
                  return _vm.openModalEditBankAccount(false, $event)
                },
                "action-DELETE": _vm.deleteBankverbindungConfirmation
              }
            }),
            _c(
              "BaseModal",
              {
                ref: "editBankAccount",
                attrs: {
                  modalTitle: "Bankverbindung",
                  confirmDisabled:
                    !_vm.isEditable || _vm.isFormValid || _vm.checking
                },
                on: {
                  onConfirmButton: function($event) {
                    return _vm.saveBankAccount()
                  },
                  close: _vm.removeEditBankAccountIdFromQuery
                }
              },
              [
                _c("InputField", {
                  attrs: {
                    label: "Inhaber Vorname",
                    validateUntouched: "",
                    disabled: !_vm.isEditable
                  },
                  model: {
                    value: _vm.editAccount.ownerFirstName,
                    callback: function($$v) {
                      _vm.$set(_vm.editAccount, "ownerFirstName", $$v)
                    },
                    expression: "editAccount.ownerFirstName"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    label: "Inhaber Nachname",
                    validateUntouched: "",
                    disabled: !_vm.isEditable
                  },
                  model: {
                    value: _vm.editAccount.ownerLastName,
                    callback: function($$v) {
                      _vm.$set(_vm.editAccount, "ownerLastName", $$v)
                    },
                    expression: "editAccount.ownerLastName"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    label: "IBAN",
                    validateUntouched: "",
                    disabled: !_vm.isEditable
                  },
                  on: {
                    change: function($event) {
                      return _vm.findIBANDetails()
                    },
                    onBlur: function($event) {
                      return _vm.checkIfIBANIsInvalid($event.target.value)
                    },
                    input: function($event) {
                      return _vm.checkLettersIban($event)
                    }
                  },
                  model: {
                    value: _vm.editAccount.iban,
                    callback: function($$v) {
                      _vm.$set(_vm.editAccount, "iban", $$v)
                    },
                    expression: "editAccount.iban"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    label: "SWIFT-BIC",
                    validateUntouched: "",
                    disabled: !_vm.isEditable
                  },
                  on: {
                    input: function($event) {
                      return _vm.checkLetters($event)
                    }
                  },
                  model: {
                    value: _vm.editAccount.bic,
                    callback: function($$v) {
                      _vm.$set(_vm.editAccount, "bic", $$v)
                    },
                    expression: "editAccount.bic"
                  }
                }),
                _c("InputField", {
                  attrs: { label: "Bank", disabled: !_vm.isEditable },
                  model: {
                    value: _vm.editAccount.bankName,
                    callback: function($$v) {
                      _vm.$set(_vm.editAccount, "bankName", $$v)
                    },
                    expression: "editAccount.bankName"
                  }
                }),
                _c("InputField", {
                  attrs: { label: "Ort der Bank", disabled: !_vm.isEditable },
                  model: {
                    value: _vm.editAccount.bankCity,
                    callback: function($$v) {
                      _vm.$set(_vm.editAccount, "bankCity", $$v)
                    },
                    expression: "editAccount.bankCity"
                  }
                }),
                _c("ComboBox", {
                  attrs: {
                    label: "Bankverbindungstyp",
                    values: _vm.customerDataConfig.bankAccountTypes,
                    firstEmpty: !_vm.hasValidComboValue(
                      "editAccount",
                      "typeOfAccount",
                      _vm.customerDataConfig.bankAccountTypes
                    ),
                    disabled: !_vm.isEditable
                  },
                  model: {
                    value: _vm.editAccount.typeOfAccount,
                    callback: function($$v) {
                      _vm.$set(_vm.editAccount, "typeOfAccount", $$v)
                    },
                    expression: "editAccount.typeOfAccount"
                  }
                }),
                _vm.isAVL
                  ? _c("InputToggleSwitch", {
                      attrs: { label: "Verwendung für DWS Erstattung der AVL" },
                      model: {
                        value: _vm.editAccount.isDWSErstattung4AVL,
                        callback: function($$v) {
                          _vm.$set(_vm.editAccount, "isDWSErstattung4AVL", $$v)
                        },
                        expression: "editAccount.isDWSErstattung4AVL"
                      }
                    })
                  : _vm._e(),
                _c("InputField", {
                  attrs: { label: "Bemerkung", disabled: !_vm.isEditable },
                  model: {
                    value: _vm.editAccount.annotation,
                    callback: function($$v) {
                      _vm.$set(_vm.editAccount, "annotation", $$v)
                    },
                    expression: "editAccount.annotation"
                  }
                }),
                _c("InputToggleSwitch", {
                  attrs: { label: "Als Standard setzen" },
                  model: {
                    value: _vm.editAccount.standard,
                    callback: function($$v) {
                      _vm.$set(_vm.editAccount, "standard", $$v)
                    },
                    expression: "editAccount.standard"
                  }
                })
              ],
              1
            ),
            _c(
              "BaseModal",
              {
                ref: "deleteBankverbindungConfirmation",
                attrs: {
                  labelButtonConfirm: "löschen",
                  modalTitle: "Bankverbindung löschen"
                },
                on: {
                  onConfirmButton: function($event) {
                    return _vm.deleteBankverbindung()
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.deleteMessage) + " ")]
            ),
            _c(
              "BaseModal",
              {
                ref: "fehlerModal",
                attrs: { labelButtonCancel: "Ok", showConfirmButton: false },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "modalTitle",
                      fn: function() {
                        return [
                          _c("ph-warning", {
                            staticClass: "mr-2",
                            staticStyle: { color: "orangered" },
                            attrs: { size: 16 }
                          }),
                          _vm._v(" Fehler ")
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  2587106909
                )
              },
              [
                _c("div", { staticClass: "var(--color-danger) font-bold" }, [
                  _c("hr"),
                  _vm._v(
                    " Diese Bankverbindung ist bereits im System vorhanden. Bitte bearbeiten Sie die vorhandene Bankverbindung "
                  )
                ])
              ]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }