<template>
  <div class="row">
    <div class="col-12" v-for="(news, index) of newsList" :key="index">
        <h4><a :href="news.link" target="_blank">{{news.title}}</a></h4>
        <h6>{{news.pubDate}}</h6>
        <p>{{news.desc}}</p>
        <hr>
    </div>
  </div>
</template>

<script>
import MARKTBERICHT_TYPES from "@/store/marktbericht/types";
import { mapGetters } from 'vuex';

export default {
    props: {
        lines: {
            type: Number,
            default: 3
        }
    },
    computed: {
      ...mapGetters({newsList: MARKTBERICHT_TYPES.GETTERS.MARKTBERICHT})
    },
    mounted(){
      const payload = this.lines;
      this.$store.dispatch(MARKTBERICHT_TYPES.ACTIONS.GET_MARKTBERICHT , payload);
    }

}
</script>

<style>
div > h4 {
  margin: 0px 0px;
}
div > h6 {
  margin: 5px 5px;
}

</style>