import CUSTOMER_TYPES from './types';
import LOG_TYPES from '@/store/log/types';
import axios from 'axios';

export default {
  [CUSTOMER_TYPES.ACTIONS.TEMPLATE]({ commit, state, rootState }) {
    axios.post(rootState.core.apiAddress + '/template', {}).then(response => {
      commit(CUSTOMER_TYPES.MUTATIONS.TEMPLATE, response.data);
    }).catch(error => {
      commit(CUSTOMER_TYPES.MUTATIONS.TEMPLATE, error.response && error.response.data);
    })
  },

  [CUSTOMER_TYPES.ACTIONS.SEND_CUSTOMER_COMPLAINT]({ rootState, dispatch }, payload) {
    const config = {
        defaultSpinner: true
    };
    axios.post(rootState.core.apiAddress + '/complaints/complaintFromCustomer', payload,config).then(response => {
        if (response.data.updateSuccess === "ok") {
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, 'Vielen Dank für Ihre Kontaktaufnahme. Wir werden Ihre Beschwerde unverzüglich bearbeiten und uns ggfs. bei Ihnen melden.');
            return true;
        }
        else {
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, 'Es ist ein Fehler aufgetreten!');
            return false;
        }
    })
},
}