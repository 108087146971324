var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "event-body" },
    [
      _c(
        "BaseModal",
        {
          ref: "modal",
          attrs: {
            labelButtonCancel: "Nein",
            labelButtonConfirm: "Ja",
            showConfirmButton: true,
            modalTitle: "Dokument bestätigen"
          },
          on: { onConfirmButton: _vm.save, onCancelButton: _vm.close }
        },
        [_c("BoxContainer", [_c("label", [_vm._v(_vm._s(_vm.tit))])])],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }