import VERSICHERUNG_TYPES from './types';

export default {
  [VERSICHERUNG_TYPES.GETTERS.INSURANCE_LIST](state) {
    return state.versicherungen;
  },
  [VERSICHERUNG_TYPES.GETTERS.INSURANCE_DETAILS](state) {
    return state.versicherungenDetails;
  },
  [VERSICHERUNG_TYPES.GETTERS.INSURANCE_VERTRAGSDATEN_FIELDS_DEFINITION](state) {
    return state.vertragsdatenFieldsDefinition;
  },
  [VERSICHERUNG_TYPES.GETTERS.INSURANCE_VERTRAGSDATEN_GESELLSCHAFT_MAKLERGESELLNR](state) {
    return state.gesellschaftMaklerGesellNr;
  },
  [VERSICHERUNG_TYPES.GETTERS.FILTERED_CATEGORY](state) {
    return state.filteredCategory;
  },
  [VERSICHERUNG_TYPES.GETTERS.INSURANCE_AUSWAHL_VERTRAGSART_SELECTED](state) {
    return state.categorySelected;
  },
  [VERSICHERUNG_TYPES.GETTERS.CATEGORIES_LIST](state) {
    return state.categories;
  },
  [VERSICHERUNG_TYPES.GETTERS.ALL_CATEGORIES_LIST](state) {
    return state.allCategories;
  },
  [VERSICHERUNG_TYPES.GETTERS.EDITING_DETAILS](state) {
    return state.editingDetails;
  },
  [VERSICHERUNG_TYPES.GETTERS.INSURANCE_DETAILS_COMBINED](state) {
    return state.insuranceDetails;
  },
  [VERSICHERUNG_TYPES.GETTERS.SPARTEN_LIST](state) {
    return state.spartenList;
  },
  [VERSICHERUNG_TYPES.GETTERS.ZAHLRHYTHMUS_LIST](state) {
    return state.zahlrhythmusList;
  },
  [VERSICHERUNG_TYPES.GETTERS.DEPOT_POSITIONS](state) {
    return state.depotPositions;
  },
  [VERSICHERUNG_TYPES.GETTERS.LAST_DELETED_SCHADENSMELDUNG](state){
    return state.deletedSchadensmeldungId;
  },
  [VERSICHERUNG_TYPES.GETTERS.INSURANCE_FILTERS_DATA](state) {
    return state.insuranceFiltersData;
  },
  [VERSICHERUNG_TYPES.GETTERS.INSURANCES_FILTERED](state) {
    return state.insurancesFiltered;
  },
  [VERSICHERUNG_TYPES.GETTERS.TEMP_FILES](state) {
    return state.tempFiles?.length && [...state.tempFiles] || [];
  },
  [VERSICHERUNG_TYPES.GETTERS.RETRIEVE_VERSICHERUNG_DETAILS_EDITED](state) {
    return state.versicherungDetailsEdited;
  },
  [VERSICHERUNG_TYPES.GETTERS.GESELLSCHAFT_LIST](state) {
    return state.gesellschaftList
  },
  [VERSICHERUNG_TYPES.GETTERS.VERSICHERUNG_FORM_VALIDATION](state) {
    return state.versicherungFormValidation
  },
  [VERSICHERUNG_TYPES.GETTERS.ANTRAG_CHECK_VERS_DATA](state) {
    return state.antragCheckVersData
  },
  [VERSICHERUNG_TYPES.GETTERS.PRUEFSTATUS_CHANGE](state) {
    return state.pruefstatusChange
  },
  [VERSICHERUNG_TYPES.GETTERS.INSURANCE_ANMERKUNG_GET](state) {
    return state.anmerkungen
  },
  [VERSICHERUNG_TYPES.GETTERS.CAN_EDIT_ANMERKUNG](state) {
    return state.canEditAnmerkung
  },  
  [VERSICHERUNG_TYPES.GETTERS.INSURANCES_BY_CUSTOMER](state) {
    return state.insurancesByCustomer
  },
  [VERSICHERUNG_TYPES.GETTERS.INSURANCE_COURTAGE](state) {
    return state.courtage
  },
  [VERSICHERUNG_TYPES.GETTERS.INSURANCE_COURTAGE_CONFIG](state) {
    return state.insuranceCourtageConfig
  },
  [VERSICHERUNG_TYPES.GETTERS.INSURANCE_ABRECHNUNGEN](state) {
    return state.abrechnungen
  },
  [VERSICHERUNG_TYPES.GETTERS.INSURANCE_COPY](state) {
    return state.insuranceCopy;
  },
  [VERSICHERUNG_TYPES.GETTERS.VERTRAGSPERSONENDATA](state) {
    return state.vertragsPersonenData;
  },
  [VERSICHERUNG_TYPES.GETTERS.VERTRAGPERSONEN_LIST](state) {
    return state.vertragPersonenList;
  },
  [VERSICHERUNG_TYPES.GETTERS.VERTRAGS_VERSCHIEBUNGEN_DATA](state) {
    return state.vertragsverschiebungenData;
  },
  [VERSICHERUNG_TYPES.GETTERS.GET_SPARTEN](state) {
    return state.sparten;
  },
  [VERSICHERUNG_TYPES.GETTERS.FAHRZEUGDATEN_LIST](state) {
    return state.fahrzeugdatenList;
  },
  [VERSICHERUNG_TYPES.GETTERS.NODE_ID](state) {
    return state.nodeId;
  },  
  [VERSICHERUNG_TYPES.GETTERS.FOUND_CUSTOMER](state) {
    return state.foundCustomer;
  },  
  [VERSICHERUNG_TYPES.GETTERS.VERSICHERUNGSSUMMEN](state) {
    return state.versicherungsSummen;
  },  
  [VERSICHERUNG_TYPES.GETTERS.EXTERNAL_URL](state) {
    return ["WIENER STAEDTISCHE", "DONAU", "WR STÄDTISCHE"].includes(state?.versicherungenDetails?.insurance?.gesellschaft?.id);
  },  
  

  
}