<template>
<div class="card box__shadow">
  <div class="card-header">
    <div class="title">
      <slot name="header"></slot>
      <div v-if="isError">Es gab ein Problem bei dem Abruf der Daten.</div>
    </div>
    <div class="switcher" v-if="allowConfig">
      <select name="switchOptions" @change="onChange($event)" class="form-control" v-model="selectedKey">
        <option value=""></option>
        <template v-for="item in switchOptions">
          <option v-bind:value="item.name" v-bind:key="item.name">{{item.title}}</option>
        </template>
      </select>
    </div>
    <div v-if="showToolbar" class="list-switcher">
      <ph-text-align-justify :size="16" v-if="allowListView && rows && !isListView" @click="isListView = !isListView"/>
      <ph-table :size="16" v-if="allowListView && rows && isListView" @click="isListView = !isListView"/>
      <ph-question :size="16" @click="showInfo()"/>
    </div>
  </div>
  <div class="card-body">
    <div class="card-body-main" v-if="!isListView">
      <slot></slot>
    </div>
    <div class="card-body-alternative" v-if="isListView">
      <slot name="alternative"></slot>
    </div>
    <template v-if="allowListView && isListView && rows">
        <Table
            tableId="5542f528-f246-462a-8817-2fcbbef61eca"
            :headers="headers"
            :rows="rows"
            hidePagination
        />
      <a @click="showMore()" v-if="rows.length > 5">
        Details
      </a>
    </template>
    <div v-else-if="allowListView && isListView">Keine Daten</div>

    <BaseModal
      :showDefaultButtons="false"
      :modalTitle="!isInfoModal ? 'Details' : ''"
      @onCancelButton="hideModal()"
      ref="modalInfo">
      <template>
        <template v-if="isInfoModal">
          <div v-html="info"></div>
        </template>
        <template v-else>
            <Table
                tableId="d8ef0adc-f212-4981-bf4b-e34a3e634962"
                :headers="headers"
                :rows="rows"
                hidePagination
            />
        </template>
      </template>
    </BaseModal>
  </div>
</div>

</template>

<script>
import BaseModal from "@/components/core/BaseModal.vue";
import Table from "@/components/table2/Table.vue";
import {TextColumn} from "@/components/table2/table_util.js";
import { PhTextAlignJustify, PhTable, PhQuestion } from 'phosphor-vue';
import {sanitize} from '@/helpers/string-helper.js';

export default {
  props: {
    headers: {
        type: Object,
        default: () => ({
            lockedLeft: [
                TextColumn("label", ""),
            ],
            lockedRight: [
                TextColumn("value", ""),
            ],
        }),
    },
    rows: {
        type: Array,
        default: null,
    },
    showToolbar: {
      type: Boolean,
      default: true,
    },
    listView: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      isListView: false,
      selectedKey: '',
      switchOptions: this.$parent.propsData && this.$parent.propsData.getSwitcherOptions && this.$parent.propsData.getSwitcherOptions() || [],
      allowConfig: this.$parent.propsData && this.$parent.propsData.allowConfig || false,
      isError: this.$parent.propsData && this.$parent.propsData.isError || false,
      allowListView: this.$parent.propsData && this.$parent.propsData.allowListView || false,
      info: sanitize(this.$parent.propsData?.info || ''),
      infoLabel: this.$parent.propsData && this.$parent.propsData.infoLabel || '',
      
      showModal: false,
      isInfoModal: true,
    }
  },

  watch: {
    listView(value) {
      this.isListView = value;
    },
  },

  methods: {
    onChange(event) {
      if (event.target.value) {
        this.$parent.propsData.cardSelected(this.$parent.propsData.index, event.target.value);
      }
    },
    showInfo() {
      this.isInfoModal = true;
      this.showModal = true;
      this.$refs.modalInfo.open();
    },
    showMore() {
      this.isInfoModal = false;
      this.showModal = true;
      this.$refs.modalInfo.open();
    },
    hideModal() {
      this.showModal = false;
      this.$refs.modalInfo.close();
    },
  },
  components: {
    BaseModal,
    Table,
    PhTextAlignJustify,
    PhTable,
    PhQuestion,
  }
}
</script>

<style scoped>
.card {
  border-radius: 8px;
	padding: 16px;
	background: var(--color-box);
  height: 100%;
  box-sizing: border-box;
}

.card-header {
	padding: 0;
	color: var(--color-text);
	font-size: 1.2rem;
}

.card-body {
	padding: 0;
  height: 100%;
}
.card-body-main {
  height: 100%;
}
.card-header {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
}
.list-switcher {
  white-space: nowrap;
}
</style>