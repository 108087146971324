var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "broker-permissions__container" }, [
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("div", { staticClass: "box__title" }, [
          _vm._v("Genereller Zugriff")
        ]),
        _vm.loading
          ? [
              _c(
                "div",
                {
                  staticClass:
                    "broker-permissions--field__container forms__input--half-size"
                },
                [_c("GhostLoading", { attrs: { type: "input" } })],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "broker-permissions--field__container forms__input--half-size"
                },
                [_c("GhostLoading", { attrs: { type: "input" } })],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "broker-permissions--field__container forms__input--half-size"
                },
                [_c("GhostLoading", { attrs: { type: "input" } })],
                1
              )
            ]
          : [
              _c(
                "div",
                { staticClass: "broker-permissions--field__container" },
                [
                  _c("ComboBox", {
                    staticClass: "p-0",
                    attrs: {
                      label: "Investmentfonds",
                      values: _vm.comboData.permissionValues,
                      sortComboboxValues: "",
                      isComponentHalfSize: ""
                    },
                    on: {
                      change: function($event) {
                        return _vm.setDataChangedCombo(
                          "zugriffInvestmentFondsParam",
                          $event
                        )
                      }
                    },
                    model: {
                      value: _vm.formData.zugriffInvestmentFondsParam,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.formData,
                          "zugriffInvestmentFondsParam",
                          $$v
                        )
                      },
                      expression: "formData.zugriffInvestmentFondsParam"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "text-small" },
                    [
                      _vm._v("aktuell gültig: "),
                      _c("Pill", {
                        attrs: {
                          label: _vm.permissions.zugriffInvestmentFondsText,
                          type: "primary"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "broker-permissions--field__container" },
                [
                  _c("ComboBox", {
                    staticClass: "p-0",
                    attrs: {
                      label: "Beteiligungen",
                      values: _vm.comboData.permissionValues,
                      sortComboboxValues: "",
                      isComponentHalfSize: ""
                    },
                    on: {
                      change: function($event) {
                        return _vm.setDataChangedCombo(
                          "zugriffGeschlosseneFondsParam",
                          $event
                        )
                      }
                    },
                    model: {
                      value: _vm.formData.zugriffGeschlosseneFondsParam,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.formData,
                          "zugriffGeschlosseneFondsParam",
                          $$v
                        )
                      },
                      expression: "formData.zugriffGeschlosseneFondsParam"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "text-small" },
                    [
                      _vm._v("aktuell gültig: "),
                      _c("Pill", {
                        attrs: {
                          label: _vm.permissions.zugriffGeschlosseneFondsText,
                          type: "primary"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "broker-permissions--field__container" },
                [
                  _c("ComboBox", {
                    staticClass: "p-0",
                    attrs: {
                      label: "Versicherungen",
                      values: _vm.comboData.permissionValues,
                      sortComboboxValues: "",
                      isComponentHalfSize: ""
                    },
                    on: {
                      change: function($event) {
                        return _vm.setDataChangedCombo(
                          "zugriffVersicherungenParam",
                          $event
                        )
                      }
                    },
                    model: {
                      value: _vm.formData.zugriffVersicherungenParam,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.formData,
                          "zugriffVersicherungenParam",
                          $$v
                        )
                      },
                      expression: "formData.zugriffVersicherungenParam"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "text-small" },
                    [
                      _vm._v("aktuell gültig: "),
                      _c("Pill", {
                        attrs: {
                          label: _vm.permissions.zugriffVersicherungenText,
                          type: "primary"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "broker-permissions--field__container" },
                [
                  _c("ComboBox", {
                    staticClass: "p-0",
                    attrs: {
                      label: "Darlehen / Kredite",
                      values: _vm.comboData.permissionValues,
                      sortComboboxValues: "",
                      isComponentHalfSize: ""
                    },
                    on: {
                      change: function($event) {
                        return _vm.setDataChangedCombo(
                          "zugriffKreditedarelehenParam",
                          $event
                        )
                      }
                    },
                    model: {
                      value: _vm.formData.zugriffKreditedarelehenParam,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.formData,
                          "zugriffKreditedarelehenParam",
                          $$v
                        )
                      },
                      expression: "formData.zugriffKreditedarelehenParam"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "text-small" },
                    [
                      _vm._v("aktuell gültig: "),
                      _c("Pill", {
                        attrs: {
                          label: _vm.permissions.zugriffKreditedarelehenText,
                          type: "primary"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "broker-permissions--field__container" },
                [
                  _c("ComboBox", {
                    staticClass: "p-0",
                    attrs: {
                      label: _vm.isFA
                        ? "Löschen gesp. Dokumente"
                        : "Löschen Dokumentenarchiv",
                      values: _vm.comboData.permissionValues,
                      sortComboboxValues: "",
                      isComponentHalfSize: ""
                    },
                    on: {
                      change: function($event) {
                        return _vm.setDataChangedCombo(
                          "loeschenGespeicherteDokumenteParam",
                          $event
                        )
                      }
                    },
                    model: {
                      value: _vm.formData.loeschenGespeicherteDokumenteParam,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.formData,
                          "loeschenGespeicherteDokumenteParam",
                          $$v
                        )
                      },
                      expression: "formData.loeschenGespeicherteDokumenteParam"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "text-small" },
                    [
                      _vm._v("aktuell gültig: "),
                      _c("Pill", {
                        attrs: {
                          label:
                            _vm.permissions.loeschenGespeicherteDokumenteText,
                          type: "primary"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "broker-permissions--field__container" },
                [
                  _c("ComboBox", {
                    staticClass: "p-0",
                    attrs: {
                      label: "Passwort änderbar",
                      values: _vm.comboData.permissionValues,
                      sortComboboxValues: "",
                      isComponentHalfSize: ""
                    },
                    on: {
                      change: function($event) {
                        return _vm.setDataChangedCombo(
                          "passwortAenderbarParam",
                          $event
                        )
                      }
                    },
                    model: {
                      value: _vm.formData.passwortAenderbarParam,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "passwortAenderbarParam", $$v)
                      },
                      expression: "formData.passwortAenderbarParam"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "text-small" },
                    [
                      _vm._v("aktuell gültig: "),
                      _c("Pill", {
                        attrs: {
                          label: _vm.permissions.passwortAenderbarText,
                          type: "primary"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }