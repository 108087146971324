var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "customer-settings-view" },
    [
      _c("PageHeaderTitleNavigation", { attrs: { title: _vm.parentTitle } }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-3 col-md-3 col-sm-12 col-12" }, [
          _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("Stepper", {
                attrs: {
                  steps: _vm.$appNavigation.currentTabMenu,
                  currentStep: _vm.$route
                }
              })
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "col-lg-9 col-md-9 col-sm-12 col-12" },
          [_c("router-view")],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }