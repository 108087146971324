import FONDSVERGLEICH_TYPES from './types';
import CORE_TYPES from '@/store/core/types'
import axios from 'axios';
import dayjs from 'dayjs';
import Vue from 'vue';
import {TextColumn, NumberColumn, SlotColumn, PercentageColumn} from "@/components/table2/table_util.js";

const config = {
  defaultSpinner: true
}

export default {
  async [FONDSVERGLEICH_TYPES.ACTIONS.SETTINGS]({ commit, state, rootState }, payload) {
    try {
      const params = !payload ? {} : payload;
      let response = await axios.post(rootState.core.apiAddress + '/consultation/fondsvergleich?ask=init', 
            {ask: 'init', params}, config);
      commit(FONDSVERGLEICH_TYPES.MUTATIONS.SETTINGS, response.data);
    } catch (er) {
      console.log('FONDSVERGLEICH_TYPES.ACTIONS.SETTINGS', er);
    }
    return state.settings;
  },
  async [FONDSVERGLEICH_TYPES.ACTIONS.TOGGLE_AVAILABLE_TO_CUSTOMER]({commit, getters }, payload) {
    try {
      const params = {
        ask: 'toggle_available_to_customer',
        params: payload
      }
      const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/consultation/fondsvergleich?ask=${params.ask}`, params, config);
      if (response.data?.fv_params) {
        commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_FVP, response.data);
      }
      return Promise.resolve(response.data);
    } catch (er) {
      // empty block
    }
  },
  async [FONDSVERGLEICH_TYPES.ACTIONS.FUNDSDATA]({ commit, state, rootState }) {
    try {
      commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_ACT, {
        fundsdata: {},
        hasVergleich: false,
        ergebnisLoading: { fondsDaten: true },
      });
      let response = await axios.post(rootState.core.apiAddress + '/consultation/fondsvergleich?ask=stamm_data', {
        ask: 'stamm_data',
        params: {
          selectedFonds: state.session.settings.funds.map( f => f.isin),
          selectionIdx: state.session.settings.indices.map( i => i[0])
        }
      }, config);
      const headersC = { lockedLeft: [], center: [], lockedRight: [] };
      const dataTypeC = {};
      const recordsC = [];
      const headersR = {
        label: {
          key: 'label',
          label: '',
          dataType: 'String',
          priority: 'VISIBLE_ALWAYS',
          visible: true,
        }
      };
      const recordsR = [];
      const keys = [];
      let w = 0, temp, idxISIN = 1;
      response.data.headerInfo.forEach((h, idx) => {
        if (h[0] === 'ISIN') {
          idxISIN = idx;
        }
        w = w + parseInt(h[3]) * 0.8;
        let p = 'VISIBLE_ALWAYS';
        if (w > 1200) {
          p = 'COLLAPSED';
        } else if (w > 960) {
          p = 'VISIBLE_LARGE';
        } else if (w > 640) {
          p = 'VISIBLE_MEDIUM';
        }
        let t = 'String';
        let col = null;
        switch (h[4]) {
          case 'DOUBLE':
            t = 'Number';
            if (h[0] === 'KURS1') {
              t = 'Slot';
              col = SlotColumn(h[0], h[1], 150, 0);
            } else {
              if (h[2].indexOf('%') != -1) {
                col = NumberColumn(h[0], h[1], 2);
              } else {
                col = NumberColumn(h[0], h[1]);
              }
              
            }
            break;
          case 'LONG':
            t = 'NumberInt';
            col = NumberColumn(h[0], h[1]);
            break;
          case 'DATE':
            t = 'Slot';
            col = SlotColumn(h[0], h[1], 150, 0);
            break;
          case 'PERCENTAGE':
            t = 'Number';
            col = PercentageColumn(h[0], h[1], 2);
            break;
          default:
            col = TextColumn(h[0], h[1]);
            break;
        }
        if (h[0]) {
          if (idx === 0) {
            // headersC.lockedLeft.push(TextColumn(h[0], null).makeAlwaysVisible());
            headersC.lockedLeft.push(SlotColumn(h[0], null, 200).makeAlwaysVisible().makeSortable());
          } else {
            headersC.center.push(col);
          }
          dataTypeC[h[0]] = t;
          keys.push(h[0]);
          recordsR.push({ label: h[2] });
        }
      });
      response.data.recordsTable.forEach((r, ixR) => {
        const row = {};
        const isin = r[idxISIN];
        keys.forEach((key, idx) => {
          row[key] = r[idx];
          temp = r[idx];
          if (dataTypeC[key] === 'Slot' && temp) {
            temp = dayjs( new Date(temp)).format('DD.MM.YYYY');
            temp === '01.01.1970' ? '' : temp ;
          } else if (dataTypeC[key] === 'Number' && temp) {
            temp = r[idx].toLocaleString('de-DE', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2});
          }
          recordsR[idx][isin] = temp;
        })
        recordsC.push(row);
        headersR[isin] = {
          key: keys[ixR],
          label: isin,
          dataType: 'String',
          priority: 'VISIBLE_ALWAYS',
          visible: true,
        }
      });
      commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_ACT, {
        fundsdata: {
          showAsCols: {
            headers: headersC,
            records: recordsC
          },
          showAsRows: {
            headers: headersR,
            records: recordsR
          }
        },
        hasVergleich: true,
      });
      return state.session.fundsdata;
    } catch (er) {
      console.log('FONDSVERGLEICH_TYPES.ACTIONS.FUNDSDATA', er);
    } finally {
      commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_ACT, {
        ergebnisLoading: { fondsDaten: false },
      });
    }
  },
  async [FONDSVERGLEICH_TYPES.ACTIONS.WERTENTW]({ commit, state, rootState }) {
    try {
      commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_ACT, {
        wertentw: {chartSerie: []},
        ergebnisLoading: { wertentwicklung: true },
      });
      commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_START_END_ZOOM, {start: 0, end: 100});
      const isinFonds = {};
      state.session.settings.funds.forEach(f => {
        isinFonds[f.fondsname] = f.isin;
      })
      const isinIdx = {};
      state.session.settings.Indizes.forEach(i => {
        isinIdx[i[1]] = i[0];
      })
      const indices = state.session.settings.indices || [];
      let response = await axios.post(rootState.core.apiAddress + '/consultation/fondsvergleich?ask=wertentw', {
        ask: 'wertentw',
        params: {
          selectedFonds: state.session.settings.funds.map( f => f.isin),
          isinFonds: isinFonds,
          selectionIdx: indices.map(i => i[0]),
          isinIdx: isinIdx,
          WertEntwZeitArt: state.session.settings.WertEntwZeitArt,
          WertEntwJahreValue: state.session.settings.WertEntwJahreValue,
          zeitraum_bis: state.session.settings.zeitraum_bis,
          zeitraum_von: state.session.settings.zeitraum_von,
          cbAnpassen: state.session.settings.cbAnpassen
        }
      }, { defaultSpinner: true });
      response.data.indicesSeries = {};
      commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_ACT, {
        wertentw: {
          ...response.data,
          chartSerie: response.data.chartSerie.sort((a, b) => a.key.localeCompare(b.key))
        },
        ergebnisLoading: { wertentwicklung: false },
      });
      const restIndexes = state.session.settings.Indizes
        .filter(i => !indices.length || -1 === indices.findIndex(f => f[0] === i[0]))
        .map(i => i[0]);
      if (restIndexes.length) {
        response = await axios.post(rootState.core.apiAddress + '/consultation/fondsvergleich?ask=weIdices', {
          ask: 'weIdices',
          params: {
            selectedFonds: [],
            selectionIdx: restIndexes,
            isinIdx: isinIdx,
            WertEntwZeitArt: state.session.settings.WertEntwZeitArt,
            WertEntwJahreValue: state.session.settings.WertEntwJahreValue,
            zeitraum_bis: response.data.max,
            zeitraum_von: response.data.min,
            cbAnpassen: state.session.settings.cbAnpassen,
          }
        }, { defaultSpinner: true });
        if (response.data.indicesSeries) {
          Vue.set(state.session.wertentw, 'indicesSeries', response.data.indicesSeries);
        }
      }
      return state.session.wertentw;
    } catch (er) {
      console.log('FONDSVERGLEICH_TYPES.ACTIONS.WERTENTW', er);
    } finally {
      commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_ACT, {
        ergebnisLoading: { wertentwicklung: false },
      });
    }
  },
  async [FONDSVERGLEICH_TYPES.ACTIONS.RENDRISIKO]({ commit, state, rootState }) {
    try {
      commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_ACT, { 
        renditeRisiko: [],
        ergebnisLoading: { rendRisiko: true },
      });
      const selectedFIsin = state.session.settings.funds.map(f => f.isin);
      const selectedFName = state.session.settings.funds.map(f => f.fondsname);
      let response = await axios.post(rootState.core.apiAddress + '/consultation/fondsvergleich?ask=rendite_risiko', {
        ask: 'rendite_risiko',
        params: {
          risikoJahr: state.session.settings.RisikoJahreValue,
          selectedFIsin: selectedFIsin.concat(state.session.settings.indices.map(i => i[0])),
          selectedFName: selectedFName.concat(state.session.settings.indices.map(i => i[1]))
        }
      }, config);
      commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_ACT, {
         renditeRisiko: response.data.renditeRisiko.sort((a, b) => a.name.localeCompare(b.name))
      });
      return state.session.renditeRisiko;
    } catch (er) {
      console.log('FONDSVERGLEICH_TYPES.ACTIONS.RENDRISIKO', er);
    } finally {
      commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_ACT, { 
        ergebnisLoading: { rendRisiko: false },
      });
    }
  },
  async [FONDSVERGLEICH_TYPES.ACTIONS.EINSTELL]({ commit, state, rootState }, payload) {
    try {
      let response = await axios.post(rootState.core.apiAddress + '/consultation/fondsvergleich?ask=save_config', {
        ask: 'save_config',
        params: payload
      });
      commit(FONDSVERGLEICH_TYPES.MUTATIONS.SETTINGS, response.data);
    } catch (er) {
      console.log('FONDSVERGLEICH_TYPES.ACTIONS.EINSTELL', er);
    }
    return state.settings;
  },
  async [FONDSVERGLEICH_TYPES.ACTIONS.FVPSAVEDESC]({ commit, rootState }, payload) {
    try {
      let response = await axios.post(rootState.core.apiAddress + '/consultation/fondsvergleich?ask=save_descript', {
        ask: 'save_descript',
        params: payload
      }, { defaultSpinner: true });
      if (response.data.fv_params) {
        commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_FVP, response.data);
      }
      return response.data;
    } catch (er) {
      console.log('FONDSVERGLEICH_TYPES.ACTIONS.FVPSAVEDESC', er);
    }
  },
  [FONDSVERGLEICH_TYPES.ACTIONS.FVPSAVEORDER]({ rootState }, payload) {
    return axios.post(rootState.core.apiAddress + '/consultation/fondsvergleich', {
      ask: 'save_order',
      params: payload
    }, { defaultSpinner: true });
  },
  async [FONDSVERGLEICH_TYPES.ACTIONS.FVPREAD]({ commit, rootState }, payload) {
    try {
      commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_FILTER, payload);
      let response = await axios.post(rootState.core.apiAddress + '/consultation/fondsvergleich?ask=get_fvp', {
        ask: 'get_fvp',
        params: payload
      }, config);
      if (response.data.fv_params) {
        commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_FVP, response.data);
      }
      return Promise.resolve(response.data);
    } catch (er) {
      console.log('FONDSVERGLEICH_TYPES.ACTIONS.FVPREAD', er);
    }
  },
  async [FONDSVERGLEICH_TYPES.ACTIONS.FVPSAVE]({ commit, rootState }, payload) {
    try {
      let response = await axios.post(rootState.core.apiAddress + '/consultation/fondsvergleich?ask=save_fvp', {
        ask: 'save_fvp',
        params: payload
      }, config);
      if (response.data.fv_params) {
        commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_FVP, response.data);
      }
      return Promise.resolve(response.data);
    } catch (er) {
      console.log('FONDSVERGLEICH_TYPES.ACTIONS.FVPSAVE', er);
    }
  },
  async [FONDSVERGLEICH_TYPES.ACTIONS.FVPDEL]({ commit, rootState }, payload) {
    try {
      let response = await axios.post(rootState.core.apiAddress + '/consultation/fondsvergleich?ask=del_fvp', {
        ask: 'del_fvp',
        params: payload
      }, config);
      if (response.data.fv_params) {
        commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_FVP, response.data);
      }
      return Promise.resolve(response.data);
    } catch (er) {
      console.log('FONDSVERGLEICH_TYPES.ACTIONS.FVPDEL', er);
    }
  },
  async [FONDSVERGLEICH_TYPES.ACTIONS.FVPCOPY]({ commit, rootState }, payload) {
    try {
      let response = await axios.post(rootState.core.apiAddress + '/consultation/fondsvergleich?ask=copy_fvp', {
        ask: 'copy_fvp',
        params: payload
      }, config);
      if (response.data.fv_params) {
        commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_FVP, response.data);
      }
      return Promise.resolve(response.data);
    } catch (er) {
      console.log('FONDSVERGLEICH_TYPES.ACTIONS.FVPCOPY', er);
    }
  },
  async [FONDSVERGLEICH_TYPES.ACTIONS.PDF]({state, rootState }, payload) {
    try {
      payload.funds = state.session.settings.funds.map(f => [f.isin, f.aa_manuell]);
      payload.selectedFonds = state.session.settings.funds.map(f => f.isin);
      payload.selectionIdx = state.session.settings.indices.map(i => i[0]);
      let response = await axios.post(rootState.core.apiAddress + '/consultation/fondsvergleich?ask=get_pdf', {
        ask: 'get_pdf',
        params: payload
      }, config);
      return Promise.resolve(response.data);
    } catch (er) {
      console.log('FONDSVERGLEICH_TYPES.ACTIONS.PDF', er);
    }
  },
  async [FONDSVERGLEICH_TYPES.ACTIONS.XLS]({state, rootState }, payload) {
    try {
      payload.funds = state.session.settings.funds.map(f => [f.isin, f.aa_manuell]);
      payload.selectedFonds = state.session.settings.funds.map(f => f.isin);
      payload.selectionIdx = state.session.settings.indices.map(i => i[0]);
      let response = await axios.post(rootState.core.apiAddress + '/consultation/fondsvergleich?ask=get_xls', {
        ask: 'get_xls',
        params: payload
      }, config);
      return Promise.resolve(response.data);
    } catch (er) {
      console.log('FONDSVERGLEICH_TYPES.ACTIONS.XLS', er);
    }
  },

  [FONDSVERGLEICH_TYPES.ACTIONS.GET_FONDS]({commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(rootState.core.apiAddress + '/consultation/fondsvergleich?ask=get_fonds', {
      ask: 'get_fonds',
      params: payload
    }, config).then(response => {
        commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_FUNDS, response?.data?.fonds || [])
        commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_UNSEL_FUNDS, response?.data?.unselected || [])
        resolve(response?.data?.fonds?.length || 0)
      });
    });
  },

  [FONDSVERGLEICH_TYPES.ACTIONS.GET_DEPOT]({rootState}) {
    return axios.post(rootState.core.apiAddress + '/consultation/fondsvergleich?ask=get_depot', {
      ask: 'get_depot',
      params: {}
    }, config);
  },

  async [FONDSVERGLEICH_TYPES.ACTIONS.FVPNEW]({ commit, dispatch, rootState, state }, payload) {
    try {
      if (!state.settings) {
        await dispatch(FONDSVERGLEICH_TYPES.ACTIONS.SETTINGS);
      }
      const isinList = payload.split(',');
      const params = {
        WertEntwJahreValue: (state.settings?.WertEntwJahreValue || "max"),
        WertEntwZeitArt: (state.settings?.WertEntwZeitArt || "Letztes_N_Jahr"),
        RisikoJahreValue: (state.settings?.RisikoJahreValue || "3"),
        cbRenditePA: false,
        unsel: [],
        zeitraum_von: null,
        zeitraum_bis: null,
        legendWESort: "",
        indices: [ ],
        wentwChartVal: 1,
        cbSteuern: false,
        cbAgio: false,
        cbAnpassen: false,
        funds: isinList.map( isin => [isin, null]),
        customerAllowedToSee: false,
        fv_description: 'Unbenannt',
      }
      const response = await axios.post(rootState.core.apiAddress + '/consultation/fondsvergleich?ask=save_fvp', {
        ask: 'save_fvp',
        params: params
      }, config);
      if (response.data?.fv_id) {
        commit(FONDSVERGLEICH_TYPES.MUTATIONS.RESET_ACT );
        let fvp = response.data.fv_params.find( item => item.id === response.data.fv_id);
        if (!fvp) {
          fvp = state.session?.fvp || {};
          fvp.id = response.data.fv_id;
          fvp.description = response.data.fv_description;
          fvp.create = response.data.fv_create;
        }
        commit(FONDSVERGLEICH_TYPES.MUTATIONS.SET_ACT, {fvp: fvp});
      }
      return response;
    } catch (er) {
      console.log('FONDSVERGLEICH_TYPES.ACTIONS.FVPNEW', er);
    }
  },
}