<template>
  <div class="risiko-analyse-positionen-component">
    <div class="risiko-analyse-positionen-component-buttons">
      <BaseButton @click="handleErneutAnalysieren()" :disabled="loadingAntrag">Erneut Analysieren</BaseButton>
    </div>

    <hr>

    <div v-if="data" class="risiko-analyse-positionen-component-tables">
      <h4>In diesem Antrag gekaufte Wertpapiere</h4>
        <Table v-if="newRows.length"
          :headers="newHeaders"
          :rows="newRows"
          rowId="description1"
          hidePagination
        />
        <NoData noIcon v-else content="Keine Informationen" />

      <h4>Bestände des Kunden</h4>
        <Table v-if="oldRows.length"
          :headers="oldHeaders"
          :rows="oldRows"
          rowId="description2"
          hidePagination
        />
        <NoData noIcon v-else content="Keine Informationen" />
      
      <div v-if="hasNachhaltigkeit">
        <h4>Informationen zur Nachhaltigkeit</h4>
          <Table v-if="nachhaltigkeitRows.length"
            :headers="nachhaltigkeitHeaders"
            :rows="nachhaltigkeitRows"
            rowId="description33"
            hidePagination
          />
          <NoData noIcon v-else content="Keine Informationen" /> 
        
        <h4>Details Taxonomie</h4>
          <Table v-if="taxoRows.length"
            :headers="taxonomieHeaders"
            :rows="taxoRows"
            rowId="description4"
            hidePagination
          />
          <NoData noIcon v-else content="Keine Informationen" />
        
        <h4>Details SFDR</h4>
          <Table v-if="sfdrRows.length"
            :headers="sfdrHeaders"
            :rows="sfdrRows"
            rowId="description5"
            hidePagination
          />
        <NoData noIcon v-else content="Keine Informationen" /> 
      </div>
    </div> 
  </div>
</template>

<script>
import ANTRAG_TYPES from "@/store/antrag/types";

import BaseButton from '@/components/core/BaseButton.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, NumberColumn, PercentageColumn, CurrencyColumn} from "@/components/table2/table_util.js";
import NoData from '@/components/core/NoData.vue';

export default {
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
      required: true,
    },
    antragId: {
      type: String
    },
    antragName: {
      type: String
    },
    config: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
        loadingAntrag: false,
        newHeaders: {
          lockedLeft: [
            TextColumn("description", "Wertpapiername"),
          ],
          center: [
            TextColumn("isin", "ISIN"),
            TextColumn("wkn", "WKN"),
            NumberColumn("riskNumber", "SRI", 0),
            NumberColumn("anlageNetto", "Anlage Netto", 2).withSumFooter(),
            NumberColumn("aa", "AA %", 4),
            NumberColumn("anlageBrutto", "Anlage Brutto", 2).withSumFooter(),
          ],
        },
        oldHeaders: {
          lockedLeft: [
            TextColumn("description", "Wertpapiername"),
          ],
          center: [
            TextColumn("isin", "ISIN"),
            TextColumn("wkn", "WKN"),
            NumberColumn("riskNumber", "SRI", 0),
            NumberColumn("altBestand", "Altbestand", 2).withSumFooter(),
            NumberColumn("sales", "Verkäufe", 2),
            NumberColumn("neuBestand", "Neubestand", 2).withSumFooter(),
          ],
        },
        nachhaltigkeitHeaders: {
          lockedLeft: [
              TextColumn("description", "Wertpapiername"),
          ],
          center: [
            CurrencyColumn("neubestand", "Neubestand", 2),
            PercentageColumn("depotanteil", "Depotanteil", 2),
            PercentageColumn("taxo", "Taxo", 2),
            PercentageColumn("sfdr", "SFDR", 2),
            TextColumn("pais", "PAIs"),
            PercentageColumn("nachhaltigkeit", "Nachhaltigkeit", 2),
          ],
        },
        taxonomieHeaders: {
          lockedLeft: [
              TextColumn("description", "Wertpapiername"),
          ],
          center: [
            CurrencyColumn("neubestand", "Neubestand", 2),
            PercentageColumn("depotanteil", "Depotanteil", 2),
            PercentageColumn("taxo", "Taxo", 2),
            PercentageColumn("kumuliert", "Kumuliert", 2),
          ],
        },
        sfdrHeaders: {
          lockedLeft: [
              TextColumn("description", "Wertpapiername"),
          ],
          center: [
            CurrencyColumn("neubestand", "Neubestand", 2),
            PercentageColumn("depotanteil", "Depotanteil", 2),
            PercentageColumn("sfdr", "SFDR", 2),
            PercentageColumn("kumuliert", "Kumuliert", 2),
          ],
        },
    };
  },
  computed: {
    lagerstelle() {
      return this.$route.params.lagerstelle;
    },
    antragsName() {
      return this.$route.params.antragsName;
    },
    beratungsMappeId() {
      return this.data && this.data['beratungsMappeId'] || '';
    },
    currentFormLink() {
      return `/beratung/formulare/antrag/${this.lagerstelle}/${this.antragsName}`;
    },
    newRows() {
        return this.data?.NEW_FONDS_POSITIONS || [];
    },
    oldRows() {
        return this.data?.OLD_FONDS_POSITIONS || [];
    },
    nachhaltigkeitRows() {
        return this.data?.NACHHALTIGKEIT_POSITIONS || [];
    },
    taxoRows() {
        return this.data?.TAXO_POSITIONS || [];
    },
    sfdrRows() {
        return this.data?.SFDR_POSITIONS || [];
    },
    hasNachhaltigkeit() {
      return this.nachhaltigkeitRows?.length > 0 || this.taxoRows?.length > 0 || this.sfdrRows?.length > 0;
    }
  },
  methods: {
    handleErneutAnalysieren() {
      const payload = {
        antragsName: this.antragsName,
        lagerstelle: this.lagerstelle,
        id: this.antragId,
        beratungsMappeId: this.beratungsMappeId,
      };

      this.loadingAntrag = true;
      this.$store.dispatch(ANTRAG_TYPES.ACTIONS.GET_ANTRAG, payload).then(() => {
        this.loadingAntrag = false;
      });
    },
  },
  components: {
    Table,
    BaseButton,
    NoData,
  },
}
</script>
