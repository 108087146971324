var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "customer-kontaktdaten__container box__container-wrap" },
    [
      _c(
        "div",
        { staticClass: "box__container-rows" },
        [
          _vm.contacts.length
            ? [
                _c(
                  "div",
                  { staticClass: "label-value--list" },
                  _vm._l(_vm.contacts, function(contact, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "label-value--item" },
                      [
                        _c("div", { staticClass: "label-value--item-label" }, [
                          _vm._v(_vm._s(contact.title))
                        ]),
                        contact.typeId.includes("Telefon")
                          ? _c(
                              "div",
                              { staticClass: "label-value--item-value" },
                              [
                                _c("DialablePhoneNumber", {
                                  attrs: { phoneNumber: contact.value }
                                }),
                                _c("PhCheck", {
                                  staticClass: "color-success",
                                  style: { opacity: contact.default ? 1 : 0 },
                                  attrs: { size: 16 }
                                })
                              ],
                              1
                            )
                          : contact.typeId.includes("Mobil")
                          ? _c(
                              "div",
                              { staticClass: "label-value--item-value" },
                              [
                                _c("DialablePhoneNumber", {
                                  attrs: { phoneNumber: contact.value }
                                }),
                                _c("PhCheck", {
                                  staticClass: "color-success",
                                  style: { opacity: contact.default ? 1 : 0 },
                                  attrs: { size: 16 }
                                })
                              ],
                              1
                            )
                          : _c(
                              "div",
                              { staticClass: "label-value--item-value" },
                              [
                                _vm._v(" " + _vm._s(contact.value) + " "),
                                _c("PhCheck", {
                                  staticClass: "color-success",
                                  style: { opacity: contact.default ? 1 : 0 },
                                  attrs: { size: 16 }
                                })
                              ],
                              1
                            )
                      ]
                    )
                  }),
                  0
                )
              ]
            : _c("NoData", { attrs: { noIcon: "" } })
        ],
        2
      ),
      !_vm.$isSmallScreen
        ? _c("div", { staticClass: "bottom-row" }, [
            _c(
              "div",
              { staticClass: "text-right" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to:
                        "/persoenlichedaten/customer-data/steps/kommunikationsdaten"
                    }
                  },
                  [_vm._v("Kontaktdaten anzeigen")]
                )
              ],
              1
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }