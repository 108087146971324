<template>
  <div>
    <p>Zur Dokumentation ist jeweils ein Screenshot/Unterlagen beizulegen</p>
    <DragnDropArea
      class="form-select-file-button__container mb-3"
      hoverText="Hochladen"
      :disabled="disabledStatusOK"
      @files="upload($event, type)"
    >
      <BaseFileSelect @files="upload($event, type)" :disabled="disabledStatusOK">
        Dokument hochladen
      </BaseFileSelect>
      <DownloadLink
        target="_blank"
        rel="noopener noreferer"
        v-if="attachment"
        :title="attachment.bezeichnung"
        :filename="attachment.filename"
        downloadServicePath="/get_simple_file"
        :queryParams="{
          fileId: attachment.id,
        }"
      >
        <Pill :label="attachment.filename" type="default"></Pill>
      </DownloadLink>
      <BaseButton v-if="attachment" isLink
        ><span> <PhTrash  v-if="!disabledStatusOK" class="ml-2" :size="16" @click="onRemoveFile()" /></span>
       
      </BaseButton>
    </DragnDropArea>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import DragnDropArea from "@/components/fileUpload/DragnDropArea.vue";
import BaseFileSelect from "@/components/fileUpload/BaseFileSelect.vue";
import Vue from 'vue';
import DownloadLink from "@/components/core/download/DownloadLink.vue";
import { uploadFileChunked } from "@/helpers/upload-helper";
import MAKLER_ANTRAG_TYPES from "@/store/maklerAntrag/types";
import DOKUMENTENARCHIV_TYPES from "@/store/dokumentenarchiv/types";
import Pill from "@/components/core/Pill.vue";
import { PhTrash } from "phosphor-vue";
import BaseButton from "@/components/core/BaseButton.vue";
export default {
  components: {
    DragnDropArea,
    BaseFileSelect,
    DownloadLink,
    Pill,
    PhTrash,
    BaseButton,
  },
  data() {
    return {
      files: null,
      uploadFiles: [],
      type: "JAHRESCHECKLISTE_DOK",
      attachment: null,
    };
  },
  props: {
    paramId: {
      type: String,
      require: true,
    },
    topic: {
      type: String,
      require: true,
    },
    form:{

    }

  },
  computed: {
    ...mapGetters({
         attachments:MAKLER_ANTRAG_TYPES.GETTERS.GET_JAHRESCHECKLISTE_DOCS,
         maklerAntrag: MAKLER_ANTRAG_TYPES.GETTERS.GET_MAKLER_ANTRAG,
    }),
    disabledStatusOK(){
      return this.form.INPUT_STATUS_OK == '1' || this.form.INPUT_STATUS_OK == 'true'
    }
  },

watch:{
  'attachments': function(value){
    this.setAttachment()
  }
},
mounted(){
  this.setAttachment()
},
  methods: {
   async upload(files, type) {
      if(!this.paramId){      
      this.saveMaklerAntrag().then(()=>{
        if(files.length){
          this.doUpload(files,type)
        }
      }) 
      }else if (files.length) {
        this.doUpload(files,type)      
      }
    },
    async loadAttachments() {
      const response = await this.$store
        .dispatch(
          MAKLER_ANTRAG_TYPES.ACTIONS.LOAD_CHECKLISTE_DOCS,
          this.paramId || this.maklerAntrag.PARAM_ID
        )
       
        if(response && response.data){
          this.attachments = response.data;
           this.setAttachment();
        }
    },
    async doUpload(files,type){
      const parentId = btoa("53");
      await  uploadFileChunked(files[0])
          .then(async (id) => {
           await this.$store.dispatch(
              DOKUMENTENARCHIV_TYPES.ACTIONS.UPLOAD_DOCUMENT,
              {
                fileId: id,
                parentId: parentId || "",
                formData: {
                  JAHRESCHECKLISTE: this.paramId || this.maklerAntrag.PARAM_ID,
                  FILE_KATEGORIE: type,
                  INPUT_BEMERKUNG: this.topic,
                },
              }
            ).then(()=>{
              this.loadAttachments()
            })
            
          }) 
    },
    setAttachment() {
      if (this.attachments && this.attachments.length) {
        const result = this.attachments.find(
          (att) => att.bemerkung == this.topic
        );
        Vue.set(this, 'attachment',result)
      }else{
        Vue.set(this, 'attachment',null)
      }
    },
    async onRemoveFile() {
      await this.$store
        .dispatch(DOKUMENTENARCHIV_TYPES.ACTIONS.EXECUTE_ACTION, {
          action: {
            legend: { key: "DOKUMENT_LOSCHEN" },
            sendData: [
              { key: "nodeId", value: btoa("52") },
              { key: "PARAM_DOK_NODE_ITEM_TYPE", value: "OWNER_FILE" },
              { key: "DOKFILEID", value: this.attachment.id },
              { key: "FILE_TYPE", value: "JAHRESCHECKLISTE_DOK" },
              { key: "DOKANTRAGID", value: this.paramId },
            ],
          },
          nodeId: btoa("53"),
          documentId:
            "%2F2YEpdag3v6F2sowcBdqX5UfL7gbKOqvmQukJ%2FwP4Yk%3D&" +
            this.attachment.id,
        })
        .then(async() => {
         await this.loadAttachments();
        });
    },
    async saveMaklerAntrag(){
       await  this.$store.dispatch(
        MAKLER_ANTRAG_TYPES.ACTIONS.SAVE_JAHRESCHECKLISTE,
        this.form
      );
    }
  },
};
</script>
