import {Thrift} from "./thrift.js";

function MemoryTransport (buffer) {
    this._buffer = buffer,
    /*public void */
    this.write = function(/*String*/buffer) {
        this._buffer = buffer;
    };
    this.append = function(buffer) {
        this._buffer += buffer;
    }
    /*public string */
    this.readAll = function() {
        return this._buffer;
    };
    /* public String */
    this.flush = function(async, callback) {
        return this._buffer;
    };
    /* public jqXHR */
    this.jqRequest = function(client, postData, args, recv_method) {
        return postData;
    };
};
function TClosePhraseProtocol (transport) {
    this.transport = transport;
    const SEPARATOR = ';';
	const NAME_I32 = 'a';
	const NAME_I64 = 'b';
    const NAME_STRING = 's';

    let readPos = 0;
    
    function getTypeIDForTypeName(name) {
		let result = Thrift.Type.STOP;
		switch (name) {
			case NAME_I32:
				result = Thrift.Type.I32;
				break;
			case NAME_I64:
				result = Thrift.Type.I64;
				break;
			case NAME_STRING:
				result = Thrift.Type.STRING;
				break;
		}
        return result;
    }
    function getTypeNameForTypeID(typeID) {
        switch (typeID) {
			case Thrift.Type.I32:
				return NAME_I32;
			case Thrift.Type.I64:
				return NAME_I64;
			case Thrift.Type.STRING:
				return NAME_STRING;
			default:
				throw new Thrift.TException("Unrecognized type");
		}
    }
    this.writeInteger = function (num) {
        this.transport.append("" + num);
    }
    this.writeStr = function (s) {
        this.transport.append(s);
    }
 
    this.writeMessageBegin = this.writeMessageEnd = this.writeStructBegin = function () {};
    this.writeStructEnd = function() {
        this.transport.append(SEPARATOR);
    }
    this.writeFieldBegin = function(fieldName, fieldType, fieldId) {
        this.writeInteger(fieldId);
		this.writeStr(getTypeNameForTypeID(fieldType));
    }
    this.writeFieldEnd = function() {
        this.transport.append(SEPARATOR);
    }
    this.writeFieldStop = this.writeMapBegin = this.writeMapEnd = this.writeListBegin = this.writeListEnd = this.writeSetBegin = this.writeSetEnd = this.writeBool = this.writeByte = this.writeI16 = function () {};
    this.writeI32 = function(i32) {
        this.writeInteger(i32);
    }
    this.writeI64 = function(i64) {
        this.writeInteger(i64);
    }
    this.writeDouble = function () {};
    this.writeString = function (str) {
        this.writeStr(str);
    }
    this.writeBinary = function () {};

    function isNumeric(b) {
		switch (b) {
			case '+':
			case '-':
			case '.':
			case '0':
			case '1':
			case '2':
			case '3':
			case '4':
			case '5':
			case '6':
			case '7':
			case '8':
			case '9':
			case 'E':
			case 'e':
				return true;
		}
		return false;
    }

    this.peek = function () {
        return this.transport.readAll().charAt(readPos);
    }
    this.read = function () {
        readPos++;
        return this.transport.readAll().charAt(readPos - 1);
    }
    
    this.readNumericChars = function() {
        let result = "";
		while (true) {
			const ch = this.peek();
			if (ch == SEPARATOR[0]) {
				this.read();
				break;
			}
			if (!isNumeric(ch)) {
				break;
			}
			result += this.read();
		}
		return result;
    }
    this.readInteger = function() {
        return parseInt(this.readNumericChars());
    }

    this.readMessageBegin = function () {
        return {};
    }
    this.readMessageEnd = function () {};
    this.readStructBegin = function () {
        return {};
    }
    this.readStructEnd = function () {};
    this.readFieldBegin = function () {
		const ch = this.peek();
		let type = null;
		let id = 0;
		if (ch == SEPARATOR) {
			type = Thrift.Type.STOP;
		} else {
			id = this.readInteger();
			type = getTypeIDForTypeName(this.read());
			// ignore field if we can't understand its type
			if (type == Thrift.Type.STOP) {
				this.readString();
			}
		}
		return {
            name: "",
            ftype: type,
            fid: id,
        };
    }
    this.readFieldEnd = function () {};
    this.readMapBegin = function () {
        return {};
    }
    this.readMapEnd = function () {};
    this.readListBegin = function () {
        return {};
    }
    this.readListEnd = function () {};
    this.readSetBegin = function () {
        return {};
    }
    this.readSetEnd = function () {};
    this.readBool = function () {
        return false;
    }
    this.readByte = this.readI16 = function () {
        return 0;
    }
    this.readI32 = function () {
        return this.readInteger();
    }
    this.readI64 = function () {
        return this.readInteger();
    }
    this.readDouble = function () {
        return 0;
    }
    this.readString = function () {
        let result = "";
        while (true) {
            const ch = this.read();
            if (ch == SEPARATOR) {
                break;
            }
            result += ch;
        }
        return result;
    }
    this.readBinary = function () {
        return "";
    }

}
TClosePhraseProtocol.prototype = Thrift.Protocol;


export {MemoryTransport, TClosePhraseProtocol};