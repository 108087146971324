export const MUTATION_PREFIX = 'DOKUMENTENARCHIV_MUTATIONS_';
export const ACTIONS_PREFIX = 'DOKUMENTENARCHIV_ACTIONS_';
export const GETTERS_PREFIX = 'DOKUMENTENARCHIV_GETTERS_';

export default {
  MUTATIONS: {
    RETRIEVE_DOKUMENTENARCHIV_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_DOKUMENTENARCHIV_SUCCESS',
    RESET_DATA: MUTATION_PREFIX + 'RESET_DATA',
    SET_TARGET_NODE_ID: MUTATION_PREFIX + 'SET_TARGET_NODE_ID',
    ADD_LOADING_NODE_ID_TO_LIST: MUTATION_PREFIX + 'ADD_LOADING_NODE_ID_TO_LIST',
    UPDATE_LOADING_STATUS: MUTATION_PREFIX + 'UPDATE_LOADING_STATUS',
    RETRIEVE_ADDITIONAL_UPLOAD_INPUTS_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_ADDITIONAL_UPLOAD_INPUTS_SUCCESS',
    RETRIEVE_DOCUMENTS_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_DOCUMENTS_SUCCESS',
    SET_PAGE: MUTATION_PREFIX + 'SET_PAGE',    
    RETRIEVE_ADDITIONAL_DOCUMENTS_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_ADDITIONAL_DOCUMENTS_SUCCESS',
    SET_ROW_SELECTION: MUTATION_PREFIX + 'SET_ROW_SELECTION',
    SET_TREE_LOADING: MUTATION_PREFIX + 'SET_TREE_LOADING',
    SET_SELECTED_ALL_IN_FOLDER: MUTATION_PREFIX + 'SET_SELECTED_ALL_IN_FOLDER',

    UPDATE_SELECTED_NODE_TREE: MUTATION_PREFIX + 'UPDATE_SELECTED_NODE_TREE',
    SET_TEMP_UPLOAD_FILES: MUTATION_PREFIX + 'SET_TEMP_UPLOAD_FILES',
    SORTING: MUTATION_PREFIX + 'SORTING',
    RELOAD_DOCUMENTS_PAYLOAD: MUTATION_PREFIX + 'RELOAD_DOCUMENTS_PAYLOAD',

    SET_DOCUMENTS_FILTER: MUTATION_PREFIX + 'SET_DOCUMENTS_FILTER',

    ADD_VIRTUAL_NUMBER_OF_RECORDS: MUTATION_PREFIX + 'ADD_VIRTUAL_NUMBER_OF_RECORDS',
    RESET_VIRTUAL_NUMBER_OF_RECORDS: MUTATION_PREFIX + 'RESET_VIRTUAL_NUMBER_OF_RECORDS',
    GET_PROHYP_DOCUMENTS_SUCCESS: MUTATION_PREFIX + 'GET_PROHYP_DOCUMENTS_SUCCESS',
  },
  ACTIONS: {
    RETRIEVE_DOKUMENTENARCHIV: ACTIONS_PREFIX + 'RETRIEVE_DOKUMENTENARCHIV',
    RETRIEVE_NODE_TREE: ACTIONS_PREFIX + 'RETRIEVE_NODE_TREE',
    RETRIEVE_ATTACHMENTS_INFO: ACTIONS_PREFIX + 'RETRIEVE_ATTACHMENTS_INFO',
    UPLOAD_DOCUMENT: ACTIONS_PREFIX + 'UPLOAD_DOCUMENT',
    EXECUTE_ACTION: ACTIONS_PREFIX + 'EXECUTE_ACTION',
    RETRIEVE_ADDITIONAL_UPLOAD_INPUTS: ACTIONS_PREFIX + 'RETRIEVE_ADDITIONAL_UPLOAD_INPUTS',
    SORT_DOCUMENTS: ACTIONS_PREFIX + 'SORT_DOCUMENTS',
    RETRIEVE_DOCUMENTS: ACTIONS_PREFIX + 'RETRIEVE_DOCUMENTS',
    DOKUMENTE_HINZUFUGEN_UPLOAD: ACTIONS_PREFIX + 'DOKUMENTE_HINZUFUGEN_UPLOAD',
    RETRIEVE_ADDITIONAL_DOCUMENTS: ACTIONS_PREFIX + 'RETRIEVE_ADDITIONAL_DOCUMENTS',
    EXECUTE_HEADER_ACTION: ACTIONS_PREFIX + 'EXECUTE_HEADER_ACTION',
    GET_FILE_DATA: ACTIONS_PREFIX + 'GET_FILE_DATA',
    DOWNLOAD_ATTACHMENT: ACTIONS_PREFIX + 'DOWNLOAD_ATTACHMENT',
    ADDITIONAL_DOCUMENTS_ACTIONS: ACTIONS_PREFIX + 'ADDITIONAL_DOCUMENTS_ACTIONS',
    EXECUTE_EDIT_FILE: ACTIONS_PREFIX + 'EXECUTE_EDIT_FILE',

    RELOAD_SELECTED_NODE_TREE: ACTIONS_PREFIX + 'RELOAD_SELECTED_NODE_TREE',
    SAVE_PRIVATE_DOK_NODE: ACTIONS_PREFIX + 'SAVE_PRIVATE_DOK_NODE',
    UPDATE_PRIVATE_DOK_NODE: ACTIONS_PREFIX + 'UPDATE_PRIVATE_DOK_NODE',
    GET_PRIVATE_DOK_NODE: ACTIONS_PREFIX + 'GET_PRIVATE_DOK_NODE',
    DELETE_PRIVATE_DOK_NODE: ACTIONS_PREFIX + 'DELETE_PRIVATE_DOK_NODE',

    DROP_ACTION: ACTIONS_PREFIX + 'DROP_ACTION',
    GET_PROHYP_DOCUMENTS: ACTIONS_PREFIX + 'GET_PROHYP_DOCUMENTS',
    EXECUTE_PROHYP_UPLOAD: ACTIONS_PREFIX + 'EXECUTE_PROHYP_UPLOAD',
  },
  GETTERS: {
    DOKUMENTENARCHIV_DATA: GETTERS_PREFIX + 'DOKUMENTENARCHIV_DATA',
    DOKUMENT_ACTIONS: GETTERS_PREFIX + 'DOKUMENT_ACTIONS',
    ADDITIONAL_UPLOAD_INPUTS: GETTERS_PREFIX + 'ADDITIONAL_UPLOAD_INPUTS',
    DOCUMENTS: GETTERS_PREFIX + 'DOCUMENTS',
    DOCUMENT_PAGE: GETTERS_PREFIX + 'DOCUMENT_PAGE',
    ADDITIONAL_DOCUMENTS: GETTERS_PREFIX + 'ADDITIONAL_DOCUMENTS',
    SELECTED_ROWS: GETTERS_PREFIX + 'SELECTED_ROWS',
    GET_ATTACHMENT_LINK_BY_ID: GETTERS_PREFIX + 'GET_ATTACHMENT_LINK_BY_ID',
    IS_LOADING_NODE: GETTERS_PREFIX + 'IS_LOADING_NODE',
    SHOW_SCHADEN_DOCUMENTS: GETTERS_PREFIX + 'SHOW_SCHADEN_DOCUMENTS',
    SHOW_DELETED_DOCUMENTS: GETTERS_PREFIX + 'SHOW_DELETED_DOCUMENTS',
    DOC_LABEL: GETTERS_PREFIX + 'DOC_LABEL',
    IS_SELECTED_ALL_IN_FOLDER: GETTERS_PREFIX + 'IS_SELECTED_ALL_IN_FOLDER',
    TEMP_UPLOAD_FILES: GETTERS_PREFIX + 'TEMP_UPLOAD_FILES',
    TREE_LOADING: GETTERS_PREFIX + 'TREE_LOADING',
    SORTING: GETTERS_PREFIX + 'SORTING',
    DOCUMENTS_FILTER: GETTERS_PREFIX + 'DOCUMENTS_FILTER',
    IS_DROP_ACTION_ACTIVE: GETTERS_PREFIX + 'IS_DROP_ACTION_ACTIVE',
    PROHYP_DOCUMENTS: GETTERS_PREFIX + 'PROHYP_DOCUMENTS',
  }
}