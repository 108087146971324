var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseButton",
    {
      attrs: { isClear: "" },
      on: {
        click: function($event) {
          return _vm.openModal()
        }
      }
    },
    [
      _c("ph-magnifying-glass", { attrs: { size: _vm.size } }),
      _c("BaseModal", {
        ref: "searchModal",
        attrs: { size: _vm.modalSize, showDefaultButtons: false },
        on: { close: _vm.modalClosed },
        scopedSlots: _vm._u([
          {
            key: "modalTitle",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "global-search--title-container" },
                  [
                    _c("div", { staticClass: "global-search--title" }, [
                      _vm._v("Globale Suche")
                    ]),
                    _c("GlobalSearchHelpDropdown", {
                      staticClass: "global-search--help-button"
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _c("GlobalSearch", {
                  on: {
                    recentMenuOpened: _vm.hideModal,
                    close: _vm.hideModal,
                    modalSizeChanged: _vm.changeModalSize
                  }
                }),
                _c("div", { staticClass: "text-right" }, [
                  _c("span", { staticClass: "text-small" }, [
                    _vm._v("version: " + _vm._s(_vm.currentVersion))
                  ])
                ])
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }