var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "96adce4c-eb60-42ce-a2b3-116be577c7f4",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        width: "1157.72",
        height: "825.98",
        viewBox: "0 0 1157.72 825.98"
      }
    },
    [
      _c(
        "defs",
        [
          _c(
            "linearGradient",
            {
              attrs: {
                id: "9eb45642-ac44-401b-a69a-da5dedbae5b0",
                x1: "418.19",
                y1: "540.35",
                x2: "418.19",
                y2: "8.65",
                gradientUnits: "userSpaceOnUse"
              }
            },
            [
              _c("stop", {
                attrs: {
                  offset: "0",
                  "stop-color": "gray",
                  "stop-opacity": "0.25"
                }
              }),
              _c("stop", {
                attrs: {
                  offset: "0.54",
                  "stop-color": "gray",
                  "stop-opacity": "0.12"
                }
              }),
              _c("stop", {
                attrs: {
                  offset: "1",
                  "stop-color": "gray",
                  "stop-opacity": "0.1"
                }
              })
            ],
            1
          ),
          _c("linearGradient", {
            attrs: {
              id: "c3921df2-9812-4dc7-9d17-147517e1c049",
              x1: "561.46",
              y1: "451.84",
              x2: "561.46",
              y2: "451.78",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "324d97de-38ce-46a9-a16b-73fa50900cb8",
              x1: "566.83",
              y1: "511.15",
              x2: "566.83",
              y2: "451.84",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "eee737d7-b20b-417c-a3fa-aee7cf179088",
              x1: "566.42",
              y1: "505.23",
              x2: "566.42",
              y2: "500.93",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "7a4bf5f6-0a2d-4d46-9148-34580b38aa0c",
              x1: "573.33",
              y1: "463.86",
              x2: "573.33",
              y2: "457.49",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "1873546a-7082-44d0-8613-2894044f6770",
              x1: "574.03",
              y1: "466.6",
              x2: "574.03",
              y2: "461.87",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "bdd8dd2b-0953-4002-9214-f67ecfc20577",
              x1: "571",
              y1: "471.64",
              x2: "571",
              y2: "465.77",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "a5b44cbd-acf7-4c5e-bca9-9655f59a73e4",
              x1: "569.74",
              y1: "476.81",
              x2: "569.74",
              y2: "472.34",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "1b791299-4efa-4eee-b585-14c9478af84e",
              x1: "567.96",
              y1: "482.09",
              x2: "567.96",
              y2: "474.74",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "675b5daa-23af-4e11-b3fa-e46631febacd",
              x1: "566.96",
              y1: "483.74",
              x2: "566.96",
              y2: "478.79",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "630057ce-9e1d-44ba-b046-d9f8db19c76f",
              x1: "569.17",
              y1: "486.56",
              x2: "569.17",
              y2: "482.56",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "662f01a2-2aa8-423e-b0f2-c327d1d6aa86",
              x1: "564.99",
              y1: "495.8",
              x2: "564.99",
              y2: "488.04",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "10d603f6-75bf-4960-8ede-b0e49c938813",
              x1: "567.74",
              y1: "493.71",
              x2: "567.74",
              y2: "490.62",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "16a76fc1-6006-4084-b7a4-1e8655cd3e2b",
              x1: "563.91",
              y1: "502.03",
              x2: "563.91",
              y2: "492.66",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "906200ce-c2e1-4e69-aca6-6b0c300e8d97",
              x1: "564.17",
              y1: "508.32",
              x2: "564.17",
              y2: "497.03",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "09ae5dbf-04ae-456f-b606-b78be7f557d4",
              x1: "589.65",
              y1: "508.24",
              x2: "589.65",
              y2: "461.7",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "ca582b94-4b90-4460-aa10-f094c60b8e01",
              x1: "637.22",
              y1: "511.4",
              x2: "637.22",
              y2: "460.75",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "d72adaae-db48-4891-8751-e69fb2eb4adc",
              x1: "593.95",
              y1: "510.51",
              x2: "593.95",
              y2: "460.75",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "a1584201-eed1-4945-ad33-134cef0a8100",
              x1: "556.38",
              y1: "511.14",
              x2: "556.38",
              y2: "451.84",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "c1f48460-d486-42d8-b725-65643a87f10e",
              x1: "777.04",
              y1: "810.07",
              x2: "777.04",
              y2: "679.24",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "d12d6e01-7b31-4c3b-a267-ba8df3c8502a",
              x1: "858.15",
              y1: "793.9",
              x2: "858.15",
              y2: "586.42",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "c0a593ea-604f-4b79-bf7e-88ef80098439",
              x1: "777.01",
              y1: "463",
              x2: "777.01",
              y2: "384.85",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "59f0f69e-0789-4b50-8eee-3503c8852587",
              x1: "693.32",
              y1: "655.8",
              x2: "693.32",
              y2: "566.67",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "ecbbec08-52f5-486e-baee-0d22d5e5f35b",
              x1: "784.01",
              y1: "593.15",
              x2: "784.01",
              y2: "445.4",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "94cd213e-49ee-4606-a8ea-9126ef4159fc",
              x1: "789.52",
              y1: "479.23",
              x2: "789.52",
              y2: "445.4",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "b4c2834c-08de-4c0f-a497-47f56f129203",
              x1: "769.72",
              y1: "440.22",
              x2: "769.72",
              y2: "359.14",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "6fc82309-225f-4073-ba7e-a386c8e0d1cc",
              x1: "784.01",
              y1: "593.15",
              x2: "784.01",
              y2: "487.36",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "e46a40f0-a414-4ea9-87ff-b37ea4c29189",
              x1: "782.08",
              y1: "693.69",
              x2: "782.08",
              y2: "490.63",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "e3f1ace3-4a96-46e0-b33c-8544e3cf838f",
              x1: "782.08",
              y1: "691.68",
              x2: "782.08",
              y2: "488.62",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "ea07025c-0e83-46b8-8f52-6c3207ad5db6",
              x1: "693.32",
              y1: "590.64",
              x2: "693.32",
              y2: "566.67",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "07131257-a2d2-43b2-a496-09592b21e720",
              x1: "694.66",
              y1: "588.64",
              x2: "694.66",
              y2: "521.11",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "68826aad-586b-4b89-9b23-6b3f1523aa46",
              x1: "674.74",
              y1: "658.97",
              x2: "674.74",
              y2: "490.67",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "63604195-5fe1-4fe1-9a46-195dbf7de3df",
              x1: "825.97",
              y1: "775.45",
              x2: "825.97",
              y2: "736.79",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "8ab28d86-2676-4cef-81e6-8417288a6835",
              x1: "816.79",
              y1: "771.83",
              x2: "816.79",
              y2: "736.81",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "fcf262f3-a42e-42c8-9ced-d7a52a7c5ba6",
              x1: "689.2",
              y1: "658.97",
              x2: "689.2",
              y2: "646.08",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "76592684-a275-48c6-834d-813e774799b7",
              x1: "750.14",
              y1: "810.91",
              x2: "750.14",
              y2: "648.09",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "9a87b11e-0e93-49fd-90c4-230c3356c4c7",
              x1: "737.1",
              y1: "796.17",
              x2: "737.1",
              y2: "660.64",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "d1cc038d-1412-4354-9890-63a82c5891a8",
              x1: "735.05",
              y1: "798.18",
              x2: "735.05",
              y2: "662.65",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "75539bfb-e051-4ab0-89da-ccc2e7793d7a",
              x1: "735.05",
              y1: "796.17",
              x2: "735.05",
              y2: "660.64",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "6c8c047c-fdf2-405d-ae69-93a7955e0e3c",
              x1: "689.04",
              y1: "772.24",
              x2: "689.04",
              y2: "735.53",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "6ebf3550-27dd-4b32-9fdf-076e08994285",
              x1: "794.31",
              y1: "747.43",
              x2: "794.31",
              y2: "707.65",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "9313d757-6aef-4307-8c32-3c7374438594",
              x1: "812.48",
              y1: "784.44",
              x2: "812.48",
              y2: "758.84",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "53df1f91-1770-48b7-9bef-bf6bf01c3f97",
              x1: "805.2",
              y1: "833.36",
              x2: "805.2",
              y2: "759.84",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "72bd7345-a516-48e4-9dc7-291477333a62",
              x1: "805.2",
              y1: "833.36",
              x2: "805.2",
              y2: "804.2",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "123e6431-808c-4338-886b-86770c5413c7",
              x1: "777.31",
              y1: "739.49",
              x2: "777.31",
              y2: "736.79",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "57ab2043-46d4-4ed5-bbe3-05e270c6b49e",
              x1: "780.05",
              y1: "750.13",
              x2: "780.05",
              y2: "747.43",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "8086d19d-7628-4b81-b869-f6078bbb2fc8",
              x1: "779.31",
              y1: "746.18",
              x2: "779.31",
              y2: "743.48",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "71fb1645-291f-4eb7-8225-b6ca42f6896b",
              x1: "780.05",
              y1: "755.44",
              x2: "780.05",
              y2: "752.74",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "ee711ff6-6027-438c-807c-c83c40cf1416",
              x1: "773.46",
              y1: "760.94",
              x2: "773.46",
              y2: "758.24",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "3b0c90e8-3e2e-494c-abf4-d76076270d29",
              x1: "862.64",
              y1: "812.46",
              x2: "862.64",
              y2: "774.6",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "aff462f7-b22a-4aee-b069-6ff16a23c41a",
              x1: "907.2",
              y1: "814.39",
              x2: "907.2",
              y2: "760.44",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "b8765757-b253-4c78-b455-89dba0362a09",
              x1: "907.2",
              y1: "798.12",
              x2: "907.2",
              y2: "761.84",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "baa2132a-640c-4392-8c03-f8a3a934dd2f",
              x1: "868.85",
              y1: "763.68",
              x2: "868.85",
              y2: "611.9",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "c377a887-7ef8-4568-81cb-14860a038b29",
              x1: "871.92",
              y1: "789.8",
              x2: "871.92",
              y2: "611.9",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "f794111e-fa4d-4866-a118-7d6bc0a4d194",
              x1: "816.9",
              y1: "761.86",
              x2: "816.9",
              y2: "744.66",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "5ab4824a-bd9f-435a-8648-4ff84520ee27",
              x1: "870.9",
              y1: "789.8",
              x2: "870.9",
              y2: "602.69",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "47edc419-4189-48da-a88f-6f02fec89647",
              x1: "769.35",
              y1: "651.44",
              x2: "769.35",
              y2: "638.6",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "db05c772-84d4-4714-a206-28a51ba64cf9",
              x1: "764.04",
              y1: "663.34",
              x2: "764.04",
              y2: "657.71",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "2aa969ef-e70a-47e6-8fc0-9a06eccf5825",
              x1: "726.47",
              y1: "530.38",
              x2: "726.47",
              y2: "504.12",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "b8dd2f66-99a5-4fb7-b84d-72a384286528",
              x1: "708.03",
              y1: "538.2",
              x2: "708.03",
              y2: "512.74",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "64ce91db-faed-4a41-aed5-eaaad768e17f",
              x1: "748.85",
              y1: "773.83",
              x2: "748.85",
              y2: "726.79",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "198c7f00-a40e-4c94-b12d-cdc61db2a37b",
              x1: "896.31",
              y1: "743.48",
              x2: "896.31",
              y2: "740.78",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "7f6423fb-d328-484b-940e-cabe8a476a35",
              x1: "901.77",
              y1: "771.66",
              x2: "901.77",
              y2: "768.96",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "442f9016-39e4-48b4-97d9-31ee76430526",
              x1: "890.68",
              y1: "721.79",
              x2: "890.68",
              y2: "660.64",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "d53aa4f0-6411-425a-9ac7-2a6e83836793",
              x1: "885.78",
              y1: "675.79",
              x2: "885.78",
              y2: "637.37",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "8ccaeed1-d6b7-4ab3-9782-a68e9ca8520e",
              x1: "848.29",
              y1: "754.42",
              x2: "848.29",
              y2: "740.55",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "48d1bb63-b435-447e-947d-37e1e2a67403",
              x1: "864.81",
              y1: "750.13",
              x2: "864.81",
              y2: "747.43",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "35ff77dd-c62d-4bef-a1ca-8b20def25ace",
              x1: "846.19",
              y1: "747.53",
              x2: "846.19",
              y2: "744.83",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "8f36819d-fb09-4332-9b6d-a6ac139f1f7f",
              x1: "881.19",
              y1: "749.6",
              x2: "881.19",
              y2: "746.9",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "adf438c9-62c7-42a4-b242-177369b28fd2",
              x1: "886.06",
              y1: "770.31",
              x2: "886.06",
              y2: "767.61",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "d8c5bce7-43b1-49db-9994-a75dcb1ba9e5",
              x1: "889.38",
              y1: "746.9",
              x2: "889.38",
              y2: "744.2",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "0b11f5a3-8ae9-4ec0-b0e4-8f8f835d5b28",
              x1: "893.72",
              y1: "770.31",
              x2: "893.72",
              y2: "767.61",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "961bbb9f-1bc2-4dd1-9e59-496da606e16c",
              x1: "872.64",
              y1: "750.95",
              x2: "872.64",
              y2: "748.25",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "e2a35fe1-bf7e-4cc5-80cf-cd5536c1bdb9",
              x1: "876.21",
              y1: "771.66",
              x2: "876.21",
              y2: "768.96",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "fad86b09-880e-427b-8f95-010953ce79f8",
              x1: "856.52",
              y1: "749.6",
              x2: "856.52",
              y2: "746.9",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "58b1f8cb-3444-47b9-aa9a-29767dc9198a",
              x1: "806.51",
              y1: "727.79",
              x2: "806.51",
              y2: "668.88",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "95c7ab8c-e4a2-4a0b-9c5d-253bb396c6a9",
              x1: "814.59",
              y1: "726.79",
              x2: "814.59",
              y2: "588.63",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "0689681d-3e16-4713-89c6-79a6df0c2037",
              x1: "850.17",
              y1: "603.03",
              x2: "850.17",
              y2: "588.63",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "3429680b-e53c-49fb-8152-45f434bfc0c1",
              x1: "852.9",
              y1: "601.03",
              x2: "852.9",
              y2: "566.67",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "fa4b69ab-4044-466c-a52e-1fc11ccc4e0c",
              x1: "749.15",
              y1: "577.5",
              x2: "749.15",
              y2: "490.97",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "30ad1975-f5dc-4274-bb3c-b9c4f20b0fb0",
              x1: "848.06",
              y1: "581.05",
              x2: "848.06",
              y2: "488.62",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "d36ad3bd-8d93-4981-9b38-7e68914f4feb",
              x1: "803.57",
              y1: "585.21",
              x2: "803.57",
              y2: "369.24",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "e62193dc-90cc-4716-baf9-ed9aa01c58ec",
              x1: "866",
              y1: "427.4",
              x2: "866",
              y2: "413.47",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "0ded85c0-3985-406f-9981-c8862c085a5b",
              x1: "767.18",
              y1: "450.76",
              x2: "767.18",
              y2: "418.27",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "207473ec-285f-4cb0-900d-401e69e367a5",
              x1: "721.08",
              y1: "477.69",
              x2: "721.08",
              y2: "430.35",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "775de71c-7a44-4518-aeac-97c00c9966eb",
              x1: "762.7",
              y1: "532.98",
              x2: "762.7",
              y2: "519.25",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "aedcd206-4f7b-48e9-8781-e8202b836b94",
              x1: "759.55",
              y1: "491.42",
              x2: "759.55",
              y2: "466.23",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "c1bb3b15-f111-4b8d-9469-94d017edeb6b",
              x1: "850.33",
              y1: "585.21",
              x2: "850.33",
              y2: "548.89",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "23edbfb8-e6e1-43b9-b8d7-28fd916cff92",
              x1: "885.57",
              y1: "560.24",
              x2: "885.57",
              y2: "486.11",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "2eb4d4fb-05bb-4375-a02c-9d70a5391a04",
              x1: "811.63",
              y1: "524.13",
              x2: "811.63",
              y2: "497.28",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "c891de9a-5269-4998-b035-f98334afd135",
              x1: "749.16",
              y1: "575.49",
              x2: "749.16",
              y2: "509.11",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "5aa79ae1-bc14-4465-90c2-b1c4a3b8ecc3",
              x1: "798.41",
              y1: "458.74",
              x2: "798.41",
              y2: "399.27",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "143adfe7-e64a-4465-9788-e6626a22f82c",
              x1: "778.83",
              y1: "639.38",
              x2: "778.83",
              y2: "581.08",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "2ffe8922-870f-4473-800d-42a3319e36c4",
              x1: "777.81",
              y1: "637.37",
              x2: "777.81",
              y2: "579.07",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "d3eb8c1d-3143-4526-bc61-fcc74e63e0a2",
              x1: "704.23",
              y1: "625.68",
              x2: "704.23",
              y2: "592.01",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "a5ed3db4-94f3-414f-93ab-d65698b876a7",
              x1: "783.34",
              y1: "573",
              x2: "783.34",
              y2: "552.61",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "401c088d-6620-4ef5-af60-8d16cc8201d0",
              x1: "812.36",
              y1: "782.25",
              x2: "812.36",
              y2: "768.97",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          }),
          _c("linearGradient", {
            attrs: {
              id: "74ac0e64-4c3a-4de0-8f0b-12f809b40d22",
              x1: "875.12",
              y1: "811.72",
              x2: "875.12",
              y2: "787.42",
              "xlink:href": "#9eb45642-ac44-401b-a69a-da5dedbae5b0"
            }
          })
        ],
        1
      ),
      _c("ellipse", {
        attrs: {
          cx: "788.82",
          cy: "768.45",
          rx: "195.65",
          ry: "26.54",
          fill: _vm.color,
          opacity: "0.1"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "1029.06",
          cy: "710.91",
          rx: "82.47",
          ry: "11.19",
          fill: _vm.color,
          opacity: "0.1"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "1053.29",
          cy: "821.13",
          rx: "35.78",
          ry: "4.85",
          fill: _vm.color,
          opacity: "0.1"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "428.53",
          cy: "755.65",
          rx: "82.47",
          ry: "11.19",
          fill: _vm.color,
          opacity: "0.1"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "285.37",
          cy: "705.99",
          rx: "82.47",
          ry: "11.19",
          fill: _vm.color,
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M734.14,125.34C684.46,156.49,620,154.47,563.56,138.27S455.23,93.21,400.21,72.71A580.66,580.66,0,0,0,256.7,39.2C186.59,32,108.29,40.77,59.43,91.12,5,147.19,9.21,248.32,68.05,299.8c29.93,26.18,69.12,39,103.3,59.47s65.82,54.39,62.91,93.85c-2.7,36.52-34,64.55-66.44,82.14-25.06,13.59-55.93,28.43-58.95,56.58-2.92,27.24,23,48.59,47.81,60.72,80.84,39.6,180.43,39.22,261-1,28.73-14.35,55.14-33.43,85.27-44.62,79.11-29.36,166.28.2,250.05,11A556.31,556.31,0,0,0,965.63,604.1c41.48-11,83.12-27.75,112.26-59,21-22.53,34.11-51.05,46.4-79.21q19.67-45,37.8-90.73c7.25-18.28,14.4-36.92,16.27-56.48,3.4-35.44-10.91-70.48-30.4-100.39-46.26-71-124.17-121-208.51-133.65S765.76,96.73,700.32,151",
          transform: "translate(-21.14 -37.01)",
          fill: _vm.color,
          opacity: "0.1"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "109.49 8.65 109.49 29.28 109.49 540.35 726.89 540.35 726.89 29.28 726.89 8.65 109.49 8.65",
          fill: "url(#9eb45642-ac44-401b-a69a-da5dedbae5b0)"
        }
      }),
      _c("rect", {
        attrs: {
          x: "113.94",
          y: "13.31",
          width: "608.5",
          height: "20.27",
          fill: "#f6f7f9"
        }
      }),
      _c("rect", {
        attrs: {
          x: "113.94",
          y: "13.31",
          width: "608.5",
          height: "20.27",
          fill: "#534d72"
        }
      }),
      _c("rect", {
        attrs: {
          x: "113.94",
          y: "33.58",
          width: "608.5",
          height: "502.1",
          fill: "#f6f7f9"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "127.06",
          cy: "23.45",
          rx: "4.56",
          ry: "3.44",
          fill: "#f6f7f9"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "139.42",
          cy: "23.45",
          rx: "4.56",
          ry: "3.44",
          fill: "#f6f7f9"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "151.77",
          cy: "23.45",
          rx: "4.56",
          ry: "3.44",
          fill: "#f6f7f9"
        }
      }),
      _c("g", { attrs: { opacity: "0.2" } }, [
        _c("rect", {
          attrs: {
            x: "257.92",
            y: "64.54",
            width: "320.55",
            height: "9.46",
            fill: _vm.color
          }
        })
      ]),
      _c("rect", {
        attrs: {
          x: "535.88",
          y: "64.54",
          width: "42.59",
          height: "9.46",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d: "M561.48,451.84l0,0S561.46,451.78,561.48,451.84Z",
          transform: "translate(-21.14 -37.01)",
          fill: "url(#c3921df2-9812-4dc7-9d17-147517e1c049)"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M545.41,505.14l-4.68,3.16c0-2.21,8.23-11,8.23-11-2.13-1.2-9.67,4.68-9.67,4.68.89-3.85,7.55-8.51,7.55-8.51-.58-.3-5.72,2.3-5.72,2.3,1.68-3.55,8.61-8.69,8.61-8.69-.86-.55-5.93,1.64-6.17,1.74.27-.19,8.71-6.11,8-6.29s-7.14,1.15-7.14,1.15c-.5-2.26,10-12.91,10-12.91s1.31-1.85.84-1.36-5.34,2.18-5.34,2.18c.07-3.33,10.22-8.49,10.22-8.49l-4.62-.18,3-3.27c-.14.11-4.9,3.76-5,2.69s4.48-5.37,5.46-6,4-.12,4-.12-1.32-3.81-1.57-4.36c.73.6,11.09,5,11.09,5l.9.18c2.42.47,9,1.8,10,2.5s6,1.65,8.88,2.21l2,.38L587,508.24c-.68,0-1.36,0-2,0-1.19,0-2.37-.1-3.55-.2q-2.79-.25-5.55-.76c-3.7-.69-8.56-1.61-12.31-2.34-2.32-.45-4.21-.84-5.14-1.05-3.71-.87-8.34,2-9.4,2s-7.44,5.31-7.44,5.31C541.66,510.15,545.41,505.14,545.41,505.14Z",
          transform: "translate(-21.14 -37.01)",
          fill: "url(#324d97de-38ce-46a9-a16b-73fa50900cb8)"
        }
      }),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d:
              "M545.37,505.06c.08,0,7.28-2.95,9.68-3.89s31.19,1.13,32.41,1.22v.18c-.3,0-29.94-2.16-32.33-1.22s-9.6,3.85-9.67,3.88Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#eee737d7-b20b-417c-a3fa-aee7cf179088)"
          }
        })
      ]),
      _c("path", {
        attrs: {
          d: "M561.48,451.84l0,0S561.46,451.78,561.48,451.84Z",
          transform: "translate(-21.14 -37.01)",
          fill: "url(#c3921df2-9812-4dc7-9d17-147517e1c049)"
        }
      }),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d:
              "M553.5,462.27a55.92,55.92,0,0,1,6.12-4.32c1.64-.91,4.85-.26,7.19.21a16.15,16.15,0,0,0,2.08.34c1.43,0,9.26,2.22,9.59,2.32.08,0,8.56,2,9.63,1.94a32.35,32.35,0,0,1,5,.91l0,.19a32.82,32.82,0,0,0-5-.92c-1.1.07-9.34-1.86-9.69-1.94-.08,0-8.13-2.31-9.54-2.32a13.82,13.82,0,0,1-2.11-.34c-2.19-.44-5.49-1.1-7.07-.23a59.1,59.1,0,0,0-6.1,4.3Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#7a4bf5f6-0a2d-4d46-9148-34580b38aa0c)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d:
              "M555.19,462.79c.73-.81,9.23-1.1,10-.8s10.51,1.25,11,1.23c.16,0,12.8,2.44,16.67,3.2l0,.18c-5.62-1.1-16.34-3.17-16.63-3.19-.05,0-10.19-.9-11.09-1.25s-9.22.14-9.78.75Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#1873546a-7082-44d0-8613-2894044f6770)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d:
              "M549.8,471.54c2.61-4.44,17.34-5.76,17.48-5.77,1.57-.13,24,4.12,24.92,4.3l0,.18c-.23,0-23.32-4.43-24.86-4.29-4.1.35-15.21,2.05-17.33,5.68Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#bdd8dd2b-0953-4002-9214-f67ecfc20577)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d:
              "M548.8,476.69c4.16-4.67,17.36-4.35,17.49-4.34,1,0,23.44,3.36,24.39,3.5l0,.18c-.23,0-23.39-3.45-24.37-3.49-.13,0-13.23-.33-17.33,4.27Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#a5b44cbd-acf7-4c5e-bca9-9655f59a73e4)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d:
              "M544.87,481.86c1.56-2.91,5.94-5,13-6.19a72.7,72.7,0,0,1,10.37-.93l22.86,2.61,0,.19-22.84-2.61c-.19,0-19.79.15-23.29,7.16l-.17-.08Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#1b791299-4efa-4eee-b585-14c9478af84e)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d:
              "M544.31,483.62c2.19-2.4,10.76-4.3,14.86-4.79,3.85-.46,29.36,3.57,30.45,3.74l0,.18c-.27,0-26.57-4.19-30.4-3.74s-12.61,2.4-14.74,4.73Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#675b5daa-23af-4e11-b3fa-e46631febacd)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d:
              "M549.32,484.51l9.67-1.6a25.82,25.82,0,0,1,7.5-.14l10.72,1.38L589,486.38l0,.18-11.8-2.23L566.47,483a25.83,25.83,0,0,0-7.45.13l-9.67,1.61Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#630057ce-9e1d-44ba-b046-d9f8db19c76f)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d:
              "M541.23,495.29c2-3.72,10.9-5.57,15.78-6.59,1-.21,1.85-.38,2.33-.52,2.76-.77,28.52,1.82,29.61,1.93l0,.19c-.27,0-26.82-2.7-29.54-1.94-.49.14-1.31.31-2.34.52-4.67,1-14.4,3-15.84,6.92l-.18-.06A3,3,0,0,1,541.23,495.29Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#662f01a2-2aa8-423e-b0f2-c327d1d6aa86)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d:
              "M546.61,493.53s4.92-1.55,6-2.06,13.85-.83,14.39-.85L588.87,492l0,.19L567,490.81c-.12,0-13.21.32-14.31.82s-5.86,2-6.06,2.08Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#10d603f6-75bf-4960-8ede-b0e49c938813)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d:
              "M539.21,501.93c4.81-6.76,23.13-9.38,24.77-9.26l24.63,1.58,0,.18c-.23,0-23-1.46-24.62-1.58s-19.84,2.49-24.6,9.18Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#16a76fc1-6006-4084-b7a4-1e8655cd3e2b)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d:
              "M541.21,506.88c4.34-8.1,23-9.92,24.71-9.85l21.78,1v.18l-21.78-1c-1.81-.08-22.3,1.93-25.09,11.1l-.18-.05A8,8,0,0,1,541.21,506.88Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#906200ce-c2e1-4e69-aca6-6b0c300e8d97)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d:
              "M585,508.22l7.37-46.52,2,.38L587,508.24C586.31,508.24,585.63,508.24,585,508.22Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#09ae5dbf-04ae-456f-b606-b78be7f557d4)"
          }
        })
      ]),
      _c("path", {
        attrs: {
          d:
            "M585.39,509.23l7.69-48.48,9.43,3,6.9,2.2s4.82-1,6.36,2.51-3.37,13.76-1,16,22.4,4,22.4,4,29.1,7.75,32.14,8.21S689,506.84,689,506.84c.91,2.29-10.27,2.39-12.77,3.07s-26.36-3.81-27.91-5.18-23.75-7.36-23.75-7.36c-7.15-3.7-11.74-2.15-11.74-2.15a55.09,55.09,0,0,1-3.45,14,3.8,3.8,0,0,1-4,2.17l-10.78-.85Z",
          transform: "translate(-21.14 -37.01)",
          fill: "url(#ca582b94-4b90-4460-aa10-f094c60b8e01)"
        }
      }),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d:
              "M585.39,509.23l7.69-48.48,9.43,3C601,468.44,592,498,594.6,510.51Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#d72adaae-db48-4891-8751-e69fb2eb4adc)"
          }
        })
      ]),
      _c("path", {
        attrs: {
          d:
            "M545.41,505.14l-4.68,3.16c0-2.21,8.23-11,8.23-11-2.13-1.2-9.67,4.68-9.67,4.68.89-3.85,7.55-8.51,7.55-8.51-.58-.3-5.72,2.3-5.72,2.3,1.68-3.55,8.61-8.69,8.61-8.69-.86-.55-5.93,1.64-6.17,1.74.27-.19,8.71-6.11,8-6.29s-7.14,1.15-7.14,1.15c-.5-2.26,10-12.91,10-12.91s1.31-1.85.84-1.36-5.34,2.18-5.34,2.18c.07-3.33,10.22-8.49,10.22-8.49l-4.62-.18,3-3.27c-.14.11-4.9,3.76-5,2.69s4.48-5.37,5.46-6,4-.12,4-.12-1.32-3.81-1.57-4.36c.73.6,11.09,5,11.09,5l.9.17c-1,.76-1.92,1.45-2.53,2a7.37,7.37,0,0,0-2.21,2.7,5.48,5.48,0,0,0,.69,4.5c.77,1.37,1.83,2.56,2.59,3.94a2.66,2.66,0,0,1,.42,1.94,3.34,3.34,0,0,1-1.31,1.51,25.37,25.37,0,0,0-8.32,11.5,3.1,3.1,0,0,0-.25,1.4,4.67,4.67,0,0,0,.82,1.75,6.31,6.31,0,0,1-.81,7.27c-.82.9-2,1.69-2.06,2.9a3.9,3.9,0,0,0,.6,1.92q1.23,2.29,2.44,4.61c-2.32-.45-4.21-.84-5.14-1.05-3.71-.87-8.34,2-9.4,2s-7.44,5.3-7.44,5.3C541.66,510.15,545.41,505.14,545.41,505.14Z",
          transform: "translate(-21.14 -37.01)",
          fill: "url(#a1584201-eed1-4945-ad33-134cef0a8100)"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M702.23,777.41l4.43,17.75s99.48,32.16,131.73,2-3.55-88.78-3.55-88.78L813,685.94l-57.61-6.7H735Z",
          transform: "translate(-21.14 -37.01)",
          fill: "url(#c1f48460-d486-42d8-b725-65643a87f10e)"
        }
      }),
      _c("g", { attrs: { opacity: "0.03" } }, [
        _c("path", {
          attrs: {
            d:
              "M702.23,777.41l4.43,17.75s99.48,32.16,131.73,2-3.55-88.78-3.55-88.78L813,685.94l-57.61-6.7H735Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#c1f48460-d486-42d8-b725-65643a87f10e)"
          }
        })
      ]),
      _c("path", {
        attrs: {
          d:
            "M943.93,648.08,934.37,659l-33.06,59.14-11.25,20.77s-8.5,10.58-7.66,15,3.23,37.27-25.4,39.94-90.69-64.33-90.69-64.33l2.53-1.7,55.43-37.49,19.9-21.42,5.32-5.72s25.92-34.84,25.23-40.2,25.91-37.52,41.25-36.52,26.93,1,32.38,20.44S943.93,648.08,943.93,648.08Z",
          transform: "translate(-21.14 -37.01)",
          fill: "url(#d12d6e01-7b31-4c3b-a267-ba8df3c8502a)"
        }
      }),
      _c("rect", {
        attrs: {
          x: "724.47",
          y: "384.85",
          width: "105.08",
          height: "78.14",
          fill: "url(#c0a593ea-604f-4b79-bf7e-88ef80098439)"
        }
      }),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("rect", {
          attrs: {
            x: "724.47",
            y: "384.85",
            width: "105.08",
            height: "78.14",
            fill: "url(#c0a593ea-604f-4b79-bf7e-88ef80098439)"
          }
        })
      ]),
      _c("path", {
        attrs: {
          d:
            "M716.89,584.62c0,1.43-.13,3.39-.34,5.6-.79,8.6-2.73,21-2.73,21s-4.09,21.83-3.07,23.61-1,16.33-2.39,19.53-9.54,0-9.54,0l-27-71.22-1.49-3.93-.59-1.55.62-2.38,2.19-8.63S716.89,577.68,716.89,584.62Z",
          transform: "translate(-21.14 -37.01)",
          fill: "url(#59f0f69e-0789-4b50-8eee-3503c8852587)"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M840.29,514.19v11.26l-8.19,39.33-17.72,23.36-39.2,5-37.5-14.47S712.8,539.21,741.77,517a54.69,54.69,0,0,0,16.6-20.75,66.44,66.44,0,0,0,5.74-26.67,61,61,0,0,0-.86-11.8s61.36-27.8,51.47,0a31,31,0,0,0-1.39,14.95,46.05,46.05,0,0,0,4.91,14.66C826.16,502.78,840.29,514.19,840.29,514.19Z",
          transform: "translate(-21.14 -37.01)",
          fill: "url(#ecbbec08-52f5-486e-baee-0d22d5e5f35b)"
        }
      }),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d:
              "M814.72,457.75a31,31,0,0,0-1.39,14.95,41.86,41.86,0,0,1-49.22-3.15,61,61,0,0,0-.86-11.8S824.61,430,814.72,457.75Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#94cd213e-49ee-4606-a8ea-9126ef4159fc)"
          }
        })
      ]),
      _c("ellipse", {
        attrs: {
          cx: "769.72",
          cy: "399.68",
          rx: "41.25",
          ry: "40.54",
          fill: "url(#b4c2834c-08de-4c0f-a497-47f56f129203)"
        }
      }),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d:
              "M840.29,514.19v11.26l-8.19,39.33-17.72,23.36-39.2,5-37.5-14.47S712.8,539.21,741.77,517a54.69,54.69,0,0,0,16.6-20.75C735.71,581.49,784,577.07,784,577.07c44.18,0,35.29-81,34.22-89.71C826.16,502.78,840.29,514.19,840.29,514.19Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#6fc82309-225f-4073-ba7e-a386c8e0d1cc)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d:
              "M877.79,552.27c-1.71,5.7-5.8,26.46-5.8,26.46s-1.15.45-3,1.28c-7,3.19-24.31,12-25.59,23a4.1,4.1,0,0,0-.06.49c-1,14.07-13.63,43.89-20.1,57.29s3.07,27.81,3.07,27.81l-13,3S795.29,694,780,690.3s-13.3,6-22.84,2.68-40.91-5.7-46-8.71-9.54-29.81-6.82-33.17,11.25-74,11.25-74-34.43-31.5-33.75-51.94,25.57-21.43,25.57-21.43,9.57-3.3,17.68-6.73c1.57-.66,3.1-1.34,4.48-2,6.73-3.17,21.88-1.79,28-1.06,1.6.2,2.58.34,2.58.34-.88,3-1.67,5.94-2.39,8.75-13.91,54.89,2.42,71.3,14.87,76.14A28,28,0,0,0,784,581.09c15.66,0,24.65-10.19,29.69-23.72,10-26.81,4.42-66.74,4.42-66.74s31.71,12.72,52.16,25.46S879.5,546.57,877.79,552.27Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#e46a40f0-a414-4ea9-87ff-b37ea4c29189)"
          }
        })
      ]),
      _c("path", {
        attrs: {
          d:
            "M877.79,550.26c-1.71,5.7-5.8,26.46-5.8,26.46s-1.15.45-3,1.28c-7,3.19-24.31,12-25.59,23a4.1,4.1,0,0,0-.06.49c-1,14.07-13.63,43.89-20.1,57.29s3.07,27.81,3.07,27.81l-13,3S795.29,692,780,688.29s-13.3,6-22.84,2.68-40.91-5.7-46-8.71-9.54-29.81-6.82-33.17,11.25-74,11.25-74-34.43-31.5-33.75-51.94,25.57-21.43,25.57-21.43,9.57-3.3,17.68-6.73c1.57-.66,3.1-1.34,4.48-2,6.73-3.17,21.88-1.79,28-1.06,1.6.2,2.58.34,2.58.34-.88,3-1.67,5.94-2.39,8.75-13.91,54.89,2.42,71.3,14.87,76.14A28,28,0,0,0,784,579.08c15.66,0,24.65-10.19,29.69-23.72,10-26.81,4.42-66.74,4.42-66.74s31.71,12.72,52.16,25.46S879.5,544.56,877.79,550.26Z",
          transform: "translate(-21.14 -37.01)",
          fill: "url(#e3f1ace3-4a96-46e0-b33c-8544e3cf838f)"
        }
      }),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d:
              "M716.89,584.62v6l-.34-.42c-14.39-17.92-37.7-10-44.72-7.06-1.33.56-2.08.94-2.08.94s.4-2.3.59-4.87a27.27,27.27,0,0,0,0-3.93l2.19-8.63S716.89,577.68,716.89,584.62Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#ea07025c-0e83-46b8-8f52-6c3207ad5db6)"
          }
        })
      ]),
      _c("path", {
        attrs: {
          d:
            "M692.34,521.11l-8.88,11.4s-15,35.84-13.62,38.52-.09,11.06-.09,11.06,30.09-15.38,47.13,6.55V577.4s1.71-4.69,2.39-6,0-11.73,0-11.73Z",
          transform: "translate(-21.14 -37.01)",
          fill: "url(#07131257-a2d2-43b2-a496-09592b21e720)"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M708.36,654.46a5.34,5.34,0,0,1-.35,1.11c-4.35,9.71-36.91-4.47-36.91-4.47s-.27-1.34-.7-3.51c-1.72-8.73-5.81-31.06-3.62-35.35,2.72-5.36,3-22.11,3-22.11,9.12-16.08,4.52-48.58,4.52-48.58,4.09-7-2.38-13.4-2.38-13.4s-56.6-32.84-17.73-37.19,46.37,41.86,43.3,46.91,2.38,45.82,2.38,45.82l5.45,31.57S710.71,642.61,708.36,654.46Z",
          transform: "translate(-21.14 -37.01)",
          fill: "url(#68826aad-586b-4b89-9b23-6b3f1523aa46)"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "851.15 775.46 841.03 774.96 801.68 773.06 801.63 771.83 800.79 751.84 830.67 736.81 830.71 736.79 831.09 737.06 831.86 737.59 832.69 738.17 849.76 750.14 851.15 775.46",
          fill: "url(#63604195-5fe1-4fe1-9a46-195dbf7de3df)"
        }
      }),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("polygon", {
          attrs: {
            points:
              "832.79 738.06 832.69 738.17 801.63 771.83 800.79 751.84 830.67 736.81 831.09 737.06 832.79 738.06",
            fill: "url(#8ab28d86-2676-4cef-81e6-8417288a6835)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d:
              "M708,655.57c-4.35,9.71-36.91-4.47-36.91-4.47s-.27-1.34-.7-3.51c4.34-1.79,9.87-2.16,17.17-.17C697,650,703.56,652.94,708,655.57Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#fcf262f3-a42e-42c8-9ced-d7a52a7c5ba6)"
          }
        })
      ]),
      _c("path", {
        attrs: {
          d:
            "M706.66,795.16s-19.09-9.38-20.11-14.4-6.48-20.78-8.87-24.13-17-26.13-20.45-35.84-13.64-37.19-6.14-47.58,11.93-30.48,36.48-23.78,29.31,15.74,29.31,15.74l2.69,5.7s59.36,54.21,60.38,55.92,32.73,25.15,32.73,25.15l39.2,23.12-33.07,35.85s-41.93-16.08-43.29-22.78-12.88-12.73-12.88-12.73l-15.76-6Z",
          transform: "translate(-21.14 -37.01)",
          fill: "url(#76592684-a275-48c6-834d-813e774799b7)"
        }
      }),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d:
              "M666.77,715.09s7.16,7,7.16,9.72,9.88,7.37,9.88,7.37,78.41,47.87,87.62,47.72l16.39,16.27,23.09-47L782,726.79l-60.38-53.24s-11.21-7.37-17.35-8.38-37.5-15.07-40.22,12.06S666.77,715.09,666.77,715.09Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#9a87b11e-0e93-49fd-90c4-230c3356c4c7)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d:
              "M664.73,717.1s7.16,7,7.16,9.72,9.87,7.37,9.87,7.37,78.42,47.87,87.62,47.72l16.39,16.27,23.1-47L780,728.8l-60.38-53.24s-11.21-7.37-17.34-8.38-37.5-15.07-40.23,12.06S664.73,717.1,664.73,717.1Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#d1cc038d-1412-4354-9890-63a82c5891a8)"
          }
        })
      ]),
      _c("path", {
        attrs: {
          d:
            "M664.73,715.09s7.16,7,7.16,9.72,9.87,7.37,9.87,7.37,78.42,47.87,87.62,47.72l16.39,16.27,23.1-47L780,726.79l-60.38-53.24s-11.21-7.37-17.34-8.38S664.73,650.1,662,677.23,664.73,715.09,664.73,715.09Z",
          transform: "translate(-21.14 -37.01)",
          fill: "url(#75539bfb-e051-4ab0-89da-ccc2e7793d7a)"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M664.73,715.09s7.16,7,7.16,9.72,9.87,7.37,9.87,7.37,78.42,47.87,87.62,47.72l16.39,16.27,23.1-47L780,726.79l-60.38-53.24s-11.21-7.37-17.34-8.38S664.73,650.1,662,677.23,664.73,715.09,664.73,715.09Z",
          transform: "translate(-21.14 -37.01)",
          fill: "url(#75539bfb-e051-4ab0-89da-ccc2e7793d7a)"
        }
      }),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d:
              "M672.23,735.53s20.6,33.56,31.77,36.54S672.23,735.53,672.23,735.53Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#6c8c047c-fdf2-405d-ae69-93a7955e0e3c)"
          }
        })
      ]),
      _c("polygon", {
        attrs: {
          points:
            "811.31 707.65 808.21 724.85 805.37 740.64 804.15 747.43 777.31 739.06 780.05 724.34 780.2 723.52 781.99 713.93 811.31 707.65",
          fill: "url(#6ebf3550-27dd-4b32-9fdf-076e08994285)"
        }
      }),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d:
              "M826.51,777.65l-1.22,6.79-26.84-8.37,2.74-14.73c3.93-5,14.26-1,14.26-1,14.35-1.68,9.5,14.74,9.5,14.74A5.49,5.49,0,0,1,826.51,777.65Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#9313d757-6aef-4307-8c32-3c7374438594)"
          }
        })
      ]),
      _c("path", {
        attrs: {
          d:
            "M829.38,833.36C822.23,827,796.31,824,787.79,824s-5.11-2.67-5.11-2.67l-5.64-4c-3.85-2.75-2.08-9.24-.58-13.08.71-1.79,1.35-3,1.35-3s2.14-15.75,4.27-20.45S799.77,769,799.77,769c-1.32-14.12,15.68-7.65,15.68-7.65,14.35-1.67,9.5,14.75,9.5,14.75,2.73,2,3.41,12.72,2.73,16.41s2.38,15.41,2.38,15.41,2.87,10.6,4.61,16.79c.34,1.19.63,2.23.85,3C836.88,832.35,829.38,833.36,829.38,833.36Z",
          transform: "translate(-21.14 -37.01)",
          fill: "url(#53df1f91-1770-48b7-9bef-bf6bf01c3f97)"
        }
      }),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d:
              "M829.38,833.36C822.23,827,796.31,824,787.79,824s-5.11-2.67-5.11-2.67l-5.64-4c-3.85-2.75-2.08-9.24-.58-13.08l7.63,6.7s-2.18,1.68,0,1.68H793s28.64,6,41.64,12.1c.34,1.19.63,2.23.85,3C836.88,832.35,829.38,833.36,829.38,833.36Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#72bd7345-a516-48e4-9dc7-291477333a62)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("ellipse", {
          attrs: {
            cx: "777.31",
            cy: "738.14",
            rx: "1.37",
            ry: "1.35",
            fill: "url(#123e6431-808c-4338-886b-86770c5413c7)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("ellipse", {
          attrs: {
            cx: "780.05",
            cy: "748.78",
            rx: "1.37",
            ry: "1.35",
            fill: "url(#57ab2043-46d4-4ed5-bbe3-05e270c6b49e)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("ellipse", {
          attrs: {
            cx: "779.31",
            cy: "744.83",
            rx: "1.37",
            ry: "1.35",
            fill: "url(#8086d19d-7628-4b81-b869-f6078bbb2fc8)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("ellipse", {
          attrs: {
            cx: "780.05",
            cy: "754.09",
            rx: "1.37",
            ry: "1.35",
            fill: "url(#71fb1645-291f-4eb7-8225-b6ca42f6896b)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("ellipse", {
          attrs: {
            cx: "773.46",
            cy: "759.59",
            rx: "1.37",
            ry: "1.35",
            fill: "url(#ee711ff6-6027-438c-807c-c83c40cf1416)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d:
              "M872.29,812.46,862.17,812a3.5,3.5,0,0,1,0-1.9s7.42-7-4.86-20.32c0,0,.71-3.13,3.55-2.33,0,0-6.74-11.14-7.76-12.28a.89.89,0,0,1-.12-.54l.83.57,17.07,12Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#3b0c90e8-3e2e-494c-abf4-d76076270d29)"
          }
        })
      ]),
      _c("path", {
        attrs: {
          d:
            "M959.35,787.15a21.28,21.28,0,0,1-2.63,11,21.93,21.93,0,0,1-4.79,6.08l-.09,0a75.3,75.3,0,0,1-31.51,5.59c-13.94-.53-31.6-.48-37.55,2.63,0,0-20.59,5.65-18.54-2.39,0,0,7.41-7-4.86-20.32,0,0,.71-3.13,3.55-2.33,0,0-6.74-11.14-7.76-12.28-.57-.62,1-5,2.44-8.82.43-1.12.86-2.19,1.24-3.11.67-1.63,1.16-2.77,1.16-2.77.23.13,1.43.84,3.19,1.77,3.31,1.72,8.62,4.16,13.44,4.89,7.41,1.14,13.81,3.52,25.57,0s16.87-8.37,32.85-2.55S959,776.4,959.35,787.15Z",
          transform: "translate(-21.14 -37.01)",
          fill: "url(#aff462f7-b22a-4aee-b069-6ff16a23c41a)"
        }
      }),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d:
              "M959.35,787.15a21.28,21.28,0,0,1-2.63,11c-2.86-11.84-6.53-19.11-18.85-28.25-13.55-10-34.51.88-34.51.88s-17,.75-29.41.75a27.33,27.33,0,0,1-16.34-5.18,17.87,17.87,0,0,1-2.57-2.23l3.81-.88,4.35-1c3.31,1.72,8.62,4.16,13.44,4.89,7.41,1.14,13.81,3.52,25.57,0s16.87-8.37,32.85-2.55S959,776.4,959.35,787.15Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#b8765757-b253-4c78-b455-89dba0362a09)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d:
              "M886.31,611.9s-9.2,13.74-8.86,17.76-7.5,12.06-7.5,12.06-8.52,9-8.52,12.73-15.69,35.71-15.69,35.71S825,709.4,820.52,712.41s-15.34,23.45-19.77,26.13-4.35,22-4.35,22l31.28-32.12s34.77,34.6,36.81,35.27,8.18-28.14,8.18-28.14,19.1-18,24.55-22.91,26.52-38.29,26.52-38.29S942.9,639,941.2,628.66,923.3,624.47,886.31,611.9Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#baa2132a-640c-4392-8c03-f8a3a934dd2f)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d:
              "M889.38,611.9s-9.21,13.74-8.86,17.76-7.5,12.06-7.5,12.06-8.53,9-8.53,12.73-15.68,35.71-15.68,35.71S828,709.4,823.59,712.41s-15.34,23.45-19.78,26.13-4.34,22-4.34,22,35.93-23,49.34,29.28h0l12.27-17.56s4.44-9.24,6.48-8.57,8.18-28.14,8.18-28.14,19.09-18,24.55-22.91,26.52-38.29,26.52-38.29S946,639,944.26,628.66,922.62,621,889.38,611.9Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#c377a887-7ef8-4568-81cb-14860a038b29)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d:
              "M832.45,744.66l-3.1,17.2c-10.56-7.19-22.13-3.89-28-1.33l1.79-9.59Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#f794111e-fa4d-4866-a118-7d6bc0a4d194)"
          }
        })
      ]),
      _c("path", {
        attrs: {
          d:
            "M888.36,611.9s-9.21,13.74-8.87,17.76-7.5,12.06-7.5,12.06-8.52,9-8.52,12.73-15.68,35.71-15.68,35.71S827,709.4,822.56,712.41s-15.34,23.45-19.77,26.13-4.34,22-4.34,22,35.93-23,49.34,29.28h0l12.27-17.56s4.43-9.24,6.48-8.57,8.18-28.14,8.18-28.14,19.09-18,24.54-22.91,26.53-38.29,26.53-38.29S945,639,943.24,628.66,925.85,587.45,888.36,611.9Z",
          transform: "translate(-21.14 -37.01)",
          fill: "url(#5ab4824a-bd9f-435a-8648-4ff84520ee27)"
        }
      }),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d: "M729.84,645.41s50.56-18.78,79,6Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#47edc419-4189-48da-a88f-6f02fec89647)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d:
              "M735.05,657.71s40.47,6.79,55.81,5.45S735.05,657.71,735.05,657.71Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#db05c772-84d4-4714-a206-28a51ba64cf9)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d:
              "M723.36,504.12s-3.18,20.68,4.38,25.7S723.36,504.12,723.36,504.12Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#2aa969ef-e70a-47e6-8fc0-9a06eccf5825)"
          }
        })
      ]),
      _c("path", {
        attrs: {
          d: "M702.23,512.74s10.58,16.75,11.6,25.46",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1",
          fill: "url(#b8dd2f66-99a5-4fb7-b84d-72a384286528)"
        }
      }),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d: "M731.2,726.79S769.38,755,766.31,768s-2.27,0-2.27,0Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#64ce91db-faed-4a41-aed5-eaaad768e17f)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("ellipse", {
          attrs: {
            cx: "896.31",
            cy: "742.13",
            rx: "1.37",
            ry: "1.35",
            fill: "url(#198c7f00-a40e-4c94-b12d-cdc61db2a37b)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("ellipse", {
          attrs: {
            cx: "901.77",
            cy: "770.31",
            rx: "1.37",
            ry: "1.35",
            fill: "url(#7f6423fb-d328-484b-940e-cabe8a476a35)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d:
              "M903.71,660.64s-24.79,23.63-24.68,35-1.37,26.13-1.37,26.13l8.29-30.1Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#442f9016-39e4-48b4-97d9-31ee76430526)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d:
              "M905.83,637.37S862.3,671.87,866,675.56,905.83,637.37,905.83,637.37Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#d53aa4f0-6411-425a-9ac7-2a6e83836793)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d:
              "M838.39,740.55s20.93,9.57,19.77,13.33S838.39,740.55,838.39,740.55Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#8ccaeed1-d6b7-4ab3-9782-a68e9ca8520e)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("ellipse", {
          attrs: {
            cx: "864.81",
            cy: "748.78",
            rx: "1.37",
            ry: "1.35",
            fill: "url(#48d1bb63-b435-447e-947d-37e1e2a67403)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("ellipse", {
          attrs: {
            cx: "846.19",
            cy: "746.18",
            rx: "1.37",
            ry: "1.35",
            fill: "url(#35ff77dd-c62d-4bef-a1ca-8b20def25ace)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("ellipse", {
          attrs: {
            cx: "881.19",
            cy: "748.25",
            rx: "1.37",
            ry: "1.35",
            fill: "url(#8f36819d-fb09-4332-9b6d-a6ac139f1f7f)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("ellipse", {
          attrs: {
            cx: "886.06",
            cy: "768.96",
            rx: "1.37",
            ry: "1.35",
            fill: "url(#adf438c9-62c7-42a4-b242-177369b28fd2)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("ellipse", {
          attrs: {
            cx: "889.38",
            cy: "745.55",
            rx: "1.37",
            ry: "1.35",
            fill: "url(#d8c5bce7-43b1-49db-9994-a75dcb1ba9e5)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("ellipse", {
          attrs: {
            cx: "893.72",
            cy: "768.96",
            rx: "1.37",
            ry: "1.35",
            fill: "url(#0b11f5a3-8ae9-4ec0-b0e4-8f8f835d5b28)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("ellipse", {
          attrs: {
            cx: "872.64",
            cy: "749.6",
            rx: "1.37",
            ry: "1.35",
            fill: "url(#961bbb9f-1bc2-4dd1-9e59-496da606e16c)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("ellipse", {
          attrs: {
            cx: "876.21",
            cy: "770.31",
            rx: "1.37",
            ry: "1.35",
            fill: "url(#e2a35fe1-bf7e-4cc5-80cf-cd5536c1bdb9)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("ellipse", {
          attrs: {
            cx: "856.52",
            cy: "748.25",
            rx: "1.37",
            ry: "1.35",
            fill: "url(#fad86b09-880e-427b-8f95-010953ce79f8)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d:
              "M844.17,668.88c-.34,1.84-.47,3-.47,3s-19.09,29.82-32.39,33.84c0,0-30.14,21.83-42.47,22.08l55.43-37.49Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#58b1f8cb-3444-47b9-aa9a-29767dc9198a)"
          }
        })
      ]),
      _c("path", {
        attrs: {
          d:
            "M866.54,588.63l-2.12,10.22s-.12.68-.36,1.88c-1.59,8.28-8.19,41.29-13.55,48-6.13,7.71-7.83,22.11-7.83,22.11s-19.09,29.82-32.39,33.84c0,0-51.82,37.52-47.38,14.73s34.42-31.82,34.42-31.82,4.09-7.71,4.09-9.38,8.87-12.06,8.87-12.06,7.84-14.08,8.18-17.09,14-58.3,14-58.3l8.07-.51Z",
          transform: "translate(-21.14 -37.01)",
          fill: "url(#95c7ab8c-e4a2-4a0b-9c5d-253bb396c6a9)"
        }
      }),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d:
              "M866.54,588.63l-2.12,10.22s-.12.68-.36,1.88c-10.4,1.86-27.61,4.19-30-.22-1.1-2,1.88-5.86,6.48-10.23Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#0689681d-3e16-4713-89c6-79a6df0c2037)"
          }
        })
      ]),
      _c("path", {
        attrs: {
          d:
            "M872,597.17S854.74,601,843.41,601q-.85,0-1.65,0c-3.88-.15-6.82-.86-7.72-2.5-1.12-2,2-6,6.71-10.44,6.27-5.91,15.42-12.7,21.47-17,3.74-2.66,6.29-4.38,6.29-4.38L872,576.72s-.38,1.79-.79,4.33C870.32,586.55,869.34,595.56,872,597.17Z",
          transform: "translate(-21.14 -37.01)",
          fill: "url(#3429680b-e53c-49fb-8152-45f434bfc0c1)"
        }
      }),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d:
              "M765.8,551.93q5.06,12,10.37,23.92a14.78,14.78,0,0,1-3.66,1.29c-6.28,1.32-13.29-1.25-19.44-3.85-9.2-3.88-19.19-8.5-23.35-17.45-3.07-6.61-2.27-14.26-3.45-21.43-1-6.19-3.57-12.12-4-18.37-.55-7.1,1.58-14.06,2.8-21.08,1.57-.66,3.1-1.34,4.48-2,6.73-3.17,21.88-1.79,28-1.06a24.36,24.36,0,0,0-.35,4.36,25,25,0,0,0,.54,4.73c1.52,7.62,5.8,14.85,5.82,22.67,0,4.85-1.64,9.61-1.51,14.47S763.94,547.5,765.8,551.93Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#fa4b69ab-4044-466c-a52e-1fc11ccc4e0c)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d:
              "M877.79,550.26c-1.71,5.7-5.8,26.46-5.8,26.46s-.38,1.79-.79,4.33c-.75-1-1.47-2-2.2-3-.47-.67-1-1.35-1.45-2a19.18,19.18,0,0,0-5.33-5,9.39,9.39,0,0,0-5-1.4c-2.86.08-5.43,1.65-8,2.89-10.33,5.05-24.68,4.57-31.46-4.61a22.41,22.41,0,0,1-4-12.57c10-26.81,4.42-66.74,4.42-66.74s31.71,12.72,52.16,25.46S879.5,544.56,877.79,550.26Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#30ad1975-f5dc-4274-bb3c-b9c4f20b0fb0)"
          }
        })
      ]),
      _c("path", {
        attrs: {
          d:
            "M774.16,410.87c-1.27,3.85,0,8-.37,12.07-.9,9.49-10.58,16.11-12.77,25.39-1.69,7.11,1.29,14.48,1,21.77-.38,8.22-5,15.92-4.87,24.16.12,9.41,6.33,18,6.36,27.39,0,4.86-1.65,9.62-1.51,14.48s2,9.36,3.85,13.79q5.06,12,10.38,23.92c-7.12,3.64-15.73.55-23.11-2.56-9.21-3.89-19.19-8.49-23.35-17.45-3.06-6.62-2.26-14.26-3.45-21.43-1-6.19-3.57-12.12-4-18.38-.56-7.27,1.69-14.42,2.89-21.62a75,75,0,0,0-2.34-34.26c-2-6.43-4.85-12.63-6.12-19.24s-.71-14,3.54-19.24c4.62-5.72,12.46-7.77,18.27-12.32,6.17-4.84,9.7-12.23,11.94-19.67,1.9-6.33,3.6-13.67,9.49-16.83a15.58,15.58,0,0,1,7.77-1.6c9,.21,17.37,5.26,26.4,5.81,5.15.32,10.26-.85,15.38-1.43,12.69-1.46,26,.77,36.81,7.48s18.75,18.14,19.9,30.64c.68,7.5-1,15.07-.28,22.56a35.83,35.83,0,0,0,10,21.47c3.56,3.64,7.94,6.58,10.69,10.85a28.56,28.56,0,0,1,3.78,11.51c4.41,30.65-12.89,61-10,91.86.46,4.93,2,10.56,6.63,12.54-2.3,3.5-7.85,3.32-11.33,1s-5.58-6.18-8.12-9.5-6.13-6.47-10.36-6.35c-2.86.08-5.43,1.64-8,2.9-10.33,5-24.68,4.55-31.47-4.62-5.12-6.92-4.44-16.36-3.08-24.81s3.13-17.47-.38-25.31c-2.62-5.87-8.15-11.13-7.21-17.47.71-4.85,5-8.27,8.15-12.07,6-7.26,8.23-17,8-26.32s-2.63-18.5-5-27.54c-2-7.35-4-14.94-8.93-20.81a19.68,19.68,0,0,0-19.3-6.93c-3,.53-5.84-.76-9,.11A9.88,9.88,0,0,0,774.16,410.87Z",
          transform: "translate(-21.14 -37.01)",
          fill: "url(#d36ad3bd-8d93-4981-9b38-7e68914f4feb)"
        }
      }),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d:
              "M865.76,427.4c.19-4.64.7-9.3.49-13.93C866.06,418.11,865.54,422.77,865.76,427.4Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#e62193dc-90cc-4716-baf9-ed9aa01c58ec)"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M760.62,450.76a18.65,18.65,0,0,1,.4-2.43c2.2-9.28,11.87-15.9,12.77-25.39a28,28,0,0,0-.06-4.67c-1.1,9.3-10.54,15.87-12.71,25A23.89,23.89,0,0,0,760.62,450.76Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#0ded85c0-3985-406f-9981-c8862c085a5b)"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M726.06,477.69a75,75,0,0,0-3.29-24.58c-2-6.43-4.85-12.62-6.12-19.23a34.43,34.43,0,0,1-.48-3.53,31,31,0,0,0,.48,8.55c1.27,6.61,4.14,12.81,6.12,19.24A75.08,75.08,0,0,1,726.06,477.69Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#207473ec-285f-4cb0-900d-401e69e367a5)"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M762.09,533c.38-3.78,1.38-7.52,1.37-11.33a21.48,21.48,0,0,0-.15-2.4c-.39,4-1.47,7.87-1.36,11.85C762,531.73,762,532.36,762.09,533Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#775de71c-7a44-4518-aeac-97c00c9966eb)"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M757.25,491.42c.76-7.18,4.38-14.05,4.72-21.32a29.42,29.42,0,0,0-.09-3.87c-.72,7.8-4.88,15.15-4.78,23C757.11,490,757.17,490.7,757.25,491.42Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#aedcd206-4f7b-48e9-8781-e8202b836b94)"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M883.62,579.88a10,10,0,0,1-8-1.42c-3.48-2.36-5.58-6.17-8.12-9.5s-6.13-6.46-10.36-6.34c-2.86.08-5.43,1.64-8,2.89-10.34,5-24.69,4.56-31.48-4.61a22.18,22.18,0,0,1-4-12c-.34,6.11.4,12.19,4,17,6.79,9.17,21.14,9.66,31.48,4.62,2.56-1.26,5.13-2.82,8-2.9,4.23-.12,7.82,3,10.36,6.35s4.64,7.14,8.12,9.5,9,2.54,11.33-1A8.52,8.52,0,0,1,883.62,579.88Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#c1bb3b15-f111-4b8d-9469-94d017edeb6b)"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M880.09,560.24c.74-24.83,11.73-49.42,11-74.13C890.33,510.8,879.34,535.39,880.09,560.24Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#23edbfb8-e6e1-43b9-b8d7-28fd916cff92)"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M816.23,524.13a28.1,28.1,0,0,0-2-13.36c-2.06-4.59-5.88-8.82-7-13.49a8.88,8.88,0,0,0-.22,1.05c-.93,6.34,4.59,11.6,7.22,17.47A24.37,24.37,0,0,1,816.23,524.13Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#2eb4d4fb-05bb-4375-a02c-9d70a5391a04)"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M753.07,566.25c-9.2-3.88-19.19-8.49-23.35-17.45-3.06-6.61-2.26-14.25-3.45-21.43-1-6.15-3.53-12-4-18.26a33.53,33.53,0,0,0,0,4.91c.48,6.26,3,12.19,4,18.38,1.19,7.17.39,14.81,3.45,21.43,4.16,9,14.15,13.56,23.35,17.45,7.38,3.11,16,6.2,23.11,2.56q-.95-2.13-1.87-4.25C767.58,571.91,759.81,569.1,753.07,566.25Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#c891de9a-5269-4998-b035-f98334afd135)"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M809.27,411.59c4.9,5.87,7,13.46,8.93,20.81,2.3,8.66,4.6,17.41,5,26.34.06-1.28.09-2.55.06-3.82-.2-9.35-2.62-18.5-5-27.55-2-7.34-4-14.93-8.93-20.8a19.69,19.69,0,0,0-19.3-6.94c-3,.54-5.84-.75-9,.12a9.88,9.88,0,0,0-6.84,6.1,18.37,18.37,0,0,0-.45,7.06,11.29,11.29,0,0,1,.45-2,9.9,9.9,0,0,1,6.84-6.1c3.13-.87,6,.42,9-.11A19.68,19.68,0,0,1,809.27,411.59Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#5aa79ae1-bc14-4465-90c2-b1c4a3b8ecc3)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d:
              "M839.41,581.08s15.88,41.55-20.94,58.3c0,0-66-7.33-86.24-2s-20.88-39.54-11.63-42.22S839.41,581.08,839.41,581.08Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#143adfe7-e64a-4465-9788-e6626a22f82c)"
          }
        })
      ]),
      _c("path", {
        attrs: {
          d:
            "M838.39,579.07s15.88,41.55-20.94,58.3c0,0-66-7.33-86.25-2s-20.87-39.54-11.63-42.22S838.39,579.07,838.39,579.07Z",
          transform: "translate(-21.14 -37.01)",
          fill: "url(#2ffe8922-870f-4473-800d-42a3319e36c4)"
        }
      }),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d: "M701.28,592l5.78,33.67S708.51,600.26,701.28,592Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#d3eb8c1d-3143-4526-bc61-fcc74e63e0a2)"
          }
        })
      ]),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d: "M784,552.61s-1.31,15.07-1.93,19.76S786.37,554,784,552.61Z",
            transform: "translate(-21.14 -37.01)",
            fill: "url(#a5ed3db4-94f3-414f-93ab-d65698b876a7)"
          }
        })
      ]),
      _c("path", {
        attrs: {
          d: "M799.77,769s14,24.36,25.18,7.1",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1",
          fill: "url(#401c088d-6620-4ef5-af60-8d16cc8201d0)"
        }
      }),
      _c("path", {
        attrs: {
          d: "M862.93,787.42s34.07,17.41,21.65,24.3",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1",
          fill: "url(#74ac0e64-4c3a-4de0-8f0b-12f809b40d22)"
        }
      }),
      _c("path", {
        attrs: {
          d: "M566.57,453.77a.09.09,0,0,1,0,0S566.55,453.71,566.57,453.77Z",
          transform: "translate(-21.14 -37.01)",
          fill: "#9d9cb5"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M550.86,506.8l-4.58,3.14c0-2.2,8.05-10.94,8.05-10.94-2.09-1.2-9.46,4.65-9.46,4.65.87-3.83,7.39-8.46,7.39-8.46-.58-.3-5.6,2.28-5.6,2.28,1.65-3.52,8.42-8.65,8.42-8.65-.84-.54-5.8,1.64-6,1.74.26-.19,8.51-6.08,7.78-6.26s-7,1.14-7,1.14c-.49-2.24,9.79-12.84,9.79-12.84s1.28-1.84.82-1.36-5.23,2.18-5.23,2.18c.07-3.32,10-8.46,10-8.46l-4.52-.17,3-3.25c-.14.1-4.8,3.73-4.86,2.67s4.38-5.34,5.34-6,3.94-.13,3.94-.13-1.28-3.79-1.53-4.33c.71.59,10.84,4.94,10.84,4.94l.88.17c2.37.48,8.76,1.8,9.76,2.49s5.83,1.65,8.68,2.21l2,.38-7.2,45.92c-.67,0-1.34,0-2,0-1.16,0-2.32-.1-3.48-.2-1.81-.16-3.62-.42-5.42-.76-3.62-.69-8.37-1.6-12-2.32-2.27-.45-4.12-.84-5-1.05-3.63-.86-8.16,2-9.2,2s-7.27,5.28-7.27,5.28C547.19,511.78,550.86,506.8,550.86,506.8Z",
          transform: "translate(-21.14 -37.01)",
          fill: "#e4e3f9"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M550.82,506.71c.07,0,7.11-2.93,9.47-3.86s30.49,1.12,31.69,1.2l0,.19c-.29,0-29.27-2.15-31.6-1.22s-9.4,3.84-9.47,3.86Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d: "M566.57,453.77a.09.09,0,0,1,0,0S566.55,453.71,566.57,453.77Z",
          transform: "translate(-21.14 -37.01)",
          fill: "none"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M558.76,464.14a58.51,58.51,0,0,1,6-4.3c1.61-.9,4.74-.26,7,.21a15.38,15.38,0,0,0,2,.34c1.41,0,9.06,2.21,9.38,2.31.08,0,8.37,2,9.42,1.93a31.23,31.23,0,0,1,4.94.91l0,.18a31.86,31.86,0,0,0-4.89-.91c-1.08.06-9.13-1.85-9.47-1.93-.09,0-8-2.3-9.34-2.31a13.43,13.43,0,0,1-2.06-.34c-2.14-.43-5.36-1.09-6.91-.23a58.6,58.6,0,0,0-6,4.28Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M560.42,464.66c.71-.8,9-1.09,9.77-.8s10.27,1.24,10.77,1.23c.16,0,12.52,2.43,16.3,3.18l0,.18c-5.51-1.09-16-3.16-16.27-3.17-.05,0-10-.9-10.84-1.25s-9,.14-9.56.75Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M555.15,473.37c2.56-4.43,17-5.73,17.1-5.74,1.53-.14,23.42,4.09,24.36,4.27l0,.18c-.23,0-22.8-4.4-24.31-4.27-4,.35-14.87,2.05-17,5.65Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M554.18,478.49c4.06-4.65,17-4.32,17.09-4.32,1,0,22.92,3.34,23.86,3.48l0,.18c-.23,0-22.88-3.43-23.83-3.47-.13,0-12.94-.33-16.95,4.25Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M550.33,483.64c1.53-2.9,5.81-5,12.74-6.16a69.62,69.62,0,0,1,10.14-.93l22.35,2.6,0,.18-22.34-2.59c-.18,0-19.35.15-22.77,7.12l-.17-.08S550.31,483.68,550.33,483.64Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M549.78,485.38c2.14-2.38,10.52-4.28,14.53-4.76,3.77-.46,28.71,3.55,29.77,3.72l0,.18c-.25,0-26-4.17-29.72-3.72s-12.33,2.39-14.41,4.7Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M554.68,486.27l9.45-1.59a24.74,24.74,0,0,1,7.34-.14L582,485.91l11.54,2.22,0,.18-11.53-2.22-10.48-1.36a24.4,24.4,0,0,0-7.29.13l-9.45,1.59Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M546.77,497c2-3.7,10.66-5.54,15.43-6.55,1-.21,1.81-.38,2.28-.52,2.69-.77,27.88,1.81,28.95,1.92l0,.19c-.26,0-26.22-2.69-28.88-1.93-.48.14-1.28.31-2.29.52-4.57,1-14.08,3-15.49,6.89l-.18-.07A4.43,4.43,0,0,1,546.77,497Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M552,495.24c.05,0,4.81-1.54,5.9-2.05s13.55-.82,14.08-.84l21.34,1.33v.19L572,492.54c-.12,0-12.92.32-14,.82s-5.73,2-5.93,2.06Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M544.8,503.6c4.69-6.72,22.61-9.33,24.22-9.22s23.85,1.56,24.07,1.58v.18L569,494.57c-1.59-.12-19.39,2.47-24,9.13Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M546.75,508.53c4.24-8.06,22.47-9.88,24.16-9.8l21.3,1v.18l-21.3-1c-1.77-.09-21.8,1.91-24.53,11l-.18,0A7.53,7.53,0,0,1,546.75,508.53Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M589.52,509.86l7.21-46.28,2,.38-7.2,45.92C590.85,509.88,590.18,509.88,589.52,509.86Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M590,510.87l7.52-48.24,9.22,3,6.74,2.19s4.72-1,6.22,2.5-3.29,13.69-1,15.89,21.9,4,21.9,4S669,497.91,672,498.36s19.23,10.12,19.23,10.12c.89,2.28-10,2.39-12.49,3.06s-25.77-3.79-27.29-5.15-23.22-7.33-23.22-7.33c-7-3.68-11.48-2.13-11.48-2.13a55.25,55.25,0,0,1-3.37,13.89,3.71,3.71,0,0,1-3.91,2.17L599,512.13Z",
          transform: "translate(-21.14 -37.01)",
          fill: "#535461"
        }
      }),
      _c("path", {
        attrs: {
          d: "M590,510.87l7.52-48.24,9.22,3c-1.44,4.65-10.32,34-7.73,46.5Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M550.86,506.8l-4.58,3.14c0-2.2,8.05-10.94,8.05-10.94-2.08-1.2-9.46,4.65-9.46,4.65.87-3.83,7.39-8.46,7.39-8.46-.58-.31-5.6,2.28-5.6,2.28,1.65-3.53,8.42-8.65,8.42-8.65-.84-.54-5.8,1.64-6,1.74.26-.19,8.51-6.08,7.78-6.26s-7,1.14-7,1.14c-.49-2.25,9.79-12.85,9.79-12.85s1.28-1.83.82-1.35-5.23,2.17-5.23,2.17c.07-3.31,10-8.45,10-8.45l-4.51-.17,3-3.25c-.14.1-4.8,3.73-4.86,2.67s4.38-5.34,5.34-6,3.94-.13,3.94-.13-1.28-3.79-1.53-4.33c.71.59,10.84,4.94,10.84,4.94l.88.17c-.93.76-1.87,1.44-2.47,2a7.38,7.38,0,0,0-2.16,2.69,5.54,5.54,0,0,0,.67,4.48c.76,1.36,1.79,2.55,2.54,3.92a2.68,2.68,0,0,1,.41,1.93,3.3,3.3,0,0,1-1.29,1.51,25.19,25.19,0,0,0-8.13,11.44,3.2,3.2,0,0,0-.25,1.39,4.76,4.76,0,0,0,.81,1.74,6.39,6.39,0,0,1-.79,7.24c-.81.89-1.94,1.68-2,2.87a3.71,3.71,0,0,0,.58,1.92c.8,1.52,1.59,3.05,2.39,4.59-2.27-.46-4.12-.84-5-1.05-3.63-.86-8.16,2-9.19,2s-7.28,5.28-7.28,5.28C547.19,511.78,550.86,506.8,550.86,506.8Z",
          transform: "translate(-21.14 -37.01)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M704.19,777.69l4.34,17.66s97.27,32,128.8,2S833.86,709,833.86,709l-21.33-22.33L756.19,680h-20Z",
          transform: "translate(-21.14 -37.01)",
          fill: "#5e52ad"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M704.19,777.69l4.34,17.66s97.27,32,128.8,2S833.86,709,833.86,709l-21.33-22.33L756.19,680h-20Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.03"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M940.53,649l-9.34,10.83-32.33,58.84-11,20.67s-8.31,10.52-7.49,14.92,3.16,37.08-24.84,39.74-88.67-64-88.67-64l2.47-1.7,54.2-37.3L843,669.71l5.2-5.69s25.34-34.66,24.67-40,25.33-37.33,40.33-36.33,26.34,1,31.67,20.33S940.53,649,940.53,649Z",
          transform: "translate(-21.14 -37.01)",
          fill: "#5e52ad"
        }
      }),
      _c("rect", {
        attrs: {
          x: "725.47",
          y: "386.93",
          width: "102.75",
          height: "77.75",
          fill: "#784d55"
        }
      }),
      _c("rect", {
        attrs: {
          x: "725.47",
          y: "386.93",
          width: "102.75",
          height: "77.75",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M718.53,585.88c0,1.42-.13,3.37-.33,5.57-.77,8.55-2.67,20.91-2.67,20.91s-4,21.72-3,23.49-1,16.25-2.34,19.43-9.33,0-9.33,0l-26.39-70.86L673,580.51l-.57-1.54.6-2.37,2.15-8.58S718.53,579,718.53,585.88Z",
          transform: "translate(-21.14 -37.01)",
          fill: "#f8bdc5"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M839.19,515.8V527l-8,39.14-17.33,23.24-38.33,5L738.86,580s-24.33-39.27,4-61.4a54.2,54.2,0,0,0,16.23-20.65,67,67,0,0,0,5.61-26.53,61.53,61.53,0,0,0-.84-11.74s60-27.66,50.33,0a31.43,31.43,0,0,0-1.36,14.87,46.8,46.8,0,0,0,4.8,14.59C825.38,504.45,839.19,515.8,839.19,515.8Z",
          transform: "translate(-21.14 -37.01)",
          fill: "#f8bdc5"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M814.19,459.65a31.43,31.43,0,0,0-1.36,14.87,40.36,40.36,0,0,1-48.13-3.13,61.53,61.53,0,0,0-.84-11.74S823.86,432,814.19,459.65Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("circle", {
        attrs: { cx: "769.72", cy: "401.68", r: "40.33", fill: "#f8bdc5" }
      }),
      _c("path", {
        attrs: {
          d:
            "M839.19,515.8V527l-8,39.14-17.33,23.24-38.33,5L738.86,580s-24.33-39.27,4-61.4a54.2,54.2,0,0,0,16.23-20.65c-22.16,84.84,25.08,80.44,25.08,80.44,43.2,0,34.51-80.63,33.46-89.25C825.38,504.45,839.19,515.8,839.19,515.8Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M875.86,553.69c-1.67,5.67-5.67,26.33-5.67,26.33s-1.12.44-2.92,1.27c-6.9,3.17-23.77,12-25,22.91,0,.16,0,.33,0,.49-1,14-13.33,43.67-19.66,57s3,27.67,3,27.67l-12.67,3s-17.67,2.33-32.67-1.34-13,6-22.33,2.67-40-5.67-45-8.67-9.33-29.66-6.67-33,11-73.66,11-73.66-33.66-31.34-33-51.67,25-21.33,25-21.33,9.36-3.28,17.29-6.69c1.54-.66,3-1.33,4.38-2,6.58-3.16,21.4-1.78,27.33-1.06,1.57.2,2.52.34,2.52.34-.86,3-1.63,5.91-2.33,8.71-13.6,54.61,2.36,70.94,14.54,75.75a26.93,26.93,0,0,0,11.25,1.93c15.31,0,24.11-10.13,29-23.6,9.75-26.67,4.33-66.4,4.33-66.4s31,12.66,51,25.33S877.53,548,875.86,553.69Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M875.86,551.69c-1.67,5.67-5.67,26.33-5.67,26.33s-1.12.44-2.92,1.27c-6.9,3.17-23.77,12-25,22.91,0,.16,0,.33,0,.49-1,14-13.33,43.67-19.66,57s3,27.67,3,27.67l-12.67,3s-17.67,2.33-32.67-1.34-13,6-22.33,2.67-40-5.67-45-8.67-9.33-29.66-6.67-33,11-73.66,11-73.66-33.66-31.34-33-51.67,25-21.33,25-21.33,9.36-3.28,17.29-6.69c1.54-.66,3-1.33,4.38-2,6.58-3.16,21.4-1.78,27.33-1.06,1.57.2,2.52.34,2.52.34-.86,3-1.63,5.91-2.33,8.71-13.6,54.61,2.36,70.94,14.54,75.75a26.93,26.93,0,0,0,11.25,1.93c15.31,0,24.11-10.13,29-23.6,9.75-26.67,4.33-66.4,4.33-66.4s31,12.66,51,25.33S877.53,546,875.86,551.69Z",
          transform: "translate(-21.14 -37.01)",
          fill: "#eb6475"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M718.53,585.88v6l-.33-.42c-14.07-17.83-36.86-10-43.73-7-1.3.56-2,.94-2,.94s.39-2.29.57-4.85a27,27,0,0,0,0-3.91l2.15-8.58S718.53,579,718.53,585.88Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M694.53,522.69,685.84,534s-14.65,35.66-13.31,38.32-.09,11-.09,11,29.42-15.29,46.09,6.52V578.69s1.66-4.67,2.33-6,0-11.67,0-11.67Z",
          transform: "translate(-21.14 -37.01)",
          fill: "#eb6475"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M710.19,655.36a5.33,5.33,0,0,1-.34,1.11c-4.25,9.66-36.09-4.45-36.09-4.45s-.27-1.33-.69-3.49c-1.68-8.69-5.68-30.9-3.54-35.17,2.66-5.34,2.91-22,2.91-22,8.92-16,4.42-48.34,4.42-48.34,4-7-2.33-13.33-2.33-13.33s-55.34-32.67-17.34-37,45.34,41.65,42.34,46.67S701.86,585,701.86,585l5.33,31.41S712.49,643.57,710.19,655.36Z",
          transform: "translate(-21.14 -37.01)",
          fill: "#f8bdc5"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "849.34 775.56 839.45 775.07 800.97 773.18 800.92 771.95 800.1 752.06 829.32 737.11 829.36 737.09 829.73 737.36 830.48 737.89 831.29 738.46 847.98 750.37 849.34 775.56",
          fill: "#f8bdc5"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "831.39 738.35 831.29 738.46 800.92 771.95 800.1 752.06 829.32 737.11 829.73 737.36 831.39 738.35",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M709.85,656.47c-4.25,9.66-36.09-4.45-36.09-4.45s-.27-1.33-.69-3.49c4.25-1.78,9.65-2.15,16.79-.17C699.12,650.93,705.5,653.85,709.85,656.47Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M708.53,795.35S689.86,786,688.86,781s-6.33-20.67-8.67-24-16.66-26-20-35.67-13.33-37-6-47.33,11.67-30.33,35.67-23.67S718.53,666,718.53,666l2.63,5.67s58,53.94,59,55.63,32,25,32,25l38.34,23L818.19,811s-41-16-42.33-22.67-12.6-12.66-12.6-12.66l-15.4-6Z",
          transform: "translate(-21.14 -37.01)",
          fill: "#5e52ad"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M669.53,715.69s7,7,7,9.66,9.65,7.34,9.65,7.34,76.68,47.63,85.68,47.48l16,16.18,22.58-46.81-28.28-22.22-59-53s-11-7.33-17-8.33-36.66-15-39.33,12S669.53,715.69,669.53,715.69Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M667.53,717.69s7,7,7,9.66,9.65,7.34,9.65,7.34,76.68,47.63,85.68,47.48l16,16.18,22.58-46.81-28.28-22.22-59-53s-11-7.33-17-8.33-36.66-15-39.33,12S667.53,717.69,667.53,717.69Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M667.53,715.69s7,7,7,9.66,9.65,7.34,9.65,7.34,76.68,47.63,85.68,47.48l16,16.18,22.58-46.81-28.28-22.22-59-53s-11-7.33-17-8.33-36.66-15-39.33,12S667.53,715.69,667.53,715.69Z",
          transform: "translate(-21.14 -37.01)",
          fill: "#5e52ad"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M667.53,715.69s7,7,7,9.66,9.65,7.34,9.65,7.34,76.68,47.63,85.68,47.48l16,16.18,22.58-46.81-28.28-22.22-59-53s-11-7.33-17-8.33-36.66-15-39.33,12S667.53,715.69,667.53,715.69Z",
          transform: "translate(-21.14 -37.01)",
          fill: "#5e52ad"
        }
      }),
      _c("path", {
        attrs: {
          d: "M674.86,736s20.14,33.39,31.07,36.36S674.86,736,674.86,736Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "810.39 708.1 807.36 725.21 804.58 740.92 803.39 747.68 777.14 739.35 779.82 724.7 779.97 723.89 781.72 714.35 810.39 708.1",
          fill: "#f8bdc5"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M825.72,777.93l-1.19,6.76-26.25-8.33L801,761.71c3.84-4.93,13.94-1,13.94-1,14-1.67,9.29,14.67,9.29,14.67A5.4,5.4,0,0,1,825.72,777.93Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M828.53,833.36c-7-6.34-32.34-9.34-40.67-9.34s-5-2.66-5-2.66l-5.51-4c-3.77-2.73-2-9.19-.57-13,.69-1.78,1.32-3,1.32-3s2.09-15.67,4.18-20.34,17.29-11.72,17.29-11.72c-1.29-14,15.33-7.61,15.33-7.61,14-1.67,9.29,14.67,9.29,14.67,2.67,2,3.34,12.66,2.67,16.33S829.19,808,829.19,808s2.81,10.55,4.51,16.71c.33,1.18.61,2.21.83,3C835.86,832.36,828.53,833.36,828.53,833.36Z",
          transform: "translate(-21.14 -37.01)",
          fill: "#ffc075"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M828.53,833.36c-7-6.34-32.34-9.34-40.67-9.34s-5-2.66-5-2.66l-5.51-4c-3.77-2.73-2-9.19-.57-13l7.46,6.67s-2.13,1.67,0,1.67H793s28,5.93,40.72,12c.33,1.18.61,2.21.83,3C835.86,832.36,828.53,833.36,828.53,833.36Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("circle", {
        attrs: { cx: "777.14", cy: "738.43", r: "1.34", opacity: "0.1" }
      }),
      _c("circle", {
        attrs: { cx: "779.82", cy: "749.02", r: "1.34", opacity: "0.1" }
      }),
      _c("circle", {
        attrs: { cx: "779.1", cy: "745.09", r: "1.34", opacity: "0.1" }
      }),
      _c("circle", {
        attrs: { cx: "779.82", cy: "754.31", r: "1.34", opacity: "0.1" }
      }),
      _c("circle", {
        attrs: { cx: "773.38", cy: "759.78", r: "1.34", opacity: "0.1" }
      }),
      _c("path", {
        attrs: {
          d:
            "M870.48,812.57l-9.89-.49a3.46,3.46,0,0,1,0-1.89s7.25-6.94-4.75-20.22c0,0,.69-3.11,3.47-2.32,0,0-6.59-11.08-7.59-12.21a.89.89,0,0,1-.12-.54l.81.57,16.69,11.91Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M955.61,787.38A21.43,21.43,0,0,1,953,798.3a21.73,21.73,0,0,1-4.68,6.05l-.09,0a72.61,72.61,0,0,1-30.81,5.55c-13.63-.51-30.9-.47-36.72,2.63,0,0-20.13,5.62-18.13-2.38,0,0,7.25-6.94-4.75-20.22,0,0,.69-3.11,3.47-2.32,0,0-6.59-11.08-7.59-12.21-.55-.62.94-5,2.39-8.78.42-1.11.84-2.18,1.21-3.09.66-1.63,1.14-2.76,1.14-2.76.22.13,1.39.84,3.12,1.76,3.23,1.71,8.42,4.14,13.14,4.87,7.24,1.13,13.5,3.5,25,0s16.5-8.33,32.12-2.54S955.24,776.69,955.61,787.38Z",
          transform: "translate(-21.14 -37.01)",
          fill: "#ffc075"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M955.61,787.38A21.43,21.43,0,0,1,953,798.3c-2.8-11.78-6.38-19-18.43-28.11-13.25-10-33.75.88-33.75.88s-16.62.74-28.75.74a26.41,26.41,0,0,1-16-5.15,17.27,17.27,0,0,1-2.51-2.22l3.72-.87,4.26-1c3.23,1.71,8.42,4.14,13.14,4.87,7.24,1.13,13.5,3.5,25,0s16.5-8.33,32.12-2.54S955.24,776.69,955.61,787.38Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M884.19,613s-9,13.67-8.66,17.67-7.34,12-7.34,12-8.33,9-8.33,12.66-15.33,35.53-15.33,35.53S824.19,710,819.86,713s-15,23.33-19.33,26-4.25,21.87-4.25,21.87l30.58-32s34,34.42,36,35.09,8-28,8-28,18.67-17.92,24-22.79,25.93-38.1,25.93-38.1,18.74-35.11,17.07-45.44S920.36,625.52,884.19,613Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M887.19,613s-9,13.67-8.66,17.67-7.34,12-7.34,12-8.33,9-8.33,12.66-15.33,35.53-15.33,35.53S827.19,710,822.86,713s-15,23.33-19.33,26-4.25,21.87-4.25,21.87S834.41,738,847.53,790h0l12-17.48s4.33-9.19,6.33-8.52,8-28,8-28,18.67-17.92,24-22.79,25.93-38.1,25.93-38.1,18.74-35.11,17.07-45.44S919.69,622,887.19,613Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M831.53,745.11l-3,17.11c-10.33-7.15-21.64-3.87-27.39-1.32l1.75-9.54Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M886.19,613s-9,13.67-8.66,17.67-7.34,12-7.34,12-8.33,9-8.33,12.66-15.33,35.53-15.33,35.53S826.19,710,821.86,713s-15,23.33-19.33,26-4.25,21.87-4.25,21.87S833.41,738,846.53,790h0l12-17.48s4.33-9.19,6.33-8.52,8-28,8-28,18.67-17.92,24-22.79,25.93-38.1,25.93-38.1,18.74-35.11,17.07-45.44S922.86,588.69,886.19,613Z",
          transform: "translate(-21.14 -37.01)",
          fill: "#5e52ad"
        }
      }),
      _c("path", {
        attrs: {
          d: "M731.19,646.35s49.44-18.68,77.28,6Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d: "M736.29,658.6s39.57,6.75,54.57,5.42S736.29,658.6,736.29,658.6Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M724.86,505.78s-3.11,20.57,4.28,25.57S724.86,505.78,724.86,505.78Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d: "M704.19,514.35s10.36,16.67,11.35,25.34",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d: "M732.53,727.32s37.33,28,34.33,41-2.22,0-2.22,0Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("circle", {
        attrs: { cx: "893.5", cy: "742.4", r: "1.34", opacity: "0.1" }
      }),
      _c("circle", {
        attrs: { cx: "898.85", cy: "770.44", r: "1.34", opacity: "0.1" }
      }),
      _c("path", {
        attrs: {
          d: "M901.2,661.51S877,685,877.08,696.35s-1.34,26-1.34,26l8.1-30Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d: "M903.28,638.35s-42.56,34.34-39,38S903.28,638.35,903.28,638.35Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d: "M837.33,741s20.47,9.52,19.33,13.26S837.33,741,837.33,741Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("circle", {
        attrs: { cx: "862.71", cy: "749.02", r: "1.34", opacity: "0.1" }
      }),
      _c("circle", {
        attrs: { cx: "844.49", cy: "746.43", r: "1.34", opacity: "0.1" }
      }),
      _c("circle", {
        attrs: { cx: "878.72", cy: "748.49", r: "1.34", opacity: "0.1" }
      }),
      _c("circle", {
        attrs: { cx: "883.48", cy: "769.1", r: "1.34", opacity: "0.1" }
      }),
      _c("circle", {
        attrs: { cx: "886.72", cy: "745.81", r: "1.34", opacity: "0.1" }
      }),
      _c("circle", {
        attrs: { cx: "890.97", cy: "769.1", r: "1.34", opacity: "0.1" }
      }),
      _c("circle", {
        attrs: { cx: "870.35", cy: "749.83", r: "1.34", opacity: "0.1" }
      }),
      _c("circle", {
        attrs: { cx: "873.85", cy: "770.44", r: "1.34", opacity: "0.1" }
      }),
      _c("circle", {
        attrs: { cx: "854.6", cy: "748.49", r: "1.34", opacity: "0.1" }
      }),
      _c("path", {
        attrs: {
          d:
            "M843,669.71c-.33,1.83-.46,3-.46,3s-18.67,29.67-31.67,33.67c0,0-29.47,21.72-41.53,22l54.2-37.3Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M864.86,589.87,862.79,600s-.12.68-.35,1.88c-1.56,8.23-8,41.08-13.25,47.78-6,7.67-7.66,22-7.66,22s-18.67,29.67-31.67,33.67c0,0-50.67,37.33-46.33,14.66s33.66-31.66,33.66-31.66,4-7.67,4-9.34,8.67-12,8.67-12,7.67-14,8-17,13.67-58,13.67-58l7.89-.51Z",
          transform: "translate(-21.14 -37.01)",
          fill: "#f8bdc5"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M864.86,589.87,862.79,600s-.12.68-.35,1.88c-10.17,1.85-27,4.16-29.36-.22-1.07-2,1.84-5.83,6.34-10.18Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M870.19,598.36s-16.87,3.84-28,3.84c-.55,0-1.09,0-1.61,0-3.79-.15-6.67-.85-7.55-2.48-1.09-2,1.93-6,6.56-10.39,6.13-5.88,15.08-12.64,21-16.93,3.65-2.64,6.15-4.35,6.15-4.35l3.4,10s-.37,1.78-.77,4.3C868.56,587.8,867.6,596.76,870.19,598.36Z",
          transform: "translate(-21.14 -37.01)",
          fill: "#eb6475"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M766.36,553.35q4.93,12,10.14,23.8a14,14,0,0,1-3.58,1.28c-6.14,1.32-13-1.24-19-3.83-9-3.86-18.77-8.45-22.83-17.36-3-6.58-2.22-14.19-3.38-21.32-1-6.16-3.49-12.06-4-18.28-.53-7.06,1.55-14,2.74-21,1.54-.66,3-1.33,4.38-2,6.58-3.16,21.4-1.78,27.33-1.06a25.16,25.16,0,0,0-.34,4.34,24.8,24.8,0,0,0,.53,4.71c1.48,7.58,5.67,14.77,5.69,22.55,0,4.83-1.61,9.57-1.48,14.4S764.54,548.94,766.36,553.35Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M875.86,551.69c-1.67,5.67-5.67,26.33-5.67,26.33s-.37,1.78-.77,4.3c-.74-1-1.44-2-2.15-3-.46-.67-.93-1.34-1.42-2a19.08,19.08,0,0,0-5.21-4.93,9.12,9.12,0,0,0-4.92-1.39c-2.8.08-5.31,1.64-7.82,2.88-10.1,5-24.14,4.54-30.77-4.59a22.6,22.6,0,0,1-3.93-12.51c9.75-26.67,4.33-66.4,4.33-66.4s31,12.66,51,25.33S877.53,546,875.86,551.69Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M774.53,413c-1.24,3.83,0,8-.36,12-.88,9.44-10.34,16-12.49,25.26-1.65,7.07,1.26,14.41.93,21.66-.37,8.19-4.86,15.84-4.76,24,.12,9.36,6.19,17.89,6.22,27.25,0,4.84-1.61,9.58-1.48,14.41s1.95,9.31,3.77,13.72q4.93,12,10.14,23.8c-7,3.62-15.38.54-22.59-2.55-9-3.86-18.77-8.45-22.83-17.36-3-6.58-2.21-14.19-3.38-21.32-1-6.16-3.49-12.06-4-18.28-.54-7.24,1.66-14.36,2.83-21.52A75.92,75.92,0,0,0,724.28,460c-1.94-6.4-4.74-12.56-6-19.14s-.7-13.9,3.46-19.14c4.51-5.69,12.18-7.74,17.87-12.27,6-4.8,9.48-12.16,11.67-19.56,1.86-6.3,3.52-13.6,9.28-16.75a15,15,0,0,1,7.59-1.59c8.85.21,17,5.23,25.82,5.78,5,.32,10-.84,15-1.43,12.41-1.45,25.43.77,36,7.45s18.34,18.05,19.46,30.49c.67,7.46-1,15-.28,22.44A36,36,0,0,0,874,457.68c3.48,3.62,7.77,6.54,10.45,10.79a28.64,28.64,0,0,1,3.7,11.46c4.32,30.48-12.6,60.72-9.78,91.38.45,4.91,2,10.51,6.48,12.49-2.24,3.47-7.67,3.3-11.07,1s-5.46-6.15-7.94-9.46-6-6.43-10.13-6.31c-2.8.08-5.31,1.64-7.82,2.88-10.1,5-24.14,4.54-30.77-4.59-5-6.89-4.35-16.28-3-24.69s3.06-17.38-.36-25.18c-2.57-5.84-8-11.08-7.06-17.38.7-4.83,4.89-8.23,8-12,5.87-7.22,8.05-16.88,7.85-26.18s-2.56-18.41-4.92-27.41c-1.91-7.31-3.94-14.86-8.73-20.7a19.07,19.07,0,0,0-18.87-6.9c-2.94.53-5.71-.75-8.77.11A9.75,9.75,0,0,0,774.53,413Z",
          transform: "translate(-21.14 -37.01)",
          fill: "#784d55"
        }
      }),
      _c("g", { attrs: { opacity: "0.1" } }, [
        _c("path", {
          attrs: {
            d:
              "M864.1,429.45c.18-4.62.68-9.25.47-13.86C864.39,420.21,863.88,424.84,864.1,429.45Z",
            transform: "translate(-21.14 -37.01)"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M761.29,452.69a18.46,18.46,0,0,1,.39-2.42c2.15-9.24,11.61-15.82,12.49-25.26a30.11,30.11,0,0,0,0-4.65c-1.09,9.26-10.32,15.79-12.44,24.91A24,24,0,0,0,761.29,452.69Z",
            transform: "translate(-21.14 -37.01)"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M727.49,479.48a75.77,75.77,0,0,0-3.2-24.45c-1.95-6.4-4.75-12.56-6-19.14a33.67,33.67,0,0,1-.47-3.51,31.5,31.5,0,0,0,.47,8.51c1.24,6.58,4,12.74,6,19.14A76,76,0,0,1,727.49,479.48Z",
            transform: "translate(-21.14 -37.01)"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M762.73,534.49c.37-3.75,1.35-7.48,1.34-11.27a21,21,0,0,0-.15-2.38c-.38,3.93-1.44,7.83-1.33,11.79C762.61,533.25,762.66,533.88,762.73,534.49Z",
            transform: "translate(-21.14 -37.01)"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M758,493.15c.75-7.15,4.29-14,4.62-21.22a30.93,30.93,0,0,0-.08-3.85c-.71,7.76-4.77,15.08-4.68,22.89C757.86,491.7,757.92,492.43,758,493.15Z",
            transform: "translate(-21.14 -37.01)"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M881.57,581.16a9.67,9.67,0,0,1-7.78-1.41c-3.41-2.35-5.46-6.15-7.94-9.46s-6-6.43-10.13-6.31c-2.8.08-5.31,1.64-7.82,2.88-10.1,5-24.14,4.54-30.77-4.59a22.21,22.21,0,0,1-3.89-11.94c-.34,6.07.38,12.12,3.89,16.94,6.63,9.13,20.67,9.61,30.77,4.59,2.51-1.24,5-2.8,7.82-2.88,4.14-.12,7.64,3,10.13,6.31s4.53,7.11,7.94,9.46,8.83,2.52,11.08-1A8.33,8.33,0,0,1,881.57,581.16Z",
            transform: "translate(-21.14 -37.01)"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M878.11,561.61c.72-24.69,11.47-49.16,10.71-73.75C888.12,512.43,877.38,536.89,878.11,561.61Z",
            transform: "translate(-21.14 -37.01)"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M815.67,525.69a28.4,28.4,0,0,0-1.92-13.29c-2-4.57-5.75-8.78-6.84-13.42-.09.34-.16.68-.22,1-.91,6.3,4.49,11.54,7.06,17.38A24.8,24.8,0,0,1,815.67,525.69Z",
            transform: "translate(-21.14 -37.01)"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M753.91,567.6c-9-3.86-18.77-8.45-22.83-17.36-3-6.58-2.21-14.19-3.38-21.32-1-6.13-3.45-12-3.94-18.17a33.25,33.25,0,0,0,0,4.89c.47,6.22,3,12.12,4,18.28,1.17,7.13.39,14.74,3.38,21.32,4.06,8.91,13.83,13.5,22.83,17.36,7.22,3.09,15.63,6.17,22.59,2.55-.61-1.41-1.21-2.82-1.83-4.23C768.1,573.22,760.5,570.43,753.91,567.6Z",
            transform: "translate(-21.14 -37.01)"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M808.86,413.72c4.79,5.84,6.82,13.39,8.73,20.7,2.26,8.61,4.5,17.32,4.86,26.21.07-1.27.09-2.54.06-3.8-.19-9.31-2.56-18.41-4.92-27.41-1.91-7.31-3.94-14.86-8.73-20.7a19.06,19.06,0,0,0-18.87-6.9c-2.94.53-5.71-.75-8.77.12a9.72,9.72,0,0,0-6.69,6.06,18.82,18.82,0,0,0-.44,7,12.07,12.07,0,0,1,.44-2,9.72,9.72,0,0,1,6.69-6.06c3.06-.87,5.83.41,8.77-.12A19.06,19.06,0,0,1,808.86,413.72Z",
            transform: "translate(-21.14 -37.01)"
          }
        })
      ]),
      _c("path", {
        attrs: {
          d:
            "M838.33,582.35s15.53,41.34-20.47,58c0,0-64.48-7.29-84.33-2s-20.41-39.33-11.37-42S838.33,582.35,838.33,582.35Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M837.33,580.35s15.53,41.34-20.47,58c0,0-64.48-7.29-84.33-2s-20.41-39.33-11.37-42S837.33,580.35,837.33,580.35Z",
          transform: "translate(-21.14 -37.01)",
          fill: "#eb6475"
        }
      }),
      _c("path", {
        attrs: {
          d: "M703.27,593.23l5.65,33.5S710.34,601.44,703.27,593.23Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d: "M784.17,554s-1.29,15-1.89,19.67S786.47,555.35,784.17,554Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d: "M799.57,769.3s13.7,24.23,24.62,7.06",
          transform: "translate(-21.14 -37.01)",
          fill: "none",
          stroke: "#000",
          "stroke-miterlimit": "10",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d: "M861.33,787.65S894.64,805,882.5,811.83",
          transform: "translate(-21.14 -37.01)",
          fill: "none",
          stroke: "#000",
          "stroke-miterlimit": "10",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M507.74,667c-.41,1.44-2.26,2.61-4.93,3.55-10.25,3.64-32.64,4.06-32.64,4.06l-42.23-1-20.9-3a87.51,87.51,0,0,1-9.92-3.47c-2.49-1.1-4.58-2.31-5-3.43,0,0,29.71-12.84,62-10.4S509.72,660.07,507.74,667Z",
          transform: "translate(-21.14 -37.01)",
          fill: "#534d72"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M507.74,667c-.41,1.44-2.26,2.61-4.93,3.55-10.25,3.64-32.64,4.06-32.64,4.06l-42.23-1-20.9-3a87.51,87.51,0,0,1-9.92-3.47c-2.49-1.1-4.58-2.31-5-3.43,0,0,29.71-12.84,62-10.4S509.72,660.07,507.74,667Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M507.1,670.15l-1.25,26.38-.56,11.82-1.85,39-.5,10.7-1.36,28.57c-32.47,16.11-102.29,0-102.29,0l-1.08-28.57-.53-14h0l-1.35-35.73-.55-14.58-.94-25.11C434.1,681.61,507.1,670.15,507.1,670.15Z",
          transform: "translate(-21.14 -37.01)",
          fill: "#534d72"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M505.85,696.53l-.56,11.82h-109l-.55-14.58C409.81,698.17,468.71,714.78,505.85,696.53Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M503.44,747.37l-.5,10.7H398.21l-.53-14C414.13,749.05,467.74,763.12,503.44,747.37Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "429.83",
          cy: "667.8",
          rx: "26.28",
          ry: "11.12",
          opacity: "0.1"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "429.83",
          cy: "667.16",
          rx: "26.28",
          ry: "11.12",
          fill: _vm.color
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "432.69",
          cy: "633.25",
          rx: "39.47",
          ry: "4.99",
          opacity: "0.1"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "432.91",
          cy: "633.67",
          rx: "39.47",
          ry: "4.99",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M507.74,667.43c-.41,1.43-2.26,2.6-4.93,3.55C495.88,652.7,451,657.63,451,657.63c-32.35-2-49.22,7-53.85,10-2.49-1.09-4.58-2.31-5-3.42,0,0,29.71-12.84,62-10.4S509.72,660.49,507.74,667.43Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M507.74,667c-.41,1.44-2.26,2.61-4.93,3.55C495.88,652.27,451,657.2,451,657.2c-32.35-2-49.22,7-53.85,10-2.49-1.1-4.58-2.31-5-3.43,0,0,29.71-12.84,62-10.4S509.72,660.07,507.74,667Z",
          transform: "translate(-21.14 -37.01)",
          fill: "#534d72"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M399.09,780.82s-6.91,3.19-.43,8.49S448.1,798,458.93,796c0,0,39.37-2.12,44.51-7.75,0,0,.12-3.8-1.77-3.92,0,0-15.21,6.73-48.79,6.15S397.4,787.6,399.09,780.82Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M399.09,781.24s-6.91,3.19-.43,8.5,49.44,8.7,60.27,6.68c0,0,39.37-2.12,44.51-7.74,0,0,.12-3.81-1.77-3.92,0,0-15.21,6.72-48.79,6.14S397.4,788,399.09,781.24Z",
          transform: "translate(-21.14 -37.01)",
          fill: "#534d72"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M397.83,747.33c-4.16-1.17-20.29-7.58-12.21-35,0,0,8.7-29.4,14-36.4,0,0-.92-1.64-2.22,0s-5.2,11.36-5.2,11.36-16.88,31.93-8.39,50.29c2.93,6.34,8.21,10,13.87,12",
          transform: "translate(-21.14 -37.01)",
          fill: "#534d72"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M392.08,664.21s-.53,4.68,2.81,6.37,97.57,13.79,112.21,3c0,0,2.12-6.58,0-6.37S422.64,681.61,392.08,664.21Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M392.08,663.78s-.53,4.68,2.81,6.38,97.57,13.78,112.21,3c0,0,2.12-6.58,0-6.37S422.64,681.19,392.08,663.78Z",
          transform: "translate(-21.14 -37.01)",
          fill: "#534d72"
        }
      }),
      _c("circle", {
        attrs: { cx: "378.42", cy: "639.66", r: "4.83", opacity: "0.1" }
      }),
      _c("circle", {
        attrs: { cx: "377.74", cy: "638.92", r: "4.83", fill: "#534d72" }
      }),
      _c("path", {
        attrs: {
          d:
            "M274,666.42c.25.86,1.37,1.57,3,2.14,6.2,2.2,19.74,2.45,19.74,2.45l25.55-.57,12.64-1.8a53.57,53.57,0,0,0,6-2.1c1.5-.66,2.77-1.4,3.05-2.07,0,0-18-7.77-37.48-6.29S272.83,662.22,274,666.42Z",
          transform: "translate(-21.14 -37.01)",
          fill: "#534d72"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M274,666.42c.25.86,1.37,1.57,3,2.14,6.2,2.2,19.74,2.45,19.74,2.45l25.55-.57,12.64-1.8a53.57,53.57,0,0,0,6-2.1c1.5-.66,2.77-1.4,3.05-2.07,0,0-18-7.77-37.48-6.29S272.83,662.22,274,666.42Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M274.42,668.32l.75,15.95.34,7.15,1.12,23.6.31,6.48.81,17.28c19.64,9.74,61.88,0,61.88,0l.65-17.28.32-8.46h0l.82-21.61.33-8.81.57-15.19C318.57,675.25,274.42,668.32,274.42,668.32Z",
          transform: "translate(-21.14 -37.01)",
          fill: "#534d72"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M275.17,684.27l.34,7.15h65.91l.33-8.81C333.26,685.27,297.64,695.32,275.17,684.27Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M276.63,715l.31,6.48h63.34l.32-8.46C330.65,716,298.23,724.56,276.63,715Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "287.23",
          cy: "652.27",
          rx: "15.9",
          ry: "6.72",
          opacity: "0.1"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "287.23",
          cy: "651.89",
          rx: "15.9",
          ry: "6.72",
          fill: _vm.color
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "287.23",
          cy: "651.89",
          rx: "15.9",
          ry: "6.72",
          fill: _vm.secondColor,
          opacity: "0.5"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "285.5",
          cy: "631.37",
          rx: "23.88",
          ry: "3.02",
          opacity: "0.1"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "285.37",
          cy: "631.63",
          rx: "23.88",
          ry: "3.02",
          fill: _vm.color
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "285.5",
          cy: "631.66",
          rx: "23.88",
          ry: "3.02",
          fill: _vm.secondColor,
          opacity: "0.5"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M274,666.67c.25.87,1.37,1.58,3,2.15,4.2-11.06,31.36-8.07,31.36-8.07,19.57-1.19,29.77,4.25,32.57,6,1.5-.67,2.77-1.4,3.05-2.08,0,0-18-7.76-37.48-6.29S272.83,662.48,274,666.67Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M274,666.42c.25.86,1.37,1.57,3,2.14,4.2-11.06,31.36-8.07,31.36-8.07,19.57-1.18,29.77,4.25,32.57,6,1.5-.66,2.77-1.4,3.05-2.07,0,0-18-7.77-37.48-6.29S272.83,662.22,274,666.42Z",
          transform: "translate(-21.14 -37.01)",
          fill: "#534d72"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M339.75,735.26s4.18,1.93.26,5.14-29.91,5.26-36.45,4c0,0-23.82-1.28-26.93-4.69,0,0-.07-2.3,1.07-2.37,0,0,9.2,4.07,29.51,3.72S340.77,739.36,339.75,735.26Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M339.75,735.51s4.18,1.93.26,5.14-29.91,5.27-36.45,4.05c0,0-23.82-1.29-26.93-4.69,0,0-.07-2.3,1.07-2.37,0,0,9.2,4.07,29.51,3.72S340.77,739.62,339.75,735.51Z",
          transform: "translate(-21.14 -37.01)",
          fill: "#534d72"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M340.51,715c2.52-.71,12.27-4.58,7.39-21.17,0,0-5.27-17.78-8.47-22,0,0,.56-1,1.34,0s3.15,6.86,3.15,6.86S354.12,698,349,709.11a14.06,14.06,0,0,1-8.39,7.26",
          transform: "translate(-21.14 -37.01)",
          fill: "#534d72"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M344,664.72s.32,2.84-1.7,3.86-59,8.34-67.87,1.79c0,0-1.29-4,0-3.85S325.51,675.25,344,664.72Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M344,664.47s.32,2.83-1.7,3.85-59,8.34-67.87,1.8c0,0-1.29-4,0-3.85S325.51,675,344,664.47Z",
          transform: "translate(-21.14 -37.01)",
          fill: "#534d72"
        }
      }),
      _c("circle", {
        attrs: { cx: "318.32", cy: "635.25", r: "2.92", opacity: "0.1" }
      }),
      _c("circle", {
        attrs: { cx: "318.74", cy: "634.81", r: "2.92", fill: "#534d72" }
      }),
      _c("path", {
        attrs: {
          d:
            "M1004.47,670.16c.25.87,1.37,1.57,3,2.15,6.2,2.19,19.74,2.45,19.74,2.45l25.54-.58,12.64-1.8a52.83,52.83,0,0,0,6-2.1c1.51-.66,2.77-1.39,3-2.07,0,0-18-7.76-37.48-6.29S1003.28,666,1004.47,670.16Z",
          transform: "translate(-21.14 -37.01)",
          fill: "#534d72"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1004.47,670.16c.25.87,1.37,1.57,3,2.15,6.2,2.19,19.74,2.45,19.74,2.45l25.54-.58,12.64-1.8a52.83,52.83,0,0,0,6-2.1c1.51-.66,2.77-1.39,3-2.07,0,0-18-7.76-37.48-6.29S1003.28,666,1004.47,670.16Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1004.86,672.06l.76,16,.34,7.15,1.11,23.6.31,6.47.82,17.28c19.64,9.75,61.87,0,61.87,0l.65-17.28.32-8.46h0l.82-21.61.33-8.82.58-15.19C1049,679,1004.86,672.06,1004.86,672.06Z",
          transform: "translate(-21.14 -37.01)",
          fill: "#534d72"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1005.62,688l.34,7.15h65.9l.33-8.82C1063.71,689,1028.08,699.06,1005.62,688Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1007.07,718.77l.31,6.47h63.34l.32-8.46C1061.1,719.79,1028.67,728.3,1007.07,718.77Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "1017.67",
          cy: "656.02",
          rx: "15.9",
          ry: "6.72",
          opacity: "0.1"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "1017.67",
          cy: "655.63",
          rx: "15.9",
          ry: "6.72",
          fill: _vm.color
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "1017.67",
          cy: "655.63",
          rx: "15.9",
          ry: "6.72",
          opacity: "0.2"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "1015.94",
          cy: "635.12",
          rx: "23.88",
          ry: "3.02",
          opacity: "0.1"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "1015.81",
          cy: "635.37",
          rx: "23.88",
          ry: "3.02",
          fill: _vm.color
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "1016.84",
          cy: "635.12",
          rx: "23.88",
          ry: "3.02",
          opacity: "0.2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1004.47,670.42c.25.86,1.37,1.57,3,2.14,4.19-11.06,31.35-8.07,31.35-8.07,19.57-1.18,29.77,4.25,32.57,6,1.51-.66,2.77-1.4,3-2.07,0,0-18-7.77-37.48-6.29S1003.28,666.22,1004.47,670.42Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1004.47,670.16c.25.87,1.37,1.57,3,2.15,4.19-11.06,31.35-8.08,31.35-8.08,19.57-1.18,29.77,4.25,32.57,6,1.51-.66,2.77-1.39,3-2.07,0,0-18-7.76-37.48-6.29S1003.28,666,1004.47,670.16Z",
          transform: "translate(-21.14 -37.01)",
          fill: "#534d72"
        }
      }),
      _c("path", {
        attrs: {
          d: "M1028.43,667h0S1028.39,667,1028.43,667Z",
          transform: "translate(-21.14 -37.01)",
          fill: "#9d9cb5"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1048.71,683.14l.55,2.54c-1-.39-3.33-5.42-3.33-5.42-.89.7.36,4.93.36,4.93-1.52-1.05-2.38-4.7-2.38-4.7a11.67,11.67,0,0,0,0,2.84c-1.25-1.34-2.28-5.18-2.28-5.18-.38.27-.31,2.81-.31,2.93,0-.15-1.14-4.77-1.35-4.48a25.82,25.82,0,0,0-.73,3.23c-1.06-.18-3.86-6.51-3.86-6.51s-.57-.88-.44-.59a18.22,18.22,0,0,1,0,2.65c-1.43-.61-1.92-5.82-1.92-5.82l-.86,1.93-.9-1.86c0,.08.78,2.74.31,2.58s-1.55-2.84-1.66-3.37.64-1.74.64-1.74-1.87-.1-2.15-.09c.38-.21,4-3.84,4-3.84s.08-.14.23-.36a28.11,28.11,0,0,1,2.79-3.79,23.22,23.22,0,0,0,2.48-3.39c.31-.47.51-.8.51-.8L1057.18,666l-.36.86c-.22.5-.45,1-.7,1.48-.39.76-.82,1.5-1.28,2.22-.93,1.45-2.16,3.35-3.12,4.82-.59.9-1.09,1.64-1.34,2-1,1.42-.57,3.89-.76,4.34s1,4.08,1,4.08A17.69,17.69,0,0,1,1048.71,683.14Z",
          transform: "translate(-21.14 -37.01)",
          fill: "#e4e3f9"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1048.66,683.14s0-3.6,0-4.79,5.84-13.05,6.09-13.55l.08,0c-.07.13-6.07,12.34-6.08,13.52s0,4.75,0,4.79Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d: "M1028.43,667h0S1028.39,667,1028.43,667Z",
          transform: "translate(-21.14 -37.01)",
          fill: "none"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1031.56,672.22a27.07,27.07,0,0,1-.82-3.35c-.1-.86.73-2.11,1.33-3a6.16,6.16,0,0,0,.5-.83,44.25,44.25,0,0,1,2.65-3.67s2.33-3.28,2.49-3.75a15.58,15.58,0,0,1,1.26-2l.07,0a14.59,14.59,0,0,0-1.25,2c-.16.48-2.4,3.64-2.5,3.78s-2.39,3.05-2.64,3.65a5.17,5.17,0,0,1-.51.83c-.56.85-1.41,2.14-1.31,3a29.7,29.7,0,0,0,.81,3.33Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1032.08,671.59c-.22-.45,1.11-4.11,1.37-4.38s2.34-4.25,2.42-4.46,3.25-5,4.24-6.53l.07,0c-1.44,2.2-4.17,6.39-4.23,6.51s-2.14,4.16-2.44,4.49a14.76,14.76,0,0,0-1.35,4.28Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1034.94,675.41c-1.48-1.89.48-8.37.5-8.44.21-.68,5.89-9.45,6.13-9.83l.07.05c-.05.09-5.91,9.13-6.12,9.81-.55,1.8-1.72,6.82-.51,8.35Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1037,676.73c-1.31-2.58,1.1-8.13,1.12-8.18.19-.42,5.48-9.37,5.7-9.75l.08,0c-.06.09-5.51,9.33-5.7,9.74,0,.06-2.41,5.56-1.12,8.11Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1038.55,679.3c-1-1.17-1.14-3.39-.44-6.61a32.55,32.55,0,0,1,1.38-4.57l5-9.25.07,0-5,9.25c0,.08-3.33,8.43-.9,11.14l-.07.06Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1039.21,679.85c-.66-1.35,0-5.32.48-7.15s6.58-11.85,6.84-12.28l.08.05c-.07.1-6.37,10.55-6.83,12.25s-1.13,5.78-.49,7.09Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1040.46,677.88l1-4.39a11.45,11.45,0,0,1,1.22-3.21l2.44-4.32,3-4.62.07,0-3,4.62-2.43,4.31a11.76,11.76,0,0,0-1.22,3.19l-1,4.39Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1043.73,683.19c-1.27-1.49-.54-5.6-.14-7.85.08-.47.15-.85.17-1.08.14-1.3,5.68-11.79,5.92-12.24l.07,0c0,.11-5.76,10.92-5.9,12.21,0,.23-.09.61-.18,1.09-.38,2.15-1.17,6.63.28,7.93l-.06.07A1.29,1.29,0,0,1,1043.73,683.19Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1043.89,680.6s.17-2.36.15-2.92a64.54,64.54,0,0,1,2.1-6.26l4.32-9,.08,0-4.32,9s-2.13,5.67-2.1,6.22-.13,2.84-.14,2.94Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1046.25,685.21c-2.09-3.22-.08-11.46.25-12.14s4.86-10.09,4.91-10.18l.07,0c0,.09-4.57,9.51-4.9,10.18s-2.33,8.86-.26,12Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1048.74,685.23c-2.76-3.26-.35-11.5,0-12.22l4.17-9.07.08,0-4.17,9.08c-.35.75-3,9.81.49,12.59l0,.07A3.25,3.25,0,0,1,1048.74,685.23Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d: "M1056.82,666.88,1038,655.63c.31-.47.51-.8.51-.8L1057.18,666Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1057.33,666.87l-19.63-11.73,2.92-3.48,2.13-2.54s.39-2.23,2.18-2.27,5.37,3.84,6.73,3.21,5.58-8.81,5.58-8.81,8.34-11,9.06-12.22,7.77-6.58,7.77-6.58c1.15,0-.73,4.78-.87,6s-6.16,10.53-7,11-7.26,8.8-7.26,8.8c-2.82,2.39-2.94,4.61-2.94,4.61a26.26,26.26,0,0,1,5.45,3.91,1.74,1.74,0,0,1,.25,2.07l-2.22,4.43Z",
          transform: "translate(-21.14 -37.01)",
          fill: "#535461"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1057.33,666.87l-19.63-11.73,2.92-3.48c1.76,1.44,13,10.46,18.84,11.52Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1048.71,683.14l.55,2.54c-1-.39-3.33-5.42-3.33-5.42-.89.7.36,4.93.36,4.93-1.52-1.05-2.38-4.7-2.38-4.7-.24.2,0,2.84,0,2.84-1.25-1.34-2.28-5.18-2.28-5.18-.39.27-.31,2.8-.31,2.93,0-.15-1.14-4.77-1.35-4.48a25.82,25.82,0,0,0-.73,3.23c-1.06-.18-3.86-6.51-3.86-6.51s-.57-.88-.44-.59a18.22,18.22,0,0,1,0,2.65c-1.43-.61-1.92-5.82-1.92-5.82l-.86,1.93-.9-1.86c0,.07.78,2.74.31,2.58s-1.55-2.84-1.66-3.37.64-1.74.64-1.74-1.87-.1-2.15-.09c.38-.21,4-3.84,4-3.84l.23-.36c.17.54.3,1.07.43,1.43a3.4,3.4,0,0,0,.79,1.4,2.55,2.55,0,0,0,2.06.5c.73-.09,1.43-.33,2.15-.41a1.19,1.19,0,0,1,.91.16,1.52,1.52,0,0,1,.43.82,11.8,11.8,0,0,0,3.54,5.54,1.47,1.47,0,0,0,.57.35,2.24,2.24,0,0,0,.89,0,3,3,0,0,1,3,1.61c.24.5.39,1.13.89,1.38a1.86,1.86,0,0,0,.94.08l2.41-.23c-.59.9-1.09,1.64-1.34,2-1,1.42-.57,3.89-.76,4.34s1,4.08,1,4.08A17.69,17.69,0,0,1,1048.71,683.14Z",
          transform: "translate(-21.14 -37.01)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1048.81,683.14l.56,2.54c-.95-.39-3.33-5.42-3.33-5.42-.89.7.36,4.93.36,4.93-1.52-1.05-2.38-4.7-2.38-4.7-.24.2,0,2.84,0,2.84-1.25-1.34-2.28-5.18-2.28-5.18-.39.27-.31,2.8-.31,2.93,0-.15-1.14-4.77-1.35-4.48a25.82,25.82,0,0,0-.73,3.23c-1.06-.18-3.86-6.51-3.86-6.51s-.58-.88-.45-.59a18.37,18.37,0,0,1,0,2.65c-1.43-.61-1.92-5.82-1.92-5.82l-.87,1.93-.89-1.86c0,.07.78,2.74.31,2.58s-1.55-2.84-1.66-3.37.64-1.74.64-1.74-1.88-.1-2.15-.09c.37-.21,4-3.84,4-3.84l.23-.36c.17.54.3,1.07.43,1.43a3.4,3.4,0,0,0,.79,1.4,2.55,2.55,0,0,0,2.06.5c.73-.09,1.42-.33,2.15-.41a1.2,1.2,0,0,1,.91.16,1.52,1.52,0,0,1,.43.82,11.8,11.8,0,0,0,3.54,5.54,1.51,1.51,0,0,0,.56.35,2.29,2.29,0,0,0,.9,0,3,3,0,0,1,3,1.61c.24.5.39,1.13.89,1.38a1.86,1.86,0,0,0,.94.08l2.41-.23c-.6.9-1.09,1.64-1.34,2-1,1.42-.57,3.89-.76,4.34s1,4.08,1,4.08A17.16,17.16,0,0,1,1048.81,683.14Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1070.19,739s4.18,1.93.26,5.14-29.9,5.26-36.45,4c0,0-23.82-1.28-26.93-4.68,0,0-.07-2.3,1.07-2.37,0,0,9.21,4.06,29.52,3.71S1071.22,743.1,1070.19,739Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("rect", {
        attrs: {
          x: "1002.97",
          y: "637.31",
          width: "32.5",
          height: "11.39",
          fill: "#534d72"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1070.19,739.26s4.18,1.93.26,5.14-29.9,5.26-36.45,4c0,0-23.82-1.28-26.93-4.69,0,0-.07-2.3,1.07-2.37,0,0,9.21,4.07,29.52,3.72S1071.22,743.36,1070.19,739.26Z",
          transform: "translate(-21.14 -37.01)",
          fill: "#534d72"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1071,718.74c2.52-.71,12.28-4.58,7.39-21.17,0,0-5.26-17.78-8.47-22,0,0,.56-1,1.35,0s3.14,6.87,3.14,6.87,10.21,19.32,5.07,30.42a14,14,0,0,1-8.39,7.26",
          transform: "translate(-21.14 -37.01)",
          fill: "#534d72"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1074.43,668.47s.32,2.83-1.7,3.85-59,8.34-67.87,1.8c0,0-1.28-4,0-3.86S1056,679,1074.43,668.47Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1074.43,668.21s.32,2.83-1.7,3.86-59,8.34-67.87,1.79c0,0-1.28-4,0-3.85S1056,678.74,1074.43,668.21Z",
          transform: "translate(-21.14 -37.01)",
          fill: "#534d72"
        }
      }),
      _c("circle", {
        attrs: { cx: "1048.77", cy: "639", r: "2.92", opacity: "0.1" }
      }),
      _c("circle", {
        attrs: { cx: "1049.18", cy: "638.55", r: "2.92", fill: "#534d72" }
      }),
      _c("ellipse", {
        attrs: {
          cx: "116.42",
          cy: "773.8",
          rx: "26.93",
          ry: "4.55",
          fill: _vm.color,
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1097.5,851.88a11.66,11.66,0,0,0,3.83-5.78c.5-2.3-.48-5.06-2.67-5.9-2.46-.94-5.09.77-7.09,2.49s-4.27,3.69-6.88,3.32a10.47,10.47,0,0,0,3.24-9.81,4.12,4.12,0,0,0-.9-2c-1.37-1.46-3.84-.83-5.48.32-5.2,3.65-6.65,10.72-6.68,17.07-.52-2.29-.08-4.68-.09-7s-.66-5-2.64-6.22a8,8,0,0,0-4-1c-2.34-.09-4.94.15-6.54,1.86-2,2.12-1.47,5.69.26,8s4.35,3.81,6.76,5.42a15,15,0,0,1,4.84,4.62,5.19,5.19,0,0,1,.36.82h14.65A40.77,40.77,0,0,0,1097.5,851.88Z",
          transform: "translate(-21.14 -37.01)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M119.48,789.65s15.36-.47,20-3.77,23.62-7.23,24.77-1.94,23.08,26.29,5.74,26.43-40.29-2.7-44.91-5.52S119.48,789.65,119.48,789.65Z",
          transform: "translate(-21.14 -37.01)",
          fill: "#a8a8a8"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M170.29,808.53c-17.34.14-40.29-2.7-44.91-5.52-3.52-2.14-4.92-9.83-5.39-13.38l-.51,0s1,12.39,5.59,15.2,27.57,5.66,44.91,5.52c5,0,6.74-1.82,6.64-4.46C175.93,807.5,174,808.5,170.29,808.53Z",
          transform: "translate(-21.14 -37.01)",
          opacity: "0.2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M268.6,422.55s4,11,2.46,20.5.53,2.83,1.78,6.95,1.26,18.24,1.26,18.24v-9.68s-.46-14.75,0-19.13S268.6,422.55,268.6,422.55Z",
          transform: "translate(-21.14 -37.01)",
          fill: _vm.color,
          opacity: "0.5"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M305.11,406.16s4,11,2.46,20.5.53,2.83,1.78,6.94,1.26,18.25,1.26,18.25v-9.69s-.46-14.75,0-19.12S305.11,406.16,305.11,406.16Z",
          transform: "translate(-21.14 -37.01)",
          fill: _vm.color,
          opacity: "0.5"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M288.55,296.34s7.92,11,4.92,20.5-5,14.77-2.5,18.89,8.58,6.3,8.58,6.3v-9.68s-.92-14.75,0-19.13S288.55,296.34,288.55,296.34Z",
          transform: "translate(-21.14 -37.01)",
          fill: _vm.secondColor,
          opacity: "0.5"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M474.74,286.09s-1.41,13.46-10,18.57-13.53,7.73-14.41,12.46,2.19,10.38,2.19,10.38l6.43-7.23s9.12-11.63,12.72-14.29S474.74,286.09,474.74,286.09Z",
          transform: "translate(-21.14 -37.01)",
          fill: _vm.secondColor,
          opacity: "0.5"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M551.41,528.14a29.43,29.43,0,0,0-1.14,4.21,4.2,4.2,0,0,0,1.4,3.92,1.42,1.42,0,0,0,1.1.3c.8-.17,1-1.2,1-2,0-1.61,0-3.22-.1-4.84a12.57,12.57,0,0,0-.41-3.24,3.76,3.76,0,0,0-1.64-2.31C551.25,525,551.72,526.94,551.41,528.14Z",
          transform: "translate(-21.14 -37.01)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M569.12,538a30.11,30.11,0,0,0-1.13,4.21,4.16,4.16,0,0,0,1.4,3.92,1.41,1.41,0,0,0,1.09.31c.81-.17,1-1.2,1-2,0-1.61,0-3.22-.09-4.83a13.17,13.17,0,0,0-.42-3.25,3.73,3.73,0,0,0-1.63-2.3C569,534.89,569.44,536.81,569.12,538Z",
          transform: "translate(-21.14 -37.01)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M560.68,525.1a12.06,12.06,0,0,0,.48-1.76,1.76,1.76,0,0,0-.59-1.64.56.56,0,0,0-.46-.13c-.34.07-.43.5-.44.85,0,.67,0,1.35,0,2a5.18,5.18,0,0,0,.18,1.36,1.52,1.52,0,0,0,.68,1C560.74,526.41,560.55,525.61,560.68,525.1Z",
          transform: "translate(-21.14 -37.01)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M656.19,334.1a12.19,12.19,0,0,0,.48-1.77,1.75,1.75,0,0,0-.59-1.64.64.64,0,0,0-.46-.13c-.34.07-.43.51-.43.85,0,.68,0,1.35,0,2a4.83,4.83,0,0,0,.17,1.36,1.61,1.61,0,0,0,.68,1C656.26,335.4,656.06,334.6,656.19,334.1Z",
          transform: "translate(-21.14 -37.01)",
          fill: _vm.color
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "548.62",
          cy: "732.82",
          rx: "7.53",
          ry: "1.22",
          fill: _vm.color
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "554.91",
          cy: "733.54",
          rx: "7.53",
          ry: "1.22",
          fill: _vm.color
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "557.53",
          cy: "730.53",
          rx: "7.53",
          ry: "1.22",
          fill: _vm.color
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "562.44",
          cy: "734.05",
          rx: "7.53",
          ry: "1.22",
          fill: _vm.color
        }
      }),
      _c("polygon", {
        attrs: {
          points: "300.02 251.23 301.05 256.71 303.01 249.03 300.02 251.23",
          fill: _vm.color,
          opacity: "0.5"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M313.58,223.09l11.36,59.83,4.89-19.14-.59,0,8.31-46.18q-8.42,1.62-16.54,3.59l1.75,15.94-4.09-15.37Q316.1,222.43,313.58,223.09Z",
          transform: "translate(-21.14 -37.01)",
          fill: _vm.color,
          opacity: "0.5"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M637.75,295.54l-1.41,8.09,1.41,8.09c1.94-2.66,3.73-5.36,5.4-8.09C641.48,300.89,639.69,298.2,637.75,295.54Z",
          transform: "translate(-21.14 -37.01)",
          fill: _vm.color,
          opacity: "0.8"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "286.73 266.62 286.89 272.37 295.45 278.65 298.52 266.62 295.45 254.59 286.89 260.87 286.73 266.62",
          fill: _vm.color,
          opacity: "0.5"
        }
      }),
      _c("polygon", {
        attrs: {
          points: "301.05 276.53 300.02 282.01 303.01 284.21 301.05 276.53",
          fill: _vm.color,
          opacity: "0.5"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M224,322.51l-4.73-13.84-6.91,25.52c2,1.75,12.93,3.47,15.07,5.16C229,333.62,222,327.72,224,322.51Z",
          transform: "translate(-21.14 -37.01)",
          fill: _vm.color,
          opacity: "0.5"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M291,328.42l5.43-24.79,7.46-50.7c-5,1.74-14.21-6.69-19-4.75l-5.3,14.92-8.31-25.68c-4,1.68-18.17,23.21-21.94,25l6.38,41.2,10,43.05c3.77,1.81,1.65,21.48,5.61,23.16l8.31-25.68-4.41,27.29C280,373.39,286,326.68,291,328.42Z",
          transform: "translate(-21.14 -37.01)",
          fill: _vm.color,
          opacity: "0.5"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M337.55,389.62l-8.31-46.18.59,0-4.89-19.15-11.36,59.83q2.52.68,5.09,1.3l4.09-15.37L321,386C326.43,387.33,331.94,388.54,337.55,389.62Z",
          transform: "translate(-21.14 -37.01)",
          fill: _vm.color,
          opacity: "0.5"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M218.53,267.91q-3.19,2.54-6.21,5.16l6.91,25.51L224,284.74C222,279.53,220.15,273.64,218.53,267.91Z",
          transform: "translate(-21.14 -37.01)",
          fill: _vm.color,
          opacity: "0.5"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M207.45,277.49l-3.51,51.44c.27.27,4.07,1.36,4.35,1.62l9.21-26.92-9.21-26.93C208,277,207.72,277.22,207.45,277.49Z",
          transform: "translate(-21.14 -37.01)",
          fill: _vm.color,
          opacity: "0.5"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M257.14,346.68l-18.4-117.5q-6.12,3.3-11.85,6.81c2.49,17.22,16,64.25,8.48,65.06-3.45.38-7.06-5.5-10.38-13.68l-4.4,16.26,4.4,16.26c3.32-8.18,6.93-14.06,10.38-13.69,7.55.81-3.24,10.51-5.73,27.73C233.45,336.28,253.05,344.49,257.14,346.68Z",
          transform: "translate(-21.14 -37.01)",
          fill: _vm.color,
          opacity: "0.8"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M341.3,390.32q10.86,2,22.14,3.39l-5.13-40.95L357.56,367l-.9-27.4-.51-4L354,355.35l-4.85-7.76,5.8-21.8L353.58,315,351,313.08l1.18-9.45L351,294.18l2.61-1.92,1.35-10.8-5.8-21.8,4.85-7.75,2.17,19.8.51-4,.9-27.41.75,14.24,9.08-6.59c-5.59,4.48,19.84,38.67-26.09-31l-2,46.17-7.72.55-7.55,40,7.55,40,7.72.55Z",
          transform: "translate(-21.14 -37.01)",
          fill: _vm.color,
          opacity: "0.5"
        }
      }),
      _c("path", {
        attrs: {
          d: "M576.7,240c-2.14-1-4.31-1.91-6.5-2.83l-6.43,14.21L578.66,254Z",
          transform: "translate(-21.14 -37.01)",
          fill: _vm.color,
          opacity: "0.5"
        }
      }),
      _c("path", {
        attrs: {
          d: "M541.66,314.28c-2.17-.87,2.22-54.91,0-55.75l8.24-7.35-3.06,47.4Z",
          transform: "translate(-21.14 -37.01)",
          fill: _vm.color,
          opacity: "0.5"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M201.39,307.2l2.55-3.57-1.84-20.81A122.16,122.16,0,0,0,186,303.63C190.39,310.85,195.07,300.58,201.39,307.2Z",
          transform: "translate(-21.14 -37.01)",
          fill: _vm.color,
          opacity: "0.95"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M567.64,351.87c2.76-1.48,17.7,4.47,20.34,2.92l8.47-51.16-3.56-38.11c-2.64-1.56-13.34-1.18-16.1-2.66l-5.89,28.49-4.6-32.82-7.58,45.1-5.28,35.8L570.9,315.9Z",
          transform: "translate(-21.14 -37.01)",
          fill: _vm.color,
          opacity: "0.5"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M532.62,379.19l-21.19-73.87-5.31,15.13L511,303.63l-4.83-16.82,5.31,15.13,21.19-73.87c-3.86-1.24-7.77-2.42-11.76-3.54v39.29l-11.72-24.14c-6.36-1.66-8.79-.51-15.43-1.85h0l-4.29,15.39Q482.78,252,476,251l2.43-13.8-6,13.27c-2.28-.32-4.56-.63-6.86-.91L479,301.12l2.32-10-1.93,12.55,1.93,12.55-2.32-28-13.47,87.59q3.45,26.56,6.86-.91l6-22.73L476,374.29c4.53,17.32,9-1.43,13.44-2.25l4.29-2.61,1.62,1.45c6.64-1.35,13.15,15.15,19.51,13.49l6-40.93v39.29C524.85,381.61,528.76,380.42,532.62,379.19Z",
          transform: "translate(-21.14 -37.01)",
          fill: _vm.color,
          opacity: "0.5"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M512,332.82l-8.19-28.54-2,5.85,1.86-6.5-1.86-6.5,2,5.84L512,274.44c-1.49-.48-3-.94-4.54-1.37v15.18l-2.33-15.82q-3.69-1-7.54-1.74L497,278.2l-1.66-8c-1.71-.32-3.45-.6-5.2-.87l.94,8.58-2.33-8.78-2.65-.35,5.2,33.84.9-3.88-.75,4.85.75,4.85-.9-3.88-5.2,33.84,2.65-.35,2.33-8.78-.94,8.57c1.75-.26,3.49-.55,5.2-.87l1.66-8,.62,7.52q3.86-.78,7.54-1.75L507.49,319v15.18C509,333.76,510.54,333.3,512,332.82Z",
          transform: "translate(-21.14 -37.01)",
          fill: _vm.color,
          opacity: "0.5"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M598.14,271.18l-1.54,31.53.29-1.73-.21,2.65.21,2.65-.29-1.74-.15,37.14c5.55-3.46,19.27-12.72,24.19-16.5L601.55,341l3.52-37.38,8.07-16.95-4.66-20.43C603.56,262.48,603.68,274.65,598.14,271.18Z",
          transform: "translate(-21.14 -37.01)",
          fill: _vm.color,
          opacity: "0.5"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M386.64,381.43c1.11-.23,34.11,15.15,35.22,14.92l3.05-92.72-10.55-53.35c-4.69-1-15-7.14-19.72-8.12l3.24,14.29,1.57,14.35-3-11.33L388,277.12l1.73,15.79-3.32-12.47-9,18.87-.53,4.32.53,4.32,9,18.86,3.32-12.46L388,330.13l8.41,17.66,3-11.33-1.57,14.35Z",
          transform: "translate(-21.14 -37.01)",
          fill: _vm.color,
          opacity: "0.6"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "281.27 268.23 281.35 266.62 281.27 265.01 279.07 266.62 281.27 268.23",
          fill: _vm.color,
          opacity: "0.5"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M536.26,303.63,532.11,248l8.18,1.45,1.37-18.35c-1.24-.44-2.48-.87-3.73-1.29L512,303.63l25.9,73.8,3.73-1.3-1.37-18.35-8.18,1.46Z",
          transform: "translate(-21.14 -37.01)",
          fill: _vm.color,
          opacity: "0.5"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M454.44,379.33l13.95-75.7-16.77-47c-3.41-.44-6.86-.81-10.33-1.12l1.84-22.9-4.52,22.69q-3.87-.3-7.79-.5l-2.06,48.85,1.64,73.8c2.61-.13,5.63,18.74,8.21,18.54l4.52-21.3,10,19C456.61,393.4,451,379.77,454.44,379.33Z",
          transform: "translate(-21.14 -37.01)",
          fill: _vm.color,
          opacity: "0.5"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "236 231.81 247.46 268.31 261.86 249.08 267.41 239.7 257.92 274.71 251.7 290.5 236 254.59 236 231.81",
          fill: _vm.color
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }