
export default {
    mounted() {
        window.addEventListener("message", this.receiveProp.bind(this));
        window.top.postMessage('mounted', '*')
    },
    methods: {
        receiveProp(message) {
            const isMine = message.data?.component_viewer;
            if (isMine) {
                const {key, value} = message.data;
                if (this.hasOwnProperty(key) && (typeof(this[key]) === typeof(value) || value == null || this[key] == null)) {
                    this[key] = value;
                }
            }
        }
    },
    beforeDestroy() {
        window.removeEventListener("message", this.receiveProp.bind(this));
    },
}
