<template>
  <div class="portfolio-selector-button__container">
    <BaseButton @click="openSelectorModal()"><slot>Depot auswählen</slot></BaseButton>

    <BaseModal ref="modal" modalTitle="Depot auswählen" 
      :showDefaultButtons="true" :showConfirmButton="false">
      <template #default>
        <ul class="portfolio-selector-button--list list list-bordered">
          <li v-for="item in values" :key="item.value" class="list-bordered-item clickable" :class="{ 'text-bold': isSelected(item.value), }"
            @click="closeSelectorModal(); setSelected(item.value)"
          >
            <div class="row">
              <span class="col">{{ item.label }}</span>
              <span class="col-auto"><CurrencyLabel :value="item.depotGesamt" /></span>
            </div>
          </li>
        </ul>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DEPOTPOSITIONS_TYPES from "@/store/depotpositions/types";
import { ALL_DEPOTS, } from './types'

import BaseButton from '@/components/core/BaseButton.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import CurrencyLabel from '@/components/core/CurrencyLabel.vue';

export default {
  computed: {
    ...mapGetters({
      config: DEPOTPOSITIONS_TYPES.GETTERS.DEPOTPOSITIONS_CONFIG,
      depotid: DEPOTPOSITIONS_TYPES.GETTERS.DEPOT_ID,
    }),
    values() {
      if (this.config?.depotFiltr) {
        return this.config.depotFiltr.map(v => ({label: v.value, value: v.key, depotGesamt: v.depotGesamt}));
      } else {
        return [{label: 'Positionen aller Depots anzeigen', value: ALL_DEPOTS}];
      }
    },
    selected() {
      return this.depotid || ALL_DEPOTS;
    },
  },
  methods: {
    openSelectorModal() {
      this.$refs.modal.open();
      // reload every time in case the user has changed some config
      this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.RETRIEVE_DEPOTPOSITIONS_CONFIG, {});
    },
    closeSelectorModal() {
      this.$refs.modal.close();
    },
    isSelected(value) {
      return this.selected === value;
    },
    setSelected(value) {
      this.$store.commit(DEPOTPOSITIONS_TYPES.MUTATIONS.CHANGE_ID, value);
    },
  },
  mounted() {
    // reload every time in case the user has changed some config
    this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.RETRIEVE_DEPOTPOSITIONS_CONFIG, {});
  },
  components: {
    BaseButton,
    BaseModal,
    CurrencyLabel,
  },
};
</script>
