<template>
  <div>
    <div>
      <PageHeaderTitleNavigation :title="title" />

      <StepperForm
        ref="stepperForm"
        :stepType="'fixed'"
        :stepperMediator="tabsStepperMediator"
        :showSaveSpinner="showSaveSpinner"
        :selectedStepKey="currentStep"
        :hasFertig="false"
        @set-step="setStepByKey($event)"
      >
        <template #contentTemplate v-if="listOfComponentsByDivider && listOfComponentsByDivider.length">
          <div class="row my-0">
            <div class="col-12">
              <div v-for="(componentsByDivider, index) of listOfComponentsByDivider" :key="index">
                <div class="box__container">
                  <template v-for="(component, index) of componentsByDivider">
                    <div v-if="!isComponentHidden(component)" :key="index + component.id">
                        <AntragComponent
                          :component="component"
                          :comboboxSelection="(comboboxSelections && comboboxSelections[component.id]) || []"
                          :data="dataForCurrentStep || {}"
                          :disabled="disabledForCurrentStep"
                          isComponentHalfSizeEnabled
                          @change="updateData(component.id, $event)">
                        </AntragComponent>
                    </div>
                  </template>
                </div>
              </div> 
              <div class="box__container">
                <div v-for="buttonLink in buttonMapForCurrentStep" :key="buttonLink.buttonId">
                  <div style="display: flex">
                    <div class="toggle_switch">
                      <InputToggleSwitch
                        v-model="dataForCurrentStep[buttonLink.buttonId]"
                        inLineLabel
                        :disabled="disabledForCurrentStep"
                      />
                    </div>
                      <DownloadLink
                        :title="buttonLink.label"
                        :disabled="!buttonLink.erfolg"
                        :href="getPDFLink(buttonLink)"
                      />
                  </div>
                </div>
                <div class='small'>
                  ** In der Erklärung können anhängig gewesene Strafverfahren unberücksichtigt bleiben, die mangels hinreichenden 
                  Tatverdacht endgültig eingestellt wurden, mit einem Freispruch endeten oder bei denen eine ergangene Eintragung im Bundeszentralregister 
                  entfernt oder getilgt wurde.
                </div>
                <BaseButton @click="saveNegativerklaerung()" :disabled="disabledForCurrentStep || showSaveSpinner"> OK </BaseButton>
              </div>
            </div>
          </div>
        </template>
        <template #buttonsTemplate v-else>
          <NoData noIcon />
        </template>
      </StepperForm>
    </div>
    <BaseModal
        ref="errorModal"
        modalTitle="Fehler"
        labelButtonConfirm="Ok"
        :showCancelButton="false">
        <label>{{errorMessage}}</label>
    </BaseModal> 
  </div>
</template>

<script>
import CORE_TYPES from "../../store/core/types";
import { mapGetters } from "vuex";
import validator from "@/mixins/validator";
import axios from "axios";
import {
  componentValidatorToRule,
  isHiddenDisabled,
} from "@/components/antrag/antrag-utils";
import { makeQueryParam } from "@/helpers/utils-helper";
import { StepperMediator } from "@/components/stepper/utils";

import StepperForm from "@/components/stepper/StepperForm.vue";
import BaseModal from '@/components/core/BaseModal.vue';
import BaseButton from "@/components/core/BaseButton.vue";
import DownloadLink from "@/components/core/download/DownloadLink.vue";
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import AntragComponent from "@/components/antrag/AntragComponent.vue";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import NoData from '@/components/core/NoData.vue';

const DEFAULT_TITLE = 'Zuverlässigkeitserklärung'

export default {
  mixins: [validator],
  components: {
    BaseButton,
    OptionMenu,
    PageHeaderTitleNavigation,
    DownloadLink,
    InputToggleSwitch,
    AntragComponent,
    StepperForm,
    BaseModal,
    NoData
  },
  data() {
    return {
      errorMessage: null,
      components: {},
      data: {},
      comboboxSelections: {},
      additionalData: {},
      buttonMap: {},
      currentStep: null,
      steps: [],
      showSaveSpinner: false,
      refreshKey: false,
      stepCountToBeConfirmed: null,
    };
  },
  mounted() {
    this.$store.commit(CORE_TYPES.MUTATIONS.MERGE_PENDING_ACTIONS, { negativerklaerung: false });
    this.init();
  },
  watch: {
    dynamicValidators: {
      immediate: true,
      handler(newDynamicValidators) {
        for (const dynamicValidator of newDynamicValidators) {
          if (dynamicValidator && this.$configureValidators) {
            this.$configureValidators({
              [dynamicValidator.componentId]: dynamicValidator.validators,
            });
          }
        }
      },
    },
    steps(newSteps) {
      if (newSteps && newSteps.length) {
        this.setStepByKey(newSteps[0].stepKey)
      }
    },
  },
  computed: {
    ...mapGetters({
      apiAddress: CORE_TYPES.GETTERS.API_ADDRESS,
      token: CORE_TYPES.GETTERS.GET_TOKEN,
    }),
    tabsStepperMediator() {
      return new StepperMediator(this.steps);
    },
    dynamicValidators() {
      const dynamicValidators = this.componentsForCurrentStep ? this.componentsForCurrentStep
        .filter((component) => component.id && component.validators?.length)
        .map((component) => ({
          componentId: component.id,
          validators: component.validators.map(componentValidatorToRule),
        })) : []
      return dynamicValidators;
    },
    title() {
      return (this.additionalDataForCurrentStep && this.additionalDataForCurrentStep.title) || this.$appNavigation?.currentMenu?.label || DEFAULT_TITLE;
    },
    componentsForCurrentStep() {
      const routeStep = this.currentStep;
      if (routeStep && this.components && this.components[routeStep] && this.components[routeStep].length) {
        return this.components[routeStep]
      }
      return []
    },
    buttonMapForCurrentStep() {
      const routeStep = this.currentStep;
      if (routeStep && this.buttonMap && this.buttonMap[routeStep]) {
        return this.buttonMap[routeStep]
      }
      return []
    },
    disabledForCurrentStep() {
      this.refreshKey
      const routeStep = this.currentStep;
      if (routeStep && this.additionalData && this.additionalData[routeStep]) {
        return this.additionalData[routeStep] && this.additionalData[routeStep].IS_DISABLED
      }
      return false
    },
    dataForCurrentStep() {
      const routeStep = this.currentStep;
      if (routeStep && this.data && this.data[routeStep]) {
        return this.data[routeStep]
      }
      return []
    },
    additionalDataForCurrentStep() {
      const routeStep = this.currentStep;
      if (routeStep && this.additionalData && this.additionalData[routeStep]) {
        return this.additionalData[routeStep]
      }
      return []
    },
    listOfComponentsByDivider() {
        if (Array.isArray(this.componentsForCurrentStep) && this.componentsForCurrentStep.length) {
          const componentsGroup = []
          let temp = []
          componentsGroup.push(temp)
          
          for (const component of this.componentsForCurrentStep) {
              if (component.type === 'DIVIDER') {
                  temp = []
                  componentsGroup.push(temp)
              } else {
                  temp.push(component)
              }
          }
          return componentsGroup
        }
        return []
    },
    isPendingAction() {
      return this.$route.meta.isPendingAction !== false
    },
  },
  methods: {
    getPDFLink(buttonLink) {
      const payload = {
        buttonName: buttonLink.buttonName,
      };
      const apiAddress = this.apiAddress;
      const token = this.token;
      const input = btoa(JSON.stringify(payload));

      const params = makeQueryParam({ input, token });

      const fileName = buttonLink.fileName;

      return `${apiAddress}/negativerklaerungService/generate_antrag_pdf/${fileName}?${params}`;
    },
    init() {
      let serviceUrl = `/negativerklaerungService/initNegativerklaerung?isPendingAction=${this.isPendingAction}`;
      axios.get(`${process.env.VUE_APP_API}` + serviceUrl).then((response) => {
        if (response && response.data) {
          this.initNegativerklaerungen(response.data)
        }
      });
    },
    initNegativerklaerungen(negativerklaerungen) {
      let steps = []
      if (negativerklaerungen && negativerklaerungen.length) {
        negativerklaerungen.forEach(erklaerung => {
          let personId = erklaerung.additionalData && erklaerung.additionalData.PARAM_PERSONID || 'newPerson'
          let label = 'Zuverlässigkeitserklärung für den Geschäftsführer/Berater'
          if (erklaerung.data.INPUT_NAME && erklaerung.data.INPUT_VORNAME) {
            label = erklaerung.data.INPUT_NAME + ' ' + erklaerung.data.INPUT_VORNAME
          }

          steps.push({
            label: label,
            stepKey: personId
          })

          this.components[personId] = erklaerung.components
          this.buttonMap[personId] = erklaerung.additionalData && erklaerung.additionalData.buttonMap || [];
          this.data[personId] = erklaerung.data || {};
          this.additionalData[personId] = erklaerung.additionalData;
        })
        this.stepCountToBeConfirmed = negativerklaerungen.length;
        this.comboboxSelections = negativerklaerungen[0].comboboxSelections;
      }
      
      this.currentStep = steps[0] && steps[0].stepKey || ''
      this.steps = steps;
    },
    logout() {
      this.$store.dispatch(CORE_TYPES.ACTIONS.LOGOUT);
    },
    updateData(componentId, value) {
      this.dataForCurrentStep[componentId] = value;
    },
    isComponentHidden(component) {
      const result =
        component &&
        component.hidden &&
        isHiddenDisabled(component.hidden, this.data);
      if (!result) {
        return result;
      }

      return result;
    },
    setStepByKey(stepKey) {
        if (stepKey && this.currentStep !== stepKey) {
          this.currentStep = stepKey
        }
    },
    saveNegativerklaerung() {
      this.showSaveSpinner = true;
      const payload = this.dataForCurrentStep
      let serviceUrl = "/negativerklaerungService/saveNegativerklaerung";
      axios.post(`${process.env.VUE_APP_API}` + serviceUrl, payload).then((response) => {
        if (response && response.data) {
          if (response.data.errorMessage) {
            this.errorMessage = response.data.errorMessage
            this.$refs.errorModal.open();
          } else {
            if (this.stepCountToBeConfirmed && --this.stepCountToBeConfirmed === 0) {
              this.$router.push({ path: '/home' }).catch(() => {});
            } else {
              let personId = this.additionalDataForCurrentStep && this.additionalDataForCurrentStep.PARAM_PERSONID
              if (personId) {
                this.tabsStepperMediator.markStepAsSuccess(personId);
                this.updateStepper()
              }
                this.additionalData[this.currentStep].IS_DISABLED = true;
                this.refreshKey = !this.refreshKey
            }

          }
        }
      }).finally(() => this.showSaveSpinner = false);
      
    },
    updateStepper() {
      this.$refs.stepperForm?.$refs?.stepper?.updateStepList();
    },
    skipNegativerklaerung() {
      this.$store.commit(CORE_TYPES.MUTATIONS.MERGE_PENDING_ACTIONS, { negativerklaerung: false });
      this.$router.push({ path: '/home' }).catch(() => {});
    },
  },
  validators: {},
};
</script>

<style scoped>
.toggle_switch {
  padding-top: 3px;
}

.small {
  font-size: smaller;
}
</style>
