var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.vertragsdatenFieldsDefinition
    ? _c("div", [
        _c("div", {
          staticStyle: { display: "flex", "flex-direction": "column" }
        }),
        _vm.vertragsdatenFieldsDefinition.maklerGesellNr.visible
          ? _c(
              "div",
              { staticClass: "row", style: _vm.styleOrder("maklerGesellNr") },
              [
                _c(
                  "div",
                  { staticClass: "col-12 col-sm-6" },
                  [
                    _c("ComboBox", {
                      attrs: {
                        label: _vm.isFK
                          ? "Vermittlernummer Gesellschaft"
                          : "Courtagenummer",
                        values:
                          _vm.vertragsdatenFieldsDefinition.maklerGesellNr
                            .value,
                        disabled:
                          _vm.vertragsdatenFieldsDefinition.maklerGesellNr
                            .disabled,
                        firstEmpty: false
                      },
                      on: {
                        change: function($event) {
                          return _vm.dataChanged("maklerGesellNr", $event)
                        }
                      },
                      model: {
                        value: _vm.versicherung.maklerGesellNr,
                        callback: function($$v) {
                          _vm.$set(_vm.versicherung, "maklerGesellNr", $$v)
                        },
                        expression: "versicherung.maklerGesellNr"
                      }
                    })
                  ],
                  1
                )
              ]
            )
          : _vm._e(),
        _vm.vertragsdatenFieldsDefinition.vermittlernummerBemerkung.visible
          ? _c(
              "div",
              {
                staticClass: "row",
                style: _vm.styleOrder("vermittlernummerBemerkung")
              },
              [
                _c(
                  "div",
                  { staticClass: "col-12 col-sm-6" },
                  [
                    _c("InputTextArea", {
                      attrs: {
                        label: "Bemerkung Vermittlernummer",
                        disabled:
                          _vm.vertragsdatenFieldsDefinition
                            .vermittlernummerBemerkung.disabled,
                        autoGrow: "",
                        hideScrollBar: ""
                      },
                      model: {
                        value: _vm.versicherung.vermittlernummerBemerkung,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.versicherung,
                            "vermittlernummerBemerkung",
                            $$v
                          )
                        },
                        expression: "versicherung.vermittlernummerBemerkung"
                      }
                    })
                  ],
                  1
                )
              ]
            )
          : _vm._e(),
        _vm.vertragsdatenFieldsDefinition.bezug.visible
          ? _c("div", { staticClass: "row", style: _vm.styleOrder("bezug") }, [
              _c(
                "div",
                { staticClass: "col-12 col-sm-6" },
                [
                  _c("ComboBox", {
                    attrs: {
                      label: "Bezug",
                      values: _vm.vertragsdatenFieldsDefinition.bezug.value,
                      disabled:
                        _vm.vertragsdatenFieldsDefinition.bezug.disabled,
                      validateUntouched: true,
                      firstEmpty: ""
                    },
                    on: {
                      change: function($event) {
                        return _vm.dataChanged("bezug", $event)
                      }
                    },
                    model: {
                      value: _vm.versicherung.bezug,
                      callback: function($$v) {
                        _vm.$set(_vm.versicherung, "bezug", $$v)
                      },
                      expression: "versicherung.bezug"
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e(),
        _vm.vertragsdatenFieldsDefinition.bemerkungStatus.visible
          ? _c(
              "div",
              { staticClass: "row", style: _vm.styleOrder("bemerkungStatus") },
              [
                _c(
                  "div",
                  { staticClass: "col-12 col-sm-6" },
                  [
                    _c("InputField", {
                      attrs: {
                        label: "Bemerkung Status",
                        disabled:
                          _vm.vertragsdatenFieldsDefinition.bemerkungStatus
                            .disabled
                      },
                      on: {
                        input: function($event) {
                          return _vm.dataChanged()
                        }
                      },
                      model: {
                        value: _vm.versicherung.bemerkungStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.versicherung, "bemerkungStatus", $$v)
                        },
                        expression: "versicherung.bemerkungStatus"
                      }
                    })
                  ],
                  1
                )
              ]
            )
          : _vm._e(),
        _vm.vertragsdatenFieldsDefinition.interneNummer.visible
          ? _c("div", { staticClass: "row", style: _vm.styleOrder("id") }, [
              _c(
                "div",
                { staticClass: "col-12 col-sm-6" },
                [
                  _c("InputField", {
                    attrs: {
                      label: "Interne Nummer",
                      disabled:
                        _vm.vertragsdatenFieldsDefinition.interneNummer.disabled
                    },
                    model: {
                      value: _vm.versicherung.id,
                      callback: function($$v) {
                        _vm.$set(_vm.versicherung, "id", $$v)
                      },
                      expression: "versicherung.id"
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e(),
        _vm.vertragsdatenFieldsDefinition.kundennr.visible
          ? _c(
              "div",
              { staticClass: "row", style: _vm.styleOrder("kundennr") },
              [
                _c(
                  "div",
                  { staticClass: "col-12 col-sm-6 form-control" },
                  [
                    _c("InputField", {
                      staticClass: "align-end",
                      attrs: {
                        label: "Kundennummer",
                        disabled:
                          _vm.vertragsdatenFieldsDefinition.kundennr.disabled
                      },
                      model: {
                        value: _vm.versicherung.kundennr,
                        callback: function($$v) {
                          _vm.$set(_vm.versicherung, "kundennr", $$v)
                        },
                        expression: "versicherung.kundennr"
                      }
                    }),
                    _vm.canChangeVertragKundennr
                      ? _c(
                          "a",
                          {
                            staticClass: "align-end",
                            staticStyle: { "flex-grow": "0" },
                            on: {
                              click: function($event) {
                                return _vm.kundennrAendern()
                              }
                            }
                          },
                          [_c("PhPencilLine", { attrs: { size: 24 } })],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            )
          : _vm._e(),
        _vm.vertragsdatenFieldsDefinition.geteiltmitKunde.visible
          ? _c(
              "div",
              { staticClass: "row", style: _vm.styleOrder("geteiltmitKunde") },
              [
                _c(
                  "div",
                  { staticClass: "col-12 col-sm-6 form-control" },
                  [
                    _c("InputField", {
                      staticClass: "align-end",
                      attrs: {
                        label: "Geteilt mit Kundennummer",
                        disabled:
                          _vm.vertragsdatenFieldsDefinition.geteiltmitKunde
                            .disabled
                      },
                      on: {
                        input: function($event) {
                          return _vm.dataChanged()
                        }
                      },
                      model: {
                        value: _vm.versicherung.geteiltmitKunde,
                        callback: function($$v) {
                          _vm.$set(_vm.versicherung, "geteiltmitKunde", $$v)
                        },
                        expression: "versicherung.geteiltmitKunde"
                      }
                    }),
                    _c(
                      "a",
                      {
                        staticClass: "align-end",
                        staticStyle: { "flex-grow": "0" },
                        on: {
                          click: function($event) {
                            return _vm.openCustomerNewTab(
                              _vm.versicherung.geteiltmitKunde
                            )
                          }
                        }
                      },
                      [_c("PhArrowSquareOut", { attrs: { size: 24 } })],
                      1
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "align-end",
                        staticStyle: { "flex-grow": "0" },
                        on: { click: _vm.handleAddUsers }
                      },
                      [_c("PhBookOpen", { attrs: { size: 24 } })],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          : _vm._e(),
        _vm.vertragsdatenFieldsDefinition.erfassungsdatum.visible
          ? _c(
              "div",
              { staticClass: "row", style: _vm.styleOrder("erfassungsdatum") },
              [
                _c(
                  "div",
                  { staticClass: "col-12 col-sm-6" },
                  [
                    _c("DatePickerField", {
                      attrs: {
                        label: "Erfassungsdatum",
                        disabled:
                          !_vm.isNewVertrag ||
                          _vm.vertragsdatenFieldsDefinition.erfassungsdatum
                            .disabled
                      },
                      on: {
                        input: function($event) {
                          return _vm.dataChanged()
                        }
                      },
                      model: {
                        value: _vm.versicherung.erfassungsdatum,
                        callback: function($$v) {
                          _vm.$set(_vm.versicherung, "erfassungsdatum", $$v)
                        },
                        expression: "versicherung.erfassungsdatum"
                      }
                    })
                  ],
                  1
                )
              ]
            )
          : _vm._e(),
        _vm.vertragsdatenFieldsDefinition.autoUpdate.visible
          ? _c(
              "div",
              { staticClass: "row", style: _vm.styleOrder("autoUpdate") },
              [
                _c(
                  "div",
                  { staticClass: "col-12 col-sm-6" },
                  [
                    _c("InputToggleSwitch", {
                      attrs: {
                        label:
                          _vm.vertragsdatenFieldsDefinition.autoUpdate.value,
                        disabled:
                          _vm.vertragsdatenFieldsDefinition.autoUpdate.disabled
                      },
                      on: {
                        input: function($event) {
                          return _vm.dataChanged()
                        }
                      },
                      model: {
                        value: _vm.versicherung.einlesen_updaten,
                        callback: function($$v) {
                          _vm.$set(_vm.versicherung, "einlesen_updaten", $$v)
                        },
                        expression: "versicherung.einlesen_updaten"
                      }
                    })
                  ],
                  1
                )
              ]
            )
          : _vm._e(),
        _vm.vertragsdatenFieldsDefinition.letzteGDVAnderung.visible
          ? _c(
              "div",
              {
                staticClass: "row",
                style: _vm.styleOrder("letzteGDVAnderung")
              },
              [
                _c(
                  "div",
                  { staticClass: "col-12 col-sm-6" },
                  [
                    _c("InputField", {
                      attrs: {
                        label:
                          "Letzte " +
                          (_vm.isFA ? "OMDS Änderung" : "GDV-Datenänderung") +
                          " ",
                        disabled:
                          _vm.vertragsdatenFieldsDefinition.letzteGDVAnderung
                            .disabled
                      },
                      on: {
                        input: function($event) {
                          return _vm.dataChanged()
                        }
                      },
                      model: {
                        value: _vm.versicherung.dateUpdatetdGDV,
                        callback: function($$v) {
                          _vm.$set(_vm.versicherung, "dateUpdatetdGDV", $$v)
                        },
                        expression: "versicherung.dateUpdatetdGDV"
                      }
                    })
                  ],
                  1
                )
              ]
            )
          : _vm._e(),
        _vm.vertragsdatenFieldsDefinition.pramieBruttoJahr.visible
          ? _c(
              "div",
              { staticClass: "row", style: _vm.styleOrder("pramieBruttoJahr") },
              [
                _c(
                  "div",
                  { staticClass: "col-12 col-sm-6" },
                  [
                    _c("InputField", {
                      attrs: {
                        type: "currency",
                        precision: 2,
                        label: "Bruttoprämie pro Jahr",
                        disabled:
                          _vm.vertragsdatenFieldsDefinition.pramieBruttoJahr
                            .disabled
                      },
                      model: {
                        value: _vm.pramieBruttoJahr,
                        callback: function($$v) {
                          _vm.pramieBruttoJahr = $$v
                        },
                        expression: "pramieBruttoJahr"
                      }
                    })
                  ],
                  1
                )
              ]
            )
          : _vm._e(),
        _vm.vertragsdatenFieldsDefinition.pramieNettoJahr.visible
          ? _c(
              "div",
              { staticClass: "row", style: _vm.styleOrder("pramieNettoJahr") },
              [
                _c(
                  "div",
                  { staticClass: "col-12 col-sm-6" },
                  [
                    _c("InputField", {
                      attrs: {
                        type: "currency",
                        precision: 2,
                        label: "Nettoprämie pro Jahr",
                        disabled:
                          _vm.vertragsdatenFieldsDefinition.pramieNettoJahr
                            .disabled
                      },
                      model: {
                        value: _vm.pramieNettoJahr,
                        callback: function($$v) {
                          _vm.pramieNettoJahr = $$v
                        },
                        expression: "pramieNettoJahr"
                      }
                    })
                  ],
                  1
                )
              ]
            )
          : _vm._e(),
        _vm.vertragsdatenFieldsDefinition.bestandsubertragung.visible
          ? _c(
              "div",
              {
                staticClass: "row",
                style: _vm.styleOrder("bestandsubertragung")
              },
              [
                _c(
                  "div",
                  { staticClass: "col-12 col-sm-6" },
                  [
                    _c("InputToggleSwitch", {
                      attrs: {
                        label: "Bestandsübertragung",
                        disabled:
                          _vm.vertragsdatenFieldsDefinition.bestandsubertragung
                            .disabled
                      },
                      on: {
                        input: function($event) {
                          return _vm.dataChanged()
                        }
                      },
                      model: {
                        value: _vm.versicherung.bestandsubertragung,
                        callback: function($$v) {
                          _vm.$set(_vm.versicherung, "bestandsubertragung", $$v)
                        },
                        expression: "versicherung.bestandsubertragung"
                      }
                    })
                  ],
                  1
                )
              ]
            )
          : _vm._e(),
        _vm.vertragsdatenFieldsDefinition.alleRechtePflichten.visible
          ? _c(
              "div",
              {
                staticClass: "row",
                style: _vm.styleOrder("alleRechtePflichten")
              },
              [
                _c(
                  "div",
                  { staticClass: "col-12 col-sm-6" },
                  [
                    _c("InputToggleSwitch", {
                      attrs: {
                        label: "Inkl. aller Rechte/Pflichten",
                        disabled:
                          _vm.vertragsdatenFieldsDefinition.alleRechtePflichten
                            .disabled
                      },
                      on: {
                        input: function($event) {
                          return _vm.dataChanged()
                        }
                      },
                      model: {
                        value: _vm.versicherung.alleRechtePflichten,
                        callback: function($$v) {
                          _vm.$set(_vm.versicherung, "alleRechtePflichten", $$v)
                        },
                        expression: "versicherung.alleRechtePflichten"
                      }
                    })
                  ],
                  1
                )
              ]
            )
          : _vm._e(),
        _vm.vertragsdatenFieldsDefinition.korrespondenz.visible
          ? _c(
              "div",
              { staticClass: "row", style: _vm.styleOrder("korrespondenz") },
              [
                _c(
                  "div",
                  { staticClass: "col-12 col-sm-6" },
                  [
                    _c("InputToggleSwitch", {
                      attrs: {
                        label: "Korrespondenz",
                        disabled:
                          _vm.vertragsdatenFieldsDefinition.korrespondenz
                            .disabled
                      },
                      on: {
                        input: function($event) {
                          return _vm.dataChanged()
                        }
                      },
                      model: {
                        value: _vm.versicherung.korrespondenz,
                        callback: function($$v) {
                          _vm.$set(_vm.versicherung, "korrespondenz", $$v)
                        },
                        expression: "versicherung.korrespondenz"
                      }
                    })
                  ],
                  1
                )
              ]
            )
          : _vm._e(),
        _vm.vertragsdatenFieldsDefinition.buAbgelehnt.visible
          ? _c(
              "div",
              { staticClass: "row", style: _vm.styleOrder("buAbgelehnt") },
              [
                _c(
                  "div",
                  { staticClass: "col-12 col-sm-6" },
                  [
                    _c("InputToggleSwitch", {
                      attrs: {
                        label: "BÜ abgelehnt",
                        disabled:
                          _vm.vertragsdatenFieldsDefinition.buAbgelehnt.disabled
                      },
                      on: {
                        input: function($event) {
                          return _vm.dataChanged()
                        }
                      },
                      model: {
                        value: _vm.versicherung.buAbgelehnt,
                        callback: function($$v) {
                          _vm.$set(_vm.versicherung, "buAbgelehnt", $$v)
                        },
                        expression: "versicherung.buAbgelehnt"
                      }
                    })
                  ],
                  1
                )
              ]
            )
          : _vm._e(),
        _vm.vertragsdatenFieldsDefinition.letzteAnderung.visible
          ? _c(
              "div",
              { staticClass: "row", style: _vm.styleOrder("letzteAnderung") },
              [
                _c(
                  "div",
                  { staticClass: "col-12 col-sm-6" },
                  [
                    _c("InputField", {
                      attrs: {
                        label: "Letzte Änderung",
                        disabled:
                          _vm.vertragsdatenFieldsDefinition.letzteAnderung
                            .disabled
                      },
                      on: {
                        input: function($event) {
                          return _vm.dataChanged()
                        }
                      },
                      model: {
                        value: _vm.versicherung.letzteAnderung,
                        callback: function($$v) {
                          _vm.$set(_vm.versicherung, "letzteAnderung", $$v)
                        },
                        expression: "versicherung.letzteAnderung"
                      }
                    })
                  ],
                  1
                )
              ]
            )
          : _vm._e(),
        _vm.vertragsdatenFieldsDefinition.vertragssumme.visible
          ? _c(
              "div",
              { staticClass: "row", style: _vm.styleOrder("vertragssumme") },
              [
                _c(
                  "div",
                  { staticClass: "col-12 col-sm-6" },
                  [
                    _c("InputField", {
                      attrs: {
                        type: "currency",
                        precision: 2,
                        label: "Vertragssumme",
                        disabled:
                          _vm.vertragsdatenFieldsDefinition.vertragssumme
                            .disabled
                      },
                      on: {
                        input: function($event) {
                          return _vm.dataChanged()
                        }
                      },
                      model: {
                        value: _vm.versicherung.vertragssumme,
                        callback: function($$v) {
                          _vm.$set(_vm.versicherung, "vertragssumme", $$v)
                        },
                        expression: "versicherung.vertragssumme"
                      }
                    })
                  ],
                  1
                )
              ]
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }