<template>
  <div>
    <PageHeaderTitleNavigation
      title="Wertpapier beantragen"
      :actions="headerActions"
      @action-SAVE="save()"
      @action-FONDSINFO="gotoFondsinfo(isin)"
      @action-FACTSHEET="openFactsheetFile('FACTSHEET')"
      @action-FACTSHEET-KAG="openFactsheetFile('MR')"
      @action-KID="openFactsheetFile('KIID')"
      @action-VIDEO="video()"
    />

    <div class="box__container" v-if="statistikHinweise">
      <BaseCollapsable :showSection="showExpanded">
        <template v-slot:title>
          <div class="box__title">Hinweise</div>
        </template>
        <template v-slot:content>
          <div
            class="mt-2"
            v-for="(hinweis, index) of statistikHinweise"
            :key="index"
          >
            <span class="font-bold">{{ hinweis.label }}</span>
            <span>: {{ hinweis.text }}</span>
          </div>
        </template>
      </BaseCollapsable>
    </div>

    <div class="box__container">
      <div class="cards__item--header">
        <div class="box__title cards__item--header-title">
          {{ daten.title }}
        </div>
      </div>

      <div class="cards__item">
        <div class="cards__item--info">
          <div
            class="cards__item--entry"
            v-for="(entry, index) of stammdaten"
            :key="'st' + index"
          >
            <span class="font-bold">{{ entry.label }}:</span
            ><span> {{ entry.value }}</span>
          </div>
        </div>
        <div class="cards__item--info">
          <div
            class="cards__item--entry"
            v-for="(entry, index) of mifid"
            :key="'mf' + index"
          >
            <div>
              <span class="font-bold">{{ entry.label }}:</span
              ><span> {{ entry.value }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <GhostLoading
      v-if="loadingData"
      type="block"
      :config="{ block: { height: 250 } }"
    />
    <div v-else class="box__container">
      <InputTextArea
        v-if="daten.canSave"
        label="Kommentar"
        v-model="comment"
        isComponentHalfSize
      />
      <InputField
        isEmbedded
        type="percent"
        v-model="maxAnteil"
        label="Max. Anteil (optional)"
        :precision="2"
        :disabled="daten.isMaxAnteilDisabled"
        isComponentHalfSize
      />
      <div>
        <span class="font-bold">Aktueller Status:</span
        ><span class="font-bold" :class="daten.statusColor">
          {{ daten.aktuelStatus }}</span
        >
      </div>
      <ComboBox
        label="Neuer Status"
        v-model="newStatus"
        :values="daten.newStatusValues || []"
        :disabled="daten.isNewStatusDisabled"
        :firstEmpty="false"
        isComponentHalfSize
      />

      <div
        v-if="
          daten.aktuellAnlagevolumen &&
          daten.tableAnlagevolumen &&
          daten.tableAnlagevolumen.length
        "
      >
        <span class="font-bold">Aktuelles Anlagevolumen VV:</span>
        <span>
          {{ daten.aktuellAnlagevolumen }}
          <PhCurrencyEur :size="16" />
        </span>

        <Table
          class="aktuellAnlagevolumenTable"
          :headers="aktuellAnlagevolumenHeaders"
          :rows="daten.tableAnlagevolumen"
          :rowsPerPage="20"
          hidePagination
          rowId="aktuellAnlagevolumen"
        />
      </div>

      <div class="font-bold color-danger" v-if="daten.wpFehler">
        {{ daten.wpFehler }}
      </div>
    </div>

    <div class="box__container">
      <div class="font-bold mt-4">Protokoll</div>
      <Table
        :headers="headers"
        :rows="tableRows"
        :rowsPerPage="20"
        rowId="vvwertpapierbeantragen"
      />
    </div>
  </div>
</template>

<script>
import VV_TYPES from "@/store/vermoegensverwaltung/types";
import LOG_TYPES from "@/store/log/types";

import { mapGetters } from "vuex";
import mixin from "@/mixins/vermoegensverwaltung/vv-mixin.js";
import { wpListeMixin } from "@/mixins/wp-suche.js";
import { buildMessage } from "@/helpers/log-message-helper";

import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import PageHeaderTitleNavigation from "@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue";
import {
  TextColumn,
  DateColumn,
  PillColumn,
  PercentageColumn,
  CurrencyColumn,
} from "@/components/table2/table_util.js";
import Table from "@/components/table2/Table.vue";
import InputField from "@/components/core/forms/InputField.vue";
import InputTextArea from "@/components/core/forms/InputTextArea.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import { PhCurrencyEur } from "phosphor-vue";
import BaseCollapsable from "@/components/core/BaseCollapsable.vue";
import {
  PageHeaderSimpleAction,
  PageHeaderDownloadLinkAction,
  PageHeaderDownloadLinkHrefAction,
} from "@/components/core/header-title-navigation/page-header-utils";
import GhostLoading from "@/components/core/loading/GhostLoading.vue";

import { openLink } from '@/link-resolvers';
import { ResolverId } from '@/link-resolvers/types';

export default {
  mixins: [mixin, wpListeMixin],
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    Table,
    InputField,
    ComboBox,
    PhCurrencyEur,
    BaseCollapsable,
    InputTextArea,
    GhostLoading,
  },
  data() {
    return {
      loadingData: false,

      comment: "",
      maxAnteil: "",
      newStatus: "",
      aktuellAnlagevolumenHeaders: {
        lockedLeft: [TextColumn("strategie", "Strategie").makeAlwaysVisible()],
        center: [
          CurrencyColumn("volumen", "Volumen"),
          TextColumn("depotsCount", "Anzahl Depots"),
        ],
        lockedRight: [],
      },
      showExpanded: true,
    };
  },
  watch: {
    daten(newValue) {
      this.comment = newValue?.comment;
      this.maxAnteil = newValue?.maxAnteil;
      this.newStatus = newValue?.newStatus;
    },
  },
  mounted() {
    this.loadingData = true;
    this.$store
      .dispatch(VV_TYPES.ACTIONS.WERTPAPIER_BEANTRAGEN, this.isin)
      .finally(() => {
        this.loadingData = false;
      });
  },
  computed: {
    ...mapGetters({
      wertpapierData: VV_TYPES.GETTERS.WERTPAPIER_BEANTRAGEN_DATA,
    }),
    headerActions() {
      return [
        PageHeaderSimpleAction("SAVE", "Speichern / Beantragen").withVisible(
          () => this.daten?.canSave
        ),
        PageHeaderDownloadLinkAction(
          "PDF",
          "PDF",
          "",
          "/vv_wertpapierverlauf",
          { isin: this.isin }
        ),
        PageHeaderSimpleAction("FONDSINFO", "Info"),
        PageHeaderSimpleAction("FACTSHEET", "Factsheet"),
        PageHeaderSimpleAction("FACTSHEET-KAG", "Factsheet KAG"),
        PageHeaderSimpleAction("KID", "PRIIP-BIB"),
        PageHeaderDownloadLinkHrefAction(
          "VIDEO",
          "Video",
          "Wertpapier_beantragen_Video",
          this.daten?.videoFileUrl
        ),
      ];
    },
    statistikHinweise() {
      return this.wertpapierData?.statistikHinweise || [];
    },
    stammdaten() {
      return this.wertpapierData?.stammdaten || [];
    },
    mifid() {
      return this.wertpapierData?.mifid || [];
    },
    daten() {
      return this.wertpapierData?.daten || {};
    },
    tableRows() {
      return (this.wertpapierData?.rows || []).map((row) => {
        return {
          ...row,
          statusPill: row.status
            ? { label: row.status, type: row.statusColor }
            : null,
        };
      });
    },
    isin() {
      return this.$route.params.isin;
    },
    headers() {
      return {
        lockedLeft: [PillColumn("statusPill", "Status").makeAlwaysVisible()],
        center: [
          TextColumn("comment", "Kommentar"),
          TextColumn("name", "Name"),
          TextColumn("typ", "Typ"),
          TextColumn("datum", "Datum"),
          TextColumn("kundenKlasse", "Kunden Klasse"),
          TextColumn("keuer", "Kenntnisse/Erfahrungen"),
          TextColumn("verlust", "Verlusttragfähigkeit"),
          TextColumn("zeithorizont", "Zeithorizont"),
          PercentageColumn("eaTer", "EA Ter"),
          PercentageColumn("eaTerAnteilWpd", "EA Ter Anteil WPD"),
          TextColumn("sri", "SRI"),
          CurrencyColumn("preis", "Rücknamepreis"),
          DateColumn("standDate", "Stand"),
        ],
        lockedRight: [],
      };
    },
    rows() {
      const result = [];
      return result;
    },
  },
  methods: {
    save() {
      if (this.isin) {
        if (this.newStatus && this.newStatus !== "NOT_PERSISTED") {          
          this.$store.dispatch(VV_TYPES.ACTIONS.SAVE_WERTPAPIER_BEANTRAGEN, {
            isin: this.isin,
            data: {
              comment: this.comment,
              newStatus: this.newStatus,
              maxAnteil: this.maxAnteil,
            },
          });
        } else {
          this.$store.dispatch(
            LOG_TYPES.ACTIONS.ADD_MESSAGE,
            buildMessage("Bitte wählen Sie den neuen Status aus.", "danger")
          );
        }
      }
    },
    video() {
      if (!this.daten.videoFileId && !this.daten.videoFileUrl) {
        this.$confirmModal({
          title: "Kein Videospot",
          message: "Dieses Wertpapier hat kein Videospot mit Fondsinfos",
          labelButtonConfirm: "Ok",
        });
      }
    },
    openFactsheetFile(type) {
      openLink(ResolverId.Factsheet, {
        type,
        isin: this.isin,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cards__item {
  display: flex;
  flex-direction: row;
  margin: 0rem;
  flex-wrap: wrap;
}
.cards__item--info {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0.5rem;
  width: 500px;
  flex-basis: auto;
}
.cards__item--entry {
  margin: 0.5rem 0.5rem;
}
.aktuellAnlagevolumenTable {
  width: 50%;
}
</style>