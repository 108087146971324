<template>
  <SortableList v-if="hasMenus" :items="menus" @orderChanged="$emit('orderChanged', $event)">
    <ul class="option-menu-edit-modal__items list-bordered" :data-sortable-container="isSortable">
      <OptionMenuConfigItem v-for="menu in menus" :key="`${menu.id || ''}-${menu.path || ''}`" 
        class="list-bordered-item"  
        :configId="configId"
        :menu="menu" 
        :userLevel="userLevel" 
        :configContext="configContext"
        :hideParentLabel="hideParentLabel"
        :isAvailable="isAvailable"
        :isRemoveable="isRemoveable"
        :isToggleRemoveable="isToggleRemoveable"
        :isLockable="isLockable"
        :isSortable="isSortable"
        :isPermissionConfigurable="isPermissionConfigurable"
        :isExpandedLayout="isExpandedLayout"
        :isIndeterminate="isIndeterminate(menu)"
        @add="$emit('add', $event)"
        @remove="$emit('remove', $event)"
        @lock="$emit('lock', $event)"
        @unlock="$emit('unlock', $event)"
        @toggleRemoveable="$emit('toggleRemoveable', $event)"
      />
    </ul>
  </SortableList>
</template>

<script>
import SortableList from '@/components/core/SortableList.vue';
import OptionMenuConfigItem from './OptionMenuConfigItem.vue';
import { mapGetters } from 'vuex';
import MENU_CONFIG_TYPES from '@/store/menuConfig/types';

export default {
  name: 'OptionMenuConfigList',
  props: {
    configId: {
      type: String,
      required: true,
    },
    menus: {
      type: Array,
      default: () => [],
      required: true,
    },
    userLevel: {
      type: String,
      required: true,
    },
    configContext: {
      type: String,
    },
    hideParentLabel: {
      type: Boolean,
      default: false,
    },
    isAvailable: {
      type: Boolean,
      default: false,
    },
    isRemoveable: {
      type: Boolean,
      default: false,
    },
    isToggleRemoveable: {
      type: Boolean,
      default: false,
    },
    isLockable: {
      type: Boolean,
      default: false,
    },
    isSortable: {
      type: Boolean,
      default: false,
    },
    isPermissionConfigurable: {
      type: Boolean,
      default: false,
    },
    isExpandedLayout: {
      type: Boolean,
      default: false,
    },
    configuredMenuIndeterminate: {
      type: Object,
      default: () => {}
    }
  },
  emits: ['orderChanged'],
  computed: {
    ...mapGetters({
      resolveMenuPath: MENU_CONFIG_TYPES.GETTERS.RESOLVE_MENU_PATH
    }),
    hasMenus() {
      return this.menus?.length > 0;
    },
  },
  methods: {
    isIndeterminate(menu) {
      if (!this.configuredMenuIndeterminate) {
        return false;
      }

      const pathResolved = this.resolveMenuPath(this.configContext, menu.path);

      return this.configuredMenuIndeterminate[menu.id]
        || this.configuredMenuIndeterminate[menu.path]
        || this.configuredMenuIndeterminate[pathResolved];
    }
  },
  components: {
    SortableList,
    OptionMenuConfigItem,
  },
}
</script>
