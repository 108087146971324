import A_C from './types';

export default {

  [A_C.GETTERS.SETUP](state) {

    return state.SETUP;
  },


  [A_C.GETTERS.FILTER](state) {

    return state.FILTER;
  },
  [A_C.GETTERS.ROW_COUNT](state) {

    return state.rowCount;
  },
  
  [A_C.GETTERS.INFO](state) {

    return state.INFO;
  },
}