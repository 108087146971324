<template>
<!-- if you change this table component, you may also need to change PrimitiveFlexTable.vue -->
<SortableList :items="rows" @orderChanged="$emit('orderChanged', $event)">
    <table :data-sortable-container="sortableRows">
        <colgroup>
            <col v-for="{key, width} in topHeaders" :key="key" :style="{width: width + 'px'}"/>
        </colgroup>
        <thead>
            <th v-for="{key, classes, align} in topHeaders" :key="key" class="header-cell" :class="[classes, 'text-' + align]">
                <slot :name="'header_' + key" :collapsed="false"/>
            </th>
        </thead>
        <DragnDropArea v-for="({acceptsFile, open, id, row, customRowStyle, modalOpen, loadingCollapsed}, index) in rows"
            :key="id+index"
            element="tbody"
            :data-sortable-item="sortableRows && !row.__FOOTER__"
            hoverText="Datei hier ablegen"
            :disabled="!acceptsFile"
            @files="handleFile(row, $event)"
        >

            <tr v-draggable-source="row.$draggable">
                <td v-for="{key, classes, align} in topHeaders" :key="key"
                    :valign="valign"
                    class="table-cell"
                    :class="[classes, 'text-' + align]"
                    :style="customRowStyle"
                >
                    <slot v-if="isToggleCollapseVisible(key, row)" :name="key" :row="row" :open="open" :id="id" :index="index">
                        <div v-if="row[key]">
                            <span>{{row[key]}}</span>
                            <br/>
                        </div>
                        <div v-if="row.constants" v-html="sanitize(row.constants[key] || '')"/>
                    </slot>
                </td>
            </tr>
            <BaseSheetModal
                v-if="isToOpenSmallScreenModal && modalOpen"
                modalTitle=""
                class="hide-on-large-screen"
                :autoOpen="isToOpenSmallScreenModal && modalOpen"
                @closed="$emit('closeRowModal', id, index)"
            >
                <TableCard
                    :topHeaders="topHeaders"
                    :collapsedHeaders="collapsedHeaders"
                    :loadingCollapsed="loadingCollapsed"
                    :row="row"
                    :acceptsFile="acceptsFile"
                    infoModal
                >
                    <template v-for="{key} in [...topHeaders, ...collapsedHeaders]" v-slot:[`header_${key}`]="data">
                        <slot :name="'header_' + key" v-bind="data">
                            {{key}}
                        </slot>
                    </template>
                    <template v-for="{key} in [...topHeaders, ...collapsedHeaders]" :slot="key">
                        <slot :name="key" :row="row" :open="open" :id="id" collapsed>
                            {{row[key]}}
                        </slot>
                    </template>
                </TableCard>
            </BaseSheetModal>
            <tr v-if="open && collapsedHeaders.length > 0" class="hide-on-small-screen">
                <td :colspan="topHeaders.length">
                    <GhostLoading v-if="loadingCollapsed" type="block" :config="{ block: { height: Math.ceil(collapsedHeaders.length / 5) * 75, }, }" />
                    <div v-else class="expanded-row" :style="{maxWidth: (maxCollapsedWidth - 10) + 'px'}">
                        <template v-for="{key, classes, align} in collapsedHeaders">
                            <div v-if="isCollapsedContentVisible(key, row)" :key="key" class="collapsed">
                                <div class="header-cell collapsed-header">
                                    <slot :name="'header_' + key" collapsed>
                                        {{key}}
                                    </slot>
                                </div>
                                <div class="table-cell collapsed-cell" :class="[classes, 'text-' + align]">
                                    <slot :name="key" :row="row" :open="open" :id="id" collapsed>
                                        {{row[key]}}
                                    </slot>
                                </div>
                            </div>
                        </template>
                    </div>
                </td>
            </tr>
        </DragnDropArea>
        <slot name="bottom__table"></slot>
    </table>
</SortableList>
</template>
<script>
import TableCard from './TableCard.vue';
import SortableList from '@/components/core/SortableList.vue';
import DragnDropArea from '@/components/fileUpload/DragnDropArea.vue';
import BaseSheetModal from '@/components/core/BaseSheetModal.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import {sanitize} from '@/helpers/string-helper.js';

export default {
    components: {
        TableCard,
        SortableList,
        DragnDropArea,
        BaseSheetModal,
        GhostLoading,
    },
    props: {
        topHeaders: { 
            type: Array,
            default: () => [],
        },
        collapsedHeaders: { 
            type: Array,
            default: () => [],
        },
        rows: { 
            type: Array,
            default: () => [],
        },
        maxCollapsedWidth: {
            type: Number,
        },
        valign: {
            type: String,
            default: 'center'
        },
        sortableRows: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isToOpenSmallScreenModal() {
            return this.collapsedHeaders?.length > 0 && this.$isSmallScreen;
        },
    },
    methods: {
        handleFile(row, files) {
            this.$emit('files', {
                row,
                files,
            });
        },
        isToggleCollapseVisible(headerKey, row) {
            if(row.__FOOTER__ && headerKey === '_v') { // toggle collapse column (_v) value is visible on the footer / sum row only if there is collapsed headers
                return this.collapsedHeaders?.filter(ch => ch.key in row).length > 0;
            }
            return true;
        },
        isCollapsedContentVisible(headerKey, row) {
            return !row.__FOOTER__ || headerKey in row;
        },
        sanitize(htmlString) {
            return sanitize(htmlString);
        },
    },
}
</script>
<style scoped>
table {
    table-layout: fixed;
    border-spacing: 0;
    width: 100%;
    font-size: 0.9em;
}
thead th {
    box-sizing: border-box;
}

.expanded-row {
    margin: 0 -12px;
    padding: 0 14px 25px 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
    gap: 8px;
}
.collapsed {
    display: flex;
    flex-direction: column;
    min-width: 200px;
}
.collapsed-cell {
    word-break: break-all;
    white-space: normal;
}
</style>

<!-- GLOBAL STYLE -->
<style lang="scss">
table.sortable-list__item-dragged {
    font-size: 0.9em;

    .table-cell {
        box-sizing: border-box;
        padding: 8px 4px;
        border-top: 1px solid #C4C4C4;
        overflow: hidden;
    }
}
</style>
