var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Manuelle Transaktionen einfügen",
          actions: _vm.headerActions
        },
        on: { "action-CANCEL": _vm.abbrechen }
      }),
      _c("div", { staticClass: "box__container" }, [
        _c(
          "div",
          { staticClass: "d-block" },
          [
            _c("Fields", {
              ref: "refFields",
              attrs: {
                fields: _vm.fields,
                combos: _vm.combos,
                tableData: {},
                valuesProp: _vm.values,
                isNotReactive: true,
                validators: _vm.validators
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }