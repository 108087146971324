<template>
  <div class="calendar-datepicker__container">
    <DatePickerField 
      class="calendar__datepicker p-0 m-0" 
      :asCalendar="asCalendar"
      :showSecondarySelector="asCalendar"
      :highlightWeek="highlightWeek"
      :monthPicker="monthPicker"
      :datePickerSelectorsInline="false"
      :value="value"
      :disabled="disabled" 
      @input="$emit('input', $event)" 
      @change="$emit('change', $event)" 
    >
      <template><PhCalendarBlank size="20" /></template>
    </DatePickerField>
  </div>
</template>

<script>
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import { PhCalendarBlank, } from 'phosphor-vue';

export default {
  props: {
    asCalendar: {
      type: Boolean,
      default: true,
    },
    highlightWeek: {
      type: [String, Boolean],
      default: false,
    },
    monthPicker: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Date, Object],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DatePickerField,
    PhCalendarBlank,
  },
}
</script>

<!-- GLOBAL STYLE -->
<style lang="scss">
@media screen and (max-height: 980px) {
  .calendar__datepicker {
    .datepicker__selectors--item-2 {
      display: none;
    }
  }
}
</style>
