var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "MyDepot - Musterdepotverwaltung",
          defaultMenu: _vm.customOptionMenu,
          actions: _vm.headerActions
        },
        on: {
          "action-CREATE": function($event) {
            return _vm.handleAction({ key: "CREATE" })
          },
          "action-FILES": _vm.onFileChange
        }
      }),
      _c(
        "div",
        {
          staticClass: "box__container",
          attrs: { tid: _vm._generateTidFromString("box__container") }
        },
        [
          _vm.rows.length
            ? _c(
                "DragnDropArea",
                {
                  attrs: { hoverText: "Hochladen" },
                  on: { files: _vm.onFileChange }
                },
                [
                  _c("Table", {
                    attrs: {
                      headers: _vm.headers,
                      rows: _vm.rows,
                      headerActions: _vm.tableHeaderActions,
                      selected: _vm.selectedRowsByModus,
                      sortableRows: ""
                    },
                    on: {
                      selected: _vm.selectRow,
                      "click-label": _vm.openMusterdepot,
                      action: _vm.handleAction,
                      "action-DELETE": function($event) {
                        return _vm.deleteMusterdepots([$event])
                      },
                      "headerAction-BULK_DELETE": function($event) {
                        return _vm.deleteMusterdepots(_vm.allSelectedRows)
                      },
                      orderChanged: _vm.orderChanged
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "struktur",
                          fn: function(row) {
                            return [
                              row.owner
                                ? _c("InputToggleSwitch", {
                                    attrs: {
                                      disabled: _vm.loading,
                                      value: row.struktur,
                                      inLineLabel: ""
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.setStrukturBeratung({
                                          row: row,
                                          key: "STRUKTUR",
                                          value: $event,
                                          objKey: "struktur"
                                        })
                                      }
                                    }
                                  })
                                : _c("span")
                            ]
                          }
                        },
                        {
                          key: "beratung",
                          fn: function(row) {
                            return [
                              _c("ComboBox", {
                                attrs: {
                                  value: row.beratung,
                                  values: _vm.beratungValues,
                                  disabled: _vm.loading
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.setStrukturBeratung({
                                      row: row,
                                      key: "BERATUNG",
                                      value: $event,
                                      objKey: "beratung"
                                    })
                                  }
                                }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2718151861
                    )
                  })
                ],
                1
              )
            : !_vm.loading
            ? _c("NoData")
            : _c("GhostLoading", {
                attrs: { type: "table", config: { table: { rows: 10 } } }
              })
        ],
        1
      ),
      _vm._m(0),
      _c(
        "BaseModal",
        {
          ref: "createOrEditMusterdepotModal",
          attrs: {
            labelButtonConfirm: _vm.labelButtonConfirm,
            modalTitle: _vm.modalTitle
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.handleActionConfirmed()
            }
          }
        },
        [
          _c("InputField", {
            attrs: { label: "Bezeichnung" },
            model: {
              value: _vm.createMusterdepotData.label,
              callback: function($$v) {
                _vm.$set(_vm.createMusterdepotData, "label", $$v)
              },
              expression: "createMusterdepotData.label"
            }
          }),
          _c("ComboBox", {
            attrs: { label: "Typ", values: _vm.typeValues },
            model: {
              value: _vm.createMusterdepotData.type,
              callback: function($$v) {
                _vm.$set(_vm.createMusterdepotData, "type", $$v)
              },
              expression: "createMusterdepotData.type"
            }
          })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "errorModal",
          attrs: {
            labelButtonConfirm: "Ok",
            showCancelButton: false,
            modalTitle: "Hinweise und Fehler"
          }
        },
        _vm._l(_vm.errorMessages, function(error, index) {
          return _c("div", { key: index, staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass: "col-12",
                class: _vm.getStatusClass(error.status)
              },
              [_vm._v(" " + _vm._s(_vm.getDescription(error)) + " ")]
            )
          ])
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "box__container" }, [
      _c("div", [
        _c("p", [
          _vm._v(
            "Mit MyDepot können Sie Ihre eigenen Musterdepots (Einmalanlagen und Sparpläne separat) mit verschiedenen Anlagebeträgen zusammenstellen."
          )
        ]),
        _c("p", [
          _vm._v(
            "Diese Muster-Depots können beim Kunden unter Beratung/Anlageempfehlung aufgerufen und weiterverarbeitet werden."
          )
        ]),
        _c("p", [
          _vm._v(
            " - Mit der Einstellung „Für Struktur freigeschaltet“ legen Sie fest, welche Ihrer MyDepots an Ihre Unterstruktur vererbt wird. "
          )
        ]),
        _c("p", [
          _vm._v(
            " - Mit der Einstellung „Für Beratung verwendet“ entscheiden Sie, welche der MyDepots Ihnen in der Anlageempfehlung angezeigt werden. Bitte beachten Sie, dass wenn Sie kein Musterdepot aktivieren, Ihnen keines in den jeweiligen Tools angezeigt wird. "
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }