<template>
  <div>
    <div class="antrag-components__container">
      <div class="row my-0">
        <div class="col-12">
          <div v-for="(componentsByDivider, index) of listOfComponentsByDivider" :key="index">
            <div class="box__container">
              <template v-for="(component, index) of componentsByDivider">
                <div v-if="!isComponentHidden(component)"
                  :key="index + component.id" 
                  :class="{'indented' : component.config && component.config.indented, 'indented2' : component.config && component.config.indented2,}">

                  <AntragComponent
                    :ref="component.id"
                    :component="component"
                    :components="allComponents"
                    :antragId="antragId"
                    :data="antragData"
                    :comboboxSelection="getComboboxSelection(component)"
                    :multiSelectionsTabledata="getMultiSelectionsTabledata(component)"
                    isComponentHalfSizeEnabled
                    @updateStore="updateAntragData($event)"
                    @change="changeEvent"
                   >
                  </AntragComponent>
                </div>
              </template>
            </div>
          </div> 
        </div>
      </div>  
    </div>
  </div>
</template>

<script>
import AntragComponent from '@/components/antrag/AntragComponent.vue';
import validator from '@/mixins/validator'
import { componentValidatorToRule, isHiddenDisabled } from '@/components/antrag/antrag-utils';
import ANTRAG_TYPES from '@/store/antrag/types';
import { mapGetters } from 'vuex'

export default {
  mixins: [validator],
  props: {
    antragComponents: {
    },
    comboboxSelections: {
    },
    multiSelectionsTabledata: {
    },
    antragId: {
    },
    warnings: {
    },
    antragData: {
    },
    showTwoColumns:{
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.resetWarnings();
  },
  watch: {
    dynamicValidators: {
      immediate: true,
      handler(newDynamicValidators) {
        this.setupErrors(newDynamicValidators);
      },
    },
     antragWarnings: {//Watch antragWarnings directly, as dynamicValidators is not always updated simultaneously
       immediate: true,
       handler() {      
        this.setupErrors();
      }
    }
  },
  computed: {
    ...mapGetters({
      saveAntragState: ANTRAG_TYPES.GETTERS.SAVE_ANTRAG_STATE
    }),
    allComponents() {
      if (!this.antragComponents)
        return [];
      return Object.keys(this.antragComponents).reduce((acc, key) => {
        return acc.concat(this.antragComponents[key]);
      }, []);
    },
    componentsForCurrentStep() {
      const routeStep = this.$route.params.step;
      if (routeStep && this.antragComponents && this.antragComponents[routeStep]) {
        return this.antragComponents[routeStep].filter(ct => !ct.type || ct.type != 'BUTTON_DECKBLATT') // TODECIDE: add to action buttons or components
      }
      const routeSubstep = this.$route.params.substep;
      if (routeSubstep && this.antragComponents && this.antragComponents[routeSubstep]) {
        return this.antragComponents[routeSubstep].filter(ct => !ct.type || ct.type != 'BUTTON_DECKBLATT') // TODECIDE: add to action buttons or components
      }
      return []
    },
    // The 'DIVIDER' components gonna be used to put other components into boxes
    listOfComponentsByDivider() {
      if (Array.isArray(this.componentsForCurrentStep) && this.componentsForCurrentStep.length) {
        
        const componentsGroup = []
        let temp = []
        componentsGroup.push(temp)
        
        for (const component of this.componentsForCurrentStep) {
          if (component.type === 'DIVIDER') {
            temp = []
            componentsGroup.push(temp)
          } else {
            temp.push(component)
          }
        }

        return componentsGroup.filter(this.hasVisibleComponents)
      }
      return []
    },
    dynamicValidators() {
      const dynamicValidators = this.componentsForCurrentStep
        .filter(component => component.id && component.validators?.length)
        .map(component => ({
          componentId: component.id,
          validators: component.validators.map(componentValidatorToRule)
        })
      )

      return dynamicValidators
    },
    antragWarnings() {
      return this.warnings?.antragWarnings || []
    }
  },
  methods: {
    updateAntragData(payload) {
      this.$store.dispatch(ANTRAG_TYPES.ACTIONS.UPDATE_ANTRAG_DATA, {...payload, antragData: this.antragData});
    },

    getComboboxSelection(component) {
      if (this.comboboxSelections && component && component.id) {
        return this.comboboxSelections[component.id]
      }
      return []
    },
    getMultiSelectionsTabledata(component) {
      if (this.multiSelectionsTabledata && component && component.id) {
        return this.multiSelectionsTabledata[component.id]
      }
      return {
        headers: [],
        records: []
      }
    },
    hasVisibleComponents(componentsGroup) {
      return componentsGroup && componentsGroup.length && componentsGroup.some(comp => !(comp.hidden && isHiddenDisabled(comp.hidden, this.antragData)));
    },
    isComponentHidden(component) {
      const result = component && component.hidden && isHiddenDisabled(component.hidden, this.antragData)
      if (!result) {
        return result;
      }
  
      return result;
    },
    setupErrors(validators = this.dynamicValidators) {
      this.resetWarnings();

      for (const dynamicValidator of validators) {
        if (dynamicValidator && this.$configureValidators) {
          this.$configureValidators({ 
            [dynamicValidator.componentId]: dynamicValidator.validators,
          });
        }
      }
      if (this.antragWarnings && !this.saveAntragState) {
        for (const warn of this.antragWarnings) {
          if (warn.id && warn.message) {
            this.$pushErrors(warn.id, warn.message);
          }
        }
      }
    },
    resetWarnings() {
      if (this.componentsForCurrentStep) {
        this.componentsForCurrentStep.forEach(item => {
          if (item.id) {
            this.validation.reset(item.id)
          }
        })
      }
    },
    changeEvent(event) {
      this.$emit('change', event);
    },
  },
  validators: {
  },
  components: {
    AntragComponent
  }
}
</script>

<style scoped>
  .antrag-components__container {
    border: none;
  }
  .indented {
    margin-top: -16px;
    margin-left: 32px;
  }
  .indented2 {
    margin-top: -8px;
    margin-left: 26px;
  }
</style>