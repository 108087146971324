var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("GesellschaftTitleNavigation", {
        attrs: { headerActions: _vm.headerActions },
        on: {
          "action-CREATE_MEHRFACHPOSITION": _vm.openCreateMehrfachpositionModal
        }
      }),
      _c("BaseFilter", {
        attrs: {
          title: "Mehrfachpositionen Filter",
          metadata: _vm.metadata,
          configFilter: _vm.configFilter,
          hasSmartSearch: ""
        },
        on: { onFilter: _vm.doFilter }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", title: _vm.TABLE_TITLE }
              })
            : _c("Table", {
                attrs: {
                  title: _vm.TABLE_TITLE,
                  rows: _vm.rows,
                  headers: _vm.headers,
                  rowsPerPage: 20
                },
                on: {
                  "action-EDIT": _vm.openCreateMehrfachpositionModal,
                  "action-DELETE": _vm.deleteMehrfachposition
                }
              })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "createMehrfachpositionModal",
          attrs: {
            modalTitle: _vm.editMehrfachposition.id
              ? "Mehrfachposition bearbeiten"
              : "Neue Mehrfachposition",
            labelButtonConfirm: "Speichern",
            confirmDisabled:
              !_vm.editMehrfachposition.kontonr ||
              !_vm.editMehrfachposition.isin
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.saveMehrfachposition()
            }
          }
        },
        [
          _c("InputField", {
            attrs: { label: "Kontonummer" },
            model: {
              value: _vm.editMehrfachposition.kontonr,
              callback: function($$v) {
                _vm.$set(_vm.editMehrfachposition, "kontonr", $$v)
              },
              expression: "editMehrfachposition.kontonr"
            }
          }),
          _c("InputField", {
            attrs: { label: "ISIN" },
            model: {
              value: _vm.editMehrfachposition.isin,
              callback: function($$v) {
                _vm.$set(_vm.editMehrfachposition, "isin", $$v)
              },
              expression: "editMehrfachposition.isin"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }