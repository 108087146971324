<template>
  <span>{{ userLevelText }}</span>
</template>

<script>
import { mapGetters } from 'vuex'
import CORE_TYPES from '@/store/core/types'
import FC_CONFIG_TYPES from '@/store/fcConfig/types';

import { FC_CONFIG_USER_LEVEL } from '@/configs/fcConfig';

export default {
  props: {
    value: {},
  },
  computed: {
    ...mapGetters({
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      allowConfigGlobal: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_ALLOW_CONFIG_GLOBAL,
    }),
    userLevelText() {
      if(this.value === FC_CONFIG_USER_LEVEL.GLOBAL) {
        return 'global';
      }

      if(this.hasRoles([[this.VIEW_ROLES.VIEW_BROKER_AS_INTERN], [this.VIEW_ROLES.VIEW_BROKER, this.ROLES.IS_MAKLERZUGANG]])) {
        switch(this.value) {
          case FC_CONFIG_USER_LEVEL.MAKLER:
            return 'dieser Makler';
        }
      } else if(this.hasRoles([this.VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, this.ROLES.IS_KUNDENZUGANG])) {
        switch(this.value) {
          case FC_CONFIG_USER_LEVEL.KUNDE:
            return 'für diesen Kunden';
          case FC_CONFIG_USER_LEVEL.MAKLER:
            return 'für alle Kunden als Standard';
        }
      }

      return '';
    },
  },
}
</script>
