var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasComponent
    ? _c(
        _vm.cardComponent,
        _vm._b(
          { ref: "cardComponent", tag: "component" },
          "component",
          _vm.cardPropsData,
          false
        )
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }