var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Anlegen eines Nafi Users",
          actions: _vm.headerActions
        },
        on: {
          "action-ADD": function($event) {
            return _vm.nafiLogin()
          }
        }
      }),
      _c("div", { staticClass: "row my-0" }, [
        _c("div", { staticClass: "d-lg-none col-12" }, [
          _c(
            "div",
            { staticClass: "antrag-step__container box__container" },
            [
              _c("Stepper2", {
                ref: "pieStepper",
                attrs: {
                  selectedStepKey: _vm.stepKey,
                  stepperService: _vm.stepper
                },
                on: { setStep: _vm.setStepByKey }
              })
            ],
            1
          )
        ])
      ]),
      _c("ContentWithStepper", {
        scopedSlots: _vm._u([
          {
            key: "stepper",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "box__container" },
                  [
                    _c("VerticalStepper", {
                      ref: "stepper",
                      attrs: {
                        selectedStepKey: _vm.stepKey,
                        stepperService: _vm.stepper,
                        stepperName: "nafiStepper"
                      },
                      on: { setStep: _vm.setStepByKey }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "box__container" },
                  [
                    _c("router-view"),
                    _c(
                      "div",
                      { staticClass: "mt-4" },
                      [
                        _vm.stepKey !== "maklerdaten"
                          ? _c(
                              "BaseButton",
                              {
                                attrs: { isSecondary: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.prev()
                                  }
                                }
                              },
                              [_vm._v("Zurück")]
                            )
                          : _vm._e(),
                        _vm.stepKey !== "zugangsdaten"
                          ? _c(
                              "BaseButton",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.next()
                                  }
                                }
                              },
                              [_vm._v("Weiter")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }