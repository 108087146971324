import STOPPUHR_TYPES from './types';
import CORE_TYPES from '../core/types';
import axios from 'axios';
import { buildMessage, } from '../../helpers/log-message-helper';
import * as utils from '../../helpers/commonfunctions';

export default {
  [STOPPUHR_TYPES.ACTIONS.INIT_STOPPUHR]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/stoppuhr/initStoppuhr';
  
      axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
        if (response && response.data) {
  
          commit(STOPPUHR_TYPES.MUTATIONS.INIT_STOPPUHR_SUCCESS, response.data);
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });



  },

  [STOPPUHR_TYPES.ACTIONS.PERSIST]({ commit, rootState, state }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/stoppuhr/persist';

      if (payload?.courtage) {
        serviceUrl = `${serviceUrl}?courtage=true`;
      }

      let params = {
        ...state?.stoppuhrForm,
        timeLapsed: state.timeLapsed
      }
  
      axios.post(rootState.core.apiAddress + serviceUrl, params, config).then(response => {
        if (response?.data) {
  
          if (!payload?.courtage) {
            commit(STOPPUHR_TYPES.MUTATIONS.PROCESS_FORM_CHANGE, {field: 'id', content: response?.data?.id});
          }
          
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });



  },


}