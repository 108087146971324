import TAN_TYPES from './types';
import WERTPAPIERORDER_TYPES from '../wertpapierorder/types';
import CORE_TYPES from '@/store/core/types'
import axios from 'axios';
import LOG_TYPES from '@/store/log/types'
import { buildMessage } from '../../helpers/log-message-helper';

export default {
  async [TAN_TYPES.ACTIONS.ACTIVATE_COMDIRECT_TAN]({ getters, commit, dispatch }, payload ) {
    const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/wertpapierorder/activateTan?gesellschaft=COMDIRECT`
    const unknownErrorMessage = "Es ist ein unbekannter Fehler aufgetreten."
    const successMessage = "TAN erfolgreich aktiviert"
    const successMessageTimeMs = 5000
    const loginRequiredResponseCode = 511
    const timeoutResponseCode = 408
    const retVal = {
      message: undefined,
      success: undefined
    }
          
    try {
      const response = await axios.post(url);
      if (response?.data){
        if (payload?.showInstantSuccess){
          // output success message
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(successMessage, 'success', true, successMessageTimeMs))
        }
        // update tan-required-state
        commit(TAN_TYPES.MUTATIONS.SET_COMDIRECT_TAN_REQUIRED, { value: false })
        // enforce re-save (to trigger re-validation)
        if (payload?.updateAntrag){
          dispatch(WERTPAPIERORDER_TYPES.ACTIONS.CHECK_COMDIRECT_LOGIN, 'COMDIRECT')
          dispatch(WERTPAPIERORDER_TYPES.ACTIONS.SAVE_WERTPAPIERORDER, { requiresComdirectLogin: false })
        }
      }
      retVal.success = response?.data
      return retVal
    } catch(error) {
      let status = error.response?.status
      let mscMessage = error.response?.data.ATTR_ERR_MSG
      if(status === loginRequiredResponseCode){// start comdirect 2FA flow
        // open tab
        const wHandle = window.open(error.response.data.ATTR_LOGIN_URI_COMDIRECT);
        wHandle.focus();
        // wait until timeout or tan activated (reload = true)
         try {
            const response = await axios.post(`${url}&reload=true`)
            // close tab
            wHandle.close();
            // check result
            if (response?.data){
              // output success
              dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(successMessage, 'success', true, successMessageTimeMs))
              // update tan-required-state
              commit(TAN_TYPES.MUTATIONS.SET_COMDIRECT_TAN_REQUIRED, { value: false })              

              if (payload?.updateAntrag){
                // enforce re-save (to trigger re-validation)
                dispatch(WERTPAPIERORDER_TYPES.ACTIONS.CHECK_COMDIRECT_LOGIN, 'COMDIRECT')
                dispatch(WERTPAPIERORDER_TYPES.ACTIONS.SAVE_WERTPAPIERORDER, {  requiresComdirectLogin: false });
              }
            }
            retVal.success = response?.data
            return retVal
        } catch (timeoutError){
          status = timeoutError.response?.status
          mscMessage = timeoutError.response?.data.ATTR_ERR_MSG                    
          retVal.success = false
        }
      } else {
        retVal.success = false
      }

      if(status == timeoutResponseCode){
        retVal.message = buildMessage('Es konnte innerhalb einer Minute keine TAN-Aktivierung festgestellt werden', 'info')
      } else if (status == 404 || status == 502 || status == 503){
        retVal.message = buildMessage('Der Service ist aktuell nicht erreichbar.', 'danger')
      } else if (status != 500){  // everything but 500 might contain a message (500 will be standard exception and have its own handler)
        retVal.message = buildMessage(mscMessage || unknownErrorMessage, 'danger')
      }
    }

    return retVal
  },

  
  async [TAN_TYPES.ACTIONS.GET_COMDIRECT_SESSION]({ getters, dispatch, commit }) {
    
    const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/externe_zugang/getComdirectSession`;
    try {
      const response = await axios.get(url);
      commit(TAN_TYPES.MUTATIONS.GET_COMDIRECT_SESSION, response);
      //return response
    } catch(error) {
      console.log('err: ', error.response.data, 'err decoded: ', error.response.data.ATTR_LOGIN_URI_COMDIRECT);
//      console.log('err decoded: ', error.response.data.ATTR_LOGIN_URI_COMDIRECT);
      if(error.response.status === 511 && error.response.data.ATTR_ERR_MSG === 'comdirect login needed'){// start comdirect 2FA flow
        const wHandle = window.open(error.response.data.ATTR_LOGIN_URI_COMDIRECT);
        wHandle.focus();
        const params = '?reload=true';
        const url2 = `${url}${params}`;
        console.log('wHandle 1.: ', wHandle);
        const response2 = await axios.get(url2)
        wHandle.close();
        console.log('wHandle 2.: ', wHandle);
        commit(TAN_TYPES.MUTATIONS.GET_COMDIRECT_SESSION, response2);
        //return response2
      }else{
        let msg =  error.response.data.ATTR_ERR_MSG || 'Es ist ein unerwarteter Fehler aufgetreten.';
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(msg, 'danger'));
      }
    } 
  },

}