<template>
  <div>
    <template v-if="loading">
      <GhostLoading useBoxContainer>
        <Block type="title" />
        <Block height="150" />
      </GhostLoading>
    </template>
    <template v-else>
      <div  class="box__container">
        <div  class="box__title">E-Mail Anhang</div>
        <p v-if="dokUrl">
          <a :href="dokUrl" target="_blank">Die letzte Anlageempfehlung, erzeugt am {{dokCreateDate}}.</a>
        </p>
        <p v-else>
          Für diese Anlageempfehlung wurde kein Dokument erzeugt.
        </p>
        <InputToggleSwitch v-model="neuPDF" label="Ein neues Dokument erzeugen" inLineLabel />
        <div class="d-flex">
          <BaseButton @click="startEmail" :disabled="!dokUrl">Das letzte Dokument verwenden</BaseButton>
        </div>
      </div>
      <template v-if="neuPDF">
        <ActionPdfStep :id="id" :fromEmail="true" @createPDF="setPdfInfo"/>
      </template>
    </template>
  </div>
</template>

<script>
import INVESTMENT_ADVICE from "@/store/investmentAdvice/types";
import MAILCOMPOSER_TYPES from "@/store/mailcomposer/types";
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import ActionPdfStep from './ActionPdfStep.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';

export default {
  components: {
    ActionPdfStep, GhostLoading, Block, BaseButton, InputToggleSwitch
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data: function() {
    return {
      dokId: null,
      dokCreateDate: '',
      dokUrl: '',
      loading: true,
      neuPDF: false,
    }
  },
  mounted() {
    this.$store.dispatch(INVESTMENT_ADVICE.ACTIONS.LOAD_LAST_DOC_INFO, this.id)
    .then(resp => {
      if (resp.data?.dokId) {
        this.setPdfInfo(resp.data);
      } else {
        this.neuPDF = true;
      }
      this.loading = false;
    }); 
  },
  computed: {
  },
  watch: {
    
  },
  methods: {
    setPdfInfo(data) {
      this.dokId = data.dokId;
      this.dokCreateDate = data.created;
      this.dokUrl = data.url;
      this.neuPDF = !this.dokUrl;
    },
    startEmail() {
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG, {
        type: 'mailcomposer-investment',
        action: 'clear',
      });
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, {
        type: 'mailcomposer-investment',
        action: 'clear',
      });
      this.$router.push(`/communication/mailcomposer-investment/${this.id}`);
    },
    submitChanges() {
    },

  },
}
</script>

<style scoped>

</style>