export const MUTATION_PREFIX = 'LOADING: ';
export const ACTIONS_PREFIX = 'LOADING_ACTIONS_';
export const GETTERS_PREFIX = 'LOADING_GETTERS_';

export default {
  MUTATIONS: {
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    ADD_LOADING: MUTATION_PREFIX + 'ADD_LOADING',
    STOP_LOADING: MUTATION_PREFIX + 'STOP_LOADING',
  },
  ACTIONS: {
    START_LOADING: ACTIONS_PREFIX + 'START_LOADING',
    STOP_LOADING: ACTIONS_PREFIX + 'STOP_LOADING',
  },
  GETTERS: {
    IS_LOADING: GETTERS_PREFIX + 'IS_LOADING',
    IS_INITIAL_LOADING: GETTERS_PREFIX + 'IS_INITIAL_LOADING',
  },
}
