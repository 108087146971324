import GESELLSCHAFT_TYPES from './types';

export default {
  [GESELLSCHAFT_TYPES.GETTERS.GET_GESELLSCHAFT](state) {
    return state.gesellschaftData;
  },
  [GESELLSCHAFT_TYPES.GETTERS.GET_GESELLSCHAFT_ID_LOGGED_USER](state) {
    return state.gesellschaftLoggedUser;
  },
  [GESELLSCHAFT_TYPES.GETTERS.GESELLSCHAFTS_INFOS](state) {
    return state.gesellschaftsInfos;
  },
}