import MSC_MARKETING_TYPES from './types';
import CORE_TYPES from '../core/types';
import axios from 'axios';
import router from '../../router';
import { getNewMarketingCampaign } from './index';

const config = {
  defaultSpinner: true
};

export default {
  async [MSC_MARKETING_TYPES.ACTIONS.GET_MARKETING_CAMPAIGN_LIST]({ getters, commit}, { filter }) {
    let params = {};

    let url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/marketing_campaign/list`;

    if(filter) {
      params = {...params, ...filter}
    }

    try {
      const response = await axios.get(url, {params, config})

      commit(MSC_MARKETING_TYPES.MUTATIONS.GET_MARKETING_CAMPAIGN_SUCCESS, response.data);
    } catch(e) {
      commit(MSC_MARKETING_TYPES.MUTATIONS.GET_MARKETING_CAMPAIGN_SUCCESS, []);
    }
  },

  async [MSC_MARKETING_TYPES.ACTIONS.GET_MARKETING_CAMPAIGN]({ getters }, params) {
    try {
      const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/marketing_campaign/get_marketing_campaign`;
      const response = await axios.get(url, { params, config })
      return response.data;
    } catch(e) {
      // empty block
    }
  },

  async [MSC_MARKETING_TYPES.ACTIONS.GET_MARKETING_CAMPAIGN_TARGET_LIST]({ getters, commit }, params) {
    try {
      const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/marketing_campaign/target_list`;
      const response = await axios.get(url, { params, config });

      commit(MSC_MARKETING_TYPES.MUTATIONS.RESET_USER_INTO_TARGET_LIST);
      commit(MSC_MARKETING_TYPES.MUTATIONS.SET_USER_INTO_TARGET_LIST, response.data);
    } catch(e) {
      commit(MSC_MARKETING_TYPES.MUTATIONS.RESET_USER_INTO_TARGET_LIST);
    }
  },
  
  async [MSC_MARKETING_TYPES.ACTIONS.LOAD_USER_ID_AS_TARGET]({ getters, commit }, { userId, targetType }) {
    try {
      const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/marketing_campaign/load_user_by_id`;
      const response = await axios.get(url, { params: { userId }, config });

      if (response.data?.id) {
        const values = [{
          ...response.data,
        }]

        commit(MSC_MARKETING_TYPES.MUTATIONS.ADD_USER_INTO_TARGET_LIST, { values, targetType });
      }

    } catch(e) {
      // empty block
    }
  },

  async [MSC_MARKETING_TYPES.ACTIONS.COPY_MARKETING_CAMPAIGN]({ getters, commit }, id) {
    if (!id) {
      return;
    }

    try {
      const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/marketing_campaign/copy_campaign/${id}`;
      const response = await axios.put(url, { config });

      if (response.data.id) {
        const current = getters[MSC_MARKETING_TYPES.GETTERS.MARKETING_CAMPAIGNS];
        commit(MSC_MARKETING_TYPES.MUTATIONS.GET_MARKETING_CAMPAIGN_SUCCESS, [...current, response.data ]);
      }
    } catch (e) {
      // empty block
    }
  },

  async [MSC_MARKETING_TYPES.ACTIONS.SAVE_MARKETING_CAMPAIGN]({ getters, dispatch, commit }, campaign) {
    if (!campaign || !getters[MSC_MARKETING_TYPES.GETTERS.MARKETING_CAMPAIGN_CHANGED]) {
      return;
    }

    commit(MSC_MARKETING_TYPES.MUTATIONS.SET_EDITING_MARKETING_CAMPAIGN, campaign);

    if (!campaign.title) {
      return;
    }

    try {
      const baseUrl = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/marketing_campaign`;
      
      let response = null;
      if (!campaign.id) {
        response = await axios.post(`${baseUrl}/create`, campaign, config);
      } else {
        response = await axios.put(`${baseUrl}/update/${campaign.id}`, campaign, config);
      }

      await dispatch(MSC_MARKETING_TYPES.ACTIONS.SAVE_TARGET_LIST, response.data.id);
      commit(MSC_MARKETING_TYPES.MUTATIONS.SET_EDITING_MARKETING_CAMPAIGN, getNewMarketingCampaign());
      commit(MSC_MARKETING_TYPES.MUTATIONS.MARKETING_CAMPAIGN_CHANGED, false);

      return response.data;
    } catch(e) {
      // empty block
    }
  },

  async [MSC_MARKETING_TYPES.ACTIONS.SAVE_TARGET_LIST]({ getters }, id) {
    const targetList = getters[MSC_MARKETING_TYPES.GETTERS.TARGET_LIST];

    if (!id) {
      return;
    }

    try {
      const baseUrl = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/marketing_campaign`;

      await axios.put(`${baseUrl}/update_target_list/${id}`, targetList || [], config);
    } catch (e) {
      // empty block
    }
  },

  async [MSC_MARKETING_TYPES.ACTIONS.MARK_AS_READ]({ getters, dispatch }, { id }) {
    if (!id) {
      return
    }

    await axios.put(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/marketing_campaign/mark_as_read/${id}`, config);
    await dispatch(MSC_MARKETING_TYPES.ACTIONS.GET_PENDING_MARKETING_CAMPAIGNS);
  },

  async [MSC_MARKETING_TYPES.ACTIONS.GET_PENDING_MARKETING_CAMPAIGNS]({ getters, commit }) {
    try {
      const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/marketing_campaign/pending_campaigns`, config);

      commit(MSC_MARKETING_TYPES.MUTATIONS.GET_PENDING_MARKETING_CAMPAIGNS_SUCCESS, response.data);

    } catch (e) {
      // empty block
    }
  },

  async [MSC_MARKETING_TYPES.ACTIONS.POSTPONE_MARKETING_CAMPAIGN]({ getters, commit }, { marketingCampaign, nextUrl }) {
    if (!marketingCampaign?.id) {
      return;
    }

    commit(MSC_MARKETING_TYPES.MUTATIONS.POSTPONE_MARKETING_CAMPAIGN, marketingCampaign);

    const pendingCampaigns = getters[MSC_MARKETING_TYPES.GETTERS.PENDING_MARKETING_CAMPAIGNS];

    if (!pendingCampaigns?.length) {
      commit(CORE_TYPES.MUTATIONS.MERGE_PENDING_ACTIONS, { marketingCampaigns: false });
      router.push({ path: nextUrl || '/home' }).catch(() => {});
    }
  },

  async [MSC_MARKETING_TYPES.ACTIONS.ACKNOWLEDGE_MARKETING_CAMPAIGN]({ getters, commit, dispatch }, { marketingCampaign, nextUrl}) {
    if (!marketingCampaign?.id) {
      return;
    }

    await dispatch(MSC_MARKETING_TYPES.ACTIONS.MARK_AS_READ, { id: marketingCampaign.id });

    const pendingCampaigns = getters[MSC_MARKETING_TYPES.GETTERS.PENDING_MARKETING_CAMPAIGNS];

    if (!pendingCampaigns?.length) {
      commit(CORE_TYPES.MUTATIONS.MERGE_PENDING_ACTIONS, { marketingCampaigns: false });
      router.push({ path: nextUrl || '/home' }).catch(() => {});
    }
  },

  async [MSC_MARKETING_TYPES.ACTIONS.DELETE_MARKETING_CAMPAIGN]({ getters }, id) {
    await axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/marketing_campaign/delete/${id}`, config);
  }
}