var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "editMappeModal",
          attrs: {
            modalTitle: _vm.isEdit
              ? "Beratungsmappe bearbeiten"
              : "Beratungsmappe erstellen",
            labelButtonConfirm: _vm.isEdit ? "Speichern" : "Erstellen",
            confirmDisabled:
              ((!_vm.bezeichnungValid || !_vm.mappeData.mappeTyp) &&
                !_vm.isBeratungsmappeClosed) ||
              (_vm.isMappeTypVVSchema &&
                (_vm.isMissingMaklerLegitimations ||
                  !!_vm.beratungsmappeSelections.errorsWealthKonzept))
          },
          on: { onConfirmButton: _vm.confirmButton }
        },
        [
          _vm.loading
            ? _c(
                "GhostLoading",
                [
                  _c("Block", { attrs: { type: "form-input" } }),
                  _c("Block", { attrs: { type: "form-input" } }),
                  _c("Block", { attrs: { type: "form-input" } }),
                  _c("Block", { attrs: { height: "100" } })
                ],
                1
              )
            : [
                _c("InputField", {
                  attrs: {
                    label: "Titel (max. 99 Zeichen)",
                    renderDanger: !_vm.bezeichnungValid,
                    disabled: _vm.isBeratungsmappeClosed
                  },
                  model: {
                    value: _vm.mappeData.bezeichnung,
                    callback: function($$v) {
                      _vm.$set(_vm.mappeData, "bezeichnung", $$v)
                    },
                    expression: "mappeData.bezeichnung"
                  }
                }),
                _c("ComboBox", {
                  attrs: {
                    label: "Verwende",
                    values: _vm.mappeTypenValues,
                    disabled: _vm.isBeratungsmappeClosed
                  },
                  on: { change: _vm.setDefaultLagerstelle },
                  model: {
                    value: _vm.mappeData.mappeTyp,
                    callback: function($$v) {
                      _vm.$set(_vm.mappeData, "mappeTyp", $$v)
                    },
                    expression: "mappeData.mappeTyp"
                  }
                }),
                _vm.showLagerstelle
                  ? _c("ComboBox", {
                      attrs: {
                        label: "Lagerstelle",
                        values: _vm.lagerstellenValues,
                        disabled: _vm.isBeratungsmappeClosed
                      },
                      model: {
                        value: _vm.mappeData.lagerstelle,
                        callback: function($$v) {
                          _vm.$set(_vm.mappeData, "lagerstelle", $$v)
                        },
                        expression: "mappeData.lagerstelle"
                      }
                    })
                  : _vm._e(),
                _c("InputTextArea", {
                  attrs: {
                    label:
                      "Bemerkung zur Beratung / Vermittlung (für Berater des Kunden sichtbar)"
                  },
                  model: {
                    value: _vm.mappeData.bemerkung,
                    callback: function($$v) {
                      _vm.$set(_vm.mappeData, "bemerkung", $$v)
                    },
                    expression: "mappeData.bemerkung"
                  }
                }),
                _vm.isMappeTypVVSchema &&
                this.beratungsmappeSelections.errorsWealthKonzept
                  ? _c("div", { staticClass: "error" }, [
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.beratungsmappeSelections.errorsWealthKonzept
                          )
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm.isMappeTypVVSchema && _vm.isMissingMaklerLegitimations
                  ? _c(
                      "div",
                      { staticClass: "error" },
                      [
                        _c("p", [
                          _vm._v(
                            "Leider liegen nicht alle benötigten Ausweiskopien und/oder Daten vor. Bitte klicken Sie auf den Link um diese zu hinterlegen. Ohne die vollständig hinterlegten Anforderungen können leider keine Beratungsmappen für die Vermögensverwaltung erstellt werden."
                          )
                        ]),
                        _vm._l(_vm.missingMaklerLegitimations, function(
                          makler,
                          personId
                        ) {
                          return _c("div", [
                            _c("h5", [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.redirectToMitarbeiter(personId)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(makler.name))]
                              )
                            ]),
                            _c(
                              "ul",
                              _vm._l(_vm.getErrorList(makler.errors), function(
                                error
                              ) {
                                return _c("li", [_vm._v(_vm._s(error.trim()))])
                              }),
                              0
                            )
                          ])
                        })
                      ],
                      2
                    )
                  : _vm._e()
              ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }