<template>
  <Firma v-if="isFirma" />
  <Privat v-else />
</template>

<script>
import { mapGetters } from 'vuex'
import CUSTOMERDATA_TYPES from '@/store/customerData/types'

import Firma from './stammdaten/Firma.vue'
import Privat from './stammdaten/Privat.vue'


export default {
  computed: {
    ...mapGetters({
      personId: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_ID,
      customerDataEdited: CUSTOMERDATA_TYPES.GETTERS.GET_CUSTOMER_DATA_EDITED,
    }),
    personalDataAddress() {
      return this.customerDataEdited && this.customerDataEdited.personalDataAddress
    },
    isFirma() {
      return this.personalDataAddress && this.personalDataAddress.title === 'Firma'
    },
    isPrivat() {
      return this.personalDataAddress && this.personalDataAddress.title !== 'Firma'
    }
  },
  methods: {
    
  },
  mounted() {
    if (this.$route.query.title || this.$route.query.firstName || this.$route.query.lastName || this.$route.query.dayOfBirth) {
        const payload = {
            personId: this.personId,
            personalDataAddress: {},
            personalDataBirth: {},
        };

        if (this.$route.query.title){
            payload.personalDataAddress.title = this.$route.query.title;
        }
        if (this.$route.query.firstName){
            payload.personalDataAddress.firstName = this.$route.query.firstName;
        }
        if (this.$route.query.lastName){
            payload.personalDataAddress.lastName = this.$route.query.lastName;
        }
        if (this.$route.query.dayOfBirth){
            payload.personalDataBirth.dayOfBirth = this.$route.query.dayOfBirth;
        }
        if (this.$route.query.zip){
            payload.personalDataAddress.zip = this.$route.query.zip;
        }
        if (this.$route.query.city){
            payload.personalDataAddress.city = this.$route.query.city;
        }
        if (this.$route.query.street){
            payload.personalDataAddress.street = this.$route.query.street;
        }

        this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.ADD_CUSTOMER_DATA_EDITED, payload);
    }
  },
  components: {
    Privat,
    Firma,
  }
}
</script>
