<template>
  <div>
    <Hobbys />
    <Vorlieben />
    <ZusaetzlicheInfos />
  </div>
</template>

<script>
import { PhTrash } from 'phosphor-vue';

import persoenlicheDatenMixin from '@/components/persoenliche-daten/persoenliche-daten-mixin';

import Hobbys from './hobbys-vorlieben/Hobbys.vue';
import Vorlieben from './hobbys-vorlieben/Vorlieben.vue';
import ZusaetzlicheInfos from './hobbys-vorlieben/ZusaetzlicheInfos.vue';


export default {
  mixins: [persoenlicheDatenMixin],
  components: {
    Hobbys,
    Vorlieben,
    ZusaetzlicheInfos,
  },
}
</script>
