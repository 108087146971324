<template>
  <div>
    <div class="box__container">
      <div class="text-bold">Basispunkte</div>
      <InputField
        isComponentHalfSize
        v-model="form.basispunkteGesamt"
        type="number"
        validateUntouched
        :precision="0"
        label="damit Basispunkte gesamt"
        :disabled="true"
        @change="
          updateStore({ basispunkteGesamt: formatNumber($event) }, 'settings')
        "
      />
      <InputField
        isComponentHalfSize
        v-model="form.basispunkteWK"
        id="form.basispunkteWK"
        type="number"
        validateUntouched
        :precision="0"
        label="Basispunkte WealthKonzept"
        :disabled="!isIntern || isFa"
        @change="
          updateStore({ basispunkteWK: formatNumber($event) }, 'settings')
        "
      />
      <InputField
        isComponentHalfSize
        v-model="form.basispunkteAdvisor"
        id="form.basispunkteAdvisor"
        type="number"
        validateUntouched
        :precision="0"
        label="Basispunkte Advisor"
        :disabled="isFa"
        @change="
          updateStore({ basispunkteAdvisor: formatNumber($event) }, 'settings')
        "
      />
      <div class="text-bold">Benchmark</div>
      <InputField
        isComponentHalfSize
        v-model="form.benchmarkName"
        id="form.benchmarkName"
        label="Name"
        validateUntouched
        :disabled="true"
        @change="updateStore({ benchmarkName: $event }, 'settings')"
      />
      <div class="d-flex fc-form-danger">
        <span
          v-if="
            !form.benchmarkDisabled &&
            !form.benchmarkIndex1 &&
            !form.benchmarkIndex2 &&
            !form.benchmarkIndex3
          "
        >
          Bitte machen Sie Angaben zu mindestens einem Index.
        </span>
        <span
          v-else-if="
            !form.benchmarkDisabled &&
            (parseInt(form.benchmarkAnteil1) || 0) +
              (parseInt(form.benchmarkAnteil2) || 0) +
              (parseInt(form.benchmarkAnteil3) || 0) !=
              100
          "
        >
          Die Verteilung muss sich zu 100% aufsummieren.
        </span>
      </div>
      <div class="d-flex forms__input--half-size">
        <div class="index_combo">
          <ComboBox
            v-model="form.benchmarkIndex1"
            id="form.benchmarkIndex1"
            :values="valuesBenchmarkIndex"
            label="Index 1"
            validateUntouched
            :disabled="form.benchmarkDisabled"
            :firstEmpty="true"
            @change="updateStore({ benchmarkIndex1: $event }, 'settings')"
          />
        </div>
        <div class="index_percent">
          <InputField
            v-model="form.benchmarkAnteil1"
            id="form.benchmarkAnteil1"
            type="percent"
            :precision="0"
            validateUntouched
            :disabled="form.benchmarkDisabled"
            @change="updateStore({ benchmarkAnteil1: $event }, 'settings')"
          />
        </div>
      </div>
      <div class="d-flex forms__input--half-size">
        <div class="index_combo">
          <ComboBox
            v-model="form.benchmarkIndex2"
            id="form.benchmarkIndex2"
            :values="valuesBenchmarkIndex"
            label="Index 2"
            validateUntouched
            :disabled="form.benchmarkDisabled"
            :firstEmpty="true"
            @change="updateStore({ benchmarkIndex2: $event }, 'settings')"
          />
        </div>
        <div class="index_percent">
          <InputField
            v-model="form.benchmarkAnteil2"
            id="form.benchmarkAnteil2"
            type="percent"
            :precision="0"
            :disabled="form.benchmarkDisabled"
            validateUntouched
            @change="updateStore({ benchmarkAnteil2: $event }, 'settings')"
          />
        </div>
      </div>
      <div class="d-flex forms__input--half-size">
        <div class="index_combo">
          <ComboBox
            v-model="form.benchmarkIndex3"
            id="form.benchmarkIndex3"
            :values="valuesBenchmarkIndex"
            label="Index 3"
            validateUntouched
            :disabled="form.benchmarkDisabled"
            :firstEmpty="true"
            @change="updateStore({ benchmarkIndex3: $event }, 'settings')"
          />
        </div>
        <div class="index_percent">
          <InputField
            v-model="form.benchmarkAnteil3"
            id="form.benchmarkAnteil3"
            type="percent"
            :precision="0"
            :disabled="form.benchmarkDisabled"
            validateUntouched
            @change="updateStore({ benchmarkAnteil3: $event }, 'settings')"
          />
        </div>
      </div>
      <InputToggleSwitch
        v-model="form.fkVisible"
        id="form.fkVisible"
        :label="getLabelVisible"
        inLineLabel
        validateUntouched
        @input="updateStore({ fkVisible: $event }, 'settings')"
      />
      <InputToggleSwitch
        v-model="form.immerRebalancing"
        id="form.immerRebalancing"
        label="Rebalancing auch bei einfachem Fondstausch"
        inLineLabel
        validateUntouched
        @input="updateStore({ immerRebalancing: $event }, 'settings')"
      />
      <InputRadioBoxGroup
        title="Depotanpassung"
        v-model="form.rebalancingArt"
        id="form.rebalancingArt"
        :values="depotAnpassungValues"
        validateUntouched
        @input="updateStore({ rebalancingArt: $event }, 'settings')"
      />
    </div>
  </div>
</template>

<script>
import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputField from "@/components/core/forms/InputField.vue";
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import InputRadioBoxGroup from "@/components/core/forms/radiobox/InputRadioBoxGroup.vue";
import mixin from "@/mixins/vermoegensverwaltung/vv-mixin.js";
import validator from "@/mixins/validator";
import { minValue, maxValue } from "@/mixins/validator/rules";
export default {
  mixins: [mixin, validator],
  validators: {
    form: {
      basispunkteAdvisor: [
        minValue(0, "Die eingegebene Zahl muss positiv sein.", {
          forceTouch: true,
        }),
        maxValue(31, "Der Maximalwert beträgt 30 Basispunkte.", {
          forceTouch: true,
        }),
      ],
    },
  },
  components: {
    InputField,
    ComboBox,
    InputToggleSwitch,
    InputRadioBoxGroup,
  },
  data() {
    return {
      form: {
        basispunkteGesamt: "",
        basispunkteWK: "",
        basispunkteAdvisor: "",
        benchmarkName: "",
        benchmarkIndex1: "",
        benchmarkIndex2: "",
        benchmarkIndex3: "",
        benchmarkAnteil1: "",
        benchmarkAnteil2: "",
        benchmarkAnteil3: "",
        fkVisible: "0",
        immerRebalancing: "0",
        rebalancingArt: "",
      },
      values: [],
      depotAnpassungValues: [
        { label: "Tausch über Abwicklungskonto", value: false },
        { label: "Zug um Zug", value: true },
      ],
    };
  },
  mounted() {
    this.initValuesSettings();
    this.updateWarnings(true);
  },
  watch: {
    schemaData(value) {
      this.initValuesSettings();
    },
  },
  computed: {
    valuesBenchmarkIndex() {
      return this.dataSettings?.valuesBenchmarkIndex || [];
    },
    getLabelVisible() {
      return this.isFa
        ? "Strategie ist für FinanzAdmin sichtbar"
        : "Strategie ist für Fondskonzept sichtbar";
    },
  },
};
</script>

<style scoped>
.index_percent {
  margin-top: 1.5rem;
  margin-left: 1rem;
}
.index_combo {
  width: 70%;
}
</style>