import Vue from 'vue';

const INSTANCES = new Map();

function observer(el) {
  const observer = new IntersectionObserver(([e]) => {
    e.target.classList.toggle('stuck', e.intersectionRatio < 1 && e.intersectionRect?.y <= 0);
  }, { threshold: [1] });
  observer.observe(el);
  INSTANCES.set(el, observer);
}

function disconnect(el) {
  const observer = INSTANCES.get(el);
  observer?.disconnect();
  INSTANCES.delete(el);
}

Vue.directive('sticky', {
  bind(el, binding) {
    const { value, } = binding;

    Vue.nextTick(() => {
      if(value !== false) {
        el.dataset.vSticky = true;
        observer(el);
      }
    });
  },
  unbind(el) {
    disconnect(el);
  },
});
