<template>
<div>
  <BaseModal 
    ref="editTransactionCommentModal"
    modalTitle="Kommentar"
    labelButtonConfirm="Speichern"
    labelButtonCancel="Abbrechen"
    @onConfirmButton="saveComment()"
    @onCancelButton="close()">
    <InputField type="text" label="" v-model="comment"/>
  </BaseModal>
</div>
</template>

<script>
import MULTIBANKING_TYPES from '@/store/multiBanking/types'
import InputField from '@/components/core/forms/InputField.vue'
import BaseModal from '@/components/core/BaseModal.vue'

export default {
  components: {
    BaseModal,
    InputField,
  },

  props: {
    transaction: {
      type: Object,
      required: true,
    },
  },

  data: function () {
    return {
      comment: this.transaction.customerComment || '',
    }
  },
  
  methods: {
    saveComment() {
      this.$store.dispatch(MULTIBANKING_TYPES.ACTIONS.SAVE_TRANSACTION_COMMENT, {
          transactionId: this.transaction.id,
          category: this.transaction.category.category,
          comment: this.comment,
        })
      this.close()
    },
    close() {
      this.$refs.editTransactionCommentModal.close()
      this.$emit("close")
    },
  },
  mounted() {
    this.$refs.editTransactionCommentModal.open()
  }
}
</script>

<style scoped>

</style>