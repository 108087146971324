<template>
  <div>
    <PageHeaderActionCard title="Import Config" />

    <div class="box__container">
      <InputTextArea
        label="JSON Content"
        v-model="content"/>

      <BaseButton 
        @click="importData()">
        Import
      </BaseButton>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/core/BaseButton.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import PageHeaderActionCard from '@/components/core/PageHeaderActionCard.vue';
import CONFIG_DEFINITION_TYPES from '@/store/configDefinition/types';

export default {
  components: {
    PageHeaderActionCard,
    InputTextArea,
    BaseButton,
  },

  data() {
    return {
      content: ''
    }
  },

  methods: {
    async importData() {
      if (this.content) {
        try {
          await this.$store.dispatch(CONFIG_DEFINITION_TYPES.ACTIONS.IMPORT_CONFIGS, {
            content: this.content
          })

          this.$confirmModal({
            title: 'Import Finished', 
            message: 'Import Finished', 
            labelButtonConfirm: 'Ok',
            showCancelButton: false,
          })
        } catch (error) {
          // empty block
        }
      }
    }
  }
}
</script>