import { render, staticRenderFns } from "./DocumentsView.vue?vue&type=template&id=03d7482c&scoped=true&"
import script from "./DocumentsView.vue?vue&type=script&lang=js&"
export * from "./DocumentsView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03d7482c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("P:\\workspaces\\fk0\\fc\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('03d7482c')) {
      api.createRecord('03d7482c', component.options)
    } else {
      api.reload('03d7482c', component.options)
    }
    module.hot.accept("./DocumentsView.vue?vue&type=template&id=03d7482c&scoped=true&", function () {
      api.rerender('03d7482c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/bipro/DocumentsView.vue"
export default component.exports