import EXTERNE_ZUGANG_CONFIG_TYPES from './types';
import CORE_TYPES from '@/store/core/types'
import axios from 'axios';
import LOG_TYPES from '@/store/log/types';
import { buildMessage } from '@/helpers/log-message-helper';

const config = {
  defaultSpinner: true
};

const SERVICE_PATH = '/externe_zugang_config'

export default {

  async [EXTERNE_ZUGANG_CONFIG_TYPES.ACTIONS.FIND_CONFIG_DATA]({ getters, commit, }) {
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/configData`, config);
    commit(EXTERNE_ZUGANG_CONFIG_TYPES.MUTATIONS.SET_CONFIG_DATA, { ...response?.data || {}, });
  },

  async [EXTERNE_ZUGANG_CONFIG_TYPES.ACTIONS.FIND_LIST]({ getters, commit, }) {
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}`, config);
    commit(EXTERNE_ZUGANG_CONFIG_TYPES.MUTATIONS.SET_LIST, [ ...response?.data || [], ]);
  },

  async [EXTERNE_ZUGANG_CONFIG_TYPES.ACTIONS.FIND_EXTERNE_ZUGANG_DEFINITION]({ getters, commit, }, { bezeichnung, id, }) {
    if(!bezeichnung) return;

    const params = [];
    if(id) {
      params.push(['id', id].join('='));
    }
    await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/definition/${bezeichnung}?${params.join('&')}`, config).then(response => {
      commit(EXTERNE_ZUGANG_CONFIG_TYPES.MUTATIONS.SET_DEFINITION, {
        [bezeichnung]: { ...response?.data || {}, },
      });
    });
  },

  [EXTERNE_ZUGANG_CONFIG_TYPES.ACTIONS.SAVE_EXTERNE_ZUGANG_DATA]({ getters, commit, }, { bezeichnung, id, data, }) {
    if(!bezeichnung || !data) return;

    const params = [];
    if(id) {
      params.push(['id', id].join('='));
    }

    axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/data/${bezeichnung}?${params.join('&')}`, { ...data, }, config).then(response => {
      commit(EXTERNE_ZUGANG_CONFIG_TYPES.MUTATIONS.ADD_REPLACE_LIST_ITEM, {
        externeZugang: { ...response?.data || {}, },
      });
  });
  },

  [EXTERNE_ZUGANG_CONFIG_TYPES.ACTIONS.DELETE_EXTERNE_ZUGANG]({ getters, commit, }, id) {
    if(!id) return;

    axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/${id}`, config).then(response => {
      commit(EXTERNE_ZUGANG_CONFIG_TYPES.MUTATIONS.DELETE_REPLACE_LIST_ITEM, {
        externeZugang: { ...response?.data || {}, },
        deletedId: id,
      });
    })
  },

  [EXTERNE_ZUGANG_CONFIG_TYPES.ACTIONS.EXECUTE_ACTION]({ getters, dispatch, }, { action, id, bezeichnung, data, addLocationHref, }) {
    if(!action || !bezeichnung) return;

    const params = [];
    params.push(['action', action].join('='));
    if(id) {
      params.push(['id', id].join('='));
    }
    if (addLocationHref) {
      data["REDIRECT_URI"] = location.origin + location.pathname;
    }

    axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/executeAction/${bezeichnung}?${params.join('&')}`, data || {}, config).then(response => {
      const message = response?.data;
      if(message?.text || message?.title || message?.type) {
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(`${message.title}<br>${message.text}`, message.type?.toLowerCase()));
      } else if (message?.uri) {
        if (message?.redirect) {
          location.href = message.uri;
        } else {
          window.open(message.uri, "_blank").focus();
        }
      }
    });
  },

  async [EXTERNE_ZUGANG_CONFIG_TYPES.ACTIONS.FIND_BIPRO_SIBLING_GESELLSCHAFTEN]({ getters, commit, }, { bezeichnung, }) {
    if(!bezeichnung) return;

    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/biproSiblingGesellschaften/${bezeichnung}`, config);
    commit(EXTERNE_ZUGANG_CONFIG_TYPES.MUTATIONS.SET_BIPRO_SIBLING_GESELLSCHAFTEN, {
      [bezeichnung]: [ ...response?.data || [], ],
    });
  },

  async [EXTERNE_ZUGANG_CONFIG_TYPES.ACTIONS.SAVE_BIPRO_GDV_PARAMETER]({ getters, }, { gesellschaftId, }) {
    if(!gesellschaftId) return;

    await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/biproGdvParameter/${gesellschaftId}`, {}, config);
  },

  async [EXTERNE_ZUGANG_CONFIG_TYPES.ACTIONS.DELETE_BIPRO_GDV_PARAMETER]({ getters, }, { gesellschaftId, }) {
    if(!gesellschaftId) return;

    await axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/biproGdvParameter/${gesellschaftId}`, config);
  },

}
