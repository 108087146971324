<template>
  <div v-if="customerDataEdited">
    <FlexibleList
      v-if="rows && rows.length"
      :rows="rows"
      :pageSize="isCustomerOnly ? 0 : 5"
      @onNavigate="navigateToCard"
    >
      <template #title="row">
        {{ row.title }}
      </template>

      <template #value="row">
        {{ row.value }}
      </template>

      <template #thirdRow="row">
        {{ row.description }}
      </template>
    </FlexibleList>
    <NoData v-else noIcon />
  </div>
</template>


<script>
import BoxContainer from "@/components/core/BoxContainer.vue";
import { PhPencilLine, PhBookOpen } from "phosphor-vue";
import { TextColumn } from "@/components/table2/table_util.js";
import Table from "@/components/table2/Table.vue";
import { mapGetters } from "vuex";
import CUSTOMERDATA_TYPES from "@/store/customerData/types";
import CORE_TYPES from "@/store/core/types";
import persoenlicheDatenMixin from "../persoenliche-daten-mixin";
import stammdatenMixin from "@/components/persoenliche-daten/stammdaten/stammdaten-mixin";
import FlexibleList from "@/components/flexibleList/FlexibleList.vue";
import NoData from '@/components/core/NoData.vue';

export default {
  components: {
    BoxContainer,
    PhPencilLine,
    PhBookOpen,
    Table,
    FlexibleList,
    NoData,
  },
  mixins: [persoenlicheDatenMixin, stammdatenMixin],
  data: function () {
    return {
      title: "Kommunikationsdaten",
      personalDataBirth: {
        nameAtBirth: "",
        nationality: "",
        countryOfBirth: "",
        placeOfBirth: "",
        dayOfBirth: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      customerDataEdited: CUSTOMERDATA_TYPES.GETTERS.GET_CUSTOMER_DATA_EDITED,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      isFK: CORE_TYPES.GETTERS.IS_FK,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      personId: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_ID,
      customerDataConfig: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_CONFIG,
      customerData: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_DATA,
      isCustomerOnly: CORE_TYPES.GETTERS.IS_CUSTOMER_ONLY,
    }),
    headers() {
      const result = {
        center: [],
      };

      result.center.push(TextColumn("title", "Typ"));
      result.center.push(TextColumn("value", "Beschreibung"));
      result.center.push(
        TextColumn("hauptkommunikation", "Hauptkommunikation")
      );
      // result.center.push(TextColumn("comment", "Bemerkung"));

      return result;
    },
    rows() {
      return (this.customerData?.contacts || [])
        .map((row) => ({
          ...row,
          hauptkommunikation: row.standart ? "Ja" : "Nein",
          shouldHighlight: row.standart,
          description: "tested by Guedes",
        }))
        .sort((a, b) => {
          // Sort primarily by "title", and if they are the same, by "standart" (true first)
          return a.title.localeCompare(b.title) || b.standart - a.standart;
        });
    },
    tableId() {
      const uniqueUUID = "47f7d9cd-5e2b-4a52-b2e6-15f940541a6e";
      return uniqueUUID;
    },
  },
  methods: {
    navigateToCard(row) {
      this.$router.push(`/persoenlichedaten/customer-data/steps/kommunikationsdaten`);
    },
    // currency(value) {
    //   if (isNaN(value)) {
    //     return value;
    //   }
    //   let val = (value / 1).toFixed(2).replace(".", ",");
    //   return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €";
    // },
    // updateGroupsIntoData() {
    //   this.updateGroupIntoData("personalDataBirth");
    // },
  },
  mounted() {
    // this.updateGroupsIntoData();
  },
  validators: {},
};
</script>

<style scoped>
.align-end {
  flex: 1 1 auto;
  align-self: flex-end;
  padding: 0px 0px 16px 5px;
  color: var(--color-text);
}
.form-control {
  display: flex;
  align-content: stretch;
  align-items: center;
}
</style>