var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showCourtageHistotie
    ? _c("div", [
        _vm.showStatisticsInvestment
          ? _c(
              "div",
              { staticClass: "label-value--list no-margin" },
              [
                _vm._m(0),
                _c("div", { staticClass: "label-value--item" }, [
                  _c("div", { staticClass: "label-value--item-label" }, [
                    _vm._v("Courtage Umsatz")
                  ]),
                  _c(
                    "div",
                    { staticClass: "label-value--item-value" },
                    [
                      _c("CurrencyLabel", {
                        attrs: { value: _vm.courtage.umsatz }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "label-value--item" }, [
                  _c("div", { staticClass: "label-value--item-label" }, [
                    _vm._v("Courtage Bestand")
                  ]),
                  _c(
                    "div",
                    { staticClass: "label-value--item-value" },
                    [
                      _c("CurrencyLabel", {
                        attrs: { value: _vm.courtage.bestand }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "label-value--item" }, [
                  _c("div", { staticClass: "label-value--item-label" }, [
                    _vm._v("Courtage Serviceentgelt")
                  ]),
                  _c(
                    "div",
                    { staticClass: "label-value--item-value" },
                    [
                      _c("CurrencyLabel", {
                        attrs: { value: _vm.courtage.serviceGebuer }
                      })
                    ],
                    1
                  )
                ]),
                !_vm.isFA
                  ? [
                      _c("div", { staticClass: "label-value--item" }, [
                        _c("div", { staticClass: "label-value--item-label" }, [
                          _vm._v("Courtage VWL")
                        ]),
                        _c(
                          "div",
                          { staticClass: "label-value--item-value" },
                          [
                            _c("CurrencyLabel", {
                              attrs: { value: _vm.courtage.vwl }
                            })
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "label-value--item" }, [
                        _c("div", { staticClass: "label-value--item-label" }, [
                          _vm._v("Courtage Managemententgelt")
                        ]),
                        _c(
                          "div",
                          { staticClass: "label-value--item-value" },
                          [
                            _c("CurrencyLabel", {
                              attrs: { value: _vm.courtage.management }
                            })
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "label-value--item" }, [
                        _c("div", { staticClass: "label-value--item-label" }, [
                          _vm._v("Courtage Verwaltungsgebühr")
                        ]),
                        _c(
                          "div",
                          { staticClass: "label-value--item-value" },
                          [
                            _c("CurrencyLabel", {
                              attrs: { value: _vm.courtage.verwaltungsgebuehr }
                            })
                          ],
                          1
                        )
                      ])
                    ]
                  : _vm._e(),
                _c("div", { staticClass: "label-value--item" }, [
                  _c("div", { staticClass: "label-value--item-label" }, [
                    _vm._v("Courtage Gesamt")
                  ]),
                  _c(
                    "div",
                    { staticClass: "label-value--item-value" },
                    [
                      _c("CurrencyLabel", {
                        attrs: { value: _vm.courtage.gesamt }
                      })
                    ],
                    1
                  )
                ])
              ],
              2
            )
          : _vm._e(),
        _vm.showStatisticsVersicherung
          ? _c("div", { staticClass: "label-value--list no-margin mt-3" }, [
              _vm._m(1),
              _c("div", { staticClass: "label-value--item" }, [
                _c("div", { staticClass: "label-value--item-label" }, [
                  _vm._v("Courtage Umsatz")
                ]),
                _c(
                  "div",
                  { staticClass: "label-value--item-value" },
                  [
                    _c("CurrencyLabel", {
                      attrs: { value: _vm.courtageVers.umsatz }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "label-value--item" }, [
                _c("div", { staticClass: "label-value--item-label" }, [
                  _vm._v("Courtage Bestand")
                ]),
                _c(
                  "div",
                  { staticClass: "label-value--item-value" },
                  [
                    _c("CurrencyLabel", {
                      attrs: { value: _vm.courtageVers.bestand }
                    })
                  ],
                  1
                )
              ])
            ])
          : _vm._e(),
        !_vm.$isSmallScreen
          ? _c("div", { staticClass: "bottom-row" }, [
              _c(
                "div",
                { staticClass: "text-right" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/home/courtagestatistik" } },
                    [_vm._v("Courtagen anzeigen")]
                  )
                ],
                1
              )
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("Investmentabschlüsse")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("Versicherungen")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }