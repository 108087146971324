var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.$appNavigation.currentMenu.parent.label,
          subtitle: _vm.$appNavigation.currentMenu.label,
          defaultMenu: _vm.$appNavigation.currentTabMenu
        }
      }),
      _vm.lagerstellen && _vm.lagerstellen.length
        ? _c("BaseFilter", {
            attrs: {
              filterId: "ad59a8c1-0a61-482a-8471-fbe6f092ba5f",
              title: "Konditionen filtern",
              metadata: _vm.filterMetadata
            },
            on: {
              onFilter: function($event) {
                return _vm.filterForm($event)
              }
            }
          })
        : !_vm.lagerstellen || !_vm.lagerstellen.length
        ? _c("GhostLoading", {
            attrs: {
              type: "block",
              config: { block: { height: 100 } },
              useBoxContainer: ""
            }
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "box__container",
          attrs: { tid: "489796bd-259f-424b-80e5-f83526c042dd" }
        },
        [
          !_vm.loading && _vm.tableRows.length
            ? _c("Table", {
                staticClass: "table-container",
                attrs: {
                  tableId: "ded35895-8a4a-4d56-ad56-45ad956c3a8b",
                  title: _vm.TABLE_TITLE,
                  headers: _vm.tableHeaders,
                  rows: _vm.tableRows,
                  rowsPerPage: 20,
                  count: _vm.resultCount,
                  exportConfig: { roottext: _vm.TABLE_TITLE }
                },
                on: {
                  action: function($event) {
                    return _vm.executeAction($event)
                  }
                }
              })
            : _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", title: _vm.TABLE_TITLE }
              })
            : _c("NoData", { attrs: { title: _vm.TABLE_TITLE } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }