import INFOBRIEFE_TYPES from './types';
import { getInitialState } from './index'
import Vue from 'vue';

export default {
  [INFOBRIEFE_TYPES.MUTATIONS.RETRIEVE_INFOBRIEFE_SUCCESS](state, payload) {
    state.infobriefeData = payload;
    Vue.set(state, 'infobriefeData', payload);
  }, 
  [INFOBRIEFE_TYPES.MUTATIONS.RESET_STATE](state, payload) {
    Object.assign(state, getInitialState())
  }, 
  [INFOBRIEFE_TYPES.MUTATIONS.FREIGEBEN_SUCCESS](state, payload) {

    let changedRow = state?.infobriefeData?.rows?.find(i => i.fileName == payload );

    if (changedRow) {
      changedRow.freigabe = !changedRow.freigabe;
    }
  }, 

  
}