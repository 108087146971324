var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.renderComponent
      ? _c(
          "div",
          { staticClass: "container" },
          [
            _vm._l(_vm.stepsComputed, function(step, index) {
              return _c(
                "div",
                {
                  key: "nav" + index + _vm.lastUpdated,
                  staticClass: "stepper-row"
                },
                [
                  _c(
                    "div",
                    { staticClass: "box__container" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "stepper-section",
                          on: {
                            click: function($event) {
                              return _vm.onClickStep(step.stepKey)
                            }
                          }
                        },
                        [
                          _vm.getSubsteps(step).length <= 1
                            ? _c(
                                "div",
                                { staticClass: "stepper-section__arrow" },
                                [_c("PhCaretRight", { attrs: { size: 20 } })],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "stepper-section__label" },
                            [
                              _vm.stepperStatus(step) === "default"
                                ? _c("ph-circle", {
                                    staticClass: "color-primary status__icon",
                                    attrs: { size: 16 }
                                  })
                                : _vm.stepperStatus(step) === "success"
                                ? _c("ph-check-circle", {
                                    staticClass: "color-success status__icon",
                                    attrs: { size: 16 }
                                  })
                                : _vm.stepperStatus(step) === "warning"
                                ? _c("ph-warning-circle", {
                                    staticClass: "color-danger status__icon",
                                    attrs: { size: 16 }
                                  })
                                : _vm._e(),
                              _c("div", {
                                class: [
                                  "clickable",
                                  {
                                    "font-bold":
                                      _vm.selectedStepKey == step.stepKey
                                  },
                                  { "substep-sm": _vm.isMobileNativeContext }
                                ],
                                domProps: {
                                  innerHTML: _vm._s(_vm.sanitize(step.label))
                                }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _vm.getSubsteps(step).length > 1
                        ? _vm._l(_vm.getSubsteps(step), function(
                            substep,
                            subIndex
                          ) {
                            return _c(
                              "span",
                              {
                                key: "sub" + subIndex + _vm.lastUpdated,
                                staticClass: "stepper-substep",
                                on: {
                                  click: function($event) {
                                    return _vm.onClickSubstep(
                                      step.stepKey,
                                      substep.substepKey
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "line-separator-substeps",
                                    class: [
                                      "clickable",
                                      {
                                        "font-bold":
                                          _vm.selectedSubstepKey ==
                                          substep.stepKey
                                      },
                                      {
                                        "substep-sm": _vm.isMobileNativeContext
                                      }
                                    ]
                                  },
                                  [
                                    _vm.showLastSubstep(index, step) &&
                                    !substep.hidden
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "substep-label",
                                            class: {
                                              "substep-label__warning": _vm.hasSubstepWarning(
                                                substep
                                              )
                                            }
                                          },
                                          [
                                            _vm.hasSubstepWarning(substep)
                                              ? _c("ph-warning-circle", {
                                                  staticClass:
                                                    "color-danger substep-warning__icon",
                                                  attrs: { size: 12 }
                                                })
                                              : _vm._e(),
                                            _c("span", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.sanitize(substep.title)
                                                )
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "substep-arrow",
                                        class: [
                                          "btn-clear",
                                          "d-block",
                                          "d-lg-none"
                                        ],
                                        attrs: { type: "button" }
                                      },
                                      [
                                        _c("PhCaretRight", {
                                          attrs: { size: 20 }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]
                            )
                          })
                        : _vm._e()
                    ],
                    2
                  )
                ]
              )
            })
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }