var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Table", {
        attrs: {
          tableId: "ccdb782e-dc29-40ec-973f-eb5ee6bd4f4f",
          headers: _vm.headers,
          rows: _vm.rows,
          rowId: "name",
          hidePagination: ""
        },
        scopedSlots: _vm._u([
          {
            key: "value",
            fn: function(row) {
              return [
                _c("KennzahlenValue", {
                  attrs: { value: row.value, type: row.type }
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }