var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "antrag-components__container" }, [
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c(
          "div",
          { staticClass: "row" },
          [
            _c("ComboBox", {
              attrs: {
                label: "DAB Depotnummer",
                values:
                  _vm.comboboxValues && _vm.comboboxValues.depotnr
                    ? _vm.comboboxValues.depotnr
                    : []
              },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { depotnr: $event })
                }
              },
              model: {
                value: _vm.form.depotnr,
                callback: function($$v) {
                  _vm.$set(_vm.form, "depotnr", $$v)
                },
                expression: "form.depotnr"
              }
            }),
            _vm.form.depotnr === "freie Eingabe:"
              ? _c("InputField", {
                  staticClass: "input-fix",
                  on: {
                    change: function($event) {
                      return _vm.$emit("save", { depotnrText: $event })
                    }
                  },
                  model: {
                    value: _vm.form.depotnrText,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "depotnrText", $$v)
                    },
                    expression: "form.depotnrText"
                  }
                })
              : _vm._e(),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("FormLabel", {
                  attrs: {
                    label:
                      "Bitte tragen Sie hier die Daten des Depots ein, von dem die Wertpapiere übertragen werden sollen.",
                    config: { bold: true }
                  }
                })
              ],
              1
            ),
            _c("FormDivider", { staticClass: "col-12" }),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("FormLabel", {
                  attrs: {
                    label: "Daten der Empfängerbank",
                    config: { bold: true }
                  }
                })
              ],
              1
            ),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Name" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bankName: $event })
                }
              },
              model: {
                value: _vm.form.bankName,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bankName", $$v)
                },
                expression: "form.bankName"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Depotnummer" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { empfangDepotnr: $event })
                }
              },
              model: {
                value: _vm.form.empfangDepotnr,
                callback: function($$v) {
                  _vm.$set(_vm.form, "empfangDepotnr", $$v)
                },
                expression: "form.empfangDepotnr"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "BLZ" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bankBLZ: $event })
                }
              },
              model: {
                value: _vm.form.bankBLZ,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bankBLZ", $$v)
                },
                expression: "form.bankBLZ"
              }
            }),
            _c("InputIbanBic", {
              staticClass: "col-12 col-md-6",
              attrs: {
                label: "BIC (nur Ausland)",
                type: "BIC",
                componentData: _vm.form.bankBIC
              },
              on: {
                input: function($event) {
                  return _vm.$emit("save", { bankBIC: $event })
                }
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Ansprechpartner (nur Ausland)" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bankAnsprech: $event })
                }
              },
              model: {
                value: _vm.form.bankAnsprech,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bankAnsprech", $$v)
                },
                expression: "form.bankAnsprech"
              }
            }),
            _c("FormDivider", { staticClass: "col-12" }),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("FormLabel", {
                  attrs: {
                    label: "Daten des Empfängers",
                    config: { bold: true }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("FormLabel", {
                  attrs: { label: "Empfänger 1", config: { bold: true } }
                })
              ],
              1
            ),
            _c("InputRadioBoxGroup", {
              staticClass: "col-12 col-md-6",
              attrs: {
                value: _vm.form.anrede,
                values: [
                  { value: "Herr", label: "Herr" },
                  { value: "Frau", label: "Frau" }
                ]
              },
              on: {
                input: function($event) {
                  return _vm.$emit("save", { anrede: $event })
                }
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Vorname" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { empfangVorname1: $event })
                }
              },
              model: {
                value: _vm.form.empfangVorname1,
                callback: function($$v) {
                  _vm.$set(_vm.form, "empfangVorname1", $$v)
                },
                expression: "form.empfangVorname1"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Name" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { empfangName1: $event })
                }
              },
              model: {
                value: _vm.form.empfangName1,
                callback: function($$v) {
                  _vm.$set(_vm.form, "empfangName1", $$v)
                },
                expression: "form.empfangName1"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Straße, Hausnummer" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { strasse1: $event })
                }
              },
              model: {
                value: _vm.form.strasse1,
                callback: function($$v) {
                  _vm.$set(_vm.form, "strasse1", $$v)
                },
                expression: "form.strasse1"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "PLZ" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { plz1: $event })
                }
              },
              model: {
                value: _vm.form.plz1,
                callback: function($$v) {
                  _vm.$set(_vm.form, "plz1", $$v)
                },
                expression: "form.plz1"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Ort" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { Ort1: $event })
                }
              },
              model: {
                value: _vm.form.Ort1,
                callback: function($$v) {
                  _vm.$set(_vm.form, "Ort1", $$v)
                },
                expression: "form.Ort1"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Steuer-Identifikationsnnummer (TIN)" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { tin1: $event })
                }
              },
              model: {
                value: _vm.form.tin1,
                callback: function($$v) {
                  _vm.$set(_vm.form, "tin1", $$v)
                },
                expression: "form.tin1"
              }
            }),
            _c("DatePickerField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Geburtsdatum", isValueAsString: "" },
              on: {
                input: function($event) {
                  return _vm.$emit("save", { geburtsdatum1: $event })
                }
              },
              model: {
                value: _vm.form.geburtsdatum1,
                callback: function($$v) {
                  _vm.$set(_vm.form, "geburtsdatum1", $$v)
                },
                expression: "form.geburtsdatum1"
              }
            }),
            _c("ComboBox", {
              staticClass: "col-12 col-md-6",
              attrs: {
                label: "Verwandschaftsverhältnis zum Auftraggeber",
                firstEmpty: true,
                values:
                  _vm.comboboxValues && _vm.comboboxValues.verwandschaft
                    ? _vm.comboboxValues.verwandschaft
                    : []
              },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { verwandschaft1: $event })
                }
              },
              model: {
                value: _vm.form.verwandschaft1,
                callback: function($$v) {
                  _vm.$set(_vm.form, "verwandschaft1", $$v)
                },
                expression: "form.verwandschaft1"
              }
            }),
            _c("FormDivider", { staticClass: "col-12" }),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("FormLabel", {
                  attrs: { label: "Empfänger 2", config: { bold: true } }
                })
              ],
              1
            ),
            _c("InputRadioBoxGroup", {
              staticClass: "col-12 col-md-6",
              attrs: {
                value: _vm.form.anrede2,
                values: [
                  { value: "Herr", label: "Herr" },
                  { value: "Frau", label: "Frau" }
                ]
              },
              on: {
                input: function($event) {
                  return _vm.$emit("save", { anrede2: $event })
                }
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Vorname" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { empfangVorname2: $event })
                }
              },
              model: {
                value: _vm.form.empfangVorname2,
                callback: function($$v) {
                  _vm.$set(_vm.form, "empfangVorname2", $$v)
                },
                expression: "form.empfangVorname2"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Name" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { empfangName2: $event })
                }
              },
              model: {
                value: _vm.form.empfangName2,
                callback: function($$v) {
                  _vm.$set(_vm.form, "empfangName2", $$v)
                },
                expression: "form.empfangName2"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Straße, Hausnummer" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { strasse2: $event })
                }
              },
              model: {
                value: _vm.form.strasse2,
                callback: function($$v) {
                  _vm.$set(_vm.form, "strasse2", $$v)
                },
                expression: "form.strasse2"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "PLZ" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { plz2: $event })
                }
              },
              model: {
                value: _vm.form.plz2,
                callback: function($$v) {
                  _vm.$set(_vm.form, "plz2", $$v)
                },
                expression: "form.plz2"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Ort" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { Ort2: $event })
                }
              },
              model: {
                value: _vm.form.Ort2,
                callback: function($$v) {
                  _vm.$set(_vm.form, "Ort2", $$v)
                },
                expression: "form.Ort2"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Steuer-Identifikationsnnummer (TIN)" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { tin2: $event })
                }
              },
              model: {
                value: _vm.form.tin2,
                callback: function($$v) {
                  _vm.$set(_vm.form, "tin2", $$v)
                },
                expression: "form.tin2"
              }
            }),
            _c("DatePickerField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Geburtsdatum", isValueAsString: "" },
              on: {
                input: function($event) {
                  return _vm.$emit("save", { geburtsdatum2: $event })
                }
              },
              model: {
                value: _vm.form.geburtsdatum2,
                callback: function($$v) {
                  _vm.$set(_vm.form, "geburtsdatum2", $$v)
                },
                expression: "form.geburtsdatum2"
              }
            }),
            _c("ComboBox", {
              staticClass: "col-12 col-md-6",
              attrs: {
                label: "Verwandschaftsverhältnis zum Auftraggeber",
                firstEmpty: true,
                values:
                  _vm.comboboxValues && _vm.comboboxValues.verwandschaft
                    ? _vm.comboboxValues.verwandschaft
                    : []
              },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { verwandschaft2: $event })
                }
              },
              model: {
                value: _vm.form.verwandschaft2,
                callback: function($$v) {
                  _vm.$set(_vm.form, "verwandschaft2", $$v)
                },
                expression: "form.verwandschaft2"
              }
            })
          ],
          1
        ),
        _c(
          "BaseButton",
          {
            on: {
              click: function($event) {
                return _vm.$emit("next")
              }
            }
          },
          [_vm._v("Weiter")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }