<template>
  <div class="input-forms__container">
    <div class="input-forms__label-container" >
      <FormLabel :label="label" :config="config"/>

      <div v-if="config && config.subtitle">
        {{config.subtitle}}
      </div>

      <div class="input-forms__input-container mt-4 mb-2">
        <SortableList :items="items" @orderChanged="onOrderChanged($event)">
          <ul class="list-bordered" data-sortable-container>
            <li v-for="(item) in items" v-bind:key="item.key" class="list-bordered-item" data-sortable-item>
              <div class="d-flex justify-content-between">
                <div class="config-column-item--title">
                  <div class="d-flex align-items-center">
                    <InputToggleSwitch v-if="useCheckBox" :value="item.visible" @input="changeVisibility(item, $event)" class="p-0" />
                    <span>{{item.label}}</span>
                  </div>
                </div>
                <div class="config-column-item--actions">
                  <div class="d-flex">
                    <InputRadioBoxGroup
                      v-if="!useCheckBox"
                      :value="item.visible"
                      :values="visibilityOptions"
                      @input="changeVisibility(item, $event)"
                      class="pb-0 mr-3"/>
                    <div data-sortable-item-handler><ph-list :size="16" weight="bold" /></div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </SortableList>
      </div>
    </div>
  </div>
</template>

<script>
import FormLabel from '@/components/core/forms/FormLabel.vue'
import SortableList from '@/components/core/SortableList.vue'
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue'
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue'
import { sanitize } from '@/helpers/string-helper.js';
import { PhList } from 'phosphor-vue'

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: null,
    },
    componentFields: {
      type: Array,
      default: () => [],
    },
    isComponentHalfSize: {
      type: Boolean,
      default: false,
    },
    useCheckBox: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    config: {
    }
  },
  data() {
    return {
      items: null
    }
  },
  watch: {
    value() {
      this.updateItems()
    }
  },
  computed: {
    visibilityOptions() {
      return [
        { label: 'Ja', value: true },
        { label: 'Nein', value: false },
      ]
    },
    itemStringify() {
      return this.items ? JSON.stringify(this.items) : null
    },
    valueParse() {
      return this.value ? JSON.parse(this.value) : null
    }
  },
  methods: {
    sanitize(htmlString) {
      return sanitize(htmlString);
    },
    onOrderChanged(items) {
      this.items = items ? items.map((item, index) => ({ ...item, position: index + 1 })) : null;

      this.emitCurrentItems()
    },
    emitCurrentItems() {
      this.$emit('input', this.itemStringify);
    },
    updateItems() {
      this.items = this.valueParse;
      this.items?.sort((a, b) => a.position - b.position);
    },
    changeVisibility(item, visibilityValue) {
      this.$set(item, 'visible', visibilityValue)
      this.emitCurrentItems()
    }
  },
  mounted() {
    this.updateItems();
  },
  components: {
    SortableList,
    PhList,
    FormLabel,
    InputRadioBoxGroup,
    InputToggleSwitch,
  },
}
</script>

<style scoped>
.config-column-item--actions {
  flex: 0 0 auto;
}
</style>
