<template>
  <BaseButton v-if="showButton" :isClear="showAsButton" :isLink="!showAsButton" @click="clickPhone()">
    <template v-if="showAsButton">
      <ph-phone v-if="!disabled" weight="fill" :size="size" />
      <ph-phone-x v-else :size="size" />
    </template>
    <template v-else>
      {{ callButtonLabel }}
    </template>
  </BaseButton>
</template>

<script>
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';
import LOG_TYPES from '@/store/log/types';
import WEBRTC_TYPES from '@/store/webrtc/types';
import { PhPhone, PhPhoneX } from 'phosphor-vue';
import { buildMessage } from '@/helpers/log-message-helper';
import ROLES, { VIEW_ROLES } from '@/router/roles';
import BaseButton from '@/components/core/BaseButton.vue';

export default {
  props: {
    size: {
      default: 18,
    },
    showAsButton: {
      default: true
    }
  },
  computed: {
    ...mapGetters({
      webrtcCustomer: CORE_TYPES.GETTERS.IS_WEBRTC_KUNDENZUGANG,
      webrtcBroker: CORE_TYPES.GETTERS.IS_WEBRTC_MAKLERZUGANG,
      isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      isActuallyBroker: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_BROKER,
      available: WEBRTC_TYPES.GETTERS.AVAILABLE,
      inCallWith: WEBRTC_TYPES.GETTERS.IN_CALL_WITH,
      calling: WEBRTC_TYPES.GETTERS.WE_ARE_CALLING_ID,
      openWith: WEBRTC_TYPES.GETTERS.OPENED_CHAT_BETEILIGTER_ID,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
    callButtonLabel() {
      if (this.$hasRoles([[VIEW_ROLES.VIEW_CUSTOMER_AS_BROKER]])) {
        return 'Kunde anrufen';
      } else {
        return 'Betreuer anrufen';
      }
    },
    showButton() {
      const isBypassSlash = this.hasRoles(ROLES.IS_BYPASS_SLASH) && !this.hasRoles([ROLES.IS_KUNDENZUGANG])
      const isCustomer = !isBypassSlash && this.hasRoles([VIEW_ROLES.VIEW_CUSTOMER_ONLY, VIEW_ROLES.VIEW_CUSTOMER_AS_BROKER, ROLES.IS_KUNDENZUGANG])
      const isBroker = this.hasRoles([VIEW_ROLES.VIEW_BROKER_ONLY])

      return (isCustomer && this.webrtcCustomer) ||
            (isBroker && this.webrtcBroker && this.openWith);
    },
    chatEnabled() {
        return this.hasRoles(ROLES.DATA_PRIVACY_APP);
    },
    disabled() {
      return this.inCallWith || this.calling || !this.available && !this.openWith;
    },
  },
  components: {
    PhPhone,
    PhPhoneX,
    BaseButton,
  },
  methods: {
    clickPhone() {
      this.$emit('click')
      if (this.hasRoles([ROLES.IS_KUNDENZUGANG])) {
        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('FinanceScreen ist über den Kundenzugang nicht möglich. Bitte nutzen Sie FinanceScreen über das smartMSC.', 'info'));
        return
      }
      
      if (this.disabled) {
        if (!this.available && !this.openWith) {

          if (this.chatEnabled) {
            this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Leider konnte kein Gesprächspartner gefunden werden. Bitte versuchen Sie es später nochmal.', 'info'));
          } else {
            const isCustomerOnly = this.hasRoles([VIEW_ROLES.VIEW_CUSTOMER_ONLY])
            if(isCustomerOnly) {
              this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Sie haben die Kommunikationsart “App / Kundenzugang” in der Datenschutzvereinbarung verweigert. Ein Anruf über FinanceScreen ist nicht möglich.', 'info'));
            } else {
              this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Der Kunde hat die Kommunikationsart “App / Kundenzugang” in der Datenschutzvereinbarung verweigert. Ein Anruf über FinanceScreen ist nicht möglich.', 'info'));
            }
          }

        }
        return;
      }
      if (this.openWith)
        this.$store.commit(WEBRTC_TYPES.MUTATIONS.TOGGLE_CALL_MODAL);
      else
        this.$store.dispatch(WEBRTC_TYPES.ACTIONS.OPEN_CALL_MODAL);
    },
  },
}
</script>

<style scoped>

</style>
