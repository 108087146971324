<template>
  <div class="navi_header_container">
    <div class="navi_header_horizontal_wrapper">
      <div class="logo">
        <a @click="goToFirstRoute"  v-if="logoURLProp">
          <img alt="Makler Logo" v-bind:src="logoURLProp" id="logo_image" @error="hideImage"/>
        </a>
      </div>
      <div class="navi">
        <div v-if="hasHome" class="navi_link home" :class="{navi_link_visited: isHomeVisited}" @click="gotoRoute(getHomeLink())" ><a>Home</a>
        </div>
        <div v-if="hasFondsshop"  class="navi_link fondsshop" :class="{navi_link_visited: isFondsshopVisited}" @click="gotoRoute('fondsshop')">
          <a>Fondsshop</a>
        </div>
        <div  v-if="hasSofunktionierts" 
          class="navi_link so_funktionierts"
          :class="{navi_link_visited: isSofunktioniertsVisited}" 
          @click="gotoRoute('sofunktionierts')"
        >
          <a>So funktioniert's</a>
        </div>
        <div  v-if="hasModellportfolios" 
          class="navi_link modellportfolios"
          :class="{navi_link_visited: isModellportfoliosVisited}"
          @click="gotoRoute('modellportfolios')"
        >
          <a>Modellportfolios</a>
        </div>
        <div  v-if="hasEinzelfonds" class="navi_link einzelfonds" :class="{navi_link_visited: isEinzelfondsVisited}" @click="gotoRoute('einzelfonds')">
          <a>Einzelfonds</a>
        </div>
        <div  v-if="hasDetails" class="navi_link details" :class="{navi_link_visited: isDetailsVisited}"  @click="gotoRoute('details')">
          <a>Details</a>
        </div>
        <div  v-if="hasRoutes && brokerInfo.maklerHomepage" class="navi_link homepage" :class="{navi_link_visited: isHomepageVisited}">
          <a :href="brokerInfo.maklerHomepage">Homepage</a>
        </div>
      </div>
      <BaseFullscreenMenu v-if="hasRoutes" class="hamburger" ref="baseFullscreenMenu" :icon="burgerMenuIcon">
        <div v-if="hasHome" class="vert_link" @click="gotoRouteModal(getHomeLink())"> 
          <a> Home</a>
        </div>
        <div v-if="hasFondsshop" class="vert_link"  @click="gotoRouteModal('fondsshop')">
          <a>Fondsshop</a>
        </div>
        <div v-if="hasSofunktionierts" class="vert_link" @click="gotoRouteModal('sofunktionierts')">
          <a>So funktioniert's</a>
        </div>
        <div v-if="hasModellportfolios" class="vert_link" @click="gotoRouteModal('modellportfolios')">
          <a>Modellportfolios</a>
        </div>
        <div v-if="hasEinzelfonds" class="vert_link" @click="gotoRouteModal('einzelfonds')">
          <a>Einzelfonds</a>
        </div>
        <div v-if="hasDetails" class="vert_link" @click="gotoRouteModal('details')">
          <a>Details</a>
        </div>
        <div v-if="hasRoutes && brokerInfo.maklerHomepage && isCmsFondsshopOhneHomeSeite" 
            class="vert_link" @click="gotoRouteModal(brokerInfo.maklerHomepage)">
          <a>Homepage</a>
        </div>
      </BaseFullscreenMenu>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import CMS_TYPES from "@/store/cms/types";
import CORE_TYPES from '@/store/core/types';
import BaseFullscreenMenu from '@/components/core/BaseFullscreenMenu.vue'
import { PhList } from "phosphor-vue"

export default {
  components: {
    BaseFullscreenMenu,
  },
  props:{
      logoURLProp:{
          default:"",
          required:true,
      },
      availableRoutes:{
          type:Array,
          default: () => [],
          required:true
      },
  },
  data() {
    return {
      visited: null,
      openLinks: false,
      notError:true,
    };
  },
  computed: {
    ...mapGetters({
      firstPageRoute: CMS_TYPES.GETTERS.GET_CMS_FIRST_PAGE_ROUTE,
      brokerInfo: CORE_TYPES.GETTERS.GET_BROKER_INFORMATION,
      isCmsFondsshopOhneHomeSeite: CORE_TYPES.GETTERS.ALLOW_CMS_FONDSSHOP_OHNE_HOME_SEITE,

    }),
    burgerMenuIcon() {
      return PhList
    },
    getNotError(){
      return this.notError;
    },
    isHomeVisited() {
      return this.visited && (this.visited.includes("/home") || this.visited == "/" || this.visited == "//" || this.visited == "" || this.visited === this.getHomeLink());
    },
    isFondsshopVisited() {
      return this.visited && (this.visited.includes("/fondsshop") || (!this.availableRoutes.includes("/") && this.visited && (this.visited == "/" || this.visited == "//" || this.visited == "")));
    },
    isSofunktioniertsVisited() {
      return this.visited && this.visited.includes("/sofunktionierts");
    },
    isModellportfoliosVisited() {
      return this.visited && this.visited.includes("/modellportfolios");
    },
    isEinzelfondsVisited() {
      return this.visited && this.visited.includes("/einzelfonds");
    },
    isDetailsVisited() {
      return this.visited && this.visited.includes("/details");
    },
    isHomepageVisited() {
      return this.visited && this.visited.includes(this.brokerInfo.maklerHomepage);
    },
    
    hasHome() {
      return this.availableRoutes.includes("/");
    },
    hasFondsshop() {
      return this.availableRoutes.includes("fondsshop");
    },
    hasSofunktionierts() {
      return this.availableRoutes.includes("sofunktionierts");
    },
    hasModellportfolios() {
      return this.availableRoutes.includes("modellportfolios");
    },
    hasEinzelfonds() {
      return this.availableRoutes.includes("einzelfonds") && this.brokerInfo.fsFondsAnzeigen;
    },
    hasDetails() {
      return this.availableRoutes.includes("details");
    },
    hasRoutes(){
        return this.availableRoutes.length>0;
    }
  },
  watch: {
    "$route.path": function(path) {
      let exp = /\/page[\/]?([\d]{5,})?[\/]?/;
      let found = path.replace(exp, "");
      this.setVisited('/' + found);
    }
  },
  methods: {
    getHomeLink() {
      const brokerIdFromUrl = this.$route.params.id || this.$route.params.broker_id;
      return brokerIdFromUrl ? `/page/${brokerIdFromUrl}/home` : '/';
    },
    hideImage(){
      this.notError=false;
    },
    setVisited(value) {
      this.visited = value;
    },
    goToFirstRoute() {
       let firstR = this.firstPageRoute.substring(1);
     if (this.$route.path.split('/').pop()===firstR) {
        window.scrollTo(0, 0);
      }
      else this.$router.push(firstR);
    },
    toggleOpenLinks() {
      this.openLinks = !this.openLinks;
    },
    gotoRoute(route){
      if (this.$route.path.split('/').pop()===route) {
        window.scrollTo(0, 0);
      }
      else this.$router.push(route);
    },
    gotoRouteModal(route){
      if (this.$route.path.split('/').pop()===route) {
        window.scrollTo(0, 0);
      }
      else this.$router.push(route);
      this.$refs.baseFullscreenMenu.closeModal();
    }
  },
  mounted(){
    let mountroute = this.$route.path.split('/').pop();
    this.setVisited("/"+mountroute);
  }
};
</script>
<style scoped>
.navi_header_container {
  max-width: 1200px;
  height:124px;
  margin: 0 auto;
  padding-left: 1em;
  padding-right: 1em;
}

.navi_header_horizontal_wrapper {
  height: 124px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navi_header_horizontal_wrapper .logo img {
  max-height: 124px;
  max-width: 240px;
}

.navi {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  gap: 1em;
}
.logo {
  text-align: start;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.home {
  grid-area: home;
}
.fondsshop {
  grid-area: fondsshop;
}
.so_funktionierts {
  grid-area: so_funktionierts;
}
.modellportfolios {
  grid-area: modellportfolios;
}
.einzelfonds {
  grid-area: einzelfonds;
}

.details {
  grid-area: details;
}
.homepage {
  grid-area: homepage;
}

.home {
  grid-area: home;
}
.streitbeilegung {
  grid-area: streitbeilegung;
  justify-self: start;
}

.navi_link a {
  color: var(--color-workspaces-nav-text);
  font-size: 1.14em;
  text-decoration: none;
  white-space: nowrap;
}

.navi_link_visited a {
  font-weight: bold;
  color: var(--color-workspaces-nav-active);
}

.hamburger {
  display: none;
  grid-area: hamburger;
}

.vert_link {
  padding-top: 2em;
}
.vert_link a {
  text-decoration: none;
  margin-top: 1em;
  margin-bottom: 1em;
  color: var(--color-top-nav-text);
}


@media only screen and (max-width: 1024px) {
  .navi_header_horizontal_wrapper {
    grid-template-columns: 6fr 1fr;
    grid-template-areas: "logo hamburger";
  }
  .logo {
    justify-self: start;
  }

  #logo_image {
    height: 60%;
    width: 60%;
  }

  .navi {
    display: none;
  }

  .hamburger {
    display: inline;
  }

  .streitbeilegung {
    justify-self: center;
  }
}
</style>