var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.fields.length
    ? _c(
        "div",
        { staticClass: "mb-3" },
        [
          _c("Fields", {
            ref: "refFields",
            attrs: {
              fields: _vm.fields,
              valuesProp: _vm.values,
              combos: _vm.combos,
              tableData: _vm.tableData,
              rowsCount: _vm.rowsCount,
              isNotReactive: _vm.isNotReactive,
              validators: _vm.validators,
              actionKeys: "delete,duplicate,wiedervorlage"
            },
            on: {
              onBtClick: _vm.onBtClick,
              gotoEdit: _vm.gotoEdit,
              doAction: _vm.doAction
            }
          }),
          _c(
            "BaseModal",
            {
              ref: "modalMessage",
              attrs: {
                modalTitle: _vm.modalArg.title,
                showConfirmButton: _vm.modalArg.showConfirmButton,
                labelButtonConfirm: _vm.modalArg.labelButtonConfirm,
                labelButtonCancel: _vm.modalArg.labelButtonCancel
              },
              on: { onConfirmButton: _vm.modalArg.onConfirm }
            },
            [
              _c("div", {
                domProps: { innerHTML: _vm._s(_vm.sanitize(_vm.modalArg.body)) }
              })
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }