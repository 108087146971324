<template>
  <div class="input-forms__container">
    <div class="input-forms__label-container">
      <div class="row">
        <div class="col-12">
            <InputField
              label="Aktuelles Depot"
              type="text"
              :isEmbedded="true" 
              :value="valueForLabel" 
              :disabled="true"
              />
          <div class="row">
            <div class="col-12 col-xl-8">
              <ComboBox
                label="Für Wertpapier"
                v-model="depotPosition" 
                :values="valuesForDepot"
                >
              </ComboBox>
            </div>
            <div class="col-12 col-xl-4 mt-3 pl-2">
              <BaseButton :isEmbedded="true" :disabled="!depotPosition" @click="addPosition()">Fonds hinzufügen</BaseButton>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="input-forms__input-container">
      <Table
        v-if="tableData && tableData.records && tableData.records.length"
        :tableData="tableData"
        :cardViewEnabled="false"
        :filterEnabled="false"
        :exportEnabled="false"
        :paginationEnabled="false"
        :actions="columnActions"
        :rowsPerPage="tableData.records.length"
        @execute-action="handleTableAction($event)"
      >
        <template v-slot:isWert="props">       
          <ComboBox
            :values="anlageValues"
            :value="props.data.row.isWert"
            :disabled="props.data.row.allesVerkaufen"
            @input="onChangeInputField($event, props.data.row, 'isWert')"/>
        </template>

        <template v-slot:betrag="props">
          <InputField  v-if="props.data.row.isWert === true"
            type="currency" 
            :value="props.data.row.wert"
            :disabled="props.data.row.allesVerkaufen"
            :id="'wert' + props.data.row.index"
            @input="onChangeInputField($event, props.data.row, 'wert', props.data)"/>
          <InputField v-if="!(props.data.row.isWert === true)"
            type="number" 
            :value="props.data.row.anteile"
            :disabled="props.data.row.allesVerkaufen"
            :id="'anteile' + props.data.row.index"
            @input="onChangeInputField($event, props.data.row, 'anteile')"/>
        </template>

        <template v-slot:allesVerkaufen="props">
            <InputToggleSwitch
            :value="props.data.row.allesVerkaufen"
            :id="'allesVerkaufen' + props.data.row.index"
            @input="onChangeInputField($event, props.data.row, 'allesVerkaufen')"/>
        </template>

       <template v-slot:sparplaeneLoeschen="props">       
            <InputToggleSwitch
            :id="'sparplaeneLoeschen' + props.data.row.index"
            @input="onChangeInputField($event, props.data.row, 'sparplaeneLoeschen')"/>
        </template>
      </Table>

  <DeletePositionModal ref="deleteModal" :position="positionToDelete"
    @delete="doDeletePosition()"></DeletePositionModal>

    </div>
  </div>
</template>

<script>
import ANTRAG_TYPES from '@/store/antrag/types';
import FONDSFINDER_TYPES from '@/store/fondsfinder/types';
import BaseButton from '@/components/core/BaseButton.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputField from '@/components/core/forms/InputField.vue';
import antragNavigationMixin from '@/mixins/antrag/antrag-navigation-mixin.js';
import BaseModal from '@/components/core/BaseModal.vue';
import Table from "@/components/table/Table.vue"
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import WertpapierAuswahlPositionFields from '@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlPositionFields.vue';
import DeletePositionModal from '@/components/core/forms/antraege/wertpapierAuswahl/DeletePositionModal.vue'
import { mapGetters } from 'vuex';
import antragMixin from '@/mixins/antrag/antrag-mixin.js';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';

export default {
  mixins: [antragNavigationMixin, antragMixin],
  props: {
    antragData: {
    },
    config: {
    },
    antragTyp: {
    },
    antragId: {
    },
    comboboxSelections: {
    },
    categoryId: {
    },
    suppressFilterLagerstelle: {
      type: Boolean,
      default: false
    },
    warnings: {
    },
  },
  components: {
    BaseButton,
    BaseModal,
    ComboBox,
    InputField,
    Table,
    DeletePositionModal,
    WertpapierAuswahlPositionFields,
    InputRadioBoxGroup,
    InputToggleSwitch
  },
  data() {
    return {
      depotPosition: '',
      disabled: false,
      columnActions : [
        {
          legend: {
            icon: 'PhTrash',
            index: 1,
            key: 'DELETE',
            label: 'Löschen',
          }
        }
      ],
      positionToDelete : {},
      anlageValues: [   
        {label: 'in Anteilen', value: false},
        {label: 'in EUR', value: true}],
    }
  },
  computed: {
    ...mapGetters({
      positionInfo: FONDSFINDER_TYPES.GETTERS.POSITION_INFO,
      positionsAll: ANTRAG_TYPES.GETTERS.POSITIONS,
      positionDataAll: ANTRAG_TYPES.GETTERS.POSITION_DATA
    }),
    tableData() {
      const result = {
        headers: {
          isin: {
            label: "ISIN",
            key: "isin",
            dataType: "String",
            visible: true,
            sum: false,
            fixed: true,
          },
          fondsname: {
            label: "Fondsname",
            key: "fondsname",
            dataType: "String",
            visible: true,
            sum: false,
            fixed: true,
          },
          isWert: {
            label: "Verkauf",
            key: "isWert",
            dataType: "Slot",
            visible: true,
            sum: false,
            fixed: true,
          },
          betrag: {
            label: "Betrag/Anzahl",
            key: "betrag",
            dataType: "Slot",
            dataSymbol: this.isWert? "€" : "",
            visible: true,
            sum: false,
            fixed: true,
          },
          allesVerkaufen: {
            label: "Alles verkaufen",
            key: "allesVerkaufen",
            dataType: "Slot",
            visible: true,
            sum: false,
            fixed: true,
          },
          sparplaeneLoeschen: {
            label: "bestehende Sparpläne löschen",
            key: "sparplaeneLoeschen",
            dataType: "Slot",
            visible: true,
            sum: false,
            fixed: true,
          },
          actions: {
            label: "",
            key: "actions",
            priority: 1,
            dataType: "tableAction",
            visible: true,
            sortable: false,
            filterable: false,
            sum: false,
            fixed: true,
          }
        },
        records: this.positions && this.positions.map((pos, index) => Object.assign(pos, {index: index, fondsname: this.getFondsName(pos, this.positionInfo), lagerstelle: 'FODB'})),
      }
      return result;
    },
    positions() {
      if (this.positionsAll && this.antragId && this.categoryId 
          && this.positionsAll[this.antragId] 
          && this.positionsAll[this.antragId][this.categoryId]) {
        return this.positionsAll[this.antragId][this.categoryId]
      }
    },
    positionData() {
      if (this.positionDataAll && this.antragId && this.categoryId 
          && this.positionDataAll[this.antragId]
          && this.positionDataAll[this.antragId][this.categoryId]) {
        return this.positionDataAll[this.antragId][this.categoryId]
      }
    },
    valuesForDepot() {
      if(this.antragData['INPUT_DEPOTNUMMER'] == "Bitte auswählen...")
        return ["kein Depot ausgewählt"]
      else {
        return !this.antragData['INPUT_DEPOTNUMMER'] ? [] : this.comboboxSelections.depotPositions[this.antragData['INPUT_DEPOTNUMMER']];
      }
    },
    valueForLabel() {
      if(this.antragData['INPUT_DEPOTNUMMER'] == "Bitte auswählen...")
        return "keine Einstellung"
      else
        return !this.antragData['INPUT_DEPOTNUMMER'] ? "" : this.antragData['INPUT_DEPOTNUMMER'];
    },
  },
  methods: {
    addPosition() {
      const positionInfo = this.valuesForDepot.filter(info => info.value == this.depotPosition)
      const payload = {
          antragTyp: this.antragTyp || null,
          antragId: this.antragId || null,
          categoryId: this.categoryId,
          saveType: '',
          positionInfo,
          checkPositions: {
            lagerstelle: 'FODB',
            isins: {
              [positionInfo[0].isin]: this.categoryId
            }
          }
        }
      this.$store.dispatch(FONDSFINDER_TYPES.ACTIONS.TAKE_SELECTED_ISINS, payload)
    },
    commitUpdatePositionsFromEvent(positionChange, isin) {
      const payload = {
        id: this.antragId,
        categoryId: this.categoryId,
        fonds: [
          { 
            ...positionChange,
            isin,
          }
        ]
      }
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_POSITIONS, payload)
    },
    onChangeInputField($event, position, field) { 
      const positionChange = { [field]: $event };
      this.commitUpdatePositionsFromEvent(positionChange, position.isin)
    },
    handleTableAction(event) {
      if(event.action?.legend?.key === 'DELETE') {
        this.openModalDeleteFond(event.value);
      }
    },
    openModalDeleteFond(fond) {
      this.positionToDelete = fond;
      this.$refs.deleteModal.open()
    },
    doDeletePosition() {
      this.deletePosition(this.antragId, this.categoryId, this.positionToDelete?.isin);
      this.positionToDelete = null;
    }
  }
}
</script>

<style scoped>
  .input-forms__label-content--bigger {
    font-size: 1.5rem;
  }
  .input-forms__label-content--bigger > i {
    font-size: 2rem;
  }
</style>