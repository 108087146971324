var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-forms__container" }, [
    _c("div", { staticClass: "input-forms__label-container" }, [
      _c("div", { staticClass: "row mt-0" }, [
        _c("div", { staticClass: "col" }, [
          _vm.title
            ? _c(
                "div",
                {
                  staticClass:
                    "input-forms__label-content input-forms__label-content--bigger"
                },
                [
                  _c("ph-bank", { attrs: { size: 16 } }),
                  _vm._v(" " + _vm._s(_vm.title) + " ")
                ],
                1
              )
            : _vm._e()
        ]),
        _vm.config.istVerkauf
          ? _c(
              "div",
              { staticClass: "col-12 col-xl-8" },
              [
                _c("ComboBox", {
                  attrs: {
                    label: "Für Wertpapier",
                    values: _vm.valuesForDepot
                  },
                  model: {
                    value: _vm.depotPosition,
                    callback: function($$v) {
                      _vm.depotPosition = $$v
                    },
                    expression: "depotPosition"
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm.config.istVerkauf
          ? _c(
              "div",
              { staticClass: "col-12 col-xl-4 mt-3 pl-2" },
              [
                _c(
                  "BaseButton",
                  {
                    attrs: {
                      isEmbedded: true,
                      disabled: _vm.disableFondsButton
                    },
                    on: {
                      click: function($event) {
                        return _vm.addPosition()
                      }
                    }
                  },
                  [_vm._v("Fonds hinzufügen")]
                )
              ],
              1
            )
          : _vm._e(),
        _vm.config.istKauf
          ? _c(
              "div",
              { staticClass: "col-auto" },
              [
                _c("BaseButton", { on: { click: _vm.openFormsFinder } }, [
                  _vm._v("Fonds hinzufügen")
                ])
              ],
              1
            )
          : _vm._e()
      ])
    ]),
    _c(
      "div",
      { staticClass: "input-forms__input-container" },
      [
        _vm.rows.length
          ? _c("Table", {
              attrs: {
                headers: _vm.headers,
                rows: _vm.rows,
                rowId: "index",
                hidePagination: ""
              },
              on: { "action-DELETE": _vm.openModalDeleteFond },
              scopedSlots: _vm._u(
                [
                  _vm.config.istKauf
                    ? {
                        key: "aufteilung",
                        fn: function(row) {
                          return [
                            _c("InputField", {
                              attrs: {
                                type: "currency",
                                precision: 2,
                                value: row.aufteilung,
                                id: "aufteilung" + row.index
                              },
                              on: {
                                input: function($event) {
                                  return _vm.onChangeInputField(
                                    $event,
                                    row,
                                    "aufteilung"
                                  )
                                }
                              }
                            })
                          ]
                        }
                      }
                    : null,
                  this.modus == "PROZENT"
                    ? {
                        key: "aufteilungP",
                        fn: function(row) {
                          return [
                            _c("InputField", {
                              attrs: {
                                type: "percent",
                                value: row.aufteilungP,
                                id: "aufteilungP" + row.index
                              },
                              on: {
                                input: function($event) {
                                  return _vm.onChangeInputField(
                                    $event,
                                    row,
                                    "aufteilungP"
                                  )
                                }
                              }
                            })
                          ]
                        }
                      }
                    : null,
                  _vm.config.istVerkauf
                    ? {
                        key: "betrag",
                        fn: function(row) {
                          return [
                            _c("InputField", {
                              attrs: {
                                type: "currency",
                                precision: 2,
                                value: row.betrag,
                                id: "betrag" + row.index
                              },
                              on: {
                                input: function($event) {
                                  return _vm.onChangeInputField(
                                    $event,
                                    row,
                                    "betrag"
                                  )
                                }
                              }
                            })
                          ]
                        }
                      }
                    : null,
                  _vm.config.istKauf
                    ? {
                        key: "bemerkung",
                        fn: function(row) {
                          return [
                            _c("InputField", {
                              attrs: {
                                type: "text",
                                value: row.bemerkung,
                                id: "bemerkung" + row.index
                              },
                              on: {
                                input: function($event) {
                                  return _vm.onChangeInputField(
                                    $event,
                                    row,
                                    "bemerkung"
                                  )
                                }
                              }
                            })
                          ]
                        }
                      }
                    : null
                ],
                null,
                true
              )
            })
          : _vm._e(),
        _vm.config && _vm.config.positionFields
          ? _c(
              "div",
              [
                _c("WertpapierAuswahlPositionFields", {
                  attrs: {
                    config: _vm.config,
                    categoryId: _vm.categoryId,
                    antragId: _vm.antragId
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm.config.istKauf
          ? _c("InputRadioBoxGroup", {
              attrs: {
                title:
                  "Für die Aufteilung der Summe aller Verkäufe % oder € verwenden:",
                values: [
                  { value: "EUROBETRAG", label: "€" },
                  { value: "PROZENT", label: "%" }
                ]
              },
              on: {
                input: function($event) {
                  return _vm.onChange("INPUT_AUFTEILUNG_VERWENDEN", $event)
                }
              },
              model: {
                value: _vm.modus,
                callback: function($$v) {
                  _vm.modus = $$v
                },
                expression: "modus"
              }
            })
          : _vm._e(),
        _c("DeletePositionModal", {
          ref: "deleteModal",
          attrs: { position: _vm.positionToDelete },
          on: {
            delete: function($event) {
              return _vm.doDeletePosition()
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }