import actions from './actions';
import mutations from './mutations';
import getters from './getters';


export function getInitialState() {
  return {
    shownDays:[],
    weekCalendarData:[],
    refinedCalendarData: [],
    calendarDataReady:false,
    hoursStart:0,
    hoursStop:23,
    shownHours:[],
    columnEventArray:[],
    hoursContent:[],
    dayElements:{},
    weeksContent:[],
    selectedAppointment:{},
    clickedOnEvent:false,
    appointmentConfig: {},
    appointmentCombos:{},
    appointmentBeginDate:null,
    appointmentBeginTime:"",
    appointmentEndDate:null,
    appointmentEndTime:"",
    appointmentEndDateDuration:null,
    appointmentEndTimeDuration:"",
    appointmentDuration:0,
    customers:null,
    beteiligteForUpdate:[],
    emptyAppointment: {
      sid: null,
      label: null,
      retry: null,
      begin: null,
      end: null,
      fullDay: false,
      place: null,
      retryCount: null,
      retryMaxDate: null,
      activity: null,
      bereich: null,
      bereichId: null,
      bereichDisplay: null,
      status: null,
      alarmModusMSC: null,
      alarmModuEmail: null,
      alarmMsc: null,
      alarmEmail: null,
      text: null,
      color: null,
      produkt: null,
      geschaeftwert: null,
      bestaetigt: null,
      art: null,
      private: null,
    },
    appointmentSetup: null,
    customerAppointments: null,
    presaveAppointment: null,
    attachments: null,
    datesToSend: {},
    loadAppointment: '',
  }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}