import actions from './actions';
import mutations from './mutations';
import getters from './getters';

/**
 * mutations: synchronous operation responsible for change the state
 * actions: asynchronous operation responsible for call external resources and commit mutations 
 */

export function getInitialState() {
  return {
    dokumentenarchivData: {},
    virtualNumberOfRecords: {}, // mapped by nodeId
    dokumentActions: {},
    additionalUploadInputs: {},
    documentNode: {},
    documentNodePage: 0,
    additionalDocuments: {},
    selectedRows: [],
    loadingNode: null,
    loadingTree: false,
    loadingNodeList: [],
    isSelectedAllInFolder: false,
    tempUploadFiles: {},
    reloadDocumentsPayload: undefined,
    sortKey: undefined,
    sortAscending: false,
    documentsFilter: {
      showSchaden: true,
    },
    dropActionActive: false,
    prohypDocuments: [],
  }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}