<template>
  <div>
    <BaseModal
      ref="vertragVereinigenModal"
      modalTitle="Versicherungen vereinigen"
      labelButtonConfirm="Vereinigen "
      @onConfirmButton="onConfirmModal()"
      @onCancelButton="onCancelModal()"
      @onCloseButton="onCancelModal()"
    >

      <div class="row">
        <div class="col-12">
          <p>Hier können Sie zwei Versicherungen vereinigen.</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <hr>
          <p><span class="color-danger font-bold">ACHTUNG!</span></p>
          <p><span class="color-danger font-bold">Vereinigte Verträge können im Nachgang nicht mehr "getrennt" werden. Sind Sie sich sicher, dass Sie die Verträge vereinigen möchten?</span></p>
          <hr>
        </div>
      </div>    


      <div class="main__container">
        <div class="header" >
          Quell-Vertrag
        </div>
        <div class="control" >
          &nbsp;
        </div>

        <div class="header" >
          Ziel-Vertrag
        </div>  
      </div>


      <div v-if="vertragList && vertragList.length==2 && !changingPosition" class="main__container">
          <div class="item">
            {{vertragList[0]["id"]}} <br> {{vertragList[0]["bezeichnung"]}}
          </div>
          <div class="clickable control">
            <ph-arrows-clockwise :size="16" weight="bold" @click="changePositions()" />
          </div>
          <div class="item">
            {{vertragList[1]["id"]}}  <br> {{vertragList[1]["bezeichnung"]}}
          </div>          
      </div>


    </BaseModal>
  </div>
</template>

<script>
import BaseModal from "@/components/core/BaseModal.vue";
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";
import { mapGetters } from "vuex";
import {
PhArrowsClockwise
} from "phosphor-vue";

export default {
  props: {
    vertragList: { 
      type: Array,
      default: () => []
    },
  },  
  components: {
    BaseModal,
    PhArrowsClockwise
  },
  data() {
    return {
      versicherungen: null,
      changingPosition: false,
    };
  },
  computed: {
    ...mapGetters({
      insurancesByCustomer: VERSICHERUNG_TYPES.GETTERS.INSURANCES_BY_CUSTOMER,
    }),    
  },
  methods: {
    open() {
      this.$refs.vertragVereinigenModal.open();
    },
    onConfirmModal() {

      this.versicherungen = {
        toRemove : {
          vertragId: this.vertragList[0].id
        },
        toKeep: {
          vertragId: this.vertragList[1].id
        }
      }  

      this.$emit("save", this.versicherungen);
      this.$refs.vertragVereinigenModal.close();
      

    },
    onCancelModal() {
      this.$refs.vertragVereinigenModal.close();
    },
    changePositions() {
      this.changingPosition = true;

      let firstPosition = this.vertragList[0];
      let secondPosition = this.vertragList[1];

      this.vertragList[0] = secondPosition;
      this.vertragList[1] = firstPosition;

      this.versicherungen = {
        toRemove: {
          vertragId: firstPosition.id
        },
        toKeep : {
          vertragId: secondPosition.id
        }
      } 

      this.changingPosition = false;


    },   
  },
  mounted() {}
};
</script>

<style>

.main__container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 0px;

}

.main__container .header {
  flex: 1 1 0px;
}

.main__container .item {
  flex: 1 1 0px;
  /* align-self: center; */
  border: 1px  solid var(--color-text);
  padding: 4px;
}

.main__container .control {
  flex: 0.2 1 0px;
  align-self: center;
  justify-self: center;
  text-align: center;
}




</style>