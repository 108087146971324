<template>
  <div class="card-label__container">
    <div v-if="label" class="card-label__label">
      <span>{{ label }}</span>
    </div>
    <div class="card-label__value">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
  },
}
</script>

<style scoped>
  .card-label__container {
    display: flex;
    flex-direction: column;
  }
  .card-label__label {
    font-size: 0.75em;
    color: var(--color-primary);
    margin: 0 0 5px 0;
  }
  .card-label__value {
    word-break: break-all;
    white-space: normal;
  }
</style>