<template>
  <div class="container" v-if="showTapiPanel">
    <div class="header">
      <span class="header-outer" >
        {{ displayName }}
        <!--span v-if="kundennr">({{ kundennr }})</span-->
      </span>

      <div class="icon-button-container">
        
          <!--ph-pencil-line :size="32" class="icon-button" @click="doSet">
            <title>Edit</title>
          </ph-pencil-line-->

          <ph-phone
            :size="32"
            class="icon-button"
            @click="clickPhoneAnswer"
            v-if="this.ringing  && this.tapiOpened"
            color="var(--color-success)"
            ><title>Annehmen</title>
          </ph-phone>

          <ph-phone-disconnect
            :size="32"
            class="icon-button"
            @click="clickPhoneDisconnect"
            v-if="activeCallTapi1  && this.tapiOpened"
            color="var(--color-danger)"
            ><title>Auflegen</title></ph-phone-disconnect
          >
          <ph-gear :size="32" class="icon-button" @click="doSet"
            ><title>Einstellungen</title></ph-gear
          >

          <ph-x :size="32" class="icon-button" @click="close"
            ><title>Schließen</title></ph-x
          >

          <InputToggleSwitch
            v-model="tapiHostSwitch"
            v-if="this.canTapiHost && this.tapiOpened"
            label="Telefonservice aktiviert"
            inLineLabel
          />
        
      </div>

      <span class="header-outer" style="text-align: right">
        <span v-if="this.startCallTime">{{ callDuration }}</span>
      </span>
    </div>

    <SetupTapiDialog ref="SetupTapiDialog" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CORE_TYPES from "@/store/core/types";
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";

import SetupTapiDialog from "@/components/tapi/SetupTapiDialog.vue";

import {
  PhGear,
  PhX,
  PhPhoneDisconnect,
  PhPhone,
  PhPencilLine,
} from "phosphor-vue";
import TAPI from "../../store/tapi/types";
import { VIEW_ROLES } from "@/router/roles";

export default {
  components: {
    PhGear,
    PhX,
    PhPhone,
    PhPhoneDisconnect,
    InputToggleSwitch,
    SetupTapiDialog,
    PhPencilLine,
  },
  props: {},
  computed: {
    ...mapGetters({
      incomingCallTapi: TAPI.GETTERS.OPEN_INCOMMING_DIALOG,
      activeCallTapi: TAPI.GETTERS.ACTIVE_CALL,

      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      canTapi: TAPI.GETTERS.CAN_TAPI,
      tapiOpened: TAPI.GETTERS.WEB_SOCKET_OPENED,
      canTapiHost: TAPI.GETTERS.CAN_TAPI_HOST,
      tapiHost: TAPI.GETTERS.HOST,
      showPanel: TAPI.GETTERS.MODAL_PANEL_OPEN,
      direction: TAPI.GETTERS.DIRECTION,
      ringing: TAPI.GETTERS.RINGING,
      startCallTime: TAPI.GETTERS.START_CALL_DATE,
      caller: TAPI.GETTERS.CALLER,
      phoneNumber:TAPI.GETTERS.PHONE_NUMBER
    }),
    showTapiPanel() {
      
      return this.showPanel;
    },

    kundennr() {
      return null;
    },
    displayName() {
      if (this.incomingCallTapi) {
        return this.incomingCallTapi;
      } else if(this.phoneNumber &&  this.direction=='OUT'){
            return "Anruf an " +this.phoneNumber+(this.caller?" "+this.caller.userType +" "+this.caller.userId:"");
      }
      return "";
    },

    activeCallTapi1() {
      return this.activeCallTapi;
    },

    tapiHostSwitch: {
      get() {
        const value = this.tapiHost;
        return value;
      },
      set(value) {
        this.setHost(value);
      },
    },
  },
  watch: {
    startCallTime(startCallTime) {
      if (startCallTime) {
        this.setTimerUpdater();
      } else {
        this.clearTimerUpdater();
      }
    },
  },
  data() {
    return {
      callDuration: "",
      timer: null,
      showChat: false,
      collapsedVideo: false,
      videoIsScreen: false,
      mediaSettings: false,
    };
  },
  methods: {
    clickPhoneDisconnect() {
      this.$store.dispatch(TAPI.ACTIONS.CALL_DISCONNECT);
    },
    clickPhoneAnswer() {
      this.$store.dispatch(TAPI.ACTIONS.CONNECT, { param: "ACCEPTCALLOUT" });
    },

    setHost(v) {
      
      this.$store.dispatch(TAPI.ACTIONS.HOST, v);
    },
    close() {
      this.$store.commit(TAPI.MUTATIONS.MODAL_PANEL_OPEN, false);
    },
    doSet() {
      this.$refs.SetupTapiDialog?.open();
    },
    updateTime() {
      if (this.startCallTime) {
        const seconds = Math.floor((Date.now() - this.startCallTime) / 1000);
        this.callDuration =
          ("" + Math.floor(seconds / 60)).padStart(2, "0") +
          ":" +
          ("" + (seconds % 60)).padStart(2, "0");
      } else this.callDuration = "";
    },
    setTimerUpdater() {
      this.clearTimerUpdater();
      this.timer = setInterval(() => this.updateTime(), 1000);
    },
    clearTimerUpdater() {
      this.callDuration = "";
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
  },
  mounted() {
    const hasRoles = this.$store.getters[CORE_TYPES.GETTERS.HAS_ROLES];
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 2;
  width: 100%;
  height: 100%;
  align-items: center;
  bottom: 0;
  justify-content: flex-end;
  pointer-events: none;
}
.header {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 56px;
  line-height: 56px;
  justify-content: space-between;
  overflow-y: visible;
  background: rgba(0, 0, 0, 0.7);
  color: var(--color-box);
  pointer-events: auto;
}
.header-outer {
  margin: 0 10px;
  width: 50%;
  flex-shrink: 1;
}

.icon-button-container {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
}
.icon-button {
  cursor: pointer;
  border-radius: 100px;
  background: var(--color-box);
  margin: 1em;
  color: black;
  border: 4px solid var(--color-box);
}
.icon-button:hover {
  background: lightgray;
  border-color: lightgray;
}

@keyframes pulsate-record-button {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.1);
  }
}
</style>
