<template>
  <div>
    <InputToggleSwitch v-model="erlaubtGf" 
      :id="erlaubtGf" label="Geschlossene Investmentvermögen nach KAGB (AIF)" inLineLabel 
      :disabled="isDisabled" 
      isComponentHalfSize
      @input="updateStore('closedProperty', 'erlaubtGf', $event, true)" />
    <InputToggleSwitch v-model="erlaubtSonstige" label="Sonstige Vermögensanlagen (Vermögensanlagen)" inLineLabel 
      :disabled="isDisabled" 
      isComponentHalfSize
      @input="updateStore('closedProperty', 'erlaubtSonstige', $event, true)" />
    <InputToggleSwitch v-model="erlaubtDirekt" label="Direktinvestments (Vermögensanlagen)" inLineLabel 
      :disabled="isDisabled" 
      isComponentHalfSize
      @input="updateStore('closedProperty', 'erlaubtDirekt', $event, true)" />

    <InputRadioBoxGroup 
      title="Übertragbarkeit / Kündbarkeit"
      v-model="kuendbarkeit" 
      :disabled="isDisabled || !isGfZusatzAktiv"
      :validateUntouched="true"
      :values="[
          {value: 'KURZFRISTIG', label: 'kurzfristig kündbar oder unbeschränkte Übertragbarkeit bei erwartetem liquidem Zweitmarkt'},
          {value: 'MITTELFRISTIG', label: 'mittelfristig kündbar oder unbeschränkte Übertragbarkeit, jedoch kein liquider Zweitmarkt'},
          {value: 'LANGFRISTIG', label: 'langfristig oder nicht kündbar und eingeschränkte Übertragbarkeit bzw. keine Übertragbarkeit'}
      ]"
      @input="updateStore('closedProperty', 'kuendbarkeit', $event)" />
    <InputRadioBoxGroup 
      title="Geschäftsmodell"
      v-model="geschaeftsmodell" 
      :disabled="isDisabled || !isGfZusatzAktiv"
      :validateUntouched="true"
      :values="[
          {value: 'NORMAL', label: 'keine gesteigerten Risiken aus Geschäftsmodell; theoretisches Totalverlustrisiko'},
          {value: 'GESTEIGERT', label: 'gesteigerte Risiken aus Geschäftsmodell; nicht nur theoretisches Totalverlustrisiko'},
          {value: 'HOHE', label: 'hohe Risiken aus Geschäftsmodell, z. B. – Venture Capital – Projektentwicklung – Abhängigkeit von Schlüsselpersonen; nicht nur theoretisches Totalverlustrisiko'}
      ]"
      @input="updateStore('closedProperty', 'geschaeftsmodell', $event)" />
    <InputRadioBoxGroup 
      title="Haftung"
      v-model="haftung" 
      :disabled="isDisabled || !isGfZusatzAktiv"
      :validateUntouched="true"
      :values="[
          {value: 'NORMAL', label: 'keine über die zu leistende Einlage hinausgehende persönliche Haftung, jedoch Aufleben der persönlichen Haftung bis zur Höhe der Einlage möglich (z. B. im Falle von Ausschüttungen, wenn hierdurch das Kapitalkonto des Anlegers unter den Betrag der Hafteinlage absinkt)'},
          {value: 'BEGRENZT', label: 'begrenzte über die zu leistende Einlage hinausgehende persönliche Haftung bzw. begrenzte Nachschusspflichten'},
          {value: 'UNBEGRENZT', label: 'unbegrenzte persönliche Haftung bzw. unbegrenzte Nachschusspflichten'}
      ]"
      @input="updateStore('closedProperty', 'haftung', $event)" />
    <InputRadioBoxGroup 
      title="Fremdfinanzierung (auf Investmentvermögens- und Anlegerebene)"
      v-model="fremdfinanzierung"
      :disabled="isDisabled || !isGfZusatzAktiv"
      :validateUntouched="true"
      :values="[
          {value: 'MAX_25', label: 'kein Fremdkapitaleinsatz oder Fremdkapitalquote bis. max. 25 %'},
          {value: 'MAX_50', label: 'Fremdkapitalquote bis max. 50 %'},
          {value: 'MAX_100', label: 'Fremdkapitalquote über 50 %'}
      ]"
      @input="updateStore('closedProperty', 'fremdfinanzierung', $event)" />
    <InputRadioBoxGroup 
      title="Währungsrisiko"
      v-model="waehrungsrisiko" 
      :disabled="isDisabled || !isGfZusatzAktiv"
      :validateUntouched="true"
      :values="[
          {value: 'GERING', label: 'Anlage in Euro; Verwendung der Gelder auf Ebene des geschlossenen Investmentvermögen bzw. der Vermögensanlage: Investitionen erfolgen in Euro und es besteht kein oder geringes Währungsrisiko bei Fremdfinanzierung.'},
          {value: 'MODERAT', label: 'Anlage in Euro; Verwendung der Gelder auf Ebene des geschlossenen Investmentvermögen bzw. der Vermögensanlage: Investitionen erfolgen teilweise in Fremdwährung und/oder moderates Währungsrisiko bei Fremdfinanzierung.'},
          {value: 'HOCH', label: 'Anlage in Euro oder in Fremdwährung; Verwendung der Gelder auf Ebene des geschlossenen Investmentvermögen bzw. der Vermögensanlage: Investitionen erfolgen überwiegend oder ausschließlich in Fremdwährung und/oder hohes Währungsrisiko bei Fremdfinanzierung.'}
      ]"
      @input="updateStore('closedProperty', 'waehrungsrisiko', $event)" />

    <InputToggleSwitch 
      v-model="bestaetigungRichtigkeit" 
      :label="checkBest" 
      :disabled="isDisabled || !isGfZusatzAktiv"
      inLineLabel suppressValidationMessage isComponentHalfSize
      @input="updateStore('closedProperty', 'bestaetigungRichtigkeit', $event)" />

  </div>
</template>

<script>

import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import anlegerprofilMixin from '@/mixins/anlegerprofil/anlegerprofil-mixin.js';

export default {
  mixins: [anlegerprofilMixin],
  components: {
    InputRadioBoxGroup,
    InputToggleSwitch,
  },
  data() {
    return {
      erlaubtGf: false,
      erlaubtSonstige: false,
      erlaubtDirekt: false,
      fremdfinanzierung: '',
      geschaeftsmodell: '',
      kuendbarkeit: '',
      haftung: '',
      waehrungsrisiko: '',
      bestaetigungRichtigkeit: false,
      checkBest: 'Hiermit bestätige/n ich/wir die Richtigkeit der Angaben '
				+ 'im Sondererhebungsbogen "Kundenangaben" für den Vertrieb von geschlossenen Investmentvermögen und Vermögensanlagen. '
				+ 'Sollten sich die Angaben ändern, verpflichte/n ich/wir mich/uns diese an meinen/unseren Berater/Vermittler sofort weiter zu geben.',
    };
  },
  mounted() {
    this.patchForm();
    this.$nextTick().then(() => {
      this.updateWarnings();
    });
  },
  methods: {
    patchForm() {
      if (this.data?.closedProperty && Object.keys(this.data.closedProperty).length) {
        Object.keys(this.data.closedProperty).forEach(key => this[key] = this.data.closedProperty[key])
      }
    },
  },
}
</script>

<style scoped>
</style>