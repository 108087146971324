import DASHBOARD_TYPES from './types';

export default {
  [DASHBOARD_TYPES.GETTERS.SAVE_DASHBOARD](state) {
    return state.template;
  },
  [DASHBOARD_TYPES.GETTERS.GET_DASHBOARD_CHARTS](state) {
    return state.charts.data;
  },
  [DASHBOARD_TYPES.GETTERS.GET_DASHBOARD_CHARTS_STRUCTURE](state) {
    return state.isStructure;
  },
  [DASHBOARD_TYPES.GETTERS.GET_USER_DASHBOARD](state) {
    return state.configuration.dashboardCards;
  },
  [DASHBOARD_TYPES.GETTERS.GET_USER_DASHBOARD_HIDE_CARDS](state) {
    return state.configuration.hideCards;
  },
  [DASHBOARD_TYPES.GETTERS.GET_STECKBRIEF_CONFIGURATION](state) {
    return state.steckbriefConfiguration;
  },
  [DASHBOARD_TYPES.GETTERS.BROKER_OVERVIEW_CONFIGURATION](state) {
    return { ...state.brokerOverviewConfiguration };
  },
  [DASHBOARD_TYPES.GETTERS.INTERN_OVERVIEW_CONFIGURATION](state) {
    return { ...state.internOverviewConfiguration };
  }, 
  [DASHBOARD_TYPES.GETTERS.GET_ENABLED_CARDS](state) {
    return state.enabledMaklerCards;
  },
  [DASHBOARD_TYPES.GETTERS.BROKER_PARAGRAPHS_CONFIGURATION](state) {
    return state.brokerParagraphsConfiguration;
  },
}
