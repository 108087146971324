var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("div", { staticClass: "box__title" }, [_vm._v("Einstellungen")]),
        _c("ComboBox", {
          attrs: {
            isComponentHalfSize: "",
            values: _vm.valuesVerwahrstelle,
            label: "Verwahrstelle",
            validateUntouched: "",
            disabled: _vm.form.disabled
          },
          on: {
            change: function($event) {
              return _vm.updateVerwahrstelle($event)
            }
          },
          model: {
            value: _vm.form.verwahrstelle,
            callback: function($$v) {
              _vm.$set(_vm.form, "verwahrstelle", $$v)
            },
            expression: "form.verwahrstelle"
          }
        }),
        _c("ComboBox", {
          attrs: {
            isComponentHalfSize: "",
            values: _vm.valuesRisikoneigung,
            label: "Risiko der Strategie",
            validateUntouched: "",
            sortComboboxValues: false,
            disabled: _vm.form.disabled
          },
          on: {
            change: function($event) {
              return _vm.updateStore({ risikoneigung: $event }, "settings")
            }
          },
          model: {
            value: _vm.form.risikoneigung,
            callback: function($$v) {
              _vm.$set(_vm.form, "risikoneigung", $$v)
            },
            expression: "form.risikoneigung"
          }
        }),
        _c("ComboBox", {
          attrs: {
            isComponentHalfSize: "",
            values: _vm.valuesAutoRebalancing,
            label: "Automatisches Rebalancing",
            validateUntouched: ""
          },
          on: {
            change: function($event) {
              return _vm.updateStore({ autoRebalancing: $event }, "settings")
            }
          },
          model: {
            value: _vm.form.autoRebalancing,
            callback: function($$v) {
              _vm.$set(_vm.form, "autoRebalancing", $$v)
            },
            expression: "form.autoRebalancing"
          }
        }),
        _c("InputField", {
          attrs: {
            isComponentHalfSize: "",
            label: "Strategie-Kennung",
            validateUntouched: "",
            disabled: !!_vm.schemaId || _vm.form.portfolioKennungDisabled
          },
          on: {
            change: function($event) {
              return _vm.updateStore({ portfolioKennung: $event }, "settings")
            }
          },
          model: {
            value: _vm.form.portfolioKennung,
            callback: function($$v) {
              _vm.$set(_vm.form, "portfolioKennung", $$v)
            },
            expression: "form.portfolioKennung"
          }
        }),
        _c("InputField", {
          attrs: {
            isComponentHalfSize: "",
            type: "currency",
            validateUntouched: "",
            precision: 2,
            label: "Minimum Einmalanlage"
          },
          on: {
            change: function($event) {
              _vm.updateStore(
                { minEinmalanlageSumme: _vm.formatNumber($event) },
                "settings"
              )
            }
          },
          model: {
            value: _vm.form.minEinmalanlageSumme,
            callback: function($$v) {
              _vm.$set(_vm.form, "minEinmalanlageSumme", $$v)
            },
            expression: "form.minEinmalanlageSumme"
          }
        }),
        _c("InputField", {
          attrs: {
            isComponentHalfSize: "",
            type: "currency",
            validateUntouched: "",
            precision: 2,
            label: _vm.getLabelSparplan
          },
          on: {
            change: function($event) {
              _vm.updateStore(
                { minSparplanSumme: _vm.formatNumber($event) },
                "settings"
              )
            }
          },
          model: {
            value: _vm.form.minSparplanSumme,
            callback: function($$v) {
              _vm.$set(_vm.form, "minSparplanSumme", $$v)
            },
            expression: "form.minSparplanSumme"
          }
        }),
        _c("InputField", {
          attrs: {
            isComponentHalfSize: "",
            type: "number",
            validateUntouched: "",
            precision: 0,
            label: "Haltedauer in Jahren"
          },
          on: {
            change: function($event) {
              return _vm.updateStore({ haltedauer: $event }, "settings")
            }
          },
          model: {
            value: _vm.form.haltedauer,
            callback: function($$v) {
              _vm.$set(_vm.form, "haltedauer", $$v)
            },
            expression: "form.haltedauer"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }