var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ResizableModal",
    {
      ref: "resizablePopup",
      attrs: {
        modalTitle: _vm.modalTitle,
        initialWidth: _vm.initialWidth,
        initialHeight: _vm.initialHeight,
        sizeIsPercentage: _vm.sizeIsPercentage,
        startPosition: _vm.startPosition,
        id: _vm.id
      },
      on: {
        onMinimize: _vm.resizeIMGContainer,
        onMaximize: _vm.resizeIMGContainer,
        onResize: _vm.resizeIMGContainer
      }
    },
    [
      _c("div", { ref: "objectContainer", staticClass: "object-container" }, [
        _vm.imgUrl ? _c("img", { attrs: { src: _vm.imgUrl } }) : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }