export const MUTATION_PREFIX = 'DOKUMENTENARCHIV_MUTATIONS_';
export const ACTIONS_PREFIX = 'DOKUMENTENARCHIV_ACTIONS_';
export const GETTERS_PREFIX = 'DOKUMENTENARCHIV_GETTERS_';

export default {
  MUTATIONS: {
    RETRIEVE_QUARTALSBERICHTE_NODE_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_QUARTALSBERICHTE_NODE_SUCCESS',
    QUARTALSBERICHTE_BERECHNEN_SUCCESS: MUTATION_PREFIX + 'QUARTALSBERICHTE_BERECHNEN_SUCCESS',
  },
  ACTIONS: {
    RETRIEVE_QUARTALSBERICHTE_NODE: ACTIONS_PREFIX + 'RETRIEVE_QUARTALSBERICHTE_NODE',
    QUARTALSBERICHTE_BERECHNEN: ACTIONS_PREFIX + 'QUARTALSBERICHTE_BERECHNEN',
  },
  GETTERS: {
    QUARTALSBERICHTE_NODE: GETTERS_PREFIX + 'QUARTALSBERICHTE_NODE',
    QUARTALSBERICHTE_BERECHNEN_ERGEBNIS: GETTERS_PREFIX + 'QUARTALSBERICHTE_BERECHNEN_ERGEBNIS',
  }
}