var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _c("div", { staticClass: "box__title" }, [_vm._v("Gewerbeschein")]),
      _c("FormDivider"),
      _c("FormLabel", {
        attrs: {
          label:
            "Anforderung: Gemäß dem mit Ihnen geschlossenen Berechtigungsvertrag ist (sind) jegliche Änderung(en) Ihrer Gewerbeberechtigung, sowie Kontaktdaten (Telefonnummer, E-Mail, etc.) umgehend bekanntzugeben."
        }
      }),
      _c("InputRadioBoxGroup", {
        attrs: {
          value: _vm.form.INPUT_GEWERBE_BEST1,
          values: _vm.gewerbescheinValues,
          labelClass: "font-bold",
          disabled: _vm.disabledStatusOK
        },
        on: {
          input: function($event) {
            return _vm.fieldChanged($event, "INPUT_GEWERBE_BEST1")
          }
        }
      }),
      _c("InputField", {
        attrs: {
          value: _vm.form.INPUT_GEWERBE_BEST2_TEXT,
          isComponentHalfSize: "",
          disabled: !_vm.form.INPUT_GEWERBE_BEST1 || _vm.disabledStatusOK
        },
        on: {
          change: function($event) {
            return _vm.fieldChanged($event, "INPUT_GEWERBE_BEST2_TEXT")
          }
        }
      }),
      _c("ChecklisteDocsUpload", {
        attrs: {
          paramId: this.form.PARAM_ID,
          topic: "Gewerbeschein",
          form: this.form
        }
      }),
      _c("InputRadioBoxGroup", {
        attrs: {
          value: _vm.form.INPUT_GEWERBE_BEST3,
          values: _vm.adresseValues,
          labelClass: "font-bold",
          disabled: _vm.disabledStatusOK
        },
        on: {
          input: function($event) {
            return _vm.fieldChanged($event, "INPUT_GEWERBE_BEST3")
          }
        }
      }),
      _c("InputField", {
        attrs: {
          value: _vm.form.INPUT_GEWERBE_STRASSE,
          label: "Strasse",
          isComponentHalfSize: "",
          disabled: !_vm.form.INPUT_GEWERBE_BEST3 || _vm.disabledStatusOK
        },
        on: {
          change: function($event) {
            return _vm.fieldChanged($event, "INPUT_GEWERBE_STRASSE")
          }
        }
      }),
      _c("InputField", {
        attrs: {
          label: "PLZ",
          value: _vm.form.INPUT_GEWERBE_PLZ,
          isComponentHalfSize: "",
          disabled: !_vm.form.INPUT_GEWERBE_BEST3 || _vm.disabledStatusOK
        },
        on: {
          change: function($event) {
            return _vm.fieldChanged($event, "INPUT_GEWERBE_PLZ")
          }
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Ort",
          value: _vm.form.INPUT_GEWERBE_ORT,
          isComponentHalfSize: "",
          disabled: !_vm.form.INPUT_GEWERBE_BEST3 || _vm.disabledStatusOK
        },
        on: {
          change: function($event) {
            return _vm.fieldChanged($event, "INPUT_GEWERBE_ORT")
          }
        }
      }),
      _c("FormDivider"),
      _c("InputToggleSwitch", {
        attrs: {
          label:
            "Ich bestätige hiermit, die Angaben wahrheitsgetreu getätigt zu haben.",
          inLineLabel: true,
          config: { bold: true },
          isComponentHalfSize: "",
          disabled: _vm.disabledStatusOK,
          value: true
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }