<template>
  <div>
    <MailBriefVorlage
      :appendActions="headerActions"
      @action-REFRESH="onRefresh()"
      @action-ADD-NEW-TAG="openAddNewTagModal()"
    />

    <BaseFilter
      title="Vorlagen Briefe / E-Mails Filter"
      :filterId="baseFilterSaveKey"
      :metadata="searchMetadata"
      :configFilter="configFilter"
      :showSaveButton="true"
      :defaultOptions="defaultOptions"
      @onFilter="search($event)"
      @onFilterZurucksetzen="search(null)"
    />

    <div class="box__container">
      <Table
          v-if="!pageLoading && rows.length"
          ref="tableResults"
          tableId="cec0b9a7-812c-48f2-9889-6e9b64ca839c"
          :title="TABLE_TITLE"
          :headers="headers"
          :rows="filteredRows"
          :rowsPerPage="100"
          @click-grantLabel="showFreigabeEditor"
          @action-EDIT="handleEdit"
          @action-DELETE="handleDelete"
          @action-COPY="handleCopy"
          @action-CHANGE_ACCESS="showFreigabeEditor"
        >
          <template #description="vorlage">
            <DownloadLink
              :title="vorlage.description"
              :downloadServicePath="vorlage.isWord ? '/get_word_vorlage' : '/vorlage_preview'"
              :filename="vorlage.isWord ? 'Dokument.doc' : 'Dokument.pdf'"
              :queryParams="{
                id: vorlage.id,
                fileId: vorlage.id,
                
              }"
            />
          </template>
          <template #visible="vorlage">
            <ComboBox
              :value="(vorlage.visible || (isIntern ? 'JA' : 'NEIN'))"
              :values="sichtbarkeitValues"
              :disabled="loadingItems[vorlage.id]"
              @input="changeVisibility(vorlage, $event)"
            />
          </template>

          <template #tags="vorlage">
            <InputChips 
              class="pb-0"
              showToggle
              v-model="selectedTags[vorlage.id]"
              :disableInput="(!vorlage.editable || loadingItems[vorlage.id])"
              :availableOptions="tagsOptionWithoutCurrentTags(vorlage.id)"
              @addItem="addItem($event, vorlage)"
              @removeItem="removeItem($event, vorlage)" />
          </template>
      </Table>

      <GhostLoading v-else-if="pageLoading" type="table" :title="TABLE_TITLE" />

      <NoData v-else :title="TABLE_TITLE" content="Keine Vorlagen vorhanden" />
    </div>

    <FreigabeEditModal 
      v-if="editFreigabeVorlage" 
      :vorlage="editFreigabeVorlage" 
      @close="closeEditFreigabeVorlage"/>

    <AddVorlagenTagsModal 
			v-if="showAddNewTag"
			@close="closeAddNewTag" />
  </div>
</template>

<script>
import TEMPLATES_EDIT_TYPES from '@/store/templatesEdit/types';
import TAGS_TYPES from '@/store/tags/types';
import CORE_TYPES from '@/store/core/types';
import NoData from '@/components/core/NoData.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import { mapGetters } from 'vuex'
import Table from "@/components/table2/Table.vue";
import { TextColumn, ActionColumn, SimpleAction, Icon, SlotColumn, ConfirmedAction, DownloadLinkAction } from "@/components/table2/table_util.js";
import { PhFileText, PhEnvelope } from 'phosphor-vue';
import MailBriefVorlage from './MailBriefVorlage.vue';
import DownloadLink from '@/components/core/download/DownloadLink.vue';
import FreigabeEditModal from '@/views/templatesEdit/FreigabeEditModal.vue';
import InputChips from '@/components/core/input-chips/InputChips.vue';
import { VIEW_ROLES } from '@/router/roles';
import AddVorlagenTagsModal from '@/components/vorlagen-tags/AddVorlagenTagsModal.vue';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import BaseFilter from '@/components/core/BaseFilter.vue';
import { prepareFilters, getSearchMetaTag, VALUE_EMPTY } from '@/views/templatesEdit/mailVorlageListConstants.js'
import ComboBox from '@/components/core/forms/ComboBox.vue'

const TYPE_LABELS = {
  'BRIEF': 'Brief',
  'EMAIL': 'E-Mail',
  'BRIEF_MIT_DS': 'Brief mit Datenschutzerklärung',
}

const GRANT_LABELS = {
  'NO': 'Keine Freigabe',
  'STRUCTURE': 'Freigabe für Struktur',
  'ALL': 'Freigabe für Alle Vermittler',
}

const TABLE_TITLE = 'Vorlagen Briefe / E-Mails Liste';

export default {
  components: {
    GhostLoading,
    NoData,
    Table,
    MailBriefVorlage,
    DownloadLink,
    FreigabeEditModal,
    InputChips,
    AddVorlagenTagsModal,
    BaseFilter,
    ComboBox,
  },
  data() {
    return {
      TABLE_TITLE,
      baseFilterSaveKey: 'MailBriefVorlage',
      editFreigabeVorlage: null,
      selectedTags: {},

      showAddNewTag: false,
      showExpanded: false,

      configFilter: {
        placeholderForDefSearchInput: 'Bezeichnung',
        checkDefaultSearchInput: false,
      },

      filterOptions: {
        vorlagen: 'OWN'
      },

      loadingItems: {},
      pageLoading: false,

      defaultOptions: {
        description: { value: '' },
        vorlagen: { value: 'OWN' }
      }
    }
  },
  watch: {
    templates: {
      handler() {
        this.populateSelectedTags()
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      templates: TEMPLATES_EDIT_TYPES.GETTERS.GET_LETTER_TEMPLATES,
      tags: TAGS_TYPES.GETTERS.TAGS,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES
    }),
    searchMetadata() {
      return getSearchMetaTag(this.typeFilterOptions, this.tagsOption)
    },
    showTagListButton() {
			return this.isIntern
		},
    headers() {
      return {
        lockedLeft: [
          SlotColumn('description', 'Betreff', 200, 3).makeAlwaysVisible().makeSortable(),
        ],
        center: [
          SlotColumn('visible', 'Kommunikationsanzeige', 80),
          TextColumn('grantLabel', 'Freigabe').makeLink(),
          TextColumn('typeLabel', 'Typ', 80),
          TextColumn('format', 'Format', 80),
        ],
        lockedRight: [
          SlotColumn('tags', 'Tags', 360),
          ActionColumn('actions'),
        ],
      }
    },

    isIntern() {
			return this.hasRoles(VIEW_ROLES.VIEW_INTERN)
		},
    htmlTemplates() {
      return this.templates?.htmlTemplates || []
    },
    wordTemplates() {
      return this.templates?.worldTemplates || []
    },
    tagsOption() {
			return this.tags?.map(tag =>({label: tag.description, value: tag.id}))
		},
    tagsOptionWithoutCurrentTags() {
      return function (vorlageId) {
        if (!vorlageId || !this.selectedTags[vorlageId]) {
          return this.tagsOption
        }
        return this.tagsOption.filter(tag => !this.selectedTags[vorlageId].some(ct => ct.value === tag.value))
      }
    },
    rows() {
      return this.htmlTemplates.map(v => ({
        ...v,
        isWord: false,
        description: v.subjectOverwrite || v.subject,
      }))
      .concat(this.wordTemplates.map(v => ({
        ...v,
        isWord: true,
        description: v.subjectOverwrite || v.subject,
      }))).map(row => {
        const actions = []

        actions.push(DownloadLinkAction('VIEW', 'PhEye', 'anschauen', 
          row.isWord ? 'Dokument.doc' : 'Dokument.pdf', 
          row.isWord ? '/get_word_vorlage' : '/vorlage_preview', 
          { id: row.id, fileId: row.id }));

        if (!row.isWord) {
          actions.push(SimpleAction('COPY', 'PhCopy', 'kopieren'));
        }

        if (row.editable) {
          actions.push(SimpleAction('EDIT', 'PhPencil', 'bearbeiten'));
          actions.push(ConfirmedAction('DELETE', 'PhTrash', 'Löschen', 'Wollen Sie diesen Eintrag wirklich löschen?', 'Bestätigung', 'Löschen'));
          actions.push(SimpleAction('CHANGE_ACCESS', 'PhEye', 'Freigabe ändern'));
        }

        return {
          ...row,
          format: row.isWord ? 'Word' : 'HTML',
          typeLabel: this.getTypeLabel(row.type),
          grantLabel: this.getFreigabeLabel(row),
          actions,
        }
      })
    },
    filteredRows() {
			let rows = [ ...this.rows || [], ];
      const { filterOptions } = this;

      if (!filterOptions) {
        return rows
      }

      for (const [key, value] of Object.entries(filterOptions)) {
        switch(key) {
          case 'description':
            rows = rows.filter(v => {
              return !value?.toLowerCase?.() || v.subjectOverwrite?.toLowerCase?.().includes(value?.toLowerCase?.()) ||
                v.subject?.toLowerCase?.().includes(value?.toLowerCase?.())
            });
            break;

          case 'grant':
            rows = rows.filter(v => value?.includes(v.grant) || (value?.includes(VALUE_EMPTY) && !v.grant))
            break;
          case 'vorlageType':
            rows = rows.filter(v => value?.includes(v.type))
            break;

          case 'onlyWithoutTags':
            rows = rows.filter(v => !v.tags || !v.tags?.length)
            break;
          case 'onlyWithTags':
            rows = rows.filter(v => v.tags?.length)
            break;

          case 'withTag':
            rows = rows.filter(v => v.tags?.some(tag => value.includes(tag.id) ))
            break;
          case 'withoutTag':
            rows = rows.filter(v => !v.tags || !v.tags?.some(tag => value.includes(tag.id) ))
            break;

          case 'vorlagen':
            rows = rows.filter(v => (!['OWN', 'SHARED'].includes(value)) || 
              (value === 'OWN' && v.editable) || (value === 'SHARED' && !v.editable))
            break;

          default:
            break;
        }
      }

			return rows;
		},

    typeFilterOptions() {
			const options = this.rows?.map(v => v.type).reduce((acc, curr) => {
				if (!acc.includes(curr)) {
					acc.push(curr)
				}
				return acc
			}, [])

      return options?.filter(op => op).map(value => ({label: this.getTypeLabel(value), value }))
		},

    headerActions() {
      const { showTagListButton, } = this;

      const actions = [];
      actions.push(PageHeaderSimpleAction('REFRESH', 'Aktualisieren'));
      if(showTagListButton) {
        actions.push(PageHeaderSimpleAction('ADD-NEW-TAG', 'Tag Liste'));
      }
      return actions;
    },
    sichtbarkeitValues() {
      return [
        {value: 'JA', label: 'Ja'},
        {value: 'JA_STANDARD', label: 'Ja, standardmäßig'},
        ...(this.isIntern ? [] : [{value: 'NEIN', label: 'Nein'}]),
      ]
    },

  },
  methods: {
    search(filters) {
      // brings the table to the fist page. 
      // This is necessary when the filtered list has less pages than the previous one.
      this.$refs.tableResults?.onPageChange?.(0)

      this.filterOptions = prepareFilters(filters)
    },
    openAddNewTagModal() {
			this.showAddNewTag = true
		},
    handleEdit(item) {
      const path = item.isWord ? `../mailvorlage/word/${item.id}` : `../mailvorlage/email/${item.id}`;
      this.$router.push({ path });
    },
    closeEditFreigabeVorlage() {
      this.editFreigabeVorlage = null;
    },
    closeAddNewTag() {
      this.showAddNewTag=false
      this.onRefresh();
    },
    showTagEditor() {
      this.$refs.editTagsModal.open();
    },
    showFreigabeEditor(row) {
      this.editFreigabeVorlage = row;
    },
    getIcon(row) {
      switch (row.type) {
        case 'BRIEF_MIT_DS':
        case 'BRIEF':
          return [Icon(PhFileText, '', 24)];
        case 'EMAIL':
          return [Icon(PhEnvelope, '', 24)];
        default:
          return [];
      }
    },
    getFreigabeLabel(row) {
      return GRANT_LABELS[row?.grant] || ''
    },
    getTypeLabel(type) {
      return TYPE_LABELS[type] || type 
    },
    generateAddRemoveTagPayload(tag) {
      return {
        id: tag.label != tag.value ? tag.value : null,
        description: tag.label || tag.value,
      }
    },
    generateAddRemoveHtmlPayload(tag, htmlVorlage) {
			return {
				tag: this.generateAddRemoveTagPayload(tag),
				htmlVorlage: {
					id: htmlVorlage.id
				}
			}
		},
    generateAddRemoveWordPayload(tag, wordVorlage) {
			return {
				tag: this.generateAddRemoveTagPayload(tag),
				wordVorlage: {
					id: wordVorlage.id
				}
			}
		},
    async changeVisibility(vorlage, visible) {
      try {
        const payload = {
          id: vorlage.id,
          visible: vorlage.isWord ? visible.includes('JA') : visible,
          isWord: vorlage.isWord,
        }
        this.setLoadingItem(vorlage.id)
        await this.$store.dispatch(TEMPLATES_EDIT_TYPES.ACTIONS.CHANGE_TEMPLATE_VISIBILITY, payload)
      } catch {
        // empty block
      } finally {
        this.deleteLoadingItem(vorlage.id)
      }
    },
    async addItem(tag, vorlage) {
      try {
        const { selectedTags } = this;

        this.setLoadingItem(vorlage.id)
        if (vorlage.isWord) {
          await this.$store.dispatch(TAGS_TYPES.ACTIONS.ADD_TAG_WORD_VORLAGEN, this.generateAddRemoveWordPayload(tag, vorlage))
        } else {
          await this.$store.dispatch(TAGS_TYPES.ACTIONS.ADD_TAG_HTML_VORLAGEN, this.generateAddRemoveHtmlPayload(tag, vorlage))
        }

        this.$store.commit(TEMPLATES_EDIT_TYPES.MUTATIONS.UPDATE_TEMPLATE_TAGS, {
          id: vorlage.id,
          tags: selectedTags[vorlage.id].map(item => this.generateAddRemoveTagPayload(item))
        });
      } finally {
        this.deleteLoadingItem(vorlage.id)
      }
		},
		async removeItem(tag, vorlage) {
      try {
        const { selectedTags } = this;

        this.setLoadingItem(vorlage.id)
        if (vorlage.isWord) {
          await this.$store.dispatch(TAGS_TYPES.ACTIONS.REMOVE_TAG_WORD_VORLAGEN, this.generateAddRemoveWordPayload(tag, vorlage))
        } else {
          await this.$store.dispatch(TAGS_TYPES.ACTIONS.REMOVE_TAG_HTML_VORLAGEN, this.generateAddRemoveHtmlPayload(tag, vorlage))
        }

        this.$store.commit(TEMPLATES_EDIT_TYPES.MUTATIONS.UPDATE_TEMPLATE_TAGS, {
          id: vorlage.id,
          tags: selectedTags[vorlage.id].map(item => this.generateAddRemoveTagPayload(item))
        });
      } finally {
        this.deleteLoadingItem(vorlage.id)
      }
		},
    setLoadingItem(id) {
      this.$set(this.loadingItems, id, true)
    },
    deleteLoadingItem(id) {
      this.$delete(this.loadingItems, id)
    },
    populateSelectedTags() {
			this.$set(this, 'selectedTags', {})

      const templates = [...this.htmlTemplates, ...this.wordTemplates ]
			
			for (const template of templates) {
				if (!this.selectedTags[template.id]) {
					this.$set(this.selectedTags, template.id, []);
				}

				for (const tag of template.tags) {
					this.selectedTags[template.id].push({label: tag.description, value: tag.id})
				}

			}
		},
    async handleDelete(item) {
      if (item.isWord) {
        this.$store.dispatch(TEMPLATES_EDIT_TYPES.ACTIONS.DELETE_WORD_TEMPLATE, {id: item.id});
      } else {
        this.$store.dispatch(TEMPLATES_EDIT_TYPES.ACTIONS.DELETE_TEMPLATE, {id: item.id});
      }
    },
    async handleCopy(item) {
      this.$store.dispatch(TEMPLATES_EDIT_TYPES.ACTIONS.COPY_LETTER_TEMPLATE, {id: item.id});
    },
    async onRefresh() {
      this.pageLoading = true

      await Promise.all([
        this.$store.dispatch(TAGS_TYPES.ACTIONS.GET_TAGS),
        this.$store.dispatch(TEMPLATES_EDIT_TYPES.ACTIONS.GET_LETTER_TEMPLATES_HTML),
      ]).finally(() => this.pageLoading = false)

      // slower request, can be finished later
      await this.$store.dispatch(TEMPLATES_EDIT_TYPES.ACTIONS.GET_LETTER_TEMPLATES_WORD);
    },
  },
  mounted() {
    this.onRefresh()
  },
  beforeDestroy() {
    this.$store.commit(TAGS_TYPES.MUTATIONS.RESET_STATE);
    this.$store.dispatch(TEMPLATES_EDIT_TYPES.ACTIONS.RESET);
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu Vorlagen Briefe / E-Mails', 
      to,
      from,
      breadcrumb: 'Vorlagen Briefe / E-Mails'
    });
    next();
  },
}
</script>

<style scoped>
.top-buttons {
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
}
</style>
