<template>
  <div>
    <BaseButton v-if="editMode" @click="neueBeteiligte()" class="mb-16px">Neue Person hinzufügen</BaseButton>

    <div>
      <Table
        v-if="tableData.records.length"
        tableId="d944db51-7e78-45ab-be48-b993d9803675"
        :tableData="tableData"
        :actions="tableActions"
        :cardViewEnabled="false"
        :filterEnabled="false"
        :exportEnabled="false"
        :rowsPerPage="tableData.records.length"
        :paginationEnabled="false"
        @execute-action="handleTableAction($event)"
      >
        <template #rollenFahrer="{ data }">
          {{ data.row.rollen.fahrer ? 'Ja' : 'Nein' }}
        </template>
        <template #rollenGeschadigter="{ data }">
          {{ data.row.rollen.geschadigter ? 'Ja' : 'Nein' }}
        </template>
        <template #rollenVerursacher="{ data }">
          {{ data.row.rollen.verursacher ? 'Ja' : 'Nein' }}
        </template>
        <template #rollenZeuge="{ data }">
          {{ data.row.rollen.zeuge ? 'Ja' : 'Nein' }}
        </template>
        <template #rollenSachverstandiger="{ data }">
          {{ data.row.rollen.sachverstandiger ? 'Ja' : 'Nein' }}
        </template>
      </Table>
      <div v-else>Keine Daten</div>
    </div>

    <BaseModal 
      ref="beteiligteModal"
      :showDefaultButtons="false" 
      :actions="baseModalActions"
      @action-SAVE_PERSON_DATA="beteiligteSpeichern()"
      @action-CANCEL="closeBeteiligteModal()">
      <template #modalTitle>{{ inserting == true ? 'Neue Beteiligte' : 'Beteiligte bearbeiten' }}</template>

      <template #default>
        <div>
          <div class="box__title">Allgemeine Angaben</div>
          <InputField label="Zugehöriger Schadensfall" type="text" :disabled="true" :value="sparte" />
          <div class="mt-16px">
            <div class="box__title">Rolle(n)</div>
            <div class="checkbox-input__container">
              <InputCheckBoxItem label="Geschädigter" v-model="beteiligte_form.rollen.geschadigter" />
              <InputCheckBoxItem label="Verursacher" v-model="beteiligte_form.rollen.verursacher" />
              <InputCheckBoxItem label="Fahrer" v-model="beteiligte_form.rollen.fahrer" />
              <InputCheckBoxItem label="Zeuge" v-model="beteiligte_form.rollen.zeuge" />
              <InputCheckBoxItem label="Sachverständiger" v-model="beteiligte_form.rollen.sachverstandiger" />
              <InputCheckBoxItem label="Sonstiges" @input="whenSonstigesChanged($event)" v-model="beteiligte_form.rollen.sonstiges" />
              <InputField label="" v-if="beteiligte_form.rollen.sonstiges" v-model="beteiligte_form.bettext" validateUntouched/>
            </div>
          </div>
        </div>

         <div class="mt-16px">
          <div class="box__title">
            <span class="mr-2">Personendaten</span>
            <a class="align-end" style="flex-grow: 0;" @click="handleAddUsers"><PhBookOpen :size="24"/></a>
          </div>

           <ComboBox
            label="Anrede"
            v-model="beteiligte_form.anrede" 
            :values="anrede"
            :firstEmpty="true">
          </ComboBox>

          <InputField label="Titel" v-model="beteiligte_form.titel" />
          <InputField label="Nachname" v-model="beteiligte_form.nachname"  :validateUntouched="true"/>
          <InputField label="Vorname" v-model="beteiligte_form.vorname" />
          
          <InputField label="Straße/Hausnummer" v-model="beteiligte_form.strasse" />
          <ComboBox
            label="Land"
            v-model="beteiligte_form.land" 
            :values="lands"
            :firstEmpty="true">
          </ComboBox>
          <InputZIP
            label="PLZ"
            v-model="beteiligte_form.plz"
            @city="beteiligte_form.ort = $event"/>
          <InputField label="Ort" v-model="beteiligte_form.ort" />
          <InputField label="Telefon" v-model="beteiligte_form.telefon" inputmode="tel"/>
          <InputField label="E-Mail" v-model="beteiligte_form.email" />

        </div>

        <div class="mt-16px">
          <div class="box__title">Kontodaten für Zahlungen (nur relevant für Firmen)</div>
          <InputCheckBoxItem label="Beteiligter ist Vorsteuerabzugsberechtigt" v-model="beteiligte_form.vorsteuerabzugsberechtigt" />
          <InputField label="Bank" v-model="beteiligte_form.bank" />
          <InputField label="IBAN" v-model="beteiligte_form.iban" />
          <InputField label="BIC" v-model="beteiligte_form.bic" />
        </div>

        
        <div class="mt-16px" v-if="istKFZSparte">
          <div class="box__title">Führerscheindaten des Fahrers</div>
          <InputField label="Führerscheinnummer" v-model="beteiligte_form.ausweisnr" :disabled="!beteiligte_form.rollen.fahrer"/>
          <InputField label="Klassen" v-model="beteiligte_form.zusatzInfos" :disabled="!beteiligte_form.rollen.fahrer"/>
          <DatePickerField label="Ausstellungsdatum" :isComponentHalfSize="true" v-model="beteiligte_form.ausstellungsdatum" :disabled="!beteiligte_form.rollen.fahrer"/> 
        </div>
        {{ isFormInvalid }}
      </template>

    </BaseModal>

    <BaseModal ref="beteiligteDeleteModal" 
      modalTitle="Eintrag löschen"
      labelButtonConfirm="Löschen"
      @onConfirmButton="deleteBeteiligte(beteiligteToDelete)" 
      @close="clearBeteiligteToDelete()">
      <div v-if="beteiligteToDelete">
        Wollen Sie die Eintrag "{{beteiligteToDelete.vorname}}, {{beteiligteToDelete.nachname}}" wirklich löschen?
      </div>
    </BaseModal>
  </div>
</template>

<script>
import Vue from 'vue';
import validator from "@/mixins/validator";
import { required, requiredIf } from "@/mixins/validator/rules";
import { PhPencilLine, PhTrash, PhBookOpen } from 'phosphor-vue';
import { mapGetters } from 'vuex';
import SCHADENSMELDUNG_TYPES from "@/store/schadensmeldung/types";
import CUSTOMER_SEARCH_TYPES from '@/store/customerSearch/types';

import BaseButton from '@/components/core/BaseButton.vue';
import InputField from '@/components/core/forms/InputField.vue';
import InputCheckBoxItem from '@/components/core/forms/checkbox/InputCheckBoxItem.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';
import Table from '@/components/table/Table.vue';
import InputZIP from '@/components/core/forms/InputZIP.vue';
import { BaseModalSimpleAction } from '@/components/core/base-modal-actions/base-modal-actions-utils';
import CORE_TYPES from '@/store/core/types'

const TABLE_HEADERS = {
  vorname: {
    label: 'Vorname',
    sortable: true,
    filterable: false,
    dataType: 'String',
    key: 'vorname',
    visible: true,
  },
  nachname: {
    label: 'Nachname',
    sortable: true,
    filterable: false,
    dataType: 'String',
    key: 'nachname',
    visible: true,
  },
  rollenFahrer: {
    label: 'Fahrer',
    sortable: true,
    filterable: false,
    dataType: 'Slot',
    key: 'rollenFahrer',
    visible: true,
  },
  rollenGeschadigter: {
    label: 'Geschädigter',
    sortable: true,
    filterable: false,
    dataType: 'Slot',
    key: 'rollenGeschadigter',
    visible: true,
  },
  rollenVerursacher: {
    label: 'Verursacher',
    sortable: true,
    filterable: false,
    dataType: 'Slot',
    key: 'rollenVerursacher',
    visible: true,
  },
  rollenZeuge: {
    label: 'Zeuge',
    sortable: true,
    filterable: false,
    dataType: 'Slot',
    key: 'rollenZeuge',
    visible: true,
  },
  rollenSachverstandiger:{
    label: 'Sachverständiger',
    sortable: true,
    filterable: false,
    dataType: 'Slot',
    key: 'rollenSachverstandiger',
    visible: true,
  },
  zusatzInfos:{
    label: 'Zusatz',
    sortable: true,
    filterable: true,
    dataType: 'String',
    key: 'zusatzInfos',
    visible: true,
  },
 
};
const TABLE_HEADER_ACTION = {
  actions: {
    label: 'Aktionen',
    key: 'actions',
    dataType: 'tableAction',
    visible: true,
  },
};
const TABLE_ACTIONS = [{
  legend: {
    icon: PhPencilLine,
    index: 1,
    key: 'EDIT',
    label: 'Bearbeiten',
  }
}, {
  legend: {
    icon: PhTrash,
    index: 3,
    key: 'DELETE',
    label: 'Löschen',
  }
}];


export default {
  mixins: [validator],
  props: {
    schadenId: {
      type: [String, Number],
    },
    editMode: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      beteiligte_form: {},
      beteiligteToDelete: null,
      editing: false,
      inserting: false,
      tableActions: TABLE_ACTIONS,
    };
  },
  computed: {
    ...mapGetters({
      schadensmeldung: SCHADENSMELDUNG_TYPES.GETTERS.SCHADENSMELDUNG,
      configData: SCHADENSMELDUNG_TYPES.GETTERS.CONFIG_DATA,
      selectedCustomersExternal: CUSTOMER_SEARCH_TYPES.GETTERS.SET_SELECTED_CUSTOMERS,
      beteiligteEdited: SCHADENSMELDUNG_TYPES.GETTERS.BETEILIGTE_EDITED,
      isFA: CORE_TYPES.GETTERS.IS_FA,
    }),
    beteiligte() {
      const beteiligte = this.schadensmeldung?.beteiligte || [];
      return [...beteiligte];
    },
    sparte(){
      return this.configData?.sparte || '';
    },
    tableHeaders() {
      return {
        ...TABLE_HEADERS,
        ...(this.editMode ? TABLE_HEADER_ACTION : {})
      };
    },
    tableData() {
      return {
        headers: this.tableHeaders,
        records: this.beteiligte,
      };
    },
    lands() {
      return this.configData?.lands || [];
    },
    anrede(){
       return this.configData?.anrede || [];
    },
    fieldsValidators() {
      const validator = {
        beteiligte_form: {
          nachname: [required()],
          bettext: [requiredIf("Sie haben 'Sonstiges' als Rolle angekreuzt. Bitte geben Sie die Rolle im Textfeld an.", () => this.beteiligte_form?.rollen?.sonstiges)]
        }
      }

      return validator;
    },
    isFormInvalid() {
      return this.validation.invalid;
    },
    istKFZSparte() {
      return 'KFZ-Versicherung'.toLowerCase() === this.sparte?.toLowerCase();
    },
    baseModalActions() {
      const modalActions = [];

      modalActions.push(BaseModalSimpleAction('CANCEL', 'Abbrechen')
        .withPrimary(() => false)
        .withDisabled(() => this.loading));
      modalActions.push(BaseModalSimpleAction('SAVE_PERSON_DATA', 'Speichern')
        .withLoading(() => this.loading)
        .withDisabled(() => this.loading || this.isFormInvalid)
        .withReplacementActionForCloseOnSmallScreen(() => true));

      return modalActions;
    },
  },
  methods: {
    getNewBeteiligte() {
      return {
        id: null,
        titel:"",
        vorname: "",
        nachname: "",
        strasse: "",
        land: this.isFA ? "Österreich" : "Deutschland",
        ort: "",
        plz: "",
        telefon: "",
        email: "",
        bank: "",
        iban: "",
        bic: "",
        rollen: {
          geschadigter: false,
          verursacher: false,
          fahrer: false,
          zeuge: false,
          sachverstandiger: false,
          sonstiges: false,
        },
        bettext: '',
        ausstellungsdatum:"",
        vorsteuerabzugsberechtigt: false,
        anrede:"",
        ausweisnr: "",
        zusatzInfos: "",
        ausstellungsdatum:null,
        schadenId: null
      };
    },
    async openBeteiligteModal() {
      await this.$refs.beteiligteModal.open();
      this.$configureValidators(this.fieldsValidators);
    },
    closeBeteiligteModal() {
      this.$refs.beteiligteModal.close();
      this.clearSelectedPerson();
    },
    openBeteiligteDeleteModal(whatBeteiligte) {
      this.$refs.beteiligteDeleteModal.open();
      this.beteiligteToDelete = whatBeteiligte;
    },
    neueBeteiligte() {
      this.beteiligte_form = this.getNewBeteiligte();
      this.editing = false;
      this.inserting = true;
      this.openBeteiligteModal();
    },
    editBeteiligte(whatBeteiligte) {
      this.beteiligte_form = whatBeteiligte;
      this.editing = true;
      this.inserting = false;
      this.openBeteiligteModal();
    },
    deleteBeteiligte(whatBeteiligte) {
      const beteiligte_form = whatBeteiligte;
      beteiligte_form.schadenId = this.schadenId;
      this.$store.dispatch(SCHADENSMELDUNG_TYPES.ACTIONS.DELETE_BETEILIGTE, beteiligte_form);
    },
    clearBeteiligteToDelete() {
      this.beteiligteToDelete = null;
    },
    sendFormBeteiligte() {
      if(this.isFormInvalid) {
        return;
      }
      this.loading = true;
      this.beteiligte_form.schadenId = this.schadenId;
      this.beteiligte_form.fahrerDataSet = !!(this.beteiligte_form.ausweisnr || this.beteiligte_form.zusatzInfos || this.beteiligte_form.ausstellungsdatum);
      
      this.$store.dispatch(SCHADENSMELDUNG_TYPES.ACTIONS.SEND_BETEILIGTE, this.beteiligte_form)
        .then(() => this.closeBeteiligteModal())
        .catch((error) => console.log(error))
        .finally(() => this.loading = false);
    },
    beteiligteSpeichern() {
      this.editing = false;
      this.inserting = false;
      this.sendFormBeteiligte();
      this.clearSelectedPerson();
    },
    cancelEditing() {
      this.editing = false;
      this.inserting = false;
      this.beteiligte_form = this.getNewBeteiligte();
    },
    handleTableAction(event) {
      if(event.action?.legend?.key === 'EDIT') {
        this.editBeteiligte(event?.value);
      } else if(event.action?.legend?.key === 'DELETE') {
        this.openBeteiligteDeleteModal(event?.value);
      }
    },
    whenSonstigesChanged(event) {
      if(!event) {
        this.beteiligte_form.bettext = '';
        this.validation.reset('beteiligte_form.bettext');
      }
    },
    handleAddUsers() {
      this.$store.commit(SCHADENSMELDUNG_TYPES.MUTATIONS.BETEILIGTE_EDITED, this.beteiligte_form);
      this.navigateTo(`/communication/mailcomposer-customer-search/allgemeineVertragsdaten?insurance=true&back=${this.$router.currentRoute.path}?stepKey=beteiligte`);
    },
    navigateTo(event) {
      this.$router.push({
        path: event?.path ? event.path : event,
        query: {
          singleUser: this.$route.query?.singleUser,
          ...(event?.query ? event.query : {})
        },
      });
    },
    clearSelectedPerson() {
      this.$store.commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.SET_SELECTED_CUSTOMERS,[]);
    },
    mapSelectedPersonToBeteiligte(selectedPerson) {
      if(!selectedPerson) {
        return;
      }
      this.beteiligte_form.anrede = selectedPerson.title;
      const nameSplited = selectedPerson?.name?.split(',');
      this.beteiligte_form.nachname = nameSplited[0]?.trim();
      this.beteiligte_form.vorname = nameSplited[1]?.trim();
      this.beteiligte_form.strasse = selectedPerson.street;
      this.beteiligte_form.ort = selectedPerson.city;
      this.beteiligte_form.plz = selectedPerson.zip;
      this.beteiligte_form.telefon = selectedPerson.telefon;
      this.beteiligte_form.email = selectedPerson.email;


    },
  },
  mounted() {
    if(this.selectedCustomersExternal?.length) {
      this.neueBeteiligte();
      if(this.beteiligteEdited) {
        Vue.set(this, 'beteiligte_form', this.beteiligteEdited);
        this.mapSelectedPersonToBeteiligte(this.selectedCustomersExternal[0]);
      }

    }
  },
  validators: {},
  components: {
    BaseButton,
    InputField,
    InputCheckBoxItem,
    ComboBox,
    DatePickerField,
    BaseModal,
    AnimatedSpinner,
    Table,
    InputZIP,
    PhBookOpen,
  },
}
</script>
