<template>
  <BaseModal ref="modal"
      labelButtonConfirm="Ja"
     :showCloseButton="true"
      labelButtonCancel="Nein"
      @onConfirmButton="call"
      @onCancelButton="close"
      @onCloseButton="close"
      >
    <template v-slot:modalTitle>
      Ausgehender Anruf
    </template>
        Möchten Sie
    <strong>
      {{pn}} 
  
    </strong> wirklich wählen?
    <div></div>
  </BaseModal>
</template>

<script>
import { mapGetters } from 'vuex'

import BaseModal from '@/components/core/BaseModal.vue';

import TAPI from '../../store/tapi/types'
export default {
  data() {
    return {
        pn:''
    };
  },
  computed: {
  ...mapGetters({
   
   
      lineTapi: TAPI.GETTERS.LINE,
      canOpenCallTapi: TAPI.GETTERS.CAN_OPEN_CALL_OUT_DIALOG
    }),
 
    displayName() {
      return  this.canOpenCallTapi;
    },
    visible() {
      
      return this.canOpenCallTapi;
    },
   
  },
  props: {
   
    showCloseIcon: {
      type: Boolean,
      default: false,
    }    
  },
  watch: {
    visible(newValue) {
      
      if (newValue){

         this.open();
      }else{
         this.close();
      }
    }
  },
  methods: {
   call() {
      this.$store.commit(TAPI.MUTATIONS.PHONE_NUMBER,this.pn); 
      let  call ={};
      call.userType=this.$store.getters[TAPI.GETTERS.CALL_OUT_USER_TYPE];
      call.userId=this.$store.getters[TAPI.GETTERS.CALL_OUT_US_ID];
      this.$store.commit(TAPI.MUTATIONS.CALLER,call); 
      this.$store.dispatch(TAPI.ACTIONS.CONNECT,{param:"DIALOUT"});    
      
       this.close();
     
    },
    open(){
     
        this.pn=this.$store.getters[TAPI.MUTATIONS.CALL_OUT_NUMBER];
        this.$refs.modal?.open();
    },
    close() {
      this.beforeClose(); 
      this.$refs.modal.close();
    },
    beforeClose(){
      this.$store.commit(TAPI.MUTATIONS.CALL_OUT_USER_TYPE,null); 
      this.$store.commit(TAPI.MUTATIONS.CALL_OUT_US_ID,null); 
      this.$store.commit(TAPI.MUTATIONS.CALL_OUT_NUMBER,null); 
    }

  },
  components: {
    BaseModal,
   },
  
}
</script>

<style scoped>
