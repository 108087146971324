export const MUTATION_PREFIX = 'CREDIT: ';
export const ACTIONS_PREFIX = 'CREDIT_ACTIONS_';
export const GETTERS_PREFIX = 'CREDIT_GETTERS_';

export default {
  MUTATIONS: {
    FIND_CREDITS_SUCCESS: MUTATION_PREFIX + 'FIND_CREDITS_SUCCESS',
    SET_SELECTED_CREDIT_ID: MUTATION_PREFIX + 'SET_SELECTED_CREDIT_ID',
    GET_COMBOS_SUCCESS: MUTATION_PREFIX + 'GET_COMBOS_SUCCESS',
    GET_CREDIT_SUCCESS: MUTATION_PREFIX + 'GET_CREDIT_SUCCESS',
    APPEND_CREDIT_EDITED: MUTATION_PREFIX + 'APPEND_CREDIT_EDITED',
    RESET_CREDIT_EDITED: MUTATION_PREFIX + 'RESET_CREDIT_EDITED',
    RESET_CREDIT_STATE: MUTATION_PREFIX + 'RESET_CREDIT_STATE',
    SET_RIGHTS: MUTATION_PREFIX + 'SET_RIGHTS',

    FIND_UPDATES_SUCCESS: MUTATION_PREFIX + 'FIND_UPDATES_SUCCESS',

    FIND_STATUS_SUCCESS: MUTATION_PREFIX + 'FIND_STATUS_SUCCESS',

    FIND_APPOINTMENTS_SUCCESS: MUTATION_PREFIX + 'FIND_APPOINTMENTS_SUCCESS',

    GET_PROHYP_COMBOS_SUCCESS: MUTATION_PREFIX + 'GET_PROHYP_COMBOS_SUCCESS',
    GET_PROHYP_SUCCESS: MUTATION_PREFIX + 'GET_PROHYP_SUCCESS',
    APPEND_PROHYP_EDITED: MUTATION_PREFIX + 'APPEND_PROHYP_EDITED',
    RESET_PROHYP_EDITED: MUTATION_PREFIX + 'RESET_PROHYP_EDITED',

    FIND_BUCHUNGEN_SUCCESS: MUTATION_PREFIX + 'FIND_BUCHUNGEN_SUCCESS',
    SET_SELECTED_BUCHUNG: MUTATION_PREFIX + 'SET_SELECTED_BUCHUNG',
    FIND_BUCHUNG_PROV_STRUKTUREN_SUCCESS: MUTATION_PREFIX + 'FIND_BUCHUNG_PROV_STRUKTUREN_SUCCESS',

    ADD_HINT: MUTATION_PREFIX + 'ADD_HINT',
    RESET_HINTS: MUTATION_PREFIX + 'RESET_HINTS',

    FIND_DOCUMENTS_SUCCESS: MUTATION_PREFIX + 'FIND_DOCUMENTS_SUCCESS', // TODO remove after migrate Kredit Dokumente to Dokumentenarchiv
  },
  ACTIONS: {
    FIND_CREDITS: ACTIONS_PREFIX + 'FIND_CREDITS',
    GET_COMBOS: ACTIONS_PREFIX + 'GET_COMBOS',
    GET_CREDIT: ACTIONS_PREFIX + 'GET_CREDIT',
    SAVE_ONLY: ACTIONS_PREFIX + 'SAVE_ONLY',
    SAVE_CREDIT: ACTIONS_PREFIX + 'SAVE_CREDIT',
    DELETE_CREDIT: ACTIONS_PREFIX + 'DELETE_CREDIT',

    FIND_UPDATES: ACTIONS_PREFIX + 'FIND_UPDATES',
    SAVE_UPDATE: ACTIONS_PREFIX + 'SAVE_UPDATE',
    DELETE_UPDATE: ACTIONS_PREFIX + 'DELETE_UPDATE',

    FIND_STATUS: ACTIONS_PREFIX + 'FIND_STATUS',
    SAVE_STATUS: ACTIONS_PREFIX + 'SAVE_STATUS',
    DELETE_STATUS: ACTIONS_PREFIX + 'DELETE_STATUS',

    FIND_APPOINTMENTS: ACTIONS_PREFIX + 'FIND_APPOINTMENTS',

    GET_PROHYP_COMBOS: ACTIONS_PREFIX + 'GET_PROHYP_COMBOS',
    GET_PROHYP: ACTIONS_PREFIX + 'GET_PROHYP',
    SAVE_PROHYP: ACTIONS_PREFIX + 'SAVE_PROHYP',
    GET_PROHYP_PORTAL_URL: ACTIONS_PREFIX + 'GET_PROHYP_PORTAL_URL',
    SEND_TO_PROHYP: ACTIONS_PREFIX + 'SEND_TO_PROHYP',
    UPDATE_FROM_PROHYP: ACTIONS_PREFIX + 'UPDATE_FROM_PROHYP',

    FIND_BUCHUNGEN: ACTIONS_PREFIX + 'FIND_BUCHUNGEN',
    FIND_BUCHUNG_PROV_STRUKTUREN: ACTIONS_PREFIX + 'FIND_BUCHUNG_PROV_STRUKTUREN',

    FIND_DOCUMENTS: ACTIONS_PREFIX + 'FIND_DOCUMENTS', // TODO remove after migrate Kredit Dokumente to Dokumentenarchiv
  },
  GETTERS: {
    CREDITS: GETTERS_PREFIX + 'CREDITS',
    SELECTED_CREDIT_ID: GETTERS_PREFIX + 'SELECTED_CREDIT_ID',
    IS_NEW: GETTERS_PREFIX + 'IS_NEW',
    COMBOS: GETTERS_PREFIX + 'COMBOS',
    CREDIT: GETTERS_PREFIX + 'CREDIT',
    CREDIT_EDITED: GETTERS_PREFIX + 'CREDIT_EDITED',

    UPDATES: GETTERS_PREFIX + 'UPDATES',

    STATUS: GETTERS_PREFIX + 'STATUS',

    APPOINTMENTS: GETTERS_PREFIX + 'APPOINTMENTS',
    APPOINTMENT_COMBOS: GETTERS_PREFIX + 'APPOINTMENT_COMBOS',

    PROHYP_COMBOS: GETTERS_PREFIX + 'PROHYP_COMBOS',
    PROHYP: GETTERS_PREFIX + 'PROHYP',
    PROHYP_EDITED: GETTERS_PREFIX + 'PROHYP_EDITED',
    RIGHTS: GETTERS_PREFIX + 'RIGHTS',

    BUCHUNGEN: GETTERS_PREFIX + 'BUCHUNGEN',
    SELECTED_BUCHUNG: GETTERS_PREFIX + 'SELECTED_BUCHUNG',
    BUCHUNG_PROV_STRUKTUREN: GETTERS_PREFIX + 'BUCHUNG_PROV_STRUKTUREN',

    HINTS: GETTERS_PREFIX + 'HINTS',

    DOCUMENTS: GETTERS_PREFIX + 'DOCUMENTS', // TODO remove after migrate Kredit Dokumente to Dokumentenarchiv
  }
}
