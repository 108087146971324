import NAFI_TYPES from '@/store/nafi/types';
import { mapGetters } from 'vuex';
import { StepperMediator } from '@/components/stepper/utils';

const mixin = {
    computed: {
        ...mapGetters({
            zugang: NAFI_TYPES.GETTERS.NAFI_ZUGANGSDATEN,
        }),
        stepper() {
            return new StepperMediator([
                { stepKey: 'maklerdaten', label: 'Vermittlerdaten' },
                { stepKey: 'anwenderdaten', label: 'Anwenderdaten'},
                { stepKey: 'zugangsdaten', label: 'Zugangsdaten', warning: !this.zugang?.anwenderKennwort},
            ]);
        },
    },
    watch: {
        zugang(value) {
            this.initForm();
        }
    },
    methods: {
        initForm() {
            if (this.zugang) {
                this.form = Object.assign(this.form || {}, this.zugang);
            }
        },
        updateNafiStore(componentId, value) {
            if (componentId) {
                this.$store.commit(NAFI_TYPES.MUTATIONS.UPDATE_NAFI_STORE, {componentId, value});
            }
        },
    }
}

export default mixin;