<template>
  <div class="card clickable" @click="cardClickEvent()" >
    <div class="card-content">
      <template v-if="title">
        <h4 v-if="titleStyle" :class="titleStyle">{{ title }}</h4>
        <h4 v-else>{{ title }}</h4>
      </template>
      <slot name="title"></slot>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "card",

  props: {
    title: {
      type: String,
      default: "",
    },
    titleStyle: {
      type: String,
      default: "color-text",
    },
  },
  methods: {
    cardClickEvent() {
      this.$emit("cardClickEvent");
    }
  }
};
</script>
<style scoped>
.card {
  background-color: var(--color-box);;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  width: 100%;
  max-width: 100%;
  position: relative;
}
.card-content {
  background-color: transparent;
  padding: 1.5rem;
}

.card h4 {
  word-wrap: break-word;
}
</style>
