var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.showAsTable
      ? _c(
          "div",
          [
            _c("SortableTable", {
              ref: "sortableTable",
              attrs: {
                headers: _vm.legendHeaders,
                rows: _vm.rowsWithId,
                scrollHorizontally: false,
                pageSize: _vm.pageSize,
                page: _vm.pageIndex,
                maxHeaderLines: 1,
                sortedManually: _vm.sortedManually,
                sortableRows: _vm.sortRows
              },
              on: { sort: _vm.onSort, orderChanged: _vm.onOrderChanged },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header_marker",
                    fn: function() {
                      return [
                        _c("InputCheckBoxItem", {
                          staticStyle: { "margin-left": "3px" },
                          attrs: {
                            value: _vm.areAllSelected,
                            indeterminate: _vm.areOnlySomeSelected,
                            tid: "legendTable"
                          },
                          on: { input: _vm.changeStatus }
                        })
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "marker",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("div", {
                          staticClass: "like-link",
                          style: row.marker,
                          on: {
                            click: function($event) {
                              return _vm.onClickLegend(row.name)
                            }
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "name",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "like-link",
                            style: row.styName,
                            on: {
                              click: function($event) {
                                return _vm.onClickLegend(row.name)
                              },
                              mouseover: function($event) {
                                return _vm.changeHighlight(row.name, true)
                              },
                              mouseleave: function($event) {
                                return _vm.changeHighlight(row.name, false)
                              }
                            }
                          },
                          [_vm._v(_vm._s(row.name))]
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                3060069712
              )
            }),
            _vm.legendRows.length > _vm.pageSize
              ? _c("div", { staticClass: "table-pagination" }, [
                  _c(
                    "div",
                    { staticClass: "table-pagination--left" },
                    [
                      _c("PageRowCount", {
                        attrs: {
                          displayedItems: _vm.displayedRowCount,
                          totalRows: _vm.legendRows.length
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "table-pagination--right" },
                    [
                      _vm.displayedRowCount < _vm.legendRows.length
                        ? _c("Pagination", {
                            attrs: {
                              totalPages: _vm.pageCount,
                              currentPage: _vm.pageIndex
                            },
                            on: { pagechanged: _vm.onPageChange }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ])
              : _vm._e()
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "raster" },
          _vm._l(_vm.legendRows, function(row) {
            return _c(
              "div",
              {
                key: row.name,
                on: {
                  click: function($event) {
                    return _vm.onClickLegend(row.name)
                  },
                  mouseover: function($event) {
                    return _vm.changeHighlight(row.name, true)
                  },
                  mouseleave: function($event) {
                    return _vm.changeHighlight(row.name, false)
                  }
                }
              },
              [
                _c("div", [
                  _c("div", { staticClass: "legend-marker" }, [
                    _c("div", { style: row.marker })
                  ]),
                  _c(
                    "div",
                    { staticClass: "legend-name", style: row.styName },
                    [_vm._v(_vm._s(row.name))]
                  )
                ]),
                _vm.addOnHeaders && _vm.addOnHeaders.length
                  ? _vm._l(_vm.addOnHeaders, function(col, idx) {
                      return _c("div", { key: idx }, [
                        row[col.key]
                          ? _c("div", [
                              _c("span", { staticClass: "label-small" }, [
                                _vm._v(_vm._s(col.label) + " ")
                              ]),
                              _c("span", { staticClass: "font-bold" }, [
                                _vm._v(_vm._s(_vm.displayValue(row, col)))
                              ]),
                              col.props && col.props.symbol
                                ? _c("span", [
                                    _vm._v(" " + _vm._s(col.props.symbol))
                                  ])
                                : _vm._e()
                            ])
                          : _vm._e()
                      ])
                    })
                  : _vm._e()
              ],
              2
            )
          }),
          0
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }