<template>
  <BaseCollapsable>
    <template v-slot:title>
      Erweiterter Filter
    </template>
    <template v-slot:content>
      <div>
        <div class="row">
          <InputField class="col-12 col-md-4" v-model="filterText" :placeholder="nameDescriptionPlaceholder" label="Text" @input="emitChange"/>
          <InputField class="col-12 col-md-4" v-model="filterMinAmount" label="Betrag von" type="currency" @input="emitChange"/>
          <InputField class="col-12 col-md-4" v-model="filterMaxAmount" label="Betrag bis" type="currency" @input="emitChange"/>
          <div class="col-12 col-md-4" v-if="showTransactions">
            <ComboBox v-model="filterCategory" 
              :values="categoryFlatComboValues"
              firstEmpty
              @input="emitChange">
            </ComboBox>
          </div>
        </div>
        <BaseButton @click="cleanFilters()">
          Filter zurücksetzen
        </BaseButton>
      </div>
    </template>
  </BaseCollapsable>
</template>

<script lang="ts">
import { mapGetters } from 'vuex'
import MULTIBANKING_TYPES from '../../store/multiBanking/types'
import InputField from '../core/forms/InputField.vue'
import BaseButton from '../core/BaseButton.vue'
import BaseCollapsable from '../core/BaseCollapsable.vue'
import ComboBox from '@/components/core/forms/ComboBox.vue'

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    showDepotPositions: {
      type: Boolean,
    },
    showTransactions: {
      type: Boolean,
    },
  },

  components: {
    InputField,
    BaseButton,
    BaseCollapsable,
    ComboBox,
  },

  computed: {
    ...mapGetters({
      categoryTree: MULTIBANKING_TYPES.GETTERS.CATEGORY_TREE,
    }),
    categoryFlat() {
      let result = []
      if (this.categoryTree)
        this.categoryTree.forEach(parentCategory => {
          result.push(parentCategory)
          result = result.concat(parentCategory.children)
        })
      return result
    },
    categoryFlatComboValues() {
      if (!this.categoryFlat) {
        return []
      }

      return this.categoryFlat.map(cat => ({
        label: cat.displayName,
        value: cat.category,
      }))
    },
    nameDescriptionPlaceholder() {
      const placeholder = ['Name', 'Konto']
      if (this.showTransactions)
        placeholder.push('Verwendungszweck', 'Gegenkonto')
      if (this.showDepotPositions)
        placeholder.push('ISIN', 'WKN', 'Venue')
      return placeholder.join(', ')
    },
  },

  data() {
    return {
      filterText: this.value && this.value.text || null,
      filterMinAmount: this.value && this.value.minAmount || null,
      filterMaxAmount: this.value && this.value.maxAmount || null,
      filterCategory: this.value && this.value.category || null,
      extendedFilter: false,
    }
  },

  methods: {
    emitChange() {
      this.$emit('input', {
        text: this.filterText ? this.filterText.toLowerCase() : null,
        minAmount: this.filterMinAmount || null,
        maxAmount: this.filterMaxAmount || null,
        category: this.filterCategory,
      })
    },
    cleanFilters() {
      this.filterText = null
      this.filterMinAmount = null
      this.filterMaxAmount = null
      this.filterCategory = null
      this.emitChange()
    },
  },
}
</script>

<style scoped>
a {
  cursor: pointer;
}
.kvfilter-parent {
  background: var(--color-secondary);
}
</style>