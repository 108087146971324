var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      !_vm.isSmallScreen
        ? _c(
            "BaseButton",
            {
              on: {
                click: function($event) {
                  return _vm.openAddModal()
                }
              }
            },
            [_vm._v("Neue Anpassung")]
          )
        : _vm._e(),
      !_vm.loading && _vm.rows.length
        ? _c("Table", {
            attrs: {
              tableId: "5d753192-f0fe-426d-82d3-32c8080572e9",
              headers: _vm.headers,
              rows: _vm.rows,
              mobileConfig: {
                title: "Anpassungen",
                headers: ["reason", "date", "aktWert"],
                disableClickRow: false
              },
              rowsPerPage: 10
            },
            on: {
              "click-reason": _vm.openEditModal,
              "action-DELETE": _vm.openDeleteModal
            }
          })
        : _vm.loading
        ? _c(
            "div",
            { staticClass: "text-centered" },
            [_c("AnimatedSpinner")],
            1
          )
        : _c("div", [_vm._v("Keine Daten")]),
      _c("BaseModal", {
        ref: "anpassungDataModal",
        attrs: {
          size: "sm",
          modalTitle: "Wert anpassen",
          actions: _vm.baseModalActions,
          showDefaultButtons: false
        },
        on: {
          "action-SPEICHERN": function($event) {
            return _vm.actionSpeichern()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c("InputField", {
                  attrs: { label: "Anpassungsgrund", validateUntouched: true },
                  model: {
                    value: _vm.update.reason,
                    callback: function($$v) {
                      _vm.$set(_vm.update, "reason", $$v)
                    },
                    expression: "update.reason"
                  }
                }),
                _c("DatePickerField", {
                  attrs: {
                    isValueAsString: "",
                    label: "Anpassungsdatum",
                    validateUntouched: true
                  },
                  model: {
                    value: _vm.update.date,
                    callback: function($$v) {
                      _vm.$set(_vm.update, "date", $$v)
                    },
                    expression: "update.date"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    label: _vm.aktWertLabel,
                    type: "currency",
                    validateUntouched: true
                  },
                  model: {
                    value: _vm.update.aktWert,
                    callback: function($$v) {
                      _vm.$set(_vm.update, "aktWert", $$v)
                    },
                    expression: "update.aktWert"
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c(
                  "BaseButton",
                  {
                    staticClass: "mr-3",
                    attrs: { isSecondary: "" },
                    on: {
                      click: function($event) {
                        return _vm.closeAnpassungDataModal()
                      }
                    }
                  },
                  [_vm._v("Abbrechen")]
                ),
                _c(
                  "BaseButton",
                  {
                    attrs: { disabled: _vm.validation.isInvalid("update") },
                    on: {
                      click: function($event) {
                        _vm.saveUpdate()
                        _vm.closeAnpassungDataModal()
                      }
                    }
                  },
                  [_vm._v("Speichern")]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("BaseModal", {
        ref: "anpassungDeleteModal",
        attrs: { size: "sm", modalTitle: "Anpassung löschen" },
        on: {
          onConfirmButton: function($event) {
            return _vm.deleteUpdate()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [_vm._v(" Soll die Anpassung wirklich gelöscht werden? ")]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }