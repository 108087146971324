var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("GesellschaftTitleNavigation", {
        attrs: { headerActions: _vm.headerActions },
        on: { "action-CREATE_ACTIVITY": _vm.editOrCreateAppointment }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", title: _vm.TABLE_TITLE }
              })
            : _c("Table", {
                attrs: {
                  rowId: "rowId",
                  title: _vm.TABLE_TITLE,
                  rows: _vm.rows,
                  headers: _vm.headers,
                  rowsPerPage: 20
                },
                on: {
                  "click-subject": _vm.editOrCreateAppointment,
                  "action-DELETE": _vm.deleteRow
                },
                scopedSlots: _vm._u([
                  {
                    key: "participants",
                    fn: function(row) {
                      return [
                        _c(
                          "ul",
                          { staticStyle: { padding: "0", margin: "0" } },
                          _vm._l(row.participants, function(
                            participant,
                            index
                          ) {
                            return _c(
                              "li",
                              {
                                key: participant.id,
                                staticStyle: { display: "inline-block" }
                              },
                              [
                                _c("span", [_vm._v(_vm._s(participant.name))]),
                                index < row.participants.length - 1
                                  ? _c("span", [_vm._v("; ")])
                                  : _vm._e()
                              ]
                            )
                          }),
                          0
                        )
                      ]
                    }
                  }
                ])
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }