<template>
  <div class="box__container">
    <InputField
      label="Produktbezeichnung"
      :isComponentHalfSize="true"
      :value="produktbezeichnung"
      :disabled="true"
    />

    <AbrechnungenTable />

    <VerteilungAbrechnungStrukturTable />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import OTHER_PRODUCTS_TYPES from '@/store/otherProducts/types';

import InputField from '@/components/core/forms/InputField.vue';

import AbrechnungenTable from './abrechnung/AbrechnungenTable.vue';
import VerteilungAbrechnungStrukturTable from './abrechnung/VerteilungAbrechnungStrukturTable.vue';


export default {
  data() {
    return {
      selectedItem: null,
    }
  },
  computed: {
    ...mapGetters({
      otherProduct: OTHER_PRODUCTS_TYPES.GETTERS.OTHER_PRODUCT,
    }),
    produktbezeichnung() {
      return `${this.otherProduct?.produktart} ${this.otherProduct?.produktbez || ''}`.trim();
    },
  },
  components: {
    InputField,
    AbrechnungenTable,
    VerteilungAbrechnungStrukturTable,
  },
}
</script>
