import INFOFILME_TYPES from './types';
import { getInitialState } from './index'
import Vue from 'vue';

export default {
  [INFOFILME_TYPES.MUTATIONS.SET_INFOFILME](state, payload) {
    if (payload.row !== undefined ) {
      let idx = -1;
      if (payload.row.ID) {
        idx = state.infofilme.rows.findIndex(r => r.ID === payload.row.ID );
      }
      if (idx == -1) {
        state.infofilme.rows.unshift(payload.row);
      } else {
        state.infofilme.rows[idx] = payload.row;
      }
      state.infofilme = {...state.infofilme};
    } else {
      state.infofilme = {...state.infofilme, ...payload};
    }
  }, 
  [INFOFILME_TYPES.MUTATIONS.SET_FILTER](state, payload) {
    state.filter = payload;
  }, 

  
}