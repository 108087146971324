var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { key: _vm.configDefinitionId },
    [
      _c("ConfigRender", {
        attrs: {
          componentsDef: _vm.componentsDef,
          configGroupLabel: _vm.configGroupLabel,
          data: _vm.data,
          changedData: _vm.changedData,
          defaultData: _vm.defaultData,
          isLoading: _vm.loading
        },
        on: {
          change: _vm.onChange,
          remove: _vm.onRemove,
          discardChanges: _vm.onDiscardChanges,
          saveChanges: _vm.saveChanges,
          executeConfigButton: _vm.executeConfigButton,
          setStep: _vm.onChangeStep,
          setSubstep: _vm.onChangeSubstep
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }