var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "messageContainerEl", staticClass: "message__container" },
    [
      _c(
        "transition-group",
        { attrs: { name: "slide-fade", tag: "div" } },
        _vm._l(_vm.messages, function(message) {
          return _c(
            "div",
            {
              key: message.id,
              staticClass:
                "message boxes__corner--rounded boxes__shadow--smooth",
              class: {
                "message--success": message.type === "success",
                "message--warning": message.type === "warning",
                "message--primary": message.type === "primary",
                "message--danger": message.type === "danger"
              }
            },
            [
              _c("div", [
                _c("div", { staticClass: "row my-0 message-content" }, [
                  _c("div", { staticClass: "col text-left" }, [
                    _c("div", {
                      staticClass: "message-content--text",
                      domProps: {
                        innerHTML: _vm._s(_vm.sanitize(message.content))
                      }
                    })
                  ])
                ]),
                message.actions
                  ? _c("div", { staticClass: "row mt-2 mb-0" }, [
                      _c("div", { staticClass: "col-auto" }, [
                        _c(
                          "div",
                          { staticClass: "layout__negative-margin--8" },
                          [
                            _vm._l(message.actions, function(action, count) {
                              return [
                                _c("BaseButton", {
                                  key: count,
                                  attrs: {
                                    label: action.label,
                                    isPrimary: action.isPrimary,
                                    isSecondary: action.isSecondary,
                                    isSmall: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.executeAction(message, action)
                                    }
                                  }
                                })
                              ]
                            })
                          ],
                          2
                        )
                      ])
                    ])
                  : _vm._e()
              ]),
              _c(
                "div",
                { staticClass: "button__container" },
                [
                  _c("PhXCircle", {
                    staticClass: "clickable",
                    attrs: { size: 24 },
                    on: {
                      click: function($event) {
                        return _vm.closeMessage(message)
                      }
                    }
                  })
                ],
                1
              )
            ]
          )
        }),
        0
      ),
      _vm.hasMessages
        ? _c(
            "div",
            { staticClass: "close-all__container" },
            [
              _c(
                "BaseButton",
                {
                  staticClass: "close-all__link boxes__shadow--smooth",
                  attrs: { isPrimary: "" },
                  on: {
                    click: function($event) {
                      return _vm.closeAll()
                    }
                  }
                },
                [_vm._v("alle schließen")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }