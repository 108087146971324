var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "box__container" }, [
        _c(
          "div",
          { staticClass: "box__title" },
          [
            _c("CurrencyLabel", {
              attrs: {
                label: "Vermögen heute:",
                showInlineLabel: "",
                value: _vm.gesamtVermoegen(_vm.scenario)
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "box__title" },
          [
            _c("CurrencyLabel", {
              attrs: {
                label: "Wunschrente (zu Rentenbeginn berüksichtig Inflation):",
                showInlineLabel: "",
                value: _vm.wunschrenteMitInflation(_vm.scenario)
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "dashboard-panel__widgets" }, [
        _c(
          "div",
          { staticClass: "box__container dashboard-panel-widget" },
          [
            _c(
              "div",
              { staticClass: "layout__negative-margin--8 pb-3 text-right" },
              [
                _c(
                  "BaseButton",
                  {
                    attrs: { isLink: "" },
                    on: {
                      click: function($event) {
                        return _vm.openGoalModal({ zuflussType: "AUSGABE" })
                      }
                    }
                  },
                  [_vm._v(" Ausgabe hinzufügen ")]
                ),
                _c(
                  "BaseButton",
                  {
                    attrs: { isLink: "" },
                    on: {
                      click: function($event) {
                        return _vm.openGoalModal({ zuflussType: "EINNAHME" })
                      }
                    }
                  },
                  [_vm._v(" Einnahme hinzufügen ")]
                )
              ],
              1
            ),
            _vm.rowsMyGoals && _vm.rowsMyGoals.length
              ? _c("Table", {
                  attrs: {
                    title: "Meine Einahmen und Verbindlichkeiten",
                    rowId: "id",
                    headers: _vm.headersMyGoals,
                    rows: _vm.rowsMyGoals,
                    rowsPerPage: 10,
                    hidePagination: "",
                    mobileConfig: {},
                    selected: _vm.selectedGoals
                  },
                  on: {
                    selected: _vm.onSelectedMyGoals,
                    "action-EDIT": _vm.editGoal,
                    "action-DELETE": _vm.deleteAction,
                    "click-title": _vm.editGoal
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "chart",
                        fn: function(row) {
                          return [
                            _c("PieChart", {
                              staticStyle: { width: "40px" },
                              attrs: {
                                chartData: _vm.eChartData(row),
                                customColors: _vm.eChartColors,
                                isTooltip: false,
                                height: "40px",
                                doughnut: ""
                              }
                            })
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    197734938
                  )
                })
              : _c("NoData", { attrs: { title: "Meine Ziele" } })
          ],
          1
        ),
        _c("div", { staticClass: "box__container dashboard-panel-widget" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c(
                "div",
                { staticClass: "mt-3 mb-3" },
                [
                  _vm.isLoading ? [_c("AnimatedSpinner")] : _vm._e(),
                  _c(
                    "div",
                    { class: { "hidden-chart": _vm.isLoading } },
                    [
                      _c("RetirementChart", {
                        attrs: {
                          data: {
                            data: _vm.apexSeriesWithOriginalWert,
                            series: _vm.apexSeriesWithOriginalWert,
                            xaxis: _vm.apexXaxisCurrentScenario,
                            annotations: _vm.apexAnnotationsCurrentScenario,
                            markers: _vm.apexMarkers,
                            dataLabels: { enabled: false },
                            stroke: { curve: "smooth" },
                            autoUpdateSeries: true
                          }
                        }
                      }),
                      _vm.hasOriginalWert
                        ? _c("InputToggleSwitch", {
                            attrs: {
                              label: "Aktueller Wert/Urspürnglicher Wert",
                              inLineLabel: ""
                            },
                            model: {
                              value: _vm.showOriginalWert,
                              callback: function($$v) {
                                _vm.showOriginalWert = $$v
                              },
                              expression: "showOriginalWert"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                2
              )
            ])
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("BaseCollapsable", {
            attrs: { showSection: _vm.showExpanded },
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function() {
                  return [
                    _c("div", { staticClass: "box__title" }, [
                      _vm._v(" Zahlungsplan ")
                    ])
                  ]
                },
                proxy: true
              },
              {
                key: "content",
                fn: function() {
                  return [
                    _vm.tilgungsplanRows(_vm.scenario)
                      ? _c("Table", {
                          attrs: {
                            rowId: "jahr",
                            headers: _vm.headersTilgungsplan,
                            rows: _vm.tilgungsplanRows(_vm.scenario),
                            rowsPerPage: 50,
                            mobileConfig: {}
                          }
                        })
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "goalModal",
          attrs: {
            modalTitle: _vm.goalModalTitle,
            labelButtonConfirm: "Speichern",
            confirmDisabled: !_vm.canSaveGoal
          },
          on: { onConfirmButton: _vm.saveGoal }
        },
        [
          _c("EditForm", {
            ref: "editForm",
            attrs: {
              goal: _vm.selectedGoal,
              comboboxGoalTypes: _vm.comboboxGoalTypes
            },
            on: { isValid: _vm.updateCanSaveGoal }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }