<template>
    <div>
        <RequestAccountDataDeletion/>
    </div>        
</template>
  
<script>  
import RequestAccountDataDeletion from "@/components/core/RequestAccountDataDeletion.vue";

export default {
    name: "DebugView",
    components: {
        RequestAccountDataDeletion,
    },     
}
</script>
  
<style></style>
  