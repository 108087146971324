var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _c("PageHeaderTitleNavigation", {
            attrs: {
              title: "Depotpositionslimit",
              defaultMenu: _vm.optionsMenu,
              actions: _vm.headerActions
            },
            on: {
              "action-REMOVE": function($event) {
                return _vm.loschen()
              },
              "action-RESET": function($event) {
                return _vm.zuruckSetzen()
              }
            }
          }),
          _c(
            "div",
            { staticClass: "box__container limits-box" },
            [
              _c("span", { staticClass: "box__title" }, [
                _vm._v(_vm._s(_vm.fondsName))
              ]),
              _c("br"),
              _vm.limits
                ? [
                    _vm.limits && _vm.limits.id !== null
                      ? [
                          _vm.limits && _vm.limits.data && !_vm.limits.isError
                            ? [
                                _c(
                                  "div",
                                  { staticClass: "container-fluid" },
                                  _vm._l(_vm.limits.data.labels, function(
                                    l,
                                    i
                                  ) {
                                    return _c(
                                      "div",
                                      { key: "row-" + i, staticClass: "row" },
                                      [
                                        _c("p", { staticClass: "col-12" }, [
                                          _vm._v(_vm._s(l))
                                        ])
                                      ]
                                    )
                                  }),
                                  0
                                ),
                                _c("form", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "form-group container-fluid"
                                    },
                                    [
                                      _c("div", { staticClass: "row mb-2" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-12 col-md-6 col-lg-2"
                                          },
                                          [
                                            _vm._v(
                                              "Interne Depotpositionsnummer"
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-12 col-md-6 col-lg-10"
                                          },
                                          [_vm._v(_vm._s(_vm.begleitscheinNr))]
                                        )
                                      ]),
                                      _c("div", { staticClass: "row mb-2" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-12 col-md-6 col-lg-2"
                                          },
                                          [_vm._v("ISIN")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-12 col-md-6 col-lg-10"
                                          },
                                          [_vm._v(_vm._s(_vm.id))]
                                        )
                                      ]),
                                      _c("div", { staticClass: "row mb-2" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-12 col-md-6 col-lg-2"
                                          },
                                          [_vm._v("Währung")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-12 col-md-6 col-lg-10"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.limits.data.currency)
                                            )
                                          ]
                                        )
                                      ]),
                                      _c("div", { staticClass: "row mb-2" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-12 col-md-6 col-lg-2"
                                          },
                                          [_vm._v("Aktueller Kurs")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-12 col-md-6 col-lg-10"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    _vm.limits.data.kurs,
                                                    2,
                                                    _vm.limits.data.currency
                                                  )
                                                )
                                            )
                                          ]
                                        )
                                      ]),
                                      _c("div", { staticClass: "row mb-2" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-12 col-md-6 col-lg-2"
                                          },
                                          [_vm._v("Kursdatum")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-12 col-md-6 col-lg-10"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.limits.data.kursDate)
                                            )
                                          ]
                                        )
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "row mb-2" },
                                        [
                                          _c("InputField", {
                                            attrs: {
                                              label:
                                                "Ausgangswert (Anteilspreis)",
                                              type: "currency",
                                              foreign_currency:
                                                _vm.limits.data.currency
                                            },
                                            model: {
                                              value: _vm.price,
                                              callback: function($$v) {
                                                _vm.price = $$v
                                              },
                                              expression: "price"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "row mb-2" },
                                        [
                                          _c("InputField", {
                                            attrs: {
                                              label: "Abweichung nach unten",
                                              type: "number"
                                            },
                                            model: {
                                              value: _vm.low,
                                              callback: function($$v) {
                                                _vm.low = $$v
                                              },
                                              expression: "low"
                                            }
                                          }),
                                          _c("ComboBox", {
                                            attrs: {
                                              label: " ",
                                              values:
                                                _vm.untenOptionComboOptions
                                            },
                                            model: {
                                              value: _vm.lowRelative,
                                              callback: function($$v) {
                                                _vm.lowRelative = $$v
                                              },
                                              expression: "lowRelative"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "row mb-2" },
                                        [
                                          _c("InputField", {
                                            attrs: {
                                              label: "Abweichung nach oben",
                                              type: "number"
                                            },
                                            model: {
                                              value: _vm.high,
                                              callback: function($$v) {
                                                _vm.high = $$v
                                              },
                                              expression: "high"
                                            }
                                          }),
                                          _c("ComboBox", {
                                            attrs: {
                                              label: " ",
                                              values:
                                                _vm.untenOptionComboOptions
                                            },
                                            model: {
                                              value: _vm.highRelative,
                                              callback: function($$v) {
                                                _vm.highRelative = $$v
                                              },
                                              expression: "highRelative"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "row mb-2" },
                                        [
                                          _c("InputToggleSwitch", {
                                            attrs: { label: "Dynamik" },
                                            model: {
                                              value: _vm.dynamic,
                                              callback: function($$v) {
                                                _vm.dynamic = $$v
                                              },
                                              expression: "dynamic"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c("div", { staticClass: "row mb-2" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-12 col-md-6 col-lg-2"
                                          },
                                          [_vm._v("Ausgangswert für Dynamik")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-12 col-md-6 col-lg-10"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("number")(
                                                  _vm.limits.data.dynamicValue
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ]),
                                      _vm.limits.data.changeDate
                                        ? _c(
                                            "div",
                                            { staticClass: "row mb-2" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-12 col-md-6 col-lg-2"
                                                },
                                                [_vm._v("Letzte Änderung")]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-12 col-md-6 col-lg-10"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.limits.data.changeDate
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.limits.data.dateOutofLimit
                                        ? _c(
                                            "div",
                                            { staticClass: "row mb-2" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-12 col-md-6 col-lg-2"
                                                },
                                                [
                                                  _vm._v(
                                                    "Datum der Überschreitung"
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-12 col-md-6 col-lg-10"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.limits.data
                                                        .dateOutofLimit
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.isBroker || _vm.isByPass
                                        ? _c(
                                            "div",
                                            { staticClass: "row mb-2 w-394" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col-12" },
                                                [
                                                  _c("InputTextArea", {
                                                    attrs: {
                                                      label: "Bemerkung"
                                                    },
                                                    model: {
                                                      value: _vm.comment,
                                                      callback: function($$v) {
                                                        _vm.comment = $$v
                                                      },
                                                      expression: "comment"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ])
                              ]
                            : _vm._e()
                        ]
                      : _vm._e()
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      ),
      _c("BaseModal", {
        ref: "modalRef",
        attrs: {
          modalTitle: "Limit löschen?",
          labelButtonConfirm: "Ja",
          labelButtonCancel: "Nein"
        },
        on: {
          onConfirmButton: function($event) {
            return _vm.action(true)
          },
          onCancelButton: function($event) {
            return _vm.action(false)
          }
        }
      }),
      _c("LimitErrorModal", {
        ref: "modalError",
        attrs: { errorText: _vm.errorFromService },
        on: { closeLimit: _vm.confirmError, resetError: _vm.resetError }
      }),
      _c("BaseModal", {
        ref: "saveOrReset",
        attrs: {
          labelButtonCancel: "Verwerfen",
          labelButtonConfirm: "Speichern"
        },
        on: { onCancelButton: _vm.handleReset, onConfirmButton: _vm.speichern },
        scopedSlots: _vm._u([
          {
            key: "modalTitle",
            fn: function() {
              return [_vm._v(" Möchte Sie Ihre Änderungen speichern? ")]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }