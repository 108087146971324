<template>
  <div>
    <div class="box__container">
      <ComboBox v-if="auswahlTitle"
        :label="auswahlTitle"
        v-model="source"
        :values="sources"
        :isComponentHalfSize="true"
        :sortComboboxValues="false"
      ></ComboBox>
      <template v-if="sparplan">
        <InputField
          v-model="amount"
          type="currency"
          label="Wert"
          :validateUntouched="true"
          :isComponentHalfSize="true"
        ></InputField>    
      </template>
      <template v-if="main">
        <div class="row">
          <div class="col-12 col-sm-6">
            <InputField
              v-model="amount"
              type="currency"
              label="Depotwert"
              :disabled="true"
            ></InputField>    
          </div>
          <div class="col-12 col-sm-6 content-right">
            <div  v-if="source == '-6'" >
              <BaseFileSelect 
                @files="confirmImport" 
                buttonText='Importieren'
                buttonClass='ph-download-simple'
                accept=".xls, .xlsx, .csv"
              />
            </div>
          </div>
        </div>
        
      </template>
      
      <template v-if="asVVSchema != null">
        <InputRadioBoxGroup
          v-model="asVVSchema" :values="vvSchemaOption" title="Backtesting Berechnung"
        />
      </template>
    </div>

    <div class="box__container" v-if="hasTableData">
      <GhostLoading v-if="readingRows">
        <Block height="100" />
      </GhostLoading>
      <Table v-else-if="showedRows.length"
        :headers="headers"
        :rows="showedRows"
        :headerActions="headerActions"
        :tableId="'Anlageempfehlung_' + stepName"
        :showColumnsConfigEvenNoTitle="true"
        rowId="unique"
        v-model="activeRows"
        :rowsPerPage="25"
        :scrollHorizontally="stepName == stepNames.FAVORITES"
        @headerAction="onHeaderActions"
        @action-FACTSHEET="viewDocument($event.FACTSHEET_URL)"
        @action-FACTSHEET_KAG="viewDocument($event.FACTSHEET_KAG_URL)"
        @action-KID="viewDocument($event.KID_URL)"
        @action-PRIIP_BIB="viewDocument($event.PRIIP_BIB_URL)"
        @action-LOCKM$JA="setLock($event,'JA')"
        @action-LOCKM$FEST="setLock($event,'FEST')"
        @action-LOCKM$KEINKAUF="setLock($event,'KEINKAUF')"
        @action-LOCKM$KEINVERKAUF="setLock($event,'KEINVERKAUF')"
        @action-EDIT="edit($event.isin)"
				@action-DELETE="delRow"
        @click-name="gotoFondsinfo"
      >
        <template #current="row">
          <span class="edited-row" @click="edit(row.isin)" >
            <span :class="{selected: edit_isin==row.isin}">
              <PhPencilLine :style = "{opacity: edit_isin==row.isin ? '1' : '0.3' }" :size="18"/>
            </span>
          </span>
        </template>
      </Table>
      <NoData :noIcon="true" v-else />
    </div>
    
    <div class="box__container" v-if="source == '-4'">
      <div class="box__title">Eine neue Position hinzufügen</div>
      <div class="row">
        <div class="col-12 col-sm-6">
          <InputField  v-model="isinOrWkn" label="ISIN/WKN"/>
        </div>
        <div class="col-12 col-sm-6 content-right">
          <BaseButton class="btn btn-primary my-1" :disabled="!isinOrWkn" @click="addRow">Fonds suchen</BaseButton>
        </div>
      </div>
    </div>
    
    <div class="box__container" v-if="rowEditable && showedRows.length">
      <div class="box__title">Positionen bearbeiten</div>
      <div class="box__title">{{edit_name}}</div>
      <div class="row">
        <ComboBox label="Einschränkungen" v-model="lockingMode" class="col-12 col-sm-6"
          :sortComboboxValues="false" :values="lockModeOpt" @input="rowEdited" />
        <div class="col-12 col-sm-6">
          <div class="two-label">
            <div :style="{opacity: (isWert ? 0.4 : 1)}" class="pr-2" @click="isWert=false">Anteile</div>
            <InputToggleSwitch v-model="isWert" inLineLabel suppressValidationMessage/>
            <div :style="{opacity: (!isWert ? 0.4 : 1)}" @click="isWert=true">Wert (€)</div>
          </div>
          <InputField v-if="!isWert" v-model="due" @change="rowEdited" type="number" precision="6"  />
          <InputField v-else v-model="value" @change="rowEdited" type="currency" />
        </div>
      </div>
      <div class="d-flex">
        <BaseButton :isSecondary="true" :disabled="edit_idx <= 0" @click="changeRow(-1)">Vorherige Zeile</BaseButton>
        <BaseButton :isSecondary="true" :disabled="edit_idx < 0 || edit_idx > showedRows.length - 2 " @click="changeRow(1)">Nächste Zeile</BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import INVESTMENT_ADVICE from "@/store/investmentAdvice/types";
import {setLockPill, setRow} from "@/store/investmentAdvice/types";
import StepMixin from "./StepMixin";
import StepAllocation from "./StepAllocation";
import Table from "@/components/table2/Table.vue";
import {TextColumn, SlotColumn, ActionColumn, PercentageColumn, PillColumn, CurrencyColumn, NumberColumn, DateColumn} from "@/components/table2/table_util.js";
import { viewDocument } from '@/components/core/download/DownloadLink.vue';
import InputCheckBoxItem from '@/components/core/forms/checkbox/InputCheckBoxItem.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import InputField from "@/components/core/forms/InputField.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import NoData from '@/components/core/NoData.vue';
import BaseButton from "@/components/core/BaseButton.vue";
import BaseFileSelect from '@/components/fileUpload/BaseFileSelect.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import { PhPencilLine } from "phosphor-vue"
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {
  props: {
    stepName: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      newSource: '',
      readingRows: true,
      sparplan: false,
      main: false,
      summe: null,
      isinOrWkn: '',
      
      edit_isin: '',
      edit_idx: -1,
      edit_name: '',
      lockingMode: 'JA',
      lockModeOpt: [
        {value: 'JA', label: 'keine Einschränkungen'},
        {value: 'FEST', label: 'komplett gesperrt'},
        {value: 'KEINKAUF', label: 'kein Kauf'},
        {value: 'KEINVERKAUF', label: 'kein Verkauf'},
      ],
      isWert: false,
      due: 0,
      value: 0,
      vvSchemaOption: [
        {label: 'Vollständige VV-Strategie', value: true}, 
        {label: 'Auswählbare Fonds', value: false}
      ],
    }
  },
  mixins: [StepMixin],
  components: {
    Table, InputCheckBoxItem, ComboBox, GhostLoading, Block, NoData, InputField, BaseFileSelect, BaseButton,
    InputToggleSwitch, PhPencilLine, InputRadioBoxGroup,
  },
  mounted() {
    this.init();
  },
  computed: {
    headers() {
      const headers = {
        lockedLeft: [
          TextColumn('isin', 'ISIN')
          .setColorFn(row => row.isNichtHandelbar ? 'color-danger' : null),
        ],
        center: [
          TextColumn('name', 'Name').makeAlwaysVisible().makeLink(),
          TextColumn('Frs', 'SRI', 50, 0),
        ],
        lockedRight: [
          ActionColumn('actions', '')
        ]
      }
      switch (this.stepName) {
        case this.stepNames.FAVORITES:
          headers.center.push(TextColumn('Kategoriee', 'Kategorie'));
          if (this.stepParameters?.data?.infos?.length  ) {
            this.stepParameters.data.infos.forEach(item => {
              switch (item[0]) {
                case 'Text':
                  headers.center.push(TextColumn(item[1], item[2]));
                  break;
                case 'Date':
                  headers.center.push(DateColumn(item[1], item[2]));
                  break;
                case 'Currency':
                  headers.center.push(CurrencyColumn(item[1], item[2]));
                  break;
                case 'Percentage':{
                    const precision = item.length > 3 ? item[3] : 2;
                    headers.center.push(PercentageColumn(item[1], item[2], precision)
                    .setColorFn(row => row[item[1]] < 0 ? 'color-danger' : null)
                    );
                  }
                  break;
                case 'Number':{
                    const precision = item.length > 3 ? item[3] : 2;
                    headers.center.push(NumberColumn(item[1], item[2], precision)
                    .setColorFn(row => row[item[1]] < 0 ? 'color-danger' : null)
                    );
                  }
                  break;
              
                default:
                  break;
              }
            })
          }
          break;
        case this.stepNames.WK:
          headers.center.push(TextColumn('Kategoriee', 'Kategorie'));
          break;
        case this.stepNames.MYDEPOT_EINMALANLAGE:
        case this.stepNames.MYDEPOT_SPARPLAN:
          headers.center.push(PercentageColumn('dueProc', 'Anteil'));
          break;
        case this.stepNames.MAIN:
          if (this.rowEditable) {
            headers.lockedLeft.unshift( SlotColumn('current', null, 80) );
          }
          headers.lockedLeft.push(PillColumn('lockingModePill', 'Einschränkungen', 150),);
          headers.center.push(PercentageColumn('dueProc', 'Anteil'));
          headers.center.push(CurrencyColumn('value', 'Wert'));
          headers.center.push(NumberColumn('due', 'Anteile', 6));
          headers.center.push(TextColumn('taxFree', 'ASt-frei', 80, 0).setTitle('Abgeltungssteuerfreie Anteile'));
          headers.center.push(TextColumn('depot', 'Depotnummer'));
          headers.center.push(TextColumn('lagerstelle', 'Lagerstelle'));
          break;
        default:
          break;
      }
      
      return headers;
    },
    auswahlTitle() {
      switch (this.stepName) {
        case this.stepNames.FAVORITES:
          return 'Favoritenliste';
        case this.stepNames.WK:
          return 'VV-Strategie';
        case this.stepNames.MYDEPOT_EINMALANLAGE:
          return 'Musterdepot';
        case this.stepNames.MYDEPOT_SPARPLAN:
          return 'Musterdepot';
        case this.stepNames.MAIN:
          return 'Bestandsdepot';
        default:
          break;
      }
      return '';
    },
    sources() {
      if (this.main) {
        return this.stepParameters?.data?.bestandsdepotAuswahl || [];
      }
      return this.stepParameters?.data?.sources || [];
    },
    source: {
      get(){
        if (this.main) {
          return this.newSource || this.stepParameters?.data?.parameterData?.bestandsdepot || '-5';
        }
        return this.newSource || ('' + this.stepParameters?.data?.selected) || '-1';
      },
      set(value) {
        this.newSource = value;
        if (value && value != '-1'){
          this.readingRows = true;
        }
        this.save(false,  value);
      }
    },
    rowEditable() {
      return this.main && (this.source === '-4' || this.source === '-6');
    },
    hasTableData() {
      return this.main ? this.source != '-5' : this.source != '-1' 
    },
    amount: {
      get(){
        if (this.sparplan){
          const summeSparplan = this.stepParameters?.data?.summeSparplan;
          return this.summe != null ? this.summe : (summeSparplan == null ? '' : '' + summeSparplan);
        } else if (this.main){
          if (this.showedRows?.length && !this.readingRows) {
            return this.showedRows.reduce( (sum, row) => sum += row.value, 0);
          }
          return '';
        }
        return '';
      },
      set(value) {
        this.summe = value;
        this.modified = true;
      }
    },
    asVVSchema: {
      get() {
        if (this.stepParameters?.data?.asVVSchema != null ) {
          return this.stepParameters.data.asVVSchema;
        }
        return null
      },
      set(value) {
        this.stepParameters.data.asVVSchema = value;
        this.modified = true;
      }
    },
    rows() {
      if (this.stepParameters?.data?.rows?.length && this.hasTableData ) {
        return this.stepParameters.data.rows;
      }
      return []
    },
    activeRows: {
      get() {
        if (this.asVVSchema) {
          return null;
        }
        return this.rows.filter( r => r.active);
      },
      set(activeRows) {
        this.stepParameters.data.rows.forEach(row => {
          const isActive = !!(activeRows.length && activeRows.find( r => r.unique == row.unique));
          if (row.active != isActive ) {
            row.active = isActive;
            row.changed = true;
            this.modified = true;
          }
        })
      }
    },
    stepNames() {
      return INVESTMENT_ADVICE.STEPS;
    },
		showedRows() {
      return this.rows.filter(row => !row.isDeleted)
		},
    hinweise() {
      return this.stepParameters?.data?.hinweise || [];
    },
    headerActions() {
      if (this.stepName === this.stepNames.MAIN) {
        const actions = [];
        actions.push(PageHeaderSimpleAction('JA', 'keine Einschränkungen (ausgewählte Zeilen)'));
        actions.push(PageHeaderSimpleAction('FEST', 'komplett gesperrt (ausgewählte Zeilen)'));
        actions.push(PageHeaderSimpleAction('KEINKAUF', 'kein Kauf (ausgewählte Zeilen)'));
        actions.push(PageHeaderSimpleAction('KEINVERKAUF', 'kein Verkauf (ausgewählte Zeilen)'));
        return actions;
      }
      return [];
    },
  },
  watch: {
    promiseParameters() {
      if (this.promiseParameters == null ) {
        this.readingRows = false;
      }
    },
    'stepName': 'init', 
    modified() {
      this.$emit('modified', true)
    },
    rows() {
      if (this.showedRows?.length && this.rowEditable) {
        if (!this.edit_isin) {
          this.edit(this.showedRows[0].isin)
        }
      }
    },
    'hinweise': 'checkHinweis', 
  },
  methods: {
    init() {
      this.newSource = '';
      this.sparplan = false;
      this.main = false;
      this.summe = null;
      this.readingRows = this.stepName != 'MAIN';
      this.step = this.stepName;
      this.stepAllocation = this.stepAlloc(this.stepName);
      if (this.stepName !== 'MAIN') {
        this.loadStepData();
      }
      if (this.rows.length > 0 && this.promiseParameters == null) {
        this.readingRows = false;
      }
    },
    checkHinweis(hinweise) {
      if (this.stepParameters?.data?.hinweise?.length) {
        this.stepParameters?.data?.hinweise.forEach( h => {
          if (h.label == "nicht handelbare Fonds (<span class='color-danger'>x</span>-Markierung)") {
            h.label = "nicht handelbare Fonds (<span class='color-danger'>ISIN</span> Rot markiert)"
          }
        })
      }
    },
    save(makePersist, source) {
      if (this.loading) {
        return Promise.resolve();
      }
      if (this.stepName === this.stepNames.MAIN) {
        this.stepParameters.data.parameterData.bestandsdepot = source;
        this.$parent.save(false); 
        return;
      }
      const parameters = {
        step: this.step,
        quelle: this.stepAllocation[0].quelle,
        selection: source || this.source,
        rows: this.rows.map(row => ({
          id: row.id,
          isin: row.isin,
          active: row.active,
          changed: row.changed,
        }))
      };
      if (this.stepName === this.stepNames.MYDEPOT_SPARPLAN) {
        parameters.summeSparplan = this.amount;
      }
      if (this.stepName === this.stepNames.WK) {
        parameters.asVVSchema = this.asVVSchema;
      }
      return this.$store.dispatch(INVESTMENT_ADVICE.ACTIONS.SAVE_PARAMETERS, {adviceId: this.id, parameters })
      .finally(() => {
        this.readingRows = false;
      });
    },
    stepAlloc(step) {
      switch (step) {
        case this.stepNames.FAVORITES:
          return [ new StepAllocation(INVESTMENT_ADVICE.QUELLE.FONDSFAVORITEN, null)] ;
        case this.stepNames.WK:
          return [ new StepAllocation(INVESTMENT_ADVICE.QUELLE.WEALTHKONZEPT, null)] ;
        case this.stepNames.MYDEPOT_EINMALANLAGE:
          return [ new StepAllocation(INVESTMENT_ADVICE.QUELLE.MYDEPOT_EINMALANLAGE, null)] ;
        case this.stepNames.MYDEPOT_SPARPLAN:
          this.sparplan = true;
          return [ new StepAllocation(INVESTMENT_ADVICE.QUELLE.MYDEPOT_SPARPLAN, null)] ;
        case this.stepNames.MAIN:
          this.main = true;
          return [ new StepAllocation(INVESTMENT_ADVICE.QUELLE.KUNDENDEPOT, null)] ;
        default:
          break;
      }
      return [];
    },
    viewDocument(href) {
      viewDocument({href});
    },
    gotoFondsinfo(row) {
      const path = `/shared/vermogensubersicht/fondsinfo/${row.isin}`;
      this.$router.push({ path });
    },
    addRow() {
      if (this.isinOrWkn?.length === 6 || this.isinOrWkn?.length === 12) {
        this.$store.dispatch(INVESTMENT_ADVICE.ACTIONS.LOAD_ISIN_WKN, this.isinOrWkn )
        .then( (resp => {
          resp = resp.data;
          if (resp.error) {
            this.$store.commit(INVESTMENT_ADVICE.MUTATIONS.SET_MESSAGE, {
              show: true,
              text: resp.error
            })
          } else {
            const index = this.rows.findIndex(row => row.isin === resp.isin)
            if (index !== -1) {
              let text = ''
              if (this.rows[index].isDeleted) {
                this.rows[index].isDeleted = false;
                text = `Das angegebene Wertpapier ${resp.isin} ist bereits als manuelle Position angelegt.<br>
Wurde aber in diese Sitzung gelöscht. Löschvorgang wurde rückgängig gemacht.`
              } else {
                text = `Das angegebene Wertpapier ${resp.isin} ist bereits als manuelle Position angelegt.<br>
Jedes Wertpapier darf nur einmal manuell erfasst werden, bitte ändern Sie statt einer Neuerfassung
den Depotwert der bestehenden Position.`;
              }
              this.$store.commit(INVESTMENT_ADVICE.MUTATIONS.SET_MESSAGE, { show: true, text })
              return;
            }
            const row = {
              ...resp,
              active: false,
              lockingMode: 'JA',
              value: 0,
              due: 0,
              dueProc: 0,
              taxFree: '',
              depot: '',
              lagerstelle: '',
              changed: true,
              addpos: true,
            }
            setRow(row, this.step, this.source);
            const rows = this.rows.concat([row])
            const changes = {
              step: this.step,
              changed: rows
            }
            this.$store.commit(INVESTMENT_ADVICE.MUTATIONS.CHANGE_ROWS, changes);
            this.edit(resp.isin);
            this.isinOrWkn = '';
          }
        }))
      } else {
        const mess = `<div class="box__container"><div class="box__title">Falsche länge ${this.isinOrWkn?.length}</div><ul>
<li>Eine ISIN-Eingabe muss 12 Zeichen haben</li>
<li>Eine WKN-Eingabe muss 6 Zeichen haben</li>
</ul></div>`
        this.$store.commit(INVESTMENT_ADVICE.MUTATIONS.SET_MESSAGE, {
          show: true,
          text: mess
        })
      }
    },
    confirmImport(files) {
      if (files && files[0]) {
        this.$emit('import_table', files[0]);        
      }
    },
    delRow(row) {
      const index = this.rows.findIndex(f => f.isin === row.isin)
      if (index !== -1) {
			  const rows = this.rows.map(row => ({...row}));
        const changes = {
          step: this.step,
        }
				if (rows[index].id) {
					rows[index].isDeleted = true;
					rows[index].changed = true;
					this.modified = true;
          changes.rowIdx = index;
          changes.changed = rows[index]
				} else {
					rows.splice(index, 1);
          changes.changed = rows
				}
        this.$store.commit(INVESTMENT_ADVICE.MUTATIONS.CHANGE_ROWS, changes);
      }
    },
    setLock(row, mode) {
      const index = this.rows.findIndex(f => f.isin === row.isin);
      if (index !== -1) {
        const row = this.rows[index];
        if (row.lockingMode != 'IMMER') {
          row.lockingMode = mode;
          setLockPill(row);
          row.changed = true;
          this.modified = true;
          this.$store.commit(INVESTMENT_ADVICE.MUTATIONS.CHANGE_ROWS, {
            step: this.step, rowIdx: index, changed: row
          });
        }
      }
    },
    edit(isin) {
      this.edit_isin = isin;
      this.edit_idx = this.rows.findIndex(row => row.isin === isin);
      if (this.edit_idx != -1) {
        const row = this.rows[this.edit_idx];
        this.edit_name = row.name;
        this.due = row.due;
        this.value = row.value;
        this.lockingMode = row.lockingMode;
        this.isWert = true;
      }
    },
    rowEdited() {
      if (!this.rows?.length || this.edit_idx < 0) {
        return;
      }
      if (this.rows[this.edit_idx].due != this.due || this.rows[this.edit_idx].value != this.value || this.rows[this.edit_idx].lockingMode != this.lockingMode) {
        
			  const rows = this.rows.map(row => ({...row}));
        const row = rows[this.edit_idx];

        if ( this.isWert ) {
          if (row.anteilsPreisEuro) {
            this.due = parseFloat(parseFloat(this.value / row.anteilsPreisEuro).toFixed(6));

          } else {
            this.due = null;
          }
        } else {
          if (row.anteilsPreisEuro) {
            this.value = Math.round(this.due * row.anteilsPreisEuro * 100 ) / 100;
          } else {
            this.value = null;
          }
        }
        row.value = this.value;
        row.due = this.due;
        row.lockingMode = this.lockingMode;
        setLockPill(row);
        row.changed = true;
        this.modified = true;

        const summeProz = rows.reduce( (sum, row) => sum += row.value, 0);
        rows.forEach (row => {
          row.dueProc = row.value  * 100 / summeProz;
        })

        const changes = {
          step: this.step,
          changed: rows
        }
        this.$store.commit(INVESTMENT_ADVICE.MUTATIONS.CHANGE_ROWS, changes);
      }
    },
    changeRow(next) {
      if (this.edit_idx >= 0 && this.edit_idx < this.showedRows.length) {
        next += this.edit_idx;
        if (next >= 0 && next < this.showedRows.length) {
          const table = this.$refs.tblAllocation ;
          if (table && table.pageCount > 1) {
            if (next < table.pageIndex * table.pageSize ) {
              table.onPageChange(table.pageIndex - 1)
            } else if (next >= (table.pageIndex + 1) * table.pageSize ) {
              table.onPageChange(table.pageIndex + 1)
            }
          }
          this.edit(this.showedRows[next].isin);
        }
      }
    },
    onHeaderActions({key}) {
      this.activeRows.forEach( row => { this.setLock(row, key)});
    }
  }
}
</script>

<style scoped>
.content-right {
  align-self: end;
  text-align: right;
}
.two-label {  
  display: flex;
  padding: 3px 0;
  cursor: pointer;
}
.two-label > div {
  line-height: 18px;
  height: 18px;
  padding-bottom: 0;
}
.edited-row {
  display: inline-block;
  height: 100%;
  width: 18px;
  box-sizing: border-box;
  position: relative;
}
.edited-row > span {
  background-color: #eee;
}
.edited-row > .selected {
  background-color: transparent;
}
</style>