var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Limits",
          subtitle: "Automatisches Depotpositionslimit"
        }
      }),
      _c("div", { staticClass: "box__container" }, [
        _c("div", { staticClass: "box__title" }, [
          _vm._v(_vm._s(_vm.title) + " Automatisches Depotpositionslimit")
        ]),
        _c(
          "div",
          [
            _c("div", { staticClass: "paragraph" }, [
              _vm._v(
                " Zum Setzen von automatischen Limits muss eine prozentuale Abweichung, basierend auf dem Ausgangswert, eingegeben werden. Soll ein Limit (oberes oder unteres) nicht beachtet werden, so ist dieser Wert leer bzw. auf 0 zu setzen. "
              )
            ]),
            _c("div", { staticClass: "paragraph" }, [
              _vm._v(
                " Beispiel: unteres Limit 5% bedeutet eine Abweichung des Wertpapierpreises um 5% nach unten. "
              )
            ]),
            _c("div", { staticClass: "paragraph" }, [
              _vm._v(
                " Wenn Sie Dynamik auswählen und ein unteres Limit gesetzt ist, so steigt bei steigenden Wertpapierkursen das untere Limit im gleichen Verhältnis an. Bei sinkenden Kursen bleiben die Limits unverändert. "
              )
            ]),
            !_vm.isCustomerOrigin
              ? _c("div", { staticClass: "font-bold" }, [
                  _c("div", { staticClass: "paragraph" }, [
                    _vm._v("Kundennummer: " + _vm._s(_vm.form.kundennr))
                  ]),
                  _c("div", { staticClass: "paragraph" }, [
                    _vm._v("Kundenname: " + _vm._s(_vm.form.kundenLinkName))
                  ])
                ])
              : _vm._e(),
            _c("ComboBox", {
              attrs: { values: _vm.depotValues || [], isComponentHalfSize: "" },
              model: {
                value: _vm.form.depot,
                callback: function($$v) {
                  _vm.$set(_vm.form, "depot", $$v)
                },
                expression: "form.depot"
              }
            }),
            _c("InputField", {
              attrs: {
                label: "Abweichung nach Unten",
                type: "percent",
                precision: 4,
                isComponentHalfSize: ""
              },
              model: {
                value: _vm.form.abweichungUnten,
                callback: function($$v) {
                  _vm.$set(_vm.form, "abweichungUnten", $$v)
                },
                expression: "form.abweichungUnten"
              }
            }),
            _c("InputField", {
              attrs: {
                label: "Abweichung nach Oben",
                type: "percent",
                precision: 4,
                isComponentHalfSize: ""
              },
              model: {
                value: _vm.form.abweichungOben,
                callback: function($$v) {
                  _vm.$set(_vm.form, "abweichungOben", $$v)
                },
                expression: "form.abweichungOben"
              }
            }),
            _c("InputToggleSwitch", {
              attrs: {
                label: "Dynamik",
                inLineLabel: "",
                suppressValidationMessage: "",
                isComponentHalfSize: ""
              },
              model: {
                value: _vm.form.isDynamisch,
                callback: function($$v) {
                  _vm.$set(_vm.form, "isDynamisch", $$v)
                },
                expression: "form.isDynamisch"
              }
            }),
            _c("InputToggleSwitch", {
              attrs: {
                label:
                  "Gilt auch für neue Depotpositionen <span class='text-small'>(Sollte ein Limit manuell gelöscht werden, so wird dieses auch wieder neu angelegt.)</span>",
                inLineLabel: "",
                suppressValidationMessage: "",
                isComponentHalfSize: ""
              },
              model: {
                value: _vm.form.includeNewPositions,
                callback: function($$v) {
                  _vm.$set(_vm.form, "includeNewPositions", $$v)
                },
                expression: "form.includeNewPositions"
              }
            }),
            _c("InputToggleSwitch", {
              attrs: {
                label:
                  "Vor dem Anlegen alte Limits löschen <span class='text-small'>(Wenn ein Virtuelles Depot ausgewählt wurde, werden nur dort Limits gelöscht, ansonsten werden alle Limits für Depotpositionen dieses Kunden gelöscht.)</span>",
                inLineLabel: "",
                suppressValidationMessage: "",
                isComponentHalfSize: ""
              },
              model: {
                value: _vm.form.removeOldLimits,
                callback: function($$v) {
                  _vm.$set(_vm.form, "removeOldLimits", $$v)
                },
                expression: "form.removeOldLimits"
              }
            }),
            _c("div", { staticClass: "text-small my-2" }, [
              _vm._v(
                ' Es werden nur Limits angelegt, wenn die entsprechende Depotposition noch kein Limit besitzt. Wenn es mehrere automatische Limits gibt, hat ein Limit, bei dem ein Depot angegeben wurde, eine höhere Priorität als wenn "Alle Depotpositionen" ausgewählt wurde. Es gibt immer maximal ein Limit pro Depotposition. '
              )
            ]),
            !_vm.isCustomerOrigin
              ? _c("InputTextArea", {
                  attrs: {
                    label: "Bemerkung (für Kunden nicht sichtbar)",
                    isComponentHalfSize: ""
                  },
                  model: {
                    value: _vm.form.bemerkung,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "bemerkung", $$v)
                    },
                    expression: "form.bemerkung"
                  }
                })
              : _vm._e()
          ],
          1
        )
      ]),
      _c(
        "BaseModal",
        {
          ref: "modalError",
          attrs: {
            modalTitle: "Fehler",
            labelButtonConfirm: "Ok",
            labelButtonCancel: "Änderungen verwerfen",
            showCloseButton: false
          },
          on: {
            onCancelButton: _vm.confirmError,
            onConfirmButton: function($event) {
              _vm.nextRoute = false
            }
          }
        },
        [
          _c("div", { staticClass: "mb-2" }, [
            _vm._v(
              "Diese Fehler müssen korrigiert werden um speichern zu können:"
            )
          ]),
          _c("span", {
            domProps: { innerHTML: _vm._s(_vm.sanitize(_vm.infoFromService)) }
          })
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "modalInfo",
          attrs: {
            modalTitle: "Hinweis",
            labelButtonConfirm: "Trotzdem speichern",
            labelButtonCancel: "Änderungen verwerfen",
            showCloseButton: false
          },
          on: {
            onCancelButton: _vm.confirmError,
            onConfirmButton: _vm.confirmSave
          }
        },
        [
          _c("span", {
            domProps: { innerHTML: _vm._s(_vm.sanitize(_vm.infoFromService)) }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }