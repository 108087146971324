<template>
  <div>

    <PageHeaderTitleNavigation
      title="Änderungshistorie"
      :actions="pageHeaderTitleActions"
      @action-MERGE_VERSIONS="mergeVersions"
    />

    <div class="box__container">
      <GhostLoading v-if="loading" type="table" :config="{ table: { rows: 10 } }" />
      <Table
        v-else
        :title="historyTitle"
        :rows="rows"
        :headers="headers"
        @action-AENDERUNGSLOG="loadAenderungslog"
        @action-VERSIONLOG="showVersions"
        @action-SIGNATUR_ZURUECKSETZEN="resetSignature"
      >
        <template v-slot:bezeichnung="row">
          <DownloadLink
            v-if="row.hasAccess && row.dokId"
            :title="row.bezeichnung"
            downloadServicePath="/get_aenderungshistorie_file"
            :filename="row.filename"
            :queryParams="{
              dokId: row.dokId,
            }"
          />
          <span v-else>{{ row.bezeichnung }}</span>
        </template>
      </Table>
    </div>

    <div class="box__container" v-if="isShowVersions || isShowLog">
      <GhostLoading v-if="loadingSubtable" type="table" :config="{ table: { rows: 10 } }" />

      <template v-else>
        <Table
          v-if="isShowVersions"
          :title="versionTitle"
          :headers="versionHeaders"
          :rows="versionRows"
          v-model="selectedVersionDocs"
          hidePagination
        >
          <template v-slot:version="row">
            <DownloadLink
              v-if="row.hasAccess && row.dokId"
              :title="row.version"
              downloadServicePath="/get_aenderungshistorie_file"
              :filename="row.filename"
              :queryParams="{
                dokId: row.dokId,
                version: row.version,
              }"
            />
            <span v-else>{{ row.version }}</span>
          </template>
        </Table>

        <Table
          v-else-if="isShowLog"
          :title="logTitle"
          :headers="logHeaders"
          :rows="logRows"
          rowId="aenderung"
          hidePagination
        />
      </template>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import CORE_TYPES from '@/store/core/types';
import DOCUMENT_TYPES from "@/store/documents/types";
import Table from "@/components/table2/Table.vue";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';

import { mapGetters } from "vuex";
import { TextColumn, SlotColumn, DateColumn, ActionColumn, HtmlColumn, DateTimeColumn, convertTableActions, } from "@/components/table2/table_util.js";
import DownloadLink from '@/components/core/download/DownloadLink.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import { makeQueryParam } from '@/helpers/utils-helper';
import { PageHeaderConfirmedAction } from '@/components/core/header-title-navigation/page-header-utils';

const config = {
  defaultSpinner: true
};

const MODUS = {
  LOG: 'LOG',
  VERS: 'VERS',
}

export default {
  mixins: [],
  components: {
    Table,
    OptionMenu,
    PageHeaderTitleNavigation,
    GhostLoading,
    DownloadLink,
  },
  data() {
    return {
      tableRows: [],
      selectedDoc: null,
      selectedVersionDocs: null,
      loading: false,
      label: '',
      aenderungslog: {},
      loadingSubtable: false,
      subtableModus: null,
    };
  },
  computed: {
    ...mapGetters({
      isIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
    }),
    pageHeaderTitleActions() {
        const actions = []

        if (this.isShowVersions && this.isIntern) {
          actions.push(PageHeaderConfirmedAction('MERGE_VERSIONS', 'Versionen vereinigen', 'Sollen die ausgewählten Dokumente vereinigt werden?', 'Versionsvereinigung', 'Vereinigen')
            .withDisabled(() => !(this.selectedVersionDocs && this.selectedVersionDocs.length > 1)));
        }

        return actions
    },
    historyTitle() {
      return 'Änderungshistorie für ' + this.label;
    },
    headers() {
      let headers = {
          lockedLeft: [
            SlotColumn("bezeichnung", "Bezeichnung", 200, 1),
          ],
          center: [
            DateColumn("datum", "Datum", 200, 1).alignLeft(),
          ],
          lockedRight: [
            ActionColumn("actions", "Aktionen")
          ],
      }
      return headers
    },
    rows() {
      return this.tableRows.map(row => {
        return {...row, data: row, bezeichnung: row.bezeichnung || 'Unbenannt', actions: this.makeActions(row)};
      });
    },
    versionTitle() {
      return 'Versionen für ' + this.selectedDoc?.bezeichnung
    },
    versionHeaders() {
      return {
          lockedLeft: [
            SlotColumn("version", "Version", 200, 1),
          ],
          center: [
            TextColumn("datum", "Datum"),
            TextColumn("benutzer", "Benutzer"),
          ],
          lockedRight: [],
      }
    },
    versionRows() {
      return (this.selectedDoc?.subtablerecords || []).map(row => {
        return {...row, version: row.version + '', data: row};
      });
    },
    logTitle() {
      return 'Änderungslog für ' + this.selectedDoc?.bezeichnung;
    },
    logHeaders() {
      return {
        center: [
          DateTimeColumn("datum", "Datum"),
          TextColumn("benutzer", "Benutzer"),
          TextColumn("eintrag", "Eintrag"),
        ],
        lockedRight: [
          HtmlColumn("aenderungTxt", "Änderung", 250, 1),
        ],
      }
    },
    logRows() {
        let result = this.aenderungslog?.tableRows || [];

        return result.map(row => ({
            ...row,
            aenderungTxt: row.aenderung.split('<#>').join('<br>')
        }));
    },
    isShowVersions() {
      return this.subtableModus === MODUS.VERS
    },
    isShowLog() {
      return this.subtableModus === MODUS.LOG
    },
  }, 
  mounted() {
    this.loadDocumentHistory()
  },
  methods: {
    loadDocumentHistory() {
      this.loading = true
      axios.get(`${process.env.VUE_APP_API}/dokumentHistory/getDocumentHistory?docInfo=${encodeURIComponent(this.$route.query.docInfo)}`, config)
      .then(response => {
        this.$store.commit(DOCUMENT_TYPES.MUTATIONS.RETRIEVE_DOCUMENT_HISTORY_SUCCESS, response)
        this.label = response.data.label
        this.tableRows = response.data.tableRows  
      }).finally(() => this.loading = false)
    },
    makeActions(row) {
      if (row.actions?.length) {
        return convertTableActions(row.actions)
      }

      return []
    },
    showVersions(row) {
      this.subtableModus = MODUS.VERS
      this.selectedVersionDocs = this.isIntern ? [] : null
      this.selectedDoc = row;
    },
    loadAenderungslog(row) {
      this.subtableModus = MODUS.LOG
      this.loadingSubtable = true
      this.selectedDoc = row;

      const params = this.getParams(row, "AENDERUNGSLOG")
      const queryParams = makeQueryParam(params, true)

      axios.get(`${process.env.VUE_APP_API}/dokumentHistory/getDocumentChangeHistory?${queryParams}`, config).then(response => {
        if (response && response.data) {
          this.aenderungslog = response.data
        }
      }).finally(() => this.loadingSubtable = false)
    },
    resetSignature(row) {
      this.loading = true
      const params = this.getParams(row, "SIGNATUR_ZURUECKSETZEN")
      const queryParams = makeQueryParam(params)

      axios.post(`${process.env.VUE_APP_API}/dokumentHistory/resetSignature?${queryParams}`, undefined, config)
        .finally(() => {
          this.loading = false
          this.loadDocumentHistory()  
        })
    },
    getParams(row, actionKey) {
      let action = row.data.actions.find(t => t.key === actionKey)

      return action.data || {}
    },
    mergeVersions() {
      this.loading = true

      const params = {
        selectedVersions: this.selectedVersionDocs.map(x => x.version),
        dokId: this.selectedDoc.dokId,
        fileId: this.selectedDoc.fileId,
      }

      const queryParams = makeQueryParam(params)

      axios.post(`${process.env.VUE_APP_API}/dokumentHistory/mergeVersions?${queryParams}`, undefined, config)
        .finally(() => {
          this.loading = false
          this.loadDocumentHistory()  
        })
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zur Änderungshistorie', 
      to,
      from,
      breadcrumb: 'Änderungshistorie',
    });

    next();
  },
}
</script>