var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      key: _vm.key,
      staticClass: "datepicker__selectors-container",
      class: {
        "datepicker__selectors--inline": _vm.datePickerSelectorsInline,
        "datepicker__selectors--month-picker": _vm.monthPicker
      }
    },
    [
      _c(
        "DatePickerSelector",
        _vm._b(
          {
            ref: "datePickerSelector1",
            staticClass:
              "datepicker__selectors--item datepicker__selectors--item-1",
            attrs: {
              monthPicker: _vm.monthPicker,
              isRangePicker: _vm.isRangePicker,
              isFocused:
                _vm.lastSelectedDatePicker === _vm.DATE_PICKER_SOURCE_ONE
            },
            on: {
              onSelectMonth: function($event) {
                return _vm.$emit("onSelectMonth", $event)
              },
              onSelectDay: function($event) {
                return _vm.onSelectDay($event, _vm.DATE_PICKER_SOURCE_ONE)
              },
              referenceDayChanged: function($event) {
                return _vm.syncDatePickersPeriod(
                  $event,
                  _vm.DATE_PICKER_SOURCE_ONE
                )
              }
            }
          },
          "DatePickerSelector",
          _vm.$attrs,
          false
        )
      ),
      _vm.isSecondDateSelectorEnabled
        ? _c(
            "DatePickerSelector",
            _vm._b(
              {
                ref: "datePickerSelector2",
                staticClass:
                  "datepicker__selectors--item datepicker__selectors--item-2",
                attrs: {
                  isSecondDateSelector: "",
                  isRangePicker: _vm.isRangePicker,
                  isFocused:
                    _vm.lastSelectedDatePicker === _vm.DATE_PICKER_SOURCE_TWO
                },
                on: {
                  onSelectMonth: function($event) {
                    return _vm.$emit("onSelectMonth", $event)
                  },
                  onSelectDay: function($event) {
                    return _vm.onSelectDay($event, _vm.DATE_PICKER_SOURCE_TWO)
                  },
                  referenceDayChanged: function($event) {
                    return _vm.syncDatePickersPeriod(
                      $event,
                      _vm.DATE_PICKER_SOURCE_TWO
                    )
                  }
                }
              },
              "DatePickerSelector",
              _vm.$attrs,
              false
            )
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }