import router, { getRouteChildren } from '@/router';
import { 
  isUserHasRequiredRole,
  isRolesIncludes,
} from '@/router/guards';
import { ROLES, VIEW_ROLES} from '@/router/roles';

import PERSON_DATA_MENUS from '@/components/persoenliche-daten/person-daten/person-data-menus';

export const STAMMDATEN_SUBSTEP = { title: 'Stammdaten', substepKey: 'stammdaten', };
export const ADRESSE_SUBSTEP = { title: 'Adresse', substepKey: 'adresse', };
export const VERSANDADRESSE_SUBSTEP = { title: 'Versandadresse', substepKey: 'versandadresse', };
export const ANSCHRIFTFELD_SUBSTEP = { title: 'Anschriftfeld', substepKey: 'anschriftfeld', };
export const GEBURTSTAG_SUBSTEP = { title: 'Geburtstag und Staatsangehörigkeit', substepKey: 'geburtstag', };
export const GEBURTSTAG_SUBSTEP_FIRMA = { title: 'Gründungsdatum und Branche', substepKey: 'geburtstag'}
export const BERUF_SUBSTEP = { title: 'Beruf', substepKey: 'beruf', };
export const LEGITIMATIONSURKUNDE_SUBSTEP = { title: 'Legitimationsurkunden', substepKey: 'legitimationsurkunde', };
export const LEGITIMATIONSURKUNDE_SUBSTEP_FIRMA = { title: 'Notwendige Unterlagen', substepKey: 'legitimationsurkunde', };
export const STEUERDATEN_SUBSTEP = { title: 'Steuerdaten', substepKey: 'steuerdaten', };

export const MAP_SUBSTEP_KEY_TO_TITLE = {
  [STAMMDATEN_SUBSTEP.substepKey]: STAMMDATEN_SUBSTEP.title,
  [ADRESSE_SUBSTEP.substepKey]: ADRESSE_SUBSTEP.title,
  [VERSANDADRESSE_SUBSTEP.substepKey]: VERSANDADRESSE_SUBSTEP.title,
  [ANSCHRIFTFELD_SUBSTEP.substepKey]: ANSCHRIFTFELD_SUBSTEP.title,
  [GEBURTSTAG_SUBSTEP.substepKey]: GEBURTSTAG_SUBSTEP.title,
  [BERUF_SUBSTEP.substepKey]: BERUF_SUBSTEP.title,
  [LEGITIMATIONSURKUNDE_SUBSTEP.substepKey]: LEGITIMATIONSURKUNDE_SUBSTEP.title,
  [STEUERDATEN_SUBSTEP.substepKey]: STEUERDATEN_SUBSTEP.title,
};

export const MAP_SUBSTEP_KEY_TO_TITLE_FIRMA = {
  [STAMMDATEN_SUBSTEP.substepKey]: STAMMDATEN_SUBSTEP.title,
  [ADRESSE_SUBSTEP.substepKey]: ADRESSE_SUBSTEP.title,
  [VERSANDADRESSE_SUBSTEP.substepKey]: VERSANDADRESSE_SUBSTEP.title,
  [ANSCHRIFTFELD_SUBSTEP.substepKey]: ANSCHRIFTFELD_SUBSTEP.title,
  [GEBURTSTAG_SUBSTEP_FIRMA.substepKey]: GEBURTSTAG_SUBSTEP_FIRMA.title,
  [BERUF_SUBSTEP.substepKey]: BERUF_SUBSTEP.title,
  [LEGITIMATIONSURKUNDE_SUBSTEP.substepKey]: LEGITIMATIONSURKUNDE_SUBSTEP_FIRMA.title,
  [STEUERDATEN_SUBSTEP.substepKey]: STEUERDATEN_SUBSTEP.title,
};

export const MAP_SUBSTEP_KEY_TO_TITLE_FIRMA_ALS_ZUS_PERSON = {
  [STAMMDATEN_SUBSTEP.substepKey]: STAMMDATEN_SUBSTEP.title,
  [ADRESSE_SUBSTEP.substepKey]: ADRESSE_SUBSTEP.title,
};

export const MAP_CUSTOMER_ROUTE_TO_DEFAULT_SUBSTEP = {
  '/persoenlichedaten/customer-data/steps/stammdaten': STAMMDATEN_SUBSTEP.substepKey,
  '/persoenlichedaten/customer-data/steps/pep': 'pep',
};

export const MAP_PERSON_ROUTE_TO_DEFAULT_SUBSTEP = {
  '/persoenlichedaten/person-data/:personId/stammdaten': STAMMDATEN_SUBSTEP.substepKey,
  '/persoenlichedaten/person-data/:personId/pep': 'pep',
};

export const MAP_CUSTOMER_ROUTE_PATH_TO_SUBSTEPS = {
  '/persoenlichedaten/customer-data/steps/stammdaten:privat': [...personPrivatSubsteps()],
  '/persoenlichedaten/customer-data/steps/stammdaten:firma': [...personFirmaSubsteps()],
};

export function personPrivatSubsteps(isMainPerson = true) {
  const substeps = [];
  substeps.push(STAMMDATEN_SUBSTEP);
  substeps.push(ADRESSE_SUBSTEP);

  if(isRolesIncludes([VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS])) {
    substeps.push(VERSANDADRESSE_SUBSTEP);

    if(isMainPerson) {
      substeps.push(ANSCHRIFTFELD_SUBSTEP);
    }
  }

  substeps.push(GEBURTSTAG_SUBSTEP);
  substeps.push(BERUF_SUBSTEP);
  substeps.push(LEGITIMATIONSURKUNDE_SUBSTEP);
  substeps.push(STEUERDATEN_SUBSTEP);
  return substeps;
}

export function personFirmaSubsteps() {
  return [
    STAMMDATEN_SUBSTEP,
    ADRESSE_SUBSTEP,
    GEBURTSTAG_SUBSTEP_FIRMA,
    STEUERDATEN_SUBSTEP,
    LEGITIMATIONSURKUNDE_SUBSTEP_FIRMA
  ];
}
export const FIRMA_AVAILABLE_STEPS = new Set(
  ['/persoenlichedaten/customer-data/steps/stammdaten',
  '/persoenlichedaten/customer-data/steps/kommunikationsdaten',
  '/persoenlichedaten/customer-data/steps/zusaetzliche-personen',
  '/persoenlichedaten/customer-data/steps/sonstiges',
  '/persoenlichedaten/customer-data/steps/bankverbindung',
  '/persoenlichedaten/customer-data/steps/aenderungslog',
  '/persoenlichedaten/customer-data/steps/pep',
  '/persoenlichedaten/customer-data/steps/hobbys-vorlieben',
  '/persoenlichedaten/customer-data/steps/finanzielle-verhaltnisse',
  '/persoenlichedaten/customer-data/steps/courtage'
]);

const PERSON_SUB_ROUTES_MAIN_PERSON_ONLY = ['/persoenlichedaten/person-data/:personId/arbeitgeber'];
const PERSON_SUB_ROUTES_ADDITIONAL_PERSON_ONLY = ['/persoenlichedaten/person-data/:personId/dokumente'];

export function personSteps(personId, isFirma, isMainPerson = false) {
  if(!personId) return;

  const mapRouteToSubsteps = {
    '/persoenlichedaten/person-data/:personId/stammdaten:privat': [...personPrivatSubsteps(isMainPerson)],
    '/persoenlichedaten/person-data/:personId/stammdaten:firma': [...personFirmaSubsteps(isMainPerson)],
  };


  const personDataMenus = PERSON_DATA_MENUS;

  const steps = [];
  let index = 1;

  personDataMenus?.subMenu?.forEach((menu) => {
    if(!isMainPerson && PERSON_SUB_ROUTES_MAIN_PERSON_ONLY.indexOf(menu.path) >= 0) {
      return ;
    }
    if(isMainPerson && PERSON_SUB_ROUTES_ADDITIONAL_PERSON_ONLY.indexOf(menu.path) >= 0) {
      return ;
    }

    const to = router.matcher.match(menu.path.replace(':personId', personId));
    isUserHasRequiredRole(to, null, (redirect) => {
      if(!redirect) {
        const key = menu.path;
        const extraKey = isFirma ? 'firma' : 'privat';
        steps.push({
          stepKey: to?.path, 
          label: menu.label, 
          current: false, 
          totalProgress: index++,
          substeps: mapRouteToSubsteps[`${key}`] || mapRouteToSubsteps[`${key}:${extraKey}`] || null,
        });
      }
    });
  });

  return steps;
}
