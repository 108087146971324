var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "600px" } },
    [
      _c("DBM", {
        attrs: { act: this.act, showLoadButton: false, query: this.pars() }
      }),
      _c("ComboBox", {
        attrs: {
          label: "Minutes",
          values: _vm.comboMinutes,
          value: _vm.minutes,
          isComponentHalfSize: "",
          disabled: this.syncRun
        },
        on: {
          change: function($event) {
            return _vm.runMinutes($event)
          }
        }
      }),
      _c("ComboBox", {
        attrs: {
          label: "Statistics",
          values: _vm.comboStats,
          value: _vm.statId,
          isComponentHalfSize: "",
          disabled: this.syncRun,
          sortComboboxValues: false
        },
        on: {
          change: function($event) {
            return _vm.changeStat($event)
          }
        }
      }),
      this.statData
        ? _c("VChart", {
            ref: "vchart",
            attrs: { option: _vm.option },
            on: { click: _vm.kkk }
          })
        : _vm._e(),
      _c("DbmSessionDialog", { ref: "DbmSessionDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }