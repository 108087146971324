<template>
  <div>
    <ComboBox label="Anrede" 
      :values="customerDataConfig.genders" 
      v-model="personalDataAddress.title" 
      validateUntouched
      firstEmpty
      @change="dataChanged()" 
      tabindex="1" />
    <InputField label="Vorname" 
      v-model="personalDataAddress.firstName" 
      @ref="focusOnField($event)"
      @change="dataChanged()" 
      tabindex="2"/>
    <InputField label="Name" 
      v-model="personalDataAddress.lastName" 
      validateUntouched
      @input="dataChanged()"
      tabindex="3" />
    <DatePickerField label="Geburtsdatum" 
      v-model="personalDataBirth.dayOfBirth"
      @input="dataChanged()" 
      tabindex="4" />
  </div>
</template>

<script>
import validator from '@/mixins/validator'
import { required } from '@/mixins/validator/rules'
import stammdatenMixin from './stammdaten-mixin'

import ComboBox from '@/components/core/forms/ComboBox.vue'
import InputField from '@/components/core/forms/InputField.vue'
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue'

import { LAST_NAME_REQUIRED_TEXT, TITLE_REQUIRED_TEXT }  from '@/views/customer/customer-utils'


export default {
  mixins: [validator, stammdatenMixin],
  data() {
    return {
      personalDataAddress: {},
      personalDataBirth: {},
    };
  },
  computed: {
    // go to stammdaten-mixin.js. Shared computed attributes for Privat.vue and Firma.vue will be there
  },
  methods: {
    // go to stammdaten-mixin.js. Shared methods for Privat.vue and Firma.vue will be there
  },
  validators: {
    personalDataAddress: {
      lastName: [required(LAST_NAME_REQUIRED_TEXT)],
      //title: [required(TITLE_REQUIRED_TEXT)],
    }
  },
  components: {
    ComboBox,
    InputField,
    DatePickerField,
  }
}
</script>
