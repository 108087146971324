<template>
  <div class="formulare-list__container">
  </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import InteractiveHelpCommonsMixin from '@/assets/mixins/interactivehelpcommonsmixins';

export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    OptionMenu,
  },
}
</script>
