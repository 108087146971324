var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-forms__container" }, [
    _c(
      "div",
      { staticClass: "input-forms__label-container" },
      [
        _vm.label
          ? _c("div", {
              staticClass: "input-forms__label-content",
              domProps: { innerHTML: _vm._s(_vm.sanitize(_vm.label)) }
            })
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "input-forms__input-container",
            class: { "forms__input--half-size": _vm.isComponentHalfSize }
          },
          [
            _c("HtmlEditor", {
              attrs: {
                isFontParams: _vm.isFontParams,
                disabled: _vm.disabled,
                value: _vm.value
              },
              on: {
                input: function($event) {
                  return _vm.$emit("input", $event)
                }
              }
            })
          ],
          1
        ),
        _vm.isValidationConfigured()
          ? [
              !_vm.suppressValidationMessage && _vm.validation
                ? _c(
                    "div",
                    { key: _vm.validation.updated },
                    _vm._l(
                      _vm.validation.getErrors(
                        this.validationPath,
                        this.validateUntouched
                      ),
                      function(error) {
                        return _c(
                          "div",
                          { key: error, staticClass: "fc-form-danger" },
                          [_vm._v(" " + _vm._s(error) + " ")]
                        )
                      }
                    ),
                    0
                  )
                : _vm._e()
            ]
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }