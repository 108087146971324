<template>
  <div class="additional-permissions__container">
    <div class="box__container">
      <div class="box__title">Weitere Zugriffsrechte</div>

      <template>
        <GhostLoading v-if="loadingPermission" type="title" :repeat="8" />
        <NoData v-else-if="!hasComponents" />
        <template v-else>
          <template v-for="(component, index) in components">
            <div :key="index" class="antrag-component__container">
              <AntragComponent
                  v-if="!isComponentHidden(component)"
                  class="antrag-component"
                  :component="component"
                  :data="allData"
                  :disabled="savingPermission"
                  isComponentHalfSizeEnabled
                  @change="onChange(component, $event)" />
            </div>
          </template>
          <BaseButton
              v-if="userPermissionTarget === 'MAKLER_PERSONEN' && !!unternr"
              isSecondary
              @click="restoreDefault(unternr)">
            Standard wiederherstellen
          </BaseButton>

          <BaseButton
              v-if="userPermissionTarget === 'MAKLER'"
              isSecondary
              @click="confirmRestoreDefaultSubBrokers()">
            Untervermittler Standard wiederherstellen
          </BaseButton>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, } from 'vuex';
import CORE_TYPES from '@/store/core/types';
import BROKER_PERMISSIONS_TYPES, { __MAKLER__, } from '@/store/brokerPermissions/types';
import AntragComponent from '@/components/antrag/AntragComponent.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import BaseButton from '@/components/core/BaseButton.vue';

import { componentValidatorToRule, isHiddenDisabled } from '@/components/antrag/antrag-utils';
import validator from '@/mixins/validator';

export default {
  mixins: [validator],
  props: {
    unternr: {
      default: null,
    },
    userPermissionTarget: {
      type: String,
      default: 'MAKLER'
    }
  },
  data() {
    return {
      loadingPermission: false,
      savingPermission: false,
    };
  },
  computed: {
    ...mapGetters({
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      additionalPermissionsFn: BROKER_PERMISSIONS_TYPES.GETTERS.ADDITIONAL_PERMISSIONS,
      additionalPermissionsEdited: BROKER_PERMISSIONS_TYPES.GETTERS.ADDITIONAL_PERMISSIONS_EDITED,
    }),
    additionalPermissions() {
      const unternr = this.userPermissionTarget === 'MAKLER_PERSONEN' ? this.unternr : __MAKLER__;
      return this.additionalPermissionsFn(unternr);
    },
    components() {
      return ([ ...this.additionalPermissions?.components || [], ]).map(component => ({
        ...component,
        config: {
          ...component?.config || {},
          validateUntouched: true,
          autocomplete: 'new-password',
        },
      }));
    },
    hasComponents() {
      return !!this.components?.length;
    },
    data() {
      return { ...this.additionalPermissions?.data || {}, };
    },
    dataEdited() {
      return { ...this.additionalPermissionsEdited?.data || {}, };
    },
    allData() {
      return {
        ...this.data,
        ...this.dataEdited,
      };
    },
    dynamicValidators() {
      const { components, } = this;

      return components
          .filter(component => component.id && component.validators?.length)
          .map(component => ({
            componentId: component.id,
            validators: component.validators.map(componentValidatorToRule),
          }));
    },

    isFormInvalid() {
      return this.validation.updated 
        && this.components?.some(component => component?.id && this.validation?.[component.id]?.invalid);
    },
  },
  watch: {
    dynamicValidators: {
      immediate: true,
      handler(newDynamicValidators) {
        for (const dynamicValidator of newDynamicValidators) {
          if (dynamicValidator && this.$configureValidators) {
            this.$configureValidators({ 
              [dynamicValidator.componentId]: dynamicValidator.validators,
            });
          }
        }
      },
    },
  },
  methods: {
    confirmRestoreDefaultSubBrokers() {
      this.$confirmModal({
        title: 'Weitere Zugriffsrechte wiederherstellen',
        message: `Wollen Sie den Untervermittler Standard wirklich wiederherstellen?`,
        labelButtonCancel: 'Nein',
        labelButtonConfirm: 'Ja',
      }).then(() => {
        this.restoreDefaultSubBrokers();
      }).catch(() => {})
    },
    async restoreDefaultSubBrokers() {
      try {
        this.savingPermission = true;
        await this.$store.dispatch(BROKER_PERMISSIONS_TYPES.ACTIONS.RESTORE_DEFAULT_SUB_BROKERS_ADDITIONAL_PERMISSIONS);
      } finally {
        this.savingPermission = false;
      }
    },
    async restoreDefault(unternr) {
      try {
        this.savingPermission = true;
        await this.$store.dispatch(BROKER_PERMISSIONS_TYPES.ACTIONS.RESTORE_DEFAULT_ADDITIONAL_PERMISSIONS, { unternr, });
      } finally {
        this.savingPermission = false;
      }
    },
    async findAdditionalPermissions(unternr) {
      try {
        this.loadingPermission = true;
        await this.$store.dispatch(BROKER_PERMISSIONS_TYPES.ACTIONS.FIND_ADDITIONAL_PERMISSIONS, { unternr, });
      } finally {
        this.loadingPermission = false;
      }
    },
    isComponentHidden(component) {
      const result = component && component.hidden && isHiddenDisabled(component.hidden, this.allData);
      if (!result) {
        return result;
      }

      return result;
    },
    onChange(component, value) {
      if(!component?.id) return;

      this.$store.commit(BROKER_PERMISSIONS_TYPES.MUTATIONS.ADD_ADDITIONAL_PERMISSIONS_EDITED, {
        unternr: this.unternr || null,
        data: {
          [component.id]: value,
        },
      })
    },
    async saveData() {
      try {
        this.savingPermission = true;
        await this.$store.dispatch(BROKER_PERMISSIONS_TYPES.ACTIONS.SAVE_ADDITIONAL_PERMISSIONS);
      } finally {
        this.savingPermission = false;
      }
    },
  },
  mounted() {
    this.findAdditionalPermissions(this.unternr);
  },
  async beforeRouteLeave(to, from, next) {
    await this.saveData();
    next();
  },
  async beforeDestroy() {
    await this.saveData();
  },
  validators: {},
  components: {
    AntragComponent,
    GhostLoading,
    NoData,
    BaseButton,
  },
}
</script>

<style lang="scss" scoped>
.antrag-component__container {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
