var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.isAnlageBeispiel ? "Anlagebeispiel" : "Anlageempfehlung",
          actions: _vm.actions
        },
        on: {
          "action-EMAIL": function($event) {
            return _vm.toActions("email")
          },
          "action-PDF": function($event) {
            return _vm.toActions("pdf")
          },
          "action-COURTAGE": function($event) {
            return _vm.toActions("courtage")
          },
          "action-ORDER": function($event) {
            return _vm.toActions("order")
          }
        }
      }),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("StepperForm", {
              ref: "stepper",
              attrs: {
                stepType: "step",
                stepperMediator: _vm.getStepperMediator(),
                hasFertig: false,
                selectedStepKey: _vm.selectedStepKey,
                selectedSubstepKey: _vm.selectedSubstepKey,
                showSaveSpinner: _vm.showSaveSpinner,
                hideNavigationButtons: _vm.hideNavigationButtons
              },
              on: {
                "set-step": _vm.setStep,
                "on-fertig": _vm.fertig,
                "set-substep": _vm.setSubstep
              },
              scopedSlots: _vm._u([
                {
                  key: "headerTemplate",
                  fn: function() {
                    return [
                      _c("investment-advice-sidetext", {
                        attrs: {
                          warnings: _vm.warnings,
                          hinweise: _vm.hinweise
                        },
                        on: { navigate_to: _vm.navigateTo }
                      })
                    ]
                  },
                  proxy: true
                },
                {
                  key: "contentTemplate",
                  fn: function(props) {
                    return [
                      props.data.stepKey === "calculation-parameters"
                        ? _c(
                            "div",
                            [
                              _c("ParametersStep", {
                                ref: "step",
                                attrs: { id: _vm.id },
                                on: {
                                  updated: _vm.updateParameters,
                                  display_server_question:
                                    _vm.displayServerQuestion,
                                  init_warnings: _vm.initWarnings,
                                  init_hinweise: _vm.initHinweise
                                }
                              })
                            ],
                            1
                          )
                        : props.data.stepKey === "my-depot"
                        ? _c(
                            "div",
                            [
                              _vm.selectedSubstepKey === "Sparplan"
                                ? [
                                    _c("InvestmentAdviceTable", {
                                      ref: "step",
                                      attrs: {
                                        id: _vm.id,
                                        stepName: _vm.stepNames.MYDEPOT_SPARPLAN
                                      },
                                      on: {
                                        init_warnings: _vm.initWarnings,
                                        init_hinweise: _vm.initHinweise
                                      }
                                    })
                                  ]
                                : [
                                    _c("InvestmentAdviceTable", {
                                      ref: "step",
                                      attrs: {
                                        id: _vm.id,
                                        stepName:
                                          _vm.stepNames.MYDEPOT_EINMALANLAGE
                                      },
                                      on: {
                                        init_warnings: _vm.initWarnings,
                                        init_hinweise: _vm.initHinweise
                                      }
                                    })
                                  ]
                            ],
                            2
                          )
                        : props.data.stepKey === "wealthkonzept"
                        ? _c(
                            "div",
                            [
                              _c("InvestmentAdviceTable", {
                                ref: "step",
                                attrs: {
                                  id: _vm.id,
                                  stepName: _vm.stepNames.WK
                                },
                                on: {
                                  init_warnings: _vm.initWarnings,
                                  init_hinweise: _vm.initHinweise
                                }
                              })
                            ],
                            1
                          )
                        : props.data.stepKey === "favoriten"
                        ? _c(
                            "div",
                            [
                              _c("InvestmentAdviceTable", {
                                ref: "step",
                                attrs: {
                                  id: _vm.id,
                                  stepName: _vm.stepNames.FAVORITES
                                },
                                on: {
                                  init_warnings: _vm.initWarnings,
                                  init_hinweise: _vm.initHinweise
                                }
                              })
                            ],
                            1
                          )
                        : props.data.stepKey === "wertpapierauswahl"
                        ? _c(
                            "div",
                            [
                              _c("fondsfinder-step", {
                                ref: "step",
                                attrs: { id: _vm.id },
                                on: {
                                  init_warnings: _vm.initWarnings,
                                  init_hinweise: _vm.initHinweise
                                }
                              })
                            ],
                            1
                          )
                        : props.data.stepKey === "depotoptimierung"
                        ? _c(
                            "div",
                            [
                              _c("optimierung-step", {
                                ref: "step",
                                attrs: { id: _vm.id },
                                on: {
                                  init_warnings: _vm.initWarnings,
                                  init_hinweise: _vm.initHinweise
                                }
                              })
                            ],
                            1
                          )
                        : props.data.stepKey === "benchmark"
                        ? _c(
                            "div",
                            [
                              _c("benchmarking-step", {
                                ref: "step",
                                attrs: { id: _vm.id },
                                on: {
                                  init_warnings: _vm.initWarnings,
                                  init_hinweise: _vm.initHinweise
                                }
                              })
                            ],
                            1
                          )
                        : props.data.stepKey === "ergebnis-anlageallocation"
                        ? _c(
                            "div",
                            [
                              _c("ErgebnisAnlageallocation", {
                                ref: "step",
                                attrs: { id: _vm.id, tab: props.data.stepKey },
                                on: {
                                  init_warnings: _vm.initWarnings,
                                  init_hinweise: _vm.initHinweise,
                                  display_server_question:
                                    _vm.displayServerQuestion
                                }
                              })
                            ],
                            1
                          )
                        : props.data.index === 8 ||
                          props.data.index === 9 ||
                          props.data.index === 10
                        ? _c(
                            "div",
                            [
                              _c("ergebnis-step", {
                                ref: "step",
                                attrs: {
                                  id: _vm.id,
                                  tab: props.data.stepKey,
                                  subtab: props.currentSubstep.substepKey
                                },
                                on: {
                                  init_warnings: _vm.initWarnings,
                                  init_hinweise: _vm.initHinweise,
                                  display_server_question:
                                    _vm.displayServerQuestion
                                }
                              })
                            ],
                            1
                          )
                        : props.data.stepKey === "pdf"
                        ? _c(
                            "div",
                            [
                              _c("action-pdf-step", {
                                ref: "step",
                                attrs: { id: _vm.id },
                                on: {
                                  init_warnings: _vm.initWarnings,
                                  init_hinweise: _vm.initHinweise
                                }
                              })
                            ],
                            1
                          )
                        : props.data.stepKey === "courtage"
                        ? _c(
                            "div",
                            [
                              _c("action-courtage-step", {
                                ref: "step",
                                attrs: { id: _vm.id },
                                on: {
                                  init_warnings: _vm.initWarnings,
                                  init_hinweise: _vm.initHinweise
                                }
                              })
                            ],
                            1
                          )
                        : props.data.stepKey === "order"
                        ? _c(
                            "div",
                            [
                              _c("action-order-step", {
                                ref: "step",
                                attrs: { id: _vm.id },
                                on: {
                                  init_warnings: _vm.initWarnings,
                                  init_hinweise: _vm.initHinweise
                                }
                              })
                            ],
                            1
                          )
                        : props.data.stepKey === "email"
                        ? _c(
                            "div",
                            [
                              _c("ActionEmailStep", {
                                ref: "step",
                                attrs: { id: _vm.id }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ]),
      _c(
        "BaseModal",
        {
          ref: "infoModal",
          attrs: { showCancelButton: false, labelButtonConfirm: "Schließen" },
          on: { close: _vm.closeMessage },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [_vm._v(" " + _vm._s(_vm.serverModalTitle) + " ")]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", [
            _c("div", {
              domProps: {
                innerHTML: _vm._s(_vm.sanitize(_vm.serverModalMessage))
              }
            })
          ])
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "yesNoModal",
          attrs: {
            labelButtonConfirm: _vm.serverModalYesNo.yesText,
            labelButtonCancel: _vm.serverModalYesNo.noText
          },
          on: { onCancelButton: _vm.handleNo, onConfirmButton: _vm.handleYes },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("PhInfo", {
                    staticStyle: { color: "var(--color-danger)" }
                  }),
                  _vm._v(" " + _vm._s(_vm.serverModalYesNo.label) + " ")
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", [
            _c("div", {
              domProps: {
                innerHTML: _vm._s(_vm.sanitize(_vm.serverModalYesNo.text))
              }
            })
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }