var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "eFonds24",
          subtitle: "Stammdaten",
          actions: _vm.headerActions
        },
        on: {
          "action-UPLOAD": function($event) {
            return _vm.uploadData()
          },
          "action-OPEN-E-FONDS": function($event) {
            return _vm.openEFonds()
          }
        }
      }),
      _c("div", { staticClass: "row my-0" }, [
        _c("div", { staticClass: "col-12" }, [_c("AntragSidetext")], 1)
      ]),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.accessRights
            ? [
                _c("EntitySelector", {
                  attrs: {
                    isComponentHalfSize: "",
                    isPersonPicker: "",
                    values: _vm.personsCombo,
                    hideNewButton: ""
                  },
                  on: {
                    input: function($event) {
                      return _vm.$emit("input", $event)
                    },
                    change: function($event) {
                      return _vm.getStammdaten($event)
                    },
                    edit: function($event) {
                      return _vm.editPerson(_vm.personId)
                    }
                  },
                  model: {
                    value: _vm.personId,
                    callback: function($$v) {
                      _vm.personId = $$v
                    },
                    expression: "personId"
                  }
                }),
                _vm.candidates.length
                  ? _c(
                      "div",
                      { staticClass: "box__container" },
                      [
                        _c("p", [
                          _vm._v(
                            "Der Kunde ist nicht zugeordnet zu einem Kunden in " +
                              _vm._s(_vm.data.efondsName) +
                              ", aber im " +
                              _vm._s(_vm.data.efondsName) +
                              " System wurden eventuell passende Kunden gefunden"
                          )
                        ]),
                        _c("div", [_vm._v("Bitte auswählen")]),
                        _c("InputRadioBoxItem", {
                          attrs: {
                            name: "candidates",
                            value: -1,
                            label: _vm.getTitel(-1),
                            checked: -1 === _vm.idxCandidates,
                            config: { bold: -1 === _vm.idxCandidates }
                          },
                          on: {
                            change: function($event) {
                              return _vm.updateGuid(-1)
                            }
                          }
                        }),
                        _vm._l(_vm.candidates, function(item, idx) {
                          return _c(
                            "div",
                            { key: item.guid },
                            [
                              _c("InputRadioBoxItem", {
                                attrs: {
                                  name: "candidates",
                                  value: idx,
                                  label: _vm.getLabel(idx),
                                  checked: idx === _vm.idxCandidates,
                                  config: { bold: idx === _vm.idxCandidates }
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.updateGuid(idx)
                                  }
                                }
                              }),
                              item.uriEFonds
                                ? _c(
                                    "div",
                                    { staticClass: "my-2 ml-4" },
                                    [
                                      _c("DownloadLink", {
                                        attrs: {
                                          title: _vm.getLinkText(idx),
                                          href: item.uriEFonds
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        })
                      ],
                      2
                    )
                  : _vm._e(),
                _c("Table", {
                  attrs: {
                    title: _vm.getTitel(_vm.idxCandidates),
                    headers: _vm.headers,
                    rows: _vm.rows,
                    hidePagination: ""
                  }
                })
              ]
            : _vm.loadingList
            ? _c("GhostLoading", { attrs: { type: "table" } })
            : _c("div", [_vm._v("Fehlende Berechtigungen für diesen Bereich")])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }