import BERATUNGSMAPPE_TYPES from './types';
import CORE_TYPES from '../core/types';
import LOG_TYPES from '@/store/log/types';
import axios from 'axios';
import { buildMessage, } from '../../helpers/log-message-helper';
import { makeQueryParam } from '@/helpers/utils-helper';
import * as utils from '../../helpers/commonfunctions';

const config = {
  defaultSpinner: true
};

export default {
  async [BERATUNGSMAPPE_TYPES.ACTIONS.RETRIEVE_BERATUNGSMAPPELIST]({ commit, dispatch, rootState }, payload) {

    let serviceUrl = '/beratungsmappe/list';


    if (payload) {
      const filterDateVon = payload.filterDateVon;
      const filterModusDatum = payload.filterModusDatum;
      const filterDateBis = payload.filterDateBis;
      const filterStatus = payload.filterStatus;
      const filterFehler = payload.filterFehler;
      const filterMitScan = payload.filterMitScan;
      const filterDateJahr = payload.filterDateJahr;
      const filterOrt = payload.filterOrt;
      const filterKundennr = payload.filterKundennr;
      const firstName = payload.firstName;
      const lastName = payload.lastName;

      if (filterDateVon) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'filterDateVon', filterDateVon);
      }

      if (filterModusDatum) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'filterModusDatum', filterModusDatum);
      }

      if (filterDateBis) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'filterDateBis', filterDateBis);
      }

      if (filterStatus) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'filterStatus', filterStatus);
      }

      if (filterFehler) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'filterFehler', filterFehler);
      }

      if (filterMitScan) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'filterMitScan', filterMitScan);
      } else  {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'filterMitScan', false);
      }

      if (filterDateJahr) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'filterDateJahr', filterDateJahr);
      }

      if (filterOrt) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'filterOrt', filterOrt);
      }     
      
      if (filterKundennr) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'filterKundennr', filterKundennr);
      }      

      if (firstName) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'firstName', firstName);
      }    
      
      if (lastName) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'lastName', lastName);
      }         

    }    
   

    await axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
      if (response && response.data) {

        commit(BERATUNGSMAPPE_TYPES.MUTATIONS.RETRIEVE_BERATUNGSMAPPELIST_SUCCESS, response.data);

      }      

    })
  },

  [BERATUNGSMAPPE_TYPES.ACTIONS.EDIT_BERATUNGSMAPPE]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    let serviceUrl = '/mrsberatungsmappe';


    if (payload) {
      const beratungsMappeID = payload.beratungsMappeID;


      if (!beratungsMappeID) {
        return;
      } 

      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'beratungsMappeID', beratungsMappeID);      
    }    
   

    return axios.get(rootState.core.apiAddress + '/../' + serviceUrl, config).then(response => {
      if (response && response.data) {

        commit(BERATUNGSMAPPE_TYPES.MUTATIONS.EDIT_BERATUNGSMAPPE_SUCCESS, response.data);

      }      

    })
  }, 
  [BERATUNGSMAPPE_TYPES.ACTIONS.RETRIEVE_BERATUNGSMAPPEACTIONS]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    let serviceUrl = '/mrsberatungsmappeactions';


    if (payload) {
      const beratungsMappeID = payload.beratungsMappeID;


      if (!beratungsMappeID) {
        return;
      } 

      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'beratungsMappeID', beratungsMappeID);

      const PARAMETER_NAME_MODUS = payload.PARAMETER_NAME_MODUS;

      if (PARAMETER_NAME_MODUS) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'PARAMETER_NAME_MODUS', PARAMETER_NAME_MODUS);
      }

    }    

    axios.get(rootState.core.apiAddress + '/../' + serviceUrl, config).then(response => {
      if (response && response.data) {

        commit(BERATUNGSMAPPE_TYPES.MUTATIONS.RETRIEVE_BERATUNGSMAPPEACTIONS_SUCCESS, response.data);

      }      

    })
  }, 

  [BERATUNGSMAPPE_TYPES.ACTIONS.EXECUTE_BERATUNGSMAPPEACTION]({ commit, dispatch, rootState }, payload) {
    if (!payload?.beratungsMappeID) {
        return;
    }    
    const config = {
      defaultSpinner: true
    };
    let serviceUrl = '/mrsberatungsmappeactions';

    return axios.post(`${rootState.core.apiAddress}/../${serviceUrl}?beratungsMappeID=${payload.beratungsMappeID}&action=${payload.action}`, {data: payload}, config).then(response => {
      if (response && response.data) {
        commit(BERATUNGSMAPPE_TYPES.MUTATIONS.EXECUTE_BERATUNGSMAPPEACTION_SUCCESS, response.data);
      }      

    }).catch(error => {
      let message = 'Es war nicht möglich, die Aktion auszuführen.';
      if (error && error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message;
      }
      dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(message, 'danger'));
    })
  }, 

  [BERATUNGSMAPPE_TYPES.ACTIONS.DELETE_BERATUNGSMAPPE]({ commit, rootState }, payload) {
    if (!payload?.beratungsMappeID) {
      return
    }

    let serviceUrl = '/beratungsmappe/delete';

    serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'beratungsMappeID', payload.beratungsMappeID);

    return axios.delete(rootState.core.apiAddress + serviceUrl, config)
  },  
  [BERATUNGSMAPPE_TYPES.ACTIONS.CREATE_BERATUNGSMAPPE]({ dispatch, getters }, payload) {
    return new Promise((resolve, reject) => {

      const param = makeQueryParam({...payload, mappeData: undefined}, true)
      const mappeData = payload.mappeData || {}

      const serviceUrl = `/beratungsmappe/create?${param}`;

      axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${serviceUrl}`, mappeData, config).then(response => {
        if (response && response.data) {
          resolve(response.data)
        }      
      })
    });
  },

  
  [BERATUNGSMAPPE_TYPES.ACTIONS.RETRIEVE_VERSENDEN_FILES]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    let serviceUrl = '/beratungsmappe/beratungsmappeVersendenFiles';

    const beratungsMappeID = payload && payload.beratungsMappeID;

    if (!payload || !payload.beratungsMappeID) {
      return;
    }    

    serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'beratungsMappeID', beratungsMappeID);

    if (!payload || !payload.data) {
      return;
    }   
    
    serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'beratungsMappeID', beratungsMappeID);

    return new Promise((resolve, reject) => {axios.post(rootState.core.apiAddress + serviceUrl, payload.data, config).then(response => {
      resolve(response)
    })
    })    

  },

  [BERATUNGSMAPPE_TYPES.ACTIONS.DELETE_ANTRAG]({ commit, dispatch, rootState }, payload) {

    const config = {
      defaultSpinner: true
    };

    let serviceUrl = '/beratungsmappe/removeAntrag';

    const beratungsMappeID = payload && payload.beratungsMappeID;

    if (!payload || !payload.antragId || !beratungsMappeID) {
      return;
    }
    
    serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'beratungsMappeID', beratungsMappeID);
    serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'antragId', payload.antragId);
    serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'deleteCompletely', payload.deleteCompletely || false);

    return axios.delete(rootState.core.apiAddress + serviceUrl, {}, config)
    .then(response => {
      response.data.antragId = payload.antragId;
      commit(BERATUNGSMAPPE_TYPES.MUTATIONS.DELETE_ANTRAG_SUCCESS, response.data);
    })
  }, 
  [BERATUNGSMAPPE_TYPES.ACTIONS.COPY_BERATUNGSMAPPE]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    let serviceUrl = '/beratungsmappe/copy';

    if (payload) {
      const beratungsMappeID = payload.beratungsMappeID;


      if (!beratungsMappeID) {
        return;
      } 

      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'beratungsMappeID', beratungsMappeID);

    } 


    return axios.get(rootState.core.apiAddress + serviceUrl, config)
  },  
  [BERATUNGSMAPPE_TYPES.ACTIONS.RETRIEVE_LAGERSTELLE]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    let serviceUrl = '/beratungsmappe/retrieveLagerstelle';


    if (payload) {
      const isin = payload.isin;

      if (isin) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'isin', isin);
      } else {
        return;
      }


    }    
   

    axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
      if (response && response.data) {

        commit(BERATUNGSMAPPE_TYPES.MUTATIONS.RETRIEVE_LAGERSTELLE_SUCCESS, response.data);

      }      

    })
  },
  [BERATUNGSMAPPE_TYPES.ACTIONS.RETRIEVE_BERATUNGSMAPPE_SELECTIONS]({ commit, rootState }) {
    return new Promise((resolve, reject) => {

      axios.get(rootState.core.apiAddress + '/beratungsmappe/retrieveSelections', config).then(response => {
        if (response && response.data) {
          commit(BERATUNGSMAPPE_TYPES.MUTATIONS.RETRIEVE_BERATUNGSMAPPE_SELECTIONS_SUCCESS, response.data)
          resolve(response.data)
        }
      })
    });

  },
  [BERATUNGSMAPPE_TYPES.ACTIONS.RETRIEVE_AA]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    let serviceUrl = '/beratungsmappe/retrieveAA';


    if (payload) {
      const isin = payload.isin;
      const bezugAuswahl = payload.bezugAuswahl;

      if (isin) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'isin', isin);
      } 
      else {
        return;
      }
      if (bezugAuswahl){
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'bezugAuswahl', bezugAuswahl);
      }
      else {
        return;
      }
    }

    return new Promise((resolve, reject) => {axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
      resolve(response)
    })
    })
  },     

  [BERATUNGSMAPPE_TYPES.ACTIONS.SAVE_ANTRAGS_POSITION]({ commit, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    let serviceUrl = '/beratungsmappe/addUpdateOrderPositionen';

    if (payload?.beratungsmappeId) {
      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'beratungsmappeId', payload.beratungsmappeId);

      if (payload.positionId) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'positionId', payload.positionId);
      }
      if (payload.isin) { 
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'isin', payload.isin);     
      }
      if (payload.bezugAuswahl) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'bezugAuswahl', payload.bezugAuswahl);
      }      
      if (payload.betrag) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'betrag', payload.betrag);
      }         
      if (payload.anlageArt) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'anlageArt', payload.anlageArt);
      }  
      if (payload.aa) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'aa', payload.aa);
      }       
      if (payload.frequenz) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'frequenz', payload.frequenz);
      }      
      if (payload.fondsArt) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'fondsArt', payload.fondsArt);
      }      
      if (payload.noWarn) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'noWarn', payload.noWarn);
      }
      
      axios.post(rootState.core.apiAddress + serviceUrl, config).then(response => {
        if (response?.data) {
          commit(BERATUNGSMAPPE_TYPES.MUTATIONS.SAVE_ANTRAGS_POSITION_SUCCESS, response.data);
        }      
      })
    }    
  },    

  [BERATUNGSMAPPE_TYPES.ACTIONS.DELETE_POSITION]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    let serviceUrl = '/beratungsmappe/deleteOrderPosition';


    if (payload) {
      const positionId = payload.positionId;

      if (positionId) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'positionId', positionId);
      }  else {
        return;
      }
      
      const beratungsmappeId = payload.beratungsmappeId;

      if (beratungsmappeId) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'beratungsmappeId', beratungsmappeId);
      } else {
        return;
      }      
      
    }    
   

    return axios.delete(rootState.core.apiAddress + serviceUrl, config).then(response => {
      if (response && response.data) {

        commit(BERATUNGSMAPPE_TYPES.MUTATIONS.DELETE_POSITION_SUCCESS, response.data);

      }      

    })
  },    
  [BERATUNGSMAPPE_TYPES.ACTIONS.RETRIEVE_ORDER_POSITION]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };
    let serviceUrl = '/beratungsmappe/retrievePositionById';

    if (payload?.positionId && payload?.beratungsMappeId && payload?.isin) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'positionId', payload.positionId);
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'beratungsMappeId', payload.beratungsMappeId);
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'isin', payload.isin);

      axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
        if (response?.data) {
          commit(BERATUNGSMAPPE_TYPES.MUTATIONS.RETRIEVE_ORDER_POSITION_SUCCESS, response.data);
        }      
      })
    } else {
      commit(BERATUNGSMAPPE_TYPES.MUTATIONS.UPDATE_ORDER_POSITION, null);
    } 
  },  
  
  [BERATUNGSMAPPE_TYPES.ACTIONS.GENERATE_GESELLSCHAFTEN]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    let serviceUrl = '/beratungsmappe/generateGesellschaften';


    if (payload) {
      const typ = payload.typ;

      if (typ) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'typ', typ);
      }

      const sicher = payload.sicher;

      if (sicher) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'sicher', sicher);
      }  
      
    }    
   

    axios.post(rootState.core.apiAddress + serviceUrl, config).then(response => {
      if (response && response.data) {

        commit(BERATUNGSMAPPE_TYPES.MUTATIONS.GENERATE_GESELLSCHAFTEN_SUCCESS, response.data);

      }      

    })
  },   
}