var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "customer-bankkonten__container box__container-wrap" },
    [
      _c(
        "div",
        { staticClass: "box__container-rows" },
        [
          _vm.accounts.length
            ? _c("Table", {
                attrs: {
                  tableId: "e8a13936-8e4e-4c5a-8bd3-8e677e0720ed",
                  headers: _vm.headers,
                  rows: _vm.accounts,
                  rowId: "id",
                  ignoreTablePageSize: "",
                  rowsPerPage: 5,
                  tableRowsPerPage: [],
                  mobileConfig: {
                    title: "Name",
                    headers: ["Kontonummer", "Kontostand"],
                    disableClickRow: true
                  }
                }
              })
            : _c("NoData", { attrs: { noIcon: "" } })
        ],
        1
      ),
      _c("div", { staticClass: "customer-bankkonten__bottom bottom-row" }, [
        _c(
          "div",
          { staticClass: "customer-bankkonten__links text-right" },
          [
            !_vm.$isSmallScreen
              ? _c("router-link", { attrs: { to: "/home/account" } }, [
                  _vm._v("Bankkonten")
                ])
              : _vm._e(),
            _vm.showLinkToBankverbindung
              ? _c(
                  "router-link",
                  {
                    staticClass: "ml-3",
                    attrs: {
                      to:
                        "/persoenlichedaten/customer-data/steps/bankverbindung"
                    }
                  },
                  [_vm._v(" Bankverbindungen anzeigen ")]
                )
              : _vm._e()
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }