<template>
  <div>
  </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';

export default {
  mixins: [],
  computed: {
  },
  beforeRouteLeave (to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zum Service', 
      to,
      from,
    });

    next()
  },
  components: {
    OptionMenu,
  },
}
</script>
