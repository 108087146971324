import actions from './actions';
import mutations from './mutations';
import getters from './getters';


export function getInitialState() {
  return {
   performancePayload:{},
   progressDetailsConfig:{},
   performanceData:{},
   historicalData:{},
   depotid:"ALLE_DEPOTS_ANZEIGEN",
   realizedProfit:{},
   yearPerformanceData:{},
   transactions:{},
   depotConfigs:{},
  }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}