var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-forms__container" }, [
    _c(
      "div",
      { staticClass: "input-forms__container" },
      [
        _c("ComboBox", {
          attrs: {
            label: "Bereits vorhandene Depots",
            values: _vm.vorhandeneDepotsAuswahl,
            value: _vm.antragData.INPUT_VORHANDENE_KONTEN_REDUKTION,
            sortComboboxValues: false
          },
          on: {
            input: function($event) {
              return _vm.updateStore(
                "INPUT_VORHANDENE_KONTEN_REDUKTION",
                $event
              )
            }
          }
        }),
        _vm.selectedDepot
          ? _c("WertpapierorderAnteileInfo", {
              attrs: { position: _vm.selectedDepot }
            })
          : _vm._e()
      ],
      1
    ),
    _c("div", { staticClass: "input-forms__label-container" }, [
      _c("div", { staticClass: "input-forms__label-container" }, [
        _c("div", { staticClass: "row mt-0" }, [
          _c("div", { staticClass: "col" }, [
            _vm.label
              ? _c(
                  "div",
                  {
                    staticClass:
                      "\n              input-forms__label-content input-forms__label-content--bigger\n            "
                  },
                  [
                    _c("ph-bank", { attrs: { size: 16 } }),
                    _vm._v(" " + _vm._s(_vm.label) + " ")
                  ],
                  1
                )
              : _vm._e()
          ]),
          _vm.config && _vm.config.buttonText
            ? _c(
                "div",
                { staticClass: "col-auto" },
                [
                  _c("BaseButton", { on: { click: _vm.openFormsFinder } }, [
                    _vm._v(_vm._s(_vm.config.buttonText))
                  ])
                ],
                1
              )
            : _vm._e()
        ])
      ])
    ]),
    _c(
      "div",
      { staticClass: "input-forms__input-container" },
      [
        _vm.rows && _vm.rows.length
          ? _c("Table", {
              attrs: {
                hidePagination: "",
                headers: _vm.headers,
                rows: _vm.rows,
                rowId: "index"
              },
              on: { "action-DELETE": _vm.openModalDeleteFond },
              scopedSlots: _vm._u(
                [
                  {
                    key: "betragAlt",
                    fn: function(row) {
                      return [
                        _c("InputField", {
                          attrs: {
                            type: "currency",
                            id: "betragAlt" + row.index,
                            value: row.betragAlt
                          },
                          on: {
                            input: function($event) {
                              return _vm.onChangeInputField(
                                $event,
                                row,
                                "betragAlt"
                              )
                            }
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "betrag",
                    fn: function(row) {
                      return [
                        _c("InputField", {
                          attrs: {
                            type: "currency",
                            id: "betrag" + row.index,
                            value: row.betrag
                          },
                          on: {
                            input: function($event) {
                              return _vm.onChangeInputField(
                                $event,
                                row,
                                "betrag"
                              )
                            }
                          }
                        })
                      ]
                    }
                  }
                ],
                null,
                false,
                2670470953
              )
            })
          : _vm._e(),
        _c("DeletePositionModal", {
          ref: "deleteModal",
          attrs: { position: _vm.positionToDelete },
          on: {
            delete: function($event) {
              return _vm.doDeletePosition()
            }
          }
        }),
        _c(
          "div",
          [
            _c("Summenbildung", {
              attrs: {
                antragId: _vm.antragId,
                summeSpar: _vm.summe,
                mitSparplan: true,
                ohneEinmal: true
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }