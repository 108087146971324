<template>
<div>
    <BoxContainer :title="selectedTabLabel">
        <Table v-if="rows.length"
            :headers="headers"
            :rows="rows"
            rowId="index"
            hidePagination
        />
    </BoxContainer>
</div>
</template>

<script>
import FONDSINFO_TYPES from "@/store/fondsinfo/types";
import BoxContainer from '@/components/core/BoxContainer.vue';
import { mapGetters } from 'vuex';
import Table from "@/components/table2/Table.vue";
import {TextColumn, DateColumn, PercentageColumn} from "@/components/table2/table_util.js";

export default {
  components: {
    BoxContainer,
    Table,
  },
  name: "TabAusschuttung",
  props:{
    selectedTabLabel: {
      type: String
    }
  },
    data() {
        return {
            headers: {
                center: [
                    TextColumn("year", "Jahr"),
                    DateColumn("planDate", "Geplanter_Auszahlungstermin"),
                    PercentageColumn("planPercentage", "Wert (Soll)"),
                    DateColumn("forecastDate", "Voraussichtlicher Auszahlungstermin"),
                    PercentageColumn("forecastPercentage", "Wert (Prognose)"),
                    DateColumn("actualDate", "Tatsächlicher Auszahlungstermin"),
                    PercentageColumn("actualPercentage", "Wert (Ist)"),
                    TextColumn("actualPercentageRemark", "Wert (Anmerkung)"),
                    TextColumn("comment", "Kommentar"),
                ],
            }
        };
    },
  computed: {
    ...mapGetters({
        ausschuttung : FONDSINFO_TYPES.GETTERS.FONDSINFO_AUSSCHUTTUNG
    }),
    rows() {
        return (this.ausschuttung?.distributions || []).map((row, index) => ({
            index,
            ...row,
        }));
    },
  },
  mounted() {
    if(!this.ausschuttung) {
      this.$store.dispatch(FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_AUSSCHUTTUNG, 
      {
        isin: this.$route.params?.isin,
        bgsNr: this.$route.params?.bgsNr,
      });
    }
  },

}
</script>

<style>

</style>