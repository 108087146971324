var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("LoginNeeded", { attrs: { selectedAccounts: _vm.selectedAccounts } }),
      _c("MultiBankingNavigation", {
        scopedSlots: _vm._u([
          {
            key: "subtitle",
            fn: function() {
              return [
                _c("span", [_vm._v(_vm._s(_vm.title))]),
                _c(
                  "span",
                  { staticClass: "ml-2" },
                  [
                    _vm.showWarningAuthNeeded
                      ? _c("ph-warning", { attrs: { size: 18 } })
                      : _vm._e()
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm.showTransactions
        ? _c("MultiBankingFilter", {
            attrs: {
              filterId: "5ddbe7f0-ebea-4a4c-8070-ddf8fd0db545",
              accountId: _vm.accountIdParam
            }
          })
        : _vm._e(),
      _vm.showTransactions
        ? _c(
            "div",
            [
              _c("KontoverlaufGraph", {
                attrs: {
                  accountId: _vm.selectedAccountId,
                  category: _vm.selectedCategory,
                  currentBalance: _vm.currentBalance,
                  hideDepotPositions: ""
                }
              })
            ],
            1
          )
        : _c("NoMultibankingWarning")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }