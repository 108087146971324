<template>
  <div class="box__container">
    <div class="box__title">Zusätzliche Personen</div>

    <div v-if="isMainPerson">
      <BaseButtonsContainer v-if="isBrokerOrByPass && isEditable" class="mb-3">
        <BaseButton isSecondary @click="navigateToAddExistentPeople()">Vorhandene Personen hinzufügen</BaseButton>
        <BaseButton isSecondary @click="openPersonenBeziehungenMatrixModal()">Beziehungen aller Personen</BaseButton>
        <BaseButton @click="newPerson()">Neue Person</BaseButton>
        <BaseButton @click="newPersonIdScan()">Neue Person über Ausweis</BaseButton>
      </BaseButtonsContainer>

        <Table
            tableId="2544518d-72be-403f-993a-4ec9defa7010"
            :headers="headers"
            :rows="rows"
            :rowsPerPage="10"
            rowId="personId"
            :mobileConfig="{title: 'Zusätzliche Personen', headers: ['lastName', 'firstName', 'relation']}"
            :exportConfig="{roottext: 'Zusaetzliche Personen'}"
            @click-lastName="editPerson($event.personId)"
            @click-relation="editPersonBeziehungen"
            @action-DELETE="deletePersonConfirmation"
        />

      <BaseModal
        @onConfirmButton="deletePerson()"
        labelButtonConfirm="Entfernen"
        ref="deletePersonConfirmation"
        size="sm"
        modalTitle="Person entfernen">
        <span v-html="sanitize(deleteMessage)"></span>
      </BaseModal>

      <PersonEditBeziehungenModal
        ref="personEditBeziehungenModal"
        @saved="updateData()"
      />

      <PersonenBeziehungenMatrixModal
        ref="personenBeziehungenMatrixModal"
        @saved="updateData()"
      />
    </div>
  </div>
</template>

<script>
import CUSTOMERDATA_TYPES from '@/store/customerData/types'
import { mapGetters } from 'vuex'
import CORE_TYPES from '@/store/core/types.js'

import BaseButton from '@/components/core/BaseButton.vue'
import Table from "@/components/table2/Table.vue";
import {TextColumn, ActionColumn, SimpleAction, DateColumn} from "@/components/table2/table_util.js";
import BaseModal from '@/components/core/BaseModal.vue'
import PersonEditBeziehungenModal from './zusaetzliche-personen/PersonEditBeziehungenModal.vue'
import PersonenBeziehungenMatrixModal from './zusaetzliche-personen/PersonenBeziehungenMatrixModal.vue'
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import BaseButtonsContainer from '@/components/core/BaseButtonsContainer.vue';
import axios from 'axios';
import {sanitize} from '@/helpers/string-helper.js';

export default {
  mixins: [InteractiveHelpCommonsMixin],
  data() {
    return {
        personToDelete: {},
        deleteMessage: '',
    }
  },
  computed: {
    ...mapGetters({
      customerData: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_DATA,
      persons: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_ADDITIONAL_PERSONS,
      isMainPerson: CUSTOMERDATA_TYPES.GETTERS.IS_MAIN_PERSON,
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
      isBrokerOrByPass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
    isEditable() {
      return this.customerData?.isEditable;
    },
    isFA() {
      return !!this.loginData?.rights?.isFA;
    },

    vollmachtLabel() {
      return this.isFA ? 'ZB' : 'VM';
    },

    headers() {
        const headers = {
            lockedLeft: [
                TextColumn("lastName", "Name").makeLink(),
                TextColumn("firstName", "Vorname"),
            ],
            center: [
                TextColumn("relation", "Beziehung").makeLink(this.isBrokerOrByPass && this.isEditable),
                TextColumn("isLegalAgent", "Ges. Vertreter"),
                TextColumn("isExtraDepotOwner", "DI"),
                TextColumn("isMandatory", this.vollmachtLabel),
                TextColumn("zip", "Plz"),
                TextColumn("city", "Ort"),
                DateColumn("personalDataBirth", "Geburtsdatum"),
            ],
            lockedRight: [],
        };
        if (this.deleteEnabled) {
            headers.lockedRight.push(ActionColumn("actions", "Aktionen"));
        }
        return headers;
    },
    deleteEnabled() {
        return this.isBrokerOrByPass && this.isEditable;
    },

    rows() {
        const actions = [
            SimpleAction("DELETE", 'PhTrash', "Löschen"),
        ];
        return (this.persons || [])
          .filter(customer => this.isBrokerOrByPass || customer?.relationship?.isExtraDepotOwner || customer?.relationship?.isLegalAgent || customer?.relationship?.isMandatory )
          .map((customer) => ({
            lastName: customer.personalDataAddress.lastName,
            firstName: customer.personalDataAddress.firstName,
            relationship: customer.relationship,
            relation: customer?.relationship?.relation ? customer.relationship.relation.display : '',
            isLegalAgent: customer?.relationship?.isLegalAgent ? 'Ja' : 'Nein',
            isExtraDepotOwner: customer?.relationship?.isExtraDepotOwner ? 'Ja' : 'Nein',
            isMandatory: customer?.relationship?.isMandatory ? 'Ja' : 'Nein',
            zip: customer.personalDataAddress.zip,
            city: customer.personalDataAddress.city,
            personalDataBirth: customer.personalDataBirth ? customer.personalDataBirth.dayOfBirth : '',
            personId: customer.personId,
            actions,
        }));
    },
  },
  methods: {
    sanitize(htmlString) {
        return sanitize(htmlString);
    },
    editPerson(personId) {
      this.$router.push({ path: `/persoenlichedaten/person-data/${personId}` })
    },
    newPerson() {
      this.$router.push({ path: '/persoenlichedaten/person-data/neue_person' })
    },
    newPersonIdScan() {
      this.$router.push({ path: '/persoenlichedaten/new-person-id-scan' })
    },
    async deletePersonConfirmation(row) {
      
      await axios.get(`${process.env.VUE_APP_API}/customerData/deletePerson?personId=${row.personId}`, {defaultSpinner: true})
      .then(response => this.deleteMessage = response.data)

      this.personToDelete = row;
      this.$refs.deletePersonConfirmation.open()
    },
    deletePerson() {
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.DELETE_PERSON, { personId: this.personToDelete.personId })
    },
    editPersonBeziehungen(person) {
      this.$refs.personEditBeziehungenModal.open(person);
    },
    updateData() {
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA_ADDITIONAL_PERSONS, { list: true });
    },
    navigateToAddExistentPeople() {
      this.$router.push({
        path: '/persoenlichedaten/add-existent-people',
        query: {
          backUrl: this.$route.path,
        },
      });
    },
    openPersonenBeziehungenMatrixModal() {
      this.$refs.personenBeziehungenMatrixModal.open();
    },
  },
  components: {
    Table,
    BaseButton,
    BaseModal,
    PersonEditBeziehungenModal,
    PersonenBeziehungenMatrixModal,
    BaseButtonsContainer,
  }
}
</script>

<style scoped>

</style>