var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "broker-access-management__container" }, [
    _c("div", { staticClass: "box__container" }, [
      _c(
        "div",
        [
          _c("div", { staticClass: "box__title" }, [_vm._v("Account")]),
          _vm.loading
            ? _c(
                "GhostLoading",
                { staticClass: "my-0", attrs: { type: "custom" } },
                [_c("Block", { attrs: { type: "button", width: 140 } })],
                1
              )
            : _c(
                "BaseButton",
                {
                  attrs: {
                    isPrimary: _vm.loginStatus.accountLocked,
                    isSecondary: !_vm.loginStatus.accountLocked,
                    disabled: _vm.saving[_vm.ACCOUNT_LOCKED],
                    animated: _vm.saving[_vm.ACCOUNT_LOCKED]
                  },
                  on: { click: _vm.toggleAccountLocked }
                },
                [
                  _vm._v(
                    "Account " +
                      _vm._s(
                        _vm.loginStatus.accountLocked
                          ? "freischalten"
                          : "sperren"
                      )
                  )
                ]
              ),
          _c("div", { staticClass: "text-small mt-1" }, [
            _vm._v(
              "Hinweis: der Benutzer kann sich erst anmelden, wenn er freigeschaltet ist"
            )
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }