<template id="strucktur">
  <div>
    <PageHeaderTitleNavigation title="Vermittlerstruktur" />

<!--     <div class="box__container" tid="a17429ca-55e3-49a5-b743-c8c67dd94aeb">
      <BaseButton @click="gesamteStrukturSwitch()" isSecondary >
        <span>gesamte Struktur anzeigen</span>
        <AnimatedSpinner v-if="loading"/>
      </BaseButton>
      <BaseButton @click="vermittlerwechseln()" isSecondary >
        <span>Zu markiertem Vermittler wechseln</span>
        <AnimatedSpinner v-if="loading"/>
      </BaseButton>      
    </div> -->
    <div class="box__container" tid="4b819bbd-d86c-4a2f-8b79-9f5ef8b7f205">
      <Tree 
        v-if="struckturData && struckturData.nodes && struckturData.nodes.length"
        :items="[struckturData]" childrenKey="nodes" idKey="nodeId" 
        :itemSelectedId="selectedNodeId" @itemSelect="selectNode($event)"
        uniqueId="c557f5e8-82eb-11ee-b962-0242ac120002"
        class="strucktur__tree_nodes">
        <template #default="{item}">
          <div class="strucktur__tree_node_content">
            <a @click="openBroker(item.nodeId)" class="strucktur__tree_node_label" v-if="maklernr!==item.nodeId">{{ item.label }}</a>
            <span v-if="maklernr==item.nodeId" class="strucktur__tree_node_label">{{ item.label }}</span>
            <Pill v-if="item.icon"  class="strucktur__tree_node_pill" :label="item.icon" type="info"></Pill>
            <span v-if="item.numberOfRecords" class="strucktur__tree_node_total">{{ item.numberOfRecords }}</span>
          </div>
        </template>
      </Tree>
      <GhostLoading v-else-if="loadingMitarbeiterstruktur" class="mt-2" type="block" :config="{ block: { height: 24, }, }" :repeat="10" />
      <div v-else>Keine Daten</div>
    </div>
  </div>
</template>

<script>
import MITARBEITER_STRUKTUR_TYPES from "@/store/vermittlerstruktur/types";
import Tree from '@/components/core/Tree.vue';
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import { mapGetters } from 'vuex';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import CORE_TYPES from '@/store/core/types';
import Pill from '@/components/core/Pill.vue';

export default {
  mixins: [InteractiveHelpCommonsMixin],
  name: "BaseExplorer",
  components: {
    Tree,
    GhostLoading,
    BaseButton,
    AnimatedSpinner,
    OptionMenu,
    PageHeaderTitleNavigation,
    Pill,
  },
  data() {
    return {
      selectedNodeId: null,
      navigationArray: [{ label: "Vermittlerstruktur" }],
      loadingMitarbeiterstruktur: false,
      loading: false,
    };
  },
  mounted() {
    this.retrieveMitarbeiterStruktur();
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu Vermittlerstruktur', 
      dokumentenarchivLastFolder: Array.prototype.map.call(this.navigationArray.slice(1), function(item) { return item.label; }).join("%a%"),
      to,
      from,
      breadcrumb: 'Vermittlerstruktur',
    });

    
    this.$store.commit(MITARBEITER_STRUKTUR_TYPES.MUTATIONS.RESET_STATE);
    next();
  },
  computed: {
    ...mapGetters({
      struckturData: MITARBEITER_STRUKTUR_TYPES.GETTERS.MITARBEITER_STRUKTUR,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
      isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
    }),
    maklernr() {
      const maklernr = (this.isBroker && this.loginData.userid) || (this.isCustomer && this.loginData.maklernr);
      return maklernr;
    }
    
  },
  methods: {  
    async retrieveMitarbeiterStruktur() {
      try {
        this.loadingMitarbeiterstruktur = true;
        await this.$store.dispatch(MITARBEITER_STRUKTUR_TYPES.ACTIONS.RETRIEVE_MITARBEITER_STRUKTUR);
      } finally {
        this.loadingMitarbeiterstruktur = false;
      }
    },
    resetData() {
      this.selectedNodeId = null;
    },
    selectNode(whatNode) {
      this.selectedNodeId = whatNode?.nodeId;

      this.navigationArray.length = 0;
      this.navigationArray.push(whatNode);


    },
          
    selectNavigationNode(whatNode) {
      this.selectedNodeId = whatNode?.nodeId;
    },
 
    gesamteStrukturSwitch() {

    },

    vermittlerwechseln() {

    },
    openBroker(brokerId) {
      this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_BROKER_NEW_TAB, { brokerId })
    },    
  },
};
</script>

<style scoped>
.header_buttons {
  display: flex;
  align-items: flex-end;
}

.strucktur__container {
  display: flex;
  flex-direction: column;
}

.strucktur__body {
  width: 100%;
}

.strucktur__header {
  display: flex;
  align-items: center;
  margin-left: auto;
  flex-wrap: wrap;
  padding: 0.8em;
}

.strucktur__header * {
  float: right;
}

.strucktur__header i {
  font-size: 1.8em;
  cursor: pointer;
  float: right;
}

.strucktur__header .strucktur__header__search {
  position: relative;
  float: right;
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.strucktur__header .strucktur__header__search input {
  flex-grow: 1;
}

.strucktur__header .strucktur__schadensmeldung_checkbox {
  margin-right: 4px;
  margin-left: 4px;
}

.strucktur__header .strucktur__header__search i {
  position: absolute;
  left: 8px;
  font-size: 1em;
}

.strucktur__header .strucktur__header__search input {
  text-indent: 26px;
}

.strucktur__sidebar {
  white-space: nowrap;
  padding: 4px;
  flex-grow: 0;
}

.strucktur__sidebar div {
  cursor: pointer;
}

.strucktur__content {
  position: relative;
  background-color: unset;
  flex-grow: 1;
  max-width: 100%;
}

.content__item {
  display: flex;
  max-width: 98px;
  min-width: 98px;
  min-height: 98px;
  text-align: center;
  /* background-color: aqua; */
  margin: 2px;
  flex-direction: column;
  word-wrap: break-word;
  padding: 2px;
}

.content__item i:nth-child(2) {
  font-size: 1.2em;
}

.content__detailed {
  display: flex;
  flex-wrap: nowrap;
  /* border: 1px black solid; */
}

.content__detailed:nth-child(1) div {
  font-weight: bold;
  border-left: 1px #e5e5e5 solid;
  cursor: pointer;
}

.content__detailed:nth-child(1) div i {
  font-size: 1em;
}

.content__detailed:nth-child(1) div:nth-child(1) {
  border-left: none;
}

.content__item__expanded__column {
  word-wrap: break-word;
  text-align: left;
  width: 120px;
}

.content__item__expanded__column__icon {
  display: flex;
  /* Warning: Needed for oldIE support, but words are broken up letter-by-letter */
  -ms-word-break: break-all;
  word-break: break-all;
  /* Non standard for webkit */
  word-break: break-word;

  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.content__item__expanded__column:nth-child(1) {
  width: 32px;
}

.content__item__expanded__column:nth-child(2) {
  width: 32px;
}

.content__item__expanded__column:nth-child(3) {
  width: 32px;
}

.content__item__expanded__column:nth-child(4) {
  width: 32px;
}

.content__item__expanded__column:nth-child(6) {
  width: 80px;
}

.content__item__expanded__column:nth-child(8) {
  width: 180px;
}

.strucktur__navigation__item {
  display: inline-block;
  cursor: pointer;
  flex: 1 1 0px;
  margin-bottom: 4px;
  font-size: 1.1em;
}

.strucktur__content__node {
  display: flex;
  flex-wrap: wrap;
}

.strucktur__content__node i {
  cursor: pointer;
}

.strucktur__content__node a {
  color: var(--color-link)
}

.strucktur__content i {
  font-size: 3em;
}

.content__detailed i {
  font-size: 2em;
}

.strucktur__content__smaller {
  display: none;
}

.strucktur__search__string {
  font-weight: bold;
  font-style: italic;
}

.break-text {
  -ms-word-break: break-all;
  word-break: break-all;
  /* Non standard for webkit */
  word-break: break-word;
}

.vertrag__ordner.tooltip {
  position: relative;
  display: inline-block;
}

.vertrag__ordner.tooltip .tooltiptext {
  visibility: hidden;
  background: rgb(204, 204, 204);
  background: rgba(204, 204, 204, 0.5);
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  display: inline-block;
  z-index: 1;
  min-width: 600px;
}

.vertrag__ordner.tooltip:hover .tooltiptext {
  visibility: visible;
}

.no__vertrag__ordner {
  display: none;
}

.selected__node {
  font-weight: bold;
}

.unselected__node {
  font-weight: unset;
}

.color-text {
  color: var(--color-primary);
}

.color-primary {
  color: var(--color-primary);
}

.color-success {
  color: var(--color-success);
}

.color-warning {
  color: var(--color-warning);
}

.color-info {
  color: var(--color-info);
}

.color-danger {
  color: var(--color-danger);
}

.td-icon i {
  font-size: 1em;
  padding: 2px;
}

th,
tr,
td {
  vertical-align: middle;
}

.icons-inline {
  display: inline;
  font-size: 1.8em;
}

.strucktur__tree_nodes {
  margin: 8px 0 8px;
}

.strucktur__tree_node_content {
  display: flex;
}

.strucktur__tree_node_label {
  flex: 1 1 auto;
  word-break: break-word;
}

.strucktur__tree_node_pill {
  flex: 2 1 auto;
  word-break: break-word;
}

.strucktur__tree_node_total {
  align-self: center;
  flex: 0 0 auto;
  margin: 0 0 0 12px;
}


.box__title {
  margin-bottom: 8px;
}

.primary {
  background-color: var(--color-primary);
  color: var(--color-box);
}

.success {
  background-color: var(--color-success);
  color: var(--color-box);
}

.warning {
  background-color: var(--color-warning);
  color: var(--color-box);
}

.info {
  background-color: var(--color-info);
  color: var(--color-box);
}

.danger {
  background-color: var(--color-danger);
  color: var(--color-box);
}

.strucktur__navigation {
   width: 100%;
}

.header__button {
  margin-right: 16px;
}

.name-filter {
  width: 300px;
}

/* Large desktops and laptops */
@media (min-width: 1861px) {
  .strucktur__sidebar {
    display: block;
  }
  .strucktur__content__smaller {
    display: none;
  }

  .content__item__expanded__column:nth-child(5) {
    width: 320px;
  }
  .content__item__expanded__column:nth-child(6) {
    width: 80px;
  }
  .content__item__expanded__column:nth-child(7) {
    display: block;
  }
  .content__item__expanded__column:nth-child(8) {
    display: block;
  }
  .content__item__expanded__column:nth-child(9) {
    display: block;
    min-width: 200px;
  }

  .status-icons-many-columns {
    display: table-cell;
  }

  .status-icons-single-column {
    display: none;
  }

  tr.line-separator {
    border-bottom: none;
  }
}

/* Landscape tablets and medium desktops */
@media (min-width: 1021px) and (max-width: 1860px) {
  .strucktur__sidebar {
    display: block;
  }
  .strucktur__content__smaller {
    display: none;
  }
  .content__item__expanded__column:nth-child(5) {
    width: 250px;
  }
  .search__mode__on .content__item__expanded__column:nth-child(7) {
    display: block;
  }
  .search__mode__on .content__item__expanded__column:nth-child(8) {
    display: none;
  }
  .search__mode__on .content__item__expanded__column:nth-child(9) {
    display: block;
    min-width: 300px;
  }

  .search__mode__off .content__item__expanded__column:nth-child(7) {
    display: block;
  }
  .search__mode__off .content__item__expanded__column:nth-child(8) {
    display: block;
  }
  .search__mode__off .content__item__expanded__column:nth-child(9) {
    display: none;
  }

  .status-icons-many-columns {
    display: table-cell;
  }

  .status-icons-single-column {
    display: none;
  }

  tr.line-separator {
    border-bottom: none;
  }
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 1020px) {
  .strucktur__sidebar {
    display: block;
  }
  .strucktur__content__smaller {
    display: none;
  }
  .content__item__expanded__column .vertrag__ordner:nth-child(4) {
    width: 180px;
  }
  .search__mode__on .content__item__expanded__column:nth-child(7) {
    display: none;
  }
  .search__mode__on .content__item__expanded__column:nth-child(8) {
    display: none;
  }
  .search__mode__on .content__item__expanded__column:nth-child(9) {
    display: block;
    min-width: 240px;
  }

  .search__mode__off .content__item__expanded__column:nth-child(7) {
    display: none;
  }
  .search__mode__off .content__item__expanded__column:nth-child(8) {
    display: block;
  }
  .search__mode__off .content__item__expanded__column:nth-child(9) {
    display: none;
  }

  .status-icons-many-columns {
    display: table-cell;
  }

  .status-icons-single-column {
    display: none;
  }

  tr.line-separator {
    border-bottom: none;
  }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  .strucktur__sidebar {
    display: none;
  }
  .strucktur__content__smaller {
    display: flex;
    flex-wrap: wrap;
  }
  .content__item__expanded__column:nth-child(5) {
    width: 180px;
  }
  .content__item__expanded__column:nth-child(7) {
    display: none;
  }
  .content__item__expanded__column:nth-child(8) {
    display: none;
  }
  .strucktur__content {
    border-left: none;
  }

  .status-icons-many-columns {
    display: none;
  }

  .status-icons-single-column {
    display: table-cell;
  }

  tr.line-separator {
    border-bottom: 1px solid var(--color-secondary);
  }
}

/* Portrait phones and smaller */
@media (max-width: 640px) {
  .strucktur__sidebar {
    display: none;
  }
  .strucktur__content__smaller {
    display: flex;
    flex-wrap: wrap;
  }

  /* Search mode on */
  .search__mode__on .content__item__expanded__column:nth-child(5) {
    min-width: 150px;
  }
  .search__mode__on .content__item__expanded__column:nth-child(6) {
    display: none;
  }
  .search__mode__on .content__item__expanded__column:nth-child(7) {
    display: none;
  }
  .search__mode__on .content__item__expanded__column:nth-child(8) {
    display: none;
  }
  .search__mode__on .content__item__expanded__column:nth-child(9) {
    display: block;
    min-width: 120px;
  }

  /* Search mode off */
  .search__mode__off .content__item__expanded__column:nth-child(5) {
    min-width: 150px;
  }
  .search__mode__off .content__item__expanded__column:nth-child(7) {
    display: none;
  }
  .search__mode__off .content__item__expanded__column:nth-child(8) {
    display: none;
  }
  .search__mode__off .content__item__expanded__column:nth-child(9) {
    display: none;
  }

  .strucktur__navigation__item {
    font-size: 1em;
  }

  .status-icons-many-columns {
    display: none;
  }

  .status-icons-single-column {
    display: table-cell;
  }

  tr.line-separator {
    border-bottom: 1px solid var(--color-secondary);
  }
}
</style>
