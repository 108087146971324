export const documents = [{ label: 'Marktkommentar zur Strategie', text: 'Der Marktkommentar wird von Ihrer Struktur vorgegeben und ändert sich jedes Quartal.'},
    { label: 'Anlagestrategie', text: 'Kurze Beschreibung Ihrer Strategie zur Infomation an den Kunden.'},
    { label: 'Strategieberater', text: 'Kurze Beschreibung des Strategieberaters/Advisors.'},
    { label: 'Portfoliokommentar zur Strategie', text: 'Hier sollte ein Kommentar zum Verlauf Ihrer Strategie im letzten Vierteljahr eingetragen werden,'
        + ' z.B. welchen Fonds Sie getauscht haben oder wie die Strategie sich entwickelt hat.'
        + ' Dieser Marktkommentar muss auch von Ihnen immer zum Anfang des Quartals erneuert werden.'}];
    
export const varianten = [{ label: 'Zielmarktdaten', text: "Hier sehen Sie den bisher festgelegten und aktuellen Zielmarkt für Ihre Strategie. "
    + "Unter \"Einstellungen\" können Sie Vorgaben für den Zielmarkt Ihrer Strategie eingeben bzw. ändern. "
    + "Sie haben aber zwei Möglichkeiten den Zielmarkt Ihrer Strategie zu bestimmen.<br> "
    + "Die Erstere ist, dass Sie Ihre komplette Fondsstrategie hier zuerst eingeben durch den Button "
    + "\"Zielmarktdaten berechnen\" diesen durch das smartMSC berechnen lassen. Sind Sie mit dem Zielmarkt "
    + "einverstanden, können Sie mit dem Button \"Zielmarktdaten übernehmen\" diese speichern.<br> "
    + "Die zweite Möglichkeit ist, zuerst unter dem Reiter \"Einstellungen\" den Zielmarkt Ihrer Strategie "
    + "festlegen und dann Ihre Strategie erst zu gestalten. Dabei überwacht das MSC immer die Einhaltung des Strategiezielmarkts.<br> "
    + "Wenn die Strategie durch den Vermögensverwalter genehmigt und aktiviert wurde, kann der Zielmarkt nicht mehr verändert werden."}];

export const settings = [{ label: 'Risiko der Strategie', text: 'Im Anlegerprofil muss der Kunde bei der Risikoneigung die angezeigte GSRI oder höher festgelegt haben.'},
    { label: 'Strategie-Kennung', text: 'Dieses Feld wird von der Verwahrstelle vergeben und automatisch eingetragen.'},
    { label: 'Haltedauer in Jahren', text: 'Die empfohlene Haltedauer in Jahren. Die Berechnung der Ex-Ante Kosten erfolgt immer für eine Dauer von 10 Jahren. '
        + 'Bitte beachten Sie, dass der im Zielmarkt festgelegte Zeithorizont ebenfalls eine Mindest-Haltedauer impliziert.'}]

export const gebuehren = [{ label: 'Geschätzte jährliche Wertentwicklung', text: 'Bitte schätzen Sie die Wertentwicklung nach Produkt- und vor Dienstleistungskosten.'}]

export const others = [{ label: 'Benchmark', text: 'Hier können Sie einen Benchmark definieren, der zum Vergleich Ihrer Vermögensverwaltung angezeigt wird.'
    + 'Möchten Sie nur einen einzelnen Wert haben, geben Sie diesem einem 100%-Anteil.'},
    { label: 'Basispunkte WealthKonzept', text: 'Der Anteil des Vermögensverwalters wird durch den Vermögensverwalter eingetragen. '
        + 'Er beläuft sich je nach verwaltetem Vermögen des Advisors auf 20 - 30 BP (30 BP bis 5 Mio €; 25 BP ab 5 Mio € bis 20 Mio €; 20 BP ab 20 Mio €). '
        + 'Der Anteil ist zzgl. Mwst.'},
    { label: 'Basispunkte Advisor', text: 'Die Fee für den Advisor sollte nicht über 30 BP liegen. Die Fee ist zzgl. Mwst.'}]

export const othersFa = [{ label: 'Benchmark', text: 'Hier können Sie einen Benchmark definieren, der zum Vergleich Ihrer Vermögensverwaltung angezeigt wird.'
    + 'Möchten Sie nur einen einzelnen Wert haben, geben Sie diesem einem 100%-Anteil.'}]