<template>
  <div>
    <GesellschaftTitleNavigation
      :headerActions="headerActions"
      @action-UPLOAD_DOCUMENT="uploadNewDocument"
      @action-CREATE_KOMMUNIKATION="openEditOrCreateEinreichungModal"
    />

    <template v-if="loading.init">
      <GhostLoading useBoxContainer type="input" />
      <GhostLoading useBoxContainer type="table" />
      <GhostLoading useBoxContainer type="block" />
    </template>
    <template v-else>
      <div class="box__container" v-if="isIntern">
        <div class="box__title">Faxserver</div>
        <ComboBox
          isComponentHalfSize
          :sortComboboxValues="false"
          :values="faxServerValues"
          v-model="antragsbearbeitung.faxServer"
        />
      </div>

      <div class="box__container">
        <Table
          title="Kommunikationen"
          :headers="kommunikationHeaders"
          :rows="kommunikationRows"
          :rowsPerPage="10"
          @click-bezeichnung="openEditOrCreateEinreichungModal"
          @action-DELETE="deleteGesellKommunikation"
        />
      </div>

      <div class="box__container" v-if="isIntern">
        <div class="box__title">Elektonische Signatur</div>
        <InputToggleSwitch
          label="e-signiert Text mit Ort/Datum weglassen"
          v-model="antragsbearbeitung.ohneESignaturText"
        />
      </div>

      <div class="box__container" v-if="isIntern">
        <div class="box__title">Spezielle Einstellungen Versicherungen</div>
        Ist nichts eingetragen, erfolgen die Erinnerungen in beiden Fällen nach 14 Tagen.
        <InputField
          label="Erste Erinnerung nach Anzahl Tagen"
          isComponentHalfSize
          type="number"
          v-model="antragsbearbeitung.einreichungVersWartezeit1"
        />
        <InputField
          label="Folgeerinnerungen nach Anzahl Tagen"
          isComponentHalfSize
          type="number"
          v-model="antragsbearbeitung.einreichungVersWartezeit2"
        />
        <InputTextArea
          label="Vorgehensweise der Antragsbearbeitung"
          isComponentHalfSize
          v-model="antragsbearbeitung.vorgehenAntragsbearbeitung"
        />
      </div>

      <div class="box__container" v-if="isModusVersDaten">
        <InputField
          label="Typischer Bearbeitungszeitraum (Tage)"
          isComponentHalfSize
          type="number"
          v-model="antragsbearbeitung.estimatedDoneDate"
        />
      </div>

      <DragnDropArea
        v-if="isIntern"
        hoverText="Datei hier ablegen"
        :disabled="isSomethingLoading"
        @files="dropFiles($event)"
      >
        <div class="box__container">
          <Table
            title="Beispielanträge"
            :headers="gesellAntragHeaders"
            :rows="gesellAntragRows"
            :rowsPerPage="10"
            @action-EDIT="editDocument"
            @action-DELETE="deleteDocument"
          >
            <template v-slot:bezeichnung="row">
              <DownloadLink 
                  :title="row.bezeichnung"
                  :filename="row.bezeichnung"
                  downloadServicePath="/get_simple_file"
                  :queryParams="{
                    fileId: row.id
                  }"
                />
            </template>
          </Table>
        </div>
      </DragnDropArea>
    </template>

      <DokumentUploadModal
        ref="uploadModal"
        disableMultifile
        disableFotoUpload
        v-model="files"
        :confirmDisabled="!fileData.bezeichnung"
        @upload="uploadDocuments"
      >
        <InputField
          label="Bezeichnung"
          :renderDanger="!fileData.bezeichnung"
          v-model="fileData.bezeichnung"
        />
        <ComboBox
          label="Typ"
          :sortComboboxValues="false"
          :values="fileTypValues"
          :value="fileData.typ"
          disabled
        />
        <InputTextArea
          label="Bemerkung"
          v-model="fileData.bemerkung"
        />
        <DatePickerField
          label='Stand'
          isValueAsString
          v-model="fileData.dateStand"
        />
      </DokumentUploadModal>

    <BaseModal 
      ref="editOrCreateEinreichungModal"
      modalTitle="Kennung hinzufügen"
      :autoClose="false"
      :confirmDisabled="!editKommunikationRow.name || (isVersicherung && !editKommunikationRow.zweigstelleId)"
      @onConfirmButton="saveGesellKommunikation()"
    > 

      <InputToggleSwitch
        v-if="isIntern"
        label="Alle Antragsarten anzeigen"
        v-model="editKommunikationRow.alleGesellschaften"
      />
      <ComboBox
        label="Antragsart" 
        :sortComboboxValues="false"
        :values="currentArtValues"
        :renderDanger="!editKommunikationRow.name"
        v-model="editKommunikationRow.name"
      />

      <template v-if="isVersicherung">
        <ComboBox
          label="Versicherungsbereich" 
          :sortComboboxValues="false"
          :values="versKategorieValues"
          v-model="editKommunikationRow.versKategorie"
        />
        <ComboBox
          label="Zweigstelle" 
          :sortComboboxValues="false"
          :values="zweigstelleValues"
          :renderDanger="!editKommunikationRow.zweigstelleId"
          v-model="editKommunikationRow.zweigstelleId"
        />
        <InputField
          :disabled="editKommunikationRow.name !== 'Schadensmeldung'"
          label="Telefon Ansprechpartner"
          v-model="editKommunikationRow.appTelefonZusatz"
        />
      </template>

      <ComboBox
        label="Einreichung mittels" 
        :sortComboboxValues="false"
        :values="einreichungArtValues"
        v-model="editKommunikationRow.art"
      />

      <InputToggleSwitch
        v-if="editKommunikationRow.showSignForbidden"
        label="digitale Signatur ist nicht erlaubt"
        v-model="editKommunikationRow.signForbidden"
      />

      <ComboBox
        v-if="istFPPlusMaster"
        label="Einreichung gilt für" 
        :sortComboboxValues="false"
        :values="einreichungZusatzValues"
        v-model="editKommunikationRow.beschraenkungZusatz"
      />

      <InputField 
        label="Mailadresse / Faxnummer"
        v-model="editKommunikationRow.adresse"
      />
      <InputField 
        label="CC"
        v-model="editKommunikationRow.cc"
      />
      <InputField 
        label="Betreff"
        v-model="editKommunikationRow.betreff"
      />
      <InputField 
        label="Passwort für Verschlüsselung von PDF-Dokumenten in E-Mails"
        v-model="editKommunikationRow.passwort"
      />
      <InputTextHtml
        v-model="editKommunikationRow.text"
      />

    </BaseModal>
  </div>

</template>

<script>
import GESELLSCHAFT_TYPES from '@/store/gesellschaft/types';
import CORE_TYPES from '@/store/core/types';

import { mapGetters } from 'vuex';

import GesellschaftTitleNavigation from '@/views/gesellschaft/intern/GesellschaftTitleNavigation.vue'
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';

import Table from "@/components/table2/Table.vue";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import { makeQueryParam } from '@/helpers/utils-helper';

import axios from 'axios';
import { TextColumn, SlotColumn, ActionColumn, SimpleAction, ConfirmedAction, DateColumn } from "@/components/table2/table_util.js";
import ComboBox from '@/components/core/forms/ComboBox.vue'
import InputField from '@/components/core/forms/InputField.vue'
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import InputTextHtml from '@/components/core/forms/antraege/InputTextHtml.vue';
import BaseModal from '@/components/core/BaseModal.vue'
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import DokumentUploadModal from "@/views/gesellschaft/intern/DokumentUploadModal.vue";
import DownloadLink from '@/components/core/download/DownloadLink.vue'
import DragnDropArea from '@/components/fileUpload/DragnDropArea.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue'
import dayjs from 'dayjs';

const config = {
  defaultSpinner: true
};

export default {
  mixins: [],
  components: {
    GesellschaftTitleNavigation,
    Table,
    GhostLoading,
    ComboBox,
    InputField,
    InputTextArea,
    BaseModal,
    DatePickerField,
    DokumentUploadModal,
    DownloadLink,
    DragnDropArea,
    InputToggleSwitch,
    InputTextHtml,
  },
  data() {
    return {
      antragsbearbeitung: {},
      originalAntragsbearbeitung: null,
      loading: {
        init: false,
        save: false,
      },
      editKommunikationRow: {},
      editRow: {},
      fileData: {},
      files: [],
    }
  },
  watch: {
    gesellschaft: {
      handler(newVal) {

        this.antragsbearbeitung = newVal.antragsbearbeitung || {}

        if (!this.originalAntragsbearbeitung && Object.keys(this.antragsbearbeitung).length) {
          // Create a deep copy and compare it when trying to save. If no changes were made we don't need to save
          this.originalAntragsbearbeitung = JSON.parse(JSON.stringify(this.antragsbearbeitung))
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      gesellschaft: GESELLSCHAFT_TYPES.GETTERS.GET_GESELLSCHAFT,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      isGesellschaft: CORE_TYPES.GETTERS.IS_GESELLSCHAFT,
      istFPPlusMaster: CORE_TYPES.GETTERS.IS_FPP_PLUS_MASTER,
    }),
    isModusVersDaten() {
      return this.$route.meta?.isModusVersDaten
    },
    kommunikationHeaders() {
      return {
        lockedLeft: [
          TextColumn("bezeichnung", "Antragsart").makeConditionalLink('editable'),
        ],
        center: [
          TextColumn("einreichungUnsigniert", "Einreichung Papiersignatur"),
          TextColumn("einreichungSigniert", "Einreichung e-Signatur"),
          ...(this.isVersicherung ? [TextColumn("zweigstelle", "Zweigstelle")] : []),
        ],
        lockedRight: [
          ...(this.isIntern || this.isModusVersDaten ? [ActionColumn("actions", "Aktionen")] : [])
        ],
      }
    },
    kommunikationRows() {
      let rows = this.gesellschaft.antragsbearbeitung?.kommunikationen || []

      return rows.map(row => ({
        ...row,
        actions: this.makeKommunikationActions(row),
      }))
    },
    gesellAntragHeaders() {
      return {
        lockedLeft: [
          SlotColumn("bezeichnung", "Bezeichnung", 250, 1).makeAlwaysVisible(),
        ],
        center: [
          DateColumn("dateStand", "Datum"),
          TextColumn("bemerkung", "Bemerkung"),
          DateColumn("dateCreated", "Erstellungsdatum"),
          TextColumn("id", "ID"),
        ],
        lockedRight: [
          ActionColumn("actions", "Aktionen"),
        ],
      }
    },
    gesellAntragRows() {
      let rows = this.gesellschaft.antragsbearbeitung?.gesellschaftsantraege || []

      return rows.map(row => ({
        ...row,
        actions: this.makeActions(row),
      }))
    },
    headerActions() {
      return [
        PageHeaderSimpleAction('UPLOAD_DOCUMENT', 'Dokument hochladen').withVisible(() => this.isIntern),
        PageHeaderSimpleAction('CREATE_KOMMUNIKATION', 'Neue Kommunikation').withVisible(() => this.isIntern || this.isModusVersDaten),
      ]
    },
    isSomethingLoading() {
      return Object.values(this.loading).some(action => action) 
    },
    fileTypValues() {
      return this.gesellschaft.comboboxSelections?.fileTypValues || []
    },
    faxServerValues() {
      return this.gesellschaft.comboboxSelections?.faxServerValues || []
    },
    artValuesLong() {
      return this.gesellschaft.comboboxSelections?.artValuesLong || []
    },
    artValues() {
      return this.gesellschaft.comboboxSelections?.artValues || []
    },
    currentArtValues() {
      return this.editKommunikationRow.alleGesellschaften ? this.artValuesLong : this.artValues
    },
    einreichungArtValues() {
      return this.gesellschaft.comboboxSelections?.einreichungArtValues || []
    },
    versKategorieValues() {
      return this.gesellschaft.comboboxSelections?.versKategorieValues || []
    },
    zweigstelleValues() {
      return this.gesellschaft.comboboxSelections?.zweigstelleValues || []
    },
    einreichungZusatzValues() {
      return this.gesellschaft.comboboxSelections?.einreichungZusatzValues || []
    },
    isVersicherung() {
      return !!this.gesellschaft.isVersicherung
    },
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData(isReload = false) {
      this.loading.init = true

      this.$store.dispatch(GESELLSCHAFT_TYPES.ACTIONS.GET_GESELLSCHAFT_ANTRAGSBEARBEITUNG, { gesellschaftId: this.$route.params.gesellId, isReload })
      .finally(() => this.loading.init = false);
    },
    makeKommunikationActions(row) {
      let actions = []

      if (row.deletable) {
        actions.push(ConfirmedAction("DELETE", 'PhTrash', "Löschen", `Möchten Sie den Eintrag für "${row.beschreibung}" wirklich löschen?`, 'Einreichungsweg löschen', "Löschen"))
      }

      return actions
    },
    makeActions(row) {
      let actions = []

      if (row.deletable) {
        actions.push(ConfirmedAction("DELETE", 'PhTrash', "Löschen", `Soll das Dokument "${row.bezeichnung || row.filename}" wirklich gelöscht werden?`, 'Dokument löschen', "Löschen"))
      }

      return actions
    },
    uploadNewDocument() {
      this.fileData = {
        typ: 'GESELLSCHAFTSANTRAG',
        dateStand: dayjs().format("DD.MM.YYYY"),
      }
      this.files = []
      this.$refs.uploadModal.open()
    },
    dropFiles(files) {
      this.fileData = {
        typ: 'GESELLSCHAFTSANTRAG',
        dateStand: dayjs().format("DD.MM.YYYY"),
      }
      this.files = files
      this.$refs.uploadModal.open()
    },
    openEditOrCreateEinreichungModal(row) {
      if (!row) {
        this.editKommunikationRow = {
          name: "",
          versKategorie: "",
          zweigstelleId: this.antragsbearbeitung.hauptpersonId,
        }
      } else {
        this.editKommunikationRow = {...row}
      }
      
      this.$refs.editOrCreateEinreichungModal.open()
    },
    async saveGesellKommunikation(editKommunikationRow = this.editKommunikationRow) {
      await this.save()

      this.loading.save = true

      const params = makeQueryParam({ gesellschaftId: this.$route.params.gesellId }, true)

      axios.post(`${process.env.VUE_APP_API}/gesellschaftenService/gesellkommunikation?${params}`, editKommunikationRow, config).then(() => {
        this.$refs.editOrCreateEinreichungModal.close()
        this.initData(true)
      }).finally(() => this.loading.save = false)
    },
    deleteGesellKommunikation({id}) {
      this.loading.save = true
      const params = makeQueryParam({ gesellKommunikationId: id, gesellschaftId: this.$route.params.gesellId }, true)

      axios.delete(`${process.env.VUE_APP_API}/gesellschaftenService/gesellkommunikation?${params}`, config).then(response => {
        if (response && response.status === 200) {
          this.gesellschaft.antragsbearbeitung.kommunikationen = this.gesellschaft.antragsbearbeitung.kommunikationen.filter(row => row.id !== id)
        } 
      }).finally(() => this.loading.save = false)
    },
    editDocument(row) {
      this.fileData = JSON.parse(JSON.stringify(row))
      delete this.fileData.actions;
      this.files = [row]
      this.$refs.uploadModal.open()
    },
    deleteDocument({id}) {
      this.loading.save = true
      const params = makeQueryParam({ id, gesellschaftId: this.$route.params.gesellId }, true)

      axios.delete(`${process.env.VUE_APP_API}/gesellschaftenService/documents?${params}`, config).then(response => {
        if (response && response.status === 200) {
          this.gesellschaft.antragsbearbeitung.gesellschaftsantraege = this.gesellschaft.antragsbearbeitung.gesellschaftsantraege.filter(row => row.id !== id)
        } 
      }).finally(() => this.loading.save = false)
    },
    uploadDocuments({tempFileId}) {
      this.loading.save = true

      const params = makeQueryParam({ gesellschaftId: this.$route.params.gesellId }, true)
      
      const payload = {
        ...this.fileData,
        tempFileId,
      }

      axios.post(`${process.env.VUE_APP_API}/gesellschaftenService/documents?${params}`, payload, config).then(response => {
        if (response && response.data) {
          const indx = this.gesellschaft.antragsbearbeitung.gesellschaftsantraege.findIndex(row => row.id === response.data.id)

          if (indx > -1) {
            this.$set(this.gesellschaft.antragsbearbeitung.gesellschaftsantraege, indx, response.data)
          } else {
            this.gesellschaft.antragsbearbeitung.gesellschaftsantraege.push(response.data)
          }
        } 
      }).finally(() => this.loading.save = false)
    },
    async save() {
      if (JSON.stringify(this.originalAntragsbearbeitung) === JSON.stringify(this.antragsbearbeitung)) {
        return
      }
      this.loading.save = true

      const params = makeQueryParam({ gesellschaftId: this.$route.params.gesellId }, true)

      return axios.post(`${process.env.VUE_APP_API}/gesellschaftenService/antragsbearbeitung?${params}`, this.antragsbearbeitung, config)
        .finally(() => this.loading.save = false)
    },
  },
  beforeRouteLeave(to, from, next) {
    this.save();
    next();
  }
}
</script>