var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.parameters && _vm.showMultibankingActionButtons
    ? _c("div", [
        _c(
          "div",
          {
            staticClass: "layout__negative-margin--8 d-flex align-items-center"
          },
          [
            _c("InputToggleSwitch", {
              attrs: {
                label: _vm.parameters.shareDataSwitchText,
                inLineLabel: "",
                suppressValidationMessage: ""
              },
              on: { input: _vm.toggleShareAccount },
              model: {
                value: _vm.shareAccounts,
                callback: function($$v) {
                  _vm.shareAccounts = $$v
                },
                expression: "shareAccounts"
              }
            })
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }