<template>
  <div>
    <PageHeaderTitleNavigation
      title="Ändern/Neue Zuordnungen für Data Control"
    />
    <div class="box__container">
      <div class="row justify-content-center mt-3">
        <div class="col-sm-6 mt-2">
          <ComboBox
            label="Sparte MSC"
            v-model="form.sparteMSC"
            :values="getSparten"
          />
        </div>
      </div>
      <div class="row justify-content-center mt-3">
        <div class="col-sm-6 mt-2">
          <InputField isComponentHalfSize v-model="form.sparteMSCText" />
        </div>
      </div>
      <div class="row justify-content-center mt-3">
        <div class="col-sm-6 mt-2">
          <ComboBox
            label="Gesellschaftsname MSC"
            v-model="form.gesellschaftsnameMSC"
            :values="getGesellschaften"
          />
        </div>
      </div>
      <div class="row justify-content-center mt-3">
        <div class="col-sm-6 mt-2">
          <InputField v-model="form.gesellschaftsnameMSCText" />
        </div>
      </div>
      <div class="row justify-content-center mt-3">
        <div class="col-sm-6 mt-2">
          <InputField label="Produkt DC" v-model="form.productDC" />
        </div>
      </div>
      <div class="row justify-content-center mt-3">
        <div class="col-sm-6 mt-2 mb-3">
          <InputField
            label="Gesellschaft DC"
            v-model="form.gesellschaftsnameDC"
          />
        </div>
      </div>
      <div class="row justify-content-center mt-3">
        <div class="col-sm-6 mt-2 mb-3">
          <BaseButton @click="saveProduct()" :disabled="disabled">
            <span>Speichern</span>
          </BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DCS_SYNCHRONISATION_TYPES from "@/store/dcsSynchronisation/types";
import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import PageHeaderTitleNavigation from "@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue";
import { mapGetters } from "vuex";
import InputField from "@/components/core/forms/InputField.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import BaseButton from "@/components/core/BaseButton.vue";

export default {
  data() {
    return {
      form: {},
      isDisabled: false,
    };
  },
  computed: {
    ...mapGetters({
      product: DCS_SYNCHRONISATION_TYPES.GETTERS.GET_PRODUCT,
      comboboxVlaues: DCS_SYNCHRONISATION_TYPES.GETTERS.GET_COMBOBOXVALUES,
    }),
    getGesellschaften() {
      return this.comboboxVlaues?.gesellListe || [];
    },
    getSparten() {
      return this.comboboxVlaues?.spartenListe || [];
    },
    disabled() {
      return this.isDisabled;
    },
  },
  mounted() {
    this.$store.dispatch(DCS_SYNCHRONISATION_TYPES.ACTIONS.LOAD_COMBOBOXVALUES);
    const productId = this.$route.params.productId;
    this.getProductByID(productId).then(() => {
      if (this.product && this.product.id == productId) {
        this.form = {
          ...this.product,
        };
      }
    });
  },
  methods: {
    addProductDataEdited(data) {
      const payload = {
        ...this.product,
        ...data,
      };
      this.$store.commit(
        DCS_SYNCHRONISATION_TYPES.MUTATIONS.SAVE_PRODUCT_SUCCESS,
        payload
      );
    },
    async saveProduct() {
      if (
        JSON.stringify({ ...this.from }) !=
          JSON.stringify({ ...this.product }) ||
        !this.product
      ) {
        await this.$store
          .dispatch(DCS_SYNCHRONISATION_TYPES.ACTIONS.SAVE_PRODUCT, this.form)
          .then(() => (this.isDisabled = true));
      }
    },
    async getProductByID(productId) {
      await this.$store.dispatch(
        DCS_SYNCHRONISATION_TYPES.ACTIONS.GET_PRODUCT_BY_ID,
        productId
      );
    },
  },
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    InputField,
    ComboBox,
    BaseButton,
  },

  beforeRouteLeave(to, from, next) {
    next();
  },
};
</script>
