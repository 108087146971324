export const MUTATION_PREFIX = 'VERTRAG_STATUS_MUTATIONS_';
export const ACTIONS_PREFIX = 'VERTRAG_STATUS_ACTIONS_';
export const GETTERS_PREFIX = 'VERTRAG_STATUS_GETTERS_';

export default {
  MUTATIONS: {
    VERTRAG_STATUS_LIST_SUCCESS: MUTATION_PREFIX + 'VERTRAG_STATUS_LIST_SUCCESS',
    VERTRAG_STATUS_SUCCESS: MUTATION_PREFIX + 'VERTRAG_STATUS_SUCCESS',
    DELETE_VERTRAG_STATUS: MUTATION_PREFIX + 'DELETE_VERTRAG_STATUS',
    SAVE_VERTRAG_STATUS_SUCCESS: MUTATION_PREFIX + 'SAVE_VERTRAG_STATUS_SUCCESS',
    UPDATE_VERTRAG_STATUS_SUCCESS: MUTATION_PREFIX + 'UPDATE_VERTRAG_STATUS_SUCCESS',
    RESET_DATA: MUTATION_PREFIX + 'RESET_DATA',
  },
  ACTIONS: {
    VERTRAG_STATUS_LIST: MUTATION_PREFIX + 'VERTRAG_STATUS_LIST',
    VERTRAG_STATUS: MUTATION_PREFIX + 'VERTRAG_STATUS',
    DELETE_VERTRAG_STATUS: MUTATION_PREFIX + 'DELETE_VERTRAG_STATUS',
    SAVE_VERTRAG_STATUS: MUTATION_PREFIX + 'SAVE_VERTRAG_STATUS',
    UPDATE_VERTRAG_STATUS: MUTATION_PREFIX + 'UPDATE_VERTRAG_STATUS',
  },
  GETTERS: {
    VERTRAG_STATUS_LIST: GETTERS_PREFIX + 'VERTRAG_STATUS_LIST',
    VERTRAG_STATUS: GETTERS_PREFIX + 'VERTRAG_STATUS',
  }
}
