export const MUTATION_PREFIX = 'LIMIT_: ';
export const ACTIONS_PREFIX = 'LIMIT_ACTIONS_';
export const GETTERS_PREFIX = 'LIMIT_GETTERS_';

export default {
  MUTATIONS: {
    GET_DEPOTLIMIT_SUCCESS: MUTATION_PREFIX + 'GET_DEPOTLIMIT_SUCCESS',
    GET_DEPOTLIMIT_LIST_SUCCESS: MUTATION_PREFIX + 'GET_DEPOTLIMIT_LIST_SUCCESS',
    GET_DEPOTPOSITION_LIMIT_LIST_SUCCESS: MUTATION_PREFIX + 'GET_DEPOTPOSITION_LIMIT_LIST_SUCCESS',
    GET_DEPOTPOSITION_LIMIT_AUTOMATIC_LIST_SUCCESS: MUTATION_PREFIX + 'GET_DEPOTPOSITION_LIMIT_AUTOMATIC_LIST_SUCCESS',
    GET_DEPOTPOSITION_LIMIT_AUTOMATIC_SUCCESS: MUTATION_PREFIX + 'GET_DEPOTPOSITION_LIMIT_AUTOMATIC_SUCCESS',
    GET_RISIKOKLASSEN_LIMIT_SUCCESS: MUTATION_PREFIX + 'GET_RISIKOKLASSEN_LIMIT_SUCCESS',
    GET_RISIKOKLASSEN_LIMIT_LIST_SUCCESS: MUTATION_PREFIX + 'GET_RISIKOKLASSEN_LIMIT_LIST_SUCCESS',
    GET_WERTPAPIERKATEGORIE_LIMIT_LIST_SUCCESS: MUTATION_PREFIX + 'GET_WERTPAPIERKATEGORIE_LIMIT_LIST_SUCCESS',
    GET_WERTPAPIERKATEGORIE_LIMIT_SUCCESS: MUTATION_PREFIX + 'GET_WERTPAPIERKATEGORIE_LIMIT_SUCCESS',
    GET_WERTPAPIER_LIMIT_LIST_SUCCESS: MUTATION_PREFIX + 'GET_WERTPAPIER_LIMIT_LIST_SUCCESS',
    GET_WERTPAPIER_LIMIT_SUCCESS: MUTATION_PREFIX + 'GET_WERTPAPIER_LIMIT_SUCCESS',
    GET_GD200_LIMIT_LIST_SUCCESS: MUTATION_PREFIX + 'GET_GD200_LIMIT_LIST_SUCCESS',
    GET_GD200_LIMIT_SUCCESS: MUTATION_PREFIX + 'GET_GD200_LIMIT_SUCCESS',
    DELETE_RISIKOKLASSEN_LIMIT_FROM_LIST_SUCCESS: MUTATION_PREFIX + 'DELETE_RISIKOKLASSEN_LIMIT_FROM_LIST_SUCCESS',
    DELETE_WERTPAPIER_LIMIT: MUTATION_PREFIX + 'DELETE_WERTPAPIER_LIMIT',
    DELETE_GD200_LIMIT: MUTATION_PREFIX + 'DELETE_GD200_LIMIT',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    SET_MORE_LIMITS: MUTATION_PREFIX + 'SET_MORE_LIMITS',
  },
  ACTIONS: {
    GET_DEPOTLIMIT: ACTIONS_PREFIX + 'GET_DEPOTLIMIT',
    GET_DEPOTLIMIT_LIST: ACTIONS_PREFIX + 'GET_DEPOTLIMIT_LIST',
    GET_DEPOTPOSITION_LIMIT_LIST: ACTIONS_PREFIX + 'GET_DEPOTPOSITION_LIMIT_LIST',
    GET_DEPOTPOSITION_LIMIT_AUTOMATIC_LIST: ACTIONS_PREFIX + 'GET_DEPOTPOSITION_LIMIT_AUTOMATIC_LIST',
    GET_DEPOTPOSITION_LIMIT_AUTOMATIC: ACTIONS_PREFIX + 'GET_DEPOTPOSITION_LIMIT_AUTOMATIC',
    GET_RISIKOKLASSEN_LIMIT: ACTIONS_PREFIX + 'GET_RISIKOKLASSEN_LIMIT',
    GET_RISIKOKLASSEN_LIMIT_LIST: ACTIONS_PREFIX + 'GET_RISIKOKLASSEN_LIMIT_LIST',
    GET_WERTPAPIERKATEGORIE_LIMIT_LIST: ACTIONS_PREFIX + 'GET_WERTPAPIERKATEGORIE_LIMIT_LIST',
    GET_WERTPAPIERKATEGORIE_LIMIT: ACTIONS_PREFIX + 'GET_WERTPAPIERKATEGORIE_LIMIT',
    GET_WERTPAPIER_LIMIT_LIST: ACTIONS_PREFIX + 'GET_WERTPAPIER_LIMIT_LIST',
    GET_WERTPAPIER_LIMIT: ACTIONS_PREFIX + 'GET_WERTPAPIER_LIMIT',
    GET_GD200_LIMIT_LIST: ACTIONS_PREFIX + 'GET_GD200_LIMIT_LIST',
    GET_GD200_LIMIT: ACTIONS_PREFIX + 'GET_GD200_LIMIT',
    SAVE_DEPOTLIMIT: ACTIONS_PREFIX + 'SAVE_DEPOTLIMIT',
    SAVE_RISIKOKLASSEN_LIMIT: ACTIONS_PREFIX + 'SAVE_RISIKOKLASSEN_LIMIT',
    SAVE_DEPOTPOSITION_LIMIT_AUTOMATIC: ACTIONS_PREFIX + 'SAVE_DEPOTPOSITION_LIMIT_AUTOMATIC',
    SAVE_WERTPAPIERKATEGORIE_LIMIT: ACTIONS_PREFIX + 'SAVE_WERTPAPIERKATEGORIE_LIMIT',
    SAVE_WERTPAPIER_LIMIT: ACTIONS_PREFIX + 'SAVE_WERTPAPIER_LIMIT',
    SAVE_GD200_LIMIT: ACTIONS_PREFIX + 'SAVE_GD200_LIMIT',
    RESET_WERTPAPIER_LIMIT: ACTIONS_PREFIX + 'RESET_WERTPAPIER_LIMIT',
    RESET_GD200_LIMIT: ACTIONS_PREFIX + 'RESET_GD200_LIMIT',
    DELETE_DEPOTLIMIT: ACTIONS_PREFIX + 'DELETE_DEPOTLIMIT',
    DELETE_OVERTAKEN_DEPOTLIMITS: ACTIONS_PREFIX + 'DELETE_OVERTAKEN_DEPOTLIMITS',
    DELETE_DEPOTPOSITION_LIMIT: ACTIONS_PREFIX + 'DELETE_DEPOTPOSITION_LIMIT',
    DELETE_ALL_DEPOTPOSITION_LIMITS: ACTIONS_PREFIX + 'DELETE_ALL_DEPOTPOSITION_LIMITS',
    DELETE_DEPOTPOSITION_LIMIT_AUTOMATIC: ACTIONS_PREFIX + 'DELETE_DEPOTPOSITION_LIMIT_AUTOMATIC',
    DELETE_RISIKOKLASSEN_LIMIT: ACTIONS_PREFIX + 'DELETE_RISIKOKLASSEN_LIMIT',
    DELETE_RISIKOKLASSEN_LIMIT_FROM_LIST: ACTIONS_PREFIX + 'DELETE_RISIKOKLASSEN_LIMIT_FROM_LIST',
    DELETE_ALL_OVERTAKEN_RISIKOKLASSEN_LIMITS: ACTIONS_PREFIX + 'DELETE_ALL_OVERTAKEN_RISIKOKLASSEN_LIMITS',
    DELETE_WERTPAPIERKATEGORIE_LIMIT: ACTIONS_PREFIX + 'DELETE_WERTPAPIERKATEGORIE_LIMIT',
    DELETE_FONDS_LIMIT: ACTIONS_PREFIX + 'DELETE_FONDS_LIMIT',
    DELETE_OVERTAKEN_WERTPAPIER_LIMITS: ACTIONS_PREFIX + 'DELETE_OVERTAKEN_WERTPAPIER_LIMITS',
    RESET_WERTPAPIERKATEGORIE_LIMIT: ACTIONS_PREFIX + 'RESET_WERTPAPIERKATEGORIE_LIMIT',
    RESET_MORE_LIMITS: ACTIONS_PREFIX + 'RESET_MORE_LIMITS',
  },
  GETTERS: {
    DEPOTLIMIT: GETTERS_PREFIX + 'DEPOTLIMIT',
    DEPOTLIMIT_LIST: GETTERS_PREFIX + 'DEPOTLIMIT_LIST',
    DEPOTPOSITION_LIMIT_LIST: GETTERS_PREFIX + 'DEPOTPOSITION_LIMITS',
    DEPOTPOSITION_LIMIT_AUTOMATIC_LIST: GETTERS_PREFIX + 'DEPOTPOSITION_LIMITS_AUTOMATIC',
    DEPOTPOSITION_LIMIT_AUTOMATIC: GETTERS_PREFIX + 'DEPOTPOSITION_LIMIT_AUTOMATIC',
    RISIKOKLASSEN_LIMIT: GETTERS_PREFIX + 'RISIKOKLASSEN_LIMIT',
    RISIKOKLASSEN_LIMIT_LIST: GETTERS_PREFIX + 'RISIKOKLASSEN_LIMIT_LIST',
    WERTPAPIERKATEGORIE_LIMIT_LIST: GETTERS_PREFIX + 'WERTPAPIERKATEGORIE_LIMIT_LIST',
    WERTPAPIERKATEGORIE_LIMIT: GETTERS_PREFIX + 'WERTPAPIERKATEGORIE_LIMIT',
    WERTPAPIER_LIMIT_LIST: GETTERS_PREFIX + 'WERTPAPIER_LIMIT_LIST',
    WERTPAPIER_LIMIT: GETTERS_PREFIX + 'WERTPAPIER_LIMIT',
    GD200_LIMIT_LIST: GETTERS_PREFIX + 'GD200_LIMIT_LIST',
    GD200_LIMIT: GETTERS_PREFIX + 'GD200_LIMIT',
  }
}
