import MERGE_PEOPLE_TYPES from './types';
import CORE_TYPES from '@/store/core/types'
import LOG_TYPES from '@/store/log/types'
import axios from 'axios';
import { buildMessageWith } from '@/helpers/log-message-helper';

const config = {
	defaultSpinner: true
};

export default {
	async [MERGE_PEOPLE_TYPES.ACTIONS.LIST_DUPLICATED_PEOPLE]({ getters, commit, }, params) {

		try {
			commit(MERGE_PEOPLE_TYPES.MUTATIONS.LIST_DUPLICATED_PEOPLE_SUCCESS, [])
			commit(MERGE_PEOPLE_TYPES.MUTATIONS.LIST_UNIFIABLE_PEOPLE_SUCCESS, [])
			const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/merge_people/duplicated_people`, {params, ...config});

			if (response?.data) {
				commit(MERGE_PEOPLE_TYPES.MUTATIONS.LIST_DUPLICATED_PEOPLE_SUCCESS, response.data)
			}
		} catch (error) {
			commit(MERGE_PEOPLE_TYPES.MUTATIONS.LIST_DUPLICATED_PEOPLE_ERROR)
			throw error
		}

	},

	async [MERGE_PEOPLE_TYPES.ACTIONS.LIST_UNIFIABLE_PEOPLE]({ getters, commit, }, params) {

		try {
			const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/merge_people/unifiable_people`, {params, ...config});

			if (response?.data) {
				commit(MERGE_PEOPLE_TYPES.MUTATIONS.LIST_UNIFIABLE_PEOPLE_SUCCESS, response.data)
			}
		} catch (error) {
			commit(MERGE_PEOPLE_TYPES.MUTATIONS.LIST_UNIFIABLE_PEOPLE_ERROR)
			throw error
		}

	},

	async [MERGE_PEOPLE_TYPES.ACTIONS.UNITE_PEOPLE]({ getters, commit, dispatch }, payload) {
		try {
			const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/merge_people/unite_people`, payload, config);

			if (response?.data?.messageText) {
				dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessageWith({
					content: response.data.messageText,
				}))
			}

		} catch (error) {
			// empty block
		} finally {
			commit(MERGE_PEOPLE_TYPES.MUTATIONS.LIST_DUPLICATED_PEOPLE_SUCCESS, [])
			commit(MERGE_PEOPLE_TYPES.MUTATIONS.LIST_UNIFIABLE_PEOPLE_SUCCESS, [])
		}
	}

}