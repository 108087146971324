<template>
  <div>
    <div class="mb-3">
      <InputField label="Name" v-model="filter.name" isComponentHalfSize class="p-0 mb-1" />
      <ComboBox label="Anzeige-Optionen" v-model="filter.anzeige" :values="anzeigeValues" isComponentHalfSize />
    </div>

    <div class="mb-2">
      <BaseButton @click="selectAll()" :disabled="disabled">alle auswählen</BaseButton>
      <BaseButton isSecondary @click="unselectAll()" :disabled="disabled">alle abwählen</BaseButton>
    </div>

    <Table
      :key="tableKey"
      :headers="headers"
      :rows="rows"
      :rowsPerPage="ROWS_PER_PAGE"
      :class="{ 'table--disabled': disabled, }"
    >
      <template #selection="row">
        <div class="gesell-selection__container">
          <InputCheckBoxItem v-if="row.erstellungsTyp === 'AUTOMATISCH'" v-model="form[row.id]" :disabled="disabled" @input="dataChanged(row.id, $event)" />
          <button v-else-if="row.erstellungsTyp === 'UNSICHTBAR'" 
            type="button" 
            class="clickable btn-clear"
            :disabled="disabled"
            @click="makeGesellschaftVisible(row)"
          >
            <PhArrowFatRight :size="18" />
          </button>
          <button v-else-if="row.erstellungsTyp === 'DURCH_MAKLER'" 
            type="button" 
            class="clickable btn-clear"
            :disabled="disabled"
            @click="openDeleteGesellschaftConfirmModal(row)"
          >
            <PhTrashSimple :size="18" />
          </button>
        </div>
      </template>
    </Table>
  </div>
</template>

<script>
import { mapGetters, } from 'vuex';
import MAKLER34F_TYPES from '@/store/makler34f/types';

import BaseButton from '@/components/core/BaseButton.vue';
import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import Table from '@/components/table2/Table.vue';
import { SlotColumn, TextColumn, } from '@/components/table2/table_util';
import InputCheckBoxItem from '@/components/core/forms/checkbox/InputCheckBoxItem.vue';

import { PhArrowFatRight, PhTrashSimple, } from 'phosphor-vue';

import { replaceUmlaute, } from '@/helpers/commonfunctions';

const ALLE_ANZEIGEN = 'ALLE_ANZEIGEN';
const DURCH_MAKLER = 'DURCH_MAKLER';
const UNSICHTBAR = 'UNSICHTBAR';

const ROWS_PER_PAGE = 20;

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filter: {
        name: '',
        anzeige: ALLE_ANZEIGEN,
      },
      form: {},
      ROWS_PER_PAGE,
    };
  },
  computed: {
    ...mapGetters({
      makler34fGesellschaftenEdited: MAKLER34F_TYPES.GETTERS.MAKLER34F_GESELLSCHAFTEN_EDITED,
      gesellschaft34fTyp: MAKLER34F_TYPES.GETTERS.GESELLSCHAFT34F_TYP_SELECTED,
    }),
    list() {
      return this.gesellschaft34fTyp?.makler34fGesellschaften || [];
    },
    tableKey() {
      return Object.keys(this.filter).reduce((acc, key) => acc + this.filter[key], '');
    },
    anzeigeValues() {
      return [
        {
          label: 'alle Gesellschaften anzeigen',
          value: ALLE_ANZEIGEN,
        },
        {
          label: 'nur selbst angelegte Gesellschaften anzeigen',
          value: DURCH_MAKLER,
        },
        {
          label: 'nur ausgeblendete Gesellschaften anzeigen',
          value: UNSICHTBAR,
        },
      ];
    },
    headers() {
      const { disabled, } = this;
      return {
        lockedLeft: [
          SlotColumn('selection', 'Auswahl').makeSortable(!disabled),
          TextColumn('gesellName', 'Name').addCellProps({ lineClamp: 4, }).makeSortable(!disabled),
        ],
      };
    },
    rows() {
      return [ ...this.list, ]
        // filter anzeige
        .filter(item => {
          const { anzeige } = this.filter;
          return anzeige === ALLE_ANZEIGEN || anzeige === item.erstellungsTyp;
        })
        // filter name
        .filter(item => {
          const name = this.filter.name?.toLowerCase()?.trim();
          const itemName = item.gesellName?.toLowerCase();
          return !name || itemName?.includes(name) || replaceUmlaute(itemName)?.includes(name);
        })
        .map(item => {
          const angekreuzt = item.id in this.form ? this.form[item.id] : item.angekreuzt;
          return {
            ...item,
            selection: angekreuzt ? 1 : 0,
          };
        });
    },
  },
  watch: {
    list: {
      handler() {
        this.setData();
      },
      immediate: true,
    },
    makler34fGesellschaftenEdited() {
      this.setData();
    },
  },
  methods: {
    setData() {
      this.list?.forEach(item => {
        if(item.id in this.makler34fGesellschaftenEdited) {
          this.$set(this.form, item.id, this.makler34fGesellschaftenEdited[item.id]);
        } else {
          this.$set(this.form, item.id, item.angekreuzt);
        }
      });
    },
    selectItem(item, selected) {
      switch(item.erstellungsTyp) {
        case 'AUTOMATISCH':
          this.dataChanged(item.id, selected);
          break;
        default:
      }
    },
    selectAll() {
      this.list?.forEach(item => this.selectItem(item, true));
    },
    unselectAll() {
      this.list?.forEach(item => this.selectItem(item, false));
    },
    makeGesellschaftVisible(gesellschaft) {
      this.$store.dispatch(MAKLER34F_TYPES.ACTIONS.MAKE_GESELLSCHAFT_VISIBLE, { ...gesellschaft, });
    },
    openDeleteGesellschaftConfirmModal(gesellschaft) {
      this.$confirmModal({
        title: 'Gesellschaft löschen',
        message: `Möchten Sie Gesellschaft <b>"${gesellschaft.gesellName}"</b> wirklich löschen?`,
        labelButtonConfirm: 'Löschen',
      }).then(() => this.$store.dispatch(MAKLER34F_TYPES.ACTIONS.DELETE_GESELLSCHAFT, { ...gesellschaft, }));
    },
    dataChanged(id, value) {
      this.$store.commit(MAKLER34F_TYPES.MUTATIONS.SET_MAKLER34F_GESELLSCHAFTEN_EDITED, {
        [id]: value,
      });
    },
  },
  components: {
    BaseButton,
    InputField,
    ComboBox,
    Table,
    InputCheckBoxItem,
    PhArrowFatRight,
    PhTrashSimple,
  },
}
</script>

<style lang="scss" scoped>
.gesell-selection__container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table--disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
</style>
