
/**
 * This service is intended to manage the steppers of the application.
 * Each instance of StepperMediator stores all the configurations of a stepper.
 *
 * In order to create a dynamic stepper there is tow options. You can create an instance of an empty StepperMediator, e.g;
 *
 *    theNewStepper: StepperMediator = new StepperMediator();
 *
 * The second option is to call the method 'createNewStepper' this way:
 *
 *    this.stepperService.createNewStepper('theNewStepper');
 *
 * Then you can add add all steps you need by calling the method addStep this way:
 *
 *    this.stepperService.theNewStepper.addStep({ stepKey: 'key0', label: 'My new Step'});
 *
 * The step can also be removed by calling 'removeStepByKey' or 'removeStepByIndex'. e.g.:
 *
 *    this.stepperService.theNewStepper.removeStepByKey('key0')
 */
export class StepperMediator {
  stepListSource;
  isLoadingSource;
  currentStep = {};

  constructor(initialStepList = []) {
    this.stepListSource = [...initialStepList];
    this.isLoadingSource = false;
  }

  actioncAfterOnChangeStepFunc = (previousStep, currentStep, previousStepList) => {};

  updateStepReferences() {
    const currentList = this.getCurrentStepList();
    const previousList = currentList.slice(0, currentList.indexOf(this.currentStep));
    const afterList = currentList.slice(currentList.indexOf(this.currentStep) + 1);

    this.currentStep = this.currentStep || {};

    this.currentStep.current = true;
    this.currentStep.before = false;
    this.currentStep.after = false;

    afterList.forEach(step => {
      step.before = false;
      step.after = true;
      step.current = false;
    });

    previousList.forEach(step => {
      step.before = true;
      step.after = false;
      step.current = false;
      step.alreadyVisitedStep = true;
    });
    /* last step can be also marked as visited: if there are no steps or only one step or if it (last -1) substep was visited
    */
    if (!afterList.length && !this.currentStep.alreadyVisitedStep) {
      const substeps = this.currentStep.substeps?.filter(sub => !sub.hidden);
      if (!substeps || !substeps.length || substeps.length === 1 ||
        (substeps[substeps.length - 2].success || substeps[substeps.length - 2].warning)) {
          this.currentStep.alreadyVisitedStep = true;
      }
    }
  }

  updateStepStatus(allSteps = false, checkSummary = true) {
    let currentList = this.getCurrentStepList();
    if (!checkSummary) {
      currentList = currentList.filter(step => step.stepKey !== 'summary');
    }
    if (!allSteps) {
      currentList = currentList.filter(step => step.alreadyVisitedStep);
    }
    currentList.forEach(step => {
      if (step.substeps) {
        if (step.substeps.some(subStep => subStep.warning)) {
          step.success = false;
          step.warning = true;
        } else {
          step.success = true;
          step.warning = false;
        }
      } else {
          step.success = step.warning ? false : true;
          step.warning = step.warning ? true : false;
        }
      });
  }

  getCurrentStepList() {
    return Array.from(this.stepListSource);
  }

  changeSteplist(stepList) {
    this.stepListSource = [...stepList];
  }

  getCurrentStep(stepKey) {
    const currentList = this.getCurrentStepList();
    const currentStep = currentList.filter((step) => step.stepKey === stepKey)[0];
    return currentStep;
  }

  getCurrentSubStepIndex(stepKey, substepKey) {
    if (substepKey) {
      const currentList = this.getCurrentStepList().filter(step => !step.hidden);
      const currentStep = currentList.filter((step) => step.stepKey === stepKey)[0];
      if (currentStep?.substeps && currentStep.substeps?.length) {
        return currentStep.substeps.filter(sub => !sub.hidden)
                .findIndex((subStep)  => subStep.substepKey === substepKey);
      }
    } else {
      return 0;
    }
  }

  getCurrentStepIndex(stepKey) {
    const currentList = this.getCurrentStepList();
    return currentList.findIndex((step)  =>
    step.stepKey === stepKey);
  }

  getFirstStep() {
    const currentList = this.getCurrentStepList().filter(step => !step.hidden);
    if (currentList && currentList.length) {
      return currentList[0];
    }
    return {};
  }

  getLastStep() {
    const currentList = this.getCurrentStepList().filter(step => !step.hidden);
    if (currentList && currentList.length) {
      return currentList[currentList.length - 1];
    }
    return {};
  }

  getNextStep(stepKey) {
    const currentList = this.getCurrentStepList().filter(step => !step.hidden);
    const index = currentList.findIndex((step) => step.stepKey === stepKey);
    return index < (currentList.length - 1) ? currentList[index + 1].stepKey : null;
  }

  getNextSubstep(currentStepKey, substepKey) {
    const currentList = this.getCurrentStepList().filter(step => !step.hidden);
    const currentStep = currentList.find((step) => step.stepKey === currentStepKey);
    if (currentStep && currentStep.substeps && currentStep.substeps.length) {
      if (!substepKey) {
        return {
          stepKey: currentStep.stepKey,
          substepKey: currentStep.substeps[0].substepKey
        };
      } else if (typeof substepKey === 'number' && substepKey < (currentStep.substeps.length - 1)) {
        // old code, maybe not necessary any more
        return {
          stepKey: currentStep.stepKey,
          substepKey: currentStep.substeps[substepKey + 1].substepKey
        };
      } else if (typeof substepKey === 'string') {
        const index = currentStep.substeps.findIndex(subStep => subStep.substepKey === substepKey);
        if (index >= 0 && index < (currentStep.substeps.length - 1)) {
          const nextSubstepKey = this.findNextSubstepKey(currentStep, index);
          if (nextSubstepKey) {
            return { stepKey: currentStep.stepKey, substepKey: nextSubstepKey };
          }
        }
      }
    }
    const nextStepKey = this.getNextStep(currentStepKey);
    if (nextStepKey) {
      const nextStep = this.getCurrentStep(nextStepKey);
      const nextSubstepKey = this.findNextSubstepKey(nextStep, -1);
      return { stepKey: nextStepKey, substepKey: nextSubstepKey || '0' };
    }
  }

  findNextSubstepKey(currentStep, index) {
    if (index >= 0 && index < (currentStep.substeps.length - 1)) {
      const nextSubstep = currentStep.substeps.slice(index + 1).filter(substep => !substep.hidden)[0];
      return nextSubstep && nextSubstep.substepKey;
    } else if (index < 0 && currentStep.substeps && currentStep.substeps.length) {
      return currentStep.substeps[0].substepKey;
    }
  }

  getPreviousStep(stepKey) {
    const currentList = this.getCurrentStepList().filter(step => !step.hidden);
    const index = currentList.findIndex((step) => step.stepKey === stepKey);
    return index > 0 && currentList[index - 1].stepKey;
  }

  getPreviousSubstep(currentStepKey, substepKey) {
    const currentList = this.getCurrentStepList().filter(step => !step.hidden);
    const currentStep = currentList.find((step) => step.stepKey === currentStepKey
      || (step.substeps && step.substeps.length && step.substeps.some(
        substep => substep.substepKey === currentStepKey)));
    if (currentStep && currentStep.substeps && currentStep.substeps.length) {
      const index = this.getCurrentSubStepIndex(currentStepKey, substepKey);
      if (index > 0) {
        let substep = currentStep.substeps.filter(sub => !sub.hidden)[index - 1];
        // if (substep.hidden) {
        //   substep = currentStep.substeps.slice(0, substepKey - 1).find(sub => !sub.hidden);
        // }
        if (substep) {
          return {
            stepKey: currentStep.stepKey, substepKey: substep.substepKey,
            progress: currentStep.substeps.filter(sub => !sub.hidden).indexOf(sub => sub.substepKey === substep.substepKey)
          };
        }
      }
    }
    let prevStepKey = this.getPreviousStep(currentStep && currentStep.stepKey);
    if (prevStepKey) {
      const prevStep = this.getCurrentStep(prevStepKey);
      let progress = 0;
      if (prevStep && prevStep.substeps && prevStep.substeps.length) {
        const lastIndex = prevStep.substeps.map(sub => !sub.hidden).lastIndexOf(true);
        if (lastIndex >= 0) {
          progress = lastIndex;
          prevStepKey = prevStep.substeps[progress].substepKey;
        }
      }
      return {
        stepKey: prevStep.stepKey, substepKey: prevStepKey,
        progress: progress
      };
    }
  }

  updateStepList() {
    this.changeSteplist(this.getCurrentStepList());
  }

  markStepAsSuccess(stepKey) {
    const currentStepTemp = this.getCurrentStep(stepKey);
    if (currentStepTemp) {
      currentStepTemp.success = true;
      currentStepTemp.warning = false;
      this.updateStepList();
    }
  }

  markStepAsWarning(stepKey) {
    const currentStepTemp = this.getCurrentStep(stepKey);
    if (currentStepTemp) {
      currentStepTemp.success = false;
      currentStepTemp.warning = true;
      this.updateStepList();
    }
  }

  toggleLoadingState(stepKey, loading) {
    const currentStepTemp = this.getCurrentStep(stepKey);
    currentStepTemp.loading = loading;
    // feed the loading source with true/false. If some step has loading=true, then return true.
    this.isLoadingSource = this.getCurrentStepList().findIndex(step => step.loading) > -1;
    this.updateStepList();
  }

  changeAllLoadingToFalse() {
    this.getCurrentStepList().forEach(step => {
      step.loading = false;
    });
    this.isLoadingSource = false;
    this.updateStepList();
  }

  isLoading() {
    return this.isLoadingSource;
  }

  removeStepByKey(stepKey) {
    this.changeSteplist(this.getCurrentStepList().filter(step => step.stepKey !== stepKey));
  }

  removeStepByIndex(index) {
    if (index || index === 0) {
      const currentList = this.getCurrentStepList();
      currentList.splice(index, 1);
      this.changeSteplist(currentList);
    }
  }

  addStep(step, position) {
    if (step) {
      if (!step.totalProgress) {
        step.totalProgress = 1;
      }
      const currentList = this.getCurrentStepList();
      if ((position || position === 0) && position < currentList.length - 1) {
        currentList.splice(position, 0, step);
      } else {
        currentList.push(step);
      }
      this.changeSteplist(currentList);
    }
  }

  addSubStep(stepKey, subStep) {
    const currentList = this.getCurrentStepList();
    const filteredList = currentList.filter(step => step.stepKey === stepKey);
    if (filteredList && filteredList.length) {
      if (!filteredList[0].substeps) {
        filteredList[0].substeps = [];
      }
      /* does not add a subStep with existing substepKey */
      if (subStep.substepKey && filteredList[0].substeps.some(substep => substep.substepKey === subStep.substepKey)) {
        return;
      }
      filteredList[0].substeps.push(subStep);
      if (filteredList[0].totalProgress < filteredList[0].substeps.filter(substep => !substep.hidden).length) {
        filteredList[0].totalProgress = filteredList[0].substeps.filter(substep => !substep.hidden).length;
      }
    }
    this.updateStepList();
  }

  containsSubstep(stepKey, substepKey) {
    const currentList = this.getCurrentStepList();
    const filteredList = currentList.filter(step => step.stepKey === stepKey);
    if (!filteredList[0].substeps) {
      return false;
    } else {
      return filteredList[0].substeps.some(substep => substep.substepKey === substepKey);
    }
  }

  removeSubsteps(stepKey, startIndex) {
    const step = this.getCurrentStep(stepKey);
    if (step.substeps && step.substeps.length) {
      step.substeps.splice(startIndex);
      step.totalProgress = step.substeps.length;
    }
  }

  markSubStepAsWarning(stepKey, currentProgress) {
    const currentStepTemp = this.getCurrentStep(stepKey);
    if (currentStepTemp.substeps && currentStepTemp.substeps[currentProgress]) {
      currentStepTemp.substeps[currentProgress].success = false;
      currentStepTemp.substeps[currentProgress].warning = true;
      this.updateStepList();
    }
  }

  markSubStepAsSuccess(stepKey, currentProgress) {
    const currentStepTemp = this.getCurrentStep(stepKey);
    if (currentStepTemp.substeps && currentStepTemp.substeps[currentProgress]) {
      currentStepTemp.substeps[currentProgress].success = true;
      currentStepTemp.substeps[currentProgress].warning = false;
      this.updateStepList();
    }
  }

  progress(stepKey, currentProgress, suppressUpdate = false) {
    const previousStep = this.currentStep;
    this.currentStep = this.getCurrentStep(stepKey);
    this.updateStepReferences();
    if (!suppressUpdate) {
      this.updateStepStatus();
    }
    if (typeof currentProgress === 'string') {
      currentProgress = this.getCurrentSubStepIndex(stepKey, currentProgress);
    }

    if (!this.currentStep.totalProgress || !currentProgress || currentProgress < 0 || this.currentStep.totalProgress < 0) {
      this.currentStep.percentage = 0;
      this.currentStep.currentProgress = 0;
    } else if (currentProgress > this.currentStep.totalProgress) {
      this.currentStep.percentage = 100;
      this.currentStep.currentProgress = this.currentStep.totalProgress;
    } else {
      this.currentStep.percentage = (currentProgress / this.currentStep.totalProgress) * 100;
      this.currentStep.currentProgress = currentProgress;
    }

    this.updateStepList();
    const currentList = this.getCurrentStepList();
    const previousStepList = currentList.slice(0, currentList.indexOf(previousStep));
    this.actioncAfterOnChangeStepFunc && this.actioncAfterOnChangeStepFunc(previousStep, this.currentStep, previousStepList);
  }

  setTotalProgress(stepKey, totalProgress) {
    this.currentStep = this.getCurrentStep(stepKey);
    if (totalProgress > 0) {
      this.currentStep.totalProgress = totalProgress;
      this.updateStepList();
    }
  }

  setLink(stepKey, path) {
    this.currentStep = this.getCurrentStep(stepKey);
    this.currentStep.link += path;
  }

  registerActionFuncStep(stepKey, func) {
    this.currentStep = this.getCurrentStep(stepKey);
    if (func && this.currentStep) {
      this.currentStep.actionFunc = func;
    }
  }

  registerActionFuncSubStep(stepKey, subStepIndex, func) {
    this.currentStep = this.getCurrentStep(stepKey);
    if (func && this.currentStep && this.currentStep.substeps && this.currentStep.substeps[subStepIndex]) {
      this.currentStep.substeps[subStepIndex].actionFunc = func;
    }
  }

  registerActioncAfterOnChangeStepFunc(func) {
    if (func) {
      this.actioncAfterOnChangeStepFunc = func;
    }
  }

  callActionFunction(stepKey) {
    this.currentStep = this.getCurrentStep(stepKey);
    this.currentStep && this.currentStep.actionFunc && this.currentStep.actionFunc();
  }

  toggleCompleteDone(stepKey) {
    const currentStep = this.getCurrentStep(stepKey);

    if (!currentStep.success && !currentStep.warning) {
      currentStep.success = true;
    } else {
      currentStep.success = !currentStep.success;
      currentStep.warning = !currentStep.warning;
    }

    this.updateStepList();
  }

  // dummy method. just for test
  increaseProgress(stepKey) {
    const currentStep = this.getCurrentStep(stepKey);

    if (currentStep) {
      if (currentStep.percentage < 100) {
        currentStep.percentage += 10;
      } else {
        currentStep.percentage = 0;
      }
    }

    this.updateStepList();
  }

  updateWarningsAnlegerprofil(warnings = [], stepCurrentValue = 0, checkAllSteps = false) {
    let stepList = this.getCurrentStepList().filter(step => step.stepKey !== 'summary');
    if (!checkAllSteps) {
         /* filter only previous and current steps */
      stepList = stepList.filter(step => !step.after);
    }
    stepList.forEach(step => {
      const warningsForStep = warnings.filter(warning => warning && step.warningTitle === warning.title);
      stepCurrentValue = stepCurrentValue === 0 ? 1 : stepCurrentValue;
      const substeps = step.current && step.substeps && step.substeps.length ? step.substeps.slice(0, stepCurrentValue) : step.substeps;
      if (substeps) {
        substeps.forEach(subStep => {
          if (warningsForStep.some(warning =>
            warning.msgs.some(msg => subStep.warningSubTitle && (!subStep.warningSubTitle.length || subStep.warningSubTitle.length
              && subStep.warningSubTitle.some(subTitle => msg.includes(subTitle)))))) {
            subStep.success = false;
            subStep.warning = true;
          } else {
            subStep.success = true;
            subStep.warning = false;
          }
        });
      }
    });
    this.updateStepStatus(checkAllSteps, false);
    this.updateStepList();
  }

  /* warnings: array of { stepKey, substepKey } */
  // warnings, substepCurrentValue = 0, checkAllSteps = false
  updateWarnings(data) {
    if (data) {
      let stepList = this.getCurrentStepList();
      if (!data.checkAllSteps) {
        /* filter only previous and current steps */
        stepList = stepList.filter(step => !step.after);
      }
      stepList.forEach(step => {
        if (step.stepKey === 'aktionen') {
          step.success = !data.warnings || !data.warnings.length;
          step.warning = data.warnings && data.warnings.length > 0;
        } else {
          const stepWarnings = data.warnings.filter(warn => warn.stepKey === step.stepKey
            && (warn.stepKey === 'auswahl' ? true : warn.personType && data.personType && !step.ignorePersonType ? warn.personType === data.personType : true));
          // cuts substeps of the current step to the current one; other substeps are checked entirely
          // const substeps = step.current && step.substeps && step.substeps.length 
          //   ? step.substeps.slice(0, data.substepCurrentValue || 0) : step.substeps;
          if (step.substeps?.length) {
            step.substeps.filter(substep => substep && !substep.hidden).forEach(substep => {
              const substepKey = substep.substepKey.includes('/') 
                ? substep.substepKey.substring(substep.substepKey.lastIndexOf('/') + 1) 
                : substep.substepKey;
              if (stepWarnings.some(warn => warn.substepKey.includes(substepKey))) {
                substep.success = false;
                substep.warning = true;
              } else {
                substep.success = true;
                substep.warning = false;
              }
            });
          }
        }
      });
      this.updateStepStatus(data.checkAllSteps);
      this.updateStepList();
    }
  }

  hasWarnings() {
    const currentList = this.getCurrentStepList();
    for (let index = 0; index < currentList.length; index++) {
      const step = currentList[index];
      if (step.warning) {
        return step.warning;
      }

      if (step.substeps) {
        for (let j = 0; j < step.substeps.length; j++) {
          const subStep = step.substeps[j];
          if (subStep.warning) {
            return subStep.warning;
          }
        }
      }
    }
  }

  setWarningTitle(stepKey, warningTitle) {
    this.getCurrentStep(stepKey).warningTitle = warningTitle;
  }

  setStepHidden(stepKey, hidden = true) {
    const currentStepTemp = this.getCurrentStep(stepKey);
    if (currentStepTemp) {
      currentStepTemp.hidden = hidden;
      this.updateStepList();
    }
  }

  setSubstepHidden(stepKey, substepKey, hidden = true) {
    const currentStepTemp = this.getCurrentStep(stepKey);
    if (currentStepTemp && currentStepTemp.substeps && currentStepTemp.substeps.length) {
      const substep = currentStepTemp.substeps.find(sub => sub && sub.substepKey === substepKey);
      if (substep) {
        substep.hidden = hidden;
        this.setTotalProgress(stepKey, currentStepTemp.substeps.filter(substep => !substep.hidden).length || 1);
        this.updateStepList();
      }
    }
  }

  getHighestStepVisitedIndex() {
    const currentList = this.getCurrentStepList()?.filter(step => !step.hidden);
    const visited = currentList.filter(step => step.alreadyVisitedStep);
    return visited?.length ? currentList.indexOf(visited.pop()) : 0;
  }

}
