var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasTabs
    ? _c(
        "div",
        { staticClass: "tabs__container" },
        [
          _vm._l(_vm.tabList, function(tab, index) {
            return [
              tab.visible !== false
                ? [
                    _c(
                      "a",
                      {
                        key: "tab-item-" + index,
                        class: {
                          "font-bold": tab.key === _vm.selected,
                          disabled: tab.disabled || _vm.disabled
                        },
                        attrs: { disabled: tab.disabled },
                        on: {
                          click: function($event) {
                            return _vm.selectTab(tab)
                          }
                        }
                      },
                      [_vm._v(_vm._s(tab.label || ""))]
                    ),
                    index < _vm.tabList.length - 1
                      ? _c(
                          "span",
                          {
                            key: "tab-divider-" + index,
                            staticClass: "tab-divider"
                          },
                          [_vm._v("|")]
                        )
                      : _vm._e()
                  ]
                : _vm._e()
            ]
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }