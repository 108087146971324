var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      {
        class: {
          myheader_logged: _vm.isBroker || _vm.isIntern,
          myheader_unlogged: !_vm.isBroker && !_vm.isIntern
        }
      },
      [
        _c("data-header", {
          staticClass: "head_foot_painter",
          attrs: { id: "dataheader" }
        }),
        _c(
          "div",
          { staticClass: "navi-painter" },
          [
            _c("navi-header", {
              attrs: {
                logoURLProp: _vm.maklerLogo,
                availableRoutes: _vm.availableRoutes,
                id: "naviheader"
              }
            })
          ],
          1
        )
      ],
      1
    ),
    !_vm.getLoaded ? _c("div", { staticClass: "cms_ghost-filler" }) : _vm._e(),
    _c(
      "div",
      {
        staticClass: "router_view_class",
        class: {
          router_view_margin_logged: _vm.isBroker || _vm.isIntern,
          router_view_margin_unlogged: !_vm.isBroker && !_vm.isIntern
        }
      },
      [_vm._t("default"), _c("router-view")],
      2
    ),
    _c(
      "div",
      { staticClass: "head_foot_painter" },
      [
        _vm.loaded
          ? _c("footer-component", {
              attrs: { maklerinfo: _vm.maklerInfo, id: "footer_component" }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }