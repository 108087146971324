<template>
  <div class="vertical-step__icon-container">
    <template v-if="isCurrent && collapsed">
      <ph-circle 
        class="color-primary vertical-stop__icon" 
        weight="fill"
        :size="16" />
    </template>
    <template v-else>
      <ph-circle 
        class="color-primary vertical-stop__icon" 
        v-if="stepperStatus === 'default'" 
        :size="16" />
      <ph-check-circle 
        class="color-success vertical-stop__icon" 
        v-if="stepperStatus === 'success'"
        :size="16" />
      <ph-warning-circle 
        class="color-danger vertical-stop__icon" 
        v-if="stepperStatus === 'warning'"
        :size="16" />
    </template>
  </div>
</template>

<script>
import { PhCircle, PhCheckCircle, PhWarningCircle } from 'phosphor-vue'

export default {
  props: {
    stepperStatus: {
    },
    isCurrent: {
      type: Boolean,
      default: false,
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PhCircle, 
    PhCheckCircle, 
    PhWarningCircle, 
  }
}
</script>


<style src='./vertical-stepper.scss' lang='scss' scoped></style>