import { mapGetters, } from 'vuex';
import RISIKOKLASSEN_DEFINITION_TYPES from '@/store/risikoklassenDefinition/types';

export default {
  computed: {
    ...mapGetters({
      risikoklassenDefinition: RISIKOKLASSEN_DEFINITION_TYPES.GETTERS.RISIKOKLASSEN_DEFINITION,
      risikoklassenDefinitionEdited: RISIKOKLASSEN_DEFINITION_TYPES.GETTERS.RISIKOKLASSEN_DEFINITION_EDITED,
      hasConfig: RISIKOKLASSEN_DEFINITION_TYPES.GETTERS.HAS_CONFIG,
      isChangeAllowed: RISIKOKLASSEN_DEFINITION_TYPES.GETTERS.IS_CHANGE_ALLOWED,
    }),
    risikoklassenDefinitionData() {
      return this.risikoklassenDefinitionEdited || this.risikoklassenDefinition;
    },
  },
  methods: {
    dataChanged() {
      this.setDataChanged(this.form);
    },
    setDataChanged(form) {
      this.$store.commit(RISIKOKLASSEN_DEFINITION_TYPES.MUTATIONS.SET_RISIKOKLASSEN_DEFINITION_EDITED, {
        ...this.risikoklassenDefinition,
        ...this.risikoklassenDefinitionEdited || {},
        ...form,
        einstellung: {
          ...this.risikoklassenDefinition?.einstellung ||{},
          ...this.risikoklassenDefinitionEdited?.einstellung || {},
          ...form?.einstellung || {},
        },
      });
    },
  },
}
