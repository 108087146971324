<template>
    <div >
      <DBMT query="dbm_free_space"/>
  
    </div>
  </template>
  
  <script>
  
  import DBMT from "@/views/dbm/DbmT.vue";
  
  
  
  export default {
  
    components: {
    
      
      DBMT
    },
   
   
    
  
  }
  
  </script>
  
  