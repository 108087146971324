var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.searchingPositions
    ? _c("div", [
        _vm._m(0),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [_c("FondsFinderSuchMaske", { attrs: { ignoreLagerstelle: "" } })],
            1
          )
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c("FondsFinderPositionsList", {
                attrs: {
                  zurueckButtonText: "Abbrechen",
                  addPositionsOnBack: false,
                  ignoreLagerstelle: ""
                },
                on: {
                  goBack: function($event) {
                    return _vm.addFonds($event)
                  }
                }
              })
            ],
            1
          )
        ])
      ])
    : _c("div", { staticClass: "antrag-components__container" }, [
        _c(
          "div",
          { staticClass: "box__container" },
          [
            !_vm.positionData.all
              ? _c("Table", {
                  attrs: {
                    tableId: "f168f001-d40a-4a9a-ba2b-aa8d3ce74e30",
                    headers: _vm.headers,
                    rows: _vm.rows,
                    rowId: "index",
                    hidePagination: ""
                  },
                  on: {
                    "action-DELETE": function($event) {
                      return _vm.deleteFond($event.index - 1)
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "Anteile",
                        fn: function(row) {
                          return [
                            _c("InputField", {
                              model: {
                                value: _vm.positionData["anteile" + row.index],
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.positionData,
                                    "anteile" + row.index,
                                    $$v
                                  )
                                },
                                expression:
                                  "positionData['anteile' + row.index]"
                              }
                            })
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3472760306
                  )
                })
              : _vm._e(),
            !_vm.positionData.all
              ? _c(
                  "BaseButton",
                  {
                    on: {
                      click: function($event) {
                        _vm.searchingPositions = true
                      }
                    }
                  },
                  [_vm._v("Positionen hinzufügen")]
                )
              : _vm._e(),
            _c("FormDivider"),
            _c("InputToggleSwitch", {
              attrs: {
                label:
                  "Darüber hinaus erteile ich/erteilen wir den Auftrag, alle bestehenden Sparpläne einzustellen und das Depot bei der o.g. Investmentgesellschaft/Bank zu schließen.\\n(Eine Depotschließung kann bei einem Gemeinschaftsdepot nur durch alle Depotinhaber gemeinsam veranlasst werden.)"
              },
              model: {
                value: _vm.positionData.depotAufloesen,
                callback: function($$v) {
                  _vm.$set(_vm.positionData, "depotAufloesen", $$v)
                },
                expression: "positionData.depotAufloesen"
              }
            }),
            _c("InputToggleSwitch", {
              attrs: {
                label:
                  "Hiermit widerrufe ich meinen/widerrufen wir unseren Freistellungsauftrag."
              },
              model: {
                value: _vm.positionData.freiwiderruf,
                callback: function($$v) {
                  _vm.$set(_vm.positionData, "freiwiderruf", $$v)
                },
                expression: "positionData.freiwiderruf"
              }
            }),
            _c("InputToggleSwitch", {
              attrs: {
                label:
                  "Ich möchte meinen/Wir möchten unseren Freistellungsauftrag ändern. Bitte überlassen Sie/uns einen entsprechenden Vordruck."
              },
              model: {
                value: _vm.positionData.freiaendern,
                callback: function($$v) {
                  _vm.$set(_vm.positionData, "freiaendern", $$v)
                },
                expression: "positionData.freiaendern"
              }
            }),
            _c("InputToggleSwitch", {
              attrs: {
                label:
                  "Ich erteile/Wir erteilen der abgebenden Bank/Fondsgesellschaft den Auftrag, ein eventuell zugehöriges Konto zu löschen und ein bestehendes Guthaben auf u. g. Bankverbindung zu überweisen."
              },
              model: {
                value: _vm.positionData.kontoAufloesen,
                callback: function($$v) {
                  _vm.$set(_vm.positionData, "kontoAufloesen", $$v)
                },
                expression: "positionData.kontoAufloesen"
              }
            }),
            _c("EntitySelector", {
              attrs: {
                values: _vm.bankverbindungSelection,
                label: "Verwende Konto"
              },
              on: {
                new: _vm.editBankverbindung,
                edit: _vm.editBankverbindung,
                change: function($event) {
                  return _vm.$emit("save", { bankverbindungId: $event })
                }
              },
              model: {
                value: _vm.positionData.bankverbindungId,
                callback: function($$v) {
                  _vm.$set(_vm.positionData, "bankverbindungId", $$v)
                },
                expression: "positionData.bankverbindungId"
              }
            }),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c(
                    "BaseButton",
                    {
                      attrs: { isSecondary: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("back")
                        }
                      }
                    },
                    [_vm._v("Zurück")]
                  ),
                  _c("BaseButton", { on: { click: _vm.onBackButton } }, [
                    _vm._v("Zurück zum Formular")
                  ])
                ],
                1
              )
            ])
          ],
          1
        )
      ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("h2", [_vm._v("Wertpapiersuche")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }