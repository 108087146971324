import CREDIT_TYPES from './types';

export default {
  [CREDIT_TYPES.GETTERS.CREDITS](state) {
    return state.credits;
  },

  [CREDIT_TYPES.GETTERS.SELECTED_CREDIT_ID](state) {
    return state.selectedCreditId;
  },

  [CREDIT_TYPES.GETTERS.IS_NEW](state) {
    return state.selectedCreditId === 'neu';
  },

  [CREDIT_TYPES.GETTERS.COMBOS](state) {
    return { ...state.combos };
  },

  [CREDIT_TYPES.GETTERS.CREDIT](state) {
    return { ...state.credit };
  },

  [CREDIT_TYPES.GETTERS.CREDIT_EDITED](state) {
    return { ...state.creditEdited };
  },

  [CREDIT_TYPES.GETTERS.RIGHTS](state) {
    return { ...state.rights };
  },

  [CREDIT_TYPES.GETTERS.UPDATES](state) {
    return [...state.updates];
  },

  [CREDIT_TYPES.GETTERS.STATUS](state) {
    return [...state.status];
  },

  [CREDIT_TYPES.GETTERS.APPOINTMENTS](state) {
    return [...state.appointments];
  },

  [CREDIT_TYPES.GETTERS.APPOINTMENT_COMBOS](state) {
    const appointmentCombos = state.appointments?.[0] || {};
    return {
      ...appointmentCombos?.appointmentCombos,
    };
  },

  [CREDIT_TYPES.GETTERS.PROHYP_COMBOS](state) {
    return { ...state.prohypCombos };
  },

  [CREDIT_TYPES.GETTERS.PROHYP](state) {
    return { ...state.prohyp };
  },

  [CREDIT_TYPES.GETTERS.PROHYP_EDITED](state) {
    return { ...state.prohypEdited };
  },

  [CREDIT_TYPES.GETTERS.BUCHUNGEN](state) {
    return [...state.buchungen];
  },

  [CREDIT_TYPES.GETTERS.SELECTED_BUCHUNG](state) {
    return { ...state.selectedBuchung };
  },

  [CREDIT_TYPES.GETTERS.BUCHUNG_PROV_STRUKTUREN](state) {
    const selectedBuchung = state.selectedBuchung;
    if(selectedBuchung?.id) {
      return [...state.buchungProvStrukturen[selectedBuchung.id]];
    }
  },

  [CREDIT_TYPES.GETTERS.HINTS](state) {
    return [...state.hints];
  },

  [CREDIT_TYPES.GETTERS.DOCUMENTS](state) { // TODO remove after migrate Kredit Dokumente to Dokumentenarchiv
    return [...state.documents];
  },

}