var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "markup_editor_container" },
    [
      _c("div", [_vm._v("Die empfohlene Breite des Bildes ist 1080px.")]),
      _c("br"),
      _c("br"),
      _c("BaseButton", { on: { click: _vm.openDialog } }, [
        _vm._v("Bild auswählen")
      ]),
      _c("br"),
      _c("br"),
      _c("div", [_vm._v(_vm._s(_vm.fileName))]),
      _c("br"),
      _c("br"),
      _c("div", { staticClass: "editor" }, [
        _vm.noError
          ? _c("div", { attrs: { id: "innerspan" } }, [
              _c("img", {
                attrs: { src: _vm.getContent },
                on: { error: _vm.hasError }
              })
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }