var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "profile-menu-container" },
    [
      _c(
        "div",
        {
          staticClass: "profile-menu-content",
          on: { click: _vm.openProfileModal }
        },
        [
          _c("LoggedUserInformation", { staticClass: "mr-2" }),
          _c("UserPicture")
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "profileModal",
          attrs: { showDefaultButtons: false, actions: _vm.baseModalActions },
          on: {
            "action-ABMELDEN": _vm.logout,
            "action-KUNDENZUGANG": _vm.openKundenzugang,
            "action-MAKLERZUGANG": _vm.openMaklerzugang,
            close: _vm.onClose
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "dashboard-panel-config-modal--title-container clearfix"
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "dashboard-panel-config-modal--title" },
                        [_vm._v(_vm._s(_vm.loginUsername))]
                      )
                    ]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "div",
            [
              _c(
                "BoxContainer",
                [
                  _c("GlobalSearch", {
                    attrs: { size: 24, label: "Globale Suche" },
                    on: { close: _vm.closeProfileModal }
                  })
                ],
                1
              ),
              _vm.isCustomer
                ? _c(
                    "BoxContainer",
                    { attrs: { title: "Mein Betreuer" } },
                    [
                      _c("AgentContact", {
                        on: { click: _vm.closeProfileModal }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "BoxContainer",
                [
                  _c("NavigationList", {
                    attrs: { routes: _vm.navigationRoutes }
                  })
                ],
                1
              ),
              _vm.showActionButtons
                ? _c(
                    "BoxContainer",
                    [
                      _vm.showToggleProtectYourAccount
                        ? _c("InputToggleSwitch", {
                            attrs: {
                              disabled: _vm.isToggleProtectYourAccountDisabled,
                              label: _vm.protectYourAccountLabel,
                              value:
                                _vm.biometricLoginSettings.isPermissionGranted
                            },
                            on: {
                              input: function($event) {
                                return _vm.toggleBiometricSettingsIsPermissionGranted()
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-center align-items-center text-center"
                },
                [_vm._v(_vm._s(_vm.currentVersion))]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }