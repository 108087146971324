import V_P from './types';

export default {

  [V_P.GETTERS.SETUP](state) {

    return state.SETUP;
  },


  [V_P.GETTERS.FILTER](state) {
    return state.FILTER;
  },
  [V_P.GETTERS.ROW_COUNT](state) {
    return state.rowCount;
  },
  [V_P.GETTERS.COMMUNICATIONS](state) {
    return state.COMMUNICATIONS;
  },
  [V_P.GETTERS.VP_BEMERKUNG](state) {
    return state.VP_BEMERKUNG;
  },
  [V_P.GETTERS.TRACE](state) {
    return state.TRACE
  },
  [V_P.GETTERS.QUELLE](state) {
    return state.QUELLE
  },
  
  [V_P.GETTERS.LABEL](state) {
    return state.LABEL
  },
  [V_P.GETTERS.RELOAD](state) {
    return state.RELOAD
  },
  [V_P.GETTERS.EINGANG](state) {
    return state.EINGANG
  },
  [V_P.GETTERS.BARCODES_COMBO](state) {
       return state.BARCODES_COMBO
  },
  [V_P.GETTERS.EDIT_GESEL_PAGE](state) {
    
     return state.EDIT_GESEL_PAGE;
  },

  [V_P.GETTERS.ERROR_EDIT](state) {
    
    return state.ERROR_EDIT;
  },
  [V_P.GETTERS.EDIT_GESEL_PAGE_CONFIG](state) {
    
    return state.EDIT_GESEL_PAGE_CONFIG;
  },
  [V_P.GETTERS.GESELL_KOMM_COMBO](state) {
    
    return state.GESELL_KOMM_COMBO;
  },
 
  [V_P.GETTERS.EDIT_KUNDEN_PAGE_CONFIG](state) {
    
    return state.EDIT_KUNDEN_PAGE_CONFIG;
  },
  [V_P.GETTERS.PERSONEN_COMBO](state) {
    
    return state.PERSONEN_COMBO;
  },
  [V_P.GETTERS.PAGES_ACTIVE](state) {
    
    return state.PAGES_ACTIVE;
  },
  [V_P.GETTERS.MAPPE_COMBO](state) {
    
    return state.MAPPE_COMBO;
  },
  [V_P.GETTERS.MAPPE_TABLE](state) {
    
    return state.MAPPE_TABLE;
  },
  [V_P.GETTERS.GET_FILTER_PARAMS](state) {
    return state.lastFilterParams;
  },
}
