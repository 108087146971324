var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "modal",
    attrs: {
      modalTitle: "andere Personen",
      confirmDisabled: !_vm.selected.length
    },
    on: {
      onConfirmButton: function($event) {
        return _vm.select()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "modalTitle",
        fn: function() {
          return [_vm._v("andere Personen")]
        },
        proxy: true
      },
      {
        key: "modalHeaderBottom",
        fn: function() {
          return [
            _c("InputField", {
              attrs: { placeholder: "Suche", autofocus: "" },
              model: {
                value: _vm.filterText,
                callback: function($$v) {
                  _vm.filterText = $$v
                },
                expression: "filterText"
              }
            })
          ]
        },
        proxy: true
      },
      {
        key: "default",
        fn: function() {
          return [
            _vm.loading
              ? _c("GhostLoading", { attrs: { type: "table" } })
              : !_vm.rows.length
              ? _c("NoData", { attrs: { noIcon: "" } })
              : _c("Table", {
                  attrs: {
                    headers: _vm.headers,
                    rows: _vm.rows,
                    hidePagination: ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "select",
                      fn: function(row) {
                        return [
                          _c("InputToggleSwitch", {
                            attrs: { disabled: _vm.isAlreadySelected(row.id) },
                            model: {
                              value: _vm.selectedPersonen[row.id],
                              callback: function($$v) {
                                _vm.$set(_vm.selectedPersonen, row.id, $$v)
                              },
                              expression: "selectedPersonen[row.id]"
                            }
                          })
                        ]
                      }
                    }
                  ])
                })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }