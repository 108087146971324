var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "PageHeaderTitleNavigation",
        _vm._g(
          {
            attrs: { defaultMenu: _vm.customOptionsMenu },
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function() {
                  return [_vm._v(_vm._s(_vm.gesellschaftName))]
                },
                proxy: true
              },
              {
                key: "subtitle",
                fn: function() {
                  return [
                    _vm.bezeichnung
                      ? [
                          _c("span", [_vm._v(_vm._s(_vm.bezeichnung))]),
                          _c("span", [_vm._v(" | ")])
                        ]
                      : _vm._e(),
                    [_c("span", [_vm._v("Schadensmeldung")])]
                  ]
                },
                proxy: true
              }
            ])
          },
          _vm.$listeners
        )
      ),
      _c(
        "div",
        { staticClass: "box__container" },
        [_c("ZahlungenGeschaedigte", { attrs: { schadenId: _vm.schadenId } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }