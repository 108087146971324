export const MUTATION_PREFIX = 'TAGS: ';
export const ACTIONS_PREFIX = 'TAGS_';
export const GETTERS_PREFIX = 'TAGS_';

export default {
  MUTATIONS: {
    GET_DOMAINS_FC_SUCCESS: GETTERS_PREFIX + 'GET_DOMAINS_FC_SUCCESS',

    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
  },
  ACTIONS: {
    GET_DOMAINS_FC: ACTIONS_PREFIX + 'GET_DOMAINS_FC',
    DELETE_DOMAIN_FC: ACTIONS_PREFIX + 'DELETE_DOMAIN_FC',
    SAVE_DOMAIN_FC: ACTIONS_PREFIX + 'SAVE_DOMAIN_FC',
  },
  GETTERS: {
    DOMAINS_FC: GETTERS_PREFIX + 'DOMAINS_FC',
  }
}