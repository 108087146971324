const breakpoints = {
  xl: 1200,
  lg: 992,
  md: 768,
  sm: 576,
  xs: 0,
}

function throttle(callback, limit) {
  var wait = false;
  return function ($event) {
    if (!wait) {
      wait = true;
      setTimeout(function () {
        const currentSize = getCurrentSize();
        callback.call(this, $event, currentSize);
        wait = false;
      }, limit);
    }
  }
}

function callbackAtSize(callback, breakpointSize) {
  return function($event) {
    let currentKey = getCurrentSize ();
    
    if (currentKey === breakpointSize) {
      callback.call(this, $event)
    }
  }
}

function getCurrentSize () {
  for (let [key, size] of Object.entries(breakpoints)) {
    if (window.innerWidth > size) {
      return key;
    }
  }
  return '';
}

const mixin = {
  data: function () {
    return {
      screenSizeData: {
        listenerFunctions: [],
        throttleLimit: 100, // milliseconds
        initialSize: ''
      }
    }
  },
  methods: {
    $getCurrentSize() {
        return getCurrentSize();
    },
    $addResizeListener(callback) {
      if (window.addEventListener) {
        const throttleCallback = throttle(callback, this.screenSizeData.throttleLimit);
        window.addEventListener('resize', throttleCallback, true);
        this.screenSizeData.listenerFunctions.push(throttleCallback)
        return getCurrentSize();
      }
      return null;
    },
    $addResizeXSListener(callback) {
      this.$addResizeListener(callbackAtSize(callback, 'xs'))
    },
    $addResizeSMListener(callback) {
      this.$addResizeListener(callbackAtSize(callback, 'sm'))
    },
    $addResizeMDListener(callback) {
      this.$addResizeListener(callbackAtSize(callback, 'md'))
    },
    $addResizeLGListener(callback) {
      this.$addResizeListener(callbackAtSize(callback, 'lg'))
    },
    $addResizeXLListener(callback) {
      this.$addResizeListener(callbackAtSize(callback, 'xl'))
    },
    getBreakpoints() {
      return breakpoints;
    },
  },
  created() {
    this.screenSizeData.initialSize = getCurrentSize();
  },
  beforeDestroy() {
    this.screenSizeData.listenerFunctions.forEach(callback => {
      window.removeEventListener('resize', callback, true)
    })

    this.screenSizeData.listenerFunctions = []
  }
}

/**
 * Provides resize listener functions to be used whenever the window size changes.
 * 
 * @see $addResizeListener - executed on every size window
 * @see $addResizeXSListener - executed on extra small size window
 * @see $addResizeSMListener - executed on small size window
 * @see $addResizeMDListener - executed on medium size window
 * @see $addResizeLGListener - executed on large size window
 * @see $addResizeXLListener - executed on extra large window
 * 
 */
export default mixin;