<template>
  <div>
    <InputToggleSwitch 
      label="Vorschau" 
      :disabled="disabled" 
      :value="value"
      @input="$emit('input', $event)"
      inLineLabel />
  </div>
</template>

<script>
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';

export default {
  components: {
    InputToggleSwitch,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  }
}

</script>