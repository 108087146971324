<template>
  <div class="logged-user__container">
    <div class="d-flex">
      <template v-if="isBypSession">
        <div v-if="isLoggedUserAvailable" 
          class="logged-user--extra-info mx-0"
          :class="{ 'clickable': isLoggedUserClickable }"
          @click="openLoggedUserInNewTab"
        >
          <span>{{loginUserid}}</span>
          <span class="mx-2">></span>
        </div>
        <div v-if="hasMaklerInfo"
          class="logged-user--extra-info mx-0"
          :class="{ 'clickable': isBrokerClickable }"
          @click="openBrokerInNewTab"
        >
          <span class="logged-user--name">{{maklerName}}</span>
          <span v-if="maklerName && maklernr" class="logged-user--divider mx-2">|</span>
          <span>{{maklernr}}</span>
          <span v-if="maklerName || maklernr" class="mx-2"> > </span>
        </div>
      </template>
      <div class="logged-user--extra-info" v-else-if="ssoLoginName">
        <span>{{ssoLoginName}}</span>
        <span class="mx-2">></span>
      </div>
      <div class="logged-user--main-info" :class="{'clickable': isCustomerAsBypassClickable}" @click="openCustomerInNewTab">
        <span class="logged-user--name">{{fullname}}</span>
        <template v-if="loginData.depotInhaber">
          <span class="logged-user--name" v-for="di in loginData.depotInhaber" :key="di">&nbsp;&amp; {{di}}</span>
        </template>
        <span class="logged-user--divider mx-2">|</span>
        <span>{{userIdUnterNr}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types'
import SSO_TYPES from '@/store/sso/types'
import { mapGetters } from 'vuex'

import { VIEW_ROLES } from '@/router/roles';

export default {
  computed: {
    ...mapGetters({
      userid: CORE_TYPES.GETTERS.GET_USER_ID,
      fullname: CORE_TYPES.GETTERS.GET_USER_FULL_NAME,
      unternr: CORE_TYPES.GETTERS.GET_USER_UNTER_NR,
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
      ssoLoginName: SSO_TYPES.GETTERS.LOGIN_NAME,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
    userIdUnterNr() {
      return this.unternr ? `${this.userid}-${this.unternr}` : this.userid
    },
    loginUsername() {
      return this.loginData?.rights?.isIntern ? null : this.loginData?.loginUsername || this.loginData?.fullname
    },
    loginUserid() {
      return this.loginData?.loginUserid
    },
    maklerName() {
      return this.loginData?.maklerName
    },
    maklernr() {
      return this.loginData?.maklernr
    },
    hasMaklerInfo() {
      return this.maklerName || this.maklernr;
    },
    isBypSession() {
      return this.loginData?.rights?.isBypSession
    },
    isViewCustomerAsBypass() {
      return this.hasRoles([VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS]);
    },
    isCustomerAsBypassClickable() {
      return this.isViewCustomerAsBypass && !this.$isMobileNativeContext;
    },
    isBrokerClickable() {
      return !this.$isMobileNativeContext;
    },
    isLoggedUserAvailable() {
      return this.maklernr != this.loginUserid;
    },
    isLoggedUserClickable() {
      return this.isLoggedUserAvailable && !this.$isMobileNativeContext;
    },
  },
  methods: {
    openCustomerInNewTab(event) {
      if (this.isCustomerAsBypassClickable) {
        event.preventDefault();
        event.stopPropagation();

        this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { customerId: this.userIdUnterNr });
      }
    },
    openLoggedUserInNewTab(event) {
      if (this.isLoggedUserClickable) {
        event.preventDefault();
        event.stopPropagation();

        if (this.loginData?.rights?.isIntern) {
          this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CURRENT_LOGGED_INTERN);
        } else {
          this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CURRENT_LOGGED_BROKER);
        }
      }
    },
    openBrokerInNewTab(event) {
      if (this.isBrokerClickable) {
        event.preventDefault();
        event.stopPropagation();

        this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_BROKER_NEW_TAB, { brokerId: this.maklernr });
      }
    },
  }
}
</script>

<style lang="scss" scoped>

.logged-user__container {
  align-items: center;
  display: flex;
}

.hideElements {
  display: none;
}

.logged-user--extra-info,
.logged-user--main-info {
  display: flex;

  .logged-user--name {
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
}

@media only screen and (max-width: 1024px) {
  .logged-user--extra-info {
    .logged-user--name,
    .logged-user--divider {
      display: none;
    }
  }
}

@media only screen and (max-width: 575px) {
  .logged-user--extra-info {
    display: none;
  }
}

</style>