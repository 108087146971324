<template>
  <div>
    <InputRadioBoxGroup 
      title="Eigenheim"
      v-model="homeHollyOwned" 
      :validateUntouched="true"
      :disabled="isRefusedPage"
      :values="proprietary"
      labelClass="font-bold"
      @input="updateImmo('homeHollyOwned', $event)" />
    <InputField
      label="Eigenanteil"
      v-model="homePercentage"
      type="percent"
      isComponentHalfSize
      :disabled="isRefusedPage || homeHollyOwned !== 'CO_OWNER'"
      @input="updateImmo('homePercentage', $event || null)" />
    <InputField
      label="Aktueller Verkehrswert (Gesamtobjekt)"
      v-model="home"
      type="currency"
      isComponentHalfSize
      :disabled="isRefusedPage || !homeHollyOwned"
      @input="updateImmo('home', $event || null)" />
    <hr>

    <InputRadioBoxGroup 
      title="Eigentumswohnungen"
      v-model="appartmentsHollyOwned" 
      :validateUntouched="true"
      :disabled="isRefusedPage"
      :values="proprietary"
      labelClass="font-bold"
      @input="updateImmo('appartmentsHollyOwned', $event)" />
    <InputField
      label="Eigenanteil"
      v-model="appartmentsPercentage"
      type="percent"
      isComponentHalfSize
      :disabled="isRefusedPage || appartmentsHollyOwned !== 'CO_OWNER'"
      @input="updateImmo('appartmentsPercentage', $event || null)" />
    <InputField
      label="Aktueller Verkehrswert (Gesamtobjekt)"
      v-model="appartments"
      type="currency"
      isComponentHalfSize
      :disabled="isRefusedPage || !appartmentsHollyOwned"
     @input="updateImmo('appartments', $event || null)" />
    <hr>

    <InputRadioBoxGroup 
      title="Sonstige Immobilien / Liegenschaften"
      v-model="otherPropertyHollyOwned" 
      :validateUntouched="true"
      :disabled="isRefusedPage"
      :values="proprietary"
      labelClass="font-bold"
      @input="updateImmo('otherPropertyHollyOwned', $event)" />
    <InputField 
      label="Eigenanteil"
      v-model="otherPropertyPercentage"
      type="percent"
      isComponentHalfSize
      :disabled="isRefusedPage || otherPropertyHollyOwned !== 'CO_OWNER'"
      @input="updateImmo('otherPropertyPercentage', $event || null)" />
    <InputField
      label="Aktueller Verkehrswert (Gesamtobjekt)"
      v-model="otherProperty"
      type="currency"
      isComponentHalfSize
      :disabled="isRefusedPage || !otherPropertyHollyOwned"
      @input="updateImmo('otherProperty', $event || null)" />
  </div>
</template>

<script>
import InputField from '@/components/core/forms/InputField.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';

export default {
    props: {
        isRefusedPage: {
            type: Boolean,
            default: false,
        },
        finance: {
            type: Object,
            default: () => {}
        }
    },
    components: {
        InputField,
        InputRadioBoxGroup,
    },
    mounted() {
        this.createForm();
        // this.updateWarnings();
    },
    data() {
        return {
            proprietary: [{ value: 'HOLLY_OWNED', label: 'Alleineigentümer' }, { value: 'CO_OWNER', label: 'Miteigentümer' }, { value: '', label: 'Keine Angabe' }],
            home: '',
            homeHollyOwned: '',
            homePercentage: '',
            appartments: '',
            appartmentsHollyOwned: '',
            appartmentsPercentage: '',
            otherProperty: '',
            otherPropertyHollyOwned: '',
            otherPropertyPercentage: '',
        };
    },
    methods: {
        createForm() {
            this.home = this.finance.home || '';
            this.homeHollyOwned = this.finance.homeHollyOwned || '';
            this.homePercentage = this.finance.homePercentage || '';
            this.appartments = this.finance.appartments || '';
            this.appartmentsHollyOwned = this.finance.appartmentsHollyOwned || '';
            this.appartmentsPercentage = this.finance.appartmentsPercentage || '';
            this.otherProperty = this.finance.otherProperty || '';
            this.otherPropertyHollyOwned = this.finance.otherPropertyHollyOwned || '';
            this.otherPropertyPercentage = this.finance.otherPropertyPercentage || '';
        },
        updateImmo(compId, value) {
            if (compId) {
                this.$emit('updateImmo', { compId: compId, value: value })
            }
        }
    }
}
</script>

<style>

</style>