<template>
  <div>
    <PageHeaderTitleNavigation v-on="$listeners">
      <template #title><slot name="title">Lizenzen</slot></template>
    </PageHeaderTitleNavigation>

    <BoxContainer>
      <div class="row">
        <div class="col-12 d-flex justify-content-between" v-for="(license, index) in licensesMapped" :key="index">
          <span class="text-bold">{{`${license.key}: `}}</span>
          <a @click="openLicenseFile(license)">{{license.value}}</a>
        </div>
      </div>
      <BaseModal
        ref="licenseModal"
        size="md"
        @close="closeModal"
        modalTitle="License"
        labelButtonConfirm="Ok"
        :showDefaultButtons="false">
          <div class="row">
            <div class="col-12">
              <pre>{{currentLicense}}</pre>
            </div>
          </div>
        </BaseModal>

    </BoxContainer>
  </div>
</template>

<script>
import BaseModal from '@/components/core/BaseModal.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import BoxContainer from "@/components/core/BoxContainer.vue";
import axios from 'axios'

export default {
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    BoxContainer,
    BaseModal,
  },
  data() {
    return{
      licenses: null,
      currentLicense: '',
    }
  },
  computed: {
    licensesMapped() {
      if(!this.licenses) {
        return null
      }
      return Object.entries(this.licenses).map(([key, value, index]) => {
        return {key: key.replace(/@|\d*\.\d*\.\d*/g, ''), value: value.licenses, filePath: value.licenseFile}
      })
    }
  },
  methods: {
    openLicenseFile(licenseFile) {
      if(licenseFile) {
        let file = `/licenses${licenseFile.filePath.replace(/^.*node_modules/, '').replace(/\\/g, '/')}`
        axios.get(file)
        .then(response => {
          this.currentLicense = response.data;
          this.openModal()
        })
        .catch(error => {
          console.error('Error to load license file.', error);
        });
      }
    },
    closeModal() {
      this.currentLicense = '';
      this.$refs.licenseModal.close()
    },
    openModal() {
      this.$refs.licenseModal.open();
    },
  },
  mounted() {
    axios.get('/licenses/licenses.json').then(response => {
        this.licenses = response.data;
      })
      .catch(error => {
        console.error('License file not found. Maybe was not generated by build script.', error);
      });
  },
}
</script>
<style scoped>
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>