var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderActionCard", {
        attrs: { title: "Spartentypen bearbeiten" }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("div", { staticClass: "box__title" }, [
            _vm._v("Spartentypen Liste")
          ]),
          _c("InputField", {
            attrs: {
              isComponentHalfSize: "",
              placeholder: "Suchen nach Bezeichnung"
            },
            model: {
              value: _vm.descriptionSearch,
              callback: function($$v) {
                _vm.descriptionSearch = $$v
              },
              expression: "descriptionSearch"
            }
          }),
          !_vm.loading && _vm.tableRows.length
            ? _c("Table", {
                attrs: {
                  rowId: "sparteId",
                  headers: _vm.tableHeaders,
                  rows: _vm.tableRows
                },
                on: { "click-bezeichnung": _vm.openEditConfig }
              })
            : _c("GhostLoading", { attrs: { type: "table" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }