<template>
  <div>
    <PageHeaderTitleNavigation title="Mustervorlagen" />

    <div class="box__container">
      <div>
        <BaseButton class="mb-3" @click="produceForm()">PDF</BaseButton>
      </div>

      <div class="box__title">Vermittler Daten</div>
      <ComboBox
        label="Vermittlerauswahl"
        :values="vermittlerauswahlValues"
        :isComponentHalfSize="true"
        :sortComboboxValues="false"
        v-model="form.vermittlerauswahl"
      />
      <InputField
        label="Firma Name"
        :isComponentHalfSize="true"
        v-model="form.firmaName"
      />
      <InputField
        label="Straße, Hausnummer"
        :isComponentHalfSize="true"
        v-model="form.strassehnr"
      />
      <InputField
        label="PLZ,Ort"
        :isComponentHalfSize="true"
        v-model="form.plzOrt"
      />
      <div  v-if="!isErklaerung && !isBestUebertragungVers" class="box__title mt=5">Daten des abgebenden Pool</div>
      <InputField
      v-if="!isErklaerung && !isBestUebertragungVers"
        label="Pool Name/Firma"
        :isComponentHalfSize="true"
        v-model="form.poolName"
      />
      <InputField
      v-if="!isErklaerung && !isBestUebertragungVers"
        label="Straße, Hausnummer"
        :isComponentHalfSize="true"
        v-model="form.poolStrasseHnr"
      />
      <InputField
      v-if="!isErklaerung && !isBestUebertragungVers"
        label="PLZ,Ort"
        :isComponentHalfSize="true"
        v-model="form.poolPlzOrt"
      />
      <InputField
        v-if="!isErklaerung && !isBestUebertragungVers"
        label="Vermittlernummer"
        :isComponentHalfSize="true"
        v-model="form.vermittlernummer"
      />
      <DatePickerField 
      label='Kunden informiert am'
      v-if="isErklaerung"
      :isComponentHalfSize="true"
      v-model="form.informiertAm"/>

      <InputField
        v-if="isBestUebertragungVers"
        label="Vermittlernummer Gesellschaft"
        :isComponentHalfSize="true"
        v-model="form.vermittlernrGesellschaft"
      />

    </div>
      <BaseModal ref="infoModal" :showDefaultButtons="false">
        <template v-slot:modalTitle>
          <span>Die Bestandsfreigabe muss bei der Fondskonzept eingereicht werden.</span>
        </template>
      </BaseModal>
  </div>
</template>

<script>
import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import PageHeaderTitleNavigation from "@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue";
import { mapGetters } from "vuex";
import InputField from "@/components/core/forms/InputField.vue";
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import ANTRAG_TYPES from "@/store/antrag/types";
import BaseButton from "@/components/core/BaseButton.vue";
import { viewDocument } from "@/components/core/download/DownloadLink.vue";
import { base64ToArrayBuffer } from "@/helpers/download-helper";
import dayjs from 'dayjs';
import BaseModal from '@/components/core/BaseModal.vue';

export default {
  data() {
    return {
      form: {
        id: null,
        vermittlerauswahl: "",
        firmaName: "",
        strassehnr: "",
        plzOrt: "",
        vermittlernummer: "",
        poolPlzOrt: "",
        poolStrasseHnr: "",
        poolName: "",
        informiertAm:"",
        vermittlernrGesellschaft:""
      },
      vermittlerauswahlValues: [],
    };
  },
  methods: {
    updateAddress() {
      const payload = this.form.vermittlerauswahl;
      this.$store
        .dispatch(ANTRAG_TYPES.ACTIONS.GET_VERMITTLER_ADDRESS, payload)
        .then((response) => {
          if (response && response.data) {
            this.form.firmaName = response.data.firmaName;
            this.form.strassehnr = response.data.strassehnr;
            this.form.plzOrt = response.data.plzOrt;
          }
        });
    },
    async produceForm() {
      const dateiName = this.$route.query.dateiName;
      const personId = this.form.vermittlerauswahl;
      this.form.informiertAm = this.form.informiertAm ?  dayjs(this.form.informiertAm).format('DD.MM.YYYY') : ''
      const payload = {
        mustervorlage: this.form,
        type: dateiName,
      };
      if (personId != "M") {
        payload.personId = personId;
      }
      await this.$store
        .dispatch(ANTRAG_TYPES.ACTIONS.PRODUCE_MUSTERVORLAGE_PDF, payload)
        .then((response) => {
          if (response && response.data) {
            const file = base64ToArrayBuffer(response.data.data);
            viewDocument(
            {
              data: file,
              filename: response.data.fileName,
              contentType: "application/pdf",
            },
            true
          );
          }
        }).finally(()=>{
          if(!this.isBestUebertragungVers){
            this.$refs.infoModal.open();
          }
        });
    },
    cleanFields() {
      this.form.firmaName = null;
      this.form.strassehnr = null;
      this.form.plzOrt = null;
    }
  },
  watch: {
    "form.vermittlerauswahl": function (newVal, oldVal) {
      if(newVal == 'freie Eingabe:') {
        this.cleanFields();
      } else {
        this.updateAddress(newVal);
      }
    },
  },
  mounted() {
    this.$store
      .dispatch(ANTRAG_TYPES.ACTIONS.GET_VERMITTLERAUSWAHL)
      .then((response) => {
        this.vermittlerauswahlValues = response.data;
        this.form.vermittlerauswahl = "M";
      });
  },
  computed: {
    ...mapGetters({}),
    isErklaerung(){
      return this.$route.query.dateiName == 'FK_Erklaerung_BestUebertrag.pdf';
    },
    isBestUebertragungVers(){
      return this.$route.query.dateiName == 'AK_Bestand_Uebertragung.pdf';
    },
    
  },
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    InputField,
    DatePickerField,
    ComboBox,
    BaseButton,
    BaseModal
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: "zurück",
      to,
      from,
    });
    next();
  },
};
</script>
