import { render, staticRenderFns } from "./Risikoverteilung.vue?vue&type=template&id=ff4d0022&"
import script from "./Risikoverteilung.vue?vue&type=script&lang=js&"
export * from "./Risikoverteilung.vue?vue&type=script&lang=js&"
import style0 from "./Risikoverteilung.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("P:\\workspaces\\fk0\\fc\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ff4d0022')) {
      api.createRecord('ff4d0022', component.options)
    } else {
      api.reload('ff4d0022', component.options)
    }
    module.hot.accept("./Risikoverteilung.vue?vue&type=template&id=ff4d0022&", function () {
      api.rerender('ff4d0022', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/risikoverteilung/Risikoverteilung.vue"
export default component.exports