import INSURANCE_TYPES from './types';
import { getInitialState } from './index'

export default {
  [INSURANCE_TYPES.MUTATIONS.RETRIEVE_DOCUMENT_SUCCESS](state, payload) {
    state.documentData = payload;
  }, 
  [INSURANCE_TYPES.MUTATIONS.RESET_STATE](state, payload) {
    Object.assign(state, getInitialState())
  } 
  
  
}