var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.$appNavigation.currentMenu.parent.label,
          subtitle: _vm.$appNavigation.currentMenu.label,
          defaultMenu: _vm.$appNavigation.currentTabMenu,
          actions: _vm.actions
        },
        on: {
          "action-RESET": function($event) {
            return _vm.doConfirm("zurücksetzen")
          }
        }
      }),
      !_vm.loading && _vm.limits && _vm.limits.length
        ? _c(
            "div",
            _vm._l(_vm.limits, function(limit, index) {
              return _c(
                "div",
                { key: "limit" + index, staticClass: "box__container" },
                [
                  limit.depot
                    ? _c("div", { staticClass: "box__title" }, [
                        _vm._v(_vm._s(limit.depot))
                      ])
                    : _vm._e(),
                  !_vm.isCustomerView
                    ? _c("div", [
                        _c(
                          "a",
                          {
                            on: {
                              click: function($event) {
                                return _vm.openCustomerNewTab(limit)
                              }
                            }
                          },
                          [
                            _c("span", [_vm._v(_vm._s(limit.kundenLinkName))]),
                            _c("span", [
                              _vm._v(
                                " (Kundennr " + _vm._s(limit.kundennr) + ")"
                              )
                            ])
                          ]
                        )
                      ])
                    : _vm._e(),
                  [
                    _c("Table", {
                      attrs: {
                        tableId: "11fcafaf-5332-48db-8ba2-9e5ba7259281",
                        headers: _vm.headers,
                        rows: limit.rows,
                        rowsPerPage: 20,
                        mobileConfig: {
                          title: "kategorieStr",
                          headers: [
                            "status",
                            "wert",
                            "unteresLimit",
                            "oberesLimit"
                          ]
                        },
                        rowId: "id",
                        hidePagination: ""
                      },
                      on: {
                        "action-new": function($event) {
                          return _vm.editLimit(limit)
                        },
                        "action-edit": function($event) {
                          return _vm.editLimit(limit)
                        },
                        "action-delete": function($event) {
                          _vm.selectedLimit = limit
                          _vm.$refs.confirmDelete.open()
                        },
                        "action-reset": function($event) {
                          _vm.selectedLimit = limit
                          _vm.$refs.confirmReset.open()
                        }
                      }
                    })
                  ]
                ],
                2
              )
            }),
            0
          )
        : _vm.loading
        ? _c("GhostLoading", {
            attrs: {
              type: "table",
              title: _vm.TABLE_TITLE,
              useBoxContainer: ""
            }
          })
        : _c("NoData", {
            attrs: { title: _vm.TABLE_TITLE, useBoxContainer: "" }
          }),
      _c(
        "BaseModal",
        {
          ref: "confirmDelete",
          attrs: { labelButtonConfirm: "Ja", labelButtonCancel: "Nein" },
          on: {
            onCancelButton: function($event) {
              _vm.selectedLimit = null
            },
            onCloseButton: function($event) {
              _vm.selectedLimit = null
            },
            onConfirmButton: function($event) {
              return _vm.removeLimit()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("PhWarning", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v("Löschen ")
                ]
              },
              proxy: true
            }
          ])
        },
        [_c("div", [_vm._v("Wollen Sie wirklich das Limit löschen?")])]
      ),
      _c(
        "BaseModal",
        {
          ref: "confirmReset",
          attrs: { labelButtonConfirm: "Ja", labelButtonCancel: "Nein" },
          on: {
            onCancelButton: function($event) {
              _vm.selectedLimit = null
            },
            onCloseButton: function($event) {
              _vm.selectedLimit = null
            },
            onConfirmButton: function($event) {
              return _vm.resetLimit()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("PhWarning", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v("Zurücksetzen ")
                ]
              },
              proxy: true
            }
          ])
        },
        [_c("div", [_vm._v("Wollen Sie wirklich das Limit zurücksetzen?")])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }