var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Table",
        _vm._b(
          {
            attrs: {
              headers: _vm.headers,
              rows: _vm.rows,
              selected: _vm.modelSelected ? _vm.selected : undefined,
              scrollHorizontally: _vm.scrollHorizontally
            },
            on: {
              selected: function($event) {
                _vm.selected = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "text_input",
                fn: function(row) {
                  return [
                    _c("InputField", {
                      attrs: { value: row.text },
                      on: {
                        input: function($event) {
                          return _vm.updateRow(row, "text", $event)
                        }
                      }
                    })
                  ]
                }
              },
              {
                key: "custom",
                fn: function(row) {
                  return [
                    row.custom
                      ? _c(
                          "ul",
                          _vm._l(row.custom, function(el) {
                            return _c("li", { key: el }, [
                              _vm._v(" " + _vm._s(el) + " ")
                            ])
                          }),
                          0
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          },
          "Table",
          _vm.props,
          false
        )
      ),
      _vm.modelSelected
        ? [
            _vm._v(
              " selected " +
                _vm._s(_vm.selected.length) +
                " rows, namely: " +
                _vm._s(_vm.selected) +
                " "
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }