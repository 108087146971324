<template>
  <div>
    <InputColorPicker
      v-if="!isFullColor"
      :size="size"
      :value="currentColor"
      :disabled="disabled"
      @change="$emit('change', $event)"/>
  </div>
</template>

<script>
import InputColorPicker from '@/components/core/forms/InputColorPicker.vue'

export default {
  components: {
    InputColorPicker,
  },
  props: {
    value: {
      type: String,
    },
    size: {
      type: String,
      default: '32',
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      isFullColor: false,
    }
  },
  computed: {
    currentColor() {
      return this.value || document.documentElement.style.getPropertyValue('--color-text');
    }
  }

}
</script>

