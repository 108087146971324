<template>
  <div>
    <div class="row" v-if="isBrokerOrBypass && canEditAnmerkung">
      <div class="col-12">
        <BaseButton @click="openAddModal()">Neue Anmerkung</BaseButton>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <GhostLoading v-if="loading" type="table" />
        <Table  v-else-if="rows.length"
            :headers="headers"
            :rows="rows"
            :rowsPerPage="10"
            @click-betreff="openEditModal"
            @action-EDIT="openEditModal"
            @action-DELETE="openDeleteModal"
        />
        <NoData v-else />
      </div>
    </div>

    <BaseModal ref="anmerkungDataModal"
      modalTitle="Anmerkung editieren"
      size="md"
      :showDefaultButtons="false">
      <template #default>
        <div class="row">
          <div class="col-12">
            <DatePickerField
              isValueAsString
              label="Datum"
              validateUntouched
              v-model="form.datum"
              :disabled="!isBrokerOrBypass || !canEditAnmerkung"
            />
          </div>
          <div class="col-12">
            <InputField
              label="Erstellt am"
              :disabled="true"
              v-model="form.dateCreated"
            />
          </div>
          <div class="col-12">
            <InputField
              label="Zuletzt geändert am"
              :disabled="true"
              v-model="form.dateModified"
            />
          </div>
          <div class="col-12">
            <InputField label="Thema" v-model="form.betreff" validateUntouched :disabled="!isBrokerOrBypass || !canEditAnmerkung"/>
          </div>
          <div class="col-12">
            <InputTextArea
              label="Text"
              :rows="5"
              v-model="form.text"
              :disabled="!isBrokerOrBypass || !canEditAnmerkung"
            />
          </div>
          <div class="col-12">
            <InputRadioBoxGroup 
              title="Sichtbar für"
              v-model="form.sichtbar" 
              :values="radioboxGroupData"
              :disabled="!isBrokerOrBypass || !canEditAnmerkung"
            />
          </div>
          <InputField
            label="Ersteller"
            disabled
            v-model="form.ersteller"
          />
        </div>
      </template>
      <template #footer>
        <BaseButton isSecondary @click="closeAnmerkungDataModal()" class="mr-3">{{canEditAnmerkung ? 'Abbrechen' : 'Schließen'}}</BaseButton>
        <BaseButton :disabled="validation.isInvalid('form')" @click="saveAndUpdate(); closeAnmerkungDataModal();" v-if="isBrokerOrBypass && canEditAnmerkung">Speichern</BaseButton>
      </template>
    </BaseModal>

    <BaseModal
      ref="anmerkungDeleteModal"
      size="sm"
      modalTitle="Anmerkung löschen"
      labelButtonConfirm="Anmerkungen löschen"
      @onConfirmButton="deleteAnmerkung()">
      <template #default>
        Soll die Anmerkung für den {{ anmerkungToDelete.datum }} wirklich gelöscht werden?
      </template>
    </BaseModal>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";
import dayjs from 'dayjs';

import Table from "@/components/table2/Table.vue";
import {TextColumn, ActionColumn, SimpleAction, DateColumn} from "@/components/table2/table_util.js";
import BaseButton from '@/components/core/BaseButton.vue';
import InputField from '@/components/core/forms/InputField.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';

import validator from '@/mixins/validator';
import { required } from '@/mixins/validator/rules';


export default {
  mixins: [validator],
  components: {
    Table,
    BaseButton,
    InputField,
    InputRadioBoxGroup,
    DatePickerField,
    InputTextArea,
    InputToggleSwitch,
    BaseModal,
    GhostLoading,
    NoData,
  },
  data() {
    return {
      loading: false,
      form: {},
      anmerkungToDelete: {},
    };
  },
  computed: {
    ...mapGetters({
      anmerkungen: VERSICHERUNG_TYPES.GETTERS.INSURANCE_ANMERKUNG_GET,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
      isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
      canEditAnmerkung: VERSICHERUNG_TYPES.GETTERS.CAN_EDIT_ANMERKUNG,
      originalUser: CORE_TYPES.GETTERS.ORIGINAL_USER,
    }),
    radioboxGroupData() {
      let values = [{ value: 'POOL', label: 'Pool' }, { value: 'MAKLER', label: 'Vermittler' }, { value: 'KUNDE', label: 'Kunde' }];

      if(this.isIntern){
        values.splice(0,0, { value: 'INTERN', label: 'Intern' })
      }

      return values;
    },
    headers() {
        const headers = {
            center: [
                DateColumn("datum", "Datum"),
                TextColumn("betreff", "Thema").makeLink(),
                TextColumn("text", "Text"),
                TextColumn("ersteller", "Ersteller"),
            ],
            lockedRight: [],
        }
        if (this.isBrokerOrBypass && this.canEditAnmerkung)
            headers.lockedRight.push(ActionColumn("actions"));
        return headers;
    },
    rows() {

        return (this.anmerkungen || []).map(row => {
          let actions = this.makeActions(row);
          return {
            ...row,
            actions,
        }})
    },
    userIdUnterNr() {
      return this.originalUser?.unternr ? `${this.originalUser?.userid}-${this.originalUser.unternr}` : this.originalUser?.userid
    },
  },
  methods: {
    makeActions(row) {
        const actions = [];

        if (!row.betreff) {
          actions.push(SimpleAction("EDIT", 'PhPencilLine', "Bearbeiten"))
        }

        actions.push(SimpleAction("DELETE", 'PhTrash', "Löschen"))
        return actions
    },
    openAnmerkungDataModal() {
      this.$refs.anmerkungDataModal.open();
    },
    closeAnmerkungDataModal() {
      this.$refs.anmerkungDataModal.close();
    },
    openAddModal() {
      this.form = {
        datum: dayjs(new Date()).format('DD.MM.YYYY'),
        dateCreated: dayjs(new Date()).format('DD.MM.YYYY'),
        dateModified: null,
        betreff: null,
        text: null,
        sichtbar: 'MAKLER',
        ersteller: this.userIdUnterNr,
      };
      this.openAnmerkungDataModal();
    },
    openEditModal(anmerkung) {
      this.form = {
        ...anmerkung,
      };
      this.openAnmerkungDataModal();
    },
    findAnmerkung() {
      this.loading = true;
      this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.INSURANCE_ANMERKUNG_GET, this.$route.params.versicherungId)
        .finally(() => this.loading = false);
    },
    async saveAndUpdate() {
      await this.saveForm();
      this.findAnmerkung();
    },
    async saveForm() {
      const data = {
        ...this.form,
        owner: this.$route.params.versicherungId
      };
      
      const anmerkung = await this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.INSURANCE_ANMERKUNG_SAVE, data);

      this.form = {
        ...anmerkung,
      };
    },
    openDeleteModal(anmerkung) {
      this.anmerkungToDelete = { 
        ...anmerkung,
      };

      this.$refs.anmerkungDeleteModal.open();
    },
    deleteAnmerkung() {
      this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.INSURANCE_ANMERKUNG_DELETE, this.anmerkungToDelete?.id);
    },
  },
  mounted() {
    this.findAnmerkung();
  },
  validators: {
    form: {
      datum: [required('Datum ist erforderlich!')],
      betreff: [required('Thema ist erforderlich!')],
    },
  },
}
</script>
