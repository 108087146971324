<template>
    <div>
        <div v-if="isEmpty">Keine zusatzliche Daten erforderlich</div>
        <div v-else>
            <template v-if="components && components.length">
                <div class="box__title">Liechtensteinische Landesbank (Österreich) AG</div>
                 <ComponentList :components="components" :data="data" :comboboxValues="comboboxValues" :disabled="readOnly"
                    @change="updateStoreData($event)"></ComponentList>
            </template>
        </div>
    </div>
</template>

<script>
import ComponentList from '@/components/wertpapierorder/ComponentList.vue';
import WERTPAPIERORDER_TYPES from '@/store/wertpapierorder/types';
import mixin from '@/mixins/wertpapierorder/mixin.js';
import { mapGetters } from "vuex";

export default {
    mixins: [mixin],
    components: {
        ComponentList,
    },
    mounted() {
    },
    computed: {
        ...mapGetters({
            comboboxValues: WERTPAPIERORDER_TYPES.GETTERS.COMBOBOX_VALUES || {},
            lagerstelle: WERTPAPIERORDER_TYPES.GETTERS.SELECTED_LAGERSTELLE,
            components: WERTPAPIERORDER_TYPES.GETTERS.SEMPERCONSTANTIA_COMPONENTS,
        }),
        isEmpty() {
            return !this.components || !Object.keys(this.components).length;
        }
    },
}
</script>

<style>
</style>