var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: {
        labelButtonConfirm: "Ok",
        labelButtonCancel: "Abbrechen",
        autoClose: ""
      },
      on: { onConfirmButton: _vm.save, close: _vm.close },
      scopedSlots: _vm._u([
        {
          key: "modalTitle",
          fn: function() {
            return [_vm._v(" Datensatz bearbeiten ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        [
          _c("span", { staticClass: "info-header" }, [_vm._v("Zeilennummer")]),
          _vm._v(" " + _vm._s(_vm.line.id)),
          _c("br"),
          _c("span", { staticClass: "info-header" }, [_vm._v("Dateiname")]),
          _vm._v(" " + _vm._s(_vm.line.fileName)),
          _c("br"),
          _c("span", { staticClass: "info-header" }, [_vm._v("Gesellschaft")]),
          _vm._v(" " + _vm._s(_vm.line.gesellschaft)),
          _c("br"),
          _c("span", { staticClass: "info-header" }, [
            _vm._v("Interface Name")
          ]),
          _vm._v(" " + _vm._s(_vm.line.interfaceName)),
          _c("br"),
          _c("br"),
          _c("ComboBox", {
            attrs: {
              label: "Datensatz",
              values: _vm.use4BookingOptions,
              disabled: !_vm.loaded
            },
            model: {
              value: _vm.use4Booking,
              callback: function($$v) {
                _vm.use4Booking = $$v
              },
              expression: "use4Booking"
            }
          }),
          _c("InputTextArea", {
            attrs: {
              label: "Text",
              disabled: !_vm.loaded || _vm.textDisabled,
              autoGrow: ""
            },
            model: {
              value: _vm.text,
              callback: function($$v) {
                _vm.text = $$v
              },
              expression: "text"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }