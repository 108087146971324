<template>
  <div>
    <div class="dashboard-panel__widgets">
      <div class="box__container dashboard-panel-widget">
        <InputField
          label="Bezeichnung"
          placeholder=""
          v-model="form.title"
          type="text"
          validateUntouched
          @change="doChanges('title', $event)" />
        <DatePickerField
          v-model="form.endDate"
          label="Zieldatum (Ende)"
          isValueAsString
          monthPicker
          :disabled="hasDepots"
          @change="endDateChanged" />
        <InputField v-if="!isRuecklage"
          label="Benötigtes Kapital"
          v-model="form.zielsumme"
          @change="zielsummeChanged"
          type="currency"
          :precision="2" />
        <InputField v-if="isRuecklage || isImmobilie"
          label="Sparrate pro Monat"
          v-model="form.sparrate"
          validateUntouched
          :disabled="hasDepots"
          @change="valueChanged('sparrate', $event)"
          type="currency"
          :precision="2" />
        <InputField
          label="Vorhandenes Kapital"
          v-model="form.startkapital"
          :disabled="hasDepots"
          @change="startkapitalChanged"
          type="currency"
          :precision="2" />
        <InputField
          label="Wertenwicklung"
          v-model="form.rendite"
          :disabled="hasDepots"
          @change="renditeChanged"
          type="percent"
          :precision="2" />
      </div>

      <div class="box__container dashboard-panel-widget">
        <VirtuelleDepots @depotChange="setForm"/>
      </div>
    </div>

  </div>
</template>

<script>
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import InputField from "@/components/core/forms/InputField.vue";
import validator from "@/mixins/validator";
import { required } from "@/mixins/validator/rules";
import MY_GOALS_TYPES from '@/store/myGoals/types';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import dayjs from 'dayjs';
import mixin from './my-goals-mixin.js';
import VirtuelleDepots from "@/components/myGoals/VirtuelleDepots.vue";

export default {
  mixins: [validator, mixin],
  components: {
    InputField,
    DatePickerField,
    ComboBox,
    VirtuelleDepots,
  },
  data() {
    return {
      ages: new Array(50),
      form: {
        title: '',
        endDate: null,
        startDate: null,
        zielsumme: null,
        startkapital: null,
        sparrate: null,
        rendite: null,
      },
    }
  },
  validators: {
    form: {
      title: [required('Bitte geben Sie eine Bezeichnung.')],
      sparrate: [required('Bitte geben Sie eine Sparrate ein.')],
    }
  },
  mounted() {
    this.setForm();
  },
  methods: {
    endDateChanged(value) {
      if (this.endDateValid(value)) {
        this.valueChanged('endDate', value);
        this.updateZielsummeForm();
      } else {
        this.$confirmModal({
          message: "Zielsdatum is nicht gültig. Bitte korrigieren.",
          title: "Ungültiges Datum",
          showCancelButton: false,
          labelButtonConfirm: 'Ok'
        });
      }
    },
    zielsummeChanged(value) {
      if (this.zielsummeValid(value)) {
        this.doChanges('zielsumme', +value)
      } else {
        this.form.zielsumme = this.goal.zielsumme;
        this.$confirmModal({
          message: this.zielsummeInvalidText,
          title: "Daten nicht korrekt",
          showCancelButton: false,
          labelButtonConfirm: 'Ok'
        });
      }
    },
    startkapitalChanged(value) {
      this.doChanges('startkapital', value ? +value : null)
      this.updateZielsummeForm();
    },
    renditeChanged(value) {
      this.doChanges('rendite', value)
      this.updateZielsummeForm();
    },
    updateZielsummeForm() {
      if (!this.form.sparrate && this.form.zielsumme < this.calculateZielsumme()) {
        this.form.zielsumme = this.calculateZielsumme();
      }
    },
    valueChanged(componentId, value) {
      if (this.isRuecklage) {
        this.$store.commit(MY_GOALS_TYPES.MUTATIONS.UPDATE_STORE, { value: { [componentId]: value}, id: this.id });
        this.updateZielsumme();
      } else {
        this.doChanges(componentId, value);
      }
    },
    setForm() {
      this.form = Object.assign(this.form, this.goal);
      if (!Object.keys(this.goal)?.length) {
        this.form.rendite = 2;
        this.doChanges('rendite', 2);
      }
    },
    getInterest() {
      if (this.goal.type === 'large') {
        const endDateVal = this.endDate;
        const now = new Date();
        now.setDate(1);
        const period = this.monthsDiff(now, dayjs(endDateVal, 'MM.YYYY').toDate());
        if (period > 4 * 12) {
          return 0.04;
        }
      }
      return 0.02;
    },
  },
};
</script>

<style src='./my-goals.scss' lang='scss' lang="scss" scoped></style>