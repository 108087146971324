<template>
  <div class="dropdown-menu__hook" :class="{'active': show, 'is-dropdown-disabled': disabled}" @click="onClickTargetHook($event)">
    <div ref="dropdownMenuHook" class="dropdown-menu--hook-target"><slot name="hook-target"></slot></div>
    <transition name="transition-active">
      <div ref="dropdownMenuContainer"
        class="dropdown-menu__container boxes__corner--rounded" :class="containerClasses">
        <div class="dropdown-menu__container--inner">
          <slot/>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import positioning from '@/mixins/positioning-helper'
import { isDescendant } from '@/helpers/utils-helper.js'

export default {
  mixins: [positioning],
  props: {
    target: { default: null },
    placement: { type: String, default: 'bottom' },
    windowOpenClass: { type: String, default: '' },
    containerClass: { type: String, default: '' },
    closeWithInnerClick: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false }
  },
  data: function() {
    return {
      show: false,
      top: 0,
      left: 0,
      openTriggerEvent: null
    }
  },
  computed: {
    containerClasses() {
      return [
        this.containerClass, 
        {
          active: this.show,
        },
      ];
    },
  },
  methods: {
    close() {
      this.hideContainer();
    },
    showContainer($event) {
      if (this.disabled) {
        return
      }
      this.show = true;
      if ($event?.target) {
        if (this.windowOpenClass) {
          document.querySelector('html').classList.add('dropdown-menu-open', this.windowOpenClass);
        }
        this.configurePosition();
      }
      this.$emit('onShow')
    },
    hideContainer() {
      this.show = false;
      if (this.windowOpenClass) {
        document.querySelector('html').classList.remove('dropdown-menu-open', this.windowOpenClass);
      }
      this.$emit('onHide')
    },
    onClickTargetHook: function($event) {
      if (!this.show) {
        this.showContainer($event);
      } else {
        this.hideContainer($event);
      }
      this.openTriggerEvent = $event;
    },
    onClick($event) {
      const notCloseEl = $event.target.closest('[data-dropdown-not-close]') || $event.target;
      // the property "ignoreClosePopup" was manually added to the event
      // and is present only when displaying the interactive help
      if (this.show && this.openTriggerEvent !== $event && !$event.ignoreClosePopup 
          && this.canCloseByInnerClick($event) && !notCloseEl?.hasAttribute('data-dropdown-not-close')) {
        this.hideContainer($event);
      }
    },
    canCloseByInnerClick(event) {
      const descendant = isDescendant(this.$refs.dropdownMenuContainer, event.target)
      return !descendant || (this.closeWithInnerClick && descendant)
    },
    configurePosition() {
      this.positionElement('dropdownMenuHook', 'dropdownMenuContainer', {
        placement: this.placement, 
        appendToBody: true,
      });
    },
    handleResize() {
      if(!this.$refs.dropdownMenuHook?.offsetParent) {
        this.hideContainer();
      }

      this.configurePosition();
    },
  },
  mounted() {
    document.body.appendChild(this.$refs.dropdownMenuContainer)

    document.addEventListener('click', this.onClick);
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    document.body.removeChild(this.$refs.dropdownMenuContainer);

    document.removeEventListener('click', this.onClick);
    window.removeEventListener('resize', this.handleResize);
  },
}
</script>

<style scoped>
  * {
    box-sizing: border-box;
  }

  .dropdown-menu__hook {
    position: relative;
    overflow: hidden;
  }
  .is-dropdown-disabled * {
    cursor: not-allowed;
  }
  .dropdown-menu__container {
    box-shadow: 0 1px 4px 2px rgba(85, 85, 85, 0.25);
    color: var(--color-text);
    position: absolute;
    top: -9999px; /** keep the container out of the screen */
    left: -9999px; /** keep the container out of the screen */
    padding: 0;
    background-color: var(--color-box);
    z-index: 1000;
    max-width: 85vw;
    opacity: 0;
    pointer-events: none;
    width: 0; /** keep the container out of the screen */
    height: 0; /** keep the container out of the screen */
    overflow: hidden; /** keep the container out of the screen */
  }
  .modal-open .dropdown-menu__container {
    z-index: 1100; /** increases the z-index when a dropdown is visible and a modal is opened */
  }
  .dropdown-menu__container.active::after{
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    box-sizing: border-box;
    
    border: 0.5em solid black;
    border-color: transparent transparent var(--color-box) var(--color-box);
    
    transform-origin: 0 0;
    box-shadow: -2px 2px 2px 0 rgba(0, 0, 0, 0.12);
  }
  .dropdown-menu__container--inner {
    padding: 16px 20px;
  }
  .dropdown-menu__container.active {
    transition: opacity .3s ease;
    opacity: 1;
    pointer-events: all;
    width: auto;
    height: auto;
    overflow: initial;
  }

  .positioned-top::after {
    margin-left: -0.75em;
    bottom: -1em;
    left: 50%;
    transform: rotate(-45deg);
  }
  
  .positioned-left::after {
    right: -1em;
    top: 50%;
    margin-top: 0.65rem;
    transform: rotate(220deg);
  }

  .positioned-right::after {
    left: 0em;
    top: 50%;
    margin-top: -0.75rem;
    transform: rotate(45deg);
  }

  .positioned-bottom::after {
    margin-left: 0.65em;
    top: 0em;
    left: 50%;
    transform: rotate(135deg);
    box-shadow: -2px 2px 2px 0 rgba(0, 0, 0, 0.06);
  }

  .positioned-top-left::after {
    margin-left: 0.95em;
    left: 0;
  }

  .positioned-top-right::after {
    margin-left: -2.35em;
    left: 100%;
  }

  .positioned-bottom-left::after {
    margin-left: 2.35em;
    left: 0;
  }

  .positioned-bottom-right::after {
    margin-left: -0.95em;
    left: 100%;
  }


  .positioned-left-top::after {
    top: 1.75em;
    right: -1em;
  }
  
  .positioned-left-bottom::after {
    top: 100%;
    right: -1em;
    margin-top: -0.95em;
  }

  .positioned-right-top::after {
    top: 1.75em;
    left: 0;
  }

  .positioned-right-bottom::after {
    top: 100%;
    margin-top: -2.35em;
    left: 0;
  }

  @media screen and (max-width: 767px) {
    .dropdown-menu__container--inner {
      padding: 8px 12px;
    }
  }
</style>
