<template>
  <div class="shared-view">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'BaseSharedView',
}
</script>
