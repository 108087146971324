var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isBrokerOrBypass && _vm.canEditAnmerkung
        ? _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c(
                  "BaseButton",
                  {
                    on: {
                      click: function($event) {
                        return _vm.openAddModal()
                      }
                    }
                  },
                  [_vm._v("Neue Anmerkung")]
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _vm.loading
              ? _c("GhostLoading", { attrs: { type: "table" } })
              : _vm.rows.length
              ? _c("Table", {
                  attrs: {
                    headers: _vm.headers,
                    rows: _vm.rows,
                    rowsPerPage: 10
                  },
                  on: {
                    "click-betreff": _vm.openEditModal,
                    "action-EDIT": _vm.openEditModal,
                    "action-DELETE": _vm.openDeleteModal
                  }
                })
              : _c("NoData")
          ],
          1
        )
      ]),
      _c("BaseModal", {
        ref: "anmerkungDataModal",
        attrs: {
          modalTitle: "Anmerkung editieren",
          size: "md",
          showDefaultButtons: false
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("DatePickerField", {
                          attrs: {
                            isValueAsString: "",
                            label: "Datum",
                            validateUntouched: "",
                            disabled:
                              !_vm.isBrokerOrBypass || !_vm.canEditAnmerkung
                          },
                          model: {
                            value: _vm.form.datum,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "datum", $$v)
                            },
                            expression: "form.datum"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("InputField", {
                          attrs: { label: "Erstellt am", disabled: true },
                          model: {
                            value: _vm.form.dateCreated,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "dateCreated", $$v)
                            },
                            expression: "form.dateCreated"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("InputField", {
                          attrs: {
                            label: "Zuletzt geändert am",
                            disabled: true
                          },
                          model: {
                            value: _vm.form.dateModified,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "dateModified", $$v)
                            },
                            expression: "form.dateModified"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("InputField", {
                          attrs: {
                            label: "Thema",
                            validateUntouched: "",
                            disabled:
                              !_vm.isBrokerOrBypass || !_vm.canEditAnmerkung
                          },
                          model: {
                            value: _vm.form.betreff,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "betreff", $$v)
                            },
                            expression: "form.betreff"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("InputTextArea", {
                          attrs: {
                            label: "Text",
                            rows: 5,
                            disabled:
                              !_vm.isBrokerOrBypass || !_vm.canEditAnmerkung
                          },
                          model: {
                            value: _vm.form.text,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "text", $$v)
                            },
                            expression: "form.text"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("InputRadioBoxGroup", {
                          attrs: {
                            title: "Sichtbar für",
                            values: _vm.radioboxGroupData,
                            disabled:
                              !_vm.isBrokerOrBypass || !_vm.canEditAnmerkung
                          },
                          model: {
                            value: _vm.form.sichtbar,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "sichtbar", $$v)
                            },
                            expression: "form.sichtbar"
                          }
                        })
                      ],
                      1
                    ),
                    _c("InputField", {
                      attrs: { label: "Ersteller", disabled: "" },
                      model: {
                        value: _vm.form.ersteller,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "ersteller", $$v)
                        },
                        expression: "form.ersteller"
                      }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c(
                  "BaseButton",
                  {
                    staticClass: "mr-3",
                    attrs: { isSecondary: "" },
                    on: {
                      click: function($event) {
                        return _vm.closeAnmerkungDataModal()
                      }
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.canEditAnmerkung ? "Abbrechen" : "Schließen")
                    )
                  ]
                ),
                _vm.isBrokerOrBypass && _vm.canEditAnmerkung
                  ? _c(
                      "BaseButton",
                      {
                        attrs: { disabled: _vm.validation.isInvalid("form") },
                        on: {
                          click: function($event) {
                            _vm.saveAndUpdate()
                            _vm.closeAnmerkungDataModal()
                          }
                        }
                      },
                      [_vm._v("Speichern")]
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("BaseModal", {
        ref: "anmerkungDeleteModal",
        attrs: {
          size: "sm",
          modalTitle: "Anmerkung löschen",
          labelButtonConfirm: "Anmerkungen löschen"
        },
        on: {
          onConfirmButton: function($event) {
            return _vm.deleteAnmerkung()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _vm._v(
                  " Soll die Anmerkung für den " +
                    _vm._s(_vm.anmerkungToDelete.datum) +
                    " wirklich gelöscht werden? "
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }