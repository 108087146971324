var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DragnDropArea",
    {
      attrs: {
        hoverText: "Datei hier ablegen",
        disabled: _vm.isSomethingLoading || !_vm.stepHasDocuments
      },
      on: {
        files: function($event) {
          return _vm.dropFiles($event)
        }
      }
    },
    [
      _c("GesellschaftTitleNavigation", {
        attrs: { headerActions: _vm.headerActions },
        on: {
          "action-CREATE_LESEZEICHEN": _vm.openCreateLesezeichenModal,
          "action-UPLOAD_DOCUMENT": _vm.uploadNewDocument
        }
      }),
      _c("StepperForm", {
        ref: "stepperForm",
        attrs: {
          stepType: "tab",
          stepperName: "customer-data",
          stepperMediator: _vm.tabsStepperMediator,
          hasFertig: false,
          selectedStepKey: _vm.currentStep
        },
        on: {
          "set-step": function($event) {
            return _vm.setStep($event)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "contentTemplate",
            fn: function() {
              return [
                _vm.loading.init
                  ? _c("GhostLoading", {
                      attrs: { useBoxContainer: "", type: "table" }
                    })
                  : _vm.currentStep === "INTERNETADRESSEN"
                  ? [
                      _c(
                        "div",
                        { staticClass: "box__container" },
                        [
                          _c("Table", {
                            attrs: {
                              title: "Internetadressen",
                              rowId: "urlKey",
                              noDataContent: "Keine Lesezeichen vorhanden",
                              headers: _vm.headers,
                              rows: _vm.internetAdressenRows,
                              rowsPerPage: 10
                            },
                            on: {
                              "action-EDIT": _vm.openEditLesezeichenModal,
                              "action-DELETE": function($event) {
                                return _vm.deleteLesezeichen(
                                  $event,
                                  "internetadressen"
                                )
                              }
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function(row) {
                                  return [
                                    row.url
                                      ? _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: row.url,
                                              target: "_blank",
                                              rel: "noopener noreferer"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(row.label || row.url) +
                                                " "
                                            )
                                          ]
                                        )
                                      : _c("span", [
                                          _vm._v(" " + _vm._s(row.label) + " ")
                                        ])
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ]
                  : _vm.currentStep === "FORMULARE"
                  ? [
                      _c(
                        "div",
                        { staticClass: "box__container" },
                        [
                          _c("Table", {
                            attrs: {
                              title: "Formulare",
                              rowId: "urlKey",
                              noDataContent: "Keine Lesezeichen vorhanden",
                              headers: _vm.headers,
                              rows: _vm.formulareRows,
                              rowsPerPage: 10
                            },
                            on: {
                              "action-EDIT": _vm.openEditLesezeichenModal,
                              "action-DELETE": function($event) {
                                return _vm.deleteLesezeichen(
                                  $event,
                                  "formulare"
                                )
                              }
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function(row) {
                                  return [
                                    row.url
                                      ? _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: row.url,
                                              target: "_blank",
                                              rel: "noopener noreferer"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(row.label || row.url) +
                                                " "
                                            )
                                          ]
                                        )
                                      : _c("span", [
                                          _vm._v(" " + _vm._s(row.label) + " ")
                                        ])
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "box__container" },
                        [
                          _c("Table", {
                            attrs: {
                              title: "Dokumente",
                              noDataContent: "Keine Dokumente vorhanden",
                              headers: _vm.documentHeaders,
                              rows: _vm.formulareDocumentRows,
                              rowsPerPage: 10
                            },
                            on: {
                              "action-EDIT": _vm.editDocument,
                              "action-DELETE": _vm.deleteDocument
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "bezeichnung",
                                fn: function(row) {
                                  return [
                                    _c("DownloadLink", {
                                      attrs: {
                                        title: row.bezeichnung,
                                        filename: row.bezeichnung,
                                        downloadServicePath: "/get_simple_file",
                                        queryParams: {
                                          fileId: row.id
                                        }
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ]
                  : _vm.currentStep === "INFOFILME"
                  ? [
                      _c(
                        "div",
                        { staticClass: "box__container" },
                        [
                          _c("Table", {
                            attrs: {
                              title: "Infofilme",
                              noDataContent: "Keine Lesezeichen vorhanden",
                              rowId: "urlKey",
                              headers: _vm.headers,
                              rows: _vm.videoRows,
                              rowsPerPage: 10
                            },
                            on: {
                              "action-EDIT": _vm.openEditLesezeichenModal,
                              "action-DELETE": function($event) {
                                return _vm.deleteLesezeichen(
                                  $event,
                                  "infofilme"
                                )
                              }
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "label",
                                fn: function(row) {
                                  return [
                                    row.url
                                      ? _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: row.url,
                                              target: "_blank",
                                              rel: "noopener noreferer"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(row.label || row.url) +
                                                " "
                                            )
                                          ]
                                        )
                                      : _c("span", [
                                          _vm._v(" " + _vm._s(row.label) + " ")
                                        ])
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "box__container" },
                        [
                          _c("Table", {
                            attrs: {
                              title: "Dokumente",
                              noDataContent: "Keine Filme vorhanden",
                              headers: _vm.documentHeaders,
                              rows: _vm.videoDocumentRows,
                              rowsPerPage: 10
                            },
                            on: {
                              "action-EDIT": _vm.editDocument,
                              "action-DELETE": _vm.deleteDocument
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "bezeichnung",
                                fn: function(row) {
                                  return [
                                    _c("DownloadLink", {
                                      attrs: {
                                        title: row.bezeichnung,
                                        filename: row.bezeichnung,
                                        downloadServicePath: "/get_simple_file",
                                        queryParams: {
                                          fileId: row.id
                                        }
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ]
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "BaseModal",
        {
          ref: "editLesezeichenModal",
          attrs: {
            modalTitle: _vm.editRow.urlKey
              ? "Lesezeichen bearbeiten"
              : "Lesezeichen anlegen",
            confirmDisabled: !_vm.editRow.url
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.saveLesezeichen(_vm.editRow)
            }
          }
        },
        [
          _c("InputField", {
            attrs: { label: "Bezeichnung" },
            model: {
              value: _vm.editRow.label,
              callback: function($$v) {
                _vm.$set(_vm.editRow, "label", $$v)
              },
              expression: "editRow.label"
            }
          }),
          _c("InputField", {
            attrs: { label: "Link", renderDanger: !_vm.editRow.url },
            model: {
              value: _vm.editRow.url,
              callback: function($$v) {
                _vm.$set(_vm.editRow, "url", $$v)
              },
              expression: "editRow.url"
            }
          })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "editSichtbarkeitModal",
          attrs: { modalTitle: "Formular sichtbar für" }
        },
        [
          _c("InputField", {
            attrs: { isComponentHalfSize: "" },
            model: {
              value: _vm.fileData.testing,
              callback: function($$v) {
                _vm.$set(_vm.fileData, "testing", $$v)
              },
              expression: "fileData.testing"
            }
          }),
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c("BaseButton", {
                attrs: {
                  label: "nicht sichtbar",
                  disabled: !_vm.fileData.testing
                },
                on: {
                  click: function($event) {
                    return _vm.addSichtbar(false)
                  }
                }
              }),
              _c("BaseButton", {
                attrs: {
                  label: "nur sichtbar",
                  disabled: !_vm.fileData.testing
                },
                on: {
                  click: function($event) {
                    return _vm.addSichtbar()
                  }
                }
              })
            ],
            1
          ),
          _c("Table", {
            attrs: {
              hidePagination: "",
              headers: _vm.sichtbarHeaders,
              rows: _vm.sichtbarRows,
              rowsPerPage: 5
            },
            on: { "action-DELETE": _vm.deleteDocument }
          })
        ],
        1
      ),
      _c(
        "DokumentUploadModal",
        {
          ref: "uploadModal",
          attrs: {
            disableMultifile: "",
            disableFotoUpload: "",
            confirmDisabled: !_vm.fileData.bezeichnung
          },
          on: { upload: _vm.uploadDocuments },
          model: {
            value: _vm.files,
            callback: function($$v) {
              _vm.files = $$v
            },
            expression: "files"
          }
        },
        [
          _c("InputField", {
            attrs: {
              label: "Bezeichnung",
              renderDanger: !_vm.fileData.bezeichnung
            },
            model: {
              value: _vm.fileData.bezeichnung,
              callback: function($$v) {
                _vm.$set(_vm.fileData, "bezeichnung", $$v)
              },
              expression: "fileData.bezeichnung"
            }
          }),
          _c("InputTextArea", {
            attrs: { label: "Bemerkung" },
            model: {
              value: _vm.fileData.bemerkung,
              callback: function($$v) {
                _vm.$set(_vm.fileData, "bemerkung", $$v)
              },
              expression: "fileData.bemerkung"
            }
          }),
          !_vm.isInfofilm
            ? _c("ComboBox", {
                attrs: {
                  label: "Kategorie",
                  sortComboboxValues: false,
                  values: _vm.comboboxSelections.kategorien
                },
                model: {
                  value: _vm.fileData.formulareKategorie,
                  callback: function($$v) {
                    _vm.$set(_vm.fileData, "formulareKategorie", $$v)
                  },
                  expression: "fileData.formulareKategorie"
                }
              })
            : _vm._e(),
          _c("DatePickerField", {
            attrs: { label: "Stand", isValueAsString: "" },
            model: {
              value: _vm.fileData.dateStand,
              callback: function($$v) {
                _vm.$set(_vm.fileData, "dateStand", $$v)
              },
              expression: "fileData.dateStand"
            }
          }),
          !_vm.isInfofilm
            ? _c("InputField", {
                attrs: { label: "Warnung" },
                model: {
                  value: _vm.fileData.warnung,
                  callback: function($$v) {
                    _vm.$set(_vm.fileData, "warnung", $$v)
                  },
                  expression: "fileData.warnung"
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }