var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "adddelete_container" }, [
    _vm.isBroker
      ? _c("div", { staticClass: "add_delete_buttons" }, [
          _c(
            "div",
            { staticClass: "adder", on: { click: _vm.add } },
            [
              _c("ph-plus-circle", { attrs: { title: "Hinzufügen", size: 16 } })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "remover", on: { click: _vm.remove } },
            [_c("ph-trash", { attrs: { title: "Löschen", size: 16 } })],
            1
          )
        ])
      : _vm._e(),
    _c(
      "div",
      { class: { adddelete_content: _vm.isBroker } },
      [_vm._t("default")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }