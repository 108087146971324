var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.tableData && _vm.headers
    ? _c(
        "div",
        {
          ref: "tableContainer",
          staticClass: "table-container",
          attrs: { id: _vm.componentId }
        },
        [
          _vm.cardViewEnabled
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c(
                    "div",
                    { staticClass: "mode-selector" },
                    [
                      _c(
                        "BaseButton",
                        {
                          staticClass: "mode-selector-item",
                          attrs: { isPrimary: _vm.activeView === "list" },
                          on: {
                            click: function($event) {
                              return _vm.setActiveView("list")
                            }
                          }
                        },
                        [_c("ph-text-align-justify", { attrs: { size: 16 } })],
                        1
                      ),
                      _c(
                        "BaseButton",
                        {
                          staticClass: "mode-selector-item",
                          attrs: { isPrimary: _vm.activeView === "cards" },
                          on: {
                            click: function($event) {
                              return _vm.setActiveView("cards")
                            }
                          }
                        },
                        [_c("ph-table", { attrs: { size: 16 } })],
                        1
                      )
                    ],
                    1
                  )
                ])
              ])
            : _vm._e(),
          _vm.activeView === "list"
            ? _c("div", [
                _c("div", { staticClass: "table--header" }, [
                  _c("div", { staticClass: "box__title" }, [
                    _vm._v(_vm._s(_vm.title))
                  ]),
                  _vm.showOpenColumnsConfig ||
                  _vm.showExport ||
                  (_vm.paginationEnabled && _vm.rows.length > 10)
                    ? _c(
                        "div",
                        { staticClass: "export-links-align-right" },
                        [
                          _c(
                            "BaseContextMenu",
                            [
                              _vm.showOpenColumnsConfig
                                ? _c(
                                    "ContextMenuGroup",
                                    [
                                      _c(
                                        "ContextMenuItem",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.openColumnsConfig()
                                            }
                                          }
                                        },
                                        [_vm._v("Spalten anpassen")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.showExport
                                ? _c(
                                    "ContextMenuGroup",
                                    [
                                      _vm.exportTbl.pdf.show
                                        ? _c(
                                            "ContextMenuItem",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.exportPDF()
                                                }
                                              }
                                            },
                                            [_c("span", [_vm._v("PDF")])]
                                          )
                                        : _vm._e(),
                                      _vm.exportTbl.xls.show
                                        ? _c(
                                            "ContextMenuItem",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.exportTableToExcel()
                                                }
                                              }
                                            },
                                            [_c("span", [_vm._v("Excel")])]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "ContextMenuGroup",
                                [
                                  _vm._l(_vm.menuRowsPerPage, function(item) {
                                    return [
                                      _c(
                                        "ContextMenuItem",
                                        { key: item.value },
                                        [
                                          item.value == _vm.pageSize
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "mr-1 menu-disabled"
                                                },
                                                [
                                                  _c("PhList", {
                                                    staticClass: "mr-1",
                                                    attrs: { size: 16 }
                                                  }),
                                                  _c("span", [
                                                    _vm._v(_vm._s(item.label))
                                                  ])
                                                ],
                                                1
                                              )
                                            : _c(
                                                "div",
                                                {
                                                  staticClass: "mr-1 clickable",
                                                  on: {
                                                    click: function($event) {
                                                      _vm.tablePageSize =
                                                        item.value
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("PhList", {
                                                    staticClass: "mr-1",
                                                    attrs: { size: 16 }
                                                  }),
                                                  _c("span", [
                                                    _vm._v(_vm._s(item.label))
                                                  ])
                                                ],
                                                1
                                              )
                                        ]
                                      )
                                    ]
                                  })
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]),
                _vm.allowColumnsPriorities
                  ? _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-auto" },
                        [
                          _c(
                            "BaseButton",
                            {
                              attrs: { "is-secondary": "" },
                              on: {
                                click: function($event) {
                                  return _vm.openColumnsConfig()
                                }
                              }
                            },
                            [_c("ph-sort-ascending", { attrs: { size: 16 } })],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm.filterEnabled
                  ? _c("div", { staticClass: "row mb-0" }, [
                      _c(
                        "div",
                        { staticClass: "col-12 col-sm-6" },
                        [
                          _c("InputField", {
                            staticClass: "table-header__input-search mb-2",
                            attrs: { placeholder: _vm.searchPlaceHolder },
                            model: {
                              value: _vm.searchValue,
                              callback: function($$v) {
                                _vm.searchValue = $$v
                              },
                              expression: "searchValue"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _c(
                  "table",
                  {
                    ref: "table",
                    staticClass: "table__table-list",
                    attrs: { id: _vm._f("lowercase")(_vm.title) }
                  },
                  [
                    _c(
                      "thead",
                      [
                        _c("th", {
                          staticClass: "table__control-col",
                          class: { hide: !_vm.getIsSomeExpanded },
                          attrs: { "data-table-col-key": "collapse" }
                        }),
                        _vm.showCheckBoxBulkAction
                          ? _c(
                              "th",
                              {
                                staticClass: "table__control-col",
                                attrs: {
                                  "data-table-col-key": "checkbox",
                                  "data-col-checkbox": ""
                                }
                              },
                              [
                                _c("InputCheckBoxItem", {
                                  staticClass: "my-0",
                                  attrs: {
                                    value: _vm.isAllRowsSelected,
                                    indeterminate:
                                      !_vm.isAllRowsSelected &&
                                      _vm.hasSomeRowsSelected
                                  },
                                  on: {
                                    input: function($event) {
                                      return _vm.toggleSelectAllRows($event)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._l(_vm.headers, function(col, key, index) {
                          return [
                            col.visible
                              ? [
                                  _c(
                                    "th",
                                    {
                                      key: key + index,
                                      class: {
                                        hide: _vm.headersHiddenSmallScreen[key],
                                        "actions-column": col.key === "actions"
                                      },
                                      attrs: {
                                        "data-table-col-key": key,
                                        "data-col-checkbox":
                                          col.dataType === "Boolean"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          class: {
                                            "tab-head-cell":
                                              col.key !== "actions",
                                            "text-right":
                                              col.align === "right" ||
                                              col.dataType === "Currency" ||
                                              col.dataType === "Procent",
                                            "text-left":
                                              col.align !== "right" &&
                                              (col.dataType === "String" ||
                                                col.dataType === "Slot")
                                          },
                                          style: _vm.columnStyle(key)
                                        },
                                        [
                                          col.dataType === "Boolean"
                                            ? [
                                                _c("InputCheckBoxItem", {
                                                  staticClass:
                                                    "my-0 table__control-col",
                                                  attrs: {
                                                    value: _vm.isAllRowsSelectedByKey(
                                                      key
                                                    ),
                                                    indeterminate:
                                                      !_vm.isAllRowsSelectedByKey(
                                                        key
                                                      ) &&
                                                      _vm.hasSomeRowsSelectedByKey(
                                                        key
                                                      )
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      return _vm.toggleSelectAllRowsByKey(
                                                        key,
                                                        $event
                                                      )
                                                    }
                                                  }
                                                })
                                              ]
                                            : _vm._e(),
                                          col.label === "_slot"
                                            ? [
                                                _vm._t("_" + key, null, {
                                                  data: { col: col }
                                                })
                                              ]
                                            : [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "table__table-th-labels mr-1",
                                                    class: {
                                                      clickable:
                                                        col.key !== "actions" &&
                                                        col.sortable
                                                    },
                                                    attrs: {
                                                      tid:
                                                        col && col.key
                                                          ? _vm._generateTidFromString(
                                                              col.key
                                                            )
                                                          : _vm._generateTidFromString(
                                                              col.label
                                                            )
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.toggleSort(
                                                          col.sortKey || key,
                                                          col.sortable
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(col.label) +
                                                        " "
                                                    )
                                                  ]
                                                ),
                                                col.key !== "actions"
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "table__control-icon"
                                                      },
                                                      [
                                                        _c("ph-caret-up", {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value: _vm.showArrowUp(
                                                                col.sortKey ||
                                                                  key
                                                              ),
                                                              expression:
                                                                "showArrowUp(col.sortKey || key)"
                                                            }
                                                          ],
                                                          attrs: { size: 16 }
                                                        }),
                                                        _c("ph-caret-down", {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value: _vm.showArrowDown(
                                                                col.sortKey ||
                                                                  key
                                                              ),
                                                              expression:
                                                                "showArrowDown(col.sortKey || key)"
                                                            }
                                                          ],
                                                          attrs: { size: 16 }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ]
                                        ],
                                        2
                                      )
                                    ]
                                  )
                                ]
                              : _vm._e()
                          ]
                        })
                      ],
                      2
                    ),
                    _c(
                      "tbody",
                      [
                        _vm._l(_vm.currentPageData, function(row, index) {
                          return [
                            _c(
                              "DragnDropArea",
                              {
                                key: index,
                                style: _vm.rowStyle(row),
                                attrs: {
                                  id: "tr" + _vm.componentId + index,
                                  element: "tr",
                                  hoverText: "Datei hier ablegen",
                                  disabled: !_vm.dragnDropFilesOnRow
                                },
                                on: {
                                  files: function($event) {
                                    return _vm.handleRowFilesDropped(
                                      row,
                                      $event
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "td",
                                  {
                                    staticClass: "clickable table__control-col",
                                    class: { hide: !_vm.getIsSomeExpanded },
                                    attrs: { "data-table-col-key": "collapse" }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "table__control-icon" },
                                      [
                                        !_vm.isExpanded(index)
                                          ? _c("ph-caret-right", {
                                              attrs: { size: 16 },
                                              on: {
                                                click: function($event) {
                                                  return _vm.collapse(
                                                    _vm.collapsedItems,
                                                    index
                                                  )
                                                }
                                              }
                                            })
                                          : _vm._e(),
                                        _vm.isExpanded(index)
                                          ? _c("ph-caret-down", {
                                              attrs: { size: 16 },
                                              on: {
                                                click: function($event) {
                                                  return _vm.collapse(
                                                    _vm.collapsedItems,
                                                    index
                                                  )
                                                }
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _vm.showCheckBoxBulkAction
                                  ? _c(
                                      "td",
                                      {
                                        staticClass: "table__control-col",
                                        attrs: {
                                          "data-table-col-key": "checkbox"
                                        }
                                      },
                                      [
                                        (!_vm.listEnabledCheckBoxBulkAction ||
                                          (_vm.listEnabledCheckBoxBulkAction &&
                                            _vm.listEnabledCheckBoxBulkAction[
                                              row.id
                                            ])) &&
                                        !row.customCheckBoxDisabled
                                          ? [
                                              _c("InputCheckBoxItem", {
                                                staticClass: "my-0",
                                                on: {
                                                  input: function($event) {
                                                    return _vm.onChangeSelected(
                                                      $event,
                                                      index +
                                                        _vm.selectRowIndexPadding,
                                                      row
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value:
                                                    _vm.selectedRows[
                                                      index +
                                                        _vm.selectRowIndexPadding
                                                    ],
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.selectedRows,
                                                      index +
                                                        _vm.selectRowIndexPadding,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "selectedRows[index+selectRowIndexPadding]"
                                                }
                                              })
                                            ]
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _vm._l(_vm.headers, function(col, key, hIndex) {
                                  return [
                                    col.visible
                                      ? [
                                          _c(
                                            "td",
                                            {
                                              key: key + "-" + hIndex,
                                              staticClass: "tab-rows-cell",
                                              class: {
                                                hide:
                                                  _vm.headersHiddenSmallScreen[
                                                    key
                                                  ]
                                              },
                                              attrs: {
                                                valign: _vm.valign,
                                                "data-table-col-key": key,
                                                "data-label":
                                                  _vm.headers[key] &&
                                                  _vm.headers[key].label
                                              }
                                            },
                                            [
                                              _c(
                                                "Cell",
                                                {
                                                  style: _vm.columnStyle(key),
                                                  attrs: {
                                                    content: _vm.rowContent(
                                                      row,
                                                      key,
                                                      index +
                                                        _vm.selectRowIndexPadding
                                                    ),
                                                    header: _vm.headers[key],
                                                    tableData: _vm.tableData,
                                                    row: row,
                                                    actions: _vm.actions.length
                                                      ? _vm.actions
                                                      : row["actions"],
                                                    rowIndex: index
                                                  },
                                                  on: {
                                                    "execute-action":
                                                      _vm.clickAction,
                                                    "selected-cell": function(
                                                      $event
                                                    ) {
                                                      return _vm.selectedRowKey(
                                                        row,
                                                        index +
                                                          _vm.selectRowIndexPadding,
                                                        key,
                                                        $event
                                                      )
                                                    },
                                                    input: function($event) {
                                                      return _vm.$emit(
                                                        "input",
                                                        {
                                                          event: $event,
                                                          row: row,
                                                          key: key,
                                                          index: index
                                                        }
                                                      )
                                                    }
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      _vm.headers[key]
                                                        .dataType === "Slot"
                                                        ? {
                                                            key: key,
                                                            fn: function() {
                                                              return [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "tab-rows-cell",
                                                                    class: [
                                                                      _vm
                                                                        .headers[
                                                                        key
                                                                      ]
                                                                        .align ===
                                                                      "right"
                                                                        ? "text-right"
                                                                        : "text-left"
                                                                    ]
                                                                  },
                                                                  [
                                                                    _vm._t(
                                                                      key,
                                                                      null,
                                                                      {
                                                                        data: {
                                                                          tableData:
                                                                            _vm.tableData,
                                                                          row: row,
                                                                          index: index
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  2
                                                                )
                                                              ]
                                                            },
                                                            proxy: true
                                                          }
                                                        : null
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                },
                                                [
                                                  !_vm.actions.length
                                                    ? _vm._t("action", null, {
                                                        data: {
                                                          content: row[key],
                                                          key: key,
                                                          row: row
                                                        }
                                                      })
                                                    : _vm._e()
                                                ],
                                                2
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      : _vm._e()
                                  ]
                                })
                              ],
                              2
                            ),
                            _vm.isExpanded(index) && _vm.getIsSomeExpanded
                              ? _c("tr", { key: "collapse" + index }, [
                                  _c(
                                    "td",
                                    {
                                      attrs: { colspan: _vm.calculateColspan() }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "collapsed collapsed--col-sm-2 collapsed--col-md-3 collapsed--col-lg-4"
                                        },
                                        [
                                          _vm._l(_vm.headers, function(
                                            col,
                                            key,
                                            colIndex
                                          ) {
                                            return [
                                              col.visible &&
                                              _vm.headersHiddenSmallScreen[
                                                key
                                              ] &&
                                              (_vm.showIds ||
                                                (col.key !== "nodeId" &&
                                                  col.key !== "id"))
                                                ? [
                                                    _c(
                                                      "div",
                                                      {
                                                        key: colIndex,
                                                        staticClass: "card-row"
                                                      },
                                                      [
                                                        _c(
                                                          "CardLabel",
                                                          {
                                                            attrs: {
                                                              label: col.label
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "Cell",
                                                              {
                                                                attrs: {
                                                                  content:
                                                                    row[key],
                                                                  header: col,
                                                                  keepTextLeft: true,
                                                                  actions: _vm
                                                                    .actions
                                                                    .length
                                                                    ? _vm.actions
                                                                    : row[
                                                                        "actions"
                                                                      ]
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    _vm.headers[
                                                                      key
                                                                    ]
                                                                      .dataType ===
                                                                    "Slot"
                                                                      ? {
                                                                          key: key,
                                                                          fn: function() {
                                                                            return [
                                                                              _vm._t(
                                                                                key,
                                                                                null,
                                                                                {
                                                                                  data: {
                                                                                    tableData:
                                                                                      _vm.tableData,
                                                                                    row: row,
                                                                                    colIndex: colIndex
                                                                                  }
                                                                                }
                                                                              )
                                                                            ]
                                                                          },
                                                                          proxy: true
                                                                        }
                                                                      : null
                                                                  ],
                                                                  null,
                                                                  true
                                                                )
                                                              },
                                                              [
                                                                !_vm.actions
                                                                  .length
                                                                  ? _vm._t(
                                                                      "action",
                                                                      null,
                                                                      {
                                                                        data: {
                                                                          content:
                                                                            row[
                                                                              key
                                                                            ],
                                                                          key: key,
                                                                          row: row
                                                                        }
                                                                      }
                                                                    )
                                                                  : _vm._e()
                                                              ],
                                                              2
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                : _vm._e()
                                            ]
                                          }),
                                          _vm._t("additionalCollapsed", null, {
                                            data: {
                                              tableData: _vm.tableData,
                                              row: row,
                                              index: index
                                            }
                                          })
                                        ],
                                        2
                                      )
                                    ]
                                  )
                                ])
                              : _vm._e()
                          ]
                        })
                      ],
                      2
                    ),
                    _vm.getIsSomeSum || _vm.getIsSomeAutoSum
                      ? _c("tfoot", [
                          _c(
                            "tr",
                            { staticClass: "sum" },
                            [
                              _vm.getIsSomeExpanded
                                ? _c("td", {
                                    staticClass: "table__control-col"
                                  })
                                : _vm._e(),
                              _vm.showCheckBoxBulkAction
                                ? _c("td", {
                                    staticClass: "table__control-col"
                                  })
                                : _vm._e(),
                              _vm._l(_vm.headers, function(item, key, index) {
                                return [
                                  item.visible
                                    ? _c(
                                        "td",
                                        {
                                          key: index,
                                          class: {
                                            hide:
                                              _vm.headersHiddenSmallScreen[key]
                                          },
                                          attrs: {
                                            "data-label":
                                              _vm.headers[key] &&
                                              _vm.headers[key].label
                                          }
                                        },
                                        [
                                          _vm.getIsSum(key)
                                            ? _c(
                                                "span",
                                                { staticClass: "no-wrap" },
                                                [
                                                  _c("Cell", {
                                                    attrs: {
                                                      content: +_vm.getSum(key),
                                                      header: _vm.headers[key],
                                                      tableData: _vm.tableData,
                                                      row: {}
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.getIsAutoSum(key)
                                            ? _c(
                                                "span",
                                                { staticClass: "no-wrap" },
                                                [
                                                  _c("Cell", {
                                                    attrs: {
                                                      content: _vm.getAutoSum(
                                                        key
                                                      ),
                                                      header: _vm.headers[key],
                                                      tableData: _vm.tableData,
                                                      row: {}
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              })
                            ],
                            2
                          )
                        ])
                      : _vm._e()
                  ]
                ),
                _vm.paginationEnabled
                  ? _c(
                      "div",
                      { staticClass: "table-footer" },
                      [
                        _c("Pagination", {
                          attrs: {
                            totalPages: Math.round(_vm.pagesNumber),
                            total: _vm.count || _vm.rows.length,
                            perPage: _vm.pageSize,
                            displayedItens: _vm.displayedItens,
                            currentPage: _vm.currentPageIndex
                          },
                          on: { pagechanged: _vm.onPageChange }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          _c("ModalColumnConfig", {
            ref: "columnsConfig",
            attrs: {
              title: _vm.configModalTitle,
              tableHeaders: _vm.tableHeadersList,
              lockedColumns: _vm.lockedColumns
            },
            on: {
              orderChanged: _vm.setConfiguredHeaders,
              onFinishConfig: _vm.onFinishColumnsConfig,
              onRestoreDefault: _vm.restoreDefaultColumnConfiguration
            }
          }),
          _c(
            "BaseModal",
            {
              ref: "modalDownloadPin",
              attrs: {
                modalTitle: "Download PIN geschützte Datei",
                showConfirmButton: true,
                labelButtonConfirm: "Herunterladen",
                labelButtonCancel: "Abbrechen",
                autoClose: false
              },
              on: {
                onConfirmButton: _vm.downloadPinFile,
                close: function() {
                  this$1.tempFilePin = ""
                  this$1.errorPinFile = ""
                }
              }
            },
            [
              _c(
                "div",
                [
                  _c("InputField", {
                    attrs: {
                      label: "Bitte geben Sie die PIN ein",
                      placeholder: "Pin"
                    },
                    model: {
                      value: _vm.tempFilePin,
                      callback: function($$v) {
                        _vm.tempFilePin = $$v
                      },
                      expression: "tempFilePin"
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "fc-form-danger" }, [
                _vm._v(" " + _vm._s(_vm.errorPinFile) + " ")
              ])
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }