import actions from './actions';
import mutations from './mutations';
import getters from './getters';

/**
 * mutations: synchronous operation responsible for change the state
 * actions: asynchronous operation responsible for call external resources and commit mutations 
 */

export function getInitialState() {
  return {
    stoppuhr: {},
    isStoppuhrRunning: false,
    stoppuhrHour: "00",
    stoppuhrMinutes: "00",
    stoppuhSeconds: "00",
    stoppuhrForm: {},
    stoppuhrIntervalId: null,
    timeLapsed: "",
    stoppuhrStartTime: 0,
    currentInterval: {},
    listInterval : [],
  }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}