var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.rows.length
        ? _c("Table", {
            attrs: {
              tableId: "d7c41d19-545f-47ff-81a5-418d5176e3af",
              title: _vm.title,
              headers: _vm.headers,
              rows: _vm.rows,
              rowsPerPage: 10,
              rowId: "fileId"
            },
            on: { "action-DELETE": _vm.deleteFile },
            scopedSlots: _vm._u(
              [
                {
                  key: "bezeichnung",
                  fn: function(row) {
                    return [
                      _c("DownloadLink", {
                        attrs: {
                          target: "_blank",
                          rel: "noopener noreferer",
                          title: row.bezeichnung || row.filename || "Unbekannt",
                          filename:
                            row.bezeichnung || row.filename || "Unbekannt",
                          downloadServicePath: "/get_simple_file",
                          queryParams: {
                            fileId: row.fileId
                          }
                        }
                      })
                    ]
                  }
                }
              ],
              null,
              false,
              1399512371
            )
          })
        : _c("NoData", {
            attrs: {
              noIcon: "",
              content: "Keine Anhänge vorhanden",
              title: _vm.title
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }