var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-container" },
    [
      !_vm.$isSmallScreen || !_vm.mobileConfig
        ? [
            _c("SortableTable", {
              ref: "sortableTable",
              attrs: {
                cardView: _vm.cardView,
                headersSize: _vm.headersSize,
                showWeiterLink: _vm.showWeiterLink,
                tableId: _vm.tableId,
                title: _vm.title,
                headers: _vm.headerMap,
                rows: _vm.rowsWithId,
                enableToggleHorizontalScrollingConfig:
                  _vm.enableToggleHorizontalScrollingConfig,
                scrollHorizontally: _vm.scrollHorizontally,
                pageSize: _vm.pageSize,
                page: _vm.pageIndex,
                exportConfig: _vm.exportConfig,
                maxHeaderLines: _vm.maxHeaderLines,
                headerActions: _vm.headerActions,
                sortableRows: _vm.sortableRows,
                showColumnsConfigEvenNoTitle: _vm.showColumnsConfigEvenNoTitle,
                thHoverDisabled: _vm.thHoverDisabled,
                noDataIcon: _vm.noDataIcon,
                noDataContent: _vm.noDataContent
              },
              on: {
                clickCell: _vm.handleClick,
                action: _vm.handleAction,
                files: _vm.handleFile,
                headerAction: _vm.handleHeaderAction,
                configColumn: _vm.columnConfigChanged,
                orderChanged: function($event) {
                  return _vm.$emit("orderChanged", $event)
                },
                onNavigate: function($event) {
                  return _vm.$emit("onNavigate", $event)
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "customTableTitle",
                    fn: function() {
                      return [_vm._t("customTableTitle")]
                    },
                    proxy: true
                  },
                  _vm.menuRowsPerPage.length && !_vm.ignoreTablePageSize
                    ? {
                        key: "dropdown_menu",
                        fn: function() {
                          return [
                            _c("SelectRowPerPage", {
                              attrs: {
                                pageSize: _vm.pageSize,
                                rowSteps: _vm.menuRowsPerPage
                              },
                              on: { tablePageSize: _vm.onPageSizeChange }
                            })
                          ]
                        },
                        proxy: true
                      }
                    : null,
                  {
                    key: "header___SELECT__",
                    fn: function() {
                      return [
                        _c("InputCheckBoxItem", {
                          staticStyle: { "margin-left": "3px" },
                          attrs: {
                            value: _vm.areAllSelected,
                            indeterminate: _vm.areOnlySomeSelected,
                            disabled: _vm.areAllHiddenDisabled,
                            tid: _vm._generateTidFromString(
                              _vm.tableId + ":__SELECT__"
                            )
                          },
                          on: { input: _vm.onSelectAll }
                        })
                      ]
                    },
                    proxy: true
                  },
                  _vm._l(_vm.headersFlat, function(ref) {
                    var key = ref.key
                    var rowElement = ref.rowElement
                    return {
                      key: key,
                      fn: function(ref) {
                        var row = ref.row
                        var id = ref.id
                        return [
                          !rowElement &&
                          key == "__SELECT__" &&
                          id != null &&
                          !row.selectedHidden
                            ? _c("InputCheckBoxItem", {
                                key: key,
                                attrs: {
                                  disabled: row.selectedDisabled,
                                  value: _vm.isSelected[id],
                                  tid: _vm._generateTidFromString(
                                    _vm.tableId + ":__SELECT__:" + id
                                  )
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onSelect(id)
                                  }
                                }
                              })
                            : !rowElement
                            ? _vm._t(key, null, null, row)
                            : _vm._e()
                        ]
                      }
                    }
                  }),
                  _vm._l(_vm.headersFlatLabelNull, function(header) {
                    return {
                      key: header.headerKey,
                      fn: function() {
                        return [_vm._t(header.headerKey, null, null, header)]
                      },
                      proxy: true
                    }
                  }),
                  _vm._l(_vm.headerActionsSlot, function(hAction) {
                    return {
                      key: hAction.actionKey,
                      fn: function() {
                        return [_vm._t(hAction.actionKey, null, null, hAction)]
                      },
                      proxy: true
                    }
                  })
                ],
                null,
                true
              )
            }),
            !_vm.hidePagination && _vm.displayedRowCount > 0
              ? _c("div", { staticClass: "table-pagination" }, [
                  _c(
                    "div",
                    { staticClass: "table-pagination--left" },
                    [
                      _c("PageRowCount", {
                        attrs: {
                          displayedItems: _vm.displayedRowCount,
                          totalRows: _vm.rows.length
                        }
                      }),
                      !_vm.ignoreTablePageSize
                        ? _c("SelectRowPerPage", {
                            attrs: {
                              short: "",
                              pageSize: _vm.pageSize,
                              rowSteps: _vm.menuRowsPerPage
                            },
                            on: { tablePageSize: _vm.onPageSizeChange }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.displayedRowCount < _vm.rows.length
                    ? _c(
                        "div",
                        { staticClass: "table-pagination--right" },
                        [
                          _c("Pagination", {
                            attrs: {
                              totalPages: _vm.pageCount,
                              currentPage: _vm.pageIndex
                            },
                            on: { pagechanged: _vm.onPageChange }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ])
              : _vm._e()
          ]
        : [
            _c(
              "MobileTable",
              _vm._g(
                {
                  ref: "sortableTable",
                  attrs: {
                    tableId: _vm.tableId,
                    rowId: _vm.rowId,
                    title: _vm.title,
                    headers: _vm.headerMap,
                    rows: _vm.rowsWithId,
                    titleActions: _vm.headerActions,
                    config: _vm.mobileConfig,
                    selected: _vm.selected,
                    noDataIcon: _vm.noDataIcon,
                    noDataContent: _vm.noDataContent
                  },
                  on: {
                    selected: function($event) {
                      return _vm.$emit("selected", $event)
                    },
                    clickCell: _vm.handleClick,
                    action: _vm.handleAction,
                    files: _vm.handleFile,
                    headerAction: _vm.handleHeaderAction,
                    configColumn: _vm.columnConfigChanged,
                    orderChanged: function($event) {
                      return _vm.$emit("orderChanged", $event)
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      _vm._l(_vm.headersFlat, function(ref) {
                        var key = ref.key
                        var rowElement = ref.rowElement
                        return {
                          key: key,
                          fn: function(ref) {
                            var row = ref.row
                            var mobileTableContext = ref.mobileTableContext
                            return [
                              !rowElement && key !== "__SELECT__"
                                ? _vm._t(
                                    key,
                                    null,
                                    { mobileTableContext: mobileTableContext },
                                    row
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      }),
                      _vm._l(_vm.headersFlatLabelNull, function(header) {
                        return {
                          key: header.headerKey,
                          fn: function() {
                            return [
                              _vm._t(header.headerKey, null, null, header)
                            ]
                          },
                          proxy: true
                        }
                      })
                    ],
                    null,
                    true
                  )
                },
                _vm.$listeners["clickRow"]
                  ? {
                      clickRow: function(event) {
                        return _vm.$emit("clickRow", event)
                      }
                    }
                  : {}
              )
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }