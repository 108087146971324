var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "modal",
    attrs: { size: "lg" },
    on: {
      onConfirmButton: function($event) {
        return _vm.add()
      },
      close: function($event) {
        return _vm.onClose()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "modalTitle",
        fn: function() {
          return [_vm._v(" neuer Parameter ")]
        },
        proxy: true
      },
      {
        key: "default",
        fn: function() {
          return [
            _c("ComboBox", {
              attrs: {
                label: "verfügbare Einstellungen",
                values: _vm.configsAvailableValues
              },
              model: {
                value: _vm.selectedConfigsAvailableValue,
                callback: function($$v) {
                  _vm.selectedConfigsAvailableValue = $$v
                },
                expression: "selectedConfigsAvailableValue"
              }
            }),
            _vm.selectedConfigsAvailable
              ? [
                  _c("div", {
                    staticClass: "box__title",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.sanitize(_vm.selectedConfigsAvailable.label)
                      )
                    }
                  }),
                  _c("InputField", {
                    attrs: { placeholder: "Filter..." },
                    model: {
                      value: _vm.searchKeyword,
                      callback: function($$v) {
                        _vm.searchKeyword = $$v
                      },
                      expression: "searchKeyword"
                    }
                  }),
                  _vm.selectedConfigsAvailableComponents.length
                    ? _c(
                        "div",
                        {
                          key: _vm.selectedConfigsAvailableValue,
                          staticClass: "config-components"
                        },
                        _vm._l(_vm.selectedConfigsAvailableComponents, function(
                          component,
                          index
                        ) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "config-component--item"
                            },
                            [
                              _c("InputToggleSwitch", {
                                staticClass: "config-component--item-input",
                                attrs: {
                                  label: component.label,
                                  inLineLabel: "",
                                  disabled: _vm.isAlreadySelected(component.id)
                                },
                                model: {
                                  value:
                                    _vm.selectedComponentsToAdd[component.id],
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.selectedComponentsToAdd,
                                      component.id,
                                      $$v
                                    )
                                  },
                                  expression:
                                    "selectedComponentsToAdd[component.id]"
                                }
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "config-component--item-details"
                                },
                                [
                                  component.usage && component.usage.length
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "config-component--item-used"
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "config-component--item-used--label"
                                            },
                                            [_vm._v("Used in:")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "config-component--item-used--places"
                                            },
                                            _vm._l(component.usage, function(
                                              place,
                                              index
                                            ) {
                                              return _c("Pill", {
                                                key: index,
                                                staticClass:
                                                  "config-component--item-used--place",
                                                attrs: {
                                                  label:
                                                    place.groupLabel +
                                                    " > " +
                                                    place.stepLabel,
                                                  type: "default"
                                                }
                                              })
                                            }),
                                            1
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _c("NoData", { attrs: { noIcon: "" } })
                ]
              : _vm._e()
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }