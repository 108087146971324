var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("MultiBankingNavigation", {
        attrs: { subtitle: "Einnahmen & Ausgaben" }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.filteredAccounts && _vm.filteredAccounts.length
            ? _c(
                "div",
                [
                  _c("BarChart", {
                    attrs: {
                      labels: _vm.chartLabels,
                      chartSeries: _vm.chartSeries,
                      height: "400px"
                    }
                  }),
                  _c("div", {
                    staticClass: "kontoverwaltung-account__separator"
                  }),
                  _vm.filteredAccounts.length
                    ? _c("KontoverwaltungAccountTable", {
                        attrs: {
                          accounts: _vm.filteredAccounts,
                          useBoxContainer: false
                        },
                        model: {
                          value: _vm.selectedAccounts,
                          callback: function($$v) {
                            _vm.selectedAccounts = $$v
                          },
                          expression: "selectedAccounts"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _c("NoData")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }