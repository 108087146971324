var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "modal",
    attrs: {
      modalTitle: "Makler Kriterien auswählen",
      labelButtonConfirm: "Neue markierte hinzufügen",
      labelButtonCancel: "Abbrechen"
    },
    on: { onConfirmButton: _vm.emitSave },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function() {
          return [
            _vm.rows.length
              ? _c("Table", {
                  attrs: {
                    tableId: "579b1982-470b-4522-8482-1246152081a5",
                    rowId: "label",
                    headers: _vm.headers,
                    rows: _vm.rows,
                    rowsPerPage: _vm.rows.length,
                    selected: _vm.selectedRows,
                    hidePagination: ""
                  },
                  on: { selected: _vm.handleSelected }
                })
              : _c("NoData", { attrs: { noIcon: "" } })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }