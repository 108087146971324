<template>
  <div>
    <div class="box__container" v-if="!brokerData.zugriffCourtage || isBrokerNoBypass">Keine Daten</div>
    <div
      class="box__container"
      v-if="
        brokerData.istMaklerMitVertrag || isIntern || isBrokerBypass"
    >
      <div>Servicegebühr</div>
      <InputRadioBoxGroup
        title="Abrechnungsmethode"
        :values="abrechnungServiceGebuehrValues"
        v-model="brokerData.abrechnungServiceGebuehr"
        @input="addBrokerDataEdited('brokerData')"
      />
    </div>
    <div
      class="box__container"
      v-if="isBrokerBypass && brokerData.isUntermaklerDesktop"
    >
      <p>
        Wünschen Sie für Ihren Mitarbeiter einen Direktvertrag mit der
        FondsKonzept Investmentmakler GmbH, dann bitten wir Sie, eine Nachricht
        an
        <a :href="link">vertrieb@fondskonzept.ag</a>
        zu senden. Bitte beachten Sie, dass Direktverträge erst ab einem
        Courtagesatz von 90% vom Ausgabeaufschlag und 80% der
        FK-Bestandscourtage erstellt werden können.
      </p>
    </div>
    <div
      v-if="
        brokerData.hatRechteCourtage &&
        (isIntern || brokerData.isUntermaklerDesktop || isBrokerBypass)
      "
    >    

      <div>  
        <div>
          <BaseButton v-if="isBrokerBypass || brokerData.isUntermaklerDesktop" @click="wechselStrukturAbrechnung"
            >Wechsel zu manueller Strukturabrechnung</BaseButton
          >
        </div>     

        <div class="box__container" v-if="brokerData.isUntermaklerDesktop">
          <p>
            Bitte tragen Sie hier den Prozentsatz ein den Ihr Mitarbeiter von
            Ihrer Courtage erhalten soll.
          </p>
          <p>
            Beispiel: Ihre Courtage: 5,00% Mitarbeiter soll 4,5% erhalten.
            Eintrag bei Courtage Abschluss: 90%
          </p>
          <p>
          Bitte beachten Sie, dass die angegebenen Prozentwerte immer in Relation zu Ihren Courtagesätzen sind und nicht zum direkt übergeordneten Vermittler.
          </p>
        </div>
        <FormDivider />
        <div v-if="isIntern || brokerData.isUntermaklerDesktop || isBrokerBypass">
          <InputField
            label="Courtage Abschluss"
            type="percent"
            :precision="4"
            :isComponentHalfSize="true"
            v-model="brokerData.courtageAbschluss"
            :disabled="brokerData.isPassivMakler"
            @change="addBrokerDataEdited('brokerData')"
          />

          <InputField
            label="Courtage Bestand"
            type="percent"
            :precision="4"
            :isComponentHalfSize="true"
            v-model="brokerData.courtageBestand"
            @change="addBrokerDataEdited('brokerData')"
          />
          <InputField
            v-if="istFK"
            label="Courtage Wechsel"
            type="percent"
            :precision="4"
            :isComponentHalfSize="true"
            v-model="brokerData.courtageWechsel"
            :disabled="brokerData.isPassivMakler"
            @change="addBrokerDataEdited('brokerData')"
          />
          <InputField
            label="Courtage VV Gebühr"
            type="percent"
            :precision="4"
            :isComponentHalfSize="true"
            :disabled="brokerData.isPassivMakler"
            v-model="brokerData.courtageVVGebuehr"
            @change="addBrokerDataEdited('brokerData')"
          />
          <InputField
            v-if="istFK"
            label="Courtage Strategiegebühr"
            type="percent"
            :precision="4"
            :isComponentHalfSize="true"
            :disabled="brokerData.isPassivMakler"
            v-model="brokerData.courtageStrategieGebuehr"
            @change="addBrokerDataEdited('brokerData')"
          />
          <InputField
            v-if="istFK"
            label="Courtage Servicegebühr"
            type="percent"
            :precision="4"
            :isComponentHalfSize="true"
            v-model="brokerData.courtageServiceGebuehr"
            @change="addBrokerDataEdited('brokerData')"
          />
          <InputField
            v-if="istFK"
            label="Courtage VWL"
            type="percent"
            :precision="4"
            :isComponentHalfSize="true"
            v-model="brokerData.courtageVWL"
            :disabled="brokerData.isPassivMakler"
            @change="addBrokerDataEdited('brokerData')"
          />
          <InputField
            v-if="istFK"
            label="Courtage Beteiligungen"
            type="percent"
            :precision="4"
            :isComponentHalfSize="true"
            v-model="brokerData.courtageBeteiligungen"
            :disabled="brokerData.isPassivMakler"
            @change="addBrokerDataEdited('brokerData')"
          />
          <InputField
            v-if="istFK"
            label="Courtage Managemententgelt"
            type="percent"
            :precision="4"
            :isComponentHalfSize="true"
            v-model="brokerData.courtageManagemententgelt"
            @change="addBrokerDataEdited('brokerData')"
          />
          <InputField
            v-if="
              brokerData.courtageVorsorge != null &&
              istFK &&
              (isIntern || istFPPlus)
            "
            label="Courtage Vorsorge"
            type="percent"
            :precision="4"
            :isComponentHalfSize="true"
            v-model="brokerData.courtageVorsorge"
            :disabled="brokerData.isPassivMakler"
            @change="addBrokerDataEdited('brokerData')"
          />
          <InputField
            v-if="isFA"
            label="Courtage Option A"
            type="percent"
            :precision="4"
            :isComponentHalfSize="true"
            v-model="brokerData.courtageOptionA"
            @change="addBrokerDataEdited('brokerData')"
          />
          <InputField
            v-if="isFA"
            label="Courtage Option B"
            type="percent"
            :precision="4"
            :isComponentHalfSize="true"
            v-model="brokerData.courtageOptionB"
            @change="addBrokerDataEdited('brokerData')"
          />
          <InputField
            v-if="isBrokerBypass"
            label="Zuführer Bestand"
            type="percent"
            :precision="4"
            :isComponentHalfSize="true"
            v-model="brokerData.zufruehrerBestand"
            :disabled="brokerData.isPassivMakler"
            @change="addBrokerDataEdited('brokerData')"
          />
          <InputField
            v-if="isBrokerBypass"
            label="Zuführer Servicegebühr"
            type="percent"
            :precision="4"
            :isComponentHalfSize="true"
            v-model="brokerData.zufruehrerServicegeb"
            :disabled="brokerData.isPassivMakler"
            @change="addBrokerDataEdited('brokerData')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputField from "@/components/core/forms/InputField.vue";
import BROKERDATA_TYPES from "@/store/brokerData/types";
import { mapGetters } from "vuex";
import InputCheckboxItem from "@/components/core/forms/checkbox/InputCheckBoxItem.vue";
import BaseButton from "@/components/core/BaseButton.vue";
import CORE_TYPES from "@/store/core/types";
import BaseModal from "@/components/core/BaseModal.vue";
import InputRadioBoxGroup from "@/components/core/forms/radiobox/InputRadioBoxGroup.vue";
import FormDivider from "@/components/core/forms/FormDivider.vue";
import FormHeader from "@/components/core/forms/FormHeader.vue";
import vermittlerDatenMixin from "@/views/intern/vermittler-daten-mixin";
import { VIEW_ROLES } from '@/router/roles';


export default {
  mixins: [vermittlerDatenMixin],
  data() {
    return {
      abrechnungServiceGebuehrValues: [
        { value: "1", label: "prozentual analog Abschlussfolge" },
        { value: "2", label: "prozentual gem. Vorgabe Struktur" },
        { value: "0", label: "fixer Einbehalt" },
      ],
      mailBody: "",
      link: "",
    };
  },
  computed: {
    ...mapGetters({
      brokerData: BROKERDATA_TYPES.GETTERS.GET_BROKER_DATA,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      istFK: CORE_TYPES.GETTERS.IS_FK,
      istFPPlus: CORE_TYPES.GETTERS.IS_FPP_PLUS,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
    isBrokerBypass() {
        return this.hasRoles(VIEW_ROLES.VIEW_BROKER_AS_INTERN);
    }, 
    isBrokerNoBypass(){
       return this.hasRoles(VIEW_ROLES.VIEW_BROKER_ONLY);
    }
  },
  mounted() {
    this.mailBody =
      "Sehr%20geehrte%20Damen%20und%20Herren%2C%0A%0ABitte%20senden%20Sie%20folgendem%20Vermittler%20einen%20Direktvertrag:%0A%0A" +
      "Vermittlernummer:%20" +
      this.brokerData.untermaklerNr;
    this.link =
      "mailto:vertrieb@fondskonzept.ag?subject=Direktvertrag%20Vermittler%26body=" +
      this.mailBody;
  },
  components: {
    ComboBox,
    InputCheckboxItem,
    BaseButton,
    BaseModal,
    InputRadioBoxGroup,
    FormDivider,
    InputField,
    FormHeader,
  },
  methods: {
    wechselStrukturAbrechnung() {},
  },
  async beforeRouteLeave(to, from, next) {
    await this.saveChanges().then(() => next());
  },
};
</script>