export const MUTATION_PREFIX = 'BERATUNG_VERSICHERUNG_MUTATIONS_';
export const ACTIONS_PREFIX = 'BERATUNG_VERSICHERUNG_ACTIONS_';
export const GETTERS_PREFIX = 'BERATUNG_VERSICHERUNG_GETTERS_';

export default {
  MUTATIONS: {
    RETRIEVE_BERATUNG_VERSICHERUNG_LIST_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_BERATUNG_VERSICHERUNG_LIST_SUCCESS',
    RETRIEVE_BERATUNG_VERSICHERUNG_LIST_FAILTURE: MUTATION_PREFIX + 'RETRIEVE_BERATUNG_VERSICHERUNG_LIST_FAILTURE',
    RESET_DATA: MUTATION_PREFIX + 'RESET_DATA',
    EDIT_BERATUNG_VERSICHERUNG_SUCCESS: MUTATION_PREFIX + 'EDIT_BERATUNG_VERSICHERUNG_SUCCESS',
    EDIT_BERATUNG_VERSICHERUNG_FAILTURE: MUTATION_PREFIX + 'EDIT_BERATUNG_VERSICHERUNG_FAILTURE',
    SAVE_BERATUNG_VERSICHERUNG_SUCCESS: MUTATION_PREFIX + 'SAVE_BERATUNG_VERSICHERUNG_SUCCESS',
    SAVE_BERATUNG_VERSICHERUNG_FAILTURE: MUTATION_PREFIX + 'SAVE_BERATUNG_VERSICHERUNG_FAILTURE',
    RETRIEVE_BERATUNG_VERSICHERUNG_ACTIONS_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_BERATUNG_VERSICHERUNG_ACTIONS_SUCCESS',
    RETRIEVE_BERATUNG_VERSICHERUNG_ACTIONS_FAILURE: MUTATION_PREFIX + 'RETRIEVE_BERATUNG_VERSICHERUNG_ACTIONS_FAILURE',
    EXECUTE_BERATUNG_VERSICHERUNG_ACTION_SUCCESS: MUTATION_PREFIX + 'EXECUTE_BERATUNG_VERSICHERUNG_ACTION_SUCCESS',
    EXECUTE_BERATUNG_VERSICHERUNG_ACTION_FAILURE: MUTATION_PREFIX + 'EXECUTE_BERATUNG_VERSICHERUNG_ACTION_FAILURE',
    DELETE_ANTRAG_SUCCESS: MUTATION_PREFIX + 'DELETE_ANTRAG_SUCCESS',
    ZUR_E_SIGNATUR_VORLEGEN_SUCCESS: MUTATION_PREFIX + 'ZUR_E_SIGNATUR_VORLEGEN_SUCCESS',
    COPY_BERATUNGSMAPPE_SUCCESS: MUTATION_PREFIX + 'COPY_BERATUNGSMAPPE_SUCCESS',
    SAVE_ANTRAGS_POSITION_SUCCESS: MUTATION_PREFIX + 'SAVE_ANTRAGS_POSITION_SUCCESS',
    DELETE_POSITION_SUCCESS: MUTATION_PREFIX + 'DELETE_POSITION_SUCCESS',
    RETRIEVE_INSURANCES_CUSTOMER_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_INSURANCES_CUSTOMER_SUCCESS',
    FINANZPORTAL24_ONLINE_INSURANCELINK: MUTATION_PREFIX + 'FINANZPORTAL24_ONLINE_INSURANCELINK',
    FINANZPORTAL24_ONLINE_FILELINK: MUTATION_PREFIX + 'FINANZPORTAL24_ONLINE_FILELINK',
    FINANZPORTAL24_OFFLINE_INSURANCEFPXFILE: MUTATION_PREFIX + 'FINANZPORTAL24_OFFLINE_INSURANCEFPXFILE',
    DO_DOWNLOAD_DOKUMENT_SMART_MSC_SUCCESS: MUTATION_PREFIX + 'DO_DOWNLOAD_DOKUMENT_SMART_MSC_SUCCESS',
    SAVE_INSURANCE_CUSTOMER: MUTATION_PREFIX + 'SAVE_INSURANCE_CUSTOMER',
  },
  ACTIONS: {
    RETRIEVE_BERATUNG_VERSICHERUNG_LIST: ACTIONS_PREFIX + 'RETRIEVE_BERATUNG_VERSICHERUNG_LIST',
    EDIT_BERATUNG_VERSICHERUNG: ACTIONS_PREFIX + 'EDIT_BERATUNG_VERSICHERUNG',
    SAVE_BERATUNG_VERSICHERUNG: ACTIONS_PREFIX + 'SAVE_BERATUNG_VERSICHERUNG',
    RETRIEVE_BERATUNG_VERSICHERUNG_ACTIONS: ACTIONS_PREFIX + 'RETRIEVE_BERATUNG_VERSICHERUNG_ACTIONS',
    EXECUTE_BERATUNG_VERSICHERUNG_ACTION: ACTIONS_PREFIX + 'EXECUTE_BERATUNG_VERSICHERUNG_ACTION',
    DELETE_BERATUNG_VERSICHERUNG: ACTIONS_PREFIX + 'DELETE_BERATUNG_VERSICHERUNG',
    CREATE_BERATUNG_VERSICHERUNG: ACTIONS_PREFIX + 'CREATE_BERATUNG_VERSICHERUNG',
    DELETE_ANTRAG: ACTIONS_PREFIX + 'DELETE_ANTRAG',
    ZUR_E_SIGNATUR_VORLEGEN: ACTIONS_PREFIX + 'ZUR_E_SIGNATUR_VORLEGEN',
    BERATUNG_VERSICHERUNG_ABSCHLIESSEN: ACTIONS_PREFIX + 'BERATUNG_VERSICHERUNG_ABSCHLIESSEN',
    COPY_BERATUNG_VERSICHERUNG: ACTIONS_PREFIX + 'COPY_BERATUNG_VERSICHERUNG',
    SAVE_ANTRAGS_POSITION: ACTIONS_PREFIX + 'SAVE_ANTRAGS_POSITION',
    RETRIEVE_INSURANCES_CUSTOMER: ACTIONS_PREFIX + 'RETRIEVE_INSURANCES_CUSTOMER',
    FINANZPORTAL24_ONLINE_INSURANCELINK: ACTIONS_PREFIX + 'FINANZPORTAL24_ONLINE_INSURANCELINK',
    FINANZPORTAL24_ONLINE_FILELINK: ACTIONS_PREFIX + 'FINANZPORTAL24_ONLINE_FILELINK',
    FINANZPORTAL24_OFFLINE_INSURANCEFPXFILE: ACTIONS_PREFIX + 'FINANZPORTAL24_OFFLINE_INSURANCEFPXFILE',
    DO_DOWNLOAD_DOKUMENT_SMART_MSC: ACTIONS_PREFIX + 'DO_DOWNLOAD_DOKUMENT_SMART_MSC',
    SAVE_INSURANCE_CUSTOMER: ACTIONS_PREFIX + 'SAVE_INSURANCE_CUSTOMER',
  },
  GETTERS: {
    BERATUNG_VERSICHERUNG_LIST_DATA: GETTERS_PREFIX + 'BERATUNG_VERSICHERUNG_LIST_DATA',
    BERATUNG_VERSICHERUNG_CARDS: GETTERS_PREFIX + 'BERATUNG_VERSICHERUNG_CARDS',
    BERATUNG_VERSICHERUNG_ACTIONS: GETTERS_PREFIX + 'BERATUNG_VERSICHERUNG_ACTIONS',
    EXECUTE_ACTION_RESULT: GETTERS_PREFIX + 'EXECUTE_ACTION_RESULT',
    LAST_SAVED_ANTRAGS_POSITION: GETTERS_PREFIX + 'LAST_SAVED_ANTRAGS_POSITION',
    LAST_DELETED_ANTRAGS_POSITION: GETTERS_PREFIX + 'LAST_DELETED_ANTRAGS_POSITION',
    INSURANCES_CUSTOMER: GETTERS_PREFIX + 'INSURANCES_CUSTOMER',
    FINANZPORTAL24_ONLINE_INSURANCELINK: GETTERS_PREFIX + 'FINANZPORTAL24_ONLINE_INSURANCELINK',
    FINANZPORTAL24_ONLINE_FILELINK: GETTERS_PREFIX + 'FINANZPORTAL24_ONLINE_FILELINK',
    FINANZPORTAL24_OFFLINE_INSURANCEFPXFILE: GETTERS_PREFIX + 'FINANZPORTAL24_OFFLINE_INSURANCEFPXFILE',
    DOKUMENT_SMART_MSC: GETTERS_PREFIX + 'DOKUMENT_SMART_MSC',
  }
}