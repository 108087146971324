export const MUTATION_PREFIX = 'COURTAGETABELLE_INVESTMENT_MUTATION_';
export const ACTIONS_PREFIX = 'COURTAGETABELLE_INVESTMENT_ACTIONS_';
export const GETTERS_PREFIX = 'COURTAGETABELLE_INVESTMENT_GETTERS_';

export default {
  MUTATIONS: {
    FILTER_FIELDS_DEFINITIONS_SUCCESS: MUTATION_PREFIX + 'FILTER_FIELDS_DEFINITIONS_SUCCESS',
    COURTAGETABELLE_INVESTMENT_LIST_SUCCESS: MUTATION_PREFIX + 'COURTAGETABELLE_INVESTMENT_LIST_SUCCESS',
    CLEAR_COURTAGETABELLE_INVESTMENT_LIST: MUTATION_PREFIX + 'CLEAR_COURTAGETABELLE_INVESTMENT_LIST',
    GET_COURTAGETABELLE_INVESTMENT_FONDSPLATTFORMEN_SUCCESS: MUTATION_PREFIX + 'GET_COURTAGETABELLE_INVESTMENT_FONDSPLATTFORMEN_SUCCESS',
  },
  ACTIONS: {
    GET_FILTER_FIELDS_DEFINITIONS: ACTIONS_PREFIX + 'GET_FILTER_FIELDS_DEFINITIONS',
    GET_COURTAGETABELLE_INVESTMENT_LIST: ACTIONS_PREFIX + 'GET_COURTAGETABELLE_INVESTMENT_LIST',
    GET_COURTAGETABLLE_INVESTMENT_FONDSPLATTFORMEN: ACTIONS_PREFIX + 'GET_COURTAGETABLLE_INVESTMENT_FONDSPLATTFORMEN',
    EXPORT_COURTAGETABELLE_INVESTMENT: ACTIONS_PREFIX + 'EXPORT_COURTAGETABELLE_INVESTMENT',
  },
  GETTERS: {
    FILTER_FIELDS_DEFINITIONS: GETTERS_PREFIX + 'FILTER_FIELDS_DEFINITIONS',
    COURTAGETABELLE_INVESTMENT_LIST: GETTERS_PREFIX + 'COURTAGETABELLE_INVESTMENT_LIST',
    COURTAGETABELLE_INVESTMENT_FONDSPLATTFORMEN: GETTERS_PREFIX + 'COURTAGETABELLE_INVESTMENT_FONDSPLATTFORMEN',
  }
}
