import BIPRO_CATEGORY_TYPES from './types';
import CORE_TYPES from '@/store/core/types'
import axios from 'axios';
import { buildMessage } from '@/helpers/log-message-helper';
import LOG_TYPES from '@/store/log/types';

const config = {
    defaultSpinner: true
}

export default {
    async [BIPRO_CATEGORY_TYPES.ACTIONS.RETRIEVE_BIPRO_CATEGORIES]({ dispatch, commit, getters }, payload) {
        const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/bipro_categories/filter`;
    
        try {
            const { data } = await axios.post(url, payload, config);
            commit(BIPRO_CATEGORY_TYPES.MUTATIONS.RETRIEVE_BIPRO_CATEGORIES_SUCCESS, data);
            return data;
        } catch (error) {
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Es gab ein Problem bei dem Abruf der Daten.', 'danger'));
            throw error;
        }
    },
    async [BIPRO_CATEGORY_TYPES.ACTIONS.SAVE_BIPRO_CATEGORY]({ dispatch, getters }, payload) {
        const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/bipro_categories/save`;
    
        try {
            const response = await axios.post(url, payload, config);
            return response.data;
        } catch (error) {
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error?.response?.data, 'danger'));
            throw error;
        }
    },
    async [BIPRO_CATEGORY_TYPES.ACTIONS.UPDATE_BIPRO_CATEGORY]({ dispatch, getters }, payload) {
        const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/bipro_categories/update`;
    
        try {
            const response = await axios.put(url, payload, config);
            return response.data;
        } catch (error) {
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error?.response?.data, 'danger'));
            throw error;
        }
    },    
    async [BIPRO_CATEGORY_TYPES.ACTIONS.DELETE_BIPRO_CATEGORY]({ dispatch, getters }, payload) {
        const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/bipro_categories/delete`;
    
        try {
            const response = await axios.delete(url, { data: payload, ...config });
            return response.data;
        } catch (error) {
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Es gab einen Fehler beim Speichern der Daten', 'danger'));
            throw error;
        }
    },
}