<template>
  <div @click="pressAnzahl" class="box spec_button anzahl">Anzahl</div>
</template>

<script>
//import store from "./../../this.$store.js";
export default {
  name: "Anzahl",
  methods: {
    pressAnzahl() {
      if (this.$store.getters.getCurrent !== "") {
        this.$store.commit("SET_ANZAHL", this.$store.getters.getCurrent);
        this.$store.commit("SET_CURRENT", "");
      }
    }
  }
};
</script>

<style scoped>
.minus {
  grid-area: anzahl;
}
</style>