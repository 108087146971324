var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("InputField", {
        attrs: {
          label: "Betrachtungszeitraum in Jahren (mindestens 3 Jahre)",
          disabled: _vm.isDisabled || _vm.isRefusedPage,
          isComponentHalfSize: ""
        },
        on: {
          change: function($event) {
            _vm.updateStore(_vm.getCategory(), "years", $event)
          }
        },
        model: {
          value: _vm.years,
          callback: function($$v) {
            _vm.years = $$v
          },
          expression: "years"
        }
      }),
      _c("InputRadioBoxGroup", {
        attrs: {
          title:
            "Ich habe Kenntnisse und Erfahrungen in folgenden Anlageformen:",
          validateUntouched: true,
          disabled: _vm.isDisabled || _vm.isRefusedPage,
          inlineLabelValues: false,
          values: [
            { value: "no", label: "keine Kenntnisse und Erfahrungen" },
            { value: "yes", label: "folgende Kenntnisse und Erfahrungen:" }
          ]
        },
        on: {
          input: function($event) {
            return _vm.setNoExperience($event)
          }
        },
        model: {
          value: _vm.hasErfahrungen,
          callback: function($$v) {
            _vm.hasErfahrungen = $$v
          },
          expression: "hasErfahrungen"
        }
      }),
      _c(
        "div",
        { staticClass: "checkbox-input__container mb-3 ml-3" },
        _vm._l(_vm.categories, function(category, index) {
          return _c(
            "div",
            { key: index },
            [
              _c("InputCheckBoxItem", {
                attrs: {
                  label: category.label,
                  disabled:
                    _vm.isDisabled ||
                    _vm.isRefusedPage ||
                    _vm.hasErfahrungen !== "yes"
                },
                on: {
                  input: function($event) {
                    return _vm.onSelection($event, category.path)
                  }
                },
                model: {
                  value: _vm.form[category.path],
                  callback: function($$v) {
                    _vm.$set(_vm.form, category.path, $$v)
                  },
                  expression: "form[category.path]"
                }
              })
            ],
            1
          )
        }),
        0
      ),
      !_vm.isDialogFVV
        ? _c("InputRadioBoxGroup", {
            attrs: {
              title:
                "Bitte wählen Sie, ob Wertpapiergeschäfte auf Kreditbasis getätigt wurden.",
              validateUntouched: true,
              disabled: _vm.isDisabled || _vm.isRefusedPage,
              inlineLabelValues: _vm.isMobileNativeContext ? true : false,
              values: [
                { value: "no", label: "nein" },
                { value: "yes", label: "ja" }
              ]
            },
            on: {
              input: function($event) {
                _vm.updateStore(
                  _vm.getCategory(),
                  "investOnCredit",
                  _vm.getBooleanValue($event)
                )
              }
            },
            model: {
              value: _vm.investOnCredit,
              callback: function($$v) {
                _vm.investOnCredit = $$v
              },
              expression: "investOnCredit"
            }
          })
        : _vm._e(),
      !_vm.isDialogFVV && _vm.investOnCredit === "yes"
        ? _c("InputField", {
            attrs: {
              label: "In welcher Höhe bewegte sich Ihr Kredit (Euro)?",
              type: "currency",
              disabled: _vm.isDisabled || _vm.isRefusedPage,
              isComponentHalfSize: ""
            },
            on: {
              change: function($event) {
                _vm.updateStore(_vm.getCategory(), "creditSum", $event)
              }
            },
            model: {
              value: _vm.creditSum,
              callback: function($$v) {
                _vm.creditSum = $$v
              },
              expression: "creditSum"
            }
          })
        : _vm._e(),
      _c("InputTextArea", {
        attrs: {
          label: "Zusätzliche Angaben zu den Kenntnissen und Erfahrungen:",
          disabled: _vm.isDisabled || _vm.isRefusedPage,
          isComponentHalfSize: ""
        },
        on: {
          change: function($event) {
            _vm.updateStore(_vm.getCategory(), "annotationKenntnisse", $event)
          }
        },
        model: {
          value: _vm.annotationKenntnisse,
          callback: function($$v) {
            _vm.annotationKenntnisse = $$v
          },
          expression: "annotationKenntnisse"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }