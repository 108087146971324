var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "newCustomerModal",
    attrs: {
      autoClose: false,
      labelButtonConfirm: _vm.confirmLabel,
      confirmDisabled: !_vm.isValid || _vm.loading
    },
    on: {
      onConfirmButton: function($event) {
        return _vm.saveCustomer()
      },
      closed: function($event) {
        return _vm.backToCustomerHome()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "modalTitle",
        fn: function() {
          return [
            _vm._v(
              _vm._s(_vm.isSuperCustomer ? "Kundengruppe" : "Kunde") +
                " anlegen"
            )
          ]
        },
        proxy: true
      },
      {
        key: "default",
        fn: function() {
          return [_c("Stammdaten")]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }