
/** 
 * @deprecated don't use this function. 
 * 
 * Download files should be handled by DownloadLink.vue component due to the native stack.
 * 
 * Use this function for opening a Blob File in a new Tab
 * @param file file to open
 * @param windowRef: The Reference of the Window to show the file in. 
 *  Create a new windowRef e.g. with let windowRef = window.open('about:blank','_blank').
 *  The windowRef MUST be created, before any AJAX is fired, otherwise Safari won't open a new Tab because of security reasons.
 */
export function openFileInNewTab(file, windowRef, modalViewerServce, filename = 'Dokument.pdf'){
    if (!windowRef || !windowRef.location){
        modalViewerServce.showModal('Bitte Popups erlauben', 'Die Datei wird in einem neuen Tab geöffnet. \
        Bitte vergewissern Sie sich, dass kein Addblocker diesen Zugriff verbietet. \
        Bitte erlauben Sie das Öffnen in einem neuen Tab');
        throw new Error('Can not access Tab to write content to.')
    } else {
        if (navigator.userAgent.toLocaleLowerCase().indexOf('edge') > -1){
            windowRef.close();
            navigator.msSaveOrOpenBlob(file, filename);
        } else {
            const linkUrl = URL.createObjectURL(new File([file], filename, {type: file.type}));
            windowRef.location.href = linkUrl;
        }
    }
}

/**
 * @deprecated don't use this function. 
 * 
 * Download files should be handled by DownloadLink.vue component due to the native stack.
 * 
 * @param {*} fileName 
 * @param {*} byteArr 
 * @param {*} contentType 
 */
export function downloadFile(fileName, response, contentType = 'text/csv;charset=utf-8;') {
    const options = { type: contentType };
    const blob = new File([response], fileName, options);

    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    link.setAttribute('href', url);
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
        link.setAttribute('download', fileName);
    } else {
        link.target = '_blank';
    }
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    setTimeout(() => document.body.removeChild(link), 0);
}

/** Get the Window for opening the Redirection Page in a new Tab */
export function getRedirectionWindow() {
    return window.open(getRedirectionHtmlString(),'_blank');
}

/** 
 *  Redirection URL for PDF Files 
 *  Use this to show an empty html page with redirection Text
 */
export function getRedirectionHtmlString() {
    return process.env.VUE_APP_API + '/../images/etc/ksc3/warten.html';
}

export function removeFormatting(formattedNumber) {
    if (formattedNumber) {
        formattedNumber = String(formattedNumber);
        let lastSeparator = String(formattedNumber).lastIndexOf('.');
        if (formattedNumber.lastIndexOf(',') > lastSeparator) {
            lastSeparator = formattedNumber.lastIndexOf(',');
        }
        let numberPart = '';
        let decimalPart = '';
        if (lastSeparator > 0) {
            numberPart = formattedNumber.substring(0, lastSeparator).split(',').join('').split('.').join('');
            decimalPart = formattedNumber.substring(lastSeparator + 1, formattedNumber.length);
            if (decimalPart.indexOf(',') > 0) {
                lastSeparator = formattedNumber.lastIndexOf(',');
                decimalPart = formattedNumber.substring(lastSeparator + 1, formattedNumber.length);
            }
        } else {
            numberPart = formattedNumber;
        }
        return [numberPart, decimalPart].join('.');
    }
    return formattedNumber;
}
