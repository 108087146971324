<template>
  <div class="hour-cell" id="hour-cell-id" :data-hour="hour">
    <div class="parent" v-if="ifExistsEvent">
      <HourEventDiv
        :contentarray="getCell"
        :dimensions="dimensions"
        :width="getNewWidth"
        :events="allHourEvents[hour]"
        @editEvent="editEvent"
      />
    </div>
  </div>
</template>
<script>
function getDimensions() {
  let dimensionsbox = document
    .getElementById("hour-cell-id")
    .getBoundingClientRect();
  return {
    initialWidth: dimensionsbox.width,
    initialHeight: dimensionsbox.height
  };
}

import dayjs from "dayjs";
import { mapGetters, mapState } from "vuex";
import CALENDAR_TYPES from "@/store/calendar/types";
import HourEventDiv from "@/components/calendar/HourEventDiv.vue";
export default {
  components: {
    HourEventDiv
  },
  data() {
    return {
      dimensions: {}
    };
  },
  props: {
    dayindex: {
      type: Number,
      default: () => 0,
      required: true
    },
    daylabel: {
      type: String,
      default: () => "",
      required: true
    },
    date: {
      type: Object,
      default: () => dayjs()
    },
    hour: {
      type: String,
      default: () => "",
      required: true
    },
    maxconcurrent: {
      type: Number,
      default: () => 0,
      required: true
    },
    allHourEvents: {
      type: Object,
      default: () => {},
      required: true
    },
  },
  computed: {
    ...mapGetters({
      compGetCalendarData: CALENDAR_TYPES.GETTERS.GET_CALENDAR_DATA,
      hourCompGetWeekCalendarData:
        CALENDAR_TYPES.GETTERS.GET_WEEK_CALENDAR_DATA,
      columnEventArray: CALENDAR_TYPES.GETTERS.GET_COLUMN_EVENT_ARRAY,
      getIfAlreadyPlaced: CALENDAR_TYPES.GETTERS.GET_IF_ALREADY_PLACED,
      getIfRightPosition: CALENDAR_TYPES.GETTERS.GET_IF_RIGHT_POSITION,
      getHoursContent: CALENDAR_TYPES.GETTERS.GET_HOURS_CONTENT
    }),

    timedEvents() {
      return this.hourCompGetWeekCalendarData[this.dayindex].timedEvents;
    },
    ifExistsEvent() {
      return this.timedEvents.length > 0;
    },

    getNewWidth() {
      return (
        Math.floor(this.dimensions.initialWidth / (this.maxconcurrent + 1)) +
        "px"
      );
    },

    getCell() {
      let indexNumber = Number(this.hour.split(":")[0]) + this.dayindex * 24;
      return this.getHoursContent[indexNumber].content && this.getHoursContent[indexNumber].content
        .filter(v => v.some(v => v.isStart)).filter(v => v.length).reduce((acc, curr) => ([...acc, ...curr]), []);
    }
  },
  mounted() {
    this.dimensions = getDimensions();
  },
  methods: {
    editEvent(e) {
      this.$emit('editEvent', e);
    },
  },
};
</script>
<style  scoped>
/* .hour-cell {
  height: 4em;
  border-style: none none dotted none;
  border-width: thin;
  display: flex;
  flex: auto;
} */

.eventsnum {
  cursor: pointer;
  height: 100%;
  width: 0;
  flex: 1 1 0;
}
.parent {
  display: flex;
  flex-basis: 100%;
}
.parent div {
  flex-grow: 1;
  max-width: 100%;
}
</style>