var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("LineChart", {
        ref: "lineChart",
        attrs: {
          chartSeries: _vm.chartSeries,
          isArea: false,
          customTooltipFormatter: _vm.customTooltipFormatter,
          height: _vm.height,
          format: _vm.relativ ? "" : _vm.format,
          isLegend: false,
          showDataZoom: _vm.showDataZoom,
          chartOptions: _vm.chartOptions,
          gridLeft: 50
        }
      }),
      _vm.hasShowAsTable && _vm.lineChart && _vm.chartSeries.length
        ? _c("InputToggleSwitch", {
            attrs: { label: "Tabellarische Anzeige", inLineLabel: "" },
            model: {
              value: _vm.showAsTable,
              callback: function($$v) {
                _vm.showAsTable = $$v
              },
              expression: "showAsTable"
            }
          })
        : _vm._e(),
      _vm.type == "Fondsinfo"
        ? _c(
            "div",
            { staticClass: "row" },
            [
              _c("div", [_vm._v("Obere Schranke")]),
              _c("ComboBox", {
                attrs: {
                  value: _vm.obereSchranke,
                  values: _vm.obereSchrankeOptions
                },
                on: { change: _vm.onChangeObereSchranke }
              }),
              _c("div", [_vm._v("Untere Schranke")]),
              _c("ComboBox", {
                attrs: {
                  value: _vm.untereSchranke,
                  values: _vm.untereSchrankeOptions
                },
                on: { change: _vm.onChangeUntereSchranke }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._t("beforLegend"),
      _vm.lineChart && _vm.chartSeries.length
        ? _c("ChartLegend", {
            attrs: {
              showAsTable: _vm.showAsTable,
              chartSeries: _vm.chartSeries,
              lineChart: _vm.lineChart,
              addOnHeaders: _vm.addOnHeaders,
              addOnRows: _vm.addOnRows,
              sortRows: _vm.sortLegend
            },
            on: {
              newOrder: function($event) {
                return _vm.$emit("newOrder", $event)
              }
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }