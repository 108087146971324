var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("div", { staticClass: "box__title" }, [_vm._v("Bankdaten")]),
        !_vm.isFA
          ? _c(
              "div",
              [
                _c("p", { staticClass: "text-bold" }, [
                  _vm._v(
                    "Bankverbindung des Maklers (nur notwendig, wenn der Kunde die Rechnung überweisen soll)"
                  )
                ]),
                _c("InputToggleSwitch", {
                  attrs: { label: "Zahlung per Überweisung", inLineLabel: "" },
                  on: {
                    input: function($event) {
                      return _vm.dataChanged()
                    }
                  },
                  model: {
                    value: _vm.feePropertiesForm.zahlungUeberweisung,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.feePropertiesForm,
                        "zahlungUeberweisung",
                        $$v
                      )
                    },
                    expression: "feePropertiesForm.zahlungUeberweisung"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    label: "Bankname",
                    type: "text",
                    isComponentHalfSize: ""
                  },
                  on: {
                    change: function($event) {
                      return _vm.dataChanged()
                    }
                  },
                  model: {
                    value: _vm.feePropertiesForm.maklerBank,
                    callback: function($$v) {
                      _vm.$set(_vm.feePropertiesForm, "maklerBank", $$v)
                    },
                    expression: "feePropertiesForm.maklerBank"
                  }
                }),
                _c("InputIBAN_BIC", {
                  attrs: {
                    label: "IBAN",
                    type: "IBAN",
                    componentData: _vm.feePropertiesForm.maklerIban,
                    isComponentHalfSize: "",
                    validation_path: "feePropertiesForm.maklerIban"
                  },
                  on: {
                    change: function($event) {
                      return _vm.dataChangedCombo("maklerIban", $event)
                    }
                  }
                }),
                _c("InputIBAN_BIC", {
                  attrs: {
                    label: "BIC",
                    type: "BIC",
                    componentData: _vm.feePropertiesForm.maklerBic,
                    isComponentHalfSize: "",
                    validation_path: "feePropertiesForm.maklerBic"
                  },
                  on: {
                    change: function($event) {
                      return _vm.dataChangedCombo("maklerBic", $event)
                    }
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _c("InputField", {
          attrs: {
            label: "Buchungstext Lastschrift",
            type: "text",
            isComponentHalfSize: ""
          },
          on: {
            change: function($event) {
              return _vm.dataChanged()
            }
          },
          model: {
            value: _vm.feePropertiesForm.buchungstextLastschrift,
            callback: function($$v) {
              _vm.$set(_vm.feePropertiesForm, "buchungstextLastschrift", $$v)
            },
            expression: "feePropertiesForm.buchungstextLastschrift"
          }
        }),
        _vm.isCustomer
          ? _c(
              "div",
              [
                _c("p", { staticClass: "text-bold" }, [
                  _vm._v(
                    "Bankverbindung des Kunden (nur notwendig, wenn per SEPA Lastschrift eingezogen werden soll und für Erstattungen an den Kunden)"
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "mb-3" },
                  [
                    _c(
                      "BaseButton",
                      {
                        attrs: { disabled: _vm.loading },
                        on: {
                          click: function($event) {
                            return _vm.findKundeBankverbindungData()
                          }
                        }
                      },
                      [_vm._v("Von Standardbankverbindung kopieren")]
                    )
                  ],
                  1
                ),
                _c("InputField", {
                  attrs: {
                    label: "Bankname",
                    type: "text",
                    isComponentHalfSize: ""
                  },
                  on: {
                    change: function($event) {
                      return _vm.dataChanged()
                    }
                  },
                  model: {
                    value: _vm.feePropertiesForm.kundenBank,
                    callback: function($$v) {
                      _vm.$set(_vm.feePropertiesForm, "kundenBank", $$v)
                    },
                    expression: "feePropertiesForm.kundenBank"
                  }
                }),
                _c("InputIBAN_BIC", {
                  attrs: {
                    label: "IBAN",
                    type: "IBAN",
                    componentData: _vm.feePropertiesForm.kundenIban,
                    isComponentHalfSize: "",
                    validation_path: "feePropertiesForm.kundenIban"
                  },
                  on: {
                    change: function($event) {
                      return _vm.dataChangedCombo("kundenIban", $event)
                    }
                  }
                }),
                _c("InputIBAN_BIC", {
                  attrs: {
                    label: "BIC",
                    type: "BIC",
                    componentData: _vm.feePropertiesForm.kundenBic,
                    isComponentHalfSize: "",
                    validation_path: "feePropertiesForm.kundenBic"
                  },
                  on: {
                    change: function($event) {
                      return _vm.dataChangedCombo("kundenBic", $event)
                    }
                  }
                })
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }