<template>
  <div>
    <BaseModal 
      ref="changeCategoryModal"
      modalTitle="Kategorie ändern"
      @close="close()"
      @onConfirmButton="submit()"
    >

        <ComboBox 
            v-model="chosenCategory" 
            :values="categoriesComboBox">
        </ComboBox>

    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MULTIBANKING_TYPES from '@/store/multiBanking/types'
import BaseModal from '@/components/core/BaseModal.vue'
import ComboBox from '@/components/core/forms/ComboBox.vue'

export default {
  components: {
    BaseModal,
    ComboBox,
  },

  props: {
    transaction: {
      type: Object,
      required: true,
    },
  },

  data: function () {
    return {
      chosenCategory: this.transaction.category.category,
    }
  },

  computed: {
    ...mapGetters({
      categoryTree: MULTIBANKING_TYPES.GETTERS.CATEGORY_TREE,
    }),
    categoriesComboBox() {
      return this.categoryFlat.map(cat => ({
        label: cat.displayName,
        value: cat.category,
        disabled: this.categoryTree.includes(cat)
      }))
      .filter(item => !item.disabled);
    },
    categoryFlat() {
      let result = []
      this.categoryTree.forEach(parentCategory => {
        result.push(parentCategory)
        result = result.concat(parentCategory.children)
      })
      return result
    },
  },
  
  methods: {
    submit() {
      let category = this.categoryFlat.find((ct) => ct.category === this.chosenCategory)
      this.$store.dispatch(MULTIBANKING_TYPES.ACTIONS.SAVE_TRANSACTION_CATEGORY, {
          transactionId: this.transaction.id,
          category: category,
        })
      this.close()
    },
    close() {
        this.$refs.changeCategoryModal.close()
        this.$emit("close")
    },
  },
  mounted() {
    this.$refs.changeCategoryModal.open()
  }
}
</script>

<style scoped>
.change-category-head, .change-category-item {
  width: 25em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: .5em;
}
.change-category-item {
  background-color: var(--color-secondary);
  color: var(--color-secondary-text);
}
.change-category-name {
  flex-grow: 1;
}
input[type=text] {
  width: 100%;
}
</style>