var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "antrag-components__container" }, [
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("InputToggleSwitch", {
          attrs: { label: "Übertragung des gesamten Depots" },
          on: {
            input: function($event) {
              return _vm.$emit("save", { all: $event })
            }
          },
          model: {
            value: _vm.form.all,
            callback: function($$v) {
              _vm.$set(_vm.form, "all", $$v)
            },
            expression: "form.all"
          }
        }),
        _c("WertpapierauswahlDepotUebertragung", {
          attrs: {
            hasGesamtbestand: false,
            positions: _vm.positionData,
            anzahlFonds: _vm.anzahlFonds
          },
          on: {
            save: function($event) {
              return _vm.$emit("save", $event)
            },
            fondsfinder: function($event) {
              return _vm.$emit("fondsfinder", $event)
            }
          }
        }),
        _c("FormDivider"),
        _c("InputToggleSwitch", {
          attrs: { label: "mein/e Konto/Konten abzuschließen und aufzulösen." },
          on: {
            input: function($event) {
              return _vm.$emit("save", { kontoBeenden: $event })
            }
          },
          model: {
            value: _vm.form.kontoBeenden,
            callback: function($$v) {
              _vm.$set(_vm.form, "kontoBeenden", $$v)
            },
            expression: "form.kontoBeenden"
          }
        }),
        _c("InputToggleSwitch", {
          attrs: {
            label: "mein(e)/unser(e) Depot/Depots abzuschließen und aufzulösen."
          },
          on: {
            input: function($event) {
              return _vm.$emit("save", { sparplanBeenden: $event })
            }
          },
          model: {
            value: _vm.form.sparplanBeenden,
            callback: function($$v) {
              _vm.$set(_vm.form, "sparplanBeenden", $$v)
            },
            expression: "form.sparplanBeenden"
          }
        }),
        _c("InputToggleSwitch", {
          attrs: {
            label: "vorhandene Guthaben auf folgendes Konto überweisen:"
          },
          on: {
            input: function($event) {
              return _vm.$emit("save", { kontoGuthabenEmpfaenger: $event })
            }
          },
          model: {
            value: _vm.form.kontoGuthabenEmpfaenger,
            callback: function($$v) {
              _vm.$set(_vm.form, "kontoGuthabenEmpfaenger", $$v)
            },
            expression: "form.kontoGuthabenEmpfaenger"
          }
        }),
        _vm.form.kontoGuthabenEmpfaenger
          ? _c("InputField", {
              attrs: { label: "Vorname, Name" },
              on: {
                input: function($event) {
                  return _vm.$emit("save", {
                    nameVornameGuthabenEmpfaenger: $event
                  })
                }
              },
              model: {
                value: _vm.form.nameVornameGuthabenEmpfaenger,
                callback: function($$v) {
                  _vm.$set(_vm.form, "nameVornameGuthabenEmpfaenger", $$v)
                },
                expression: "form.nameVornameGuthabenEmpfaenger"
              }
            })
          : _vm._e(),
        _vm.form.kontoGuthabenEmpfaenger
          ? _c("InputIbanBic", {
              attrs: {
                label: "IBAN",
                type: "IBAN",
                componentData: _vm.form.ibanGuthabenEmpfaenger,
                validation_path: "ibanGuthabenEmpfaenger"
              },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { ibanGuthabenEmpfaenger: $event })
                }
              },
              model: {
                value: _vm.form.ibanGuthabenEmpfaenger,
                callback: function($$v) {
                  _vm.$set(_vm.form, "ibanGuthabenEmpfaenger", $$v)
                },
                expression: "form.ibanGuthabenEmpfaenger"
              }
            })
          : _vm._e(),
        _vm.form.kontoGuthabenEmpfaenger
          ? _c("InputIbanBic", {
              attrs: {
                label: "BIC",
                type: "BIC",
                componentData: _vm.form.bicGuthabenEmpfaenger,
                validation_path: "bicGuthabenEmpfaenger"
              },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bicGuthabenEmpfaenger: $event })
                }
              },
              model: {
                value: _vm.form.bicGuthabenEmpfaenger,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bicGuthabenEmpfaenger", $$v)
                },
                expression: "form.bicGuthabenEmpfaenger"
              }
            })
          : _vm._e(),
        _c("FormDivider"),
        _c(
          "BaseButton",
          {
            staticClass: "col-12 mr-2",
            attrs: { isSecondary: true },
            on: {
              click: function($event) {
                return _vm.$emit("back")
              }
            }
          },
          [_vm._v("Zurück")]
        ),
        _c(
          "BaseButton",
          {
            staticClass: "mr-2",
            on: {
              click: function($event) {
                return _vm.$emit("next")
              }
            }
          },
          [_vm._v("Weiter")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }