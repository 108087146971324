import RISIKOKLASSEN_DEFINITION_TYPES from './types';

export default {

  [RISIKOKLASSEN_DEFINITION_TYPES.GETTERS.RISIKOKLASSEN_DEFINITION](state) {
    return { ...state.risikoklassenDefinition, };
  },

  [RISIKOKLASSEN_DEFINITION_TYPES.GETTERS.RISIKOKLASSEN_DEFINITION_EDITED](state) {
    return state.risikoklassenDefinitionEdited && { ...state.risikoklassenDefinitionEdited, } || null;
  },

  [RISIKOKLASSEN_DEFINITION_TYPES.GETTERS.HAS_CONFIG](state) {
    const risikoklassenDefinition = state.risikoklassenDefinitionEdited || state.risikoklassenDefinition;
    return risikoklassenDefinition?.hasConfig === true;
  },

  [RISIKOKLASSEN_DEFINITION_TYPES.GETTERS.IS_CHANGE_ALLOWED](state) {
    return state.risikoklassenDefinition?.changeAllowed === true;
  },

  [RISIKOKLASSEN_DEFINITION_TYPES.GETTERS.HAS_RISIKOKLASSE_ERRORS](state) {
    return (index) => {
      const risikoklassenDefinition = state.risikoklassenDefinitionEdited || state.risikoklassenDefinition;
      if(!risikoklassenDefinition.hasConfig) {
        return false;
      }

      const risikoklassen = risikoklassenDefinition?.einstellung?.[`risiko${index}`] || {};

      if(!risikoklassen.bezeichnung) {
        return true;
      }

      if(risikoklassen.grenzeAktiv) {
        if(!risikoklassen.anteilUG || !risikoklassen.anteilOG 
          || !risikoklassen.anteilMaxSatz || !risikoklassen.text) {
          return true;
        }

        if(risikoklassen.anteilUG > risikoklassen.anteilOG) {
          return true;
        }
      }

      return false;
    };
  },

}
