//
// Autogenerated by Thrift Compiler (1.0.0-soscomp-2)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//


const WebRtcCallStatus = {
  'PREPARED' : 0,
  'CALLING' : 1,
  'CANCELED' : 2,
  'REJECTED' : 3,
  'ESTABLISHED' : 4,
  'DROPOUT' : 5,
  'FINISHED' : 6,
  'BUSY' : 7,
  'ERROR' : 8,
  'DROPOUT_SERVER_WEBRTC' : 9,
  'DROPOUT_SERVER_WBESOCKET' : 10,
  'RECORDING_ERROR' : 11,
  'FATAL' : 12,
  'TIMEOUT' : 13
};
const ConnectionRole = {
  'CALLER' : 0,
  'CALLEE' : 1
};
const WebRtcUser = function(args) {
  this.maklernr = null;
  this.kundennr = null;
  this.ticketBeteiligterId = null;
  this.chatBeteiligterId = null;
  if (args) {
    if (args.maklernr !== undefined && args.maklernr !== null) {
      this.maklernr = args.maklernr;
    }
    if (args.kundennr !== undefined && args.kundennr !== null) {
      this.kundennr = args.kundennr;
    }
    if (args.ticketBeteiligterId !== undefined && args.ticketBeteiligterId !== null) {
      this.ticketBeteiligterId = args.ticketBeteiligterId;
    }
    if (args.chatBeteiligterId !== undefined && args.chatBeteiligterId !== null) {
      this.chatBeteiligterId = args.chatBeteiligterId;
    }
  }
};
WebRtcUser.prototype = {};
WebRtcUser.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.maklernr = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.kundennr = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.ticketBeteiligterId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.I64) {
        this.chatBeteiligterId = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

WebRtcUser.prototype.write = function(output) {
  output.writeStructBegin('WebRtcUser');
  if (this.maklernr !== null && this.maklernr !== undefined) {
    output.writeFieldBegin('maklernr', Thrift.Type.STRING, 1);
    output.writeString(this.maklernr);
    output.writeFieldEnd();
  }
  if (this.kundennr !== null && this.kundennr !== undefined) {
    output.writeFieldBegin('kundennr', Thrift.Type.STRING, 2);
    output.writeString(this.kundennr);
    output.writeFieldEnd();
  }
  if (this.ticketBeteiligterId !== null && this.ticketBeteiligterId !== undefined) {
    output.writeFieldBegin('ticketBeteiligterId', Thrift.Type.STRING, 3);
    output.writeString(this.ticketBeteiligterId);
    output.writeFieldEnd();
  }
  if (this.chatBeteiligterId !== null && this.chatBeteiligterId !== undefined) {
    output.writeFieldBegin('chatBeteiligterId', Thrift.Type.I64, 4);
    output.writeI64(this.chatBeteiligterId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

const WebRtcConnection = function(args) {
  this.webrtcCallId = null;
  this.senderSignalingUUID = null;
  this.correspondentSignalingUUID = null;
  if (args) {
    if (args.webrtcCallId !== undefined && args.webrtcCallId !== null) {
      this.webrtcCallId = args.webrtcCallId;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field webrtcCallId is unset!');
    }
    if (args.senderSignalingUUID !== undefined && args.senderSignalingUUID !== null) {
      this.senderSignalingUUID = args.senderSignalingUUID;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field senderSignalingUUID is unset!');
    }
    if (args.correspondentSignalingUUID !== undefined && args.correspondentSignalingUUID !== null) {
      this.correspondentSignalingUUID = args.correspondentSignalingUUID;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field correspondentSignalingUUID is unset!');
    }
  }
};
WebRtcConnection.prototype = {};
WebRtcConnection.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.I64) {
        this.webrtcCallId = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.senderSignalingUUID = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.correspondentSignalingUUID = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

WebRtcConnection.prototype.write = function(output) {
  output.writeStructBegin('WebRtcConnection');
  if (this.webrtcCallId !== null && this.webrtcCallId !== undefined) {
    output.writeFieldBegin('webrtcCallId', Thrift.Type.I64, 1);
    output.writeI64(this.webrtcCallId);
    output.writeFieldEnd();
  }
  if (this.senderSignalingUUID !== null && this.senderSignalingUUID !== undefined) {
    output.writeFieldBegin('senderSignalingUUID', Thrift.Type.STRING, 2);
    output.writeString(this.senderSignalingUUID);
    output.writeFieldEnd();
  }
  if (this.correspondentSignalingUUID !== null && this.correspondentSignalingUUID !== undefined) {
    output.writeFieldBegin('correspondentSignalingUUID', Thrift.Type.STRING, 3);
    output.writeString(this.correspondentSignalingUUID);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

const WebRtcCall = function(args) {
  this.toUser = null;
  this.fromSignalingUUID = null;
  this.toSignalingUUID = null;
  this.videoEnabled = null;
  if (args) {
    if (args.toUser !== undefined && args.toUser !== null) {
      this.toUser = args.toUser;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field toUser is unset!');
    }
    if (args.fromSignalingUUID !== undefined && args.fromSignalingUUID !== null) {
      this.fromSignalingUUID = args.fromSignalingUUID;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field fromSignalingUUID is unset!');
    }
    if (args.toSignalingUUID !== undefined && args.toSignalingUUID !== null) {
      this.toSignalingUUID = args.toSignalingUUID;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field toSignalingUUID is unset!');
    }
    if (args.videoEnabled !== undefined && args.videoEnabled !== null) {
      this.videoEnabled = args.videoEnabled;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field videoEnabled is unset!');
    }
  }
};
WebRtcCall.prototype = {};
WebRtcCall.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.toUser = new WebRtcUser();
        this.toUser.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.fromSignalingUUID = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.toSignalingUUID = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.BOOL) {
        this.videoEnabled = input.readBool().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

WebRtcCall.prototype.write = function(output) {
  output.writeStructBegin('WebRtcCall');
  if (this.toUser !== null && this.toUser !== undefined) {
    output.writeFieldBegin('toUser', Thrift.Type.STRUCT, 1);
    this.toUser.write(output);
    output.writeFieldEnd();
  }
  if (this.fromSignalingUUID !== null && this.fromSignalingUUID !== undefined) {
    output.writeFieldBegin('fromSignalingUUID', Thrift.Type.STRING, 2);
    output.writeString(this.fromSignalingUUID);
    output.writeFieldEnd();
  }
  if (this.toSignalingUUID !== null && this.toSignalingUUID !== undefined) {
    output.writeFieldBegin('toSignalingUUID', Thrift.Type.STRING, 3);
    output.writeString(this.toSignalingUUID);
    output.writeFieldEnd();
  }
  if (this.videoEnabled !== null && this.videoEnabled !== undefined) {
    output.writeFieldBegin('videoEnabled', Thrift.Type.BOOL, 4);
    output.writeBool(this.videoEnabled);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

const WebRtcCallPreparedResult = function(args) {
  this.callId = null;
  this.webrtcConfig = null;
  this.busy = null;
  this.userBildURL = null;
  this.calleeChatBeteiligterId = null;
  this.calleeName = null;
  if (args) {
    if (args.callId !== undefined && args.callId !== null) {
      this.callId = args.callId;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field callId is unset!');
    }
    if (args.webrtcConfig !== undefined && args.webrtcConfig !== null) {
      this.webrtcConfig = args.webrtcConfig;
    }
    if (args.busy !== undefined && args.busy !== null) {
      this.busy = args.busy;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field busy is unset!');
    }
    if (args.userBildURL !== undefined && args.userBildURL !== null) {
      this.userBildURL = args.userBildURL;
    }
    if (args.calleeChatBeteiligterId !== undefined && args.calleeChatBeteiligterId !== null) {
      this.calleeChatBeteiligterId = args.calleeChatBeteiligterId;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field calleeChatBeteiligterId is unset!');
    }
    if (args.calleeName !== undefined && args.calleeName !== null) {
      this.calleeName = args.calleeName;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field calleeName is unset!');
    }
  }
};
WebRtcCallPreparedResult.prototype = {};
WebRtcCallPreparedResult.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.I64) {
        this.callId = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.webrtcConfig = new WebRtcConfig();
        this.webrtcConfig.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.BOOL) {
        this.busy = input.readBool().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.userBildURL = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.I64) {
        this.calleeChatBeteiligterId = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.calleeName = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

WebRtcCallPreparedResult.prototype.write = function(output) {
  output.writeStructBegin('WebRtcCallPreparedResult');
  if (this.callId !== null && this.callId !== undefined) {
    output.writeFieldBegin('callId', Thrift.Type.I64, 1);
    output.writeI64(this.callId);
    output.writeFieldEnd();
  }
  if (this.webrtcConfig !== null && this.webrtcConfig !== undefined) {
    output.writeFieldBegin('webrtcConfig', Thrift.Type.STRUCT, 2);
    this.webrtcConfig.write(output);
    output.writeFieldEnd();
  }
  if (this.busy !== null && this.busy !== undefined) {
    output.writeFieldBegin('busy', Thrift.Type.BOOL, 3);
    output.writeBool(this.busy);
    output.writeFieldEnd();
  }
  if (this.userBildURL !== null && this.userBildURL !== undefined) {
    output.writeFieldBegin('userBildURL', Thrift.Type.STRING, 4);
    output.writeString(this.userBildURL);
    output.writeFieldEnd();
  }
  if (this.calleeChatBeteiligterId !== null && this.calleeChatBeteiligterId !== undefined) {
    output.writeFieldBegin('calleeChatBeteiligterId', Thrift.Type.I64, 5);
    output.writeI64(this.calleeChatBeteiligterId);
    output.writeFieldEnd();
  }
  if (this.calleeName !== null && this.calleeName !== undefined) {
    output.writeFieldBegin('calleeName', Thrift.Type.STRING, 6);
    output.writeString(this.calleeName);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

const WebRtcCallResult = function(args) {
};
WebRtcCallResult.prototype = {};
WebRtcCallResult.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

WebRtcCallResult.prototype.write = function(output) {
  output.writeStructBegin('WebRtcCallResult');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

const WebRtcConfig = function(args) {
  this.signalingServerUrl = null;
  this.mediaServerUrl = null;
  this.stunServerUrl = null;
  this.turnServerUrl = null;
  this.turnServerUser = null;
  this.turnServerPassword = null;
  if (args) {
    if (args.signalingServerUrl !== undefined && args.signalingServerUrl !== null) {
      this.signalingServerUrl = args.signalingServerUrl;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field signalingServerUrl is unset!');
    }
    if (args.mediaServerUrl !== undefined && args.mediaServerUrl !== null) {
      this.mediaServerUrl = args.mediaServerUrl;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field mediaServerUrl is unset!');
    }
    if (args.stunServerUrl !== undefined && args.stunServerUrl !== null) {
      this.stunServerUrl = args.stunServerUrl;
    }
    if (args.turnServerUrl !== undefined && args.turnServerUrl !== null) {
      this.turnServerUrl = args.turnServerUrl;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field turnServerUrl is unset!');
    }
    if (args.turnServerUser !== undefined && args.turnServerUser !== null) {
      this.turnServerUser = args.turnServerUser;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field turnServerUser is unset!');
    }
    if (args.turnServerPassword !== undefined && args.turnServerPassword !== null) {
      this.turnServerPassword = args.turnServerPassword;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field turnServerPassword is unset!');
    }
  }
};
WebRtcConfig.prototype = {};
WebRtcConfig.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.signalingServerUrl = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.mediaServerUrl = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.stunServerUrl = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.turnServerUrl = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.turnServerUser = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.turnServerPassword = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

WebRtcConfig.prototype.write = function(output) {
  output.writeStructBegin('WebRtcConfig');
  if (this.signalingServerUrl !== null && this.signalingServerUrl !== undefined) {
    output.writeFieldBegin('signalingServerUrl', Thrift.Type.STRING, 1);
    output.writeString(this.signalingServerUrl);
    output.writeFieldEnd();
  }
  if (this.mediaServerUrl !== null && this.mediaServerUrl !== undefined) {
    output.writeFieldBegin('mediaServerUrl', Thrift.Type.STRING, 2);
    output.writeString(this.mediaServerUrl);
    output.writeFieldEnd();
  }
  if (this.stunServerUrl !== null && this.stunServerUrl !== undefined) {
    output.writeFieldBegin('stunServerUrl', Thrift.Type.STRING, 3);
    output.writeString(this.stunServerUrl);
    output.writeFieldEnd();
  }
  if (this.turnServerUrl !== null && this.turnServerUrl !== undefined) {
    output.writeFieldBegin('turnServerUrl', Thrift.Type.STRING, 4);
    output.writeString(this.turnServerUrl);
    output.writeFieldEnd();
  }
  if (this.turnServerUser !== null && this.turnServerUser !== undefined) {
    output.writeFieldBegin('turnServerUser', Thrift.Type.STRING, 5);
    output.writeString(this.turnServerUser);
    output.writeFieldEnd();
  }
  if (this.turnServerPassword !== null && this.turnServerPassword !== undefined) {
    output.writeFieldBegin('turnServerPassword', Thrift.Type.STRING, 6);
    output.writeString(this.turnServerPassword);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

const PING_PERIOD_MILLISECONDS = 500;
const PONG_PERIOD_MILLISECONDS = 3500;
const REFRESH_PERIOD_AS_MULTIPLE_OF_PING_PERIOD = 2;




export {WebRtcCallStatus}