<template>
  <div>
    <div class="box__title">{{ title }}</div>

    <div>
      <InputField label="Bezeichnung" v-model="form.bezeichnung" isComponentHalfSize 
        :validateUntouched="!disabled" :disabled="disabled" @change="dataChanged()" />

      <InputField label="Risikobeschreibung" v-model="form.risikotext" isComponentHalfSize 
        :disabled="disabled" @change="dataChanged()" />

      <InputField label="Beschreibung Anfang" v-model="form.beschreibung1" isComponentHalfSize 
        :disabled="disabled" @change="dataChanged()" />

      <InputToggleSwitch label="Maximaler Anteil für Wertpapiere der SRI" v-model="form.grenzeAktiv" inLineLabel 
        :disabled="disabled" @input="dataChanged()" />

      <div class="forms__input--half-size mb-3">
        <div class="row my-0">
          <div class="col-12 col-sm-3 mb-3 mb-sm-0">
            <ComboBox label="von" v-model="form.anteilUG" :values="sriValues" :disabled="isMaxSRIDisabled" 
              :validateUntouched="!isMaxSRIDisabled" @change="dataChangedCombo('anteilUG', $event); validateUgOg($event, form.anteilOG)" />
          </div>
          <div class="col-12 col-sm-3 mb-3 mb-sm-0">
            <ComboBox label="bis" v-model="form.anteilOG" :values="sriValues" :disabled="isMaxSRIDisabled" 
              :validateUntouched="!isMaxSRIDisabled" @change="dataChangedCombo('anteilOG', $event), validateUgOg(form.anteilUG, $event)" />
          </div>
          <div class="col-12 col-sm-6">
            <InputField label="am Gesamtdepot:" type="percent" v-model="form.anteilMaxSatz" :disabled="isMaxSRIDisabled" 
              :validateUntouched="!isMaxSRIDisabled" @change="dataChanged()" />
          </div>
        </div>
      </div>

      <div v-if="!isMaxSRIDisabled && validateUgOgMessage" class="fc-alert fc-alert-danger">{{ validateUgOgMessage }}</div>

      <div class="mb-3">Achtung: Wertpapiere mit Risikoklasse höher als die Obergrenze sind nicht erlaubt.</div>

      <InputTextArea label="Erklärung der Anteilsregel in Worten" v-model="form.text" :rows="3" :disabled="isMaxSRIDisabled" 
        :validateUntouched="!isMaxSRIDisabled" @change="dataChanged()" />

      <div class="mb-3">** Siehe Erläuterungen unter Risikokennzahl / Risikobereitschaft</div>

      <InputTextArea label="Beschreibung Ende" v-model="form.beschreibung2" :rows="3" 
        :disabled="disabled" @change="dataChanged()" />
    </div>
  </div>
</template>

<script>
import InputField from '@/components/core/forms/InputField.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';

import validator from '@/mixins/validator';
import { required, } from '@/mixins/validator/rules';

export default {
  mixins: [validator],
  props: {
    title: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        bezeichnung: null,
        risikotext: null,
        beschreibung1: null,
        beschreibung2: null,
        grenzeAktiv: null,
        anteilUG: null,
        anteilOG: null,
        anteilMaxSatz: null,
        text: null,
      },
      validateUgOgMessage: null,
    };
  },
  computed: {
    sriValues() {
      return [1, 2, 3, 4, 5, 6, 7];
    },
    isMaxSRIDisabled() {
      return this.disabled || this.form?.grenzeAktiv !== true;
    },
  },
  watch: {
    data: {
      handler() {
        Object.keys(this.form).forEach(key => {
          this.$set(this.form, key, this.data?.[key]);
        });

        this.validateUgOg(this.form?.anteilUG, this.form?.anteilOG);
      },
      immediate: true,
    },
  },
  methods: {
    dataChanged() {
      this.$emit('dataChanged', { ...this.form, });
    },
    dataChangedCombo(field, value) {
      this.$set(this.form, field, value);
      this.dataChanged();
    },
    validateUgOg(anteilUG, anteilOG) {
      if(anteilUG > anteilOG) {
        this.validateUgOgMessage = 'Die Untergrenze muss kleiner oder gleich der Obergrenze sein.';
        this.$nextTick(() => {
          this.$pushErrors('form.anteilUG');
          this.$pushErrors('form.anteilOG');
        });
      } else {
        this.validateUgOgMessage = null;
        this.$nextTick(() => {
          this.validation.reset('form.anteilUG');
          this.validation.reset('form.anteilOG');
        });
      }
    },
  },
  validators: {
    form: {
      bezeichnung: [required()],
      anteilUG: [required()],
      anteilOG: [required()],
      anteilMaxSatz: [required()],
      text: [required()],
    },
  },
  components: {
    InputField,
    InputToggleSwitch,
    ComboBox,
    InputTextArea,
  },
}
</script>
