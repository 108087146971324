<template>
    <div class="container">
        <span class="d-none d-lg-inline">Anzahl angezeigter Datensätze: </span>
        <span class="d-none d-md-inline d-lg-none">Anzahl Datensätze: </span>
        <span class="d-md-none">Anzahl </span>
        {{displayedItems}} von {{totalRows}}
    </div>
</template>

<script>

export default {
	props: {
        totalRows:  {
            type: Number,
            required: true
        },
        displayedItems: {
            type: Number,
            required: true
        },
    },
};
</script>

<style scoped>
.container {
    flex: 0 0 auto;
    line-height: 32px;
}
</style>
