import CORE_TYPES from '@/store/core/types';
import { onNavigationFailure } from '@/router/breadcrumb';

import { isFunction, } from '@/helpers/commonfunctions';
import { trackBreadcrumbToCurrentRouteIfNeeded, isSamePath } from '@/router/breadcrumb';

function findBreadcrumbLabel(route) {
  if (route?.meta?.breadcrumb?.label) {
    return isFunction(route.meta.breadcrumb.label) ? route.meta.breadcrumb.label(route) : route.meta.breadcrumb.label;
  } else if (route?.meta?.menu?.label) {
    return typeof route.meta.menu.label === 'function' ? route.meta.menu.label() : route.meta.menu.label;
  } else if (route?.matched?.length) {
    const matched = route.matched.find(m => m.path === route.path);
    return matched?.meta?.menu?.label || null;
  }

  return null;
}

function findBackBreadcrumbLabel(breadcrumbLabel) {
  return breadcrumbLabel ? `zurück zur ${breadcrumbLabel}` : 'zurück';
}

function removeEmptyFields(options) {
  if(!options) {
    return options;
  }

  const newOptions = Object.assign({}, options);

  if(!newOptions.label) {
    delete newOptions.label;
  }

  if(!newOptions.breadcrumb) {
    delete newOptions.breadcrumb;
  }

  return newOptions;
}

const globalMixin = {
  computed: {
    $breadcrumbs() {
      const { $route } = this;
      const breadcrumbs = [ ...this.$store.getters[CORE_TYPES.GETTERS.GET_SAVE_BACK_TO_PREVIOUS_PAGE] || [], ];

      let peek = breadcrumbs[breadcrumbs.length - 1];
      while (isSamePath($route, peek?.fullPath)) {
        breadcrumbs.pop();
        peek = breadcrumbs[breadcrumbs.length - 1];
      }

      return breadcrumbs;
    },
  },
  methods: {
    /**
     * It adds a new item in the breadcrumbs store
     * 
     * @param {Object} options 
     */
    $addBreadcrumb(options) {
      if(this.$store.getters[CORE_TYPES.GETTERS.IS_ADD_BREADCRUMB_LOCKED]) {
        return;
      }

      const label = options?.label || findBackBreadcrumbLabel(findBreadcrumbLabel(options?.from));
      const breadcrumb = options?.breadcrumb || findBreadcrumbLabel(options?.to);

      const innerOptions = removeEmptyFields(options);

      const payload = { 
        route: this.$route,
        label,
        breadcrumb,
        ...innerOptions, 
        dokumentenarchivLastFolder: options.dokumentenarchivLastFolder,
      };
    
      this.$store.dispatch(CORE_TYPES.ACTIONS.SAVE_BACK_TO_PREVIOUS_PAGE, payload);
    },

    /**
     * Add a default breadcrumb if there is not any added
     * 
     * @param {Array} breadcrumbs 
     */
    $addDefaultBreadcrumb(breadcrumbs) {
      if(!breadcrumbs?.length) return;

      // if there is breadcrumb data, it do not add the default breadcrumb
      const backToPreviousData = this.$store.getters[CORE_TYPES.GETTERS.GET_SAVE_BACK_TO_PREVIOUS_PAGE];
      if(backToPreviousData?.length > 0) return;

      breadcrumbs?.forEach(({ label, fullPath, breadcrumb, }, index) => {
        if(index > 0) {
          trackBreadcrumbToCurrentRouteIfNeeded({ path: fullPath, });
        }

        this.$addBreadcrumb({ label, fullPath, breadcrumb, });
      });
    },

    /**
     * Update an existent breadcrumb for the current route
     * 
     * @param {Object} options 
     */
    $updateCurrentBreadcrumb(options) {
      this.$store.commit(CORE_TYPES.MUTATIONS.UPDATE_CURRENT_BACK_TO_PREVIOUS_PAGE, options);
    },

    $goToLastPage() {
      const backButtonPeek = this.$breadcrumbs?.slice(-1)?.pop();
      if (!backButtonPeek) return;

      const query = { backAction: true};
      const fullPath = backButtonPeek.fullPath;

      if (backButtonPeek?.dokumentenarchivLastFolder) {
        query.dokumentenarchivLastFolder = backButtonPeek.dokumentenarchivLastFolder;
      }

      this.$router.push({ path: fullPath, query: query, })
        .catch(onNavigationFailure);
    },
  },
};

export const breadcrumbGlobalMixin = globalMixin;
