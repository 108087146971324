var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.row[_vm.column]
    ? _c("Pill", {
        style: { "text-wrap": _vm.textWrap ? "wrap" : undefined },
        attrs: {
          label: _vm.row[_vm.column].label,
          type: _vm.row[_vm.column].type,
          bordered: _vm.row[_vm.column].bordered,
          bold: _vm.row[_vm.column].bold
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }