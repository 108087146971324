<template>
  <div>
    <PageHeaderTitleNavigation
      :title="appointmentConfig.title"
      :actions="headerActions"
      @action-BACK="back()"
      @action-SAVE="save()"
      @action-CANCEL="cancel()"
      @action-EVENT_INFO="eventInfo()"
      @action-DELETE="actionDelete()"
    />

    <div class="box__container">
      <AppointmentEditCreate
        ref="edit_create"
        :subject="appointmentConfig.subject"
        :status="appointmentConfig.status"
        :isActivity="appointmentConfig.isActivity"
        :bereich="appointmentConfig.bereich"
        :vsnr="appointmentConfig.vsnr"
        :bereichId="appointmentConfig.bereichId"
        :bereichText="appointmentConfig.bereichText"
        :mode="appointmentConfig.currentMode"
        :selectedDate="appointmentConfig.selectedDate"
        :discardChanges="discardChanges"
        :additionalBeteiligte="appointmentConfig.additionalBeteiligte"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CORE_TYPES from "@/store/core/types";
import CALENDAR_TYPES from "@/store/calendar/types";
import AppointmentEditCreate from "@/components/calendar/appointmentFiles/AppointmentEditCreate.vue";
import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import PageHeaderTitleNavigation from "@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue";
import { PageHeaderSimpleAction } from "@/components/core/header-title-navigation/page-header-utils";

export default {
  name: "Appointment",
  components: {
    AppointmentEditCreate,
    OptionMenu,
    PageHeaderTitleNavigation,
  },

  props: {},

  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      appointmentConfig: CALENDAR_TYPES.GETTERS.GET_APPOINTMENT_CONFIG,
      getSelectedEvent: CALENDAR_TYPES.GETTERS.GET_SELECTED_APPOINTMENT_DATA,
    }),
    headerActions() {
      let actions = [
        PageHeaderSimpleAction("SAVE", "Speichern").withDisabled(
          () => this.$refs.edit_create?.isDisabled
        ),
        PageHeaderSimpleAction("CANCEL", "Änderungen verwerfen"),
        PageHeaderSimpleAction("EVENT_INFO", "Terminbestätigung").withDisabled(
          () =>
            !(this.getSelectedEvent.appointment && this.getSelectedEvent.appointment.sid)
        ),
        PageHeaderSimpleAction("DELETE", "Löschen").withVisible(() => 
          this.getSelectedEvent.appointment && this.getSelectedEvent.appointment.sid
        ).withDisabled(() => !this.$refs.edit_create?.canEdit || this.$refs.edit_create?.loading)
      ];

      if (this.appointmentConfig.back)
        actions.push(PageHeaderSimpleAction("BACK", "Abbrechen"));

      return actions;
    },
  },

  watch: {},

  async mounted() {},
  beforeDestroy() {},

  methods: {
    discardChanges() {
      // this.$router.back()
      if (this.appointmentConfig.discardChanges) {
        this.appointmentConfig.discardChanges();
      }
      this.$router.push({
        path: this.appointmentConfig.back,
        query: { backAction: true },
      });
    },
    back() {
      this.$router.push({
        path: this.appointmentConfig.back,
        query: { backAction: true },
      });
    },
    save() {
      this.$refs.edit_create?.saveAppointment();
    },
    cancel() {
      this.$refs.edit_create?.discardChanges();
    },
    eventInfo() {
      this.$refs.edit_create?.mailSend();
    },
    actionDelete() {
      this.$refs.edit_create?.deleteAppointment()
    }
  },

  validators: {},

  beforeRouteLeave(to, from, next) {
    next();
  },
};
</script>

<style scoped></style>
