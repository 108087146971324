var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-forms__container" },
    [
      _c(
        "div",
        { staticClass: "input-forms__label-container" },
        [
          _c("ComboBox", {
            attrs: {
              isComponentHalfSize: "",
              values: _vm.depots,
              sortComboboxValues: false
            },
            on: {
              input: function($event) {
                return _vm.setDepotpositionVerkauf($event)
              }
            },
            model: {
              value: _vm.antragData["INPUT_DEPOTPOSITION_AUSWAHL"],
              callback: function($$v) {
                _vm.$set(_vm.antragData, "INPUT_DEPOTPOSITION_AUSWAHL", $$v)
              },
              expression: "antragData['INPUT_DEPOTPOSITION_AUSWAHL']"
            }
          }),
          _c("div", { staticClass: "col" }, [
            _c(
              "div",
              {
                staticClass:
                  "input-forms__label-content input-forms__label-content--bigger"
              },
              [_c("ph-bank", { attrs: { size: 24 } }), _vm._v("  Tausch In: ")],
              1
            )
          ]),
          _c("div", { staticClass: "mt-3 pl-2" }, [
            _c(
              "div",
              { staticClass: "mt-1" },
              [
                _c(
                  "BaseButton",
                  {
                    attrs: {
                      isEmbedded: true,
                      disabled:
                        _vm.disabled ||
                        _vm.isinVerkauf === "" ||
                        _vm.rows.length > 4
                    },
                    on: { click: _vm.openFormsFinder }
                  },
                  [_vm._v("Fonds hinzufügen")]
                )
              ],
              1
            ),
            _c("span", { staticClass: "mr-2" }, [_vm._v("oder")]),
            _c(
              "div",
              { staticClass: "mt-1" },
              [
                _c(
                  "BaseButton",
                  {
                    attrs: {
                      isEmbedded: true,
                      disabled:
                        _vm.disabled ||
                        _vm.isinVerkauf === "" ||
                        _vm.rows.length > 4
                    },
                    on: { click: _vm.splitPosition }
                  },
                  [_vm._v("Neue Depotposition")]
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "input-forms__input-container mt-3" },
        [
          _vm.rows && _vm.rows.length
            ? _c("Table", {
                attrs: {
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowId: "index",
                  hidePagination: ""
                },
                on: { "action-DELETE": _vm.openModalDeleteFond },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "betrag",
                      fn: function(row) {
                        return [
                          _c("InputField", {
                            attrs: {
                              type: "currency",
                              precision: 2,
                              value: row.betrag,
                              id: "betrag" + (row.posGrpId || row.index)
                            },
                            on: {
                              change: function($event) {
                                return _vm.onChangeInputField(
                                  $event,
                                  row,
                                  "betrag"
                                )
                              }
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "bemerkung",
                      fn: function(row) {
                        return [
                          _c("InputField", {
                            attrs: {
                              value: row.bemerkung,
                              id: "bemerkung" + row.index
                            },
                            on: {
                              change: function($event) {
                                return _vm.onChangeInputField(
                                  $event,
                                  row,
                                  "bemerkung"
                                )
                              }
                            }
                          })
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1473017939
                )
              })
            : _vm._e(),
          _vm.config &&
          _vm.config.positionFields &&
          _vm.positions &&
          _vm.positions.length
            ? _c(
                "div",
                [
                  _c("WertpapierAuswahlPositionFields", {
                    attrs: {
                      config: _vm.config,
                      categoryId: _vm.categoryId,
                      antragId: _vm.antragId,
                      disabled: _vm.disabled
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("BaseModal", {
        ref: "fehlerModal",
        attrs: { labelButtonCancel: "Ok", showConfirmButton: false },
        scopedSlots: _vm._u([
          {
            key: "modalTitle",
            fn: function() {
              return [
                _c("ph-warning", {
                  staticClass: "color-danger mr-2",
                  attrs: { size: 16 }
                }),
                _c("span", { staticClass: "color-danger" }, [
                  _vm._v(
                    " Fehler: Sie haben die maximal mögliche Anzahl an Fonds erreicht. Es sind nur 5 Positionen möglich."
                  )
                ])
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("DeletePositionModal", {
        ref: "deleteModal",
        attrs: { position: _vm.positionToDelete },
        on: {
          delete: function($event) {
            return _vm.doDeletePosition()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }