<template>
  <div v-if="showEditTable">
    <BaseDropdownMenu ref="dropdown" :closeWithInnerClick="false"  :disabled="disabled">
      <template #hook-target>
        <BaseToolbarButton 
          title="Edit Table"
          :icon="icon"
          :disabled="disabled"
          :active="true"
          class="mr-0"/>
      </template>
      <template #default>
        <div class="tb-buttons__container layout__negative-margin--8 d-flex">
          <TBAddColumnBefore showLabel :editor="editor" :disabled="disabled"/>
          <TBAddColumnAfter showLabel :editor="editor" :disabled="disabled"/>
          <TBDeleteColumn showLabel :editor="editor" :disabled="disabled"/>
          <hr class="divider">
          <TBAddRowBefore showLabel :editor="editor" :disabled="disabled"/>
          <TBAddRowAfter showLabel :editor="editor" :disabled="disabled"/>
          <TBDeleteRow showLabel :editor="editor" :disabled="disabled"/>
          <hr class="divider">
          <TBToggleReaderRow showLabel :editor="editor" :disabled="disabled"/>
          <TBMergeCells showLabel :editor="editor" :disabled="disabled"/>
          <TBSplitCells showLabel :editor="editor" :disabled="disabled"/>
          <TBDeleteTable showLabel :editor="editor" :disabled="disabled"/>
          <hr class="divider">
          <TBAddTable showLabel forceShowAddTable :editor="editor" :disabled="disabled"/>
          <hr class="divider">
          <TBTableBorderSize showLabel :editor="editor" :disabled="disabled"/>
        </div>
      </template>
    </BaseDropdownMenu>
  </div>
</template>

<script>
import { PhTable } from 'phosphor-vue';
import BaseToolbarButton from '../../base-components/BaseToolbarButton.vue';
import BaseDropdownMenu from '@/components/core/BaseDropdownMenu.vue'
import TBAddColumnBefore from './TBAddColumnBefore.vue';
import TBAddColumnAfter from './TBAddColumnAfter.vue';
import TBDeleteColumn from './TBDeleteColumn.vue';
import TBAddRowBefore from './TBAddRowBefore.vue';
import TBAddRowAfter from './TBAddRowAfter.vue';
import TBDeleteRow from './TBDeleteRow.vue';
import TBMergeCells from './TBMergeCells.vue';
import TBSplitCells from './TBSplitCells.vue';
import TBDeleteTable from './TBDeleteTable.vue';
import TBTableBorderSize from './TBTableBorderSize.vue';
import TBAddTable from './TBAddTable.vue';
import { BORDER_OPTIONS } from '../../custom-extensions/CustomTable';
import TBToggleReaderRow from './TBToggleHeaderRow.vue'

export default {
  components: {
    TBAddColumnBefore,
    TBAddColumnAfter,
    TBDeleteColumn,
    TBAddRowBefore,
    TBAddRowAfter,
    TBDeleteRow,
    TBMergeCells,
    TBSplitCells,
    TBDeleteTable,
    TBToggleReaderRow,
    BaseToolbarButton,
    BaseDropdownMenu,
    TBTableBorderSize,
    TBAddTable,
  },

  props: {
    editor: {
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      borderOptions: [ ...BORDER_OPTIONS ],
    }
  },

  computed: {
    icon() {
      return PhTable;
    },
    borderValue() {
      return BORDER_OPTIONS
        .map(val => val.value)
        .find(value => this.editor?.isActive('table', { border: value }));
    },
    isTableHeader() {
      return this.editor?.isActive('tableHeader');
    },

    showEditTable() {
      return this.editor?.isActive('table')
    }
  },

}
</script>

<style scoped>
.tb-buttons__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.divider {
  width: 100%;
  margin-right: 0;
  margin-left: 0;
}
</style>
