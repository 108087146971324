var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showTapiPanel
    ? _c(
        "div",
        { staticClass: "container" },
        [
          _c("div", { staticClass: "header" }, [
            _c("span", { staticClass: "header-outer" }, [
              _vm._v(" " + _vm._s(_vm.displayName) + " ")
            ]),
            _c(
              "div",
              { staticClass: "icon-button-container" },
              [
                this.ringing && this.tapiOpened
                  ? _c(
                      "ph-phone",
                      {
                        staticClass: "icon-button",
                        attrs: { size: 32, color: "var(--color-success)" },
                        on: { click: _vm.clickPhoneAnswer }
                      },
                      [_c("title", [_vm._v("Annehmen")])]
                    )
                  : _vm._e(),
                _vm.activeCallTapi1 && this.tapiOpened
                  ? _c(
                      "ph-phone-disconnect",
                      {
                        staticClass: "icon-button",
                        attrs: { size: 32, color: "var(--color-danger)" },
                        on: { click: _vm.clickPhoneDisconnect }
                      },
                      [_c("title", [_vm._v("Auflegen")])]
                    )
                  : _vm._e(),
                _c(
                  "ph-gear",
                  {
                    staticClass: "icon-button",
                    attrs: { size: 32 },
                    on: { click: _vm.doSet }
                  },
                  [_c("title", [_vm._v("Einstellungen")])]
                ),
                _c(
                  "ph-x",
                  {
                    staticClass: "icon-button",
                    attrs: { size: 32 },
                    on: { click: _vm.close }
                  },
                  [_c("title", [_vm._v("Schließen")])]
                ),
                this.canTapiHost && this.tapiOpened
                  ? _c("InputToggleSwitch", {
                      attrs: {
                        label: "Telefonservice aktiviert",
                        inLineLabel: ""
                      },
                      model: {
                        value: _vm.tapiHostSwitch,
                        callback: function($$v) {
                          _vm.tapiHostSwitch = $$v
                        },
                        expression: "tapiHostSwitch"
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c(
              "span",
              {
                staticClass: "header-outer",
                staticStyle: { "text-align": "right" }
              },
              [
                this.startCallTime
                  ? _c("span", [_vm._v(_vm._s(_vm.callDuration))])
                  : _vm._e()
              ]
            )
          ]),
          _c("SetupTapiDialog", { ref: "SetupTapiDialog" })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }