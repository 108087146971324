var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: {
        labelButtonConfirm: "Ja",
        showCloseButton: true,
        labelButtonCancel: "Nein"
      },
      on: {
        onConfirmButton: _vm.call,
        onCancelButton: _vm.close,
        onCloseButton: _vm.close
      },
      scopedSlots: _vm._u([
        {
          key: "modalTitle",
          fn: function() {
            return [_vm._v(" Ausgehender Anruf ")]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" Möchten Sie "),
      _c("strong", [_vm._v(" " + _vm._s(_vm.pn) + " ")]),
      _vm._v(" wirklich wählen? "),
      _c("div")
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }