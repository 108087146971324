<template>
  <div class="customer-bankkonten__container box__container-wrap">
    <div class="box__container-rows">
        <Table v-if="accounts.length"
            tableId="e8a13936-8e4e-4c5a-8bd3-8e677e0720ed"
            :headers="headers"
            :rows="accounts"
            rowId="id"
            ignoreTablePageSize
            :rowsPerPage="5"
            :tableRowsPerPage="[]"
            :mobileConfig="{title: 'Name', headers: ['Kontonummer', 'Kontostand'], disableClickRow: true}"
        />
        <NoData v-else noIcon />
    </div>
    <div class="customer-bankkonten__bottom bottom-row">
      <div class="customer-bankkonten__links text-right">
        <router-link to="/home/account" v-if="!$isSmallScreen">Bankkonten</router-link>
        <router-link 
          v-if="showLinkToBankverbindung" 
          to="/persoenlichedaten/customer-data/steps/bankverbindung" 
          class="ml-3">
            Bankverbindungen anzeigen
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ACCOUNT_TYPES from '@/store/account/types';
import NoData from '@/components/core/NoData.vue';
import Table from '@/components/table2/Table.vue';
import {TextColumn, CurrencyColumn} from '@/components/table2/table_util.js';
import CUSTOMERDATA_TYPES from '@/store/customerData/types';
import CORE_TYPES from '@/store/core/types'
import { ROLES } from '@/router/roles';

export default {
  data() {
    return {
        headers: {
            lockedLeft: [
                TextColumn("Name", "Name"),
            ],
            center: [
                TextColumn("Kontonummer", "Kontonummer"),
            ],
            lockedRight: [
                CurrencyColumn("Kontostand", "Kontostand"),
            ],
        },
    };
  },
  computed: {
    ...mapGetters({
      accountsData: ACCOUNT_TYPES.GETTERS.ACCOUNTS,
      customerData: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_DATA,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
    showLinkToBankverbindung() {
      return !this.hasRoles([ROLES.SUPER_CUSTOMER])
    },
    accounts() {
      const accountsData = this.accountsData || {};
      const bankAccounts = this.customerData?.bankAccounts || [];

      const headers = (accountsData.tableHeaders || []).reduce((acc, curr) => ({...acc, ...({[curr.key] : curr})}), {});
      const headersKeys = Object.keys(headers);

      const accounts = (accountsData.records || []).map((tuple, i) => {
        return tuple.reduce((acc, curr, index) => ({
          ...acc, 
          ...({[headersKeys[index]] : curr}),
          id: (acc?.Kontonummer || '') + i,
          }), {});
      });
      bankAccounts.map((account, index) => {
        accounts.push({
          Name: account.bankName,
          Kontostand: '',
          Kontonummer: account.iban,
          id: account.id + index,
        })
      })
      return accounts;
    },
  },
  mounted() {
    this.$store.dispatch(ACCOUNT_TYPES.ACTIONS.GET_ACCOUNTS);
    this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA);
  },
  components: {
    NoData,
    Table,
  },
}
</script>

<style src='../steckbrief.scss' lang='scss' scoped></style>

<style lang="scss" scoped>
.customer-bankkonten__bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  .customer-bankkonten__links {
    flex: 1 0 auto;
  }
}

@media screen and (max-width: 660px) {
  .customer-bankkonten__links {
    display: flex;
    flex-flow: column;

    a {
      margin: 0;
    }
  }
}

@media screen and (max-width: 440px) {
  .customer-bankkonten__bottom {
    flex-flow: column;

    .customer-bankkonten__links {
      text-align: right;
    }
  }
}
</style>
