var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "editable_link_container" }, [
        _vm.isBroker
          ? _c(
              "div",
              { staticClass: "text-small edit", on: { click: _vm.edit } },
              [
                _vm._v(" bearbeiten "),
                _c("ph-pencil-line", {
                  attrs: { title: "Bearbeiten", size: 16 }
                })
              ],
              1
            )
          : _vm._e(),
        _vm.isBroker ? _c("div", { staticClass: "innerContent" }) : _vm._e()
      ]),
      _c(
        "BaseModal",
        {
          ref: "editSourceNewsFeed",
          attrs: { modalTitle: "RSS-Feed" },
          on: {
            onConfirmButton: function($event) {
              return _vm.onConfirmModal()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "div",
                    { staticClass: "col-auto" },
                    [
                      _c(
                        "BaseButton",
                        {
                          attrs: { isSecondary: "" },
                          on: { click: _vm.deleteCustomRssSrc }
                        },
                        [_vm._v("Standard RSS-Feed")]
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", { staticClass: "inner_modal" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(
                " Bitte ergänzen Sie den RSS-Feed für Ihre Nachrichten mit kompletter URL. "
              )
            ]),
            _c("div", [
              _vm._v(
                " Wird keine URL eingetragen, wird der Nachrichten-Bereich ausgeblendet. "
              )
            ]),
            _c(
              "div",
              { staticClass: "social" },
              [
                _c("ph-rss", { attrs: { size: 16 } }),
                _c("div", { staticClass: "social_title" }, [
                  _vm._v("RSS-Feed:")
                ]),
                _c("InputField", {
                  model: {
                    value: _vm.rssSrc,
                    callback: function($$v) {
                      _vm.rssSrc = $$v
                    },
                    expression: "rssSrc"
                  }
                })
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }