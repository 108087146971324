<template>
  <BaseButton @click="onclick">{{label}}</BaseButton>
</template>

<script>
import { mapGetters } from 'vuex';
import ANTRAG_TYPES from '@/store/antrag/types';
import CUSTOMERDATA_TYPES from "@/store/customerData/types.js";
import LOG_TYPES from '@/store/log/types';
import BaseButton from '@/components/core/BaseButton.vue';

export default {
  components: {
    BaseButton,
  },
  props: {
    label: {
      default: ''
    },
    config: {
      required: true,
    },
    value: {
    },
    antragId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      antragData: ANTRAG_TYPES.GETTERS.ANTRAEGE_DATA,
      customerData: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA,
      additionalPersons: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_ADDITIONAL_PERSONS,
    }),
    persons() {
      const additionalPersons = (this.additionalPersons || []);
      return [this.customerData, ...additionalPersons];
    },
  },
  data() {
    return {
      type: this.config.type,
    }
  },
  methods: {
    onclick() {
      const positions = this.value ? this.value.slice() : []
      switch (this.type) {
        case "BUTTON_PERSDATEN_UEBERNEHMEN":
          if (!confirm("Sollen die Personendaten für ALLE Fremd-Depots übernommen werden?"))
            return
          for (let i = 1; i <= 2; i++) {
            let person = null
            if (this.antragData[this.antragId]["HAKEN_PERSON" + i + "_LOESCHEN"] == "1") {
              person = {}
              this.antragData[this.antragId]["HAKEN_PERSON" + i + "_LOESCHEN"] = "0"
            }
            else {
              const personId = this.antragData[this.antragId]["INPUT_PERSONEN_INHABER" + i]
              if (!personId)
                continue
              person = this.persons.find(person => person && (person.personId+'') === (personId+''))
            }
            if (person) {
              positions.forEach(position => {
                position["bisherName" + i] = person.personalDataAddress && person.personalDataAddress.lastName || ""
                position["bisherVorname" + i] = person.personalDataAddress && person.personalDataAddress.firstName || ""
                position["bisherID" + i] = person.personalDataTax && person.personalDataTax.taxID || ""
              });
            }
          }
          break;
        case "BUTTON_UEBERTRAGUNGSDATEN_UEBERNEHMEN":
          if (!confirm("Soll die Übertragsart für ALLE Fremd-Depots übernommen werden?"))
            return
          positions.forEach(position => {
            position["uebertragArt"] = this.antragData[this.antragId][this.config.uebertragArt]
          });
          break;
        case "BUTTON_DEPOTAKTIONEN_UEBERNEHMEN":
          if (!confirm("Sollen die Depotaktionen für ALLE Fremd-Depots übernommen werden?"))
            return
          positions.forEach(position => {
            position["sparplanBeenden"] = this.antragData[this.antragId][this.config.sparplanBeenden] == "1"
            position["kontoBeenden"] = this.antragData[this.antragId][this.config.kontoBeenden] == "1"
            position["kontoGuthabenEmpfaenger"] = this.antragData[this.antragId][this.config.kontoGuthabenEmpfaenger] == "1"
            if (position["kontoGuthabenEmpfaenger"]) {
              position["nameVornameGuthabenEmpfaenger"] = this.antragData[this.antragId][this.config.nameVornameGuthabenEmpfaenger]
              position["ibanGuthabenEmpfaenger"] = this.antragData[this.antragId][this.config.ibanGuthabenEmpfaenger]
              position["bicGuthabenEmpfaenger"] = this.antragData[this.antragId][this.config.bicGuthabenEmpfaenger]
            }
          });
          break;
        case "BUTTON_VERLUSTVERRECHUNGSSALDEN_UEBERNEHMEN":
          if (!confirm("Sollen die Einstellungen für Verlustverrechnungssalden für ALLE Fremd-Depots übernommen werden?"))
            return
          positions.forEach(position => {
            position["verrechnungssalden"] = this.antragData[this.antragId][this.config.verrechnungssalden]
            position["verrechnungssaldo_aktien"] = this.antragData[this.antragId][this.config.verrechnungssaldo_aktien] == "1"
            position["verrechnungssaldo_sonstige"] = this.antragData[this.antragId][this.config.verrechnungssaldo_sonstige] == "1"
            position["verrechnungssaldo_auslq"] = this.antragData[this.antragId][this.config.verrechnungssaldo_auslq] == "1"
          });
          break;
        default:
          this.$store.dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "unknown type in Comdirect Depotuebertragung button", type: this.type});
      }
      this.$emit('input', positions)
    },
  },
}
</script>

<style scoped>

</style>