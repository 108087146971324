<template>
  <div :class="{ today: istoday && shouldPaint, not_in_month: !inmonth }">
    <div class="innerday">
      <div class="week-events">
        <div :class="{'upper': true, 'all-day-upper': isAllDay, 'upper-full-height': isAllVisible}" v-if="isAllDay">
          <div class="allday-part" v-if="eventsReady" @click="editEventNew(true)"
            @touchstart="touchstart(true)">
            <ExpandedEventView v-for="(alldayevent, index) in allDayEventsVisible" :key="index" :event="alldayevent" isOneLineContainer>
              <div
                data-event-view
                :class="['allday-events', 'clickable', 'event-' + alldayevent.type, { 'has-repetition': alldayevent.repetition, }]"
                @click="setSelectedEvent(alldayevent, $event)"
                :style="{ 'background-color': isSelected(alldayevent) ? alldayevent.color : alldayevent.lighterColor, }"
              >
                <div class="event-cell-before" :style="{ 'background-color': alldayevent.color}"></div>
                <div class="allday-event--wrap sameline">
                  <component v-if="alldayevent.introIcon" :is="alldayevent.introIcon"/>
                  <span v-else-if='alldayevent.startTime !== "00:00" && alldayevent.startTime !== "23:59"'>
                      <b> {{alldayevent.startTime}} </b>
                  </span> 
                  <span class="event-label">{{alldayevent.label}}</span>
                  <span v-if="alldayevent.repetition" class="allday-repetition-icon"><PhArrowsClockwise size="16" /></span>
                </div>
              </div>
            </ExpandedEventView>
            <div class="timed-events" v-if="isShowMore">
              <button type="button" class="btn-clear clickable" @click="handleShowMore">
                <template v-if="!isAllVisible">{{moreCount}} weitere...</template>
                <template v-else>Reduzieren</template>
              </button>
            </div>
          </div>
        </div>
        <div class="timed-part" v-if="eventsReady && !isAllDay">
          <WholeHourCell
            v-for="(hour,idx) in hours"
            :key="idx"
            :hour="hour"
            :date="date"
            :daylabel="daylabel"
            :maxconcurrent="getConcurrentEvents"
            :dayindex="dindex"
            :style="setStyle"
            :allHourEvents="allHourEvents"
            @editEvent="editEvent"
            class="whole-hour-cell"
            @click.native="editEventNew(false, hour)"
            @touchstart.native="touchstart(false, hour)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import CALENDAR_TYPES from "@/store/calendar/types";
import ExpandedEventView from '@/components/calendar/ExpandedEventView.vue';
import WholeHourCell from "@/components/calendar/WholeHourCell.vue";
import { PhGift, PhBriefcase, PhArrowsClockwise, } from 'phosphor-vue';
const MAX_ALL_DAY_COUNT = 3;

export default {
  components: {
    ExpandedEventView,
    WholeHourCell,
    PhGift,
    PhBriefcase,
    PhArrowsClockwise,
  },

  props: {
    isAllDay: {
      type: Boolean,
      default: false,
    },
    istoday: {
      type: Boolean,
      default: () => false,
      required: true
    },
    inmonth: {
      type: Boolean,
      default: () => true,
      required: true
    },
    hours: {
      type: Array,
      default: () => [],
      required: true
    },
    daylabel: {
      type: String,
      default: () => "",
      required: true
    },
    dindex: {
      type: Number,
      default: () => 0,
      required: true
    },
    calledfrom: {
      type: String,
      default: () => 0,
      required: true
    },
    date: {
      type: Object,
      default: () => dayjs(),
      required: true
    },
  },
  data() {
    return {
      columnEventArray: [],
      index: 0,
      isAllVisible: false,
      timer: null,
      eventsAdded: false,
      allHourEvents: {},
    };
  },
  computed: {
    ...mapGetters({
      inDayCompGetWeekCalendarData:
        CALENDAR_TYPES.GETTERS.GET_WEEK_CALENDAR_DATA,
      getSelectedEvent: CALENDAR_TYPES.GETTERS.GET_SELECTED_APPOINTMENT_DATA,
      getEmptyAppointment: CALENDAR_TYPES.GETTERS.GET_EMPTY_APPOINTMENT,
      getHoursContent: CALENDAR_TYPES.GETTERS.GET_HOURS_CONTENT,
    }),
    shouldPaint() {
      if (this.calledfrom === "week") return true;
      else return false;
    },
    setStyle() {
      if (this.calledfrom === "week") return "height:4em";
      if (this.calledfrom === "day") return "height:4em";
      return "";
    },
    getLabel() {
      if (this.calledfrom === "week") return this.daylabel;
      if (this.calledfrom === "day") return "";
      return "";
    },
    allDayEvents() {
      return this.inDayCompGetWeekCalendarData[this.dindex].allDayEvents.map(this.mapEvent);
    },
    timedEvents() {
      return this.inDayCompGetWeekCalendarData[this.dindex] &&
        this.inDayCompGetWeekCalendarData[this.dindex].timedEvents.map(this.mapEvent) || [];
    },
    eventsReady() {
      this.setTaps();
      this.setAllHourEvents();
      return this.inDayCompGetWeekCalendarData[this.dindex] !== undefined;
    },
    getConcurrentEvents() {
      let endConcurrArray = [];
      if (this.timedEvents !== undefined) {
        for (let i = this.timedEvents.length - 1; i > 0; i--) {
          let tempnum = 0;
          for (let k = i - 1; k > -1; k--) {
            if (
              dayjs(this.timedEvents[i].startTime, "HH:mm").isBefore(
                dayjs(this.timedEvents[k].endTime, "HH:mm")
              )
            ) {
              tempnum = tempnum + 1;
            }
          }
          if (tempnum > 0) {
            endConcurrArray.push(tempnum);
          }
        }
        let finalnum = 0;
        for (let j = 0; j < endConcurrArray.length; j++) {
          if (endConcurrArray[j] > finalnum) finalnum = endConcurrArray[j];
        }
        return finalnum;
      } else return 0;
    },
    allDayEventsVisible() {
      return this.isAllVisible ? this.allDayEvents : this.allDayEvents.slice(0, MAX_ALL_DAY_COUNT);
    },
    isShowMore() {
      return this.allDayEvents && this.allDayEvents.length > MAX_ALL_DAY_COUNT;
    },
    moreCount() {
      return this.allDayEvents && this.allDayEvents.length - MAX_ALL_DAY_COUNT;
    },
  },

  methods: {
    isSelected(event) {
      if (!event?.id) return false;
      return this.getSelectedEvent?.appointment?.sid == event.id;
    },
    setSelectedEvent(allDayEvent, e) {
      if (e) e.stopPropagation();
      if (allDayEvent.type === "appoitment") {
        this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_CLICKED_ON_EVENT, true);
        this.$store.dispatch(
          CALENDAR_TYPES.ACTIONS.RETRIEVE_SELECTED_APPOINTMENT,
          allDayEvent.id
        );
        this.$emit('editEvent', allDayEvent);
      }
    },
    editEvent(e) {
      this.$emit('editEvent', e);
    },
    editEventNew(fullDay, hour) {
      this.createAppointment(fullDay, hour, this.date)
      this.$emit('editEvent', {bereich: 'Termin'});
    },
    createAppointment(fullDay, hour, date) {
      const theDate = hour ? dayjs(date).hour(hour.split(':')[0]) : dayjs(date)
      const { getEmptyAppointment, } = this;
      this.$store.commit(
        CALENDAR_TYPES.MUTATIONS.PRESAVE_APPOINTMET,
        {
          appointment: {
            ...getEmptyAppointment,
            fullDay,
            begin: `${dayjs(date).format('DD.MM.YYYY')} ${hour || '00:00'}`,
            end: `${dayjs(date).format('DD.MM.YYYY')} ${!fullDay && hour && dayjs(hour, 'HH:mm').add(1, 'hour').format('HH:mm') || '23:59'}`,
            activity: false,
            bereich: 'Termin'
          },
          beteiligte: [],
          allUsers: [],
          allAttachments: [],
          day: theDate
        }
      );
    },

    mapEvent(event) {
        let introIcon = null;
        if (event.type === "birthday")
            introIcon = PhGift;
        else if (event.type === "appoitment" && (event.startTime === "00:00" || event.startTime === "23:59"))
            introIcon = PhBriefcase;
        return {
            ...event,
            introIcon,
            label: event.label || 'Termin'
        }
    },
    handleShowMore(ev) {
      ev.stopPropagation();
      this.isAllVisible = !this.isAllVisible;
      this.$nextTick(() => window.dispatchEvent(new Event('resize')));
    },
    touchstart(fullDay, hour) {
      this.touchend();
      this.timer = setTimeout(() => this.editEvent(fullDay, hour), 1500);
    },
    touchend() {
      if (this.timer)
        clearTimeout(this.timer);
      this.timer = null;
    },
    setTaps() {
      if (!this.eventsAdded) {
        setTimeout(this.ontap, 0);
        this.eventsAdded = true;
      }
    },
    ontap() {
      window.addEventListener('touchend', this.touchend, false);
    },
    setAllHourEvents() {
      // sort the events by the start hour
      const timedEventsSorted = [...this.timedEvents]
        .map(event => {
          const eventStart = dayjs(event.startTime || '00:00', 'HH:mm');
          const eventEnd = dayjs(event.endTime || '23:00', 'HH:mm');
          const spotsSize = eventEnd.diff(eventStart, 'hour');
          const hoursSpots = [...new Array(eventEnd.format('mm') === '00' ? spotsSize : spotsSize+1)]
            .map((_, i) => i)
            .map(v => `${eventStart.add(v, 'hour').format('HH')}:00`);

          return {
            ...event,
            _startHour: `${eventStart.format('HH')}:00`,
            _hoursSpots: hoursSpots,
          };
        })
        .sort((event1, event2) => {
          const event1Start = dayjs(event1.startTime, 'HH:mm');
          const event2Start = dayjs(event2.startTime, 'HH:mm');
          if(event1Start.isBefore(event2Start)) {
            return -1;
          } else if(event2Start.isBefore(event1Start)) {
            return 1;
          }
          return 0;
        });

      // group by the start hour
      const timedEventsGrouped = timedEventsSorted.groupBy(event => event._startHour);

      // sort the events in the hour spots by the longer to shorter
      const timedEventsGroupedSorted = Object.keys(timedEventsGrouped).reduce((acc, key) => {
        return {
          ...acc,
          [key]: timedEventsGrouped[key].sort((event1, event2) => {
            const event1Start = dayjs(event1.startTime || '00:00', 'HH:mm');
            const event1End = dayjs(event1.endTime || '23:00', 'HH:mm');
            const event2Start = dayjs(event2.startTime || '00:00', 'HH:mm');
            const event2End = dayjs(event2.endTime || '23:00', 'HH:mm');

            if(event1Start.diff(event1End, 'minutes') < event2Start.diff(event2End, 'minutes')) {
              return -1;
            } else if(event1Start.diff(event1End, 'minutes') > event2Start.diff(event2End, 'minutes')) {
              return 1;
            }
            return 0;
          }),
        };
      }, {})

      // add the timed event to the hour spot as ghost
      timedEventsSorted.forEach(event => {
        event._hoursSpots.forEach(hour => {
          const index = timedEventsGroupedSorted[event._startHour].findIndex(ev => ev.id === event.id);
          if(timedEventsGroupedSorted[hour]?.findIndex(ev => ev.id === event.id) < 0) {
            timedEventsGroupedSorted[hour].splice(index, 0, {
              ...event,
              ghost: true,
            });
          }
        });
      });

      // fill with the remaining events
      timedEventsSorted.forEach(event => {
        const realHoursSpots = Object.keys(
            event._hoursSpots
              .reduce((acc, hour) => [...acc, hour, ...(timedEventsGroupedSorted[hour]?.flatMap(ev => [...ev._hoursSpots]) || [])], [])
              .reduce((acc, hour) => ({ ...acc, [hour]: '', }), {})
          );

        const total = realHoursSpots.reduce((acc, hour) => Math.max(acc, timedEventsGroupedSorted[hour]?.length || 0), 0);
        if(total > 0) {
          event._hoursSpots.forEach(hour => {
            const currentTotal = timedEventsGroupedSorted[hour]?.length || 0;
            [ ...new Array(total - currentTotal), ].forEach(() => {
              timedEventsGroupedSorted[hour] ??= [];
              timedEventsGroupedSorted[hour].push({
                ...event,
                ghost: true,
              });
            });
          });
        }
      });

      const { hours } = this;
      const allHourEvents = hours.reduce((acc, hour) => ({
        ...acc,
        [hour]: timedEventsGroupedSorted?.[hour] || [],
      }), {});
      this.$set(this, 'allHourEvents', allHourEvents);
    },
  },
  mounted() {
    this.setAllHourEvents();

    this.$store.commit(CALENDAR_TYPES.MUTATIONS.RENEW_COLUMN_EVENT_ARRAY);
  },
  beforeDestroy() {
    window.removeEventListener('touchend', this.touchend);
  }
};
</script>
<style scoped>
* {
  box-sizing: border-box;
}

.innerday {
  width: 100%;
}

.week-events {
  text-align: left;
  width: 100%;
  height: 100%;;
}
.hour-cell {
  border-bottom: 1px solid var(--borderColor);
  box-sizing: border-box;
}

.allday-part {
  height: 100%;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  padding: 0.25rem;
}
.upper {
  height: 100%;
  border-bottom: 1px solid var(--borderColor);
}
.upper.all-day-upper {
  border: none;
}
.upper.upper-full-height {
  height: auto;
}
.allday-part::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}
.allday-events.event-appoitment {
  position: relative;
  overflow: hidden;
  padding-left: 0;
}
.has-repetition .allday-event--wrap {
  background: inherit;
  box-sizing: border-box;
  padding-right: 16px;
}
.event-cell-before {
  display: block;
  content: " ";
  background-color: var(--color-primary);
  height: 110%;
  width: 4px;
  position: absolute;
  left: 0;
  top: 0;
}
.allday-repetition-icon {
  background: inherit;
  position: absolute;
  right: 2px;
  bottom: 2px;
}

.title {
  font-weight: bold;
  height: 20%;
}

.today {
  
}

.not_in_month {
  color: var(--color-text);
 
}
.allday-events {
  margin-top: 0.25em;
  color: var(--color-text);
  background-color: var(--color-success-background);
  border-radius: 8px;
  padding: 0;
  line-height: 1;
  position: relative;
  overflow: hidden;
}

@media (prefers-color-scheme: dark) {
  .allday-events {
    color: var(--color-box);
  }
}

.allday-events .sameline {
  padding: 0.25rem 0.25rem 0.25rem 0.5rem;
}
.event-birthday .event-cell-before {
  display: none;
}
.event-appoitment,
.event-appointment {
  background: var(--color-info-background);
}
.event-birthday {
  background: var(--color-danger-background);
}
.sameline {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.event-label {
  vertical-align: middle;
  margin: 0 0 0 2px;
}
</style>