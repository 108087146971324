var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Auswahl Vertragsart",
          subtitle: _vm.categorySelected && _vm.categorySelected.name,
          defaultMenu: _vm.customOptionMenu,
          isLoading: _vm.loading
        }
      }),
      _vm.categorySelected && _vm.categorySelected.groups.length
        ? _c(
            "div",
            {
              staticClass: "box__container",
              attrs: {
                tid: _vm._generateTidFromString("box__container-suchen")
              }
            },
            [
              _c("div", { staticClass: "box__title" }, [_vm._v("Suchen")]),
              _c("InputField", {
                attrs: {
                  isComponentHalfSize: "",
                  placeholder: "Suchen nach Bezeichnung"
                },
                model: {
                  value: _vm.descriptionSearch,
                  callback: function($$v) {
                    _vm.descriptionSearch = $$v
                  },
                  expression: "descriptionSearch"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "cards__container layout__negative-margin--8" },
        _vm._l(_vm.groups, function(group, index) {
          return _c(
            "div",
            { key: index, staticClass: "box__container cards__item" },
            [
              _c("Table", {
                attrs: {
                  tableId: "fa5bef18-bb0a-423e-8fdc-fb5ca1cd1baf",
                  headers: _vm.headers(group),
                  rows: _vm.filteredBezeichnungen(group),
                  hidePagination: ""
                },
                on: { "click-label": _vm.handleLabelClick }
              })
            ],
            1
          )
        }),
        0
      ),
      _vm.showAddVersicherungModal
        ? _c("AddVersicherungModal", {
            on: {
              close: function($event) {
                _vm.showAddVersicherungModal = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }