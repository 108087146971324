<template>
  <div class="box__container">

    <Table v-if="!loading && rows.length"
        tableId="e1078c62-25d3-4185-a475-a6c2a2e7106f"
        :headers="headers"
        :rows="rows"
        :rowsPerPage="10"
        rowId="sid"
        @click-label="openEditModal"
        @action-DELETE="openDeleteModal"
    />
    <div v-else-if="loading" class="text-centered"><AnimatedSpinner /></div>
    <div v-else>Keine Daten</div>

    <BaseModal
      ref="modalDelete"
      modalTitle="Eintrag löschen"
      size="sm"
      @onConfirmButton="deleteAppointment()"
    >
      <template #default>
        Soll der Eintrag "{{ appointmentToDelete && appointmentToDelete.label || '' }}" wirklich gelöscht werden?
      </template>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CALENDAR_TYPES from '@/store/calendar/types';
import OTHER_PRODUCTS_TYPES from '@/store/otherProducts/types';
import LOG_TYPES from '@/store/log/types';

import { 
  PhCheck,
} from 'phosphor-vue';

import Table from "@/components/table2/Table.vue";
import {TextColumn, DateColumn, DateTimeColumn, IconColumn, PillColumn, Icon, CurrencyColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import Pill from '@/components/core/Pill.vue';
import AppointmentEditCreate from '@/components/calendar/appointmentFiles/AppointmentEditCreate.vue';

const MAP_WV_STATUS_TO_PILL_TYPE = {
  UNBESTAETIGTE_VERGANGENHEIT: 'warning',
  UNBESTAETIGTE_ZUKUNFTIG: 'info',
  BESTAETIGTE: 'success',
};

const BEREICH = 'SonstigeProdukte';


export default {
  data() {
    return {
        loading: false,
        appointmentToDelete: null,
        headers: {
            lockedLeft: [
                TextColumn("label", "Betreff").makeLink(),
            ],
            center: [
                PillColumn("wvStatusMessage", "Status"),
                IconColumn("retry", "Wiederholung"),
                DateTimeColumn("begin", "Zeitpunkt"),
                TextColumn("adressatText", "Adressat"),
                TextColumn("text", "Text"),
                TextColumn("artText", "Art"),
                TextColumn("bereichText", "Bereich"),
                TextColumn("customerNames", "Beteiligte Kunden"),
                TextColumn("ownerName", "Besitzer"),
                DateColumn("dateCreated", "Erstellungsdatum"),
                CurrencyColumn("geschaeftwert", "Geschätzter Geschäftswert"),
                TextColumn("produkt", "Produkt"),
                TextColumn("sellingPhaseText", "Verkaufphase"),
                TextColumn("statusText", "Status"),
            ],
            lockedRight: [
                ActionColumn("actions", "Aktionen"),
            ],
        },
        MAP_WV_STATUS_TO_PILL_TYPE,
        BEREICH,
    };
  },
  computed: {
    ...mapGetters({
      sprodId: OTHER_PRODUCTS_TYPES.GETTERS.SELECTED_SPROD_ID,
      otherProduct: OTHER_PRODUCTS_TYPES.GETTERS.OTHER_PRODUCT,
      appointments: OTHER_PRODUCTS_TYPES.GETTERS.APPOINTMENTS,
      appointmentCombos: OTHER_PRODUCTS_TYPES.GETTERS.APPOINTMENT_COMBOS,
    }),
    rows() {
        const actions = [
            SimpleAction("DELETE", 'PhTrash', "Löschen"),
        ];
        return (this.appointments || []).map(item => ({
            ...item.appointment,
            retry: item.appointment.retry && item.appointment.retry !== 'Keine' ? [
                Icon(PhCheck, "Ja", 24),
            ] : undefined,
            wvStatusMessage: item.appointment.wvStatus ? {
                label: this.appointmentCombos?.wvStatusMessage?.[item.appointment.wvStatus],
                type: MAP_WV_STATUS_TO_PILL_TYPE[item.appointment.wvStatus],
            } : undefined,
            artText: item.appointment.art ? this.appointmentCombos?.artComboWV?.[item.appointment.art] : '',
            adressatText: this.getAdressatText(item.appointment, item.beteiligteOutput),
            bereichText: this.getBereichText(item.appointment),
            customerNames: item.beteiligteOutput.filter(o => !!o?.customerName).map(o => o?.customerName).join(', '),
            sellingPhaseText: this.getSellingPhaseText(item.appointment),
            statusText: this.getStatusText(item.appointment),
            actions,
        }));
    },
  },
  watch: {
    sprodId: {
      handler() {
        this.findAppointments();
      },
      immediate: true,
    },
  },
  methods: {
    findAppointments() {
      this.$store.dispatch(OTHER_PRODUCTS_TYPES.ACTIONS.FIND_APPOINTMENTS);
    },
    openEditModal(appointment) {
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_CLICKED_ON_EVENT, true);
      this.$store.dispatch(CALENDAR_TYPES.ACTIONS.RETRIEVE_SELECTED_APPOINTMENT, appointment.sid);
      this.openAppointment();
    },
    openDeleteModal(appointment) {
      this.appointmentToDelete = { ...appointment };
      this.$refs.modalDelete.open();
    },
    async deleteAppointment() {
      if(this.appointmentToDelete) {
        this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT, this.appointmentToDelete);
        this.loading = true;
        await this.$store.dispatch(CALENDAR_TYPES.ACTIONS.DELETE_APPOINTMENT);
        this.loading = false;
        this.findAppointments();
      }
    },
    openAppointment() {
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_CONFIG, {
        title: 'Aktivität bearbeiten',
        bereich: this.BEREICH,
        bereichId: this.sprodId,
        isActivity: false,
        selectedDate: null,
        discardChanges: this.resetAppointment,
  
        back: this.$router.currentRoute.fullPath,
      })
      this.$router.push({ path: `/communication/appointment` });
    },
    resetAppointment() {
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.RENEW_CALENDAR_DATA);
    },
    renewAppointmentView() {
      this.findAppointments();
    },
    getAdressatText(appointment, beteiligteOutput) {
      const adressatText = [];
      if(appointment?.ownerName) {
        adressatText.push(appointment.ownerName);
      }
      beteiligteOutput.forEach(o => adressatText.push(o.name));
      return adressatText.join(', ');
    },
    getBereichText(appointment) {
      const bereichText = this.appointmentCombos?.bereichCombo?.[appointment.bereich] || '';

      const description = [];
      if(this.otherProduct?.company) {
        description.push(this.otherProduct.company);
      }
      if(this.otherProduct?.produktart) {
        description.push(this.otherProduct.produktart);
      }

      const descriptionExtra = [];
      if(this.otherProduct?.anlageArtenImmobilie) {
        descriptionExtra.push(this.otherProduct.anlageArtenImmobilie);
      }
      if(this.otherProduct?.produktbez) {
        descriptionExtra.push(this.otherProduct?.produktbez);
      }
      if(this.otherProduct?.sprodNr) {
        descriptionExtra.push(this.otherProduct?.sprodNr);
      }

      return `${bereichText}: ${description.join(', ')}(${descriptionExtra.join(', ')})`;
    },
    getSellingPhaseText(appointment) {
      if(!appointment.sellingPhase) return '';
      return appointment.activity ? this.appointmentCombos?.verkaufsphaseWVCombo?.[appointment.sellingPhase] : '';
    },
    getStatusText(appointment) {
      if(!appointment.status) return '';
      return appointment.activity ? this.appointmentCombos?.statusComboWV?.[appointment.status] : this.appointmentCombos?.statusComboAufgabe?.[appointment.status];
    },
  },
  mounted() {
  },
  components: {
    PhCheck,
    Table,
    AnimatedSpinner,
    BaseModal,
    AppointmentEditCreate,
    Pill,
  },
}
</script>
