var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { key: _vm.configContext }, [
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("div", { staticClass: "box__title" }, [
          _vm._v(_vm._s(_vm.configContextLabel))
        ]),
        _vm.isLoading
          ? _c("OptionMenuGhostLoading", { attrs: { showTitle: "" } })
          : _vm.currentOptionMenuId
          ? _c("OptionMenuEditPage", {
              key: _vm.currentPath,
              ref: "optionMenuEditPageEl",
              attrs: {
                configId: _vm.currentOptionMenuId,
                defaultMenu: _vm.currentOptionMenu,
                optionMenu: _vm.optionMenu,
                currentPath: _vm.currentPath,
                configContextLabel: _vm.configContextLabel,
                configContext: _vm.configContext,
                enablePermissionConfig: _vm.enablePermissionConfig
              }
            })
          : _c("NoData")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }