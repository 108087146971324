export const MUTATION_PREFIX = 'ANTRAG_EFONDS: ';
export const ACTIONS_PREFIX = 'ANTRAG_EFONDS_ACTIONS_';
export const GETTERS_PREFIX = 'ANTRAG_EFONDS_GETTERS_';

export default {
  MUTATIONS: {
  },
  ACTIONS: {
    GET_MINIMAL_INVEST_INFO: ACTIONS_PREFIX + 'GET_MINIMAL_INVEST_INFO',
    GET_EFONDS_LINK_NEW: ACTIONS_PREFIX + 'GET_EFONDS_LINK_NEW',
    GET_EFONDS_LINK_EDIT: ACTIONS_PREFIX + 'GET_EFONDS_LINK_EDIT',
    GET_EFONDS_LIST: ACTIONS_PREFIX + 'GET_EFONDS_LIST',
    GET_EFONDS_EINREICHUNG_STR: ACTIONS_PREFIX + 'GET_EFONDS_EINREICHUNG_STR',
    ADD_EZEICHNUNG_TO_MAPPE: ACTIONS_PREFIX + 'ADD_EZEICHNUNG_TO_MAPPE',
    REMOVE_EZEICHNUNG: ACTIONS_PREFIX + 'REMOVE_EZEICHNUNG',
    GET_STAMMDATEN: ACTIONS_PREFIX + 'GET_STAMMDATEN',
    GET_EFONDS_LINK_STAMMDATEN: ACTIONS_PREFIX + 'GET_EFONDS_LINK_STAMMDATEN',
    GET_EFONDS_INFO_LINK: ACTIONS_PREFIX + 'GET_EFONDS_INFO_LINK',
    GET_EFONDS_PERSONS: ACTIONS_PREFIX + 'GET_EFONDS_PERSONS',
    UPLOAD_STAMMDATEN: ACTIONS_PREFIX + 'UPLOAD_STAMMDATEN',
    GET_KOSTENINFO_PDF: ACTIONS_PREFIX + 'GET_KOSTENINFO_PDF',
    SAVE_EZEICHNUNG: ACTIONS_PREFIX + 'SAVE_EZEICHNUNG',
  },
  GETTERS: {
  }
}