<template>
<div>
    <router-view>
        // TODO
    </router-view>
</div>
</template>

<script>
import { loadDefaultColorsScheme } from '@/configs/color-config';

export default {
    mounted() {
        loadDefaultColorsScheme();
    },
}
</script>
