import actions from './actions';
import mutations from './mutations';
import getters from './getters';


export function initialTableData() {
  return {
    tableHeaders: [],
    records: [],
    headerLabels: [],
    footerLabels: [],
    isLoaded: false,
    isError: false,
  };
}

/**
 * mutations: synchronous operation responsible for change the state
 * actions: asynchronous operation responsible for call external resources and commit mutations 
 */

export function getInitialState() {
  return {
    isLoaded: false,
    details: [],
    stocks: [],
    performance: {},
    chartData: {},
    config: {},
    depositSum: initialTableData(),
    depotPositions: {},
    riskIndicator: {isLoaded: false},
    realisedProfit: {data: initialTableData()},
    historicalPerformance: {data: initialTableData()},
    historicalPerformanceDetails: {},
    performanceFiltered: {collection: {data: initialTableData()}, id: 0},
    progressDetailsConfig: {},
    progressDetailsChartData: {},
    insuranceFunds: {},
    meta: {
      updatedAt: 0
    }
  }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}