<template>
  <CurrencyLabel :value="value" symb="%" v-bind="$attrs" />
</template>

<script>
import CurrencyLabel from '@/components/core/CurrencyLabel.vue';

export default {
  components: {
    CurrencyLabel,
  },
  props: {
    value: { },
  },
}
</script>
