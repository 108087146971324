<template>
  <div>
    <BaseModal
      ref="vertragVereinigenModal"
      modalTitle="Versicherungen vereinigen"
      labelButtonConfirm="Speichern"
      :confirmDisabled="isFormInvalid"
      @onConfirmButton="onConfirmModal()"
      @onCancelButton="onCancelModal()"
      @onCloseButton="onCancelModal()"
    >
      <div class="row">
        <div class="col-12">
          <p>Hier können Sie zwei Versicherungen vereinigen.</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <hr>
          <p><span class="color-danger font-bold">ACHTUNG!</span></p>
          <p><span class="color-danger font-bold">Vereinigte Verträge können im Nachgang nicht mehr "getrennt" werden.</span></p>
          <p><span class="color-danger font-bold">Sind Sie sich sicher, dass Sie die Verträge vereinigen möchten?</span></p>
          <hr>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <InputField
            v-model="versicherungen.toKeep.vertragId"
            label="Versicherung 1, der die Daten zugeordnet werden sollen"
            :validateUntouched="true"
            @input="dataChanged('toKeepId', $event)"
          />
          <div v-if="versicherungen.toKeep.vertragId && versicherungen.toKeep.vertragId != versicherungen.toRemove.vertragId">
            <span v-if="versicherungen.toKeep.bezeichnung">{{ versicherungen.toKeep.bezeichnung }}</span><br>
            <span v-if="versicherungen.toKeep.polNr"> Pol.Nr.: {{ versicherungen.toKeep.polNr }}</span>
          </div>
        </div>
        <div class="col-6">
          <InputField
            v-model="versicherungen.toRemove.vertragId"
            label="Versicherung 2, der die Daten zugeordnet werden sollen"
            :validateUntouched="true"
            @input="dataChanged('toRemoveId', $event)"
          />
          <div v-if="versicherungen.toRemove.vertragId && versicherungen.toRemove.vertragId != versicherungen.toKeep.vertragId">
            <span v-if="versicherungen.toRemove.bezeichnung">{{ versicherungen.toRemove.bezeichnung }}</span><br>
            <span v-if="versicherungen.toRemove.polNr"> Pol.Nr.: {{ versicherungen.toRemove.polNr }}</span>
          </div>
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import BaseModal from "@/components/core/BaseModal.vue";
import InputField from "@/components/core/forms/InputField.vue";
import validator from "@/mixins/validator";
import { required } from "@/mixins/validator/rules";
import { debounce } from '@/helpers/commonfunctions.js';
import { buildMessage } from '@/helpers/log-message-helper';
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";
import LOG_TYPES from '@/store/log/types';

export default {
  mixins: [validator],
  validators: {},
  components: {
    BaseModal,
    InputField,
  },
  data() {
    return {
      versicherungen: {
        toKeep: { bezeichnung: null, vertragId: null, polNr: null },
        toRemove: { bezeichnung: null, vertragId: null, polNr: null },
      },
      fieldsValidators: {
        versicherungen : {
          toKeep: {
            vertragId: [required('Versicherung 1 ist erforderlich!')]
          },
          toRemove: {
            vertragId: [required('Versicherung 2 ist erforderlich!')]
          }
        },
      }
    };
  },
  computed: {
    isFormInvalid() {
      return this.validation.invalid;
    },
  },
  methods: {
    cleanUpForm() {
      this.versicherungen = {
        toKeep: { bezeichnung: null, vertragId: null, polNr: null },
        toRemove: { bezeichnung: null, vertragId: null, polNr: null },
      }
    },
    open(versicherung) {
      this.versicherungen.toRemove = versicherung;
      this.$refs.vertragVereinigenModal.open();
    },
    onConfirmModal() {
      this.$emit("save", this.versicherungen);
      this.cleanUpForm()
      this.$refs.vertragVereinigenModal.close();
    },
    onCancelModal() {
      this.cleanUpForm()
      this.$refs.vertragVereinigenModal.close();
    },
    dataChanged: debounce(async function changedData(field, insuranceId){

      if(insuranceId && this.versicherungen.toKeep.vertragId && this.versicherungen.toRemove.vertragId && 
      (this.versicherungen.toKeep.vertragId != this.versicherungen.toRemove.vertragId)) {

        this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.RETRIEVE_INSURANCE_DETAILS, { versVertrag: insuranceId }).then((response) => {
          const insurance = response?.insurance;
          if(insurance){
            if(field === 'toKeepId'){
              this.versicherungen.toKeep.polNr = insurance.nummer;
              this.versicherungen.toKeep.bezeichnung = insurance.bezeichnung;
              this.validation.reset('versicherungen.toKeep.vertragId');
            }
            if(field === 'toRemoveId'){
              this.versicherungen.toRemove.polNr = insurance.nummer;
              this.versicherungen.toRemove.bezeichnung = insurance.bezeichnung;
              this.validation.reset('versicherungen.toRemove.vertragId');
            }
          }
        }).catch(error => {
          const not_found = 404;
          if(error.response.status == not_found){
            if(field === 'toKeepId'){
              this.$pushErrors('versicherungen.toKeep.vertragId', error.response.data);
            }
            if(field === 'toRemoveId'){
              this.$pushErrors('versicherungen.toRemove.vertragId', error.response.data);
            }
          } else {
            this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error, 'danger'));
          }
        })
      } else if(this.versicherungen?.toKeep?.vertragId == this.versicherungen?.toRemove?.vertragId){
        this.$pushErrors('versicherungen.toKeep.vertragId', 'Versicherung 1 ist gleich Versicherung 2');
      }

    }, 500),
  },
  mounted() {
    this.$configureValidators(this.fieldsValidators);
  }
};
</script>

<style>
</style>