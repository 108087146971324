var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "mb-5" },
        [
          _c("InputToggleSwitch", {
            attrs: {
              inLineLabel: "",
              label: "Anlage in Managed Depot erfassen",
              disabled: true
            },
            model: {
              value: _vm.djeAktiv,
              callback: function($$v) {
                _vm.djeAktiv = $$v
              },
              expression: "djeAktiv"
            }
          })
        ],
        1
      ),
      _c("InputRadioBoxGroup", {
        attrs: {
          title:
            "Welchen Verlust an liquidem Vermögen innerhalb eines Jahres würden Sie höchstens in Kauf nehmen?",
          validateUntouched: "",
          disabled: _vm.isDisabled || !_vm.isZusatzDJEAktiv,
          values: _vm.values
        },
        on: {
          input: function($event) {
            return _vm.updateStore("zusatzDJE", "djeVerlust", $event)
          }
        },
        model: {
          value: _vm.djeVerlust,
          callback: function($$v) {
            _vm.djeVerlust = $$v
          },
          expression: "djeVerlust"
        }
      }),
      _c("InputTextArea", {
        attrs: {
          label:
            "Beschreibung der Verlusttragfähigkeit mit eigenen Worten falls vom Kunden gewünscht und obige Fallgruppen nicht ausreichend sind",
          isComponentHalfSize: "",
          disabled: _vm.isDisabled || !_vm.isZusatzDJEAktiv
        },
        on: {
          change: function($event) {
            return _vm.updateStore("zusatzDJE", "annotationVerlust", $event)
          }
        },
        model: {
          value: _vm.annotationVerlust,
          callback: function($$v) {
            _vm.annotationVerlust = $$v
          },
          expression: "annotationVerlust"
        }
      }),
      _c("InputTextArea", {
        attrs: {
          label:
            "Beschreibung, falls vom Kunden spezielle Anforderungen für die Finanzanlagen bestehen",
          isComponentHalfSize: "",
          disabled: _vm.isDisabled || !_vm.isZusatzDJEAktiv
        },
        on: {
          change: function($event) {
            return _vm.updateStore(
              "zusatzDJE",
              "annotationSpezAnforderungen",
              $event
            )
          }
        },
        model: {
          value: _vm.annotationSpezAnforderungen,
          callback: function($$v) {
            _vm.annotationSpezAnforderungen = $$v
          },
          expression: "annotationSpezAnforderungen"
        }
      }),
      _c("InputTextArea", {
        attrs: {
          label:
            "Beschreibung des Anlagezieles, der Risikoneigung und der Risikobereitschaft (Risikokennzahl) mit eigenen Worten falls vom Kunden gewünscht",
          isComponentHalfSize: "",
          disabled: _vm.isDisabled || !_vm.isZusatzDJEAktiv
        },
        on: {
          change: function($event) {
            return _vm.updateStore("zusatzDJE", "annotationRisiko", $event)
          }
        },
        model: {
          value: _vm.annotationRisiko,
          callback: function($$v) {
            _vm.annotationRisiko = $$v
          },
          expression: "annotationRisiko"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }