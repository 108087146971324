var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("div", { staticClass: "box__title" }, [_vm._v("Gebühren")]),
        _c("div", { staticClass: "box__title" }, [
          _vm._v("Beratungsgebühr prozentual gemäß Bestand")
        ]),
        _c("InputField", {
          attrs: {
            label: "Honorar/Serviceleistung",
            type: "percent",
            precision: 4,
            isComponentHalfSize: "",
            validateUntouched: ""
          },
          on: {
            change: function($event) {
              return _vm.dataChanged()
            }
          },
          model: {
            value: _vm.feePropertiesForm.percentage,
            callback: function($$v) {
              _vm.$set(_vm.feePropertiesForm, "percentage", $$v)
            },
            expression: "feePropertiesForm.percentage"
          }
        }),
        _c("InputRadioBoxGroup", {
          attrs: {
            title: "Berechnungsgrundlage",
            values: _vm.feePropertiesCombos.berechnungsgrundlageType
          },
          on: {
            input: function($event) {
              return _vm.dataChanged()
            }
          },
          model: {
            value: _vm.feePropertiesForm.berechnungsgrundlageType,
            callback: function($$v) {
              _vm.$set(_vm.feePropertiesForm, "berechnungsgrundlageType", $$v)
            },
            expression: "feePropertiesForm.berechnungsgrundlageType"
          }
        }),
        _c("InputToggleSwitch", {
          attrs: {
            label: "Zusammenfassung der Einzelpositionen",
            inLineLabel: ""
          },
          on: {
            input: function($event) {
              return _vm.dataChanged()
            }
          },
          model: {
            value: _vm.feePropertiesForm.zusammenfassungGebuehren,
            callback: function($$v) {
              _vm.$set(_vm.feePropertiesForm, "zusammenfassungGebuehren", $$v)
            },
            expression: "feePropertiesForm.zusammenfassungGebuehren"
          }
        }),
        _c("InputToggleSwitch", {
          attrs: { label: "Bankkonten mit berücksichtigen", inLineLabel: "" },
          on: {
            input: function($event) {
              return _vm.dataChanged()
            }
          },
          model: {
            value: _vm.feePropertiesForm.useBankkonten,
            callback: function($$v) {
              _vm.$set(_vm.feePropertiesForm, "useBankkonten", $$v)
            },
            expression: "feePropertiesForm.useBankkonten"
          }
        }),
        _c("InputToggleSwitch", {
          attrs: {
            label: "Beteiligungen mit berücksichtigen",
            inLineLabel: ""
          },
          on: {
            input: function($event) {
              return _vm.dataChanged()
            }
          },
          model: {
            value: _vm.feePropertiesForm.useBeteiligungen,
            callback: function($$v) {
              _vm.$set(_vm.feePropertiesForm, "useBeteiligungen", $$v)
            },
            expression: "feePropertiesForm.useBeteiligungen"
          }
        }),
        _c("InputField", {
          attrs: {
            label: "Minimalbetrag",
            type: "currency",
            isComponentHalfSize: ""
          },
          on: {
            change: function($event) {
              return _vm.dataChanged()
            }
          },
          model: {
            value: _vm.feePropertiesForm.minAmount,
            callback: function($$v) {
              _vm.$set(_vm.feePropertiesForm, "minAmount", $$v)
            },
            expression: "feePropertiesForm.minAmount"
          }
        }),
        _c("InputField", {
          attrs: {
            label: "Maximalbetrag",
            type: "currency",
            isComponentHalfSize: ""
          },
          on: {
            change: function($event) {
              return _vm.dataChanged()
            }
          },
          model: {
            value: _vm.feePropertiesForm.maxAmount,
            callback: function($$v) {
              _vm.$set(_vm.feePropertiesForm, "maxAmount", $$v)
            },
            expression: "feePropertiesForm.maxAmount"
          }
        }),
        _c("p", { staticClass: "text-italic" }, [
          _vm._v(
            "Minimal- und Maximalbetrag beziehen sich auf den gesamten errechneten prozentualen Honorar-/Serviceleistung betrag pro Abrechnung."
          )
        ])
      ],
      1
    ),
    _c("div", { staticClass: "box__container" }, [
      _c("div", { staticClass: "box__title" }, [
        _vm._v("Pauschalen je Abrechnung")
      ]),
      _c(
        "div",
        { staticClass: "forms__input--half-size" },
        [
          _vm._m(0),
          _vm._l(_vm.MAX_PAUSCHALEN, function(lfdnr) {
            return _c("div", { key: lfdnr, staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12 col-sm-6 mb-1 mb-sm-0" },
                [
                  _c("InputField", {
                    attrs: { type: "text" },
                    on: {
                      change: function($event) {
                        return _vm.dataChanged()
                      }
                    },
                    model: {
                      value:
                        _vm.feePropertiesForm["pauschaleBezeichnung" + lfdnr],
                      callback: function($$v) {
                        _vm.$set(
                          _vm.feePropertiesForm,
                          "pauschaleBezeichnung" + lfdnr,
                          $$v
                        )
                      },
                      expression:
                        "feePropertiesForm['pauschaleBezeichnung' + lfdnr]"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-12 col-sm-6" },
                [
                  _c("InputField", {
                    attrs: { type: "currency" },
                    on: {
                      change: function($event) {
                        return _vm.dataChanged()
                      }
                    },
                    model: {
                      value: _vm.feePropertiesForm["pauschaleBetrag" + lfdnr],
                      callback: function($$v) {
                        _vm.$set(
                          _vm.feePropertiesForm,
                          "pauschaleBetrag" + lfdnr,
                          $$v
                        )
                      },
                      expression: "feePropertiesForm['pauschaleBetrag' + lfdnr]"
                    }
                  })
                ],
                1
              )
            ])
          })
        ],
        2
      )
    ]),
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("div", { staticClass: "box__title" }, [_vm._v("Stundensatz")]),
        _c("InputField", {
          attrs: {
            label: "Abrechnungsbetrag je Stunde",
            type: "currency",
            isComponentHalfSize: ""
          },
          on: {
            change: function($event) {
              return _vm.dataChanged()
            }
          },
          model: {
            value: _vm.feePropertiesForm.stundensatz,
            callback: function($$v) {
              _vm.$set(_vm.feePropertiesForm, "stundensatz", $$v)
            },
            expression: "feePropertiesForm.stundensatz"
          }
        }),
        _c("InputField", {
          attrs: {
            label: "Aufrundung Arbeitszeit je angefangene (Minuten)",
            type: "number",
            isComponentHalfSize: ""
          },
          on: {
            change: function($event) {
              return _vm.dataChanged()
            }
          },
          model: {
            value: _vm.feePropertiesForm.rundungStundensatz,
            callback: function($$v) {
              _vm.$set(_vm.feePropertiesForm, "rundungStundensatz", $$v)
            },
            expression: "feePropertiesForm.rundungStundensatz"
          }
        })
      ],
      1
    ),
    _vm.isCustomer
      ? _c(
          "div",
          { staticClass: "box__container" },
          [
            _c("KundeHonorareTable", {
              attrs: {
                title:
                  "Zeitabhängige und fixe Honorargebühren/Serviceleistungen"
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 col-sm-6" }, [_vm._v("Bezeichnung")]),
      _c("div", { staticClass: "col-12 col-sm-6" }, [_vm._v("Betrag")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }