<template>
	<div>
		<PageHeaderTitleNavigation title="Personen zusammenführen" />

		<BaseCollapsable class="box__container" v-model="showHinweis">
			<template v-slot:title>
				<span class="box__title">
					Hinweise
				</span>
			</template>
			<template v-slot:content>
				<p>
					Hier können Sie nach mehrfach angelegten Personen suchen und diese zusammenführen.
				</p>
				<p class="font-strong">
					Achtung, bitte überzeugen Sie sich, dass die von Ihnen in der Liste ausgewählten Personen, auch wirklich identisch sind. Eine spätere Trennung ist nur mit großem Aufwand möglich.
				</p>
				<p>
					Bitte klicken Sie dazu auf eine Person in der oberen Liste. Es werden die als identisch erkannten Personen in der unteren Liste aufgeführt. Markieren Sie dort die Personen, die zusammengeführt werden sollen und drücken Sie "Markierte Personen zusammenführen".
				</p>
			</template>
		</BaseCollapsable>

		<div class="box__container">
			<div class="button__container">
				<InputToggleSwitch 
					label="Name und Vorname" 
					:disabled="true || loading" 
					inLineLabel/>
				<InputToggleSwitch 
					label="Geburtsdatum" 
					v-model="filter.checkBirthday"
					:disabled="loading" 
					inLineLabel/>
				<InputToggleSwitch 
					label="PLZ"
					v-model="filter.checkPLZ"
					:disabled="loading" 
					inLineLabel/>
				<InputToggleSwitch 
					label="Straße" 
					v-model="filter.checkStreet"
					:disabled="loading" 
					inLineLabel/>
			</div>
		</div>

		<DuplicatedPeopleList 
			@showUnifiablePeople="loadUnifiablePeople"
			:loading="duplicatedPeopleStatus.loading"
			:error="duplicatedPeopleStatus.error" />

		<UnifiablePeopleList 
			ref="unifiablePeople"
			v-if="selectedDuplicatedPerson"
			@peopleMerged="loadDuplicatedPeople"
			:loading="unifiablePeopleStatus.loading"
			:error="unifiablePeopleStatus.error" />

	</div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import BaseCollapsable from '@/components/core/BaseCollapsable.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import MERGE_PEOPLE_TYPES from '@/store/merge-people/types.js';

import DuplicatedPeopleList from '@/components/merge-people/DuplicatedPeopleList.vue'
import UnifiablePeopleList from '@/components/merge-people/UnifiablePeopleList.vue'

export default {

	data() {
		return {
			duplicatedPeopleStatus: {
				loading: false,
				error: false,
			},
			unifiablePeopleStatus: {
				loading: false,
				error: false,
			},
			showHinweis: false,
			filter: {
				checkBirthday: true,
				checkPLZ: true,
				checkStreet: true,
			},
			selectedDuplicatedPerson: null,
		}
	},

	watch: {
		filter: {
			deep: true,
			handler() {
				this.loadDuplicatedPeople()
			}
		}
	},

	computed: {
		loading() {
			return this.duplicatedPeopleStatus.loading || this.unifiablePeopleStatus.loading
		}
	},

	methods: {
		async loadDuplicatedPeople() {
			this.selectedDuplicatedPerson = null
			this.$set(this.duplicatedPeopleStatus, 'loading', true)

			try {
				await this.$store.dispatch(MERGE_PEOPLE_TYPES.ACTIONS.LIST_DUPLICATED_PEOPLE, { ...this.filter })
				
			} catch (error) {
				this.$set(this.duplicatedPeopleStatus, 'error', true)
			} finally {
				this.$set(this.duplicatedPeopleStatus, 'loading', false)
			}
		},

		async loadUnifiablePeople(row) {
			this.selectedDuplicatedPerson = row			
			this.$set(this.unifiablePeopleStatus, 'loading', true)

			try {
				const params = { 
					...this.filter, 
					personRefId: this.selectedDuplicatedPerson.destinationPersonId 
				}
				
				await this.$store.dispatch(MERGE_PEOPLE_TYPES.ACTIONS.LIST_UNIFIABLE_PEOPLE, params)
				
				setTimeout(() => {
					this.$refs.unifiablePeople?.$el?.scrollIntoView({ block: 'end', inline: 'nearest', behavior: 'smooth' })
				}, 0)
			} catch (error) {
				this.$set(this.unifiablePeopleStatus, 'error', true)
			} finally {
				this.$set(this.unifiablePeopleStatus, 'loading', false)
			}
		},
	},

	mounted() {
		this.loadDuplicatedPeople();
	},

	beforeRouteLeave(to, from, next) {
		this.$store.commit(MERGE_PEOPLE_TYPES.MUTATIONS.RESET_STATE);
		next()
	},

	components: {
		OptionMenu,
		PageHeaderTitleNavigation,
		BaseCollapsable,
		InputToggleSwitch,
		DuplicatedPeopleList,
		UnifiablePeopleList,
	}
}
</script>

<style scoped>

.button__container {
	display: flex;
	align-items: baseline;
	flex-wrap: wrap;
}

.input-forms__container {
	padding: 5px 10px 5px 0;
}

</style>