<template>
  <div class="home">
    <router-view></router-view>
  </div>
</template>

<script>
import DASHBOARD_TYPES from '@/components/dashboard/store/types';
import GRAPHICAL_COURSE_TYPES from '@/store/graphicalCourse/types';
import { mapGetters } from 'vuex'
import CORE_TYPES from '@/store/core/types';
import { VIEW_ROLES } from '@/router/roles';

export default {
   computed: {
    ...mapGetters({
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    })
   },

  mounted() {
    const hasRoles = this.$store.getters[CORE_TYPES.GETTERS.HAS_ROLES]

    // Preoloading Wertentwicklung Chart only for Customer
    if (hasRoles([VIEW_ROLES.VIEW_CUSTOMER])) {
      this.$store.dispatch(GRAPHICAL_COURSE_TYPES.ACTIONS.DEPOT, {site: 'page', depotid: -1});
    }
  },
}
</script>