<template>
  <div>
    <div v-if="!form.isManagement">
      <label class="mb-0 font-strong" for="knowledge">Kenntnisse</label>
      <InputSlider
        v-model="form.knowledge" 
        :values="knowledges"
        :disabled="isDisabled || isRefusedPage"
         @input="doExperienceCardChange(form, category, 'knowledge', $event)">
      </InputSlider>
    </div>
    <div v-if="!form.isManagement">
      <label class="mb-0 font-strong" for="experience">Erfahrungen</label>
      <InputSlider
        v-model="form.experience" 
        :values="knowledges"
        :disabled="isDisabled || isRefusedPage"
         @input="doExperienceCardChange(form, category, 'experience', $event)">
      </InputSlider>
    </div>
    <div>
      <label class="mb-0 font-strong" for="sum">Ungefährer Betrag (Euro)</label>
      <InputSlider
        v-model="form.sum" 
        :values="sums"
        :disabled="isDisabled || isRefusedPage"
         @input="doExperienceCardChange(form, category, 'sum', $event)">
      </InputSlider>
    </div>
    <div>
      <label class="mb-0 font-strong" for="transaction">Anzahl Transaktionen p.a.</label>
      <InputSlider
        v-model="form.transaction" 
        :values="transactions"
        :disabled="isDisabled || isRefusedPage"
         @input="doExperienceCardChange(form, category, 'transaction', $event)">
      </InputSlider>
    </div>
    <InputField
      label="Anmerkungen"
      v-model="form.annotationKenntnisseCard"
      :disabled="isDisabled || isRefusedPage"
      isComponentHalfSize
       @change="doExperienceCardChange(form, category, 'annotationKenntnisseCard', $event)">
    </InputField>   

    <BaseButton v-if="isDetailButtonVisible()"
      @click="openSaveAllModal()" 
      :disabled="isDisabled || isSaving"
      isSecondary>
      <span>&nbsp;Detailangaben für andere Kenntnisse und Erfahrungen übernehmen</span>
    </BaseButton>

    <BaseModal 
      ref="saveForAll"
      labelButtonConfirm="Daten übernehmen"
      labelButtonCancel="Abbrechen"
      @onConfirmButton="doSaveForAll()"
      @onCancelButton="closeModal()"
      @onCloseButton="closeModal()"
    >
      <template v-slot:modalTitle>
        <!-- <ph-warning :size="16" class="mr-2" style="color: orangered"/> -->
        Angaben übernehmen
      </template>
      <div>Die Angaben von Detailangaben zu <span style="font-weight: bold">{{category && category.label || ''}}</span> auch übernehmen für:</div>
      <div v-for="cat in selectedCategories" :key="cat.substepKey">
        <InputCheckBoxItem :label="cat.title" v-model="cat.value" /> 
      </div>
    </BaseModal>
  </div>
</template>

<script>

import InputField from '@/components/core/forms/InputField.vue';
import InputSlider from '@/components/core/forms/InputSlider.vue';
import InputCheckBoxItem from '@/components/core/forms/checkbox/InputCheckBoxItem.vue';
import { mapGetters } from 'vuex';
import ANLEGERPROFIL_TYPES from '@/store/anlegerprofil/types';
import anlegerprofilMixin from '@/mixins/anlegerprofil/anlegerprofil-mixin.js';
import BaseModal from '@/components/core/BaseModal.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import { PhWarning } from 'phosphor-vue'

const knowledges = [ 
  { name: 'keine', value: '0' },
  { name: 'Basis', value: '1' },  
  { name: 'erweitert', value: '2' },
  { name: 'umfangreich', value: '3' },
  { name: 'Experte', value: '4' }
];
const sums = [
  { name: 'keine Anlage', value: '0' },
  { name: 'bis 2.500', value: '1' }, 
  { name: '2.500 bis 10.000', value: '2' }, 
  { name: 'ab 10.000', value: '3' }
];
const transactions = [
  { name: 'keine', value: '0' },
  { name: '1 bis 10', value: '1' }, 
  { name: '11 bis 25', value: '2' },
  { name: 'mehr als 25', value: '3' } 
];

export default {
  mixins: [anlegerprofilMixin],
  components: {
    InputSlider,
    InputField,
    BaseModal,
    BaseButton,
    InputCheckBoxItem,
    PhWarning,
  },
  data() {
    return {
      form: {},
      values: [],
      knowledges: knowledges,
      sums: sums,
      transactions: transactions,
      category: null,
      selectedCategories: [],
      selectedForm: {},
      changed: false,
    };
  },
  watch: {
    $route(to, from) {
      if (from.params.category !== to.params.category) {
        this.doSave();
        this.patchForm();
      }
    },
  },
  computed: {
    ...mapGetters({
      isSaving: ANLEGERPROFIL_TYPES.GETTERS.SAVE_ANLEGERPROFIL_STATE
    }),
    isRefusedPage() {
      return this._isRefusedPage(this.data, 'kenntnisse');
    },
    categoryPath() {
      return this.$route.params.category;
    },
  },
  mounted() {
    this.createForm();
  },
  methods: {
    createForm() {
      this.form = {
        knowledge: '',
        experience: '',
        sum: '',
        transaction: '',
        annotationKenntnisseCard: '',
        isManagement: false,
      };
      this.patchForm();
    },
    patchForm() {
      const data = this.getExperience(this.getPersonType);
      if (this.form && data) {
        const categories = data.categories || [];
        this.category = categories && this.categoryPath && categories.find(cat => cat.path === this.categoryPath) || {};
        this.form =  Object.assign({}, this.category);
      }
    },
    openSaveAllModal() {
      const currentSubstepKey = this.getSubstepKey;
      this.selectedCategories = this.getVisibleKenntnisseSubsteps(this.stepper)
        .filter(substep => substep.substepKey !== currentSubstepKey)
        .map(substep => Object.assign({}, substep, { value: true }));
      this.$refs.saveForAll.open();
    },
    async doSaveForAll() {
      const selections = this.selectedCategories.filter(cat => cat.value)
      .map(cat => Object.assign({}, this.form, { path: cat.substepKey.substring(cat.substepKey.indexOf('/') + 1) }));
      
      const data = this.getExperience(this.getPersonType); 
      this.updateExperienceCategories(this.getPersonType, selections, false);
      await this.doSave({experience: { personType: data.personType, categories: selections } , step: 'card'});
      this.closeModal();
    },
    closeModal () {
      this.selectedCategories = [];
      this.selectedForm = {};
      this.$refs.saveForAll.close();
    },
    isDetailButtonVisible() {
      return !this.form.isManagement && this.getVisibleKenntnisseSubsteps(this.stepper)?.length > 1 && this.isKennntnisseCardFilled(this.form);
    }
  },
}
</script>

<style scoped>

</style>