<template>
  <div>
    <PageHeaderTitleNavigation 
      v-if="isMobileNativeContext"
      title="Profil" 
      subtitle="Datenschutzerklärung" 
      :actions="headerActions()" 
      v-on="$listeners"
      @action-ABLEHNEN="$refs.rejectTermsModal.open()"
      @action-AKZEPTIEREN="checkAcceptTerms()">
      
    </PageHeaderTitleNavigation>   
    
    <HinweiseUndFehler :hints="hints" />

    <div class="box__container">
      <div v-html="mainHtmlText" @click="openLink"></div>

      <InputCheckBoxItem v-if="!hideAcceptLabelHtmlText"
        :label="acceptLabelHtmlText"
        v-model="internalForm.isConfirmed"
        :disabled="isKundenzugang"
        @click="openLink"
      />

      <InputCheckBoxItem 
        v-if="isTestCustomer"
        :label="erstinformationText"
        v-model="internalForm.hasErstinformation"
        :disabled="isKundenzugang"
        @click="openLink"
      />

    </div>

    <div class="box__container">
      <div class="box__title">Ich möchte künftig kontaktiert werden per</div>

        <div v-for="contactValue in contactValues">
          <InputCheckBoxItem
          :label="contactValue.label"
          v-model="internalForm.privacyChecks[contactValue.path]"
          :disabled="isKundenzugang || (isVVCustomer && contactValue.path === 'app')"
          />
          <span v-if="isVVCustomer && contactValue.path === 'app'" style="color: var(--color-danger);">Sie sind Kunde der Vermögensverwaltung. Hier herrscht eine gesetzliche Reportingpflicht. Eine Änderung der Einstellung ist nicht möglich. Bei weiteren Fragen wenden Sie sich bitte an Ihren Berater.</span>
        </div>

    </div>

    <div class="box__container" v-if="!isMobileNativeContext">
      <div>
        <div class="layout__negative-margin--8">
          <BaseButton isSecondary 
            @click="$refs.rejectTermsModal.open()"
            :disabled="!!isLoading || isKundenzugang">
            Ablehnen
          </BaseButton>

          <BaseButton 
            @click="checkAcceptTerms()"
            :disabled="!hideAcceptLabelHtmlText && validation.isInvalid('internalForm') || !!isLoading || isKundenzugang">
            Akzeptieren <AnimatedSpinner v-if="!!isLoading" />
          </BaseButton>
        </div>
      </div>
    </div>

    <BaseModal 
      ref="rejectTermsModal"
      labelButtonConfirm="Ok"
      labelButtonCancel="Abbrechen"
      @onConfirmButton="handleTermRejected()">
      <template v-slot:modalTitle>
        {{ ablehnenDataschutzerklaerungMessageTitle }}
      </template>
      {{ ablehnenDataschutzerklaerungMessage }}
    </BaseModal>

    <BaseModal 
      ref="confirmContactModal"
      labelButtonConfirm="Ok"
      labelButtonCancel="Abbrechen"
      @onConfirmButton="acceptTerms()">
      <template v-slot:modalTitle>
        {{ kommunikationMessageTitle }}
      </template>
      {{ kommunikationMessage }}
    </BaseModal>

    <BaseModal 
      ref="refuseAppModal"
      labelButtonConfirm="Ok"
      :showCancelButton="false">
      <template v-slot:modalTitle>
        Achtung, keine App-Kontaktmöglichkeit
      </template>
      Bitte beachten Sie, dass Sie keine Nachrichten mehr schicken bzw. auf keine Nachrichten antworten können, wenn Sie die Kontaktmöglichkeit "App" abwählen.
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';

import validator from '@/mixins/validator';
import { requiredTrue } from '@/mixins/validator/rules';
import {sanitize} from '@/helpers/string-helper.js';

import InputCheckBoxGroup from '@/components/core/forms/checkbox/InputCheckBoxGroup.vue';
import InputCheckBoxItem from '@/components/core/forms/checkbox/InputCheckBoxItem.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';
import ROLES from '@/router/roles';
import HinweiseUndFehler from '@/components/core/HinweiseUndFehler.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import BRIDGE_TYPES from '@/store/bridge/types';


export default {
  mixins: [validator],
  emit: ['terms-rejected'],
  props: {
    info: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    contacts: {
      type: Array,
      default: () => [],
      required: true,
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    hideAcceptLabelHtmlText: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      internalForm: {
        isConfirmed: false,
        hasErstinformation: false,
        privacyChecks: {}
      },
    }
  },
  computed: {
    ...mapGetters({
      isTestCustomer: CORE_TYPES.GETTERS.IS_TEST_CUSTOMER,
      isLoading: CORE_TYPES.GETTERS.GLOBAL_LOADING_STATE_STATUS,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      appNameDefault: CORE_TYPES.GETTERS.APP_NAME_DEFAULT,
      isMobileNativeContext: BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT,
    }),
    isKundenzugang() {
      return this.hasRoles(ROLES.IS_KUNDENZUGANG);
    },
    isVVCustomer() {
      return this.hasRoles(ROLES.IS_VVCUSTOMER);
    },
    contactValues() {
      if (!this.contacts) {
        return []
      }

      return this.contacts.map(contact => {
        return {
          label: contact.title,
          path: contact.type
        }
      });
    },
    hints() {
      return [
        {title: 'E-Mail', message: 'Notwendig, um Ihnen Nachrichten zu Ihrem Depot / Ihrer Versicherung sowie zur Passwortänderung zukommen zu lassen.'},
        {title: 'Telefon / SMS', message: 'Notwendig, um Ihnen die Bestätigungscodes zwecks Passwortänderung sowie Einrichtung financeIDENT zukommen zu lassen.'},
        {title: 'App / Kundenzugang', message: `Notwendig um mit Ihrem Vermittler über das in der ${this.appNameDefault} / FinanceApp integrierte Nachrichtensystem zu kommunizieren.`},
      ]
    },
    mainHtmlText() {
      return sanitize(this.info?.config?.mainHtmlText || "");
    },
    acceptLabelHtmlText() {
      return this.info?.config?.acceptLabelHtmlText;
    },
    ablehnenDataschutzerklaerungMessageTitle() {
      return this.info?.config?.ablehnenDataschutzerklaerungMessageTitle;
    },
    ablehnenDataschutzerklaerungMessage() {
      return this.info?.config?.ablehnenDataschutzerklaerungMessage;
    },
    kommunikationMessageTitle() {
      return this.info?.config?.kommunikationMessageTitle;
    },
    kommunikationMessage() {
      return this.info?.config?.kommunikationMessage;
    },
    metaDataConfig() {
      return this.info?.config?.metaDataConfig;
    },
    erstinformationText() {
      return 'Ja, ich habe die <a id="ersInf" class="text-info privacy-link">Kundenerstinformation</a> gelesen und bestätige diese.';
    },
    hasNoContactSelected() {
      return this.internalForm?.privacyChecks && Object.values(this.internalForm?.privacyChecks).some(value => value);
    },
  },
  watch: {
    form: {
      handler(newForm) {
        this.internalForm = {
          ...this.internalForm,
          ...newForm,
        };
      },
      deep: true,
      immediate: true,
    },
    contacts: {
      handler(newContacts) {
        if (Array.isArray(newContacts)) {
          for (const contact of newContacts) {
            this.$set(this.internalForm.privacyChecks, contact.type, contact.hasPermission)
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    headerActions() {
      const actions = [];
      actions.push(PageHeaderSimpleAction('AKZEPTIEREN', 'Akzeptieren').withDisabled(() => !this.hideAcceptLabelHtmlText && this.validation.isInvalid('internalForm') || !!this.isLoading || this.isKundenzugang));
      actions.push(PageHeaderSimpleAction('ABLEHNEN', 'Ablehnen').withDisabled(() => !!this.isLoading || this.isKundenzugang));
      return actions;
    },      
    handleTermRejected() {
      this.$emit('terms-rejected');
    },
    checkAcceptTerms() {
      if (!this.hasNoContactSelected) {
        this.$refs.confirmContactModal.open();
        this.$refs.rejectTermsModal.close();
        return ;
      }

      this.acceptTerms();
    },
    acceptTerms() {
      this.$emit('terms-accepted', this.getTermsAccepted());
    },
    checkAppWarningModal($event) {
      if($event && typeof $event.app !== 'undefined' && $event.app === false) {
        this.$refs.refuseAppModal.open()
      }
      console.log(this.contactValues) 
    },
    getTermsAccepted() {
      if (!this.internalForm?.privacyChecks || !this.contacts) {
        return
      }

      const contacts = [];
      for (const contact of this.contacts) {
        contacts.push({ ...contact, hasPermission: this.internalForm.privacyChecks[contact.type] });  
      }

      const privacy = {
        isConfirmed: this.internalForm.isConfirmed,
        ...this.isTestCustomer && { hasErstinformation: this.internalForm.hasErstinformation },
        contacts: contacts,
      };
      return privacy;
    },
    openLink($event) {
      const target = $event.target;
      if ((target.nodeName && target.nodeName.toLowerCase() === 'a') && target.className.indexOf('privacy-link') > 0) {
        const type = target.id ? target.id.toLowerCase() : '';
        this.emitOpenLink(type, target.text, $event);
      }
    },
    emitOpenLink(type, text, event) {
      if (type && this.metaDataConfig) {
        event.preventDefault();

        const params = {
          text,
        };
        if (type && this.replaceUmlaute(type).indexOf('datenschutz') >= 0) {
          params.version = this.metaDataConfig.version;
          params.type = 'DATENSCHUTZERKLAERUNG_HTML';
        } else if (type && this.replaceUmlaute(type).indexOf('agb') >= 0) {
          params.version = this.metaDataConfig.version;
          params.type = 'AGB_HTML';
        }

        this.$emit('open-link', {
          type,
          params,
        });
      }
    },
    replaceUmlaute(type) {
      const umlautMap = {
        '\u00dc': 'UE',
        '\u00c4': 'AE',
        '\u00d6': 'OE',
        '\u00fc': 'ue',
        '\u00e4': 'ae',
        '\u00f6': 'oe',
        '\u00df': 'ss',
      };
      return type.replace(new RegExp('[' + Object.keys(umlautMap).join('|') + ']', 'g'),
        (a) => umlautMap[a]
      );
    },
  },
  validators: {
    internalForm: {
      isConfirmed: [requiredTrue()],
      hasErstinformation: [requiredTrue()]
    }
  },
  components: {
    InputCheckBoxGroup,
    InputCheckBoxItem,
    BaseButton,
    BaseModal,
    AnimatedSpinner,
    HinweiseUndFehler,
    PageHeaderTitleNavigation,
  },
}
</script>
