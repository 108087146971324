<template>
    <EventsFkTable :events="rows" :showTitle="false" :rowsPerPage="5" :isOverview="true" />
</template>

<script>
import EventsFkTable from '@/components/broker/EventsFkTable.vue';
import { mapGetters } from 'vuex';
import MSC_NEWS_TYPES from '@/store/mscnews/types.js';

export default {
    components: {
        EventsFkTable,
    },
     computed: {
        ...mapGetters({
            events: MSC_NEWS_TYPES.GETTERS.FK_EVENTS,
        }),
        rows() {
            return this.events || [];
        }
     }
}
</script>

<style scoped>
</style>