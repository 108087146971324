var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("MailBriefVorlage", {
        attrs: { prependActions: _vm.headerActions },
        on: {
          "action-SAVE": function($event) {
            return _vm.saveTemplates()
          },
          "action-REFRESH": function($event) {
            return _vm.onRefresh()
          }
        }
      }),
      _vm.loadingTemplate || !_vm.id
        ? _c("div", { staticClass: "box__container" }, [
            _c(
              "div",
              [
                [
                  _vm.templateData || !_vm.id
                    ? _c(
                        "div",
                        [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col col-md-6" },
                              [
                                _c("ComboBox", {
                                  attrs: {
                                    label: "Freigabe",
                                    values: _vm.grantValues,
                                    disabled: !_vm.template.editable
                                  },
                                  model: {
                                    value: _vm.grant,
                                    callback: function($$v) {
                                      _vm.grant = $$v
                                    },
                                    expression: "grant"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col col-md-6" },
                              [
                                _c("ComboBox", {
                                  attrs: {
                                    label: "Typ der Vorlage",
                                    values: _vm.typeValues,
                                    disabled: !_vm.template.editable
                                  },
                                  model: {
                                    value: _vm.type,
                                    callback: function($$v) {
                                      _vm.type = $$v
                                    },
                                    expression: "type"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col col-md-6" },
                              [
                                _c("InputField", {
                                  attrs: {
                                    label: "Betreff",
                                    disabled: !_vm.template.editable
                                  },
                                  model: {
                                    value: _vm.subject,
                                    callback: function($$v) {
                                      _vm.subject = $$v
                                    },
                                    expression: "subject"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col col-md-6" },
                              [
                                _c("InputField", {
                                  attrs: {
                                    label: "abw. Bezeichnung",
                                    disabled: !_vm.template.editable
                                  },
                                  model: {
                                    value: _vm.subjectOverwrite,
                                    callback: function($$v) {
                                      _vm.subjectOverwrite = $$v
                                    },
                                    expression: "subjectOverwrite"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col col-md-6" },
                              [
                                _c("ComboBox", {
                                  attrs: {
                                    label: "Kategorie",
                                    values: _vm.categoryValues,
                                    disabled: !_vm.template.editable
                                  },
                                  model: {
                                    value: _vm.category,
                                    callback: function($$v) {
                                      _vm.category = $$v
                                    },
                                    expression: "category"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._m(0),
                          [
                            _vm.template.editable
                              ? _c("TemplateHtmlEditor", {
                                  attrs: {
                                    placeholders: _vm.placeholders || {}
                                  },
                                  model: {
                                    value: _vm.html,
                                    callback: function($$v) {
                                      _vm.html = $$v
                                    },
                                    expression: "html"
                                  }
                                })
                              : _c("div", {
                                  domProps: { innerHTML: _vm._s(_vm.html) }
                                })
                          ]
                        ],
                        2
                      )
                    : _vm._e()
                ]
              ],
              2
            )
          ])
        : _c("GhostLoading", { attrs: { type: "block" } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mb-4" }, [
      _c("b", [
        _c("i", [
          _vm._v(
            " Achtung! Bitte keine Inhalte aus Word u.ä. einkopieren, da der Inhalt beim Empfänger nicht korrekt dargestellt wird! "
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }