var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-forms__container" }, [
    _c("div", { staticClass: "input-forms__label-container" }, [
      _vm.label
        ? _c("div", {
            staticClass: "input-forms__label-content",
            domProps: { innerHTML: _vm._s(_vm.sanitize(_vm.label)) }
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "input-forms__input-container" },
        [
          _vm._l(_vm.componentFields, function(field, index) {
            return [
              field.type == "COMBOBOX"
                ? _c("ComboBox", {
                    key: index,
                    attrs: {
                      label: field.label,
                      value: _vm.value[field.id],
                      values: field.values,
                      disabled: _vm.disabled,
                      isComponentHalfSize: _vm.isComponentHalfSize,
                      sortComboboxValues: false
                    },
                    on: {
                      change: function($event) {
                        return _vm.onChange(field, $event)
                      }
                    }
                  })
                : _c("InputField", {
                    key: index,
                    attrs: {
                      label: field.label,
                      value: _vm.value[field.id],
                      disabled: _vm.disabled,
                      isComponentHalfSize: _vm.isComponentHalfSize
                    },
                    on: {
                      change: function($event) {
                        return _vm.onChange(field, $event)
                      }
                    }
                  })
            ]
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }