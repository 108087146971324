<template>
  <div>
    <HeaderLogin />
    <div data-app-content class="register-terms-content-page p-24px">
      <TermsContent class="m-24px" :registerCode="registerCode" hideMenu/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';

import TermsContent from '@/views/core/privacy/TermsContent.vue';
import HeaderLogin from '@/components/core/login/HeaderLogin.vue';

export default {
  computed: {
    ...mapGetters({
      registrationData: CORE_TYPES.GETTERS.REGISTRATION_DATA,
    }),
    registerCode() {
      return this.registrationData?.registerCode || '';
    },
  },
  components: {
    TermsContent,
    HeaderLogin,
  },
}
</script>
