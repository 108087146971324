var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.title,
          subtitle: _vm.subtitle,
          defaultMenu: _vm.$appNavigation.currentTabMenu,
          actions: _vm.headerActions
        },
        on: {
          "action-ADD": function($event) {
            return _vm.addNewAccount()
          }
        },
        scopedSlots: _vm._u(
          [
            _vm.$slots.title
              ? {
                  key: "title",
                  fn: function() {
                    return [_vm._t("title")]
                  },
                  proxy: true
                }
              : null,
            _vm.$slots.subtitle
              ? {
                  key: "subtitle",
                  fn: function() {
                    return [_vm._t("subtitle")]
                  },
                  proxy: true
                }
              : null,
            _vm.isMobileNativeContext
              ? {
                  key: "action-SHARE_ACCOUNT",
                  fn: function() {
                    return [
                      _c("InputToggleSwitch", {
                        attrs: {
                          label: _vm.parameters.shareDataSwitchText,
                          inLineLabel: "",
                          suppressValidationMessage: ""
                        },
                        on: { input: _vm.toggleShareAccount },
                        model: {
                          value: _vm.shareAccounts,
                          callback: function($$v) {
                            _vm.shareAccounts = $$v
                          },
                          expression: "shareAccounts"
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              : null
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }