<template>
<BaseModal 
    ref="modal"
    modalTitle="Versicherungen vereinigen"
    labelButtonConfirm="Vereinigen"
    labelButtonCancel="Abbrechen"
    :confirmDisabled="!uniteInsurance1 || !uniteInsurance2 || uniteInsurance1 == uniteInsurance2"
    @onConfirmButton="uniteInsurances"
    @close="close"
>
    <div>
        Hier können Sie zwei Versicherungen vereinigen.
        <br>
        <span class="color-danger">
            ACHTUNG!<br>
            Vereinigte Verträge können im Nachgang nicht mehr „getrennt“ werden.<br>
            Sind Sie sich sicher, dass Sie die Verträge vereinigen möchten?
        </span>
        <InputField
            label="Versicherung 1, der die Daten zugeordnet werden sollen"
            v-model="uniteInsurance1"
            @change="loadInsuranceDescription(1)"
        />
        {{insuranceDescription1}}
        <hr>
        <InputField
            label="Versicherung 2, die gelöscht werden soll"
            v-model="uniteInsurance2"
            @change="loadInsuranceDescription(2)"
        />
        {{insuranceDescription2}}
    </div>
    <template #footer>
        <BaseButton @click="switchContracts()" isSecondary>
            Verträge wechseln
        </BaseButton>
      </template>
</BaseModal>
</template>
<script>
import BaseModal from '@/components/core/BaseModal.vue';
import InputField from '@/components/core/forms/InputField.vue';
import BaseButton from '@/components/core/BaseButton.vue';

import axios from 'axios';
const config = {
    defaultSpinner: true,
};

export default {
    components: {
        BaseModal,
        InputField,
        BaseButton,
    },
    props: {
        insurance1: {
            type: Number,
        },
        insurance2: {
            type: Number,
        },        
    },
    data() {
        return {
            uniteInsurance1: "",
            uniteInsurance2: "",
            insuranceDescription1: "",
            insuranceDescription2: "",
        };
    },
    mounted() {
        this.uniteInsurance1 = this.insurance1;
        this.uniteInsurance2 = this.insurance2;
        this.insuranceDescription1 = "";
        this.insuranceDescription2 = "";
        this.$refs.modal.open();
        this.loadInsuranceDescription();
    },
    methods: {
        switchContracts() {
            // Temporary variables to hold the current values
            const tempUniteInsurance1 = this.uniteInsurance1;
            const tempInsuranceDescription1 = this.insuranceDescription1;

            // Swapping the values
            this.uniteInsurance1 = this.uniteInsurance2;
            this.insuranceDescription1 = this.insuranceDescription2;

            this.uniteInsurance2 = tempUniteInsurance1;
            this.insuranceDescription2 = tempInsuranceDescription1;

        },
        async loadInsuranceDescription() {
            const id1 = this.uniteInsurance1;
            const id2 = this.uniteInsurance2;
            const response = await axios.get(`${process.env.VUE_APP_API}/bipro_import/insurance_description?id=${id1}&id=${id2}`, config);
            const insuranceData = response.data;
            this.insuranceDescription1 = insuranceData[this.insurance1];
            this.insuranceDescription2 = insuranceData[this.insurance2];
        },
        async uniteInsurances() {
            axios.get(`${process.env.VUE_APP_API}/bipro_import/unite_insurances?id1=${this.uniteInsurance1}&id2=${this.uniteInsurance2}`, config)
            .then(response => {
                this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data, 'info'));
            });
        },
        close() {
            this.$emit("close");
        },
    }
}
</script>