<template>
  <div>
    <InputCheckBoxItem
      class="option-menu-permission--toggle-switch"
      label="Zugriff"
      :value="indeterminate ? false : isVisible"
      :disabled="isDisabled"
      :indeterminate="isVisible ? indeterminate : false"
      @input="onInput" 
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MENU_CONFIG_TYPES from '@/store/menuConfig/types';

import InputCheckBoxItem from '@/components/core/forms/checkbox/InputCheckBoxItem.vue';

export default {
  props: {
    menu: {
      type: Object,
      required: true,
    },
    userLevel: {
      type: String,
      required: true,
    },
    configContext: {
      type: String,
    },
    indeterminate: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapGetters({
      isMenuPathVisibleFn: MENU_CONFIG_TYPES.GETTERS.IS_MENU_PATH_VISIBLE,
      isMenuPathDisabledFn: MENU_CONFIG_TYPES.GETTERS.IS_MENU_PATH_DISABLED,
    }),
    isVisible() {
      return this.isMenuPathVisibleFn(this.configContext, this.userLevel, this.menu.path);
    },
    isDisabled() {
      return this.isMenuPathDisabledFn(this.configContext, this.userLevel, this.menu.path);
    },
  },
  methods: {
    onInput(visible) {
      const { configContext, userLevel, menu, } = this;
      this.$store.dispatch(MENU_CONFIG_TYPES.ACTIONS.ADD_OPTIONS_MENU_PERMISSION_CONFIG_EDITED, {
        configContext,
        userLevel,
        path: menu.path,
        visible,
      });

      this.$emit('input', visible);
    },
  },
  components: {
    InputCheckBoxItem,
  },
}
</script>

<!-- GLOBAL STYLE -->
<style lang="scss">
.option-menu-permission--toggle-switch {
  .input-forms__label-container {
    padding: 0;
  }

  .toggle-switch__body {
    width: 34px;
    height: 16px;
  }

  .toggle-switch__slider {
    &.round {
      border-radius: 16px;
    }

    &::before {
      width: 10px;
      height: 10px;
    }
  }
}
</style>
