<template>
  <div>
    <div class="box__container">
      <InputToggleSwitch 
        label="Zwei-Faktor-Authentifizierung per SMS (kostenpflichtig für Vermittler, 10 Cent zzgl. MwSt pro SMS)"
        v-model="toggleSMSValue"
        @input="toggleSMSCode()" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CORE_TYPES from '@/store/core/types.js';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';

export default {
  props: {
    unternr: {
      default: null,
    }
  },
  data() {
    return {
      toggleSMSValue: false,
    };
  },
  computed: {
    ...mapGetters({
      mfaChallenge: CORE_TYPES.GETTERS.MFA_CHALLENGE_TYPE,
    }),
  },
  watch: {
    mfaChallenge: {
      handler() {
        this.takeMfaChallengeTypeFromPinData();
      },
      immediate: true,
    },
  },
  methods: {
    toggleSMSCode() {
      if (this.toggleSMSValue) {
        if (this.mfaChallenge.phoneNumber2FAPresent) {
          this.$confirmModal({
            title: 'Kostenpflichtig aktivieren',
            message: `Diese Option verursacht zusätzlichen Kosten bei jeder Nutzung. 
              Diese Kosten müssen wir Ihnen vollständig in Rechnung stellen. 
              Es findet keine Begrenzung der Kosten statt. 
              Sie können diese Option hier jederzeit wieder abschalten. 
              Wollen Sie dieses kostenpflichtige Zusatzfeature wirklich für Ihren Zugang aktivieren?`, 
            labelButtonConfirm: 'Kostenpflichtig aktivieren',
          }).then(() => {
            this.changeMfaChallengeType('CODE_SMS');
          }).catch(() => {
            this.changeMfaChallengeType('APP');
          })
        } else {
          this.$confirmModal({
            title: 'Aktivierung nicht möglich',
            message: `Bitte fügen Sie erst eine Mobiltelefonnummer im Profil des Kunden im Bereich Kommunikationsdaten hinzu.`, 
            labelButtonConfirm: 'Ok',
            showCancelButton: false,
          }).finally(() => this.toggleSMSValue = false)
        }
      } else {
        this.changeMfaChallengeType('APP');
      }
    },
    changeMfaChallengeType(value) {
      this.toggleSMSValue = value === 'CODE_SMS';
      
      this.$store.dispatch(CORE_TYPES.ACTIONS.SAVE_MFA_CHALLENGE_TYPE, {
        unternr: this.unternr,
        mfaChallenge: {
          mfaChallengeType: value,
        }
      });
    },
    takeMfaChallengeTypeFromPinData() {
      this.toggleSMSValue = this.mfaChallenge.mfaChallengeType === 'CODE_SMS';
    }
  },
  mounted() {
    this.$store.dispatch(CORE_TYPES.ACTIONS.GET_MFA_CHALLENGE_TYPE, { unternr: this.unternr });
  },
  components: {
    InputToggleSwitch,
  },
}
</script>
