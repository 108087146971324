import EXPOST_TYPES from './types';
import Vue from 'vue';

export default {
  [EXPOST_TYPES.MUTATIONS.SET_CONFIG](state, payload) {
    Vue.set(state, 'config', payload);
  },
  [EXPOST_TYPES.MUTATIONS.SET_LIST](state, payload) {
    Vue.set(state, 'list', payload);
  },
  [EXPOST_TYPES.MUTATIONS.SET_BERECHNUNG](state, payload) {
    Vue.set(state, 'berechnung', payload);
  },

}
