var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "vorganglisteModal",
      attrs: {
        modalTitle: _vm.activity,
        labelButtonConfirm: "Hinzufügen",
        confirmDisabled: !_vm.selectedValidRows.length || !_vm.fondHasIsin,
        showCancelButton: !_vm.$isSmallScreen,
        closeButtonLabel: ""
      },
      on: { onConfirmButton: _vm.addFondsToListe }
    },
    [
      _vm.loading
        ? _c("GhostLoading", { attrs: { type: "table" } })
        : _c("Table", {
            attrs: {
              headers: _vm.headers,
              rows: _vm.rows,
              mobileConfig: {
                title: "label",
                headers: ["fondIncludedPill"],
                disableClickRow: true
              },
              rowId: "id",
              rowsPerPage: 15,
              tableRowsPerPage: []
            },
            scopedSlots: _vm._u([
              {
                key: "label",
                fn: function(row) {
                  return [
                    row.id == -1
                      ? _c(
                          "div",
                          [
                            _c("InputField", {
                              attrs: {
                                placeholder: _vm.placeholder,
                                disabled: !_vm.neuSelected,
                                validateUntouched: _vm.neuSelected
                              },
                              model: {
                                value: _vm.newName,
                                callback: function($$v) {
                                  _vm.newName = $$v
                                },
                                expression: "newName"
                              }
                            })
                          ],
                          1
                        )
                      : _c("span", [_vm._v(_vm._s(row.label))])
                  ]
                }
              },
              {
                key: "isSparplan",
                fn: function(row) {
                  return [
                    row.id == -1
                      ? _c(
                          "div",
                          [
                            _c("InputToggleSwitch", {
                              attrs: {
                                label: "",
                                inLineLabel: "",
                                disabled: !_vm.neuSelected
                              },
                              model: {
                                value: _vm.isSparplan,
                                callback: function($$v) {
                                  _vm.isSparplan = $$v
                                },
                                expression: "isSparplan"
                              }
                            })
                          ],
                          1
                        )
                      : _c("span", [_vm._v(_vm._s(row.isSparplan))])
                  ]
                }
              }
            ]),
            model: {
              value: _vm.selectedRows,
              callback: function($$v) {
                _vm.selectedRows = $$v
              },
              expression: "selectedRows"
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }