<template>
  <div>
    <PageHeaderTitleNavigation 
      :actions="headerActions"
      @action-BACK="close"
      @action-NEW_SPARPLAN="newSparplan"
      @action-action_neue_transaktion="newTransaktion"
      @action-actiontransaktionenpdf="transaktionenpdf"
      @action-button_transaktionen2xls="transaktionen2xls"
      @action-button_transaktionenanlegen="transaktionenanlegen"
      @action-button_courtagezubringer_neu="newCourtage"
      @action-button_add_wiedervorlage="newAktivitaet"
      @action-button_dokument_neu="newDocument"
    >
      <template #title>
        <span :class="{'fc-form-danger': blinkHinweis}">Depotposition</span>
      </template>
    </PageHeaderTitleNavigation>

    <div class="box__container d-lg-none col-12">
      <Stepper2
        ref="refStepper2"
        stepType="step"
        :visible="true"
        stepperName="BegleitscheinData"
        :selectedStepKey="selectedStepKey"
        :stepperService="steps"
        @setStep="setStep($event)"
      ></Stepper2>
    </div>

    <div class="box__container" v-if="hinweise.length">
      <BaseCollapsable v-model="showHinweis">
        <template v-slot:title>
          <span class="box__title" :class="{'fc-form-danger': blinkHinweis}">{{hinweisTitle()}}</span>
        </template>
        <template v-slot:content>
          <div class="d-block" v-if="hinweise.length">
            <div v-for="(text, idx) in hinweise" :key="idx">
              <div v-html="sanitize(text)"></div>
            </div>
          </div>
        </template>
      </BaseCollapsable>
    </div>

    <ContentWithStepper :stepperCollapsed="stepperCollapsed">
        <template #stepper>
            <BoxContainer>
                <VerticalStepper
                    :stepType="'tab'"
                    :visible="true"
                    :stepperName="'BegleitscheinData'"
                    :selectedStepKey="selectedStepKey"
                    :stepperService="steps"
                    :showCollapse="true"
                    :showSaveSpinner="showSaveSpinner"
                    @setStep="setStep($event)"
                    @collapsed="stepperCollapsed = $event"
                ></VerticalStepper>
            </BoxContainer>
        </template>
        <template #content>
          <BegleitscheinData ref="refBegleitscheinData" class="box__container"
            :currentStepKey="selectedStepKey" 
            @setStep="setStep($event)"/>
          <div class="box__container antrag-bottom-buttons-container row">
            <div class="col-12 my-1 col-sm-auto" v-if="selectedStepKey !== 'POSITION'">
              <BaseButton 
                isSecondary 
                isBlock
                @click="onButtonPrevious()">Zurück
              </BaseButton>
            </div>
            <div class="col-12 my-1 col-sm-auto" v-if="selectedStepKey !== 'DOKUMENT'">
              <BaseButton  
                isBlock
                @click="onButtonNext()">Weiter
              </BaseButton>
            </div>
            <div class="col-12 my-1 col-sm-auto" v-if="selectedStepKey === 'DOKUMENT'">
              <BaseButton 
                isBlock
                @click="close()">Abschließen
              </BaseButton>
            </div>
          </div>
        </template>
    </ContentWithStepper>

    <DocumentsUploadModal v-if="selectedStepKey==='DOKUMENT'"
      ref='uploadForm'
      :files='uploadFiles' 
      :additionalInputs="uploadFields" 
      :uploadType="3" 
      :multipleFileUpload="true"
      :openOnMount="false"
      :customUpload="dokUpload"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DEPOTPOSITIONENEDIT_TYPES from "@/store/depotpositionenedit/types";
import BaseCollapsable from '@/components/core/BaseCollapsable.vue';
import ContentWithStepper from '@/components/core/ContentWithStepper.vue';
import BoxContainer from '@/components/core/BoxContainer.vue';
import VerticalStepper from '@/components/vertical-stepper/VerticalStepper.vue';
import { StepperMediator } from '@/components/stepper/utils';
import BegleitscheinData from '@/components/begleitscheine/BegleitscheinData.vue';
import {sanitize} from '@/helpers/string-helper.js';
import Fields from '@/components/begleitscheine/Fields.vue';
import Stepper2 from '@/components/stepper/Stepper2.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import CORE_TYPES from '@/store/core/types'

import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';
import { viewDocument } from '@/components/core/download/DownloadLink.vue';
import DocumentsUploadModal from '@/components/fileUpload/DocumentsUploadModal.vue';

export default {
  name: 'BegleitscheinSteps',
  components: {
    BaseCollapsable, ContentWithStepper, BoxContainer, VerticalStepper, BegleitscheinData, Fields, Stepper2,
    BaseButton, OptionMenu, PageHeaderTitleNavigation, DocumentsUploadModal,
  },
  props: {
    bgsnr: {
      type: String
    }
  },
  data: function() {
    return {
      currentSelectedTab: null,
      stepperCollapsed: false,
      hinweise: [],
      blinkHinweis: false,
      showHinweis: false,
      uploadFields: [
        {key:'input_bezeichnung', label:'Bezeichnung', type:'STRING',value: ''},
        {key:'input_bemerkung', label:'Bemerkung', type:'TEXT',value: ''},
      ],
      uploadFiles: [],
      showSaveSpinner: false,
    }
  },
  mounted() {
    if (this.bgs.bgsnr != this.bgsnr) {
      this.$store.commit(DEPOTPOSITIONENEDIT_TYPES.MUTATIONS.BGS, {bgsnr: this.bgsnr});
      this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.GET_BGS, {bgsnr: this.bgsnr});
    }
  },
  
  beforeRouteLeave(to, from, next) {
    if (-1 === to.fullPath.indexOf('backAction=true')) {
      const arg = {
        label: 'zurück zur '+this.bgs.bgsnr + ' ' + this.currentSelectedTab?.label, 
      };
      if ( to.meta?.breadcrumb?.label) {
        arg.breadcrumb = to.meta?.breadcrumb?.label;
      }
      this.$addBreadcrumb(arg);
    }
    next();
  },
  computed: {
    ...mapGetters({
      begleitschein: DEPOTPOSITIONENEDIT_TYPES.GETTERS.BGS,
      hinweis: DEPOTPOSITIONENEDIT_TYPES.GETTERS.HINWEIS,
    }),
    steps() {
      const result = [];
      let nr = 1;
      if (this.bgs?.steps?.POSITION ) {
        result.push({stepKey: 'POSITION', label:'Depotposition', current: this.selectedStepKey==='POSITION', totalProgress: nr++});
      }
      if (this.bgs?.steps?.TRANSAKT ) {
        result.push({stepKey: 'TRANSAKT', label:'Transaktionen', current: this.selectedStepKey==='TRANSAKT', totalProgress: nr++});
      }
      if (this.bgs?.steps?.SPARPLAN ) {
        result.push({stepKey: 'SPARPLAN', label:'Sparpläne', current: this.selectedStepKey==='SPARPLAN', totalProgress: nr++});
      }
      if (this.bgs?.steps?.COURTAGE ) {
        result.push({stepKey: 'COURTAGE', label:'Courtagezubringer', current: this.selectedStepKey==='COURTAGE', totalProgress: nr++});
      }
      if (this.bgs?.steps?.AKTIVITAET ) {
        result.push({stepKey: 'AKTIVITAET', label:'Aktivitäten', current: this.selectedStepKey==='AKTIVITAET', totalProgress: nr++});
      }
      if (this.bgs?.steps?.DOKUMENT ) {
        result.push({stepKey: 'DOKUMENT', label:'Dokumente', current: this.selectedStepKey==='DOKUMENT', totalProgress: nr++});
      }
      return new StepperMediator(result)
    },
    bgs() {
      return this.begleitschein || {};
    },
    selectedStepKey() {
      return this.$route.params.stepKey || 'POSITION'
    },
    headerActions() {
      const actions = [];
      if (this.selectedStepKey === 'TRANSAKT' && this.bgs?.transaktActions?.length) {
        actions.push(...this.bgs?.transaktActions.map(action => PageHeaderSimpleAction(action.key, action.label)) || []);
      } else if (this.selectedStepKey === 'COURTAGE' && this.bgs?.courtageActions?.length) {
        actions.push(...this.bgs?.courtageActions.map(action => PageHeaderSimpleAction(action.key, action.label)) || []);
      } else if (this.selectedStepKey === 'SPARPLAN' && this.bgs?.aktivitaetActions?.length) {
        actions.push(PageHeaderSimpleAction('NEW_SPARPLAN', 'Neuen Spar/Entnahmeplan anlegen'));
      } else if (this.selectedStepKey === 'AKTIVITAET' && this.bgs?.aktivitaetActions?.length) {
        actions.push(...this.bgs?.aktivitaetActions.map(action => PageHeaderSimpleAction(action.key, action.label)) || []);
      } else if (this.selectedStepKey === 'DOKUMENT' && this.bgs?.dokumentActions?.length) {
        actions.push(...this.bgs?.dokumentActions.map(action => PageHeaderSimpleAction(action.key, action.label)) || []);
      }
      return actions;
    },
  },
  watch: {
    'steps': 'changeSteps',
    'hinweis': 'changeHinweis',
  },
  methods: {
    sanitize(htmlString) {
        return sanitize(htmlString);
    },
    async setStep(step) {
      if (step) {
        if (this.$refs.refBegleitscheinData.canChangeStep) {
          const canChange = this.$refs.refBegleitscheinData.canChangeStep(step)
          if (canChange === 'nein') {
            return;
          } else if (canChange === 'save') {
            this.showSaveSpinner = true;
            await this.$refs.refBegleitscheinData.saveData(step);
            this.showSaveSpinner = false;
          }
        }
        this.currentSelectedTab = this.steps.getCurrentStep(step);
        if (this.bgs) {
          this.bgs.selectedStepKey = step;
        }
        if (step !== this.selectedStepKey) {
          this.$router.push(`/home/depotpositionenedit/begleitscheinsteps/${this.bgs.bgsnr}/${step}`);
        }

        if ( this.currentSelectedTab?.label ) {
          this.$updateCurrentBreadcrumb({
            breadcrumb: (this.bgs.bgsnr == '0' ? 'Neue' : this.bgs.bgsnr) + ' ' + this.currentSelectedTab.label,
          })
        }
      }
    },
    changeSteps() {
      this.setStep(this.selectedStepKey);
    },
    changeHinweis() {
      this.hinweise = this.hinweis.texte;
      if (this.hinweis.show) {
        this.blinkHinweis = true;
        this.showHinweis = true;
        setTimeout(() => {
          this.blinkHinweis = false;
          this.$store.commit(DEPOTPOSITIONENEDIT_TYPES.MUTATIONS.HINWEIS, {show: false});
        }, 2000);
      }
    },
    hinweisTitle() {
      if (this.hinweise?.length === 1) {
        return '1 Hinweis';
      }
      if (this.hinweise?.length > 1) {
        return this.hinweise.length + ' Hinweise';
      }
      this.showHinweis = false;
      return 'Hinweise';
    },
    onBtClick(data) {
      if (data?.key === 'button_sparplan_edit') {
        this.$router.push(`/home/vermogensubersicht/sparplan/${this.bgs.bgsnr}/neu`);
      } else if (data?.key === 'button_auftrennen') {
        // TODO for Intern
      }
    },
    newSparplan() {
      this.$router.push(`/home/vermogensubersicht/sparplan/${this.bgs.bgsnr}/neu`);
    },
    newTransaktion() {
      this.$router.push({path: `/home/vermogensubersicht/bgstransakt/${this.bgs.bgsnr}/0/TRANSAKT` });
    },
    transaktionenpdf() {
      this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.JOB, {
          ask: 'get_pdf',
          bgsnr: this.bgs.bgsnr || '',
          table_name: 'tabletransaktionen',
          title: 'Transaktionen',
        })
        .then(response => {
          if (response?.redirectUrl) {
            viewDocument({
              href: response.redirectUrl,
            });
          }
        });
    },
    transaktionen2xls() {
      return this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.XLS, {
          bgsnr: this.bgs.bgsnr || '',
          table_name: 'tabletransaktionen',
          title: 'Transaktionen',
        })
        .then(response => {
          viewDocument({
            data: response.xls,
            filename: 'Transaktionen.xls',
            contentType: 'application/xls',
          }, true);
        });
    },
    transaktionenanlegen() {
      this.$router.push({path: `/home/vermogensubersicht/manuelle-transaktionen/${this.bgs.bgsnr}` });
    },
    newCourtage() {
      this.$router.push({path: `/home/vermogensubersicht/courtage/${this.bgs.bgsnr}/0` });
    },
    newAktivitaet() {
      this.$router.push({path: `/home/vermogensubersicht/bgsaktivit/${this.bgs.bgsnr}/0/DATEN` });
    },
    newDocument() {
      this.$refs.uploadForm.open();
    },
    dokUpload(data) {
      this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.JOB, {
        ask: 'add_dok',
        bgsnr: this.bgs.bgsnr || '',
        fileid: '' + data.id,
        input_bezeichnung: data.formData.input_bezeichnung,
        input_bemerkung: data.formData.input_bemerkung,
      }).then(response => {
        if (response.ok){
          this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.GET_BGS, {bgsnr: this.bgs.bgsnr});
        }
      });
    },
    onButtonPrevious() {
      const steps = this.steps?.getCurrentStepList() || [];
      const index = steps.findIndex(step => step.stepKey === this.selectedStepKey);
      if (index > 0 && index < steps.length) {
        this.setStep(steps[index - 1].stepKey);
      }
    },
    onButtonNext() {
      const steps = this.steps?.getCurrentStepList() || [];
      const index = steps.findIndex(step => step.stepKey === this.selectedStepKey);
      if (index >= 0 && index < steps?.length - 1) {
        this.setStep(steps[index + 1].stepKey);
      }
    }, 
    close() {
      this.$store.commit(CORE_TYPES.MUTATIONS.POP_SAVE_BACK_TO_PREVIOUS_PAGE)
      this.$router.push('/home/depotpositionenedit?backAction=true');
    }
  },
}
</script>

<style scoped>

</style>