import actions from './actions';
import mutations from './mutations';
import getters from './getters';

/**
 * mutations: synchronous operation responsible for change the state
 * actions: asynchronous operation responsible for call external resources and commit mutations 
 */
export function getInitialState() {
  return {
    currentType: null,
    filterCombos: {},
    otherProducts: { // indexed by type
      Immobilie: [],
      WeitereVerbindlichkeiten: [],
      WeitereVermoegen: [],
    },
    combos: { // indexed by type
      Immobilie: {},
      WeitereVerbindlichkeiten: {},
      WeitereVermoegen: {},
    },
    otherProduct: {},
    otherProductEdited: null,
    selectedSprodId: null,
    updates: [],
    documents: [],
    appointments: [],
    buchungen: [],
    selectedBuchung: {},
    buchungProvStrukturen: {}, // indexed by buchungsnr
  }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}