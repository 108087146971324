var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderActionCard", {
        attrs: { title: "Eingang", showBreadcrumb: false },
        scopedSlots: _vm._u([
          {
            key: "actionButtons",
            fn: function() {
              return [
                _c("div", [
                  _vm._v(
                    " Zur Dokumentation bzw. Ablage Ihrer ein- und ausgehenden Kunden-E-Mails im MSC können Sie hier die Daten des Eingangs-Servers Ihres E-Mail Zugangs hinterlegen. Möchten Sie, dass die Mails aus dem MSC an Ihre Kunden von Ihrem E-Mail-Account aus geschickt werden, so müssen Sie auch den Ausgangsserver hinterlegen. "
                  )
                ]),
                _c("div", [
                  _vm._v(
                    " Einmal täglich wird der Server auf Adressen überprüft, die im MSC vorhanden sind und eine Kopie der Mails im MSC unter dem Kunden gespeichert."
                  ),
                  _c("br"),
                  _c("b", [
                    _vm._v("Es werden keine Mails auf Ihrem Server gelöscht.")
                  ])
                ])
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", config: { table: { rows: 3 } } }
              })
            : _c("Table", {
                attrs: {
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowsPerPage: 10,
                  hidePagination: false
                },
                on: {
                  "click-server": _vm.editServer,
                  "action-DELETE": _vm.deleteKontakt,
                  "action-EDIT": _vm.editServer,
                  "action-FOLDER_STRUCTURE": _vm.openOrdnerstruktur,
                  "action-IGNORED_LIST": _vm.openIgnoredList,
                  "action-ERRORS": _vm.openErrors
                }
              })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "errorModal",
          attrs: { labelButtonCancel: "Ok", showConfirmButton: false },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-warning", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v(" Nicht durchsuchte Ordner im Postfach ")
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", [
            _vm._v(
              " Es konnten nicht alle Ordner eingelesen werden: " +
                _vm._s(_vm.errorCount) +
                " Ordner im Postfach konnten nicht durchsucht werden. Insbesondere Sonderzeichen oder Umlaute in Ordnernamen können zu diesem Problem führen. "
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }