var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "editModal",
          attrs: { modalTitle: "Kategorie" },
          on: {
            onConfirmButton: function($event) {
              return _vm.submit()
            },
            close: function($event) {
              return _vm.close()
            }
          }
        },
        [
          _c("InputField", {
            attrs: { type: "currency", precision: 2, label: "Budget:" },
            model: {
              value: _vm.budget,
              callback: function($$v) {
                _vm.budget = $$v
              },
              expression: "budget"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }