export const MUTATION_PREFIX = 'BALANCE_SHEET_MUTATIONS_';
export const ACTIONS_PREFIX = 'BALANCE_SHEET_ACTIONS_';
export const GETTERS_PREFIX = 'BALANCE_SHEET_GETTERS_';

export default {
  MUTATIONS: {
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    GET_FAILTURE: MUTATION_PREFIX + 'GET_FAILTURE',
    GET_OTHER_ASSETS_SUCCESS: MUTATION_PREFIX + 'GET_OTHER_ASSETS_SUCCESS',
    GET_CREDIT_SUCCESS: MUTATION_PREFIX + 'GET_CREDIT_SUCCESS',
    GET_BANK_ACCOUNT_SUCCESS: MUTATION_PREFIX + 'GET_BANK_ACCOUNT_SUCCESS',
    GET_CLOSED_FUNDS_SUCCESS: MUTATION_PREFIX + 'GET_CLOSED_FUNDS_SUCCESS',
    GET_DEPOSIT_SUM_SUCCESS: MUTATION_PREFIX + 'GET_DEPOSIT_SUM_SUCCESS',
    GET_ACCOUNTS_SUCCESS: MUTATION_PREFIX + 'GET_ACCOUNTS_SUCCESS',
    GET_ACCOUNTS_DETAILS_SUCCESS: MUTATION_PREFIX + 'GET_ACCOUNTS_DETAILS_SUCCESS',
    GET_INSURANCE_TABLE_SUCCESS: MUTATION_PREFIX + 'GET_INSURANCE_TABLE_SUCCESS',
    GET_DEPOTPOSITIONS_SUCCESS: MUTATION_PREFIX + 'GET_DEPOTPOSITIONS_SUCCESS',
    GET_TRANSACTIONS_SUCCESS: MUTATION_PREFIX + 'GET_TRANSACTIONS_SUCCESS',
    GET_SPAR_ENTNAHMEPLANE_SUCCESS: MUTATION_PREFIX + 'GET_SPAR_ENTNAHMEPLANE_SUCCESS',
    GET_VERMOGENSUBERSICHT_PDF_SUCCESS: MUTATION_PREFIX + 'GET_VERMOGENSUBERSICHT_PDF_SUCCESS',
  },
  ACTIONS: {
    GET_OTHER_ASSETS: ACTIONS_PREFIX + 'GET_OTHER_ASSETS',
    GET_CREDIT: ACTIONS_PREFIX + 'GET_CREDIT',
    GET_BANK_ACCOUNT: ACTIONS_PREFIX + 'GET_BANK_ACCOUNT',
    GET_CLOSED_FUNDS: ACTIONS_PREFIX + 'GET_CLOSED_FUNDS',
    GET_DEPOSIT_SUM: ACTIONS_PREFIX + 'GET_DEPOSIT_SUM',
    GET_ACCOUNTS: ACTIONS_PREFIX + 'GET_ACCOUNTS',
    GET_ACCOUNTS_DETAILS: ACTIONS_PREFIX + 'GET_ACCOUNTS_DETAILS',
    GET_INSURANCE_TABLE: ACTIONS_PREFIX + 'GET_INSURANCE_TABLE',
    GET_DEPOTPOSITIONS: ACTIONS_PREFIX + 'GET_DEPOTPOSITIONS',
    GET_TRANSACTIONS: ACTIONS_PREFIX + 'GET_TRANSACTIONS',
    GET_SPAR_ENTNAHMEPLANE: ACTIONS_PREFIX + 'GET_SPAR_ENTNAHMEPLANE',
    GET_VERMOGENSUBERSICHT_PDF: ACTIONS_PREFIX + 'GET_VERMOGENSUBERSICHT_PDF',
  },
  GETTERS: {
    OTHER_ASSETS: GETTERS_PREFIX + 'OTHER_ASSETS',
    CREDIT: GETTERS_PREFIX + 'CREDIT',
    BANK_ACCOUNT: GETTERS_PREFIX + 'BANK_ACCOUNT',
    CLOSED_FUNDS: GETTERS_PREFIX + 'CLOSED_FUNDS',
    DEPOSIT_SUM: GETTERS_PREFIX + 'DEPOSIT_SUM',
    ACCOUNTS: GETTERS_PREFIX + 'ACCOUNTS',
    ACCOUNTS_DETAILS: GETTERS_PREFIX + 'ACCOUNTS_DETAILS',
    INSURANCE_TABLE: GETTERS_PREFIX + 'INSURANCE_TABLE',
    DEPOTPOSITIONS: GETTERS_PREFIX + 'DEPOTPOSITIONS',
    TRANSACTIONS: GETTERS_PREFIX + 'TRANSACTIONS',
    SPAR_ENTNAHMEPLANE: GETTERS_PREFIX + 'SPAR_ENTNAHMEPLANE',
    VERMOGENSUBERSICHT_PDF: ACTIONS_PREFIX + 'VERMOGENSUBERSICHT_PDF',
  }
}