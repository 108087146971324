var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "initModal",
          attrs: {
            modalTitle: "Wählen Sie eine Lagerstelle",
            labelButtonConfirm: "Bestätigen",
            confirmDisabled: !_vm.lagerstelleSelectorConfirmAllowed,
            showCancelButton: false,
            showCloseButton: false,
            showConfirmButton: _vm.isSelectedDepotnumberAllowed
          },
          on: {
            onConfirmButton: _vm.loadData,
            onCloseButton: function($event) {
              return _vm.$router.go(-1)
            }
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c("BaseBackButtonBreadcrumb", {
                    staticClass: "mr-16px",
                    attrs: {
                      showAsSecondaryButton: "",
                      fixedLabel: "Abbrechen"
                    }
                  })
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm.lagerstellen && _vm.lagerstellen.length === 0
            ? _c("div", [_vm._v(" Sie müssen eine Kundennummer erstellen. ")])
            : _c(
                "div",
                [
                  _c("ComboBox", {
                    attrs: {
                      label: "Lagerstelle",
                      values: _vm.lagerstellenSorted,
                      disabled: _vm.isLagerstelleDisabled
                    },
                    on: {
                      change: function($event) {
                        return _vm.lagestelleChanged()
                      }
                    },
                    model: {
                      value: _vm.lagerstelleSelect,
                      callback: function($$v) {
                        _vm.lagerstelleSelect = $$v
                      },
                      expression: "lagerstelleSelect"
                    }
                  }),
                  _c("ComboBox", {
                    attrs: {
                      label: "Depotnummer",
                      values: _vm.getDepotNumbers,
                      validateUntouched: ""
                    },
                    model: {
                      value: _vm.depotSelect,
                      callback: function($$v) {
                        _vm.depotSelect = $$v
                      },
                      expression: "depotSelect"
                    }
                  }),
                  _vm.depotSelect === _vm.FREIE_EINGABE
                    ? _c("InputField", {
                        on: {
                          input: function($event) {
                            return _vm.kontoFreeTextChanged()
                          }
                        },
                        model: {
                          value: _vm.kontoFreeText,
                          callback: function($$v) {
                            _vm.kontoFreeText = $$v
                          },
                          expression: "kontoFreeText"
                        }
                      })
                    : _vm._e(),
                  !_vm.isSelectedDepotnumberAllowed
                    ? _c("div", {
                        staticClass: "color-danger",
                        domProps: {
                          innerHTML: _vm._s(_vm.selectedDepotNumber.warning)
                        }
                      })
                    : _vm._e(),
                  _vm.lagerstelleSelectorConfirmAllowed === false
                    ? _c("div", { staticClass: "color-danger" }, [
                        _vm._v("Depotnummer ist ungültig!")
                      ])
                    : _vm._e(),
                  _vm.predefinedFundsList
                    ? _c("div", [
                        _vm._v(
                          " Es werden nur bei ausgewählter Lagerstelle beziehbare Wertpapiere berücksichtigt. "
                        )
                      ])
                    : _vm._e()
                ],
                1
              )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }