import axios from 'axios';

import { ResolverId } from '../types';
import { createLinkResolver, wrapUrl, wrapError, wrapDownloadLink, wrapDownloadLinkHref } from '../utils';

const NO_PDF_FILE_MESSAGE = 'Keine PDF-Datei vorhanden.';

const FACTSHEET_TYPE = 'FACTSHEET';
const MR_TYPE = 'MR';
const KIID_TYPE = 'KIID';

const MAP_TYPE_FILENAME = {
  [FACTSHEET_TYPE]: 'Factsheet.pdf',
  [MR_TYPE]: 'KAG.pdf',
  [KIID_TYPE]: 'PRIIP-BIB.pdf',
};

const PDF_EXTENSION = '.pdf';

export default createLinkResolver(ResolverId.Factsheet, async function({ apiAddress }, { type, isin, lagerstelle, ...payload }) {
  // type, e.g., FACTSHEET, MR, KIID
  if (!type || !isin) {
    return wrapError(NO_PDF_FILE_MESSAGE);
  }

  const { filename = MAP_TYPE_FILENAME?.[type] } = payload ?? {};
  const params = {
    type,
    isin,
    lagerstelle,
  };

  if (type === MR_TYPE || type === KIID_TYPE) {
    return wrapDownloadLink(filename, '/download_service/get_factsheet', params);
  } else if (type === FACTSHEET_TYPE) {
    const response = await axios.get(`${apiAddress}/download_service/get_factsheet_url/${filename}`, {
      defaultSpinner: true, 
      params,
    });

    if (response?.data?.includes(PDF_EXTENSION)) {
      return wrapDownloadLinkHref(filename, response.data);
    } else {
      return wrapUrl(response.data);
    }
  }

  return wrapError(NO_PDF_FILE_MESSAGE);
});
