import PORTFOLIO_TYPES from './types';
import axios from 'axios';

const config = {
  defaultSpinner: true
};

export default {
  [PORTFOLIO_TYPES.ACTIONS.GET_DEPOT_CONFIG]({ commit, state, dispatch, rootState }, payload) {
   
    let url = 'mrsdepotconfig';
    axios.get(rootState.core.apiAddress + '/../' + url, config).then(response => {
      if (response && response.data) {
        commit(PORTFOLIO_TYPES.MUTATIONS.GET_DEPOT_CONFIG_SUCCESS, response.data);
      }
    }).catch(error => {
      commit(PORTFOLIO_TYPES.MUTATIONS.GET_FAILTURE);
    })
  },
  async [PORTFOLIO_TYPES.ACTIONS.GET_REALISED_PROFIT]({ commit, state, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true,
      params: {
        ...{...payload.id && payload.id !== 'undefined' ? {
          'depotid': `${payload.id}`
        } : {}}
      }
    };
    let url = `mrsrealisedprofit`;
    await axios.get(rootState.core.apiAddress + '/../' + url, config).then(response => {
      if (response && response.data) {
        commit(PORTFOLIO_TYPES.MUTATIONS.GET_REALISED_PROFIT_SUCCESS, {data: response.data, id: payload.id});
      }
    }).catch(error => {
      commit(PORTFOLIO_TYPES.MUTATIONS.GET_FAILTURE);
    })
  },
  async [PORTFOLIO_TYPES.ACTIONS.GET_HISTORICAL_PERFORMANCE]({ commit, state, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true,
      params: {...{...payload.id && payload.id !== 'undefined' ? {
          'depotid': `${payload.id}`
        } : {}}
      } 
    };
    let url = 'mrshistoricalperformance';
    await axios.get(rootState.core.apiAddress + '/../' + url, config).then(response => {
      if (response && response.data) {
        commit(PORTFOLIO_TYPES.MUTATIONS.GET_HISTORICAL_PERFORMANCE_SUCCESS, {data: response.data, id: payload.id});
      }
    }).catch(error => {
      commit(PORTFOLIO_TYPES.MUTATIONS.GET_FAILTURE);
    })
  },
  [PORTFOLIO_TYPES.ACTIONS.GET_PERFORMANCE_FILTERED]({ commit, state, dispatch, rootState }, data) {
    const config = {
      defaultSpinner: true,
      ...{...data.id && data.id !== 'undefined' ? {
        'depotid': `${data.id}`
      } : {}}
    };
    let params = {};
    if (data.dateBegin) {
      params = {
        dateBegin: data.dateBegin,
        dateEnd: data.dateEnd,
      };
    }
    if (data.modus) {
      params = {
        modus: data.modus,
        value: data.value,
      };
    }
    if (data.id) {
      params['depotid'] = data.id;
    }
    Object.keys(params).forEach((key) => (params[key] == null || params[key] === undefined) && delete params[key]);

    let url = 'mrsperformance';
    axios.get(rootState.core.apiAddress + '/../' + url, {defaultSpinner: true, ...config, params}).then(response => {
      if (response && response.data) {
        commit(PORTFOLIO_TYPES.MUTATIONS.SET_IS_LOADED, true);
        commit(PORTFOLIO_TYPES.MUTATIONS.GET_PERFORMANCE_FILTERED_SUCCESS, {id: data.id, data: response.data});
      }
    }).catch(error => {
      commit(PORTFOLIO_TYPES.MUTATIONS.GET_FAILTURE);
    })
  },

  [PORTFOLIO_TYPES.ACTIONS.GET_HISTORICAL_PERFORMANCE_DETAILS]({ commit, state }, {year, depotId}) {
    if (year) {
      
      commit(PORTFOLIO_TYPES.MUTATIONS.GET_HISTORICAL_PERFORMANCE_DETAILS_SUCCESS, {...state, isLoaded: false})
      axios.get(`${process.env.VUE_APP_API}/berichteInvestment/getJahresperformanceDetails?year=${year}&depotId=${depotId}`, config).then(response => {
        if (response.status === 200 && response.data) {
          commit(PORTFOLIO_TYPES.MUTATIONS.GET_HISTORICAL_PERFORMANCE_DETAILS_SUCCESS, {...response.data, isLoaded: true})
        }
      });
    }
  },

}
