var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-forms__container" }, [
    _c("div", { staticClass: "input-forms__label-container" }, [
      _c("div", { staticClass: "row mt-0" }, [
        _c("div", { staticClass: "col" }, [
          _c(
            "div",
            {
              staticClass:
                "input-forms__label-content input-forms__label-content--bigger"
            },
            [
              _c("ph-bank", { attrs: { size: 16 } }),
              _vm._v(" " + _vm._s(_vm.title) + " ")
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "col-12 col-sm-4 col-xl-2 mx-0 ml-3 mb-3" },
          [
            _c(
              "BaseButton",
              {
                attrs: { isBlock: "", disabled: _vm.disabled },
                on: {
                  click: function($event) {
                    _vm.openFondsfinder(
                      _vm.config && _vm.config.istSparplan ? "SPARPLAN" : "KAUF"
                    )
                  }
                }
              },
              [_vm._v(" " + _vm._s(_vm.config.buttonText) + " ")]
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        [
          _vm._l(_vm.positions, function(pos, index) {
            return [
              _c("FormFFBSparplanPositionCards", {
                key: index,
                attrs: {
                  position: pos,
                  antragId: _vm.antragId,
                  disabled: _vm.disabled,
                  config: _vm.config,
                  categoryId: _vm.categoryId
                }
              })
            ]
          })
        ],
        2
      )
    ]),
    _vm.config.mitSummenbildung && this.positions && this.positions.length
      ? _c(
          "div",
          [
            _vm.categoryId == "SPARPLAN"
              ? _c("Summenbildung", {
                  attrs: {
                    antragId: _vm.antragId,
                    summeSpar: _vm.summe,
                    mitSparplan: true,
                    ohneEinmal: true
                  }
                })
              : _vm._e(),
            _vm.categoryId == "KAUF"
              ? _c("Summenbildung", {
                  attrs: { antragId: _vm.antragId, summe: _vm.summe }
                })
              : _vm._e()
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }