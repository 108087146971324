import { MenuType } from '@/menu/menu-utils';
import MENU_STRUCTURE_TYPES from './types';

export default {

  [MENU_STRUCTURE_TYPES.GETTERS.MENU_STRUCTURES](state) {
    return [ ...state.menuStructures || [] ];
  },

  [MENU_STRUCTURE_TYPES.GETTERS.MENU_STRUCTURE_CONTEXT](state) {
    const { menuStructureContext = {} } = state;
    const { flatMenuDefault, flatMenuConfigured, flatMenuConfiguredEdited } = menuStructureContext;
    const configured = flatMenuConfiguredEdited ?? flatMenuConfigured ?? [];

    const primaryMenuDefault = flatMenuDefault.filter(menu => menu.type === MenuType.PrimaryMenu);
    const primaryMenuConfigured = configured.filter(menu => menu.type === MenuType.PrimaryMenu);
    const availablePrimaryMenu = primaryMenuDefault.filter(menu => !primaryMenuConfigured.some(cm => menu.path === cm.path));

    const availableMenuItem = flatMenuDefault.filter(menu => menu.type === MenuType.MenuItem);
    const availableAsyncMenu = flatMenuDefault.filter(menu => menu.type === MenuType.AsyncMenu);

    const groupMenuDefault = flatMenuDefault.filter(menu => menu.type === MenuType.GroupMenu);
    const groupMenuConfigured = configured.filter(menu => menu.type === MenuType.GroupMenu);
    const availableGroupMenu = groupMenuDefault.filter(menu => !groupMenuConfigured.some(cm => menu.path === cm.path));

    const availableOptionsMenu = [ ...availableMenuItem, ...availableGroupMenu, ...availableAsyncMenu ];

    return {
      ...menuStructureContext, 
      flatMenuConfigured: [ ...configured ], 
      primaryMenu: [ ...primaryMenuConfigured ], 
      availablePrimaryMenu, 
      availableOptionsMenu, 
    };
  },

  [MENU_STRUCTURE_TYPES.GETTERS.HAS_MENU_STRUCTURE_EDITED](state) {
    const { menuStructureContext = {} } = state;
    return !!menuStructureContext.flatMenuConfiguredEdited;
  },

  [MENU_STRUCTURE_TYPES.GETTERS.MENU_STRUCTURE_CONFIGS](state) {
    const { menuStructureConfigs, menuStructureConfigsEdited } = state;

    const menuStructureConfigsMerged = [
      ...menuStructureConfigs.map(config => ({
        ...config, 
        ...(menuStructureConfigsEdited.find(edited => edited.id === config.id) || {}), 
      })), 
      ...menuStructureConfigsEdited.filter(edited => !edited.id), 
    ];

    return [ ...menuStructureConfigsMerged || [] ];
  },

  [MENU_STRUCTURE_TYPES.GETTERS.HAS_MENU_STRUCTURE_CONFIGS_EDITED](state) {
    return state.menuStructureConfigsEdited?.length > 0;
  },

}
