<template>
  <div>
    <InputField
      label="aktueller Bestand gem. Depotpositionen:"
      v-model="depotbestand"
      :disabled="true"
      isComponentHalfSize
      type="currency"/>
    <div v-if="showBondDepotRelevant">
      <InputField
        label="beratungsrelevante Wertpapierdepots (Gesamtbetrag lt. aktueller Depotauszüge)"
        v-model="bondDepot"
        type="currency"
        isComponentHalfSize
        :disabled="isDisabled || isRefusedPage"
        @input="updateStore('finance', 'bondDepot', $event || 0)" />
      <InputField
        label="nicht beratungsrelevante Wertpapierdepots (Gesamtbetrag lt. aktueller Depotauszüge)"
        v-model="bondDepotNotRelevant"
        type="currency"
        isComponentHalfSize
        :disabled="isDisabled || isRefusedPage"
        @input="updateStore('finance', 'bondDepotNotRelevant', $event || 0)" />
    </div>
    <div v-else>
      <InputField class="font-bold"
        label="Wertpapierdepots (Gesamtbetrag lt. aktueller Depotauszüge)"
        v-model="bondDepot"
        type="currency"
        isComponentHalfSize
        :disabled="isDisabled || isRefusedPage"
        @input="updateStore('finance', 'bondDepot', $event || 0)" />
    </div>
 
    <InputField
      label="gebundene Spareinlagen (z.B. Sparbuch, Festgeld)"
      v-model="boundBankDeposit"
      type="currency"
      isComponentHalfSize
      :disabled="isDisabled || isRefusedPage"
      @input="updateStore('finance', 'boundBankDeposit', $event || 0)" />
   
    <InputField
      label="Alternative Investments"
      v-model="alternativeInvestment"
      type="currency"
      isComponentHalfSize
      :disabled="isDisabled || isRefusedPage"
      @input="updateStore('finance', 'alternativeInvestment', $event || 0)" />
    <InputField
      label="Kunstgegenstände, Antiquitäten"
      v-model="art"
      type="currency"
      isComponentHalfSize
      :disabled="isDisabled || isRefusedPage"
      @input="updateStore('finance', 'art', $event || 0)" />
    <InputField
      label="Bausparverträge (aktuelle Werte)"
      v-model="buildingSaving"
      type="currency"
      isComponentHalfSize
      :disabled="isDisabled || isRefusedPage"
      @input="updateStore('finance', 'buildingSaving', $event || 0)" />
    <InputField
      label="Lebensversicherungen, Kapitalversicherungen (aktuelle Rückkaufswerte)"
      v-model="insurance"
      type="currency"
      isComponentHalfSize
      :disabled="isDisabled || isRefusedPage"
      @input="updateStore('finance', 'insurance', $event || 0)" />
    <InputField class="font-bold"
      label="Sonstiges"
      v-model="otherInvestment"
      type="currency"
      isComponentHalfSize
      :disabled="isDisabled || isRefusedPage"
      @input="updateStore('finance', 'otherInvestment', $event || 0)" />

    <div class="sum d-flex justify-content-between">
      <div class="my-2">Summe Kapitalvermögen / sonstiger Vermögenswerte</div>
      <div class="my-2 pr-3"><CurrencyLabel :value="summeAnlage"/></div>
    </div>
  </div>
</template>

<script>

import InputField from '@/components/core/forms/InputField.vue';
import anlegerprofilMixin from '@/mixins/anlegerprofil/anlegerprofil-mixin.js';
import CurrencyLabel from '@/components/core/CurrencyLabel.vue';

export default {
  mixins: [anlegerprofilMixin],
  components: {
    InputField,
    CurrencyLabel,
  },
  data() {
    return {
      depotbestand: '',
      bondDepot: '',
      bondDepotNotRelevant: '',
      alternativeInvestment: '',
      art: '',
      buildingSaving: '',
      insurance: '',
      otherInvestment: '',
      boundBankDeposit: '',
    };
  },
  computed: {
    isRefusedPage() {
      return this._isRefusedPage(this.data, 'finanzen');
    },
    showBondDepotRelevant() {
      return this.data?.finance?.showBondDepotRelevant;
    }
  },
  mounted() {
    this.createForm();
    this.updateWarnings();
  },
  methods: {
    createForm() {
      if (this.data?.finance) {
        this.depotbestand = this.data.finance.depotbestand || '';
        this.bondDepot = this.data.finance.bondDepot || '';
        this.bondDepotNotRelevant = this.data.finance.bondDepotNotRelevant || '';
        this.alternativeInvestment = this.data.finance.alternativeInvestment || '';
        this.art = this.data.finance.art || '';
        this.buildingSaving = this.data.finance.buildingSaving || '';
        this.insurance = this.data.finance.insurance || '';
        this.otherInvestment = this.data.finance.otherInvestment || '';
        this.boundBankDeposit = this.data.finance.boundBankDeposit || '';

        this.updateStore('finance', 'bondDepot', this.bondDepot || 0);
      }
    },
  },
}
</script>

<style scoped>
  .sum {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    font-size: 1.25rem;
    font-weight: bold;
  }
</style>