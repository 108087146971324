<template>
  <div>
    <BaseModal
      ref="modal"
      modalTitle="Vermittler anlegen"
      :showDefaultButtons="false"
      @close="onClose"
    >
      <template #default>
        <InputField label="Firmenname" v-model="form.firmenname" validateUntouched autofocus />

        <InputField label="Postleitzahl" v-model="form.plz" validateUntouched />

        <ComboBox label="Staatsangehörigkeit" :values="brokerDataConfig.nationalities" v-model="form.nationality" />

        <InputField label="Ort" v-model="form.ort" />

        <ComboBox label="Land" :values="brokerDataConfig.countries" v-model="form.land" />
      </template>
      <template #footer>
        <BaseButton isSecondary @click="close()" :disabled="loading">Abbrechen</BaseButton>
        <BaseButton @click="saveBroker()" :disabled="!isValid || loading">
          Vermittler anlegen <AnimatedSpinner v-if="loading" />
        </BaseButton>
      </template>
    </BaseModal>

    <BaseModal
      ref="haftungserklaerungsUntervermittlerModal"
      size="lg"
      modalTitle="Haftungserklärung"
      :showDefaultButtons="false"
      @onCloseButton="onClose()"
      @onEscButton="onClose()"
      >
      <div>
        <HaftungserklaerungsUntervermittler @confirmed="isHaftungConfirmed = true; open()" @close="closeHaftungsModal(); onClose()"/>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters, } from 'vuex';
import BROKERDATA_TYPES from '@/store/brokerData/types';
import CORE_TYPES from '@/store/core/types';

import BaseModal from '@/components/core/BaseModal.vue';
import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';
import HaftungserklaerungsUntervermittler from './HaftungserklaerungsUntervermittler.vue';

import { ROLES, } from '@/router/roles';

import validator from '@/mixins/validator';
import { required } from '@/mixins/validator/rules';
import { getRedirectionWindow, } from '@/components/table/export/utils';
import { addParamToURL } from '@/helpers/utils-helper';

const BROKER_SEARCH_HOME = '/customer/maklersuche';
const FA_NATIONALITY = 'österreichisch';
const FA_LAND = 'Österreich';
const FK_NATIONALITY = 'deutsch';
const FK_LAND = 'Deutschland';

export default {
  name: 'NewBroker',
  mixins: [validator],
  components: {
    BaseModal,
    InputField,
    ComboBox,
    BaseButton,
    AnimatedSpinner,
    HaftungserklaerungsUntervermittler,
  },
  data() {
    return {
      form: {},
      loading: false,
      isHaftungConfirmed: false,
    };
  },
  computed: {
    ...mapGetters({
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      brokerDataConfig: BROKERDATA_TYPES.GETTERS.GET_BROKER_DATA_CONFIG,
      isFA: CORE_TYPES.GETTERS.IS_FA,
    }),
    brauchtHaftungserklaerung() {
      return this.hasRoles([ROLES.BRAUCHT_HAFTUNGSERKLAERUNG_NEW_BROKER]);
    },
    isValid() {
      return this.validation.updated && this.validation.isValid('form');
    },
    headerActions() {
      return [];
    },
  },
  methods: {
    open() {
      const { brauchtHaftungserklaerung } = this;

      if(this.isHaftungConfirmed || !brauchtHaftungserklaerung) {
        this.closeHaftungsModal();
        this.init();
        this.$refs.modal.open();
      } else {
        this.$refs.haftungserklaerungsUntervermittlerModal.open();
      }
    },
    async init() {
      this.reset();
      await this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.GET_BROKER_DATA_CONFIG);
      this.setInitialData();
    },
    setInitialData() {
      const { isFA, } = this;
      this.$set(this.form, 'nationality', isFA ? FA_NATIONALITY : FK_NATIONALITY);
      this.$set(this.form, 'land', isFA ? FA_LAND : FK_LAND);
    },
    close() {
      this.$refs.modal.close();
    },
    onClose() {
      this.reset();
      this.$router.push(addParamToURL(BROKER_SEARCH_HOME, 'backAction=true'));
    },
    reset() {
      this.form = {};
    },
    async saveBroker() {
      const windowRef = getRedirectionWindow(true);
      try {
        this.loading = true;
        const payload = {
          ...(this.form || {}),
        };

        const response = await this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.SAVE_NEW_BROKER, payload);
        if(response?.maklernr) {
          this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_BROKER_NEW_TAB, {
            brokerId: response.maklernr,
            path: '/intern/vermittlerdaten',
            windowRef,
          });
        }

        this.close();
      } catch(e) {
        windowRef.close();
      } finally {
        this.loading = false;
      }
    },
    closeHaftungsModal() {
      this.$refs.haftungserklaerungsUntervermittlerModal?.close();
    }
  },
  mounted() {
    this.open();
  },
  validators: {
    form: {
      firmenname: [required()],
      plz: [required()],
    }
  },
}
</script>
