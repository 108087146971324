<template>
  <div class="antrag-components__container">
    <div class="box__container">

      <InputRadioBoxGroup 
        :value="form.alle_WP" 
        :validateUntouched="true"
        :values="[
          {value: true, label: 'Bitte übertragen Sie das gesamte Depot'},
          {value: false, label: 'Bitte übertragen Sie einzelne Wertpapiere:'}
        ]"
        @input="$emit('save', { alle_WP: $event })"/>
      <FormDivider/>

      <InputRadioBoxGroup :value="form.uebertragungsArt" title="Art des Übertrags" 
        :values="artValues"
        @input="$emit('save', { uebertragungsArt: $event })"/>
      <FormDivider/>

      <InputCheckBoxGroup 
        v-model="form"
        title="Übertrag der Verlustverrechnungstöpfe"
        :values="[ 
          { label: 'Aktien', path: 'verlustuebertragenAktien' },
          { label: 'Sonstige', path: 'verlustuebertragen_sonstige' }, 
          { label: 'ausländische Quellensteuer', path: 'verlustuebertragenAuslaendisch' },
        ]"
        @checkBoxToggle="$emit('save', $event)"/>
      <FormDivider/>

      <BaseButton class="col-12 mr-2" :isSecondary="true" @click="$emit('back')">Zurück</BaseButton>
      <BaseButton class="col-12" @click="$emit('next')">Weiter</BaseButton>
    </div>
  </div>
</template>
<script>

import antragNavigationMixin from '@/mixins/antrag/antrag-navigation-mixin.js';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import FormDivider from '@/components/core/forms/FormDivider.vue';
import InputCheckBoxGroup from '@/components/core/forms/checkbox/InputCheckBoxGroup.vue';
import InputField from '@/components/core/forms/InputField.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';

export default {
  mixins: [antragNavigationMixin],
  components: {
    InputRadioBoxGroup,
    BaseButton,
    FormDivider,
    InputCheckBoxGroup,
    InputField,
    InputToggleSwitch,
  },
  props: {
    positionData: {
    },
    anzahlFonds: {
      type: Number,
      default: 4,
    },
    warnings: {
    },
  },
  data() {
    return {
      form: {},
      artValues: [
        {value: 'Inhabergleich', label: 'Inhabergleich<br>Überträge auf eigene Depots gelten steuerrechtlich nicht als Inhaberwechsel und sind daher'
					+ ' steuerlich unbeachtlich. Eine Meldung an die Finanzbehörden erfolgt daher nicht. Anschaffungsdaten werden innerhalb von Deutschland'
					+ ' übertragen.'},
        {value: 'unentgeltlich', label: 'Inhaberwechsel - unentgeltlich<br>Überträge auf Depots Dritter gelten als Überträge mit Inhaberwechsel.'
					+ ' Werden ab dem 01.01.2009 angeschaffte Bestände unentgeltlich übertragen, erfolgt grundsätzlich die Meldung "unentgeltlicher Übertrag"'
					+ ' an die Finanzbehörden. Anschaffungsdaten werden innerhalb von Deutschland übertragen.'},
        {value: 'Erbschaft', label: 'Inhaberwechsel - Erbschaft<br>Anschaffungsdaten werden innerhalb von Deutschland übertragen.'},
        {value: 'entgeltlich', label: 'Inhaberwechsel - entgeltlich<br>Für Bestände, die ab dem 01.01.2009 angeschafft wurden, unterstellt'
					+ ' das Einkommenssteuergesetz eine Veräußerung, sofern bei Beauftragung der Übertrag nicht als unentgeltlich deklariert wurde.'},
      ],
    }
  },
  mounted() {
    this.form = Object.assign({}, this.positionData);
    if(!this.form.alle_WP){
      this.form.alle_WP = true;
    }
      
  },
  methods: {
  },
}
</script>
<style scoped>

</style>