var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.loading && _vm.rows.length
        ? _c("Table", {
            attrs: {
              tableId: "45fc16a5-081b-4cab-b832-04bfe1a86004",
              headers: _vm.headers,
              rows: _vm.rows,
              rowId: "name",
              hidePagination: "",
              mobileConfig: _vm.mobileConfig,
              rowsPerPage: _vm.rowPerPage,
              tableRowsPerPage: [],
              ignoreTablePageSize: ""
            }
          })
        : _vm.loading
        ? _c("GhostLoading", {
            attrs: {
              type: "table",
              config: { table: { rows: _vm.rowPerPage } }
            }
          })
        : _c("NoData", { attrs: { noIcon: "" } }),
      _c("BaseModal", {
        ref: "settingsModal",
        attrs: {
          modalTitle: "Geburtstage Vermittler (Konfiguration)",
          size: "sm"
        },
        on: {
          onConfirmButton: function($event) {
            return _vm.saveSettings()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c("ComboBox", {
                  attrs: {
                    label: "Mit der Struktur",
                    values: _vm.mitDerStrukturValues
                  },
                  model: {
                    value: _vm.form.mitDerStruktur,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "mitDerStruktur", $$v)
                    },
                    expression: "form.mitDerStruktur"
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }