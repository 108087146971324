var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "data_header_container" }, [
    _c("div", { staticClass: "data_header_wrapper" }, [
      _c(
        "div",
        { staticClass: "telefon_email" },
        [
          _vm._t("username", function() {
            return [
              _c("div", [_vm._v(_vm._s(_vm.maklerInfo.telefon))]),
              _vm.hasEmailAndTelefon
                ? _c("div", { staticClass: "email_telefon_separator" }, [
                    _vm._v("|")
                  ])
                : _vm._e(),
              _c("div", [_vm._v(_vm._s(_vm.emailMakler))])
            ]
          })
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "links_alltogether" },
        [
          _vm._t("right", function() {
            return [
              _vm.hasMainHomepage
                ? _c(
                    "a",
                    {
                      staticClass: "anmelden_link",
                      attrs: { href: _vm.maklerInfo.mainHomepage }
                    },
                    [_vm._v("HOMEPAGE")]
                  )
                : _vm._e(),
              _c(
                "a",
                {
                  staticClass: "anmelden_link",
                  attrs: { href: _vm.anmeldenUrl }
                },
                [_vm._v("ANMELDEN")]
              ),
              _c(
                "div",
                { staticClass: "editable_social" },
                [_c("edit-social-media-src")],
                1
              ),
              _c(
                "div",
                { staticClass: "social_links_alltogether" },
                _vm._l(_vm.socialMediaList, function(social) {
                  return _c(
                    "div",
                    { key: social.src, staticClass: "social_link" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "social_link_icon",
                          on: {
                            click: function($event) {
                              return _vm.gotoSite(social.src)
                            }
                          }
                        },
                        [
                          _c(social.icon, {
                            tag: "component",
                            attrs: { size: 18 }
                          })
                        ],
                        1
                      )
                    ]
                  )
                }),
                0
              )
            ]
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }