var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isCustomerAllowed
    ? _c("div", [
        _c("div", { staticClass: "box__container" }, [
          _c("div", { staticClass: "box__title" }, [
            _vm._v(" Noch keine Konten verknüpft ")
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("p", [
                _vm._v(
                  " Mit der Multibanking funktion können Sie Ihre Bankkonten und externen Depots hier an einem zentralen Ort verknüpfen. Ihr Salden und Depotpositionen werden dann automatisch der Vermögensbilanz hinzugefügt. Daraus erhalten Sie nicht nur ein realistisches Abbild Ihrer Vermögenssituation. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Durch unsere intelligenten Analysen erhalten Sie Auswertungen und Prognosen für die Zukunft: "
                )
              ]),
              _vm._m(0),
              _c("br"),
              _c(
                "p",
                [
                  _vm._v(
                    "Um Ihre Konten zu verknüpfen, gehen Sie bitte auf die "
                  ),
                  _c(
                    "router-link",
                    {
                      staticClass: "btn-link",
                      attrs: { to: { name: "kontoverwaltung" } }
                    },
                    [_vm._v("Kontoverwaltung")]
                  ),
                  _vm._v(".")
                ],
                1
              )
            ])
          ])
        ])
      ])
    : _c("div", [_vm._m(1)])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _vm._v(
          "Ihre Kontoumsätze werden automatisch kategorisiert. Dadurch sehen Sie wohin Ihr Geld fließt und können mit den Budgets Ihr Ausgabeverhalten managen."
        )
      ]),
      _c("li", [_vm._v("Regelmäßige Ausgaben wie Verträge werden erkannt.")]),
      _c("li", [
        _vm._v(
          "Durch die Prognose von potentiellen Ausgaben und Verträgen wissen Sie täglich wie viel Sie bis zum nächsten Geldeingang frei verfügbar haben."
        )
      ]),
      _c("li", [
        _vm._v(
          "Sie erhalten tägliche und wöchentliche Zusammenfassungen sowie wichtige Hinweise und Warnungen."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "box__container" }, [
      _c("div", { staticClass: "box__title" }, [
        _vm._v(" Achtung, Zugriff verweigert! ")
      ]),
      _vm._v(" Sie haben keinen Berechtigung um diese Daten zu sehen. ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }