import HTTP_REQ_TYPES from './types';

export default {
  [HTTP_REQ_TYPES.GETTERS.HTTP_REQUEST_URL](state){
    return Object.entries(state.httpRequestUrl).reduce((acc, [key, value]) => ({
      ...acc,
      [value.url]: {
        requestId: key,
        ...value,
      },
    }), {});
  },
  [HTTP_REQ_TYPES.GETTERS.HTTP_REQUEST_URL_LIST](state) {
    return Object.entries(state.httpRequestUrl).map(([key, value]) => ({
      requestId: key,
      ...value,
    }))
  },
  [HTTP_REQ_TYPES.GETTERS.HTTP_CANCELABLE_REQUEST_URL_LIST](state, getters) {
    return getters[HTTP_REQ_TYPES.GETTERS.HTTP_REQUEST_URL_LIST].filter(request => request.cancelableRequest)
  },

  [HTTP_REQ_TYPES.GETTERS.IS_TOKEN_EXPIRED](state) {
    return (token) => {
      return token && state.timeoutInvalidTokens?.[token]
    }
  }
}