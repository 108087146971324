var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: "Menüstruktur Einstellungen" }
      }),
      _vm._l(_vm.contexts, function(context, index) {
        return _c(
          "div",
          {
            key: index,
            ref: context.id,
            refInFor: true,
            staticClass: "box__container"
          },
          [
            _vm.loading
              ? _c("GhostLoading", {
                  attrs: { type: "table", title: context.title }
                })
              : _c("Table", {
                  attrs: {
                    title: context.title,
                    headerActions: _vm.headerActionsTable,
                    headers: _vm.headers,
                    rows: _vm.rowsByContext[context.id],
                    hidePagination: ""
                  },
                  on: {
                    "headerAction-ADD": function($event) {
                      return _vm.add(context.id)
                    },
                    "action-DELETE": function($event) {
                      return _vm.remove($event)
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "menuStructure",
                        fn: function(row) {
                          return [
                            _c("ComboBox", {
                              attrs: {
                                value: row.menuStructure,
                                values:
                                  _vm.menuStructureValuesByContext[context.id]
                              },
                              on: {
                                change: function($event) {
                                  return _vm.onChange(
                                    row,
                                    "menuStructure",
                                    $event
                                  )
                                }
                              }
                            })
                          ]
                        }
                      },
                      {
                        key: "substructureMenuStructure",
                        fn: function(row) {
                          return [
                            _c("ComboBox", {
                              attrs: {
                                value: row.substructureMenuStructure,
                                values:
                                  _vm.menuStructureValuesByContext[context.id]
                              },
                              on: {
                                change: function($event) {
                                  return _vm.onChange(
                                    row,
                                    "substructureMenuStructure",
                                    $event
                                  )
                                }
                              }
                            })
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                })
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }