<template>
  <BaseButton v-if="haveTapi" isClear @click="showTapiModal">
    <ph-Vibrate :size="size" class="clickable"  />
  </BaseButton>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import { mapGetters } from 'vuex';
import { PhVibrate } from 'phosphor-vue'
import TAPI from '@/store/tapi/types';
import BaseButton from '@/components/core/BaseButton.vue';

export default {
  props: {
    size: {
      default: 18
    }
  },
  computed: {
    ...mapGetters({
      userid: CORE_TYPES.GETTERS.GET_USER_ID,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      CAN_TAPI: TAPI.GETTERS.CAN_TAPI,
      CAN_TAPI_BUTTON:TAPI.GETTERS.CAN_TAPI_BUTTON,
      modal_open :TAPI.GETTERS.MODAL_PANEL_OPEN
    }),
    haveTapi() {
  
        return this.CAN_TAPI_BUTTON||this.CAN_TAPI;
    },
  },
  methods: {
    showTapiModal() {
     // alert(this.hasRoles(ROLES.CAN_TAPI_HOST));
     //alert(this.ta);
       //dispatch(TAPI.ACTIONS.MODAL_PANEL_OPEN,true);
        this.$store.commit(TAPI.MUTATIONS.MODAL_PANEL_OPEN,!this.modal_open); 
       //TAPI.
    },
  },
  components: {
    PhVibrate,
    BaseButton,
  }
}
</script>

<style scoped>
.flex-content {
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.flex-content > div {
  cursor: pointer;
}
.logout__container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.uk-link {
  color: var(--color-primary-text);
  
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  font-size: 1.1rem;
  font-weight: 500;
}
.uk-link:hover {
  color: var(--color-top-nav-text);
}
.anmelden_link {
  color: var(--color-top-nav-text);
  text-decoration: none;
  padding: 9px 20px;
  outline: var(--color-top-nav-background) solid 1px;
}
.logout__container {
  display: none;
}
@media only screen and (max-width: 640px) {
  .anmelden_link {
    display: none;
  }
  .logout__container {
    display: flex;
  }
}
</style>