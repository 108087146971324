import FREISTELLUNGSANTRAG_TYPES from './types';
import { getInitialState } from './index'

export default {
  [FREISTELLUNGSANTRAG_TYPES.MUTATIONS.RETRIEVE_FREISTELLUNGSANTRAG_SUCCESS](state, payload) {
    state.freistellungsantragData = payload;
  }, 
  [FREISTELLUNGSANTRAG_TYPES.MUTATIONS.RESET_STATE](state, payload) {
    Object.assign(state, getInitialState())
  }, 
  [FREISTELLUNGSANTRAG_TYPES.MUTATIONS.REMOVE_ROW_SUCCESS](state, payload) {
    state.lastRemovedRow = payload;
  },
  [FREISTELLUNGSANTRAG_TYPES.MUTATIONS.SELECTED_ITEM](state, payload) {
    state.selectedItem = payload;
  },
  [FREISTELLUNGSANTRAG_TYPES.MUTATIONS.PERSIST_ROW_SUCCESS](state, payload) {
    state.persistedData = payload;
  },
  [FREISTELLUNGSANTRAG_TYPES.MUTATIONS.RESET_LIST](state, payload) {
    state.freistellungsantragData.rows = [];
  }, 
  [FREISTELLUNGSANTRAG_TYPES.MUTATIONS.REMOVE_ROW_SUCCESS](state, payload) {
    state.lastRemovedRow = payload;
  },
  [FREISTELLUNGSANTRAG_TYPES.MUTATIONS.FREISTELLUNGSANTRAG_GESELLSCHAFTEN](state, payload) {
    state.gesellschaften = payload;
  },  
  
  
}