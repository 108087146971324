export const MUTATION_PREFIX = 'CMS: ';
export const ACTIONS_PREFIX = 'CMS_ACTIONS_';
export const GETTERS_PREFIX = 'CMS_GETTERS_';

export default {
  MUTATIONS: {
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    SET_CMS_CONTENT: MUTATION_PREFIX + 'SET_CMS_CONTENT',
    SET_CMS_TEMPLATE: MUTATION_PREFIX + 'SET_CMS_TEMPLATE',
    SET_CMS_COMPONENT_CURRENT_CONTENT: MUTATION_PREFIX + 'SET_CMS_COMPONENT_CURRENT_CONTENT',
    SET_CMS_COMPONENT_CONTENT_UPDATED: MUTATION_PREFIX + 'SET_CMS_COMPONENT_CONTENT_UPDATED',
    SET_CMS_UPDATE_COMPONENT_CONTENT: MUTATION_PREFIX + 'SET_CMS_UPDATE_COMPONENT_CONTENT',
    SET_USER_ID: MUTATION_PREFIX + 'SET_USER_ID',
    SET_SELECTED_FOND: MUTATION_PREFIX + 'SET_SELECTED_FOND',
    SET_SELECTED_TEMPLATE: MUTATION_PREFIX + 'SET_SELECTED_TEMPLATE',
    
    

    SET_EXISTING_INSURANCES: MUTATION_PREFIX + 'SET_EXISTING_INSURANCES',
    SET_TABLE_INSURANCES: MUTATION_PREFIX + 'SET_TABLE_INSURANCES',
    SET_RECORDS_READY: MUTATION_PREFIX + 'SET_RECORDS_READY',
    SET_TARIFF_DETAILS: MUTATION_PREFIX + 'SET_TARIFF_DETAILS',
    SET_TARIFF_DETAILS_READY: MUTATION_PREFIX + 'SET_TARIFF_DETAILS_READY',
    SET_CMS_NEWS: MUTATION_PREFIX + 'SET_CMS_NEWS',
    SET_PRIVACY_LINK: MUTATION_PREFIX + 'SET_PRIVACY_LINK',

    SET_FONDSSHOP_PARAMS: MUTATION_PREFIX + 'SET_FONDSSHOP_PARAMS',
  },

  ACTIONS: {
    RETRIEVE_CMS_CONTENT: ACTIONS_PREFIX + 'RETRIEVE_CMS_CONTENT',
    RETRIEVE_UNLOGGED_CMS_CONTENT: ACTIONS_PREFIX + 'RETRIEVE_UNLOGGED_CMS_CONTENT',
    POST_CMS_COMPONENT_CONTENT: ACTIONS_PREFIX + 'POST_CMS_COMPONENT_CONTENT',
    SEND_KONTAKT: ACTIONS_PREFIX + 'SEND_KONTAKT',
    RETRIEVE_CONTENT_FROM_URL: ACTIONS_PREFIX + 'RETRIEVE_CONTENT_FROM_URL',
    ADD_EDITABLE_GROUP: ACTIONS_PREFIX + 'ADD_EDITABLE_GROUP',
    REMOVE_EDITABLE_GROUP: ACTIONS_PREFIX + 'REMOVE_EDITABLE_GROUP',
    RETRIEVE_PORTFOLIOS: ACTIONS_PREFIX + 'RETRIEVE_PORTFOLIOS',
    UPDATE_SOCIAL_MEDIA_SRC: ACTIONS_PREFIX + 'UPDATE_SOCIAL_MEDIA_SRC',
    UPDATE_RSS_SRC: ACTIONS_PREFIX + 'UPDATE_RSS_SRC',
    DELETE_RSS_SRC: ACTIONS_PREFIX + 'DELETE_RSS_SRC',
    SAVE_TEMPLATE_SELECTION: ACTIONS_PREFIX + 'SAVE_TEMPLATE_SELECTION',
    RETRIEVE_CMS_TEMPLATE: ACTIONS_PREFIX + 'RETRIEVE_CMS_TEMPLATE',
    
    
   
    EXISTING_INSURANCES: ACTIONS_PREFIX + 'EXISTING_INSURANCES',
    RETRIEVE_INSURANCES_COMPARED_LIST: ACTIONS_PREFIX + 'RETRIEVE_INSURANCES_COMPARED_LIST',
    RETRIEVE_TARIFF_DETAILS: ACTIONS_PREFIX + 'RETRIEVE_TARIFF_DETAILS',
    RETRIEVE_CMS_NEWS: ACTIONS_PREFIX + 'RETRIEVE_CMS_NEWS',
    RETRIEVE_PRIVACY_LINK: ACTIONS_PREFIX + 'RETRIEVE_PRIVACY_LINK',
    RETRIEVE_PRIVACY_LINK: ACTIONS_PREFIX + 'RETRIEVE_PRIVACY_LINK',
    
  },

  GETTERS: {
    GET_CMS_FIRST_PAGE_ROUTE: GETTERS_PREFIX + 'GET_CMS_FIRST_PAGE_ROUTE',
    GET_CMS_TEMPLATE: GETTERS_PREFIX + 'GET_CMS_TEMPLATE',
    GET_CMS_COMPONENTS: GETTERS_PREFIX + 'GET_CMS_COMPONENTS',
    GET_CMS_COMPONENT_CURRENT_CONTENT: GETTERS_PREFIX + 'GET_CMS_COMPONENT_CURRENT_CONTENT',
    GET_CMS_COMPONENT_CONTENT_UPDATED: GETTERS_PREFIX + 'GET_CMS_COMPONENT_CONTENT_UPDATED',
    GET_USER_ID: GETTERS_PREFIX + 'GET_USER_ID',
    GET_COMPONENT_GROUPS: GETTERS_PREFIX + 'GET_COMPONENT_GROUPS',
    GET_SELECTED_FOND: GETTERS_PREFIX + 'GET_SELECTED_FOND',
    GET_PORTFOLIOS: GETTERS_PREFIX + 'GET_PORTFOLIOS',
    GET_CMS_COMPONENTS_READY: GETTERS_PREFIX + 'GET_CMS_COMPONENTS_READY',

    GET_EXISTING_INSURANCES: GETTERS_PREFIX + 'GET_EXISTING_INSURANCES',
    GET_TABLE_INSURANCES: GETTERS_PREFIX + 'GET_TABLE_INSURANCES',
    GET_RECORDS_READY: GETTERS_PREFIX + 'GET_RECORDS_READY',
    GET_TARIFF_DETAILS: GETTERS_PREFIX + 'GET_TARIFF_DETAILS',
    GET_TARIFF_DETAILS_READY: GETTERS_PREFIX + 'GET_TARIFF_DETAILS_READY',
    GET_CMS_NEWS: GETTERS_PREFIX + 'GET_CMS_NEWS',
    GET_PRIVACY_LINK: GETTERS_PREFIX + 'GET_PRIVACY_LINK',

    GET_FONDSSHOP_PARAMS: GETTERS_PREFIX + 'GET_FONDSSHOP_PARAMS',
    
  }
}