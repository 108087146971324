<template>
  <div>
    <ExterneZugangAskOptions 
      v-if="askOptions"
      :askOptions="askOptions"
      :confirmButtonLabel="actionLabel"
      :data="data"
      :title="externeZugang.companyLabel"
      @close="resetExterneZugang(); navigateToVersicherungstoolsHome();"
      @onConfirmButton="askOptionsConfirmed($event); navigateToVersicherungstoolsHome();"/>
  </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import vergleichsrechnerMixin from '@/components/externeZugang/vergleichsrechner-mixin';

export default {
  mixins: [vergleichsrechnerMixin],
  computed: {
    path() {
      return this.$route.query?.path;
    },
  },
  methods: {
    navigateToVersicherungstoolsHome() {
      this.$router.push('/beratung/vergleichsrechner');
    },
    setAskOptions() {
      const { externeZugangList, path, } = this;
      if(!externeZugangList?.length || !path) return;

      const externeZugang = this.externeZugangList.find(externeZugang => externeZugang?.path === path);
      if (externeZugang.askOptions) {
        this.externeZugang = { ...externeZugang }
        this.askOptions = [...externeZugang.askOptions]
        this.actionLabel = externeZugang?.actionLabel;
      }
      if (externeZugang?.data) {
        this.data = externeZugang.data;
      }
    },
  },
  mounted() {
    this.setAskOptions();
  },
  components: {
    OptionMenu,
  },
}
</script>
