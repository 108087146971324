<template>
  <div>
    <ComboBox label="Anrede" 
      :values="customerDataConfig.genders" 
      v-model="personalDataAddress.title" 
      @change="dataChanged()"/>
    <InputField label="Firmenname" 
      v-model="personalDataAddress.lastName" 
      validateUntouched
      @ref="focusOnField($event)"
      @input="dataChanged()"/>
  </div>
</template>

<script>
import validator from '@/mixins/validator'
import { required } from '@/mixins/validator/rules'
import stammdatenMixin from './stammdaten-mixin'

import ComboBox from '@/components/core/forms/ComboBox.vue'
import InputField from '@/components/core/forms/InputField.vue'

import { LAST_NAME_REQUIRED_TEXT }  from '@/views/customer/customer-utils'


export default {
  mixins: [validator, stammdatenMixin],
  data() {
    return {
      personalDataAddress: {},
    };
  },
  computed: {
    // go to stammdaten-mixin.js. Shared computed attributes for Privat.vue and Firma.vue will be there
  },
  methods: {
    prepareData() {
      this.personalDataAddress = {
        title: this.customerDataEdited?.personalDataAddress?.title || '',
        lastName: this.customerDataEdited?.personalDataAddress?.lastName || '',
      };
      this.dataChanged();
    },
    // go to stammdaten-mixin.js. Shared methods for Privat.vue and Firma.vue will be there
  },
  validators: {
    personalDataAddress: {
      lastName: [required(LAST_NAME_REQUIRED_TEXT)]
    }
  },
  mounted() {
    this.prepareData();
  },
  components: {
    ComboBox,
    InputField,
  }
}
</script>
