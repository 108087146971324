var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ComboBox", {
        attrs: { label: "Anrede", values: _vm.customerDataConfig.genders },
        on: {
          change: function($event) {
            return _vm.dataChanged()
          }
        },
        model: {
          value: _vm.personalDataAddress.title,
          callback: function($$v) {
            _vm.$set(_vm.personalDataAddress, "title", $$v)
          },
          expression: "personalDataAddress.title"
        }
      }),
      _c("InputField", {
        attrs: { label: "Firmenname", validateUntouched: "" },
        on: {
          ref: function($event) {
            return _vm.focusOnField($event)
          },
          input: function($event) {
            return _vm.dataChanged()
          }
        },
        model: {
          value: _vm.personalDataAddress.lastName,
          callback: function($$v) {
            _vm.$set(_vm.personalDataAddress, "lastName", $$v)
          },
          expression: "personalDataAddress.lastName"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }