<template>
  <div @click="addExpresion('/')" class="box norm_button divider">/</div>
</template>

<script>
import functionMixins from "../../functionMixins.js";
export default {
  name: "Divider",
  mixins: [functionMixins]
};
</script>

<style scoped>
.divider {
  grid-area: divider;
}
</style>