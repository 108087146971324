<template>
  <div class="makler_info">
    <h3 style="font-size: 1.85em">Kontakt</h3>
        <div class="fc-alert fc-alert-warning" v-if="isIntern">Hier kommen die Kontaktdaten des Maklers.</div>
      <div v-else>
        <div class="makler_image" v-if="getBrokerImage">
          <img alt="Makler Bild" v-bind:src="getBrokerImage" id="image" class="broker_image"/>
        </div>
        <div>
          <div>
            <b style="font-size: 1.14em">{{maklerInfo.officialName}}</b>
          </div>
          <div class="two_items_row">
            <div>
              <ph-map-pin :size="16" />
            </div>
            <div>{{maklerInfo.strasse}}</div>
          </div>
          <div class="two_items_row_plz">
            <div></div>
            <div>{{maklerInfo.plzOrt}}</div>
          </div>
          <div class="two_items_row">
            <div>
              <ph-phone :size="16" />
            </div>
            <div>{{maklerInfo.telefon}}</div>
          </div>
        </div>
      </div>
      <hr class="separator" />
      <div class="two_items_row">
        <div>
          <ph-envelope-simple :size="16" />
        </div>
        <router-link to="kontakt">Kontakt</router-link>
      </div>

      <div class="two_items_row">
        <div>
          <ph-power :size="16" />
        </div>
        <div class="home_login_link">
          <a :href="anmeldenUrl">Anmelden</a>
        </div>
      </div>
    </div>
</template>
<script>
import CORE_TYPES from "@/store/core/types";
import LOG_TYPES from '@/store/log/types';
import { mapGetters } from "vuex";
import { PhMapPin, PhPhone, PhEnvelopeSimple, PhPower } from 'phosphor-vue';

export default {
  components:{
    PhMapPin,
    PhPhone,
    PhEnvelopeSimple,
    PhPower,
  },
  data(){
    return {
      noError:true,
    };
  },
  computed: {
    ...mapGetters({
      maklerInfo: CORE_TYPES.GETTERS.GET_BROKER_INFORMATION,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      anmeldenUrl: CORE_TYPES.GETTERS.GET_ANMELDEN_URL,
    }),
    getBrokerImage() {
      //return this.maklerInfo.maklerImageUrl !== "without";

      /* 
        Makler Image is a request to restful/cms/getBrokerImage/maklernr
        So we test against maklerImageUrl String. If its empty we do not show an image => not <img>
        Else we request with prefix
      */
      if (this.maklerInfo.maklerImageUrl) {
        const brokerImageURL = window.location.origin +  process.env.VUE_APP_API +  "/cms/getBrokerImage/" + this.maklerInfo.maklernr
        this.$store.dispatch(LOG_TYPES.ACTIONS.INFO, "MaklerBild: " + brokerImageURL);
        return brokerImageURL;
      }
      else {
        this.$store.dispatch(LOG_TYPES.ACTIONS.INFO, "MaklerBild: kein Logo gefunden");
        return null;
      }
    }
  
  },
  methods:{
    hasError(){
      this.noError = false;
    },
  },
};
</script>
<style  scoped>
.broker_image {
  min-width: 100%;
}

.separator {
  border-top: 1px solid var(--color-primary);
}

.makler_info {
  text-align: start;
  background: var(--color-box);
  box-shadow: 0 4px 16px rgb(85 85 85 / 10%);
  padding: 10px;
}
.two_items_row {
  display: grid;
  grid-template-columns: 25px auto;
  align-items: center;
  gap: 0px;
  margin-top: 10px;
}
.two_items_row_plz {
  display: grid;
  grid-template-columns: 25px auto;
  align-items: center;
  gap: 0px;
}
.map_icon {
  align-self: top;
}
a {
  color: var(--color-workspaces-nav-active);
}
.makler_image {
  padding-bottom: 1em;
  /* width: 14em; */
}
</style>