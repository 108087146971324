<template>
<div>
    <OptionMenu :id="$appNavigation.parentOptionMenuId" :defaultMenu="$appNavigation.parentOptionMenu" />

    <PageHeaderTitleNavigation :id="$appNavigation.currentOptionMenuId" title="Datensatz zuordnen" />

    <div class="masonry__container" v-if="!loading">
        <div class="item">
            <div class="card-header">
                <div class="card-title">
                    Kundendaten
                </div>
            </div> 

            <div class="card-row"  v-if="fileName">
                <div class="card-row-label">Dateiname</div>
                <div class="card-row-value">{{fileName}}</div>
            </div>
            
            <div class="card-row"  v-if="foreignName">
                <div class="card-row-label">Kundenname</div>
                <div class="card-row-value">{{foreignName}}</div>
            </div>
            
            <div class="card-row"  v-if="foreignVorname">
                <div class="card-row-label">Kundenvorname</div>
                <div class="card-row-value">{{foreignVorname}}</div>
            </div>
            
            <div class="card-row"  v-if="foreignBirthName">
                <div class="card-row-label">Kundengeburtsname</div>
                <div class="card-row-value">{{foreignBirthName}}</div>
            </div>
            
            <div class="card-row"  v-if="foreignAddress">
                <div class="card-row-label">Kundenanschrift</div>
                <div class="card-row-value">{{foreignAddress}}</div>
            </div>  
            
            <div class="card-row"  v-if="foreignBirthDate">
                <div class="card-row-label">Kundengeburtsdatum</div>
                <div class="card-row-value">{{foreignBirthDate}}</div>
            </div>             

        </div>

        <div class="item">
            <div class="card-header">
                <div class="card-title">
                    Vertragsdaten
                </div>
            </div>
            
            <div class="card-row"  v-if="cause">
                <div class="card-row-label">Fehlerursache</div>
                <div class="card-row-value">{{cause}}</div>
            </div>
            
            <div class="card-row"  v-if="customerGesellId">
                <div class="card-row-label">KundenGesellNr</div>
                <div class="card-row-value">{{customerGesellId}}</div>
            </div>

            <div class="card-row"  v-if="foreignPersonId">
                <div class="card-row-label">FremdPersonNr.</div>
                <div class="card-row-value">{{foreignPersonId}}</div>
            </div>            
            
            <div class="card-row"  v-if="damageNumber">
                <div class="card-row-label">Schaden-Nr.</div>
                <div class="card-row-value">{{damageNumber}}</div>
            </div>
            
            <div class="card-row"  v-if="vtgSparte">
                <div class="card-row-label">VTG-Sparte</div>
                <div class="card-row-value">{{vtgSparte}}</div>
            </div>
            
            <div class="card-row"  v-if="insuranceId">
                <div class="card-row-label">VSNR</div>
                <div class="card-row-value">{{insuranceId}}</div>
            </div>
            
            <div class="card-row"  v-if="sparteNr">
                <div class="card-row-label">Sparten-Nr.</div>
                <div class="card-row-value">{{sparteNr}}</div>
            </div>  

            <div class="card-row"  v-if="sparteNr">
                <div class="card-row-label">Sparten-Nr.</div>
                <div class="card-row-value">{{sparteNr}}</div>
            </div>
            
            <div class="card-row">
                <div class="card-row-label">Interne Nummer</div>
                <div class="card-row-value">
                    <InputField v-model="mscInternalId" class="input-field" />
                    <BaseButton @click="searchInsurances" isSecondary class="base-button">Versicherungssuche</BaseButton>
                </div>
            </div>

            <div class="card-row">
                <div class="card-row-label">Kundenname</div>
                <div class="card-row-value">
                    <InputField v-model="customerNameSearch" class="input-field" />
                    <BaseButton @click="searchCustomer" isSecondary class="base-button">Suchen</BaseButton>
                </div>
            </div>            

            <div class="card-row"  v-if="sparteNr">
                <div class="card-row-label">Sparten-Nr.</div>
                <div class="card-row-value">{{sparteNr}}</div>
            </div> 
            
            <InputToggleSwitch
                v-model="transferInsuranceNumber"
                :label="formatName + ' Versicherungsnummer übernehmen'"
                inLineLabel
            />            

            <BaseButton @click="buttonKundengesellZuordnen" isPrimary :disabled="assignMahnungAllowed || !mscInternalId">
                {{formatName}} KundenGesellNr. zuordnen
            </BaseButton>

            <BaseButton @click="buttonNewCustomer" isPrimary>
                Neukunden anlegen
            </BaseButton>

        </div>
    </div>  
    
    <div class="box__container" v-if="loading">
        <GhostLoading type="table" />
    </div>    

    <div class="box__container" v-if="insuranceRows.length || customerRows.length " >
        <BigTable  v-if="insuranceRows.length"
            title="SmartMSC Vorschläge (Versicherungen)"
            :headers="insuranceHeaders"
            :rows="insuranceRows"
            :rowsPerPage="10"
            @click-icon-datensatzZuordnen="kundengesellZuordnen(undefined, row.id)"
            @click-kundennr="openCustomerNewTab"
            @action-CONTRACT="actionContract"
            @action-UNITE="actionUnite"
        >
            <template v-slot:street="row">  
                <div :class="{'green': row.street.green}">
                    {{row.street.text}}
                </div>
            </template>
            <template v-slot:plz="row">  
                <div :class="{'green': row.plz.green}">
                    {{row.plz.text}}
                </div>
            </template>
            <template v-slot:ort="row">  
                <div :class="{'green': row.ort.green}">
                    {{row.ort.text}}
                </div>
            </template>
            <template v-slot:birthdate="row">  
                <div :class="{'green': row.birthdate.green}">
                    {{row.birthdate.text}}
                </div>
            </template>
        </BigTable>
        <BigTable  v-if="customerRows.length"
            title="SmartMSC Vorschläge (Kunden)"
            :headers="customerHeaders"
            :rows="customerRows"
            :rowsPerPage="10"
            rowId="kundennr"
            @click-kundennr="openCustomerNewTab"
            @click-icon-datensatzZuordnen="kundengesellZuordnen(row.kundennr)"
            @action-CONTRACT="openCustomerNewInsurance"
        >
            <template v-slot:name="row">  
                <div :class="{'green': row.name.green}">
                    {{row.name.text}}
                </div>
            </template>
            <template v-slot:vorname="row">  
                <div :class="{'green': row.vorname.green}">
                    {{row.vorname.text}}
                </div>
            </template>
            <template v-slot:street="row">  
                <div :class="{'green': row.street.green}">
                    {{row.street.text}}
                </div>
            </template>
            <template v-slot:plz="row">  
                <div :class="{'green': row.plz.green}">
                    {{row.plz.text}}
                </div>
            </template>
            <template v-slot:ort="row">  
                <div :class="{'green': row.ort.green}">
                    {{row.ort.text}}
                </div>
            </template>
            <template v-slot:birthdate="row">  
                <div :class="{'green': row.birthdate.green}">
                    {{row.birthdate.text}}
                </div>
            </template>
        </BigTable>
    </div>
    <UniteContracts v-if="uniteInsurance" :insurance="uniteInsurance" @close="uniteInsurance=null"/>
</div>
</template>

<script>
import CORE_TYPES from '@/store/core/types'
import LOG_TYPES from '@/store/log/types';

import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import Table from "@/components/table2/Table.vue";
import BaseButton from '@/components/core/BaseButton.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import InputField from '@/components/core/forms/InputField.vue';
import UniteContracts from '@/components/bipro/UniteContracts.vue';
import { buildMessage } from "@/helpers/log-message-helper";

import { mapGetters } from 'vuex'
import axios from 'axios';
import {PhPencilLine, PhArrowFatRight, PhFilePlus} from 'phosphor-vue';

import BigTable, { CURRENCY_COLUMN, DATETIME_COLUMN, LINK_COLUMN  } from '@/components/bigTable/BigTable.vue';
import {STRING_COLUMN, ACTION_COLUMN, FIXED_LEFT, NUMBER_COLUMN, SLOT_COLUMN, DATE_COLUMN, ICON_COLUMN, Icon} from '@/components/bigTable/BigTable.vue';
import {SimpleAction, ConfirmedAction, DownloadLinkAction, SlotColumn} from "@/components/table2/table_util.js";

import GhostLoading from '@/components/core/loading/GhostLoading.vue';

const config = {
    defaultSpinner: true,
};

export default {
    components: {
        OptionMenu,
        PageHeaderTitleNavigation,
        Table,
        BaseButton,
        InputToggleSwitch,
        InputField,
        UniteContracts,
        PhArrowFatRight,
        BigTable,
        GhostLoading,
    },
    data() {
        return {
            customerHeaders: [
                { label: "Zuordnen", key: "datensatzZuordnen", width: 40, type: ICON_COLUMN, position: FIXED_LEFT, hideLabel: true },
                { label: "Kundenname", key: "name", width: 100, type: SLOT_COLUMN, position: FIXED_LEFT },
                { label: "Kundenvorname", key: "vorname", width: 250, type: SLOT_COLUMN, visible: true },
                { label: "Kundennr", key: "kundennr", width: 250, type: STRING_COLUMN, visible: true },
                { label: "Straße u. Hausnr.", key: "street", width: 250, type: SLOT_COLUMN, visible: true },
                { label: "PLZ", key: "plz", width: 250, type: SLOT_COLUMN, visible: true },
                { label: "Ort", key: "ort", width: 250, type: NUMBER_COLUMN, visible: true },
                { label: "Geburtsdatum", key: "birthdate", width: 150, type: SLOT_COLUMN, visible: true },
                { label: "Maklernr", key: "maklernr", width: 150, type: DATETIME_COLUMN, visible: true },
                { label: "Maklername", key: "makler", width: 250, type: STRING_COLUMN, visible: true },
                { label: "Kunden GesellNr.", key: "gesellnr", width: 250, type: STRING_COLUMN, visible: true },
                { label: "Aktionen", key: "actions", width: 120, type: ACTION_COLUMN, visible: true },
            ],
            insuranceHeaders: [
                { label: "Interne Nr", key: "id", width: 100, type: STRING_COLUMN, position: FIXED_LEFT },
                { label: "Zuordnen", key: "datensatzZuordnen", width: 50, type: ICON_COLUMN, position: FIXED_LEFT },
                { label: "Gesellschaft", key: "gesellschaft", width: 100, type: STRING_COLUMN, visible: true},
                { label: "Vers. Nr.", key: "insuranceIdValue", width: 250, type: STRING_COLUMN, visible: true, backgroundColor:  'var(--color-success)', colorIf: (row) => row.insuranceId.green },
                { label: "Prod. Bezeichnun", key: "description", width: 250, type: STRING_COLUMN, visible: true },
                { label: "Sparte", key: "sparte", width: 250, type: STRING_COLUMN, visible: true },
                { label: "Beginn", key: "begin", width: 250, type: DATE_COLUMN, visible: true },
                { label: "Beitrag", key: "beitrag", width: 250, type: CURRENCY_COLUMN, visible: true },
                { label: "Status", key: "status", width: 250, type: STRING_COLUMN, visible: true },
                { label: "Kundenname", key: "nameValue", width: 250, type: STRING_COLUMN, visible: true, backgroundColor:  'var(--color-success)', colorIf: (row) => row.name.green},
                { label: "Kundenvorname", key: "vornameValue", width: 250, type: STRING_COLUMN, visible: true, backgroundColor:  'var(--color-success)', colorIf: (row) => row.vorname.green },
                { label: "Kundennr", key: "kundennr", width: 250, type: LINK_COLUMN, visible: true },
                { label: "Straße u. Hausnr.", key: "street", width: 250, type: SLOT_COLUMN, visible: true },
                { label: "PLZ", key: "plz", width: 250, type: SLOT_COLUMN, visible: true },
                { label: "Ort", key: "ort", width: 250, type: SLOT_COLUMN, visible: true },
                { label: "Geburtsdatum", key: "birthdate", width: 250, type: SLOT_COLUMN, visible: true },
                { label: "Maklernr", key: "maklernr", width: 250, type: STRING_COLUMN, visible: true },
                { label: "Maklername", key: "makler", width: 250, type: STRING_COLUMN, visible: true },
                { label: "Weitere Personen/Gegenstand", key: "weitere", width: 250, type: STRING_COLUMN, visible: true },
                { label: "Kunden GesellNr.", key: "gesellnr", width: 250, type: STRING_COLUMN, visible: true },
                { label: this.isFA ? "OMDS aktualisiert" : "GDV aktualisiert", key: "current", width: 250, type: DATE_COLUMN, visible: true },
                { label: "Aktionen", key: "actions", width: 120, type: ACTION_COLUMN, visible: true },                
            ],                
            insurances: null,
            customers: null,

            title: null,
            assignMahnungAllowed: false,

            fileName: null,
            foreignName: null,
            foreignVorname: null,
            foreignBirthName: null,
            foreignAddress: null,
            foreignStrasse: null,
            foreignOrt: null,
            foreignPLZ: null,
            foreignBirthDate: null,
            cause: null,
            damageNumber: null,
            customerGesellId: null,
            foreignPersonId: null,
            sparteNr: null,
            vtgSparte: null,
            insuranceId: null,

            transferInsuranceNumber: false,
            customerNameSearch: "",

            mscInternalId: "",
            uniteInsurance: null,
            insuranceRows: [],
            customerRows: [],
            loading: false,
        }
    },
    computed: {
        ...mapGetters({
            isFA: CORE_TYPES.GETTERS.IS_FA,
        }),
		formatName() {
			return this.isFA ? "OMDS" : "GDV";
		},
    },
    mounted() {
        if (typeof(this.$route.query.insurance) == "object")
            this.mscInternalId = this.$route.query.insurance.id || "";
        
        this.loadData(null);
    },
    methods: {
        loadData(name) {
            this.loading = true;

            let nameParam = "";
            if (name !== null)
                nameParam = `&name=${name}`;
            axios.get(`${process.env.VUE_APP_API}/bipro_import/datensatz_zuordnen?id=${this.$route.query.id}` + nameParam)
            .then(response => {
                this.title = response.data.title;
                this.assignMahnungAllowed = response.data.assignMahnungAllowed;

                // table rows
                this.insurances = response.data.insurances;
                this.customers = response.data.customers;

                this.insuranceRows = (this.insurances || []).map(row => {
                    const actions = [
                        SimpleAction("CONTRACT", PhPencilLine, "Versicherungsvertrag öffnen"),
                        SimpleAction("UNITE", PhFilePlus, "Diesen Vertrag vereinigen"),
                    ];
                    return {
                        ...row,
                        datensatzZuordnen: Icon("PhArrowBendUpRight", "GDV KundenGesellnr. zuordnen"), 
                        insuranceIdValue: row?.insuranceId?.text,
                        nameValue: row?.name?.text,
                        vornameValue: row?.vorname?.text,                        
                        actions,
                    }
                });                


                this.customerRows =  (this.customers || []).map(row => {
                    const actions = [
                        SimpleAction("CONTRACT", PhFilePlus, "Neuen Vers. Vertrag anlegen"),
                    ];
                    return {
                        ...row,
                        datensatzZuordnen: Icon("PhArrowBendUpRight", "GDV KundenGesellnr. zuordnen"), 
                        nameValue: row?.name?.text,
                        vornameValue: row?.vorname?.text,
                        actions,
                    }
                });

                // information displayed above the tables
                this.fileName = response.data.fileName;
                this.foreignName = response.data.foreignName;
                this.foreignVorname = response.data.foreignVorname;
                this.foreignBirthName = response.data.foreignBirthName;
                this.foreignAddress = response.data.foreignAddress;
                this.foreignStrasse = '';
                this.foreignOrt = '';
                this.foreignPLZ = '';
                const adr = this.foreignAddress ? this.foreignAddress.split(', ') : [];
                if (adr.length === 3) {
                    this.foreignStrasse = adr[2];
                    this.foreignOrt = adr[1];
                    this.foreignPLZ = adr[0];
                }
                this.foreignBirthDate = response.data.foreignBirthDate;
                this.cause = response.data.cause;
                this.damageNumber = response.data.damageNumber;
                this.customerGesellId = response.data.customerGesellId;
                this.foreignPersonId = response.data.foreignPersonId;
                this.sparteNr = response.data.sparteNr;
                this.vtgSparte = response.data.vtgSparte;
                this.insuranceId = response.data.insuranceId;

                this.loading = false;
            });
        },
        actionContract(row) {
            this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, {
                customerId: row.kundennr,
                insurances: true,
                path: `/home/versicherungen/insurance-group/details/${row.id}`
            });
        },
        openCustomerNewInsurance(row) {
            let path = '/home/versicherungen/auswahl-vertragsart';
            
            if (this.insuranceId) {
                let query='?vesichnr=' + this.insuranceId;
                path += encodeURIComponent(query);
            }
                
            this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { customerId: row.kundennr, path })
        },        
        actionUnite(row) {
            this.uniteInsurance = row.id;
        },
        searchInsurances() {

            this.$router.push({
                path: "/home/versicherungsfilter/insurance-group",
            });
        },
        buttonKundengesellZuordnen() {
            this.kundengesellZuordnen();
        },
        kundengesellZuordnen(kundennr, mscInternalId) {
            const params = {
                docId: this.$route.query.id,
                mscInternalId: mscInternalId || this.mscInternalId,
                insuranceId: this.insuranceId,
                setPoliceNr: this.transferInsuranceNumber,
                kundennr,
            };
            axios.post(`${process.env.VUE_APP_API}/bipro_import/assign_gdv_kunde`, params, config)
            .then(response => {
                if (response?.data)
                    this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data, 'success'));
                this.$router.push({path: "/intern/gdv/import"});
            }).catch(error => {
                const fehlertext = error.response?.headers?.fehlertext;                
                if (fehlertext) {
                    this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(fehlertext, 'danger'));
                }
            })
        },
        buttonNewCustomer() {
            this.$addBreadcrumb({
              label: `zurück`,
              fullPath: this.$route.fullPath,
              breadcrumb: 'Datensatz zuordnen',
            });
            let query = '';
            if (this.foreignName) {
                query += '&lastName=' + this.foreignName;
            }
            if (this.foreignVorname) {
                query += '&firstName=' + this.foreignVorname;
            }
            if (this.foreignBirthDate) {
                query += '&dayOfBirth=' + this.foreignBirthDate;
            }
            if (this.foreignPLZ) {
                query += '&zip=' + this.foreignPLZ;
            }
            if (this.foreignOrt) {
                query += '&city=' + this.foreignOrt;
            }
            if (this.foreignStrasse) {
                query += '&street=' + this.foreignStrasse;
            }
            if (query) {
                query = '?' + query.substring(1);
            }
            this.$router.push({
                path: "/customer/maklersuche",
                query: {
                    bipro: true,
                    nextUrl: `customer/kunde-anlegen${query}`
                }
            });
        },
        searchCustomer() {
            this.loadData(this.customerNameSearch);
        },
        openCustomerNewTab(row) {
            this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { customerId: row.kundennr })
        },
    },
    beforeRouteLeave(to, from, next) {
        this.$addBreadcrumb({ 
            label: `zurück zu ${this.$route.meta?.breadcrumb?.label}`,
                fullPath: this.$route.fullPath,
                breadcrumb: "Datensatz zuordnen",            
         });



        next();
    },    
}
</script>


<style lang="scss" scoped>
.green {
    background-color: var(--color-success)
}

.masonry__container {
    -moz-column-gap: 8px;
    column-gap: 14px;
    row-gap: 14px;
    transition: border .2s,padding .2s;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 8px;
}

.box__shadow {
  box-shadow: 0px 4px 16px rgba(85, 85, 85, 0.1);
}

.item {
    margin-bottom: 8px;
    border-radius: 8px;
    background-color: var(--color-box);
    border: 1px solid var(--color-box);
    padding: 8px;
    @extend .box__shadow;
    max-width: 50%;
}

.card-row {
    display: flex;
    flex-wrap: nowrap;
    padding: 8px;
}

.card-row-label {
    min-width: 160px;
    
}

.card-row-value {
    margin-left: 8px;
    flex: 1 1 auto;
    display: flex;
    align-items: baseline;
    gap: 8px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    overflow: hidden;
}

.card-row-value .input-field {
    flex: 1 1 80%; /* InputField takes 80% of the available space */
}

.card-row-value .base-button {
    flex: 1 1 20%; /* BaseButton takes 20% of the available space */
}

.card-title {
    padding: 5px;
    margin-bottom: 5px;
    font-weight: bold;
    flex: 1 1 auto;
    display: flex;
}

.card-header {
    display: flex;
    justify-content: space-between;
    margin: 0 0 8px;

}

@media (max-width:1291px) {
  .item {
    max-width: 100%;
  }
}


</style>
