var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "recorded-items" },
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: "Gespeicherte Elemente bearbeiten" }
      }),
      _vm.tutorialRecordedItems && _vm.tutorialRecordedItems.length
        ? _c(
            "div",
            { staticClass: "recorded-items" },
            [
              _c("div", { staticClass: "box__container" }, [
                _c(
                  "div",
                  { staticClass: "col-12 col-md-6" },
                  [
                    _c("InputField", {
                      attrs: { label: "Titel" },
                      on: {
                        change: function($event) {
                          return _vm.updateTitle()
                        }
                      },
                      model: {
                        value: _vm.tutorialTitle,
                        callback: function($$v) {
                          _vm.tutorialTitle = $$v
                        },
                        expression: "tutorialTitle"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._l(_vm.tutorialRecordedItems, function(item) {
                return _c("div", { key: item.step }, [
                  _c(
                    "div",
                    { staticClass: "box__container list-item items-container" },
                    [
                      _c(
                        "p",
                        [
                          _c(
                            "BaseButton",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.confirmDeleteItem(item)
                                }
                              }
                            },
                            [_vm._v("Entfernen")]
                          )
                        ],
                        1
                      ),
                      _c("p", [
                        _c("span", [_vm._v("Routerpfad:")]),
                        _vm._v(" " + _vm._s(item.routerPath) + " ")
                      ]),
                      _c("InputTextArea", {
                        attrs: { label: "Meldung" },
                        on: {
                          change: function($event) {
                            return _vm.updateElement(item)
                          }
                        },
                        model: {
                          value: item.helpMessage,
                          callback: function($$v) {
                            _vm.$set(item, "helpMessage", $$v)
                          },
                          expression: "item.helpMessage"
                        }
                      }),
                      _vm._m(0, true),
                      _c("img", {
                        staticClass: "recorded-item-img",
                        attrs: { src: item.canvas }
                      })
                    ],
                    1
                  )
                ])
              })
            ],
            2
          )
        : _vm._e(),
      !_vm.tutorialRecordedItems || !_vm.tutorialRecordedItems.length
        ? _c("div", { staticClass: "recorded-items" }, [
            _vm._v(" No items recorded ")
          ])
        : _vm._e(),
      _c(
        "BaseModal",
        {
          ref: "deleteItemConfirmation",
          attrs: {
            modalTitle: "Schritt löschen",
            labelButtonConfirm: "Löschen"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.removeElement()
            }
          }
        },
        [_vm._v(" Soll das Element gelöscht werden? ")]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", [_vm._v("Vorschau:")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }