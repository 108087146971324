<template>
  <div>
    <BaseModal
      ref="mailContacts"
      modalTitle="Kontakte"
      size="lg"
      :showCancelButton="false"
      labelButtonConfirm="Auswahl übernehmen"
      @onConfirmButton="confirmContactsToSend">
      <InsuranceCompanyMailContacts :records="records" @selectedRows="selectedRows" />
    </BaseModal>


  </div>
</template>

<script>
import BaseModal from '@/components/core/BaseModal.vue';
import InsuranceCompanyMailContacts from './InsuranceCompanyMailContacts.vue'

export default {
    components: {
      BaseModal,
      InsuranceCompanyMailContacts,
    },
    props: {
      records: {
        type: Array,
        default: () => [],
      },    
    },
    data() {
      return {
        selectedRowsValues: [],
      }
    },
    computed: {
    },
    methods: {
      open() {
        this.$refs.mailContacts.open();
      },
      close() {
        this.$refs.statusModal.close();
      },
      onConfirmModal() {
      },
      onCancelModal() {
        this.$refs.statusModal.close();
      },
      selectedRows(rows) {
        this.selectedRowsValues = rows;
      },
      confirmContactsToSend() {
        this.$emit("select-contacts", this.selectedRowsValues);
        this.$refs.mailContacts.close();
      },      
    }
}
</script>
