var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container-content" },
    [
      [
        [
          _c("div", { staticClass: "d-md-block d-none" }, [
            _c(
              "div",
              { staticClass: "btn-group justify-content-center m-0" },
              _vm._l(_vm.fondsValues, function(fond, index) {
                return _c(
                  "BaseButton",
                  {
                    key: index,
                    staticClass: "btn-group-item clickable",
                    attrs: { isSecondary: fond.isin !== _vm.selectedIsin },
                    on: {
                      click: function($event) {
                        return _vm.selectFond(fond)
                      }
                    }
                  },
                  [_vm._v(_vm._s(fond.title))]
                )
              }),
              1
            ),
            _vm.showYearFilter
              ? _c(
                  "div",
                  { staticClass: "btn-group justify-content-center mt-3" },
                  _vm._l(_vm.yearsValues, function(year, index) {
                    return _c(
                      "BaseButton",
                      {
                        key: index,
                        staticClass: "btn-group-item clickable",
                        attrs: { isSecondary: year.value !== _vm.selectedYear },
                        on: {
                          click: function($event) {
                            return _vm.selectYear(year.value)
                          }
                        }
                      },
                      [_vm._v(_vm._s(year.label))]
                    )
                  }),
                  1
                )
              : _vm._e()
          ])
        ],
        [
          _c(
            "div",
            { staticClass: "d-md-none" },
            [
              _c("ComboBox", {
                attrs: { value: _vm.selectedIsin, values: _vm.fondsValues },
                on: {
                  change: function($event) {
                    return _vm.selectFondByISIN($event)
                  }
                }
              }),
              _vm.showYearFilter
                ? _c("ComboBox", {
                    attrs: { value: _vm.selectedYear, values: _vm.yearsValues },
                    on: {
                      change: function($event) {
                        return _vm.selectYear($event)
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        !_vm.loading && _vm.data
          ? _c(
              "div",
              {
                key: _vm.selectedIsin,
                staticClass: "marktubersicht-chart__content"
              },
              [
                _c("LineChart", {
                  attrs: {
                    chartData: _vm.eChartsData,
                    axisLabelFormatterShowOption: "full",
                    format: "integer",
                    showDataZoom: _vm.showBrushChart,
                    isTooltip: false,
                    height: "450px",
                    gridLeft: 40
                  }
                })
              ],
              1
            )
          : _vm.loading
          ? _c("GhostLoading", {
              attrs: { type: "block", config: { block: { height: 450 } } }
            })
          : _c("NoData", { attrs: { noIcon: "" } })
      ],
      !_vm.loading && _vm.data
        ? [
            _vm.showDetailLink
              ? _c("div", { staticClass: "text-right card-bottom" }, [
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.openMarktubersichtDetails()
                        }
                      }
                    },
                    [_vm._v("Details")]
                  )
                ])
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }