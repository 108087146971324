import store from '@/store';
import MENU_CONFIG_TYPES from '@/store/menuConfig/types';
import { PATH_ROLE_FORBIDDEN, resolvePathParams } from '@/router/guards';

export function isOptionMenuPermissionConfigVisible(configContext, to, from, next) {
  if (to.path === PATH_ROLE_FORBIDDEN) {
    next();
  } else {
    const relatedPaths = ([
      to.path, 
      to.fullPath, 
      ...to.matched.flatMap(r => [
        r.path, 
        resolvePathParams(r.path, to.params), 
        r.fullPath, 
        resolvePathParams(r.fullPath, to.params),
      ]),
    ]).filter(path => !!path);

    if(relatedPaths.every(path => store.getters[MENU_CONFIG_TYPES.GETTERS.IS_OPTIONS_MENU_PERMISSION_CONFIG_VISIBLE](configContext, path))) {
      next();
    } else {
      next(PATH_ROLE_FORBIDDEN);
    }
  }
}
