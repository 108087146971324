var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c(
        "div",
        { staticClass: "login-container" },
        [
          _c("HeaderLogin", { staticClass: "login-header" }),
          _c(
            "div",
            {
              staticClass: "login-content",
              style: { backgroundImage: _vm.loginContentBackground },
              attrs: { "data-app-content": "" }
            },
            [
              _c("div", { staticClass: "login-box" }, [
                _c("div", { staticClass: "login-logo-box" }, [
                  _c("img", { attrs: { src: _vm.logoLoginBoxBackground } })
                ]),
                _c("hr", { staticClass: "login-logo__bottom--spacer" }),
                _c("div", { staticClass: "login-logo__box-title" }, [
                  _vm._v(" " + _vm._s(_vm.loginWelcomeTitle) + " ")
                ]),
                _c(
                  "div",
                  [
                    _c("AnimatedSpinner"),
                    _vm._v(
                      " Bitte haben Sie einen Augenblick Geduld. Die Anwendung wird geladen! "
                    )
                  ],
                  1
                )
              ])
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }