<template>
    <div v-if="risiko">

      <Table
        v-if="rows.length"
        ref="table"
        :tableId="tableId"
        cardView
        :headers="headers"
        :rows="rows"
        rowId="isinWkn"
        hidePagination
      >

      </Table>
    </div>
  </template>
 <script>
 import FONDSINFO_TYPES from "@/store/fondsinfo/types";
 import BoxContainer from '@/components/core/BoxContainer.vue';
 import { mapGetters } from 'vuex';
 import Table from "@/components/table2/Table.vue";
 import {TextColumn, DateColumn, IconColumn, PillColumn, Icon, numberToSortable} from "@/components/table2/table_util.js";
 
 export default {
   components: {
     BoxContainer,
     Table,
   },
   name: "TabRisiko",
   props:{
     selectedTabLabel: {
       type: String
     }
   },
   computed: {
     ...mapGetters({
         risiko : FONDSINFO_TYPES.GETTERS.FONDSINFO_RISIKO
     }),
     headers() {
        const result = {
              center: [],
           };

        result.center.push(TextColumn("first_col", "Kennzahl"));
        result.center.push(TextColumn("col1", "Wert"));
        result.center.push(TextColumn("col2", "1 Jahr"));
        result.center.push(TextColumn("col3", "3 Jahre"));
        result.center.push(TextColumn("col4", "5 Jahre"))



        return result;        
     },
     rows() {
        let rows = [];
        if (!this.risiko?.records || !this.risiko.tableHeaders) {
          return rows;
        }


        this.risiko?.records.forEach(r => {

          const row = {};

          row.first_col = r[0] || '-';
          row.col1 = r[1]?.trim()?.length ? r[1] : '-';
          row.col2 = r[2] || '-';
          row.col3 = r[3] || '-';
          row.col4 = r[4] || '-';

          rows.push(row);

        });

        return rows;
     },
     tableId() {
      const uniqueUUID = "4839f268-1578-4c86-802f-6cce477c45bc";
      return uniqueUUID;
    }, 
   },
   mounted() {
     if(!this.risiko) {
       this.$store.dispatch(FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_RISIKO, 
       {
         isin: this.$route.params?.isin
       });
     }
   },
 
 }
 </script>
 
 <style>
 
 </style>
  