var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "antrag-components__container" }, [
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("InputRadioBoxGroup", {
          attrs: {
            validateUntouched: true,
            values: [
              {
                value: "alleDepot",
                label:
                  "Alle Positionen des Depots sind vollständig zu übertragen"
              },
              {
                value: "",
                label:
                  "Bitte übertragen Sie die folgend ausgewählten Positionen in den angegebenen Stückzahlen:"
              }
            ]
          },
          on: {
            input: function($event) {
              return _vm.$emit("save", { alleDepot: $event ? true : false })
            }
          },
          model: {
            value: _vm.form.alleDepot,
            callback: function($$v) {
              _vm.$set(_vm.form, "alleDepot", $$v)
            },
            expression: "form.alleDepot"
          }
        }),
        _c("WertpapierauswahlDepotUebertragung", {
          attrs: {
            hasGesamtbestand: true,
            positions: _vm.positionData,
            anzahlFonds: _vm.anzahlFonds,
            disabled: _vm.form.alleDepot === "alleDepot"
          },
          on: {
            save: function($event) {
              return _vm.$emit("save", $event)
            },
            fondsfinder: function($event) {
              return _vm.$emit("fondsfinder", $event)
            }
          }
        }),
        _c("FormDivider"),
        _c("InputToggleSwitch", {
          attrs: { label: "bestehende Sparpläne löschen" },
          on: {
            input: function($event) {
              return _vm.$emit("save", { sparplanBeenden: $event })
            }
          },
          model: {
            value: _vm.form.sparplanBeenden,
            callback: function($$v) {
              _vm.$set(_vm.form, "sparplanBeenden", $$v)
            },
            expression: "form.sparplanBeenden"
          }
        }),
        _c("InputToggleSwitch", {
          attrs: { label: "Löschung des Freistellungauftrags" },
          on: {
            input: function($event) {
              return _vm.$emit("save", { freistellungBeenden: $event })
            }
          },
          model: {
            value: _vm.form.freistellungBeenden,
            callback: function($$v) {
              _vm.$set(_vm.form, "freistellungBeenden", $$v)
            },
            expression: "form.freistellungBeenden"
          }
        }),
        _c("InputToggleSwitch", {
          attrs: { label: "anschließende Depotauflösung" },
          on: {
            input: function($event) {
              return _vm.$emit("save", { depotAufloesen: $event })
            }
          },
          model: {
            value: _vm.form.depotAufloesen,
            callback: function($$v) {
              _vm.$set(_vm.form, "depotAufloesen", $$v)
            },
            expression: "form.depotAufloesen"
          }
        }),
        _c("InputToggleSwitch", {
          attrs: { label: "anschließende Kontoauflösung" },
          on: {
            input: function($event) {
              return _vm.$emit("save", { kontoAufloesen: $event })
            }
          },
          model: {
            value: _vm.form.kontoAufloesen,
            callback: function($$v) {
              _vm.$set(_vm.form, "kontoAufloesen", $$v)
            },
            expression: "form.kontoAufloesen"
          }
        }),
        _c("InputToggleSwitch", {
          attrs: {
            label:
              "Bruchstücke sind zu verkaufen und der Erlös auf mein folgendes Konto gutzuschreiben:"
          },
          on: {
            input: function($event) {
              return _vm.$emit("save", { verkaufBruchstuecke: $event })
            }
          },
          model: {
            value: _vm.form.verkaufBruchstuecke,
            callback: function($$v) {
              _vm.$set(_vm.form, "verkaufBruchstuecke", $$v)
            },
            expression: "form.verkaufBruchstuecke"
          }
        }),
        _vm.form.verkaufBruchstuecke
          ? _c("EntitySelector", {
              attrs: {
                values: _vm.bankverbindungSelection,
                label: "Verwende Konto"
              },
              on: {
                new: _vm.editBankverbindung,
                edit: _vm.editBankverbindung,
                change: function($event) {
                  return _vm.$emit("save", {
                    bankverbindungId:
                      $event === "NICHT_VERWENDEN" ? null : $event
                  })
                }
              },
              model: {
                value: _vm.form.bankverbindungId,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bankverbindungId", $$v)
                },
                expression: "form.bankverbindungId"
              }
            })
          : _vm._e(),
        _c(
          "BaseButton",
          {
            staticClass: "col-12",
            on: {
              click: function($event) {
                return _vm.$emit("back")
              }
            }
          },
          [_vm._v("Zurück")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }