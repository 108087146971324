<template>
  <BaseModal ref="modal"
      labelButtonConfirm="Speichern"
      labelButtonCancel="Abbrechen"
      @onConfirmButton="save"
      @onCancelButton="cancel"
      @onCloseButton="cancel">
    <template v-slot:modalTitle>
      Medieneinstellungen
    </template>
    <div>
      <label style="width: 100%;">
        <ph-video-camera :size="24"></ph-video-camera>
        Bevorzugte Kamera
        <span v-if="cameraList.length <= 1">(Keine Kamera gefunden)</span>
        <Combobox :value="previewCamera" @change="updateCameraPreview($event)" :values="cameraList" :disabled="cameraList.length <= 1"/>
      </label>
      <br>
      <div class="video-preview" v-if="cameraList.length > 1">
        <video :src-object.prop.camel="cameraStream" width="200px" height="150px" autoplay playsinline></video>
      </div>
      <hr>
      <label style="width: 100%;">
        <ph-microphone :size="24"></ph-microphone>
        Bevorzugtes Mikrofon
        <span v-if="microphoneList.length <= 1">(Kein Mikrofon gefunden)</span>
        <Combobox :value="previewMicrophone" @change="updateMicrophonePreview($event)" :values="microphoneList" :disabled="microphoneList.length <= 1"/>
      </label>
      <br>
      <div class="audio-preview">
        <div :style="{width: maxVolume + '%'}"></div>
      </div>
      <audio ref="mediaPreviewAudio">
        <source :src="apiAddress + '/../../ksc/assets/sinus_425Hz_1000ms.mp3'" type='audio/mp3'/>
      </audio>
      <template v-if="setSinkIdMethodExists">
        <hr>
        <label style="width: 100%;">
          <ph-speaker-high :size="24"></ph-speaker-high>
          Bevorzugte Audioausgabe
          <span v-if="speakerList.length <= 1">(Kein Ausgabegerät gefunden)</span>
          <Combobox v-model="previewSpeaker" :values="speakerList" :disabled="speakerList.length <= 1"/>
        </label>
        <br>
        <BaseButton isSecondary @click="playSound()">
          Prüfton abspielen
        </BaseButton>
      </template>
    </div>
  </BaseModal>
</template>

<script>
import { mapGetters } from 'vuex'
import WEBRTC_TYPES from '../../store/webrtc/types'
import CORE_TYPES from '../../store/core/types'
import LOG_TYPES from '@/store/log/types';
import BaseModal from '@/components/core/BaseModal.vue';
import BaseButton from '@/components/core/BaseButton'
import Combobox from '@/components/core/forms/ComboBox.vue';
import { PhMicrophone, PhVideoCamera, PhSpeakerHigh } from 'phosphor-vue'
import { getSavedCameraId, getSavedMicrophoneId, getSavedSpeakerId, saveWebRTCSettings } from '@/helpers/webrtc-settings-helper';

export default {
  components: {
    BaseModal,
    BaseButton,
    Combobox,
    PhMicrophone,
    PhVideoCamera,
    PhSpeakerHigh,
  },
  props: {
  },
  data() {
    const AudioContext = window.AudioContext || window.webkitAudioContext
    const audioCtx = AudioContext && new AudioContext()
    const audioAnalyzer = audioCtx.createAnalyser();
    audioAnalyzer.fftSize = 512;
    const audioAnalyzerBuffer = new Uint8Array(audioAnalyzer.frequencyBinCount);
    return {
      previewCamera: this.cameraIdSetting,
      previewMicrophone: this.microphoneIdSetting,
      previewSpeaker: this.speakerIdSetting,
      cameraStream: null,
      maxVolume: 0,
      audioCtx,
      audioAnalyzerSource: null,
      audioAnalyzer,
      audioAnalyzerBuffer,
      oscillatorAnimationFrame: requestAnimationFrame(() => this.showPreviewVolume()),
      setSinkIdMethodExists: false,
    }
  },
  computed: {
    ...mapGetters({
      cameraList: WEBRTC_TYPES.GETTERS.CAMERA_OPTIONS,
      microphoneList: WEBRTC_TYPES.GETTERS.MICROPHONE_OPTIONS,
      speakerList: WEBRTC_TYPES.GETTERS.SPEAKER_OPTIONS,
      sentVideo: WEBRTC_TYPES.GETTERS.SENT_VIDEO,
      sentAudio: WEBRTC_TYPES.GETTERS.SENT_AUDIO,
      callId: WEBRTC_TYPES.GETTERS.CALL_ID,
      apiAddress: CORE_TYPES.GETTERS.API_ADDRESS,
    }),
    speakerIdSetting() {
      return getSavedSpeakerId()
    },
    microphoneIdSetting() {
      return getSavedMicrophoneId()
    },
    cameraIdSetting() {
      return getSavedCameraId()
    },
  },
  mounted() {
    this.$store.commit(WEBRTC_TYPES.MUTATIONS.ENUMERATE_DEVICES);
    this.previewSpeaker = this.speakerIdSetting;
    const audio = document.createElement("audio");
    this.setSinkIdMethodExists = audio.setSinkId != null;
    this.updateMicrophonePreview(this.microphoneIdSetting);
    this.updateCameraPreview(this.cameraIdSetting);
    this.$refs.modal.open();
  },
  methods: {
    maybeEndCameraStream() {
      if (this.cameraStream && this.cameraStream != this.sentVideo) {
        this.cameraStream.getTracks().forEach(track => {
          track.stop();
        });
      }
    },
    updateCameraPreview(deviceId) {
      this.previewCamera = deviceId;
      this.maybeEndCameraStream();
      if (this.sentVideo && (!this.previewCamera || this.previewCamera == this.sentVideo.deviceId)) {
        this.cameraStream = this.sentVideo;
      }
      else {
        // same settings as with the real video transmission in a call
        const settings = {
          audio: false,
          video: {
            facingMode: "user",
            frameRate: 15,
            width: 1280,
            height: 720,
            deviceId: this.previewCamera ? {exact: this.previewCamera} : undefined,
          },
        };
        navigator.mediaDevices.getUserMedia(settings).then(stream => {
          this.$store.commit(WEBRTC_TYPES.MUTATIONS.ENUMERATE_DEVICES);
          this.cameraStream = stream;
        }).catch(error => {
          this.$store.dispatch(LOG_TYPES.ACTIONS.WARN, {message: 'updateCameraPreview getUserMedia error', error});
        })
      }
    },
    maybeEndMicrophoneStream() {
      if (this.audioAnalyzerSource) {
        this.audioAnalyzerSource.disconnect();
        if (this.audioAnalyzerSource.mediaStream != this.sentAudio)
          this.audioAnalyzerSource.mediaStream.getTracks().forEach(track => {
            track.stop()
          });
        this.audioAnalyzerSource = null;
      }
    },
    setMicrophoneStream(stream) {
      this.maybeEndMicrophoneStream();
      this.$store.commit(WEBRTC_TYPES.MUTATIONS.ENUMERATE_DEVICES);
      this.audioAnalyzerSource = this.audioCtx.createMediaStreamSource(stream);
      this.audioAnalyzerSource.connect(this.audioAnalyzer);
    },
    updateMicrophonePreview(deviceId) {
      this.previewMicrophone = deviceId;
      if (this.sentAudio && (!this.previewMicrophone || this.previewMicrophone == this.sentAudio.deviceId)) {
        this.setMicrophoneStream(this.sentAudio);
      }
      else {
        // same settings as with the real audio transmission in a call
        const settings = {
          audio: {
            echoCancellation: true,
            autoGainControl: false,
            noiseSuppression: false,
            googEchoCancellation: true,
            googAutoGainControl: false,
            googNoiseSuppression: false,
            deviceId: this.previewMicrophone ? {exact: this.previewMicrophone} : undefined,
          },
          video: false,
        };
        navigator.mediaDevices.getUserMedia(settings)
        .then(stream => this.setMicrophoneStream(stream))
        .catch(error => {
          this.$store.dispatch(LOG_TYPES.ACTIONS.ERROR, {message: 'updateMicrophonePreview getUserMedia error', error});
        })
      }
    },
    showPreviewVolume() {
      this.audioAnalyzer.getByteFrequencyData(this.audioAnalyzerBuffer);
      this.maxVolume = 0;
      for (let i = 0; i < this.audioAnalyzerBuffer.length / 2; i++) {
        this.maxVolume = Math.max(this.maxVolume, this.audioAnalyzerBuffer[i] / 2.56);
      }
      this.oscillatorAnimationFrame = requestAnimationFrame(() => this.showPreviewVolume());
    },
    playSound() {
      this.$refs.mediaPreviewAudio.setSinkId(this.previewSpeaker)
      .then(() => {
        this.$refs.mediaPreviewAudio.currentTime = 0;
        this.$refs.mediaPreviewAudio.play();
      })
    },
    save() {
      saveWebRTCSettings(this.previewMicrophone, this.previewCamera, this.previewSpeaker)
      
      if (this.callId) {
        if (this.sentVideo && this.cameraStream && this.cameraStream != this.sentVideo)
          this.$store.dispatch(WEBRTC_TYPES.ACTIONS.TRANSMIT_VIDEO, {stream: this.cameraStream, isCamera: true});
        if (this.sentAudio && this.audioAnalyzerSource && this.audioAnalyzerSource.mediaStream && this.audioAnalyzerSource.mediaStream == this.sentAudio)
          this.$store.dispatch(LOG_TYPES.ACTIONS.INFO, "Not updating audio stream because the current audio stream is already the chosen one");
        if (this.sentAudio && this.audioAnalyzerSource && this.audioAnalyzerSource.mediaStream && this.audioAnalyzerSource.mediaStream != this.sentAudio)
          this.$store.dispatch(WEBRTC_TYPES.ACTIONS.TRANSMIT_AUDIO, {callId: this.callId, stream: this.audioAnalyzerSource.mediaStream});
      }
      this.close();
    },
    cancel() {
      this.close();
    },
    close() {
      cancelAnimationFrame(this.oscillatorAnimationFrame);
      this.maybeEndCameraStream();
      this.maybeEndMicrophoneStream();
      this.$refs.modal.close();
      this.$emit("close");
    }
  },
}
</script>

<style scoped>
.container {
  -outline: 1px solid black;
  max-height: 100%;
  overflow-y: auto;
  width: 400px;
}
.video-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.audio-preview {
  width: 100%;
  height: 10px;
  border: 1px solid black;
  border-radius: 5px;
}
.audio-preview div {
  height: 100%;
  background-color: gray;
}
</style>
