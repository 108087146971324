var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("DBM", {
        attrs: {
          query: this.pars(),
          refreshInterval: this.refreshInterval,
          act: this.act
        }
      }),
      this.dat ? _c("div") : _vm._e(),
      _c("DbmT", {
        attrs: { dat: this.dat, name: "ggggg" },
        on: { onClickTbs: _vm.clickTb }
      }),
      _c("DbmSegmentsDialog", { ref: "DbmSegmentsDialog" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }