<template>
  <BaseButton isClear class="context-menu-item__container" v-bind="$attrs" v-on="$listeners">
    <slot />
  </BaseButton>
</template>

<script>
import BaseButton from '@/components/core/BaseButton.vue';

export default {
  components: {
    BaseButton,
  },
}
</script>

<!-- GLOBAL STYLE -->
<style lang="scss">
.context-menu-item__container {
  margin: 6px 0;
  width: 100%;
  min-width: 100%;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  > *:not(svg),
  .toggle-switch__inline-label {
    width: 100% !important;
    min-width: 100% !important;
    margin: 0;
  }

  a,
  button,
  .button { /** clear button style */
    background: none !important;
    border: none !important;
    color: var(--color-text) !important;
    padding: 0 !important;
    margin: 0 !important;
  }

  & + .context-menu-group__container {
    margin-top: 4px;
  }
}

.app--is-mobile-native-context {
  .context-menu-item__container {
    margin: 2px 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
