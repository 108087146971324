<template>
    <BaseModal ref="modal"
        modalTitle="Telefonnummer ändern"
        @onCloseButton="close"
        :showDefaultButtons="false">
        <template v-slot:default>
            <InputField label="Neue Telefonnummer für Sicherheitscodes" v-model="phoneNumber" @change="clearErrorStr()" />
            <BaseButton @click="requestCodes" :disabled="isFormInvalid || tokenButtonsDisabled" isSecondary>Sicherheitscode {{codesRequested ? 'erneut ' : ''}}senden</BaseButton>
            <InputField class="mt-4" label="SMS Code" v-model="smsCode" v-if="codesRequested"/>
            <div v-if="errorStr" class="color-danger mt-2">{{errorStr}}</div>
        </template>
        <template v-slot:footer>
            <BaseButton @click="changePhone" :disabled="isFormInvalid || smsCode.length <= 0" isPrimary>Telefonnummer ändern</BaseButton>
        </template>
    </BaseModal>
</template>
<script>
import SSO_TYPES from '@/store/sso/types';
import { mapGetters } from 'vuex';

import BaseModal from '@/components/core/BaseModal.vue';
import InputField from '@/components/core/forms/InputField.vue';
import BaseButton from '@/components/core/BaseButton.vue'
import validator from '@/mixins/validator/index.js';
import { formatPhone, required, createRule } from "@/mixins/validator/rules";

export default {
    mixins: [validator],
    validators: {
        phoneNumber: [
            required(), 
            formatPhone('Bitte geben Sie eine gültige Telefonnummer ein.'),
            createRule((value, self) => !value || value !== self.oldPhoneNumber)
                .withMessage('gleich der aktuellen Telefonnummer'),
        ],
    },
    components: {
        BaseModal,
        InputField,
        BaseButton,
    },
    emits: ['close'],
    computed: {
        ...mapGetters({
            oldPhoneNumber: SSO_TYPES.GETTERS.PHONE_NUMBER,
        }),
        isFormInvalid() {
            return this.validation.updated && this.validation.isInvalid('phoneNumber');
        },
    },
    data() {
        return {
            phoneNumber: this.oldPhoneNumber || "",
            smsCode: "",
            codesRequested: false,
            tokenButtonsDisabled: false,
            errorStr: '',
        };
    },
    mounted() {
        this.$refs.modal.open();
    },
    methods: {
        requestCodes() {
            this.errorStr = null;
            // this.emailCode = "";
            this.smsCode = "";
            this.codesRequested = true;
            this.tokenButtonsDisabled = true;
            this.$store.dispatch(SSO_TYPES.ACTIONS.REQUEST_SMS_CODE, this.phoneNumber).then(response => {
                if (response?.data?.errorStr) {
                    if (response.data.phone) {
                         this.$pushErrors('phoneNumber', response.data.errorStr);
                    } else {
                         this.errorStr = response.data.errorStr;
                    }
                } 
            // this.$store.dispatch(SSO_TYPES.ACTIONS.REQUEST_EMAIL_CODE, null);
            }).catch(error => this.errorStr = "Die Telefonnummer konnte nicht geändert werden. Bitte überprüfen Sie ihre Eingaben.");
            
            setTimeout(() => {
                this.tokenButtonsDisabled = false;
            }, 2000);
        },
        changePhone() {
            this.$store.dispatch(SSO_TYPES.ACTIONS.CHANGE_PHONE_NUMBER, {
                newValue: this.phoneNumber,
                smsCode: this.smsCode,
            }).then(response => {
                 if (response?.data?.errorStr) {
                    if (response.data.sms) {
                        this.$pushErrors('smsCode', response.data.errorStr);
                    } else {
                         this.errorStr = response.data.errorStr;
                    }
                } else {
                    this.$store.dispatch(SSO_TYPES.ACTIONS.GET_SSO_ACCOUNT_INFO);
                    this.close();
                }
            }).catch(error => {
                this.errorStr = "Die Telefonnummer konnte nicht geändert werden. Bitte überprüfen Sie ihre Eingaben.";
            });
        },
        close() {
            this.errorStr = null;
            this.$refs.modal.close();
            this.$emit('close');
        },
         clearErrorStr() {
            if (this.errorStr) {
                this.errorStr = null;
            }
        }
    },
}
</script>
<style scoped>

</style>