var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ComboBox", {
        attrs: {
          label: "Bezug",
          validateUntouched: "",
          values: _vm.bezugstelleValues
        },
        on: {
          change: function($event) {
            _vm.clearGesellschaft()
            _vm.clearFondsname()
            _vm.findGesellschaft($event)
            _vm.dataChangedCombo("bezug", $event)
          }
        },
        model: {
          value: _vm.form.bezug,
          callback: function($$v) {
            _vm.$set(_vm.form, "bezug", $$v)
          },
          expression: "form.bezug"
        }
      }),
      _c("ComboBox", {
        attrs: {
          label: "Gesellschaft",
          validateUntouched: "",
          values: _vm.gesellschaftValues
        },
        on: {
          change: function($event) {
            _vm.clearFondsname()
            _vm.findFonds($event)
            _vm.dataChangedCombo("gesellschaft", $event)
          }
        },
        model: {
          value: _vm.form.gesellschaft,
          callback: function($$v) {
            _vm.$set(_vm.form, "gesellschaft", $$v)
          },
          expression: "form.gesellschaft"
        }
      }),
      _c("ComboBox", {
        attrs: {
          label: "Fondsname",
          validateUntouched: "",
          values: _vm.fondsValues
        },
        on: {
          change: function($event) {
            return _vm.dataChangedCombo("fondsname", $event)
          }
        },
        model: {
          value: _vm.form.fondsname,
          callback: function($$v) {
            _vm.$set(_vm.form, "fondsname", $$v)
          },
          expression: "form.fondsname"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Zeichnungssumme",
          validateUntouched: "",
          type: "currency"
        },
        on: {
          change: function($event) {
            return _vm.dataChanged()
          }
        },
        model: {
          value: _vm.form.zeichnungssumme,
          callback: function($$v) {
            _vm.$set(_vm.form, "zeichnungssumme", $$v)
          },
          expression: "form.zeichnungssumme"
        }
      }),
      _vm.isAVL
        ? [
            _c("InputField", {
              attrs: { label: "AVL Angebotsnr" },
              on: {
                change: function($event) {
                  return _vm.dataChanged()
                },
                input: function($event) {
                  return _vm.addAvlAngebotsnummerErrorIfNeeded()
                }
              },
              model: {
                value: _vm.form.avlAngebotsnummer,
                callback: function($$v) {
                  _vm.$set(_vm.form, "avlAngebotsnummer", $$v)
                },
                expression: "form.avlAngebotsnummer"
              }
            }),
            _c("InputToggleSwitch", {
              attrs: { label: "Zeichnung mit Direktrabatt", inLineLabel: "" },
              on: {
                change: function($event) {
                  return _vm.dataChanged()
                }
              },
              model: {
                value: _vm.form.avlMitDirektRabatt,
                callback: function($$v) {
                  _vm.$set(_vm.form, "avlMitDirektRabatt", $$v)
                },
                expression: "form.avlMitDirektRabatt"
              }
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }