<template>
  <div>
    <BaseModal ref="modalAdd" modalTitle="Aufgabe bearbeiten" :showDefaultButtons="false" size="lg">
      <AppointmentEditCreate
        :closeModal="onCancelModal"
        :subject="`${gesellschaftName} ${(nummer || bereichId || bezeichnung) && `(${nummer || bereichId || bezeichnung})`}`"
        :status="'OFFEN'"
        :isActivity="true"
        :bereich="'VersVertrag'"
        :bereichId="bereichId"
        :bereichText="bereichText"
      />
    </BaseModal>    


  </div>
</template>

<script>
import BaseModal from '@/components/core/BaseModal.vue';
import AppointmentEditCreate from '@/components/calendar/appointmentFiles/AppointmentEditCreate.vue';

export default {
    components: {
      BaseModal,
      AppointmentEditCreate,
    },
    props: {
      bereichId: {
        type: String,
        default: () => null,
      },    
      gesellschaftName: {
        type: String,
        default: () => null,
      },       
      nummer: {
        type: String,
        default: () => null,
      },         
      bezeichnung: {
        type: String,
        default: () => null,
      },      
      bereichText: {
        type: String,
        default: () => null,
      },             
    },
    data() {
      return {
        selectedRowsValues: [],
      }
    },
    computed: {
    },
    methods: {
      open() {
        this.$refs.modalAdd.open();
      },
      close() {
        this.$refs.modalAdd.close();
      },
      onConfirmModal() {
      },
      onCancelModal() {
        this.$refs.modalAdd.close();
      },
      selectedRows(rows) {
        this.selectedRowsValues = rows;
      },
      confirmContactsToSend() {
        this.$emit("select-contacts", this.selectedRowsValues);
        this.$refs.mailContacts.close();
      },      
    }
}
</script>
