<template>
<div>
    <div v-if="stepParameters && stepParameters.data && stepParameters.data.parameterData">
        <div class="box__container">
            <ComboBox
                label="SRI"
                v-model="stepParameters.data.parameterData.sri"
                @change="markModified"
                :values="stepParameters.data.sriAuswahl"
                hint='Hier wird die SRI des Kunden laut Anlegerprofil übernommen'
                :isComponentHalfSize="true"
            >
            </ComboBox>
            <input-field
                v-model="stepParameters.data.parameterData.wunschrendite"
                @change="markModified"
                label="Wunschrendite "
                hint='Geben Sie hier die Zielrendite Ihres Kunden ein'
                :validateUntouched="true"
                type='percent'
                :isComponentHalfSize="true"
            ></input-field>
            <ComboBox
                label="Zugrunde liegende Zeitperiode"
                v-model="stepParameters.data.parameterData.timeperiod"
                @change="markModified"            
                :values="stepParameters.data.timeperiodAuswahl"
                hint='Hier wird die SRI des Kunden laut Anlegerprofil übernommen'
                :isComponentHalfSize="true"
            >
            </ComboBox>      
        </div>
        <div class="box__container">
            <FormHeader label='Limitierung'/>       
            <label class="input-forms__label-container">
                <div class="input-forms__label-content">pro Fonds</div>
                <div class="input-forms__input-container forms__input--half-size">
                    <div class="row my-0">
                        <input-field class="col-6 pb-0"
                            v-model="stepParameters.data.parameterData.percentagePerFundMin"
                            @change="markModified"
                            :validateUntouched="true"
                            type='percent'
                        ></input-field>
                        <input-field class="col-6 pb-0"
                            v-model="stepParameters.data.parameterData.percentagePerFundMax"
                            @change="markModified"
                            :validateUntouched="true"
                            type='percent'
                        ></input-field>
                    </div>                
                </div>
                <div class="input-forms__hint-content col-12 mt-0">Limitieren Sie hier den Anteil je Einzelfonds innerhalb der Depotoptimierung (gilt nicht für die gesperrten Positionen)</div>
            </label>        
            <label class="input-forms__label-container">
                <div class="input-forms__label-content">pro Assetklasse</div>
                <div class="input-forms__input-container forms__input--half-size">
                    <div class="row my-0">
                        <input-field class="col-6 pb-0"
                            v-model="stepParameters.data.parameterData.percentagePerAssetclassMin"
                            :validateUntouched="true"
                            @change="markModified"
                            type='percent'
                        ></input-field>
                        <input-field class="col-6 pb-0"
                            v-model="stepParameters.data.parameterData.percentagePerAssetclassMax"
                            :validateUntouched="true"
                            @change="markModified"                    
                            type='percent'
                        ></input-field>
                    </div>                
                </div>
                <div class="input-forms__hint-content col-12 mt-0">Limitieren Sie hier den maximalen Anteil einer Assetklasse innerhalb der Optimierung</div>
            </label>
        </div>
        <div class="box__container">
            <FormHeader label='Max. Anzahl'/>        
            <input-field
                v-model="stepParameters.data.parameterData.candidatesMax"
                label='Fonds im Depot'
                 @change="markModified"
                :validateUntouched="true"
                type='number'
                :isComponentHalfSize="true"
            ></input-field>
            <input-field
                v-model="stepParameters.data.parameterData.candidatesPerAssetclassMax"
                label=' Fonds Pro Assetklasse'
                 @change="markModified"
                :validateUntouched="true"
                type='number'
                :isComponentHalfSize="true"
            ></input-field>
        </div>         
    </div>
		<div class="box__container">
			<div class="box__title">Einschränkungen</div>
			<GhostLoading v-if="loading">
				<Block height="200" />
			</GhostLoading>
			<Table v-else-if="rows.length"
        ref="tblRestriction"
				:headers="headers"
				:rows="rows"
				rowId="unique"
				hidePagination
				@action-FACTSHEET="viewDocument($event.FACTSHEET)"
				@action-FACTSHEET_KAG="viewDocument($event.FACTSHEET_KAG)"
				@action-PRIIP_BIB="viewDocument($event.PRIIP_BIB_URL)"
        @action-EDIT="edit($event.isin)"
        @click-name="gotoFondsinfo"
			>
        <template #current="row">
          <span class="edited-row" @click="edit(row.isin)" >
            <span :class="{selected: edit_isin==row.isin}">
              <PhPencilLine :style = "{opacity: edit_isin==row.isin ? '1' : '0.3' }" :size="18"/>
            </span>
          </span>
        </template>
			</Table>
      <NoData :noIcon="true" v-else />
		</div>
    
    <div class="box__container" v-if="rows.length">
      <div class="box__title">Positionen bearbeiten</div>
      <div class="box__title">{{edit_name}}</div>
      <div class="row">
        <InputField v-model="minProc" type="percent" precision="6" class="col-12 col-sm-6"
          @change="rowEdited" label = "minimaler Anteil"/>
        <InputField v-model="maxProc" type="percent" precision="6" class="col-12 col-sm-6"
          @change="rowEdited" label = "maximaler Anteil"/>
      </div>
      <div class="d-flex">
        <BaseButton :isSecondary="true" :disabled="edit_idx <= 0" @click="changeRow(-1)">Vorherige Zeile</BaseButton>
        <BaseButton :isSecondary="true" :disabled="edit_idx < 0 || edit_idx > rows.length - 2 " @click="changeRow(1)">Nächste Zeile</BaseButton>
      </div>
    </div>
	</div>
</div>
</template>
<!-- POSITIONS: {{ stepPositions }}  -->
<script>
import StepMixin from "./StepMixin";
import INVESTMENT_ADVICE from "@/store/investmentAdvice/types";
import StepAllocation from "./StepAllocation";
import Table from "@/components/table2/Table.vue";
import {TextColumn, PillColumn, SlotColumn, ActionColumn, PercentageColumn, NumberColumn} from "@/components/table2/table_util.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import NoData from '@/components/core/NoData.vue';
import { PhPencilLine } from "phosphor-vue";
import { viewDocument } from '@/components/core/download/DownloadLink.vue';
import InputField from "@/components/core/forms/InputField.vue";
import BaseButton from "@/components/core/BaseButton.vue";

export default {
  data() {
    return {
      stepAllocation: [
        new StepAllocation(undefined, null),
      ],
      step: INVESTMENT_ADVICE.STEPS.OPTIMIZATION,
      percentPrecision: 6,
			headers: {
        lockedLeft: [
          SlotColumn('current', null, 80),
          PillColumn('lockingModePill', 'Einschränkungen', 150),
        ],
        center: [
          TextColumn('isin', 'ISIN'),
          TextColumn('name', 'Name').makeAlwaysVisible().makeLink(),
          TextColumn('Frs', 'SRI', 50, 0),
          PercentageColumn('minProc', 'min Anteil'),
          PercentageColumn('maxProc', 'max Anteil'),
					NumberColumn('rendite', 'Historische Rendite'),
					NumberColumn('risiko', 'Risiko'),
					NumberColumn('sr', 'SR'),
          TextColumn('assetklasse', 'Assetklasse'),
        ],
        lockedRight: [
          ActionColumn('actions', '')
        ]
			},
      
      edit_isin: '',
      edit_idx: -1,
      edit_name: '',
      minProc: null,
      maxProc: null,
    };
  },
  mixins: [StepMixin],
	components: {
		Table, GhostLoading, Block, NoData, PhPencilLine, InputField, BaseButton,
	},
  computed: {
		test(){
				return false;
		},
    rows() {
      if (this.stepParameters?.data?.rows?.length ) {
        return this.stepParameters.data.rows;
      }
      return []
    },
  },
  watch: {
    modified() {
      this.$emit('modified', true)
    },
    rows() {
      if (this.rows?.length) {
        if (!this.edit_isin) {
          this.edit(this.rows[0].isin)
        }
      }
    },
  },
  methods: {
    save(makePersist, source) {
      if (this.loading) {
        return Promise.resolve();
      }
      return this.$store.dispatch(INVESTMENT_ADVICE.ACTIONS.SAVE_PARAMETERS, { 
        adviceId: this.id,
        parameters: {
          step: this.step,
          depotoptimierungParameterData: this.stepParameters.data.parameterData,
          rows: this.rows.map(row => ({
            id: row.id,
            isin: row.isin,
            minProc: row.minProc,
            maxProc: row.maxProc,
            changed: row.changed,
          }))
          // tableData: this.stepAllocation[this.currentAsset].tableData.records.map(item=> Object.values(item))
        }
       });
    },
    gotoFondsinfo(row) {
      const path = `/shared/vermogensubersicht/fondsinfo/${row.isin}`;
      this.$router.push({ path });
    },
    viewDocument(href) {
      viewDocument({href});
    },
    edit(isin) {
      this.edit_isin = isin;
      this.edit_idx = this.rows.findIndex(row => row.isin === isin);
      if (this.edit_idx != -1) {
        const row = this.rows[this.edit_idx];
        this.edit_name = row.name;
        this.minProc = row.minProc;
        this.maxProc = row.maxProc;
      }
    },
    changeRow(next) {
      if (this.edit_idx >= 0 && this.edit_idx < this.rows.length) {
        next += this.edit_idx;
        if (next >= 0 && next < this.rows.length) {
          const table = this.$refs.tblRestriction ;
          if (table && table.pageCount > 1) {
            if (next < table.pageIndex * table.pageSize ) {
              table.onPageChange(table.pageIndex - 1)
            } else if (next >= (table.pageIndex + 1) * table.pageSize ) {
              table.onPageChange(table.pageIndex + 1)
            }
          }
          this.edit(this.rows[next].isin);
        }
      }
    },
    rowEdited() {
      if (!this.rows?.length || this.edit_idx < 0) {
        return;
      }
      if (this.rows[this.edit_idx].minProc != this.minProc || this.rows[this.edit_idx].maxProc != this.maxProc || this.rows[this.edit_idx].lockingMode != this.lockingMode) {
        
			  const rows = this.rows.map(row => ({...row}));
        const row = rows[this.edit_idx];

        row.maxProc = this.maxProc;
        row.minProc = this.minProc;
        row.changed = true;
        this.modified = true;

        const changes = {
          step: this.step,
          changed: rows
        }
        this.$store.commit(INVESTMENT_ADVICE.MUTATIONS.CHANGE_ROWS, changes);
      }
    },
  }
};
</script>

<style scoped>
.edited-row {
  display: inline-block;
  height: 100%;
  width: 18px;
  box-sizing: border-box;
  position: relative;
}
.edited-row > span {
  background-color: #eee;
}
.edited-row > .selected {
  background-color: transparent;
}
</style>