var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.$appNavigation.currentMenu.label,
          defaultMenu: _vm.customOptionMenu
        }
      }),
      _c(
        "KeepAliveOnBackNavigation",
        [
          _c("WertpapierSearch", {
            attrs: {
              name: _vm.searchKey,
              type: _vm.wi_type,
              isSelectable: _vm.wi_type == "I",
              tableHeaderActions: _vm.tableHeaderActions,
              canAddToFavoritenliste: _vm.isBrokerOrBypass,
              canAddToMyDepot: _vm.isBrokerOrBypass,
              canAddToFondsvergleich: _vm.isBrokerOrBypass
            },
            on: { selected: _vm.onSelect, headerAction: _vm.headerAction }
          })
        ],
        1
      ),
      _c("AddFondsToListModal", { ref: "addFondsToListModal" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }