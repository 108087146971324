<template>
  <div>
    <template v-if="!hideNavigation">
      <PageHeaderTitleNavigation title="Kredite" 
        :defaultMenu="$appNavigation.currentTabMenu"
        :actions="headerActions" 
        @action-ADD="newCredit()"
      />
    </template>

    <div>
      <BaseFilter ref="filter"
        title="Kreditefilter" 
        filterId="6f7d47af-1dd5-4700-a532-f3b65f9c4fe4"
        hasSmartSearch
        :showSaveButton="true"
        :metadata="searchMenu" 
        :configFilter="configFilter"
        :immidiateSearch="!isBroker && !isIntern"
        @onFilter="filterForm">
      </BaseFilter>
    </div>

    <div v-if="filtered" class="box__container" tid="c491e8bd-0178-4765-8fab-df73f7d3f5db">
        <Table v-if="rows.length"
            :title="TABLE_TITLE"
            tableId="18ac3d80-ee79-4eb7-97bd-c27f0eb2332d"
            :headers="headers"
            :rows="rows"
            :mobileConfig="mobileConfig"
            @click-form="editCredit"
            @action-DELETE="deleteCredit"
            hidePagination
        />
        <GhostLoading v-else-if="loading" type="table" :title="TABLE_TITLE" />
        <NoData v-else :title="TABLE_TITLE" />
    </div>
  </div>
</template>

<script>
import CREDIT_TYPES from "@/store/credit/types";
import CORE_TYPES from "@/store/core/types";
import { mapGetters } from 'vuex';
import Table from "@/components/table2/Table.vue";
import {TextColumn, DateColumn, NumberColumn, ActionColumn, LoadingAction, CurrencyColumn, PercentageColumn} from "@/components/table2/table_util.js";
import { PhPencilLine, PhTrash } from 'phosphor-vue'
import BaseFilter from '@/components/core/BaseFilter.vue'
import dayjs from 'dayjs'
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue'
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import { isDate } from '@/helpers/commonfunctions.js';
import GhostLoading from '@/components/core/loading/GhostLoading.vue'
import NoData from '@/components/core/NoData.vue'
import { ROLES, VIEW_ROLES, BROKER_STRUCTURE_ROLES } from '@/router/roles';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';

import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import BRIDGE_TYPES from '@/store/bridge/types';

const TABLE_TITLE = 'Kredite';

export default {
  mixins: [InteractiveHelpCommonsMixin],
  props: {
    hideNavigation: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Table,
    BaseFilter,
    PhPencilLine,
    OptionMenu,
    GhostLoading,
    NoData,
    PageHeaderTitleNavigation,
  },
  data() {
    return {
      TABLE_TITLE,
      filters: null,
      deletingCreditId: null,
      configFilter: {
        placeholderForDefSearchInput: 'Form, Art, Nummer, Bezeichnung',
        checkDefaultSearchInput: false,
      },
      loading: false,
      filtered: false,
      mobileConfig: {
        title: 'form',
        headers: ['artText', 'nummer', 'gesellschaft', 'bezeichnung'],
      },
    };
  },
  computed: {
    ...mapGetters({
      credits: CREDIT_TYPES.GETTERS.CREDITS,
      isSuperCustomer: CORE_TYPES.GETTERS.IS_SUPER_CUSTOMER,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      isMobileNativeContext: BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT,
    }),
    allowEditCredit() {
      return this.hasRoles(
        [
          [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS], 
          [VIEW_ROLES.VIEW_CUSTOMER, ROLES.ALLOW_EDIT_CREDIT],
          [VIEW_ROLES.VIEW_BROKER, BROKER_STRUCTURE_ROLES.ZUGRIFF_KREDITEDARELEHEN],
          [VIEW_ROLES.VIEW_INTERN]
        ]);
    },
    headerActions() {
      const { allowEditCredit, hasRoles, isSuperCustomer } = this;

      const actions = [];
      if(allowEditCredit && hasRoles([VIEW_ROLES.VIEW_CUSTOMER]) && !isSuperCustomer) {
        actions.push(PageHeaderSimpleAction('ADD', 'Kredite hinzufügen'));
      }
      return actions;
    },
    isSuperCustomer() {
      return this.hasRoles(ROLES.SUPER_CUSTOMER);
    },
    headers() {
        const result = {
            lockedLeft: [
                TextColumn("form", "Form").makeLink(this.allowEditCredit),
            ],
            center: [
                TextColumn("artText", "Art"),
                TextColumn("nummer", "Nummer"),
                TextColumn("gesellschaft", "Gesellschaft"),
                TextColumn("bezeichnung", "Bezeichnung"),
                CurrencyColumn("summe", "Summe").withSumFooter(),
                TextColumn("zahlungsrhythmus", "Zahlungsrhythmus"),
                CurrencyColumn("rate", "Rate p.a."),
                CurrencyColumn("monatlicheRate", "monatliche Rate"),
                PercentageColumn("kurs", "Ausz.kurs"),
                DateColumn("termin", "Ausz. termin"),
                DateColumn("begin", "Beginn"),
                DateColumn("end", "Ende"),
                NumberColumn("id", "Id").addCellProps({separator: ""}),
                DateColumn("ablauf", "Vertragsablauf"),
            ],
            lockedRight: [
                ActionColumn("actions"),
            ],
        };
        if (this.isSuperCustomer || this.hasRoles(VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS)) {
            if (this.isSuperCustomer) {
                result.lockedLeft.unshift(TextColumn("vorname", "Vorname"));
                result.lockedLeft.unshift(TextColumn("name", "Name"));
            }
            result.lockedLeft.unshift(TextColumn("kundennr", "Kundennummer"));
        }
        return result;
    },
    rows() {
        return this.credits.map(row => {
            const actions = [];
            if (this.allowEditCredit) {
                actions.push(LoadingAction("DELETE", PhTrash, "Löschen", undefined, (row) => row.id == this.deletingCreditId));
            }
            return {
                ...row,
                actions,
            }
        });
    },
    searchMenu() {
      let menuAllgemein =         {
          type: 'group',
          key: 'allgemein',
          label: 'Allgemein',
          menuItems: [    
            {
              type: 'text',
              label: 'Kredit-/Darlehensnummer',
              key: 'kreditnr',
            },
            {
              type: 'text',
              label: 'Prohyp Antragsnummer',
              key: 'ehypAntragsnr',
            },
            {
              type: 'integer',
              label: 'Interne Nummer',
              key: 'vertragsNr',
            },
          ]
        };

      if (this.isIntern) {
        menuAllgemein?.menuItems.push(
            {
              type: 'text',
              label: 'Vermittlernummer',
              key: 'maklernr',
            },
            {
              type: 'text',
              label: 'Kundennr',
              key: 'kundennr',
            },            
        );
      }

      if (this.isBroker) {
        menuAllgemein?.menuItems.push(
            {
              type: 'text',
              label: 'Kundennr',
              key: 'kundennr',
              inputmode: 'numeric',
            },            
        );
      }      

      return [
        ...[menuAllgemein],
        {
          type: 'group',
          key: 'vertragsdaten',
          label: 'Vertragsdaten',
          menuItems: [
            {
              type: 'dateRange',
              label: 'Vertragsbeginn ab',
              key: 'vertragsBeginnAb',
              comboOptions: [
                {
                  type: 'datepicker',
                  label: 'ab',
                  key: 'min',
                },
                {
                  type: 'datepicker',
                  label: 'bis',
                  key: 'max',
                },
              ] 
            },

            {
              type: 'dateRange',
              label: 'Vertragsbeginn+10 Jahre ab',
              key: 'vertragsBeginnPlus10JahreAb',
              comboOptions: [
                {
                  type: 'datepicker',
                  label: 'ab',
                  key: 'min',
                },
                {
                  type: 'datepicker',
                  label: 'bis',
                  key: 'max',
                },
              ] 
            },

            {
              type: 'dateRange',
              label: 'Vertragsende/Ablauf Sollzinsbindung ab',
              key: 'vertragsendeAblaufSollzinsbindungAb',
              comboOptions: [
                {
                  type: 'datepicker',
                  label: 'ab',
                  key: 'min',
                },
                {
                  type: 'datepicker',
                  label: 'bis',
                  key: 'max',
                },
              ] 
            },
          ]
        },
      ]
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({to, from});
    next();
  },
  methods: {
    newCredit() {
      this.$router.push('/home/verbindlichkeiten/credit/edit/neu')
    },
    editCredit(row) {
        const path = this.isMobileNativeContext ? `/home/verbindlichkeiten/credit/overview/${row.id}` : `/home/verbindlichkeiten/credit/edit/${row.id}`
        if( this.isBroker || this.isIntern) {
            this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { 
                customerId: row.kundennr,
                path,
            });
        } else {
            this.$router.push(path)
        }
    },
    async filterForm(filters = []) {
      this.filters = filters;

      const params = filters
        .filter(filter => filter.value)
        .reduce((acc, curr) => { 
          if(curr.key === 'defaultSearchInput') {
            acc['query'] = curr.value;
          } else if (Array.isArray(curr.value)) {
            curr.value.forEach(element => { 
              acc[curr.key + element.key] = isDate(element.value) && dayjs(element.value).format('DD.MM.YYYY') || element.value
            });
          } else {
            acc[curr.key] = curr.value; 
          }
          return acc}, 
        {})

      this.loading = true;
      this.filtered = true;
      try {
        await this.$store.dispatch(CREDIT_TYPES.ACTIONS.FIND_CREDITS, params);
      } finally {
        this.loading = false;
      }
    },
    async deleteCredit(credit) {
      try {
        await this.$confirmModal({
          title: 'Kredit/Darlehen löschen',
          message: 'Soll der Eintrag wirklich gelöscht werden?',
          size: 'sm',
        });
  
        const creditId = credit?.id;
        this.deletingCreditId = creditId;
        await this.$store.dispatch(CREDIT_TYPES.ACTIONS.DELETE_CREDIT, {
          creditId,
        });
        this.deletingCreditId = null;
        this.filterForm(this.filters);
      } catch(e) {
        // empty block
      }
    },
  },
}
</script>

<style scoped>
.space-between {
  justify-content: space-between;
}
.flex-item {
  flex: 1 1 auto;
}

.text-center {
  text-align: center;
}
</style>