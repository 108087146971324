<template>
    <div>
        <Action v-bind="$props" @action="requestConfirmation"/>
        <BaseModal
            ref="confirmationModal"
            :modalTitle="confirmationTitle"
            :labelButtonConfirm="labelButtonConfirm"
            :showCancelButton="true"
            @onConfirmButton="onClick"
        >
            <label>{{ confirmationMessage }}</label>
        </BaseModal>
    </div>
</template>
<script>
import actionMixin from './action-mixin.js';
import Action from './Action.vue';
import BaseModal from '@/components/core/BaseModal.vue';

export default {
    mixins: [actionMixin],
    components: {
        Action,
        BaseModal,
    },
    props: {
        confirmationTitle: {
            type: String,
            required: true,
        },
        confirmationMessage: {
            type: String,
            required: true,
        },
        labelButtonConfirm: {
            type: String,
            default: 'Ok',
        }
    },
    methods: {
        requestConfirmation() {
            this.$refs.confirmationModal.open();
        },
    },
}
</script>
<style src='./action.scss' lang='scss' scoped/>
