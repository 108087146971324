import store from "@/store";
import FC_CONFIG from "@/configs/fcConfig";
import FC_CONFIG_TYPES from "@/store/fcConfig/types";

// detect empty paragraph
const EMPTY_P_TAG_REGEX = /(<p\s?((style=")([a-zA-Z0-9:;.\s()\-,]*)("))?>)(<\/p>)/g;

// detect either <br> and &nbsp; as possible empty paragraph placeholder
const PLACEHOLDER_EMPTY_P = /(<p\s?((style=")([a-zA-Z0-9:;.\s()\-,]*)("))?>)(<br>|&nbsp;)(<\/p>)/g;

const isPositiveRegex = /(1|j[a]?)/i;

const ADD_PARAMETER = {
  fontSize: {
    paramName: "HTML_EDITOR_DEFAULT_FONT_SIZE",
    cssStyle: (value) => {
      return value?.trim() ? `p { font-size: ${value}; }` : "";
    },
  },
  fontFamily: {
    paramName: "HTML_EDITOR_DEFAULT_FONT_FAMILY",
    cssStyle: (value) => {
      return value?.trim() ? `p { font-family: ${value}; }` : "";
    },
  },
  fontLineHeight: {
    paramName: "HTML_EDITOR_DEFAULT_FONT_LINE_HEIGHT",
    cssStyle: (value) => {
      return value?.trim() ? `p { line-height: ${value}; }` : "";
    },
  },
  fontColor: {
    paramName: "HTML_EDITOR_DEFAULT_FONT_COLOR",
    cssStyle: (value) => {
      return value?.trim() ? `p { color: ${value}; }` : "";
    },
  },
  paragraphMargin: {
    paramName: "HTML_EDITOR_DEFAULT_DISTANCE_AFTER_PARAGRAPH",
    cssStyle: (value) => {
      return value?.trim() && isPositiveRegex.test(value)
        ? ""
        : "p { margin: 0px; }";
    },
  },
};

function insertPlaceholderParagraphs(str) {
  return str?.replaceAll(EMPTY_P_TAG_REGEX, "$1&nbsp;$6");
}

function removePlaceholderParagraph(str) {
  return str?.replaceAll(PLACEHOLDER_EMPTY_P, "$1$7");
}

export function extractBodyContent(html) {
  const parser = new DOMParser();
  const htmlDocument = parser.parseFromString(html, "text/html");
  const innerBodyContent = htmlDocument.body.innerHTML;
  return innerBodyContent;
}

function appendHtmlBodyToContent(html, cssStyles) {
  return `<html><head><meta charset="UTF-8"><style>${
    cssStyles ? cssStyles.join(" ") : ""
  }</style></head><body>${html}</body></html>`;
}

function makeCssStyleEditor(configs) {
  let cssStyle = "";

  if (!configs.cssStyles) {
    return cssStyle;
  }

  for (const css of configs.cssStyles) {
    cssStyle += `.html-editor__text ${css} \n`;
  }

  return cssStyle;
}

async function loadSavedSettings(configs) {
  if (!configs.applySavedSettings) {
    return;
  }

  const payload = Object.values(ADD_PARAMETER).map((item) => ({
    configId: item.paramName,
    configType: FC_CONFIG.ADD_PARAMETER,
  }));

  await store.dispatch(FC_CONFIG_TYPES.ACTIONS.LOAD_FC_CONFIG, payload);
  const addParameter =
    store.getters[FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_ADD_PARAMETER];

  configs.cssStyles = [];
  for (const value of Object.values(ADD_PARAMETER)) {
    const defaultConfig = addParameter?.[value.paramName]?.content;
    const cssStyle = value.cssStyle(defaultConfig);
    if (cssStyle) {
      configs.cssStyles.push(cssStyle);
    }
  }
}

export class HtmlEditorContentAdapter {
  constructor(configs) {
    this.configs = {};
    this.initializeConfigs(configs);
  }

  async initializeConfigs(configs) {
    Object.assign(this.configs, configs ?? {});
    await loadSavedSettings(this.configs);

    this.configs.dataCallback({
      cssStyle: makeCssStyleEditor(this.configs),
    });
  }

  /**
   * It wraps the content before sending this to outside the editor
   * @param {*} html
   */
  wrapContent(html) {
    if (this.config?.applySavedSettings) {
      return html;
    }

    if (!html) {
      html = "";
    }

    html = insertPlaceholderParagraphs(html);

    if (!this.configs?.doNotAppendHtmlBody) {
      html = appendHtmlBodyToContent(html, this.configs?.cssStyles);
    }

    return html;
  }

  /**
   * The content must be unwrapped when comes from database before going inside the editor
   * @param {*} html
   */
  unwrapContent(html) {
    html ??= "";
    html = extractBodyContent(html);
    html = removePlaceholderParagraph(html);
    return html;
  }

  htmlHasBodyContent(html) {
    return (
      html?.toLowerCase().includes("<body") &&
      html?.toLowerCase().includes("</body>")
    );
  }
}
