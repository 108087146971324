var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "input-forms__container",
      class: { disabled: _vm.disabled }
    },
    [
      _vm.label
        ? _c("label", { staticClass: "input-forms__label-container" }, [
            _c("div", { staticClass: "input-forms__label-content" }, [
              _vm._v(_vm._s(_vm.label))
            ])
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "input-forms__input-container",
          class: { "forms__input--half-size": _vm.isComponentHalfSize }
        },
        [
          _c("div", { staticClass: "slider-chart__container" }, [
            _c("div", { staticClass: "slider-chart" }, [
              _c("div", {
                staticClass: "slider-chart--line slider-chart--green-line"
              }),
              _c("div", {
                staticClass: "slider-chart--line slider-chart--yellow-line",
                style: _vm.yellowLineStyle
              }),
              _c("div", {
                staticClass: "slider-chart--line slider-chart--red-line",
                style: _vm.redLineStyle
              })
            ])
          ]),
          _c(
            "div",
            { staticClass: "slider-inputs__container" },
            [
              _c("InputSlider", {
                staticClass: "slider-input__container p-0",
                attrs: {
                  values: _vm.values,
                  showStopLabel: false,
                  disabled: _vm.disabled
                },
                on: {
                  input: function($event) {
                    return _vm.onMinInput($event)
                  }
                },
                model: {
                  value: _vm.minValue,
                  callback: function($$v) {
                    _vm.minValue = $$v
                  },
                  expression: "minValue"
                }
              }),
              _c("InputSlider", {
                staticClass: "slider-input__container",
                attrs: { values: _vm.values, disabled: _vm.disabled },
                on: {
                  input: function($event) {
                    return _vm.onMaxInput($event)
                  }
                },
                model: {
                  value: _vm.maxValue,
                  callback: function($$v) {
                    _vm.maxValue = $$v
                  },
                  expression: "maxValue"
                }
              })
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }