<template>
  <div>
    <DBM
      :query="this.pars()"
      :refreshInterval="this.refreshInterval"
      :act="this.act"
    />

    <div v-if="this.dat"></div>
    <DbmT :dat="this.dat" name="ggggg" @onClickTbs="clickTb" >
     
    </DbmT>  
    <DbmSegmentsDialog ref="DbmSegmentsDialog"/>
  </div>
</template>

<script>
import DB_TYPES from "@/store/dbm/types";

import { mapGetters } from "vuex";
import Table from "@/components/table2/Table.vue";

import DBM from "@/views/dbm/Dbm.vue";
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import DbmT from "@/views/dbm/DbmTable.vue";
import DbmSegmentsDialog from "@/views/dbm/DbmSegmentsDialog.vue";
export default {
  props: {
    query: {
      type: String,
    },
    queryParams: {
      type: Object,
    },
    act: {
      type: String,
      default: DB_TYPES.ACTIONS.DATA,
    },
    refreshInterval: {
      type: Number,
      default: 300,
    },
  },
  components: {
    Table,

    DBM,
  
    InputToggleSwitch,
    DbmT,
    DbmSegmentsDialog
  },

  computed: {
    ...mapGetters({
      dat: DB_TYPES.GETTERS.DATA,
    }),
   
    data() {
      return {
        apiAddress: process.env.VUE_APP_API,
      };
    },
  },
    methods: {
      pars() {
        let result = {};
        result.query = this.query;
        result.queryParams = this.queryParams;
        return result;
      },
      async  clickTb(row){
        //this.$emit("onClickTbs",row);
        this.$refs.DbmSegmentsDialog?.open(row.TABLESPACE_NAME);
        this.$store.commit(DB_TYPES.MUTATIONS.START_TIME,null); 
     }
   
    },

   
  
}
</script>


<style scoped>
.subject {
  display: inline-block;
  max-width: 700px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>