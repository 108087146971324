<template>
  <div>
    <DashboardPanel
      v-if="hasAccess('/communication/postfach/overview') && this.hasAccess('/communication/postfach')"
      id="3f6a2d8a-406b-4d4a-85f9-7204579b313n"
      :data="dashboardData"
      @executeAction="handleExecuteAction"
    />
  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';

import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import DashboardPanel from '@/components/dashboard/DashboardPanel.vue';

import { mapGetters } from "vuex";

export default {
  components: {
    DashboardPanel,
    OptionMenu,
  },
  data() {
    return {
      dashboardData: {
        widgets: [
          {
            name: 'PostfachWidgetTicketsNachrichten',
            title: 'Nachrichten',
            routeStep: 'chats',
            overrideDefaultAction: true,
            isNavigable: false,
            component: () => import('@/views/communication/postfach/widgets/PostfachWidgetChats.vue'),
            visible: () => this.hasAccess('/communication/postfach/chats'),
          },
          {
            name: 'PostfachWidgetEmail',
            title: 'E-Mails',
            routeStep: 'emails',
            overrideDefaultAction: true,
            isNavigable: false,
            component: () => import('@/views/communication/postfach/widgets/PostfachWidgetEmail.vue'),
            visible: () => this.hasAccess('/communication/postfach/emails'),
          },
          {
            name: 'PostfachWidgetTermin',
            title: 'Termine',
            routeStep: 'termine',
            overrideDefaultAction: true,
            isNavigable: false,
            component: () => import('@/views/communication/postfach/widgets/PostfachWidgetTermin.vue'),
            visible: () => (this.isBroker || this.isIntern) && this.hasAccess('/communication/postfach/termine'),
          },
          {
            name: 'PostfachWidgetAufgaben',
            title: 'Aufgaben',
            routeStep: 'aufgaben',
            overrideDefaultAction: true,
            isNavigable: false,
            component: () => import('@/views/communication/postfach/widgets/PostfachWidgetAufgaben.vue'),
            visible: () => (this.isBroker || this.isIntern) && this.hasAccess('/communication/postfach/termine'),
          },
          /*{
            name: 'PostfachWidgetActivities',
            title: 'Aktivitäten',
            routeStep: 'aktivitaeten',
            overrideDefaultAction: true,
            isNavigable: false,
            component: () => import('@/views/communication/postfach/widgets/PostfachWidgetActivities.vue'),
            visible: () => (this.isBroker || this.isIntern) && this.hasAccess('/communication/postfach/aktivitaeten'),
          },*/
          {
            name: 'PostfachWidgetBemerkung',
            title: 'Bemerkungen',
            routeStep: 'bemerkungen',
            overrideDefaultAction: true,
            isNavigable: false,
            component: () => import('@/views/communication/postfach/widgets/PostfachWidgetBemerkung.vue'),
            visible: () => (this.isBroker || this.isIntern) && this.hasAccess('/communication/postfach/bemerkungen'),
          },
          {
            name: 'PostfachWidgetCalls',
            title: 'Gespräche',
            routeStep: 'talks',
            overrideDefaultAction: true,
            isNavigable: false,
            component: () => import('@/views/communication/postfach/widgets/PostfachWidgetTalks.vue'),
            visible: () => (this.isBroker || this.isIntern) && this.hasAccess('/communication/postfach/talks'),
          },
          {
            name: 'PostfachWidgetSchriftverkehr',
            title: 'Schriftverkehr',
            routeStep: 'schriftverkehr',
            overrideDefaultAction: true,
            isNavigable: false,
            component: () => import('@/views/communication/postfach/widgets/PostfachWidgetSchriftverkehr.vue'),
            visible: () => (this.isBroker || this.isIntern) && this.hasAccess('/communication/postfach/schriftverkehr'),
          },
          {
            name: 'PostfachWidgetSMS',
            title: 'SMS',
            routeStep: 'smses',
            overrideDefaultAction: true,
            isNavigable: false,
            component: () => import('@/views/communication/postfach/widgets/PostfachWidgetSMS.vue'),
            visible: () => this.hasAccess('/communication/postfach/smses'),
          },
          {
            name: 'PostfachWidgetFax',
            title: 'Faxe',
            routeStep: 'faxes',
            overrideDefaultAction: true,
            isNavigable: false,
            component: () => import('@/views/communication/postfach/widgets/PostfachWidgetFax.vue'),
            visible: () => this.hasAccess('/communication/postfach/faxes'),
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      isIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
      isBroker: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_BROKER,
      hasAccess: CORE_TYPES.GETTERS.HAS_ACCESS,
    }),
  },
  methods: {
    handleExecuteAction({ widget }) {
      // doesn't do anything currently, this is just a draft
      if (widget && widget.routeStep) {
        this.$router.push(`/communication/postfach/${widget.routeStep}`)
      }
    },
  },
  beforeRouteLeave (to, from, next) {
    this.$addBreadcrumb({ to, from });
    next()
  },
}
</script>