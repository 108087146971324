import BROKER_SEARCH_TYPES from './types';
import CORE_TYPES from '../core/types';
import WEBRTC_TYPES from '@/store/webrtc/types'
import LOG_TYPES from '@/store/log/types';
import axios from 'axios';
import { makeQueryParam } from '@/helpers/utils-helper';

import { VIEW_ROLES } from '@/router/roles';

function convertLastBrokerOpenedToOpenBroker(broker) {
  return {
    data: {
      person: {
        personalDataAddress: {
          ...broker,
        },
      },
      brokerID: broker.brokerId,
      ...broker,
      _source: 'LAST_BROKERS_OPENED',
    },
  };
}

const config = {
  defaultSpinner: true,
};

export default {
  


  async [BROKER_SEARCH_TYPES.ACTIONS.TRACK_OPEN_BROKER]({ getters, commit, }, { brokerId }) {
    const hasRoles = getters[CORE_TYPES.GETTERS.HAS_ROLES];
    if(!hasRoles([VIEW_ROLES.VIEW_INTERN]) ) {
      return;
    }

    const config = {
      defaultSpinner: true,
    };

    try {

      // saves the last broker opened
      const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/fcconfig/last_brokers_opened`, brokerId, {
        ...config,
        headers: {
          'Content-Type': 'text/plain',
        },
      })

      const lastBrokersOpened = [ ...response?.data || [] ];
      lastBrokersOpened.forEach(broker => {
        commit(BROKER_SEARCH_TYPES.MUTATIONS.UPDATE_LAST_OPENED_BROKER_DATA_ONLY, convertLastBrokerOpenedToOpenBroker(broker));
      });
    } catch (error) {
      // empty block
    }
  },

  async [BROKER_SEARCH_TYPES.ACTIONS.GET_LAST_BROKERS_OPENED]({ getters, commit, }) {
    const hasRoles = getters[CORE_TYPES.GETTERS.HAS_ROLES];
    if(!hasRoles([VIEW_ROLES.VIEW_INTERN])) {
      return;
    }

    const config = {
      defaultSpinner: true,
    };

    try {
      const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/fcconfig/last_brokers_opened`, config)
      const lastBrokersOpened = [ ...response?.data || [] ];
      lastBrokersOpened.forEach(customer => {
        commit(BROKER_SEARCH_TYPES.MUTATIONS.OPEN_BROKER, convertLastBrokerOpenedToOpenBroker(customer));
      });
    } catch (error) {
      // empty block
    }
  },

}
