<template   >
  <div class="event-body" v-if="this.isLoaded">
    <BaseModal
      ref="modal"
      labelButtonCancel="Schließen"
      @onCancelButton="close"
      @onConfirmButton="saveCode()"
      :autoClose="false"
      :showConfirmButton="showConf()"
      :showCancelButton="!this.saving"
      :showCloseButton="!this.saving"
      size="md"
    >
      <template v-slot:modalTitle> </template>
      <BoxContainer>
        <div>
          <DownloadLink
            target="_blank"
            rel="noopener noreferer"
            :title="linkTitle"
            downloadServicePath="/pages_download"
            :queryParams="{
              eingangId: this.id,
              pages: this.pages,
            }"
          />
          <InputField v-model="code" :disabled="this.saving" />
          <ComboBox
            v-if="dat && dat.length > 0"
            :values="dat"
            :firstEmpty="true"
            v-model="code"
            
            @change="onChangeInputField($event)"
            :disabled="this.saving"
          ></ComboBox>
        </div>
      </BoxContainer>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InputField from "@/components/core/forms/InputField.vue";
import BaseModal from "@/components/core/BaseModal.vue";
import V_P_TYPES from "@/store/versandProtokolle/types";
import BoxContainer from "@/components/core/BoxContainer.vue";
import InputTextArea from "@/components/core/forms/InputTextArea.vue";
import DownloadLink from "@/components/core/download/DownloadLink.vue";
import LOG_TYPES from "@/store/log/types";
import { buildMessage } from "@/helpers/log-message-helper";
import ComboBox from "@/components/core/forms/ComboBox.vue";

export default {
  data() {
    return {
      isLoaded: false,
      saving: false,
      code: null,
      id: null,
      dat: null,
      pages: null,
    };
  },
  computed: {
    ...mapGetters({


    }),

    linkTitle() {
      return (
        (this.pages.length == 1 ? " Seite " : " Seiten ") + this.pages.join(",")
      );
    },
  },
  mounted() {},

  methods: {
    async saveCode() {
      try {
        this.saving = true;
        console.log("a");
        let e = {};
        e.eingangId = this.id;
        e.barcode = this.code;
        e.rownums = this.pages;
        await this.$store.dispatch(V_P_TYPES.ACTIONS.SET_BARCODE_PAGES, e);
        let bool = this.$store.getters[V_P_TYPES.GETTERS.ERROR_EDIT];
        console.log("bool", bool);
        if (!bool) {
          this.close();
        }
      } finally {
        this.saving = false;
      }
    },
    showConf() {
      return (
        this.code != null &&
        this.code.trim().length >= 9 &&
        this.code.trim().toLowerCase().substring(0, 2) == "s0" &&
        !this.saving
      );
    },

    async open(i, pages) {
      try {
        this.saving = true;
        this.id = i;
        this.pages = pages;
        await this.loadData();

        this.$refs.modal?.open();
      } finally {
        this.saving = false;
      }
    },
    close() {
      this.$refs.modal.close();
    },

    onChangeInputField($event) {
      this.code = $event;
    },
    async loadData() {
      let par = {};
      par.page = this.pages[0];
      par.id = this.id;
      await this.$store.dispatch(V_P_TYPES.ACTIONS.BARCODES_COMBO, par);
      this.dat =
        this.$store.getters[V_P_TYPES.GETTERS.BARCODES_COMBO].barcodesCombo;
      if (this.$store.getters[V_P_TYPES.GETTERS.BARCODES_COMBO].page) {
        this.code =
          this.$store.getters[V_P_TYPES.GETTERS.BARCODES_COMBO].page.barcode;
      } else {
        this.code = null;
      }
      this.isLoaded = true;
    },
  },
  components: {
    BaseModal,

    InputField,
    InputTextArea,
    DownloadLink,
    BoxContainer,
    ComboBox,
    InputField,
  },
};
</script>

<style scoped>
