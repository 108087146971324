var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "modal",
    attrs: { modalTitle: "Dokumente hinzufügen", showDefaultButtons: false },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function() {
          return [
            _c("MultiFileSelect", {
              model: {
                value: _vm.files,
                callback: function($$v) {
                  _vm.files = $$v
                },
                expression: "files"
              }
            }),
            _c("div", { staticClass: "mb-3" }, [
              _c("div", [
                _vm._v(
                  "Kunde: " +
                    _vm._s(_vm.customer.name) +
                    " (" +
                    _vm._s(_vm.customer.customerID) +
                    ")"
                )
              ]),
              _vm.hasPersonen
                ? _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("InputToggleSwitch", {
                        attrs: { label: "Andere Person", inLineLabel: "" },
                        on: {
                          input: function($event) {
                            return _vm.clearPersonId()
                          }
                        },
                        model: {
                          value: _vm.showPersonen,
                          callback: function($$v) {
                            _vm.showPersonen = $$v
                          },
                          expression: "showPersonen"
                        }
                      }),
                      _vm.showPersonen
                        ? _c("ComboBox", {
                            attrs: { values: _vm.personenValues },
                            model: {
                              value: _vm.form.personId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "personId", $$v)
                              },
                              expression: "form.personId"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ]),
            _c("ComboBox", {
              attrs: {
                label: "Dokumenten-Typ",
                values: _vm.deckblattTypeValues,
                validateUntouched: ""
              },
              on: {
                change: function($event) {
                  return _vm.clearForm()
                }
              },
              model: {
                value: _vm.form.deckblattType,
                callback: function($$v) {
                  _vm.$set(_vm.form, "deckblattType", $$v)
                },
                expression: "form.deckblattType"
              }
            }),
            _vm.form.deckblattType
              ? _c(
                  "div",
                  { key: _vm.form.deckblattType },
                  [
                    _vm.form.deckblattType && _vm.isAllgemeineDokumente
                      ? _c("AllgemeineDokumente", {
                          ref: "internalForm",
                          attrs: {
                            customer: _vm.customer,
                            deckblattType: _vm.form.deckblattType
                          },
                          on: {
                            updateValidation: function($event) {
                              return _vm.updateValidation()
                            },
                            dataChanged: function($event) {
                              return _vm.updateForm($event)
                            }
                          }
                        })
                      : _vm.form.deckblattType === "INVESTMENTFONDS_ORDER" ||
                        _vm.form.deckblattType === "INVESTMENTFONDS_ANTRAG"
                      ? _c("Investmentfonds", {
                          ref: "internalForm",
                          attrs: {
                            customer: _vm.customer,
                            deckblattType: _vm.form.deckblattType
                          },
                          on: {
                            updateValidation: function($event) {
                              return _vm.updateValidation()
                            },
                            dataChanged: function($event) {
                              return _vm.updateForm($event)
                            }
                          }
                        })
                      : _vm.form.deckblattType === "GESCHLOSSENE_FONDS"
                      ? _c("GeschlosseneFonds", {
                          ref: "internalForm",
                          attrs: {
                            customer: _vm.customer,
                            deckblattType: _vm.form.deckblattType
                          },
                          on: {
                            updateValidation: function($event) {
                              return _vm.updateValidation()
                            },
                            dataChanged: function($event) {
                              return _vm.updateForm($event)
                            }
                          }
                        })
                      : _vm.form.deckblattType === "VERMITTLERWECHSEL"
                      ? _c("Vermittlerwechsel", {
                          ref: "internalForm",
                          attrs: {
                            customer: _vm.customer,
                            deckblattType: _vm.form.deckblattType
                          },
                          on: {
                            updateValidation: function($event) {
                              return _vm.updateValidation()
                            },
                            dataChanged: function($event) {
                              return _vm.updateForm($event)
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ]
        },
        proxy: true
      },
      {
        key: "footer",
        fn: function() {
          return [
            _c(
              "BaseButton",
              {
                attrs: { isSecondary: "" },
                on: {
                  click: function($event) {
                    return _vm.close()
                  }
                }
              },
              [_vm._v("Abbrechen")]
            ),
            _c(
              "BaseButton",
              {
                attrs: {
                  disabled: !_vm.hasFiles || _vm.isFormInvalid || _vm.loading
                },
                on: {
                  click: function($event) {
                    return _vm.upload()
                  }
                }
              },
              [
                _vm.loading ? _c("AnimatedSpinner") : _vm._e(),
                _vm._v(" Speichern ")
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }