var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { noPrimaryAction: "", title: "BiPRO GDV-Dateien" }
      }),
      _c("BaseFilter", {
        attrs: {
          title: "BiPRO GDV-Dateien",
          filterId: "BiPRO GDV-Dateien",
          configFilter: _vm.filterConfig,
          metadata: _vm.metadata,
          defaultOptions: _vm.defaultOptions,
          showSaveButton: "",
          immidiateSearch: true
        },
        on: { onFilter: _vm.onFilter }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.loading || !_vm.rows.length
            ? _c("div", [
                _c("span", { staticClass: "box__title" }, [
                  _vm._v(" BiPRO GDV-Dateien ")
                ]),
                _c("br")
              ])
            : _vm._e(),
          _vm.loading
            ? _c("GhostLoading", { attrs: { type: "table" } })
            : _vm.rows.length
            ? _c("BigTable", {
                staticStyle: { clear: "both" },
                attrs: {
                  selectable: "",
                  tableId: "fea3ca15-41a8-430f-85d2-d7dde32b4360",
                  title: "BiPRO GDV-Dateien",
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowsPerPage: 10,
                  page: _vm.page,
                  sortingState: _vm.sortingState,
                  initialPageSize: _vm.minNumRows,
                  headerActions: _vm.headerActions
                },
                on: {
                  onClickHeader: _vm.onClickHeader,
                  selectedRow: _vm.onRowSelection,
                  selectedRows: _vm.onAllRowsSelection,
                  "action-INFO": _vm.info,
                  "action-DELETE": function($event) {
                    return _vm.deleteRows([$event])
                  },
                  "headerAction-MARKIERTE_POSITIONEN_LOESCHEN": function(
                    $event
                  ) {
                    return _vm.deleteRows(_vm.selected)
                  }
                }
              })
            : _c("NoData")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }