var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _c("PageHeaderTitleNavigation", {
            attrs: {
              title: "200 Tage Limit",
              defaultMenu: _vm.optionsMenu,
              actions: _vm.headerActions
            },
            on: {
              "action-REMOVE": function($event) {
                return _vm.loschen()
              },
              "action-RESET": function($event) {
                return _vm.zuruckSetzen()
              }
            }
          }),
          _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("span", { staticClass: "box__title" }, [
                _vm._v(_vm._s(_vm.fondsName))
              ]),
              _c("br"),
              _vm.limits200
                ? [
                    _vm.limits200 && _vm.limits200.id !== null
                      ? [
                          _vm.limits200 &&
                          _vm.limits200.data &&
                          !_vm.limits200.isError
                            ? [
                                _vm._l(_vm.limits200.data.labels, function(
                                  l,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    { key: "row" + index, staticClass: "row" },
                                    [
                                      _c("p", { staticClass: "col-12" }, [
                                        _vm._v(_vm._s(l))
                                      ])
                                    ]
                                  )
                                }),
                                _c("form", [
                                  _c("div", { staticClass: "form-group" }, [
                                    _c("div", { staticClass: "row  mb-2" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-12 col-md-6 col-lg-3"
                                        },
                                        [_vm._v("ISIN")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-12 col-md-6 col-lg-9"
                                        },
                                        [_vm._v(_vm._s(_vm.id))]
                                      )
                                    ]),
                                    _c("div", { staticClass: "row  mb-2" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-12 col-md-6 col-lg-3"
                                        },
                                        [_vm._v("Währung")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-12 col-md-6 col-lg-9"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.limits200.data.currency)
                                          )
                                        ]
                                      )
                                    ]),
                                    _c("div", { staticClass: "row  mb-2" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-12 col-md-6 col-lg-3"
                                        },
                                        [_vm._v("Aktueller Kurs")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-12 col-md-6 col-lg-9"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  _vm.limits200.data.kurs
                                                )
                                              )
                                          )
                                        ]
                                      )
                                    ]),
                                    _c("div", { staticClass: "row  mb-2" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-12 col-md-6 col-lg-3"
                                        },
                                        [_vm._v("Kursdatum")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-12 col-md-6 col-lg-9"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.limits200.data.kursDate)
                                          )
                                        ]
                                      )
                                    ]),
                                    _c("div", { staticClass: "row  mb-2" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-12 col-md-6 col-lg-3"
                                        },
                                        [_vm._v("Aktueller Wert GD200")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-12 col-md-6 col-lg-9"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("currency")(
                                                  _vm.limits200.data.value200
                                                )
                                              )
                                          )
                                        ]
                                      )
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "row  mb-2" },
                                      [
                                        _c("InputField", {
                                          attrs: {
                                            label:
                                              "Abweichung nach unten - prozentual (relativ)",
                                            type: "percent"
                                          },
                                          model: {
                                            value: _vm.low,
                                            callback: function($$v) {
                                              _vm.low = $$v
                                            },
                                            expression: "low"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "row mb-2" },
                                      [
                                        _c("InputField", {
                                          attrs: {
                                            label:
                                              "Abweichung nach oben - prozentual (relativ)",
                                            type: "percent"
                                          },
                                          model: {
                                            value: _vm.high,
                                            callback: function($$v) {
                                              _vm.high = $$v
                                            },
                                            expression: "high"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm.limits200.data.changeDate
                                      ? _c(
                                          "div",
                                          { staticClass: "row  mb-2" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-12 col-md-6 col-lg-3"
                                              },
                                              [_vm._v("Letzte Änderung")]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-12 col-md-6 col-lg-9"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.limits200.data
                                                      .changeDate
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.limits200.data.dateOutofLimit
                                      ? _c(
                                          "div",
                                          { staticClass: "row  mb-2" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-12 col-md-6 col-lg-3"
                                              },
                                              [
                                                _vm._v(
                                                  "Datum der Überschreitung"
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-12 col-md-6 col-lg-9"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.limits200.data
                                                      .dateOutofLimit
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.isBroker || _vm.isByPass
                                      ? _c(
                                          "div",
                                          { staticClass: "row  mb-2 w-311" },
                                          [
                                            _c("InputTextArea", {
                                              attrs: { label: "Bemerkung" },
                                              model: {
                                                value: _vm.comment,
                                                callback: function($$v) {
                                                  _vm.comment = $$v
                                                },
                                                expression: "comment"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ])
                                ])
                              ]
                            : _vm._e()
                        ]
                      : _vm._e()
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      ),
      _c("BaseModal", {
        ref: "modalRef",
        attrs: {
          modalTitle: "Limit löschen?",
          labelButtonConfirm: "Ja",
          labelButtonCancel: "Nein"
        },
        on: {
          onConfirmButton: function($event) {
            return _vm.action(true)
          },
          onCancelButton: function($event) {
            return _vm.action(false)
          }
        }
      }),
      _c("LimitErrorModal", {
        ref: "modalError",
        attrs: { errorText: _vm.errorFromService },
        on: { closeLimit: _vm.confirmError, resetError: _vm.resetError }
      }),
      _c("BaseModal", {
        ref: "saveOrReset",
        attrs: {
          labelButtonCancel: "Verwerfen",
          labelButtonConfirm: "Speichern"
        },
        on: { onCancelButton: _vm.handleReset, onConfirmButton: _vm.speichern },
        scopedSlots: _vm._u([
          {
            key: "modalTitle",
            fn: function() {
              return [_vm._v(" Möchte Sie Ihre Änderungen speichern? ")]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }