import Vue from 'vue';
import BrowserSupport from '@/browser-support';

const MOUSE_EVENT_OPTIONS = BrowserSupport.supportsPassive ? { passive: true, capture: false, } : false;
const MOUSE_EVENT_NO_PASSIVE_OPTIONS = BrowserSupport.supportsPassive ? { passive: false, capture: false, } : false;

const DRAG_START_DELAY = 220;

const DEFAULT_GROUP = 'default';
const LEFT_MOUSE_KEY = 1;
const AUTO_SCROLL_TOP_DIR = 'top';
const AUTO_SCROLL_BOTTOM_DIR = 'bottom';
const AUTO_SCROLL_INTERVAL = 2.5;
const DEFAULT_OFFSET = 16;

const DROPPED_EVENT_NAME = 'dropped';
const DROPPED_INTERN_EVENT_NAME = 'droppedIntern';

const DRAGGING_CLASS = 'dragging';
const DRAGGING_ACTIVE_CLASS = 'dragging-active';
const ACTIVE_CLASS = 'active';
const AVAILABLE_TARGET_CLASS = 'available-target';
const DRAGGING_ELEMENT_CLASS = 'dragging-element';

const DATA_DRAGGABLE_SOURCE_ATTR = 'data-draggable-source';
const DATA_DRAGGABLE_SOURCE_SELECTOR = `[${DATA_DRAGGABLE_SOURCE_ATTR}]`;

const DATA_DRAGGABLE_TARGET_ATTR = 'data-draggable-target';
const DATA_DRAGGABLE_TARGET_SELECTOR = `[${DATA_DRAGGABLE_TARGET_ATTR}]`;
const DATA_DRAGGABLE_TARGET_BY_GROUP_SELECTOR = `[${DATA_DRAGGABLE_TARGET_ATTR}="@group@"]`;
const DATA_DRAGGABLE_TARGET_ACTIVE_SELECTOR = `${DATA_DRAGGABLE_TARGET_SELECTOR}.${ACTIVE_CLASS}`;
const targetByGroupSelector = group => DATA_DRAGGABLE_TARGET_BY_GROUP_SELECTOR.replace('@group@', group);

const DEFAULT_IGNORED_TAGS = ['a', 'button'];
const isAnIgnoredTag = el => {
  // is it a ignored tag name or a child of a ignored tag name?
  return DEFAULT_IGNORED_TAGS.includes(el?.tagName?.toLowerCase()) || !!el?.closest(DEFAULT_IGNORED_TAGS.join(','));
};

/**
 * Draggable simple object
 * 
 * @param {*} value 
 * @param {*} group 
 * @returns 
 */
function draggable(value, group) {
  return { 
    value,
    group,
  };
}

/**
 * Define a draggable object value, text and group
 * 
 * @param {*} value 
 * @param {*} text 
 * @param {*} group 
 * @returns 
 */
export function Draggable(value, text, group) {
  return {
    ...draggable(value, group),
    text,
  };
}

/**
 * Define a droppable object value and group
 * 
 * @param {*} value 
 * @param {*} group 
 * @returns 
 */
export function Droppable(value, group) {
  return {
    ...draggable(value, group),
  };
}

// DraggableComponent
const DraggableComponent = Vue.extend({
  template: `<div>{{ text }}</div>`,
  props: {
    text: {},
  },
});

const getGroup = (group) => group || DEFAULT_GROUP;

const dispatchCustomEvent = (eventName, el, detail = {}) => {
  if(!eventName || !el) return;

  const customEvent = new CustomEvent(eventName, { detail, });
  el.dispatchEvent(customEvent);
};

const eventCoord = (event, coord) => {
  let eventCoords = event;

  if(event.targetTouches && event.targetTouches.length) {
    eventCoords = event.targetTouches[0];
  } else if(event.changedTouches && event.changedTouches.length) {
    eventCoords = event.changedTouches[0];
  }

  return eventCoords[coord];
};

const preventSelection = (event) => {
  event.preventDefault();
  event.stopPropagation();
};

const parentScroll = (node) => {
  if(node === null) return null;
  return node.scrollHeight > node.clientHeight ? node : parentScroll(node.parentNode);
};

const isSameGroup = (sourceEl, targetEl) => sourceEl?.dataset?.draggableSource && sourceEl.dataset.draggableSource === targetEl?.dataset?.draggableTarget;

const setDraggedPosition = (draggedEl, clientX, clientY, offsetX, offsetY) => {
  if(!draggedEl) return;

  draggedEl.style.cssText = `
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    transform: translate(${clientX - offsetX}px, ${clientY - offsetY}px);
  `;
};

let autoScrollVerticalInterval = null;
let lastAutoVerticalScrollDirection = undefined;
const autoScrollVertical = (scrollEl, clientY) => {
  if(!scrollEl) return;

  const autoScroll = (direction) => {
    if(lastAutoVerticalScrollDirection === direction) return;
    cancelAutoScrollVertical();

    lastAutoVerticalScrollDirection = direction;
    autoScrollVerticalInterval = setInterval(() => {
      const top = scrollEl.scrollTop + (direction === AUTO_SCROLL_TOP_DIR ? -1 : 1);
      scrollEl.scroll(0, top);
    }, AUTO_SCROLL_INTERVAL);
  };

  const scrollBounding = scrollEl.getBoundingClientRect();
  const scrollElY = scrollBounding.y >= 0 ? scrollBounding.y : 0;
  const scrollElHeight = scrollEl.clientHeight;
  const scrollOffsetTop = (scrollElHeight / 100) * 5; // 5% of element height

  const scrollTopLimitSize = (scrollElY + scrollOffsetTop);
  const scrollBottomLimitSize = (scrollElY + scrollElHeight) - scrollOffsetTop;

  if(clientY < scrollTopLimitSize) {
    autoScroll(AUTO_SCROLL_TOP_DIR);
  } else if(clientY > scrollBottomLimitSize) {
    autoScroll(AUTO_SCROLL_BOTTOM_DIR);
  } else {
    cancelAutoScrollVertical();
  }
};

const cancelAutoScrollVertical = () => {
  lastAutoVerticalScrollDirection = undefined;
  clearInterval(autoScrollVerticalInterval);
};

function drag(event) {
  const state = this;
  const { draggedEl, parentScrollEl, offsetX, offsetY, } = state;

  // set current position
  const clientX = eventCoord(event, 'clientX');
  const clientY = eventCoord(event, 'clientY');
  setDraggedPosition(draggedEl, clientX, clientY, offsetX, offsetY);
  autoScrollVertical(parentScrollEl, clientY);

  // set current hover element
  draggedEl.style.display = 'none';
  const currentHoverEl = document.elementFromPoint(clientX, clientY);
  draggedEl.style.display = '';

  // set active class to current target element
  const currentTargetEl = currentHoverEl?.closest(DATA_DRAGGABLE_TARGET_SELECTOR) || currentHoverEl;
  document.querySelectorAll(DATA_DRAGGABLE_TARGET_SELECTOR).forEach(el => el !== currentTargetEl && el.classList.remove(ACTIVE_CLASS));
  if(isSameGroup(draggedEl, currentTargetEl)) {
    currentTargetEl.classList.add(ACTIVE_CLASS);
  }
}

function dragEnd() {
  const state = this;
  const { draggableComponentInstance, draggedEl, sourceEl, } = state;
  const { _draggableSource, } = sourceEl;
  const sourceValue = _draggableSource?.value;
  const group = draggedEl.dataset.draggableSource;

  // check active and dispatch event
  const dropTargetEl = document.querySelector(DATA_DRAGGABLE_TARGET_ACTIVE_SELECTOR);
  if(isSameGroup(draggedEl, dropTargetEl)) {
    dispatchCustomEvent(DROPPED_INTERN_EVENT_NAME, dropTargetEl, { value: { ...sourceValue, }, });
  }

  // remove classes
  sourceEl.classList.remove(DRAGGING_ACTIVE_CLASS);
  document.querySelectorAll(DATA_DRAGGABLE_TARGET_SELECTOR).forEach(el => el.classList.remove(ACTIVE_CLASS));
  document.querySelectorAll(targetByGroupSelector(group)).forEach(targetEl => targetEl.classList.remove(AVAILABLE_TARGET_CLASS))


  // destroy draggable component
  draggableComponentInstance.$destroy();

  // body - remove element and class
  document.querySelector('body').removeChild(draggedEl);
  document.querySelector('body').classList.remove(DRAGGING_ELEMENT_CLASS);

  // remove events
  document.removeEventListener('mousemove', state.drag, MOUSE_EVENT_NO_PASSIVE_OPTIONS);
  document.removeEventListener('mouseup', state.dragEnd, MOUSE_EVENT_OPTIONS);
  document.removeEventListener('selectstart', preventSelection, MOUSE_EVENT_NO_PASSIVE_OPTIONS);

  // cancel auto scroll
  cancelAutoScrollVertical();
}

function dragStart(event) {
  if(event.which !== LEFT_MOUSE_KEY) return;

  const sourceEl = event.target.closest(DATA_DRAGGABLE_SOURCE_SELECTOR) || event.target;
  if(!sourceEl) return;

  const { _draggableSource, } = sourceEl;
  const group = sourceEl.dataset.draggableSource;

  // create dragged element
  const draggedEl = document.createElement('div');
  draggedEl.dataset.draggableSource = group;

  // mount draggable component
  const propsData = { ..._draggableSource, };
  const draggableComponentInstance = new DraggableComponent({ propsData, }).$mount();

  // append draggable component
  draggedEl.appendChild(draggableComponentInstance.$el);

  // set position
  const clientX = eventCoord(event, 'clientX');
  const clientY = eventCoord(event, 'clientY');
  const offsetX = DEFAULT_OFFSET;
  const offsetY = DEFAULT_OFFSET;
  setDraggedPosition(draggedEl, clientX, clientY, offsetX, offsetY);

  // create a state
  const parentScrollEl = parentScroll(sourceEl);
  const state = {
    draggableComponentInstance,
    parentScrollEl,
    draggedEl,
    sourceEl,
    offsetX,
    offsetY,
  };
  state.drag = drag.bind(state);
  state.dragEnd = dragEnd.bind(state);

  // set classes
  draggedEl.classList.add(DRAGGING_CLASS);
  sourceEl.classList.add(DRAGGING_ACTIVE_CLASS);
  document.querySelectorAll(targetByGroupSelector(group)).forEach(targetEl => targetEl.classList.add(AVAILABLE_TARGET_CLASS));

  // body - add new element and class
  document.querySelector('body').appendChild(draggedEl);
  document.querySelector('body').classList.add(DRAGGING_ELEMENT_CLASS);

  // add events
  document.addEventListener('mousemove', state.drag, MOUSE_EVENT_NO_PASSIVE_OPTIONS);
  document.addEventListener('mouseup', state.dragEnd, MOUSE_EVENT_OPTIONS);
  document.addEventListener('selectstart', preventSelection, MOUSE_EVENT_NO_PASSIVE_OPTIONS);
}

const dispatchDropped = (event) => {
  const sourceValue = event?.detail?.value;
  const { _draggableTarget, } = event.target;
  const targetValue = _draggableTarget?.value;

  dispatchCustomEvent(DROPPED_EVENT_NAME, event.target, {
    source: { ...sourceValue || {}, },
    target: { ...targetValue || {}, },
  });
};

let delayTimer = null;
function cancelDragStartDelay() {
  clearTimeout(delayTimer);
  document.removeEventListener('mouseup', cancelDragStartDelay, MOUSE_EVENT_OPTIONS);
  document.removeEventListener('selectstart', preventSelection, MOUSE_EVENT_NO_PASSIVE_OPTIONS);
}
function dragStartDelay(event) {
  if(isAnIgnoredTag(event.target)) return;

  clearTimeout(delayTimer);
  delayTimer = setTimeout(() => {
    cancelDragStartDelay();
    dragStart(event);
  }, DRAG_START_DELAY);
  document.addEventListener('mouseup', cancelDragStartDelay, MOUSE_EVENT_OPTIONS);
  document.addEventListener('selectstart', preventSelection, MOUSE_EVENT_NO_PASSIVE_OPTIONS);
}

const draggableSourceInit = (el, binding) => {
  if(binding?.value?.value) {
    el.dataset.draggableSource = getGroup(binding?.value?.group);
    el._draggableSource = {
      value: { ...binding?.value?.value, },
      text: binding?.value?.text,
    };

    el.removeEventListener('mousedown', dragStartDelay, MOUSE_EVENT_NO_PASSIVE_OPTIONS);
    el.addEventListener('mousedown', dragStartDelay, MOUSE_EVENT_NO_PASSIVE_OPTIONS);
  } else {
    delete el._draggableSource;
    delete el.dataset.draggableSource;
    el.removeEventListener('mousedown', dragStartDelay, MOUSE_EVENT_NO_PASSIVE_OPTIONS);
  }
};
Vue.directive('draggable-source', {
  bind(el, binding) {
    draggableSourceInit(el, binding);
  },
  update(el, binding) {
    draggableSourceInit(el, binding);
  },
});

const draggableTargetInit = (el, binding) => {
  if(binding?.value?.value) {
    el.dataset.draggableTarget = getGroup(binding?.value?.group);
    el._draggableTarget = { 
      value: { ...binding?.value?.value, },
    };

    el.removeEventListener(DROPPED_INTERN_EVENT_NAME, dispatchDropped);
    el.addEventListener(DROPPED_INTERN_EVENT_NAME, dispatchDropped);
  } else {
    delete el.dataset.draggableTarget;
    delete el._draggableTarget;

    el.removeEventListener(DROPPED_INTERN_EVENT_NAME, dispatchDropped);
  }
};
Vue.directive('draggable-target', {
  bind(el, binding) {
    draggableTargetInit(el, binding);
  },
  update(el, binding) {
    draggableTargetInit(el, binding);
  },
});
