<template>
  <div>
    <PageHeaderTitleNavigation title="Sipgate"
      :actions="headerActions"
      @action-ADD="addAccount()"
    />

    <div class="box__container">
      <Table v-if="!loading && records && records.length && records.length>0" tableId="15b90920-ebdd-11ec-8ea0-0242ac120002" :title="TABLE_TITLE"
        :headers="headers" :rows="rows" :rowsPerPage="1000" @action-DELETE="openDeleteDocumentConfirmModal">
      </Table>
      <GhostLoading v-else-if="loading" type="table" :title="TABLE_TITLE" />
      <NoData v-else :title="TABLE_TITLE" />
    </div>

    <BaseModal ref="deleteDocumentConfirmModal" modalTitle="Löschen" labelButtonConfirm="Löschen"
      @onCloseButton="cancelDeleteDocument()" @onCancelButton="cancelDeleteDocument()"
      @onEscButton="cancelDeleteDocument()" @onConfirmButton="deleteDocument()"><template #default>
        Wollen Sie Sipgate Account löschen?
      </template>
    </BaseModal>


    <BaseModal ref="insertAccountModal" modalTitle="Hinzufügen" labelButtonConfirm="Hinzufügen"
      @onCloseButton="cancelInsert" @onConfirmButton="insertAccount"><template #default>
        <InputField label="E-MAIL" v-model="account.email" />
        <InputField label="Personal Access TOKEN_ID" v-model="account.personalAccessId" />
        <InputField label="Personal Access TOKEN" v-model="account.personalAccesToken" />
      </template>
    </BaseModal>






  </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { mapGetters } from "vuex";
import Table from "@/components/table2/Table.vue";
import TYPES from "@/store/sipgate/types";
import BaseModal from '@/components/core/BaseModal.vue';
import InputField from "@/components/core/forms/InputField.vue"
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';

import {
  TextColumn,
  ActionColumn,
  SimpleAction,

} from "@/components/table2/table_util.js";

import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

const TABLE_TITLE = 'Sipgate Liste';

export default {
  components: {

    OptionMenu,
    PageHeaderTitleNavigation,
    Table,
    InputField,
    BaseModal,
    GhostLoading,
    NoData,
  },
  data() {
    return {
      TABLE_TITLE,
      loading: false,

      documentToDelete: null,
      account: null

    };
  },
  computed: {
    ...mapGetters({
      records: TYPES.GETTERS.FILTER,

    }),
    headers() {
      return {

        lockedLeft: [
          TextColumn("email", "Email"),
          TextColumn("lastFetch", "Letzte Ausführung"),
          TextColumn("personalAccessId", "Personal Access TOKEN_ID"),


        ] /* filter((column) => !this.disabledColumns.includes(column.key))*/,

        lockedRight: [ActionColumn("actions", "Aktionen")] /*.filter(
          (column) => !this.disabledColumns.includes(column.key)
        )*/,
      };
    },
    rows() {
      if (!this.records) return [];
      return this.records.map((record) => {
        const row = {
          ...record,
        };


        if (record.actions) {
          row.actions = record.actions.map((legend) => {
            return SimpleAction(legend.key, legend.icon, legend.label);
          });
        }
        return row;
      });
    },

    headerActions() {
      return [
        PageHeaderSimpleAction('ADD', 'Hinzufügen'),
      ];
    },
  },

  mounted() {
    this.doList();
  },

  methods: {
    async doList() {
      try {
        this.loading = true;
        await this.$store.dispatch(TYPES.ACTIONS.FILTER);
      } finally {
        this.loading = false;
      }
    },
    openDeleteDocumentConfirmModal(row) {
      this.documentToDelete = row.email;
      this.$refs.deleteDocumentConfirmModal.open();
    },
    addAccount() {
      this.account = {
        email: null,
        personalAccessId: null,
        personalAccesToken: null
      }
      this.$refs.insertAccountModal.open();

    },
    cancelDeleteDocument() {
      this.documentToDelete = null;
    },
    async insertAccount() {
      console.log(this.account)
      await this.$store.dispatch(TYPES.ACTIONS.INSERT, this.account);
      this.account = null;
    },
    async deleteDocument() {

      await this.$store.dispatch(TYPES.ACTIONS.DELETE, this.documentToDelete);
      this.documentToDelete = null;
    },
    cancelInsert() {
      this.documentToDelete = null;
    }
  }
}


</script>
<style scoped>
.positionDivider {
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
}
</style>