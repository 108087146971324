<template>
  <div class="breadcrumb__navigation">
    <div class="breadcrumb__navigation__item wide__screen">
      <template v-for="(node, index) in navigationArray">
        <div v-if="!(ignoreFirst && index === 0)" :class="index == 0 ? 'first__label' : ''" :key="index" @click="selectNavigationNode(node)">
          {{concatenatedLabel(node.label, index)}}&nbsp;
        </div>
      </template>
    </div>
  </div>
  
</template>

<script>
export default {
  props: {
    navigationArray: {
      default: [{ label: "Dokumentenarchiv" }]
    },
    ignoreFirst: {
      type: Boolean,
      default: false,
    },
  },
  data: function() {
    return {
      selectedNode : null

    }
  },
  methods: {
    concatenatedLabel(label, index) {
      return `${label} ${index < this.navigationArray.length - 1 ? ' |' : ''}`
    },
    selectNavigationNode(whatNode) {
      this.selectedNode = whatNode;
      if (this.navigationArray.length>0) {
        this.removeAboveCurrentFolder();
      }
      this.$emit("select-navigation-node", whatNode);
    },
    removeAboveCurrentFolder() {
      const index = this.navigationArray
        .map(function(node) {
          return node.label;
        })
        .indexOf(this.selectedNode.label);

      if (index > -1 && this.navigationArray.length > 1) {
        this.navigationArray.splice(index+1, this.navigationArray.length);
      }
    },
    uuidv4() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(
        c
      ) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },
  }
};
</script>

<style scoped>

.breadcrumb__navigation {
  flex-grow: 0;
}

.breadcrumb__navigation__item {
  display: flex;
  cursor: pointer;
  font-size: 1.1em;
  flex-wrap: wrap;
}

.first__label {
  color: var(--color-text);
  font-size: 1.2rem;
  font-weight: 500;
}

</style>
