<template>
  <div class="login-container" v-if="isReady">
    <div v-if=isChristmasSeason>
      <!-- Only for Christmas Season -->
      <div class="snowflake">❅</div>
      <div class="snowflake">❅</div>
      <div class="snowflake">❆</div>
      <div class="snowflake">❄</div>
      <div class="snowflake">❅</div>
      <div class="snowflake">❆</div>
      <div class="snowflake">❄</div>
      <div class="snowflake">❅</div>
      <div class="snowflake">❆</div>
      <div class="snowflake">❄</div>
    </div>
    <HeaderLogin class="login-header" />
    <div data-app-content class="login-content" :style="{ backgroundImage: loginContentBackground }">
      <div class="login-logo-box">
          <img :src="logoLoginBoxBackground"/>
      </div>
      <div class="login-box">
        <hr class="login-logo__bottom--spacer"/>
        <div class="login-logo__box-title">
          {{loginWelcomeTitle}}
        </div>
        <form v-if="showNativeMeansOfLogin" class="login__form" key="nativeMeansOfLoginForm">
          <div v-if="mfaChallengeId != null" class="mb-3">
                {{mfaChallengeHint}}
          </div>
          <div class="login__form--auto-login-buttons">
            <div class="login__form--auto-login-button__container">
              <BaseButton 
                isLink
                @click="showRegularCredentialFields()">
                Mit Passwort anmelden
              </BaseButton>
            </div>

            <div class="login__form--auto-login-button__container">
              <BaseButton 
                v-if="loadedLoginData.loginName"
                isClear
                :disabled="hasLoginInProgress || isLoggedIn"
                @click="loadHashedPassword" 
                :title="authenticationTypeLabel"
                class="button-auth"
              >
                <AnimatedSpinner v-if="hasLoginInProgress || isLoggedIn" class="button-auth--spinner"/>
                <NativeAuthIcon class="button-auth--icon"/>
              </BaseButton>
            </div>
          </div> 
          <DownloadAppInfo 
            v-if="mfaError" 
            :errors="mfaError" :appName="mfaAppName" 
            :urlIOS="mfaUrlIOs" :urlAndroid="mfaUrlAndroid" 
            class="mt-3"
          />
        </form>
        <form v-else v-on:submit.prevent="login" class="login__form" key="loginForm">
            <div v-if="mfaChallengeId != null" class="mb-3">
                <div>
                  {{mfaChallengeHint}}
                </div>
                <InputField
                  v-if="confirm2FA && mfaType === MFA_TYPE_CODE_SMS"
                  label="SMS Sicherheitscode"
                  v-model="loginForm.mfaCode"
                  inputmode="numeric" 
                  trimValue
                  autofocus
                  class="login__form--input"
                  placeholder="SMS Sicherheitscode"
                  autocomplete="one-time-code"
                />
            </div>
            <template v-else>
                <InputField
                  id="username"
                  label="Loginnummer oder E-Mail"
                  v-model="loginForm.username"
                  class="login__form--input"
                  placeholder="Zugangsnummer oder E-Mail"
                  autocomplete="username"
                  autofocus/>
                <InputField
                  id="password"
                  label="Passwort"
                  type="password"
                  allowPlainPassword
                  v-model="loginForm.password"
                  class="login__form--input"
                  autocomplete="current-password"
                  enterkeyhint="send"
                  placeholder="Passwort" />
                <InputField
                  v-if="confirm2FA && mfaChallengeId == null"
                  label="Sicherheitscode"
                  v-model="loginForm.factor2"
                  class="login__form--input"
                  autocomplete="one-time-code"
                  placeholder="Sicherheitscode"/>
            </template>

            <DownloadAppInfo 
              v-if="mfaError && mfaUrlIOs && mfaUrlAndroid"
              class="mb-3"
              :errors="mfaError" :appName="mfaAppName" 
              :urlIOS="mfaUrlIOs" :urlAndroid="mfaUrlAndroid" 
            />

          <div class="login__form--buttons" :class="{ 'has-no-button-native-login': !showFieldsForNativeMeansOfLoginToggle, }">
            <div class="login__form--button login__form--button-login">
              <BaseButton
                :disabled="hasLoginInProgress || isLoggedIn || isMfaSmsCodeInvalid"
                type="submit" 
                isPrimary>
                  <AnimatedSpinner v-if="hasLoginInProgress || isLoggedIn"/>
                  Anmelden
              </BaseButton>
              <BaseButton 
                v-if="!hasLoginInProgress && mfaType === MFA_TYPE_CODE_SMS" 
                isSecondary class="ml-3" @click="resetMfaData()">Abbrechen</BaseButton>
            </div>

            <template v-if="!hasLoginInProgress && !mfaChallengeId">
              <div v-if="showFieldsForNativeMeansOfLoginToggle" class="login__form--button login__form--button-native-login">
                  <BaseButton isLink @click="showFieldsForNativeMeansOfLogin = true">{{ authenticationTypeLabel }}</BaseButton>
              </div>
              <div class="login__form--button login__form--button-forgot-password">
                  <BaseButton isLink @click="recoverPassword()">Passwort zurücksetzen/anfordern</BaseButton>
              </div>
              <template v-if="showKundeLoginSelfRegistration">
                <div class="login__form--new-account-divider"></div>
                <div v-if="showRegistrationButton" class="login__form--button login__form--button-register">
                    <BaseButton isLink @click="goToRegistration()">Neues Konto erstellen</BaseButton>
                </div>
              </template>
            </template>
          </div>
        </form>
      </div>
      <div class="call-modal-webrtc-gap" v-if="showCallModalWebrtcGap">
        &nbsp;
      </div>
    </div>
  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import LOG_TYPES from '@/store/log/types';
import BRIDGE_TYPES from '@/store/bridge/types';
import WEBRTC_TYPES from '@/store/webrtc/types';
import BaseButton from '@/components/core/BaseButton.vue';
import InputField from '@/components/core/forms/InputField.vue';
import validator from '@/mixins/validator';
import { required } from '@/mixins/validator/rules';
import { mapGetters } from 'vuex';
import { removeItem } from '@/helpers/local-storage-helper';
import { buildMessage } from '@/helpers/log-message-helper';
import HeaderLogin from '@/components/core/login/HeaderLogin.vue'
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue'
import { loadDefaultColorsScheme } from '../../configs/color-config';
import dayjs from 'dayjs';
import NativeAuthIcon from '@/components/icons/NativeAuthIcon.vue'
import DownloadAppInfo from '@/components/core/DownloadAppInfo.vue';

import { findUserId, findLoginNextUrl } from '@/router/features-routes/login';

const MFA_TYPE_CODE_SMS = 'CODE_SMS';

function waitUntilImageBeLoaded(imageURL) {
  return new Promise((resolve) => {
    if(imageURL) {
      var image = document.createElement('img');
      image.onload = () => setTimeout(resolve, 500); // delayed to resolve when an image is loaded, it avoids incomplete rendered images when calling the "ready-to-screenshot-start-page" action
      image.onerror = () => resolve(); // must resolve even on error to avoid block the code if receive a 404 code
      image.src = imageURL;
    } else {
      resolve();
    }
  });
}

function waitUntilImagesBeLoaded(imagesURL) {
  return Promise.all(imagesURL.map(imageURL => waitUntilImageBeLoaded(imageURL)));
}

export default {
    mixins: [validator],
    data() {
        return {
            MFA_TYPE_CODE_SMS,
            loginLoading: false,
            appReturnedFromBackground: false,
            mfaAppName: "FinanceIdent",
            mfaChallengeId: null,
            mfaChallengeHint:null,
            mfaSessionSecurityToken: null,
            mfaType: null,
            mfaError: null,
            mfaUrlAndroid: null,
            mfaUrlIOs: null,
            showFieldsForNativeMeansOfLogin: false, // initially set as false. it should be set to true after startpage screenshot
            loginForm: {
                username: '',
                password: '',
                factor2: '',
                appLoginToken: null,
                mfaCode: null,
            },
            isMounted: false,
            showRegistrationButton: !window.location.host.toLowerCase().includes("finanzadmin"),
        }
    },

    validators: {
        loginForm: {
            username: [required('Bitte geben Sie Ihre Zugangsdaten ein')],
            password: [required('Bitte geben Sie Ihr Passwort ein')]
        }
    },

    methods: {
        login() {
            this.loginLoading = true
            const loginPayload = { 
                userid: this.loginForm.username?.trim(), 
                password: this.loginForm.mfaCode ? null : this.loginForm.password, // do not send password when mfaCode is provided
                appLoginToken: this.loginForm.password ? null : this.loginForm.appLoginToken,
                mfaCode: this.loginForm.mfaCode?.trim(),
                mfaType: this.mfaType,
                //factor2: this.confirm2FA ? this.loginForm.factor2 : null,
                confirm2FA: this.confirm2FA,
                mfaChallengeId: this.mfaChallengeId,
                mfaSessionSecurityToken: this.mfaSessionSecurityToken,
                nextUrl: this.nextUrl,
                callbackToken: this.callbackToken,
                callbackURL: this.callbackURL,
            };

            this.$store.dispatch(CORE_TYPES.ACTIONS.LOGIN, loginPayload)
            .then(responseData => {
                this.handleLoginResponse(responseData);
            }).catch(err => {
                this.loginLoading = false;
                this.resetMfaData();
            });
        },
        handleLoginResponse(responseData) {
            this.loginLoading = false;
            this.resetMfaData();
            if (responseData) {
                if (responseData.NEED_FACTOR_2) {
                    // login incomplete, request user to confirm factor2
                    this.$store.commit(CORE_TYPES.MUTATIONS.SET_CONFIRM_2FA, true);
                    this.mfaChallengeId = responseData.mfaChallengeId;
                    this.mfaChallengeHint = responseData.mfaChallengeHint;
                    this.mfaSessionSecurityToken = responseData.mfaSessionSecurityToken;
                    this.mfaType = responseData.mfaType;
                    if (this.mfaChallengeId != null && this.mfaType === 'APP') {
                        // create new request that contains the challengeId and succeeds as soon as the user
                        // confirms the login in the MFA-App
                        this.login();
                    }
                } else {
                    // login failed
                    if (responseData.ANDROID_URL) {
                        this.mfaAppName = responseData.APP_NAME;
                        this.mfaError = responseData.message.split("\n");
                        this.mfaUrlAndroid = responseData.ANDROID_URL;
                        this.mfaUrlIOs = responseData.IOS_URL;
                    } else {
                        const message = responseData.message || 'Es ist ein Fehler aufgetreten.';
                        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(message, 'danger'));
                    }
                }
            }
        },
    resetMfaData() {
      this.$store.commit(CORE_TYPES.MUTATIONS.SET_CONFIRM_2FA, false);

      this.$set(this.loginForm, 'factor2', '');
      this.$set(this.loginForm, 'mfaCode', null);

      this.mfaType = null;
      this.mfaUrlAndroid = null;
      this.mfaUrlIOs = null;
      this.mfaError = null;
      this.mfaChallengeId = null;
      this.mfaChallengeHint = null;
      this.mfaSessionSecurityToken = null;
    },
    async automaticLogin() {
      if(!this.hasMobileDataForNativeMeansOfLogin || this.loginLoading || this.isLoggedIn || this.confirm2FA) return;

      const { logout=false, logoutTimeout=false } = this.$route.query || {};
      const { appReturnedFromBackground, } = this;
      const force = appReturnedFromBackground === true || logoutTimeout+'' === 'true';
      if(!force && (!this.showNativeMeansOfLogin || logout+'' === 'true')) return;

      await this.loadHashedPassword();
    },
    async loadHashedPassword() {
      if (this.loadedLoginData?.loginName) {
        const hashedPassword = await this.$store.dispatch(BRIDGE_TYPES.ACTIONS.LOAD_HASHED_PASSWORD, { loginName: this.loadedLoginData.loginName })

        if (hashedPassword) {
          this.loginForm.username = this.loadedLoginData.loginName
          this.loginForm.appLoginToken = hashedPassword
          this.login()
        } else {
          this.showRegularCredentialFields()
        }
      }
    },
    showRegularCredentialFields() {
      this.loginForm.username = this.loadedLoginData.loginName || ''
      this.loginForm.password = ''
      this.showFieldsForNativeMeansOfLogin = false

      this.$nextTick(() => {
        if (this.loginForm.username) {
          this.$refs.password?.focus()
        } else {
          this.$refs.username?.focus()
        }
      })
    },
    goToRegistration() {
      const maklernr = this.$route.query.id || this.$route.params.broker_id || this.$route.query.maklernr;

      this.$router.push({
        path: '/login/register',
        query: {
          maklernr,
        },
      });
    },
    recoverPassword() {
      this.$router.push({
        path: '/login/forgotpassword'
      });
    },
    openNextUrlIfIsLoggedIn() {
        if(this.isLoggedIn && this.nextUrl) {
            this.$router.push(this.nextUrl);
            return true;
        }
        return false;
    },
    async onReady() {
      if(!this.isReady) {

        if (!this.hasColorSchema) { // if it's not ready, might be missing the colorSchema
          loadDefaultColorsScheme(); 
        }

        return;
      }

      await waitUntilImagesBeLoaded([this.loginContentBackgroundImage, this.logoLoginBoxBackground]);
      await this.$store.dispatch(BRIDGE_TYPES.ACTIONS.LOAD_LOGIN_DATA);
      requestAnimationFrame(async () => { // waits for the page to be completely rendered
        if (this.isInitialAppState) {
          await this.$store.dispatch(BRIDGE_TYPES.ACTIONS.READY_TO_SCREENSHOT_START_PAGE);
          this.$store.commit(CORE_TYPES.MUTATIONS.MARK_INITIAL_APP_LOADED)
        }
        this.showFieldsForNativeMeansOfLogin = true
  
        if (this.loadedLoginData) {
          await this.automaticLogin();
        }

        this.handleDeferredDeeplink();
      });
    },
    handlerDeepLinkNeedFactor2(sitzungID, token) {
        const needFactor2 = this.$route.query.NEED_FACTOR_2?.toLowerCase?.() === 'true'

        if (needFactor2 && !this.openNextUrlIfIsLoggedIn() && !sitzungID && !token) {
            this.loginForm.username = this.$route.query.loginName || '';
            this.handleLoginResponse(this.$route.query);
        }
    },
    triggerLoginViaQueryParameters() {
      if (this.loginForm?.username && this.loginForm?.password && !this.loginLoading) {
        this.login();
      }
    },
    async handleAppReturnedFromBackground() {
      this.markDeeplinkAsNotReady();

      this.appReturnedFromBackground = true;

      if(!this.isReady) return; // ignore if it is not ready

      await this.$store.dispatch(BRIDGE_TYPES.ACTIONS.LOAD_LOGIN_DATA, { reload: true });

      if(Object.keys(this.loadedLoginData || {}).length > 0) { // calls automatic login when there is login data loaded
        await this.automaticLogin();
      }

      this.handleDeferredDeeplink();
    },
    handlerErrorMessageComingFromRedirection() {
      const { message } = this.$route.query;

      if (message) {
        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(message, 'danger'));
      }
    },
    markDeeplinkAsNotReady() {
      // prevents handle deeplink before automatic login is processed
      this.$store.commit(BRIDGE_TYPES.MUTATIONS.SET_DEEPLINK_READY, false);
    },
    handleDeferredDeeplink() {
      this.$store.commit(BRIDGE_TYPES.MUTATIONS.SET_DEEPLINK_READY, true);
      this.$store.dispatch(BRIDGE_TYPES.ACTIONS.OPEN_DEFERRED_DEEPLINK);
    },
    setPasswordFromQuery() {
      const { password } = this.$route.query ?? {};
      if (password === undefined) {
        return;
      }

      this.$set(this.loginForm, 'password', password);

      // replace from URL
      this.$router.replace({
        query: {
          ...(this.$route.query ?? {}),
          password: undefined,
        },
      });
    },
  },
  computed: {
    ...mapGetters({
      apiAddressLegacy: CORE_TYPES.GETTERS.API_ADDRESS_LEGACY,
      imageBackgroundLoginUrl: CORE_TYPES.GETTERS.GET_BROKER_IMAGE_BACKGROUND_LOGIN_URL,
      maklerLogo: CORE_TYPES.GETTERS.GET_BROKER_LOGO,
      hasColorSchema: CORE_TYPES.GETTERS.HAS_COLOR_SCHEMA,
      isLoggedIn: CORE_TYPES.GETTERS.IS_LOGGED_IN,
      loginWelcomeTitle: CORE_TYPES.GETTERS.GET_LOGIN_WELCOME_TITLE,
      hasMobileDataForNativeMeansOfLogin: BRIDGE_TYPES.GETTERS.HAS_MOBILE_DATA_FOR_NATIVE_MEANS_OF_LOGIN,
      loadedLoginData: BRIDGE_TYPES.GETTERS.LOADED_LOGIN_DATA,
      authenticationTypeLabel: BRIDGE_TYPES.GETTERS.GET_AUTHENTICATION_TYPE_LABEL,
      isInitialAppState: CORE_TYPES.GETTERS.IS_INITIAL_APP_STATE,
      openWith: WEBRTC_TYPES.GETTERS.OPENED_CHAT_BETEILIGTER_ID,
      inCallWith: WEBRTC_TYPES.GETTERS.IN_CALL_WITH,
      brokerInfo: CORE_TYPES.GETTERS.GET_BROKER_INFORMATION,
      hasLoginInProgress: CORE_TYPES.GETTERS.HAS_LOGIN_IN_PROGRESS,
      confirm2FA: CORE_TYPES.GETTERS.IS_CONFIRMING_2FA,
    }),
    showKundeLoginSelfRegistration() {
      return this.brokerInfo?.kundeLoginSelfRegistration;
    },
    showCallModalWebrtcGap() {
      return this.openWith || this.inCallWith || false;
    },
    isReady() {
      return this.isMounted && !this.$route.query?.token && this.hasColorSchema;
    },
    showNativeMeansOfLogin() {
      return this.hasMobileDataForNativeMeansOfLogin && this.showFieldsForNativeMeansOfLogin && this.mfaType !== MFA_TYPE_CODE_SMS;
    },
    showFieldsForNativeMeansOfLoginToggle() {
      return this.hasMobileDataForNativeMeansOfLogin && !this.isInitialAppState;
    },
    loginContentBackgroundImage() {
      return this.imageBackgroundLoginUrl || `${this.apiAddressLegacy}/../images/etc/layout/msc_wolken4.png`;
    },
    loginContentBackground() {
      return `url(${this.loginContentBackgroundImage})`;
    },
    logoLoginBoxBackground() {
      return this.maklerLogo
    },
    userid() {
      return findUserId(this.$route);
    },
    nextUrl() {
      return findLoginNextUrl(this.$route);
    },
    callbackToken() {
      return this.$route.query?.callbackToken;
    },
    callbackURL() {
      return this.$route.query?.callbackURL;
    },
    isChristmasSeason() {
      const december = 11; // zero indexed
      const today = dayjs();
      const seasonBegin = dayjs().month(december).date(1);
      const seasonEnd = dayjs().month(december).date(31);

      //return today.isBetween(seasonBegin, seasonEnd, 'day');
      return false;
    },
    isMfaSmsCodeInvalid() {
      return this.mfaType === MFA_TYPE_CODE_SMS && !this.loginForm?.mfaCode;
    },
  },
  watch: {
    userid: {
      handler(newUserid) {
        this.loginForm.username = newUserid;
      },
      immediate: true,
    },
    '$route.query.password': {
      handler() {
        this.setPasswordFromQuery();
      },
      immediate: true,
    },
    isReady: {
      handler() {
        this.onReady();
      },
      immediate: true,
    },
    hasColorSchema: {
      handler(newValue) {
        if(!newValue){
          loadDefaultColorsScheme();
        }
      }
    },
  },
  created() {
    this.markDeeplinkAsNotReady();

    // reset confirm 2FA
    this.$store.commit(CORE_TYPES.MUTATIONS.SET_CONFIRM_2FA, false);
  },
    async mounted() {
        document.dispatchEvent(new CustomEvent('triggerRegistrationUpdate'))

        this.$nextTick(() => this.isMounted = true);

        const token = this.$route.query.token;
        const sitzungID = this.$route.query.SitzungID;

        this.handlerErrorMessageComingFromRedirection();

        removeItem('originalUser');

        if (sitzungID) {
            const loginPayload = {
                sitzungID: sitzungID,
            };
            this.$store.dispatch(CORE_TYPES.ACTIONS.LOGIN, loginPayload).then(responseData => {
                this.handleLoginResponse(responseData);
            }).catch(err => {
                this.loginLoading = false;
                this.resetMfaData();
            });
        } else if (token) {
            const loginPayload = {
                token: token,
                nextUrl: '/home/dashboard'
            };
            this.$store.dispatch(CORE_TYPES.ACTIONS.TOKEN_LOGIN, loginPayload);
        }

      this.handlerDeepLinkNeedFactor2(sitzungID, token);
      this.triggerLoginViaQueryParameters();
      document.addEventListener(BRIDGE_TYPES.HANDLERS.APP_RETURNED_FROM_BACKGROUND, this.handleAppReturnedFromBackground);
    },
    beforeDestroy() {
      document.removeEventListener(BRIDGE_TYPES.HANDLERS.APP_RETURNED_FROM_BACKGROUND, this.handleAppReturnedFromBackground);
    },
    components: {
        BaseButton,
        InputField,
        HeaderLogin,
        AnimatedSpinner,
        NativeAuthIcon,
        DownloadAppInfo,
    },
}
</script>

<style src="./login.scss" lang="scss" scoped>
  .snowflake {
  color: #fff;
  font-size: 1em;
  font-family: Arial;
  text-shadow: 0 0 1px #000;
}

@-webkit-keyframes snowflakes-fall{0%{top:-10%}100%{top:100%}}@-webkit-keyframes snowflakes-shake{0%{-webkit-transform:translateX(0px);transform:translateX(0px)}50%{-webkit-transform:translateX(80px);transform:translateX(80px)}100%{-webkit-transform:translateX(0px);transform:translateX(0px)}}@keyframes snowflakes-fall{0%{top:-10%}100%{top:100%}}@keyframes snowflakes-shake{0%{transform:translateX(0px)}50%{transform:translateX(80px)}100%{transform:translateX(0px)}}.snowflake{position:fixed;top:-10%;z-index:9999;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;cursor:default;-webkit-animation-name:snowflakes-fall,snowflakes-shake;-webkit-animation-duration:10s,3s;-webkit-animation-timing-function:linear,ease-in-out;-webkit-animation-iteration-count:infinite,infinite;-webkit-animation-play-state:running,running;animation-name:snowflakes-fall,snowflakes-shake;animation-duration:10s,3s;animation-timing-function:linear,ease-in-out;animation-iteration-count:infinite,infinite;animation-play-state:running,running}.snowflake:nth-of-type(0){left:1%;-webkit-animation-delay:0s,0s;animation-delay:0s,0s}.snowflake:nth-of-type(1){left:10%;-webkit-animation-delay:1s,1s;animation-delay:1s,1s}.snowflake:nth-of-type(2){left:20%;-webkit-animation-delay:6s,.5s;animation-delay:6s,.5s}.snowflake:nth-of-type(3){left:30%;-webkit-animation-delay:4s,2s;animation-delay:4s,2s}.snowflake:nth-of-type(4){left:40%;-webkit-animation-delay:2s,2s;animation-delay:2s,2s}.snowflake:nth-of-type(5){left:50%;-webkit-animation-delay:8s,3s;animation-delay:8s,3s}.snowflake:nth-of-type(6){left:60%;-webkit-animation-delay:6s,2s;animation-delay:6s,2s}.snowflake:nth-of-type(7){left:70%;-webkit-animation-delay:2.5s,1s;animation-delay:2.5s,1s}.snowflake:nth-of-type(8){left:80%;-webkit-animation-delay:1s,0s;animation-delay:1s,0s}.snowflake:nth-of-type(9){left:90%;-webkit-animation-delay:3s,1.5s;animation-delay:3s,1.5s}

</style>

