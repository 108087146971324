var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CoreCard", {
    ref: "coreCard",
    attrs: { showToolbar: _vm.showToolbar },
    scopedSlots: _vm._u(
      [
        _vm.showTitle
          ? {
              key: "header",
              fn: function() {
                return [_vm._v(" Vermögen / Verbindlichkeiten ")]
              },
              proxy: true
            }
          : null,
        {
          key: "alternative",
          fn: function() {
            return undefined
          },
          proxy: true
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }