<template>
  <div>
    <PageHeaderTitleNavigation :title="title" :defaultMenu="optionsMenu" :showBackButton="false"/>

    <div class="cards__container layout__negative-margin--8" v-if="Object.keys(signatures).length > 0">
      <div class="box__container cards__item" v-for="(signature, fileId) in signaturesForAuthor" :key="signature.title">
        <div class="cards__item-header">
          <div class="box__title breakable-words">
            <DownloadLink v-if="signature.type === 'antrag'" :title="signature.title" downloadServicePath="/get_antragPDF"
              :filename="signature.title" :queryParams="{ antragId: fileId }" />
            <DownloadLink v-else :title="signature.title" downloadServicePath="/get_simple_file"
              :filename="signature.title" :queryParams="{ fileId: fileId  }" />

          </div>
        </div>
        <div>
          <img :src="signature.img" class="signature-img" />
        </div>
        <div class="action-button-list mt-3">
          <InputToggleSwitch label="Abgelehnt" @input="onChangeSignatureAbgelehnt($event, signature.title)" />
        </div>
      </div>
    </div>
    <div v-else>
      <NoData />
    </div>
  </div>
</template>
<script>
import BaseModal from '@/components/core/BaseModal.vue';
import { mapGetters } from "vuex";
import VV_TYPES from '@/store/vermoegensverwaltung/types';
import CORE_TYPES from '@/store/core/types';
import NoData from "@/components/core/NoData.vue";
import BaseButton from '@/components/core/BaseButton.vue';
import InputCheckboxItem from "@/components/core/forms/checkbox/InputCheckBoxItem.vue";
import FormDivider from '@/components/core/forms/FormDivider.vue';
import DownloadLink from '@/components/core/download/DownloadLink.vue';
import InputToggleSwitch from '../core/forms/InputToggleSwitch.vue';
import { SIGNATURE_AUTHOR_TYPES } from '@/components/suitabilityTest/SuitabilityTest.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';


export default {

  components: {
    BaseModal,
    NoData,
    BaseButton,
    InputCheckboxItem,
    FormDivider,
    DownloadLink,
    InputToggleSwitch,
    PageHeaderTitleNavigation
  },
  data: function () {
    return {
      antraege: [],
      fehler: [],
      title: "Unterschriften",
      signatureAuthor: SIGNATURE_AUTHOR_TYPES.ALLE,
    }
  },
  computed: {
    ...mapGetters({
      API_ADDRESS: CORE_TYPES.GETTERS.API_ADDRESS,
      suitabilityResult: VV_TYPES.GETTERS.SUITABILITYRESULT,
      signatures: VV_TYPES.GETTERS.SUITABILITYSIGNATURES,
    }),
    signaturesForAuthor() {
      return this.signatures[this.signatureAuthor.key];
    },
    secondDepotinhaber() {
      return this.suitabilityResult?.zusaetzlichePersonen.find(p => {
            return p.relationship.extraDepotOwner;
          })
    },
    optionsMenu() {
      return Object.keys(SIGNATURE_AUTHOR_TYPES).filter(type => {
        return type != SIGNATURE_AUTHOR_TYPES.DEPOTINHABER_2.key || this.secondDepotinhaber; //Removes DEPOTINHABER_2 option from the menu, if there is no 2. Depotinhaber
      })
      .map(key => {
        return {
          action: () => {
            this.signatureAuthor = SIGNATURE_AUTHOR_TYPES[key];
          },
          label: SIGNATURE_AUTHOR_TYPES[key].label,
          textBold: this.signatureAuthor === SIGNATURE_AUTHOR_TYPES[key],
        }
      })
    }
  },
  watch: {
    signatureAuthor: {
      handler() {
        this.onChangeSignatureAuthor();
      },
      immediate: true
    }
  },
  methods: {
    onChangeSignatureAbgelehnt(isAbgelehnt, abgelehnteSignatur) {
      if (isAbgelehnt) {
        this.fehler.push(`${abgelehnteSignatur}: ${this.signatureAuthor.label}`)
      } else { //checkbox was unchecked so remove error if it exists
        const index = this.fehler.indexOf(abgelehnteSignatur);
        if (index > -1) {
          this.fehler.splice(index, 1);
        }
      }
      this.$emit('rejected', this.fehler);
    },
    onChangeSignatureAuthor() {
      this.$emit('signatureAuthor', this.signatureAuthor)
    },
  }
}
</script>
<style scoped>
.breakable-words {
  display: flex;
  flex-wrap: wrap;
  word-wrap: break-word;
  word-break: break-word;
}

.action-button-list {
  display: flex;
  justify-content: flex-end;
}


.cards__container {
  display: flex;
  flex-wrap: wrap;
}

.cards__item {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
}

.cards__item-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .cards__item {
    flex: 1 1 calc(32% - 16px);
  }
}

.signature-img {
  max-height: 350px;
  cursor: pointer;
}
</style>