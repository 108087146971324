var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "mt-2 mb-5" },
        [
          _c(
            "BaseButton",
            {
              on: {
                click: function($event) {
                  return _vm.hinzufugen()
                }
              }
            },
            [_vm._v("Hinzufügen")]
          )
        ],
        1
      ),
      _vm.rows.length
        ? _c("Table", {
            attrs: {
              tableId: "00479a60-4c16-4305-adc3-32a31d55a9f2",
              headers: _vm.headers,
              rows: _vm.rows,
              rowsPerPage: 20
            },
            on: {
              "click-name": function($event) {
                return _vm.openRow($event)
              },
              "action-delete": function($event) {
                return _vm.confirmDeletePerson($event)
              }
            }
          })
        : _c("NoData"),
      _c("VertragsPersonenModal", { ref: "vertragsPersonenModal" }),
      _c(
        "BaseModal",
        {
          ref: "deletePersonConfirmation",
          attrs: {
            modalTitle: "Vertragsperson löschen",
            labelButtonConfirm: "Löschen"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.deletePerson()
            }
          }
        },
        [_vm._v(" " + _vm._s(_vm.deletePersonMessage) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }