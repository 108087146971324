<template>
  <div>
    <EditTransactionComment ref="editTransactionCommentModal" v-if="editComment" :transaction="editComment" @close="editComment=null"/>
    <ChangeCategory v-if="changeCategory" :transaction="changeCategory" @close="changeCategory=null"/>
    <EditTransactionSplits v-if="splitTransaction && splitTransactionSplit"
          :transaction="splitTransaction" :originalSplit="splitTransactionSplit" @close="splitTransaction=null"/>
    
    <div v-if="!transactions">
      <span>Keine Daten</span>
    </div>
    <div v-else>
        <Table
            :headers="headers"
            :rows="rows"
            :rowsPerPage="10"
            :mobileConfig="{title: 'accountDisplayName', headers: ['categoryDisplayName', 'amount']}"
            @action-ADD_COMENT="openAddComment($event.transaction)"
            @action-CHANGE_CATEGORY="openChangeCategoryModal($event.transaction)"
            @action-SPLIT_TRANSACTION="openSplitTransaction($event.transaction)"
        />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import dayjs from "dayjs";
import MULTIBANKING_TYPES from '@/store/multiBanking/types'
import CORE_TYPES from '@/store/core/types'
import BaseCollapsable from '../core/BaseCollapsable'
import AccountTypeIcon from './AccountTypeIcon'
import EditTransactionComment from './EditTransactionComment'
import ChangeCategory from './ChangeCategory'
import EditTransactionSplits from './EditTransactionSplits'
import Table from "@/components/table2/Table.vue";
import {TextColumn, DateColumn, CurrencyColumn, PillColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import { PhFileText, PhPencilLine, PhGitFork} from 'phosphor-vue'

export default {
  components: {
    BaseCollapsable,
    AccountTypeIcon,
    EditTransactionComment,
    ChangeCategory,
    EditTransactionSplits,
    Table,
  },

  props: {
    transactions: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
        editComment: null,
        changeCategory: null,
        splitTransaction: null,
        headers: {
            lockedLeft: [
                TextColumn("accountDisplayName", "Konto"),
                CurrencyColumn("amount", "Betrag"),
                PillColumn("status", ""),
            ],
            center: [
                TextColumn("categoryDisplayName", "Kategorie"),
                DateColumn("entryDate", "Buchung"),
                DateColumn("valueDate", "Wertstellung"),
                TextColumn("name", "Beschreibung").addCellProps({ lineClamp: 4, }),
                TextColumn("payeePayerAcctNo", "Gegenkonto"),
                TextColumn("purpose", "Verwendungszweck", 600).addCellProps({ lineClamp: 4, }),
                TextColumn("customerComment", "Kommentar").addCellProps({ lineClamp: 4, }),
                CurrencyColumn("totalAmount", "Originalbetrag"),
            ],
            lockedRight: [
                ActionColumn("actions", "Aktionen"),
            ],
        },
    }
  },

  computed: {
    ...mapGetters({
      transactionSplits: MULTIBANKING_TYPES.GETTERS.TRANSACTION_SPLITS,
      isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
    }),
    splitTransactionSplit() {
      if (this.splitTransaction) {
        return this.transactionSplits["" + this.splitTransaction.id]
      }
      return null
    },
    transactionsByDate() {
      if (!this.transactions)
        return null
      const result = new Map();
      this.transactions.forEach(trans => {
        const dateKey = dayjs(trans.valueDate || trans.entryDate).format('DD.MM.YYYY')
        if (!result.has(dateKey)) {
          result.set(dateKey, [])
        }
        result.get(dateKey).push(trans);
      })
      return result
    },
    sortedTransactionDates() {
      return Array.from(this.transactionsByDate.keys()).sort((a, b) => b - a)
    },

    rows() {
        const actions = this.isBrokerOrBypass ? [] : [
            SimpleAction("ADD_COMENT", PhFileText, "Kommentar hinzufügen"),
            SimpleAction("CHANGE_CATEGORY", PhPencilLine, "Kategorie wechseln"),
            SimpleAction("SPLIT_TRANSACTION", PhGitFork, "Betrag aufteilen"),
        ];
        return this.transactions.map((transaction) => ({
            name: transaction.name,
            accountDisplayName: transaction.account?.displayName,
            categoryDisplayName: transaction.category?.displayName,
            amount: transaction.amount,
            entryDate: transaction.entryDate ? dayjs(transaction.entryDate).format('DD.MM.YYYY') : null,
            valueDate: transaction.valueDate ? dayjs(transaction.valueDate).format('DD.MM.YYYY') : null, 
            payeePayerAcctNo: transaction.payeePayerAcctNo,
            purpose: transaction.purpose,
            customerComment: transaction.customerComment,
            totalAmount: transaction.totalAmount,
            splitted: transaction.splitted,
            status: transaction.splitted ? {label: "Splittbuchung", type: "warning"} : null,
            transaction,
            actions,
        }))
    },
  },

  methods: {
    openSplitTransaction (transaction) {
      this.splitTransaction = transaction
      this.$store.dispatch(MULTIBANKING_TYPES.ACTIONS.LOAD_TRANSACTION_SPLIT, transaction.id)
    },
    openAddComment (transaction) {
      this.editComment = transaction
    },
    openChangeCategoryModal (transaction) {
      if (transaction.splitted) {
        this.openSplitTransaction(transaction)
      } else {
        this.changeCategory = transaction
      }
    },
  },
}
</script>

<style scoped>
.transaction-list-date {
  background-color: var(--color-secondary);
  color: var(--color-secondary-text);
  padding: 2px 15px;
}
.transaction-list-title {
  display: flex;
  padding: 10px 0;
}
.transaction-list-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 20px;
  line-height: 1.5;
}
.transaction-list-content > div {
  margin-left: -15px;
  margin-right: -15px;
}
a {
  cursor: pointer;
}
</style>