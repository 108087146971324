<template>
  <div>
    <PageHeaderTitleNavigation 
      title="Einstellungen bearbeiten" 
      :actions="headerActions" 
      @action-ADD="$router.push('edit/new')" 
    />

    <div class="box__container">
      <div class="box__title">Einstellungen bearbeiten</div>

      <GhostLoading v-if="loading" type="table" />
      <Table
        v-else
        :headers="tableHeaders"
        :rows="tableRows"
        @click-label="openEditConfig"
        @action-DELETE="openRemoveConfigModal"
        @action-COPY="openCopyConfigDefinitionModal"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CONFIG_DEFINITION_TYPES from '@/store/configDefinition/types';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import Table from '@/components/table2/Table.vue';
import { HtmlColumn, ActionColumn, SimpleAction, } from "@/components/table2/table_util.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {
  data() {
    return {
      tableHeaders: {
        lockedLeft: [
          HtmlColumn('label', 'Titel').makeLink().makeAlwaysVisible(),
        ],
        center: [
        ],
        lockedRight: [
          ActionColumn('actions', 'Aktionen'),
        ]
      },
      selectedConfig: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      allConfigsDefinition: CONFIG_DEFINITION_TYPES.GETTERS.ALL_CONFIGS_DEFINITION
    }),
    headerActions() {
      return [
        PageHeaderSimpleAction('ADD', 'Neue Einstellung'),
      ];
    },
    tableRows() {
      return this.allConfigsDefinition.map(config => ({
        ...config,
        actions: [
          SimpleAction('DELETE', 'PhTrash', 'Löschen'),
          SimpleAction('COPY', 'PhCopy', 'Kopieren'),
        ],
      }));
    },
  },
  methods: {
    async findAllConfigsDefinition() {
      try {
        this.loading = true;
        await this.$store.dispatch(CONFIG_DEFINITION_TYPES.ACTIONS.GET_ALL_CONFIGS_DEFINITION);
      } finally {
        this.loading = false;
      }
    },
    openEditConfig(config) {
      this.selectedConfig = { ...config, };
      this.$router.push(`edit/${config.id}`);
    },
    openRemoveConfigModal(config) {
      this.$confirmModal({
        message: `Möchten Sie die Einstellung <b>"${config.label}"</b> wirklich löschen?`,
        title: 'Einstellung löschen',
        labelButtonConfirm: 'Löschen'
      })
        .then(async () => {
          await this.removeConfig(config);
          this.findAllConfigsDefinition();
        });
    },
    openCopyConfigDefinitionModal(config) {
      this.$confirmModal({
        message: `Möchten Sie die Einstellung <b>"${config.label}"</b> wirklich kopieren?`,
        title: 'Einstellung kopieren',
        labelButtonConfirm: 'Kopieren'
      })
        .then(async () => {
          await this.copyConfigDefinition(config);
          this.findAllConfigsDefinition();
        });
    },
    async removeConfig(config) {
      await this.$store.dispatch(CONFIG_DEFINITION_TYPES.ACTIONS.DELETE_CONFIG_DEFINITION, {
        id: config.id,
      });
    },
    async copyConfigDefinition(config) {
      await this.$store.dispatch(CONFIG_DEFINITION_TYPES.ACTIONS.COPY_CONFIG_DEFINITION, {
        id: config.id,
      });
    },
  },
  mounted() {
    this.findAllConfigsDefinition();
  },
  beforeRouteLeave (to, from, next) {
    this.$addBreadcrumb({
      label: `zurück zu den Einstellungen`,
      breadcrumb: this.selectedConfig?.label,
      to,
      from,
    });

    next()
  },
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    BaseButton,
    Table,
    GhostLoading,
  }
}
</script>

<style scoped>

</style>