import { render, staticRenderFns } from "./PersonSearch.vue?vue&type=template&id=580a3572&"
import script from "./PersonSearch.vue?vue&type=script&lang=js&"
export * from "./PersonSearch.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("P:\\workspaces\\fk0\\fc\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('580a3572')) {
      api.createRecord('580a3572', component.options)
    } else {
      api.reload('580a3572', component.options)
    }
    module.hot.accept("./PersonSearch.vue?vue&type=template&id=580a3572&", function () {
      api.rerender('580a3572', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/persoenliche-daten/person-search/PersonSearch.vue"
export default component.exports