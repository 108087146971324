import MY_GOALS_TYPES from './types';
import axios from 'axios';

const config = {
  defaultSpinner: true
};

export default {
  [MY_GOALS_TYPES.ACTIONS.GET_GOALS]({ commit }) {
    axios.get(`${process.env.VUE_APP_API}/myGoals/getMyGoals`, config).then(response => {
      if (response?.data) {
        commit(MY_GOALS_TYPES.MUTATIONS.GET_GOALS_SUCCESS, response.data);
      }
    })
  },

  async [MY_GOALS_TYPES.ACTIONS.SAVE_GOAL]({ commit, state}, id = '') {
    if (state.dataHasChanged) {
      const goal = state.goals?.find(goal => goal.id === id);
      if (goal?.title) {
        return await axios.post(`${process.env.VUE_APP_API}/myGoals/saveMyGoal`, goal, config).then(response => {
          if (response?.data) {
            commit(MY_GOALS_TYPES.MUTATIONS.GET_GOALS_SUCCESS, response.data);
          }
          return response.data
        })
      }
    }
  },

  [MY_GOALS_TYPES.ACTIONS.DELETE_GOAL]({ commit }, {id}) {
    axios.delete(`${process.env.VUE_APP_API}/myGoals/deleteMyGoal?id=${id}`, config).then(response => {
      if (response?.data) {
        commit(MY_GOALS_TYPES.MUTATIONS.GET_GOALS_SUCCESS, response.data);
      }
    })
  },

  async [MY_GOALS_TYPES.ACTIONS.GET_DEPOTS]({ }) {
    const response = await axios.post(`${process.env.VUE_APP_API}/virtualsubdepot?get_list`, {ask: 'get_list',params: {}},  config);
    return response?.data;
  },

  async [MY_GOALS_TYPES.ACTIONS.ADD_DEPOTS]({ dispatch, commit, state}, { goalId, depotIds}) {
    const goal = state.goals?.find(goal => goal.id === goalId);
    if (goal && depotIds) {
      if (state.dataHasChanged) {
        await dispatch(MY_GOALS_TYPES.ACTIONS.SAVE_GOAL, goalId);
      }
      await axios.post(`${process.env.VUE_APP_API}/myGoals/addDepots?goalId=${goalId}`, depotIds, config).then(response => {
        if (response?.data) {
          commit(MY_GOALS_TYPES.MUTATIONS.GET_GOALS_SUCCESS, response.data);
        }
        return response.data
      })
    }
  },

  async [MY_GOALS_TYPES.ACTIONS.REMOVE_DEPOT]({ dispatch, commit, state}, { goalId, depotId}) {
    const goal = goalId && state.goals?.find(goal => goal.id === goalId);
    if (goal) {
      if (state.dataHasChanged) {
        await dispatch(MY_GOALS_TYPES.ACTIONS.SAVE_GOAL, goalId);
      }
      await axios.delete(`${process.env.VUE_APP_API}/myGoals/removeDepot?goalId=${goalId}&depotId=${depotId}`, config).then(response => {
        if (response?.data) {
          commit(MY_GOALS_TYPES.MUTATIONS.GET_GOALS_SUCCESS, response.data);
        }
      })
    }
  },

  async [MY_GOALS_TYPES.ACTIONS.REMOVE_ALL_DEPOTS]({ dispatch, commit, state}, goalId) {
    const goal = goalId && state.goals?.find(goal => goal.id === goalId);
    if (goal) {
      if (state.dataHasChanged) {
        await dispatch(MY_GOALS_TYPES.ACTIONS.SAVE_GOAL, goalId);
      }
      await axios.delete(`${process.env.VUE_APP_API}/myGoals/removeAllDepots?goalId=${goalId}`, config).then(response => {
        if (response?.data) {
          commit(MY_GOALS_TYPES.MUTATIONS.GET_GOALS_SUCCESS, response.data);
        }
      })
    }
  },

  [MY_GOALS_TYPES.ACTIONS.UPLOAD_FILE]({ commit, rootState }, payload) {
    return axios.post(rootState.core.apiAddress + `/myGoals/saveFile?goalId=${payload.goalId}`, payload.file, config)
      .then(response => {
        commit(MY_GOALS_TYPES.MUTATIONS.GET_GOALS_SUCCESS, response.data);
      });
  },

  [MY_GOALS_TYPES.ACTIONS.DELETE_FILE]({ commit, rootState }, goalId) {
    return axios.delete(rootState.core.apiAddress + `/myGoals/deleteFile?goalId=${goalId}`, config)
      .then(response => {
        commit(MY_GOALS_TYPES.MUTATIONS.GET_GOALS_SUCCESS, response.data);
      });
  },
}
