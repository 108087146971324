var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "carousel-item__container",
      class: _vm.containerClass,
      on: {
        click: function($event) {
          return _vm.handleAction($event, _vm.item)
        }
      }
    },
    [
      _vm.item.loading
        ? _c(
            "GhostLoading",
            { attrs: { type: "custom", width: 112 } },
            [_c("Block", { attrs: { height: 52 } }), _c("Block")],
            1
          )
        : [
            _c(
              "div",
              { staticClass: "carousel-item__icon", style: _vm.iconStyle },
              [
                _c(
                  _vm.item.component,
                  _vm._b(
                    { tag: "component" },
                    "component",
                    _vm.item.componentProps,
                    false
                  )
                ),
                _c("BaseBadgeCounter", {
                  staticClass: "carousel-item__icon--badge__counter",
                  attrs: { count: _vm.item.badgeCount }
                })
              ],
              1
            ),
            _c("div", {
              staticClass: "carousel-item__label",
              style: _vm.labelStyle,
              domProps: { innerHTML: _vm._s(_vm.sanitize(_vm.item.label)) }
            })
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }