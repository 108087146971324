var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c("GhostLoading", { attrs: { type: "table" } })
        : !_vm.hasLinks
        ? _c("NoData", { attrs: { noIcon: "" } })
        : _c(
            "ul",
            { staticClass: "m-0" },
            _vm._l(_vm.deepLinks, function(link) {
              return _c("li", { key: link.name }, [
                !link.clicked
                  ? _c(
                      "a",
                      {
                        attrs: { href: link.url, target: "_blank" },
                        on: {
                          click: function($event) {
                            return _vm.onLinkClick(link)
                          }
                        }
                      },
                      [_vm._v(_vm._s(link.name))]
                    )
                  : _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "fc-tooltip",
                            rawName: "v-fc-tooltip",
                            value:
                              "Der Deeplink kann nur einmal verwendet werden.<br/>Für eine erneute Nutzung bitte den Button 'Deeplinks aktualisieren' klicken.",
                            expression:
                              "'Der Deeplink kann nur einmal verwendet werden.<br/>Für eine erneute Nutzung bitte den Button \\'Deeplinks aktualisieren\\' klicken.'"
                          }
                        ]
                      },
                      [_vm._v(_vm._s(link.name))]
                    )
              ])
            }),
            0
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }