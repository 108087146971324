var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("MailComposer", {
        attrs: {
          custom: true,
          customFunctions: _vm.customFunctions,
          isUserSelect: !_vm.$route.meta.insuranceCancel,
          allwaysManual: false,
          showExtendedActionButtons: !_vm.$route.meta.insuranceCancel,
          additionalSendMailParams: _vm.additionalSendMailParams,
          isGetPlaceholdersOnInit: _vm.isGetPlaceholdersOnInit,
          isContactRequired:
            !_vm.$route.meta.insurance && !_vm.$route.meta.insuranceCancel,
          reciverDisable:
            _vm.$route.meta.insuranceCancel &&
            _vm.$route.meta.modus == "MANY_MAIL"
        },
        on: { vollmacht: _vm.setVollmacht, callbacks: _vm.setCallbacks },
        scopedSlots: _vm._u([
          {
            key: "maklervollmacht",
            fn: function() {
              return [
                _vm.maklervollmacht && _vm.maklervollmacht.hasVollmacht
                  ? _c(
                      "BaseButton",
                      {
                        attrs: {
                          isSecondary: "",
                          tid: _vm._generateTidFromString("Vollmacht"),
                          disabled:
                            !_vm.isByPass ||
                            (_vm.maklervollmacht &&
                              _vm.maklervollmacht.disabled)
                        },
                        on: {
                          click: function($event) {
                            return _vm.handleAddDocuments()
                          }
                        }
                      },
                      [_vm._v(" Maklervollmacht anhängen ")]
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }