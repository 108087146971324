var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "box__container" }, [
      _c("div", { staticClass: "box__title" }, [
        _vm._v("Höchste / relevante Schulbildung *")
      ]),
      _c("div", { staticClass: "forms__input--half-size" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-6" },
            [
              _c("InputField", {
                attrs: { label: "Schultyp", disabled: !_vm.isEditable },
                on: {
                  change: function($event) {
                    return _vm.addCustomerDataEdited("education")
                  }
                },
                model: {
                  value: _vm.education.schoolType1,
                  callback: function($$v) {
                    _vm.$set(_vm.education, "schoolType1", $$v)
                  },
                  expression: "education.schoolType1"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-6" },
            [
              _c("InputField", {
                attrs: { label: "von / bis", disabled: !_vm.isEditable },
                on: {
                  change: function($event) {
                    return _vm.addCustomerDataEdited("education")
                  }
                },
                model: {
                  value: _vm.education.schoolTime1,
                  callback: function($$v) {
                    _vm.$set(_vm.education, "schoolTime1", $$v)
                  },
                  expression: "education.schoolTime1"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-6" },
            [
              _c("InputField", {
                attrs: { label: "Schultyp", disabled: !_vm.isEditable },
                on: {
                  change: function($event) {
                    return _vm.addCustomerDataEdited("education")
                  }
                },
                model: {
                  value: _vm.education.schoolType2,
                  callback: function($$v) {
                    _vm.$set(_vm.education, "schoolType2", $$v)
                  },
                  expression: "education.schoolType2"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-6" },
            [
              _c("InputField", {
                attrs: { label: "von / bis", disabled: !_vm.isEditable },
                on: {
                  change: function($event) {
                    return _vm.addCustomerDataEdited("education")
                  }
                },
                model: {
                  value: _vm.education.schoolTime2,
                  callback: function($$v) {
                    _vm.$set(_vm.education, "schoolTime2", $$v)
                  },
                  expression: "education.schoolTime2"
                }
              })
            ],
            1
          )
        ])
      ])
    ]),
    _c("div", { staticClass: "box__container" }, [
      _c("div", { staticClass: "box__title" }, [
        _vm._v("Berufliche Qualifikationen")
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("InputField", {
              attrs: {
                label: "Frühere berufliche Tätigkeiten",
                isComponentHalfSize: true,
                disabled: !_vm.isEditable
              },
              on: {
                change: function($event) {
                  return _vm.addCustomerDataEdited("education")
                }
              },
              model: {
                value: _vm.education.pastOccupation,
                callback: function($$v) {
                  _vm.$set(_vm.education, "pastOccupation", $$v)
                },
                expression: "education.pastOccupation"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("InputField", {
              attrs: {
                label: "Berufliche Qualifikation / Studium *",
                isComponentHalfSize: true,
                disabled: !_vm.isEditable
              },
              on: {
                change: function($event) {
                  return _vm.addCustomerDataEdited("education")
                }
              },
              model: {
                value: _vm.education.qualification,
                callback: function($$v) {
                  _vm.$set(_vm.education, "qualification", $$v)
                },
                expression: "education.qualification"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("InputField", {
              attrs: {
                label: "Kaufmännische / Wertpapierspezifische Ausbildung",
                isComponentHalfSize: true,
                disabled: !_vm.isEditable
              },
              on: {
                change: function($event) {
                  return _vm.addCustomerDataEdited("education")
                }
              },
              model: {
                value: _vm.education.training,
                callback: function($$v) {
                  _vm.$set(_vm.education, "training", $$v)
                },
                expression: "education.training"
              }
            })
          ],
          1
        )
      ])
    ]),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "box__container" }, [
      _c("p", [
        _vm._v(
          "* Die gekennzeichneten Daten werden im Anlegerprofil aufgedruckt."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }