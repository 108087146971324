<template>
	<CoreCard ref="coreCard" :showToolbar="showToolbar" :listView="listView">
		<template v-if="showTitle" v-slot:header>
			{{chartName}}
		</template>
		<template v-slot:alternative>
		</template>
    <div class="content">
      <div v-if="isContainerRendered">
		    <Chart :data="chartData" :lineData="lineData" type="area" :isDataLabels="false" :xlabels="chartXLabels" :numberFixed="0" :isLegend="false" :xLabel="xLabel()" :tooltip="tooltip()" :colors="customColors" :mixedColors="false" :isTooltip="true"></Chart>
        <div class="legend" v-if="labels">
          <div v-for="(item, index) of labels" class="legend-item" :key="index" :style="{color: getColor(index)}">
            &bull; {{item}}
          </div>
        </div>
      </div>
    </div>
	</CoreCard>
</template>

<script>
import CoreCard from '../CoreCard/CoreCard'
import Chart from '../../charts/Chart.vue'
import { getColorsMixedContrastDecreasing } from '../../../helpers/colors-helper';
import dayjs from "dayjs";
import cardMixin from '../CoreCard/card-mixin';


export default {
  mixins: [cardMixin],

  data: function() {
    return {
      isContainerRendered: false,
      labels: [],
      colorsApex: getColorsMixedContrastDecreasing(),
    };
  },

  computed: {
    chartName: function() {
      return this.propsData.option;
    },
    data: function() {
      return this.propsData.data;
    },
    customColors: function() {
      return this.propsData.customColors;
    },
    chartXLabels: function() {
      if (this.chartName === 'Bestand' && this.data[this.chartName]) {
        let data = [];
        const dataKeys = Object.keys(this.data[this.chartName])
        .map(v => {
          const arr = (`${v}`).split('.');
          return new Date(arr[1], arr[0] - 1, 1)
        })
        .sort((a, b) => {
          return a - b;
        })
        return dataKeys.map(v => dayjs(v).format('MM.YYYY'));
      }
      return undefined; //[...Array(12).keys()].map(v => v + 1);
	  },
    lineData: function() {
      if (!this.data) {return {name: '', data: []}}
      const keys = this.chartName && this.data[this.chartName] && Object.keys(this.data[this.chartName]) || [];
      let result = {
        name: keys[0] || '',
        data: [],
      }
      if (keys && keys.length) {
        result = {
          name: keys[0] || '',
          data: this.data[this.chartName][keys[0]] || []
        };
      }
      return result;
    },
    chartData: function() {
      const self = this;
      if (!this.data) {return [{name: '', data: []}]}

      if (this.chartName === 'Bestand' && this.data[this.chartName]) {
        let data = [];
        const dataKeys = Object.keys(self.data[this.chartName]).sort((x, y) => {
          const arrA = (`${x}`).split('.');
          const a = new Date(+arrA[1], +arrA[0], 0);
          const arrB = (`${y}`).split('.');
          const b = new Date(+arrB[1], +arrB[0], 0);
          return a - b;
        })
        data = dataKeys.map(k => self.data[this.chartName][k]);
        return [{
          type: 'area',
          name: '',
          data: data.map(v => Array.isArray(v) && +v[0] || +v)
        }]
      }

      let resultData = [];
      let resultData2 = [];
      const keys = this.chartName && self.data[this.chartName] && Object.keys(self.data[this.chartName]) || [];
      this.labels = keys;
      if (this.data[this.chartName]) {
        if (keys && keys.length) {
          keys[0] && self.data[this.chartName][keys[0]].map((v, i) => {
            resultData.push({
              label: `${i + 1}`,
              value: !Array.isArray(v) ? +v : +v[0]
            });
          });
          keys[1] && self.data[this.chartName][keys[1]].map((v, i) => {
            resultData2.push({
              label: `${i + 1}`,
              value: +v,
            });
          });
        }
      }

      let result = {
        type: 'area',
        name: keys[0] || '',
        data: this.chartName && self.data[this.chartName] && self.data[this.chartName][keys[0]] && self.data[this.chartName][keys[0]].map(v => +v.toFixed(0)) || [] // resultData
      };
      let result2 = {
        type: 'area',
        name: keys[1] || '',
        data: this.chartName && self.data[this.chartName] && self.data[this.chartName][keys[1]] && self.data[this.chartName][keys[1]].map(v => +v.toFixed(0)) || [] //resultData2
      };
      if (result.data.length < 12 ||  result2.data.length < 12) {
        return [
          result,
          result2,
          {name: '', data: Array(12).fill(0)}
        ];
      }
      return [
        result,
        result2,
      ];
    },
    
    tableData() {
      const resultData = [];
      this.chartData.map(chart => {
        chart.name && chart.data.map(value => {
          resultData.push({
            label: chart.name,
            value: value,
            year: '',
          })
        })
      });
      return {
        headers: {
          label: {
            label: '',
            key: 'label',
            dataType: 'String',
            sortable: false,
            filterable: false,
            sum: false,
            visible: true,
            fixed: true,
          },
          value: {
            label: '',
            key: 'value',
            dataType: 'String',
            visible: true,
            align: 'right',
            sortable: false,
            filterable: false,
            sum: false,
            fixed: true,
          },
          year: {
            label: '',
            key: 'year',
            dataType: 'String',
            visible: true,
            align: 'right',
            sortable: false,
            filterable: false,
            sum: false,
            fixed: true,
          }
        },
        records: [
          ...resultData
        ]
      }
    },
  },

  mounted: function () {
    this.$nextTick(function () {
      this.isContainerRendered = true;
    });
  },

  methods: {
    getColor(index) {
      return this.customColors[index] || this.colorsApex[index];
    },
    xLabel() {
      if (this.chartName === 'Bestand' && this.data[this.chartName]) {
        return {
          formatter: v => v && v.getMonth() === 0 ? dayjs(v).format("YYYY") : ''
        }
      }
      return {}
    },
    tooltip() {
      if (this.chartName === 'Bestand' && this.data[this.chartName]) {
        return {
          x: {
            formatter: v => dayjs(this.chartXLabels[v - 1]).format("DD.MM.YYYY")
          }
        }
      }
      return {
        x: {
          show: false,
        }
      }
    },
  },

  components: {
    CoreCard,
    Chart
  },
}
</script>

<style scoped>
/* .content {
  width: 100%;
  min-width: 300px;
  height: 100%;
  min-height: 350px;
  position: relative;
} */
</style>
