import VUBLOCK_TYPES from './types';
import CORE_TYPES from '@/store/core/types';
import { VIEW_ROLES } from '@/router/roles';

export default {

  [VUBLOCK_TYPES.GETTERS.VUBLOCK_SELECTED](state) {
    return state.vuBlockSelected;
  },

  [VUBLOCK_TYPES.GETTERS.VUBLOCK_GESELL_SELECTED](state) {
    return (state.vuBlockSelected?.gesellschaften || []).find(gesell => gesell.id == state.vuBlockGesellSelectedId) || {};
  },

  [VUBLOCK_TYPES.GETTERS.VUBLOCK_GESELL_ID_SELECTED](state) {
    return state.vuBlockGesellSelectedId;
  },

  [VUBLOCK_TYPES.GETTERS.VIEW_IS_EDIT_ALLOWED](state, getters) {
    const hasRoles = getters[CORE_TYPES.GETTERS.HAS_ROLES];

    if (hasRoles([VIEW_ROLES.VIEW_BROKER_AS_INTERN]))
      return true;

    return false;
  },

  [VUBLOCK_TYPES.GETTERS.VIEW_IS_BROKER](state, getters) {
    const hasRoles = getters[CORE_TYPES.GETTERS.HAS_ROLES];

    if (hasRoles([[VIEW_ROLES.VIEW_BROKER_ONLY], [VIEW_ROLES.VIEW_BROKER_AS_BROKER]]))
      return true;

    return false;
  },

}