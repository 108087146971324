<template   >
  <BaseModal v-if="page" ref="modal" labelButtonCancel="Schließen" @onCancelButton="close" @onCloseButton="close"
   :confirmDisabled="saving"
   modalTitle="Fax/E-Mail manuell zuordnen"
    @onConfirmButton="save()" :autoClose="false" :showConfirmButton="valid()" size="full">



    <DownloadLink target="_blank" rel="noopener noreferer" :title="this.linkTitle" downloadServicePath="/pages_download"
      :queryParams="this.getDownloadsPageParams" />
    <br />

    <InputField ref="input_kun" v-model="page.kundennrIn" @input="onChangeCustomerNr($event)" label="Kundennr" />
    <InputField ref="input_name" v-model="page.kundenname" :disabled="true" :validateUntouched="true"
      v-if="page.kundennrIn" />


    <!--div v-if="page.kundenname &&(page.kundennrIn !=null && /^\d{7}$/.test( page.kundennrIn))" class="col-xl-2 px-1">
            {{ page.kundenname }}
            </div-->

    <ComboBox label="Gesellschaft" :firstEmpty="true" v-model="page.gesellschaftIn"
      :values="this.config ? this.config.allItems : null" @change="onChangeGesell($event)"></ComboBox>
    <ComboBox label="" :firstEmpty="true" :values="this.commun" @change="onChangeGesellComm($event)"></ComboBox>


    <ComboBox label="Email/Fax" :firstEmpty="true" v-model="page.kommunikationsArt" :validateUntouched="true"
      :values="this.config ? this.config.artItems : null"></ComboBox>
    <InputField ref="input_adress" v-model="page.commAddress" :label="this.getAddressLabel" />
    <InputField v-if="this.page && this.page.kommunikationsArt == 'EMAIL'" v-model="page.subject" label="Subject" />

    <div v-if="this.page && this.page.kommunikationsArt == 'EMAIL'">
        <HtmlEditor 
          :value="this.page && this.page.htmlText ? this.page.htmlText : ''"
          @input="htmlText = $event"
          isFontParams
        />
    </div>

  </BaseModal>
</template>

<script>
import { mapGetters } from "vuex";
import InputField from "@/components/core/forms/InputField.vue";
import BaseModal from "@/components/core/BaseModal.vue";
import V_P_TYPES from "@/store/versandProtokolle/types";
import BoxContainer from "@/components/core/BoxContainer.vue";
import InputTextArea from "@/components/core/forms/InputTextArea.vue";
import DownloadLink from "@/components/core/download/DownloadLink.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import { required, regex, maxLength, minLength, numbers } from "@/mixins/validator/rules";
import validator from '@/mixins/validator';
import HtmlEditor from '@/components/html-editor/HtmlEditor.vue';


export default {

  mixins: [validator],
  validators: {
    page: {
      kundennrIn: [regex(/^\d{7}$|^$/, 'Kundennr 7 Zahlen')],
      kommunikationsArt: [regex(/^FAX$|^EMAIL$/, 'Wählen Sie Bitte E-Mail ode Fax')],
      kundenname: [minLength(1, 'Kundennr nicht richtig')],
      subject: [minLength(1, 'Bitte tragen Sie den Betreff ein.')],
      commAddress: [minLength(1, 'Bitte tragen das ein.')]
    }
  },

  mounted() {
    


  },
  data() {
    return {
      isLoaded: false,
      id: null,
      dat: null,
      saving: false,
      pages: null,
      htmlText: '',
    };
  },

  computed: {
    ...mapGetters({
      page: V_P_TYPES.GETTERS.EDIT_GESEL_PAGE,
      config: V_P_TYPES.GETTERS.EDIT_GESEL_PAGE_CONFIG,
      commun: V_P_TYPES.GETTERS.GESELL_KOMM_COMBO
    }),

    linkTitle() {
      if (this.pages) {

        let res = " Seiten ";
        if (this.pages.length == 1) {
          res = " Seiten ";
        }
        return res + (this.pages.map(t => t.rownum).join(','));
      } else {
        return null;
      }
    },
    getAddressLabel() {
      if (this.page.kommunikationsArt == 'FAX') {
        return 'Faxnummer'
      } else if (this.page.kommunikationsArt == 'EMAIL') {
        return 'E-Mail'
      } else {
        return '';
      }
    },
    getDownloadsPageParams() {
      if (this.pages) {
        return {
          eingangId: this.id,
          pages: this.pages.map(t => t.rownum)
        };
      } else {
        return '';
      }

    },
  },
  methods: {


    async open(id, pages) {
      this.$store.commit(V_P_TYPES.GETTERS.EDIT_GESEL_PAGE, null);

      this.id = id;
      this.pages = pages;
    
      await this.loadData(id, pages[0].rownum);

      //this.validation.reset("input_kun");
     
      await this.$refs.modal?.open();
      await this.$refs.input_kun?.$runValidator(this.page.kundennrIn);
      await this.$refs.input_name?.$runValidator(this.page.kundennrIn);


    },
    async loadData(id, page) {
      //await this.$store.commit(V_P_TYPES.MUTATIONS.EDIT_GESEL_PAGES,pages);
      let e = {};
      e.id = id;
      e.page = page;
      this.$store.commit(V_P_TYPES.MUTATIONS.EDIT_GESEL_PAGE, null);
      this.$store.commit(V_P_TYPES.MUTATIONS.GESELL_KOMM_COMBO, null);
      await this.$store.dispatch(V_P_TYPES.ACTIONS.GET_PAGE, e);

    },
    async close() {

      // this.$store.commit(V_P_TYPES.MUTATIONS.EINGANG,null);
      //await this.$store.dispatch(V_P_TYPES.ACTIONS.EINGANG, this.id);
      /*console.log('close');
      this.page.kundennrIn='1234567';
      this.validation.reset("input_kun");
      this.$store.commit(V_P_TYPES.MUTATIONS.EDIT_GESEL_PAGE,this.page);*/

      this.$store.commit(V_P_TYPES.GETTERS.EDIT_GESEL_PAGE, null);

      this.$refs.modal.handleClosed();
    },



    onChangeInputField($event) {
      this.code = $event;
    },
    async onChangeGesell(event) {
      if (event) {
        await this.$store.dispatch(V_P_TYPES.ACTIONS.GESELL_KOMM_COMBO, event);
      } else {
        this.$store.commit(V_P_TYPES.ACTIO.GESELL_KOMM_COMBO, null);
      }
    },

    async onChangeCommArt(event) {
      if (event) {
        //this.page.kommunikationsArt=event;
        // this.$store.commit(V_P_TYPES.MUTATIONS.EDIT_GESEL_PAGE,this.page);
      }
    },



    async onChangeGesellComm(event) {
      if (event) {
        await this.$store.dispatch(V_P_TYPES.ACTIONS.GET_GESELL_KOMM, event);
      }
    },
    onChangeCustomerNr($event) {


      if (!$event) {
        this.page.kundenname = '';
        this.$store.commit(V_P_TYPES.MUTATIONS.EDIT_GESEL_PAGE, this.page);
        return;
      }

      let b = /^\d{7}$/.test($event);
      if (!b) {
        //this.page.kundennrIn='1';
        this.page.kundenname = '';

        this.$store.commit(V_P_TYPES.MUTATIONS.EDIT_GESEL_PAGE, this.page);
      } else {

        //alert(typeof event)
        this.$store.dispatch(V_P_TYPES.ACTIONS.GET_KUNDEN_NAME, $event);
      }

    },
    setHtml(event) {
      
    },
    async save() {
      try {
        this.saving = true;
        if (!this.valid()) {
          return;
        }
        let res = {}
        res.eingangId = this.id;
        res.rownums = this.pages.map(t => t.rownum);
        res.kommunikationsArt = this.page.kommunikationsArt;
        res.kundennr = this.page.kundennrIn;
        res.gesellschaft = this.page.gesellschaftIn;
        res.address = this.page.commAddress;
        if (this.page.kommunikationsArt == 'EMAIL') {
          res.subject = this.page.subject;
          res.htmlText = this.htmlText || this.page?.htmlText || '';
        } else {
          res.subject = null;
          res.htmlText = null;
        }
        
        await this.$store.dispatch(V_P_TYPES.ACTIONS.SET_KUNDEN_GESEL_PAGES ,res);      
        let bool = this.$store.getters[V_P_TYPES.GETTERS.ERROR_EDIT];
       
        if (!bool) {
          this.close();
        }
      }
      finally {
        this.saving = false;
      }
    },

    valid() {
      if (!this.page) {
        return false;
      }
      if (this.page.kundennrIn && !this.page.kundenname) {
        return false;
      }

      if (!this.page.kommunikationsArt) {
        return false;
      }

      if (!this.page.commAddress) {
        return false;
      }
      if (this.page.kommunikationsArt == 'EMAIL' && !this.page.subject) {
        return false;
      }

      return true;

    }

  },
  components: {
    BaseModal,
    HtmlEditor,
    InputField,
    InputTextArea,
    DownloadLink,
    BoxContainer,
    ComboBox,
    InputField,
  },
};
</script>

<style scoped>
.w-300 {
  max-width: 300px;
}

.w-100 {
  width: 100%;
}

.nowrap {
  white-space: nowrap;
  padding: 0;
}




.mt-1 {
  margin-top: 1rem;
}

.mb-0 {
  margin-bottom: 0;
}

.max-w-100 {
  max-width: 100%;
}

.mr-n8 {
  margin-right: -16px;
}

.f-grow-1 {
  flex-grow: 1;
  flex: 1;
}

.f-nowrap {
  flex-wrap: wrap;
}

.text-nowrap {
  white-space: nowrap;
}

.mr-3 {
  margin-right: 3rem;
}

.text-color-info {
  color: var(--color-info);
}
</style>
<style>

.input-forms-p .input-forms__container {
  padding-bottom: 0;
}
</style>