<template>
  <div>
    <BaseModal 
      ref="editModal"
      modalTitle="Warnung Deaktivieren (inkl. Kommentar)"
      :showCancelButton="false"
      @onCloseButton="close()"
      @close="close()">

      <GhostLoading v-if="loading">
        <Block type="title"/>
        <Block type="paragraph" height="90"/>
      </GhostLoading>

      <div v-else>
        <InputToggleSwitch 
          label="Deaktivieren"
          v-model="dokStornos.disableStornosBestaetigt" />

        <InputTextArea
          label="Kommentar"
          :disabled="!dokStornos.disableStornosBestaetigt"
          v-model="dokStornos.disableComment"/>
      </div>

    </BaseModal>
  </div>
</template>

<script>
import BaseModal from '@/components/core/BaseModal.vue';
import OPEN_SIGNS_TYPES from '@/store/openSigns/types';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';

function dokStornosForm() {
  return {
    disableStornosBestaetigt: null,
    disableComment: null,
  }
}

export default {
  props: {
    selectedElement: {
      type: Object,
      default: () => ({}),
    }
  },

  data: function () {
    return {
      loading: false,
      dokStornos: dokStornosForm(),
    }
  },

  methods: {
    async saveDokStornos() {
      if (this.selectedElement?.dokId && this.dokStornos) {
        try {
          await this.$store.dispatch(OPEN_SIGNS_TYPES.ACTIONS.SAVE_DOKUMENT_SIGNATUR_STORNOS_BESTAETIGT, {
            dokId: this.selectedElement.dokId,
            dokStornos: this.dokStornos,
          })
          this.dokStornos = dokStornosForm()
        } catch (error) {
          // empty block
        }
        
      }
    },
    async close() {
      await this.saveDokStornos();
      this.$emit("close")
    },
    async loadDokStornos() {
      if (this.selectedElement?.dokId) {
        try {
          this.loading = true
          const dokStornos = await this.$store.dispatch(OPEN_SIGNS_TYPES.ACTIONS.GET_DOKUMENT_SIGNATUR_STORNOS_BESTAETIGT, this.selectedElement.dokId);
          this.dokStornos = dokStornos ?? dokStornosForm();
        } finally {
          this.loading = false;
        }
      }
    }
  },
  mounted() {
    this.$refs.editModal.open()
    this.loadDokStornos();
  },

  components: {
    Block,
    BaseModal,
    InputToggleSwitch,
    GhostLoading,
    InputTextArea,
  },
}
</script>
