<template>
  <div class="box__container">
    <ComboBox
      label="Kredit-/Darlehensform"
      :firstEmpty="!form.kreditform"
      :values="combos.kreditFormCombo"
      :isComponentHalfSize="true"
      v-model="form.kreditform"
      validateUntouched
      :disabled="hasEhypAntragsnr"
      @change="dataChangedCombo('kreditform', $event); clearKreditart();"
    />
    <ComboBox
      label="Kredit-/Darlehensart"
      :firstEmpty="true"
      :values="kreditartCombo"
      :isComponentHalfSize="true"
      v-model="form.kreditart"
      :disabled="hasEhypAntragsnr"
      @change="dataChangedCombo('kreditart', $event)"
    />
    <InputField
      label="Kredit-/Darlehensnummer"
      :isComponentHalfSize="true"
      v-model="form.kreditnummer"
      :disabled="hasEhypAntragsnr"
      @change="dataChanged()"
    />
    <InputField
      label="Bezeichnung"
      :isComponentHalfSize="true"
      v-model="form.bezeichnung"
      :disabled="hasEhypAntragsnr"
      @change="dataChanged()"
    />
    <InputField
      label="Verwendungszweck"
      :isComponentHalfSize="true"
      v-model="form.verwendung"
      @change="dataChanged()"
    />
    <InputField
      label="Gesellschaft"
      :isComponentHalfSize="true"
      v-model="form.gesellschaft"
      :disabled="hasEhypAntragsnr"
      @change="dataChanged()"
    />
    <InputField
      label="Filiale"
      :isComponentHalfSize="true"
      v-model="form.filiale"
      @change="dataChanged()"
    />
    <EntitySelectorBankverbindung
      :isComponentHalfSize="true"
      :values="combos.bankingAccounts"
      v-model="form.bankingAccount"
      @change="dataChangedCombo('bankingAccount', $event)"
    />

    <hr>

    <InputField
      label="Kredit-/Darlehenssumme"
      type="currency"
      :isComponentHalfSize="true"
      v-model="form.darlehenssumme"
      @change="dataChanged()"
    />
    <InputField
      label="Auszahlungskurs"
      type="percent"
      :isComponentHalfSize="true"
      v-model="form.auszahlungskurs"
      @change="dataChanged()"
    />
    <InputField
      label="Bearbeitungskosten"
      type="currency"
      :isComponentHalfSize="true"
      v-model="form.bearbeitungskosten"
      @change="dataChanged()"
    />
    <InputField
      label="Sollzins nominal"
      type="percent"
      :isComponentHalfSize="true"
      v-model="form.sollzinsNominal"
      :precision="4"
      @change="dataChanged()"
    />
    <InputField
      label="Sollzins effektiv"
      type="percent"
      :isComponentHalfSize="true"
      v-model="form.sollzinsEffektiv"
      :precision="4"
      @change="dataChanged()"
    />
    <InputField
      label="Sollzins nach Festschreibung"
      type="percent"
      :isComponentHalfSize="true"
      v-model="form.sollzinsNachFestschreibung"
      :precision="4"
      @change="dataChanged()"
    />
    <ComboBox
      label="Zahlungsperiode Raten"
      :isComponentHalfSize="true"
      :firstEmpty="true"
      :values="combos.sollzinsPeriodeCombos"
      v-model="form.sollzinsPeriode"
      @change="dataChangedCombo('sollzinsPeriode', $event)"
    />
    <ComboBox
      label="Tilgungsverrechnung"
      :isComponentHalfSize="true"
      :firstEmpty="true"
      :values="combos.tilgungsverrechnungPeriodeCombos"
      v-model="form.tilgungsverrechnungPeriode"
      @change="dataChangedCombo('tilgungsverrechnungPeriode', $event)"
    />
    <ComboBox
      label="Zinsabrechnung"
      :isComponentHalfSize="true"
      :firstEmpty="true"
      :values="combos.zinsabrechnungPeriodeCombos"
      v-model="form.zinsabrechnungPeriode"
      @change="dataChangedCombo('zinsabrechnungPeriode', $event)"
    />
    <InputTextArea
      label="Besonderheiten"
      :isComponentHalfSize="true"
      v-model="form.sonstiges"
      @change="dataChanged()"
    />

    <hr>

    <DatePickerField
      isValueAsString
      label="Vertragsbeginn"
      :isComponentHalfSize="true"
      v-model="form.begin"
      @input="dataChanged()"
    />
    <DatePickerField
      isValueAsString
      label="Vertragsablauf"
      :isComponentHalfSize="true"
      v-model="form.ablauf"
      @input="dataChanged()"
    />
    <DatePickerField
      isValueAsString
      label="Ende Sollzinsbindung"
      :isComponentHalfSize="true"
      v-model="form.end"
      @input="dataChanged()"
    />
    <DatePickerField
      isValueAsString
      label="Auszahlungstermin"
      :isComponentHalfSize="true"
      v-model="form.auszahlungDatum"
      @input="dataChanged()"
    />
    <DatePickerField
      isValueAsString
      label="1. Rate am"
      :isComponentHalfSize="true"
      v-model="form.ersteRateDatum"
      @input="dataChanged()"
    />
    <DatePickerField
      isValueAsString
      label="1. Tilgungsverrechnung"
      :isComponentHalfSize="true"
      v-model="form.ersteTilgungsverrechDatum"
      @input="dataChanged()"
    />
    <DatePickerField
      isValueAsString
      label="1. Zinsabrechnung"
      :isComponentHalfSize="true"
      v-model="form.ersteZinsabrechnungDatum"
      @input="dataChanged()"
    />
    <InputField
      label="Rate"
      type="currency"
      :isComponentHalfSize="true"
      v-model="form.folgerate"
      @change="dataChanged()"
    />
    <InputField
      label="anfänglicher Tilgungssatz"
      type="percent"
      :isComponentHalfSize="true"
      v-model="form.tilgungssatz"
      @change="dataChanged()"
    />
    <InputField
      label="Restschuld nach Festschreibung"
      type="currency"
      :isComponentHalfSize="true"
      v-model="form.restschuld"
      @change="dataChanged()"
    />
    <DatePickerField
      isValueAsString
      label="Frühester Ablösezeitpunkt"
      :isComponentHalfSize="true"
      v-model="form.fruehesterAbloesezeitpunkt"
      @input="dataChanged()"
    />
    <InputToggleSwitch
      label="Sondertilgung"
      :inLineLabel="true"
      v-model="form.hatSondertilgung"
      @input="dataChanged()"
    />
    <InputField
      v-if="form.hatSondertilgung"
      type="percent"
      :isComponentHalfSize="true"
      v-model="form.sondertilgungProzent"
      @change="dataChanged()"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CREDIT_TYPES from '@/store/credit/types';

import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputField from '@/components/core/forms/InputField.vue';
import EntitySelectorBankverbindung from '@/components/core/forms/EntitySelectorBankverbindung.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';

import validator from '@/mixins/validator';
import { required, range } from '@/mixins/validator/rules';

import { KREDITFORM_BAUFINANZIERUNG } from '../credit-utils';

const FORM_KREDITART_PATH = 'form.kreditart';


export default {
  mixins: [validator],
  data() {
    return {
      form: {
        id: null,
        kreditform: null,
        kreditart: null,
        kreditnummer: null,
        bezeichnung: null,
        verwendung: null,
        gesellschaft: null,
        filiale: null,
        bankingAccount: null,
        darlehenssumme: null,
        auszahlungskurs: null,
        bearbeitungskosten: null,
        sollzinsNominal: null,
        sollzinsEffektiv: null,
        sollzinsNachFestschreibung: null,
        sollzinsPeriode: null,
        tilgungsverrechnungPeriode: null,
        zinsabrechnungPeriode: null,
        sonstiges: null,
        begin: null,
        end: null,
        auszahlungDatum: null,
        ersteRateDatum: null,
        ersteTilgungsverrechDatum: null,
        ersteZinsabrechnungDatum: null,
        folgerate: null,
        tilgungssatz: null,
        restschuld: null,
        fruehesterAbloesezeitpunkt: null,
        hatSondertilgung: null,
        sondertilgungProzent: null,
        ablauf: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      creditId: CREDIT_TYPES.GETTERS.SELECTED_CREDIT_ID,
      combos: CREDIT_TYPES.GETTERS.COMBOS,
      credit: CREDIT_TYPES.GETTERS.CREDIT,
      creditEdited: CREDIT_TYPES.GETTERS.CREDIT_EDITED,
      prohyp: CREDIT_TYPES.GETTERS.PROHYP,
    }),
    kreditartCombo() {
      const kreditform = this.form?.kreditform || 'default';
      return this.combos?.kreditArtCombos?.[kreditform];
    },
    hasEhypAntragsnr() {
      return !!this.prohyp.ehypAntragsnr;
    },
    isKreditformBaufinanzierung() {
      return this.form?.kreditform === KREDITFORM_BAUFINANZIERUNG;
    },
  },
  watch: {
    credit: {
      handler() {
        this.updateData();
      },
      immediate: true,
    },
    'form.kreditform': {
      handler() {
        this.validateKreditart();

        this.addHints();
      },
      immediate: true,
    },
    'form.kreditart'() {
      this.$nextTick(() => this.validateKreditart());
    },
  },
  methods: {
    checkData() {
      return !this.validation.isInvalid('form');
    },
    clearKreditart() {
      this.form.kreditart = null;
      this.dataChanged();
    },
    validateKreditart() {
      if(!this.form?.kreditform) return;

      const kreditart = this.form?.kreditart;
      if(this.isKreditformBaufinanzierung && !kreditart) {
        this.$pushErrors(FORM_KREDITART_PATH);
      } else {
        this.validation.reset(FORM_KREDITART_PATH);
      }
    },
    async save() {
      if(!this.checkData()) return;
      await this.$store.dispatch(CREDIT_TYPES.ACTIONS.SAVE_CREDIT);
    },
    updateData() {
      Object.keys(this.form).forEach(key => {
        if(key in this.creditEdited) {
          this.form[key] = this.creditEdited[key];
        } else if(key in this.credit) {
          this.form[key] = this.credit[key];
        }
      });
    },
    isChanged(group) {
      return Object.keys(group)
        .filter(fieldName => this.credit[fieldName] !== group[fieldName])
        .length > 0;
    },
    dataChanged() {
      const groupData = this.isChanged(this.form) ? this.form : null;

      if (groupData !== null || groupData !== undefined) {
        const payload = {
          ...this.credit, 
          ...groupData,
          id: this.creditId,
        };

        this.$store.commit(CREDIT_TYPES.MUTATIONS.APPEND_CREDIT_EDITED, payload);
      }
    },
    dataChangedCombo(field, value) {
      this.form[field] = value;
      this.dataChanged();
    },
    addHints() {
      this.$store.commit(CREDIT_TYPES.MUTATIONS.RESET_HINTS);

      this.$store.commit(CREDIT_TYPES.MUTATIONS.ADD_HINT, {
        title: 'Kredit-/Darlehensform',
        message: 'Wenn deactiviert sind die Änderungen nicht mehr erlaubt, da der Kredit Antrag an Prohyp verschickt wurde',
      });

      this.$store.commit(CREDIT_TYPES.MUTATIONS.ADD_HINT, {
        title: 'Kredit-/Darlehensart',
        message: 'Wenn deactiviert sind die Änderungen nicht mehr erlaubt, da der Kredit Antrag an Prohyp verschickt wurde',
      });

      if(this.isKreditformBaufinanzierung) {
        this.$store.commit(CREDIT_TYPES.MUTATIONS.ADD_HINT, {
          title: 'Kredit-/Darlehensart',
          message: [
            '<b>Nur für Baufinanzierung:</b>', 
            '<br>',
            'Wählen Sie hier den zutreffenden Verwendungszweck für das Vorhaben Ihres Kunden.', 
            '<br>',
            'Erläuterungen:', 
            '<br>',
            'Kauf eines Neubaus vom Bauträger: Ihr Kunde kauft eine Immobilie, die gerade neu erbaut wurde.', 
            '<br>',
            'Kauf einer bestehenden Immobilie: Ihr Kunde kauft eine nicht neu erbaute Immobilie (z.B. Altbauwohnung).', 
            '<br>',
            'Eigenes Bauvorhaben: Ihr Kunde baut sein eigenes Haus, das er dann später selbst bewohnen oder auch vermieten möchte.', 
            '<br>',
            'Anschlussfinanzierung: Die Sollzinsbindung des bestehenden Darlehens Ihres Kunden läuft demnächst aus.', 
            '<br>',
            'Umbau/Modernisierung: Ihr Kunde plant einen Umbau oder eine Modernisierung seiner Immobilie wie beispielsweise den Anbau eines Wintergartens oder die Erneuerung der Elektrik.', 
            '<br>',
            'Kapitalbeschaffung: Ihr Kunde beleiht eine bestehende Immobilie zur Beschaffung von Kapital für einen anderen Verwendungszweck.', 
            '<br>',
            '<br>',
            'Hinweis:',
            '<br>',
            'Der Verwendungszweck kann nur geändert werden, wenn der Antrag aktiv ist.', 
            '<br>',
            'Eine nachträgliche Änderung ist unzulässig.', 
          ].join(''),
        });
      }
    },
  },
  async beforeDestroy() {
    if(this.checkData()) {
      await this.save();
    }

    this.$store.commit(CREDIT_TYPES.MUTATIONS.RESET_HINTS);
  },
  validators: {
    form: {
      kreditform: [required()],
      auszahlungskurs: [range(50, 100)],
    },
  },
  components: {
    ComboBox,
    InputField,
    EntitySelectorBankverbindung,
    InputTextArea,
    DatePickerField,
    InputToggleSwitch,
  },
}
</script>
