import ANLEGERPROFIL_TYPES from './types';
import { getInitialState } from './index';
import Vue from 'vue';

const EXPERIENCES = ['experience1', 'experience2', 'experienceGF1', 'experienceGF2', 'mandatory1', 'mandatory2', 'mandatoryGF1', 'mandatoryGF2']

export default {
  [ANLEGERPROFIL_TYPES.MUTATIONS.GET_ANLEGERPROFIL_SUCCESS](state, payload) {
    Vue.set(state, 'data', Object.assign({}, payload));
    Vue.set(state, 'warnings', Object.assign([], payload?.warnings || []));
  },

  [ANLEGERPROFIL_TYPES.MUTATIONS.SAVE_ANLEGERPROFIL_SUCCESS](state, payload) {
    const data = {}
    if (payload) {
      Object.keys(payload).forEach(key => {
        if (key !== 'warnings') {
          data[key] = payload[key];

          if(payload[key] && EXPERIENCES.includes(key)) {
            if (payload[key]?.id === state.data[key]?.id) {
              // Für die Kenntnisse und Erfahrungen der gleichen Person werden emptyCategories vom state kopiert.
              // Das ist wichtig für die selectierte Kategorien, welche Cards noch nicht befüllt worden. Sonst geht der Hacken verloren. 
              const emptyCategories = state.data[key]?.emptyCategories
              data[key].emptyCategories = emptyCategories;
            }
          }
      }});
    }
    Vue.set(state, 'data', data);
    Vue.set(state, 'warnings', Object.assign([], payload?.warnings || []));
  },
  
  [ANLEGERPROFIL_TYPES.MUTATIONS.UPDATE_DATA_STORE_EXPERIENCE_CARD](state, payload) {
    if (payload?.category && EXPERIENCES.includes(payload?.category) && payload?.data?.path) {
      const categories = state.data && Object.assign(state.data[payload.category] || {})?.categories || [];
      let category = categories.find(cat => cat.path === payload.data.path);
      if (category) {
        const index = categories.indexOf(category);
        categories.splice(index, 1, payload.data)
        if (!state.data) {
          state.data = {};
        }
        if (!state.data[payload.category]) {
          state.data[payload.category] = {};
        }
        state.data[payload.category].categories = categories;
        state.data.dataHasChanged = true;
      }
    }
  },
  
  [ANLEGERPROFIL_TYPES.MUTATIONS.UPDATE_DATA_STORE](state, { category, data, forceUpdate = false}) {
    if (category && data) {
      if (!state.data) {
        state.data = {};
      }
      if (data?.emptyCategories || forceUpdate) {
        state.data = Object.assign({}, state.data);
      }

      const oldData = state.data && state.data[category];
      const actualData = Object.assign(oldData || {}, data);

      state.data[category] = actualData;
      state.data.dataHasChanged = true;
    }
  },

  [ANLEGERPROFIL_TYPES.MUTATIONS.UPDATE_DATA_STORE_ZUSATZ_DJE_ZIELMARKT_ROWS](state, {row, data = {}} ) {
    if (!state.data.zusatzDJE) {
      state.data.zusatzDJE = {};
    }
    if (row?.zeithorizont) {
      const index = state.data.zusatzDJE.zielmarktRows?.findIndex(zm => zm.zeithorizont === row.zeithorizont);
      if (index >= 0) {
        state.data.zusatzDJE.zielmarktRows[index] = Object.assign(state.data.zusatzDJE.zielmarktRows[index] || {}, data);
        state.data.dataHasChanged = true;
      }
    }
  },

  [ANLEGERPROFIL_TYPES.MUTATIONS.REMOVE_ALL_KENNTNISSE](state, payload) {
      if (!state.data) {
        state.data = {};
      }
      state.data = Object.assign({}, state.data);
      if (state.data.experience1) {
        state.data.experience1.emptyCategories = payload.emptyCategories;
      }
      if (state.data.experience2) {
        state.data.experience2.emptyCategories = payload.emptyCategories;
      }
      if (state.data.mandatory1) {
        state.data.mandatory1.emptyCategories = payload.emptyCategories;
      }
      if (state.data.mandatory2) {
        state.data.mandatory2.emptyCategories = payload.emptyCategories;
      }
      if (state.data.experienceGF1) {
        state.data.experienceGF1.emptyCategories = payload.emptyCategoriesGF;
      }
      if (state.data.experienceGF2) {
        state.data.experienceGF2.emptyCategories = payload.emptyCategoriesGF;
      }
      if (state.data.mandatoryGF1) {
        state.data.mandatoryGF1.emptyCategories = payload.emptyCategoriesGF;
      }
      if (state.data.mandatoryGF2) {
        state.data.mandatoryGF2.emptyCategories = payload.emptyCategoriesGF;
      }
  },
  
  [ANLEGERPROFIL_TYPES.MUTATIONS.UPDATE_SCHIENE](state, value) {
    if (!state.data) {
      state.data = {};
    }
    if (!state.data.extraData) {
      state.data.extraData = {};
    }
    state.data.extraData.schiene = value || '';
  },

  [ANLEGERPROFIL_TYPES.MUTATIONS.COPY_FINANCE_SUCCESS](state, payload) {
    state.data = Object.assign({}, state.data, { finance: payload?.finance });
  },

  [ANLEGERPROFIL_TYPES.MUTATIONS.SET_BERATUNGS_MAPPE_ID](state, payload) {
    state.beratungsMappeId = payload;
  },

  [ANLEGERPROFIL_TYPES.MUTATIONS.UPDATE_ANLEGERPROFIL_EXPERIENCE_CATEGORIES](state, payload) {
    // merge between old and new category list
    if (payload?.propertyName && payload?.categories) {
      const currentCategories = state.data[payload.propertyName].categories
      const newCategories = currentCategories.filter(currentCat => !payload.categories.some(payloadCat => payloadCat.path === currentCat.path))
      newCategories.push(...payload.categories)
      state.data.dataHasChanged = true;
      Vue.set(state.data[payload.propertyName], 'categories', newCategories);
    }
  },

  [ANLEGERPROFIL_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },

  [ANLEGERPROFIL_TYPES.MUTATIONS.START_SAVE_ANLEGERPROFIL_STATE](state) {
    Vue.set(state, 'saveAnlegerprofilState', true)
  },
  [ANLEGERPROFIL_TYPES.MUTATIONS.STOP_SAVE_ANLEGERPROFIL_STATE](state) {
    Vue.set(state, 'saveAnlegerprofilState', false)
  },

  [ANLEGERPROFIL_TYPES.MUTATIONS.SET_SELECTED_PERSON_TYPE](state, personType) {
    Vue.set(state, 'selectedPersonType', personType)
  },
  
  [ANLEGERPROFIL_TYPES.MUTATIONS.SET_PERSON_TYPE](state, personType) {
    Vue.set(state, 'personType', personType)
  },

  [ANLEGERPROFIL_TYPES.MUTATIONS.ANLAGEZIELE_UPDATE_DATA](state, payload) {
    if (payload.fullSuffix && payload.param && state.data?.anlageziele?.zeilen && state.data.anlageziele.zeilen[payload.fullSuffix]) {
      state.data.anlageziele.zeilen[payload.fullSuffix][payload.param] = payload.value;
    } else if (payload?.compId) {
      state.data.anlageziele[payload.compId] = payload.value
    }
    state.data.dataHasChanged = true;
  },

  [ANLEGERPROFIL_TYPES.MUTATIONS.ANLAGEZIELE_ADD_ZEILE](state, payload) {
    const anlageziele = state.data?.anlageziele || {};
    anlageziele.zeilen = Object.assign({}, anlageziele.zeilen || {}, {[payload.suffix]: payload.zeile})
    state.data = Object.assign({}, state.data, anlageziele);
  },

  [ANLEGERPROFIL_TYPES.MUTATIONS.ANLAGEZIELE_REMOVE_ZEILE](state, payload) {
    if (payload.suffix && payload.count) {
      const anlageziele = state.data?.anlageziele || {};
      const zeilen = anlageziele.zeilen || {};
      const zeilenArr = Object.keys(zeilen).sort((a,b) => a.localeCompare(b));

      let newZeilen = {};
      // remove last element
      if (zeilenArr.length && zeilenArr[zeilenArr.length - 1] === (payload.suffix + payload.count)) {
        delete zeilen[payload.suffix + payload.count];
        newZeilen = Object.assign({}, zeilen);
      } else {
        // remove an element in the middle
        delete zeilen[payload.suffix + payload.count];
        newZeilen[payload.suffix + 1] = zeilen[payload.suffix + 1];
        delete zeilen[payload.suffix + 1];

        Object.keys(zeilen).sort((a,b) => a.localeCompare(b)).forEach((key, index) => {
          if (key === payload.suffix + (index + 2)) {
            newZeilen[payload.suffix + (index+2)] = zeilen[key];
          } else {
            const newData = {};
            const zeile = zeilen[key];
            Object.keys(zeilen[key]).forEach(param => {
              const newParam = param.substr(0, param.lastIndexOf('_')).concat('_' + payload.suffix + (index + 2));
              newData[newParam] = zeile[param];
            });
            newZeilen[payload.suffix + (index+2)] = newData;
          }
        });
      }
      anlageziele.zeilen = newZeilen;
      state.data = Object.assign({}, state.data, anlageziele);
    }
  },
  
  [ANLEGERPROFIL_TYPES.MUTATIONS.UPDATE_IS_VORGELEGT](state, isVorgelegt) {
    if (!state.data) {
      state.data = {};
    }
    if (!state.data.extraData) {
      state.data.extraData = {};
    } else {
      state.data.extraData = Object.assign({}, state.data.extraData);
    }
    state.data.extraData.IS_VORGELEGT = isVorgelegt || false;
  }
}
