var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.haveTapi
    ? _c(
        "BaseButton",
        { attrs: { isClear: "" }, on: { click: _vm.showTapiModal } },
        [
          _c("ph-Vibrate", {
            staticClass: "clickable",
            attrs: { size: _vm.size }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }