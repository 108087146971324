var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sofunktionierts_container" },
    [
      _c(
        "div",
        { staticClass: "banner" },
        [
          _c("editable-frame", {
            attrs: { type: "sofunktionierts_title_page" }
          })
        ],
        1
      ),
      _c("hr"),
      _vm._m(0),
      _c(
        "div",
        {},
        [
          _c(
            "base-button",
            {
              on: {
                click: function($event) {
                  return _vm.$router.push("fonds")
                }
              }
            },
            [_vm._v("ZUM FONDSSHOP")]
          ),
          _c("hr")
        ],
        1
      ),
      _vm._m(1),
      _c("risiko-hinweise")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dreispalten_wrapper" }, [
      _c("div", { staticClass: "dreispalten" }, [
        _c("div", { attrs: { id: "spalte1" } }, [
          _c("div", { staticClass: "drei_spalten_image" }, [
            _c("img", {
              attrs: {
                alt: "Icon für die Erklärung",
                src: require("@/../public/img/cms/fonds1.png")
              }
            })
          ]),
          _c("div", { staticClass: "drei_spalten_banner" }, [
            _c("h3", [
              _vm._v("1. Fonds, Modellportfolio oder VL-Fonds auswählen")
            ])
          ]),
          _c("hr"),
          _c("div", { staticClass: "drei_spalten_text" }, [
            _c("p", [
              _vm._v(
                " Zunächst informieren Sie sich umfassend und selbständig über unser Angebot innerhalb des Fondsshops. Anschließend wählen Sie ebenfalls auf eigene Initiative Ihre persönlichen Fondsfavoriten aus über einhundert möglichen Einzelfonds oder Sie entscheiden sich für die vielen Vorzüge eines fertigen Modellportfolios. Ausserdem können Sie über den Menüpunkt „VWL-Fondsauswahl“ auch einen Vertrag für Vermögenswirksame Leistungen auswählen. "
              )
            ]),
            _c("p", [
              _vm._v(
                " Entscheiden Sie selbst, ob Sie nur einmalig anlegen möchten oder mit einem Sparplan die Möglichkeiten einer Fondsanlage regelmäßig und dauerhaft nutzen möchten. "
              )
            ]),
            _c("p", [
              _vm._v(
                " Beachten Sie dabei, dass unser Fondsshop keine persönliche Anlageberatung für Sie bietet. Er ist für Kunden konzipiert, die als Selbstentscheider eine eigene Investmententscheidung in die Tat umsetzen wollen. Für technische Fragen zur Nutzung des Fondsshops oder eine alternative persönliche Beratung stehen wir Ihnen gerne über unser Kontaktformular zur Verfügung. "
              )
            ])
          ]),
          _c("hr")
        ]),
        _c("div", { attrs: { id: "spalte2" } }, [
          _c("div", { staticClass: "drei_spalten_image" }, [
            _c("img", {
              attrs: {
                alt: "Icon für die Erklärung",
                src: require("@/../public/img/cms/fonds2.png")
              }
            })
          ]),
          _c("div", { staticClass: "drei_spalten_banner" }, [
            _c("h3", [_vm._v("2. Dokumente erstellen")])
          ]),
          _c("hr"),
          _c("div", { staticClass: "drei_spalten_text" }, [
            _c("p", [
              _vm._v(
                " Nachdem Sie Ihr Modellportfolio, Ihre Einzelfonds oder Ihren VL-Fonds selbständig ausgewählt haben, können Sie Ihren Fondskauf nun abschließen. Dazu benötigen Sie ein Depot. "
              )
            ]),
            _c("p", [
              _vm._v(
                " Im zweiten Schritt legen Sie deshalb Ihre(n) gewünschten Fonds in den “Warenkorb” und gehen anschließend zur Depoteröffnung! "
              )
            ]),
            _c("p", [
              _vm._v(
                " Nun registrieren Sie sich als neuer Kunde oder melden sich mit Ihren bereits vorhandenen Kundendaten (Kundennummer / Passwort) im Fondsshop an. Dann schließen Sie mit Eingabe Ihrer Stammdaten den Antrag auf Depoteröffnung erfolgreich ab. Hierbei legen wir hohen Wert auf die Einhaltung des Datenschutzes und die Sicherheit Ihrer Daten. Nach Kenntnisnahme Konto- und Depotbezogener Unterlagen und abschließender rechtlicher Hinweise steht Ihrem Investment nichts mehr im Wege. "
              )
            ]),
            _c("p", [
              _vm._v(
                " Prüfen Sie abschließend die auf Basis Ihrer Eingaben erstellten Unterlagen sorgfältig und unterschreiben Sie an allen gekennzeichneten und relevanten Stellen. Die Depoteröffnungsunterlagen bestehen aus "
              )
            ]),
            _c("ul", [
              _c("li", [_vm._v("Checkliste zur Depoteröffnung")]),
              _c("li", [
                _vm._v("Rahmenvereinbarung zur Nutzung des Fondsshops")
              ]),
              _c("li", [
                _vm._v("Depoteröffnungsunterlagen der FFB (FIL Fondsbank GmbH)")
              ]),
              _c("li", [_vm._v("Postident Formular")]),
              _c("li", [_vm._v("Anschreiben zum Postversand")])
            ]),
            _c("p", [
              _vm._v(
                " Alternativ zur „Klassischen Depoteröffnung“ steht Ihnen auch die „Online Depoteröffnung“ zur Verfügung. Hier können Sie sich zwischen dem Post-Ident oder Video-Ident der Deutschen Post AG entscheiden. Bei diesem Verfahren ist keinerlei Unterschrift oder Ausdruck der Unterlagen notwendig. Der Abschluss erfolgt durch Ihre Zustimmungen im Eröffnungsprozess und wird durch die Legitimation finalisiert. "
              )
            ])
          ]),
          _c("hr")
        ]),
        _c("div", { attrs: { id: "spalte3" } }, [
          _c("div", { staticClass: "drei_spalten_image" }, [
            _c("img", {
              attrs: {
                alt: "Icon für die Erklärung",
                src: require("@/../public/img/cms/fonds3.png")
              }
            })
          ]),
          _c("div", { staticClass: "drei_spalten_banner" }, [
            _c("h3", [_vm._v("3. Depoteröffnungsunterlagen weiterleiten")])
          ]),
          _c("hr"),
          _c("div", { staticClass: "drei_spalten_text" }, [
            _c("p", [
              _vm._v(
                " Wenn Sie die „Klassische Depoteröffnung“ gewählt haben ist es nun notwendig den Antrag zur Depoteröffnung nun an die FFB (FIL Fondsbank GmbH) weiterzuleiten, und somit die Abbuchung der gewünschten Beträge zu veranlassen. Hierzu drucken Sie bitte die Unterlagen komplett aus und bringen Sie zu Ihrem Finanzberater. "
              )
            ]),
            _c("p", [
              _vm._v(
                " Dieser führt an Hand Ihres Personalausweises oder Reisepasses Ihre Legitimation durch und leitet die Unterlagen an die FFB (FIL Fondsbank GmbH) weiter. Dort werden die Unterlagen auf Vollständigkeit und Richtigkeit geprüft und Ihr Depot incl. Verrechnungskonto wird innerhalb weniger Tage eröffnet. "
              )
            ]),
            _c("p", [
              _vm._v(
                " Über die erfolgreiche Depoteröffnung und die Abbuchung werden Sie postalisch informiert. Ebenso über alle weiteren Umsätze und Bewegungen innerhalb Ihres Kontos und Depots. "
              )
            ]),
            _c("p", [
              _vm._v(
                " Die Zugangsdaten für Ihren persönlichen Login via PC oder App erhalten Sie ebenfalls per Post. "
              )
            ]),
            _c("p", [
              _vm._v(
                " Mit Ihrem persönlichen Login steht Ihnen nach erfolgreicher Depoteröffnung das komplette Fondsuniversum der FFB (FIL Fondsbank GmbH) zur Verfügung. "
              )
            ])
          ]),
          _c("hr")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", {}, [
      _c("div", { staticClass: "text_only" }, [
        _vm._v(
          " Die nachfolgenden Darstellungen auf dieser Webseite und innerhalb unseres Fondsshops enthalten keine persönliche Empfehlung zu Geschäften mit bestimmten Finanzinstrumenten. Eine Prüfung der persönlichen Umstände des Anlegers erfolgt nicht, ebenso wenig eine Prüfung, ob die jeweiligen Fonds für den Kunden geeignet sind. Beachten Sie hierzu auch die untenstehenden Risikohinweise zur Geldanlage in Investmentfonds und die Rechtlichen Hinweise zur Nutzung des Fondsshops. "
        )
      ]),
      _c("hr")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }