<template>
  <div>
    <div class="row justify-content-end" v-if="showButtonsSelectDeselect">
      <div class="col-auto">
        <BaseButton isSecondary @click="deleteAll">Alle löschen</BaseButton>
      </div>
      <div class="col-auto">
        <BaseButton @click="selectAll">Alle hinzufügen</BaseButton>
      </div>
    </div>
    
    <div class="input-forms__input-container">
     
        <div>
          <Table 
            :tableData="tableDataComputed"
            :cardViewEnabled="false"
            :filterEnabled="false"
            :exportEnabled="false"
            :paginationEnabled="false"
            :actions="columnActions"
            :rowsPerPage="tableData.records.length"
            @input="changeInputValue($event)"
            @execute-action="handleTableAction($event)"
          >
          </Table>
        </div>
      </div>
      <BaseModal ref="deleteModal" 
        modalTitle="Gesellschaft entfernen?"
        labelButtonConfirm="Gesellschaft Entfernen"
        @onConfirmButton="deletePosition()"
        @onCancelButton="onCancelModal()">      
      <div>
        Soll die Gesellschaft <span class="font-bold">{{ positionToDelete.name }}</span> wirklich entfernt werden?
      </div>
    </BaseModal>
  </div>
</template>

<script>
import LOG_TYPES from '@/store/log/types';
import BaseButton from '@/components/core/BaseButton.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import validatorComponentUtils from '@/mixins/validator/validator-component-utils'
import Table from '@/components/table/Table.vue';
import DeletePositionModal from '@/components/core/forms/antraege/wertpapierAuswahl/DeletePositionModal.vue'
import Vue from 'vue'


export default {
  mixins: [validatorComponentUtils],
  props: {
    title: {
      type: String,
      default: ''
    },
    showButtonsSelectDeselect: {
      default: false
    },
    provideDeleteAction: {
      default: true
    },
    componentFields: {
      type: Array,
      default: () => [],
    },
    antragData: {
      type: Object
    },
    config: {
      type: Object
    },
    tableRecords: {
      default: []
    },
    tableData: {
      type: Object,
      default: () => ({
        headers: {},
        records: []
      })
    },
  },
  data() {
    return {
      positionToDelete : {
      },
      headers: undefined,
      tableDataFC: undefined,
      
    }
  },    
  watch: {
    tableRecords: function (newData, oldData) {
    }
  },
  computed: {
    tableDataComputed() {
      if (this.headers && this.headers.length > 0) {
        // update
          this.tableData.headers = this.headers;
      } else {
        // initialise
        this.headers = this.tableData.headers;
      }

      if (this.tableRecords) {
      this.tableData.records = [];
      this.tableRecords.forEach(r=>{       
          if (r && r.myItems) {
              let record = {};
                r.myItems.forEach(col=>{
                  if(col.headerKey == 'EMAIL' && col.isEditable == false){
                    col.isEditable = true;
                  }                                    
                  Vue.set(record, col.headerKey, col);                  
                });              
              this.tableData.records.push(record);
          }         
      });      
      }  
      return this.tableData;
    },

    tableDataConverted() {
      this.$store.dispatch(LOG_TYPES.ACTIONS.LOG, 'tableDataConverted');
      if (this.tableDataFC) {
          return this.tableDataFC;
      }
      this.tableDataFC = {
        records: [],
        headers: {}
      }
      let keysHeader = new Set();
      if (this.tableData) {
        this.tableData.headers.forEach(h=>{
            keysHeader.add(h.key);
            Vue.set(this.tableDataFC.headers, h.key, {...h, 
              visible: true,
              dataType: ['Action', 'Edit', 'Icon'].includes(h.dataType) ? 'Slot' : h.dataType});
          });
          this.tableDataFC.headers = this.tableData.headers;
          this.tableData.records.forEach(r=>{            
              if (r) {
                let record = {};
                r.forEach(col=>{
                  if (keysHeader.has(col.key)) {               
                    if (Object.keys(col).find(n=>n==='value') && !Object.keys(col).find(n=>n==='url')) {
                      Vue.set(record, col.key, col.value);
                    } else {
                      Vue.set(record, col.key, col);
                    }
                  }
                });              
                this.tableDataFC.records.push(record);
              }
            });
      }
      return this.tableDataFC;
    },

    columnActions() {
      return !this.provideDeleteAction ? [] : [
        {
          legend: {
            icon: 'PhTrash',
            index: 1,
            key: 'DELETE',
            label: 'Löschen',
          }
        },
      ];
    },
  },
  methods: {
    onCancelModal() {
      this.$refs.deleteModal.close();
    },
    onInput($event) {
      this.$emit('input', $event)
    },
    deleteAll() {

    },
    selectAll() {

    },
    test(){
      console.log(this.antragData)
    },
    handleTableAction(event) {
      if(event.action?.legend?.key === 'DELETE') {
        this.positionToDelete = event.value.DELETE;
        this.deletePosition();
        //this.openModalDelete(event.value);
      }
    },
    openModalDelete(gesellschaft) {
      this.positionToDelete = gesellschaft;
      this.positionToDelete.name = gesellschaft.gesellschaftName;
      this.$refs.deleteModal.open()
    },
    deletePosition() {
      if (this.positionToDelete) {
        this.$emit('delete', this.positionToDelete);
      }
    },
    changeInputValue(event) {
      if(event) {
        this.$emit('input', event)
      }
    },
  },
  components: {
    BaseButton,
    Table,
    DeletePositionModal,
    BaseModal,
  },
  
}
</script>

<style scoped>

</style>