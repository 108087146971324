<template>
  <div>
    <PageHeaderTitleNavigation
      title="Archiv für Schema"
    />

    <div>
      <div
        v-for="(row, index) of varianten"
        :key="index"
        class="box__container"
      >
        <Table
          :title="row && row.name"
          :headers="headers"
          :rows="(row && row.list) || []"
          :rowsPerPage="20"
          rowId="vvarchivlist"
          hidePagination
          @click-statusPill="openVariante"
        >
          <template v-slot:wertpapiere="row">
            <div v-if="row.wertpapiere && row.wertpapiere.length">
              <div v-for="(wertpapier, index) in row.wertpapiere" :key="index">
                {{ wertpapier }}
              </div>
            </div>
            <div v-else></div>
          </template>
        </Table>
      </div>
    </div>
  </div>
</template>

<script>
import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import PageHeaderTitleNavigation from "@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue";
import Table from "@/components/table2/Table.vue";
import {
  PillColumn,
  SlotColumn,
  TextColumn,
} from "@/components/table2/table_util.js";
import { mapGetters } from "vuex";
import VV_TYPES from "@/store/vermoegensverwaltung/types";
import mixin from "@/mixins/vermoegensverwaltung/vv-mixin.js";

export default {
  mixins: [mixin],
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    Table,
  },
  mounted() {
    this.$store.dispatch(VV_TYPES.ACTIONS.GET_ARCHIV_LIST, this.schemaId);
  },
  computed: {
    ...mapGetters({
      vvArchiv: VV_TYPES.GETTERS.VV_ARCHIV,
    }),
    headers() {
      const headers = {
        lockedLeft: [
          PillColumn("statusPill", "Status", 80).makeLink().makeAlwaysVisible(),
        ],
        center: [
          TextColumn("datum", "Datum"),
          TextColumn("user", "Benutzer"),
          SlotColumn("wertpapiere", "Wertpapiere"),
          TextColumn("comment", "Bemerkung").setColorFn(() => {
            return "color-danger";
          }),
          TextColumn("sri", "SRI"),
        ],
        lockedRight: [],
      };
      return headers;
    },
    varianten() {
      return (
        (this.vvArchiv &&
          Object.keys(this.vvArchiv)
            .map((key) => {
              return (
                {
                  name: key,
                  list: this.vvArchiv[key]?.map((row) => {
                    return {
                      ...row,
                      statusPill: { label: row.status, type: row.statusColor },
                    };
                  }),
                } || []
              );
            })
            .sort((a, b) => a?.name?.localeCompare(b?.name))
            .reverse()) ||
        []
      );
    },
  },
  methods: {
    openVariante(row) {
      if (row?.versionId) {
        this.$addBreadcrumb({
          label: "zurück zum Archiv",
          fullPath: this.$route.path,
          breadcrumb: `Archiv-Ansicht für Schema ${this.schemaData?.title}, ${row.name}`,
        });
        this.$router.push(`/beratung/vv/archiv-variante/${row.versionId}`);
      }
    },
  },
};
</script>

<style>
</style>