import actions from './actions';
import mutations from './mutations';
import getters from './getters';

/**
 * mutations: synchronous operation responsible for change the state
 * actions: asynchronous operation responsible for call external resources and commit mutations 
 */

export const GS_TYPES = {
  APPOINTMENT: 'APPOINTMENT',
  BROKER: 'BROKER',
  COMPANY: 'COMPANY',
  CUSTOMER: 'CUSTOMER',
  CUSTOMER_DATA: 'CUSTOMER_DATA',
  DEPOTS: 'DEPOTS',
  FONDS: 'FONDS',
  FORM: 'FORM',
  NAVIGATION: 'NAVIGATION',
  UNTERDEPOTS: 'UNTERDEPOTS',
  VERSICHERUNGEN: 'VERSICHERUNGEN',
}

export function getInitialState() {
  return {
    routeChangeQueue: [],
    nextScheduledSendRouteChange: null,
  };
}

export default {
  state: {
    ...getInitialState(),
  },
  mutations: {
    ...mutations,
  },
  actions: {
    ...actions,
  },
  getters: {
    ...getters,
  },
}
