var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", { attrs: { title: "Rundmail Status" } }),
      _c(
        "div",
        {
          staticClass: "box__container",
          attrs: { tid: "1c773416-898a-43e9-905c-d9df1b7836e3" }
        },
        [
          !_vm.loading && _vm.tableRows.length
            ? _c("Table", {
                staticClass: "table-container",
                attrs: {
                  tableId: "4bae5e03-6d98-4c7a-8e9b-ff8b36152463",
                  title: _vm.TABLE_TITLE,
                  rowId: "index",
                  headers: _vm.tableHeaders,
                  rows: _vm.tableRows,
                  rowsPerPage: 20,
                  mobileConfig: {
                    title: "subject",
                    headers: ["dateCreated", "dateSend", "anzahlEmpf"]
                  }
                }
              })
            : _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", title: _vm.TABLE_TITLE }
              })
            : _c("NoData", { attrs: { title: _vm.TABLE_TITLE } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }