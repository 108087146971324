import axios from 'axios';

import CORE_TYPES from '../core/types';
import CUSTOMER_CATEGORY_TYPES from './types';

const config = {
  defaultSpinner: true
};


export default {

  async [CUSTOMER_CATEGORY_TYPES.ACTIONS.GET_CUSTOMER_CATEGORY]({ getters, commit, }) {
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/customerCategory/get`, config);
    commit(CUSTOMER_CATEGORY_TYPES.MUTATIONS.GET_CUSTOMER_CATEGORY_SUCCESS, response?.data || {});
  },

  async [CUSTOMER_CATEGORY_TYPES.ACTIONS.SAVE_CUSTOMER_CATEGORY]({ getters, commit, dispatch, }) {
    const hasDataChanged = getters[CUSTOMER_CATEGORY_TYPES.GETTERS.HAS_CUSTOMER_CATEGORY_EDITED];
    if(!hasDataChanged) return;

    const edited = getters[CUSTOMER_CATEGORY_TYPES.GETTERS.CUSTOMER_CATEGORY_EDITED];
    await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/customerCategory/save`, edited, config);
    commit(CUSTOMER_CATEGORY_TYPES.MUTATIONS.RESET_CUSTOMER_CATEGORY_EDITED);
  },

}
