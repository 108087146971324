<script>
import Vue from 'vue';

const V_NODE_LIMIT = 1;

function lookForAnimation(el) {
  if (el && 'tagName' in el && !el.dataset.ttbAnimationListenersAdded) { // prevents add events twice
    el.dataset.ttbAnimationListenersAdded = true;

    const setData = (event, key, value) => {
      if (event?.target === el) {
        el.dataset[key] = value;
      }
    };

    const animationRunning = 'ttbAnimationRunning';
    const transitionRunning = 'ttbTransitionRunning';

    // gives a chance to wait animation/transition finish
    el.addEventListener('animationstart', e => setData(e, animationRunning, true));
    el.addEventListener('animationend', e => setData(e, animationRunning, false));
    el.addEventListener('animationcancel', e => setData(e, animationRunning, false));
    el.addEventListener('transitionstart', e => setData(e, transitionRunning, true));
    el.addEventListener('transitionend', e => setData(e, transitionRunning, false));
    el.addEventListener('transitioncancel', e => setData(e, transitionRunning, false));
  }
}

/**
 * Any component/element within this component is moved to the body element
 * 
 * Example:
 *   <TeleportToBody>
 *     <div></div><!-- // it must have only one root element -->
 *   </TeleportToBody>
 */
export default {
  name: 'TeleportToBody',
  data() {
    return {
      cleanUpFallbackId: null,
      renderInstance: null,
      head: document.createComment('ttb start'),
      tail: document.createComment('ttb end'),
    };
  },
  methods: {
    setUp() {
      const self = this;
      if (self.renderInstance) return;

      // component instance
      const renderInstance = new Vue({
        parent: self,
        data() {
          return {
            updated: performance.now(),
          };
        },
        methods: {
          refreshUpdated() {
            this.$set(this, 'updated', performance.now());
          },
        },
        render(createElement) {
          if (this.updated) {
            // appends the TeleportToBody component default slot
            return self.$slots.default;
          }
          return createElement();
        },
      });
      self.renderInstance = renderInstance;

      // mount
      const renderMounted = renderInstance.$mount();

      // append to body
      const { head, tail } = self;
      const fragment = document.createDocumentFragment();
      fragment.appendChild(head);
      fragment.appendChild(renderMounted.$el);
      fragment.appendChild(tail);
      document.body.appendChild(fragment);
    },
    cleanUp() {
      clearTimeout(this.cleanUpFallbackId);

      try {
        const { head, tail } = this;
        while(head.nextSibling !== tail) {
          document.body.removeChild(head.nextSibling);
        }
        document.body.removeChild(head);
        document.body.removeChild(tail);

        this.renderInstance = null;
      } catch(e) {
        // empty block
      }
    },
    refreshRenderInstance() {
      this.renderInstance?.refreshUpdated();
    },
    destroyRenderInstance() {
      this.renderInstance?.$destroy();
    },
  },
  updated() {
    const { renderInstance } = this;
    this.refreshRenderInstance();
    requestAnimationFrame(() => lookForAnimation(renderInstance?.$el));
  },
  mounted() {
    this.setUp();
  },
  destroyed() {
    this.destroyRenderInstance();

    const { renderInstance } = this;
    const destroyedEl = renderInstance?.$el;
    if (destroyedEl?.dataset?.ttbAnimationRunning || destroyedEl?.dataset?.ttbTransitionRunning) {
      destroyedEl.addEventListener('animationend', this.cleanUp);
      destroyedEl.addEventListener('transitionend', this.cleanUp);

      // prevents to stack destroyed elements even if something goes wrong with the end of the animation/transition
      this.cleanUpFallbackId = setTimeout(this.cleanUp, 10000);
    } else {
      this.cleanUp();
    }
  },
  render(createElement) {
    if (this.$slots.default?.length > V_NODE_LIMIT) {
      throw 'TeleportToBody must have only one root element';
    }

    // renders an empty template
    // for the real render component, please check the setUp method
    return createElement();
  },
}
</script>
