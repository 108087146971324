var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "base-filter-select__container" }, [
    _c("div", { staticClass: "base-filter-select__title box__title" }, [
      _c("span", [_vm._v(_vm._s(_vm.title))]),
      (_vm.showSaveButton && _vm.hasSearchParameters) || _vm.$slots.titleToolbar
        ? _c(
            "div",
            { staticClass: "base-filter-select__toolbar-container ml-2" },
            [
              _vm.showSaveButton && _vm.hasSearchParameters
                ? _c(
                    "div",
                    { staticClass: "base-filter-select__search-parameters" },
                    [
                      _c(
                        "BaseButtonsContainer",
                        { attrs: { minItemsRequired: 1, btnAsLink: "" } },
                        [
                          _vm._l(_vm.sortedList, function(searchParam, index) {
                            return [
                              searchParam && searchParam.visible
                                ? _c(
                                    "button",
                                    {
                                      key: index,
                                      staticClass:
                                        "base-filter-select__search-parameters-item btn btn-clear clickable",
                                      class: _vm.searchParamPillType(
                                        searchParam
                                      ),
                                      attrs: { type: "button" },
                                      on: {
                                        click: function($event) {
                                          _vm.selectStoredSearchParameter(
                                            searchParam
                                          )
                                          _vm.clearSelectedStoredSearchParameter()
                                          _vm.setSelectedStoredSearchParameter(
                                            searchParam
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(searchParam.searchLabel))]
                                  )
                                : _vm._e()
                            ]
                          }),
                          _vm._l(_vm.programmaticSearchParameter, function(
                            searchParam,
                            index
                          ) {
                            return [
                              _c(
                                "button",
                                {
                                  key: 1000 + index,
                                  staticClass:
                                    "base-filter-select__search-parameters-item btn btn-clear clickable",
                                  class: _vm.programmaticSearchParameterPillType(
                                    searchParam
                                  ),
                                  attrs: { type: "button" },
                                  on: {
                                    click: function($event) {
                                      searchParam.onClick(index)
                                      _vm.clearSelectedStoredSearchParameter()
                                      _vm.setProgrammaticSearchParameter(
                                        searchParam
                                      )
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(searchParam.searchLabel))]
                              )
                            ]
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$slots.titleToolbar
                ? _c(
                    "div",
                    { staticClass: "base-filter-select__toolbar" },
                    [_vm._t("titleToolbar")],
                    2
                  )
                : _vm._e()
            ]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }