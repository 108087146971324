var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-forms__container" }, [
    _c("div", { staticClass: "input-forms__label-container" }, [
      _c(
        "div",
        [
          _c(
            "div",
            {
              staticClass: "box__container row layout__negative-margin--8 mb-3"
            },
            [
              _c(
                "div",
                { staticClass: "col-12 col-sm-4 col-xl-2 mx-0 ml-3 mb-3" },
                [
                  _c(
                    "BaseButton",
                    {
                      attrs: {
                        isBlock: "",
                        isSecondary: "",
                        disabled:
                          _vm.disabled ||
                          (_vm.config.anzahlFonds &&
                            _vm.positions.length >= _vm.config.anzahlFonds)
                      },
                      on: {
                        click: function($event) {
                          return _vm.openFondsfinder("KAUF")
                        }
                      }
                    },
                    [_vm._v(" Kauf ")]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-12 col-sm-4 col-xl-2 mx-0 ml-3 mb-3" },
                [
                  _c(
                    "BaseButton",
                    {
                      attrs: {
                        isBlock: "",
                        isSecondary: "",
                        disabled:
                          _vm.disabled ||
                          (_vm.config.anzahlFonds &&
                            _vm.positions.length >= _vm.config.anzahlFonds)
                      },
                      on: {
                        click: function($event) {
                          return _vm.openFondsfinder("SPARPLAN")
                        }
                      }
                    },
                    [_vm._v(" Sparplan ")]
                  )
                ],
                1
              ),
              _vm.config.anzahlFonds
                ? _c("FormLabel", {
                    staticClass: "col-12 mt-3",
                    attrs: {
                      config: { small: true },
                      label:
                        "(Maximal " + _vm.config.anzahlFonds + " Positionen)"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm._l(_vm.positions, function(pos, index) {
            return [
              _c("FormEbasePositionCards", {
                key: index,
                attrs: {
                  position: pos,
                  category: _vm.category,
                  antragId: _vm.antragId,
                  isWebservice: _vm.config.isWebservice,
                  disabled: _vm.disabled
                }
              })
            ]
          })
        ],
        2
      )
    ]),
    _vm.config.mitSummenbildung && this.positions && this.positions.length
      ? _c(
          "div",
          [
            _c("Summenbildung", {
              attrs: {
                antragId: _vm.antragId,
                summe: _vm.summe,
                summeSpar: _vm.summeSparplan,
                mitSparplan: true
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }