<template>
    <div>
        <Table v-if="rows.length"
            ref="table"
            :tableId="tableId"
            cardView
            :headers="headers"
            :rows="rows"
            rowId="id"
            hidePagination
        >


        </Table>

    </div>
</template>
<script>
import Table from "@/components/table2/Table.vue";
import CORE_TYPES from '@/store/core/types';
import OTHER_PRODUCTS_TYPES from "@/store/otherProducts/types";
import {TextColumn, CurrencyColumn} from "@/components/table2/table_util.js";
import { mapGetters } from 'vuex'
import CUSTOMERDATA_TYPES from '@/store/customerData/types';

const PRODUKTART_FREIE_EINGABE = 'Freie Eingabe';
const PRODUKTART_IMMOBILIE = 'Immobilie';
const NUTZUNG_MISCHNUTZUNG = 'Mischnutzung';

export default {
    components: {
        Table,
    },
    data() {
        return {
            sprod: {
                id: null,
                company: null,
                sprodNr: null,
                produktTyp: this.currentType,
                produktart: null,
                freieEingabeProduktart: null,
                tarif: null,
                eingangZahlungBetreuung: true,
                zahlrhythm: null,
                begin: null,
                ende: null,
                beitrag: null,
                bezugssumme: null,
                produktbez: null,
                autoupdate: true,
                fremdanlage: true,
                aktWert: null,
                versBearbeitungsstatus: null,
                bearbStatusBem: null,
                bearbStatusDate: null,
                bankingAccount: null,
                antragsNrIntern: null,
                nutzung: null,
                wohnflaeche: null,
                wohnflaecheEigennutzung: null,
                wohnflaecheFremdnutzung: null,
                anlageArtenImmobilie: null,
                gebaeudetyp: null,
                miete: null,
                adresse1: null,
                adresse2: null,
                betreuerwechsel: null,
                provVorgabe: null,
                sollprovision: null,
                bemerkung: null,
                einnahmen: null,
            },
         };
    },    
    computed: {
        ...mapGetters({
            customerDataEdited: CUSTOMERDATA_TYPES.GETTERS.GET_CUSTOMER_DATA_EDITED,
            isFA: CORE_TYPES.GETTERS.IS_FA,
            isFK: CORE_TYPES.GETTERS.IS_FK,
            hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
            personId: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_ID,
            customerDataConfig: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_CONFIG,
            otherProductEdited: OTHER_PRODUCTS_TYPES.GETTERS.OTHER_PRODUCT_EDITED,
            isByPass: CORE_TYPES.GETTERS.IS_BYPASS,
            sprodRights: OTHER_PRODUCTS_TYPES.GETTERS.RIGHTS,
            otherProduct: OTHER_PRODUCTS_TYPES.GETTERS.OTHER_PRODUCT,
            combos: OTHER_PRODUCTS_TYPES.GETTERS.COMBOS,
            currentType: OTHER_PRODUCTS_TYPES.GETTERS.CURRENT_TYPE,
            sprodId: OTHER_PRODUCTS_TYPES.GETTERS.SELECTED_SPROD_ID,
        }),
        headers() {
            const result = {
            center: [],
            };

            if (this.sprod?.id && this.isIntern) {
                result.center.push(TextColumn("id", "Interne Nummer"));
            }

            if (!this.isImmobilie) {
                result.center.push(TextColumn("fremdanlage", "Fremdanlage"));
                result.center.push(TextColumn("autoupdate", "Automatisch aktualisieren"));
                
            }

            if (this.isFA && this.isByPass) {
                result.center.push(TextColumn("betreuerwechsel", "Betreuerwechsel"));

            }
            
            result.center.push(TextColumn("produktart", "Produktart"));

            if (this.isProduktartFreieEingabe) {
                result.center.push(TextColumn("freieEingabeProduktart", "Produktart Freie Eingabe"));

            }

            if (this.isImmobilie) {
                result.center.push(TextColumn("nutzung", "Nutzung Freie Eingabe"));
                result.center.push(TextColumn("wohnflaeche", "QM Wohnfläche gesamt"));
                result.center.push(TextColumn("wohnflaecheEigennutzung", "QM Wohnfläche Eigennutzung"));
                result.center.push(TextColumn("wohnflaecheFremdnutzung", "QM Wohnfläche Fremdnutzung"));
                result.center.push(TextColumn("gebaeudetyp", "Gebäudetyp"));
                result.center.push(TextColumn("begin", "Anschaffungsdatum"));
                result.center.push(CurrencyColumn("bezugssumme", "Kaufpreis"));
                result.center.push(CurrencyColumn("aktWert", "akt. Verkehrswert"));
                result.center.push(CurrencyColumn("miete", "Mietkosten pro Monat"));
                result.center.push(CurrencyColumn("einnahmen", "Mieteinnahmen pro Monat"));
                result.center.push(TextColumn("adresse1", "Adresse des Objektes 1"));
                result.center.push(TextColumn("adresse2", "Adresse des Objektes 2"));


            } else if (this.sprod?.produktart) {
                result.center.push(TextColumn("produktbez", "Produktbezeichnung"));
                result.center.push(TextColumn("company", "Gesellschaft"));
                result.center.push(TextColumn("tarif", "Tarif"));
                result.center.push(TextColumn("sprodNr", "Produktnummer"));
                result.center.push(TextColumn("begin", "Beginn"));
                result.center.push(TextColumn("ende", "Ende"));
                result.center.push(CurrencyColumn("aktWert", "aktueller Wert"));
                result.center.push(TextColumn("zahlrhythm", "Zahlrhythmus"));
                result.center.push(CurrencyColumn("beitrag", "Sparrate pro Periode"));
                result.center.push(CurrencyColumn("bezugssumme", "Anfangswert/Bezugssumme"));

            }

            if (this.sprodRights?.allowReadSollprovision) {
                result.center.push(TextColumn("provVorgabe", "Provision"));
                result.center.push(CurrencyColumn("sollprovision", "Sollprovision"));
            }

            if (this.sprodRights?.allowReadBearbStatus && !this.isImmobilie) {
                result.center.push(TextColumn("versBearbeitungsstatus", "Bearbeitungsstatus"));
                result.center.push(TextColumn("bearbStatusDate", "Datum Status"));
                result.center.push(TextColumn("bearbStatusBem", "Bemerkung Status"));
            }

            result.center.push(TextColumn("bankingAccount", "Bankverbindung"));
            result.center.push(TextColumn("bemerkung", "Beschreibung / Bemerkung"));


            return result;
        },       
        rows() {
            const rows = [];

            const row = {};

            if (this.sprod?.id && this.isIntern) {
                row.id = this.sprod?.id;
            }


            if (!this.isImmobilie) {
                // result.center.push(TextColumn("fremdanlage", "Fremdanlage"));
                row.fremdanlage = this.sprod?.fremdanlage ? 'Ja' : 'Nein';

                // result.center.push(TextColumn("autoupdate", "Automatisch aktualisieren"));
                row.autoupdate = this.sprod?.autoupdate ? 'Ja' : 'Nein';
                
            }

            if (this.isFA && this.isByPass) {
                // result.center.push(TextColumn("betreuerwechsel", "Betreuerwechsel"));
                row.betreuerwechsel = this.sprod?.betreuerwechsel ? 'Ja' : 'Nein';

            }
            
            // result.center.push(TextColumn("produktart", "Produktart"));
            row.produktart = this.sprod?.produktart;

            if (this.isProduktartFreieEingabe) {
                // result.center.push(TextColumn("freieEingabeProduktart", "Produktart Freie Eingabe"));
                row.freieEingabeProduktart = this.sprod?.freieEingabeProduktart;

            }

            if (this.isImmobilie) {
                // result.center.push(TextColumn("nutzung", "Nutzung Freie Eingabe"));
                row.nutzung = this.sprod?.nutzung;

                // result.center.push(TextColumn("wohnflaeche", "QM Wohnfläche gesamt"));
                row.wohnflaeche = this.sprod?.wohnflaeche;

                // result.center.push(TextColumn("wohnflaecheEigennutzung", "QM Wohnfläche Eigennutzung"));
                row.wohnflaecheEigennutzung = this.sprod?.wohnflaecheEigennutzung;

                // result.center.push(TextColumn("wohnflaecheFremdnutzung", "QM Wohnfläche Fremdnutzung"));
                row.wohnflaecheFremdnutzung = this.sprod?.wohnflaecheFremdnutzung;

                // result.center.push(TextColumn("gebaeudetyp", "Gebäudetyp"));
                row.gebaeudetyp = this.sprod?.gebaeudetyp;


                // result.center.push(TextColumn("begin", "Anschaffungsdatum"));
                // row.begin = this.sprod?.begin || './.';

                // result.center.push(CurrencyColumn("bezugssumme", "Kaufpreis"));
                row.bezugssumme = this.sprod?.bezugssumme || '-';

                // result.center.push(CurrencyColumn("aktWert", "akt. Verkehrswert"));
                row.aktWert = this.sprod?.aktWert || '-';

                // result.center.push(CurrencyColumn("miete", "Mietkosten pro Monat"));
                row.miete = this.sprod?.miete || '-';

                // result.center.push(CurrencyColumn("einnahmen", "Mieteinnahmen pro Monat"));
                row.einnahmen = this.sprod?.einnahmen || '-';


                // result.center.push(TextColumn("adresse1", "Adresse des Objektes 1"));
                row.adresse1 = this.sprod?.adresse1 || '-';

                // result.center.push(TextColumn("adresse2", "Adresse des Objektes 2"));
                row.adresse2 = this.sprod?.adresse2 || '-';


            } else if (this.sprod?.produktart) {
                // result.center.push(TextColumn("produktbez", "Produktbezeichnung"));
                row.produktbez = this.sprod?.produktbez || '-';

                // result.center.push(TextColumn("company", "Gesellschaft"));
                row.company = this.sprod?.company || '-';

                // result.center.push(TextColumn("tarif", "Tarif"));
                row.tarif = this.sprod?.tarif || '-';

                // result.center.push(TextColumn("sprodNr", "Produktnummer"));
                row.sprodNr = this.sprod?.sprodNr || '-';

                // result.center.push(TextColumn("begin", "Beginn"));
                row.begin = this.sprod?.begin || './.';

                // result.center.push(TextColumn("ende", "Ende"));
                row.ende = this.sprod?.ende || './.';

                // result.center.push(CurrencyColumn("aktWert", "aktueller Wert"));
                row.aktWert = this.sprod?.aktWert || '-';

                // result.center.push(TextColumn("zahlrhythm", "Zahlrhythmus"));
                row.zahlrhythm = this.sprod?.zahlrhythm || '-';

                // result.center.push(CurrencyColumn("beitrag", "Sparrate pro Periode"));
                row.beitrag = this.sprod?.beitrag || '-';


                // result.center.push(CurrencyColumn("bezugssumme", "Anfangswert/Bezugssumme"));
                row.bezugssumme = this.sprod?.bezugssumme || '-';

            }

            if (this.sprodRights?.allowReadSollprovision) {
                // result.center.push(TextColumn("provVorgabe", "Provision"));
                row.provVorgabe = this.sprod?.provVorgabe || '-';

                // result.center.push(CurrencyColumn("sollprovision", "Sollprovision"));
                row.sollprovision = this.sprod?.sollprovision || '-';
            }

            if (this.sprodRights?.allowReadBearbStatus && !this.isImmobilie) {
                // result.center.push(TextColumn("versBearbeitungsstatus", "Bearbeitungsstatus"));
                row.versBearbeitungsstatus = this.sprod?.versBearbeitungsstatus || '-';

                // result.center.push(TextColumn("bearbStatusDate", "Datum Status"));
                row.bearbStatusDate = this.sprod?.bearbStatusDate || '-';

                // result.center.push(TextColumn("bearbStatusBem", "Bemerkung Status"));
                row.bearbStatusBem = this.sprod?.bearbStatusBem || '-';
            }

            // result.center.push(TextColumn("bankingAccount", "Bankverbindung"));
            row.bankingAccount = this.sprod?.bankingAccount || '-';

            // result.center.push(TextColumn("bemerkung", "Beschreibung / Bemerkung"));
            row.bemerkung = this.sprod?.bemerkung || '-';

            rows.push(row);
            return rows;
        },
        tableId() {
            const uniqueUUID = 'fb82c311-91bb-40fa-b5c6-2afb40bef89c';
            return uniqueUUID;
        },      
        isImmobilie() {
            return this.sprod?.produktart === PRODUKTART_IMMOBILIE;
        },   
        isIntern() {
            return this.hasRoles([this.VIEW_ROLES.VIEW_CUSTOMER_AS_INTERN]);
        },        
        isProduktartFreieEingabe() {
            return this.checkIfProduktartIsFreieEingabe(this.sprod?.produktart);
        },        
        isNutzungMischnutzung() {
            return this.sprod?.nutzung === NUTZUNG_MISCHNUTZUNG;
        },        
    },
    watch: {
        otherProduct() {
            this.updateData();
        },
        currentType: {
        handler(value) {
            if(!this.sprod?.produktTyp) {
            this.sprod.produktTyp = value;
            }
        },
        immediate: true,
        },
    },
    methods: {
        updateData() {
            Object.keys(this.sprod).forEach(key => {
                if(key in this.otherProductEdited) {
                this.sprod[key] = this.otherProductEdited[key];
                } else if(key in this.otherProduct) {
                this.sprod[key] = this.otherProduct[key];
                }
            });

            if(this.sprod?.bankingAccount) {
                this.sprod.bankingAccount = `${this.sprod.bankingAccount}`;
            }
        },
        checkIfProduktartIsFreieEingabe(value) {
            return value?.startsWith(PRODUKTART_FREIE_EINGABE);
        },        
        setProduktartIfNeeded() {
            if(this.currentType === PRODUKTART_IMMOBILIE) {
                this.sprod.produktart = PRODUKTART_IMMOBILIE;
            }
        },
    },
    mounted() {
        this.updateData();
        this.setProduktartIfNeeded();
    },

};
</script>