<template>
  <div>
    <label>{{ label }}</label>
    <div class="row px-2">
      <div class="col-12 col-md-8 col-lg-10 basisfonds" :class="{'invalid': isInvalid}">
        {{ getFondStr }}
      </div>
      <div class="col-12 col-md-4 col-lg-2 pt-2">
        <div class="d-flex">
          <a :class="{'disabled': disabled || disableBtn}" @click="openFondsfinder" :title="addButtonTitle">
            <ph-pencil :size="32" class="pl-2"/>
          </a>
          <a :class="{'disabled': disabled || disableBtn || !isin}" @click="$refs.removeModal.open()" title="Position löschen" v-if="hasDelete">
            <ph-trash :size="32" class="pl-2"/>
          </a>
          <a :class="{'disabled': !isin}" @click="isin && gotoFondsinfo(isin)" title="Fondsinfos">
            <ph-info :size="32" class="pl-2"/>
          </a>
          <a :class="{'disabled': !isin}" @click="isin && openFactsheet()" title="Factsheet" v-if="hasFactsheet">
            <ph-file :size="32" class="pl-2"/>
          </a>
        </div>
      </div>
    </div>
    <BaseModal 
      ref="removeModal"
      labelButtonConfirm="Position Entfernen"
      labelButtonCancel="Abbrechen"
      @onConfirmButton="removePosition"
      >
      <template v-slot:modalTitle>
        Position entfernen?
      </template>
      <div>
        Soll die Position <b>{{ wertpapiername }} (ISIN {{ isin }}) </b>
        wirklich entfernt werden?
      </div>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types.js';
import FONDSFINDER_TYPES from '@/store/fondsfinder/types';
import FONDSINFO_TYPES from '@/store/fondsinfo/types';
import ANTRAG_TYPES from '@/store/antrag/types';
import InputField from '@/components/core/forms/InputField.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import antragNavigationMixin from '@/mixins/antrag/antrag-navigation-mixin.js';
import {wpListeMixin} from '@/mixins/wp-suche.js';
import { PhPencil, PhTrash, PhInfo, PhFile } from 'phosphor-vue'

const VALUE_FREIE_EINGABE = 'freie Eingabe:';

export default {
  mixins: [antragNavigationMixin, wpListeMixin],
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
      required: true,
    },
    antragWarnings: {
      type: Array
    },
    data: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    antragId: {
    },
    config: {
    },
    hasDelete: {
      type: Boolean,
      default: true,
    },
    hasFactsheet: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['change'],
  data() {
    return {
      isin: '',
      ignoreWarning: false,
      disableBtn: false,
    }
  },
  mounted() {
    this.setIsin();
  },
  computed: {
    ...mapGetters({
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
      positionInfo: FONDSFINDER_TYPES.GETTERS.POSITION_INFO,
    }),
    isInvalid() {
      return !this.ignoreWarning && this.antragWarnings && this.antragWarnings.some(warn => warn.id === this.id);
    },
    wertpapiername() {
      const result =  (this.isin && this.positionInfo && this.positionInfo[this.isin] && this.positionInfo[this.isin].wertpapiername) 
        || (this.id && this.data && this.data[this.id + '_wertpapiername'])
        || (this.config && this.data && this.data[this.config.isinName])
        || (this.data && this.data.basisfondWertpapiername)
        || '';
        return result;
    },
    getFondStr() {
      this.setIsin();
      return this.isin && this.data ? this.data['VIEW_BASISFONDS'] || (`${this.wertpapiername} (ISIN: ${this.isin})`) : 'Wertpapierauswahl ist leer.';
    },
    isEmbedded() {
      return this.config?.isEmbedded;
    },
    addButtonTitle() {
      return this.config?.addButtonText || 'Basisfonds hinzufügen';
    }
  },
  watch: {
    data(value) {
      const isin = value && value[this.id] || '';
      if (!this.isin && isin || isin !== this.isin) {
        this.$emit('updateIsin', { id: this.id, isin: isin });
      }

      this.isin = isin;
      if (!this.isEmbedded) {
        if (value['INPUT_COMBO_PORTFOLIO'] && value['INPUT_COMBO_PORTFOLIO'] !== VALUE_FREIE_EINGABE) {
          this.disableBtn = true;
          this.isin = '';
        } else {
          this.disableBtn = false;
        }
      }
    },
  },
  methods: {
    setIsin() {
      if (!this.isin && this.data && this.data[this.id]) {
        this.isin = this.data && this.data[this.id];
      }
      if (this.isin) {
        this.updateIsinName();
      }
    },
    openFondsfinder() {
      if (!this.isEmbedded) {
        this.ignoreWarning = true;
        if (!this.disableBtn) {
          this.isin = '';
          let query = this.config?.query && JSON.parse(this.config?.query) || {};
          query = Object.assign(query, { singleSelection: true, saveType: 'isin', isVWL: this.config && (this.config.isVwl || this.config.categoryId === 'VWL')});
          this.navigateToFormsFinder(this.id, query);
        }
      } else {
        this.$emit('openFondsfinder');
      }
    },
    openFactsheet() {
      if (this.isin) {
          this.$store.dispatch(FONDSINFO_TYPES.ACTIONS.GET_FACTSHEET_PDF, { isin: this.isin, type: 'FACTSHEET', lagerstelle: this.data?.lagerstelle || '' });
      }
    },
    removePosition() {
      if (!this.isEmbedded) {
        if (!this.disableBtn && this.isin) {
          this.isin = '';
          this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_ANTRAG_DATA, { 
            id: this.antragId, 
            data: { [this.id]: '', [this.config.isinName]: this.wertpapiername || '' }
          });
        }
      } else {
        this.$emit('removePosition', this.isin);
      }
    },
    updateIsinName() {
      if (this.config && this.config.isinName && this.data && this.wertpapiername !== this.data[this.config.isinName]) {
        this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_ANTRAG_DATA, { 
          id: this.antragId, 
          data: {[this.config.isinName]: this.wertpapiername || '' }
        });
      }
    }
  },
  components: {
    InputField,
    BaseModal,
    PhPencil,
    PhTrash,
    PhInfo,
    PhFile
  },
}
</script>
<style scoped>
  .basisfonds {
      background-color: var(--color-box);
      padding: 0.65rem 1.25rem 0.4rem 1.25rem;
      /* margin-left: 1rem; */
      border-width: 1px;
      border-radius: 2px;
      border-color: var(--color-text);
      border-style: solid;
  }

  a.disabled {
      color:  #c8c8c2;
      pointer-events: none;
      cursor: default;
  }

  .invalid {
    border: 1px solid var(--color-danger);
  }

  .las {
    font-size: 1.5rem;
  }
</style>
