<template>
    <div>
        <BaseHeaderContainer>
            <div class="top-navbar__container-bg">
                <Logout :size="20" class="mr-3"/>
                <div class="logged-user__container mr-5">{{loginName}}</div>
            </div>

            <BaseTopProgressBar />

            <div class="navbar-workspaces__container-bg">
                <div class="fc-container navbar-workspaces__container">
                    <div class="navbar-container__logo-container">
                        <img class="container__img" :src="logoLoginBoxBackground" v-if="logoLoginBoxBackground"/>
                    </div>
                    <div class="login-title">
                        {{loginWelcomeTitle}}
                    </div>
                </div>
            </div>
        </BaseHeaderContainer>
        
        <div class="fc-container">
            <SsoHinweise :isSsoKonto="loginName && true"/>
            <SsoKontoOverview />
        </div>
    </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import SSO_TYPES from '@/store/sso/types';
import { mapGetters } from 'vuex';
import BaseHeaderContainer from '@/components/core/BaseHeaderContainer.vue';
import SsoKontoOverview from '@/components/core/sso/SsoKontoOverview.vue';
import SsoHinweise from '@/components/core/sso/SsoHinweise.vue';
import Logout from '@/components/core/header/buttons/Logout.vue';
import BaseTopProgressBar from "@/components/core/BaseTopProgressBar.vue";

import { SmartMSCAppLabel } from '@/helpers/app-helper';

export default {
    data() {
        return {
            loginWelcomeTitle: SmartMSCAppLabel.WELCOME_TITLE,
        };
    },
    computed: {
        ...mapGetters({
            loginName: SSO_TYPES.GETTERS.LOGIN_NAME,
            apiAddressLegacy: CORE_TYPES.GETTERS.API_ADDRESS_LEGACY,
            imageBackgroundLoginUrl: CORE_TYPES.GETTERS.GET_BROKER_IMAGE_BACKGROUND_LOGIN_URL,
            maklerLogo: CORE_TYPES.GETTERS.GET_BROKER_LOGO,
            hasColorSchema: CORE_TYPES.GETTERS.HAS_COLOR_SCHEMA,
        }),
        loginContentBackground() {
            return this.imageBackgroundLoginUrl ? `url(${this.imageBackgroundLoginUrl})` : `url(${this.apiAddressLegacy}/../images/etc/layout/msc_wolken4.png)`;
        },
        logoLoginBoxBackground() {
            return this.maklerLogo || `${this.apiAddressLegacy}/../images/etc/layout/FK20-FinanceCloud.png`;
        },
    },
    mounted() {
        if (!this.loginName) {
            this.$router.push({name: 'login'});
        }
        this.$store.commit(CORE_TYPES.MUTATIONS.UPDATE_BROKER_LOGO, this.logoLoginBoxBackground);
    },
    components: {
        BaseHeaderContainer,
        SsoKontoOverview,
        SsoHinweise,
        Logout,
        BaseTopProgressBar,
    },
}
</script>

<style scoped>
* {
    box-sizing: border-box;
}
.top-navbar__container-bg {
    color: var(--color-top-nav-text);
    background-color: var(--color-top-nav-background);
    display: flex;
    justify-content: flex-end;
    font-size: 18px;
    height: 40px;
}
.logged-user__container {
  align-items: center;
  display: flex;
}
.navbar-container__logo-container {
  display: flex;
}

.navbar-workspaces__container-bg {
  background-color: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 40, 87, 0.1);
}

.navbar-workspaces__container {
    color: #ffffff;
    display: flex;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 12px;
}
.container__img {
    max-height: 75px;
  }
.login-title {
    color: #051031;
    padding-left: 3rem;
    font-size: 1.75rem;
    font-weight: 500;
}

@media screen and (max-width: 767px) {
    .navbar-workspaces__container-bg {
        display: none;
    }
}

</style>