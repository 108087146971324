export const MUTATION_PREFIX = 'VERMITTLERNUMMERN_MUTATIONS_';
export const ACTIONS_PREFIX = 'VERMITTLERNUMMERN_ACTIONS_';
export const GETTERS_PREFIX = 'VERMITTLERNUMMERN_GETTERS_';

export default {
  MUTATIONS: {
    RETRIEVE_MITARBEITER_STRUKTUR_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_MITARBEITER_STRUKTUR_SUCCESS',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
  },
  ACTIONS: {
    RETRIEVE_MITARBEITER_STRUKTUR: ACTIONS_PREFIX + 'RETRIEVE_MITARBEITER_STRUKTUR',
  },
  GETTERS: {
    MITARBEITER_STRUKTUR: GETTERS_PREFIX + 'MITARBEITER_STRUKTUR',
  }
}