import CMS_TYPES from './types';

export default {
  [CMS_TYPES.GETTERS.GET_CMS_TEMPLATE](state) {
    return state.template;
  },
  [CMS_TYPES.GETTERS.GET_CMS_FIRST_PAGE_ROUTE](state) {
    return state.firstPageRoute;
  },
  [CMS_TYPES.GETTERS.GET_CMS_COMPONENTS]:(state)=>{
    return state.components;
  },
  [CMS_TYPES.GETTERS.GET_CMS_COMPONENTS_READY]:(state)=>{
    return state.componentsReady;
  },
  [CMS_TYPES.GETTERS.GET_CMS_COMPONENT_CURRENT_CONTENT]:(state)=>{
    return state.componentCurrentContent;
  },
  [CMS_TYPES.GETTERS.GET_CMS_COMPONENT_CONTENT_UPDATED ]:(state)=>{
    return state.componentContentUpdated;
  },
  [CMS_TYPES.GETTERS.GET_USER_ID]:(state)=>{
    return state.userID;
  },
  [CMS_TYPES.GETTERS.GET_CMS_NEWS]:(state)=>{
    return state.cmsNews;
  },
  [CMS_TYPES.GETTERS.GET_PRIVACY_LINK]:(state)=>{
    return state.privacyLink;
  },
  [CMS_TYPES.GETTERS.GET_COMPONENT_GROUPS]:(state)=>{
    return state.componentGroups;
  },
  [CMS_TYPES.GETTERS.GET_SELECTED_FOND]:(state)=>{
    return state.selectedFond;
  },
  [CMS_TYPES.GETTERS.GET_PORTFOLIOS]:(state)=>{
    return state.portfolios;
  },
  


  [CMS_TYPES.GETTERS.GET_EXISTING_INSURANCES ]:(state)=>{
    return state.existingInsurances;
  },
  [CMS_TYPES.GETTERS.GET_TABLE_INSURANCES ]:(state)=>{
    return state.tablerecords;
  },
  [CMS_TYPES.GETTERS.GET_RECORDS_READY ]:(state)=>{
    return state.areRecordsReady;
  },
  [CMS_TYPES.GETTERS.GET_TARIFF_DETAILS ]:(state)=>{
    return state.tariffDetails;
  },
  [CMS_TYPES.GETTERS.GET_TARIFF_DETAILS_READY ]:(state)=>{
    return state.areTariffDetailsReady;
  },

  [CMS_TYPES.GETTERS.GET_FONDSSHOP_PARAMS ]:(state)=>{
    return state.fondsshopParams;
  },
}