<template>
  <div class="input-forms__container">
    <div class="input-forms__label-container">
      <div class="row mt-0">
        <div class="col">
          <div
            class="
              input-forms__label-content input-forms__label-content--bigger
            "
            v-if="title"
          >
            <PhBank :size="16" />&nbsp;{{ title }}
          </div>
        </div>
      </div>
    </div>
    <Table
        :headers="headers"
        :rows="rows"
        rowId="isin"
        hidePagination
    >
        <template v-slot:betrag="row">
            <InputField
                :value="row.betrag"
                :id="'betrag' + row.index"
                @input="updatePositionData($event, row, 'betrag')"
                type="currency"
            />
        </template>
    </Table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ANTRAG_TYPES from "@/store/antrag/types";
import antragNavigationMixin from "@/mixins/antrag/antrag-navigation-mixin.js";
import antragMixin from "@/mixins/antrag/antrag-mixin.js";
import Table from "@/components/table2/Table.vue";
import {TextColumn, IconColumn, Icon, SlotColumn} from "@/components/table2/table_util.js";
import InputField from "@/components/core/forms/InputField.vue";
import { PhBank, PhCheck, PhSquare } from "phosphor-vue";

export default {
  mixins: [antragNavigationMixin, antragMixin],
  data() {
    return {
        fremdwaehrungValues: [],
        positionDataAdditional: {},
        headers: {
            lockedLeft: [
                IconColumn("vorhanden", "Vorhanden"),
                TextColumn("fondsname", "Fondsname"),
            ],
            center: [
                TextColumn("isin", "ISIN"),
            ],
            lockedRight: [
                SlotColumn("betrag", "Einmalanlage (in Euro)"),
            ],
        },
    };
  },
  props: {
    title: {},
    categoryId: {},
    antragId: {},
    antragTyp: {},
    config: {},
  },
  computed: {
    ...mapGetters({
      positionsAll: ANTRAG_TYPES.GETTERS.POSITIONS,
      positions: ANTRAG_TYPES.GETTERS.POSITIONS_FREMDWAEHRUNG,
      antraege: ANTRAG_TYPES.GETTERS.ANTRAEGE,
       antraegeData: ANTRAG_TYPES.GETTERS.ANTRAEGE_DATA,
    }),
   data() {
      if (this.antraegeData && this.antraegeData[this.antragId]) {
        return this.antraegeData[this.antragId]
      } else if (this.data && Object.keys(this.data).length) {
        return this.data;
      }
      return null
    },
    rows() {
        const rows = this.positionsAll?.payload || [];
        return rows.map(row => {
            return {
                ...row,
                vorhanden: [row.betrag != null && row.betrag != ''
                    ? Icon(PhCheck, "Ja", 24)
                    : Icon(PhSquare, "Nein", 24)
                ],
            }
        });
    },
  },
  components: {
    Table,
    InputField,
    PhBank,
  },
  mounted() {
    this.loadFremdWaehrungen();
  },
  methods: {
    async loadFremdWaehrungen() {
           
      const antragsnrIntern =  this.data && this.data.antragsnrIntern;
      const response = await this.$store.dispatch(
        ANTRAG_TYPES.ACTIONS.GET_FREMDWAEHRUNG_VALUES, {id: antragsnrIntern}
      );

      if (response) {
        this.fremdwaehrungValues = response.data;
        this.$store.commit(
          ANTRAG_TYPES.MUTATIONS.GET_FREMDWAEHRUNG_VALUES_SUCESS,
          { payload: this.fremdwaehrungValues }
        );
      }
    },
    commitUpdatePositionsFromEvent(positionChange, isin) {
      const payload = {
        id: this.antragId,
        categoryId: this.categoryId,
        fonds: [
          {
            ...positionChange,
            isin,
          },
        ],
      };
      this.$store.commit(
        ANTRAG_TYPES.MUTATIONS.UPDATE_POSITIONS_FREMDWAEHRUNG,
        payload
      );
    },
    onChangeInputField($event, position, field) {
      const positionChange = { [field]: $event };
      this.commitUpdatePositionsFromEvent(positionChange, position.isin);
    },
    updatePositionData($event, position, field) {
      const positionChange = { [field]: $event };
      
        const fieldValue = field;

        if (fieldValue == "betrag") {
          let id = position.fondsname;
          this.positionDataAdditional[id] = {
            betrag: $event,
            fondsname: position.fondsname,
            isin:'BANK' + position.fondsname + 'EBASE'
          };
        }
        const payload = {
          id: this.antragId,
          categoryId: "KAUF",
          positionData: this.positionDataAdditional,
        };
        this.commitUpdatePositionsFromEvent(
          positionChange,
          position.isin,
          "KAUF"
        );
        this.$store.commit(
          ANTRAG_TYPES.MUTATIONS.UPDATE_POSITION_DATA,
          payload
        );
      
    },
  },
};
</script>