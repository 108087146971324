var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "input-forms__container",
      class: { "input-forms__container--embedded": _vm.isEmbedded }
    },
    [
      _c(
        "label",
        {
          staticClass: "input-forms__label-container",
          on: { mousedown: _vm.onMouseDown }
        },
        [
          _vm.label
            ? _c("div", { staticClass: "input-forms__label-content" }, [
                _vm._v(_vm._s(_vm.label))
              ])
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "input-forms__input-container",
              class: {
                "slider-input-disabled": _vm.disabled,
                "forms__input--half-size": this.isComponentHalfSize
              }
            },
            [
              _c("div", {
                ref: "rail",
                staticClass: "slider-input-rail",
                on: { mousedown: _vm.onMouseDown }
              }),
              _c("div", {
                staticClass: "slider-track",
                style: { width: _vm.handlerPosition + "%" },
                on: { mousedown: _vm.onMouseDown }
              }),
              _vm._l(_vm.stops, function(stop, index) {
                return _c(
                  "div",
                  {
                    key: stop.value,
                    staticClass: "slider-input-stop",
                    class: "slider-input-stop-" + _vm.stopFormat,
                    style: { left: stop.stepStyle },
                    on: { mousedown: _vm.onMouseDown }
                  },
                  [
                    _vm.showStopLabel
                      ? _c(
                          "span",
                          {
                            staticClass: "slider-input-stop-label",
                            class: {
                              "slider-input-stop-label--left": index === 0,
                              "slider-input-stop-label--center":
                                index > 0 && index < _vm.values.length - 1,
                              "slider-input-stop-label--right":
                                index === _vm.values.length - 1
                            }
                          },
                          [_vm._v(" " + _vm._s(stop.label) + " ")]
                        )
                      : _vm._e()
                  ]
                )
              }),
              _c(
                "div",
                {
                  staticClass: "slider-input-handler",
                  style: { left: _vm.leftHandler },
                  attrs: { tabindex: "0" },
                  on: {
                    mousedown: _vm.onMouseDown,
                    keyup: [
                      function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "right", 39, $event.key, [
                            "Right",
                            "ArrowRight"
                          ])
                        ) {
                          return null
                        }
                        if ("button" in $event && $event.button !== 2) {
                          return null
                        }
                        return _vm.moveRight.apply(null, arguments)
                      },
                      function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "left", 37, $event.key, [
                            "Left",
                            "ArrowLeft"
                          ])
                        ) {
                          return null
                        }
                        if ("button" in $event && $event.button !== 0) {
                          return null
                        }
                        return _vm.moveLeft.apply(null, arguments)
                      }
                    ]
                  }
                },
                [
                  _vm.isShowTooltip
                    ? _c("span", { staticClass: "slider-label" }, [
                        _vm._v(
                          _vm._s(
                            _vm.selectedStop.label || _vm.selectedStop.value
                          )
                        )
                      ])
                    : _vm._e()
                ]
              )
            ],
            2
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }