import ANSPRECHPARTNER_TYPES from './types';

export default {

  [ANSPRECHPARTNER_TYPES.MUTATIONS.SET_ANSPRECHPARTNER](state, payload) {
    state.ansprechpartnerData = payload
  },
  [ANSPRECHPARTNER_TYPES.MUTATIONS.SET_ANSPRECHPARTNER_SELECTIONS](state, payload) {
    state.comboboxSelections = payload
  },
  [ANSPRECHPARTNER_TYPES.MUTATIONS.UPDATE_ANSPRECHPARTNER_DATA](state, payload) {
    state.ansprechpartnerData[payload.fieldId] = payload.value
    state.dataHasChanged = true
  },
  [ANSPRECHPARTNER_TYPES.MUTATIONS.GET_USER_PICTURE_SUCCESS](state, payload) {
    state.userPicture = payload
  },
  [ANSPRECHPARTNER_TYPES.MUTATIONS.SET_TEMP_USER_PICTURE](state, payload) {
    state.tempUserPicture = payload
    state.dataHasChanged = true
  },

}
