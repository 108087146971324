import FONDSVERGLEICH_TYPES, {resetSession} from './types';
import Vue from 'vue';

export default {
  [FONDSVERGLEICH_TYPES.MUTATIONS.SETTINGS](state, payload) {
    state.settings = payload;
    state.session.settings = payload;
  },
  [FONDSVERGLEICH_TYPES.MUTATIONS.RESET_ACT](state) {
    if (state.session ) {
      state.session = resetSession(state.settings, state.session.fvp);
    }
  },
  [FONDSVERGLEICH_TYPES.MUTATIONS.SET_ACT](state, payload) {
    if (payload.settings !== undefined) {
      Vue.set(state.session, 'settings', payload.settings);
    }
    if (payload.stepKey !== undefined && state.session.settings) {
      state.session.stepKey = payload.stepKey;
      state.session.substepKey = payload.substepKey;
    }
    if (payload.fundsdata !== undefined) {
      Vue.set(state.session, 'fundsdata', payload.fundsdata);
    }
    if (payload.wertentw !== undefined) {
      Vue.set(state.session, 'wertentw', payload.wertentw);
    }
    if (payload.renditeRisiko !== undefined) {
      Vue.set(state.session, 'renditeRisiko', payload.renditeRisiko);
    }
    if (payload.hasVergleich !== undefined) {
      Vue.set(state.session, 'hasVergleich', payload.hasVergleich);
    }
    if (payload.ergebnisLoading !== undefined) {
      Vue.set(state.session, 'ergebnisLoading', {
        ...(state.session.ergebnisLoading || {}),
        ...payload.ergebnisLoading,
      });
    }
    if (payload.fvp !== undefined) {
      Vue.set(state.session, 'fvp', payload.fvp);
    }
  },
  [FONDSVERGLEICH_TYPES.MUTATIONS.SET_FVP](state, payload) {
    if (payload.fv_params !== undefined) {
      state.settings.fv_params = payload.fv_params;
      Vue.set(state.session.settings, 'fv_params', payload.fv_params);
    }
  },
  [FONDSVERGLEICH_TYPES.MUTATIONS.SET_FUNDS](state, payload) {
    Vue.set(state.session, 'fundsCompare', payload);
  },
  [FONDSVERGLEICH_TYPES.MUTATIONS.SET_UNSEL_FUNDS](state, payload) {
    Vue.set(state.session, 'fundsUnselect', payload);
  },
  [FONDSVERGLEICH_TYPES.MUTATIONS.SET_START_END_ZOOM](state, payload) {
    Vue.set(state.session, 'zoom', payload);
  },
  [FONDSVERGLEICH_TYPES.MUTATIONS.SET_FILTER](state, payload) {
    Vue.set(state, 'filter', payload);
  },
}