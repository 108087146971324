var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.isMobileNativeContext
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-6 border-right" }, [
            _c(
              "div",
              {
                staticClass: "d-flex",
                staticStyle: { "flex-direction": "column" }
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "py-1",
                    staticStyle: { "text-align": "right" }
                  },
                  [_vm._v("Ausgaben")]
                ),
                _c("ChatHorizontalBullet", {
                  staticClass: "py-1",
                  staticStyle: { transform: "rotate(180deg)" },
                  attrs: {
                    current: _vm.lastMonthExpenses,
                    average: _vm.lastThreeMonthExpensesAverage
                  }
                }),
                _c("div", { staticClass: "d-flex py-1 justify-content-end" }, [
                  _c("span", { staticClass: "mx-1" }, [_vm._v("30 Tage:")]),
                  _vm._v("  "),
                  _c(
                    "span",
                    [
                      _c("CurrencyLabel", {
                        attrs: { value: _vm.lastMonthExpenses }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "d-flex py-1 justify-content-end" }, [
                  _c("span", { staticClass: "mx-1" }, [_vm._v("Ø 90 Tage:")]),
                  _vm._v("  "),
                  _c(
                    "span",
                    [
                      _c("CurrencyLabel", {
                        attrs: { value: _vm.lastThreeMonthExpensesAverage }
                      })
                    ],
                    1
                  )
                ])
              ],
              1
            )
          ]),
          _c("div", { staticClass: "col-6" }, [
            _c(
              "div",
              {
                staticClass: "d-flex",
                staticStyle: { "flex-direction": "column" }
              },
              [
                _c("span", { staticClass: "py-1" }, [_vm._v(" Einnahmen ")]),
                _c("ChatHorizontalBullet", {
                  staticClass: "py-1",
                  attrs: {
                    current: _vm.lastMonthIncome,
                    average: _vm.lastThreeMonthIncomeAverage
                  }
                }),
                _c("div", { staticClass: "d-flex py-1" }, [
                  _c("span", { staticClass: "mx-1" }, [_vm._v("30 Tage:")]),
                  _vm._v("  "),
                  _c(
                    "span",
                    [
                      _c("CurrencyLabel", {
                        attrs: { value: _vm.lastMonthIncome }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "d-flex py-1" }, [
                  _c("span", { staticClass: "mx-1" }, [_vm._v("Ø 90 Tage:")]),
                  _vm._v("  "),
                  _c(
                    "span",
                    [
                      _c("CurrencyLabel", {
                        attrs: { value: _vm.lastThreeMonthIncomeAverage }
                      })
                    ],
                    1
                  )
                ])
              ],
              1
            )
          ])
        ])
      : _c(
          "div",
          [
            _c("FlexibleList", {
              attrs: { rows: _vm.mobileTable.rows },
              scopedSlots: _vm._u([
                {
                  key: "title",
                  fn: function(row) {
                    return [_vm._v(" " + _vm._s(row.title) + " ")]
                  }
                },
                {
                  key: "value",
                  fn: function(row) {
                    return [
                      _c("CurrencyLabel", { attrs: { value: row.value } })
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }