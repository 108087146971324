import CORE_TYPES from '@/store/core/types'

import axios from 'axios';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import OverviewTable from '@/views/communication/OverviewTable.vue';

import { mapGetters } from "vuex";
import {looksLikeKundennr} from '@/views/customer/customer-utils';
import COMMUNICATION_TYPES from "@/store/communication/types";
export default {
    mixins: [],
    components: {
        GhostLoading,
        NoData,
        OverviewTable,
    },
    data() {
        return {
            records: [],
            loading: false,
        };
    },
    computed: {
        ...mapGetters({
            isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
        }),
    },
    mounted() {
        this.doSearch()
    },
    methods: {
        canOpenCustomer(row) {
            return !this.isCustomer && !!row.nummer && looksLikeKundennr(row.nummer)
        },
        openCustomerNewTab(customer) {
            if (customer?.nummer) {
              this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { customerId: customer.nummer });
            }
        },
        doSearch() {
            this.loading = true;
        
            axios.get(`${process.env.VUE_APP_API}/postfachOverview/list/${this.routeStep}`, { defaultSpinner: true, })
            .then(response => {
                this.records = response.data || [];
                if(this.routeStep=='chats'){
                  /* let cnt=this.records.filter(t => t.unread).length;
                   let dat={};
                   dat.chats=cnt; 
                   this.$store.commit(COMMUNICATION_TYPES.MUTATIONS.UNREAD_COMMUNICATIONS, dat);*/
                   this.$store.dispatch (COMMUNICATION_TYPES.ACTIONS.UNREAD_COMMUNICATIONS);
                }
            
            })
            .finally(() => this.loading = false)
        },
    },
}