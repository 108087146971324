export const MUTATION_PREFIX = 'MY_GOALS_MUTATIONS_';
export const ACTIONS_PREFIX = 'MY_GOALS_ACTIONS_';
export const GETTERS_PREFIX = 'MY_GOALS_GETTERS_';

export default {
  MUTATIONS: {
    GET_GOALS_SUCCESS: MUTATION_PREFIX + 'GET_GOALS_SUCCESS',
    UPDATE_STORE: MUTATION_PREFIX + 'UPDATE_STORE',
    ADD_GOAL: MUTATION_PREFIX + 'ADD_GOAL',
    UPDATE_FILE: MUTATION_PREFIX + 'UPDATE_FILE',
  },
  ACTIONS: {
    GET_GOALS: ACTIONS_PREFIX + 'GET_GOALS',
    SAVE_GOAL: ACTIONS_PREFIX + 'SAVE_GOAL',
    DELETE_GOAL: ACTIONS_PREFIX + 'DELETE_GOAL',
    GET_DEPOTS: ACTIONS_PREFIX + 'GET_DEPOTS',
    ADD_DEPOTS: ACTIONS_PREFIX + 'ADD_DEPOTS',
    REMOVE_DEPOT: ACTIONS_PREFIX + 'REMOVE_DEPOT',
    REMOVE_ALL_DEPOTS: ACTIONS_PREFIX + 'REMOVE_ALL_DEPOTS',
    UPLOAD_FILE: ACTIONS_PREFIX + 'UPLOAD_FILE',
    DELETE_FILE: ACTIONS_PREFIX + 'DELETE_FILE',
  },
  GETTERS: {
    GOALS: GETTERS_PREFIX + 'GOALS',
    SELECTED_GOAL_ID: GETTERS_PREFIX + 'SELECTED_GOAL_ID',
    BASE_URL: GETTERS_PREFIX + 'BASE_URL',
  }
}