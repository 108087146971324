var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      !_vm.loading && _vm.rows.length
        ? _c("Table", {
            attrs: {
              tableId: "e1078c62-25d3-4185-a475-a6c2a2e7106f",
              headers: _vm.headers,
              rows: _vm.rows,
              rowsPerPage: 10,
              rowId: "sid"
            },
            on: {
              "click-label": _vm.openEditModal,
              "action-DELETE": _vm.openDeleteModal
            }
          })
        : _vm.loading
        ? _c(
            "div",
            { staticClass: "text-centered" },
            [_c("AnimatedSpinner")],
            1
          )
        : _c("div", [_vm._v("Keine Daten")]),
      _c("BaseModal", {
        ref: "modalDelete",
        attrs: { modalTitle: "Eintrag löschen", size: "sm" },
        on: {
          onConfirmButton: function($event) {
            return _vm.deleteAppointment()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _vm._v(
                  ' Soll der Eintrag "' +
                    _vm._s(
                      (_vm.appointmentToDelete &&
                        _vm.appointmentToDelete.label) ||
                        ""
                    ) +
                    '" wirklich gelöscht werden? '
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }