var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-forms__container" }, [
    _c("label", { staticClass: "input-forms__label-container" }, [
      _vm.label
        ? _c("div", { staticClass: "input-forms__label-content" }, [
            _vm._v(_vm._s(_vm.label))
          ])
        : _vm._e(),
      _c("div", { staticClass: "input-forms__input-container" })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }