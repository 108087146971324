var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container-wrap" },
    [
      _c("div", { staticClass: "box__container-rows" }, [
        _c("div", { staticClass: "interne-bemerkung-content" }, [
          _c("span", {
            staticClass: "interne-bemerkung-text",
            domProps: { innerHTML: _vm._s(_vm.interneBemerkungText) }
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn-clear clickable",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  return _vm.edit()
                }
              }
            },
            [_c("PhPencilLine", { attrs: { size: 16 } })],
            1
          )
        ])
      ]),
      _c("BaseModal", {
        ref: "interneBemerkungEditModal",
        attrs: {
          modalTitle: "Anmerkungen bearbeiten",
          confirmDisabled: !_vm.edited
        },
        on: {
          onConfirmButton: function($event) {
            return _vm.save()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c("InputTextArea", {
                  attrs: {
                    label: "Anmerkungen (für den Kunden nicht sichtbar)",
                    rows: 5,
                    maxlength: 4000
                  },
                  model: {
                    value: _vm.interneBemerkungEdit,
                    callback: function($$v) {
                      _vm.interneBemerkungEdit = $$v
                    },
                    expression: "interneBemerkungEdit"
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }