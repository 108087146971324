<template>
  <BaseModal
      ref="vertragsPersonenModal"
      modalTitle="Vertragspersondaten"
      labelButtonConfirm="Fertig "
      @onConfirmButton="onConfirmModal()"
      @onCancelButton="onCancelModal()"
      @onCloseButton="onCancelModal()"
    >
    <div v-if="form.selectedPerson || form.vertragId">
      <InputField
        v-model="form.vertragBeschreibung"
        :disabled="true"
        label="Vertrag"
      />

      <ComboBox
        label="Rolle"
        v-model="form.rolle"
        :firstEmpty="true"
        :values="vertragsPersonenData.availableRoles"
      />        

      <EntitySelector
        isPersonPicker
        label="Person"
        :value="form.selectedPerson"
        :values="vertragsPersonenData.availablePersons"
        @new="newPerson()"
        @change="updateForm($event)"
      />

    </div>

  </BaseModal>
</template>


<script>
import InsuranceDetailMixin from './InsuranceDetail-mixin.js';
import { mapGetters } from 'vuex';
import BaseButton from '@/components/core/BaseButton.vue';
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";
import EntitySelector from '@/components/core/forms/EntitySelector.vue';
import BaseModal from "@/components/core/BaseModal.vue";
import InputField from "@/components/core/forms/InputField.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import CUSTOMERDATA_TYPES from '@/store/customerData/types';

export default {
  mixins: [InsuranceDetailMixin],
  components: {
    BaseButton,
    EntitySelector,
    BaseModal,
    InputField,
    ComboBox,
  },
  data: function () {
    return {
      form: {
        id: null,
        vertragBeschreibung: null,
        vertrag: null,
        selectedPerson: null,
        rolle: null,
        vertragId: null,
      },
    };
  },
  props: {
    details: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
      vertragsPersonenData: VERSICHERUNG_TYPES.GETTERS.VERTRAGSPERSONENDATA,
    }),
  },
  methods: {
    closeModal() {

    },
    open() {
      this.form.id = this.vertragsPersonenData?.id;
      this.form.vertragBeschreibung = this.vertragsPersonenData?.vertragBeschreibung;
      this.form.selectedPerson = this.vertragsPersonenData?.selectedPerson?.value;
      this.form.rolle = this.vertragsPersonenData?.rolle;
      this.form.vertragId = this.vertragsPersonenData?.vertragId;
      this.$refs.vertragsPersonenModal.open();
    },
    onConfirmModal() {
        this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.SAVE_VERTRAG_PERSONEN_DATA, this.form).then(response => {
            this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.RETRIEVE_VERTRAGPERSONEN_LIST, this.form);

          });
    },
    onCancelModal() {

    },
    updateForm(newValue) {
      this.form.selectedPerson = newValue;
    },
    newPerson() {
      const personId = 'neue_person';
      this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.UPDATE_SELECTED_CUSTOMER_DATA, personId);
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GOTO_CUSTOMER_DATA);
    },    

}
}

</script>

<style scoped>
.align-end {
  flex: 1 1 auto;
  align-self: flex-end;
  padding: 0px 0px 16px 5px;
}
.form-control {
  display: flex;
  align-content: stretch;
  align-items: center;
}
</style>