<template>
<div>
    <AssignInsurance
        type="pdfBox"
        :id="this.$route.query.id"
        :isMrMoney="true"
        :versicherungId="this.$route.query.insuranceId">
    </AssignInsurance>

 
</div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import AssignInsurance from '@/components/bipro/AssignInsurance.vue';
import BIPRO_TYPES from '@/store/bipro/types';

export default {
    components: {
        OptionMenu,
        AssignInsurance,
    },
    beforeRouteLeave(to, from, next) {
        this.$addBreadcrumb({to, from});
        this.$store.commit(BIPRO_TYPES.MUTATIONS.PARAM_RETURN_VERS_VERTRAG, null);
        next();
    },
}
</script>


<style scoped>
.info-headers {
    display: grid;
    gap: 3px 10px;
    grid-template-columns: 1fr;
}
@media (min-width: 960px) {
.info-headers {
    grid-template-columns: 1fr 1fr;
}
}
/* .info-headers > .info-header {
    width: calc(50% - 10px);
    padding-right: 10px;
} */
.info-header {
    display: flex;
    flex-direction: row;
    margin-bottom: 0;
}
.info-header > :first-child {
    flex-basis: 230px;
    font-weight: bold;
}
.info-header > :nth-child(2) {
    flex: 1;
}

.green {
    background-color: var(--color-success)
}
</style>
