import EX_POST_MANUELLE_TYPES from './types';
import CORE_TYPES from '@/store/core/types';

import axios from 'axios';

const config = {
  defaultSpinner: true
};

export default {

  async [EX_POST_MANUELLE_TYPES.ACTIONS.FIND_LIST]({ getters, commit, }) {
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/exPostManuelle`, config);
    commit(EX_POST_MANUELLE_TYPES.MUTATIONS.SET_LIST, response?.data || []);
  },

  async [EX_POST_MANUELLE_TYPES.ACTIONS.SAVE]({ getters, dispatch, }, payload) {
    if (!payload) return;

    return await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/exPostManuelle`, payload, config)
      .then(() => dispatch(EX_POST_MANUELLE_TYPES.ACTIONS.FIND_LIST));
  },

  async [EX_POST_MANUELLE_TYPES.ACTIONS.DELETE]({ getters, dispatch, }, { id }) {
    if (!id) return;

    await axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/exPostManuelle/${id}`, config);
    dispatch(EX_POST_MANUELLE_TYPES.ACTIONS.FIND_LIST);
  },

  async [EX_POST_MANUELLE_TYPES.ACTIONS.FIND_COMBOS]({ getters, commit, }) {
    const isLoaded = getters[EX_POST_MANUELLE_TYPES.GETTERS.IS_COMBOS_LOADED];
    if (isLoaded) return;

    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/exPostManuelle/combos`, config);
    commit(EX_POST_MANUELLE_TYPES.MUTATIONS.SET_COMBOS, response?.data || {});
  },

}