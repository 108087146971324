var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c("GhostLoading", { attrs: { type: "table" } })
        : [
            _c(
              "div",
              { staticClass: "box__container" },
              [
                _c("div", { staticClass: "box__title" }, [
                  _vm._v("Zugangsdaten")
                ]),
                _c("InputToggleSwitch", {
                  attrs: {
                    label: "Zugang gestattet",
                    value: _vm.ansprechpartner.zugang
                  },
                  on: { input: _vm.zugangFreischalten }
                }),
                _c("InputToggleSwitch", {
                  attrs: {
                    label: "Zugang wegen falscher Passworteingabe gesperrt",
                    disabled: ""
                  }
                }),
                _c("InputField", {
                  attrs: {
                    label: "Persönliche Nutzerkennung",
                    disabled: "",
                    value: _vm.ansprechpartner.zugang_nutzerkennung
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "box__container" },
              [
                _c("div", { staticClass: "box__title" }, [
                  _vm._v("Sichtbarkeit")
                ]),
                _vm.ansprechpartner.maklerBesitzer
                  ? _c("InputField", {
                      attrs: {
                        label: "Maklernummer Besitzer",
                        disabled: "",
                        value: _vm.ansprechpartner.maklerBesitzer
                      }
                    })
                  : _vm._e(),
                _c("InputToggleSwitch", {
                  attrs: {
                    label: "Für Makler sichtbar (Ansprechpartner)",
                    value: _vm.ansprechpartner.visibleToMakler
                  },
                  on: {
                    input: function($event) {
                      return _vm.updateAP("visibleToMakler", $event)
                    }
                  }
                }),
                _vm.ansprechpartner.maklerBesitzer
                  ? _c("InputToggleSwitch", {
                      attrs: {
                        label: "Freigabe für die Vermittler-Struktur",
                        disabled: !_vm.ansprechpartner.visibleToMakler,
                        value: _vm.ansprechpartner.struktur
                      },
                      on: {
                        input: function($event) {
                          return _vm.updateAP("struktur", $event)
                        }
                      }
                    })
                  : _vm._e(),
                !_vm.ansprechpartner.zugang
                  ? _c("InputToggleSwitch", {
                      attrs: {
                        label: "Ansprechpartner ist inaktiv",
                        value: _vm.ansprechpartner.ansprechpartnerInaktiv
                      },
                      on: {
                        input: function($event) {
                          return _vm.updateAP("ansprechpartnerInaktiv", $event)
                        }
                      }
                    })
                  : _vm._e(),
                _c("Table", {
                  attrs: {
                    title:
                      "Einschränkungen der Sichtbarkeit auf bestimmte Strukturen",
                    rowId: "maklernr",
                    rows: _vm.sichtbarkeitenRows,
                    headers: _vm.sichtbarkeitenHeaders,
                    headerActions: _vm.sichtbarkeitenTableHeaderActions,
                    rowsPerPage: 20
                  },
                  on: {
                    "action-DELETE": _vm.deleteSichtbarkeit,
                    "headerAction-NEW_SICHTBARKEIT":
                      _vm.openCreateSichtbarkeitModal
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "box__container" },
              [
                _c("Table", {
                  attrs: {
                    title: "Termine",
                    rowId: "commId",
                    rows: _vm.termineRows,
                    headers: _vm.termineHeaders,
                    headerActions: _vm.termineTableHeaderActions,
                    rowsPerPage: 20
                  },
                  on: {
                    "click-betreff": _vm.editOrCreateAppointment,
                    "action-DELETE": _vm.deleteTermin,
                    "headerAction-CREATE_TERMIN": _vm.editOrCreateAppointment
                  }
                })
              ],
              1
            )
          ],
      _c(
        "BaseModal",
        {
          ref: "createSichtbarkeitModal",
          attrs: {
            modalTitle: "Neue Einschränkung",
            showDefaultButtons: false
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c("BaseButton", {
                    attrs: { label: "Abbrechen", isSecondary: "" },
                    on: {
                      click: function($event) {
                        return _vm.closeCreateSichtbarkeitModal()
                      }
                    }
                  }),
                  _c("BaseButton", {
                    attrs: { label: "Nicht sichtbar" },
                    on: {
                      click: function($event) {
                        return _vm.addSichtbarkeit(false)
                      }
                    }
                  }),
                  _c("BaseButton", {
                    attrs: { label: "Nur sichtbar" },
                    on: {
                      click: function($event) {
                        return _vm.addSichtbarkeit()
                      }
                    }
                  })
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("InputField", {
            attrs: {
              label: "Maklernr Strukturleiter",
              isComponentHalfSize: ""
            },
            model: {
              value: _vm.editSichtbarkeit.maklernr,
              callback: function($$v) {
                _vm.$set(_vm.editSichtbarkeit, "maklernr", $$v)
              },
              expression: "editSichtbarkeit.maklernr"
            }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }