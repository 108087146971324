import VIRTUALDEPOTS_TYPES from './types';
import Vue from 'vue';

export default {
  [VIRTUALDEPOTS_TYPES.MUTATIONS.LIST](state, payload) {
    Vue.set(state, 'list', payload);
  },
  [VIRTUALDEPOTS_TYPES.MUTATIONS.SET_VD](state, payload) {
    if ( payload.id != null ) {
      if (payload.items === undefined) {
        if (state.subdepot.id != payload.id ) {
          Vue.set(state.subdepot, 'id', payload.id);
          Vue.set(state.subdepot, 'data', {});
        }
      } else {
        const vd = {
          items: payload.items,
          lagerstellen: payload.lagerstellen,
          rowsFonds: payload.rowsFonds,
          rowsAuto: payload.rowsAuto,
        };
        Vue.set(state.subdepot, 'id', payload.id);
        Vue.set(state.subdepot, 'data', vd);
      }
    }
  },
}