<template>
  <div>
    <div class="defaultcard-container defaultcard" v-if="findLabelComp(editableComponent)">
      <span class="box__title"></span>
      <HtmlEditor
        doNotAppendHtmlBody
        isFontParams
        v-model="html"
        :textPlaceholderOptions="placeholdersOptions"
        @input="getUpdatedHtml($event, findLabelComp(editableComponent))"
      />
    </div>
  </div>
</template>

<script>
import DYNAMIC_DOCUMENT_TYPES from '@/store/dynamicDocument/types';
import { mapGetters } from 'vuex'
import HtmlEditor from '@/components/html-editor/HtmlEditor.vue';

export default {
  components: {
    HtmlEditor,
  },
  props: {
    editableComponent: {},
  },
  data() {
    return {
      html: null,
    }
  },
  computed: {
    ...mapGetters({
      placeholders: DYNAMIC_DOCUMENT_TYPES.GETTERS.PLACEHOLDERS,
    }),
    placeholdersOptions() {
      const keys = this.placeholders && Object.keys(this.placeholders)
      return this.placeholders && Object.values(this.placeholders).map((v, i) => ({
        name: keys[i],
        data: v.map(p => ({
          value: Object.values(p)[0],
          label: Object.keys(p)[0]
        })
        )
      })) || []
    },
  },
  mounted() {
    this.html = this.findLabelComp(this.editableComponent) && this.findLabelComp(this.editableComponent).content || ''
  },
  methods: {
    getUpdatedHtml(event, component) {
      if (component) {
        component.content = this.htmlEncode(event)
      }
    },


    // special characters need to be converted to their html entity because the htmlConverter cannot use them
    htmlEncode(s){
      return s.replace(/„/g, "&bdquo;").replace(/“/g, "&ldquo;");
    },
    findLabelComp(component) {
      if (!component)
        return
      if (component.editable && component.label || (component.htmlCardType && component.htmlCardType.includes('TEXT')) || ((component.content || component.content === "") && !component.htmlCardType && !component.label && !component.value))
        return component

      const findItemNested = (arr, nestingKey) => (
        arr.reduce((a, item) => {
          if (a || item.htmlCardType || !item.editable) return a;
          if (item.label || (component.content && !component.label && !component.value)) return item;
          if (item[nestingKey]) return findItemNested(item[nestingKey], nestingKey)
        }, null)
      );
      if (component.htmlComponents && component.htmlComponents.length) {
        return findItemNested(component.htmlComponents, "htmlComponents");
      }
    },
  },

}
</script>

<style scoped>
  .defaultcard-container {
    border: 1px solid;
    border-style: solid none none none;
    padding-top: 10px;
    margin-bottom:12px;
    margin-top: 12px;
  }
  .placeholders {
    display: flex;
    width: 100%;
    padding-top: 0.5rem;
  }
  .placeholder-control {
    padding: 0 0.5rem;
    cursor: pointer;
  }
  .d-flex .grow-1:last-child {
    padding-right: 0;
  }
  .grow-1 {
    flex-grow: 1;
    padding-right: 0.5em;
  }
</style>

<style>
  .defaultcard.hidden-editor .ProseMirror {
    display: none;
  }
</style>