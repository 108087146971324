import BROKER_STATISTICS_TYPES from './types';

export default{
  [BROKER_STATISTICS_TYPES.GETTERS.BROKER_STATISTICS_TITLES_LIST](state){
    return state.titlesList;
  },
  [BROKER_STATISTICS_TYPES.GETTERS.GET_TABLE_VIEWER](state){
    return state.tableViewer;
  },
  [BROKER_STATISTICS_TYPES.GETTERS.GET_DEEP_TITLE](state){
    return state.deepTitle;
  },
}