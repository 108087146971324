var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("HinweiseUndFehler", {
        attrs: { errors: _vm.warnings, hints: _vm.hints }
      }),
      !_vm.brokerData.zugriffCourtage
        ? _c("div", [_vm._v("Keine Daten")])
        : _c("div", { staticClass: "box__container" }, [
            _c("div", { staticClass: "font-bold" }, [
              _vm._v(
                " Bei den folgenden E-Mail Adressen können Sie auch mehrere Adressen hintereinander in ein Feld eintragen, diese dürfen NUR durch Leerstellen getrennt werden. Bedenken Sie dass bei automatischem Versand dann aber an all diese Adressen die E-Mails versandt werden. "
              )
            ])
          ]),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c(
            "BaseButton",
            {
              attrs: { disabled: _vm.isFormInvalid() },
              on: { click: _vm.standardEmailUebertragen }
            },
            [_vm._v(" Standard E-Mail an übrige Adressen übertragen ")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("InputField", {
            attrs: {
              label: "Standard",
              isComponentHalfSize: true,
              disabled: !(!_vm.isFPPlus || _vm.istFPPlusMaster || _vm.istDGFRP),
              validateUntouched: true
            },
            on: {
              change: function($event) {
                return _vm.addBrokerDataEdited("brokerData")
              }
            },
            model: {
              value: _vm.brokerData.emailStandard,
              callback: function($$v) {
                _vm.$set(_vm.brokerData, "emailStandard", $$v)
              },
              expression: "brokerData.emailStandard"
            }
          }),
          _vm.brokerData.zeigeAbsender
            ? _c("ComboBox", {
                attrs: {
                  label: "Absender",
                  isComponentHalfSize: "",
                  values: _vm.brokerData.absenderStandart
                },
                on: {
                  change: function($event) {
                    return _vm.addBrokerDataEditedCombo(
                      "brokerData",
                      "absenderStandartEmail",
                      $event
                    )
                  }
                },
                model: {
                  value: _vm.brokerData.absenderStandartEmail,
                  callback: function($$v) {
                    _vm.$set(_vm.brokerData, "absenderStandartEmail", $$v)
                  },
                  expression: "brokerData.absenderStandartEmail"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("InputField", {
            attrs: { label: "Geburtstag", isComponentHalfSize: true },
            on: {
              change: function($event) {
                return _vm.addBrokerDataEdited("brokerData")
              }
            },
            model: {
              value: _vm.brokerData.emailGeburtstag,
              callback: function($$v) {
                _vm.$set(_vm.brokerData, "emailGeburtstag", $$v)
              },
              expression: "brokerData.emailGeburtstag"
            }
          }),
          _vm.brokerData.zeigeAbsender
            ? _c("ComboBox", {
                attrs: {
                  label: "Absender",
                  isComponentHalfSize: "",
                  values: _vm.brokerData.absenderGeburtstag
                },
                on: {
                  change: function($event) {
                    return _vm.addBrokerDataEditedCombo(
                      "brokerData",
                      "absenderGeburtstagEmail",
                      $event
                    )
                  }
                },
                model: {
                  value: _vm.brokerData.absenderGeburtstagEmail,
                  callback: function($$v) {
                    _vm.$set(_vm.brokerData, "absenderGeburtstagEmail", $$v)
                  },
                  expression: "brokerData.absenderGeburtstagEmail"
                }
              })
            : _vm._e(),
          _c("InputCheckboxItem", {
            attrs: { label: "Zusenden" },
            on: {
              input: function($event) {
                return _vm.addBrokerDataEdited("brokerData")
              }
            },
            model: {
              value: _vm.brokerData.geburtstagZusenden,
              callback: function($$v) {
                _vm.$set(_vm.brokerData, "geburtstagZusenden", $$v)
              },
              expression: "brokerData.geburtstagZusenden"
            }
          }),
          _c("InputCheckboxItem", {
            attrs: { label: "Zusenden nur Kunden mit Bestand" },
            on: {
              input: function($event) {
                return _vm.addBrokerDataEdited("brokerData")
              }
            },
            model: {
              value: _vm.brokerData.kundenMitBestand,
              callback: function($$v) {
                _vm.$set(_vm.brokerData, "kundenMitBestand", $$v)
              },
              expression: "brokerData.kundenMitBestand"
            }
          }),
          !_vm.brokerData.geburtstagZusenden && _vm.brokerData.kundenMitBestand
            ? _c(
                "div",
                { staticClass: "fc-alert fc-alert-warning fc-alert-small" },
                [
                  _c("b", [_vm._v("Zusenden")]),
                  _vm._v(" muss auch aktiviert sein, wenn "),
                  _c("b", [_vm._v("Zusenden nur Kunden mit Bestand")]),
                  _vm._v(
                    " aktiviert ist. Andernfalls werden die E-Mails nicht versendet. "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("InputField", {
            attrs: { label: "Limit Check", isComponentHalfSize: true },
            on: {
              change: function($event) {
                return _vm.addBrokerDataEdited("brokerData")
              }
            },
            model: {
              value: _vm.brokerData.emailLimitCheck,
              callback: function($$v) {
                _vm.$set(_vm.brokerData, "emailLimitCheck", $$v)
              },
              expression: "brokerData.emailLimitCheck"
            }
          }),
          _vm.brokerData.zeigeAbsender
            ? _c("ComboBox", {
                attrs: {
                  label: "Absender",
                  isComponentHalfSize: "",
                  values: _vm.brokerData.absenderLimitCheck
                },
                on: {
                  change: function($event) {
                    return _vm.addBrokerDataEditedCombo(
                      "brokerData",
                      "absenderLimitEmail",
                      $event
                    )
                  }
                },
                model: {
                  value: _vm.brokerData.absenderLimitEmail,
                  callback: function($$v) {
                    _vm.$set(_vm.brokerData, "absenderLimitEmail", $$v)
                  },
                  expression: "brokerData.absenderLimitEmail"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("InputCheckboxItem", {
            attrs: {
              disabled: !_vm.isBrokerBypassedByIntern,
              label: "Info Stornowarnung"
            },
            on: {
              input: function($event) {
                return _vm.addBrokerDataEdited("brokerData")
              }
            },
            model: {
              value: _vm.brokerData.infoStorno,
              callback: function($$v) {
                _vm.$set(_vm.brokerData, "infoStorno", $$v)
              },
              expression: "brokerData.infoStorno"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("div", [_vm._v("Info Transaktionen")]),
          _vm.brokerData.zeigeAbsender
            ? _c("ComboBox", {
                attrs: {
                  label: "Absender",
                  isComponentHalfSize: "",
                  values: _vm.brokerData.absenderTransaktion
                },
                on: {
                  change: function($event) {
                    return _vm.addBrokerDataEditedCombo(
                      "brokerData",
                      "absenderInfoTransaktionenEmail",
                      $event
                    )
                  }
                },
                model: {
                  value: _vm.brokerData.absenderInfoTransaktionenEmail,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.brokerData,
                      "absenderInfoTransaktionenEmail",
                      $$v
                    )
                  },
                  expression: "brokerData.absenderInfoTransaktionenEmail"
                }
              })
            : _vm._e(),
          _c("InputCheckboxItem", {
            attrs: { label: "Zusenden" },
            on: {
              input: function($event) {
                return _vm.addBrokerDataEdited("brokerData")
              }
            },
            model: {
              value: _vm.brokerData.infoTransaktionenZusenden,
              callback: function($$v) {
                _vm.$set(_vm.brokerData, "infoTransaktionenZusenden", $$v)
              },
              expression: "brokerData.infoTransaktionenZusenden"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.brokerData.isUntermaklerDesktop || _vm.isBrokerBypassedByIntern
            ? _c("InputField", {
                attrs: {
                  label: "Info Transaktionen als BCC",
                  isComponentHalfSize: true
                },
                on: {
                  change: function($event) {
                    return _vm.addBrokerDataEdited("brokerData")
                  }
                },
                model: {
                  value: _vm.brokerData.emailInfoTransaktionenBBC,
                  callback: function($$v) {
                    _vm.$set(_vm.brokerData, "emailInfoTransaktionenBBC", $$v)
                  },
                  expression: "brokerData.emailInfoTransaktionenBBC"
                }
              })
            : _vm._e(),
          _vm.brokerData.zeigeAbsender &&
          (_vm.brokerData.isUntermaklerDesktop || _vm.isBrokerBypassedByIntern)
            ? _c("ComboBox", {
                attrs: {
                  label: "Absender",
                  isComponentHalfSize: "",
                  values: _vm.brokerData.absenderTransaktionBCC
                },
                on: {
                  change: function($event) {
                    return _vm.addBrokerDataEditedCombo(
                      "brokerData",
                      "absenderInfoTransaktionenBCCEmail",
                      $event
                    )
                  }
                },
                model: {
                  value: _vm.brokerData.absenderInfoTransaktionenBCCEmail,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.brokerData,
                      "absenderInfoTransaktionenBCCEmail",
                      $$v
                    )
                  },
                  expression: "brokerData.absenderInfoTransaktionenBCCEmail"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("InputField", {
            attrs: { label: "Courtageabrechnung", isComponentHalfSize: true },
            on: {
              change: function($event) {
                return _vm.addBrokerDataEdited("brokerData")
              }
            },
            model: {
              value: _vm.brokerData.emailCourtage,
              callback: function($$v) {
                _vm.$set(_vm.brokerData, "emailCourtage", $$v)
              },
              expression: "brokerData.emailCourtage"
            }
          }),
          _vm.brokerData.zeigeAbsender
            ? _c("ComboBox", {
                attrs: {
                  label: "Absender",
                  isComponentHalfSize: "",
                  values: _vm.brokerData.absenderCourtageabrechnung
                },
                on: {
                  change: function($event) {
                    return _vm.addBrokerDataEditedCombo(
                      "brokerData",
                      "absenderCourtageEmail",
                      $event
                    )
                  }
                },
                model: {
                  value: _vm.brokerData.absenderCourtageEmail,
                  callback: function($$v) {
                    _vm.$set(_vm.brokerData, "absenderCourtageEmail", $$v)
                  },
                  expression: "brokerData.absenderCourtageEmail"
                }
              })
            : _vm._e(),
          _c("InputCheckboxItem", {
            attrs: { label: "Zusenden" },
            on: {
              change: function($event) {
                return _vm.addBrokerDataEdited("brokerData")
              }
            },
            model: {
              value: _vm.brokerData.emailCourtageZusenden,
              callback: function($$v) {
                _vm.$set(_vm.brokerData, "emailCourtageZusenden", $$v)
              },
              expression: "brokerData.emailCourtageZusenden"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("InputField", {
            attrs: { label: "Kundenkontakt", isComponentHalfSize: true },
            on: {
              change: function($event) {
                return _vm.addBrokerDataEdited("brokerData")
              }
            },
            model: {
              value: _vm.brokerData.emailKundenkontakt,
              callback: function($$v) {
                _vm.$set(_vm.brokerData, "emailKundenkontakt", $$v)
              },
              expression: "brokerData.emailKundenkontakt"
            }
          }),
          _vm.brokerData.zeigeAbsender
            ? _c("ComboBox", {
                attrs: {
                  label: "Absender",
                  isComponentHalfSize: "",
                  values: _vm.brokerData.absenderKundenkontakt
                },
                on: {
                  change: function($event) {
                    return _vm.addBrokerDataEditedCombo(
                      "brokerData",
                      "absenderKundenKontaktEmail",
                      $event
                    )
                  }
                },
                model: {
                  value: _vm.brokerData.absenderKundenKontaktEmail,
                  callback: function($$v) {
                    _vm.$set(_vm.brokerData, "absenderKundenKontaktEmail", $$v)
                  },
                  expression: "brokerData.absenderKundenKontaktEmail"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.brokerData.zugriffInvestment
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("InputField", {
                attrs: {
                  label: "Antragsprüfung Wertpapiere",
                  isComponentHalfSize: true
                },
                on: {
                  change: function($event) {
                    return _vm.addBrokerDataEdited("brokerData")
                  }
                },
                model: {
                  value: _vm.brokerData.emailWertpapiere,
                  callback: function($$v) {
                    _vm.$set(_vm.brokerData, "emailWertpapiere", $$v)
                  },
                  expression: "brokerData.emailWertpapiere"
                }
              }),
              _vm.brokerData.zeigeAbsender
                ? _c("ComboBox", {
                    attrs: {
                      label: "Absender",
                      isComponentHalfSize: "",
                      values: _vm.brokerData.absenderWertpapiere
                    },
                    on: {
                      change: function($event) {
                        return _vm.addBrokerDataEditedCombo(
                          "brokerData",
                          "absenderWertpapiereEmail",
                          $event
                        )
                      }
                    },
                    model: {
                      value: _vm.brokerData.absenderWertpapiereEmail,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.brokerData,
                          "absenderWertpapiereEmail",
                          $$v
                        )
                      },
                      expression: "brokerData.absenderWertpapiereEmail"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.brokerData.zugriffVersicherungen
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("InputField", {
                attrs: {
                  label: "Antragsprüfung Versicherungen",
                  isComponentHalfSize: true
                },
                on: {
                  change: function($event) {
                    return _vm.addBrokerDataEdited("brokerData")
                  }
                },
                model: {
                  value: _vm.brokerData.emailVersicherungen,
                  callback: function($$v) {
                    _vm.$set(_vm.brokerData, "emailVersicherungen", $$v)
                  },
                  expression: "brokerData.emailVersicherungen"
                }
              }),
              _vm.brokerData.zeigeAbsender
                ? _c("ComboBox", {
                    attrs: {
                      label: "Absender",
                      isComponentHalfSize: "",
                      values: _vm.brokerData.absenderVersicherungen
                    },
                    on: {
                      change: function($event) {
                        return _vm.addBrokerDataEditedCombo(
                          "brokerData",
                          "absenderVersicherungenEmail",
                          $event
                        )
                      }
                    },
                    model: {
                      value: _vm.brokerData.absenderVersicherungenEmail,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.brokerData,
                          "absenderVersicherungenEmail",
                          $$v
                        )
                      },
                      expression: "brokerData.absenderVersicherungenEmail"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.brokerData.zugriffKredite
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("InputField", {
                attrs: {
                  label: "Antragsprüfung Finanzierungen",
                  isComponentHalfSize: true
                },
                on: {
                  change: function($event) {
                    return _vm.addBrokerDataEdited("brokerData")
                  }
                },
                model: {
                  value: _vm.brokerData.emailFinanzierungen,
                  callback: function($$v) {
                    _vm.$set(_vm.brokerData, "emailFinanzierungen", $$v)
                  },
                  expression: "brokerData.emailFinanzierungen"
                }
              }),
              _vm.brokerData.zeigeAbsender
                ? _c("ComboBox", {
                    attrs: {
                      label: "Absender",
                      isComponentHalfSize: "",
                      values: _vm.brokerData.absenderFinanzierung
                    },
                    on: {
                      change: function($event) {
                        return _vm.addBrokerDataEditedCombo(
                          "brokerData",
                          "absenderFinanzierungenEmail",
                          $event
                        )
                      }
                    },
                    model: {
                      value: _vm.brokerData.absenderFinanzierungenEmail,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.brokerData,
                          "absenderFinanzierungenEmail",
                          $$v
                        )
                      },
                      expression: "brokerData.absenderFinanzierungenEmail"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.brokerData.zugriffVV
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("InputField", {
                attrs: {
                  label: "Vermögensverwaltung",
                  isComponentHalfSize: true
                },
                on: {
                  change: function($event) {
                    return _vm.addBrokerDataEdited("brokerData")
                  }
                },
                model: {
                  value: _vm.brokerData.emailVV,
                  callback: function($$v) {
                    _vm.$set(_vm.brokerData, "emailVV", $$v)
                  },
                  expression: "brokerData.emailVV"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.brokerData.zugriffMaklerauftragVerwaltung
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("InputField", {
                attrs: {
                  label: "Maklerauftrag Fehler",
                  isComponentHalfSize: true
                },
                on: {
                  change: function($event) {
                    return _vm.addBrokerDataEdited("brokerData")
                  }
                },
                model: {
                  value: _vm.brokerData.emailMaklerAuftragFehler,
                  callback: function($$v) {
                    _vm.$set(_vm.brokerData, "emailMaklerAuftragFehler", $$v)
                  },
                  expression: "brokerData.emailMaklerAuftragFehler"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.brokerData.zugriffMaklerauftragVerwaltung
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("InputField", {
                attrs: {
                  label: "Maklerauftrag Kontrolle",
                  isComponentHalfSize: true
                },
                on: {
                  change: function($event) {
                    return _vm.addBrokerDataEdited("brokerData")
                  }
                },
                model: {
                  value: _vm.brokerData.emailMaklerAuftragKontrolle,
                  callback: function($$v) {
                    _vm.$set(_vm.brokerData, "emailMaklerAuftragKontrolle", $$v)
                  },
                  expression: "brokerData.emailMaklerAuftragKontrolle"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.brokerData.zeigeEmailDepotbickIntern
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("InputField", {
                attrs: {
                  label: "Ersatzadresse Absender",
                  isComponentHalfSize: true,
                  disabled: "disabled"
                },
                model: {
                  value: _vm.brokerData.emailErsatzAdresse,
                  callback: function($$v) {
                    _vm.$set(_vm.brokerData, "emailErsatzAdresse", $$v)
                  },
                  expression: "brokerData.emailErsatzAdresse"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }