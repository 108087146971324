var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Table", {
        attrs: {
          tableId: "47e82852-2bbf-4e87-9a9d-8ce3cafee39b",
          title: "Versicherungssumme",
          headers: _vm.versicherungsSummenHeaders,
          rows: _vm.versicherungsSummenRows,
          rowId: "id",
          headerActions: _vm.headerActions,
          hidePagination: ""
        },
        on: {
          headerAction: _vm.handleHeaderAction,
          "action-EDIT": _vm.editRow,
          "action-DELETE": _vm.removeRow
        }
      }),
      _c(
        "BaseModal",
        {
          ref: "modalAdd",
          attrs: { modalTitle: "Versicherungssumme", size: "lg" },
          on: { onConfirmButton: _vm.saveOrUpdateRow }
        },
        [
          !_vm.form.id
            ? _c("ComboBox", {
                attrs: { label: "Sparte", values: _vm.availableSparten },
                model: {
                  value: _vm.form.sparteId,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "sparteId", $$v)
                  },
                  expression: "form.sparteId"
                }
              })
            : _vm._e(),
          _c("InputField", {
            attrs: { label: "Bezeichnung" },
            model: {
              value: _vm.form.bezeichnung,
              callback: function($$v) {
                _vm.$set(_vm.form, "bezeichnung", $$v)
              },
              expression: "form.bezeichnung"
            }
          }),
          _c("InputField", {
            attrs: {
              type: "currency",
              precision: 2,
              label: "Versicherungssumme"
            },
            model: {
              value: _vm.form.betrag,
              callback: function($$v) {
                _vm.$set(_vm.form, "betrag", $$v)
              },
              expression: "form.betrag"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }