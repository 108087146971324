<template>
  <div>
    <PageHeaderTitleNavigation :title="titleStr + ' öffnen'" />

    <div class="box__container" :tid="_generateTidFromString('box__container_antrag_beratungsmappe_selector')">
      <div class="row">
        <div class="col">
          Soll für {{ antragStr }} eine neue Beratungsmappe angelegt werden oder möchten Sie ihn zu einer bestehenden Mappe hinzufügen?
        </div>
      </div>
      <div>
        <div class="layout__negative-margin--8">
          <BaseButton @click="existingBeratungsmappe()" :isSecondary="true">
            Eine bestehende Mappe öffnen
          </BaseButton>
          <BaseButton @click="openCreateBeratungsmappeModal()" :isSecondary="true">
            Eine neue Mappe anlegen
          </BaseButton>
          <BaseButton @click="withoutBeratung()">
            Ohne Beratung
          </BaseButton>
        </div>
      </div>
    </div>

    <EditBeratungsmappeModal 
      @confirm="createBeratungsmappe" 
      ref="createBeratungsmappeModal"
    />

    <div class="box__container" v-if="showBeratungsmappeList">
        <Table v-if="rows.length"
            tableId="cc8c185e-0263-4401-b654-903010d11f8e"
            :headers="headers"
            :rows="rows"
            :rowsPerPage="20"
            @click-art="openExistingBeratungsmappe"
        />
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/core/BaseButton.vue'
import BERATUNGSMAPPE_TYPES from '@/store/beratungsmappe/types'
import { mapGetters } from 'vuex'
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, DateTimeColumn, NumberColumn} from "@/components/table2/table_util.js";
import CORE_TYPES from '@/store/core/types'
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import EditBeratungsmappeModal from "@/components/beratungsmappe/EditBeratungsmappeModal.vue";

export default {
    mixins: [InteractiveHelpCommonsMixin],
    data() {
        return {
            useExistingBeratungsmappe: false,
            headers: {
                lockedLeft: [
                    TextColumn("art", "Art").makeAlwaysVisible().makeConditionalLink("id"),
                ],
                center: [
                    TextColumn("beschreibung", "Beschreibung"),
                    DateTimeColumn("datum_erstellt", "erstellt"),
                    DateTimeColumn("datum_geschlossen", "geschlossen"),
                    NumberColumn("pos", "Positionen"),
                ],
            },
        }
    },
    computed: {
        ...mapGetters({
            beratungsmappeList: BERATUNGSMAPPE_TYPES.GETTERS.BERATUNGSMAPPELIST_DATA,
        }),
        showBeratungsmappeList() {
            return this.useExistingBeratungsmappe && this.beratungsmappeList?.rows
        },
        rows() {
            return (this.beratungsmappeList?.rows || []);
        },
        antragStr() {
          return this.$route.query.formPath?.includes('wertpapierorder') ? 'die Order' : 'den Antrag';
        },
        titleStr() {
          return this.$route.query.formPath?.includes('wertpapierorder') ? 'Wertpapierorder' : 'Antrag';
        }
    },
  methods: {
    openCreateBeratungsmappeModal() {        
        this.$refs.createBeratungsmappeModal.open()
    },
    openExistingBeratungsmappe(row) {
        this.redirectToForm(row.id, row.datum_erstellt)
    },
    existingBeratungsmappe() {
      this.useExistingBeratungsmappe = true
      this.$store.dispatch(BERATUNGSMAPPE_TYPES.ACTIONS.RETRIEVE_BERATUNGSMAPPELIST, {});
    },
    createBeratungsmappe(mappeData) {
      const payload = {
        mappeData,
      }

      this.$store.dispatch(BERATUNGSMAPPE_TYPES.ACTIONS.CREATE_BERATUNGSMAPPE, payload).then(data => {
        this.redirectToForm(data.beratungsMappeId, data.datum)
      });
    },
    withoutBeratung() {
      this.redirectToForm()
    },
    redirectToForm(beratungsMappeId, datum) {
      const formPath = this.$route.query?.formPath
      if (!formPath) {
        return
      }

      const query = { 
        bmOptChosen: true,
        lagerstelleLabel: this.$route.query?.lagerstelleLabel,
        ...beratungsMappeId && { beratungsMappeId }
      }

      if (beratungsMappeId) {
        const fullPath = `/beratung/beratung/bearbeiten?id=${beratungsMappeId}`
        
        this.$store.dispatch(CORE_TYPES.ACTIONS.SAVE_BACK_TO_PREVIOUS_PAGE_TRACK, { 
          currentRouteLink: fullPath,
        });

        this.$addBreadcrumb({
          label: 'zurück zur Beratungsmappe',
          fullPath,
        })

      }

      this.$router.push( { path: formPath, query })
    }
  },
  components: {
    BaseButton,
    PageHeaderTitleNavigation,
    Table,
    EditBeratungsmappeModal,
  }
}
</script>

<style scoped>

</style>