var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.rows.length
        ? _c("Table", {
            attrs: {
              tableId: "2544554d-72be-493f-993a-4ec9defi7010",
              title: _vm.title,
              headers: _vm.headers,
              rows: _vm.rows,
              rowsPerPage: 10,
              exportConfig: _vm.exportConfig,
              mobileConfig: {
                title: "name",
                headers: ["vorname", "typ", "unternr"]
              },
              rowId: "personId"
            },
            on: {
              "click-name": function($event) {
                return _vm.editPersonenDaten($event)
              },
              "action-OPEN": _vm.openPersonInNewTab,
              "action-DELETE": _vm.deletePerson
            }
          })
        : _c("NoData", { attrs: { title: _vm.title } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }