<template>
  <div class="row justify-content-end">
    <div class="col col-12 col-sm-6 mb-4 mb-sm-0 mt-2">
      <div class="m-0" :class="{'box__container': !noContainer}">
        <div>
          <CurrencyLabel
            v-if="!ohneEinmal"
            :label="mitSparplan ? 'Einmalanlagen Gesamt:' : labelDefault"
            :value="summe"
            showInlineLabel
          />
        </div>
        <hr v-if="mitSparplan && !ohneEinmal">
         <div v-if="mitSparplan">
          <CurrencyLabel
            label='Sparplan Gesamt:'
            :value="summeSpar"
            showInlineLabel
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CurrencyLabel from "@/components/core/CurrencyLabel.vue";

export default {
  props: {
    antragId: {
      type: String,
    },
    summe:{
        type: Number
    },
    summeSpar:{
        type: Number
    },
    mitSparplan:{
        type: Boolean,
        default: false
    },
    ohneEinmal:{
        type: Boolean,
        default: false
    },
    noContainer:{
        type: Boolean,
        default: false
    },
  },
    data(){
        return{
            labelDefault: "Gesamt:"
        }
    },

  components: {
    CurrencyLabel,
  },
};
</script>

