import CORE_TYPES from '@/store/core/types';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

function getInitialState() {
  return {
    // reactive data
    sessions: {
      acts: [],
      search: {
        filters: undefined,
        outcome: undefined,
        phase: {
          wp_type: undefined,
          f_state: {item: '1.1.01'}
        }
      },
    },
    responseOutcome: 0, // incremented for reactivity
    
    // containers
    config: {
      request: 0,
      filter: undefined,
    },
    getComdirectSession: {},
    watchliste: {},
  }
}

export default {
  state: {
    ...getInitialState()
  },
  getters: {
    ...getters
  },
  mutations: {
    ...mutations,
    [CORE_TYPES.MUTATIONS.RESET_STATE](state) { // logout
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    ...actions
  }
}