var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      attrs: {
        tid: _vm._generateTidFromString(
          "cards__container layout__negative-margin--8"
        )
      }
    },
    [
      _c("Table", {
        attrs: {
          tableId: "e91ac4a9-6761-429c-9190-58e6c3c9cb49",
          title: "Kontakte",
          headers: _vm.headers,
          rows: _vm.rows,
          rowId: "id",
          rowsPerPage: 20,
          selected: _vm.selectedRowsValues
        },
        on: { selected: _vm.selectedRows }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }