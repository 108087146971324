import Vue from 'vue'
import store from '../store'
import CORE_TYPES from '../store/core/types'
import LOG_TYPES from '@/store/log/types';

Vue.directive('role', {
  inserted(el, binding, vnode, old) {
    const hasRoles = store.getters[CORE_TYPES.GETTERS.HAS_ROLES];
    if (!binding?.value?.length) {
      store.dispatch(LOG_TYPES.ACTIONS.ERROR, 'In order to use the v-role directive, you should provide a list of roles to check');
      return;
    }

    if (!hasRoles(binding.value)) {
      vnode.elm.parentElement.removeChild(vnode.elm)
    } 
  }
})
