var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "box__title" }, [
      _vm._v("Stornoreserve Versicherungen")
    ]),
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("BaseFilter", {
          attrs: {
            title: "Kunden Filter",
            metadata: _vm.searchMetadata,
            configFilter: _vm.configFilter,
            showSaveButton: false,
            defaultOptions: _vm.defaultOptions
          },
          on: {
            onFilter: function($event) {
              return _vm.search($event)
            },
            onFilterZurucksetzen: function($event) {
              return _vm.search(null)
            }
          }
        }),
        _c("HandleTablePin", {
          attrs: { keyOfPIN: "TABELLE_STORNORESERVE_VERS" }
        }),
        _vm.loaded > 0 && _vm.rows.length
          ? _c("Table", {
              attrs: {
                title: "Stornoreserve",
                headers: _vm.headers,
                rows: _vm.rows,
                rowsPerPage: 25,
                exportConfig: { roottext: "Stornoreserve Versicherungen" }
              }
            })
          : _vm.loaded < 0
          ? _c("GhostLoading", { attrs: { type: "table" } })
          : _vm.loaded > 0
          ? _c("NoData", { attrs: { noIcon: "" } })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }