var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-forms__container" },
    [
      _c("div", { staticClass: "input-forms__label-container" }, [
        _c("div", { staticClass: "row mt-0" }, [
          _c("div", { staticClass: "col" }, [
            _vm.title
              ? _c(
                  "div",
                  {
                    staticClass:
                      "input-forms__label-content input-forms__label-content--bigger"
                  },
                  [
                    _c("ph-bank", { attrs: { size: 16 } }),
                    _vm._v(" " + _vm._s(_vm.title) + " ")
                  ],
                  1
                )
              : _vm._e()
          ]),
          _vm.config && _vm.config.buttonText
            ? _c(
                "div",
                { staticClass: "col-auto" },
                [
                  _c("BaseButton", { on: { click: _vm.openFormsFinder } }, [
                    _vm._v(_vm._s(_vm.config.buttonText))
                  ])
                ],
                1
              )
            : _vm._e()
        ])
      ]),
      _vm.positions && _vm.positions.length
        ? _c(
            "div",
            { staticClass: "input-forms__input-container" },
            [
              _vm.rows.length
                ? _c("Table", {
                    attrs: {
                      headers: _vm.headers,
                      rows: _vm.rows,
                      rowId: "index",
                      hidePagination: ""
                    },
                    on: { "action-DELETE": _vm.openModalDeleteFond },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "betragEinmal",
                          fn: function(row) {
                            return [
                              _c("InputField", {
                                attrs: {
                                  type: "currency",
                                  precision: 2,
                                  value: row.betragEinmal,
                                  id: "einmalBetrag" + row.index
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      row,
                                      "betragEinmal"
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "betragPlanEuro",
                          fn: function(row) {
                            return [
                              _c("InputField", {
                                attrs: {
                                  type: "currency",
                                  precision: 2,
                                  value: row.betragPlanEuro,
                                  id: "betragPlanEuro" + row.index
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      row,
                                      "betragPlanEuro"
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "betragPlanAnteile",
                          fn: function(row) {
                            return [
                              _c("InputField", {
                                attrs: {
                                  value: row.betragPlanAnteile,
                                  id: "betragPlanAnteile" + row.index
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      row,
                                      "betragPlanAnteile"
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      2641859177
                    )
                  })
                : _vm._e(),
              _vm.positions &&
              _vm.positions.length &&
              _vm.config &&
              _vm.config.positionFields
                ? _c("WertpapierAuswahlPositionFields", {
                    staticClass: "mt-3",
                    attrs: {
                      config: _vm.config,
                      categoryId: _vm.categoryId,
                      antragId: _vm.antragId
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _c("div", [_c("div", [_vm._v("Wertpapierauswahl ist leer")])]),
      _c("DeletePositionModal", {
        ref: "deleteModal",
        attrs: { position: _vm.positionToDelete },
        on: {
          delete: function($event) {
            return _vm.doDeletePosition()
          }
        }
      }),
      _c(
        "BaseModal",
        {
          ref: "fehlerModal",
          attrs: { labelButtonCancel: "Ok", showConfirmButton: false },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("PhWarning", {
                    staticClass: "color-danger mr-2",
                    attrs: { size: 16 }
                  }),
                  _c("span", { staticClass: "color-danger" }, [
                    _vm._v("Fehler")
                  ])
                ]
              },
              proxy: true
            }
          ])
        },
        [_vm._v(" Fehler: Sie dürfen nur 6 Positionen wählen. ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }