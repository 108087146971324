import VERTRAGSSPIEGEL_TYPES from './types';
import Vue from 'vue';

export default {
  [VERTRAGSSPIEGEL_TYPES.MUTATIONS.FIELDS_DEFINITION_SUCCESS](state, payload) {
    Vue.set(state, 'fieldsDefinition', payload);
  },
  [VERTRAGSSPIEGEL_TYPES.MUTATIONS.GET_PDF_FILE_SUCCESS](state, payload) {
    console.log(payload)
  },
}