import CORE_TYPES from './types';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

/**
 * mutations: synchronous operation responsible for change the state
 * actions: asynchronous operation responsible for call external resources and commit mutations
 */
export function getInitialState() {
  return {
    template: {

    },
    charts: {
      data: [
        {
          name: 'infoInventory#Bestandsübersicht',
          id: 'Bestandsubersicht',
          section: 1,
          isLoaded: false,
        },
        {
          name: 'infoInventory#Kostenpflichtige Produkte',
          id: 'Kostenpflichtige_Produkte',
          section: 1,
          isLoaded: false,
        },
        {
          name: `radialBarMultiple#Kundenbindung`,
          id: 'Kundenbindung',
          section: 1,
          title: `Kundenbindung`,
          isLoaded: false,
        },
        {
          name: 'Gesamtcourtage',
          id: 'Gesamtcourtage',
          section: 1,
          isLoaded: false
        },
        {
          name: 'area#Gesamtcourtage kumuliert',
          id: 'Gesamtcourtage_YEAR',
          section: 1,
          isLoaded: false,
        },
        {
          name: 'area#Bestand',
          id: 'Bestand',
          section: 2,
          isLoaded: false,
        },
        {
          name: `donut#Bestandsübersicht`,
          id: 'Bestandsübersicht',
          all: 0,
          section: 2,
          title: `Bestandsübersicht`,
          isLoaded: false,
        },
        {
          name: `radialBarMultiple#Bestandkunden`,
          id: 'Bestandkunden',
          section: 2,
          title: `Bestandkunden`,
          isLoaded: false,
        },
        {
          name: 'bar#Nettomittelzufluss Gesamt',
          id: 'Nettomittelzufluss_Gesamt',
          section: 2,
          isLoaded: false,
        },
        {
          name: 'area#Courtage Investmentfonds',
          id: 'Courtage_Investmentfonds',
          section: 2,
          isLoaded: false,
        },
        {
          name: 'area#Courtage Servicegebühr',
          id: 'Courtage_Servicegebühr',
          section: 2,
          isLoaded: false,
        },
        {
          name: 'pie#Top Lagerstelle Bestand',
          id: 'Top_Lagerstelle_Bestand',
          section: 2,
          isLoaded: false,
        },
        {
          name: 'pie#Top Gesellschaften Bestand',
          id: 'Top_Gesellschaften_Bestand',
          section: 2,
          isLoaded: false,
        },
        {
          name: 'pie#Top Fonds Bestand',
          id: 'Top_Fonds_Bestand',
          section: 2,
          isLoaded: false,
        },
        {
          name: 'pie#Top Lagerstelle Nettomittelaufkommen',
          id: 'Top_Lagerstelle_Nettomittelaufkommen',
          section: 2,
          isLoaded: false,
        },
        {
          name: 'pie#Top Gesellschaften Nettomittelaufkommen',
          id: 'Top_Gesellschaften_Nettomittelaufkommen',
          section: 2,
          isLoaded: false,
        },
        {
          name: 'pie#Top Fonds Nettomittelaufkommen',
          id: 'Top_Fonds_Nettomittelaufkommen',
          section: 2,
          isLoaded: false,
        },

        {
          name: `radialBarMultiple#Kundenübersicht`,
          section: 3,
          title: `Kundenübersicht`,
          id: 'Kundenübersicht',
          isLoaded: false,
        },
        {
          name: 'vertrag#Aufteilung in den letzten 12 Monaten',
          id: 'Aufteilung_in_den_letzten_12_Monaten',
          section: 3,
          isLoaded: false,
        },
        {
          name: 'area#Courtage Versicherungen',
          id: 'Courtage_Versicherungen',
          section: 3,
          isLoaded: false,
        },
        {
          name: 'stacked#Anzahl Verträge',
          id: 'Anzahl_Verträge',
          section: 3,
          isLoaded: false,
        },
        {
          name: 'area#Courtage Versicherungen Bestand',
          id: 'Courtage_Versicherungen_Bestand',
          section: 3,
          isLoaded: false,
        },
        {
          name: 'area#Courtage Versicherungen Abschluss',
          id: 'Courtage_Versicherungen_Abschluss',
          section: 3,
          isLoaded: false,
        },
        {
          name: 'pie#Top Lagerstelle Beteiligungen',
          id: 'Top_Lagerstelle_Beteiligungen',
          section: 4,
          isLoaded: false,
        },
        {
          name: 'area#Courtage Beteiligungen',
          id: 'Courtage_Beteiligungen',
          section: 4,
          isLoaded: false,
        },
        {
          name: 'pie#Abschlussvolumen',
          id: 'Abschlussvolume',
          section: 5,
          isLoaded: false,
        },
        {
          name: 'area#Courtage Finanzierungen',
          id: 'Courtage_Finanzierungen',
          section: 5,
          isLoaded: false,
        },
        {
          name: 'num#Vertrag Investment',
          id: 'Vertrag_Investment',
          section: 0,
          isLoaded: false,
        },
        {
          name: 'num#Vertrag Versicherung',
          id: 'Vertrag_Versicherung',
          section: 0,
          isLoaded: false,
        },
        {
          name: 'num#Courtagestufe',
          id: 'Courtagestufe',
          section: 0,
          isLoaded: false,
        },
        {
          name: 'num#Top Courtage',
          id: 'TopCourtage',
          section: 0,
          isLoaded: false,
        },
        
        {
          name: 'anzahlVertraegeBeteiligungen',
          id: 'anzahlVertraegeBeteiligungen',
          section: -1,
          isLoaded: false,
        },
        {
          name: 'anzahlVertraegeBeteiligungen',
          id: 'anzahlVertraegeFinanzierungen',
          section: -1,
          isLoaded: false,
        },
      ],
    },
    configuration: {},
    steckbriefConfiguration: {},
    isStructure: 0,
    isPrecalculated: false,
    brokerOverviewConfiguration: {},
    enabledMaklerCards: {},
    brokerParagraphsConfiguration: {},
    internOverviewConfiguration: {},
  }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}
