var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("div", { staticClass: "box__title" }, [_vm._v("Persönliche Daten")]),
        _vm.ansprechpartner.istEigenerAP
          ? _c("InputToggleSwitch", {
              attrs: {
                label: "Freigabe für die Vermittler-Struktur",
                value: _vm.ansprechpartner.struktur,
                disabled: _vm.loading || _vm.readOnly
              },
              on: {
                input: function($event) {
                  return _vm.updateAP("struktur", $event)
                }
              }
            })
          : _vm._e(),
        _vm.isGesellschaft
          ? _c("InputToggleSwitch", {
              attrs: {
                label: "Ansprechpartner inaktiv",
                value: _vm.ansprechpartner.ansprechpartnerInaktiv,
                disabled: _vm.loading || _vm.readOnly
              },
              on: { input: _vm.setInaktiv }
            })
          : _vm._e(),
        _c("ComboBox", {
          attrs: {
            label: "Typ",
            isComponentHalfSize: "",
            values: _vm.comboboxSelections.typValues,
            value: _vm.ansprechpartner.type,
            renderDanger: !_vm.loading && !_vm.ansprechpartner.type,
            disabled: _vm.loading || _vm.readOnly
          },
          on: {
            change: function($event) {
              return _vm.updateAP("type", $event)
            }
          }
        }),
        _c("ComboBox", {
          attrs: {
            label: "Anrede",
            isComponentHalfSize: "",
            values: _vm.comboboxSelections.anredeValues,
            value: _vm.ansprechpartner.anrede,
            disabled: _vm.loading || _vm.readOnly
          },
          on: {
            change: function($event) {
              return _vm.updateAP("anrede", $event)
            }
          }
        }),
        _c("ComboBox", {
          attrs: {
            label: "Titel",
            isComponentHalfSize: "",
            values: _vm.comboboxSelections.titleValues,
            value: _vm.ansprechpartner.title,
            disabled: _vm.loading || _vm.readOnly
          },
          on: {
            change: function($event) {
              return _vm.updateAP("title", $event)
            }
          }
        }),
        _c("InputField", {
          attrs: {
            label: "Vorname",
            isComponentHalfSize: "",
            value: _vm.ansprechpartner.firstName,
            disabled: _vm.loading || _vm.readOnly
          },
          on: {
            input: function($event) {
              return _vm.updateAP("firstName", $event)
            }
          }
        }),
        _c("InputField", {
          attrs: {
            label: "Name",
            isComponentHalfSize: "",
            value: _vm.ansprechpartner.lastName,
            renderDanger: !_vm.loading && !_vm.ansprechpartner.lastName,
            disabled: _vm.loading || _vm.readOnly
          },
          on: {
            input: function($event) {
              return _vm.updateAP("lastName", $event)
            }
          }
        }),
        _c("InputField", {
          attrs: {
            label: "Beschreibung",
            isComponentHalfSize: "",
            value: _vm.ansprechpartner.description,
            disabled: _vm.loading || _vm.readOnly
          },
          on: {
            input: function($event) {
              return _vm.updateAP("description", $event)
            }
          }
        }),
        _c("InputField", {
          attrs: {
            label: "E-Mail",
            isComponentHalfSize: "",
            value: _vm.ansprechpartner.email,
            disabled: _vm.loading || _vm.readOnly
          },
          on: {
            input: function($event) {
              return _vm.updateAP("email", $event)
            }
          }
        }),
        _c("InputField", {
          attrs: {
            label: "Telefon",
            isComponentHalfSize: "",
            value: _vm.ansprechpartner.phone,
            disabled: _vm.loading || _vm.readOnly,
            inputmode: "tel"
          },
          on: {
            input: function($event) {
              return _vm.updateAP("phone", $event)
            }
          }
        }),
        _c("InputField", {
          attrs: {
            label: "Mobiltelefon",
            isComponentHalfSize: "",
            value: _vm.ansprechpartner.mobile,
            disabled: _vm.loading || _vm.readOnly,
            inputmode: "tel"
          },
          on: {
            input: function($event) {
              return _vm.updateAP("mobile", $event)
            }
          }
        }),
        _c("InputField", {
          attrs: {
            label: "Fax",
            isComponentHalfSize: "",
            value: _vm.ansprechpartner.fax,
            disabled: _vm.loading || _vm.readOnly,
            inputmode: "tel"
          },
          on: {
            input: function($event) {
              return _vm.updateAP("fax", $event)
            }
          }
        }),
        _c("DatePickerField", {
          attrs: {
            label: "Geburtsdatum",
            isValueAsString: "",
            isComponentHalfSize: "",
            value: _vm.ansprechpartner.dayOfBirth,
            disabled: _vm.loading || _vm.readOnly
          },
          on: {
            change: function($event) {
              return _vm.updateAP("dayOfBirth", $event)
            }
          }
        }),
        _c("InputField", {
          attrs: {
            label: "Briefanrede",
            isComponentHalfSize: "",
            value: _vm.ansprechpartner.briefanrede,
            disabled: _vm.loading || _vm.readOnly
          },
          on: {
            input: function($event) {
              return _vm.updateAP("briefanrede", $event)
            }
          }
        }),
        _c("ComboBox", {
          attrs: {
            label: "Zweigstelle",
            isComponentHalfSize: "",
            values: _vm.comboboxSelections.zweigValues,
            value: _vm.ansprechpartner.zweigstelle,
            disabled: _vm.loading || _vm.readOnly
          },
          on: {
            change: function($event) {
              return _vm.updateAP("zweigstelle", $event)
            }
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("div", { staticClass: "box__title" }, [_vm._v("Private Adresse")]),
        _c("InputField", {
          attrs: {
            label: "Postleitzahl",
            isComponentHalfSize: "",
            value: _vm.ansprechpartner.zip,
            disabled: _vm.loading || _vm.readOnly
          },
          on: {
            input: function($event) {
              return _vm.updateAP("zip", $event)
            }
          }
        }),
        _c("InputField", {
          attrs: {
            label: "Ort",
            isComponentHalfSize: "",
            value: _vm.ansprechpartner.city,
            disabled: _vm.loading || _vm.readOnly
          },
          on: {
            input: function($event) {
              return _vm.updateAP("city", $event)
            }
          }
        }),
        _c("InputField", {
          attrs: {
            label: "Straße",
            isComponentHalfSize: "",
            value: _vm.ansprechpartner.street,
            disabled: _vm.loading || _vm.readOnly
          },
          on: {
            input: function($event) {
              return _vm.updateAP("street", $event)
            }
          }
        }),
        _c("ComboBox", {
          attrs: {
            label: "Land",
            isComponentHalfSize: "",
            values: _vm.comboboxSelections.countryValues,
            value: _vm.ansprechpartner.country,
            disabled: _vm.loading || _vm.readOnly
          },
          on: {
            input: function($event) {
              return _vm.updateAP("country", $event)
            }
          }
        }),
        _c("InputToggleSwitch", {
          attrs: {
            label: "Reporting zusenden",
            value: _vm.ansprechpartner.sendReporting,
            disabled: _vm.loading || _vm.readOnly
          },
          on: {
            input: function($event) {
              return _vm.updateAP("sendReporting", $event)
            }
          }
        }),
        _c("AnsprechpartnerPicture", {
          attrs: {
            personId: _vm.ansprechpartner.id,
            disabled: _vm.loading || _vm.readOnly
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }