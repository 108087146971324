var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          defaultMenu: _vm.$appNavigation.currentTabMenu,
          actions: _vm.headerActions,
          title: "Courtagetabellen",
          subtitle: "Offene Wertpapiere"
        },
        on: {
          "action-FONDSPLATTFORMENVERGLEICH":
            _vm.openFondsplattformenvergleichModal
        }
      }),
      _c("WertpapierSearch", {
        ref: "wertpapierFilter",
        attrs: {
          name: "searchWPCourtage",
          emitFilters: "",
          isCourtage: "",
          type: _vm.wi_type,
          defaultOptions: _vm.defaultFilterOptions
        },
        on: { onSearch: _vm.handleSearch }
      }),
      _vm.fondsOhneCourtageText
        ? _c("div", { staticClass: "box__container" }, [
            _c("div", { staticClass: "box__title" }, [_vm._v("Info")]),
            _c("span", {
              domProps: {
                innerHTML: _vm._s(_vm.sanitize(_vm.fondsOhneCourtageText))
              }
            })
          ])
        : _vm._e(),
      _vm.gebuehrFondsOhneCourtageInfo
        ? _c("div", { staticClass: "box__container" }, [
            _c("div", { staticClass: "box__title" }, [
              _vm._v("Gebühr: Fonds ohne Courtage")
            ]),
            _c("span", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.sanitize(_vm.gebuehrFondsOhneCourtageInfo)
                )
              }
            })
          ])
        : _vm._e(),
      _vm.filtered
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _vm.loading
                ? _c("GhostLoading", {
                    attrs: { type: "table", title: _vm.TABLE_TITLE }
                  })
                : _vm.rowCount == 0
                ? _c("NoData", { attrs: { title: _vm.TABLE_TITLE } })
                : _vm.rowCount
                ? [
                    _c("Table", {
                      attrs: {
                        title: _vm.TABLE_TITLE,
                        tableId: "99aaf2c1-69c9-42f3-9118-978d8ea8f459",
                        headers: _vm.headers,
                        rows: _vm.rows,
                        rowId: "isin",
                        rowsPerPage: 20,
                        exportConfig: _vm.exportConfig,
                        mobileConfig: {
                          title: "fondsname",
                          headers: [
                            "gesellschaft",
                            "abschlussProvision",
                            "bestandsProvision"
                          ]
                        }
                      }
                    })
                  ]
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _c(
        "BaseModal",
        {
          ref: "fondsplattformenModal",
          attrs: {
            labelButtonConfirm: "Vergleichen",
            modalTitle: "Fondsplattformen"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.filterFondsplattformenvergleich()
            }
          }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _vm.fondsplattformen && _vm.fondsplattformen.rows.length
                  ? _c("Table", {
                      attrs: {
                        tableId: "18bed2fa-f425-44cf-b011-714f72243980",
                        rowId: "lagerstelle",
                        headers: _vm.fondsplattformen.headers,
                        rows: _vm.fondsplattformen.rows
                      },
                      model: {
                        value: _vm.selectedRows,
                        callback: function($$v) {
                          _vm.selectedRows = $$v
                        },
                        expression: "selectedRows"
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }