var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DragnDropArea",
        {
          attrs: { hoverText: "Datei hier ablegen", disabled: _vm.isBroker },
          on: {
            files: function($event) {
              return _vm.openAddVersicherungPage($event)
            }
          }
        },
        [
          _vm.isCustomer
            ? _c("InsuranceOptionMenu", {
                attrs: { headerActions: _vm.headerActions },
                on: {
                  "action-ADD": function($event) {
                    return _vm.navigateToAuswahlVertragsart()
                  },
                  "action-VERSICHERUNG_KUENDIGUNG": function($event) {
                    return _vm.handleVersicherungKuendigung()
                  },
                  "action-KUENDIGUNGSMAIL": function($event) {
                    return _vm.handleMailComposerCancelInsurance()
                  }
                }
              })
            : _c(
                "div",
                [
                  _c("PageHeaderTitleNavigation", {
                    attrs: { title: _vm.$appNavigation.currentMenu.label }
                  })
                ],
                1
              ),
          _c("BaseFilter", {
            ref: "baseFilter",
            attrs: {
              filterId: "Versicherungssuche",
              showSaveButton: "",
              title: "Versicherungssuche",
              saveKey: "customerSearchAdvanced",
              metadata: _vm.searchMetadata,
              hasSmartSearch: "",
              configFilter: _vm.configFilter,
              defaultOptions: _vm.defaultOptions,
              openFilterOnMount: false
            },
            on: {
              onFilter: function($event) {
                return _vm.handleSearch($event)
              },
              storedFiltersLoaded: function($event) {
                return _vm.initSearch($event)
              },
              onFilterZurucksetzen: function($event) {
                _vm.insuranceCancelingViewEnabled = false
              },
              changeSubFilter: _vm.handleFilterChanges
            }
          }),
          _c("HandleTablePin", {
            attrs: { keyOfPIN: "TABELLE_VERSICHERUNGEN" }
          }),
          _vm.filtered
            ? _c(
                "div",
                {
                  staticClass: "box__container",
                  attrs: { tid: "6eb27d9e-918c-4dc5-91bb-53b6a3f21b42" }
                },
                [
                  _c("InsuranceGroupTable", {
                    ref: "versicherungsliste",
                    attrs: {
                      tableId: "154e6a24-0fe9-404c-bd2d-625cf9358c41",
                      headers: _vm.headers,
                      pages: _vm.pages,
                      count: _vm.insurancesCount,
                      pageCount: _vm.pageCount,
                      pageSize: _vm.rowsPerPage,
                      menuRowsPerPage: _vm.menuRowsPerPage,
                      exportConfig: _vm.exportConfig,
                      isLoading: _vm.isLoading && !_vm.isScrollLoading,
                      selected: _vm.selectedRows,
                      selectionAsBlacklist: _vm.selectionAsBlacklist,
                      backAction: _vm.backAction && _vm.backAction.action,
                      headerActions: _vm.tableHeaderActions,
                      insuranceCancelingViewEnabled:
                        _vm.insuranceCancelingViewEnabled,
                      mobileConfig: {
                        title: "bezeichnung",
                        headers: ["gesellschaftText", "status", "nummer"]
                      },
                      dragnDropFilesOnRow: "",
                      mitZuordnenAktion: _vm.mitZuordnenAktion
                    },
                    on: {
                      selected: _vm.onSelect,
                      selectionAsBlacklist: function($event) {
                        _vm.selectionAsBlacklist = $event
                      },
                      rowsPerPage: _vm.changeRowsPerPage,
                      action: function($event) {
                        return _vm.handleInsuranceAction($event)
                      },
                      requestPage: _vm.requestPage,
                      onSort: function($event) {
                        return _vm.sortDataByColumn($event)
                      },
                      "row-files": function($event) {
                        return _vm.handleRowFiles($event)
                      },
                      "item-selected": function($event) {
                        return _vm.openItemSelectedOverview($event)
                      },
                      "headerAction-VERTRAG-VEREINIGEN": function($event) {
                        return _vm.openVertragVereinigenModal()
                      },
                      "headerAction-MAIL-COMPOSER-COMPANY": function($event) {
                        return _vm.handleMailComposerCompany()
                      },
                      onScroll: _vm.onScroll,
                      scrollLoading: function($event) {
                        _vm.scrollLoading = $event
                      },
                      zuordnenAktion: function($event) {
                        return _vm.handleInsuranceAction($event)
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c("ShowDeeplinksModal", {
            ref: "showDeeplinksModal",
            on: { "open-item": _vm.openItemSelectedDetail }
          })
        ],
        1
      ),
      _c("DeleteInsuranceModal", {
        ref: "deleteModal",
        attrs: {
          insurance: _vm.insuranceToDelete,
          insuranceLabel: _vm.getInsuranceLabel(_vm.insuranceToDelete)
        },
        on: {
          delete: function($event) {
            return _vm.removeInsurance()
          }
        }
      }),
      _c("VertragVereinigenModalNew", {
        ref: "vertragVereinigenModalNew",
        attrs: { vertragList: _vm.selectedAndLoaded },
        on: {
          save: function($event) {
            return _vm.mergeInsurances($event)
          }
        }
      }),
      _c("VertragVereinigenModal", {
        ref: "vertragVereinigenModal",
        on: {
          save: function($event) {
            return _vm.mergeInsurances($event)
          }
        }
      }),
      _c(
        "BaseModal",
        {
          ref: "mailContacts",
          attrs: {
            modalTitle: "Kontakte",
            size: "lg",
            showCancelButton: false,
            labelButtonConfirm: "Auswahl übernehmen"
          },
          on: { onConfirmButton: _vm.confirmContactsToSend }
        },
        [
          _c("InsuranceCompanyMailContacts", {
            attrs: { records: _vm.mailUsers },
            on: { selectedRows: _vm.onSelectedRow }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }