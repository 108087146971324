<template>
      <DraggableParentElement v-if="isStoppuhrRunning" @dragFinished="setDragging()" :style="dragging ? 'height: none;' : 'height: auto;'">
      <div class="d-flex draggable_div" :style="isStoppuhrRunning ? 'display: none' : 'display: block'">
          Stoppuhr&nbsp;
          <StoppuhrWatch />
      </div>
    </DraggableParentElement>

</template>

<script>
import { mapGetters } from 'vuex'
import STOPPUHR_TYPES from "@/store/stoppuhr/types";
import DraggableParentElement from '@/components/core/DraggableParentElement.vue'
import StoppuhrWatch from '@/views/communication/stoppuhr/StoppuhrWatch.vue';

import stoppurShared from "@/views/communication/stoppuhr/stoppuhr-mixing.js";

export default {
  mixins: [stoppurShared],
  components: {
    DraggableParentElement,
    StoppuhrWatch,
  },
  props: {
  },
  computed: {
    ...mapGetters({
      isStoppuhrRunning: STOPPUHR_TYPES.GETTERS.IS_STOPPUHR_RUNNING,
    }),
  },
  data() {
    return {
      isLoggedIn: true,
      dragging: false, 
    }
  },
  methods: {
    setDragging() {
      this.dragging = true;
    }
  },
}
</script>

<style scoped>

.draggable_div {
  font-size: 20px;
  column-gap: 8px;
  padding: 8px; 
}

</style>
