import VERTRAG_WP_TYPES from './types';
import { getInitialState } from './index'
import Vue from 'vue';

export default {
	[VERTRAG_WP_TYPES.MUTATIONS.GET_VERTRAG_WP_SUCCESS](state, payload) {
		Vue.set(state, 'vertragWP', payload)
	},

	[VERTRAG_WP_TYPES.MUTATIONS.GET_VERTRAG_WP_ERROR](state) {
		Vue.set(state, 'vertragWP', getInitialState().vertragWP)
	},

	[VERTRAG_WP_TYPES.MUTATIONS.GET_VERTRAG_WP_HINWEIS_SUCCESS](state, payload) {
		Vue.set(state, 'vertragWPHinweis', payload)
	},

	[VERTRAG_WP_TYPES.MUTATIONS.GET_VERTRAG_WP_HINWEIS_ERROR](state) {
		Vue.set(state, 'vertragWPHinweis', getInitialState().vertragWPHinweis)
	},

	[VERTRAG_WP_TYPES.MUTATIONS.GET_VERTRAG_WP_VALUES_SUCCESS](state, payload) {
		Vue.set(state, 'vertragWPValues', payload)
	},

	[VERTRAG_WP_TYPES.MUTATIONS.GET_VERTRAG_WP_VALUES_ERROR](state) {
		Vue.set(state, 'vertragWPValues', getInitialState().vertragWPValues)
	},

	[VERTRAG_WP_TYPES.MUTATIONS.SAVE_VERTRAG_WP_SUCCESS](state, payload) {
		Vue.set(state.vertragWP, 'formData', payload?.formData)
		Vue.set(state.vertragWP, 'errors', payload?.errors)
	},

	[VERTRAG_WP_TYPES.MUTATIONS.RESET_VERTRAG_WP_ERROR_LIST](state) {
		Vue.set(state.vertragWP, 'errors', [])
	},

	[VERTRAG_WP_TYPES.MUTATIONS.RESET_STATE](state) {
		Object.assign(state, getInitialState())
	},

}
