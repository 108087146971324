var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.noop.apply(null, arguments)
        },
        keyup: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.doSearch()
        }
      }
    },
    [
      _c("InputField", {
        attrs: { placeholder: "(ISIN, WKN, Name)", type: "text" },
        model: {
          value: _vm.searchKey,
          callback: function($$v) {
            _vm.searchKey = $$v
          },
          expression: "searchKey"
        }
      }),
      _c(
        "BaseButton",
        {
          attrs: { isPrimaray: "" },
          on: {
            click: function($event) {
              return _vm.doSearch()
            }
          }
        },
        [_vm._v("Suchen")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }