<template>
  <div>
    <BemerkungenList
      :records="bemerkungen"
      :loading="loading"
      :typ="VALUE_TYP"
      basePath="/intern"
      @delete="deleteRow"
      @deleteSelected="deleteSelected"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BROKERDATA_TYPES from "@/store/brokerData/types";
import BemerkungenList from "@/components/persoenliche-daten/BemerkungenList.vue";
import axios from 'axios';
import { makeQueryParam } from '@/helpers/utils-helper';
import vermittlerDatenMixin from "@/views/intern/vermittler-daten-mixin";

const VALUE_TYP = "makler";

export default {
  mixins: [vermittlerDatenMixin],
  components: {
    BemerkungenList,
  },
  data() {
    return {
      loading: false,
      VALUE_TYP,
    };
  },
  computed: {
    ...mapGetters({
      bemerkungen: BROKERDATA_TYPES.GETTERS.BEMERKUNGEN,
    }),
  },
  methods: {
    async findBemerkungen() {
      try {
        this.loading = true;
        await this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.FIND_BEMERKUNGEN);
      } finally {
        this.loading = false;
      }
    },
    deleteRow({ id }) {
      const params = makeQueryParam({ id, typ: VALUE_TYP });

      axios
        .delete(
          `${process.env.VUE_APP_API}/postfachBemerkung/bemerkung?${params}`,
          { defaultSpinner: true }
        )
        .then(() => this.findBemerkungen());
    },
    deleteSelected({ ids, next }) {
      const params = makeQueryParam({ ids, typ: VALUE_TYP });

      axios
        .delete(
          `${process.env.VUE_APP_API}/postfachBemerkung/bemerkungen?${params}`,
          { defaultSpinner: true }
        )
        .then(() => this.findBemerkungen().then(() => next()));
    },
  },
  mounted() {
    this.findBemerkungen();
  },
  async beforeRouteLeave(to, from, next) {
    await this.saveChanges().then(() => next());
  },
};
</script>
