<template>
  <div>
    <PageHeaderTitleNavigation 
      :defaultMenu="$appNavigation.currentTabMenu" 
      title="Courtagetabellen"
      subtitle="Beteiligungen" 
    />

    <BaseFilter v-if="searchMetadata && searchMetadata.length"
      filterId="courtagetabelleInvestmentBeteiligingenSuche"
      title="Beteiligungen"
      replacePreviousSelectedValues
      :metadata="searchMetadata"
      :configFilter="configFilter"
      @onFilter="handleSearch($event)"
    />

    <div class="box__container" v-if="gebuehrFondsOhneCourtageInfo">
      <div class="box__title">Info</div>
      <span v-html="sanitize(gebuehrFondsOhneCourtageInfo)"/>
    </div>

    <div v-if="filtered" class="box__container">
      <GhostLoading v-if="loading" type="table" :title="TABLE_TITLE" />

      <NoData v-else-if="rowCount == 0" :title="TABLE_TITLE" />

      <template v-else-if="rowCount">
        <PaginatedTable
          tableId="99aaf2c1-69c9-42f3-9118-978d8ea8f459"
          :title="TABLE_TITLE"
          :headers="headers"
          :pages="pages"
          :pageCount="pageCount"
          :rowCount="rowCount"
          :page="selectedPage"
          :exportConfig="exportConfig"
          @page="selectedPage = $event"
          @requestPage="loadPage"
          @sort="sortDataByColumn($event)"
          v-model="selectedRow"
        />
      </template>
      
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import COURTAGETABELLE_INVESTMENT_TYPES from '@/store/courtagetabelleInvestment/types';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import PaginatedTable from '@/components/table2/PaginatedTable.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import BaseFilter from '@/components/core/BaseFilter.vue';
import {sanitize} from '@/helpers/string-helper.js';
import pageSizeMixin from './page-size-mixin.js';
import { oldToNewColumns, } from '@/components/table2/table_util';

const TABLE_TITLE = 'Courtagetabelle';

export default {
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    PaginatedTable,
    GhostLoading,
    BaseFilter,
    NoData,
  },
  mixins: [pageSizeMixin],
  data() {
    return {
      TABLE_TITLE,
      loading: false,
      selectedRow: null,
      selectedPage: 0,
      selectedFilters: {},
      orderBy: null,
      selectedInfo: null,
      configFilter: {
        defaultSearchInputKeys: [],
        noResetOnDefaultSearchInputExit: true,
        hideFirstColumn: true,
        filterType: 'courtagetabelleInvestmentBeteiligungen',
        singleFilter: true,
      },
      exportSelectedFilters: {},
      filtered: false,
    }
  },
  computed: {
    ...mapGetters({
      filterFieldsDefinition: COURTAGETABELLE_INVESTMENT_TYPES.GETTERS.FILTER_FIELDS_DEFINITIONS,
      courtagetabelleInvestmentList: COURTAGETABELLE_INVESTMENT_TYPES.GETTERS.COURTAGETABELLE_INVESTMENT_LIST,
    }),
    predefinedFilters(){
      return [{key: '1.1.06', value: 'Alle'}]
    },
    searchMetadata(){
      if(Object.keys(this.filterFieldsDefinition)?.length){
        return [this.filterFieldsDefinition];
      }
      return [];
    },
    headers() {
      if(this.courtagetabelleInvestmentList?.headers) {
        const headersFiltered = oldToNewColumns(this.courtagetabelleInvestmentList?.headers.filter(header => header.visible))
        return {
          lockedLeft: [headersFiltered.shift()],
          center: headersFiltered,
          lockedRight: [],
        }
      }
      return {};
    },
    pages() {
      if (!this.courtagetabelleInvestmentList?.rows) {
          return [];
      }
      const pages =  {
       [this.selectedPage]: [...this.courtagetabelleInvestmentList.rows]
      }
      return pages;
    },
    rowCount() {
      return this.courtagetabelleInvestmentList?.count || 0;
    },
    pageCount() {
      return Math.ceil(this.rowCount / this.maxCount);
    },
    fondsOhneCourtageText() {
      return this.courtagetabelleInvestmentList?.fondsOhneCourtageText || null;
    },
    gebuehrFondsOhneCourtageInfo() {
      return this.courtagetabelleInvestmentList?.gebuehrFondsOhneCourtageInfo || null;
    },
    exportConfig() {
      return {
        roottext: 'Beteiligungen',
        dispatch: this.exportFile,
      };
    },
  },
  methods:{
    sanitize(htmlString) {
      return sanitize(htmlString);
    },
    handleSearch(event){
      if(this.$route.path.includes('beteiligungen')) {
        const filter = event.shift();
        this.selectedFilters = {[filter.key]: filter.value};
      } else {
        this.selectedFilters = event;
      }
      this.filterData();
    },
    filterData() {
      const lagerstelle = 'COMBO_LAGERSTELLEBETEILIGUNGEN'
      if(!Object.keys(this.selectedFilters).length || !this.selectedFilters?.[lagerstelle]) {
        this.emitAlertOfMissingFilter();
        return;
      }
      this.loading = true;
      this.filtered = true;
      this.exportSelectedFilters = { ...this.selectedFilters, },
      this.$store.dispatch(COURTAGETABELLE_INVESTMENT_TYPES.ACTIONS.GET_COURTAGETABELLE_INVESTMENT_LIST, 
          {filters: this.selectedFilters, page: this.selectedPage, order: this.orderBy, type: 'BETEILIGUNGEN', maxCount: this.maxCount}
        )
        .finally(() => this.loading = false);
    },
    loadPage(event){
        this.selectedPage = event;
        this.filterData();
    },
    sortDataByColumn(event) {
      if(event){
        this.orderBy = event;
        this.filterData();
      }
    },
    emitAlertOfMissingFilter() {
      this.$confirmModal({
        message: 'Bitte wählen Sie eine Lagerstelle aus',
        title: 'Benötigter Filter',
        labelButtonConfirm: 'ok'
      })
    },
    async exportFile(fileType) {
      if(!Object.keys(this.exportSelectedFilters).length) return;

      return await this.$store.dispatch(COURTAGETABELLE_INVESTMENT_TYPES.ACTIONS.EXPORT_COURTAGETABELLE_INVESTMENT, {
        filters: this.exportSelectedFilters,
        type: 'BETEILIGUNGEN',
        fileType,
      });
    },
  },
  mounted(){
    this.$store.dispatch(COURTAGETABELLE_INVESTMENT_TYPES.ACTIONS.GET_FILTER_FIELDS_DEFINITIONS, {type: 'BETEILIGUNGEN'});
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu Details', 
      to,
      from,
      replaceLastPreviousData: true,
    });

    this.selectedFilters = null;
    this.$store.commit(COURTAGETABELLE_INVESTMENT_TYPES.MUTATIONS.CLEAR_COURTAGETABELLE_INVESTMENT_LIST);

    next()
  },

}
</script>
