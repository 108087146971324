import SEARCH_CONFIGS_TYPES from './types';
import FC_CONFIG_TYPES from '@/store/fcConfig/types'
import FC_CONFIG from '@/configs/fcConfig.js'

export default {
  [SEARCH_CONFIGS_TYPES.GETTERS.TABLE_SORT_OPTIONS](state) {
    return (tableId) => tableId && state.tableSortOptions?.[tableId]
  },

  [SEARCH_CONFIGS_TYPES.GETTERS.FILTER_OPTIONS](state) {
    return (filterId) => filterId && state.filterOptions?.[filterId]
  },

  [SEARCH_CONFIGS_TYPES.GETTERS.LOADED_FILTERS](state, getters) {
    const fcConfigFilters = getters[FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG]?.[FC_CONFIG.STORED_SEARCH_PARAMETER] || {}
    const loadedFiltersFC = {}

    for (const [filterId, config] of Object.entries(fcConfigFilters)) {
      if (config?.content) {
        loadedFiltersFC[filterId] = {
          searchParameters: JSON.parse(config.content)
        }
      }
    }

    return loadedFiltersFC
  },

  [SEARCH_CONFIGS_TYPES.GETTERS.GET_FILTER_INTERNAL](state) {
    return state.presetFilter;
  }, 
}