<template>
  <div>
    <PageHeaderTitleNavigation title="DataControl Synchronisation" />

    <div class="box__container">
      <FormLabel
        label="Synchronisation DataControl mit smartMSC"
        :config="{ bold: true }"
      />
      {{ lbMessage }}
      <div class="box_container">
        <div class="row justify-content-center mt-3">
          <ComboBox label="Mandat" v-model="mandat" :values="mandatValues">
        </ComboBox>
        </div>
        <div class="row justify-content-center mt-3">
            <BaseButton @click="vertraegeSenden()">
          Verträge an DCS senden <AnimatedSpinner v-if="isLoadingVetraegeAn" />
        </BaseButton>
        </div>
        <div class="row justify-content-center mt-3">
           <BaseButton @click="vertraegeAbholen()">
          Verträge von DCS abholen
          <AnimatedSpinner v-if="isLoadingVetraegeVon" />
        </BaseButton>
        </div>
        <div class="row justify-content-center mt-3">
           <BaseButton @click="getProvisionAbrechnungen()">
          Provisionabrechnungen von DCS abholen<AnimatedSpinner
            v-if="isLoadingProv"
          />
        </BaseButton>
        </div>
      
       <div class="row justify-content-center mt-3">
         <BaseButton class="mt-5" @click="zuordnungsListeAufrufen()">
          <span>Data Control Zuordnungsliste aufrufen</span>
        </BaseButton>
       </div>
       
      </div>
    </div>
    <BaseModal ref="infoModal" :showDefaultButtons="false">
      <template v-slot:modalTitle>
        <PhInfo style="color: var(--color-success)" /> {{ infoText }}
      </template>
    </BaseModal>
    <BaseModal ref="errorModal" :showDefaultButtons="false">
      <template v-slot:modalTitle>
        <PhWarning style="color: var(--color-danger)" /> Fehler beim Export an DC
      </template>
    </BaseModal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import CORE_TYPES from "@/store/core/types";
import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import PageHeaderTitleNavigation from "@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import BaseButton from "@/components/core/BaseButton.vue";
import FormLabel from "@/components/core/forms/FormLabel.vue";
import DCS_SYNCHRONISATION_TYPES from "@/store/dcsSynchronisation/types";
import BaseModal from "@/components/core/BaseModal.vue";
import { PhInfo, PhWarning } from "phosphor-vue";
import AnimatedSpinner from "@/components/core/AnimatedSpinner.vue";
import { viewDocument } from "@/components/core/download/DownloadLink.vue";

export default {
  data() {
    return {
      mandatValues: [
        { label: "FP+ GBR (0)", value: "0" },
        { label: "FP+ GmbH (10000001)", value: "10000001" },
        { label: "FP+ Gewerbe GmbH (10000002)", value: "10000002" },
        { label: "FP+ Service GmbH (10000003)", value: "10000003" },
      ],
      mandat: "10000001",
      loadingVertraegeAn: false,
      loadingVertraegeVon: false,
      loadingProv: false,
      lbMessage: "",
      infoText: "",
    };
  },
  mounted() {},
  computed: {
    ...mapGetters({
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
    }),
     isLoadingVetraegeAn() {
      return this.loadingVertraegeAn;
    },
     isLoadingVetraegeVon() {
      return this.loadingVertraegeVon;
    },
     isLoadingProv() {
      return this.loadingProv;
    },
    
  },
  methods: {
    async vertraegeSenden() {
      this.loadingVertraegeAn = true;
      this.$store
        .dispatch(DCS_SYNCHRONISATION_TYPES.ACTIONS.SEND_VERTRAEGE, this.mandat)
        .then((response) => {
          if (!this.isIntern) {
            if (response.data?.errors) {
              this.$refs.errorModal.open();
            } else {
              this.lbMessage = "DCS Vertragsdaten mit DCS Synchronisiert";
              this.infoText = "Datensätze erfolgreich zu DC exportiert";
              this.$refs.infoModal.open();
            }
          }
          this.loadingVertraegeAn = false;
        });
    },
    async vertraegeAbholen() {
      this.loadingVertraegeVon = true;
      this.$store
        .dispatch(DCS_SYNCHRONISATION_TYPES.ACTIONS.GET_VERTRAEGE, this.mandat)
        .then((response) => {
          this.lbMessage =
            "DCS Vertragsdaten mit DCS Synchronisiert: " + response.data;
          this.loadingVertraegeVon = false;
        });
    },
    async getProvisionAbrechnungen() {
      this.loadingProv = true;
      this.$store
        .dispatch(
          DCS_SYNCHRONISATION_TYPES.ACTIONS.GET_PROVISION_ABRECHNUNGEN,
          this.mandat
        )
        .then((response) => {
          this.lbMessage =
            "DCS Provisionsabrechnungen gelesen: " +
            response.data.abrechnungenSize +
            ": " +
            response.data.bezeichnung;
          this.infoText = "Import Abrechnungen DC";
          this.loadingProv = false;
          this.$refs.infoModal.open();
          this.openDocument(response);
        });
    },
    zuordnungsListeAufrufen() {
      this.$router.push({ path: "/intern/data-control-zuordnungen" });
    },
    openDocument(data) {
      if (data.file && data.file.bytes) {
        viewDocument({
          data: data.file.bytes,
          contentType: "application/txt",
          filename: "Fehlerliste_Abrechnungimport.txt",
        });
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({ to, from });
    next();
  },
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    ComboBox,
    BaseButton,
    FormLabel,
    BaseModal,
    PhInfo,
    PhWarning,
    AnimatedSpinner,
  },
};
</script>