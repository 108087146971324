<template>
  <div>
    <div v-if="loading">
      <div class="box__container">
        <GhostLoading v-for="item in [1,2,3,4,5]" :key="item">
          <Block height="45" />
        </GhostLoading>
      </div>
      <div class="box__container">
        <GhostLoading >
          <Block height="100" />
        </GhostLoading>
      </div>
    </div>
    <div class="box__container" v-if="!loading">
      <div  class="row ">
        <div class="col-12">
          <InputField
            v-model="stepData.bezeichnung"
            label="Bezeichnung"
            :isComponentHalfSize="true"
            :validateUntouched="true"
            @change="markModified"></InputField>
          <template v-if="stepParameters && stepParameters.data && stepParameters.data.vorgangAuswahl">
            <ComboBox
              label="Vorgang"
              :isComponentHalfSize="true"
              @change="triggerSelectVorgang"
              v-model="stepData.vorgang"
              :isEmbedded="false"
              :values="stepParameters.data.vorgangAuswahl">
            </ComboBox>
          </template>
          <template v-if="stepParameters && stepParameters.data && stepParameters.data.ziellagerstelleAuswahl">
            <ComboBox
              @change="triggerSelectLagerstelle"
              label="Ziellagerstelle"
              :isComponentHalfSize="true"
              v-model="stepData.ziellagerstelle"
              :isEmbedded="false"
              :values="stepParameters.data.ziellagerstelleAuswahl">
            </ComboBox>
          </template>
          
          <DatePickerField
            label="Backtest-Zeitraum"
            isRangePicker
            isComponentHalfSize
            :value="{
              from: backtestStart,
              to: backtestEnd,
            }"
            @onSelectRangeFrom="pChanged('backtestStart', $event)"
            @onSelectRangeTo="pChanged('backtestEnd', $event)"
            @input="markModified"
            @onBlur="checkRange"
          />

          <InputRadioBoxGroup class="pb-0 pt-3"
            v-model="stepData.zielsummeberechnung"
            @input="markModified"
            :validateUntouched="true"
            :values="zielsummeberechnungValues"
          />
          <InputField
            v-if="stepData.zielsummeberechnung"
            :isComponentHalfSize="true"
            v-model="stepData.zielsumme"
            @change="markModified"
            type="currency"
            :validateUntouched="true"
          ></InputField>
          <InputField
            v-if="!stepData.zielsummeberechnung"
            :isComponentHalfSize="true"
            v-model="stepData.anlagesumme"
            @change="markModified"
            type="currency"
            label="Neuanlagesumme "
            :validateUntouched="true"
          ></InputField>
        </div>
      </div>
    </div>
    <InvestmentAdviceTable  v-if="!loading && stepParameters && stepParameters.data && stepParameters.data.parameterData" 
      :id="id" stepName="MAIN" @modified="markModified" @import_table="importAllocation"
    />
  </div>
</template>

<script>
import InputField from "@/components/core/forms/InputField.vue";
import InputRadioBoxGroup from "@/components/core/forms/radiobox/InputRadioBoxGroup.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import InvestmentAdviceTable from './InvestmentAdviceTable.vue'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import StepMixin from "./StepMixin";
import StepAllocation from "./StepAllocation";
import ParameterData from "./ParameterData";
import INVESTMENT_ADVICE from "@/store/investmentAdvice/types";
import BaseButton from '@/components/core/BaseButton.vue';
import dayjs from 'dayjs';
import { isDate } from '@/helpers/commonfunctions.js';

const DATE_FORMAT = 'DD.MM.YYYY';
const ANLAGEBETRAG_VALUE = false;
const ZIELSUMME_VALUE = true;

export default {
  data() {
    return {
      step: INVESTMENT_ADVICE.STEPS.MAIN,
      stepData: new ParameterData(),
      stepAllocation: [
        new StepAllocation(
          INVESTMENT_ADVICE.QUELLE.KUNDENDEPOT, null
        ),
      ],
      vorgangComboOptions: [{ value: "11166", label: "Testvorgang" }],
      bestandsdepotComboOptions: [{ value: "222", label: "Testdepot" }],
      ziellagerstelleComboOptions: [{ value: "333", label: "Testlagerstelle" }],
      percentPrecision: 6,
      backtestStart: null,
      backtestEnd: null,
    };
  },
  computed: {
    depotsumme() {
      return this.stepAllocation[0].sum();
    },
    removable() {
      return this.stepAllocation[0].source === "-4"; // See AnlageempfehlungServlet DepotCode
    },
    importable() {
      return this.stepAllocation[0].source === "-6";
    },
    editable() {
      return this.stepAllocation[0].source === "-6" || this.stepAllocation[0].source === "-4";
    },
    isDepotoptimierung() {
      return this.stepData?.depotoptimierung;
    },
    zielsummeberechnungValues() {
      return [
        { value: ANLAGEBETRAG_VALUE, label: 'Anlagebetrag' },
        { value: ZIELSUMME_VALUE, label: 'Zielsumme', disabled: this.isDepotoptimierung },
      ];
    },
  },
  components: {
    InputField, ComboBox, DatePickerField, InputRadioBoxGroup, BaseButton, GhostLoading, Block,
    InvestmentAdviceTable, 
  },
  mixins: [StepMixin],
  prop: {},
  watch: {
    isDepotoptimierung(value) {
      if(value) {
        this.stepData.zielsummeberechnung = ANLAGEBETRAG_VALUE;
      }
    },
  },
  methods: {
    formatDate(date) {
      if(date && !isDate(dayjs(date, DATE_FORMAT).toDate())) {
        return dayjs(date).format(DATE_FORMAT);
      }
      return date;
    },
    parseDate(date) {
      if(date && !isDate(date)) {
        return dayjs(date, DATE_FORMAT).toDate();
      }
      return date;
    },
    importAllocation(event) {
      if (event) {
        let file = new FormData();
        file.append("name", event.name);
        file.append("data", event);
        this.$store.dispatch(INVESTMENT_ADVICE.ACTIONS.UPLOAD_ALLOCATION_FILE, {
        adviceId :this.id,
        file: file
      })
      }
    },
    async triggerSelectVorgang(event) {
      await this.saveParameters(false, true, event);
    },
    save(makePersist) {
      return this.saveParameters(makePersist, false);
    },
    saveParameters(makePersist, updateOnlyVorgang, vorgang) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      const parameterData = { ...this.stepParameters.data.parameterData };

      if (this.checkRange()) {
        if(this.backtestStart) {
          parameterData.backtestStart = this.parseDate(this.backtestStart);
        }
        if(this.backtestEnd) {
          parameterData.backtestEnd = this.parseDate(this.backtestEnd);
        }
      }
      if(updateOnlyVorgang) {
        parameterData.vorgang = vorgang;
      }

      return this.$store.dispatch(INVESTMENT_ADVICE.ACTIONS.SAVE_PARAMETERS, { 
        adviceId: this.id,
        parameters: {
          step: this.step,
          parameterData: parameterData,        
          updateOnlyVorgang: updateOnlyVorgang,
          persist: makePersist,
          rows: this.stepParameters.data.rows.map(row => ({
            id: row.id,
            isin: row.isin,
            active: row.active,
            changed: row.changed,
            isDeleted: row.isDeleted,
            addpos: row.addpos,
            lockingMode: row.lockingMode,
            value: row.value,
            due: row.due,
          }))
        }
      });
    },
    triggerSelectLagerstelle() { this.markModified()},
    triggerSelectDepot(event) {
      if (this.loading) {
        return;
      }
      this.stepParameters.data.parameterData.bestandsdepot = event.selection;
      this.save(false);
    },
    pChanged(key, value) {
      this[key] = value;
      this.modified = true;
    },
    checkRange() {
      let mess = '', parts;
      if (!this.backtestStart || !this.backtestEnd ) {
        mess = 'Falscher Zeitraum';
      } else {
        let start = this.backtestStart;
        if (typeof start == 'string') {
          parts = start.split('.').map(p => parseInt(p, 10));
          start = new Date(parts[2], parts[1] - 1, parts[0]);
        }
        let end = this.backtestEnd;
        if (typeof end == 'string') {
          parts = end.split('.').map(p => parseInt(p, 10));
          end = new Date(parts[2], parts[1] - 1, parts[0]);
        }
        if (end.getTime() < start.getTime()) {
          mess = 'Falscher Zeitraum';
        }
      }
      if (mess) {
        this.$store.commit(INVESTMENT_ADVICE.MUTATIONS.SET_MESSAGE, {
          show: true,
          text: mess
        })
        return false;
      }
      return true;
    },
    parametersChanged(parameters) {
      if (parameters && parameters.data) {
        const stepData = parameters.data.parameterData;
        if(stepData?.backtestStart) {
          this.backtestStart = this.formatDate(stepData.backtestStart);
        }
        if(stepData?.backtestEnd) {
          this.backtestEnd = this.formatDate(stepData.backtestEnd);
        }
        this.stepData = stepData;
        this.stepAllocation[0].source = this.stepParameters.data.parameterData.bestandsdepot;
      }
    },
  },
};
</script>