<template>
<div>
    <template v-if="keys.length > 1">
        <label v-for="k in keys" :key="k">
            <input type="radio" :value="k" v-model="key"/>
            {{k}}
        </label>
    </template>
    <table>
        <tr v-for="(code, index) in displayedCode" :key="index">
            <td class="line-number">{{index}}</td>
            <td><pre v-html="code"></pre></td>
        </tr>
    </table>
</div>
</template>

<script>

export default {
    components: {
    },
    props: {
        code: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            key: null,
        };
    },
    mounted() {
        this.key = this.selectedKey;
    },
    computed: {
        keys() {
            return Object.keys(this.code);
        },
        selectedKey() {
            return this.key || this.keys[0];
        },
        codeSplit() {
            const result = this.code[this.selectedKey].split("\n");
            if (result.length > 1 && result[0] == "")
                result.shift();
            return result;
        },
        spacesToRemove() {
            let spaces = null;
            this.codeSplit.forEach(line => {
                if (line.length > 0) {
                    const s = line.length - line.trimLeft().length;
                    if (spaces == null || spaces > s)
                        spaces = s;
                }
            });
            return spaces || 0;
        },
        displayedCode() {
            return this.codeSplit
                .map(line => line.substr(this.spacesToRemove));
        },
    },
    methods: {
    },
}
</script>

<style scoped>
table {
    border-collapse: collapse;
    outline: 1px solid black;
}
td {
    padding: 0 10px;
}
pre {
    margin: 0;
}
::v-deep a {
    text-decoration: underline !important;
    color: blue !important;
}
.line-number {
    text-align: right;
    border-right: 1px solid black;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: small;
    background: lightgray;
}
</style>
