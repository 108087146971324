<template>
  <div class="label lstart">
    <div class="result">{{getStart}} €</div>
    <div class="truelabel">Start</div>
  </div>
</template>

<script>
//import store from "./../../store.js";
export default {
  name: "LStart",
  computed: {
    getStart() {
      return this.$store.getters.getStart;
    }
  },
};
</script>

<style scoped>
.lstart {
  grid-area: lstart;
}

</style>