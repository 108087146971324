<template>
<div>
  <InsuranceOptionMenu
    title="E-Mails & Aufgaben"
    :insuranceId="versicherungId"
    :headerActions="headerActions"
    @action-NEW-ACTIVITY="newAppointment()"
    @action-OLD-POLICE-NUMBERS="showOldPoliceNumbers()"
    @action-EMAIL-KUNDE="emailStart(false)"
    @action-EMAIL-GESELLSCHAFT="emailStart(true)"
    @action-SCHADEN-MELDEN="createSchaden()"
    @action-BRIEF-ERSTELLEN="briefStart()"
    @action-KUENDIGUNG-VERSICHERUNGEN="emailCancel()"
    @action-AUFGABE-HINZUFUEGEN="handleAddActivity()"
    @action-VERSICHERUNG-KOPIEREN="versicherungKopierenBestaetigung()"
    @action-DOKUMENTE-HOCHLADEN="dateiHochladen()"
    @action-EXTERNEN-ZUGANG-AUFRUFEN="externenZugangOeffnen()"  
  >
    <template #title>
      E-Mails & Aufgaben
    </template>
    <template #subtitle>
      <template v-if="gesellschaftName">
        <span class="subtitle-part"><a @click="navigateToGesellschaft()">{{ gesellschaftName }}</a></span>
        <span> | </span>
      </template>
      <template v-if="bezeichnung">
          <span class="subtitle-part">{{ bezeichnung }}</span>
          <span v-if="nummer || statusAktuell || showKennzeichen"> | </span>
        </template> 
      <template v-if="nummer">
        <router-link class="subtitle-part" :to="overviewLink(versicherungId)">{{ nummer }}</router-link>
        <span v-if="statusAktuell || showKennzeichen"> | </span>
      </template>
      <template v-if="statusAktuell">
        <span class="subtitle-part"><Pill :label="statusAktuell" :type="statusToPillType[statusAktuell]" /></span>
        <span v-if="showKennzeichen"> | </span>
      </template>
      <template v-if="showKennzeichen">
        <span class="subtitle-part">{{ kennzeichen }}</span>
      </template>
    </template>     
  </InsuranceOptionMenu>


    <PostfachOverviewTable
      title="E-Mails & Aufgaben"
      isInsurance
      :records="records"
      :loading="loadingActivities"
      :parentId="versicherungId"
      :disabledColumns="['name', 'contact']"
    />


  <EmailVersand/>

  <CopyInsuranceConfirmationModal ref="copyInsuranceConfirmationModal" @open-item="openItemSelectedDetail"></CopyInsuranceConfirmationModal>
  <ShowOldPoliceNumbersModal ref="showOldPoliceNumbersModal" @open-item="openItemSelectedDetail"></ShowOldPoliceNumbersModal> 
  <AddDocument ref="addDocumentModal" :nodeId="nodeId" :versicherungId="versicherungId"  @close="goToDownloadArea(versicherungId, true)" />

</div>
</template>

<script>
import { mapGetters } from 'vuex';
import COMMUNICATION_TYPES from '@/store/communication/types';
import PostfachOverviewTable from '@/views/communication/PostfachOverviewTable.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import InsuranceOptionMenu from './insurance-group/InsuranceOptionMenu.vue';
import CORE_TYPES from "@/store/core/types";
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';
import CALENDAR_TYPES from '@/store/calendar/types';
import InsuranceDetailMixin from '@/components/versicherungen/InsuranceDetail-mixin.js'
import Pill from '@/components/core/Pill.vue';
import EmailVersand from "@/components/core/EmailVersand.vue";
import CopyInsuranceConfirmationModal from '@/views/versicherungen/CopyInsuranceConfirmationModal.vue'
import ShowOldPoliceNumbersModal from '@/views/versicherungen/ShowOldPoliceNumbersModal.vue'
import AddDocument from '@/views/versicherungen//AddDocument.vue';

export default {
  mixins: [InsuranceDetailMixin],
  components: {
    PostfachOverviewTable,
    PageHeaderTitleNavigation,
    OptionMenu,
    InsuranceOptionMenu,
    Pill,
    EmailVersand,
    CopyInsuranceConfirmationModal,
    ShowOldPoliceNumbersModal,
    AddDocument,
  },
  data() {
    return {
      loadingActivities: false,
    }
  },
  computed: {
    ...mapGetters({
      records: COMMUNICATION_TYPES.GETTERS.COMMUNICATION_INSURANCE_RECORDS,
      isOriginalUserBroker: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_BROKER,
      isOriginalUserIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
    }),
    versicherungId() {
      return this.$route?.params?.versicherungId;
    },
    customOptionsMenu() {
      return [];
    },
  },
  methods: {
    async loadActivities() {
      try {
        this.loadingActivities = true;
        await this.$store.dispatch(COMMUNICATION_TYPES.ACTIONS.LOAD_COMMUNICATION_INSURANCE_LISTS, this.$route?.params?.versicherungId);
      } finally {
        this.loadingActivities = false;
      }
    }, 
  },
  async mounted() {
    this.loadActivities();
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      to, from
    });  

    next();
  }

}
</script>
<style lang="scss" scoped>
.subtitle-part {
  word-break: break-word;
}
</style>