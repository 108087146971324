<template>
  <div :style="style">
    <v-chart class="chart" :option="option" :theme="themeKey" autoresize  ref="refVChart" />
  </div>
</template>

<script>
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { BarChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from 'echarts/components';
import VChart, { INIT_OPTIONS_KEY } from 'vue-echarts';

import echartsMixin from './echarts-mixin';
import screenSize from '@/mixins/screen-size';

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  name: 'BarChart',
  mixins: [echartsMixin,screenSize],
  components: {
    VChart
  },
  provide: {
    [INIT_OPTIONS_KEY]: { locale: 'DE' }
  },
  props: {
    chartData: {
      type: Array,
      default: () => [],
    },
    chartSeries: {
      type: Array,
      default: () => [],
    },
    format: {
      type: String,
      default: 'currency',
    },
    numberFixed: {
      type: Number,
      default: 2,
    },
    height: {
      type: String,
      default: '200px',
    },
    labels: {
      type: Array,
      default: () => [],
    },
    gridLeft: {
      type: [Number, String],
      default: '5%',
    },
    chartOptions: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      refresh: 1,
    }
  },
  mounted() {
    this.refresh++;
    this.$addResizeListener((event) => {
      this.refresh++;
    })
  },
  computed: {
    option() {
      const option = {
        type: 'bar',
        name: this.chartDataName,
      };
      let series = (this.chartSeries?.length ? this.chartSeries.map(cs => ({
        ...option,
        ...cs,
      })) : [
        {
          ...option,
          data: this.chartData || [],
          label: {
            show: true,
            position: [(this.$refs.refVChart?.$el?.clientWidth * 0.3 || 200),0],
            formatter: v => this.formattedValue(v.value) + ' ' + v.name,
          },
        }
      ]);
      const chartOptions = this.chartOptions || {};
      series.forEach(serie => {
        const data = JSON.parse(JSON.stringify(serie.data))
        serie.data = data.reverse();
      })
      if (this.refresh) { // only true, refresh chart by screen size changing
        series = chartOptions.series ? chartOptions.series : series;
      }
      return {
        color: this.themeColors,
        backgroundColor: 'transparent',
        grid: {
          left: 20,
          right: '70%',
          top: 10,
          bottom: 10,
          containLabel: false,
        },
        tooltip: {
          show: this.isTooltip,
          formatter: this.tooltipFormatter,
          position: { left: 'center', top: 'auto'},
        },
        xAxis: chartOptions.yAxis ? chartOptions.yAxis :  {
          show: false,
          type: 'value',
        },
        yAxis: chartOptions.xAxis ? chartOptions.xAxis : {
          show: false,
          type: 'category',
          data: this.labels?.length && this.labels || (this.chartData || []).map(cd => cd.name),
        },
        legend: chartOptions.legend ? chartOptions.legend : {show: this.isLegend},
        series,
      }
    }
  },
};
</script>
