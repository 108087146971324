<template>
  <div class="gesellschaft-auswahl-component">
    <ComboBox class="gesellschaft-auswahl-component-combo-box-container mr-3" :label="label" :values="values" v-model="internalValue" :disabled="disabled" />

    <BaseButton class="gesellschaft-auswahl-component-button" @click="openAntrag()" :disabled="!hasLink || disabled">Antrag Erstellen</BaseButton>
  </div>
</template>

<script>
import ComboBox from '@/components/core/forms/ComboBox.vue';
import BaseButton from "@/components/core/BaseButton.vue";

// TODO it can be loaded from the backend
const LINK_TO_FORM =  {
  EBASE_AAB: '/beratung/formulare/antrag/EBASE_AAB/servicegebuerf5305',
  BANKZWEIPLUS: '/beratung/formulare/antrag/BANK ZWEIPLUS/servicegebuehr',
  COMDIRECT: '/beratung/formulare/antrag/COMDIRECT/servicegebuehr',
  DAB: '',
  EBASE: '/beratung/formulare/antrag/EBASE/servicegebuerf5305',
  FFB: '/beratung/formulare/antrag/FFB/servicegebuehr',
  FODB: '/beratung/formulare/antrag/FODB/servicegebuehr',
  METZLER: '',
};

export default {
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    antragId: {
      type: String,
      required: true,
    },
    antragName: {
      type: String,
      required: true,
    },
    beratungsMappeId: {
      type: [String, Number]
    },
    values: {
      type: Array,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalValue: '',
    };
  },
  computed: {
    hasLink() {
      return this.internalValue && LINK_TO_FORM[this.internalValue];
    }
  },
  methods: {
    openAntrag() {
      if(this.hasLink) {
        const bachToPreviousFormLink = `/beratung/formulare/antrag/${this.$route.params.lagerstelle}/${this.$route.params.antragsName}`;

        this.$router.push({ 
          path: LINK_TO_FORM[this.internalValue], 
          query: {
            beratungsMappeId: this.beratungsMappeId,
            // On 5.21.2021 Micahel aske me to remove this
            // bachToPreviousFormLink: bachToPreviousFormLink, 
            // backToPreviousFormLabel: this.antragName
          }
        });
      }
    }
  },
  components: {
    ComboBox,
    BaseButton,
  }
}
</script>

<style scoped>
.gesellschaft-auswahl-component {
  display: flex;
  align-items: flex-end;
}

.gesellschaft-auswahl-component-combo-box-container {
  flex-grow: 1;
}

.gesellschaft-auswahl-component-button {
  margin-bottom: 16px;
}
</style>
