<template>
  <div class="fonds_container">
    <div class="banner">FONDSSHOP</div>
    <hr />
    <div class="einzelfonds">
      <div v-if="!isIntern && isMounted">
        <fondsshop-element :zeige="selectedFond" />
      </div>
        <div class="fc-alert fc-alert-warning" v-if="isIntern">Hier kommt der Fondsshopsanteil.</div>
    </div>
    <div class="beachten_wrapper">
      <div class="beachten">
        <p>
          <b>Beachten Sie:</b> Die Angaben über die bisherige Wertentwicklung stellen keine Prognose für die Zukunft dar. Zukünftige Ergebnisse der Investmentanlage sind insbesondere von den
          Entwicklungen der Kapitalmärkte abhängig. Die Kurse an der Börse können steigen und fallen. Investmentfonds unterliegen dem Risiko sinkender Anteilspreise, da sich Kursrückgänge
          der im Fonds enthaltenen Wertpapiere im Anteilspreis widerspiegeln. Bei der Darstellung der Wertentwicklung sind eventuell anfallende Entgelte - z. B. für den Wertapierkauf und -verkauf
          - nicht berücksichtigt. Wie sich Kosten auswirken können erfahren Sie unter Details!
        </p>
        <p>Datenquelle: FWW Fondsdaten (https://www.fww.de/)</p>
        <p>
          Unsere Homepage bietet Ihnen die Möglichkeit, auf Eigeninitiative
          <b>selbständig und kostengünstig</b> Fonds zu erwerben. Unser Fondsshop bietet
          <b>keine Anlageberatung</b> für Sie. Er ist für
          Kunden konzipiert, die als Selbstentscheider eine eigene Investmententscheidung in die Tat umsetzen wollen. Die nachfolgenden Darstellungen enthalten
          <b>keine persönliche Empfehlung</b>
          zu Geschäften mit bestimmten Finanzinstrumenten.
          <b>
            Eine Prüfung der persönlichen Umstände des Anlegers erfolgt nicht, ebenso wenig eine Prüfung, ob die jeweiligen Fonds für den
            Kunden geeignet sind.
          </b>
        </p>
      </div>
    </div>
    <risiko-hinweise />
  </div>
</template>
<script>
import CMS_TYPES from "@/store/cms/types";
import { mapGetters } from "vuex";
import EditableFrame from "@/components/cms/EditableFrame.vue";
import RisikoHinweise from "@/components/cms/parts/RisikoHinweise.vue";
import FondsshopElement from "@/components/cms/parts/fondsshopElement.vue";
import CORE_TYPES from "@/store/core/types";
export default {
  components: {
    EditableFrame,
    RisikoHinweise,
    FondsshopElement
  },
  data() {
    return {
      selectedFond: '',
      isMounted: false,
    }
  },
  computed: {
    ...mapGetters({
      getSelectedFond: CMS_TYPES.GETTERS.GET_SELECTED_FOND,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,

    })
  },
  mounted() {
    this.selectedFond = this.getSelectedFond || this.$route.query.fund;
    if (this.selectedFond?.length == 12 && this.selectedFond.indexOf('ISIN_') == -1) {
      this.selectedFond = 'ISIN_' + this.selectedFond;
    }
    this.isMounted = true;
    window.scrollTo(0, 0);
  },
  beforeDestroy() {
    this.$store.commit(CMS_TYPES.MUTATIONS.SET_SELECTED_FOND, null);
  }
};
</script>
<style  scoped>
.banner {
  font-size: 26px;
  text-align: left;
  color: var(--color-text);
}
.fonds_container {
  margin: 0 auto;
  padding-left: 1em;
  padding-right: 1em;
  max-width: 1200px;
}

.beachten_wrapper {
  background: var(--color-background);
  padding-top: 2em;
  padding-bottom: 1em;
  margin-bottom: 2em;
}
.beachten {
  margin: 0 auto;
  text-align: justify;
  padding-left: 2em;
  padding-right: 2em;
}

.text_only_wrapper {
  padding: 2em;
}
.text_only {
  width: 70%;
  margin: 0 auto;
  text-align: justify;
}

.einzelfonds {
  padding-bottom: 2em;
}
a {
  color: var(--color-link);
}
</style>