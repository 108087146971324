export const MUTATION_PREFIX = 'VERTRAGSSPIEGEL_';
export const ACTIONS_PREFIX = 'VERTRAGSSPIEGEL_ACTIONS_';
export const GETTERS_PREFIX = 'VERTRAGSSPIEGEL_GETTERS_';

export default {
  MUTATIONS: {
    FIELDS_DEFINITION_SUCCESS: MUTATION_PREFIX + 'FIELDS_DEFINITION_SUCCESS',
    GET_PDF_FILE_SUCCESS: MUTATION_PREFIX + 'GET_PDF_FILE_SUCCESS',
  },
  ACTIONS: {
    GET_FIELDS_DEFINITION: ACTIONS_PREFIX + 'GET_FIELDS_DEFINITION',
    GET_PDF_FILE: ACTIONS_PREFIX + 'GET_PDF_FILE',
  },
  GETTERS: {
    FIELDS_DEFINITION: GETTERS_PREFIX + 'FIELDS_DEFINITION',
  }
}
