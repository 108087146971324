<template>
  <div v-if="customerDataEdited">
    <Table
      v-if="rows.length"
      ref="table"
      :tableId="tableId"
      cardView
      :headers="headers"
      :rows="rows"
      :headersSize="isCustomerOnly ? 0 : 5"
      showWeiterLink
      :rowsPerPage="isCustomerOnly ? 0 : 1"
      @onNavigate="navigateToCard"
      hidePagination
    >
    </Table>
    <NoData v-else noIcon />
  </div>
</template>


<script>
import BoxContainer from "@/components/core/BoxContainer.vue";
import { PhPencilLine, PhBookOpen } from "phosphor-vue";
import {
  TextColumn,
  PercentageColumn,
} from "@/components/table2/table_util.js";
import Table from "@/components/table2/Table.vue";
import { mapGetters } from "vuex";
import CUSTOMERDATA_TYPES from "@/store/customerData/types";
import CORE_TYPES from "@/store/core/types";
import persoenlicheDatenMixin from "../persoenliche-daten-mixin";
import stammdatenMixin from "@/components/persoenliche-daten/stammdaten/stammdaten-mixin";
import NoData from '@/components/core/NoData.vue';

const PRECISION = 4;

export default {
  components: {
    BoxContainer,
    PhPencilLine,
    PhBookOpen,
    Table,
    NoData,
  },
  mixins: [persoenlicheDatenMixin, stammdatenMixin],
  data: function () {
    return {
      PRECISION,
      title: "Bankverbindung",
    };
  },
  computed: {
    ...mapGetters({
      customerDataEdited: CUSTOMERDATA_TYPES.GETTERS.GET_CUSTOMER_DATA_EDITED,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      isFK: CORE_TYPES.GETTERS.IS_FK,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      personId: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_ID,
      customerDataConfig: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_CONFIG,
      customerData: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_DATA,
      courtage: CUSTOMERDATA_TYPES.GETTERS.COURTAGE,
      isCustomerOnly: CORE_TYPES.GETTERS.IS_CUSTOMER_ONLY,
    }),
    bankAccounts() {
      return (
        this.customerData?.bankAccounts?.sort(
          (a, b) =>
            (a.bic || "").localeCompare(b.bic || "") &&
            (a.iban || "").localeCompare(b.iban || "")
        ) || []
      );
    },
    headers() {
      const result = {
        center: [],
      };

      result.center.push(TextColumn("zubringer", "Zubringer"));
      result.center.push(
        PercentageColumn(
          "provAbschluss",
          "Abschluss / Einmalige VV-Strategiegebühr"
        )
      );
      result.center.push(PercentageColumn("provBestand", "Bestand"));
      result.center.push(
        PercentageColumn(
          "provServicegebuehr",
          "Servicegebühr / VV-Vermittlungsgebühr"
        )
      );
      result.center.push(PercentageColumn("mwst", "MwSt."));

      return result;
    },
    rows() {
      return (this.courtage || []).map((r) => ({
        ...r,
        zubringer: `${r.zubringerFullName || ""}/${r.zubringernr || ""}`.trim(),
        provAbschluss: (r.provAbschluss || 0).toFixed(PRECISION),
        provBestand: (r.provBestand || 0).toFixed(PRECISION),
        provServicegebuehr: (r.provServicegebuehr || 0).toFixed(PRECISION),
        absolutText: r.absolut === true ? "Ja" : "Nein",
      }));
    },
    tableId() {
      const uniqueUUID = "d91ca95f-3137-470e-9222-eb30a0611163";
      return uniqueUUID;
    },
  },
  methods: {
    navigateToCard(row) {
      this.$router.push(`/persoenlichedaten/customer-data/steps/courtage`);
    },
    async findCourtage() {
      try {
        this.loading = true;
        await this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.FIND_COURTAGE);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.findCourtage();
  },
  validators: {},
};
</script>

<style scoped>
.align-end {
  flex: 1 1 auto;
  align-self: flex-end;
  padding: 0px 0px 16px 5px;
  color: var(--color-text);
}
.form-control {
  display: flex;
  align-content: stretch;
  align-items: center;
}
</style>