<template>
  <div v-if="showEditImage">
    <BaseDropdownMenu ref="dropdown" 
      :closeWithInnerClick="false" 
      :disabled="disabled" 
      @onShow="loadSizes">
      <template #hook-target>
        <BaseToolbarButton 
          title="Edit Image"
          :icon="icon"
          :disabled="disabled"
          :active="true"
          class="mr-0"/>
      </template>
      <template #default>
        <div class="tb-buttons__container d-flex">
          <InputField 
            class="tb-input-field"
            label="Breite, px" 
            @change="updateAspectRatio('width', $event)"
            v-model="width"/>
          <InputField 
            class="tb-input-field"
            label="Höhe, px" 
            @change="updateAspectRatio('height', $event)"
            v-model="height"/>
          <div class="mb-3">
            Das Seitenverhältnis wird beibehalten
          </div>

          <div class="layout__negative-margin--8">
            <BaseButton isSecondary @click="onRestoreDefault();">
              Standard wiederherstellen
            </BaseButton>
            <BaseButton @click="updateImageAttributes();">
              Übernehmen
            </BaseButton>
          </div>
        </div>
      </template>
    </BaseDropdownMenu>
  </div>
</template>

<script>
import { PhImage } from 'phosphor-vue';
import BaseToolbarButton from '../base-components/BaseToolbarButton.vue';
import BaseDropdownMenu from '@/components/core/BaseDropdownMenu.vue'
import InputField from '@/components/core/forms/InputField.vue';
import BaseButton from '@/components/core/BaseButton.vue';

export default {
  components: {
    BaseToolbarButton,
    BaseDropdownMenu,
    InputField,
    BaseButton,
  },

  props: {
    editor: {
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      width: null,
      height: null,
    }
  },

  computed: {
    icon() {
      return PhImage;
    },

    showEditImage() {
      return this.editor?.isActive('image')
    },

    aspectRatio() {
      const naturalWidth = this.editor?.getAttributes('image').naturalWidth;
      const naturalHeight = this.editor?.getAttributes('image').naturalHeight;

      if (!naturalHeight || !naturalWidth) {
        return null;
      }

      return naturalWidth / naturalHeight;
    }
  },

  methods: {
    updateAspectRatio(changedAttribute, value) {
      try {
        const number = parseInt(value);

        if (!number || !this.aspectRatio) {
          return;
        }

        if (changedAttribute === 'height') {
          this.width = parseInt(number * this.aspectRatio);
        } else if (changedAttribute === 'width') {
          this.height = parseInt(number / this.aspectRatio);
        }
      } catch (error) {
        // empty block
      }
    },
    loadSizes() {
      this.width = this.editor?.getAttributes('image').width;
      this.height = this.editor?.getAttributes('image').height;
    },

    updateImageAttributes() {
      this.editor?.chain().focus().updateImageSize({ height: this.height, width: this.width }).run()
    },
    onRestoreDefault() {
      const width = this.editor?.getAttributes('image').naturalWidth;
      const height = this.editor?.getAttributes('image').naturalHeight;
      
      this.editor?.chain().focus().updateImageSize({ height, width }).run()
    },
  },

}
</script>

<style scoped>
.tb-buttons__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tb-input-field {
  width: 150px;
}

.divider {
  width: 100%;
  margin-right: 0;
  margin-left: 0;
}
</style>
