import { render, staticRenderFns } from "./TabRisiko.vue?vue&type=template&id=656d6b59&"
import script from "./TabRisiko.vue?vue&type=script&lang=js&"
export * from "./TabRisiko.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("P:\\workspaces\\fk0\\fc\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('656d6b59')) {
      api.createRecord('656d6b59', component.options)
    } else {
      api.reload('656d6b59', component.options)
    }
    module.hot.accept("./TabRisiko.vue?vue&type=template&id=656d6b59&", function () {
      api.rerender('656d6b59', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/fondsinfo/TabRisiko.vue"
export default component.exports