<template>
    <div>
        <PageHeaderTitleNavigation
            :title="$appNavigation.currentMenu.parent.label"
            :subtitle="$appNavigation.currentMenu.label"
            :defaultMenu="$appNavigation.currentTabMenu"
            :actions="actions"
            @action-REMOVE_ALL="confirmRemoveAll()"
            @action-RESET="doConfirm('zurücksetzen')"
        />

        <div class="box__container">
            <div  class="box__title">Limits {{title}}</div>
            <div>
                Bei ausgefülltem Anlegerprofil wird das {{title}}-limit automatisch innerhalb ihrer Grenzen überwacht. 
				Hier haben Sie einen Überblick über alle gesetzten Limits auf die {{title}} und können diese 
				noch weiter einengen oder erweitern.
            </div>
            <div class="layout__negative-margin--8 antrag-action-button__container">
                <InputToggleSwitch v-model="includeStruktur" label="Limits der Untermakler anzeigen" inLineLabel suppressValidationMessage v-if="!isCustomerView" @input="reload()"/>
            </div>
        </div>
        <div class="box__container">
            <div v-if="!loading && rows && rows.length">
                <Table
                    tableId="9a41e68c-9568-484a-beb7-163a77861d75"
                    :title="TABLE_ID"
                    :headers="headers"
                    :rows="rows"
                    :rowsPerPage="20"
                    rowId="id"
                    hidePagination
                    :mobileConfig="{title: 'kundenLinkName', headers: ['mittelwert', 'abweichungLow', 'abweichungHigh']}"
                    @action-edit="editLimit"
                    @action-delete="removeLimit"
                    @click-kundenLinkName="openCustomerNewTab"
                >
                </Table>
            </div>
            <GhostLoading v-else-if="loading" type="table" :title="TABLE_ID" />
            <NoData v-else :title="TABLE_ID" />
        </div>
        <BaseModal ref="confirmDelete" 
            labelButtonConfirm="Ja" 
            labelButtonCancel="Nein" 
            @onConfirmButton="removeAllLimits(true)"
            @onCancelButton="removeAllLimits(false)">
            <template v-slot:modalTitle>
                <ph-warning :size="16" class="mr-2" style="color: orangered"/>
                Löschen
            </template>
            <div>
                Wollen Sie wirklich alle überschrittenen Limits löschen?
            </div>
        </BaseModal>
    </div>
</template>

<script>
import mixin from '@/mixins/limit/limit-mixin.js';
import LIMIT_TYPES from '@/store/limit/types';
import { mapGetters } from 'vuex';
import Table from "@/components/table2/Table.vue";
import {TextColumn, ActionColumn, PercentageColumn, SimpleAction, DateColumn, NumberColumn, IconColumn, Icon } from "@/components/table2/table_util.js";
import BaseButton from '@/components/core/BaseButton.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import { PhCheck, PhWarning } from "phosphor-vue";
import BaseModal from '@/components/core/BaseModal.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';

const TABLE_ID = 'Risikoklassenlimits';

export default {
    mixins: [mixin],
    components: {
        BaseButton,
        Table,
        InputToggleSwitch,
        BaseModal,
        PhWarning,
        PageHeaderTitleNavigation,
        GhostLoading,
        NoData,
    },
    data() {
        return {
            TABLE_ID,
            loading: false,
            includeStruktur: '0',
        }
    },
    mounted() {
        if (this.$route.query.includeStruktur === '1') {
            this.includeStruktur = '1';
        }
        this.reload();
    },
    computed: {
        ...mapGetters({
            limitObj: LIMIT_TYPES.GETTERS.RISIKOKLASSEN_LIMIT_LIST,
        }),
        title() {
            return this.limitObj?.title || 'SRI'
        },
        limits() {
            return this.limitObj?.limits || [];
        },
        headers() { 
            const headers = { 
                lockedLeft: [
                    IconColumn("status", "Status", 80).makeAlwaysVisible().makeSortable(cell => cell?.length && cell[0].title),
                    TextColumn("kundennr", "Kundennr").makeAlwaysVisible(),
                    TextColumn("kundenLinkName", "Kunde").makeLink(),
                ],
                center: [
                    NumberColumn("confirmedValue", `Bestätigte ${this.title}`, 1),
                    NumberColumn("mittelwert", `Durchschnittliche ${this.title} des Depots`, 2),
                    NumberColumn("ausgangswert", `Ausgangswert (${this.title})`, 1),
                    PercentageColumn("abweichungLow", "Untere Abweichung", 1),
                    PercentageColumn("abweichungHigh", "Obere Abweichung", 1),
                    DateColumn("dateUpdatetd", "Letzte Änderung"),
                    DateColumn("dateOutOfLimit", "Benachrichtigung"),
                ],
                lockedRight: [
                    ActionColumn("actions", ""),
                ],
            };
            if ('1' === this.includeStruktur) {
                // insert after status
                headers.lockedLeft.splice(1, 0, TextColumn("maklernr", "Maklernr").makeAlwaysVisible());
            }
            return headers;
        },
        rows() {
            const result = !this.limits ? [] : this.limits.map((limit, index) => {
                const actions = [SimpleAction("edit", 'PhPencil', "Limit bearbeiten"), SimpleAction("delete", 'PhTrash', "Limit löschen")];
                return {
                    ...limit,
                    status: [limit.statusStr === 'ok'
                        ? Icon(PhCheck, "Ok", 20, 'bold', 'color-success')
                        : Icon(PhWarning, "Warnung", 20, 'bold', 'color-danger')
                    ],
                    id: index,
                    actions: actions,
                };
            });
            return result;
        },
        actions() {
            const actions = []
            if (!this.isCustomerView) {
                actions.push(PageHeaderSimpleAction('REMOVE_ALL', 'Überschrittene Limits löschen'))
                actions.push(PageHeaderSimpleAction('RESET', 'Überschrittene Limits zurücksetzen').withDisabled(() => !this.rows?.length ))
            }
            return actions
        }
    },
    methods: {
        async reload() {
            try {
                this.loading = true;
                await this.$store.dispatch(LIMIT_TYPES.ACTIONS.GET_RISIKOKLASSEN_LIMIT_LIST, { includeStruktur: this.includeStruktur });
            } finally {
                this.loading = false;
            }
        },
        removeLimit(event) {
             if (event?.kundennr) {
                this.$store.dispatch(LIMIT_TYPES.ACTIONS.DELETE_RISIKOKLASSEN_LIMIT_FROM_LIST, { kundennr: event.kundennr });
             }
        },
        editLimit(event) {
            if (event?.kundennr) {
                this.$addBreadcrumb({
                    label: `zurück zu den Risikoklassenlimits`,
                    fullPath: this.$route.fullPath,
                    breadcrumb: 'Risikoklassenlimit',
                });
                this.$router.push(`${this.mainPath}/risikoklassenlimit?kundennr=${event.kundennr}`)
            }
        },
        confirmRemoveAll() {
            this.$refs.confirmDelete.open();
        },
        removeAllLimits(confirmed) {
            if (confirmed) {
                this.$store.dispatch(LIMIT_TYPES.ACTIONS.DELETE_ALL_OVERTAKEN_RISIKOKLASSEN_LIMITS, { includeStruktur: this.includeStruktur });
            }
            this.$refs.confirmDelete.close();
        },
        async doConfirm(exec) {
            await this.doConfirmAction(exec, 'risikoklassenLimitList');
        },
    },

}
</script>

<style>

</style>