var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fodb-auswahl-component" },
    [
      _c("ComboBox", {
        staticClass: "fodb-auswahl-component-combo-box-container mr-3",
        attrs: { label: _vm.label, values: _vm.comboboxValues },
        on: { input: _vm.onInputComboBox },
        model: {
          value: _vm.INPUT_SERVICEGEBUEHR_FONDS,
          callback: function($$v) {
            _vm.INPUT_SERVICEGEBUEHR_FONDS = $$v
          },
          expression: "INPUT_SERVICEGEBUEHR_FONDS"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }