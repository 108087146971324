var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "antrag-components__container" }, [
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("WertpapierauswahlDepotUebertragung", {
          attrs: {
            positions: _vm.positionData,
            anzahlFonds: _vm.anzahlFonds,
            titleAnteile:
              _vm.antragName === "uebertragungsauftrag"
                ? "Stückzahl/Nominal"
                : "Stückzahl"
          },
          on: {
            save: function($event) {
              return _vm.$emit("save", $event)
            },
            fondsfinder: function($event) {
              return _vm.$emit("fondsfinder", $event)
            }
          }
        }),
        _c(
          "BaseButton",
          {
            staticClass: "col-12",
            on: {
              click: function($event) {
                return _vm.$emit("back")
              }
            }
          },
          [_vm._v("Zurück")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }