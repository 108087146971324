import NAVIGATION_LOG_TYPES from './types';
import { getInitialState, } from './index';
import Vue from 'vue';

export default {
  [NAVIGATION_LOG_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState());
  },
  [NAVIGATION_LOG_TYPES.MUTATIONS.ADD_ROUTE_CHANGE_TO_QUEUE](state, payload) {
    if (!payload) return;

    if (Array.isArray(payload)) {
      state.routeChangeQueue.push.apply(state.routeChangeQueue, payload);
    } else {
      state.routeChangeQueue.push(payload);
    }
  },
  [NAVIGATION_LOG_TYPES.MUTATIONS.TAKE_NAVIGATION_LOG_FROM_QUEUE](state, callback) {
    if (state.routeChangeQueue?.length) {
      const queue = state.routeChangeQueue.splice(0, state.routeChangeQueue.length);
      callback(queue);
    }
  },
  [NAVIGATION_LOG_TYPES.MUTATIONS.SAVE_SCHEDULED_SEND_ROUTE_CHANGE](state, nextScheduledSendRouteChange) {
    Vue.set(state, 'nextScheduledSendRouteChange', nextScheduledSendRouteChange);
  },
}
