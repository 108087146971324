export const MUTATION_PREFIX = 'EINGEBUNDENE_WERTPAPIERE_MUTATIONS_';
export const ACTIONS_PREFIX = 'EINGEBUNDENE_WERTPAPIERE_ACTIONS_';
export const GETTERS_PREFIX = 'EINGEBUNDENE_WERTPAPIERE_GETTERS_';

export default {
  MUTATIONS: {
    SAVE_EINGEBUNDENE_WERTPAPIERE_SUCCESS: MUTATION_PREFIX + 'SAVE_EINGEBUNDENE_WERTPAPIERE_SUCCESS',
    EINGEBUNDENE_WERTPAPIERE_EDIT: MUTATION_PREFIX + 'EINGEBUNDENE_WERTPAPIERE_EDIT',
    CLEAN_EINGEBUNDENE_WERTPAPIERE_EDIT: MUTATION_PREFIX + 'CLEAN_EINGEBUNDENE_WERTPAPIERE_EDIT',
    DELETE_VERLAUF_ANTEILE_SUCCESS: MUTATION_PREFIX + 'DELETE_VERLAUF_ANTEILE_SUCCESS',
  },
  ACTIONS: {
    VALIDATE_FOND_ID: ACTIONS_PREFIX + 'VALIDATE_FOND_ID',
    SAVE_EINGEBUNDENE_WERTPAPIERE: ACTIONS_PREFIX + 'SAVE_EINGEBUNDENE_WERTPAPIERE',
    DELETE_VERLAUF_ANTEILE: ACTIONS_PREFIX + 'DELETE_VERLAUF_ANTEILE',
  },
  GETTERS: {
    EINGEBUNDENE_WERTPAPIERE_EDIT: GETTERS_PREFIX + 'EINGEBUNDENE_WERTPAPIERE_EDIT',
  }
}
