<template>
  <div class="box__container">
    <div class="box__title">Risikostufe {{ index }}</div>

    <RisikostufeFields title="Vorgabe" :data="defaultData" disabled />

    <template v-if="isChangeAllowed">
      <hr/>

      <RisikostufeFields title="Eigene Einstellung (wird für Unterstruktur verwendet)" :data="configData" 
        :disabled="!hasConfig" @dataChanged="risikostufeDataChanged($event)" />
    </template>
  </div>
</template>

<script>
import RisikostufeFields from './../components/RisikostufeFields.vue';

import risikoklassenDefinitionStepMixin from './../risikoklassen-definition-step-mixin';

export default {
  mixins: [risikoklassenDefinitionStepMixin],
  props: {
    index: {
      type: Number,
      default: null,
    },
  },
  computed: {
    defaultData() {
      const { index, } = this;
      return this.risikoklassenDefinitionData?.vorgabe?.[`risiko${index}`] || {};
    },
    configData() {
      const { index, } = this;
      return this.risikoklassenDefinitionData?.einstellung?.[`risiko${index}`] || {};
    },
  },
  methods: {
    risikostufeDataChanged(form) {
      this.setDataChanged({ 
        einstellung: {
          [`risiko${this.index}`]: form,
        }, 
      })
    },
  },
  components: {
    RisikostufeFields,
  },
}
</script>
