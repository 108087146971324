<template>
  <div>
    <slot :name="`action-${actionKey}`" />
  </div>
</template>
<script>
import actionMixin from './header-actions-mixin.js';

export default {
  mixins: [actionMixin],
}
</script>
