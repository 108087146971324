var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.allowConfigGlobal ||
    _vm.hasRoles([
      _vm.VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS,
      _vm.ROLES.IS_KUNDENZUGANG
    ])
    ? _c(
        "ComboBox",
        _vm._g(
          _vm._b(
            {
              attrs: { values: _vm.userLevelValues },
              model: {
                value: _vm.internalValue,
                callback: function($$v) {
                  _vm.internalValue = $$v
                },
                expression: "internalValue"
              }
            },
            "ComboBox",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }