<template>
  <div>
    <PageHeaderTitleNavigation
      :title="$appNavigation.currentMenu.parent.label"
      :subtitle="$appNavigation.currentMenu.label"
    />

    <!-- TODO: implement filter (the original module in MSC has no filter) -->

    <div class="box__container" tid="e81400d2-282f-43b7-b21b-e26117dcba07">
      <Table
        tableId="47bbba68-fc47-439f-9670-ebda06b3a2fa"
        :title="TABLE_TITLE"
        class="table-container"
        v-if="!loading && tableRows.length"
        :headers="tableHeaders"
        :rows="tableRows"
        :rowsPerPage="20"
        :count="resultCount"
      >

      </Table>
      <GhostLoading v-else-if="loading" type="table" :title="TABLE_TITLE" />
      <NoData v-else :title="TABLE_TITLE" />
    </div>    

  </div>
    
</template>

<script>
import CORE_TYPES from "@/store/core/types";
import DATENSCHUTZKUNDENAENDERUNGEN_TYPES from "@/store/datenschutzkundenaenderungen/types";
import { mapGetters } from "vuex";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, DateTimeColumn} from "@/components/table2/table_util.js";
import { VIEW_ROLES } from '@/router/roles';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'

const TABLE_TITLE = 'Details Datenschutz- / Kommunikationsänderungen';

export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    Table,
    GhostLoading,
    NoData,
    OptionMenu,
    PageHeaderTitleNavigation,
  },
  data: function () {
      return {
        TABLE_TITLE,
        resultCount: null,
        unsignedAttachments: [],
        signedAttachments: [],
        currentFilename: null,
        downloadErrorMessage: null,
        filtered: false,
        loading: true,
      }
  },
  computed: {
    ...mapGetters({
      detailsListing: DATENSCHUTZKUNDENAENDERUNGEN_TYPES.GETTERS.DETAILS_LISTING,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      isFA: CORE_TYPES.GETTERS.IS_FA,
    }),
    tableHeaders() {
      const headers = {
        lockedLeft: [
          TextColumn("id", "Id").makeHidden(),
          DateTimeColumn("dateCreated", "Stand"),
          TextColumn("user", "Benutzer"),
        ],
        center: [
        TextColumn("kontext", "Kontext / Ort"),
        TextColumn("art", "Art der Änderung"),
        TextColumn("text", "Beschreibung"),
        ],   
      };

      return headers;
     
    },
    tableRows () {
      return this.detailsListing || [];
    },
  },
  mounted() {
    this.retrieveData();  
  },
  methods: {
    retrieveData() {
      this.loading = true;

      const kslId = this.$route.params.id;

      this.$store.dispatch(
        DATENSCHUTZKUNDENAENDERUNGEN_TYPES.ACTIONS.GET_DETAILS_LISTING, {kslId}).then(() => {
                this.loading = false;
            });  

    },
    filterForm(filterConfig) {
      const mitZweigstellen = filterConfig.find(fc => fc.key=="mitZweigstellen");
      const vermittlernummer = filterConfig.find(fc => fc.key=="vermittlernummer");
      const gesellschaften = filterConfig.find(fc => fc.group=="gesellschaften");

      this.retrieveData(mitZweigstellen?.value, vermittlernummer?.value, gesellschaften?.key) ;
    },

  },
  async beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu Details Datenschutz- / Kommunikationsänderungen', 
      fullPath: from.fullPath,
      breadcrumb: to?.meta?.breadcrumb?.label,
    });

    // this.$store.commit(DATENSCHUTZKUNDENAENDERUNGEN_TYPES.MUTATIONS.RESET_STATE);

    next();
  },


    
}
</script>