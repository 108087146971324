import { render, staticRenderFns } from "./beratungsForm.vue?vue&type=template&id=50ac3316&scoped=true&"
import script from "./beratungsForm.vue?vue&type=script&lang=js&"
export * from "./beratungsForm.vue?vue&type=script&lang=js&"
import style0 from "./beratungsForm.vue?vue&type=style&index=0&id=50ac3316&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50ac3316",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("P:\\workspaces\\fk0\\fc\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('50ac3316')) {
      api.createRecord('50ac3316', component.options)
    } else {
      api.reload('50ac3316', component.options)
    }
    module.hot.accept("./beratungsForm.vue?vue&type=template&id=50ac3316&scoped=true&", function () {
      api.rerender('50ac3316', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/cms/parts/beratungsForm.vue"
export default component.exports