var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-container",
      class: { "form-container__stepper-collapsed": _vm.stepperCollapsed }
    },
    [
      _vm.$slots.stepper
        ? _c(
            "div",
            { staticClass: "form-container__stepper" },
            [_vm._t("stepper")],
            2
          )
        : _vm._e(),
      _vm.$slots.content
        ? _c(
            "div",
            {
              staticClass: "form-container__content",
              class: { "form-container__content--full": !_vm.$slots.stepper },
              on: {
                transitionstart: function($event) {
                  if ($event.target !== $event.currentTarget) {
                    return null
                  }
                  return _vm.handleTransitionStart($event)
                },
                transitionend: function($event) {
                  if ($event.target !== $event.currentTarget) {
                    return null
                  }
                  return _vm.handleTransitionEnd($event)
                }
              }
            },
            [_vm._t("content")],
            2
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }