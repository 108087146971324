<template>
  <div class="modellportfolios_container">
    <div class="banner">
      <editable-frame type="modellportfolios_title_page" />
    </div>
    <hr />
    <div class="text_only_wrapper">
      <div class="text_only">
        <editable-frame type="modellportfolios_intro_text" />
      </div>
    </div>
    <div class="banner">MODELLPORTFOLIOS</div>
    <hr />
    <div class="dreispalten_wrapper">
        <div class="fc-alert fc-alert-warning" v-if="isIntern">Hier kommen die vom Makler bestimmten Modellportfolios.</div>
      <div class="dreispalten">
        <div v-for="(portfolio,index) in getPortfolios" :key="index">
          <div class="spalte1">
            <div class="drei_spalten_image">
              <EditableImagesSelector :type="'image_'+portfolio.fremdId" genus="fondsshopPortfolio" />
            </div>
            <div class="drei_spalten_banner">
              <h3>
                Modellportfolio
                <br />
                {{portfolio.anzeigeName}}
              </h3>

              <hr />
            </div>
            <div class="drei_spalten_text">
              <editable-frame :type="'text_'+portfolio.fremdId" genus="fondsshopPortfolio" />
            </div>
            <div>
              <base-button @click="gotoPortfolio(portfolio.fremdId)">Jetzt Ansehen</base-button>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </div>

    <div class="banner">Modellportfolios im Detail</div>
    <hr />
    <div class="dreispalten_wrapper">
      <div class="dreispalten">
        <div class="spalte1">
          <div class="drei_spalten_image">
            <EditableImagesSelector type="modell_3sp2_image1" />
          </div>
          <div class="drei_spalten_banner">
            <editable-frame type="modell_3sp2_title1" />
            <hr />
          </div>
          <div class="drei_spalten_text">
            <editable-frame type="modell_3sp2_text1" />
          </div>
        </div>
        <div class="spalte2">
          <div class="drei_spalten_image">
            <EditableImagesSelector type="modell_3sp2_image2" />
          </div>
          <div class="drei_spalten_banner">
            <editable-frame type="modell_3sp2_title2" />
            <hr />
          </div>
          <div class="drei_spalten_text">
            <editable-frame type="modell_3sp2_text2" />
          </div>
        </div>
        <div class="spalte3">
          <div class="drei_spalten_image">
            <EditableImagesSelector type="modell_3sp2_image3" />
          </div>
          <div class="drei_spalten_banner">
            <editable-frame type="modell_3sp2_title3" />
            <hr />
          </div>
          <div class="drei_spalten_text">
            <editable-frame type="modell_3sp2_text3" />
          </div>
        </div>
      </div>
    </div>
    <div class="beachten_wrapper">
      <div class="beachten">
        <p>
          <b>Beachten Sie:</b> Die Angaben über die bisherige Wertentwicklung stellen keine Prognose für die Zukunft dar. Zukünftige Ergebnisse der Investmentanlage sind insbesondere von den
          Entwicklungen der Kapitalmärkte abhängig. Die Kurse an der Börse können steigen und fallen. Investmentfonds unterliegen dem Risiko sinkender Anteilspreise, da sich Kursrückgänge
          der im Fonds enthaltenen Wertpapiere im Anteilspreis widerspiegeln. Bei der Darstellung der Wertentwicklung sind eventuell anfallende Entgelte - z. B. für den Wertpapierkauf und -verkauf
          - nicht berücksichtigt. Wie sich Kosten auswirken können erfahren Sie unter Details!
        </p>
        <p>Datenquelle: FWW Fondsdaten (https://www.fww.de/)</p>
        <p>
          Unsere Homepage bietet Ihnen die Möglichkeit, auf Eigeninitiative
          <b>selbständig und kostengünstig</b> Fonds zu erwerben. Unser Fondsshop bietet
          <b>keine Anlageberatung</b> für Sie. Er ist für
          Kunden konzipiert, die als Selbstentscheider eine eigene Investmententscheidung in die Tat umsetzen wollen. Die nachfolgenden Darstellungen enthalten
          <b>keine persönliche Empfehlung</b>
          zu Geschäften mit bestimmten Finanzinstrumenten.
          <b>
            Eine Prüfung der persönlichen Umstände des Anlegers erfolgt nicht, ebenso wenig eine Prüfung, ob die jeweiligen Fonds für den
            Kunden geeignet sind.
          </b>
        </p>
      </div>
    </div>
    <risiko-hinweise />
  </div>
</template>
<script>
import EditableFrame from "@/components/cms/EditableFrame.vue";
import EditableImagesSelector from "@/components/cms/EditableImagesSelector.vue";
import RisikoHinweise from "@/components/cms/parts/RisikoHinweise.vue";
import AddDeleteWrapper from "@/components/cms/AddDeleteWrapper.vue";
import CMS_TYPES from "@/store/cms/types";
import CORE_TYPES from "@/store/core/types";
import { mapGetters, dispatch, commit } from "vuex";
import BaseButton from "@/components/core/BaseButton.vue";

export default {
  components: {
    EditableFrame,
    EditableImagesSelector,
    RisikoHinweise,
    AddDeleteWrapper,
    BaseButton
  },
  computed: {
    ...mapGetters({
      getPortfolios: CMS_TYPES.GETTERS.GET_PORTFOLIOS,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
    })
  },
  methods: {
    getFondsshopForPortfolio(genus, species) {
        this.$store.commit(CMS_TYPES.MUTATIONS.SET_SELECTED_FOND, {
          genus: genus,
          species: species
        });
        this.$router.push("fonds");
    },
    gotoPortfolio(fremdId) {
      this.$store.commit(CMS_TYPES.MUTATIONS.SET_SELECTED_FOND, fremdId);
      this.$router.push("fonds");
    }
  },
  beforeMount() {
      if (!this.isIntern){
        this.$store.dispatch(CMS_TYPES.ACTIONS.RETRIEVE_PORTFOLIOS);
      }
  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>
<style  scoped>
.banner {
  font-size: 26px;
  text-align: left;
  color: var(--color-text);
}
.modellportfolios_container {
  margin: 0 auto;
  padding-left: 1em;
  padding-right: 1em;
  max-width: 1200px;
}

.beachten_wrapper {
  background: var(--color-background);
  padding-top: 2em;
  padding-bottom: 1em;
  margin-bottom: 2em;
}
.beachten {
  margin: 0 auto;
  text-align: justify;
  padding-left: 2em;
  padding-right: 2em;
}

.text_only_wrapper {
  padding-bottom: 2em;
  padding-top: 2em;
}
.text_only {
  margin: 0 auto;
  text-align: justify;
}

.dreispalten_wrapper {
  padding-bottom: 2em;
  padding-top: 2em;
}

.dreispalten {
  margin: 0 auto;
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
  column-gap: 4em;
  row-gap: 4em;
  grid-template-areas: " spalte1 spalte2 spalte3 ";
  grid-area: dreispalten;
}

.drei_spalten_banner {
  font-size: 1.5em;
  margin-top: 0.75em;
}

.drei_spalten_text {
  text-align: justify;
}

.spalte1 {
  grid-area: spalte1;
  /*   align-self: center;
 */
}
.spalte2 {
  grid-area: spalte2;
}
.spalte3 {
  grid-area: spalte3;
}
a {
  color: var(--color-link);
}
@media only screen and (max-width: 1024px) {
  .dreispalten {
    grid-template-columns: 1fr;
    grid-template-areas:
      "spalte1"
      "spalte2"
      "spalte3";
    gap: 5em;
  }
}
</style>