var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("SignoViewerLink", {
    attrs: {
      asButton: "",
      disabled: _vm.isDisabled,
      isSecondary: !_vm.isMainAction,
      title: _vm.responsiveLabel,
      href: _vm.href,
      requestURL: _vm.requestURL,
      extraParams: _vm.extraParams,
      isDeepLink: _vm.isDeepLink
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }