var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isSmallScreen
        ? _c("div", { staticClass: "drop_down__area" }, [
            _vm.title
              ? _c("div", { staticClass: "title__area box__title" }, [
                  _vm._v(" " + _vm._s(_vm.title) + " ")
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "actions__area" },
              [
                _vm.cameraAvailable && _vm.hasPhotoButton
                  ? _c(
                      "FotoButton",
                      {
                        staticClass: "image-upload__button",
                        attrs: {
                          buttonClass: "foto-button",
                          isLink: "",
                          isSecondary: "",
                          disabled: _vm.disabled,
                          edgeCorrection: _vm.edgeCorrection,
                          camera: _vm.photoCamera
                        },
                        on: {
                          file: function($event) {
                            return _vm.upload([$event])
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.photoButtonText) + " ")]
                    )
                  : _vm._e(),
                _c(
                  "BaseContextMenu",
                  [
                    [
                      _c(
                        "div",
                        { staticClass: "drop_down__buttons" },
                        [
                          _vm.hasUploadButton
                            ? _c(
                                "BaseFileSelect",
                                {
                                  staticClass: "image-upload__button",
                                  attrs: {
                                    isClear: "",
                                    accept: "image/png, image/jpeg, .pdf",
                                    disabled: _vm.disabled
                                  },
                                  on: {
                                    files: function($event) {
                                      return _vm.upload($event)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.uploadButtonText) + " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.hasImage && _vm.hasEditTool
                            ? _c(
                                "BaseButton",
                                {
                                  attrs: {
                                    isClear: "",
                                    disabled: _vm.disabled || _vm.loading
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.openCroppingDialog()
                                    }
                                  }
                                },
                                [_vm._v(" Bearbeiten ")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    _vm._l(_vm.actions, function(action) {
                      return _c(
                        "ContextMenuItem",
                        { key: action.key },
                        [
                          action.component
                            ? _c(
                                action.component,
                                _vm._b(
                                  {
                                    tag: "component",
                                    attrs: {
                                      tid: _vm._generateTidFromString(
                                        action.key
                                      )
                                    },
                                    on: { action: _vm.handleComponentAction }
                                  },
                                  "component",
                                  action,
                                  false
                                ),
                                [
                                  _c(
                                    "template",
                                    { slot: "action-" + action.actionKey },
                                    [_vm._t("action-" + action.actionKey)],
                                    2
                                  )
                                ],
                                2
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _c(
        "DragnDropArea",
        {
          staticClass: "image-upload__container",
          class: { "has-image": _vm.hasImage, "no-image": !_vm.hasImage },
          attrs: {
            hoverText: _vm.hoverText,
            disabled: _vm.disabled,
            tid: _vm._generateTidFromString(_vm.title ? _vm.title : "")
          },
          on: {
            files: function($event) {
              return _vm.upload($event)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "image-upload__img-container mb-3" },
            [
              _vm.title && !_vm.isSmallScreen
                ? _c(
                    "div",
                    { staticClass: "box__title" },
                    [
                      _vm._v(" " + _vm._s(_vm.title) + " "),
                      _vm.loading ? _c("AnimatedSpinner") : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.image
                ? _c("img", {
                    ref: "image",
                    staticClass: "image-upload__img",
                    class: ["image-" + _vm.imageFit],
                    style: _vm.imageStyle,
                    attrs: { src: _vm.image, crossorigin: "anonymous" }
                  })
                : _vm._e(),
              _vm.editImage
                ? _c("ImageCroppingDialog", {
                    attrs: {
                      src: _vm.editImage,
                      detectedCorners: [],
                      simpleDialog: true
                    },
                    on: {
                      cancel: function($event) {
                        _vm.editImage = null
                      },
                      file: function($event) {
                        return _vm.onChange($event)
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          !_vm.isSmallScreen
            ? _c(
                "div",
                [
                  _vm.hasImage
                    ? _c(
                        "BaseButtonsContainer",
                        { staticClass: "mt-3" },
                        [
                          _vm.hasUploadButton
                            ? _c(
                                "BaseFileSelect",
                                {
                                  staticClass: "image-upload__button",
                                  attrs: {
                                    isPrimary: "",
                                    accept: "image/png, image/jpeg, .pdf",
                                    disabled: _vm.disabled
                                  },
                                  on: {
                                    files: function($event) {
                                      return _vm.upload($event)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.uploadButtonText) + " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.cameraAvailable && _vm.hasPhotoButton
                            ? _c(
                                "FotoButton",
                                {
                                  staticClass: "image-upload__button",
                                  attrs: {
                                    isSecondary: "",
                                    disabled: _vm.disabled,
                                    edgeCorrection: _vm.edgeCorrection,
                                    camera: _vm.photoCamera
                                  },
                                  on: {
                                    file: function($event) {
                                      return _vm.upload([$event])
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.photoButtonText) + " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.hasImage && _vm.hasEditTool
                            ? _c(
                                "BaseButton",
                                {
                                  attrs: {
                                    isSecondary: "",
                                    disabled: _vm.disabled || _vm.loading
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.openCroppingDialog()
                                    }
                                  }
                                },
                                [_vm._v(" Bearbeiten ")]
                              )
                            : _vm._e(),
                          _vm.hasImage && _vm.hasDeleteButton
                            ? _c(
                                "BaseButton",
                                {
                                  attrs: {
                                    isSecondary: "",
                                    disabled: _vm.disabled || _vm.loading
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.$emit("delete")
                                    }
                                  }
                                },
                                [_vm._v(" Löschen ")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          staticClass:
                            "buttons_container image-upload__img image-upload__button--img",
                          style: _vm.imageStyle
                        },
                        [
                          _vm.hasUploadButton
                            ? _c(
                                "BaseFileSelect",
                                {
                                  staticClass: "image-upload__button",
                                  attrs: {
                                    isPrimary: "",
                                    accept: "image/png, image/jpeg, .pdf",
                                    disabled: _vm.disabled
                                  },
                                  on: {
                                    files: function($event) {
                                      return _vm.upload($event)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.uploadButtonText) + " "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.cameraAvailable && _vm.hasPhotoButton
                            ? _c(
                                "FotoButton",
                                {
                                  staticClass: "image-upload__button",
                                  attrs: {
                                    buttonClass: "foto-button",
                                    isSecondary: "",
                                    disabled: _vm.disabled,
                                    edgeCorrection: _vm.edgeCorrection,
                                    camera: _vm.photoCamera
                                  },
                                  on: {
                                    file: function($event) {
                                      return _vm.upload([$event])
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.photoButtonText) + " "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                ],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }