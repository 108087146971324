<template>
<div>
    <Table
        tableId="ccdb782e-dc29-40ec-973f-eb5ee6bd4f4f"
        :headers="headers"
        :rows="rows"
        rowId="name"
        hidePagination
    >
        <template #value="row">
            <KennzahlenValue :value="row.value" :type="row.type" />
        </template>
    </Table>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import KENNZAHLEN_TYPES from '@/store/kennzahlen/types';

import Table from "@/components/table2/Table.vue";
import {TextColumn, SlotColumn} from "@/components/table2/table_util.js";
import KennzahlenValue from './KennzahlenValue.vue';


export default {
    components: {
        Table,
        KennzahlenValue,
    },
    computed: {
        ...mapGetters({
            kennzahlenData: KENNZAHLEN_TYPES.GETTERS.KENNZAHLEN_DATA,
        }),
        headers() {
            return {
                center: [
                    TextColumn("name", "Name"),
                    SlotColumn("value", `Wert (${this.rangeText})`).alignRight(),
                ],
            }
        },
        rows() {
            return this.kennzahlenData?.dataScnd || []
        },
        rangeText() {
            const { datumBeginnVerlauf, datumEndeVerlauf } = this.kennzahlenData || {};

            const rangeText = datumBeginnVerlauf && datumEndeVerlauf ? `${datumBeginnVerlauf} bis ${datumEndeVerlauf}` : 'konnte nicht ermittelt werden!';
            return `Zeitraum ${rangeText}`;
        },
    },
}
</script>
