<template>
  <div class="private-dok-node-tree__container">
    <div>
      <div class="add-new-node-tree--header">
        <span class="add-new-node-tree--label">Name</span>
        <span class="add-new-node-tree--visibility">Sichtbar</span>
        <span class="add-new-node-tree--actions">Aktionen</span>
      </div>

      <Tree 
        v-if="nodeId"
        ref="nodeTree"
        :items="nodes" childrenKey="nodes" idKey="nodeId"
        :showArrowRight="false"
        uniqueId="a43515bc-82eb-11ee-b962-0242ac120002"
        class="add-new-node-tree__container"
      >
        <template #default="{item}">
          <div class="add-new-node-tree--content">
            <span class="add-new-node-tree--label">{{ item.label }}</span>
            <span v-if="item.subfolderMetadata" class="add-new-node-tree--visibility">
              <PhEye v-if="item.subfolderMetadata.showForKunde" :size="16" weight="bold" />
              <PhEyeSlash v-else class="color-danger" :size="16" weight="bold" />
            </span>
            <span class="add-new-node-tree--actions" data-tree-ignore-click>
              <BaseDropdownMenu v-if="item.subfolderAllowed" placement="bottom-right">
                <template #hook-target>
                  <button type="button" class="btn-clear clickable"><PhDotsThreeVertical :size="24" /></button>
                </template>
                <template #default>
                  <ul v-if="item.subfolderMetadata" class="action-texts list m-0">
                    <li v-if="item.subfolderMetadata.canEdit">
                      <button 
                        type="button" class="btn-clear clickable" 
                        @click="toggleNodeShowForKunde(item)"
                      >
                        <template v-if="item.subfolderMetadata.showForKunde">
                          <PhEyeSlash :size="24" /> <span>Ordner für Kunde verbergen</span>
                        </template>
                        <template v-else>
                          <PhEye :size="24" /> <span>Ordner für Kunde freigeben</span>
                        </template>
                      </button>
                    </li>
                    <li v-if="item.subfolderMetadata.canAdd">
                      <button 
                        type="button" class="btn-clear clickable" 
                        @click="addNewNode(item)">
                        <PhPlus :size="24" /> <span>Neuer Ordner anlegen</span>
                      </button>
                    </li>
                    <li v-if="item.subfolderMetadata.canEdit">
                      <button 
                        type="button" class="btn-clear clickable" 
                        @click="editNode(item)">
                        <PhPencilLine :size="24" /> <span>Ordner ändern</span>
                      </button>
                    </li>
                    <li v-if="item.subfolderMetadata.canRemove">
                      <button 
                        type="button" class="btn-clear clickable" 
                        @click="openRemoveNodeConfirm(item)">
                        <PhTrash :size="24" /> <span>Ordner löschen</span>
                      </button>
                    </li>
                  </ul>
                </template>
              </BaseDropdownMenu>
            </span>
          </div>
        </template>
      </Tree>
    </div>

    <AddPrivateDokNodeModal ref="addNewNodeModal" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DOKUMENTENARCHIV_TYPES from '@/store/dokumentenarchiv/types';

import { PhEye, PhEyeSlash, PhPlus, PhTrash, PhPencilLine, PhDotsThreeVertical, } from 'phosphor-vue';

import Tree from '@/components/core/Tree.vue';
import BaseDropdownMenu from '@/components/core/BaseDropdownMenu.vue';
import AddPrivateDokNodeModal from '@/components/documents/privateDokNode/AddPrivateDokNodeModal.vue';
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";

export default {
  mixins: [InteractiveHelpCommonsMixin],
  props: {
    nodeId: {
      type: [String, Number],
    },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      dokumentenarchivData: DOKUMENTENARCHIV_TYPES.GETTERS.DOKUMENTENARCHIV_DATA,
    }),
    nodes() {
      const node = this.dokumentenarchivData?.nodes?.find(node => node.nodeId === this.nodeId);
      return node && [ node ] || [];
    },
  },
  methods: {
    expandNode(nodeId) {
      if(!nodeId) return;
      this.$refs.nodeTree?.expandItem(nodeId);
    },
    nodeTreeExpandFirstItem() {
      if(!this.nodes?.length) return;
      this.expandNode(this.nodes[0]?.nodeId);
    },
    toggleNodeShowForKunde(node) {
      if(!node?.subfolderMetadata) return;

      this.$store.dispatch(DOKUMENTENARCHIV_TYPES.ACTIONS.UPDATE_PRIVATE_DOK_NODE, {
        nodeId: node.nodeId,
        showForKunde: !node.subfolderMetadata.showForKunde,
      });
    },
    addNewNode(parentNode) {
      this.$refs.addNewNodeModal?.open(parentNode);
      this.expandNode(parentNode?.nodeId);
    },
    editNode(node) {
      this.$refs.addNewNodeModal?.open(node.parent, node?.nodeId);
      this.expandNode(node?.nodeId);
    },
    openRemoveNodeConfirm(node) {
      this.$confirmModal({
        message: `Wollen Sie Ordner <b>"${node.label}"</b> wirklich löschen?`,
        title: 'Ordner löschen',
        labelButtonConfirm: 'Löschen'
      }).then(() => this.$store.dispatch(DOKUMENTENARCHIV_TYPES.ACTIONS.DELETE_PRIVATE_DOK_NODE, node));
    },
  },
  mounted() {
    this.nodeTreeExpandFirstItem();
  },
  components: {
    Tree,
    BaseDropdownMenu,
    AddPrivateDokNodeModal,
    PhEye,
    PhEyeSlash,
    PhPlus,
    PhTrash,
    PhPencilLine,
    PhDotsThreeVertical,
  },
}
</script>

<style lang="scss" scoped>
.private-dok-node-tree__container {
  font-size: 0.9em;
}

.add-new-node-tree--header,
.add-new-node-tree--content {
  display: flex;
  justify-content: space-between;

  > span {
    margin: 0 8px 0 0;

    &:last-child {
      margin-right: 0;
    }
  }

  .add-new-node-tree--label {
    flex: 1 1 auto;
    word-break: break-all;
  }

  .add-new-node-tree--visibility {
    text-align: center;
    flex: 0 0 auto;
    width: 60px;
  }

  .add-new-node-tree--actions {
    display: flex;
    flex: 0 0 auto;
    justify-content: flex-end;
    width: 68px;

    button {
      margin: 0 8px 0 0;
      align-items: center;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.add-new-node-tree--header {
  border-bottom: 1px solid #c4c4c4;
  font-weight: 500;
  margin: 0 0 4px;
}

.action-texts {
  display: flex;
  flex-direction: column;
  padding: 2px;
  color: var(--color-text);
  justify-content: left;
  font-size: 0.85rem;

  button {
    padding: 2px 6px;
  }
}
</style>
