var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "config-edit-param-label__container" }, [
    _c(
      "div",
      { staticClass: "config-edit-param-label__content" },
      [
        _c("HtmlEditor", {
          attrs: { isSimplified: "", isSmallTextarea: "", value: _vm.value },
          on: {
            input: function($event) {
              return _vm.onUpdate($event)
            }
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "config-edit-param-label--actions" },
      [
        _c(
          "BaseButton",
          {
            attrs: { disabled: _vm.disabledConfirm },
            on: {
              click: function($event) {
                return _vm.onConfirm()
              }
            }
          },
          [_vm._v("Speichern")]
        ),
        _c(
          "BaseButton",
          {
            attrs: { isSecondary: "" },
            on: {
              click: function($event) {
                return _vm.onCancel()
              }
            }
          },
          [_vm._v("Abbrechen")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }