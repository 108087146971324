var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _vm.isLoadingData
        ? _c("Makler34fConfigLoading")
        : [
            _c("div", { staticClass: "box__title" }, [
              _vm._v(
                "Liste der erlaubten Gesellschaften für " +
                  _vm._s(_vm.gesellschaft34fTyp.title)
              )
            ]),
            _c(
              "div",
              [
                _c("InputToggleSwitch", {
                  attrs: {
                    label: _vm.gesellschaft34fTyp.erlaubnisLabel,
                    inLineLabel: "",
                    disabled: _vm.isDisabled
                  },
                  on: {
                    input: function($event) {
                      return _vm.dataChanged($event)
                    }
                  },
                  model: {
                    value: _vm.form.erlaubnis,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "erlaubnis", $$v)
                    },
                    expression: "form.erlaubnis"
                  }
                })
              ],
              1
            ),
            _c("p", { staticClass: "text-small" }, [
              _vm._v(
                ' Hier sehen Sie eine Liste aller Gesellschaften, die im MSC angeboten werden. Sie können hier einzelne (oder alle) Gesellschaften deaktivieren, falls Sie diese nicht vertreiben. Gesellschaften, die neu in den Datenbestand übernommen werden, sind zunächst als "Erlaubt" gekennzeichnet. '
              )
            ]),
            _c("Makler34fGesellschaftenTable", {
              attrs: { disabled: _vm.isDisabled || !_vm.isAllowed }
            })
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }