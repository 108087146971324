var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("GesellschaftTitleNavigation"),
      _c(
        "div",
        {
          staticClass: "box__container",
          attrs: { tid: _vm._generateTidFromString("box__container") }
        },
        [
          _c("div", { staticClass: "box__title" }, [
            _vm._v("Gesellschaftsdaten")
          ]),
          _vm.gesellschaftInfoField
            ? _c("div", [
                _c("div", [_vm._v(_vm._s(_vm.gesellschaftInfoField.name))]),
                _c(
                  "div",
                  [
                    _vm.gesellschaftInfoField.strasse
                      ? [
                          _vm._v(
                            _vm._s(_vm.gesellschaftInfoField.strasse) + ", "
                          )
                        ]
                      : _vm._e(),
                    _vm._v(
                      _vm._s(_vm.gesellschaftInfoField.plz) +
                        " " +
                        _vm._s(_vm.gesellschaftInfoField.ort)
                    )
                  ],
                  2
                ),
                _c("div", [_vm._v(_vm._s(_vm.gesellschaftInfoField.land))]),
                _c("div", [
                  _vm._v(
                    _vm._s(_vm.gesellschaftInfoField.plzPostfach) +
                      " " +
                      _vm._s(_vm.gesellschaftInfoField.postfach)
                  )
                ]),
                _c("div", { staticClass: "mt-3" }, [
                  _vm._v(_vm._s(_vm.gesellschaftInfoField.beschreibung))
                ])
              ])
            : _vm.loading
            ? _c("GhostLoading", { attrs: { type: "table" } })
            : _vm._e()
        ],
        1
      ),
      _vm.vermittlerRecords && _vm.vermittlerRecords.length
        ? _c(
            "div",
            {
              staticClass: "box__container",
              attrs: {
                tid: _vm._generateTidFromString(
                  "cards__container layout__negative-margin--8"
                )
              }
            },
            [
              _c("Table", {
                attrs: {
                  title: "Vermittler",
                  rowId: "index",
                  hidePagination: "",
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowsPerPage: 20
                }
              }),
              _vm.isUnternrnMoeglich
                ? _c("div", [_vm._v("Unternummern möglich!")])
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.biproZugang
        ? _c(
            "div",
            {
              staticClass: "box__container",
              attrs: {
                tid: _vm._generateTidFromString(
                  "box__container cards__biproZugang"
                )
              }
            },
            [
              _c("div", { staticClass: "box__title" }, [
                _vm._v("BiPRO Zugänge")
              ]),
              _c("Table", {
                attrs: {
                  headers: _vm.headersBiproZugaeng,
                  hidePagination: "",
                  rowsPerPage: 20,
                  rows: _vm.rowsBiproZugaeng
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.gesellschaftDaten.hinweise && _vm.gesellschaftDaten.hinweise.length
        ? _c(
            "div",
            {
              staticClass: "box__container",
              attrs: {
                tid: _vm._generateTidFromString(
                  "box__container cards__hinweise"
                )
              }
            },
            [
              _c("div", { staticClass: "box__title" }, [
                _vm._v("Wichtige Hinweise")
              ]),
              _vm._l(_vm.gesellschaftDaten.hinweise, function(hinweis, index) {
                return _c("p", { key: index }, [_vm._v(_vm._s(hinweis))])
              })
            ],
            2
          )
        : _vm._e(),
      _vm.gesellschaftLinks && _vm.gesellschaftLinks.length
        ? _c(
            "div",
            {
              staticClass: "box__container",
              attrs: {
                tid: _vm._generateTidFromString(
                  "box__container cards__itemasdfa"
                )
              }
            },
            [
              _c("div", { staticClass: "box__title" }, [
                _vm._v("Wichtige Links")
              ]),
              _c("div", [
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.clickGesellschaftLink()
                      }
                    }
                  },
                  [_vm._v("PDF Briefkopf")]
                )
              ]),
              _vm._l(_vm.gesellschaftLinks, function(linkItem, index) {
                return _c("div", { key: index }, [
                  _c("a", { attrs: { target: "_blank", href: linkItem.url } }, [
                    _vm._v(_vm._s(linkItem.label))
                  ])
                ])
              })
            ],
            2
          )
        : _vm._e(),
      _vm.gesellschaftInfoField
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("div", { staticClass: "box__title" }, [_vm._v("Notizen")]),
              _c("InputTextArea", {
                attrs: { autoGrow: "" },
                model: {
                  value: _vm.maklerBemerkung,
                  callback: function($$v) {
                    _vm.maklerBemerkung = $$v
                  },
                  expression: "maklerBemerkung"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }