import BROKERDATA_TYPES from './types';
import Vue from 'vue'


export default {
  [BROKERDATA_TYPES.MUTATIONS.RESET_BROKER_DATA_EDITED](state) {
    Vue.set(state, 'brokerDataEdited', {})
  },

  [BROKERDATA_TYPES.MUTATIONS.GET_BROKER_DATA_SUCCESS](state, payload) {
    if (payload.brokerData) {

      Vue.set(state, 'brokerData', { ...payload.brokerData });
    }
  },
  [BROKERDATA_TYPES.MUTATIONS.GET_BROKER_DATA_CONFIG_SUCCESS](state, payload) {
    if (payload.brokerDataConfig) {
      Vue.set(state, 'brokerDataConfig', { ...payload.brokerDataConfig });
    }
  },
  [BROKERDATA_TYPES.MUTATIONS.SAVE_BROKER_DATA_SUCCESS](state, payload) {
    if (payload.brokerData) {
      Vue.set(state, 'brokerData', { ...payload.brokerData });
    }
  },
  [BROKERDATA_TYPES.MUTATIONS.SAVE_MAKLER_ANSPRECHPARTNER_SUCCESS](state, payload) {
    if (payload.ansprechpartner) {
      Vue.set(state, 'ansprechpartner', { ...payload.ansprechpartner });
    }
  },
  [BROKERDATA_TYPES.MUTATIONS.DELETE_PERSON_DATA_SUCCESS](state, payload) {
    if (payload.brokerData) {
      Vue.set(state, 'brokerData', { ...payload.brokerData });
    }
  },
  [BROKERDATA_TYPES.MUTATIONS.GET_ANSPRECHPARTNER_BY_ID_SUCCESS](state, payload) {
    if (payload.ansprechpartner) {
      Vue.set(state, 'ansprechpartner', { ...payload.ansprechpartner });
    }
  },
  [BROKERDATA_TYPES.MUTATIONS.FIND_ANSPRECHPARTNER_SUCCESS](state, payload) {
    Vue.set(state, 'ansprechpartnerList', [ ...payload || [], ]);
  },
  [BROKERDATA_TYPES.MUTATIONS.GET_BROKER_BY_ID_SUCCESS](state, payload) {
    if (payload.brokerId) {
      Vue.set(state, 'broker', { ...payload.broker });
    }
  },
  [BROKERDATA_TYPES.MUTATIONS.GET_MAKLER_PICTURE_SUCCESS](state, payload) {
    if (payload.maklerPicture) {
      Vue.set(state, 'maklerPicture', { ...payload.maklerPicture });
    }
  },
  /* [BROKERDATA_TYPES.MUTATIONS.FETCH_TERMINE_SUCCESS](state, payload) {
    if (payload.termine) {
      Vue.set(state, 'termine', payload.termine);
    }
  }, */
  [BROKERDATA_TYPES.MUTATIONS.FETCH_ACTIVITIES_SUCCESS](state, payload) {
    if (payload.activities) {
      Vue.set(state, 'activities', payload.activities);
    }
  },
  [BROKERDATA_TYPES.MUTATIONS.FIND_BEMERKUNGEN_SUCCESS](state, payload) {
    Vue.set(state, 'bemerkungen', [...payload]);
  },
  [BROKERDATA_TYPES.MUTATIONS.GET_BESUCHSBERICHTE_SUCCESS](state, payload) {
    Vue.set(state, 'besuchsberichte', payload);
  },
  [BROKERDATA_TYPES.MUTATIONS.GET_IMG_MAKLER_FACIMILIE_SUCCESS](state,payload){
    if(payload.facsimileImg){
      Vue.set(state, 'facsimileImg', { ...payload.facsimileImg })
    }
  },
  [BROKERDATA_TYPES.MUTATIONS.GET_IMG_MAKLER_FACIMILIE_OHNE_SUCCESS](state,payload){
    if(payload.facsimileOhne){
      Vue.set(state, 'facsimileOhne', { ...payload.facsimileOhne })
    }
  },
  [BROKERDATA_TYPES.MUTATIONS.ADD_BROKER_DATA_EDITED](state, payload) {
    Vue.set(state, 'brokerDataEdited', Object.assign({}, state.brokerDataEdited, payload))
  },
  [BROKERDATA_TYPES.MUTATIONS.ADD_MAKLER_ANSPRECHPARTNER_DATA_EDITED](state, payload) {
    Vue.set(state, 'ansprechpartnerEdited', Object.assign({}, state.ansprechpartnerEdited, payload))
  },
  [BROKERDATA_TYPES.MUTATIONS.RESET_BROKER_DATA_EDITED](state) {
    Vue.set(state, 'brokerDataEdited', {})
  },
  [BROKERDATA_TYPES.MUTATIONS.RESET_MAKLER_ANSPRECHPARTNER_DATA_EDITED](state) {
    Vue.set(state, 'ansprechpartnerEdited', {})
  },
  [BROKERDATA_TYPES.MUTATIONS.SAVE_BROKER_DATA_SUCCESS](state, payload) {
    if (payload.brokerData) {
      Vue.set(state, 'brokerData', { ...payload.brokerData });
    }
  },

  [BROKERDATA_TYPES.MUTATIONS.GET_BROKER_PRIMAER_PERSONEN_SUCCESS](state, payload) {
    if (payload.primaerPersonen) {
      Vue.set(state, 'primaerPersonen', { ...payload.primaerPersonen });
    }
  },

  [BROKERDATA_TYPES.MUTATIONS.SAVE_PRIMAER_PERSONEN_SUCCESS](state, payload) {
    if (payload.primaerPersonen) {
      Vue.set(state, 'primaerPersonen', { ...payload.primaerPersonen });
    }
  },

  async [BROKERDATA_TYPES.MUTATIONS.FIND_BIRTHDAYS_SUCCESS](state, payload) {
    Vue.set(state, 'birthdays', [...payload || []]);
  },

  [BROKERDATA_TYPES.MUTATIONS.UPLOAD_FACSIMILE_SUCCESS](state, payload) {
    state.facsimile = Object.assign({}, state.facsimile || {}, payload);
  },

  [BROKERDATA_TYPES.MUTATIONS.PUT_BROKER_PAGE_TABLE_SIZE](state, payload) {
    state.pageTableSize = typeof payload == 'string' ? parseInt(payload, 10) : payload ;
  },

  [BROKERDATA_TYPES.MUTATIONS.RESET_FACSIMILE](state, payload){
    Vue.set(state, 'facsimile', {})
  },

  [BROKERDATA_TYPES.MUTATIONS.SET_STORNORESERVE_VERS](state, payload){
    Vue.set(state, 'stornoreserveVers', payload)
  },

  [BROKERDATA_TYPES.MUTATIONS.SET_SONSTIGES](state, payload){
    Vue.set(state, 'sonstiges', payload)
  },


}
