import COURTAGETABELLE_VERSICHERUNG_TYPES from './types';

export default {
  [COURTAGETABELLE_VERSICHERUNG_TYPES.GETTERS.FILTER_FIELDS_DEFINITIONS](state) {
    return state.filterFieldsDefinition;
  },
  [COURTAGETABELLE_VERSICHERUNG_TYPES.GETTERS.COURTAGETABELLE_VERSICHERUNG_LIST](state) {
    return state.courtagetabelleVersicherungList;
  },
  [COURTAGETABELLE_VERSICHERUNG_TYPES.GETTERS.COURTAGETABELLE_VERSICHERUNG_DISPLAY_INFO](state) {
    return state.courtagetabelleVersicherungDisplayInfo;
  },
  [COURTAGETABELLE_VERSICHERUNG_TYPES.GETTERS.GET_MENU_ITEM_ACCESS_RULE](state) {
    return state.menuOptionAccessRule;
  },
  [COURTAGETABELLE_VERSICHERUNG_TYPES.GETTERS.LAST_UPDATED_ITEM](state) {
    return state.lastUpdatedItem;
  },
  [COURTAGETABELLE_VERSICHERUNG_TYPES.GETTERS.FORM_METADATA](state) {
    return state.formMetadata;
  },
  
}