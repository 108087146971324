import QUARTALSBERICHTE_TYPES from './types';
import axios from 'axios';
import * as utils from '../../helpers/commonfunctions';

export default {
  async [QUARTALSBERICHTE_TYPES.ACTIONS.RETRIEVE_QUARTALSBERICHTE_NODE]({ commit, dispatch, rootState }, {root}) {
    const config = {
      defaultSpinner: true
    };

    return await new Promise((resolve, reject) => {
      let serviceUrl = '/dokumentenarchiv/getDokTree';

      if(root) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'root', root);
      }
     
  
      axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
        if (response && response.data) {
  
          commit(QUARTALSBERICHTE_TYPES.MUTATIONS.RETRIEVE_QUARTALSBERICHTE_NODE_SUCCESS, response.data);
          resolve(response.data);
        } else {
          reject();
        }

  
      })
      .catch(() => reject());

    });  


  },

  [QUARTALSBERICHTE_TYPES.ACTIONS.QUARTALSBERICHTE_BERECHNEN]({ commit, dispatch, rootState }, {root}) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/quartalsberichte/berechnen';

      axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
        if (response && response.data) {
  
          commit(QUARTALSBERICHTE_TYPES.MUTATIONS.QUARTALSBERICHTE_BERECHNEN_SUCCESS, response.data);
          resolve(response.data);
        } else {
          reject();
        }

  
      });

    });  


  },  

}