var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "risiko-analyse-positionen-component" }, [
    _c(
      "div",
      { staticClass: "risiko-analyse-positionen-component-buttons" },
      [
        _c(
          "BaseButton",
          {
            attrs: { disabled: _vm.loadingAntrag },
            on: {
              click: function($event) {
                return _vm.handleErneutAnalysieren()
              }
            }
          },
          [_vm._v("Erneut Analysieren")]
        )
      ],
      1
    ),
    _c("hr"),
    _vm.data
      ? _c(
          "div",
          { staticClass: "risiko-analyse-positionen-component-tables" },
          [
            _c("h4", [_vm._v("In diesem Antrag gekaufte Wertpapiere")]),
            _vm.newRows.length
              ? _c("Table", {
                  attrs: {
                    headers: _vm.newHeaders,
                    rows: _vm.newRows,
                    rowId: "description1",
                    hidePagination: ""
                  }
                })
              : _c("NoData", {
                  attrs: { noIcon: "", content: "Keine Informationen" }
                }),
            _c("h4", [_vm._v("Bestände des Kunden")]),
            _vm.oldRows.length
              ? _c("Table", {
                  attrs: {
                    headers: _vm.oldHeaders,
                    rows: _vm.oldRows,
                    rowId: "description2",
                    hidePagination: ""
                  }
                })
              : _c("NoData", {
                  attrs: { noIcon: "", content: "Keine Informationen" }
                }),
            _vm.hasNachhaltigkeit
              ? _c(
                  "div",
                  [
                    _c("h4", [_vm._v("Informationen zur Nachhaltigkeit")]),
                    _vm.nachhaltigkeitRows.length
                      ? _c("Table", {
                          attrs: {
                            headers: _vm.nachhaltigkeitHeaders,
                            rows: _vm.nachhaltigkeitRows,
                            rowId: "description33",
                            hidePagination: ""
                          }
                        })
                      : _c("NoData", {
                          attrs: { noIcon: "", content: "Keine Informationen" }
                        }),
                    _c("h4", [_vm._v("Details Taxonomie")]),
                    _vm.taxoRows.length
                      ? _c("Table", {
                          attrs: {
                            headers: _vm.taxonomieHeaders,
                            rows: _vm.taxoRows,
                            rowId: "description4",
                            hidePagination: ""
                          }
                        })
                      : _c("NoData", {
                          attrs: { noIcon: "", content: "Keine Informationen" }
                        }),
                    _c("h4", [_vm._v("Details SFDR")]),
                    _vm.sfdrRows.length
                      ? _c("Table", {
                          attrs: {
                            headers: _vm.sfdrHeaders,
                            rows: _vm.sfdrRows,
                            rowId: "description5",
                            hidePagination: ""
                          }
                        })
                      : _c("NoData", {
                          attrs: { noIcon: "", content: "Keine Informationen" }
                        })
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }