<template>
<div>
    <BaseModal
        ref="angeboteneGesellschaften"
        modalTitle="angebotene Gesellschaften"
        :showDefaultButtons="false"
        :showCloseButton="true">
      <div>
        {{ rueckFrage }}
      </div>
      <template v-slot:footer>
        <BaseButton isPrimary @click="confirmOperation()">Ja</BaseButton>
        <BaseButton @click="cancelOperation()" isSecondary>Nein</BaseButton>
        <BaseButton @click="close()" isSecondary>Abbrechen</BaseButton>
      </template>
    </BaseModal> 


</div>

</template>

<script>
import BaseModal from '@/components/core/BaseModal.vue';
import BERATUNGSMAPPE_TYPES from "@/store/beratungsmappe/types";
import { mapGetters } from "vuex";
import BaseButton from '@/components/core/BaseButton.vue';

export default {
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    config: {
      type: Object,
    },
    antragData: {
      type: Object,
    },
    beratungsmappeId: {
      type: String
    },
  },
  components: {
    BaseModal,
    BaseButton
  },
  mounted() {
    if (this.antragData && this.antragData['RUECK_FRAGE']) {
      this.rueckFrage = this.antragData['RUECK_FRAGE'];

      if (this.antragData['HAT_RUECK_FRAGE'] == "1") {
        this.$refs.angeboteneGesellschaften.open();
      }

    }
  },
  data() {
    return {
      form: {},
      resultCount: null,     
      positionToDelete: null, 
      deletedPositions: [],
      rueckFrage: '',
    };
  },
  watch: {
    lastDeletedAntragsPosition: function (newValue, oldValue) {
      this.deletedPositions.push(newValue.id);
    },
  },
  computed: {
    ...mapGetters({
      lastDeletedAntragsPosition: BERATUNGSMAPPE_TYPES.GETTERS.LAST_DELETED_ANTRAGS_POSITION,
    }),   
   

  },
  methods: {
    confirmOperation() {
      const params = {
        sicher: '1',
        typ: this.config && this.config['RAHMENVEREINBARUNGSTYP'] || 'ERSTINFORMATION'
      };

      this.$store.dispatch(BERATUNGSMAPPE_TYPES.ACTIONS.GENERATE_GESELLSCHAFTEN, params);
      this.$refs.angeboteneGesellschaften.close();

    },
    cancelOperation() {
      const params = {
        sicher: '2',
        typ: this.config && this.config['RAHMENVEREINBARUNGSTYP'] || 'ERSTINFORMATION'
      };

      this.$store.dispatch(BERATUNGSMAPPE_TYPES.ACTIONS.GENERATE_GESELLSCHAFTEN, params);
      this.$refs.angeboteneGesellschaften.close();

    },  
    close() {
      this.$refs.angeboteneGesellschaften.close();
    },  
  },
};
</script>
<style scoped>
.input-forms__container {
  padding-bottom: 8px;
}

div.form-container__content > div {
  padding-bottom: 8px;
}
</style>