<template>
  <div id="chart-main" >
    <div class="d-flex" v-if="zoom.length">
      <div style="flex-grow: 1;"></div>
      <ComboBox :value="years" :values="zoom" @change="onChangeYear" />
    </div>

    <LineChart ref="linechart"
      :chartSeries="echartSeries" 
      :isArea="false"
      :customTooltipFormatter="customTooltipFormatter"
      :customColors="getColors(echartSeries)"
      :hiddenSeries="hiddenSeries"
      height="450px" 
      :format="format"
      isLegend
      showDataZoom />

    <!--dispMessage doesn't contain unsanitized user input-->
    <div v-if="dispMessage" class="ml-4" v-html="dispMessage">
    </div>
  </div>
</template>

<script>
import ComboBox from '@/components/core/forms/ComboBox.vue';
import FONDSINFO_TYPES from "@/store/fondsinfo/types";
import dayjs from 'dayjs';
import {formatNumber} from '@/helpers/number-formatter.js';
import LineChart from '@/components/charts/echarts/LineChart.vue'

const percent = (value, numberFixed = 0) => {
  if (isNaN(value)) {
    return value;
  }
  let val = numberFixed !== 0 ? (value/1).toFixed(numberFixed).replace('.', ',') : parseInt(value/1).toString().replace('.', ',')
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' %';
};

export default {
  props: {
    chartHeight: {
      type: String,
      default: '400px',
    },
    chartData: {
      type: Array,
      required: false,
    },
    linie200Tage: {
      type: Boolean,
      default: true,
    },
    wertentwicklung: {
      type: Array,
      required: false,
    },
    zoom: {
      type: Array,
      required: false,
      default: () => []
    },
    isin: {
      type: String,
      default: '',
    },
    toolTipTruncName: {
      type: String,
      default: '',
    },
    customColors: {
      type: Array,
      default: null,
    },
    customColorsOrder: {
      default: () => ({}),
    },
    setCustomColorsOrder: {
      default: null,
    },

  },
  data() {
    return {
      xLabels: [],
      series: [],
      // seriesZ: [],
      years: 0,
      refresh: false,
      dispMessage: '',
      leftZoom: 0,
      rightZoom: 100,
      zoomIdxStart: -1,
      zoomIdxEnd: -1,
      externLegend: [],
      colors: ['#00CC00', '#0000FF', '#FF0000', '#FF8000'],
      format: 'percent'
    }
  },
  computed: {
    echartSeries() {
      if (!this.refresh && this.series.length && this.zoomIdxStart !== -1 && this.zoomIdxEnd !== -1) {
        if (this.wertentwicklung) { // serie wurde sortiert (wie Einstellungen - normal Performance) / name ist uninteressant
          return this.series;
        }
        let result = [];
        if ( this.zoomIdxEnd === this.series[0].data.length && this.zoomIdxStart === 0) {
          result = this.series.sort((a, b) => a.name.localeCompare(b.name));
        } else if ( this.zoomIdxEnd === this.series[0].data.length) {
          result = this.series.map(serie => { return {name: serie.name, data: serie.data.slice(this.zoomIdxStart).sort((a, b) => a.name.localeCompare(b.name))}});
        } else {
          result = this.series.map(serie => { return {name: serie.name, data: serie.data.slice(this.zoomIdxStart,this.zoomIdxEnd).sort((a, b) => a.name.localeCompare(b.name))}});
        }
        return result;
        // no reduced data for ECharts
        // const reducer = Math.floor( result[0].data.length / 800 ) ;
        // if (this.wertentwicklung || reducer <= 1) {
        //   return result;
        // } else {
        //   const reduce = [];
        //   result.forEach( serie => {
        //     const data = [];
        //     serie.data.forEach( (point, idx) => {
        //       if (idx % reducer === 0) {
        //         data.push(point);
        //       }
        //     })
        //     reduce.push( {...serie, data});
        //   })
        //   return reduce;
        // }
        
      }
      return [{name: '', data: []}];
    },
    seriesM() {
      if (!this.refresh && this.series.length && this.zoomIdxStart !== -1 && this.zoomIdxEnd !== -1) {
        let result = [];
        if ( this.zoomIdxEnd === this.series[0].data.length && this.zoomIdxStart === 0) {
          result = this.series;
        } else if ( this.zoomIdxEnd === this.series[0].data.length) {
          result = this.series.map(serie => { return {name: serie.name, data: serie.data.slice(this.zoomIdxStart)}});
        } else {
          result = this.series.map(serie => { return {name: serie.name, data: serie.data.slice(this.zoomIdxStart,this.zoomIdxEnd)}});
        }
        const reducer = Math.floor( result[0].data.length / 800 ) ;
        if (this.wertentwicklung || reducer <= 1) {
          return result;
        } else {
          const reduce = [];
          result.forEach( serie => {
            const data = [];
            serie.data.forEach( (point, idx) => {
              if (idx % reducer === 0) {
                data.push(point);
              }
            })
            reduce.push( {...serie, data});
          })
          return reduce;
        }
        
      }
      return [{name: '', data: []}];
    },
    hiddenSeries() {
      const hidden = this.externLegend?.reduce?.((accu, curr) => ({[curr.name]: curr.visible, ...accu}), {})
      return Object.keys(hidden).length ? hidden : null;
    },
  },
  methods: {
    getColors(data) {
      const colors = this.customColors || this.colors
      if (Array.isArray(data) && this.setCustomColorsOrder) {
        return data.map(v => {
          const name = v.name.replace('\n', ' *').split(/ \*/)
          if (name && name[0]) {
            if (!this.customColorsOrder[name[0]]) {
              this.setCustomColorsOrder(name[0])
            }
            return this.customColorsOrder[name[0]] || colors[Object.keys(this.customColorsOrder).length];
          }
        }).concat(colors)
      }
      return colors
    },
    setSeries() {
      this.refresh = false;
      this.makeSeries( [this.chartData]);
    },
    wertentw() {
      if ( this.wertentwicklung && this.wertentwicklung.length) {
        this.refresh = true;
        this.leftZoom = 0;
        this.rightZoom = 100;
        this.dispMessage = '';
        const dataSeries = [];
        this.seriesName = [];
        this.seriesId = [];
        const colors = [];
        this.format = this.wertentwicklung[0].format ? this.wertentwicklung[0].format : 'percent' ;
        this.wertentwicklung.forEach( chData => {
          dataSeries.push(chData.data);
          this.seriesName.push(chData.name);
          this.seriesId.push(chData.id);
          colors.push(chData.color);
        });
        this.colors = colors;
        setTimeout(() => {
          this.refresh = false;
          this.externLegend = this.wertentwicklung[0].externLegend ? this.wertentwicklung[0].externLegend : [];
          this.makeSeries(dataSeries);
        }, 0);
      }
    },
    makeSeries(dataSeries) {
      if ( !dataSeries ) {
        if (!this.chartData?.length) {
          return;
        }
        dataSeries = [this.chartData];
      }
      if (this.zoom.length && !this.years) {
        this.years = this.zoom[0].value;
      }
      let chartData;
      let series = [];
      for (let serIdx = 0; serIdx < dataSeries.length; serIdx++ ) {
        chartData = dataSeries[serIdx];
        try {
          if ( chartData && chartData.length > 0 ) {
            const s1 = [], s2 = [], s3 = [], s4 = [];
            let temp = [], xDate, time;
            let sumAverage = 0, gdValue = 0, offset = 0;
            chartData.forEach( (point, idx) => {
              if (typeof point[0] === 'number') {
                time = point[0];
              } else {
                temp = point[0].split('.').map( v => parseInt(v, 10));
                xDate = new Date(temp[2], temp[1] - 1, temp[0]);
                time = xDate.getTime();
              }
              s1.push([time, point[1]]);
              if ( this.linie200Tage ) {
                sumAverage += point[1];
                if ( idx >= 200) {
                  sumAverage -= chartData[idx - 200][1];
                  gdValue = sumAverage / 200;
                  offset = gdValue * 0.1;
                  s2.push([time, gdValue]);
                  s3.push([time, gdValue + offset]);
                  s4.push([time, gdValue - offset]);
                } else {
                  s2.push([time, null]);
                  s3.push([time, null]);
                  s4.push([time, null]);
                }
              }
            });
            const serie =  {name: this.seriesName[serIdx], data: s1};
            if (this.seriesId && serIdx < this.seriesId.length) {
              serie.id = this.seriesId[serIdx];
            }
            series.push( serie );
            if ( this.linie200Tage ) {
                series = series.concat( [
                {name: this.seriesName[1], data: s2},
                {name: this.seriesName[2], data: s3},
                {name: this.seriesName[3], data: s4}
              ])
            }
          }
        } catch (e) { console.error(e, this); }
      }
      this.zoomIdxStart = 0;
      this.zoomIdxEnd = series[0].data.length;
      this.series = series;
    },
    // getIntervall(idxVon, idxBis) {
    //   const data = this.series[0].data;
    //   const von = dayjs(data[idxVon][0]).format('DD MMM YYYY') ;
    //   const bis = dayjs(data[idxBis][0]).format('DD MMM YYYY') ;
    //   let result = 'Wertentwicklung ';
    //   if (this.zoomIdxStart === idxVon) {
    //     result += von;
    //   } else {
    //     result += '<span style="color: var(--color-danger)">' + von +'</span>'
    //   }
    //   result += ' - ';
    //   if (this.zoomIdxEnd - 1 === idxBis) {
    //     result += bis;
    //   } else {
    //     result += '<span style="color: var(--color-danger)">' + bis +'</span>'
    //   }
    //   this.dispMessage = result;
    // },
    onChangeYear(value) {
      if (value != this.years && this.isin ) {
        this.years = value;
        this.$store.dispatch(
          FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_PERFORMANCE_CHART,
          {
            isin: this.isin,
            years: this.years
          }
        );
        this.refresh = true;
        this.leftZoom = 0;
        this.rightZoom = 100;
        this.dispMessage = '';
      }
    },
    customTooltipFormatter(params) {
      if(Array.isArray(params)) {
        const name = dayjs(params[0].value[0]).format('DD MMM YYYY') || '';
        const html = params.map(p => {
          const { marker, seriesName, value } = p;
          let seriesNameTool = seriesName;
          if (this.toolTipTruncName && -1 !== seriesNameTool.indexOf(this.toolTipTruncName)) {
            seriesNameTool = seriesNameTool.substr(0, seriesNameTool.indexOf(this.toolTipTruncName));
          }
          let percentValue = value
          if (Array.isArray(value)) {
            percentValue = value[1]
          }
          if (!percentValue) {
            return ''
          }
          // const formattedValue = this.format === 'percent' ? percent(percentValue || 0, 2) ;
          const formattedValue = formatNumber(percentValue || 0, 2) + ( this.format === 'percent' ? ' %' : ' €');
          return `<div><span>${marker}</span> <span>${seriesNameTool}:</span> <b>${formattedValue}</b></div>`;
        });
        return `
          <div class="tooltip-series">
            <div><b>${name}</b></div>
            <div class="tooltip-series-table">${html.join('')}</div>
          </div>
        `;
      } else {
        const { marker, seriesName, name, value } = params;
        let seriesNameTool = seriesName;
        if (this.toolTipTruncName && -1 !== seriesNameTool.indexOf(this.toolTipTruncName)) {
          seriesNameTool = seriesNameTool.substr(0, seriesNameTool.indexOf(this.toolTipTruncName));
        }
        // const formattedValue = percent(value);
        const formattedValue = formatNumber(value, 0) + ( this.format === 'percent' ? ' %' : ' €');
        return `
          <div>
            <div><b>${seriesNameTool} test</b></div>
            <div><span>${marker}</span> <span>${name}:</span> <b>${formattedValue}</b></div>
          </div>`;
      }
    },
  },
  watch: {
    chartData: 'setSeries',
    wertentwicklung: 'wertentw',
  },
  created() {
    this.seriesName = ['Wertentwicklung', '200-tage-Linie', 'Obere Schranke', 'Untere Schranke'];
  },
  mounted() {
    if ( this.$refs?.linechart?.$refs?.chart) {
      this.$emit('perf-chart', this.$refs.linechart.$refs.chart, this.$refs.linechart);
      if (this.chartData && this.chartData.length && !this.xLabels.length) {
        this.makeSeries();
      } 
      if ( this.wertentwicklung && this.wertentwicklung.length) {
        this.wertentw();
      }
    }
    
  },
  components: { 
    ComboBox,
    LineChart,
  }
}
</script>

<style scoped>
#chart-main {
  background-color: var(--color-box);
  padding-right: 1rem;
}
.containerZoom {
  position: relative;
}
.zoomPar {
  position: relative;
  height: 50px;
  overflow-y: hidden;
}
.zoomChart {
  position: absolute;
  top: -25px;
}
.rangeZoom {
  position: absolute;
  top: 10%;
  bottom: 10%;
  background-color: rgba(144, 200, 255, 0.5);
}
.chevron-left, .chevron-right {
  top: calc(50% - 10px);
  position: absolute;
  display:inline-block;
  width: 12px;
  height: 12px;
  border-width: 0 2px 2px 0;
  border-style: solid;
  border-color: inherit;
  box-sizing: border-box
}
.chevron-left {
  transform:rotate(135deg);
}
.chevron-right {
  transform:rotate(-45deg);
  margin-left: 4px;
}
.charts-legend {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(15rem,auto));
  padding: 0.5rem 2rem 0 2rem;
  font-size: 13px;
  cursor: pointer;
}
.charts-legend .charts-legend-marker {
  margin-right: 5px;
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 50%;
}
</style>

<style>
#chart-main .apexcharts-tooltip-text {
	width: 100%;
}
#chart-main .apexcharts-tooltip-text .apexcharts-tooltip-y-group,
#chart-main .apexcharts-tooltip-text .apexcharts-tooltip-z-group  {
	display: flex;
}
#chart-main .apexcharts-tooltip-text .apexcharts-tooltip-y-group .apexcharts-tooltip-text-label,
#chart-main .apexcharts-tooltip-text .apexcharts-tooltip-y-group .apexcharts-tooltip-text-y-label,
#chart-main .apexcharts-tooltip-text .apexcharts-tooltip-z-group .apexcharts-tooltip-text-z-label{
	flex-grow: 1;
}
</style>