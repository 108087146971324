import VV_TYPES from './types';
import LOG_TYPES from '@/store/log/types'
import WERTPAPIERINFO_TYPES from '@/store/wertpapierinfo/types'
import { buildMessage } from '@/helpers/log-message-helper';
import axios from 'axios';

const config = {
  defaultSpinner: true
};

export default {

  [VV_TYPES.ACTIONS.GET_STRATEGIEN_FILTER_ITEMS]({ rootState, dispatch, commit }) {
    return axios.get(rootState.core.apiAddress + `/vv/getStrategienFilterMenus`, config)
      .then(response => commit(VV_TYPES.MUTATIONS.GET_STRATEGIEN_FILTER_ITEMS_SUCCESS, response.data))
      .catch(error => dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error, 'danger')))
  },

  [VV_TYPES.ACTIONS.GET_STRATEGIEN]({ state, rootState, dispatch, commit }) {
    return axios.post(rootState.core.apiAddress + `/vv/getStrategien`, state.strategienFilters, config)
      .then(response => {
        if (response.data.errorStr) {
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.errorStr, 'danger'));
        } else {
          commit(VV_TYPES.MUTATIONS.GET_STRATEGIEN_SUCCESS, response.data)
        }
      })
      .catch(error => dispatch(LOG_TYPES.ACTIONS.ERROR, { message: "VV_TYPES.ACTIONS.GET_STRATEGIEN", error }))
  },

  [VV_TYPES.ACTIONS.GET_GENERIERTE_FACTSCHEET_PDF]({ rootState }, schemaId) {
    return axios.get(rootState.core.apiAddress + `/vv/getGenerierteFactsheetPdf?schemaId=${schemaId}`, config);
  },

  [VV_TYPES.ACTIONS.VV_GET_AVAILABLE_FONDS]({ rootState, dispatch, commit, getters }, payload) {
    const act = getters[WERTPAPIERINFO_TYPES.GETTERS.ACT](payload?.act);
    const filter = act?.filters?.map(f => [f[0], f[2]]) || [];

    return axios.post(rootState.core.apiAddress + `/vv/getAvailableFonds?versionId=${payload?.versionId || ''}`,
      {
        ask: 'get_wp',
        params: {
          type: act?.phase?.wp_type,
          filter: filter,
          gesellschaft: '',
          lagerstelle: '',
          categoryId: '',
          zusatz: '',
          showOnlyGenehmigt: payload?.showOnlyGenehmigt || '0',
        }
      },
      {
        ...config,
        cancelableRequest: {
          title: 'Produktliste'
        },
      })
      .then(response => {
        commit(WERTPAPIERINFO_TYPES.MUTATIONS.SET_ACT, {
          act: payload.act, outcome: response?.data?.tabledata || {}
        });
      })
      .catch(error => dispatch(LOG_TYPES.ACTIONS.ERROR, { message: "VV_TYPES.ACTIONS.VV_GET_AVAILABLE_FONDS", error }))
  },

  [VV_TYPES.ACTIONS.VV_GET_NEW_FONDS]({ rootState, dispatch, commit, getters }, payload) {
    const act = getters[WERTPAPIERINFO_TYPES.GETTERS.ACT](payload?.act);
    const filter = act?.filters?.map(f => [f[0], f[2]]) || [];

    return axios.post(rootState.core.apiAddress + `/vv/getNewFonds`,
      {
        ask: 'get_wp',
        params: {
          type: act?.phase?.wp_type,
          filter: filter,
          gesellschaft: '',
          lagerstelle: '',
          categoryId: '',
          zusatz: ''
        }
      },
      {
        ...config,
        cancelableRequest: {
          title: 'Wertpapiersuche'
        },
      })
      .then(response => {
        commit(WERTPAPIERINFO_TYPES.MUTATIONS.SET_ACT, {
          act: payload.act, outcome: response?.data?.tabledata || {}
        });
      })
      .catch(error => dispatch(LOG_TYPES.ACTIONS.ERROR, { message: "VV_TYPES.ACTIONS.VV_GET_NEW_FONDS", error }))
  },

  [VV_TYPES.ACTIONS.WERTPAPIER_BEANTRAGEN]({ rootState, dispatch, commit }, isin) {
    if (isin) {
      return axios.get(rootState.core.apiAddress + `/vv/wertpapierBeantragen?isin=${isin}`, config)
        .then(response => commit(VV_TYPES.MUTATIONS.WERTPAPIER_BEANTRAGEN_DATA_SUCCESS, response?.data))
        .catch(error => dispatch(LOG_TYPES.ACTIONS.ERROR, { message: "VV_TYPES.ACTIONS.WERTPAPIER_BEANTRAGEN", error }))
    }
  },

  [VV_TYPES.ACTIONS.SAVE_WERTPAPIER_BEANTRAGEN]({ rootState, dispatch, commit }, { isin, data }) {
    if (isin) {
      return axios.post(rootState.core.apiAddress + `/vv/saveWertpapierBeantragen?isin=${isin}`, data, config)
        .then(response => commit(VV_TYPES.MUTATIONS.WERTPAPIER_BEANTRAGEN_DATA_SUCCESS, response?.data))
        .catch(error => dispatch(LOG_TYPES.ACTIONS.ERROR, { message: "VV_TYPES.ACTIONS.SAVE_WERTPAPIER_BEANTRAGEN", error }))
    }
  },

  [VV_TYPES.ACTIONS.LOAD_SUBSCRIPTION]({ rootState, dispatch, commit }) {
    return axios.get(rootState.core.apiAddress + '/vv/loadSubscription', config)
      .then(response => commit(VV_TYPES.MUTATIONS.PUT_SUBSCRIPTION, { ...response.data, status: { ready: true } }))
      .catch(error => dispatch(LOG_TYPES.ACTIONS.ERROR, { message: "VV_TYPES.ACTIONS.LOAD_SUBSCRIPTION", error }))
  },

  [VV_TYPES.ACTIONS.UPDATE_SUBSCRIPTION]({ rootState, dispatch, commit }, payload) {
    return axios.put(rootState.core.apiAddress + '/vv/updateSubscription', payload, config)
      .then(response => commit(VV_TYPES.MUTATIONS.PUT_SUBSCRIPTION, { ...response.data, status: { ready: true } }))
      .catch(error => dispatch(LOG_TYPES.ACTIONS.ERROR, { message: "VV_TYPES.ACTIONS.UPDATE_SUBSCRIPTION", error }))
  },

  [VV_TYPES.ACTIONS.GET_STRATEGIEN_SCHEMA_DATA]({ rootState, commit }, payload) {
    commit(VV_TYPES.MUTATIONS.RESET_STRATEGIEN_SCHEMA_DATA);
    commit(VV_TYPES.MUTATIONS.GET_STRATEGIEN_SCHEMA_DATA_LOADING, true);

    const path = payload?.isEditor ? 'getVVSchemaEdit' : 'getVVSchemaView';

    return axios.get(rootState.core.apiAddress + `/vv/${path}?schemaId=${payload?.schemaId}`, config)
      .then(response => commit(VV_TYPES.MUTATIONS.GET_STRATEGIEN_SCHEMA_DATA_SUCCESS, response.data))
      .then(() => commit(VV_TYPES.MUTATIONS.GET_STRATEGIEN_SCHEMA_DATA_LOADING, false));
  },

  [VV_TYPES.ACTIONS.CREATE_STRATEGIE]({ rootState }, data = {}) {
    if (data) {
      return axios.post(rootState.core.apiAddress + `/vv/createStrategie`, data, {
        ...config,
        cancelableRequest: {
          title: 'Strategie anlegen'
        },
      })
        .then(response => response?.data?.schemaId)
    }
  },

  [VV_TYPES.ACTIONS.SAVE_SCHEMA]({ state, rootState, commit }, data = {}) {
    if (state.strategienSchemaData?.dataHasChanged) {
      state.strategienSchemaData.dataHasChanged = false;
      return axios.post(rootState.core.apiAddress + `/vv/${data.schemaId === '0' ? 'saveNewSchema' : 'saveSchema'}?schemaId=${data.schemaId}&stepKey=${data.stepKey}`, state.strategienSchemaData, config)
        .then(response => commit(VV_TYPES.MUTATIONS.GET_STRATEGIEN_SCHEMA_DATA_SUCCESS, response.data))
    }
  },

  [VV_TYPES.ACTIONS.SAVE_SCHEMA_VERSION]({ state, rootState }, data = {}) {
    if (state.strategienSchemaData?.dataHasChanged || data.forceSave) {
      if (data.versionId === '0' && !state.strategienSchemaData?.versionAktuell) {
        return;

      } else {
        state.strategienSchemaData.dataHasChanged = false;
        return axios.post(rootState.core.apiAddress + `/vv/saveSchemaVersion?versionId=${data.versionId}`, state.strategienSchemaData, config)
      }
    }
  },

  [VV_TYPES.ACTIONS.DELETE_STRATEGIE]({ state, rootState, commit }, schemaId) {
    if (schemaId) {
      return axios.post(rootState.core.apiAddress + `/vv/deleteStrategie/${schemaId}`, state.strategienFilters, config)
        .then(response => commit(VV_TYPES.MUTATIONS.GET_STRATEGIEN_SUCCESS, response.data))
    }
  },

  [VV_TYPES.ACTIONS.CALC_ZIELMARKT_DATA]({ dispatch, commit }, data = {}) {
    return dispatch(VV_TYPES.ACTIONS.SAVE_SCHEMA_VERSION, { versionId: data.versionId, forceSave: true })
      .then(response => {
        if (response?.data)
          commit(VV_TYPES.MUTATIONS.GET_STRATEGIEN_SCHEMA_DATA_SUCCESS, response.data)
      });
  },

  [VV_TYPES.ACTIONS.CALC_ZIELMARKT_DATA_UPDATE]({ rootState, commit }, data = {}) {
    return axios.post(rootState.core.apiAddress + `/vv/calcSchemaVersionZielmarkt?schemaId=${data.schemaId}`, data, config)
      .then(response => {
        commit(VV_TYPES.MUTATIONS.GET_ZIELMARTKT_DATA_UPDATE_SUCCESS, {
          versionId: data.versionId,
          tableRows: response.data.tableRows,
          richtbandbreiten: response.data.richtbandbreiten,
        })
      })
  },

  [VV_TYPES.ACTIONS.ADD_GENEHMIGT_WERTPAPIERE]({ rootState, commit }, { versionId, isins }) {
    if (versionId && isins?.length) {
      return axios.post(rootState.core.apiAddress + `/vv/addGenehmigtWertpapiere?versionId=${versionId}`, isins, config)
        .then(response => commit(VV_TYPES.MUTATIONS.GET_STRATEGIEN_SCHEMA_DATA_SUCCESS, response.data))
    }
  },

  [VV_TYPES.ACTIONS.DELETE_WERTPAPIER]({ rootState, commit }, data) {
    if (data?.positionId) {
      return axios.delete(rootState.core.apiAddress + `/vv/deleteWertpapier?positionId=${data.positionId}`, config)
        .then(() => commit(VV_TYPES.MUTATIONS.DELETE_WERTPAPIER_SUCCESS, data))
    }
  },

  [VV_TYPES.ACTIONS.ZIELMARKT_UEBERNEHMEN]({ state, rootState, dispatch, commit }, versionId) {
    if (versionId) {
      return dispatch(VV_TYPES.ACTIONS.SAVE_SCHEMA_VERSION, { versionId: versionId })
        .then(() => axios.post(rootState.core.apiAddress + `/vv/zielmarktdatenUebernehmen?versionId=${versionId}`, state.strategienSchemaData, config))
        .then(response => commit(VV_TYPES.MUTATIONS.GET_STRATEGIEN_SCHEMA_DATA_SUCCESS, response.data))
    }
  },

  [VV_TYPES.ACTIONS.UPLOAD_SCHEMA_DOCUMENT]({ rootState, commit }, payload) {
    return axios.post(rootState.core.apiAddress + `/vv/uploadSchemaDocument?schemaFileId=${payload?.schemaFileId}`, payload, config)
      .then(response => commit(VV_TYPES.MUTATIONS.UPLOAD_SCHEMA_DOCUMENT_SUCCESS, response.data))
  },

  [VV_TYPES.ACTIONS.OPEN_SCHEMA_DOCUMENT_PDF]({ rootState }, schemaFileId) {
    return axios.get(rootState.core.apiAddress + `/vv/openSchemaDocumentPdf?schemaFileId=${schemaFileId}`, config)
  },

  [VV_TYPES.ACTIONS.VERSION_STATUSCHANGE_CHECK]({ rootState }, payload) {
    return axios.get(rootState.core.apiAddress + `/vv/changeVersionStatusMessage?versionId=${payload.versionId}&newStatus=${payload.newStatus}`, config)
  },

  [VV_TYPES.ACTIONS.VERSION_STATUSCHANGE]({ rootState, commit }, payload) {
    return axios.get(rootState.core.apiAddress + `/vv/changeVersionStatus?versionId=${payload.versionId}&newStatus=${payload.newStatus}`, config)
      .then(response => commit(VV_TYPES.MUTATIONS.GET_STRATEGIEN_SCHEMA_DATA_SUCCESS, Object.assign(response.data, { isUpdateSubstep: true })))
  },

  [VV_TYPES.ACTIONS.MAKE_NEW_VERSION]({ rootState, dispatch, commit }, data = {}) {
    if (data.schemaId) {
      return dispatch(VV_TYPES.ACTIONS.SAVE_SCHEMA, { schemaId: data.schemaId, stepKey: data.stepKey })
        .then(() => axios.post(rootState.core.apiAddress + `/vv/makeNewVersion?schemaId=${data.schemaId}`, config))
        .then(response => commit(VV_TYPES.MUTATIONS.GET_STRATEGIEN_SCHEMA_DATA_SUCCESS, Object.assign(response.data, { isUpdateSubstep: true })))
    }
  },

  [VV_TYPES.ACTIONS.DELETE_SCHEMA_VERSION]({ rootState, commit }, versionId) {
    if (versionId) {
      return axios.delete(rootState.core.apiAddress + `/vv/deleteSchemaVersion?versionId=${versionId}`, config)
        .then(response => commit(VV_TYPES.MUTATIONS.GET_STRATEGIEN_SCHEMA_DATA_SUCCESS, Object.assign(response.data, { isUpdateSubstep: true })))
    }
  },

  [VV_TYPES.ACTIONS.CLONE_SCHEMA_VERSION]({ rootState, dispatch, commit }, data) {
    if (data?.versionId) {
      return dispatch(VV_TYPES.ACTIONS.SAVE_SCHEMA_VERSION, { versionId: data.versionId })
        .then(() => axios.post(rootState.core.apiAddress + `/vv/cloneVariante?versionId=${data.versionId}`, data, config))
        .then(response => commit(VV_TYPES.MUTATIONS.GET_STRATEGIEN_SCHEMA_DATA_SUCCESS, Object.assign(response.data, { isUpdateSubstep: true })));
    }
  },

  [VV_TYPES.ACTIONS.SAVE_COMMENT]({ rootState }, { id, comment }) {
    return axios.post(rootState.core.apiAddress + `/vv/saveComment?id=${id}&comment=${comment}`, config);
  },

  [VV_TYPES.ACTIONS.GET_ARCHIV_LIST]({ rootState, commit }, schemaId) {
    return axios.get(rootState.core.apiAddress + `/vv/getArchiv?schemaId=${schemaId}`, config)
      .then(response => commit(VV_TYPES.MUTATIONS.GET_ARCHIV_LIST_SUCCESS, response.data))
  },

  [VV_TYPES.ACTIONS.GET_ARCHIV_VARIANTE]({ rootState }, versionId) {
    return axios.get(rootState.core.apiAddress + `/vv/getArchivVariante?versionId=${versionId}`, config)
  },

  [VV_TYPES.ACTIONS.GET_HOCHGELADENE_FACTSCHEET_PDF]({ rootState }, factsheetKagId) {
    return axios.get(rootState.core.apiAddress + `/vv/getHochgeladeneFactsheetPdf?factsheetId=${factsheetKagId}`, config)
  },

  [VV_TYPES.ACTIONS.MOVE_VARIANTE_INTO_ARCHIV]({ rootState, commit }, versionId) {
    if (versionId) {
      return axios.get(rootState.core.apiAddress + `/vv/moveVarianteIntoArchive?versionId=${versionId}`, config)
        .then(response => commit(VV_TYPES.MUTATIONS.GET_STRATEGIEN_SCHEMA_DATA_SUCCESS, Object.assign(response.data, { isUpdateSubstep: true })))
    }
  },

  [VV_TYPES.ACTIONS.GET_PDF_MANUAL]({ rootState }, params) {
    return axios.get(rootState.core.apiAddress + `/vv/getPdfManual?schemaId=${params?.schemaId}`, config)
  },

  [VV_TYPES.ACTIONS.CONVERT_PDF]({ rootState }, params) {
    if (params?.schemaId) {
      return axios.post(rootState.core.apiAddress + `/vv/convertPdf?schemaId=${params.schemaId}`, params, config)
    }
  },

  [VV_TYPES.ACTIONS.GET_PDF_FACTSHEET_VV_CALC]({ rootState }, params) {
    return axios.get(rootState.core.apiAddress + `/vv/getFactsheetVVCalc?schemaId=${params?.schemaId}`, config)
  },

  [VV_TYPES.ACTIONS.FACTSHEET_HISTORY]({ rootState }, schemaId) {
    if (schemaId) {
      return axios.get(rootState.core.apiAddress + `/vv/getFactsheetHistory?schemaId=${schemaId}`, config)
    }
  },

  [VV_TYPES.ACTIONS.GET_PDF_FACTSHEET]({ rootState }, params) {
    return axios.get(rootState.core.apiAddress + `/vv/getFactsheet?dokId=${params?.dokId}`, config)
  },

  [VV_TYPES.ACTIONS.GET_PDF_WK_KUNDE_DEPOTAUSZUG]({ rootState }, params) {
    return axios.get(rootState.core.apiAddress + `/vv/getPdfWkKundeDepotauszug?schemaId=${params?.schemaId}`, config)
  },

  [VV_TYPES.ACTIONS.DELETE_SCHEMA_DOCUMENT]({ rootState, dispatch, commit }, schemaFileId) {
    if (schemaFileId) {
      return axios.delete(rootState.core.apiAddress + `/vv/deleteSchemaDocument?schemaFileId=${schemaFileId}`, config)
        .then(response => {
          if (response.data?.errorStr) {
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.errorStr, 'danger'));
          } else {
            commit(VV_TYPES.MUTATIONS.UPLOAD_SCHEMA_DOCUMENT_SUCCESS, response.data);
          }
        })
    }
  },

  [VV_TYPES.ACTIONS.SAVE_BESCHWERDE]({ rootState, commit }, payload) {
    return axios.post(rootState.core.apiAddress + `/vv/saveBeschwerde`, payload, config)
      .then(response => commit(VV_TYPES.MUTATIONS.SAVE_BESCHWERDE_SUCCESS, response?.data))
  },

  [VV_TYPES.ACTIONS.GET_COMBO_VALUES_VERSIONEN_PREVIOUS]({ rootState }, { schemaId, versionAktuell }) {
    if (schemaId && versionAktuell) {
      return axios.get(rootState.core.apiAddress + `/vv/getComboValuesVersionenPrevious?schemaId=${schemaId}&versionAktuell=${versionAktuell}`, config)
    }
  },

  [VV_TYPES.ACTIONS.UPDATE_VERWAHRSTELLE_SETTINGS]({ rootState, commit }, { verwahrstelle }) {
    return axios.get(rootState.core.apiAddress + `/vv/getVerwahrstelleSettings?verwahrstelle=${verwahrstelle}`, config)
      .then(response => commit(VV_TYPES.MUTATIONS.UPDATE_VERWAHRSTELLE_SETTINGS, response.data))
  },
  [VV_TYPES.ACTIONS.GET_GEBUEHREN_MODELL_DATA]({ rootState }, { gebuehrenModell }) {
    return axios.get(rootState.core.apiAddress + `/vv/getGebuehrenModellData?gebuehrenModell=${gebuehrenModell}`, config)
  },

  [VV_TYPES.ACTIONS.NEW_FACTSHEET]({ rootState }, schemaId) {
    if (schemaId) {
      return axios.get(rootState.core.apiAddress + `/vv/newFactsheet?schemaId=${schemaId}`, config)
    }
  },

  [VV_TYPES.ACTIONS.GET_MISSING_LEGITIMATIONS]({ rootState, commit }) {
    axios.get(rootState.core.apiAddress + '/vv/missingMaklerLegitimations', config).then(response => {
      commit(VV_TYPES.MUTATIONS.GET_MISSING_LEGITIMATIONS_SUCCESS, response?.data);
    })
  },
  [VV_TYPES.ACTIONS.GET_SUITABILITYRESULTLIST]({ rootState, commit }, queryParam) {
    return axios.get(rootState.core.apiAddress + "/vv/suitabilityResultList", { ...config, params: queryParam }).then(response => {
      if (response?.data) {
        commit(VV_TYPES.MUTATIONS.GET_SUITABILITYRESULTLIST_SUCCESS, response.data);
      }
    })
  },
  [VV_TYPES.ACTIONS.GET_SUITABILITYRESULT]({ rootState, commit }, queryParams) {
    return axios.get(rootState.core.apiAddress + "/vv/suitabilityResult", { ...config, params: queryParams }).then(response => {
      if (response?.data) {
        commit(VV_TYPES.MUTATIONS.GET_SUITABILITYRESULT_SUCCESS, response.data);
      }
    })
  },
  [VV_TYPES.ACTIONS.GET_SUITABILITYSIGNATURES]({ rootState, commit }, queryParams) {
    commit(VV_TYPES.MUTATIONS.GET_SUITABILITYSIGNATURES_SUCCESS, {});
    return axios.get(rootState.core.apiAddress + "/vv/suitabilitySignatures", { ...config, params: queryParams }).then(response => {
      if (response?.data) {
        commit(VV_TYPES.MUTATIONS.GET_SUITABILITYSIGNATURES_SUCCESS, response.data);
      }
    })
  },
  [VV_TYPES.ACTIONS.POST_SUITABILITYRESULT]({ rootState }, queryParams) {
    return axios.post(rootState.core.apiAddress + "/vv/suitabilityResult", null, { ...config, params: queryParams });
  }

}