import { mapGetters } from 'vuex';

import { getStepVisible, isStepHidden } from '@/components/antrag/antrag-utils';
import { removeBackActionFromQuery } from '@/router/breadcrumb/index';
import ANTRAG_TYPES from '@/store/antrag/types'

const NEXT_DIR = 'NEXT';
const PREV_DIR = 'PREV';

const mixin = {
  data() {
    return {
      lastAntragStepDirClicked: null,
    };
  },
  computed: {
    ...mapGetters({
      saveAntragState: ANTRAG_TYPES.GETTERS.SAVE_ANTRAG_STATE,
    }),
    bachToPreviousFormLink() {
      return this.antrag && this.antrag.backToPreviousAntrag && this.antrag.backToPreviousAntrag.bachToPreviousFormLink;
    },
    backToPreviousFormLabel() {
      return this.antrag && this.antrag.backToPreviousAntrag && this.antrag.backToPreviousAntrag.backToPreviousFormLabel;
    },
    hasBachToPreviousFormLink() {
      return !!this.bachToPreviousFormLink;
    },
    beratungsMappeData() {
      return this.antrag && this.antrag.beratungsMappeData;
    },
    hasBeratungsData() {
      return this.beratungsMappeData && this.beratungsMappeData.id && this.beratungsMappeData.art && this.beratungsMappeData.date;
    },
    antragStepVisible() {
      if (!this.antragStep && !this.steps) {
        return null;
      }

      const completeStepList = this.antragStep || this.steps
      const resultList = getStepVisible(completeStepList, this.antragData)
      return resultList
    },
    currentAntragStepIndex() {
      const routeStep = this.$route.params.step;
      const { antragStepVisible, } = this;
      return antragStepVisible 
        && Array.isArray(antragStepVisible) 
        && antragStepVisible.map(st => st.stepKey).indexOf(routeStep) 
        || -1;
    },
    hasPreviousAntragStep() {
      return this.currentAntragStepIndex > 0;
    },
    isLoadingPreviousAntragStep() {
      return this.saveAntragState && this.lastAntragStepDirClicked === PREV_DIR;
    },
    hasNextAntragStep() {
      const { antragStepVisible } = this;
      return antragStepVisible && this.currentAntragStepIndex < antragStepVisible.length - 1;
    },
    isLoadingNextAntragStep() {
      return this.saveAntragState && this.lastAntragStepDirClicked === NEXT_DIR;
    },
    currentAntragStepTitle() {
      const routeStep = this.$route.params.step;
      const routeSubstep = this.$route.params.substep;
      const { antragStepVisible } = this;

      const currentStep = antragStepVisible?.find?.(st => st.stepKey === routeStep) || {};
      const currentSubStep = routeSubstep && currentStep?.substeps?.find?.(sst => sst.substepKey === routeSubstep) || {};
      return currentSubStep.label || currentStep.label || '';
    },
  },
  watch: {
    saveAntragState(currentSaveAntragState) {
      if (!currentSaveAntragState) {
        this.lastAntragStepDirClicked = null;
      }
    },
  },
  methods: {
    navigateToFirstStep() {
      if (!this.routeStep && Array.isArray(this.steps) && this.steps.length) {
        const nextStep = this.antragStepVisible ? this.antragStepVisible[0] : this.steps[0];
        this.navigateToStep(nextStep.stepKey);
      }
    },
    navigateNextStep() {
      if (Array.isArray(this.antragStepVisible) && this.antragStepVisible.length) {
        const routeStep = this.$route.params.step;
        const routeSubStep = this.$route.params.substep;
        this.lastAntragStepDirClicked = NEXT_DIR;

        if (!routeStep && !routeSubStep) {
          this.navigateToFirstStep();
          return;
        }

        const currentStepIndex = this.antragStepVisible.map(st => st.stepKey).indexOf(routeStep)
        const currentStep = this.antragStepVisible[currentStepIndex]
        const nextStepIndex = currentStepIndex + 1;
        const nextStep = this.antragStepVisible[nextStepIndex];

        // Check if out current step has substeps
        if (routeSubStep) {
          const currentSubStepIndex = currentStep.substeps.map(sst => sst.substepKey).indexOf(routeSubStep)
          const nextSubStepIndex = currentSubStepIndex + 1;
          const nextSubStep = currentStep.substeps[nextSubStepIndex]

          // If the current substep has a nextSubStep, go to that substep
          if (nextSubStep) {
            this.navigateToSubstep({
              stepKey: currentStep.stepKey, 
              substepKey: nextSubStep.substepKey});

          // If not, go to the next step (If that step has substeps it will automatically jump into the first substep)
          } else if (nextStep){
            this.navigateToStep(nextStep.stepKey);
          }

          // Current step has no substeps -> Go to next step as usual
        } else if (nextStep){
          this.navigateToStep(nextStep.stepKey);
        }
      }
    },
    navigatePreviousStep() {
      if (Array.isArray(this.antragStepVisible) && this.antragStepVisible.length) {
        const routeStep = this.$route.params.step;
        const routeSubStep = this.$route.params.substep;
        this.lastAntragStepDirClicked = PREV_DIR;

        if (!routeStep && !routeSubStep) {
          this.navigateToFirstStep();
          return;
        }

        const currentStepIndex = this.antragStepVisible.map(st => st.stepKey).indexOf(routeStep)
        const currentStep = this.antragStepVisible[currentStepIndex]
        const previousStepIndex = currentStepIndex - 1;
        const previousStep = this.antragStepVisible[previousStepIndex];

        // Check if the current step has substeps
         if (routeSubStep) {
          const currentSubStepIndex = currentStep.substeps.map(sst => sst.substepKey).indexOf(routeSubStep)
          const previousSubStepIndex = currentSubStepIndex - 1;
          const previousSubStep = currentStep.substeps[previousSubStepIndex]

          // If the current substep has a previousSubStep, go to that substep
          if (previousSubStep) {
            this.navigateToSubstep({
              stepKey: currentStep.stepKey, 
              substepKey: previousSubStep.substepKey});

          // If not, check if previous step has substeps -> If yes, go to the last substep of his substep list
          } else if (previousStep && previousStep.substeps && previousStep.substeps.length){
            this.navigateToSubstep({
              stepKey: previousStep.stepKey, 
              substepKey: previousStep.substeps[previousStep.substeps.length - 1].substepKey});

          // Else go to the previous Step as usual
          } else if (previousStep) {
            this.navigateToStep(previousStep.stepKey);
          }

        // If previous step has substeps -> go to the last substep of his substep list
        } else if (previousStep && previousStep.substeps && previousStep.substeps.length) {
          this.navigateToSubstep({
            stepKey: previousStep.stepKey, 
            substepKey: previousStep.substeps[previousStep.substeps.length - 1].substepKey});

        // Else go to the previous Step as usual
        } else if (previousStep){
          this.navigateToStep(previousStep.stepKey);
        }
      }
    },
    async navigateToStep(stepKey, enableSave = true) {
      if (this.steps && this.steps.find(st => st.stepKey === stepKey)?.substeps?.length > 0) {
        this.navigateToSubstep({
          stepKey: stepKey, 
          substepKey: this.steps.find(st => st.stepKey === stepKey).substeps[0].substepKey});
        return;
      } else if (this.antragStepVisible && this.antragStepVisible.find(st => st.stepKey === stepKey)?.substeps?.length > 0) {
        this.navigateToSubstep({
          stepKey: stepKey, 
          substepKey: this.antragStepVisible.find(st => st.stepKey === stepKey).substeps[0].substepKey});
        return;
      }
      let path = `/beratung/formulare/antrag/${this.$route.params.lagerstelle}/${this.$route.params.antragsName}/${stepKey}`
      if (this.$route.path.includes('wertpapierorder')) {
        path = `/beratung/formulare/antrag/WP/wertpapierorder/${stepKey}`;
      } else if (this.$route.path.includes('/vv/advisor-fragebogen/')) {
        path = `/beratung/vv/advisor-fragebogen/${this.$route.params.lagerstelle}/${this.$route.params.antragsName}/${stepKey}`;
      } 
      let query = this.$route.query || {}
      query = removeBackActionFromQuery(query)
      if (stepKey) {
        this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_HIGHEST_STEP, {id: this.antragId, stepKey});
        if (this.$route.path !== path) {
          if (enableSave) {
            await this.save()
          }
          await this.$router.push({ path, query }).catch(e => {});
        }
      }
    },
    async navigateToSubstep({stepKey, substepKey}) {
      let path = `/beratung/formulare/antrag/${this.$route.params.lagerstelle}/${this.$route.params.antragsName}/${stepKey}/${substepKey}`
      let query = this.$route.query || {}
      query = removeBackActionFromQuery(query)
      if (stepKey) {
        this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_HIGHEST_STEP, {id: this.antragId, stepKey});
        await this.save()
        if (this.$route.path !== path) {
          await this.$router.push({ path, query })
        }
      }
    },
    navigateToStepAntrag(stepkey){
      this.navigateToStep(stepkey)
    },
    async save(forceSave) {
      if (this.antragId && this.$route.params.lagerstelle) {
        if (forceSave) {
          const payload = {
            id: this.antragId,
            data: {}
          }
          this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_ANTRAG_DATA, payload)
        }
        let action = this.$route.params.step === 'aktionen' ? 'submit' : 'save'
        await this.$store.dispatch(ANTRAG_TYPES.ACTIONS.SAVE_ANTRAG, { id: this.antragId, lagerstelle: this.$route.params.lagerstelle, action: action })
      }
    },
    async navigateToFormsFinder(categoryId, query) {
      const path = `/beratung/formulare/antrag/${this.$route.params.lagerstelle}/${this.$route.params.antragsName}/${this.$route.params.substep ? this.$route.params.step + '/' + this.$route.params.substep : this.$route.params.step}/fondsfinder/${categoryId}`
      if (categoryId && this.$route.path !== path) {
        await this.$router.push({ path, query})
      }
    },
    async navigateToFormsFinderEditable(categoryId, query) {
      const path = `/beratung/formulare/antrag/${this.$route.params.lagerstelle}/${this.$route.params.antragsName}/${this.$route.params.substep ? this.$route.params.step + '/' + this.$route.params.substep : this.$route.params.step}/positionbearbeiten/${categoryId}`
      if (this.$route.path !== path) {
        await this.$router.push({ path, query})
      }
    },
    async navigateBackToCurrentStep() {
      const navigateTo = `/beratung/formulare/antrag/${this.$route.params.lagerstelle}/${this.$route.params.antragsName}/${this.$route.params.substep ? this.$route.params.step + '/' + this.$route.params.substep : this.$route.params.step}`
      if (this.$router.path !== navigateTo) {
        await this.$router.push({path: navigateTo, query: { avoidAntragLoading: true, backAction: true, }})
      }
    },
    async navigateBackToCurrentStepFromExternal() {
      let antragsName = this.$route.params.antragsName ?? 'wertpapierorder'
      const navigateTo = `/beratung/formulare/antrag/${this.$route.params.lagerstelle}/${antragsName}/${this.$route.params.substep ? this.$route.params.step + '/' + this.$route.params.substep : this.$route.params.step}`
      if (this.$router.path !== navigateTo) {
        await this.$router.push({path: navigateTo, query: { avoidAntragLoading: true, backAction: true }})
      }
    },
    async navigateToPreviousAntrag() {
      if(!this.hasBachToPreviousFormLink) {
        console.error('Error in navigateToPreviousAntrag: bachToPreviousFormLink not defined!');
        return ;
      }

      const beratungsMappeId = this.antrag.beratungsMappeData && this.antrag.beratungsMappeData.id;

      await this.$router.push({ 
        path: this.bachToPreviousFormLink,
        query: {
          beratungsMappeId,
        }
      })
    },
    async goToFormsFinder(categoryId, config, suppressFilterLagerstelle = false, editable = false) {
      
      if (!categoryId) {
        return
      }
      const query = {}
      if (config) {
        if (config.SINGLE_SELECTION) {
          query.singleSelection = true;
        }
        if (config.isDimensional) {
          query.isDimensional = !!config.isDimensional;
        }
        if (config.isETF) {
          query.isETF = !!config.isETF;
        }
        if (config.isImmobilien) {
          query.isImmobilien = !!config.isImmobilien;
        }
        if (config.query) {
          const jsonObj = JSON.parse(config.query);
          Object.assign(query, jsonObj);
        }
      }
      if (suppressFilterLagerstelle) {
        query.suppressFilterLagerstelle = suppressFilterLagerstelle;
      }
      
      if (editable) {
        await this.navigateToFormsFinderEditable(categoryId, query)
      } else {
        await this.navigateToFormsFinder(categoryId, query)
      }
    },
    async navigateToBeratungsPage() {
      if(!this.hasBeratungsData) {
        return;
      }

      await this.$router.push({
        path: '/beratung/beratung/bearbeiten', 
        query: {
          id: this.beratungsMappeData.id, 
          art: this.beratungsMappeData.art, 
          datum: this.beratungsMappeData.date
        }
      });
    },
  }
}

export default mixin;