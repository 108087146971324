//
// Autogenerated by Thrift Compiler (1.0.0-soscomp-2)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
import {Thrift} from "./thrift.js";


const SignalingResult = {
  'SUCCESS' : 0,
  'FAIL' : 1
};
const SignalingConnectionRole = {
  'CALLER_WEBRTC_CALL_RECEIVER' : 0,
  'CALLEE_WEBRTC_CALL_INITIATOR' : 1
};
const SignalingMessageId = {
  'REGISTRATION' : 0,
  'ICE_CANDIDATE' : 1,
  '_OBSOLETE_AUDIO_CONNECTION_READY' : 2,
  'ACCEPTED_BY_CALLEE' : 3,
  'REMOTE_VIDEO_ON' : 4,
  'REMOTE_VIDEO_OFF' : 5,
  'REMOTE_AUDIO_ON' : 6,
  'REMOTE_AUDIO_OFF' : 7,
  'REQUEST_VIDEO_UPGRADE' : 8,
  'CANCEL_VIDEO_UPGRADE' : 9,
  'CONFIRM_VIDEO_UPGRADE' : 10,
  'ACCEPT_VIDEO_UPGRADE' : 11,
  'REJECT_VIDEO_UPGRADE' : 12,
  'CAMERA_SWITCHED' : 13,
  'RECONNECT' : 14,
  'REOFFER' : 15,
  'SHOW_TEXT' : 16,
  'RESERVED_FOR_FUTURE_USE_1' : 17,
  'RESERVED_FOR_FUTURE_USE_2' : 18,
  'RESERVED_FOR_FUTURE_USE_3' : 19,
  'UPDATE_CALLEE' : 20
};
const WebSocketEndPoint = {
  'SIGNALER' : 0,
  'CLIENT' : 1
};
const SwspPayload = function(args) {
  this.values = null;
  if (args) {
    if (args.values !== undefined && args.values !== null) {
      this.values = args.values;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field values is unset!');
    }
  }
};
SwspPayload.prototype = {};
SwspPayload.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.MAP) {
        var _size0 = 0;
        var _rtmp34;
        this.values = {};
        var _ktype1 = 0;
        var _vtype2 = 0;
        _rtmp34 = input.readMapBegin();
        _ktype1 = _rtmp34.ktype;
        _vtype2 = _rtmp34.vtype;
        _size0 = _rtmp34.size;
        for (var _i5 = 0; _i5 < _size0; ++_i5)
        {
          if (_i5 > 0 ) {
            if (input.rstack.length > input.rpos[input.rpos.length -1] + 1) {
              input.rstack.pop();
            }
          }
          var key6 = null;
          var val7 = null;
          key6 = input.readI32().value;
          val7 = input.readString().value;
          this.values[key6] = val7;
        }
        input.readMapEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SwspPayload.prototype.write = function(output) {
  output.writeStructBegin('SwspPayload');
  if (this.values !== null && this.values !== undefined) {
    output.writeFieldBegin('values', Thrift.Type.MAP, 1);
    output.writeMapBegin(Thrift.Type.I32, Thrift.Type.STRING, Thrift.objectLength(this.values));
    for (var kiter8 in this.values)
    {
      if (this.values.hasOwnProperty(kiter8))
      {
        var viter9 = this.values[kiter8];
        output.writeI32(kiter8);
        output.writeString(viter9);
      }
    }
    output.writeMapEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

const SignalingMessageStopCommunication = function(args) {
  this.status = null;
  if (args) {
    if (args.status !== undefined && args.status !== null) {
      this.status = args.status;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field status is unset!');
    }
  }
};
SignalingMessageStopCommunication.prototype = {};
SignalingMessageStopCommunication.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.I32) {
        this.status = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SignalingMessageStopCommunication.prototype.write = function(output) {
  output.writeStructBegin('SignalingMessageStopCommunication');
  if (this.status !== null && this.status !== undefined) {
    output.writeFieldBegin('status', Thrift.Type.I32, 1);
    output.writeI32(this.status);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

const MessageFromClientToPusher = function(args) {
  this.dbPrefix = null;
  this.pushSocketId = null;
  if (args) {
    if (args.dbPrefix !== undefined && args.dbPrefix !== null) {
      this.dbPrefix = args.dbPrefix;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field dbPrefix is unset!');
    }
    if (args.pushSocketId !== undefined && args.pushSocketId !== null) {
      this.pushSocketId = args.pushSocketId;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field pushSocketId is unset!');
    }
  }
};
MessageFromClientToPusher.prototype = {};
MessageFromClientToPusher.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.dbPrefix = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.pushSocketId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

MessageFromClientToPusher.prototype.write = function(output) {
  output.writeStructBegin('MessageFromClientToPusher');
  if (this.dbPrefix !== null && this.dbPrefix !== undefined) {
    output.writeFieldBegin('dbPrefix', Thrift.Type.STRING, 1);
    output.writeString(this.dbPrefix);
    output.writeFieldEnd();
  }
  if (this.pushSocketId !== null && this.pushSocketId !== undefined) {
    output.writeFieldBegin('pushSocketId', Thrift.Type.STRING, 2);
    output.writeString(this.pushSocketId);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

const WebSocketCloseReasonPhrase = function(args) {
  this.dbPrefix = null;
  this.webRtcCallId = null;
  this.connectionRole = null;
  this.status = null;
  this.closingEndpoint = null;
  this.correspondentSignalingUUID = null;
  if (args) {
    if (args.dbPrefix !== undefined && args.dbPrefix !== null) {
      this.dbPrefix = args.dbPrefix;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field dbPrefix is unset!');
    }
    if (args.webRtcCallId !== undefined && args.webRtcCallId !== null) {
      this.webRtcCallId = args.webRtcCallId;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field webRtcCallId is unset!');
    }
    if (args.connectionRole !== undefined && args.connectionRole !== null) {
      this.connectionRole = args.connectionRole;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field connectionRole is unset!');
    }
    if (args.status !== undefined && args.status !== null) {
      this.status = args.status;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field status is unset!');
    }
    if (args.closingEndpoint !== undefined && args.closingEndpoint !== null) {
      this.closingEndpoint = args.closingEndpoint;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field closingEndpoint is unset!');
    }
    if (args.correspondentSignalingUUID !== undefined && args.correspondentSignalingUUID !== null) {
      this.correspondentSignalingUUID = args.correspondentSignalingUUID;
    }
  }
};
WebSocketCloseReasonPhrase.prototype = {};
WebSocketCloseReasonPhrase.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.dbPrefix = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I64) {
        this.webRtcCallId = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.connectionRole = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.I32) {
        this.status = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.I32) {
        this.closingEndpoint = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.correspondentSignalingUUID = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

WebSocketCloseReasonPhrase.prototype.write = function(output) {
  output.writeStructBegin('WebSocketCloseReasonPhrase');
  if (this.dbPrefix !== null && this.dbPrefix !== undefined) {
    output.writeFieldBegin('dbPrefix', Thrift.Type.STRING, 1);
    output.writeString(this.dbPrefix);
    output.writeFieldEnd();
  }
  if (this.webRtcCallId !== null && this.webRtcCallId !== undefined) {
    output.writeFieldBegin('webRtcCallId', Thrift.Type.I64, 2);
    output.writeI64(this.webRtcCallId);
    output.writeFieldEnd();
  }
  if (this.connectionRole !== null && this.connectionRole !== undefined) {
    output.writeFieldBegin('connectionRole', Thrift.Type.I32, 3);
    output.writeI32(this.connectionRole);
    output.writeFieldEnd();
  }
  if (this.status !== null && this.status !== undefined) {
    output.writeFieldBegin('status', Thrift.Type.I32, 4);
    output.writeI32(this.status);
    output.writeFieldEnd();
  }
  if (this.closingEndpoint !== null && this.closingEndpoint !== undefined) {
    output.writeFieldBegin('closingEndpoint', Thrift.Type.I32, 5);
    output.writeI32(this.closingEndpoint);
    output.writeFieldEnd();
  }
  if (this.correspondentSignalingUUID !== null && this.correspondentSignalingUUID !== undefined) {
    output.writeFieldBegin('correspondentSignalingUUID', Thrift.Type.STRING, 6);
    output.writeString(this.correspondentSignalingUUID);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

const MessageFromSignalerToClient = function(args) {
  this.messageId = null;
  this.messageCall = null;
  this.messageIceCandidate = null;
  this.webRtcCallId = null;
  this.showText = null;
  if (args) {
    if (args.messageId !== undefined && args.messageId !== null) {
      this.messageId = args.messageId;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field messageId is unset!');
    }
    if (args.messageCall !== undefined && args.messageCall !== null) {
      this.messageCall = args.messageCall;
    }
    if (args.messageIceCandidate !== undefined && args.messageIceCandidate !== null) {
      this.messageIceCandidate = args.messageIceCandidate;
    }
    if (args.webRtcCallId !== undefined && args.webRtcCallId !== null) {
      this.webRtcCallId = args.webRtcCallId;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field webRtcCallId is unset!');
    }
    if (args.showText !== undefined && args.showText !== null) {
      this.showText = args.showText;
    }
  }
};
MessageFromSignalerToClient.prototype = {};
MessageFromSignalerToClient.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.I32) {
        this.messageId = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.messageCall = new SignalingMessageCall();
        this.messageCall.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.messageIceCandidate = new SignalingMessageIceCandidate();
        this.messageIceCandidate.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.I64) {
        this.webRtcCallId = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRUCT) {
        this.showText = new SignalingMessageShowText();
        this.showText.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

MessageFromSignalerToClient.prototype.write = function(output) {
  output.writeStructBegin('MessageFromSignalerToClient');
  if (this.messageId !== null && this.messageId !== undefined) {
    output.writeFieldBegin('messageId', Thrift.Type.I32, 1);
    output.writeI32(this.messageId);
    output.writeFieldEnd();
  }
  if (this.messageCall !== null && this.messageCall !== undefined) {
    output.writeFieldBegin('messageCall', Thrift.Type.STRUCT, 3);
    this.messageCall.write(output);
    output.writeFieldEnd();
  }
  if (this.messageIceCandidate !== null && this.messageIceCandidate !== undefined) {
    output.writeFieldBegin('messageIceCandidate', Thrift.Type.STRUCT, 5);
    this.messageIceCandidate.write(output);
    output.writeFieldEnd();
  }
  if (this.webRtcCallId !== null && this.webRtcCallId !== undefined) {
    output.writeFieldBegin('webRtcCallId', Thrift.Type.I64, 7);
    output.writeI64(this.webRtcCallId);
    output.writeFieldEnd();
  }
  if (this.showText !== null && this.showText !== undefined) {
    output.writeFieldBegin('showText', Thrift.Type.STRUCT, 8);
    this.showText.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

const MessageFromClientToSignaler = function(args) {
  this.messageId = null;
  this.messageRegisterRequest = null;
  this.messageIceCandidate = null;
  this.dbPrefix = null;
  this.webRtcCallId = null;
  this.senderRtcUserId = null;
  this.connectionRole = null;
  this.messageReoffer = null;
  if (args) {
    if (args.messageId !== undefined && args.messageId !== null) {
      this.messageId = args.messageId;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field messageId is unset!');
    }
    if (args.messageRegisterRequest !== undefined && args.messageRegisterRequest !== null) {
      this.messageRegisterRequest = args.messageRegisterRequest;
    }
    if (args.messageIceCandidate !== undefined && args.messageIceCandidate !== null) {
      this.messageIceCandidate = args.messageIceCandidate;
    }
    if (args.dbPrefix !== undefined && args.dbPrefix !== null) {
      this.dbPrefix = args.dbPrefix;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field dbPrefix is unset!');
    }
    if (args.webRtcCallId !== undefined && args.webRtcCallId !== null) {
      this.webRtcCallId = args.webRtcCallId;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field webRtcCallId is unset!');
    }
    if (args.senderRtcUserId !== undefined && args.senderRtcUserId !== null) {
      this.senderRtcUserId = args.senderRtcUserId;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field senderRtcUserId is unset!');
    }
    if (args.connectionRole !== undefined && args.connectionRole !== null) {
      this.connectionRole = args.connectionRole;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field connectionRole is unset!');
    }
    if (args.messageReoffer !== undefined && args.messageReoffer !== null) {
      this.messageReoffer = args.messageReoffer;
    }
  }
};
MessageFromClientToSignaler.prototype = {};
MessageFromClientToSignaler.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.I32) {
        this.messageId = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.messageRegisterRequest = new SignalingMessageRegisterRequest();
        this.messageRegisterRequest.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.messageIceCandidate = new SignalingMessageIceCandidate();
        this.messageIceCandidate.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.dbPrefix = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.I64) {
        this.webRtcCallId = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRING) {
        this.senderRtcUserId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.I32) {
        this.connectionRole = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 13:
      if (ftype == Thrift.Type.STRUCT) {
        this.messageReoffer = new SignalingMessageReoffer();
        this.messageReoffer.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

MessageFromClientToSignaler.prototype.write = function(output) {
  output.writeStructBegin('MessageFromClientToSignaler');
  if (this.messageId !== null && this.messageId !== undefined) {
    output.writeFieldBegin('messageId', Thrift.Type.I32, 1);
    output.writeI32(this.messageId);
    output.writeFieldEnd();
  }
  if (this.messageRegisterRequest !== null && this.messageRegisterRequest !== undefined) {
    output.writeFieldBegin('messageRegisterRequest', Thrift.Type.STRUCT, 2);
    this.messageRegisterRequest.write(output);
    output.writeFieldEnd();
  }
  if (this.messageIceCandidate !== null && this.messageIceCandidate !== undefined) {
    output.writeFieldBegin('messageIceCandidate', Thrift.Type.STRUCT, 4);
    this.messageIceCandidate.write(output);
    output.writeFieldEnd();
  }
  if (this.dbPrefix !== null && this.dbPrefix !== undefined) {
    output.writeFieldBegin('dbPrefix', Thrift.Type.STRING, 6);
    output.writeString(this.dbPrefix);
    output.writeFieldEnd();
  }
  if (this.webRtcCallId !== null && this.webRtcCallId !== undefined) {
    output.writeFieldBegin('webRtcCallId', Thrift.Type.I64, 7);
    output.writeI64(this.webRtcCallId);
    output.writeFieldEnd();
  }
  if (this.senderRtcUserId !== null && this.senderRtcUserId !== undefined) {
    output.writeFieldBegin('senderRtcUserId', Thrift.Type.STRING, 8);
    output.writeString(this.senderRtcUserId);
    output.writeFieldEnd();
  }
  if (this.connectionRole !== null && this.connectionRole !== undefined) {
    output.writeFieldBegin('connectionRole', Thrift.Type.I32, 9);
    output.writeI32(this.connectionRole);
    output.writeFieldEnd();
  }
  if (this.messageReoffer !== null && this.messageReoffer !== undefined) {
    output.writeFieldBegin('messageReoffer', Thrift.Type.STRUCT, 13);
    this.messageReoffer.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

const SignalingMessageShowText = function(args) {
  this.durationMillis = null;
  this.messagePlainText = null;
  if (args) {
    if (args.durationMillis !== undefined && args.durationMillis !== null) {
      this.durationMillis = args.durationMillis;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field durationMillis is unset!');
    }
    if (args.messagePlainText !== undefined && args.messagePlainText !== null) {
      this.messagePlainText = args.messagePlainText;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field messagePlainText is unset!');
    }
  }
};
SignalingMessageShowText.prototype = {};
SignalingMessageShowText.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.I32) {
        this.durationMillis = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.messagePlainText = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SignalingMessageShowText.prototype.write = function(output) {
  output.writeStructBegin('SignalingMessageShowText');
  if (this.durationMillis !== null && this.durationMillis !== undefined) {
    output.writeFieldBegin('durationMillis', Thrift.Type.I32, 1);
    output.writeI32(this.durationMillis);
    output.writeFieldEnd();
  }
  if (this.messagePlainText !== null && this.messagePlainText !== undefined) {
    output.writeFieldBegin('messagePlainText', Thrift.Type.STRING, 2);
    output.writeString(this.messagePlainText);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

const SignalingClient = function(args) {
  this.clientArtId = null;
  this.version = null;
  if (args) {
    if (args.clientArtId !== undefined && args.clientArtId !== null) {
      this.clientArtId = args.clientArtId;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field clientArtId is unset!');
    }
    if (args.version !== undefined && args.version !== null) {
      this.version = args.version;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field version is unset!');
    }
  }
};
SignalingClient.prototype = {};
SignalingClient.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.I32) {
        this.clientArtId = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.DOUBLE) {
        this.version = input.readDouble().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SignalingClient.prototype.write = function(output) {
  output.writeStructBegin('SignalingClient');
  if (this.clientArtId !== null && this.clientArtId !== undefined) {
    output.writeFieldBegin('clientArtId', Thrift.Type.I32, 1);
    output.writeI32(this.clientArtId);
    output.writeFieldEnd();
  }
  if (this.version !== null && this.version !== undefined) {
    output.writeFieldBegin('version', Thrift.Type.DOUBLE, 2);
    output.writeDouble(this.version);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

const SignalingMessageRegisterRequest = function(args) {
  this.correspondent = null;
  this.__OBSOLETE_sitzungId = null;
  this.messageSdpOffer = null;
  this.client = null;
  if (args) {
    if (args.correspondent !== undefined && args.correspondent !== null) {
      this.correspondent = args.correspondent;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field correspondent is unset!');
    }
    if (args.__OBSOLETE_sitzungId !== undefined && args.__OBSOLETE_sitzungId !== null) {
      this.__OBSOLETE_sitzungId = args.__OBSOLETE_sitzungId;
    }
    if (args.messageSdpOffer !== undefined && args.messageSdpOffer !== null) {
      this.messageSdpOffer = args.messageSdpOffer;
    }
    if (args.client !== undefined && args.client !== null) {
      this.client = args.client;
    }
  }
};
SignalingMessageRegisterRequest.prototype = {};
SignalingMessageRegisterRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.correspondent = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.__OBSOLETE_sitzungId = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.messageSdpOffer = new SignalingMessageCall();
        this.messageSdpOffer.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.client = new SignalingClient();
        this.client.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SignalingMessageRegisterRequest.prototype.write = function(output) {
  output.writeStructBegin('SignalingMessageRegisterRequest');
  if (this.correspondent !== null && this.correspondent !== undefined) {
    output.writeFieldBegin('correspondent', Thrift.Type.STRING, 1);
    output.writeString(this.correspondent);
    output.writeFieldEnd();
  }
  if (this.__OBSOLETE_sitzungId !== null && this.__OBSOLETE_sitzungId !== undefined) {
    output.writeFieldBegin('__OBSOLETE_sitzungId', Thrift.Type.STRING, 2);
    output.writeString(this.__OBSOLETE_sitzungId);
    output.writeFieldEnd();
  }
  if (this.messageSdpOffer !== null && this.messageSdpOffer !== undefined) {
    output.writeFieldBegin('messageSdpOffer', Thrift.Type.STRUCT, 3);
    this.messageSdpOffer.write(output);
    output.writeFieldEnd();
  }
  if (this.client !== null && this.client !== undefined) {
    output.writeFieldBegin('client', Thrift.Type.STRUCT, 4);
    this.client.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

const SignalingMessageCall = function(args) {
  this.sdpOffer = null;
  this.result = null;
  this.sdpAnswer = null;
  if (args) {
    if (args.sdpOffer !== undefined && args.sdpOffer !== null) {
      this.sdpOffer = args.sdpOffer;
    }
    if (args.result !== undefined && args.result !== null) {
      this.result = args.result;
    }
    if (args.sdpAnswer !== undefined && args.sdpAnswer !== null) {
      this.sdpAnswer = args.sdpAnswer;
    }
  }
};
SignalingMessageCall.prototype = {};
SignalingMessageCall.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.sdpOffer = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.result = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.sdpAnswer = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SignalingMessageCall.prototype.write = function(output) {
  output.writeStructBegin('SignalingMessageCall');
  if (this.sdpOffer !== null && this.sdpOffer !== undefined) {
    output.writeFieldBegin('sdpOffer', Thrift.Type.STRING, 1);
    output.writeString(this.sdpOffer);
    output.writeFieldEnd();
  }
  if (this.result !== null && this.result !== undefined) {
    output.writeFieldBegin('result', Thrift.Type.I32, 2);
    output.writeI32(this.result);
    output.writeFieldEnd();
  }
  if (this.sdpAnswer !== null && this.sdpAnswer !== undefined) {
    output.writeFieldBegin('sdpAnswer', Thrift.Type.STRING, 3);
    output.writeString(this.sdpAnswer);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

const SignalingMessageReoffer = function(args) {
  this.sdpOffer = null;
  if (args) {
    if (args.sdpOffer !== undefined && args.sdpOffer !== null) {
      this.sdpOffer = args.sdpOffer;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field sdpOffer is unset!');
    }
  }
};
SignalingMessageReoffer.prototype = {};
SignalingMessageReoffer.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.sdpOffer = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SignalingMessageReoffer.prototype.write = function(output) {
  output.writeStructBegin('SignalingMessageReoffer');
  if (this.sdpOffer !== null && this.sdpOffer !== undefined) {
    output.writeFieldBegin('sdpOffer', Thrift.Type.STRING, 1);
    output.writeString(this.sdpOffer);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

const SignalingMessageIceCandidate = function(args) {
  this.candidate = null;
  this.sdpMid = null;
  this.sdpMLineIndex = null;
  this.messageSdpAnswer = null;
  if (args) {
    if (args.candidate !== undefined && args.candidate !== null) {
      this.candidate = args.candidate;
    }
    if (args.sdpMid !== undefined && args.sdpMid !== null) {
      this.sdpMid = args.sdpMid;
    }
    if (args.sdpMLineIndex !== undefined && args.sdpMLineIndex !== null) {
      this.sdpMLineIndex = args.sdpMLineIndex;
    }
    if (args.messageSdpAnswer !== undefined && args.messageSdpAnswer !== null) {
      this.messageSdpAnswer = args.messageSdpAnswer;
    }
  }
};
SignalingMessageIceCandidate.prototype = {};
SignalingMessageIceCandidate.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.candidate = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.sdpMid = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.sdpMLineIndex = input.readI32().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.messageSdpAnswer = new SignalingMessageCall();
        this.messageSdpAnswer.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SignalingMessageIceCandidate.prototype.write = function(output) {
  output.writeStructBegin('SignalingMessageIceCandidate');
  if (this.candidate !== null && this.candidate !== undefined) {
    output.writeFieldBegin('candidate', Thrift.Type.STRING, 1);
    output.writeString(this.candidate);
    output.writeFieldEnd();
  }
  if (this.sdpMid !== null && this.sdpMid !== undefined) {
    output.writeFieldBegin('sdpMid', Thrift.Type.STRING, 2);
    output.writeString(this.sdpMid);
    output.writeFieldEnd();
  }
  if (this.sdpMLineIndex !== null && this.sdpMLineIndex !== undefined) {
    output.writeFieldBegin('sdpMLineIndex', Thrift.Type.I32, 3);
    output.writeI32(this.sdpMLineIndex);
    output.writeFieldEnd();
  }
  if (this.messageSdpAnswer !== null && this.messageSdpAnswer !== undefined) {
    output.writeFieldBegin('messageSdpAnswer', Thrift.Type.STRUCT, 4);
    this.messageSdpAnswer.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

const SignalingMessageUpdateCallee = function(args) {
  this.calleeChatBeteiligterId = null;
  this.calleeName = null;
  this.userBildURL = null;
  if (args) {
    if (args.calleeChatBeteiligterId !== undefined && args.calleeChatBeteiligterId !== null) {
      this.calleeChatBeteiligterId = args.calleeChatBeteiligterId;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field calleeChatBeteiligterId is unset!');
    }
    if (args.calleeName !== undefined && args.calleeName !== null) {
      this.calleeName = args.calleeName;
    } else {
      throw new Thrift.TProtocolException(Thrift.TProtocolExceptionType.UNKNOWN, 'Required field calleeName is unset!');
    }
    if (args.userBildURL !== undefined && args.userBildURL !== null) {
      this.userBildURL = args.userBildURL;
    }
  }
};
SignalingMessageUpdateCallee.prototype = {};
SignalingMessageUpdateCallee.prototype.read = function(input) {
  input.readStructBegin();
  while (true)
  {
    var ret = input.readFieldBegin();
    var fname = ret.fname;
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid)
    {
      case 1:
      if (ftype == Thrift.Type.I64) {
        this.calleeChatBeteiligterId = input.readI64().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.calleeName = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.userBildURL = input.readString().value;
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SignalingMessageUpdateCallee.prototype.write = function(output) {
  output.writeStructBegin('SignalingMessageUpdateCallee');
  if (this.calleeChatBeteiligterId !== null && this.calleeChatBeteiligterId !== undefined) {
    output.writeFieldBegin('calleeChatBeteiligterId', Thrift.Type.I64, 1);
    output.writeI64(this.calleeChatBeteiligterId);
    output.writeFieldEnd();
  }
  if (this.calleeName !== null && this.calleeName !== undefined) {
    output.writeFieldBegin('calleeName', Thrift.Type.STRING, 2);
    output.writeString(this.calleeName);
    output.writeFieldEnd();
  }
  if (this.userBildURL !== null && this.userBildURL !== undefined) {
    output.writeFieldBegin('userBildURL', Thrift.Type.STRING, 3);
    output.writeString(this.userBildURL);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

const PUSH_REQUEST_POST_HEADER_SHAERED_SECRET = 'soscomp-auth';
const PUSH_REQUEST_POST_HEADER_PUSH_SOCKET_ID = 'soscomp-push-socket-id';
const CALL_REGISTRATION_POST_HEADER_SHARED_SECRET = 'soscomp-auth';
const CALL_REGISTRATION_POST_PARAMETER_CALL_ID = 'call-id';
const CALL_REGISTRATION_POST_PARAMETER_RECORDING_DISABLED = 'recording-disabled';
const CALL_REGISTRATION_POST_PARAMETER_RECORD_ON_WEB_CALLEE = 'record-on-web-callee';
const CALL_REGISTRATION_POST_PARAMETER_RECORD_ON_APP_CALLEE = 'record-on-app-callee';
const START_RECORDING_POST_HEADER_SHARED_SECRET = 'soscomp-auth';
const START_RECORDING_POST_PARAMETER_CALL_ID = 'call-id';
const ACCEPT_CALL_TIMEOUT_SECONDS = 300;
const RECONNECT_TIMEOUT_SECONDS = 15;
const START_CALL_TIMEOUT_SECONDS = 5;
const ICE_NEGOTIATION_TIMEOUT = 45;




export {WebSocketEndPoint, SignalingMessageRegisterRequest, SignalingMessageCall, MessageFromClientToPusher, MessageFromClientToSignaler, MessageFromSignalerToClient, WebSocketCloseReasonPhrase, SignalingConnectionRole, SignalingMessageIceCandidate, SwspPayload, SignalingMessageId};
