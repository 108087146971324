var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: "DataControl Synchronisation" }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("FormLabel", {
            attrs: {
              label: "Synchronisation DataControl mit smartMSC",
              config: { bold: true }
            }
          }),
          _vm._v(" " + _vm._s(_vm.lbMessage) + " "),
          _c("div", { staticClass: "box_container" }, [
            _c(
              "div",
              { staticClass: "row justify-content-center mt-3" },
              [
                _c("ComboBox", {
                  attrs: { label: "Mandat", values: _vm.mandatValues },
                  model: {
                    value: _vm.mandat,
                    callback: function($$v) {
                      _vm.mandat = $$v
                    },
                    expression: "mandat"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "row justify-content-center mt-3" },
              [
                _c(
                  "BaseButton",
                  {
                    on: {
                      click: function($event) {
                        return _vm.vertraegeSenden()
                      }
                    }
                  },
                  [
                    _vm._v(" Verträge an DCS senden "),
                    _vm.isLoadingVetraegeAn ? _c("AnimatedSpinner") : _vm._e()
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "row justify-content-center mt-3" },
              [
                _c(
                  "BaseButton",
                  {
                    on: {
                      click: function($event) {
                        return _vm.vertraegeAbholen()
                      }
                    }
                  },
                  [
                    _vm._v(" Verträge von DCS abholen "),
                    _vm.isLoadingVetraegeVon ? _c("AnimatedSpinner") : _vm._e()
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "row justify-content-center mt-3" },
              [
                _c(
                  "BaseButton",
                  {
                    on: {
                      click: function($event) {
                        return _vm.getProvisionAbrechnungen()
                      }
                    }
                  },
                  [
                    _vm._v(" Provisionabrechnungen von DCS abholen"),
                    _vm.isLoadingProv ? _c("AnimatedSpinner") : _vm._e()
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "row justify-content-center mt-3" },
              [
                _c(
                  "BaseButton",
                  {
                    staticClass: "mt-5",
                    on: {
                      click: function($event) {
                        return _vm.zuordnungsListeAufrufen()
                      }
                    }
                  },
                  [
                    _c("span", [
                      _vm._v("Data Control Zuordnungsliste aufrufen")
                    ])
                  ]
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _c("BaseModal", {
        ref: "infoModal",
        attrs: { showDefaultButtons: false },
        scopedSlots: _vm._u([
          {
            key: "modalTitle",
            fn: function() {
              return [
                _c("PhInfo", {
                  staticStyle: { color: "var(--color-success)" }
                }),
                _vm._v(" " + _vm._s(_vm.infoText) + " ")
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("BaseModal", {
        ref: "errorModal",
        attrs: { showDefaultButtons: false },
        scopedSlots: _vm._u([
          {
            key: "modalTitle",
            fn: function() {
              return [
                _c("PhWarning", {
                  staticStyle: { color: "var(--color-danger)" }
                }),
                _vm._v(" Fehler beim Export an DC ")
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }