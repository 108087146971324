var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("NoData", {
    attrs: { noIcon: "", useBoxContainer: "" },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function() {
          return [
            _c("div", { staticClass: "mb-3" }, [
              _vm._v("Bitte einen Fondsvergleich starten")
            ]),
            _c(
              "div",
              [
                _c(
                  "BaseButton",
                  {
                    on: {
                      click: function($event) {
                        return _vm.$emit("click")
                      }
                    }
                  },
                  [_vm._v("zu den Einstellungen")]
                )
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }