import { StepperMediator } from '@/components/stepper/utils';
import WERTPAPIERORDER_TYPES from '@/store/wertpapierorder/types';
import TAN_TYPES from '@/store/tan/types';
import validator from "@/mixins/validator";
import { mapGetters } from "vuex";
import CORE_TYPES from '@/store/core/types';
import CUSTOMERDATA_TYPES from '@/store/customerData/types';
import ANTRAG_TYPES from '@/store/antrag/types';

export const FREIE_EINGABE = 'freie Eingabe:'
export const PATH_CUSTOMER = '/beratung/wertpapierorder/';
export const PATH_BROKER = '/beratung/formulare/antrag/WP/wertpapierorder/';

const categories = {
    NEU_KAUF: {
        id: 'NEU_KAUF',
        title: 'Eine neue Position kaufen',
        titleExtend: 'Neukauf für ',
        label: 'Neuer Kauf',
        letter: 'K',
        icon: 'fa fa-plus',
        operation: 'buy'
    },
    NEU_SPARPLAN: {
        id: 'NEU_SPARPLAN',
        title: 'Einen neuen Sparplan erstellen',
        titleExtend: 'Neuer Sparplan für ',
        label: 'Neuer Sparplan',
        letter: 'S',
        icon: 'fa fa-plus',
        operation: 'buy'
    },
    ALT_KAUF: {
        id: 'ALT_KAUF',
        title: 'Eine bestehende Position nachkaufen',
        titleExtend: 'Nachkauf von ',
        label: 'Nachkauf',
        letter: 'K',
        icon: 'fa fa-plus',
        operation: 'buy'
    },
    ALT_VERKAUF: {
        id: 'ALT_VERKAUF',
        title: 'Eine bestehende Position/Gesamtbestand verkaufen',
        titleExtend: 'Verkauf von ',
        label: 'Verkauf',
        letter: 'V',
        icon: 'fa fa-times',
        operation: 'sell'
    },
    ALT_VERKAUF_ALLE_ANTEILE: {
        id: 'ALT_VERKAUF_ALLE_ANTEILE',
        title: 'Alle Bestände verkaufen',
        titleExtend: 'Verkauf des gesamten Bestandes von ',
        label: 'Alle Bestände',
        letter: '',
        icon: 'fa fa-times',
        operation: 'sell'
    },
    ALT_TAUSCH: {
        id: 'ALT_TAUSCH',
        title: 'Eine bestehende Position tauschen',
        titleExtend: 'Tausch von ',
        label: 'Fondsumschichtung',
        letter: 'U',
        icon: 'fa fa-retweet',
        operation: ''
    },
    ALT_SPARPLAN: {
        id: 'ALT_SPARPLAN',
        title: 'Einen bestehenden Sparplan bearbeiten',
        titleExtend: 'Sparplan für ',
        label: 'Sparplan',
        letter: 'S',
        icon: 'fa fa-pencil',
        operation: 'buy'
    },
    ALT_ENTNAHMEPLAN: {
        id: 'ALT_ENTNAHMEPLAN',
        title: 'Einen bestehenden Entnahmeplan bearbeiten',
        titleExtend: 'Entnahmeplan für ',
        label: 'Entnahmeplan',
        letter: 'E',
        icon: 'fa fa-pencil',
        operation: 'sell'
    },
    LIMITAENDERUNG: {
        id: 'LIMITAENDERUNG',
        title: 'Limit einer Börsenorder ändern',
        titleExtend: 'Limitänderung für ',
        label: 'Limitänderung',
        letter: 'LÄ',
        icon: 'fa fa-pencil',
        operation: ''
    },
    STREICHUNG: {
        id: 'STREICHUNG',
        title: 'Einen bestehende Börsenorder streichen',
        titleExtend: 'Streichung von ',
        label: 'Streichung',
        letter: 'ST',
        icon: 'fa fa-pencil',
        operation: ''
    },
};
const betragModus = {
    VALUE_BETRAG_MODUS_ALLE_ANTEILE: 'Alle Anteile',
    VALUE_BETRAG_MODUS_EURO: 'Euro',
    VALUE_BETRAG_MODUS_ANTEILE: 'Anteile',
    VALUE_BETRAG_MODUS_CHF: 'CHF',
    VALUE_BETRAG_MODUS_PROZENT: '% der Anlagesumme',
};
const radioErklaerung = [{ label: 'Keine Auswahl treffen', value: 'NICHTS'},
    { label: 'Der Verkaufsprospekt (inklusive Vertragsbedingungen, '
  + 'Jahresbericht bzw. aktueller Halbjahresbericht), die Basisinformation über die Vermögensanlage '
  + 'in Investmentfonds und Hinweise zur Widerrufsbelehrung gemäß § 305 KAGB wurden mir zur Verfügung gestellt.', value: 'ERHALTEN'},
  { label: 'Ich verzichte auf den Verkaufsprospekt, den Jahresbericht '
  + 'bzw. aktuellen Halbjahresbericht, sowie die Basisinformationen über die Vermögensanlage in '
  + 'Investmentfonds. Die Unterlagen können kostenlos bei der jeweiligen Kapitalanlagegesellschaft '
  + 'angefordert werden. Hiermit bestätige ich, dass ich anlage- und anlegerbezogene Informationen '
  + 'erhalten habe und hinsichtlich dieses Auftrags anlage- und anlegerbezogen aufgeklärt wurde.', value: 'VERZICHTE' }];
  const radioMaklerunterschrift = [{ label: 'Anzeigen', value: 'Ja'},
  { label: 'Nicht anzeigen (falls der Ausgabeaufschlag reduziert wird, wird das Feld trotzdem angezeigt)', value: 'Nein' }];
  const radioAusgabeaufschlag = [{ label: 'Ja', value: 'Ja'},
  { label: 'Nein (auch nicht wenn der Ausgabeaufschlag reduziert wurde)', value: 'Nein' }];
  const faVisibleCategories = {
      CAPITALBANK: ['ALT_KAUF', 'ALT_SPARPLAN', 'ALT_TAUSCH', 'ALT_VERKAUF'], 
      HELLOBANK: ['ALT_KAUF', 'ALT_SPARPLAN', 'ALT_TAUSCH', 'ALT_VERKAUF', 'ALT_ENTNAHMEPLAN'],
      CONSTANTIA: ['ALT_KAUF', 'ALT_SPARPLAN', 'ALT_VERKAUF'],
      ['MOVENTUM LUX']: ['ALT_KAUF', 'ALT_SPARPLAN', 'ALT_VERKAUF'],
      ['MOVENTUM AT']: ['ALT_KAUF', 'ALT_SPARPLAN', 'ALT_VERKAUF'],
  }

const mixin = {
    mixins: [validator],
    validators: {},
    data() {
      return {
        categories: categories,
        betragModus: betragModus,
        radioErklaerung: radioErklaerung,
        radioMaklerunterschrift: radioMaklerunterschrift,
        radioAusgabeaufschlag: radioAusgabeaufschlag,
        faVisibleCategories: faVisibleCategories,
        PATH_CUSTOMER: PATH_CUSTOMER,
        PATH_BROKER: PATH_BROKER,
      };
    },
    computed: {
        ...mapGetters({
            wertpapierorderData: WERTPAPIERORDER_TYPES.GETTERS.DATA,
            warningsAll: WERTPAPIERORDER_TYPES.GETTERS.WARNINGS,
            _isFA: CORE_TYPES.GETTERS.IS_FA,
            depotsAll: WERTPAPIERORDER_TYPES.GETTERS.DEPOTS || {},
            orderbooksAll: WERTPAPIERORDER_TYPES.GETTERS.ORDERBOOKS || {},
            venues: WERTPAPIERORDER_TYPES.GETTERS.VENUES || {},
            lagerstelle: WERTPAPIERORDER_TYPES.GETTERS.SELECTED_LAGERSTELLE,
            beratungsMappeData: WERTPAPIERORDER_TYPES.GETTERS.BERATUNGS_MAPPE_DATA,
            positions: WERTPAPIERORDER_TYPES.GETTERS.POSITIONS || [],
            orderAntrag: WERTPAPIERORDER_TYPES.GETTERS.ANTRAG,
            isFirma: CUSTOMERDATA_TYPES.GETTERS.IS_FIRMA,
            isAntragActionLoading: ANTRAG_TYPES.GETTERS.IS_ANTRAG_ACTION_LOADING,
            isComdirectLoginRequired: TAN_TYPES.GETTERS.IS_COMDIRECT_TAN_REQUIRED,
            isSomeButtonLoading: ANTRAG_TYPES.GETTERS.IS_SOME_ANTRAG_ACTION_LOADING_ACTIVE,
            isCustomerLogin: CORE_TYPES.GETTERS.IS_CUSTOMER_ONLY,
            saveAntragState: ANTRAG_TYPES.GETTERS.SAVE_ANTRAG_STATE,
        }),
        stepper() {
            const l = this.lagerstelle;
            return new StepperMediator([
            { stepKey: 'main', label: 'Orderaufträge'}, 
            { stepKey: 'positions-data', label: 'Zusätzliche Daten'}, 
            { stepKey: 'capitalbank', label: 'Schelhammer Capitalbank', hidden: !this.isGesellschaftCapitalbank},
            { stepKey: 'hellobank', label: 'Easybank', hidden: !this.isGesellschaftHellobank}, 
            { stepKey: 'hellobank-selbst', label: 'Selbstauskunft',hidden: !(this.isGesellschaftHellobank && this.hasKauf)}, 
            { stepKey: 'semperconstantia', label: 'Semper Constantia', hidden: !this.isGesellschaftConstantia}, 
            { stepKey: 'sonstiges', label: 'Sonstiges'}, 
            { stepKey: 'capitalbank-kyc', label: 'Know your Customer', hidden: (!this.isGesellschaftCapitalbank || this.isGesellschaftCapitalbankFirma)},
            //FODB nur für FirmenKunden(ohner Webservice zeigen)
          //  { stepKey: 'FODB', label: 'Fondsdepotbank', hidden: !(this.isGesellschaftFODB && this.isFirma)},
            { stepKey: 'fodb-mix', label: 'FODB Mixdepot', hidden: !(this.isGesellschaftFODB && this.data.hatFODBMix)}, 
            { stepKey: 'ebase-edelmetal', label: 'FNZ Edelmetallkonto', hidden: true}, 
            { stepKey: 'dab', label: 'DAB', hidden: !this.isGesellschaftDAB},
            { stepKey: 'morgen-fund', label: 'MorgenFund', hidden: !this.isGesellschaftMorgenFund},  
            { stepKey: 'aktionen', label: 'Zusammenfassung', hinweise: this.aktionenStepHinweise }
          ]);
        },
        depots() {
            return this.lagerstelle ? this.depotsAll?.[this.lagerstelle] : (this.data?.lagerstelleSelect ? this.depotsAll?.[this.data?.lagerstelleSelect] : []);
        },
        orderbook() {
            return this.lagerstelle ? this.orderbooksAll?.[this.lagerstelle] : (this.data?.lagerstelleSelect ? this.orderbooksAll?.[this.data?.lagerstelleSelect] : []);
        },
        data() {
            return this.wertpapierorderData || {};
        },
        // Antrag wurde dem Kunde zur Unterschrift vorgelegt
        isVorgelegt() {
            return this.data?.IS_VORGELEGT;
        },
        isGesellschaftHellobank(){
            return this.lagerstelle == 'HELLOBANK'
        },
        isGesellschaftCapitalbank(){
            return this.lagerstelle == 'CAPITALBANK'
        },
        //MSC-21513 backend not implemented yet (empty kyc-form is added)
        isGesellschaftCapitalbankFirma(){
            return this.isGesellschaftCapitalbank && this.isFirma;
        },
        isGesellschaftFODB(){
            return this.lagerstelle == 'FODB'
        },
        isGesellschaftConstantia(){
            return this.lagerstelle == 'CONSTANTIA PRIVATBANK'
        },
        isGesellschaftDAB(){
            return this.lagerstelle == 'DAB'
        }, 
        isGesellschaftMorgenFund(){
            return this.lagerstelle == 'DWS FONDSPLATTFORM FFM' || this.lagerstelle == 'DWS FONDSPLATTFORM'
        },  
        isFA() {
            return this._isFA;
        },
        getStepKey() {
            return this.$route.path.substring(this.$route.path.lastIndexOf('/') + 1);
        },
        getRouteParams() {
            const knownParams = [
              'antragsdatenId',
              'beratungsMappeId',
              'lagerstelleFixed',
              'bgsNr',
            ]
            const routeQuery = this.$route.query || {};
            const payload = knownParams.reduce((accu, curr) => {
              routeQuery[curr] ? accu[curr] = routeQuery[curr] : ''
              return accu;
            }, {})
            return payload;
        },
        isBeratungsmappe() {
            return this.data?.beratungsMappeId;
        },
        isSignatureAllowed() {
            return this.orderAntrag?.signatureAllowed;
        },
        aktionenStepHinweise() {
            const hinweise = [];
            if (this.isSigniert) {
                hinweise.push({ text: 'PDF ist Digital signiert'});
            } else {
                const actions = [];
                if(!this.isFA && this.data?.antragsdatenId && this.data?.hatPDF && !this.isBeratungsmappe) {
                    actions.push({ key: 'SIGN', label: 'PDF signieren', loading: this.isAntragActionLoading('sign') || this.isAntragActionLoading('zusatzDok'), });
                    if (!this.readOnly) {
                        actions.push({ key: 'TOGGLE-E-SIGNATUR', label: (this.isVorgelegt ? 'e-Signatur zurückziehen' : 'zur e-Signatur vorlegen'), loading: this.isAntragActionLoading('eSignatur'), });
                    }
                }
                if (!this.isCustomerLogin) {
                    if (this.isSignatureAllowed) {
                        hinweise.push({ 
                            text: 'eSignatur der Dokumente ist <b>möglich</b>.', 
                            actions: actions, 
                        });
                        if (!this.isFA) {
                            hinweise.push({ text: 'Bitte legen Sie Ihrem Kunden die Unterlagen zur Unterschrift per eSignatur vor oder laden Sie das Dokument bei <b>Kommunikation</b> unter dem Punkt <b>"Dokumente hinzufügen"</b> hoch.', });
                        }
                    } else {
                        hinweise.push({ 
                            text: 'eSignatur der Dokumente ist <b>nicht</b> möglich.', 
                        });
                    }
                }
            } 

            if (this.lagerstelle === 'COMDIRECT') {
                hinweise.unshift({ text: `Bitte beachten Sie die Besonderheiten beim Festpreisgeschäft:<br>
                    - Mindestvolumen beim Festpreisgeschäft beträgt 250 EUR, wobei Fondsgesellschaften diesen Mindestbetrag auch höher ansetzen können.<br>
                    - Im Festpreisgeschäft - anders als beim Sparplan - können nur ganze Anteile erworben werden.<br>
                    --> Heißt: Bei einem Anteilspreis von 136,63 EUR und einer Mindestorderhöhe von 250 EUR, muss genau 273,26 EUR investiert werden (2 ganze Anteile), damit die Order ausgeführt wird.`})
            }
            
            if (this.lagerstelle === 'FFB'  || this.lagerstelle === 'FODB') {
                hinweise.unshift({ text: `Bitte beachten Sie, dass die Dokumente elektronisch verarbeitet werden und daher keine Änderungen (z.B. handschriftliche Änderungen oder Seiten entfernen) an den Dokumenten vorgenommen werden dürfen.`})
            }
            return hinweise;
        },
        readOnly() {
            return this.beratungsMappeData?.closed || this.data?.READ_ONLY;
        },
        hasVerkauf() {
            return this.positions?.some(pos => pos.positionTyp === 'ALT_VERKAUF' || pos.positionTyp === 'ALT_TAUSCH');
        },
        hasTausch(){
            return this.positions?.some(pos => pos.positionTyp === 'ALT_TAUSCH');
        },
        hasSparplan() {
            return this.positions?.some(pos => pos.positionTyp === 'ALT_SPARPLAN' || pos.positionTyp === 'NEU_SPARPLAN');
        },
        hasNeuKauf() {
            return this.positions?.some(pos => pos.positionTyp === 'NEU_KAUF');
        },
        hasKauf() {
            return this.positions?.some(pos => pos.positionTyp === 'NEU_KAUF' || pos.positionTyp === 'ALT_KAUF');
        },
        hasKaufVerkauf() {
            return this.hasVerkauf || this.positions?.some(pos => pos.positionTyp === 'NEU_KAUF' || pos.positionTyp === 'ALT_KAUF');
        },
        lagerstelleFixed() {
            return this.$route.query.lagerstelleFixed || '';
        },
        depotComboboxValues() {
            const result = this.depots?.filter(depot => depot.bgsNr && (depot.depotNr === this.depotNr || depot.depotNr?.includes(this.depotNr)))
              .map(depot => ({
                value: depot.bgsNr,
                label: depot.ffbPortfolioData ? `Portfolio ${depot.ffbPortfolioData.portfolioBez}; ${depot.ffbPortfolioData.portfolioNr} (${depot.ffbPortfolioData.depotnr})` : depot.label
              })) || []
      
              
            return result.sort((a,b) => a?.label === 'freie Eingabe:' ? -1 :
                (b?.label === 'freie Eingabe:' ? 1 : a?.label?.localeCompare(b?.label)));
        },
        orderbookComboboxValues() {
            const result = this.orderbook?.filter(order => order.depotNr === this.depotNr || order.depotNr?.includes(this.depotNr))
              .map(order => ({
                value: order.externalId,
                label: order.label
              })) || []

            return result.sort((a,b) => a?.label === 'freie Eingabe:' ? -1 : 
                (b?.label === 'freie Eingabe:' ? 1 : a?.label?.localeCompare(b?.label)));
        },
        isDisabled(){
            return this.readOnly || this.isLoading;
        },
        isLoading() {
            return this.isSomeButtonLoading || this.saveAntragState;
        }
    },
    methods: {
        loadWertpapierorder({lagerstelle, depot, categoryId, }) {
            if (this.$route.query?.kauf) {
                this.$store.dispatch(
                    WERTPAPIERORDER_TYPES.ACTIONS.KAUF, {
                    isinLst: this.$route.query.kauf, 
                    lagerstelle: lagerstelle,//this.lagerstelleSelect, 
                    depotNr: depot, //this.depotSelect, 
                    categoryId: categoryId, //this.categories.NEU_KAUF.id
                    }
                );
            } else {
                this.$store.dispatch(WERTPAPIERORDER_TYPES.ACTIONS.GET_WERTPAPIERORDER, { 
                    lagerstelle: lagerstelle, //this.lagerstelleSelect, 
                    depotNr: depot, //this.kontoForValidating,
                    beratungsMappeId: this.$route.query.beratungsMappeId || '' 
                });
            }
        },
        updateStoreData(data) {
            if (data?.componentId) {
                this.$store.commit(WERTPAPIERORDER_TYPES.MUTATIONS.UPDATE_DATA, { [data.componentId]: data.value });
            }
        },
        updateStoreDataValues(data) {
            if (data && Object.keys(data).length) {
                this.$store.commit(WERTPAPIERORDER_TYPES.MUTATIONS.UPDATE_DATA, data);
            }
        },
        getDepot(depotNr, bgsNr) {
            if (depotNr && this.depots?.length) {
                return this.depots.find(depot => (depot.depotNr === depotNr || depot.depotNr?.includes(depotNr)) && (bgsNr && bgsNr === depot.bgsNr || !bgsNr))
            }
        },
        getOrderbook(depotNr) {
            if (depotNr && this.orderbook?.length) {
                return this.orderbook.find(depot => (depot.depotNr === depotNr || depot.depotNr?.includes(depotNr)) )
            }
        },
        async navigateToStep(stepKey) {
            if (stepKey && !this.isSomeButtonLoading && !this.saveAntragState) {
                const path = this.$route.path.includes(this.PATH_CUSTOMER) ? `${this.PATH_CUSTOMER}${stepKey}` : `${this.PATH_BROKER}${stepKey}`;
                const query = this.$route.query && this.lagerstelleFixed ? {lagerstelleFixed: this.lagerstelleFixed} : null;
                if (path !== this.$route.path) {
                    const routerPath = {path: path};
                    if (query) {
                        routerPath.query = query
                    }
                    await this.doSave()
                    this.$router.push(routerPath);
                }
            }  
        },
        async navigateToStepWPO(stepKey){
            if (stepKey && !this.isSomeButtonLoading && !this.saveAntragState) {
                const path = this.$route.path.includes(this.PATH_CUSTOMER) ? `${this.PATH_CUSTOMER}${stepKey}` : `${this.PATH_BROKER}${stepKey}`;
                const query = this.$route.query && this.lagerstelleFixed ? {lagerstelleFixed: this.lagerstelleFixed} : null;
                if (path !== this.$route.path) {
                    const routerPath = {path: path};
                    if (query) {
                        routerPath.query = query
                    }
                    console.log('test' + this.data?.isWebserviceAktiv)
                    if(!this.data?.isWebserviceAktiv){
                        await this.doSave()
                    }
                    this.$router.push(routerPath);
                }
            } 
        },
        doSave(data) {
            if (this.readOnly) {
                return;
            }
            if (this.getStepKey === 'positions-data') {
                return this.$store.dispatch(WERTPAPIERORDER_TYPES.ACTIONS.SAVE_POSITION_DATA, data);
            }else {
                return this.$store.dispatch(WERTPAPIERORDER_TYPES.ACTIONS.SAVE_WERTPAPIERORDER, data);
            }
        },
        getWertpapiernameStr(pos) {
            return pos && pos.wertpapiername || '';
        },
        getKontoStr(depotNr) {
            const extraDepotStr = depotNr && this.depots?.find(depot => depot.depotNr === depotNr || depot.kontoNr || depotNr?.includes(depot.kontoNr) || {})
                .extraStr || '';
            return 'Depot ' + (depotNr || '') + extraDepotStr;

        },
        // if mitHinweis == true, return also warnings with status == 'HINWEIS', in other way ignore warnings with status == 'HINWEIS'
        getWarnings(stepKey, mitHinweis = false) {
            const warnings = Object.assign({}, this.warningsAll);
            if (!stepKey || !Object.keys(warnings)?.length) {
                return false;
            }
            let result;
            switch (stepKey) {
                case 'main':
                    if (warnings.positionWarnings?.length) {
                        result = Object.assign([], warnings.positionWarnings);
                    }
                    if (warnings.antragWarnings?.length) {
                        const antragWarnings = warnings.antragWarnings.filter(warn => warn.stepKey === 'main');
                        if (antragWarnings?.length) {
                            if (result) {
                                result.push(...antragWarnings)
                            } else {
                                result = Object.assign([], antragWarnings);
                            }
                        }
                    } 
                    if (!mitHinweis) {
                        result = result?.filter(warn => warn.status !== 'HINWEIS')
                    }
                    if (!result?.length) {
                        result = null;
                    }
                    break;
                case 'aktionen':
                    result = Object.assign([], warnings.positionWarnings || []);
                    if (warnings.antragWarnings?.length) {
                        result.push(...warnings.antragWarnings);
                    }
                    if (!mitHinweis) {
                        result = result.filter(warn => warn.status !== 'HINWEIS')
                    }
                    if (!result.length) {
                        result = null;
                    }
                    break;
                default:
                    result = {};
                    if (warnings.antragWarnings?.length) {
                        warnings.antragWarnings.forEach(warn => {
                            if ((mitHinweis || !mitHinweis && warn.status !== 'HINWEIS') && stepKey === warn.stepKey) {
                                result[warn.id] = warn;
                            }
                        });
                    }
                    result = Object.keys(result).length ? result : null;
                    break;
            }
            return result || null;
        },
        updateWarnings(stepKey, hasForm = false) {
            if (stepKey) {
                const warnings = this.getWarnings(stepKey, true) || {};
                Object.keys(warnings).forEach(key => {
                    this.$pushErrors((hasForm ? 'form.' : '') + key, [warnings[key].message])
                });
            }
        },
        getColorClass(pos) {
            if(pos?.positionTyp === 'ALT_VERKAUF' || pos?.positionTyp === 'ALT_ENTNAHMEPLAN' || pos?.positionTyp === 'STREICHUNG') {
                return 'color-danger';
            } else if(pos?.positionTyp === 'ALT_TAUSCH' || pos?.positionTyp === 'LIMITAENDERUNG') {
                return 'color-info';
            } else if(pos?.positionTyp === 'ALT_SPARPLAN') {
                switch(pos?.auftragArt) {
                    case 'NEU':
                        return 'color-success';
                    case 'AENDERN':
                        return 'color-info';
                    case 'LOESCHEN':
                        return 'color-danger';
                    case 'AUSSETZEN':
                        return 'color-warning';
                    default:
                        return 'color-text';
                }
            }
            return 'color-success';
        },
        getMeldungEinreichung() {
            this.$store.dispatch(WERTPAPIERORDER_TYPES.ACTIONS.GET_MELDUNG_EINREICHUNG);
        }
    },
}

export default mixin;

