<template>
    <div v-if="fkLoggedIn">

        <div class="box__container" v-if="!readOnly">
            <div class="box__title">Einladung zur Registrierung für einen Unterzugang zur {{ userLevel }}</div>
            <BaseButton @click="inviteModal = true" isPrimary>Einladung versenden</BaseButton>
            <InviteToSsoModal v-if="inviteModal" @close="inviteModal = false"/>
        </div>

        <div class="box__container" >
            <div class="box__title">Mit {{userId}} verknüpfte Single-Sign-Ons</div>
            <Table v-if="ssoAccountsAll && ssoAccountsAll.length"
                tableId="fc5b97e6-e605-4281-9be0-25d5dc9c7df1"
                :headers="headers"
                :rows="rows"
                @action-DELETE="handleActionDelete"
                hidePagination
            >
                <template v-slot:permissionDescription="row">
                    <ComboBox
                        v-model="row.permissionDescription"
                        :values="comboBerechtigung"
                        :disabled="readOnly"
                        @change="updatePermission(row, $event)"
                    />
                </template>
            </Table>
            <div class="mb-2" v-else>
                Keine verknüpfte Single-Sign-Ons.
            </div>

            <BaseButton @click="goToInvite" v-if="!readOnly" isPrimary>Registrierten Benutzer hinzufügen</BaseButton>
        </div>

        <BaseModal ref="errorModal" labelButtonCancel="Ok" :showConfirmButton="false" @onCancelButton="closeErrorModal">
            <template v-slot:modalTitle>
                <PhInfo style="color: orange" />
                Fehler
            </template>
            <div>
                {{errorStr}}
            </div>
        </BaseModal>
    </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import SSO_TYPES from '@/store/sso/types';

import { mapGetters } from 'vuex';
import { PhKey, PhTrash, PhPower, PhPencil, PhCheck } from "phosphor-vue";
import Pill from '@/components/core/Pill.vue'
import {TextColumn, ActionColumn, DateColumn, PillColumn, SlotColumn, SimpleAction} from "@/components/table2/table_util.js";

import InputField from '@/components/core/forms/InputField.vue';
import BaseButton from '@/components/core/BaseButton.vue'
import Table from "@/components/table2/Table.vue";
import InviteToSsoModal from '@/components/core/sso/InviteToSsoModal.vue'
import ComboBox from '@/components/core/forms/ComboBox.vue';
import mixin from '@/mixins/sso/sso-mixin.js';
import BaseModal from '@/components/core/BaseModal.vue';

export default {
    mixins: [mixin],
    components: {
        PhPower,
        PhPencil,
        PhKey,
        PhCheck,
        InputField,
        BaseButton,
        Table,
        InviteToSsoModal,
        Pill,
        ComboBox,
        BaseModal,
    },
    data() {
        return {
            confirm2FA: false,
            inviteModal: false,
            errorStr: '',
            headers: {
                lockedLeft: [
                    TextColumn("ssoName", "SSO-Konto").makeAlwaysVisible(),
                    PillColumn("statusPill", "Status"),
                    SlotColumn("permissionDescription", "Berechtigung").makeAlwaysVisible(),
                ],
                center: [
                    DateColumn("validUntil", "Gültig bis"),
                ],
                lockedRight: [
                    ActionColumn("actions", "Aktionen"),
                ],
            },
        };
    },
    computed: {
        ...mapGetters({
            loginName: SSO_TYPES.GETTERS.LOGIN_NAME,
            ssoAccountsAll: SSO_TYPES.GETTERS.SSO_ACCOUNTS_ALL,
            fkLoggedIn: CORE_TYPES.GETTERS.IS_LOGGED_IN,
            userId: CORE_TYPES.GETTERS.GET_USER_ID,
            readOnly: CORE_TYPES.GETTERS.READ_ONLY,
            isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
        }),
        rows() {
            if (!this.ssoAccountsAll)
                return [];
            const actions = [
                SimpleAction("DELETE", PhTrash, "Löschen"),
            ];
            return this.ssoAccountsAll.map(row => {
                let statusPill = null;
                if (row.status =='aktiv')
                    statusPill = {label: row.status, type: "success"};
                else if (row.status == 'eingeladen')
                    statusPill = {label: row.status, type: "info"};
                return {
                    ...row,
                    actions,
                    statusPill
                }
            })
        },
        userLevel() {
            return this.isCustomer ? 'Kundennummer' : 'Vermittlernummer';
        }
    },
    mounted() {
        if (this.fkLoggedIn) {
            this.$store.dispatch(SSO_TYPES.ACTIONS.GET_FK_ACCOUNT_INFO);
        }
        this.$store.dispatch(SSO_TYPES.ACTIONS.GET_SSO_ACCOUNT_INFO);
    },
    methods: {
        goToInvite() {
            this.$addBreadcrumb({
                label: 'zurück zur SSO Zugangsverwaltung', 
                fullPath: '/home/sso',
                breadcrumb: 'Einladung SSO-Konto',
            });
            this.$router.push({name: 'sso-invite'});
        },
        handleActionDelete(row) {
            if (row.status === 'eingeladen' && row.displayedName) {
                // delete invitation by Kunde
                this.$store.dispatch(SSO_TYPES.ACTIONS.DELETE_INVITE_FK, row.displayedName);
            } else {
                // delete SSO-Konto by Kunde
                this.$store.dispatch(SSO_TYPES.ACTIONS.DELETE_ACCESS_FK, row.id);
            }
        },
        updatePermission(row, event) {
            const account = this.ssoAccountsAll.find(account => account.id == row.id);
            if (account.permissionDescription !== event) {
                this.$store.dispatch(SSO_TYPES.ACTIONS.UPDATE_PERMISSION, {
                    ...account,
                    permissionDescription: event,
                }).then(response => {
                    if (response?.data?.errorStr) {
                        this.errorStr = response.data.errorStr;
                        this.$refs.errorModal.open();
                    }
                });
            }
        },
        closeErrorModal() {
            this.errorStr = null;
        },
    },
}
</script>

<style scoped>
.sso-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.column {
    display: flex;
    flex-direction: column;
    width: 480px;
}
.box__container {
    width: 100%;
}
.info {
    display: flex;
    flex-direction: row;
    margin-bottom: 0 !important;
}

</style>