var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "impr-container" }, [
    _c("div", { staticClass: "impr-wrapper" }, [
      _c("div", { staticClass: "banner" }, [_vm._v("IMPRESSUM")]),
      _c("hr"),
      _c(
        "div",
        { staticClass: "impressum" },
        [_c("editable-frame", { attrs: { type: "impressum_editable" } })],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }