<template   >
  <div class="event-body">
    <BaseModal
      ref="modala"
      @onCloseButton="close()"
      :showCloseButton="true"
      :showDefaultButtons="false"
      :showConfirmButton="false"
      :autoClose="true"
      size="xl"
    >
     <template v-slot:modalTitle> {{ title }} </template>
     <BoxContainer>
      <DBMT :dat=this.dat></DBMT>
     </BoxContainer>
     <template v-slot:footer>
       
        <BaseButton @click="close" isSecondary>close</BaseButton>
       
      </template>       
    </BaseModal>
  

  </div>
</template>
<script>
import DB_TYPES from "@/store/dbm/types";

import { mapGetters } from "vuex";

import InputField from "@/components/core/forms/InputField.vue";
import BaseModal from "@/components/core/BaseModal.vue";

import BoxContainer from "@/components/core/BoxContainer.vue";
import InputTextArea from "@/components/core/forms/InputTextArea.vue";
import BaseButton from "@/components/core/BaseButton.vue";
import DBMT from "@/views/dbm/DbmTable.vue"
export default {
    props: {
   
   act: {
     type: String,
     default: DB_TYPES.ACTIONS.SEGMENTS,
   },
 },

  components: {
    DBMT, 
    BaseModal,
    InputField,
    InputTextArea,
    BoxContainer,
    BaseButton,
    
  },
  watch: {},
  computed: {
    ...mapGetters({
      dat: DB_TYPES.GETTERS.DATA_SEGMENTS,
    }),
    title() {
      let result =
        
        this.tbs 
        
      return result;
    },
  },

  data() {
    return {
      isLoaded: false,
      loading: false,
      tbs : null, 
     
    };
  },

  mounted() {},
  beforeDestroy() {},
  methods: {
    async open(t) {
      this.tbs=t;    
      await this.$store.dispatch(DB_TYPES.ACTIONS.SEGMENTS, {
        query: this.tbs,
      
      });
      // this.dat = this.$store.getters[DB_TYPES.GETTERS.DATA]; 
    

      this.$refs.modala?.open();
      
      this.loading = false;
    },
    close() {
      this.$store.commit(
        DB_TYPES.MUTATIONS.START_TIME,
        new Date().getTime() - 6000000000
      );
      this.$refs.modala.close();
    },

    


  },
};
</script>
