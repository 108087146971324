var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: {
        modalTitle: "Multiinterface verarbeiten",
        autoClose: false,
        confirmDisabled: !_vm.form.newItabSid,
        showConfirmButton: _vm.interfaceEditEnable
      },
      on: {
        onConfirmButton: _vm.save,
        close: function($event) {
          return _vm.close()
        }
      }
    },
    [
      _c(
        "div",
        [
          _c("InputField", {
            attrs: { value: _vm.interfaceId, disabled: "", label: "ID" }
          }),
          _c("InputField", {
            attrs: { value: _vm.form.lfdno, disabled: "", label: "Lfd. Nr." }
          }),
          _vm.interfaceOptions
            ? _c("ComboBox", {
                attrs: {
                  values: _vm.interfaceOptions,
                  disabled: !_vm.interfaceEditEnable,
                  label: "Neues Interface"
                },
                model: {
                  value: _vm.form.newItabSid,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "newItabSid", $$v)
                  },
                  expression: "form.newItabSid"
                }
              })
            : _vm._e(),
          _c("InputTextArea", {
            attrs: {
              disabled: !_vm.interfaceEditEnable,
              label: "Check Statement",
              autoGrow: ""
            },
            model: {
              value: _vm.form.checkStatement,
              callback: function($$v) {
                _vm.$set(_vm.form, "checkStatement", $$v)
              },
              expression: "form.checkStatement"
            }
          }),
          _c("InputTextArea", {
            attrs: {
              disabled: !_vm.interfaceEditEnable,
              label: "Beschreibung",
              autoGrow: ""
            },
            model: {
              value: _vm.form.description,
              callback: function($$v) {
                _vm.$set(_vm.form, "description", $$v)
              },
              expression: "form.description"
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }