import RETIREMENT_SCENARIO_TYPES from './types';

export default {
  [RETIREMENT_SCENARIO_TYPES.GETTERS.SCENARIOS](state) {
    return state.scenarios;
  },
  [RETIREMENT_SCENARIO_TYPES.GETTERS.CURRENT_SCENARIO](state) {
    return state.currentScenario;
  },
  [RETIREMENT_SCENARIO_TYPES.GETTERS.PERSONAL_DATA](state) {
    return state.personalData;
  },
}