<template>
  <div class="customer-view">
    <router-view></router-view>
    <router-view name="modal"></router-view>
  </div>
</template>

<script>
export default {
  validators: {},
};
</script>
