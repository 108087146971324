var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Persönliches Geschäft",
          actions: _vm.headerActions
        },
        on: {
          "action-SAVE": function($event) {
            return _vm.save()
          }
        }
      }),
      _c("ComboBox", {
        attrs: {
          label: "Jahr",
          values: _vm.availableYears,
          disabled: _vm.disabled,
          isComponentHalfSize: ""
        },
        on: { change: _vm.setChanged },
        model: {
          value: _vm.form.jahr,
          callback: function($$v) {
            _vm.$set(_vm.form, "jahr", $$v)
          },
          expression: "form.jahr"
        }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("InputRadioBoxGroup", {
            attrs: {
              title: "Ich bestätige,",
              values: _vm.depotStatusValues,
              disabled: _vm.disabled
            },
            on: { input: _vm.setChanged },
            model: {
              value: _vm.form.depotStatus,
              callback: function($$v) {
                _vm.$set(_vm.form, "depotStatus", $$v)
              },
              expression: "form.depotStatus"
            }
          }),
          _c("InputField", {
            attrs: {
              label: "Depotnummer",
              disabled: !_vm.hasDepot || _vm.disabled,
              isComponentHalfSize: ""
            },
            on: { change: _vm.setChanged },
            model: {
              value: _vm.form.depotnr,
              callback: function($$v) {
                _vm.$set(_vm.form, "depotnr", $$v)
              },
              expression: "form.depotnr"
            }
          }),
          _c("InputField", {
            attrs: {
              label: "Lagerstelle",
              disabled: !_vm.hasDepot || _vm.disabled,
              isComponentHalfSize: ""
            },
            on: { change: _vm.setChanged },
            model: {
              value: _vm.form.lagerstelle,
              callback: function($$v) {
                _vm.$set(_vm.form, "lagerstelle", $$v)
              },
              expression: "form.lagerstelle"
            }
          }),
          _c(
            "DragnDropArea",
            {
              staticClass: "form-select-file-button__container mb-3",
              attrs: {
                hoverText: "Hochladen",
                disabled: _vm.isUploadDisabled(_vm.DEPOTAUSZUG)
              },
              on: {
                files: function($event) {
                  return _vm.upload($event, _vm.DEPOTAUSZUG)
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "my-3" },
                [
                  _c(
                    "BaseFileSelect",
                    {
                      attrs: {
                        disabled: _vm.isUploadDisabled(_vm.DEPOTAUSZUG)
                      },
                      on: {
                        files: function($event) {
                          return _vm.upload($event, _vm.DEPOTAUSZUG)
                        }
                      }
                    },
                    [_vm._v(" Depotauszug hochladen ")]
                  ),
                  _vm.depotauszugFileName
                    ? _c("div", { staticClass: "mt-3" }, [
                        _c(
                          "div",
                          {
                            key: _vm.form.depotauszugFileObjectId,
                            staticClass: "pill__container"
                          },
                          [
                            _vm.form.depotauszugFileId
                              ? _c(
                                  "DownloadLink",
                                  {
                                    attrs: {
                                      target: "_blank",
                                      rel: "noopener noreferer",
                                      title: _vm.form.depotauszugFileName,
                                      filename: _vm.form.depotauszugFileName,
                                      downloadServicePath: "/get_simple_file",
                                      queryParams: {
                                        fileId: _vm.form.depotauszugFileId,
                                        tempFileId: _vm.form.depotauszugFileId
                                      }
                                    }
                                  },
                                  [
                                    _c("Pill", {
                                      attrs: {
                                        label: _vm.depotauszugFileName,
                                        type: "default"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "BaseButton",
                              { attrs: { isLink: "" } },
                              [
                                _c("PhTrash", {
                                  staticClass: "ml-2",
                                  attrs: { size: 16 },
                                  on: {
                                    click: function($event) {
                                      return _vm.onRemoveFile(_vm.DEPOTAUSZUG)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ],
                1
              )
            ]
          ),
          _c(
            "DragnDropArea",
            {
              staticClass: "simple-additional-documents-table__dropzone",
              attrs: {
                hoverText: "Neues Dokument hochladen",
                disabled: _vm.isUploadDisabled(_vm.TRANSLISTE)
              },
              on: {
                files: function($event) {
                  return _vm.upload($event, _vm.TRANSLISTE)
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "my-3" },
                [
                  _c(
                    "BaseFileSelect",
                    {
                      attrs: { disabled: _vm.isUploadDisabled(_vm.TRANSLISTE) },
                      on: {
                        files: function($event) {
                          return _vm.upload($event, _vm.TRANSLISTE)
                        }
                      }
                    },
                    [_vm._v(" Transaktionsliste hochladen ")]
                  )
                ],
                1
              ),
              _vm.translisteFileName
                ? _c("div", { staticClass: "mt-3" }, [
                    _c(
                      "div",
                      {
                        key: _vm.form.translisteFileObjectId,
                        staticClass: "pill__container"
                      },
                      [
                        _vm.form.translisteFileId
                          ? _c(
                              "DownloadLink",
                              {
                                attrs: {
                                  target: "_blank",
                                  rel: "noopener noreferer",
                                  title: _vm.form.translisteFileName,
                                  filename: _vm.form.translisteFileName,
                                  downloadServicePath: "/get_simple_file",
                                  queryParams: {
                                    fileId: _vm.form.translisteFileId,
                                    tempFileId: _vm.form.translisteFileId
                                  }
                                }
                              },
                              [
                                _c("Pill", {
                                  attrs: {
                                    label: _vm.translisteFileName,
                                    type: "default"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "BaseButton",
                          { attrs: { isLink: "" } },
                          [
                            _c("PhTrash", {
                              staticClass: "ml-2",
                              attrs: { size: 16 },
                              on: {
                                click: function($event) {
                                  return _vm.onRemoveFile(_vm.TRANSLISTE)
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ]
          ),
          _vm._m(0),
          _c("InputTextArea", {
            attrs: {
              label: "Anmerkung",
              isComponentHalfSize: "",
              disabled: !_vm.form.depotStatus || _vm.disabled
            },
            on: { change: _vm.setChanged },
            model: {
              value: _vm.form.bemerkung,
              callback: function($$v) {
                _vm.$set(_vm.form, "bemerkung", $$v)
              },
              expression: "form.bemerkung"
            }
          }),
          _c("InputToggleSwitch", {
            attrs: {
              disabled: !_vm.form.depotStatus || _vm.disabled,
              label:
                "Ich bestätige hiermit, die Angaben wahrheitsgetreu getätigt zu haben.",
              inLineLabel: "",
              validateUntouched: ""
            },
            on: { input: _vm.setChanged },
            model: {
              value: _vm.form.confirmed,
              callback: function($$v) {
                _vm.$set(_vm.form, "confirmed", $$v)
              },
              expression: "form.confirmed"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", [
        _c("span", { staticClass: "font-bold" }, [_vm._v("Hinweis: ")]),
        _vm._v(
          " Bitte fügen Sie alle Depotauszüge/Transaktionslisten zu jeweils einem PDF zusammen, sofern mehrere Dokumente vorliegen."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }