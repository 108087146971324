import { buildMessage } from '@/helpers/log-message-helper';
import STORNO_WARNUNG_TYPES from './types';
import MAILCOMPOSER_TYPES from "@/store/mailcomposer/types";
import LOG_TYPES from '@/store/log/types'
import axios from 'axios';

const config = {
  defaultSpinner: true
};

export const MAX_OVERVIEW_COUNT = 5;

export default {
  async [STORNO_WARNUNG_TYPES.ACTIONS.GET_STORNO_WARNUNG_OVERVIEW]({ commit, state }) {

    if (!state.isLoading) {
      commit(STORNO_WARNUNG_TYPES.MUTATIONS.RESET_FILTERS);
      commit(STORNO_WARNUNG_TYPES.MUTATIONS.SET_IS_LOADING, true);
      const payload = { filters: state.defaultFilters, limit: MAX_OVERVIEW_COUNT, pageIndex: 0 };
      axios.post(`${process.env.VUE_APP_API}/stornoWarnungen/getStornoWarnungen`, payload, config).then(response => {
        if (response.status === 200 && response.data) {
          commit(STORNO_WARNUNG_TYPES.MUTATIONS.GET_STORNO_WARNUNG_OVERVIEW_SUCCESS, response.data)
        }
        commit(STORNO_WARNUNG_TYPES.MUTATIONS.SET_IS_LOADING, false);
      })
      .catch(error => {
        commit(STORNO_WARNUNG_TYPES.MUTATIONS.SET_IS_LOADING, false);
      })

    }
  },

  [STORNO_WARNUNG_TYPES.ACTIONS.GET_STORNO_WARNUNG]({ commit, state }, payload) {
    if (!state.isLoading) {
      commit(STORNO_WARNUNG_TYPES.MUTATIONS.SET_IS_LOADING, true);
      payload = Object.assign(payload || {}, {filters: state.defaultFilters })
      if (!payload.pageIndex) {
        payload.pageIndex = state.pageIndex || 0;
      }
      commit(STORNO_WARNUNG_TYPES.MUTATIONS.UPDATE_PAGE_INDEX, payload?.pageIndex || 0)
      axios.post(`${process.env.VUE_APP_API}/stornoWarnungen/getStornoWarnungen`, payload, config).then(response => {
        if (response.status === 200 && response.data) {
            commit(STORNO_WARNUNG_TYPES.MUTATIONS.GET_STORNO_WARNUNG_SUCCESS, {
                ...response.data,
                pageIndex: payload?.pageIndex || 0,
            })
        }
        commit(STORNO_WARNUNG_TYPES.MUTATIONS.SET_IS_LOADING, false);
      })
      .catch(error => {
        commit(STORNO_WARNUNG_TYPES.MUTATIONS.SET_IS_LOADING, false);
      })
    }
  },
  
  [STORNO_WARNUNG_TYPES.ACTIONS.GET_STORNO_ARTS]({ commit }) {

    axios.get(`${process.env.VUE_APP_API}/stornoWarnungen/getStornoTransaktionsArt`, config).then(response => {
      if (response.status === 200 && response.data) {
        commit(STORNO_WARNUNG_TYPES.MUTATIONS.GET_STORNO_ARTS_SUCCESS, response.data)
      }
    });
  },
 
  [STORNO_WARNUNG_TYPES.ACTIONS.CONFIRM_STORNO_WARNUNG]({ commit, getters }, payload = {}) {

    payload.filters = getters[STORNO_WARNUNG_TYPES.GETTERS.DEFAULT_FILTERS] || null;
    axios.post(`${process.env.VUE_APP_API}/stornoWarnungen/confirmStornos`, payload, config).then(response => {
      if (response.status === 200 && response.data) {
        if (payload.isOverview) {
            commit(STORNO_WARNUNG_TYPES.MUTATIONS.GET_STORNO_WARNUNG_OVERVIEW_SUCCESS, response.data)
        } else {
            const pageIndex = payload.pageIndex || 0;
            commit(STORNO_WARNUNG_TYPES.MUTATIONS.GET_STORNO_WARNUNG_SUCCESS, {...response.data, pageIndex, fullReset: true});
            commit(STORNO_WARNUNG_TYPES.MUTATIONS.UPDATE_PAGE_INDEX, pageIndex);
        }
      }
    });
  },

  async [STORNO_WARNUNG_TYPES.ACTIONS.EXECUTE_FREIGABE]({ commit }, payload = {}) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API}/stornoWarnungen/executeFreigabe`, payload, config).then((response) => {
        if (!response.data?.errorStr && response.data?.freigabe) {
          payload.freigabe = response.data.freigabe;
          commit(STORNO_WARNUNG_TYPES.MUTATIONS.EXECUTE_FREIGABE_SUCCESS, payload);
        }
        resolve(response?.data || {});
      }, (error) => {
        reject(error)
      });
    });
  },

  async [STORNO_WARNUNG_TYPES.ACTIONS.SEND_COMDIRECT_DOCUMENTS]({ dispatch }, payload = {}) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API}/stornoWarnungen/sendComdirectDocuments`, payload, config).then((response) => {
        if (response?.data?.errorStr){
          let actions = undefined
          if (response?.data?.checkProtocols){
            actions = [{
              label: 'Versandprotokolle anzeigen',
              isPrimary: true,
              path: `/communication/versandprtokolle/eingang/${response.data.checkProtocols}`
            }]
          }
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.errorStr, 'danger', true, undefined, actions))
        }
        if (response?.data?.reloadStornos){
          if (payload?.isOverview){
            dispatch(STORNO_WARNUNG_TYPES.ACTIONS.GET_STORNO_WARNUNG_OVERVIEW)
          } else {
            dispatch(STORNO_WARNUNG_TYPES.ACTIONS.GET_STORNO_WARNUNG)
          }
        }
        resolve(response?.data || {});
      }, (error) => {
        reject(error)
      });
    });
  },
  
  async [STORNO_WARNUNG_TYPES.ACTIONS.CREATE_ATTACHMENT_TEMP_FILE]({ commit }, payload) {

    await axios.get(`${process.env.VUE_APP_API}/stornoWarnungen/createAttachmentTempFile?fileId=${payload.fileId}&dokId=${payload.dokId}`, config).then(response => {
      if (response.status === 200 && response.data) {
        commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, [response.data]);
      }
    });
  },

  async [STORNO_WARNUNG_TYPES.ACTIONS.GET_EMAIL_DATA_KUNDE]({ commit }, payload) {
    if (payload?.stornoId) {
      const storno = { versSchadenId: payload.versSchadenId || '', art: payload.art, beschreibung: payload.beschreibung, kontoNr: payload.kontoNr}
      const response = await axios.post(`${process.env.VUE_APP_API}/stornoWarnungen/get_email_data_kunde?stornoId=${payload.stornoId}&kundennr=${payload.kundennr}&fileId=${payload.fileId || ''}&dokId=${payload.dokId || ''}`, 
        storno, config);
      if (response?.status === 200 && response.data?.attachments?.length) {
        commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, [response.data.attachments[0]]);
      }
      return response?.data;
    } else {
      return null;
    }
  },

  async [STORNO_WARNUNG_TYPES.ACTIONS.GET_EMAIL_DATA_BEARBEITENDER]({ commit }, payload) {
    if (payload?.stornoId) {
      const storno = { versSchadenId: payload.versSchadenId || '', art: payload.art, beschreibung: payload.beschreibung, kontoNr: payload.kontoNr}
      const response = await axios.post(`${process.env.VUE_APP_API}/stornoWarnungen/get_email_data_bearbeitender?stornoId=${payload.stornoId}&kundennr=${payload.kundennr}&fileId=${payload.fileId || ''}&dokId=${payload.dokId || ''}&versId=${payload.versId || ''}`, 
        storno, config);
      if (response?.status === 200 && response.data?.attachments?.length) {
        commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, [response.data.attachments[0]]);
      }
      return response?.data;
    } else {
      return null;
    }
  },
  
  async [STORNO_WARNUNG_TYPES.ACTIONS.SIGNO_VIEWER]({ }, stornoId) {
    const response = axios.get(`${process.env.VUE_APP_API}/stornoWarnungen/getLinkToSignoViewer?stornoId=${stornoId}`, config);
    return response;
  },

}