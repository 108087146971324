import VERMITTLERNUMMERN_TYPES from './types';
import CORE_TYPES from '../core/types';
import axios from 'axios';
import { buildMessage, } from '../../helpers/log-message-helper';
import * as utils from '../../helpers/commonfunctions';

export default {
  [VERMITTLERNUMMERN_TYPES.ACTIONS.RETRIEVE_VERSICHERUNGEN_DATA]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/vermittlernummern/getVersicherungen';
      const mitZweigstellen = payload?.mitZweigstellen || false;
      serviceUrl = `${serviceUrl}?mitZweigstellen=${mitZweigstellen}`;
  
      const vermittlernummer = payload?.vermittlernummer;
      if (vermittlernummer) {
        serviceUrl = `${serviceUrl}&vermittlernummer=${vermittlernummer}`;
      }

      const gesellschaftParam = payload?.gesellschaft;
      if (gesellschaftParam) {
        serviceUrl = `${serviceUrl}&gesellschaftParam=${gesellschaftParam}`;
      }      
     
  
      axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
        if (response && response.data) {
  
          commit(VERMITTLERNUMMERN_TYPES.MUTATIONS.RETRIEVE_VERSICHERUNGEN_SUCCESS, response.data);
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });



  },

  [VERMITTLERNUMMERN_TYPES.ACTIONS.PERSIST_ROW]({ getters, dispatch, commit }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/vermittlernummern/persist`, payload, config)
        .then((response) => {
          commit(VERMITTLERNUMMERN_TYPES.MUTATIONS.PERSIST_ROW_SUCCESS, response.data);
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },  

  [VERMITTLERNUMMERN_TYPES.ACTIONS.REMOVE_ROW]({ commit, state, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };    
   
    let url = '/vermittlernummern/removeItem';

    if(payload && payload.id) {
      url = `${url}?id=${payload.id}`;
    }

    return new Promise((resolve, reject) => {
      axios.delete(rootState.core.apiAddress + url, config).then(response => {
        if (response?.data) {
          commit(VERMITTLERNUMMERN_TYPES.MUTATIONS.REMOVE_ROW_SUCCESS, response.data);
          resolve(response.data);
        } else {
          reject();
        }
      })
    });


  
  }, 

  [VERMITTLERNUMMERN_TYPES.ACTIONS.GET_OTHER_TABS_DATA]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/vermittlernummern/getOtherTabsRows';
      const mitZweigstellen = payload?.mitZweigstellen || false;
      serviceUrl = `${serviceUrl}?mitZweigstellen=${mitZweigstellen}`;
  
      const vermittlernummer = payload?.vermittlernummer;
      if (vermittlernummer) {
        serviceUrl = `${serviceUrl}&vermittlernummer=${vermittlernummer}`;
      }

      const gesellschaftParam = payload?.gesellschaft;
      if (gesellschaftParam) {
        serviceUrl = `${serviceUrl}&gesellschaftParam=${gesellschaftParam}`;
      }      
     
      const gesellschaftsArtParam = payload?.gesellschaftsArtParam;
      if (gesellschaftsArtParam) {
        serviceUrl = `${serviceUrl}&gesellschaftsArtParam=${gesellschaftsArtParam}`;
      }        
  
      axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
        if (response && response.data) {
  
          commit(VERMITTLERNUMMERN_TYPES.MUTATIONS.GET_OTHER_TABS_DATA_SUCCESS, response.data);
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });



  },  

  [VERMITTLERNUMMERN_TYPES.ACTIONS.GET_ALLE_MITARBEITER_DATA]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/vermittlernummern/getFurAlleMitarbeiterData';
  
      const gesellschaftParam = payload?.gesellschaft;
      if (gesellschaftParam) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'gesellschaftParam', gesellschaftParam);
      }       
     
      const gesellschaftsArtParam = payload?.gesellschaftsArtParam;
      if (gesellschaftsArtParam) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'gesellschaftsArtParam', gesellschaftsArtParam);
      }        
  
      axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
        if (response && response.data) {
  
          commit(VERMITTLERNUMMERN_TYPES.MUTATIONS.GET_ALLE_MITARBEITER_DATA_SUCCESS, response.data);
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });



  },    

  

}