<template >
    <div class="event-body" v-if="this.isLoaded">
        <BaseModal ref="modal" @onConfirmButton="save" @onCancelButton="close" :showConfirmButton="dat!=null && id!=null" size="full">
            <template v-slot:modalTitle>
                Bemerkung
            </template>
            <BoxContainer>
                <InputField label="Thema" v-model="dat.subject" />

                <InputTextArea label="Text" :rows="5" v-model="dat.text" />

            </BoxContainer>





        </BaseModal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import InputField from "@/components/core/forms/InputField.vue"
import BaseModal from '@/components/core/BaseModal.vue';
import V_P_TYPES from "@/store/versandProtokolle/types";
import BoxContainer from '@/components/core/BoxContainer.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue'
import LOG_TYPES from '@/store/log/types';
import { buildMessage } from '@/helpers/log-message-helper';
export default {
    data() {

        return {
           
            isLoaded: false,
            id:null,
            dat:null
           
        };
    },
    computed: {

        ...mapGetters({

         
            


        }),


    },
    mounted() {

    },
    watch: {
    b(next, oldValue) {
    }    
    },
    methods: {
       
        

        async open(i) {
             this.id=i; 
             await this.loadData();
           
            this.$refs.modal?.open();
        },
        close() {

            this.$refs.modal.close();
        },

        async save() {
            if (this.id != null  &&  this.dat  !=null)   {

                let e ={};
                e.id=this.id;
                e.bemerkung=this.dat;
                await this.$store.dispatch(V_P_TYPES.ACTIONS.SET_VP_BEMERKUNG, e);
                this.$store.commit(V_P_TYPES.MUTATIONS.RELOAD,true);
            } else {
                this.$store.dispatch(LOG_TYPES.ACTIONS.WARN, buildMessage("Unerwartet", 'danger'));
                return false;
            }
        },

        async loadData() {
             
            await this.$store.dispatch(V_P_TYPES.ACTIONS.GET_VP_BEMERKUNG,this.id);
            this.dat = this.$store.getters[V_P_TYPES.GETTERS.VP_BEMERKUNG];
            this.isLoaded = true;
        }
    },
    components: {
        BaseModal,
       
        InputField,
        InputTextArea,
        
        BoxContainer
    },

}
</script>

<style scoped>
