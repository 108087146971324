import FAHRZEUGDATEN_TYPES from './types';
import Vue from 'vue';

export default {
  [FAHRZEUGDATEN_TYPES.MUTATIONS.FAHRZEUGDATEN_EDIT](state, payload) {
    if(payload){
      Vue.set(state, 'fahrzeugdatenEdit', payload);
    }
  },
  [FAHRZEUGDATEN_TYPES.MUTATIONS.CLEAN_FAHRZEUGDATEN_EDIT](state) {
    Vue.set(state, 'fahrzeugdatenEdit', null);
  },
}
