<template>
  <div>
    <PageHeaderTitleNavigation title="Mustervorlagen" />

    <div class="box__container">
      <Table
        v-if="liste"
        :headers="headers"
        :rows="rows"
        rowId="label"
        :title="isSubItems ? TABLE_TITLE_SUBITEMS : TABLE_TITLE"
      >
        <template v-slot:label="row">
          <DownloadLink
            v-if="row.dateiName && !row.subitem"
            downloadServicePath="/musterVorlage"
            :title="row.label"
            :filename="row.dateiName"
            :queryParams="{}"
          />
          <a v-else
            :tid="_generateTidFromString('mustervorlage' + row.label)"
            @click="open(row)">
            {{ row.label }}
          </a>
        </template>
      </Table>
    </div>
  </div>
</template>
<script>
import Table from "@/components/table2/Table.vue";
import axios from "axios";
import { SlotColumn } from "@/components/table2/table_util.js";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import PageHeaderTitleNavigation from "@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue";
import DownloadLink from "@/components/core/download/DownloadLink.vue";
import { viewDocument } from "@/components/core/download/DownloadLink.vue";
import { base64ToArrayBuffer } from "@/helpers/download-helper";
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';


export default {
  mixins: [InteractiveHelpCommonsMixin],
  data() {
    return {
      loading: false,
      liste: {},
      headers: {
        lockedLeft: [SlotColumn("label", "Mustervorlage")],
      },
      TABLE_TITLE: "Mustervorlagen",
      TABLE_TITLE_SUBITEMS: "Bestandsfreigaben / Bestandsübertragungen",
      isSubItems: false,
    };
  },
  components: {
    Table,
    OptionMenu,
    PageHeaderTitleNavigation,
    DownloadLink,
  },
  mounted() {
    this.retrieveMustervorlagenListe(this.$route.path.includes("subitems"));
  },
  watch: {
    "$route.path": {
      handler: function () {
        this.isSubItems = this.$route.path.includes("subitems");
        this.retrieveMustervorlagenListe(this.isSubItems);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    retrieveMustervorlagenListe(isSubItems) {
      const config = {
        defaultSpinner: true,
      };
      let serviceUrl =
        "/antraegeService/getMustervorlagenListe?subitems=" + isSubItems;
      this.loading = true;
      axios
        .get(`${process.env.VUE_APP_API}` + serviceUrl, config)
        .then((response) => {
          if (response && response.data) {
            this.liste = response.data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    open(row) {
      if(row.dateiName && row.subitem){
        this.$router.push({path:"/intern/mustervorlagen/subitem", query: {dateiName: row.dateiName}})
      } else {
        this.$router
          .push({ path: "/intern/mustervorlagen/subitems"})
          .then(() =>
            this.retrieveMustervorlagenListe(
              this.$route.path.includes("subitems")
            )
          );
      }
    },
  },
  computed: {
    ...mapGetters({
       apiAddress: CORE_TYPES.GETTERS.API_ADDRESS,
    }),
    rows() {
      let resultData = [];
      Object.keys(this.liste).forEach((e) => {
        resultData.push(this.liste[e]);
      });
      return resultData;
    },
  },
  beforeRouteLeave(to, from, next) {  
      this.$addBreadcrumb({
      label: "zurück zur Mustervorlagen",
      to,
      from,
    });
    next();
  }
    
};
</script>
