var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.$appNavigation.currentMenu.parent.label,
          subtitle: _vm.$appNavigation.currentMenu.label,
          defaultMenu: _vm.$appNavigation.currentTabMenu,
          actions: _vm.actions
        },
        on: {
          "action-NEW": function($event) {
            return _vm.navigateToAutomaticDepotLimit({ id: "neu" })
          },
          "action-RESET": function($event) {
            return _vm.doConfirm("zurücksetzen")
          }
        }
      }),
      !_vm.isCustomerView
        ? _c("div", { staticClass: "box__container" }, [
            _c(
              "div",
              {
                staticClass:
                  "layout__negative-margin--8 antrag-action-button__container"
              },
              [
                _c(
                  "div",
                  { staticClass: "mt-4" },
                  [
                    !_vm.isCustomerView
                      ? _c("InputToggleSwitch", {
                          attrs: {
                            label: "Limits der Untermakler anzeigen",
                            inLineLabel: "",
                            suppressValidationMessage: ""
                          },
                          on: {
                            input: function($event) {
                              return _vm.reload()
                            }
                          },
                          model: {
                            value: _vm.includeStruktur,
                            callback: function($$v) {
                              _vm.includeStruktur = $$v
                            },
                            expression: "includeStruktur"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]
            )
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          !_vm.loading && _vm.rows && _vm.rows.length
            ? _c(
                "div",
                [
                  _c("Table", {
                    attrs: {
                      tableId: "11fcafaf-5332-48db-8ba2-1u5ba7251111",
                      title: _vm.TABLE_TITLE,
                      headers: _vm.headers,
                      rows: _vm.rows,
                      rowsPerPage: 20,
                      rowId: "id",
                      mobileConfig: {
                        title: "depot",
                        headers: [
                          "kundenLinkName",
                          "abweichungUnten",
                          "abweichungOben"
                        ]
                      }
                    },
                    on: {
                      "action-edit": _vm.navigateToAutomaticDepotLimit,
                      "action-delete": _vm.removeLimit,
                      "click-kundenLinkName": _vm.openCustomerNewTab
                    }
                  })
                ],
                1
              )
            : _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", title: _vm.TABLE_TITLE }
              })
            : _c("NoData", { attrs: { title: _vm.TABLE_TITLE } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }