<template>
  <div class="formulare-list__container">
    <PageHeaderTitleNavigation
      :title="$appNavigation.currentMenu.label"
      :subtitle="selectedGesellschaft && selectedGesellschaft.name || ''"
      :defaultMenu="customOptionMenu"
    />

    <div v-if="formList && formList.length">
      <div class="box__container" :tid="_generateTidFromString('box__container')">
        <div class="box__title">{{selectedGesellschaft.name}}</div>
        
        <InputField 
          isComponentHalfSize
          placeholder="Suchen nach Bezeichnung"
          v-model="descriptionSearch"/>
      </div>

      <div class="cards__container layout__negative-margin--8">
        <div class="box__container cards__item" :tid="_generateTidFromString('cards__container layout__negative-margin--8')" >
          <Table 
            class="formulare-list__table"
            tableId="0eca709b-022c-4078-871f-633007348bae"
            :tableData="tableData"
            :cardViewEnabled="false"
            :exportEnabled="false"
            :rowsPerPage="20"
            :paginationEnabled="true">
            <template #label="{ data }">
              <a :tid="_generateTidFromString('formulare-table' + data.index)" @click="selectFormsByGesellschaftId(data.row)">
                {{ data.row.name }}
              </a>
            </template>
          </Table>
        </div>
      </div>
    </div>
    <div v-else-if="loadingGesellschaftsFormulare">
      <GhostLoading useBoxContainer isHalfSize>
        <Block type="title" />
        <Block type="form-input" />
      </GhostLoading>

      <GhostLoading type="table" useBoxContainer />
    </div>

  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types'
import { mapGetters } from "vuex";
import Table from '@/components/table/Table.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import ANTRAG_TYPES from '@/store/antrag/types';
import InputField from '@/components/core/forms/InputField.vue'
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import UndrawFillForms from '@/components/icons/undraw/UndrawFillForms.vue'
import UndrawSecureServer from '@/components/icons/undraw/UndrawSecureServer.vue'
import BaseImage from '@/components/core/BaseImage.vue';
import UndrawWallet from '@/components/icons/undraw/UndrawWallet.vue'
import GhostLoading from '@/components/core/loading/GhostLoading.vue'
import Block from '@/components/core/loading/ghost-loading/Block.vue'
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'

export const DEFAULT_OTHER_CATEGORY = 'Sonstiges'
const INITIAL_CUSTOM_OPTION_MENU = [{}, {}, {}];

export default {
  mixins: [InteractiveHelpCommonsMixin],
  data: function() {
    return {
      lagerstelleList: [],
      configuredLagerstelleList: [],
      selectedLagerstelle: {},
      selectedForm: {},
      descriptionSearch: '',
      formList: [],
      // formLinks: [],
      selectedGesellschaft: {},
      loadingMainStructure: false,
      loadingGesellschaftsFormulare: false,
    }
  },
  watch: {
    gesellschaftsMainStructure: {
      handler(newValue) {
        this.lagerstelleList = newValue || [];
      },
      immediate: true,
    },  
    gesellschaftsFormulare: {
      handler(newValue) {
        this.formList = newValue || [];
      },
      immediate: true,
    },  
  },
  computed: {
    ...mapGetters({
      roles: CORE_TYPES.GETTERS.GET_USER_ROLES,
      gesellschaftsFormulare: ANTRAG_TYPES.GETTERS.GESELLSCHAFTS_FORMULARE,
      gesellschaftsMainStructure: ANTRAG_TYPES.GETTERS.GESELLSCHAFTS_MAINSTRUCTURE,
      addressLegacy: CORE_TYPES.GETTERS.API_ADDRESS_LEGACY,
      currentBackPreviousPage: CORE_TYPES.GETTERS.CURRENT_BACK_TO_PREVIOUS_PAGE,
    }),
    customOptionMenu() {
      if (!this.sortedLagerstelleList || !this.sortedLagerstelleList.length) {
        return this.loadingMainStructure ? INITIAL_CUSTOM_OPTION_MENU : [];
      }

      const result = this.sortedLagerstelleList && this.sortedLagerstelleList.filter(menuItem => (!menuItem.isHidden)).map((lagerstelle) => ({
        ...lagerstelle,
        label: lagerstelle.name,
        component: this.componentByLagerstelle(lagerstelle),
        componentProps: {
          src: lagerstelle.gesellschaft?.toUpperCase() ? `${this.addressLegacy}/mrscompanylogo?company=${lagerstelle.gesellschaft?.toUpperCase()}` : null,
          fit: 'contain',
          placeholder: {
            width: '78px',
            height: '48px',
          }
        },
        textBold: this.selectedGesellschaft?.group === lagerstelle.group,
        action: () => {
          this.selectFormsByType(lagerstelle);
        },
      }));

      return result;
    },
    sortedLagerstelleList() {
      if (this.lagerstelleList && this.lagerstelleList.length) {
        let tempList = this.lagerstelleList;
        const sortedList = tempList.sort(this.sortByLabel);
        return sortedList;     
      }
      return [];
    },    
    tableData() {
      const headers = {
          label: {
            dataType: "Slot",
            key: "label",
            label: "Label",
            visible: true,
          },
          name: {
            dataType: "String",
            key: "name",
            label: "Name",
            sum: false,
            visible: false,
          },              
          gesellschaft: {
            dataType: "String",
            key: "gesellschaft",
            label: "Gesellschaft",
            sum: false,
            visible: false,
          },   
          art: {
            dataType: "String",
            key: "art",
            label: "Art",
            sum: false,
            visible: true,
          },   
          group: {
            dataType: "String",
            key: "group",
            label: "Group",
            sum: false,
            visible: false,
          },                       

      };

      const result = {
        headers: headers,
        records: this.formList.map((formItem) => {
           return {
              name: formItem.name,
              gesellschaft: formItem.gesellschaft,
              art: formItem.art,
              group: formItem.group,
           };	
        }),
      };

      if (this.descriptionSearch.length > 0) {
        result.records = result.records.filter(r => r.name.search(new RegExp(this.descriptionSearch, "i")) > -1);
      }

      return result;
    },    
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      this.formList = [];
      await this.retrieveMainStructure();
      this.selectFirstOption();
    },
    selectFirstOption() {
      this.customOptionMenu?.[0]?.action();
    },
    componentByLagerstelle(lagerstelle) {
      switch (lagerstelle?.group) {
        case 'BANK':
          return UndrawWallet;
        case 'WEITERE_UNTERLAGEN':  
          return UndrawFillForms;
        case 'VERSICHERUNG':
          return UndrawSecureServer;
        default:
          return BaseImage;
      }
    },
    sortByLabel(om1, om2) {
      if(!om1.name || !om2.name) {
        return 1;
      }

      return om1.name.toLowerCase().localeCompare(om2.name.toLowerCase());
    },
    async retrieveMainStructure() {
      try {
        this.loadingMainStructure = true;
        await this.$store.dispatch(ANTRAG_TYPES.ACTIONS.RETRIEVE_GESELLSCHAFTS_MAINSTRUCTURE);
      } finally {
        this.loadingMainStructure = false;
      }
    },     
    
    async selectFormsByType(lagerstelle) {
      this.selectedGesellschaft = lagerstelle;

      this.formList = [];

      const payload = {
        filterArt: lagerstelle?.art
      };

      try {
        this.loadingGesellschaftsFormulare = true;
        await this.$store.dispatch(ANTRAG_TYPES.ACTIONS.RETRIEVE_GESELLSCHAFTS_FORMULARE, payload);
      } finally {
        this.loadingGesellschaftsFormulare = false;
      }
    },

    selectFormsByGesellschaftId(lagerstelle) {
      this.formList = [];
      this.$router.push({path: '/beratung/gesellschaft-formulare/formList', query: {gesellschaftId: lagerstelle?.gesellschaft, lagerstelle: lagerstelle?.name}});
    },    
    

  },

  beforeRouteLeave (to, from, next) {
    const breadcrumb = this.selectedGesellschaft?.name || '';
    
    this.$addBreadcrumb({
      label: `zurück zu Formulare`,
      to,
      from,
      breadcrumb,
    });

    next()
  },
  components: {
    Table,
    OptionMenu,
    InputField,
    GhostLoading,
    Block,
    PageHeaderTitleNavigation,
  },
}
</script>

<style scoped>
.formulare-list__item-link {
  color: var(--color-text)
}
.cards__container {
  display: flex;
  flex-wrap: wrap;
}

.cards__item { 
  flex: 1 1 100%;
}

@media (min-width: 576px) {
  .cards__item { 
    flex: 1 1 calc(50% - 16px);
  }
}

@media (min-width: 992px) {  
  .cards__item { 
    flex: 1 1 calc(33.3333% - 16px);
  }
}
</style>
