<template>
  <DragnDropArea class="form-select-file-button__container mb-3" hoverText="Hochladen" @files="onFileChange">
    <BaseFileSelect @files="onFileChange">
      {{ label }}
    </BaseFileSelect>
    <span>{{filename}}</span>
  </DragnDropArea>
</template>

<script>
import BaseFileSelect from "@/components/fileUpload/BaseFileSelect.vue";
import DragnDropArea from '@/components/fileUpload/DragnDropArea.vue';

export default {
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
      default: 'Hochladen',
    },
  },
  data() {
    return {
      filename: null,
    }
  },
  components: {
    BaseFileSelect,
    DragnDropArea,
  },
  methods: {
    onFileChange(files) {
      const reader = new FileReader();
      reader.onload = e => {
        let base64string = reader.result.split(",").pop();
        this.$emit('input', { data: base64string, name: files[0].name, });
      }
      reader.readAsDataURL(files[0]);
      this.filename = files[0].name;
    },
  },
};
</script>

<style scoped>
</style>
