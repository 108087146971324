var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.details
    ? _c(
        "div",
        [
          _vm.rows.length
            ? _c("Table", {
                ref: "table",
                attrs: {
                  tableId: _vm.tableId,
                  cardView: "",
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowId: "isinWkn",
                  hidePagination: ""
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "fwwFundStars",
                      fn: function(row) {
                        return [
                          row.fwwFundStars
                            ? _c(
                                "div",
                                { staticClass: "value--item-value" },
                                _vm._l(_vm.getStars(row.fwwFundStars), function(
                                  star,
                                  index
                                ) {
                                  return _c("PhStar", {
                                    key: "star-" + index,
                                    attrs: { size: 16, weight: "fill" }
                                  })
                                }),
                                1
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2866319440
                )
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }