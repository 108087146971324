var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: { showDefaultButtons: false },
      on: { onCloseButton: _vm.close },
      scopedSlots: _vm._u([
        {
          key: "modalTitle",
          fn: function() {
            return [
              _vm._v(" " + _vm._s(_vm.displayName) + " "),
              _vm.kundennr
                ? _c("span", [_vm._v("(" + _vm._s(_vm.kundennr) + ")")])
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("Ticket", {
        staticClass: "ticket__embedded",
        attrs: { embedded: "", chatId: _vm.chatId, channelId: _vm.channelId }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }