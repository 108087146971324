var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "week-calendar__container",
      class: { "work-days-only": _vm.isWorkDays }
    },
    [
      _vm.compgetWeekCalendarDataReady
        ? _c(
            "div",
            {
              attrs: { id: "weekcalendarapp", "data-calculating-sizes": "true" }
            },
            [
              _c("div", { staticClass: "week-calendar__sticky" }, [
                _c(
                  "div",
                  { staticClass: "weekweekdays" },
                  [
                    _c("div", { staticClass: "time-header" }),
                    _vm._l(_vm.getWeekdayLabels, function(weekday, i) {
                      return _c(
                        "div",
                        {
                          key: weekday[(0)[1]],
                          class: [
                            "weekweekday",
                            _vm.getShownDays[i] &&
                              _vm.getShownDays[i].isToday &&
                              "today"
                          ]
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "clickable",
                              on: {
                                click: function($event) {
                                  return _vm.openDay(_vm.getShownDays[i])
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(weekday) +
                                  " " +
                                  _vm._s(_vm.getShownDays[i].label)
                              )
                            ]
                          )
                        ]
                      )
                    })
                  ],
                  2
                ),
                _vm.getCompWeekCalendarDataReady
                  ? _c("div", { staticClass: "wholeday__container" }, [
                      _c(
                        "div",
                        { staticClass: "daysrow" },
                        [
                          _vm._m(0),
                          _vm._l(_vm.getShownDaysVisible, function(
                            dayobject,
                            index
                          ) {
                            return _c("CalendarWeekDayColumn", {
                              key: "allday" + index,
                              staticClass: "wday",
                              class: {
                                selected: dayobject.isSelected,
                                notselected: !dayobject.isSelected
                              },
                              attrs: {
                                isAllDay: true,
                                calledfrom: "week",
                                istoday: dayobject.isToday,
                                inmonth: dayobject.inMonth,
                                dindex: index,
                                daylabel: dayobject.label,
                                hours: _vm.getShownHours,
                                date: dayobject.day
                              },
                              on: { editEvent: _vm.editEvent }
                            })
                          })
                        ],
                        2
                      )
                    ])
                  : _vm._e()
              ]),
              _vm.getCompWeekCalendarDataReady
                ? _c(
                    "div",
                    {
                      ref: "dayGridHoursEl",
                      staticClass: "daygridhours hours-view",
                      style: { maxHeight: "var(--w-dayGridHoursHeight)" },
                      attrs: { "data-scroll-container": "" }
                    },
                    [
                      _vm.isCurrentLine()
                        ? _c("div", {
                            staticClass: "redline",
                            style: _vm.currentLineStyle
                          })
                        : _vm._e(),
                      _vm._m(1),
                      _c(
                        "div",
                        { staticClass: "daysrow" },
                        [
                          _c(
                            "div",
                            { staticClass: "time-column" },
                            _vm._l(_vm.getShownHours, function(wholehour) {
                              return _c(
                                "div",
                                {
                                  key: wholehour,
                                  staticClass: "wholehour",
                                  style: _vm.setStyle
                                },
                                [_vm._v(_vm._s(wholehour))]
                              )
                            }),
                            0
                          ),
                          _vm._l(_vm.getShownDaysVisible, function(
                            dayobject,
                            index
                          ) {
                            return _c("CalendarWeekDayColumn", {
                              key: index,
                              staticClass: "wday",
                              class: {
                                selected: dayobject.isSelected,
                                notselected: !dayobject.isSelected
                              },
                              attrs: {
                                isAllDay: false,
                                calledfrom: "week",
                                istoday: dayobject.isToday,
                                inmonth: dayobject.inMonth,
                                dindex: index,
                                daylabel: dayobject.label,
                                hours: _vm.getShownHours,
                                date: dayobject.day
                              },
                              on: { editEvent: _vm.editEvent }
                            })
                          })
                        ],
                        2
                      )
                    ]
                  )
                : _vm._e()
            ]
          )
        : _c("CalendarGhostLoading", {
            style: {
              maxHeight: "var(--w-containerHeight)",
              overflow: "hidden"
            },
            attrs: { type: _vm.isWorkDays ? "work-week" : "week" }
          })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "time-column" }, [
      _c("div", { staticClass: "wholeday ellipsis" }, [_vm._v(" Ganztägig ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "daysrow" }, [
      _c("div", { staticClass: "time-column" }),
      _c("div", { staticClass: "border-line" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }