import { VIEW_ROLES } from '@/router/roles'

/**
 * Shared routes
 * > It must not have a menu
 * >> IF YOU NEED TO SET A MENU FOR A ROUTE INSIDE THIS FILE, PLEASE MOVE IT TO A WORKSPACE/FEATURE MENU
 * > Be careful with roles
 */
export default [
  {
    path: 'vermogensubersicht/fondsinfo/:isin/:bgsNr?',
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_CUSTOMER], 
          [VIEW_ROLES.VIEW_BROKER],
          [VIEW_ROLES.VIEW_INTERN],
        ],
      },
    },
    component: () => import(/* webpackChunkName: "fondsinfo" */ '@/components/fondsinfo/Fondsinfo.vue'),
  },
  {
    path: 'beteiligungen/fondsinfo/:isin/:bgsNr?',
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_CUSTOMER], 
          [VIEW_ROLES.VIEW_BROKER],
          [VIEW_ROLES.VIEW_INTERN],
        ],
      },
    },
    component: () => import(/* webpackChunkName: "fondsinfo" */ '@/components/fondsinfo/Fondsinfo.vue'),
  },
];
