export const MUTATION_PREFIX = 'VERMITTLERWECHSEL_STATUS_MUTATIONS_';
export const ACTIONS_PREFIX = 'VERMITTLERWECHSEL_STATUS_ACTIONS_';
export const GETTERS_PREFIX = 'VERMITTLERWECHSEL_STATUS_GETTERS_';

export default {
  MUTATIONS: {
    RETRIEVE_VERMITTLERWECHSEL_STATUS_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_VERMITTLERWECHSEL_STATUS_SUCCESS',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    REMOVE_ROW_SUCCESS: MUTATION_PREFIX + 'REMOVE_ROW_SUCCESS',
    PERSIST_ROW_SUCCESS: MUTATION_PREFIX + 'PERSIST_ROW_SUCCESS',
    SELECTED_EINTRAG: MUTATION_PREFIX + 'SELECTED_EINTRAG',
    UPDATE_STATUS_SUCCESS: MUTATION_PREFIX + 'UPDATE_STATUS_SUCCESS',
    STORE_GESELLSCHAFTEN: MUTATION_PREFIX + 'STORE_GESELLSCHAFTEN',
    RESET_LIST: MUTATION_PREFIX + 'RESET_LIST',
    GET_DATA_FOR_EDITING_SUCCESS: GETTERS_PREFIX + 'GET_DATA_FOR_EDITING_SUCCESS',
    GET_BEISPIELKONTONR_SUCCESS: GETTERS_PREFIX + 'GET_BEISPIELKONTONR_SUCCESS',
  },
  ACTIONS: {
    RETRIEVE_VERMITTLERWECHSEL_STATUS: ACTIONS_PREFIX + 'RETRIEVE_VERMITTLERWECHSEL_STATUS',
    REMOVE_ROW: ACTIONS_PREFIX + 'REMOVE_ROW',
    PERSIST_ROW: ACTIONS_PREFIX + 'PERSIST_ROW',
    UPDATE_STATUS: ACTIONS_PREFIX + 'UPDATE_STATUS',
    GET_DATA_FOR_EDITING: ACTIONS_PREFIX + 'GET_DATA_FOR_EDITING',
    GET_BEISPIELKONTONR: ACTIONS_PREFIX + 'GET_BEISPIELKONTONR',
  },
  GETTERS: {
    VERMITTLERWECHSEL_STATUS: GETTERS_PREFIX + 'VERMITTLERWECHSEL_STATUS',
    SELECTED_EINTRAG: GETTERS_PREFIX + 'SELECTED_EINTRAG',
    UPDATE_STATUS_RESULT: GETTERS_PREFIX + 'UPDATE_STATUS_RESULT',
    GESELLSCHAFTEN: GETTERS_PREFIX + 'GESELLSCHAFTEN',
    DATA_FOR_EDITING: GETTERS_PREFIX + 'DATA_FOR_EDITING',
    BEISPIELKONTONR: GETTERS_PREFIX + 'BEISPIELKONTONR',
  }
}