<template>
  <div class="box__container">
        <BaseCollapsable :showSection="showExpanded">
            <template v-slot:title>
                <div class="box__title">Hinweise</div>
            </template>

             <template v-slot:content>
                <div class="antrag-sidetext__container mt-8px">
                    <div>SSO (single-sign-on) ermöglicht die Verwaltung Ihres Zugangs. Sie können weiteren Personen einen Zugang ermöglichen oder weitere Nutzer zu Ihrem Zugang hinzufügen.</div>
                    <div class="mt-2">Besteht noch kein SSO-Zugang, können Sie eine Einladung an den Nutzer versenden, der hinzugefügt werden soll. </div>
                    <div class="mt-2" v-if="isSsoKonto">Sie haben erfolgreich einen Zugang eingerichtet. Bitte teilen Sie Ihren Login Namen der Person mit, die Ihnen einen Zugang einrichten möchte. Dies ermöglicht diesem, Ihnen eine Einladung für Ihren Zugang zukommen zu lassen können.</div>
                </div>
            </template>
        </BaseCollapsable>
    </div>
</template>

<script>
import BaseCollapsable from '@/components/core/BaseCollapsable.vue'

export default {
    props: {
        isSsoKonto: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        BaseCollapsable,
    },
    data() {
        return {
            showExpanded: true,
        }
    }
}
</script>

<style>

</style>