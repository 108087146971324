import STORNO_WARNUNG_TYPES from './types';
import { getInitialState } from './index';
import Vue from 'vue';

export default {
  [STORNO_WARNUNG_TYPES.MUTATIONS.GET_STORNO_WARNUNG_OVERVIEW_SUCCESS](state, payload) {
    Vue.set(state, 'stornoWarnungOverview', Object.assign([], payload?.stornoWarnungList || []));
  },

    [STORNO_WARNUNG_TYPES.MUTATIONS.GET_STORNO_WARNUNG_SUCCESS](state, {stornoWarnungList, pageIndex, totalStornosLength, fullReset}) {
        Vue.set(state, 'totalRows', totalStornosLength);
        if (fullReset || pageIndex == 0 && state.stornoWarnungList[0]) {
            Vue.set(state, 'stornoWarnungList', {[pageIndex]: stornoWarnungList || []});
        } else {
            Vue.set(state.stornoWarnungList, pageIndex, stornoWarnungList || []);
        }
    },
 
  [STORNO_WARNUNG_TYPES.MUTATIONS.UPDATE_PAGE_INDEX](state, pageIndex) {
    Vue.set(state, 'pageIndex', pageIndex || 0);
  },

  [STORNO_WARNUNG_TYPES.MUTATIONS.RESET_STORNOS](state, commit) {
    Vue.set(state, 'stornoWarnungList', {});
    Vue.set(state, 'pageIndex', 0);
    // Vue.set(state, 'defaultFilters', null);
    commit(STORNO_WARNUNG_TYPES.MUTATIONS.RESET_FILTERS);
  },

  [STORNO_WARNUNG_TYPES.MUTATIONS.SET_FILTERS](state, payload) {
    if (payload?.filters) {
      Vue.set(state, 'isFilterSaved', true);
      Vue.set(state, 'defaultFilters', Object.assign({}, payload.filters));
    }
  },

  [STORNO_WARNUNG_TYPES.MUTATIONS.GET_STORNO_ARTS_SUCCESS](state, payload) {
    Vue.set(state, 'stornoArts', Object.assign([], payload || []));
  },

  [STORNO_WARNUNG_TYPES.MUTATIONS.EXECUTE_FREIGABE_SUCCESS](state, payload) {
    if (payload?.stornoId && payload?.freigabe) {
      if (payload.isOverview) {
        state.stornoWarnungOverview = Object.assign([], state.stornoWarnungOverview || []);
        state.stornoWarnungOverview.filter(storno => storno?.stornoId === payload.stornoId).map(storno => storno.freigabe = payload.freigabe);
      } else {
        state.stornoWarnungList = Object.assign({}, state.stornoWarnungList || {});
        state.stornoWarnungList[state.pageIndex]?.filter(storno => storno?.stornoId === payload.stornoId).map(storno => storno.freigabe = payload.freigabe);
      }
    }
  },

  [STORNO_WARNUNG_TYPES.MUTATIONS.SET_IS_LOADING](state, payload) {
    Vue.set(state, 'isLoading', payload ? true : false);
  },

  [STORNO_WARNUNG_TYPES.MUTATIONS.SET_STORNO_SELECTED](state, payload) {
    Vue.set(state, 'stornoSelected', payload);
  },
  
  [STORNO_WARNUNG_TYPES.MUTATIONS.RESET_FILTERS](state) {
    Vue.set(state, 'isFilterSaved', false);
    Vue.set(state, 'defaultFilters', getInitialState().defaultFilters);
  },

  [STORNO_WARNUNG_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },
}
