var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ComboBox", {
        attrs: {
          label: "Typ",
          validateUntouched: "",
          values: _vm.isEinnahme ? _vm.einnahmeTypes : _vm.ausgabeTypes
        },
        on: { input: _vm.emitChange },
        model: {
          value: _vm.form.type,
          callback: function($$v) {
            _vm.$set(_vm.form, "type", $$v)
          },
          expression: "form.type"
        }
      }),
      !_vm.isSparquote
        ? _c("InputField", {
            attrs: {
              label: "Beschreibung",
              placeholder: "Name",
              type: "text",
              validateUntouched: ""
            },
            on: { input: _vm.emitChange },
            model: {
              value: _vm.form.title,
              callback: function($$v) {
                _vm.$set(_vm.form, "title", $$v)
              },
              expression: "form.title"
            }
          })
        : _vm._e(),
      _vm.isPeriodicEvent
        ? _c("ComboBox", {
            attrs: { label: "Zeitraum", values: _vm.frequenzTypes },
            model: {
              value: _vm.form.frequenz,
              callback: function($$v) {
                _vm.$set(_vm.form, "frequenz", $$v)
              },
              expression: "form.frequenz"
            }
          })
        : _vm._e(),
      _vm.isPeriodicEvent || _vm.isSparquote
        ? _c("DatePickerField", {
            attrs: {
              label: "Startdatum",
              placeholder: "MM.JJJJ",
              monthPicker: "",
              isValueAsString: ""
            },
            model: {
              value: _vm.form.startDate,
              callback: function($$v) {
                _vm.$set(_vm.form, "startDate", $$v)
              },
              expression: "form.startDate"
            }
          })
        : _vm._e(),
      !_vm.isSparquote
        ? _c("DatePickerField", {
            attrs: {
              label: _vm.isPeriodicEvent ? "Endtermin" : "Datum",
              placeholder: "MM.JJJJ",
              monthPicker: "",
              isValueAsString: ""
            },
            model: {
              value: _vm.form.endDate,
              callback: function($$v) {
                _vm.$set(_vm.form, "endDate", $$v)
              },
              expression: "form.endDate"
            }
          })
        : _vm._e(),
      _c("InputField", {
        attrs: { label: "Betrag", type: "currency", validateUntouched: "" },
        on: { input: _vm.emitChange },
        model: {
          value: _vm.form.betrag,
          callback: function($$v) {
            _vm.$set(_vm.form, "betrag", $$v)
          },
          expression: "form.betrag"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }