var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fonds_container" },
    [
      _c("div", { staticClass: "banner" }, [_vm._v("FONDSSHOP")]),
      _c("hr"),
      _c("div", { staticClass: "einzelfonds" }, [
        !_vm.isIntern && _vm.isMounted
          ? _c(
              "div",
              [_c("fondsshop-element", { attrs: { zeige: _vm.selectedFond } })],
              1
            )
          : _vm._e(),
        _vm.isIntern
          ? _c("div", { staticClass: "fc-alert fc-alert-warning" }, [
              _vm._v("Hier kommt der Fondsshopsanteil.")
            ])
          : _vm._e()
      ]),
      _vm._m(0),
      _c("risiko-hinweise")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "beachten_wrapper" }, [
      _c("div", { staticClass: "beachten" }, [
        _c("p", [
          _c("b", [_vm._v("Beachten Sie:")]),
          _vm._v(
            " Die Angaben über die bisherige Wertentwicklung stellen keine Prognose für die Zukunft dar. Zukünftige Ergebnisse der Investmentanlage sind insbesondere von den Entwicklungen der Kapitalmärkte abhängig. Die Kurse an der Börse können steigen und fallen. Investmentfonds unterliegen dem Risiko sinkender Anteilspreise, da sich Kursrückgänge der im Fonds enthaltenen Wertpapiere im Anteilspreis widerspiegeln. Bei der Darstellung der Wertentwicklung sind eventuell anfallende Entgelte - z. B. für den Wertapierkauf und -verkauf - nicht berücksichtigt. Wie sich Kosten auswirken können erfahren Sie unter Details! "
          )
        ]),
        _c("p", [_vm._v("Datenquelle: FWW Fondsdaten (https://www.fww.de/)")]),
        _c("p", [
          _vm._v(
            " Unsere Homepage bietet Ihnen die Möglichkeit, auf Eigeninitiative "
          ),
          _c("b", [_vm._v("selbständig und kostengünstig")]),
          _vm._v(" Fonds zu erwerben. Unser Fondsshop bietet "),
          _c("b", [_vm._v("keine Anlageberatung")]),
          _vm._v(
            " für Sie. Er ist für Kunden konzipiert, die als Selbstentscheider eine eigene Investmententscheidung in die Tat umsetzen wollen. Die nachfolgenden Darstellungen enthalten "
          ),
          _c("b", [_vm._v("keine persönliche Empfehlung")]),
          _vm._v(" zu Geschäften mit bestimmten Finanzinstrumenten. "),
          _c("b", [
            _vm._v(
              " Eine Prüfung der persönlichen Umstände des Anlegers erfolgt nicht, ebenso wenig eine Prüfung, ob die jeweiligen Fonds für den Kunden geeignet sind. "
            )
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }