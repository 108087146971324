var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", [
        _vm.isBroker || _vm.isIntern
          ? _c(
              "div",
              { staticClass: "text-small edit", on: { click: _vm.edit } },
              [
                _vm._v(" bearbeiten "),
                _c("ph-pencil-line", {
                  attrs: { title: "Bearbeiten", size: 16 }
                })
              ],
              1
            )
          : _vm._e(),
        _c("div", { staticClass: "inner_content" }, [
          _vm.getNotOuterError
            ? _c("div", [
                _c("img", {
                  attrs: { src: _vm.getComputedInhalt },
                  on: {
                    error: function($event) {
                      return _vm.handleOuterError()
                    }
                  }
                })
              ])
            : _vm._e()
        ])
      ]),
      _c(
        "BaseModal",
        {
          ref: "EditableImagesModal",
          attrs: { modalTitle: "Bitte wählen Sie ein Bild aus" },
          on: {
            onConfirmButton: function($event) {
              return _vm.onConfirmModal()
            },
            onCancelButton: function($event) {
              return _vm.onCancelModal()
            }
          }
        },
        [
          _c("div", { staticClass: "selector_class" }, [
            _c("div", { staticClass: "preview" }, [
              _vm.getNotInnerError
                ? _c("div", [
                    _c("img", {
                      attrs: { src: this.selectedImage },
                      on: {
                        error: function($event) {
                          return _vm.handleInnerError()
                        }
                      }
                    })
                  ])
                : _vm._e()
            ]),
            _c(
              "div",
              { staticClass: "image_grid" },
              _vm._l(_vm.images, function(image, index) {
                return _c("div", { key: index }, [
                  _c(
                    "div",
                    {
                      staticClass: "image_grid_item",
                      on: {
                        click: function($event) {
                          return _vm.selectThis(image, index)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "grid_item_image" }, [
                        _c("img", {
                          class: {
                            image_selected: _vm.isSelected(index),
                            image_not_selected: !_vm.isSelected(index)
                          },
                          attrs: { src: image.pathLong }
                        })
                      ])
                    ]
                  )
                ])
              }),
              0
            ),
            _c(
              "div",
              { staticStyle: { margin: "1rem auto" } },
              [
                _c(
                  "BaseButton",
                  { attrs: { isSecondary: "" }, on: { click: _vm.noImage } },
                  [_vm._v("Kein Bild")]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }