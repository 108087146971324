<template>
  <div v-if="customerDataEdited">
    <Table
      v-if="rows.length"
      ref="table"
      :tableId="tableId"
      cardView
      :headers="headers"
      :rows="rows"
      rowId="id"
      :headersSize="isCustomerOnly ? null : 5"
      showWeiterLink
      hidePagination
      @onNavigate="navigateToCard"
    >
    </Table>
    <NoData v-else noIcon />
  </div>
</template>


<script>
import BoxContainer from "@/components/core/BoxContainer.vue";
import { PhPencilLine, PhBookOpen } from "phosphor-vue";
import { TextColumn, CurrencyColumn } from "@/components/table2/table_util.js";
import Table from "@/components/table2/Table.vue";
import { mapGetters } from "vuex";
import CUSTOMERDATA_TYPES from "@/store/customerData/types";
import CORE_TYPES from "@/store/core/types";
import persoenlicheDatenMixin from "../persoenliche-daten-mixin";
import stammdatenMixin from "@/components/persoenliche-daten/stammdaten/stammdaten-mixin";
import NoData from '@/components/core/NoData.vue';


export default {
  components: {
    BoxContainer,
    PhPencilLine,
    PhBookOpen,
    Table,
    NoData,
  },
  mixins: [persoenlicheDatenMixin, stammdatenMixin],
  data: function () {
    return {
      title: "Legitimationsurkunde",
      legitimation: {
        legitimType: "",
        legitimNumber: "",
        authority: "",
        dateOfIssue: "",
        validTo: "",
        bildVorderseite: null,
        bildRueckseite: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      customerDataEdited: CUSTOMERDATA_TYPES.GETTERS.GET_CUSTOMER_DATA_EDITED,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      isFK: CORE_TYPES.GETTERS.IS_FK,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      personId: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_ID,
      customerDataConfig: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_CONFIG,
      isCustomerOnly: CORE_TYPES.GETTERS.IS_CUSTOMER_ONLY,
    }),
    rechtsform() {
      return CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA?.personalDataAddress?.rechtsform;
    },
    headers() {
      const result = {
        center: [],
      };

      result.center.push(TextColumn("legitimType", "Art der Urkunde"));
      result.center.push(TextColumn("legitimNumber", "Ausweisnummer"));
      result.center.push(TextColumn("authority", "Ausstellungsbehörde"));
      result.center.push(TextColumn("dateOfIssue", "Ausstellungsdatum"));
      result.center.push(TextColumn("validTo", "Gültig bis"));

      return result;
    },
    rows() {
      const rows = [];

      const row = {};

      this.customerDataConfig.legitimationTypes?.find(
        (cb) => cb.value === this.legitimation?.legitimType
      )?.label || "-";

      row.legitimNumber = this.legitimation?.legitimNumber || "-";
      row.authority = this.legitimation?.authority || "-";
      row.dateOfIssue = this.legitimation?.dateOfIssue || "-";
      row.validTo = this.legitimation?.validTo || "./.";

      rows.push(row);
      return rows;
    },
    tableId() {
      const uniqueUUID = "9bfbadca-a51c-4413-9dec-9d29af0fd507";
      return uniqueUUID;
    },
  },
  methods: {
    navigateToCard(row) {
      this.$router.push(
        `/persoenlichedaten/customer-data/steps/stammdaten?substep=legitimationsurkunde`
      );
    },
    currency(value) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €";
    },
    updateGroupsIntoData() {
      this.updateGroupIntoData("legitimation");
    },
  },
  mounted() {
    this.updateGroupsIntoData();
  },
  validators: {},
};
</script>

<style scoped>
.align-end {
  flex: 1 1 auto;
  align-self: flex-end;
  padding: 0px 0px 16px 5px;
  color: var(--color-text);
}
.form-control {
  display: flex;
  align-content: stretch;
  align-items: center;
}
</style>