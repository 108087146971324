var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _vm._l(_vm.iconList, function(item, i) {
        return [
          _c(
            "span",
            { key: i, attrs: { title: item.title } },
            [
              _c(
                item.icon,
                _vm._b(
                  {
                    tag: "component",
                    on: {
                      click: function($event) {
                        return _vm.onClick(i, item)
                      }
                    }
                  },
                  "component",
                  item,
                  false
                )
              )
            ],
            1
          )
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }