export const MUTATION_PREFIX = 'VERSICHERUNGSANTRAEGE_MUTATION_: ';
export const ACTIONS_PREFIX = 'VERSICHERUNGSANTRAEGE_ACTIONS_';
export const GETTERS_PREFIX = 'VERSICHERUNGSANTRAEGE_GETTERS_';

export default {
  GETTERS: {
    GET_ATTACHMENT_LINK_MAKER: GETTERS_PREFIX + 'GET_ATTACHMENT_LINK_MAKER',
  },
  MUTATIONS: {
  },
  ACTIONS: {
    GET_FILE_DATA: ACTIONS_PREFIX + 'GET_FILE_DATA',
  },
}

