<template>
  <div :style="style">
    <v-chart class="chart" :option="option" :theme="themeKey" autoresize />
  </div>
</template>

<script>
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { BarChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from 'echarts/components';
import VChart from 'vue-echarts';

import echartsMixin from './echarts-mixin';

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  name: 'BarChart',
  mixins: [echartsMixin],
  components: {
    VChart
  },
  props: {
    chartData: {
      type: Array,
      default: () => [],
    },
    format: {
      type: String,
      default: 'currency',
    },
    numberFixed: {
      type: Number,
      default: 2,
    },
    height: {
      type: String,
      default: '200px',
    },
    labels: {
      type: Array,
      default: () => [],
    },
    isSum: {
      type: Boolean,
      default: false,
    },
    gridLeft: {
      type: Number,
      default: null
    },
  },
  data() {
    return {
    }
  },
  computed: {
    option() {
      return {
        color: this.themeColors,
        backgroundColor: 'transparent',
        grid: {
          ...(this.gridLeft && { left: this.gridLeft })
        },
        tooltip: {
          show: this.isTooltip,
          formatter: this.tooltipFormatter,
          position: { left: 'center', top: 'auto'},
        },
        xAxis: {
          type: 'category',
          data: this.labels ? this.labels.map(v => String(v)) : (this.chartData || []).map(cd => cd.name),
          axisLabel: {
            fontSize: 10,
          },
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: this.axisLabelFormatter(this.axisLabelFormatterShowOption),
            fontSize: 10,
          },
        },
        series: this.chartData.map((value, i) => ({
            type: 'bar',
            stack: 'Total',
            ...(this.isSum && i === (this.chartData.length - 1) ? {label: {
              show: true,
              position: 'top',
              formatter: v => this.formattedValue(this.chartData.map(dataset => dataset.data[v.dataIndex]).reduce((acc, curr) => (acc + curr), 0)),
            }} : {}),
            name: value.name,
            data: value.data || [],
          })),
      }
    }
  },
};
</script>
