<template>
  <div class="einzelfonds_container">
    <div class="banner">
      <editable-frame type="einzelfonds_title_page" />
    </div>
    <hr>
    <div class="text_only_wrapper">
      <div class="text_only">
        <editable-frame type="einzelfonds_intro_text" />
      </div>
    </div>
    <div class="banner">
      EINZELFONDS AUSWÄHLEN, ANSEHEN, IN DEN WARENKORB LEGEN!
    </div>
    <hr>
    <div class="einzelfonds" v-if="!isIntern">
      <fondsshop-element zeige="nurFonds" />
    </div>
      <div class="fc-alert fc-alert-warning" v-if="isIntern">Hier kommt der Einzelfondsanteil.</div>
    <risiko-hinweise/>
  </div>
</template>
<script>
import EditableFrame from "@/components/cms/EditableFrame.vue";
import RisikoHinweise from "@/components/cms/parts/RisikoHinweise.vue";
import FondsshopElement from "@/components/cms/parts/fondsshopElement.vue";
import CORE_TYPES from "@/store/core/types";
import { mapGetters} from "vuex";
export default {
  components: {
    EditableFrame,
    RisikoHinweise,
    FondsshopElement
  },
    computed: {
    ...mapGetters({
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
    })
  },
   mounted(){
     window.scrollTo(0,0);
  },
};
</script>
<style  scoped>
.banner {
  font-size: 26px;
  text-align: left;
  color: var(--color-text);
}
.einzelfonds_container {
  margin: 0 auto;
  padding-left: 1em;
  padding-right: 1em;
  max-width: 1200px;
}

.beachten_wrapper {
  padding-bottom: 2em;
  margin-bottom: 2em;
}
.beachten {
  margin: 0 auto;
  text-align: justify;
}

.text_only_wrapper {
  padding-bottom: 2em;
  padding-top: 2em;
}
.text_only {
  margin: 0 auto;
  text-align: justify;
}

.einzelfonds{
  padding-bottom: 2em;
}
a{
    color:var(--color-link);
}
</style>