import TYPES from './types';
import Vue from 'vue'
import { getInitialState } from './index'

export default {
  [TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },
  
  [TYPES.MUTATIONS.RESULT](state, payload) {
    state.RESULT = payload
  },
  
}