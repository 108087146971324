export const MUTATION_PREFIX = 'WERTPAPIERINFO_MUTATION_: ';
export const ACTIONS_PREFIX = 'WERTPAPIERINFO_ACTIONS_';
export const GETTERS_PREFIX = 'WERTPAPIERINFO_GETTERS_';

export default {
  GETTERS: {
    FILTER: GETTERS_PREFIX + 'FILTER',
    ACT: GETTERS_PREFIX + 'ACT',
    BASE_URL: GETTERS_PREFIX + 'BASE_URL',
    GET_WATCHLIST: GETTERS_PREFIX + 'GET_WATCHLIST',
  },
  MUTATIONS: {
    CONFIG: MUTATION_PREFIX + 'CONFIG',
    ADD_ACT: MUTATION_PREFIX + 'ADD_ACT',
    RESET_ACT: MUTATION_PREFIX + 'RESET_ACT',
    SET_ACT: MUTATION_PREFIX + 'SET_ACT',
    SET_WATCHLIST: MUTATION_PREFIX + 'SET_WATCHLIST',
  },
  ACTIONS: {
    CONFIG: ACTIONS_PREFIX + 'CONFIG',
    OPTIONS: ACTIONS_PREFIX + 'OPTIONS',
    OUTCOME: ACTIONS_PREFIX + 'OUTCOME',
    LOAD_WATCHLIST: ACTIONS_PREFIX + 'LOAD_WATCHLIST',
    ADD_WATCHLIST: ACTIONS_PREFIX + 'ADD_WATCHLIST',
    UPD_WATCHLIST: ACTIONS_PREFIX + 'UPD_WATCHLIST',
  },
}

