<template>
  <div class="content-with-sidebar__container" :class="{
    'show-sidebar': showSidebar,
    'changing-content': changingContent,
    'content-active': contentActive,
    'sidebar-active': sidebarActive,
  }">
    <div class="content-with-sidebar__sidebar" @transitionend.self="handleTransitionEnd()">
      <div class="content-with-sidebar__sidebar--wrap">
        <slot name="sidebar"></slot>
      </div>
    </div>
    <div class="content-with-sidebar__content" 
      @touchstart="handleSwipeLeft($event)" @touchend="handleSwipeLeft($event)">
      <div class="content-with-sidebar__content--wrap">
        <div class="content-with-sidebar__content--button__container">
          <button type="button" @click="toggleSidebar()" class="content-with-sidebar__content--button--toggle__sidebar clickable">
            <ph-caret-left :size="24" />
          </button>
          <button type="button" @click="backSidebar()" class="content-with-sidebar__content--button--sidebar clickable">
            <ph-caret-left :size="24" />
          </button>
        </div>
        <div class="content-with-sidebar__content--main">
          <slot name="content"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PhCaretLeft } from 'phosphor-vue';

const MIN_SWIPE_LEFT_SIZE = -80;

const SIDEBAR = 'sidebar';
const CONTENT = 'content';

const PROP_MOBILE_CONTENT_VALIDS = [SIDEBAR, CONTENT];

/**
 * ContentWithSidebar
 * 
 * Note:
 * To avoid weird behavior in mobile view (<=768px).
 * You need to set a variable for "mobileContent" to control the content that is visible.
 * And, you need to listen the value from "mobileContentChange" event to update the variable.
 */
export default {
  props: {
    mobileContent: {
      type: String,
      validator: (value) => {
        return PROP_MOBILE_CONTENT_VALIDS.indexOf(value) >= 0;
      },
      default: SIDEBAR,
    }
  },
  emits: ['mobileContentChange'],
  computed: {
    sidebarActive() {
      return !this.mobileContent || this.mobileContent === SIDEBAR;
    },
    contentActive() {
      return this.mobileContent && this.mobileContent === CONTENT;
    },
  },
  data() {
    return {
      showSidebar: true,
      changingContent: false,
      touch: {
        prevPageX: 0,
      },
    };
  },
  watch: {
    mobileContent() {
      this.changingContent = true;
    }
  },
  methods: {
    handleTransitionEnd() {
      this.changingContent = false;
      dispatchEvent(new Event('resize'));
    },
    toggleSidebar() {
      this.showSidebar = !this.showSidebar;
    },
    backSidebar() {
      this.$emit('mobileContentChange', SIDEBAR);
    },
    handleSwipeLeft(event) {
      const currentPageX = event.changedTouches[0].pageX;
      if(event.type === 'touchstart') {
        this.touch.prevPageX = currentPageX;
      } else if(event.type === 'touchend') {
        if(this.touch.prevPageX - currentPageX < MIN_SWIPE_LEFT_SIZE) {
          this.backSidebar();
        }
      }
    }
  },
  components: {
    PhCaretLeft,
  }
}
</script>

<style scoped>
.content-with-sidebar__container {
  display: flex;
  width: 100%;
  max-width: 100%;
}
.content-with-sidebar__sidebar {
  display: flex;
  flex: 0 0 auto;
  overflow-x: hidden;
}
.content-with-sidebar__sidebar--wrap {
  flex: 1 0 300px;
}
.content-with-sidebar__content {
  display: flex;
  flex: 1 1 auto;
  border-left: 1px solid #bbb;
  overflow-x: hidden;
  margin: 0 0 0 16px;
  padding: 0 0 0 16px;
}
.content-with-sidebar__content--wrap {
  flex: 1 1 auto;
  max-width: 100%;
}
.content-with-sidebar__content--button__container {
  margin: 0 0 8px;
}
.content-with-sidebar__content--button__container button {
  background: none;
  border: none;
  display: flex;
  margin: 0;
  padding: 0;
  outline: none;
}
.content-with-sidebar__content--button--toggle__sidebar {
  transition: transform .3s ease;
  transform: rotate(180deg);
}
.show-sidebar .content-with-sidebar__content--button--toggle__sidebar {
  transform: rotate(0deg);
}

/* Desktop */
@media screen and (min-width: 769px) {
  .content-with-sidebar__sidebar {
    max-width: 0;
    transition: max-width .5s ease;
  }
  .show-sidebar .content-with-sidebar__sidebar {
    width: 300px;
    max-width: 300px;
  }
  .content-with-sidebar__content--button__container .content-with-sidebar__content--button--sidebar {
    display: none;
  }
}

@media screen and (max-width: 1024px) and (min-width: 769px) {
  .show-sidebar .content-with-sidebar__sidebar {
    width: 220px;
    max-width: 220px;
  }
  .content-with-sidebar__sidebar--wrap {
    flex-basis: 220px;
  }
}

/* Mobile */
@media screen and (max-width: 768px) {
  .content-with-sidebar__container {
    overflow-x: hidden;
  }
  .content-with-sidebar__sidebar {
    flex: 1 0 auto;
    width: 100%;
    transition: transform .5s ease;
    transform: translate(-100%, 0);
    max-height: 0;
  }
  .sidebar-active .content-with-sidebar__sidebar {
    transform: translate(0, 0);
    max-height: none;
  }
  .content-with-sidebar__sidebar--wrap {
    flex-basis: 100%;
  }
  .content-with-sidebar__content {
    border-left: none;
    flex: 1 0 auto;
    margin: 0;
    padding: 0;
    width: 100%;
    transition: transform .5s ease;
    transform: translate(0, 0);
    max-height: 0;
  }
  .content-active .content-with-sidebar__content {
    transform: translate(-100%, 0);
    max-height: none;
  }
  .changing-content .content-with-sidebar__sidebar,
  .changing-content .content-with-sidebar__content {
    max-height: none;
  }
  .changing-content .content-with-sidebar__sidebar {
    padding-right: 16px;
  }
  .content-with-sidebar__content--button__container .content-with-sidebar__content--button--toggle__sidebar {
    display: none;
  }
}
</style>
