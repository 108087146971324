var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", { attrs: { title: _vm.pageTitle } }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("InputField", {
            attrs: {
              label: "Bezeichnung",
              isComponentHalfSize: "",
              validateUntouched: ""
            },
            on: {
              change: function($event) {
                return _vm.dataChanged()
              }
            },
            model: {
              value: _vm.form.bezeichnung,
              callback: function($$v) {
                _vm.$set(_vm.form, "bezeichnung", $$v)
              },
              expression: "form.bezeichnung"
            }
          }),
          _c("ComboBox", {
            attrs: {
              label: "Gesellschaft",
              values: _vm.gesellschaftValues,
              isComponentHalfSize: "",
              validateUntouched: ""
            },
            on: {
              change: function($event) {
                _vm.dataChangedCombo("gesellId", $event)
                _vm.getGesellschaftBeispielKontonr($event)
              }
            },
            model: {
              value: _vm.form.gesellId,
              callback: function($$v) {
                _vm.$set(_vm.form, "gesellId", $$v)
              },
              expression: "form.gesellId"
            }
          }),
          _c("InputField", {
            attrs: {
              label: "Nummer",
              isComponentHalfSize: "",
              validateUntouched: ""
            },
            on: {
              input: function($event) {
                return _vm.checkFremdnr($event)
              },
              change: function($event) {
                _vm.dataChanged()
                _vm.checkFremdnr($event)
              }
            },
            model: {
              value: _vm.form.fremdnr,
              callback: function($$v) {
                _vm.$set(_vm.form, "fremdnr", $$v)
              },
              expression: "form.fremdnr"
            }
          }),
          _c("InputField", {
            attrs: { label: "Beispiel", isComponentHalfSize: "", disabled: "" },
            model: {
              value: _vm.form.beispielKontonr,
              callback: function($$v) {
                _vm.$set(_vm.form, "beispielKontonr", $$v)
              },
              expression: "form.beispielKontonr"
            }
          }),
          _c("InputField", {
            attrs: {
              label: "Portfolio-Kennung",
              isComponentHalfSize: "",
              disabled: ""
            },
            model: {
              value: _vm.form.portfolioFremdId,
              callback: function($$v) {
                _vm.$set(_vm.form, "portfolioFremdId", $$v)
              },
              expression: "form.portfolioFremdId"
            }
          }),
          _vm.isFAIntern
            ? [
                _c("InputField", {
                  attrs: { label: "Depot Zusatz", isComponentHalfSize: "" },
                  on: {
                    change: function($event) {
                      return _vm.dataChanged()
                    }
                  },
                  model: {
                    value: _vm.form.depotnrZusatz,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "depotnrZusatz", $$v)
                    },
                    expression: "form.depotnrZusatz"
                  }
                })
              ]
            : _vm._e(),
          _c("InputRadioBoxGroup", {
            attrs: { title: "Typ der Nummer", values: _vm.typNummerValues },
            on: {
              input: function($event) {
                return _vm.dataChanged()
              }
            },
            model: {
              value: _vm.form.nummerntyp,
              callback: function($$v) {
                _vm.$set(_vm.form, "nummerntyp", $$v)
              },
              expression: "form.nummerntyp"
            }
          }),
          _c("InputToggleSwitch", {
            attrs: { label: "Depot / Konto ist geschlossen", inLineLabel: "" },
            on: {
              input: function($event) {
                return _vm.dataChanged()
              }
            },
            model: {
              value: _vm.form.depotGeschlossen,
              callback: function($$v) {
                _vm.$set(_vm.form, "depotGeschlossen", $$v)
              },
              expression: "form.depotGeschlossen"
            }
          }),
          _c("InputField", {
            attrs: {
              label: "Depottyp / Beschreibung",
              isComponentHalfSize: ""
            },
            on: {
              change: function($event) {
                return _vm.dataChanged()
              }
            },
            model: {
              value: _vm.form.typ,
              callback: function($$v) {
                _vm.$set(_vm.form, "typ", $$v)
              },
              expression: "form.typ"
            }
          }),
          _vm.isIntern
            ? [
                _c("InputToggleSwitch", {
                  attrs: {
                    label: "Stornorisiko nicht berücksichtigen",
                    inLineLabel: ""
                  },
                  on: {
                    input: function($event) {
                      return _vm.dataChanged()
                    }
                  },
                  model: {
                    value: _vm.form.stornorisikoNichtBeruecksichtigen,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.form,
                        "stornorisikoNichtBeruecksichtigen",
                        $$v
                      )
                    },
                    expression: "form.stornorisikoNichtBeruecksichtigen"
                  }
                })
              ]
            : _vm.form.stornorisikoNichtBeruecksichtigen
            ? _c("div", [_vm._v("Stornorisiko wird nicht berücksichtigt.")])
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }