import actions from './actions';
import mutations from './mutations';
import getters from './getters';

/**
 * mutations: synchronous operation responsible for changing the state
 * actions: asynchronous operation responsible for calling external resources and commiting mutations 
 */

export function getInitialState() {
    return {
        sessionId: null,
        loginName: null,
        email: null,
        phoneNumber: null,
        activated: false,
        sessionValidUntil: null,
        fkAccounts: null,
        ssoInvitations: null,
        ssoAccounts: null,
        showSsoCustomerOverview: false,
        invitations: null,
        referrer: null,
    }
}

export default {
    state: {
        ...getInitialState()
    },
    mutations: {
        ...mutations
    },
    actions: {
        ...actions
    },
    getters: {
        ...getters
    }
}