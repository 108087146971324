<template>
  <div class="container" ref="container" :key="messageListReverse.length">
    <div v-for="message in messageListReverse" :key="message.messageId">
      <div class="message" :class="[
          message.direction ? (message.direction == sending ? 'chat-sender' : 'chat-receiver') : 'chat-center',
          message.isInternalRemark ? 'internalRemark' : ''
        ]">
        <div class="message-body" v-if="showMessageBody(message)">
          <span class="message-text" v-html="message.messageText"></span>
          <span class="chat-info" v-if="message.newStatus && message.messageText" v-html="message.newStatus"></span>

          <div v-for="imgAttachment in message.imgAttachments">
 
            <div class="message-real-download-link__container d-none">
              <!-- This DownloadLink is linked to the message text img click -->
              <DownloadLink :key="imgAttachment.id" asButton title="Anhang" downloadServicePath="/getChatAtt"
                :filename="imgAttachment.name" :queryParams="{ id: imgAttachment.id, }"
                :data-img-attachment-id="imgAttachment.id">
                <img :src="setImgSrc(imgAttachment, message.direction)" />
              </DownloadLink>
            </div>

            <div class="forward">
            <a rel="noopener noreferer" class="d-flex px-0" style="text-overflow: ellipsis" draggable="false"
              :href="null" @click="forwardAttachment(imgAttachment, null, false)">
              <ph-share :size="12" class="mr-8px cursor-pointer" />
              Weiterleiten
            </a>
            <a v-if="customerId"  rel="noopener noreferer" class="d-flex px-0" style="text-overflow: ellipsis" draggable="false"
              :href="null" @click="forwardAttachment(imgAttachment, null, true)">
              <ph-share :size="12" class="mr-8px cursor-pointer" />
              Einem Vertrag zuweisen
            </a>
          </div>
          </div>

          <div v-for="externalAttachment in message.externalAttachments" :key="externalAttachment.id">
            <DownloadLink asButton class="d-flex px-0" title="Anhang" downloadServicePath="/getChatAtt"
              :filename="externalAttachment.name" :queryParams="{ id: externalAttachment.id, }">
              <ph-paperclip :size="18" class="mr-8px cursor-pointer" />
              {{ externalAttachment.name }}
            </DownloadLink>

            <div class="spreadsheet__container" v-if="spreadsheetContent[externalAttachment.id]">
              <Table tableId="f5efcb61-1f0a-4317-90a8-841b2f2b7f5e" :scrollHorizontally="true"
                :headers="spreadsheetContent[externalAttachment.id].headers"
                :rows="spreadsheetContent[externalAttachment.id].rows" />
            </div>
            <div v-else-if="externalAttachment.renderSpreadsheet">
              <AnimatedSpinner />
            </div>
            <div class="forward">
              <a rel="noopener noreferer" class="d-flex px-0" style="text-overflow: ellipsis" draggable="false"
              :href="null" @click="forwardAttachment(externalAttachment, 'application/pdf', false)">
              <ph-share :size="12" class="mr-8px cursor-pointer" />
              Weiterleiten
            </a>
            <a v-if="customerId" rel="noopener noreferer" class="d-flex px-0" style="text-overflow: ellipsis" draggable="false"
              :href="null" @click="forwardAttachment(externalAttachment, 'application/pdf', true)">
              <ph-share :size="12" class="mr-8px cursor-pointer" />
              Einem Vertrag zuweisen
            </a>
            </div>
          </div>
            
          <div v-if="message.isInternalRemark">
            <span class="text-small">Interne Bemerkung <PhLockKey :size="16" /></span>
          </div>

          <div class="chat-delete cursor-pointer" v-if="message.isDeletionAllowed">
            <ph-trash :size="18" @click="openDeleteMessageModal(message.messageId)" />
          </div>
  
        </div>
           
        <div class="message-body" v-if="message.newStatus && !message.messageText">
            <span class="message-text" v-html="message.newStatus"></span>
        </div>
      </div>
      
      <div class="sent-by" :style="{
        justifyContent: message.direction ? (message.direction == sending ? 'flex-end' : 'start') : 'center',
      }">
      <div class="chat-info" v-if="message.direction == sending">
        {{ message.date }} | {{ message.fromName }} |
        <ph-check :size="18" v-if="!message.isGelesen" />
        <ph-checks :size="18" v-else />
      </div>
        <div class="chat-info" v-else-if="message.direction">
          {{ message.date }} | {{ message.fromName }}
        </div>
      <div class="chat-info" v-else>
        {{ message.date }} | {{ message.fromName }}
      </div>
    </div>
  </div>

    <BaseModal ref="forwardAttachmentModal" modalTitle="Anhang weiterleiten" :showDefaultButtons="false"
      :actions="forwardAttachmentModalActions" @action-SAVE="saveForwardAttachementMenu"
      @close="closeForwardAttachementMenu">
      <template>
        <DownloadLink asLabel class="d-flex px-0 pb-2" title="Anhang" downloadServicePath="/getChatAtt"
              :filename="attachmentToForward.name" :queryParams="{ id: attachmentToForward.id, }">
              <ph-paperclip :size="18" class="mr-8px cursor-pointer" />
              {{ attachmentToForward.name }}
            </DownloadLink>
        <ComboBox 
          v-model="insuranceToForwardTo" :values="insuranceList">
        </ComboBox>
      </template>
    </BaseModal>

    <EmailVersand/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import WEBRTC_TYPES from "@/store/webrtc/types";
import COMMUNICATION_TYPES from "@/store/communication/types";
import MAILCOMPOSER_TYPES from "@/store/mailcomposer/types";
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";
import LOG_TYPES from "@/store/log/types";
import { PhTrash, PhCheck, PhChecks, PhPaperclip, PhShare, PhLockKey } from "phosphor-vue";
import sanitizeHtml from "sanitize-html";
import DownloadLink from "@/components/core/download/DownloadLink.vue";
import CORE_TYPES from "@/store/core/types";
import axios from "axios";
import { TextColumn, NumberColumn } from "@/components/table2/table_util.js";
import AnimatedSpinner from "@/components/core/AnimatedSpinner.vue";
import { getColorLimitedListBasedOnLightingDecreasing } from "@/helpers/colors-helper";
import BaseModal from '@/components/core/BaseModal.vue';
import InputCheckBoxItem from '@/components/core/forms/checkbox/InputCheckBoxItem.vue';
import { BaseModalSimpleAction } from '@/components/core/base-modal-actions/base-modal-actions-utils';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import EmailVersand from "@/components/core/EmailVersand.vue";
import InsuranceDetailMixin from '@/components/versicherungen/InsuranceDetail-mixin.js'

export default {
  mixins: [InsuranceDetailMixin],
  components: {
    BaseModal,
    PhTrash,
    PhPaperclip,
    PhCheck,
    PhChecks,
    PhLockKey,
    PhShare,
    PhLockKey,
    DownloadLink,
    AnimatedSpinner,
    InputCheckBoxItem,
    ComboBox,
    EmailVersand
  },
  props: {
    chatId: {
      type: [Number, String],
    },
    channelId: {
      type: [Number, String],
    },
    emailId: {
      type: [Number, String],
    },
  },
  data() {
    return {
      sending: "SENDING",
      receiving: "RECEIVING",
      spreadsheetContent: {},
      forwardToInsuranceCompany: false,
      insuranceToForwardTo: [],
      attachmentToForward: {},
    };
  },
  computed: {
    ...mapGetters({
      chatInfos: WEBRTC_TYPES.GETTERS.CHAT_INFO,
      sanitizeHtmlConfig: COMMUNICATION_TYPES.GETTERS.SANITIZE_HTML_CONFIG,
      apiAddress: CORE_TYPES.GETTERS.API_ADDRESS,
      attachments: MAILCOMPOSER_TYPES.GETTERS.SET_ATTACHMENTS,
      ticketInfo: COMMUNICATION_TYPES.GETTERS.TICKET_INFO,
      insuranceListFiltered: VERSICHERUNG_TYPES.GETTERS.INSURANCES_FILTERED,
    }),
    chatInfo() {
      return (
        this.chatInfos &&
        this.chatInfos.find(
          (chatInfo) =>
            chatInfo.chatId == this.chatId &&
            chatInfo.channelId == this.channelId &&
            (!this.emailId || chatInfo.emailId == this.emailId)
        )
      );
    },
    messageList() {
      return this.chatInfo?.messageList
        ?.map(this.mapUrlImagesIntoAttachments)
        ?.map(this.mapExternalUrlImagesIntoProxy);
    },
    /**
     * Message list reversed to take some advantage on flex container column-reverse
     * This way the browser keeps scroll to bottom on every message list update.
     */
    messageListReverse() {
      if (!this.messageList) {
        return [];
      }
      return [...this.messageList].reverse();
    },
    forwardAttachmentModalActions() {
      return [
        BaseModalSimpleAction('SAVE', 'Weiter').withDisabled(() => !this.insuranceToForwardTo)
      ];
    },
    filteredInsuranceList(){
      if(this.insuranceListFiltered?.pages){
        return this.insuranceListFiltered?.pages[0]
      }
      return undefined
    },
    insuranceList(){
      if(!this.filteredInsuranceList){
        return []
      }else {
        return [...new Set(this.filteredInsuranceList.map((vers) => vers.bezeichnung + " | " + vers.gesellschaft.zweigstelleName))]
      }
    },
    customerId(){
      if(this.ticketInfo){
        return this.ticketInfo?.ticket?.kundennr
      }
    }
  },
  watch: {
    messageList: {
      handler(newValue) {
        this.checkAndLoadSpreadsheetAttachments(newValue);
        this.$nextTick(() => {
          this.addOpenImgAttachmentEvent()
          this.setRgbSenderBackgroundColor()
        });
      },
      immediate: true,
    },
    forwardToInsuranceCompany: {
      handler(newValue){
        if(newValue && this.customerId && !this.filteredInsuranceList){
          this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.GET_INSURANCES_FILTERED, {customerId: this.ticketInfo.ticket.kundennr, activeOnly: true })
        }
      }
    }
  },
  mounted() {
    this.$nextTick(() => this.setRgbSenderBackgroundColor());
  },
  methods: {
    setRgbSenderBackgroundColor() {
      const hexColor = document.documentElement.style.getPropertyValue(
        "--color-primary"
      );
      const colors = getColorLimitedListBasedOnLightingDecreasing(hexColor, {
        quantity: 1,
        fromStepsHigher: 4,
        useChartColorPreset: false,
      });
      
      const warningColor = document.documentElement.style.getPropertyValue('--color-warning') || '#f8d720'; 
      const grayColor = document.documentElement.style.getPropertyValue('--color-box-neutral') || '#efefef'; 

      if (this.$refs.container) {
        this.$refs.container.style.setProperty('--color-background-chat-message', colors?.[0]);
        this.$refs.container.style.setProperty('--color-background-chat-internal-remark', warningColor);
        this.$refs.container.style.setProperty('--color-background-chat-center', grayColor);
      };
    },
    mapExternalUrlImagesIntoProxy(message) {
      let messageText = message?.messageText;

      if (messageText) {
        try {
          const pattern = /\src\=(?:\"|\')(.+?)(?:\"|\')(?:.+?)/gi;
          messageText = messageText.replaceAll(
            pattern,
            `src="${this.apiAddress}/proxy/content?url=$1"`
          );
        } catch (e) {
          this.$store.dispatch(LOG_TYPES.ACTIONS.LOG, e);
        }
      }

      return {
        ...message,
        messageText,
      };
    },
    mapUrlImagesIntoAttachments(message) {
      let messageText = message?.messageText;
      try {
        if (message?.imgAttachments?.length > 0) {
          message?.imgAttachments?.forEach((imgAttachment) => {
            if (imgAttachment.imageData) {
              const pattern = new RegExp(
                `soscomp://chat-attachment/${imgAttachment.id}`,
                "gi"
              );
              messageText = messageText.replaceAll(
                pattern,
                imgAttachment.imageData
              );
            } else {
              messageText = messageText.replaceAll(
                /soscomp:\/\/chat-attachment\/\d+/gi,
                ""
              );
            }
          });
        }
      } catch (e) {
        this.$store.dispatch(LOG_TYPES.ACTIONS.LOG, e);
      }

      return {
        ...message,
        messageText,
      };
    },
    checkAndLoadSpreadsheetAttachments(messageList) {
      if (!messageList?.length) {
        return;
      }

      const attIdsToLoad = messageList
        .map((message) => message.externalAttachments)
        .flatMap((att) => att)
        .filter(
          (att) => att.renderSpreadsheet && !this.spreadsheetContent[att.id]
        )
        .map((att) => att.id);

      attIdsToLoad.forEach(this.loadSpreadsheetContent);
    },
    addOpenImgAttachmentEvent() {
      const imgEls = this.$el?.querySelectorAll?.(".message-text img");

      if (!imgEls?.length) return;

      imgEls.forEach((imgEl) => {
        imgEl.setAttribute("title", "Anhang");
        imgEl.removeEventListener("click", this.openImgAttachment);
        imgEl.addEventListener("click", this.openImgAttachment);
      });
    },
    openImgAttachment(event) {
      const imgEl = event.target;
      if (!imgEl?.src) return;

      const { messageList } = this;
      const message = messageList?.find((message) =>
        message.imgAttachments?.some(
          (imgAttachment) => imgAttachment.imageData === imgEl.src
        )
      );
      const imgAttachment = message?.imgAttachments?.find(
        (imgAttachment) => imgAttachment.imageData === imgEl.src
      );
      if (imgAttachment) {
        document
          .querySelector(`[data-img-attachment-id="${imgAttachment.id}"]`)
          .click();
      }
    },
    loadSpreadsheetContent(chatMessage) {
      const columns = {
        NumberColumn: (h) => NumberColumn(h.key, h.label, 2),
        TextColumn: (h) => TextColumn(h.key, h.label),
      };

      axios
        .get(
          `${this.apiAddress}/spreadsheet_service/chat_attachment_spreadsheet_as_table?chatMessage=${chatMessage}`
        )
        .then((response) => {
          if (response.data) {
            const data = {
              headers: {
                center: response.data.headers.map((h) => columns[h.type]?.(h)),
              },
              rows: response.data.rows.slice(0, 20),
            };

            this.$set(this.spreadsheetContent, chatMessage, data);
          }
        });
    },
    showMessageBody(message) {
      return !!(
        this.getMessageWithoutBody(message.messageText) ||
        message.imgAttachments?.length ||
        message.externalAttachments?.length
      );
    },
    setImgSrc(attachment, direction) {
      if (attachment.imageData != null)
        // WEBRTC_TYPES.ACTIONS.LOAD_ATTACHMENT will set this to an empty string while the loading hasn't completed to prevent duplicate requests
        return attachment.imageData;
      this.$store.dispatch(LOG_TYPES.ACTIONS.INFO, {
        message: "setImgSrc" + direction,
        attachment,
      });
      this.$store.dispatch(WEBRTC_TYPES.ACTIONS.LOAD_ATTACHMENT, attachment.id);
      return "";
    },
    getMessageWithoutBody(message) {
      if (!message) return "";
      return sanitizeHtml(message, this.sanitizeHtmlConfig);
    },
    onOpenFile(id, name) {
      this.$store.dispatch(WEBRTC_TYPES.ACTIONS.OPEN_FILE, {
        attId: id,
        filename: name,
      });
    },
    openDeleteMessageModal(deleteMessageId) {
      this.$confirmModal({
        title: "Nachricht Löschen",
        message: "Möchten Sie diese Nachricht wirklich löschen?",
        labelButtonConfirm: "Löschen",
        showCloseButton: false,
      }).then(() => {
        this.deleteMessage(deleteMessageId);
      });
    },
    deleteMessage(deleteMessageId) {
      this.$store.dispatch(WEBRTC_TYPES.ACTIONS.DELETE_MESSAGE, {
        chatId: this.chatId,
        channelId: this.channelId,
        messageId: deleteMessageId,
      });
    },
    forwardAttachment(attachment, contentType, forwardToInsuranceCompany){
      this.attachmentToForward = {...attachment, contentType}
      this.forwardToInsuranceCompany = forwardToInsuranceCompany
      if(this.forwardToInsuranceCompany){
        this.$refs.forwardAttachmentModal.open();
      }
      else {
        this.prepareForwardAttachment()
        this.$router.push({ path: `/communication/mailcomposer-single`});
        this.$store.commit(VERSICHERUNG_TYPES.MUTATIONS.CLEAR_INSURANCES_FILTERED)
      }
    },
    prepareForwardAttachment() {
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.RESET_STATE)
      this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.CHAT_ATTACHMENT_TO_TEMP_FILE, this.attachmentToForward )
    },
    saveForwardAttachementMenu() {
      this.prepareForwardAttachment()
      if(this.insuranceToForwardTo){
        let antragInfo = this.insuranceToForwardTo.split(" | ")
        let antragsdaten = this.filteredInsuranceList.filter((vers) => vers.bezeichnung == antragInfo[0] && vers.gesellschaft.zweigstelleName == antragInfo[1])[0]
        this.$route.params.id = antragsdaten?.id
        this.emailStart(true, [this.attachments] )
      }
      this.$store.commit(VERSICHERUNG_TYPES.MUTATIONS.CLEAR_INSURANCES_FILTERED)
    },
    closeForwardAttachementMenu() {
      this.attachmentToForward = {}
      this.insuranceToForwardTo = []
      this.forwardToInsuranceCompany = false
      this.$refs.forwardAttachmentModal.close()
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
}

@media (max-width: 767px) {
  .container {
    padding-right: 8px; //padding for scrollbar on small devices
  }
}

.chat-sender .message-body {
  background-color: var(--color-background-chat-message);
}





.message {
  display: flex;
  align-items: center;
}

.message-body {
  margin: 0.5rem;
  padding: 0.25rem;
  max-width: 70%;
  border-radius: 4px;
  border: solid;
  border-color: transparent transparent var(--color-box) transparent;
}

.message-body img {
  max-width: 100%;
}

.message-body p {
  min-height: 0.1px;
}

.chat-info {
  font-size: small;
}

.chat-sender {
  justify-content: flex-end;
}

.chat-sender {
  .message-body {
    border-width: 0 8px 8px 0;
    padding-left: 8px;
    color: var(--color-text-neutral);
  }

  a.button {
    background-color: unset;
    border: unset;
    color: var(--color-text);
  }
}

.chat-receiver {
  .message-body {
    border-width: 0 0 8px 8px;
    background-color: var(--color-box-neutral);
    padding-right: 8px;
    color: var(--color-text);
  }

  a.button {
    border-color: var(--color-background);
    background-color: var(--color-background);
    color: var(--color-text);
  }
}

.chat-center {
    justify-content: center;
  }

.chat-center .message-body {
  background-color: var(--color-background-chat-center); 
  padding-left: 8px;
  padding-right: 8px;
  color: var(--color-text);
  border-width: 0 8px 8px 8px; 
}

.internalRemark .message-body {
  background-color: var(--color-background-chat-internal-remark); 
  color: var(--color-text);
}

@media (prefers-color-scheme: dark) {
  .chat-receiver .message-body {
    background-color: var(--color-box-neutral);
  }

  .chat-sender .message-body {
    color: var(--color-text-neutral);
  }
}

.sent-by {
  display: flex;
}

.spreadsheet__container {
  color: var(--color-text);
  background: var(--color-background);
  overflow: hidden;
  padding: 1rem;
}

.cursor-pointer {
  cursor: pointer;
}

// @media (min-width: 1767px) {
//   .chat-sender {
//     justify-content: flex-end;
//   }
//   .chat-receiver {
//     justify-content: flex-end;
//   }
// }</style>

<!-- GLOBAL STYLE -->
<style>
.message-text img {
  cursor: pointer;
}

.message-text p {
  padding: 0;
  margin: 0;
}

.forward {
  display: flex;
  gap: 12px;
}
</style>
