<template>
  <div class="d-inline">
    <BaseButton
      @click="virtualCameraEnabled = true"
      :class="buttonClass"
      :disabled="disabled"
      :isLink="isLink"
      :isPrimary="isPrimary"
      :isSecondary="isSecondary"
      :isBlock="isBlock">
      Fotografieren
    </BaseButton>
    <VirtualCamera 
      v-if="virtualCameraEnabled"
      :camera="camera"
      :edgeCorrection="edgeCorrection"
      @input="onPhoto"
      @cancel="virtualCameraEnabled = false"/>
  </div>
</template>

<script>
import VirtualCamera from './VirtualCamera.vue';
import BaseButton from '@/components/core/BaseButton.vue'
import {fileFromUrlData} from '@/helpers/upload-helper'

import { FRONT_CAMERA_VALUE, PROP_CAMERA_VALIDS, } from './camera-utils';

export default {
  components: {
    VirtualCamera,
    BaseButton,
  },
  props: {
    camera: {
      type: String,
      default: FRONT_CAMERA_VALUE,
      validator: (value) => {
        return PROP_CAMERA_VALIDS.indexOf(value) >= 0;
      },
    },
    edgeCorrection: {
      type: Boolean,
      default: false,
    },
    buttonClass: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isLink: {
      type: Boolean,
      default: false
    },
    isPrimary: {
      type: Boolean,
      default: true
    },
    isSecondary: {
      type: Boolean,
      default: false
    },
    isBlock: {
      type: Boolean,
      default: false,
    },
    highQuality: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      virtualCameraEnabled: false,
    }
  },
  methods: {
    // the virtual camera made a photo
    onPhoto(urlData) {
      this.$emit("input", urlData);
      this.$emit("file", fileFromUrlData(urlData, "photo_" + Math.round(Math.random() * 100000) + ".jpeg", 'image/jpeg'));
      this.close();
    },

    close() {
      this.virtualCameraEnabled = false;
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.d-inline {
  display: inline;
}
.foto-button {
  margin: 0;
  min-height: 100%;
}
</style>
