var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _c("InputField", {
        attrs: {
          label: "Produktbezeichnung",
          isComponentHalfSize: true,
          value: _vm.produktbezeichnung,
          disabled: true
        }
      }),
      _c("AbrechnungenTable"),
      _c("VerteilungAbrechnungStrukturTable")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }