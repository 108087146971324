var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: (_vm.sub ? "SUB " : "") + "Interface Tables bearbeiten",
          actions: _vm.headerActions
        },
        on: { "action-SAVE": _vm.save }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("div", { staticClass: "box__title" }, [_vm._v(" Daten ")]),
          _vm.id != null
            ? _c("InputField", {
                attrs: { disabled: "", label: "ID" },
                model: {
                  value: _vm.id,
                  callback: function($$v) {
                    _vm.id = $$v
                  },
                  expression: "id"
                }
              })
            : _vm._e(),
          _c("InputField", {
            attrs: {
              disabled: !_vm.interfaceEditEnable,
              label: "Interfacename"
            },
            model: {
              value: _vm.form.interfaceName,
              callback: function($$v) {
                _vm.$set(_vm.form, "interfaceName", $$v)
              },
              expression: "form.interfaceName"
            }
          }),
          _c("InputCheckBoxGroup", {
            attrs: {
              values: [
                {
                  label:
                    "fehlerhafte Datensätze beim automatischen Einlesen im System belassen",
                  path: "keepErrors"
                },
                {
                  label:
                    "fehlerhafte Datensätze werden nicht in 'Dateien in Verarbeitung' angezeigt",
                  path: "hideErrors"
                },
                { label: "Mehrfachauswahl", path: "multiple" },
                { label: "Interface ist aktiv", path: "active" },
                { label: "Interface ist nicht sichtbar", path: "invisible" },
                {
                  label:
                    "Interf. liest UNICODE (keine Auswirkungen aufs manuelle Datei Einlesen)",
                  path: "unicode"
                }
              ],
              value: _vm.form
            }
          }),
          _vm.gesellschaftOptions
            ? _c("ComboBox", {
                attrs: {
                  values: _vm.gesellschaftOptions,
                  disabled: !_vm.interfaceEditEnable,
                  label: "Gesellschaft"
                },
                model: {
                  value: _vm.form.gesellschaft,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "gesellschaft", $$v)
                  },
                  expression: "form.gesellschaft"
                }
              })
            : _vm._e(),
          _c("InputTextArea", {
            attrs: {
              disabled: !_vm.interfaceEditEnable,
              label: "Check Statement",
              autoGrow: "",
              rows: 3
            },
            model: {
              value: _vm.form.checkStatement,
              callback: function($$v) {
                _vm.$set(_vm.form, "checkStatement", $$v)
              },
              expression: "form.checkStatement"
            }
          }),
          _c("InputTextArea", {
            attrs: {
              disabled: !_vm.interfaceEditEnable,
              label: "Pre File Check Statement",
              autoGrow: "",
              rows: 3
            },
            model: {
              value: _vm.form.preFileCheckStatement,
              callback: function($$v) {
                _vm.$set(_vm.form, "preFileCheckStatement", $$v)
              },
              expression: "form.preFileCheckStatement"
            }
          }),
          _c("InputTextArea", {
            attrs: {
              disabled: !_vm.interfaceEditEnable,
              label: "Beschreibung",
              autoGrow: "",
              rows: 3
            },
            model: {
              value: _vm.form.description,
              callback: function($$v) {
                _vm.$set(_vm.form, "description", $$v)
              },
              expression: "form.description"
            }
          }),
          _c("InputToggleSwitch", {
            attrs: {
              disabled: !_vm.interfaceEditEnable,
              label: "ist XML-Interface"
            },
            model: {
              value: _vm.form.xml,
              callback: function($$v) {
                _vm.$set(_vm.form, "xml", $$v)
              },
              expression: "form.xml"
            }
          }),
          _vm.form.xml
            ? _c("InputField", {
                attrs: { disabled: !_vm.interfaceEditEnable, label: "XPath" },
                model: {
                  value: _vm.form.xPath,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "xPath", $$v)
                  },
                  expression: "form.xPath"
                }
              })
            : _vm._e(),
          _vm.form.xml
            ? _c("InputField", {
                attrs: {
                  disabled: !_vm.interfaceEditEnable,
                  label: "Namecheck"
                },
                model: {
                  value: _vm.form.nameCheck,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "nameCheck", $$v)
                  },
                  expression: "form.nameCheck"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.form.multiple
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _vm.loading || !_vm.multiRows.length
                ? _c("span", { staticClass: "box__title" }, [
                    _vm._v(" Multitab ")
                  ])
                : _vm._e(),
              _vm.id != null
                ? _c(
                    "BaseButton",
                    {
                      staticStyle: { float: "right", "margin-bottom": "0" },
                      attrs: { disabled: !_vm.interfaceEditEnable },
                      on: { click: _vm.newMulti }
                    },
                    [_vm._v(" Neue Daten anlegen ")]
                  )
                : _vm._e(),
              _vm.loading
                ? _c("GhostLoading", { attrs: { type: "table" } })
                : _vm.multiRows.length
                ? _c("Table", {
                    staticStyle: { clear: "both" },
                    attrs: {
                      tableId: "6adb2f8c-388c-4eb0-990e-1b7806c52511",
                      title: "Multitab",
                      headers: _vm.multiHeaders,
                      rows: _vm.multiRows,
                      rowId: "lfdno",
                      rowsPerPage: 20
                    },
                    on: {
                      "action-OPEN": _vm.actionOpenMulti,
                      "action-EDIT": _vm.actionEditMulti,
                      "action-DELETE": _vm.deleteMulti
                    }
                  })
                : _c("NoData")
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.loading || !_vm.felderRows.length
            ? _c("span", { staticClass: "box__title" }, [_vm._v(" Felder ")])
            : _vm._e(),
          _vm.id != null
            ? _c(
                "BaseButton",
                {
                  staticStyle: { float: "right", "margin-bottom": "0" },
                  attrs: { disabled: !_vm.interfaceEditEnable },
                  on: { click: _vm.newFeld }
                },
                [_vm._v(" Neues Feld anlegen ")]
              )
            : _vm._e(),
          _vm.loading
            ? _c("GhostLoading", { attrs: { type: "table" } })
            : _vm.felderRows.length
            ? _c("Table", {
                staticStyle: { clear: "both" },
                attrs: {
                  tableId: "6adb2f8c-388c-4eb0-990e-1b7806c52512",
                  title: "Felder",
                  headers: _vm.felderHeaders,
                  rows: _vm.felderRows,
                  rowId: "name",
                  rowsPerPage: 20
                },
                on: {
                  "action-EDIT": _vm.actionEditFeld,
                  "action-DELETE": _vm.deleteFeld
                }
              })
            : _c("NoData")
        ],
        1
      ),
      _vm.multiEdit
        ? _c("InterfaceMultiModal", {
            attrs: { interfaceId: _vm.id, multi: _vm.multiEdit },
            on: {
              close: function($event) {
                _vm.multiEdit = null
                _vm.reloadTables()
              }
            }
          })
        : _vm._e(),
      _vm.feldEdit
        ? _c("InterfaceFeldModal", {
            attrs: { interfaceId: _vm.id, feld: _vm.feldEdit },
            on: {
              close: function($event) {
                _vm.feldEdit = null
                _vm.reloadTables()
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }