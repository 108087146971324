import * as phosphor from 'phosphor-vue';
import CORE_TYPES from '@/store/core/types';
import { mapGetters } from 'vuex';

export default {
  props: {
    actionKey: {
      type: String,
      required: true,
    },
    tid: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
    },
    isMainAction: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Function,
      default: null,
    },
    loading: {
      type: Function,
      default: null,
    },
    icon: {
      required: false
    },
    size: {
      type: Number,
      default: 20
    },
    visible: {
      type: Function,
      default: null,
    },
    primary: {
      type: Function,
      default: null,
    },
    visibleOnLargeScreen: {
      type: Function,
      default: null,
    },
    visibleOnSmallScreen: {
      type: Function,
      default: null,
    },
    showAsLink: {
      type: Boolean,
      default: true
    },
    replacementActionForCloseOnSmallScreen: {
      type: Function,
      default: null
    }
  },
  computed: {
    ...mapGetters({
      isSmallScreen: CORE_TYPES.GETTERS.IS_SMALL_SCREEN
    }),
    isDisabled() {
      return this.disabled && this.disabled(this.actionKey);
    },
    iconComponent() {
      if (typeof this.icon === 'string') {
        return phosphor[this.icon];
      } else {
        return this.icon;
      }
    },
    isShowAsLink() {
      return this.isSmallScreen && this.showAsLink;
    },
    isPrimary() {
      return this.isMainAction || !this.primary || (typeof this.primary === 'function' && this.primary());
    },
    isLoading() {
      return this.loading?.();
    },
  },
  methods: {
    onClick() {
      this.emitAction(this.value);
    },
    emitAction(value) {
      this.$emit("action", {
        key: this.actionKey,
        value,
      });
    },
  },
}
