import RISIKOVERTEILUNG_TYPES from './types';
import Vue from 'vue';

export default {
  [RISIKOVERTEILUNG_TYPES.MUTATIONS.GET_RISIKOVERTEILUNG_SUCCESS](state, payload) {
    const treatedPayload = Object.entries(payload).reduce((acc, curr) => 
    ({...acc, ...({[curr[0]] : (typeof curr[1] === 'number' ? parseFloat(curr[1].toFixed(1)) : parseFloat(curr[1].replace(/,/g, '.')))} )}),{});
    Vue.set(state, 'risk', Object.assign({}, treatedPayload));
  },
}
