import DYNAMIC_DOCUMENT_TYPES from './types';
import { getInitialState } from './index';
import Vue from 'vue'

export default {
  [DYNAMIC_DOCUMENT_TYPES.MUTATIONS.RETRIEVE_DYNAMIC_DOCUMENT_SUCCESS](state, payload) {
    if(payload && payload.templates) {
      payload.templates.forEach((temp, index) => temp.key = index)
    }

    state.selectedDynamicDocument = payload
  },

  [DYNAMIC_DOCUMENT_TYPES.MUTATIONS.RELOAD_DYNAMIC_DOCUMENT_SUCCESS](state, payload) {
    state.selectedDynamicDocument.componentMap = payload.componentMap;
  },

  [DYNAMIC_DOCUMENT_TYPES.MUTATIONS.RETRIEVE_SELECTIONS_SUCCESS](state, payload) {
    state.comboboxSelections = {...payload};
  },

  [DYNAMIC_DOCUMENT_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },

  [DYNAMIC_DOCUMENT_TYPES.MUTATIONS.RETRIEVE_PLACEHOLDERS_SUCCESS](state, payload) {
    state.placeholders = payload
  },  

  [DYNAMIC_DOCUMENT_TYPES.MUTATIONS.SET_DOCUMENT_HAS_CHANGED](state, payload) {
    state.documentHasChanged = payload === true ? true : false
  },   
}