<template>
<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
 width="1008.92047" height="607.45001" viewBox="0 0 1008.92047 607.45001"
 xmlns:xlink="http://www.w3.org/1999/xlink">
 <path d="M1104.46,620.305a15.34,15.34,0,0,1-15.25958,15.42H417.8a15.34,15.34,0,0,1-15.26-15.41962V161.695a15.34,15.34,0,0,1,15.25957-15.42H1089.2a15.34,15.34,0,0,1,15.26,15.41959v.00043Z" transform="translate(-95.53976 -146.27499)" fill="#e6e6e6"/>
<path d="M1096.54,612.795a14.91,14.91,0,0,1-14.91,14.91H425.83a14.91,14.91,0,0,1-14.91-14.91V169.415a14.91,14.91,0,0,1,14.91-14.91h655.83a14.91,14.91,0,0,1,14.88,14.91Z" transform="translate(-95.53976 -146.27499)" 
:fill="secondColor"/>
<path id="b9c54412-061c-4155-b289-fb4c23e4a64e-18" data-name="c6fe725a-3b03-4a96-90af-cfbd2676500a" d="M350.43023,27.48h616.22a8.85,8.85,0,0,1,8.85,8.85V451.61a8.85,8.85,0,0,1-8.85,8.85h-616.22a8.85,8.85,0,0,1-8.85-8.85V36.33a8.85,8.85,0,0,1,8.85-8.85Z" fill="#e6e6e6"/>
<path d="M358.23023,37.44h599.95a8.85,8.85,0,0,1,8.85,8.85V439.41a8.85,8.85,0,0,1-8.85,8.85h-599.95a8.85,8.85,0,0,1-8.85-8.85V46.29A8.85,8.85,0,0,1,358.23023,37.44Z" 
:fill="secondColor"/>
<path d="M877.37572,456.8078a16.86941,16.86941,0,0,1-6.85316-1.46024,8.31363,8.31363,0,0,1-1.42861-.81184l-43.60186-30.69158h0a16.86934,16.86934,0,0,1-7.15892-13.79065V368.19654a16.86933,16.86933,0,0,1,7.1589-13.79066l43.60186-30.69158a8.31367,8.31367,0,0,1,1.42862-.81183A16.86929,16.86929,0,0,1,894.245,338.31679V439.93323a16.8693,16.8693,0,0,1-16.8693,16.8693Z" transform="translate(-95.53976 -146.27499)" fill="#3f3d56"/>
<path d="M779.7948,465.03685H658.187c-24.81022-.02033-44.91651-16.0578-44.942-35.84726V349.06042c.0255-19.78946,20.13179-35.82692,44.942-35.84726H780.112c24.63351.02325,44.59564,15.94572,44.62477,35.59423v80.3822C824.71131,448.97905,804.605,465.01652,779.7948,465.03685Z" transform="translate(-95.53976 -146.27499)" fill="#3f3d56"/>
<circle cx="900.87945" cy="386.81368" r="36.16732" fill="#e6e6e6"/>
<path d="M1015.20446,532.44038l-28.82923-16.64457a.7486.7486,0,0,0-1.12289.6483v33.28913a.74859.74859,0,0,0,1.12289.6483l28.82923-16.64456a.74859.74859,0,0,0,0-1.2966l-28.82923-16.64457a.7486.7486,0,0,0-1.12289.6483v33.28913a.74859.74859,0,0,0,1.12289.6483l28.82923-16.64456A.74859.74859,0,0,0,1015.20446,532.44038Z" transform="translate(-95.53976 -146.27499)" 
:fill="secondColor"/>
<path d="M949.46,675.305a15.34,15.34,0,0,1-15.25958,15.42H262.8a15.34,15.34,0,0,1-15.26-15.41962V216.695a15.34,15.34,0,0,1,15.25957-15.42H934.2a15.34,15.34,0,0,1,15.26,15.41959v.00043Z" transform="translate(-95.53976 -146.27499)" fill="#e6e6e6"/>
<path d="M941.54,667.795a14.91,14.91,0,0,1-14.91,14.91H270.83a14.91,14.91,0,0,1-14.91-14.91V224.415a14.91,14.91,0,0,1,14.91-14.91H926.66a14.91,14.91,0,0,1,14.88,14.91Z" transform="translate(-95.53976 -146.27499)" 
:fill="secondColor"/>
<path id="a7242049-80b4-49e1-bd08-67354734c824-19" data-name="c6fe725a-3b03-4a96-90af-cfbd2676500a" d="M195.43022,82.48h616.22a8.85,8.85,0,0,1,8.85,8.85V506.61a8.85,8.85,0,0,1-8.85,8.85h-616.22a8.85,8.85,0,0,1-8.85-8.85V91.33a8.85,8.85,0,0,1,8.85-8.85Z" fill="#e6e6e6"/>
<path d="M203.23023,92.44h599.95a8.85,8.85,0,0,1,8.85,8.85V494.41a8.85,8.85,0,0,1-8.85,8.85h-599.95a8.85,8.85,0,0,1-8.85-8.85V101.29A8.85,8.85,0,0,1,203.23023,92.44Z" 
:fill="secondColor"/>
<path d="M722.37572,511.8078a16.86941,16.86941,0,0,1-6.85316-1.46024,8.31363,8.31363,0,0,1-1.42861-.81184l-43.60186-30.69158h0a16.86934,16.86934,0,0,1-7.15892-13.79065V423.19654a16.86933,16.86933,0,0,1,7.1589-13.79066l43.60186-30.69158a8.31367,8.31367,0,0,1,1.42862-.81183A16.86929,16.86929,0,0,1,739.245,393.31679V494.93323a16.8693,16.8693,0,0,1-16.8693,16.8693Z" transform="translate(-95.53976 -146.27499)" fill="#3f3d56"/>
<path d="M624.7948,520.03685H503.187c-24.81022-.02033-44.91651-16.0578-44.942-35.84726V404.06042c.0255-19.78946,20.13179-35.82692,44.942-35.84726H625.112c24.63351.02325,44.59564,15.94572,44.62477,35.59423v80.3822C669.71131,503.97905,649.605,520.01652,624.7948,520.03685Z" transform="translate(-95.53976 -146.27499)" fill="#3f3d56"/>
<circle cx="745.87945" cy="441.81368" r="36.16732" fill="#e6e6e6"/>
<path d="M860.20446,587.44038l-28.82923-16.64457a.7486.7486,0,0,0-1.12289.6483v33.28913a.74859.74859,0,0,0,1.12289.6483L860.20446,588.737a.74859.74859,0,0,0,0-1.2966l-28.82923-16.64457a.7486.7486,0,0,0-1.12289.6483v33.28913a.74859.74859,0,0,0,1.12289.6483L860.20446,588.737A.74859.74859,0,0,0,860.20446,587.44038Z" transform="translate(-95.53976 -146.27499)" 
:fill="secondColor"/>
<path d="M797.46,738.305a15.34,15.34,0,0,1-15.25958,15.42H110.8a15.34,15.34,0,0,1-15.26-15.41962V279.695a15.34,15.34,0,0,1,15.25957-15.42H782.2a15.34,15.34,0,0,1,15.26,15.41959v.00043Z" transform="translate(-95.53976 -146.27499)" fill="#e6e6e6"/>
<path d="M789.54,730.795a14.91,14.91,0,0,1-14.91,14.91H118.83a14.91,14.91,0,0,1-14.91-14.91V287.415a14.91,14.91,0,0,1,14.91-14.91H774.66a14.91,14.91,0,0,1,14.88,14.91Z" transform="translate(-95.53976 -146.27499)" 
:fill="secondColor"/>
<path id="b7a050eb-0cb2-44a8-b642-35d340850eff-20" data-name="c6fe725a-3b03-4a96-90af-cfbd2676500a" d="M43.43022,145.48h616.22a8.85,8.85,0,0,1,8.85,8.85V569.61a8.85,8.85,0,0,1-8.85,8.85h-616.22a8.85,8.85,0,0,1-8.85-8.85V154.33a8.85,8.85,0,0,1,8.85-8.85Z" fill="#e6e6e6"/>
<path d="M51.23023,155.44h599.95a8.85,8.85,0,0,1,8.85,8.85V557.41a8.85,8.85,0,0,1-8.85,8.85h-599.95a8.85,8.85,0,0,1-8.85-8.85V164.29A8.85,8.85,0,0,1,51.23023,155.44Z" 
:fill="secondColor"/>
<path d="M570.37572,574.8078a16.86941,16.86941,0,0,1-6.85316-1.46024,8.31363,8.31363,0,0,1-1.42861-.81184l-43.60186-30.69158h0a16.86934,16.86934,0,0,1-7.15892-13.79065V486.19654a16.86933,16.86933,0,0,1,7.1589-13.79066l43.60186-30.69158a8.31367,8.31367,0,0,1,1.42862-.81183A16.86929,16.86929,0,0,1,587.245,456.31679V557.93323a16.8693,16.8693,0,0,1-16.8693,16.8693Z" transform="translate(-95.53976 -146.27499)" 
:fill="color"/>
<path d="M472.7948,583.03685H351.187c-24.81022-.02033-44.91651-16.0578-44.942-35.84726V467.06042c.0255-19.78946,20.13179-35.82692,44.942-35.84726H473.112c24.63351.02325,44.59564,15.94572,44.62477,35.59423v80.3822C517.71131,566.97905,497.605,583.01652,472.7948,583.03685Z" transform="translate(-95.53976 -146.27499)" 
:fill="color"/>
<circle cx="593.87945" cy="504.81368" r="36.16732" fill="#3f3d56"/>
<path d="M708.20446,650.44038l-28.82923-16.64457a.7486.7486,0,0,0-1.12289.6483v33.28913a.74859.74859,0,0,0,1.12289.6483L708.20446,651.737a.74859.74859,0,0,0,0-1.2966l-28.82923-16.64457a.7486.7486,0,0,0-1.12289.6483v33.28913a.74859.74859,0,0,0,1.12289.6483L708.20446,651.737A.74859.74859,0,0,0,708.20446,650.44038Z" transform="translate(-95.53976 -146.27499)" 
:fill="secondColor"/>
</svg>
</template>

<script>
import undraw from '@/components/icons/undraw/undraw-mixin.js'

export default {
  mixins: [undraw]
}
</script>