export const MUTATION_PREFIX = 'BROKER_STATISTICS_MUTATIONS_';
export const ACTIONS_PREFIX = 'BROKER_STATISTICS_ACTIONS_';
export const GETTERS_PREFIX = 'BROKER_STATISTICS_GETTERS_';

export default{
  MUTATIONS: {
    RETRIEVE_TITLES_LIST_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_TITLES_LIST_SUCCESS',
    SET_TABLE_VIEWER: MUTATION_PREFIX + 'SET_TABLE_VIEWER',
    SET_DEEP_TITLE: MUTATION_PREFIX + 'SET_DEEP_TITLE',
  },
  ACTIONS: {
    RETRIEVE_TITLES_LIST: ACTIONS_PREFIX + 'RETRIEVE_TITLES_LIST',
    RETRIEVE_TABLE_VIEWER: ACTIONS_PREFIX + 'RETRIEVE_TABLE_VIEWER',
    RETRIEVE_PAGE_VIEWER: ACTIONS_PREFIX + 'RETRIEVE_PAGE_VIEWER',
    RETRIEVE_EXPORT_DATA: ACTIONS_PREFIX + 'RETRIEVE_EXPORT_DATA',
  },
  GETTERS: {
    BROKER_STATISTICS_TITLES_LIST: GETTERS_PREFIX + 'BROKER_STATISTICS_TITLES_LIST',
    GET_TABLE_VIEWER: GETTERS_PREFIX + 'GET_TABLE_VIEWER',
    GET_DEEP_TITLE: GETTERS_PREFIX + 'GET_DEEP_TITLE',
  }
}