var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: {
        isModalAsFullPage: false,
        labelButtonConfirm: "Annehmen",
        labelButtonCancel: "Ablehnen"
      },
      on: {
        onConfirmButton: _vm.acceptCall,
        onCancelButton: _vm.rejectCall,
        onCloseButton: _vm.rejectCall
      },
      scopedSlots: _vm._u([
        {
          key: "modalTitle",
          fn: function() {
            return [_vm._v(" Eingehender Anruf ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("strong", [
        _vm._v(" " + _vm._s(_vm.displayName) + " "),
        _vm.kundennr
          ? _c("span", [_vm._v("(" + _vm._s(_vm.kundennr) + ")")])
          : _vm._e()
      ]),
      _vm._v(" ruft an. "),
      _c("div", [_vm._v("Der Anruf wird mit aktivierter Kamera gestartet.")]),
      _c("div", [
        _vm._v(
          "Bitte beachten Sie, dass das Gespräch aufgezeichnet werden kann."
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }