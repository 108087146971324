var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("InputField", {
        attrs: { label: "Anrede", isComponentHalfSize: "" },
        on: {
          change: function($event) {
            return _vm.updateNafiStore("anwenderAnrede", $event)
          }
        },
        model: {
          value: _vm.form.anwenderAnrede,
          callback: function($$v) {
            _vm.$set(_vm.form, "anwenderAnrede", $$v)
          },
          expression: "form.anwenderAnrede"
        }
      }),
      _c("InputField", {
        attrs: { label: "Vorname", isComponentHalfSize: "" },
        on: {
          change: function($event) {
            return _vm.updateNafiStore("anwenderVorname", $event)
          }
        },
        model: {
          value: _vm.form.anwenderVorname,
          callback: function($$v) {
            _vm.$set(_vm.form, "anwenderVorname", $$v)
          },
          expression: "form.anwenderVorname"
        }
      }),
      _c("InputField", {
        attrs: { label: "Nachname", isComponentHalfSize: "" },
        on: {
          change: function($event) {
            return _vm.updateNafiStore("anwenderNachname", $event)
          }
        },
        model: {
          value: _vm.form.anwenderNachname,
          callback: function($$v) {
            _vm.$set(_vm.form, "anwenderNachname", $$v)
          },
          expression: "form.anwenderNachname"
        }
      }),
      _c("InputField", {
        attrs: { label: "Straße", isComponentHalfSize: "" },
        on: {
          change: function($event) {
            return _vm.updateNafiStore("anwenderStrasse", $event)
          }
        },
        model: {
          value: _vm.form.anwenderStrasse,
          callback: function($$v) {
            _vm.$set(_vm.form, "anwenderStrasse", $$v)
          },
          expression: "form.anwenderStrasse"
        }
      }),
      _c("InputField", {
        attrs: { label: "PLZ", isComponentHalfSize: "" },
        on: {
          change: function($event) {
            return _vm.updateNafiStore("anwenderPlz", $event)
          }
        },
        model: {
          value: _vm.form.anwenderPlz,
          callback: function($$v) {
            _vm.$set(_vm.form, "anwenderPlz", $$v)
          },
          expression: "form.anwenderPlz"
        }
      }),
      _c("InputField", {
        attrs: { label: "Ort", isComponentHalfSize: "" },
        on: {
          change: function($event) {
            return _vm.updateNafiStore("anwenderOrt", $event)
          }
        },
        model: {
          value: _vm.form.anwenderOrt,
          callback: function($$v) {
            _vm.$set(_vm.form, "anwenderOrt", $$v)
          },
          expression: "form.anwenderOrt"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Telefon 1",
          isComponentHalfSize: "",
          inputmode: "tel"
        },
        on: {
          change: function($event) {
            return _vm.updateNafiStore("anwenderTelefon1", $event)
          }
        },
        model: {
          value: _vm.form.anwenderTelefon1,
          callback: function($$v) {
            _vm.$set(_vm.form, "anwenderTelefon1", $$v)
          },
          expression: "form.anwenderTelefon1"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Telefon 2",
          isComponentHalfSize: "",
          inputmode: "tel"
        },
        on: {
          change: function($event) {
            return _vm.updateNafiStore("anwenderTelefon2", $event)
          }
        },
        model: {
          value: _vm.form.anwenderTelefon2,
          callback: function($$v) {
            _vm.$set(_vm.form, "anwenderTelefon2", $$v)
          },
          expression: "form.anwenderTelefon2"
        }
      }),
      _c("InputField", {
        attrs: { label: "Telefax", isComponentHalfSize: "", inputmode: "tel" },
        on: {
          change: function($event) {
            return _vm.updateNafiStore("anwenderTelefax", $event)
          }
        },
        model: {
          value: _vm.form.anwenderTelefax,
          callback: function($$v) {
            _vm.$set(_vm.form, "anwenderTelefax", $$v)
          },
          expression: "form.anwenderTelefax"
        }
      }),
      _c("InputField", {
        attrs: { label: "E-Mail", isComponentHalfSize: "" },
        on: {
          change: function($event) {
            return _vm.updateNafiStore("anwenderEmail", $event)
          }
        },
        model: {
          value: _vm.form.anwenderEmail,
          callback: function($$v) {
            _vm.$set(_vm.form, "anwenderEmail", $$v)
          },
          expression: "form.anwenderEmail"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }