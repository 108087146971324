<template>
  <div class="config-edit-vertical-stepper__container box__container">
    <VerticalStepperEditable
      ref="stepper"
      :stepType="stepType"
      :visible="showStepper"
      :stepperName="stepperName"
      :selectedStepKey="selectedStep"
      :stepperService="getStepperMediator"
      :antragData="configData"
      :showSaveSpinner="showSaveSpinner"
      :showCollapse="true"
      :disabled="disabled"
      :actionsConfig="actionsConfig"
      @setStep="$emit('setStep', $event)"
      @setSubstep="$emit('setSubstep', $event)"
      @collapsed="$emit('collapsed', $event)"
      @zusammenfassung="$emit('zusammenfassung', $event)"
      @stepEdited="$emit('stepEdited', $event);"
      @enablesEditStep="enablesEditStep($event)"
      @disablesEditStep="disablesEditStep($event)"
      @removeStep="openRemoveStepConfirmModal($event)"
      @orderChanged="$emit('orderChanged', $event)"
      @addStep="$emit('addStep', $event)"
    ></VerticalStepperEditable>
  </div>
</template>

<script>
import { StepperMediator } from '@/components/stepper/utils';
import VerticalStepperEditable from './VerticalStepperEditable.vue';

export default {
  components: {
    VerticalStepperEditable,
  },
  props: {
    configStep: {
    },
    configData: {
    },
    selectedStep: {
    },
    disabled: {
    },
    actionsConfig: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      stepType: 'tab',
      showStepper: true,
      stepperName: 'name?',
      showSaveSpinner: false,

      stepKeyToDelete: null,
    }
  },
  computed: {
    getStepperMediator() {
      if (this.configStep && this.configData) {
        return new StepperMediator(JSON.parse(JSON.stringify(this.configStep)));
      }
      return null;
    },
  },
  methods: {
    enablesEditStep(stepKey) {
      // disable all editing
      this.disablesEditStep();

      // set selected step as editing
      this.$nextTick(() => {
        const step = this.getStepperMediator.getCurrentStep(stepKey);
        this.$set(step, 'editing', { label: step.label, });
      });
    },
    disablesEditStep() {
      // disable all editing
      const steps = this.getStepperMediator.getCurrentStepList();
      steps.forEach(step => this.$set(step, 'editing', null));
    },
    openRemoveStepConfirmModal(step) {
      this.$confirmModal({
        message: `Möchten Sie die Gruppe <b>"${step.label}"</b> wirklich löschen?`,
        title: 'Gruppe löschen',
        labelButtonConfirm: 'Löschen'
      })
        .then(() => this.$emit('removeStep', step.stepKey));
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
