<template>
  <div class="input-forms__container">
    <div class="input-forms__container">
      <ComboBox
        label="Bereits vorhandene Depots"
        :values="vorhandeneDepotsAuswahl"
        :value="antragData.INPUT_VORHANDENE_KONTEN_TERMIN"
        :sortComboboxValues="false"
        @input="updateStore('INPUT_VORHANDENE_KONTEN_TERMIN', $event)"
      />
      <WertpapierorderAnteileInfo v-if="selectedDepot" :position="selectedDepot" />
    </div>
    <div class="input-forms__label-container">
      <div class="row mt-0">
        <div class="col">
          <div
            class="
              input-forms__label-content input-forms__label-content--bigger
            "
            v-if="label"
          >
            <ph-bank :size="16" />&nbsp;{{ label }}
          </div>
        </div>
        <div class="col-auto" v-if="config && config.buttonText">
          <BaseButton @click="openFormsFinder">{{
            config.buttonText
          }}</BaseButton>
        </div>
      </div>
    </div>
    <div class="input-forms__input-container">
      <Table 
        v-if="rows && rows.length"
        hidePagination
        :headers="headers"
        :rows="rows"
        rowId="index"
        @action-DELETE="openModalDeleteFond"
      >
        <template #abbuchungsKonto="row">
          <InputRadioBoxGroup
            type="text"
            :value="row.abbuchungsKonto"
            :values="abbuchungsKontoValues"
            :validateUntouched="true"
            @input="
              onChangeInputField($event, row, 'abbuchungsKonto')
            "
          />
        </template>

        <template #ktoPlusIban="row">
          <InputField
            v-if="row.abbuchungsKonto === 'kontoplus'"
            placeholder="IBAN eingeben"
            :value="row.ktoPlusIban"
            @input="onChangeInputField($event, row, 'ktoPlusIban')"
          />
        </template>

        <template #abbuchungsTag="row">
          <InputRadioBoxGroup
            type="text"
            :value="row.abbuchungsTag"
            :values="abbuchungsTagValues"
            @input="onChangeInputField($event, row, 'abbuchungsTag')"
          />
        </template>
      </Table>
      <WertpapierAuswahlPositionFields
        v-if="positions && positions.length && config && config.positionFields"
        :config="config"
        :categoryId="categoryId"
        :antragId="antragId"
      />
      <DeletePositionModal
        ref="deleteModal"
        :position="positionToDelete"
        @delete="doDeletePosition()"
      ></DeletePositionModal>
    </div>
  </div>
</template>

<script>
import ANTRAG_TYPES from "@/store/antrag/types";
import FONDSFINDER_TYPES from "@/store/fondsfinder/types";
import BaseButton from "@/components/core/BaseButton.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputField from "@/components/core/forms/InputField.vue";
import antragNavigationMixin from "@/mixins/antrag/antrag-navigation-mixin.js";
import DeletePositionModal from "@/components/core/forms/antraege/wertpapierAuswahl/DeletePositionModal.vue";
import { mapGetters } from "vuex";
import antragMixin from "@/mixins/antrag/antrag-mixin.js";
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import { PhBank } from "phosphor-vue";
import WertpapierAuswahlPositionFields from "@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlPositionFields.vue";
import InputRadioBoxGroup from "@/components/core/forms/radiobox/InputRadioBoxGroup.vue";
import validator from "@/mixins/validator";
import { required } from "@/mixins/validator/rules";
import WertpapierorderAnteileInfo from "@/components/wertpapierorder/WertpapierorderAnteileInfo.vue";
import Table from "@/components/table2/Table.vue";
import { TextColumn, SlotColumn, ActionColumn, SimpleAction } from "@/components/table2/table_util.js";

export default {
  mixins: [antragNavigationMixin, antragMixin, validator],
  validators: {
    abbuchungsKonto: [required()],
  },
  props: {
    label: {},
    antragData: {},
    config: {},
    antragTyp: {},
    antragId: {},
    comboboxSelections: {},
    categoryId: {},
    suppressFilterLagerstelle: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BaseButton,
    ComboBox,
    InputField,
    Table,
    WertpapierAuswahlPositionFields,
    DeletePositionModal,
    DatePickerField,
    PhBank,
    InputRadioBoxGroup,
    WertpapierorderAnteileInfo,
  },
  data() {
    return {
      abbuchungsKontoValues: [
      { value: "keine", label: "keine Änderung" },
        { value: "referenzkonto", label: "Bestehendes Referenzkonto." },
       /* {
          value: "verrechnungskonto",
          label: "Verrechnungskonto bei der Schelhammer Capital Bank.",
        }, */
        { value: "kontoplus", label: "KONTO plus, IBAN:" },
      ],
      abbuchungsTagValues: [
      { value: 0, label: "keine Änderung" },
        { value: 12, label: "monatlich am 15" },
       //{ value: 4, label: "quartalsweise am 15" },
      ],
      positionDataAdditional: {},
      positionToDelete: {},
    };
  },
  computed: {
    ...mapGetters({
      positionInfo: FONDSFINDER_TYPES.GETTERS.POSITION_INFO,
      positionsAll: ANTRAG_TYPES.GETTERS.POSITIONS,
    }),
    headers() {
      const result = {
        lockedLeft: [
          TextColumn("isin", "ISIN"),
          TextColumn("fondsName", "Fondsname", 600),
        ],
        center: [
          ...(!this.config.hideKonto ? [SlotColumn("abbuchungsKonto", "Abrechnungskonto für Vermögensplan", 100, 1)] : []),
          SlotColumn("ktoPlusIban", "", 100, 1),
          SlotColumn("abbuchungsTag", "Durchführungstermin", 100, 1),
        ],
        lockedRight: [
          ActionColumn("actions", ""),
        ]
      }
        return result
    },
    rows() {
      const actions = [
        SimpleAction("DELETE", 'PhTrash', "Löschen"),
      ];
      return (this.positions || []).map((row, index) => ({
          ...row, 
          actions,
          index,
          lagerstelle: 'CAPITALBANK',
          fondsName: this.getFondsName(row, this.positionInfo),
      }));
    },
    positions() {
      if (
        this.positionsAll &&
        this.antragId &&
        this.categoryId &&
        this.positionsAll[this.antragId] &&
        this.positionsAll[this.antragId][this.categoryId]
      ) {
        const pos = this.positionsAll[this.antragId][this.categoryId].filter(
          (p) =>
            p.aenderungTyp == this.config.aenderungTyp ||
            p.aenderungTyp == "UNBEKANT" ||
            p.aenderungTyp == undefined
        );
        if (pos != undefined && pos.length > 0) {        
          pos.map((x) => (x.aenderungTyp = "SPARPLAN_ABBUCHUNG"));        
        }
        return pos || [];
      }
    },
    vorhandeneDepots() {
      return this.comboboxSelections && this.comboboxSelections['INPUT_VORHANDENE_KONTEN'] || []
    },
    vorhandeneDepotsAuswahl() {
      return this.vorhandeneDepots.map(depot => ({
        label: depot.label,
        value: depot.isin || depot.value
      }))
    },
    selectedDepot() {
      return this.vorhandeneDepots.find(depot => depot.isin && depot.isin === this.antragData.INPUT_VORHANDENE_KONTEN_TERMIN)
    },
  },
  methods: {
    onChangeInputField($event, position, field) {
      const positionChange = { [field]: $event, isin: position.isin };

      // in this case: update the position with that isin
      this.addOrUpdatePosition(positionChange);
    },
      updateStore(componentId, value) {
      const payload = {
        id: this.antragId,
        data: {
          [componentId]: value
        }
      }
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_ANTRAG_DATA, payload);
    },   
    openModalDeleteFond(fond) {
      this.positionToDelete = fond;
      this.$refs.deleteModal.open();
    },
    doDeletePosition() {
      this.deletePosition(
        this.antragId,
        this.categoryId,
        this.positionToDelete
      );
      this.positionToDelete = null;
    },
    addOrUpdatePosition(position) {
      const payload = {
        id: this.antragId,
        categoryId: this.config.isAuszahlung ? 'ENTNAHMEPLAN' : 'SPARPLAN',
        additionalIdentifier: ["aenderungTyp"],
        fonds: [{
          ...position,
          aenderungTyp: this.config.aenderungTyp,
        }],
      };
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_POSITIONS, payload);  
    },
    addToPositions(posInfo) {
      const pos = this.addPositionCBAenderingVermoegensplaene(posInfo,this.config.aenderungTyp)
      this.addOrUpdatePosition(pos)
    },
    openFormsFinder() {
      let posInfo =
        this.antragData.INPUT_VORHANDENE_KONTEN_TERMIN !== "NICHT_VERWENDEN"
          ? this.antragData.INPUT_VORHANDENE_KONTEN_TERMIN
          : null;
      if (posInfo != null) {
        this.addToPositions(this.vorhandeneDepotsAuswahl.find(p=>p.value === posInfo));
      } else {
        this.goToFormsFinder(
          this.categoryId,
          this.config,
          this.suppressFilterLagerstelle
        );
      }
    },
  },
};
</script>

<style scoped>
.input-forms__label-content--bigger {
  font-size: 1.5rem;
}
.input-forms__label-content--bigger > i {
  font-size: 2rem;
}
</style>