var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-forms__container" }, [
    _c("div", { staticClass: "input-forms__label-container" }, [
      _c("div", { staticClass: "row mt-0" }, [
        _c("div", { staticClass: "col" }, [
          _vm.title
            ? _c(
                "div",
                {
                  staticClass:
                    "input-forms__label-content input-forms__label-content--bigger"
                },
                [
                  _c("ph-bank", { attrs: { size: 16 } }),
                  _vm._v(" " + _vm._s(_vm.title) + " ")
                ],
                1
              )
            : _vm._e()
        ]),
        _vm.config && _vm.config.buttonText
          ? _c(
              "div",
              { staticClass: "col-auto" },
              [
                _c(
                  "BaseButton",
                  {
                    attrs: { disabled: _vm.disabled },
                    on: { click: _vm.openFormsFinder }
                  },
                  [_vm._v(_vm._s(_vm.config.buttonText))]
                )
              ],
              1
            )
          : _vm._e()
      ])
    ]),
    _c("div", { staticClass: "input-forms__input-container" }, [
      _vm.hasPositions
        ? _c("div", [
            _c(
              "div",
              [
                _vm.config.topSelect
                  ? _c("WertpapierAuswahlPositionsFODBKaufTop", {
                      attrs: {
                        positions: _vm.positions,
                        config: _vm.config,
                        categoryId: _vm.categoryId,
                        antragId: _vm.antragId,
                        disabled: _vm.disabled
                      }
                    })
                  : _c("WertpapierAuswahlPositionsFODBKauf", {
                      attrs: {
                        positions: _vm.positions,
                        config: _vm.config,
                        categoryId: _vm.categoryId,
                        antragId: _vm.antragId,
                        disabled: _vm.disabled
                      }
                    }),
                _vm.config.positionFields
                  ? _c("WertpapierAuswahlPositionFields", {
                      attrs: {
                        config: _vm.config,
                        categoryId: _vm.categoryId,
                        antragId: _vm.antragId,
                        disabled: _vm.disabled
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        : _c("div", [_c("div", [_vm._v("Wertpapierauswahl ist leer")])])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }