import INTERNESTATISTIKEN_TYPES from './types';

export default {
  [INTERNESTATISTIKEN_TYPES.GETTERS.STATISTICSGROUPS_LIST](state){
    return state.groupListData;
  },  
  [INTERNESTATISTIKEN_TYPES.GETTERS.GESELLSCHAFTENCOMBO](state){
    return state.gesellschaftenCombo;
  },
  [INTERNESTATISTIKEN_TYPES.GETTERS.FILTER_BROKER_NUMBER](state){
    return state.filterBrokerNumber;
  },  
}