import TextStyle from '@tiptap/extension-text-style';

export const TextStyleFontSize = TextStyle.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      fontSize: {
        default: '1em',
        parseHTML: (element) => element.style.fontSize,
        renderHTML: (attributes) => {
          if (!attributes['fontSize']) {
            return {};
          }
          return {
            style: `font-size: ${attributes['fontSize']}`
          };
        }
      }
    };
  },

  addCommands() {
    return {
      ...this.parent?.(),
      setFontSize:
        (fontSize) =>
          ({ commands }) => {
            return commands.setMark(this.name, { fontSize: fontSize });
          },
      unsetFontSize:
        () =>
          ({ chain }) => {
            return chain()
              .setMark(this.name, { fontSize: null })
              .removeEmptyTextStyle()
              .run();
          }
    };
  }
});