<template>
  <div>
    <div>
      <label class="mb-0 font-strong" for="knowledge">Kenntnisse</label>
      <InputSlider
        v-model="form.knowledge" 
        :values="knowledges"
        :disabled="isDisabled || isRefusedPage" 
        @input="doExperienceCardChange(form, category, 'knowledge', $event)" />
    </div>
    <div>
      <label class="mb-0 font-strong" for="experience">Erfahrungen</label>
      <InputSlider
        v-model="form.experience" 
        :values="knowledges"
        :disabled="isDisabled || isRefusedPage" 
        @input="doExperienceCardChange(form, category, 'experience', $event)"/>
    </div>

    <div class="col-12 mt-3">
      <FormLabel :config="{'bold': true}" 
        label="Getätigte Geschäfte (Hohe der Anlagesumme, Währungen, Fremdfinanzierungen, Anzahl) in T Euro)"/>
    </div>
    <InputField
      label="Zeichnungsvolumen gesamt"
      type="number"
      v-model="form.amountTotal"
      :disabled="isDisabled || isRefusedPage"
      @change="doExperienceCardChange(form, category, 'amountTotal', $event)" />

    <FormLabel class="col-12 mt-3" label="Davon in folgenden Währungen:"/>
    <InputField
      label="EUR"
      type="number"
      v-model="form.amountEur"
      :disabled="isDisabled || isRefusedPage" 
      @change="doExperienceCardChange(form, category, 'amountEur', $event)" />
    <InputField
      label="USD"
      type="number"
      v-model="form.amountUsd"
      :disabled="isDisabled || isRefusedPage"
      @change="doExperienceCardChange(form, category, 'amountUsd', $event)" />
    <InputField
      label="GBP"
      type="number"
      v-model="form.amountGbp"
      :disabled="isDisabled || isRefusedPage"
      @change="doExperienceCardChange(form, category, 'amountGbp', $event)" />
    <InputField
      label="Sonstige"
      type="number"
      v-model="form.amountSonstige"
      :disabled="isDisabled || isRefusedPage"
      @change="doExperienceCardChange(form, category, 'amountSonstige', $event)" />

    <InputField
      label="Anzahl Transaktionen p.a."
      type="number"
      v-model="form.transaction"
      :disabled="isDisabled || isRefusedPage"
      @change="doExperienceCardChange(form, category, 'transaction', $event)" />
    
    <InputField
      label="Anmerkungen"
      v-model="form.annotationGFCard"
      :disabled="isDisabled || isRefusedPage"
      @change="doExperienceCardChange(form, category, 'annotationGFCard', $event)" />

    <BaseButton v-if="isDetailButtonVisible()"
      @click="openSaveAllModal()" 
      :disabled="isDisabled || isSaving"
      isSecondary>
      <span>&nbsp;Detailangaben für andere Kenntnisse und Erfahrungen übernehmen</span>
    </BaseButton>

    <BaseModal 
      ref="saveForAll"
      labelButtonConfirm="Daten übernehmen"
      labelButtonCancel="Abbrechen"
      @onConfirmButton="doSaveForAll()"
      @onCancelButton="closeModal()"
      @onCloseButton="closeModal()"
    >
      <template v-slot:modalTitle>
        <!-- <ph-warning :size="16" class="mr-2" style="color: orangered"/> -->
        Angaben übernehmen
      </template>
      <div>Die Angaben von Detailangaben zu <span style="font-weight: bold">{{category && category.label || ''}}</span> auch übernehmen für:</div>
      <div v-for="cat in selectedCategories" :key="cat.substepKey">
        <InputCheckBoxItem :label="cat.title" v-model="cat.value" /> 
      </div>
    </BaseModal>
  </div>
</template>

<script>

import InputField from '@/components/core/forms/InputField.vue';
import InputSlider from '@/components/core/forms/InputSlider.vue';
import InputCheckBoxItem from '@/components/core/forms/checkbox/InputCheckBoxItem.vue';
import { mapGetters } from 'vuex';
import ANLEGERPROFIL_TYPES from '@/store/anlegerprofil/types';
import anlegerprofilMixin from '@/mixins/anlegerprofil/anlegerprofil-mixin.js';
import BaseModal from '@/components/core/BaseModal.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import FormLabel from '@/components/core/forms/FormLabel.vue';
import { PhWarning } from 'phosphor-vue'

const knowledges = [ 
  { name: 'keine', value: '0' },
  { name: 'Basis', value: '1' },  
  { name: 'erweitert', value: '2' },
  { name: 'umfangreich', value: '3' },
  { name: 'Experte', value: '4' }
];

export default {
  mixins: [anlegerprofilMixin],
  components: {
    InputSlider,
    InputField,
    BaseModal,
    BaseButton,
    InputCheckBoxItem,  
    FormLabel,
    PhWarning,
  },
  data() {
    return {
      form: {},
      values: [],
      knowledges: knowledges,
      category: null,
      selectedCategories: [],
      selectedForm: {},
    };
  },
  watch: {
    $route(to, from) {
      if (from.params.category !== to.params.category) {
        this.doSave();
        this.patchForm();
      }
    },
  },
  computed: {
    ...mapGetters({
      isSaving: ANLEGERPROFIL_TYPES.GETTERS.SAVE_ANLEGERPROFIL_STATE
    }),
    isRefusedPage() {
      return this._isRefusedPage(this.data, 'kenntnisse');
    },
    categoryPath() {
      return this.$route.params.category;
    },
  },
  mounted() {
    this.createForm();
    this.updateWarnings();
  },
  methods: {
    createForm() {
      this.form = {
        knowledge: '',
        experience: '',
        amountTotal:'',
        amountEur: '',
        amountUsd: '',
        amountGbp: '',
        amountSonstige: '',
        transaction: '',
        annotationGFCard: '',
      };
      this.patchForm();
    },
    patchForm() {
      const data = this.getExperienceGF(this.getPersonType);
      if (this.form && data) {
        const categories = data.categories || [];
        this.category = categories && this.categoryPath && categories.find(cat => cat.path === this.categoryPath) || {};
        this.form = {
          knowledge: this.category.knowledge,
          experience: this.category.experience,
          amountTotal: this.category.amountTotal,
          amountEur: this.category.amountEur,
          amountUsd: this.category.amountUsd,
          amountGbp: this.category.amountGbp,
          amountSonstige: this.category.amountSonstige,
          transaction: this.category.transaction,
          annotationGFCard: this.category.annotationGFCard,
        };
      }
    },
    openSaveAllModal() {
      const currentSubstepKey = this.getSubstepKey;
      this.selectedCategories = this.getVisibleKenntnisseGFSubsteps(this.stepper)
        .filter(substep => substep.substepKey !== currentSubstepKey)
        .map(substep => Object.assign({}, substep, { value: true }));
      this.$refs.saveForAll.open();
    },
    async doSaveForAll() {
      const selections = this.selectedCategories.filter(cat => cat.value)
      .map(cat => Object.assign({}, this.form, { path: cat.substepKey.substring(cat.substepKey.indexOf('/') + 1) }));
      
      this.updateExperienceCategories(this.getPersonType, selections, true);
      await this.doSave();
      this.closeModal();
    },
    closeModal () {
      this.selectedCategories = [];
      this.selectedForm = {};
      this.$refs.saveForAll.close();
    },
    isDetailButtonVisible() {
      return this.getVisibleKenntnisseGFSubsteps(this.stepper)?.length > 1 && this.isKennntnisseGFCardFilled(this.form);
    }
  },
}
</script>

<style scoped>

</style>