var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Limits",
          subtitle: "Depotlimit",
          defaultMenu: _vm.hasRoles([_vm.VIEW_ROLES.VIEW_BROKER])
            ? []
            : _vm.$appNavigation.currentTabMenu,
          actions: _vm.actions
        },
        on: {
          "action-RESET": function($event) {
            return _vm.save("reset")
          },
          "action-REMOVE": function($event) {
            return _vm.remove()
          }
        }
      }),
      _c("div", { staticClass: "box__container" }, [
        _c("div", { staticClass: "box__title" }, [_vm._v(_vm._s(_vm.title))]),
        _c("div", { staticClass: "mb-4" }, [
          _vm._v(
            'Zum Setzen der Limits muss ein prozentualer Wert, basierend auf dem aktuellen Depotwert, eingegeben werden. Der Ausgangswert wird in Zukunft entsprechend getätigter Käufe oder Verkäufe angepasst. Um den Ausgangswert an den aktuellen Depotwert anzupassen oder um bei einer Benachrichtigung das Limit neu zu setzen, ist der "Zurücksetzen"-Button zu drücken. Soll ein Limit (oberes oder unteres) nicht beachtet werden, so ist dieser Wert leer bzw. auf 0 zu lassen. Mit dem Aktivieren der Dynamik wird der Wert des unteren Limits immer vom Höchststand des Depots aus berechnet. '
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("div", { staticClass: "info-block" }, [
            !_vm.isCustomerOrigin
              ? _c("div", [_vm._v("Kundennr: " + _vm._s(_vm.limit.kundennr))])
              : _vm._e(),
            !_vm.isCustomerOrigin
              ? _c("div", [_vm._v("Kunde: " + _vm._s(_vm.limit.kunde))])
              : _vm._e(),
            _c("div", [
              _vm._v(
                "Aktueller Depotwert: " +
                  _vm._s(_vm.formatCurrency(_vm.limit.depotWert))
              )
            ]),
            _c("div", [
              _vm._v(
                "Ausgangswert (Depotwert): " +
                  _vm._s(_vm.formatCurrency(_vm.limit.asgangswert))
              )
            ])
          ]),
          _c("InputField", {
            attrs: {
              label: "Abweichung nach unten",
              type: "percent",
              precision: 4,
              isComponentHalfSize: ""
            },
            model: {
              value: _vm.form.abweichungLow,
              callback: function($$v) {
                _vm.$set(_vm.form, "abweichungLow", $$v)
              },
              expression: "form.abweichungLow"
            }
          }),
          _c("InputField", {
            attrs: {
              label: "Abweichung nach oben",
              precision: 4,
              type: "percent",
              isComponentHalfSize: ""
            },
            model: {
              value: _vm.form.abweichungHigh,
              callback: function($$v) {
                _vm.$set(_vm.form, "abweichungHigh", $$v)
              },
              expression: "form.abweichungHigh"
            }
          }),
          _c("InputToggleSwitch", {
            attrs: { label: "Dynamik" },
            model: {
              value: _vm.form.isDynamisch,
              callback: function($$v) {
                _vm.$set(_vm.form, "isDynamisch", $$v)
              },
              expression: "form.isDynamisch"
            }
          }),
          _c("div", [
            _vm._v(
              "Ausgangswert für Dynamik: " +
                _vm._s(_vm.formatCurrency(_vm.limit.dynamik))
            )
          ]),
          _vm.limit.dateUpdated
            ? _c("div", [
                _vm._v("Letzte Änderung: " + _vm._s(_vm.limit.dateUpdated))
              ])
            : _vm._e(),
          _vm.limit.dateOutOfLimit
            ? _c("div", [
                _vm._v(
                  "Datum der Überschreitung: " +
                    _vm._s(_vm.limit.dateOutOfLimit)
                )
              ])
            : _vm._e(),
          !_vm.isCustomerOrigin
            ? _c("InputTextArea", {
                attrs: {
                  label: "Bemerkung (für Kunden nicht sichtbar)",
                  isComponentHalfSize: ""
                },
                model: {
                  value: _vm.form.bemerkung,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "bemerkung", $$v)
                  },
                  expression: "form.bemerkung"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c("BaseModal", {
        ref: "saveOrReset",
        attrs: {
          labelButtonCancel: "Verwerfen",
          labelButtonConfirm: "Speichern"
        },
        on: {
          onCancelButton: _vm.handleReset,
          onConfirmButton: function($event) {
            return _vm.save("save")
          }
        },
        scopedSlots: _vm._u([
          {
            key: "modalTitle",
            fn: function() {
              return [_vm._v(" Möchte Sie Ihre Änderungen speichern? ")]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }