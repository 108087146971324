var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseButton",
    {
      attrs: {
        tid: _vm.tid,
        isSecondary: !_vm.isMainAction,
        disabled: _vm.isDisabled
      },
      on: { click: _vm.onClick }
    },
    [
      _vm.iconComponent
        ? _c(_vm.iconComponent, { tag: "component", attrs: { size: _vm.size } })
        : _vm._e(),
      _vm._v(" " + _vm._s(_vm.responsiveLabel) + " "),
      _vm.isLoading ? _c("AnimatedSpinner") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }