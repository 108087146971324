export const MUTATION_PREFIX = 'VIRTUALDEPOTS_MUTATION_: ';
export const ACTIONS_PREFIX = 'VIRTUALDEPOTS_ACTIONS_';
export const GETTERS_PREFIX = 'VIRTUALDEPOTS_GETTERS_';

export default {
  GETTERS: {
    LIST: GETTERS_PREFIX + 'LIST',
    GET_VD: GETTERS_PREFIX + 'GET_VD',
  },
  MUTATIONS: {
    LIST: MUTATION_PREFIX + 'LIST',
    SET_VD: MUTATION_PREFIX + 'SET_VD',
  },
  ACTIONS: {
    LIST: ACTIONS_PREFIX + 'LIST',
    GET_VD: ACTIONS_PREFIX + 'GET_VD',
    CREATE_VD: ACTIONS_PREFIX + 'CREATE_VD',
    DEL_VD: ACTIONS_PREFIX + 'DEL_VD',
    SAVE_VD: ACTIONS_PREFIX + 'SAVE_VD',
    SAVE_AUTO: ACTIONS_PREFIX + 'SAVE_AUTO',
    DEL_AUTO: ACTIONS_PREFIX + 'DEL_AUTO',
  },
}

