export const MUTATION_PREFIX = 'EMAIL_NOT_RECOGNIZED_TYPES: ';
export const ACTIONS_PREFIX = 'EMAIL_NOT_RECOGNIZED_TYPES_';
export const GETTERS_PREFIX = 'EMAIL_NOT_RECOGNIZED_TYPES_';

export default {
	MUTATIONS: {
		GET_EMAIL_NOT_RECOGNIZED_SUCCESS: MUTATION_PREFIX + 'GET_EMAIL_NOT_RECOGNIZED_SUCCESS',
		RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
	},
	ACTIONS: {
		GET_EMAIL_NOT_RECOGNIZED: ACTIONS_PREFIX + 'GET_EMAIL_NOT_RECOGNIZED',
		REMOVE_EMAIL_NOT_RECOGNIZED: ACTIONS_PREFIX + 'REMOVE_EMAIL_NOT_RECOGNIZED',
	},
	GETTERS: {
		EMAIL_NOT_RECOGNIZED: GETTERS_PREFIX + 'EMAIL_NOT_RECOGNIZED',
	}
}