var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("GenericEinkommenSozialversicherungen", {
    attrs: {
      title: "Einkommen und Sozialversicherungen (Partner)",
      data: _vm.incomeSocialSecurityPartner,
      dataTaxResult: _vm.taxCalcResult,
      partner: true
    },
    on: {
      dataChanged: function($event) {
        return _vm.dataChanged($event)
      },
      taxDataChanged: function($event) {
        return _vm.saveTaxData("incomeSocialSecurityPartner")
      },
      bruttoeinkommmenpaDataChanged: function($event) {
        return _vm.calcBruttoeinkommmenpa()
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }