import MR_MONEY_TYPES from './types';
import CORE_TYPES from '../core/types';
import axios from 'axios';
import { buildMessage, } from '../../helpers/log-message-helper';
import * as utils from '../../helpers/commonfunctions';
import LOG_TYPES from '@/store/log/types';

export default {
  [MR_MONEY_TYPES.ACTIONS.GET_LISTING]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/mrMoney/getListing';
     
  
      axios.post(rootState.core.apiAddress + serviceUrl, payload, config).then(response => {
        if (response && response.data) {
  
          commit(MR_MONEY_TYPES.MUTATIONS.GET_LISTING_SUCCESS, response.data);
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });



  },


  [MR_MONEY_TYPES.ACTIONS.ASSIGN_INSURANCE]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true,
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/mrMoney/accept_insurance_proposal';
     
  
      axios.post(rootState.core.apiAddress + serviceUrl, payload, config).then(response => {

        if (response?.data?.error) {
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.error, 'warning'));
        } else {
          if (response?.data?.message) {
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.message, 'success', true, 5000));
          } else {
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Der Schaden wurde erfolgreich übernommen, und die Dokumente wurden direkt zugeordnet.', 'success'));
          }
        }   
        
        resolve(response?.data);
  
      }).catch((error) => {
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Es ist ein Fehler aufgetreten. Der Schaden konnte nicht übernommen werden, und die Dokumente konnten nicht zugeordnet werden.', 'danger'));
        reject(error);
      });
    })    

  },

  [MR_MONEY_TYPES.ACTIONS.RESCANN_ALL]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true,
      cancelableRequest: {
        title: 'Erneut analysieren und autom. zuordnen',
      },      
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/mrMoney/rescanAll';
     
  
      axios.post(rootState.core.apiAddress + serviceUrl, payload, config).then(response => {
        if (response && response.data) {
  
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });



  },
  

  [MR_MONEY_TYPES.ACTIONS.UPLOAD_AND_ANALYSE_PDF]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/mrMoney/uploadAndAnalysePdf';

      if (payload?.fileId) {
        serviceUrl = `${serviceUrl}?fileId=${payload?.fileId}`;
      }
     
  
      axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
        if (response && response.data) {
  
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });



  },

  [MR_MONEY_TYPES.ACTIONS.TRASH_PDF]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/mrMoney/trashPDF';

      if (payload?.mrMoneyId) {
        serviceUrl = `${serviceUrl}?mrMoneyId=${payload?.mrMoneyId}`;
      }
     
  
      axios.delete(rootState.core.apiAddress + serviceUrl, config).then(response => {
        if (response && response.data) {
  
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });



  },
  

  

}