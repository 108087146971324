var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderActionCard", { attrs: { showHeaderCard: false } }),
      _vm.pruefstatusChange &&
      _vm.pruefstatusChange.newStatus &&
      !_vm.pruefstatusChange.errorMessage
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("div", { staticClass: "box__title" }, [
                _vm._v("Prüfstatusänderung")
              ]),
              _c("div", [
                _vm._v(
                  "Kunde " +
                    _vm._s(_vm.pruefstatusChange.kundennr) +
                    " " +
                    _vm._s(_vm.pruefstatusChange.vorname) +
                    " " +
                    _vm._s(_vm.pruefstatusChange.nachname)
                )
              ]),
              _c("div", [
                _vm._v(
                  "Gesellschaft " +
                    _vm._s(_vm.pruefstatusChange.gesellschaftName)
                )
              ]),
              _vm.pruefstatusChange.documents &&
              _vm.pruefstatusChange.documents.length
                ? _c(
                    "div",
                    _vm._l(_vm.pruefstatusChange.documents, function(
                      doc,
                      index
                    ) {
                      return _c("div", { key: index }, [
                        _vm._v(" " + _vm._s(doc.name) + " ")
                      ])
                    }),
                    0
                  )
                : _vm._e(),
              _c("div", [
                _vm._v(
                  "Status ändern " +
                    _vm._s(_vm.pruefstatusChange.pruefstatus.value) +
                    " -> " +
                    _vm._s(_vm.pruefstatusChange.pruefstatusChangedStr)
                )
              ]),
              _vm.pruefstatusChange.pruefstatusChanged === "OK"
                ? _c("InputField", {
                    attrs: { label: "Bemerkung" },
                    model: {
                      value: _vm.form.bemerkung,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "bemerkung", $$v)
                      },
                      expression: "form.bemerkung"
                    }
                  })
                : _vm._e(),
              _vm.pruefstatusChange.pruefstatusChanged === "OK" ||
              _vm.pruefstatusChange.pruefstatusChanged === "IN_BEARBEITUNG"
                ? _c(
                    "div",
                    [
                      _c("DatePickerField", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          isValueAsString: "",
                          disabled: !_vm.pruefstatusChange.isVersBeginnEditable,
                          label: "Versicherungsbeginn"
                        },
                        model: {
                          value: _vm.form.versBeginn,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "versBeginn", $$v)
                          },
                          expression: "form.versBeginn"
                        }
                      }),
                      _vm.pruefstatusChange.isAenderungsBeginnVisible
                        ? _c("DatePickerField", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              isValueAsString: "",
                              label: "Änderungsbeginn"
                            },
                            model: {
                              value: _vm.form.aenderungsBeginn,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "aenderungsBeginn", $$v)
                              },
                              expression: "form.aenderungsBeginn"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _vm.pruefstatusChange.pruefstatusChanged === "IN_BEARBEITUNG"
                ? _c(
                    "div",
                    [
                      _c("InputCheckBoxItem", {
                        attrs: { label: "Makler informieren" },
                        model: {
                          value: _vm.form.maklerInformieren,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "maklerInformieren", $$v)
                          },
                          expression: "form.maklerInformieren"
                        }
                      }),
                      _c("FormLabel", {
                        attrs: {
                          config: { small: true },
                          label:
                            "E-Mail an den Makler, das der Vertrag an die Gesellschaft verschickt wurde"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.pruefstatusChange.pruefstatusChanged === "IN_BEARBEITUNG" ||
              _vm.pruefstatusChange.pruefstatusChanged === "SELBST_GESENDET"
                ? _c(
                    "div",
                    [
                      _c("InputCheckBoxItem", {
                        attrs: { label: "Erinnerung senden" },
                        model: {
                          value: _vm.form.reklamationRemainder,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "reklamationRemainder", $$v)
                          },
                          expression: "form.reklamationRemainder"
                        }
                      }),
                      _c("FormLabel", {
                        attrs: {
                          config: { small: true },
                          label:
                            "Erinnerungsmails / Reklamationen an die Gesellschaft senden bis der Vertrag bearbeitet wurde"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.pruefstatusChange.sendMaklerSelbst
                ? _c("div", { staticClass: "color-danger" }, [
                    _vm._v(
                      " Achtung: der Vermittler hat angegeben der Antrag selbst an die Gesellschaft zu senden bzw gesendet zu haben! "
                    )
                  ])
                : _vm._e(),
              _vm.pruefstatusChange.emailData
                ? _c(
                    "div",
                    [
                      _c("InputRadioBoxGroup", {
                        attrs: { values: _vm.comboboxValues },
                        model: {
                          value: _vm.form.emailSenden,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "emailSenden", $$v)
                          },
                          expression: "form.emailSenden"
                        }
                      }),
                      _vm.form.emailSenden === "RADIO_MAIL"
                        ? _c("MailComposer", {
                            ref: "mailComposer",
                            attrs: {
                              hasPageHeader: false,
                              legitimation: {
                                hasLegitimation: true,
                                disabled:
                                  _vm.pruefstatusChange.emailDataActions &&
                                  _vm.pruefstatusChange.emailDataActions[
                                    "LEGITIMATION"
                                  ]
                              },
                              vollmacht: {
                                hasVollmacht: true,
                                disabled:
                                  _vm.pruefstatusChange.emailDataActions &&
                                  _vm.pruefstatusChange.emailDataActions[
                                    "VOLLMACHT"
                                  ]
                              },
                              senderDisabled: true,
                              multipleReceivers: false,
                              hasPasswort: true
                            },
                            on: {
                              fileUpload: function($event) {
                                return _vm.fileUpload($event)
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "mt-3" }, [
                _vm._v("Wollen Sie die Änderung übernehmen?")
              ]),
              _c("div", { staticClass: "layout__negative-margin--8" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 col-sm-3 col-lg-2 mt-1" },
                    [
                      _c(
                        "BaseButton",
                        {
                          attrs: { isBlock: "", isPrimary: "" },
                          on: {
                            click: function($event) {
                              return _vm.confirm()
                            }
                          }
                        },
                        [_vm._v("Ja")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12 col-sm-3 col-lg-2 mt-1" },
                    [
                      _c(
                        "BaseButton",
                        {
                          attrs: { isBlock: "", isSecondary: "" },
                          on: {
                            click: function($event) {
                              return _vm.resetData()
                            }
                          }
                        },
                        [_vm._v("Nein")]
                      )
                    ],
                    1
                  )
                ])
              ])
            ],
            1
          )
        : _vm.pruefstatusChange && _vm.pruefstatusChange.errorMessage
        ? _c("div", { staticClass: "box__container" }, [
            _c("div", { staticClass: "box__title" }, [_vm._v("Fehler")]),
            _c("div", [_vm._v(_vm._s(_vm.pruefstatusChange.errorMessage))]),
            _c(
              "div",
              { staticClass: "col-12 col-sm-3 col-lg-2 mt-1" },
              [
                _c(
                  "BaseButton",
                  {
                    attrs: { isBlock: "", isPrimary: "" },
                    on: {
                      click: function($event) {
                        return _vm.resetData()
                      }
                    }
                  },
                  [_vm._v("Ok")]
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _c(
        "BaseModal",
        {
          ref: "noAttachments",
          attrs: { showCancelButton: false, labelButtonConfirm: "Ok" },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-warning", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v("Dokumente anhängen ")
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", [
            _vm._v("Achtung, es wurden keine Dokumente an die Mail angehängt.")
          ])
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "errorModal",
          attrs: { showCancelButton: false, labelButtonConfirm: "Ok" },
          on: {
            onConfirmButton: function($event) {
              _vm.errorMessage = null
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-warning", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v("Fehler ")
                ]
              },
              proxy: true
            }
          ])
        },
        [_c("div", [_vm._v(_vm._s(_vm.errorMessage))])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }