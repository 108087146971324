var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseFileSelect",
    {
      attrs: {
        asButton: "",
        disabled: _vm.isDisabled,
        isSecondary: !_vm.isMainAction,
        accept: _vm.accept,
        multiple: _vm.multiple
      },
      on: { files: _vm.onFileSelection }
    },
    [
      _vm.iconComponent
        ? _c(_vm.iconComponent, { tag: "component", attrs: { size: _vm.size } })
        : _vm._e(),
      _vm._v(" " + _vm._s(_vm.responsiveLabel) + " ")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }