<template>
  <div>
    <PageHeaderTitleNavigation 
      title="Mitarbeiter" 
      :actions="headerActions"
      @action-NEW="createNewAp()"
    />

    <div class="box__container">
      <div class="box__title">Ansprechpartner</div>
      <ComboBox
        label="Primärer Ansprechpartner"
        :values="primaerPersonen.hauptPersonValues"
        v-model="primaerPersonen.hauptPerson"
        isComponentHalfSize
        :disabled="isSomeButtonLoading"
        @change="savePrimaerPersonen('hauptPerson', $event, true)"
      />
      <ComboBox
        label="Primärer Kundenberater"
        :values="primaerPersonen.kundenBeraterValues"
        v-model="primaerPersonen.kundenBerater"
        isComponentHalfSize
        :disabled="isSomeButtonLoading"
        @change="savePrimaerPersonen('kundenBerater', $event)"
      />
    </div>
    <div class="box__container">
      <GhostLoading v-if="loading.table" type="table" :title="TABLE_TITLE" />
      <AnsprechpartnerTabelle v-else :title="TABLE_TITLE" :maklerAnsprechpartner="ansprechpartnerList" @delete="deletePerson"></AnsprechpartnerTabelle>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BROKERDATA_TYPES from '@/store/brokerData/types';

import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import AnsprechpartnerTabelle from '@/components/broker/AnsprechpartnerTabelle.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import ComboBox from "@/components/core/forms/ComboBox.vue";
import axios from 'axios';

import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import { ROLES, VIEW_ROLES, BROKER_LEVEL_ROLES, EMPLOYEE_ROLES } from '@/router/roles';

const SERVICE_PATH = '/brokerData'
const config = {
  defaultSpinner: true
};

const TABLE_TITLE = 'Mitarbeiter Liste';

export default {
  name: 'MaklerPersonen',
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    AnsprechpartnerTabelle,
    ComboBox,
    GhostLoading,
  },
  data() {
    return {
      TABLE_TITLE,
      loading: {
        table: false,
        primaer: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      primaerPersonen: BROKERDATA_TYPES.GETTERS.GET_BROKER_PRIMAER_PERSONEN,
      ansprechpartnerList: BROKERDATA_TYPES.GETTERS.ANSPRECHPARTNER_LIST,
    }),
    headerActions() {
      return [
        PageHeaderSimpleAction('NEW', 'Neuen Mitarbeiter anlegen')
          .withVisible(() => this.$hasRoles([
            [ROLES.FK, VIEW_ROLES.VIEW_BROKER, BROKER_LEVEL_ROLES.USER_IS_REALLY_USER],
            [ROLES.FK, VIEW_ROLES.VIEW_BROKER_AS_INTERN],
            [ROLES.FK, ROLES.IS_USER_UNTERNR, BROKER_LEVEL_ROLES.ZUGRIFF_MITARBEITER_NEU, EMPLOYEE_ROLES.ZUGRIFF_MITARBEITER_NEU],
            [ROLES.FA, BROKER_LEVEL_ROLES.UNTERVERMITTLER_NEU, BROKER_LEVEL_ROLES.ZUGRIFF_MITARBEITER_NEU, EMPLOYEE_ROLES.ZUGRIFF_MITARBEITER_NEU],
          ]))
          .withDisabled(() => this.loading?.table),
      ];
    },
    isSomeButtonLoading() {
      return !!(Object.values(this.loading).filter(valueLoading => valueLoading).length)
    },
  },
  methods: {
    async findAnsprechpartner() {
      try {
        this.loading.table = true;
        await this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.FIND_ANSPRECHPARTNER);
      } finally {
        this.loading.table = false;
      }
    },
    createNewAp() {
      this.$router.push(`/intern/ansprechpartner/newPerson`);
    },
    savePrimaerPersonen(field, value, refreshTable = false) {
      this.loading.primaer = true

      const payload = {
        ...this.primaerPersonen,
        [field]: value,
      }

      axios.post(`${process.env.VUE_APP_API}/${SERVICE_PATH}/savePrimaerPersonen`, payload, config).then(() => {
        if (refreshTable) {
          this.findAnsprechpartner()
        }
      }).finally(() => this.loading.primaer = false)
    },
    deletePerson(personId) {
      this.loading.table = true

      this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.DELETE_PERSON, { personId })
      .then(() => {
        this.findAnsprechpartner()
        this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.GET_BROKER_PRIMAER_PERSONEN)
      })
      .catch(() => this.loading.table = false)
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({ to, from, });
    next();
  },
  mounted() {
    this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.GET_BROKER_PRIMAER_PERSONEN);
    this.findAnsprechpartner();
  },
}
</script>
