var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.findLabelComp(_vm.editableComponent)
      ? _c(
          "div",
          { staticClass: "defaultcard-container defaultcard" },
          [
            _c("span", { staticClass: "box__title" }, [
              _vm._v("Überschrift des Eingabefeldes")
            ]),
            _c("HtmlEditor", {
              attrs: {
                doNotAppendHtmlBody: "",
                isFontParams: "",
                textPlaceholderOptions: _vm.placeholdersOptions
              },
              on: {
                input: function($event) {
                  _vm.getUpdatedHtml(
                    $event,
                    _vm.findLabelComp(_vm.editableComponent)
                  )
                }
              },
              model: {
                value: _vm.html,
                callback: function($$v) {
                  _vm.html = $$v
                },
                expression: "html"
              }
            })
          ],
          1
        )
      : _vm._e(),
    _vm.internalValue !== undefined
      ? _c("div", { staticClass: "defaultcard-container" }, [
          _c("span", { staticClass: "box__title" }, [
            _vm._v("Inhalt des Eingabefeldes")
          ]),
          _c("div", { staticClass: "d-flex" }, [
            _c("div", { staticClass: "grow-1" }, [
              _c(
                "div",
                {
                  staticClass: "d-inline-block placeholder-control",
                  on: {
                    click: function($event) {
                      _vm.showValuePlaceholder = !_vm.showValuePlaceholder
                    }
                  }
                },
                [
                  _vm._v(" Platzhalter "),
                  !_vm.showValuePlaceholder
                    ? _c("PhCaretDown", { attrs: { size: "20" } })
                    : _vm._e(),
                  _vm.showValuePlaceholder
                    ? _c("PhCaretUp", { attrs: { size: "20" } })
                    : _vm._e()
                ],
                1
              )
            ])
          ]),
          _vm.showValuePlaceholder
            ? _c("div", { staticClass: "placeholders" }, [
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-between" },
                  _vm._l(_vm.placeholdersOptions, function(p, i) {
                    return _c(
                      "div",
                      { key: "p" + i, staticClass: "grow-1" },
                      [
                        _c("ComboBox", {
                          attrs: {
                            label: p.name,
                            values: p.data,
                            firstEmpty: true
                          },
                          on: {
                            change: function($event) {
                              return _vm.insertPlaceholder($event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  }),
                  0
                )
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "mt-3" },
            [
              _c("InputField", {
                attrs: { isComponentHalfSize: "" },
                model: {
                  value: _vm.internalValue,
                  callback: function($$v) {
                    _vm.internalValue = $$v
                  },
                  expression: "internalValue"
                }
              })
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }