<template>
  <canvas v-if="showHeatmap" 
    id="my-canvas" 
    class="heatmap__canvas" 
    :style="{ 
      'width': width + 'px',
      'height': height + 'px',
    }">
    </canvas>
</template>

<script>
import { mapGetters } from 'vuex'
import SESSION_TIME_TYPES from "@/store/session-time/types"
import CORE_TYPES from '@/store/core/types'
import { VIEW_ROLES } from '@/router/roles'
import LOG_TYPES from '@/store/log/types'
import { buildMessageWith } from '@/helpers/log-message-helper'

export default {
  data() {
    return {
      ctx: null,
      canvas: null,
      heatmapVisible: false,

      height: 0,
      width: 0,
    }
  },
  watch: {
    '$route': {
      handler() {
        this.drawClicks()
      }
    }
  },
  computed: {
    ...mapGetters({
      clicksByPath: SESSION_TIME_TYPES.GETTERS.CLICKS_BY_PATH,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
    currentClicks() {
      return this.clicksByPath[this.$route.path]
    },
    heatmapAvailable() {
      return this.hasRoles([
        [VIEW_ROLES.VIEW_INTERN], 
        [VIEW_ROLES.VIEW_CUSTOMER_AS_INTERN], 
        [VIEW_ROLES.VIEW_BROKER_AS_INTERN]
      ])
    },
    showHeatmap() {
      return this.heatmapVisible && this.heatmapAvailable
    },
  },

  methods: {
    drawClicks() {
      setTimeout(() => {
        this.calculateCanvasSize()
        this.drawClicksUniqueColor()
      }, 250)
    },
    drawClicksUniqueColor() {
      if (!this.showHeatmap) {
        return
      }
      
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

      if (!this.currentClicks) {
        return
      }

      for (const click of this.currentClicks) {
        this.drawADot(click.x, click.y, click.userType)
      }
    },
    drawADot(centerX, centerY, userType) {
      const radius = 5;

      const colors = {
        'INTERN': '#065535',
        'MAKLER': '#003366',
        'KUNDE': '#cc0000',
        'PERSON': '#660066',
      }

      this.ctx.beginPath();
      this.ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
      this.ctx.fillStyle = '#a50026';
      this.ctx.fill();
    },
    onLogout() {
      this.heatmapVisible = false
    },
    getHeight() {
      return document.body.clientHeight || window.innerHeight
    },
    getWidth() {
      return document.body.clientWidth || window.innerWidth
    },
    calculateCanvasSize() {
      this.height = this.getHeight();
      this.width = this.getWidth();

      if (this.canvas) {
        this.canvas.height = this.height;
        this.canvas.width = this.width;
      }
    },
    notifyHeatmap() {
      if (this.heatmapAvailable) {
        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessageWith({
          content: this.showHeatmap ? 'Heatmap aktiviert' : 'Heatmap deaktiviert',
          timeout: 4000
        }))
      }
    },
    toggleHeatmap() {
      this.calculateCanvasSize()
      this.heatmapVisible = !this.heatmapVisible
      this.notifyHeatmap()

      if (this.showHeatmap) {
        this.$store.dispatch(SESSION_TIME_TYPES.ACTIONS.LOAD_CLICKS)

        this.$nextTick(() => {
          this.canvas = document.getElementById("my-canvas");
          this.ctx = this.canvas.getContext("2d");

          this.drawClicks()
        })
      }
    }
  },

  mounted() {
    document.addEventListener('onLogout', this.onLogout)
    window.addEventListener('resize', this.drawClicks)
    document.addEventListener('onToggleHeatmap', this.toggleHeatmap)
  },

  destroyed() {
    document.removeEventListener('onLogout', this.onLogout)
    window.removeEventListener('resize', this.drawClicks)
    document.removeEventListener('onToggleHeatmap', this.toggleHeatmap)
  }
}

</script>

<style scoped>
.heatmap__canvas{
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.6;
  pointer-events: none;
  z-index: 9999999;
}
</style>