var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: "Steuerliche Daten" }
      }),
      _c("div", { staticClass: "box__container" }, [
        _c("div", { staticClass: "box__title" }, [
          _vm._v("Auswahl der steuerlichen Daten")
        ]),
        _c("div", [_vm._v(_vm._s(_vm.subtitle))])
      ]),
      _c(
        "BaseFilter",
        {
          attrs: {
            filterId: _vm.saveKey,
            title:
              "Geben Sie an, ab welchem Datum die steuerliche Daten angezeigt werden sollen",
            metadata: _vm.searchMenu,
            configFilter: _vm.configFilter,
            defaultOptions: _vm.defaultOptions
          },
          on: {
            onFilter: function($event) {
              return _vm.handleSearch($event)
            },
            onFilterZurucksetzen: function($event) {
              return _vm.$emit("onFilterZurucksetzen")
            }
          }
        },
        [_vm._t("default")],
        2
      ),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.rows && _vm.rows.length
            ? _c(
                "div",
                [
                  _c("Table", {
                    attrs: {
                      headers: _vm.headers,
                      rows: _vm.rows,
                      rowsPerPage: 20,
                      exportConfig: { roottext: "Steuerliche Daten" },
                      rowId: "steuerlicheDaten"
                    },
                    on: { "click-kundenName": _vm.openCustomerNewTab }
                  })
                ],
                1
              )
            : _c("NoData", { attrs: { content: "Keine Informationen" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }