import VERTRAG_STATUS_TYPES from './types';

export default {
  [VERTRAG_STATUS_TYPES.GETTERS.VERTRAG_STATUS_LIST](state) {
    return { ...state.vertragsStatusList };
  },
  [VERTRAG_STATUS_TYPES.GETTERS.VERTRAG_STATUS](state) {
    return { ...state.vertragStatus };
  },
}
