export const MUTATION_PREFIX = 'EXPOST: ';
export const ACTIONS_PREFIX = 'EXPOST_ACTIONS_';
export const GETTERS_PREFIX = 'EXPOST_GETTERS_';

export default {
  MUTATIONS: {
    SET_CONFIG: MUTATION_PREFIX + 'SET_CONFIG',
    SET_LIST: MUTATION_PREFIX + 'SET_LIST',
    SET_BERECHNUNG: MUTATION_PREFIX + 'SET_BERECHNUNG',
  },
  ACTIONS: {
    LOAD_CONFIG: ACTIONS_PREFIX + 'LOAD_CONFIG',
    SAVE_CONFIG: ACTIONS_PREFIX + 'SAVE_CONFIG',
    LOAD_LIST: ACTIONS_PREFIX + 'LOAD_LIST',
    GET_PDF: ACTIONS_PREFIX + 'GET_PDF',
    GET_BERECHNUNG: ACTIONS_PREFIX + 'GET_BERECHNUNG',
  },
  GETTERS: {
    GET_CONFIG: GETTERS_PREFIX + 'GET_CONFIG',
    GET_LIST: GETTERS_PREFIX + 'DEPOTSTAMMDATEN',
    BERECHNUNG: GETTERS_PREFIX + 'BERECHNUNG',
  }
}
