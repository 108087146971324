<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="851.034"
    height="593.12578"
    viewBox="0 0 851.034 593.12578"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <polygon
        points="106.02869 527.61269 119.19265 527.9242 114.22039 567.99929 105.30017 567.78878 106.02869 527.61269"
        fill="#ffb6b6"
      />
      <path
        d="M135.90429,586.39896l-.00011-.00003c0-1.08826-.56607-2.07243-1.4405-2.50438l-9.82449-4.85313-6.6981-15.53484-.10149-15.65075-13.80219-1.62303-1.99616,9.97012s4.11002-.61326-.96433,3.79213c-5.0743,4.40542-2.63095,13.02367-2.63095,13.02367l-1.10666,16.10879h9.13381l.49029-10.01818c7.10401,3.01419,8.84642,10.01818,8.84642,10.01818h17.70582c1.31918,0,2.38858-1.22162,2.38858-2.72852h0l.00005-.00003Z"
        fill="#2f2e41"
      />
    </g>
    <g>
      <polygon
        points="125.31118 528.05377 138.47514 528.36528 133.50287 568.44037 124.58268 568.22986 125.31118 528.05377"
        fill="#ffb6b6"
      />
      <path
        d="M155.18678,586.84004l-.00011-.00003c0-1.08826-.56607-2.07243-1.4405-2.50438l-9.82449-4.85313-6.6981-15.53484-.10149-15.65075-13.80219-1.62303-1.99616,9.97012s4.11002-.61326-.96433,3.79213c-5.0743,4.40542-2.63095,13.02367-2.63095,13.02367l-1.10666,16.10879h9.13383l.49029-10.01818c7.10401,3.01419,8.84642,10.01818,8.84642,10.01818h17.70582c1.31918,0,2.38858-1.22162,2.38858-2.72852h0l.00003-.00003Z"
        fill="#2f2e41"
      />
    </g>
    <path
      d="M110.2148,292.9908h0l-14.92335,35.63332c-4.97455,11.87802-6.55102,24.90459-4.5535,37.62636l7.2531,175.05372h28.67661l12.51705-155.88562,2.47005,61.57671-28.70756,87.73249h28.23487l36.24153-87.7325,2.68683-67.55462-12.87551-52.42174-57.02011-34.02814h0Z"
      fill="#2f2e41"
    />
    <g>
      <path
        id="b-225"
        d="M514.50928,140.51913h0V51.51363C514.50928,23.06344,491.44586,0,462.99567,0h-188.5741c-28.4502-.00047-51.51404,23.06261-51.5145,51.51281v488.29096c0,28.4502,23.06343,51.51361,51.51364,51.51361h188.57251c28.4502,0,51.51361-23.06342,51.51361-51.51361V203.87473h.00024c1.79193,0,3.24463-1.45264,3.24469-3.24458l.00226-56.86618c.00006-1.79205-1.45264-3.24484-3.24469-3.24484l-.00006,.00002Z"
        fill="#3f3d56"
      />
      <path
        id="c-226"
        d="M504.8356,47.23048v495.18908c-30.10516-1.84918-14.7923,37.81769-39.05603,39.06433h-192.44296c-21.57881,.00323-39.07541-17.48553-39.08183-39.06433V47.23048c.00642-21.57802,17.50214-39.06627,39.08015-39.06351h14.19235c-3.8649,9.50451,27.32773,12.59678,29.71896,12.59981h98.55893c10.26028-.01299,35.01379-10.38471,34.11304-12.59981h15.85388c21.56973,.00369,39.05566,17.4863,39.06351,39.05603l.00006,.00748h-.00006Z"
        :fill="secondColor"
      />
      <g>
        <path
          d="M221.39043,151.42123c-1.37241,0-2.48843,1.11603-2.48843,2.48843v19.90749c0,1.37241,1.11603,2.48843,2.48843,2.48843s2.48843-1.11603,2.48843-2.48843v-19.90749c0-1.37241-1.11603-2.48843-2.48843-2.48843Z"
          fill="#3f3d56"
        />
        <path
          d="M221.39043,211.14368c-1.37241,0-2.48843,1.11603-2.48843,2.48843v38.57074c0,1.37241,1.11603,2.48843,2.48843,2.48843s2.48843-1.11603,2.48843-2.48843v-38.57074c0-1.37241-1.11603-2.48843-2.48843-2.48843Z"
          fill="#3f3d56"
        />
        <path
          d="M221.39043,265.88925c-1.37241,0-2.48843,1.11603-2.48843,2.48843v38.57074c0,1.37241,1.11603,2.48843,2.48843,2.48843s2.48843-1.11603,2.48843-2.48843v-38.57074c0-1.37241-1.11603-2.48843-2.48843-2.48843Z"
          fill="#3f3d56"
        />
      </g>
    </g>
    <g>
      <polygon
        points="808.33052 559.84212 796.52176 567.9306 759.70145 526.23338 777.12972 514.29506 808.33052 559.84212"
        fill="#ffb6b6"
      />
      <path
        d="M767.69144,587.29035h0c.05656,.87112,1.23174,3.00255,1.72507,3.72263h0c1.51638,2.21337,4.53988,2.77842,6.7533,1.26201l36.5679-25.05266c1.50995-1.03447,1.89546-3.09716,.86095-4.60716l-1.04302-1.52244s-1.32584-5.81507-8.91405-8.90331c0,0-1.60346,7.58732-13.25885,5.40924l-4.5712-2.29767-7.36681,20.91319-7.49361,6.61402c-1.63951,1.44699-3.40123,2.27995-3.25956,4.462l-.00015,.0001,.00003,.00005Z"
        fill="#2f2e41"
      />
    </g>
    <g>
      <polygon
        points="721.2688 581.31561 706.95551 581.31421 700.14661 526.10522 717.66663 528.28687 721.2688 581.31561"
        fill="#ffb6b6"
      />
      <path
        d="M672.22968,580.99091h0c-.44568,.75061-.68085,3.17316-.68085,4.04602h0c0,2.68298,2.17493,4.85797,4.85797,4.85797h44.3266c1.83032,0,3.31415-1.48376,3.31415-3.31415v-1.84546s2.19281-5.54657-2.32178-12.383c0,0-5.61102,5.35303-13.9953-3.03125l-2.47247-4.47906-17.89716,13.08905-9.9201,1.22107c-2.17035,.26709-4.09448-.04144-5.21088,1.83875h-.00018v.00006Z"
        fill="#2f2e41"
      />
    </g>
    <path
      id="d-227"
      d="M671.08496,371.50479l1.07708,13.01898-2.14348,10.35302,3.903,10.91486,4.77447,57.71036,24.88391,99.49799,19,4-9.12122-99.3869,7.0752-81.9783-49.44897-14.13Z"
      fill="#2f2e41"
    />
    <path
      id="e-228"
      d="M751.82495,314.9848l-72.66602,1.009-8.07404,55.508,49.44897,23.21301-1.57738,79.27601,55.07146,79.7196,29.33117-16.75766-49.14889-67.70036,13.7626-112.8896-16.14801-41.37799h.00012Z"
      fill="#2f2e41"
    />
    <path
      id="f-229"
      d="M698.034,184.4868l-12.82001,16.453,6.05603,126.15501,47.43402-1.009,8.07404-110.007-10.09198-28.259-3.65198-6.33299-4.36737-8.19995-28,2-2.63263,9.19994h-.00012Z"
      fill="#e6e6e6"
    />
    <path
      id="g-230"
      d="M679.10297,262.50378l2.5636,25.78308-3.1496,29.66953-3.93701,8.04361-9,32s-9.185,13.99399,11,15,86.34595-5.54117,86.34595-5.54117c0,0,8.2489-.20343,4.65405-17.45883-5.80714-9.98025-2.06299-24.04361-2.06299-24.04361l-7.63702-28.13556,2.01801-26.23999,14.13-68.629c-12.54883-9.86151-26.60425-16.91971-41.88403-21.70001l-13.38254,35.19014-19.92545-32.16216-7.56903,5.55099c-10.20923,2.67712-19.19403,6.79395-26.29999,13.12l14.13605,59.55298Z"
      fill="#2f2e41"
    />
    <path
      id="h-231"
      d="M439.54602,201.29082h-142.435c-9.76315-.01102-17.67499-7.92285-17.686-17.686v-61.22501c.01102-9.76314,7.92288-17.67499,17.686-17.686,45.45001-12.592,93.11798-11.412,142.435,0,9.76276,.01155,17.67401,7.92325,17.68503,17.686v61.22501c-.01102,9.76276-7.92224,17.67444-17.685,17.686h-.00003Z"
      fill="#f2f2f2"
    />
    <g id="i" transform="translate(0 -32.533)">
      <path
        id="j-232"
        d="M454.05698,297.98982h-171.45599c-1.75281-.00165-3.17334-1.42218-3.17499-3.17499v-42.53c.0022-1.75259,1.42242-3.17279,3.17499-3.17499h171.45599c1.75256,.0022,3.17279,1.42239,3.17499,3.17499v42.53101c-.0022,1.75259-1.42242,3.17279-3.17499,3.17499v-.00101Zm-171.45599-47.61002c-1.05164,.0011-1.90393,.85333-1.90503,1.905v42.53101c.0011,1.05167,.85339,1.9039,1.90503,1.905h171.45599c1.05164-.0011,1.90393-.85333,1.90503-1.905v-42.53101c-.0011-1.05167-.85339-1.9039-1.90503-1.905h-171.45599Z"
        fill="#e6e6e6"
      />
      <path
        id="k-233"
        d="M335.62497,264.6558c-1.22748,.02594-2.20148,1.04202-2.17554,2.26947,.02521,1.19095,.98462,2.15036,2.17554,2.17554h104.77802c1.22693,.04694,2.25952-.90961,2.30652-2.13651,.04694-1.2269-.90961-2.25955-2.13654-2.30649h-104.948v-.00201Z"
        fill="#e6e6e6"
      />
      <path
        id="l-234"
        d="M335.62497,277.99479c-1.22748,.02594-2.20148,1.04202-2.17554,2.26947,.02521,1.19095,.98462,2.15036,2.17554,2.17554h104.77802c1.22693,.04694,2.25952-.90961,2.30652-2.13651,.04694-1.2269-.90961-2.25955-2.13654-2.30649h-104.948v-.00201Z"
        fill="#e6e6e6"
      />
      <circle id="m" cx="306.19202" cy="273.55079" r="8.89099" fill="#e6e6e6" />
    </g>
    <g id="n" transform="translate(0 0.223)">
      <path
        id="o-235"
        d="M454.05698,473.98978h-171.45599c-1.75281-.00165-3.17334-1.42218-3.17499-3.17499v-42.52997c.0022-1.75256,1.42242-3.17279,3.17499-3.17499h171.45599c1.75256,.0022,3.17279,1.42242,3.17499,3.17499v42.53101c-.0022,1.75256-1.42242,3.17279-3.17499,3.17499v-.00104Zm-171.45599-47.60999c-1.0517,.0011-1.90393,.85333-1.90503,1.90503v42.53101c.0011,1.0517,.85333,1.90393,1.90503,1.90503l151.97897-1.49786c1.0517-.0011,16.9989-5.9483,17-7l4.38202-35.93817c-.0011-1.0517-.85333-1.90393-1.90503-1.90503h-171.45596Z"
        fill="#e6e6e6"
      />
      <path
        id="p-236"
        d="M335.62497,446.9958c-1.22748-.02594-2.24353,.94806-2.26947,2.17554s.94806,2.24353,2.17554,2.26947c.03131,.00067,.06262,.00067,.09393,0h104.77802c1.22693,.04694,2.25952-.90961,2.30652-2.13647,.04694-1.22693-.90961-2.25958-2.13654-2.30652h-104.948v-.00201Z"
        fill="#e6e6e6"
      />
      <circle id="q" cx="306.19202" cy="449.55079" r="8.89099" fill="#e6e6e6" />
    </g>
    <g id="r" transform="translate(0 0.223)">
      <path
        id="s-237"
        d="M454.05698,385.98978h-171.45599c-1.75281-.00165-3.17334-1.42218-3.17499-3.17499v-42.52997c.0022-1.75256,1.42242-3.17279,3.17499-3.17499h171.45599c1.75256,.0022,3.17279,1.42242,3.17499,3.17499v42.53101c-.0022,1.75256-1.42242,3.17279-3.17499,3.17499v-.00104Zm-171.45599-47.60999c-1.0517,.0011-1.90393,.85333-1.90503,1.90503v42.53101c.0011,1.0517,.85333,1.90393,1.90503,1.90503l151.97897-1.49786c1.0517-.0011,16.9989-5.9483,17-7l4.38202-35.93817c-.0011-1.0517-.85333-1.90393-1.90503-1.90503h-171.45596Z"
        fill="#e6e6e6"
      />
      <path
        id="t-238"
        d="M335.62497,358.9958c-1.22748-.02594-2.24353,.94806-2.26947,2.17554s.94806,2.24353,2.17554,2.26947c.03131,.00067,.06262,.00067,.09393,0h104.77802c1.22693,.04694,2.25952-.90961,2.30652-2.13647,.04694-1.22693-.90961-2.25958-2.13654-2.30652h-104.948v-.00201Z"
        fill="#e6e6e6"
      />
      <circle id="u" cx="306.19202" cy="361.55079" r="8.89099" fill="#e6e6e6" />
    </g>
    <g>
      <circle cx="712.16968" cy="147.07086" r="25.04932" fill="#ffb6b6" />
      <path
        d="M730.56708,167.65244c1.37976,.23524,2.99957-3.10915,4.54742-6.36639,3.2403-6.81859,5.30017-11.15318,5.45691-17.28021,.13361-5.22198-1.31714-3.85144-2.72845-15.46124-.46545-3.82923-7.00739-3.82186-8.33887-5.64016-2.24908-3.0714,1.20166-4.33809-4.39392-6.18315-10.48108-3.45587-15.22058,1.9127-21.87677,2.37521-.16821,.0117-3.46796,3.23869-5.31085,3.40556-3.22882,.29236-7.88672-.21057-9.97491-5.12995-1.95251-4.59969-3.24585,8.84154-1.50073,10.50497,1.48169,1.4123,4.0376,4.95715,4.10284,5.21494,.07385,.29176-6.07745-6.52229-7.10425-3.71423-.73901,2.02092,1.58722,12.41829,3.46631,12.80908,1.57141,.32674,5.41449-4.85272,4.54742-2.72845-2.01093,4.92648,10.08075,8.06992,24.55609-1.81897,.51331-.35072,.02844,.2086,1.81897,4.54742,2.25824,5.47223,2.76788,5.76866,2.72845,7.27588-.08533,3.2627-2.53503,4.22336-1.81897,6.36639,.57996,1.7357,2.8465,3.07939,4.54742,2.72845,1.91943-.39612,2.24854-2.77065,3.63794-2.72845,1.19012,.03619,2.6026,1.82874,2.72845,3.63794,.26508,3.81146-5.37756,4.85973-6.36639,10.00433-.55804,2.90343,6.00793-2.03513,7.27588-1.81897Z"
        fill="#2f2e41"
      />
    </g>
    <g>
      <path
        id="v-239"
        d="M787.25488,367.87912h0c.1084-2.80505-.45642-5.59528-1.64734-8.13727l2.5799-29.23322-13.9502-.81976-1.11432,29.74731c-1.31622,2.47955-2.01947,5.23831-2.05096,8.04538-.17493,7.02219,3.30585,12.80411,7.77313,12.91602s8.23206-5.49051,8.40735-12.51163l.00244-.00684Z"
        fill="#ffb6b6"
      />
      <path
        id="w-240"
        d="M757.58002,250l11,34,3.15796,69.44073,18.41351-.37613,1.42853-76.06461s-9.17682-56.20538-12.24969-68.83829c-3.07288-12.63289-31.40656-7.95123-31.40656-7.95123l9.65625,49.78954v-.00002Z"
        fill="#2f2e41"
      />
    </g>
    <path
      d="M301.32031,161.35631c5.202,1.72716,10.65576,3.47565,16.2085,3.18057,2.52698-.13428,4.95786-.73685,7.24997-1.80872,2.35101-1.0994,4.49918-2.59225,6.87885-3.6338,8.74551-3.82774,17.41873,2.71127,25.57477,5.16556,4.0694,1.22455,8.34872,1.51756,12.3291-.18796,4.39764-1.88432,7.71497-5.48019,11.33585-8.4825,8.11838-6.73145,18.711-10.3606,29.24765-10.08623,11.55887,.30098,22.74683,5.4064,30.70657,13.75731,.66736,.70016,1.72696-.36163,1.06067-1.06065-6.9563-7.29819-16.32962-12.21854-26.31122-13.70955-10.00311-1.49423-20.38733,.50233-29.18265,5.48373-4.45081,2.5208-8.02051,5.97134-11.94138,9.19156-3.81812,3.13582-8.06329,5.15669-13.09686,4.49023-8.67206-1.14819-16.1315-7.83051-25.1254-7.22467-5.74435,.38695-9.8468,4.70876-15.25201,6.08656-6.44849,1.64372-13.18832-.58414-19.28357-2.60786-.91824-.30487-1.31198,1.14322-.39877,1.44643h0l-.00006-.00002Z"
      fill="#3f3d56"
    />
    <circle id="x" cx="317.60202" cy="163.7928" r="5.556" :fill="color" />
    <circle id="y" cx="365.56604" cy="164.0388" r="5.556" :fill="color" />
    <circle id="a`" cx="414.276" cy="145.19179" r="5.556" :fill="color" />
    <path
      id="aa-241"
      d="M91.88582,337.94937c-16.42693,46.7715,4.50266,83.62044,.15932,59.85027-3.62983-19.86527,58.22264-80.31491,76.84067-67.85033-.39001-2.017-12.22395-38.38721-13.38399-38.55518,1.62299-4.08701-.63898-4.12601-.73102-6.22-.35199-8.08801,1.52802-16.48399,5.19-25.125,2.40002-6.67599,2.44202-12.412,.11603-17.037-2.0014-3.80138-5.36377-6.70734-9.41498-8.13699l-12.20001-14.73399c-3.17798-3.83501-7.86499-12.11-12.84497-12.07899l-26.73297-.955c-.76398-.459-10.151,7.61301-19.29102,11.57401-9.91302,4.3-7.86798,16.036-7.84302,16.186l.02301,.14,.10901,.10001,14.57097,42.88763,13.16506,12.33837c-.66699,1.93402-.5,3.38599,.74902,4.21899-1.79303,1.32599-9.76908,41.00723-8.48112,42.39722v1h0Z"
      :fill="color"
    />
    <g>
      <path
        id="ab-242"
        d="M229.25006,253.07015c-1.98782,.79332-3.75677,2.05104-5.159,3.668l-21.17001,7.257,3.74799,9.972,21.07101-8.435c2.13528,.14932,4.27527-.21483,6.241-1.062,4.94699-2.05299,7.89999-6.269,6.59201-9.41699s-6.37601-4.036-11.323-1.983h0v-.00002Z"
        fill="#ffb6b6"
      />
      <path
        id="ac-243"
        d="M116.32082,221.0671h0l48.29501,65.19,56.61102-15.59-2.461-14.76801-43.10803,8.16499-24.04901-36.972c-7.853-15.17999-23.33099-18.237-35.28799-6.026v.00101Z"
        :fill="color"
      />
    </g>
    <g>
      <path
        d="M463.18613,549.78687h-22.03906c-3.84912,0-6.98047-3.13184-6.98047-6.98047-4.17786-11.3512-4.7049-21.53308,0-30.03906,0-3.84863,3.13135-6.98047,6.98047-6.98047,6.51697-4.72729,14.11517-3.29156,22.03906,0,3.84912,0,6.98047,3.13184,6.98047,6.98047-2.67426,9.62415-2.48053,19.66541,0,30.03906,0,3.84863-3.13135,6.98047-6.98047,6.98047Z"
        :fill="color"
      />
      <g>
        <path
          id="ad-244"
          d="M461.05499,519.15576h-17.79999c-.61441,0-1.11249-.49811-1.11249-1.11249s.49808-1.11249,1.11249-1.11249h17.79999c.61441,0,1.11249,.49811,1.11249,1.11249s-.49808,1.11249-1.11249,1.11249h0Z"
          :fill="secondColor"
        />
        <path
          id="ae-245"
          d="M461.05499,528.05377h-17.79999c-.61441,0-1.11249-.49811-1.11249-1.11249s.49808-1.11249,1.11249-1.11249h17.79999c.61441,0,1.11249,.49811,1.11249,1.11249s-.49808,1.11249-1.11249,1.11249h0Z"
          :fill="secondColor"
        />
        <path
          id="af-246"
          d="M461.05499,536.95276h-17.79999c-.61441,0-1.11249-.49811-1.11249-1.11249s.49808-1.11249,1.11249-1.11249h17.79999c.61441,0,1.11249,.49811,1.11249,1.11249s-.49808,1.11249-1.11249,1.11249h0Z"
          :fill="secondColor"
        />
      </g>
    </g>
    <rect
      id="ag"
      y="590.2298"
      width="851.034"
      height="1.68298"
      fill="#e6e6e6"
    />
    <g>
      <path
        id="ah-247"
        d="M664.20368,365.54672c.72338,6.98595,4.91441,12.27706,9.35929,11.81643,4.44488-.46064,7.46322-6.49686,6.74012-13.48391-.25074-2.79602-1.1674-5.4914-2.67335-7.86052l-3.43534-29.56929-13.84349,1.90731,4.85627,28.94224c-.98865,2.62728-1.33371,5.4531-1.00646,8.24111h0l.00297,.00662Z"
        fill="#ffb6b6"
      />
      <path
        id="ai-248"
        d="M684.57387,245.70264l5.73621-50.39183s-28.61287-2.45337-30.68923,10.38101c-2.07636,12.83439-6.83327,69.58499-6.83327,69.58499l7.36779,75.72041,18.3866-1.06384-2.27774-69.47518,8.30963-34.75558v.00002Z"
        fill="#2f2e41"
      />
    </g>
    <polygon
      points="131.84512 201.55704 93.186 205.07715 92.04514 164.897 134.16168 163.70116 131.84512 201.55704"
      fill="#2f2e41"
    />
    <circle cx="112.2962" cy="175.87539" r="20.28835" fill="#ffb6b6" />
    <path
      d="M128.61668,156.10679c-.14293-5.03412-6.40694-5.57921-11.28744-6.82161l-4.42563-2.29679c-4.34402-1.10583-8.95298-.32808-12.69357,2.14201l-11.80101,7.14905c-5.29882,3.49905-6.14054,9.15467-8.29528,15.12777l-3.90002,8.74365c-2.12116,5.88,.68185,13.67117,2.82608,19.5428l.89851,2.46043-1.40499,8.05242,24.59268-1.14858,2.18599-12.43093-4.19791-4.71881c-1.48948-1.67431-1.6103-4.16047-.29019-5.97132h.00001c4.74864-6.5139,3.86849-15.6425,3.86849-15.6425l6.00964,3.27817c3.94866,.53868,7.46749,.47963,10.56355-.16736,3.40509-.71158,6.29878-2.13433,8.69043-4.25524l2.14257,7.20649c.55195,8.96916-1.12407,11.34966-9.11841,20.12293l14.01901,8.80133-.51846-4.76288c-1.23335-11.33018,2.52691-20.26484,1.99979-29.48635-.36449-6.37641-4.15672-12.0575-9.86381-14.92467h-.00001Z"
      fill="#2f2e41"
    />
    <g>
      <path
        id="aj-249"
        d="M131.97482,327.18014c-1.98076-.8108-4.12682-1.13554-6.25879-.94712l-20.26667-9.49188-4.23405,9.77553,21.01404,8.57596c1.43178,1.59114,3.22374,2.8163,5.22603,3.57302,4.9841,1.96119,10.03729,.98133,11.28416-2.19137s-1.78063-7.33294-6.76473-9.29414h.00001Z"
        fill="#ffb6b6"
      />
      <path
        id="ak-250"
        d="M73.00255,225.69379h0l-10.56887,80.43907,51.54368,28.12659,8.4922-12.33017-36.67384-24.08317,8.39675-43.29871c4.90095-16.37322-4.10546-29.32693-21.18922-28.85433l-.0007,.00072Z"
        :fill="color"
      />
    </g>
  </svg>
</template>

<script>
import undraw from "@/components/icons/undraw/undraw-mixin.js";

export default {
  mixins: [undraw],
};
</script>