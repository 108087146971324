<template>
  <div>
    <OptionMenu
      :id="$appNavigation.currentOptionMenuId"
      :defaultMenu="$appNavigation.currentOptionMenu"
    />
    
    <PageHeaderTitleNavigation 
      :id="$appNavigation.currentOptionMenuId" 
      title="Interne Statistiken" 
      :subtitle="selectedGroupTitle"
      :defaultMenu="customOptionMenu" 
    />

    <div class="box__container"  v-if="selectedGroup ==='table_makler'">
      <BaseButton @click="filterBroker()">
        Makler filtern
      </BaseButton>
      <BaseButton isSecondary @click="clearBrokerFilterNumber()" :disabled="!filterBrokerNumber">
        Maklerfilter löschen
      </BaseButton>
    </div>
    
    <BaseFilter 
      v-if="selectedGroup ==='table_gesellschaft'"
      :metadata="gesellschaftFilterMetadata" 
      title="Gesellschaft"
      :showSaveButton="false" 
      :showSearchButton="false"
      @changeSubFilter="handleGesellschaftFilterChange"
      @onFilterZurucksetzen="resetGesellschaftFilter"
    />

    <div class="box__container">
      <Table
        v-if="unfilteredList.length > 0"
        :title="STATISTICS_TABLE_TITLE"
        :headers="headers"
        :rows="statisticRowsForCurrentGroup"
        rowId="label" 
      >
        <template v-slot:label="row">
          <DownloadLink
            v-if="row.dateiName && !row.subitem"
            downloadServicePath="/internestatistikendetail"
            :title="row.label"
            :filename="row.dateiName"
            :queryParams="{}"
          />
          <a v-else
            :tid="_generateTidFromString('/internestatistikendetail' + row.label)"
            @click="openSelectedStatistic(row)">
            {{ row.label }}
          </a>
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';
import INTERNESTATISTIKEN_TYPES from '@/store/interneStatistiken/types';
import BROKER_STATISTICS_TYPES from '@/store/brokerStatistics/types'
import CUSTOMER_SEARCH_TYPES from '@/store/customerSearch/types';
import Table from "@/components/table2/Table.vue";
import { SlotColumn } from "@/components/table2/table_util.js";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import PageHeaderTitleNavigation from "@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue";
import DownloadLink from "@/components/core/download/DownloadLink.vue";
import InputField from "@/components/core/forms/InputField.vue";
import ComboBox from '@/components/core/forms/ComboBox.vue';
import { TabMenu, } from '@/menu/menu-utils';
import BrokerSearch from '@/components/brokerSearch/BrokerSearch.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import BaseFilter from '@/components/core/BaseFilter.vue';

export default {
  mixins: [InteractiveHelpCommonsMixin],
  data() {
    return {
      isMounted: false,
      loading: false,
      unfilteredList: [],
      
      headers: {
        lockedLeft: [SlotColumn("label", "Interne Statistiken")],
      },
      
      selectedGroup: 'table_makler',
      selectedGesellschaft: null,
    };
  },
  components: {
    Table,
    OptionMenu,
    PageHeaderTitleNavigation,
    DownloadLink,
    ComboBox,
    TabMenu,
    InputField,
    BrokerSearch,
    BaseButton,
    BaseFilter,
  },
  mounted() {
    this.isMounted = true;
    this.loading = true;
    this.$store.dispatch(INTERNESTATISTIKEN_TYPES.ACTIONS.RETRIEVE_STATISTICGROUPS_LIST)
    .then(() => {
      this.unfilteredList = Object.values(this.statisticGroupsListData);
      //console.log("this.statisticGroupsList: " + JSON.stringify(this.statisticGroupsListData));
    })
    .finally(() => {
      this.loading = false;
    });
  },
  methods: {
    getGroupForTable(data, groupTableName) { 
      const group = data ? data.find(g => g.tableName === groupTableName) : {};
      return group  
    },
    getStatisticRowsForGroup(data, groupTableName) {
      const group = this.getGroupForTable(data, groupTableName);
      // If the group is found, map each row to a new object with 'label' and 'value' instead of 'description/statisticId'
      return group ? group.rows.map(row => ({ label: row.description, value: row.statisticId })) : [];
    },
    getGesellschaftLabel(value) {
      const gesellschaft =  this.gesellschaftenCombo.find(g => g.value === value)
      return gesellschaft.label;
    },
    clearBrokerFilterNumber() {
      this.$store.commit(INTERNESTATISTIKEN_TYPES.MUTATIONS.SET_FILTER_BROKER_NUMBER, null);
    },
    openSelectedStatistic(row) {
      const selectedStatistic = {
        label: row.label,
        statisticId: row.value,
        statisticGroup: this.selectedGroup
      }
      //console.log('clicked row, selectedStatistic:', selectedStatistic)
      this.$store.dispatch(BROKER_STATISTICS_TYPES.ACTIONS.RETRIEVE_TABLE_VIEWER, 
        {
          index: 0,
          //group: this.selectedStatistics.title, 
          //title: statistics.bezeichnung, 
          id: selectedStatistic.statisticId, 
          typ:'msc.hibernate.persistence.StatistikIntern',
          //typ: 'Makler',
          //eigenbestand:this.eigenbestand, 
          //fremdbestand:this.fremdbestand, 
          //aktiv:this.aktiv, 
          //struktur: this.struktur
          maklerNummer: this.filterBrokerNumber ? this.filterBrokerNumber : "",
          gesellschaftCombo: this.selectedGesellschaft
        });       
        this.$router.push({path: '/intern/internestatistiken/view'});
    },
    handleGesellschaftFilterChange(selectedFilters) {
      if (!this.isMounted) {
        // will be accessed to build filter menu even before mount is ready
        return;
      };
      // will also be triggered when filter is reset
      //console.log("handleGesellschaftFilterChange:" + JSON.stringify(selectedFilters));
      if (selectedFilters.secondaryKey != "Manuell") {
        this.selectedGesellschaft = selectedFilters.secondaryKey
      }
    },
    resetGesellschaftFilter() {
      console.log("resetGesellschaftFilter" );
      this.selectedGesellschaft = null;
    },
    filterBroker() {
      // navigate to InterneStatistikenBrokerSelect which will commit a selected broker to the store
      this.$router.push({path: '/intern/internestatistiken/brokerselect'});
    },
  },
  computed: {
    ...mapGetters({
       apiAddress: CORE_TYPES.GETTERS.API_ADDRESS,
       statisticGroupsListData: INTERNESTATISTIKEN_TYPES.GETTERS.STATISTICSGROUPS_LIST,
       gesellschaftenCombo: INTERNESTATISTIKEN_TYPES.GETTERS.GESELLSCHAFTENCOMBO,
       filterBrokerNumber: INTERNESTATISTIKEN_TYPES.GETTERS.FILTER_BROKER_NUMBER,
    }),
    STATISTICS_TABLE_TITLE() {
      if (this.selectedGroup == "table_makler") {
        return this.filterBrokerNumber ? "Maklernummer: " + this.filterBrokerNumber : "Kein Makler ausgewählt";
      } else if (this.selectedGroup == "table_gesellschaft") {
        return  this.selectedGesellschaft ? "Gesellschaft: " + this.getGesellschaftLabel(this.selectedGesellschaft) : "Keine Gesellschaft ausgewählt";
      } else {
        return "";
      }
    },
    customOptionMenu() {
      return this.unfilteredList.map(statisticGroup => ({
        label: statisticGroup.title,
        textBold: this.selectedGroup == statisticGroup.tableName,
        action: () => this.selectedGroup = statisticGroup.tableName,
      }));
    },
    selectedGroupTitle() {
      const currentGroup = this.getGroupForTable(this.unfilteredList, this.selectedGroup)
      return currentGroup?.title
    },
    statisticRowsForCurrentGroup() { 
      let statisticsForGroup = this.getStatisticRowsForGroup(this.unfilteredList , this.selectedGroup);
      //console.log(" statisticsForGroup: ", statisticsForGroup);
      return statisticsForGroup;
    }, 
    comboBoxGesellschaftList() {
      return this.gesellschaftenCombo;
      // return [];
    },
    gesellschaftFilterMenuItemsList() {
      if (!this.isMounted) {
        // will be accessed to build filter menu even before mount is ready
        return [];
      };
      if (this.loading) {
        return [];
      };
      let gesellschaften = this.$store.getters[INTERNESTATISTIKEN_TYPES.GETTERS.GESELLSCHAFTENCOMBO];
      return gesellschaften.map(item => ({ key: item.value, label: item.label }));
    },
    gesellschaftFilterMetadata() {
      return [
        {
          key: 'name',
          label: 'Gesellschaft',
          menuItems: this.gesellschaftFilterMenuItemsList,
          type: 'combobox'
        },
      ]
    } 
  },
  beforeRouteLeave(to, from, next) {  
      this.$addBreadcrumb({
      label: "zurück zur Statistikübersicht",
      to,
      from,
    });
    next();
  }
    
};
</script>
