var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "vertragsPersonenModal",
      attrs: {
        modalTitle: "Vertragspersondaten",
        labelButtonConfirm: "Fertig "
      },
      on: {
        onConfirmButton: function($event) {
          return _vm.onConfirmModal()
        },
        onCancelButton: function($event) {
          return _vm.onCancelModal()
        },
        onCloseButton: function($event) {
          return _vm.onCancelModal()
        }
      }
    },
    [
      _vm.form.selectedPerson || _vm.form.vertragId
        ? _c(
            "div",
            [
              _c("InputField", {
                attrs: { disabled: true, label: "Vertrag" },
                model: {
                  value: _vm.form.vertragBeschreibung,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "vertragBeschreibung", $$v)
                  },
                  expression: "form.vertragBeschreibung"
                }
              }),
              _c("ComboBox", {
                attrs: {
                  label: "Rolle",
                  firstEmpty: true,
                  values: _vm.vertragsPersonenData.availableRoles
                },
                model: {
                  value: _vm.form.rolle,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "rolle", $$v)
                  },
                  expression: "form.rolle"
                }
              }),
              _c("EntitySelector", {
                attrs: {
                  isPersonPicker: "",
                  label: "Person",
                  value: _vm.form.selectedPerson,
                  values: _vm.vertragsPersonenData.availablePersons
                },
                on: {
                  new: function($event) {
                    return _vm.newPerson()
                  },
                  change: function($event) {
                    return _vm.updateForm($event)
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }