var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-forms__container" }, [
    _c("div", { staticClass: "input-forms__label-container" }, [
      _c("div", { staticClass: "row mt-0" }, [
        _c("div", { staticClass: "col" }, [
          _vm.title
            ? _c(
                "div",
                {
                  staticClass:
                    "input-forms__label-content input-forms__label-content--bigger"
                },
                [
                  _c("ph-bank", { attrs: { size: 16 } }),
                  _vm._v(" " + _vm._s(_vm.title) + " ")
                ],
                1
              )
            : _vm._e()
        ])
      ])
    ]),
    _c(
      "div",
      { staticClass: "input-forms__input-container" },
      [
        _vm.positions.length
          ? _c("Table", {
              attrs: {
                headers: _vm.headers,
                rows: _vm.positions,
                rowId: "isin",
                hidePagination: ""
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "vorhanden",
                    fn: function(row) {
                      return [
                        row
                          ? _c("InputToggleSwitch", {
                              attrs: {
                                value: row.vorhanden,
                                disabled: !row.isKaufbar
                              },
                              on: {
                                input: function($event) {
                                  return _vm.onChangeInputField(
                                    $event,
                                    row,
                                    "vorhanden"
                                  )
                                }
                              }
                            })
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "typ",
                    fn: function(row) {
                      return [
                        row
                          ? _c("ComboBox", {
                              attrs: {
                                value: row.posTyp,
                                values: row.isKaufbar
                                  ? _vm.typeValues
                                  : _vm.typeValuesWithoutKauf
                              },
                              on: {
                                input: function($event) {
                                  return _vm.onChangeInputField(
                                    $event,
                                    row,
                                    "posTyp"
                                  )
                                }
                              }
                            })
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "betrag",
                    fn: function(row) {
                      return [
                        _c("InputField", {
                          attrs: {
                            type: "currency",
                            value: row.betrag,
                            disabled: !_vm.service && !row.vorhanden
                          },
                          on: {
                            change: function($event) {
                              return _vm.onChangeInputField(
                                $event || 0,
                                row,
                                "betrag"
                              )
                            }
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "monat",
                    fn: function(row) {
                      return [
                        _c("InputField", {
                          attrs: {
                            value: row.monat,
                            disabled: !_vm.service && !row.vorhanden
                          },
                          on: {
                            change: function($event) {
                              return _vm.onChangeInputField(
                                $event,
                                row,
                                "monat"
                              )
                            }
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "jahr",
                    fn: function(row) {
                      return [
                        _c("InputField", {
                          attrs: {
                            value: row.jahr,
                            disabled: !_vm.service && !row.vorhanden
                          },
                          on: {
                            change: function($event) {
                              return _vm.onChangeInputField($event, row, "jahr")
                            }
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "rythmus",
                    fn: function(row) {
                      return [
                        _c("ComboBox", {
                          attrs: {
                            value: row.rythmus,
                            values: _vm.dateValues,
                            disabled: !_vm.service && !row.vorhanden
                          },
                          on: {
                            change: function($event) {
                              return _vm.onChangeInputField(
                                $event,
                                row,
                                "rythmus"
                              )
                            }
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "isEinzugViaFlex",
                    fn: function(row) {
                      return [
                        _c("InputToggleSwitch", {
                          attrs: {
                            value: row.isEinzugViaFlex,
                            disabled: !_vm.service && !row.vorhanden
                          },
                          on: {
                            input: function($event) {
                              return _vm.onChangeInputField(
                                $event,
                                row,
                                "isEinzugViaFlex"
                              )
                            }
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "flex",
                    fn: function(row) {
                      return [
                        _c("InputToggleSwitch", {
                          attrs: { value: row.flex },
                          on: {
                            input: function($event) {
                              return _vm.onChangeInputField($event, row, "flex")
                            }
                          }
                        })
                      ]
                    }
                  }
                ],
                null,
                false,
                3534421558
              )
            })
          : _vm._e(),
        _vm.advices && _vm.advices.length
          ? _c(
              "div",
              { staticClass: "mt-2" },
              _vm._l(_vm.advices, function(advice, index) {
                return _c("div", { key: index, staticClass: "warning" }, [
                  _vm._v(
                    ' Wichtiger Hinweis: "' +
                      _vm._s(advice) +
                      '" steht für Neuanlagen im TL200 nicht mehr zur Verfügung. '
                  )
                ])
              }),
              0
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }