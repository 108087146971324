var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Einstellungen",
          subtitle: "ExPost manuelle Kosten",
          actions: _vm.headerActions
        },
        on: {
          "action-ADD": function($event) {
            return _vm.openAddExPostManuelleModal()
          }
        }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("div", { staticClass: "box__title" }, [
            _vm._v("ExPost manuelle Kosten")
          ]),
          _c("ExPostManuelleTable", {
            on: {
              edit: function($event) {
                return _vm.openAddExPostManuelleModal($event)
              },
              remove: function($event) {
                return _vm.removeExPostManuelle($event)
              }
            }
          })
        ],
        1
      ),
      _c("AddExPostManuelleModal", { ref: "addExPostManuelleModal" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }