import CUSTOMERDATA_TYPES from './types';
import CORE_TYPES from '../core/types';
import CUSTOMER_SEARCH_TYPES from '@/store/customerSearch/types';
import ANTRAG_TYPES from "@/store/antrag/types";
import MAILCOMPOSER_TYPES from "@/store/mailcomposer/types";
import LOADING_TYPES from '@/store/loading/types'
import LOG_TYPES from '@/store/log/types'
import { buildMessage } from "@/helpers/log-message-helper";
import axios from 'axios';
import router from '../../router';
import { 
  LAST_NAME_REQUIRED_TEXT, 
  CUSTOMER_PERSONALDATAADDRESS_TITLE_DEFAULT, 
  PERSOENLICHEDATEN_HOME 
}  from '@/views/customer/customer-utils'
import { convertNewLineToBR } from '@/helpers/commonfunctions';

const config = {
  defaultSpinner: true
};

const multipartConfig = {
  defaultSpinner: true,
  headers: {'Content-Type': 'multipart/form-data'},
};

const SUFFIX_RUECKSEITE = 'RUECKSEITE';
const SUFFIX_VORDERSEITE = 'VORDERSEITE';
const SUFFIX_MELDEBESCHEINIGUNG = 'MELDEBESCHEINIGUNG';

function preparePepData(customerDataEdited, isFirma = false) {
  if(!customerDataEdited?.pep) return {};

  const pep = {
    ...customerDataEdited.pep,
  };

  if(pep.pep_funktion) {
    pep.pep_aktiv = '1';
  }
  if(pep.pep_ehe_text) {
    pep.pep_ehe_aktiv = '1';
  }
  if(pep.vip_funktion) {
    pep.vip_aktiv = '1';
  }
  if(pep.vip_ehe_text) {
    pep.vip_ehe_aktiv = '1';
  }

  if(isFirma) {
    pep.finanzielle_verh_f1 = pep?.finanzielle_verh_f1 || pep?.finanzielle_verh_f1 === 0 ? pep.finanzielle_verh_f1 : null;
    pep.finanzielle_verh_f2 = pep?.finanzielle_verh_f2 || pep?.finanzielle_verh_f2 === 0 ? pep.finanzielle_verh_f2 : null;
    pep.finanzielle_verh_f3 = pep?.finanzielle_verh_f3 || pep?.finanzielle_verh_f3 === 0 ? pep.finanzielle_verh_f3 : null;
    pep.finanzielle_verh_f4 = pep?.finanzielle_verh_f4 || pep?.finanzielle_verh_f4 === 0 ? parseInt(pep.finanzielle_verh_f4) : null;
  } else {
    pep.finanzielle_verh_n1 = pep?.finanzielle_verh_n1 || pep?.finanzielle_verh_n1 === 0 ? pep.finanzielle_verh_n1 : null;
    pep.finanzielle_verh_n2 = pep?.finanzielle_verh_n2 || pep?.finanzielle_verh_n2 === 0 ? pep.finanzielle_verh_n2 : null;
    pep.finanzielle_verh_n3 = pep?.finanzielle_verh_n3 || pep?.finanzielle_verh_n3 === 0 ? pep.finanzielle_verh_n3 : null;
    pep.finanzielle_verh_n4 = pep?.finanzielle_verh_n4 || pep?.finanzielle_verh_n4 === 0 ? pep.finanzielle_verh_n4 : null;
  }

  return { pep };
}

function isRequiredDataInvalid(data) {
  if(data?.personId === 'neue_person') {
    return !data?.personalDataAddress?.lastName?.trim();
  }

  return (Object.keys(data?.personalDataAddress || {}).length > 0 && !data?.personalDataAddress?.lastName?.trim());
}

export default {
  async [CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA]({ commit, dispatch, rootState}, payload ) {
    let serviceUrl = '/../mrscustomerdata';

    if(payload?.newCustomer) {
      serviceUrl += "?newCustomer=true"
    }

    await axios.get(`${rootState.core.apiAddress}${serviceUrl}`, config).then(response => {
      if (response.status === 200 && response.data) {
        const customerData = response?.data;
        const country = customerData?.personalDataAddress?.country;
        const personId = 'person1';
        commit(CUSTOMERDATA_TYPES.MUTATIONS.GET_CUSTOMER_DATA_SUCCESS, { customerData: customerData })
        if(country) {
          dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_PERSON_STATES, {
            country,
            personId,
          });
        }

        if (payload?.newCustomer && response?.data?.pepInfo?.value) {
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response?.data?.pepInfo?.key, 'info'));
        }        
      }
    });
  },

  async [CUSTOMERDATA_TYPES.ACTIONS.GET_SELECTED_PERSON_DATA]({ getters, commit }) {
    const params = {
      personId: getters[CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_ID],
    }

    try {
      const additionalPersonUrl = `${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsadditionalpersons`
      const response = await axios.get(additionalPersonUrl, { ...config, params })
      const person = response?.data || {};
      commit(CUSTOMERDATA_TYPES.MUTATIONS.GET_SELECTED_PERSON_DATA_SUCCESS, person);
    } catch (error) {
      //empty block
    }
  },

  async [CUSTOMERDATA_TYPES.ACTIONS.GET_SELECTED_CUSTOMER_DATA]({ getters, dispatch })  {
    const isMainPerson = getters[CUSTOMERDATA_TYPES.GETTERS.IS_MAIN_PERSON];
    if(isMainPerson) {
      await dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA);
    } else {
      await dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_SELECTED_PERSON_DATA);
    }
  },

  async [CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA_CONFIG]({ commit, rootState, getters }, payload) {
    const shouldLoad = !getters[CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_CONFIG].isLoaded || (payload && payload.forceReload)

    if (shouldLoad) {
      await axios.get(`${rootState.core.apiAddress}/../mrscustomerdataconfig`, config).then(response => {
        if (response.status === 200 && response.data) {
          commit(CUSTOMERDATA_TYPES.MUTATIONS.GET_CUSTOMER_DATA_CONFIG_SUCCESS, { customerDataConfig: response.data })
        }
      });
    }
  },

  async [CUSTOMERDATA_TYPES.ACTIONS.SAVE_CUSTOMER_DATA]({ commit, rootState, getters, dispatch }) {
    let edited = getters[CUSTOMERDATA_TYPES.GETTERS.GET_CUSTOMER_DATA_EDITED]
    if (!edited || !edited.personId || isRequiredDataInvalid(edited)) {
      return
    }
    const extraData = getters[CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_EXTRA_DATA] 
    if (extraData && Object.keys(extraData).length) {
      Object.keys(extraData).forEach(key => {
        if (key in edited) {
          edited[key] = Object.assign(edited[key], extraData[key]);
        }
      })
      // edited = Object.assign(edited, extraData);
    }

    edited = {
      ...edited,
      ...preparePepData(edited, getters[CUSTOMERDATA_TYPES.GETTERS.IS_FIRMA]),
    };

    const loadingId = 'mrscustomerdata-save';
    dispatch(LOADING_TYPES.ACTIONS.START_LOADING, loadingId);
    const response = await axios.post(`${rootState.core.apiAddress}/../mrscustomerdata`, edited, config).then((response) => {
      if (response.status === 200 && response.data) {
        if (edited.personId === 'person1') {
          commit(CUSTOMERDATA_TYPES.MUTATIONS.SAVE_CUSTOMER_DATA_SUCCESS, { customerData: response.data })
        } else {
          commit(CUSTOMERDATA_TYPES.MUTATIONS.SAVE_PERSON_DATA_SUCCESS, { personData: response.data })
        }
        if(edited?.personId === 'neue_person') {
          commit(CUSTOMERDATA_TYPES.MUTATIONS.UPDATE_SELECTED_CUSTOMER_DATA, response?.data?.personId)
          commit(CUSTOMERDATA_TYPES.MUTATIONS.LAST_SAVED_NEW_PERSON, response?.data?.personId)
        }
        // dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_SELECTED_CUSTOMER_DATA);
        dispatch(ANTRAG_TYPES.ACTIONS.RELOAD_ANTRAEGE_DATA);

        if (response?.data?.pepInfo?.value) {
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response?.data?.pepInfo?.key, 'info'));
        }
      }

      return response || {};
    })
    .finally(() => {
      commit(CUSTOMERDATA_TYPES.MUTATIONS.RESET_CUSTOMER_DATA_EDITED);
      dispatch(LOADING_TYPES.ACTIONS.STOP_LOADING, loadingId);
    });

    return response || {};
  },

  [CUSTOMERDATA_TYPES.ACTIONS.DELETE_CUSTOMER_DATA]({ commit, getters }, payload) {
    const apiAddressLegacy = getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]

    axios.delete(`${apiAddressLegacy}/mrscustomerdata`, {...config, data: { ...payload }}).then(response => {
      if (response.status === 200 && response.data) {
        if (payload.personId === 'person1') {
          commit(CUSTOMERDATA_TYPES.MUTATIONS.DELETE_CUSTOMER_DATA_SUCCESS, { customerData: response.data })
        } else {
          commit(CUSTOMERDATA_TYPES.MUTATIONS.DELETE_PERSON_DATA_SUCCESS, { personData: response.data })
        }
      }
    });
  },

  [CUSTOMERDATA_TYPES.ACTIONS.DELETE_PERSON]({ rootState, dispatch }, payload) {
    if (!payload.personId) {
      return;
    }

    axios.delete(`${rootState.core.apiAddress}/customerData/deletePerson?personId=${payload.personId}`, config).then(response => {
      if (response.status === 200) {
        dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA)
        dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA_ADDITIONAL_PERSONS)
      }
    });
  },

  [CUSTOMERDATA_TYPES.ACTIONS.DELETE_BANKVERBINDUNG]({ rootState, dispatch }, payload) {
    if (!payload.bankverbindungId) {
      return;
    }

    axios.delete(`${rootState.core.apiAddress}/customerData/deleteBankverbindung?bankverbindungId=${payload.bankverbindungId}`, config).then(response => {
      if (response.status === 200) {
        dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA)
        dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA_ADDITIONAL_PERSONS)
      }
    });
  },

  async [CUSTOMERDATA_TYPES.ACTIONS.FIND_IBAN_DETAILS]({ getters }, { iban }) {
    if (!iban) return;

    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/customerData/ibanDetails?iban=${iban}`, config);
    return response?.data || {};
  },

  [CUSTOMERDATA_TYPES.ACTIONS.CHECK_FORMAT_COMMUNICATION]({ getters }, { type, value }) {
    const apiAddressLegacy = getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]

    if (type && value) {
      return new Promise((resolve, reject) => {
        axios.get(`${apiAddressLegacy}/mrscheckformat?type=${encodeURIComponent(type)}&value=${encodeURIComponent(value)}`, config).then(response => {
          if (response.data) {
            if (response.data.text) {
              reject({ text: response.data.text });
            } else {
              resolve({ valid: true });
            }
          }
        });
      })
    }
  },

  async [CUSTOMERDATA_TYPES.ACTIONS.FORMAT_VALUE]({ getters }, { type, value }) {
    if(!type || !value) return '';

    const apiAddress = getters[CORE_TYPES.GETTERS.API_ADDRESS];
    return await axios.get(`${apiAddress}/customerData/formatValue?type=${encodeURIComponent(type)}&value=${encodeURIComponent(value)}`, config)
      .then(response => response?.data || '');
  },

  async [CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA_ADDITIONAL_PERSONS]({ commit, getters }, params) {

    try {
      const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsadditionalpersons`, {...config, params});
      if (response?.data) {
        commit(CUSTOMERDATA_TYPES.MUTATIONS.GET_CUSTOMER_DATA_ADDITIONAL_PERSONS_SUCCESS, response.data?.persons)
      }
    } catch (error) {
      // empty block
    }
  },

  [CUSTOMERDATA_TYPES.ACTIONS.GOTO_CUSTOMER_DATA]({ commit, getters }, payload) {
    if (payload && payload.validators) {
      commit(CUSTOMERDATA_TYPES.MUTATIONS.SET_ADDITIONAL_VALIDATORS, payload.validators)
    }
    router.push({
      path: `/persoenlichedaten/person-data/${getters[CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_ID]}`, 
      query: { 
        relation: payload?.relation, 
      },
    });
  },

  [CUSTOMERDATA_TYPES.ACTIONS.GOTO_BANKVERBINDUNG]({ commit }, payload) {
    commit(CUSTOMERDATA_TYPES.MUTATIONS.UPDATE_SELECTED_CUSTOMER_DATA, 'person1');

    router.push({
      path: '/persoenlichedaten/customer-data/steps/bankverbindung',
      query: {
        focusMode: true,
        editBankAccountId: payload?.id,
      },
    })
  },

  async [CUSTOMERDATA_TYPES.ACTIONS.UPLOAD_LEGITIMATION_IMAGES]({ dispatch, getters, state, commit }, payload) {
      const apiAddressLegacy = getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]

      const formData = new FormData();
      if (payload.vorderseite) {
        formData.append(SUFFIX_VORDERSEITE, payload.vorderseite);
      }
      if (payload.rueckseite) {
        formData.append(SUFFIX_RUECKSEITE, payload.rueckseite);
      }
      if (payload.meldebescheinigung) {
        formData.append(SUFFIX_MELDEBESCHEINIGUNG, payload.meldebescheinigung);
      }
      formData.append('personId', payload.personId);
      formData.append('customerId', payload.customerId || '');

      const test = state.persons?.find(person => person.personId === payload.personId);
      if (payload.personId === 'person1' && !state.customerData?.legitimation?.legitimType) {
        await dispatch(CUSTOMERDATA_TYPES.ACTIONS.SAVE_CUSTOMER_DATA);
      } else if (payload.personId && payload.personId !== 'person1' && !state.persons?.find(person => person.personId === payload.personId)?.legitimation?.legitimType) {
        await dispatch(CUSTOMERDATA_TYPES.ACTIONS.SAVE_CUSTOMER_DATA);
      }
      await axios.post(`${apiAddressLegacy}/mrslegitimationbilder`, formData, multipartConfig).then(response => {
        commit(CUSTOMERDATA_TYPES.MUTATIONS.UPLOAD_LEGITIMATION_IMAGES_SUCCESS, { data: response.data, personId: payload.personId});
      })
      .catch((error) => {
      });
  },
  async [CUSTOMERDATA_TYPES.ACTIONS.UPLOAD_FIRMA_LEGITIMATION_IMAGES]({ dispatch, getters, state, commit }, payload) {
    const apiAddressLegacy = getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]
    const formData = new FormData();
    for(var key in payload) {
      formData.append(key, payload[key])
    }

    if (payload.personId === 'person1' && !state.customerData?.legitimation?.legitimType) {
      await dispatch(CUSTOMERDATA_TYPES.ACTIONS.SAVE_CUSTOMER_DATA);
    } else if (payload.personId && payload.personId !== 'person1' && !state.persons?.find(person => person.personId === payload.personId)?.legitimation?.legitimType) {
      await dispatch(CUSTOMERDATA_TYPES.ACTIONS.SAVE_CUSTOMER_DATA);
    }
    await axios.post(`${apiAddressLegacy}/mrslegitimationbilder`, formData, multipartConfig).then(response => {
      commit(CUSTOMERDATA_TYPES.MUTATIONS.UPLOAD_LEGITIMATION_IMAGES_SUCCESS, { data: response.data, personId: payload.personId});
    })
},

  [CUSTOMERDATA_TYPES.ACTIONS.DELETE_LEGITIMATION_IMAGES]({ dispatch, getters }, { personId }) {
    const apiAddressLegacy = getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]
    
    return axios.delete(`${apiAddressLegacy}/mrslegitimationbilder?personId=${personId}`, config).then(response => {
      dispatch(CUSTOMERDATA_TYPES.ACTIONS.SAVE_CUSTOMER_DATA);
    });
  },  
  [CUSTOMERDATA_TYPES.ACTIONS.DELETE_LEGITIMATION_IMAGE_FIRMA]({ dispatch, getters }, { personId, legiTypFirma }) {
    const apiAddressLegacy = getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]
    
    return axios.delete(`${apiAddressLegacy}/mrslegitimationbilder`, {...config, params: {personId, legiTypFirma}}).then(response => {
      dispatch(CUSTOMERDATA_TYPES.ACTIONS.SAVE_CUSTOMER_DATA);
    });
  },

  [CUSTOMERDATA_TYPES.ACTIONS.TOGGLE_E_SIGNATUR_LEGITIMATION]({ rootState, dispatch }, {fileId}) {
    axios.post(`${rootState.core.apiAddress}/customerData/toggle_e_signatur_legitimation?fileId=${fileId}`, config).then(response => 
      dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.message, response.data.type)));
  },

  [CUSTOMERDATA_TYPES.ACTIONS.SAVE_NEW_CUSTOMER_DATA]({ rootState, getters, commit, dispatch }, customerEdited) {
    return new Promise((resolve, reject) => {
      if (!customerEdited)
        customerEdited = getters[CUSTOMERDATA_TYPES.GETTERS.GET_CUSTOMER_DATA_EDITED];

      if(!customerEdited?.personalDataAddress?.lastName?.trim()) {
        reject(LAST_NAME_REQUIRED_TEXT);
        return ;
      }

      if(!customerEdited.personalDataAddress.title) {
        customerEdited.personalDataAddress.title = CUSTOMER_PERSONALDATAADDRESS_TITLE_DEFAULT;
      }

      axios.post(`${rootState.core.apiAddress}/customerData/saveNewCustomer`, customerEdited, config).then(response => {
        if (response.status === 200) {
          commit(CUSTOMERDATA_TYPES.MUTATIONS.RESET_CUSTOMER_DATA_EDITED);

          const customer = response.data;

          commit(CUSTOMERDATA_TYPES.MUTATIONS.SET_SAVED_CUSTOMER_ID, customer);
          resolve(customer);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
    });
  },

  [CUSTOMERDATA_TYPES.ACTIONS.COPY_CUSTOMER_DATA]({ rootState, getters, commit, dispatch }) {
    return new Promise((resolve, reject) => {
      axios.post(`${rootState.core.apiAddress}/customerData/copyCustomer`, {}, config).then(response => {
        if (response.status === 200) {
          const customer = {
            customerID: response.data,
          };

          commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.OPEN_CUSTOMER, { data: customer });
          dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { 
            customerId: customer.customerID,
            path: PERSOENLICHEDATEN_HOME
          });

          resolve(customer);
        } else {
          reject();
        }
      })
      .catch(() => reject());
    });
  },

  [CUSTOMERDATA_TYPES.ACTIONS.COPY_CUSTOMER_DATA_AS_GROUP_CUSTOMER]({ rootState, getters, commit, dispatch }) {
    return new Promise((resolve, reject) => {
      axios.post(`${rootState.core.apiAddress}/customerData/copyAsGroupCustomer`, {}, config).then(response => {
        if (response.status === 200) {
          const customer = {
            customerID: response.data,
          };

          commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.OPEN_CUSTOMER, { data: customer });
          dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { 
            customerId: customer.customerID,
            path: PERSOENLICHEDATEN_HOME
          });

          resolve(customer);
        } else {
          reject();
        }
      })
      .catch(() => reject());
    });
  },

  [CUSTOMERDATA_TYPES.ACTIONS.RESET_PIN]({ commit, getters }) {
    return new Promise((resolve, reject) => {
      axios.put(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/customerData/resetPin`, config).then((response) => {
        if (response && response.data) {
          const pinData = response.data;
          commit(CUSTOMERDATA_TYPES.MUTATIONS.SET_PIN_DATA_SUCCESS, { pinData });
          resolve(pinData);
        } else {
          reject();
        }
      }, (error) => {
        reject(error);
      });
    });
  },

  [CUSTOMERDATA_TYPES.ACTIONS.SAVE_PIN_DATA]({ getters, commit }, payload) {
    return new Promise((resolve, reject) => {
      const pinData = payload.pinData;
      axios.put(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/customerData/pin`, pinData, config).then((response) => {
        if (response && response.data) {
          commit(CUSTOMERDATA_TYPES.MUTATIONS.SET_PIN_DATA_SUCCESS, { pinData: response.data });
          resolve();
        } else {
          reject();
        }
      }, (error) => {
        reject(error);
      });
    });
  },

  [CUSTOMERDATA_TYPES.ACTIONS.GET_PIN_DATA]({ commit, getters }) {
    return new Promise((resolve, reject) => {
      axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/customerData/pin`, config).then((response) => {
        if (response && response.data) {
          commit(CUSTOMERDATA_TYPES.MUTATIONS.SET_PIN_DATA_SUCCESS, { pinData: response.data });
          resolve();
        } else {
          reject();
        }
      }, (error) => {
        reject(error)
      });
    });
  },

  [CUSTOMERDATA_TYPES.ACTIONS.RESET_PIN_EMAIL]({ getters }, { sendEmail }) {
    return new Promise((resolve, reject) => {
      axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/customerData/resetPinEmail?sendEmail=${sendEmail}`, {}, config).then((response) => {
        if (response && response.data) {
          resolve(response.data);
        } else {
          reject();
        }
      }, (error) => {
        reject(error);
      });
    });
  },

  [CUSTOMERDATA_TYPES.ACTIONS.CUSTOMER_CALC_TAX]({ getters }) {
    return new Promise((resolve, reject) => {
      axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/customerData/calcTax`, config).then((response) => {
        resolve(response?.data || []);
      }, (error) => {
        reject(error)
      });
    });
  },

  [CUSTOMERDATA_TYPES.ACTIONS.GET_PERSON_STATES]({ commit, getters }, payload) {
    return new Promise((resolve, reject) => {
      const country = payload?.country;
      const personId = payload?.personId;
      if(!country || !personId) {
        reject('CUSTOMERDATA_TYPES.ACTIONS.GET_PERSON_STATES -> "country" and "personId" must not be empty');
        return ;
      }

      axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/customerData/states?land=${country}`, config).then((response) => {
        const states = response?.data || [];
        commit(CUSTOMERDATA_TYPES.MUTATIONS.GET_PERSON_STATES_SUCCESS, {
          personId,
          states,
        });
        resolve(states);
      }, (error) => {
        reject(error)
      });
    });
  },

  [CUSTOMERDATA_TYPES.ACTIONS.RECOGNIZE_UPLOADED_FILE]({ getters }, { tempFileId, front }) {
    return new Promise((resolve, reject) => {
      axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/idcard/recognizeUploadedFile?tempFileId=${tempFileId}&front=${front}`, config)
        .then((response) => {
          resolve(response?.data || {});
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  [CUSTOMERDATA_TYPES.ACTIONS.SAVE_USER_PICTURE]({ getters, dispatch }, { file }) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', file);
      axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsuserpicture`, formData, multipartConfig)
        .then((response) => {
          dispatch(CORE_TYPES.ACTIONS.GET_USER_PICTURE);
          resolve();
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },

  async [CUSTOMERDATA_TYPES.ACTIONS.DELETE_USER_PICTURE]({ getters, dispatch }) {
    await axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsuserpicture`, config)
      .then(_ => {
        dispatch(CORE_TYPES.ACTIONS.GET_USER_PICTURE);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async [CUSTOMERDATA_TYPES.ACTIONS.SAVE_CUSTOMER_RELATIONSHIP]({ getters }, { personId, relationship }) {
    if(!personId) {
      return ;
    }

    const payload = {
      personId,
      relationship,
    };
    await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrscustomerdata`, payload, config);
  },

  async [CUSTOMERDATA_TYPES.ACTIONS.COPY_ADDRESS]({ getters, commit, dispatch }) {
    let mainPersonData = getters[CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA];
    if(!mainPersonData?.personId) {
      await dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA);
      mainPersonData = getters[CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA];
    }
    const payload = {
      street: mainPersonData?.personalDataAddress?.street,
      zip: mainPersonData?.personalDataAddress?.zip,
      city: mainPersonData?.personalDataAddress?.city,
      country: mainPersonData?.personalDataAddress?.country,
      state: mainPersonData?.personalDataAddress?.state,
    };
    commit(CUSTOMERDATA_TYPES.MUTATIONS.COPY_ADDRESS_SUCCESS, payload);
  },

  async [CUSTOMERDATA_TYPES.ACTIONS.CONVERT_PERSON_TO_CUSTOMER]({ getters, commit, dispatch }, { personId }) {
    await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/customerData/convertPersonToCustomer?personId=${personId}`, config)
      .then((response) => {
        const { customerId, message } = response.data;
        const customer = {
          customerID: customerId,
        };

        if (message) {
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(convertNewLineToBR(message), 'info'));
        }

        commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.OPEN_CUSTOMER, { data: customer });
        dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { 
          customerId: customer.customerID,
          path: PERSOENLICHEDATEN_HOME,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async [CUSTOMERDATA_TYPES.ACTIONS.FIND_PEOPLE]({ getters, commit }, payload) {
    await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/customerData/findPeople`, payload, config)
      .then((response) => {
        const peopleFound = response?.data || [];
        commit(CUSTOMERDATA_TYPES.MUTATIONS.FIND_PEOPLE_SUCCESS, peopleFound);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async [CUSTOMERDATA_TYPES.ACTIONS.ADD_PEOPLE_RELATIONSHIP]({ getters }, { personRelationships }) {
    if(!personRelationships?.length) return ;

    await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/customerData/addPeopleRelationship`, personRelationships, config);
  },

  async [CUSTOMERDATA_TYPES.ACTIONS.FIND_PEOPLE_RELATIONSHIP]({ getters, commit }) {
    await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/customerData/findPeopleRelationship`, config)
      .then((response) => {
        const peopleRelationship = response?.data || [];
        commit(CUSTOMERDATA_TYPES.MUTATIONS.FIND_PEOPLE_RELATIONSHIP_SUCCESS, [...peopleRelationship]);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  async [CUSTOMERDATA_TYPES.ACTIONS.SAVE_PEOPLE_RELATIONSHIP]({ getters }, { peopleRelationship }) {
    if(!peopleRelationship?.length) return;

    await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/customerData/savePeopleRelationship`, peopleRelationship, config);
  },

  async [CUSTOMERDATA_TYPES.ACTIONS.SAVE_KOMMUNIKATION_DATEN_PENDING]({ getters, commit, dispatch},  params) {
    const payload = getters[CUSTOMERDATA_TYPES.GETTERS.GET_CUSTOMER_DATA_EDITED] 
    if(!payload || !params.personId) return;
    console.log(params)
    await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/customerData/savePersonCommunicationData?personId=${params.personId}&isNewContact=${params.isNewCommunication}`, payload?.contact, config)
    .then(() => commit(CUSTOMERDATA_TYPES.MUTATIONS.RESET_CUSTOMER_DATA_EDITED))
    .finally(() => dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_SELECTED_CUSTOMER_DATA));
  },

  async [CUSTOMERDATA_TYPES.ACTIONS.DOWNLOAD_FILE]({ getters }, { fileId }) {
    if(!fileId) return;
    return await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/customerData/downloadFile?fileId=${fileId}`, config)
      .then(response => response?.data || {})
      .catch(error => console.log(error));
  },

  async [CUSTOMERDATA_TYPES.ACTIONS.DELETE_FILE]({ getters, dispatch }, { personId, fileId }) {
    if(!personId || !fileId) return;
    await axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/customerData/deleteFile?personId=${personId}&fileId=${fileId}`, config);
    await dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_SELECTED_CUSTOMER_DATA);
  },

  async [CUSTOMERDATA_TYPES.ACTIONS.GET_EXPIRATION_DATE]({ getters }, { dateOfIssue, legitimType, dayOfBirth }) {
    if(!dateOfIssue) return;
    return await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/customerData/expirationDate?dateOfIssue=${dateOfIssue}&legitimType=${legitimType}&dayOfBirth=${dayOfBirth}`, config)
      .then(response => response?.data || '')
      .catch(error => console.log(error));
  },

  async [CUSTOMERDATA_TYPES.ACTIONS.FIND_COURTAGE]({ getters, commit }) {
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/customerData/courtage`, config);

    commit(CUSTOMERDATA_TYPES.MUTATIONS.FIND_COURTAGE_SUCCESS, response?.data || []);
  },

  async [CUSTOMERDATA_TYPES.ACTIONS.SAVE_COURTAGE]({ getters, dispatch }, payload) {
    if(!payload?.zubringernr || !payload?.mwst && payload?.mwst !== 0) {
      return;
    }

    await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/customerData/setCourtage`, payload, config);

    dispatch(CUSTOMERDATA_TYPES.ACTIONS.FIND_COURTAGE);
  },

  async [CUSTOMERDATA_TYPES.ACTIONS.DELETE_COURTAGE]({ getters, dispatch }, { courtageId }) {
    if(!courtageId) {
      return;
    }

    await axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/customerData/deleteCourtage/${courtageId}`, config);

    dispatch(CUSTOMERDATA_TYPES.ACTIONS.FIND_COURTAGE);
  },

  async [CUSTOMERDATA_TYPES.ACTIONS.GET_ZUBRINGER_DATA]({ getters }, { maklernr }) {
    if(!maklernr) return;

    const response = await axios
      .get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/customerData/zubringerData/${maklernr}`, { 
        ...config, 
        disableDefaultErrorMessage: true, 
      });

    return response?.data || {};
  },

  async [CUSTOMERDATA_TYPES.ACTIONS.FIND_BEMERKUNGEN]({ getters, commit }, payload) {
    const response = payload == true ?  await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/customerData/bemerkungenKunde`, config) :
    await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/customerData/bemerkungen`, config);
    commit(CUSTOMERDATA_TYPES.MUTATIONS.FIND_BEMERKUNGEN_SUCCESS, response?.data || []);
  },

  async [CUSTOMERDATA_TYPES.ACTIONS.FIND_SUB_CUSTOMERS]({ getters, commit }) {
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/customerData/subCustomers`, config);
    commit(CUSTOMERDATA_TYPES.MUTATIONS.FIND_SUB_CUSTOMERS_SUCCESS, response?.data || []);
  },

  async [CUSTOMERDATA_TYPES.ACTIONS.ADD_SUB_CUSTOMERS]({ getters, dispatch }, payload) {
    if(!payload?.length) return;

    await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/customerData/addSubCustomers`, payload, config);
    dispatch(CUSTOMERDATA_TYPES.ACTIONS.FIND_SUB_CUSTOMERS);
  },

  async [CUSTOMERDATA_TYPES.ACTIONS.DELETE_SUB_CUSTOMER]({ getters, dispatch }, { customerID }) {
    if(!customerID) return;

    await axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/customerData/deleteSubCustomer/${customerID}`, config);
    dispatch(CUSTOMERDATA_TYPES.ACTIONS.FIND_SUB_CUSTOMERS);
  },

  async [CUSTOMERDATA_TYPES.ACTIONS.GET_INTERNE_BEMERKUNG]({ getters, commit }) {
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/customerData/interneBemerkung`, config);
    commit(CUSTOMERDATA_TYPES.MUTATIONS.GET_INTERNE_BEMERKUNG_SUCCESS, response?.data?.content || '');
  },

  async [CUSTOMERDATA_TYPES.ACTIONS.SAVE_INTERNE_BEMERKUNG]({ getters, commit }, payload) {
    const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/customerData/interneBemerkung`, payload, {
      ...config,
      headers: {
        'Content-Type': 'text/plain',
      },
    });
    commit(CUSTOMERDATA_TYPES.MUTATIONS.GET_INTERNE_BEMERKUNG_SUCCESS, response?.data?.content || '');
  },

  async [CUSTOMERDATA_TYPES.ACTIONS.GET_BEMERKUNG_KUNDE]({ getters, commit }) {
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/customerData/bemerkungKunde`, config);
    commit(CUSTOMERDATA_TYPES.MUTATIONS.BEMERKUNG_KUNDE, response?.data?.content || '');
  },

  async [CUSTOMERDATA_TYPES.ACTIONS.PUT_BEMERKUNG_KUNDE]({ getters, commit }, payload) {
    const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/customerData/bemerkungKunde`, payload, {
      ...config,
      headers: {
        'Content-Type': 'text/plain',
      },
    });
    commit(CUSTOMERDATA_TYPES.MUTATIONS.BEMERKUNG_KUNDE, response?.data?.content || '');
  },

  async [CUSTOMERDATA_TYPES.ACTIONS.GET_BEMERKUNG_KUNDE_SICHTBAR]({ getters, commit }) {
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/customerData/bemerkungKundeSichtbar`, config);
    commit(CUSTOMERDATA_TYPES.MUTATIONS.BEMERKUNG_KUNDE_SICHTBAR, response.data || false);
  },

  async [CUSTOMERDATA_TYPES.ACTIONS.PUT_BEMERKUNG_KUNDE_SICHTBAR]({ getters, commit }, payload) {
    const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/customerData/bemerkungKundeSichtbar`, {sichtbar: payload}, {
      ...config
    });
    commit(CUSTOMERDATA_TYPES.MUTATIONS.BEMERKUNG_KUNDE_SICHTBAR, response.data || false);
  },

  async [CUSTOMERDATA_TYPES.ACTIONS.FIND_BIRTHDAYS]({ getters, commit, }, { nextDays }) {
    if(!nextDays) {
      nextDays = 7 ;
    }
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/customerData/birthdays?nextDays=${nextDays}`, config);
    commit(CUSTOMERDATA_TYPES.MUTATIONS.FIND_BIRTHDAYS_SUCCESS, [ ...response?.data || [] ]);
  },

  async [CUSTOMERDATA_TYPES.ACTIONS.GET_BIRTHDAY_EMAIL_DATA]({ getters, dispatch, commit, }, payload) {
    if (payload?.customerID) {
      const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/customerData/getBirthdayEmailData?kundennr=${payload.customerID}`, config).then(response => {
        if (response.data?.errorStr) {
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.errorStr, 'danger'));
        } else {
          commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_PARTICIPANTS, null);
          commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG, {
              type: 'mailcomposer-single',
              action: 'clear',
          });
          commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, {
              type: 'mailcomposer-single',
              action: 'clear',
          });

          commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG, {
              sender: [{value: response.data.from, label:response.data.from}],
              receiver: [{value: response.data.to || payload.customerID, label: response.data.to}],
              subject: response.data.subject,
              getComputedText: response.data.text.replaceAll('\n', '<br>'),
          });
        }
      });
      return response;
    }
  },

  async [CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_CONTACT_PERSON_DATA]({ commit, rootState }) {
    let restEasyUrl = rootState.core.apiAddress + "/customerContactPerson/getContactData";

    await axios.get(restEasyUrl, config).then(response => {
      commit(CUSTOMERDATA_TYPES.MUTATIONS.GET_CUSTOMER_CONTACT_PERSON_DATA_SUCCESS, response?.data);
    });
  },

  [CUSTOMERDATA_TYPES.ACTIONS.LOAD_PDF_KUNDENDATEN]({ rootState }, payload) {
    return axios.post(rootState.core.apiAddress + '/customerData/getPdfKundendaten', payload, config);
  },

  [CUSTOMERDATA_TYPES.ACTIONS.SAVE_EINKOMMEN_SOZIALVERSICHERUNGEN]({ state, commit, rootState }, type = 'incomeSocialSecurity') {
    const data = state.customerDataEdited?.[type] || {};
    if (!data?.personId && state.customerDataEdited?.personId) {
      data.personId = state.customerDataEdited.personId;
    }
    axios.post(rootState.core.apiAddress + '/customerData/saveEinkommenSozialversicherungen', data, config).then(response => {
      if (response?.data) {
        commit(CUSTOMERDATA_TYPES.MUTATIONS.SAVE_EINKOMMEN_SOZIALVERSICHERUNGEN_SUCCESS, { type: type, data: response.data});
      }
    })
  },

  [CUSTOMERDATA_TYPES.ACTIONS.LOAD_VCARD_KUNDENDATEN]({ rootState }, payload) {
    return axios.post(rootState.core.apiAddress + '/customerData/getVCardKundendaten', payload, config);
  },
  
  async [CUSTOMERDATA_TYPES.ACTIONS.LOAD_FA_CHECK_EMAILS]({ getters, commit }) {
    commit(CUSTOMERDATA_TYPES.MUTATIONS.SET_FA_CHECK_EMAILS, {status: 1} );
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/customerData/strukturEMailsFA`, config);
    commit(CUSTOMERDATA_TYPES.MUTATIONS.SET_FA_CHECK_EMAILS, {status: 2, emails: response.data} );
  },

}