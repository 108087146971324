import actions from './actions';
import mutations from './mutations';
import getters from './getters';

/**
 * mutations: synchronous operation responsible for change the state
 * actions: asynchronous operation responsible for call external resources and commit mutations 
 */
export function getInitialState() {
  return {
    fieldsDefinition: {
      versicherungStatus: {},
      betrieblich: true,
      gesundheit: true,
      sachversicherungen: true,
      sonstige: true,
      vorsorge: true,
      wertpapier: true,
      sonstigeProdukte: true,
      bankkonten: true,
      verschiedene: true,
      kredite: true,
    },
    vertragsspiegel: {
      versicherungStatus: {},
      betrieblich: true,
      gesundheit: true,
      sachversicherungen: true,
      sonstige: true,
      vorsorge: true,
      wertpapier: true,
      sonstigeProdukte: true,
      bankkonten: true,
      verschiedene: true,
      kredite: true,
    }
  }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}