var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: {
        modalTitle: "Daten-Konflikt",
        size: "md",
        confirmDisabled: !_vm.selectedRows.length
      },
      on: {
        onConfirmButton: function($event) {
          return _vm.confirm()
        },
        onCloseButton: function($event) {
          return _vm.close()
        },
        onCancelButton: function($event) {
          return _vm.close()
        }
      }
    },
    [
      _c("p", { staticClass: "mb-8px" }, [
        _vm._v(
          " Es sind bereits Felder befüllt, die bei dem automatischen Auslesen zu einem Konflikt führen. Bitte wählen sie die Felder, die überschrieben werden sollen: "
        )
      ]),
      _c("Table", {
        attrs: {
          tableId: "8575cd74-cd3c-4182-b699-7f2ed45a6743",
          headers: _vm.headers,
          rows: _vm.rows,
          rowsPerPage: 10,
          rowId: "key"
        },
        scopedSlots: _vm._u([
          {
            key: "newValue",
            fn: function(ref) {
              var newValue = ref.newValue
              var invalid = ref.invalid
              return [
                _c("div", { class: { "color-danger": !!invalid } }, [
                  _vm._v(_vm._s(newValue))
                ])
              ]
            }
          }
        ]),
        model: {
          value: _vm.selectedRows,
          callback: function($$v) {
            _vm.selectedRows = $$v
          },
          expression: "selectedRows"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }