export function monthsDiff(day1, day2) {
    let d1 = day1;
    let d2 = day2;
    if (day1 < day2) {
        d1 = day2;
        d2 = day1;
    }
    let m = (d1.getFullYear() - d2.getFullYear()) * 12 + (d1.getMonth() - d2.getMonth());
    if (d1.getDate() < d2.getDate()) {
        --m;
    }
    return m;
}

export const SPECIAL_TYPE_REVENUE_PENSION_INCOME = 'Renteneinnahmen';
export const SPECIAL_TYPE_REVENUE_BEFORE_RETIREMENT_INCOME = 'Sparquote bis Renteneintritt';
export const SPECIAL_TYPE_REVENUE_PRESENT_VALUE_MANUAL = 'weitere Vermögenswerte (nicht im System)';
export const SPECIAL_TYPE_GOAL_COST_OF_LIVING_AFTER_RETIREMENT = 'Lebenshaltungskosten ab Renteneintritt';

export function filterSpecialGoals(v) {
    return !v.isIncome && ![
        SPECIAL_TYPE_GOAL_COST_OF_LIVING_AFTER_RETIREMENT,
    ].includes(v.type);
}

export function filterSpecialRevenues(v) {
    return v.isIncome && ![
        SPECIAL_TYPE_REVENUE_PENSION_INCOME,
        SPECIAL_TYPE_REVENUE_BEFORE_RETIREMENT_INCOME,
        SPECIAL_TYPE_REVENUE_PRESENT_VALUE_MANUAL,
    ].includes(v.type);
}