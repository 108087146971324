<template>
  <div>
    <BaseModal 
      modalTitle="Warte auf Antwort vom Server..."
      ref="killSessionModal"
      :esc="isKillSessionCloseable"
      :showCloseButton="isKillSessionCloseable"
      :showCancelButton="false"
      :showConfirmButton="false"
      :isModalAsFullPage="false">
      <Table
        :headers="headers"
        :rows="rows"
        rowId="requestId"
        hidePagination>
        <template #action="{ requestId }">
          <BaseButton @click="triggerKillRequest(requestId)" :disabled="killRequestRunning.get(requestId)">
            <AnimatedSpinner v-if="killRequestRunning.get(requestId)" /> Abbrechen
          </BaseButton>
        </template>
      </Table>
      <template #footer v-if="isKillSessionCloseable">
        <BaseButton @click="closeModal()" isSecondary>
          Schließen
        </BaseButton>
        <BaseButton @click="triggerKillAllRequests()" :disabled="killRequestRunning.size > 0">
          Alle abbrechen
        </BaseButton>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseModal from '@/components/core/BaseModal.vue'
import HTTP_REQ_TYPES from '@/store/http-requests/types'
import CORE_TYPES from '@/store/core/types'
import Table from "@/components/table2/Table.vue";
import {TextColumn, SlotColumn} from "@/components/table2/table_util.js";
import BaseButton from '@/components/core/BaseButton.vue'
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue'

import { 
  MESSAGE_CANCELLED_BY_USER
} from '@/store/http-requests/actions';

export default {
  props: {
    isKillSessionCloseable: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    hasCancelableRequests(current) {
      if (current && !this.scheduled) {
        this.scheduled = setTimeout(() => this.openModal(), 3000)
      } else if (!current && this.scheduled) {
        clearTimeout(this.scheduled)
        this.scheduled = null
        this.closeModal()
      }
    }
  },
  data() {
    return {
      scheduled: null,
      killRequestRunning: new Map(),
      headers: {
        lockedLeft: [
          TextColumn("requestName", "Anfrage"),
        ],
        center: [
          TextColumn("message", ""),
        ],
        lockedRight: [
          SlotColumn("action", ""),
        ],
      },
    }
  },
  computed: {
    ...mapGetters({
      cancelableRequest: HTTP_REQ_TYPES.GETTERS.HTTP_CANCELABLE_REQUEST_URL_LIST,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
    hasCancelableRequests() {
      return this.cancelableRequest?.length > 0
    },
    showCancelAllRequests() {
      return this.cancelableRequest?.length > 1
    },
    rows() {
        return this.cancelableRequest?.map( req => ({
            requestName: req.cancelableRequest.title,
            message: req.cancelableRequest.message,
            requestId: req.requestId,
        })) || [];
    },
  },
  methods: {
    openModal() {
      this.$refs.killSessionModal.open()
    },
    closeModal() {
      this.killRequestRunning = new Map()
      this.$refs.killSessionModal.close()
    },
    triggerKillRequest(requestId) {
      if (requestId && !this.killRequestRunning.get(requestId)) {
        this.killRequestRunning.set(requestId, true)
        this.$store.dispatch(HTTP_REQ_TYPES.ACTIONS.KILL_SESSION, { 
          requestId, 
          message: MESSAGE_CANCELLED_BY_USER 
        })
      }
    },
    triggerKillAllRequests() {
      for (const req of this.cancelableRequest) {
        this.triggerKillRequest(req.requestId)
      }
    }
  },
  components: {
    BaseModal,
    Table,
    BaseButton,
    AnimatedSpinner,
  }
}
</script>

<style scoped>
  
</style>