<template>
  <div class="antrag-components__container">
    <div class="box__container">

      <InputRadioBoxGroup 
        :value="form.uebtrall" 
        :validateUntouched="true"
        :values="[
          {value: true, label: 'Übertragung aller Investmentfondsanteile'},
          {value: false, label: 'Übertragung folgender Investmentfondsanteile:'}
        ]"
        @input="$emit('save', { alleDepot: $event })"/>

      <WertpapierauswahlDepotUebertragung
        :hasGesamtbestand="true"
        :positions="positionData"
        :anzahlFonds="anzahlFonds"
        @save="$emit('save', $event)"
        @fondsfinder="$emit('fondsfinder', $event)">
      </WertpapierauswahlDepotUebertragung>
      <FormDivider/>

      <InputToggleSwitch v-model="form.depotAufloesen" label="anschließende Löschung des Depots mit Verkauf der Anteilsbruchstücke"
        @input="$emit('save', { depotAufloesen: $event })"/>

      <FormLabel class="col-12" label="Der Verkaufserlös ist folgendem Konto gutzuschreiben:"/>

      <EntitySelector v-model="form.bankverbindungId" 
        :values="bankverbindungSelection" 
        label="Verwende Konto" 
        @new="editBankverbindung" @edit="editBankverbindung"
        @change="$emit('save', { bankverbindungId: $event })"/>

      <BaseButton class="col-12 mr-2" :isSecondary="true" @click="$emit('back')">Zurück</BaseButton>
    </div>
  </div>
</template>
<script>

import antragNavigationMixin from '@/mixins/antrag/antrag-navigation-mixin.js';
import CUSTOMERDATA_TYPES from '@/store/customerData/types'
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import EntitySelector from '@/components/core/forms/EntitySelector.vue';
import WertpapierauswahlDepotUebertragung from '@/components/core/forms/antraege/wertpapierAuswahl/WertpapierauswahlDepotUebertragung.vue';
import FormDivider from '@/components/core/forms/FormDivider.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import FormLabel from '@/components/core/forms/FormLabel.vue';

export default {
  mixins: [antragNavigationMixin],
  components: {
    InputToggleSwitch,
    BaseButton,
    EntitySelector,
    FormDivider,
    WertpapierauswahlDepotUebertragung,
    InputRadioBoxGroup,
    FormLabel,
  },
  props: {
    positionData: {
    },
    bankverbindungSelection: {
    },
    anzahlFonds: {
      type: Number,
      default: 4,
    },
    warnings: {
    },
  },
  data() {
    return {
      form: {},
    }
  },
  mounted() {
    this.form = Object.assign({}, this.positionData);
    if (!this.form.uebtrall) {
      this.form.uebtrall = false;
    }
  },
  methods: {
    editBankverbindung(event) {
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GOTO_BANKVERBINDUNG);
    },
  },
}
</script>
<style scoped>

</style>