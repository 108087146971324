<template>
  <div>
    <InputField
      label="Miete"
      v-model="rent"
      type="currency"
      isComponentHalfSize
      :disabled="isDisabled || isRefusedPage"
      @input="updateStore('finance', 'rent', $event || 0)" />
    <InputField
      label="Leasing"
      v-model="leasing"
      type="currency"
      isComponentHalfSize
      :disabled="isDisabled || isRefusedPage"
      @input="updateStore('finance', 'leasing', $event || 0)" /> 
    <InputField
      label="Kreditrückzahlung"
      v-model="creditPayment"
      type="currency"
      isComponentHalfSize
      :disabled="isDisabled || isRefusedPage"
      @input="updateStore('finance', 'creditPayment', $event || 0)" />
    <InputField
      label="Haushalt"
      v-model="household"
      type="currency"
      isComponentHalfSize
      :disabled="isDisabled || isRefusedPage"
      @input="updateStore('finance', 'household', $event || 0)" />
    <InputField
      label="Unterhalt"
      v-model="maintainance"
      type="currency"
      isComponentHalfSize
      :disabled="isDisabled || isRefusedPage"
      @input="updateStore('finance', 'maintainance', $event || 0)" />
    <InputField
      label="Versicherungsbeiträge"
      v-model="insurancePayment"
      type="currency"
      isComponentHalfSize
      :disabled="isDisabled || isRefusedPage"
      @input="updateStore('finance', 'insurancePayment', $event || 0)" /> 
    <InputField
      label="Sonstiges"
      v-model="otherPayment"
      type="currency"
      isComponentHalfSize
      :disabled="isDisabled || isRefusedPage"
      @input="updateStore('finance', 'otherPayment', $event || 0)" />

    <InputTextArea
      :label="labelHinweisBetragNiedrig"
      v-model="textHinweisBetragNiedrig"
      v-if="showHinweisBetragNiedrig"
      :disabled="isDisabled || isRefusedPage"
      @change="updateStore('finance', 'textHinweisBetragNiedrig', $event)" />

    <div class="sum d-flex justify-content-between">
      <div class="my-2">regelmäßige finanzielle Verpflichtungen</div>
      <div class="my-2 pr-3"><CurrencyLabel :value="summeAusgaben" /></div>
    </div>
  </div>
</template>

<script>
import InputField from "@/components/core/forms/InputField.vue";
import InputTextArea from "@/components/core/forms/InputTextArea.vue";
import anlegerprofilMixin from "@/mixins/anlegerprofil/anlegerprofil-mixin.js";
import CurrencyLabel from "@/components/core/CurrencyLabel.vue";

export default {
  mixins: [anlegerprofilMixin],
  components: {
    InputField,
    InputTextArea,
    CurrencyLabel,
  },
  data() {
    return {
      rent: "",
      leasing: "",
      creditPayment: "",
      household: "",
      maintainance: "",
      insurancePayment: "",
      otherPayment: "",

      labelHinweisBetragNiedrig: "",
      limitHinweisBetragNiedrig: "",
      textHinweisBetragNiedrig: "",
    };
  },
  computed: {
    isRefusedPage() {
      return this._isRefusedPage(this.data, "finanzen");
    },
    showHinweisBetragNiedrig() {
      if (
        this.labelHinweisBetragNiedrig &&
        this.summeAusgaben &&
        this.limitHinweisBetragNiedrig
      ) {
        return this.summeAusgaben < this.limitHinweisBetragNiedrig;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.createForm();
    this.updateWarnings();
  },
  methods: {
    createForm() {
      const data = (this.data && this.data.finance) || {};
      this.rent = data.rent || "";
      this.leasing = data.leasing || "";
      this.creditPayment = data.creditPayment || "";
      this.household = data.household || "";
      this.maintainance = data.maintainance || "";
      this.insurancePayment = data.insurancePayment || "";
      this.otherPayment = data.otherPayment || "";
      this.labelHinweisBetragNiedrig = data.labelHinweisBetragNiedrig || "";
      this.limitHinweisBetragNiedrig = data.limitHinweisBetragNiedrig || "";
      this.textHinweisBetragNiedrig = data.textHinweisBetragNiedrig || "";
    },
  },
};
</script>

<style scoped>
.sum {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  font-size: 1.25rem;
  font-weight: bold;
}
</style>