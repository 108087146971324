<template>
  <div>
    <FinanceImmobilienvermoegen :isRefusedPage="isDisabled || isRefusedPage" :finance="finance" @updateImmo="updateStoreStep33($event)"/>
    <div class="sum d-flex justify-content-between">
      <div class="my-2">Summe Immobilienvermögen</div>
      <div class="my-2 pr-3"><CurrencyLabel :value="sumImmobilien"/></div>
    </div>
  </div>
</template>

<script>

import FinanceImmobilienvermoegen from '@/components/anlegerprofil/FinanceImmobilienvermoegen.vue';
import anlegerprofilMixin from '@/mixins/anlegerprofil/anlegerprofil-mixin.js';
import CurrencyLabel from '@/components/core/CurrencyLabel.vue';

export default {
  mixins: [anlegerprofilMixin],
  components: {
    FinanceImmobilienvermoegen,
    CurrencyLabel,
  },
  computed: {
    isRefusedPage() {
      return this._isRefusedPage(this.data, 'finanzen');
    },
    finance() {
      return this.data?.finance || {}
    }
  },
  mounted() {
    this.updateWarnings();
  },
  methods: {
    updateStoreStep33(event) {
      if (event?.compId) {
        this.updateStoreCheckBoxGroup('finance', {[event.compId] : event.value})
      }
    }
  },
}
</script>

<style scoped>
  .sum {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    font-size: 1.25rem;
    font-weight: bold;
  }
</style>