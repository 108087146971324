var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.isAnlageBeispiel ? "Anlagebeispiel" : "Anlageempfehlung",
          actions: _vm.actions
        },
        on: {
          "action-NEW_INVESTMENT_ADVICE": function($event) {
            return _vm.createNew()
          }
        }
      }),
      _c("InvestmentAdviceSidetext", {
        attrs: { warnings: _vm.warnings, hinweise: _vm.hinweise }
      }),
      _c("BaseFilter", {
        attrs: {
          title: "Filter",
          filterId: "AnlageempfehlungSuche",
          configFilter: _vm.filterConfig,
          metadata: _vm.filterMetadata,
          defaultOptions: _vm.filterDefaultOptions
        },
        on: { onFilter: _vm.onFilter }
      }),
      _c(
        "div",
        {
          staticClass: "box__container",
          attrs: { tid: "15a4d7fd-737e-4c4b-a953-b9ccc0da2166" }
        },
        [
          !_vm.listLoading && _vm.rows.length
            ? _c("Table", {
                attrs: {
                  tableId: "Anlageempfehlungliste-777",
                  title: _vm.tableTitle,
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowsPerPage: 10,
                  headerActions: _vm.tableActions,
                  exportConfig: { roottext: "Anlageempfehlungsliste" }
                },
                on: {
                  "click-bezeichnung": _vm.open,
                  "action-INFO": _vm.info,
                  "action-COPY": _vm.copy,
                  "action-FREIJA": function($event) {
                    return _vm.freigeben($event, true)
                  },
                  "action-FREINEIN": function($event) {
                    return _vm.freigeben($event, false)
                  },
                  "action-DELETE": _vm.deleteInvestmentAdvice,
                  "headerAction-DELETE": _vm.deleteSelection
                },
                model: {
                  value: _vm.selectedRows,
                  callback: function($$v) {
                    _vm.selectedRows = $$v
                  },
                  expression: "selectedRows"
                }
              })
            : _vm.listLoading
            ? _c("GhostLoading", {
                attrs: { type: "table", title: _vm.tableTitle }
              })
            : _c("NoData", { attrs: { title: _vm.tableTitle } })
        ],
        1
      ),
      _c("BaseModal", {
        ref: "infoModal",
        attrs: { showDefaultButtons: false, size: "lg" },
        scopedSlots: _vm._u([
          {
            key: "modalTitle",
            fn: function() {
              return [_vm._v("Anlageempfehlung")]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _c("div", {
                  staticClass: "info-modal--content",
                  domProps: { innerHTML: _vm._s(_vm.infoHTMLText) }
                })
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "BaseModal",
        {
          ref: "copyModal",
          attrs: {
            labelButtonConfirm: !_vm.message ? "Kopieren" : "Schließen",
            showCancelButton: !_vm.message
          },
          on: { onConfirmButton: _vm.doCopy },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [_vm._v(" Anlageempfehlung kopieren ")]
              },
              proxy: true
            }
          ])
        },
        [
          !_vm.message
            ? _c(
                "form",
                [
                  _c("InputField", {
                    attrs: { label: "Bezeichnung" },
                    model: {
                      value: _vm.bezeichnung,
                      callback: function($$v) {
                        _vm.bezeichnung = $$v
                      },
                      expression: "bezeichnung"
                    }
                  }),
                  _vm.anwendungen.length
                    ? _c("InputRadioBoxGroup", {
                        attrs: { title: "Anwendung", values: _vm.anwendungen },
                        model: {
                          value: _vm.changeZweck,
                          callback: function($$v) {
                            _vm.changeZweck = $$v
                          },
                          expression: "changeZweck"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _c("div", [_vm._v(_vm._s(_vm.message))])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }