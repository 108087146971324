import BERICHTE_TYPES from './types';
import Vue from 'vue';

export default {
  [BERICHTE_TYPES.MUTATIONS.GET_RISIKOPRUEFUNG_LIST_SUCCESS](state, payload) {
    Vue.set(state, 'risikopruefungList', Object.assign([], payload || []));
  },

  [BERICHTE_TYPES.MUTATIONS.SAVE_RISIKOPRUEFUNG_BEMERKUNG_SUCCESS](state, payload) {
    if (payload?.bemFehlerId) {
      const index = state.risikopruefungList?.findIndex(elem => elem.bemFehlerId === payload.bemFehlerId);
      if (index >= 0) {
        const elem = Object.assign(state.risikopruefungList[index], payload);
        state.risikopruefungList.splice(index, 1, elem);
      }
    }
  },

  [BERICHTE_TYPES.MUTATIONS.GET_STATUS_FFB_DEPOTS_SUCCESS](state, payload) {
    Vue.set(state, 'statusFFBDepotList', Object.assign([], payload?.rows || []));
  },

  [BERICHTE_TYPES.MUTATIONS.SET_FILTERS_FFB_STATUS_DEPOT](state, payload) {
    Vue.set(state, 'filtersStatusFFBDepot', Object.assign({}, payload || {}));
  },
 
  [BERICHTE_TYPES.MUTATIONS.GET_STEUERLICHE_DATEN_SUCCESS](state, payload) {
    Vue.set(state, 'steuerlicheDaten', Object.assign([], payload?.rows || []));
  },

  [BERICHTE_TYPES.MUTATIONS.SET_FILTERS_STEUERLICHE_DATEN](state, payload) {
    Vue.set(state, 'filtersSteuerlicheDaten', Object.assign({}, payload || {}));
  },

  [BERICHTE_TYPES.MUTATIONS.GET_STEUER_VERLUST_LIST_SUCCESS](state, payload) {
    Vue.set(state, 'steuerVerlustList', Object.assign([], payload?.rows || []));
  },

  [BERICHTE_TYPES.MUTATIONS.GET_GESELLSCHAFTEN_AUSWAHL_SUCCESS](state, payload) {
    Vue.set(state, 'gesellschaftenAuswahl', Object.assign([], payload || []));
  },

  [BERICHTE_TYPES.MUTATIONS.SET_COURTAGE_INVEST_KUNDE](state, payload) {
    state.courtageInvest = payload;
  },
  [BERICHTE_TYPES.MUTATIONS.SET_COURTAGE_VERS_KUNDE](state, payload) {
    state.courtageVers = payload;
  },

  [BERICHTE_TYPES.MUTATIONS.INIT_ALTERSVORSORGE_SUCCESS](state, payload) {
    Vue.set(state.altersvorsorge, 'init', Object.assign({}, payload || {}));
  },

  [BERICHTE_TYPES.MUTATIONS.GET_ALTERSVORSORGE_SUCCESS](state, payload) {
    state.altersvorsorge = {...state.altersvorsorge, ...payload}
  },
}