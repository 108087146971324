import dayjs from 'dayjs';
import LOG_TYPES from '@/store/log/types';
import {formatNumber} from '@/helpers/number-formatter';
import { mapGetters } from "vuex";
import BRIDGE_TYPES from "@/store/bridge/types";
import { getActiveColorScheme, ColorSchemeChangedEvent } from '@/configs/color-config';
import { getColorsMixedContrastDecreasing } from '@/helpers/colors-helper';

const AXIS_LABEL_SHOW_OPTIONS = ['short', 'decimal-short', 'full']

export function currency(value, numberFixed = 0, ending = '') {
  if (isNaN(value)) {
    return value;
  }
  let val = numberFixed !== 0 ? (value/1).toFixed(numberFixed).replace('.', ',') : parseInt(value/1).toString().replace('.', ',')
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ` ${ending}€`;
}

export function percent(value, numberFixed = 0, ending = '') {
  if (isNaN(value)) {
    return value;
  }
  let val = numberFixed !== 0 ? (value/1).toFixed(numberFixed).replace('.', ',') : parseInt(value/1).toString().replace('.', ',')
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ` ${ending}%`;
}

export function trimDigits(value, maximumFractionDigits = 2) {
  if (isNaN(value) || typeof value !== 'number') {
    return value || '';
  }
  return value.toLocaleString('de-DE', {minimumFractionDigits: 0, maximumFractionDigits: maximumFractionDigits});
}

export default {
  props: {
    chartDataName: {
      type: String,
      default: '',
    },
    format: {
      type: String,
      default: 'currency',
    },
    height: {
      type: String,
      default: '200px',
    },
    numberFixed: {
      type: Number,
      default: 2,
    },
    isTooltip: {
      type: Boolean,
      default: true,
    },
    isLegend: {
      type: Boolean,
      default: false
    },
    showXaxis: {
      type: Boolean,
      default: true,
    },
    showYaxis: {
      type: Boolean,
      default: true,
    },
    customColors: {
      type: Array,
      default: null,
    },
    axisLabelFormatterShowOption: {
      type: String|Number,
      default: 'short'
    }
  },
  data() {
    return {
      themeKey: getActiveColorScheme(),
      themeColors: getColorsMixedContrastDecreasing(),
    };
  },
  computed: {
    style() {
      let height = this.height
      if (this.isMobileNativeContext) {
        const h = parseInt(this.height);
        height = `${h/1.33}px`;
      }
      return {
        width: '100%',
        height,
        ...mapGetters({
          isMobileNativeContext: BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT,
        }),
      };
    },
  },
  methods: {
    formattedValue(value) {
      if (this.format === 'currency') {
        return currency(value || 0, this.numberFixed);
      } else if (this.format === 'percent') {
        return percent(value || 0, this.numberFixed);
      }

      return value;
    },
    formattedXValue(value, xAxisType) {
      if(xAxisType === 'xAxis.time') {
        return dayjs(value).format('DD.MM.YYYY');
      }
      return value;
    },
    yValue(value) {
      return Array.isArray(value) ? value[1] : value;
    },
    tooltipFormatter(params) {
      if(Array.isArray(params)) {
        const data = params[0];
        const tooltipRow = this.chartSeries.length && this.chartSeries[0].config?.tooltipRow;
        const name = data.name || this.formattedXValue(data.axisValueLabel, data.axisType) || '';
        const html = params.filter(p => Array.isArray(p.value) || !isNaN(p.value)).map(p => {
          if (tooltipRow) {
            return tooltipRow(p, this.chartSeries);
          } else {
            const { marker, seriesName, value } = p;
            const formattedValue = this.formattedValue(this.yValue(value));
            
            return `<div><span>${marker}</span> <span>${seriesName}:</span> <b>${formattedValue}</b></div>`;
          }
        });
        return `
          <div class="tooltip-series">
            <div><b>${name}</b></div>
            <div class="tooltip-series-table">${html.join('')}</div>
          </div>
        `;
      } else {
        const { marker, seriesName, name, value } = params;
        const formattedValue = this.formattedValue(this.yValue(value));
        return `
          <div>
            <div><b>${seriesName}</b></div>
            <div class="tooltip-series-label">
              <div><span>${marker}</span>${name}:<wbr>
                <span style="white-space: nowrap;"><b>${formattedValue}</b></span>
              </div>
            </div>
          </div>`;
      }
    },
    // available showOption: 'short', 'decimal-short', 'full'
    axisLabelFormatter(showOption = 'short') {
      if (!AXIS_LABEL_SHOW_OPTIONS.includes(showOption)) {
        this.$store.dispatch(LOG_TYPES.ACTIONS.WARN, `axisLabelFormatter got invalid value as showOption: "${showOption}"`);
      }

      return (fullValue) => {
        let ending = ''
        let value = fullValue
        let numberFixed = 2;

        if (showOption !== 'full') {
          if (fullValue >= 1000000 || fullValue <= -1000000) {
            value = value / 1000000
            ending = 'M'
          } else if (fullValue >= 1000 || fullValue <= -1000) {
            value = value / 1000
            ending = 'T'
          }

          if (showOption === 'short') {
            numberFixed = 1
          } else if (typeof showOption === 'number') {
            if (this.format === 'currency') {
              ending += '€';
            } else if (this.format === 'percent') {
              ending += '%';
            }
            return trimDigits(value, showOption) + ' ' + ending;
          }
        }

        if (this.format === 'currency') {
          return trimDigits(value, numberFixed) + ' ' + ending + '€';
        } else if (this.format === 'percent') {
          return trimDigits(value, numberFixed) + ' ' + ending + '%';
        } else if (this.format === 'integer') {
          return formatNumber(value, 0) + (ending ? ' ' + ending : '');
        }
        return value;
      }
    },
    dispatchAction(payload) {
      if(this.$refs?.chart) {
        this.$refs.chart?.dispatchAction(payload);
      }
    }, 
    handleColorSchemeChanged() {
      this.$nextTick(() => requestAnimationFrame(() => {
        this.themeKey = getActiveColorScheme();
        this.themeColors = getColorsMixedContrastDecreasing();
      }));
    },
  },
  mounted() {
    window.addEventListener(ColorSchemeChangedEvent.name, this.handleColorSchemeChanged)
  },
  destroyed() {
    window.removeEventListener(ColorSchemeChangedEvent.name, this.handleColorSchemeChanged)
  },
}
