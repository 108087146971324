<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />
    <PageHeaderTitleNavigation 
      :id="$appNavigation.currentOptionMenuId" 
      title="DBM" 
      :subtitle="$appNavigation.currentMenu.label" 
    />

    <BaseFilter
      title="Avg Response Time"
      :filterId="baseFilterSaveKey"
      :metadata="searchMetadata"
      :configFilter="configFilter"
      :showSaveButton="true"
      immidiateSearch
      :defaultOptions="defaultOptions"
      @onFilter="search($event)"
      @onFilterZurucksetzen="search(null)"
    />

    <div class="box__container" v-if="chartData">
      <LineChart
        height="470px"
        format="integer"
        :isArea="false"
        :customTooltipFormatter="customTooltipFormatter"
        :chartData="chartData"
        :markPointData="markPointData"
        :gridLeft="40"
        @click="onClick"/>
    </div>
    <div class="box__container" v-if="endpoints">
      <Table
        :headers="headers"
        :rows="rows"
        :rowsPerPage="20"
      />
    </div>
  </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import axios from 'axios';
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';
import BaseButton from '@/components/core/BaseButton.vue';
import LineChart from '@/components/charts/echarts/LineChart.vue';
import dayjs from 'dayjs';
import Table from '@/components/table2/Table.vue';
import { TextColumn, NumberColumn } from '@/components/table2/table_util.js';
import BaseFilter from '@/components/core/BaseFilter.vue';
import { BASE_AXIOS_CONFIG as config } from "@/configs/http-request-interceptor";

export function getSearchMetadata() {
  return [
    {
      type: 'group',
      key: 'allgemein',
      label: 'Allgemein',
      menuItems: [
        {
          type: 'combobox',
          label: 'Zeitraum',
          key: 'period',
          emptyDenied: true,
          comboOptions: [
            { value: '15', label: 'vor 15 min' },
            { value: '60', label: 'vor 1 Stunde' },
            { value: '120', label: 'vor 2 Stunden' },
            { value: '240', label: 'vor 4 Stunden' },
            { value: '480', label: 'vor 8 Stunden'},
          ]
        },
      ],
    }
  ]
}

export default {
  props: {
  },
  data() {
    return {
      loading: false,
      chartData: null,
      endpoints: null,
      baseFilterSaveKey: 'ec06933b-0658-487a-b005-5c7999f1b0b0',
      configFilter: {
        placeholderForDefSearchInput: '',
        checkDefaultSearchInput: false,
      },
      defaultOptions: {
        period: {
          value: '15',
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      apiAddress: CORE_TYPES.GETTERS.API_ADDRESS,
    }),
    searchMetadata() {
      return getSearchMetadata()
    },
    markPointData() {
      return this.chartData?.map(point => ({
        coord: point,
        itemStyle: {
          borderWith: 1,
        },
      }))
    },
    headers() {
      return {
        center: [
          TextColumn("date", "Date"),
          TextColumn("execution_time", "Execution Time"),
          NumberColumn("execution_time_ms", "Execution Time (ms)"),
          TextColumn("user", "User"),
        ],
        lockedRight: [
          TextColumn("endpoint", "Endpoint"),
        ],
      }
    },
    rows() {
      return this.endpoints?.map(item => ({
        date: dayjs(item[0]).format("DD.MM.YYYY HH:mm:ss"),
        execution_time: item[2],
        execution_time_ms: parseInt(item[3]),
        user: item[4],
        endpoint: item[5],
      }));
    }
  },
  methods: {
    async onClick(ev) {
      if (ev.componentType === "markPoint") {
        try {
          this.loading = true;
          const params = {
            date: ev.data.coord[0]
          }

          const response = await axios.get(`${this.apiAddress}/performance_reports/endpoint_list`, { ...config, params });
          this.endpoints = response.data;
        } catch (e) {
          // empty block
        } finally {
        this.loading = false;
      }
      }
    },
    search(filter) {
      let params = filter.reduce((acc, curr) => ({...acc, [curr.key] : curr.value}), {});
      this.loadAvgTime(params);
    },
    async loadAvgTime(params) {
      try {
        this.loading = true;

        let response = await axios.get(`${this.apiAddress}/performance_reports/avg_response_time`, { ...config, params });
        this.chartData = response.data;

        this.endpoints = null;
      } catch (error) {
        // empty block
      } finally {
        this.loading = false;
      }
    },
    customTooltipFormatter(params) {
      if(Array.isArray(params)) {
        const name = dayjs(params[0].value[0]).format('DD.MM.YYYY HH:mm') || '';
        const html = params.map(p => {
          const { marker, seriesName, value } = p;
          let seriesNameTool = seriesName;
          if (this.toolTipTruncName && -1 !== seriesNameTool.indexOf(this.toolTipTruncName)) {
            seriesNameTool = seriesNameTool.substr(0, seriesNameTool.indexOf(this.toolTipTruncName));
          }
          let percentValue = value
          if (Array.isArray(value)) {
            percentValue = value[1]
          }
          if (!percentValue) {
            return ''
          }
          const nFormat = new Intl.NumberFormat(undefined, {minimumFractionDigits: 2});
          const formattedValue = `${nFormat.format(percentValue)} ms`;
          return `
            <div>
              <span>${marker}</span>
              <span style="white-space: normal;">Avg Time:</span> 
              <b>${formattedValue}</b>
            </div>`;
        });
        return `
          <div class="tooltip-series">
            <div><b>${name}</b></div>
            <div class="tooltip-series-table">${html.join('')}</div>
            <div class="font-bold">Click to see the Endpoints</div>
          </div>
        `;
      }
    },
  },
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    BaseButton,
    LineChart,
    Table,
    BaseFilter,
  },
};
</script>

