<template>
  <div>
    <InputField
      label="Höhe des derzeit regelmäßigen Nettoeinkommens (pro Monat)"
      v-model="einkommenMtlGesamt"
      type="currency"
      :disabled="isDisabled || isRefusedPage"
      isComponentHalfSize
      @input="updateStore('financeCompact', 'einkommenMtlGesamt', $event || 0)"
    />

    <InputField
      label="Aktuelle liquide Vermögenswerte – täglich verfügbare Bankeinlagen (z. B. Tagesgeld, Kontokorrent)"
      v-model="vermoegenVerfuegbar"
      type="currency"
      :disabled="isDisabled || isRefusedPage"
      isComponentHalfSize
      @input="updateStore('financeCompact', 'vermoegenVerfuegbar', $event || 0)"
    />

    <InputField
      label="Wertpapierdepots (Gesamtbetrag lt. aktueller Depotauszüge)"
      v-model="vermoegenWertpapiere"
      type="currency"
      :disabled="isDisabled || isRefusedPage"
      isComponentHalfSize
      @input="
        updateStore('financeCompact', 'vermoegenWertpapiere', $event || 0)
      "
    />

    <InputField
      label="Immobilienvermögen (ggf. geschätzt)"
      v-model="vermoegenImmo"
      type="currency"
      isComponentHalfSize
      :disabled="isDisabled || isRefusedPage"
      @input="updateStore('financeCompact', 'vermoegenImmo', $event || 0)"
    />

    <InputField
      label="gebundene Anlageprodukte / Sonstige Vermögenswerte"
      v-model="vermoegenAnlageGebunden"
      type="currency"
      isComponentHalfSize
      :disabled="isDisabled || isRefusedPage"
      @input="
        updateStore('financeCompact', 'vermoegenAnlageGebunden', $event || 0)
      "
    />

    <InputField
      label="Verbindlichkeiten fällig in den nächsten 5 Jahren"
      v-model="schuldenKurzfristig"
      type="currency"
      isComponentHalfSize
      :disabled="isDisabled || isRefusedPage"
      @input="updateStore('financeCompact', 'schuldenKurzfristig', $event || 0)"
    />

    <InputField
      label="Verbindlichkeiten fällig später als 5 Jahre"
      v-model="schuldenLangfristig"
      type="currency"
      isComponentHalfSize
      :disabled="isDisabled || isRefusedPage"
      @input="updateStore('financeCompact', 'schuldenLangfristig', $event || 0)"
    />

    <InputField
      label="Regelmäßige finanzielle Verpflichtungen (pro Monat)"
      v-model="ausgabenMtlGesamt"
      type="currency"
      :disabled="isDisabled || isRefusedPage"
      isComponentHalfSize
      @input="updateStore('financeCompact', 'ausgabenMtlGesamt', $event || 0)"
    />

    <InputTextArea
      :label="labelHinweisBetragNiedrig"
      v-model="textHinweisBetragNiedrig"
      v-if="showHinweisBetragNiedrig"
      :disabled="isDisabled || isRefusedPage"
      @change="
        updateStore('financeCompact', 'textHinweisBetragNiedrig', $event)
      "
    />

    <div class="row py-1 mx-2 font-bold">
      <div class="col-12 col-xl-8">
        <label class="col ml-0">
          Summe des monatlich für Anlagezwecke frei zur Verfügung stehenden
          Einkommens
        </label>
      </div>
      <div class="col-12 col-xl-4 text-right column-amount cell">
        <CurrencyLabel :value="summeFreiMonatlichKompakt" />
      </div>
    </div>

    <div class="row py-1 mx-2 font-bold">
      <div class="col-12 col-xl-8">
        <label class="col ml-0">
          Summe des für einmalige Anlagezwecke frei zur Verfügung stehenden
          Kapitals
        </label>
      </div>
      <div class="col-12 col-xl-4 text-right column-amount cell">
        <CurrencyLabel :value="summeKapitalKompakt" />
      </div>
    </div>
  </div>
</template>

<script>
import InputField from "@/components/core/forms/InputField.vue";
import InputTextArea from "@/components/core/forms/InputTextArea.vue";
import anlegerprofilMixin from "@/mixins/anlegerprofil/anlegerprofil-mixin.js";
import CurrencyLabel from "@/components/core/CurrencyLabel.vue";

export default {
  components: {
    InputField,
    InputTextArea,
    CurrencyLabel,
  },
  mixins: [anlegerprofilMixin],
  data() {
    return {
      einkommenMtlGesamt: "",
      vermoegenVerfuegbar: "",
      vermoegenWertpapiere: "",
      vermoegenImmo: "",
      vermoegenAnlageGebunden: "",
      schuldenKurzfristig: "",
      schuldenLangfristig: "",
      ausgabenMtlGesamt: "",

      labelHinweisBetragNiedrig: "",
      limitHinweisBetragNiedrig: "",
      textHinweisBetragNiedrig: "",
    };
  },
  watch: {
    financeCompact() {
      this.updateForm();
    },
  },
  mounted() {
    this.updateForm();
    this.updateWarnings();
  },
  computed: {
    isRefusedPage() {
      return this._isRefusedPage(this.data, "finanzen");
    },
    financeCompact() {
      return this.data?.financeCompact || {};
    },
    summeFreiMonatlichKompakt() {
      return (this.einkommenMtlGesamt || 0) - (this.ausgabenMtlGesamt || 0);
    },
    summeKapitalKompakt() {
      return (
        (this.vermoegenVerfuegbar || 0) +
        (this.vermoegenWertpapiere || 0) -
        (this.schuldenKurzfristig || 0)
      );
    },
    showHinweisBetragNiedrig() {
      if (
        this.labelHinweisBetragNiedrig &&
        this.ausgabenMtlGesamt &&
        this.limitHinweisBetragNiedrig
      ) {
        return this.ausgabenMtlGesamt < this.limitHinweisBetragNiedrig;
      } else {
        return false;
      }
    },
  },
  methods: {
    updateForm() {
      this.einkommenMtlGesamt = this.financeCompact.einkommenMtlGesamt || "";
      this.vermoegenVerfuegbar = this.financeCompact.vermoegenVerfuegbar || "";
      this.vermoegenWertpapiere =
        this.financeCompact.vermoegenWertpapiere || "";
      this.vermoegenImmo = this.financeCompact.vermoegenImmo || "";
      this.vermoegenAnlageGebunden =
        this.financeCompact.vermoegenAnlageGebunden || "";
      this.schuldenKurzfristig = this.financeCompact.schuldenKurzfristig || "";
      this.schuldenLangfristig = this.financeCompact.schuldenLangfristig || "";
      this.ausgabenMtlGesamt = this.financeCompact.ausgabenMtlGesamt || "";
      this.labelHinweisBetragNiedrig =
        this.financeCompact.labelHinweisBetragNiedrig || "";
      this.limitHinweisBetragNiedrig =
        this.financeCompact.limitHinweisBetragNiedrig || "";
      this.textHinweisBetragNiedrig =
        this.financeCompact.textHinweisBetragNiedrig || "";
    },
  },
};
</script>

<style scoped>
.sum {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  font-size: 1.25rem;
  font-weight: bold;
}
</style>