var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._v(" " + _vm._s(_vm.title(_vm.name)) + " "),
      _vm.name == "SFDRChart" ||
      _vm.name == "TaxonomieChart" ||
      _vm.name == "PaiChart"
        ? _c(
            "a",
            {
              staticClass: "btn btn-clean clickable",
              on: {
                click: function($event) {
                  return _vm.infoModal(_vm.name)
                }
              }
            },
            [_c("PhInfo", { staticClass: "ml-1", attrs: { size: 20 } })],
            1
          )
        : _vm._e(),
      _c(
        "BaseModal",
        {
          ref: "infoModalRef",
          attrs: {
            showCancelButton: false,
            labelButtonConfirm: "Ok",
            isModalAsFullPage: false
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("span", {
                    domProps: { innerHTML: _vm._s(_vm.infoModalTitle) }
                  })
                ]
              },
              proxy: true
            }
          ])
        },
        [_c("span", { domProps: { innerHTML: _vm._s(_vm.infoModalText) } })]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }