var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "modal",
    attrs: {
      modalTitle: "Menüstruktur",
      size: "sm",
      confirmDisabled: _vm.loading || _vm.isInvalid
    },
    on: {
      onConfirmButton: function($event) {
        return _vm.save()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function() {
          return [
            _c("InputField", {
              attrs: {
                label: "Beschreibung",
                autofocus: "",
                validateUntouched: ""
              },
              model: {
                value: _vm.form.label,
                callback: function($$v) {
                  _vm.$set(_vm.form, "label", $$v)
                },
                expression: "form.label"
              }
            }),
            _c("ComboBox", {
              attrs: {
                label: "Ebene",
                values: _vm.contextValues,
                sortComboboxValues: false,
                validateUntouched: "",
                disabled: !_vm.isNew
              },
              model: {
                value: _vm.form.context,
                callback: function($$v) {
                  _vm.$set(_vm.form, "context", $$v)
                },
                expression: "form.context"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }