<template>
  <div class="antrag-components__container">
    <div class="box__container">
      <FormLabel label="Bitte tragen Sie hier die Daten des Depots ein, von dem die Wertpapiere übertragen werden sollen."
        :config="{'bold': true}" />
      <FormDivider/>

      <div class="row">
        <div class="col-12">
          <FormLabel :config="{'bold': true}" label="Daten der bisher depotführenden Gesellschaft"/>
        </div>
        <InputField class="col-12 col-md-6" v-model="form.bisherManuellName" label="Name"
          @change="$emit('save', { bisherManuellName: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.bisherManuellTelefon" label="Telefon"
          @change="$emit('save', { bisherManuellTelefon: $event })" inputmode="tel"/>
        <InputField class="col-12 col-md-6" v-model="form.bisherManuellStrasse" label="Straße/Nr."
          @change="$emit('save', { bisherManuellStrasse: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.bisherManuellOrt" label="PLZ Ort"
          @change="$emit('save', { bisherManuellOrt: $event })"/>
      </div>
      <FormDivider/>
      <div class="row">
        <div class="col-12">
          <FormLabel :config="{'bold': true}" label="Daten Konto/Depotinhaber depotführenden Bank"/>
        </div>
        <InputField class="col-12 col-md-6" v-model="form.bisherDepotnr" label="Depotnummer"
          @change="$emit('save', { bisherDepotnr: $event })"/>
                  <div class="col-12"></div>
        <InputField class="col-12 col-md-6" v-model="form.bisherManuellKontobezeichnung" label="Name"
          @change="$emit('save', { bisherManuellKontobezeichnung: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.bisherManuellKontoStrasse" label="Straße/Nr"
          @change="$emit('save', { bisherManuellKontoStrasse: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.bisherManuellKontoPLZOrt" label="PLZ/Ort"
          @change="$emit('save', { bisherManuellKontoPLZOrt: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.bisherManuellKontoLand" label="Land"
          @change="$emit('save', { bisherManuellKontoLand: $event })"/>
      </div>
      <BaseButton @click="$emit('next')">Weiter</BaseButton>
    </div>
  </div>
</template>
<script>
import FormLabel from '@/components/core/forms/FormLabel.vue';
import InputField from '@/components/core/forms/InputField.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import FormDivider from '@/components/core/forms/FormDivider.vue';

export default {
  props: {
    positionData: {
    },
    warnings: {
    },
  },
  components: {
    FormLabel,
    InputField,
    BaseButton,
    FormDivider,
  },
  computed: {
  },
  data() {
    return {
      form: {}
    }
  },
  mounted() {
    this.form = Object.assign({}, this.positionData);
  },
  methods: {
  },
  beforeRouteLeave(to, from, next){

     next();
  }
}
</script>
<style scoped>

</style>