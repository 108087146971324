var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: { showDefaultButtons: true, showConfirmButton: false },
      on: { onCloseButton: _vm.close },
      scopedSlots: _vm._u([
        {
          key: "modalTitle",
          fn: function() {
            return [
              _vm._v(
                " " +
                  _vm._s(_vm.oldLoginName) +
                  " ändern (muss sich vom bisherigen LoginName unterscheiden) "
              )
            ]
          },
          proxy: true
        },
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "BaseButton",
                {
                  attrs: { disabled: _vm.isFormInvalid, isPrimary: "" },
                  on: { click: _vm.changeLoginName }
                },
                [_vm._v("Login Name ändern")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("InputField", {
        attrs: { label: "Neuer Login Name" },
        model: {
          value: _vm.loginName,
          callback: function($$v) {
            _vm.loginName = $$v
          },
          expression: "loginName"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }