var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: {
        modalTitle: "Interface Konvertierung verarbeiten",
        autoClose: false,
        confirmDisabled: !_vm.form.name,
        showConfirmButton: _vm.interfaceEditEnable
      },
      on: {
        onConfirmButton: _vm.save,
        close: function($event) {
          return _vm.close()
        }
      }
    },
    [
      _c(
        "div",
        [
          _c("InputField", {
            attrs: { disabled: !_vm.interfaceEditEnable, label: "Feldname" },
            model: {
              value: _vm.form.name,
              callback: function($$v) {
                _vm.$set(_vm.form, "name", $$v)
              },
              expression: "form.name"
            }
          }),
          _c("InputRadioBoxGroup", {
            attrs: {
              disabled: !_vm.interfaceEditEnable,
              values: _vm.artOptions
            },
            model: {
              value: _vm.form.art,
              callback: function($$v) {
                _vm.$set(_vm.form, "art", $$v)
              },
              expression: "form.art"
            }
          }),
          _vm.form.art == 0
            ? [
                _c("InputField", {
                  attrs: {
                    disabled: !_vm.interfaceEditEnable,
                    label: "Trennzeichen"
                  },
                  model: {
                    value: _vm.form.delimiter,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "delimiter", $$v)
                    },
                    expression: "form.delimiter"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    disabled: !_vm.interfaceEditEnable,
                    type: "number",
                    label: "Nummer des Felds"
                  },
                  model: {
                    value: _vm.form.occurance,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "occurance", $$v)
                    },
                    expression: "form.occurance"
                  }
                })
              ]
            : _vm.form.art == 1
            ? [
                _c("InputField", {
                  attrs: {
                    disabled: !_vm.interfaceEditEnable,
                    type: "number",
                    label: "Position"
                  },
                  model: {
                    value: _vm.form.position,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "position", $$v)
                    },
                    expression: "form.position"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    disabled: !_vm.interfaceEditEnable,
                    type: "number",
                    label: "Feldlänge"
                  },
                  model: {
                    value: _vm.form.len,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "len", $$v)
                    },
                    expression: "form.len"
                  }
                })
              ]
            : [
                _c("InputField", {
                  attrs: { disabled: !_vm.interfaceEditEnable, label: "XPath" },
                  model: {
                    value: _vm.form.selection,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "selection", $$v)
                    },
                    expression: "form.selection"
                  }
                })
              ],
          _c("InputTextArea", {
            attrs: {
              disabled: !_vm.interfaceEditEnable,
              label: "Bemerkung",
              autoGrow: ""
            },
            model: {
              value: _vm.form.comment,
              callback: function($$v) {
                _vm.$set(_vm.form, "comment", $$v)
              },
              expression: "form.comment"
            }
          }),
          _c("InputTextArea", {
            attrs: {
              disabled: !_vm.interfaceEditEnable,
              label: "Konvertierung",
              autoGrow: ""
            },
            model: {
              value: _vm.form.conversion,
              callback: function($$v) {
                _vm.$set(_vm.form, "conversion", $$v)
              },
              expression: "form.conversion"
            }
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }