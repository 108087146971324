var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("DynamicDocumentNavigation", {
        attrs: {
          document: _vm.selectedDocument,
          versionValues: _vm.versionValues,
          documentHasChanged: _vm.documentHasChanged,
          isDocumentLoading: _vm.loading
        }
      }),
      _c("StepperForm", {
        attrs: {
          stepType: "tab",
          stepperName: "ansprechpartner",
          stepperMediator: _vm.tabsStepperMediator,
          selectedStepKey: _vm.$route.params && _vm.$route.params.step,
          hasFertig: false,
          isDynamicDocument: true
        },
        on: {
          "set-step": function($event) {
            return _vm.setStepByKey($event)
          },
          addStep: function($event) {
            return _vm.addStep()
          },
          removeStep: function($event) {
            return _vm.openRemoveStepModal($event)
          },
          copyStep: function($event) {
            return _vm.copyStep($event)
          },
          orderChanged: function($event) {
            return _vm.orderChanged($event)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "contentTemplate",
            fn: function() {
              return [
                _c("div", { staticClass: "row my-0" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _vm.components
                        ? _c("DynamicDocumentComponentList", {
                            attrs: {
                              documentComponents: _vm.components,
                              document: _vm.selectedDocument
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ])
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "BaseModal",
        {
          ref: "beforeLeaveModal",
          attrs: { labelButtonCancel: "Zurück", labelButtonConfirm: "Ok" },
          on: { onConfirmButton: _vm.confirmBeforeLeaveModal },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [_vm._v(" Es wurden Änderungen getätigt ")]
              },
              proxy: true
            }
          ])
        },
        [
          _c("p", [
            _vm._v(
              "Wenn Sie die Seite verlassen gehen ungespeicherte Änderungen verloren."
            )
          ]),
          _c("p", [_vm._v("Sind Sie sicher?")])
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "deletePageModal",
          attrs: { labelButtonCancel: "Zurück", labelButtonConfirm: "Ja" },
          on: { onConfirmButton: _vm.removeStep },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [_vm._v(" Seite löschen ")]
              },
              proxy: true
            }
          ])
        },
        [_c("p", [_vm._v("Möchten Sie diese Seite löschen?")])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }