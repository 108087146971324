var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("InputTextArea", {
        attrs: { label: "Schadennotizen Vermittler", autoGrow: true },
        on: {
          change: function($event) {
            return _vm.dataChanged()
          }
        },
        model: {
          value: _vm.form.maklerNotizen,
          callback: function($$v) {
            _vm.$set(_vm.form, "maklerNotizen", $$v)
          },
          expression: "form.maklerNotizen"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }