import E_MAIL_ACCESS_TYPES from './types';

export default {
  [E_MAIL_ACCESS_TYPES.GETTERS.GET_E_MAIL_SERVER](state) {
    return state.emailServer || [];
  },
  
  [E_MAIL_ACCESS_TYPES.GETTERS.GET_MS_GRAPH_EMAIL_DATA](state) {
    return state.ms_graph_config || {};
  },

  [E_MAIL_ACCESS_TYPES.GETTERS.IGNORED_ADDRESSES](state) {
    return state.ignoredAddresses || [];
  },

  [E_MAIL_ACCESS_TYPES.GETTERS.FOLDER_STRUCTURE](state) {
    return state.folderstructure || {};
  },
}