<template>
  <div class="kontoverwaltung-account-table__container">
    <template v-if="accountGroups">
      <div v-for="(accountGroup, index) in accountGroups" :key="accountGroup.accountType + index"
        class="kontoverwaltung-account-table__inner"
        :class="{ 'box__container': useBoxContainer, }"
      >
        <div class="box__title">
          <AccountTypeIcon :accountType="accountGroup.accountType"/>
          {{accountTypeLabels[accountGroup.accountType]}}
        </div>

        <Table
            :headers="headers(accountGroup.accountType)"
            :rows="rows(accountGroup.accounts, accountGroup.accountType)"
            :selected="selected"
            @selected="updateSelected(accountGroup.accountType, $event)"
            @action="$emit('action-' + $event.key, $event.row)" 
            :mobileConfig="{}"
            hidePagination
        >
            <template v-slot:lastExternalUpdate="row">
                <span v-if="!row.account.authNeeded">
                    {{row.account.lastExternalUpdate}}
                </span>
                <Pill v-else label="Daten nicht aktualisiert" type="danger"></Pill>
            </template>
        </Table>
      </div>
    </template>
    <div v-else class="text-centered">Keine Daten</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';
import MULTIBANKING_TYPES from '@/store/multiBanking/types';
import BALANCE_SHEET_TYPES from '@/store/balance-sheet/types';

import { PhPencilLine, PhArrowRight, PhArrowsLeftRight, PhArrowsClockwise } from 'phosphor-vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, CurrencyColumn, ActionColumn, SimpleAction, SlotColumn, dateToSortable} from "@/components/table2/table_util.js";
import AccountTypeIcon from '@/components/multiBanking/AccountTypeIcon';
import Pill from '@/components/core/Pill.vue';
import ROLES from '@/router/roles';

export default {
    model: {
        prop: 'selected',
        event: 'selected',
    },
    props: {
        hasActions: {
            type: Boolean,
            default: false,
        },
        useBoxContainer: {
            type: Boolean,
            default: true,
        },
        selected: {
            type: Array,
            default: () => null,
        },
    },
  computed: {
    ...mapGetters({
      savingsPotentialByAccount: MULTIBANKING_TYPES.GETTERS.SAVINGS_POTENTIAL_BY_ACCOUNT,
      accountDetails: BALANCE_SHEET_TYPES.GETTERS.ACCOUNTS_DETAILS,
      isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      accounts: MULTIBANKING_TYPES.GETTERS.ACCOUNTS,
    }),
    accountTypeLabels() {
      return {
        DEPOT: 'Depots',
        BANKACCOUNT: 'Konten',
        CREDITCARD: 'Kreditkarten',
        OTHER: 'Weitere'
      }
    },
    filteredAccounts() {
      return [ ...this.accounts || [] ]?.sort?.((a, b) => a.accountType === 'DEPOT' ? -1 : 1)
    },
    accountGroups() {
      if (this.filteredAccounts) {
        return this.filteredAccounts.filter(a => 
          (a.totalAmount >= 0 && this.showAccounts === 'positive') ||
          (a.totalAmount < 0 && this.showAccounts === 'negative') ||
          (!this.showAccounts))
        .reduce((acc, cur) => { 
          let found = acc.find(group => group.accountType === cur.accountType)
          if (!found) {
            found = {
              accounts: [],
              accountType: cur.accountType
            }
            acc.push(found)
          }
          
          found.accounts.push(cur)
          return acc;
        }, [])
      }
      return null
    },
  },
  methods: {
    updateSelected(accountType, selected) {
        const result = [
            ...this.selected.filter(account => account.accountType != accountType),
            ...selected,
        ];
        this.$emit('selected', result);
    },
    headers(accountType) {
        const headers = {
            lockedLeft: [
                TextColumn("displayName", "Kontoname"),
                TextColumn("providerName", "Bank"),
            ],
            center: [
                TextColumn("holderName", "Kontoinhaber"),
                TextColumn("title", "Art"),
            ],
            lockedRight: [
                SlotColumn("lastExternalUpdate", "letzte Aktualisierung").makeSortable(dateToSortable),
                CurrencyColumn("totalAmount", "Kontostand").withSumFooter(),
            ],
        };
        switch (accountType) {
            case 'BANKACCOUNT':
                headers.center.push(TextColumn("iban", "IBAN"));
                headers.center.push(TextColumn("bic", "BIC"));
                headers.center.push(CurrencyColumn("savingsPotential", "Sparpotential"));
                break;
            case 'CREDITCARD':
                headers.center.push(TextColumn("creditcardName", "Herausgeber"));
                headers.center.push(TextColumn("creditcardNo", "Nummer"));
                break;
        }
        if (this.hasActions) {
            headers.lockedRight.push(ActionColumn("actions", "Aktionen"));
        }
        return headers;
    },
    rows(accounts, accountType) {
        const actions = []
        if (this.showEditAccountButton()) {
            actions.push(SimpleAction("EDIT_ACCOUNT", PhPencilLine, "Konto bearbeiten"));
        }
        if (this.canMakeTransfer(accountType)) {
            actions.push(SimpleAction("MAKE_TRANSFER", PhArrowRight, "Überweisung"));
        }
        if (this.showGoToTransactions(accountType)) {
            actions.push(SimpleAction("GO_TO_TRANSACTIONS", PhArrowsLeftRight, "zeige Umsätze"));
        }
        const authNeededActions = [
            SimpleAction("AUTHENTICATE_ACCOUNT", PhArrowsClockwise, "aktualisieren"),
            ...actions,
        ];

        if (accountType === 'DEPOT') {
            return this.accountDetails.depotList.map((depot, index) => ({
                ...depot.account,
                id: index,
                displayName: depot.name,
                totalAmount: depot.totalAmount,
                lastExternalUpdate: depot.rateDate,
                holderProvider: `${depot.account.holderName} - ${depot.account.providerName}`,
                savingsPotential: this.getSavingsPotential(depot.account),
                account: depot.account,
                actions: this.showUpdateAccountButton() && depot.account.authNeeded ? authNeededActions : actions,
            }));
        } else {
            return accounts.map((account) => ({
                ...account,
                holderProvider: `${account.holderName} - ${account.providerName}`,
                savingsPotential: this.getSavingsPotential(account),
                account,
                actions: this.showUpdateAccountButton() && account.authNeeded ? authNeededActions : actions,
            }));
        }
    },
    canMakeTransfer(accountType) {
      return accountType === 'BANKACCOUNT' && !this.isBrokerOrBypass && !this.hasRoles(ROLES.IS_BYPASS_SLASH)
    },
    showGoToTransactions(accountType) {
      return accountType !== 'DEPOT' && !this.isBrokerOrBypass
    },
    showEditAccountButton() {
      return !this.isBrokerOrBypass && !this.hasRoles(ROLES.IS_BYPASS_SLASH)
    },
    showUpdateAccountButton() {
      return !this.isBrokerOrBypass && !this.hasRoles(ROLES.IS_BYPASS_SLASH)
    },
    getSavingsPotential(account) {
      return this.savingsPotentialByAccount && this.savingsPotentialByAccount[account.externalId] && this.savingsPotentialByAccount[account.externalId].avgTotal || null
    },
  },
  components: {
    Table,
    AccountTypeIcon,
    Pill,
  },
}
</script>

<style lang="scss" scoped>
.kontoverwaltung-account-table__inner {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
