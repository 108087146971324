<template>
  <div class="home_container">
    <div class="home_header_image">
      <EditableImageOnly class="home_image" type="home_header_image" />
    </div>
    <div class="but_image">
      <div class="zweispalte">
        <div class="home_text">
          <editable-frame type="home_intro" />
        </div>
        <div class="makler_info_outer">
          <makler-info />
        </div>
      </div>
      <news-titles class="news" />
    </div>
  </div>
</template>
<script>
import MaklerInfo from "@/components/cms/parts/MaklerInfo.vue";
import EditableFrame from "@/components/cms/EditableFrame.vue";
import EditableImageOnly from "@/components/cms/EditableImageOnly.vue";
import NewsTitles from "@/components/cms/parts/NewsTitles.vue";

export default {
  components: {
    EditableFrame,
    EditableImageOnly,
    NewsTitles,
    MaklerInfo
  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>
<style  scoped>
a {
  color: var(--color-link);
  text-decoration: none;
}
.home_container {
  margin-top: -35px;
}
.home_header_image {
  overflow: hidden;
  max-height: 540px;
}


.but_image {
  max-width: 1200px;
  margin: 0 auto;
  padding-left: 1em;
  padding-right: 1em;
}

.zweispalte {
  padding-top: 2em;
  padding-bottom: 3em;
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-areas: "home_text makler_info_outer";
  gap: 1.5em;
}

.home_text {
}

.makler_info_outer {
  /* justify-self: right; */
  /* padding-left: 1.5em;
  padding-right: 1.5em; */
  /* background: var(--color-background); */
}

.broker_info {
  text-align: start;
  padding-right: 4px;
}
.home_login_link {
  cursor: pointer;
  color: var(--color-link);
}

.two_items_row {
  display: grid;
  grid-template-columns: 1fr 13fr;
  align-items: center;
}
.news {
  margin-bottom: 2em;
}

.makler_info {
  justify-self: right;
  background: var(--color-box);
  box-shadow: 0 4px 16px rgb(85 85 85 / 10%);
}

@media only screen and (max-width: 1024px) {
  .zweispalte {
    padding-top: 0px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "home_text"
      "makler_info_outer";
    gap: 1em;
  }
.home_header_image{
 overflow: hidden;
  max-height: 270px;
  }

  .makler_info_outer {
    margin-top: 1em;
  }
}
</style>