export const MUTATION_PREFIX = 'INSURANCE_MUTATIONS_';
export const ACTIONS_PREFIX = 'INSURANCE_ACTIONS_';
export const GETTERS_PREFIX = 'INSURANCE_GETTERS_';

export default {
  MUTATIONS: {
    RETRIEVE_DOCUMENT_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_DOCUMENT_SUCCESS',
    RETRIEVE_DOCUMENT_FAILURE: MUTATION_PREFIX + 'RETRIEVE_DOCUMENT_FAILURE',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
  },
  ACTIONS: {
    RETRIEVE_DOCUMENT: ACTIONS_PREFIX + 'RETRIEVE_DOCUMENT',
  },
  GETTERS: {
    DOCUMENT_DATA: GETTERS_PREFIX + 'DOCUMENT_DATA',
  }
}