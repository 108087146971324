export const MUTATION_PREFIX = 'VERTRAG_WP: ';
export const ACTIONS_PREFIX = 'VERTRAG_WP_';
export const GETTERS_PREFIX = 'VERTRAG_WP_';

export default {
	MUTATIONS: {
		GET_VERTRAG_WP_SUCCESS: MUTATION_PREFIX + 'GET_VERTRAG_WP_SUCCESS',
		GET_VERTRAG_WP_ERROR: MUTATION_PREFIX + 'GET_VERTRAG_WP_ERROR',
		
		GET_VERTRAG_WP_HINWEIS_ERROR: MUTATION_PREFIX + 'GET_VERTRAG_WP_HINWEIS_ERROR',
		GET_VERTRAG_WP_HINWEIS_SUCCESS: MUTATION_PREFIX + 'GET_VERTRAG_WP_HINWEIS_SUCCESS',

		GET_VERTRAG_WP_VALUES_SUCCESS: MUTATION_PREFIX + 'GET_VERTRAG_WP_VALUES_SUCCESS',
		GET_VERTRAG_WP_VALUES_ERROR: MUTATION_PREFIX + 'GET_VERTRAG_WP_VALUES_ERROR',

		SAVE_VERTRAG_WP_SUCCESS: MUTATION_PREFIX + 'SAVE_VERTRAG_WP_SUCCESS',
		RESET_VERTRAG_WP_ERROR_LIST: MUTATION_PREFIX + 'RESET_VERTRAG_WP_ERROR_LIST',

		RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
	},
	ACTIONS: {
		GET_VERTRAG_WP: ACTIONS_PREFIX + 'GET_VERTRAG_WP',
		GET_VERTRAG_WP_HINWEIS: ACTIONS_PREFIX + 'GET_VERTRAG_WP_HINWEIS',
		GET_VERTRAG_WP_VALUES: ACTIONS_PREFIX + 'GET_VERTRAG_WP_VALUES',

		GET_MAKLER_PREVIEW_DATA: ACTIONS_PREFIX + 'GET_MAKLER_PREVIEW_DATA',
		GET_BESTAENDE_PREVIEW_DATA: ACTIONS_PREFIX + 'GET_BESTAENDE_PREVIEW_DATA',

		SAVE_FORM: ACTIONS_PREFIX + 'SAVE_FORM',
		TO_SIGNATURE_VORLEGEN: ACTIONS_PREFIX + 'TO_SIGNATURE_VORLEGEN',
	},
	GETTERS: {
		VERTRAG_WP_ACTIVE_FIELDS: GETTERS_PREFIX + 'VERTRAG_WP_ACTIVE_FIELDS',
		VERTRAG_WP_FORM_DATA: GETTERS_PREFIX + 'VERTRAG_WP_FORM_DATA',
		VERTRAG_WP_HINWEIS: GETTERS_PREFIX + 'VERTRAG_WP_HINWEIS',
		VERTRAG_WP_VALUES: GETTERS_PREFIX + 'VERTRAG_WP_VALUES',
		VERTRAG_WP_ERRORS: GETTERS_PREFIX + 'VERTRAG_WP_ERRORS',

		GET_PDF_LINK_GENERATOR: GETTERS_PREFIX + 'GET_PDF_LINK_GENERATOR',
		GET_SIGNO_VIEWER_LINK_GENERATOR: GETTERS_PREFIX + 'GET_SIGNO_VIEWER_LINK_GENERATOR',
	}
}
