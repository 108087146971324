export const MUTATION_PREFIX = 'OTHER_PRODUCTS: ';
export const ACTIONS_PREFIX = 'OTHER_PRODUCTS_';
export const GETTERS_PREFIX = 'OTHER_PRODUCTS_';

export default {
  MUTATIONS: {
    RESET_DATA: MUTATION_PREFIX + 'RESET_DATA',

    SET_CURRENT_TYPE: MUTATION_PREFIX + 'SET_CURRENT_TYPE',

    GET_FILTER_COMBOS_SUCCESS: MUTATION_PREFIX + 'GET_FILTER_COMBOS_SUCCESS',
    FIND_OTHER_PRODUCTS_SUCCESS: MUTATION_PREFIX + 'FIND_OTHER_PRODUCTS_SUCCESS',
    GET_COMBOS_SUCCESS: MUTATION_PREFIX + 'GET_COMBOS_SUCCESS',
    GET_OTHER_PRODUCT_SUCCESS: MUTATION_PREFIX + 'GET_OTHER_PRODUCT_SUCCESS',
    APPEND_OTHER_PRODUCT_EDITED: MUTATION_PREFIX + 'APPEND_OTHER_PRODUCT_EDITED',
    RESET_OTHER_PRODUCT_EDITED: MUTATION_PREFIX + 'RESET_OTHER_PRODUCT_EDITED',
    SET_SELECTED_SPROD_ID: MUTATION_PREFIX + 'SET_SELECTED_SPROD_ID',
    RESET_OTHER_PRODUCT_STATE: MUTATION_PREFIX + 'RESET_OTHER_PRODUCT_STATE',

    FIND_UPDATES_SUCCESS: MUTATION_PREFIX + 'FIND_UPDATES_SUCCESS',

    FIND_DOCUMENTS_SUCCESS: MUTATION_PREFIX + 'FIND_DOCUMENTS_SUCCESS',
    ADD_DOCUMENT_SUCCESS: MUTATION_PREFIX + 'ADD_DOCUMENT_SUCCESS',

    FIND_APPOINTMENTS_SUCCESS: MUTATION_PREFIX + 'FIND_APPOINTMENTS_SUCCESS',

    FIND_BUCHUNGEN_SUCCESS: MUTATION_PREFIX + 'FIND_BUCHUNGEN_SUCCESS',
    SET_SELECTED_BUCHUNG: MUTATION_PREFIX + 'SET_SELECTED_BUCHUNG',
    FIND_BUCHUNG_PROV_STRUKTUREN_SUCCESS: MUTATION_PREFIX + 'FIND_BUCHUNG_PROV_STRUKTUREN_SUCCESS',
  },
  ACTIONS: {
    GET_FILTER_COMBOS: ACTIONS_PREFIX + 'GET_FILTER_COMBOS',
    FIND_OTHER_PRODUCTS: ACTIONS_PREFIX + 'FIND_OTHER_PRODUCTS',
    GET_COMBOS: ACTIONS_PREFIX + 'GET_COMBOS',
    GET_OTHER_PRODUCT: ACTIONS_PREFIX + 'GET_OTHER_PRODUCT',
    SAVE_OTHER_PRODUCT: ACTIONS_PREFIX + 'SAVE_OTHER_PRODUCT',
    DELETE_OTHER_PRODUCT: ACTIONS_PREFIX + 'DELETE_OTHER_PRODUCT',

    FIND_UPDATES: ACTIONS_PREFIX + 'FIND_UPDATES',
    SAVE_UPDATE: ACTIONS_PREFIX + 'SAVE_UPDATE',
    DELETE_UPDATE: ACTIONS_PREFIX + 'DELETE_UPDATE',

    FIND_DOCUMENTS: ACTIONS_PREFIX + 'FIND_DOCUMENTS',
    ADD_DOCUMENT: ACTIONS_PREFIX + 'ADD_DOCUMENT',
    DOWNLOAD_DOCUMENT: ACTIONS_PREFIX + 'DOWNLOAD_DOCUMENT',
    DELETE_DOCUMENT: ACTIONS_PREFIX + 'DELETE_DOCUMENT',

    FIND_APPOINTMENTS: ACTIONS_PREFIX + 'FIND_APPOINTMENTS',
    CREATE_APPOINTMENT: ACTIONS_PREFIX + 'CREATE_APPOINTMENT',

    FIND_BUCHUNGEN: ACTIONS_PREFIX + 'FIND_BUCHUNGEN',
    FIND_BUCHUNG_PROV_STRUKTUREN: ACTIONS_PREFIX + 'FIND_BUCHUNG_PROV_STRUKTUREN',
  },
  GETTERS: {
    CURRENT_TYPE: GETTERS_PREFIX + 'CURRENT_TYPE',
    CURRENT_PIN_NAME: GETTERS_PREFIX + 'CURRENT_PIN_NAME',

    FILTER_COMBOS: GETTERS_PREFIX + 'FILTER_COMBOS',
    OTHER_PRODUCTS: GETTERS_PREFIX + 'OTHER_PRODUCTS',
    OTHER_PRODUCTS_ALL: GETTERS_PREFIX + 'OTHER_PRODUCTS_ALL',
    COMBOS: GETTERS_PREFIX + 'COMBOS',
    OTHER_PRODUCT: GETTERS_PREFIX + 'OTHER_PRODUCT',
    OTHER_PRODUCT_EDITED: GETTERS_PREFIX + 'OTHER_PRODUCT_EDITED',
    SELECTED_SPROD_ID: GETTERS_PREFIX + 'SELECTED_SPROD_ID',
    IS_NEW: GETTERS_PREFIX + 'IS_NEW',
    RIGHTS: GETTERS_PREFIX + 'RIGHTS',

    UPDATES: GETTERS_PREFIX + 'UPDATES',

    DOCUMENTS: GETTERS_PREFIX + 'DOCUMENTS',

    APPOINTMENTS: GETTERS_PREFIX + 'APPOINTMENTS',
    APPOINTMENT_COMBOS: GETTERS_PREFIX + 'APPOINTMENT_COMBOS',

    BUCHUNGEN: GETTERS_PREFIX + 'BUCHUNGEN',
    SELECTED_BUCHUNG: GETTERS_PREFIX + 'SELECTED_BUCHUNG',
    BUCHUNG_PROV_STRUKTUREN: GETTERS_PREFIX + 'BUCHUNG_PROV_STRUKTUREN',
  },
}
