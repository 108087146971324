var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.accounts && _vm.accounts.length
      ? _c("div", [
          _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("LineChart", {
                attrs: {
                  height: "450px",
                  axisLabelFormatterShowOption: "decimal-short",
                  chartData: _vm.chartData,
                  showDataZoom: false,
                  isTooltip: false,
                  gridLeft: 80
                }
              }),
              _c("KontoverlaufFilter", {
                attrs: { showTransactions: "" },
                model: {
                  value: _vm.selectedFilter,
                  callback: function($$v) {
                    _vm.selectedFilter = $$v
                  },
                  expression: "selectedFilter"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("TransactionList", {
                attrs: { transactions: _vm.filteredTransactions }
              })
            ],
            1
          )
        ])
      : _c("div", { staticClass: "box__container" }, [_c("NoData")], 1)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }