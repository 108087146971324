import PERS_GESCHAEFT_TYPES from './types';
import { getInitialState } from './index';
import Vue from 'vue';

export default {
  [PERS_GESCHAEFT_TYPES.MUTATIONS.GET_FRAGEBOGEN_SUCCESS](state, payload) {
    Vue.set(state, 'fragebogen', Object.assign({}, payload || {}));
  },

  [PERS_GESCHAEFT_TYPES.MUTATIONS.GET_GESPEICHERTE_FRAGEBOGEN_LIST_SUCCESS](state, payload) {
    Vue.set(state, 'gespeicherteFragebogenList', Object.assign([], payload || []));
  },

  [PERS_GESCHAEFT_TYPES.MUTATIONS.GET_OVERVIEW_LIST_SUCCESS](state, payload) {
      Vue.set(state, 'overviewList', Object.assign({}, {[payload.pageIndex || 0] : payload?.list}));
      Vue.set(state, 'overviewListTotalRows', payload?.totalSize || 0);
      Vue.set(state, 'overviewListPageIndex', payload?.pageIndex || 0);
  },

  [PERS_GESCHAEFT_TYPES.MUTATIONS.SET_OVERVIEW_FILTERS](state, payload) {
    Vue.set(state, 'overviewFilters', Object.assign({}, payload || {}));
  },

  [PERS_GESCHAEFT_TYPES.MUTATIONS.UPDATE_OVERVIEW_LIST_PAGE_INDEX](state, index) {
    Vue.set(state, 'overviewListPageIndex', index || 0);
  },

  [PERS_GESCHAEFT_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },
}
