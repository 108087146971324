var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: "Aktivität", actions: _vm.headerActions },
        on: { "action-CANCEL": _vm.abbrechen, "action-EMAIL": _vm.email }
      }),
      _c(
        "div",
        { staticClass: "box__container d-lg-none col-12" },
        [
          _c("Stepper2", {
            ref: "refStepper2",
            attrs: {
              stepType: "step",
              visible: true,
              stepperName: "BegleitscheinData",
              selectedStepKey: _vm.selectedStepKey,
              stepperService: _vm.steps
            },
            on: {
              setStep: function($event) {
                return _vm.setStep($event)
              }
            }
          })
        ],
        1
      ),
      _c("ContentWithStepper", {
        attrs: { stepperCollapsed: _vm.stepperCollapsed },
        scopedSlots: _vm._u([
          {
            key: "stepper",
            fn: function() {
              return [
                _c(
                  "BoxContainer",
                  [
                    _c("VerticalStepper", {
                      attrs: {
                        stepType: "tab",
                        visible: true,
                        stepperName: "BegleitscheinAktivit",
                        selectedStepKey: _vm.selectedStepKey,
                        stepperService: _vm.steps,
                        showCollapse: true
                      },
                      on: {
                        setStep: function($event) {
                          return _vm.setStep($event)
                        },
                        collapsed: function($event) {
                          _vm.stepperCollapsed = $event
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                !_vm.transition
                  ? _c("Fields", {
                      staticClass: "box__container",
                      attrs: {
                        fields: _vm.fields,
                        valuesProp: _vm.values,
                        combos: _vm.combos,
                        tableData: _vm.tableData,
                        rowsCount: _vm.rowsCount,
                        validators: _vm.validators
                      },
                      on: {
                        onBtClick: _vm.onBtClick,
                        gotoEdit: _vm.gotoEdit,
                        doAction: _vm.doAction
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "beteiligte",
                            fn: function() {
                              return [
                                _c(
                                  "div",
                                  [
                                    _c("ParticipantInput", {
                                      attrs: {
                                        label: "Teilnehmer",
                                        availableUsers: _vm.beteiligteValue,
                                        isUserSelect: false,
                                        isRole: true
                                      },
                                      on: {
                                        deleteReceiveUsers: function(bet) {
                                          return _vm.doAction(
                                            "delete_beteiligter",
                                            bet
                                          )
                                        },
                                        changeProp: function(bet) {
                                          return _vm.doAction("change_row", bet)
                                        },
                                        changeRole: function(bet) {
                                          return _vm.doAction("change_row", bet)
                                        }
                                      },
                                      model: {
                                        value: _vm.beteiligteValue,
                                        callback: function($$v) {
                                          _vm.beteiligteValue = $$v
                                        },
                                        expression: "beteiligteValue"
                                      }
                                    }),
                                    _c(
                                      "BaseButton",
                                      {
                                        staticClass: "mb-2",
                                        attrs: { isSecondary: "" },
                                        on: {
                                          click: function($event) {
                                            return _vm.doAction("show_empf")
                                          }
                                        }
                                      },
                                      [_vm._v("Empfänger")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        3637911216
                      )
                    })
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass:
                      "box__container antrag-bottom-buttons-container row"
                  },
                  [
                    _vm.selectedStepKey !== "DATEN"
                      ? _c(
                          "div",
                          { staticClass: "col-12 my-1 col-sm-auto" },
                          [
                            _c(
                              "BaseButton",
                              {
                                attrs: { isBlock: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.setStep("DATEN")
                                  }
                                }
                              },
                              [_vm._v("Zurück")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.selectedStepKey !== "ANHANG"
                      ? _c(
                          "div",
                          { staticClass: "col-12 my-1 col-sm-auto" },
                          [
                            _c(
                              "BaseButton",
                              {
                                attrs: { isBlock: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.setStep("ANHANG")
                                  }
                                }
                              },
                              [_vm._v("Weiter")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "BaseModal",
        {
          ref: "modalMessage",
          attrs: {
            modalTitle: _vm.modalArg.title,
            showConfirmButton: _vm.modalArg.showConfirmButton,
            labelButtonConfirm: _vm.modalArg.labelButtonConfirm,
            labelButtonCancel: _vm.modalArg.labelButtonCancel
          },
          on: { onConfirmButton: _vm.modalArg.onConfirm }
        },
        [
          _c("div", {
            domProps: { innerHTML: _vm._s(_vm.sanitize(_vm.modalArg.body)) }
          })
        ]
      ),
      !_vm.transition && _vm.selectedStepKey === "DATEN"
        ? _c(
            "BaseModal",
            {
              ref: "modalEmpf",
              attrs: {
                modalTitle: "Empfänger",
                showConfirmButton: true,
                labelButtonConfirm: "Neue markierte hinzufügen",
                labelButtonCancel: "Abbrechen"
              },
              on: { onConfirmButton: _vm.addBeteiligter }
            },
            [
              _c(
                "div",
                _vm._l(_vm.empfValues, function(empf, k) {
                  return _c(
                    "div",
                    { key: k, staticClass: "row" },
                    [
                      _c("InputCheckBoxItem", {
                        staticClass: "col-3",
                        attrs: {
                          label:
                            empf.maklernr +
                            +(empf.unternr !== -1 ? "-" + empf.unternr : "")
                        },
                        model: {
                          value: _vm.selectEmpf[empf.personId],
                          callback: function($$v) {
                            _vm.$set(_vm.selectEmpf, empf.personId, $$v)
                          },
                          expression: "selectEmpf[empf.personId]"
                        }
                      }),
                      _c("span", { staticClass: "col-4" }, [
                        _vm._v(_vm._s(empf.name))
                      ]),
                      _c("span", { staticClass: "col-4" }, [
                        _vm._v(_vm._s(empf.email))
                      ])
                    ],
                    1
                  )
                }),
                0
              )
            ]
          )
        : _vm._e(),
      !_vm.transition && _vm.selectedStepKey === "ANHANG"
        ? _c("DocumentsUploadModal", {
            ref: "uploadForm",
            attrs: {
              files: _vm.uploadFiles,
              additionalInputs: _vm.uploadFields,
              uploadType: 3,
              multipleFileUpload: true,
              openOnMount: false,
              customUpload: _vm.anhangUpload
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }