var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "btn-clean clickable",
      attrs: {
        type: "button",
        tid: _vm._generateTidFromString(_vm.tid + _vm.actionKey)
      },
      on: { click: _vm.onClick }
    },
    [
      _c(_vm.iconComponent, { tag: "component", attrs: { size: _vm.size } }),
      _vm.expanded
        ? _c("span", { domProps: { innerHTML: _vm._s(_vm.sanitizedValue) } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }