var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "btn-clean",
      class: {
        clickable: !_vm.isDisabled && !_vm.isLoading,
        disabled: _vm.isDisabled
      },
      attrs: {
        type: "button",
        tid: _vm._generateTidFromString(_vm.tid + _vm.actionKey),
        disabled: _vm.isDisabled || _vm.isLoading,
        title: _vm.label
      },
      on: { click: _vm.onClick }
    },
    [
      _vm.isLoading
        ? _c("AnimatedSpinner", { attrs: { size: _vm.size } })
        : _c(_vm.iconComponent, {
            tag: "component",
            attrs: { size: _vm.size }
          }),
      _vm.expanded
        ? _c("span", [_vm._v(" " + _vm._s(_vm.label) + " ")])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }