export const MUTATION_PREFIX = 'INSURANCE_BUCHUNG_MUTATION_';
export const ACTIONS_PREFIX = 'INSURANCE_BUCHUNG_ACTIONS_';
export const GETTERS_PREFIX = 'INSURANCE_BUCHUNG_GETTERS_';

export default {
  MUTATIONS: {
    BUCHUNG_FIELDS_DEFINITIONS_SUCCESS: MUTATION_PREFIX + 'BUCHUNG_FIELDS_DEFINITIONS_SUCCESS',
    SAVE_BUCHUNG_SUCCESS: MUTATION_PREFIX + 'SAVE_BUCHUNG_SUCCESS',
    UPDATE_BUCHUNG_SUCCESS: MUTATION_PREFIX + 'UPDATE_BUCHUNG_SUCCESS',
    INSURANCE_BUCHUNG_VERTEILUNG_STRUKTUR_SUCCESS: MUTATION_PREFIX + 'INSURANCE_BUCHUNG_VERTEILUNG_STRUKTUR_SUCCESS',
    INSURANCE_BUCHUNG_VERTEILUNG_STRUKTUR_DELETE_SUCCESS: MUTATION_PREFIX + 'INSURANCE_BUCHUNG_VERTEILUNG_STRUKTUR_DELETE_SUCCESS',
    SELECTED_INSURANCE_BUCHUNG: MUTATION_PREFIX + 'SELECTED_INSURANCE_BUCHUNG',
    RESET_INSURANCE_BUCHUNG_VERTEILUNG_STRUKTUR: MUTATION_PREFIX + 'RESET_INSURANCE_BUCHUNG_VERTEILUNG_STRUKTUR',
  },
  ACTIONS: {
    BUCHUNG_FIELDS_DEFINITIONS: ACTIONS_PREFIX + 'BUCHUNG_FIELDS_DEFINITIONS',
    BUCHUNG_BY_ID: ACTIONS_PREFIX + 'BUCHUNG_BY_ID',
    SAVE_BUCHUNG: ACTIONS_PREFIX + 'SAVE_BUCHUNG',
    UPDATE_BUCHUNG: ACTIONS_PREFIX + 'UPDATE_BUCHUNG',
    INSURANCE_BUCHUNG_VERTEILUNG_STRUKTUR: ACTIONS_PREFIX + 'INSURANCE_BUCHUNG_VERTEILUNG_STRUKTUR',
    INSURANCE_BUCHUNG_VERTEILUNG_STRUKTUR_DELETE: ACTIONS_PREFIX + 'INSURANCE_BUCHUNG_VERTEILUNG_STRUKTUR_DELETE',
    INSURANCE_BUCHUNG_VERTEILUNG_STRUKTUR_SAVE: ACTIONS_PREFIX + 'INSURANCE_BUCHUNG_VERTEILUNG_STRUKTUR_SAVE',
    INSURANCE_BUCHUNG_VERTEILUNG_STRUKTUR_UPDATE: ACTIONS_PREFIX + 'INSURANCE_BUCHUNG_VERTEILUNG_STRUKTUR_UPDATE',
  },
  GETTERS: {
    BUCHUNG_FIELDS_DEFINITIONS: GETTERS_PREFIX + 'BUCHUNG_FIELDS_DEFINITIONS',
    BUCHUNG: GETTERS_PREFIX + 'BUCHUNG',
    INSURANCE_BUCHUNG_VERTEILUNG_STRUKTUR: GETTERS_PREFIX + 'INSURANCE_BUCHUNG_VERTEILUNG_STRUKTUR',
    SELECTED_INSURANCE_BUCHUNG: GETTERS_PREFIX + 'SELECTED_INSURANCE_BUCHUNG',
  }
}