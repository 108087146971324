var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.parentOptionMenuId,
          defaultMenu: _vm.$appNavigation.parentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Zuordnung Gesellschaften zu BiPRO Zugängen",
          actions: _vm.headerActions
        },
        on: { "action-ADD": _vm.addNewGesellschaft }
      }),
      _vm.gesellschaftOptions && _vm.gesellschaftOptions.length
        ? _c("BaseFilter", {
            attrs: {
              filterId: "0c53a5a6-dbe7-4070-891f-74b68f585555",
              title: "Bipro Kategorien filtern",
              metadata: _vm.filterMetadata,
              defaultOptions: _vm.defaultOptionsData
            },
            on: {
              onFilter: function($event) {
                return _vm.filterForm($event)
              }
            }
          })
        : !_vm.gesellschaftOptions || !_vm.gesellschaftOptions.length
        ? _c("GhostLoading", {
            attrs: {
              type: "block",
              config: { block: { height: 100 } },
              useBoxContainer: ""
            }
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "box__container",
          attrs: { tid: "3c93767d-4367-4793-9c1c-2ef36529b20e" }
        },
        [
          !_vm.loading && _vm.rows.length
            ? _c("BigTable", {
                staticClass: "table-container",
                attrs: {
                  tableId: "a36f8c8e-9800-40c8-9d4c-ec44e59e7e94",
                  title: _vm.TABLE_TITLE,
                  headers: _vm.headers,
                  rows: _vm.rows,
                  exportConfig: { roottext: _vm.TABLE_TITLE },
                  page: _vm.page,
                  sortingState: _vm.sortingState,
                  headerActions: _vm.headerActions,
                  initialPageSize: _vm.minNumRows
                },
                on: {
                  action: function($event) {
                    return _vm.executeAction($event)
                  },
                  onClickHeader: _vm.onClickHeader,
                  "action-REMOVE_ITEM": _vm.confirmItemRemoval
                }
              })
            : _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", title: _vm.TABLE_TITLE }
              })
            : _c("NoData", { attrs: { title: _vm.TABLE_TITLE } })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "removeItemConfirmation",
          attrs: { modalTitle: "Warnung", labelButtonConfirm: "Löschen" },
          on: {
            onConfirmButton: function($event) {
              return _vm.removeItem()
            }
          }
        },
        [
          _vm.selectedRow
            ? [
                _vm._v(
                  " Wollen Sie die Gesellschaft " +
                    _vm._s(_vm.selectedRow.gesellschaft) +
                    " wirklich gelöscht werden? "
                )
              ]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }