var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Kunde löschen",
          subtitle: _vm.deletedCustomersRows.length
            ? "Ergebnis der Kundenlöschung"
            : "Bestätigung"
        }
      }),
      !_vm.deletedCustomersRows.length
        ? _c(
            "div",
            {
              staticClass: "box__container",
              attrs: { tid: "6e357cea-962a-4cee-8d60-7b779a3139d5" }
            },
            [
              _c("p", [
                _vm._v(
                  "Sollen die ausgewählten Kunden wirklich gelöscht werden?"
                )
              ]),
              _vm.rows.length && !_vm.deletedCustomersRows.length
                ? _c("Table", {
                    attrs: {
                      tableId: "fd378e7e-9bcf-4f8d-bc42-2488bbb073c6",
                      headers: _vm.headers,
                      rows: _vm.rows,
                      rowsPerPage: 10
                    }
                  })
                : _vm.rows.length === 0
                ? _c("NoData")
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.deletedCustomersRows.length
        ? _c(
            "div",
            _vm._l(_vm.deletedCustomersRows, function(resultCard, index) {
              return _c(
                "div",
                {
                  key: resultCard.kundennr + index,
                  staticClass: "box__container"
                },
                [
                  _c("div", { staticClass: "box__title" }, [
                    _vm._v(
                      _vm._s(resultCard.kundennr) +
                        " - " +
                        _vm._s(resultCard.name)
                    )
                  ]),
                  _c(
                    "ul",
                    { staticClass: "list m-0" },
                    _vm._l(resultCard.meldungZusatz, function(
                      message,
                      messageIndex
                    ) {
                      return _c("li", { key: message + messageIndex }, [
                        _vm._v(_vm._s(message))
                      ])
                    }),
                    0
                  )
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _c(
        "BaseButton",
        {
          attrs: { isSecondary: "" },
          on: {
            click: function($event) {
              return _vm.goBack()
            }
          }
        },
        [
          _vm._v(
            " " +
              _vm._s(_vm.deletedCustomersRows.length ? "Zurück" : "Abbrechen") +
              " "
          )
        ]
      ),
      _vm.rows.length && !_vm.deletedCustomersRows.length
        ? _c(
            "BaseButton",
            {
              on: {
                click: function($event) {
                  return _vm.kundenLoeschen()
                }
              }
            },
            [_vm._v(" Löschen ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }