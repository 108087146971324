<template>
  <BaseModal 
    ref="optionMenuEditModal" 
    @onCloseButton="onSaveConfig()" 
    size="md" 
    :showDefaultButtons="false"
    :actions="modalActions"
    @action-RESET="onRestoreDefault(); close();"
    @action-SAVE="onSaveConfig(); close();"
  >
    <template #modalTitle>
      <div class="option-menu-edit-modal--title-container">
        <div class="option-menu-edit-modal--title">Optionen-Liste bearbeiten</div>
        <OptionMenuHelpDropdown class="option-menu-edit-modal--help-button" />
      </div>
    </template>

    <template #modalHeaderBottom>
      <Tabs 
        v-if="tabList.length > 1"
        :tabList="tabList" 
        :selected="selectedUserLevel" 
        :disabled="loading" 
        class="mt-1" 
        @tabSelected="selectUserLevel($event.key)" 
      />
    </template>

    <template #default>
      <OptionMenuGhostLoading v-if="loading" />
      <OptionMenuConfig v-else
        :key="selectedUserLevel"
        :configContext="configContext"
        :configId="configId"
        :defaultMenu="defaultMenu"
        :optionMenu="optionMenu"
        :userLevel="selectedUserLevel"
        :currentPath="configId"
      />
    </template>
  </BaseModal>
</template>

<script>
import { mapGetters } from 'vuex';
import MENU_CONFIG_TYPES from '@/store/menuConfig/types';

import BaseModal from '@/components/core/BaseModal.vue';
import Tabs from '@/components/tabs/Tabs.vue';
import OptionMenuGhostLoading from './OptionMenuGhostLoading.vue';
import OptionMenuConfig from './OptionMenuConfig.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import OptionMenuHelpDropdown from '@/components/core/option-menu/OptionMenuHelpDropdown.vue';
import { BaseModalSimpleAction } from '@/components/core/base-modal-actions/base-modal-actions-utils';

import optionMenuEditMixin from './option-menu-edit-mixin';

export default {
  mixins: [optionMenuEditMixin],
  components: {
    BaseModal,
    Tabs,
    OptionMenuGhostLoading,
    OptionMenuConfig,
    BaseButton,
    OptionMenuHelpDropdown,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      tabListFn: MENU_CONFIG_TYPES.GETTERS.OPTIONS_MENU_CONFIG_TABS,
    }),
    tabList() {
      return this.tabListFn(this.configContext);
    },
    modalActions() {
      return [
        BaseModalSimpleAction('RESET', 'Zurücksetzen').withPrimary(() => false),
        BaseModalSimpleAction('SAVE', 'Schließen').withPrimary(() => true),
      ];
    },
  },
  methods: {
    open(initialUserLevel = '') {
      this.resetAll();

      // config user levels
      this.$store.dispatch(MENU_CONFIG_TYPES.ACTIONS.CONFIGURE_USER_LEVEL, { configContext: this.configContext });

      this.init(initialUserLevel);

      this.$refs.optionMenuEditModal.open();
    },
    close() {
      this.$refs.optionMenuEditModal.close();
    },
    async findFCConfigByUserLevel(userLevel, forceReload = false) {
      try {
        this.loading = true;
        await this.$store.dispatch(MENU_CONFIG_TYPES.ACTIONS.FIND_OPTIONS_MENU_CONFIG, {
          configContext: this.configContext,
          userLevel,
          configId: this.configId,
          forceReload,
        });
      } finally {
        this.loading = false;
      }
    },
  },
}
</script>

<!-- GLOBAL STYLE -->
<style lang="scss">
.option-menu-edit-modal--title-container {
  display: flex;
  justify-content: space-between;

  .option-menu-edit-modal--help-button {
    flex: 0 0 auto;
  }
}
</style>
