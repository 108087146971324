<template>
  <div class="ticket-bewertung__container" :class="{ 'ticket-bewertung--centered': centered, }">
    <label class="bewertung">
      <input :checked="bewertung==1" @change="setBewertung(1)" type="radio" name="bewertung" :value="1" :disabled="disabled"/>
      <PhSmiley :size="36" :color="bewertung==1 ? 'var(--color-success)' : 'var(--color-text)'"/>
    </label>
    <label class="bewertung mx-3">
      <input :checked="bewertung==3" @change="setBewertung(3)" type="radio" name="bewertung" :value="3" :disabled="disabled"/>
      <PhSmileyMeh :size="36" :color="bewertung==3 ? 'var(--color-warning)' : 'var(--color-text)'"/>
    </label>
    <label class="bewertung">
      <input :checked="bewertung==5" @change="setBewertung(5)" type="radio" name="bewertung" :value="5" :disabled="disabled"/>
      <PhSmileySad :size="36" :color="bewertung==5 ? 'var(--color-danger)' : 'var(--color-text)'"/>
    </label>
  </div>
</template>

<script>
import { PhSmiley, PhSmileyMeh, PhSmileySad, } from 'phosphor-vue'

export default {
  props: {
    value: {
      type: [Number, String],
      default: null,
    },
    centered: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    bewertung() {
      return this.value;
    },
  },
  methods: {
    setBewertung(value) {
      this.$emit('input', value);
    },
  },
  components: {
    PhSmiley,
    PhSmileyMeh,
    PhSmileySad,
  },
}
</script>

<style lang="scss" scoped>
.ticket-bewertung__container {
  display: flex;
  margin: 0 0 12px;

  &.ticket-bewertung--centered {
    justify-content: center;
  }

  .bewertung {
    cursor: pointer;

    input[type="radio"] {
      display: none;

      &[disabled] ~ * {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }
  }
}
</style>
