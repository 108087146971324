var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Vermittlersuche",
          defaultMenu: _vm.customOptionMenu,
          actions: _vm.headerActions
        },
        on: {
          "action-SELECTION-DONE": _vm.handleBackAction,
          "action-NEW-BROKER": function($event) {
            return _vm.addNewBroker()
          }
        }
      }),
      _c("GenericPersonFilter", {
        attrs: {
          filterId: "fa9b49fb-41b0-4c49-bc6e-981c0741d8bc",
          title: "Vermittlersuche",
          saveKey: "borkerSearchAdvanced",
          metadata: _vm.searchMenu,
          predefinedFilter: _vm.predefinedFilter,
          isCustomerSearch: false,
          configFilter: _vm.configFilter,
          isCache: _vm.$route.meta.isCache,
          defaultOptions: _vm.defaultFilter
        },
        on: {
          search: function($event) {
            return _vm.handleSearch($event)
          }
        }
      }),
      _vm.isExternalSelect || _vm.showWithPerson
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("InputCheckBoxItem", {
                attrs: { label: "Ansprechpartner anzeigen" },
                model: {
                  value: _vm.withPerson,
                  callback: function($$v) {
                    _vm.withPerson = $$v
                  },
                  expression: "withPerson"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("HandleTablePin", { attrs: { keyOfPIN: "TABELLE_VERMITTLERLISTE" } }),
      _vm.filtered
        ? _c(
            "div",
            {
              staticClass: "box__container",
              attrs: { tid: "57259cdd-9d9c-4ef5-8afc-66c34a2f5fcf" }
            },
            [
              _vm.loading && !_vm.scrollLoading
                ? _c("GhostLoading", {
                    attrs: { type: "table", title: _vm.TABLE_TITLE }
                  })
                : _vm.error
                ? _c("UnexpectedError")
                : _c("PaginatedTable", {
                    ref: "tableBrokers",
                    attrs: {
                      title: _vm.TABLE_TITLE,
                      tableId: "9815ec29-a2c2-49e4-95b9-04831b92d2c5",
                      selectionAsBlacklist: _vm.invertSelection,
                      headers: _vm.headers,
                      pages: _vm.pages,
                      pageCount: _vm.pageCount,
                      rowCount: _vm.rowCount,
                      page: _vm.pageId,
                      exportConfig: _vm.exportConfig,
                      sorted: _vm.brokerSearchSort,
                      thHoverDisabled: true,
                      headerActions: _vm.tableHeaderActions,
                      mobileConfig: {
                        title: "fullName",
                        headers: ["userId", "structureLeader"],
                        selectionActive: _vm.isExternalSelect
                      }
                    },
                    on: {
                      page: function($event) {
                        _vm.pageId = $event
                      },
                      requestPage: _vm.loadPage,
                      selectionAsBlacklist: _vm.selectionAsBlacklist,
                      "click-fullName": function($event) {
                        return _vm.selectUserId($event.userId)
                      },
                      "click-phone": function($event) {
                        return _vm.callTapi($event.userId, $event.phone)
                      },
                      "headerAction-SEND-MESSAGE-TO-SELECTED":
                        _vm.handleNewEmail,
                      sort: _vm.onBrokerSearchResultSort,
                      onScroll: _vm.onScroll,
                      scrollLoading: function($event) {
                        _vm.scrollLoading = $event
                      }
                    },
                    model: {
                      value: _vm.selectedRows,
                      callback: function($$v) {
                        _vm.selectedRows = $$v
                      },
                      expression: "selectedRows"
                    }
                  })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }