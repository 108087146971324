var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pagination-container" }, [
    _c(
      "span",
      {
        staticClass: "pagination-text text-centered d-flex align-items-center"
      },
      [
        _vm._v(
          "Anzahl angezeigter Datensätze: " +
            _vm._s(_vm.totalPages == 1 ? _vm.total : _vm.itensOnPage) +
            " von " +
            _vm._s(_vm.total || 0)
        )
      ]
    ),
    _vm.totalPages > 1
      ? _c(
          "ul",
          { staticClass: "fc-pagination justify-content-end m-0" },
          [
            _c("li", { class: { "page-disabled": _vm.currentPage <= 0 } }, [
              _c(
                "a",
                { on: { click: _vm.onClickPreviousPage } },
                [
                  _c("PhCaretLeft", {
                    staticClass: "mr-2",
                    attrs: { size: 16 }
                  }),
                  _c("span", { staticClass: "hide-on-small-screen" }, [
                    _vm._v("zurück")
                  ])
                ],
                1
              )
            ]),
            _vm._l(_vm.pages, function(pageId) {
              return [
                _c(
                  "li",
                  {
                    key: pageId,
                    class: {
                      "page-disabled": _vm.currentPage == pageId,
                      "page-active": _vm.currentPage == pageId
                    }
                  },
                  [
                    pageId < 0
                      ? _c("span", { staticClass: "elipsis" }, [_vm._v("...")])
                      : _c(
                          "a",
                          {
                            on: {
                              click: function($event) {
                                return _vm.onClickPage(pageId)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(pageId + 1) + " ")]
                        )
                  ]
                )
              ]
            }),
            _c(
              "li",
              {
                class: {
                  "page-disabled": _vm.currentPage >= _vm.totalPages - 1
                }
              },
              [
                _c(
                  "a",
                  { on: { click: _vm.onClickNextPage } },
                  [
                    _c("span", { staticClass: "hide-on-small-screen" }, [
                      _vm._v("weiter")
                    ]),
                    _c("PhCaretRight", {
                      staticClass: "ml-2",
                      attrs: { size: 16 }
                    })
                  ],
                  1
                )
              ]
            )
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }