export const MUTATION_PREFIX = 'OPEN_SIGNS: ';
export const ACTIONS_PREFIX = 'OPEN_SIGNS_ACTIONS_';
export const GETTERS_PREFIX = 'SOPEN_SIGNS_GETTERS_';

export default {
  MUTATIONS: {
    GET_OPEN_SIGNS_SUCCESS: MUTATION_PREFIX + 'GET_OPEN_SIGNS_SUCCESS',
    SET_OPEN_SIGNS_COUNT: MUTATION_PREFIX + 'SET_OPEN_SIGNS_COUNT',
    SET_IS_COUNTING: MUTATION_PREFIX + 'SET_IS_COUNTING',
    SET_IS_LOADING: MUTATION_PREFIX + 'SET_IS_LOADING',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    RESET_OPEN_SIGNATURES: MUTATION_PREFIX + 'RESET_OPEN_SIGNATURES',
    OPEN_SIGNATURES_REMOVE_VORLAGE_SUCCESS: MUTATION_PREFIX + 'OPEN_SIGNATURES_REMOVE_VORLAGE_SUCCESS',
    INCREMENT_PAGE_OPEN_SIGNATURE_META: MUTATION_PREFIX + 'INCREMENT_PAGE_OPEN_SIGNATURE_META',
    SET_HAS_OPEN_SIGNS: MUTATION_PREFIX + 'SET_HAS_OPEN_SIGNS',
  },
  ACTIONS: {
    GET_OPEN_SIGNS: ACTIONS_PREFIX + 'GET_OPEN_SIGNS',
    GET_OPEN_SIGNS_COUNT: ACTIONS_PREFIX + 'GET_OPEN_SIGNS_COUNT',
    GET_NEXT_PAGE_OPEN_SIGNATURES: ACTIONS_PREFIX + 'GET_NEXT_PAGE_OPEN_SIGNATURES',
    OPEN_SIGNATURES_REMOVE_VORLAGE: ACTIONS_PREFIX + 'OPEN_SIGNATURES_REMOVE_VORLAGE',
    GET_HAS_OPEN_SIGNS: ACTIONS_PREFIX + 'GET_HAS_OPEN_SIGNS',
    EXPORT_OPEN_SIGNS: ACTIONS_PREFIX + 'EXPORT_OPEN_SIGNS',
    SUBMIT_DOCUMENT: ACTIONS_PREFIX + 'SUBMIT_DOCUMENT',
    METADATA_SUBMIT_DOCUMENT: ACTIONS_PREFIX + 'METADATA_SUBMIT_DOCUMENT',
    GET_DOKUMENT_SIGNATUR_STORNOS_BESTAETIGT: ACTIONS_PREFIX + 'GET_DOKUMENT_SIGNATUR_STORNOS_BESTAETIGT',
    SAVE_DOKUMENT_SIGNATUR_STORNOS_BESTAETIGT: ACTIONS_PREFIX + 'SAVE_DOKUMENT_SIGNATUR_STORNOS_BESTAETIGT',
  },
  GETTERS: {
    OPEN_SIGNS: GETTERS_PREFIX + 'OPEN_SIGNS',
    OPEN_SIGNS_COUNT: GETTERS_PREFIX + 'OPEN_SIGNS_COUNT',
    IS_COUNTING: GETTERS_PREFIX + 'IS_COUNTING',
    OPEN_SIGNS_META: GETTERS_PREFIX + 'OPEN_SIGNS_META',
    GET_SIGNO_VIEWER_LINK_MAKER: GETTERS_PREFIX + 'GET_SIGNO_VIEWER_LINK_MAKER',
    HAS_OPEN_SIGNS: GETTERS_PREFIX + 'HAS_OPEN_SIGNS',
  }
}
