var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "GhostLoading",
    [
      _vm.showTitle
        ? _c("Block", {
            style: { margin: "0 0 16px 0" },
            attrs: { type: "title" }
          })
        : _vm._e(),
      _c("Block", {
        style: { margin: "0 0 1px 0" },
        attrs: { height: "40px" }
      }),
      _c("Block", {
        style: { margin: "0 0 1px 0" },
        attrs: { height: "40px" }
      }),
      _c("Block", {
        style: { margin: "0 0 1px 0" },
        attrs: { height: "40px" }
      }),
      _c("Block", {
        style: { margin: "0 0 1px 0" },
        attrs: { height: "40px" }
      }),
      _c("Block", {
        style: { margin: "0 0 1px 0" },
        attrs: { height: "40px" }
      }),
      _c("Block", { staticClass: "mb-0", attrs: { height: "40px" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }