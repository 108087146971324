<template>
  <div>
    <PageHeaderTitleNavigation
      :title="$appNavigation.currentMenu.label"
      :actions="headerActions"
      @action-MARK="confirmSelection()"
    />

    <StornoWarnungFilter ref="filter" @getStornos="getStornosForPage({limit: ROWS_PER_PAGE_DEFAULT})"/>
    <StornoWarnungTable v-model="selectedRows" focusOnResult @goToPage="getStornosForPage($event)" @sort="getStornosForPage($event)" @confirm="confirmSelection()"/>
    
  </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue'
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import StornoWarnungTable from '@/components/stornoWarnungen/StornoWarnungTable.vue'
import StornoWarnungFilter from '@/components/stornoWarnungen/StornoWarnungFilter.vue'
import STORNO_WARNUNG_TYPES from '@/store/stornoWarnung/types';
import mixin from '@/mixins/stornos/stornos-mixin.js';
import { mapGetters } from 'vuex';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {
    mixins: [mixin],
    components: {
        OptionMenu,
        PageHeaderTitleNavigation,
        StornoWarnungTable,
        StornoWarnungFilter,
        PageHeaderSimpleAction,
    },
    data() {
        return {
            selectedRows: [],
        }
    },
    computed: {
        ...mapGetters({
            pageIndex: STORNO_WARNUNG_TYPES.GETTERS.PAGE_INDEX,
        }),
        isPrimary() {
            return this.selectedRows.length > 0;
        },
        headerActions() {
          return this.selectedRows.length > 0 ? [
            PageHeaderSimpleAction('MARK', 'Markierte Positionen bestätigen')
          ] : [];
        },
    },
  methods: {
    confirmSelection() {
      const selection = this.selectedRows.length && (this.selectedRows.map(sel => sel.stornoId) || []).filter(id => !!id) || null;
      if (selection?.length) {
        this.$store.dispatch(STORNO_WARNUNG_TYPES.ACTIONS.CONFIRM_STORNO_WARNUNG, { stornosToConfirm: selection, pageIndex: this.pageIndex, limit: this.ROWS_PER_PAGE_DEFAULT });
        this.selectedRows = [];
      }
    },
    getStornosForPage(data = {}) {
      this.$store.dispatch(STORNO_WARNUNG_TYPES.ACTIONS.GET_STORNO_WARNUNG, {...data, limit: this.ROWS_PER_PAGE_DEFAULT})
    },
  },
  beforeRouteLeave(to, from, next) {
    // this.$store.commit(STORNO_WARNUNG_TYPES.MUTATIONS.RESET_STORNOS);

    this.$addBreadcrumb({ to, from, });

    next();
  }
}
</script>

<style scoped>

</style>