<template>
  <div>
    <div v-if="currentPendingMarketingCampaign">
      <div class="box__container">
        <div class="box__title">
          {{ currentPendingMarketingCampaign.title }}
        </div>
        <div
          v-html="sanitize(currentPendingMarketingCampaign.body)">
        </div>
      </div>

      <div class="box__container">
        <div>
          <div class="layout__negative-margin--8">
            <BaseButton 
              isSecondary
              @click="postponeCampaign()">
              Abbrechen
            </BaseButton>
            
            <BaseButton
              @click="acknowledgeCampaign()">
              Bestätigen
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import MSC_MARKETING_TYPES from '@/store/mscmarketing/types';
import { mapGetters } from 'vuex';
import BaseButton from '@/components/core/BaseButton.vue';
import { sanitize } from '@/helpers/string-helper.js';

export default {
  components: {
    BaseButton,

  },
  computed: {
    ...mapGetters({
      pendingMarketingCampaigns: MSC_MARKETING_TYPES.GETTERS.PENDING_MARKETING_CAMPAIGNS
    }),
    currentPendingMarketingCampaign() {
      return this.pendingMarketingCampaigns?.length && this.pendingMarketingCampaigns[0];
    },
    acceptPostponePayload() {
      return {
        nextUrl: this.$route.query?.nextUrl || '/home', 
        marketingCampaign: this.currentPendingMarketingCampaign 
      }
    }
  },
  methods: {
    sanitize(htmlString) {
      return sanitize(htmlString);
    },
    postponeCampaign() {
      this.$store.dispatch(MSC_MARKETING_TYPES.ACTIONS.POSTPONE_MARKETING_CAMPAIGN, this.acceptPostponePayload);
    },
    acknowledgeCampaign() {
      this.$store.dispatch(MSC_MARKETING_TYPES.ACTIONS.ACKNOWLEDGE_MARKETING_CAMPAIGN, this.acceptPostponePayload);
    }
  },
  mounted() {
    this.$store.dispatch(MSC_MARKETING_TYPES.ACTIONS.GET_PENDING_MARKETING_CAMPAIGNS);
  },
}
</script>
