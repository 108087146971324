import CREDIT_TYPES from './types';
import CORE_TYPES from '@/store/core/types'
import axios from 'axios';

const config = {
  defaultSpinner: true
};

export default {
  async [CREDIT_TYPES.ACTIONS.FIND_CREDITS]({ commit, getters, dispatch}, params) {
    let queryParam = params && Object.entries(params).map(([key, val]) => `${key}=${val}`).join('&') || '';
    queryParam = queryParam ? `?${queryParam}` : ''

    const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrscredit${queryParam}`;
    await axios.get(url, config).then(response => {
      commit(CREDIT_TYPES.MUTATIONS.FIND_CREDITS_SUCCESS, response?.data?.list || []);
    })
    .catch(error => {
      commit(CREDIT_TYPES.MUTATIONS.FIND_CREDITS_SUCCESS, []);
      console.log(error);
    });
  },

  [CREDIT_TYPES.ACTIONS.GET_COMBOS]({ commit, getters }) {
    axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/credit/combos`, config)
      .then(response => {
        commit(CREDIT_TYPES.MUTATIONS.GET_COMBOS_SUCCESS, response?.data?.combos || {});
        commit(CREDIT_TYPES.MUTATIONS.SET_RIGHTS, response?.data || {});
      })
      .catch(error => console.log(error));
  },

  async [CREDIT_TYPES.ACTIONS.GET_CREDIT]({ commit, getters }) {
    const creditId = getters[CREDIT_TYPES.GETTERS.SELECTED_CREDIT_ID];
    if(!creditId || getters[CREDIT_TYPES.GETTERS.IS_NEW]) return;

    await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/credit/get/${creditId}`, config)
      .then(response => {
        commit(CREDIT_TYPES.MUTATIONS.GET_CREDIT_SUCCESS, response?.data?.credit || {});
        commit(CREDIT_TYPES.MUTATIONS.GET_PROHYP_SUCCESS, response?.data?.prohyp || {});
        commit(CREDIT_TYPES.MUTATIONS.SET_RIGHTS, response?.data || {});
      })
      .catch(error => console.log(error));
  },

  async [CREDIT_TYPES.ACTIONS.SAVE_ONLY]({ getters, commit, }) {
    const edited = getters[CREDIT_TYPES.GETTERS.CREDIT_EDITED];
    if(!edited?.id) return;

    if(getters[CREDIT_TYPES.GETTERS.IS_NEW]) {
      edited.id = null;
    }

    const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/credit/createOrUpdate`, edited, config);
    commit(CREDIT_TYPES.MUTATIONS.RESET_CREDIT_EDITED);
    return response?.data?.credit || {};
  },

  async [CREDIT_TYPES.ACTIONS.SAVE_CREDIT]({ commit, dispatch, getters }) {
    const credit = await dispatch(CREDIT_TYPES.ACTIONS.SAVE_ONLY);
    if(!credit?.id) return;

    if(getters[CREDIT_TYPES.GETTERS.IS_NEW]) {
      commit(CREDIT_TYPES.MUTATIONS.SET_SELECTED_CREDIT_ID, credit?.id);
    }

    dispatch(CREDIT_TYPES.ACTIONS.GET_CREDIT);
  },

  async [CREDIT_TYPES.ACTIONS.DELETE_CREDIT]({ commit, getters }, { creditId }) {
    if(!creditId) return;

    await axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/credit/delete/${creditId}`, config)
      .then(response => {
      })
      .catch(error => console.log(error));
  },

  async [CREDIT_TYPES.ACTIONS.FIND_UPDATES]({ commit, getters }) {
    const creditId = getters[CREDIT_TYPES.GETTERS.SELECTED_CREDIT_ID];
    if(!creditId || getters[CREDIT_TYPES.GETTERS.IS_NEW]) return;

    await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/credit/updates/${creditId}`, config)
      .then(response => {
        commit(CREDIT_TYPES.MUTATIONS.FIND_UPDATES_SUCCESS, response?.data || []);
      })
      .catch(error => console.log(error));
  },

  [CREDIT_TYPES.ACTIONS.SAVE_UPDATE]({ dispatch, getters }, payload) {
    const creditId = getters[CREDIT_TYPES.GETTERS.SELECTED_CREDIT_ID];
    if(!creditId || getters[CREDIT_TYPES.GETTERS.IS_NEW]) return;
    if(!payload?.bemerkung || !payload?.datum) return ;

    const update = {
      ...payload,
      aktWert: payload.aktWert || 0,
      kreditId: creditId,
    };

    axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/credit/setUpdate`, update, config)
      .then(response => {
        dispatch(CREDIT_TYPES.ACTIONS.FIND_UPDATES);
      })
      .catch(error => console.log(error));
  },

  [CREDIT_TYPES.ACTIONS.DELETE_UPDATE]({ dispatch, getters }, { updateId }) {
    if(!updateId) return;

    axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/credit/deleteUpdate/${updateId}`, config)
      .then(response => {
        dispatch(CREDIT_TYPES.ACTIONS.FIND_UPDATES);
      })
      .catch(error => console.log(error));
  },

  async [CREDIT_TYPES.ACTIONS.FIND_STATUS]({ commit, getters }) {
    const creditId = getters[CREDIT_TYPES.GETTERS.SELECTED_CREDIT_ID];
    if(!creditId || getters[CREDIT_TYPES.GETTERS.IS_NEW]) return;

    await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/credit/status/${creditId}`, config)
      .then(response => {
        commit(CREDIT_TYPES.MUTATIONS.FIND_STATUS_SUCCESS, response?.data || []);
      })
      .catch(error => console.log(error));
  },

  [CREDIT_TYPES.ACTIONS.SAVE_STATUS]({ dispatch, getters }, payload) {
    const creditId = getters[CREDIT_TYPES.GETTERS.SELECTED_CREDIT_ID];
    if(!creditId || getters[CREDIT_TYPES.GETTERS.IS_NEW]) return;
    if(!payload?.bemerkung || !payload?.datum) return ;

    const status = {
      ...payload,
      kreditId: creditId,
    };

    axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/credit/setStatus`, status, config)
      .then(response => {
        dispatch(CREDIT_TYPES.ACTIONS.FIND_STATUS);
      })
      .catch(error => console.log(error));
  },

  [CREDIT_TYPES.ACTIONS.DELETE_STATUS]({ dispatch, getters }, { statusId }) {
    if(!statusId) return;

    axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/credit/deleteStatus/${statusId}`, config)
      .then(response => {
        dispatch(CREDIT_TYPES.ACTIONS.FIND_STATUS);
      })
      .catch(error => console.log(error));
  },

  [CREDIT_TYPES.ACTIONS.FIND_APPOINTMENTS]({ commit, getters }) {
    const creditId = getters[CREDIT_TYPES.GETTERS.SELECTED_CREDIT_ID];
    if(!creditId || getters[CREDIT_TYPES.GETTERS.IS_NEW]) return;

    axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/credit/appointments/${creditId}`, config)
      .then(response => {
        commit(CREDIT_TYPES.MUTATIONS.FIND_APPOINTMENTS_SUCCESS, response?.data || []);
      })
      .catch(error => console.log(error));
  },

  [CREDIT_TYPES.ACTIONS.GET_PROHYP_COMBOS]({ commit, getters }) {
    axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/credit/prohypCombos`, config)
      .then(response => {
        commit(CREDIT_TYPES.MUTATIONS.GET_PROHYP_COMBOS_SUCCESS, response?.data || {});
      })
      .catch(error => console.log(error));
  },

  async [CREDIT_TYPES.ACTIONS.SAVE_PROHYP]({ commit, getters }) {
    const creditId = getters[CREDIT_TYPES.GETTERS.SELECTED_CREDIT_ID];
    if(!creditId || getters[CREDIT_TYPES.GETTERS.IS_NEW]) return;

    const edited = getters[CREDIT_TYPES.GETTERS.PROHYP_EDITED];
    if(!edited?.kreditId) return;

    await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/credit/setProhyp`, edited, config)
      .then(response => {
        commit(CREDIT_TYPES.MUTATIONS.RESET_PROHYP_EDITED);
        commit(CREDIT_TYPES.MUTATIONS.GET_CREDIT_SUCCESS, response?.data?.credit || {});
        commit(CREDIT_TYPES.MUTATIONS.GET_PROHYP_SUCCESS, response?.data?.prohyp || {});
      })
      .catch(error => console.log(error));
  },

  async [CREDIT_TYPES.ACTIONS.SEND_TO_PROHYP]({ getters }) {
    const creditId = getters[CREDIT_TYPES.GETTERS.SELECTED_CREDIT_ID];
    if(!creditId || getters[CREDIT_TYPES.GETTERS.IS_NEW]) return;

    return await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/credit/sendToProhyp/${creditId}`, {}, config)
      .then(response => {
        return response?.data || {};
      })
      .catch(error => console.log(error));
  },

  async [CREDIT_TYPES.ACTIONS.UPDATE_FROM_PROHYP]({ getters }) {
    const creditId = getters[CREDIT_TYPES.GETTERS.SELECTED_CREDIT_ID];
    if(!creditId || getters[CREDIT_TYPES.GETTERS.IS_NEW]) return;

    return await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/credit/updateFromProhyp/${creditId}`, {}, config)
      .then(response => {
        return response?.data || {};
      })
      .catch(error => console.log(error));
  },

  async [CREDIT_TYPES.ACTIONS.GET_PROHYP_PORTAL_URL]({ getters }) {
    return await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/credit/prohypPortalUrl`, config)
      .then(response => {
        return response?.data || '';
      })
      .catch(error => console.log(error));
  },

  async [CREDIT_TYPES.ACTIONS.FIND_BUCHUNGEN]({ getters, commit }) {
    const creditId = getters[CREDIT_TYPES.GETTERS.SELECTED_CREDIT_ID];
    if(!creditId || getters[CREDIT_TYPES.GETTERS.IS_NEW]) return;

    await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/credit/buchungen/${creditId}`, config)
      .then(response => {
        commit(CREDIT_TYPES.MUTATIONS.FIND_BUCHUNGEN_SUCCESS, response?.data || []);
      })
      .catch(error => console.log(error));
  },

  async [CREDIT_TYPES.ACTIONS.FIND_BUCHUNG_PROV_STRUKTUREN]({ getters, commit }) {
    const buchung = getters[CREDIT_TYPES.GETTERS.SELECTED_BUCHUNG];
    if(!buchung || !buchung?.id) return;

    await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/credit/buchungProvStrukturen/${buchung.id}`, config)
      .then(response => {
        commit(CREDIT_TYPES.MUTATIONS.FIND_BUCHUNG_PROV_STRUKTUREN_SUCCESS, response?.data || []);
      })
      .catch(error => console.log(error));
  },

  [CREDIT_TYPES.ACTIONS.FIND_DOCUMENTS]({ commit, getters }) { // TODO remove after migrate Kredit Dokumente to Dokumentenarchiv
    const creditId = getters[CREDIT_TYPES.GETTERS.SELECTED_CREDIT_ID];
    if(!creditId || getters[CREDIT_TYPES.GETTERS.IS_NEW]) return;

    axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrskreditdokumente?kreditId=${creditId}`, config)
      .then(response => {
        commit(CREDIT_TYPES.MUTATIONS.FIND_DOCUMENTS_SUCCESS, response?.data?.listDokumente || []);
      })
      .catch(error => console.log(error));
  },

}
