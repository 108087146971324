<template>
  <div class="entgelt-component">
    <label>{{ label }}</label>

    <div class="entgelt-component-netto-input-container" v-if="!!componentNetto">
      <InputField type="percent" 
        :label="componentNetto.label" 
        :placeholder="componentNetto.placeholder"
        :precision="precision" 
        :value="internalValueNetto" 
        :disabled="disabled"
        @input="handleNettoInput($event)" 
        @change="handleNettoChange($event)" />
    </div>

    <div class="entgelt-component-brutto-input-container" v-if="!!componentBrutto">
      <InputField type="percent" 
        :label="componentBrutto.label" 
        :placeholder="componentBrutto.placeholder" 
        :precision="precision" 
        :value="internalValueBrutto" 
        :disabled="disabled"
        @input="handleBruttoInput($event)" 
        @change="handleBruttoChange($event)" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import CORE_TYPES from '@/store/core/types.js';
import InputField from '@/components/core/forms/InputField.vue';
import { formatNumber, parse } from '@/helpers/number-formatter.js';

// Deutschland Tax
const UMSATZSTEUER_DE = 1.190;
// Austrian Tax
const UMSATZSTEUER_AT = 1.200;

const ENTGELT_TYPE_NETTO = 'netto';
const ENTGELT_TYPE_BRUTTO = 'brutto';

const PRECISION = 3;
//MSC-20201 ffb serviceentgelt in depotopening 2 decimals when via webservice, otherwise 3 deciamls
const PRECISION_FFB_ONLINE = 2;

export default {
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
    values: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['change'],
  data() {
    return {
      internalValueNetto: parse(this.values[ENTGELT_TYPE_NETTO], this.config.isFFBOnline ? PRECISION_FFB_ONLINE : PRECISION),
      internalValueBrutto: parse(this.values[ENTGELT_TYPE_BRUTTO], this.config.isFFBOnline ? PRECISION_FFB_ONLINE : PRECISION),
      precision: this.config.isFFBOnline ? PRECISION_FFB_ONLINE : PRECISION,
      hasChanged: false,
    }
  },
  computed: {
    ...mapGetters({
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
    }),
    isFA() {
      return this.loginData && this.loginData.rights && this.loginData.rights.isFA;
    },
    uSt() {
      return this.isFA ? UMSATZSTEUER_AT : UMSATZSTEUER_DE;
    },
    componentFields() {
      return this.config && this.config.componentFields.reduce((result, cf) => {
        result[cf.config.entgeltType] = cf;
        return result;
      }, {}) || {};
    },
    componentNetto() {
      return this.componentFields[ENTGELT_TYPE_NETTO] || {};
    },
    componentBrutto() {
      return this.componentFields[ENTGELT_TYPE_BRUTTO] || {};
    },
  },
  methods: {
    handleNettoInput(value) {
      let newValue = undefined;
      if(value || value === 0) {
        newValue = (value * this.uSt).toFixed(this.config.isFFBOnline ? PRECISION_FFB_ONLINE : PRECISION);
      }
      this.internalValueBrutto = newValue;

      this.hasChanged = true;
    },
    handleBruttoInput(value) {
      let newValue = undefined;
      if(value || value === 0) {
        newValue = (value / this.uSt).toFixed(this.config.isFFBOnline ? PRECISION_FFB_ONLINE : PRECISION);
      }
      this.internalValueNetto = newValue;

      this.hasChanged = true;
    },
    handleNettoChange(value) {
      this.internalValueNetto = value;
      this.emitChangeEvent();
    },
    handleBruttoChange(value) {
      this.internalValueBrutto = value;
      this.emitChangeEvent();
    },
    emitChangeEvent() {
      if(!this.hasChanged) {
        return ;
      }

      const valuesToChange = {};

      valuesToChange[this.componentNetto.id] = formatNumber(this.internalValueNetto, this.config.isFFBOnline ? PRECISION_FFB_ONLINE : PRECISION);
      valuesToChange[this.componentBrutto.id] = formatNumber(this.internalValueBrutto, this.config.isFFBOnline ? PRECISION_FFB_ONLINE : PRECISION);

      this.$emit('change', valuesToChange);

      this.hasChanged = false;
    }
  },
  components: {
    InputField,
  },
}
</script>


<style scoped>
  .entgelt-component-netto-input-container {
    margin-bottom: 8px;
  }
</style>
