<template>
  <div class="label lrate">
    <div class="result">{{getRate}} €</div>
    <div class="truelabel">Rate</div>
  </div>
</template>

<script>
//import store from "./../../store.js";
export default {
  name: "LRate",
  computed: {
    getRate() {
      return this.$store.getters.getRate;
    }
  },
};
</script>

<style scoped>
.lrate {
  grid-area: lrate;
}

</style>