<template>
  <div v-if="isCustomerAllowed">
    <div class="box__container">
      <div class="box__title">
        Noch keine Konten verknüpft
      </div>
      <div class="row">
        <div class="col-12">
          <p>
            Mit der Multibanking funktion können Sie Ihre Bankkonten und externen Depots hier an einem zentralen Ort verknüpfen. Ihr Salden und Depotpositionen werden dann automatisch der Vermögensbilanz hinzugefügt.  Daraus erhalten Sie nicht nur ein realistisches Abbild Ihrer Vermögenssituation.
          </p>
          <p>
          Durch unsere intelligenten Analysen erhalten Sie Auswertungen und Prognosen für die Zukunft:
          </p>
          <ul>
            <li>Ihre Kontoumsätze werden automatisch kategorisiert. Dadurch sehen Sie wohin Ihr Geld fließt und können mit den Budgets Ihr Ausgabeverhalten managen.</li>
            <li>Regelmäßige Ausgaben wie Verträge werden erkannt.</li>
            <li>Durch die Prognose von potentiellen Ausgaben und Verträgen wissen Sie täglich wie viel Sie bis zum nächsten Geldeingang frei verfügbar haben.</li>
            <li>Sie erhalten tägliche und wöchentliche Zusammenfassungen sowie wichtige Hinweise und Warnungen.</li>
          </ul>
          <br>
          <p>Um Ihre Konten zu verknüpfen, gehen Sie bitte auf die <router-link class="btn-link" :to="{name: 'kontoverwaltung'}">Kontoverwaltung</router-link>.</p>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="box__container">
      <div class="box__title">
        Achtung, Zugriff verweigert!
      </div>
      Sie haben keinen Berechtigung um diese Daten zu sehen.
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CORE_TYPES from '../../store/core/types'
import MULTIBANKING_TYPES from '../../store/multiBanking/types'

export default {
  props: {
    forbidden: {
      type:Boolean,
      default: false,
    }
  },
  
  computed: {
    ...mapGetters({
      isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
      userSettings: MULTIBANKING_TYPES.GETTERS.USER_SETTINGS,
    }),
    isCustomerAllowed() {
      return !this.forbidden && (!this.isBrokerOrBypass || (this.userSettings && this.userSettings.shareAccounts))
    },
  }
}
</script>

<style scoped>

</style>