import actions from './actions';
import mutations from './mutations';
import getters from './getters';



/**
 * mutations: synchronous operation responsible for changing the state
 * actions: asynchronous operation responsible for calling external resources and commiting mutations 
 */

export function getInitialState() {
  return {
    DATA:null,
    DATA_SEGMENTS:null,
    START_TIME:null,
    SESSION:null,
    SELECTED_NODE:null,
    STAT_DATA:null,
    FORMAT_SWITCH:true,
    FULL_SQL:false,
    RUNNIG:false ,
    STAT:'IO',
    MINUTS:40
    }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}