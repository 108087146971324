var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "fondsshop_container" }, [
    _c(
      "div",
      { staticClass: "banner" },
      [_c("editable-frame", { attrs: { type: "fondsshop_title_page" } })],
      1
    ),
    _c("hr"),
    _c("div", { staticClass: "fondsshop_content" }, [
      _c(
        "div",
        { staticClass: "fondsshop_image" },
        [_c("EditableImageOnly", { attrs: { type: "fondsshop_image1" } })],
        1
      ),
      _c(
        "div",
        { staticClass: "fondsshop_text" },
        [
          _c("editable-frame", { attrs: { type: "fondsshop_text1" } }),
          _c(
            "base-button",
            {
              on: {
                click: function($event) {
                  return _vm.$router.push("fonds")
                }
              }
            },
            [_vm._v("JETZT FONDSSHOP ENTDECKEN!")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }