var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [
      _vm.config.tableData
        ? _c(
            "div",
            { staticClass: "col-12" },
            [
              _vm.rows.length
                ? _c("Table", {
                    attrs: {
                      tableId: "701e2bd4-29b4-427e-96da-40ad35e57788",
                      headers: _vm.headers,
                      rows: _vm.rows,
                      rowsPerPage: 20
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }