import QUARTALSBERICHTE_TYPES from './types';
import CORE_TYPES from '@/store/core/types'
import { makeQueryParam } from '@/helpers/utils-helper';

export default {
  [QUARTALSBERICHTE_TYPES.GETTERS.QUARTALSBERICHTE_NODE](state) {
    return state.quartalsberichteNode;
  },
  [QUARTALSBERICHTE_TYPES.GETTERS.QUARTALSBERICHTE_BERECHNEN_ERGEBNIS](state) {
    return state.berechnenErgebnis;
  },
}