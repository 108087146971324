import DOKUMENTENARCHIV_TYPES from './types';
import OPEN_SIGNS_TYPES from '@/store/openSigns/types';
import HTTP_REQ_TYPES from '@/store/http-requests/types'
import CORE_TYPES from '../core/types';
import LOG_TYPES from '@/store/log/types';
import axios from 'axios';
import { buildMessage, } from '../../helpers/log-message-helper';
import * as utils from '../../helpers/commonfunctions';
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";
import OTHER_PRODUCTS_TYPES from '@/store/otherProducts/types';
import Vue from 'vue'

import { makeQueryParam } from '@/helpers/utils-helper';

export default {
  [DOKUMENTENARCHIV_TYPES.ACTIONS.RETRIEVE_DOKUMENTENARCHIV]({ commit, dispatch, getters, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    commit(DOKUMENTENARCHIV_TYPES.MUTATIONS.SET_TREE_LOADING, true);

    // if the document tree is already loading cancel the old request
    const requestList = getters[HTTP_REQ_TYPES.GETTERS.HTTP_REQUEST_URL_LIST]
    if (requestList && requestList.length) {
      let oldRequest = requestList.find(req => req.url?.includes('/dokumentenarchiv/getDokTree'))
      if (oldRequest && oldRequest.requestId) {
        dispatch(HTTP_REQ_TYPES.ACTIONS.KILL_SESSION, { requestId: oldRequest.requestId })
      }
    }

    const docFilter = getters[DOKUMENTENARCHIV_TYPES.GETTERS.DOCUMENTS_FILTER]
    const params = makeQueryParam({ ...docFilter, root: payload?.root, });

    return new Promise((resolve, reject) => {
      axios.get(rootState.core.apiAddress + `/dokumentenarchiv/getDokTree?${params}`, config).then(response => {
        if (response && response.data) {
          commit(DOKUMENTENARCHIV_TYPES.MUTATIONS.RETRIEVE_DOKUMENTENARCHIV_SUCCESS, response.data);
          resolve(response.data)
        } 
      }).finally(() => commit(DOKUMENTENARCHIV_TYPES.MUTATIONS.SET_TREE_LOADING, false))

    })
  },

  async [DOKUMENTENARCHIV_TYPES.ACTIONS.RETRIEVE_NODE_TREE]({ getters, }, { nodeId, }) {
    if(!nodeId) return;

    const config = {
      defaultSpinner: true,
    };

    let serviceUrl = '/dokumentenarchiv/getDokNodeTree';
    const docFilter = getters[DOKUMENTENARCHIV_TYPES.GETTERS.DOCUMENTS_FILTER]
    const params = makeQueryParam({ ...docFilter, nodeId, });

    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${serviceUrl}?${params}`, config);
    return { ...response?.data?.nodes?.[0] || {}, };
  },
  [DOKUMENTENARCHIV_TYPES.ACTIONS.RETRIEVE_ATTACHMENTS_INFO]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    let serviceUrl = '/dokumentenarchivSign/documentSignAction';


    if (payload) {
      const nodeId = payload.actionData?.nodeId;
      const documentId = payload.id;

      if (nodeId && documentId) {
        serviceUrl = `${serviceUrl}?docInfo=${nodeId}&docInfo=${documentId}`;
      } else {
         return; 
      }

    }    
   

    axios.post(rootState.core.apiAddress + serviceUrl, payload.actionData.action?.sendData, config).then(response => {
      if (response && response.data) {
        const data = Object.assign(response.data || {}, payload.actionData || {});
        commit(DOKUMENTENARCHIV_TYPES.MUTATIONS.RETRIEVE_ATTACHMENTS_INFO_SUCCESS, data);

      }      

    })
  },

  
  async [DOKUMENTENARCHIV_TYPES.ACTIONS.UPLOAD_DOCUMENT]({ commit, state, getters, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true,
    };

    const params = {};
    if(payload?.schadenId) {
      params.schadenId = payload.schadenId;
    }

    await axios.post(rootState.core.apiAddress + '/dokumentenarchiv/uploadDocument', payload, {params, ...config}).then(response => {
      return new Promise((resolve) => {
        const nodeId = payload.parentId;
        const offset = 0;
        const limit = 20;
        const versicherungId = payload.versicherungId;
        const schadenId = payload.schadenId;

        if (!payload.suppressReload) {
          if (versicherungId) {
            dispatch(VERSICHERUNG_TYPES.ACTIONS.RETRIEVE_INSURANCE_DETAILS, {versVertrag: versicherungId});
          }
          
          dispatch(OPEN_SIGNS_TYPES.ACTIONS.GET_OPEN_SIGNS_COUNT)
          dispatch(DOKUMENTENARCHIV_TYPES.ACTIONS.RETRIEVE_DOCUMENTS, {nodeId, offset, limit, versicherungId, schadenId, reloadTree: true})
        }
        resolve();
      })
    })
  },

  [DOKUMENTENARCHIV_TYPES.ACTIONS.EXECUTE_ACTION]({ dispatch, getters, state }, {action, nodeId, documentId, offset, limit, schadenId}) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API}/dokumentenarchiv/executeAction?docInfo=${nodeId}&docInfo=${encodeURIComponent(documentId)}`,
        action, {
        headers: {'Content-Type': 'application/json'},
        defaultSpinner: true
      }).then(response => {
        if (response.data?.fehlerTooltip != null) {
          // an error occured
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.fehlerTooltip, 'danger'));
          reject();
        } else if (response.data?.warningMessage) {
          resolve({ warningMessage: response.data?.warningMessage, warningTitle: response.data.warningTitle })
        } else {
          if (action && (action.specificAction === 'ACTION_SHOW_FOR_SIGNING' || action.legend?.key === 'DOKUMENT_LOSCHEN')) {
            dispatch(OPEN_SIGNS_TYPES.ACTIONS.GET_OPEN_SIGNS_COUNT)
          }

          if (action && action.legend?.key === 'DOKUMENT_LOSCHEN') {
            dispatch(DOKUMENTENARCHIV_TYPES.ACTIONS.RETRIEVE_DOCUMENTS, {nodeId, offset, limit, schadenId, reloadTree: true});

          } else {
            const selectedNode = getters[DOKUMENTENARCHIV_TYPES.GETTERS.DOCUMENTS];
            var foundIndex = selectedNode.documents[offset].findIndex(x => x.id == response.data.id);
            selectedNode.documents[offset][foundIndex] = response.data;
            Vue.set(state.documentNode, 'documents', Object.assign({}, selectedNode.documents));
          }

          resolve();
        }
      })
    });
  },

  async [DOKUMENTENARCHIV_TYPES.ACTIONS.RETRIEVE_ADDITIONAL_UPLOAD_INPUTS]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    let serviceUrl = `${rootState.core.apiAddress}/dokumentenarchiv/getAdditionalUploadInputs`;

    let params = {};
      
    if(payload?.nodeId) {
      params.nodeId = payload.nodeId;
    }
    if(payload?.showOnlySchadenDocs) {
      params.showOnlySchadenDocs = payload.showOnlySchadenDocs;
    }

    await axios.get(serviceUrl, { ...config, params, }).then(response => {
      if (response && response.data) {
        commit(DOKUMENTENARCHIV_TYPES.MUTATIONS.RETRIEVE_ADDITIONAL_UPLOAD_INPUTS_SUCCESS, response.data);
      }
    })
  },
  
    async [DOKUMENTENARCHIV_TYPES.ACTIONS.SORT_DOCUMENTS]({ state, commit, dispatch, getters, rootState }, {sortKey, ascending}) {
        commit(DOKUMENTENARCHIV_TYPES.MUTATIONS.SORTING, {sortKey, ascending});
        if (state.reloadDocumentsPayload) {
            dispatch(DOKUMENTENARCHIV_TYPES.ACTIONS.RETRIEVE_DOCUMENTS, {
                ...state.reloadDocumentsPayload,
                keepOldPages: false,
            });
        }
    },

  async [DOKUMENTENARCHIV_TYPES.ACTIONS.RETRIEVE_DOCUMENTS]({ state, commit, dispatch, getters, rootState }, payload) {

    commit(DOKUMENTENARCHIV_TYPES.MUTATIONS.RELOAD_DOCUMENTS_PAYLOAD, payload);
    const config = {
      defaultSpinner: true,
    };

    let serviceUrl = '/dokumentenarchiv/getDocuments';

    const params = {
        sortKey: state.sortKey,
        sortAscending: state.sortAscending,
    };

    if (payload) {

      const explorerData = getters[DOKUMENTENARCHIV_TYPES.GETTERS.DOKUMENTENARCHIV_DATA]

      // Tree needs to be reloaded for some actions
      if (payload.reloadTree && Object.keys(explorerData).length !== 0) {
        dispatch(DOKUMENTENARCHIV_TYPES.ACTIONS.RETRIEVE_DOKUMENTENARCHIV);
      }

      const nodeId = payload.nodeId;

      if (nodeId) {
        // always set target
        commit(DOKUMENTENARCHIV_TYPES.MUTATIONS.SET_TARGET_NODE_ID, nodeId)

        // if it's being loaded already we can return
        if (state.loadingNodeList.includes(nodeId) && !payload.doAlwaysLoad) {
          return
        }

        // cancel old requests since we are loading a new node
        const requestList = getters[HTTP_REQ_TYPES.GETTERS.HTTP_REQUEST_URL_LIST]
        if (requestList && requestList.length) {
          let oldRequest = requestList.find(req => req.url?.includes('/dokumentenarchiv/getDocuments'))
          if (oldRequest && oldRequest.requestId) {
            dispatch(HTTP_REQ_TYPES.ACTIONS.KILL_SESSION, { requestId: oldRequest.requestId })
          }
        }
  
        // add nodeId that's to be loaded to current loading list
        commit(DOKUMENTENARCHIV_TYPES.MUTATIONS.ADD_LOADING_NODE_ID_TO_LIST, nodeId)

        Object.assign(params, {nodeId})
      } else {
        return
      }

      const offset = payload.offset;

      if (typeof offset !== undefined) {
        Object.assign(params, {offset})
      }

      const limit = payload.limit;

      if (typeof limit !== undefined) {
        Object.assign(params, {limit})
      }      

      const antragsNrIntern = payload.antragsNrIntern;
      if (antragsNrIntern) {
        Object.assign(params, {antragsNrIntern})
      }

      const docFilter = getters[DOKUMENTENARCHIV_TYPES.GETTERS.DOCUMENTS_FILTER]

      if (docFilter) {
        Object.assign(params, {...docFilter})
      }
      
      if(payload.versicherungId){
        Object.assign(params, {versicherungId: payload.versicherungId})
      }

      if(payload.schadenId) {
        Object.assign(params, {schadenId: payload.schadenId})
      }

      if (payload.linkVertrag) {
        Object.assign(params, {linkVertrag: payload.linkVertrag})
      }
    }
    
    if(payload.useOriginalFileName) {
      Object.assign(params, {useOriginalFileName: payload.useOriginalFileName})
    }
    
    if (!payload.keepOldPages) {
      commit(DOKUMENTENARCHIV_TYPES.MUTATIONS.SET_ROW_SELECTION, []);
    }

    await axios.get(rootState.core.apiAddress + serviceUrl, {...config, params}).then(response => {
      if (response && response.data) {
        commit(DOKUMENTENARCHIV_TYPES.MUTATIONS.RETRIEVE_DOCUMENTS_SUCCESS, { node: response.data, page: params.offset || 0, versicherungId: payload.versicherungId, keepOldPages: payload.keepOldPages });
        commit(DOKUMENTENARCHIV_TYPES.MUTATIONS.UPDATE_LOADING_STATUS, response.data);
      }      
    })
  }, 

  async [DOKUMENTENARCHIV_TYPES.ACTIONS.DOKUMENTE_HINZUFUGEN_UPLOAD]({ commit, state, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true,
      headers: {'Content-Type': 'application/json'},
    };
    const baseUrl = '/dokumentenarchiv/dokumenteHinzufugenUpload';

    await axios.post(rootState.core.apiAddress + baseUrl, payload, config).then(response => {
      if (response) {
        dispatch(DOKUMENTENARCHIV_TYPES.ACTIONS.RETRIEVE_ADDITIONAL_DOCUMENTS, { nodeId: payload.parentId, fileId: encodeURIComponent(payload.documentString)})
      }      
    })
  },   

  async [DOKUMENTENARCHIV_TYPES.ACTIONS.RETRIEVE_ADDITIONAL_DOCUMENTS]({ commit, state, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true,
      headers: {'Content-Type': 'application/json'},
    };

    let serviceUrl = '/dokumentenarchiv/additionalDocuments';

    if (payload) {
      const nodeId = payload.nodeId;

      if (nodeId) {
        serviceUrl = `${serviceUrl}?nodeId=${nodeId}`;
      } else {
         return; 
      }

      const fileId = payload.fileId;

      if (fileId) {
        serviceUrl = `${serviceUrl}&fileId=${fileId}`;
      }

      const showDeleted = payload.showDeleted;

      if (showDeleted) {
        serviceUrl = `${serviceUrl}&showDeleted=${showDeleted}`;
      }
    }

    return await axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
      if (response && response.data) {

        commit(DOKUMENTENARCHIV_TYPES.MUTATIONS.RETRIEVE_ADDITIONAL_DOCUMENTS_SUCCESS, response.data);

      }      

    })

  },

  [DOKUMENTENARCHIV_TYPES.ACTIONS.EXECUTE_HEADER_ACTION]({ commit, dispatch, rootState, getters }, payload) {

    const config = {
        defaultSpinner: true
    };

    const nodeId = payload.nodeId;

    if (!nodeId) {
        return; 
    }

    const headerAction = payload.headerAction;
    const documentId = payload.documentIds
    const docFilter = getters[DOKUMENTENARCHIV_TYPES.GETTERS.DOCUMENTS_FILTER]
    const isSelectedAllInFolder = getters[DOKUMENTENARCHIV_TYPES.GETTERS.IS_SELECTED_ALL_IN_FOLDER]
    
    const params = makeQueryParam({ ...docFilter, isSelectedAllInFolder, documentId, headerAction, nodeId}, true);

    axios.get(rootState.core.apiAddress + `/dokumentenarchiv/executeHeaderAction?${params}`, config)
    .then(response => {
        if (response.data.fehlerTooltip != null) {
            // an error occured
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.fehlerTooltip, 'danger'));
        } else {
          commit(DOKUMENTENARCHIV_TYPES.MUTATIONS.SET_ROW_SELECTION, []);
          commit(DOKUMENTENARCHIV_TYPES.MUTATIONS.SET_SELECTED_ALL_IN_FOLDER, false)
        
          if (payload.headerAction == 'MARKIERTE_LOSCHEN') {
            dispatch(OPEN_SIGNS_TYPES.ACTIONS.GET_OPEN_SIGNS_COUNT)
            dispatch(DOKUMENTENARCHIV_TYPES.ACTIONS.RETRIEVE_DOCUMENTS, {nodeId, offset: 0, limit: 20, versicherungId: payload.versicherungId, reloadTree: true});
          }
        }
    })
  },  

  async [DOKUMENTENARCHIV_TYPES.ACTIONS.GET_FILE_DATA]({ commit, state, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    let serviceUrl = '/documents/docInfo';   


    if (payload) {
      const nodeId = payload.nodeId;
      const documentId = payload.id;

      if (nodeId && documentId) {
        serviceUrl = `${serviceUrl}?docInfo=${nodeId}&docInfo=${documentId}`;
      } else {
         return; 
      }

    }     

    const response = await axios.get(rootState.core.apiAddress + serviceUrl, config);

    if (response && response.data) {
      return response.data;
    }
  },  

  async [DOKUMENTENARCHIV_TYPES.ACTIONS.DOWNLOAD_ATTACHMENT]({ getters }, { paramDisplayPDFData }) {
    const config = {
      defaultSpinner: true
    };

    if (paramDisplayPDFData) {
      const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/documents/get_download_file`, {
        params: {
          paramDisplayPDFData: paramDisplayPDFData
        }
      }, config)

      if (response.data) {
        return response.data
      }
    }
  },  

  async [DOKUMENTENARCHIV_TYPES.ACTIONS.ADDITIONAL_DOCUMENTS_ACTIONS]({ getters }, { nodeId, fileId, data, }) {
    if(!nodeId || !fileId || !data?.sendData || !data?.legend) return;

    const config = { 
      headers: {'Content-Type': 'application/json'}, 
      defaultSpinner: true, 
    };

    return await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/dokumentenarchiv/additionalDocumentsActions?nodeId=${nodeId}&fileId=${fileId}`, data, config);
  },

  [DOKUMENTENARCHIV_TYPES.ACTIONS.EXECUTE_EDIT_FILE]({ dispatch, getters }, {fileId, action}) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API}/dokumentenarchiv/executeEditFile?fileId=${fileId}`,
        action, {
        headers: {'Content-Type': 'application/json'},
        defaultSpinner: true
      }).then(response => {
          dispatch(OTHER_PRODUCTS_TYPES.ACTIONS.FIND_DOCUMENTS);
          resolve(response);
      }, (error) => reject(error));
    });
  },  

  async [DOKUMENTENARCHIV_TYPES.ACTIONS.RELOAD_SELECTED_NODE_TREE]({ getters, dispatch, commit, }) {
    const selectedNode = getters[DOKUMENTENARCHIV_TYPES.GETTERS.DOCUMENTS];
    const data = await dispatch(DOKUMENTENARCHIV_TYPES.ACTIONS.RETRIEVE_NODE_TREE, { nodeId: selectedNode.nodeId, });
    commit(DOKUMENTENARCHIV_TYPES.MUTATIONS.UPDATE_SELECTED_NODE_TREE, { ...data, });

    // reset virtual number of records
    const resetVirtualNumberOfRecords = node => {
      commit(DOKUMENTENARCHIV_TYPES.MUTATIONS.RESET_VIRTUAL_NUMBER_OF_RECORDS, node);
      node.nodes?.forEach(resetVirtualNumberOfRecords);
    };
    [ data ].forEach(resetVirtualNumberOfRecords);
  },

  async [DOKUMENTENARCHIV_TYPES.ACTIONS.SAVE_PRIVATE_DOK_NODE]({ getters, dispatch, }, payload) {
    const config = {
      defaultSpinner: true
    };
    await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/dokumentenarchiv/privateDokNode`, payload, config);
    dispatch(DOKUMENTENARCHIV_TYPES.ACTIONS.RELOAD_SELECTED_NODE_TREE);
  },

  async [DOKUMENTENARCHIV_TYPES.ACTIONS.UPDATE_PRIVATE_DOK_NODE]({ getters, dispatch, }, { nodeId, showForKunde }) {
    if(!nodeId) return;

    const config = {
      defaultSpinner: true
    };
    await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/dokumentenarchiv/privateDokNode/${nodeId}?showForKunde=${showForKunde}`, {}, config);
    dispatch(DOKUMENTENARCHIV_TYPES.ACTIONS.RELOAD_SELECTED_NODE_TREE);
  },

  async [DOKUMENTENARCHIV_TYPES.ACTIONS.GET_PRIVATE_DOK_NODE]({ getters, }, { nodeId, }) {
    const config = {
      defaultSpinner: true
    };
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/dokumentenarchiv/privateDokNode/${nodeId}`, config);
    return response?.data || {};
  },

  async [DOKUMENTENARCHIV_TYPES.ACTIONS.DELETE_PRIVATE_DOK_NODE]({ getters, dispatch, }, { nodeId }) {
    const config = {
      defaultSpinner: true
    };
    await axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/dokumentenarchiv/privateDokNode/${nodeId}`, config);
    dispatch(DOKUMENTENARCHIV_TYPES.ACTIONS.RELOAD_SELECTED_NODE_TREE);
  },

  async [DOKUMENTENARCHIV_TYPES.ACTIONS.DROP_ACTION]({ getters, state, commit, }, { sourceNodeId, targetNodeId, fileId, }) {
    if(!sourceNodeId || !targetNodeId || !fileId) return;

    try {
      Vue.set(state, 'dropActionActive', true);
      const config = { defaultSpinner: true, };
      const params = makeQueryParam({ sourceNodeId, targetNodeId, fileId, }, true);
      await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/dokumentenarchiv/dropAction?${params}`, {}, config);

      commit(DOKUMENTENARCHIV_TYPES.MUTATIONS.ADD_VIRTUAL_NUMBER_OF_RECORDS, { nodeId: sourceNodeId, value: -1, }); // decrement source node number of records
      commit(DOKUMENTENARCHIV_TYPES.MUTATIONS.ADD_VIRTUAL_NUMBER_OF_RECORDS, { nodeId: targetNodeId, value: 1, }); // increment target node number of records
    } finally {
      Vue.set(state, 'dropActionActive', false);
    }
  },

  [DOKUMENTENARCHIV_TYPES.ACTIONS.GET_PROHYP_DOCUMENTS]({ commit }, {fileId, nodeId, documentId}) {
    return new Promise((resolve, reject) => {
      const params = makeQueryParam({ fileId, docInfo: [nodeId, documentId], });

      axios.get(`${process.env.VUE_APP_API}/dokumentenarchiv/getEhypDokumente?${params}`, { defaultSpinner: true}).then(response => {
        if (response && response.data) {
          commit(DOKUMENTENARCHIV_TYPES.MUTATIONS.GET_PROHYP_DOCUMENTS_SUCCESS, response.data);
          resolve();
        } else {
          reject()
        }
      }).catch(() => reject())
    });
  },

  [DOKUMENTENARCHIV_TYPES.ACTIONS.EXECUTE_PROHYP_UPLOAD]({}, {fileId, ehypForDocumentId, nodeId, documentId}) {
    return new Promise((resolve, reject) => {
      const params = makeQueryParam({ ehypForDocumentId, fileId, docInfo: [nodeId, documentId], });

      axios.post(`${process.env.VUE_APP_API}/dokumentenarchiv/uploadToEhyp?${params}`, { defaultSpinner: true}).then(() => {
        resolve();
      }).catch(() => reject())
    });
  },

}