<template>
  <div>
    <PageHeaderTitleNavigation 
      title="Aktivitäten" 
      :actions="headerActions"
      @action-NEW_ACTIVITY="newActivity"
    />

    <BaseFilter
      v-if="defaultOptions"
      title="Aktivitäten Filter"
      filterId="PostfachActivityFilter"
      :isCache="true"
      :configFilter="filterConfig"
      :metadata="metadata"
      :defaultOptions="defaultOptions"
      showSaveButton
      hasSmartSearch
      immidiateSearch
      @onFilter="onFilter"
    />

    <div class="box__container">
      <GhostLoading v-if="loading && !scrollLoading" type="table" :config="{ table: { rows: 10 } }"/>
      <PaginatedTable
          v-else
          title="Aktivitäten"
          tableId="015eb9cf-6e4f-4dca-b9e3-52f0b49736bd"
          rowId="rowid"
          v-model="selectedRows"
          :headers="headers"
          :page="selectedPage"
          :pages="pages"
          :pageCount="pageCount"
          :rowCount="rowCount"
          :selectionAsBlacklist="invertSelection"
          @selectionAsBlacklist="invertSelection = $event"
          @page="selectedPage = $event"
          @requestPage="loadPage"
          @sort="onSearchSort"
          :sorted="searchSort"
          :headerActions="tableHeaderActions"
          :exportConfig="exportConfig"
          :mobileConfig="mobileConfig"
          @onScroll="onScroll"
          @scrollLoading="scrollLoading = $event"
          @click-subject="clickBetreff"
          @click-bereich="clickBereich"
          @action-DELETE="deleteRow"
          @action-DONE="doneRow"
          @headerAction-BULK_DELETE="deleteSelected"
          @headerAction-BULK_POSTPONE="openPostponeModal"
          @headerAction-BULK_DONE="doneSelected"
        >
          <template v-slot:participants="row">
            <ul style="padding: 0; margin: 0">
              <li style="display:inline-block;" v-for="(participant, index) in row.participants" :key="participant.id">
                <a v-if="participant.isKunde && canOpenCustomer(participant)" @click="openCustomerNewTab(participant.nummer)">{{participant.name}}</a>
                <span v-else>{{participant.name}}</span>
                <span v-if="index < row.participants.length - 1">;&nbsp;</span>
              </li>
            </ul>
          </template>
        </PaginatedTable>
    </div>

    <BaseModal
      ref="postponeModal"
      modalTitle="Markierte Aktivitäten verschieben auf"
      labelButtonConfirm="Verschieben"
      :confirmDisabled="!postponeDate"
      @onConfirmButton="postponeActivities"
    >
      <DatePickerField 
        v-model="postponeDate"
        isComponentHalfSize
        isValueAsString
        :renderDanger="!postponeDate"
      />
      <div class="fc-form-danger" v-if="isDateBeforeNow(postponeDate)">Die Aktivitäten liegen in der Vergangenheit und werden nicht versendet</div>
    </BaseModal>
  </div>
</template>

<script>
import CALENDAR_TYPES from "@/store/calendar/types";
import COMMUNICATION_TYPES from '@/store/communication/types';
import CORE_TYPES from '@/store/core/types'
import LOG_TYPES from '@/store/log/types';

import PaginatedTable from '@/components/table2/PaginatedTable.vue';
import {TextColumn, SlotColumn, ActionColumn, ConfirmedAction, DateColumn, CurrencyColumn, PillColumn} from "@/components/table2/table_util.js";
import BaseModal from '@/components/core/BaseModal.vue';
import postfachTableMixin from '@/views/communication/postfach/tables/postfach-table-mixin.js';
import axios from 'axios';
import dayjs from 'dayjs';
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';

import { buildMessage } from "@/helpers/log-message-helper";
import BaseFilter from "@/components/core/BaseFilter.vue";
import { mapGetters } from "vuex";
import { PageHeaderSimpleAction, PageHeaderConfirmedAction} from '@/components/core/header-title-navigation/page-header-utils';
import { makeQueryParam } from '@/helpers/utils-helper';
import { DatePickerUtils } from '@/components/core/forms/DatePicker/date-picker-utils';

const LINK_ACTIONS = ['VersVertrag', 'Kunde', 'Makler']
const ROWS_PER_PAGE_DEFAULT = 20
const config = { defaultSpinner: true }

export default {
  mixins: [postfachTableMixin],
  components: {
    BaseModal,
    BaseFilter,
    DatePickerField,
    PaginatedTable,
  },
  data() {
    return {
      category: 'termin',
      mailboxLabel: 'Termin',
      defaultOptions: null,
      filterConfig: {
        placeholderForDefSearchInput: 'Betreff, Besitzer, Teilnehmer',
        noResetOnDefaultSearchInputExit: true,
        checkDefaultSearchInput: false,
        hideFirstColumn: true,
      },
      postponeDate: null,
      selectedPage: 0,
      searchSort: {},
      invertSelection: false,
      pageList: {},
      rowCount: 0,
      ROWS_PER_PAGE_DEFAULT,
      mobileConfig: {
          title: 'subject',
          headers: ['name', 'bereich', 'date', 'status']
      },
      onScrollEnd: () => {},
      scrollLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      postfachDefaultFilters: COMMUNICATION_TYPES.GETTERS.POSTFACH_DEFAULT_FILTERS,
      getAppointmentCombos: CALENDAR_TYPES.GETTERS.GET_APPOINTMENT_COMBOS,
      isCustomerOnly: CORE_TYPES.GETTERS.IS_CUSTOMER_ONLY,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      fullname: CORE_TYPES.GETTERS.GET_USER_FULL_NAME,
    }),
    headers() {
      // IMPORTANT note: The keys of the headers are used to compare in PostfachTerminService.getComparatorBySortKey
      return {
        lockedLeft: [
          DateColumn("date", "Datum"),
          PillColumn("wvStatus", ""),
          TextColumn("subject", "Betreff").makeLink(),
        ],
        center: [
          TextColumn("name", "Besitzer", 250),
          SlotColumn("participants", "Teilnehmer", 80, 1),
          TextColumn("bereich", "Bereich", 400).makeConditionalLink("hasOpenAction").addCellProps({lineClamp: 2}),
          TextColumn("text", "Text", 400).addCellProps({lineClamp: 4}),
          TextColumn("art", "Art"),
          TextColumn("telefon", "Telefon"),
          TextColumn("mobil", "Mobil"),
          DateColumn("dateCreated", "Erstellungsdatum"),
          CurrencyColumn("geschaeftwert", "Geschätzter Geschäftswert"),
          TextColumn("produkt", "Produkt"),
          TextColumn("verkaufphase", "Verkaufphase"),
          TextColumn("status", "Status"),
        ],
        lockedRight: [
          ActionColumn("actions", "Aktionen"),
        ]
      };
    },
    pages() {
      const result = {};
      Object.entries(this.pageList).forEach(([page, records]) => {
        result[page] = records.map(record => ({
        ...record,
        wvStatus: this.getWVStatus(record.wvStatus),
        hasOpenAction: !this.isCustomerOnly && Object.keys(record.bereichOeffnen || {}).length && LINK_ACTIONS.includes(record.bereichOeffnen.bereich),
        rowid: ('' + record.commId + record.date),
        subject: record.subject || "[kein Betreff]",
        actions: this.makeActions(record)
        }))
      })
      return result;
    },
    pageCount() {
      return Math.ceil(this.rowCount / ROWS_PER_PAGE_DEFAULT);
    },
    metadata() {
      const statuses = this.getAppointmentCombos?.wvStatusFilter || [];
      const arten = {
        ...(this.getAppointmentCombos?.artComboWV || []),
        KEINE_ANGABEN: "ohne Angabe"
      }
      const result = [
        {
          type: 'group',
          key: 'allgemein',
          label: 'Allgemein',
          menuItems: [
            {
              type: 'text',
              label: 'Betreff',
              key: 'subject',
            },
            {
              type: 'default',
              label: 'nur mich als Besitzer',
              key: 'isOwner',
            },
            {
              type: 'default',
              label: 'nur zukünftige Aktivitäten',
              key: 'isFuture',
            },
            {
              type: 'text',
              label: 'Besitzer',
              key: 'owner',
            },
            {
              type: 'text',
              label: 'Teilnehmer',
              key: 'participant',
            },
            {
              type: 'dateRange',
              label: 'Zeitraum',
              key: 'zeitraum',
            },
            {
              type: 'datepicker',
              label: 'Datum',
              key: 'datum',
            },
            {
              type: 'combobox',
              label: 'Status',
              key: 'status',
              comboOptions: statuses,
            },
            {
              type: 'combobox',
              label: 'Art',
              key: 'art',
              comboOptions: Object.entries(arten).filter(([key, value]) => !!value).map(([key, value]) => ({
                label: value,
                value: key,
              })),
            },
          ],
        },
      ];
      return result;
    },
    headerActions() {
      return [
        PageHeaderSimpleAction('NEW_ACTIVITY', 'Neue Aktivität'),
      ];
    },
    tableHeaderActions() {
      return [
        PageHeaderConfirmedAction('BULK_DELETE', 'Markierte löschen', 'Sollen die markierten Einträge wirklich gelöscht werden?', 'Markierte löschen', 'Löschen')
        .withDisabled(() => !this.isSomeRowSelected),
        PageHeaderSimpleAction('BULK_POSTPONE', 'Markierte verschieben')
        .withDisabled(() => !this.isSomeRowSelected),
        PageHeaderSimpleAction('BULK_DONE', 'Markierte als Erledigt markieren')
        .withDisabled(() => !this.isSomeRowSelected),
      ];
    },
    isSomeRowSelected() {
      return this.invertSelection ? this.rowCount !== this.selectedRows.length : this.selectedRows.length
    },
    exportConfig() { return {
      roottext: 'Postfach',
      dispatch: this.exportAsFile,
    }},
    exportableHeaders() {
        return Object.values(this.headers).flat().filter(header => header.exportFn != null && header.visible );
    },
  },
  mounted() {
    this.$store.dispatch(CALENDAR_TYPES.ACTIONS.RETRIEVE_APPOINTMENT_COMBOS);

    const { fromDate, toDate, } = this.postfachDefaultFilters;

    this.defaultOptions = {
      zeitraum: {
        value: [{
          key: "min",
          value: fromDate,
        },
        {
          key: "max",
          value: toDate,
        }],
      },
    };
  },
  methods: {
    loadPage(event){
      this.selectedPage = event;
      this.doSearch()
    },
    onScroll(onScrollEnd) {
      this.onScrollEnd = onScrollEnd
      this.loadPage(this.selectedPage + 1)
    },
    onSearchSort(searchSort) {
      this.searchSort = searchSort || {}
      this.pageList = {}
      this.loadPage(0);
    },
    onFilter(filterConfig) {
        let params = {};
  
        filterConfig.forEach(fc => {
            let value = fc.value;
            if (fc.key == 'zeitraum') {
                params.fromDate = DatePickerUtils.toDateStr(value.find(v => v.key == 'min').value);
                params.toDate = DatePickerUtils.toDateStr(value.find(v => v.key == 'max').value);
            } else if (fc.key == 'datum') {
                params.fromDate = DatePickerUtils.toDateStr(value);
                params.toDate = DatePickerUtils.toDateStr(value);
            } else {
                params[fc.key] = value;
            }
        });
  
        this.filterParams = params
        this.pageList = {}
        this.loadPage(0);
    },
    doSearch(urlParams = this.filterParams, page = this.selectedPage) {
      this.loading = true;

      const filterParams = {
        ...urlParams,
        fromDate: urlParams.fromDate || dayjs(this.postfachDefaultFilters.fromDate).format('DD.MM.YYYY'),
        toDate: urlParams.toDate || dayjs(this.postfachDefaultFilters.toDate).format('DD.MM.YYYY'),
      }

      const params = makeQueryParam({ limit: ROWS_PER_PAGE_DEFAULT || 5, sortKey: this.searchSort.key, sortAscending: this.searchSort.sortDirection === 'asc' })

      axios.post(`${process.env.VUE_APP_API}/postfachTermin/list/aktivitaeten?page=${page}&${params}`, filterParams, config)
      .then(response => {
        this.$set(this.pageList, page, response.data.records)
        this.rowCount = response.data.rowCount
      })
      .finally(() => {
        this.invertSelection = false,
        this.selectedRows = []
        this.loading = false
        this.onScrollEnd()
      })
    },
    async getSelectedIds(urlParams = this.filterParams) {
      const filterParams = {
        ...urlParams,
        fromDate: urlParams.fromDate || dayjs(this.postfachDefaultFilters.fromDate).format('DD.MM.YYYY'),
        toDate: urlParams.toDate || dayjs(this.postfachDefaultFilters.toDate).format('DD.MM.YYYY'),
      }

      const selectedIds = this.selectedRows.map(row => row.commId)

      if (!this.invertSelection) {
        return selectedIds
      }

      const params = makeQueryParam({ selectedIds })

      const response = await axios.post(`${process.env.VUE_APP_API}/postfachTermin/listIds/aktivitaeten?${params}`, filterParams, config)
      return response.data || []
    },
    isDateBeforeNow(stringDate) {
      if (!stringDate)
        return false

      let nowDateString = dayjs().format('DD.MM.YYYY')
      let nowDate = dayjs(nowDateString, 'DD.MM.YYYY', true)
      let date= dayjs(stringDate, 'DD.MM.YYYY', true)
      return date.isBefore(nowDate)
    },
    makeActions(row) {
      return [
        ConfirmedAction("DONE", 'PhCheck', "Erledigt", `Soll die Aktivität "${row.subject || "[kein Betreff]"}" wirklich als erledigt markiert werden?`, `Aktivität als Erledigt markieren`, "Erledigt"),
        ConfirmedAction("DELETE", 'PhTrash', "Löschen", `Soll die Aktivität "${row.subject || "[kein Betreff]"}" wirklich gelöscht werden?`, `Aktivität löschen`, "Löschen")
      ]
    },
    clickBetreff(row) {
      if (row.commId) {
        this.$store.dispatch(CALENDAR_TYPES.ACTIONS.RETRIEVE_SELECTED_APPOINTMENT, row.commId);
        this.openAppointment();
      }
    },
    clickBereich(row) {
      const action = row.bereichOeffnen

      if (action) {
        switch(action.bereich) {
          case 'VersVertrag':
            this.openInsurance(action)
            return;
          case 'Kunde':
            this.openCustomerNewTab(action.bereichId)
            return;
          case 'Makler':
            this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_BROKER_NEW_TAB, { brokerId: action.bereichId })
            return;
          default:
            return;
        }
      }
    },
    openAppointment() {
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_CONFIG, {
        title: 'Aktivität bearbeiten',
        isActivity: true,
        selectedDate: null,
  
        back: this.$router.currentRoute.fullPath,
      })
      this.$router.push({ path: `/communication/appointment` });
    },
    openInsurance(action) {
      if(this.isBroker || this.isIntern) {
        this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, 
          { customerId: action.customerId, insurances: true, path: `/home/versicherungen/insurance-group/details/${action.bereichId}` });
      } else {
        this.$router.push(`/home/versicherungen/insurance-group/details/${action.bereichId}`);
      }
    },
    newActivity() {
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT, {activity: true});
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_NEW_ACTIVITY);
      this.openAppointment();
    },
    async deleteSelected() {
      const selectedIds = await this.getSelectedIds()

      const payload = selectedIds.map(id => ({
          id,
          category: this.mailboxLabel
      }));

      axios.post(`${process.env.VUE_APP_API}/postfach/deleteList`, payload, config)
      .then(() => {
        this.invertSelection = false,
        this.selectedRows = []
        this.loadPage(0);
      })
    },
    openPostponeModal() {
      this.postponeDate = null
      this.$refs.postponeModal.open()
    },
    async postponeActivities() {
      this.loading = true

      const selectedIds = await this.getSelectedIds()
      const params = makeQueryParam({ ids: selectedIds, date: this.postponeDate})

      axios.post(`${process.env.VUE_APP_API}/postfachTermin/postpone?${params}`, {}, config)
      .then(() => {
        this.invertSelection = false,
        this.selectedRows = []
        this.loadPage(0);
      })
      .catch(() => this.loading = false)
    },
    doneRow(data) {
      const params = makeQueryParam({ status: "ERLEDIGT" })

      axios.post(`${process.env.VUE_APP_API}/calendarService/setStatus/${data.commId}?${params}`, { status: "ERLEDIGT" }, {defaultSpinner: true})
      .then(() => {
        this.loadPage(this.selectedPage)
      }).catch((error) => {
        let msg = error?.data || 'Es ist ein unerwarteter Fehler aufgetreten.';
        if(error?.response?.status == 400 || error?.response?.status == 403 ){
            msg = error?.response?.data
        }                
        this.$store.dispatch(LOG_TYPES.ACTIONS.ERROR, buildMessage(msg, 'danger'));
      })
    },
    async doneSelected() {
      const selectedIds = await this.getSelectedIds()


      axios.post(`${process.env.VUE_APP_API}/calendarService/setStatusList`, { ids: selectedIds, status: "ERLEDIGT"}, config)
      .then(() => {
        this.invertSelection = false,
        this.selectedRows = []
        this.loadPage(0);
      })
    },
    getWVStatus(value) {
      switch (value) {
        case 'green':
          return {
            label: 'erledigt', 
            type: 'success',
          }
        case 'blue':
          return {
            label: 'wird versendet', 
            type: 'info',
          }
        case 'yellow':
          return {
            label: 'Handlung notwendig', 
            type: 'warning',
          };
        default:
          return '';
      }
    },
    exportAsFile(fileEnding) {
      const filterParams = {
        ...this.filterParams,
        fromDate: this.filterParams.fromDate || dayjs(this.postfachDefaultFilters.fromDate).format('DD.MM.YYYY'),
        toDate: this.filterParams.toDate || dayjs(this.postfachDefaultFilters.toDate).format('DD.MM.YYYY'),
      }

      return axios.post(`${process.env.VUE_APP_API}/postfachTermin/list/aktivitaeten?${fileEnding}`, filterParams, {defaultSpinner: true})
      .then(response => {
        {
          const columns = this.exportableHeaders.map(header => header.label);
          const rows = response.data.records.map(row => {
            return this.exportableHeaders.map(header => {
              return header.exportFn(row[header.key], row, header) || '';
            });
          });
          const params = {
              create: fileEnding,
              filename: this.exportConfig.roottext + ' ' + dayjs(new Date()).format('DD-MMM-YYYY HH mm'),
              title: this.exportConfig.roottext + ' - ' + this.fullname,
              columns,
              rows,
              printMaklerLogo: '0',
          };
          return this.$store.dispatch(CORE_TYPES.ACTIONS.CREATE_XLS_OR_PDF, params);
        }
      })
    },
  },
}
</script>