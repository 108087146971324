<template>
  <div class="input-forms__container">
    <div class="input-forms__label-container">
      <div class="input-forms__input-container">
        <BaseButton
          :disabled="disabled"
          :isComponentHalfSize="isComponentHalfSize"
          @click="applyToStructure()"
          isSecondary
        >
          <span>{{ label }}</span>
          <AnimatedSpinner v-if="loading" />
        </BaseButton>
      </div>
    </div>
    <BaseModal ref="infoModal" :showDefaultButtons="false">
      <template v-slot:modalTitle> Korrekturen gesetzt </template>
      <div>
        <span>Daten wurden erfolgreich angepasst!</span>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import BaseButton from "@/components/core/BaseButton.vue";
import AnimatedSpinner from "@/components/core/AnimatedSpinner.vue";
import BROKERDATA_TYPES from "@/store/brokerData/types";
import { mapGetters } from "vuex";
import BaseModal from "@/components/core/BaseModal.vue";
import CONFIG_DEFINITION_TYPES from '@/store/configDefinition/types'

export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    isComponentHalfSize: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      brokerData: BROKERDATA_TYPES.GETTERS.GET_BROKER_DATA,
    }),
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async applyToStructure() {
      this.loading = true;
      //1. save config
      await this.$store.dispatch(CONFIG_DEFINITION_TYPES.ACTIONS.SAVE_CHANGED_CONFIGS)
      //2. wait to save changed configs and apply to structure
      
      const response = await this.$store.dispatch(
        BROKERDATA_TYPES.ACTIONS.APPLY_SETTINGS_VERSANDINFO
      );
      if (response && response.status == 200) {
        this.loading = false;
        this.$refs.infoModal.open();
      }
    },
  },
  components: {
    BaseButton,
    AnimatedSpinner,
    BaseModal,
  },
};
</script>
