import LOG_TYPES from '@/store/log/types.js';
import CORE_TYPES from '@/store/core/types.js';
import WEBRTC_TYPES from '@/store/webrtc/types.js';
import { Thrift } from '@/store/webrtc/utils/thrift'
import { MemoryTransport } from '@/store/webrtc/utils/thrift-non-generated'
import { AppNotificationKey, AppNotificationType } from '@/store/webrtc/utils/appNotificationCommon_types'
import { WebRtcCallStatus } from "@/store/webrtc/utils/webrtc_types.js";
import {
  MessageFromClientToSignaler,
  MessageFromSignalerToClient,
  WebSocketCloseReasonPhrase,
  SwspPayload,
  SignalingMessageId,
  SignalingMessageRegisterRequest,
  SignalingMessageCall,
  SignalingConnectionRole
} from "@/store/webrtc/utils/signalingService_types.js";

export const appNotificationKeyDict = Object.entries(AppNotificationKey)
  .reduce(reducerInvertKeyAndValue, {})

export const appNotificationTypeDict = Object.entries(AppNotificationType)
  .reduce(reducerInvertKeyAndValue, {})

export const SignalingMessageIdDict = Object.entries(SignalingMessageId)
  .reduce(reducerInvertKeyAndValue, {})

export const WebRtcCallStatusDict = Object.entries(WebRtcCallStatus)
  .reduce(reducerInvertKeyAndValue, {})

// ---------- local functions ----------

function readThrift(data, tmsg) {
  const transport = new MemoryTransport(data)
  const protocol = new Thrift.Protocol(transport)
  protocol.initializeForReading()
  tmsg.read(protocol)
  return tmsg;
}

// ---------- exports functions ----------

export function createMessageToSignaler(callInfo, type) {
  return new MessageFromClientToSignaler({
    messageId: type,
    connectionRole: callInfo.connectionRole,
    dbPrefix: callInfo.dbPrefix,
    webRtcCallId: callInfo.callId,
    senderRtcUserId: callInfo.ownUUID,
  })
}

export function writeThrift(tmsg) {
  const transport = new MemoryTransport()
  const protocol = new Thrift.Protocol(transport)
  tmsg.write(protocol)
  protocol.flush()
  let result = transport.readAll()
  return result
}

export function readThriftSwspPayload(data) {
  let tmsg = readThrift(data, new SwspPayload());
  return tmsg.values;
}

export function readThriftMessageFromSignalerToCLient(data) {
  let tmsg = readThrift(data, new MessageFromSignalerToClient());
  return tmsg;
}

export function readThriftWebSocketCloseReasonPhrase(data) {
  let tmsg = readThrift(data, new WebSocketCloseReasonPhrase());
  return tmsg;
}

export function logSocketCloseConnection(dispatch, event) {

  const causeList = {
    1000: { meaning: "Normal Closure ", detail: "The connection successfully completed the purpose for which it was created." },
    1001: { meaning: "Going Away ", detail: "The endpoint is going away, either because of a server failure or because the browser is navigating away from the page that opened the connection." },
    1002: { meaning: "Protocol error ", detail: "The endpoint is terminating the connection due to a protocol error." },
    1003: { meaning: "Unsupported Data ", detail: "The connection is being terminated because the endpoint received data of a type it cannot accept. (For example, a text-only endpoint received binary data.)" },
    1004: { meaning: "Reserved ", detail: "Reserved. A meaning might be defined in the future." },
    1005: { meaning: "No Status Rcvd ", detail: "Reserved. Indicates that no status code was provided even though one was expected." },
    1006: { meaning: "Abnormal Closure ", detail: "Reserved. Indicates that a connection was closed abnormally (that is, with no close frame being sent) when a status code is expected." },
    1007: { meaning: "Invalid frame payload data ", detail: "The endpoint is terminating the connection because a message was received that contained inconsistent data (e.g., non-UTF-8 data within a text message)." },
    1008: { meaning: "Policy Violation ", detail: "The endpoint is terminating the connection because it received a message that violates its policy. This is a generic status code, used when codes 1003 and 1009 are not suitable." },
    1009: { meaning: "Message Too Big ", detail: "The endpoint is terminating the connection because a data frame was received that is too large." },
    1010: { meaning: "Mandatory Ext. ", detail: "The client is terminating the connection because it expected the server to negotiate one or more extension, but the server didn't." },
    1011: { meaning: "Internal Error ", detail: "The server is terminating the connection because it encountered an unexpected condition that prevented it from fulfilling the request." },
    1012: { meaning: "Service Restart ", detail: "The server is terminating the connection because it is restarting." },
    1013: { meaning: "Try Again Later ", detail: "The server is terminating the connection due to a temporary condition, e.g. it is overloaded and is casting off some of its clients." },
    1014: { meaning: "Bad Gateway ", detail: "The server was acting as a gateway or proxy and received an invalid response from the upstream server. This is similar to 502 HTTP Status Code." },
    1015: { meaning: "TLS handshake ", detail: "Reserved. Indicates that the connection was closed due to a failure to perform a TLS handshake (e.g., the server certificate can't be verified). " },
  }

  const cause = causeList[event.code]

  const payload = {
    message: "pushSocket closed - temporary log", error: {
      cause: cause || 'cause out of range: code ' + event.code,
      eventData: {
        code: event.code,
        reason: event.reason,
        wasClean: event.wasClean,
      }
    }
  };

  dispatch(LOG_TYPES.ACTIONS.ERROR, payload)
}

export function createCallInfo(storeCtx, callId, connectionRole, ownUUID, otherUUID, calleeChatBeteiligterId, socketUrl, iceUrls, iceUsername, iceCredential) {
  const { getters, dispatch } = storeCtx;

  dispatch(LOG_TYPES.ACTIONS.INFO, "'createCallInfo' helper called");

  const callInfo = {
    socketBacklog: [],
    receivedStream: null,
    receivingAudio: true,
    receivingVideo: false,
    requestedVideo: false,
    callId,
    dbPrefix: getters[CORE_TYPES.GETTERS.GET_DB_PREFIX],
    connectionRole,
    ownUUID,
    otherUUID,
    calleeChatBeteiligterId,
    socket: null,
    socketUrl,
    iceUrls,
    iceUsername, 
    iceCredential,
    peerConnection: null,
    videoSender: null,
    get isCaller() {
      return this.connectionRole === SignalingConnectionRole.CALLER_WEBRTC_CALL_RECEIVER;
    }, 
    get isCallee() {
      return this.connectionRole === SignalingConnectionRole.CALLEE_WEBRTC_CALL_INITIATOR;
    },
  };

  return callInfo;
}

export function sendSignalingRegistrationMessage(callInfo, commit, dispatch, { withSdpOffer }) {
  let messageSdpOffer = new SignalingMessageCall({});

  if (withSdpOffer) {
    messageSdpOffer = new SignalingMessageCall({
      sdpOffer: callInfo.peerConnection.localDescription.sdp,
    })
  }

  let tmsg = new MessageFromClientToSignaler({
    messageId: SignalingMessageId.REGISTRATION,
    messageRegisterRequest: new SignalingMessageRegisterRequest({
      messageSdpOffer,
      correspondent: callInfo.otherUUID,
    }),
    connectionRole: callInfo.connectionRole,
    dbPrefix: callInfo.dbPrefix,
    webRtcCallId: callInfo.callId,
    senderRtcUserId: callInfo.ownUUID,
  })
  commit(WEBRTC_TYPES.MUTATIONS.SEND_SIGNAL_MESSAGE, { callId: callInfo.callId, message: writeThrift(tmsg) });
  dispatch(LOG_TYPES.ACTIONS.LOG, `send Signaling Registration Message: withSdpOffer: ${withSdpOffer}`);
}

export function translateAndLogPushSocketMessage(msg, dispatch) {
  const result = {}

  try {
    for (let [key, value] of Object.entries(msg)) {
      const newValue = parseInt(key) === AppNotificationKey.NOTIFICATION_TYPE ? `${value}: ${appNotificationTypeDict[value]}` : value;

      result[appNotificationKeyDict[key]] = newValue
    }
  } catch (error) {
    // empty block
  }

  dispatch(LOG_TYPES.ACTIONS.LOG, 'on pushSocket message (translated) received: ' + JSON.stringify(result, null, 2))

  return result
}

export function translateAndLogHandleSignalMessage(msg, dispatch) {
  const result = {
    ...msg,
    messageId: SignalingMessageIdDict[msg.messageId]
  }

  dispatch(LOG_TYPES.ACTIONS.LOG, 'on signaler message (translated) received: ' + JSON.stringify(result, null, 2))
}

export function reducerInvertKeyAndValue(acu, cur) {
  acu[cur[1]] = cur[0]
  return acu
}
