<template>
  <div>
    <PageHeaderTitleNavigation 
      title="Fax"
      :actions="headerActions"
      @action-NEW_FAX="sendNewFax"
    />

    <BaseFilter
      v-if="defaultOptions"
      title="Fax Filter"
      filterId="PostfachFaxFilter"
      :configFilter="filterConfig"
      :metadata="metadata"
      :defaultOptions="defaultOptions"
      showSaveButton
      hasSmartSearch
      immidiateSearch
      @onFilter="onFilter"
    />

    <div class="box__container">
      <Table 
        v-if="rows.length"
        :headers="headers"
        :rows="rows"
        :rowsPerPage="25"
        rowId="rowId"
        :exportConfig="{roottext: 'Postfach'}"
        :mobileConfig="{title: 'name', headers: ['subject', 'date', 'stat']}"
        @click-name="openCustomerNewTab($event.nummer)"
        @action-RESEND_FAX="resendFax"
      >
        <template v-slot:subject="row">
          <DownloadLink
            v-if="!row.mobileTableContext"
            :title="row.subject"
            :disabled="!row.commId"
            downloadServicePath="/downloadFax"
            :filename="row.subject.includes('.pdf') ? row.subject : `${row.subject}.pdf`"
            :queryParams="{
                id: row.commId,
            }"
          />
          <div v-else>{{row.subject}}</div>
        </template>
      </Table>
      <GhostLoading v-else-if="loading" type="table" :config="{ table: { rows: 5 } }"/>
      <NoData v-else/>
    </div>
  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import Table from "@/components/table2/Table.vue";
import { TextColumn, SlotColumn, DateTimeColumn, PillColumn, ConfirmedAction, DownloadLinkAction, ActionColumn } from "@/components/table2/table_util.js";
import DownloadLink from '@/components/core/download/DownloadLink.vue'

import postfachTableMixin from '@/views/communication/postfach/tables/postfach-table-mixin.js';
import axios from 'axios';
import { mapGetters } from "vuex";
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {
  mixins: [postfachTableMixin],
  components: {
    Table,
    DownloadLink,
  },
  data() {
    return {
      category: 'fax',
      mailboxLabel: 'Fax',
      filterConfig: {
        placeholderForDefSearchInput: 'Faxnummer',
        defaultSearchInputKeys: ['subject'],
        hideFirstColumn: true,
      },
    }
  },
  computed: {
    ...mapGetters({
      isBroker: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_BROKER,
    }),
    headers() {
        return {
            lockedLeft: [
              DateTimeColumn("date", "Datum", undefined, 1),
              SlotColumn("subject", "Betreff", undefined, 1),
            ],
            center: [
              TextColumn("name", "Name").makeConditionalLink('hasNummer').makeAlwaysVisible(),
              TextColumn("contact", "Kontakt"),
              PillColumn("stat", "Status", undefined, 1).addCellProps({textWrap: true}),
              PillColumn("typ", "Typ", undefined, 1),
            ],
            lockedRight: [
              ActionColumn("actions", "Aktionen"),
            ]
        };
    },
    rows() {
      return this.records.map(record => ({
        ...record,
        stat: {label: record.statusText, type: record.status},
        typ: {label: record.type, type: 'info'},
        rowId: (record.commId + this.category + record.date),
        subject: record.subject || "[kein Betreff]",
        hasNummer: this.canOpenCustomer(record),
        actions: this.makeActions(record),
        downloadLinkOptions: {
          downloadServicePath: "/downloadFax",
          filename: record.subject.includes('.pdf') ? record.subject : `${record.subject}.pdf`,
          queryParams: {
            id: record.commId,
          },
        },
      }))
    },
    metadata() {
      return [
        {
          type: 'group',
          key: 'allgemein',
          label: 'Allgemein',
          menuItems: [
            {
              type: 'text',
              label: 'Betreff',
              key: 'subject',
              emptyDenied: true,
            },
            {
              type: 'combobox',
              label: 'Postfach',
              key: 'postfach',
              comboOptions: [
                {
                  label: 'Eingang',
                  value: 'Eingang',
                },
                {
                  label: 'Ausgang',
                  value: 'Ausgang',
                },
              ],
            },
            {
              type: 'text',
              label: 'Name',
              key: 'name',
              emptyDenied: true,
            },
            {
              type: 'dateRange',
              label: 'Zeitraum',
              key: 'zeitraum',
              emptyDenied: true,
            },
            {
                type: 'datepicker',
                label: 'Datum',
                key: 'datum',
                emptyDenied: true,
            },
          ],
        },
      ]
    },
    headerActions() {
      return [
        PageHeaderSimpleAction('NEW_FAX', 'Neues Fax versenden').withVisible(() => this.isBroker),
      ];
    },
  },
  methods: {
    sendNewFax() {
      this.$router.push(`/communication/mailcomposer-fax-send`);
    },
    makeActions(row) {
      let actions = [
        DownloadLinkAction("FAX_REPORT", 'PhFilePdf', "Faxsendebericht", row.subject, "/faxReport", {id: row.commId}),
      ]

      if (row.erneutSenden) {
        actions.push(ConfirmedAction("RESEND_FAX", 'PhArrowUDownLeft', "Erneut senden", `Wollen Sie das Fax wirklich neu versenden?`, 'Fax Versand', "Erneut senden"))
      }

      return actions
    },
    resendFax(row) {
      if (row.commId) {
        axios.post(`${process.env.VUE_APP_API}/postfach/resendFax?id=${row.commId}`, undefined, {defaultSpinner: true})
          .then(() => this.doSearch(this.filterParams));
      }
    }
  },
}
</script>