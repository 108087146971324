var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", { attrs: { title: _vm.title } }),
      _c("SsoHinweise", { attrs: { isSsoKonto: _vm.loginName && true } }),
      _c("div", { staticClass: "cards__container" }, [
        _c("div", { class: _vm.getClass }, [_c("SsoKontoOverview")], 1),
        _vm.showSsoCustomerOverview
          ? _c("div", { class: _vm.getClass }, [_c("SsoCustomerOverview")], 1)
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }