var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "antrag-components__container" }, [
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("InputRadioBoxGroup", {
          attrs: {
            value: _vm.form.alle_WP,
            validateUntouched: true,
            values: [
              { value: true, label: "Bitte übertragen Sie das gesamte Depot" },
              {
                value: false,
                label: "Bitte übertragen Sie einzelne Wertpapiere:"
              }
            ]
          },
          on: {
            input: function($event) {
              return _vm.$emit("save", { alle_WP: $event })
            }
          }
        }),
        _c("FormDivider"),
        _c("InputRadioBoxGroup", {
          attrs: {
            value: _vm.form.uebertragungsArt,
            title: "Art des Übertrags",
            values: _vm.artValues
          },
          on: {
            input: function($event) {
              return _vm.$emit("save", { uebertragungsArt: $event })
            }
          }
        }),
        _c("FormDivider"),
        _c("InputCheckBoxGroup", {
          attrs: {
            title: "Übertrag der Verlustverrechnungstöpfe",
            values: [
              { label: "Aktien", path: "verlustuebertragenAktien" },
              { label: "Sonstige", path: "verlustuebertragen_sonstige" },
              {
                label: "ausländische Quellensteuer",
                path: "verlustuebertragenAuslaendisch"
              }
            ]
          },
          on: {
            checkBoxToggle: function($event) {
              return _vm.$emit("save", $event)
            }
          },
          model: {
            value: _vm.form,
            callback: function($$v) {
              _vm.form = $$v
            },
            expression: "form"
          }
        }),
        _c("FormDivider"),
        _c(
          "BaseButton",
          {
            staticClass: "col-12 mr-2",
            attrs: { isSecondary: true },
            on: {
              click: function($event) {
                return _vm.$emit("back")
              }
            }
          },
          [_vm._v("Zurück")]
        ),
        _c(
          "BaseButton",
          {
            staticClass: "col-12",
            on: {
              click: function($event) {
                return _vm.$emit("next")
              }
            }
          },
          [_vm._v("Weiter")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }