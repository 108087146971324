var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "antrag-components__container" }, [
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("FormLabel", {
          attrs: {
            label:
              "Bitte tragen Sie hier die Daten des Depots ein, von dem die Wertpapiere übertragen werden sollen.",
            config: { bold: true }
          }
        }),
        _c("FormDivider"),
        _c(
          "div",
          { staticClass: "row" },
          [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("FormLabel", {
                  attrs: {
                    config: { bold: true },
                    label: "Daten der bisher depotführenden Gesellschaft"
                  }
                })
              ],
              1
            ),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Name" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherManuellName: $event })
                }
              },
              model: {
                value: _vm.form.bisherManuellName,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherManuellName", $$v)
                },
                expression: "form.bisherManuellName"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Telefon", inputmode: "tel" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherManuellTelefon: $event })
                }
              },
              model: {
                value: _vm.form.bisherManuellTelefon,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherManuellTelefon", $$v)
                },
                expression: "form.bisherManuellTelefon"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Straße/Nr." },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherManuellStrasse: $event })
                }
              },
              model: {
                value: _vm.form.bisherManuellStrasse,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherManuellStrasse", $$v)
                },
                expression: "form.bisherManuellStrasse"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "PLZ Ort" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherManuellOrt: $event })
                }
              },
              model: {
                value: _vm.form.bisherManuellOrt,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherManuellOrt", $$v)
                },
                expression: "form.bisherManuellOrt"
              }
            })
          ],
          1
        ),
        _c("FormDivider"),
        _c(
          "div",
          { staticClass: "row" },
          [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("FormLabel", {
                  attrs: {
                    config: { bold: true },
                    label: "Daten Konto/Depotinhaber depotführenden Bank"
                  }
                })
              ],
              1
            ),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Depotnummer" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherDepotnr: $event })
                }
              },
              model: {
                value: _vm.form.bisherDepotnr,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherDepotnr", $$v)
                },
                expression: "form.bisherDepotnr"
              }
            }),
            _c("div", { staticClass: "col-12" }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Name" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", {
                    bisherManuellKontobezeichnung: $event
                  })
                }
              },
              model: {
                value: _vm.form.bisherManuellKontobezeichnung,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherManuellKontobezeichnung", $$v)
                },
                expression: "form.bisherManuellKontobezeichnung"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Straße/Nr" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", {
                    bisherManuellKontoStrasse: $event
                  })
                }
              },
              model: {
                value: _vm.form.bisherManuellKontoStrasse,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherManuellKontoStrasse", $$v)
                },
                expression: "form.bisherManuellKontoStrasse"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "PLZ/Ort" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherManuellKontoPLZOrt: $event })
                }
              },
              model: {
                value: _vm.form.bisherManuellKontoPLZOrt,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherManuellKontoPLZOrt", $$v)
                },
                expression: "form.bisherManuellKontoPLZOrt"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Land" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherManuellKontoLand: $event })
                }
              },
              model: {
                value: _vm.form.bisherManuellKontoLand,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherManuellKontoLand", $$v)
                },
                expression: "form.bisherManuellKontoLand"
              }
            })
          ],
          1
        ),
        _c(
          "BaseButton",
          {
            on: {
              click: function($event) {
                return _vm.$emit("next")
              }
            }
          },
          [_vm._v("Weiter")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }