import CALENDAR_SETTINGS_TYPES from './types';

export default {

  [CALENDAR_SETTINGS_TYPES.GETTERS.COMBO_DATA](state) {
    return { ...state.comboData || {}, };
  },

  [CALENDAR_SETTINGS_TYPES.GETTERS.INTERN_PERSONEN](state) {
    return [ ...state.internPersonen || [], ];
  },

  [CALENDAR_SETTINGS_TYPES.GETTERS.SETTINGS_DATA](state) {
    return { ...state.settingsData || {}, };
  },

  [CALENDAR_SETTINGS_TYPES.GETTERS.SETTINGS_DATA_EDITED](state) {
    return state.settingsDataEdited && { ...state.settingsDataEdited || {}, } || null;
  },

  [CALENDAR_SETTINGS_TYPES.GETTERS.FEIERTAGE_GRUPPEN_SPEZIAL](state) {
    return [ ...state?.feiertageGruppenSpezial || [], ];
  },

  [CALENDAR_SETTINGS_TYPES.GETTERS.FEIERTAGE_SPEZIAL](state) {
    return [ ...state?.feiertageSpezial || [], ];
  },

}
