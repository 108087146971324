
export default {
  data() {
    return {
      textEl: null,
    };
  },
  methods: {
    createOrGetTexEl() {
      if(!this.textEl) {
        const textEl = document.createElement('span');
        textEl.classList.add('vertical-stepper-item--title');
        textEl.textContent = this.$el.title;
        this.textEl = textEl;
      }

      if(this.isCurrent) {
        this.textEl.classList.add('vertical-stepper-item--title--selected');
      }

      return this.textEl;
    },
    removeTextEl() {
      const textEl = this.createOrGetTexEl();
      if(textEl && textEl.parentNode === document.body) {
        document.body.removeChild(textEl);
      }
    },
    handleMouseOver(event) {
      if(!this.collapsed || !this.$el?.title) {
        return ;
      }
      const bounding = this.$el.getBoundingClientRect();
      const textEl = this.createOrGetTexEl();
      textEl.style.transform = `translate(${bounding.x}px, ${bounding.y + bounding.height / 2 + window.scrollY}px)`;
      document.body.appendChild(textEl);
    },
    handleMouseLeave(event) {
      this.removeTextEl();
    },
  },
  beforeDestroy() {
    this.removeTextEl();
  },
}
