var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseButton",
    {
      class: {
        clickable: !_vm.isDisabled && !_vm.isLoading,
        disabled: _vm.isDisabled
      },
      attrs: {
        tid: _vm.tid,
        isSecondary: !_vm.isMainAction,
        disabled: _vm.isDisabled || _vm.isLoading
      },
      on: { click: _vm.onClick }
    },
    [
      _vm.iconComponent
        ? _c(_vm.iconComponent, { tag: "component", attrs: { size: _vm.size } })
        : _vm._e(),
      _vm.isLoading
        ? _c("AnimatedSpinner", { attrs: { size: _vm.size } })
        : _vm._e(),
      _vm._v(" " + _vm._s(_vm.responsiveLabel) + " ")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }