import store from '@/store';
import CORE_TYPES from '@/store/core/types'
import FC_CONFIG_TYPES from '@/store/fcConfig/types';
import { makeQueryParam, addParamToURL } from '@/helpers/utils-helper';
import  {PATH_CUSTOMER, PATH_BROKER}  from "@/mixins/wertpapierorder/mixin.js";
import router from '@/router';
import { openLink } from '@/link-resolvers';
import { ResolverId } from '@/link-resolvers/types';

import FC_CONFIG from '@/configs/fcConfig';

let FormUtil  = {};

  FormUtil.getLoadURL = (antrag, antragList, isCustomerLogin) => {
    let beratungsMappeId = antrag.beratungsMappeId
    let processId = antrag.processId
    let bezeichnung = antrag.bezeichnung
    let gesellschaft = antrag.gesellschaft
    let antragsdatenId = antrag.antragsdatenId
    let hideBeratungsmappepopup = null;

    let antragItem = null;
    let antragGroup = null;

    if (bezeichnung === "Wertpapierorder") {
      const path = isCustomerLogin ? PATH_CUSTOMER : PATH_BROKER;
      if (beratungsMappeId) {
        return `${path}main?bmOptChosen=true&beratungsMappeId=${beratungsMappeId}&antragsdatenId=${antragsdatenId}&initData=true&editPreviouslySaved=true`;
      }
      return `${path}main?antragsdatenId=${antragsdatenId}&initData=true&editPreviouslySaved=true`;
    }

    bezeichnung = updateOldBezeichnung(bezeichnung, antrag, gesellschaft)
    // If there is a gesellschaftID then we can find it in the antragList and find the antrag there
    
    if (gesellschaft) {
      if (gesellschaft === 'EFONDS24.DE') {
        bezeichnung = 'eZeichnung';
      }
      antragGroup = antragList.find(g => g.id === gesellschaft)

      if (antragGroup) {
        antragItem = antragGroup.children.find(c => isFormEqual(c, antrag))

        if (antragItem) {
          hideBeratungsmappepopup = antragItem.hideBeratungsmappepopup || antrag.hideBeratungsmappepopup
        }
      }
    }
    
    if (!antragGroup) {
      // sometimes the gesellschaft is null (for example in 'weitere Unterlagen')
      // in that case we just hope that the friendlyName is unique
      let anzahl = 0
      antragList.forEach((g) =>
        g.children.forEach((c) => {
          if (isFormEqual(c, antrag)) {
            hideBeratungsmappepopup = c.hideBeratungsmappepopup || antrag.hideBeratungsmappepopup;
            antragItem = c;
            antragGroup = g;
            anzahl++
          }
        })
      );
      if (anzahl > 1 && bezeichnung != 'Erweiterte Geldwäscheprüfung') {
        console.log('Error, multiple forms with name:', bezeichnung)
        return
      }
    }

    let path = '/beratung/formulare/antrag/'

    if (antrag.servicePath) {
      path += antrag.servicePath
    } else if (gesellschaft === 'EFONDS24.DE') {
      path += `ezeichnung`
    } else if (gesellschaft === 'FODB' && bezeichnung === 'FodB Order StrategieInvestment') {
      path += `FODB/orderstrategie`; 
    } else if (antragItem) {
      path += `${antragGroup.path}/${antragItem.path}`
    } else if (bezeichnung === "Vermittlerwechsel") {
      path += `FK/vermittlerwechsel`
    } else {
      return
    }

    const params = makeQueryParam({
      dynamicFormId: antragItem?.dynamicFormId,
      hideBeratungsmappepopup, antragsdatenId,
      beratungsMappeId,
      processId,
      selectStep: antrag.selectStep,
    })
    return `${path}?${params}`
  };

  FormUtil.getForms = (selectedLagerstelle, parameters = {}) => {
    const userRoles = store.getters[CORE_TYPES.GETTERS.GET_USER_ROLES];

    return [
      ...(selectedLagerstelle?.children || [])
        .filter(form => checkFormRoles(form, userRoles))
        .map(form => mapFormLink(selectedLagerstelle, form, parameters)),
    ];
  };

  function isFormEqual(c, antrag) {

    let bezeichnung = antrag.bezeichnung
    let antragModus = antrag.antragModus
    return (c.friendlyName && c.friendlyName === bezeichnung || c.label === bezeichnung) && (!c.antragModus || !antragModus || c.antragModus === antragModus);
  }

  function checkFormRoles(form, userRoles) {
    if (!form.roles)
      return true;

    // check if the user has a role that allows them to see this child
    let result = false;
    for (let i = 0; i < form.roles.length; i+=1) {
      if (form.roles[i].startsWith("!")) {
        // this child is allowed if the user doesn't have the given role
        if (!userRoles.includes(form.roles[i].substr(1)))
          result = true;
      }
      else {
        // this child is allowed if the user has the given role
        if (userRoles.includes(form.roles[i]))
          result = true;
      }
    }

    return result;
  }

  function mapFormLink(lagerstelle, form, parameters = {}) {
    const child =  Object.assign({}, form);

    child.link = form.path && FormUtil.getLink(lagerstelle.path, form.path, parameters) 
      || form.linkExtraAntrag && FormUtil.getLink(null, form.linkExtraAntrag, parameters) 
      || null;

    if (child.friendlyName == 'Luxemburg (Nach)kauf / Verkauf / Umschichtung / Spar- / Entnahmeplan' 
      || child.friendlyName == 'Frankfurt (Nach)kauf / Verkauf / Umschichtung / Spar- / Entnahmeplan') {
      child.link = form.path && FormUtil.getLink(form.lagerstelle, form.path, parameters) 
        || form.linkExtraAntrag && FormUtil.getLink(null, form.linkExtraAntrag, parameters) 
        ||  null;
    }

    return child;
  }

FormUtil.getLink = (lagerstelle, antragId, parameters = {}) => {
    if (!antragId) return undefined;

    Object.keys(parameters).forEach(key => {
      if (!parameters[key]) {
        delete parameters[key];
      }
    });

    if ((lagerstelle === 'FFB' && antragId === 'ffbwertpapierorder') || antragId === 'wertpapierorder') {
      return {
        path: '/beratung/formulare/antrag/WP/wertpapierorder/main', 
        query: {
          lagerstelleFixed: lagerstelle,
          antragId: antragId,
          initData: true,
          ...parameters,
        },
      };
    } else if (lagerstelle === 'WP' && antragId=== '/wertpapierorder/main')   {
      return {
        path: '/beratung/formulare/antrag/WP/wertpapierorder/main', 
        query: {
          initData: true,
          ...parameters,
        },
      };
    }

    return {
      path: '/beratung/formulare/antrag/' + (lagerstelle && (lagerstelle + '/') || '') + antragId, 
      query: {
        lagerstelleFixed: lagerstelle || '',
        antragId: antragId,
        initData: true,
        ...parameters,
      },
    };
  };

  FormUtil.openForm = (lagerstelle, form) => {
    if (!lagerstelle || !form) return;

    if(form?.link) {
      openFormLink(lagerstelle, form);
    } else {
      openLink(ResolverId.Form, form);
    }
  }

  FormUtil.getLinkPath = (lagerstelle, form) => {
    if (!lagerstelle || !form) return;

    const formRouteParams = getFormRouteParams(lagerstelle, form);
    return addParamToURL(formRouteParams.path, makeQueryParam(formRouteParams.query));
  }

  const openFormLink = (lagerstelle, form) => {
    if (!lagerstelle || !form) return;

    router
      .push(getFormRouteParams(lagerstelle, form))
      .catch(() => {});
  }

  const getFormRouteParams = (lagerstelle, form) => {
    if (!lagerstelle || !form) return;

    if(form.hideBeratungsmappepopup === true){
      return {
        path: form?.link?.path,
        query: {
          dynamicFormId: form?.dynamicFormId,
          hideBeratungsmappepopup: form?.hideBeratungsmappepopup,
          lagerstelleLabel: lagerstelle?.label,
        }
      };
    } else {
      return {
        path: form?.link?.path, 
        query: {
          lagerstelleLabel: lagerstelle?.label, 
          lagerstelleFixed: form.link.query?.lagerstelleFixed, 
          initData: true,
        }
      };
    }
  }

  FormUtil.openFormGlobalSearch = (lagerstelle, form) => {
    if (!lagerstelle || !form) return;

    if (form?.link) {
      router
        .push('/noop') // prevents navigation issues when route is already open
        .catch(() => {})
        .finally(() => openFormLink(lagerstelle, form));
    } else {
      openLink(ResolverId.Form, form);
    }
  };

  FormUtil.createIsFormVisibleCheck = (lagerstellePath) => {
    const fcConfig = store.getters[FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG];
    const content = fcConfig?.[FC_CONFIG.FORMULARE_MENU_PERMISSION_CONFIG]?.[lagerstellePath]?.content || '[]';
    const configuredList = JSON.parse(content);

    return (form) => {
      const { hideForm, path: formPath } = form || {};
      return !hideForm && !configuredList.some(configured => configured.path === formPath && configured.visible === false);
    };
  };

  function updateBezeichnungDWSMorgenFund(antrag){ //make it possible to load old DWS Investmenkonto; ANTRAG_BEZEICHNUNG has changed
    if(antrag.bezeichnung.includes('DWS')){
      return antrag.bezeichnung.replace('DWS','MorgenFund')
    }else{
      return antrag.bezeichnung
    }  
  }

  function updateOldBezeichnung(bezeichnung, antrag, gesellschaft){
    if((gesellschaft == 'DWS FONDSPLATTFORM FFM' || gesellschaft == 'DWS') && antrag.bezeichnung == 'DWS Depoteröffnung Investmentkonto'){
      bezeichnung = updateBezeichnungDWSMorgenFund(antrag);
    }
    if(gesellschaft == 'EBASE' && bezeichnung == 'DEA Business Depot mit Konto'){
      bezeichnung = 'Depoteröffnung'
    }
    if(gesellschaft === 'BAADER BANK' && (bezeichnung === 'Depot- und Kontoeröffnung Gemeinschaftsdepot' || bezeichnung === 'Depot- und Kontoeröffnung für Minderjährige')){
      bezeichnung = 'Depot- und Kontoeröffnung'
    } else if (gesellschaft === 'BAADER BANK' && bezeichnung?.includes('Legitimationsprüfung')) {
      return 'Legitimationsprüfung'
    }else if(gesellschaft == 'FFB' && bezeichnung == 'VL mit/ohne Abschlussentgelt (für bestehendes Depot)'){
      bezeichnung = 'VL (für bestehendes Depot)'
    }

    return bezeichnung
  }

  export default FormUtil;