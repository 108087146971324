<template>
  <div style="height: 600px">
    <DBM :act="this.act" :showLoadButton="false" :query="this.pars()"></DBM>
    <ComboBox label="Minutes" :values="comboMinutes" :value="minutes" isComponentHalfSize @change="runMinutes($event)"
      :disabled="this.syncRun" />
    <ComboBox label="Statistics" :values="comboStats" :value="statId" isComponentHalfSize @change="changeStat($event)"
      :disabled="this.syncRun" :sortComboboxValues="false" />

    <VChart ref="vchart" v-if="this.statData" :option="option" @click="kkk"></VChart>
    <DbmSessionDialog ref="DbmSessionDialog" />
  </div>
</template>

<script>
import DB_TYPES from "@/store/dbm/types";

import { mapGetters } from "vuex";
import Tree from "@/components/core/Tree.vue";

import DBM from "@/views/dbm/Dbm.vue";

import BaseButton from "@/components/core/BaseButton.vue";
import DbmSessionDialog from "@/views/dbm/DbmSessionDialog.vue";
import VChart from "vue-echarts";
import * as echarts from "echarts";
import ComboBox from "@/components/core/forms/ComboBox.vue";

import { white } from "color-name";
export default {
  mounted() {
    /*console.log('ba',this.$refs.vchart.chart);
      let chart= this.$refs.vchart;
      //chart.chart.on('click','onDataZo');
      chart.chart.on('click', params => {
        console.log('pppp');
    if(params.targetType === 'axisLabel'){
      console.log(params.value);    	
    }
  }); */
  },
  components: {
    DBM,
    Tree,
    BaseButton,
    DbmSessionDialog,
    ComboBox,
    VChart,
  },
  props: {

  },
  data() {
    let comb = [3, 5, 10, 20, 40, 60, 120, 180, 720, 1440, 2880];
    let statsV = [
      { label: 'IO', value: 'IO' },
      { label: 'read IO', value: 'READ_IO' },
      { label: 'write IO', value: 'WRITE_IO' },
      { label: 'cpu', value: 'CPU' },
      { label: 'changed blocks', value: 'CHANGED_BLOCKS' },
      { label: 'mb sent via SQL*Net to client', value: 'NET_SENT' },
      { label: 'mb received via SQL*Net from client', value: 'NET_REC' },
      { label: 'CPU_LOAD', value: 'CPU_LOAD' },
    ];

    return {
      comboMinutes: comb,

      stat: 'IO',
      comboStats: statsV
      //statId: 'CHANGED_BLOCKS',
    };
  },
  computed: {
    ...mapGetters({
      statData: DB_TYPES.GETTERS.STAT_DATA,
      syncRun: DB_TYPES.GETTERS.RUNNIG,
      statId: DB_TYPES.GETTERS.STAT,
      minutes: DB_TYPES.GETTERS.MINUTS,
    }),
    act() {
      return DB_TYPES.ACTIONS.STAT_DATA;
    },

    option() {
      let opt = {
        title: {

          textStyle: {
            align: "center",
            fontSize: 15,
            //fontStyle: "italic",
            fontWeight: "normal",
          },
          text: this.statData.label, //'Gradient Stacked Area Chart'
        },

        tooltip: {
          trigger: "item",
          formatter: (params) => {
            let obj = this.statData.yaxis[params.componentIndex];


            return (

              params.name +
              " value = " +
              params.value +
              " " +
              this.statData.einheit +
              ` <br/> 
                ` +
              obj.html
            );
          },
          ///formatter: '{a} <br/>{b} : {c} '
        },

        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        grid: {
          left: "3px",
          right: "4px",
          bottom: "53px",

          containLabel: true,
        },
        xAxis: {
          type: "category",
          // boundaryGap: [0, "31px"],

          axisLabel: {
            rotate: 90,
            fontSize: 10,
          },

          //data:  this.statData.xAxis, //["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]// this.statData.xAxis,//["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },

        yAxis: {
          type: "value",
          // boundaryGap: [0, "1px"],
          splitArea: {
            show: false,
          },
          max: this.statData.max,
        },

        series: [],
      };
      opt.xAxis.data = this.statData.xasis;

      this.statData.yaxis.forEach((e) => {
        let ser = {
          name: e.name,
          type: this.statData.type,
          stack: "Total",
          //stack:'总量',
          smooth: true,
          showSymbol: true,
          lineStyle: {
            high: 10,
          },
          sid: e.sid,
          id: e.sid + "/" + e.serial + "/" + e.ident,
          idd: {
            sid: e.sid,
            serial: e.serial,
            ident: e.ident,
          },

          //color:white,
          /* emphasis: {
           focus: "series",
          },*/
          data: e.data,

          color: e.color,
        };
        /*if(e.color){
          ser.areaStyle.color=e.color;
        }*/
        opt.series.push(ser);
        // opt.legend.data.push(ser.name);
      });

      return opt;
    },
    title() {
      if (this.data != null) {
        return this.data.label;
      } else {
        return null;
      }
    },
  },
  methods: {
    pars() {
      let res = {};
      res.query = this.$store.getters[DB_TYPES.GETTERS.STAT];
      res.minutes = this.$store.getters[DB_TYPES.GETTERS.MINUTS];
      if (res.minutes >= 1440) {
        res.minutes = res.minutes - 3;
      }
      return res;
    },
    async changeStat(event) {
      this.$store.commit(DB_TYPES.MUTATIONS.STAT,
        event);
      await this.run()
    },
    async runMinutes(event) {

      this.$store.commit(DB_TYPES.MUTATIONS.MINUTS,
        event);
      await this.run()
    },
    async run() {
      this.$store.commit(DB_TYPES.MUTATIONS.START_TIME,
        null);
      this.$store.commit(DB_TYPES.MUTATIONS.START_TIME,
        new Date().getTime() - 6000000000);
    },
    kkk(e) {
      let id = e.seriesId.split("/");
      if (id[0] != "0") {
        this.$refs.DbmSessionDialog?.open(id[0], id[1]);
        this.$store.commit(DB_TYPES.MUTATIONS.START_TIME, null);
      }
    },
  },
};
</script>
