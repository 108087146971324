<template>
  <div>
    <label>Profilbild</label>

    <ImageUpload
      :tid="_generateTidFromString('user-picture')"
      class="user-picture"
      :image="userPicture"
      :loading="loading"
      :config="imageUploadConfig"
      :disabled="loading"
      uploadButtonText="Profilbild hochladen"
      @file="uploadImage($event)"
      @change="uploadImage($event)"
    />

    <div v-if="!isDefaultImage && isEditable" class="mt-16px mb-0">
      <BaseButton isSecondary @click="deleteImage()" :disabled="loading" :tid="_generateTidFromString('Profilbild löschen')">Profilbild löschen</BaseButton>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';
import CUSTOMERDATA_TYPES from '@/store/customerData/types';
import ImageUpload from '@/components/fileUpload/ImageUpload.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";


export default {
  mixins: [InteractiveHelpCommonsMixin],
  data() {
    return {
      userPicturePreview: null,
      loading: false,
      imageUploadConfig: {
        height: '212px',
        fit: 'contain',
      },
    };
  },
  computed: {
    ...mapGetters({
      customerData: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_DATA,
      userPictureUrl: CORE_TYPES.GETTERS.GET_USER_PICTURE_URL,
      userPictureData: CORE_TYPES.GETTERS.GET_USER_PICTURE_DATA,
      readOnly: CORE_TYPES.GETTERS.READ_ONLY,
    }),
    isEditable() {
      return this.customerData?.isEditable;
    },
    isDefaultImage() {
      return this.userPictureData?.isDefaultImage;
    },
    userPicture() {
      if(this.userPicturePreview) {
        return this.userPicturePreview;
      } else {
        return this.userPictureUrl;
      }
    },
  },
  methods: {
    uploadImage(file) {
      this.loading = true;
      this.userPicturePreview = URL.createObjectURL(file);
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.SAVE_USER_PICTURE, { file: file })
        .finally(() => this.loading = false);
    },
    async deleteImage() {
      this.loading = true;
      this.userPicturePreview = null;
      await this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.DELETE_USER_PICTURE);
      this.loading = false;
    },
  },
  components: {
    ImageUpload,
    BaseButton,
  },
}
</script>

<style scoped>
.user-picture {
  width: 100%;
}
</style>
