var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "deleteModal",
          attrs: {
            modalTitle: _vm.message ? _vm.title : "Vertrag löschen?",
            labelButtonConfirm: "Vertrag löschen"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.onConfirmModal()
            },
            onCancelButton: function($event) {
              return _vm.onCancelModal()
            }
          }
        },
        [
          _vm.message
            ? _c("div", [_c("span", [_vm._v(_vm._s(_vm.message))])])
            : _c("div", [
                _vm._v(" Soll die Versicherung "),
                _c("span", { staticClass: "font-bold" }, [
                  _vm._v(_vm._s(_vm.insuranceDescription))
                ]),
                _vm._v(" wirklich entfernt werden? ")
              ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }