var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("MailContactsModal", {
        ref: "mailContacts",
        attrs: { records: _vm.mailUsers },
        on: { "select-contacts": _vm.confirmContactsToSend }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }