<template>
    <div>
        <div class="box__container box__title pt-3 pb-4" v-if="!antraegeEmail && !antraegePdf">
            <div v-if="hasActivePositions">Bitte bestätigen Sie den Versand mit dem Button "Anträge anzeigen bzw. versenden.</div>
            <div v-else>Bitte wählen Sie eine Position aus.</div>
        </div>
        <div class="box__container" v-else>
            <Table
                v-if="antraegeEmail && antraegeEmail.length"
                title="Die folgenden Aufträge wurden per E-Mail an den jeweiligen Kunden gesendet:"
                :headers="headers"
                :rows="rowsEmail"
                rowId="posGrpId"
                hidePagination
                @click-kundenname="openCustomerNewTab"
            >
            </Table>
            <NoData v-else title="Die folgenden Aufträge wurden per E-Mail an den jeweiligen Kunden gesendet:" noIcon />
            
            <div class="my-5">
                <Table
                    v-if="antraegePdf && antraegePdf.length"
                    title="Die folgenden Aufträge wurden als PDF-Datei erzeugt:"
                    :headers="headers"
                    :rows="rowsPdf"
                    rowId="posGrpId"
                    hidePagination
                    @click-kundenname="openCustomerNewTab"
                >
                </Table>
                <NoData v-else title="Die folgenden Aufträge wurden als PDF-Datei erzeugt:" noIcon />
            </div>
        </div>
    </div>
</template>

<script>
import mixin from './wertpapiere-mixin.js';
import Table from "@/components/table2/Table.vue";
import {TextColumn} from "@/components/table2/table_util.js";
import NoData from '@/components/core/NoData.vue';
import WERTPAPIERE_ALLE_KUNDEN_TYPES from '@/store/wertpapiereAlleKunden/types';

export default {
    mixins: [mixin],
    components: {
        Table, 
        NoData,
    },
    mounted() {
        if (this.positionsChanged) {
            this.$store.commit(WERTPAPIERE_ALLE_KUNDEN_TYPES.MUTATIONS.RESET_ANTRAEGE);
        }
    },
    computed: {
        rowsPdf() {
            return this.antraegePdf || [];
        },
        rowsEmail() {
            return this.antraegeEmail || [];
        },
        headers() {
            return { 
                lockedLeft: [
                    TextColumn('kundenNr', 'Kundennummer').makeAlwaysVisible(),
                    TextColumn('kundenname', 'Name').makeLink(),
                ],
                center: [
                    TextColumn('fond', 'Fonds').addCellProps({lineClamp: 4}),
                    TextColumn('lagerstelle', 'Lagerstelle'),
                ],
                lockedRight: [
                ],
            }
        }
    },
}

</script>

<style>

</style>