var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.position
    ? _c("div", [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12 font-bold" }, [
            _vm._v(_vm._s(_vm.position.fondsname))
          ]),
          !_vm.position.hasError
            ? _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("span", { staticClass: "subtitle" }, [
                      _vm._v("Depotposition über:")
                    ]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.betrag(_vm.position.betrag)) + "€")
                    ])
                  ]),
                  _c("div", { staticClass: "col" }, [
                    _c("span", { staticClass: "subtitle" }, [
                      _vm._v("Anlagehorizont:")
                    ]),
                    _vm._v(" " + _vm._s(_vm.position.horizont))
                  ]),
                  _c("div", { staticClass: "col" }, [
                    _c("span", { staticClass: "subtitle" }, [
                      _vm._v("Verlusttragfähigkeit:")
                    ]),
                    _vm._v(" " + _vm._s(_vm.position.verlust))
                  ]),
                  _c("div", { staticClass: "col" }, [
                    _c("span", { staticClass: "subtitle" }, [
                      _vm._v("Anlageziel:")
                    ]),
                    _vm._v(" " + _vm._s(_vm.position.anlageziel))
                  ]),
                  _c("div", { staticClass: "col" }, [
                    _c("span", { staticClass: "subtitle" }, [
                      _vm._v("Anlageziel speziell:")
                    ]),
                    _vm._v(" " + _vm._s(_vm.position.anlagezielSpeziell))
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-6" },
                    [
                      _c("ComboBox", {
                        attrs: {
                          label: "Zuordnen zu Anlageziel",
                          values: _vm.zeileComboValues
                        },
                        on: {
                          change: function($event) {
                            return _vm.doChange($event)
                          }
                        },
                        model: {
                          value: _vm.zeileCombo,
                          callback: function($$v) {
                            _vm.zeileCombo = $$v
                          },
                          expression: "zeileCombo"
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            : _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("span", { staticClass: "subtitle" }, [
                      _vm._v("Depotposition über:")
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      [
                        _vm._v(_vm._s(_vm.betrag(_vm.position.betrag))),
                        _c("PhCurrencyEur", { attrs: { size: 16 } })
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "col color-danger" }, [
                    _vm._v("Es sind keine Zielmarkdaten vorhanden")
                  ]),
                  _c(
                    "div",
                    { staticClass: "col col-md-6" },
                    [
                      _c("ComboBox", {
                        attrs: {
                          label: "Kunde möchte halten in",
                          values: _vm.zeileComboValues
                        },
                        on: {
                          change: function($event) {
                            return _vm.doChange($event)
                          }
                        },
                        model: {
                          value: _vm.zeileCombo,
                          callback: function($$v) {
                            _vm.zeileCombo = $$v
                          },
                          expression: "zeileCombo"
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }