var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _c("div", { staticClass: "box__title" }, [_vm._v("Dokumente")]),
      _vm.rows.length
        ? _c("Table", {
            attrs: {
              tableId: "e4443e72-ee74-40ff-b07e-821a4f74c3d3",
              headers: _vm.headers,
              rows: _vm.rows,
              rowsPerPage: 10,
              rowId: "fileId"
            },
            on: {
              "action-DOWNLOAD": _vm.downloadDocument,
              "action-DELETE": _vm.openDeleteDocumentConfirmModal
            }
          })
        : _c("div", [_vm._v("Keine Daten")]),
      _c(
        "BaseModal",
        {
          ref: "deleteDocumentConfirmModal",
          attrs: { size: "sm", modalTitle: "Löschen" },
          on: {
            onConfirmButton: function($event) {
              return _vm.deleteDocument()
            }
          }
        },
        [_vm._v(" Wollen Sie das Dokument wirklich löschen? ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }