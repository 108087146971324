<template>
  <div>
    <PageHeaderTitleNavigation
      :actions="headerActions"
      title="Tarifgruppe"
      @action-CREATE_COURTAGESATZ="openEditCourtagesatzModal"
    />

    <GhostLoading v-if="loading.init" useBoxContainer type="table" />

    <template v-else>
      <div class="box__container">
        <div class="box__title">Daten Tarifgruppe</div>
        <InputField
          label="Bezeichnung"
          isComponentHalfSize
          v-model="tarifgruppe.bezeichnung"
        />
      </div>

      <div class="box__container">
        <Table
          title="Hier können Sie pro Provisionsart einen Courtagesatz eintragen."
          rowId="provartId"
          :headers="headers"
          :rows="rows"
          :rowsPerPage="10"
          @click-provart="openEditCourtagesatzModal"
          @action-DELETE="deleteCourtagesatz"
        />
      </div>
    </template>


    <BaseModal 
      ref="editCourtagesatzModal"
      modalTitle="Neuer Courtagesatz"
      :confirmDisabled="!editCourtagesatz.provartId || !editCourtagesatz.master_provsatz || !editCourtagesatz.standard_provsatz || !isProvartValid"
      @onConfirmButton="saveCourtagesatz"
    > 
      <ComboBox
        label="Provisionsart" 
        isComponentHalfSize
        :values="provartValues"
        :renderDanger="!editCourtagesatz.provartId"
        v-model="editCourtagesatz.provartId"
      />
      <InputField
        label="Master Provsatz"
        isComponentHalfSize
        type="number"
        :precision="4"
        :renderDanger="!editCourtagesatz.master_provsatz"
        v-model="editCourtagesatz.master_provsatz"
      />
      <InputField
        label="Standard Provsatz"
        isComponentHalfSize
        type="number"
        :precision="4"
        :renderDanger="!editCourtagesatz.standard_provsatz"
        v-model="editCourtagesatz.standard_provsatz"
      />
      <InputField
        label="Max Provsatz"
        isComponentHalfSize
        type="number"
        :precision="4"
        v-model="editCourtagesatz.max_provsatz"
      />
      <div v-if="!isProvartValid" class="color-danger">
        Es darf nur einen Courtagesatz pro Provisionsart geben!
      </div>
    </BaseModal>

  </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';

import Table from "@/components/table2/Table.vue";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import { makeQueryParam } from '@/helpers/utils-helper';

import axios from 'axios';
import { TextColumn, NumberColumn, ActionColumn, ConfirmedAction } from "@/components/table2/table_util.js";
import InputField from '@/components/core/forms/InputField.vue'
import ComboBox from '@/components/core/forms/ComboBox.vue'
import BaseModal from '@/components/core/BaseModal.vue'

const config = {
  defaultSpinner: true
};

export default {
  mixins: [],
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    Table,
    GhostLoading,
    InputField,
    ComboBox,
    BaseModal,
  },
  data() {
    return {
      loading: {
        init: false,
        save: false,
      },
      tarifgruppe: {},
      originalTarifgruppe: {},
      editCourtagesatz: {},
      provartValues: [],
    }
  },
  computed: {
    headers() {
      return {
        lockedLeft: [
          TextColumn("provart", "Provart").makeLink(),
          NumberColumn("master_provsatz", "Master Provsatz", 4),
          NumberColumn("standard_provsatz", "Standard Provsatz", 4),
          NumberColumn("max_provsatz", "Max Provsatz", 4),
        ],
        center: [],
        lockedRight: [
          ActionColumn("actions", "Aktionen"),
        ],
      }
    },
    rows() {
      let rows = this.tarifgruppe.courtagesaetze || []

      return rows.map(row => ({
        ...row,
        actions: this.makeActions(row),
      }))
    },
    headerActions() {
      return [
        PageHeaderSimpleAction('CREATE_COURTAGESATZ', 'Neuer Courtagesatz'),
      ]
    },
    isProvartValid() {
      return !this.editCourtagesatz.provartId || !(this.tarifgruppe.courtagesaetze || [])
      .some(court => court.provartId === this.editCourtagesatz.provartId && court.courtagesatzId !== this.editCourtagesatz.courtagesatzId)
    }
  },
  mounted() {
    this.loadTarifgruppe()
  },
  methods: {
    loadTarifgruppe() {
      this.loading.init = true

      const params = makeQueryParam({ id: this.$route.params.id })

      axios.get(`${process.env.VUE_APP_API}/tarifgruppen/tarifgruppe?${params}`, config).then(response => {
        this.tarifgruppe = response.data.tarifgruppe
        this.originalTarifgruppe = JSON.parse(JSON.stringify(this.tarifgruppe))
        this.provartValues = response.data.provartValues
      }).finally(() => this.loading.init = false)
    },
    async saveTarifgruppe() {
      if (this.originalTarifgruppe.bezeichnung === this.tarifgruppe.bezeichnung) {
        return
      }

      this.loading.save = true

      const payload = {
        id: this.$route.params.id,
        bezeichnung: this.tarifgruppe.bezeichnung
      }

      return axios.post(`${process.env.VUE_APP_API}/tarifgruppen/tarifgruppe`, payload, config)
      .finally(() => this.loading.save = false)
    },
    openEditCourtagesatzModal(row) {
      this.editCourtagesatz = {...row}
      this.$refs.editCourtagesatzModal.open()
    },
    saveCourtagesatz() {
      this.loading.save = true

      const params = makeQueryParam({ tarifgruppeId: this.$route.params.id })

      axios.post(`${process.env.VUE_APP_API}/tarifgruppen/courtagesatz?${params}`, this.editCourtagesatz, config).then(response => {
          const indx = this.tarifgruppe.courtagesaetze.findIndex(row => row.provartId === response.data.provartId)

          if (indx > -1) {
            this.$set(this.tarifgruppe.courtagesaetze, indx, response.data)
          } else {
            this.tarifgruppe.courtagesaetze.push(response.data)
          }
      }).finally(() => this.loading.save = false)
    },
    makeActions(row) {
      return [
        ConfirmedAction("DELETE", 'PhTrash', "Löschen", `Soll der Eintrag für "${row.provart}" wirklich gelöscht werden?`, 'Eintrag löschen', "Löschen")
      ]
    },
    deleteCourtagesatz({ courtagesatzId }) {
      this.loading.save = true

      const params = makeQueryParam({ courtagesatzId })

      axios.delete(`${process.env.VUE_APP_API}/tarifgruppen/courtagesatz?${params}`, config).then(response => {
        if (response && response.status === 200) {
          this.tarifgruppe.courtagesaetze = this.tarifgruppe.courtagesaetze.filter(row => row.courtagesatzId !== courtagesatzId)
        } 
      }).finally(() => this.loading.save = false)
    },
  },
  async beforeRouteLeave(to, from, next) {
    await this.saveTarifgruppe();
    next();
  }
}
</script>