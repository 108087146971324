/* eslint-disable no-console */

import { register } from 'register-service-worker'

// service worker is disabled. New configs were done on apache.
export const LIVE_UPDATE = false
export const ENABLE_SERVICE_WORKER = false

export function unregister () {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister()
    }).catch(error => console.error(error))
  }
}

if (process.env.NODE_ENV === 'production' && ENABLE_SERVICE_WORKER) {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered (registration) {
      console.log('Service worker has been registered.')

      if (LIVE_UPDATE) {
        const updateInterval = +process.env.VUE_APP_SERVICE_WORKER_UPDATE_INTERVAL || null
        if (updateInterval) {
          registration.update()
          setInterval(() => registration.update(), updateInterval)
        }

        document.addEventListener('triggerRegistrationUpdate', () => {
          registration.update()
        })
      }
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated (registration) {
      console.log('New content is available; please refresh.')

      if (LIVE_UPDATE) {
        document.dispatchEvent(new CustomEvent('swUpdated', { detail: registration }))
      }
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
} else {
  unregister()
}
