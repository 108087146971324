<template>
  <BaseModal 
    ref="modal" 
    modalTitle="Spezialfeiertage"
    :confirmLoading="loading"
    :showConfirmButton="false"
  >
    <template #default>
      <div v-if="isBesitzer" class="mb-3">
        <div class="box__title" @click="$refs.feiertagModal.open()">Gruppe: {{ gruppe.bezeichnung }}</div>

        <div class="row">
          <div class="col-12 col-sm-4">
            <DatePickerField label="Datum" v-model="form.datum" isValueAsString emptyValueAsNull validateUntouched />
          </div>
          <div class="col-12 col-sm-8">
            <InputField label="Bezeichnung" type="text" v-model="form.bezeichnung" validateUntouched />
          </div>
        </div>

        <InputToggleSwitch label="Jährliche Wiederholung" v-model="form.wiederholung" class="pb-2" />

        <BaseButton isSecondary v-if="feiertag && feiertag.id > 0" :disabled="saving" @click="reset">Abbrechen</BaseButton>
        <BaseButton @click="save" :disabled="saving || !form.datum || !form.bezeichnung">
          <AnimatedSpinner v-if="saving" /> Speichern
        </BaseButton>
      </div>

      <GhostLoading v-if="loading" type="table" />
      <Table
        v-else
        :title="isBesitzer ? 'Übersicht meine Spezialfeiertage' : null"
        :headers="headers"
        :rows="rows"
        hidePagination
        @action-EDIT="setEdit"
        @action-DELETE="deleteFeiertag"
      />
    </template>
  </BaseModal>
</template>

<script>
import { mapGetters, } from 'vuex';
import CALENDAR_SETTINGS_TYPES from '@/store/calendarSettings/types';

import BaseModal from '@/components/core/BaseModal.vue';
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import InputField from '@/components/core/forms/InputField.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';
import Table from '@/components/table2/Table.vue';
import { TextColumn, ActionColumn, SimpleAction, } from '@/components/table2/table_util';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';

import validator from '@/mixins/validator/index';
import { required, } from '@/mixins/validator/rules';

export default {
  mixins: [validator],
  data() {
    return {
      loading: false,
      saving: false,
      gruppe: null,
      feiertag: null,
      form: {
        gruppeSpezialId: null,
        datum: null,
        bezeichnung: null,
        wiederholung: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      feiertageGruppenSpezial: CALENDAR_SETTINGS_TYPES.GETTERS.FEIERTAGE_GRUPPEN_SPEZIAL,
      feiertageSpezial: CALENDAR_SETTINGS_TYPES.GETTERS.FEIERTAGE_SPEZIAL,
    }),
    isBesitzer() {
      return !!this.gruppe?.besitzer;
    },
    gruppeSpezialValues() {
      return [ ...this.feiertageGruppenSpezial, ].map(gruppe => ({
        label: gruppe.bezeichnung,
        value: gruppe.id,
      }));
    },
    gruppeFeiertageSpezial() {
      const { gruppe, } = this;
      return [ ...this.feiertageSpezial || [], ]
        .filter(feiertag => feiertag.gruppeSpezialId == gruppe?.id);
    },
    headers() {
      return {
        lockedLeft: [],
        center: [
          TextColumn('bezeichnung', 'Bezeichnung'),
          TextColumn('datum', 'Datum'),
          TextColumn('wiederholungText', 'Wiederholung'),
        ],
        lockedRight: this.isBesitzer ? [ActionColumn('actions')] : [],
      };
    },
    rows() {
      return [ ...this.gruppeFeiertageSpezial || [], ].map(item => ({
        ...item,
        wiederholungText: item.wiederholung ? 'Ja' : 'Nein',
        actions: this.isBesitzer ? [
          SimpleAction('EDIT', 'PhPencilLine', 'bearbeiten'),
          SimpleAction('DELETE', 'PhTrash', 'löschen'),
        ] : [],
      }));
    },
  },
  methods: {
    open(gruppe) {
      if(!gruppe) return;

      this.gruppe = { ...gruppe, };

      this.init();
      this.find();
      this.$refs.modal?.open();
    },
    close() {
      this.$refs.modal?.close();
    },
    init() {
      this.loading = false;
      this.reset();
    },
    setEdit(feiertag) {
      this.feiertag = { ...feiertag, };
      this.form = {
        gruppeSpezialId: feiertag.gruppeSpezialId,
        datum: feiertag.datum,
        bezeichnung: feiertag.bezeichnung,
        wiederholung: feiertag.wiederholung,
      };
    },
    async find() {
      try {
        this.loading = true;
        await this.$store.dispatch(CALENDAR_SETTINGS_TYPES.ACTIONS.FIND_FEIERTAGE_SPEZIAL);
      } finally {
        this.loading = false;
      }
    },
    reset() {
      this.feiertag = null;
      this.form = {
        gruppeSpezialId: this.gruppe?.id || null,
        datum: null,
        bezeichnung: null,
        wiederholung: false,
      };
      this.validation.reset('form.datum');
      this.validation.reset('form.bezeichnung');
    },
    async save() {
      try {
        this.saving = true;
        const payload = { 
          ...this.form,
          id: this.feiertag?.id,
        };
        await this.$store.dispatch(CALENDAR_SETTINGS_TYPES.ACTIONS.SAVE_FEIERTAGE_SPEZIAL, payload);
        this.reset();
      } finally {
        this.saving = false;
      }
    },
    async deleteFeiertag(feiertag) {
      try {
        this.reset();

        await this.$confirmModal({
          title: 'Feiertage löschen?',
          message: `Möchten Sie diesen Feiertag löschen?`,
        });
        await this.$store.dispatch(CALENDAR_SETTINGS_TYPES.ACTIONS.DELETE_FEIERTAGE_SPEZIAL, { ...feiertag, });
      } catch(e) {
        // empty block
      }
    },
  },
  validators: {
    form: {
      datum: [required()],
      bezeichnung: [required()],
    },
  },
  components: {
    BaseModal,
    DatePickerField,
    InputField,
    InputToggleSwitch,
    BaseButton,
    AnimatedSpinner,
    Table,
    GhostLoading,
  },
}
</script>
