var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.brokerData.zugriffCourtage || _vm.isBrokerNoBypass
      ? _c("div", { staticClass: "box__container" }, [_vm._v("Keine Daten")])
      : _vm._e(),
    _vm.brokerData.istMaklerMitVertrag || _vm.isIntern || _vm.isBrokerBypass
      ? _c(
          "div",
          { staticClass: "box__container" },
          [
            _c("div", [_vm._v("Servicegebühr")]),
            _c("InputRadioBoxGroup", {
              attrs: {
                title: "Abrechnungsmethode",
                values: _vm.abrechnungServiceGebuehrValues
              },
              on: {
                input: function($event) {
                  return _vm.addBrokerDataEdited("brokerData")
                }
              },
              model: {
                value: _vm.brokerData.abrechnungServiceGebuehr,
                callback: function($$v) {
                  _vm.$set(_vm.brokerData, "abrechnungServiceGebuehr", $$v)
                },
                expression: "brokerData.abrechnungServiceGebuehr"
              }
            })
          ],
          1
        )
      : _vm._e(),
    _vm.isBrokerBypass && _vm.brokerData.isUntermaklerDesktop
      ? _c("div", { staticClass: "box__container" }, [
          _c("p", [
            _vm._v(
              " Wünschen Sie für Ihren Mitarbeiter einen Direktvertrag mit der FondsKonzept Investmentmakler GmbH, dann bitten wir Sie, eine Nachricht an "
            ),
            _c("a", { attrs: { href: _vm.link } }, [
              _vm._v("vertrieb@fondskonzept.ag")
            ]),
            _vm._v(
              " zu senden. Bitte beachten Sie, dass Direktverträge erst ab einem Courtagesatz von 90% vom Ausgabeaufschlag und 80% der FK-Bestandscourtage erstellt werden können. "
            )
          ])
        ])
      : _vm._e(),
    _vm.brokerData.hatRechteCourtage &&
    (_vm.isIntern || _vm.brokerData.isUntermaklerDesktop || _vm.isBrokerBypass)
      ? _c("div", [
          _c(
            "div",
            [
              _c(
                "div",
                [
                  _vm.isBrokerBypass || _vm.brokerData.isUntermaklerDesktop
                    ? _c(
                        "BaseButton",
                        { on: { click: _vm.wechselStrukturAbrechnung } },
                        [_vm._v("Wechsel zu manueller Strukturabrechnung")]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm.brokerData.isUntermaklerDesktop
                ? _c("div", { staticClass: "box__container" }, [
                    _c("p", [
                      _vm._v(
                        " Bitte tragen Sie hier den Prozentsatz ein den Ihr Mitarbeiter von Ihrer Courtage erhalten soll. "
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        " Beispiel: Ihre Courtage: 5,00% Mitarbeiter soll 4,5% erhalten. Eintrag bei Courtage Abschluss: 90% "
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        " Bitte beachten Sie, dass die angegebenen Prozentwerte immer in Relation zu Ihren Courtagesätzen sind und nicht zum direkt übergeordneten Vermittler. "
                      )
                    ])
                  ])
                : _vm._e(),
              _c("FormDivider"),
              _vm.isIntern ||
              _vm.brokerData.isUntermaklerDesktop ||
              _vm.isBrokerBypass
                ? _c(
                    "div",
                    [
                      _c("InputField", {
                        attrs: {
                          label: "Courtage Abschluss",
                          type: "percent",
                          precision: 4,
                          isComponentHalfSize: true,
                          disabled: _vm.brokerData.isPassivMakler
                        },
                        on: {
                          change: function($event) {
                            return _vm.addBrokerDataEdited("brokerData")
                          }
                        },
                        model: {
                          value: _vm.brokerData.courtageAbschluss,
                          callback: function($$v) {
                            _vm.$set(_vm.brokerData, "courtageAbschluss", $$v)
                          },
                          expression: "brokerData.courtageAbschluss"
                        }
                      }),
                      _c("InputField", {
                        attrs: {
                          label: "Courtage Bestand",
                          type: "percent",
                          precision: 4,
                          isComponentHalfSize: true
                        },
                        on: {
                          change: function($event) {
                            return _vm.addBrokerDataEdited("brokerData")
                          }
                        },
                        model: {
                          value: _vm.brokerData.courtageBestand,
                          callback: function($$v) {
                            _vm.$set(_vm.brokerData, "courtageBestand", $$v)
                          },
                          expression: "brokerData.courtageBestand"
                        }
                      }),
                      _vm.istFK
                        ? _c("InputField", {
                            attrs: {
                              label: "Courtage Wechsel",
                              type: "percent",
                              precision: 4,
                              isComponentHalfSize: true,
                              disabled: _vm.brokerData.isPassivMakler
                            },
                            on: {
                              change: function($event) {
                                return _vm.addBrokerDataEdited("brokerData")
                              }
                            },
                            model: {
                              value: _vm.brokerData.courtageWechsel,
                              callback: function($$v) {
                                _vm.$set(_vm.brokerData, "courtageWechsel", $$v)
                              },
                              expression: "brokerData.courtageWechsel"
                            }
                          })
                        : _vm._e(),
                      _c("InputField", {
                        attrs: {
                          label: "Courtage VV Gebühr",
                          type: "percent",
                          precision: 4,
                          isComponentHalfSize: true,
                          disabled: _vm.brokerData.isPassivMakler
                        },
                        on: {
                          change: function($event) {
                            return _vm.addBrokerDataEdited("brokerData")
                          }
                        },
                        model: {
                          value: _vm.brokerData.courtageVVGebuehr,
                          callback: function($$v) {
                            _vm.$set(_vm.brokerData, "courtageVVGebuehr", $$v)
                          },
                          expression: "brokerData.courtageVVGebuehr"
                        }
                      }),
                      _vm.istFK
                        ? _c("InputField", {
                            attrs: {
                              label: "Courtage Strategiegebühr",
                              type: "percent",
                              precision: 4,
                              isComponentHalfSize: true,
                              disabled: _vm.brokerData.isPassivMakler
                            },
                            on: {
                              change: function($event) {
                                return _vm.addBrokerDataEdited("brokerData")
                              }
                            },
                            model: {
                              value: _vm.brokerData.courtageStrategieGebuehr,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.brokerData,
                                  "courtageStrategieGebuehr",
                                  $$v
                                )
                              },
                              expression: "brokerData.courtageStrategieGebuehr"
                            }
                          })
                        : _vm._e(),
                      _vm.istFK
                        ? _c("InputField", {
                            attrs: {
                              label: "Courtage Servicegebühr",
                              type: "percent",
                              precision: 4,
                              isComponentHalfSize: true
                            },
                            on: {
                              change: function($event) {
                                return _vm.addBrokerDataEdited("brokerData")
                              }
                            },
                            model: {
                              value: _vm.brokerData.courtageServiceGebuehr,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.brokerData,
                                  "courtageServiceGebuehr",
                                  $$v
                                )
                              },
                              expression: "brokerData.courtageServiceGebuehr"
                            }
                          })
                        : _vm._e(),
                      _vm.istFK
                        ? _c("InputField", {
                            attrs: {
                              label: "Courtage VWL",
                              type: "percent",
                              precision: 4,
                              isComponentHalfSize: true,
                              disabled: _vm.brokerData.isPassivMakler
                            },
                            on: {
                              change: function($event) {
                                return _vm.addBrokerDataEdited("brokerData")
                              }
                            },
                            model: {
                              value: _vm.brokerData.courtageVWL,
                              callback: function($$v) {
                                _vm.$set(_vm.brokerData, "courtageVWL", $$v)
                              },
                              expression: "brokerData.courtageVWL"
                            }
                          })
                        : _vm._e(),
                      _vm.istFK
                        ? _c("InputField", {
                            attrs: {
                              label: "Courtage Beteiligungen",
                              type: "percent",
                              precision: 4,
                              isComponentHalfSize: true,
                              disabled: _vm.brokerData.isPassivMakler
                            },
                            on: {
                              change: function($event) {
                                return _vm.addBrokerDataEdited("brokerData")
                              }
                            },
                            model: {
                              value: _vm.brokerData.courtageBeteiligungen,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.brokerData,
                                  "courtageBeteiligungen",
                                  $$v
                                )
                              },
                              expression: "brokerData.courtageBeteiligungen"
                            }
                          })
                        : _vm._e(),
                      _vm.istFK
                        ? _c("InputField", {
                            attrs: {
                              label: "Courtage Managemententgelt",
                              type: "percent",
                              precision: 4,
                              isComponentHalfSize: true
                            },
                            on: {
                              change: function($event) {
                                return _vm.addBrokerDataEdited("brokerData")
                              }
                            },
                            model: {
                              value: _vm.brokerData.courtageManagemententgelt,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.brokerData,
                                  "courtageManagemententgelt",
                                  $$v
                                )
                              },
                              expression: "brokerData.courtageManagemententgelt"
                            }
                          })
                        : _vm._e(),
                      _vm.brokerData.courtageVorsorge != null &&
                      _vm.istFK &&
                      (_vm.isIntern || _vm.istFPPlus)
                        ? _c("InputField", {
                            attrs: {
                              label: "Courtage Vorsorge",
                              type: "percent",
                              precision: 4,
                              isComponentHalfSize: true,
                              disabled: _vm.brokerData.isPassivMakler
                            },
                            on: {
                              change: function($event) {
                                return _vm.addBrokerDataEdited("brokerData")
                              }
                            },
                            model: {
                              value: _vm.brokerData.courtageVorsorge,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.brokerData,
                                  "courtageVorsorge",
                                  $$v
                                )
                              },
                              expression: "brokerData.courtageVorsorge"
                            }
                          })
                        : _vm._e(),
                      _vm.isFA
                        ? _c("InputField", {
                            attrs: {
                              label: "Courtage Option A",
                              type: "percent",
                              precision: 4,
                              isComponentHalfSize: true
                            },
                            on: {
                              change: function($event) {
                                return _vm.addBrokerDataEdited("brokerData")
                              }
                            },
                            model: {
                              value: _vm.brokerData.courtageOptionA,
                              callback: function($$v) {
                                _vm.$set(_vm.brokerData, "courtageOptionA", $$v)
                              },
                              expression: "brokerData.courtageOptionA"
                            }
                          })
                        : _vm._e(),
                      _vm.isFA
                        ? _c("InputField", {
                            attrs: {
                              label: "Courtage Option B",
                              type: "percent",
                              precision: 4,
                              isComponentHalfSize: true
                            },
                            on: {
                              change: function($event) {
                                return _vm.addBrokerDataEdited("brokerData")
                              }
                            },
                            model: {
                              value: _vm.brokerData.courtageOptionB,
                              callback: function($$v) {
                                _vm.$set(_vm.brokerData, "courtageOptionB", $$v)
                              },
                              expression: "brokerData.courtageOptionB"
                            }
                          })
                        : _vm._e(),
                      _vm.isBrokerBypass
                        ? _c("InputField", {
                            attrs: {
                              label: "Zuführer Bestand",
                              type: "percent",
                              precision: 4,
                              isComponentHalfSize: true,
                              disabled: _vm.brokerData.isPassivMakler
                            },
                            on: {
                              change: function($event) {
                                return _vm.addBrokerDataEdited("brokerData")
                              }
                            },
                            model: {
                              value: _vm.brokerData.zufruehrerBestand,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.brokerData,
                                  "zufruehrerBestand",
                                  $$v
                                )
                              },
                              expression: "brokerData.zufruehrerBestand"
                            }
                          })
                        : _vm._e(),
                      _vm.isBrokerBypass
                        ? _c("InputField", {
                            attrs: {
                              label: "Zuführer Servicegebühr",
                              type: "percent",
                              precision: 4,
                              isComponentHalfSize: true,
                              disabled: _vm.brokerData.isPassivMakler
                            },
                            on: {
                              change: function($event) {
                                return _vm.addBrokerDataEdited("brokerData")
                              }
                            },
                            model: {
                              value: _vm.brokerData.zufruehrerServicegeb,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.brokerData,
                                  "zufruehrerServicegeb",
                                  $$v
                                )
                              },
                              expression: "brokerData.zufruehrerServicegeb"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }