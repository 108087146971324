<template>
  <div v-if="details">
    <Table
      v-if="rows.length"
      ref="table"
      :tableId="tableId"
      cardView
      :headers="headers"
      :rows="rows"
      rowId="isinWkn"
      hidePagination
    >
      <template v-slot:fwwFundStars="row">
        <div v-if="row.fwwFundStars" class="value--item-value">
          <PhStar
            v-for="(star, index) of getStars(row.fwwFundStars)"
            :size="16"
            weight="fill"
            :key="'star-' + index"
          />
        </div>
      </template>
    </Table>
  </div>
</template>
<script>
import FONDSINFO_TYPES from "@/store/fondsinfo/types";
import BoxContainer from "@/components/core/BoxContainer.vue";
import { PhStar } from "phosphor-vue";
import { mapGetters } from "vuex";
import { sanitize } from "@/helpers/string-helper.js";
import Table from "@/components/table2/Table.vue";
import {
  TextColumn,
  CurrencyColumnm,
  SlotColumn,
} from "@/components/table2/table_util.js";

export default {
  components: {
    BoxContainer,
    PhStar,
    Table,
  },
  name: "TabDetail",
  props: {
    selectedTabLabel: {
      type: String,
    },
  },
  computed: {
    ...mapGetters({
      fundsDetail: FONDSINFO_TYPES.GETTERS.FONDSINFO_DETAILS,
      allgemeinDescription: FONDSINFO_TYPES.GETTERS.FONDSINFO_ALGEMEIN,
    }),
    details() {
      if (this.fundsDetail) {
        const records = Object.values(this.fundsDetail).slice(1);
        return records;
      }
      return [];
    },
    headers() {
      const result = {
        center: [],
      };

      result.center.push(TextColumn("isinWkn", "ISIN / WKN"));
      result.center.push(TextColumn("fondsgesellschaft", "Fondsgesellschaft"));
      result.center.push(TextColumn("fondsmanager", "Fondsmanager"));
      result.center.push(TextColumn("vertriebszulassung", "Vertriebszulassung"));
      result.center.push(TextColumn("schwerpunkt", "Schwerpunkt"));
      result.center.push(TextColumn("produktart", "Produktart"));
      result.center.push(TextColumn("fondsdomizil", "Fondsdomizil"));
      result.center.push(TextColumn("auflagedatum", "Auflagedatum"));
      result.center.push(TextColumn("ruecknahmepreis", "Rücknahmepreis"));
      result.center.push(TextColumn("waehrung", "Währung"));
      result.center.push(TextColumn("fondsvolumen", "Fondsvolumen"));
      result.center.push(TextColumn("sri", "SRI"));
      result.center.push(TextColumn("mindestanlage", "Mindestanlage"));
      result.center.push(TextColumn("ertragsart", "Ertragsart"));
      result.center.push(SlotColumn("fwwFundStars", "FWW FundStars"));
      result.center.push(TextColumn("euroFondsnote", "€uro-Fondsnote"));
      result.center.push(TextColumn("lipperLeaders_3J", "Lipper Leaders (3 J.)"));
      result.center.push(TextColumn("depotbank", "Depotbank"));
      result.center.push(TextColumn("geschaeftsjahr", "Geschäftsjahr"));
      result.center.push(TextColumn("benchmark", "Benchmark"));
      result.center.push(TextColumn("taxonomie", "Taxonomie"));
      result.center.push(TextColumn("sfdr", "SFDR"));
      result.center.push(TextColumn("pais", "PAIs"));
      result.center.push(TextColumn("nachhaltigkeit", "Nachhaltigkeit"));

      return result;
    },
    rows() {
      const rows = [];

      const row = {};

      const fieldValue = (whatArray) => (whatLabel) => (whatType) =>
        whatArray
          ?.find((w) => w?.label == whatLabel)
          ?.records?.find((r) => r.type == whatType)?.value || "-";

      row.isinWkn = fieldValue(this.details)("Stammdaten")("ISIN / WKN");
      row.fondsgesellschaft = fieldValue(this.details)("Stammdaten")("Fondsgesellschaft");
      row.fondsmanager = fieldValue(this.details)("Stammdaten")("Fondsmanager");
      row.vertriebszulassung = fieldValue(this.details)("Stammdaten")("Vertriebszulassung");
      row.schwerpunkt = fieldValue(this.details)("Stammdaten")("Schwerpunkt");
      row.produktart = fieldValue(this.details)("Stammdaten")("Produktart");
      row.fondsdomizil = fieldValue(this.details)("Stammdaten")("Fondsdomizil");
      row.auflagedatum = fieldValue(this.details)("Stammdaten")("Auflagedatum");
      row.ruecknahmepreis = fieldValue(this.details)("Stammdaten")("Rücknahmepreis");
      row.waehrung = fieldValue(this.details)("Stammdaten")("Währung");
      row.fondsvolumen = fieldValue(this.details)("Stammdaten")("Fondsvolumen");
      row.sri = fieldValue(this.details)("Stammdaten")("SRI");
      row.mindestanlage = fieldValue(this.details)("Stammdaten")("Mindestanlage");
      row.ertragsart = fieldValue(this.details)("Stammdaten")("Ertragsart");
      row.fwwFundStars = fieldValue(this.details)("Stammdaten")("FWW FundStars");
      row.euroFondsnote = fieldValue(this.details)("Stammdaten")("€uro-Fondsnote");
      row.lipperLeaders_3J = fieldValue(this.details)("Stammdaten")("Lipper Leaders (3 J.)");
      row.depotbank = fieldValue(this.details)("Stammdaten")("Depotbank");
      row.geschaeftsjahr = fieldValue(this.details)("Stammdaten")("Geschäftsjahr");
      row.benchmark = fieldValue(this.details)("Stammdaten")("Benchmark");
      row.taxonomie = fieldValue(this.details)("Stammdaten")("Taxonomie");
      row.sfdr = fieldValue(this.details)("Stammdaten")("SFDR");
      row.pais = fieldValue(this.details)("Stammdaten")("PAIs");
      row.nachhaltigkeit = fieldValue(this.details)("Stammdaten")("Nachhaltigkeit");

      rows.push(row);

      return rows;
    },
    tableId() {
      const uniqueUUID = "609efc63-49e7-46b7-b65a-879eade58661";
      return uniqueUUID;
    },
  },
  methods: {
    sanitize(htmlString) {
      return sanitize(htmlString);
    },
    getStars(value) {
      const starQuantity = parseInt(value.charAt());
      return isNaN(starQuantity) ? 0 : starQuantity;
    },
    detailLabel(detail) {
      if (detail && detail.label) {
        return detail.label;
      }
      return "";
    },
  },
  mounted() {
    if (!this.fundsDetail || !this.allgemeinDescription) {
      this.$store.dispatch(FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_ALGEMEIN, {
        isin: this.$route.params?.isin,
        depotid: this.$route.params?.depotid,
      });
    }
  },
};
</script>

<style>
.value--item-label {
  width: 25%;
}
</style>
