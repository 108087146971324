import WERTPAPIERE_ALLE_KUNDEN_TYPES from './types';

export default {
  [WERTPAPIERE_ALLE_KUNDEN_TYPES.GETTERS.LAGERSTELLEN](state) {
    return state.lagerstellen;
  },
  [WERTPAPIERE_ALLE_KUNDEN_TYPES.GETTERS.GESELLSCHAFTEN](state) {
    return state.gesellschaften;
  },
  [WERTPAPIERE_ALLE_KUNDEN_TYPES.GETTERS.KATEGORIEN](state) {
    return state.kategorien;
  },
  [WERTPAPIERE_ALLE_KUNDEN_TYPES.GETTERS.WERTPAPIERE](state) {
    return state.wertpapiere;
  },
  [WERTPAPIERE_ALLE_KUNDEN_TYPES.GETTERS.DATA](state) {
    return state.data;
  },

  [WERTPAPIERE_ALLE_KUNDEN_TYPES.GETTERS.POSITIONS](state) {
    return state.positions;
  },
 
  [WERTPAPIERE_ALLE_KUNDEN_TYPES.GETTERS.COMBOBOX_VALUES](state) {
    return state.comboboxValues;
  },
  
  [WERTPAPIERE_ALLE_KUNDEN_TYPES.GETTERS.ANTRAEGE_EMAIL](state) {
    return state.antraegeEmail;
  },
  
  [WERTPAPIERE_ALLE_KUNDEN_TYPES.GETTERS.ANTRAEGE_PDF](state) {
    return state.antraegePdf;
  },
  
  [WERTPAPIERE_ALLE_KUNDEN_TYPES.GETTERS.TEMP_FILE_ID](state) {
    return state.tempFileId;
  },

  [WERTPAPIERE_ALLE_KUNDEN_TYPES.GETTERS.EMAIL_DATA](state) {
    return state.emailData;
  },
}