var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("GesellschaftTitleNavigation"),
      _vm.loading
        ? _c("GhostLoading", {
            attrs: {
              type: "table",
              useBoxContainer: "",
              title: _vm.TABLE_TITLE
            }
          })
        : [
            _c(
              "div",
              { staticClass: "box__container" },
              [
                _c("div", { staticClass: "box__title" }, [
                  _vm._v("Einzahlung für Kunden")
                ]),
                _c("InputField", {
                  attrs: { label: "Name Adresse", isComponentHalfSize: "" },
                  model: {
                    value: _vm.fondsauftrag.einzahlungEmpfaenger,
                    callback: function($$v) {
                      _vm.$set(_vm.fondsauftrag, "einzahlungEmpfaenger", $$v)
                    },
                    expression: "fondsauftrag.einzahlungEmpfaenger"
                  }
                }),
                _c("InputIbanBic", {
                  attrs: {
                    label: "IBAN",
                    type: "IBAN",
                    isComponentHalfSize: ""
                  },
                  model: {
                    value: _vm.fondsauftrag.einzahlungIban,
                    callback: function($$v) {
                      _vm.$set(_vm.fondsauftrag, "einzahlungIban", $$v)
                    },
                    expression: "fondsauftrag.einzahlungIban"
                  }
                }),
                _c("InputIbanBic", {
                  attrs: { label: "BIC", type: "BIC", isComponentHalfSize: "" },
                  model: {
                    value: _vm.fondsauftrag.einzahlungBic,
                    callback: function($$v) {
                      _vm.$set(_vm.fondsauftrag, "einzahlungBic", $$v)
                    },
                    expression: "fondsauftrag.einzahlungBic"
                  }
                }),
                _c("InputField", {
                  attrs: { label: "Bank", isComponentHalfSize: "" },
                  model: {
                    value: _vm.fondsauftrag.einzahlungBank,
                    callback: function($$v) {
                      _vm.$set(_vm.fondsauftrag, "einzahlungBank", $$v)
                    },
                    expression: "fondsauftrag.einzahlungBank"
                  }
                }),
                _c("InputField", {
                  attrs: { label: "Bemerkung", isComponentHalfSize: "" },
                  model: {
                    value: _vm.fondsauftrag.einzahlungBemerkung,
                    callback: function($$v) {
                      _vm.$set(_vm.fondsauftrag, "einzahlungBemerkung", $$v)
                    },
                    expression: "fondsauftrag.einzahlungBemerkung"
                  }
                }),
                _c("InputField", {
                  attrs: { label: "Fax", isComponentHalfSize: "" },
                  model: {
                    value: _vm.fondsauftrag.einzahlungFax,
                    callback: function($$v) {
                      _vm.$set(_vm.fondsauftrag, "einzahlungFax", $$v)
                    },
                    expression: "fondsauftrag.einzahlungFax"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "box__container" },
              [
                _c("div", { staticClass: "box__title" }, [
                  _vm._v("Einreichungsstelle")
                ]),
                _c("InputField", {
                  attrs: { label: "Name", isComponentHalfSize: "" },
                  model: {
                    value: _vm.fondsauftrag.einreichName,
                    callback: function($$v) {
                      _vm.$set(_vm.fondsauftrag, "einreichName", $$v)
                    },
                    expression: "fondsauftrag.einreichName"
                  }
                }),
                _c("InputField", {
                  attrs: { label: "Strasse", isComponentHalfSize: "" },
                  model: {
                    value: _vm.fondsauftrag.einreichStrasse,
                    callback: function($$v) {
                      _vm.$set(_vm.fondsauftrag, "einreichStrasse", $$v)
                    },
                    expression: "fondsauftrag.einreichStrasse"
                  }
                }),
                _c("InputZIP", {
                  attrs: { label: "PLZ", isComponentHalfSize: "" },
                  on: {
                    city: function($event) {
                      _vm.fondsauftrag.einreichOrt = $event
                    }
                  },
                  model: {
                    value: _vm.fondsauftrag.einreichPlz,
                    callback: function($$v) {
                      _vm.$set(_vm.fondsauftrag, "einreichPlz", $$v)
                    },
                    expression: "fondsauftrag.einreichPlz"
                  }
                }),
                _c("InputField", {
                  attrs: { label: "Ort", isComponentHalfSize: "" },
                  model: {
                    value: _vm.fondsauftrag.einreichOrt,
                    callback: function($$v) {
                      _vm.$set(_vm.fondsauftrag, "einreichOrt", $$v)
                    },
                    expression: "fondsauftrag.einreichOrt"
                  }
                }),
                _c("InputField", {
                  attrs: { label: "Fax", isComponentHalfSize: "" },
                  model: {
                    value: _vm.fondsauftrag.einreichFax,
                    callback: function($$v) {
                      _vm.$set(_vm.fondsauftrag, "einreichFax", $$v)
                    },
                    expression: "fondsauftrag.einreichFax"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "box__container" },
              [
                _c("InputToggleSwitch", {
                  attrs: { label: "Formular Kauf möglich" },
                  model: {
                    value: _vm.fondsauftrag.formularKauf,
                    callback: function($$v) {
                      _vm.$set(_vm.fondsauftrag, "formularKauf", $$v)
                    },
                    expression: "fondsauftrag.formularKauf"
                  }
                }),
                _c("InputToggleSwitch", {
                  attrs: { label: "Formular Verkauf möglich" },
                  model: {
                    value: _vm.fondsauftrag.formularVerkauf,
                    callback: function($$v) {
                      _vm.$set(_vm.fondsauftrag, "formularVerkauf", $$v)
                    },
                    expression: "fondsauftrag.formularVerkauf"
                  }
                }),
                _c("InputToggleSwitch", {
                  attrs: { label: "Formular Fondsumschichtung möglich" },
                  model: {
                    value: _vm.fondsauftrag.formularUmschichtung,
                    callback: function($$v) {
                      _vm.$set(_vm.fondsauftrag, "formularUmschichtung", $$v)
                    },
                    expression: "fondsauftrag.formularUmschichtung"
                  }
                }),
                _c("InputToggleSwitch", {
                  attrs: { label: "Formular Sparplan möglich" },
                  model: {
                    value: _vm.fondsauftrag.formularSparplan,
                    callback: function($$v) {
                      _vm.$set(_vm.fondsauftrag, "formularSparplan", $$v)
                    },
                    expression: "fondsauftrag.formularSparplan"
                  }
                }),
                _c("InputToggleSwitch", {
                  attrs: { label: "Formular Entnahmeplan möglich" },
                  model: {
                    value: _vm.fondsauftrag.formularEntnahmeplan,
                    callback: function($$v) {
                      _vm.$set(_vm.fondsauftrag, "formularEntnahmeplan", $$v)
                    },
                    expression: "fondsauftrag.formularEntnahmeplan"
                  }
                }),
                _c("InputToggleSwitch", {
                  attrs: { label: "Fondskürzel im Auftrag verwenden" },
                  model: {
                    value: _vm.fondsauftrag.kuerzelImAuftrag,
                    callback: function($$v) {
                      _vm.$set(_vm.fondsauftrag, "kuerzelImAuftrag", $$v)
                    },
                    expression: "fondsauftrag.kuerzelImAuftrag"
                  }
                }),
                _c("InputToggleSwitch", {
                  attrs: {
                    label: "Lastschrifteinzug von Einmalanlagen möglich"
                  },
                  model: {
                    value: _vm.fondsauftrag.lastschrifteinzug,
                    callback: function($$v) {
                      _vm.$set(_vm.fondsauftrag, "lastschrifteinzug", $$v)
                    },
                    expression: "fondsauftrag.lastschrifteinzug"
                  }
                }),
                _c("InputToggleSwitch", {
                  attrs: {
                    label: "Kundenfremdnummern in Depotauswahl anzeigen"
                  },
                  model: {
                    value: _vm.fondsauftrag.kundenGesnrInDepotauswahl,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.fondsauftrag,
                        "kundenGesnrInDepotauswahl",
                        $$v
                      )
                    },
                    expression: "fondsauftrag.kundenGesnrInDepotauswahl"
                  }
                }),
                _c("InputTextArea", {
                  attrs: {
                    label: "Text bei Sparplan",
                    isComponentHalfSize: ""
                  },
                  model: {
                    value: _vm.fondsauftrag.textSparplan,
                    callback: function($$v) {
                      _vm.$set(_vm.fondsauftrag, "textSparplan", $$v)
                    },
                    expression: "fondsauftrag.textSparplan"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "box__container" },
              [
                _c("div", { staticClass: "box__title" }, [
                  _vm._v("Hinweis zu SEPA in Orderformularen")
                ]),
                _c("InputRadioBoxGroup", {
                  attrs: { values: _vm.orderBemerkungSepaAktivValues },
                  on: { input: _vm.getDefaultOrderBemerkungSepa },
                  model: {
                    value: _vm.fondsauftrag.orderBemerkungSepaAktiv,
                    callback: function($$v) {
                      _vm.$set(_vm.fondsauftrag, "orderBemerkungSepaAktiv", $$v)
                    },
                    expression: "fondsauftrag.orderBemerkungSepaAktiv"
                  }
                }),
                _c("InputTextArea", {
                  attrs: {
                    label: "Text bei Sparplan",
                    isComponentHalfSize: "",
                    disabled: _vm.fondsauftrag.orderBemerkungSepaAktiv !== "1"
                  },
                  model: {
                    value: _vm.fondsauftrag.orderBemerkungSepa,
                    callback: function($$v) {
                      _vm.$set(_vm.fondsauftrag, "orderBemerkungSepa", $$v)
                    },
                    expression: "fondsauftrag.orderBemerkungSepa"
                  }
                }),
                _vm.fondsauftrag.orderBemerkungSepaAktiv === "1"
                  ? _c("div", [
                      _vm._v(
                        "Bitte beachten Sie, dass die Gläubiger-ID im Text per Hand ergänzt werden muss."
                      )
                    ])
                  : _vm._e()
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }