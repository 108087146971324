var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "edge-detection-container" }, [
    _vm.videoInput
      ? _c("canvas", {
          ref: "c0",
          class: { "edge-detection-canvas-debug": _vm.debug },
          attrs: { id: "videoDestination" }
        })
      : _vm.imageInput
      ? _c("canvas", {
          ref: "c0",
          class: { "edge-detection-canvas-debug": _vm.debug },
          attrs: { id: "videoDestination" }
        })
      : _vm._e(),
    _vm.imgUrl
      ? _c("img", { ref: "src", attrs: { src: _vm.imgUrl } })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }