import DOKUMENTENARCHIV_TYPES from './types';
import { getInitialState } from './index'
import Vue from 'vue'

export default {
  [DOKUMENTENARCHIV_TYPES.MUTATIONS.RETRIEVE_DOKUMENTENARCHIV_SUCCESS](state, payload) {
    Vue.set(state, 'dokumentenarchivData', payload.nodes ? payload : {nodes: payload});
    Vue.set(state, 'virtualNumberOfRecords', {}); // reset virtual node number of records, when there is new dokumentenarchiv data
  }, 
  [DOKUMENTENARCHIV_TYPES.MUTATIONS.RESET_DATA](state) {
    const showSchaden = state.documentsFilter?.showSchaden;
    const showDeleted = state.documentsFilter?.showDeleted

    // reset state
    Object.assign(state, getInitialState())

    Vue.set(state, 'documentsFilter', {
      docLabel: '',
      showSchaden,
      showDeleted,
    });
  }, 
  [DOKUMENTENARCHIV_TYPES.MUTATIONS.SET_TARGET_NODE_ID](state, payload) {
    state.loadingNode = payload;
  },  
  [DOKUMENTENARCHIV_TYPES.MUTATIONS.SET_TREE_LOADING](state, payload) {
    state.loadingTree = payload || false;
  },  
  [DOKUMENTENARCHIV_TYPES.MUTATIONS.ADD_LOADING_NODE_ID_TO_LIST](state, payload) {
    state.loadingNodeList.push(payload)
  },
  [DOKUMENTENARCHIV_TYPES.MUTATIONS.UPDATE_LOADING_STATUS](state, payload) {
    const nodeId = payload?.nodeId
    if (nodeId) {
      if (nodeId === state.loadingNode) {
        state.loadingNode = null
        state.loadingNodeList = []
      } else {
        const index = state.loadingNodeList.indexOf(nodeId);
        if (index > -1) {
          state.loadingNodeList.splice(index, 1);
        }
      }
    }
  }, 
  [DOKUMENTENARCHIV_TYPES.MUTATIONS.RETRIEVE_ADDITIONAL_UPLOAD_INPUTS_SUCCESS](state, payload) {
    state.additionalUploadInputs = payload;
  }, 
  [DOKUMENTENARCHIV_TYPES.MUTATIONS.RETRIEVE_DOCUMENTS_SUCCESS](state, {node, page, versicherungId, keepOldPages}) {
        const nodeId = node?.nodeId
        if (nodeId && nodeId === state.loadingNode || versicherungId) {
          const actionsOrder = ["DOKUMENT_UNTERSCHREIBEN", "FREIGABE_KUNDE_VORHANDEN", "DOKUMENTE_HINZUFUGEN", "ANDERUNGSHISTORIE", "DOKUMENT_LOSCHEN", "MIT_KUNDE_TEILEN", "NICHT_MIT_KUNDE_TEILEN"];
          node.documents.forEach(doc => {
            doc.actions = doc.actions.sort((a, b) => actionsOrder.indexOf(a.legend.key) - actionsOrder.indexOf(b.legend.key));
          })

            const documents = {
                [page]: node.documents || [],
            };
            if (keepOldPages && state.documentNode && state.documentNode.nodeId === nodeId) {
                Object.keys(state.documentNode.documents).forEach(key => {
                    if (key != page)
                        documents[key] = state.documentNode.documents[key];
                });
                node.availableActions = [...(node.availableActions || []), ...(state.documentNode.availableActions || [])];
                node.availableStatusIcons = [...(node.availableStatusIcons || []), ...(state.documentNode.availableStatusIcons || [])];
            } else {
                state.documentNodePage = page;
            }
            state.documentNode = {
                ...node,
                documents,
            };
        } else {
          state.documentNode = {}
        }
  },  
  [DOKUMENTENARCHIV_TYPES.MUTATIONS.SET_PAGE](state, pageIndex) {
    state.documentNodePage = pageIndex;
  }, 
  [DOKUMENTENARCHIV_TYPES.MUTATIONS.RETRIEVE_ADDITIONAL_DOCUMENTS_SUCCESS](state, payload) {
    state.additionalDocuments = payload;
  }, 
  [DOKUMENTENARCHIV_TYPES.MUTATIONS.SET_ROW_SELECTION](state, selectedRows) {
      state.selectedRows = selectedRows;
  },
  [DOKUMENTENARCHIV_TYPES.MUTATIONS.SET_TEMP_UPLOAD_FILES](state, payload) {
    state.tempUploadFiles = payload;
  },
  [DOKUMENTENARCHIV_TYPES.MUTATIONS.SET_SELECTED_ALL_IN_FOLDER](state, payload) {
    state.isSelectedAllInFolder = payload;
  },
  [DOKUMENTENARCHIV_TYPES.MUTATIONS.UPDATE_SELECTED_NODE_TREE](state, payload) {
    if(!payload?.nodeId) return;

    const index = state.dokumentenarchivData?.nodes?.findIndex(node => node.nodeId === payload.nodeId);
    if(index >= 0) {
      Vue.set(state.dokumentenarchivData.nodes, index, { ...payload, });
    }
  },
    [DOKUMENTENARCHIV_TYPES.MUTATIONS.SORTING](state, {sortKey, ascending}) {
        state.sortKey = sortKey;
        state.sortAscending = ascending;
    },
    [DOKUMENTENARCHIV_TYPES.MUTATIONS.RELOAD_DOCUMENTS_PAYLOAD](state, reloadDocumentsPayload) {
        state.reloadDocumentsPayload = reloadDocumentsPayload;
    },
  [DOKUMENTENARCHIV_TYPES.MUTATIONS.SET_DOCUMENTS_FILTER](state, filterPayload) {
    Vue.set(state, 'documentsFilter', filterPayload);
  },
  [DOKUMENTENARCHIV_TYPES.MUTATIONS.ADD_VIRTUAL_NUMBER_OF_RECORDS](state, { nodeId, value, }) {
    if(!nodeId || !value) return;

    const { virtualNumberOfRecords, } = state;
    const currentValue = virtualNumberOfRecords?.[nodeId] || 0;
    const newValue = currentValue + value;
    Vue.set(state.virtualNumberOfRecords, nodeId, newValue);
  },
  [DOKUMENTENARCHIV_TYPES.MUTATIONS.RESET_VIRTUAL_NUMBER_OF_RECORDS](state, { nodeId }) {
    if(!nodeId) return;

    if(nodeId in state.virtualNumberOfRecords) {
      Vue.delete(state.virtualNumberOfRecords, nodeId);
    }
  },
  [DOKUMENTENARCHIV_TYPES.MUTATIONS.GET_PROHYP_DOCUMENTS_SUCCESS](state, prohypDocuments) {
    Vue.set(state, 'prohypDocuments', prohypDocuments);
  },
}