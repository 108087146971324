<template>
  <div>
    <PageHeaderTitleNavigation 
      :title="title"
      :subtitle="subtitle"
      :defaultMenu="antragCustomNavigation.headerOptionMenu"
      :actions="headerActions"
      @action-PDF="getPdfList"
      @action-SAVE="webserviceSync()"
      @action-ACTIVATE_TAN="webserviceActivateTan()"
      @action-SIGN="doClick('sign')"
      @action-ONLINE_LEGI="doClick('online')"
      @action-EMAIL="doClick('email')"
      @action-FAX="doClick('fax')"
      @action-AUFTRAGSDOKUMENTE="doClick('auftragsdokumente')"
      @action-VOLLMACHT="doClickFFBButtons('vollmacht')"
      @action-COPY_SIGNED_ANTRAG="copySignedAntrag"
      @action-ADDITIONAL="doClickAdditional"
      @action-TOGGLE-E-SIGNATUR="sendFormToElectronicSignature"
    >
      <template v-if="$slots.title" #title><slot name="title" /></template>
      <template v-if="$slots.subtitle" #subtitle><slot name="subtitle" /></template>

      <template #action-TOGGLE-AGB>
        <InputToggleSwitch class="py-0" v-model="form.agb" :disabled="disabled"
          label="inklusive AGBs / weitere Unterlagen" inLineLabel inLineAlignment="left" @input="updateAgbProperty($event)" />
      </template>
      <template #action-TOGGLE-RAHMEN-KUNDEN-SAVE>
        <InputToggleSwitch class="py-0" :disabled="disabled" v-model="form.rahmenvereinbarungkundensave" 
          label="Änderungen in Kundeneinstellungen speichern" inLineLabel inLineAlignment="left" @input="sendFormRahmenKundenSave($event)" />
      </template>
      <template #action-TOGGLE-SHOW-PDF-LANGVERSION>
        <InputToggleSwitch class="py-0" v-model="form.showPDFLangversionSwitch" label="PDF in Langversion" 
          inLineLabel inLineAlignment="left" :disabled="disabled" @input="setShowPDFLangversion($event)"/>
      </template>
    </PageHeaderTitleNavigation>

    <BaseModal ref="multisignable" labelButtonCancel="Zurück" :showConfirmButton="false"
       @onCancelButton="closeMultisignModal" @onCloseButton="closeMultisignModal">
      <template v-slot:modalTitle>
        Dokumente zur Signatur
      </template>
      <div class="row" v-for="(signoElement, index) of signoList" :key="index">
        <a v-if="signoElement.signaturStatus !== 'KOMPLETT'" target="_blank" @click="handleSingleSignature(signoElement, true, $refs.multisignable && $refs.multisignable.toClose)">{{signoElement.fileName}}</a>   
        <span class="grayed-out" v-else>{{signoElement.fileName}}</span>
      </div>
    </BaseModal>

    <BaseModal ref="multi_pdf" labelButtonCancel="Zurück" :showConfirmButton="false">
      <template v-slot:modalTitle>
        Dokumente
      </template>
      <div class="row" v-for="(file, index) of pdfList" :key="index">
        <DownloadLink
          :title="getTitle(file)"
          :href="getPDFLinkForMultiPdf(file)" />
      </div>
    </BaseModal>

    <BaseModal ref="hatFehler" labelButtonConfirm="Trotzdem anzeigen" labelButtonCancel="Zurück"
      @onConfirmButton="confirmFehlerModal" @onCancelButton="closeFehlerModal" :showConfirmButton="hatFehlerConfirm">
      <template v-slot:modalTitle>
        <ph-warning :size="16" class="mr-2" style="color: orangered"/>
        Fehlerliste für Antrag
      </template>
      <div class="row" v-for="(warning, index) of pdfWarnings" :key="index">
        <div class="col mb-2" v-html="sanitize(warning)"></div>
      </div>
    </BaseModal>

    <BaseModal ref="hatInfo" labelButtonCancel="Ok" :showConfirmButton="false" @onCancelButton="closeInfoModal">
      <template v-slot:modalTitle>
        <ph-info style="color: var(--color-success)" />
        Information
      </template>
      <div class="row" v-for="(info, index) of pdfInfos" :key="index">
        <div class="col mb-2" v-html="sanitize(info)"></div>
      </div>
    </BaseModal>

    <BaseModal ref="needsConfirmation" labelButtonConfirm="Ja" labelButtonCancel="Nein" @onConfirmButton="confirm(true)"
      @onCancelButton="confirm(false)">
      <template v-slot:modalTitle>
        <ph-warning :size="16" class="mr-2" style="color: orangered"/>
        {{confirmTitle}}
      </template>
      <div>
        {{confirmText}}
      </div>
    </BaseModal>

    <BaseModal ref="berichtDok" :showDefaultButtons=false labelButtonCancel="Abbrechen"
      @onCancelButton="closeBerichtListModal">
      <template v-slot:modalTitle>
        <ph-file class="mr-2" :size="24" />
        Bericht erstellen
      </template>

      <div class="font-strong mb-1">
        Achtung: Die Dateigröße der Factsheets ist sehr umfangreich. Dies kann zu längeren Downloadzeiten führen.
      </div>
      <div class="row" v-for="(dok, index) of berichtList" :key="index">
        <div class="col">
          <InputToggleSwitch v-model="berichtDokForm[dok.name]" :label="dok.label" inLineLabel />
        </div>
      </div>
      <DownloadLink 
        asButton
        title="Erzeugen"
        :href="getBerichtErstellenLink"
      />
      <BaseButton v-if="eMailVersandEnabled" class="mr-2" isSecondary @click="berichtVersenden()">Bericht per E-Mail
        versenden</BaseButton>
    </BaseModal>

    <PictureAttachmentsModal
      ref="pictureAttachmentsModal"
      :selectedDoc="selectedDoc"
      @updateLoadingStatus="updateLoadingStatus($event)"
    />

    <BaseModal ref="komplett" :showConfirmButton="false" labelButtonCancel="Ok">
      <template v-slot:modalTitle>
        <ph-warning :size="16" class="mr-2" style="color: orangered"/>
        Formular ist bereits abgeschlossen
      </template>
      <div>
        Das Dokument wurde bereits elektronisch signiert und abgeschlossen.
        <br>
        Eine Änderung ist daher nicht mehr möglich.
        <br>
      </div>
    </BaseModal>

    <BaseModal ref="teilweise" :showDefaultButtons="false">
      <template v-slot:modalTitle>
        <ph-warning :size="16" class="mr-2" style="color: orangered"/>
        Formular ist bereits teilweise signiert
      </template>
      <div>
        Das Dokument wurde bereits teilweise elektronisch signiert.
        <br>
        Möchten Sie eine neue PDF mit den geänderten Daten erstellen (alle bisherigen Unterschriften gehen verloren)
        oder die Daten nur speichern ohne sie in die PDF zu übernehmen?
      </div>
      <template v-slot:footer>
        <div class="col-auto">
          <BaseButton isSecondary @click="pdfNeuErstellen()">PDF neu erstellen
          </BaseButton>
        </div>
        <div class="col-auto">
          <BaseButton isSecondary @click="showSignoListFromTeilweise()">PDF
            unverändert lassen</BaseButton>
        </div>
        <div class="col-auto">
          <BaseButton isSecondary>Abbrechen</BaseButton>
        </div>
      </template>
    </BaseModal>

    <!-- In case of any errors no pdf can be created. All buttons that require a pdf should be disabled, 
         if somehow somebody manages to call any function that requires it despite that this message is shown. -->
    <BaseModal ref="fehlerStatusModal" labelButtonCancel="Ok" :showConfirmButton="false">
      <template v-slot:modalTitle>
        <ph-warning :size="16" class="mr-2" style="color: orangered"/>
        Antrag enthält Fehler.
      </template>
      <div class="var(--color-danger) font-bold">
        <hr>
        Alle Fehler müssen korrigiert werden, um das Dokument erzeugen zu können.
      </div>
    </BaseModal>

    <BaseModal ref="fehlerModal" labelButtonCancel="Ok" :showConfirmButton="false">
      <template v-slot:modalTitle>
        <ph-warning :size="16" class="mr-2" style="color: orangered"/>
        Fehler
      </template>
      <div class="var(--color-danger) font-bold">
        <hr>
        {{serverError}}
      </div>
    </BaseModal>

    <BaseModal ref="videoIdent"  labelButtonConfirm="Ja" labelButtonCancel="Nein" @onConfirmButton="startVideoOnline()" >
      <template v-slot:modalTitle>
        <ph-warning :size="16" class="mr-2" style="color: orangered"/>
        Videolegitimation Vorgang starten
      </template>
      <div class="">
        Die Depoteröffnung wird nun an die FFB übermittelt und Ihr Kunde erhält ein ToDo zur Online Legitimation.<br><br>
Danach kann der Antrag nicht mehr geändert werden.<br><br>
Möchten Sie diesen Vorgang fortsetzen?
      </div>
    </BaseModal>
      <BaseModal ref="infoModal" :showDefaultButtons="false">
          <template v-slot:modalTitle>
             Bitte warten...  <AnimatedSpinner/>
          </template>
          <div>
              <span>Warte auf Antwort vom Webservice... <AnimatedSpinner /></span>
          </div>
      </BaseModal>

  </div>
</template>


<script>
  import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';

  import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue'
  import LOG_TYPES from '@/store/log/types';
  import ANTRAG_TYPES from '@/store/antrag/types';
  import MAILCOMPOSER_TYPES from "@/store/mailcomposer/types";
  import WERTPAPIERORDER_TYPES from '@/store/wertpapierorder/types';
  import DOCUMENT_TYPES from "@/store/documents/types";
  import TAN_TYPES from '@/store/tan/types';
  import BaseModal from '@/components/core/BaseModal.vue';
  import BaseButton from '@/components/core/BaseButton.vue';
  import PictureAttachmentsModal from "@/views/documents/PictureAttachmentsModal.vue";
  import { PhInfo, PhFile, PhWarning } from 'phosphor-vue'
  import { buildMessage } from '../../helpers/log-message-helper';
  import {sanitize} from '@/helpers/string-helper.js';
  import DownloadLink from '@/components/core/download/DownloadLink.vue'
  import axios from 'axios';

  import antragNavigationMixin from '@/mixins/antrag/antrag-navigation-mixin.js';
  import { mapGetters } from 'vuex';
  import CORE_TYPES from '@/store/core/types'
  import { makeQueryParam } from '@/helpers/utils-helper';
  import { viewDocument } from '@/components/core/download/DownloadLink.vue';
  import { openSignoViewDocument } from '@/components/core/download/SignoViewerLink.vue';
  import AnimatedSpinner from "@/components/core/AnimatedSpinner.vue";
  import mixin from "@/mixins/wertpapierorder/mixin.js";


  import { 
    PageHeaderDownloadLinkHrefAction, 
    PageHeaderLoadingAction, 
    PageHeaderSimpleAction, 
    PageHeaderSlotAction,
  } from '@/components/core/header-title-navigation/page-header-utils';

  import EVENT_BUS, { ANTRAG_SIGN_ACTION, ANTRAG_E_SIGNATUR, ANTRAG_CLOSE } from '@/event-bus';

  export default {
    mixins: [antragNavigationMixin, mixin],
    inject: {
      antragCustomNavigation: {
        from: 'antragCustomNavigation',
        default: () => ({}),
      },
    },
    props: {
      title: {
        type: String,
        default: 'Title'
      },
      subtitle: {
        type: String,
        default: ''
      },

      antragData: {
      },
      antrag: {
      },
      antragId: {
      },
      hatPDF:{
        type: Boolean,
        default: false
      },

      /* used not for Formulare, for ex. for Wertpapierorder */
      actionConfigs: {
      },
      antragTyp: {
        type: String,
        default: ''
      },
      warnings: {
      },
      currentStepAdditionalButtons: {
      }, 
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    components: {
      PageHeaderTitleNavigation,
      InputToggleSwitch,
      BaseModal,
      BaseButton,
      PhFile,
      PhInfo,
      PhWarning,
      DownloadLink,
      PictureAttachmentsModal,
      AnimatedSpinner
    },
    data() {
      return {
        eMailVersandEnabled: false,
        serverError: '',
        form: {
          sendToElectronicSignature: false,
          rahmenvereinbarungkundensave: "1",
          agb: false,
          showPDFLangversionSwitch: false,
        },
        selectedDoc: null,
        loadingAdditional: [],
        signoList: [],
        pdfList: [],
        errorStr: '',
        modalPdfType: '',
        berichtList: [],
        berichtDokForm: {},
        pdfWarnings: [],
        pdfInfos: [],
        signoFile: null,
        needsConfButton: undefined,
        hatFehlerConfirm: true,
      }
    },
    watch: {
      antragData: function() {
        this.loadInitialValues();
      },
    },
    computed: {
      ...mapGetters({
        apiAddress: CORE_TYPES.GETTERS.API_ADDRESS,
        token: CORE_TYPES.GETTERS.GET_TOKEN,
        isOriginalUserBroker: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_BROKER,
        isOriginalUserIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
        isCustomerLogin: CORE_TYPES.GETTERS.IS_CUSTOMER_ONLY,
        isAntragActionLoading: ANTRAG_TYPES.GETTERS.IS_ANTRAG_ACTION_LOADING,
        isSomeButtonLoading: ANTRAG_TYPES.GETTERS.IS_SOME_ANTRAG_ACTION_LOADING_ACTIVE,
        isComdirectLoginRequired: TAN_TYPES.GETTERS.IS_COMDIRECT_TAN_REQUIRED,
        simpleFileLinkmaker: DOCUMENT_TYPES.GETTERS.GET_SIMPLE_FILE_LINK_MAKER,
      }),
      headerActions() {
        if (this.isCustomerLogin) {
          return [
          (this.hasMultiplePdf 
              ? PageHeaderSimpleAction('PDF', 'PDF anzeigen') 
                .withVisible(() => this.actionStep && !this.actionStepConfig?.['hidePDF'] && (!this.isWebserviceAktiv || (this.isWebserviceAktiv && this.hasPDF)))
                .withDisabled(() => this.isPdfButtonDisabled)
              : PageHeaderDownloadLinkHrefAction('PDF', 'PDF anzeigen', '', this.getPDFLink)
                .withVisible(() => this.actionStep && !this.actionStepConfig?.['hidePDF'] && (!this.isWebserviceAktiv || (this.isWebserviceAktiv && this.hasPDF)))
                .withDisabled(() => this.isPdfButtonDisabled)),

            PageHeaderSimpleAction('SAVE', 'PDF anzeigen')
              .withVisible(() => this.actionStep && !this.actionStepConfig?.['hidePDF'] && (this.isWebserviceAktiv && !this.hasPDF))
              .withDisabled(() => this.isPdfButtonDisabled),

            PageHeaderLoadingAction('SIGN', 'PDF signieren', () => this.isAntragActionLoading('sign') || this.isAntragActionLoading('zusatzDok'))
              .withVisible(() => this.actionStep && !this.actionStepConfig?.['hideESignatur'])
              .withDisabled(() => this.isPdfButtonDisabled),
          ];
        } else {
          return [
            PageHeaderSimpleAction('ACTIVATE_TAN', 'TAN aktivieren')
              .withVisible(() => this.actionStep && !this.actionStepConfig?.['hidePDF'] && this.isComdirectLoginRequired && this.lagerstelle === 'WP' )
              .withDisabled(() => !this.isComdirectLoginRequired),

            (this.hasMultiplePdf 
              ? PageHeaderSimpleAction('PDF', 'PDF anzeigen') 
                .withVisible(() => this.actionStep && !this.actionStepConfig?.['hidePDF'] && (!this.isWebserviceAktiv || (this.isWebserviceAktiv && this.hasPDF)))
                .withDisabled(() => this.isPdfButtonDisabled)
              : PageHeaderDownloadLinkHrefAction('PDF', 'PDF anzeigen', '', this.getPDFLink)
                .withVisible(() => this.actionStep && !this.actionStepConfig?.['hidePDF'] && (!this.isWebserviceAktiv || (this.isWebserviceAktiv && this.hasPDF)))
                .withDisabled(() => this.isPdfButtonDisabled)),

            PageHeaderSimpleAction('SAVE', 'PDF anzeigen')
              .withVisible(() => this.actionStep && !this.actionStepConfig?.['hidePDF'] && (this.isWebserviceAktiv && !this.hasPDF))
              .withDisabled(() => this.isPdfButtonDisabled),

            PageHeaderLoadingAction('SIGN', 'PDF signieren', () => this.isAntragActionLoading('sign') || this.isAntragActionLoading('zusatzDok'))
              .withVisible(() => this.actionStep && !this.actionStepConfig?.['hideESignatur'])
              .withDisabled(() => this.isPdfButtonDisabled),

              PageHeaderLoadingAction('ONLINE_LEGI', 'Online Legitimation', () => this.isAntragActionLoading('online')) 
              .withVisible(() => this.actionStep && this.actionStepConfig?.['hideESignatur'] && this.actionStepConfig?.['showVideoIdent'])
              .withDisabled(() => this.isPdfButtonDisabled),

            PageHeaderLoadingAction('EMAIL', 'E-Mail', () => this.isAntragActionLoading('email'))
              .withVisible(() => this.actionStep && !this.actionStepConfig?.['hideSendEmail'] && this.antragId !== 'FK-dokumentationgrundlage')
              .withDisabled(() => this.isPdfButtonDisabled || !this.antragId ),

            PageHeaderLoadingAction('FAX', 'Fax', () => this.isAntragActionLoading('fax'))
              .withVisible(() => this.actionStep && !this.actionStepConfig?.['hideSendFax'] && this.antragId !== 'FK-dokumentationgrundlage')
              .withDisabled(() => this.isPdfButtonDisabled || !this.antragId ),

            PageHeaderLoadingAction('AUFTRAGSDOKUMENTE', 'Auftragsdokumente', () => this.isAntragActionLoading('auftragsdokumente'))
              .withVisible(() => this.actionStep && !this.actionStepConfig?.['hideAuftragsdokumente'])
              .withDisabled(() => this.isPdfButtonDisabled),

            PageHeaderSimpleAction('VOLLMACHT', 'FFB Depotvollmacht anlegen')
              .withVisible(() => this.actionStepConfig?.['FFBVollmacht'])
              .withDisabled(() => this.isSomeButtonLoading || !this.antragId || this.disabled || !this.hasPDF),

            ...this.headerAdditionalStepActions,

            PageHeaderSlotAction('TOGGLE-AGB')
              .withVisible(() => this.actionStep && !this.actionStepConfig?.['hideAGB']),

            PageHeaderSimpleAction('TOGGLE-E-SIGNATUR', this.antragData?.IS_VORGELEGT ? 'E-Signatur zurückziehen' : 'Zur e-Signatur vorlegen')
              .withVisible(() => (this.isOriginalUserBroker || this.isOriginalUserIntern) && this.actionStep && !this.actionStepConfig['hideESignatur'] && !this.antragData?.READ_ONLY)
              .withDisabled(() => this.isPdfButtonDisabled),

            PageHeaderSlotAction('TOGGLE-RAHMEN-KUNDEN-SAVE')
              .withVisible(() => this.actionStep && this.actionStepConfig?.['rahmendaten']),

            PageHeaderSlotAction('TOGGLE-SHOW-PDF-LANGVERSION')
              .withVisible(() => this.actionStepConfig?.['showPDFLangversionSwitch']),

            PageHeaderSimpleAction('COPY_SIGNED_ANTRAG', this.$route.params?.antragsName == 'vvwvertrag' ? 'Neuen Vertrag erstellen der bearbeitet werden kann' : 'Erstellt eine (unsignierte) Kopie die bearbeitet werden kann.')
              .withVisible(() => this.actionStep && !!this.actionStepConfig?.['copySignedAntrag']),
            
          ];
        }
      },
      headerAdditionalStepActions() {
        const { currentStepAdditionalButtons, } = this;
        if(!currentStepAdditionalButtons?.length) return [];
        return [...currentStepAdditionalButtons].map(button => {
          return PageHeaderLoadingAction('ADDITIONAL', button.label, () => !!this.loadingAdditional.find(actionButton => actionButton === button.id))
            .withValue(button)
            .withIcon(button?.config?.gebuehrenpflichtig ? 'PhCurrencyEur' : undefined)
            .withDisabled(() => this.antragData?.antragsBezeichnung == 'Kunden Deckblätter' ? false : (this.disabled || button?.disabled));
        });
      },
      hasMultiplePdf() {
        return this.antragData?.hasMultiplePdf;
      }, 
      isPdfButtonDisabled() {
        if(this.isWebserviceAktiv ){
          return  this.hasFehlerStatusWebservice || this.isSomeButtonLoading || this.disabled || (this.antragId == 'EBASE_AAB-depoteroeffnungwphg' && !this.antragData.INPUT_RADIO_BUTTON_ANLAGESTRATEGIEN)//it is a Double action Btn (if no pdf data -> call WS and produce PDF data, otherwise just show the file)
        }
        return !this.hasPDF || (!this.antragsdatenId  && !this.protokollId && !this.beratungsMappeId && !this.paramId) || this.hasFehlerStatus|| this.isSomeButtonLoading || this.disabled;
      },
       isWebserviceAktiv(){
        return this.antragData?.isWebserviceAktiv;
      },
      requiresComdirectLogin(){
        return this.antragData?.requiresComdirectLogin && (this.antragData?.lagerstelle === 'COMDIRECT' || this.lagerstelleFixed === 'COMDIRECT');
      },
      confirmText() {
        return this.needsConfButton ? this.needsConfButton.config.confirmText : '';
      },
      confirmTitle() {
        return this.needsConfButton ? this.needsConfButton.config.confirmTitle : '';
      },
      isAntragSaved() {
        return this.antragData && (this.antragData['antragsdatenId'] || this.antragData['antragsnrIntern'])
          && !this.antrag?.dataHasChanged;
      },
      isWertpapierorderSaved() {
        return !this.antragData?.dataHasChanged;
      },
      isWertpapierorderPositionDataSaved() {
        return !this.antragData?.positionDataHasChanged;
      },
      antragsdatenId() {
        return this.antragData && this.antragData['antragsdatenId'];
      },
      protokollId() {
        return this.antragData && this.antragData['PARAMETER_PROTOKOLL_ID'];
      },
      paramId() {
        return this.antragData && this.antragData['PARAM_ID'];
      },
      beratungsMappeId() {
        return this.antragData && this.antragData['beratungsMappeId'];
      },
      lagerstelle() {
        return this.$route.params.lagerstelle
      },
      actionStep() {
        if (this.antrag && Array.isArray(this.antrag.steps)) {
          const found = this.antrag.steps.some(st => st.stepKey === 'aktionen')
          return found;
        }
        return true; // not sure if the buttons are used by non-forms
      },
      actionStepConfig() {
        if (this.antrag && Array.isArray(this.antrag.steps)) {
          const found = this.antrag.steps.find(st => st.stepKey === 'aktionen')

          if (found && found.config) {
            return found.config
          }
        }
        if (this.actionConfigs) {
          return this.actionConfigs;
        }
        return {}
      },
      sortWarnings() {
        let sortWarnings = [];
        if (this.warnings) {
          sortWarnings.push(...(this.warnings['positionWarnings'] || []));//.sort((a, b) => (a.title || '').localeCompare(b.title || '')));
          sortWarnings.push(...(this.warnings['antragWarnings'] || [])); //.sort((a, b) => (a.title || '').localeCompare(b.title || '')));
        }
        // const sortStatus = sortWarnings.sort((a, b) => (a.status === 'FEHLER' ? -1 : (b.status === 'FEHLER' ? 1 : 0)));
        return sortWarnings;
      },
      hasFehlerStatus() {
        return this.sortWarnings?.length && this.sortWarnings.some(warn => warn.status === 'FEHLER');
      },
      hasFehlerStatusWebservice(){
         //when a webservice error occurs pdf button should be active so that webservice cal be called again to reevaluate the error
          return this.sortWarnings?.length && this.sortWarnings.some(warn => warn.status === 'FEHLER' && !warn?.message.startsWith('Fehler vom Webservice')); 
      },
      getPDFLink() {
             const simplifiedData = {
              beratungsMappeId: this.antragData && this.antragData['beratungsMappeId'],
              antragsnrIntern: this.antragData && this.antragData['antragsnrIntern'],
              PARAMETER_PROTOKOLL_ID: this.antragData && this.antragData['PARAMETER_PROTOKOLL_ID'],
              PARAM_ID: this.antragData && this.antragData['PARAM_ID'],
        };

        const payload = {
          data: simplifiedData,
          antragsdatenId: this.antragsdatenId,
          antragsnrIntern: this.antragData && this.antragData['antragsnrIntern'],
          lagerstelle: this.lagerstelle,
          id: this.antragId,
          action: "pdf",               
          confirmed: true,
          antragTyp: this.antrag && this.antrag.antragTyp || this.antragTyp
        }        

        const apiAddress = this.apiAddress
        const token = this.token
        const input = btoa(JSON.stringify(payload));

        const params = makeQueryParam({ input, token })

        const fileName = (this.antragId || 'antrag') + '.pdf';
        
        return `${apiAddress}/download_service/generate_antrag_pdf/${fileName}?${params}`;    
      },
      getBerichtErstellenLink() {
        let concatIds;
        if (this.berichtDokForm) {
          let checks = Object.keys(this.berichtDokForm);
          concatIds = checks.filter(name => this.berichtDokForm[name]);
        }

        if (!this.antragData) {
          return ""
        }

        const payload = {
          concatIds: concatIds,
          antragsdatenId: this.antragsdatenId,
          antragsnrIntern: this.antragData['antragsnrIntern'],
          lagerstelle: this.lagerstelle,
          id: this.antragId,
          action: 'createReport',
          antragTyp: this.antrag && this.antrag.antragTyp || this.antragTyp
        }

        const apiAddress = this.apiAddress
        const token = this.token
        const input = btoa(JSON.stringify(payload));

        const params = makeQueryParam({ input, token })
        
        return `${apiAddress}/download_service/get_antrag_report/auftragsdokumente.pdf?${params}`;
      },
      hasPDF() {      
        return  this.hatPDF || (this.antrag && this.antrag['hatPDF']);       
      }
    },
    mounted() {
      this.loadInitialValues();
      this.registerEventBus();
    },
    destroyed() {
      this.unregisterEventBus();
    },
    methods: {
      copySignedAntrag(action) {
        this.$store.commit(ANTRAG_TYPES.MUTATIONS.START_ANTRAG_ACTION_LOADING, action);

        axios.post(`${process.env.VUE_APP_API}/antragButton/copyAntrag?antragsdatenId=${this.antragsdatenId}&beratungsmappeId=${this.beratungsMappeId}`, {}, 
        {defaultSpinner: true, cancelableRequest: { title: 'Zusätzliche Dokumente' }}).then(response => {
          if (response && response.data) {            

            this.$router.push({
              path: this.$route.path,
              query: { ...this.$route.query, antragsdatenId: response.data}
            }).then(() => this.$store.commit(CORE_TYPES.MUTATIONS.POP_SAVE_BACK_TO_PREVIOUS_PAGE))
          }
        }).finally(() => {
          this.$store.commit(ANTRAG_TYPES.MUTATIONS.STOP_ANTRAG_ACTION_LOADING, action);
        }) 
      },
      updateLoadingStatus(value) {
        const action = 'zusatzDok';
        this.$store.commit(ANTRAG_TYPES.MUTATIONS.SET_ANTRAG_ACTION_LOADING, { action, value, });
      },
        sanitize(htmlString) {
            return sanitize(htmlString);
        },
      loadInitialValues() {
        if (this.antragData) {
          // const inputUnterschrift = this.antragData['INPUT_UNTERSCHRIFT'] || this.antragData['input_unterschrift'];
          const inputUnterschrift = this.antragData['IS_VORGELEGT'] || this.antragData['is_vorgelegt'];

          const isPositiveRegex = /(1|j[a]?)/gi;
          const booleanValue = [1, true].some(available => available === inputUnterschrift) || isPositiveRegex.test(inputUnterschrift);

          this.form.sendToElectronicSignature = booleanValue;
          this.form.agb = this.antragData['INPUT_CHECK_INCLUSIVE_AGB'] || false
          if (this.actionStepConfig['rahmendaten']) {
            this.form.rahmenvereinbarungkundensave = this.antragData['INPUT_CHECK_SAVE_KUNDENDATEN'] || "0"
          }
          if (this.antragData.check_verwende_langversion != null) {
            this.form.showPDFLangversionSwitch = this.antragData.check_verwende_langversion;
          }

          this.$store.commit(TAN_TYPES.MUTATIONS.SET_COMDIRECT_TAN_REQUIRED, {
            value: this.antragData?.requiresComdirectLogin,
            antragId: this.antragId
          })
        }
      },

      doClickFFBButtons(path) {
        this.$router.push({
          path: `/beratung/formulare/antrag/${this.$route.params.lagerstelle}/${path}`,
          query: { lagerstelleLabel: this.$route.query?.lagerstelleLabel, ffbDepoteroeffnungId: this.antragsdatenId}
        })
      },

      doClickAdditional(button) {
        if(!button) return;

        button.loading = true;
        if (button.config?.needsConfirmation) {
          this.needsConfButton = button;
          this.needsConfButton.confirmed = false;
          this.$refs.needsConfirmation.open();
        } else {
          this.needsConfButton = undefined;
          this.doClick('additional', button);
        }
      },

      confirm(confirm) {
        this.needsConfButton.confirmed = confirm;
        this.doClick('additional', this.needsConfButton);
      },
      closeNeedsConfirmationModal() {
        this.needsConfButton.confirmed = false;
        this.$refs.needsConfirmation.close();
      },

      doClick(action, actionButton) {
        if (['pdf', 'sign', 'online'].includes(action)) {
          if(this.antragId == 'wertpapierorder'){
             this.getMeldungEinreichung();
             this.navigateToStepWPO('aktionen');
          }   
        }
          if (actionButton?.clickFn) {
            actionButton.clickFn();
          } else {
            if (actionButton) {
              this.loadingAdditional.push(actionButton.id);
            }

            this.errorStr = null;
            this.pdfWarnings = null;
            this.pdfInfos = null;
            this.doOpenPdfOrSign(action, actionButton)
        }
      },

      berichtVersenden() {
        let concatIds;
        if (this.berichtDokForm) {
          concatIds = Object.keys(this.berichtDokForm).filter(name => this.berichtDokForm[name]);
        }
        if (this.antragData) {
          this.$store.commit(ANTRAG_TYPES.MUTATIONS.START_ANTRAG_ACTION_LOADING, 'auftragsdokumente');
          const payload = {
            data: this.antragData,
            concatIds: concatIds,
            antragsdatenId: this.antragsdatenId,
            antragsnrIntern: this.antragData['antragsnrIntern'],
            lagerstelle: this.lagerstelle,
            id: this.antragId,
            action: 'sendReport',
            antragTyp: this.antrag && this.antrag.antragTyp || this.antragTyp
          }
          this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.SEND_BERICHT_PDF, payload).then(response => {
            if (response && response.data) {
              const responseData = response.data
              if (responseData.errorMessage) {
                this.errorStr = responseData.errorMessage;
              } else {
                if(this.antragsdatenId){
                  this.$router.push(`/communication/mailcomposer-auftragsdokumente/${this.antragsdatenId}`)
                }
              }
            }
            this.$store.commit(ANTRAG_TYPES.MUTATIONS.STOP_ANTRAG_ACTION_LOADING, 'auftragsdokumente');
            this.closeBerichtListModal();
          }).catch(err => {
            this.errorStr = err && err.message;
            if (err.response && err.response.data) {
              this.serverError = err.response.data.message;
              this.$refs.fehlerModal.open();
            }
            this.$store.commit(ANTRAG_TYPES.MUTATIONS.STOP_ANTRAG_ACTION_LOADING, 'auftragsdokumente');
          });
        }
      },

      pdfNeuErstellen() {
        this.$refs.teilweise.close();
        const payload = {
          data: this.antragData,
          lagerstelle: this.lagerstelle,
          id: this.antragId,
          action: 'sign',
          antragTyp: this.antrag.antragTyp
        }

        this.getSignedFiles(payload, 'recreatePdf');
      },

      showSignoListFromTeilweise() {
        this.$refs.teilweise.close();
        setTimeout(() => {
          this.$refs.multisignable.open()
        }, 300);
      },

      closeBerichtListModal() {
        this.$refs.berichtDok.close();
        this.berichtList = null;
        this.berichtDokForm = {};
      },

      updateAgbProperty(agb) {
        const payload = {
          id: this.antragId,
          data: {
            INPUT_CHECK_INCLUSIVE_AGB: agb
          }
        }
        if (this.antragId === 'wertpapierorder') {
          this.$store.commit(WERTPAPIERORDER_TYPES.MUTATIONS.UPDATE_DATA, payload.data);
        } else if (this.antrag) {
          this.updateAntragData(payload.data)
          this.saveAntrag()
        }
      },

      async sendFormToElectronicSignature() {
        await this.saveAntragBeforeAction('TOGGLE-E-SIGNATUR');

        if (this.antragId == 'wertpapierorder') {
          this.$store.dispatch(WERTPAPIERORDER_TYPES.ACTIONS.TO_SIGNATURE_VORLEGEN, {
            antragsdatenId: this.antragsdatenId, data: this.antragData });
        } else if (this.antragsdatenId || this.antragId === 'VERSICHERUNG-beratungsdokumentationVersicherungen') {
          this.$store.dispatch(ANTRAG_TYPES.ACTIONS.TO_SIGNATURE_VORLEGEN, {
            antragId: this.antragId, antragsdatenId: this.antragsdatenId, protokollId: this.protokollId, data: this.antragData });
        }
      },

      sendFormRahmenKundenSave(value) {
        const payload = {
          id: this.antragId,
          data: {
            INPUT_CHECK_SAVE_KUNDENDATEN: value
          }
        }
        this.$store.dispatch(ANTRAG_TYPES.ACTIONS.UPDATE_ANTRAG_DATA, payload);
      },

      setShowPDFLangversion(value) {
        const payload = {
          id: this.antragId,
          data: {
            CHECK_VERWENDE_LANGVERSION: value,
            check_verwende_langversion: value
          }
        }
        this.$store.dispatch(ANTRAG_TYPES.ACTIONS.UPDATE_ANTRAG_DATA, payload);
        this.saveAntrag()
      },

      async saveData() {
        if (this.antragId !== 'wertpapierorder') {
          await this.saveAntrag()
        } else if (this.antragId === 'wertpapierorder') {
          if (!this.isWertpapierorderPositionDataSaved) {
            await this.$store.dispatch(WERTPAPIERORDER_TYPES.ACTIONS.SAVE_POSITION_DATA);
          } else if (!this.isWertpapierorderSaved) {
            await this.$store.dispatch(WERTPAPIERORDER_TYPES.ACTIONS.SAVE_WERTPAPIERORDER);
          }
        }
      },

      async doOpenPdfOrSign(action, actionButton) {
        try {
          await this.saveAntragBeforeAction(action);

          // without setTimeout save wertpapierorder is called after pdf request
          setTimeout(() => {
            if (this.hasFehlerStatus) {
              this.$refs.fehlerStatusModal.open();
              actionButton && this.loadingAdditional.pop(actionButton?.id);
              return;
            }

            const payload = {
              data: this.antragData,
              lagerstelle: this.lagerstelle,
              id: this.antragId,
              action: action,               //  enum
              actionButton: actionButton?.id,   //  string for additional buttons
              button: actionButton,
              confirmed: this.needsConfButton ? this.needsConfButton.confirmed : true,
              antragTyp: this.antrag && this.antrag.antragTyp || this.antragTyp
            }
          
            switch (action) {
              case 'additional':
                this.getFileData(payload);
                break;
              case 'pdf':
                // für webservice Formulare gebraucht
                 this.getFileData(payload);
                break;
              case 'sign':
                this.getSignedFiles(payload, 'sign');
                break;
              case 'online':
                this.$refs.videoIdent.open();
                break;
              case 'auftragsdokumente':
                this.getBerichtFiles(payload);
                break;
              case 'email':
                if(this.antragsdatenId){
                  this.$router.push({ path:`/communication/mailcomposer-antrag/${this.antragsdatenId}`, query: { noSave: true } });
                } else if (this.protokollId) {
                  this.$router.push({ path:`/communication/mailcomposer-versdoku/${this.protokollId}`, query: { noSave: true } });
                } else {
                  if (payload.button)
                    payload.button.loading = false;
                  this.loadingAdditional.pop(payload.actionButton);
                  this.$refs.fehlerStatusModal.open();
                }
                break;
              case 'fax':
                if(this.antragsdatenId){
                  this.$router.push(`/communication/mailcomposer-fax-antrag/${this.antragsdatenId}`);
                } else if (this.protokollId) {
                  this.$router.push({ path:`/communication/mailcomposer-fax-versdoku/${this.protokollId}`, query: { noSave: true } });
                } else {
                  if (payload.button)
                    payload.button.loading = false;
                  this.loadingAdditional.pop(payload.actionButton);
                  this.$refs.fehlerStatusModal.open();
                }
                
                break;
            }
          }, 1000);
        } catch (error) {
          this.errorStr = error
        }
      },

      getSignedFiles(data, pdfType) {
        this.$store.commit(ANTRAG_TYPES.MUTATIONS.START_ANTRAG_ACTION_LOADING, 'sign');
        this.$store.dispatch(ANTRAG_TYPES.ACTIONS.OPEN_PDF, data).then(response => {
          this.errorStr = null;

          if (response && response.data) {
            this.signoFile = response.data
            const responseData = response.data

            if (responseData.warnings && responseData.warnings.length) {
              this.pdfWarnings = responseData.warnings == null ? null
                : responseData.warnings.sort((a, b) => (a.status === 'FEHLER' ? -1 : (b.status === 'FEHLER' ? 1 : 0)));
              this.$refs.hatFehler.open()
              this.modalPdfType = pdfType;
            } else if (responseData.errorMessage) {
              this.errorStr = responseData.errorMessage;
            } else {
              this.handleSignatures(data?.actionButton === 'close')
            }
          }
        })
          .catch(err => {
            this.errorStr = err && err.message;
          })
          .finally(() => {
            this.$store.commit(ANTRAG_TYPES.MUTATIONS.STOP_ANTRAG_ACTION_LOADING, 'sign');
          })
      },

      getBerichtFiles(data) {
        if ((data && data.data && data.data['antragsdatenId'])) {
          data['antragsdatenId'] = data.data['antragsdatenId'];
          this.$store.commit(ANTRAG_TYPES.MUTATIONS.START_ANTRAG_ACTION_LOADING, 'auftragsdokumente');
          this.$store.dispatch(ANTRAG_TYPES.ACTIONS.OPEN_BERICHT, data).then(response => {
            this.errorStr = null;
            if (response && response.data) {
              const responseData = response.data;
              if (responseData.warnings && responseData.warnings.length) {
                this.pdfWarnings = responseData.warnings == null ? null
                  : responseData.warnings.sort((a, b) => (a.status === 'FEHLER' ? -1 : (b.status === 'FEHLER' ? 1 : 0)));
                this.berichtList = responseData.berichtList;
                this.$refs.hatFehler.open()
              } else if (responseData.errorMessage) {
                this.errorStr = responseData.errorMessage;
              } else if (responseData.berichtList && responseData.berichtList.length) {
                this.berichtList = responseData.berichtList;
                this.openBerichtDokModal(responseData);
              }
            } else {
              this.$store.commit(ANTRAG_TYPES.MUTATIONS.STOP_ANTRAG_ACTION_LOADING, 'auftragsdokumente');
              this.$refs.fehlerStatusModal.open();
            }
          })
            .catch(err => {
              this.errorStr = err && err.message;
            })
            .finally(() => {
              this.$store.commit(ANTRAG_TYPES.MUTATIONS.STOP_ANTRAG_ACTION_LOADING, 'auftragsdokumente');
            })
        } 
      },

      getFileData(data) {

        if ((data && data.data && data.data['antragsdatenId']) 
            || data.action === 'additional' || this.antragId === 'wertpapierorder'
            || this.antragId === 'VERSICHERUNG-beratungsdokumentationVersicherungen' 
            || this.antragId === 'FK-dokumentationgrundlage' || this.antragId === 'FK-dokumentationergebnis') {
            
          this.$store.commit(ANTRAG_TYPES.MUTATIONS.START_ANTRAG_ACTION_LOADING, data.action);
          this.$store.dispatch(ANTRAG_TYPES.ACTIONS.OPEN_PDF, data).then(response => {
            if (response && response.data) {
              const responseData = response.data

              if (responseData.warnings && responseData.warnings.length) {
                this.pdfWarnings = responseData.warnings;
                this.modalPdfType = 'pdf'
                this.hatFehlerConfirm = responseData.hatFehlerConfirm
                this.$refs.hatFehler.open()
              }
              if (responseData.infos && responseData.infos.length) {
                this.pdfInfos = responseData.infos;
                this.$refs.hatInfo.open()
              }
              if (responseData.file && (this.pdfWarnings || this.pdfInfos)) {
                this.bytes = responseData.file.bytes;
              } else if (responseData.file && !(this.pdfWarnings || this.pdfInfos)) {
                this.showFileContent(responseData.file.bytes);
              }
            }
          })
            .catch(err => {
              this.errorStr = err && err.message;
            })
            .finally(() => {
              if (data.button)
                data.button.loading = false;
              this.$store.commit(ANTRAG_TYPES.MUTATIONS.STOP_ANTRAG_ACTION_LOADING, data.action);
              this.loadingAdditional.pop(data.actionButton);
            })
        } else {
          if (data.button)
            data.button.loading = false;
          this.loadingAdditional.pop(data.actionButton);
          this.$refs.fehlerStatusModal.open();
        }
      },
      showFileContent(bytes) {
        if (!bytes) {
          this.openModal(this.noDataModal, false);
        } else {
          this.openFile(bytes, 'application/pdf', this.antragId === 'wertpapierorder' ? 'wertpapierorder':'Antrag');
        }
      },

      openFile(byteArr, contentType, fileName) {
        viewDocument({
          data: byteArr,
          contentType,
          filename: fileName,
        })
      },

      handleSignatures(toClose = false) {
        const signatures = this.signoFile.signatures
        if (signatures.length === 1) {
          this.handleSingleSignature(signatures[0], false, toClose)
        } else if (signatures.length > 1) {
          this.signoList = signatures;
          if (this.signoFile.signaturStatus === 'KOMPLETT') {
            this.$refs.multisignable.toClose = toClose;
            this.$refs.multisignable.open();
          } else if (this.signoFile.signaturStatus === 'TEILWEISE') {
            this.$refs.teilweise.toClose = toClose;
            this.$refs.teilweise.open()
          } else {
            this.$refs.multisignable.toClose = toClose;
            this.$refs.multisignable.open();
          }
        } else {
          this.errorStr = 'Es liegen keine Dokumente vor.';
          this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(this.errorStr, 'primary'))
        }
      },
      handleSingleSignature(signoItem, fromMultiSignModal = false, toClose = false) {
        if (signoItem.signaturStatus === 'KOMPLETT') {
          if (fromMultiSignModal) {
            this.$refs.multisignable.close()
            this.closeMultisignModal()
          }
          setTimeout(() => {
            this.$refs.komplett.open();
          }, 200);
        } else if (signoItem.isPictureAttach) {
          if (fromMultiSignModal) {
            this.$refs.multisignable.close()
            this.closeMultisignModal()
          }
          this.selectedDoc = signoItem.signoElement
          setTimeout(() => {
            this.$refs.pictureAttachmentsModal.emitClose = toClose;
            this.$refs.pictureAttachmentsModal.open();
          }, 200);
        } else if (signoItem.url) {
          openSignoViewDocument(signoItem.url, signoItem.fileName);
          if (toClose) {
            EVENT_BUS.$emit(ANTRAG_CLOSE);
          }
        } else {
          this.errorStr = 'Es liegen keine Dokumente vor.';
          this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(this.errorStr, 'primary'))
        }
      },
      confirmFehlerModal() {
        // this.$refs.hatFehler.close();
        setTimeout(() => {
          if (this.modalPdfType === 'sign') {
            this.handleSignatures()
          } else {
            this.showFileContent(this.bytes);
          }
          this.closeFehlerModal();
        }, 100);
      },
      closeMultisignModal(){

        this.bytes = null;
      
        this.reloadAntrag(); //relaod antrag to evaluate if any signitures are present and disable components
      },
       reloadAntrag() {
      let payload = {
        id: this.antragId,
        beratungsMappeId: this.antragData.beratungsMappeId,
        antragsdatenId: this.antragData.antragsdatenId,
        lagerstelle: this.$route.params.lagerstelle,
        antragsName: this.$route.params.antragsName,
        dynamicFormId: this.$route.query?.dynamicFormId,
      };
      this.$store.commit(
        ANTRAG_TYPES.MUTATIONS.SET_ANTRAG_RELOAD_PAYLOAD,
        payload
      );
      return this.$store.dispatch(ANTRAG_TYPES.ACTIONS.RELOAD_ANTRAG, {
        id: this.antragId,
        antragsdatenId: this.antragData.antragsdatenId,
      });
    },

      closeFehlerModal() {
        this.modalPdfType = null;
        this.bytes = null;
        this.pdfWarnings = null;
      },

      closeInfoModal() {
        this.pdfInfos = null;
        setTimeout(() => {
          if (this.bytes != null)
            this.showFileContent(this.bytes);
          this.bytes = null;
          //this.$refs.hatInfo.close();
        }, 100);
      },

      openBerichtDokModal(responseData) {
        if (this.berichtList && this.berichtList.length) {
          this.berichtDokForm = {}
          this.berichtList.forEach(dok => this.$set(this.berichtDokForm, dok.name, dok.check));
          this.eMailVersandEnabled = responseData.eMailVersand;
          this.$refs.berichtDok.open();
        }
      },
      async startVideoOnline() {
        if (this.antragId === 'wertpapierorder') {
          return;
        }
        const response = await this.$store.dispatch(ANTRAG_TYPES.ACTIONS.START_VIDEO_INDENT, this.antrag.data.antragsnrIntern);
        if (response.data) {
          if (response.data.ok) {
            const antrag = this.antrag;
            const components = JSON.parse(JSON.stringify(antrag.components));
            Object.keys(components).forEach(key => {
              components[key].forEach(comp => {
                comp.disabled = {IMMER: 'IMMER'};
              })
            });
            antrag.components = components;
            this.updateAntragData({ HAT_VIDEO_LINK: true})
            this.doClick('email');
          }
        }
      },
      registerEventBus() {
        // Sign Action
        EVENT_BUS.$on(ANTRAG_SIGN_ACTION, (step, stepConfig, close) => {
          if((this.actionStep || step) && (!this.actionStepConfig?.['hideESignatur'] || !stepConfig) && !this.isSomeButtonLoading && !this.disabled) {
            this.doClick('sign', close ? { id: 'close' } : null);
          }
        });

        // E-Signatur
        EVENT_BUS.$on(ANTRAG_E_SIGNATUR, (value, step, stepConfig) => {
          if((this.isOriginalUserBroker || this.isOriginalUserIntern) && (this.actionStep || step) && (!this.actionStepConfig['hideESignatur'] || !stepConfig)) {
            this.sendFormToElectronicSignature();
          }
        });
      },
      unregisterEventBus() {
        EVENT_BUS.$off(ANTRAG_SIGN_ACTION);
        EVENT_BUS.$off(ANTRAG_E_SIGNATUR);
      },
       getMeldungEinreichung() { 
           this.$store.dispatch(WERTPAPIERORDER_TYPES.ACTIONS.GET_MELDUNG_EINREICHUNG);      
      },
      async webserviceActivateTan(){
        const result = await this.$store.dispatch(TAN_TYPES.ACTIONS.ACTIVATE_COMDIRECT_TAN, {showInstantSuccess: true, updateAntrag: true })
        if (result?.message){
          this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, result.message);
        }
      },
     async webserviceSync(){
      if( this.antragId && this.antragId == 'wertpapierorder'){
        this.$refs.infoModal.open();
        this.antragData.synchronisieren = true;
        this.antragData.dataHasChanged = true;
        await this.doSave(this.antragData).then((response)=>{
          this.antragData.synchronisieren = false;
          this.antragData.dataHasChanged = false;
          this.$refs.infoModal.close();
          this.doClick('pdf');                                        
      })} else {
        this.doClick('pdf');
      }
    },
    async saveAntragBeforeAction(action) {
      if (this.antragId !== 'wertpapierorder' && !this.disabled) {
        if((this.antragId !== 'FODB-depoteroeffnung' && this.antragId !== 'FODB-depoteroeffnungonline') && this.antragId !== 'FFB-depoteroeffnungonline' && !this.isWebserviceAktiv) {   //fodb webservice           
          this.updateAntragData()
          await this.saveAntrag(action)
        
        } else if(this.isWebserviceAktiv) {
          if('pdf' === action) {
            this.$refs.infoModal.open();
            this.setWebserviceSync()  
            await this.saveAntrag().then(() => {
              this.antragData.synchronisieren = false;
              
            }).finally(() => {
              this.$refs.infoModal.close(); 
            })
          } else if(!this.hasPDF || this.antrag?.dataHasChanged) {
            this.setWebserviceSync()
            await this.saveAntrag(action)
          }
        }
      } else if (this.antragId === 'wertpapierorder') {
        if(this.isWebserviceAktiv  && (!this.antragData?.hatPDF || !this.isWertpapierorderSaved || !this.isWertpapierorderPositionDataSaved )) {

          this.antragData.synchronisieren = 'true';

          const payload = {
            id: this.antragId,
            data: this.antragData
          }
          
          if(!this.isWertpapierorderSaved || !this.hasPDF) {
            this.$store.commit(WERTPAPIERORDER_TYPES.MUTATIONS.UPDATE_DATA, payload)
            await this.$store.dispatch(WERTPAPIERORDER_TYPES.ACTIONS.SAVE_WERTPAPIERORDER);
          } else if(!this.isWertpapierorderPositionDataSaved || !this.hasPDF) {
            this.$store.commit(WERTPAPIERORDER_TYPES.MUTATIONS.UPDATE_POSITION_DATA_STORE, payload)
            await this.$store.dispatch(WERTPAPIERORDER_TYPES.ACTIONS.SAVE_POSITION_DATA);
          }
        
        } else {
          if (!this.isWertpapierorderPositionDataSaved) {
            await this.$store.dispatch(WERTPAPIERORDER_TYPES.ACTIONS.SAVE_POSITION_DATA);
          } else if (!this.isWertpapierorderSaved) {
            await this.$store.dispatch(WERTPAPIERORDER_TYPES.ACTIONS.SAVE_WERTPAPIERORDER);
          }
        }
      }
    },
    getPdfList() {
      this.getMeldungEinreichung();
      if (this.hasMultiplePdf) {
        this.$store.commit(ANTRAG_TYPES.MUTATIONS.START_ANTRAG_ACTION_LOADING, 'pdf');
        this.$store.dispatch(ANTRAG_TYPES.ACTIONS.GET_PDF_LIST, this.antragsdatenId).then(response => {
          this.pdfList = response?.data;
          if (this.pdfList?.length > 1) {
            this.$refs.multi_pdf.open();
          }
        }).finally(() => {
          this.$store.commit(ANTRAG_TYPES.MUTATIONS.STOP_ANTRAG_ACTION_LOADING, 'pdf');
        });
      }
    },
    getPDFLinkForMultiPdf({fileName, fileId}) {
      return fileName.startsWith('ErweiterteGeldwaeschepruefung') ? this.getPDFLink : this.simpleFileLinkmaker(fileName, fileId);
    },
    getTitle(file) {
      return file?.fileName;
    },
    updateAntragData(data = {}) {
      const payload = {
        id: this.antragId,
        data: {...data},
      }

      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_ANTRAG_DATA, payload)
    },
    saveAntrag(action) {
      return this.$store.dispatch(ANTRAG_TYPES.ACTIONS.SAVE_ANTRAG, {
          id: this.antragId,
          lagerstelle: this.lagerstelle,
          action,
      })
    },
    setWebserviceSync() {
      this.updateAntragData({synchronisieren: true})
    },
  }
}
</script>

<style scoped>
  .antrag-action-button__container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .grayed-out {
    color: grey;
  }
</style>