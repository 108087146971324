<template>
  <li class="option-menu-edit-modal__item"
    :class="{ 
      'is-permission-disabled': isPermissionDisabled,
      'has-remaining-actions': hasRemainingActions,
      'is-from-struktur': isFromStruktur,
      'sub-menu-view-active': isSubMenuViewActive,
    }" 
    :data-sortable-item="isSortable"
    :key="menuItemKey"
  >
    <div class="option-menu-edit-modal__item--wrap">
      <span class="option-menu-edit-modal__item--label">
        <span v-if="!hideParentLabel" v-html="parentLabel" class="option-menu-edit-modal__item--parent-label" />
        <span class="option-menu-edit-modal__item--menu-label">
          <span v-html="sanitize(menu.label)" />
          <button v-if="hasSubMenu && isExpandedLayout" 
            type="button" 
            class="option-menu-edit-modal__item--toggle-sub-menu-view btn-clear clickable"
            :class="{ 'active': isSubMenuViewActive, }"
            @click="isSubMenuViewActive = !isSubMenuViewActive">
            <PhCaretDown :size="16" />
          </button>
        </span>
      </span>
      
      <div class="option-menu-edit-modal__item--actions">
        <template v-if="isExpandedLayout"><!-- TODO new layout - maybe should be kept for all (modal and page) after approval -->
          <PhEye v-if="!isConfiguredMenuRemoveable" weight="fill" class="option-menu-edit-modal__item--non-removeable-info" /><!-- non removeable -->

          <OptionMenuPermissionToggleSwitch v-if="isPermissionConfigurable && !menu.unmodifiablePermission"
            :menu="menu" :userLevel="userLevel" :configContext="configContext" @input="onPermissionInput" :indeterminate="isIndeterminate"/><!-- permission -->

          <template v-if="isFromStruktur"><!-- from struktur -->
            <Pill label="Standard" type="default" />
          </template>
          <template v-else-if="isAvailable"><!-- available -->
            <button type="button" @click="$emit('add', menu)" class="btn-clear clickable">
              <PhPlus :size="16" />
            </button>
          </template>
          <BaseContextMenu v-else-if="hasRemainingActions" class="option-menu-edit-modal__item--actions-context-menu">
            <template v-if="isLockable && isLocked">
              <ContextMenuItem @click="$emit('unlock', menu)"><!-- locked -->
                <PhLock :size="16" weight="fill" /> <span>fixiert</span>
              </ContextMenuItem>
            </template>
            <template v-else-if="!isConfiguredMenuRemoveable"><!-- not removeable -->
              <ContextMenuItem @click="$emit('toggleRemoveable', menu)">
                <PhEye :size="16" weight="fill" /> <span>nicht entfernbar</span>
              </ContextMenuItem>
            </template>
            <template v-else><!-- remaining actions -->
              <ContextMenuItem v-if="isToggleRemoveable" @click="$emit('toggleRemoveable', menu)">
                <PhEye :size="16" /> <span>nicht entfernbar</span>
              </ContextMenuItem>

              <ContextMenuItem v-if="isLockable" @click="$emit('lock', menu)">
                <PhLock :size="16" /> <span>fixieren</span>
              </ContextMenuItem>

              <ContextMenuItem v-if="isRemoveable" @click="$emit('remove', menu)">
                <PhTrashSimple :size="16" /> <span>ausblenden</span>
              </ContextMenuItem>
            </template>
          </BaseContextMenu>

          <button v-if="isSortable" type="button" class="btn-clear" data-sortable-item-handler><!-- sortable -->
            <PhList :size="16" weight="bold" />
          </button>
        </template>
        <template v-else><!-- TODO old layout - maybe should be removed after approval -->
          <template v-if="isFromStruktur"><!-- from struktur -->
            <Pill label="Standard" type="default" />
          </template>
          <template v-else-if="isAvailable"><!-- available -->
            <button type="button" @click="$emit('add', menu)" class="btn-clear clickable">
              <PhPlus :size="16" />
            </button>
          </template>
          <template v-else-if="isLockable && isLocked"><!-- locked -->
            <button type="button" @click="$emit('unlock', menu)" class="btn-clear clickable">
              <PhLock :size="16" weight="fill" />
            </button>
          </template>
          <template v-else-if="!isConfiguredMenuRemoveable"><!-- not removeable -->
            <button type="button" @click="$emit('toggleRemoveable', menu)" class="btn-clear clickable" :disabled="!isConfiguredMenuRemoveableChangeable">
              <PhEye :size="16" weight="fill" />
            </button>
          </template>
          <template v-else><!-- remaining actions -->
            <button v-if="isToggleRemoveable" type="button" class="btn-clear clickable" @click="$emit('toggleRemoveable', menu)">
              <PhEye :size="16" />
            </button>

            <button v-if="isLockable" type="button" @click="$emit('lock', menu)" class="btn-clear clickable">
              <PhLock :size="16" />
            </button>

            <button v-if="isRemoveable" type="button" @click="$emit('remove', menu)" class="btn-clear clickable">
              <PhTrashSimple :size="16" />
            </button>
          </template>

          <button v-if="isSortable" type="button" class="btn-clear" data-sortable-item-handler><!-- sortable -->
            <PhList :size="16" weight="bold" />
          </button>
        </template>
      </div>
    </div>

    <div v-if="hasSubMenu && isExpandedLayout" v-show="isSubMenuViewActive" class="option-menu-edit-modal__item--sub-menu">
      <OptionMenuConfigList 
        :configId="configId"
        :menus="menu.subMenu" 
        :userLevel="userLevel" 
        :configContext="configContext"
        hideParentLabel
        :isPermissionConfigurable="isPermissionConfigurable"
        :isExpandedLayout="isExpandedLayout"
      />
    </div>
  </li>
</template>

<script>
import { mapGetters } from 'vuex';
import MENU_CONFIG_TYPES from '@/store/menuConfig/types';

import { PhPlus, PhLock, PhEye, PhTrashSimple, PhList, PhCaretDown, } from 'phosphor-vue';
import BaseContextMenu from '@/components/core/BaseContextMenu.vue';
import ContextMenuItem from '@/components/core/base-context-menu/ContextMenuItem.vue';
import OptionMenuPermissionToggleSwitch from './OptionMenuPermissionToggleSwitch.vue';
import Pill from '@/components/core/Pill.vue';

import { sanitize, } from '@/helpers/string-helper.js';

export default {
  name: 'OptionMenuConfigItem',
  props: {
    configId: {
      type: String,
      required: true,
    },
    menu: {
      type: Object,
      required: true,
    },
    userLevel: {
      type: String,
      required: true,
    },
    configContext: {
      type: String,
    },
    hideParentLabel: {
      type: Boolean,
      default: false,
    },
    isAvailable: {
      type: Boolean,
      default: false,
    },
    isRemoveable: {
      type: Boolean,
      default: false,
    },
    isToggleRemoveable: {
      type: Boolean,
      default: false,
    },
    isLockable: {
      type: Boolean,
      default: false,
    },
    isSortable: {
      type: Boolean,
      default: false,
    },
    isPermissionConfigurable: {
      type: Boolean,
      default: false,
    },
    isExpandedLayout: {
      type: Boolean,
      default: false,
    },
    isIndeterminate: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      isSubMenuViewActive: false,
    };
  },
  computed: {
    ...mapGetters({
      isMenuPathVisibleFn: MENU_CONFIG_TYPES.GETTERS.IS_MENU_PATH_VISIBLE,
      isMenuPathDisabledFn: MENU_CONFIG_TYPES.GETTERS.IS_MENU_PATH_DISABLED,
      isConfiguredMenuRemoveableFn: MENU_CONFIG_TYPES.GETTERS.IS_CONFIGURED_MENU_REMOVEABLE,
      isConfiguredMenuRemoveableChangeableFn: MENU_CONFIG_TYPES.GETTERS.IS_CONFIGURED_MENU_REMOVEABLE_CHANGEABLE,
      isConfiguredMenuLockedFn: MENU_CONFIG_TYPES.GETTERS.IS_CONFIGURED_MENU_LOCKED,
      isConfiguredMenuChangeableFn: MENU_CONFIG_TYPES.GETTERS.IS_CONFIGURED_MENU_CHANGEABLE,
    }),
    menuItemKey() {
      const { menu, isLocked, isConfiguredMenuRemoveable, } = this;

      return `${menu.path}-${isLocked}-${isConfiguredMenuRemoveable}`;
    },
    parentLabel() {
      return this.sanitize(this.menu?.parent?.label || '');
    },
    isPermissionDisabled() {
      if(!this.isPermissionConfigurable) return false;
      return !this.isMenuPathVisibleFn(this.configContext, this.userLevel, this.menu.path) || this.isMenuPathDisabledFn(this.configContext, this.userLevel, this.menu.path);
    },
    hasSubMenu() {
      return this.menu.hasSubMenu;
    },
    isFromStruktur() {
      return !this.isConfiguredMenuChangeable;
    },
    isConfiguredMenuRemoveable() {
      return this.isConfiguredMenuRemoveableFn(this.configContext, this.userLevel, this.configId, this.menu.path);
    },
    isConfiguredMenuRemoveableChangeable() {
      return this.isConfiguredMenuRemoveableChangeableFn(this.configContext, this.userLevel, this.configId, this.menu.path);
    },
    isConfiguredMenuChangeable() {
      return this.isConfiguredMenuChangeableFn(this.configContext, this.userLevel, this.configId, this.menu.path);
    },
    isLocked() {
      return this.isConfiguredMenuLockedFn(this.configContext, this.userLevel, this.configId, this.menu.path);
    },
    hasRemainingActions() {
      return this.isConfiguredMenuRemoveableChangeable && this.isConfiguredMenuChangeable && 
        (this.isLockable || this.isToggleRemoveable || !this.isConfiguredMenuRemoveable || this.isRemoveable && this.isConfiguredMenuRemoveable);
    },
  },
  methods: {
    sanitize(htmlString) {
      return sanitize(htmlString);
    },
    onPermissionInput() {
      if(!this.hasSubMenu) return;
      this.isSubMenuViewActive = true;
    },
  },
  components: {
    BaseContextMenu,
    ContextMenuItem,
    OptionMenuPermissionToggleSwitch,
    OptionMenuConfigList: () => import('./OptionMenuConfigList.vue'),
    Pill,
    PhPlus,
    PhLock,
    PhEye,
    PhTrashSimple,
    PhList,
    PhCaretDown,
  },
}
</script>

<style scoped lang="scss">
.option-menu-edit-modal__item.sub-menu-view-active {
  padding-bottom: 0;
}
.option-menu-edit-modal__item--wrap {
  display: flex;
  align-items: center;
}
.option-menu-edit-modal__item.is-permission-disabled .option-menu-edit-modal__item--label {
  color: #aaa;
}
.option-menu-edit-modal__item--label {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  word-break: break-word;
}
.option-menu-edit-modal__item--parent-label {
  font-size: 0.68rem;
  line-height: 1em;
}
.option-menu-edit-modal__item--menu-label {
  line-height: 1.2em;
}
.option-menu-edit-modal__item--toggle-sub-menu-view {
  margin: 0 0 0 4px;
  transition: transform .3s ease;
}
.option-menu-edit-modal__item--toggle-sub-menu-view.active {
  transform: rotate(-180deg);
}
.option-menu-edit-modal__item--actions {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  flex: 0 0 auto;
  margin: 0 6px 0 12px;
}
.option-menu-edit-modal__item--actions > * {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 16px;
  padding: 0;
}
.option-menu-edit-modal__item--actions > *:first-child {
  margin-left: 0;
}
.option-menu-edit-modal__item--actions > .pill__status {
  padding: 0 4px;
}
.option-menu-edit-modal__item--non-removeable-info {
  color: #ccc;
}

::v-deep .option-menu-edit-modal__item--actions-context-menu .dropdown-menu--hook-target {
  display: flex;
}

.option-menu-edit-modal__item--actions-context-menu--content > * {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}
.option-menu-edit-modal__item--actions-context-menu--content svg {
  margin: 0 4px 0 0;
}

.option-menu-edit-modal__item--sub-menu {
  background: var(--color-box);
  margin: 8px 0 0;
  padding: 8px 32px 8px 12px;
}
.has-remaining-actions .option-menu-edit-modal__item--sub-menu {
  padding-right: 68px;
}
.is-from-struktur .option-menu-edit-modal__item--sub-menu {
  padding-right: 76px;
}
.option-menu-edit-modal__item--sub-menu .list-bordered-item {
  border: none;
  padding: 0;
}
.option-menu-edit-modal__item--sub-menu .option-menu-edit-modal__item--sub-menu {
  margin-top: 0;
  margin-bottom: 0;
  padding: 2px 0 2px 16px;
}
</style>

<style>
.option-menu-edit-modal__item--actions-context-menu .dropdown-menu--hook-target {
  display: flex;
}
</style>