import TableHeader from '@tiptap/extension-table-header'
import {
  mergeAttributes,
} from '@tiptap/core';

function calculateTableHeaderWidthStyle(HTMLAttributes) {
  let totalWidth = 0;
  let fixedWidth = true;

  if (HTMLAttributes.colwidth) {
    HTMLAttributes.colwidth.forEach(col => {
      if (!col) {
        fixedWidth = false;
      } else {
        totalWidth += col;
      }
    });
  } else {
    fixedWidth = false;
  }

  let sizeStyle = null;

  if (fixedWidth && totalWidth > 0) {
    sizeStyle = `width: ${totalWidth}px`;
  } else if (totalWidth && totalWidth > 0) {
    sizeStyle = `min-width: ${totalWidth}px`;
  }

  return sizeStyle;
}

export const CustomTableHeader = TableHeader.extend({
  addAttributes() {
    return {
      ...this.parent(),
      colwidth: {
        default: null,
        parseHTML: element => {
          const colwidth = element.getAttribute('colwidth');
          const value = colwidth
            ? colwidth.split(',').map(item => parseInt(item, 10))
            : null;

          return value;
        },
      },
      style: {
        default: 'text-align: left',
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    const attributes = {
      style: calculateTableHeaderWidthStyle(HTMLAttributes)
    }

    return ['th', mergeAttributes(HTMLAttributes, attributes), 0];
  },

});


