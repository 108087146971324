import COURTAGELISTE_TYPES from './types';
import { getInitialState } from './index'
import Vue from 'vue';

export default {
  [COURTAGELISTE_TYPES.MUTATIONS.RETRIEVE_FONDSWERTE_SUCCESS](state, payload) {
    Vue.set(state, 'fondswerte', payload);
  }, 
  [COURTAGELISTE_TYPES.MUTATIONS.RETRIEVE_LAGERSTELLEN_SUCCESS](state, payload) {
    Vue.set(state, 'lagerstellen', payload);
  },   
  [COURTAGELISTE_TYPES.MUTATIONS.RESET_STATE](state, payload) {
    Object.assign(state, getInitialState())
  }, 
  [COURTAGELISTE_TYPES.MUTATIONS.RESET_LIST](state, payload) {
    Object.assign(state, 'fondswerte',  [])
  },   
}