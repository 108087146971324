<template>
  <div>
    <PageHeaderTitleNavigation
      :title="$appNavigation.currentMenu.label"
      :actions="pageActions"
      @action-ADD="gotoWertpapierinfo"
    />

    <div class="box__container">
      <Table v-if="!loading && rows.length"
        tableId="1010ff5d-7c8f-4753-a432-46b33878b057"
        title=""
        :headers="headers"
        :rows="rows"
        :headerActions="tableActions"
        rowId="isin"
        v-model="selectedRows"
        :rowsPerPage="25"
        :scrollHorizontally="true"
        :exportConfig="{roottext: $appNavigation.currentMenu.label }"
        @click-fondsname="gotoFondsinfo($event && $event.isin)"
        @action-EDIT="edit"
        @action-DELETE="deleteRows($event)"
        @headerAction-EDIT="edit"
        @headerAction-DELETE="deleteRows"
      />
      <GhostLoading v-else-if="loading" type="table"  />
      <NoData v-else  />
    </div>
    
    <BaseModal ref="bemerkModal"
      modalTitle="Bemerkung"
      @onConfirmButton="saveBem"
    >
      <form>
        <InputTextArea v-model="comment" label="" />
      </form>
    </BaseModal>

  </div>
</template>

<script>
import CORE_TYPES from "@/store/core/types";
// import WertpapierSearch from '@/components/wertpapierinfo/WertpapierSearch.vue';
import WERTPAPIERINFO_TYPES from "@/store/wertpapierinfo/types";
import { mapGetters } from 'vuex';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue'
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';
// import KeepAliveOnBackNavigation from '@/components/core/KeepAliveOnBackNavigation.vue';
import Table from "@/components/table2/Table.vue";
import { ActionColumn, DateTimeColumn, NumberColumn, PercentageColumn, TextColumn, SimpleAction, LinkAction } from "@/components/table2/table_util.js";
import { PhTrash, PhEye } from 'phosphor-vue';
import dayjs from 'dayjs';
import ROLES, { VIEW_ROLES } from '@/router/roles';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import BaseModal from "@/components/core/BaseModal.vue";
import InputTextArea from "@/components/core/forms/InputTextArea.vue";

export default {
  components: {
    PageHeaderTitleNavigation, OptionMenu, GhostLoading, NoData, Table, BaseModal, InputTextArea
  },
  data: function() {
    return {
      loading: true,
      rows: [],
      headers: {
        lockedLeft: [
          TextColumn("fondsname", "Wertpapiername").makeAlwaysVisible().makeLink(),
        ],
        center: [],
        lockedRight: [
          ActionColumn("actions", ""),
        ]
      },
      selectedRows: [],
      isinList: null,
      comment: '',
    }
  },
  computed: {
    ...mapGetters({
      watchliste: WERTPAPIERINFO_TYPES.GETTERS.GET_WATCHLIST,
      isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
    }),
    pageActions() {
      return [
        PageHeaderSimpleAction('ADD', 'Wertpapiere auswählen'),
      ]
    },
    tableActions() {
      return [
        PageHeaderSimpleAction('EDIT', 'Bemerkung (Markierung)')
            .withDisabled(() => !this.selectedRows.length),
        PageHeaderSimpleAction('DELETE', 'Entfernen (Markierung)')
            .withDisabled(() => !this.selectedRows.length),
      ]
    },
  },
  mounted() {
    this.$store.dispatch(WERTPAPIERINFO_TYPES.ACTIONS.LOAD_WATCHLIST).then(() => {this.loading = false;})
    if (this.watchliste?.rows) {
      this.init();
      this.loading = false;
    }
  },
  methods: {
    gotoFondsinfo(isin) {
      this.$addBreadcrumb({
        breadcrumb: 'Fondsinfo',
        fullPath: this.$route.fullPath,
      });
      const path = `/shared/vermogensubersicht/fondsinfo/${isin}`;
      this.$router.push({ path });
    },
    gotoWertpapierinfo() {
      this.$addBreadcrumb({
        breadcrumb: 'Wertpapierinfo',
        fullPath: this.$route.fullPath,
      });
      const path = this.$route.path.replace(/list$/, 'add');
      this.$router.push({ path });
    },
    deleteRows(row) {
      let param = null;
      if (row) {
        param = [''+row.id];
      } else if (this.selectedRows.length){
        param = this.selectedRows.map( s => ('' +s.id));
      }
      if (param) {
        this.$store.dispatch(WERTPAPIERINFO_TYPES.ACTIONS.UPD_WATCHLIST, {delete: param})
      }
    },
    edit(row){
      this.isinList = null;
      if (row) {
        this.isinList = [row.isin];
        this.comment = row.bemerkung;
      } else if (this.selectedRows.length){
        this.isinList = this.selectedRows.map( s => s.isin);
        this.comment = this.selectedRows[0].bemerkung;
      }
      if (this.isinList != null){
        this.$refs.bemerkModal.open();
      }
    },
    saveBem(){
      let row;
      const update = {};
      this.isinList.forEach(isin => {
        row = this.rows.find( r => r.isin == isin);
        if (row) {
          row.bemerkung = this.comment;
          update['' + row.id] = this.comment;
        }
      })
      this.$store.dispatch(WERTPAPIERINFO_TYPES.ACTIONS.UPD_WATCHLIST, {update})
    },
    init() {      
      const rows = [];
      if (this.watchliste?.rows?.length) {
        this.watchliste.rows.forEach(row => {
          const zusatz = JSON.parse(JSON.stringify(this.watchliste.infos[row.isin])) ;
          const actions = JSON.parse(zusatz.actions[0].replace(/'/g,'"'))
          zusatz.actions = [];
          actions.forEach((action, idx) => {
            if (action.text === 'Factsheet'){
              zusatz.actions.push(LinkAction('FACTSHEET', PhEye, 'Factsheet', action.href));
            } else if (action.text === 'Factsheet KAG'){
              zusatz.actions.push(LinkAction('KAG', PhEye, 'Factsheet KAG', action.href));
            } else if (action.text === 'KID'){
              zusatz.actions.push(LinkAction('KID', PhEye, 'PRIIP-BIB',  action.href));
            }
          });
          zusatz.actions.push(SimpleAction('EDIT', 'PhPencilLine', 'Bemerkung'));
          zusatz.actions.push(SimpleAction('DELETE', PhTrash, 'Entfernen'));
          rows.push( Object.assign({}, row, zusatz) );
        })
      }
      this.rows = rows;

      const center = [
        TextColumn("added_by_customer", "Kundeneintrag"),
        DateTimeColumn("date_created", "Am", 150, 1),
        TextColumn("bemerkung", "Bemerkung"),
      ]
      if (this.watchliste?.infos?.headers) {
        this.watchliste.infos.headers.forEach(h => {
          if (h[0] == 'String') {
            center.push(TextColumn(h[1], h[2]));
          } else if (h[0] == 'Percentage') {
            center.push(PercentageColumn(h[1], h[2])
              .setColorFn(row => row[h[1]] < 0 ? 'color-danger' : null));
          } else if (h[0] == 'NumberInt') {
            center.push(NumberColumn(h[1], h[2], 0));
          } else if (h[0] == 'Number') {
            let prec = 2;
            if (h[1].indexOf('aa_') == 0) {
              prec = 4;
            }
            center.push(NumberColumn(h[1], h[2], prec)
              .setColorFn(row => row[h[1]] < 0 ? 'color-danger' : null));
          }
        });
      }
      this.headers = {
        lockedLeft: [
          TextColumn("fondsname", "Wertpapiername").makeAlwaysVisible().makeLink(),
        ],
        center,
        lockedRight: [
          ActionColumn("actions", ""),
        ]
      }
    }
  },
  watch: {
    'watchliste': 'init'
  }
}
</script>
