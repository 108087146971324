<template>
<div class="fondsinfo__container" :key="isin">
    <PageHeaderTitleNavigation
        title="Fondsinfo"
        :subtitle="fundsName"
        :actions="pageHeaderTitleActions"
        @action-ADD_FAVORITENLIST="$refs.addFondsToListModal.open(fonds, 'Favoritenlisten')"
        @action-ADD_MYDEPOT="$refs.addFondsToListModal.open(fonds, 'MyDepot')"
        @action-ADD_FONDSVERGLEICH="$refs.addFondsToListModal.open(fonds, 'Fondsvergleich')"
    />

    <StepperForm
        ref="stepperForm"
        stepType="tab"
        :stepperName="'fondsinfo'"
        :stepperMediator="tabs"
        :selectedStepKey="selectedTabKey"
        :hasFertig="false"
        :key="selectedTabKey"
        backNavigationOnClose
        @set-step="setTab($event)"
    >
        <template #contentTemplate>
            <keep-alive>
                <component :is="loadTabComponent" :selectedTabLabel="selectedTabLabel"></component>
            </keep-alive>
        </template>
    </StepperForm>

    <AddFondsToListModal ref="addFondsToListModal" />
</div>
</template>

<script>
import CORE_TYPES from '@/store/core/types'
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import StepperForm from '@/components/stepper/StepperForm.vue';
import FONDSINFO_TYPES from "@/store/fondsinfo/types";
import { mapGetters } from 'vuex';
import { StepperMediator } from '@/components/stepper/utils';
import AddFondsToListModal from '@/components/fondsinfo/AddFondsToListModal.vue';
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';

export default {
    components :{
        OptionMenu,
        PageHeaderTitleNavigation,
        StepperForm,
        AddFondsToListModal,
    },
    data() {
        return {
            currentSelectedTab: null,
            tabs: {},
            path: {
                BETEILIGUNGEN : 'beteiligungen',
                VERMOGENSUBERSICHT : 'vermogensubersicht'
            },
            stepperCollapsed: false,
        }
    },
  beforeMount() {
    this.init();
  },
  mounted() {
    this.selectFirstStep();
  },
  watch: {
    '$route.params.isin': function (id) {
        this.init();
    },
    '$route.params.begleitscheinnummer': function (id) {
        this.init();
    },
  },
  destroyed(){
    this.$store.dispatch(FONDSINFO_TYPES.ACTIONS.RESET_STATE);
  },
  computed: {
    ...mapGetters({
        fundsDetail : FONDSINFO_TYPES.GETTERS.FONDSINFO_DETAILS,
        hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
        isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
    }),
    currentPath() {
        if(this.$route.path.includes(this.path.BETEILIGUNGEN)){
            return this.path.BETEILIGUNGEN;
        }
        if(this.$route.path.includes(this.path.VERMOGENSUBERSICHT)){
            return this.path.VERMOGENSUBERSICHT;
        }
        return this.path.VERMOGENSUBERSICHT;
    },
    selectedTabKey() {
        if(this.currentSelectedTab){
            return this.currentSelectedTab?.stepKey;
        } else {
            return this.getFirstTab()?.stepKey;
        }
    },
    selectedTabLabel() {
        if(this.fundsDetail){
            return this.tabs?.currentStep?.label;
        } else {
            return this.getFirstTab()?.label;
        }
    },
    fundsDetailList(){
        if(this.fundsDetail) {
            if(this.currentPath === this.path.BETEILIGUNGEN) {
                return this.fundsDetail?.kennnummernUndKlassifizierung;
            }
            return this.fundsDetail?.stammdaten?.records?.slice(0,2);
        }

        return null;
    },
    fundsName() {
        return this.fundsDetail?.namme || '';
    },
    fonds() {
        return [{isin: this.isin, fondsname: this.fundsName}]
    },
    loadTabComponent(){
        switch(this.currentSelectedTab?.stepKey){
            case 'ALLGEMEIN': 
                return () => import('@/components/fondsinfo/TabAllgemein.vue');
            case 'AUSSCHUTTUNG': 
                return () => import('@/components/fondsinfo/TabAusschuttung.vue');
            case 'STEUERLICHES_ERGEBNIS': 
                return () => import('@/components/fondsinfo/TabSteuerlichesErgebnis.vue');
            case 'ERBSCHAFTSSTEUERLICHE_WERTE': 
                return () => import('@/components/fondsinfo/TabErbschaftssteuerlicheWerte.vue');
            case 'EINZAHLUNGEN': 
                return () => import('@/components/fondsinfo/TabEinzahlungen.vue');
            case 'MITTELHERKUNFT': 
                return () => import('@/components/fondsinfo/TabMittelherkunft.vue');
            case 'INVESTITIONSKOSTEN': 
                return () => import('@/components/fondsinfo/TabInvestitionskosten.vue');
            case 'DOKUMENTE': 
                return () => import('@/components/fondsinfo/TabDokumente.vue');
            case 'DETAIL': 
                return () => import('@/components/fondsinfo/TabDetail.vue');
            case 'CHARTS': 
                return () => import('@/components/fondsinfo/TabCharts.vue');
            case 'PERFORMANCE': 
                return () => import('@/components/fondsinfo/TabPerformance.vue');
            case 'RISIKO': 
                return () => import('@/components/fondsinfo/TabRisiko.vue');
            case 'UNTERLAGEN': 
                return () => import('@/components/fondsinfo/TabUnterlagen.vue');
            case 'PURCHASEOPTS': 
                return () => import('@/components/fondsinfo/TabBezugsmoglichkeiten.vue');
            default:
                return null;
        }
    },
    pageHeaderTitleActions() {
        const actions = []

        if (this.isBrokerOrBypass) {
            actions.push(
                PageHeaderSimpleAction('ADD_FAVORITENLIST', 'Zu Favoritenliste hinzufügen'),
                PageHeaderSimpleAction('ADD_MYDEPOT', 'Zum Musterdepot hinzufügen'),
                PageHeaderSimpleAction('ADD_FONDSVERGLEICH', 'Zum Fondsvergleich hinzufügen'),
            )
        }

        return actions
    },
    isin() {
        return this.$route.params.isin
    },
  },
  methods: {
    init() {
        // reset old data
        this.$store.dispatch(FONDSINFO_TYPES.ACTIONS.RESET_STATE);

        // prepare tabs
        this.tabs = this.createTabs();
        this.selectFirstStep();

        // load data
        this.$store.dispatch(FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_DETAILS, {
            isin: this.$route.params?.isin,
            bgsNr: this.$route.params?.begleitscheinnummer,
        });
    },
    setTab(event) {
        this.currentSelectedTab = this.tabs?.getCurrentStep(event);
    },
    createTabs() {
        let stepsVermogensubersicht = [
                    {stepKey: 'DETAIL', label:'Detail', current: true, totalProgress: 1},
                    {stepKey: 'CHARTS', label:'Charts', current: false, totalProgress: 2},
                    {stepKey: 'PERFORMANCE', label:'Performance', current: false, totalProgress: 3},
                    {stepKey: 'RISIKO', label:'Risiko', current: false, totalProgress: 4},
                    {stepKey: 'UNTERLAGEN', label:'Unterlagen', current: false, totalProgress: 5},
                ];

        switch(this.currentPath) {
            case this.path.BETEILIGUNGEN :
                return new StepperMediator([
                    {stepKey: 'ALLGEMEIN', label:'Allgemein', current: true, totalProgress: 1},
                    {stepKey: 'AUSSCHUTTUNG', label:'Ausschüttung', current: false, totalProgress: 2},
                    {stepKey: 'STEUERLICHES_ERGEBNIS', label:'Steuerliches Ergebnis', current: false, totalProgress: 3},
                    {stepKey: 'ERBSCHAFTSSTEUERLICHE_WERTE', label:'Erbschaftssteuerliche Werte', current: false, totalProgress: 4},
                    {stepKey: 'EINZAHLUNGEN', label:'Einzahlungen', current: false, totalProgress: 5},
                    {stepKey: 'MITTELHERKUNFT', label:'Mittelherkunft', current: false, totalProgress: 6},
                    {stepKey: 'INVESTITIONSKOSTEN', label:'Investitionskosten', current: false, totalProgress: 7},
                    {stepKey: 'DOKUMENTE', label:'Dokumente', current: false, totalProgress: 8},
                ])
            case this.path.VERMOGENSUBERSICHT:


                if (this.hasRoles([[this.VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS], [this.VIEW_ROLES.VIEW_BROKER], [this.VIEW_ROLES.VIEW_BROKER_AS_BYPASS]])) {
                    stepsVermogensubersicht.push({stepKey: 'PURCHASEOPTS', label:'Bezugsmöglichkeiten', current: false, totalProgress: 6});
                }

                return new StepperMediator(stepsVermogensubersicht)
            default:
                return new StepperMediator();
        }
    },
    getFirstTab() {
        return this.tabs.getFirstStep();
    },
    selectFirstStep() {
        const firstTab = this.getFirstTab();
        this.setTab(firstTab?.stepKey);
    },
  },
};
</script>

<style scoped>
.strech {
  align-items: stretch;
  align-content: stretch;
  flex-grow: 1;
}
.flex-item {
  flex: 1 1 auto;
}
@media screen and (max-width: 767px) {
  .responsive-stepper {
    display: none;
  }
}
@media screen and (min-width: 992px) {
  .mobile-stepper {
    display: none;
  }
}
</style>