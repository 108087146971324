var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _c("ComboBox", {
        attrs: {
          label: "Kredit-/Darlehensform",
          firstEmpty: !_vm.form.kreditform,
          values: _vm.combos.kreditFormCombo,
          isComponentHalfSize: true,
          validateUntouched: "",
          disabled: _vm.hasEhypAntragsnr
        },
        on: {
          change: function($event) {
            _vm.dataChangedCombo("kreditform", $event)
            _vm.clearKreditart()
          }
        },
        model: {
          value: _vm.form.kreditform,
          callback: function($$v) {
            _vm.$set(_vm.form, "kreditform", $$v)
          },
          expression: "form.kreditform"
        }
      }),
      _c("ComboBox", {
        attrs: {
          label: "Kredit-/Darlehensart",
          firstEmpty: true,
          values: _vm.kreditartCombo,
          isComponentHalfSize: true,
          disabled: _vm.hasEhypAntragsnr
        },
        on: {
          change: function($event) {
            return _vm.dataChangedCombo("kreditart", $event)
          }
        },
        model: {
          value: _vm.form.kreditart,
          callback: function($$v) {
            _vm.$set(_vm.form, "kreditart", $$v)
          },
          expression: "form.kreditart"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Kredit-/Darlehensnummer",
          isComponentHalfSize: true,
          disabled: _vm.hasEhypAntragsnr
        },
        on: {
          change: function($event) {
            return _vm.dataChanged()
          }
        },
        model: {
          value: _vm.form.kreditnummer,
          callback: function($$v) {
            _vm.$set(_vm.form, "kreditnummer", $$v)
          },
          expression: "form.kreditnummer"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Bezeichnung",
          isComponentHalfSize: true,
          disabled: _vm.hasEhypAntragsnr
        },
        on: {
          change: function($event) {
            return _vm.dataChanged()
          }
        },
        model: {
          value: _vm.form.bezeichnung,
          callback: function($$v) {
            _vm.$set(_vm.form, "bezeichnung", $$v)
          },
          expression: "form.bezeichnung"
        }
      }),
      _c("InputField", {
        attrs: { label: "Verwendungszweck", isComponentHalfSize: true },
        on: {
          change: function($event) {
            return _vm.dataChanged()
          }
        },
        model: {
          value: _vm.form.verwendung,
          callback: function($$v) {
            _vm.$set(_vm.form, "verwendung", $$v)
          },
          expression: "form.verwendung"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Gesellschaft",
          isComponentHalfSize: true,
          disabled: _vm.hasEhypAntragsnr
        },
        on: {
          change: function($event) {
            return _vm.dataChanged()
          }
        },
        model: {
          value: _vm.form.gesellschaft,
          callback: function($$v) {
            _vm.$set(_vm.form, "gesellschaft", $$v)
          },
          expression: "form.gesellschaft"
        }
      }),
      _c("InputField", {
        attrs: { label: "Filiale", isComponentHalfSize: true },
        on: {
          change: function($event) {
            return _vm.dataChanged()
          }
        },
        model: {
          value: _vm.form.filiale,
          callback: function($$v) {
            _vm.$set(_vm.form, "filiale", $$v)
          },
          expression: "form.filiale"
        }
      }),
      _c("EntitySelectorBankverbindung", {
        attrs: {
          isComponentHalfSize: true,
          values: _vm.combos.bankingAccounts
        },
        on: {
          change: function($event) {
            return _vm.dataChangedCombo("bankingAccount", $event)
          }
        },
        model: {
          value: _vm.form.bankingAccount,
          callback: function($$v) {
            _vm.$set(_vm.form, "bankingAccount", $$v)
          },
          expression: "form.bankingAccount"
        }
      }),
      _c("hr"),
      _c("InputField", {
        attrs: {
          label: "Kredit-/Darlehenssumme",
          type: "currency",
          isComponentHalfSize: true
        },
        on: {
          change: function($event) {
            return _vm.dataChanged()
          }
        },
        model: {
          value: _vm.form.darlehenssumme,
          callback: function($$v) {
            _vm.$set(_vm.form, "darlehenssumme", $$v)
          },
          expression: "form.darlehenssumme"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Auszahlungskurs",
          type: "percent",
          isComponentHalfSize: true
        },
        on: {
          change: function($event) {
            return _vm.dataChanged()
          }
        },
        model: {
          value: _vm.form.auszahlungskurs,
          callback: function($$v) {
            _vm.$set(_vm.form, "auszahlungskurs", $$v)
          },
          expression: "form.auszahlungskurs"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Bearbeitungskosten",
          type: "currency",
          isComponentHalfSize: true
        },
        on: {
          change: function($event) {
            return _vm.dataChanged()
          }
        },
        model: {
          value: _vm.form.bearbeitungskosten,
          callback: function($$v) {
            _vm.$set(_vm.form, "bearbeitungskosten", $$v)
          },
          expression: "form.bearbeitungskosten"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Sollzins nominal",
          type: "percent",
          isComponentHalfSize: true,
          precision: 4
        },
        on: {
          change: function($event) {
            return _vm.dataChanged()
          }
        },
        model: {
          value: _vm.form.sollzinsNominal,
          callback: function($$v) {
            _vm.$set(_vm.form, "sollzinsNominal", $$v)
          },
          expression: "form.sollzinsNominal"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Sollzins effektiv",
          type: "percent",
          isComponentHalfSize: true,
          precision: 4
        },
        on: {
          change: function($event) {
            return _vm.dataChanged()
          }
        },
        model: {
          value: _vm.form.sollzinsEffektiv,
          callback: function($$v) {
            _vm.$set(_vm.form, "sollzinsEffektiv", $$v)
          },
          expression: "form.sollzinsEffektiv"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Sollzins nach Festschreibung",
          type: "percent",
          isComponentHalfSize: true,
          precision: 4
        },
        on: {
          change: function($event) {
            return _vm.dataChanged()
          }
        },
        model: {
          value: _vm.form.sollzinsNachFestschreibung,
          callback: function($$v) {
            _vm.$set(_vm.form, "sollzinsNachFestschreibung", $$v)
          },
          expression: "form.sollzinsNachFestschreibung"
        }
      }),
      _c("ComboBox", {
        attrs: {
          label: "Zahlungsperiode Raten",
          isComponentHalfSize: true,
          firstEmpty: true,
          values: _vm.combos.sollzinsPeriodeCombos
        },
        on: {
          change: function($event) {
            return _vm.dataChangedCombo("sollzinsPeriode", $event)
          }
        },
        model: {
          value: _vm.form.sollzinsPeriode,
          callback: function($$v) {
            _vm.$set(_vm.form, "sollzinsPeriode", $$v)
          },
          expression: "form.sollzinsPeriode"
        }
      }),
      _c("ComboBox", {
        attrs: {
          label: "Tilgungsverrechnung",
          isComponentHalfSize: true,
          firstEmpty: true,
          values: _vm.combos.tilgungsverrechnungPeriodeCombos
        },
        on: {
          change: function($event) {
            return _vm.dataChangedCombo("tilgungsverrechnungPeriode", $event)
          }
        },
        model: {
          value: _vm.form.tilgungsverrechnungPeriode,
          callback: function($$v) {
            _vm.$set(_vm.form, "tilgungsverrechnungPeriode", $$v)
          },
          expression: "form.tilgungsverrechnungPeriode"
        }
      }),
      _c("ComboBox", {
        attrs: {
          label: "Zinsabrechnung",
          isComponentHalfSize: true,
          firstEmpty: true,
          values: _vm.combos.zinsabrechnungPeriodeCombos
        },
        on: {
          change: function($event) {
            return _vm.dataChangedCombo("zinsabrechnungPeriode", $event)
          }
        },
        model: {
          value: _vm.form.zinsabrechnungPeriode,
          callback: function($$v) {
            _vm.$set(_vm.form, "zinsabrechnungPeriode", $$v)
          },
          expression: "form.zinsabrechnungPeriode"
        }
      }),
      _c("InputTextArea", {
        attrs: { label: "Besonderheiten", isComponentHalfSize: true },
        on: {
          change: function($event) {
            return _vm.dataChanged()
          }
        },
        model: {
          value: _vm.form.sonstiges,
          callback: function($$v) {
            _vm.$set(_vm.form, "sonstiges", $$v)
          },
          expression: "form.sonstiges"
        }
      }),
      _c("hr"),
      _c("DatePickerField", {
        attrs: {
          isValueAsString: "",
          label: "Vertragsbeginn",
          isComponentHalfSize: true
        },
        on: {
          input: function($event) {
            return _vm.dataChanged()
          }
        },
        model: {
          value: _vm.form.begin,
          callback: function($$v) {
            _vm.$set(_vm.form, "begin", $$v)
          },
          expression: "form.begin"
        }
      }),
      _c("DatePickerField", {
        attrs: {
          isValueAsString: "",
          label: "Vertragsablauf",
          isComponentHalfSize: true
        },
        on: {
          input: function($event) {
            return _vm.dataChanged()
          }
        },
        model: {
          value: _vm.form.ablauf,
          callback: function($$v) {
            _vm.$set(_vm.form, "ablauf", $$v)
          },
          expression: "form.ablauf"
        }
      }),
      _c("DatePickerField", {
        attrs: {
          isValueAsString: "",
          label: "Ende Sollzinsbindung",
          isComponentHalfSize: true
        },
        on: {
          input: function($event) {
            return _vm.dataChanged()
          }
        },
        model: {
          value: _vm.form.end,
          callback: function($$v) {
            _vm.$set(_vm.form, "end", $$v)
          },
          expression: "form.end"
        }
      }),
      _c("DatePickerField", {
        attrs: {
          isValueAsString: "",
          label: "Auszahlungstermin",
          isComponentHalfSize: true
        },
        on: {
          input: function($event) {
            return _vm.dataChanged()
          }
        },
        model: {
          value: _vm.form.auszahlungDatum,
          callback: function($$v) {
            _vm.$set(_vm.form, "auszahlungDatum", $$v)
          },
          expression: "form.auszahlungDatum"
        }
      }),
      _c("DatePickerField", {
        attrs: {
          isValueAsString: "",
          label: "1. Rate am",
          isComponentHalfSize: true
        },
        on: {
          input: function($event) {
            return _vm.dataChanged()
          }
        },
        model: {
          value: _vm.form.ersteRateDatum,
          callback: function($$v) {
            _vm.$set(_vm.form, "ersteRateDatum", $$v)
          },
          expression: "form.ersteRateDatum"
        }
      }),
      _c("DatePickerField", {
        attrs: {
          isValueAsString: "",
          label: "1. Tilgungsverrechnung",
          isComponentHalfSize: true
        },
        on: {
          input: function($event) {
            return _vm.dataChanged()
          }
        },
        model: {
          value: _vm.form.ersteTilgungsverrechDatum,
          callback: function($$v) {
            _vm.$set(_vm.form, "ersteTilgungsverrechDatum", $$v)
          },
          expression: "form.ersteTilgungsverrechDatum"
        }
      }),
      _c("DatePickerField", {
        attrs: {
          isValueAsString: "",
          label: "1. Zinsabrechnung",
          isComponentHalfSize: true
        },
        on: {
          input: function($event) {
            return _vm.dataChanged()
          }
        },
        model: {
          value: _vm.form.ersteZinsabrechnungDatum,
          callback: function($$v) {
            _vm.$set(_vm.form, "ersteZinsabrechnungDatum", $$v)
          },
          expression: "form.ersteZinsabrechnungDatum"
        }
      }),
      _c("InputField", {
        attrs: { label: "Rate", type: "currency", isComponentHalfSize: true },
        on: {
          change: function($event) {
            return _vm.dataChanged()
          }
        },
        model: {
          value: _vm.form.folgerate,
          callback: function($$v) {
            _vm.$set(_vm.form, "folgerate", $$v)
          },
          expression: "form.folgerate"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "anfänglicher Tilgungssatz",
          type: "percent",
          isComponentHalfSize: true
        },
        on: {
          change: function($event) {
            return _vm.dataChanged()
          }
        },
        model: {
          value: _vm.form.tilgungssatz,
          callback: function($$v) {
            _vm.$set(_vm.form, "tilgungssatz", $$v)
          },
          expression: "form.tilgungssatz"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Restschuld nach Festschreibung",
          type: "currency",
          isComponentHalfSize: true
        },
        on: {
          change: function($event) {
            return _vm.dataChanged()
          }
        },
        model: {
          value: _vm.form.restschuld,
          callback: function($$v) {
            _vm.$set(_vm.form, "restschuld", $$v)
          },
          expression: "form.restschuld"
        }
      }),
      _c("DatePickerField", {
        attrs: {
          isValueAsString: "",
          label: "Frühester Ablösezeitpunkt",
          isComponentHalfSize: true
        },
        on: {
          input: function($event) {
            return _vm.dataChanged()
          }
        },
        model: {
          value: _vm.form.fruehesterAbloesezeitpunkt,
          callback: function($$v) {
            _vm.$set(_vm.form, "fruehesterAbloesezeitpunkt", $$v)
          },
          expression: "form.fruehesterAbloesezeitpunkt"
        }
      }),
      _c("InputToggleSwitch", {
        attrs: { label: "Sondertilgung", inLineLabel: true },
        on: {
          input: function($event) {
            return _vm.dataChanged()
          }
        },
        model: {
          value: _vm.form.hatSondertilgung,
          callback: function($$v) {
            _vm.$set(_vm.form, "hatSondertilgung", $$v)
          },
          expression: "form.hatSondertilgung"
        }
      }),
      _vm.form.hatSondertilgung
        ? _c("InputField", {
            attrs: { type: "percent", isComponentHalfSize: true },
            on: {
              change: function($event) {
                return _vm.dataChanged()
              }
            },
            model: {
              value: _vm.form.sondertilgungProzent,
              callback: function($$v) {
                _vm.$set(_vm.form, "sondertilgungProzent", $$v)
              },
              expression: "form.sondertilgungProzent"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }