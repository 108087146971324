import DYNAMIC_DOCUMENT_TYPES from './types';

export default {
  [DYNAMIC_DOCUMENT_TYPES.GETTERS.SELECTED_DYNAMIC_DOCUMENT](state) {
    return state.selectedDynamicDocument;
  },
  [DYNAMIC_DOCUMENT_TYPES.GETTERS.COMBOBOX_SELECTIONS](state) {
    return state.comboboxSelections;
  },
  [DYNAMIC_DOCUMENT_TYPES.GETTERS.PLACEHOLDERS](state) {
    return state.placeholders;
  },
  [DYNAMIC_DOCUMENT_TYPES.GETTERS.GET_DOCUMENT_HAS_CHANGED](state) {
    return state.documentHasChanged;
  },
}