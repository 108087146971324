var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.dynamicTemplate
    ? _c(
        _vm.dynamicTemplate,
        _vm._g(
          _vm._b(
            { tag: "component", class: _vm.dynamicBindings.class || "" },
            "component",
            _vm.dynamicBindings.props,
            false
          ),
          _vm.dynamicBindings.events
        )
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }