var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.mainParameters
        ? _c("WertpapierSearch", {
            ref: "wp_filter",
            attrs: {
              name: "AnlageempfehlungSuche",
              type: "I",
              lagerstelle: _vm.lagerstelle,
              respond: 3,
              isSelectable: ""
            },
            on: { selected: _vm.selectedFunds }
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("div", { staticClass: "box__title" }, [
            _vm._v("Anlageempfehlungsliste")
          ]),
          _vm.loading
            ? _c("GhostLoading", [_c("Block", { attrs: { height: "200" } })], 1)
            : _vm.showedRows.length
            ? _c("Table", {
                attrs: {
                  headers: _vm.headers,
                  rows: _vm.showedRows,
                  rowId: "isin_id",
                  hidePagination: ""
                },
                on: {
                  "action-DELETE": _vm.delRow,
                  "action-FACTSHEET": function($event) {
                    return _vm.viewDocument($event.FACTSHEET)
                  },
                  "action-FACTSHEET_KAG": function($event) {
                    return _vm.viewDocument($event.FACTSHEET_KAG)
                  },
                  "action-PRIIP_BIB": function($event) {
                    return _vm.viewDocument($event.PRIIP_BIB_URL)
                  },
                  "click-name": _vm.gotoFondsinfo
                },
                scopedSlots: _vm._u([
                  {
                    key: "header_active",
                    fn: function() {
                      return [
                        _c("InputCheckBoxItem", {
                          staticStyle: { "margin-left": "3px" },
                          attrs: {
                            value: _vm.areAllSelected,
                            indeterminate: _vm.areOnlySomeSelected
                          },
                          on: { input: _vm.changeStatus }
                        })
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "active",
                    fn: function(row) {
                      return [
                        _c("InputCheckBoxItem", {
                          attrs: { value: row.active },
                          on: {
                            input: function(checked) {
                              return _vm.onChangeActive(row.isin, checked)
                            }
                          }
                        })
                      ]
                    }
                  }
                ])
              })
            : _c("NoData", { attrs: { noIcon: true } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }