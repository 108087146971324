var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.rowsComputed, function(row) {
        return _c("div", { key: row.id, staticClass: "top__line" }, [
          _c(
            "div",
            {
              staticClass: "top__line-content",
              on: {
                click: function($event) {
                  return _vm.onRowClick($event, row)
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "firstRow" },
                [_vm._t("title", null, null, row)],
                2
              ),
              _c("div", { staticClass: "firstRow" }, [
                _c(
                  "span",
                  { class: { highlighted: row.shouldHighlight } },
                  [
                    _vm._t("value", null, null, row),
                    (row.isClickable !== undefined
                    ? row.isClickable && _vm.linkInFirstRow
                    : _vm.linkInFirstRow)
                      ? _c(
                          "button",
                          {
                            staticClass: "btn-clear d-block",
                            class: { "d-lg-none": !_vm.isMobileNativeContext },
                            attrs: { type: "button" }
                          },
                          [_c("PhCaretRight", { attrs: { size: 20 } })],
                          1
                        )
                      : _vm._e()
                  ],
                  2
                )
              ])
            ]
          ),
          _vm.showThirdRow
            ? _c("div", { staticClass: "subject" }, [
                _c(
                  "div",
                  { staticClass: "thirdRow" },
                  [_vm._t("thirdRow", null, null, row)],
                  2
                )
              ])
            : _vm._e()
        ])
      }),
      _vm.pageSize
        ? _c("div", { staticClass: "weitere___link" }, [
            _c(
              "a",
              {
                on: {
                  click: function($event) {
                    return _vm.navigateToCard()
                  }
                }
              },
              [_vm._v("Weitere...")]
            )
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }