var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.disabled
    ? _c(_vm.element, { tag: "component" }, [_vm._t("default")], 2)
    : _c(
        _vm.element,
        {
          tag: "component",
          staticClass: "dropzone",
          on: {
            drag: _vm.ondrag,
            dragover: _vm.ondragover,
            dragenter: _vm.ondragover,
            dragleave: _vm.ondragleave,
            drop: _vm.ondrop
          }
        },
        [
          _vm._t("default"),
          _vm.hoverText && _vm.draggedOver
            ? _c("div", { staticClass: "dropzone_overlay" }, [
                _vm._v(" " + _vm._s(_vm.hoverText) + " ")
              ])
            : _vm._e()
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }