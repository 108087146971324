<template>
  <div>
    <GesellschaftTitleNavigation/>

    <BaseFilter 
      filterId="790ca065-681f-4a9f-952f-ec8c993ca818"
      title="News filtern" 
      :metadata="filterMetadata" 
      :configFilter="configFilter"
      hasSmartSearch
      @onFilter="getNews($event)">
    </BaseFilter>

    <div class="box__container" :tid="_generateTidFromString('cards__container layout__negative-margin--8')" >
      <div class="box__title">News</div>
      <Table 
        v-if="newsRecords && newsRecords.length"
        :headers="headers"
        :rows="rows"
        :rowsPerPage="20"
        hidePagination
        @click-title="openNews($event)">
      </Table>
      <GhostLoading v-else-if="loading" type="table" />
      <NoData v-else content="keine News vorhanden" />
    </div>

    <BaseModal
      ref="newsModal"
      :modalTitle="'Gesellschafts Nachricht'"
      :showConfirmButton="false"
      labelButtonCancel="Schließen"
      size="lg">
      <div v-html="sanitize(newsPanelTitle)">
      </div>
      <br>
      <div v-html="sanitize(newsPanelBody)">
      </div>
    </BaseModal>
  </div>
</template>

<script>
import GesellschaftTitleNavigation from '@/views/gesellschaft/intern/GesellschaftTitleNavigation.vue'

import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import Table from "@/components/table2/Table.vue";
import axios from 'axios';
import BaseFilter from "@/components/core/BaseFilter.vue";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import dayjs from "dayjs";
import BaseModal from "@/components/core/BaseModal.vue";
import {sanitize} from '@/helpers/string-helper.js';
import {TextColumn} from "@/components/table2/table_util.js";
import { makeQueryParam } from '@/helpers/utils-helper';

const config = {
  defaultSpinner: true
};

export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    Table,
    BaseFilter,
    BaseModal,
    GhostLoading,
    NoData,
    GesellschaftTitleNavigation,
  },
  data() {
    return {
      loading: false,
      newsRecords: [],
      newsPanelTitle: '',
      newsPanelBody: '',
      filterMetadata: [
          {
            type: "group",
            key: "abDatum",
            label: "Ab Datum",
            menuItems: [
              {
                type: "dateRange",
                label: "Zeitraum",
                key: "ZEITRAUM",
              },
             {
                type: "default",
                label: "letzte 3 Monate",
                key: "LETZTEN_3_MONATEN",
              },
              {
                type: "text",
                label: "Jahr",
                key: "jahr",
              },

            ],
          },
        ], 
      configFilter: {
          placeholderForDefSearchInput: 'Suchbegriff',
          checkDefaultSearchInput: false,
          cbDefSearchInput: null,
          hideFirstColumn: false,
      },
    }
  },
  computed: {
   headers() {
      let headers = {
          lockedLeft: [
            TextColumn("title", "Titel").makeLink(),
          ],
          center: [
            TextColumn("date", "Datum"),
          ],
          lockedRight: [],
      }
      return headers
    },
    rows() {
      return this.newsRecords.map(record => {
        return {...record, title: record.title || 'Kein Titel angegeben'}
      })
    },
  },
  mounted() {
    this.getNews()    
  },
  methods: {
    sanitize(htmlString) {
        return sanitize(htmlString);
    },
    openNews(row) {
      this.loading = true

      let serviceUrl = `/gesellschaftenService/newsPanel?newsId=${row.id}`;

      axios.get(`${process.env.VUE_APP_API}` + serviceUrl, config).then(response => {
        if (response && response.data) {
          this.newsPanelTitle = response.data.title
          this.newsPanelBody = response.data.body
          this.$refs.newsModal.open();
        } 
      }).finally(() => this.loading = false)
    },
    prepareFilterParams(filterConfig) {
        let params = {};
        filterConfig.forEach(fc => {
          if (fc.group == "abDatum") {
            if (fc.key === "LETZTEN_3_MONATEN") {
              params['fromDate'] = dayjs().subtract(3, "month").format("DD.MM.YYYY");
            } else if (fc.key ==='ZEITRAUM') {
              params['fromDate'] = dayjs(fc.value.find(v => v.key == 'min').value, 'DD.MM.YYYY').format("DD.MM.YYYY");
              params['toDate'] = dayjs(fc.value.find(v => v.key == 'max').value, 'DD.MM.YYYY').format("DD.MM.YYYY")
            } else if (fc.key === "jahr") {
              params['fromDate'] = dayjs(fc.value).startOf('year').format("DD.MM.YYYY");
              params['toDate'] = dayjs(fc.value).endOf('year').format("DD.MM.YYYY");
            }
          } else if (fc.group == "sonstige") {
            params[fc.key] = true
          } else if (fc.key) {
            params[fc.key] = fc.value;
          }
        });
        return params;
    }, 
    getNews(filterconfig) {
      this.loading = true

      let payload = {};

      if (filterconfig) {
        payload = this.prepareFilterParams(filterconfig)
      }

      const params = makeQueryParam({ gesellschaftId: this.$route.params.gesellId }, true)

      axios.post(`${process.env.VUE_APP_API}/gesellschaftenService/news?${params}`, payload, config).then(response => {
          this.newsRecords = response?.data || []
      }).finally(() => this.loading = false)
    },  
  },
}
</script>
