import { chartColorScheme } from './RetirementChart.vue';
import {DatePickerUtils} from '@/components/core/forms/DatePicker/date-picker-utils.js';
import dayjs from 'dayjs';
import { removeFormatting, filterSpecialGoals, filterSpecialRevenues, monthsDiff } from './Utils';
import FinancialCalculator, { manLifeYears, womanLifeYears } from './financialCalculator.js';
import { SPECIAL_TYPE_GOAL_COST_OF_LIVING_AFTER_RETIREMENT, SPECIAL_TYPE_REVENUE_PENSION_INCOME, SPECIAL_TYPE_REVENUE_BEFORE_RETIREMENT_INCOME, SPECIAL_TYPE_REVENUE_PRESENT_VALUE_MANUAL } from './types';


export function calculateDataPoints({
  transactionsArray,
  selectedTransactions,
  selectedTransactionsSpecial,
  dateOfBirth,
  retirementAge = 67,
  beingDestroyed = false,
  period = 'pro Jahr',
  lifeLength,
  presentValues = {
    assets: [],
    loss: []
  },
  interestAfterRetirement = 0,
  assetsInterest = {},
  liabilitiesInterest = {},
}) {
  // this.calculateBalance();
  let dataPointsPerYear = []
  let dataPoints = [];
  const now = new Date();

  // const specialEventsData = getSpecialEventsData({
  //   transactionsArray,
  //   selectedTransactionsSpecial
  // });
  
  // special events with no date as a constant and recalculated
  const {presentValue, pensionIncome, beforeRetirementIncome, costOfLivingFromRetirement} = getSpecialEventsData({
    transactionsArray,
    selectedTransactionsSpecial
  });
  const dividerFreq = period === 'pro Jahr' ? 12 : 1;
  const pv = presentValue;
  
  const dob = getDateFromStrOrDate(dateOfBirth)
  const dobYear = dob.getFullYear()
  const retirementYear = retirementAge + dobYear + 1;
  
  const repeatingIntervals = [];
  let intervals = [{date: getMonthsFromCurrent(now), amount: pv}];
  if (presentValues) {
    presentValues.assets.map(v => {
      intervals.push({
        date: getMonthsFromCurrent(now),
        amount: v.value,
        interest: assetsInterest[v.key.toLowerCase()] || 0,
        type: 'assets'
      })
    })
    presentValues.loss.map(v => {
      intervals.push({
        date: getMonthsFromCurrent(now),
        amount: v.value,
        interest: liabilitiesInterest[v.key.toLowerCase()] || 0,
        type: 'assets'
      })
    })
  }
  
  // add goals
  intervals = intervals.concat(transactionsArray
    .filter(filterSpecialGoals)
    .filter(g => selectedTransactions.includes(+g.id))
    .map(val => {
      // add repeating events
      if (val.repeat !== 'Keine Wiederholung' && val.endDate) {
        const repeatingIntervalDividerFreq = val.period === 'pro Jahr' ? 12 : 1;
        for (let index = 1; index < getMonthsFromCurrent(val.endDate) - getMonthsFromCurrent(val.age); index++) {
          repeatingIntervals.push({
            date: getMonthsFromCurrent(val.age) + index,
            amount: - val.amount / repeatingIntervalDividerFreq
          });
        }
      }
      // add original to intervals array
      return ({date: getMonthsFromCurrent(val.age), amount: - val.amount});
    }));
  // add revenues
  intervals = intervals.concat(
    transactionsArray
      .filter(filterSpecialRevenues)
      .filter(g => selectedTransactions.includes(+g.id))
      .map(val => {
        // add repeating events
        if (val.repeat !== 'Keine Wiederholung' && val.endDate) {
          const repeatingIntervalDividerFreq = val.period === 'pro Jahr' ? 12 : 1;
          for (let index = 1; index < getMonthsFromCurrent(val.endDate) - getMonthsFromCurrent(val.age); index++) {
            repeatingIntervals.push({
              date: getMonthsFromCurrent(val.age) + index,
              amount: val.amount / repeatingIntervalDividerFreq
            });
          }
        }
        // add original to intervals array
        return ({date: getMonthsFromCurrent(val.age), amount: val.amount});
      }));
  intervals = intervals.filter(v => v.date !== null && v.date !== undefined && v.amount !== null && v.amount !== undefined);
  intervals.push({date: getMonthsFromCurrent(`01.01.${retirementYear}`), amount: null});
  intervals = intervals.concat(repeatingIntervals);
  intervals = intervals.filter(interval => interval.date >= getMonthsFromCurrent(now)).sort((a, b) => a.date - b.date);
  const intervalsCombined = [];
  intervals.map(interval => {
    const intervalIndex = intervalsCombined.findIndex(v => v.date === interval.date);
    if (intervalIndex >= 0) {
      intervalsCombined[intervalIndex] = {
        date: +intervalsCombined[intervalIndex].date,
        intervals: [
          ...intervalsCombined[intervalIndex].intervals,
          {
            ...interval
          }
        ],
        amount: +intervalsCombined[intervalIndex].amount + (interval.type !== 'assets' ? +interval.amount : 0),
      };
    } else {
      intervalsCombined.push({
        date: +interval.date,
        intervals: [interval],
        amount: interval.type !== 'assets' ? +interval.amount : 0,
      });
    }
  });

  dataPoints = [];

  // // special events with no date as a constant and recalculated
  // const {pensionIncome, beforeRetirementIncome, costOfLivingFromRetirement} = getSpecialEventsData({
  //   transactionsArray,
  //   selectedTransactionsSpecial
  // });

  let dataLinesBalance = [];
  let dataLines = [];
  
  // calc data for savings by intervals
  intervalsCombined.map((perDate, i) => {
    let incomePerPeriod = perDate.date < getMonthsFromCurrent(`01.01.${retirementYear}`) ?
      beforeRetirementIncome :
      - costOfLivingFromRetirement + pensionIncome;

    // const interestRate = (
    //   perDate.date < getMonthsFromCurrent(`01.01.${retirementYear}`) ?
    //   +this.form['interestBeforeRetirement'] :
    //   +this.form['interestAfterRetirement']
    // );
    const interestRate = (
      perDate.date < getMonthsFromCurrent(`01.01.${retirementYear}`) ?
      0 :
      +interestAfterRetirement
    );
    const previousPV = intervalsCombined[i - 1] ? dataLines[dataLines.length - 1] : 0;

    const dobVal = getDateFromStrOrDate(dateOfBirth)
    
    const currentYear = new Date().getFullYear()
    const term = intervalsCombined[i + 1] ? intervalsCombined[i + 1].date : +( dobVal.getFullYear() || currentYear) + lifeLength;
    let amount = perDate.amount ? perDate.amount : 0;
  
    let currentPV = previousPV + amount;

    if (+term - +perDate.date === 1) {
      currentPV += incomePerPeriod;
      incomePerPeriod = 0;
    }

    dataLines = dataLines.concat(FinancialCalculator.compaundFutureValues(
      currentPV,
      incomePerPeriod / dividerFreq,
      interestRate / 12,
      1,
      +term - +perDate.date
    ));

    // balance intervals
    intervalsCombined[0].intervals.map((from, intervalIndex) => {
      if (from.type === 'assets') {
        // assets lines start
        let minusAsset = 0;
        let amountA = from.amount && from.type === 'assets' ? (from.amount || 0) : 0;
        if (amount < 0) {
          const percentageAssets = intervalsCombined[0].intervals.map(v => v.amount || 0)
          const sumAllAssets = percentageAssets.reduce((acc, curr) => acc+curr, 0)
          minusAsset = ((percentageAssets[intervalIndex] / sumAllAssets) * amount)
        }

        const interestRateA = from.interest !== undefined ? (from.interest || 0) : 0;
      
        const previousPVA = intervalsCombined[i - 1] && dataLinesBalance[intervalIndex] && dataLinesBalance[intervalIndex].length
          ? dataLinesBalance[intervalIndex][dataLinesBalance[intervalIndex].length - 1]
          : 0;
        let currentPVA = (previousPVA || amountA) + minusAsset;
        
        const pointsA = FinancialCalculator.compaundFutureValues(
          currentPVA,
          0,
          interestRateA / 12,
          1,
          +term - +from.date
        )
        if (!dataLinesBalance[intervalIndex]) {
          dataLinesBalance[intervalIndex] = []
        }
        dataLinesBalance[intervalIndex] = dataLinesBalance[intervalIndex].concat(pointsA)
        // assets lines end
      }
    })
    const dataPointsVal = [];
    if (dataLines && dataLines.length) {
      dataLines.map((data, i) => {
        dataLinesBalance.map(line => {
          dataPointsVal[i] = (dataPointsVal[i] || 0) + +line[i].toFixed(0) + +dataLines[i].toFixed(0)
        });
      })
    }
    dataPoints = dataPointsVal;
  });

  dataPointsPerYear = [];
  let maxY = 0;
  let minY = 0;
  const currentYear = new Date().getFullYear()
  if (!beingDestroyed) {
    dataPointsPerYear = dataPoints
    .filter((v, i) => i % 12 === 0)
    .filter((v, i) => +i <= +lifeLength - getAgeTo(currentYear, dateOfBirth));

    dataLinesBalance = dataLinesBalance
      .filter(b => b && b.length)
      .map(line => {
      return line
        .filter((v, i) => i % 12 === 0)
        .filter((v, i) => +i <= +lifeLength - getAgeTo(currentYear, dateOfBirth));
    })

    const maxMinPoints = dataPointsPerYear.concat().sort((a, b) => a - b);
    maxY = maxMinPoints[maxMinPoints.length - 1];
    minY = maxMinPoints[0];
    // this.updateChart();
  }

  // this.tableDataDetailedRecords = this.getTableData(this.tableDataDetailed);

  return {
    dataPointsPerYear,
    dataLinesBalance,
    maxY,
    minY,
  }
}

export function calculateChart({
  transactionsArray,
  selectedTransactions,
  dataPointsPerYear,
  dateOfBirth,
  retirementYear,
  maxY = 0,
  minY = 0,
  lifeLength = 80,
  moneyNeededAtTheEnd = 0,
}) {
  const currentYear = new Date().getFullYear()
  const apexMarkers = {
  };

  const existingZielePoints = {};
  const goalsArrayNoSpecials = transactionsArray
    .filter(filterSpecialGoals)
    .filter(g => selectedTransactions.includes(+g.id));
  const zielePoints = goalsArrayNoSpecials.map((val, i) => {
    const age = val.age && val.age.split('.') || [0, 0, currentYear];
    const x = age[2] - currentYear + 1;
    let y = dataPointsPerYear[age[2] - currentYear];
    existingZielePoints[x] = existingZielePoints[x] ? existingZielePoints[x] += 1 : 1;
    if (existingZielePoints[x] > 1) {
      y += (maxY - minY) * (existingZielePoints[x] - 1) * 0.1;
    }
    const colorIndex = transactionsArray.findIndex(v => v.id === val.id);
    return ({
      x: x,
      y: y,
      marker: {
        size: 6,
        fillColor: getColor(colorIndex),
        strokeColor: '#ea1601',
        radius: 2
      },
    });
  }).concat(goalsArrayNoSpecials
    .filter(goal => {
      if (!goal.endDate) {
        return false;
      }
      const endDateVal = goal.endDate.split('.');
      const ageVal = goal.age.split('.');
      return goal.repeat !== 'Keine Wiederholung'
        && endDateVal[2] !== ageVal[2];
    })
    .map((val, i) => {
      // for repeating events add endDate
      const endDateVal = val.endDate.split('.');
      const x = endDateVal[2] - currentYear + 1;
      let y = dataPointsPerYear[endDateVal[2] - currentYear];
      existingZielePoints[x] = existingZielePoints[x] ? existingZielePoints[x] += 1 : 1;
      if (existingZielePoints[x] > 1) {
        y += (maxY - minY) * (existingZielePoints[x] - 1) * 0.1;
      }
      const colorIndex = transactionsArray.findIndex(v => v.id === val.id);
      return ({
        x: x,
        y: y,
        marker: {
          size: 6,
          fillColor: getColor(colorIndex),
          strokeColor: '#2698FF',
          radius: 2
        },
      });
  }));
  const revenueArrayNoSpecials = transactionsArray
    .filter(filterSpecialRevenues)
    .filter(g => selectedTransactions.includes(+g.id));
  const revenuePoints = revenueArrayNoSpecials.map((val, i) => {
    const ageVal = val.age.split('.');
    const x = ageVal[2] - currentYear + 1;
    let y = dataPointsPerYear[ageVal[2] - currentYear];
    existingZielePoints[x] = existingZielePoints[x] ? existingZielePoints[x] += 1 : 1;
    if (existingZielePoints[x] > 1) {
      y += (maxY - minY) * (existingZielePoints[x] - 1) * 0.1;
    }
    const colorIndex = transactionsArray.findIndex(v => v.id === val.id);
    return ({
      x: x,
      y: y,
      marker: {
        size: 6,
        fillColor: getColor(colorIndex),
        strokeColor: '#00e396',
        radius: 2
      },
    });
  }).concat(revenueArrayNoSpecials
    .filter(val => {
      if (!val.endDate) {
        return false;
      }
      const endDateVal = val.endDate.split('.');
      const ageVal = val.age.split('.');
      return val.repeat !== 'Keine Wiederholung'
        && endDateVal[2] !== ageVal[2];
    })
    .map((val, i) => {
      const endDateVal = val.endDate.split('.');
      const x = endDateVal[2] - currentYear + 1;
      let y = dataPointsPerYear[endDateVal[2] - currentYear];
      existingZielePoints[x] = existingZielePoints[x] ? existingZielePoints[x] += 1 : 1;
      if (existingZielePoints[x] > 1) {
        y += (maxY - minY) * (existingZielePoints[x] - 1) * 0.1;
      }
      const colorIndex = transactionsArray.findIndex(v => v.id === val.id);
      return ({
        x: x,
        y: y,
        marker: {
          size: 6,
          fillColor: getColor(colorIndex),
          strokeColor: '#00e396',
          radius: 2
        },
      });
  }));

  // const {
  //   moneyNeededForRetirement,
  //   moneyNeededForRetirementAdditionalStartDate,
  //   moneyNeededForRetirementAdditionalMonthly
  // } = getMoneyNeededForRetirement(
  //   retirementYear,
  //   lifeLength,
  //   dateOfBirth,
  //   moneyNeededAtTheEnd,
  //   +this.form['interestAfterRetirement'],
  //   +this.form['interestBeforeRetirement'],
  //   dataPointsPerYear
  // )

  let moneyNeededForRetirePoints = [
    {
      x: lifeLength - (getAgeTo(currentYear, dateOfBirth) || 0) + 1,
      y: moneyNeededAtTheEnd,
      borderColor: '#775DD0',
      marker: {
        size: 6,
        fillColor: '#775DD0',
        strokeColor: '#775DD0',
        radius: 2
      },
    }
  ];

  let apexSeries = [{data: []}]
  apexSeries[0].data = [
    ...dataPointsPerYear
  ];
  // this.dataLinesBalance.map(line => {
  //   apexSeries.push({
  //     data: line
  //   })
  // })

  let apexXaxis = {
    type: 'category',
    categories: Array.from({ length: 90 }, (_, i) => i + currentYear ),
    labels: {
      show: true,
      rotate: 90,
      hideOverlappingLabels: true,
      showDuplicates: false,
      offsetX: 0,
      offsetY: 0,
      trim: false,
    }
  };
  const apexAnnotations = {
    xaxis: [
      {
        x: retirementYear,
        strokeDashArray: 0,
        borderColor: '#775DD0',
        label: {
          borderColor: '#775DD0',
          style: {
            color: '#fff',
            background: '#775DD0'
          },
          text: 'Renteneintritt'
        }
      },
      {
        x: currentYear + lifeLength - (getAgeTo(currentYear, dateOfBirth) || 0),
        strokeDashArray: 0,
        borderColor: '#775DD0',
        label: {
          borderColor: '#775DD0',
          style: {
            color: '#fff',
            background: '#775DD0'
          },
          text: 'Lebenserwartung'
        }
      },
    ],
    points: zielePoints.concat(revenuePoints).concat(moneyNeededForRetirePoints)
  };

  return {
    apexSeries,
    apexXaxis,
    apexAnnotations,
    apexMarkers,
  }
}

export function getMoneyNeededForRetirement(
  retirementYear,
  lifeLength,
  dateOfBirth,
  moneyNeededAtTheEnd,
  interestAfterRetirement,
  interestBeforeRetirement,
  dataPointsPerYear
) {
  const currentYear = new Date().getFullYear()
  const retirementIndex = retirementYear - currentYear + 1;
  const deathIndex = lifeLength - getAgeTo(currentYear, dateOfBirth);

  let moneyNeededForRetirement = 0;
  let moneyNeededForRetirementAdditionalStartDate = 0;
  let moneyNeededForRetirementAdditionalMonthly = 0;
  if (dataPointsPerYear[deathIndex] >= 0) {
    moneyNeededForRetirement = 0;
    moneyNeededForRetirementAdditionalStartDate = 0;
    moneyNeededForRetirementAdditionalMonthly = 0;
    return;
  }
  const maxMinPointsRetirement = dataPointsPerYear.slice(retirementIndex, deathIndex).sort((a, b) => a - b);
  const minY = maxMinPointsRetirement[0];
  moneyNeededForRetirement = FinancialCalculator.compoundFutureValue(
    Math.abs(minY) - moneyNeededAtTheEnd,
    0,
    interestAfterRetirement,
    1,
    deathIndex - retirementIndex
  );
  moneyNeededForRetirement = FinancialCalculator.initialValue(
    Math.abs(minY) + moneyNeededAtTheEnd,
    interestBeforeRetirement,
    deathIndex - retirementIndex
  );
  moneyNeededForRetirementAdditionalStartDate = Math.abs(FinancialCalculator.initialValue(
    moneyNeededForRetirement,
    interestBeforeRetirement,
    retirementIndex
  ));
  moneyNeededForRetirementAdditionalMonthly = Math.abs(FinancialCalculator.monthlyPayment(
    moneyNeededForRetirement,
    dataPointsPerYear[0],
    interestBeforeRetirement,
    12,
    deathIndex - retirementIndex
  ));

  return {
    moneyNeededForRetirement,
    moneyNeededForRetirementAdditionalStartDate,
    moneyNeededForRetirementAdditionalMonthly
  }
};

export function getAgeTo(year, dateOfBirth) {
  const dob = getDateFromStrOrDate(dateOfBirth)
  if (!dob || !year) {
    return;
  }
  const yearVal = year.split && year.split('.');
  const theYear = yearVal && yearVal[2] ? yearVal[2] : year;
  return theYear - dob.getFullYear() || 0;
};

export function getColor(index) {
  return chartColorScheme[index % chartColorScheme.length];
};

export function getMonthsFromCurrent(date) {
  const now = new Date();
  if (date === undefined || date === null) {
    return 0;
  }
  if (date instanceof Date) {
    if (date < now) {
      return 0;
    }
    return DatePickerUtils.safeDate(date).getMonth() - DatePickerUtils.safeDate(now).getMonth() +
      (12 * (DatePickerUtils.safeDate(date).getFullYear() - DatePickerUtils.safeDate(now).getFullYear()));
  } else {
    const stDate = DatePickerUtils.getDateFromString(date);
    if (stDate < now) {
      return 0;
    }
    return stDate.getMonth() - DatePickerUtils.safeDate(now).getMonth() +
      (12 * (stDate.getFullYear() - now.getFullYear()));
  }
}

export function getDateFromStrOrDate(value) {
  let dob = new Date();
  if (value && (value instanceof Date || typeof value === 'string')) {
    dob = value instanceof Date ? value : dayjs(value, 'DD.MM.YYYY').toDate();
  }
  const dobVal = DatePickerUtils.safeDate(dob);
  return dobVal
}

export function getSpecialEventsData({
  transactionsArray,
  selectedTransactionsSpecial
}) {
  const beforeRetirementIncomeVal = transactionsArray.filter(v => v.type === SPECIAL_TYPE_REVENUE_BEFORE_RETIREMENT_INCOME && selectedTransactionsSpecial.includes(v.type+(v.amount || v.qty)));
  const pensionIncomeVal = transactionsArray.filter(v => v.type === SPECIAL_TYPE_REVENUE_PENSION_INCOME && selectedTransactionsSpecial.includes(v.type+(v.amount || v.qty)));
  const costOfLivingFromRetirementVal = transactionsArray.filter(v => v.type === SPECIAL_TYPE_GOAL_COST_OF_LIVING_AFTER_RETIREMENT && selectedTransactionsSpecial.includes(v.type+(v.amount || v.qty)));
  const beforeRetirementIncome = beforeRetirementIncomeVal.reduce((acc, curr) => acc + +curr.amount, 0);
  const pensionIncome = pensionIncomeVal.reduce((acc, curr) => acc + +curr.amount, 0);
  const costOfLivingFromRetirement = costOfLivingFromRetirementVal.reduce((acc, curr) => acc + +curr.amount, 0);
  const presentValueManualVal = transactionsArray.filter(v => v.type === SPECIAL_TYPE_REVENUE_PRESENT_VALUE_MANUAL && selectedTransactionsSpecial.includes(v.type+(v.amount || v.qty)));
  const presentValue = presentValueManualVal.reduce((acc, curr) => acc + +curr.amount, 0);
  return {
    pensionIncome,
    beforeRetirementIncome,
    costOfLivingFromRetirement,
    presentValue,
  };
}

export function mapGoalAPIToName(val, isIncome = false) {
  switch (val) {
    case 'immobilienkauf':
    case 'estate':
      return isIncome ? 'Immobilienverkauf' : 'Immobilienkauf';
    case 'reserve':
      return 'Sonstiges';
    case 'urlaub':
    case 'holiday':
      return 'Urlaub';
    case 'grossereanschaffung':
      return 'größere Anschaffung';
    case 'weiderkehrendeausgaben':
      return 'weiderkehrende Ausgaben';
    case 'einmaligeeinnahme':
      return 'Einmalige Einnahme';
    case 'regelmassiteeinnahme':
      return 'Regelmässite Einnahme';
    case 'sonstiges':
      return 'Sonstiges';
    case 'purchase':
      return 'Sonstiges';
  }
  return val || 'Sonstiges';
}
