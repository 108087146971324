const allowedPlacements = [
  'bottom', 'top', 'left', 'right', 
  'top-left', 'top-right', 
  'bottom-left', 'bottom-right', 
  'left-top', 'left-bottom', 
  'right-top', 'right-bottom'
];

const cornersPlacements = [
  'top-left', 'top-right', 
  'bottom-left', 'bottom-right', 
];

const baseClassName = 'positioned'

function getAllStyles(element) { 
  return window.getComputedStyle(element); 
}

function getStyle(element, prop) { 
  return getAllStyles(element)[prop]; 
}

function isStaticPositioned(element) {
  return (getStyle(element, 'position') || 'static') === 'static';
}

function offsetParent(element) {
  let offsetParentEl = element.offsetParent || document.documentElement;

  while (offsetParentEl && offsetParentEl !== document.documentElement && isStaticPositioned(offsetParentEl)) {
    offsetParentEl = offsetParentEl.offsetParent;
  }

  return offsetParentEl || document.documentElement;
}

export function position(element) {
  let elPosition;
  let parentOffset = {width: 0, height: 0, top: 0, bottom: 0, left: 0, right: 0};

  if (getStyle(element, 'position') === 'fixed') {
    elPosition = element.getBoundingClientRect();
    elPosition = {
      top: elPosition.top,
      bottom: elPosition.bottom,
      left: elPosition.left,
      right: elPosition.right,
      height: elPosition.height,
      width: elPosition.width
    };
  } else {
    const offsetParentEl = offsetParent(element);

    elPosition = offset(element);

    if (offsetParentEl !== document.documentElement) {
      parentOffset = offset(offsetParentEl);
    }

    parentOffset.top += offsetParentEl.clientTop;
    parentOffset.left += offsetParentEl.clientLeft;
  }

  elPosition.top -= parentOffset.top;
  elPosition.bottom -= parentOffset.top;
  elPosition.left -= parentOffset.left;
  elPosition.right -= parentOffset.left;

  return elPosition;
}

export function offset(element) {
  const elBcr = element.getBoundingClientRect();
  const viewportOffset = {
    top: window.pageYOffset - document.documentElement.clientTop,
    left: window.pageXOffset - document.documentElement.clientLeft
  };

  let elOffset = {
    height: elBcr.height || element.offsetHeight,
    width: elBcr.width || element.offsetWidth,
    top: elBcr.top + viewportOffset.top,
    bottom: elBcr.bottom + viewportOffset.top,
    left: elBcr.left + viewportOffset.left,
    right: elBcr.right + viewportOffset.left
  };

  return elOffset;
}

function resetElementPosition(toBePositionedElement) {
  if(!toBePositionedElement) return;
  const style = toBePositionedElement.style;
  style.position = 'absolute';
  style.top = '0';
  style.left = '0';
  style['will-change'] = 'transform';
}

function addClassToPositionedElement(toBePositionedElement, selectedPlacement, baseNameClass) {
  const[primary, secondary] = selectedPlacement.split('-');
  const regexClass = new RegExp(`${baseClassName}-[bottom|left|right|top][-]?[bottom|left|right|top]?`);

  const toRemove = []

  for (const cl of toBePositionedElement.classList) {
    if (regexClass.test(cl)) {
      toRemove.push(cl)
    }
  }

  toBePositionedElement.classList.remove(...toRemove);

  if (baseClassName) {
    if (primary) {
      toBePositionedElement.classList.add(`${baseNameClass}-${primary}`);
      if (secondary) {
        toBePositionedElement.classList.add(`${baseNameClass}-${primary}-${secondary}`);
      }
    }
  }
}

function findBestMatchPlacement(hostElement, toBePositionedElement, placement, appendToBody, gap, auto) {
  let selectedPlacement = placement;
  let isOnViewCenter = translateElement(hostElement, toBePositionedElement, selectedPlacement, appendToBody, gap);

  if (!isOnViewCenter && auto) {
    for (let index = 0; index < allowedPlacements.length; index++) {
      selectedPlacement = allowedPlacements[index];
      isOnViewCenter = translateElement(hostElement, toBePositionedElement, selectedPlacement, appendToBody, gap);
      
      if (isOnViewCenter) {
        break;
      }
    }
  }

  if (!isOnViewCenter && auto) {
    selectedPlacement = placement;
    isOnViewCenter = translateElement(hostElement, toBePositionedElement, placement, appendToBody, gap, true);
  }

  if (!isOnViewCenter && auto) { // try at the corners placements
    for (let index = 0; index < cornersPlacements.length; index++) {
      selectedPlacement = cornersPlacements[index];
      isOnViewCenter = translateElement(hostElement, toBePositionedElement, selectedPlacement, appendToBody, gap, true);
      
      if (isOnViewCenter) {
        break;
      }
    }
  }

  return selectedPlacement;
}

function isElementInsideViewport(toBePositionedElement) {
  const elBcr = toBePositionedElement.getBoundingClientRect();
  const html = document.documentElement;
  const windowHeight = html.clientHeight;
  const windowWidth = html.clientWidth;

  return elBcr.left >= 0 && elBcr.top >= 0 && elBcr.right <= windowWidth && elBcr.bottom <= windowHeight
}

function translateElement(hostElement, toBePositionedElement, placement, appendToBody, gap, keepAtLeastInViewportBoundary = false) {
  if(!toBePositionedElement) return;

  const [placementPrimary = 'top', placementSecondary = 'center'] = placement.split('-');

  const hostElPosition = appendToBody ? offset(hostElement) : position(hostElement);
  const targetElStyles = getAllStyles(toBePositionedElement);

  const marginTop = parseFloat(targetElStyles.marginTop);
  const marginBottom = parseFloat(targetElStyles.marginBottom);
  const marginLeft = parseFloat(targetElStyles.marginLeft);
  const marginRight = parseFloat(targetElStyles.marginRight);

  let topPosition = 0;
  let leftPosition = 0;

  switch (placementPrimary) {
    case 'top':
      topPosition = (hostElPosition.top - (toBePositionedElement.offsetHeight + marginTop + marginBottom));
      topPosition -= gap;
      break;
    case 'bottom':
      topPosition = (hostElPosition.top + hostElPosition.height);
      topPosition += gap;
      break;
    case 'left':
      leftPosition = (hostElPosition.left - (toBePositionedElement.offsetWidth + marginLeft + marginRight));
      leftPosition -= gap;
      break;
    case 'right':
      leftPosition = (hostElPosition.left + hostElPosition.width);
      leftPosition += gap;
      break;
  }

  switch (placementSecondary) {
    case 'top':
      topPosition = hostElPosition.top;
      break;
    case 'bottom':
      topPosition = hostElPosition.top + hostElPosition.height - toBePositionedElement.offsetHeight;
      break;
    case 'left':
      leftPosition = hostElPosition.left;
      break;
    case 'right':
      leftPosition = hostElPosition.left + hostElPosition.width - toBePositionedElement.offsetWidth;
      break;
    case 'center':
      if (placementPrimary === 'top' || placementPrimary === 'bottom') {
        leftPosition = (hostElPosition.left + hostElPosition.width / 2 - toBePositionedElement.offsetWidth / 2);
      } else {
        topPosition = (hostElPosition.top + hostElPosition.height / 2 - toBePositionedElement.offsetHeight / 2);        
      }
      break;
  }

  if (appendToBody && keepAtLeastInViewportBoundary) {
    topPosition = Math.max(0, topPosition); // keep the element at the top boundary
    leftPosition = Math.max(0, leftPosition); // keep the element at the left boundary
  }

  toBePositionedElement.style.transform = `translate(${Math.round(leftPosition)}px, ${Math.round(topPosition)}px)`;

  return isElementInsideViewport(toBePositionedElement);
}

/**
 * Helper to position an element based on another one. Check options parameter for more details
 * 
 * @param hostElement - the element to be used as a reference
 * @param toBePositionedElement - the element to be positioned
 * 
 * @param options.baseNameClass - class name prefix added to the positioned element
 * @param options.placement - where the positioned element should be
 * @param options.appendToBody - whether the positioned element is appendedToBody or not
 * @param options.gap - amount of space between host and target element
 */
function _positionElement(hostElement, toBePositionedElement, options = {}) {
  if(!toBePositionedElement) return;

  const{baseNameClass = 'positioned', placement = 'bottom', appendToBody = false, gap = 15, auto = true} = options;

  resetElementPosition(toBePositionedElement);
  let selectedPlacement = findBestMatchPlacement(hostElement, toBePositionedElement, placement, appendToBody, gap, auto)

  addClassToPositionedElement(toBePositionedElement, selectedPlacement, baseNameClass)
}

export function positionElement(hostElement, toBePositionedElement, options = {}) {
  window.requestAnimationFrame(() => {
    _positionElement(hostElement, toBePositionedElement, options)
  })
}

export const positionMixin = {
  methods: {
    positionElement(hostElement, refName, options = {}) {
      window.requestAnimationFrame(() => {
        _positionElement(this.$refs[hostElement], this.$refs[refName], options)
      })
    }
  }
}

export default positionMixin;
