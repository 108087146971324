<template>
    <button type="button"
        @click="onClick"
        :tid="_generateTidFromString(tid + actionKey)"
        :disabled="isDisabled || isLoading"
        :title="label"
        class="btn-clean"
        :class="{clickable: !isDisabled && !isLoading, disabled: isDisabled}"
    >
        <AnimatedSpinner v-if="isLoading" :size="size"/>
        <component v-else :is="iconComponent" :size="size"/>
        <span v-if="expanded">
            {{label}}
        </span>
    </button>
</template>
<script>
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';

import actionMixin from './action-mixin.js';

export default {
    mixins: [actionMixin],
    components: {
        AnimatedSpinner,
    },
    props: {
        disabled: {
            type: Function,
            default: null,
        },
        loading: {
            type: Function,
            default: null,
        },
    },
    computed: {
        isDisabled() {
            return this.disabled && this.disabled(this.row);
        },
        isLoading() {
            return this.loading && this.loading(this.row);
        },
    },
}
</script>
<style scoped>
.disabled {
    opacity: 0.5;
}
</style>
<style src='./action.scss' lang='scss' scoped/>
