<template>
  <Table
    tableId="579b1932-470b-4522-8482-1266152082a3"
    :tableData="tableData"
    :cardViewEnabled="false"
    :filterEnabled="false"
    :exportEnabled="false"
    :paginationEnabled="false"
    :rowsPerPage="tableData.records.length"
  >
  </Table>
</template>

<script>
import Table from '@/components/table/Table.vue';


export default {
  props: {
    depotPositions: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    headers() {
      const headers = this.depotPositions?.headers || [];
      return headers.reduce((result, header) => {
        header.visible = true;
        header.sortable = true;

        result[header.key] = header;
        return result;
      }, {});
    },
    records() {
      const headers = this.depotPositions?.headers || [];
      const records = this.depotPositions?.records || [];
      return records.map((record) => {
        const values = record?.values || [];
        return values.reduce((result, value, index) => {
          result[headers[index].key] = value;
          return result;
        }, {});
      });
    },
    tableData() {
      return {
        headers: this.headers,
        records: this.records,
      };
    },
  },
  components: {
    Table,
  },
}
</script>
