<template>
  <BaseToolbarComboBox
    :value="currentValue"
    :values="values"
    :disabled="disabled"
    @change="changeFontSize($event)"
    content="Schriftgröße"
    v-tippy="{ arrow: true }"
  />
</template>

<script>
import BaseToolbarComboBox from "../base-components/BaseToolbarComboBox.vue";
import FC_CONFIG_TYPES from "@/store/fcConfig/types";
import { mapGetters } from "vuex";

const ADD_PARAMETER = "HTML_EDITOR_DEFAULT_FONT_SIZE";

export default {
  components: {
    BaseToolbarComboBox,
  },

  props: {
    editor: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      addParameters: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_ADD_PARAMETER,
    }),
    values() {
      return [
        { value: "", label: "Schriftgröße" },
        { value: "6pt", label: "6" },
        { value: "7pt", label: "7" },
        { value: "8pt", label: "8" },
        { value: "9pt", label: "9" },
        { value: "10pt", label: "10" },
        { value: "11pt", label: "11" },
        { value: "12pt", label: "12" },
        { value: "14pt", label: "14" },
        { value: "15pt", label: "15" },
        { value: "16pt", label: "16" },
        { value: "18pt", label: "18" },
        { value: "20pt", label: "20" },
        { value: "22pt", label: "22" },
        { value: "24pt", label: "24" },
        { value: "28pt", label: "28" },
        { value: "36pt", label: "36" },
        { value: "48pt", label: "48" },
        { value: "72pt", label: "78" },
      ];
    },
    applySavedSettings() {
      return this.editor?.options?.editorProps?.attributes?.applySavedSettings;
    },
    savedOption() {
      return this.applySavedSettings
        ? this.addParameters?.[ADD_PARAMETER]?.content?.trim()
        : "12pt";
    },
    currentValue() {
      const current =
        this.values
          .map((val) => val.value)
          .find((value) =>
            this.editor?.isActive("textStyle", { fontSize: value })
          ) || "";

      return current || this.savedOption || "";
    },
  },

  methods: {
    changeFontSize(newValue) {
      if (newValue) {
        this.editor?.commands.setFontSize(newValue);
      } else {
        this.editor?.commands.unsetFontSize();
      }

      this.editor?.chain().focus().run();
    },
  },
};
</script>
