<template>
    <div>
        <div class="row my-0">
            <div class="col-12">
                <div v-for="(componentsByDivider, index) of listOfComponentsByDivider" :key="index">
                    <div class="box__container">
                         <template v-for="(component, index) of componentsByDivider">
                            <div v-if="!isComponentHidden(component)"
                                :key="index + component.id" 
                                :class="{'indented' : component.config && component.config.indented, 'indented2' : component.config && component.config.indented2,}">
       
                                <AntragComponent
                                    :ref="component.id"
                                    :component="component"
                                    :comboboxSelection="comboboxValues && comboboxValues[component.id] || []"
                                    :data="data || {}"
                                    :disabled="disabled"
                                    :foreignCurrenciesProp="foreignCurrencies"
                                    isComponentHalfSizeEnabled
                                    validateUntouched
                                    @change="doChange(component.id, $event)">
                                </AntragComponent>
                            </div>
                        </template>
                    </div>
                </div> 
            </div>
        </div>  
    </div>
</template>

<script>
import AntragComponent from '@/components/antrag/AntragComponent.vue'; 
import validator from "@/mixins/validator";
import { componentValidatorToRule, isHiddenDisabled } from '@/components/antrag/antrag-utils';

export default {
    mixins: [validator],
    validators: {},
    components: {
        AntragComponent,
    },
    props: {
        components: {
            type: Array,
            default: () => [],
        },
        data: {
            type: Object,
            default: () => {},
        },
        comboboxValues: {
            type: Object,
            default: () => {},
        },
        warnings: {
            type: Object,
            default: () => {},
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        foreignCurrencies: {
            type: Object,
            default: () => {},
        }
    },
    data() {
        return {
            form: {},
        };
    },
    mounted() {
        this.initForm();
    },
    watch: {
        dynamicValidators: {
            immediate: true,
            handler(newDynamicValidators) {
                for (const dynamicValidator of newDynamicValidators) {
                    if (dynamicValidator && this.$configureValidators) {
                        this.$configureValidators({ 
                            [dynamicValidator.componentId]: dynamicValidator.validators,
                        });
                    }
                }
            },
        },
    },
    computed: {
        dynamicValidators() {
            const dynamicValidators = this.components
                .filter(component => component.id && component.validators?.length)
                .map(component => ({
                    componentId: component.id,
                    validators: component.validators.map(componentValidatorToRule)
                })
            )
            this.$emit("isValid", this.validation?.valid);
            return dynamicValidators
        },
        // The 'DIVIDER' components gonna be used to put other components into boxes
        listOfComponentsByDivider() {
            if (Array.isArray(this.components) && this.components.length) {
                
                const componentsGroup = []
                let temp = []
                componentsGroup.push(temp)
                
                for (const component of this.components) {
                    if (component.type === 'DIVIDER') {
                        temp = []
                        componentsGroup.push(temp)
                    } else {
                        temp.push(component)
                    }
                }

    	        this.updateWarnings();
                return componentsGroup.filter(this.hasVisibleComponents)
            }
            return []
        },
    },
    methods: {
        initForm() {
            if (this.components?.length) {
                this.components.forEach(comp => {
                    if (comp.id && this.data) {
                        this.form[comp.id] = this.data[comp.id];
                    }
                })
                this.updateWarnings();
            }
        },
        doChange(compId, value) {
            this.form[compId] = value;
            this.$emit('change', { componentId: compId, value: value });
        },
        updateWarnings() {
            if (this.warnings && Object.keys(this.warnings).length) {              
                    Object.keys(this.warnings).filter(key => Object.keys(this.form).includes(key)).forEach(key => {
                        if(!this.validation?.isTouched(key)) { //!this.form[key]){
                            this.$pushErrors(key, [this.warnings[key].message])
                        }
                    });
                }
        },
        isComponentHidden(component) {
            const result = component && component.hidden && isHiddenDisabled(component.hidden, this.data)
            if (!result) {
                return result;
            }
        
            return result;
        },
        hasVisibleComponents(componentsGroup) {
            return componentsGroup?.length && componentsGroup.some(comp => !(comp.hidden && isHiddenDisabled(comp.hidden, this.data)));
        },
    }
}
</script>

<style scoped>
    .components__container > * {
        margin-bottom: 16px;
    }
    .components__container > :last-child {
        margin-bottom: 0px;
    }
</style>