const defaultOptions = {
  offsetY: 0,
  behavior: 'smooth',
  ignoresWhenTopIsVisible: true,
  animate: false,
};

const SCROLL_CONTAINER_SELECTOR = '[data-scroll-container]';
const ELEMENT_POSITION_Y_PADDING = 50;

const WAIT_FOR_SCROLL_TO_FINISH_INTERVAL = 25;
const WAIT_FOR_SCROLL_TO_FINISH_MAX_CHECKS = 24;

const ANIMATION_CSS_CLASS = 'scale-pulse';

function waitForScrollToFinish(scrollContainerEl, scrollToTop, next) {
  let checkIfScrollFinishedCount = 0;
  const checkIfScrollFinishedId = setInterval(() => {
    checkIfScrollFinishedCount++;
    if (scrollToTop === scrollContainerEl.scrollTop || checkIfScrollFinishedCount >= WAIT_FOR_SCROLL_TO_FINISH_MAX_CHECKS) {
      clearInterval(checkIfScrollFinishedId);

      next?.();
    }
  }, WAIT_FOR_SCROLL_TO_FINISH_INTERVAL);
}

function pulseAnimation(el) {
  el.classList.add(ANIMATION_CSS_CLASS); // add class
  setTimeout(() => el.classList.remove(ANIMATION_CSS_CLASS), 1000); // remove after animation has finished
}

const globalMixin = {
  methods: {
    /**
     * brings an element into view
     * 
     * @param {Object} options 
     */
    $scrollIntoView(el, options = {}) {
      if(!el) return;

      const settings = {
        ...defaultOptions,
        ...(options || {}),
      };

      requestAnimationFrame(() => {
        const scrollContainerEl = el.closest(SCROLL_CONTAINER_SELECTOR) || document.documentElement;
        const containerScrollY = scrollContainerEl.scrollTop;
        const containerOffsetTop = scrollContainerEl.offsetTop || 0;
        const containerHeight = scrollContainerEl.clientHeight;

        const top = el.offsetTop - containerOffsetTop - settings.offsetY;
        const bounding = el.getBoundingClientRect();
        const currentYPosition = bounding.y + ELEMENT_POSITION_Y_PADDING;

        const isElementTopVisible = top >= containerScrollY && currentYPosition < containerHeight;
        if(settings.ignoresWhenTopIsVisible && isElementTopVisible) return;

        const scrollToTop = Math.max(0, top);
        scrollContainerEl.scrollTo({
          top: scrollToTop,
          left: 0,
          behavior: settings.behavior,
        });

        waitForScrollToFinish(scrollContainerEl, scrollToTop, () => {
          if (options.animate) {
            pulseAnimation(el);
          }
        });
      });
    },
  },
};

export const scrollGlobalMixin = globalMixin;
