import axios from 'axios';

import LOGIN_TYPES from './types';
import CORE_TYPES from '@/store/core/types';

const config = {
  defaultSpinner: true
};

export default {

  async [LOGIN_TYPES.ACTIONS.FIND_LOGIN_STATUS]({ getters, commit }) {
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/login/login_status`, config);
    commit(LOGIN_TYPES.MUTATIONS.SET_LOGIN_STATUS, response?.data ?? {});
  },

  async [LOGIN_TYPES.ACTIONS.SAVE_LOGIN_STATUS]({ getters, commit }, payload) {
    const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/login/login_status`, payload, config);
    commit(LOGIN_TYPES.MUTATIONS.SET_LOGIN_STATUS, response?.data ?? {});
  },

}
