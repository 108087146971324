var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.label || _vm.$slots.default
    ? _c(
        "span",
        {
          staticClass: "pill__status",
          class: _vm.pillClass,
          on: {
            click: function($event) {
              return _vm.$emit("click", $event)
            }
          }
        },
        [
          _vm.label
            ? [
                _c("span", {
                  class: { clickable: _vm.isClickable },
                  domProps: { innerHTML: _vm._s(_vm.sanitize(_vm.label)) }
                })
              ]
            : _vm.$slots.default
            ? _vm._t("default")
            : _vm._e(),
          _vm.showCloseIcon
            ? _c(
                "span",
                { staticClass: "clickable" },
                [
                  _c("PhXCircle", {
                    attrs: { size: "16" },
                    on: {
                      click: function($event) {
                        return _vm.removePill()
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }