var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-forms__container" }, [
    _c("div", { staticClass: "input-forms__label-container" }, [
      _c("div", { staticClass: "row mt-0" }, [
        _c("div", { staticClass: "col" }, [
          _vm.title
            ? _c(
                "div",
                {
                  staticClass:
                    "input-forms__label-content input-forms__label-content--bigger"
                },
                [
                  _c("ph-bank", { attrs: { size: 16 } }),
                  _vm._v(" " + _vm._s(_vm.title) + " ")
                ],
                1
              )
            : _vm._e()
        ]),
        _vm.config &&
        _vm.config.buttonText &&
        (_vm.componentType === "WERTPAPIER_AUSWAHL" ||
          _vm.componentType === "WERTPAPIER_AUSWAHL_DIREKT" ||
          _vm.componentType === "WERTPAPIER_AUSWAHL_CAPITALBANK_INVESTMENT")
          ? _c(
              "div",
              { staticClass: "col-auto" },
              [
                _c(
                  "BaseButton",
                  {
                    attrs: { disabled: _vm.disabled },
                    on: { click: _vm.openFormsFinder }
                  },
                  [_vm._v(_vm._s(_vm.config.buttonText))]
                )
              ],
              1
            )
          : _vm._e()
      ])
    ]),
    _c(
      "div",
      { staticClass: "input-forms__input-container" },
      [
        _vm.dynamicTemplate
          ? _c(
              _vm.dynamicTemplate,
              _vm._g(
                _vm._b(
                  { tag: "component" },
                  "component",
                  _vm.dynamicBindings.props,
                  false
                ),
                _vm.dynamicBindings.events
              )
            )
          : _vm._e(),
        _vm.positions &&
        _vm.positions.length &&
        _vm.config &&
        _vm.config.positionFields
          ? _c(
              "div",
              [
                _c("WertpapierAuswahlPositionFields", {
                  attrs: {
                    config: _vm.config,
                    categoryId: _vm.categoryId,
                    antragId: _vm.antragId,
                    disabled: _vm.disabled
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _c("BaseModal", {
          ref: "fehlerModal",
          attrs: { labelButtonCancel: "Ok", showConfirmButton: false },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c(
                    "span",
                    [
                      _c("ph-warning", {
                        staticClass: "color-danger mr-2",
                        attrs: { size: 16 }
                      }),
                      _c("div", { staticClass: "info-modal--content" }, [
                        _vm._v(_vm._s(_vm.textInfo))
                      ])
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }