var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.list && _vm.list.length
    ? _c("div", { staticClass: "mb-16px" }, [
        _c("div", { staticClass: "font-bold" }, [_vm._v(_vm._s(_vm.title))]),
        _c(
          "ul",
          { staticClass: "list-bordered" },
          _vm._l(_vm.list, function(fixedItem) {
            return _c(
              "li",
              { key: fixedItem.key, staticClass: "list-bordered-item" },
              [
                _c("div", { staticClass: "d-flex justify-content-between" }, [
                  _c("div", [_c("span", [_vm._v(_vm._s(fixedItem.label))])]),
                  _c(
                    "div",
                    [_c("ph-lock", { attrs: { weight: "bold", size: 16 } })],
                    1
                  )
                ])
              ]
            )
          }),
          0
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }