import Vue from 'vue';

import LOGIN_TYPES from './types';
import { getInitialState } from './index';

export default {

  [LOGIN_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState());
  },

  [LOGIN_TYPES.MUTATIONS.SET_LOGIN_STATUS](state, payload) {
    Vue.set(state, 'loginStatus', payload ?? {});
  },

}
