var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.records.length
        ? _c("Table", {
            attrs: {
              tableId: "f168f001-d40a-4a9a-ba2b-aa8d3ce74e30",
              headers: _vm.headers,
              rows: _vm.records,
              rowId: "index",
              hidePagination: ""
            },
            on: { "action-DELETE": _vm.deletePosition },
            scopedSlots: _vm._u(
              [
                {
                  key: "anteile",
                  fn: function(row) {
                    return [
                      _c(
                        "div",
                        [
                          _vm.hasGesamtbestand
                            ? _c("ComboBox", {
                                attrs: {
                                  isEmbedded: true,
                                  value: _vm.gesamtbestand(row),
                                  values: _vm.anteileValues
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.onChange("alle", $event, row)
                                  }
                                }
                              })
                            : _vm._e(),
                          !_vm.hasGesamtbestand || _vm.isVisible(row)
                            ? _c("InputField", {
                                attrs: { isEmbedded: true, value: row.anteile },
                                on: {
                                  change: function($event) {
                                    return _vm.onChange("anteile", $event, row)
                                  }
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              807493762
            )
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "mt-3" },
        [
          _c(
            "BaseButton",
            {
              attrs: {
                disabled:
                  _vm.disabled ||
                  (!_vm.records && _vm.resords.length <= _vm.anzahlFonds)
              },
              on: {
                click: function($event) {
                  return _vm.$emit("fondsfinder")
                }
              }
            },
            [_vm._v("Positionen hinzufügen")]
          )
        ],
        1
      ),
      _c("FormLabel", {
        staticClass: "col-12 mt-3",
        attrs: {
          config: { small: true },
          label: "(Maximal " + _vm.anzahlFonds + " Positionen)"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }