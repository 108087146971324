<template>
<div>
    <PageHeaderTitleNavigation :title="title" />

    <BaseFilter
        :title="title"
        :filterId="title"
        :configFilter="filterConfig"
        :metadata="metadata"
        :defaultOptions="defaultOptions"
        showSaveButton
        :immidiateSearch="true"
        @onFilter="onFilter"
    />

    <div class="box__container">
        <GhostLoading v-if="loading" type="table"/>
        <BigTable v-else-if="rows.length" style="clear: both;"
            selectable
            tableId="38b4386f-c591-4165-b3d9-1c1644592596"
            :headers="headers"
            :rows="rows"
            :page="page"
            :sortingState="sortingState"
            :headerActions="headerActions"
            :initialPageSize="minNumRows"
            @onClickHeader="onClickHeader"
            @selectedRow="onRowSelection"
            @selectedRows="onAllRowsSelection"
            @action-READ="actionRead"
            @action-RESULT="actionResult"
            @headerAction-MARKIERTE_POSITIONEN_LOESCHEN="deleteSelected"
        >
            <template v-slot:downloadFile="row">
                <DownloadLink
                        title="Herunterladen"
                        downloadServicePath="/bipro_courtage_file"
                        :filename="row.fileName"
                        :queryParams="{
                            id: row.id,
                        }"
                    >

                    <PhFilePdf v-if="looksLikePDFFile(row)" :size="16" />
                    <PhFile v-else :size="16" />&nbsp;

                </DownloadLink>
                <a v-if="looksLikePDFFile(row)" @click="fetchObject(row)">{{ row.fileName }}</a>
                <span v-else >{{ row.fileName }}</span>
            </template>        
            <template v-slot:zusatz="row">
                <InputField :value="row.zusatz" @change="saveZusatz(row, $event)"/>
            </template>
        </BigTable>
        <NoData v-else/>
    </div>

    <PreviewObject :fullClientHeight="true" ref="resizablePopup" :modalTitle="modalTitle" :objectUrl="objectUrl" id="e3fe3d48-88a8-48f3-9c14-caaefce1a233"></PreviewObject>


</div>
</template>

<script>
import CORE_TYPES from '@/store/core/types'
import LOG_TYPES from '@/store/log/types';
import BIPRO_TYPES from '@/store/bipro/types'

import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue'
import BaseFilter from "@/components/core/BaseFilter.vue";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import InputField from '@/components/core/forms/InputField.vue';

import { mapGetters } from 'vuex';
import axios from 'axios';
import { buildMessage } from "@/helpers/log-message-helper";
import { DatePickerUtils } from '@/components/core/forms/DatePicker/date-picker-utils';
import {PhFile, PhArrowFatRight, PhInfo, PhFilePdf} from 'phosphor-vue';
import BigTable, { DATETIME_COLUMN, DATE_COLUMN } from '@/components/bigTable/BigTable.vue';
import {STRING_COLUMN, ACTION_COLUMN, FIXED_LEFT, INTEGER_COLUMN, SLOT_COLUMN, ICON_COLUMN} from '@/components/bigTable/BigTable.vue';
import { PageHeaderConfirmedAction} from '@/components/core/header-title-navigation/page-header-utils';
import {SimpleAction, ConfirmedAction,  DownloadLinkAction} from "@/components/table2/table_util.js";
import DownloadLink from '@/components/core/download/DownloadLink.vue';
import { downloadLinkMaker } from '@/helpers/utils-helper';
import PreviewObject from '@/components/core/PreviewObject.vue';

const CONFIG = {
    defaultSpinner: true,
};

const ROOT_DOWNLOAD_SERVICE_PATH = '/download_service'

export default {
    mixins: [],
    components: {
        PageHeaderTitleNavigation,
        OptionMenu,
        BaseFilter,
        GhostLoading,
        NoData,
        InputField,
        BigTable,
        DownloadLink,
        PhFilePdf,
        PhFile,
        PreviewObject,     
    },
    props: {
        isCourtage: {
            type: Boolean,
        },
    },
    data() {
        const startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);
        return {
            loading: false,
            selected: [],
            filterConfig: {
                checkDefaultSearchInput: false,
                cbDefSearchInput: null,
                hideFirstColumn: true,
                filterZurucksetzen: null,
                noResetOnDefaultSearchInputExit: true,
            },
            defaultOptions: {
                zeitraum: {
                    value: [{
                        key: "min",
                        value: startDate,
                    },
                    {
                        key: "max",
                        value: new Date(),
                    }],
                },
            },
            filterParams: {},
            headers: [
                { label: "Dateiname", key: "downloadFile", width: 200, type: SLOT_COLUMN, position: FIXED_LEFT, },
                { label: "BiPRO Gevo Art", key: "gevoArt", width: 200, type: INTEGER_COLUMN, visible: true },
                { label: "BiPRO Erstelldatum", key: "creationDate", width: 200, type: DATE_COLUMN, visible: true },
                { label: "BiPRO Gesellschaft", key: "gesellschaft", width: 200, type: STRING_COLUMN, visible: true },
                { label: "Zusatz", key: "zusatz", width: 250, type: SLOT_COLUMN, visible: true },
                { label: "Eingespielt", key: "dateRecorded", width: 200, type: DATETIME_COLUMN, visible: true },
                { label: "Ausgewertet", key: "evaluationDate", width: 200, type: DATETIME_COLUMN, visible: true },
                { label: "Zugang ID", key: "extUserName", width: 400, type: STRING_COLUMN, visible: true },
                { label: "Statuslog", key: "statuslog", width: 600, type: STRING_COLUMN, visible: true },
                { label: "Aktionen", key: "actions", width: 120, type: ACTION_COLUMN, visible: true },
            ],
            sortingState: { key: "gevoArt", direction: "desc" },
            minNumRows: 100,
            page: 0,
            rows: [],
            objectUrl: null,
            modalTitle: 'Vorschau',


        };
    },
    computed: {
        ...mapGetters({
            isIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
            gesellschaftOptions: BIPRO_TYPES.GETTERS.GESELLSCHAFT_OPTIONS_WITH_ALL,
        }),
        headerActions() {
            const actions = [PageHeaderConfirmedAction('MARKIERTE_POSITIONEN_LOESCHEN', 'Markierte Positionen löschen', 'Sollen die markierten Einträge wirklich gelöscht werden?', 'Markierte löschen', 'Löschen').withDisabled(() => !this.rows.some(item => item.selected))];
            return actions;
         },        
        title() {
            return this.isCourtage ? "BiPRO Abrechnungen/Courtage" : "BiPRO Mahn-/Dynamik-/Aktionslisten";
        },
        metadata() {
            return [
                {
                    type: 'group',
                    key: 'allgemein',
                    label: 'Allgemein',
                    menuItems: [
                        {
                            type: 'dateRange',
                            label: 'Zeitraum',
                            key: 'zeitraum',
                            emptyDenied: true,
                        },
                        {
                            type: 'combobox',
                            label: 'Versicherungsgesellschaft',
                            key: 'gesellschaftId',
                            comboOptions: this.gesellschaftOptions || [],
                        },
                        {
                            type: 'default',
                            label: 'mit gelöschten Einträgen anzeigen',
                            key: 'showDeleted',
                        },
                    ],
                },
            ];
        },
    },
    mounted() {
        if (!this.gesellschaftOptions)
            this.$store.dispatch(BIPRO_TYPES.ACTIONS.LOAD_GESELLSCHAFT_OPTIONS);
    },
    methods: {
        looksLikePDFFile(whatRow) {
            const fileName = whatRow?.fileName || '';
            const fileExtension = fileName.slice(fileName.lastIndexOf('.')).toLowerCase();
            return fileExtension === '.pdf';
        },
        fetchObject(fetchData) {
            const fileName = fetchData?.fileName;

            const queryParams = {
                id: fetchData?.id, 
            };

            const downloadServicePath="/bipro_courtage_file";

            const url = downloadLinkMaker(this.$store.getters, `${ROOT_DOWNLOAD_SERVICE_PATH}${downloadServicePath}`, fileName, queryParams);
            this.objectUrl = url;

            this.modalTitle = fileName;

            this.$refs.resizablePopup.showPopup();

        },                  
        onFilter(filterConfig) {
            if (!this.gesellschaftOptions)
                return;
            this.filterParams = {
                isCourtage: this.isCourtage,
            };

            filterConfig.forEach(fc => {
                let value = fc.value;
                if (fc.key == 'showDeleted')
                    value = true; // the filters with above keys have no input field and should only be considered as boolean (true if added to the filter)
                if (fc.key == "zeitraum") {
                    this.filterParams.dateFrom = DatePickerUtils.toDateStr(value.find(v => v.key == 'min').value);
                    this.filterParams.dateTo = DatePickerUtils.toDateStr(value.find(v => v.key == 'max').value);
                } else {
                    this.filterParams[fc.key] = value;
                }
            });
            this.loadRows();
        },
        async loadRows() {
            this.loading = true;
            try {
                const response = await axios.post(`${process.env.VUE_APP_API}/bipro_courtage/filter`, this.filterParams, CONFIG)
                this.rows = response.data || [];

                this.rows = this.rows.map(row => {
                    const actions = [
                    ];
                    actions.push(DownloadLinkAction("DOWNLOAD", PhFile, "Datei Herunterladen", row.fileName, "/bipro_courtage_file", {id: row.id}));
                    if (row.importItem) {
                        if ((row.statuslog || "").replaceAll("\n", "").length == 0) {
                            actions.push(ConfirmedAction("READ", PhArrowFatRight, "Datei einlesen", "Wollen Sie die ausgewählte Datei einlesen und auswerten?", "Sicherheitsabfrage"));
                        } else {
                            // statuslog is created after einlesen, so now the result can be shown
                            actions.push(SimpleAction("RESULT", PhInfo, "Datei Einlesen Resultat anzeigen"));
                        }
                    }
                    return {
                        ...row,
                        selectedHidden: row.invisible,
                        actions,
                    };
                });


            } catch (_) {
                this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Bei der Suche ist ein Fehler aufgetreten.', 'danger'));
            } finally {
                this.loading = false;
            }
        },
        saveZusatz(row, newValue) {
            const data = {
                id: row.id,
                zusatz: newValue,
            };
            axios.post(`${process.env.VUE_APP_API}/bipro_courtage/edit_zusatz`, data, CONFIG)
            .then(response => {
                this.loadRows();
            })
        },
        actionRead(row) {
            axios.post(`${process.env.VUE_APP_API}/bipro_courtage/einlesen`, {id: row.id}, CONFIG)
            .then(response => {
                if (response.data) {
                    this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data, 'info'));
                }
                this.loadRows();
            })
        },
        actionResult(row) {
            this.$router.push({path: "/intern/gdv/import", query: {id: row.fileId}});
        },
        deleteSelected() {
            const idObjects = this.selected.map(row => ({id: row.id}));
            this.selected = [];
            axios.post(`${process.env.VUE_APP_API}/bipro_courtage/delete_rows`, idObjects, CONFIG)
            .then(response => {
                this.loadRows();
            })
        },
        onRowSelection(payload) {
            if (payload?.action == "added") {
                // Check if the row is not already selected before adding
                if (!this.selected.some(item => item === payload.row)) {
                    this.selected.push(payload.row);
                }
            } else if (payload?.action == "removed") {
                // Find the index of the row in selected array
                const index = this.selected.findIndex(item => item === payload.row);
                // If found, remove it
                if (index !== -1) {
                    this.selected.splice(index, 1);
                }
            }
        },
        onAllRowsSelection(payload)  {
            this.selected = payload;
        },  
        onClickHeader(headerMetadata) {
            this.sortingState = {key: headerMetadata?.header?.key, direction: headerMetadata?.sortingState?.direction };
            // TODO: save the sorting state
        }, 

    },
}
</script>
