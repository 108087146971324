<template>
  <li>
    <span 
      class="mr-3"
      v-if="isCollapsed" 
      @click="toggleCollapsed()">
      <ph-plus :size="16" />
    </span>
    <span 
      class="mr-3"
      v-if="!isCollapsed" 
      @click="toggleCollapsed()">
      <ph-minus :size="16" />
    </span>

    <span class="router-node__label">{{ nodePath }}</span>
    <span class="router-node__label font-strong">{{ nodeMenuLabel }}</span>
    <span class="router-node__label color-danger" v-if="!menuHasIcon"> Missing Icon </span>

    <template v-if="!isCollapsed">
      <div class="router-node__roles--allowed">{{rolesAllowed}}</div>
      <div class="router-node__roles--denied">{{rolesDenied}}</div>

      <ul v-if="node.subMenu && node.subMenu.length">
        <RouterNode 
          v-for="(child, index) in node.subMenu"
          :key="index"
          :allCollapsed="allCollapsed"  
          :node="child"/>
      </ul>
    </template>
  </li>
</template>

<script>

import { PhPlus, PhMinus } from 'phosphor-vue'

export default {
  props: {
    node: {
      type: Object
    },
    allCollapsed: {
    }
  },
  data() {
    return {
      collapsed: false
    }
  },
  computed: {
    nodePath() {
      return this.node?.path
    },
    isCollapsed() {
      return this.allCollapsed || this.collapsed
    },
    nodeMenuLabel() {
      return this.node?.label ? ` (${this.node?.label})` : ''
    },
    menuHasIcon() {
      return this.node?.component && !this.node?.component?.__file?.includes('UndrawFinance')
    },
    roles() {
      return this.$router.match(this.nodePath)?.meta?.roles || {};
    },
    rolesAllowed() {
      if (!this.roles?.allowed) {
        return ''
      }

      return `${this.roles?.allowed?.map(a => Array.isArray(a) ? `(${a.join(' && ')})` : a)?.join(' || ')}`
    },
    rolesDenied() {
      if (!this.roles?.denied) {
        return ''
      }

      return `${this.roles?.denied?.map(a => Array.isArray(a) ? `(${a.join(' && ')})` : a)?.join(' || ')}`
    }
  },
  methods: {
    toggleCollapsed() {
      this.collapsed = !this.collapsed
    },
  },
  components: {
    PhPlus,
    PhMinus,
    RouterNode: () => import('@/components/core/router-viewer/RouterNode.vue')
  }
}
</script>

<style scoped>
.router-node__roles--allowed {
  font-size: 0.8rem; 
  font-style:italic;
  color: var(--color-success)
}
.router-node__roles--denied {
  font-size: 0.8rem;
  font-style:italic;
  color: var(--color-danger)
}
</style>