<template   >
  <BaseModal
    v-if="page"
    ref="modal"
    labelButtonCancel="Schließen"
    @onCancelButton="close"
    @onCloseButton="close"
    :confirmDisabled="saving"
    @onConfirmButton="save()"
    :autoClose="false"
    :showConfirmButton="valid()"
    size="sm"
  >
    <DownloadLink
      target="_blank"
      rel="noopener noreferer"
      :title="this.linkTitle"
      downloadServicePath="/pages_download"
      :queryParams="this.getDownloadsPageParams"
    />
    <br />

    <InputField
      ref="input_kun"
      v-model="page.kundennrIn"
      @input="onChangeCustomerNr($event)"
      label="Kundennr"
    />
    <InputField
      ref="input_name"
      v-model="page.kundenname"
      :disabled="true"
      :validateUntouched="true"

      v-if="page.kundennrIn"
    />
    <InputField
      
      v-model="page.defaultFileBezeichnung"
      label="Bezeichnung"
      :validateUntouched="true"

      
    />


    <!--div v-if="page.kundenname &&(page.kundennrIn !=null && /^\d{7}$/.test( page.kundennrIn))" class="col-xl-2 px-1">
            {{ page.kundenname }}
            </div-->

    <ComboBox
      label="Bitte wählen Sie die Dokumentart."
      :firstEmpty="true"
      v-model="page.deckblattLabel"
      :values="this.config ? this.config.items : null"
      :validateUntouched="true"
      @change="onChangeDeck($event)"></ComboBox>
      <DatePickerField 
      :isComponentHalfSize="true"
      :isValueAsString="true"
      v-if="isLeg(page)" label="Ablaufdatum des Ausweises" v-model="page.ablaufDatum" />
   
      <ComboBox
      v-if="isLeg(page)" 
      label="Ausweisinhaber"
      :firstEmpty="false"
      v-model="page.legiInhaberPersonId"
      :values="this.persons "
      :validateUntouched="true"
      ></ComboBox>

  </BaseModal>
</template>

<script>
import { mapGetters } from "vuex";
import InputField from "@/components/core/forms/InputField.vue";
import BaseModal from "@/components/core/BaseModal.vue";
import V_P_TYPES from "@/store/versandProtokolle/types";
import BoxContainer from "@/components/core/BoxContainer.vue";
import InputTextArea from "@/components/core/forms/InputTextArea.vue";
import DownloadLink from "@/components/core/download/DownloadLink.vue";
import LOG_TYPES from "@/store/log/types";
import { buildMessage } from "@/helpers/log-message-helper";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import { doUpload,isLegi } from '@/helpers/versandProtokolle';
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';

import {
  required,
  regex,
  maxLength,
  minLength,
  numbers,
  minValue
} from "@/mixins/validator/rules";
import validator from "@/mixins/validator";

export default {
  mixins: [validator],
  validators: {
    page: {
      kundennrIn: [regex(/^\d{7}$/, "Kundennr 7 Zahlen")],

      kundenname: [minLength(1, "Kundennr nicht richtig")],
      deckblattLabel:[minLength(1, "Bitte wählen Sie die Dokumentart.")],
      legiInhaberPersonId:[minValue(1, "Bitte wählen Sie den Ausweisinhaber. Die Urkunde kann andernfalls nicht in Anträgen verwendet werden.")],
    },
  },

  mounted() {
    
  },
  data() {
    return {
      isLoaded: false,

      customerName: null,
      id: null,
      dat: null,
      saving: false,
      pages: null,
    };
  },
  computed: {
    ...mapGetters({
      page: V_P_TYPES.GETTERS.EDIT_GESEL_PAGE,
      config: V_P_TYPES.GETTERS.EDIT_KUNDEN_PAGE_CONFIG,
      persons:V_P_TYPES.GETTERS.PERSONEN_COMBO
    }),

    linkTitle() {
      if (this.pages) {
        let res = " Seiten ";
        if (this.pages.length == 1) {
          res = " Seiten ";
        }
        return res + this.pages.map((t) => t.rownum).join(",");
      } else {
        return null;
      }
    },

    getDownloadsPageParams() {
      if (this.pages) {
        return {
          eingangId: this.id,
          pages: this.pages.map((t) => t.rownum),
        };
      } else {
        return "";
      }
    },
  },

  methods: {
    async open(i, pages) {
      this.$store.commit(V_P_TYPES.GETTERS.EDIT_GESEL_PAGE, null);

      this.id = i;

      this.pages = pages;
      await this.loadData(i, pages[0].rownum);

   
      await this.$refs.modal?.open();
      await this.$refs.input_kun?.$runValidator(this.page.kundennrIn);
      await this.$refs.input_name?.$runValidator(this.page.kundennrIn);
    },
    async loadData(id, page) {
      //await this.$store.commit(V_P_TYPES.MUTATIONS.EDIT_GESEL_PAGES,pages);
      let e = {};
      e.id = id;
      e.page = page;
      this.$store.commit(V_P_TYPES.MUTATIONS.EDIT_GESEL_PAGE, null);

      await this.$store.dispatch(V_P_TYPES.ACTIONS.GET_PAGE, e);
    },
    async close() {
      // this.$store.commit(V_P_TYPES.MUTATIONS.EINGANG,null);
      //await this.$store.dispatch(V_P_TYPES.ACTIONS.EINGANG, this.id);
      /*console.log('close');
      this.page.kundennrIn='1234567';
      this.validation.reset("input_kun");
      this.$store.commit(V_P_TYPES.MUTATIONS.EDIT_GESEL_PAGE,this.page);*/

      this.$store.commit(V_P_TYPES.GETTERS.EDIT_GESEL_PAGE, null);

      this.$refs.modal.handleClosed();
    },

    async onChangeCustomerNr($event) {
      if (!$event) {
        this.page.kundenname = "";
        this.page.legiInhaberPersonId=null;
        this.$store.commit(V_P_TYPES.MUTATIONS.EDIT_GESEL_PAGE, this.page);
        return;
      }

      let b = /^\d{7}$/.test($event);
      if (!b) {
        //this.page.kundennrIn='1';
        this.page.kundenname = "";
        this.page.legiInhaberPersonId=null;
        this.$store.commit(V_P_TYPES.MUTATIONS.EDIT_GESEL_PAGE, this.page);
      } else {
        this.page.legiInhaberPersonId=null;
        //alert(typeof event)
        this.$store.commit(V_P_TYPES.MUTATIONS.EDIT_GESEL_PAGE, this.page);
        await this.$store.dispatch(V_P_TYPES.ACTIONS.GET_KUNDEN_NAME, $event);
       
       await this.$store.dispatch(V_P_TYPES.ACTIONS.PERSONEN_COMBO);
      }
    },
    async onChangeDeck(event){
        this.page.deckblattLabel=event;
        this.$store.commit(V_P_TYPES.MUTATIONS.EDIT_GESEL_PAGE, this.page);
        await this.$store.dispatch(V_P_TYPES.ACTIONS.PERSONEN_COMBO);
    },

    async save() {
      try {
        this.saving = true;
        if (!this.valid()) {
          return;
        }
        let res = {};
        res.eingangId = this.id;
        res.rownums = this.pages.map((t) => t.rownum);
        res.kundennr=this.page.kundennrIn;
        res.deckblattLabel=this.page.deckblattLabel;
        res.defaultFileBezeichnung=this.page.defaultFileBezeichnung;
        if(isLegi(this.page)){
          res.legiInhaberPersonId=this.page.legiInhaberPersonId;
          res.ablaufDatum=this.page.ablaufDatum
        }  
        await this.$store.dispatch(
          V_P_TYPES.ACTIONS.SET_KUNDEN_DECKBLATT_PAGES,
          res
        );
        let bool = this.$store.getters[V_P_TYPES.GETTERS.ERROR_EDIT];
        
        if (!bool) {
          this.close();
        }
      } finally {
        this.saving = false;
      }
    },
    isLeg(page){
        return isLegi(page)

    },
    valid() {
      if (!this.page) {
        return false;
      }
      if (!this.page.deckblattLabel) {
        return false;
      }
      if (!this.page.kundennrIn || !this.page.kundenname) {
        return false;
      }

      

      return true;
    },
  },
  components: {
    BaseModal,

    InputField,
    InputTextArea,
    DownloadLink,
    BoxContainer,
    ComboBox,
    InputField,
    DatePickerField
  },
};
</script>