import LOADING_TYPES from './types';

export default {

  [LOADING_TYPES.ACTIONS.START_LOADING]({ getters, commit, }, id) {
    const isAlreadyLoading = getters[LOADING_TYPES.GETTERS.IS_LOADING](id);
    if(isAlreadyLoading) return;

    commit(LOADING_TYPES.MUTATIONS.ADD_LOADING, id);
  },

  [LOADING_TYPES.ACTIONS.STOP_LOADING]({ commit, }, id) {
    commit(LOADING_TYPES.MUTATIONS.STOP_LOADING, id);
  },

}
