export const MUTATION_PREFIX = 'ANTRAG_PDF: ';
export const ACTIONS_PREFIX = 'ANTRAG_PDF_ACTIONS_';
export const GETTERS_PREFIX = 'ANTRAG_PDF_GETTERS_';

export default {
  MUTATIONS: {
    RETRIEVE_DYNAMIC_DOCUMENT_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_DYNAMIC_DOCUMENT_SUCCESS',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    RELOAD_DYNAMIC_DOCUMENT_SUCCESS: MUTATION_PREFIX + 'RELOAD_DYNAMIC_DOCUMENT_SUCCESS',
    RETRIEVE_PLACEHOLDERS_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_PLACEHOLDERS_SUCCESS',
    SET_DOCUMENT_HAS_CHANGED: MUTATION_PREFIX + 'SET_DOCUMENT_HAS_CHANGED',
    RETRIEVE_SELECTIONS_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_SELECTIONS_SUCCESS',
  },
  ACTIONS: {
    RETRIEVE_DYNAMIC_DOCUMENT: ACTIONS_PREFIX + 'RETRIEVE_DYNAMIC_DOCUMENT',
    PDF_PREVIEW: ACTIONS_PREFIX + 'PDF_PREVIEW',
    RELOAD_DYNAMIC_DOCUMENT: ACTIONS_PREFIX + 'RELOAD_DYNAMIC_DOCUMENT',
    SAVE_DYNAMIC_DOCUMENT: ACTIONS_PREFIX + 'SAVE_DYNAMIC_DOCUMENT',
    RETRIEVE_PLACEHOLDERS: ACTIONS_PREFIX + 'RETRIEVE_PLACEHOLDERS',
    RETRIEVE_SELECTIONS: ACTIONS_PREFIX + 'RETRIEVE_SELECTIONS',
  },
  GETTERS: {
    SELECTED_DYNAMIC_DOCUMENT: GETTERS_PREFIX + 'SELECTED_DYNAMIC_DOCUMENT',
    PLACEHOLDERS: GETTERS_PREFIX + 'PLACEHOLDERS',
    GET_DOCUMENT_HAS_CHANGED: GETTERS_PREFIX + 'GET_DOCUMENT_HAS_CHANGED',
    COMBOBOX_SELECTIONS: GETTERS_PREFIX + 'COMBOBOX_SELECTIONS',
  }
}