var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: {
        modalTitle: "Einladung akzeptieren",
        autoClose: false,
        labelButtonConfirm: "Konto hinzufügen",
        showDefaultButtons: true
      },
      on: {
        onConfirmButton: _vm.addAccount,
        onCancelButton: _vm.close,
        onCloseButton: _vm.close
      }
    },
    [
      _c("InputField", {
        attrs: { label: "Login Name", disabled: "" },
        model: {
          value: _vm.invitation.ssoName,
          callback: function($$v) {
            _vm.$set(_vm.invitation, "ssoName", $$v)
          },
          expression: "invitation.ssoName"
        }
      }),
      _c("InputField", {
        attrs: { label: "temporäres Passwort", type: "password" },
        model: {
          value: _vm.password,
          callback: function($$v) {
            _vm.password = $$v
          },
          expression: "password"
        }
      }),
      _vm.errorStr
        ? _c("div", { staticClass: "color-danger mb-2" }, [
            _vm._v(_vm._s(_vm.errorStr))
          ])
        : _vm._e(),
      _c("div", [
        _vm._v(
          "Dieses Passwort wird Ihnen vom Hauptnutzer mitgeteilt, es handelt sich hierbei nicht um Ihr Login Kennwort."
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }