import WERT_TYPES from './types';
import { getInitialState } from './index'

export default {
  [WERT_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },

  
  [WERT_TYPES.MUTATIONS.SET_PERFORMANCE_PAYLOAD](state, payload) {
    state.performancePayload = payload;
  },
  [WERT_TYPES.MUTATIONS.SET_PROGRESS_DETAILS_CONFIG](state, payload) {
    state.progressDetailsConfig = payload;
  },

  [WERT_TYPES.MUTATIONS.SET_PERFORMANCE_DATA](state, payload) {
    state.performanceData = payload;
  },
  [WERT_TYPES.MUTATIONS.RENEW_PERFORMANCE_DATA](state, payload) {
    state.performanceData = {};
  },
  [WERT_TYPES.MUTATIONS.SET_HISTORICAL_PERFORMANCE](state, payload) {
    state.historicalData = payload;
  },
  [WERT_TYPES.MUTATIONS.RENEW_HISTORICAL_PERFORMANCE](state, payload) {
    state.historicalData = {};
  },
  [WERT_TYPES.MUTATIONS.SET_REALIZED_PROFIT](state, payload) {
    state.realizedProfit = payload;
  },
  [WERT_TYPES.MUTATIONS.RENEW_REALIZED_PROFIT](state, payload) {
    state.realizedProfit = {};
  },
  [WERT_TYPES.MUTATIONS.SET_YEAR_PERFORMANCE_DATA](state, payload) {
    state.yearPerformanceData = payload;
  },
  [WERT_TYPES.MUTATIONS.RENEW_YEAR_PERFORMANCE_DATA](state, payload) {
    state.yearPerformanceData = {};
  },
  [WERT_TYPES.MUTATIONS.SET_TRANSACTIONS](state, payload) {
    state.transactions = payload;
  },
  [WERT_TYPES.MUTATIONS.RENEW_TRANSACTIONS](state, payload) {
    state.transactions = {};
  },
  [WERT_TYPES.MUTATIONS.SET_DEPOT_CONFIGS](state, payload) {
    state.depotConfigs = {};
  },
  [WERT_TYPES.MUTATIONS.SET_DEPOT_ID](state, payload) {
    state.depotid = payload;
  },
}