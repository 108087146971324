<template>
    <div>
        <PageHeaderTitleNavigation 
            :title="title">
        </PageHeaderTitleNavigation>
        <div class="box__container">
            <InputField label="Login-Name" v-model="ssoName" isComponentHalfSize />
            <ComboBox label="Berechtigung" v-model="permission" :values="comboBerechtigung" isComponentHalfSize />
            <InputField label="temporäres Passwort" v-model="password" type="password" isComponentHalfSize allowPlainPassword />
            <PasswordPolicy />

            <div class="mb-3">
                <div class="text-bold">Bitte beachten Sie:</div>
                <ul class="mt-1 mb-0">
                    <li>Wenn Sie einen SSO-Login angeben, wird die Einladung an das entsprechenden SSO-Konto gesendet und nur dessen Besitzer kann sich damit anmelden.</li>
                    <li>Die Einladung sollte also sofort mit einem SSO-Konto verknüpft werden, um die Zugangsberechtigung dauerhaft zu behalten.</li>
                    <li>Verwenden Sie nicht das Passwort, das Sie für ihre Anmeldung verwenden!</li>
                </ul>
            </div>

            <div class="mt-4">
                <router-link :to="{name: 'sso'}">
                    <span @click="updateBreadcrumb()">zurück</span>
                </router-link>
                <BaseButton class="ml-3" @click="createInvite" :disabled="isFormInvalid" isPrimary>Einladen</BaseButton>
            </div>

            <BaseModal ref="notExist" labelButtonCancel="Ok" :showConfirmButton="false" @onCancelButton="closeErrorModal">
                <template v-slot:modalTitle>
                    <PhInfo style="color: orange" />
                    Fehler
                </template>
                <div>
                    {{errorStr}}
                </div>
            </BaseModal>
        </div>
    </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import SSO_TYPES from '@/store/sso/types';
import { mapGetters } from 'vuex';
import { PhInfo } from 'phosphor-vue';

import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import BaseButton from '@/components/core/BaseButton.vue'
import BaseModal from '@/components/core/BaseModal.vue';
import { required } from '@/mixins/validator/rules';
import validator from '@/mixins/validator';
import mixin from '@/mixins/sso/sso-mixin.js';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import PasswordPolicy, { NEW_PASSWORD_COMMON_RULES, deniedKeywordsRule } from '@/views/core/change-password/PasswordPolicy.vue';

const DEFAULT_TITLE = 'Registrierten Benutzer hinzufügen'

export default {
    mixins: [validator, mixin],
    validators: {
        ssoName: [required("Konto ist erforderlich!")],
        password: [ 
            ...NEW_PASSWORD_COMMON_RULES,
            deniedKeywordsRule((self) => [self.ssoName]),
        ],
    },
    components: {
        InputField,
        ComboBox,
        BaseButton,
        BaseModal,
        PhInfo,
        PageHeaderTitleNavigation,
        PasswordPolicy,
    },
    data() {
        return {
            ssoName: "",
            permission: "",
            password: "",
            errorStr: '',
        };
    },
    mounted() {
        this.permission = this.comboBerechtigung[0].value;
    },
    computed: {
        ...mapGetters({
            fullname: CORE_TYPES.GETTERS.GET_USER_FULL_NAME,
        }),
        title() {
            return DEFAULT_TITLE
        },
        isFormInvalid() {
            return this.validation.updated && (this.validation.isInvalid('ssoName') 
                || this.validation.isInvalid('password'));
        },
    },
    methods: {
        createInvite() {
            this.updateBreadcrumb();
            const response = this.$store.dispatch(SSO_TYPES.ACTIONS.CREATE_INVITE, {
                ssoName: this.ssoName,
                permission: this.permission,
                password: this.password,
            }).then((errorStr) => {
                if (errorStr) {
                    this.errorStr = errorStr;
                    this.$refs.notExist.open();
                } else {
                    this.$router.push({name: 'sso'});
                }
            })
        },
        closeErrorModal() {
            this.errorStr = null;
        },
        updateBreadcrumb() {
            this.$store.commit(CORE_TYPES.MUTATIONS.POP_SAVE_BACK_TO_PREVIOUS_PAGE);
        },
    },
}
</script>

<style scoped>

</style>