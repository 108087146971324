<template>
  <div class="map-chart">
    <v-chart
      :option="mapOptions"
      :init-options="initOptions"
      ref="map"
      :theme="themeKey"
      autoresize
    />
  </div>
</template>

<script>
import qs from 'qs';
import VChart from 'vue-echarts';
import {
  use,
  registerMap,
  connect,
  disconnect
} from 'echarts/core';
import {
  LineChart,
  PieChart,
  MapChart,
  RadarChart,
  ScatterChart,
  EffectScatterChart,
  LinesChart
} from 'echarts/charts';
import {
  GridComponent,
  PolarComponent,
  GeoComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  VisualMapComponent,
  DatasetComponent,
  ToolboxComponent,
  DataZoomComponent
} from 'echarts/components';
import { CanvasRenderer, SVGRenderer } from 'echarts/renderers';
import 'echarts-liquidfill';
import echartsMixin from './echarts-mixin';
import map from './map-helpers/map';
import worldMap from './map-helpers/world_de.json';
import {countriesMap} from './map-helpers/countries_de.js'
use([
  LineChart,
  PieChart,
  MapChart,
  RadarChart,
  ScatterChart,
  EffectScatterChart,
  LinesChart,
  GridComponent,
  PolarComponent,
  GeoComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  VisualMapComponent,
  DatasetComponent,
  CanvasRenderer,
  SVGRenderer,
  ToolboxComponent,
  DataZoomComponent
]);

registerMap('world', worldMap);

export default {
  name: 'vue-echarts-map',
  mixins: [echartsMixin],
  components: {
    VChart
  },
  props: {
    chartData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    const options = qs.parse(location.search, { ignoreQueryPrefix: true });
    return {
      options,
      map,
      initOptions: {
        renderer: options.renderer || 'canvas'
      },
      connected: true,
    };
  },
  computed: {
    mapOptions() {
      const countries = countriesMap()
      const series = [{
        ...map.series[0],
        data: this.chartData.map((v, i) => ({
          name: countries[v.name] || v.name,
          value: i <= 2 ? i+1 : 23
        }))
      }]
      return {
        ...map,
        series,
        backgroundColor: 'transparent'
      }
    }
  },
  methods: {
    startActions() {
      let dataIndex = -1;
      const pie = this.$refs.pie;
      if (!pie) {
        return;
      }
      const dataLen = pie.option.series[0].data.length;
      this.actionTimer = setInterval(() => {
        pie.dispatchAction({
          type: 'downplay',
          seriesIndex: 0,
          dataIndex
        });
        dataIndex = (dataIndex + 1) % dataLen;
        pie.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex
        });
        pie.dispatchAction({
          type: 'showTip',
          seriesIndex: 0,
          dataIndex
        });
      }, 1000);
    },
    stopActions() {
      clearInterval(this.actionTimer);
    }
  },
  watch: {
    connected: {
      handler(value) {
        if (value) {
          connect('radiance');
        } else {
          disconnect('radiance');
        }
      },
      immediate: true
    },
    'initOptions.renderer'(value) {
      this.options.renderer = value === 'svg' ? value : undefined;
      let query = qs.stringify(this.options);
      query = query ? '?' + query : '';
      history.pushState(
        {},
        document.title,
        `${location.origin}${location.pathname}${query}${location.hash}`
      );
    },
  },
  mounted() {
    this.startActions();
  },
  beforeUnmount() {
    this.stopActions();
  }
};
</script>

<style scoped>
.map-chart {
  height: 100%;
  min-height: 350px;
  min-width: 350px;
}
</style>