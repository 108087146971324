var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "calendar-datepicker__container" },
    [
      _c(
        "DatePickerField",
        {
          staticClass: "calendar__datepicker p-0 m-0",
          attrs: {
            asCalendar: _vm.asCalendar,
            showSecondarySelector: _vm.asCalendar,
            highlightWeek: _vm.highlightWeek,
            monthPicker: _vm.monthPicker,
            datePickerSelectorsInline: false,
            value: _vm.value,
            disabled: _vm.disabled
          },
          on: {
            input: function($event) {
              return _vm.$emit("input", $event)
            },
            change: function($event) {
              return _vm.$emit("change", $event)
            }
          }
        },
        [[_c("PhCalendarBlank", { attrs: { size: "20" } })]],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }