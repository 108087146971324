<template>
  <router-view></router-view>
</template>

<script>
import { mapGetters } from 'vuex'
import CORE_TYPES from '@/store/core/types'
import { loadColorScheme } from '@/configs/color-config.js'

export default {
  watch: {
    lightSchema: {
      handler(current) {
        // TL;DR Force the load color schema one more time to prevent cms schema to be applied

        // When the domain is loaded without the /login path, a check in guards is done to know whether this is a 
        // CMS access or a regular one. In case of latter, a navigation to login is triggered.
        // The hook BeforeEnter is used to load the colors of login page, but since the page was previously a cms one, 
        // It loads the wrong layout
        //loadColorScheme(current)
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters({
      colorSchema: CORE_TYPES.GETTERS.GET_COLOR_SCHEMA
    }),
    lightSchema() {
      return this.colorSchema?.light?.layout
    }
  },
}
</script>
