var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: { name: "modal-sheet-fade-down" },
      on: {
        "after-leave": function($event) {
          return _vm.handleClosed()
        }
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isOpen,
              expression: "isOpen"
            }
          ],
          staticClass: "base-sheet-modal__container is-modal-component",
          class: { "is-open": _vm.isOpen }
        },
        [
          _c(
            "div",
            {
              staticClass: "base-sheet-modal__backdrop",
              on: {
                click: function($event) {
                  return _vm.handleCloseBackdrop($event)
                }
              }
            },
            [
              _c("div", { staticClass: "base-sheet-modal__content-wrap" }, [
                _c("div", { staticClass: "base-sheet-modal__content" }, [
                  _c("div", { staticClass: "base-sheet-modal__header" }, [
                    _c(
                      "div",
                      { staticClass: "base-sheet-modal__header--wrap" },
                      [
                        _c(
                          "div",
                          { staticClass: "base-sheet-modal__header--title" },
                          [
                            _vm._t("modalTitle", function() {
                              return [_vm._v(_vm._s(_vm.modalTitle))]
                            })
                          ],
                          2
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "base-sheet-modal__swipe-close",
                            on: {
                              touchstart: function($event) {
                                return _vm.swipeStart($event)
                              },
                              touchend: function($event) {
                                return _vm.swipeEnd($event)
                              }
                            }
                          },
                          [
                            _c("div", {
                              staticClass: "base-sheet-modal__swipe-close--icon"
                            })
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "base-sheet-modal__header--toolbar" },
                          [
                            _vm._t("headerToolbar"),
                            _c(
                              "BaseButton",
                              {
                                staticClass: "base-sheet-modal__header--close",
                                attrs: { isLink: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.close()
                                  }
                                }
                              },
                              [_vm._v("Abbrechen")]
                            )
                          ],
                          2
                        )
                      ]
                    )
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "base-sheet-modal__body",
                      style: _vm.modalBodyStyle
                    },
                    [_vm._t("default")],
                    2
                  )
                ])
              ])
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }