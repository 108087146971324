<template>
    <div>
  
      <BrokerSearch
        :customOptionMenu="customPageHeaderMenu"
        :isExternalSelect="isExternalSelect"
        :filterType="filterType" 
        :isWithPerson="false"    
        :showCheckBoxBulkAction="false"
        :singleSelection="true"
        @handleBackAction="handleBackAction"
      />

    </div>
  </template>
  
  <script>
  import BrokerSearch from "@/components/brokerSearch/BrokerSearch.vue";
  import CUSTOMER_SEARCH_TYPES from "@/store/customerSearch/types";
  import INTERNESTATISTIKEN_TYPES from '@/store/interneStatistiken/types';
  import { TabMenu } from '@/menu/menu-utils';
  
  export default {
    components: {
      BrokerSearch,
    },
    props: {
      filterTypeProp: {
        type: String,
        default: null,
      },
      isExternalSelect: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        optionMenu: [          
          {
            label: 'Vermittler',
            path: 'brokerselect',
          },          
        ],
      };
    },
    computed: {
      filterType() {
        return (
          this.filterTypeProp
        );
      },
      type() {
        return this.$route.params.folder
      },
      basePath() {
        return this.$route.path.substring(0, this.$route.path.lastIndexOf('/'));//.replace(`/${this.$route.params.folder}`, '')
      },
      isSingleSelection() {
        return this.$route?.query?.singleSelection ==='true' || false;
      },
      customPageHeaderMenu() {
        return this.optionMenu.map(item => TabMenu(`${this.basePath}/${item.path}`, item.label))
      },
    },
    methods: {
      handleBackAction(values) {
        //  console.log("backaction, values:" + JSON.stringify(values));
        if (values.length > 0) {
          this.$store.commit(INTERNESTATISTIKEN_TYPES.MUTATIONS.SET_FILTER_BROKER_NUMBER, values[0].userId);
        } else {
          this.$store.commit(INTERNESTATISTIKEN_TYPES.MUTATIONS.SET_FILTER_BROKER_NUMBER, null);  
        }        
        this.$router.push({ name: 'internestatistiken' , query: { userselected: true, backAction: true } });
      },
    },
  };
  </script>
  
  <style scoped>
  </style>
  