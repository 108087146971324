var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "event-body" },
    [
      _c(
        "BaseModal",
        {
          ref: "modala",
          attrs: {
            showCloseButton: true,
            showDefaultButtons: false,
            showConfirmButton: false,
            autoClose: true,
            size: "xl"
          },
          on: {
            onCloseButton: function($event) {
              return _vm.close()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [_vm._v(" " + _vm._s(_vm.title) + " ")]
              },
              proxy: true
            },
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "BaseButton",
                    { attrs: { isSecondary: "" }, on: { click: _vm.close } },
                    [_vm._v("close")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [_c("BoxContainer", [_c("DBMT", { attrs: { dat: this.dat } })], 1)],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }