import DATENSCHUTZKUNDENAENDERUNGEN_TYPES from './types';

export default {
  [DATENSCHUTZKUNDENAENDERUNGEN_TYPES.GETTERS.MAIN_LISTING](state) {
    return state.mainListing;
  },
  [DATENSCHUTZKUNDENAENDERUNGEN_TYPES.GETTERS.DETAILS_LISTING](state) {
    return state.detailsListing;
  },
  [DATENSCHUTZKUNDENAENDERUNGEN_TYPES.GETTERS.TEXT_MODUS](state) {
    return state.textModus;
  },  
}