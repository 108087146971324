<template>
    <div class="legidocs-container">
        <div v-for="(legiDoc, index) in getLegiDocsForAuthor" class="legidoc-container">
            <h5>
                {{ legiDoc.label }}
                <PhArrowArcLeft size="20" @click="rotateCounterClockwise(index)" class="img-control" />
                <PhArrowArcRight size="20" @click="rotateClockwise(index)" class="img-control" />
                <PhArrowSquareOut size="20" @click="openImage(legiDoc)" class="img-control" />
            </h5>
            <img :src="legiDoc.img" class="legidoc-img" @click="openImage(legiDoc)" />
        </div>
        <PreviewImage ref="legitimationImageModal" :modalTitle="resizableModalOptions.title"
            :initialHeight="resizableModalOptions.height" :initialWidth="resizableModalOptions.width" startPosition="TOP_RIGHT"
            :imgUrl="resizableModalOptions.img" id="444d7f03-94f9-4b9a-b7c3-b4f906206163"/>
    </div>
</template>

<script>
import { SIGNATURE_AUTHOR_TYPES } from '@/components/suitabilityTest/SuitabilityTest.vue';
import PreviewImage from '@/components/core/PreviewImage.vue';
import { PhArrowArcLeft, PhArrowArcRight, PhArrowSquareOut } from 'phosphor-vue';
import { base64ToArrayBuffer } from "@/helpers/download-helper";
import {rotateImage90Clockwise} from '@/helpers/image-operation-helper';


export default {
    components: {
        PreviewImage,
        PhArrowArcLeft,
        PhArrowArcRight,
        PhArrowSquareOut,
    },
    props: {
        signatureAuthor: {
            type: Object,
            required: true
        },
        kunde: {
            type: Object,
            default: null
        },
        zusaetzlichePersonen: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            legiDocs: [],
            resizableModalOptions: {
                title: 'Legitimationsurkunde',
                img: '',
                width: 600,
                height: 400,
            },
        }
    },
    computed: {
        secondDepotinhaber() {
            return this.zusaetzlichePersonen.find(p => {
                return p.relationship?.extraDepotOwner;
            })
        },
        getLegiDocsForAuthor() {
            if (this.signatureAuthor.key == SIGNATURE_AUTHOR_TYPES.ALLE.key) {
                return this.legiDocs;
            }
            return this.legiDocs.filter(legiDoc => {
                return legiDoc.key === this.signatureAuthor.key;
            })
        }
    },
    watch: {
        kunde: {
            handler() {
                this.setLegiDocs();
            },
            immediate: true,
        },
        },
    methods: {
        openImage(legiDoc) {
            this.resizableModalOptions.title = legiDoc.label;
            this.resizableModalOptions.img = legiDoc.img;

            var img = new Image();
            img.src = legiDoc.img;
            this.resizableModalOptions.width = Math.min(img.width, 600);
            this.resizableModalOptions.height = img.height * ( this.resizableModalOptions.width / img.width) + 44; //+44 to offset headerheight of resizable modal and scale height
            this.$refs.legitimationImageModal.showPopup();
        },
        getImgSrcLegi(image) {
            if (image) {
                let byteArray = base64ToArrayBuffer(image.bytes);
                const typedArray = new Uint8Array(byteArray);
                const blob = new Blob([typedArray], { type: image.contentType });
                return URL.createObjectURL(blob);
            }
            return '';
        },
        rotateCounterClockwise(index) {
            var img = new Image();
            img.src = this.legiDocs[index].img;
            this.legiDocs[index].img = rotateImage90Clockwise(img, -1).toDataURL('image/jpeg');
        },
        rotateClockwise(index) { //index of legiDoc in legidocs
            var img = new Image();
            img.src = this.legiDocs[index].img;
            this.legiDocs[index].img = rotateImage90Clockwise(img).toDataURL('image/jpeg');
        },
        setLegiDocs() {//cant use legidocs in suitabilityResult directly, because its stored in a store and shouldnt be changed (which we do if the image is rotated)
            this.legiDocs = [];
            const IMAGE_LABELS = { bildVorderseite: 'Vorderseite', bildRueckseite: 'Rückseite', bildMeldebescheinigung: 'Meldebescheinigung' }
            if (!this.kunde) {
                return [];
            }
            Object.keys(IMAGE_LABELS).forEach(key => {
                if (this.kunde.legitimation[key]) {
                    this.legiDocs.push({
                        key: SIGNATURE_AUTHOR_TYPES.DEPOTINHABER_1.key,
                        label: `${SIGNATURE_AUTHOR_TYPES.DEPOTINHABER_1.label}: ${IMAGE_LABELS[key]}`,
                        img: this.getImgSrcLegi(this.kunde.legitimation[key])
                    });
                }
            })
            if (this.secondDepotinhaber !== undefined) {
                Object.keys(IMAGE_LABELS).forEach(key => {
                    if (this.secondDepotinhaber.legitimation[key]) {
                        this.legiDocs.push({
                            key: SIGNATURE_AUTHOR_TYPES.DEPOTINHABER_2.key,
                            label: `${SIGNATURE_AUTHOR_TYPES.DEPOTINHABER_2.label}: ${IMAGE_LABELS[key]}`,
                            img: this.getImgSrcLegi(this.secondDepotinhaber.legitimation[key])
                        });
                    }
                })
            }
        },

    }
}
</script>

<style lang="scss" scoped>
.legidoc-container {
    flex: 1 300px;
    margin: 5px 5px 5px 0;
}

.legidoc-img {
    max-height: 250px;
    cursor: pointer;
}

.img-control {
    cursor: pointer;
    margin: 0 5px;
}

.legidocs-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}
</style>