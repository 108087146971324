var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _c("div", { staticClass: "box__title" }, [_vm._v("Homepage")]),
      _c(
        "div",
        [
          _c("FormDivider"),
          _c("FormLabel", {
            attrs: {
              label:
                "Anforderung: Verfügen Sie im Wertpapierbereich über eine eigene Homepage, hat diese den Hinweis zu beinhalten, dass Sie Wertpapierdienstleistungen im Namen und auf Rechnung der FinanzAdmin durchführen bzw. wenn Sie als Wertpapiervermittler für mehrere Rechtsträger tätig sind, sind diese alle namentlich anzuführen. Der Wortlaut hat wie folgt zu lauten:"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        [
          _c("FormLabel", {
            attrs: { label: _vm.label, config: { bold: true } }
          })
        ],
        1
      ),
      _c("FormLabel", {
        attrs: {
          label:
            "FinanzAdmin bietet die „nicht unabhängige” Anlageaberatung an. Die Homepage darf keine Inhalte, welche auf „unabhängige“ Anlageberatung hinweisen, enthalten."
        }
      }),
      _c("InputRadioBoxGroup", {
        attrs: {
          value: _vm.form.INPUT_HOMEPAGE,
          values: _vm.homepageValues,
          labelClass: "font-bold",
          disabled: this.disabledStatusOK
        },
        on: {
          input: function($event) {
            return _vm.fieldChanged($event, "INPUT_HOMEPAGE")
          }
        }
      }),
      _c("InputField", {
        attrs: {
          value: _vm.form.INPUT_HOMEPAGE_TEXT,
          label: "Link Homepage:",
          isComponentHalfSize: "",
          disabled: _vm.form.INPUT_HOMEPAGE != "hp_2" || this.disabledStatusOK
        },
        on: {
          change: function($event) {
            return _vm.fieldChanged($event, "INPUT_HOMEPAGE_TEXT")
          }
        }
      }),
      _c("InputField", {
        attrs: {
          value: _vm.form.INPUT_KEINE_HOMEPAGE_BUERO_TEXT,
          isComponentHalfSize: "",
          disabled: _vm.form.INPUT_HOMEPAGE != "hp_3" || this.disabledStatusOK
        },
        on: {
          change: function($event) {
            return _vm.fieldChanged($event, "INPUT_KEINE_HOMEPAGE_BUERO_TEXT")
          }
        }
      }),
      _c("p", [
        _vm._v(
          " angeführt werde und dort der genannte Wortlaut unter folgendem Link genannt wird: "
        )
      ]),
      _c("InputField", {
        attrs: {
          value: _vm.form.INPUT_KEINE_HOMEPAGE_TEXT,
          isComponentHalfSize: "",
          disabled: _vm.form.INPUT_HOMEPAGE != "hp_3" || this.disabledStatusOK
        },
        on: {
          change: function($event) {
            return _vm.fieldChanged($event, "INPUT_KEINE_HOMEPAGE_TEXT")
          }
        }
      }),
      _c("ChecklisteDocsUpload", {
        attrs: {
          paramId: this.form.PARAM_ID,
          topic: "homepage",
          form: this.form
        }
      }),
      _c("div", { staticClass: "box__title" }, [_vm._v("E-Mail Signatur")]),
      _c("FormLabel", {
        attrs: {
          label:
            "Anforderung: Die E-Mail Korrespondenz mit Ihren Kunden im Rahmen der Finanzdienstleistungen hat den oben angeführten Wortlaut ebenfalls\nzu beinhalten."
        }
      }),
      _c("FormLabel", {
        attrs: {
          label: "Bestätigung: Mit meiner Unterschrift bestätige ich, dass"
        }
      }),
      _c("InputRadioBoxGroup", {
        attrs: {
          value: _vm.form.INPUT_SIGNATUR_BEST_1,
          values: _vm.signaturValues,
          labelClass: "font-bold",
          disabled: this.disabledStatusOK
        },
        on: {
          input: function($event) {
            return _vm.fieldChanged($event, "INPUT_SIGNATUR_BEST_1")
          }
        }
      }),
      _c("ChecklisteDocsUpload", {
        attrs: {
          paramId: this.form.PARAM_ID,
          topic: "E-Signatur",
          form: this.form
        }
      }),
      _c("div", { staticClass: "box__title" }, [_vm._v("Visitenkarte")]),
      _c("FormLabel", {
        attrs: {
          label:
            "Anforderung: Um für den Kunden klar erkennbar zu machen, für wen Sie im Bereich der Finanzdienstleistungen gemäß WAG 2018 tätig werden, ist der oben angeführte Zusatz auf Ihrer Visitenkarte ebenfalls anzuführen (z.B. Rückseite)."
        }
      }),
      _c("InputRadioBoxGroup", {
        attrs: {
          value: _vm.form.INPUT_VISITENKARTE_BEST_1,
          values: _vm.visitenKartenValues,
          labelClass: "font-bold",
          disabled: this.disabledStatusOK
        },
        on: {
          input: function($event) {
            return _vm.fieldChanged($event, "INPUT_VISITENKARTE_BEST_1")
          }
        }
      }),
      _c("ChecklisteDocsUpload", {
        attrs: {
          paramId: this.form.PARAM_ID,
          topic: "Visitenkarten",
          form: this.form
        }
      }),
      _c("FormDivider"),
      _c("InputToggleSwitch", {
        attrs: {
          label:
            "Ich bestätige hiermit, die Angaben wahrheitsgetreu getätigt zu haben.",
          inLineLabel: true,
          config: { bold: true },
          isComponentHalfSize: "",
          disabled: this.disabledStatusOK,
          value: true
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }