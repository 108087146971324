import AUFTRAEGE_TYPES from './types';

export default {
  [AUFTRAEGE_TYPES.GETTERS.AUFTRAEGE](state) {
    return state.auftraege;
  },
  [AUFTRAEGE_TYPES.GETTERS.IST_BEARBEITER](state) {
    return state.istBearbeiter;
  },
  [AUFTRAEGE_TYPES.GETTERS.HAS_ESTIMATED_DONE](state) {
    return state.hasEstimatedDone;
  },
  [AUFTRAEGE_TYPES.GETTERS.DEFAULT_FILTERS](state) {
    return state.defaultFilters;
  },
  [AUFTRAEGE_TYPES.GETTERS.AENDERUNGS_LOG](state) {
    return state.aenderungsLog;
  },
  [AUFTRAEGE_TYPES.GETTERS.GET_EMAILS_SENT](state) {
    return state.emailsSent;
  },
  
}