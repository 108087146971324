var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "f241e7b9-92bc-4a39-8e1f-dd6f59c49ece",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        width: "878.64034",
        height: "552.21468",
        viewBox: "0 0 878.64034 552.21468"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M998.54278,726.10734H255.9092a24.9009,24.9009,0,0,1-24.9009-24.9009v0q403.8068-46.93008,792.43539,0v0A24.90091,24.90091,0,0,1,998.54278,726.10734Z",
          transform: "translate(-160.67983 -173.89266)",
          fill: "#ccc"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1023.44369,702.67117,231.0083,701.20641l91.84049-154.53222.43941-.73238V205.37035a31.47506,31.47506,0,0,1,31.47769-31.47769H895.29181a31.47506,31.47506,0,0,1,31.47769,31.47769v342.915Z",
          transform: "translate(-160.67983 -173.89266)",
          fill: "#e6e6e6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M354.04817,195.86407a10.26484,10.26484,0,0,0-10.25332,10.25332V516.6466a10.2653,10.2653,0,0,0,10.25332,10.25332H900.40381a10.26575,10.26575,0,0,0,10.25332-10.25332V206.11739a10.26529,10.26529,0,0,0-10.25332-10.25332Z",
          transform: "translate(-160.67983 -173.89266)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M357.63886,567.91321a4.40643,4.40643,0,0,0-3.99233,2.55761l-28.29891,61.51994a4.39408,4.39408,0,0,0,3.99161,6.231H924.45037a4.39378,4.39378,0,0,0,3.92939-6.35969l-30.76-61.51994a4.372,4.372,0,0,0-3.92939-2.42887Z",
          transform: "translate(-160.67983 -173.89266)",
          fill: "#ccc"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "464.34902",
          cy: "10.25332",
          r: "6.59142",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M566.53427,649.9398a4.401,4.401,0,0,0-4.24265,3.25137l-7.09851,26.36568a4.39386,4.39386,0,0,0,4.24265,5.5372H694.43332a4.39329,4.39329,0,0,0,4.15111-5.83187l-9.12615-26.36568a4.39658,4.39658,0,0,0-4.15254-2.9567Z",
          transform: "translate(-160.67983 -173.89266)",
          fill: "#ccc"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "766.09 366.922 766.09 372.782 162.169 372.782 162.623 372.049 162.623 366.922 766.09 366.922",
          fill: "#ccc"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M852.22715,554.23663a1.07171,1.07171,0,0,0-1.03315.79175l-1.7286,6.42045a1.07,1.07,0,0,0,1.03316,1.34839H883.3725a1.06983,1.06983,0,0,0,1.01085-1.42015l-2.22235-6.42044a1.07064,1.07064,0,0,0-1.01121-.72Z",
          transform: "translate(-160.67983 -173.89266)",
          fill: "#ccc"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M804.22715,554.23663a1.07171,1.07171,0,0,0-1.03315.79175l-1.7286,6.42045a1.07,1.07,0,0,0,1.03316,1.34839H835.3725a1.06983,1.06983,0,0,0,1.01085-1.42015l-2.22235-6.42044a1.07064,1.07064,0,0,0-1.01121-.72Z",
          transform: "translate(-160.67983 -173.89266)",
          fill: "#ccc"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M665.1612,595.03225h2.57647l-5.153-344.38824h-2.57648l.10279,6.8706H602.36432l.10278-6.8706h-2.57647l-5.153,344.38824h2.57648l.33411-22.3294h67.17883Zm-3.71375-248.2H601.0279l.62963-42.08234h59.16028Zm.03858,2.57648.62963,42.08233h-61.756l.62964-42.08233Zm.66821,44.65881.62964,42.08234H599.69147l.62964-42.08234Zm.66821,44.65881.62964,42.08234H599.02326l.62964-42.08234Zm.66821,44.65882.62964,42.08239H598.355l.62964-42.08239ZM660.1496,260.09109l.62964,42.08234H601.69611l.62964-42.08234ZM597.68683,570.12637l.62964-42.08234h65.84241l.62964,42.08234Z",
          transform: "translate(-160.67983 -173.89266)",
          fill: "#3f3d56"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "457.7944",
          cy: "153.35761",
          r: "42.01233",
          fill: "#2f2e41"
        }
      }),
      _c("rect", {
        attrs: {
          x: "632.56176",
          y: "357.23822",
          width: "12.76324",
          height: "22.86749",
          transform: "translate(-216.13362 -54.17899) rotate(-10.26106)",
          fill: "#2f2e41"
        }
      }),
      _c("rect", {
        attrs: {
          x: "607.4436",
          y: "361.78527",
          width: "12.76324",
          height: "22.86749",
          transform: "translate(-217.34533 -58.58065) rotate(-10.26106)",
          fill: "#2f2e41"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "613.67177",
          cy: "387.29848",
          rx: "10.63602",
          ry: "3.98853",
          transform: "translate(-265.60562 314.45381) rotate(-40.26106)",
          fill: "#2f2e41"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "638.2667",
          cy: "382.84606",
          rx: "10.63605",
          ry: "3.98853",
          transform: "translate(-256.9018 329.29406) rotate(-40.26106)",
          fill: "#2f2e41"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "454.85318",
          cy: "143.08115",
          r: "14.35864",
          fill: _vm.secondColor
        }
      }),
      _c("circle", {
        attrs: {
          cx: "448.95181",
          cy: "137.93436",
          r: "4.78622",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M651.61608,281.54535c.63177-15.55359-12.77313-28.7276-29.9408-29.42493s-31.59692,11.346-32.22873,26.8996,11.30191,19.08746,28.46955,19.78485S650.9843,297.09893,651.61608,281.54535Z",
          transform: "translate(-160.67983 -173.89266)",
          fill: _vm.color
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "579.68164",
          cy: "319.59634",
          rx: "6.59448",
          ry: "21.00616",
          transform: "translate(-22.02934 639.32717) rotate(-77.0899)",
          fill: "#2f2e41"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "662.15028",
          cy: "333.31081",
          rx: "6.59448",
          ry: "21.00616",
          transform: "translate(28.6462 730.3615) rotate(-77.0899)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M630.01293,343.80682a9.57243,9.57243,0,1,1-18.8353,3.42883l-.00335-.01849c-.94175-5.20215,3.08038-7.043,8.28256-7.98474S629.07122,338.60467,630.01293,343.80682Z",
          transform: "translate(-160.67983 -173.89266)",
          fill: _vm.secondColor
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "469.86576",
          cy: "467.52635",
          rx: "21.53369",
          ry: "6.76007",
          transform: "translate(-295.28292 565.61186) rotate(-69.08217)",
          fill: "#2f2e41"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "430.05859",
          cy: "500.04835",
          r: "43.06735",
          transform: "translate(-293.10046 670.56224) rotate(-80.78252)",
          fill: "#2f2e41"
        }
      }),
      _c("rect", {
        attrs: {
          x: "249.75315",
          y: "359.95537",
          width: "13.08374",
          height: "23.44171",
          fill: "#2f2e41"
        }
      }),
      _c("rect", {
        attrs: {
          x: "275.92063",
          y: "359.95537",
          width: "13.08374",
          height: "23.44171",
          fill: "#2f2e41"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "260.65629",
          cy: "383.66967",
          rx: "10.90314",
          ry: "4.08868",
          fill: "#2f2e41"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "286.82377",
          cy: "383.12453",
          rx: "10.90314",
          ry: "4.08868",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M388.28186,459.997c-3.47748-15.57379,7.63867-31.31046,24.82861-35.14884s33.94422,5.67508,37.42169,21.24888-7.91491,21.31765-25.10485,25.156S391.75934,475.57078,388.28186,459.997Z",
          transform: "translate(-160.67983 -173.89266)",
          fill: _vm.color
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "381.55043",
          cy: "480.06495",
          rx: "6.76007",
          ry: "21.53368",
          transform: "translate(-376.387 445.19187) rotate(-64.62574)",
          fill: "#2f2e41"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "82.9008",
          cy: "369.76318",
          r: "43.06733",
          fill: "#2f2e41"
        }
      }),
      _c("rect", {
        attrs: {
          x: "63.27519",
          y: "403.56287",
          width: "13.08374",
          height: "23.44171",
          fill: "#2f2e41"
        }
      }),
      _c("rect", {
        attrs: {
          x: "89.44267",
          y: "403.56287",
          width: "13.08374",
          height: "23.44171",
          fill: "#2f2e41"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "74.17827",
          cy: "427.27716",
          rx: "10.90314",
          ry: "4.08868",
          fill: "#2f2e41"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "100.34581",
          cy: "426.732",
          rx: "10.90314",
          ry: "4.08868",
          fill: "#2f2e41"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "83.99113",
          cy: "358.86008",
          r: "14.71922",
          fill: _vm.secondColor
        }
      }),
      _c("circle", {
        attrs: {
          cx: "83.99113",
          cy: "358.86008",
          r: "4.90642",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M201.80384,503.60449c-3.47748-15.57379,7.63867-31.31043,24.82861-35.14881s33.94421,5.67511,37.42169,21.2489-7.91492,21.31769-25.10486,25.156S205.28137,519.17834,201.80384,503.60449Z",
          transform: "translate(-160.67983 -173.89266)",
          fill: "#e6e6e6"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "268.66855",
          cy: "387.38342",
          rx: "21.53369",
          ry: "6.76007",
          transform: "translate(-349.78596 326.14528) rotate(-69.08217)",
          fill: "#2f2e41"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "228.86138",
          cy: "419.90542",
          r: "43.06735",
          transform: "translate(-382.96135 404.65755) rotate(-80.78252)",
          fill: "#2f2e41"
        }
      }),
      _c("rect", {
        attrs: {
          x: "48.55595",
          y: "279.81244",
          width: "13.08374",
          height: "23.44171",
          fill: "#2f2e41"
        }
      }),
      _c("rect", {
        attrs: {
          x: "74.72343",
          y: "279.81244",
          width: "13.08374",
          height: "23.44171",
          fill: "#2f2e41"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "59.45909",
          cy: "303.52674",
          rx: "10.90314",
          ry: "4.08868",
          fill: "#2f2e41"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "85.62657",
          cy: "302.9816",
          rx: "10.90314",
          ry: "4.08868",
          fill: "#2f2e41"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "69.27189",
          cy: "235.10965",
          r: "14.71922",
          fill: _vm.secondColor
        }
      }),
      _c("circle", {
        attrs: {
          cx: "69.27189",
          cy: "235.10965",
          r: "4.90642",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M187.08466,379.854c-3.47748-15.57379,7.63867-31.31046,24.82861-35.14884s33.94422,5.67508,37.42169,21.24888-7.91491,21.31765-25.10485,25.156S190.56214,395.42785,187.08466,379.854Z",
          transform: "translate(-160.67983 -173.89266)",
          fill: _vm.color
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "180.35323",
          cy: "399.92202",
          rx: "6.76007",
          ry: "21.53368",
          transform: "translate(-418.95394 217.60525) rotate(-64.62574)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M207.70685,435.44238c0,4.21515,10.85327,12.53857,22.89655,12.53857s23.33514-11.867,23.33514-16.08209-11.29193.81775-23.33514.81775S207.70685,431.22723,207.70685,435.44238Z",
          transform: "translate(-160.67983 -173.89266)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M271.20359,255.46135a3.00328,3.00328,0,0,0-3,3v124a3.00328,3.00328,0,0,0,3,3h287a3.00328,3.00328,0,0,0,3-3v-124a3.00328,3.00328,0,0,0-3-3Z",
          transform: "translate(-160.67983 -173.89266)",
          fill: _vm.color
        }
      }),
      _c("circle", {
        attrs: {
          cx: "188.01816",
          cy: "146.56869",
          r: "29.1211",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          id: "b71acdfd-6a55-428e-917a-53f192cb0203",
          "data-name": "Path 40",
          d:
            "M414.42343,316.58683a3.40529,3.40529,0,0,0,0,6.80966h91.94566a3.40529,3.40529,0,1,0,.11181-6.80966q-.05591-.00095-.11181,0Z",
          transform: "translate(-160.67983 -173.89266)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          id: "ad4fbcfa-41b0-45f9-a593-23b6dc3fe165",
          "data-name": "Path 40",
          d:
            "M414.42343,296.85963a3.40529,3.40529,0,0,0,0,6.80966H460.339a3.40529,3.40529,0,1,0,.1118-6.80966q-.0559-.00095-.1118,0Z",
          transform: "translate(-160.67983 -173.89266)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          id: "bce2a7cd-a325-424d-9b3a-f63d1dad5aba",
          "data-name": "Path 40",
          d:
            "M414.42343,337.25342a3.40529,3.40529,0,0,0,0,6.80966h91.94566a3.40529,3.40529,0,1,0,.11181-6.80966q-.05591-.001-.11181,0Z",
          transform: "translate(-160.67983 -173.89266)",
          fill: _vm.secondColor
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "853.28249",
          cy: "546.50272",
          rx: "6.76007",
          ry: "21.53369",
          transform: "translate(-299.56006 166.77183) rotate(-20.91783)",
          fill: "#2f2e41"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "884.08966",
          cy: "588.02473",
          r: "43.06733",
          transform: "translate(-243.45509 -24.68433) rotate(-9.21747)",
          fill: "#2f2e41"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "743.035 447.932 729.952 447.932 732.512 476.005 743.058 473.14 743.035 447.932",
          fill: "#2f2e41"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "714.512 448.005 701.428 448.005 703.988 476.078 714.058 473.14 714.512 448.005",
          fill: "#2f2e41"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "863.99793",
          cy: "647.21172",
          rx: "10.90314",
          ry: "4.08868",
          transform: "translate(-205.5736 -109.16812) rotate(-4.18164)",
          fill: "#2f2e41"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "722.31949",
          cy: "403.22896",
          r: "14.71922",
          fill: _vm.secondColor
        }
      }),
      _c("circle", {
        attrs: {
          cx: "722.31949",
          cy: "403.22896",
          r: "4.90642",
          fill: "#3f3d56"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "929.59781",
          cy: "558.04133",
          rx: "21.53368",
          ry: "6.76007",
          transform: "translate(-232.25121 800.3487) rotate(-53.5499)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M863.07681,610.23132c0-3.474,8.945-10.334,18.8708-10.334s19.23228,9.7805,19.23228,13.25448-9.30655-.674-19.23228-.674S863.07681,613.70534,863.07681,610.23132Z",
          transform: "translate(-160.67983 -173.89266)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1004.85653,552.43438,769.111,519.7533a7.0085,7.0085,0,0,1-5.97249-7.8949l26.50189-191.17178a7.00819,7.00819,0,0,1,7.8949-5.97249l235.74551,32.68109a7.00818,7.00818,0,0,1,5.97249,7.8949L1012.75143,546.4619A7.00848,7.00848,0,0,1,1004.85653,552.43438Z",
          transform: "translate(-160.67983 -173.89266)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M920.40214,492.77223,800.04767,476.08764a2.47686,2.47686,0,0,1-.44482-.098l70.59053-90.70626a4.02366,4.02366,0,0,1,6.93772.96176l29.02932,71.46232,1.39127,3.41979Z",
          transform: "translate(-160.67983 -173.89266)",
          fill: _vm.secondColor
        }
      }),
      _c("polygon", {
        staticStyle: { isolation: "isolate" },
        attrs: {
          points:
            "759.722 318.879 718.63 313.183 742.864 286.679 744.609 284.77 745.481 283.815 746.872 287.235 759.722 318.879",
          opacity: "0.2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M987.41464,502.06208,883.3179,487.63129l24.23379-26.504,1.74426-1.90948,31.57921-34.54012a5.36964,5.36964,0,0,1,7.96051.62248,4.79638,4.79638,0,0,1,.3131.52448Z",
          transform: "translate(-160.67983 -173.89266)",
          fill: _vm.secondColor
        }
      }),
      _c("circle", {
        attrs: {
          cx: "761.18602",
          cy: "224.18353",
          r: "18",
          fill: _vm.secondColor
        }
      }),
      _c("rect", {
        attrs: {
          x: "799.9414",
          y: "330.81168",
          width: "22",
          height: "22",
          transform:
            "matrix(0.99053, 0.13732, -0.13732, 0.99053, -106.06204, -282.00962)",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M925.86638,547.97334c3.47748-15.57379-7.63867-31.31046-24.82861-35.14884s-33.94421,5.67508-37.42169,21.24887,7.91492,21.31766,25.10486,25.156S922.38891,563.54716,925.86638,547.97334Z",
          transform: "translate(-160.67983 -173.89266)",
          fill: "#f2f2f2"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "892.69043",
          cy: "647.14249",
          rx: "10.90314",
          ry: "4.08868",
          transform: "translate(-205.49217 -107.07609) rotate(-4.18164)",
          fill: "#2f2e41"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }