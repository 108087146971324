<template>
  <div class="color-picker__container" @mouseup="dragMainCanvasEnd" :class="{ 'disabled': disabled, }">
    <label v-if="label" v-html="sanitize(label)"></label>
    <BaseDropdownMenu class="color-picker--dropdown" @onShow="toggleShowHide()" @onHide="showPicker = false" :disabled="disabled" containerClass="color-picker--dropdown-container">
      <template v-slot:hook-target>
        <div class="color-picker__sample" :style="sampleStyle"></div>
      </template>
      <div class="color-picker__body" data-dropdown-not-close v-if="showPicker">
        <div>
          <canvas ref="saturationCanvas" width="300px" height="300px" id="saturation_canvas" @click="updateValueAndSaturation($event)"></canvas>
          <div class="saturation-handler"
            :style="{
              'left': `calc(${saturationHorizontalPosition}px - 6px)`,
              'top': `calc(${saturationVerticalPosition}px - 6px)`,
              'background-color': `${saturationColorHandler}`,
            }">
            &nbsp;
          </div>
        </div>
        <div>
          <canvas ref="hueCanvas" width="32px" height="300px" id="hue_canvas" @click="updateHue($event)"></canvas>
          <div class="hue-handler" :style="{ 'top': `calc(${selectorHorizontalPosition}px - 300px)`}">
            &nbsp;
          </div>
        </div>
      </div>
      <div class="d-flex mt-2">
        <div v-for="(color) in shortcutColors" :key="color">
          <div class="color-item" :style="{'background-color': color}" @click="selectColor(color)"></div>
        </div>
      </div>
      <BaseButton 
        v-if="showAutomaticColorButton"
        @click="setAutomatic"
        class="mt-2"
        isSecondary>
        Automatisch
      </BaseButton>
    </BaseDropdownMenu>
  </div>
</template>

<script>
import BaseDropdownMenu from '@/components/core/BaseDropdownMenu.vue';
import BrowserSupport from '@/browser-support';
import { hsvToRGB, rgbToHex, rgbToHSV, hexToRGB, getMostContrastingColorWhiteBlackByContrast } from '@/helpers/colors-helper.js'
import { sanitize } from '@/helpers/string-helper.js';
import BaseButton from '@/components/core/BaseButton.vue';
import { range } from '@/helpers/linear-interpolation.js';

const MOUSE_EVENT_OPTIONS = BrowserSupport.supportsPassive ? { passive: true, capture: false, } : false;

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showAutomaticColorButton: {
      type: Boolean,
      default: false,
    },
    isDefaultColorAsTextColor: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      hsvColor: {
        saturation: 100,
        hue: 240,
        value: 100,
      },
      showPicker: false,
      colors: [ // color spectrum to generate the Hue bar
        '#f00',
        '#ff0',
        '#0f0',
        '#0ff',
        '#00f',
        '#f0f',
        '#f00',
      ]
    }
  },
  computed: {
    sampleStyle() {
      let defaultColor = '#ffffff';
      if (this.isDefaultColorAsTextColor) {
        defaultColor = document.documentElement.style.getPropertyValue('--color-text');
      }

      return {
        'background-color': this.value || defaultColor
      }
    },
    shortcutColors() {
      const colorWhite = '#ffffff';
      const colorGray = '#7f7f7f';
      const colorBlack = '#000000';
      const colorPrimary = document.documentElement.style.getPropertyValue('--color-primary')
      const colorSecondary =document.documentElement.style.getPropertyValue('--color-secondary')
      const colorSuccess = document.documentElement.style.getPropertyValue('--color-success')
      const colorDanger = document.documentElement.style.getPropertyValue('--color-danger')
      const colorWarning = document.documentElement.style.getPropertyValue('--color-warning')
      const colorInfo = document.documentElement.style.getPropertyValue('--color-info')

      const colors = new Set([colorWhite, colorGray, colorBlack, colorPrimary, colorSecondary, colorSuccess, colorDanger, colorWarning, colorInfo]);

      return [...colors];
    },
    selectorHorizontalPosition() {
      return range(0, 360, 0, 300, this.hsvColor.hue)
    },
    saturationHorizontalPosition() {
      return range(0, 100, 0, 300, this.hsvColor.saturation)
    },
    saturationVerticalPosition() {
      return range(0, 100, 0, 300, this.hsvColor.value) * (-1);
    },
    saturationColorHandler() {
      return getMostContrastingColorWhiteBlackByContrast(this.hsvColorToHex);
    },
    hueColor() {
      return rgbToHex(hsvToRGB({ hue: this.hsvColor.hue, saturation: 100, value: 100 })); //convertColor(CM_HSV, CM_HEX, );
    },
    hsvColorToHex() {
      return rgbToHex(hsvToRGB(this.hsvColor));
    }
  },
  methods: {
    sanitize(htmlString) {
      return sanitize(htmlString);
    },
    setAutomatic() {
      this.selectColor(null);
    },
    selectColor(hex) {
      this.$emit('input', hex);
      this.$emit('change', hex);
    },
    populateHsvColorFromValue() {
      this.$set(this, 'hsvColor', rgbToHSV(hexToRGB(this.value)));
    },
    updateValueAndSaturation(event) {
      const saturationCanvas = document.getElementById('saturation_canvas');
      const boundingClientRect = saturationCanvas.getBoundingClientRect();

      const x = event.clientX - Math.round(boundingClientRect.left);
      const y = event.clientY - Math.round(boundingClientRect.top);

      this.hsvColor.saturation = Math.floor(range(0, 300, 0, 100, x));
      this.hsvColor.value = Math.floor(range(0, 300, 100, 0, y));

      this.selectColor(this.hsvColorToHex);
    },
    updateHue(event) {
      const hueCanvas = document.getElementById('hue_canvas');
      const boundingClientRect = hueCanvas.getBoundingClientRect();

      const y = event.clientY - Math.round(boundingClientRect.top);

      this.hsvColor.hue = Math.floor(range(0, 300, 0, 360, y));

      this.createSaturationCanvas()
    },
    createSaturationCanvas() {
      const saturationCanvas = document.getElementById('saturation_canvas');
      const mainCanvasCtx = saturationCanvas.getContext('2d');

      const gradientH = mainCanvasCtx.createLinearGradient(0, 0, mainCanvasCtx.canvas.width, 0);
      gradientH.addColorStop(0, '#fff');
      gradientH.addColorStop(1, this.hueColor);
      mainCanvasCtx.fillStyle = gradientH;
      mainCanvasCtx.fillRect(0, 0, mainCanvasCtx.canvas.width, mainCanvasCtx.canvas.height);

      const gradientV = mainCanvasCtx.createLinearGradient(0, 0, 0, 300);
      gradientV.addColorStop(0, 'rgba(0,0,0,0)');
      gradientV.addColorStop(1, '#000');
      mainCanvasCtx.fillStyle = gradientV;
      mainCanvasCtx.fillRect(0, 0, mainCanvasCtx.canvas.width, mainCanvasCtx.canvas.height);
    },
    setHueColors() {
      const hueCanvas = document.getElementById('hue_canvas');
      const hueCtx =  hueCanvas.getContext('2d');
      const colorGradient = hueCtx.createLinearGradient(0, 0, 0, 300);
      for (let i = 0; i < this.colors.length; i++) {
        colorGradient.addColorStop(i / (this.colors.length - 1), this.colors[i]);
      }
      hueCtx.fillStyle = colorGradient;
      hueCtx.fillRect(0, 0, 300, 300);
    },
    dragMainCanvasStart() {
      if (this.$refs.saturationCanvas) {
        this.$refs.saturationCanvas.addEventListener('touchmove', this.dragMainCanvasMove)
        this.$refs.saturationCanvas.addEventListener('mousemove', this.dragMainCanvasMove)
      }
      document.addEventListener('mouseup', this.dragMainCanvasEnd, MOUSE_EVENT_OPTIONS);
      document.addEventListener('touchend', this.dragMainCanvasEnd, MOUSE_EVENT_OPTIONS);
    },
    dragMainCanvasEnd() {
      if (this.$refs.saturationCanvas) {
        this.$refs.saturationCanvas.removeEventListener('touchmove', this.dragMainCanvasMove)
        this.$refs.saturationCanvas.removeEventListener('mousemove', this.dragMainCanvasMove)
      }
    },
    dragMainCanvasMove(event) {
      this.updateValueAndSaturation(event)
    },
    draghueCanvasStart() {
      if (this.$refs.saturationCanvas) {
        this.$refs.hueCanvas.addEventListener('touchmove', this.draghueCanvasMove)
        this.$refs.hueCanvas.addEventListener('mousemove', this.draghueCanvasMove)
      }
      document.addEventListener('mouseup', this.draghueCanvasEnd, MOUSE_EVENT_OPTIONS);
      document.addEventListener('touchend', this.draghueCanvasEnd, MOUSE_EVENT_OPTIONS);
    },
    draghueCanvasEnd() {
      if (this.$refs.saturationCanvas) {
        this.$refs.hueCanvas.removeEventListener('touchmove', this.draghueCanvasMove)
        this.$refs.hueCanvas.removeEventListener('mousemove', this.draghueCanvasMove)
      }
    },
    draghueCanvasMove(event) {
      this.updateHue(event)
    },
    addListeners() {
      if (this.$refs.saturationCanvas) {
        this.$refs.saturationCanvas.addEventListener('mousedown', this.dragMainCanvasStart, MOUSE_EVENT_OPTIONS);
        this.$refs.saturationCanvas.addEventListener('touchstart', this.dragMainCanvasStart, MOUSE_EVENT_OPTIONS);
      }

      if (this.$refs.hueCanvas) {
        this.$refs.hueCanvas.addEventListener('mousedown', this.draghueCanvasStart, MOUSE_EVENT_OPTIONS);
        this.$refs.hueCanvas.addEventListener('touchstart', this.draghueCanvasStart, MOUSE_EVENT_OPTIONS);
      }
    },
    toggleShowHide() {
      this.showPicker = true
      setTimeout(() => {
        this.populateHsvColorFromValue();
        this.createSaturationCanvas()
        this.setHueColors()
        this.addListeners()
      }, 0)
    }
  },
  mounted() {
    
  },
  components: {
    BaseDropdownMenu,
    BaseButton,
  }
}
</script>

<style scoped>
  .color-picker--dropdown {
    width: 32px;
    height: 32px;
  }

  .color-picker__sample {
    width: 32px;
    height: 32px;
    border: 1px solid;
  }

  .disabled .color-picker__sample {
    opacity: 0.6;
  }

  .color-picker__body {
    display: flex;
    cursor: crosshair;
    background-color: var(--color-box);
    padding: 0;
    gap: 8px;
  }

  .color-picker__container {
    position: relative;
  }

  .hue-handler {
    position: relative;
    background-color: black;
    width: 32px;
    height: 4px;
    pointer-events: none;
  }

  .saturation-handler {
    position: relative; 
    width: 12px; 
    height: 12px; 
    border-radius: 12px;
    pointer-events: none;
  }

</style>

<!-- GLOBAL STYLE -->
<style>
.dropdown-menu__container.color-picker--dropdown-container {
  padding: 4px;
}
</style>
