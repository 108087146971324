<template>
  <div>
    <InputField
      ref="inputField"
      v-model="internalValue"
      :label="label"
      @contentSelected="handleSelection"
      @input="handleInputData"
      @reset="clearInput"
    />
  </div>
</template>

<script>
import dayjs from "dayjs";
import InputField from "@/components/core/forms/InputField.vue";

const DEFAULT_PLACEHOLDER = "tt.mm.jjjj";
const DAY_WILDCARD = "tt"
const MONTH_WILDCARD = "mm"
const YEAR_WILDCARD = "jjjj"

export default {
  components: {
    InputField,
  },
  props: {
    value: {
      type: String,
      default: DEFAULT_PLACEHOLDER
    },
    label: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      placeholder: DEFAULT_PLACEHOLDER,
      positionSelected: null,
      internalValue: null,
      date: {
        day: null,
        month: null,
        year: null,
      },
    }
  },
  methods: {
    handleSelection(event) {
      // dd.mm.yyyy
      if(event) {
        const selectionStart = this.$refs?.inputField?.$refs.inputField.selectionStart;
        if(selectionStart <= 3 || selectionStart == 10) {
          this.selectDayPosition();
        } else if(selectionStart >= 3 && selectionStart <= 6) {
          this.selectMonthPosition();
        } else {
          this.selectYearPosition();
        }
      }
    },
    getSplitedInternalValue() {
      const splitedValue = this.internalValue.substring(0,10).split('.');
      const valueMapped = {
        day: splitedValue[0],
        month: splitedValue[1],
        year: splitedValue[2]
      }
      return valueMapped;
    },
    selectDayPosition() {
      const valueMapped = this.getSplitedInternalValue();
      if(valueMapped.day !== DAY_WILDCARD && valueMapped.day.length === 1) {
        this.$refs?.inputField?.$refs.inputField.setSelectionRange(0, 1);
      } else {
        this.$refs?.inputField?.$refs.inputField.setSelectionRange(0, 2);
      }
      this.positionSelected = 'day';
    },
    selectMonthPosition() {
      const valueMapped = this.getSplitedInternalValue();
      if(valueMapped.month !== MONTH_WILDCARD && valueMapped.month.length === 1) {
        this.$refs?.inputField?.$refs.inputField.setSelectionRange(3, 4);
      } else if(valueMapped?.day?.length === 1) {
        this.$refs?.inputField?.$refs.inputField.setSelectionRange(2, 4);
      } else {
        this.$refs?.inputField?.$refs.inputField.setSelectionRange(3, 5);
      }
      this.positionSelected = 'month';
    },
    selectYearPosition() {
      const valueMapped = this.getSplitedInternalValue();
      if(valueMapped.year !== YEAR_WILDCARD && valueMapped.year.length < 4) {
        this.$refs?.inputField?.$refs.inputField.setSelectionRange(6, 6 + valueMapped.year.length);
      } else if(valueMapped?.month?.length === 1) {
        this.$refs?.inputField?.$refs.inputField.setSelectionRange(5, 9);
      } else {
        this.$refs?.inputField?.$refs.inputField.setSelectionRange(6, 10);
      }
      this.positionSelected = 'year';
    },
    handleInputData(event) {
      if (!event?.length) {
        this.internalValue = this.placeholder;
        event = this.placeholder;
      }

      const february = '02';
      const dayPattern = /\b[0-3]{1}[0-9]{1}\b/
      const monthPattern = /\b[0]{1}[0-9]{1}|[1-2]{1}[0-9]{1}|[3]{1}[0-1]{1}\b/
      const yearPattern = /\b\d{4}\b/;
      const numberPattern = /\b\d+\b/

      if(event) {
        const valueMapped = this.getSplitedInternalValue();
        switch (this.positionSelected) {
          case 'day':
            if(valueMapped.day && valueMapped.day !== DAY_WILDCARD) {
              if(valueMapped.day.length >= 3) {
                const firstTwoChars = valueMapped.day.slice(0,2);
                valueMapped.day = firstTwoChars.padStart(2,0);
              }
              const allowedDigits = ['0', '1', '2', '3']
              if(valueMapped.day.length == 1 && !allowedDigits.includes(valueMapped.day)) {
                valueMapped.day = DAY_WILDCARD;
                this.selectMonthPosition();
                break;
              }
              if(valueMapped.day.length == 2) {
                if(valueMapped.day == '00') {
                  valueMapped.day = DAY_WILDCARD
                } else if(valueMapped.day.startsWith('3') && valueMapped.day.slice(1) > '1') {
                  valueMapped.day = (valueMapped.day.slice(0,1) + '1').padStart(2,0);
                }
              }
            } else {
              valueMapped.day = DAY_WILDCARD;
            }
            break;
          case 'month':
            if(valueMapped.month && valueMapped.month !== MONTH_WILDCARD) {
              if(valueMapped.month.length >= 3) {
                valueMapped.month = valueMapped.month.slice(0,2).padStart(2,0);
              }
              if(!valueMapped.month.startsWith('0') && !valueMapped.month.startsWith('1')) {
                valueMapped.month = MONTH_WILDCARD;
                this.selectYearPosition();
                break;
              }
              if(valueMapped.month.length == 2 && valueMapped.month.startsWith('1') && valueMapped.month.slice(1) > '2' ) {
                valueMapped.month = (valueMapped.month.slice(0,1) + '2').padStart(2,0);
              }

              const monthsWith30Days = ['04', '06', '09', '11'];
              if(valueMapped.day && valueMapped.day !== DAY_WILDCARD && valueMapped.month){
                if(valueMapped.day === '31' && monthsWith30Days.includes(valueMapped.month)) {
                  valueMapped.day = '30';
                }
                if(valueMapped.day > '29' && valueMapped.month === february) {
                  valueMapped.day = '29';
                }
              }
            } else {
              valueMapped.month = MONTH_WILDCARD;
            }
            break;
          case 'year':
            const minYear = '1900';
            const maxYear = '2100';
            if(valueMapped.year && valueMapped.year.match(yearPattern)){
              const isLeapYear = dayjs(valueMapped).isLeapYear();
              if(valueMapped.day.match(dayPattern) && valueMapped.day > '28' && valueMapped.month === february && !isLeapYear){
                valueMapped.day = '28';
              }
              if(valueMapped.year < minYear) {
                valueMapped.year = minYear;
              }
              if(valueMapped.year > maxYear) {
                valueMapped.year = maxYear;
              }
            }
            if(valueMapped.year && valueMapped.year.length > 4 ) {
              valueMapped.year = valueMapped.year.slice(0,4);
            }
            if(!valueMapped.year || !valueMapped?.year?.match(numberPattern)) {
              valueMapped.year = YEAR_WILDCARD;
            }
            break;
          default:
            break;
        }
        // New string attribution here is important to force the internal state update of InputField component
        this.internalValue = new String(Object.values(valueMapped).join('.'));

        if(this.positionSelected === 'day' && valueMapped.day.match(dayPattern)) {
          this.selectMonthPosition();
        }
        if(this.positionSelected === 'month' && valueMapped.month.match(monthPattern)) {
          this.$nextTick(() => {this.selectYearPosition()});
        }
      }
      this.$emit('input', this.internalValue);
    },
    clearInput() {
      this.internalValue = this.placeholder
    }
  },
  mounted() {
    if(this.value) {
      this.internalValue = this.value;
    } else {
      this.internalValue = this.placeholder;
    }
    this.$emit('input', this.internalValue);
  }
}
</script>
