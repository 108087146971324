import ANLAGEZIELE_TYPES from './types';
import Vue from 'vue';

export default {
  [ANLAGEZIELE_TYPES.MUTATIONS.GET_ANLAGEZIELE_SUCCESS](state, payload) {
      Vue.set(state, 'comboboxValues', Object.assign({}, payload?.comboboxValues));
  },
  
  [ANLAGEZIELE_TYPES.MUTATIONS.GET_ANLAGEZIELE_PLANEN_SUCCESS](state, payload) {
    Vue.set(state, 'zeilen', Object.assign({}, payload?.zeilen));
    Vue.set(state, 'positionsAnlageziele', Object.assign([], payload?.positionsAnlageziele));
    Vue.set(state, 'schiene', payload?.schiene || '');
  },

  [ANLAGEZIELE_TYPES.MUTATIONS.UPDATE_ZEILE](state, payload) {
    if (payload.suffix && payload.param) {
      const zeile = Object.assign({}, state.zeilen[payload.suffix]);
      const values = Object.assign({}, zeile.values);
      values[payload.param] = payload.value;
      zeile.values = values;
      state.zeilen[payload.suffix] = zeile;
    }
  },

  [ANLAGEZIELE_TYPES.MUTATIONS.SET_POSITIONS_TEMP](state) {
    state.positionsTemp = state.positionsAnlageziele?.slice();
    state.positionsAnlageziele = null;
  },
  
  [ANLAGEZIELE_TYPES.MUTATIONS.UPDATE_POSITION](state, payload) {
    if (payload.position) {
      const positions = state.positionsTemp?.filter(pos => !(pos === payload.position))
        .concat([payload.position]);
      state.positionsAnlageziele = positions;
      state.positionsTemp = null;
    }
  },

}
