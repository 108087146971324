<template>
  <div class="no-data__container" :class="{ 'box__container': useBoxContainer }">
    <div v-if="title" class="box__title">{{ title }}</div>
    <div class="no-data__wrap">
      <div v-if="!noIcon" class="no-data__icon">
        <div class="no-data__icon--wrap">
          <UndrawEmpty />
        </div>
      </div>
      <div class="no-data__content">
        <slot name="content">{{ content }}</slot>
      </div>
    </div>
  </div>
</template>

<script>

import UndrawEmpty from '@/components/icons/undraw/UndrawEmpty.vue';

export const DEFAULT_CONTENT = 'Keine Daten vorhanden';

export default {
  props: {
    title: {
      type: String,
    },
    content: {
      type: String,
      default: DEFAULT_CONTENT,
    },
    noIcon: {
      type: Boolean,
      default: false,
    },
    useBoxContainer: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    UndrawEmpty,
  },
}
</script>

<style lang="scss" scoped>
.no-data__container {

  .no-data__wrap {
    margin: 16px 0;
    text-align: center;
  }

  > * {
    margin-bottom: 16px;
  }

  .no-data__icon {
    .no-data__icon--wrap {
      margin: 0 auto;
      max-width: 250px;
    }
  }
}
</style>
