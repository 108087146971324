import COURTAGETABELLE_INVESTMENT_TYPES from './types';

export default {
  [COURTAGETABELLE_INVESTMENT_TYPES.MUTATIONS.FILTER_FIELDS_DEFINITIONS_SUCCESS](state, payload) {
    state.filterFieldsDefinition = payload;
  },
  [COURTAGETABELLE_INVESTMENT_TYPES.MUTATIONS.COURTAGETABELLE_INVESTMENT_LIST_SUCCESS](state, payload) {
    state.courtagetabelleInvestmentList = payload;
  },
  [COURTAGETABELLE_INVESTMENT_TYPES.MUTATIONS.GET_COURTAGETABELLE_INVESTMENT_FONDSPLATTFORMEN_SUCCESS](state, payload) {
    state.courtagetabelleInvestmentFondsplattformen = payload;
  },
  [COURTAGETABELLE_INVESTMENT_TYPES.MUTATIONS.CLEAR_COURTAGETABELLE_INVESTMENT_LIST](state, payload) {
    state.courtagetabelleInvestmentList.headers = [];
    state.courtagetabelleInvestmentList.fondsOhneCourtageText = null;
    state.courtagetabelleInvestmentList.gebuehrFondsOhneCourtageInfo = null;
    state.courtagetabelleInvestmentList.rows = [];
    state.courtagetabelleInvestmentList.count = 0;
  },
  
}
