/**
 * This mixing is intended to be imported in the form page.
 * This is the only mixin required to be imported in order to achieve the validation in your form
 */

function getDefaultValidationFields() {
  return {
    valid: true, invalid: false, dirty: false, touched: false
  }
}

function createValidatorConfig(composedKey, message) {
  const dummyRule = {
    isValid() { return true },
    getMessage() { return message }
  }
  const splitComposedKey = composedKey.split('.')
  const validatorConfig = splitComposedKey.reverse().reduce((prev, curr) => {
    return { [curr]: prev }
  }, dummyRule)

  return validatorConfig
}

/**
 * This function defines the validation object you can access to
 *  check if a field is invalid, or get the errors messages for a field.
 * 
 * For example: 
 *  this.validation.getErrors('form.myField')
 *  this.validation.isInvalid('form.myField')
 *  this.validation.isInvalid('form')
 * 
 * @param {*} $set 
 * @param {*} __file 
 */
function getValidation($set, __file) {
  return {
    $set,
    __file,
    configurationDone: false,
    ...getDefaultValidationFields(),
    checkConfiguration(obj) {
      if (obj === undefined) {
        throw new Error(`Error on ${__file}. Please, check the validators configuration`)
      }
    },
    getValidator(path, parent = false) {
      this.checkConfiguration(path)

      const splitComposedKey = path.split('.')
      if (parent) {
        splitComposedKey.pop();
      }
      return splitComposedKey.reduce((prev, curr) => prev && prev[curr], this) || {};
    },

    updateTimestamp() {
      this.$set(this, 'updated', new Date().getTime())
    },

    setPropertyOnCurrentAndParentValidation(path, property, value) {
      this.checkConfiguration(path)
      const validation = this.getValidator(path)
      const parent = this.getValidator(path, true)
      
      const validationPrevValue = validation?.[property];
      const parentPrevValue = parent?.[property];

      if (validation) {
        this.$set(validation, property, value)
      }

      if (parent) {
        this.$set(parent, property, value)
      }

      if(validationPrevValue !== value || parentPrevValue !== value) {
        this.updateTimestamp()
      }
    },

    setPropertyOnValidation(validation, property, value) {
      this.checkConfiguration(validation)

      const validationPrevValue = validation?.[property];

      this.$set(validation, property, value)
      if(validationPrevValue !== value) {
        this.updateTimestamp()
      }
    },

    setValid(validation) {
      this.checkConfiguration(validation)

      this.setPropertyOnValidation(validation, 'invalid', false)
      this.setPropertyOnValidation(validation, 'valid', true)
    },

    setInvalid(validation) {
      this.checkConfiguration(validation)
      
      this.setPropertyOnValidation(validation, 'invalid', true)
      this.setPropertyOnValidation(validation, 'valid', false)
    },

    setDirty(path) {
      this.setPropertyOnCurrentAndParentValidation(path, 'dirty', true)
    },

    setTouched(path) {
      this.setPropertyOnCurrentAndParentValidation(path, 'touched', true)
    },

    /**
     * Don't call this function directly, instead use the function this.$pushErrors('form.myField', 'My custom error')
     * 
     * @param {*} path 
     * @param {*} error 
     */
    addError(path, error) {
      const validation = this.getValidator(path);
      const errorList = Array.isArray(error) ? error : [error]

      const allErrorsPresent = errorList.every((error) =>
        validation?.errors.has(error)
      );

      if (allErrorsPresent) {
        return;
      }

      for (const _error of errorList) {
        validation?.errors.add(_error);
      }

      this.setInvalid(validation)
      const splitComposedKey = path.split('.');

      const invalidateParents = (pathArray) => {
        const parent = this.getValidator(pathArray.join('.'), true);
        this.setInvalid(parent)
        pathArray.pop();

        if (pathArray.length) {
          invalidateParents(pathArray)
        }
      }

      invalidateParents(splitComposedKey)
    },

    /**
     * You probably don't need to call this method directly. For validation internal use
     * 
     * @param {*} path 
     * @param {*} error 
     */
    removeError(path, error) {
      const validation = this.getValidator(path);
      const errorList = Array.isArray(error) ? error : [error]

      for (const _error of errorList) {
        validation.errors.delete(_error);
      }

      if (!validation.errors.size) {
        this.setValid(validation)
      }

      const splitComposedKey = path.split('.');

      const validateParents = (pathArray) => {
        const parent = this.getValidator(pathArray.join('.'), true);

        let valid = true;
        for (let val of Object.values(parent)) {
          if (val && typeof val !== "function" && 
              ((val.errors && val.errors.size) || (val.hasOwnProperty('invalid') && val.invalid))) {

            valid = false;
            break;
          }
        }

        if (valid) {
          this.setValid(parent)
        }

        pathArray.pop();

        if (pathArray.length) {
          validateParents(pathArray)
        }
      }

      validateParents(splitComposedKey)
    },

    /**
     * Method used to check if a field is dirty
     * 
     * For Example:
     * 
     * this.validation.isDirty('form.myField')
     * 
     * @param {*} path 
     */
    isDirty(path) {
      return this.getValidator(path).dirty;
    },

    /**
     * Method used to check if a field is valid
     * For Example:
     * 
     * this.validation.isValid('form.myField')
     * this.validation.isValid('form')
     * 
     * @param {*} path 
     * @param {*} validateUntouched 
     */
    isValid(path, validateUntouched = true) {
      const validation = this.getValidator(path);
      if (validateUntouched || validation.touched) {
        return validation.valid;
      }
      return true;
    },

    /**
     * Method used to check if a field is invalid
     * 
     * For Example:
     * 
     * this.validation.isInvalid('form.myField')
     * this.validation.isInvalid('form')
     * 
     * @param {*} path 
     * @param {*} validateUntouched 
     */
    isInvalid(path, validateUntouched = true) {
      const validation = this.getValidator(path);
      if (validation && (validateUntouched || validation.touched)) {
        return validation.invalid;
      }
      return false;
    },

    /**
     * Method used to get the errors list from a certain field
     * 
     * For Example:
     * 
     * this.validation.getErrors('form.myField')
     * 
     * @param {*} path 
     * @param {*} validateUntouched 
     */
    getErrors(path, validateUntouched = false) {
      this.checkConfiguration(path)

      const validator = this.getValidator(path)
      if (validator && validator.errors && (validateUntouched || validator.touched)) {
        return [...validator.errors]
      }
    },

    /**
     * Method used to get the errors list from a certain field
     * 
     * For Example:
     * 
     * this.validation.getErrors('form.myField')
     * 
     * @param {*} path 
     * @param {*} validateUntouched 
     */
    hasErrors(path, validateUntouched = false) {
      this.checkConfiguration(path)

      const validator = this.getValidator(path)
      return validator?.errors?.toJSON()?.length && (validateUntouched || validator.touched)
    },

    /**
     * Method used to check if a field is touched
     * 
     * For Example:
     * this.validation.isTouched('form.myField')
     * @param {*} path 
     */
    isTouched(path) {
      return this.getValidator(path).touched;
    },

    reset(path) {
      const errors = this.getErrors(path, true)
      if (errors) {
        for (const error of errors) { 
          this.removeError(path, error)
        }
      }
    },
  }
}

const validator = {
  beforeMount() {
    this.$checkValidationConfiguration();
    this.$configureValidators(this.$options.validators)
  },

  data: function() {
    return {
      validation: getValidation(this.$set, this.$options.__file)
    }
  },

  methods: {
    $checkValidationConfiguration() {
      if (!this.$options.validators) {
        throw new Error(`error on file: ${this.$options.__file}. The validation mixin is defined for this component, but the validators: {...} options is not defined`)
      }
    },
    $runValidator(rule, key, value, initialRun = false) {
      if (!rule.isValid) {
        throw new Error('The validation rule is not properly defined. There is no "isValid(...)" function: ' + rule)
      }
      if (!rule.getMessage) {
        throw new Error('The validation rule is not properly defined. There is no "getMessage(...)" function: ' + rule)
      }
      if (rule.options?.forceTouch) {
        this.validation.setTouched(key)
      }

      let valid = rule.isValid(value, this) || (!value && rule?.options?.emptyAllowed)

      if (!valid) {
        this.validation.addError(key, rule.getMessage(key, value, this))
      } else {
        if (!initialRun) {
          this.validation.removeError(key, rule.getMessage(key, value, this))
        }
      }
    },
    $register(composedKey, validation) {
      const splitComposedKey = composedKey.split('.');
      splitComposedKey.reduce((prev, curr, ix) => {
        return (ix + 1 === splitComposedKey.length)
          ? prev[curr] = validation
          : prev[curr] = prev[curr] || getDefaultValidationFields();
      }, this.validation)
    },

    /**
     * Call this function if you need to configure a validation on the fly, with no need to create validators: {...} in your component
     * 
     * Note: if you have declared all validation rules into the validators: {...}, you DO NOT need to call this function.
     * 
     * @param {*} validatorsConfig
     * @param {*} [options]
     */
    $configureValidators(validatorsConfig, options) {
      const diveIntoValidatorsTree = (_key, _validatorsConfig) => {
        for (let [key, validatorConfig] of Object.entries(_validatorsConfig)) {
          const composedKey = _key ? `${_key}.${key}` : key

          if (Array.isArray(validatorConfig) || typeof validatorConfig.isValid === 'function') {
            const previousValidator = this.validation.getValidator(composedKey)
            const previousValidatorList = options?.resetPreviousValidators ? [] : (previousValidator?.validatorList || [])
            let validatorList = new Set(Array.isArray(validatorConfig) ? [...validatorConfig, ...previousValidatorList] : [validatorConfig, ...previousValidatorList])

            const watchCallback = (newValue, initialRun = false) => {
              for (const rule of validatorList) {
                try {
                  this.$runValidator(rule, composedKey, newValue, initialRun)
                } catch (error) {
                  // empty block
                }
              }
            }

            const setDirty = () => {
              this.validation.setDirty(composedKey)
            }

            const setTouched = () => {
              this.validation.setTouched(composedKey)
            }

            const validation = { 
              watchCallback,
              validatorList,
              setDirty,
              setTouched,
              errors: new JSONSet(),
              ...getDefaultValidationFields()
            }
            this.$register(composedKey, validation)

          } else {
            diveIntoValidatorsTree(composedKey, validatorConfig)
          }
        }
      }

      diveIntoValidatorsTree('', validatorsConfig)
      this.$set(this.validation, 'configurationDone', true);
    },
    
    /**
     * It's possible to push the errors directly without need configure rules (e.g. required(), max())
     * 
     * It's useful when we receive warnings directly from the backend
     * 
     * For Example: 
     *  in your form, call:
     * 
     * this.$pushErrors('form.myField', 'An error coming from the backend')
     * this.$pushErrors('form.myField', ['More than', 'One error'])
     * 
     * @param {*} composedKey 
     * @param {*} message 
     */
    $pushErrors(composedKey, message) {
      const validator = this.validation.getValidator(composedKey)
      if (!validator || !validator.configurationDone) {
        this.$configureValidators(createValidatorConfig(composedKey, message))
      }
      this.validation.addError(composedKey, message)
      this.validation.setDirty(composedKey)
      this.validation.setTouched(composedKey)
    }
  },
  
}

class JSONSet extends Set {
  toJSON () {
    return [...this]
  }
}

export default validator;
