<template>
  <div class="input-multiple__container">
    <label class="input-forms__label-container">
      <div class="input-forms__label-content" v-if="label">{{label}}</div>
      <div class="input-forms__input-container">

        <div 
          class="input-multiple__item-container " 
          :class="[ 'flex-' + direction, direction === 'row' ? 'layout__negative-margin--8' : '' ]">
          <div class="input-multiple__item" 
            :class="{ 'input-multiple__item--row': direction === 'row' }"
            v-for="(item, index) of values" :key="'' + item.value + index">
            <span
              class="clickable" 
              :class="{'input-multiple__item--active': isItemActive(item) }"
              @click="onClickItem(item)">
              {{item.label}}
            </span>
          </div>
        </div>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    values: {
      type: Array,
      default: () => []
    },
    direction: {
      type: String,
      default: 'row' // column, row
    },
    label: {
    },
  },
  methods: {
    onClickItem(item) {
      if (this.value?.some(current => current.value === item.value)) {
        this.removeItem(item)
      } else {
        this.addItem(item)
      }
    },
    addItem(item) {
      const newSelected = this.value ? [ ...this.value, item ] : [item] 
      this.$emit('input', newSelected)
    },
    removeItem(item) {
      const newSelected = this.value?.filter(current => current.value !== item.value) || [];
      this.$emit('input', newSelected)
    },
    isItemActive(item) {
      return this.value && this.value.some(current => current.value === item.value);
    }
  }
}
</script>

<style scoped>
  .input-multiple__item {
    flex: 0 0 auto;
  }
  .input-multiple__item--row { 
    height: 32px;
    display: flex;
    align-items: center;
  }
  .input-multiple__item-container { 
    display: flex;
  }
  .flex-column {
    flex-direction: column;
  }
  .flex-row {
    flex-direction: row;
  }
  .input-multiple__item--active { 
    color: var(--color-primary);
    font-weight: bold;
  }
</style>