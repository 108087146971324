import SESSION_TIME_TYPES from './types';

export default {
  [SESSION_TIME_TYPES.GETTERS.CLICKS_BY_PATH](state) {
    return state.clicksByPath;
  },

  [SESSION_TIME_TYPES.GETTERS.PARALLEL_TIMEOUT_TIME](state) {
    return state.parallelTimeoutTime
  },

  [SESSION_TIME_TYPES.GETTERS.CLICKS](state) {
    return (path) => {
      return state.clicksByPath[path];
    }
  },
}