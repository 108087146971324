var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard-customer__container" }, [
    _c("h1", [_vm._v("I'm a customer")]),
    _vm._v(" " + _vm._s(_vm.fullname) + " "),
    _c("h4", [_vm._v("Customer dashboard")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }