<template>
  <BaseModal ref="modal"
    @onCloseButton="close"
    :showDefaultButtons="false">
    <template #modalTitle>
      {{displayName}} 
      <span v-if="kundennr">({{kundennr}})</span>
    </template>

    <Ticket 
      class="ticket__embedded"
      embedded
      :chatId="chatId"
      :channelId="channelId"
      
    />
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/core/BaseModal.vue';
import EVENT_BUS, { CHAT_SEND_MESSAGE, CHAT_ADD_DOCUMENT, } from '@/event-bus';
import Ticket from '@/views/communication/Ticket.vue';

export default {
  components: {
    BaseModal,
    Ticket,
  },
  props: {
    chatId: {
      type: [Number, String],
    },
    channelId: {
      type: [Number, String],
    },
    displayName: {
      type: String,
    },
    kundennr: {
      type: String,
    },
  },
  mounted() {
    this.$refs.modal.open();
  },
  methods: {
    close() {
      this.$emit("close");
      this.$refs.modal.close();
    },
    onSendChat() {
      EVENT_BUS.$emit(CHAT_SEND_MESSAGE);
    },
    onUploadChatFile(files) {
      EVENT_BUS.$emit(CHAT_ADD_DOCUMENT, files);
    },
  },
}
</script>

<style scoped>
.ticket__embedded {
}
</style>
