<template>
    <div>
        <div class="box__title" v-if="personen">Wirtschaftliche Eigentümer</div>

        <InputRadioBoxGroup v-if="data && data.isEigentuemerTypeVisible"
            :value="data.INPUT_RG_EIGENTUEMER_TYPE" 
            :validateUntouched="true"
            :values="valuesEigentuemerType"
            @input="updateEigentuemerType($event)" 
        />

        <div class="cards__container">
            <div v-if="personen.length" class="cards__items">
                <div class="box__container cards__item" v-for="(person, index) in personen" :key="index" ref="cards"
                >
                    <div class="box__title">Personendaten {{index+1}}</div>

                    <div class="cards__item--inputs">                    
                        <InputField label="Name und Vorname" :value="person.INPUT_PERSON_NAME" :id="'INPUT_PERSON_NAME'+person.index" 
                            @change="updatePerson('INPUT_PERSON_NAME', person, $event)"/>
                        <DatePickerField
                            label="Geburtsdatum"
                            dateFormat="DD.MM.YYYY"
                            isValueAsString
                            :value="person.INPUT_GEBURTS_DATUM" :id="'INPUT_GEBURTS_DATUM'+person.index" 
                            @change="updatePerson('INPUT_GEBURTS_DATUM', person, $event)" />
                        <InputField label="Geburtsort" :value="person.INPUT_GEBURTS_ORT" :id="'INPUT_GEBURTS_ORT'+person.iindexd" 
                            @change="updatePerson('INPUT_GEBURTS_ORT', person, $event)"/>
                        <ComboBox label="Wohnsitzland" :value="person.INPUT_LAND" :id="'INPUT_LAND'+person.index" :values="comboboxSelection['COMBO_LAND'] || []"
                            @change="updatePerson('INPUT_LAND', person, $event)" />
                        <InputField label="Ort/PLZ" :value="person.INPUT_ORT" :id="'INPUT_ORT'+person.index" 
                            @change="updatePerson('INPUT_ORT', person, $event)"/>
                        <InputField label="Adresse" :value="person.INPUT_ADRESSE" :id="'INPUT_ADRESSE'+person.index" 
                            @change="updatePerson('INPUT_ADRESSE', person, $event)"/>
                        <ComboBox label="Staatsbürgerschaft" :value="person.INPUT_STAATSBUERGERSCHAFT" :id="'INPUT_STAATSBUERGERSCHAFT'+person.index" 
                            :values="comboboxSelection['COMBO_NATIONALITY'] || []"
                            @change="updatePerson('INPUT_STAATSBUERGERSCHAFT', person, $event)" />
                    </div>

                    <div class="box__title">Art des wirtschaftlichen Eigentümers</div>
                        
                    <div class="cards__item--inputs">  
                        <InputToggleSwitch v-if="isJuristischePerson" label="direkt" :value="person.INPUT_CHK_DIREKT" :id="'INPUT_CHK_DIREKT'+person.index" 
                            @input="updatePerson('INPUT_CHK_DIREKT', person, $event)" />
                        <InputToggleSwitch v-if="isJuristischePerson" label="indirekt" :value="person.INPUT_CHK_INDIREKT" :id="'INPUT_CHK_INDIREKT'+person.index" 
                            @input="updatePerson('INPUT_CHK_INDIREKT', person, $event)" />
                        <InputToggleSwitch v-if="isJuristischePerson" label="Kontrolle" :value="person.INPUT_CHK_KONTROLLE" :id="'INPUT_CHK_KONTROLLE'+person.index" 
                            @input="updatePerson('INPUT_CHK_KONTROLLE', person, $event)" />
                        <InputToggleSwitch v-if="isJuristischePerson" label="Oberste Führungsebene" :value="person.INPUT_CHK_OBERSTE_FUEHRUNG" :id="'INPUT_CHK_OBERSTE_FUEHRUNG'+person.index" 
                            @input="updatePerson('INPUT_CHK_OBERSTE_FUEHRUNG', person, $event)" />
                        <InputToggleSwitch v-if="!isJuristischePerson" label="Stifter / Gründer" :value="person.INPUT_CHK_STIFTER" :id="'INPUT_CHK_STIFTER'+person.index" 
                            @input="updatePerson('INPUT_CHK_STIFTER', person, $event)" />
                        <InputToggleSwitch v-if="!isJuristischePerson" label="Begünstigte" :value="person.INPUT_CHK_BEGUENSTIGTE" :id="'INPUT_CHK_BEGUENSTIGTE'+person.index" 
                            @input="updatePerson('INPUT_CHK_BEGUENSTIGTE', person, $event)" />
                        <InputToggleSwitch v-if="!isJuristischePerson" label="Stiftungsvorstand" :value="person.INPUT_CHK_VORSTAND" :id="'INPUT_CHK_VORSTAND'+person.index" 
                            @input="updatePerson('INPUT_CHK_VORSTAND', person, $event)" />
                        <InputToggleSwitch v-if="!isJuristischePerson" label="Kontrolle" :value="person.INPUT_CHK_KONTROLLE" :id="'INPUT_CHK_KONTROLLE'+person.index" 
                            @input="updatePerson('INPUT_CHK_KONTROLLE', person, $event)" />
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import InputField from '@/components/core/forms/InputField.vue';
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';

const PERSONEN_DATA_LIST = ['INPUT_PERSON_NAME', 'INPUT_GEBURTS_DATUM', 'INPUT_GEBURTS_ORT', 'INPUT_LAND', 'INPUT_ADRESSE', 'INPUT_ORT',
			'INPUT_STAATSBUERGERSCHAFT', 'INPUT_CHK_DIREKT', 'INPUT_CHK_INDIREKT', 'INPUT_CHK_KONTROLLE', 'INPUT_CHK_OBERSTE_FUEHRUNG', 'INPUT_CHK_STIFTER',
			'INPUT_CHK_BEGUENSTIGTE', 'INPUT_CHK_VORSTAND'];

export default {
    components: {
        InputField,
        DatePickerField,
        InputToggleSwitch,
        ComboBox,
        InputRadioBoxGroup,
    },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
        comboboxSelection: {
            
        }
    },
    data() {
        return {
            valuesEigentuemerType: [{label: "juristische Personen", value: "j"}, {label: "Stiftung", value: "s"}],
        }
    },
    mounted() {
        
    },
    computed: {
        isJuristischePerson() {
            return this.data?.isJuristischePerson;
        },
        personen() {
            const personen = [];
            [0,1,2,3,4].forEach(index => {
                const person = {index: index};
                PERSONEN_DATA_LIST.forEach(id => {
                person[id] = this.data[`${id}_${index}`] || (id?.includes('CHK') ? '0' : '')
            })
            personen.push(person);
        });
        return personen;
        },
    },
    methods: {
        updatePerson(compId, row, value) {
            this.$emit('updateStore', {componentId: `${compId}_${row.index}`, value: value});
        },
        updateEigentuemerType(value) {
            this.$emit('updateStore', {componentId: 'INPUT_RG_EIGENTUEMER_TYPE', value: value});
            this.$emit('updateStore', {componentId: 'isJuristischePerson', value: value === "j" ? true : false});
        },
    }
}
</script>

<style lang="scss" scoped>
.cards__container {
    .cards__items {
        .cards__item {
            &.has-warnings {
                border: 1px solid var(--color-danger);
            }

            &.box__container > * {
                margin-bottom: 0.25rem;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .cards__item--inputs {
            display: flex;
            margin-left: -0.5rem;
            margin-right: -0.5rem;
            flex-wrap: wrap;

            > * {
                flex: 0 0 240px;
                margin: 0 0.5rem 0.25rem;
                padding: 0;
                width: auto;

                &.cards__item--input-240 {
                    flex-basis: 240px;
                }
            }

            .cards__item--input-no-label {
                margin-top: 1.5rem;
            }
        }
    }
}

@media (max-width: 375px) {
    .cards__container {
        .cards__items {
            .cards__item--inputs {
                > * {
                    width: calc(50% - 1rem);
                    flex-basis: auto;
                }
            }
        }
    }
}

@media (max-width: 335px) {
    .cards__container {
        .cards__items {
            .cards__item--inputs {
                > * {
                    width: calc(100% - 1rem);
                }

                .cards__item--input-no-label {
                    margin-top: 0;
                }
            }
        }
    }
}
</style>