import { isTrue } from '@/helpers/mapping';
import { parse } from '@/helpers/number-formatter.js'
import dayjs from 'dayjs'
import { toDayjs } from '@/helpers/date-helper'

const wrongValuesForRequiredField = [
  '',
  'NICHT_VERWENDEN'
]

export function createRule(isValid) {
  return {
    isValid,
    getMessage: (field) => '',
    withMessage(value) {
      if (typeof value === 'function') {
        this.getMessage = value;
      } else {
        this.getMessage = () => value;
      }
      return this;
    },
  };
}

export function required(customMessage = '', options) {
  return {
    ruleName: 'required',
    getMessage: (field) => customMessage || customMessage === '' ? customMessage : `The field ${field} is required`,
    isValid: (value) => {
      return value && !wrongValuesForRequiredField.some(wrongValue => value === wrongValue) || false;
    },
    options
  }
}

export function htmlEditorRequired(customMessage) {
  const message = customMessage || customMessage === '' ? customMessage : 'Inhalt ist erforderlich'
  return regex(/^(?!(<p><\/p>)+$|$).*$/, message);
}

export function requiredZeroAllowed(customMessage = '', options) {
  return {
    ruleName: 'requiredZeroAllowed',
    getMessage: (field) => customMessage || customMessage === '' ? customMessage : `The field ${field} is required`,
    isValid: (value) => {
      return value == '0' || value && !wrongValuesForRequiredField.some(wrongValue => value === wrongValue)
    },
    options
  }
}

export function requiredIf(customMessage = '', condition, options) {
  return {
    ruleName: 'requiredIf',
    getMessage: (field) => customMessage || customMessage === '' ? customMessage : `The field ${field} is required`,
    isValid: (value) => {
      if(condition?.()) {
        return value && !wrongValuesForRequiredField.some(wrongValue => value === wrongValue)
      }
      return true;
    },
    options
  }
}

export function minLength(length, customMessage = '', options) {
  const lengthNumeric = typeof testValue !== 'number' ? parseFloat(length) : length
  if (!lengthNumeric) {
    throw new Error("The 'minLength' validation rule must receive a number as a first parameter")
  }
  return {
    ruleName: 'minLength',
    getMessage: (field) => customMessage ? customMessage: `The field ${field} must be greater than ${lengthNumeric - 1}`,
    isValid: (value) => {
      return value && value.length >= lengthNumeric;
    },
    options
  }
}

export function maxLength(length, customMessage = '', options) {
  const lengthNumeric = typeof testValue !== 'number' ? parseFloat(length) : length
  if (!lengthNumeric) {
    throw new Error("The 'maxLength' validation rule must receive a number as a first parameter")
  }
  return {
    ruleName: 'maxLength',
    getMessage: (field) => customMessage ? customMessage: `The field ${field} must be smaller than ${lengthNumeric}`,
    isValid: (value) => {
      return !value || (value && value.length <= lengthNumeric);
    },
    options
  }
}


export function minValue(testValue, customMessage = '', options) {
  if (typeof testValue !== 'number') {
    testValue = parse(testValue)
  }

  if (testValue !== 0 && !testValue) {
    throw new Error("The 'minValue' validation rule must receive a number as a first parameter")
  }
  return {
    ruleName: 'minValue',
    getMessage: (field) => customMessage ? customMessage: `The field ${field} must be more than ${testValue}`,
    isValid: (value) => {
      if(options?.emptyAllowed && !value) {
        return true
      }
      try {
        if (typeof(value) === 'string') {
          value = parse(value)
        }
        return value >= testValue;
      } catch(error) {
        return false;
      }
    },
    options
  }
}

export function maxValue(testValue, customMessage = '', options) {
  if (typeof testValue !== 'number') {
    testValue = parse(testValue)
  }

  if (testValue !== 0 && !testValue) {
    throw new Error("The 'maxValue' validation rule must receive a number as a first parameter")
  }
  return {
    ruleName: 'maxValue',
    getMessage: (field) => customMessage ? customMessage: `The field ${field} must be less than ${testValue}`,
    isValid: (value) => {
      if(options?.emptyAllowed && !value) {
        return true
      }
      try {
        if (typeof(value) === 'string') {
          value = parse(value)
        }

        if (options?.inclusive) {
          return value <= testValue
        }

        return value < testValue
      } catch(error) {
        return false;
      }
    },
    options
  }
}

export function regex(regex, customMessage = '', options) {
  if (!regex) {
    throw new Error("The 'regex' validation rule must receive a regex as a first parameter")
  }
  return {
    ruleName: 'regex',
    getMessage: (field) => customMessage || customMessage === '' ? customMessage: `The field ${field} is invalid`,
    isValid: (value) => {
      return !value && options?.emptyAllowed ? true : regex.test(value);
    },
    options
  }
}

export function email(customMessage = '', options) {
  const defaultOptions = {
    emptyAllowed: true,
  };
  const mergedOptions = {
    ...defaultOptions,
    ...options,
  }
  return regex(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/, customMessage ? customMessage : 'Bitte geben Sie eine gültige E-Mail Adresse ein.', mergedOptions)
}

export function emailMultiple(customMessage = '', options) {
  const defaultOptions = {
    emptyAllowed: true,
    multipleEmailAddresses: true
  };
  const mergedOptions = {
    ...defaultOptions,
    ...options,
  }

  return {
    ruleName: 'emailMultiple',
    getMessage: (field) => customMessage || customMessage === '' ? customMessage: `The field ${field} is invalid`,
    isValid: (value) => {
      if(!value && options?.emptyAllowed) {
        return true;
      }
      const valueSplited = value.split(' ');
      return valueSplited.every(email(customMessage, mergedOptions).isValid);
    },
    options: mergedOptions
  }
}

export function telefon(customMessage = '', options) {
  return regex(/^[\+]?[(]?[0-9]{1,4}[)]?[-\s\.]?[0-9]{1,6}[-\s\.]?[0-9]{5,12}$/, customMessage ? customMessage : 'Bitte geben Sie eine gültige Telefonnummer ein', options)
}

export function numbers(customMessage = '', options) {
  return regex(/^\d+[,.]?[0]*$/, customMessage ? customMessage : 'Only numbers are allowed', options)
}

export function kundennr(customMessage = '', options) {
  return regex(/^(\d{1,7})?$/, customMessage ? customMessage : 'Ungültiger Wert für Kundennummer', options)
}

export function vorname(customMessage = '', options) {
  return {
    ruleName: 'vorname',
    getMessage: (field) => customMessage ? customMessage: `Bitte geben Sie Ihren Vornamen ein`,
    isValid: (value) => {
      return value && value !== ''
    },
    options
  }
}

export function nachname(customMessage = '', options) {
  return {
    ruleName: 'nachname',
    getMessage: (field) => customMessage ? customMessage: `Bitte geben Sie Ihren Nachnamen ein`,
    isValid: (value) => {
      return value && value !== ''
    },
    options
  }
}

export function requiredTrue(customMessage = '', options) {
  return {
    ruleName: 'requiredTrue',
    getMessage: (field) => customMessage ? customMessage: `The field ${field} is required`,
    isValid: (value) => {
      return isTrue(value);
    },
    options
  }
}

export function formatPhone(customMessage = '', options) {
  return regex(/^[\+]?[(]?[0-9]{1,4}[)]?[-\s\.]?[0-9]{1,6}[-\s\.]?[0-9]{5,12}$/,
   customMessage ? customMessage : 'Bitte geben Sie eine gültige Telefonnummer ein', options)
}

export function formatPLZ(land, options) {
  return {
    ruleName: 'formatPLZ',
    getMessage: (field) => `Die Postleitzahl muss dem Format ${land === 'AT' ? '1234' : '12345'} entsprechen.`,
    isValid: (value) => {
      if (value === undefined || value === '') {
        return true;
      }
      if (value.length > 12) {
        return false;
      }

      if (land && land === 'DE') {
        return new RegExp('^[0-9]{5}$').test(value);
      }
      if (land && land === 'AT') {
        return new RegExp('^[0-9]{4}$').test(value);
      }

      return new RegExp('(?:([A-Za-z]+))(.*)').test(value);
    },
    options
  }
}

export function range(minValue, maxValue, customMessage = '', options) {
  const minValueNumeric = typeof minValue !== 'number' ? parseFloat(minValue) : minValue;
  if (!minValueNumeric && minValueNumeric !== 0) {
    throw new Error("The 'minValue' validation rule must receive a number as a first parameter")
  }
  const maxValueNumeric = typeof maxValue !== 'number' ? parseFloat(maxValue) : maxValue;
  if (!maxValueNumeric && maxValueNumeric !== 0) {
    throw new Error("The 'maxValue' validation rule must receive a number as a first parameter")
  }
  return {
    ruleName: 'range',
    getMessage: (field) => customMessage ? customMessage: `Der Wert muss zwischen ${minValueNumeric} und ${maxValueNumeric} sein`,
    isValid: (value) => {
      if (typeof(value) === 'string') {
        value = parse(value)
      }
      return !value && value !== 0 || value >= minValueNumeric && value <= maxValueNumeric;
    },
    options
  }
}

// countries example 'DE, AT, CH'
export function iban(customMessage = '', countries = '') {
  const checkIban = function (fieldValue) {
    if (!fieldValue) {
      return '';
    }
    const iban = fieldValue.toUpperCase().replace(/[^A-Z0-9]/g, '');
    const CODE_LENGTHS = {
      AD: 24, AE: 23, AL: 28, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29, 
      BY: 28, CH: 21, CR: 22, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, EG: 29, 
      ES: 24, FI: 18, FO: 18, FR: 27, GB: 22, GE: 22, GI: 23, GL: 18, GR: 27, GT: 28, 
      HR: 21, HU: 28, IE: 22, IL: 23, IQ: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20,
      LB: 28, LC: 32, LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19,
      MR: 27, MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
      RO: 24, RS: 22, SA: 24, SC: 31, SE: 24, SI: 19, SK: 24, SM: 27, ST: 25, SV: 28,
      TL: 23, TN: 24, TR: 26, UA: 29, VA: 22, VG: 24, XK: 20
    };
    if ( !iban && iban.length < 2 || CODE_LENGTHS[iban.substr(0, 2)] === undefined ) {
      return 'Falscher Ländercode';
    }
    if (countries && -1 == countries.findIndex(iban.substr(0, 2)) ) {
      return 'Gültige Ländercode: ' + countries;
    }
    const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);

    if (!code || code.length !== 4) {
        return 'Falsches Format';
    }
    if ( iban.length !== CODE_LENGTHS[code[1]]) {
      return 'Falsche Länge';
    }
    const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
        return letter.charCodeAt(0) - 55;
    });
    const cs = (parcs, pardig) => {
      let fragment;
      for (let offset = 2; offset < pardig.length; offset += 7) {
        fragment = parcs + pardig.substring(offset, offset + 7);
        parcs = parseInt(fragment, 10) % 97;
      }
      return parcs;
    };
    const checksum = cs(parseInt(digits.slice(0, 2), 10), digits);
    return checksum === 1 ? '' : 'Falsche Prüfsumme';
  }
  let errorMsg = '';
  return {
    ruleName: 'iban',
    getMessage: (key, value, self) => {
      if (!customMessage && self && key) {
        self.validation.reset(key);
      }
      return customMessage ? customMessage : errorMsg
    },
    isValid: (value, self) => {
      errorMsg = checkIban(value);
      return !errorMsg;
    }
  }
}

export function minDate(testValue, customMessage = '', options) {
  const testValueDayjs = typeof testValue?.getMonth === 'function' ? dayjs(testValue) : toDayjs(testValue)
  if (!testValueDayjs.isValid()) {
    throw new Error("The 'minDate' validation rule must receive either a Date or a formatted String")
  }
  return {
    ruleName: 'minDate',
    getMessage: (field) => customMessage ? customMessage: `The field ${field} must be more than ${testValueDayjs.format(options.format)}`,
    isValid: (value) => {
      try {
        const valueDayjs = typeof value?.getMonth === 'function' ? dayjs(value) : toDayjs(value)
        return testValueDayjs.isBefore(valueDayjs, 'day');
      } catch(error) {
        return false;
      }
    },
    options
  }
}

export function maxDate(testValue, customMessage = '', options) {
  const testValueDayjs = typeof testValue?.getMonth === 'function' ? dayjs(testValue) : toDayjs(testValue)
  if (!testValueDayjs.isValid()) {
    throw new Error("The 'minDate' validation rule must receive either a Date or a formatted String")
  }
  return {
    ruleName: 'maxDate',
    getMessage: (field) => customMessage ? customMessage: `The field ${field} must be more than ${testValueDayjs.format(options.format)}`,
    isValid: (value) => {
      try {
        const valueDayjs = typeof value?.getMonth === 'function' ? dayjs(value) : toDayjs(value)
        return testValueDayjs.isAfter(valueDayjs, 'day');
      } catch(error) {
        return false;
      }
    },
    options
  }
}

export function dummy(customMessage = '', options) {
  return {
    ruleName: 'dummy',
    getMessage: () => customMessage,
    isValid: () => true,
    options
  }
}