<template>
  <div class="box__container">
    <EntitySelector
        label="Depotinhaber"
        isPersonPicker
        isComponentHalfSize
        v-model="INPUT_PERSONEN_INHABER" 
        id="INPUT_PERSONEN_INHABER"
        :values="comboPerson"
        :disabled="readOnly"
        @new="goToPersonalData('neue_person')"
        @edit="goToPersonalData(INPUT_PERSONEN_INHABER)"
        @change="updateStoreData({componentId: 'INPUT_PERSONEN_INHABER', value: $event })">
    </EntitySelector>
    <EntitySelector
        label="2. Depotinhaber (nur für bestimmte Lagerstellen notwendig und ggf. freigeschaltet)"
        isPersonPicker
        isComponentHalfSize
        v-model="INPUT_PERSONEN_INHABER2" 
        :values="comboPerson2"
        :disabled="readOnly || !hasDepotinhaber2"
        validateUntouched
        @new="goToPersonalData('neue_person')"
        @edit="goToPersonalData(INPUT_PERSONEN_INHABER2)"
        @change="updateStoreData({componentId: 'INPUT_PERSONEN_INHABER2', value: $event })">
    </EntitySelector>
    <InputRadioBoxGroup 
      title="Erklärung / Einwilligungen"
      v-model="INPUT_ERKLAERUNG" 
      :values="radioErklaerung"
      :disabled="readOnly"
      @input="updateStoreData({componentId: 'INPUT_ERKLAERUNG', value: $event })" />
    <InputRadioBoxGroup v-if="!isCustomerLogin"
      title="Maklerunterschrift / Stempelfeld"
      v-model="INPUT_MAKLERUNTERSCHRIFT" 
      :values="radioMaklerunterschrift"
      :disabled="readOnly"
      @input="updateStoreData({componentId: 'INPUT_MAKLERUNTERSCHRIFT', value: $event })" />
    <InputRadioBoxGroup v-if="!isCustomerLogin"
      title="Ausgabeaufschlag anzeigen"
      v-model="INPUT_AUSGABEAUFSCHLAG" 
      :values="radioAusgabeaufschlag"
      :disabled="readOnly"
      @input="updateStoreData({componentId: 'INPUT_AUSGABEAUFSCHLAG', value: $event })" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import WERTPAPIERORDER_TYPES from '@/store/wertpapierorder/types';
import mixin from '@/mixins/wertpapierorder/mixin.js';
import EntitySelector from '@/components/core/forms/EntitySelector.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import CUSTOMERDATA_TYPES from '@/store/customerData/types'
import validator from "@/mixins/validator";

export default {
    mixins: [mixin, validator],
    validators: {},
    components: {
        EntitySelector,
        InputRadioBoxGroup,
    },
    data() {
        return {
            INPUT_PERSONEN_INHABER: '',
            INPUT_PERSONEN_INHABER2: '',
            INPUT_ERKLAERUNG: '',
            INPUT_MAKLERUNTERSCHRIFT: '',
            INPUT_AUSGABEAUFSCHLAG: '',
        }
    },
    mounted() {
        if (this.$route.query.backAction) {
            this.$store.dispatch(WERTPAPIERORDER_TYPES.ACTIONS.GET_PERSONEN_AUSWAHL);
        }
        this.initForm();
    },
    updated() {
        this.updateWarnings('sonstiges', false);
    },
    watch: {
        storeWarnings: function(value) {
            this.updateWarnings('sonstiges', false);
        }
    },
    computed: {
        ...mapGetters({
            comboboxValues: WERTPAPIERORDER_TYPES.GETTERS.COMBOBOX_VALUES || {},
            storeWarnings: WERTPAPIERORDER_TYPES.GETTERS.WARNINGS || {},
        }),
        comboPerson() {
            return this.comboboxValues && this.comboboxValues['INPUT_PERSONEN_INHABER'] || [];
        },
        comboPerson2() {
            return this.comboboxValues && this.comboboxValues['INPUT_PERSONEN_INHABER2'] || [];
        },
        hasDepotinhaber2() {
            return this.data?.hasDepotinhaber2 || this.hatZweiterPersonComdirect() || this.hatZweiterPersonEasybank();
        }
    },
    methods: {
        initForm() {
            this.INPUT_PERSONEN_INHABER = this.data['INPUT_PERSONEN_INHABER'];
            this.INPUT_PERSONEN_INHABER2 = this.data['INPUT_PERSONEN_INHABER2'];
            this.INPUT_ERKLAERUNG = this.data['INPUT_ERKLAERUNG'];
            this.INPUT_MAKLERUNTERSCHRIFT = this.data['INPUT_MAKLERUNTERSCHRIFT'];
            this.INPUT_AUSGABEAUFSCHLAG = this.data['INPUT_AUSGABEAUFSCHLAG'];
        },
        goToPersonalData(personId) {
            if (this.data['person1'] === personId) {
                personId = 'person1';
            }
            this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.UPDATE_SELECTED_CUSTOMER_DATA, personId);
            this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.SET_SELECTED_EXTRA_DATA, {relationship: {isExtraDepotOwner: true}});
            this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GOTO_CUSTOMER_DATA);
            },
        hatZweiterPersonComdirect(){
            if(this.lagerstelle === 'COMDIRECT' && this.positions.find(p=>p.comdirectBoModus == 'LAST')){
               return true;
            }else{
              return false;
            }
        },
        hatZweiterPersonEasybank(){
             if(this.lagerstelle === 'HELLOBANK' && this.positions.find(p=>p.positionTyp == 'NEU_SPARPLAN' || p.positionTyp == 'ALT_SPARPLAN')){
               return true;
            }else{
              return false;
            }
        }
    },
}
</script>

<style>
</style>