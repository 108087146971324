var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "changeCategoryModal",
          attrs: { modalTitle: "Kategorie ändern" },
          on: {
            close: function($event) {
              return _vm.close()
            },
            onConfirmButton: function($event) {
              return _vm.submit()
            }
          }
        },
        [
          _c("ComboBox", {
            attrs: { values: _vm.categoriesComboBox },
            model: {
              value: _vm.chosenCategory,
              callback: function($$v) {
                _vm.chosenCategory = $$v
              },
              expression: "chosenCategory"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }