<template>
  <div>
    <PageHeaderTitleNavigation 
      :title="subtitle" 
      :actions="headerActions" 
      @action-BACK="onLeave()" 
      @action-CANCEL="abbrechen()" 
      @action-REMOVE="remove()" 
    />

    <div class="box__container">
      <GhostLoading v-if="loading">
        <Block type="title" width="100%" />
        <Block type="title" width="100%" />
        <Block type="title" width="100%" class="mb-3" />
        <Block width="50%" />
        <Block type="form-input" />
      </GhostLoading>

      <form v-else autocomplete="off" @submit.prevent>
        <div class="box__title">{{ subtitle }}</div>

        <p class="mt-0 mb-2">Id {{ isNew ? 'n/a' : id }}</p>

        <template v-for="(component, index) in components">
          <div :key="index" class="antrag-component__container">
            <AntragComponent 
              v-if="!isComponentHidden(component)"
              class="antrag-component"
              :component="component" 
              :data="allData" 
              isComponentHalfSizeEnabled
              @change="onChange(component, $event)" />
          </div>
        </template>
      </form>
    </div>

  </div>
</template>

<script>
import { mapGetters, } from 'vuex';
import EXTERNE_ZUGANG_CONFIG_TYPES from '@/store/externeZugangConfig/types';
import CORE_TYPES from '@/store/core/types';

import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import AntragComponent from '@/components/antrag/AntragComponent.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';

import { componentValidatorToRule, isHiddenDisabled } from '@/components/antrag/antrag-utils';
import validator from '@/mixins/validator';
import mixin from '@/mixins/externe-zugaenge/externe-zugaenge-mixin.js';

import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {
  mixins: [validator, mixin],
  validators: {},
  data() {
    return {
      dataEdited: {},
      loading: false,
      cancel: false,
    };
  },
  mounted() {
    if (this.$route.query.result) {
      this.$addBreadcrumb({
        label: 'zurück zu den externen Zugängen', 
        fullPath: '/intern/email-access/eingang',
        breadcrumb: this.bezeichnung,
      });
    }
    this.findDefinition();
    this.checkForCallback();
  },
  computed: {
    ...mapGetters({
      definition: EXTERNE_ZUGANG_CONFIG_TYPES.GETTERS.DEFINITION,
    }),
    headerActions() {
      return [
        PageHeaderSimpleAction('BACK', 'Zurück').withDisabled(() => this.loading || this.isFormInvalid),
        PageHeaderSimpleAction('CANCEL', 'Abbrechen').withDisabled(() => this.loading),
        PageHeaderSimpleAction('REMOVE', 'Löschen').withDisabled(() => this.loading || this.isEmpty),
      ];
    },
    isNew() {
      return !this.id || this.id === '0';
    },
    currentDefinition() {
      if(!this.bezeichnung) return {};
      return {
        ...this.definition?.[this.bezeichnung] || {},
      };
    },
    components() {
      return ([ ...this.currentDefinition?.components || [], ]).map(component => ({
        ...component,
        config: {
          ...component?.config || {},
          validateUntouched: true,
          autocomplete: 'new-password',
        },
      }));
    },
    data() {
      return this.currentDefinition?.data || {};
    },
    allData() {
      return {
        ...this.data,
        ...this.dataEdited,
      };
    },
    subtitle() {
      const subtitle = this.isNew ? 'Neue Zugangsdaten für' : 'Ändern der Zugangsdaten';
      return `${subtitle} ${this.bezeichnung}`;
    },
    dynamicValidators() {
      const { components, } = this;

      const dynamicValidators = components
        .filter(component => component.id && component.validators?.length)
        .map(component => ({
          componentId: component.id,
          validators: component.validators.map(componentValidatorToRule),
        }))

      return dynamicValidators;
    },
    isFormInvalid() {
      return this.validation.updated 
        && this.components?.some(component => component?.id && this.validation?.[component.id]?.invalid);
    },
    id() {
      return this.data?.id || this.$route.params.id || '';
    },
    bezeichnung() {
      return this.$route.params.type || '';
    },
    isEmpty() {
      return !Object.keys(this.data).some(key => !!this.data[key]);
    }
  },
  watch: {
    dynamicValidators: {
      immediate: true,
      handler(newDynamicValidators) {
        for (const dynamicValidator of newDynamicValidators) {
          if (dynamicValidator && this.$configureValidators) {
            this.$configureValidators({ 
              [dynamicValidator.componentId]: dynamicValidator.validators,
            });
          }
        }
      },
    },
  },
  methods: {
    async findDefinition() {
      try {
        this.loading = true;
        await this.$store.dispatch(EXTERNE_ZUGANG_CONFIG_TYPES.ACTIONS.FIND_EXTERNE_ZUGANG_DEFINITION, { bezeichnung: this.bezeichnung, id: this.id });
      } finally {
        this.loading = false;
      }
    },
    onChange(component, value) {
      switch(component.type) {
        case 'BUTTON':
        case 'CONFIRM_BUTTON':
          this.executeAction(component?.config?.action, component?.config?.addLocationHref);
          break;
        case 'SELECT_FILE_BUTTON':
          this.dataChanged(component, value?.data);
          break;
        default: 
          this.dataChanged(component, value);
          break;
      }
    },
    async executeAction(action, addLocationHref = false) {
      if(!action) return;
      this.$store.dispatch(EXTERNE_ZUGANG_CONFIG_TYPES.ACTIONS.EXECUTE_ACTION, {
        action,
        id: this.id,
        bezeichnung: this.bezeichnung,
        data: {
          ...this.allData,
        },
        addLocationHref,
      });
    },
    dataChanged(component, value) {
      if (component.id) {
        this.dataEdited = {
          ...this.dataEdited,
          [component.id]: value,
        };
      } else if (Object.keys(value)[0]) {
        this.dataEdited = {
          ...this.dataEdited,
          ...value,
        };
      }
    },
    isComponentHidden(component) {
      const result = component && component.hidden && isHiddenDisabled(component.hidden, this.allData);
      if (!result) {
        return result;
      }

      return result;
    },
    abbrechen() {
      this.cancel = true;
      this.onLeave();
    },
    onLeave() {
      this.$store.commit(CORE_TYPES.MUTATIONS.POP_SAVE_BACK_TO_PREVIOUS_PAGE)
      this.$router.push({path: '/intern/configs/externe-zugaenge', query: {backAction: true }});
    },
    save() {
      if(!this.isNew && !Object.keys(this.dataEdited).length) return;

      this.$store.dispatch(EXTERNE_ZUGANG_CONFIG_TYPES.ACTIONS.SAVE_EXTERNE_ZUGANG_DATA, {
        bezeichnung: this.bezeichnung, 
        id: this.id,
        data: {
          ...this.allData,
        },
      });
    },
    remove() {
      this.$confirmModal({
        title: 'Externen Zugang löschen', 
        message: 'Soll dieser esterne Zugang wirklich gelöscht werden?', 
        labelButtonConfirm: 'Ja',
        labelButtonCancel: 'Nein',
      }).then(() => {
        this.cancel = true;
        if (!this.isNew) {
          this.deleteExterneZugangConfig(this.id);
        }
        this.onLeave();
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.cancel && this.isFormInvalid) {
      this.$confirmModal({
        title: 'Fehler', 
        message: 'Bitte alle Pflichtfelder ausfüllen.', 
        labelButtonConfirm: 'Ok',
        showCancelButton: false,
      })
    } else {
      if (!this.cancel) {
        this.save();
      }
      next();
    }
  },
  validators: {},
  components: {
    GhostLoading,
    Block,
    AntragComponent,
    OptionMenu,
    PageHeaderTitleNavigation,
  },
}
</script>

<style lang="scss" scoped>
.antrag-component__container {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
