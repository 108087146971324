<template>
  <BaseModal 
    ref="uploadModal"
    :modalTitle="modalTitle"
    :labelButtonConfirm="labelButtonConfirm"
    :confirmDisabled="!files.length || confirmDisabled"
    @onConfirmButton="uploadDocument"
  > 
    <FotoButton 
      v-if="cameraAvailable && !disableFotoUpload" 
      isSecondary 
      edgeCorrection 
      highQuality 
      camera="back" 
      @file="onFoto" 
      class="mb-2" 
      style="float:left"
    />
    <MultiFileSelect
      v-if="!disableMultifile"
      :value="files"
      @input="dropFiles"
    />
    <BaseFileSelect
      v-else
      class="mb-2"
      @files="dropFiles" 
    />
    <div v-if="files && files.length">
      <hr>
      <div class="flex-layout">
        <div class="pill__container">
          <Pill :label="files[0].name || files[0].filename" type="default" showCloseIcon @removePill="dropFiles([])"></Pill>
        </div>          
      </div>
      <hr>
    </div>
    <slot></slot>
  </BaseModal>
</template>

<script>
import MultiFileSelect from '@/components/fileUpload/MultiFileSelect.vue';
import BaseFileSelect from '@/components/fileUpload/BaseFileSelect.vue';
import FotoButton from '@/components/fileUpload/FotoButton.vue';
import BaseModal from '@/components/core/BaseModal.vue'
import Pill from '@/components/core/Pill.vue';
import {uploadFileChunked} from '@/helpers/upload-helper'

export default {
  mixins: [],
  components: {
    BaseModal,
    FotoButton,
    BaseFileSelect,
    MultiFileSelect,
    Pill,
  },
  model: {
      prop: 'files',
      event: 'files',
  },
  props: {
    modalTitle: {
      type: String,
      default: 'Dokument hochladen'
    },
    labelButtonConfirm: {
      type: String,
      default: 'Speichern'
    },
    files: {
      type: Array,
      default: () => [],
      required: true,
    },
    disableMultifile: {
      type: Boolean,
      default: false,    
    },
    disableFotoUpload: {
      type: Boolean,
      default: false
    },
    confirmDisabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      cameraAvailable: false,
    }
  },
  computed: {
  },
  mounted() {
    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      navigator.mediaDevices.enumerateDevices()
      .then(devices => {
        this.cameraAvailable = devices.some(device => device.kind == 'videoinput');
      });
    }
  },
  methods: {
    uploadDocument() {
      
      this.files.map(file => {
        if (file instanceof File) {
          uploadFileChunked(file).then(tempFileId => {
            this.$emit('upload', {tempFileId})
          })
        } else {
          this.$emit('upload', {})
        }
      });

      // const promises = this.files.map(file => {
      //   return new Promise((resolve, reject) => {
      //     if (file instanceof File) {
      //       uploadFileChunked(file).then(tempFileId => {
      //         resolve({tempFileId})
      //       }).catch(() => reject())
      //     } else {
      //       resolve(file)
      //     }
      //   });
      // });

      // Promise.all(promises).then(documents => {
      //   this.$emit('upload', documents)
      // })
    },
    onFoto(file) {
      if (!this.disableMultifile)
        this.$emit('files', [...this.files, file])
      else
        this.$emit('files', [file])
    },
    dropFiles(files) {
      this.$emit('files', files)
    },
    open() {
      this.$refs.uploadModal.open()
    }
  },
}
</script>