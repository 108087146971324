<template>
  <div>
    <PageHeaderTitleNavigation title="Wertpapiersuche" />

    <WertpapierSearch 
        name="searchMenu" 
        @selected="selectedRows = $event"
        :respond="2" 
        type="I"
        withZ
        isSelectable
        :defaultOptions="defaultOptions"
    >

        <template v-slot:afterTable>
            <BaseButton class="mt-2" isSecondary label="Zurück" @click="goToLastAction()"/>
            <BaseButton class="mt-2" @click="onSelectionDone" :disabled="!selectedRows.length || loading">
                Markierungen übernehmen
            </BaseButton>
        </template>

    </WertpapierSearch>
  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import WERTPAPIERINFO_TYPES from "@/store/wertpapierinfo/types";
import FONDSFAVORITEN_TYPES from '@/store/fondsfavoriten/types';

import { mapGetters } from 'vuex';
import axios from 'axios';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import WertpapierSearch from '@/components/wertpapierinfo/WertpapierSearch.vue';
import { makeQueryParam } from '@/helpers/utils-helper';

const config = {
  defaultSpinner: true
};

export default {
    mixins: [],
    components: {
      OptionMenu,
      PageHeaderTitleNavigation,
      BaseButton,
      WertpapierSearch,
    },
    data() {
        return {
            defaultOptions: {},
            selectedRows: [],
            loading: false,
            modus: null,
        };
    },
    mounted() {
        this.modus = this.$route.meta.modus
        if (this.$route.query.fondsshop === 'true') {
            this.defaultOptions = {
                //lagerstelle ffb
                '1.1.06': {
                    disabled: true,
                    value: 'FFB',
                },
                //execution only
                '1.1.34': {
                    value: true,
                },
            }
        }
    },
    computed: {
        ...mapGetters({
            backToPreviousData: CORE_TYPES.GETTERS.GET_SAVE_BACK_TO_PREVIOUS_PAGE,
        }),
        lastBackToPreviousData() {
            return this.backToPreviousData && this.backToPreviousData.length && this.backToPreviousData[this.backToPreviousData.length - 1] || {} 
        },
    },
    methods: {
        goToLastAction() {
            this.$router.push({ path: this.lastBackToPreviousData.fullPath, query: { backAction: true}})
            this.$store.commit(CORE_TYPES.MUTATIONS.POP_SAVE_BACK_TO_PREVIOUS_PAGE)
        },
        onSelectionDone() {
            if (!this.selectedRows.length) {
                return
            }

            const fondsList = this.selectedRows.map(n => n.isin)

            if (this.modus === 'musterdepot') {
                this.addMusterdepotWPs(fondsList)
            } else {
                this.addFavoritenlisteWPs(fondsList)
            }
        },
        addFavoritenlisteWPs(fondsList) {
            this.loading = true

            this.$store.dispatch(FONDSFAVORITEN_TYPES.ACTIONS.ADD_FONDS, { isins: fondsList, favoritenlistId: this.$route.params.favoritenlisteId })
                .then(response => {
                    if (response && response.data) {
                        // reset wertpapiersearch
                        this.$store.commit(WERTPAPIERINFO_TYPES.MUTATIONS.RESET_ACT, 'searchMenu..');
                        this.$router.push({ path: `/intern/fondsfavoritenliste/wertpapiere/${response.data.id}`, query: { backAction: true}})
                        this.$store.commit(CORE_TYPES.MUTATIONS.POP_SAVE_BACK_TO_PREVIOUS_PAGE)
                    }
                }).finally(() => this.loading = false)
        },
        addMusterdepotWPs(fondsList) {
            const params = makeQueryParam({ fondsList, musterdepotId: this.$route.params.musterdepotId })

            this.loading = true

            axios.get(`${process.env.VUE_APP_API}/musterdepotService/add_wps?${params}`, config)
                .then(response => {
                    if (response && response.status === 200) {
                        // reset wertpapiersearch
                        this.$store.commit(WERTPAPIERINFO_TYPES.MUTATIONS.RESET_ACT, 'searchMenu..');
                        this.goToLastAction()
                    }
                }).finally(() => this.loading = false)
        },
    },
}
</script>