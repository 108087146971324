<template>
    <div>
        <PageHeaderTitleNavigation title="Regelmäßige Risikoprüfung Ergebnis" />

        <div class="box__container">
            <div  class="box__title">Regelmäßige Risikoprüfung Ergebnis</div>
            <div  class="mt-2">Hier finden Sie Ihre Kunden deren aktuelles Depot von den Angaben im aktuellsten unterschriebenen Anlegerprofil abweicht.</div>
            <div>Die Liste wird einmal täglich aktualisiert. Bitte beachten Sie, dass nur Kunden aufgeführt sind, bei denen in den Einstellungen die laufene Überprüfung der Geeignetheit bzw. der Risikoallokation aktiviert ist. </div>
            <div>Da das neuste unterschriebene Anlegerprofil zugrunde gelegt wird, verschwinden Kunden erst sobald das angepasste Anlegerprofil unterschrieben wurde.</div>
            <div class="layout__negative-margin--8 antrag-action-button__container">
                <BaseButton v-if="!isIntern" isPrimary @click="reload()">Aktualisieren</BaseButton>
                <InputToggleSwitch v-if="!isIntern" v-model="includeStruktur" label="Struktur anzeigen" @input="reload()" inLineLabel suppressValidationMessage />
                <InputToggleSwitch v-if="!isIntern" v-model="includeZielmarkt" label="Zielmarktfehler anzeigen" @input="reload()" inLineLabel suppressValidationMessage />
            </div>
        </div>
        <div class="box__container">
            <div v-if="rows && rows.length">
                <Table
                    :headers="headers"
                    :rows="rows"
                    :rowsPerPage="20"
                    hidePagination
                    rowId="risikopruefungErgebnis"
                    @click-kundenName="openCustomerNewTab"
                    @action-excel="openExcelFile"
                >
                    <template v-slot:bemerkung="row">
                        <InputField
                            :value="row['bemerkung']"
                            @change="saveBemerkung($event, row)"
                        />
                    </template>
                </Table>
            </div>
            <NoData v-else content="Keine Informationen" />
        </div>

    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BERICHTE_TYPES from '@/store/berichte/types';
import CORE_TYPES from '@/store/core/types';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import BaseButton from '@/components/core/BaseButton.vue';
import Table from "@/components/table2/Table.vue";
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import {TextColumn, DateColumn, ActionColumn, CurrencyColumn, SlotColumn, SimpleAction } from "@/components/table2/table_util.js";
import mixin from '@/mixins/limit/limit-mixin.js';
import InputField from '@/components/core/forms/InputField.vue';
import NoData from '@/components/core/NoData.vue';

export default {
    mixins: [mixin],
    components: {
        OptionMenu,
        PageHeaderTitleNavigation,
        BaseButton,
        Table,
        InputToggleSwitch,
        BaseModal,
        InputField,
        NoData,
    },
     data() {
        return {
            includeStruktur: '0',
            includeZielmarkt: '0',
        }
    },
    mounted() {
        this.reload();
    },
    computed: {
        ...mapGetters({
            list: BERICHTE_TYPES.GETTERS.RISIKOPRUEFUNG_LIST,
            isIntern: CORE_TYPES.GETTERS.IS_INTERN,
        }),
        headers() {
            const kundenLinkName = TextColumn('kundenName', 'Kunde');
            kundenLinkName.makeLink();
            
            const headers = { 
                lockedLeft: [
                    kundenLinkName,
                    TextColumn('kundennr', 'Kundennr').makeAlwaysVisible(),
                ],
                center: [
                    TextColumn('depotTyp', 'Depottyp'),
                    CurrencyColumn('depotWert', 'Depotwert', 2),
                    DateColumn('dateUeberschritten', 'besteht seit'),
                    DateColumn('dateStand', 'akt. Stand'),
                    TextColumn('anlegerprofil', 'Anlegerprofil'),
                    TextColumn('sriDepot', 'SRI Depot (SRI AP)').setColorFn((row) => { return row?.sriDepotError ? 'color-danger' : ''}),
                    TextColumn('sriMax', 'max. SRI'),
                    TextColumn('sriBereich', 'Bereich SRI').setColorFn((row) => { return row?.sriBereichError ? 'color-danger' : ''}),
                    TextColumn('zielmarkt', 'Zielmarkt').setColorFn((row) => { return row?.zielmarktError ? 'color-danger' : ''}),
                    TextColumn('nachhaltigkeit', 'Nachhaltigkeitspräferenz').setColorFn((row) => { return row?.nachhaltigkeitError ? 'color-danger' : ''}),
                    SlotColumn('bemerkung', 'Bemerkung', 200, 1),
                ],
                lockedRight: [
                    ActionColumn('actions', ''),
                ],
            };
            if ('1' === this.includeStruktur) {
                // insert after status
                headers.lockedLeft.splice(1, 0, [
                    TextColumn('maklerName', 'Vermitler'),
                    TextColumn('maklernr', 'Vermittlernr')]);
            }
            return headers;
        },
        rows() {
            return this.list?.map(elem => {
                return {
                    ...elem,
                    actions: [SimpleAction("excel", 'PhFile', "Zeile als Excel ausgeben")],
                }
            });
        },
    },
    methods: {
        openExcelFile(row) {
            this.$store.dispatch(BERICHTE_TYPES.ACTIONS.OPEN_RISIKOPRUEFUNG_XLS_FILE, row.id);
        },
        saveBemerkung(event, row = {}) {
            this.$store.dispatch(BERICHTE_TYPES.ACTIONS.SAVE_RISIKOPRUEFUNG_BEMERKUNG, { fehlerId: row.bemFehlerId, bemerkung: event});
        },
        reload() {
            this.$store.dispatch(BERICHTE_TYPES.ACTIONS.GET_RISIKOPRUEFUNG_LIST, {includeStruktur: this.isIntern ? '1' : this.includeStruktur, includeZielmarkt: this.includeZielmarkt});
        },
    },

}
</script>

<style>

</style>