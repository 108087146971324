<template >
  <div class="month-calendar__container">
    <div v-if="compgetMonthCalendarDataReady" id="monthcalendarapp">
      <div class="monthweekdays">
        <div
          class="monthweekday"
          v-for="(weekday, wkdidx) in weekdayLabels"
          :key="wkdidx"
        >
          <span class="monthweekday-label">{{weekday.label}}</span>
          <span class="monthweekday-label-shorter">{{weekday.labelShorter}}</span>
        </div>
      </div>
      <div ref="weeksEl" data-scroll-container class="weeks" :style="{maxHeight: 'var(--monthWeeksHeight)'}">
        <div class="week" v-for="(week,widx) in getShownDays" :key="widx">
          <CalendarMonthDayCell
            :class="{ selected: day.isSelected, notselected: !day.isSelected, weekend: (didx > 4) }"
            :daylabel="day.label"
            :istoday="day.isToday"
            :inmonth="day.inMonth"
            v-for="(day,didx) in week"
            :key="didx"
            :verticalLimitCount="verticalLimitCount"
            @editEvent="editEvent"
            @chooseDay="chooseMonthDay(week,day,didx)"
          />
        </div>
      </div>

      <BaseModal
        ref="modalRef">
        <Day></Day>
      </BaseModal>
    </div>
    <CalendarGhostLoading v-else type="month" :style="{maxHeight: 'var(--monthContainerHeight)', overflow: 'hidden',}" />
  </div>
</template>

<script>
import screenSize from "@/mixins/screen-size";
import dayjs from "dayjs";
import { mapGetters } from "vuex";
import CALENDAR_TYPES from "@/store/calendar/types";
import CalendarMonthDayCell from "@/components/calendar/CalendarMonthDayCell.vue";
import CalendarMixins from "@/components/calendar/CalendarMixins";
import Day from "@/components/calendar/Day.vue";
import BaseModal from "@/components/core/BaseModal.vue";
import CalendarGhostLoading from '@/components/calendar/CalendarGhostLoading.vue';

const MIN_CONTAINER_HEIGHT = 160;

const BOTTOM_OFFSET_SIZE = 28;
const MIN_MONTH_DAY_HEIGHT = 86;
const TITLE_HEIGHT = 32;
const MONTH_DAY_EVENT_HEIGHT = 24;

export default {
  props: {
    next: {
        type: Boolean,
        default: false,
    },
    prev: {
        type: Boolean,
        default: false,
    },
    current: {
        type: Boolean,
        default: false,
    },
    doRenew: {
        type: Boolean,
        default: false,
    },
    date: {
        type: Date,
        default: null,
    },
    searchTerm: {
      type: String,
      default: ''
    }
  },
  mixins: [screenSize, CalendarMixins],
  components: {
    CalendarMonthDayCell,
    Day,
    BaseModal,
    CalendarGhostLoading,
  },
  data() {
    return {
      thisday: {},
      weekdayLabels: [],
      showDayModal: false,
      verticalLimitCount: 3,
    };
  },

  computed: {
    ...mapGetters({
      getShownDays: CALENDAR_TYPES.GETTERS.GET_SHOWN_DAYS,
      compgetMonthCalendarData: CALENDAR_TYPES.GETTERS.GET_CALENDAR_DATA,
      compgetMonthCalendarDataReady: CALENDAR_TYPES.GETTERS.GET_CALENDAR_DATA_READY
    }),
    getWeekofYearTitle() {
      if (this.width > 800) return "Woche Nr.";
      else return "Nr.";
    },
    header() {
      return {
        month: dayjs(this.thisday).month(),
        year: dayjs(this.thisday).year(),
        label: dayjs(this.thisday).format("MMMM")
      };
    }
  },

  watch: {
    compgetMonthCalendarDataReady() {
      setTimeout(() => this.calculateSizes());
    },
    next(v) {
      if (v) {
        this.moveNextMonth();
      }
    },
    prev(v) {
      if (v) {
        this.movePreviousMonth();
      }
    },
    current(v) {
      if (v) {
        this.moveThisMonth();
        this.$emit('setNextPrev', true);
        this.emitTitle();
      }
    },
    doRenew(v) {
      if (v) {
        this.renewView();
        this.$emit('setNextPrev', true);
      }
    },
    date(value) {
      this.setMonth(value);
    },
    thisday() {
      this.$emit('dateChanged', this.thisday?.toDate());
    },
  },

  beforeMount() {
    this.$store.commit(CALENDAR_TYPES.MUTATIONS.RENEW_CALENDAR_DATA);
  },
  mounted() {
    this.calculateSizes();
    window.addEventListener('resize', this.calculateSizes);

    this.thisday = dayjs(this.date);
    this.getMonthCalendarData(this.thisday, this.searchTerm);
    this.month = this.thisday.month();
    this.year = this.thisday.year();
    this.setWeekdayLabels();
    this.setMonthShownDays(this.thisday);

    this.emitTitle();
  },
  destroyed() {
    window.removeEventListener('resize', this.calculateSizes);
  },
  methods: {
    calculateSizes() {
      const rootEl = this.$el;
      const { weeksEl, } = this.$refs;

      let containerHeight = null, weeksHeight = null;

      if(rootEl) {
        const containerHeightCalc = window.innerHeight - rootEl.offsetTop - BOTTOM_OFFSET_SIZE;
        containerHeight = containerHeightCalc >= MIN_CONTAINER_HEIGHT ? containerHeightCalc : MIN_CONTAINER_HEIGHT;
        rootEl.style.setProperty('--monthContainerHeight', `${containerHeight}px`);
      }

      if(weeksEl) {
        const weeksHeightCalc = window.innerHeight - weeksEl.offsetTop - BOTTOM_OFFSET_SIZE || 0;
        weeksHeight = weeksHeightCalc >= MIN_CONTAINER_HEIGHT ? weeksHeightCalc : MIN_CONTAINER_HEIGHT;
        rootEl.style.setProperty('--monthWeeksHeight', `${weeksHeight}px`);
      }

      // calculate vertical limit
      if(weeksHeight) {
        const shownDaysSize = this.getShownDays.length;
        const monthDayHeight = weeksHeight / shownDaysSize;
        const monthDayHeightChecked = monthDayHeight >= MIN_MONTH_DAY_HEIGHT ? monthDayHeight : MIN_MONTH_DAY_HEIGHT;
        const monthCalendarAppEl = document.querySelector('#monthcalendarapp');
        monthCalendarAppEl.style.setProperty('--monthDayHeight', `${monthDayHeightChecked}px`);

        this.verticalLimitCount = Math.floor((monthDayHeightChecked - TITLE_HEIGHT) / MONTH_DAY_EVENT_HEIGHT) - 1; // minus the '...weitere' item action
      }
    },
    editEvent(e) {
      this.$emit('editEvent', e);
    },
    emitTitle() {
      this.$emit('title', `${this.header.label} ${this.header.year}`);
      this.$emit('titleShortening', `${this.header.label} ${this.header.year}`);
    },
    renewView() {
      setTimeout(() => {
        this.$store.commit(CALENDAR_TYPES.MUTATIONS.RENEW_CALENDAR_DATA);
        this.getMonthCalendarData(this.thisday, this.searchTerm);
      }, 300);
    },
    setWeekdayLabels() {
      for (let wl = 0; wl < 7; wl++) {
        const weekday = dayjs().weekday(wl);
        this.weekdayLabels.push({
          label: weekday.format('dddd'),
          labelShorter: weekday.format('dd'),
        });
      }
    },
    setMonthShownDays(thisday) {
      let alldays = [];
      let weeks = [];
      var last = dayjs(thisday).daysInMonth();
      let firstday = dayjs(thisday)
        .date(1)
        .weekday(0);
      let lastday = dayjs(thisday)
        .date(last)
        .weekday(6);
      for (let dd = 0; dd < 44; dd++) {
        let nextday = firstday.add(dd, "day");
        if (nextday.isSameOrBefore(lastday)) {
          alldays.push({
            label: nextday.format("D.MM"),
            day: nextday,
            inMonth: nextday.month() === dayjs(thisday).month(),
            isToday: dayjs().isSame(nextday, "date"),
            isSelected: false
          });
        }
      }
      for (let i = 0; i < alldays.length; i += 7) {
        let tempweek = alldays.slice(i, i + 7);
        weeks.push(tempweek);
      }
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_SHOWN_DAYS, weeks);
    },

    moveThisMonth() {
      this.thisday = dayjs();
      this.setMonthShownDays(this.thisday);
      this.getMonthCalendarData(this.thisday, this.searchTerm);
    },
    moveNextMonth() {
      this.thisday = dayjs(this.thisday).add(1, "month");
      this.setMonthShownDays(this.thisday);
      this.getMonthCalendarData(this.thisday, this.searchTerm);
      this.$emit('setNextPrev', true);
      this.emitTitle();
    },
    movePreviousMonth() {
      this.thisday = dayjs(this.thisday).subtract(1, "month");
      this.setMonthShownDays(this.thisday);
      this.getMonthCalendarData(this.thisday, this.searchTerm);
      this.$emit('setNextPrev', true);
      this.emitTitle();
    },
    moveNextYear() {
      this.thisday = dayjs(this.thisday).add(1, "year");
      this.setMonthShownDays(this.thisday);
      this.getMonthCalendarData(this.thisday, this.searchTerm);
    },
    movePreviousYear() {
      this.thisday = dayjs(this.thisday).subtract(1, "year");
      this.setMonthShownDays(this.thisday);
      this.getMonthCalendarData(this.thisday, this.searchTerm);
    },
    setMonth(date) {
      if(!date) return;

      const desiredDate = dayjs(date);
      const desiredDateMonth = dayjs(date).month();
      const currentMonth = this.thisday.month();

      const desiredDateYear = dayjs(date).year();
      const currentYear = this.thisday.year();

      if(desiredDateMonth !== currentMonth || desiredDateYear !== currentYear) { // TODO check year also
        this.thisday = desiredDate;
        this.setMonthShownDays(this.thisday);
        this.getMonthCalendarData(this.thisday, this.searchTerm);

        this.emitTitle();
      }
    },
    chooseMonthDay(week, day, dindex) {
      this.unselectcells(day);
      this.makeShownHours(Number(0), Number(23));
      this.$store.dispatch(CALENDAR_TYPES.ACTIONS.CREATE_WEEK_CALENDAR_DATA, {
        fromDate: week[0].day.format("D.MM.YYYY"),
        toDate: week[6].day.format("D.MM.YYYY")
      });

      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_DAY_ELEMENTS, {
        day: day,
        dayIndex: dindex
      });
      this.$router.push('daycalendar');
    },

    unselectcells(thisday) {
      for (let wi = 0; wi < this.getShownDays.length; wi++) {
        let thisweek = this.getShownDays[wi];
        for (let di = 0; di < thisweek.length; di++) {
          thisweek[di].isSelected = false;
        }
      }
      thisday.isSelected = true;
    },

    getWeekofYear(sentday) {
      if (sentday !== undefined) return dayjs(sentday.day).isoWeek();
    },
    hideModal() {
      this.showDayModal = false;
    }
  }
};
</script>

<style scoped>
#monthcalendarapp,
#monthcalendarapp * {
  box-sizing: border-box;
}

#monthcalendarapp {
  --headerPadding: 0.5rem 1rem;
  --headerBorderWidth: 1px;
  --headerBorderStyle: solid;
  --weekdayPadding: 0.4rem 0;
  --weekdayBorderWidth: 1px;
  --weekdayBorderStyle: solid;
  --dayBorder: solid 1px var(--color-primary);
  --dayWidth: 14.2%;
  --monthDayHeight: 8rem;
  --borderColor: #cccccc;
}

.weeks {
  overflow-y: auto;
}

.mday {
  width: var(--dayWidth);
  height: var(--monthDayHeight);
  display: flex;
  justify-content: center;
  flex: 1 0 auto;
}

.title {
  flex-grow: 1;
  font-size: 1.2rem;
  text-align: center;
}

.monthweekdays {
  display: flex;
  flex: auto;
}

.monthweekday {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--weekdayPadding);
}
.monthweekday {
  width: var(--dayWidth);
}
.monthweekday-label-shorter {
  display: none !important;
}
@media only screen and (max-width: 850px) {
  .monthweekday-label {
    display: none !important;
  }
  .monthweekday-label-shorter {
    display: block !important;
  }
}

.week {
  display: flex;
}

.selected {
  border: solid 1px var(--color-primary);
}
.notselected {
  border: solid 1px var(--borderColor);
}

.mday.weekend {
  background: var(--color-background);
}
</style>