var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DragnDropArea",
    {
      staticClass: "depotnummer-anfordern-button-component mb-4",
      attrs: { hoverText: "Hochladen" },
      on: { files: _vm.onFileChange }
    },
    [
      _c(
        "BaseFileSelect",
        {
          attrs: { accept: ".pdf", disabled: _vm.disabled },
          on: { files: _vm.onFileChange }
        },
        [_vm._v(" Hochladen ")]
      ),
      _c("span", [_vm._v(_vm._s(_vm.filename))]),
      _c("br"),
      _c("span", [
        _vm._v(
          _vm._s(
            !_vm.config.sorgrechtsnachweis
              ? " Die Datei wird hochgeladen, wenn Sie zum nächsten Schritt wechseln"
              : _vm.antraegeData[_vm.antragId].PARAM_FILE_SORGERECHTSNACHWEIS
          ) + " "
        )
      ]),
      _c("br")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }