<template>
  <div>
    <PageHeaderTitleNavigation :title="title" :defaultMenu="optionsMenu" :showBackButton="false"/>

    <div class="cards__container layout__negative-margin--8" v-if="eingegangeneDokumente">
      <div class="box__container cards__item" v-for="eingegangenesDokument in eingegangeneDokumente.eingegangeneDokumente">
        <div class="cards__item-header">
          <div class="box__title breakable-words">
            <DownloadLink downloadServicePath="/dokumentEingangData"
              :title="`${eingegangenesDokument.subject} (${eingegangenesDokument.id})`"
              :queryParams="{ id: eingegangenesDokument.id }" />
          </div>
          <div >

            <dl class="key-value--item">
                        <dt class="value--item-label"> Status: </dt>
                        <dd class="value--item-value">{{ eingegangenesDokument.status.label }}</dd>
                    </dl>            
                    <dl class="key-value--item">
                        <dt class="value--item-label"> Zuletzt geändert am: </dt>
                        <dd class="value--item-value">
                          {{ eingegangenesDokument.dateUpdated ? eingegangenesDokument.dateUpdated : eingegangenesDokument.d }}</dd>
                    </dl>
                    <dl class="key-value--item">
                        <dt class="value--item-label"> Art des Dokuments: </dt>
                        <dd class="value--item-value">
                          {{ eingegangenesDokument.documentType ? eingegangenesDokument.documentType : 'Unbekannt' }}</dd>
                    </dl>
                </div>
        </div>
        <div>
          <img v-if="signaturesForAuthor && signaturesForAuthor[eingegangenesDokument.id]" :src="signaturesForAuthor[eingegangenesDokument.id].img" class="signature-img" />
        </div>
        <InputTextArea label="Bemerkung" v-model="eingegangenesDokument.bemerkung" @change="POST_BEMERKUNG(eingegangenesDokument)"/>
        <div class="action-button-list mt-3">
          <BaseButton label="Ablehnen" @click="POST_STATUS(eingegangenesDokument, 'ABGELEHNT')" isSecondary/>
          <BaseButton label="Annehmen" @click="POST_STATUS(eingegangenesDokument, 'GESENDET')" />
        </div>
      </div>
    </div>
    <div v-else>
      <NoData />
    </div>
  </div>
</template>
<script>
import BaseModal from '@/components/core/BaseModal.vue';
import { mapGetters } from "vuex";
import EINGEGANGENE_DOKUMENTE_TYPES from '@/store/eingegangeneDokumente/types';
import V_P_TYPES from "@/store/versandProtokolle/types";
import CORE_TYPES from '@/store/core/types';
import NoData from "@/components/core/NoData.vue";
import BaseButton from '@/components/core/BaseButton.vue';
import InputCheckboxItem from "@/components/core/forms/checkbox/InputCheckBoxItem.vue";
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import FormDivider from '@/components/core/forms/FormDivider.vue';
import DownloadLink from '@/components/core/download/DownloadLink.vue';
import InputToggleSwitch from '../core/forms/InputToggleSwitch.vue';
import { SIGNATURE_AUTHOR_TYPES } from '@/components/suitabilityTest/SuitabilityTest.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';


export default {

  components: {
    BaseModal,
    NoData,
    BaseButton,
    InputCheckboxItem,
    FormDivider,
    DownloadLink,
    InputToggleSwitch,
    InputTextArea,
    PageHeaderTitleNavigation
  },
  data: function () {
    return {
      fehler: [],
      title: "Eingegangene Dokumente",
      signatureAuthor: SIGNATURE_AUTHOR_TYPES.ALLE,
    }
  },
  computed: {
    ...mapGetters({
      API_ADDRESS: CORE_TYPES.GETTERS.API_ADDRESS,
      eingegangeneDokumente: EINGEGANGENE_DOKUMENTE_TYPES.GETTERS.EINGEGANGENES_DOKUMENT_RESULT,
      signatures: EINGEGANGENE_DOKUMENTE_TYPES.GETTERS.SIGNATURES,
    }),
    signaturesForAuthor() {
      return this.signatures[this.signatureAuthor.key];
    },
    secondDepotinhaber() {
      return this.eingegangeneDokumente ? this.eingegangeneDokumente.kunde.additionalPersons.find(p => {
            return p.relationship && p.relationship.extraDepotOwner;
          }) : null;
    },
    optionsMenu() {
      return Object.keys(SIGNATURE_AUTHOR_TYPES).filter(type => {
        return type != SIGNATURE_AUTHOR_TYPES.DEPOTINHABER_2.key || this.secondDepotinhaber; //Removes DEPOTINHABER_2 option from the menu, if there is no 2. Depotinhaber
      })
      .map(key => {
        return {
          action: () => {
            this.signatureAuthor = SIGNATURE_AUTHOR_TYPES[key];
          },
          label: SIGNATURE_AUTHOR_TYPES[key].label,
          textBold: this.signatureAuthor === SIGNATURE_AUTHOR_TYPES[key],
        }
      })
    }
  },
  watch: {
    signatureAuthor: {
      handler() {
        this.onChangeSignatureAuthor();
      },
      immediate: true
    }
  },
  methods: {
    POST_BEMERKUNG(faxEingang) { 
      const bemerkungPojo = {
          id: faxEingang.id,
          bemerkung: {
            text: faxEingang.bemerkung,
          }
      }
      this.$store.dispatch(V_P_TYPES.ACTIONS.SET_VP_BEMERKUNG, bemerkungPojo);

      },
    POST_STATUS(faxEingang, status) {
      if(status === 'GESENDET') {
          this.$store.dispatch(V_P_TYPES.ACTIONS.SEND, { eingangId: faxEingang.id }).then(() => {
            faxEingang.status = {pillColor: 'success', status: 'OK  ', label: 'OK'};
          });
        } else if(status ==='ABGELEHNT') {
         this.$store.dispatch(V_P_TYPES.ACTIONS.TOERROR, faxEingang.id ).then(() => {
            faxEingang.status = {pillColor: 'warning', status: 'ABGELEHNT', label: 'Fehler'};
         })
        }
    },
    onChangeSignatureAuthor() {
      this.$emit('signatureAuthor', this.signatureAuthor)
    },
  }
}
</script>
<!--also uses style from parent class EingegangeneDokumentePreufung-->
<style scoped>

.cards__item-header {
  display: flex;
  justify-content: left;
  flex-direction:column;
  margin-bottom: 0;
}

.signature-img {
  max-height: 350px;
  cursor: pointer;
}
.value--item-label {
    width: 10%;
    min-width: 10%;
}
</style>