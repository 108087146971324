<template>
  <BaseToolbarButton
    isClear
    @click="$emit('input', !value)"
    :disabled="disabled"
    :active="value"
    :icon="icon"
    title="Platzhalter"
    :showLabel="false"
    content="Platzhalter"
    v-tippy="{ arrow: true }"
  >
  </BaseToolbarButton>
</template>

<script>
import BaseToolbarButton from "@/components/html-editor/base-components/BaseToolbarButton.vue";
import { PhPlaceholder } from "phosphor-vue";

export default {
  components: {
    BaseToolbarButton,
  },
  computed: {
    icon() {
      return PhPlaceholder;
    },
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>