export const MUTATION_PREFIX = 'FC_CONFIG: ';
export const ACTIONS_PREFIX = 'FC_CONFIG_ACTIONS_';
export const GETTERS_PREFIX = 'FC_CONFIG_GETTERS_';

export default {
  MUTATIONS: {
    ADD_FC_CONFIG_LOADING: MUTATION_PREFIX + 'ADD_FC_CONFIG_LOADING',
    REMOVE_FC_CONFIG_LOADING: MUTATION_PREFIX + 'REMOVE_FC_CONFIG_LOADING',
    LOAD_CONFIG_SUCCESS: MUTATION_PREFIX + 'LOAD_CONFIG_SUCCESS',
    LOAD_CONFIG_BY_USER_LEVEL_SUCCESS: MUTATION_PREFIX + 'LOAD_CONFIG_BY_USER_LEVEL_SUCCESS',
    RESET_ALL_LOADED_CONFIG_BY_USER_LEVEL: MUTATION_PREFIX + 'RESET_ALL_LOADED_CONFIG_BY_USER_LEVEL',
    RESET_LOADED_CONFIG_BY_USER_LEVEL: MUTATION_PREFIX + 'RESET_LOADED_CONFIG_BY_USER_LEVEL',
    DELETE_CONFIG_SUCCESS: MUTATION_PREFIX + 'DELETE_CONFIG_SUCCESS',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
  },
  ACTIONS: {
    SAVE_ONLY: ACTIONS_PREFIX + 'SAVE_ONLY',
    SAVE_FC_CONFIG: ACTIONS_PREFIX + 'SAVE_FC_CONFIG',
    LOAD_FC_CONFIG: ACTIONS_PREFIX + 'LOAD_FC_CONFIG',
    LOAD_FC_CONFIG_BY_USER_LEVEL: ACTIONS_PREFIX + 'LOAD_FC_CONFIG_BY_USER_LEVEL',
    DELETE_ONLY: ACTIONS_PREFIX + 'DELETE_ONLY',
    DELETE_FC_CONFIG: ACTIONS_PREFIX + 'DELETE_FC_CONFIG',

    SAVE_AS_INTERN: ACTIONS_PREFIX + 'SAVE_AS_INTERN',
    DELETE_AS_INTERN: ACTIONS_PREFIX + 'DELETE_AS_INTERN',
  },
  GETTERS: {
    IS_FC_CONFIG_LOADING: GETTERS_PREFIX + 'IS_FC_CONFIG_LOADING',
    GET_FC_CONFIG: GETTERS_PREFIX + 'GET_FC_CONFIG',
    GET_FC_CONFIG_TABLE_COLUMN: GETTERS_PREFIX + 'GET_FC_CONFIG_TABLE_COLUMN',
    GET_FC_CONFIG_ADD_PARAMETER: GETTERS_PREFIX + 'GET_FC_CONFIG_ADD_PARAMETER',
    GET_FC_CONFIG_ADVANCED_PARAMETER: GETTERS_PREFIX + 'GET_FC_CONFIG_ADVANCED_PARAMETER',
    GET_FC_CONFIG_LAST_VISUALIZED_EVENT_DATE: GETTERS_PREFIX + 'GET_FC_CONFIG_LAST_VISUALIZED_EVENT_DATE',
    GET_FC_CONFIG_OPTION_MENU: GETTERS_PREFIX + 'GET_FC_CONFIG_OPTION_MENU',
    GET_FC_CONFIG_DASHBOARD_PANEL: GETTERS_PREFIX + 'GET_FC_CONFIG_DASHBOARD_PANEL',
    GET_FC_CONFIG_BROKER_DASHBOARD: GETTERS_PREFIX + 'GET_FC_CONFIG_BROKER_DASHBOARD',
    GET_FC_CONFIG_COLLAPSED_TREE: GETTERS_PREFIX + 'GET_FC_CONFIG_COLLAPSED_TREE',

    GET_FC_CONFIG_BY_USER_LEVEL: GETTERS_PREFIX + 'GET_FC_CONFIG_BY_USER_LEVEL',
    IS_CONFIG_BY_USER_LEVEL_LOADED: GETTERS_PREFIX + 'IS_CONFIG_BY_USER_LEVEL_LOADED',

    GET_FC_CONFIG_ALLOW_CONFIG_GLOBAL: GETTERS_PREFIX + 'GET_FC_CONFIG_ALLOW_CONFIG_GLOBAL',
    GET_FC_CONFIG_USER_LEVEL_VALUES: GETTERS_PREFIX + 'GET_FC_CONFIG_USER_LEVEL_VALUES',
    GET_FC_CONFIG_USER_LEVEL_DEFAULT: GETTERS_PREFIX + 'GET_FC_CONFIG_USER_LEVEL_DEFAULT',

    IS_CONFIGURATION_READ_ONLY: GETTERS_PREFIX + 'IS_CONFIGURATION_READ_ONLY',

    GET_FC_CONFIG_CARDS_OVERVIEW: GETTERS_PREFIX + 'GET_FC_CONFIG_CARDS_OVERVIEW',
    GET_FC_CONFIG_RESIZABLE_MODAL: GETTERS_PREFIX + 'GET_FC_CONFIG_RESIZABLE_MODAL',
  },
}
