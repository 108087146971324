var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "optionMenuEditModal",
    attrs: { size: "md", showDefaultButtons: false, actions: _vm.modalActions },
    on: {
      onCloseButton: function($event) {
        return _vm.onSaveConfig()
      },
      "action-RESET": function($event) {
        _vm.onRestoreDefault()
        _vm.close()
      },
      "action-SAVE": function($event) {
        _vm.onSaveConfig()
        _vm.close()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "modalTitle",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "option-menu-edit-modal--title-container" },
              [
                _c("div", { staticClass: "option-menu-edit-modal--title" }, [
                  _vm._v("Optionen-Liste bearbeiten")
                ]),
                _c("OptionMenuHelpDropdown", {
                  staticClass: "option-menu-edit-modal--help-button"
                })
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "modalHeaderBottom",
        fn: function() {
          return [
            _vm.tabList.length > 1
              ? _c("Tabs", {
                  staticClass: "mt-1",
                  attrs: {
                    tabList: _vm.tabList,
                    selected: _vm.selectedUserLevel,
                    disabled: _vm.loading
                  },
                  on: {
                    tabSelected: function($event) {
                      return _vm.selectUserLevel($event.key)
                    }
                  }
                })
              : _vm._e()
          ]
        },
        proxy: true
      },
      {
        key: "default",
        fn: function() {
          return [
            _vm.loading
              ? _c("OptionMenuGhostLoading")
              : _c("OptionMenuConfig", {
                  key: _vm.selectedUserLevel,
                  attrs: {
                    configContext: _vm.configContext,
                    configId: _vm.configId,
                    defaultMenu: _vm.defaultMenu,
                    optionMenu: _vm.optionMenu,
                    userLevel: _vm.selectedUserLevel,
                    currentPath: _vm.configId
                  }
                })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }