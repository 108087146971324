var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "funds-chart__container" },
    [
      _c("PageHeaderTitleNavigation", { attrs: { title: "Marktübersicht" } }),
      _c("Marktubersicht", { staticClass: "box__container" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }