import DEPOTPOSITIONENEDIT_TYPES from './types';
import Vue from 'vue';

export default {
  [DEPOTPOSITIONENEDIT_TYPES.MUTATIONS.FILTER](state, payload) {
    state.filter = payload;
  },
  [DEPOTPOSITIONENEDIT_TYPES.MUTATIONS.PARAM](state, payload) {
    state.param = payload;
  },
  [DEPOTPOSITIONENEDIT_TYPES.MUTATIONS.HINWEIS](state, payload) {
    state.hinweise = Object.assign({}, state.hinweise, payload);
  },
  [DEPOTPOSITIONENEDIT_TYPES.MUTATIONS.BGS](state, payload) {
    if (payload.bgsnr) {
      let values = payload.values|| {};
      Vue.set(state, 'bgs', Object.assign({}, payload, { values: values, selectedStepKey: 'POSITION'}) );
    } else if (payload.fund) {
      const values = JSON.parse(JSON.stringify(state.bgs.values));
      if (payload.fund.isin){
        values.inputisin = payload.fund.isin;
      }
      values.inputwertpapier = payload.fund.fondsname;
      values.inputwkn = payload.fund.wkn;
      values.gesellschaft = payload.fund.gesellschaft;
      values.input_schwerpunkt = payload.fund.schwerpunkt;
      Vue.set(state.bgs, 'values', values);
    } else {
      if (payload.values) {
        Vue.set(state.bgs, 'values', payload.values);
      }
      if (payload.combos) {
        Vue.set(state.bgs, 'combos', payload.combos);
      }
      if (payload.fundSuche) {
        if (payload.fundSuche.bgsnr == state.bgs.bgsnr ) {
          payload.fundSuche.bgsnr = undefined;
          state.bgs.values = Object.assign({}, state.bgs.values, payload.fundSuche);
        }
      }
    }
    if (process.env.VUE_APP_USER === 'hannes') console.error('BGS', {payload: payload && JSON.parse(JSON.stringify(payload))}, {state: state.bgs && JSON.parse(JSON.stringify(state.bgs))});
  },
  [DEPOTPOSITIONENEDIT_TYPES.MUTATIONS.PATCH_BEMERKUNG_SUCCESS](state, payload) {
    const index = state.tableData?.[payload.pageIndex || 0].findIndex((row) => row.positionsnr == payload.bgsNr);
    if (index >= 0) {
      Vue.set(state.tableData[payload.pageIndex || 0][index], 'bemerkung', payload.bemerkung );
    }
  },

  [DEPOTPOSITIONENEDIT_TYPES.MUTATIONS.UPDATE_PAGE_INDEX](state, pageIndex) {
    Vue.set(state, 'pageIndex', pageIndex || 0);
  },

  [DEPOTPOSITIONENEDIT_TYPES.MUTATIONS.TABLE_DATA](state, payload) {
    Vue.set(state, 'totalRows', payload?.totalRows || 0);
        if (state.pageIndex === 0) {
            Vue.set(state, 'tableData', {[state.pageIndex]: payload?.records || []});
        } else {
            Vue.set(state.tableData, state.pageIndex, payload?.records || []);
        }
  },

  [DEPOTPOSITIONENEDIT_TYPES.MUTATIONS.TRANSAKTIONEN_UEBERTRAGEN_DATA_SUCCESS](state, payload) {
    Vue.set(state, 'transaktUebertragenData', payload || {});
  },

}