<template>
  <PageHeaderTitleNavigation 
    :defaultMenu="customOptionMenu" 
    :isSticky="isSticky"
    :actions="headerActions" 
    @action-ADD-TASK="$emit('addTask')" 
    @action-ADD-APPOINTMENT="$emit('addAppointment')" 
  >
    <template #title>Terminkalender</template>
    <template #subtitle>
      <div class="d-flex justify-content-center align-items-center">
        <div class="d-flex justify-content-center align-items-center">
          <button type="button" :disabled="disabled" class="btn-clear clickable" @click="$emit('prev')"><PhCaretLeft size="20" /></button>
          <button type="button" :disabled="disabled" class="btn-clear mx-1 clickable" @click="$emit('current')">heute</button>
          <button type="button" :disabled="disabled" class="btn-clear clickable" @click="$emit('next')"><PhCaretRight size="20" /></button>
        </div>
        <div class="d-flex justify-content-center align-items-center ml-2">
          <span class="calendar-navigation__title">{{title}}</span>
          <span class="calendar-navigation__title-shortening">{{titleShortening || title}}</span>
          <span v-if="$slots.calendarDatePicker" class="calendar-navigation__date-picker-dropdown ml-1">
            <slot name="calendarDatePicker" />
          </span>
        </div>
      </div>
    </template>
  </PageHeaderTitleNavigation>
</template>

<script>
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { PhCaretRight, PhCaretLeft, } from 'phosphor-vue';

import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import { TabMenu, } from '@/menu/menu-utils';

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    titleShortening: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    hideMenu: {
      type: Boolean,
      default: false,
    },
    isSticky: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    customOptionMenu() {
      if(this.hideMenu) return [];

      return [
        TabMenu('/communication/calendar/daycalendar', 'Tag'),
        TabMenu('/communication/calendar/weekcalendar', 'Woche'),
        TabMenu('/communication/calendar/arbeitswoche', 'Arbeitswoche'),
        TabMenu('/communication/calendar/monatcalendar', 'Monat'),
      ]
    },
    headerActions() {
      return [
        PageHeaderSimpleAction('ADD-APPOINTMENT', 'Neuer Termin'),
        PageHeaderSimpleAction('ADD-TASK', 'Neue Aufgabe'),
      ];
    },
  },
  methods: {
    isActive(type) {
      return this.type === type;
    },
  },
  components: {
    PageHeaderTitleNavigation,
    PhCaretRight,
    PhCaretLeft,
  },
}
</script>

<style scoped>
.calendar-navigation__title-shortening {
  display: none;
}
.calendar-navigation__date-picker-dropdown {
  visibility: hidden;
  pointer-events: none;
}

@media only screen and (min-width: 768px) and (max-width: 1152px), only screen and (max-width: 550px) {
  .calendar-navigation__title {
    display: none;
  }
  .calendar-navigation__title-shortening {
    display: inherit;
  }
  .calendar-navigation__date-picker-dropdown {
    visibility: visible;
    pointer-events: all;
  }
}
</style>
