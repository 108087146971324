<template>
    <div class="contact_container">
        <div class="contact_wrapper">
            <div class="container">
                <div class="text">
                    <div class="banner">{{ this.homeScreenText }}</div>
                    <p class="app_id_display">{{ this.app_id_display }}</p>
                    <div class="banner">Kontolöschung</div>
                </div>
                <div class="banner_image">
                    <img class="app_image" :src=this.app_image alt="App Icon">
                </div>
            </div>

            <hr>

            <div class="deletion_text">
                <h3>Wie kann ich mein Konto löschen lassen?</h3>
                Wenn Sie Ihr Konto löschen lassen wollen, schreiben Sie bitte eine eine formlose E-Mail an <a :href="'mailto:' + this.email">{{ this.email }}</a> und bitten darin um die Löschung Ihrer Daten. Wir werden uns dann umgehend mit Ihnen in Verbindung setzen, um zu prüfen, dass Sie für diesen Account berechtigt sind und dann Ihre Daten gemäß der gesetzlichen Vorgaben löschen.

                <h3>Welche Daten werden gelöscht?</h3>
                Sollten noch keine Geschäftsbeziehung zustande gekommen sein, werden wir nach Aufforderung alle Ihre Daten vollständig löschen.

                Wenn bereits eine Geschäftsbeziehung zustande gekommen ist müssen wir alle Daten der Beratung gemäß gesetzlicher Vorgaben [§199 (3) 1. BGB, Art. 17 (3) e) DSGVO] 10 Jahre nach Beendigung der Geschäftsbeziehung aufbewahren. Vor Ablauf dieser gesetzlichen Frist können keine Daten gelöscht werden, das Konto kann lediglich deaktiviert werden. Nach Ablauf der 10 Jahresfrist werden Ihre Daten ohne weitere Aufforderung automatisch gelöscht.
                
                <h3>Welche Daten werden aufbewahrt?</h3>
                Sollten noch keine Geschäftsbeziehung zustande gekommen sein, werden wir keine Daten von Ihnen aufbewahren

                Wenn bereits eine Geschäftsbeziehung zustande gekommen ist müssen wir alle Daten der Beratung gemäß gesetzlicher Vorgaben [§199 (3) 1. BGB, Art. 17 (3) e) DSGVO] zehn Jahre nach Beendigung der Geschäftsbeziehung aufbewahren. Bis zum Ablauf dieser gesetzlichen Frist werden alle Daten Ihres Kontos aufbewahrt.

                <h3>Aufbewahrungsdauer</h3>
                Sollte bereits eine Geschäftsbeziehung zustande gekommen sein werden Ihre Daten bis zehn Jahre nach Beendigung der Geschäftsbeziehung aufbewahrt.
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

// !!IMPORTANT!!
// open with query parameters
// platform : android|ios (not case sensitive)
// app_id : app_id of the app
// example: http://localhost:10880/accountloeschung?platform=android&app_id=de.meine-vermoegensmanager.MobileMscAbatus
// If the parameters are not in the URL, you will be redirected to the home page

export default {
    data(){
        return{
            app_id: "",
            platform: "",
            email: "",
            homeScreenText: "",
            app_image: "",
            app_id_display: "",
            apiConfig:{
                disableDefaultLog: true,
                disableDefaultErrorMessage: true,
                noCheckToken: true,
            }
        };
    },

    created(){
        if(this.$route.query.platform == null || this.$route.query.app_id == null)
            this.$router.push({ path: '/home' }).catch();
    },

    mounted(){
        this.platform = this.$route.query.platform;
        this.app_id = this.$route.query.app_id;

        this.getTextInformation();
        this.getAppIcon();
    },
    methods: {

        /**
         * Get the text information from the API and set it to corresponding variables
         * @returns {Promise<void>} Nothing
         */
        async getTextInformation(){
            let queryURL = process.env.VUE_APP_API + "/dataDeletionRequest/get_deletion_request_page_info?app_id=" + this.app_id + "&platform=" + this.platform;
            axios.get(queryURL, this.apiConfig).then(response => {
              this.homeScreenText = response.data.homeScreenText;
              this.email = response.data.email;
              this.app_id_display = response.data.app_id;
            }).catch(error => {
              console.log(error);
            });
        },

        /**
         * Get the app icon and set it to the app_image variable
         * @returns {Promise<void>} Nothing
         */
        async getAppIcon(){
            let queryImageURL = process.env.VUE_APP_API + "/dataDeletionRequest/get_app_icon?app_id=" + this.app_id;
            axios.get(queryImageURL, this.apiConfig).then(response => {
              this.app_image = 'data:image/png;base64,' + response.data;
            }).catch(error => {
              console.log(error);
            });
        },

  }
}

</script>

<style  scoped>

.contact_container {
  max-width: 1200px;
  margin: 0 auto;
  padding-left: 1em;
  padding-right: 1em;
}

.banner {
  margin-top: 2em;
  font-size: 26px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  text-align: left;
}

.deletion_text {
  text-align: left;
  padding-bottom: 1em;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text {
  width: 50%;
}

.banner_image {
  display: flex;
  justify-content: right;
  align-items: center;
}.app_image {
   width: 33%;
   height: auto;
}

.app_id_display {
  font-size: 16px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  text-align: left;
  color: #098861;
}

</style>