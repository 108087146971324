export const MUTATION_PREFIX = 'LOGIN: ';
export const ACTIONS_PREFIX = 'LOGIN_ACTIONS_';
export const GETTERS_PREFIX = 'LOGIN_GETTERS_';

export default {

  MUTATIONS: {
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    SET_LOGIN_STATUS: MUTATION_PREFIX + 'SET_LOGIN_STATUS',
  },

  ACTIONS: {
    FIND_LOGIN_STATUS: ACTIONS_PREFIX + 'FIND_LOGIN_STATUS',
    SAVE_LOGIN_STATUS: ACTIONS_PREFIX + 'SAVE_LOGIN_STATUS',
  },

  GETTERS: {
    LOGIN_STATUS: GETTERS_PREFIX + 'LOGIN_STATUS',
  },

}
