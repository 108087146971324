<template>
  <div class="chart-container">
    <h3 v-if="title">{{title}}</h3>
    <div class="chart-wrap" v-if="type === 'bar'">
      <BarChart
        :chartData="getEChartData(data)"
        :format="format"
        :height="height + 'px'"
        :isSum="true"
        :gridLeft="50"
      />
    </div>
    <div class="chart-wrap" v-else-if="type === 'pie'">
      <PieChart
        :chartData="getEChartData(data, true)"
        :format="format"
        :height="height + 'px'"
        :isTooltip="false"
      />
    </div>
    <div class="chart-wrap" v-else-if="type === 'donut'">
      <PieChart
        :chartData="getEChartData(data, true)" 
        :format="format"
        :height="height + 'px'"
        :doughnut="true"
        :isTooltip="false"
      />
    </div>
    <div class="chart-wrap" v-else-if="type === 'stacked-bar'">
      <StackedBarChart
        :chartData="getEChartDataMultipleBar(data)"
        :labels="data.labels"
        :format="format"
        :height="height + 'px'"
        :isTooltip="false"
        :isSum="true"
        :gridLeft="50"
      />
    </div>
    <div class="chart-wrap" v-else-if="type === 'radialMultiple'">
      <GaugeChartMultiple
        :chartData="getEChartData(data, true)"
        :format="format"
        :height="height + 'px'"
        :isTooltip="false"
      />
    </div>
    <div class="chart-wrap" v-else-if="type === 'area'">
      <AreaChart
        :chartData="getEChartDataMultiple(data)"
        :xLabels="xlabels"
        :format="format"
        :height="height + 'px'"
        :isTooltip="isTooltip"
        :gridLeft="50"
      />
    </div>
    <div class="chart-wrap" v-else>
      Chart type is not defined!
    </div>
  </div>
</template>

<script>

import PieChart from '@/components/charts/echarts/PieChart.vue';
import BarChart from '@/components/charts/echarts/BarChart.vue';
import GaugeChartMultiple from '@/components/charts/echarts/GaugeChartMultiple.vue';
import AreaChart from '@/components/charts/echarts/AreaChart.vue';
import StackedBarChart from '@/components/charts/echarts/StackedBarChart.vue';
import { getColorsMixedContrastDecreasing } from '@/helpers/colors-helper';
import CORE_TYPES from '@/store/core/types';
import {mapGetters} from 'vuex';

export default {
  components: {
    PieChart,
    BarChart,
    AreaChart,
    GaugeChartMultiple,
    StackedBarChart,
  },
  props: {
    type: {
      type: String,
      default: 'bar', // pie, stacked-bar, line, donut
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    data: {
      type: [Object, Array],
      default: () => ({
        name: '',
        data: []
      }),
      required: true,
    },
    timeData:{
      type: Array,
      default:  function () {
        return [{
            name: 'Test Time Series',
            data: [{y: '777', x: '2018/11/25'}, {y: '1777', x: '2018/11/26'}, {y: '2777', x: '2018/11/27'}]
        }];
      }
    },
    annotationY: {
      type: Number,
      default: undefined
    },
    annotationYText: {
      type: String,
      default: ''
    },
    lineData: {
      type: Object,
      default: () => ({
        name: '',
        data: []
      }),
    },
    xlabels: {
      default: () => undefined,
    },
    numberFixed: {
      type: Number,
      default: 2,
    },
    format: {
      type: String,
      default: 'currency'
    },
    sparkline: {
      type: Boolean,
      default: false,
    },
    isDataLabels: {
      type: Boolean,
      default: true,
    },
    isTooltip: {
      type: Boolean,
      default: false,
    },
    isLegend: {
      type: Boolean,
      default: true,
    },
    colors: {
      type: Array,
      default: () => [],
    },
    xLabel: {
      type: Object,
      default: () => ({
        formatter: v => v,
      })
    },
    tooltip: {
      type: Object,
      default: () => ({
        x: {
          formatter: v => v,
        }
      })
    },
    brushMinDate: {
      type: Number,
      default: new Date().getTime(),
    },   
    brushMaxDate:{
      type: Number,
      default: new Date().getTime(),
    },
    componentId: {
      type: String,
      default: Math.random().toLocaleString()
    },
    showYaxis: {
      type: Boolean,
      default: true,
    },
    showXaxis: {
      type: Boolean,
      default: true,
    },
    showGrid: {
      type: Boolean,
      default: true,
    },
    height: {
      type: String,
      default: '250',
    },
    primaryColor: {
      type: Boolean,
      default: false,
    },
    primaryLightingBasedColor: {
      type: Boolean,
      default: false
    },
    mixedColors: {
      type: Boolean,
      default: true
    },
    customColors: {
      type: Array,
    }
  },

  computed: {
    ...mapGetters({
      userid: CORE_TYPES.GETTERS.GET_USER_ID,
    }),
    options() {
      return {
        ...(!this.showGrid ? {
          grid: {
            xaxis: {
              lines: {
                show: false
              }
            },
            yaxis: {
              lines: {
                show: false
              }
            }
          },
        } : {}),
        chart: {
          id: 'vuechart-pie',
          toolbar: {show: false},
          sparkline: {
            enabled: this.sparkline,
          },
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 1000,
            animateGradually: {
              enabled: true,
              delay: 400
            },
            dynamicAnimation: {
              enabled: true,
              speed: 400
            }
          },
          zoom: {
            enabled: false,
          },
        },
        xaxis: {
          categories: this.xlabels && this.xlabels.length ?
            this.xlabels : this.data && this.data.data && this.data.data.map((v, i) => v.label || i) || [],
          type: 'category',
          show: false,
          labels: {
            show: this.showXaxis,
            formatter: (value) => {
              return value;
            },
            ...{...(this.xLabel.formatter ? this.xLabel : {})}
          },
          axisLabel: {
            // formatter: this.xLabel,
            // show: false
          },
          axisTick: {show: false},
          silent: true,
          boundaryGap: false,
        },
        yaxis: {
          type: 'value',
          labels: {
            show: this.showYaxis,
            formatter: this.yLabel,
          },
          forceNiceScale: true,
        },
        legend: {
          show: this.isLegend,
          position: 'bottom',
          offsetY: this.isLegend ? 40 : 500,
          formatter: this.legendFormatter,
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              // width: 200,
            },
            legend: {
              position: 'bottom'
            }
          }
        }],
        dataLabels: {
          enabled: !this.sparkline && this.isDataLabels,
          offsetY: -15,
          style: {
            colors: ['var(--color-text)'],
          },
          background: {
            enabled: false,
          },
          formatter: this.yLabel,
        },
        tooltip: {
          show: false,
          enabled: this.isEmptyChart() ? false : this.isTooltip,
          ...(this.tooltip.x || this.tooltip.y ? this.tooltip : {})
        },
        ...(this.colors?.length ? { colors: this.colors } : {}),
        ...(this.isEmptyChart() ? {colors: ['#cccccc']} : {}),
        ...(this.primaryColor ? {colors: [document.documentElement.style.getPropertyValue('--color-primary')]} : {}),
        ...(this.mixedColors ? {colors: getColorsMixedContrastDecreasing()} : {}),
      }
    },
  },
  mounted() {
    if ( this.$children && this.$children.length === 1  && this.$children[0].$children.length === 1 ) {
      this.$emit('apex_chart', this.$children[0].$children[0]);
    }
  },
  methods: {
    log(v) {
      console.log(v)
    },
    isEmptyChart() {
      return this.data.data && this.data.data.length ? (this.data.data.length === 0 || this.data.data.length === 1 && this.data.data[0].label == '') : false;
    },
    yLabel(value, index) {
      if (this.format === 'currency') {
        return this.$options.filters.currency(value, this.numberFixed);
      } else if (this.format === 'percent') {
        return this.$options.filters.percent(value, this.numberFixed);
      }
      return value;
    },
    legendLabel(text) {
      return text;
    },
    legendFormatter(text) {
      // return text.length > 30 ? `${text.substring(0, 27)}<br>...` : text;
      // return text.replace(/(.{20})/g, '$&' + '<br>');
      return text;
    },
    updateChart() {
      // In the same way, update the series option
      this.series = [{
        data: []
      }]
    },
    getEChartDataMultipleBar(input) {
      return input && input.data || [];
    },
    getEChartDataMultiple(input) {
      return input.length > 1 ? input.filter(d => d.name) : input;
    },
    getEChartData(input, isAbs = false) {
      const data = input.data ? input.data : data
      const series = data.map((v) => ({
        value: isAbs ? Math.abs(v.value) : v.value,
        name: v.label,
      }));
      if (series.some(v => v.value)) {
        return series;
      }
      return [];
    },
  },
  filters: {
    currency(value, numberFixed = 0) {
      if (isNaN(value)) {
        return value;
      }
      let val = numberFixed !== 0 ? (value/1).toFixed(numberFixed).replace('.', ',') : parseInt(value/1).toString().replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €';
    },
    percent(value, numberFixed = 0) {
      if (isNaN(value)) {
        return value;
      }
      let val = numberFixed !== 0 ? (value/1).toFixed(numberFixed).replace('.', ',') : parseInt(value/1).toString().replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' %';
    },
  },
}
</script>

<style>
.chart-container {
  /* max-width: 500px; */
}
.chart-wrap > div:first-child {
  min-height: 250px;
}
.chart-container .apexcharts-legend-text {
  white-space: initial;
  max-width: 150px;
}
.chart-container .apexcharts-legend {
  max-width: 150px;
}

</style>
