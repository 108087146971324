<template>
  <BaseToolbarButton 
    title="Zeile davor hinzufügen"
    :showLabel="showLabel"
    :icon="icon"
    :disabled="disabled"
    @click="action"/>
</template>

<script>
import { PhCaretDoubleUp } from 'phosphor-vue';
import BaseToolbarButton from '../../base-components/BaseToolbarButton.vue';

export default {
  components: {
    BaseToolbarButton,
  },

  props: {
    editor: {
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    icon() {
      return PhCaretDoubleUp;
    },
  },

  methods: {
    action() {
      this.editor?.chain().focus().addRowBefore().run()
    }
  }

}
</script>
