var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.isLoaded
    ? _c(
        "div",
        { staticClass: "event-body" },
        [
          _c(
            "BaseModal",
            {
              ref: "modal",
              attrs: { labelButtonCancel: "Ok", showConfirmButton: false },
              on: { onCancelButton: _vm.close },
              scopedSlots: _vm._u(
                [
                  {
                    key: "modalTitle",
                    fn: function() {
                      return [_vm._v(" Information ")]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                1209719066
              )
            },
            [
              _vm.dat && _vm.dat.warnInfo
                ? _c("div", { staticClass: "font-bold" }, [
                    _vm._v(" " + _vm._s(_vm.dat.warnInfo) + " ")
                  ])
                : _vm._e(),
              _vm.dat && _vm.dat.records.length
                ? _c("Table", {
                    attrs: {
                      tableId: "15b90920-ebdd-11ec-8ea0-0242ac120002",
                      headers: _vm.headers,
                      rows: _vm.rows,
                      rowsPerPage: 1000,
                      hidePagination: true
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }