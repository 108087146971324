<template >
  <div class="container painter">
    <side-nav id="sidenav" />
    <data-header :email="maklerInfo.emailAddress" :telefon="maklerInfo.telefon" id="dataheader" />
    <transition-accordion>
      <navi-header
        v-if="show"
        :logoURL="maklerLogo"
        class="white-painter"
        id="naviheader"
      />
    </transition-accordion>

    <broker-info
      :cleanMaklername="maklerInfo.cleanMaklername"
      :strasse="maklerInfo.strasse"
      :plzOrt="maklerInfo.plzOrt"
      :telefon="maklerInfo.telefon"
      :faxStandard="maklerInfo.faxStandard"
      id="brokerinfo"
    />
    <beratungs-form class="white-painter" id="beratungsform" />
    <editable-frame type="ueber_uns" class="white-painter" id="ueberuns" />
    <editable-frame type="streitbeilegung" id="streitbeilegung" />
    <lower-div :content="maklerInfo.cleanMaklername" id="lowerdiv" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import CORE_TYPES from "@/store/core/types";
import EditableFrame from "@/components/cms/EditableFrame.vue";
import DataHeader from "@/components/cms/parts/dataHeader.vue";
import NaviHeader from "@/components/cms/parts/naviHeader.vue";
import BrokerInfo from "@/components/cms/parts/brokerInfo.vue";
import BeratungsForm from "@/components/cms/parts/beratungsForm.vue";
import LowerDiv from "@/components/cms/parts/lowerDiv.vue";
import SideNav from "@/components/cms/parts/sideNav.vue";
import TransitionAccordion from "@/components/transitions/TransitionAccordion.vue";
export default {
  components: {
    EditableFrame,
    DataHeader,
    NaviHeader,
    BrokerInfo,
    BeratungsForm,
    LowerDiv,
    SideNav,
    TransitionAccordion
  },
  data: function() {
    return {
      windowWidth: 0
    };
  },
  computed: {
    ...mapGetters({
      maklerInfo: CORE_TYPES.GETTERS.GET_BROKER_INFORMATION,
      maklerLogo: CORE_TYPES.GETTERS.GET_BROKER_LOGO
    }),
    show: function() {
      return this.windowWidth < 800;
    }
  },
  methods: {
    getWindowWidth(event) {
      this.windowWidth = document.documentElement.clientWidth;
    }
  },
  mounted() {
    this.$nextTick(function() {
      window.addEventListener("resize", this.getWindowWidth);
      this.getWindowWidth();
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.getWindowWidth);
  }
};
</script>
<style scoped>
.container {
  text-align: center;
  display: grid;
  grid-template-rows: auto;
  grid-row-gap: 1em;
  grid-column-gap: 1em;

  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas:
    "sidenav dataheader dataheader"
    "sidenav ueberuns ueberuns"
    "sidenav brokerinfo beratungsform"
    "sidenav streitbeilegung beratungsform"
    "sidenav lowerdiv lowerdiv";

  /*  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "dataheader dataheader"
    "naviheader naviheader"
    "brokerinfo beratungsform"
    "ueberuns ueberuns"
    "streitbeilegung streitbeilegung"
    "lowerdiv lowerdiv"; */

  /*  grid-template-columns: 1fr 1fr;
    grid-template-areas:
    "dataheader dataheader"
    "naviheader naviheader"
    "brokerinfo beratungsform"
    "ueberuns streitbeilegung"
    "lowerdiv lowerdiv"; */
}
.white-painter {
  background: var(--color-app-background);
}
.painter {
  background: var(--color-secondary);
}
.separator {
  height: 3em;
  background: var(--color-app-background);
}
#beratungsform {
  grid-area: beratungsform;
}
#dataheader {
  grid-area: dataheader;
}
#naviheader {
  grid-area: naviheader;
}
#brokerinfo {
  grid-area: brokerinfo;
}
#ueberuns {
  grid-area: ueberuns;
}
#streitbeilegung {
  grid-area: streitbeilegung;
}
#lowerdiv {
  grid-area: lowerdiv;
}
#sidenav {
  grid-area: sidenav;
}
@media only screen and (max-width: 1024px) {  .container {
    grid-template-columns: 1fr;
    grid-template-areas:
      "dataheader"
      "naviheader"
      "brokerinfo"
      "beratungsform"
      "ueberuns"
      "streitbeilegung"
      "lowerdiv";
  }
}
</style>

<style scoped>
img {
  max-width: 100%;
  height: auto;
}

.button {
  background-color: var(--color-primary);
  border: none;
  color: var(--color-top-nav-active);
  padding: 20px 34px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  margin: 4px 2px;
  cursor: pointer;
}

a {
  text-decoration: none;
  
  font-weight: bold;
  color: var(--color-app-link);
}

a:visited {
  text-decoration: none;
}

a:hover {
  color: var(--colo--color-active);
}

a:active {
  text-decoration: underline;
}
</style>