var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.kreditform || "Kredite bearbeiten",
          subtitle: _vm.kreditnummer
        }
      }),
      _c("StepperForm", {
        ref: "stepperForm",
        attrs: {
          stepperName: "credit",
          stepType: "tab",
          stepperMediator: _vm.stepperMediator,
          hasFertig: false,
          selectedStepKey: _vm.selectedStepKey,
          additionalActionsForMobile: _vm.baseModalActions
        },
        on: {
          handleOtherActions: _vm.handleOtherActions,
          "set-step": function($event) {
            return _vm.setStep($event)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "headerTemplate",
            fn: function() {
              return [_c("HinweiseUndFehler", { attrs: { hints: _vm.hints } })]
            },
            proxy: true
          },
          {
            key: "contentTemplate",
            fn: function() {
              return [
                _vm.selectedStepKey === "vertragsdaten"
                  ? _c("Vertragsdaten", { ref: "vertragsdaten" })
                  : _vm.selectedStepKey === "anpassungen"
                  ? _c("Anpassungen")
                  : _vm.selectedStepKey === "abrechnung"
                  ? _c("Abrechnung")
                  : _vm.selectedStepKey === "aktivitaet"
                  ? _c("Aktivitaet")
                  : _vm.selectedStepKey === "dokumente"
                  ? _c("Dokumente")
                  : _vm.selectedStepKey === "prohyp"
                  ? _c("Prohyp")
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }