var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "footer" }, [
    _c(
      "div",
      { staticClass: "editor-wrapper" },
      [
        _c("HtmlEditor", {
          attrs: {
            value: _vm.value,
            textPlaceholderOptions: _vm.placeholdersOptions,
            previewHTML: _vm.previewHtml.text,
            disabled: _vm.disabled,
            isFontParams: "",
            isPreviewButton: !_vm.ignoreSenders,
            applySavedSettings: ""
          },
          on: {
            input: function($event) {
              return _vm.$emit("input", $event)
            },
            change: function($event) {
              return _vm.$emit("change", $event)
            },
            onTogglePreviewEnabled: _vm.handlePreview
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }