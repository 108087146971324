var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-container paginated-table__container" },
    [
      !_vm.$isSmallScreen || !_vm.mobileConfig
        ? [
            _c("SortableTable", {
              ref: "sortableTable",
              attrs: {
                cardView: _vm.cardView,
                tableId: _vm.tableId,
                title: _vm.title,
                headers: _vm.headerMap,
                rows: _vm.displayedPage,
                sortedManually: _vm.sortConfig,
                maxHeaderLines: _vm.maxHeaderLines,
                enableToggleHorizontalScrollingConfig:
                  _vm.enableToggleHorizontalScrollingConfig,
                exportConfig: _vm.exportConfig,
                headerActions: _vm.headerActions,
                loadingCollapsed: _vm.loadingCollapsed,
                sortableRows: _vm.sortableRows,
                showColumnsConfigEvenNoTitle: _vm.showColumnsConfigEvenNoTitle,
                thHoverDisabled: _vm.thHoverDisabled,
                noDataIcon: _vm.noDataIcon,
                noDataContent: _vm.noDataContent
              },
              on: {
                sort: _vm.handleSort,
                localSort: function($event) {
                  return _vm.$emit("localSort", $event)
                },
                clickCell: _vm.handleClick,
                action: _vm.handleAction,
                files: _vm.handleFile,
                headerAction: _vm.handleHeaderAction,
                configColumn: _vm.columnConfigChanged,
                shownHeaders: function($event) {
                  return _vm.$emit("shownHeaders", $event)
                },
                allHeaders: function($event) {
                  return _vm.$emit("allHeaders", $event)
                },
                toggleRow: function($event) {
                  return _vm.$emit("toggleRow", $event)
                },
                isColumnsConfigOpen: function($event) {
                  return _vm.$emit("isColumnsConfigOpen", $event)
                },
                orderChanged: _vm.onOrderChanged
              },
              scopedSlots: _vm._u(
                [
                  _vm.menuRowsPerPage && _vm.menuRowsPerPage.length
                    ? {
                        key: "dropdown_menu",
                        fn: function() {
                          return [
                            _c("SelectRowPerPage", {
                              attrs: {
                                pageSize: _vm.pageSize,
                                rowSteps: _vm.menuRowsPerPage
                              },
                              on: { tablePageSize: _vm.onPageSizeChange }
                            })
                          ]
                        },
                        proxy: true
                      }
                    : null,
                  {
                    key: "header___SELECT__",
                    fn: function() {
                      return [
                        _vm.selectionAsBlacklist != null
                          ? _c("InputCheckBoxItem", {
                              staticStyle: { "margin-left": "3px" },
                              attrs: {
                                value: _vm.areAllSelected,
                                indeterminate: _vm.areOnlySomeSelected,
                                tid: _vm._generateTidFromString(
                                  _vm.tableId + ":__SELECT__"
                                )
                              },
                              on: { input: _vm.onSelectAll }
                            })
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  },
                  _vm._l(_vm.headersFlat, function(ref) {
                    var key = ref.key
                    var rowElement = ref.rowElement
                    return {
                      key: key,
                      fn: function(ref) {
                        var row = ref.row
                        var id = ref.id
                        return [
                          !rowElement &&
                          key == "__SELECT__" &&
                          id != null &&
                          !row.selectedHidden
                            ? _c("InputCheckBoxItem", {
                                key: key,
                                attrs: {
                                  value: _vm.isCheckmarked(id),
                                  tid: _vm._generateTidFromString(
                                    _vm.tableId + ":__SELECT__:" + id
                                  )
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onSelect(id)
                                  }
                                }
                              })
                            : !rowElement && key != "__SELECT__"
                            ? _vm._t(key, null, null, row)
                            : _vm._e()
                        ]
                      }
                    }
                  })
                ],
                null,
                true
              )
            }),
            _vm.displayedItems > 0
              ? _c("div", { staticClass: "table-pagination" }, [
                  _c(
                    "div",
                    { staticClass: "table-pagination--left" },
                    [
                      _c("PageRowCount", {
                        attrs: {
                          displayedItems: _vm.displayedItems,
                          totalRows: _vm.rowCount
                        }
                      }),
                      _c("SelectRowPerPage", {
                        attrs: {
                          short: "",
                          pageSize: _vm.pageSize,
                          rowSteps: _vm.menuRowsPerPage
                        },
                        on: { tablePageSize: _vm.onPageSizeChange }
                      })
                    ],
                    1
                  ),
                  _vm.displayedItems < _vm.rowCount
                    ? _c(
                        "div",
                        { staticClass: "table-pagination--right" },
                        [
                          _c("Pagination", {
                            attrs: {
                              totalPages: _vm.pageCount,
                              currentPage: _vm.displayedPageIndex
                            },
                            on: { pagechanged: _vm.onPageChange }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ])
              : _vm._e()
          ]
        : [
            _c(
              "ScrollLoading",
              {
                attrs: { hasScroll: _vm.rowsWithId.length !== _vm.rowCount },
                on: {
                  loading: function($event) {
                    return _vm.$emit("scrollLoading", $event)
                  },
                  scroll: function($event) {
                    return _vm.$emit("onScroll", $event)
                  }
                }
              },
              [
                _c(
                  "MobileTable",
                  _vm._g(
                    {
                      ref: "sortableTable",
                      attrs: {
                        tableId: _vm.tableId,
                        rowId: _vm.rowId,
                        title: _vm.title,
                        headers: _vm.headerMap,
                        rows: _vm.rowsWithId,
                        titleActions: _vm.headerActions,
                        config: _vm.mobileConfig,
                        selected: _vm.selected,
                        selectionAsBlacklist: _vm.selectionAsBlacklist,
                        noDataIcon: _vm.noDataIcon,
                        noDataContent: _vm.noDataContent
                      },
                      on: {
                        selected: function($event) {
                          return _vm.$emit("selected", $event)
                        },
                        selectionAsBlacklist: function($event) {
                          return _vm.$emit("selectionAsBlacklist", $event)
                        },
                        clickCell: _vm.handleClick,
                        action: _vm.handleAction,
                        files: _vm.handleFile,
                        headerAction: _vm.handleHeaderAction,
                        configColumn: _vm.columnConfigChanged,
                        orderChanged: _vm.onOrderChanged
                      },
                      scopedSlots: _vm._u(
                        [
                          _vm._l(_vm.headersFlat, function(ref) {
                            var key = ref.key
                            var rowElement = ref.rowElement
                            return {
                              key: key,
                              fn: function(ref) {
                                var row = ref.row
                                var mobileTableContext = ref.mobileTableContext
                                return [
                                  !rowElement && key !== "__SELECT__"
                                    ? _vm._t(
                                        key,
                                        null,
                                        {
                                          mobileTableContext: mobileTableContext
                                        },
                                        row
                                      )
                                    : _vm._e()
                                ]
                              }
                            }
                          }),
                          _vm._l(_vm.headersFlatLabelNull, function(header) {
                            return {
                              key: header.headerKey,
                              fn: function() {
                                return [
                                  _vm._t(header.headerKey, null, null, header)
                                ]
                              },
                              proxy: true
                            }
                          })
                        ],
                        null,
                        true
                      )
                    },
                    _vm.$listeners["clickRow"]
                      ? {
                          clickRow: function(event) {
                            return _vm.$emit("clickRow", event)
                          }
                        }
                      : {}
                  )
                )
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }