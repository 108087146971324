export const SmartMSCAppLabel = Object.freeze({
  APP_NAME: 'smartMSC',
  WELCOME_TITLE: 'Willkommen im smartMSC',
});

export const FinanceCloudAppLabel = Object.freeze({
  APP_NAME: 'FinanceCloud',
  WELCOME_TITLE: 'Willkommen in Ihrer FinanceCloud',
});
export const mobileNativeAppLabel = Object.freeze({
  APP_NAME: 'FinanceApp',
  WELCOME_TITLE: 'Willkommen in Ihrer FinanceApp',
});

function getAppDefaultLabel() {
  switch (window.location.host) {
    case 'www.smartmsc.de':
    case 'smartmsc.de':
      return SmartMSCAppLabel;
    default:
      return FinanceCloudAppLabel;
  }
}

export const AppDefaultLabel = getAppDefaultLabel();

export function PageCfg(pageTitle) {
  return {
    pageCfg: {
      pageTitle,
    },
  };
}
