import Vue from 'vue'
import { ALL_ROLES } from '@/router/roles'

/**
 * plugin responsible for make available the ROLES inside the <template> tags.
 * 
 * This way it's possible to use the component <HasRoles :allowed="[VIEW_ROLES.VIEW_CUSTOMER]">
 * 
 * It's also possible to use v-role="[VIEW_ROLES.VIEW_CUSTOMER]"
 */
Vue.use({
  install(Vue) {
    for (const roleDef in ALL_ROLES) {
      if (Object.hasOwnProperty.call(ALL_ROLES, roleDef)) {
        const roleObj = ALL_ROLES[roleDef];
        Vue.prototype[roleDef] = { ... roleObj}
      }
    }
  }
})