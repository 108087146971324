var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Favoritenliste Wertpapiere",
          actions: _vm.headerActions
        },
        on: {
          "action-CREATE": function($event) {
            return _vm.handleTableAction({ key: "CREATE" })
          }
        }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.rows.length
            ? _c("Table", {
                attrs: {
                  headers: _vm.headers,
                  rows: _vm.rows,
                  sortableRows: ""
                },
                on: {
                  action: function($event) {
                    return _vm.handleTableAction($event)
                  },
                  orderChanged: _vm.orderChanged
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "label",
                      fn: function(row) {
                        return [
                          _c(
                            "a",
                            {
                              class: {
                                bold: row.primary && !row.primaryFondsshop
                              },
                              on: {
                                click: function($event) {
                                  return _vm.openFavoritenliste(row)
                                }
                              }
                            },
                            [_vm._v(_vm._s(row.label))]
                          )
                        ]
                      }
                    },
                    {
                      key: "primary",
                      fn: function(row) {
                        return [
                          _c("InputToggleSwitch", {
                            attrs: {
                              disabled:
                                _vm.loading ||
                                row.primaryFondsshop ||
                                row.primary,
                              value: row.primary && !row.primaryFondsshop
                            },
                            on: {
                              input: function($event) {
                                return _vm.editAction({
                                  row: row,
                                  key: "PRIMARY",
                                  value: $event,
                                  objKey: "primary"
                                })
                              }
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "fondsshop",
                      fn: function(row) {
                        return [
                          _c("InputToggleSwitch", {
                            attrs: {
                              disabled:
                                _vm.loading ||
                                row.primaryFondsshop ||
                                !_vm.options.zugriffUneingeschraenkt,
                              value: row.fondsshop
                            },
                            on: {
                              input: function($event) {
                                return _vm.editAction({
                                  row: row,
                                  key: "FONDSSHOP",
                                  value: $event,
                                  objKey: "fondsshop"
                                })
                              }
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "struktur",
                      fn: function(row) {
                        return [
                          row.owner
                            ? _c("InputToggleSwitch", {
                                attrs: {
                                  disabled: _vm.loading || row.primaryFondsshop,
                                  value: row.struktur
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.editAction({
                                      row: row,
                                      key: "STRUKTUR",
                                      value: $event,
                                      objKey: "struktur"
                                    })
                                  }
                                }
                              })
                            : _c("span")
                        ]
                      }
                    },
                    {
                      key: "beratung",
                      fn: function(row) {
                        return [
                          _c("ComboBox", {
                            attrs: {
                              value: row.beratung,
                              values: _vm.beratungValues,
                              disabled: _vm.loading || row.primaryFondsshop
                            },
                            on: {
                              change: function($event) {
                                return _vm.editAction({
                                  row: row,
                                  key: "BERATUNG",
                                  value: $event,
                                  objKey: "beratung"
                                })
                              }
                            }
                          })
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  482373134
                )
              })
            : _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", config: { table: { rows: 10 } } }
              })
            : _c("NoData")
        ],
        1
      ),
      !_vm.options.favoritenRestricted
        ? _c("div", { staticClass: "box__container" }, [_vm._m(0)])
        : _vm._e(),
      _c(
        "BaseModal",
        {
          ref: "renameFavoritenlisteModal",
          attrs: {
            labelButtonConfirm: "Umbenennen",
            modalTitle: "Favoritenliste umbenennen"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.renameFavoritenListe()
            }
          }
        },
        [
          _c("InputField", {
            attrs: { label: "Bezeichnung", disabled: _vm.loading },
            model: {
              value: _vm.newFavoritenlisteName,
              callback: function($$v) {
                _vm.newFavoritenlisteName = $$v
              },
              expression: "newFavoritenlisteName"
            }
          })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "createFavoritenlisteModal",
          attrs: {
            modalTitle: "Favoritenliste erstellen",
            labelButtonConfirm: "Erstellen"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.createFavoritenListe()
            }
          }
        },
        [
          _c("InputField", {
            attrs: { label: "Bezeichnung", disabled: _vm.loading },
            model: {
              value: _vm.newFavoritenlisteName,
              callback: function($$v) {
                _vm.newFavoritenlisteName = $$v
              },
              expression: "newFavoritenlisteName"
            }
          })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "copyFavoritenlisteModal",
          attrs: {
            labelButtonConfirm: "Kopieren",
            modalTitle: "Favoritenliste kopieren"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.copyFavoritenListe()
            }
          }
        },
        [
          _c("InputField", {
            attrs: { label: "Bezeichnung", disabled: _vm.loading },
            model: {
              value: _vm.newFavoritenlisteName,
              callback: function($$v) {
                _vm.newFavoritenlisteName = $$v
              },
              expression: "newFavoritenlisteName"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", [
        _vm._v(
          "Diese Liste dient zur Zusammenstellung Ihrer eigenen Fonds-Favoriten, die dann z.B. in der Wertpapiersuche aufrufbar sind."
        )
      ]),
      _c("p", [
        _vm._v(
          'Auf Wunsch werden nur die hier von Ihnen ausgewählten Wertpapiere bei der Auswahl in der Wertpapierorder berücksichtigt. Wählen Sie hierzu die Option „Favoritenliste" im Wertpapierfilter während der Eingabe der Wertpapierorder aus.'
        )
      ]),
      _c("p", [
        _vm._v(
          " - Mit der Einstellung „Für Struktur freigeschaltet“ legen Sie fest, welche Ihrer Favoritenlisten an Ihre Unterstruktur vererbt wird "
        )
      ]),
      _c("p", [
        _vm._v(
          " - Mit der Einstellung „Für Beratung verwendet“ entscheiden Sie, welche der Favoritenlisten Ihnen z.B. in der Wertpapierinfo oder in der Anlageempfehlung angezeigt werden. Bitte beachten Sie, dass wenn Sie keine Liste aktivieren, Ihnen keine Liste in den jeweiligen Tools angezeigt wird "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }