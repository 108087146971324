import SSO_TYPES from './types';

export default {
    [SSO_TYPES.GETTERS.LOGIN_NAME](state) {
        return state.loginName;
    },
    [SSO_TYPES.GETTERS.PHONE_NUMBER](state) {
        return state.phoneNumber;
    },
    [SSO_TYPES.GETTERS.EMAIL](state) {
        return state.email;
    },
    [SSO_TYPES.GETTERS.IS_ACTIVATED](state) {
        return state.activated;
    },
    [SSO_TYPES.GETTERS.FK_ACCOUNTS](state) {
        return state.fkAccounts;
    },
    [SSO_TYPES.GETTERS.SSO_INVITATIONS](state) {
        return state.ssoInvitations;
    },
    [SSO_TYPES.GETTERS.SSO_ACCOUNTS](state) {
        return state.ssoAccounts;
    },
    [SSO_TYPES.GETTERS.SSO_ACCOUNTS_ALL](state) {
        return state.ssoAccountsAll;
    },
    [SSO_TYPES.GETTERS.GET_SHOW_SSO_CUSTOMER_OVERVIEW](state) {
        return state.showSsoCustomerOverview;
    },
    [SSO_TYPES.GETTERS.INVITATIONS](state) {
        return state.invitations;
    },
    [SSO_TYPES.GETTERS.REFERRER](state) {
        return state.referrer;
    },
}