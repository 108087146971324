export const MUTATION_PREFIX = 'ZUSAETZLICHE_INFORMATIONEN_MUTATIONS_';
export const ACTIONS_PREFIX = 'ZUSAETZLICHE_INFORMATIONEN_ACTIONS_';
export const GETTERS_PREFIX = 'ZUSAETZLICHE_INFORMATIONEN_GETTERS_';

export default {
  MUTATIONS: {
    GET_ZUSAETZLICHE_DATEN_SUCCESS: MUTATION_PREFIX + 'GET_ZUSAETZLICHE_DATEN_SUCCESS',
    GET_ALLE_STRUKTUR_ZUSAETZLICHE_INFOS_SUCCESS: MUTATION_PREFIX + 'GET_ALLE_STRUKTUR_ZUSAETZLICHE_INFOS_SUCCESS',
    NEW_ZUSAETZLICH_DATEN_SUCCESS: MUTATION_PREFIX + 'NEW_ZUSAETZLICH_DATEN_SUCCESS',
    UPDATE_OBJECT_ZUSAETZLICHE_TABELLE_SUCCESS: MUTATION_PREFIX + 'UPDATE_OBJECT_ZUSAETZLICHE_TABELLE_SUCCESS',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    UPDATE_MAIN_TABLE_ROWS: MUTATION_PREFIX + 'UPDATE_MAIN_TABLE_ROWS',
    UPDATE_SECONDARY_TABLE_ROWS: MUTATION_PREFIX + 'UPDATE_SECONDARY_TABLE_ROWS',
    REMOVE_MAIN_TABLE_ROW: MUTATION_PREFIX + 'REMOVE_MAIN_TABLE_ROW',
    DELETE_ZUSAETZLICHE_DATEN_SUCCESS: MUTATION_PREFIX + 'DELETE_ZUSAETZLICHE_DATEN_SUCCESS',
  },
  ACTIONS: {
    GET_ZUSAETZLICHE_DATEN: ACTIONS_PREFIX + 'GET_ZUSAETZLICHE_DATEN',
    GET_ALLE_STRUKTUR_ZUSAETZLICHE_INFOS: ACTIONS_PREFIX + 'GET_ALLE_STRUKTUR_ZUSAETZLICHE_INFOS',
    NEW_ZUSAETZLICH_DATEN: ACTIONS_PREFIX + 'NEW_ZUSAETZLICH_DATEN',
    UPDATE_OBJECT_ZUSAETZLICHE_TABELLE: ACTIONS_PREFIX + 'UPDATE_OBJECT_ZUSAETZLICHE_TABELLE',
    DELETE_ZUSAETZLICHE_DATEN: ACTIONS_PREFIX + 'DELETE_ZUSAETZLICHE_DATEN',
  },
  GETTERS: {
    ZUSAETZLICHE_DATEN: GETTERS_PREFIX + 'ZUSAETZLICHE_DATEN',
    ALLE_STRUKTUR_ZUSAETZLICHE_INFOS: GETTERS_PREFIX + 'ALLE_STRUKTUR_ZUSAETZLICHE_INFOS',
    MAIN_TABLE_ROWS: GETTERS_PREFIX + 'MAIN_TABLE_ROWS',
    SECONDARY_TABLE_ROWS: GETTERS_PREFIX + 'SECONDARY_TABLE_ROWS',
  }
}