<template>
  <BaseToolbarComboBox
    :value="currentValue"
    :values="values"
    :disabled="disabled"
    @change="changeFontFamily($event)"
    content="Zeilenabstand"
    v-tippy="{ arrow: true }"
  />
</template>

<script>
import BaseToolbarComboBox from "../base-components/BaseToolbarComboBox.vue";
import FC_CONFIG_TYPES from "@/store/fcConfig/types";
import { mapGetters } from "vuex";

const ADD_PARAMETER = "HTML_EDITOR_DEFAULT_FONT_LINE_HEIGHT";

export default {
  components: {
    BaseToolbarComboBox,
  },

  props: {
    editor: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      addParameters: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_ADD_PARAMETER,
    }),
    values() {
      return [
        { value: "", label: "Zeilenabstand" },
        { value: "1", label: "1" },
        { value: "1.1", label: "1.1" },
        { value: "1.2", label: "1.2" },
        { value: "1.3", label: "1.3" },
        { value: "1.5", label: "1.5" },
        { value: "2", label: "2" },
      ];
    },
    applySavedSettings() {
      return this.editor?.options?.editorProps?.attributes?.applySavedSettings;
    },
    savedOption() {
      return this.applySavedSettings
        ? (this.addParameters?.[ADD_PARAMETER]?.content?.trim() || "1.5")
        : "1.5";
    },
    currentValue() {
      const current =
        this.values
          .map((val) => val.value)
          .find((value) =>
            this.editor?.isActive("paragraph", { lineHeight: value })
          ) || "";

      return current || this.savedOption || "";
    },
  },

  methods: {
    changeFontFamily(newValue) {
      if (newValue) {
        this.editor?.commands.setLineHeight(newValue);
      } else {
        this.editor?.commands.unsetLineHeight();
      }

      this.editor?.chain().focus().run();
    },
  },
};
</script>
