export const MUTATION_PREFIX = 'WERTPAPIERE_ALLE_KUNDEN: ';
export const ACTIONS_PREFIX = 'WERTPAPIERE_ALLE_KUNDEN_ACTIONS_';
export const GETTERS_PREFIX = 'WERTPAPIERE_ALLE_KUNDEN_GETTERS_';

export default {
  MUTATIONS: {
    GET_INIT_DATA_SUCCESS: MUTATION_PREFIX + 'GET_INIT_DATA_SUCCESS',
    SEARCH_WERTPAPIER_SUCCESS: MUTATION_PREFIX + 'SEARCH_WERTPAPIER_SUCCESS',
    LIST_POSITIONS_SUCCESS: MUTATION_PREFIX + 'LIST_POSITIONS_SUCCESS',
    UPDATE_STORE: MUTATION_PREFIX + 'UPDATE_STORE',
    UPDATE_EMAIL_DATA_STORE: MUTATION_PREFIX + 'UPDATE_EMAIL_DATA_STORE',
    RESET_POSITIONS: MUTATION_PREFIX + 'RESET_POSITIONS',
    RESET_ANTRAEGE: MUTATION_PREFIX + 'RESET_ANTRAEGE',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    UPDATE_POSITION_FROM_TABLE: MUTATION_PREFIX + 'UPDATE_POSITION_FROM_TABLE',
    UPDATE_POSITION_FROM_MODAL: MUTATION_PREFIX + 'UPDATE_POSITION_FROM_MODAL',
    CREATE_ANTRAEGE_SUCCESS: MUTATION_PREFIX + 'CREATE_ANTRAEGE_SUCCESS',
    GET_EMAIL_DATA_SUCCESS: MUTATION_PREFIX + 'GET_EMAIL_DATA_SUCCESS',
  },
  ACTIONS: {
    GET_INIT_DATA: ACTIONS_PREFIX + 'GET_INIT_DATA',
    SEARCH_WERTPAPIER: ACTIONS_PREFIX + 'SEARCH_WERTPAPIER',
    LIST_POSITIONS: ACTIONS_PREFIX + 'LIST_POSITIONS',
    CREATE_ANTRAEGE: ACTIONS_PREFIX + 'CREATE_ANTRAEGE',
    GET_PDF: ACTIONS_PREFIX + 'GET_PDF',
    GET_EMAIL_DATA: ACTIONS_PREFIX + 'GET_EMAIL_DATA',
  },
  GETTERS: {
    LAGERSTELLEN: GETTERS_PREFIX + 'LAGERSTELLEN',
    GESELLSCHAFTEN: GETTERS_PREFIX + 'GESELLSCHAFTEN',
    KATEGORIEN: GETTERS_PREFIX + 'KATEGORIEN',
    WERTPAPIERE: GETTERS_PREFIX + 'WERTPAPIERE',
    DATA: GETTERS_PREFIX + 'DATA',
    POSITIONS: GETTERS_PREFIX + 'POSITIONS',
    COMBOBOX_VALUES: GETTERS_PREFIX + 'COMBOBOX_VALUES',
    TEMP_FILE_ID: GETTERS_PREFIX + 'TEMP_FILE_ID',
    ANTRAEGE_PDF: GETTERS_PREFIX + 'ANTRAEGE_PDF',
    ANTRAEGE_EMAIL: GETTERS_PREFIX + 'ANTRAEGE_EMAIL',
    EMAIL_DATA: GETTERS_PREFIX + 'EMAIL_DATA',
  }
}
