<template>
<div ref=topElement>
  <div v-if="askCount" >
    <p>Die Anzahl der Datensätze übersteigt {{isIntern ? 1000 : 25}} Zeilen.</p>
    <form @submit.prevent="pinSenden()">
      <div>Um die Daten zu sehen, benötigen Sie eine PIN, die gibt Ihnen die Berechtigung für die derzeitige Sitzung, auch größere Listen zu sehen.
        <InputField label="PIN:" placeholder="3-stellige Nummer" v-model="pin" class="input-pin" />
        <BaseButton :disabled="disableSend"  @click="pinSenden()">PIN senden</BaseButton>
      </div>
    </form>
    <p v-if="isFilter">Alternativ können Sie den Filter stärker eingrenzen, um weniger als {{isIntern ? 1000 : 25}} passende Einträge zu bekommen.</p>
    <p class="fc-form-danger" >
      <span v-if="askCount == 1">PIN Eingabe war Falsch. Sie haben noch zwei Versuche.</span>
      <span v-else-if="askCount == 2">PIN Eingabe war Falsch. Sie haben noch einen Versuch.</span>
      <span v-else-if="askCount == 3">PIN Eingabe war Falsch. PIN wurde blockiert.</span>
    </p>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';
import BaseButton from '@/components/core/BaseButton.vue';
import InputField from '@/components/core/forms/InputField.vue';

export default {
  components: {
    BaseButton, InputField
  },
  props: {
    keyOfPIN: {
      type: String,
      required: true,
    },
    isFilter: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  data: function() {
    return {
      pin: null,
      askCount: 0,
    }
  },
  computed: {
    ...mapGetters({
      hasPIN: CORE_TYPES.GETTERS.ASK_FOR_PIN,
      resetEventBaseFilter: CORE_TYPES.GETTERS.RESET_EVENT_BASE_FILTER,
      isIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
    }),
    disableSend() {
      return !(/^\d\d\d$/.test(this.pin));
    },
  },
  watch: {
    hasPIN: {
      handler: function() {
        const item = this.keyOfPIN && this.hasPIN[this.keyOfPIN];
        const askCount = item ? item.value : 0;
        if (process.env.VUE_APP_USER === 'hannes') console.log('askCount', this.keyOfPIN, askCount, this.askCount);
        if (this.askCount == 0 && askCount != 0) {
          if (this.$refs.topElement?.scrollIntoView) {
            this.$refs.topElement?.scrollIntoView();
          }
        } else if (this.askCount != 0 && askCount == 0) {
          if (item.action && item.payload && item.sendPin) {
            this.$store.dispatch(item.action, JSON.parse(item.payload));
          }
        }
        this.askCount = askCount;
      },
      immediate: true,
      deep: true,
    },
    resetEventBaseFilter() {
      this.askCount = 0;
    }
  },
  methods: {
    pinSenden() {
      if (/^\d\d\d$/.test(this.pin)) {
        this.$store.dispatch(CORE_TYPES.ACTIONS.CHECK_PIN, {thema: this.keyOfPIN, pin: this.pin});
      }
    }
  },
}
</script>

<style scoped>
::v-deep .input-pin > .input-forms__label-container {
  flex-direction: row;
  gap: 20px;
  align-items: center;
  margin-top: 5px;
}

</style>