
<template>
  <NewCustomer :isSuperCustomer="true" />
</template>

<script>
import NewCustomer from './NewCustomer.vue';

export default {
  name: 'NewSuperCustomer',
  components: {
    NewCustomer
  },
}
</script>
