var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "depotnummer-anfordern-button-component mb-4" },
    [
      _c(
        "div",
        {
          staticClass: "depotnummer-anfordern-button-component-input-container"
        },
        [
          _c(
            "BaseButton",
            {
              attrs: { disabled: _vm.loading },
              on: {
                click: function($event) {
                  return _vm.requestAnlagestrategien()
                }
              }
            },
            [
              _vm._v(" Anlagestrategien anfordern "),
              _vm.loading ? _c("AnimatedSpinner") : _vm._e()
            ],
            1
          ),
          _c("br"),
          _c("br"),
          _vm.anlagestrategienOptions && _vm.anlagestrategienOptions.length
            ? _c("InputRadioBoxGroup", {
                attrs: {
                  title: _vm.label,
                  value: _vm.value,
                  validateUntouched: true,
                  disabled: _vm.loading,
                  values: _vm.anlagestrategienOptions,
                  labelClass: "font-bold"
                },
                on: {
                  input: function($event) {
                    return _vm.handleInputEvent($event)
                  }
                }
              })
            : _c("div", [_vm._v(" " + _vm._s(_vm.responsetext) + " ")])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }