var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "action-order-step-view" },
    [
      _vm.params
        ? _c("div", [
            _c(
              "div",
              { staticClass: "box__container" },
              [
                _c("ComboBox", {
                  attrs: {
                    label: "Ziellagerstelle",
                    isEmbedded: false,
                    values: _vm.ziellagerstelleAuswahl,
                    isComponentHalfSize: true,
                    sortComboboxValues: false,
                    disabled: _vm.completed || _vm.orderInitialized
                  },
                  on: { change: _vm.triggerSelectZiellagerstelle },
                  model: {
                    value: _vm.ziellagerstelle,
                    callback: function($$v) {
                      _vm.ziellagerstelle = $$v
                    },
                    expression: "ziellagerstelle"
                  }
                }),
                _c("ComboBox", {
                  attrs: {
                    label: "Kontonummer",
                    isEmbedded: false,
                    values: _vm.zielkontoAuswahl,
                    isComponentHalfSize: true,
                    sortComboboxValues: false,
                    disabled: _vm.completed || _vm.orderInitialized
                  },
                  on: { change: _vm.triggerSelectKonto },
                  model: {
                    value: _vm.zielkonto,
                    callback: function($$v) {
                      _vm.zielkonto = $$v
                    },
                    expression: "zielkonto"
                  }
                }),
                _c("input-field", {
                  attrs: {
                    validateUntouched: true,
                    isComponentHalfSize: true,
                    sortComboboxValues: false,
                    disabled:
                      _vm.completed || _vm.orderInitialized || _vm.neuDepot
                  },
                  on: { change: _vm.markModified },
                  model: {
                    value: _vm.kontonummer,
                    callback: function($$v) {
                      _vm.kontonummer = $$v
                    },
                    expression: "kontonummer"
                  }
                }),
                _c("input-check-box-item", {
                  attrs: {
                    label: "erzeuge Tauschaufträge",
                    disabled:
                      _vm.completed ||
                      _vm.orderInitialized ||
                      !_vm.hasDepotPositionen
                  },
                  model: {
                    value: _vm.params.erzeugeTausch,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "erzeugeTausch", $$v)
                    },
                    expression: "params.erzeugeTausch"
                  }
                }),
                _vm.params
                  ? _c("ComboBox", {
                      attrs: {
                        label: "Rabatt auf Agio",
                        isEmbedded: false,
                        values: _vm.stepParameters.data.rabattAuswahl,
                        isComponentHalfSize: true,
                        sortComboboxValues: false,
                        disabled: _vm.completed || _vm.orderInitialized
                      },
                      on: { change: _vm.triggerSelectRabatt },
                      model: {
                        value: _vm.params.rabatt,
                        callback: function($$v) {
                          _vm.$set(_vm.params, "rabatt", $$v)
                        },
                        expression: "params.rabatt"
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "box__container" },
              [
                _c("div", { staticClass: "box__title" }, [
                  _vm._v("Beratungsmappe")
                ]),
                _c("InputRadioBoxGroup", {
                  attrs: {
                    title: "",
                    validateUntouched: true,
                    disabled: _vm.orderDisabled,
                    values: _vm.optionsBeratung,
                    labelClass: "font-bold"
                  },
                  on: { input: _vm.setBMArt },
                  model: {
                    value: _vm.beratungsmappenart,
                    callback: function($$v) {
                      _vm.beratungsmappenart = $$v
                    },
                    expression: "beratungsmappenart"
                  }
                }),
                _c("p", [_vm._v(_vm._s(_vm.selectedMappe))])
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "box__container" },
              [_c("ActionOrderStepButtons", { attrs: { id: _vm.id } })],
              1
            )
          ])
        : _c(
            "GhostLoading",
            [
              _c("Block", { attrs: { height: "80" } }),
              _c("Block", { attrs: { height: "80" } }),
              _c("Block", { attrs: { height: "80" } }),
              _c("Block", { attrs: { height: "100" } })
            ],
            1
          ),
      _c("EditBeratungsmappeModal", {
        ref: "createBeratungsmappeModal",
        on: { confirm: _vm.createBeratungsmappe }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }