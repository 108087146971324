<template>

  <div>
    <PageHeaderTitleNavigation 
      title="Infobriefe" 
      :actions="headerActions" 
    />

    <HinweiseUndFehler :hints="hints" />

    <BaseFilter 
      filterId="c53a8f35-ac8d-4fc5-bf24-4c6b81650e4a"
      title="Liste filtern" 
      :metadata="filterMetadata" 
      :defaultOptions="defaultOptionsData"
      @onFilter="filterForm($event)">
    </BaseFilter>

    <div class="box__container" tid="c7a34f98-596c-4b7f-bb28-dbcd05f4691e">
      <InputToggleSwitch v-model="inputFreigabe" @input="vorVersandFreigeben" label="Infobriefe vor Versand freigeben" inLineLabel suppressValidationMessage/>

      <Table
        tableId="c035de14-5805-4bf5-81ec-8241ca88c0b8"
        title="Infobriefe"
        class="table-container"
        v-if="!loading && tableRows.length"
        :headers="tableHeaders"
        :rows="tableRows"
        :rowsPerPage="20"
        :count="resultCount"
        @action-FREIGEBEN="freigeben"
      >

        <template v-slot:freigabe="row">
          <Pill v-if="!row.freigabe && !inputFreigabe" label="wird automatisch versendet" type="danger"></Pill>
          <Pill v-else-if="!row.freigabe" label="nicht freigegeben" type="danger"></Pill>
          <Pill v-if="row.freigabe" label="freigegeben" type="success"></Pill>
        </template>

        <template v-slot:fileName="row">
          <div :class="{'background-orange': isFileDateAfterToday(row)}">
            <DownloadLink 
              :title="row.fileName"
              :filename="row.fileName"
              downloadServicePath="/get_infobrief"
              :queryParams="{fileName: row.fileName,}"
            />
          </div>
        </template>

      </Table>
      <GhostLoading v-else-if="loading" type="table" :title="TABLE_TITLE" />
      <NoData v-else :title="TABLE_TITLE" />
    </div>    

  </div>
    
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import CORE_TYPES from "@/store/core/types";
import INFOBRIEFE_TYPES from "@/store/infobriefe/types";
import { mapGetters } from "vuex";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import Table from "@/components/table2/Table.vue";
import {ActionColumn, SimpleAction, DateColumn, SlotColumn} from "@/components/table2/table_util.js";
import { PhEnvelope, PhCheck, PhX} from "phosphor-vue";
import BaseFilter from "@/components/core/BaseFilter.vue";
import DownloadLink from '@/components/core/download/DownloadLink.vue'
import HinweiseUndFehler from "@/components/core/HinweiseUndFehler.vue";
import dayjs from "dayjs";
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import Pill from '@/components/core/Pill.vue';

const TABLE_TITLE = 'Liste';
const HINWEISE = {message: "<ul> "+
          "<li>Ist eine Zeile orange markiert, so wird der Brief erst versendet, wenn das Datum in der letzten Spalte vergangen ist</li>"   +
			    "<li> Ein Versand der Infobriefe findet nur statt, wenn Sie diesen vorab aktiviert haben. Die hierfür notwendige, globale Einstellung finden Sie in den Standardwerte Kunden, unter Allgemein - Auswahl von Chat/ E-Mail-Diensten, oder kundenspezifisch in den individuellen Kundeneinstellungen</li>" +
			    "<li>Möchten Sie vor jedem Versand der Infobriefe diese zuerst lesen und danach zum Versand freigeben, so setzen Sie bitte links unter \"Infobriefe vor Versand freigeben\" einen Haken in die Checkbox. Ist der Haken hier nicht gesetzt, werden die Infobriefe automatisch nach Erscheinen verschickt</li>" +
			    "<li>Ist der Haken gesetzt, erscheint links neben dem aktuellen Infobrief ein rotes Kreuz. Durch Klicken auf das rote Kreuz wird der Infobrief freigegeben und automatisch verschickt</li>" +
			    "<li>Zum Lesen des Infobriefs klicken Sie bitte auf das Öffnen-Symbol links neben dem Infobrief</li>" +
			    "<li>Sie können Infobriefe bereits freigeben, während sie noch orange markiert sind. Diese werden erst versendet, wenn das entsprechende Datum ist</li></ul>"};

export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    Table,
    OptionMenu,
    PageHeaderTitleNavigation,
    GhostLoading,
    NoData,
    BaseFilter,
    DownloadLink,
    PhCheck,
    PhX,
    HinweiseUndFehler,
    InputToggleSwitch,
    Pill,
  },
  data: function () {
      const dateCreatedVon = dayjs().subtract(3, "month").format("DD.MM.YYYY");

      return {
        TABLE_TITLE,
        resultCount: null,
        loading: true,
        hints: [HINWEISE],
        defaultPayload: {dateCreatedVon},
        inputFreigabe: false,

      }
  },
  computed: {
    ...mapGetters({
      infobriefeData: INFOBRIEFE_TYPES.GETTERS.INFOBRIEFE_DATA,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      isFA: CORE_TYPES.GETTERS.IS_FA,
    }),
    headerActions() {
      return [];
    },
    canSeeSpecialColumns() {
      return this.inputFreigabe;
    },
    tableHeaders() {

      const lockedLeft =  [
        SlotColumn("fileName", "Dateiname"),
        SlotColumn("freigabe", "Freigabe"),
        DateColumn("dateCreated", "Erstellungsdatum"),
        DateColumn("freigabeAb", "Versand ab"),
        ];

      if (this.canSeeSpecialColumns) {
        return {
          lockedLeft,
          lockedRight: [
              ActionColumn("actions", "Aktionen"),
          ],   
        };

      }

      return {
        lockedLeft,
        lockedRight: [],   
      };   
         
    },       
    tableRows () {
      if (!this.infobriefeData?.rows?.length ) {
        return [];
      }

      const fullActions = (row) => [
          SimpleAction("FREIGEBEN", '', row?.freigabe ? 'Freigabe widerrufen' : 'Freigeben'),
      ];

      return this.infobriefeData?.rows?.map(data => ({
          actions: this.infobriefeData?.inputFreigabe || this.canSeeSpecialColumns ? fullActions(data) : [],
          ...data
      }));




    },
    filterMetadata() {
      let result = [
          {
            type: "group",
            key: "dateCreated",
            label: "Erstellungsdatum",
            menuItems: [
              {
                type: "default",
                label: "Alle",
                key: "VALUE_ALLE",
              },
              {
                type: "default",
                label: "letzte 3 Monate",
                key: "LETZTEN_3_MONATEN",
              },
              {
                type: "dateRange",
                label: "Zeitraum",
                key: "ZEITRAUM",
              },
              {
                type: "integer",
                label: "Jahr",
                key: "jahr",
              },

            ],
          },
          {
            type: "group",
            key: "freigabeAb",
            label: "Versand ab",
            menuItems: [
              {
                type: "default",
                label: "Alle",
                key: "VALUE_ALLE",
              },
              {
                type: "default",
                label: "letzte 3 Monate",
                key: "LETZTEN_3_MONATEN",
              },
              {
                type: "dateRange",
                label: "Zeitraum",
                key: "ZEITRAUM",
              },
              {
                type: "integer",
                label: "Jahr",
                key: "JAHR",
              },

            ],
          },
                
        ];


        return result;
    },   
    defaultOptionsData() {
      return {
        LETZTEN_3_MONATEN: {
          value: true
        },
      };

    }
  },
  mounted() {
    this.retrieveData(this.defaultPayload);  
  },
  methods: {
    retrieveData(payload) {


      this.loading = true;

      this.$store.dispatch(
        INFOBRIEFE_TYPES.ACTIONS.RETRIEVE_INFOBRIEFE, payload
            ).then((response) => {
                this.inputFreigabe = response?.inputFreigabe;
                this.loading = false;
            });  

    },
    vorVersandFreigeben() {


      this.loading = true;

      this.$store.dispatch(
        INFOBRIEFE_TYPES.ACTIONS.VOR_VERSAND_FREIGEBEN, {}
        ).then((response) => {
            this.inputFreigabe = response?.inputFreigabe;
            this.refreshKey++;
            this.loading = false;
        });  

    },
    filterForm(filterConfig) {
      let payload = {};
      const dateCreated = filterConfig.find(fc => fc.group == "dateCreated");
      const freigabeAb = filterConfig.find(fc => fc.group == "freigabeAb");

      filterConfig.forEach(fc => {

        if (dateCreated && fc.key =='ZEITRAUM') {
          payload["dateCreatedMin"] = fc.value.find(v => v.key == 'min').value
          payload["dateCreatedMax"] = fc.value.find(v => v.key == 'max').value;
        } else if (dateCreated && fc.key =='jahr') {
          payload["dateCreatedJahr"] = fc.value;
        } else if (dateCreated && fc.key =='LETZTEN_3_MONATEN') {
          const fromDate = dayjs().subtract(3, "month").format("DD.MM.YYYY");
          payload["dateCreatedVon"] = fromDate;
        } else if (dateCreated && fc.key =='JAHR') {
          payload["dateCreatedVonYear"] = fc.value;
        } else if (freigabeAb && fc.key =='ZEITRAUM') {
          payload["freigabeAbMin"] = fc.value.find(v => v.key == 'min').value
          payload["freigabeAbMax"] = fc.value.find(v => v.key == 'max').value;
        } else if (freigabeAb && fc.key =='JAHR') {
          payload["freigabeAbYear"] = fc.value;
        } else if (freigabeAb && fc.key =='LETZTEN_3_MONATEN') {
          const freigabeAb = dayjs().subtract(3, "month").format("DD.MM.YYYY");
          payload["freigabeAb"] = freigabeAb;
        }

      });

      // console.log(payload);
      this.retrieveData(payload) 


    },
    freigeben(row) {
      this.loading = true;

      this.$store.dispatch(
        INFOBRIEFE_TYPES.ACTIONS.FREIGEBEN, {fileName: row?.fileName}
            ).then(() => {
                this.loading = false;
            });  
    },
    isFileDateAfterToday(row) {
      const format = 'DD.MM.YYYY';
      const freigabeAb = dayjs(row.freigabeAb, format);
      const today = dayjs(dayjs().format(format), format);
      return freigabeAb.isAfter(today , 'day');
    }

  },
  async beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu Infobriefe', 
      fullPath: from.fullPath,
      breadcrumb: to?.meta?.breadcrumb?.label,
    });

    // this.$store.commit(MR_MONEY_TYPES.MUTATIONS.RESET_LIST);

    next();
  },


    
}
</script>
<style scoped>
.background-orange {
  background-color: var(--color-warning) !important;
}
</style>