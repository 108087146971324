var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.title
        ? _c("div", { staticClass: "box__title" }, [_vm._v(_vm._s(_vm.title))])
        : _vm._e(),
      _vm.loading
        ? _c("GhostLoading", { attrs: { type: "table" } })
        : _c("Table", {
            attrs: { headers: _vm.headers, rows: _vm.rows },
            on: {
              "action-ADD": function($event) {
                return _vm.saveBiproGesellschaft($event)
              },
              "action-DELETE": function($event) {
                return _vm.deleteBiproGesellschaft($event)
              }
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }