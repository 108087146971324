import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import GESELLSCHAFT_TYPES from './types';

/**
 * mutations: synchronous operation responsible for change the state
 * actions: asynchronous operation responsible for call external resources and commit mutations 
 */
export function getInitialState() {
  return {
    comboboxValues: {},
    gesellschaftData: {
      gesellschaftId: null,
      gesellschaftName: null,
      gesellschaftArt: null,
      zentralData: {},
      ansprechpartnerData: {},
      formulareData: {},
      newsData: {},
      kommunikationData: {},
      nodeId: null,
      gesellschaftLoggedUser: {},
      gesellschaftsInfos: {},
    },
    gesellschaft: {

    },
  }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations,
    [GESELLSCHAFT_TYPES.MUTATIONS.RESET_STATE](state) { // logout
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}