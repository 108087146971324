import TAN_TYPES from './types';
import Vue from 'vue'

export default {
  [TAN_TYPES.MUTATIONS.SET_COMDIRECT_SESSION](state, payload) {
    Vue.set(state, 'getComdirectSession', payload );
  },

  [TAN_TYPES.MUTATIONS.SET_COMDIRECT_TAN_REQUIRED](state, payload) {
    if (payload.value !== undefined){
      Vue.set(state, 'isComdirectTanRequired', payload.value)
    }
  },
}

