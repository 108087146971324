<template>
  <span v-bind:title="accountTypeDescription">
    <ph-bank v-if="accountType === 'BANKACCOUNT'" :size="24" />
    <ph-credit-card v-else-if="accountType === 'CREDITCARD'" :size="24" />
    <ph-chart-line v-else-if="accountType === 'DEPOT'" :size="24" />
    <ph-bank v-else :size="24" />
  </span>
</template>

<script>
import { PhBank, PhCreditCard, PhChartLine } from 'phosphor-vue'
export default {
  props: {
    accountType: {
      default: null,
    },
  },
  components: {
    PhBank, 
    PhCreditCard, 
    PhChartLine
  },
  computed: {
    accountTypeDescription() {
      switch (this.accountType) {
        case 'CREDITCARD':
          return 'Kreditkarte';
        case 'DEPOT':
          return 'Depot';
        case 'BANKACCOUNT':
        default:
          return 'Konto';
      }
    },
  },
}
</script>

<style>
i {
  display: inline-block;
  margin: 0 8px;
}
</style>