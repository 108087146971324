import RUHESTANDSPLANUNG_TYPES from './types';
import { getInitialState } from './index';
import Vue from 'vue';

export default {
  [RUHESTANDSPLANUNG_TYPES.MUTATIONS.GET_SCENARIOS_SUCCESS](state, payload) {
    state.scenarios = payload.scenarios;
    state.dataHasChanged = false;
  },
  [RUHESTANDSPLANUNG_TYPES.MUTATIONS.SCENARIOS_URSPRUNG](state, payload) {
    state.scenariosUrsprung = payload.scenarios?.map(scenario => { return {
      ...scenario,
      ausgaben: scenario.ausgaben?.map(ausgabe => Object.assign({}, ausgabe)) || null,
      vermoegen: scenario.vermoegen?.map(verm => Object.assign({}, verm)) || null,
      myGoalList: scenario.myGoalList?.map(goal => Object.assign({}, goal)) || null,
      scenarioGoalList: scenario.scenarioGoalList?.map(goal => Object.assign({}, goal)) || null,
      isUrsprung: true,
    }});
  },
  [RUHESTANDSPLANUNG_TYPES.MUTATIONS.GET_VERMOEGEN_SUCCESS](state, payload) {
    state.vermoegen = payload.vermoegen;
    state.ausgaben = payload.ausgaben;
    state.comboboxGoalTypes = payload.comboboxGoalTypes;
  },
  [RUHESTANDSPLANUNG_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },
  [RUHESTANDSPLANUNG_TYPES.MUTATIONS.UPDATE_STORE](state, {value, id}) {
    if (value) {
      let scenario;
      const index = state.scenarios?.findIndex(scenario => scenario.id === id);
      if (index >= 0) {
        scenario = Object.assign(state.scenarios[index], value);
        state.scenarios[index] = scenario;
      } else if (id === 'new') {
        scenario = Object.assign({}, value, {id: id});
        if (!state.scenarios) {
          state.scenarios = [];
        }
        state.scenarios.push(scenario);
      }
      state.dataHasChanged = true;
    }
  },
  [RUHESTANDSPLANUNG_TYPES.MUTATIONS.UPDATE_SELECTED_GOALS](state, {goal, id}) {
    if (goal?.id && id) {
      const index = state.scenarios?.findIndex(scenario => scenario.id === id);
      if (index >= 0) {
        if (goal.goalType === 'MY_GOAL') {
          const indexMyGoal = state.scenarios[index].myGoalList?.findIndex(myGoal => myGoal.id === goal.id);
          if (indexMyGoal >= 0 && !goal.selected) {
            state.scenarios[index].myGoalList.splice(indexMyGoal, 1);
            state.scenarios[index].myGoalChanged = true;
          } else if ((indexMyGoal === undefined || indexMyGoal < 0) && goal.selected) {
            if (!state.scenarios[index].myGoalList) {
              state.scenarios[index].myGoalList = [];
            }
            state.scenarios[index].myGoalList.push({ id: goal.id, startDate: goal.startDate, endDate: goal.endDate, zielsumme: goal.zielsumme, title: goal.title});
            state.scenarios[index].myGoalChanged = true;
          }
        } else if (goal.goalType === 'SCENARIO_GOAL') {
          const indexScGoal = state.scenarios[index].scenarioGoalList?.findIndex(sc => sc.id === goal.id);
          if (indexScGoal >= 0) {
            state.scenarios[index].scenarioGoalList[indexScGoal].selected = goal.selected || false;
            state.scenarios[index].scenarioGoalChanged = true;
          }
        }
      }
      state.dataHasChanged = true;
    }
  },
  [RUHESTANDSPLANUNG_TYPES.MUTATIONS.UPDATE_VERMOEGEN_AUSGABEN](state, {row, id, type}) {
    if (row && id && type) {
      const index = state.scenarios?.findIndex(scenario => scenario.id === id);
      if (index >= 0) {
        if (!state.scenarios[index]?.[type]) {
          state.scenarios[index][type] = [];
        }
        const index2 = state.scenarios[index]?.[type]?.findIndex(verm => verm.name === row.name);
        if (index2 < 0) {
          state.scenarios[index][type].push(row);
        } else {
          state.scenarios[index][type][index2] = Object.assign({}, state.scenarios[index][type][index2], row);
          state.scenarios[index][type] = Object.assign([], state.scenarios[index][type]);
        }
      } else if (id === 'new') {
        const scenario = {id: id, [type]: [row]};
        if (!state.scenarios) {
          state.scenarios = [];
        }
        state.scenarios.push(scenario);
      }
      state.dataHasChanged = true;
    }
  },
}