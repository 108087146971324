<template>
    <button type="button" @click="onClick" :tid="_generateTidFromString(tid + actionKey)" class="btn-clean clickable">
        <component :is="iconComponent" :size="size"/>
        <span v-if="expanded">
            {{label}}
        </span>
    </button>
</template>
<script>
import actionMixin from './action-mixin.js';

export default {
    mixins: [actionMixin],
}
</script>
<style src='./action.scss' lang='scss' scoped/>
