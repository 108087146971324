<template   >
  <div class="event-body">
    <BaseModal
      ref="modal"
      @onCloseButton="close()"
      :showCloseButton="true"
      :showDefaultButtons="false"
      :autoClose="false"
      size="full"
    >
      <template v-slot:modalTitle> {{ title }} </template>
      <BoxContainer>
        <div v-if="dat && dat.sid">
          <InputField label="sid" v-model="dat.sid" :disabled="true" />
          <InputField label="serial" v-model="dat.serial" :disabled="true" />
          <InputField label="client id" v-model="dat.ident" :disabled="true" />
          <InputField label="status" v-model="dat.status" :disabled="true" />
          <InputField label="login" v-model="dat.logontime" :disabled="true" />
          <InputField label="Machine" v-model="dat.machine" :disabled="true" />
          <InputField label="Os User" v-model="dat.osuser" :disabled="true" />
          <InputField label="Db User" v-model="dat.userId" :disabled="true" />
          <InputField
            label="Programm"
            v-model="dat.programm"
            :disabled="true"
          />
          {{ dat.sqlText }}<br />
        </div>
        <!--inputTextArea :rows="12" :hideScrollBar="true" :autoGrow ="true"  :isEmbedded="true" readonly v-model="dat.sqlText"  />
        <inputTextArea :rows="12" :hideScrollBar="true" :autoGrow ="true" :disabled="true"  v-model="dat.sqlText"  /-->
      </BoxContainer>

      <!--div  class="base-modal__footer">
            <slot name="footer"></slot>
              <BaseButton v-if="dat  &&dat.sid"
              class="base-modal__footer--button"
              isSecondary
              @click="loadData">reload</BaseButton>
      </div-->
      <template v-slot:footer>
        <BaseButton v-if="dat && dat.killable"
          @click="openCponf"
          isPrimary
          :disabled="loading || !dat||!dat.killable || !dat.sid"
          >Kill Session!!!</BaseButton
        >
        <BaseButton @click="close" isSecondary>close</BaseButton>
        <BaseButton
          :disabled="loading || !dat || !dat.sid"
          @click="loadData"
          isSecondary
          >reload</BaseButton
        >
      </template>
    </BaseModal>
    <BaseModal ref="conf" labelButtonConfirm="yes" labelButtonCancel="no"
      @onConfirmButton="doIt" >
      <template v-slot:modalTitle>
        
        Are you sure you want to kill +{{ title }} ?
      </template>
     
    </BaseModal>  

  </div>
</template>
<script>
import DB_TYPES from "@/store/dbm/types";

import { mapGetters } from "vuex";

import InputField from "@/components/core/forms/InputField.vue";
import BaseModal from "@/components/core/BaseModal.vue";

import BoxContainer from "@/components/core/BoxContainer.vue";
import InputTextArea from "@/components/core/forms/InputTextArea.vue";
import BaseButton from "@/components/core/BaseButton.vue";
export default {
  components: {
    BaseModal,
    InputField,
    InputTextArea,
    BoxContainer,
    BaseButton,
  },
  watch: {},
  computed: {
    title() {
      let result =
        "Session sid:" +
        this.sid +
        " serial#:" +
        this.serial +
        (this.dat && this.dat.ident ? " " + this.dat.ident : "");
      return result;
    },
  },

  data() {
    return {
      isLoaded: false,
      loading: false,
      sid: null,
      serial: null,
      dat: null,
    };
  },

  mounted() {},
  beforeDestroy() {},
  methods: {
    async open(si, ser) {
      this.sid = si;
      this.serial = ser;
      this.dat = null;
      await this.loadData();

      this.$refs.modal?.open();
      this.loading = false;
    },
    close() {
      this.$store.commit(
        DB_TYPES.MUTATIONS.START_TIME,
        new Date().getTime() - 6000000000
      );
      this.$refs.modal.close();
    },

    async loadData() {
      this.loading = true;
      await this.$store.dispatch(DB_TYPES.ACTIONS.SESSION, {
        sid: this.sid,
        serial: this.serial,
      });

      this.dat = this.$store.getters[DB_TYPES.GETTERS.SESSION];
      this.isLoaded = true;
      this.loading = false;
    },

    async openCponf(){
      this.$refs.conf?.open();
    },
    async doIt() {
      try {
        this.loading = true;
        await this.$store.dispatch(DB_TYPES.ACTIONS.KILL_SESSION, {
        sid: this.sid,
        serial: this.serial,
        
      });
      this.dat = this.$store.getters[DB_TYPES.GETTERS.SESSION];
      if(!this.dat.sid){
        this.close()
      }
       /* if (confirm("Are you sure you want to kill this Session?")) {
        } else {
        }*/
      } finally {
         this.loading = false;
      }
    },
  },
};
</script>
