import actions from './actions';
import mutations from './mutations';
import getters from './getters';

/**
 * mutations: synchronous operation responsible for change the state
 * actions: asynchronous operation responsible for call external resources and commit mutations 
 */
export function getInitialState() {
  return {
    stornoWarnungOverview: [],
    stornoWarnungList: {},
    pageIndex: 0,
    totalRows: 0,
    defaultFilters: {
      CHECK_ALLES_ANZEIGEN: true,
      CHECK_MIT_ANTRAEGEN: true,
      CHECK_MIT_AUSWEISEN: true,
      INPUT_MONTH_UNBESTAETIGT: '1',
    },
    stornoArts: [],
    isLoading: false,
    stornoSelected: null,
    isFilterSaved: false,
  }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}