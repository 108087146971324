import store from '@/store';
import CORE_TYPES from '@/store/core/types';

import menuTrack, { recoverList } from '@/menu/menu-track';

function configureMenuTrack(to, from) {
  if (menuTrack.started) {
    if (!store.getters[CORE_TYPES.GETTERS.IS_LOGGED_IN]) {
      menuTrack.close();
    }

    return;
  }

  menuTrack.start(to?.path);
};

const trackedMenuHistory = {};
function trackMenuHistory(to, from) {
  if (!menuTrack.started || to?.path?.includes('/noop')) return;

  requestAnimationFrame(() => { // TODO check if there is some key / id for the state on the router stack instead of use the history API directly
    const key = history.state?.key || to.path;

    if (to?.query?.backAction) {
      const savedList = trackedMenuHistory?.[key];
      if (savedList?.length > 0) {
        recoverList(savedList);
      }
    }

    if (!(key in trackedMenuHistory)) {
      trackedMenuHistory[key] = menuTrack.copy();
    }
  });
}

export function registerMenuGuards(router) {
  if (!router) {
    console.error('a router instance must be passed to "registerMenuGuards"');
    return;
  }

  router.afterEach(configureMenuTrack);
  router.afterEach(trackMenuHistory);
}
