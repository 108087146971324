<template>
  <div @click="pressRate" class="box spec_button rate">Rate</div>
</template>

<script>
import functionMixins from "../../functionMixins.js";
//import store from "./../../this.$store.js";
export default {
  name: "Rate",
  mixins: [functionMixins],
  methods: {
    pressRate() {
      this.$store.commit("SET_OPERATORCLICKED", false);
      this.$store.commit("SET_KOMMACLICKED", false);
      if (
        this.$store.getters.getAnzahl !== "0" &&
        this.$store.getters.getJahre !== "0" &&
        this.$store.getters.getZins !== "0" &&
        this.$store.getters.getStart !== "0"
      ) {
        var anzahl = this.$store.getters.getAnzahl;
        var jahre = this.$store.getters.getJahre;
        var start = this.$store.getters.getStart;
        var zins = this.$store.getters.getZins;
        var result = this.calcRatefromStart(start, zins, jahre, anzahl);
        result = Math.round(result * 100) / 100;
        this.$store.commit("SET_RATE", this.formatNumber(result));
        var ende = result * anzahl * jahre;
        ende = Math.round(ende * 100) / 100;
        this.setRsLabels(start, ende);
      } else if (
        this.$store.getters.getAnzahl !== "0" &&
        this.$store.getters.getJahre !== "0" &&
        this.$store.getters.getZins !== "0" &&
        this.$store.getters.getEnde !== "0"
      ) {
        anzahl = this.$store.getters.getAnzahl;
        jahre = this.$store.getters.getJahre;
        ende = this.$store.getters.getEnde;
        zins = this.$store.getters.getZins;
        result = this.calcRatefromEnde(ende, zins, jahre, anzahl);
        result = Math.round(result * 100) / 100;
        this.$store.commit("SET_RATE", this.formatNumber(result));
        start = Number(result) * Number(anzahl) * Number(jahre);
        start = Math.round(start * 100) / 100;
        this.setRsLabels(start, ende);
      } else if (this.$store.getters.getCurrent !== "") {
        this.$store.commit("SET_RATE", this.$store.getters.getCurrent);
        this.$store.commit("SET_CURRENT", "");
      }
    }
  }
};
</script>

<style scoped>
.minus {
  grid-area: rate;
}
</style>