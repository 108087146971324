import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import dayjs from 'dayjs';

/**
 * mutations: synchronous operation responsible for changing the state
 * actions: asynchronous operation responsible for calling external resources and committing mutations 
 */

export function getNewMarketingCampaign() {
  return {
    title: '',
    body: '',
    dateFrom: dayjs().startOf('date').toDate(),
    dateUntil: dayjs().add(7, 'day').endOf('date').toDate(),
    active: false,
  }
}

export function getInitialState() {
  return {
    pendingMarketingCampaigns: [],
    postponeMarketingCampaign: [],
    marketingCampaigns: [],

    targetList: [],
    editingMarketingCampaign: getNewMarketingCampaign(),

    changed: false,
  }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}