<template>
  <BaseToolbarButton 
    title="Unlink"
    :icon="icon"
    :disabled="!active || disabled"
    @click="action"
    content="Link entfernen"
    v-tippy="{ arrow: true }"/>
</template>

<script>
import { PhLinkBreak } from 'phosphor-vue';
import BaseToolbarButton from '../base-components/BaseToolbarButton.vue';

export default {
  components: {
    BaseToolbarButton,
  },

  props: {
    editor: {
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    icon() {
      return PhLinkBreak;
    },
    active() {
      return this.editor?.isActive('link')
    }
  },

  methods: {
    action() {
      this.editor?.chain().focus().unsetLink().run()
    }
  }

}
</script>
