<template>
  <div>
    <label>Profilbild</label>

    <ImageUpload
      :tid="_generateTidFromString('user-picture')"
      class="user-picture"
      :image="isAnsprechpartner ? ansprecpartnerPicture : userPicture"
      :loading="loading"
      :config="imageUploadConfig"
      :disabled="loading"
      uploadButtonText="Profilbild hochladen"
      @file="
        isAnsprechpartner
          ? uploadImageAnsprechpartner($event)
          : uploadImage($event)
      "
      @change="
        isAnsprechpartner
          ? uploadImageAnsprechpartner($event)
          : uploadImage($event)
      "
    />

    <div class="mt-24px mb-0">
      <BaseButton
        isSecondary
        @click=" isAnsprechpartner ? deleteImageAnsprechpartner() : deleteImage()"
        :disabled="loading"
        :tid="_generateTidFromString('Profilbild löschen')"
        >Profilbild löschen</BaseButton
      >
    </div>
  </div>
</template>
<script>
import ImageUpload from "@/components/fileUpload/ImageUpload.vue";
import { mapGetters } from "vuex";
import BaseButton from "@/components/core/BaseButton.vue";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import { base64ToArrayBuffer } from "@/helpers/download-helper.js";
import BROKERDATA_TYPES from "@/store/brokerData/types";
import ANSPRECHPARTNER_TYPES from "@/store/ansprechpartner/types";

export default {
  mixins: [InteractiveHelpCommonsMixin],
  props: {
    isAnsprechpartner: Boolean,
    ansprechPartnerId: String,
  },
  data() {
    return {
      userPicturePreview: null,
      loading: false,
      imageUploadConfig: {
        height: "212px",
        fit: "contain",
      },
    };
  },
  computed: {
    ...mapGetters({
      brokerPictureData: BROKERDATA_TYPES.GETTERS.GET_MAKLER_PICTURE,
      ansprechPartnerPictureData:
        ANSPRECHPARTNER_TYPES.GETTERS.GET_USER_PICTURE_DATA,
    }),
    isDefaultImage() {
      return !this.userPicturePreview || !this.userPictureData?.bytes;
    },
    userPicture() {
      if (this.userPicturePreview) {
        return this.userPicturePreview;
      } else {
        return this.getImgSrc(this.brokerPictureData);
      }
    },
    ansprecpartnerPicture() {
      if (this.userPicturePreview) {
        return this.userPicturePreview;
      } else {
        return this.getImgSrc(this.ansprechPartnerPictureData);
      }
    },
  },
  watch: {
    ansprechPartnerId() {
      this.findAnsprechpartnerPicture();
    },
  },
  components: {
    ImageUpload,
    BaseButton,
  },
  mounted() {
    this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.GET_MAKLER_PICTURE);
    this.findAnsprechpartnerPicture();
  },
  methods: {
    findAnsprechpartnerPicture() {
      if (this.isAnsprechpartner && this.ansprechPartnerId) {
        const payload = {
          personId:  this.ansprechPartnerId
        }
        this.$store.dispatch(
          ANSPRECHPARTNER_TYPES.ACTIONS.GET_USER_PICTURE,
          payload
        );
      }
    },
    getImgSrc(img) {
      if (img && img.bytes) {
        const byteArray = base64ToArrayBuffer(img.bytes);
        const file = new Blob([byteArray], {
          type: img.responseContentType?.mimeType,
        });
        const linkUrl = URL.createObjectURL(
          new File([file], img.fileName, { type: file.type })
        );
        return linkUrl;
      }
      return "";
    },
    uploadImage(file) {
      this.loading = true;
      this.userPicturePreview = URL.createObjectURL(file);

      let payload = {
        file: file,
      };
      this.$store
        .dispatch(BROKERDATA_TYPES.ACTIONS.SAVE_MAKLER_PICTURE, payload)
        .finally(() => (this.loading = false));
    },
    uploadImageAnsprechpartner(file) {
      this.loading = true;
      this.userPicturePreview = URL.createObjectURL(file);
      if (this.ansprechPartnerPictureData && this.ansprechPartnerId) {
        let payload = {
          file: file,
          personId: String(this.ansprechPartnerId),
        };
        this.$store
          .dispatch(ANSPRECHPARTNER_TYPES.ACTIONS.SAVE_USER_PICTURE, payload)
          .finally(() => (this.loading = false));
      } else {
        let payload = {
          file: file,
        };
        this.$store.commit(
          ANSPRECHPARTNER_TYPES.MUTATIONS.SET_TEMP_USER_PICTURE,
          payload
        );
        this.loading = false;
      }
    },
    async deleteImage() {
      this.loading = true;
      this.userPicturePreview = null;
      await this.$store.dispatch(
        BROKERDATA_TYPES.ACTIONS.DELETE_MAKLER_PICTURE
      );
      this.loading = false;
    },
    async deleteImageAnsprechpartner(){
        this.loading = true;
      this.userPicturePreview = null;
      let payload = {
         personId: String(this.ansprechPartnerId),
      }
      await this.$store.dispatch(ANSPRECHPARTNER_TYPES.ACTIONS.DELETE_USER_PICTURE, payload);
      this.loading = false;
    }
  },
};
</script>
<style scoped>
.user-picture {
  max-width: 100%;
  width: 100%;
}
.user-picture.no-image {
  max-width: 312px;
}
</style>
