var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "modal",
    attrs: { showDefaultButtons: false },
    on: { onCloseButton: _vm.close },
    scopedSlots: _vm._u([
      {
        key: "modalTitle",
        fn: function() {
          return [
            _c("div", { staticClass: "mb-4" }, [
              _vm._v(
                "Erfassen Sie eine E-Mailadresse, an die die Registrierungsaufforderung für einen Unterzugang zur " +
                  _vm._s(_vm.userLevel) +
                  " versendet wird."
              )
            ]),
            _c("InputField", {
              model: {
                value: _vm.email,
                callback: function($$v) {
                  _vm.email = $$v
                },
                expression: "email"
              }
            })
          ]
        },
        proxy: true
      },
      {
        key: "footer",
        fn: function() {
          return [
            _c(
              "BaseButton",
              { attrs: { isSecondary: "" }, on: { click: _vm.close } },
              [_vm._v("Abbrechen")]
            ),
            _c(
              "BaseButton",
              {
                attrs: {
                  disabled: !_vm.validateEmail(_vm.email),
                  isPrimary: ""
                },
                on: { click: _vm.invite }
              },
              [_vm._v("Einladen")]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }