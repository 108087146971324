<template>
  <div @click="getResult()" class="box norm_button equalizer">=</div>
</template>

<script>
import functionMixins from "../../functionMixins.js";
export default {
  name: "Equalizer",
  mixins: [functionMixins]

};
</script>

<style scoped>
.equalizer {
  grid-area: equalizer;
}
</style>