import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';
import FILEUPLOAD_TYPES from '@/store/fileUpload/types';

import dayjs from 'dayjs';

export default {
  props: {
    customer: {
      type: Object,
      default: () => ({}),
    },
    deckblattType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      form: {},
    };
  },
  computed: {
    ...mapGetters({
      deckblattCombosData: FILEUPLOAD_TYPES.GETTERS.DECKBLATT_COMBOS,
      comboValues: FILEUPLOAD_TYPES.GETTERS.COMBO_VALUES,
      isFK: CORE_TYPES.GETTERS.IS_FK,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      isAVL: CORE_TYPES.GETTERS.IS_AVL,
    }),
    deckblattCombos() {
      return this.deckblattCombosData(this.customer?.customerID);
    },
  },
  watch: {
    'validation.updated'() {
      this.$emit('updateValidation');
    },
  },
  methods: {
    dataChanged() {
      this.$emit('dataChanged', { ...this.form });
    },
    dataChangedCombo(field, value) {
      this.$set(this.form, field, value);
      this.dataChanged();
    },
    setStandDatumZeit() {
      const currentDate = new Date();
      this.$set(this.form, 'standVom', dayjs(currentDate).format('DD.MM.YYYY'));
      this.$set(this.form, 'uhrzeit', dayjs(currentDate).format('HH:mm'));
      this.dataChanged();
    },
  },
}
