var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseButton",
    {
      staticClass: "hide-on-small-screen",
      attrs: { isClear: "" },
      on: {
        click: function($event) {
          return _vm.retrieveTutorials()
        }
      }
    },
    [
      _c("ph-question", { attrs: { size: _vm.size } }),
      _c(
        "BaseModal",
        {
          ref: "savedTutorialsModal",
          attrs: {
            modalTitle: "Verfügbare Tutorials",
            showConfirmButton: false,
            showCancelButton: true,
            labelButtonCancel: "Abbrechen"
          }
        },
        [
          _vm.rows.length
            ? _c("p", [
                _c("b", [
                  _vm._v(
                    "Bitte wählen Sie einen Tutorial-Titel, um die Präsentation zu starten"
                  )
                ])
              ])
            : _c("p", [_vm._v(" Keine Tutorials für diese Seite verfügbar ")]),
          _vm.rows.length
            ? _c("Table", {
                attrs: {
                  headers: _vm.headers,
                  rows: _vm.rows,
                  selected: _vm.isTableEditable ? _vm.selectedRows : undefined,
                  rowsPerPage: 10
                },
                on: {
                  selected: function($event) {
                    _vm.selectedRows = $event
                  },
                  "click-title": _vm.startTutorial,
                  "action-EDIT": _vm.editTutorial,
                  "action-DELETE": _vm.confirmDeleteTutorial
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "deleteTutorialConfirmation",
          attrs: {
            modalTitle: "Tutorial löschen",
            labelButtonConfirm: "Löschen"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.deleteTutorial()
            }
          }
        },
        [_vm._v(" " + _vm._s(_vm.deleteTutorialMessage) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }