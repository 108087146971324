<template>
  <div class="sofunktionierts_container">
    <div class="banner">
      <editable-frame type="sofunktionierts_title_page" />
    </div>
    <hr />
    <div class="dreispalten_wrapper">
      <div class="dreispalten">
        <div id="spalte1">
          <div class="drei_spalten_image">
            <img alt="Icon für die Erklärung" src="@/../public/img/cms/fonds1.png" />
          </div>
          <div class="drei_spalten_banner">
            <h3>1. Fonds, Modellportfolio oder VL-Fonds auswählen</h3>
          </div>
          <hr />
          <div class="drei_spalten_text">
            <p>
              Zunächst informieren Sie sich umfassend und selbständig über unser Angebot innerhalb des Fondsshops. 
              Anschließend wählen Sie ebenfalls auf eigene Initiative Ihre persönlichen Fondsfavoriten aus über einhundert möglichen 
              Einzelfonds oder Sie entscheiden sich für die vielen Vorzüge eines fertigen Modellportfolios. 
              Ausserdem können Sie über den Menüpunkt „VWL-Fondsauswahl“ auch einen Vertrag für Vermögenswirksame Leistungen auswählen.
            </p>
            <p>
              Entscheiden Sie selbst, ob Sie nur einmalig anlegen möchten oder mit einem Sparplan die 
              Möglichkeiten einer Fondsanlage regelmäßig und dauerhaft nutzen möchten.
            </p>
            <p>
              Beachten Sie dabei, dass unser Fondsshop keine persönliche Anlageberatung für Sie bietet. 
              Er ist für Kunden konzipiert, die als Selbstentscheider eine eigene Investmententscheidung in die Tat umsetzen wollen. 
              Für technische Fragen zur Nutzung des Fondsshops oder eine alternative persönliche Beratung stehen wir 
              Ihnen gerne über unser Kontaktformular zur Verfügung.
            </p>
          </div>
          <hr />
        </div>
        <div id="spalte2">
          <div class="drei_spalten_image">
            <img alt="Icon für die Erklärung" src="@/../public/img/cms/fonds2.png" />
          </div>
          <div class="drei_spalten_banner">
            <h3>2. Dokumente erstellen</h3>
          </div>
          <hr />
          <div class="drei_spalten_text">
            <p>
              Nachdem Sie Ihr Modellportfolio, Ihre Einzelfonds oder Ihren VL-Fonds selbständig ausgewählt haben, 
              können Sie Ihren Fondskauf nun abschließen. Dazu benötigen Sie ein Depot.
            </p>
            <p>
              Im zweiten Schritt legen Sie deshalb Ihre(n) gewünschten Fonds in den “Warenkorb” und gehen anschließend zur Depoteröffnung!
            </p>
            <p>
              Nun registrieren Sie sich als neuer Kunde oder melden sich mit Ihren bereits vorhandenen Kundendaten (Kundennummer / Passwort) im 
              Fondsshop an. Dann schließen Sie mit Eingabe Ihrer Stammdaten den Antrag auf Depoteröffnung erfolgreich ab. 
              Hierbei legen wir hohen Wert auf die Einhaltung des Datenschutzes und die Sicherheit Ihrer Daten. 
              Nach Kenntnisnahme Konto- und Depotbezogener Unterlagen und abschließender rechtlicher Hinweise steht Ihrem Investment nichts mehr im Wege.
            </p>
            <p>
              Prüfen Sie abschließend die auf Basis Ihrer Eingaben erstellten Unterlagen sorgfältig und unterschreiben 
              Sie an allen gekennzeichneten und relevanten Stellen. Die Depoteröffnungsunterlagen bestehen aus
            </p>
            <ul>
              <li>Checkliste zur Depoteröffnung</li>
              <li>Rahmenvereinbarung zur Nutzung des Fondsshops</li>
              <li>Depoteröffnungsunterlagen der FFB (FIL Fondsbank GmbH)</li>
              <li>Postident Formular</li>
              <li>Anschreiben zum Postversand</li>
            </ul>
            <p>
              Alternativ zur „Klassischen Depoteröffnung“ steht Ihnen auch die „Online Depoteröffnung“ zur Verfügung. 
              Hier können Sie sich zwischen dem Post-Ident oder Video-Ident der Deutschen Post AG entscheiden. 
              Bei diesem Verfahren ist keinerlei Unterschrift oder Ausdruck der Unterlagen notwendig. 
              Der Abschluss erfolgt durch Ihre Zustimmungen im Eröffnungsprozess und wird durch die Legitimation finalisiert.
            </p>
          </div>
          <hr />
        </div>
        <div id="spalte3">
          <div class="drei_spalten_image">
            <img alt="Icon für die Erklärung" src="@/../public/img/cms/fonds3.png" />
          </div>
          <div class="drei_spalten_banner">
            <h3>3. Depoteröffnungsunterlagen weiterleiten</h3>
          </div>
          <hr />
          <div class="drei_spalten_text">
            <p>
              Wenn Sie die „Klassische Depoteröffnung“ gewählt haben ist es nun notwendig den Antrag zur 
              Depoteröffnung nun an die FFB (FIL Fondsbank GmbH) weiterzuleiten, und somit die Abbuchung der gewünschten Beträge zu veranlassen. 
              Hierzu drucken Sie bitte die Unterlagen komplett aus und bringen Sie zu Ihrem Finanzberater.
            </p>
            <p>
              Dieser führt an Hand Ihres Personalausweises oder Reisepasses Ihre 
              Legitimation durch und leitet die Unterlagen an die FFB (FIL Fondsbank GmbH) weiter. 
              Dort werden die Unterlagen auf Vollständigkeit und Richtigkeit geprüft und Ihr Depot incl. 
              Verrechnungskonto wird innerhalb weniger Tage eröffnet.
            </p>
            <p>
              Über die erfolgreiche Depoteröffnung und die Abbuchung werden Sie postalisch informiert. 
              Ebenso über alle weiteren Umsätze und Bewegungen innerhalb Ihres Kontos und Depots.
            </p>
            <p>
              Die Zugangsdaten für Ihren persönlichen Login via PC oder App erhalten Sie ebenfalls per Post.
            </p>
            <p>
              Mit Ihrem persönlichen Login steht Ihnen nach erfolgreicher Depoteröffnung das komplette Fondsuniversum der FFB (FIL Fondsbank GmbH) zur Verfügung.
            </p>
          </div>
          <hr />
        </div>
      </div>
    </div>

    <div class>
      <base-button @click="$router.push('fonds')">ZUM FONDSSHOP</base-button>
      <hr />
    </div>

    <div class>
      <div class="text_only">
        Die nachfolgenden Darstellungen auf dieser Webseite und innerhalb unseres Fondsshops enthalten keine persönliche Empfehlung zu Geschäften mit bestimmten Finanzinstrumenten.
        Eine Prüfung der persönlichen Umstände des Anlegers erfolgt nicht, ebenso wenig eine Prüfung, ob die jeweiligen Fonds für den Kunden geeignet sind. Beachten Sie hierzu auch die
        untenstehenden Risikohinweise zur Geldanlage in Investmentfonds und die Rechtlichen Hinweise zur Nutzung des Fondsshops.
      </div>
      <hr />
    </div>
    <risiko-hinweise />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CORE_TYPES from "@/store/core/types";
import EditableFrame from "@/components/cms/EditableFrame.vue";
import RisikoHinweise from "@/components/cms/parts/RisikoHinweise.vue";
import BaseButton from "@/components/core/BaseButton.vue";

export default {
  components: {
    EditableFrame,
    BaseButton,
    RisikoHinweise
  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>
<style scoped>
.sofunktionierts_container {
  margin: 0 auto;
  padding-left: 1em;
  padding-right: 1em;
  max-width: 1200px;
}

.drei_spalten_banner {
  margin-top: 0.75em;
  font-weight: bold;
}

.banner {
  font-size: 26px;
  text-align: left;
  color: var(--color-text);
}
.text_only_wrapper {
  padding-top: 2em;
  padding-bottom: 2em;
}
.text_only {
  margin: 0 auto;
  text-align: justify;
}

ul {
  text-align: start;
}

.dreispalten_wrapper {
  padding-top: 2em;
  padding-bottom: 2em;
}

.dreispalten {
  margin: 0 auto;
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
  column-gap: 4em;
  grid-template-areas: " spalte1 spalte2 spalte3 ";
  grid-area: dreispalten;
}

.drei_spalten_text {
  text-align: justify;
}

#fullwidth {
  justify-self: center;
  grid-area: fullwidth;
}

#spalte1 {
  grid-area: spalte1;
}
#spalte2 {
  grid-area: spalte2;
}
#spalte3 {
  grid-area: spalte3;
}
a {
  color: var(--color-link);
}
@media only screen and (max-width: 1024px) {
  .dreispalten {
    grid-template-columns: 1fr;
    grid-template-areas:
      "spalte1"
      "spalte2"
      "spalte3";
  }
}
</style>