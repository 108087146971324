var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "eaf12ff7-9aea-44ad-af08-a969a429ad47",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        width: "1019.24603",
        height: "807.87977",
        viewBox: "0 0 1019.24603 807.87977"
      }
    },
    [
      _c("rect", {
        attrs: {
          x: "560",
          y: "267",
          width: "152",
          height: "152",
          fill: _vm.color
        }
      }),
      _c("rect", {
        attrs: {
          x: "378",
          y: "486",
          width: "284",
          height: "156",
          fill: _vm.color
        }
      }),
      _c("rect", {
        attrs: {
          x: "226",
          y: "235",
          width: "128",
          height: "186",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M764.35023,263.33717c-5.68507,21.01542-25.15772,34.03081-25.15772,34.03081s-10.25532-21.05736-4.57025-42.07279S759.78,221.26438,759.78,221.26438,770.03529,242.32174,764.35023,263.33717Z",
          transform: "translate(-90.37698 -46.06012)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M712.632,263.33717c5.68507,21.01542,25.15771,34.03081,25.15771,34.03081s10.25533-21.05736,4.57026-42.07279-25.15771-34.03081-25.15771-34.03081S706.94689,242.32174,712.632,263.33717Z",
          transform: "translate(-90.37698 -46.06012)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M720.98577,258.82072c15.59991,15.18589,17.50532,38.53013,17.50532,38.53013s-23.387-1.27695-38.98694-16.46283-17.50533-38.53012-17.50533-38.53012S705.38586,243.63484,720.98577,258.82072Z",
          transform: "translate(-90.37698 -46.06012)",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M755.99642,258.82072c-15.59991,15.18589-17.50533,38.53013-17.50533,38.53013s23.387-1.27695,38.98695-16.46283,17.50533-38.53012,17.50533-38.53012S771.59633,243.63484,755.99642,258.82072Z",
          transform: "translate(-90.37698 -46.06012)",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M274.94836,209.72079c21.76581.4669,39.08025,16.24012,39.08025,16.24012s-17.97491,15.01621-39.74072,14.54931-39.08024-16.24012-39.08024-16.24012S253.18256,209.25388,274.94836,209.72079Z",
          transform: "translate(-90.37698 -46.06012)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M165.521,135.4228c19.66447,9.34222,42.21332,3.00712,42.21332,3.00712s-9.33355-21.48183-28.998-30.82405-42.21331-3.00712-42.21331-3.00712S145.85656,126.08058,165.521,135.4228Z",
          transform: "translate(-90.37698 -46.06012)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M167.52,126.139c21.68047-1.98136,40.65735,11.74679,40.65735,11.74679s-16.1741,16.94056-37.85456,18.92192-40.65735-11.74679-40.65735-11.74679S145.8395,128.12038,167.52,126.139Z",
          transform: "translate(-90.37698 -46.06012)",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M246.99377,129.89263C244.9157,151.564,258.55905,170.602,258.55905,170.602s17.01255-16.09835,19.09062-37.76976-11.56528-40.70935-11.56528-40.70935S249.07183,108.22122,246.99377,129.89263Z",
          transform: "translate(-90.37698 -46.06012)",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M349.098,262.80271c-11.37151,18.56494-7.45917,41.65775-7.45917,41.65775s22.34922-7.00691,33.72073-25.57185,7.45916-41.65774,7.45916-41.65774S360.46953,244.23778,349.098,262.80271Z",
          transform: "translate(-90.37698 -46.06012)",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M316.1078,464.65131l1.65723-1.12012a51.91878,51.91878,0,0,1-9.16309-28.79248c.04639-15.05761,7.44238-28.98486,14.59522-42.45361,1.32763-2.5,2.65088-4.99219,3.92382-7.48242a163.33489,163.33489,0,0,0,12.70411-31.89258c11.09472-41.23.17138-89.87354-29.21924-130.12207-23.31543-31.92871-58.07178-59.84863-106.25391-85.35547l-.93555,1.76758c47.90772,25.36133,82.44141,53.08935,105.57422,84.76758,29.03711,39.76367,39.8418,87.77246,28.90284,128.42285a161.39437,161.39437,0,0,1-12.55274,31.50195c-1.269,2.481-2.5874,4.96338-3.91016,7.45459-7.2666,13.68359-14.78076,27.833-14.82861,43.38574A53.937,53.937,0,0,0,316.1078,464.65131Z",
          transform: "translate(-90.37698 -46.06012)",
          fill: "#3f3d56"
        }
      }),
      _c("circle", {
        attrs: { cx: "19.00039", cy: "75.00039", r: "19", fill: "#3f3d56" }
      }),
      _c("path", {
        attrs: {
          d: "M462.377,450.06012h-130v-188h130Zm-128-2h126v-184h-126Z",
          transform: "translate(-90.37698 -46.06012)",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "258",
          y: "266",
          width: "82",
          height: "2",
          fill: _vm.secondColor
        }
      }),
      _c("rect", {
        attrs: {
          x: "257.62302",
          y: "287.93988",
          width: "82",
          height: "2",
          fill: _vm.secondColor
        }
      }),
      _c("rect", {
        attrs: {
          x: "257.24603",
          y: "309.87977",
          width: "82",
          height: "2",
          fill: _vm.secondColor
        }
      }),
      _c("rect", {
        attrs: {
          x: "256.86905",
          y: "331.81965",
          width: "82",
          height: "2",
          fill: _vm.secondColor
        }
      }),
      _c("rect", {
        attrs: {
          x: "256.49207",
          y: "353.75954",
          width: "82",
          height: "2",
          fill: _vm.secondColor
        }
      }),
      _c("rect", {
        attrs: {
          x: "256.11509",
          y: "375.69942",
          width: "82",
          height: "2",
          fill: _vm.secondColor
        }
      }),
      _c("rect", {
        attrs: {
          x: "425",
          y: "506",
          width: "214",
          height: "2",
          fill: _vm.secondColor
        }
      }),
      _c("rect", {
        attrs: {
          x: "424.62302",
          y: "528.93988",
          width: "214",
          height: "2",
          fill: _vm.secondColor
        }
      }),
      _c("rect", {
        attrs: {
          x: "424.24603",
          y: "551.87977",
          width: "214",
          height: "2",
          fill: _vm.secondColor
        }
      }),
      _c("rect", {
        attrs: {
          x: "423.86905",
          y: "574.81965",
          width: "214",
          height: "2",
          fill: _vm.secondColor
        }
      }),
      _c("rect", {
        attrs: {
          x: "423.49207",
          y: "597.75954",
          width: "214",
          height: "2",
          fill: _vm.secondColor
        }
      }),
      _c("rect", {
        attrs: {
          x: "595",
          y: "289",
          width: "100",
          height: "2",
          fill: _vm.secondColor
        }
      }),
      _c("rect", {
        attrs: {
          x: "594.62302",
          y: "310.93988",
          width: "100",
          height: "2",
          fill: _vm.secondColor
        }
      }),
      _c("rect", {
        attrs: {
          x: "594.24603",
          y: "332.87977",
          width: "100",
          height: "2",
          fill: _vm.secondColor
        }
      }),
      _c("rect", {
        attrs: {
          x: "593.86905",
          y: "354.81965",
          width: "100",
          height: "2",
          fill: _vm.secondColor
        }
      }),
      _c("rect", {
        attrs: {
          x: "593.49207",
          y: "376.75954",
          width: "100",
          height: "2",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d: "M775.377,671.06012h-286v-158h286Zm-284-2h282v-154h-282Z",
          transform: "translate(-90.37698 -46.06012)",
          fill: "#3f3d56"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "541.87 469.251 541.314 468.728 370.314 307.632 371.686 306.176 542.13 466.749 647.911 402.146 648.954 403.854 541.87 469.251",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d: "M821.377,450.06012h-154v-154h154Zm-152-2h150v-150h-150Z",
          transform: "translate(-90.37698 -46.06012)",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1045.0785,112.59641c-18.65416-31.51976-55.55993-32.98871-55.55993-32.98871s-35.96256-4.59886-59.03232,43.40612c-21.50286,44.74458-51.17955,87.9464-4.77771,98.42078l8.38152-26.087,5.19063,28.02919a181.5582,181.5582,0,0,0,19.85362.33931c49.69262-1.60437,97.01733.46939,95.49354-17.3624C1052.60224,182.6488,1063.02755,142.92472,1045.0785,112.59641Z",
          transform: "translate(-90.37698 -46.06012)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1063.24076,480.86473s-23.40594,41.3505-5.46139,44.4713,29.64754-35.88912,29.64754-35.88912Z",
          transform: "translate(-90.37698 -46.06012)",
          fill: "#a0616a"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1109.27245,286.59538c-3.12079,32.76833-7.802,205.97232-21.06535,211.43371s-35.10891,5.46139-35.10891,5.46139V350.57163s5.46138-106.88715,24.96634-119.37032h11.28163a8.4097,8.4097,0,0,1,6.74864,3.386c.10153.13266.195.27309.28877.41352C1101.45485,242.75605,1111.63648,261.76166,1109.27245,286.59538Z",
          transform: "translate(-90.37698 -46.06012)",
          fill: "#d0cde1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M906.811,533.52811l3.12079,85.0416s-10.14258,83.48121-1.5604,109.22775l24.96634,3.901s28.08714-83.48121,24.18615-113.12874l16.38416-85.0416-41.3505-52.27328Z",
          transform: "translate(-90.37698 -46.06012)",
          fill: "#a0616a"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1001.9952,503.88058l3.901,41.3505,16.38416,92.84358s9.36238,56.95447,28.86733,93.62379l12.48317,39.7901,21.06535-7.802s6.24159-81.92081-18.72475-145.89706l-3.901-78.8-26.52673-44.4713Z",
          transform: "translate(-90.37698 -46.06012)",
          fill: "#a0616a"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1046.4665,370.46669,939.57935,350.18154S908.37142,399.334,898.22885,536.6489l24.18614,6.24159,7.02178-36.66932,4.68119,39.00991,82.701,7.802,7.802-40.5703,5.46139,42.9109,42.1307-7.02179S1068.31205,401.67462,1046.4665,370.46669Z",
          transform: "translate(-90.37698 -46.06012)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M939.18925,725.06676H902.51994l-3.901,23.40595s-46.8119,68.65744-11.703,64.75645,35.88912-22.62575,35.88912-22.62575,14.82376-14.04357,16.38416-21.06535-3.901-24.18615-3.901-24.18615Z",
          transform: "translate(-90.37698 -46.06012)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1087.42691,749.25291l-34.32872,7.02178v50.71288s-10.14258,49.93269,18.72475,46.81189,24.18615-45.25149,24.18615-45.25149Z",
          transform: "translate(-90.37698 -46.06012)",
          fill: "#2f2e41"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "890.94297",
          cy: "107.90158",
          r: "41.3505",
          fill: "#a0616a"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M973.518,188.29041s12.48317,27.30694,9.36238,31.98813,62.41585-3.1208,62.41585-3.1208-29.64753-44.47129-29.64753-53.83367Z",
          transform: "translate(-90.37698 -46.06012)",
          fill: "#a0616a"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M874.4328,442.635s-23.40594,41.3505-5.46139,44.4713S898.619,451.2172,898.619,451.2172Z",
          transform: "translate(-90.37698 -46.06012)",
          fill: "#a0616a"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1065.58136,314.68252s7.02178,36.66931-1.5604,56.95447c-2.55907,6.04653-6.43663,7.72393-10.92277,7.17783V350.57163s5.46138-106.88715,24.96634-119.37032h11.28163a8.4097,8.4097,0,0,1,6.74864,3.386Z",
          transform: "translate(-90.37698 -46.06012)",
          opacity: "0.15"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1039.44006,200.58733c-10.20845,2.18713-46.19882,9.62694-59.98979,8.86672a5.19347,5.19347,0,0,0-2.4693.464l-8.92439,4.11895L927.27973,233.3523a5.16249,5.16249,0,0,0-2.8379,5.74722l14.674,68.47867a5.15307,5.15307,0,0,1-2.70574,5.68217c-11.92366,6.02181-47.11285,26.80243.43857,41.99246,56.17427,17.94456,68.65744,14.04357,80.36041,10.92278s35.10892,25.74654,43.6911,5.46139c7.59964-17.9628,2.96366-48.773,1.80255-55.59221a5.13935,5.13935,0,0,1,.26615-2.69657l29.05-76.25613a5.16249,5.16249,0,0,0-2.47808-6.43636l-33.3749-17.028a5.1626,5.1626,0,0,1-1.30423-.94812l-10.69388-10.69387A5.15686,5.15686,0,0,0,1039.44006,200.58733Z",
          transform: "translate(-90.37698 -46.06012)",
          fill: "#d0cde1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M933.72786,234.3221h-6.23049a4.726,4.726,0,0,0-4.64284,3.84323L902.51994,345.11025l-20.28516,67.097s-49.15248,24.18614-12.48317,37.44951,55.39407,8.58218,51.49308-6.24158,33.54852-111.56834,33.54852-111.56834Z",
          transform: "translate(-90.37698 -46.06012)",
          fill: "#d0cde1"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "903.65847",
          cy: "33.70723",
          r: "26.68489",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M964.54163,72.745a26.68591,26.68591,0,0,1,23.87595-26.53487,26.95466,26.95466,0,0,0-2.80893-.15,26.68489,26.68489,0,1,0,0,53.36977,26.95466,26.95466,0,0,0,2.80893-.15A26.68592,26.68592,0,0,1,964.54163,72.745Z",
          transform: "translate(-90.37698 -46.06012)",
          fill: "#2f2e41"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "946.454 63.498 909.993 44.399 859.642 52.212 849.225 98.223 875.157 97.225 882.401 80.322 882.401 96.947 894.367 96.486 901.312 69.575 905.653 98.223 948.191 97.355 946.454 63.498",
          fill: "#2f2e41"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "931.90337",
          cy: "101.26989",
          rx: "5.46139",
          ry: "9.55743",
          fill: "#a0616a"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }