var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "killSessionModal",
          attrs: {
            modalTitle: "Warte auf Antwort vom Server...",
            esc: _vm.isKillSessionCloseable,
            showCloseButton: _vm.isKillSessionCloseable,
            showCancelButton: false,
            showConfirmButton: false,
            isModalAsFullPage: false
          },
          scopedSlots: _vm._u(
            [
              _vm.isKillSessionCloseable
                ? {
                    key: "footer",
                    fn: function() {
                      return [
                        _c(
                          "BaseButton",
                          {
                            attrs: { isSecondary: "" },
                            on: {
                              click: function($event) {
                                return _vm.closeModal()
                              }
                            }
                          },
                          [_vm._v(" Schließen ")]
                        ),
                        _c(
                          "BaseButton",
                          {
                            attrs: {
                              disabled: _vm.killRequestRunning.size > 0
                            },
                            on: {
                              click: function($event) {
                                return _vm.triggerKillAllRequests()
                              }
                            }
                          },
                          [_vm._v(" Alle abbrechen ")]
                        )
                      ]
                    },
                    proxy: true
                  }
                : null
            ],
            null,
            true
          )
        },
        [
          _c("Table", {
            attrs: {
              headers: _vm.headers,
              rows: _vm.rows,
              rowId: "requestId",
              hidePagination: ""
            },
            scopedSlots: _vm._u([
              {
                key: "action",
                fn: function(ref) {
                  var requestId = ref.requestId
                  return [
                    _c(
                      "BaseButton",
                      {
                        attrs: {
                          disabled: _vm.killRequestRunning.get(requestId)
                        },
                        on: {
                          click: function($event) {
                            return _vm.triggerKillRequest(requestId)
                          }
                        }
                      },
                      [
                        _vm.killRequestRunning.get(requestId)
                          ? _c("AnimatedSpinner")
                          : _vm._e(),
                        _vm._v(" Abbrechen ")
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }