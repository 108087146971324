var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseButton",
    {
      attrs: { isClear: "", disabled: _vm.disabled },
      on: {
        click: function($event) {
          return _vm.$emit("click", true)
        }
      }
    },
    [
      _vm.icon
        ? [
            _c(_vm.icon, {
              tag: "component",
              class: { "html-toolbar__button--active": _vm.active },
              attrs: { size: _vm.size, title: _vm.title }
            })
          ]
        : _vm._e(),
      _vm.showLabel ? [_vm._v(" " + _vm._s(_vm.title) + " ")] : _vm._e(),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }