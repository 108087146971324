var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", { attrs: { title: _vm.pageTitle } }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("div", { staticClass: "box__title" }, [
            _vm._v("Grundsätze über den Umgang mit Interessenskonflikten")
          ]),
          _c("div", { staticClass: "row mt-2" }, [
            _c(
              "div",
              { staticClass: "col-auto" },
              [
                _vm._v(
                  " Wir sind seit 1.8.2020 gesetzlich verpflichtet Ihnen unsere "
                ),
                _c(
                  "router-link",
                  { attrs: { to: "/conflict-of-interests/principles" } },
                  [_vm._v("Grundsätze zum Interessenkonflikt.")]
                ),
                _vm._v(" zur Verfügung zu stellen. ")
              ],
              1
            )
          ]),
          _vm._m(0),
          _vm._m(1),
          _c("InputCheckBoxItem", {
            staticClass: "mb-0",
            attrs: { label: _vm.acceptCheckboxText },
            on: { click: _vm.openLink },
            model: {
              value: _vm.form.acceptCheck,
              callback: function($$v) {
                _vm.$set(_vm.form, "acceptCheck", $$v)
              },
              expression: "form.acceptCheck"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "box__container" }, [
        _c("div", [
          _c(
            "div",
            { staticClass: "layout__negative-margin--8" },
            [
              _c(
                "BaseButton",
                {
                  attrs: { disabled: _vm.validation.isInvalid("form") },
                  on: {
                    click: function($event) {
                      return _vm.submit()
                    }
                  }
                },
                [_vm._v(" Bestätigen ")]
              )
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mt-2" }, [
      _c("div", { staticClass: "col-auto" }, [
        _vm._v(" Wir bitten Sie den Empfang unten zu bestätigen. ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mt-2" }, [
      _c("div", { staticClass: "col-auto" }, [
        _vm._v(" Vielen Dank für Ihr Verständnis. ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }