<template>
  <div>
    <PageHeaderTitleNavigation title="Kontakten" />
        <div class="box__container" :tid="_generateTidFromString('box__container')" v-if="kontaktList && kontaktList.length">
        <div class="box__title">{{TABLE_TITLE}}</div>
        
        <InputField 
          isComponentHalfSize
          placeholder="Suchen nach Name"
          v-model="descriptionSearch"/>
        </div>

        <div class="box__container" :tid="_generateTidFromString('cards__container layout__negative-margin--8')" 
        v-if="kontaktList && kontaktList.length">
          <Table
            tableId="0eca709b-022c-4078-871f-633007348bae"
            :headers="headers"
            :rows="rows"
            :rowsPerPage="10"
            v-model="selectedKontakte"
          />
          <BaseButton @click="addKontakte()" :disabled="selectedKontakte && !selectedKontakte.length">
            Markierte hinzufügen
          </BaseButton>
          <BaseButton @click="createKontakt()" v-if="isByPass">
            Neuen Kontakt anlegen
          </BaseButton>
        </div>
        <div v-else>
            <GhostLoading v-if="loading" type="table" :title="TABLE_TITLE" />
            <NoData v-else :title="TABLE_TITLE" />
        </div>
  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types'
import CUSTOMER_SEARCH_TYPES from '@/store/customerSearch/types';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { mapGetters } from "vuex";
import Table from "@/components/table2/Table.vue";
import {TextColumn} from "@/components/table2/table_util.js";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import axios from 'axios';
import BaseButton from '@/components/core/BaseButton.vue';
import InputField from '@/components/core/forms/InputField.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';

export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    Table,
    BaseButton,
    InputField,
    GhostLoading,
    NoData,
  },
  data() {
    return {
        kontaktList: [],
        selectedKontakte: [],
        descriptionSearch: '',
        loading: false,
        headers: {
            lockedLeft: [
                TextColumn("fullName", "Name"),
            ],
        },
        TABLE_TITLE: 'Kontaktsuche',
    }
  },

  computed: {
    ...mapGetters({
        isByPass: CORE_TYPES.GETTERS.IS_BYPASS,
    }),
    rows() {
        let rows = this.kontaktList;
        if (this.descriptionSearch.length > 0) {
            rows = rows.filter(r => r.fullName.search(new RegExp(this.descriptionSearch, "i")) > -1);
        }
        return rows;
    },
  },
  mounted() {
    this.retrieveKontaktList();
  },
  methods: {
    retrieveKontaktList() {
      const config = {
        defaultSpinner: true
      };

      let serviceUrl = '/apAuswahlService/listKontakte';
      this.loading = true;
      axios.get(`${process.env.VUE_APP_API}` + serviceUrl, config).then(response => {
        if (response && response.data) {
          this.kontaktList = response.data
        }
      })
      .finally(() => {
        this.loading = false;
      });
    },
    createKontakt() {
      this.$addBreadcrumb({
        label: 'zurück zu den Kontakten ', 
        fullPath: this.$route.fullPath,
        breadcrumb: 'Neuer Kontakt',
      });
      this.$router.push({ path: '/persoenlichedaten/person-data/neue_person' , query: {isBrokerContact: true}})
    },
    addKontakte() {
      if (this.$route.query && this.$route.query.back) {
        // General back / new appointment back
        this.$store.commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.SET_SELECTED_CUSTOMERS, this.selectedKontakte);

        this.$store.commit(CORE_TYPES.MUTATIONS.POP_SAVE_BACK_TO_PREVIOUS_PAGE)
        this.$router.push({ path: `${this.$route.query.back}?usersselected=true&backAction=true` });
      }
    },
  },
}
</script>
