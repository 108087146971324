var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showBaseHeader
    ? _c(
        "div",
        {
          staticClass: "base-footer__container",
          attrs: { "data-app-footer": "" }
        },
        [
          _c(
            "div",
            { staticClass: "base-footer__content" },
            [_c("WorkspacesMenu")],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }