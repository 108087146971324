import { render, staticRenderFns } from "./AntragStep.vue?vue&type=template&id=1261f5fb&scoped=true&"
import script from "./AntragStep.vue?vue&type=script&lang=js&"
export * from "./AntragStep.vue?vue&type=script&lang=js&"
import style0 from "./AntragStep.vue?vue&type=style&index=0&id=1261f5fb&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1261f5fb",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("P:\\workspaces\\fk0\\fc\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1261f5fb')) {
      api.createRecord('1261f5fb', component.options)
    } else {
      api.reload('1261f5fb', component.options)
    }
    module.hot.accept("./AntragStep.vue?vue&type=template&id=1261f5fb&scoped=true&", function () {
      api.rerender('1261f5fb', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/antrag/AntragStep.vue"
export default component.exports