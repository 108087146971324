<template>
    <div>
        <PageHeaderTitleNavigation
            :title="$appNavigation.currentMenu.label"
            :actions="headerActions"
            @action-LOGIN="login" 
        />

        <div class="box__container">
            <div class="box__title">{{ title }}</div>
            <div class="font-bold">{{ subtitle }}</div>
            <InputField
                label="Nutzerkennung"
                v-model="form.loginName"
                isComponentHalfSize
            />
            <InputField
                label="Passwort"
                v-model="form.password"
                type="password"
                isComponentHalfSize
            />
            <InputToggleSwitch v-model="form.savePassword" label="Passwort merken" inLineLabel/>
        </div>
    </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import InputField from '@/components/core/forms/InputField.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import EXTERNE_ZUGANG_TYPES from '@/store/externeZugang/types'

export default {
    components: {
        PageHeaderTitleNavigation,
        OptionMenu,
        InputField,
        InputToggleSwitch,
        PageHeaderSimpleAction,
    },
    data() {
        return {
            form: {
                loginName: '',
                password: '',
                savePassword: false,
            }
        }
    },
    watch: {
        'id': 'getData',
    },
    mounted() {
      this.getData(this.id);  
    },
    computed: {
        id() {
            return this.$route.params.id;
        },
        title() {
            return `Anmeldung beim ${this.$appNavigation.currentMenu.label}`;
        },
        subtitle() {
            return `Bitte geben Sie Ihre ${this.$appNavigation.currentMenu.label} Nutzerkennung und das Passwort ein.`;
        },
        headerActions() {
            return [
                PageHeaderSimpleAction('LOGIN', 'Anmelden')
                .withDisabled(() => !this.form.loginName || !this.form.password)
            ];
        },
    },
    methods: {
        login() {
            this.$store.dispatch(EXTERNE_ZUGANG_TYPES.ACTIONS.GET_FFP_PLUS_URL, {
                type: this.id,
                data: Object.assign({}, this.form, {savePassword: this.form.savePassword ? 'Ja' : 'Nein'}),
                name: this.title,
            });
        },
        async getData(id) {
            if (id) {
                this.form.loginName = '';
                this.form.password = '';
                this.form.savePassword = false;
                const data = await this.$store.dispatch(EXTERNE_ZUGANG_TYPES.ACTIONS.GET_FFP_PLUS_LOGIN_DATA, {type: id});
                if (data) {
                    this.form.loginName = data.loginName;
                    this.form.password = data.password;
                    this.form.savePassword = data.savePassword;
                }
            }
        },
    }
}
</script>

<style>

</style>