import PENDING_ACTIONS_TYPES from './types';

export default {
  [PENDING_ACTIONS_TYPES.GETTERS.SIMPLE_ACTION_KUNDENZUGANG](state) {
    return state.simpleActionResult;
  },
  [PENDING_ACTIONS_TYPES.GETTERS.ACTION_ID](state) {
    return state.actionId;
  },
  [PENDING_ACTIONS_TYPES.GETTERS.SIMPLE_ACTION_LISTE](state) {
    return state.simpleActionListe;
  },
  [PENDING_ACTIONS_TYPES.GETTERS.EXECUTE_SIMPLE_ACTION](state) {
    return state.simpleActionResult;
  },
  [PENDING_ACTIONS_TYPES.GETTERS.SIMPLE_ACTION_FONDSSHOP_DATA](state) {
    return state.simpleActionFondsshopData;
  },


  
  
  
  
}