<template>
  <div>
    <div class="box__container">
      <div class="box__title">Eine neue Position ordern:</div>
      <div>
        <div class="row layout__negative-margin--8">
          <div class="col-12 col-sm-4 col-xl-2 mx-0">
            <BaseButton
              @click="openFondsfinder('NEU_KAUF')"
              isBlock
              isSecondary
              :disabled="!kaufAvailable || isDisabled"
            >
              Kauf
            </BaseButton>
          </div>
          <div class="col-12 col-sm-4 col-xl-2 mx-0">
            <BaseButton
              @click="openFondsfinder('NEU_SPARPLAN')"
              isBlock
              isSecondary
              :disabled="!sparplanAvailable || isDisabled"
            >
              Sparplan
            </BaseButton>
          </div>
        </div>
      </div>
    </div>

    <div class="box__container">
      <div class="box__title">Eine bestehende Position bearbeiten:</div>
      <ComboBox
        v-model="bgsNr"
        :key="depotNr"
        :values="depotComboboxValues"
        :disabled="!hasDepot"
        @change="updateBgsNr($event)"
      >
      </ComboBox>
      <WertpapierorderAnteileInfo :position="selectedPosition" isMain />
      <div>
        <div class="row layout__negative-margin--8">
          <div class="col-12 col-sm-4 col-xxl-2 mx-0">
            <BaseButton
              @click="prepareAddPosition('ALT_KAUF')"
              isBlock
              isSecondary
              :disabled="!hasDepot || !kaufAvailable || isDisabled"
            >
              Nachkauf
            </BaseButton>
          </div>
          <div class="col-12 col-sm-4 col-xxl-2 mx-0">
            <BaseButton
              v-if="lagerstelle != 'DWS'"
              @click="prepareAddPosition('ALT_VERKAUF')"
              isBlock
              isSecondary
              :disabled="!hasDepot || !verkaufAvailable || isDisabled"
            >
              Verkauf
            </BaseButton>
          </div>
          <div
            class="col-12 col-sm-4 col-xxl-2 mx-0"
            v-if="isButtonVisible('ALT_TAUSCH')"
          >
            <BaseButton
              @click="prepareAddPosition('ALT_TAUSCH')"
              isBlock
              isSecondary
              :disabled="!hasDepot || !tauschAvailable || isDisabled"
            >
              Umschichtung
            </BaseButton>
          </div>
          <div class="col-12 col-sm-4 col-xxl-2 mx-0">
            <BaseButton
              @click="prepareAddPosition('ALT_SPARPLAN')"
              isBlock
              isSecondary
              :disabled="!hasDepot || !sparplanAvailable || isDisabled"
            >
              Sparplan
            </BaseButton>
          </div>
          <div
            class="col-12 col-sm-4 col-xxl-2 mx-0"
            v-if="isButtonVisible('ALT_ENTNAHMEPLAN')"
          >
            <BaseButton
              v-if="lagerstelle !== 'DWS'"
              @click="prepareAddPosition('ALT_ENTNAHMEPLAN')"
              isBlock
              isSecondary
              :disabled="!hasDepot || !entnahmeplanAvailable || isDisabled"
            >
              Entnahmeplan
            </BaseButton>
          </div>
          <div
            class="col-12 col-sm-4 col-xxl-2 mx-0"
            v-if="isButtonVisible('ALT_VERKAUF_ALLE_ANTEILE')"
          >
            <BaseButton
              v-if="lagerstelle !== 'FODB' && lagerstelle !== 'DWS'"
              @click="prepareAddPosition('ALT_VERKAUF_ALLE_ANTEILE')"
              isBlock
              isSecondary
              :disabled="!hasDepot || !verkaufAvailable || isDisabled"
            >
              Alle Bestände des Depots verkaufen
            </BaseButton>
          </div>
        </div>
      </div>
    </div>

    <div class="box__container" v-if="orderId">
      <div class="box__title">Eine offene Börsenorder bearbeiten:</div>
      <ComboBox
        v-model="orderId"
        :key="orderId"
        :values="orderbookComboboxValues"
        :disabled="!hasOrder || isDisabled"
        @change="updateOrderId($event)"
      >
      </ComboBox>
      <div>
        <div class="row layout__negative-margin--8">
          <div class="col-12 col-sm-4 col-xxl-2 mx-0">
            <BaseButton
              @click="prepareAddOrder('LIMITAENDERUNG')"
              isBlock
              isSecondary
              :disabled="!hasOrder || !isOrderEditable || isDisabled"
            >
              Limitänderung
            </BaseButton>
          </div>
          <div class="col-12 col-sm-4 col-xxl-2 mx-0">
            <BaseButton
              @click="prepareAddOrder('STREICHUNG')"
              isBlock
              isSecondary
              :disabled="!hasOrder || !isOrderDeletable || isDisabled"
            >
              Streichung
            </BaseButton>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="box__container">
        <div class="box__title">
          Es werden Formulare für die folgenden Orderaufträge erzeugt:
        </div>
      </div>

      <WertpapierorderPositionCards
        class="mb-4"
        @ebaseEdelmetalFound="$emit('ebaseEdelmetalFound')"
        :focusOnPosition="focusOnPosition"
      />
    </div>

    <BaseModal
      ref="allesVerkaufen"
      modalTitle="Verkauf des gesamten Bestandes des Depots"
      labelButtonConfirm="Verkaufen"
      @onConfirmButton="setAllPositionsForVerkauf()"
    >
      <InputToggleSwitch
        v-model="clearAltePositionen"
        label="Alle bestehenden Verkäufe und Tauschaufträge löschen"
        inLineLabel
        suppressValidationMessage
      />
    </BaseModal>
    <BaseModal
      ref="selectKonto"
      modalTitle="Bitte wählen Sie ein Konto aus."
      labelButtonCancel="Ok"
      :showConfirmButton="false"
    >
    </BaseModal>
    <BaseModal
      ref="fehlerModal"
      labelButtonCancel="Ok"
      :showConfirmButton="false"
    >
      <template v-slot:modalTitle>
        <ph-warning :size="16" class="color-danger mr-2" />
        <span class="color-danger">Fehler</span>
      </template>

      {{ errorMessage }}
    </BaseModal>

    <BaseModal
      ref="infoModalImmobilien"
      labelButtonCancel="Ok"
      :showConfirmButton="false"
    >
      <template v-slot:modalTitle>
        <ph-info :size="16" class="mr-2" style="color: orangered" />
        Hinweis:
      </template>
      <div class="var(--color-danger) font-bold">
        <hr />
        Die gewählte Position ist ein offener Immobilienfonds. Verwenden Sie
        bitte eines der speziellen Formulare für offene Immobilienfonds oder
        verwalten Sie bestehende Positionen Über die Vermögensübersicht:
        <div v-if="!isCustomerLogin">
          <a
            :tid="
              _generateTidFromString(
                'Rückgabeerklärung für offene Immobilienfonds'
              )
            "
            @click="verkaufImmobilieURL"
          >
            Rückgabeerklärung für offene Immobilienfonds
          </a>
          <br />
        </div>
      </div>
    </BaseModal>

    <BaseModal
      ref="infoModalDimensional"
      labelButtonCancel="Ok"
      :showConfirmButton="false"
    >
      <template v-slot:modalTitle>
        <ph-info :size="16" class="mr-2" style="color: orangered" />
        Hinweis:
      </template>
      <div class="var(--color-danger) font-bold">
        <hr />
        Die gewählte Position ist eine Dimensional Funds. Verwenden Sie bitte
        eines der speziellen Formulare für Dimensional Funds oder verwelten Sie
        bestehende Positionen Über die Vermögensübersicht:
        <div v-if="!isCustomerLogin">
          <a
            :tid="_generateTidFromString('Dimensional Funds Nachkauf')"
            @click="nachkaufURL"
          >
            Dimensional Funds Nachkauf
          </a>
          <br />

          <a
            :tid="_generateTidFromString('Dimensional Funds Verkauf')"
            @click="verkaufURL"
          >
            Dimensional Funds Verkauf
          </a>
          <br />
          <a
            :tid="_generateTidFromString('Dimensional Funds Sparplan')"
            @click="sparplanURL"
          >
            Dimensional Funds Sparplan
          </a>
          <br />
          <a
            :tid="_generateTidFromString('Dimensional Funds Entnahmeplan')"
            @click="entnahmeplanURL"
          >
            Dimensional Funds Entnahmeplan
          </a>
          <br />
          <a
            :tid="_generateTidFromString('Dimensional Funds Einmaliger Tausch')"
            @click="tauschURL"
          >
            Dimensional Funds Einmaliger Tausch
          </a>
          <br />
        </div>
      </div>
    </BaseModal>

    <BaseModal
      ref="infoModalETF"
      labelButtonCancel="Ok"
      :showConfirmButton="false"
    >
      <template v-slot:modalTitle>
        <ph-info :size="16" class="mr-2" style="color: orangered" />
        Hinweis:
      </template>
      <div class="var(--color-danger) font-bold">
        <hr />
        Die gewählte Position ist ein ETF. Verwenden Sie bitte eines der
        speziellen Formulare für ETFs oder benutzen Sie die bestehende
        Positionen Über die Vermögensübersicht:
        <div v-if="!isCustomerLogin">
          <a
            :tid="_generateTidFromString('ETF Nachkauf')"
            @click="nachkaufETFURL"
          >
            ETF Nachkauf
          </a>
          <br />

          <a
            :tid="_generateTidFromString('ETF Verkauf')"
            @click="verkaufETFURL"
          >
            ETF Verkauf
          </a>
          <br />
          <a
            :tid="_generateTidFromString('ETF Sparplan')"
            @click="sparplanETFURL"
          >
            ETF Sparplan
          </a>
          <br />
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import BaseButton from "@/components/core/BaseButton.vue";
import WertpapierorderPositionCards from "@/components/wertpapierorder/WertpapierorderPositionCards.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import { mapGetters } from "vuex";
import WERTPAPIERORDER_TYPES from "@/store/wertpapierorder/types";
import CORE_TYPES from "@/store/core/types";
import axios from "axios";
import BaseModal from "@/components/core/BaseModal.vue";
import mixin from "@/mixins/wertpapierorder/mixin.js";
import AntragComponent from "@/components/antrag/AntragComponent.vue";
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import { PhWarning, PhInfo } from "phosphor-vue";
import FormLabel from "@/components/core/forms/FormLabel.vue";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import CUSTOMERDATA_TYPES from "@/store/customerData/types";
import WertpapierorderAnteileInfo from "@/components/wertpapierorder/WertpapierorderAnteileInfo.vue";

export default {
  mixins: [mixin, InteractiveHelpCommonsMixin],
  props: {
    focusOnPosition: {
      type: String,
      default: "",
    },
  },
  components: {
    BaseButton,
    ComboBox,
    InputToggleSwitch,
    BaseModal,
    AntragComponent,
    PhWarning,
    PhInfo,
    WertpapierorderPositionCards,
    FormLabel,
    WertpapierorderAnteileInfo,
  },
  data() {
    return {
      disabled: false,
      bgsNr: null,
      orderId: null,
      beratungsMappeId: "",
      // positionToAdd: null,
      clearAltePositionen: true,
      errorMessage: "",
      category: null,
      bgsNrAddPosition: null,
    };
  },
  watch: {
    positions: function(elems) {
      if (elems && elems.length) {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
      this.warnings;
    },
    depots: {
      handler(newDepots) {
        if (
          !this.positions?.length &&
          newDepots &&
          this.bgsNrAddPosition &&
          this.category
        ) {
          this.addPositionFromLinkRedirection();
        }
        if (!this.bgsNr && newDepots?.length) {
          this.updateBgsNr(this.getDepot(this.depotNr)?.bgsNr || "");
        }
        if (!this.orderId && newDepots?.length) {
          const ob = this.getOrderbook(this.depotNr);
          this.updateOrderId(ob?.externalId || "");
        }
      },
      immediate: true,
    },
    depotNr(value) {
      if (!this.bgsNrAddPosition) {
        if (
          value &&
          value !== "freie Eingabe:" &&
          (this.lagerstelle || this.data?.lagerstelleSelect) &&
          this.depots?.length
        ) {
          this.updateBgsNr(this.getDepot(value)?.bgsNr || "");
        } else {
          this.updateBgsNr("");
        }
      }
    },
    orderbook: {
      handler(newOrderbook) {
        if (newOrderbook?.length > 0 && !this.order) {
          this.updateOrderId(newOrderbook[0].externalId);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.initValues();
  },
  computed: {
    ...mapGetters({
      lagerstellen: WERTPAPIERORDER_TYPES.GETTERS.LAGERSTELLEN || [],
      lagerstellenConfig: WERTPAPIERORDER_TYPES.GETTERS.LAGERSTELLEN_CONFIG,
      comboboxValues: WERTPAPIERORDER_TYPES.GETTERS.COMBOBOX_VALUES || {},
      apiAddress: CORE_TYPES.GETTERS.API_ADDRESS,
      isFirma: CUSTOMERDATA_TYPES.GETTERS.IS_FIRMA,
    }),
    kontoFreeText() {
      return this.data && this.data["kontoFreeText"];
    },
    warnings() {
      return this.getWarnings("main");
    },
    hasDepot() {
      return this.depotComboboxValues?.length ? true : false;
    },
    hasOrder() {
      return this.orderbookComboboxValues?.length ? true : false;
    },
    isOrderEditable() {
      let order =
        this.orderId &&
        this.orderbook?.find?.((o) => o.externalId === this.orderId);
      return order?.isEditable;
    },
    isOrderDeletable() {
      let order =
        this.orderId &&
        this.orderbook?.find?.((o) => o.externalId === this.orderId);
      return order?.isDeletable;
    },
    depotNr() {
      return (
        this.data?.depotNr ||
        this.data?.kontoNr ||
        this.data?.depotSelect ||
        "nicht definiert"
      );
    },
    kontoNr() {
      return this.data?.kontoNr || "";
    },
    kaufAvailable() {
      return this.lagerstellenConfig?.kaufAvailable;
    },
    verkaufAvailable() {
      return this.lagerstellenConfig?.verkaufAvailable;
    },
    tauschAvailable() {
      return this.lagerstellenConfig?.tauschAvailable;
    },
    sparplanAvailable() {
      return this.lagerstellenConfig?.sparplanAvailable;
    },
    entnahmeplanAvailable() {
      return this.lagerstellenConfig?.entnahmeplanAvailable;
    },
    selectedPosition() {
      return this.depots
        ?.filter(
          (p) => p.kontoNr === this.depotNr || p.depotNr === this.depotNr
        )
        ?.find((pos) => pos.bgsNr == this.bgsNr);
    },
  },
  methods: {
    isButtonVisible(category) {
      if (this.isFA) {
        return !category || category === "ALT_VERKAUF_ALLE_ANTEILE"
          ? false
          : this.faVisibleCategories[this.lagerstelle] &&
              this.faVisibleCategories[this.lagerstelle].length &&
              this.faVisibleCategories[this.lagerstelle].includes(category);
      } else {
        return true;
      }
    },
    addPositionFromLinkRedirection() {
      this.updateBgsNr(this.bgsNrAddPosition);
      this.prepareAddPosition(this.category);
    },
    initValues() {
      if (this.$route.query && this.$route.query.beratungsMappeId) {
        this.beratungsMappeId = this.$route.query.beratungsMappeId;
      }
      if (this.$route.query.bgsNr && this.$route.query.category) {
        this.bgsNrAddPosition = this.$route.query.bgsNr
          ? parseInt(this.$route.query.bgsNr)
          : null;
        this.category = this.$route.query.category;
      }
      if (this.lagerstelle && this.depots?.length) {
        this.updateBgsNr(
          (
            (this.depotNr &&
              this.depots?.find((depot) => depot.depotNr === this.depotNr)) ||
            this.depots[0]
          )?.bgsNr
        );
      }
      if (this.$route.query && this.$route.query.bgs) {
        this.updateBgsNr(Number(this.$route.query?.bgs));
      }
    },
    updateStore(data) {
      if (data) {
        this.$store.commit(WERTPAPIERORDER_TYPES.MUTATIONS.UPDATE_DATA, data);
      }
    },
    openFondsfinder(categoryId) {
      if (this.readOnly) {
        return;
      }
      if (this.depotNr) {
        const query = this.$route.query || {};
        query.lagerstelle = this.lagerstelle || "";
        query.categoryId = categoryId;
        if (categoryId === "NEU_SPARPLAN") {
          query.isSparplan = true;
        }
        delete query.backAction;
       
        this.$router.push({
          path: this.$route.path.includes(this.PATH_CUSTOMER)
            ? `${this.PATH_CUSTOMER}fondsfinder`
            : `${this.PATH_BROKER}fondsfinder`,
          query: query,
        });
      } else {
        this.$refs.selectKonto.open();
      }
    },
    openTausch(depot) {
      if (depot) {
        const query = this.$route.query || {};
        query.singleSelection = true;
        query.bgs = depot.bgsNr;
        query.categoryId = "ALT_TAUSCH";
        query.lagerstelle = this.lagerstelle || "";
        delete query.backAction;

        this.$router.push({
          path: this.$route.path.includes(this.PATH_CUSTOMER)
            ? `${this.PATH_CUSTOMER}tausch`
            : `${this.PATH_BROKER}tausch`,
          query: query,
        });
      }
    },
    prepareAddOrder(category) {
      if (this.readOnly) {
        return;
      }

      let order =
        this.orderId &&
        this.lagerstelle &&
        this.orderbook?.find?.((o) => o.externalId === this.orderId);
      if (!order) {
        this.errorMessage = "Bitte wählen Sie eine bestehende Position.";
        this.$refs.fehlerModal.open();
      } else {
        axios
          .get(
            `${
              process.env.VUE_APP_API
            }/wertpapierorder/checkBeforeAddOrder?orderId=${order?.externalId ||
              ""}
            &positionTyp=${order?.positionTyp}&lagerstelle=${this.lagerstelle}`
          )
          .then(() => {
            if (category == "STREICHUNG") {
              order.comdirectBoModus = "ST";
            } else if (category == "LIMITAENDERUNG") {
              order.comdirectBoModus = "LÄ";
            }
            order.lagerstelle = this.lagerstelle;
            if (this.lagerstelle == "COMDIRECT") {
              order.comdirectBoOrderId = order.externalId;
            }
            this.addPosition(order);
          });
      }
    },
    prepareAddPosition(category) {
      if (this.readOnly) {
        return;
      }

      let depot =
        this.bgsNr &&
        this.lagerstelle &&
        this.depots?.find?.((d) => d.bgsNr === this.bgsNr);
      if (!depot) {
        this.errorMessage = "Bitte wählen Sie eine bestehende Position.";
        this.$refs.fehlerModal.open();
      } else {
        axios
          .get(
            `${
              process.env.VUE_APP_API
            }/wertpapierorder/checkBeforeAddPosition?bgsnr=${depot?.bgsNr || ""}
            &positionTyp=${category}&lagerstelle=${this.lagerstelle}`
          )
          .then(() => {
            depot.positionTyp = category;
            depot.lagerstelle = this.lagerstelle;

            this.errorMessage = "";
            if (category === "ALT_VERKAUF_ALLE_ANTEILE") {
              this.openSellAll();
            } else if (category === "ALT_TAUSCH") {
              if (this.lagerstelle == "FODB") {
                if (this.selectedPosition?.isDimensionalFunds) {
                  this.$refs.infoModalDimensional.open();
                } else {
                  this.openTausch(depot);
                }
              } else {
                this.openTausch(depot);
              }
            } else {
              if (category === "ALT_VERKAUF" && this.lagerstelle == "FODB") {
                const depotNr = this.depotNr;
                if (this.selectedPosition?.isETF && this.isFirma) {
                  this.$refs.infoModalETF.open();
                } else if (
                  this.selectedPosition &&
                  this.selectedPosition.isDimensionalFunds
                ) {
                  this.$refs.infoModalDimensional.open();
                } else {
                  this.addPosition(depot);
                }
              } else {
                this.addPosition(depot);
              }
            }
          });
      }
    },
    addPosition(depot) {
      if (
        this.selectedPosition?.isDimensionalFunds &&
        this.lagerstelle == "FODB"
      ) {
        this.$refs.infoModalDimensional.open();
      } else if (
        this.selectedPosition?.isETF &&
        this.lagerstelle == "FODB" &&
        this.isFirma
      ) {
        this.$refs.infoModalETF.open();
      } else {
        if (depot?.isin) {
          this.$store.dispatch(WERTPAPIERORDER_TYPES.ACTIONS.ADD_POSITIONS, {
            positions: [depot],
            beratungsMappeId: this.beratungsMappeId,
          });
        }
      }
    },
    verkaufImmobilieURL() {
      this.$router.push({
        path: `/beratung/formulare/antrag/EBASE/rueckgabeimmobilienfond/depotdatenlagerstelleLabel=FNZ%20Bank&`,
      });
    },
    nachkaufURL() {
      this.$router.push({
        path: `/beratung/formulare/antrag/FODB/dimensionalfundskauf/depotdaten?lagerstelleFixed=FODB&antragId=dimensionalfundskauf&`,
      });
    },
    verkaufURL() {
      this.$router.push({
        path: `/beratung/formulare/antrag/FODB/dimensionalfundsverkauf/depotdaten?lagerstelleFixed=FODB&antragId=dimensionalfundsverkauf&`,
      });
    },
    sparplanURL() {
      this.$router.push({
        path: `/beratung/formulare/antrag/FODB/dimensionalfundssparplan/depotdaten?lagerstelleFixed=FODB&antragId=dimensionalfundssparplan&`,
      });
    },
    entnahmeplanURL() {
      this.$router.push({
        path: `/beratung/formulare/antrag/FODB/dimensionalfundsentnahme/depotdaten?lagerstelleFixed=FODB&antragId=dimensionalfundsentnahme&`,
      });
    },
    tauschURL() {
      this.$router.push({
        path: `/beratung/formulare/antrag/FODB/dimensionalfundstausch/depotdaten?lagerstelleFixed=FODB&antragId=dimensionalfundstausch&`,
      });
    },
    nachkaufETFURL() {
      this.$router.push({
        path: `/beratung/formulare/antrag/FODB/etfskauf/depotdaten?lagerstelleFixed=FODB&antragId=etfskauf&`,
      });
    },
    verkaufETFURL() {
      this.$router.push({
        path: `/beratung/formulare/antrag/FODB/etfsverkauf/depotdaten?lagerstelleFixed=FODB&antragId=etfsverkauf&`,
      });
    },
    sparplanETFURL() {
      this.$router.push({
        path: `/beratung/formulare/antrag/FODB/etfssparplan/depotdaten?lagerstelleFixed=FODB&antragId=etfssparplan&`,
      });
    },
    openSellAll() {
      this.$refs.allesVerkaufen.open();
    },
    async setAllPositionsForVerkauf() {
      if (this.clearAltePositionen && this.positions?.length) {
        await this.$store.dispatch(
          WERTPAPIERORDER_TYPES.ACTIONS.REMOVE_ALL_VERKAUF_AND_TAUSCH_POSITIONS,
          {}
        );
      }
      const positions = [];
      if (this.lagerstelle && this.depots?.length) {
        const lagDepots = Object.assign(
          [],
          this.depots.filter(
            (depot) =>
              depot.depotNr === this.depotNr ||
              depot.depotNr === this.kontoNr ||
              depot.depotNr?.includes(this.depotNr)
          )
        );
        lagDepots.forEach((depot) => {
          if (depot.bgsNr) {
            depot.lagerstelle = this.lagerstelle;
            depot.positionTyp = "ALT_VERKAUF";
            depot.betragModus = "VALUE_BETRAG_MODUS_ALLE_ANTEILE";
            positions.push(depot);
          }
        });
        if (positions.length) {
          this.$store.dispatch(WERTPAPIERORDER_TYPES.ACTIONS.ADD_POSITIONS, {
            positions: positions,
          });
        }
      }
    },
    isComponentDisabled(componentId, betragModus) {
      return (
        componentId === "betrag" &&
        betragModus === "VALUE_BETRAG_MODUS_ALLE_ANTEILE"
      );
    },
    updateBgsNr(value) {
      this.bgsNr = value;
    },
    updateOrderId(value) {
      this.orderId = value;
    },
  },
};
</script>

<style scoped>
.title-row {
  border-top: 1px solid #f7f3f3;
  background-color: #f7f3f3;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0;
}
.bold {
  font-weight: bold;
}
.component__container {
  margin-bottom: 16px;
}
.anmelden_link {
  color: var(--color-top-nav-text);
  text-decoration: none;
  padding: 9px 20px;
  outline: var(--color-top-nav-background) solid 1px;
}
</style>
