var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "agb_container" }, [
    _c("div", { staticClass: "agb_wrapper" }, [
      _c("div", { staticClass: "banner" }, [_vm._v("AGB")]),
      _c("hr"),
      _vm.isAGBReady
        ? _c("div", { staticClass: "agb_text" }, [
            _c("div", { domProps: { innerHTML: _vm._s(_vm.getAGB) } })
          ])
        : _vm._e(),
      _vm.isIntern
        ? _c("div", { staticClass: "fc-alert fc-alert-warning" }, [
            _vm._v("Hier kommen die für den Makler bestimmten AGB.")
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }