var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("BemerkungenList", {
        attrs: {
          records: _vm.bemerkungen,
          loading: _vm.loading,
          typ: _vm.VALUE_TYP,
          basePath: "/intern"
        },
        on: { delete: _vm.deleteRow, deleteSelected: _vm.deleteSelected }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }