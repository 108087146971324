
const personendatenOffentlichePersonMixin = {
  methods: {
    updateFieldState(field, value) {
      if(this.pep && value !== null && value !== undefined) {
        this.pep[field] = !!value ? '1' : '0';
      }
    },
  },
};

export default personendatenOffentlichePersonMixin;
