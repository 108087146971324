<template>
  <div>
    <GesellschaftTitleNavigation
      :headerActions="headerActions"
      @action-CREATE_MEHRFACHPOSITION="openCreateMehrfachpositionModal"
    />

    <BaseFilter 
      title="Mehrfachpositionen Filter" 
      :metadata="metadata" 
      :configFilter="configFilter"
      hasSmartSearch
      @onFilter="doFilter"
    />

    <div class="box__container">
      <GhostLoading v-if="loading" type="table" :title="TABLE_TITLE" />
      <Table 
        v-else
        :title="TABLE_TITLE"
        :rows="rows"
        :headers="headers"
        :rowsPerPage="20"
        @action-EDIT="openCreateMehrfachpositionModal"
        @action-DELETE="deleteMehrfachposition"
      />
    </div>

    <BaseModal
      ref="createMehrfachpositionModal"
      :modalTitle="editMehrfachposition.id ? 'Mehrfachposition bearbeiten' : 'Neue Mehrfachposition'"
      labelButtonConfirm="Speichern"
      :confirmDisabled="!editMehrfachposition.kontonr || !editMehrfachposition.isin"
      @onConfirmButton="saveMehrfachposition()"
    >
      <InputField
        label="Kontonummer"
        v-model="editMehrfachposition.kontonr"
      />
      <InputField
        label="ISIN"
        v-model="editMehrfachposition.isin"
      />
    </BaseModal>  
  </div>
</template>

<script>
import GESELLSCHAFT_TYPES from '@/store/gesellschaft/types';
import { mapGetters } from 'vuex';
import axios from 'axios';
import { makeQueryParam } from '@/helpers/utils-helper';

import GesellschaftTitleNavigation from '@/views/gesellschaft/intern/GesellschaftTitleNavigation.vue'
import BaseFilter from "@/components/core/BaseFilter.vue";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Table from "@/components/table2/Table.vue";
import { TextColumn, ActionColumn, SimpleAction, ConfirmedAction, } from "@/components/table2/table_util.js";
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';

import InputField from '@/components/core/forms/InputField.vue'
import BaseModal from '@/components/core/BaseModal.vue';

const TABLE_TITLE = 'Mehrfachpositionen';

const config = {
  defaultSpinner: true
};

export default {
  mixins: [],
  components: {
    GesellschaftTitleNavigation,
    BaseFilter,
    GhostLoading,
    Table,
    InputField,
    BaseModal,
  },
  data() {
    return {
      TABLE_TITLE,
      loading: false,
      configFilter: {
          placeholderForDefSearchInput: 'Suche nach Kontonummer',
          defaultSearchInputKeys: ['kontonr'],
          noResetOnDefaultSearchInputExit: true,
          checkDefaultSearchInput: true,
      },
      editMehrfachposition: {},
    }
  },
  computed: {
    ...mapGetters({
      gesellschaft: GESELLSCHAFT_TYPES.GETTERS.GET_GESELLSCHAFT,
    }),
    headers() {
      return {
        lockedLeft: [
          TextColumn("kontonr", "Kontonr."),
          TextColumn("isin", "ISIN"),
        ],
        center: [],
        lockedRight: [
          ActionColumn('actions')
        ],
      }
    },
    rows() {
      const mehrfachpositionen = this.gesellschaft.mehrfachpositionen || []
      return mehrfachpositionen.map(mfp => ({
        ...mfp,
        actions: [
          SimpleAction('EDIT', 'PhPencilLine', 'Bearbeiten'),
          ConfirmedAction("DELETE", 'PhTrash', "Löschen", `Soll die Mehrfachposition "${mfp.isin}" wirklich gelöscht werden?`, 'Mehrfachposition löschen', "Löschen"),
        ]
      }))
    },
    headerActions() {
      return [
        PageHeaderSimpleAction('CREATE_MEHRFACHPOSITION', 'Mehrfachposition erstellen'),
      ]
    },
    metadata() {
      return [
        {
          type: "group",
          key: "allgemein",
          label: "Allgemein",
          menuItems: [
            {
              type: "text",
              label: "Kontonr",
              key: "kontonr",
            },
            {
              type: "text",
              label: "ISIN",
              key: "isin",
            },
          ],
        },
      ]
    },
  },
  mounted() {
    this.doSearch()
  },
  methods: {
    doFilter(filterParams) {
      let params = {};
      filterParams.forEach(fc => {
        params[fc.key] = fc.value
      })

      this.filterParams = params
      this.doSearch(true)
    },
    doSearch(isReload = false) {
      this.loading = true

      this.$store.dispatch(GESELLSCHAFT_TYPES.ACTIONS.GET_GESELLSCHAFT_MEHRFACHPOSITIONEN, {
        isReload, 
        payload: { gesellschaftId: this.$route.params.gesellId, ...this.filterParams }
      })
      .finally(() => this.loading = false);
    },
    openCreateMehrfachpositionModal(row) {
      if (row) {
        this.editMehrfachposition = {
          id: row.id,
          kontonr: row.kontonr,
          isin: row.isin,
        }
      } else {
        this.editMehrfachposition = {}
      }
      
      this.$refs.createMehrfachpositionModal.open()
    },
    saveMehrfachposition() {
      const params = makeQueryParam({ gesellschaftId: this.$route.params.gesellId }, true)

      axios.post(`${process.env.VUE_APP_API}/gesellschaftenService/mehrfachposition?${params}`, this.editMehrfachposition, config)
        .then(() => this.doSearch())
    },
    deleteMehrfachposition({id}) {
      const params = makeQueryParam({ mehrfachpositionId: id, gesellschaftId: this.$route.params.gesellId }, true)

      axios.delete(`${process.env.VUE_APP_API}/gesellschaftenService/mehrfachposition?${params}`, config)
        .then(() => {
          const index = this.gesellschaft.mehrfachpositionen.findIndex(row => row.id === id);

          if (index >= 0) {
            this.gesellschaft.mehrfachpositionen.splice(index, 1);
          }
        })
    },
  },
}
</script>