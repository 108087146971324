import VUBLOCK_TYPES from "@/store/vuBlock/types";

export default {
  data() {
    return {
      FILE_AUSKUNFTSVOLLMACHT: VUBLOCK_TYPES.FILE_AUSKUNFTSVOLLMACHT,
      FILE_EINVERSTAENDNIS: VUBLOCK_TYPES.FILE_EINVERSTAENDNIS,
      loadingFileAuskunftsvollmacht: false,
      loadingFileEinverstaendnis: false,
    }
  },
  computed: {

  },
  methods: {
    onFileUpload(file, type) {
      if (file) {
        switch (type) {
          case this.FILE_AUSKUNFTSVOLLMACHT:
            this.loadingFileAuskunftsvollmacht = true;
            break;

          case this.FILE_EINVERSTAENDNIS:
            this.loadingFileEinverstaendnis = true;
            break;
        }

        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file[0]);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        }).then((data) => {
          let base64string = data.split(",").pop();

          this.$store
            .dispatch(VUBLOCK_TYPES.ACTIONS.UPLOAD_VUBLOCK_FILE, {
              vuBlockId: this.selectedVuBlock?.id,
              type: type,
              file: {
                fileName: file[0].name,
                data: base64string,
              },
            })
            .finally(() => {
              switch (type) {
                case this.FILE_AUSKUNFTSVOLLMACHT:
                  this.loadingFileAuskunftsvollmacht = false;
                  break;

                case this.FILE_EINVERSTAENDNIS:
                  this.loadingFileEinverstaendnis = false;
                  break;
              }
            });
        });
      }
    },



  },
}
