<template>
  <div class="input-forms__container" :class="{ 'disabled': disabled, }">
    <label v-if="label" class="input-forms__label-container">
      <div class="input-forms__label-content">{{ label }}</div>
    </label>

    <div class="input-forms__input-container" :class="{ 'forms__input--half-size': isComponentHalfSize, }">
      <div class="slider-chart__container">
        <div class="slider-chart">
          <div class="slider-chart--line slider-chart--green-line"></div>
          <div class="slider-chart--line slider-chart--yellow-line" :style="yellowLineStyle"></div>
          <div class="slider-chart--line slider-chart--red-line" :style="redLineStyle"></div>
        </div>
      </div>

      <div class="slider-inputs__container">
        <InputSlider class="slider-input__container p-0" v-model="minValue" :values="values" :showStopLabel="false" :disabled="disabled" @input="onMinInput($event)" />
        <InputSlider class="slider-input__container" v-model="maxValue" :values="values" :disabled="disabled" @input="onMaxInput($event)" />
      </div>
    </div>
  </div>
</template>

<script>
import InputSlider from '@/components/core/forms/InputSlider.vue';


export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    min: {
      type: Object,
      default: () => ({}),
    },
    max: {
      type: Object,
      default: () => ({}),
    },
    isComponentHalfSize: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      minValue: null,
      maxValue: null,
      values: [
        { name: '0', value: '0', },
        { name: '1', value: '1', },  
        { name: '2', value: '2', },
        { name: '3', value: '3', },
        { name: '4', value: '4', },
        { name: '5', value: '5', },
      ],
    };
  },
  watch: {
    value: {
      handler() {
        this.minValue = this.value[this.min.id];
        this.maxValue = this.value[this.max.id];
      },
      immediate: true,
    },
  },
  computed: {
    chartData() {
      return [
        {
          name: 'test1',
          data: [10, 20, 30],
        },
      ];
    },
    yellowLineStyle() {
      return { 
        width: `${ this.calcChartValue(this.maxValue) }%`,
      };
    },
    redLineStyle() {
      return { 
        width: `${ this.calcChartValue(this.minValue) }%`,
      };
    },
  },
  methods: {
    calcChartValue(value) {
      return value * 2 * 10;
    },
    onInput() {
      this.$emit('input', {
        [this.min.id]: this.minValue,
        [this.max.id]: this.maxValue,
      });
    },
    onMinInput(value) {
      const { maxValue } = this;
      if(value > maxValue) {
        this.maxValue = value;
      }

      this.onInput();
    },
    onMaxInput(value) {
      const { minValue } = this;
      if(value < minValue) {
        this.minValue = value;
      }

      this.onInput();
    },
  },
  components: {
    InputSlider,
  },
}
</script>

<style scoped lang="scss">
.slider-chart__container {
  margin: 0 0 0.5rem;

  .slider-chart {
    border-radius: 4px;
    height: 32px;
    overflow: hidden;
    position: relative;

    .slider-chart--line {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      &.slider-chart--green-line {
        background-color: var(--color-success);
      }
      &.slider-chart--yellow-line {
        background-color: var(--color-warning);
      }
      &.slider-chart--red-line {
        background-color: var(--color-danger);
      }
    }
  }
}

.slider-inputs__container {
  margin: 0 0.4rem;

  .slider-input__container {
    margin: 0 0 0.5rem;

    &:last-child {
      margin-bottom: 1.2rem;
    }

    ::v-deep .input-forms__input-container {
      margin: 0;
    }
  }
}

.disabled {
  cursor: not-allowed;
  .slider-chart__container {
    opacity: 0.8;
  }
}
</style>
