<template>
<component 
    v-if="rowSteps.length" 
    :is="short ? 'div' : 'ContextMenuGroup'"
    class="select-row-per-page__container" 
    :class="[short ? 'd-none d-sm-flex' : '']"
>
    <template v-for="step in rowSteps">
        <component 
            :is="short ? 'a' : 'ContextMenuItem'"
            :key="step.value" 
            :class="stepClass(step)"
            @click="changeStep(step.value)" 
        >
            {{ short ? step.value : step.label }}
        </component>
    </template>
</component>
</template>

<script>
import ContextMenuGroup from '@/components/core/base-context-menu/ContextMenuGroup.vue';
import ContextMenuItem from '@/components/core/base-context-menu/ContextMenuItem.vue';

export default {
    components: {
        ContextMenuGroup,
        ContextMenuItem,
    },
    props: {
        pageSize: {
            type: Number,
            required: true
        },
        rowSteps: {
            type: Array,
            required: true
        },
        short: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        stepClass(step) {
            const isSelected = step.value == this.pageSize;
            return {
                'mr-1': this.short,
                'menu-disabled': isSelected,
                'clickable': !isSelected,
            };
        },
        changeStep(value) {
            if (value != this.pageSize) {
                this.$emit('tablePageSize', value);
            }
        },
    },
};
</script>

<style scoped>
.select-row-per-page__container {
    flex: 0 0 auto;
}
.d-sm-flex {
    line-height: 32px;
    min-width: 105px;
    padding: 0 8px;
}
.menu-disabled {
    font-weight: bolder;
}
.menu-disabled > a:hover {
    text-decoration: none;
}
.menu-disabled > a {
    color: var(--color-text);
    cursor: unset;
}
</style>
