var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Eingang einer Kundenbeschwerde",
          defaultMenu: _vm.$appNavigation.currentTabMenu,
          actions: _vm.headerActions
        },
        on: {
          "action-EINREICHEN": function($event) {
            return _vm.einreichen()
          }
        }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("InputField", {
            attrs: {
              label: "Name des Mitarbeiters, der die Beschwerde annimmt",
              isComponentHalfSize: "",
              disabled: _vm.disabled
            },
            model: {
              value: _vm.form.INPUT_BESCHWERDE_NAME_MITARBEITER,
              callback: function($$v) {
                _vm.$set(_vm.form, "INPUT_BESCHWERDE_NAME_MITARBEITER", $$v)
              },
              expression: "form.INPUT_BESCHWERDE_NAME_MITARBEITER"
            }
          }),
          _c("InputField", {
            attrs: {
              label: "Name des Kunden (Beschwerdeführer)",
              isComponentHalfSize: "",
              disabled: _vm.disabled
            },
            model: {
              value: _vm.form.INPUT_BESCHWERDE_NAME_KUNDE,
              callback: function($$v) {
                _vm.$set(_vm.form, "INPUT_BESCHWERDE_NAME_KUNDE", $$v)
              },
              expression: "form.INPUT_BESCHWERDE_NAME_KUNDE"
            }
          }),
          _c("DatePickerField", {
            attrs: {
              label: "Datum des Beschwerdeeingangs",
              isValueAsString: "",
              isComponentHalfSize: "",
              disabled: _vm.disabled
            },
            model: {
              value: _vm.form.INPUT_BESCHWERDE_DATUM,
              callback: function($$v) {
                _vm.$set(_vm.form, "INPUT_BESCHWERDE_DATUM", $$v)
              },
              expression: "form.INPUT_BESCHWERDE_DATUM"
            }
          }),
          _c("InputRadioBoxGroup", {
            attrs: {
              title: "Form des Beschwerdeeingangs",
              values: _vm.typeValues,
              disabled: _vm.disabled
            },
            model: {
              value: _vm.form.INPUT_BESCHWERDE_FORM,
              callback: function($$v) {
                _vm.$set(_vm.form, "INPUT_BESCHWERDE_FORM", $$v)
              },
              expression: "form.INPUT_BESCHWERDE_FORM"
            }
          }),
          _c("InputField", {
            attrs: {
              isComponentHalfSize: "",
              disabled:
                _vm.form.INPUT_BESCHWERDE_FORM !== "SONSTIGES" || _vm.disabled
            },
            model: {
              value: _vm.form.INPUT_BESCHWERDE_FORM_SONSTIGES_TEXT,
              callback: function($$v) {
                _vm.$set(_vm.form, "INPUT_BESCHWERDE_FORM_SONSTIGES_TEXT", $$v)
              },
              expression: "form.INPUT_BESCHWERDE_FORM_SONSTIGES_TEXT"
            }
          }),
          _c("InputTextArea", {
            attrs: {
              label:
                "Inhalt der Beschwerde/Beschreibung des Anliegens/Sachverhaltsdarstellung",
              isComponentHalfSize: "",
              disabled: _vm.disabled
            },
            model: {
              value: _vm.form.INPUT_BESCHWERDE_TEXT,
              callback: function($$v) {
                _vm.$set(_vm.form, "INPUT_BESCHWERDE_TEXT", $$v)
              },
              expression: "form.INPUT_BESCHWERDE_TEXT"
            }
          }),
          _c("InputTextArea", {
            attrs: {
              label:
                "Stellungnahme zum Sachverhalt, falls Sie der Kundenbetreuer sind. Falls Sie nicht der Kundenbetreuer sind holt der Beschwerdemanager die Stellungnahme des Kundenbetreuers zum Sachverhalt ein.",
              isComponentHalfSize: "",
              disabled: _vm.disabled
            },
            model: {
              value: _vm.form.INPUT_BESCHWERDE_STELLUNGNAHME,
              callback: function($$v) {
                _vm.$set(_vm.form, "INPUT_BESCHWERDE_STELLUNGNAHME", $$v)
              },
              expression: "form.INPUT_BESCHWERDE_STELLUNGNAHME"
            }
          }),
          _c("InputCheckBoxGroup", {
            attrs: {
              title:
                "Ggf. beigefügte Unterlagen, auf die sich die Beschwerde bezieht (sofern dies zur Sachverhaltsdarstellung erforderlich ist). Falls Sie nicht der Kundenbetreuer sind, holt der Beschwerdemanager die beizufügenden Unterlagen ein.",
              values: [
                { label: "Kundendokumentation", path: "INPUT_DOKUMENT_KD" },
                {
                  label: "Vermögensverwaltungsvertrag",
                  path: "INPUT_DOKUMENT_VERTRAG"
                },
                { label: "Quartalsberichte", path: "INPUT_DOKUMENT_QB" },
                { label: "Depotauszüge", path: "INPUT_DOKUMENT_DA" },
                { label: "Sonstige:", path: "INPUT_DOKUMENT_SONSTIG" }
              ],
              disabled: _vm.disabled
            },
            model: {
              value: _vm.form,
              callback: function($$v) {
                _vm.form = $$v
              },
              expression: "form"
            }
          }),
          _c("InputField", {
            attrs: {
              disabled: !_vm.form.INPUT_DOKUMENT_SONSTIG || _vm.disabled,
              isComponentHalfSize: ""
            },
            model: {
              value: _vm.form.INPUT_DOKUMENT_SONSTIG_TEXT,
              callback: function($$v) {
                _vm.$set(_vm.form, "INPUT_DOKUMENT_SONSTIG_TEXT", $$v)
              },
              expression: "form.INPUT_DOKUMENT_SONSTIG_TEXT"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }