<template>
  <div class="footer_container">
    <div class="footer_wrapper">
      <div class="footer_inner">
        <div class="footer_column broker_info">
          <div class="foot_header">{{maklerinfo.cleanMaklername}}</div>
          <div class="foot_body">
            <div>{{maklerinfo.strasse}}</div>
            <div>{{maklerinfo.plzOrt}}</div>
            <div>Telefon: {{maklerinfo.telefon}}</div>
            <div>E-Mail: {{maklerinfo.emailAddress}}</div>
          </div>
        </div>
        <div class="footer_column links">
          <div class="foot_header">Info</div>
          <div class="foot_body">
            <router-link to="kontakt">Kontakt</router-link>
            <div class="login_link">
              <a :href="anmeldenUrl">Anmelden</a>
            </div>

            <div>
              <router-link to="impressum">Impressum</router-link>
            </div>
            <div>
              <router-link to="agb">AGB</router-link>
            </div>
            <div>
              <router-link to="datenschutz">Datenschutzerklärung</router-link>
            </div>
            <div>
              <router-link to="esg">Nachhaltigkeit/ESG</router-link>
            </div>
            <div>
              <router-link to="beschwerdemanagement">Beschwerdemanagement</router-link>
            </div>
          </div>
        </div>

        <div class="footer_column unser_angebot">
          <div class="foot_header">Unser Angebot</div>
          <div class="foot_body">
            <editable-frame type="footer_editable" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EditableFrame from "@/components/cms/EditableFrame.vue";
import { mapGetters } from "vuex";
import CORE_TYPES from "@/store/core/types";

export default {
  components: {
    EditableFrame
  },
  props: {
    maklerinfo: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters({
      maklerInfo: CORE_TYPES.GETTERS.GET_BROKER_INFORMATION,
      anmeldenUrl: CORE_TYPES.GETTERS.GET_ANMELDEN_URL,
    }),
  }
};
</script>
<style scoped lang="scss">
::v-deep a {
  color: var(--color-top-nav-text);
  text-decoration: none;
}
.login_link {
  cursor: pointer;
}
.footer_container {
  max-width: 1200px;
  margin: 0 auto;
}
.footer_inner {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "broker_info  links  unser_angebot";
  justify-content: space-around;
  color: var(--color-top-nav-text);
}
.footer_wrapper {
  padding-bottom: 4em;
  margin: 0 auto;
}

.foot_header {
  font-weight: bold;
  padding-top: 1em;
  padding-bottom: 1em;
  text-align: start;
}
.foot_body {
  text-align: start;
}
.broker_info {
  grid-area: broker_info;
  padding-right: 4px;
}
.links {
  grid-area: links;
}
.unser_angebot {
  grid-area: unser_angebot;
}
@media only screen and (max-width: 1024px) {
  .footer_inner {
    padding-left: 2em;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "broker_info "
      "links"
      "unser_angebot ";
    justify-content: start;
  }

}
</style>