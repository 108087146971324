<template>
  <div class="fodb-auswahl-component">
    <ComboBox class="fodb-auswahl-component-combo-box-container mr-3"
      :label="label"
      :values="comboboxValues"
      v-model="INPUT_SERVICEGEBUEHR_FONDS"
      @input="onInputComboBox"/>
  </div>
</template>

<script>
import ComboBox from '@/components/core/forms/ComboBox.vue';
import { mapGetters } from 'vuex';
import ANTRAG_TYPES from '@/store/antrag/types';
import FONDSFINDER_TYPES from '@/store/fondsfinder/types';

export default {
  props: {
    config: {
      type: Object,
    },
    antragData: {
      type: Object,
    },
    antragId: {
    },
    label: {
      type: String,
    },
    /*value: {
      type: [String, Number]
    },
    values: {
      type: Array,
    },*/
    },
  data() {
    return {
      INPUT_SERVICEGEBUEHR_FONDS: ''
    };
  },
  computed: {
    ...mapGetters({
      positionsAll: ANTRAG_TYPES.GETTERS.POSITIONS,
      positionInfo: FONDSFINDER_TYPES.GETTERS.POSITION_INFO,
      //positionDataAll: ANTRAG_TYPES.GETTERS.POSITION_DATA
    }),
    comboboxValues(){
      const result = [
        {
          label: "Keine Auswahl treffen",
          value: "NICHT_VERWENDEN"
        }
      ]
      if(this.positionsAll && this.positionsAll[this.antragId]){
        Object.keys(this.positionsAll[this.antragId]).forEach((item) => {
          this.positionsAll[this.antragId][item].forEach((element) => {
            result.push(
              {
                label: element.fondsname + ", WKN: " + this.positininfos[element.isin]?.wkn + ", ISIN:" + element.isin,
                value: element.isin
              }
            )
          })
        })
      }
      return result;
    },
    positions() {
      if (this.positionsAll && this.antragId 
          && this.positionsAll[this.antragId]) {
        return this.positionsAll[this.antragId]
      }
    },
    positininfos() {
      if (this.positionInfo) {
        return this.positionInfo
      }
    },
    /*internalValue: {
      get: function() {
        return this.value;
      },
      set: function(modifiedValue) {
        this.$emit('input', modifiedValue);
      }
    }*/
  },
  created() {
    this.initForm();
  },
  methods: {
    initForm() {
      this.INPUT_SERVICEGEBUEHR_FONDS = this.antragData['INPUT_SERVICEGEBUEHR_FONDS']
    },
    onInputComboBox($event) {
      //this.$emit('input', $event);
      this.$emit("input", {
        INPUT_SERVICEGEBUEHR_FONDS: $event,
      });
    },
  },
  components: {
    ComboBox,
  }
}
</script>

<style scoped>
.fodb-auswahl-component {
  display: flex;
  align-items: flex-end;
}

.fodb-auswahl-component-combo-box-container {
  flex-grow: 1;
}

.fodb-auswahl-component-button {
  margin-bottom: 16px;
}
</style>
