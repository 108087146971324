var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _c("div", { staticClass: "font-strong mb-2" }, [
        _vm._v(
          " Die Anzeigepflicht gemäß § 21 FinVermV wurde beachtet. Der den zu prüfenden Zeitraum betreffende Schriftverkehr mit der für die Erlaubniserteilung zuständigen Behörde wurde Ihnen vollständig zur Verfügung gestellt. Im Prüfungszeitraum erfolgten "
        )
      ]),
      _c("InputRadioBoxGroup", {
        attrs: { values: _vm.vertragWPValues.anzeigepflichtAktiv },
        on: {
          input: function($event) {
            return _vm.fieldChanged()
          }
        },
        model: {
          value: _vm.form.anzeigepflichtAktiv,
          callback: function($$v) {
            _vm.$set(_vm.form, "anzeigepflichtAktiv", $$v)
          },
          expression: "form.anzeigepflichtAktiv"
        }
      }),
      _c("InputTextArea", {
        on: {
          change: function($event) {
            return _vm.fieldChanged()
          }
        },
        model: {
          value: _vm.form.anzeigepflicht,
          callback: function($$v) {
            _vm.$set(_vm.form, "anzeigepflicht", $$v)
          },
          expression: "form.anzeigepflicht"
        }
      }),
      _c("div", { staticClass: "font-strong mb-2" }, [
        _vm._v(
          " Kenntnisse über Kundenbeschwerden oder Rechtsstreitigkeiten aus den unter Ziffer C. 1 genannten Tätigkeiten im Kalenderjahr "
        )
      ]),
      _c("InputRadioBoxGroup", {
        attrs: { values: _vm.vertragWPValues.angabeBeschwerden },
        on: {
          input: function($event) {
            return _vm.fieldChanged()
          }
        },
        model: {
          value: _vm.form.angabeBeschwerden,
          callback: function($$v) {
            _vm.$set(_vm.form, "angabeBeschwerden", $$v)
          },
          expression: "form.angabeBeschwerden"
        }
      }),
      _c("InputField", {
        attrs: { isComponentHalfSize: "" },
        on: {
          change: function($event) {
            return _vm.fieldChanged()
          }
        },
        model: {
          value: _vm.form.beschwerdenAnlage,
          callback: function($$v) {
            _vm.$set(_vm.form, "beschwerdenAnlage", $$v)
          },
          expression: "form.beschwerdenAnlage"
        }
      }),
      _c("div", { staticClass: "font-strong" }, [
        _vm._v(" Zusätze und Bemerkungen ")
      ]),
      _c("InputTextArea", {
        on: {
          change: function($event) {
            return _vm.fieldChanged()
          }
        },
        model: {
          value: _vm.form.bemerkung,
          callback: function($$v) {
            _vm.$set(_vm.form, "bemerkung", $$v)
          },
          expression: "form.bemerkung"
        }
      }),
      _c("div", { staticClass: "font-strong" }, [
        _vm._v(" Bitte beachten und bestätigen Sie: ")
      ]),
      _c("InputToggleSwitch", {
        attrs: {
          inLineLabel: "",
          label:
            'der Vertrag muss, bei händischer Unterschrift, eingescannt und hochgeladen bzw. eingereicht werden. Siehe auch die Hinweise unter "Vertragsdaten".'
        },
        on: {
          input: function($event) {
            return _vm.fieldChanged()
          }
        },
        model: {
          value: _vm.form.bestVtg,
          callback: function($$v) {
            _vm.$set(_vm.form, "bestVtg", $$v)
          },
          expression: "form.bestVtg"
        }
      }),
      _c("InputToggleSwitch", {
        attrs: {
          inLineLabel: "",
          label:
            "die, für die Prüfung " +
            _vm.form.currentYear +
            " notwendigen Dokumente (v.a. Beratungsprotokolle, " +
            "Vermittlungsdokumentation sowie Anlegerprofile und Anträge) sind im MSC hochgeladen und final bearbeitet."
        },
        on: {
          input: function($event) {
            return _vm.fieldChanged()
          }
        },
        model: {
          value: _vm.form.bestDok,
          callback: function($$v) {
            _vm.$set(_vm.form, "bestDok", $$v)
          },
          expression: "form.bestDok"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }