var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "big-table" },
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          isSecondary: "",
          asPrimaryOnly: "",
          actions: _vm.headerActionsLocal,
          title: _vm.title
        },
        on: { action: _vm.handleHeaderAction }
      }),
      _c("div", { staticClass: "scroll-wrapper" }, [
        _c(
          "div",
          {
            ref: "tableContainer",
            staticClass: "table-container",
            on: { scroll: _vm.checkScroll }
          },
          [
            _c(
              "table",
              { ref: "table", style: { width: _vm.tableWidth + "px" } },
              [
                _c(
                  "colgroup",
                  _vm._l(_vm.visibleColumns, function(header, index) {
                    return _c("col", {
                      key: index,
                      style: { width: header.width + "px" }
                    })
                  }),
                  0
                ),
                _c("thead", { staticClass: "sticky__header" }, [
                  _c(
                    "tr",
                    _vm._l(_vm.visibleColumns, function(header, index) {
                      return _c(
                        "th",
                        {
                          key: index,
                          class: [
                            "text-" + _vm.getAlignment(header.type),
                            {
                              "hover-highlight":
                                index === _vm.hoveredColumnIndex
                            },
                            {
                              "fixed-left-col":
                                header.type === _vm.SELECTABLE_COLUMN ||
                                header.position == _vm.FIXED_LEFT
                            },
                            {
                              "fixed-right-col":
                                header.type === _vm.ACTION_COLUMN ||
                                header.position === _vm.FIXED_RIGHT
                            }
                          ],
                          style: _vm.getPositionStyle(header)
                        },
                        [
                          _c("div", { staticClass: "header-content" }, [
                            _c(
                              "div",
                              {
                                class:
                                  header.type != _vm.ICON_COLUMN &&
                                  header.type != _vm.STATIC_ICON_COLUMN &&
                                  header.type != _vm.ACTION_COLUMN
                                    ? "header-title"
                                    : "",
                                on: {
                                  click: function($event) {
                                    return _vm.onClickHeaderTitle(header)
                                  }
                                }
                              },
                              [
                                header.type !== _vm.SELECTABLE_COLUMN
                                  ? _c(
                                      "span",
                                      [
                                        !header.hideLabel
                                          ? _c("span", [
                                              _vm._v(_vm._s(header.label))
                                            ])
                                          : _vm._e(),
                                        _c("ph-caret-up", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.sortingState &&
                                                _vm.sortingState.key ==
                                                  header.key &&
                                                _vm.sortingState.direction ==
                                                  "desc",
                                              expression:
                                                "sortingState && sortingState.key == header.key && sortingState.direction == 'desc'"
                                            }
                                          ],
                                          attrs: { size: 16 }
                                        }),
                                        _c("ph-caret-down", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.sortingState &&
                                                _vm.sortingState.key ==
                                                  header.key &&
                                                _vm.sortingState.direction ==
                                                  "asc",
                                              expression:
                                                "sortingState && sortingState.key == header.key && sortingState.direction == 'asc'"
                                            }
                                          ],
                                          attrs: { size: 16 }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm.selectionMode ===
                                    _vm.MULTIPLE_SELECTIONS
                                  ? _c("span", [
                                      _c(
                                        "div",
                                        { staticClass: "header-content" },
                                        [
                                          _c("input", {
                                            attrs: { type: "checkbox" },
                                            on: {
                                              change: function($event) {
                                                return _vm.toggleRowsSelection(
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            ),
                            header.type !== _vm.ACTION_COLUMN &&
                            header.type !== _vm.SELECTABLE_COLUMN
                              ? _c("div", {
                                  staticClass: "resize-handle",
                                  on: {
                                    mouseover: function($event) {
                                      return _vm.setHoveredColumn(index)
                                    },
                                    mouseleave: function($event) {
                                      return _vm.clearHoveredColumn()
                                    },
                                    mousedown: function($event) {
                                      return _vm.startResizing(index, $event)
                                    }
                                  }
                                })
                              : _vm._e()
                          ])
                        ]
                      )
                    }),
                    0
                  )
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.visibleRows, function(row, rowIndex) {
                    return _c(
                      "tr",
                      {
                        key: rowIndex,
                        class: [
                          "table__row__" + rowIndex,
                          {
                            "hover-row-highlight":
                              rowIndex === _vm.hoveredRowIndex
                          },
                          { activeRow: row.selected }
                        ],
                        on: {
                          mouseover: function($event) {
                            return _vm.setHoveredRow(rowIndex)
                          },
                          mouseleave: function($event) {
                            return _vm.clearHoveredRow()
                          },
                          click: function($event) {
                            return _vm.onRowClick(row, $event)
                          }
                        }
                      },
                      _vm._l(_vm.visibleColumns, function(header, colIndex) {
                        return _c(
                          "td",
                          {
                            key: colIndex,
                            class: [
                              "text-" + _vm.getAlignment(header.type),
                              {
                                "hover-highlight":
                                  colIndex === _vm.hoveredColumnIndex
                              },
                              {
                                "hover-row-highlight":
                                  _vm.hoveredRowIndex == rowIndex
                              },
                              {
                                "fixed-left-col":
                                  header.type === _vm.SELECTABLE_COLUMN ||
                                  header.position == _vm.FIXED_LEFT
                              },
                              {
                                "fixed-right-col":
                                  header.type === _vm.ACTION_COLUMN ||
                                  header.position === _vm.FIXED_RIGHT
                              },
                              ,
                              { activeRow: row.selected }
                            ],
                            style: _vm.getMergedStyles(header, row, rowIndex)
                          },
                          [
                            _vm.selectable &&
                            header.type == _vm.SELECTABLE_COLUMN
                              ? _c("span", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: row.selected,
                                        expression: "row.selected"
                                      }
                                    ],
                                    attrs: { type: "checkbox" },
                                    domProps: {
                                      checked: Array.isArray(row.selected)
                                        ? _vm._i(row.selected, null) > -1
                                        : row.selected
                                    },
                                    on: {
                                      change: [
                                        function($event) {
                                          var $$a = row.selected,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  row,
                                                  "selected",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  row,
                                                  "selected",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(row, "selected", $$c)
                                          }
                                        },
                                        function($event) {
                                          return _vm.handleRowSelection(
                                            $event,
                                            row
                                          )
                                        }
                                      ]
                                    }
                                  })
                                ])
                              : header.type == _vm.SLOT_COLUMN
                              ? _vm._t(header.key, null, null, row)
                              : header.type == _vm.ICON_COLUMN
                              ? _c(
                                  "span",
                                  {
                                    class:
                                      "icon__cell__" +
                                      rowIndex +
                                      "__" +
                                      colIndex
                                  },
                                  [
                                    _c("IconCell", {
                                      attrs: { column: header.key, row: row },
                                      on: {
                                        onClick: function($event) {
                                          return _vm.iconCellClicked(
                                            header,
                                            row,
                                            rowIndex
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : header.type == _vm.STATIC_ICON_COLUMN
                              ? _c(
                                  "span",
                                  [
                                    _c("IconCell", {
                                      attrs: { column: header.key, row: row }
                                    })
                                  ],
                                  1
                                )
                              : header.type == _vm.LINK_COLUMN
                              ? _c("span", [
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.linkCellClicked(
                                            header,
                                            row
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(row[header.key]))]
                                  )
                                ])
                              : header.key !== "actions"
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatValue(
                                          row[header.key],
                                          header.type
                                        )
                                      ) +
                                      " "
                                  )
                                ])
                              : _c(
                                  "span",
                                  [
                                    _c("ActionCell", {
                                      key: rowIndex + colIndex,
                                      attrs: {
                                        tid: _vm.tid + rowIndex,
                                        column: header.key,
                                        row: row
                                      },
                                      on: {
                                        action: function($event) {
                                          return _vm.emitAction(
                                            $event,
                                            rowIndex
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                ),
                            header.type !== _vm.ACTION_COLUMN &&
                            header.type !== _vm.SELECTABLE_COLUMN
                              ? _c("div", {
                                  staticClass: "resize-handle",
                                  on: {
                                    mouseover: function($event) {
                                      return _vm.setHoveredColumn(colIndex)
                                    },
                                    mouseleave: function($event) {
                                      return _vm.clearHoveredColumn()
                                    },
                                    mousedown: function($event) {
                                      return _vm.startResizing(colIndex, $event)
                                    }
                                  }
                                })
                              : _vm._e()
                          ],
                          2
                        )
                      }),
                      0
                    )
                  }),
                  0
                )
              ]
            )
          ]
        )
      ]),
      _c("ModalColumnConfig", {
        ref: "columnsConfig",
        attrs: {
          title: _vm.title ? _vm.title + " - Einstellungen" : "Einstellungen",
          tableId: _vm.tableId,
          tableHeaders: _vm.configurableColumns,
          lockedColumns: _vm.fakeLockedColumns
        },
        on: {
          close: _vm.onCloseColumnsConfig,
          onFinishConfig: _vm.onFinishColumnsConfig,
          onRestoreDefault: _vm.onFinishColumnsConfig
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }