import axios from 'axios';
import BROKERDATA_TYPES from './types';
import CORE_TYPES from '../core/types';
import LOG_TYPES from '@/store/log/types';
import LOADING_TYPES from '@/store/loading/types';
import { viewDocument } from '@/components/core/download/DownloadLink.vue';
import { buildMessage } from '@/helpers/log-message-helper';
import Vue from 'vue';

const SERVICE_PATH = '/brokerData'
const config = {
  defaultSpinner: true
};

const multipartConfig = {
  defaultSpinner: true,
  headers: {'Content-Type': 'multipart/form-data'},
};


export default {

  [BROKERDATA_TYPES.ACTIONS.GET_BROKER_DATA]({ commit, dispatch, rootState }) {

    axios.get( `${rootState.core.apiAddress}/../mrsbrokerdata`, config).then(response => {
      if (response.status === 200 && response.data) {   
        commit(BROKERDATA_TYPES.MUTATIONS.GET_BROKER_DATA_SUCCESS, { brokerData: response.data })      
      }
    });
  },

  async [BROKERDATA_TYPES.ACTIONS.GET_BROKER_DATA_CONFIG]({ dispatch, commit, getters, rootState }, { forceUpdate = false, } = {}) {
    const brokerDataConfig = getters[BROKERDATA_TYPES.GETTERS.GET_BROKER_DATA_CONFIG];
    if(Object.keys(brokerDataConfig || {}).length > 0 && !forceUpdate) return;

    const loadingId = 'mrsbrokerdataconfig';
    dispatch(LOADING_TYPES.ACTIONS.START_LOADING, loadingId);
    await axios.get( `${rootState.core.apiAddress}/../mrsbrokerdataconfig`, config).then(response => {
      if (response.status === 200 && response.data) {   
        commit(BROKERDATA_TYPES.MUTATIONS.GET_BROKER_DATA_CONFIG_SUCCESS, { brokerDataConfig: response.data })      
      }
    })
    .finally(() => dispatch(LOADING_TYPES.ACTIONS.STOP_LOADING, loadingId));
  },

  async [BROKERDATA_TYPES.ACTIONS.FIND_BIRTHDAYS]({ getters, commit, }, { nextDays }) {
    if(!nextDays && nextDays !== 0) return ;
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/birthdays?nextDays=${nextDays}`, config);
    commit(BROKERDATA_TYPES.MUTATIONS.FIND_BIRTHDAYS_SUCCESS, response?.data || []);
  },
  
  [BROKERDATA_TYPES.ACTIONS.DELETE_PERSON]({ rootState, dispatch }, payload) {
   if(!payload.personId){
     return
   }
   return axios.delete(`${rootState.core.apiAddress}/${SERVICE_PATH}/deletePerson?personId=${payload.personId}`, config).then(response => {
    if (response.status === 200) {
      // dispatch(BROKERDATA_TYPES.ACTIONS.FIND_ANSPRECHPARTNER)
    }
  });
  },

  async [BROKERDATA_TYPES.ACTIONS.DELETE_MAKLER_PICTURE]({ rootState, dispatch }) {
   
    axios.delete(`${rootState.core.apiAddress}/${SERVICE_PATH}/deleteMaklerPicture`, config).then(response => {
     if (response.status === 200) {
       dispatch(BROKERDATA_TYPES.ACTIONS.GET_MAKLER_PICTURE)
     }
   });
   },
  
  async [BROKERDATA_TYPES.ACTIONS.GET_ANSPRECHPARTNER_BY_ID]({ rootState, commit }, { personId, servicePath }){
    await axios.get(`${rootState.core.apiAddress}/${servicePath}/getPerson`, {...config, params: { personId }}).then(response => {
      if (response.status === 200 && response.data) {   
        commit(BROKERDATA_TYPES.MUTATIONS.GET_ANSPRECHPARTNER_BY_ID_SUCCESS, {ansprechpartner: response.data}) 
      }
    });
    
  },

  async [BROKERDATA_TYPES.ACTIONS.FIND_ANSPRECHPARTNER]({ rootState, commit }){
    const response = await axios.get(`${rootState.core.apiAddress}/${SERVICE_PATH}/personen`, config);
    commit(BROKERDATA_TYPES.MUTATIONS.FIND_ANSPRECHPARTNER_SUCCESS, [ ...response?.data || [], ]);
  },

  async [BROKERDATA_TYPES.ACTIONS.GET_ANSPRECHPARTNER_ID]({ rootState, getters, }){
    const unternr = getters[CORE_TYPES.GETTERS.GET_USER_UNTER_NR];
    if(!unternr) return null;

    const response = await axios.get(`${rootState.core.apiAddress}/${SERVICE_PATH}/getPersonId?unternr=${unternr}`, config);
    return response?.data || null;
  },

  async [BROKERDATA_TYPES.ACTIONS.GET_BROKER_PRIMAER_PERSONEN]({ rootState, commit }, payload){
   
    await axios.get(`${rootState.core.apiAddress}/${SERVICE_PATH}/getPrimaerPersonen`, config).then(response => {
      if (response.status === 200 && response.data) {   
        commit(BROKERDATA_TYPES.MUTATIONS.GET_BROKER_PRIMAER_PERSONEN_SUCCESS, {primaerPersonen: response.data}) 
      }else{
        commit(BROKERDATA_TYPES.MUTATIONS.GET_BROKER_PRIMAER_PERSONEN_SUCCESS, {primaerPersonen: {}}) 
      }
    });
    
  },

  async [BROKERDATA_TYPES.ACTIONS.GET_ANSPRECHPARTNER_PICTURE]({ rootState, commit }, payload){
   
    await axios.get(`${rootState.core.apiAddress}/${SERVICE_PATH}/getAnspre?personId=${payload.personId}`, config).then(response => {
      if (response.status === 200 && response.data) {   
        commit(BROKERDATA_TYPES.MUTATIONS.GET_ANSPRECHPARTNER_BY_ID_SUCCESS, {ansprechpartner: response.data}) 
      }
    });
    
  },
  
  async [BROKERDATA_TYPES.ACTIONS.GET_MAKLER_PICTURE]({ rootState, commit, dispatch }, payload){
   
    await axios.get(`${rootState.core.apiAddress}/${SERVICE_PATH}/getMaklerPicture`, config).then(response => {
      if (response.status === 200 && response.data) {   
        commit(BROKERDATA_TYPES.MUTATIONS.GET_MAKLER_PICTURE_SUCCESS, {maklerPicture: response.data}) 
        dispatch(CORE_TYPES.ACTIONS.GET_USER_PICTURE);  
      }
    });
    
  },
  
  async [BROKERDATA_TYPES.ACTIONS.GET_BROKER_BY_ID]({ rootState, commit }, payload){
    if(!payload.brokerId){
      return
    }
    axios.get(`${rootState.core.apiAddress}/${SERVICE_PATH}/getBroker`, config).then(response => {
      if (response.status === 200 && response.data) {
        commit(BROKERDATA_TYPES.MUTATIONS.GET_BROKER_BY_ID_SUCCESS, { broker: response.data })  
      }
    });

  },

  async [BROKERDATA_TYPES.ACTIONS.APPLY_SETTINGS_VERSANDINFO]({ rootState }){
    return axios.post(`${rootState.core.apiAddress}/${SERVICE_PATH}/applySettings`, config)
  },
  async [BROKERDATA_TYPES.ACTIONS.SAVE_UPDATE_MAKLERKRITERIEN]({rootState,dispatch},payload){
    if(!payload){
      return 
    }
    await axios.post(`${rootState.core.apiAddress}/${SERVICE_PATH}/saveUpdateMaklerKriterien`,payload, config).then(response =>{
      if(response.status == 200){
        dispatch(BROKERDATA_TYPES.ACTIONS.GET_BROKER_DATA_CONFIG, { forceUpdate: true, });
      }
    })

  },

  async [BROKERDATA_TYPES.ACTIONS.SAVE_MAKLER_PICTURE]({getters, dispatch},payload){
    if(!payload){
      return 
    }
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', payload.file);
    
      axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsuserpicture`, formData, multipartConfig)
      .then((response) => {
        dispatch(BROKERDATA_TYPES.ACTIONS.GET_MAKLER_PICTURE);
        resolve();
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
    });
  },

  async [BROKERDATA_TYPES.ACTIONS.SAVE_PRIMAER_PERSONEN]({rootState, commit},payload){
    if(!payload){
      return 
    }
    try {
      const response = await axios.post(
        `${rootState.core.apiAddress}/${SERVICE_PATH}/savePrimaryPeople`,
        payload,
        config
      );
  
      if (response.status === 200 && response.data) {
        commit(BROKERDATA_TYPES.MUTATIONS.SAVE_PRIMAER_PERSONEN_SUCCESS, {
          primaerPersonen: response.data,
        });
      }
  
      return response.data || {};
    } catch (error) {
      console.error(error);
      return {};
    }
  },

  async [BROKERDATA_TYPES.ACTIONS.SAVE_BROKER_DATA]({ commit, rootState, getters, dispatch }) {
    const edited = getters[BROKERDATA_TYPES.GETTERS.GET_BROKER_DATA_EDITED];
    if (!edited || (!edited.brokerData && !edited.primaerPersonen)) {
      return;
    }
  
    try {
      let response = {};

      if (edited.primaerPersonen) {
        const responsePrimaerPersonen = await dispatch(BROKERDATA_TYPES.ACTIONS.SAVE_PRIMAER_PERSONEN, edited.primaerPersonen);
        response = { ...response, ...responsePrimaerPersonen };
      }
  
      if (edited.brokerData) {
        const responseBrokerData = await axios.post(`${rootState.core.apiAddress}/../mrsbrokerdata`, edited.brokerData, config);
        
        if (responseBrokerData.status === 200 && responseBrokerData.data) {
          commit(BROKERDATA_TYPES.MUTATIONS.SAVE_BROKER_DATA_SUCCESS, { brokerData: responseBrokerData.data });
          response = { ...response, ...responseBrokerData.data };
        }
        
      }

      return response;
    } catch (error) {
      console.error(error);
      return {};
    } finally {
      commit(BROKERDATA_TYPES.MUTATIONS.RESET_BROKER_DATA_EDITED);
    }
  },
  
  async [BROKERDATA_TYPES.ACTIONS.SAVE_ANSPRECHPARTNER]({ commit, rootState, getters},payload) {
   
    let edited = getters[BROKERDATA_TYPES.GETTERS.GET_MAKLER_ANSPRECHPARTNER_DATA_EDITED]
   
    if (edited == undefined || edited.ansprechpartner == undefined) {
      return
    }
    edited = {
      ...edited,
    };
    const response = await axios.put(`${rootState.core.apiAddress}/${SERVICE_PATH}/saveAnsprechpartner?personId=${payload}`, edited?.ansprechpartner, config).then((response) => {
      if (response.status === 200 && response.data) {      
          commit(BROKERDATA_TYPES.MUTATIONS.SAVE_MAKLER_ANSPRECHPARTNER_SUCCESS, { ansprechpartner: response.data })
      }

      return response || {};
    })
    .finally(() => commit(BROKERDATA_TYPES.MUTATIONS.RESET_MAKLER_ANSPRECHPARTNER_DATA_EDITED));

    return response?.data || {};
  },

  [BROKERDATA_TYPES.ACTIONS.CREATE_FACSIMILE]({ getters }, payload) {
   axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/createFacsimile?suffix=${payload.suffix}&personId=${payload.personId}`, config).then(response => {
      viewDocument({
        data: response.data?.bytes,
        filename: response.data?.fileName,
        contentType: 'application/pdf',
        fileExtension: 'pdf',
      });
    });
  },

  /* [BROKERDATA_TYPES.ACTIONS.FETCH_TERMINE]({ getters, commit }) {
    axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/getBrokerAppointments`, config).then(response => {
      if(response && response.data){
        commit(BROKERDATA_TYPES.MUTATIONS.FETCH_TERMINE_SUCCESS, { termine: response.data })  
      }
     });
   }, */

   [BROKERDATA_TYPES.ACTIONS.FETCH_ACTIVITIES]({ getters, commit }) {
    axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/getBrokerActivities`, config).then(response => {
      if(response && response.data){
        commit(BROKERDATA_TYPES.MUTATIONS.FETCH_ACTIVITIES_SUCCESS, { activities: response.data })  
      }
     });
   },

   async [BROKERDATA_TYPES.ACTIONS.FIND_BEMERKUNGEN]({ getters, commit }) {
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/getBemerkungenMakler`, config);
    commit(BROKERDATA_TYPES.MUTATIONS.FIND_BEMERKUNGEN_SUCCESS, response?.data || []);
  },

  [BROKERDATA_TYPES.ACTIONS.GET_BESUCHSBERICHTE]({ getters, commit, }) {
    return axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/besuchsberichte?mitBemerkung=true`, config).then(response => {
      commit(BROKERDATA_TYPES.MUTATIONS.GET_BESUCHSBERICHTE_SUCCESS, response.data)
    });
  },

  async [BROKERDATA_TYPES.ACTIONS.FILL_BIC_AND_BANK]({getters}, payload){
    if(!payload.iban || !payload.flag){
      return
    }
    const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/fillBicAndBank/${payload.iban}?flag=${payload.flag}`, config);
    return response
  },

  [BROKERDATA_TYPES.ACTIONS.GET_FACSIMILE_MAKLER]({ getters, commit }, payload) {
    axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/getFacsimileMakler`, config).then(response => {
      commit(BROKERDATA_TYPES.MUTATIONS.UPLOAD_FACSIMILE_SUCCESS, response.data);
    });
   },
  
  [BROKERDATA_TYPES.ACTIONS.UPLOAD_FACSIMILE_IMAGE]({ getters, dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      if (payload?.fileId) {
        axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/uploadFacsimileImage`, payload, config).then(response => {
          if (response.data?.errorStr) {
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.errorStr, 'danger'));
            resolve();
          } else {
            commit(BROKERDATA_TYPES.MUTATIONS.UPLOAD_FACSIMILE_SUCCESS, response.data);
            resolve();
          }
        });
      } else {
        reject();
      }
    });
  },
 
  [BROKERDATA_TYPES.ACTIONS.UPLOAD_FACSIMILE_PDF]({ getters, dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      if (payload?.fileId) {
        axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/uploadFacsimilePdf`, payload, config).then(response => {
          if (response.data?.errorStr) {
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.errorStr, 'danger'));
            resolve();
          } else {
            commit(BROKERDATA_TYPES.MUTATIONS.UPLOAD_FACSIMILE_SUCCESS, response.data);
            resolve();
          }
        });
      } else {
        reject();
      }
    })
  },

  [BROKERDATA_TYPES.ACTIONS.REMOVE_FACSIMILE]({ getters, dispatch, commit }, payload) {
    if(payload && payload.suffix){
      if(!payload.personId){
        payload.personId = ""
      }
      axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/removeFacsimile?suffix=${payload.suffix}&personId=${payload.personId}`, config).then(response => {
        if (response.data?.errorStr) {
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.errorStr, 'danger'));
        } else {
          commit(BROKERDATA_TYPES.MUTATIONS.UPLOAD_FACSIMILE_SUCCESS, response.data);
        }
      });
    }
   },

   [BROKERDATA_TYPES.ACTIONS.LOAD_SAVED_FACSIMILE_MITARBEITER]({ getters, commit }, payload){
    if(payload){
      axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/getFacsimileMitarbeiter?suffix=${payload.suffix}&personId=${payload.personId}`, config).then((response)=>{
        if(response && response.data){
          commit(BROKERDATA_TYPES.MUTATIONS.UPLOAD_FACSIMILE_SUCCESS, response.data)
        }
      })
    }
   },

   [BROKERDATA_TYPES.ACTIONS.GET_PIN_LIST]({rootState}) {
    const url = `${rootState.core.apiAddress}/tempFile/get_list_pin`;
    return axios.get(url, config)
  },
  
  [BROKERDATA_TYPES.ACTIONS.GET_TEMP_FILE]({rootState}, payload) {
    if (payload.id) {
      axios.get(`${rootState.core.apiAddress}/tempFile/get`, {
          defaultSpinner: true,
          responseType: 'arraybuffer',
          params: {
            tempFileId: payload.id
          }
        }).then(response => {
          if (response.status == 200){
            if (payload.type === '' ) {
              if (('' + response.data).substr(0,5) === 'JVBER'){
                payload.type = 'pdf';
                payload.contenttype = 'application/pdf';
                payload.fileName += '.pdf';
              } else {
                payload.type = 'xlsx';
                payload.contenttype =  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                payload.fileName += '.xlsx';
              }
            }
            viewDocument({
              data: response.data,
              filename: payload.fileName,
              contentType: payload.contenttype,
              fileExtension: payload.type,
            });
          }
        });
    }
  },
  
  [BROKERDATA_TYPES.ACTIONS.NOTWENDIGE_DOKUMENTE]({ rootState }, payload){
    return axios.post(`${rootState.core.apiAddress}/${SERVICE_PATH}/makler-rechtliche-pruefung?${payload.ask}`, payload, config);
  },
  
  [BROKERDATA_TYPES.ACTIONS.LOAD_WK_ANLAGEASSITEN_PARAMS]({ rootState }){
    return axios.get(`${rootState.core.apiAddress}/${SERVICE_PATH}/getWkAnlageassistentParams`, config);
  },

  [BROKERDATA_TYPES.ACTIONS.SAVE_WK_ANLAGEASSITEN_PARAMS]({ rootState }, payload){
    return axios.put(`${rootState.core.apiAddress}/${SERVICE_PATH}/saveWkAnlageassistentParams`, payload, config);
  },

  async [BROKERDATA_TYPES.ACTIONS.LOAD_BROKER_PAGE_TABLE_SIZE]({rootState, state, getters}){
    if (getters[CORE_TYPES.GETTERS.IS_GESELLSCHAFT]) {
      return;
    }
    Vue.set(state, 'pageTableSize', 0);
    if (getters[CORE_TYPES.GETTERS.IS_GESELLSCHAFT]) {
      return;
    }
    const response = await axios.get(`${rootState.core.apiAddress}/${SERVICE_PATH}/pageTableSize`, config);
    if (response.data?.pageTableSize != null) {
      Vue.set(state, 'pageTableSize', response.data.pageTableSize);
    } else if (state.pageTableSize === -1) {
      Vue.set(state, 'pageTableSize', 0);
    }
  },

  async [BROKERDATA_TYPES.ACTIONS.SAVE_NEW_BROKER]({ getters, }, payload){
    if(!payload?.firmenname || !payload?.plz) return;

    const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/saveNewBroker`, payload, config);
    return response?.data || {};
  },

  async [BROKERDATA_TYPES.ACTIONS.LOAD_STORNORESERVE_VERS]({ getters, commit}, payload){
    commit(BROKERDATA_TYPES.MUTATIONS.SET_STORNORESERVE_VERS, {loaded: -1, rows: []})
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/stornoreserveVers`+payload, config);
    
    const data = response?.data || [];
    if (typeof data.askPIN == 'number') {
      commit(CORE_TYPES.MUTATIONS.CHECK_PIN, {
        key: 'TABELLE_STORNORESERVE_VERS', 
        value: data.askPIN,
        action: BROKERDATA_TYPES.ACTIONS.LOAD_STORNORESERVE_VERS,
        payload: JSON.stringify(payload)
      })
    }
    commit(BROKERDATA_TYPES.MUTATIONS.SET_STORNORESERVE_VERS, {...data, loaded: 1} )
  },

  async [BROKERDATA_TYPES.ACTIONS.LOAD_SONSTIGES]({ getters, commit}){
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/sonstiges`, config);
    commit(BROKERDATA_TYPES.MUTATIONS.SET_SONSTIGES, {...response.data, edited: false} )
  },

  [BROKERDATA_TYPES.ACTIONS.SAVE_SONSTIGES]({state, getters}){
    axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/sonstiges`, state.sonstiges, config);
  },

}
