import BETEILIGUNGEN_TYPES from './types';
import Vue from 'vue';

export default {
  [BETEILIGUNGEN_TYPES.MUTATIONS.GET_BETEILIGUNGEN_SUCCESS](state, payload) {
    Vue.set(state, 'beteiligungen', Object.assign({}, payload));
  },
  [BETEILIGUNGEN_TYPES.MUTATIONS.GET_FAILTURE](state, payload) {
    console.log(payload);
  },

}
