var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _vm._l(_vm.zugriffVertraegeValues, function(zugriffVertraege) {
        return [
          _c("InputRadioBoxGroup", {
            key: zugriffVertraege.value,
            attrs: {
              title: zugriffVertraege.label,
              values:
                _vm.mapZugriffVertraegeKeyToValues[zugriffVertraege.value] || []
            },
            on: {
              input: function($event) {
                return _vm.onDataChanged()
              }
            },
            model: {
              value: _vm.zugriffVertraegeForm[zugriffVertraege.value],
              callback: function($$v) {
                _vm.$set(_vm.zugriffVertraegeForm, zugriffVertraege.value, $$v)
              },
              expression: "zugriffVertraegeForm[zugriffVertraege.value]"
            }
          })
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }