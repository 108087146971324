<template>
    <div>
        <PageHeaderTitleNavigation
            :title="$appNavigation.currentMenu.parent.label"
            :subtitle="$appNavigation.currentMenu.label"
            :defaultMenu="$appNavigation.currentTabMenu"
            :actions="actions"
            @action-REMOVE_ALL="confirmRemoveAll('removeAll')"
            @action-REMOVE_OVERTAKEN="confirmRemoveAll('removeOvertaken')"
            @action-RESET_OVERTAKEN="confirmRemoveAll('resetOvertaken')"
        />

        <div class="box__container">
            <div  class="box__title">Limits Depotpositionen</div>
            <div class="mb-4">
                <div><span>Hier erhalten Sie eine Übersicht über alle</span>
                    <span>{{isCustomerView ? ' Limits' : ', in den Depots Ihrer Kunden, gesetzten Limits'}}</span>
                    <span> für <b>Einzelfonds</b>.</span>
                </div>
                <div>Jedes einzelne Limit können Sie über die "Limits bearbeiten"-Aktion ändern. Ein rotes Ausrufezeichen vor den Fonds bedeutet, dass ein gesetztes Limit erreicht, aber noch nicht neu definiert wurde.</div>
            </div>
            <div class="layout__negative-margin--8 antrag-action-button__container">
                <InputToggleSwitch v-model="includeStruktur" label="Limits der Untermakler anzeigen" inLineLabel suppressValidationMessage v-if="!isCustomerView" @input="reload()"/>
            </div>
        </div>

        <div class="box__container">
            <div v-if="!loading && rows && rows.length">
                <Table
                    tableId="1114085c-6fc5-4470-a6e6-1f0b4a1ff658"
                    :title="TABLE_TITLE"
                    :headers="headers"
                    :rows="rows"
                    :rowsPerPage="20"
                    :mobileConfig="{title: 'wertpapierName', headers: ['ausgangskurs', 'aktuellKurs', 'unteresLimit', 'oberesLimit']}"
                    rowId="rowId"
                    @action-edit="editLimit"
                    @action-delete="removeLimit"
                    @action-factsheet="viewDocument($event, 'FACTSHEET')"
                    @action-kag="viewDocument($event, 'MR')"
                    @action-kid="viewDocument($event, 'KIID')"
                    @click-kundenLinkName="openCustomerNewTab"
                >
                </Table>
            </div>
            <GhostLoading v-else-if="loading" type="table" :title="TABLE_TITLE" />
            <NoData v-else :title="TABLE_TITLE" />
        </div>
        <BaseModal ref="confirmDelete" 
            labelButtonConfirm="Ja" 
            labelButtonCancel="Nein" 
            @onConfirmButton="removeAllLimits(true)"
            @onCancelButton="removeAllLimits(false)">
            <template v-slot:modalTitle>
                <ph-warning :size="16" class="mr-2" style="color: orangered"/>
                Löschen
            </template>
            <div>
                Wollen Sie wirklich alle {{confirmDeleteText}}?
            </div>
        </BaseModal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LIMIT_TYPES from '@/store/limit/types';
import Table from "@/components/table2/Table.vue";
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import { PhWarning, } from "phosphor-vue";
import mixin from '@/mixins/limit/limit-mixin.js';
import BaseModal from '@/components/core/BaseModal.vue';
import {TextColumn, NumberColumn} from "@/components/table2/table_util.js";
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';

const TABLE_TITLE = 'Depotpositionslimits';

export default {
    mixins: [mixin],
    components: {
        Table,
        InputToggleSwitch,
        PhWarning,
        BaseModal,
        PageHeaderTitleNavigation,
        GhostLoading,
        NoData,
    },
    data() {
        return {
            TABLE_TITLE,
            includeStruktur: '0',
            removeAction: null,
            loading: false,
        }
    },
    mounted() {
        if (this.$route.query.includeStruktur === '1') {
            this.includeStruktur = '1';
        }
        this.findDepotpositionLimits();
    },
    computed: {
        ...mapGetters({
            depotpositionlimits: LIMIT_TYPES.GETTERS.DEPOTPOSITION_LIMIT_LIST,
        }),
        headers() {
            const headers = this.headersWertpapierLimits();
            headers.center.push(TextColumn("changePerson", "geändert durch"));
            headers.center.splice(7, 0,
                TextColumn("isDynamic", "Dynamik"),
                NumberColumn("dynamicValue", "Dynamik Ausgangswert", 2));
            
            const kundenLinkName = TextColumn("kundenLinkName", "Kunde");
            if (!this.isCustomerView) {
                kundenLinkName.makeLink();
                 // insert after status
                headers.lockedLeft.splice(1, 0,
                    TextColumn("kundennr", "Kundennr").makeAlwaysVisible(),
                    kundenLinkName);
            }
            if ('1' === this.includeStruktur) {
                // insert after status
                headers.lockedLeft.splice(1, 0, TextColumn("maklernr", "Maklernr").makeAlwaysVisible());
            }
            return headers;
        },
        rows() {
            return this.addFondsActionsAndStatus(this.depotpositionlimits, false);
        },
        confirmDeleteText() {
            if (this.removeAction) {
                switch (this.removeAction) {
                    case 'removeOvertaken':
                        return 'überschrittenen Limits löschen';
                    case 'resetOvertaken':
                        return 'überschrittenen Limits zurücksetzen';
                    default:
                        return 'Limits löschen';
                }
            }
            return 'Limits löschen';
        },
        actions() {
            const actions = [];

            if (this.isCustomerView) {
                actions.push(PageHeaderSimpleAction('REMOVE_ALL', 'Alle Limits löschen').withDisabled(() => !this.rows?.length));
                actions.push(PageHeaderSimpleAction('RESET_OVERTAKEN', 'Überschrittene Limits zurücksetzen'));
            } else {
                actions.push(PageHeaderSimpleAction('REMOVE_OVERTAKEN', 'Überschrittene Limits löschen'));
                actions.push(PageHeaderSimpleAction('RESET_OVERTAKEN', 'Überschrittene Limits zurücksetzen'));
            }

            return actions;
        }
    },
    methods: {
        async findDepotpositionLimits() {
            try {
                this.loading = true;
                await this.$store.dispatch(LIMIT_TYPES.ACTIONS.GET_DEPOTPOSITION_LIMIT_LIST, {includeStruktur: this.includeStruktur});
            } finally {
                this.loading = false;
            }
        },
        editLimit(event) {
            if (event?.isin && event?.bgsNr) {
                this.$addBreadcrumb({
                    label: `zurück zu den Depotpositionslimits`,
                    fullPath: this.$route.fullPath,
                    breadcrumb: 'Depotpositionslimit',
                });
                this.$router.push(`/home/vermogensubersicht/wertpapiere/limits/${event.isin}/${event.bgsNr}`)
            }
        },
        removeLimit(event) {
            if (event?.bgsNr) {
                this.$store.dispatch(LIMIT_TYPES.ACTIONS.DELETE_DEPOTPOSITION_LIMIT, {bgsNr: event.bgsNr, includeStruktur: this.includeStruktur});
            }
        },
        reload() {
            this.findDepotpositionLimits();
        },
        confirmRemoveAll(action) {
            if (this.rows?.length && action) {
                this.removeAction = action;
                this.$refs.confirmDelete.open();
            }
        },
        removeAllLimits(confirmed) {
            if(confirmed) {
                this.$store.dispatch(LIMIT_TYPES.ACTIONS.DELETE_ALL_DEPOTPOSITION_LIMITS, { action: this.removeAction,  includeStruktur: this.includeStruktur});
            }
            this.removeAction = null;
            this.$refs.confirmDelete.close();
        },
    }
}
</script>

<style>

</style>