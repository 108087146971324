<template>
  <div>
    <div class="box__title">Abrechnungen</div>

    <Table
      v-if="!loading && tableData.records.length"
      tableId="efc905f7-29e0-4fce-834b-b05a46a33983"
      :tableData="tableData"
      :actions="tableActions"
      :cardViewEnabled="false"
      :filterEnabled="false"
      :exportEnabled="false"
      @execute-action="handleTableAction($event)"
    />
    <div v-else-if="loading" class="text-centered"><AnimatedSpinner /></div>
    <div v-else>Keine Daten</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CREDIT_TYPES from '@/store/credit/types';
import CORE_TYPES from '@/store/core/types';

import Table from '@/components/table/Table.vue';
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';


export default {
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      buchungen: CREDIT_TYPES.GETTERS.BUCHUNGEN,
      rights: CREDIT_TYPES.GETTERS.RIGHTS,
      isBrokerMaster: CORE_TYPES.GETTERS.IS_BROKER_MASTER,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
    }),
    allowEditCourtage() {
      return this.isBrokerMaster && this.rights?.allowModifyVertragAbrechnung || this.isIntern;
    },
    tableHeaders() {
      const headers = {};

      headers['buchungsnr'] = {
        label: 'Buchungsnr',
        key: 'buchungsnr',
        dataType: 'String',
        visible: true,
        sortable: true,
      };

      headers['zeichnungsdatum'] = {
        label: 'Z.-Datum',
        key: 'zeichnungsdatum',
        dataType: 'String',
        visible: true,
        sortable: true,
      };

      headers['courtageeingDatum'] = {
        label: 'Prov. Eingang',
        key: 'courtageeingDatum',
        dataType: 'String',
        visible: true,
        sortable: true,
      };

      headers['courtageart'] = {
        label: 'Prov. Art',
        key: 'courtageart',
        dataType: 'String',
        visible: true,
        sortable: true,
      };

      headers['bewertungssumme'] = {
        label: 'Bew.Summe',
        key: 'bewertungssumme',
        dataType: 'Currency',
        visible: true,
        sortable: true,
      };

      if(this.isIntern) {
        headers['courtagesumme'] = {
          label: 'Courtagesumme',
          key: 'courtagesumme',
          dataType: 'Currency',
          visible: true,
          sortable: true,
        };
      }

      headers['status'] = {
        label: 'Status',
        key: 'status',
        dataType: 'String',
        visible: true,
        sortable: true,
      };

      if(this.allowEditCourtage) {
        headers['automatisch'] = {
          label: 'Auto',
          key: 'automatisch',
          dataType: 'String',
          visible: true,
          sortable: true,
        };
      }

      headers['rechDatum'] = {
        label: 'Abr. Datum',
        key: 'rechDatum',
        dataType: 'String',
        visible: true,
        sortable: true,
      };

      if(this.allowEditCourtage) {
        headers['userId'] = {
          label: 'Bearbeiter',
          key: 'userId',
          dataType: 'String',
          visible: true,
          sortable: true,
        };
      }

      headers['bemerkung'] = {
        label: 'Bemerkung',
        key: 'bemerkung',
        dataType: 'String',
        visible: true,
        sortable: true,
      };

      headers['actions'] = {
        label: 'Aktionen',
        key: 'actions',
        dataType: 'tableAction',
        visible: true,
        sortable: false,
      };

      return headers;
    },
    tableData() {
      return {
        headers: this.tableHeaders,
        records: [...this.buchungen],
      };
    },
    tableActions() {
      return [
        {
          visible: (row) => !row?.allowModify,
          legend: {
            icon: 'PhList',
            index: 1,
            key: 'VIEW_PROV_STRUKTUR',
            label: 'Verteilung der Abrechnung auf die Struktur',
          }
        },
      ];
    },
  },
  methods: {
    async findBuchungen() {
      this.loading = true;
      await this.$store.dispatch(CREDIT_TYPES.ACTIONS.FIND_BUCHUNGEN);
      this.loading = false;
    },
    handleTableAction(event) {
      if(!event?.value || !event?.action) {
        return ;
      }

      switch(event.action?.legend?.key) {
        case 'VIEW_PROV_STRUKTUR':
          this.$store.commit(CREDIT_TYPES.MUTATIONS.SET_SELECTED_BUCHUNG, event?.value);
          break;
        default:
          console.log(`action not implemented yet: ${event.action?.legend?.key}`);
          break;
      }
    },
  },
  mounted() {
    this.findBuchungen();
  },
  components: {
    Table,
    AnimatedSpinner,
  },
}
</script>
