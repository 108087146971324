<template>
  <div>
    <PageHeaderTitleNavigation
      :title="$appNavigation.currentMenu.parent.label"
      subtitle="Änderungshistorie Datenschutzerklärung">
    </PageHeaderTitleNavigation>

    <!-- TODO: create filter (the original module in java MSC has no filter) -->

    <div class="box__container" tid="bbdab63b-0e5b-4bdb-935b-34c55fb1a47f">

      <BaseButton class="mb-2"  v-if="textModus"
        label="Externe Erklärung eintragen"
        @click="navigateTo('externeErklaerungEintragen/' + textModus)" 
      />

      <p v-if="!loading && tableRows.length">Hier können Sie die vom Historie der vom Kunden abgegebenen Datenschutz- / Kundenanspracheerklärungen sowie Änderungen in 
        seinen Kommunikationseinstellungen einsehen</p>

      <Table
        tableId="609f1611-cd3b-4c50-94e8-5580df622ec5"
        :title="TABLE_TITLE"
        class="table-container"
        v-if="!loading && tableRows.length"
        :headers="tableHeaders"
        :rows="tableRows"
        :rowsPerPage="20"
        :count="resultCount"
      >

      <template #description="row">
        <Pill v-if="row.akzeptiertDesc.status.length" :label="row.akzeptiertDesc.status" :type="row.akzeptiertDesc.color"></Pill>
        <a v-if="canSeeSpecialColumns" @click="navigateTo('details/' + row.id)">{{ row.akzeptiertDesc.description  }}</a>
        <span v-else>{{ row.akzeptiertDesc.description  }}</span>
      </template>
        
      </Table>
      <GhostLoading v-else-if="loading" type="table" :title="TABLE_TITLE" />
      <NoData v-else :title="TABLE_TITLE" />
    </div>    

  </div>
    
</template>

<script>
import CORE_TYPES from "@/store/core/types";
import DATENSCHUTZKUNDENAENDERUNGEN_TYPES from "@/store/datenschutzkundenaenderungen/types";
import { mapGetters } from "vuex";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, PillColumn, SlotColumn, DateTimeColumn} from "@/components/table2/table_util.js";
import { VIEW_ROLES } from '@/router/roles';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import Pill from '@/components/core/Pill.vue';
import BaseButton from "@/components/core/BaseButton.vue";

const TABLE_TITLE = 'Änderungshistorie Datenschutzerklärung';

export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    Table,
    GhostLoading,
    NoData,
    OptionMenu,
    PageHeaderTitleNavigation,
    Pill,
    BaseButton,
  },
  data: function () {
      return {
        TABLE_TITLE,
        resultCount: null,
        filtered: false,
        loading: true,
      }
  },
  computed: {
    ...mapGetters({
      mainListing: DATENSCHUTZKUNDENAENDERUNGEN_TYPES.GETTERS.MAIN_LISTING,
      textModus: DATENSCHUTZKUNDENAENDERUNGEN_TYPES.GETTERS.TEXT_MODUS,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      isFA: CORE_TYPES.GETTERS.IS_FA,
    }),
    canSeeSpecialColumns() {
      return this.hasRoles(VIEW_ROLES.VIEW_CUSTOMER_AS_INTERN);
    },
    tableHeaders() {
      const headers = {
        lockedLeft: [
          TextColumn("id", "Id").makeHidden(),
          SlotColumn("description", "Akzeptierte Erklärung").makeAlwaysVisible(),
          DateTimeColumn("dateStand", "Stand"),
        ],
        center: [
          PillColumn("statusKommEmail", "E-Mail"),
          PillColumn("statusKommaktivApp", "App"),
          PillColumn("statusKommaktivTelefon", "Telefon"),
          PillColumn("statusKommaktivFax", "Fax"),
          PillColumn("statusKommaktivPost", "Post"),
        ],   
      };

      return headers;
     
    },
    tableRows () {
      if (!this.mainListing?.length ) {
        return [];
      }

      return this.mainListing.map(data => ({
          id: data.id,
          akzeptiertDesc: data.akzeptiertDesc,
          hasDisplayableData: data.hasDisplayableData,
          dateStand: data.dateStand,
          tooltip: data.tooltip,
          statusKommEmail:  {
            label:  `${data?.statusKommEmail?.textWish}, ${data?.statusKommEmail?.textSetting}`,
            type: data?.statusKommEmail?.color,
          },
          statusKommaktivApp:  {
            label:  `${data?.statusKommaktivApp?.textWish}, ${data?.statusKommaktivApp?.textSetting}`,
            type: data?.statusKommaktivApp?.color,
          },
          statusKommaktivTelefon:  {
            label:  `${data?.statusKommaktivTelefon?.textWish}, ${data?.statusKommaktivTelefon?.textSetting}`,
            type: data?.statusKommaktivTelefon?.color,
          },             
          statusKommaktivFax:  {
            label:  `${data?.statusKommaktivFax?.textWish}, ${data?.statusKommaktivFax?.textSetting}`,
            type: data?.statusKommaktivFax?.color,
          },
          statusKommaktivPost:  {
            label:  `${data?.statusKommaktivPost?.textWish}, ${data?.statusKommaktivPost?.textSetting}`,
            type: data?.statusKommaktivPost?.color,
          },

      }));




    },
  },
  mounted() {
    this.retrieveData();  
  },
  methods: {
    retrieveData() {
      this.loading = true;

      this.$store.dispatch(
        DATENSCHUTZKUNDENAENDERUNGEN_TYPES.ACTIONS.GET_MAIN_LISTING).then(() => {
                this.loading = false;
            });  

    },
    filterForm(filterConfig) {
      const mitZweigstellen = filterConfig.find(fc => fc.key=="mitZweigstellen");
      const vermittlernummer = filterConfig.find(fc => fc.key=="vermittlernummer");
      const gesellschaften = filterConfig.find(fc => fc.group=="gesellschaften");

      this.retrieveData(mitZweigstellen?.value, vermittlernummer?.value, gesellschaften?.key) ;
    },
    navigateTo(event) {
      this.$router.push({ path: `${event}` });
    },
  },
  async beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu Änderungshistorie Datenschutzerklärung', 
      fullPath: from.fullPath,
      breadcrumb: to?.meta?.breadcrumb?.label,
    });

    // this.$store.commit(DATENSCHUTZKUNDENAENDERUNGEN_TYPES.MUTATIONS.RESET_STATE);

    next();
  },


    
}
</script>