import actions from './actions';
import mutations from './mutations';
import getters from './getters';

/**
 * mutations: synchronous operation responsible for change the state
 * actions: asynchronous operation responsible for call external resources and commit mutations 
 */
export function getInitialState() {
  return {
    positions: [],
    lagerstellen: [],
    gesellschaften: [],
    wertpapiere: [],
    kategorien: [],
    comboboxValues: {},
    antraegePdf: null,
    antraegeEmail: null,
    tempFileId: '',
    emailData: {},
    data: {
      TARGET_ISIN: true,
      TARGET_KATEGORIE: false,
      lagerstelle: 'LAGERSTELLE_ALLE',
      gesellschaft: 'GESELLSCHAFT_ALLE',
      kategorie: '',
      wertpapier: 'ISIN_KEINE',
      zusatz: '',
      mindestwert: '',
      hoechstwert: '',
      mindestanteil: '',
      maxanteil: '',
      termin: 'sofort',
      bemerkung: '',
      betrag: '',
      betragModus: 'VALUE_BETRAG_MODUS_ALLE_ANTEILE',
      tauschInIsin: '',
      tauschInIsin_wertpapiername: '',
      inputAA: '',
    },
  }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}