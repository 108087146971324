<template>
  <div>
    <ComboBox class="mr-3" 
      :label="label" :values="getValues" v-model="internalValue" :disabled="disabled"/>
  </div>
</template>

<script>
import ComboBox from '@/components/core/forms/ComboBox.vue';

export default {
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    value: {
    },
    antragData: {
    },
    comboboxSelections: {
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      internalValue: '',
      depotNummer: this.antragData && this.antragData['INPUT_COMBO_DEPOTNUMMER'] || '',
    };
  },
  watch: {
    value: function(newValue) {
      this.internalValue = newValue;
    }
  },
  computed: {
    hasLink() {
      return this.internalValue && LINK_TO_FORM[this.internalValue];
    },
    getValues() {
      return this.depotNummer && this.comboboxSelections && this.comboboxSelections['INPUT_COMBO_VERTRAGSNUMMER']
        && this.comboboxSelections['INPUT_COMBO_VERTRAGSNUMMER'][this.depotNummer] || [];
    }
  },
  methods: {
    openAntrag() {
      if(this.hasLink) {
        const bachToPreviousFormLink = `/beratung/formulare/antrag/${this.$route.params.lagerstelle}/${this.$route.params.antragsName}`;

        this.$router.push({ 
          path: LINK_TO_FORM[this.internalValue], 
          query: {
            beratungsMappeId: this.beratungsMappeId,
            bachToPreviousFormLink: bachToPreviousFormLink,
            backToPreviousFormLabel: this.antragName
          }
        });
      }
    }
  },
  components: {
    ComboBox,
  }
}
</script>

<style scoped>
</style>
