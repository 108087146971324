<template>
  <BaseModal 
    :showDefaultButtons="false"
    :actions="modalActions"
    @action-RESET="restoreDefault(); close();"
    @action-SAVE="saveConfigEdited(); close();"
    @onCloseButton="saveConfigEdited()"
    @close="onClose(); $emit('close');"
    size="sm"
    ref="baseModal"
  >

    <template v-slot:modalTitle>
      <div class="column-config-modal--title-container clearfix">
        <span class="column-config-modal--title">{{title}}</span>
      </div>
    </template>

    <template #modalHeaderBottom>
      <ColumnConfigTabs v-if="tableId" :disabled="loading" :selected="userLevelSaveConfig" @tabSelected="onTabSelected($event.key)" />
    </template>

    <template v-slot:default>
      <div>
        <div class="filter__container">
          <FixedColumnList title="Fixe Spalten links" :list="internTableHeadersFixedToLeft" class="mb-16px" />

          <FixedColumnList title="Fixe Spalten rechts" :list="internTableHeadersFixedToRight" class="mb-16px" />

          <div class="font-bold" v-if="internTableHeaders.length">{{`Konfigurierbare ${isMobileTable ? 'Zeilen' : 'Spalten'}`}}</div>
          <GhostLoading v-if="loading">
            <Block v-for="(header, index) in internTableHeaders" :key="index" 
              height="40px" :style="{ margin: '1px 0 0 0', }" :class="index === 0 ? 'mb-0' : ''" />
          </GhostLoading>
          <SortableList v-else :items="internTableHeaders" @orderChanged="onOrderChanged($event)">
            <ul class="list-bordered" data-sortable-container>
              <template v-for="(item) in internTableHeaders">
                <li v-bind:key="item.key" class="list-bordered-item" data-sortable-item v-if="item.dataType !== 'tableAction'">
                  <div class="d-flex justify-content-between">
                    <div class="config-column-item--title">
                      <span>{{item.label}}</span>
                    </div>
                    <div class="config-column-item--actions">
                      <div class="column-config__controls-container">
                        <button type="button" class="btn-clear clickable" :disabled="item.alwaysVisible" @click="toggleShowColumn(item)">
                          <ph-eye 
                            weight="bold" 
                            :size="16" 
                            v-if="item.visible"
                          />
                          <ph-eye-slash 
                            weight="bold" 
                            :size="16" 
                            v-else
                          />
                        </button>
                        <div v-if="ordered" data-sortable-item-handler><ph-list :size="16" weight="bold" /></div>
                      </div>
                    </div>     
                  </div>
                </li>
              </template>
            </ul>
          </SortableList>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>

import BaseModal from '@/components/core/BaseModal.vue'
import BaseButton from '@/components/core/BaseButton.vue'
import SortableList from '@/components/core/SortableList.vue'
import { PhEye, PhEyeSlash, PhList } from 'phosphor-vue'
import { mapGetters } from 'vuex'
import FC_CONFIG_TYPES from '@/store/fcConfig/types';

import FixedColumnList from './FixedColumnList.vue';
import ColumnConfigTabs from './ColumnConfigTabs.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';

import FC_CONFIG from '@/configs/fcConfig';

import { FC_CONFIG_USER_LEVEL } from '@/configs/fcConfig';
import { BaseModalSimpleAction } from '@/components/core/base-modal-actions/base-modal-actions-utils';

function usesInitialUserLevel(initialUserLevel) {
  if(initialUserLevel === FC_CONFIG_USER_LEVEL.GLOBAL) {
      return null;
  }

  return initialUserLevel;
}

export default {
  props: {
    tableId: {
      type: String,
    },
    tableHeaders: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
    },
    ordered: {
      type: Boolean,
      default: true
    },
    lockedColumns: {
      type: Object,
      default: () => ({})
    },
    isMobileTable: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      loading: false,
      headersEdited: {},
      userLevelSaveConfig: null,
    }
  },
  computed: {
    ...mapGetters({
      fcConfigTableColumn: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_TABLE_COLUMN,
      fcConfigByUserLevel: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_BY_USER_LEVEL,
      userLevelDefault: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_USER_LEVEL_DEFAULT,
    }),
    modalActions() {
      return [
        BaseModalSimpleAction('RESET', 'Zurücksetzen').withPrimary(() => false),
        BaseModalSimpleAction('SAVE', 'Schließen').withPrimary(() => true),
      ];
    },
    tableColumnConfig() {
      const { tableId, userLevelSaveConfig, } = this;
      if(!tableId) return {};
      return this.fcConfigByUserLevel(FC_CONFIG.TABLE_COLUMN_CONFIG, tableId, userLevelSaveConfig);
    },
    columnsConfig() {
      const { tableColumnConfig, } = this;
      if(!tableColumnConfig?.content) {
        return [];
      }

      let columnsConfig = JSON.parse(tableColumnConfig?.content);
      if (typeof(columnsConfig) == "object") { // it is to handle legacy table config structure
        columnsConfig = Object.values(columnsConfig);
      }

      if (this.isMobileTable) {
        columnsConfig = columnsConfig.sort(function(x, y) { return y.visible - x.visible })
      }

      return columnsConfig
        .filter(conf => {
          const header = this.tableHeaders.find(header => header.key == conf.key);
          return header && !header.locked;
        });
    },
    configuredHeaders() {
      const { columnsConfig, } = this;

      let i = 0;
      return this.tableHeaders.map(header => {
        if ((!header.locked || this.isMobileTable) && columnsConfig.find(c => c.key == header.key)) {
          header = {
            ...this.getHeader(columnsConfig[i].key),
            visible: columnsConfig[i].visible,
          };
          i++;
        }
        return header;
      });
    },
    currentUserLevel() {
      // it is used only to get the current userLevel
      const currentTableColumnConfig = this.fcConfigTableColumn?.[this.tableId] || {};
      return currentTableColumnConfig?.userLevel;
    },
    internTableHeaders() {
      if(this.userLevelSaveConfig in this.headersEdited) {
        return this.headersEdited[this.userLevelSaveConfig]
      }

      return this.configuredHeaders
        ?.filter?.(this.isNotPresentInLockedColumns)
        .map(header => ({...header})) || []
    },
    internFixedTableHeaders() {
      return this.configuredHeaders
        ?.filter?.(this.isPresentInLockedColumns)
        .map(header => ({...header})) || []
    },
    internTableHeadersFixedToLeft() {
      return this.configuredHeaders
        ?.filter?.(this.isPresentInLockedColumnsToLeft)
        .map(header => ({...header})) || []
    },
    internTableHeadersFixedToRight() {
      return this.configuredHeaders
        ?.filter?.(this.isPresentInLockedColumnsToRight)
        .map(header => ({...header})) || []
    },
  },
  methods: {
    async onTabSelected(userLevel) {
      this.userLevelSaveConfig = userLevel;
      await this.findFCConfigByUserLevel(userLevel);
    },
    async findFCConfigByUserLevel(userLevel) {
      const { tableId, } = this;

      if(!tableId) return;

      try {
        const payload = {
          configId: tableId,
          configType: FC_CONFIG.TABLE_COLUMN_CONFIG,
          userLevel,
          forceReload: true,
        };

        this.loading = true;
        await this.$store.dispatch(FC_CONFIG_TYPES.ACTIONS.LOAD_FC_CONFIG_BY_USER_LEVEL, payload);
      } finally {
        this.loading = false;
      }
    },
    getHeader(key) {
      return this.tableHeaders.find(header => header.key == key);
    },
    isPresentInLockedColumnsToLeft(th) {
      return (this.lockedColumns?.toLeft ?? []).some(item => item.key === th.key);
    },
    isPresentInLockedColumnsToRight(th) {
      return (this.lockedColumns?.toRight ?? []).some(item => item.key === th.key);
    },
    isPresentInLockedColumns(th) {
      const allLocked = [...(this.lockedColumns?.toRight ?? []), ...(this.lockedColumns?.toLeft ?? [])]
      return allLocked.some(item => item.key === th.key)
    },
    isNotPresentInLockedColumns(th) {
      return !this.isPresentInLockedColumns(th)
    },
    open() {
      this.$refs.baseModal.open()

      this.userLevelSaveConfig = usesInitialUserLevel(this.currentUserLevel) || this.userLevelDefault;
      this.onTabSelected(this.userLevelSaveConfig)
    },
    close() {
      this.$refs.baseModal.close();
    },
    onClose() {
      this.loading = false;
      this.headersEdited = {};
      this.userLevelSaveConfig = null;
    },
    async saveConfigEdited() {
      const headersEdited = { ...this.headersEdited, };
      const { tableId, } = this;
      if(tableId && Object.keys(headersEdited).length > 0) {
        const promises = Object.keys(headersEdited).map(async userLevel => {
          const payload = {
            configId: this.tableId,
            configType: FC_CONFIG.TABLE_COLUMN_CONFIG,
            content: JSON.stringify(headersEdited?.[userLevel]),
            userLevel: userLevel,
          };
          return await this.$store.dispatch(FC_CONFIG_TYPES.ACTIONS.SAVE_ONLY, payload);
        });
        await Promise.all(promises);
        await this.reloadConfig(true);
      }

      this.$emit('onFinishConfig');
    },
    onOrderChanged(event) {
      const headers = [ ...event, ];
      this.$set(this.headersEdited, this.userLevelSaveConfig, headers);
      this.$emit('orderChanged', headers)
    },
    toggleShowColumn(item) {
      item.visible = !item.visible

      const headers = [ ...this.internTableHeaders, ];
      this.$set(this.headersEdited, this.userLevelSaveConfig, headers);
      this.$emit('orderChanged', headers)
    },
    async restoreDefault() {
      const { tableId, userLevelSaveConfig, } = this;
      if (tableId && userLevelSaveConfig) {
        const savePayload = {
          configId: tableId,
          configType: FC_CONFIG.TABLE_COLUMN_CONFIG,
          userLevel: userLevelSaveConfig,
        }
        await this.$store.dispatch(FC_CONFIG_TYPES.ACTIONS.DELETE_FC_CONFIG, savePayload);
      }

      this.$emit('onRestoreDefault')
    },
    async reloadConfig() {
      const { tableId, } = this;
      if (tableId) {
        await this.$store.dispatch(FC_CONFIG_TYPES.ACTIONS.LOAD_FC_CONFIG, {
          configId: tableId,
          configType: FC_CONFIG.TABLE_COLUMN_CONFIG,
          forceReload: true,
        });
      }
    },
  },
  components: {
    BaseModal,
    BaseButton,
    SortableList,
    PhEye, 
    PhEyeSlash, 
    PhList,
    FixedColumnList,
    ColumnConfigTabs,
    GhostLoading,
    Block,
  }
}
</script>

<style scoped>

.column-config__controls-container {
  margin: 0 -8px;
  display: flex;
}

.column-config__controls-container > * {
  margin: 0 8px;
}

.config-column-item--actions {
  flex: 0 0 auto;
}

</style>