import { mapGetters } from 'vuex';
import {TextColumn} from "@/components/table2/table_util.js";
import EXTERNE_ZUGANG_BANK_TYPES from '@/store/externeZugangBank/types';
import FC_CONFIG_TYPES from '@/store/fcConfig/types';

import FC_CONFIG from '@/configs/fcConfig';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

export const FFB = 'FFB';
export const EBASE_AAB = 'EBASE_AAB';

export const TABLE_HEADERS = {
    lockedLeft: [
        TextColumn("company", "Gesellschaft"),
        TextColumn("label", "Bezeichnung").makeLink(),
    ],
};

const mixin = {
  methods: {
    openExterneZugangBank({ konsorte, depotnr, lagerstelle = 'FFB' }) {
      if (depotnr)
      this.$store.dispatch(EXTERNE_ZUGANG_BANK_TYPES.ACTIONS.OPEN_URL, {
        company: lagerstelle,
        id: konsorte,
        payload: {
          depotnummer: depotnr,
        },
      });
    }
  }
}

export const configMixin = {
  data() {
    return {
      loadingConfig: false,
    };
  },
  computed: {
    ...mapGetters({
      fcConfig: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG,
    }),
    configuredList() {
      const content = this.fcConfig?.[FC_CONFIG.EXTERNE_ZUGANG_BANK_CONFIG]?.[FC_CONFIG.EXTERNE_ZUGANG_BANK_CONFIG]?.content;
      if(!content) return [];
      return JSON.parse(content);
    },
    headerActions() {
      return [
        PageHeaderSimpleAction('EDIT-ROWS', 'Externe Zugänge anpassen')
          .withDisabled(() => this.isLoading)
          .withVisible(() => this.$showOnlyForTestUser), // TODO remove test user flag after business approval
      ];
    },
  },
  methods: {
    async getConfig(forceReload = false) {
      try {
        this.loadingConfig = true;

        const payload = {
          configId: FC_CONFIG.EXTERNE_ZUGANG_BANK_CONFIG,
          configType: FC_CONFIG.EXTERNE_ZUGANG_BANK_CONFIG,
          forceReload,
        };
  
        await this.$store.dispatch(FC_CONFIG_TYPES.ACTIONS.LOAD_FC_CONFIG, payload);
      } finally {
        this.loadingConfig = false;
      }
    },
    filterVisibleItems(list, configuredList) {
      return [ ...list || [], ]
        .filter(item => !configuredList.some(configured => configured.id == item.id && configured.company == item.company && configured.visible === false))
    },
  }
};

export default mixin;
