var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.$appNavigation.currentMenu.parent.label,
          subtitle: _vm.$appNavigation.currentMenu.label,
          defaultMenu: _vm.$appNavigation.currentTabMenu,
          actions: _vm.actions
        },
        on: {
          "action-REMOVE_ALL": function($event) {
            return _vm.confirmRemoveAll("removeAll")
          },
          "action-REMOVE_OVERTAKEN": function($event) {
            return _vm.confirmRemoveAll("removeOvertaken")
          },
          "action-RESET_OVERTAKEN": function($event) {
            return _vm.confirmRemoveAll("resetOvertaken")
          }
        }
      }),
      _c("div", { staticClass: "box__container" }, [
        _c("div", { staticClass: "box__title" }, [
          _vm._v("Limits Depotpositionen")
        ]),
        _c("div", { staticClass: "mb-4" }, [
          _c("div", [
            _c("span", [_vm._v("Hier erhalten Sie eine Übersicht über alle")]),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.isCustomerView
                    ? " Limits"
                    : ", in den Depots Ihrer Kunden, gesetzten Limits"
                )
              )
            ]),
            _vm._m(0)
          ]),
          _c("div", [
            _vm._v(
              'Jedes einzelne Limit können Sie über die "Limits bearbeiten"-Aktion ändern. Ein rotes Ausrufezeichen vor den Fonds bedeutet, dass ein gesetztes Limit erreicht, aber noch nicht neu definiert wurde.'
            )
          ])
        ]),
        _c(
          "div",
          {
            staticClass:
              "layout__negative-margin--8 antrag-action-button__container"
          },
          [
            !_vm.isCustomerView
              ? _c("InputToggleSwitch", {
                  attrs: {
                    label: "Limits der Untermakler anzeigen",
                    inLineLabel: "",
                    suppressValidationMessage: ""
                  },
                  on: {
                    input: function($event) {
                      return _vm.reload()
                    }
                  },
                  model: {
                    value: _vm.includeStruktur,
                    callback: function($$v) {
                      _vm.includeStruktur = $$v
                    },
                    expression: "includeStruktur"
                  }
                })
              : _vm._e()
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          !_vm.loading && _vm.rows && _vm.rows.length
            ? _c(
                "div",
                [
                  _c("Table", {
                    attrs: {
                      tableId: "1114085c-6fc5-4470-a6e6-1f0b4a1ff658",
                      title: _vm.TABLE_TITLE,
                      headers: _vm.headers,
                      rows: _vm.rows,
                      rowsPerPage: 20,
                      mobileConfig: {
                        title: "wertpapierName",
                        headers: [
                          "ausgangskurs",
                          "aktuellKurs",
                          "unteresLimit",
                          "oberesLimit"
                        ]
                      },
                      rowId: "rowId"
                    },
                    on: {
                      "action-edit": _vm.editLimit,
                      "action-delete": _vm.removeLimit,
                      "action-factsheet": function($event) {
                        return _vm.viewDocument($event, "FACTSHEET")
                      },
                      "action-kag": function($event) {
                        return _vm.viewDocument($event, "MR")
                      },
                      "action-kid": function($event) {
                        return _vm.viewDocument($event, "KIID")
                      },
                      "click-kundenLinkName": _vm.openCustomerNewTab
                    }
                  })
                ],
                1
              )
            : _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", title: _vm.TABLE_TITLE }
              })
            : _c("NoData", { attrs: { title: _vm.TABLE_TITLE } })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "confirmDelete",
          attrs: { labelButtonConfirm: "Ja", labelButtonCancel: "Nein" },
          on: {
            onConfirmButton: function($event) {
              return _vm.removeAllLimits(true)
            },
            onCancelButton: function($event) {
              return _vm.removeAllLimits(false)
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-warning", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v(" Löschen ")
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", [
            _vm._v(
              " Wollen Sie wirklich alle " +
                _vm._s(_vm.confirmDeleteText) +
                "? "
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _vm._v(" für "),
      _c("b", [_vm._v("Einzelfonds")]),
      _vm._v(".")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }