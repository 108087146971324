<template>
  <div>
    <BaseButton @click="openZuordnenModal()" v-if="!disabled && selectedRows && selectedRows.length && rows.length" :disabled="disabled || antragLoading" primary>Markierte Zuordnen</BaseButton>

    <div class="row mb-3">
      <div class="col text-bold">
        <span :class="{'color-danger': zeilenlabels.zeilendescription.includes('Positionen ohne Zuordnung')}"> {{ zeilenlabels.zeilendescription }} </span>
        <span v-bind:style="zeilenlabels.zeilendescriptionCheckFehler && zeilenlabels.zeilendescriptionCheckFehler.isEmpty ? { color: 'var(--color-danger)'} : {}"> {{ zeilenlabels.zeilendescriptionPre }} </span>
        <span v-bind:style="zeilenlabels.zeilendescriptionCheckFehler && zeilenlabels.zeilendescriptionCheckFehler.einmalFehler ? { color: 'var(--color-danger)'} : {}"> {{ zeilenlabels.zeilendescriptionEinmal }} </span>
        <span> {{ zeilenlabels.zeilendescriptionIsAnother }} </span>
        <span v-bind:style="zeilenlabels.zeilendescriptionCheckFehler && zeilenlabels.zeilendescriptionCheckFehler.ratierlichFehler ? { color: 'var(--color-danger)'} : {}"> {{ zeilenlabels.zeilendescriptionRatierlich }} </span>
      </div>
      <div
        class="col-12"
        v-if="zeilenlabels.zeilenzeithorizont"
      >
        {{ zeilenlabels.zeilenzeithorizont }} |
        {{ zeilenlabels.zeilenanlageziel }} |
        {{ zeilenlabels.zeilenanlagezielspeziell }} |
        {{ zeilenlabels.zeilenverlusttf }}
      </div>
    </div>

    <Table
      v-if="rows.length"
      rowId="index"
      :rows="rows"
      :headers="headers"
      @action="handleTableAction($event)"
      v-model="selectedRows"
    >

      <template v-slot:anlageziel="row">
        <div v-if="row.anlagezielList && row.anlagezielList.length">
          <div v-for="yam in row.anlagezielList" :key="yam" 
            :class="{'pill__status color-danger': row.zmStatus.azFehler, 'pill__status color-success': row.zmStatus.azSuccess}">
            {{ yam || '' }}
          </div>
        </div>
      </template>

      <template v-slot:anlagezielspeziell="row">
        <div v-if="row.anlagezielspez && row.anlagezielspez.length">
          <div v-for="yam in row.anlagezielspez" :key="yam" 
            :class="{'pill__status color-danger': row.zmStatus.azsFehler, 'pill__status color-success': row.zmStatus.azsSuccess}">
            {{ yam || ''}}
          </div>
        </div>
      </template>
    </Table>
    <NoData content="Keine Positionen" v-else noIcon />

    <BaseModal
    ref="zuordnenModal"
    modalTitle="Zuordnen"
    :showDefaultButtons="false"
    size="lg">
      <p>Bitte weisen Sie die markierten Positionen einer Zeile zu.</p>

    <InputRadioBoxGroup
      v-model="form.selectedZeile"
      :values="zeileOptions"/>

      <BaseButton :disabled="!form.selectedZeile" @click="movetoSelectedLine()">Zuordnen</BaseButton>
      <BaseButton isSecondary @click="$refs.zuordnenModal.close();">Abbrechnen</BaseButton>

  </BaseModal> 
  </div>
</template>

<script>
import ANTRAG_TYPES from "@/store/antrag/types";
import {
  PhNumberSquareOne,
  PhNumberSquareTwo,
  PhNumberSquareThree,
  PhNumberSquareFour,
  PhNumberSquareFive,
  PhArrowsLeftRight,
  PhNumberSquareZero,
  PhX,
  PhCheck,
} from "phosphor-vue";
import BaseButton from "@/components/core/BaseButton.vue";
import Table from "@/components/table2/Table.vue";
import {TextColumn, SlotColumn, PillColumn, ActionColumn, SimpleAction, HtmlAction} from "@/components/table2/table_util.js";
import BaseModal from '@/components/core/BaseModal.vue';
import InputRadioBoxGroup from "@/components/core/forms/radiobox/InputRadioBoxGroup.vue";
import NoData from '@/components/core/NoData.vue';
import { mapGetters } from "vuex";

export default {
  components: {
    Table,
    BaseButton,
    BaseModal,
    InputRadioBoxGroup,
    NoData,
  },
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
      required: true,
    },
    antragId: {
      type: String,
    },
    antragName: {
      type: String,
    },
    config: {
      type: Object,
      required: true,
    },
    antragData: {},
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      selectedRows: [],
      form: {
        selectedZeile: null,
      }
    };
  },
  emits: ["change"],
  mounted() {
    if (this.disabled) {
      this.selectedRows = null;
    }
  },
  computed: {
    ...mapGetters({
      antragLoading: ANTRAG_TYPES.GETTERS.SAVE_ANTRAG_STATE
    }),
    zeilenlabels() {
      if(this.config?.tabledata?.tabellabels && this.id) {
        return this.config.tabledata.tabellabels[this.id] || '';
      }
      return "";
    },
    zeilen() {
      return this.config?.tabledata?.zeilen || [];
    },
    tableRecords() {
      return this.config?.tabledata?.records || [];
    },
    headers() {
      return {
        lockedLeft: [
            TextColumn("fondsname", "Position").addCellProps({lineClamp: 4}),
            TextColumn("fondsart", "Art"),
            TextColumn("betrag", "Betrag"),
            PillColumn("anlagehorizont", "Anlagehorizont").addCellProps({lineClamp: 2}),
            SlotColumn("anlageziel", "Anlageziel"),
            SlotColumn("anlagezielspeziell", "Anlageziel speziell"),
            PillColumn("verlusttragfaehigkeit", "Verlusttragfähigkeit"),
            PillColumn("status", "Status"),
        ],
        center: [],
        lockedRight: [
            ActionColumn("actions", "Aktionen"),
        ],
      };
    },
    rows() {
      return this.tableRecords.map((record, index) => {
          return {
              ...record,
              index,
              actions: this.makeActions(this.id, record),
              status: {
                label: record?.zmStatus?.hatFehler ? 'fehlerhaft' : 'fehlerfrei',
                type: record?.zmStatus?.hatFehler ? 'danger' : 'success',
              },
              anlagehorizont: {
                label: record?.anlagehorizont,
                type: record?.zmStatus?.zhFehler ? 'danger' : record?.zmStatus?.zhSuccess ? 'success' : 'text',
              },
              verlusttragfaehigkeit: {
                label: record?.verlusttragfaehigkeit,
                type: record?.zmStatus?.vltFehler ? 'danger' : record?.zmStatus?.vltSuccess ? 'success' : 'text',
              },
          }
      })
    },
    zeileOptions() {
      if (this.rows && this.rows.length) {
        return this.rows[0].actions.map( a => {
          return {
            key: a.actionKey,
            value: a.actionKey,
            label: a.label,
          }
        }).filter((f) => ['withoutZeile', 'ZEILE1','ZEILE2','ZEILE3', 'ZEILE4', 'ZEILE5', 'WK1', 'WK2', 'WK3'].includes(f.key));
      }
    },
  },
  methods: {
    handleTableAction(action) {  
      if (this.disabled) {
        return;
      }   
      if (action?.row) { 
        const valueChanges = {
          INTERNAL_ATTRIBUTE_ACTION: action.row.componentidcombozusatz
        }

        if (action.key === "kundemoechte") {
          valueChanges[action.row.componentidswitcher] = "1";
        } else if (action.key === "kundemoechtenicht") {
          valueChanges[action.row.componentidswitcher] = "0";
        } else {
          const zeile = this.zeilen.find(target => action.key === target)

          if (zeile) {
              valueChanges[action.row.componentidcombo] = zeile === "withoutZeile" ? "" : zeile;
              valueChanges["UPDATE_FROM_SMARTMSC"] = "Ja";
          } else {
            return
          }
        }

        this.$emit("change", valueChanges);
        this.handleSave();
      }
    },
    makeActions(zeile, element = {}) {
      const actions = [];
      if (this.disabled) {
        return actions;
      }
      if (element.kundemoechte === "0") {
        actions.push(SimpleAction('kundemoechte', PhCheck, 'Kunde möchte halten'))
      } else if (element.kundemoechte === "1") {
        actions.push(SimpleAction('kundemoechtenicht', PhX, 'Kunde möchte NICHT halten'))
      }

      this.zeilen.forEach((zeileAktuell) => {
        if (zeile != zeileAktuell) {
          actions.push(HtmlAction(zeileAktuell, this.getIconForZeile(zeileAktuell), this.getActionLabelsForZeile(element, zeileAktuell)))
        }
      });
      return actions;
    },
    getIconForZeile(zeileAktuell) {
      switch (zeileAktuell) {
        case "withoutZeile":
          return PhNumberSquareZero;
        case "ZEILE1":
          return PhNumberSquareOne;
        case "ZEILE2":
          return PhNumberSquareTwo;
        case "ZEILE3":
          return PhNumberSquareThree;
        case "ZEILE4":
          return PhNumberSquareFour;
        case "ZEILE5":
          return PhNumberSquareFive;
        case "WK1":
          return PhNumberSquareOne;
        case "WK2":
          return PhNumberSquareTwo;
        default:
          return PhArrowsLeftRight;
      }
    },
    getActionLabelsForZeile(element = {}, zeile) {
      if (element.comboLabels && element.comboLabels[zeile]) {
        return element.comboLabels[zeile].replaceAll('[c=RED]','<span class="color-danger">').replaceAll('[/c]','</span>');
      }
      return "nicht zuordnen";
    },
    onChangeInputField(value, fieldId) {
      this.$emit("change", {
        [fieldId]: value,
      });
    },
    handleSave() {
      this.saveAntrag().then(this.reloadAntrag);
    },
    saveAntrag() {
      return this.$store.dispatch(ANTRAG_TYPES.ACTIONS.SAVE_ANTRAG, {
        id: this.antragId,
        lagerstelle: this.$route.params.lagerstelle,
      });
    },
    reloadAntrag() {
      return this.$store.dispatch(ANTRAG_TYPES.ACTIONS.RELOAD_ANTRAG, {
        id: this.antragId,
      });
    },

    movetoSelectedLine() {
      this.$refs.zuordnenModal.close();

      if (this.selectedRows?.length && this.form?.selectedZeile) {
        const valueChanges = {};
        const changedPositions = [];
        this.selectedRows.forEach(row => {
          valueChanges[row.componentidcombo] = this.form.selectedZeile === "withoutZeile" ? "" : this.form.selectedZeile;
          changedPositions.push(row.componentidcombozusatz);
        });
        valueChanges.UPDATE_FROM_SMARTMSC = 'Ja';
        valueChanges.ZIELMARKTDATEN_ACTIONS = changedPositions;
        this.$emit("change", valueChanges);

        this.handleSave()
      }
    },
    openZuordnenModal() {
      this.$refs.zuordnenModal.open()
    }

  },
};
</script>

<style scoped>

.pill__status {
  border-radius: 4px;
  padding: 0px 4px;
  white-space: nowrap;
  font-size: 0.8rem;
}
</style>
