<template>
<div>
	<div class="">
		<div v-if="loading" class="box__container text-centered">
			<AnimatedSpinner/>
		</div>
		<NoData class="box__container" v-else-if="!depot.length && !empfehlung.length" />
		<div v-else>
			<div v-if="depot.length"  class="box__container">
				<div class="box__title">Bestandsdepot</div>
				<div class="row">
					<div v-for="(chart) in depot" :key="chart.name" class="col-auto chart-container">
						<template v-if="chart.type == 'radialMultiple'">
							<div class="box__title">{{chart.titel}}</div>
							<div class="charts-composite">
								<GaugeChartMultiple
									:chartData="multipleData(chart.data)"
									:customColors="pieColors(chart.name)"
									format="percent"
									height="250px" 
									:isTooltip="false"
								/>
								<div>
									<div v-for="(item,k) in chart.data" :key="k">
										<PhCircle size="16" weight="fill" :color="pieSerieColor(chart, k)"  />
              			<span>{{proz(item.value)}} {{item.name}}</span>
									</div>
								</div>
							</div>
						</template>
						<template v-else-if="chart.type == 'bar'">
							<div class="box__title">{{chart.titel}}</div>
							<BarHChart
								:chartData="barHData(chart.data)"
								format="percent"
								height="300px" 
								:isTooltip="false"
							/>
						</template>
						<template v-else-if="chart.type == 'pie'">
							<div class="box__title">{{chart.titel}}</div>
							<div class="charts-composite">
								<PieChart
									:chartData="chart.data" 
									:customColors="pieColors(chart.name)"
									format="percent"
									height="250px" 
								/>
								<div>
									<div v-for="(item,k) in chart.data" :key="k">
										<PhCircle size="16" weight="fill" :color="pieSerieColor(chart, k)"  />
              			<span>{{proz(item.value)}} {{item.name}}</span>
									</div>
								</div>
							</div>
						</template>

					</div>
				</div>
			</div>
			<div v-if="empfehlung.length"  class="box__container">
				<div class="box__title">Depotvorschlag</div>
				<div class="row">
					<div v-for="(chart) in empfehlung" :key="chart.name"  class="col-auto chart-container">
						<template v-if="chart.type == 'radialMultiple'">
							<div class="box__title">{{chart.titel}}</div>
							<div class="charts-composite">
								<GaugeChartMultiple
									:chartData="multipleData(chart.data)"
									:customColors="pieColors(chart.name)"
									format="percent"
									height="250px" 
									:isTooltip="false"
								/>
								<div>
									<div v-for="(item,k) in chart.data" :key="k">
										<PhCircle size="16" weight="fill" :color="pieSerieColor(chart, k)"  />
              			<span>{{proz(item.value)}} {{item.name}}</span>
									</div>
								</div>
							</div>
						</template>
						<template v-else-if="chart.type == 'bar'">
							<div class="box__title">{{chart.titel}}</div>
							<BarHChart
								:chartData="barHData(chart.data)"
								format="percent"
								height="300px" 
								:isTooltip="false"
							/>
						</template>
						<template v-else-if="chart.type == 'pie'">
							<div class="box__title">{{chart.titel}}</div>
							<div class="charts-composite">
								<PieChart
									:chartData="chart.data" 
									:customColors="pieColors(chart.name)"
									format="percent"
									height="250px" 
								/>
								<div>
									<div v-for="(item,k) in chart.data" :key="k">
										<PhCircle size="16" weight="fill" :color="pieSerieColor(chart, k)"  />
              			<span>{{proz(item.value)}} {{item.name}}</span>
									</div>
								</div>
							</div>
						</template>

					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import INVESTMENT_ADVICE from "@/store/investmentAdvice/types";
import { mapGetters } from "vuex";
import BarHChart from "@/components/charts/echarts/BarHChart.vue";
import PieChart from '@/components/charts/echarts/PieChart.vue';
import GaugeChartMultiple from '@/components/charts/echarts/GaugeChartMultiple.vue';
import NoData from "@/components/core/NoData.vue";
import AnimatedSpinner from "@/components/core/AnimatedSpinner.vue";
import { PhCircle } from 'phosphor-vue';
import { formatNumber } from '@/helpers/number-formatter.js';

export default {
	components:{
		BarHChart, PieChart, GaugeChartMultiple,
		NoData, PhCircle,
		AnimatedSpinner,
	},
	props: {
		adviceId: {
			type: Number,
			default: null,
		},
	},
	data() {
		return {
			loading: false,
		}
	},
  
	computed: {
	...mapGetters({
			diagramme: INVESTMENT_ADVICE.GETTERS.DIAGRAMME,
		}), 
		depot() {
			return this.diagramme?.depot ? this.diagramme.depot : [];
		},
		empfehlung() {
			return this.diagramme?.empfehlung ? this.diagramme.empfehlung : [];
		},         
	},
	mounted() {
		if (!this.diagramme?.pieColors) {
			this.loading = true;
		}
		this.$store.dispatch(INVESTMENT_ADVICE.ACTIONS.FETCH_DIAGRAMME, {adviceId: this.adviceId});
	},

	methods:{
		pieColors(name) {
			if (this.diagramme?.pieColors) {
				if (name == 'SRI') {
					return null;
				}
				// if (name == 'ESGTAXONOMIE' || name == 'ESGSFDR') {
				// 	return [this.diagramme.pieColors[0], 'rgb(192,192,192)', 'rgb(160,160,160)'];
				// }
				return this.diagramme.pieColors
			}
		},
		pieSerieColor(chart, idx) {
			if (this.diagramme?.pieColors) {
				if (chart.name == 'SRI') {
					return chart.data[idx].itemStyle?.color ? chart.data[idx].itemStyle.color : this.diagramme.pieColors[idx];
				}
				// if (chart.name == 'ESGTAXONOMIE' || chart.name == 'ESGSFDR') {
				// 	return [this.diagramme.pieColors[0], 'rgb(192,192,192)', 'rgb(160,160,160)'][idx];
				// }
				return this.diagramme.pieColors[idx];
			}
		},
		barHData(data) {
			for (let k = 0; k < data.length; k++) {
				if (data[k].name == 'Sonstige') {
					data[k].itemStyle = { color: 'rgb(192,192,192)'}
				} else {
					data[k].itemStyle = { color: this.diagramme.barColors[k]}
				}
			}
			return data;
		},
		multipleData(data) {
			for (let k = 0; k < data.length; k++) {
				data[k].itemStyle = { color: this.diagramme.pieColors[k]}
			}
			return data;
		},
		proz(value) {
			return formatNumber(value, 2) + ' %'
		}
	},

	watch: {
		diagramme() {
			this.loading = this.diagramme?.pieColors == undefined;
		},
	},

}
</script>

<style>
.chart-container{
  width: 400px;
	padding: 15px;
}

</style>