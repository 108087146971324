var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "day-calendar__container" },
    [
      _vm.compgetWeekCalendarDataReady
        ? _c("div", [
            _c("div", { staticClass: "daycalendarapp daycontainer" }, [
              _vm.dayElementsReady
                ? _c("div", { staticClass: "d-flex justify-content-between" }, [
                    _c("div", { staticClass: "w-100" }, [
                      _c(
                        "div",
                        {
                          staticClass: "text-centered mb-2",
                          class: { "text-bold": _vm.isCurrentLine }
                        },
                        [_vm._v(_vm._s(_vm.header.label))]
                      ),
                      _c(
                        "div",
                        { staticClass: "event-column d-block d-md-none" },
                        _vm._l(_vm.weeks, function(week, i) {
                          return _c("div", { key: "w2" + i }, [
                            _c(
                              "div",
                              { staticClass: "d-flex justify-content-between" },
                              _vm._l(week, function(day, di) {
                                return _c(
                                  "div",
                                  {
                                    key: i + "d2" + di,
                                    class: {
                                      "week-day": true,
                                      "week-day-active": _vm.isActiveDay(
                                        day.day
                                      ),
                                      "week-day-current": _vm.isToday(day)
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.moveSelectedDay(day.day)
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            class: {
                                              "not-in-month": !day.inMonth
                                            }
                                          },
                                          [_vm._v(_vm._s(day.label))]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          ])
                        }),
                        0
                      ),
                      _c(
                        "div",
                        { staticClass: "daygrid wholeday-appointments" },
                        [
                          _vm._m(0),
                          _c("CalendarWeekDayColumn", {
                            staticClass: "daycolumn",
                            attrs: {
                              isAllDay: true,
                              istoday: _vm.getDayElements.day.isToday,
                              calledfrom: "day",
                              inmonth: _vm.getDayElements.day.inMonth,
                              dindex: _vm.getDayElements.dayIndex,
                              daylabel: _vm.getDayElements.day.label,
                              hours: _vm.getShownHours,
                              date: _vm.getDayElements.day.day
                            },
                            on: { editEvent: _vm.editEvent },
                            nativeOn: {
                              click: function($event) {
                                return _vm.setEvents.apply(null, arguments)
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          ref: "dayGridHoursEl",
                          staticClass: "daygrid daygrid-hours",
                          style: { maxHeight: _vm.dayGridHoursHeight + "px" },
                          attrs: { "data-scroll-container": "" }
                        },
                        [
                          _vm.isCurrentLine
                            ? _c("div", {
                                staticClass: "redline",
                                style: _vm.currentLineStyle
                              })
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-time-column d-none d-md-block clearfix"
                            },
                            _vm._l(_vm.getShownHours, function(wholehour) {
                              return _c(
                                "div",
                                {
                                  key: wholehour,
                                  staticClass: "wholehour",
                                  style: _vm.setStyle
                                },
                                [_vm._v(_vm._s(wholehour))]
                              )
                            }),
                            0
                          ),
                          _c("CalendarWeekDayColumn", {
                            staticClass: "daycolumn",
                            attrs: {
                              isAllDay: false,
                              istoday: _vm.getDayElements.day.isToday,
                              calledfrom: "day",
                              inmonth: _vm.getDayElements.day.inMonth,
                              dindex: _vm.getDayElements.dayIndex,
                              daylabel: _vm.getDayElements.day.label,
                              hours: _vm.getShownHours,
                              date: _vm.getDayElements.day.day
                            },
                            on: { editEvent: _vm.editEvent },
                            nativeOn: {
                              click: function($event) {
                                return _vm.setEvents.apply(null, arguments)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                : _vm._e()
            ])
          ])
        : _c("CalendarGhostLoading", {
            staticClass: "d-lg-none",
            attrs: { type: "calendar" }
          }),
      !_vm.compgetWeekCalendarDataReady
        ? _c("CalendarGhostLoading", {
            staticClass: "d-lg-none",
            attrs: { type: "day" }
          })
        : _vm._e(),
      !_vm.compgetWeekCalendarDataReady
        ? _c("CalendarGhostLoading", {
            staticClass: "d-none d-md-block",
            style: {
              maxHeight: _vm.containerHeight + "px",
              overflow: "hidden"
            },
            attrs: { type: "day" }
          })
        : _vm._e(),
      _vm.compgetWeekCalendarDataReady
        ? _c(
            "div",
            { ref: "dayGridSmEl", staticClass: "day-grid-sm d-lg-none" },
            [
              _c("div", { staticClass: "daycalendarapp daycontainer" }, [
                _vm.dayElementsReady
                  ? _c(
                      "div",
                      { staticClass: "d-flex justify-content-between" },
                      [
                        _c("div", { staticClass: "w-100" }, [
                          _c(
                            "div",
                            [
                              _vm._l(_vm.allDayEvents, function(ev, i) {
                                return _c(
                                  "div",
                                  {
                                    key: "all" + i,
                                    class: ["event-mob", "type-" + ev.type],
                                    style: { "border-color": ev.color },
                                    on: {
                                      click: function($event) {
                                        return _vm.editEventMobile(ev)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-between"
                                      },
                                      [
                                        _c(
                                          "div",
                                          [
                                            ev.type == "birthday"
                                              ? _c("ph-gift", {
                                                  attrs: { size: 16 }
                                                })
                                              : _vm._e(),
                                            _vm._v(_vm._s(ev.label))
                                          ],
                                          1
                                        ),
                                        _c("div", { staticClass: "time" }, [
                                          _vm._v("Ganztägig")
                                        ])
                                      ]
                                    )
                                  ]
                                )
                              }),
                              _vm._l(_vm.timedEvents, function(ev, i) {
                                return _c(
                                  "div",
                                  {
                                    key: "time" + i,
                                    class: ["event-mob", "type-" + ev.type],
                                    style: { "border-color": ev.color },
                                    on: {
                                      click: function($event) {
                                        return _vm.editEventMobile(ev)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-between"
                                      },
                                      [
                                        _c("div", [
                                          _c("b", [_vm._v(_vm._s(ev.label))])
                                        ]),
                                        _c("div", { staticClass: "time" }, [
                                          _vm._v(_vm._s(ev.startTime))
                                        ])
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-between"
                                      },
                                      [
                                        _c("div", [
                                          _vm._v(_vm._s(ev.bereichDescription))
                                        ]),
                                        _c("div", { staticClass: "time" }, [
                                          _vm._v(_vm._s(ev.endTime))
                                        ])
                                      ]
                                    )
                                  ]
                                )
                              })
                            ],
                            2
                          )
                        ])
                      ]
                    )
                  : _vm._e()
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "d-time-column d-none d-md-block clearfix" },
      [_c("div", { staticClass: "d-wholedaytitle" }, [_vm._v("Ganztägig")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }