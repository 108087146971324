var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: "Sipgate", actions: _vm.headerActions },
        on: {
          "action-ADD": function($event) {
            return _vm.addAccount()
          }
        }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          !_vm.loading &&
          _vm.records &&
          _vm.records.length &&
          _vm.records.length > 0
            ? _c("Table", {
                attrs: {
                  tableId: "15b90920-ebdd-11ec-8ea0-0242ac120002",
                  title: _vm.TABLE_TITLE,
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowsPerPage: 1000
                },
                on: { "action-DELETE": _vm.openDeleteDocumentConfirmModal }
              })
            : _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", title: _vm.TABLE_TITLE }
              })
            : _c("NoData", { attrs: { title: _vm.TABLE_TITLE } })
        ],
        1
      ),
      _c("BaseModal", {
        ref: "deleteDocumentConfirmModal",
        attrs: { modalTitle: "Löschen", labelButtonConfirm: "Löschen" },
        on: {
          onCloseButton: function($event) {
            return _vm.cancelDeleteDocument()
          },
          onCancelButton: function($event) {
            return _vm.cancelDeleteDocument()
          },
          onEscButton: function($event) {
            return _vm.cancelDeleteDocument()
          },
          onConfirmButton: function($event) {
            return _vm.deleteDocument()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [_vm._v(" Wollen Sie Sipgate Account löschen? ")]
            },
            proxy: true
          }
        ])
      }),
      _c("BaseModal", {
        ref: "insertAccountModal",
        attrs: { modalTitle: "Hinzufügen", labelButtonConfirm: "Hinzufügen" },
        on: {
          onCloseButton: _vm.cancelInsert,
          onConfirmButton: _vm.insertAccount
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c("InputField", {
                  attrs: { label: "E-MAIL" },
                  model: {
                    value: _vm.account.email,
                    callback: function($$v) {
                      _vm.$set(_vm.account, "email", $$v)
                    },
                    expression: "account.email"
                  }
                }),
                _c("InputField", {
                  attrs: { label: "Personal Access TOKEN_ID" },
                  model: {
                    value: _vm.account.personalAccessId,
                    callback: function($$v) {
                      _vm.$set(_vm.account, "personalAccessId", $$v)
                    },
                    expression: "account.personalAccessId"
                  }
                }),
                _c("InputField", {
                  attrs: { label: "Personal Access TOKEN" },
                  model: {
                    value: _vm.account.personalAccesToken,
                    callback: function($$v) {
                      _vm.$set(_vm.account, "personalAccesToken", $$v)
                    },
                    expression: "account.personalAccesToken"
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }