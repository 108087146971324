var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: {
        labelButtonConfirm: "Speichern",
        labelButtonCancel: "Abbrechen"
      },
      on: {
        onConfirmButton: _vm.save,
        onCancelButton: _vm.cancel,
        onCloseButton: _vm.cancel
      },
      scopedSlots: _vm._u([
        {
          key: "modalTitle",
          fn: function() {
            return [_vm._v(" Medieneinstellungen ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        [
          _c(
            "label",
            { staticStyle: { width: "100%" } },
            [
              _c("ph-video-camera", { attrs: { size: 24 } }),
              _vm._v(" Bevorzugte Kamera "),
              _vm.cameraList.length <= 1
                ? _c("span", [_vm._v("(Keine Kamera gefunden)")])
                : _vm._e(),
              _c("Combobox", {
                attrs: {
                  value: _vm.previewCamera,
                  values: _vm.cameraList,
                  disabled: _vm.cameraList.length <= 1
                },
                on: {
                  change: function($event) {
                    return _vm.updateCameraPreview($event)
                  }
                }
              })
            ],
            1
          ),
          _c("br"),
          _vm.cameraList.length > 1
            ? _c("div", { staticClass: "video-preview" }, [
                _c("video", {
                  attrs: {
                    width: "200px",
                    height: "150px",
                    autoplay: "",
                    playsinline: ""
                  },
                  domProps: { srcObject: _vm.cameraStream }
                })
              ])
            : _vm._e(),
          _c("hr"),
          _c(
            "label",
            { staticStyle: { width: "100%" } },
            [
              _c("ph-microphone", { attrs: { size: 24 } }),
              _vm._v(" Bevorzugtes Mikrofon "),
              _vm.microphoneList.length <= 1
                ? _c("span", [_vm._v("(Kein Mikrofon gefunden)")])
                : _vm._e(),
              _c("Combobox", {
                attrs: {
                  value: _vm.previewMicrophone,
                  values: _vm.microphoneList,
                  disabled: _vm.microphoneList.length <= 1
                },
                on: {
                  change: function($event) {
                    return _vm.updateMicrophonePreview($event)
                  }
                }
              })
            ],
            1
          ),
          _c("br"),
          _c("div", { staticClass: "audio-preview" }, [
            _c("div", { style: { width: _vm.maxVolume + "%" } })
          ]),
          _c("audio", { ref: "mediaPreviewAudio" }, [
            _c("source", {
              attrs: {
                src:
                  _vm.apiAddress + "/../../ksc/assets/sinus_425Hz_1000ms.mp3",
                type: "audio/mp3"
              }
            })
          ]),
          _vm.setSinkIdMethodExists
            ? [
                _c("hr"),
                _c(
                  "label",
                  { staticStyle: { width: "100%" } },
                  [
                    _c("ph-speaker-high", { attrs: { size: 24 } }),
                    _vm._v(" Bevorzugte Audioausgabe "),
                    _vm.speakerList.length <= 1
                      ? _c("span", [_vm._v("(Kein Ausgabegerät gefunden)")])
                      : _vm._e(),
                    _c("Combobox", {
                      attrs: {
                        values: _vm.speakerList,
                        disabled: _vm.speakerList.length <= 1
                      },
                      model: {
                        value: _vm.previewSpeaker,
                        callback: function($$v) {
                          _vm.previewSpeaker = $$v
                        },
                        expression: "previewSpeaker"
                      }
                    })
                  ],
                  1
                ),
                _c("br"),
                _c(
                  "BaseButton",
                  {
                    attrs: { isSecondary: "" },
                    on: {
                      click: function($event) {
                        return _vm.playSound()
                      }
                    }
                  },
                  [_vm._v(" Prüfton abspielen ")]
                )
              ]
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }