var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.currentPendingMarketingCampaign
      ? _c("div", [
          _c("div", { staticClass: "box__container" }, [
            _c("div", { staticClass: "box__title" }, [
              _vm._v(
                " " + _vm._s(_vm.currentPendingMarketingCampaign.title) + " "
              )
            ]),
            _c("div", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.sanitize(_vm.currentPendingMarketingCampaign.body)
                )
              }
            })
          ]),
          _c("div", { staticClass: "box__container" }, [
            _c("div", [
              _c(
                "div",
                { staticClass: "layout__negative-margin--8" },
                [
                  _c(
                    "BaseButton",
                    {
                      attrs: { isSecondary: "" },
                      on: {
                        click: function($event) {
                          return _vm.postponeCampaign()
                        }
                      }
                    },
                    [_vm._v(" Abbrechen ")]
                  ),
                  _c(
                    "BaseButton",
                    {
                      on: {
                        click: function($event) {
                          return _vm.acknowledgeCampaign()
                        }
                      }
                    },
                    [_vm._v(" Bestätigen ")]
                  )
                ],
                1
              )
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }