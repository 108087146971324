var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "logged-user__container" }, [
    _c(
      "div",
      { staticClass: "d-flex" },
      [
        _vm.isBypSession
          ? [
              _vm.isLoggedUserAvailable
                ? _c(
                    "div",
                    {
                      staticClass: "logged-user--extra-info mx-0",
                      class: { clickable: _vm.isLoggedUserClickable },
                      on: { click: _vm.openLoggedUserInNewTab }
                    },
                    [
                      _c("span", [_vm._v(_vm._s(_vm.loginUserid))]),
                      _c("span", { staticClass: "mx-2" }, [_vm._v(">")])
                    ]
                  )
                : _vm._e(),
              _vm.hasMaklerInfo
                ? _c(
                    "div",
                    {
                      staticClass: "logged-user--extra-info mx-0",
                      class: { clickable: _vm.isBrokerClickable },
                      on: { click: _vm.openBrokerInNewTab }
                    },
                    [
                      _c("span", { staticClass: "logged-user--name" }, [
                        _vm._v(_vm._s(_vm.maklerName))
                      ]),
                      _vm.maklerName && _vm.maklernr
                        ? _c(
                            "span",
                            { staticClass: "logged-user--divider mx-2" },
                            [_vm._v("|")]
                          )
                        : _vm._e(),
                      _c("span", [_vm._v(_vm._s(_vm.maklernr))]),
                      _vm.maklerName || _vm.maklernr
                        ? _c("span", { staticClass: "mx-2" }, [_vm._v(" > ")])
                        : _vm._e()
                    ]
                  )
                : _vm._e()
            ]
          : _vm.ssoLoginName
          ? _c("div", { staticClass: "logged-user--extra-info" }, [
              _c("span", [_vm._v(_vm._s(_vm.ssoLoginName))]),
              _c("span", { staticClass: "mx-2" }, [_vm._v(">")])
            ])
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "logged-user--main-info",
            class: { clickable: _vm.isCustomerAsBypassClickable },
            on: { click: _vm.openCustomerInNewTab }
          },
          [
            _c("span", { staticClass: "logged-user--name" }, [
              _vm._v(_vm._s(_vm.fullname))
            ]),
            _vm.loginData.depotInhaber
              ? _vm._l(_vm.loginData.depotInhaber, function(di) {
                  return _c(
                    "span",
                    { key: di, staticClass: "logged-user--name" },
                    [_vm._v(" & " + _vm._s(di))]
                  )
                })
              : _vm._e(),
            _c("span", { staticClass: "logged-user--divider mx-2" }, [
              _vm._v("|")
            ]),
            _c("span", [_vm._v(_vm._s(_vm.userIdUnterNr))])
          ],
          2
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }