import WERTPAPIERORDER_TYPES from './types';
import ANTRAG_TYPES from '@/store/antrag/types';
import TAN_TYPES from '@/store/tan/types';
import INVESTMENT_ADVICE from "@/store/investmentAdvice/types";
import CORE_TYPES from '@/store/core/types'
import axios from 'axios';
import LOG_TYPES from '@/store/log/types'
import { buildMessage } from '../../helpers/log-message-helper';

const config = {
  defaultSpinner: true
};

export default {
  [WERTPAPIERORDER_TYPES.ACTIONS.GET_WERTPAPIERORDER]({ dispatch, commit, rootState, state }, payload) {

    commit(WERTPAPIERORDER_TYPES.MUTATIONS.WERTPAPIERORDER_LOADING, true);
    const param = Object.entries(payload).map(([key, val]) => `${key}=${val}`).join('&');
    const path = '/../mrswertpapierorderv3' + (param ? `?${param}` : '');
    axios.get(`${rootState.core.apiAddress}${path}`, config).then(response => {
      commit(WERTPAPIERORDER_TYPES.MUTATIONS.WERTPAPIERORDER_LOADING, false);

      if (response.status === 200 && response.data) {
        const wertpapierorder = { ...response.data }
        wertpapierorder.data = { ...wertpapierorder.data, ...payload }
        if (response.data.data?.errorMessage) {
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.data?.errorMessage, 'danger'))
        }
        commit(WERTPAPIERORDER_TYPES.MUTATIONS.GET_WERTPAPIERORDER_SUCCESS, wertpapierorder)
        if (!state.depots?.length) {
          dispatch(WERTPAPIERORDER_TYPES.ACTIONS.GET_DEPOTS, wertpapierorder?.data?.lagerstelle);
        }
      }
    });
  },

  [WERTPAPIERORDER_TYPES.ACTIONS.SAVE_WERTPAPIERORDER]({ dispatch, commit, state, rootState}, payload) {
    return new Promise((resolve, reject) => {

      if (!state.data?.antragsdatenId || !state.data?.antragsnrIntern || state.data?.dataHasChanged) {
        const data = {
          data: Object.assign({}, state.data || {}, payload || {}), 
          positions: state.positions?.map(pos => Object.assign(pos, { betrag: pos.betrag || 0 })) || null,
        };

        commit(ANTRAG_TYPES.MUTATIONS.START_SAVE_ANTRAG_STATE)
        if (!state.data) {
          state.data = {};
        }
        state.data.dataHasChanged = false;
        
         axios.post(`${rootState.core.apiAddress}/../mrswertpapierorderv3`, data, config).then(response => {
          if (response.status === 200 && response.data) {
            // reset investment advice data, if referred from there
            commit(INVESTMENT_ADVICE.MUTATIONS.UPDATE_INVESTMENT_ADVICE_ORDER_SUCCESS, payload?.lagerstelle || response.data?.data?.lagerstelle);
          
            if (response.data?.errorMessage) {
              dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data?.errorMessage, 'danger'))
              commit(WERTPAPIERORDER_TYPES.MUTATIONS.SAVE_WERTPAPIERORDER_FAILED, response.data)
            } else {
              commit(WERTPAPIERORDER_TYPES.MUTATIONS.SAVE_WERTPAPIERORDER_SUCCESS, response.data)
              commit(ANTRAG_TYPES.MUTATIONS.END_SAVE_ANTRAG_STATE)
            }
            resolve()
          }
        })
        .catch(() => {
          resolve()
        })
        .finally(() => {
          commit(ANTRAG_TYPES.MUTATIONS.END_SAVE_ANTRAG_STATE)
        });
      } else {
        resolve()
      }
    });
  },
  
  async [WERTPAPIERORDER_TYPES.ACTIONS.ADD_POSITIONS]({ dispatch, commit }, payload) {
      commit(WERTPAPIERORDER_TYPES.MUTATIONS.ADD_POSITIONS, payload);
      await dispatch(WERTPAPIERORDER_TYPES.ACTIONS.SAVE_WERTPAPIERORDER, {});
  },

  [WERTPAPIERORDER_TYPES.ACTIONS.SAVE_POSITION_DATA]({ commit, state, rootState }) {
    return new Promise((resolve) => {

      if (/*state.data?.antragsdatenId && */(state.positionData?.positionDataHasChanged || !state.data?.hatPDF)) {
        commit(ANTRAG_TYPES.MUTATIONS.START_SAVE_ANTRAG_STATE)
        
        const positionData = Object.assign({}, state.positionData || {});
        delete positionData['positionDataHasChanged']

        const data = Object.assign({}, state.data || {});

        const payload = {
          data: data,
          positionData: positionData,
          lagerstelle: state.data?.lagerstelle,
          positions: state.positions?.map(pos => Object.assign(pos, { betrag: pos.betrag || 0 })) || null,
        };
        state.data.dataHasChanged = false;
        state.positionData.positionDataHasChanged = false;
        axios.post(`${rootState.core.apiAddress}/../mrswertpapierorderupdatepositions`, payload, config).then(response => {
          if (response.status === 200 && response.data?.positions?.length) {
            commit(WERTPAPIERORDER_TYPES.MUTATIONS.SAVE_POSITION_DATA_SUCCESS, response.data)
            commit(TAN_TYPES.MUTATIONS.SET_COMDIRECT_TAN_REQUIRED, {value : response.data?.requiresComdirectLogin})
            // commit(ANTRAG_TYPES.MUTATIONS.END_SAVE_ANTRAG_STATE)
          }
          resolve();
        })
        .catch(() => {
          resolve()
        })
        .finally(() => {
          commit(ANTRAG_TYPES.MUTATIONS.END_SAVE_ANTRAG_STATE)
        });
      } else {
        resolve()
      }
    });
  },

  [WERTPAPIERORDER_TYPES.ACTIONS.REMOVE_POSITION]({ dispatch, commit, state, rootState }, position) {
    if (position?.posGrpId) {
      commit(ANTRAG_TYPES.MUTATIONS.START_ANTRAG_ACTION_LOADING, 'remove_position')
      commit(WERTPAPIERORDER_TYPES.MUTATIONS.REMOVE_UNSAVED_POSITION, position)
      const antragsnrIntern = state.data?.antragsnrIntern;
        axios.delete(`${rootState.core.apiAddress}/../mrswertpapierorderv3?posGrpId=${position.posGrpId}&antragsnrIntern=${antragsnrIntern}`, config)
        .then(response => 
          dispatch(WERTPAPIERORDER_TYPES.ACTIONS.SAVE_WERTPAPIERORDER, {}))
        .catch((error) => {
          dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "Die Position kann nicht gelöscht werden.", error});
        })
        .finally(() =>  {
          commit(ANTRAG_TYPES.MUTATIONS.STOP_ANTRAG_ACTION_LOADING, 'remove_position');
        });
    } else if (position?.isin) {
      commit(WERTPAPIERORDER_TYPES.MUTATIONS.REMOVE_UNSAVED_POSITION, position)
    }
  },

  async [WERTPAPIERORDER_TYPES.ACTIONS.REMOVE_ALL_VERKAUF_AND_TAUSCH_POSITIONS]({ commit, state, rootState }) {
      const toRemove = state.positions.filter(pos => pos.positionTyp === 'ALT_VERKAUF' || pos.positionTyp === 'ALT_TAUSCH');
        if (toRemove?.length) {
        const params = {
          posGrpIds: toRemove.map(pos => pos.posGrpId),
          antragsnrIntern: state.data?.antragsnrIntern,
        }
        commit(ANTRAG_TYPES.MUTATIONS.START_ANTRAG_ACTION_LOADING, 'remove_position')
        await axios.put(`${rootState.core.apiAddress}/../mrswertpapierorderv3`, params, config).then(response => {
          commit(WERTPAPIERORDER_TYPES.MUTATIONS.REMOVE_POSITION_SUCCESS, response.data)
          dispatch(WERTPAPIERORDER_TYPES.ACTIONS.SAVE_WERTPAPIERORDER, {})
        })
        .finally(() =>  {
          commit(ANTRAG_TYPES.MUTATIONS.STOP_ANTRAG_ACTION_LOADING, 'remove_position');
        });
      }
  },
  
  [WERTPAPIERORDER_TYPES.ACTIONS.GET_POSITION_DATA_COMPONENTS]({ commit, state, rootState }) {
    if (state.data?.lagerstelle) {
      const data = {
        positionsList: state.positions,
        lagerstelleId: state.data.lagerstelle,
        antragsdatenId: state.data.antragsdatenId || '',
        depotNr: state.data.depotNr || '',
      };
      axios.post(`${rootState.core.apiAddress}/../mrswertpapierordercomponents`, data)
        .then((response) => {
          commit(WERTPAPIERORDER_TYPES.MUTATIONS.GET_POSITION_DATA_COMPONENTS_SUCCESS, response.data)
      });
    }
  },

  async [WERTPAPIERORDER_TYPES.ACTIONS.CHECK_DEPOT_NUMMER]({ getters, dispatch }, { lagerstelle, depotNr }) {
    const params = lagerstelle && depotNr ? `?gesellId=${lagerstelle}&depotnummer=${depotNr}` : ''

    if (!params) {
      return false
    }

    const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/wertpapierorder/is_depotnummer_valid${params}`
    try {
      const response = await axios.get(url);
      return response?.data
    } catch(error) {
      console.log('err: ', error.response.data);
      // console.log('err decoded: ', decodeURIComponent(error.response.data.ATTR_LOGIN_URI_COMDIRECT));
      console.log('err decoded: ', error.response.data.ATTR_LOGIN_URI_COMDIRECT);
      let msg =  error.response.data.ATTR_ERR_MSG || 'Es ist ein unerwarteter Fehler aufgetreten.';
      dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(msg, 'danger'));
    }
  },
  
  async [WERTPAPIERORDER_TYPES.ACTIONS.KAUF]({ dispatch, commit, rootState }, payload) {
    try {
      let response = await axios.post(rootState.core.apiAddress + '/consultation/wertpapierinfo', {
        ask: 'get_wp',
        params: {
          type: "1",
          lagerstelle: payload.lagerstelle,
          filter: [['1.1.01', payload.isinLst.split(',')]],
        }
      }, {defaultSpinner: true});
      if (response.data.tabledata.records.length) {
        const fonds = response.data.tabledata;
        const positions = [];
        fonds.records.forEach(row => {
          positions.push( {
            isin: row.isin, 
            positionTyp: payload.categoryId, 
            wertpapiername: row.fondsname, 
            wkn: row.old_wkn, 
            sri: row.sri,
            depotNr: payload.depotNr, 
            lagerstelle: payload.lagerstelle, 
            betragModus: 'VALUE_BETRAG_MODUS_EURO' });
        });
        commit(WERTPAPIERORDER_TYPES.MUTATIONS.ADD_POSITIONS, {positions: positions });
        dispatch(WERTPAPIERORDER_TYPES.ACTIONS.SAVE_WERTPAPIERORDER, {});
      }
    } catch (er) {
      console.log('WERTPAPIERORDER_TYPES.ACTIONS.KAUF', er);
    }
  },
  
  [WERTPAPIERORDER_TYPES.ACTIONS.GET_PERSONEN_AUSWAHL]({ getters, commit }) {
    axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/wertpapierorder/getPersonenAuswahl`, config).then(response => {
      commit(WERTPAPIERORDER_TYPES.MUTATIONS.GET_PERSONEN_AUSWAHL_SUCCESS, response.data)
    });
  },

  [WERTPAPIERORDER_TYPES.ACTIONS.GET_MELDUNG_EINREICHUNG]({ state, getters, dispatch }) {
    if (state.data?.lagerstelle) {
      axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/wertpapierorder/getMeldungEinreichung?lagerstelle=${state.data.lagerstelle}`, config).then(response => {
        if (response?.data) {
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data, 'info'));
        }
      });
    }
  },
  
  /* Get list of Lagerstellen and depots for the modal */
  async [WERTPAPIERORDER_TYPES.ACTIONS.GET_LAGERSTELLEN]({ getters, commit }, lagerstelle) {
    await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/wertpapierorder/getLagerstellen?lagerstelle=${lagerstelle || ''}`, config).then(response => {
      if (response?.data) {
        commit(WERTPAPIERORDER_TYPES.MUTATIONS.GET_LAGERSTELLEN_SUCCESS, response.data);
      }
    });
  },

  /* Get depots for combobox 'Eine bestehende Position bearbeiten' */
  [WERTPAPIERORDER_TYPES.ACTIONS.GET_DEPOTS]({ getters, commit }, lagerstelle) {
    axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/wertpapierorder/getDepots?lagerstelle=${lagerstelle || ''}`, config).then(response => {
      if (response?.data) {
        commit(WERTPAPIERORDER_TYPES.MUTATIONS.GET_DEPOTS_SUCCESS, response.data);
      }
    });
  },

  async [WERTPAPIERORDER_TYPES.ACTIONS.CHECK_COMDIRECT_LOGIN]({ getters }, lagerstelle) {
    await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/wertpapierorder/checkComdirectLogin?lagerstelle=${lagerstelle || ''}`, config);
  },
 async [WERTPAPIERORDER_TYPES.ACTIONS.GET_VENUES]({ getters, commit }, { lagerstelle, isin }) {
   await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/wertpapierorder/getVenues?lagerstelle=${lagerstelle}&isin=${isin}`, config)
    .then(response => {
      if (response?.data) {
        commit(WERTPAPIERORDER_TYPES.MUTATIONS.GET_VENUES, {isin , value: response.data});
      }
    });
  },

  [WERTPAPIERORDER_TYPES.ACTIONS.TO_SIGNATURE_VORLEGEN]({rootState, commit}, { antragsdatenId, data }) {
    if (antragsdatenId && data?.hatPDF) {
      axios.post(`${rootState.core.apiAddress}/antraegeService/to_signatur_vorlegen?antragsdatenId=${antragsdatenId || ''}`, data, config).then(response => {
        if (response.data) {
          commit(WERTPAPIERORDER_TYPES.MUTATIONS.UPDATE_IS_VORGELEGT, { IS_VORGELEGT: response.data.IS_VORGELEGT});
        }
      })
    }
  },

}