<template>
    <div>
        <FlexibleList :rows="rows" showThirdRow :linkInFirstRow="isEditable" @onRowClick="onRowClick">
      
            <template #title="row">
                {{ row.reason }}
            </template>

            <template #value="row">
                <CurrencyLabel :value="row.aktWert"/>
            </template>

            <template #thirdRow="row">
                {{ row.date || "./." }}
            </template>      
        
        </FlexibleList>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import OTHER_PRODUCTS_TYPES from '@/store/otherProducts/types';

import FlexibleList from "@/components/flexibleList/FlexibleList.vue";
import CurrencyLabel from '@/components/core/CurrencyLabel.vue';


export default {
  data() {
    return {
      loading: false,
      update: {},
    };
  },
  computed: {
    ...mapGetters({
      updates: OTHER_PRODUCTS_TYPES.GETTERS.UPDATES,
    }),
    rows() {
        return (this.updates || []).map(row => ({
            ...row,
        }));
    },
    isEditable() {
        return true;
    },
  },
  methods: {
    onRowClick(whatRow) {
      //alert(JSON.stringify(whatRow));
      // Edit window must be implemented for the broker perspective in the app view
    },    
  },
  mounted() {
    this.$store.dispatch(OTHER_PRODUCTS_TYPES.ACTIONS.FIND_UPDATES);
  },
  components: {
    FlexibleList,
    CurrencyLabel,
  },
}
</script>