<template>
     <div>
        <FlexibleList :rows="rows" showThirdRow :linkInFirstRow="isEditable" @onRowClick="onRowClick">
          
          <template #title="row">
              {{ row.buchungsnr }}
          </template>
    
          <template #value="row">
              {{ row.zeichnungsdatum || "./." }}
          </template>
    
          <template #thirdRow="row">
              {{ row.status }}
          </template>      
    
        </FlexibleList>

    </div>   
</template>

<script>
import { mapGetters } from 'vuex';
import CREDIT_TYPES from '@/store/credit/types';

import FlexibleList from "@/components/flexibleList/FlexibleList.vue";


export default {
  data() {
    return {
        loading: false,
    }
  },
  computed: {
    ...mapGetters({
      credit: CREDIT_TYPES.GETTERS.CREDIT,
      buchungen: CREDIT_TYPES.GETTERS.BUCHUNGEN,
    }),
    rows() {
      return this.buchungen;
    },
    isEditable() {
        return true;
    },   
  },
  methods: {
    async findBuchungen() {
      this.loading = true;
      await this.$store.dispatch(CREDIT_TYPES.ACTIONS.FIND_BUCHUNGEN);
      this.loading = false;
    },
    onRowClick(whatRow) {
      //alert(JSON.stringify(whatRow));
      // Edit window must be implemented for the broker perspective in the app view
    },      
  },
  mounted() {
    this.findBuchungen();
  },  
  components: {
    FlexibleList,
  },
}
</script>