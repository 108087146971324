var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vertical-stepper",
      class: {
        "vertical-stepper--collapsed": _vm.collapsed,
        "vertical-stepper--collapsable": _vm.showCollapse
      }
    },
    [
      _vm.showCollapse
        ? _c(
            "div",
            { staticClass: "vertical-stepper--collapse-toggle__container" },
            [
              _c(
                "button",
                {
                  staticClass:
                    "vertical-stepper--collapse-toggle btn-clear clickable",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.toggleCollapse()
                    }
                  }
                },
                [_c("PhCaretLeft", { attrs: { size: 24 } })],
                1
              )
            ]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "vertical-stepper--items" },
        [
          _c(
            "SortableList",
            {
              key: _vm.stepsComputed && _vm.stepsComputed.length,
              staticClass: "vertical-stepper--items__wrap",
              attrs: { items: _vm.stepsComputed },
              on: {
                orderChanged: function($event) {
                  return _vm.$emit("orderChanged", $event)
                }
              }
            },
            [
              _c(
                "div",
                { attrs: { "data-sortable-container": "" } },
                [
                  _vm.stepsComputed && _vm.stepsComputed.length
                    ? _vm._l(_vm.stepsComputed, function(step, index) {
                        return _c(
                          "div",
                          {
                            key: index + _vm.lastUpdated,
                            staticClass: "vertical-stepper--item",
                            attrs: { "data-sortable-item": "" }
                          },
                          [
                            _c("VerticalStepItemEditable", {
                              attrs: {
                                step: step,
                                stepType: _vm.stepType,
                                isCurrent: _vm.selectedStepKey === step.stepKey,
                                showSaveSpinner:
                                  _vm.showSaveSpinner &&
                                  _vm.stepClicked === step.stepKey,
                                collapsed: _vm.collapsed,
                                disabled: _vm.disabled,
                                actionsConfig: _vm.actionsConfig
                              },
                              on: {
                                click: function($event) {
                                  return _vm.onClickStop(step.stepKey)
                                },
                                stepEdited: function($event) {
                                  return _vm.$emit("stepEdited", $event)
                                },
                                enablesEditStep: function($event) {
                                  return _vm.$emit("enablesEditStep", $event)
                                },
                                disablesEditStep: function($event) {
                                  return _vm.$emit("disablesEditStep", $event)
                                },
                                removeStep: function($event) {
                                  return _vm.$emit("removeStep", $event)
                                }
                              }
                            }),
                            _vm._l(_vm.getSubsteps(step), function(
                              substep,
                              subIndex
                            ) {
                              return _c("VerticalSubstepItem", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !substep.hidden,
                                    expression: "!substep.hidden"
                                  }
                                ],
                                key: subIndex + _vm.lastUpdated,
                                class: {
                                  "vertical-stepper--item--fake-substep": _vm.isFakeSubstep()
                                },
                                attrs: {
                                  isCurrent:
                                    _vm.selectedSubstepKey ===
                                    substep.substepKey,
                                  substep: substep,
                                  collapsed: _vm.collapsed
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.onClickSubstep(
                                      step.stepKey,
                                      substep.substepKey
                                    )
                                  }
                                }
                              })
                            })
                          ],
                          2
                        )
                      })
                    : _vm._e(),
                  _c("div", { staticClass: "vertical-stepper--item" }, [
                    _c(
                      "div",
                      {
                        staticClass: "stepper-item__container clickable",
                        class: { disabled: _vm.disabled },
                        on: { click: _vm.addStep }
                      },
                      [
                        _c("div", { staticClass: "stepper-item__icon" }, [
                          _c(
                            "div",
                            { staticClass: "vertical-step__icon-container" },
                            [
                              _c("PhPlusCircle", {
                                staticClass:
                                  "color-primary vertical-stop__icon",
                                attrs: { size: 16 }
                              })
                            ],
                            1
                          )
                        ]),
                        _c("span", { staticClass: "vertical-stop__label" }, [
                          _c(
                            "span",
                            { staticClass: "vertical-stop__label--content" },
                            [_vm._v("Neue Gruppe")]
                          )
                        ])
                      ]
                    )
                  ])
                ],
                2
              )
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }