<template>
  <BaseButton v-if="showCloseExtraWebInstance" isClear @click="callCloseExtraWebInstance">
    <PhXCircle :size="size" />
  </BaseButton>
</template>

<script>
import BRIDGE_TYPES from '@/store/bridge/types';
import { mapGetters } from 'vuex';
import { PhXCircle } from 'phosphor-vue'
import BaseButton from '@/components/core/BaseButton.vue';

export default {
  props: {
    size: {
      default: 18
    }
  },
  computed: {
    ...mapGetters({
      showCloseExtraWebInstance: BRIDGE_TYPES.GETTERS.SHOW_CLOSE_EXTRA_WEB_INSTANCE,
    }),
  },
  methods: {
    callCloseExtraWebInstance() {
      this.$store.dispatch(BRIDGE_TYPES.ACTIONS.CLOSE_EXTRA_WEB_INSTANCE_WITH_CONFIRM);
    },
  },
  components: {
    PhXCircle,
    BaseButton,
  }
}
</script>

<style scoped>

</style>