var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("InputRadioBoxGroup", {
        attrs: {
          title: "Eigenheim",
          validateUntouched: true,
          disabled: _vm.isRefusedPage,
          values: _vm.proprietary,
          labelClass: "font-bold"
        },
        on: {
          input: function($event) {
            return _vm.updateImmo("homeHollyOwned", $event)
          }
        },
        model: {
          value: _vm.homeHollyOwned,
          callback: function($$v) {
            _vm.homeHollyOwned = $$v
          },
          expression: "homeHollyOwned"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Eigenanteil",
          type: "percent",
          isComponentHalfSize: "",
          disabled: _vm.isRefusedPage || _vm.homeHollyOwned !== "CO_OWNER"
        },
        on: {
          input: function($event) {
            return _vm.updateImmo("homePercentage", $event || null)
          }
        },
        model: {
          value: _vm.homePercentage,
          callback: function($$v) {
            _vm.homePercentage = $$v
          },
          expression: "homePercentage"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Aktueller Verkehrswert (Gesamtobjekt)",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.isRefusedPage || !_vm.homeHollyOwned
        },
        on: {
          input: function($event) {
            return _vm.updateImmo("home", $event || null)
          }
        },
        model: {
          value: _vm.home,
          callback: function($$v) {
            _vm.home = $$v
          },
          expression: "home"
        }
      }),
      _c("hr"),
      _c("InputRadioBoxGroup", {
        attrs: {
          title: "Eigentumswohnungen",
          validateUntouched: true,
          disabled: _vm.isRefusedPage,
          values: _vm.proprietary,
          labelClass: "font-bold"
        },
        on: {
          input: function($event) {
            return _vm.updateImmo("appartmentsHollyOwned", $event)
          }
        },
        model: {
          value: _vm.appartmentsHollyOwned,
          callback: function($$v) {
            _vm.appartmentsHollyOwned = $$v
          },
          expression: "appartmentsHollyOwned"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Eigenanteil",
          type: "percent",
          isComponentHalfSize: "",
          disabled:
            _vm.isRefusedPage || _vm.appartmentsHollyOwned !== "CO_OWNER"
        },
        on: {
          input: function($event) {
            return _vm.updateImmo("appartmentsPercentage", $event || null)
          }
        },
        model: {
          value: _vm.appartmentsPercentage,
          callback: function($$v) {
            _vm.appartmentsPercentage = $$v
          },
          expression: "appartmentsPercentage"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Aktueller Verkehrswert (Gesamtobjekt)",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.isRefusedPage || !_vm.appartmentsHollyOwned
        },
        on: {
          input: function($event) {
            return _vm.updateImmo("appartments", $event || null)
          }
        },
        model: {
          value: _vm.appartments,
          callback: function($$v) {
            _vm.appartments = $$v
          },
          expression: "appartments"
        }
      }),
      _c("hr"),
      _c("InputRadioBoxGroup", {
        attrs: {
          title: "Sonstige Immobilien / Liegenschaften",
          validateUntouched: true,
          disabled: _vm.isRefusedPage,
          values: _vm.proprietary,
          labelClass: "font-bold"
        },
        on: {
          input: function($event) {
            return _vm.updateImmo("otherPropertyHollyOwned", $event)
          }
        },
        model: {
          value: _vm.otherPropertyHollyOwned,
          callback: function($$v) {
            _vm.otherPropertyHollyOwned = $$v
          },
          expression: "otherPropertyHollyOwned"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Eigenanteil",
          type: "percent",
          isComponentHalfSize: "",
          disabled:
            _vm.isRefusedPage || _vm.otherPropertyHollyOwned !== "CO_OWNER"
        },
        on: {
          input: function($event) {
            return _vm.updateImmo("otherPropertyPercentage", $event || null)
          }
        },
        model: {
          value: _vm.otherPropertyPercentage,
          callback: function($$v) {
            _vm.otherPropertyPercentage = $$v
          },
          expression: "otherPropertyPercentage"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Aktueller Verkehrswert (Gesamtobjekt)",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.isRefusedPage || !_vm.otherPropertyHollyOwned
        },
        on: {
          input: function($event) {
            return _vm.updateImmo("otherProperty", $event || null)
          }
        },
        model: {
          value: _vm.otherProperty,
          callback: function($$v) {
            _vm.otherProperty = $$v
          },
          expression: "otherProperty"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }