<template>
  <svg width="70" height="36" viewBox="0 0 70 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path
        d="M3.50982 33.6351L6.51388 28.8678L3.50843 34.1544L3.50682 34.6976C3.2925 34.6951 3.08035 34.6869 2.87036 34.673L3.11538 27.6949L3.11216 27.6409L3.11768 27.6306L3.14101 26.9712L0 21.7462L3.14425 26.4774L3.15366 26.6171L3.33876 21.3448L0.638484 15.9389L3.35986 20.4184L3.48645 9.49047L3.48703 9.4541L3.48746 9.48989L3.5491 18.1058L6.12935 14.6585L3.54681 18.8479L3.53484 23.5669L5.92894 19.0093L3.53352 24.2607L3.5269 26.8847L7.00455 20.5389L3.52415 27.8001L3.50982 33.6351Z"
        fill="#D0CDE1" />
      <path d="M45.6746 13.5127H44.8301V19.509H45.6746V13.5127Z" fill="#3F3D56" />
      <path d="M14.425 0.751627L14.1074 1.53418L45.3603 14.2193L45.6779 13.4367L14.425 0.751627Z" fill="#3F3D56" />
      <path
        d="M69.9122 34.4294C68.6041 36.6148 -0.068784 36.4313 0.0352744 34.4293C1.34335 32.244 70.0162 32.4275 69.9122 34.4294Z"
        fill="#D0CDE1" />
      <path d="M61.2988 25.5898H53.9512V28.4613H61.2988V25.5898Z" fill="#3F3D56" />
      <path d="M60.624 28.124H24.3926V31.3333H60.624V28.124Z" fill="#3F3D56" />
      <path d="M54.6716 29.728H45.9727V18.791H52.4758L54.6716 24.3071V29.728Z" fill="#D0CDE1" />
      <path d="M51.0383 19.4668H47.3223V23.1828H51.0383V19.4668Z" :fill="secondColor" />
      <path d="M53.8257 23.2258H51.4609V19.5098H52.4744L53.8257 23.2258Z" :fill="secondColor" />
      <path d="M48.3368 25.2949H47.4922V26.8996H48.3368V25.2949Z" fill="#3F3D56" />
      <path
        d="M52.1777 21.874H52.22C52.4328 21.874 52.6368 21.9586 52.7873 22.109C52.9378 22.2595 53.0223 22.4636 53.0223 22.6764C53.0223 22.8891 52.9378 23.0932 52.7873 23.2437C52.6368 23.3941 52.4328 23.4787 52.22 23.4787H52.1777V21.874Z"
        fill="#3F3D56" />
      <path
        d="M20.7617 28.124H20.8039C21.0167 28.124 21.2208 28.2086 21.3713 28.359C21.5217 28.5095 21.6063 28.7136 21.6063 28.9263C21.6063 29.1391 21.5217 29.3432 21.3713 29.4937C21.2208 29.6441 21.0167 29.7287 20.8039 29.7287H20.7617V28.124Z"
        fill="#3F3D56" />
      <path
        d="M58.0477 28.1114C59.3347 28.1114 60.4924 28.7349 61.2956 29.7282H62.9462L61.3415 25.2583L53.8672 23.7842L54.0783 29.7282H54.7999C55.6031 28.7349 56.7607 28.1114 58.0477 28.1114Z"
        fill="#D0CDE1" />
      <path
        d="M58.1328 34.458C60.1618 34.458 61.8066 32.8132 61.8066 30.7842C61.8066 28.7552 60.1618 27.1104 58.1328 27.1104C56.1038 27.1104 54.459 28.7552 54.459 30.7842C54.459 32.8132 56.1038 34.458 58.1328 34.458Z"
        fill="#3F3D56" />
      <path
        d="M58.1328 32.7178C59.2007 32.7178 60.0664 31.8521 60.0664 30.7842C60.0664 29.7163 59.2007 28.8506 58.1328 28.8506C57.0649 28.8506 56.1992 29.7163 56.1992 30.7842C56.1992 31.8521 57.0649 32.7178 58.1328 32.7178Z"
        fill="#D0CDE1" />
      <path
        d="M24.9417 34.4157C26.7142 34.4157 28.151 32.9788 28.151 31.2064C28.151 29.4339 26.7142 27.9971 24.9417 27.9971C23.1693 27.9971 21.7324 29.4339 21.7324 31.2064C21.7324 32.9788 23.1693 34.4157 24.9417 34.4157Z"
        fill="#3F3D56" />
      <path
        d="M24.9411 32.8958C25.8739 32.8958 26.6302 32.1396 26.6302 31.2067C26.6302 30.2738 25.8739 29.5176 24.9411 29.5176C24.0082 29.5176 23.252 30.2738 23.252 31.2067C23.252 32.1396 24.0082 32.8958 24.9411 32.8958Z"
        fill="#D0CDE1" />
      <path d="M46.2247 17.2715H44.4512V30.0665H46.2247V17.2715Z" fill="#3F3D56" />
      <path d="M46.2243 27.7441H20.7188V28.5042H46.2243V27.7441Z" fill="#3F3D56" />
      <path d="M34.8839 22.9657L7.06836 30.5332L7.2679 31.2666L35.0835 23.6992L34.8839 22.9657Z" fill="#3F3D56" />
      <path d="M32.9862 23.8872L32.5098 24.2373L35.4359 28.2191L35.9123 27.869L32.9862 23.8872Z" fill="#3F3D56" />
      <path d="M52.6844 23.3945H51.8398V23.7324H52.6844V23.3945Z" fill="#3F3D56" />
      <path
        d="M45.2536 25.928H34.1899C34.1696 25.928 34.15 25.9207 34.1346 25.9074C34.1193 25.8941 34.1093 25.8758 34.1063 25.8557C34.1034 25.8356 34.1078 25.8151 34.1188 25.7981C34.1297 25.781 34.1464 25.7684 34.1659 25.7626L44.9762 22.5533C44.9883 22.5497 45.001 22.5489 45.0134 22.5508C45.0259 22.5528 45.0377 22.5575 45.0481 22.5646C45.0584 22.5718 45.0671 22.5811 45.0734 22.592C45.0797 22.6029 45.0834 22.6151 45.0844 22.6276L45.3378 25.8369C45.3387 25.8485 45.3372 25.8602 45.3334 25.8712C45.3296 25.8822 45.3235 25.8923 45.3156 25.9009C45.3077 25.9094 45.2981 25.9163 45.2874 25.9209C45.2768 25.9256 45.2652 25.928 45.2536 25.928ZM34.7711 25.7591H45.1622L44.9243 22.7449L34.7711 25.7591Z"
        fill="#3F3D56" />
      <path
        d="M15.2941 22.2118H15.2933L12.8688 22.1889C12.857 22.1888 12.8453 22.1862 12.8346 22.1813C12.8238 22.1764 12.8142 22.1693 12.8063 22.1604C12.7985 22.1516 12.7926 22.1411 12.7891 22.1298C12.7855 22.1186 12.7844 22.1066 12.7857 22.0949L14.0454 11.1032C14.0478 11.0826 14.0577 11.0636 14.0731 11.0498C14.0886 11.036 14.1086 11.0283 14.1294 11.0283H14.1297C14.1505 11.0284 14.1706 11.0362 14.186 11.0501C14.2014 11.0641 14.2112 11.0832 14.2134 11.1039L15.3781 22.1185C15.3794 22.1302 15.3781 22.1421 15.3745 22.1534C15.3708 22.1647 15.3648 22.175 15.3569 22.1838C15.349 22.1926 15.3393 22.1997 15.3285 22.2045C15.3177 22.2093 15.306 22.2118 15.2941 22.2118ZM12.9642 22.0209L15.2002 22.042L14.126 11.884L12.9642 22.0209Z"
        fill="#3F3D56" />
      <path
        d="M61.8945 26.8242L61.855 26.8392C61.656 26.9144 61.4352 26.9076 61.2413 26.8201C61.0473 26.7325 60.896 26.5716 60.8208 26.3725C60.7455 26.1735 60.7524 25.9527 60.8399 25.7588C60.9274 25.5648 61.0883 25.4135 61.2874 25.3383L61.3269 25.3233L61.8945 26.8242Z"
        fill="#3F3D56" />
      <path d="M10.5219 27.3171L8.16602 27.9766L8.27068 28.3505L10.6266 27.6911L10.5219 27.3171Z" fill="#3F3D56" />
      <path
        d="M27.5812 22.6426L27.1264 22.8908L8.65343 28.0615L8.37917 27.7208C7.90394 27.1378 7.60017 26.4341 7.50179 25.6884C7.43204 25.1315 7.48306 24.5446 7.8715 24.1874L7.16602 18.4975L17.3826 15.624L22.2283 17.7091C22.2283 17.7091 24.0845 17.1545 25.4504 18.1331C25.8827 18.4432 26.2662 18.9068 26.5254 19.5912L26.7488 20.2363L27.5812 22.6426Z"
        :fill="color" />
      <path
        d="M23.4977 26.0539C24.8059 26.0539 25.8664 24.9934 25.8664 23.6852C25.8664 22.3769 24.8059 21.3164 23.4977 21.3164C22.1894 21.3164 21.1289 22.3769 21.1289 23.6852C21.1289 24.9934 22.1894 26.0539 23.4977 26.0539Z"
        fill="#3F3D56" />
      <path
        d="M23.4987 24.9319C24.1872 24.9319 24.7454 24.3737 24.7454 23.6852C24.7454 22.9966 24.1872 22.4385 23.4987 22.4385C22.8101 22.4385 22.252 22.9966 22.252 23.6852C22.252 24.3737 22.8101 24.9319 23.4987 24.9319Z"
      fill="#D0CDE1" />
    <path
      d="M12.2418 29.2043C13.55 29.2043 14.6106 28.1438 14.6106 26.8355C14.6106 25.5273 13.55 24.4668 12.2418 24.4668C10.9336 24.4668 9.87305 25.5273 9.87305 26.8355C9.87305 28.1438 10.9336 29.2043 12.2418 29.2043Z"
      fill="#3F3D56" />
    <path
      d="M12.2428 28.0823C12.9313 28.0823 13.4895 27.5241 13.4895 26.8356C13.4895 26.147 12.9313 25.5889 12.2428 25.5889C11.5543 25.5889 10.9961 26.147 10.9961 26.8356C10.9961 27.5241 11.5543 28.0823 12.2428 28.0823Z"
      fill="#D0CDE1" />
    <path d="M20.5524 17.7757L13.223 19.8272L12.6055 17.6209L17.2798 16.3125L20.5524 17.7757Z" :fill="secondColor" />
    <path d="M11.7098 17.8721L8.79297 18.6885L9.41052 20.8948L12.3273 20.0784L11.7098 17.8721Z" :fill="secondColor" />
    <path d="M14.0575 21.367L13.6836 21.4717L13.8825 22.1822L14.2564 22.0775L14.0575 21.367Z" fill="#3F3D56" />
    <path d="M20.05 19.0048L19.9453 18.6309L19.2348 18.8297L19.3395 19.2037L20.05 19.0048Z" fill="#3F3D56" />
    <path
      d="M19.1055 17.3574L19.1466 17.3459C19.3539 17.2879 19.5757 17.3146 19.7633 17.4201C19.9509 17.5257 20.0889 17.7014 20.1469 17.9087C20.2049 18.116 20.1782 18.3378 20.0727 18.5254C19.9671 18.713 19.7914 18.851 19.5841 18.909L19.543 18.9205L19.1055 17.3574Z"
      fill="#3F3D56" />
    <path
      d="M26.7499 20.236C26.4756 20.3458 26.1715 20.3553 25.8909 20.2628C25.6104 20.1703 25.3715 19.9817 25.2163 19.7304C25.0612 19.479 24.9997 19.181 25.0427 18.8887C25.0857 18.5965 25.2304 18.3288 25.4515 18.1328C25.8838 18.4429 26.2673 18.9065 26.5265 19.5909L26.7499 20.236Z"
      fill="#3F3D56" />
    <path d="M14.5106 0.760742H13.666V13.0913H14.5106V0.760742Z" fill="#3F3D56" />
    <path
      d="M14.0886 2.36476C14.7416 2.36476 15.271 1.83539 15.271 1.18238C15.271 0.529369 14.7416 0 14.0886 0C13.4356 0 12.9062 0.529369 12.9062 1.18238C12.9062 1.83539 13.4356 2.36476 14.0886 2.36476Z"
      fill="#3F3D56" />
    <path
      d="M45.2527 14.8638C45.9057 14.8638 46.4351 14.3344 46.4351 13.6814C46.4351 13.0284 45.9057 12.499 45.2527 12.499C44.5997 12.499 44.0703 13.0284 44.0703 13.6814C44.0703 14.3344 44.5997 14.8638 45.2527 14.8638Z"
      fill="#3F3D56" />
  </g>
  <defs>
    <clipPath id="clip0">
      <rect width="69.9121" height="36" :fill="secondColor" />
    </clipPath>
  </defs>
</svg></template>

<script>
import undraw from '@/components/icons/undraw/undraw-mixin.js'

export default {
  mixins: [undraw]
}
</script>