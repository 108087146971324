import FREISTELLUNGSANTRAG_TYPES from './types';

export default {
  [FREISTELLUNGSANTRAG_TYPES.GETTERS.FREISTELLUNGSANTRAG_DATA](state) {
    return state.freistellungsantragData;
  },
  [FREISTELLUNGSANTRAG_TYPES.GETTERS.SELECTED_ITEM](state) {
    return state.selectedItem;
  },
  [FREISTELLUNGSANTRAG_TYPES.GETTERS.FREISTELLUNGSANTRAG_GESELLSCHAFTEN](state, payload) {
    return state.gesellschaften;
  },
}