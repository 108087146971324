<template>
  <div v-if="isByPass" class="box__container">
    <div class="box__title">Versandadresse</div>
    <div v-if="isEditable" class="mb-3">
      <BaseButton isSecondary @click="copy()">Kundendaten als Versandadresse übernehmen</BaseButton>
    </div>
    <div class="row">
      <div class="col-12">
        <InputField label="c/o" v-model="shippingAddress.co" :isComponentHalfSize="true"
          :disabled="!isEditable" @change="addCustomerDataEdited('shippingAddress')" />
        <InputField label="Straße, HNr" v-model="shippingAddress.street" :isComponentHalfSize="true" 
          :disabled="!isEditable" @change="addCustomerDataEdited('shippingAddress')" />
        <InputField label="Postleitzahl" v-model="shippingAddress.zip" :isComponentHalfSize="true" 
          :disabled="!isEditable" @change="addCustomerDataEdited('shippingAddress')" />
        <InputField label="Ort" v-model="shippingAddress.city" :isComponentHalfSize="true" 
          :disabled="!isEditable" @change="addCustomerDataEdited('shippingAddress')" />
        <ComboBox label="Land" v-model="shippingAddress.country" :isComponentHalfSize="true" 
          :disabled="!isEditable" :values="customerDataConfig.countries" :firstEmpty="!shippingAddress.country"
          @change="addCustomerDataEditedCombo('shippingAddress', 'country', $event)" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CUSTOMERDATA_TYPES from '@/store/customerData/types';

import BaseButton from '@/components/core/BaseButton.vue';
import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';

import persoenlicheDatenMixin from '../persoenliche-daten-mixin';

const HINT_GROUP = 'VERSANDADRESSE';

export default {
  mixins: [persoenlicheDatenMixin],
  data() {
    return {
      ...this.initialState(),
    };
  },
  computed: {
    ...mapGetters({
      customerData: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_DATA,
      customerDataEdited: CUSTOMERDATA_TYPES.GETTERS.GET_CUSTOMER_DATA_EDITED,
      customerDataConfig: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_CONFIG,
    }),
  },
  methods: {
    initialState() {
      return {
        shippingAddress: {
          co: '',
          street: '',
          zip: '',
          city: '',
          country: '',
        },
      };
    },
    updateGroupsIntoData() {
      this.updateGroupIntoData('shippingAddress');
    },
    copy() {
      const personalDataAddress = this.customerDataEdited?.personalDataAddress || this.customerData?.personalDataAddress;
      this.shippingAddress.co = personalDataAddress.co;
      this.shippingAddress.street = personalDataAddress.street;
      this.shippingAddress.zip = personalDataAddress.zip;
      this.shippingAddress.city = personalDataAddress.city;
      this.shippingAddress.country = personalDataAddress.country;
      this.addCustomerDataEdited('shippingAddress');
    },
    addHint() {
      this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.ADD_HINT, {
        group: HINT_GROUP,
        title: 'Versandadresse',
        message: ''
          + 'Hier können Sie eine abweichende Versandadresse zu Ihrer Information eintragen. ' 
          + 'In machen Depoteröffnungsanträgen kann diese Versandadresse übernommen werden. '
          + 'Auf den Dokumenten im smartMSC wie z.B. Vermögensübersicht, wird diese Adresse nicht aufgedruckt.',
      });
    },
  },
  mounted() {
    this.addHint();
    this.updateGroupsIntoData();
  },
  beforeDestroy() {
    this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.REMOVE_HINTS, { group: HINT_GROUP, });
  },
  components: {
    BaseButton,
    InputField,
    ComboBox,
  },
}
</script>
