import WERTPAPIERE_ALLE_KUNDEN_TYPES from './types';
import axios from 'axios';

const config = {
  defaultSpinner: true
};

export default {
  [WERTPAPIERE_ALLE_KUNDEN_TYPES.ACTIONS.GET_INIT_DATA]({ commit, rootState }) {
    commit(WERTPAPIERE_ALLE_KUNDEN_TYPES.MUTATIONS.RESET_STATE);

    axios.get(`${rootState.core.apiAddress}/wertpapiereallekunden/initData`, config).then(response => {
      if (response.status === 200 && response.data) {
        commit(WERTPAPIERE_ALLE_KUNDEN_TYPES.MUTATIONS.GET_INIT_DATA_SUCCESS, response.data)
      }
    });
  },
  
  [WERTPAPIERE_ALLE_KUNDEN_TYPES.ACTIONS.SEARCH_WERTPAPIER]({ commit, rootState, state }) {
    const data = state.data || {};
    if (data.gesellschaft) {
      axios.get(`${rootState.core.apiAddress}/wertpapiereallekunden/getAuswahlFonds?gesellschaft=${data.gesellschaft}&lagerstelle=${data.lagerstelle || ''}&zusatz=${data.zusatz || ''}`, config).then(response => {
        if (response.status === 200 && response.data) {
          commit(WERTPAPIERE_ALLE_KUNDEN_TYPES.MUTATIONS.SEARCH_WERTPAPIER_SUCCESS, response.data)
        }
      });
    }
  },
  
  [WERTPAPIERE_ALLE_KUNDEN_TYPES.ACTIONS.LIST_POSITIONS]({ commit, rootState, state }, paramModus) {

    axios.post(`${rootState.core.apiAddress}/wertpapiereallekunden/listCustomers`, Object.assign({}, state.data || {}, {paramModus: paramModus || 'verkauf'}), config).then(response => {
      if (response.status === 200 && response.data) {
        commit(WERTPAPIERE_ALLE_KUNDEN_TYPES.MUTATIONS.LIST_POSITIONS_SUCCESS, response.data)
      }
    });
  },

  async [WERTPAPIERE_ALLE_KUNDEN_TYPES.ACTIONS.CREATE_ANTRAEGE]({ commit, rootState, state }) {
    const config = { defaultSpinner: true, cancelableRequest: { title: 'Alle Anträge erzeugen' } };
    await axios.post(`${rootState.core.apiAddress}/wertpapiereallekunden/createAntraege`, { positions: state.positions || [], 
      data: {emailBetreff: state.emailData?.betreff || '', emailText: state.emailData?.html}}, config).then(response => {
      if (response.status === 200 && response.data) {
        commit(WERTPAPIERE_ALLE_KUNDEN_TYPES.MUTATIONS.CREATE_ANTRAEGE_SUCCESS, response.data)
      }
    });
  },

  [WERTPAPIERE_ALLE_KUNDEN_TYPES.ACTIONS.GET_EMAIL_DATA]({ commit, rootState }) {
    axios.get(`${rootState.core.apiAddress}/wertpapiereallekunden/emailData`, config).then(response => {
      if (response.status === 200 && response.data) {
        commit(WERTPAPIERE_ALLE_KUNDEN_TYPES.MUTATIONS.GET_EMAIL_DATA_SUCCESS, response.data)
      }
    });
  },
  
}