<template>
  <div class="input-forms__container">
    <div class="input-forms__label-container">
      <div class="row mt-0">
        <div class="col">
          <div class="input-forms__label-content input-forms__label-content--bigger" v-if="title">
            <ph-bank :size="16" />&nbsp;{{title}}
          </div>
        </div>
      </div>
    </div>
    <div class="input-forms__input-container">
        <Table v-if="positions.length"
            :headers="headers"
            :rows="positions"
            rowId="isin"
            hidePagination
        >
            <template v-slot:vorhanden="row">
                <InputToggleSwitch v-if="row"
                  :value="row.vorhanden" :disabled="!row.isKaufbar"
                  @input="onChangeInputField($event, row, 'vorhanden')"
                />
            </template>

            <template v-slot:typ="row">
                <ComboBox v-if="row"
                  :value="row.posTyp"
                  :values="row.isKaufbar ? typeValues : typeValuesWithoutKauf"
                  @input="onChangeInputField($event, row, 'posTyp')"
                />
            </template>
        
            <template v-slot:betrag="row">
                <InputField type="currency" :value="row.betrag"
                  :disabled="!service && !row.vorhanden"
                  @change="onChangeInputField($event || 0, row, 'betrag')"
                />
            </template>
            
            <template v-slot:monat="row">
                <InputField :value="row.monat"
                  :disabled="!service && !row.vorhanden"
                  @change="onChangeInputField($event, row, 'monat')"
                />
            </template>
            
            <template v-slot:jahr="row">
                <InputField :value="row.jahr"
                  :disabled="!service && !row.vorhanden"
                  @change="onChangeInputField($event, row, 'jahr')"
                />
            </template>
        
            <template v-slot:rythmus="row">
                <ComboBox
                  :value="row.rythmus"
                  :values="dateValues"
                  :disabled="!service && !row.vorhanden"
                  @change="onChangeInputField($event, row, 'rythmus')"
                />
            </template>

            <template v-slot:isEinzugViaFlex="row">
              <InputToggleSwitch :value="row.isEinzugViaFlex" :disabled="!service && !row.vorhanden"
                  @input="onChangeInputField($event, row, 'isEinzugViaFlex')" />
            </template>

            <template v-slot:flex="row">
                <InputToggleSwitch :value="row.flex"
                  @input="onChangeInputField($event, row, 'flex')"
                />
            </template>
        </Table>
      
      <div class="mt-2" v-if="advices && advices.length">
        <div class="warning"  v-for="(advice, index) of advices" :key="index">
          Wichtiger Hinweis: "{{advice}}" steht für Neuanlagen im TL200 nicht mehr zur Verfügung.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ANTRAG_TYPES from '@/store/antrag/types';
import antragNavigationMixin from '@/mixins/antrag/antrag-navigation-mixin.js';
import Table from "@/components/table2/Table.vue";
import {TextColumn,  SlotColumn} from "@/components/table2/table_util.js";
import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import { PhBank } from 'phosphor-vue';

export default {
  mixins: [antragNavigationMixin],
  components: {
    Table,
    InputField,
    ComboBox,
    InputToggleSwitch,
    PhBank,
  },
  props: {
    title: {
    },
    categoryId: {
    },
    antragId: {
    },
    antragTyp: {
    },
    antragCategory: {
    }
  },
  data () {
    return {
      advices: [],
      dateValues: [{label: '', value: ''},{label: '1.', value: '1.'}, {label: '15.', value: '15.'}],
      typeValues: [   {label: 'Neu', value: 'NEU'}
                    , {label: 'Änderung', value: 'AENDERUNG'}
                    , {label: 'Löschung', value: 'LOESCHUNG'}
                    , {label: ' --- ', value: 'NONE'}],
      typeValuesWithoutKauf: [  {label: 'Änderung', value: 'AENDERUNG'}
                              , {label: 'Löschung', value: 'LOESCHUNG'}
                              , {label: ' --- ', value: 'NONE'}],
    }
  },
  computed: {
    ...mapGetters({
      positionsAll: ANTRAG_TYPES.GETTERS.POSITIONS,
    }),
    service() {
        return this.antragCategory && this.antragCategory === 'Serviceformular';
    },
    headers() {
        const headers = {
            lockedLeft: [
                TextColumn("isin", "ISIN"),
                TextColumn("fondsName", "Fondsname"),
            ],
            center: [
                // SlotColumn("isEinzugViaFlex", "Einzug ü.Konto flex"),
                // SlotColumn("flex", "Einzug ü.Konto flex"),
            ],
            lockedRight: [
                SlotColumn("betrag", "Betrag", 100),
                SlotColumn("monat", "ab Monat", 90),
                SlotColumn("jahr", "ab Jahr", 90),
                SlotColumn("rythmus", "zum", 85),
            ],
        };
        if (this.service) {
            headers.lockedLeft.unshift(SlotColumn("typ", "Art"));
            headers.lockedRight.unshift(SlotColumn("flex", "flex"));
        } else {
          headers.lockedLeft.unshift(SlotColumn("vorhanden", ""));
          headers.center.push(SlotColumn("isEinzugViaFlex", "Einzug ü.Konto flex"))
        }
        return headers;
    },
    positions() {
      if (this.positionsAll && this.antragId && this.categoryId 
          && this.positionsAll[this.antragId] 
          && this.positionsAll[this.antragId][this.categoryId]) {
        let positions = this.positionsAll[this.antragId][this.categoryId];
        if (positions && positions.length) {
          positions.filter(pos => pos.abDatum).forEach(pos => {
            const arr = pos.abDatum.split('.');
            if (pos.monat !== arr[0]) {
              this.onChangeInputField(arr[0], pos, 'monat');
            }
            if (pos.jahr !== arr[1]) {
              this.onChangeInputField(arr[1], pos, 'jahr');
            }
          });

          if (!this.advices || !this.advices.length) {
            positions.filter(pos => !pos.isKaufbar).forEach(pos => this.advices.push(pos.fondsName));
          }
        }
        return positions;
      }
    },
  },
  methods: {
    commitUpdatePositionsFromEvent(positionChange, isin) {
      const payload = {
        id: this.antragId,
        categoryId: this.categoryId,
        fonds: [
          { 
            ...positionChange,
            isin,
          }
        ]
      }
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_POSITIONS, payload)
    },
    onChangeInputField($event, position, field) { 
      const positionChange = { [field]: $event };
      this.commitUpdatePositionsFromEvent(positionChange, position.isin)
      if (field === 'monat' || field === 'jahr') {
        const abDatum = position.abDatum ? position.abDatum.split('.') : ['', ''];
        if (field === 'monat') {
          abDatum[0] = $event;
        } else if (field === 'jahr') {
          abDatum[1] = $event;
        }
      const newDatum = { abDatum: abDatum.join('.') };
      this.commitUpdatePositionsFromEvent(newDatum, position.isin)
    }
    }
  }
}
</script>

<style scoped>
  .warning {
    color: var(--color-danger);
    font-size: smaller;
  }
</style>