var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex" },
    [
      _c("div", { staticClass: "stoppuhr__timer" }, [
        _c("span", [_vm._v(_vm._s(_vm.stoppuhrHour))]),
        _c("span", { staticClass: "divider" }, [_vm._v(":")]),
        _c("span", [_vm._v(_vm._s(_vm.stoppuhrMinutes))]),
        _c("span", { staticClass: "divider" }, [_vm._v(":")]),
        _c("span", [_vm._v(_vm._s(_vm.stoppuhSeconds))])
      ]),
      !_vm.isStoppuhrRunning
        ? _c("PhPlay", {
            staticClass: "clickable",
            attrs: { size: 20, weight: "fill" },
            on: {
              click: function($event) {
                return _vm.startTimer()
              }
            }
          })
        : _vm._e(),
      _vm.isStoppuhrRunning
        ? _c("PhPause", {
            staticClass: "clickable",
            attrs: { size: 20, weight: "fill" },
            on: {
              click: function($event) {
                _vm.stopTimer()
                _vm.saveForm()
              }
            }
          })
        : _vm._e(),
      !_vm.isStoppuhrRunning
        ? _c("PhPause", { attrs: { size: 20, weight: "regular" } })
        : _vm._e(),
      !_vm.displayingStoppuhrForm
        ? _c("PhPencil", {
            staticClass: "clickable",
            attrs: { size: 20, weight: "fill" },
            on: {
              click: function($event) {
                return _vm.openStoppuhrForm()
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }