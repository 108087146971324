var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "modal",
    attrs: { modalTitle: "Personenbeziehungen", showDefaultButtons: false },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function() {
          return [
            _c(
              "div",
              [
                _c("ComboBox", {
                  attrs: { label: "Person", values: _vm.peopleValues },
                  model: {
                    value: _vm.selectedPersonId,
                    callback: function($$v) {
                      _vm.selectedPersonId = $$v
                    },
                    expression: "selectedPersonId"
                  }
                }),
                _vm.selectedPersonId
                  ? [
                      _c("div", { staticClass: "box__title" }, [
                        _vm._v(_vm._s(_vm.selectedPerson.fullName) + " ist...")
                      ]),
                      _vm.selectedPersonRelationships.length > 0
                        ? _vm._l(_vm.selectedPersonRelationships, function(
                            personRelationship,
                            index
                          ) {
                            return _c(
                              "div",
                              {
                                key: "" + _vm.selectedPerson.personId + index,
                                staticClass: "row"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "col-8" },
                                  [
                                    _c("ComboBox", {
                                      attrs: {
                                        values: _vm.relationsValues(
                                          personRelationship.fullName
                                        )
                                      },
                                      on: {
                                        change: function($event) {
                                          _vm.dataChanged()
                                          _vm.relationChanged(
                                            personRelationship,
                                            $event
                                          )
                                        }
                                      },
                                      model: {
                                        value: personRelationship.relationArt,
                                        callback: function($$v) {
                                          _vm.$set(
                                            personRelationship,
                                            "relationArt",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "personRelationship.relationArt"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-4" },
                                  [
                                    _c("InputToggleSwitch", {
                                      staticClass: "field-is-legal-agent",
                                      attrs: {
                                        label: "ges. Vertreter/in",
                                        inLineLabel: true,
                                        disabled: !personRelationship.relationArt
                                      },
                                      on: {
                                        input: function($event) {
                                          _vm.dataChanged()
                                          _vm.isLegalAgentChanged(
                                            personRelationship,
                                            $event
                                          )
                                        }
                                      },
                                      model: {
                                        value: personRelationship.isLegalAgent,
                                        callback: function($$v) {
                                          _vm.$set(
                                            personRelationship,
                                            "isLegalAgent",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "personRelationship.isLegalAgent"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          })
                        : _c("div", [_vm._v("Keine Daten")])
                    ]
                  : _vm._e()
              ],
              2
            )
          ]
        },
        proxy: true
      },
      {
        key: "footer",
        fn: function() {
          return [
            _c(
              "BaseButton",
              {
                staticClass: "mr-3",
                attrs: { isSecondary: "" },
                on: {
                  click: function($event) {
                    return _vm.close()
                  }
                }
              },
              [_vm._v("Abbrechen")]
            ),
            _c(
              "BaseButton",
              {
                attrs: { disabled: _vm.loading },
                on: {
                  click: function($event) {
                    return _vm.save()
                  }
                }
              },
              [
                _vm.loading ? _c("AnimatedSpinner") : _vm._e(),
                _vm._v(" Speichern ")
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }