<template>
  <BaseButton
    :tid="tid" 
    :isSecondary="!isMainAction"
    @click="onClick"
    :disabled="isDisabled">
    <component v-if="iconComponent" :is="iconComponent" :size="size"/>
    {{ responsiveLabel }} <AnimatedSpinner v-if="isLoading" />
  </BaseButton>
</template>

<script>

import BaseButton from '@/components/core/BaseButton.vue'
import headerActionsMixin from './header-actions-mixin.js'
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue'

export default {
  mixins: [headerActionsMixin],
  components: {
    BaseButton,
    AnimatedSpinner,
  }
}
</script>