var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.substep === "stammdaten"
        ? _c("PrivatStammdaten", { key: "privat-stammdaten" })
        : _vm.substep === "adresse"
        ? _c("div", { key: "privat-adresse", staticClass: "box__container" }, [
            _c("div", { staticClass: "box__title" }, [_vm._v("Adresse")]),
            _c(
              "div",
              [
                _vm.showCopyAddress
                  ? _c("BaseButton", {
                      attrs: { label: "Adresse kopieren" },
                      on: { click: _vm.copyAddress }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("InputZIP", {
                    attrs: {
                      label: "Postleitzahl",
                      labelClass: _vm.depoteroeffnungenLabelClass,
                      isComponentHalfSize: true,
                      land: _vm.personalDataAddress.country,
                      disabled: !_vm.isEditable
                    },
                    on: {
                      change: function($event) {
                        return _vm.addCustomerDataEdited("personalDataAddress")
                      },
                      city: function($event) {
                        return _vm.setCity($event)
                      }
                    },
                    model: {
                      value: _vm.personalDataAddress.zip,
                      callback: function($$v) {
                        _vm.$set(_vm.personalDataAddress, "zip", $$v)
                      },
                      expression: "personalDataAddress.zip"
                    }
                  }),
                  _c("InputField", {
                    attrs: {
                      label: "Ort",
                      labelClass: _vm.depoteroeffnungenLabelClass,
                      isComponentHalfSize: true,
                      disabled: !_vm.isEditable
                    },
                    on: {
                      change: function($event) {
                        return _vm.addCustomerDataEdited("personalDataAddress")
                      }
                    },
                    model: {
                      value: _vm.personalDataAddress.city,
                      callback: function($$v) {
                        _vm.$set(_vm.personalDataAddress, "city", $$v)
                      },
                      expression: "personalDataAddress.city"
                    }
                  }),
                  _c("InputField", {
                    attrs: {
                      label: "Straße",
                      labelClass: _vm.depoteroeffnungenLabelClass,
                      isComponentHalfSize: true,
                      disabled: !_vm.isEditable
                    },
                    on: {
                      change: function($event) {
                        return _vm.addCustomerDataEdited("personalDataAddress")
                      }
                    },
                    model: {
                      value: _vm.personalDataAddress.street,
                      callback: function($$v) {
                        _vm.$set(_vm.personalDataAddress, "street", $$v)
                      },
                      expression: "personalDataAddress.street"
                    }
                  }),
                  _c("ComboBox", {
                    attrs: {
                      label: "Land",
                      isComponentHalfSize: true,
                      values: _vm.customerDataConfig.countries,
                      disabled: !_vm.isEditable,
                      sortComboboxValues: false,
                      firstEmpty: !_vm.personalDataAddress.country
                    },
                    on: {
                      change: function($event) {
                        _vm.countryChanged($event)
                        _vm.addCustomerDataEditedCombo(
                          "personalDataAddress",
                          "country",
                          $event
                        )
                      }
                    },
                    model: {
                      value: _vm.personalDataAddress.country,
                      callback: function($$v) {
                        _vm.$set(_vm.personalDataAddress, "country", $$v)
                      },
                      expression: "personalDataAddress.country"
                    }
                  }),
                  _c("ComboBox", {
                    attrs: {
                      label: "Bundesland",
                      isComponentHalfSize: true,
                      values: _vm.stateComboValues,
                      disabled: !_vm.isEditable,
                      firstEmpty: !_vm.personalDataAddress.state
                    },
                    on: {
                      change: function($event) {
                        return _vm.addCustomerDataEditedCombo(
                          "personalDataAddress",
                          "state",
                          $event
                        )
                      }
                    },
                    model: {
                      value: _vm.personalDataAddress.state,
                      callback: function($$v) {
                        _vm.$set(_vm.personalDataAddress, "state", $$v)
                      },
                      expression: "personalDataAddress.state"
                    }
                  })
                ],
                1
              )
            ])
          ])
        : _vm.substep === "versandadresse"
        ? _c("Versandadresse", { key: "privat-versandadresse" })
        : _vm.substep === "anschriftfeld"
        ? _c("Anschriftfeld", { key: "privat-anschriftfeld" })
        : _vm.substep === "geburtstag"
        ? _c(
            "div",
            { key: "privat-geburtstag", staticClass: "box__container" },
            [
              _c("div", { staticClass: "box__title" }, [
                _vm._v("Geburtstag und Staatsangehörigkeit")
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("DatePickerField", {
                      attrs: {
                        label: "Geburtsdatum",
                        labelClass: _vm.depoteroeffnungenLabelClass,
                        isValueAsString: "",
                        emptyValueAsNull: "",
                        isComponentHalfSize: true,
                        disabled: !_vm.isEditable
                      },
                      on: {
                        input: function($event) {
                          return _vm.addCustomerDataEdited("personalDataBirth")
                        }
                      },
                      model: {
                        value: _vm.personalDataBirth.dayOfBirth,
                        callback: function($$v) {
                          _vm.$set(_vm.personalDataBirth, "dayOfBirth", $$v)
                        },
                        expression: "personalDataBirth.dayOfBirth"
                      }
                    }),
                    _c("InputField", {
                      attrs: {
                        label: "Geburtsort",
                        labelClass: _vm.depoteroeffnungenLabelClass,
                        isComponentHalfSize: true,
                        disabled: !_vm.isEditable
                      },
                      on: {
                        change: function($event) {
                          return _vm.addCustomerDataEdited("personalDataBirth")
                        }
                      },
                      model: {
                        value: _vm.personalDataBirth.placeOfBirth,
                        callback: function($$v) {
                          _vm.$set(_vm.personalDataBirth, "placeOfBirth", $$v)
                        },
                        expression: "personalDataBirth.placeOfBirth"
                      }
                    }),
                    _c("ComboBox", {
                      attrs: {
                        label: "Geburtsland",
                        labelClass: _vm.depoteroeffnungenLabelClass,
                        isComponentHalfSize: true,
                        values: _vm.customerDataConfig.countries,
                        sortComboboxValues: false,
                        disabled: !_vm.isEditable,
                        firstEmpty: !_vm.personalDataBirth.countryOfBirth
                      },
                      on: {
                        change: function($event) {
                          return _vm.addCustomerDataEditedCombo(
                            "personalDataBirth",
                            "countryOfBirth",
                            $event
                          )
                        }
                      },
                      model: {
                        value: _vm.personalDataBirth.countryOfBirth,
                        callback: function($$v) {
                          _vm.$set(_vm.personalDataBirth, "countryOfBirth", $$v)
                        },
                        expression: "personalDataBirth.countryOfBirth"
                      }
                    }),
                    _c("ComboBox", {
                      attrs: {
                        label: "Staatsangehörigkeit",
                        labelClass: _vm.depoteroeffnungenLabelClass,
                        isComponentHalfSize: true,
                        values: _vm.customerDataConfig.nationalities,
                        sortComboboxValues: false,
                        disabled: !_vm.isEditable,
                        firstEmpty: !_vm.personalDataBirth.nationality
                      },
                      on: {
                        change: function($event) {
                          return _vm.addCustomerDataEditedCombo(
                            "personalDataBirth",
                            "nationality",
                            $event
                          )
                        }
                      },
                      model: {
                        value: _vm.personalDataBirth.nationality,
                        callback: function($$v) {
                          _vm.$set(_vm.personalDataBirth, "nationality", $$v)
                        },
                        expression: "personalDataBirth.nationality"
                      }
                    }),
                    _c("InputField", {
                      attrs: {
                        label: "Geburtsname",
                        labelClass: _vm.depoteroeffnungenLabelClass,
                        isComponentHalfSize: true,
                        disabled: !_vm.isEditable
                      },
                      on: {
                        change: function($event) {
                          return _vm.addCustomerDataEdited("personalDataBirth")
                        }
                      },
                      model: {
                        value: _vm.personalDataBirth.nameAtBirth,
                        callback: function($$v) {
                          _vm.$set(_vm.personalDataBirth, "nameAtBirth", $$v)
                        },
                        expression: "personalDataBirth.nameAtBirth"
                      }
                    })
                  ],
                  1
                )
              ])
            ]
          )
        : _vm.substep === "beruf"
        ? _c("PrivatBeruf", { key: "privat-beruf" })
        : _vm.substep === "legitimationsurkunde"
        ? _c(
            "div",
            { key: "privat-legitimationsurkunde" },
            [
              _c("div", { staticClass: "box__container" }, [
                _c("div", { staticClass: "box__title" }, [
                  _vm._v("Legitimationsurkunde")
                ])
              ]),
              _c("LegitimationImages", {
                attrs: {
                  legitimation: _vm.legitimation,
                  disabled: _vm.isSavingCustomerData
                },
                on: {
                  "read-image-data": function($event) {
                    return _vm.setReadImageData($event)
                  },
                  addCustomerDataEdited: function($event) {
                    return _vm.addCustomerDataEdited("legitimation")
                  }
                }
              }),
              _c("div", { staticClass: "box__container" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("ComboBox", {
                        attrs: {
                          label: "Art der Urkunde",
                          labelClass: _vm.depoteroeffnungenLabelClass,
                          isComponentHalfSize: true,
                          values: _vm.customerDataConfig.legitimationTypes,
                          value: _vm.legitimation.legitimType,
                          disabled: !_vm.isLegitimationEditable,
                          firstEmpty: !_vm.hasValidComboValue(
                            "legitimation",
                            "legitimType",
                            _vm.customerDataConfig.legitimationTypes
                          )
                        },
                        on: {
                          change: function($event) {
                            return _vm.onLegitimTypeChange($event)
                          }
                        }
                      }),
                      _c("InputField", {
                        attrs: {
                          label: "Ausweisnummer",
                          labelClass: _vm.depoteroeffnungenLabelClass,
                          isComponentHalfSize: true,
                          disabled:
                            !_vm.isLegitimationEditable || !_vm.hasLegitimType
                        },
                        on: {
                          change: function($event) {
                            return _vm.addCustomerDataEdited("legitimation")
                          }
                        },
                        model: {
                          value: _vm.legitimation.legitimNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.legitimation, "legitimNumber", $$v)
                          },
                          expression: "legitimation.legitimNumber"
                        }
                      }),
                      _c("InputField", {
                        attrs: {
                          label: "Ausstellungsbehörde",
                          labelClass: _vm.depoteroeffnungenLabelClass,
                          isComponentHalfSize: true,
                          disabled:
                            !_vm.isLegitimationEditable || !_vm.hasLegitimType
                        },
                        on: {
                          change: function($event) {
                            return _vm.addCustomerDataEdited("legitimation")
                          }
                        },
                        model: {
                          value: _vm.legitimation.authority,
                          callback: function($$v) {
                            _vm.$set(_vm.legitimation, "authority", $$v)
                          },
                          expression: "legitimation.authority"
                        }
                      }),
                      _c("DatePickerField", {
                        attrs: {
                          label: "Ausstellungsdatum",
                          labelClass: _vm.depoteroeffnungenLabelClass,
                          isValueAsString: "",
                          isComponentHalfSize: true,
                          disabled:
                            !_vm.isLegitimationEditable || !_vm.hasLegitimType,
                          validateUntouched: ""
                        },
                        on: {
                          input: function($event) {
                            _vm.addCustomerDataEdited("legitimation")
                            _vm.handleDateOfIssueChange($event)
                          }
                        },
                        model: {
                          value: _vm.legitimation.dateOfIssue,
                          callback: function($$v) {
                            _vm.$set(_vm.legitimation, "dateOfIssue", $$v)
                          },
                          expression: "legitimation.dateOfIssue"
                        }
                      }),
                      _vm.legitimation.legitimType !== "Geburtsurkunde"
                        ? _c("DatePickerField", {
                            attrs: {
                              label: "Gültig bis",
                              labelClass: _vm.depoteroeffnungenLabelClass,
                              isValueAsString: "",
                              isComponentHalfSize: true,
                              disabled:
                                !_vm.isLegitimationEditable ||
                                !_vm.hasLegitimType,
                              validateUntouched: ""
                            },
                            on: {
                              input: function($event) {
                                return _vm.addCustomerDataEdited("legitimation")
                              }
                            },
                            model: {
                              value: _vm.legitimation.validTo,
                              callback: function($$v) {
                                _vm.$set(_vm.legitimation, "validTo", $$v)
                              },
                              expression: "legitimation.validTo"
                            }
                          })
                        : _vm._e(),
                      _c("DataConflictModal", {
                        ref: "dataConflictModal",
                        on: {
                          confirm: function($event) {
                            return _vm.confirmDataConflict($event)
                          },
                          cancel: function($event) {
                            return _vm.resetLegitimation()
                          }
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ],
            1
          )
        : _vm.substep === "steuerdaten"
        ? _c(
            "div",
            { key: "privat-steuerdaten", staticClass: "box__container" },
            [
              _c("div", { staticClass: "box__title" }, [_vm._v("Steuerdaten")]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("ComboBox", {
                      attrs: {
                        label: "Steuerlich in",
                        labelClass: _vm.depoteroeffnungenLabelClass,
                        isComponentHalfSize: true,
                        values: _vm.steuerlichInCountries,
                        disabled: !_vm.isEditable,
                        sortComboboxValues: false,
                        firstEmpty: !_vm.hasValidComboValue(
                          "personalDataTax",
                          "taxResidence",
                          _vm.steuerlichInCountries
                        )
                      },
                      on: {
                        change: function($event) {
                          return _vm.addCustomerDataEditedCombo(
                            "personalDataTax",
                            "taxResidence",
                            $event
                          )
                        }
                      },
                      model: {
                        value: _vm.personalDataTax.taxResidence,
                        callback: function($$v) {
                          _vm.$set(_vm.personalDataTax, "taxResidence", $$v)
                        },
                        expression: "personalDataTax.taxResidence"
                      }
                    }),
                    _c("ComboBox", {
                      attrs: {
                        label: "Güterstand",
                        labelClass: _vm.depoteroeffnungenLabelClass,
                        isComponentHalfSize: true,
                        values: _vm.customerDataConfig.maritalStates,
                        disabled: !_vm.isEditable,
                        firstEmpty: !_vm.personalDataTax.maritalPropertyRegime
                      },
                      on: {
                        change: function($event) {
                          return _vm.addCustomerDataEditedCombo(
                            "personalDataTax",
                            "maritalPropertyRegime",
                            $event
                          )
                        }
                      },
                      model: {
                        value: _vm.personalDataTax.maritalPropertyRegime,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.personalDataTax,
                            "maritalPropertyRegime",
                            $$v
                          )
                        },
                        expression: "personalDataTax.maritalPropertyRegime"
                      }
                    }),
                    _c("InputField", {
                      attrs: {
                        label: "Sozialversicherungsnummer",
                        isComponentHalfSize: true,
                        disabled: !_vm.isEditable
                      },
                      on: {
                        change: function($event) {
                          return _vm.addCustomerDataEdited("personalDataTax")
                        }
                      },
                      model: {
                        value: _vm.personalDataTax.socialInsuranceNumber,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.personalDataTax,
                            "socialInsuranceNumber",
                            $$v
                          )
                        },
                        expression: "personalDataTax.socialInsuranceNumber"
                      }
                    }),
                    _c("InputField", {
                      attrs: {
                        label: "SteuerIdentNummer TIN/LEI",
                        isComponentHalfSize: true,
                        disabled: !_vm.isEditable
                      },
                      on: {
                        change: function($event) {
                          return _vm.addCustomerDataEdited("personalDataTax")
                        }
                      },
                      model: {
                        value: _vm.personalDataTax.taxID,
                        callback: function($$v) {
                          _vm.$set(_vm.personalDataTax, "taxID", $$v)
                        },
                        expression: "personalDataTax.taxID"
                      }
                    }),
                    _c("InputField", {
                      attrs: {
                        label: "Steuerklasse",
                        isComponentHalfSize: true,
                        disabled: !_vm.isEditable
                      },
                      on: {
                        change: function($event) {
                          return _vm.addCustomerDataEdited("personalDataTax")
                        }
                      },
                      model: {
                        value: _vm.personalDataTax.taxClass,
                        callback: function($$v) {
                          _vm.$set(_vm.personalDataTax, "taxClass", $$v)
                        },
                        expression: "personalDataTax.taxClass"
                      }
                    }),
                    _c("InputField", {
                      attrs: {
                        label: "Finanzamt",
                        isComponentHalfSize: true,
                        disabled: !_vm.isEditable
                      },
                      on: {
                        change: function($event) {
                          return _vm.addCustomerDataEdited("personalDataTax")
                        }
                      },
                      model: {
                        value: _vm.personalDataTax.taxOffice,
                        callback: function($$v) {
                          _vm.$set(_vm.personalDataTax, "taxOffice", $$v)
                        },
                        expression: "personalDataTax.taxOffice"
                      }
                    }),
                    _c("InputField", {
                      attrs: {
                        label: "Steuernummer",
                        isComponentHalfSize: true,
                        disabled: !_vm.isEditable
                      },
                      on: {
                        change: function($event) {
                          return _vm.addCustomerDataEdited("personalDataTax")
                        }
                      },
                      model: {
                        value: _vm.personalDataTax.taxNr,
                        callback: function($$v) {
                          _vm.$set(_vm.personalDataTax, "taxNr", $$v)
                        },
                        expression: "personalDataTax.taxNr"
                      }
                    }),
                    _c("InputField", {
                      attrs: {
                        label: "Kindergeldnummer",
                        isComponentHalfSize: true,
                        disabled: !_vm.isEditable
                      },
                      on: {
                        change: function($event) {
                          return _vm.addCustomerDataEdited("personalDataTax")
                        }
                      },
                      model: {
                        value: _vm.personalDataTax.childrenBenefitsNr,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.personalDataTax,
                            "childrenBenefitsNr",
                            $$v
                          )
                        },
                        expression: "personalDataTax.childrenBenefitsNr"
                      }
                    }),
                    _c("InputField", {
                      attrs: {
                        label: "Familienkasse",
                        isComponentHalfSize: true,
                        disabled: !_vm.isEditable
                      },
                      on: {
                        change: function($event) {
                          return _vm.addCustomerDataEdited("personalDataTax")
                        }
                      },
                      model: {
                        value: _vm.personalDataTax.familyBenefitsOffice,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.personalDataTax,
                            "familyBenefitsOffice",
                            $$v
                          )
                        },
                        expression: "personalDataTax.familyBenefitsOffice"
                      }
                    }),
                    _c("InputToggleSwitch", {
                      attrs: {
                        label: "FATCA - Foreign Account Tax Compliance Act",
                        inLineLabel: true,
                        disabled: !_vm.isEditable
                      },
                      on: {
                        input: function($event) {
                          return _vm.addCustomerDataEdited("fatca")
                        }
                      },
                      model: {
                        value: _vm.fatca.aktiv,
                        callback: function($$v) {
                          _vm.$set(_vm.fatca, "aktiv", $$v)
                        },
                        expression: "fatca.aktiv"
                      }
                    }),
                    _vm.fatca.aktiv
                      ? [
                          _c("InputField", {
                            attrs: {
                              label: "Art des USA-Bezugs",
                              isComponentHalfSize: "",
                              disabled: !_vm.isEditable
                            },
                            on: {
                              input: function($event) {
                                return _vm.addCustomerDataEdited("fatca")
                              }
                            },
                            model: {
                              value: _vm.fatca.text,
                              callback: function($$v) {
                                _vm.$set(_vm.fatca, "text", $$v)
                              },
                              expression: "fatca.text"
                            }
                          })
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }