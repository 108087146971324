export const MUTATION_PREFIX = 'GELDWAESCHEBELEHRUNG_MUTATIONS_';
export const ACTIONS_PREFIX = 'GELDWAESCHEBELEHRUNG_ACTIONS_';
export const GETTERS_PREFIX = 'GELDWAESCHEBELEHRUNG_GETTERS_';

export default {
  MUTATIONS: {
    GET_LAYOUT_METADATA_SUCCESS: MUTATION_PREFIX + 'GET_LAYOUT_METADATA_SUCCESS',
    GET_WAG_PRUEFUNG_LINK_SUCCESS: MUTATION_PREFIX + 'GET_WAG_PRUEFUNG_LINK_SUCCESS',
  },
  ACTIONS: {
    GET_LAYOUT_METADATA: ACTIONS_PREFIX + 'GET_LAYOUT_METADATA',
    GET_WAG_PRUEFUNG_LINK: ACTIONS_PREFIX + 'GET_WAG_PRUEFUNG_LINK',
  },
  GETTERS: {
    LAYOUT_METADATA: GETTERS_PREFIX + 'LAYOUT_METADATA',
    WAG_PRUEFUNG_LINK: GETTERS_PREFIX + 'WAG_PRUEFUNG_LINK',
  }
}