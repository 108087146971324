<template>
  <div>
    <InputRadioBoxGroup 
      v-model="riskLevel" 
      :validateUntouched="true"
      :disabled="isDisabled || isRefusedPage"
      :values="values"
      @input="updateStore('risikoneigung', 'riskLevel', $event)"/>
    <hr>
    <InputTextArea
      label="Beschreibung der Anlageziele / Anlagedauer / Verlusttragfähigkeit und Risikoneigung mit eigenen Worten falls vom Kunden gewünscht und obige Fallgruppen nicht ausreichend sind"
      v-model="annotationRisikoneigung"
      :disabled="isDisabled || isRefusedPage"
      isComponentHalfSize
      @change="updateStore('risikoneigung', 'annotationRisikoneigung', $event)">
    </InputTextArea>
  </div>
</template>

<script>

import InputField from '@/components/core/forms/InputField.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import { mapGetters } from 'vuex';
import ANLEGERPROFIL_TYPES from '@/store/anlegerprofil/types';
import anlegerprofilMixin from '@/mixins/anlegerprofil/anlegerprofil-mixin.js';
import validator from "@/mixins/validator";

export default {
  mixins: [anlegerprofilMixin, validator],
  validators: {},
  components: {
    InputField,
    InputRadioBoxGroup,
    InputTextArea,
  },
  computed: {
    ...mapGetters({
      data: ANLEGERPROFIL_TYPES.GETTERS.ANLEGERPROFIL,
    }),
    isRefusedPage() {
      return this._isRefusedPage(this.data, 'risikoneigung');
    },
  },
  mounted() {
    this.createForm();
     this.updateWarnings();
  },
  data() {
    return {
      values: [],
      selected: {},
      riskLevel: '',
      annotationRisikoneigung: '',
    };
  },
  methods: {
    createForm() {
      if (this.data && this.data.risikoneigung) {
        this.values = this.processValues(this.data.risikoneigung.values);
        this.riskLevel = this.data.risikoneigung.riskLevel;
        this.annotationRisikoneigung = this.data.risikoneigung.annotationRisikoneigung;
      }
    },
    processValues(values){
      return values?.map(value => {
          value.label = value.label.replace(/\*/g, '');
          return value;
        });
    },
  },
}
</script>

<style scoped>
</style>