<template>
  <div>
    <PageHeaderActionCard 
      title="Spartentypen bearbeiten">
    </PageHeaderActionCard>

    <div class="box__container">
      <div class="box__title">Spartentypen Liste</div>

      <InputField 
          isComponentHalfSize
          placeholder="Suchen nach Bezeichnung"
          v-model="descriptionSearch"/>

      <Table
         v-if="!loading && tableRows.length"
         rowId="sparteId"
        :headers="tableHeaders"
        :rows="tableRows"
        @click-bezeichnung="openEditConfig"
      />
      <GhostLoading  v-else type="table" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SPARTENTYP_EINSTELLUNG_TYPES from '@/store/spartentypeinstellung/types';
import PageHeaderActionCard from '@/components/core/PageHeaderActionCard.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import Table from '@/components/table2/Table.vue';
import { TextColumn, } from "@/components/table2/table_util.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import InputField from '@/components/core/forms/InputField.vue'

export default {
  data() {
    return {
      tableHeaders: {
        lockedLeft: [
          TextColumn('sparteId', 'Id').makeHidden(),
          TextColumn('bezeichnung', 'Bezeichnung').makeLink().makeAlwaysVisible(),
          TextColumn('bezeichnung_kurz', 'Bezeichnung kurz').makeHidden(),
        ],
        center: [
        ],
        lockedRight: [
          // ActionColumn('actions', 'Aktionen'),
        ]
      },
      selectedConfig: null,
      loading: false,
      descriptionSearch: "",
    };
  },
  computed: {
    ...mapGetters({
      spartenTypen: SPARTENTYP_EINSTELLUNG_TYPES.GETTERS.SPARTENTYP_LIST
    }),
    tableRows() {
      const rows = this.spartenTypen || [];
      return this.descriptionSearch?.length ? rows.filter(s => s?.bezeichnung.search(new RegExp(this.descriptionSearch, "i"))  > -1) : rows;

    },
  },
  methods: {
    loadSpartentypList() {
      this.loading = true;

      this.$store.dispatch(SPARTENTYP_EINSTELLUNG_TYPES.ACTIONS.LOAD_SPARTENTYP_LIST).then(() => {
          this.loading = false;
      });

    },
    openEditConfig(config) {
      this.selectedConfig = { ...config, };
      this.$store.commit(SPARTENTYP_EINSTELLUNG_TYPES.MUTATIONS.SELECT_SPARTENTYP, this.selectedConfig);
      this.$router.push(`spartentypBearbeiten/${config.sparteId}`);
    },
  },
  mounted() {
    this.loadSpartentypList();
  },
  beforeRouteLeave (to, from, next) {
    this.$addBreadcrumb({
      label: `zurück zu den Spartentyp Einstellungen`,
      breadcrumb: this.selectedConfig?.label,
      to,
      from,
    });

    next()
  },
  components: {
    PageHeaderActionCard,
    BaseButton,
    Table,
    GhostLoading,
    InputField,
  }
}
</script>

<style scoped>

</style>