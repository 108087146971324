var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "antrag-components__container" }, [
      _c("div", { staticClass: "row my-0" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          _vm._l(_vm.listOfComponentsByDivider, function(
            componentsByDivider,
            index
          ) {
            return _c("div", { key: index }, [
              _c(
                "div",
                { staticClass: "box__container" },
                [
                  _vm._l(componentsByDivider, function(component, index) {
                    return [
                      !_vm.isComponentHidden(component)
                        ? _c(
                            "div",
                            {
                              key: index + component.id,
                              class: {
                                indented:
                                  component.config && component.config.indented,
                                indented2:
                                  component.config && component.config.indented2
                              }
                            },
                            [
                              _c("AntragComponent", {
                                ref: component.id,
                                refInFor: true,
                                attrs: {
                                  component: component,
                                  components: _vm.allComponents,
                                  antragId: _vm.antragId,
                                  data: _vm.antragData,
                                  comboboxSelection: _vm.getComboboxSelection(
                                    component
                                  ),
                                  multiSelectionsTabledata: _vm.getMultiSelectionsTabledata(
                                    component
                                  ),
                                  isComponentHalfSizeEnabled: ""
                                },
                                on: {
                                  updateStore: function($event) {
                                    return _vm.updateAntragData($event)
                                  },
                                  change: _vm.changeEvent
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  })
                ],
                2
              )
            ])
          }),
          0
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }