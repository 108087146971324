<template>
  <div class="box__container">
    <div class="box__title">Jahrescheckliste Status</div>
    <FormDivider />
    <InputToggleSwitch
      label="Status OK"
      :inLineLabel="true"
      isComponentHalfSize
      :value="form.INPUT_STATUS_OK"
      @input="fieldChanged($event, 'INPUT_STATUS_OK')"
      :disabled="form.INPUT_STATUS_FEHLER"
    />
    <DatePickerField
      label="Datum"
      :value="form.INPUT_STATUS_OK_DATUM"
      isValueAsString
      isComponentHalfSize
      :disabled="!form.INPUT_STATUS_OK"
      @change="fieldChanged($event, 'INPUT_STATUS_OK_DATUM')"
    />

    <InputToggleSwitch
      label="Status fehlerhaft"
      :inLineLabel="true"
      isComponentHalfSize
      :value="form.INPUT_STATUS_FEHLER"
      @input="fieldChanged($event, 'INPUT_STATUS_FEHLER')"
      :disabled="form.INPUT_STATUS_OK"
    />
    <InputField
      :value="form.INPUT_STATUS_FEHLER_TEXT"
      label="Notizen:"
      isComponentHalfSize      
      @change="fieldChanged($event, 'INPUT_STATUS_FEHLER_TEXT')"
    />
  </div>
</template>
<script>
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import FormLabel from "@/components/core/forms/FormLabel.vue";
import FormDivider from "@/components/core/forms/FormDivider.vue";
import InputField from "@/components/core/forms/InputField.vue";
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
export default {
  components: {
    InputToggleSwitch,
    FormLabel,
    FormDivider,
    InputField,
    DatePickerField,
  },
  props: {
    form: {},
  },
  data() {
    return {};
  },
  methods: {
    fieldChanged(newValue, propName) {
      const payload = { name: propName, value: newValue };
      this.$emit("fieldChanged", payload);
    },
  },
};
</script>