var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.antraegeEmail && !_vm.antraegePdf
      ? _c("div", { staticClass: "box__container box__title pt-3 pb-4" }, [
          _vm.hasActivePositions
            ? _c("div", [
                _vm._v(
                  'Bitte bestätigen Sie den Versand mit dem Button "Anträge anzeigen bzw. versenden.'
                )
              ])
            : _c("div", [_vm._v("Bitte wählen Sie eine Position aus.")])
        ])
      : _c(
          "div",
          { staticClass: "box__container" },
          [
            _vm.antraegeEmail && _vm.antraegeEmail.length
              ? _c("Table", {
                  attrs: {
                    title:
                      "Die folgenden Aufträge wurden per E-Mail an den jeweiligen Kunden gesendet:",
                    headers: _vm.headers,
                    rows: _vm.rowsEmail,
                    rowId: "posGrpId",
                    hidePagination: ""
                  },
                  on: { "click-kundenname": _vm.openCustomerNewTab }
                })
              : _c("NoData", {
                  attrs: {
                    title:
                      "Die folgenden Aufträge wurden per E-Mail an den jeweiligen Kunden gesendet:",
                    noIcon: ""
                  }
                }),
            _c(
              "div",
              { staticClass: "my-5" },
              [
                _vm.antraegePdf && _vm.antraegePdf.length
                  ? _c("Table", {
                      attrs: {
                        title:
                          "Die folgenden Aufträge wurden als PDF-Datei erzeugt:",
                        headers: _vm.headers,
                        rows: _vm.rowsPdf,
                        rowId: "posGrpId",
                        hidePagination: ""
                      },
                      on: { "click-kundenname": _vm.openCustomerNewTab }
                    })
                  : _c("NoData", {
                      attrs: {
                        title:
                          "Die folgenden Aufträge wurden als PDF-Datei erzeugt:",
                        noIcon: ""
                      }
                    })
              ],
              1
            )
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }