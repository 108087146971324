<template>
  <div>
    <BaseFilter 
      ref="baseFilter"
      showSaveButton
      :hasSmartSearch="hasSmartSearch"
      :filterId="saveKey"
      :title="title"
      :metadata="metadata"
      :configFilter="configFilter"
      :extraButton="extraButton"
      :predefinedFilter="predefinedFilter"
      :showButtons="showButtons"
      :immidiateSearch="immidiateSearch"
      :isCustomerSearch="isCustomerSearch"
      :defaultOptions="defaultOptions"
      :searchFromParam="searchFromParam"
      :showSearchButton="showSearchButton"
      :isCache="isCache"
      @onFilter="filterForm($event)"
      @extraButtonClick="$emit('extraButtonClick')"
      @changeCombobox="onComboboxChange"
      @onFilterZurucksetzen="onFilterZurucksetzen()"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  /**
   * @deprecated don't use this function. 
   * 
   * Download files should be handled by DownloadLink.vue component due to the native stack.
   * 
   * @param {*} fileName 
   * @param {*} byteArr 
   * @param {*} contentType 
   */
  export function downloadFile(fileName, response, contentType = 'text/csv;charset=utf-8;') {
    const options = { type: contentType };
    const blob = new File([response], fileName, options);

    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    link.setAttribute('href', url);
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
      link.setAttribute('download', fileName);
    } else {
      link.target = '_blank';
    }
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    setTimeout(() => document.body.removeChild(link), 0);
  }

  export default {
    props: {
      title: {
        type: String,
      },
      saveKey: {
        type: String,
      },
      metadata: {
        type: Array,
        default: () => [],
      },
      predefinedFilter: {
        type: Object,
        default: () => { },
      },
      configFilter: {
        type: Object,
        default: () => { },
      },
      showButtons: {
        type: Boolean,
        default: true,
      },
      extraButton: {
        type: Object,
        default: () => (null)
      },
      isCustomerSearch: {
        type: Boolean,
        default: true,
      },
      filterId: {
        type: String
      },
      defaultOptions: {
        type: Object,
        default: () => ({})
      },
      searchFromParam: {
        type: String,
      },
      immidiateSearch: {
        type: Boolean,
        default: false,
      },
      hasSmartSearch: {
        type: Boolean,
        default: true,
      },
      showSearchButton: {
        type: Boolean,
        default: true,
      },
      isCache: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['search'],
    data() {
      return {
      };
    },
    computed: {
      ...mapGetters({
      }),
    },
    methods: {
      filterForm(filterConfig) {
        let params = {};

        let fremdGesellschaftList = [];
        let vertragGesellschaftList = [];

        filterConfig.forEach(fc => {
          if (typeof fc.value === "boolean") { // is it a combobox with type "default"?

            if (fc.group == 'fremdGesellschaft') {
              fremdGesellschaftList.push(fc.key);
              params[fc.group] = fremdGesellschaftList;


            } else if (fc.group == 'vertragGesellschaft') {
              vertragGesellschaftList.push(fc.key);
              params[fc.group] = vertragGesellschaftList;

            } else {
              params[fc.key] = fc.value;
            }

          } else {

            const pers_daten = filterConfig.find(fc => fc.group == "pers_daten");
            const adresse_geburtsdatum = filterConfig.find(fc => fc.group == "Adresse_Geburtsdatum");
            const adresse = filterConfig.find(fc => fc.group == "adresse");
            const courtage = filterConfig.find(fc => fc.group == "courtage");
            const vertragFK = filterConfig.find(fc => fc.group == "vertragFK");
            const vertragAK = filterConfig.find(fc => fc.group == "vertragAK");
            const diverses1 = filterConfig.find(fc => fc.group == "diverses1");
            const diverses2 = filterConfig.find(fc => fc.group == "diverses2");
            const versicherungen = filterConfig.find(fc => fc.group == "Versicherungen");
            const depot = filterConfig.find(fc => fc.group == "Depot");
            const allgemein = filterConfig.find(fc => fc.group == "allgemein"); 
            const zusaetzliche_Kriterien = filterConfig.filter(fc => fc.group == "Zusätzliche_Kriterien"); 


            if (allgemein && fc.key =='dateCreated') {
              params["dateCreatedMin"] = fc.value.find(v => v.key == 'min').value
              params["dateCreatedMax"] = fc.value.find(v => v.key == 'max').value;
            } else if (pers_daten && fc.key == 'alter') {
              params["alterMin"] = fc.value.find(v => v.key == 'min').value
              params["alterMax"] = fc.value.find(v => v.key == 'max').value;
            } else if (pers_daten && fc.key == 'gehalt') {
              params["gehaltMin"] = fc.value.find(v => v.key == 'min').value;
              params["gehaltMax"] = fc.value.find(v => v.key == 'max').value;
            } else if ((adresse || adresse_geburtsdatum) && fc.key == 'plzVonBis') {
              params["plzBis"] = fc.value.find(v => v.key == 'max').value;
              params["plzVon"] = fc.value.find(v => v.key == 'min').value;
            } else if (adresse_geburtsdatum && fc.key === 'dayOfBirth') {
              params["dayOfBirth"] = fc.value?.replaceAll(/t|m|j|%/g, '_');
            } else if (adresse_geburtsdatum && fc.key === 'dayOfBirthVonBis') {
              params["dayOfBirthBis"] = fc.value.find(v => v.key == 'max').value;
              params["dayOfBirthVon"] = fc.value.find(v => v.key == 'min').value;
            } else if (courtage && fc.key == 'courtageAbschlussWpVonBis') {
              params["courtageAbschlussWp"] = fc.value.find(v => v.key == 'min').value;
              params["courtageAbschlussWpBis"] = fc.value.find(v => v.key == 'max').value;
            } else if (courtage && fc.key == 'courtageBestandVonBis') {
              params["courtageBestand"] = fc.value.find(v => v.key == 'min').value;
              params["courtageBestandBis"] = fc.value.find(v => v.key == 'max').value;
            } else if (courtage && fc.key == 'courtageVWLVonBis') {
              params["courtageVWL"] = fc.value.find(v => v.key == 'min').value;
              params["courtageVWLBis"] = fc.value.find(v => v.key == 'max').value;
            } else if (courtage && fc.key == 'courtageAbschlussVersVonBis') {
              params["courtageAbschlussVers"] = fc.value.find(v => v.key == 'min').value;
              params["courtageAbschlussVersBis"] = fc.value.find(v => v.key == 'max').value;
            } else if (courtage && fc.key == 'courtageBestandVersVonBis') {
              params["courtageBestandVers"] = fc.value.find(v => v.key == 'min').value;
              params["courtageBestandVersBis"] = fc.value.find(v => v.key == 'max').value;
            } else if (vertragFK && fc.key == 'vertragVonBis') {
              params["vertragVon"] = fc.value.find(v => v.key == 'min').value;
              params["vertragBis"] = fc.value.find(v => v.key == 'max').value;
            } else if (vertragFK && fc.key == 'vertragZuruekVonBis') {
                params["vertragZuruekVon"] = fc.value.find(v => v.key == 'min').value;
                params["vertragZuruekBis"] = fc.value.find(v => v.key == 'max').value;
            } else if (vertragAK && fc.key == 'vertragVonBisAK') {
              params["vertragVonAK"] = fc.value.find(v => v.key == 'min').value;
              params["vertragBisAK"] = fc.value.find(v => v.key == 'max').value;
            } else if (vertragFK && fc.key == 'vertragZuruekVonBisAK') {
              params["vertragZuruekVonAK"] = fc.value.find(v => v.key == 'min').value;
              params["vertragZuruekBisAK"] = fc.value.find(v => v.key == 'max').value;
            } else if (diverses1 && fc.key == 'uebertragskontoVonBis') {
              params["uebertragskontoVon"] = fc.value.find(v => v.key == 'min').value;
              params["uebertragskontoBis"] = fc.value.find(v => v.key == 'max').value;
            } else if (diverses2 && fc.key == 'besuchtsberichtVonBis') {
              params["besuchtsberichtVon"] = fc.value.find(v => v.key == 'min').value;
              params["besuchtsberichtBis"] = fc.value.find(v => v.key == 'max').value;
            } else if (versicherungen && fc.key == 'Vertragsbegin') {
                params["vertragsbeginMin"] = fc.value.find(v => v.key == 'min').value;
                params["vertragsbeginMax"] = fc.value.find(v => v.key == 'max').value;              
            } else if (versicherungen && fc.key == 'Vertragsende') {
                params["vertragsendeMin"] = fc.value.find(v => v.key == 'min').value;
                params["vertragsendeMax"] = fc.value.find(v => v.key == 'max').value;
            } else if (versicherungen && fc.key == 'Gesamt') {
              params["gesamtMin"] = fc.value.find(v => v.key == 'min').value;
              params["gesamtMax"] = fc.value.find(v => v.key == 'max').value;
            } else if (depot && fc.key == 'Depotwert') {
              params["depotMin0"] = fc.value.find(v => v.key == 'min').value;
              params["depotMax0"] = fc.value.find(v => v.key == 'max').value;
            } else if (versicherungen && fc.key == 'Beteiligungen') {
              params["beteiligungenMin"] = fc.value.find(v => v.key == 'min').value;
              params["beteiligungenMax"] = fc.value.find(v => v.key == 'max').value;
            } else if (versicherungen && fc.key == 'Bankkonto') {
              params["bankkontoMin"] = fc.value.find(v => v.key == 'min').value;
              params["bankkontoMax"] = fc.value.find(v => v.key == 'max').value;
            } else if (versicherungen && fc.key == 'Jahresbeitrag') {
                params["jahresBetragMin"] = fc.value.find(v => v.key == 'min').value;
                params["jahresBetragMax"] = fc.value.find(v => v.key == 'max').value;
            } else if (versicherungen && fc.key == 'Kundbarzum') {
                params["vFaeligkeitAb"] = fc.value.find(v => v.key == 'min').value;
                params["vFaeligkeitBis"] = fc.value.find(v => v.key == 'max').value;            
            } else if (versicherungen && fc.key == 'vertragGesellschaft') {
                vertragGesellschaftList.push(fc.value);
                params[fc.key] = vertragGesellschaftList;
            } else if (versicherungen && fc.key == 'fremdGesellschaft') {
                vertragGesellschaftList.push(fc.value);
                params[fc.key] = vertragGesellschaftList;
            } else if(diverses1 && (fc.key == 'kategorie' || fc.key == 'potential')){
              if(params[fc.key]){
                params[fc.key] += ',' + fc.value; 
              }else{
                params[fc.key] = fc.value; 
              }
            } else if(fc.key == 'zustimmung'){
              params["zustimmGrund"] = fc.value?.[0]?.value || '';
              params["zustimmStatus"] = fc.value?.[1]?.value || '';
            } else if (fc.group === 'versSparten' && fc.value) {
              if (params[fc.group]) {
                params[fc.group].push(fc);
              } else {
                params[fc.group] = [fc];
              }
            } else if (fc.group === "Versicherungen" && fc.key  === 'vertragStatus') {
              if (params[fc.key]) {
                params[fc.key].push(fc.value);
              } else {
                params[fc.key] = [fc.value];
              }
            } else if (fc.group === "Depot" && fc.key  === 'vvWK') {
              if (params[fc.key]) {
                params[fc.key].push(fc.value);
              } else {
                params[fc.key] = [fc.value];
              }
            } else if (zusaetzliche_Kriterien && fc.key == 'zusInformationenFreitext') {  
                params['zusInformationenFreitext'] = zusaetzliche_Kriterien.filter(zc => zc.key === 'zusInformationenFreitext').map(zc => zc.value);
            } else if (fc.key) {

              // if the key was already added
              if (params[fc.key]) {

                if (Array.isArray(params[fc.key])) {
                  params[fc.key].push(fc.value);
                } else {
                  const previouslyAddedValue = params[fc.key];
                  params[fc.key] = [previouslyAddedValue, fc.value];
                }

              } else {
                params[fc.key] = fc.value; 
              }


              
            }
          }

          if (fc?.filterNot) {
            if (!params["filterNot"]) {
              params["filterNot"] = [];
            }
            params["filterNot"].push(fc.key);
          }
        });

        this.$emit("search", params);
      },

      createItem() {
        return {
          menuGroupKey: '',
          menuItemKey: '',
          menuItemValue: '',
          type: '',
          invertedWithParameter: '',
          menuItemsComboOptions: [],
          comboOptions: [],
          footnotes: [],
          emptyDenied: false
        };
      },
      onFilterZurucksetzen() {
        this.$emit("onFilterZurucksetzen"); 
      },
      onComboboxChange(row, index, value) {
        this.$emit("comboboxChange", row, index, value); 
      }
    },
    components: {
      BaseFilter: () => import('@/components/core/BaseFilter.vue'),
    },
  }
</script>