var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "data-name": "Layer 1",
        width: "869.75984",
        height: "627",
        viewBox: "0 0 869.75984 627",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M290.66466,675.59613v1.62362a10.15645,10.15645,0,0,0,10.14762,10.14762h86.05177a10.15136,10.15136,0,0,0,10.14762-10.14762v-1.62362a10.07475,10.07475,0,0,0-2.35457-6.49447,10.439,10.439,0,0,0-1.7128-1.62362H348.311a2.03032,2.03032,0,0,1-2.02952-2.02952H300.81228A10.1617,10.1617,0,0,0,290.66466,675.59613Z",
          transform: "translate(-165.12008 -136.5)",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "338.15939",
          y: "686.96423",
          width: "10.55352",
          height: "74.68644",
          transform: "translate(521.75222 1312.11491) rotate(-180)",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M308.12245,761.27993c0,1.14029,15.99221.44106,35.7196.44106s35.71961.69923,35.71961-.44106-15.99221-10.18277-35.71961-10.18277S308.12245,760.13964,308.12245,761.27993Z",
          transform: "translate(-165.12008 -136.5)",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M307.22549,673.88323l36.01175,5.74762,41.84067,6.68117,14.50713,2.31369a5.32616,5.32616,0,0,0,6.08857-4.41624l.65761-4.10776.60886-3.82364.503-3.14982a5.26591,5.26591,0,0,0-1.00644-4.02659,5.14013,5.14013,0,0,0-1.97284-1.62362H348.311a2.03032,2.03032,0,0,1-2.02952-2.02952v-1.59924a2.03032,2.03032,0,0,1,2.02952-2.02952h22.06512L360.204,660.19614l-31.27091-4.99265-18.25738-2.91437a5.33721,5.33721,0,0,0-6.10482,4.42437l-1.76989,11.073A5.3475,5.3475,0,0,0,307.22549,673.88323Z",
          transform: "translate(-165.12008 -136.5)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M385.07791,672.34082v61.86795a5.32506,5.32506,0,0,0,5.31719,5.31734h11.22739a5.32515,5.32515,0,0,0,5.31759-5.31734V672.34082a5.24847,5.24847,0,0,0-1.11228-3.23916,5.13161,5.13161,0,0,0-2.062-1.62362H388.24389a5.16017,5.16017,0,0,0-2.0537,1.62362A5.26351,5.26351,0,0,0,385.07791,672.34082Z",
          transform: "translate(-165.12008 -136.5)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M351.24156,567.62551a5.35719,5.35719,0,0,0,.42216,1.59929,5.24152,5.24152,0,0,0,1.51818,1.989l.02458.0162,32.034,25.986.47052.37345,6.63281,5.3823,2.005,1.62361H429.0373l-1.997-1.62361-27.41481-22.2436-18.37115-14.9048-14.29585-11.59266a5.26482,5.26482,0,0,0-2.93884-1.169.02563.02563,0,0,0-.01625-.00807,5.397,5.397,0,0,0-.95808.0162,5.11434,5.11434,0,0,0-1.38816.341,3.907,3.907,0,0,0-.86057.43835,5.12673,5.12673,0,0,0-1.32315,1.16089l-6.96539,8.58082-.10544.138A5.27755,5.27755,0,0,0,351.24156,567.62551Z",
          transform: "translate(-165.12008 -136.5)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M353.20648,571.23a13.16235,13.16235,0,0,0,2.58962,4.08342l21.32624,22.90923a5.31684,5.31684,0,0,0,7.52509-.33282l.593-.67381.25964-.29224,9.21411-10.33432,3.96154-4.44875a4.67257,4.67257,0,0,0,.68179-.90922c.09751-.16237.1867-.33282.268-.50332a5.385,5.385,0,0,0,.44634-2.411,5.2456,5.2456,0,0,0-1.74531-3.71l-24.72768-18.89894a13.244,13.244,0,0,0-9.57919-2.64651.02563.02563,0,0,0-.01625-.00807,13.922,13.922,0,0,0-2.2408.47081c-.32465.08934-.641.20295-.966.32474a13.08468,13.08468,0,0,0-5.39845,3.81552,13.25474,13.25474,0,0,0-2.19164,13.5653Z",
          transform: "translate(-165.12008 -136.5)",
          fill: "#2f2e41"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "177.96005",
          cy: "357.00504",
          r: "40.68954",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M332.24531,668.27365l15.0914,2.40292.3413.05683,22.94153,3.66127,14.45837,2.31369,21.25331,3.39335.60886.09742,3.15765.50331,14.50712,2.31364a5.326,5.326,0,0,0,6.08857-4.41624l1.26647-7.92327.50342-3.14982c0-.0162.00793-.03246.00793-.04871H348.311a2.03032,2.03032,0,0,1-2.02952-2.02952v-1.59924a2.03032,2.03032,0,0,1,2.02952-2.02952h26.59468v-8.87307l-1.62362-.25979-8.02058-1.28267-29.574-4.71661a5.3281,5.3281,0,0,0-6.09689,4.41624l-.65762,4.09963-1.11227,6.98159A5.33216,5.33216,0,0,0,332.24531,668.27365Z",
          transform: "translate(-165.12008 -136.5)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M287.70124,632.32671c0,33.17052,3.36933,53.01113,55.32512,47.32847.07293-.00808.13794-.0162.21088-.02433a154.26791,154.26791,0,0,0,27.3823-5.23618,81.32231,81.32231,0,0,0,13.50862-5.293c1.02269-.51952,2.005-1.06347,2.97135-1.62362H348.311a2.03032,2.03032,0,0,1-2.02952-2.02952v-1.59924a2.03032,2.03032,0,0,1,2.02952-2.02952h26.59468V610.54591a5.95919,5.95919,0,0,1,5.95063-5.95058h21.635c-.1871-.53582-.3734-1.07972-.57635-1.62361a132.99655,132.99655,0,0,0-7.20085-16.38231,124.96323,124.96323,0,0,0-13.45986-20.76609,86.48292,86.48292,0,0,0-12.04712-12.33138c-8.14267-6.72181-17.00756-10.87827-26.00247-10.87827C310.03432,542.61367,287.70124,599.1562,287.70124,632.32671Z",
          transform: "translate(-165.12008 -136.5)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M342.22524,508.586l-.04232-1.52056c2.82877-.0787,5.3227-.25615,7.19667-1.62525a4.67417,4.67417,0,0,0,1.81049-3.44279,2.67191,2.67191,0,0,0-.87647-2.20066c-1.244-1.05058-3.24567-.71053-4.70461-.04157l-1.2581.57689,2.41263-17.63122,1.50645.2064-2.05216,14.99771a5.36756,5.36756,0,0,1,5.07732.73058,4.15948,4.15948,0,0,1,1.41439,3.41532,6.18313,6.18313,0,0,1-2.433,4.61737C347.86868,508.4278,344.73958,508.51541,342.22524,508.586Z",
          transform: "translate(-165.12008 -136.5)",
          fill: "#2f2e41"
        }
      }),
      _c("rect", {
        attrs: {
          x: "190.60013",
          y: "347.70056",
          width: "8.18934",
          height: "1.52056",
          fill: "#2f2e41"
        }
      }),
      _c("rect", {
        attrs: {
          x: "164.75061",
          y: "347.70056",
          width: "8.18934",
          height: "1.52056",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M310.60645,482.32529c3.16454-4.7636,8.7328-6.78286,14.22368-7.27407,6.18043-.55289,12.15235.6389,18.18656,1.7968,6.641,1.27432,13.66936,2.4666,20.36092.85547a20.072,20.072,0,0,0,13.64417-11.02243,25.67539,25.67539,0,0,0,1.4949-17.12006,31.12057,31.12057,0,0,0-10.17862-15.4497c-9.63027-8.23605-22.66245-11.93748-35.19715-11.58883a59.50651,59.50651,0,0,0-34.49739,12.12989,56.03055,56.03055,0,0,0-19.87746,31.14994,58.41123,58.41123,0,0,0,2.82852,36.73978c2.743,6.36306,6.71,12.09956,9.77111,18.30239,2.85011,5.77525,5.04508,12.48987,3.27172,18.93157a17.5603,17.5603,0,0,1-11.86,12.20883,12.45452,12.45452,0,0,1-13.84973-5.654c-2.8671-4.521-2.93569-10.44254,1.3691-14.09282a10.56667,10.56667,0,0,1,15.2436,1.52108,10.87673,10.87673,0,0,1,2.17568,7.97469c-.21206,1.43584,1.98534,2.05548,2.19937.60634a12.86625,12.86625,0,0,0-23.98917-8.30777c-2.596,4.88095-1.19532,10.76817,2.10008,14.94034a14.562,14.562,0,0,0,14.84713,5.33895c5.99854-1.38535,10.90567-6.153,13.21335-11.75544,2.56912-6.23718,1.392-13.19743-1.11248-19.2498-2.70728-6.54245-6.72758-12.41007-9.90689-18.71474a53.29994,53.29994,0,0,1-5.40837-18.21088,57.62251,57.62251,0,0,1,8.21814-36.49907,53.55525,53.55525,0,0,1,29.4977-22.41835,55.96929,55.96929,0,0,1,37.03525.97927c11.58951,4.5664,22.78054,14.518,22.64616,27.88353-.05741,5.70932-2.17683,11.53172-6.55,15.33981-5.22629,4.55094-12.50323,4.94-19.07305,4.22973-6.61151-.71475-13.03186-2.64561-19.6719-3.13816-5.77543-.42842-12.00623.017-17.163,2.88635a17.16027,17.16027,0,0,0-5.96134,5.53021c-.81452,1.2261,1.16006,2.36954,1.96944,1.15118Z",
          transform: "translate(-165.12008 -136.5)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M317.081,581.91338a4.27857,4.27857,0,0,0,.2188.43024,4.61161,4.61161,0,0,0,.268.42215l17.79479,25.61257,1.38023,1.989L351.25782,631.247l14.0037,20.15719,5.66642,8.1668a4.73246,4.73246,0,0,0,.51174.62511,5.30208,5.30208,0,0,0,3.32017,1.62362h.14587V626.98505l-1.62362-2.338-18.20862-26.20522L335.51712,570.2964a5.32833,5.32833,0,0,0-7.40379-1.33951l-5.99938,4.16459-3.22306,2.2406A5.31729,5.31729,0,0,0,317.081,581.91338Z",
          transform: "translate(-165.12008 -136.5)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M314.29632,581.28828l.80348,1.61549.00833.00812.2192.43029L328.373,609.23886a5.33614,5.33614,0,0,0,7.31461,1.786l.84431-.5277.21088-.12986,16.82086-10.55352a5.315,5.315,0,0,0,1.76949-7.33874l-18.47658-25.05247a13.28029,13.28029,0,0,0-16.21993-4.18891,13.0593,13.0593,0,0,0-1.8024.99038A13.28359,13.28359,0,0,0,314.29632,581.28828Z",
          transform: "translate(-165.12008 -136.5)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M410.09773,667.478v61.12115a5.32515,5.32515,0,0,0,5.31759,5.31734H426.6427a5.32506,5.32506,0,0,0,5.31719-5.31734V667.478Z",
          transform: "translate(-165.12008 -136.5)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M344.6579,663.84928v1.59924a3.66621,3.66621,0,0,0,2.93885,3.58823c.11336.0162.22713.04058.34089.04871.1217.00812.24339.0162.3734.0162H473.45967a7.584,7.584,0,0,0,7.58217-7.57414V610.54591a7.584,7.584,0,0,0-7.58217-7.57419H380.85635a7.58255,7.58255,0,0,0-7.57425,7.57419v49.65023H348.311A3.65332,3.65332,0,0,0,344.6579,663.84928Z",
          transform: "translate(-165.12008 -136.5)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M344.6579,663.84928v1.59924a3.66621,3.66621,0,0,0,2.93885,3.58823c.11336.0162.22713.04058.34089.04871.1217.00812.24339.0162.3734.0162H473.45967a7.584,7.584,0,0,0,7.58217-7.57414V610.54591a7.584,7.584,0,0,0-7.58217-7.57419H380.85635a7.58255,7.58255,0,0,0-7.57425,7.57419v49.65023H348.311A3.65332,3.65332,0,0,0,344.6579,663.84928Zm1.62362,1.59924v-1.59924a2.03032,2.03032,0,0,1,2.02952-2.02952h26.59468V610.54591a5.95919,5.95919,0,0,1,5.95063-5.95058h92.60332a5.96088,5.96088,0,0,1,5.95856,5.95058v50.98161a5.96086,5.96086,0,0,1-5.95856,5.95052H348.311A2.03032,2.03032,0,0,1,346.28152,665.44852Z",
          transform: "translate(-165.12008 -136.5)",
          fill: "#3f3d56"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "262.04104",
          cy: "499.5375",
          r: "6.24293",
          fill: _vm.color
        }
      }),
      _c("rect", {
        attrs: {
          x: "208.1866",
          y: "524.55665",
          width: "1.62362",
          height: "7.30628",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d: "M1033.87992,168.58661h-432a1,1,0,0,1,0-2h432a1,1,0,0,1,0,2Z",
          transform: "translate(-165.12008 -136.5)",
          fill: "#cacaca"
        }
      }),
      _c("circle", {
        attrs: { cx: "474.74338", cy: "9.04331", r: "9.04331", fill: "#3f3d56" }
      }),
      _c("circle", {
        attrs: { cx: "505.98389", cy: "9.04331", r: "9.04331", fill: "#3f3d56" }
      }),
      _c("circle", {
        attrs: { cx: "537.22441", cy: "9.04331", r: "9.04331", fill: "#3f3d56" }
      }),
      _c("path", {
        attrs: {
          d:
            "M684.21364,322.769a53.13452,53.13452,0,1,1,53.13427-53.13476A53.1946,53.1946,0,0,1,684.21364,322.769Zm0-104.269a51.13452,51.13452,0,1,0,51.13427,51.13428A51.19252,51.19252,0,0,0,684.21364,218.5Z",
          transform: "translate(-165.12008 -136.5)",
          fill: "#cacaca"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "516.13322",
          cy: "427.9678",
          r: "24.7093",
          fill: "#e4e4e4"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M667.52741,573.62589l-.0257-.92338c1.71781-.04779,3.23228-.15555,4.37028-.987a2.83846,2.83846,0,0,0,1.09945-2.09068,1.62254,1.62254,0,0,0-.53226-1.33638,2.79665,2.79665,0,0,0-2.85693-.02525l-.764.35033,1.4651-10.70681.91481.12534-1.2462,9.10757a3.25954,3.25954,0,0,1,3.08327.44366,2.52589,2.52589,0,0,1,.85891,2.074,3.75476,3.75476,0,0,1-1.4775,2.804C670.95447,573.52986,669.05428,573.58306,667.52741,573.62589Z",
          transform: "translate(-165.12008 -136.5)",
          fill: "#2f2e41"
        }
      }),
      _c("rect", {
        attrs: {
          x: "510.60234",
          y: "422.31752",
          width: "4.9731",
          height: "0.92338",
          fill: "#2f2e41"
        }
      }),
      _c("rect", {
        attrs: {
          x: "494.90485",
          y: "422.31752",
          width: "4.9731",
          height: "0.92338",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M677.53427,548.51041c-5.96161,4.09118-15.11749,1.83561-17.81941-5.07649a12.73872,12.73872,0,0,1,.52557-10.46521,13.35139,13.35139,0,0,1,9.30163-6.41024c5.112-1.05973,10.39752.363,15.07031,2.42936a.92865.92865,0,0,0,1.05394-.136c4.4633-3.55835,10.94814-2.01337,15.52446.47394a34.43962,34.43962,0,0,1,12.18456,11.52673,35.98974,35.98974,0,0,1,5.671,15.80324A36.07258,36.07258,0,0,1,704.968,588.69649c-.88584.66274-.01968,2.17349.87792,1.50195a37.26017,37.26017,0,0,0-2.83406-61.83463c-5.48933-3.30172-13.13977-5.07557-18.57552-.74194l1.05394-.136c-4.6737-2.06679-9.79437-3.52573-14.94491-2.848-4.375.57571-8.67343,2.67359-11.18194,6.40992a14.31115,14.31115,0,0,0-1.71293,11.6942,12.99289,12.99289,0,0,0,7.8149,8.57,14.08893,14.08893,0,0,0,12.94679-1.29965c.91726-.62948.04828-2.13756-.87793-1.50195Z",
          transform: "translate(-165.12008 -136.5)",
          fill: "#2f2e41"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "517.86124",
          cy: "141.12648",
          r: "24.39074",
          fill: "#e4e4e4"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M677.22437,287.8653c-1.58105-.0439-3.54875-.09878-5.06465-1.20613a3.88947,3.88947,0,0,1-1.53044-2.9043,2.61645,2.61645,0,0,1,.88972-2.14885,3.37648,3.37648,0,0,1,3.19386-.4598l-1.2909-9.43376.94762-.12984,1.51759,11.09059-.79134-.36313a2.89589,2.89589,0,0,0-2.9594.02639,1.68111,1.68111,0,0,0-.55129,1.38478,2.93975,2.93975,0,0,0,1.13882,2.16543c1.17969.86169,2.74813.97285,4.527,1.02259Z",
          transform: "translate(-165.12008 -136.5)",
          fill: "#2f2e41"
        }
      }),
      _c("rect", {
        attrs: {
          x: "498.46389",
          y: "136.02604",
          width: "5.15152",
          height: "0.9565",
          fill: "#2f2e41"
        }
      }),
      _c("rect", {
        attrs: {
          x: "514.72439",
          y: "136.02604",
          width: "5.15152",
          height: "0.9565",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M685.24433,259.59667c-2.96341,3.87543-7.6678,6.47517-12.63985,5.85962a10.99256,10.99256,0,0,1-9.621-8.61112,14.18957,14.18957,0,0,1,3.72875-12.52561c3.35132-3.52485,8.14762-5.421,12.8951-6.07117a32.10477,32.10477,0,0,1,25.02929,7.8444,30.66064,30.66064,0,0,1,7.98319,10.7009,29.99742,29.99742,0,0,1,2.475,12.9499,32.71844,32.71844,0,0,1-10.75235,23.38681,30.30818,30.30818,0,0,1-2.58642,2.02576c-.75748.5314-1.47523-.712-.72414-1.23886a30.74282,30.74282,0,0,0,12.3309-20.69313,28.65857,28.65857,0,0,0-6.72074-23.11846A31.46362,31.46362,0,0,0,684.756,239.438c-8.39278-.366-18.8809,3.381-20.45145,12.72353-.70394,4.18746.8613,8.589,4.69858,10.68544a11.48107,11.48107,0,0,0,12.67679-1.578,14.56149,14.56149,0,0,0,2.32552-2.39647c.55336-.72365,1.80007-.00978,1.23887.72415Z",
          transform: "translate(-165.12008 -136.5)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M684.21364,462.93408a53.13452,53.13452,0,1,1,53.13427-53.13476A53.19461,53.19461,0,0,1,684.21364,462.93408Zm0-104.269a51.13452,51.13452,0,1,0,51.13427,51.13428A51.19252,51.19252,0,0,0,684.21364,358.665Z",
          transform: "translate(-165.12008 -136.5)",
          fill: "#cacaca"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M684.21364,612.5a53.13452,53.13452,0,1,1,53.13427-53.13428A53.1945,53.1945,0,0,1,684.21364,612.5Zm0-104.269a51.13452,51.13452,0,1,0,51.13427,51.13476A51.19263,51.19263,0,0,0,684.21364,508.231Z",
          transform: "translate(-165.12008 -136.5)",
          fill: "#cacaca"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "515.47662",
          cy: "278.37721",
          r: "25.23385",
          fill: "#e4e4e4"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M672.19937,425.47c-1.6357-.04542-3.67142-.1022-5.23972-1.24783a4.02392,4.02392,0,0,1-1.58334-3.00468,2.70688,2.70688,0,0,1,.92047-2.22313,3.49317,3.49317,0,0,1,3.30426-.4757l-1.33552-9.75985.98038-.13433,1.57005,11.474-.8187-.37568a2.996,2.996,0,0,0-3.0617.0273,1.73927,1.73927,0,0,0-.57034,1.43265,3.04133,3.04133,0,0,0,1.17819,2.24028c1.22046.89148,2.84312,1.00648,4.68351,1.05794Z",
          transform: "translate(-165.12008 -136.5)",
          fill: "#2f2e41"
        }
      }),
      _c("rect", {
        attrs: {
          x: "492.96739",
          y: "273.10047",
          width: "5.32959",
          height: "0.98956",
          fill: "#2f2e41"
        }
      }),
      _c("rect", {
        attrs: {
          x: "509.78996",
          y: "273.10047",
          width: "5.32959",
          height: "0.98956",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M681.86741,440.89849l17.45364-6.77451c2.854-1.10777,5.724-2.18182,8.56294-3.32774a9.76038,9.76038,0,0,0,3.296-1.9426,6.47678,6.47678,0,0,0,1.64322-3.17993,32.7569,32.7569,0,0,0,.45274-7.96721,60.7112,60.7112,0,0,0-.70345-8.53036,41.20809,41.20809,0,0,0-5.26956-15.03167c-4.89473-8.03107-13.328-14.07338-23.02856-13.59116a19.72025,19.72025,0,0,0-12.34448,4.90527,7.79363,7.79363,0,0,1-1.16525,1.12934,1.6169,1.6169,0,0,1-.928.0623q-.61077-.0393-1.22255-.06a25.25391,25.25391,0,0,0-4.23805.17777,13.419,13.419,0,0,0-6.99143,2.90305,8.526,8.526,0,0,0-3.16389,5.70971,6.75863,6.75863,0,0,0,2.28307,5.51709c2.0823,1.755,5.05325,1.13812,7.551,1.00579l9.15037-.48478,4.63618-.24562c.95461-.05058.95183,1.43391,0,1.48434l-12.3225.65284a53.866,53.866,0,0,1-6.0029.24328,6.716,6.716,0,0,1-4.1231-1.6587,8.227,8.227,0,0,1-2.492-7.71916,11.03457,11.03457,0,0,1,5.54485-7.04326c3.605-2.03346,7.91055-2.22829,11.94439-1.92768l-.52479.21738c4.563-4.89743,11.55243-6.9652,18.11408-6.24158,6.92807.764,13.09,4.6291,17.51728,9.9142,4.93616,5.89255,7.626,13.24013,8.6718,20.79557a69.18909,69.18909,0,0,1,.53533,12.31,15.51141,15.51141,0,0,1-.91647,5.31943,7.6148,7.6148,0,0,1-3.57485,3.83263,51.15875,51.15875,0,0,1-5.67408,2.33084l-6.43029,2.49587-12.63092,4.90261-3.21515,1.24793c-.891.34585-1.277-1.08881-.39459-1.43132Z",
          transform: "translate(-165.12008 -136.5)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M701.64117,382.78737a7.66911,7.66911,0,1,1,7.66911,7.66911A7.67774,7.67774,0,0,1,701.64117,382.78737Zm1.48434,0a6.18477,6.18477,0,1,0,6.18477-6.18477A6.19174,6.19174,0,0,0,703.12551,382.78737Z",
          transform: "translate(-165.12008 -136.5)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M995.45562,291.93738H820.82871a8.22518,8.22518,0,0,1,0-16.45036H995.45562a8.22518,8.22518,0,1,1,0,16.45036Z",
          transform: "translate(-165.12008 -136.5)",
          fill: "#e4e4e4"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M939.14477,263.782H820.82871a8.22518,8.22518,0,0,1,0-16.45036H939.14477a8.22518,8.22518,0,1,1,0,16.45036Z",
          transform: "translate(-165.12008 -136.5)",
          fill: "#e4e4e4"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M995.45562,432.10224H820.82871a8.22518,8.22518,0,0,1,0-16.45036H995.45562a8.22518,8.22518,0,1,1,0,16.45036Z",
          transform: "translate(-165.12008 -136.5)",
          fill: "#e4e4e4"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M939.14477,403.94682H820.82871a8.22519,8.22519,0,0,1,0-16.45037H939.14477a8.22518,8.22518,0,0,1,0,16.45037Z",
          transform: "translate(-165.12008 -136.5)",
          fill: "#e4e4e4"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M995.45562,581.6684H820.82871a8.22518,8.22518,0,0,1,0-16.45036H995.45562a8.22518,8.22518,0,1,1,0,16.45036Z",
          transform: "translate(-165.12008 -136.5)",
          fill: "#e4e4e4"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M939.14477,553.513H820.82871a8.22518,8.22518,0,0,1,0-16.45036H939.14477a8.22518,8.22518,0,1,1,0,16.45036Z",
          transform: "translate(-165.12008 -136.5)",
          fill: "#e4e4e4"
        }
      }),
      _c("path", {
        attrs: {
          d: "M547.12008,763.5h-381a1,1,0,0,1,0-2h381a1,1,0,1,1,0,2Z",
          transform: "translate(-165.12008 -136.5)",
          fill: "#3f3d56"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }