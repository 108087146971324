var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.filterLoading
        ? _c(
            "GhostLoading",
            { attrs: { useBoxContainer: "" } },
            [_c("Block", { attrs: { height: "200" } })],
            1
          )
        : _vm._e(),
      !_vm.filterLoading
        ? _c("GenericPersonFilter", {
            ref: "filter",
            attrs: {
              filterId: "1e3eaf98-106b-41f0-adba-7a5d43a4606e",
              saveKey: _vm.saveKeyFilter,
              title: "Wertpapierfilter",
              metadata: _vm.filter,
              configFilter: _vm.configFilter,
              predefinedFilter: _vm.predefinedFilter,
              defaultOptions: _vm.defaultFilters,
              isCustomerSearch: false
            },
            on: {
              search: function($event) {
                return _vm.handleSearch($event)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }