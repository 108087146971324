import axios from 'axios';
import  D_B from '@/store/dbm/types';
export function convertData(data,mutationName,commit){
      commit(mutationName, null);

      let dat = data.data1;//[];


       /* data.data.forEach((sub) => {
        try {
          //dat.push(JSON.parse(sub))
           
        }
        catch (e) {

          dat.push(eval(sub));
        }
      })*/
      let result = {};
      result.data = dat;
      result.columns = data.columns;
      result.label = data.label;
      
     commit(mutationName, result);
  } 