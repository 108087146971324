var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        "data-name": "Layer 1",
        width: "782.39009",
        height: "634.12253",
        viewBox: "0 0 782.39009 634.12253"
      }
    },
    [
      _c("polygon", {
        attrs: {
          points:
            "782.009 634.123 524.27 634.123 524.27 632.017 782.39 632.017 782.009 634.123",
          fill: "#3f3d56"
        }
      }),
      _c("circle", {
        attrs: { cx: "6.81265", cy: "6.81404", r: "6.81334", fill: "#e6e6e6" }
      }),
      _c("circle", {
        attrs: { cx: "41.30169", cy: "6.81404", r: "6.81334", fill: "#e6e6e6" }
      }),
      _c("circle", {
        attrs: { cx: "75.79073", cy: "6.81404", r: "6.81334", fill: "#e6e6e6" }
      }),
      _c("circle", {
        attrs: { cx: "110.27977", cy: "6.81404", r: "6.81334", fill: "#e6e6e6" }
      }),
      _c("circle", {
        attrs: { cx: "144.76881", cy: "6.81404", r: "6.81334", fill: "#e6e6e6" }
      }),
      _c("circle", {
        attrs: { cx: "179.25785", cy: "6.81404", r: "6.81334", fill: "#e6e6e6" }
      }),
      _c("circle", {
        attrs: { cx: "213.74689", cy: "6.81404", r: "6.81334", fill: "#e6e6e6" }
      }),
      _c("circle", {
        attrs: { cx: "248.23593", cy: "6.81404", r: "6.81334", fill: "#e6e6e6" }
      }),
      _c("circle", {
        attrs: { cx: "282.72497", cy: "6.81404", r: "6.81334", fill: "#e6e6e6" }
      }),
      _c("circle", {
        attrs: { cx: "317.21401", cy: "6.81404", r: "6.81334", fill: "#e6e6e6" }
      }),
      _c("circle", {
        attrs: { cx: "6.81265", cy: "41.73599", r: "6.81334", fill: "#e6e6e6" }
      }),
      _c("circle", {
        attrs: { cx: "41.30169", cy: "41.73599", r: "6.81334", fill: "#e6e6e6" }
      }),
      _c("circle", {
        attrs: { cx: "75.79073", cy: "41.73599", r: "6.81334", fill: "#e6e6e6" }
      }),
      _c("circle", {
        attrs: {
          cx: "110.27977",
          cy: "41.73599",
          r: "6.81334",
          fill: _vm.color
        }
      }),
      _c("circle", {
        attrs: {
          cx: "144.76881",
          cy: "41.73599",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "179.25785",
          cy: "41.73599",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "213.74689",
          cy: "41.73599",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "248.23593",
          cy: "41.73599",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "282.72497",
          cy: "41.73599",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "317.21401",
          cy: "41.73599",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: { cx: "6.81265", cy: "76.65794", r: "6.81334", fill: "#e6e6e6" }
      }),
      _c("circle", {
        attrs: { cx: "41.30169", cy: "76.65794", r: "6.81334", fill: "#e6e6e6" }
      }),
      _c("circle", {
        attrs: { cx: "75.79073", cy: "76.65794", r: "6.81334", fill: "#e6e6e6" }
      }),
      _c("circle", {
        attrs: {
          cx: "110.27977",
          cy: "76.65794",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "144.76881",
          cy: "76.65794",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "179.25785",
          cy: "76.65794",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "213.74689",
          cy: "76.65794",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "248.23593",
          cy: "76.65794",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "282.72497",
          cy: "76.65794",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "317.21401",
          cy: "76.65794",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: { cx: "6.81265", cy: "111.57988", r: "6.81334", fill: "#e6e6e6" }
      }),
      _c("circle", {
        attrs: {
          cx: "41.30169",
          cy: "111.57988",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "75.79073",
          cy: "111.57988",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "110.27977",
          cy: "111.57988",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "144.76881",
          cy: "111.57988",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "179.25785",
          cy: "111.57988",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "213.74689",
          cy: "111.57988",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "248.23593",
          cy: "111.57988",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "282.72497",
          cy: "111.57988",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "317.21401",
          cy: "111.57988",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: { cx: "6.81265", cy: "146.50183", r: "6.81334", fill: "#e6e6e6" }
      }),
      _c("circle", {
        attrs: {
          cx: "41.30169",
          cy: "146.50183",
          r: "6.81334",
          fill: _vm.color
        }
      }),
      _c("circle", {
        attrs: {
          cx: "75.79073",
          cy: "146.50183",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "110.27977",
          cy: "146.50183",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "144.76881",
          cy: "146.50183",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "179.25785",
          cy: "146.50183",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "213.74689",
          cy: "146.50183",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "248.23593",
          cy: "146.50183",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "282.72497",
          cy: "146.50183",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "317.21401",
          cy: "146.50183",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: { cx: "6.81265", cy: "181.42378", r: "6.81334", fill: "#e6e6e6" }
      }),
      _c("circle", {
        attrs: {
          cx: "41.30169",
          cy: "181.42378",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "75.79073",
          cy: "181.42378",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "110.27977",
          cy: "181.42378",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "144.76881",
          cy: "181.42378",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "179.25785",
          cy: "181.42378",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "213.74689",
          cy: "181.42378",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "248.23593",
          cy: "181.42378",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "282.72497",
          cy: "181.42378",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "317.21401",
          cy: "181.42378",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: { cx: "6.81265", cy: "216.34573", r: "6.81334", fill: "#e6e6e6" }
      }),
      _c("circle", {
        attrs: {
          cx: "41.30169",
          cy: "216.34573",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "75.79073",
          cy: "216.34573",
          r: "6.81334",
          fill: _vm.color
        }
      }),
      _c("circle", {
        attrs: {
          cx: "110.27977",
          cy: "216.34573",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "144.76881",
          cy: "216.34573",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "179.25785",
          cy: "216.34573",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "213.74689",
          cy: "216.34573",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "248.23593",
          cy: "216.34573",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "282.72497",
          cy: "216.34573",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "317.21401",
          cy: "216.34573",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: { cx: "6.81265", cy: "251.26768", r: "6.81334", fill: "#e6e6e6" }
      }),
      _c("circle", {
        attrs: {
          cx: "41.30169",
          cy: "251.26768",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "75.79073",
          cy: "251.26768",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "110.27977",
          cy: "251.26768",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "144.76881",
          cy: "251.26768",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "179.25785",
          cy: "251.26768",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "213.74689",
          cy: "251.26768",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "248.23593",
          cy: "251.26768",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "282.72497",
          cy: "251.26768",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "317.21401",
          cy: "251.26768",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: { cx: "6.81265", cy: "286.18963", r: "6.81334", fill: "#e6e6e6" }
      }),
      _c("circle", {
        attrs: {
          cx: "41.30169",
          cy: "286.18963",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "75.79073",
          cy: "286.18963",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "110.27977",
          cy: "286.18963",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "144.76881",
          cy: "286.18963",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "179.25785",
          cy: "286.18963",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "213.74689",
          cy: "286.18963",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "248.23593",
          cy: "286.18963",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "282.72497",
          cy: "286.18963",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "317.21401",
          cy: "286.18963",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: { cx: "6.81265", cy: "321.11157", r: "6.81334", fill: "#e6e6e6" }
      }),
      _c("circle", {
        attrs: {
          cx: "41.30169",
          cy: "321.11157",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "75.79073",
          cy: "321.11157",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "110.27977",
          cy: "321.11157",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "144.76881",
          cy: "321.11157",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "179.25785",
          cy: "321.11157",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "213.74689",
          cy: "321.11157",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "248.23593",
          cy: "321.11157",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "282.72497",
          cy: "321.11157",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "317.21401",
          cy: "321.11157",
          r: "6.81334",
          fill: "#e6e6e6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M609.20925,193.8305a8.614,8.614,0,0,1,8.614,8.614V547.00388a8.614,8.614,0,0,1-8.614,8.614H361.98784a8.614,8.614,0,0,1-8.614-8.614V202.44449a8.614,8.614,0,0,1,8.614-8.614H609.20925",
          transform: "translate(-208.80496 -132.93873)",
          fill: "#e6e6e6"
        }
      }),
      _c("path", {
        attrs: {
          d: "M362.99057,546.001H608.20632V203.44727H362.99057Z",
          transform: "translate(-208.80496 -132.93873)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M553.64892,257.64283a1.72279,1.72279,0,0,1,1.72279,1.7228v3.4456a1.72279,1.72279,0,0,1-1.72279,1.72279H417.548a1.72278,1.72278,0,0,1-1.72279-1.72279v-3.4456a1.72278,1.72278,0,0,1,1.72279-1.7228h136.101",
          transform: "translate(-208.80496 -132.93873)",
          fill: "#e6e6e6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M553.64892,274.0094a1.7228,1.7228,0,0,1,1.72279,1.7228v3.4456a1.72279,1.72279,0,0,1-1.72279,1.72279H417.548a1.72278,1.72278,0,0,1-1.72279-1.72279v-3.4456a1.72279,1.72279,0,0,1,1.72279-1.7228h136.101",
          transform: "translate(-208.80496 -132.93873)",
          fill: "#e6e6e6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M553.64892,290.376a1.7228,1.7228,0,0,1,1.72279,1.72279v3.4456a1.7228,1.7228,0,0,1-1.72279,1.72279H417.548a1.72279,1.72279,0,0,1-1.72279-1.72279v-3.4456A1.72279,1.72279,0,0,1,417.548,290.376h136.101",
          transform: "translate(-208.80496 -132.93873)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M553.64892,307.10073a1.72279,1.72279,0,0,1,1.72279,1.72279v3.4456a1.72279,1.72279,0,0,1-1.72279,1.72279H417.548a1.72278,1.72278,0,0,1-1.72279-1.72279v-3.4456a1.72278,1.72278,0,0,1,1.72279-1.72279h136.101",
          transform: "translate(-208.80496 -132.93873)",
          fill: "#e6e6e6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M553.64892,323.4673a1.72279,1.72279,0,0,1,1.72279,1.72279v3.4456a1.72279,1.72279,0,0,1-1.72279,1.7228H417.548a1.72278,1.72278,0,0,1-1.72279-1.7228v-3.4456a1.72278,1.72278,0,0,1,1.72279-1.72279h136.101",
          transform: "translate(-208.80496 -132.93873)",
          fill: "#e6e6e6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M553.64892,339.83387a1.7228,1.7228,0,0,1,1.72279,1.72279v3.4456a1.72281,1.72281,0,0,1-1.72279,1.7228H417.548a1.7228,1.7228,0,0,1-1.72279-1.7228v-3.4456a1.72279,1.72279,0,0,1,1.72279-1.72279h136.101",
          transform: "translate(-208.80496 -132.93873)",
          fill: "#e6e6e6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M418.41724,394.89a2.002,2.002,0,0,0-2,2v92.91552a2.0023,2.0023,0,0,0,2,2H552.5481a2.00229,2.00229,0,0,0,2-2V396.89a2.002,2.002,0,0,0-2-2Z",
          transform: "translate(-208.80496 -132.93873)",
          fill: _vm.color
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "277.241 161.182 275.833 160.808 306.56 45.221 484.785 45.221 484.785 46.678 307.679 46.678 277.241 161.182",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M708.15259,194.17916a15.29091,15.29091,0,1,1,15.2909-15.29091A15.30831,15.30831,0,0,1,708.15259,194.17916Zm0-29.12554a13.83463,13.83463,0,1,0,13.83462,13.83463A13.85037,13.85037,0,0,0,708.15259,165.05362Z",
          transform: "translate(-208.80496 -132.93873)",
          fill: "#3f3d56"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "499.34763",
          cy: "45.94952",
          r: "5.82511",
          fill: _vm.color
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "272.477 356.941 273.884 357.317 242.937 472.845 64.712 472.504 64.715 471.047 241.82 471.387 272.477 356.941",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M258.98467,589.39543A15.29091,15.29091,0,1,1,243.66449,604.657,15.30832,15.30832,0,0,1,258.98467,589.39543Zm-.05582,29.12548a13.83463,13.83463,0,1,0-13.80809-13.86111A13.85036,13.85036,0,0,0,258.92885,618.52091Z",
          transform: "translate(-208.80496 -132.93873)",
          fill: "#3f3d56"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "50.15041",
          cy: "471.74758",
          r: "5.82511",
          fill: _vm.color
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "616.828 600.594 606.551 600.594 608.386 543.702 635.548 545.17 616.828 600.594",
          fill: "#ffb8b8"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M827.0356,766.22887c-3.10207,1.41577-5.70046-10.05467-8.31711-7.48324-7.31912,7.19262-14.8815,8.75887-24.65094,5.61857l-.22627-.03823a4.57524,4.57524,0,0,1-2.88382-5.96221h0a4.57524,4.57524,0,0,1,3.17428-2.83216l7.78388-1.946,12.22181-21.49353,12.47673-.84288h0a29.80836,29.80836,0,0,1,5.51307,25.42887C830.995,761.29929,829.24714,765.21954,827.0356,766.22887Z",
          transform: "translate(-208.80496 -132.93873)",
          fill: "#2f2e41"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "714.467 593.074 704.91 596.854 685.691 543.275 711.489 534.65 714.467 593.074",
          fill: "#ffb8b8"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M936.60125,755.901c-2.36392,2.45747-8.999-7.25324-10.48647-3.89965-4.16062,9.38044-10.61684,13.61835-20.8565,14.2914l-.22447.04767a4.57523,4.57523,0,0,1-4.87458-4.48361h0a4.57524,4.57524,0,0,1,1.91009-3.80114l6.52254-4.67249L912.0516,728.901l11.29216-5.37276h0a29.80835,29.80835,0,0,1,14.47942,21.6187C938.47,749.86063,938.28653,754.149,936.60125,755.901Z",
          transform: "translate(-208.80496 -132.93873)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M892.07129,451.2996l-15.77836,4.05729a8.25773,8.25773,0,0,1-10.30815-7.68019v0a8.25773,8.25773,0,0,1,8.51788-8.57081l13.8982.44833,26.78129-9.75184L889.869,410.92478l2.93635-26.42715,49.3313,39.604a8.19471,8.19471,0,0,1,3.04373,5.80637h0a8.19474,8.19474,0,0,1-6.00855,8.48733C927.61284,447.82846,910.77292,450.71754,892.07129,451.2996Z",
          transform: "translate(-208.80496 -132.93873)",
          fill: "#ffb8b8"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "647.29605",
          cy: "193.56598",
          r: "25.69306",
          fill: "#ffb8b8"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M887.66677,361.74092l-34.50212,13.94766a46.63067,46.63067,0,0,0-8.075-30.83168l28.62941-6.60679C873.34677,346.99393,878.075,354.80983,887.66677,361.74092Z",
          transform: "translate(-208.80496 -132.93873)",
          fill: "#ffb8b8"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M893.90651,421.56905c-27.81248,2.226-43.02531-1.4673-66.43492-15.04879l8.809-20.55445h0a17.00141,17.00141,0,0,1,7.00343-16.27794l9.1465-6.47877,27.89532-8.075,1.2513.06256A15.2693,15.2693,0,0,1,896.039,369.27584C902.09918,387.38638,902.13847,404.89273,893.90651,421.56905Z",
          transform: "translate(-208.80496 -132.93873)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M888.40086,464.51318l-59.4611-6.60679-4.40452-19.08628c-9.763-14.73785-5.50172-28.23741,5.8727-41.1089,20.73295,13.306,39.9782,19.05585,67.536,14.68175C890.61535,430.21634,883.847,447.73282,888.40086,464.51318Z",
          transform: "translate(-208.80496 -132.93873)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M934.64837,686.94171l-47.34865-2.56931L871.88389,561.77978,859.4044,683.63832,803.2467,681.069c17.13051-80.11107,29.03118-156.16275,25.69306-226.099l58.727,5.13861C907.57,481.09432,917.45251,514.59373,923.27,554.4389S933.77635,640.475,934.64837,686.94171Z",
          transform: "translate(-208.80496 -132.93873)",
          fill: "#2f2e41"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "675.58002",
          cy: "161.34488",
          r: "8.83132",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M875.28507,292.12157a8.82437,8.82437,0,0,1,8.82714-8.25412c-.18236-.02313-.36309-.05126-.549-.06321a8.83132,8.83132,0,0,0-1.13325,17.62625c.18595.01195.36879.0072.55261.00761A8.82436,8.82436,0,0,1,875.28507,292.12157Z",
          transform: "translate(-208.80496 -132.93873)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M875.23523,295.89278c-7.87608-6.53374-30.4669,1.77036-31.92466,6.16308-10.65074,1.45149-18.71465,7.66553-18.71465,18.71459v.00006c6.33219,2.59406,13.53384,2.27438,21.31879,0,12.40986,10.11331,13.26059,22.82682,5.71348,37.4293l9.285.18568,11.50907-16.82092.55038,17.06213,21.28338-.42689V333.7487a51.12092,51.12092,0,0,0-5.45822-23.42036C885.48113,303.88488,880.33405,300.12257,875.23523,295.89278Z",
          transform: "translate(-208.80496 -132.93873)",
          fill: "#2f2e41"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "646.6112",
          cy: "200.24128",
          rx: "4.22316",
          ry: "5.91243",
          fill: "#ffb8b8"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M823.11342,559.92824a1.483,1.483,0,0,1,1.483,1.483v59.31928a1.483,1.483,0,0,1-1.483,1.483h-42.5616a1.483,1.483,0,0,1-1.483-1.483V561.41123a1.483,1.483,0,0,1,1.483-1.483h42.5616",
          transform: "translate(-208.80496 -132.93873)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M828.93976,537.92193l1.43732,24.91342a10.8188,10.8188,0,0,1-12.37492,11.3268h0c-6.32954-.93081-6.47336-7.121-4.92171-13.32762l5.91167-23.64668c14.50235-51.99824,18.41745-103.03575,17.68369-152.8119A12.4975,12.4975,0,0,1,849.50966,371.662h0A12.4912,12.4912,0,0,1,861.631,384.58919C859.57812,443.03942,851.05293,496.1186,828.93976,537.92193Z",
          transform: "translate(-208.80496 -132.93873)",
          fill: "#ffb8b8"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }