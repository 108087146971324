var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "PageHeaderTitleNavigation",
        _vm._g(
          {
            attrs: {
              title: _vm.$appNavigation.currentMenu.label,
              actions: _vm.headerActions,
              defaultMenu: _vm.$appNavigation.currentTabMenu
            },
            on: {
              "action-ADD-HTML": function($event) {
                return _vm.newTemplate()
              },
              "action-ADD-WORD": function($event) {
                return _vm.newWordTemplate()
              }
            }
          },
          _vm.$listeners
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }