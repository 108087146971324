export const MUTATION_PREFIX = 'NAFI: ';
export const ACTIONS_PREFIX = 'NAFI_ACTIONS_';
export const GETTERS_PREFIX = 'NAFI_GETTERS_';

export default {
  MUTATIONS: {
    GET_NAFI_ZUGANGSDATEN_SUCCESS: MUTATION_PREFIX + 'GET_NAFI_ZUGANGSDATEN_SUCCESS',
    GENERATE_NAFI_PASSWORD_SUCCESS: MUTATION_PREFIX + 'GENERATE_NAFI_PASSWORD_SUCCESS',
    UPDATE_NAFI_STORE: MUTATION_PREFIX + 'UPDATE_NAFI_STORE',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
  },
  ACTIONS: {
    GET_NAFI_ZUGANGSDATEN: ACTIONS_PREFIX + 'GET_NAFI_ZUGANGSDATEN',
    GENERATE_NAFI_PASSWORD: ACTIONS_PREFIX + 'GENERATE_NAFI_PASSWORD',
    NAFI_LOGIN: ACTIONS_PREFIX + 'NAFI_LOGIN',
  },
  GETTERS: {
    NAFI_ZUGANGSDATEN: GETTERS_PREFIX + 'NAFI_ZUGANGSDATEN',
  }
}
