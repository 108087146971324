<template >
  <div class="week-calendar__container" :class="{'work-days-only': isWorkDays}">
    <div v-if="compgetWeekCalendarDataReady" id="weekcalendarapp" data-calculating-sizes="true">
      <div class="week-calendar__sticky">
        <div class="weekweekdays">
          <div class="time-header"></div>
          <div
            :class="['weekweekday', getShownDays[i] && getShownDays[i].isToday && 'today']"
            v-for="(weekday, i) in getWeekdayLabels"
            :key="weekday[0[1]]"
          >
            <a class="clickable" @click="openDay(getShownDays[i])">{{weekday}} {{getShownDays[i].label}}</a>
          </div>
        </div>
        <div v-if="getCompWeekCalendarDataReady" class="wholeday__container">
          <div class="daysrow">
            <div class="time-column">
              <div class="wholeday ellipsis">
                Ganztägig
              </div>
            </div>
            <CalendarWeekDayColumn
              :isAllDay="true"
              class="wday"
              v-for="(dayobject,index) in getShownDaysVisible"
              :key="'allday' + index"
              calledfrom="week"
              :class="{ selected: dayobject.isSelected, notselected: !dayobject.isSelected }"
              :istoday="dayobject.isToday"
              :inmonth="dayobject.inMonth"
              :dindex="index"
              :daylabel="dayobject.label"
              :hours="getShownHours"
              :date="dayobject.day"
              @editEvent="editEvent"
            />
          </div>
        </div>
      </div>
      <div v-if="getCompWeekCalendarDataReady" ref="dayGridHoursEl" data-scroll-container class="daygridhours hours-view" :style="{maxHeight: 'var(--w-dayGridHoursHeight)'}">
        <div class="redline" v-if="isCurrentLine()" :style="currentLineStyle" />
        <div class="daysrow"><div class="time-column"></div><div class="border-line"></div></div>
        <div class="daysrow">
          <div class="time-column">
            <div
              class="wholehour"
              :style="setStyle"
              v-for="wholehour in getShownHours"
              :key="wholehour"
              >{{wholehour}}</div>
          </div>
          <CalendarWeekDayColumn
            :isAllDay="false"
            class="wday"
            v-for="(dayobject,index) in getShownDaysVisible"
            :key="index"
            calledfrom="week"
            :class="{ selected: dayobject.isSelected, notselected: !dayobject.isSelected }"
            :istoday="dayobject.isToday"
            :inmonth="dayobject.inMonth"
            :dindex="index"
            :daylabel="dayobject.label"
            :hours="getShownHours"
            :date="dayobject.day"
            @editEvent="editEvent"
          />
        </div>
      </div>
    </div>
    <CalendarGhostLoading v-else :type="isWorkDays ? 'work-week' : 'week'" :style="{maxHeight: 'var(--w-containerHeight)', overflow: 'hidden',}" />
  </div>
</template>

<script>

import dayjs from "dayjs";
import { mapGetters } from "vuex";
import CALENDAR_TYPES from "@/store/calendar/types";
import CalendarWeekDayColumn from "@/components/calendar/CalendarWeekDayColumn.vue";
import CalendarMixins from "@/components/calendar/CalendarMixins";
import CalendarGhostLoading from '@/components/calendar/CalendarGhostLoading.vue';

const MIN_CONTAINER_HEIGHT = 160;
const BOTTOM_OFFSET_SIZE = 28;
const HOUR_CELL_CLASS = '.hour-cell';
const DEFAULT_HOUR_CELL_HEIGHT = 64;

export default {
  props: {
    next: {
        type: Boolean,
        default: false,
    },
    prev: {
        type: Boolean,
        default: false,
    },
    current: {
        type: Boolean,
        default: false,
    },
    doRenew: {
        type: Boolean,
        default: false,
    },
    date: {
        type: Date,
        default: null,
    },
    searchTerm: {
      type: String,
      default: ''
    }
  },
  components: {
    CalendarWeekDayColumn,
    CalendarGhostLoading,
  },
  data() {
    return {
      thisday: {},
      weekdayLabels: [],
      daygridhoursHeight: null,
    };
  },
  mixins: [CalendarMixins],
  computed: {
    ...mapGetters({
      getShownDays: CALENDAR_TYPES.GETTERS.GET_SHOWN_DAYS,
      compgetWeekCalendarData: CALENDAR_TYPES.GETTERS.GET_CALENDAR_DATA,
      compGetWeekCalendarData: CALENDAR_TYPES.GETTERS.GET_WEEK_CALENDAR_DATA,
      compgetWeekCalendarDataReady: CALENDAR_TYPES.GETTERS.GET_CALENDAR_DATA_READY,
      getHoursStart: CALENDAR_TYPES.GETTERS.GET_HOURS_START,
      getHoursStop: CALENDAR_TYPES.GETTERS.GET_HOURS_STOP,
      getShownHours: CALENDAR_TYPES.GETTERS.GET_SHOWN_HOURS,
      calendarSetup: CALENDAR_TYPES.GETTERS.GET_APPOINTMENT_SETUP,
    }),
    getShownDaysVisible() {
      return this.isWorkDays ? this.getShownDays.slice(0, 5) : this.getShownDays;
    },
    getWeekdayLabels() {
      return this.isWorkDays ? this.weekdayLabels.slice(0, 5) : this.weekdayLabels;
    },
    getCompWeekCalendarDataReady() {
      return this.compGetWeekCalendarData !== undefined;
    },
    isWorkDays() {
      return this.$route.meta.type === 'arbeitswoche';
    },
    setStyle() {
      return "height:4em";
    },
    header() {
      return {
        month: dayjs(this.thisday).month(),
        year: dayjs(this.thisday).year(),
        label:
          dayjs(this.thisday)
            .weekday(0)
            .format("DD.MM") +
          " - " +
          dayjs(this.thisday)
            .weekday(6)
            .format("DD.MM")
      };
    },
    currentLineStyle() {
      if(!this.getCompWeekCalendarDataReady) return {};

      const today = dayjs();
      const oneHourEl = document.querySelector(HOUR_CELL_CLASS);
      const hourHeight = oneHourEl?.offsetHeight || DEFAULT_HOUR_CELL_HEIGHT;
      return {
        top: `${+(hourHeight * +today.format('HH')) + (hourHeight/60 * today.format('mm'))}px`,
      };
    },
  },
  watch: {
    calendarSetup(val) {
      setTimeout(() => {
        this.calculateSizes();
        this.$nextTick(() => this.scrollToConfiguredTime());
      });
    },
    compGetWeekCalendarData(v) {
      setTimeout(() => {
        this.calculateSizes();
        this.$nextTick(() => this.scrollToConfiguredTime());
      });
      this.$nextTick(() => this.setInitialCellWidth());

      this.month = this.thisday.week();
      this.year = this.thisday.year();
      this.$emit('title', `Kalenderwoche ${this.month}, ${dayjs(this.thisday).format("MMMM")} ${this.year}`);
      this.$emit('titleShortening', `KW ${this.month}, ${dayjs(this.thisday).format("MMMM")} ${this.year}`);
    },
    next(v) {
      if (v) {
        this.moveNextWeek();
        this.$emit('setNextPrev', true);
      }
    },
    prev(v) {
      if (v) {
        this.movePreviousWeek();
        this.$emit('setNextPrev', true);
      }
    },
    current(v) {
      if (v) {
        this.moveThisWeek();
        this.$emit('setNextPrev', true);
      }
    },
    doRenew(v) {
      if (v) {
        this.renewView();
        this.$emit('setNextPrev', true);
      }
    },
    '$route'(to, from) {
      this.setInitialCellWidth();
      setTimeout(() => this.calculateSizes());
    },
    date(value) {
      this.setWeek(value);
    },
    thisday() {
      this.$emit('dateChanged', this.thisday?.toDate());
    },
  },

  beforeMount() {
    this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_WEEK_CALENDAR_DATA, []);
  },
  mounted() {
    this.calculateSizes();
    this.setInitialCellWidth();

    this.thisday = dayjs(this.date);
    this.getWeekCalendarData(this.thisday, this.searchTerm);
    this.month = this.thisday.month();
    this.year = this.thisday.year();
    for (let wl = 0; wl < 7; wl++) {
      this.weekdayLabels.push(
        dayjs()
          .weekday(wl)
          .format("dd.")
      );
    }
    this.makeShownHours(Number(0), Number(23));

    this.setWeekShownDays(this.thisday);

    this.$emit('title', `Kalenderwoche ${this.month+1} ${this.year}`);
    this.$emit('titleShortening', `KW ${this.month+1} ${this.year}`);
    window.addEventListener('resize', this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.setInitialCellWidth();
      this.$nextTick(() => this.calculateSizes());
    },
    calculateSizes() {
      const rootEl = this.$el;
      const { dayGridHoursEl, } = this.$refs;
      if(rootEl) {
        const containerHeightCalc = window.innerHeight - rootEl.offsetTop - BOTTOM_OFFSET_SIZE;
        const containerHeight = containerHeightCalc >= MIN_CONTAINER_HEIGHT ? containerHeightCalc : MIN_CONTAINER_HEIGHT;
        rootEl.style.setProperty('--w-containerHeight', `${containerHeight}px`);
      }
      if (dayGridHoursEl) {
        const daygridhoursHeightCalc = window.innerHeight - dayGridHoursEl.offsetTop - BOTTOM_OFFSET_SIZE || 0;
        this.daygridhoursHeight = daygridhoursHeightCalc >= MIN_CONTAINER_HEIGHT ? daygridhoursHeightCalc : MIN_CONTAINER_HEIGHT;
        rootEl.style.setProperty('--w-dayGridHoursHeight', `${this.daygridhoursHeight}px`);
      }
      this.$nextTick(() => this.setCellWidth());
    },
    scrollToConfiguredTime() {
      const { calendarSetup, } = this;
      if(!calendarSetup) return;

      const { dayGridHoursEl, } = this.$refs;
      if (dayGridHoursEl && this.daygridhoursHeight) {
        const configuredHour = calendarSetup.beginTime?.split(':')?.[0];
        const today = configuredHour ? dayjs().hour(configuredHour) : dayjs();
        const currentHourEl = document.querySelector(`${HOUR_CELL_CLASS}[data-hour="${today.format('HH')}:00"]`);
        dayGridHoursEl.scrollTop = currentHourEl?.offsetTop;
      }
    },
    setInitialCellWidth() {
      const weekCalendarAppEl = document.getElementById('weekcalendarapp');
      if(!weekCalendarAppEl) return;

      weekCalendarAppEl.dataset.calculatingSizes = true;
      if (this.isWorkDays) {
        weekCalendarAppEl.style.setProperty('--w-dayWidth', '18.9%');
      } else {
        weekCalendarAppEl.style.setProperty('--w-dayWidth', '13.5%');
      }
    },
    setCellWidth(isResize = false) {
      const weekCalendarAppEl = document.getElementById('weekcalendarapp');
      if(!weekCalendarAppEl) return;

      weekCalendarAppEl.dataset.calculatingSizes = true;

      if (isResize) {
        weekCalendarAppEl.style.setProperty('--w-timeWidth', '5.5%');
        if (this.isWorkDays) {
          weekCalendarAppEl.style.setProperty('--w-dayWidth', '18.9%');
        } else {
          weekCalendarAppEl.style.setProperty('--w-dayWidth', '13.5%');
        }
      }
      const cellTime = document.querySelector('.time-column');
      if (cellTime) {
        weekCalendarAppEl.style.setProperty('--w-timeWidth', `${cellTime.offsetWidth}px`);
      }
      const cell = document.querySelector('.hour-cell');
      if (cell) {
        weekCalendarAppEl.style.setProperty('--w-dayWidth', `${cell.offsetWidth}px`);
      }

      weekCalendarAppEl.dataset.calculatingSizes = false;
    },
    editEvent(e) {
      this.$emit('editEvent', e);
    },
    isCurrentLine() {
      const today = dayjs();
      return this.thisday && this.thisday.format && today.format("DD.MM.YYYY") === this.thisday.format("DD.MM.YYYY");
    },
    renewView() {
      setTimeout(() => {
        this.$store.commit(CALENDAR_TYPES.MUTATIONS.RENEW_CALENDAR_DATA);
        this.getWeekCalendarData(this.thisday, this.searchTerm);
      }, 300);
    },
    setWeekShownDays(thisday) {
      let alldays = [];
      let firstday = dayjs(thisday).weekday(0);
      let lastday = dayjs(thisday).weekday(6);
      for (let dd = 0; dd < 9; dd++) {
        let nextday = firstday.add(dd, "day");
        if (nextday.isSameOrBefore(lastday)) {
          alldays.push({
            label: nextday.format("D"), //D.MM
            day: nextday,
            inMonth: nextday.month() === dayjs(thisday).month(),
            isToday: dayjs().isSame(nextday, "date"),
            isSelected: false
          });
        }
      }
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_SHOWN_DAYS, alldays);
    },

    moveThisWeek() {
      this.thisday = dayjs();
      this.setWeekShownDays(this.thisday);
      this.getWeekCalendarData(this.thisday, this.searchterm);
    },
    moveNextWeek() {
      this.thisday = dayjs(this.thisday).add(1, "week");
      this.setWeekShownDays(this.thisday);
      this.getWeekCalendarData(this.thisday, this.searchterm);
    },
    movePreviousWeek() {
      this.thisday = dayjs(this.thisday).subtract(1, "week");
      this.setWeekShownDays(this.thisday);
      this.getWeekCalendarData(this.thisday, this.searchterm);
    },
    moveNextMonth() {
      this.thisday = dayjs(this.thisday).add(1, "month");
      this.setWeekShownDays(this.thisday);
      this.getWeekCalendarData(this.thisday, this.searchterm);
    },
    movePreviousMonth() {
      this.thisday = dayjs(this.thisday).subtract(1, "month");
      this.setWeekShownDays(this.thisday);
      this.getWeekCalendarData(this.thisday, this.searchterm);
    },
    setWeek(date) {
      if(!date) return;

      const desiredDate = dayjs(date);
      const desiredDateWeek = dayjs(date).week();
      const currentWeek = this.thisday.week();

      const desiredDateYear = dayjs(date).year();
      const currentYear = this.thisday.year();

      if(desiredDateWeek !== currentWeek || desiredDateYear !== currentYear) {
        this.thisday = desiredDate;
        this.setWeekShownDays(this.thisday);
        this.getWeekCalendarData(this.thisday, this.searchTerm);
      }
    },

    chooseWeekDay(thisday, dindex) {
      this.unselectcells(thisday);
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_DAY_ELEMENTS, {
        day: thisday,
        dayIndex: dindex
      });
      this.$router.push("daycalendar");
    },
    openDay(event) {
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_DAY_ELEMENTS, {
        day: event,
        dayIndex: dayjs(event.day).weekday()
      });
      this.$router.push("daycalendar");
    },

    unselectcells(thisday) {
      for (let di = 0; di < this.getShownDays.length; di++) {
        this.getShownDays[di].isSelected = false;
      }
      thisday.isSelected = true;
    }
  }
};
</script>

<style scoped>
#weekcalendarapp,
#weekcalendarapp * {
  box-sizing: border-box;
}

#weekcalendarapp {
  --w-headerPadding: 0.5rem 1rem;
  --w-headerBorderWidth: 1px;
  --w-headerBorderStyle: solid;
  --w-weekdayPadding: 0.4rem 0;
  --w-weekdayBorderWidth: 1px;
  --w-weekdayBorderStyle: solid;
  --w-dayBorder: solid 1px var(--color-primary);
  --w-dayWidth: 13.5%;
  --w-timeWidth: 5.5%;
  --borderColor: #cccccc;

}

#weekcalendarapp[data-calculating-sizes="false"] {
  transition: opacity .5s ease;
  opacity: 1;
}

#weekcalendarapp[data-calculating-sizes="true"] {
  transition: none;
  opacity: 0;
  height: var(--w-containerHeight);
  overflow: hidden;
}

.header {
  display: flex;
  justify-content: stretch;
  align-items: center;
  color: var(--color-top-nav-text);
  padding: var(--w-headerPadding);
  border-width: var(--w-headerBorderWidth);
  border-style: var(--w-headerBorderStyle);
  border-color: var(--color-top-nav-background);
}
.time-header {
  width: var(--w-timeWidth);
}
.time-column {
  width: var(--w-timeWidth);
  display: flex;
  flex-direction: column;
}
.wholeday {
  min-height: 4em;
  box-sizing: border-box;
  padding-left: 0.3em;
  padding-right: 0.3em;
  padding-top: 0.5rem;
  line-height: 1;
}
.wholedaytitle {
  height: 2em;
}
.wholehour {
  text-align: center;
  border-width: thin;
}

.wday {
  width: var(--w-dayWidth);
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.arrow {
  padding: 0 0.4em 0.2em 0.4em;
  font-size: 1.8rem;
  font-weight: 500;
  user-select: none;
  flex-grow: 0;
}

.title {
  flex-grow: 1;
  font-size: 1.2rem;
  text-align: center;
}

.weekweekdays {
  display: flex;
  flex: auto;
}
.daysrow {
  display: flex;
  flex: auto;
  position: relative;
}
.border-line {
  border-bottom: 1px solid var(--borderColor);
  width: 100%;
}
.time-hours {
  position: relative;
}

.weekweekday {
  width: var(--w-dayWidth);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--w-weekdayPadding);
  border-width: var(--w-weekdayBorderWidth);
  border-style: var(--w-weekdayBorderStyle);
  border-color: var(--borderColor);
  border-top: none;
  border-left: none;
  border-right: none;
}
.week {
  display: flex;
  flex: auto;
}

.selected {
  border: solid 1px var(--color-primary);
}
.daysrow .notselected {
  border-right: solid 1px var(--borderColor);
}
.daysrow .notselected:last-child {
  border-right: none;
}
.hour-cell {
  border-bottom: 1px solid var(--borderColor);
  border-bottom: 1px solid #cccccc;
  display: flex;
  flex: auto;
}
.weekweekday.today {
  color: var(--color-danger);
  font-weight: bold;
}
.redline {
  width: 100%;
  background: var(--color-danger);
  height: 1px;
  position: absolute;
  z-index: 1;
  pointer-events: none;
}
.hours-view {
  position: relative;
  overflow-y: auto;
  min-height: 100px;
}

.ghost-loading-calendar--inline {
  display: flex;
  margin: 0 -4px 8px;
}
.ghost-loading-calendar--inline:last-child {
  margin-bottom: 0;
}
.ghost-loading-calendar--inline > div {
  margin: 0 4px;
  flex: 1 auto;
}
</style>