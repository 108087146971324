<template>
  <div>
    <slot></slot>
    <GhostLoading v-if="!loadingDisabled && scrollLoading" type="block" :config="{ block: { height: 200, }, }"/>
  </div>
</template>

<script>
import GhostLoading from '@/components/core/loading/GhostLoading.vue';

export default {
  components: {
    GhostLoading,
  },
  props: {
    loadingDisabled: {
        type: Boolean,
        default: false,
    },
    hasScroll: {
        type: Boolean,
        default: false,
    },
  },
  data() {
    return {
      scrollLoading: false,
    }
  },
  mounted () {
    const rootEl = this.$el;
    const scrollEl = rootEl.closest('[data-scroll-container]') || document;
    scrollEl.addEventListener('scroll', this.onScroll);
  },
  destroyed () {
    const rootEl = this.$el;
    const scrollEl = rootEl.closest('[data-scroll-container]') || document;
    scrollEl.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    onScroll () {
      if (!this.hasScroll || this.scrollLoading)
        return

      const rootEl = this.$el;
      const scrollEl = rootEl.closest('[data-scroll-container]') || document.documentElement;

      let bottomOfWindow = scrollEl.scrollTop + scrollEl.clientHeight > scrollEl.scrollHeight - 100;

      if (bottomOfWindow) {
        this.onScrollBeginn()
        this.$emit("scroll", this.onScrollEnd)
      }
    },
    onScrollBeginn() {
      this.scrollLoading = true
      this.emitLoading()
    },
    onScrollEnd() {
      this.scrollLoading = false
      this.emitLoading()
    },
    emitLoading() {
      this.$emit('loading', this.scrollLoading)
    },
  },
}
</script>