<template>
  <div class="input-forms__label-content" v-if="label && config && config.html" v-html="sanitize(label)"
    :class="classes"
  >
    {{label}}
  </div>
  <div class="input-forms__label-content" v-else-if="label"
    :class="classes"
  >
    {{label}}
  </div>
</template>

<script>
import {sanitize} from '@/helpers/string-helper.js';

export default {
  props: {
    label: {
      default: ''
    },
    config: {
    }
  },
  computed: {
    classes() {
      return {
        'bold': this.config?.bold, 
        'small': this.config?.small, 
        'italic': this.config?.italic,
        ...this.fixedColor,
      };
    },
    fixedColor() {
      const { color = '' } = this.config || {};
      switch(color) {
        case 'SUCCESS':
          return { 'color-success': true, };
        case 'INFO':
          return { 'color-info': true, };
        case 'WARNING':
          return { 'color-warning': true, };
        case 'DANGER':
          return { 'color-danger': true, };
      }

      return {};
    },
  },
    methods: {
        sanitize(htmlString) {
            return sanitize(htmlString);
        },
    },
}
</script>

<style scoped>
  .bold {
    font-weight: bold;
  }
  .small {
    font-size: smaller;
  }
  .italic {
    font-style: italic
  }
</style>