var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: "Mitarbeiter", actions: _vm.headerActions },
        on: {
          "action-NEW": function($event) {
            return _vm.createNewAp()
          }
        }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("div", { staticClass: "box__title" }, [_vm._v("Ansprechpartner")]),
          _c("ComboBox", {
            attrs: {
              label: "Primärer Ansprechpartner",
              values: _vm.primaerPersonen.hauptPersonValues,
              isComponentHalfSize: "",
              disabled: _vm.isSomeButtonLoading
            },
            on: {
              change: function($event) {
                return _vm.savePrimaerPersonen("hauptPerson", $event, true)
              }
            },
            model: {
              value: _vm.primaerPersonen.hauptPerson,
              callback: function($$v) {
                _vm.$set(_vm.primaerPersonen, "hauptPerson", $$v)
              },
              expression: "primaerPersonen.hauptPerson"
            }
          }),
          _c("ComboBox", {
            attrs: {
              label: "Primärer Kundenberater",
              values: _vm.primaerPersonen.kundenBeraterValues,
              isComponentHalfSize: "",
              disabled: _vm.isSomeButtonLoading
            },
            on: {
              change: function($event) {
                return _vm.savePrimaerPersonen("kundenBerater", $event)
              }
            },
            model: {
              value: _vm.primaerPersonen.kundenBerater,
              callback: function($$v) {
                _vm.$set(_vm.primaerPersonen, "kundenBerater", $$v)
              },
              expression: "primaerPersonen.kundenBerater"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.loading.table
            ? _c("GhostLoading", {
                attrs: { type: "table", title: _vm.TABLE_TITLE }
              })
            : _c("AnsprechpartnerTabelle", {
                attrs: {
                  title: _vm.TABLE_TITLE,
                  maklerAnsprechpartner: _vm.ansprechpartnerList
                },
                on: { delete: _vm.deletePerson }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }