<template>
  <BaseModal 
    ref="modal" 
    alwaysScrollable
    @close="close" 
    :showDefaultButtons="false"
    :actions="modalActions"
    @action-SAVE="confirmAccept()"
    @action-CLOSE="close()"
    @action-TOGGLE-FREE-STYLE="freeStyle = !freeStyle"
  >
    <template v-slot:modalTitle>
      Bild zuschneiden
    </template>
    <div>
      <div v-if="simpleDialog">
        <div class="main">
          <EdgeSelectionDialog :imageData="src" :corners="null" @change="onChange" />
        </div>
      </div>
      <div v-else>
        <div v-if="!freeStyle" class="simple-main__wrapper">
          <div class="main">
            <div :style="{'max-width': '100%', position: 'relative', 'height': '100%'}">
              <img ref="img" :src="src" crossorigin="anonymous"/>
            </div>
            <div class="overlay" :style="{left: 0, top: 0, bottom: (1-bottom) * 100 + '%', right: (1-left) * 100 + '%'}"></div>
            <div class="overlay" :style="{left: 0, top: bottom * 100 + '%', bottom: 0, right: (1-right) * 100 + '%'}"></div>
            <div class="overlay" :style="{left: right * 100 + '%', top: top * 100 + '%', bottom: 0, right: 0}"></div>
            <div class="overlay" :style="{left: left * 100 + '%', top: 0, bottom: (1-top) * 100 + '%', right: 0}"></div>
            <div class="overlay" :style="{left: left * 100 + '%', top: top * 100 + '%', bottom: (1-bottom) * 100 + '%', right: (1-right) * 100 + '%', backgroundColor: 'unset', outline: '1px solid black'}"></div>
            <div class="corner" v-for="(corner, index) in corners" :key="index" @mousedown="(e)=>dragStart(corner, e)" @touchstart="(e)=>dragStart(corner, e)" :style="{left: corner.x * 100 + '%', top: corner.y * 100 + '%'}">
            </div>
          </div>
        </div>
        <div class="main" v-else>
          <EdgeSelectionDialog :imageData="src" :corners="detectedCorners" @change="onChange"/>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script lang="ts">
import Vue from 'vue';
import BaseModal from '@/components/core/BaseModal.vue';
import EdgeSelectionDialog from './EdgeSelectionDialog.vue';
import {fileFromUrlData} from '@/helpers/upload-helper'
import { BaseModalSimpleAction } from '@/components/core/base-modal-actions/base-modal-actions-utils';

export default Vue.extend({
  components: {
    BaseModal,
    EdgeSelectionDialog,
  },

    props: {
        src: {
            type: String,
        },
        detectedCorners: {
            type: Array,
            default: null,
        },
        simpleDialog: {
            type: Boolean,
            default: false,
        },
    },

  data() {
    return {
      left: 0.1,
      right: 0.9,
      top: 0.1,
      bottom: 0.9,
      edgeSelectionResult: null,
      freeStyle: this.detectedCorners != null,
    }
  },
  computed: {
    corners() {
      return [
        {x: this.left, y: this.top},
        {x: this.left, y: this.bottom},
        {x: this.right, y: this.top},
        {x: this.right, y: this.bottom},
      ]
    },
    modalActions() {
      return [
        BaseModalSimpleAction('SAVE', 'Speichern'),
        BaseModalSimpleAction('CLOSE', 'Abbrechen')
          .withVisibleOnSmallScreen(() => false)
          .withPrimary(() => false),
        BaseModalSimpleAction('TOGGLE-FREE-STYLE', this.freeStyle ? 'Einfacher Modus' : 'Mehr Optionen')
          .withVisible(() => !this.simpleDialog)
          .withPrimary(() => false),
      ];
    },
  },

  mounted() {
    this.$refs.modal.open();
  },

  methods: {
    dragStart(corner, event) {
      event = event || window.event;
      event.preventDefault();
      document.onmouseup = (e) => this.dragEnd(e);
      document.ontouchend = (e) => this.dragEnd(e);
      document.ontouchcancel = (e) => this.dragEnd(e);
      const fixedSides = {
        x: this.left == corner.x ? this.right : this.left,
        y: this.top == corner.y ? this.bottom : this.top,
      }
      document.onmousemove = (e) => this.dragCorner(fixedSides, e);
      document.ontouchmove = (e) => this.dragCorner(fixedSides, e);
    },
    dragCorner(fixedSides, event) {
      event = event || window.event;
      event.preventDefault();
      let positionObj = event;
      if (event.changedTouches)
        positionObj = event.changedTouches[0];
      let rect = this.$refs.img.getBoundingClientRect();
      const targetX = Math.max(0, Math.min(1, (positionObj.clientX - rect.left) / rect.width));
      const targetY = Math.max(0, Math.min(1, (positionObj.clientY - rect.top) / rect.height));
      this.left = Math.min(fixedSides.x, targetX);
      this.right = Math.max(fixedSides.x, targetX);
      this.top = Math.min(fixedSides.y, targetY);
      this.bottom = Math.max(fixedSides.y, targetY);
    },
    dragEnd(event) {
      event = event || window.event;
      event.preventDefault();
      document.onmouseup = null;
      document.onmousemove = null;
      document.ontouchend = null;
      document.ontouchmove = null;
    },
    onChange(urlData) {
      this.edgeSelectionResult = urlData;
    },
    confirmAccept() {
      this.$confirmModal({
        message: `
          Sind Sie sicher, dass Sie dieses Bild zuschneiden wollen? 
          Nach der Bestätigung ist es nicht mehr möglich das Bild wiederherzustellen und Sie müssen das Bild erneut hochladen.
        `,
      })
      .then(() => this.accept());
    },
    accept() {
      const emitFileAndClose = (file) => {
        this.$emit("file", file);
        this.$refs.modal.close();
      };

      if (!this.freeStyle) {
        const canvas = document.createElement("canvas");
        canvas.width = (this.right - this.left) * this.$refs.img.naturalWidth;
        canvas.height = (this.bottom - this.top) * this.$refs.img.naturalHeight;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(this.$refs.img, this.left * this.$refs.img.naturalWidth, this.top * this.$refs.img.naturalHeight, canvas.width, canvas.height, 0, 0, canvas.width, canvas.height);
        canvas.toBlob(blob => emitFileAndClose(blob));
      }
      else {
        emitFileAndClose(fileFromUrlData(this.edgeSelectionResult, "image_" + Math.round(Math.random() * 100000) + ".jpeg", 'image/jpeg'));
      }
    },
    close() {
      this.$emit("cancel");
      this.$refs.modal.close();
    },
  },
})
</script>

<style scoped>
hr {
  width: 100%;
}
.simple-main__wrapper {
  overflow: hidden;
  padding: 20px 12px 12px 20px;
}
.main {
  position: relative;
  display: inline-block;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  flex-shrink: 1;
}
.simple-main__wrapper .main {
  overflow: visible;
}
canvas {
  max-width: 100%;
  max-height: 100%;
}
img {
  max-width: 100%;
  max-height: 100%;
}
.corner {
  position: absolute;
  width: 30px;
  height: 30px;
  margin-left: -19px;
  margin-top: -19px;
  border: 4px solid black;
  border-radius: 10em;
  z-index: 1;
}
.overlay {
  position: absolute;
  background-color: var(--color-box);
  opacity: 0.8;
  z-index: 1;
}
</style>
