<template>
  <div>
    <BaseModal  @onConfirmButton="versicherungKopieren()" ref="copyInsuranceConfirmationModal" modalTitle="Versicherung kopieren Bestätigung" :showCancelButton="true" :showCloseButton="true"  labelButtonConfirm="Ok">
      Möchten Sie diese Versicherung wirklich kopieren?
    </BaseModal>  


  </div>
</template>

<script>
import BaseModal from '@/components/core/BaseModal.vue';
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";

export default {
    components: {
      BaseModal,
    },

    data() {
      return {
      }
    },
    computed: {
    },
    methods: {
      open() {
        this.$refs.copyInsuranceConfirmationModal.open();
      },
      versicherungKopieren() {
        return this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.COPY_INSURANCE, {
          vertragId: this.$route.params.id,
        }).then((response) => {
          this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.RETRIEVE_INSURANCE_DETAILS, { versVertrag: response?.value })
            .then((response) => {
              const insurance = response?.insurance;
              this.$emit("open-item", insurance);
              // this.openItemSelectedDetail(insurance);
            });

        });

      },      
    }
}
</script>
