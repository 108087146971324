<template>
  <div v-if="this.rec && this.rec.barkodePages.length>0">
    <PageHeaderTitleNavigation
      title="Versandprotokolle"
      :actions="headerActions" 
      @action-SEND="sendDaten()"
      @action-EDIT_BARCODE="editBarcodes(selectedRows)"
      @action-EDIT_GESELLSCHAFT="editKundeGesell(selectedRows)"
      @action-KUNDE_ZUORDNEN="editKunde(selectedRows)"
    />

      <Table
        :aria-disabled="runing"
        tableId="0a852a36-7335-11ed-a1eb-0242ac120002"
        :headers="headers"
        :rows="rows"
        :rowsPerPage="1000"
        rowId="rowid"
        v-model="selectedRows"
        @click-mappe="clickMappe"
        @action-editBarcodes="editBarcodes([$event])"
        @action-editGesellschaft="editKundeGesell([$event])"
        @action-editKunde="editKunde([$event])"
        @action-deakt="deakt"
        @click-kundennr="openCustomerNewTab"
        @click-kundenname="openCustomerNewTab"
      >
        <template #rownum="row">
          <DownloadLink
            target="_blank"
            rel="noopener noreferer"
            :title="str(row.rownum)"
            downloadServicePath="/page_download"
            :queryParams="{
              eingangId: row.eingangId,
              pageNo: row.rownum,
            }"
          />
        </template>
      </Table>
    
    <editBarcodes ref="editBarcodes" />
    <editKundeGesell ref="editKundeGesell" />
    <editKundeDeckBlatt ref="editKundeDeckBlatt" />
    <mappeDialog ref="mappeDialog" />
  </div>
</template>
  
  <script>
  import CORE_TYPES from '@/store/core/types'
import CUSTOMER_SEARCH_TYPES from '@/store/customerSearch/types';
import { mapGetters } from "vuex";
import DownloadLink from "@/components/core/download/DownloadLink.vue";
import Table from "@/components/table2/Table.vue";
import V_P_TYPES from "@/store/versandProtokolle/types";
import { PhCheckCircle, PhWarningOctagon, PhWarningCircle } from "phosphor-vue";
import editBarcodes from "@/views/communication/versandProtokolle/EditBarcodes.vue";
import editKundeGesell from "@/views/communication/versandProtokolle/EditKundeGesell.vue";
import editKundeDeckBlatt from "@/views/communication/versandProtokolle/EditKundenDeckBlatt.vue";
import mappeDialog  from "@/views/communication/versandProtokolle/MappeDialog.vue";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import {
  TextColumn,
  HtmlColumn,
  PillColumn,
  ActionColumn,
  SlotColumn,
  SimpleAction,
  IconColumn,
  Icon,
} from "@/components/table2/table_util.js";
export default {
  components: {
    Table,
    PhCheckCircle,
    PhWarningOctagon,
    PhWarningCircle,
    DownloadLink,
    editBarcodes,
    editKundeGesell,
    editKundeDeckBlatt,
    mappeDialog,
    OptionMenu,
    PageHeaderTitleNavigation,
  },
  computed: {
    ...mapGetters({
      rec: V_P_TYPES.GETTERS.EINGANG,
      runing:V_P_TYPES.GETTERS.PAGES_ACTIVE, 
    }),
    headers() {
      return {
        lockedLeft: [
          SlotColumn("rownum", "").makeSortable(false),
          IconColumn("act", ""),
          IconColumn("warnMessageIcon", ""),
          TextColumn("barcode", "Barcode").makeSortable(false),
          TextColumn("kundennr", "Kundennr").makeLink().makeSortable(false),
        ].filter((column) => !this.disabledColumns.includes(column.key)),
        center: [
          TextColumn("kundenname", "Name").makeLink() .makeSortable(false),

          TextColumn("gesellschaft", "Gesellschaft").makeSortable(false),
          TextColumn("bezeichnung", "Dokument").makeSortable(false),
          TextColumn("pruefnummerDokument", "P# Dokument").makeSortable(false),
          TextColumn("pruefnummerAp", "P# AP").makeSortable(false),
          PillColumn("mappe", "Beratungsmappe").makeLink().makeSortable(false),
          HtmlColumn("warnMessage", "WarnMessage")
            .setColorFn((row) => {
              return "color-danger";
            })
            .makeSortable(false),
        ].filter((column) => !this.disabledColumns.includes(column.key)),
        lockedRight: [ActionColumn("actions", "Aktionen")].filter(
          (column) => !this.disabledColumns.includes(column.key)
        ),
      };
    },

    disabledColumns() {
      return [];
    },
    rows() {
      this.selectedRows = [];
      if (!this.rec || !this.rec.barkodePages) return [];
      return this.rec.barkodePages.map((record) => {
        const row = {
          ...record,
          selectedDisabled: !record.actions?.length,
          rowid: record.rownum,
        };
        row.rowid = record.rownum + "";
        if (record.active) {
          row.act = [Icon(PhCheckCircle, "", 25, "fill", "color-success")];
        } else {
          row.act = [Icon(PhWarningCircle, "", 25, "fill", "color-danger")];
        }
        if (record.warnMessage) {
          // row.warnMessage = { label: record.warnMessage, type: 'danger' }
          row.warnMessageIcon = [
            Icon(PhWarningOctagon, row.warnMessage, 25, "fill", "color-danger"),
          ];
        }
        if (record.actions) {
          row.actions = record.actions.map((legend) => {
            return SimpleAction(legend.key, legend.icon, legend.label);
          });
        }
        return row;
      });
    },
    metadata() {},
    headerActions() {
      return [
        PageHeaderSimpleAction('SEND', 'Senden')
          .withVisible(() => this.rec && this.rec.barkodePages.length > 0)
          .withDisabled(() => this.runing),
        PageHeaderSimpleAction('EDIT_BARCODE', 'Barcode eingeben')
          .withVisible(() => this.rows.some(row => row.actions?.some(action => action.actionKey === 'editBarcodes')))
          .withDisabled(() => this.runing || !this.selectedRows.some(row => row.actions?.some(action => action.actionKey === 'editBarcodes'))),
        PageHeaderSimpleAction('EDIT_GESELLSCHAFT', 'Gesellschaft eingeben')
          .withVisible(() => this.rows.some(row => row.actions?.some(action => action.actionKey === 'editGesellschaft')))
          .withDisabled(() => this.runing || !this.selectedRows.some(row => row.actions?.some(action => action.actionKey === 'editGesellschaft'))),
        PageHeaderSimpleAction('KUNDE_ZUORDNEN', 'Nur dem Kunden zuordnen (ohne Versand)')
          .withVisible(() => this.rows.some(row => row.actions?.some(action => action.actionKey === 'editKunde')))
          .withDisabled(() => this.runing || !this.selectedRows.some(row => row.actions?.some(action => action.actionKey === 'editKunde'))),
      ];
    },
  },

  mounted() {
    this.$store.commit(V_P_TYPES.MUTATIONS.EINGANG,null);
    this.loadEingang();
  },

  data() {
    return {
      eingangId: this.$route.params.eingangId,
      selectedRows: [],      
    };
  },
  watch: {},

  methods: {
    async loadEingang() {
      this.$store.commit(V_P_TYPES.MUTATIONS.EINGANG, null);
      await this.$store.dispatch(V_P_TYPES.ACTIONS.EINGANG, this.eingangId);
    },
    str(rownum) {
      return "Seite " + rownum;
    },
    editBarcodes(rows) {
      const filteredRows = rows.filter(row => row.actions?.some(action => action.actionKey === 'editBarcodes'))
      const id = rows[0].eingangId

      this.$refs.editBarcodes?.open(id, filteredRows);
    },

    editKundeGesell(rows) {
      const filteredRows = rows.filter(row => row.actions?.some(action => action.actionKey === 'editGesellschaft'))
      const id = rows[0].eingangId

      this.$refs.editKundeGesell?.open(id, filteredRows);
    },
    editKunde(rows) {
      const filteredRows = rows.filter(row => row.actions?.some(action => action.actionKey === 'editKunde'))
      const id = rows[0].eingangId

      this.$refs.editKundeDeckBlatt?.open(id, filteredRows);
    },
    async deakt(row) {
      await this.$store.dispatch(V_P_TYPES.ACTIONS.DEACT, row);
    },
    async clickMappe(row){
      this.$refs.mappeDialog?.open(row.mappen);
      
    },

   async sendDaten() {
     
      if (this.runing) {
        return;
      }
      try {
       let e={};
       e.eingangId=this.eingangId;
       e.back=true; 
       e.fromFiltr=false;
       this.$store.dispatch(V_P_TYPES.ACTIONS.SEND, e);
      } finally {
        
      }
    },
    openCustomerNewTab(row, params = {}) {
            if (row?.kundennr) {
                this.$store.commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.OPEN_CUSTOMER, { data: 
                    { customerID: row.kundennr,
                        person: {personalDataAddress: {firstName: row.kundenname, lastName: row.kundenname }} }});
                this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, Object.assign({}, { customerId: row.kundennr}, params));
            }
        },    
  },
};
</script>
  
  <style scoped>
.subject {
  display: inline-block;
  max-width: 700px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>