<template>
  <div>
    <InputField
      label="langfristige Kredite / Darlehen"
      v-model="longTermCredit"
      type="currency"
      isComponentHalfSize
      :disabled="isDisabled || isRefusedPage"
      @input="updateStore('finance', 'longTermCredit', $event || 0)" />
    <InputField
      label="kurzfristige Kredite (Kontokorrent, Zwischenfinanzierungen)"
      v-model="shortTermCredit"
      type="currency"
      isComponentHalfSize
      :disabled="isDisabled || isRefusedPage"
      @input="updateStore('finance', 'shortTermCredit', $event || 0)" />
    <InputField
      label="Sonstige Verpflichtungen zu Gunsten Dritter (Verpfändungen, Bürgschaften)"
      v-model="otherCredit"
      type="currency"
      isComponentHalfSize
      :disabled="isDisabled || isRefusedPage"
      @input="updateStore('finance', 'otherCredit', $event || 0)" /> 
    
    <div class="sum d-flex justify-content-between">
      <div class="my-2">Summe Verbindlichkeiten / Schulden</div>
      <div class="my-2 pr-3"><CurrencyLabel :value="summeSchulden"/></div>
    </div>
  </div>
</template>

<script>

import InputField from '@/components/core/forms/InputField.vue';
import anlegerprofilMixin from '@/mixins/anlegerprofil/anlegerprofil-mixin.js';
import CurrencyLabel from '@/components/core/CurrencyLabel.vue';

export default {
  mixins: [anlegerprofilMixin],
  components: {
    InputField,
    CurrencyLabel,
  },
  data() {
    return {
      longTermCredit: '',
      shortTermCredit: '',
      otherCredit: '',
    };
  },
  computed: {
    isRefusedPage() {
      return this._isRefusedPage(this.data, 'finanzen');
    },
  },
  mounted() {
    this.createForm();
    this.updateWarnings();
  },
  methods: {
    createForm() {
      const data = this.data && this.data.finance || {};
      this.longTermCredit = data.longTermCredit || '';
      this.shortTermCredit = data.shortTermCredit || '';
      this.otherCredit = data.otherCredit || '';
    },
  },
}
</script>

<style scoped>
  .sum {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    font-size: 1.25rem;
    font-weight: bold;
  }
</style>