<template>
  <div>
    <MailComposer
      :custom="true"
      :customFunctions="customFunctions"
      @callbacks="setCallbacks"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MailComposer from '../MailComposer.vue';
import MAILCOMPOSER_TYPES from '@/store/mailcomposer/types';
import BaseButton from '@/components/core/BaseButton.vue';
import {
  MAX_ITEMS_VISIBLE,
  KUNDE_TYPE,
  BROKER_TYPE,
  VERSICHERUNG_TAG_DESCRIPTION,
  GESELLSCHAFT_TAG_DESCRIPTION,
  KUNDE_TAG_DESCRIPTION,
  VERSICHERUNG_KUNDE_TAG_DESCRIPTION,
  VERSICHERUNG_GESELLSCHAFT_TAG_DESCRIPTION,
  VORLAGEN_TYPE_MAIL,
  VORLAGEN_TYPE_BRIEF, validateEmail, getFileName, getFileType
} from '../MailComposer.vue';
import validator from "@/mixins/validator";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import CORE_TYPES from "@/store/core/types";

export default {
  name: 'AnlageempfehlungMailComposer',
  components: {
    MailComposer,
  },

  props: {},

  computed: {
    ...mapGetters({
      initAnlageempfehlungMail: MAILCOMPOSER_TYPES.GETTERS.INIT_MAIL_ANLAGEEMPFELUNG,
      savedConfiguration: MAILCOMPOSER_TYPES.GETTERS.GET_MAILCOMPOSER_CONFIG,
      attachments: MAILCOMPOSER_TYPES.GETTERS.SET_ATTACHMENTS,
    }),
  },

  data() {
    return {
      callbacks: {},
      customFunctions: {
        getRoute: () => {
          if (this.$route.meta.investment) {
            return 'mailcomposer-investment';
          }
          switch (this.$route.meta.type) {
            case 'ONE_MAIL':
              return 'mailcomposer';
            case 'MANY_MAIL':
              return 'mailcomposer-bulk';
            case 'APP':
              return 'mailcomposer-app';
            case 'BRIEF':
              if (this.$route.meta.serienbrief) {
                return 'mailcomposer-serienbrief';
              }
              return 'mailcomposer-brief';
            case 'BRIEF_MAIL':
              return 'mailcomposer-brief-email';
            case 'FAX':
              return 'mailcomposer-fax';
            default:
              return 'mailcomposer';
          }
        }
      }
    };
  },
  watch: {
    initAnlageempfehlungMail(value) {
      if (value && this.$route.meta.investment) {
        if (!this.savedConfiguration.subject) {
          this.callbacks.setSubject(value.subject);
        }
        if (!this.savedConfiguration.getComputedText) {
          this.callbacks.setHtmlText(value.html);
        }

        if (!this.attachments || !this.attachments.length) {
          const attachments = value.attachments.map(att => ({id: att.id, label: att.name, fileName: att.name,}))
          if (attachments.length) {
            this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, attachments);
          }
        }

        if (!this.savedConfiguration.receiver || !this.savedConfiguration.receiver.length) {
          let receiver = [];
          value.participants.filter(p => p.sendType != 'FROM').map(p => {
            receiver.push({
              value: p.user.userId,
              label: `${p.fullName}`,
              email: `${p.email}`,
              type: p.user.type,
            });
          });
          this.callbacks.setReceiver(receiver);
        }
      }
    },
  },
  async mounted() {
    if (this.$route.meta.investment) {
      const investmentId = this.$route.params.id + '';
      this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.INIT_MAIL_ANLAGEEMPFELUNG, { investmentId });
    }
  },

  methods: {
    setCallbacks(value) {
      this.callbacks = value;
    },
  },
  validators: {},
  beforeRouteLeave(to, from, next) {
    this.callbacks.handleBeforeRouteLeave(to, from, next)
  }
};
</script>

<style scoped>
</style>
