import DOCUMENT_TYPES from './types';
import { getInitialState } from './index'
import Vue from 'vue'

export default {
  [DOCUMENT_TYPES.MUTATIONS.RETRIEVE_DOCUMENT_SUCCESS](state, payload) {
    state.documentData = payload;
  }, 
  [DOCUMENT_TYPES.MUTATIONS.RESET_STATE](state, payload) {
    Object.assign(state, getInitialState())
  },
  [DOCUMENT_TYPES.MUTATIONS.RETRIEVE_DOCUMENT_HISTORY_SUCCESS](state, payload) {
    state.documentHistory = payload;
  }, 
  [DOCUMENT_TYPES.MUTATIONS.UPDATE_COLLAPSED](state, payload) {
    const data = {
      ...state.collapsedMap[payload.uid],
      [payload.key]: payload.value
    }

    Vue.set(state.collapsedMap, payload.uid, data);
  },

  [DOCUMENT_TYPES.MUTATIONS.SET_COLLAPSED](state, payload) {
    Vue.set(state.collapsedMap, payload.uid, payload.data);
  },
}