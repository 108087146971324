<template>
  <div class="input-forms__container">
    <div class="input-forms__label-container">
      <div class="row mt-0">
        <div class="col">
          <div class="input-forms__label-content input-forms__label-content--bigger" v-if="title">
            <ph-bank :size="16" />&nbsp;{{title}}
          </div>
        </div>
        <div class="col-auto" v-if="config && config.buttonText && 
        (componentType === 'WERTPAPIER_AUSWAHL' || 
          componentType === 'WERTPAPIER_AUSWAHL_DIREKT' || 
          componentType === 'WERTPAPIER_AUSWAHL_CAPITALBANK_INVESTMENT')">
          <BaseButton :disabled="disabled" @click="openFormsFinder">{{config.buttonText}}</BaseButton>
        </div>
      </div>
    </div>
    <div class="input-forms__input-container">
      <component 
        :is="dynamicTemplate" 
        v-if="dynamicTemplate"
        v-bind="dynamicBindings.props"
        v-on="dynamicBindings.events"
      />

      <div v-if="positions && positions.length && config && config.positionFields">
        <WertpapierAuswahlPositionFields 
          :config="config"
          :categoryId="categoryId"
          :antragId="antragId"
          :disabled="disabled"
        />
      </div>
       <BaseModal
        ref="fehlerModal"
        labelButtonCancel="Ok"
        :showConfirmButton="false"
      >
        <template v-slot:modalTitle>
          <span>
             <ph-warning :size="16" class="color-danger mr-2" />
            <div class="info-modal--content">{{ textInfo }}</div>
         </span>
        </template>
      </BaseModal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ANTRAG_TYPES from '@/store/antrag/types';
import BaseButton from '@/components/core/BaseButton.vue';
import WertpapierAuswahlPositions from '@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlPositions.vue';
import WertpapierAuswahlPositionFields from '@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlPositionFields.vue';
import antragNavigationMixin from '@/mixins/antrag/antrag-navigation-mixin.js';
import antragMixin from '@/mixins/antrag/antrag-mixin.js';
import FONDSFINDER_TYPES from '@/store/fondsfinder/types';
import { PhBank, PhWarning } from 'phosphor-vue';
import BaseModal from "@/components/core/BaseModal.vue";

export default {
  mixins: [antragNavigationMixin, antragMixin],
  props: {    
    title: {
    },
    antragData: {
    },
    config: {
    },
    categoryId: {
    },
    antragId: {
    },
    componentId: {
    },
    antragTyp: {
    },
    componentType: {
      type: String,
      default: 'WERTPAPIER_AUSWAHL',
    },
    suppressFilterLagerstelle: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{
       textInfo: null,
    }
  },
  components: {
    BaseButton,
    WertpapierAuswahlPositions,
    WertpapierAuswahlPositionFields,
    PhBank,
    PhWarning,
    BaseModal
  },
  computed: {
    ...mapGetters({
      positionsAll: ANTRAG_TYPES.GETTERS.POSITIONS,
      positionDataAll: ANTRAG_TYPES.GETTERS.POSITION_DATA,
      positionInfo: FONDSFINDER_TYPES.GETTERS.POSITION_INFO,
    }),
    dynamicTemplate() {
      if (this.componentType) {
        switch (this.componentType) {
            case 'WERTPAPIER_AUSWAHL':
            case 'WERTPAPIER_AUSWAHL_DIREKT':
                return () => import('@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlPositions.vue')
            case 'WERTPAPIER_AUSWAHL_CAPITALBANK_INVESTMENT':
                return () => import('@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlPositionsCapitalbankInvestement.vue')
            case 'WERTPAPIER_AUSWAHL_MAX_COUNT':
                return () => import('@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlPositionsMaxCount.vue')
        }
      }
    },
    dynamicBindings() {
      return {
        props: {
          positions: this.positions,
          config: this.config,
          categoryId: this.categoryId,
          antragId: this.antragId,
          /*comboboxSelections: this.comboboxSelections,*/
          antragData: this.antragData,
          disabled: this.disabled,
        },
        events: {
          fondsfinder: () => this.openFormsFinder(),
        }
      }
    },
    positions() {
      if (this.positionsAll && this.antragId && this.categoryId 
          && this.positionsAll[this.antragId] 
          && this.positionsAll[this.antragId][this.categoryId]) {
        return this.positionsAll[this.antragId][this.categoryId] || [];
      }
    },
    positionData() {
      if (this.positionDataAll && this.antragId && this.categoryId 
          && this.positionDataAll[this.antragId]
          && this.positionDataAll[this.antragId][this.categoryId]) {
        return this.positionDataAll[this.antragId][this.categoryId]
      }
    },
    hasPositions() {
      return Array.isArray(this.positions) && this.positions.length > 0
    },
    configType() {
      return this.config && this.config.type
    },
  },
  watch:{
    positions(){
       if(this.config?.maxAnzahlFondsTausch && this.positionsAll[this.antragId] ){
         if(this.positionsAll &&  this.positionsAll[this.antragId][this.categoryId]?.length > this.config.maxAnzahlFondsTausch){
         this.textInfo = "Sie haben die maximale Anzahl von Positionen erreicht. Die maximale Anzahl von Positionen ist " + this.config.maxAnzahlFondsTausch;
         this.$refs.fehlerModal.open();
         this.positionsAll[this.antragId][this.categoryId] = [];
         }
       }else if(this.antragId === 'HELLOBANK-depoteroeffnung' && this.positionsAll && this.antragId  && this.positionsAll[this.antragId]){
         let posKauf =  this.positionsAll[this.antragId]['KAUF'] ? this.positionsAll[this.antragId]['KAUF'].length : 0;
         let posSparplan = this.positionsAll[this.antragId]['SPARPLAN'] ? this.positionsAll[this.antragId]['SPARPLAN'].length : 0;
         if(posKauf + posSparplan > 8 ){
          this.textInfo = "Die maximale Anzahl von Positionen ist 8. Anzahl der aktuell gewählten Positionen: " + (posKauf + posSparplan) + ". Bitte korrigieren Sie Ihre Auswahl."  
          this.$refs.fehlerModal.open();
         }
      }
    }
  },

  methods: {
   openFormsFinder() {
    if (!this.disabled) {
      if (this.componentType) {
          switch (this.componentType) {
            case 'WERTPAPIER_AUSWAHL_DIREKT':
              this.goToFormsFinder(this.categoryId, this.config, this.suppressFilterLagerstelle, true);
              break;
            default: 
            if(this.isMaxNumberPositionsReached()){
                this.textInfo = "Sie haben die maximale Anzahl von Positionen erreicht"
                this.$refs.fehlerModal.open();
            }else{
              this.goToFormsFinder(this.categoryId, this.config, this.suppressFilterLagerstelle, false);
            }
              
          }
      } else {
        this.goToFormsFinder(this.categoryId, this.config, this.suppressFilterLagerstelle);
      }
    }
   },
   isMaxNumberPositionsReached(){
    if(this.config.maxAnzahlFondsTausch && this.positions && this.positions.length >= this.config.maxAnzahlFondsTausch){
      return true;
    }else if(this.antragId === 'HELLOBANK-depoteroeffnung' && this.positionsAll && this.antragId  && this.positionsAll[this.antragId]){
      let posKauf =  this.positionsAll[this.antragId]['KAUF'] ? this.positionsAll[this.antragId]['KAUF'].length : 0;
      let posSparplan = this.positionsAll[this.antragId]['SPARPLAN'] ? this.positionsAll[this.antragId]['SPARPLAN'].length : 0;
         if(posKauf + posSparplan >= 8 ){
            return true;
         }
    }else{
      return false;
    }
   }
  }
}
</script>

<style scoped>

</style>