<template>
  <div>
    <PageHeaderTitleNavigation 
      :title="$appNavigation.currentMenu.label"
      :actions="headerActions"
      :defaultMenu="$appNavigation.currentTabMenu"
      @action-ADD-HTML="newTemplate()"
      @action-ADD-WORD="newWordTemplate()"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import TEMPLATES_EDIT_TYPES from '@/store/templatesEdit/types';
import { mapGetters } from 'vuex'

import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
  },
  props: {
    prependActions: {
      type: Array,
      default: () => [],
    },
    appendActions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
    }
  },
  watch: {
  },
  computed: {
    ...mapGetters({
    }),
    headerActions() {
      return [
        ...(this.prependActions || []),
        PageHeaderSimpleAction('ADD-HTML', 'Neue Vorlage (HTML)'),
        PageHeaderSimpleAction('ADD-WORD', 'Neue Vorlage (Word)'),
        ...(this.appendActions || []),
      ];
    },
  },
  methods: {
    newTemplate() {
      this.$store.commit(TEMPLATES_EDIT_TYPES.MUTATIONS.GET_LETTER_TEMPLATE_EDIT, null);
      this.$router.push({ path: `/communication/mailvorlage/email/new` });
    },
    newWordTemplate() {
      this.$store.commit(TEMPLATES_EDIT_TYPES.MUTATIONS.GET_WORD_TEMPLATE_EDIT, null);
      this.$router.push({ path: `/communication/mailvorlage/word/new` });
    },
  },
  beforeDestroy() {
    this.$store.dispatch(TEMPLATES_EDIT_TYPES.ACTIONS.RESET);
    this.$store.commit(TEMPLATES_EDIT_TYPES.MUTATIONS.GET_TEMPLATES, null);
  },
}
</script>

<style scoped>
</style>
