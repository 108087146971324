var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "baseModal",
    attrs: { showDefaultButtons: false, actions: _vm.modalActions, size: "sm" },
    on: {
      "action-RESET": function($event) {
        _vm.restoreDefault()
        _vm.close()
      },
      "action-SAVE": function($event) {
        _vm.saveConfigEdited()
        _vm.close()
      },
      onCloseButton: function($event) {
        return _vm.saveConfigEdited()
      },
      close: function($event) {
        _vm.onClose()
        _vm.$emit("close")
      }
    },
    scopedSlots: _vm._u([
      {
        key: "modalTitle",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "column-config-modal--title-container clearfix" },
              [
                _c("span", { staticClass: "column-config-modal--title" }, [
                  _vm._v(_vm._s(_vm.title))
                ])
              ]
            )
          ]
        },
        proxy: true
      },
      {
        key: "modalHeaderBottom",
        fn: function() {
          return [
            _vm.tableId
              ? _c("ColumnConfigTabs", {
                  attrs: {
                    disabled: _vm.loading,
                    selected: _vm.userLevelSaveConfig
                  },
                  on: {
                    tabSelected: function($event) {
                      return _vm.onTabSelected($event.key)
                    }
                  }
                })
              : _vm._e()
          ]
        },
        proxy: true
      },
      {
        key: "default",
        fn: function() {
          return [
            _c("div", [
              _c(
                "div",
                { staticClass: "filter__container" },
                [
                  _c("FixedColumnList", {
                    staticClass: "mb-16px",
                    attrs: {
                      title: "Fixe Spalten links",
                      list: _vm.internTableHeadersFixedToLeft
                    }
                  }),
                  _c("FixedColumnList", {
                    staticClass: "mb-16px",
                    attrs: {
                      title: "Fixe Spalten rechts",
                      list: _vm.internTableHeadersFixedToRight
                    }
                  }),
                  _vm.internTableHeaders.length
                    ? _c("div", { staticClass: "font-bold" }, [
                        _vm._v(
                          _vm._s(
                            "Konfigurierbare " +
                              (_vm.isMobileTable ? "Zeilen" : "Spalten")
                          )
                        )
                      ])
                    : _vm._e(),
                  _vm.loading
                    ? _c(
                        "GhostLoading",
                        _vm._l(_vm.internTableHeaders, function(header, index) {
                          return _c("Block", {
                            key: index,
                            class: index === 0 ? "mb-0" : "",
                            style: { margin: "1px 0 0 0" },
                            attrs: { height: "40px" }
                          })
                        }),
                        1
                      )
                    : _c(
                        "SortableList",
                        {
                          attrs: { items: _vm.internTableHeaders },
                          on: {
                            orderChanged: function($event) {
                              return _vm.onOrderChanged($event)
                            }
                          }
                        },
                        [
                          _c(
                            "ul",
                            {
                              staticClass: "list-bordered",
                              attrs: { "data-sortable-container": "" }
                            },
                            [
                              _vm._l(_vm.internTableHeaders, function(item) {
                                return [
                                  item.dataType !== "tableAction"
                                    ? _c(
                                        "li",
                                        {
                                          key: item.key,
                                          staticClass: "list-bordered-item",
                                          attrs: { "data-sortable-item": "" }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex justify-content-between"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "config-column-item--title"
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(_vm._s(item.label))
                                                  ])
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "config-column-item--actions"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "column-config__controls-container"
                                                    },
                                                    [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn-clear clickable",
                                                          attrs: {
                                                            type: "button",
                                                            disabled:
                                                              item.alwaysVisible
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.toggleShowColumn(
                                                                item
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          item.visible
                                                            ? _c("ph-eye", {
                                                                attrs: {
                                                                  weight:
                                                                    "bold",
                                                                  size: 16
                                                                }
                                                              })
                                                            : _c(
                                                                "ph-eye-slash",
                                                                {
                                                                  attrs: {
                                                                    weight:
                                                                      "bold",
                                                                    size: 16
                                                                  }
                                                                }
                                                              )
                                                        ],
                                                        1
                                                      ),
                                                      _vm.ordered
                                                        ? _c(
                                                            "div",
                                                            {
                                                              attrs: {
                                                                "data-sortable-item-handler":
                                                                  ""
                                                              }
                                                            },
                                                            [
                                                              _c("ph-list", {
                                                                attrs: {
                                                                  size: 16,
                                                                  weight: "bold"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              })
                            ],
                            2
                          )
                        ]
                      )
                ],
                1
              )
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }