var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "mb-5" },
        [
          _c("InputToggleSwitch", {
            attrs: {
              inLineLabel: "",
              label: "Anlage in Managed Depot erfassen",
              disabled: true
            },
            model: {
              value: _vm.djeAktiv,
              callback: function($$v) {
                _vm.djeAktiv = $$v
              },
              expression: "djeAktiv"
            }
          })
        ],
        1
      ),
      _c("InputRadioBoxGroup", {
        attrs: {
          validateUntouched: true,
          disabled: _vm.isDisabled || !_vm.isZusatzDJEAktiv,
          values: _vm.values
        },
        on: {
          input: function($event) {
            return _vm.updateStore("zusatzDJE", "djeKontakt", $event)
          }
        },
        model: {
          value: _vm.djeKontakt,
          callback: function($$v) {
            _vm.djeKontakt = $$v
          },
          expression: "djeKontakt"
        }
      }),
      _c("InputCheckBoxGroup", {
        attrs: {
          disabled:
            _vm.isDisabled || !_vm.isZusatzDJEAktiv || _vm.djeKontakt !== "1",
          title: "Zulagenberechtigung bzw. Interesse an der Nutzung",
          values: [
            { label: "per Telefon", path: "einwilligungTel" },
            { label: "per Fax", path: "einwilligungFax" },
            { label: "per E-Mail", path: "einwilligungEmail" },
            { label: "per Post", path: "einwilligungPost" },
            { label: "per Kundenapp", path: "einwilligungApp" }
          ]
        },
        on: {
          checkBoxToggle: function($event) {
            return _vm.updateStoreCheckBoxGroup("zusatzDJE", $event)
          }
        },
        model: {
          value: _vm.form,
          callback: function($$v) {
            _vm.form = $$v
          },
          expression: "form"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }