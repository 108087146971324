var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Action",
        _vm._b(
          { on: { action: _vm.requestConfirmation } },
          "Action",
          _vm.$props,
          false
        )
      ),
      _c(
        "BaseModal",
        {
          ref: "confirmationModal",
          attrs: {
            modalTitle: _vm.confirmationTitle,
            labelButtonConfirm: _vm.labelButtonConfirm,
            showCancelButton: true
          },
          on: { onConfirmButton: _vm.onClick }
        },
        [_c("label", [_vm._v(_vm._s(_vm.confirmationMessage))])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }