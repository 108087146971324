var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "availableStatusIcons__container" },
    _vm._l(_vm.currentStatusIcons, function(action) {
      return _c("span", { key: action.legend.label + _vm.uuidv4() }, [
        _vm.statusType === "VORLAGE"
          ? _c("span", [
              action.legend.key === "DOKUMENT_VORLEGEN"
                ? _c(
                    "span",
                    [
                      _vm.statusType === "VORLAGE"
                        ? _c("Pill", {
                            attrs: { label: "nicht vorgelegt", type: "danger" }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : action.legend.key === "DOKUMENT_NICHT_VORLEGEN"
                ? _c(
                    "span",
                    [
                      _c("Pill", {
                        attrs: { label: "vorgelegt", type: "info" }
                      })
                    ],
                    1
                  )
                : action.legend.key === "DOKUMENT_VORLEGEN_TEILWEISE"
                ? _c(
                    "span",
                    [
                      _c("Pill", {
                        attrs: { label: "teilweise vorgelegt", type: "warning" }
                      })
                    ],
                    1
                  )
                : action.legend.key === "DOKUMENT_VERMITTLER_NICHT_VORLEGEN"
                ? _c(
                    "span",
                    [
                      _c("Pill", {
                        attrs: { label: "Makler vorgelegt", type: "info" }
                      })
                    ],
                    1
                  )
                : action.legend.key === "DOKUMENT_KUNDE_NICHT_VORLEGEN"
                ? _c(
                    "span",
                    [
                      _c("Pill", {
                        attrs: { label: "Kunde vorgelegt", type: "info" }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ])
          : _vm._e(),
        _vm.statusType === "SIGNATUR"
          ? _c("span", [
              action.legend.key === "EINGEREICHT_ABGELEGT"
                ? _c(
                    "span",
                    [
                      _c("Pill", {
                        attrs: { label: "eingereicht", type: "success" }
                      })
                    ],
                    1
                  )
                : action.legend.key === "UNTERSCHRIEBEN"
                ? _c(
                    "span",
                    [
                      _c("Pill", {
                        attrs: { label: "unterschrieben", type: "success" }
                      })
                    ],
                    1
                  )
                : action.legend.key === "TEILWEISE_UNTERSCHRIEBEN"
                ? _c(
                    "span",
                    [
                      _c("Pill", {
                        attrs: { label: action.legend.label, type: "warning" }
                      })
                    ],
                    1
                  )
                : action.legend.key === "TEILWEISE_EINGEREICHT"
                ? _c(
                    "span",
                    [
                      _c("Pill", {
                        attrs: { label: action.legend.label, type: "success" }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ])
          : _vm._e()
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }