<template>
  <div>
    <BrokerSearch
      :isExternalSelect="true"
      :isWithPerson="true"
      @handleBackAction="handleBackAction"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BrokerSearch from "./BrokerSearch.vue";
import CUSTOMER_SEARCH_TYPES from "@/store/customerSearch/types";
import CORE_TYPES from "@/store/core/types";
import CUSTOMER_FILTERS_TYPES from "@/store/customerFilters/types";
import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import PageHeaderTitleNavigation from "@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue";
import MAILCOMPOSER_TYPES from "@/store/mailcomposer/types";

export default {
  data() {
    return {};
  },
  validators: {},
  watch: {},
  computed: {
    ...mapGetters({
      customerFilterSetup: CUSTOMER_SEARCH_TYPES.GETTERS.CUSTOMER_FILTER_SETUP,
      customerFilterSetupDepot:
        CUSTOMER_SEARCH_TYPES.GETTERS.CUSTOMER_FILTER_SETUP_DEPOT,
      customerFilterSetupVers:
        CUSTOMER_SEARCH_TYPES.GETTERS.CUSTOMER_FILTER_SETUP_VERS,
      customerWertpapierCombo:
        CUSTOMER_SEARCH_TYPES.GETTERS.CUSTOMER_WERTPAPIER_COMBO,
      isTest: CORE_TYPES.GETTERS.IS_TEST,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      isWebRTCMakler: CORE_TYPES.GETTERS.IS_WEBRTC_MAKLERZUGANG,
    }),
  },
  mounted: function() {},
  methods: {
    handleBackAction(values) {
      if (this.$route.query && this.$route.query.back) {
        // General back / new appointment back
        this.$store.commit(
          CUSTOMER_SEARCH_TYPES.MUTATIONS.SET_SELECTED_CUSTOMERS,
          values
        );
        this.$router.push({
          path: `${this.$route.query.back}?usersselected=true&backAction=true`,
        });
      } else {
        // Mail Composer back
        this.$store.commit(
          MAILCOMPOSER_TYPES.MUTATIONS.SET_PARTICIPANTS,
          values
        );
        this.$router.push({
          path: "/communication/" + this.$route.params.type,
        });
      }
    },
  },
  filters: {},
  components: {
    BrokerSearch,
    OptionMenu,
    PageHeaderTitleNavigation,
  },

  beforeRouteLeave(to, from, next) {
    // this.$addBreadcrumb({
    //   label: 'zurück zur Kundenverwaltung',
    //   to,
    //   from,
    // });

    next();
  },
};
</script>

<style scoped></style>
