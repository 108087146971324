import MAKLER_TEXTE_TYPES from './types';

import CORE_TYPES from '@/store/core/types';

import axios from 'axios';

const config = {
  defaultSpinner: true
};

export default {

  async [MAKLER_TEXTE_TYPES.ACTIONS.FIND_MAKLER_TEXT_BEREICH]({ getters, commit, }) {
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/maklerTexte/bereich`, config);
    commit(MAKLER_TEXTE_TYPES.MUTATIONS.SET_MAKLER_TEXT_BEREICH, [ ...response?.data || [], ]);
  },

  async [MAKLER_TEXTE_TYPES.ACTIONS.FIND_LIST]({ getters, commit, state, }, { bereich, forceReload, }) {
    if (!bereich || bereich in state.listByBereich && !forceReload) return;

    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/maklerTexte/${bereich}`, config);
    commit(MAKLER_TEXTE_TYPES.MUTATIONS.SET_LIST_BY_BEREICH, {
      bereich,
      data: response?.data || [],
    });
  },

  async [MAKLER_TEXTE_TYPES.ACTIONS.FIND_SELECTED_BEREICH_LIST]({ getters, dispatch, }) {
    const selectedBereich = getters[MAKLER_TEXTE_TYPES.GETTERS.SELECTED_BEREICH];
    if(selectedBereich?.id) {
      dispatch(MAKLER_TEXTE_TYPES.ACTIONS.FIND_LIST, { bereich: selectedBereich.id, forceReload: true, });
    }
  },

  async [MAKLER_TEXTE_TYPES.ACTIONS.SAVE_MAKLER_TEXTE]({ getters, dispatch, }, payload) {
    if(!payload?.bereich) return;

    await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/maklerTexte`, { ...payload, }, config);
    dispatch(MAKLER_TEXTE_TYPES.ACTIONS.FIND_SELECTED_BEREICH_LIST);
  },

  async [MAKLER_TEXTE_TYPES.ACTIONS.DELETE_MAKLER_TEXTE]({ getters, dispatch, }, { id, }) {
    if(!id) return;

    await axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/maklerTexte/${id}`, config);
    dispatch(MAKLER_TEXTE_TYPES.ACTIONS.FIND_SELECTED_BEREICH_LIST);
  },

  async [MAKLER_TEXTE_TYPES.ACTIONS.SAVE_SICHTBAR_STRUKTUR]({ getters, dispatch, }, { ids, freigabe, }) {
    if(!ids?.length) return;

    await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/maklerTexte/sichtbarStruktur?freigabe=${freigabe}`, ids, config);
    dispatch(MAKLER_TEXTE_TYPES.ACTIONS.FIND_SELECTED_BEREICH_LIST);
  },

  async [MAKLER_TEXTE_TYPES.ACTIONS.SAVE_SICHTBAR_STRUKTUR_FOR_ALL]({ getters, dispatch, }, { freigabe, }) {
    const selectedBereich = getters[MAKLER_TEXTE_TYPES.GETTERS.SELECTED_BEREICH];
    if(selectedBereich?.id) {
      const list = getters[MAKLER_TEXTE_TYPES.GETTERS.LIST_BY_BEREICH](selectedBereich.id);
      dispatch(MAKLER_TEXTE_TYPES.ACTIONS.SAVE_SICHTBAR_STRUKTUR, {
        ids: list.map(item => item.id),
        freigabe,
      });
    }
  },

}
