<template>
  <div class="box__container">
    <div class="box__title">Gewerbeschein</div>
    <FormDivider />
    <FormLabel
      label="Anforderung: Gemäß dem mit Ihnen geschlossenen Berechtigungsvertrag ist (sind) jegliche Änderung(en) Ihrer Gewerbeberechtigung, sowie Kontaktdaten (Telefonnummer, E-Mail, etc.) umgehend bekanntzugeben."
    />
  <InputRadioBoxGroup 
      :value="form.INPUT_GEWERBE_BEST1"
      :values="gewerbescheinValues"
      labelClass="font-bold"
      :disabled="disabledStatusOK"
      @input="fieldChanged($event, 'INPUT_GEWERBE_BEST1')"
      />
    <InputField
      :value="form.INPUT_GEWERBE_BEST2_TEXT"
      isComponentHalfSize
      :disabled="!form.INPUT_GEWERBE_BEST1 || disabledStatusOK"
      @change="fieldChanged($event, 'INPUT_GEWERBE_BEST2_TEXT')"
    />
    <ChecklisteDocsUpload
      :paramId="this.form.PARAM_ID"
      :topic="'Gewerbeschein'"
      :form="this.form"
    />
   <InputRadioBoxGroup 
      :value="form.INPUT_GEWERBE_BEST3"
      :values="adresseValues"
      labelClass="font-bold"
      :disabled="disabledStatusOK"
      @input="fieldChanged($event, 'INPUT_GEWERBE_BEST3')"
      />
    <InputField
      :value="form.INPUT_GEWERBE_STRASSE"
      label="Strasse"
      isComponentHalfSize
      :disabled="!form.INPUT_GEWERBE_BEST3 || disabledStatusOK"
      @change="fieldChanged($event, 'INPUT_GEWERBE_STRASSE')"
    />
    <InputField
      label="PLZ"
      :value="form.INPUT_GEWERBE_PLZ"
      isComponentHalfSize
      :disabled="!form.INPUT_GEWERBE_BEST3 || disabledStatusOK"
      @change="fieldChanged($event, 'INPUT_GEWERBE_PLZ')"
    />
    <InputField
      label="Ort"
      :value="form.INPUT_GEWERBE_ORT"
      isComponentHalfSize
      :disabled="!form.INPUT_GEWERBE_BEST3 || disabledStatusOK"
      @change="fieldChanged($event, 'INPUT_GEWERBE_ORT')"
    />
    <FormDivider />
    <InputToggleSwitch
      label="Ich bestätige hiermit, die Angaben wahrheitsgetreu getätigt zu haben."
      :inLineLabel="true"
      :config="{ bold: true }"
      isComponentHalfSize
      :disabled="disabledStatusOK"
     :value="true"
    />
  </div>
</template>
<script>
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import FormLabel from "@/components/core/forms/FormLabel.vue";
import InputField from "@/components/core/forms/InputField.vue";
import FormDivider from "@/components/core/forms/FormDivider.vue";
import ChecklisteDocsUpload from "./ChecklisteDocsUpload.vue";
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
export default {
  components: {
    InputField,
    InputToggleSwitch,
    FormLabel,
    FormDivider,
    ChecklisteDocsUpload,
    InputRadioBoxGroup
  },
  computed:{
     disabledStatusOK(){
     return  this.form.INPUT_STATUS_OK == '1' || this.form.INPUT_STATUS_OK == 'true'
   }
  },
  props: {
    form: {},
  },
  data() {
    return {
      gewerbescheinValues:[
        { label:"Es gab keine Änderungen", value:false },
        { label:"Es gibt folgende noch nicht bekanntgegebene Änderungen:", value:true }
      ],
      adresseValues:[
        { label:"Die Adresse im Gewerbeschein ist identisch mit der Adresse gem. Firmenbuch (wie auch die FMA-Meldung)", value:false },
        { label:"Die Adresse ist abweichend vom Firmenbuch (wie auch die FMA-Meldung):", value:true }
      ]
    };
  },
  methods: {
    fieldChanged(newValue, propName) {
      const payload = { name: propName, value: newValue };
      this.$emit("fieldChanged", payload);
    },
  },
};
</script>