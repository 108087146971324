<template>
  <div>
    <OptionMenu
      :id="$appNavigation.currentOptionMenuId"
      :defaultMenu="$appNavigation.currentOptionMenu"
    />

    <PageHeaderTitleNavigation
      :title="$appNavigation.currentMenu.label"
      :id="$appNavigation.currentOptionMenuId"
      :actions="pageHeaderActions"
      v-on="pageHeaderActionListeners"
    />

    <BaseFilter
      v-bind="baseFilterConfigs"
      :filterId="componentIds.filterId"
      v-on="baseFilterListeners">
    </BaseFilter>

    <div class="box__container" :tid="componentIds.tid">
      <Table
        v-if="!loading.list && tableConfigs.rows.length"
        :tableId="componentIds.tableId"
        v-bind="tableConfigs"
        v-on="tableListeners"
      />
      <GhostLoading
        v-else-if="loading.list"
        type="table"
        :title="tableConfigs.title"
      />
      <NoData v-else-if="tableConfigs.rows.length === 0" :title="tableConfigs.title" />
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Table from '@/components/table2/Table.vue';
import BaseFilter from '@/components/core/BaseFilter.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import InteractiveHelpCommonsMixin from '@/assets/mixins/interactivehelpcommonsmixins.js';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';

export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    Table,
    PageHeaderTitleNavigation,
    OptionMenu,
    BaseFilter,
    GhostLoading,
    NoData,
  },
  props: {
    componentIds: {
      type: Object,
      default: () => ({}),
    },
    pageHeaderActionListeners: {
      type: Object,
      default: () => ({}),
    },
    pageHeaderActions: {
      type: Array,
      default: () => [],
    },
    baseFilterConfigs: {
      type: Object,
      default: () => ({}),
    },
    baseFilterListeners: {
      type: Object,
      default: () => ({}),
    },
    tableConfigs: {
      type: Object,
      default: () => ({}),
    },
    tableListeners: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Object,
      default: () => ({
        create: false,
        list: false,
      })
    }
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
    }),
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      to,
      from,
    });

    next();
  },
  methods: {
  },
};
</script>

<style scoped></style>
