<template>
    <span>
        <template v-for="(item, i) in iconList">
            <span :key="i" :title="item.title">
                <component :is="item.icon" v-bind="item" @click="onClick(i, item)"/>
            </span>
        </template>
    </span>
</template>
<script>
import * as phosphor from 'phosphor-vue';

export default {
    exportAsString(props) {
        let icons = props.row[props.column] || [];
        if (icons.icon) {
            icons = [icons];
        }
        return icons.map(icon => icon.title).join(", ");
    },
    props: {
        column: {
            type: String,
        },
        row: {
            type: Object,
        },
    },
    computed: {
        iconList () {
            let icons = this.row[this.column] || [];
            if (icons.icon) {
                icons = [icons];
            }
            return icons
                .map(item => ({
                    ...item,
                    icon: typeof(item.icon) == 'string' ? phosphor[item.icon] : item.icon,
                }));
        },
    },
    methods: {
        onClick(key, row) {
            //console.log(key, row);
            this.$emit("onClick", {key, row});
        }
    },
}
</script>