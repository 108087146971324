import Vue from 'vue';

import BROKER_PERMISSIONS_TYPES, { __MAKLER__, } from './types';
import { getInitialState, } from './index'

export default {

  [BROKER_PERMISSIONS_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },

  [BROKER_PERMISSIONS_TYPES.MUTATIONS.SET_COMBO_DATA](state, payload) {
    Vue.set(state, 'comboData', { ...payload || {}, });
  },

  [BROKER_PERMISSIONS_TYPES.MUTATIONS.SET_PERMISSIONS](state, payload) {
    Vue.set(state, 'permissions', { ...payload || {}, });
  },

  [BROKER_PERMISSIONS_TYPES.MUTATIONS.SET_PERMISSIONS_EDITED](state, payload) {
    Vue.set(state, 'permissionsEdited', {
      ...this.permissions,
      ...payload,
    });
  },

  [BROKER_PERMISSIONS_TYPES.MUTATIONS.RESET_PERMISSIONS_EDITED](state) {
    Vue.set(state, 'permissionsEdited', null);
  },

  [BROKER_PERMISSIONS_TYPES.MUTATIONS.SET_ADDITIONAL_PERMISSIONS](state, payload) {
    Vue.set(state, 'additionalPermissions', {
      ...state.additionalPermissions || {},
      ...payload || {},
    });
  },

  [BROKER_PERMISSIONS_TYPES.MUTATIONS.ADD_ADDITIONAL_PERMISSIONS_EDITED](state, { unternr, data, }) {
    const { additionalPermissionsEdited, } = state;
    Vue.set(state, 'additionalPermissionsEdited', {
      ...additionalPermissionsEdited || {},
      data: {
        ...additionalPermissionsEdited?.data || {},
        ...data,
      },
      unternr: unternr === __MAKLER__ ? undefined : unternr,
    });
  },

  [BROKER_PERMISSIONS_TYPES.MUTATIONS.RESET_ADDITIONAL_PERMISSIONS_EDITED](state) {
    Vue.set(state, 'additionalPermissionsEdited', null);
  },

}
