
import Action from './actionTypes/Action.vue';
import ConfirmedAction from './actionTypes/ConfirmedAction.vue';
import DownloadLinkAction from './actionTypes/DownloadLinkAction.vue';
import SignoViewerLinkAction from './actionTypes/SignoViewerLinkAction.vue';
import LoadingAction from './actionTypes/LoadingAction.vue'
import PortfolioSelectorAction from './actionTypes/PortfolioSelectorAction.vue'
import FileSelectAction from './actionTypes/FileSelectAction.vue'
import SlotAction from './actionTypes/SlotAction.vue'

/**
 * A simple Action for Cells in an ActionColumn.
 * When clicked, the table will emit an "action-[key]" event containing the row element.
 * for example, if the given key is "DELETE", the event can be listened to with "@action-DELETE"
 * @param {*} key 
 * @param {*} label 
 * @returns 
 */
export function PageHeaderSimpleAction(key, label) {
  return action(key, Action, label);
}

/**
* Same as SimpleAction, except before emitting the event, the user is asked for confirmation
* @param {*} key 
* @param {*} label 
* @returns 
*/
export function PageHeaderConfirmedAction(key, label, confirmationMessage, confirmationTitle="Bestätigung", labelButtonConfirm="Ok") {
  return {
    ...action(key, ConfirmedAction, label),
    confirmationMessage,
    confirmationTitle,
    labelButtonConfirm,
  };
}

/**
* This action won't emit an event.
* It handle the download link according to the platform
* @param {*} key 
* @param {*} label 
* @param {*} filename
* @param {*} downloadServicePath - the path into /download_service. e.g.: /get_simple_file
* @param {*} queryParams - an Object with all parameters of the link. e.g.: { fileId: 0123, dokId: 0123 }.
*/
export function PageHeaderDownloadLinkAction(key, label, filename, downloadServicePath, queryParams) {
  return {
      ...action(key, DownloadLinkAction, label),
      filename,
      downloadServicePath,
      queryParams,
  };
}

/**
* This action won't emit an event.
* It handle the download link according to the platform
* @param {*} key 
* @param {*} label 
* @param {*} filename
* @param {*} href
*/
export function PageHeaderDownloadLinkHrefAction(key, label, filename, href) {
  return {
      ...action(key, DownloadLinkAction, label),
      filename,
      href,
  };
}

/**
* This action won't emit an event.
* It handle the signo viewer link according to the platform
* @param {*} key 
* @param {*} label 
* @param {*} href 
* @param {*} extraParams 
* @param {*} requestURL 
* @param {*} isDeepLink
*/
export function PageHeaderSignoViewerLinkAction(key, label, href, extraParams, requestURL, isDeepLink) {
  return {
      ...action(key, SignoViewerLinkAction, label),
      href,
      extraParams,
      requestURL,
      isDeepLink,
  };
}

/**
* An action that may be displayed as disabled or loading if the callbacks
* given to the respective parameters return true.
* Otherwise it behaves the same as SimpleAction
* 
* @param {*} loading (optional) a function that receives a row-object and returns true if the action should be shown as loading
* @returns 
*/
export function PageHeaderLoadingAction(key, label, loading) {
  return {
      ...action(key, LoadingAction, label),
      loading,
  };
}

/**
 * An action to configure the selected depotid
 * 
 * @param {*} key 
 * @param {*} label 
 * @returns 
 */
export function PageHeaderPortfolioSelectorAction(key, label = 'Depot auswählen') {
  return action(key, PortfolioSelectorAction, label);
}

/**
* An action to select files as we have on the BaseFileSelect component
* 
* @param {*} key 
* @param {*} label 
* @param {*} accept - which files type are accepted
* @param {*} multiple - allow select more than one file
* @returns 
*/
export function PageHeaderFileSelectAction(key, label, accept, multiple) {
  return {
      ...action(key, FileSelectAction, label),
      accept,
      multiple,
  };
}

/**
* An action as a slot to load any component / html value
* 
* @param {*} key 
* @param {*} label 
* @returns 
*/
export function PageHeaderSlotAction(key) {
  return {
      ...action(key, SlotAction, ''),
      slotAction: true,
  };
}

/**
* The internal method for action creation
* Don't use this method directly!
* Instead use one of the ...Action functions above.
* If no fitting Action type exists yet, you can create a new one!
* @param {*} actionKey 
* @param {*} component 
* @param {*} label 
* @returns 
*/
function action(actionKey, component, label) {
  return {
    actionKey,
    component,
    label,
    withSmallScreenLabel(smallScreenLabel) {
      this.smallScreenLabel = smallScreenLabel;
      return this;
    },
    withValue(value) {
      this.value = value;
      return this;
    },
    withDisabled(disabled) {
      this.disabled = disabled
      return this
    },
    withIcon(icon, size) {
      this.icon = icon
      this.size = size
      return this
    },
    withVisible(visible) {
      this.visible = visible
      return this
    },
    withLoading(loading) {
      this.loading = loading
      return this
    },
    withAsPrimary(asPrimaryFn) {
      this.asPrimaryFn = asPrimaryFn;
      return this;
    },
    withAsGroup() {
      this.asGroup = true;
      return this;
    },
  };
}