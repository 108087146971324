import VV_TYPES from '@/store/vermoegensverwaltung/types';
import CORE_TYPES from '@/store/core/types';
import LOG_TYPES from '@/store/core/types';
import { viewDocument } from '@/components/core/download/DownloadLink.vue';
import { formatNumber } from '@/helpers/number-formatter.js';
import { mapGetters } from 'vuex';
import validator from "@/mixins/validator";
import { documents, varianten, settings, gebuehren, others, othersFa } from '@/mixins/vermoegensverwaltung/hinweise.js';
import { StepperMediator } from '@/components/stepper/utils';
import { buildMessage } from "@/helpers/log-message-helper";

const mixin = {
    mixins: [validator],
    validators: {
    },
    data() {
        return {
        }
    },
    watch: {
        warnings() {
            this.updateWarnings(this.form != null)
        }
    },
    computed: {
        ...mapGetters({
            schemaDataStore: VV_TYPES.GETTERS.STRATEGIEN_SCHEMA_DATA,
            isIntern: CORE_TYPES.GETTERS.IS_INTERN,
            isFa: CORE_TYPES.GETTERS.IS_FA,
        }),
        stepper() {
            return new StepperMediator([
                {
                    stepKey: "documents",
                    label: "Beschreibungen und Dokumente",
                    warning: this.hasWarnings("documents"),
                },
                {
                    stepKey: "settings",
                    label: "Einstellungen",
                    substeps: this.substepsSettings,
                },
            ]);
        },
        substepsSettings() {
            return [
                {
                    substepKey: "settings",
                    title: "Einstellungen",
                    warning: this.hasWarnings("settings", "settings"),
                },
                {
                    substepKey: "gebuehren",
                    title: "Gebühren",
                    warning: this.hasWarnings("settings", "gebuehren"),
                },
                {
                    substepKey: "richtBandbreite",
                    title: "Maximale Richt-Bandbreite",
                    warning: this.hasWarnings("settings", "richtBandbreite"),
                },
                {
                    substepKey: "others",
                    title: "BasisPunkte, Benchmark",
                    warning: this.hasWarnings("settings", "others"),
                },
                {
                    substepKey: "zielmarkt",
                    title: "Zielmarkt",
                    warning: this.hasWarnings("settings", "zielmarkt"),
                },
            ];
        },
        dataSettings() {
            return this.schemaDataStore?.settings || {};
        },
        schemaData() {
            return this.schemaDataStore || {};
        },
        schemaId() {
            return this.schemaData?.schemaId || this.$route.params.schemaId;
        },
        stepKey() {
            const path = this.$route.path.split("/");
            return (path?.length && path[path.length - 1]) || "";
        },
        substepKey() {
            return this.$route.query.substepKey || "";
        },
        warnings() {
            return this.schemaData?.warnings || {};
        },
        hinweise() {
            return this.getHinweise(this.stepKey, this.substepKey);
        },
    },
    methods: {
        setStepByKey(stepKey) {
            if (stepKey) {
                const path = this.getPath(stepKey);
                const step = this.stepper.getCurrentStep(stepKey);
                let query = step?.substeps?.length
                    ? { substepKey: step.substeps[0]?.substepKey || "" }
                    : {};
                if (path !== this.$route.path) {
                    this.navigateTo(stepKey, query);
                }
            }
        },
        setSubstepByKey(step) {
            if (step?.substepKey && step?.stepKey) {
                if (step.substepKey !== this.substepKey) {
                    this.navigateTo(step?.stepKey, { substepKey: step.substepKey });
                }
            }
        },
        navigateTo(stepKey, query) {
            if (this.stepKey !== "aktionen") {
                this.$store.dispatch(VV_TYPES.ACTIONS.SAVE_SCHEMA, {
                    schemaId: this.schemaId,
                    stepKey: this.stepKey === "documents" ? this.stepKey : this.substepKey,
                });
            }
            const path = this.getPath(stepKey);
            this.$router.push({ path: path, query: query });
        },
        getPath(stepKey) {
            return `/beratung/vv/vv-schema-einstellungen/${this.schemaId}/${stepKey}`;
        },
        getHinweise(stepKey, substepKey) {
            if (stepKey) {
                if (stepKey === 'documents') {
                    return documents;
                } else if (stepKey === 'varianten') {
                    return varianten;
                } else if (substepKey) {
                    switch (substepKey) {
                        case 'settings':
                            return settings;
                        case 'gebuehren':
                            return gebuehren;
                        case 'others':
                            return this.isFa ? othersFa : others;
                        default: break;
                    }
                }
            }
        },
        getWarnings(stepKey, substepKey, mitHinweis = false) {
            if (stepKey)
                return (this.schemaData?.warnings?.antragWarnings || [])
                    .concat(this.schemaData?.warnings?.positionWarnings || [])
                    .filter((warn) =>
                        (stepKey === "aktionen" || stepKey === "1zu1")
                            ? warn
                            : warn.stepKey === stepKey &&
                            (substepKey ? warn.substepKey === substepKey : true) &&
                            (!mitHinweis ? warn.status !== "HINWEIS" : true)
                    );
        },
        hasWarnings(stepKey, substepKey) {
            return this.getWarnings(stepKey, substepKey)?.length;
        },
        updateWarnings(hasForm = false) {
            const warnings = this.getWarnings(this.stepKey, this.substepKey, false) || [];
            warnings.forEach((warn) => this.$pushErrors((hasForm ? "form." : "") + warn.id, [warn.message]));
        },
        formatNumber(value) {
            return formatNumber(value);
        },
        initValuesSettings() {
            this.form = Object.assign(this.form, this.dataSettings);
        },
        handlePdf(action, param = {}) {
            if (this.schemaData?.schemaId) {
                this.$store.dispatch(action, Object.assign(param, { schemaId: this.schemaData.schemaId })).then((response) => {
                    if (response?.data?.errorStr) {
                        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.errorStr, 'danger'));
                    } else if (response?.data?.bytes) {
                        viewDocument({
                            filename: response.data.fileName,
                            data: response.data.bytes,
                            contentType: 'application/pdf',
                        });
                    }
                });
            }
        },
        updateStore(data = {}, category) {
            this.$store.commit(VV_TYPES.MUTATIONS.UPDATE_SCHEMA_DATA_STORE, { data, category });
        },
        updateVarianteStore(versionId, data = {}) {
            if (versionId) {
                this.$store.commit(VV_TYPES.MUTATIONS.UPDATE_SCHEMA_DATA_VARIANTE_STORE, { versionId, data });
            }
        },
        updateVariantePositionStore(versionId, data = {}) {
            if (versionId && data.positionId && data.componentId) {
                this.$store.commit(VV_TYPES.MUTATIONS.UPDATE_SCHEMA_DATA_VARIANTE_POSITION_STORE, { versionId: versionId, positionId: data.positionId, data: { [data.componentId]: data.value } });
                this.doUpdateZielmarkt();
            }
        },
        doUpdateZielmarkt() {
            this.$store.dispatch(VV_TYPES.ACTIONS.CALC_ZIELMARKT_DATA_UPDATE, {
                versionId: this.varianteId,
                schemaId: this.schemaId,
                positions: this.variante.positions,
            });
        },
        handleTableAction(data, zurueckLabel = 'zurück zur Liste der verfügbaren Wertpapiere') {
            if (data?.row && (data?.key === 'WERTPAPIER_BEANTRAGEN')) {
                if (!this.$route.fullPath.includes('fondsfinder-neu')) {
                    this.$addBreadcrumb({
                        label: zurueckLabel,
                        fullPath: this.$route.fullPath,
                        breadcrumb: 'Wertpapier bearbeiten',
                    });
                }
                this.$router.push({ path: `/beratung/vv/wertpapier-beantragen/${data.row.isin}`, query: {} })
            }
        },
    },
}

export default mixin;