var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.asCalendar
    ? _c(
        "div",
        { staticClass: "date-picker-field__container input-forms__container" },
        [
          _c("DatePickerSelectors", {
            attrs: {
              isRangePicker: _vm.isRangePicker,
              datePickerSelectorsInline: _vm.datePickerSelectorsInline,
              showSecondarySelector: _vm.showSecondarySelector,
              controlsSize: _vm.controlsSize,
              monthPicker: _vm.monthPicker,
              highlightWeek: _vm.highlightWeek,
              selectedDay: _vm.selectedDay,
              selectedRange: _vm.selectedRange,
              disabled: _vm.disabled
            },
            on: {
              onSelectDay: function($event) {
                _vm.selectDay(
                  _vm.dateOrRangeObject($event.date, $event.range),
                  false
                )
              },
              onSelectMonth: function($event) {
                return _vm.selectMonth($event)
              }
            }
          })
        ],
        1
      )
    : _c("DatePickerDropdown", {
        ref: "datePickerDropDown",
        staticClass: "date-picker-field__container input-forms__container",
        attrs: {
          label: _vm.label,
          disabled: _vm.disabled || _vm.inputmode != _vm.INPUTMODE_NONE,
          isRangePicker: _vm.isRangePicker
        },
        on: {
          enableKeyboard: function($event) {
            return _vm.switchToKeyboard()
          },
          showedPicker: function($event) {
            return _vm.$emit("showedPicker", $event)
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "field",
              fn: function() {
                return [
                  _vm._t("default", function() {
                    return [
                      _c("InputField", {
                        ref: "inputContainerEl",
                        staticClass: "pb-0",
                        class: {
                          "date-picker-field__input-none":
                            _vm.inputmode === _vm.INPUTMODE_NONE
                        },
                        attrs: {
                          suppressValidationMessage: "",
                          inputmode: _vm.inputmode,
                          isComponentHalfSize: _vm.isComponentHalfSize,
                          label: _vm.label,
                          labelClass: _vm.labelClass,
                          placeholder: _vm.placeholder,
                          disabled: _vm.disabled,
                          inputClass: _vm.inputClass
                        },
                        on: {
                          focus: _vm.onInputFieldFocus,
                          onBlur: function($event) {
                            return _vm.$emit("onBlur")
                          },
                          change: _vm.onChangeInputText,
                          reset: function($event) {
                            return _vm.triggerValidator()
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            _vm.inputmode !== _vm.INPUTMODE_NONE
                              ? {
                                  key: "icon",
                                  fn: function() {
                                    return [
                                      _c("PhCalendarBlank", {
                                        attrs: { size: 16, tabindex: "-1" },
                                        on: {
                                          click: function($event) {
                                            return _vm.switchToCalendarDropdown()
                                          }
                                        }
                                      })
                                    ]
                                  },
                                  proxy: true
                                }
                              : null
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: _vm.inputTextValue,
                          callback: function($$v) {
                            _vm.inputTextValue = $$v
                          },
                          expression: "inputTextValue"
                        }
                      })
                    ]
                  }),
                  _vm.isValidationConfigDone
                    ? _c("div", [
                        !_vm.suppressValidationMessage && _vm.validation
                          ? _c(
                              "div",
                              {
                                key: _vm.validation.updated,
                                staticClass: "input-forms__errors-container"
                              },
                              _vm._l(
                                _vm.validation.getErrors(
                                  _vm.validationPath,
                                  _vm.validateUntouched
                                ),
                                function(error) {
                                  return _c(
                                    "div",
                                    {
                                      key: error,
                                      staticClass: "fc-form-danger"
                                    },
                                    [_vm._v(" " + _vm._s(error) + " ")]
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e()
                      ])
                    : _vm._e()
                ]
              },
              proxy: true
            },
            {
              key: "container",
              fn: function() {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "datepicker__container",
                      class: { "is-range-picker": _vm.isRangePicker }
                    },
                    [
                      _c("DatePickerSelectors", {
                        attrs: {
                          isRangePicker: _vm.isRangePicker,
                          datePickerSelectorsInline:
                            _vm.datePickerSelectorsInline,
                          showSecondarySelector: _vm.showSecondarySelector,
                          controlsSize: _vm.controlsSize,
                          monthPicker: _vm.monthPicker,
                          highlightWeek: _vm.highlightWeek,
                          selectedDay: _vm.selectedDay,
                          selectedRange: _vm.selectedRange,
                          disabled: _vm.disabled
                        },
                        on: {
                          onSelectDay: function($event) {
                            _vm.selectDay(
                              _vm.dateOrRangeObject($event.date, $event.range),
                              false
                            )
                          },
                          onSelectMonth: function($event) {
                            return _vm.selectMonth($event)
                          }
                        }
                      }),
                      _vm.showPreset
                        ? _c("div", { staticClass: "datepicker__shortcut" }, [
                            _vm.showDatePresets
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "datepicker__preset-container layout__negative-margin--8"
                                  },
                                  _vm._l(_vm.presetDays, function(preset) {
                                    return _c(
                                      "a",
                                      {
                                        key: preset.days,
                                        on: {
                                          click: function($event) {
                                            return _vm.selectDate(preset.date)
                                          }
                                        }
                                      },
                                      [_vm._v(" " + _vm._s(preset.label) + " ")]
                                    )
                                  }),
                                  0
                                )
                              : _vm.showRangePresets
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "datepicker__preset-container layout__negative-margin--8"
                                  },
                                  _vm._l(_vm.presetPeriods, function(preset) {
                                    return _c(
                                      "a",
                                      {
                                        key: preset.days,
                                        on: {
                                          click: function($event) {
                                            return _vm.selectRangeFromPreset(
                                              preset.range
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" " + _vm._s(preset.label) + " ")]
                                    )
                                  }),
                                  0
                                )
                              : _vm._e()
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }