import { render, staticRenderFns } from "./BaseMaklerAnsprechpartner.vue?vue&type=template&id=63cd4de6&"
import script from "./BaseMaklerAnsprechpartner.vue?vue&type=script&lang=js&"
export * from "./BaseMaklerAnsprechpartner.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("P:\\workspaces\\fk0\\fc\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('63cd4de6')) {
      api.createRecord('63cd4de6', component.options)
    } else {
      api.reload('63cd4de6', component.options)
    }
    module.hot.accept("./BaseMaklerAnsprechpartner.vue?vue&type=template&id=63cd4de6&", function () {
      api.rerender('63cd4de6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/broker/BaseMaklerAnsprechpartner.vue"
export default component.exports