export const MUTATION_PREFIX = 'TAPI_';
export const ACTIONS_PREFIX = 'TAPI_';
export const GETTERS_PREFIX = 'TAPI_';

export default {
  MUTATIONS: {
    CONNECT: MUTATION_PREFIX + 'CONNECT',
    LINE: MUTATION_PREFIX + 'LINE',
    PHONE_NUMBER: MUTATION_PREFIX + 'PHONE_NUMBER',
    WEB_SOCKET:MUTATION_PREFIX + 'WEB_SOCKET',
    WEB_SOCKET_OPENED:MUTATION_PREFIX + 'WEB_SOCKET_OPENED',
    OPEN_INCOMMING_DIALOG:MUTATION_PREFIX + 'OPEN_INCOMMING_DIALOG',
    RESET_STATE:MUTATION_PREFIX + 'RESET_STATE',
    MODAL_PANEL_OPEN:MUTATION_PREFIX + 'MODAL_PANEL_OPEN',
    CAN_TAPI:MUTATION_PREFIX + 'CAN_TAPI',
    CAN_TAPI_BUTTON:MUTATION_PREFIX + 'CAN_TAPI_BUTTON',
    CAN_TAPI_HOST:MUTATION_PREFIX + 'CAN_TAPI_HOST',
    ACTIVE_CALL:MUTATION_PREFIX+'ACTIVE_CALL',
    HOST:MUTATION_PREFIX+'HOST',
    CALLER:MUTATION_PREFIX+'CALLER',
    DIRECTION:MUTATION_PREFIX+'DIRECTION',
    TAPI_PARAMS:MUTATION_PREFIX+'TAPI_PARAMS',
    START_CALL_DATE:MUTATION_PREFIX+'START_CALL_DATE',
    CALL_OUT_USER_TYPE:MUTATION_PREFIX+'CALL_OUT_USER_TYPE',
    CALL_OUT_US_ID:MUTATION_PREFIX+'CALL_OUT_US_ID',
    CALL_OUT_NUMBER:MUTATION_PREFIX+'CALL_OUT_NUMBER',
    RINGING:MUTATION_PREFIX+'RINGING',
    LINES:MUTATION_PREFIX+'LINES'
  },
  ACTIONS: {
    //FIND_CORNERS: ACTIONS_PREFIX + 'FIND_CORNERS',
    CONNECT: ACTIONS_PREFIX + 'CONNECT',
    CALLER: ACTIONS_PREFIX + 'CALLER',
    SET_CALLER:ACTIONS_PREFIX+'SET_CALLER',
    RESTART_WEB_SOCKET:ACTIONS_PREFIX + 'RESTART_WEB_SOCKET',
    START_WEB_SOCKET:ACTIONS_PREFIX + 'START_WEB_SOCKET',
    OPEN_INCOMMING_DIALOG:ACTIONS_PREFIX + 'OPEN_INCOMMING_DIALOG',
    CAN_TAPI:ACTIONS_PREFIX + 'CAN_TAPI',
    MODAL_PANEL_OPEN:ACTIONS_PREFIX + 'MODAL_PANEL_OPEN',
    ACTIVE_CALL:ACTIONS_PREFIX+'ACTIVE_CALL',
    HOST:ACTIONS_PREFIX+'HOST',
    CLOSE:ACTIONS_PREFIX+'CLOSE',
    CALL_DISCONNECT:ACTIONS_PREFIX+'CALL_DISCONNECT',
    TAPI_PARAMS:ACTIONS_PREFIX+'TAPI_PARAMS',
    SAVE_TAPI_PARAMS:ACTIONS_PREFIX+'SAVE_TAPI_PARAMS',
    CALL_OUT_USER_TYPE:ACTIONS_PREFIX+'CALL_OUT_USER_TYPE',
    CALL_OUT_US_ID:ACTIONS_PREFIX+'CALL_OUT_US_ID',
    CALL_OUT_NUMBER:ACTIONS_PREFIX+'CALL_OUT_NUMBER',
    OPEN_CALL_OUT_DIALOG:ACTIONS_PREFIX+'OPEN_CALL_OUT_DIALOG',
    RINGING:ACTIONS_PREFIX+'RINGING',
    LINES:ACTIONS_PREFIX+'LINES'
  },
  GETTERS: {
    CONNECT:GETTERS_PREFIX + 'CONNECT',
    LINE:GETTERS_PREFIX + 'LINE',
    PHONE_NUMBER:GETTERS_PREFIX + 'PHONE_NUMBER',
    WEB_SOCKET:GETTERS_PREFIX + 'WEB_SOCKET',
    WEB_SOCKET_OPENED:GETTERS_PREFIX + 'WEB_SOCKET_OPENED',
    OPEN_INCOMMING_DIALOG:GETTERS_PREFIX + 'OPEN_INCOMMING_DIALOG',
    MODAL_PANEL_OPEN:GETTERS_PREFIX + 'MODAL_PANEL_OPEN',
    CAN_TAPI:GETTERS_PREFIX + 'CAN_TAPI',
    CAN_TAPI_BUTTON:GETTERS_PREFIX + 'CAN_TAPI_BUTTON',
    CAN_TAPI_HOST:GETTERS_PREFIX + 'CAN_TAPI_HOST',
    CAN_OUT_CALL:GETTERS_PREFIX + 'CAN_OUT_CALL',
    ACTIVE_CALL:GETTERS_PREFIX+'ACTIVE_CALL',
    HOST:GETTERS_PREFIX+'HOST',
    CALLER:GETTERS_PREFIX+'CALLER',
    DIRECTION:GETTERS_PREFIX+'DIRECTION',
    TAPI_PARAMS:GETTERS_PREFIX+'TAPI_PARAMS',
    START_CALL_DATE:GETTERS_PREFIX+'START_CALL_DATE',
    CALL_OUT_USER_TYPE:GETTERS_PREFIX+'CALL_OUT_USER_TYPE',
    CALL_OUT_US_ID:GETTERS_PREFIX+'CALL_OUT_US_ID',
    CALL_OUT_NUMBER:GETTERS_PREFIX+'CALL_OUT_NUMBER',
    CAN_OPEN_CALL_OUT_DIALOG:GETTERS_PREFIX+'CAN_OPEN_CALL_OUT_DIALOG',
    RINGING:GETTERS_PREFIX+'RINGING',
    LINES:GETTERS_PREFIX+'LINES'
  }
}