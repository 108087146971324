var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Limits",
          subtitle: "200-Tage-Linie(GD200) Limit",
          actions: _vm.actions
        },
        on: {
          "action-RESET": function($event) {
            return _vm.reset()
          }
        }
      }),
      !_vm.isFondsfinder
        ? _c("div", [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "box__container" },
              [
                _c("WertpapierAuswahlUnique", {
                  attrs: {
                    label: "Wertpapier",
                    data: _vm.limit,
                    id: "isin",
                    config: { isEmbedded: true },
                    hasDelete: false,
                    disabled: _vm.wertpapierDisabled
                  },
                  on: {
                    openFondsfinder: function($event) {
                      _vm.isFondsfinder = true
                    }
                  }
                }),
                _c("div", { staticClass: "info-block" }, [
                  _vm.modus === "LIMIT_GD200_BGS"
                    ? _c("div", [
                        _vm._v("Kunde: " + _vm._s(_vm.limit.kundenName))
                      ])
                    : _vm._e(),
                  _c("div", [_vm._v("Währung: " + _vm._s(_vm.limit.currency))]),
                  _c("div", [
                    _vm._v(
                      "Aktueller Kurs: " +
                        _vm._s(_vm.formatCurrency(_vm.limit.aktuellKurs))
                    )
                  ]),
                  _c("div", [
                    _vm._v("Kursdatum: " + _vm._s(_vm.limit.kursdatum))
                  ]),
                  _c("div", [
                    _vm._v(
                      "Aktueller Wert GD200: " +
                        _vm._s(_vm.formatCurrency(_vm.limit.gd200wert))
                    )
                  ])
                ]),
                _c("InputField", {
                  attrs: {
                    label: "Abweichung nach unten (relativ)",
                    type: "percent",
                    precision: 0,
                    isComponentHalfSize: ""
                  },
                  model: {
                    value: _vm.form.unteresLimitAbweichung,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "unteresLimitAbweichung", $$v)
                    },
                    expression: "form.unteresLimitAbweichung"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    label: "Abweichung nach oben (relativ)",
                    precision: 0,
                    type: "percent",
                    isComponentHalfSize: ""
                  },
                  model: {
                    value: _vm.form.oberesLimitAbweichung,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "oberesLimitAbweichung", $$v)
                    },
                    expression: "form.oberesLimitAbweichung"
                  }
                }),
                _c("div", [
                  _vm._v("Letzte Änderung: " + _vm._s(_vm.limit.lastChangeDate))
                ]),
                _c("div", [
                  _vm._v(
                    "Datum der Überschreitung: " +
                      _vm._s(_vm.limit.informedDate)
                  )
                ]),
                _c("InputTextArea", {
                  attrs: { label: "Bemerkung", isComponentHalfSize: "" },
                  model: {
                    value: _vm.form.bemerkung,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "bemerkung", $$v)
                    },
                    expression: "form.bemerkung"
                  }
                })
              ],
              1
            )
          ])
        : _c(
            "div",
            [
              _c("FondsFinderSuchMaske"),
              _c("FondsFinderPositionsList", {
                ref: "fondsfinderResult",
                attrs: {
                  goBackEnabled: true,
                  singleSelectionProp: true,
                  hasDefaultButton: true
                },
                on: {
                  goBack: function($event) {
                    _vm.isFondsfinder = false
                  },
                  getSelectedOutcome: function($event) {
                    return _vm.setIsin($event)
                  }
                }
              })
            ],
            1
          ),
      _c("LimitErrorModal", {
        ref: "modalError",
        attrs: { errorText: _vm.errorFromService },
        on: { closeLimit: _vm.confirmNextRoute }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "box__container" }, [
      _c("div", { staticClass: "box__title" }, [
        _vm._v("200-Tage-Linie(GD200) Limit")
      ]),
      _c("div", [
        _vm._v(
          " Zum Setzen des Limits kann eine prozentuale Abweichung basierend auf dem gleitenden Durchschnitt der letzten 200 Tage (GD200) gesetzt werden. Soll ein Limit (oberes oder unteres) nicht beachtet werden so ist dieser Wert leer zu lassen bzw. auf 0 zu setzen. "
        )
      ]),
      _c("div", { staticClass: "mt-2" }, [
        _vm._v(
          " Beispiel: Unteres Limit 5% bedeutet, dass die Limitwarnung ausgelöst wird, wenn der Wertpapierpreis unter den 200 Tage Durchschnittswert - 5% fällt. "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }