<template>
    <div>
        <PageHeaderTitleNavigation
            title="Limits"
            subtitle="Wertpapierkategorie Limit"
            :actions="actions"
            @action-RESET="reset()"
            @action-REMOVE="$refs.confirmDelete.open()"
        />

        <div class="box__container">
            <div class="info-block">
                <div class="font-bold">{{limit.depot}}</div>
                <div v-if="!isCustomerOrigin">Kundennr: {{limit.kundennr}}</div>
                <div v-if="!isCustomerOrigin">Kunde: {{limit.kundenLinkName}}</div>
            </div>

            <div v-if="form.rows && form.rows.length">
                <div v-for="(row, index) of form.rows" :key="index">
                    <div class="my-2"><span>{{row.kategorieStr}}:</span>
                        <span class="font-bold"> {{formatNumber(row.wert, 2)}} <PhCurrencyEur :size="16" /></span>
                        <span class="font-bold"> ({{formatNumber(row.verteilung, 4)}} <PhPercent :size="16" />)</span>
                    </div>
                    <InputField
                        :value="row.ausgangswert"
                        type="percent"
                        :precision="4"
                        isComponentHalfSize
                        @change="row.ausgangswert = +$event" />
                </div>
            </div>

            <InputField
                label="Abweichung nach oben"
                v-model="form.abweichungOben"
                :precision="4"
                type="percent"
                isComponentHalfSize />
            <InputField
                label="Abweichung nach unten"
                v-model="form.abweichungUnten"
                type="percent"
                :precision="4"
                isComponentHalfSize />

            <div v-if="limit.dateUpdated">Letzte Änderung: {{limit.dateUpdated}}</div>
            <div v-if="limit.dateOutOfLimit">Datum der Überschreitung: {{limit.dateOutOfLimit}}</div>

            <InputTextArea v-if="!isCustomerOrigin"
                label="Bemerkung (für Kunden nicht sichtbar)"
                v-model="form.bemerkung" 
                isComponentHalfSize />
        </div>
        <BaseModal ref="confirmDelete" 
            labelButtonConfirm="Ja" 
            labelButtonCancel="Nein" 
            @onConfirmButton="remove()"
        >
            <template v-slot:modalTitle>
                <PhWarning :size="16" class="mr-2" style="color: orangered"/>Löschen
            </template>
            <div>Wollen Sie wirklich das Limit löschen?</div>
        </BaseModal>
        <BaseModal ref="confirmReset" 
            labelButtonConfirm="Ja" 
            labelButtonCancel="Nein" 
            @onConfirmButton="reset()"
        >
            <template v-slot:modalTitle>
                <PhWarning :size="16" class="mr-2" style="color: orangered"/>Zurücksetzen
            </template>
            <div>Wollen Sie wirklich das Limit zurücksetzen?</div>
        </BaseModal>
        
        <LimitErrorModal ref="modalError" :errorText="errorFromService" @closeLimit="confirmNextRoute" />

    </div>
</template>

<script>
import BaseButton from '@/components/core/BaseButton.vue';
import { mapGetters } from 'vuex';
import LIMIT_TYPES from '@/store/limit/types';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import InputField from '@/components/core/forms/InputField.vue';
import {toCurrencyString} from '@/filters.js';
import mixin from '@/mixins/limit/limit-mixin.js';
import {PhPercent, PhCurrencyEur, PhWarning } from "phosphor-vue";
import {formatNumber} from '@/helpers/number-formatter.js';
import BaseModal from '@/components/core/BaseModal.vue';
import LimitErrorModal from '@/views/limit/LimitErrorModal.vue' 
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils'

export default {
    mixins: [mixin],
    components: {
        BaseButton,
        InputField,
        InputTextArea,
        PhPercent,
        PhCurrencyEur,
        PhWarning,
        BaseModal,
        LimitErrorModal,
        PageHeaderTitleNavigation,
    },
    computed: {
        ...mapGetters({
            wertpapierkategorieLimit: LIMIT_TYPES.GETTERS.WERTPAPIERKATEGORIE_LIMIT,
        }),
        limit() {
            return this.wertpapierkategorieLimit || {};
        },
        title() {
            return `Depotlimit ${this.limit?.notExist ? '(nicht gesetzt)' : ''}`;
        },
        actions() {
            return [
                PageHeaderSimpleAction('REMOVE', 'Löschen'),
                PageHeaderSimpleAction('RESET', 'Zurücksetzen'),
            ]
        }
    },
    data() {
        return {
            form: {},
            depotId: '',
            kontoNr: '',
            nextRoute: false,
            errorFromService: null,
        }
    },
    watch: {
        limit(value) {
            if (Object.keys(value).length) {
               this.form = value;
            }
        }
    },
    mounted() {
        this.kundennr = this.$route.query.kundennr;
        this.depotId = this.$route.query.depotId;
        this.kontoNr = this.$route.query.kontoNr;
        this.$store.dispatch(LIMIT_TYPES.ACTIONS.GET_WERTPAPIERKATEGORIE_LIMIT, { kundennr: this.kundennr || '', depotId: this.depotId || '', kontoNr: this.kontoNr || ''});
        this.form = {
            abweichungUnten: '',
            abweichungOben: '',
            bemerkung: '',
        }
    },
    methods: {
        formatCurrency(value) {
            return toCurrencyString(value);
        },
        save() {
            this.$store.dispatch(LIMIT_TYPES.ACTIONS.SAVE_WERTPAPIERKATEGORIE_LIMIT, 
                Object.assign(this.form, { kundennr: this.kundennr || '', depotId: this.depotId || '', kontoNr: this.kontoNr || ''})).then(errorStr => {
                if (errorStr) {
                    this.errorFromService = errorStr;
                    this.$refs.modalError.open();
                } else {
                    this.confirmNextRoute();
                }
            });
        },
        async remove() {
            await this.$store.dispatch(LIMIT_TYPES.ACTIONS.DELETE_WERTPAPIERKATEGORIE_LIMIT, { kundennr: this.kundennr || '', depotId: this.depotId || '', kontoNr: this.kontoNr || ''});
            this.$router.push(`${this.mainPath}/wertpapierkategorie-limits`)
        },
        async reset() {
            await this.$store.dispatch(LIMIT_TYPES.ACTIONS.RESET_WERTPAPIERKATEGORIE_LIMIT, { kundennr: this.kundennr || '', depotId: this.depotId || '', kontoNr: this.kontoNr || ''});
            this.$router.push(`${this.mainPath}/wertpapierkategorie-limits`)
        },
        formatNumber(value, precision) {
            return formatNumber(value, precision);
        }
    },
     beforeRouteLeave(to, from, next) {
        this.nextRoute = next;
        this.save();
    },
}
</script>

<style scoped>
.info-block {
    display: flex;
    flex-direction: column; 
    flex-grow: 2;
    justify-content: space-between;
}
</style>