var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "box__title" }, [
        _vm._v(
          "Bitte geben Sie den Inhalt der E-Mail an, die an die Kunden geschickt wird."
        )
      ]),
      _c("InputField", {
        attrs: { label: "Betreff" },
        on: {
          change: function($event) {
            return _vm.updateEmailDataStore({ betreff: $event })
          }
        },
        model: {
          value: _vm.betreff,
          callback: function($$v) {
            _vm.betreff = $$v
          },
          expression: "betreff"
        }
      }),
      _c(
        "div",
        { staticClass: "mb-5" },
        [
          _c("TemplateHtmlEditor", {
            attrs: { placeholders: _vm.placeholders || {}, ignoreSenders: "" },
            on: {
              change: function($event) {
                return _vm.updateEmailDataStore({ html: $event })
              }
            },
            model: {
              value: _vm.html,
              callback: function($$v) {
                _vm.html = $$v
              },
              expression: "html"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }