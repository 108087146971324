export const MUTATION_PREFIX = 'EXTERNE_ZUGANG_BANK: ';
export const ACTIONS_PREFIX = 'EXTERNE_ZUGANG_BANK_ACTIONS_';
export const GETTERS_PREFIX = 'EXTERNE_ZUGANG_BANK_GETTERS_';

export default {
  MUTATIONS: {
    GET_LIST_SUCCESS: MUTATION_PREFIX + 'GET_LIST_SUCCESS',
  },
  ACTIONS: {
    GET_LIST: ACTIONS_PREFIX + 'GET_LIST',
    OPEN_URL: ACTIONS_PREFIX + 'OPEN_URL',
  },
  GETTERS: {
    LIST: GETTERS_PREFIX + 'LIST',
  },
}
