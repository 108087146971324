<template>
  <div class="antrag-step__container box__container">
    <VerticalStepper
      ref="stepper"
      :stepType="stepType"
      :visible="showStepper"
      :stepperName="stepperName"
      :selectedStepKey="routeStep"
      :selectedSubstepKey="routeSubStep"
      :stepperService="getStepperMediator"
      :antragData="antragData"
      :showSaveSpinner="saveAntragState"
      :showCollapse="true"
      @setStep="$emit('setStep', $event)"
      @setSubstep="$emit('setSubstep', $event)"
      @collapsed="$emit('collapsed', $event)"
      @zusammenfassung="$emit('zusammenfassung', $event)"
    ></VerticalStepper>
  </div>
</template>

<script>
import { StepperMediator } from '@/components/stepper/utils';
import VerticalStepper from '@/components/vertical-stepper/VerticalStepper.vue';
import antragNavigationMixin from '@/mixins/antrag/antrag-navigation-mixin.js';
import antragMixin from '@/mixins/antrag/antrag-mixin.js';
import ANTRAG_TYPES from '@/store/antrag/types'
import { mapGetters } from 'vuex'

export default {
  mixins: [antragNavigationMixin, antragMixin],
  props: {
    antragStep: {
    },
    antragData: {
    }
  },
  data() {
    return {
      stepType: 'step',
      showStepper: true,
      stepperName: 'name?',
    }
  },
  computed: {
    ...mapGetters({
      saveAntragState: ANTRAG_TYPES.GETTERS.SAVE_ANTRAG_STATE
    }),
    routeStep() {
      return this.$route.params.step;
    },
    routeSubStep() {
      return this.$route.params.substep;
    },
    getStepperMediator() {
      if (this.antragStep && this.antragData) {
        return new StepperMediator(JSON.parse(JSON.stringify(this.antragStep)));
      }
      return null;
    },
  },
  components: {
    VerticalStepper
  },
  methods: {
  
  }
}
</script>

<style scoped>
  .antrag-step__container {
    border: none;
  }
</style>