<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import mixin from '@/mixins/limit/limit-mixin.js';

export default {
    mixins: [mixin],
    components: {
        OptionMenu,
    },
    computed: {
    },
}
</script>

