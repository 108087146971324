import { WEBRTC_CONFIG_CAMERA_KEY, WEBRTC_CONFIG_MICROPHONE_KEY, WEBRTC_CONFIG_SPEAKER_KEY } from "./webrtc-settings-helper";
import CORE_TYPES from '@/store/core/types'

export const KEEP_KEYS_WHEN_CLEANING = [
  WEBRTC_CONFIG_CAMERA_KEY,
  WEBRTC_CONFIG_MICROPHONE_KEY,
  WEBRTC_CONFIG_SPEAKER_KEY
]

export function isLocalStorageAllowed() {
  try {
    return window.localStorage.setItem && window.localStorage.removeItem
  } catch (ex) {
    return false
  }
}

export function setObject(key, value) {
  if (isLocalStorageAllowed()) {
    localStorage.setItem(key, JSON.stringify(value));
  }
}
export function getObject(key) {
  if (isLocalStorageAllowed()) {
    return JSON.parse(localStorage.getItem(key));
  }
}
export function removeItem(key) {
  if (isLocalStorageAllowed()) {
    localStorage.removeItem(key);
  }
}

export function setSessionObject(key, value) {
  if (isLocalStorageAllowed()) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
}
export function getSessionObject(key) {
  if (isLocalStorageAllowed()) {
    return JSON.parse(sessionStorage.getItem(key));
  }
}
export function removeSessionItem(key) {
  if (isLocalStorageAllowed()) {
    sessionStorage.removeItem(key);
  }
}

export function clearStorage() {
  if (isLocalStorageAllowed()) {
    const keys = Object.keys(localStorage);
    for (let key of keys) {
      if (!KEEP_KEYS_WHEN_CLEANING.includes(key)) {
        localStorage.removeItem(key)
        sessionStorage.removeItem(key)
      }
    }
  }
}

export function removeLoginDataSessionStorage() {
  if (process.env.NODE_ENV === 'development') {
    removeItem('loginData')
    removeItem('app:colorScheme')
    removeSessionItem('kundenzugang-loginData')
    removeSessionItem('maklerzugang-loginData')
    removeSessionItem('userNumberPerspective')
  }
}

export function saveLoginDataSessionStorage(getters, payload, responseData) {
  if (getters[CORE_TYPES.GETTERS.STORE_SESSION_INFORMATION]) {
    if(payload.isKundenzugang) {
      setSessionObject('kundenzugang-loginData', responseData)
    } else if(payload.isMaklerzugang) {
      setSessionObject('maklerzugang-loginData', responseData)
    } else {
      setObject('loginData', responseData)
    }
  }
}