<template>
  <div>
    <div class="row justify-content-end" v-if="showButtonsSelectDeselect">
      <div class="col-auto">
        <BaseButton isSecondary @click="unselectAll">Alle abwählen</BaseButton>
      </div>
      <div class="col-auto">
        <BaseButton @click="selectAll">Alle anwählen</BaseButton>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <InputCheckBoxGroup 
          :values="computedLabelPath"
          :value="computedValue"
          :title="title"
          :disabled="disabled"
          @checkBoxToggle="onInput">
        </InputCheckBoxGroup>
      </div>
    </div>
    <div class="row" v-if="isValidationConfigured() && !suppressValidationMessage">
      <div class="col-12">
        <div :key="validation.updated">
          <div class="fc-form-danger" 
            v-for="error in validation.getErrors(validationPath, validateUntouched)" 
            :key="error">
              {{ error }}
          </div>
          <div v-for="component in componentFields" :key="component.id">
            <div class="fc-form-danger" 
              v-for="componentError in validation.getErrors(component.id, validateUntouched)" 
              :key="componentError">
                {{ componentError }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputCheckBoxGroup from '@/components/core/forms/checkbox/InputCheckBoxGroup.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import { getBooleanValue } from '@/helpers/mapping.js'
import validatorComponentUtils from '@/mixins/validator/validator-component-utils'
import { isHiddenDisabled } from '@/components/antrag/antrag-utils.js';

export default {
  mixins: [validatorComponentUtils],
  props: {
    title: {
      type: String,
      default: ''
    },
    showButtonsSelectDeselect: {
      default: false
    },
    componentFields: {
      type: Array,
      default: () => [],
    },
    antragData: {
      type: Object
    },
    config: {
      type: Object
    },
    validateUntouched: {
      type: Boolean,
      default: false
    },
    suppressValidationMessage: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    computedLabelPath() {
      if (Array.isArray(this.componentFields)) {
        return this.componentFields.map(comp => ({ label: comp.label, path: comp.id, disabled: isHiddenDisabled(comp.disabled, this.antragData)}))
      }
      return null
    },
    computedValue() {
      if (Array.isArray(this.componentFields) && this.antragData) {
        return this.componentFields.reduce((acc, cur) => {
          acc[cur.id] = this.antragData[cur.id] ?? this.antragData[Object.keys(this.antragData).find(key => key.toLowerCase() === cur.id?.toLowerCase())];
          return acc;
        }, {})
      }
      return {};
    }
  },
  methods: {
    onInput($event) {
      this.$emit('input', $event);
      this.$runValidator($event)
    },
    changeAllToReference(reference) {
      if (Array.isArray(this.componentFields)) {
        const allValues = this.componentFields.reduce((acc, cur) => {
          acc[cur.id] = getBooleanValue(this.antragData[cur.id], reference)
          return acc;
        }, {});

        this.onInput(allValues)
      }
    },
    selectAll() {
      this.changeAllToReference(true)
    },
    unselectAll() {
      this.changeAllToReference(false)
    }
  },
  data() {
    return {
      value: {}
    }
  },
  components: {
    InputCheckBoxGroup,
    BaseButton,
  }
}
</script>

<style scoped>

</style>