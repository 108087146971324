var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "private-dok-node-tree__container" },
    [
      _c(
        "div",
        [
          _vm._m(0),
          _vm.nodeId
            ? _c("Tree", {
                ref: "nodeTree",
                staticClass: "add-new-node-tree__container",
                attrs: {
                  items: _vm.nodes,
                  childrenKey: "nodes",
                  idKey: "nodeId",
                  showArrowRight: false,
                  uniqueId: "a43515bc-82eb-11ee-b962-0242ac120002"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "div",
                            { staticClass: "add-new-node-tree--content" },
                            [
                              _c(
                                "span",
                                { staticClass: "add-new-node-tree--label" },
                                [_vm._v(_vm._s(item.label))]
                              ),
                              item.subfolderMetadata
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "add-new-node-tree--visibility"
                                    },
                                    [
                                      item.subfolderMetadata.showForKunde
                                        ? _c("PhEye", {
                                            attrs: { size: 16, weight: "bold" }
                                          })
                                        : _c("PhEyeSlash", {
                                            staticClass: "color-danger",
                                            attrs: { size: 16, weight: "bold" }
                                          })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "span",
                                {
                                  staticClass: "add-new-node-tree--actions",
                                  attrs: { "data-tree-ignore-click": "" }
                                },
                                [
                                  item.subfolderAllowed
                                    ? _c("BaseDropdownMenu", {
                                        attrs: { placement: "bottom-right" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "hook-target",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn-clear clickable",
                                                      attrs: { type: "button" }
                                                    },
                                                    [
                                                      _c(
                                                        "PhDotsThreeVertical",
                                                        { attrs: { size: 24 } }
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            },
                                            {
                                              key: "default",
                                              fn: function() {
                                                return [
                                                  item.subfolderMetadata
                                                    ? _c(
                                                        "ul",
                                                        {
                                                          staticClass:
                                                            "action-texts list m-0"
                                                        },
                                                        [
                                                          item.subfolderMetadata
                                                            .canEdit
                                                            ? _c("li", [
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn-clear clickable",
                                                                    attrs: {
                                                                      type:
                                                                        "button"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.toggleNodeShowForKunde(
                                                                          item
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    item
                                                                      .subfolderMetadata
                                                                      .showForKunde
                                                                      ? [
                                                                          _c(
                                                                            "PhEyeSlash",
                                                                            {
                                                                              attrs: {
                                                                                size: 24
                                                                              }
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "Ordner für Kunde verbergen"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      : [
                                                                          _c(
                                                                            "PhEye",
                                                                            {
                                                                              attrs: {
                                                                                size: 24
                                                                              }
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "Ordner für Kunde freigeben"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                  ],
                                                                  2
                                                                )
                                                              ])
                                                            : _vm._e(),
                                                          item.subfolderMetadata
                                                            .canAdd
                                                            ? _c("li", [
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn-clear clickable",
                                                                    attrs: {
                                                                      type:
                                                                        "button"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.addNewNode(
                                                                          item
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "PhPlus",
                                                                      {
                                                                        attrs: {
                                                                          size: 24
                                                                        }
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Neuer Ordner anlegen"
                                                                      )
                                                                    ])
                                                                  ],
                                                                  1
                                                                )
                                                              ])
                                                            : _vm._e(),
                                                          item.subfolderMetadata
                                                            .canEdit
                                                            ? _c("li", [
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn-clear clickable",
                                                                    attrs: {
                                                                      type:
                                                                        "button"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.editNode(
                                                                          item
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "PhPencilLine",
                                                                      {
                                                                        attrs: {
                                                                          size: 24
                                                                        }
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Ordner ändern"
                                                                      )
                                                                    ])
                                                                  ],
                                                                  1
                                                                )
                                                              ])
                                                            : _vm._e(),
                                                          item.subfolderMetadata
                                                            .canRemove
                                                            ? _c("li", [
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn-clear clickable",
                                                                    attrs: {
                                                                      type:
                                                                        "button"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.openRemoveNodeConfirm(
                                                                          item
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "PhTrash",
                                                                      {
                                                                        attrs: {
                                                                          size: 24
                                                                        }
                                                                      }
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        "Ordner löschen"
                                                                      )
                                                                    ])
                                                                  ],
                                                                  1
                                                                )
                                                              ])
                                                            : _vm._e()
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  4052857939
                )
              })
            : _vm._e()
        ],
        1
      ),
      _c("AddPrivateDokNodeModal", { ref: "addNewNodeModal" })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "add-new-node-tree--header" }, [
      _c("span", { staticClass: "add-new-node-tree--label" }, [_vm._v("Name")]),
      _c("span", { staticClass: "add-new-node-tree--visibility" }, [
        _vm._v("Sichtbar")
      ]),
      _c("span", { staticClass: "add-new-node-tree--actions" }, [
        _vm._v("Aktionen")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }