import VERMITTLERWECHSEL_STATUS_TYPES from './types';

export default {
  [VERMITTLERWECHSEL_STATUS_TYPES.GETTERS.VERMITTLERWECHSEL_STATUS](state) {
    return state.vermittlerwechselStatusData;
  },
  [VERMITTLERWECHSEL_STATUS_TYPES.GETTERS.SELECTED_EINTRAG](state) {
    return state.selectedEintrag;
  },
  [VERMITTLERWECHSEL_STATUS_TYPES.GETTERS.GESELLSCHAFTEN](state) {
    return state.gesellschaften;
  },
  [VERMITTLERWECHSEL_STATUS_TYPES.GETTERS.UPDATE_STATUS_RESULT](state) {
    return state.updateStatusResult;
  },
  [VERMITTLERWECHSEL_STATUS_TYPES.GETTERS.DATA_FOR_EDITING](state) {
    return state.dataForEditing;
  },
  [VERMITTLERWECHSEL_STATUS_TYPES.GETTERS.BEISPIELKONTONR](state) {
    return state.beispielkontonr;
  }, 
  
  
}