var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.$appNavigation.currentMenu.label,
          actions: _vm.headerActions
        },
        on: {
          "action-MARK": function($event) {
            return _vm.confirmSelection()
          }
        }
      }),
      _c("StornoWarnungFilter", {
        ref: "filter",
        on: {
          getStornos: function($event) {
            return _vm.getStornosForPage({ limit: _vm.ROWS_PER_PAGE_DEFAULT })
          }
        }
      }),
      _c("StornoWarnungTable", {
        attrs: { focusOnResult: "" },
        on: {
          goToPage: function($event) {
            return _vm.getStornosForPage($event)
          },
          sort: function($event) {
            return _vm.getStornosForPage($event)
          },
          confirm: function($event) {
            return _vm.confirmSelection()
          }
        },
        model: {
          value: _vm.selectedRows,
          callback: function($$v) {
            _vm.selectedRows = $$v
          },
          expression: "selectedRows"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }