import TYPES from './types';

import axios from 'axios';
import CORE_TYPES from "@/store/core/types";
import LOG_TYPES from '@/store/log/types';
import { doUpload,isLegi } from '@/helpers/versandProtokolle';
import COMMUNICATION_TYPES from "@/store/communication/types";
import router from '../../router';
export default {

 async [TYPES.ACTIONS.GET_RESULT]({ commit, rootState, state }, e) {
    const config = {
      defaultSpinner: true,
    };



   await axios.get(rootState.core.apiAddress + '/ticketsnachrichten/getChatTestQuery').then(response => {


      commit(TYPES.MUTATIONS.RESULT, response.data);
      if(response.data==true){
        commit(COMMUNICATION_TYPES.MUTATIONS.LOAD_TICKETS_NACHRICHTEN_LAZY, []);
      }

    })
  },

  async [TYPES.ACTIONS.SET_RESULT]({ commit, rootState, dispatch, state }, e) {
    const config = {
      defaultSpinner: true,
      disableDefaultLog: false,
      disableDefaultErrorMessage: false,
      cancelableRequest: {
        title: 'Edit Pages'
      }
    };


    let b=false;
    await axios.post(rootState.core.apiAddress + '/ticketsnachrichten/setChatTestQuery?val='+ e, config).then(response => {

      commit(TYPES.MUTATIONS.RESULT, response.data);
      b=response.data;
    
    

    });

    if(b==true){
      await commit(COMMUNICATION_TYPES.MUTATIONS.LOAD_TICKETS_NACHRICHTEN_LAZY, []);
      await commit(COMMUNICATION_TYPES.MUTATIONS.UPDATE_TICKETS_NACHRICHTEN_LOADED_TIME, null);
      const params = {
        ignoreResponsibility: true,
        isOverview: true,
        limit: 5,
      };
       let dat={

       };
       dat.chats=5;
      await dispatch(COMMUNICATION_TYPES.ACTIONS.LOAD_TICKETS_NACHRICHTEN_LAZY, params);
      await commit(COMMUNICATION_TYPES.MUTATIONS.UPDATE_TICKETS_NACHRICHTEN_LOADED_TIME, null);
      await dispatch (COMMUNICATION_TYPES.ACTIONS.UNREAD_COMMUNICATIONS);
    }
  },
  




}


