var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-forms__container" },
    [
      _c("div", { staticClass: "input-forms__label-container" }, [
        _c("div", { staticClass: "row mt-0" }, [
          _c("div", { staticClass: "col" }, [
            _vm.title
              ? _c(
                  "div",
                  {
                    staticClass:
                      "\n            input-forms__label-content input-forms__label-content--bigger\n          "
                  },
                  [
                    _c("ph-bank", { attrs: { size: 16 } }),
                    _vm._v(" " + _vm._s(_vm.title) + " ")
                  ],
                  1
                )
              : _vm._e()
          ]),
          _vm.config && _vm.config.buttonText
            ? _c(
                "div",
                { staticClass: "col-auto" },
                [
                  _c(
                    "BaseButton",
                    {
                      attrs: {
                        disabled:
                          _vm.disabled ||
                          (_vm.maxAnzahlFonds &&
                            _vm.rows.length >= _vm.maxAnzahlFonds)
                      },
                      on: { click: _vm.openFormsFinder }
                    },
                    [_vm._v(_vm._s(_vm.config.buttonText))]
                  )
                ],
                1
              )
            : _vm._e()
        ])
      ]),
      _vm.positions && _vm.positions.length
        ? _c(
            "div",
            { staticClass: "input-forms__input-container" },
            [
              _c("Table", {
                attrs: {
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowId: "rowId",
                  hidePagination: ""
                },
                on: { "action-DELETE": _vm.openModalDeleteFond },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "aa",
                      fn: function(row) {
                        return [
                          _c("InputField", {
                            attrs: {
                              type: "percent",
                              precision: 2,
                              value: row.aa,
                              placeholder: row.defaultAA,
                              id: "aa" + row.rowId,
                              disabled: _vm.disabled
                            },
                            on: {
                              input: function($event) {
                                return _vm.onChangeInputField($event, row, "aa")
                              }
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "betrag",
                      fn: function(row) {
                        return [
                          row.betragTyp === "einmalAnteilLast"
                            ? _c("InputField", {
                                attrs: {
                                  type: "number",
                                  precision: 2,
                                  value: row[row.betragTyp],
                                  disabled: _vm.disabled
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeBetrag(
                                      $event,
                                      row,
                                      row.betragTyp
                                    )
                                  }
                                }
                              })
                            : _c("InputField", {
                                attrs: {
                                  type: "currency",
                                  precision: 2,
                                  value: row[row.betragTyp],
                                  id: row.betragTyp + row.rowId,
                                  placeholder: _vm.isSparplan
                                    ? "mind. 25,-EUR"
                                    : "mind. 250,-EUR",
                                  disabled: _vm.disabled
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeBetrag(
                                      $event,
                                      row,
                                      row.betragTyp
                                    )
                                  }
                                }
                              })
                        ]
                      }
                    },
                    {
                      key: "betragTyp",
                      fn: function(row) {
                        return [
                          _c("ComboBox", {
                            attrs: {
                              value: row.betragTyp,
                              values: _vm.betragTypValues,
                              disabled: _vm.disabled
                            },
                            on: {
                              change: function($event) {
                                return _vm.onChangeBetragTyp(
                                  $event,
                                  row,
                                  row[row.betragTyp]
                                )
                              }
                            }
                          })
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2809926579
                )
              }),
              _vm.config.mitSummenbildung &&
              this.positions &&
              this.positions.length
                ? _c(
                    "div",
                    [
                      _c("Summenbildung", {
                        attrs: {
                          antragId: _vm.antragId,
                          summe: _vm.summe,
                          noContainer: ""
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.maxAnzahlFonds && _vm.rows.length > _vm.maxAnzahlFonds
                ? _c("div", { staticClass: "color-danger" }, [
                    _vm._v(
                      " Achtung! Es sind nur maximal " +
                        _vm._s(_vm.maxAnzahlFonds) +
                        " Wertpapiere erlaubt. "
                    )
                  ])
                : _vm._e(),
              _c("WertpapierAuswahlPositionFields", {
                staticClass: "mt-3",
                attrs: {
                  config: _vm.config,
                  categoryId: _vm.categoryId,
                  antragId: _vm.antragId,
                  disabled: _vm.disabled
                }
              })
            ],
            1
          )
        : _c("div", [_c("div", [_vm._v("Wertpapierauswahl ist leer")])]),
      _c("DeletePositionModal", {
        ref: "deleteModal",
        attrs: { position: _vm.positionToDelete },
        on: {
          delete: function($event) {
            return _vm.doDeletePosition()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }