<template>
  <div class="antrag-components__container">
    <div class="box__container">
      <FormLabel label="Daten der bisher depotführenden Gesellschaft"/>
      <InputField v-model="form.bisherDepotnr" label="Depotnummer"
        @change="$emit('save', { bisherDepotnr: $event })"/>
      <InputField v-model="form.bisherManuellName" label="Name"
        @change="$emit('save', { bisherManuellName: $event })"/>
      <InputField v-model="form.bisherManuellStrasse" label="Straße/Nr."
        @change="$emit('save', { bisherManuellStrasse: $event })"/>
      <InputField v-model="form.bisherManuellOrt" label="PLZ Ort"
        @change="$emit('save', { bisherManuellOrt: $event })"/>
      <InputField v-model="form.bisherManuellLand" label="Land"
        @change="$emit('save', { bisherManuellLand: $event })"/>

      <FormDivider/>

      <BaseButton @click="$emit('next')">Weiter</BaseButton>
    </div>
  </div>
</template>
<script>
import FormLabel from '@/components/core/forms/FormLabel.vue';
import InputField from '@/components/core/forms/InputField.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import FormDivider from '@/components/core/forms/FormDivider.vue';

export default {
  props: {
    positionData: {
    },
    warnings: {
    },
  },
  components: {
    FormLabel,
    InputField,
    BaseButton,
    FormDivider,
  },
  computed: {
  },
  data() {
    return {
      form: {}
    }
  },
  mounted() {
    this.form = Object.assign({}, this.positionData);
  },
  methods: {
  }
}
</script>
<style scoped>

</style>