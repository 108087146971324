import Vue from 'vue';
import { positionElement } from '@/mixins/positioning-helper';
import { hasChanged } from './directive-utils';
import { isObject } from '@/helpers/commonfunctions.js';
import {sanitize} from '@/helpers/string-helper.js';

const DATASET_PREFIX = 'data-fc-tooltip-';

const DEFAULT_OPTIONS = {
  content: '',
  placement: 'top',
  gap: 8,
}

function makeElFocusable(el) {
  if(el.tabIndex < 0) {
    el.setAttribute('tabindex', '0');
  }
}

function createOrReplaceDataset(el, value) {
  const options = isObject(value) ? Object.assign({}, value) : { content: value || '', };

  // Remove dataset
  Object.keys(DEFAULT_OPTIONS).forEach(key => el.removeAttribute(`${DATASET_PREFIX}${key}`));

  // Add dataset
  Object.keys(options).forEach(key => el.setAttribute(`${DATASET_PREFIX}${key}`, options[key]));
}

function createTooltipEl(className='fc-tooltip') {
  const tooltipEl = document.createElement('div');
  tooltipEl.setAttribute('class', className);
  return tooltipEl;
}

function datasetOptions(el) {
  return Object.keys(DEFAULT_OPTIONS).reduce((acc, key) => ({
    ...acc,
    [key]: el.getAttribute(`${DATASET_PREFIX}${key}`) || DEFAULT_OPTIONS[key],
  }), {});
}

function isTouch(event) {
  return event.pointerType === 'touch' || !!event.touches;
}

function show(el, tooltipEl) {
  const options = datasetOptions(el);
  if(tooltipEl.parentNode || !options.content) return;

  tooltipEl.innerHTML = sanitize(`
    <div class="fc-tooltip-inner">${ options.content || '' }</div>
  `);
  document.body.append(tooltipEl);

  positionElement(el, tooltipEl, {
    placement: options.placement,
    gap: options.gap,
    appendToBody: true,
  });
}

function hide(tooltipEl) {
  if(!tooltipEl.parentNode) return;

  document.body.removeChild(tooltipEl);
}

function touch(event, el, tooltipEl) {
  if(isTouch(event)) {
    show(el, tooltipEl);
  }
}

function toggle(event, el, tooltipEl) {
  if(!isTouch(event)) {
    event.type === 'pointerenter' ? show(el, tooltipEl) : hide(tooltipEl);
  }
}

Vue.directive('fc-tooltip', {
  bind(el, binding) {
    makeElFocusable(el);
    createOrReplaceDataset(el, binding?.value);

    const tooltipEl = createTooltipEl(binding?.value?.className);
    el._tooltipEl = tooltipEl;
    el.addEventListener('pointerdown', (event) => touch(event, el, tooltipEl));
    el.addEventListener('pointerenter', (event) => toggle(event, el, tooltipEl));
    el.addEventListener('pointerleave', (event) => toggle(event, el, tooltipEl));
    el.addEventListener('focus', () => show(el, tooltipEl));
    el.addEventListener('blur', () => hide(tooltipEl));
  },
  update(el, binding) {
    if(!hasChanged(binding?.value, binding?.oldValue)) return;

    createOrReplaceDataset(el, binding?.value);
  },
  unbind(el) {
    hide(el._tooltipEl);
    el._tooltipEl = undefined;
  },
});
