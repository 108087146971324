<template>
<div>
  <div v-if="maklerAntragConfigData && maklerAntragConfigData.WPV" class="box__container">
    <div class="box__title">Wertpapiervermittler iSd § 1 Z 45 WAG 2018</div>
    <FormDivider />
    <FormLabel
      label="Anforderung: Da Sie als Wertpapiervermittler bei 3 Rechtsträgern gemeldet sein können, bitten wir Sie, uns diese bekanntzugeben."
    />
      <InputRadioBoxGroup 
      :value="form.INPUT_WPV_BEST1"
      :values="wpvBestValues"
      labelClass="font-bold"
      :disabled="disabledStatusOK"
      @input="fieldChanged($event, 'INPUT_WPV_BEST1')"
      />
    <InputField
      :value="form.INPUT_WPV_TEXT1"
      label="Name des Rechtsträgers:"
      isComponentHalfSize
      :disabled="form.INPUT_WPV_BEST1 || disabledStatusOK" 
      @change="fieldChanged($event, 'INPUT_WPV_TEXT1')"
    />

    <DatePickerField
      label="seit"
      :value="form.INPUT_WPV_DATUM1"
      isValueAsString
      isComponentHalfSize
      :disabled="form.INPUT_WPV_BEST1 || disabledStatusOK"
      @change="fieldChanged($event, 'INPUT_WPV_DATUM1')"
    />
    <InputField
      :value="form.INPUT_WPV_TEXT2"
      label="Name des Rechtsträgers:"
      isComponentHalfSize
      :disabled="form.INPUT_WPV_BEST1 || disabledStatusOK"
      @change="fieldChanged($event, 'INPUT_WPV_TEXT2')"
    />
    <DatePickerField
      label="seit"
      :value="form.INPUT_WPV_DATUM2"
      isValueAsString
      isComponentHalfSize
      :disabled="form.INPUT_WPV_BEST1 || disabledStatusOK"
      @change="fieldChanged($event, 'INPUT_WPV_DATUM2')"
    />
  
    <InputToggleSwitch
      label="Ich bestätige hiermit, die Angaben wahrheitsgetreu getätigt zu haben."
      :inLineLabel="true"
      :config="{ bold: true }"
      isComponentHalfSize
      :value="form.INPUT_BEST_WAHR_3"
      :disabled="disabledStatusOK"
      @input="fieldChanged($event, 'INPUT_BEST_WAHR_3')"
    />
  </div>
   <div class="box__container" v-else-if="maklerAntragConfigData && maklerAntragConfigData.vgV">
    <div class="box__title">Firmenbuchauszug juristische Personen (vgV) oder eingetragenes Einzelunternehmen</div>

    <FormLabel v-if="maklerAntragConfigData.juristischePerson"
      label="Anforderung: Bitte übermitteln Sie uns einen aktuellen Firmenbuchauszug (nicht älter als 3 Monate). Sollte der Auszug nicht innerhalb der gesetzten Frist bei uns einlagen, 
      wird dieser von FinanzAdmin abgerufen und die Kosten in Höhe von € 20,00 zuzüglich MwSt. von der nächsten Provisionsabrechnung abgezogen."
    />
    <InputToggleSwitch v-if="maklerAntragConfigData.juristischePerson"
      label="Der Firmenbuchauszug ist beigelegt (ein aktueller Firmenbuchauszug ist von allen Vertriebspartnern (juristische Personen) verpflichtend beizulegen)."
      :inLineLabel="true"
      isComponentHalfSize
      :value="form.INPUT_FIRMENBUCHAUSZUG_BEST"
      :disabled="disabledStatusOK"
      @input="fieldChanged($event, 'INPUT_FIRMENBUCHAUSZUG_BEST')"
    />
       <ChecklisteDocsUpload v-if="maklerAntragConfigData.juristischePerson" :paramId="this.form.PARAM_ID" :topic="'Firmenbuchauszug'" :form="this.form" />
 
      <FormLabel
      label="Anforderung: Weiters bitten wir Sie um Bekanntgabe, 
      bei welchen auch im WAG 2018 Bereich tätigen Firmen Sie (alle Geschäftsleiter) oder Ihre Angestellten auch tätig sind bzw. Funktionen ausüben."
    />
      <InputRadioBoxGroup 
      :value="form.INPUT_WAG_BEST"
      :values="wagBestValues"
      labelClass="font-bold"
      :disabled="disabledStatusOK"
      @input="fieldChanged($event, 'INPUT_WAG_BEST')"
      />
    <InputField
      :value="form.INPUT_FIRMENNAME_TEXT"
      label="Name:"
      isComponentHalfSize
      @input="firma2 = true"
      :disabled="form.INPUT_WAG_BEST || disabledStatusOK"
      @change="fieldChanged($event, 'INPUT_FIRMENNAME_TEXT')"
    />
      <InputField
      :value="form.INPUT_FIRMENFUNKTION_TEXT"
      label="Funktion/Tätigkeit:"
      isComponentHalfSize
      :disabled="form.INPUT_WAG_BEST || disabledStatusOK"
      @change="fieldChanged($event, 'INPUT_FIRMENFUNKTION_TEXT')"
    />
    <InputField
      :value="form.INPUT_GEWERBEBERECHTIGUNG_TEXT"
      label="Art der Gewerbeberechtigung:"
      isComponentHalfSize
       :disabled="form.INPUT_WAG_BEST || disabledStatusOK"
      @change="fieldChanged($event, 'INPUT_GEWERBEBERECHTIGUNG_TEXT')"
    />

    <InputField v-if="firma2 || form.INPUT_FIRMENNAME_TEXT2 != ''"
      :value="form.INPUT_FIRMENNAME_TEXT2"
      label="Name:"
      isComponentHalfSize
      :disabled="form.INPUT_WAG_BEST || disabledStatusOK"
      @change="fieldChanged($event, 'INPUT_FIRMENNAME_TEXT2')"
    />
      <InputField v-if="firma2 || form.INPUT_FIRMENFUNKTION_TEXT2 != ''"
      :value="form.INPUT_FIRMENFUNKTION_TEXT2"
      label="Funktion/Tätigkeit:"
      isComponentHalfSize
      :disabled="form.INPUT_WAG_BEST || disabledStatusOK"
      @change="fieldChanged($event, 'INPUT_FIRMENFUNKTION_TEXT2')"
    />
    <InputField v-if="firma2 || form.INPUT_GEWERBEBERECHTIGUNG_TEXT2!=''"
      :value="form.INPUT_GEWERBEBERECHTIGUNG_TEXT2"
      label="Art der Gewerbeberechtigung:"
      isComponentHalfSize
      :disabled="form.INPUT_WAG_BEST || disabledStatusOK"
      @change="fieldChanged($event, 'INPUT_GEWERBEBERECHTIGUNG_TEXT2')"
    />
    
    <FormLabel
      label="Anforderung: Sollte ein im Firmenbuch eingetragener Geschäftsführer seitens FinanzAdmin nicht bei der FMA gemeldet sein, da dieser nicht im WAG 2018 Bereich tätig ist,
       ist das Formular „Geschäftsleitung nicht im WAG tätig“ seitens dieses Geschäftsführers unterschrieben zu übermitteln."
    />
     <InputRadioBoxGroup 
      :value="form.INPUT_VORGELEGT_BEST"
      :values="vorgelegtBestValues"
      labelClass="font-bold"
      :disabled="disabledStatusOK"
      @input="fieldChanged($event, 'INPUT_VORGELEGT_BEST')"
      />
     <ChecklisteDocsUpload :paramId="this.form.PARAM_ID" :topic="'Bestätigung'" :form="this.form"/>
 
      <FormLabel
      label="Anforderung: Bitte geben Sie alle in der Anlageberatung tätigen Mitarbeiter an."
    />
    <FormLabel label="Folgende Mitarbeiter sind in der Anlageberatung tätig:" />

    
    <InputField
      :value="form.INPUT_MITARBEITER1"
      label="Name:"
      isComponentHalfSize 
      @input="mitarbeiter2 = true" 
      :disabled="disabledStatusOK" 
      @change="fieldChanged($event, 'INPUT_MITARBEITER1')"
    />
     <InputField v-if="mitarbeiter2 || form.INPUT_MITARBEITER2!= ''"
      :value="form.INPUT_MITARBEITER2"
      label="Name:"
      @input="mitarbeiter3 = true" 
      isComponentHalfSize   
      :disabled="disabledStatusOK"
      @change="fieldChanged($event, 'INPUT_MITARBEITER2')"
    />
     <InputField v-if="mitarbeiter3 || form.INPUT_MITARBEITER3!= ''"
      :value="form.INPUT_MITARBEITER3"
      label="Name:"
      isComponentHalfSize   
      :disabled="disabledStatusOK"
      @input="mitarbeiter4 = true"  
      @change="fieldChanged($event, 'INPUT_MITARBEITER3')"
    />
    <InputField v-if="mitarbeiter4 || form.INPUT_MITARBEITER4 != ''"
      :value="form.INPUT_MITARBEITER4"
      label="Name:"
      isComponentHalfSize   
      :disabled="disabledStatusOK"
      @input="mitarbeiter5 = true"  
      @change="fieldChanged($event, 'INPUT_MITARBEITER4')"
    />
    <InputField v-if="mitarbeiter5 || form.INPUT_MITARBEITER5 != ''"
      :value="form.INPUT_MITARBEITER5"
      label="Name:"
      isComponentHalfSize  
      :disabled="disabledStatusOK" 
      @change="fieldChanged($event, 'INPUT_MITARBEITER5')"
    />
  
    <FormDivider />
    <InputToggleSwitch
      label="Ich bestätige hiermit, die Angaben wahrheitsgetreu getätigt zu haben."
      :inLineLabel="true"
      :config="{ bold: true }"
      isComponentHalfSize
      :disabled="disabledStatusOK"
      :value="true"
    />
  </div>
  <div v-else>
      <NoData content="Keine Daten vorhanden" />
  </div>
  </div>
</template>
<script>
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import FormLabel from "@/components/core/forms/FormLabel.vue";
import InputField from "@/components/core/forms/InputField.vue";
import FormDivider from "@/components/core/forms/FormDivider.vue";
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import MAKLER_ANTRAG_TYPES from "@/store/maklerAntrag/types";
import { mapGetters } from "vuex";
import NoData from '@/components/core/NoData.vue';
import ChecklisteDocsUpload from './ChecklisteDocsUpload.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';

export default {
  components: {
    InputField,
    InputToggleSwitch,
    FormLabel,
    FormDivider,
    DatePickerField,
    NoData,
    ChecklisteDocsUpload,
    InputRadioBoxGroup
  },
  props: {
    form: {},
  },
  data() {
    return {
    mitarbeiter2: false,
    mitarbeiter3: false,
    mitarbeiter4: false,
    mitarbeiter5: false,
    firma2:false,
    wagBestValues:[
      {label:"Im WAG 2018 Bereich (konzessionspflichtiger Bereich) übe/üben ich/wir/meine/unsere Angestellten bei keinen weiteren Firmen eine Tätigkeit oder Funktion (Geschäftsführer, Angestellter, Gesellschafter) aus.",value:true},
      {label:"Ich/wir/meine/unsere Angestellten bin/sind noch für folgende Firmen tätig:",value:false}],
    vorgelegtBestValues:[
      {label:"Die Bestätigung wurde beigelegt.",value:true},
      {label:"Dieser Umstand trifft auf mich/uns nicht zu.",value:false}
    ],
    wpvBestValues:[
      {label:"Ich bin ausschließlich bei FinanzAdmin gemeldet.",value:true},
      {label:"Ich bin noch für folgende Rechtsträger tätig",value:false}
    ]
    };
  },
  mounted(){
    this.loadConfigData()
  },
  computed:{
    ...mapGetters({
      maklerAntragConfigData: MAKLER_ANTRAG_TYPES.GETTERS.GET_CONFIG_DATA,
    }),
     disabledStatusOK(){
     return  this.form.INPUT_STATUS_OK == '1' || this.form.INPUT_STATUS_OK == 'true'
   }
  },
  methods: {
    fieldChanged(newValue, propName) {
      const payload = { name: propName, value: newValue };
      this.$emit("fieldChanged", payload);
    },
   async loadConfigData(){
      await this.$store.dispatch(
        MAKLER_ANTRAG_TYPES.ACTIONS.LOAD_CONFIG_DATA
      )
    },
  },
};
</script>