import LOG_TYPES from '@/store/log/types';
import CORE_TYPES from '@/store/core/types';
import axios from 'axios';
import { buildMessage } from '@/helpers/log-message-helper';

const LOG_LEVELS = ['log', 'info', 'warn', 'error'];
const DEFAULT_LOG_LEVEL_COLOR = '#3d4e99';
const LOG_LEVEL_COLOR = {
  log: DEFAULT_LOG_LEVEL_COLOR,
  info: '#0071f2',
  warn: '#fad202',
  error: '#ea1601',
};

export const SUPPORT_ERROR_MESSAGE = 'Es ist ein Fehler aufgetreten. Bitte wenden Sie sich an unseren Support!';

// Sometimes messages should be duplicated in order to show that the same action happened multiple times.
// But some messages are useless when they're duplicated.
export const PREVENT_DUPLICATED_MESSAGES = [
  'Die Sitzung ist abgelaufen.'
]

function logLevelGreaterOrEquals(current, reference) {
  if (LOG_LEVELS.indexOf(current) >= 0 && LOG_LEVELS.indexOf(reference) >= 0) {
    return LOG_LEVELS.indexOf(current) >= LOG_LEVELS.indexOf(reference);
  }
  return false;
}

function consoleLog(logLevel, message, ...extra) {
  const isFormatted = process.env.VUE_APP_LOG_FORMATTED_PRINT_CONSOLE === 'true'

  if (isFormatted) {
    console.log(
      `%cFC [${logLevel}]%c`,
      `background: ${LOG_LEVEL_COLOR[logLevel] ?? DEFAULT_LOG_LEVEL_COLOR}; padding: 1px 4px; border-radius: 2px; color: #fff`,
      'background:transparent', 
      message, ...extra);
  } else {
    console.log(
      `FC [${logLevel}]`,
      message, ...extra);
  }
  
}

function isMessageDuplicatedAndShouldBeAvoided(message, getters) {
  if (!PREVENT_DUPLICATED_MESSAGES.includes(message.content)) {
    return false;
  }
  return getters[LOG_TYPES.GETTERS.MESSAGES].findIndex(m => m.content === message.content) > -1;
}

export default {
  /**
   * convenience methods for logging.
   * you can use them for just a simple message like
   *
   * dispatch(LOG_TYPES.ACTIONS.INFO, "logging a simple message");
   *
   * or you can give an object containing some or all of the following properties:
   *
   * dispatch(LOG_TYPES.ACTIONS.INFO, {
   *     message,       // a string that will be logged the same way as with the call above
   *     error,         // an error object that you want to log and send the stack trace to the server's exception log (with a high enough log-level)
   *     response,      // a response object for which metadata will be logged (currently the URL and status code)
   *     ...            // you can add any number of objects which will be logged in the console as well but not sent to the server's exception log
   * });
   * @param {*} payload can either be a string or the same as LOG_TYPES.ACTIONS.ADD_LOG's payload without logLevel
   */
  [LOG_TYPES.ACTIONS.LOG]({ dispatch }, payload) {
    if (typeof payload == "string")
      payload = {message: payload};
    dispatch(LOG_TYPES.ACTIONS.ADD_LOG, {...payload, logLevel: 'log'});
  },
  [LOG_TYPES.ACTIONS.INFO]({ dispatch }, payload) {
    if (typeof payload == "string")
      payload = {message: payload};
    dispatch(LOG_TYPES.ACTIONS.ADD_LOG, {...payload, logLevel: 'info'});
  },
  [LOG_TYPES.ACTIONS.WARN]({ dispatch }, payload) {
    if (typeof payload == "string")
      payload = {message: payload};
    dispatch(LOG_TYPES.ACTIONS.ADD_LOG, {...payload, logLevel: 'warn'});
  },
  [LOG_TYPES.ACTIONS.ERROR]({ dispatch }, payload) {
    if (typeof payload == "string")
      payload = {message: payload};
    dispatch(LOG_TYPES.ACTIONS.ADD_LOG, {...payload, logLevel: 'error'});
  },
  /**
   * Don't use this action, use one of the convenience methods above instead
   */
  [LOG_TYPES.ACTIONS.ADD_LOG]({ commit, getters }, {logLevel, message, error, response, ...additionalData}) {
    if (!message)
      message = "";
    commit(LOG_TYPES.MUTATIONS.ADD_LOG, {logLevel, message, error, response});

    if (logLevelGreaterOrEquals(logLevel, process.env.VUE_APP_LOG_LEVEL_PRINT_CONSOLE)) {
      const extra = [];
      if (error)
        extra.push(error);
      if (response)
        extra.push(response);
      if (Object.keys(additionalData).length)
        extra.push(additionalData);
      consoleLog(logLevel, message, ...extra);
    }

    if (logLevelGreaterOrEquals(logLevel, process.env.VUE_APP_LOG_LEVEL_PUT_EXCEPTION)) {

      // add useful metadata to message
      const userAgent = navigator.userAgent
      const buildTimestamp = `v${document.documentElement.getAttribute('data-build-timestamp')}`;

      // make sure we have a stack trace
      if (!error)
        error = new Error();

      const serverLog = {
        message,
        stackTrace: JSON.stringify({ userAgent, buildTimestamp, error }, null, 2),
        className: 'SmartMSC',
      }

      axios.put(`${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsputexception`, serverLog, { disableDefaultLog: true })
      .catch(e => {
        // not logging this message to prevent infinite loop if something is wrong with the server
      });
    }
  },

  [LOG_TYPES.ACTIONS.ADD_RESPONSE_ERROR_MESSAGE]({ dispatch }, error) {
    let data = error.response?.data;

    try {
      if (data instanceof ArrayBuffer) {
        data = JSON.parse(new TextDecoder("utf-8").decode(error.response.data))
      }
    } catch (error) {
      //empty block
    }
    const message = data?.message || 'Es ist nicht möglich.'
    dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(message, 'danger', false));
  },

  [LOG_TYPES.ACTIONS.ADD_MESSAGE]({ commit, state, getters }, payload) {
    if (!payload || (typeof payload === 'object' && !payload?.content)) {
      return
    }
    let closeMessageScheduled = null;
    const messageCloseableTimeMS = payload.timeout ? payload.timeout : (state.messageCloseableTimeSeconds * 1000) // if message closeable time seconds is zero, doesn't close automatically
    const message = typeof payload === 'string' ? buildMessage(payload) : payload

    if (messageCloseableTimeMS && payload && payload.closeable && payload.id) {
      payload.messageCloseableTimeMS = messageCloseableTimeMS;
      closeMessageScheduled = setTimeout(() => {
        commit(LOG_TYPES.MUTATIONS.REMOVE_MESSAGE, payload);
      }, messageCloseableTimeMS)
    }

    if (isMessageDuplicatedAndShouldBeAvoided(message, getters)) {
      return;
    }

    commit(LOG_TYPES.MUTATIONS.ADD_MESSAGE, { ...message, closeMessageScheduled });
  },

  [LOG_TYPES.ACTIONS.REMOVE_MESSAGE]({ commit }, payload) {
    if (payload && payload.closeMessageScheduled) {
      clearTimeout(payload.closeMessageScheduled);
    }
    commit(LOG_TYPES.MUTATIONS.REMOVE_MESSAGE, payload);
  },

  /**
   * Appends a log item on screen
   * 
   * it is useful when it is not possible to use/access a console/web dev tools
   * 
   * ONLY USE IT ON DEVELOPMENT MODE
   * 
   * @param {*} payload 
   * @returns 
   */
  [LOG_TYPES.ACTIONS.LOG_ON_SCREEN](ctx, payload) {
    if(process.env.NODE_ENV !== 'development') return;

    const CONTAINER_ID = 'log-on-screen__container';
    const CONTAINER_SELECTOR = `#${CONTAINER_ID}`;

    const _createLogContainerEl = () => {
      const el = document.createElement('div');
      el.id = CONTAINER_ID;
      el.style.cssText = `
        background: rgba(255, 255, 255, 0.5);
        border-radius: 4px;
        box-shadow: 0px 0px 1px 1px #000;
        box-sizing: border-box;
        margin: 12px;
        padding: 10px;
        pointer-events: none;
        position: fixed;
        bottom: 0;
        z-index: 99999999;
        width: calc(100% - 24px);
      `;
      return el;
    }

    const _createLogItemEl = (message) => {
      const el = document.createElement('pre');
      el.style.cssText = `margin: 1rem 0;`;
      el.innerHTML = JSON.stringify(message, null, 2);
      return el;
    }

    const logContainerEl = document.querySelector(CONTAINER_SELECTOR) || _createLogContainerEl();
    requestAnimationFrame(() => {
      logContainerEl.appendChild(_createLogItemEl(payload));
  
      if(!document.querySelector(CONTAINER_SELECTOR)) {
        document.body.appendChild(logContainerEl);

        let countTimeoutId = null;
        let countClick = 0;
        const clearOnClick = () => {
          clearTimeout(countTimeoutId);
          countClick++;
          countTimeoutId = setTimeout(() => countClick = 0, 500);

          if(countClick > 4) {
            document.body.removeChild(document.querySelector(CONTAINER_SELECTOR))
            document.removeEventListener('click', clearOnClick);
          }
        };
        document.addEventListener('click', clearOnClick);
      }
    });
  },

}