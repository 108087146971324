var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("InputField", {
    attrs: {
      validation_path: _vm.validation_path,
      label: _vm.label,
      disabled: _vm.disabled,
      isComponentHalfSize: _vm.isComponentHalfSize
    },
    on: {
      input: function($event) {
        return _vm.checkIbanAndUpdateValue($event)
      },
      change: _vm.showError,
      focus: function($event) {
        _vm.isInputActive = true
      }
    },
    model: {
      value: _vm.iban,
      callback: function($$v) {
        _vm.iban = $$v
      },
      expression: "iban"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }