var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-inline" },
    [
      _c(
        "BaseButton",
        {
          class: _vm.buttonClass,
          attrs: {
            disabled: _vm.disabled,
            isLink: _vm.isLink,
            isPrimary: _vm.isPrimary,
            isSecondary: _vm.isSecondary,
            isBlock: _vm.isBlock
          },
          on: {
            click: function($event) {
              _vm.virtualCameraEnabled = true
            }
          }
        },
        [_vm._v(" Fotografieren ")]
      ),
      _vm.virtualCameraEnabled
        ? _c("VirtualCamera", {
            attrs: { camera: _vm.camera, edgeCorrection: _vm.edgeCorrection },
            on: {
              input: _vm.onPhoto,
              cancel: function($event) {
                _vm.virtualCameraEnabled = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }