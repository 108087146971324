var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.$route.params.lagerstelle,
          subtitle: "Depotübertragung | Übertragungsauftrag bearbeiten"
        }
      }),
      _vm.searchingPositions
        ? _c(
            "div",
            [
              _c("AntragFormsFondsFinder", {
                attrs: { addPositionsOnBack: false },
                on: {
                  goBack: function($event) {
                    return _vm.closeFondsfinder($event)
                  }
                }
              })
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "box__container d-lg-none col-12" },
                [
                  _c("Stepper2", {
                    ref: "refStepper2",
                    attrs: {
                      stepType: "step",
                      visible: true,
                      stepperName: "uebertragungSmall",
                      selectedStepKey: _vm.stepKey,
                      stepperService: _vm.stepper
                    },
                    on: {
                      setStep: function($event) {
                        return _vm.setStepByKey($event)
                      }
                    }
                  })
                ],
                1
              ),
              _c("ContentWithStepper", {
                attrs: { stepperCollapsed: _vm.stepperCollapsed },
                scopedSlots: _vm._u([
                  {
                    key: "stepper",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          {
                            staticClass: "antrag-step__container box__container"
                          },
                          [
                            _c("VerticalStepper", {
                              attrs: {
                                stepType: "tab",
                                visible: true,
                                stepperName: "uebertragung",
                                selectedStepKey: _vm.stepKey,
                                stepperService: _vm.stepper,
                                showCollapse: true
                              },
                              on: {
                                setStep: function($event) {
                                  return _vm.setStepByKey($event)
                                },
                                collapsed: function($event) {
                                  _vm.stepperCollapsed = $event
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "content",
                    fn: function() {
                      return [
                        _c("div", { staticClass: "box__container" }, [
                          _c("label", { staticClass: "box__title" }, [
                            _vm._v(_vm._s(_vm.stepLabel))
                          ])
                        ]),
                        _vm.dynamicTemplate
                          ? _c(
                              _vm.dynamicTemplate,
                              _vm._g(
                                _vm._b(
                                  { tag: "component" },
                                  "component",
                                  _vm.dynamicBindings.props,
                                  false
                                ),
                                _vm.dynamicBindings.events
                              )
                            )
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  }
                ])
              }),
              _c(
                "div",
                { staticClass: "box__container" },
                [
                  _c(
                    "BaseButton",
                    {
                      attrs: { isSecondary: true },
                      on: {
                        click: function($event) {
                          return _vm.onBackButton()
                        }
                      }
                    },
                    [_vm._v("Fertigstellen")]
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }