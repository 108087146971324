export const MUTATION_PREFIX = 'INZIPFILE_';
export const ACTIONS_PREFIX = 'INZIPFILE_';
export const GETTERS_PREFIX = 'INZIPFILE_';

export default {
  MUTATIONS: {
    COMBO: MUTATION_PREFIX + 'COMBO',
    RESULT: MUTATION_PREFIX + 'RESULT',

    },
  ACTIONS: {
    //FIND_CORNERS: ACTIONS_PREFIX + 'FIND_CORNERS',
    COMBO: ACTIONS_PREFIX +"COMBO",
    UPLOAD:ACTIONS_PREFIX +"UPLOAD"
    
  },
  GETTERS: {
    COMBO: GETTERS_PREFIX + 'COMBO',
    RESULT:GETTERS_PREFIX + 'RESULT',
  }
}