<template>
  <div class="input-forms__container">
    <div class="input-forms__label-container">
      <div class="input-forms__input-container">
        <BaseButton
          :disabled="disabled || loading"
          class="mr-0"
          isComponentHalfSize
          @click="executeConfigButton()"
          isSecondary
        >
          <AnimatedSpinner v-if="loading" /> {{ label }}
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/core/BaseButton.vue";
import AnimatedSpinner from "@/components/core/AnimatedSpinner.vue";

export default {
  components: {
    BaseButton,
    AnimatedSpinner,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
    },
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async executeConfigButton() {
      this.$emit('executeConfigButton', {
        buttonId: this.id
      })
    },
  },
};
</script>
