var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "addSparteModal",
          attrs: {
            modalTitle: "Neue Sparte anlegen",
            labelButtonConfirm: "Sparte hinzufügen"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.addNewSparte()
            },
            close: function($event) {
              return _vm.close()
            }
          }
        },
        [
          _c("InputField", {
            attrs: { label: "Neue Sparte", disabled: "" },
            model: {
              value: _vm.beschreibung,
              callback: function($$v) {
                _vm.beschreibung = $$v
              },
              expression: "beschreibung"
            }
          }),
          _c("ComboBox", {
            attrs: { label: "Sparte", values: _vm.sparteValues },
            model: {
              value: _vm.sparteId,
              callback: function($$v) {
                _vm.sparteId = $$v
              },
              expression: "sparteId"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }