<template>
    <div>
        <div v-if="loading">
            <OptionMenu
                :id="$appNavigation.currentOptionMenuId"
                :defaultMenu="$appNavigation.currentOptionMenu" >
            </OptionMenu>

            <PageHeaderTitleNavigation
                :title="$appNavigation.currentMenu.label"
                :id="$appNavigation.currentOptionMenuId">
            </PageHeaderTitleNavigation>

            <div class="box__container">
                <GhostLoading  type="table" />
            </div>
        </div>

        <MyGoalsList v-else-if="hasMyGoals"/>
        <MyGoalSelectType v-else />
    </div>
</template>

<script>
import MY_GOALS_TYPES from '@/store/myGoals/types';
import MyGoalsList from '@/components/myGoals/MyGoalsList.vue';
import MyGoalSelectType from '@/components/myGoals/MyGoalSelectType.vue';
import { mapGetters } from 'vuex';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue'
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'

export default {
    components: {
        MyGoalsList,
        MyGoalSelectType,
        GhostLoading,
        OptionMenu,
        PageHeaderTitleNavigation,
    },
    data() {
        return {
            loading: true,
        }
    },
    mounted() {
        this.getGoals();
    },
    computed: {
        ...mapGetters({
            goals: MY_GOALS_TYPES.GETTERS.GOALS,
        }),
        hasMyGoals() {
            return this.goals?.length;
        }
    },
    methods: {
        async getGoals() {
            try {
                await this.$store.dispatch(MY_GOALS_TYPES.ACTIONS.GET_GOALS);
            } finally {
                this.loading = false;
            }
        },
    },
    beforeRouteLeave(to, from, next) {
        this.$addBreadcrumb({label: 'zurück zu Meine Ziele', to, from});
        next();
    },
};
</script>