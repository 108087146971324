var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "text-bold" }, [
        _vm._v(" " + _vm._s(_vm.label) + " ")
      ]),
      _c(
        "div",
        { staticClass: "besprochene-component-buttons" },
        [
          _c("BaseButton", {
            attrs: {
              isSecondary: "",
              label: "Neue Position",
              disabled: _vm.isDataReadOnly
            },
            on: {
              click: function($event) {
                return _vm.handleAction(
                  _vm.headerFields.BUTTON_WERTPAPIERE_BESPROCHEN_NEU
                )
              }
            }
          }),
          _c("BaseButton", {
            staticStyle: { margin: "0px" },
            attrs: {
              isSecondary: "",
              label: "Aktuelle Depotpositionen einfügen",
              disabled: _vm.isDataReadOnly
            },
            on: {
              click: function($event) {
                return _vm.handleAction(
                  _vm.headerFields.BUTTON_WERTPAPIERE_BESPROCHEN_UPDATE
                )
              }
            }
          })
        ],
        1
      ),
      _vm.loading ? _c("GhostLoading", { attrs: { type: "table" } }) : _vm._e(),
      _vm.wertpapierPanels.length
        ? _c(
            "div",
            _vm._l(_vm.wertpapierPanels, function(item, index) {
              return _c(
                "div",
                { key: index },
                [
                  _c("BesprocheneItem", {
                    attrs: {
                      antragId: _vm.antragId,
                      antragTyp: _vm.antragTyp,
                      item: item,
                      data: _vm.data,
                      isDataReadOnly: _vm.isDataReadOnly
                    },
                    on: {
                      change: function($event) {
                        return _vm.handleChange($event)
                      },
                      save: _vm.saveAntrag,
                      removePosition: function($event) {
                        return _vm.removePosition($event)
                      },
                      updateIsin: function($event) {
                        return _vm.updateIsin($event)
                      }
                    }
                  })
                ],
                1
              )
            }),
            0
          )
        : !_vm.loading
        ? _c("NoData")
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }