<template>
  <div>
    <div class="flex-container">
      <!-- TABLE -->
      <div class="flex-item-table">
        <Table
          :tableData="tableData"
          :cardViewEnabled="false"
          :filterEnabled="false"
          :exportEnabled="false"
          :paginationEnabled="false"
          :actions="tableActions"
          @execute-action="handleTableAction"
        >
        </Table>
      </div>

      <!-- TARGET TEXT BOX -->
      <div class="flex-item-textarea">
        <InputTextArea :placeholder="placeholder" ref="target" :autoGrow="false"> </InputTextArea>
      </div>
    </div>
  </div>
</template>

<script>
import LOG_TYPES from '@/store/log/types';
import InputTextArea from "@/components/core/forms/InputTextArea.vue";
import { PhArrowCircleRight } from "phosphor-vue";
import Table from "@/components/table/Table.vue";

export default {
  props: {
    placeholder:{},
    value: {
    },
    initialValues: {
      default: undefined
    },
    positions: {},
    config: {},
    headers: {
      type: Object,
      default: () => ({}),
    },
  },
  data: function () {
    return {
      tableDataNew: null,
      kidDisabled: false,
      factsheetDisabled: false,
      downloadPdf: true,
      tableActions: [
        {
          confirmationMessage: null,
          confirmationTitle: null,
          specificAction: null,
          legend: {
            index: "1",
            key: "ADD_LONGTEXT",
            label: "Einfügen",
            icon: PhArrowCircleRight,
          },
        },
      ],
    };
  },
  computed: {
    tableData() {
      return {
        headers: {
          kurztext: {
            label: "Text Kurzform",
            key: "kurztext",
            dataType: "String",
            visible: true,
            sortable: true,
            filterable: false,
            sum: false,
            fixed: true,
          },
          actions: {
            label: "",
            key: "actions",
            priority: 1,
            dataType: "tableAction",
            visible: true,
            sortable: false,
            filterable: false,
            sum: false,
            fixed: true,
          },
        },
        records: this.records,
      };
    },
    records() {
      if (this.initialValues) {
        return this.initialValues;
      } else
        return [
            { kurztext: "ABC", langtext: "Das Alphabet" },
            { kurztext: "DEF", langtext: "Weitergehts" },
            { kurztext: "3", langtext: "Drei" },
            { kurztext: "4", langtext: "Vier" },
          ]
    }
  },
  methods: {
    add(ev) {
      if (this.$refs.target.value == undefined) {
        this.$refs.target.value = "- " + ev.value.langtext + "\n";
      } else {
        this.$refs.target.value += "- " + ev.value.langtext + "\n";
      }
      this.$emit('input', this.$refs.target.value);
    },
    handleTableAction(event) {
      switch (event.action.legend.key) {
        case "ADD_LONGTEXT":
          this.add(event);
          break;
        default:
          this.$store.dispatch(LOG_TYPES.ACTIONS.WARN, "Default: " + event.value + " not found.");
      }
    },
  },
  components: {
    InputTextArea,
    PhArrowCircleRight,
    Table,
  },
};
</script>

<style scoped>
.flex-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: left;
}

.flex-item-table {
  flex: 1 0px;
  margin: 0 20px 20px 0;
}

.flex-item-textarea {
  flex: 1 0px;
  align-self: top;
}

/* Small screens */
@media all and (max-width: 768px) {
  .flex-container {
    flex-direction: column;
  }
  .flex-item-textarea {
    align-self: flex-start;
    min-width: 100%;
  }
}
</style>