<template>
  <div>
    <PageHeaderTitleNavigation 
      :title="title"
      :subtitle="subtitle" 
      :actions="headerActions"
      @action-SAVE="saveDocument()" 
      @action-DISCARD="resetChanges()" 
      @action-PREVIEW="getDocumentPreview()" 
      @action-CONFIG="openPageLayoutModal()" 
    />

    <DynamicPageLayoutModal 
      ref="openPageLayoutModal"
      :document="document" />
  </div>
</template>


<script>
import DYNAMIC_DOCUMENT_TYPES from '@/store/dynamicDocument/types';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { viewDocument } from '@/components/core/download/DownloadLink.vue';
import DynamicPageLayoutModal from '@/components/dynamicDocument/DynamicPageLayoutModal.vue';
import { PageHeaderSimpleAction, PageHeaderLoadingAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {
  mixins: [],
  props: {
    document: {
      type: Object,
      default: {},
    },
    documentHasChanged: {
      type: Boolean,
      default: false,
    },
    isDocumentLoading: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    DynamicPageLayoutModal,
  },
  data() {
    return {
      loading: {
        save: false,
        reset: false,
        preview: false,
      },
    }
  },
  computed: {
    title() {
      return this.document?.formLabel || ''
    },
    subtitle() {
      return this.document?.docGroupLabel || ''
    },
    headerActions() {
      return [
        PageHeaderLoadingAction('SAVE', 'Speichern', () => this.loading?.save).withDisabled(() => this.isSomeButtonLoading || !this.documentHasChanged),
        PageHeaderLoadingAction('DISCARD', 'Änderungen zurücksetzen', () => this.loading?.reset).withDisabled(() => this.isSomeButtonLoading || !this.documentHasChanged),
        PageHeaderLoadingAction('PREVIEW', 'PDF Vorschau', () => this.loading?.preview).withDisabled(() => this.isSomeButtonLoading), // TODO preview button should be download link -> make a new download service
        PageHeaderSimpleAction('CONFIG', 'Seite einrichten').withDisabled(() => this.isSomeButtonLoading),
      ];
    },
    isSomeButtonLoading() {
      return Object.values(this.loading).some(valueLoading => valueLoading) || this.isDocumentLoading
    },
  },
  methods: {
    openPageLayoutModal() {
      this.$refs.openPageLayoutModal.open()
    },
    saveDocument() {
      if (this.documentHasChanged) {
        this.loading.save = true
        this.$store.dispatch(DYNAMIC_DOCUMENT_TYPES.ACTIONS.SAVE_DYNAMIC_DOCUMENT, this.document)
        .finally(() => this.loading.save = false);
      }
    },
    resetChanges() {
      if (this.documentHasChanged) {
        this.$confirmModal({
          title: 'Änderungen zurücksetzen',
          message: 'Wollen Sie Ihre Änderungen wirklich zurücksetzen?',
          labelButtonConfirm: 'Zurücksetzen',
        }).then(() => {
          this.loading.reset = true
          const payload = {
            version: this.document.version,
            id: this.document.id
          }

          this.$store.dispatch(DYNAMIC_DOCUMENT_TYPES.ACTIONS.RETRIEVE_DYNAMIC_DOCUMENT, payload)
          .finally(() => this.loading.reset = false);
        }).catch(() => {})
      }
    },
    getDocumentPreview() {
      this.loading.preview = true
      this.$store.dispatch(DYNAMIC_DOCUMENT_TYPES.ACTIONS.PDF_PREVIEW, this.document).then(response => {
        if (response && response.data) {
          viewDocument({data: response.data.bytes, contentType: 'application/pdf', filename: 'Document'});
        }
      })
      .finally(() => this.loading.preview = false)
    },
  }
}
</script>