<template>
  <div class="input-forms__container">
    <div class="input-forms__label-container" >
      <div v-if="label" class="input-forms__label-content" v-html="sanitize(label)"></div>

      <div class="input-forms__input-container">
        <ComboBox v-for="(field, index) in componentFields" 
          :key="index" 
          :label="field.label" 
          :value="value[field.id]" 
          :values="field.values" 
          :disabled="disabled"
          :isComponentHalfSize="isComponentHalfSize"
          :sortComboboxValues="false"
          @change="onChange(field, $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ComboBox from '@/components/core/forms/ComboBox.vue';
import {sanitize} from '@/helpers/string-helper.js';

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    componentFields: {
      type: Array,
      default: () => [],
    },
    isComponentHalfSize: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    sanitize(htmlString) {
      return sanitize(htmlString);
    },
    onChange(field, value) {
      this.$emit('input', {
        [field.id]: value,
      });
    },
  },
  components: {
    ComboBox,
  },
}
</script>
