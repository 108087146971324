var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("InsuranceOptionMenu", { attrs: { title: "Buchungsdaten" } }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("p", [
            _vm._v(
              "Hier können Sie den Vertrag zu einem anderen Kunden verschieben. Bitte beachten Sie, dass bei dem neuen Kunden automatisch alle dafür benötigten Personen (Vertragspersonen, versicherte Personen) angelegt werden."
            )
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12 col-sm-6" }, [
              _c("p", [
                _vm._v(
                  " Vertrag: " +
                    _vm._s(_vm.vertragsverschiebungenData.beschreibung) +
                    " "
                )
              ])
            ]),
            _c("div", { staticClass: "col-12 col-sm-6" }, [
              _c("p", [
                _vm._v(
                  "Bisheriger Kunde: " +
                    _vm._s(_vm.userid) +
                    " - " +
                    _vm._s(_vm.fullname)
                )
              ])
            ]),
            _c(
              "div",
              { staticClass: "col-12 col-sm-6 form-control" },
              [
                _c("InputField", {
                  staticClass: "align-end",
                  attrs: { label: "Neuer Kunde" },
                  on: { input: _vm.searchCustomer },
                  model: {
                    value: _vm.form.kundennr,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "kundennr", $$v)
                    },
                    expression: "form.kundennr"
                  }
                }),
                _c(
                  "a",
                  {
                    staticClass: "align-end",
                    staticStyle: { "flex-grow": "0" },
                    on: { click: _vm.handleAddUsers }
                  },
                  [_c("PhBookOpen", { attrs: { size: 24 } })],
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "col-12 col-sm-6" }, [
              _c("p", [_vm._v("Neuer Kunde Name:")]),
              _c("p", [_vm._v(_vm._s(_vm.form.kundenname))])
            ])
          ]),
          _c(
            "BaseButton",
            {
              attrs: {
                disabled: !_vm.form.kundennr || _vm.form.kundennr.length < 7
              },
              on: {
                click: function($event) {
                  return _vm.vertragVerschieben()
                }
              }
            },
            [_vm._v("Vertrag verschieben")]
          ),
          _c(
            "BaseButton",
            {
              attrs: { isSecondary: "" },
              on: {
                click: function($event) {
                  return _vm.goBack()
                }
              }
            },
            [_vm._v("Abbrechen")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }