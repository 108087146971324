var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "editModal",
          attrs: {
            modalTitle: "Freigabe ändern",
            autoClose: false,
            showConfirmButton: false
          },
          on: {
            close: function($event) {
              return _vm.close()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "BaseButton",
                    {
                      on: {
                        click: function($event) {
                          return _vm.submit()
                        }
                      }
                    },
                    [
                      _vm._v(" Speichern "),
                      _vm.showLoading ? _c("AnimatedSpinner") : _vm._e()
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "div",
            { staticClass: "mb-2" },
            [
              _vm.vorlage.type === "EMAIL"
                ? _c("span", [_vm._v(" E-Mail Vorlage anschauen: ")])
                : _c("span", [_vm._v(" Brief Vorlage anschauen: ")]),
              _c("DownloadLink", {
                attrs: {
                  title: _vm.vorlage.description,
                  downloadServicePath: _vm.vorlage.isWord
                    ? "/get_word_vorlage"
                    : "/vorlage_preview",
                  filename: _vm.vorlage.isWord
                    ? "Dokument.doc"
                    : "Dokument.pdf",
                  queryParams: {
                    id: _vm.vorlage.id,
                    fileId: _vm.vorlage.id
                  }
                }
              })
            ],
            1
          ),
          _c("ComboBox", {
            attrs: {
              label: "Freigabe",
              values: _vm.grantValues,
              disabled: !_vm.vorlage.editable
            },
            model: {
              value: _vm.grant,
              callback: function($$v) {
                _vm.grant = $$v
              },
              expression: "grant"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }