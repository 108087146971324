var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "box__container-wrap": _vm.lastMarketing } },
    [
      _c("BaseFilter", {
        attrs: {
          filterId: "marketingMSC",
          title: "Marketing Filter",
          metadata: _vm.searchMetadata,
          defaultOptions: _vm.defaultOptions,
          configFilter: _vm.configFilter
        },
        on: {
          onFilter: function($event) {
            return _vm.handleSearch($event)
          }
        }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", title: "Marketing Liste" }
              })
            : _vm.rows.length === 0
            ? _c("NoData", { attrs: { title: "Marketing Liste" } })
            : _c("Table", {
                attrs: {
                  tableId: "13a0d41a-be23-4065-931e-554122d74432",
                  title: "Marketing Liste",
                  headers: _vm.headers,
                  rows: _vm.rows
                },
                on: {
                  "click-title": function($event) {
                    return _vm.handleEdit($event.id)
                  },
                  "action-VIEW": function($event) {
                    return _vm.showMarketing($event)
                  },
                  "action-EDIT": function($event) {
                    return _vm.handleEdit($event.id)
                  },
                  "action-DELETE": function($event) {
                    return _vm.deleteEntry($event.id)
                  },
                  "action-COPY": function($event) {
                    return _vm.copyEntry($event.id)
                  }
                }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }