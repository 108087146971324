var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visibleActions.length
    ? _c(
        "div",
        { staticClass: "d-flex justify-content-end" },
        [
          _c(
            "BaseContextMenu",
            { attrs: { tid: _vm.tid } },
            _vm._l(_vm.visibleActions, function(action) {
              return _c(
                "ContextMenuItem",
                { key: action.actionKey },
                [
                  _c(
                    action.component,
                    _vm._b(
                      {
                        tag: "component",
                        attrs: { row: _vm.row, tid: _vm.tid, expanded: "" },
                        on: { action: _vm.onAction }
                      },
                      "component",
                      action,
                      false
                    )
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }