var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "copyInsuranceConfirmationModal",
          attrs: {
            modalTitle: "Versicherung kopieren Bestätigung",
            showCancelButton: true,
            showCloseButton: true,
            labelButtonConfirm: "Ok"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.versicherungKopieren()
            }
          }
        },
        [_vm._v(" Möchten Sie diese Versicherung wirklich kopieren? ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }