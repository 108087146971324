var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "vertragVereinigenModal",
          attrs: {
            modalTitle: "Versicherungen vereinigen",
            labelButtonConfirm: "Speichern",
            confirmDisabled: _vm.isFormInvalid
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.onConfirmModal()
            },
            onCancelButton: function($event) {
              return _vm.onCancelModal()
            },
            onCloseButton: function($event) {
              return _vm.onCancelModal()
            }
          }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("p", [
                _vm._v("Hier können Sie zwei Versicherungen vereinigen.")
              ])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("hr"),
              _c("p", [
                _c("span", { staticClass: "color-danger font-bold" }, [
                  _vm._v("ACHTUNG!")
                ])
              ]),
              _c("p", [
                _c("span", { staticClass: "color-danger font-bold" }, [
                  _vm._v(
                    'Vereinigte Verträge können im Nachgang nicht mehr "getrennt" werden.'
                  )
                ])
              ]),
              _c("p", [
                _c("span", { staticClass: "color-danger font-bold" }, [
                  _vm._v(
                    "Sind Sie sich sicher, dass Sie die Verträge vereinigen möchten?"
                  )
                ])
              ]),
              _c("hr")
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-6" },
              [
                _c("InputField", {
                  attrs: {
                    label:
                      "Versicherung 1, der die Daten zugeordnet werden sollen",
                    validateUntouched: true
                  },
                  on: {
                    input: function($event) {
                      return _vm.dataChanged("toKeepId", $event)
                    }
                  },
                  model: {
                    value: _vm.versicherungen.toKeep.vertragId,
                    callback: function($$v) {
                      _vm.$set(_vm.versicherungen.toKeep, "vertragId", $$v)
                    },
                    expression: "versicherungen.toKeep.vertragId"
                  }
                }),
                _vm.versicherungen.toKeep.vertragId &&
                _vm.versicherungen.toKeep.vertragId !=
                  _vm.versicherungen.toRemove.vertragId
                  ? _c("div", [
                      _vm.versicherungen.toKeep.bezeichnung
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.versicherungen.toKeep.bezeichnung)
                            )
                          ])
                        : _vm._e(),
                      _c("br"),
                      _vm.versicherungen.toKeep.polNr
                        ? _c("span", [
                            _vm._v(
                              " Pol.Nr.: " +
                                _vm._s(_vm.versicherungen.toKeep.polNr)
                            )
                          ])
                        : _vm._e()
                    ])
                  : _vm._e()
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-6" },
              [
                _c("InputField", {
                  attrs: {
                    label:
                      "Versicherung 2, der die Daten zugeordnet werden sollen",
                    validateUntouched: true
                  },
                  on: {
                    input: function($event) {
                      return _vm.dataChanged("toRemoveId", $event)
                    }
                  },
                  model: {
                    value: _vm.versicherungen.toRemove.vertragId,
                    callback: function($$v) {
                      _vm.$set(_vm.versicherungen.toRemove, "vertragId", $$v)
                    },
                    expression: "versicherungen.toRemove.vertragId"
                  }
                }),
                _vm.versicherungen.toRemove.vertragId &&
                _vm.versicherungen.toRemove.vertragId !=
                  _vm.versicherungen.toKeep.vertragId
                  ? _c("div", [
                      _vm.versicherungen.toRemove.bezeichnung
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.versicherungen.toRemove.bezeichnung)
                            )
                          ])
                        : _vm._e(),
                      _c("br"),
                      _vm.versicherungen.toRemove.polNr
                        ? _c("span", [
                            _vm._v(
                              " Pol.Nr.: " +
                                _vm._s(_vm.versicherungen.toRemove.polNr)
                            )
                          ])
                        : _vm._e()
                    ])
                  : _vm._e()
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }