var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "table", staticClass: "table" },
    _vm._l(_vm.headers, function(ref) {
      var key = ref.key
      var style = ref.style
      var classes = ref.classes
      return _c(
        "div",
        {
          key: key,
          ref: key,
          refInFor: true,
          staticClass: "column",
          style: style
        },
        [
          _c(
            "div",
            { staticClass: "header-cell" },
            [_vm._t("header_" + key, null, { collapsed: false })],
            2
          ),
          _vm._l(_vm.rows, function(ref, index) {
            var row = ref.row
            var id = ref.id
            var customRowStyle = ref.customRowStyle
            return _c(
              "div",
              {
                key: index,
                staticClass: "table-cell",
                class: classes,
                style: customRowStyle
              },
              [
                _vm._t(
                  key,
                  function() {
                    return [_vm._v(" " + _vm._s(row[key]) + " ")]
                  },
                  { row: row, id: id }
                )
              ],
              2
            )
          }),
          _vm._t("bottom__table")
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }