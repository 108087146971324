var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container-wrap" },
    [
      _c(
        "Transactions",
        _vm._b(
          {
            staticClass: "box__container-rows",
            on: {
              loading: function($event) {
                _vm.loading = $event
              }
            }
          },
          "Transactions",
          _vm.$attrs,
          false
        )
      ),
      !_vm.loading && !_vm.$isSmallScreen
        ? _c(
            "div",
            { staticClass: "bottom-row text-right mt-3" },
            [
              _c(
                "router-link",
                { attrs: { to: "/customer/eingegangene-transaktionen" } },
                [_vm._v("weitere...")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }