<template>
  <div>
    <div class="box__container">
      <div class="box__title">Vertragsdaten</div>

      <template v-if="!isFA">
        <InputField label="Bezeichnung der Dienstleistung" 
          v-model="feePropertiesForm.bezeichnungHonoratyp" isComponentHalfSize 
          @change="dataChanged()" />

        <InputRadioBoxGroup title="Rechnungsbeträge" 
          v-model="feePropertiesForm.taxType" :values="feePropertiesCombos.taxType" isComponentHalfSize 
          @input="dataChanged()" />
      </template>

      <InputField label="MwSt. Satz" type="percent" 
        v-model="feePropertiesForm.taxPercentage" isComponentHalfSize 
        validateUntouched 
        @change="dataChanged()" />

      <InputToggleSwitch label="Versand der Abrechnung per E-Mail" inLineLabel
        v-model="feePropertiesForm.sendPerEmail" 
        @input="dataChanged()" />

      <template v-if="isCustomer">
        <DatePickerField label="Vertragsdatum" 
          v-model="feePropertiesForm.contractDate" isValueAsString isComponentHalfSize 
          validateUntouched 
          @change="dataChanged()" />
      </template>

      <template v-if="!isCustomer && (istMargitLang || isFA)">
        <InputField label="nächste Rechnungsnummer" type="number" 
          v-model="feePropertiesForm.nextInvoiceNr" :precision="0" isComponentHalfSize 
          @change="dataChanged()" />
      </template>

      <InputField label="Abrechnungsperioden (in Monaten)" type="number" 
        v-model="feePropertiesForm.period" :precision="0" isComponentHalfSize 
        validateUntouched 
        @change="dataChanged()" />

      <template v-if="isCustomer">
        <DatePickerField v-if="istMargitLang" label="Vertragsdatum 2" 
          v-model="feePropertiesForm.contractDate2" isValueAsString isComponentHalfSize 
          @change="dataChanged()" />

        <DatePickerField label="Beginn Abrechnungsperiode" 
          v-model="feePropertiesForm.beginDate" isValueAsString isComponentHalfSize 
          validateUntouched 
          @change="dataChanged()" />

        <DatePickerField label="Ende Abrechnungsperiode" 
          v-model="endDate" isValueAsString disabled isComponentHalfSize 
          @change="dataChanged()" />
      </template>
    </div>

    <div v-if="isCustomer" class="box__container">
      <FeeAbrechnungenTable title="Erstellte Abrechnungen" />
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';

import InputField from '@/components/core/forms/InputField.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';

import FeeAbrechnungenTable from './../components/FeeAbrechnungenTable.vue';

import validator from '@/mixins/validator';
import { required, } from "@/mixins/validator/rules";

import feePropertiesStepMixin from '../fee-properties-step-mixin';

export default {
  mixins: [feePropertiesStepMixin, validator],
  data() {
    return {
      feePropertiesForm: {
        bezeichnungHonoratyp: '',
        taxType: '',
        taxPercentage: '',
        sendPerEmail: null,
        contractDate: null,
        nextInvoiceNr: null,
        period: null,
        contractDate2: null,
        beginDate: null,
        endDate: null,
      },
    };
  },
  computed: {
    istMargitLang() {
      return !!this.feePropertiesCombos?.istMargitLang;
    },
    endDate() {
      const { beginDate, period, } = this.feePropertiesForm;
      if (beginDate && period >= 0 && period !== '') {
        return dayjs(beginDate, 'DD.MM.YYYY').add(period, 'month').subtract(1, 'day');
      } else {
        return '';
      }
    },
  },
  validators: {
    feePropertiesForm: {
      taxPercentage: [required('Sie müssen einen MwSt. Satz für das Honorar angeben!')],
      period: [required('Sie müssen eine Periode angeben!')],
      beginDate: [required('Sie müssen das Datum der aktuellen Abrechnungsperiode angeben!')],
      contractDate: [required('Sie sollten ein Vertragsdatum angeben!')],
    },
  },
  components: {
    InputField,
    InputRadioBoxGroup,
    InputToggleSwitch,
    DatePickerField,
    FeeAbrechnungenTable,
  },
}
</script>
