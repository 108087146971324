import INFOFILME_TYPES from './types';
import CORE_TYPES from '../core/types';
import axios from 'axios';
import { buildMessage, } from '../../helpers/log-message-helper';
import * as utils from '../../helpers/commonfunctions';

export default {
  [INFOFILME_TYPES.ACTIONS.LOAD_INFOFILME]({ commit, state, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };
    if (payload == null ) {
      commit(INFOFILME_TYPES.MUTATIONS.SET_INFOFILME, {status: 2, rows: [] });
      commit(INFOFILME_TYPES.MUTATIONS.SET_FILTER, {});
      return;
    }
    commit(INFOFILME_TYPES.MUTATIONS.SET_INFOFILME, {status: 1});
    commit(INFOFILME_TYPES.MUTATIONS.SET_FILTER, payload);
    axios.post(rootState.core.apiAddress + '/infofilme/liste', payload, config).then(response => {
      if (response.data?.rows) {
        commit(INFOFILME_TYPES.MUTATIONS.SET_INFOFILME, {rows: response.data.rows, status: 2});
      }
    });
  },

  [INFOFILME_TYPES.ACTIONS.UPDATE_INFOFILME]({ commit, state, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };
    commit(INFOFILME_TYPES.MUTATIONS.SET_INFOFILME, {status: 1});
    axios.post(rootState.core.apiAddress + '/infofilme/update?fileId='+payload.fileId, payload, config).then(response => {
      if (response.data?.row) {
        commit(INFOFILME_TYPES.MUTATIONS.SET_INFOFILME, {row: response.data.row});
      }
    });
  },


}