import { render, staticRenderFns } from "./VpPages.vue?vue&type=template&id=18c20a10&scoped=true&"
import script from "./VpPages.vue?vue&type=script&lang=js&"
export * from "./VpPages.vue?vue&type=script&lang=js&"
import style0 from "./VpPages.vue?vue&type=style&index=0&id=18c20a10&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18c20a10",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("P:\\workspaces\\fk0\\fc\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('18c20a10')) {
      api.createRecord('18c20a10', component.options)
    } else {
      api.reload('18c20a10', component.options)
    }
    module.hot.accept("./VpPages.vue?vue&type=template&id=18c20a10&scoped=true&", function () {
      api.rerender('18c20a10', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/communication/versandProtokolle/VpPages.vue"
export default component.exports