import COURTAGETABELLE_INVESTMENT_TYPES from './types';

export default {
  [COURTAGETABELLE_INVESTMENT_TYPES.GETTERS.FILTER_FIELDS_DEFINITIONS](state) {
    return state.filterFieldsDefinition;
  },
  [COURTAGETABELLE_INVESTMENT_TYPES.GETTERS.COURTAGETABELLE_INVESTMENT_LIST](state) {
    return state.courtagetabelleInvestmentList;
  },
  [COURTAGETABELLE_INVESTMENT_TYPES.GETTERS.COURTAGETABELLE_INVESTMENT_FONDSPLATTFORMEN](state) {
    return state.courtagetabelleInvestmentFondsplattformen;
  },
  
}
