var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("GesellschaftTitleNavigation", {
        attrs: { headerActions: _vm.headerActions },
        on: {
          "action-CREATE_BESUCHSBERICHT": _vm.editOrCreateBesuchsbericht,
          "action-CREATE_BEMERKUNG": _vm.editOrCreateBemerkung
        }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("ComboBox", {
            attrs: {
              label: "Betreuungsstatus",
              sortComboboxValues: false,
              isComponentHalfSize: "",
              values: _vm.betreuungsstatusValues,
              value: _vm.betreuungsstatus
            },
            on: { input: _vm.updateBetreuungsstatus }
          })
        ],
        1
      ),
      _c("BaseFilter", {
        attrs: {
          title: _vm.TABLE_TITLE + " Filter",
          metadata: _vm.metadata,
          configFilter: _vm.configFilter,
          hasSmartSearch: ""
        },
        on: { onFilter: _vm.doFilter }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", title: _vm.TABLE_TITLE }
              })
            : _c("Table", {
                attrs: {
                  title: _vm.TABLE_TITLE,
                  rows: _vm.rows,
                  headers: _vm.headers,
                  rowsPerPage: 20
                },
                on: {
                  "click-subject": _vm.editBemerkungOrBesuchsbericht,
                  "action-DELETE_BEMERKUNG": _vm.deleteBemerkung,
                  "action-DELETE_BERICHT": _vm.deleteBericht,
                  "action-COPY_BESUCHSBERICHT": _vm.copyBericht
                }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }