var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("div", { staticClass: "box__title" }, [
          _vm._v("Dienstleistungsvertrag")
        ]),
        _c("InputField", {
          attrs: {
            label: "Einmaliges Entgelt (inkl. MwSt.)",
            type: "currency",
            isComponentHalfSize: "",
            precision: 2
          },
          on: {
            change: function($event) {
              return _vm.dataChanged()
            }
          },
          model: {
            value: _vm.feePropertiesForm.einmaligesEntgelt,
            callback: function($$v) {
              _vm.$set(_vm.feePropertiesForm, "einmaligesEntgelt", $$v)
            },
            expression: "feePropertiesForm.einmaligesEntgelt"
          }
        }),
        _c(
          "div",
          [
            _c("div", { staticClass: "box__title" }, [
              _vm._v("Jährliches Entgelt (inkl. MwSt.)")
            ]),
            _c("InputField", {
              attrs: {
                label: "Fixes Entgelt",
                type: "currency",
                isComponentHalfSize: "",
                precision: 2
              },
              on: {
                change: function($event) {
                  return _vm.dataChanged()
                }
              },
              model: {
                value: _vm.feePropertiesForm.fixesEntgelt,
                callback: function($$v) {
                  _vm.$set(_vm.feePropertiesForm, "fixesEntgelt", $$v)
                },
                expression: "feePropertiesForm.fixesEntgelt"
              }
            }),
            _c("InputField", {
              attrs: {
                label: "jährliche Erhöhung des fixen Entgelt",
                type: "percent",
                isComponentHalfSize: "",
                validateUntouched: "",
                precision: 2
              },
              on: {
                change: function($event) {
                  return _vm.dataChanged()
                }
              },
              model: {
                value: _vm.feePropertiesForm.fixesEntgeltSteigerung,
                callback: function($$v) {
                  _vm.$set(_vm.feePropertiesForm, "fixesEntgeltSteigerung", $$v)
                },
                expression: "feePropertiesForm.fixesEntgeltSteigerung"
              }
            }),
            _c("InputField", {
              attrs: {
                label:
                  "jährliches Entgelt (gem. durchschnittl. Depotwert, inkl. MwSt.)",
                type: "percent",
                isComponentHalfSize: "",
                validateUntouched: "",
                precision: 2
              },
              on: {
                change: function($event) {
                  return _vm.dataChanged()
                }
              },
              model: {
                value: _vm.feePropertiesForm.variablesEntgelt,
                callback: function($$v) {
                  _vm.$set(_vm.feePropertiesForm, "variablesEntgelt", $$v)
                },
                expression: "feePropertiesForm.variablesEntgelt"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          [
            _c("div", { staticClass: "box__title" }, [
              _vm._v("zu berücksichtigende Depots und Konten")
            ]),
            _c("InputToggleSwitch", {
              attrs: { label: "inkl. Fremdbestand", inLineLabel: "" },
              on: {
                input: function($event) {
                  return _vm.dataChanged()
                }
              },
              model: {
                value: _vm.feePropertiesForm.inklFremdbestand,
                callback: function($$v) {
                  _vm.$set(_vm.feePropertiesForm, "inklFremdbestand", $$v)
                },
                expression: "feePropertiesForm.inklFremdbestand"
              }
            }),
            _c("InputToggleSwitch", {
              attrs: { label: "inkl. Geldverrechnungskonten", inLineLabel: "" },
              on: {
                input: function($event) {
                  return _vm.dataChanged()
                }
              },
              model: {
                value: _vm.feePropertiesForm.inklGeldverrechnungskonten,
                callback: function($$v) {
                  _vm.$set(
                    _vm.feePropertiesForm,
                    "inklGeldverrechnungskonten",
                    $$v
                  )
                },
                expression: "feePropertiesForm.inklGeldverrechnungskonten"
              }
            }),
            _c("InputToggleSwitch", {
              attrs: { label: "inkl. CapitalbankkontoPlus", inLineLabel: "" },
              on: {
                input: function($event) {
                  return _vm.dataChanged()
                }
              },
              model: {
                value: _vm.feePropertiesForm.inklCapitalbankkontoPlus,
                callback: function($$v) {
                  _vm.$set(
                    _vm.feePropertiesForm,
                    "inklCapitalbankkontoPlus",
                    $$v
                  )
                },
                expression: "feePropertiesForm.inklCapitalbankkontoPlus"
              }
            })
          ],
          1
        ),
        _c("InputField", {
          attrs: { label: "exkl. Depotnummer", isComponentHalfSize: "" },
          on: {
            change: function($event) {
              return _vm.dataChanged()
            }
          },
          model: {
            value: _vm.feePropertiesForm.depotnummer1,
            callback: function($$v) {
              _vm.$set(_vm.feePropertiesForm, "depotnummer1", $$v)
            },
            expression: "feePropertiesForm.depotnummer1"
          }
        }),
        _c("InputField", {
          attrs: {
            label: "exkl. Geldverrechnungskonto",
            isComponentHalfSize: ""
          },
          on: {
            change: function($event) {
              return _vm.dataChanged()
            }
          },
          model: {
            value: _vm.feePropertiesForm.depotnummer2,
            callback: function($$v) {
              _vm.$set(_vm.feePropertiesForm, "depotnummer2", $$v)
            },
            expression: "feePropertiesForm.depotnummer2"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }