var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.tabList.length > 1
    ? _c("Tabs", {
        staticClass: "mt-1",
        attrs: {
          tabList: _vm.tabList,
          selected: _vm.selected,
          disabled: _vm.disabled
        },
        on: {
          tabSelected: function($event) {
            return _vm.$emit("tabSelected", $event)
          }
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }