<template>
<BaseModal 
    ref="modal"
    :showDefaultButtons="false"
    @close="close"
>
    <template v-slot:modalTitle>
        {{title}}
    </template>
    <div>
        <br>
        <Table v-if="details && details.journal"
            :headers="journalHeaders"
            :rows="journalRows"
            rowId="index"
            hidePagination
        />
        <br><br>
        <Table v-if="details && details.values"
            :headers="valuesHeaders"
            :rows="details.values"
            rowId="label"
            hidePagination
        />
    </div>
</BaseModal>
</template>

<script>
import BaseModal from '@/components/core/BaseModal.vue';

import Table from "@/components/table2/Table.vue";
import {TextColumn, DateTimeColumn} from "@/components/table2/table_util.js";

export default {
    components: {
        BaseModal,
        Table,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        details: {
            type: Object,
            default: () => null,
        }
    },
    data() {
        return {
            journalHeaders: {
                lockedLeft: [
                    DateTimeColumn("label", "Zeit"),
                    TextColumn("value", "Journal-Text"),
                ],
            },
            valuesHeaders: {
                lockedLeft: [
                    TextColumn("label", "Beschreibung"),
                    TextColumn("value", "Inhalt"),
                ],
            },
        }
    },
    computed: {
        journalRows() {
            return (this.details?.journal || []).map((row, index) => ({
                index,
                ...row,
            }));
        }
    },
    mounted() {
        this.$refs.modal.open();
    },
    methods: {
        close() {
            this.$emit("close");
        },
    },
}
</script>
