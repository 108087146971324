<template>
  <div>
    <BaseModal
        ref="editDocumentModal"
        modalTitle="Datei bearbeiten"
        @onConfirmButton="confirmButton"
    >
      <UploadInputList  v-if="additionalInputs && additionalInputs.length" v-model="formData" :inputs="additionalInputs"/>
    </BaseModal> 
  </div>
</template>
<script>
import UploadInputList from '@/components/fileUpload/UploadInputList.vue';
import BaseModal from "@/components/core/BaseModal.vue";
import { toDateString } from '@/filters.js';
export default {
  mixins: [],
  components: {
    UploadInputList,
    BaseModal,
  },
  props: {
    additionalInputs: {
      required: true,
      type: Array,
    },
  },
  data () {
    return {
      formData: {},
    }
  },
  watch: {
    additionalInputs() {
      this.formData = this.getFormData();
    }
  },
  methods: {
    getFormData() {
      const formData = {}
      this.additionalInputs.forEach(input => {
        if (input.type == 'DATE') {
          formData[input.key] = toDateString(input.value)
        }
        else 
        if (input.type != 'LABEL')
          formData[input.key] = input.value
      })
      return formData;
    },
    open() {
      this.$refs.editDocumentModal.open();
    },
    confirmButton() {
      this.$emit('confirm', this.formData)
    },
  },
}
</script>
