<script>
const vNodeCache = {};

function removeCacheItem(key) {
  if (vNodeCache[key]) {
    vNodeCache[key].componentInstance.$destroy();
    vNodeCache[key] = undefined;
  }

  delete vNodeCache[key];
}

function removeAllCacheItems() {
  Object.keys(vNodeCache).forEach(key => removeCacheItem(key));
}

window.removeEventListener('navigate:beforeResetBreadcrumb', removeAllCacheItems);
window.addEventListener('navigate:beforeResetBreadcrumb', removeAllCacheItems);

/**
 * KeepAliveOnBackNavigation
 * * it keeps a component live when navigating back to a page using the 'backAction' flag
 * 
 * * Usage:
 *   ```
 *     <KeepAliveOnBackNavigation>
 *       <!-- component to be kept alive -->
 *     </KeepAliveOnBackNavigation>
 *   ```
 * 
 * * Note: It must be a Vue.JS valid component
 * 
 * * [Built-In `<keep-alive>` doc](https://v2.vuejs.org/v2/api/#keep-alive)
 *   - KeepAliveOnBackNavigation does not have the 'include' and 'exclude' props
 *   - However, the `activated` and `deactivated` are called as the Build-In `<keep-alive>`
 */
export default {
  name: 'KeepAliveOnBackNavigation',
  data() {
    return {
      componentKey: null,
      vNodeToCache: null,
    };
  },
  methods: {
    cacheVNode() {
      if (this.vNodeToCache) {
        vNodeCache[this.componentKey] = this.vNodeToCache;
        this.vNodeToCache = null;
      }
    },
  },
  mounted() {
    this.cacheVNode();
  },
  updated() {
    this.cacheVNode();
  },
  render() {
    const slot = this.$slots.default;
    if (!slot) {
      return slot;
    }

    const vnode = slot[0];

    const componentOptions = vnode && vnode.componentOptions;
    if (componentOptions) {
      const key = vnode.key == null
            ? // same constructor may get registered as different local components
              // so cid alone is not enough (#3269)
              componentOptions.Ctor.cid +
              (componentOptions.tag ? `::${componentOptions.tag}` : '')
            : vnode.key;

      if (!key) {
        return vnode;
      }

      this.componentKey = key;

      const isBackAction = !!(this.$route?.query?.backAction);
      if (isBackAction && vNodeCache[key]) { // recoveries only when it is a back action/navigation
        vnode.componentInstance = vNodeCache[key].componentInstance;
      } else {
        this.vNodeToCache = vnode;
      }

      if (vnode.data) {
        vnode.data.keepAlive = true;
      }
    }

    return vnode;
  }
}
</script>
