var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "node-view-wrapper",
    { staticClass: "node-view-wrapper", attrs: { as: "div" } },
    [
      _c("div", { staticClass: "image-container" }, [
        _c(
          "img",
          _vm._b(
            {
              ref: "resizableImg",
              attrs: {
                draggable: _vm.isDraggable,
                "data-drag-handle": _vm.isDraggable
              }
            },
            "img",
            _vm.node.attrs,
            false
          )
        ),
        _c("span", {
          staticClass: "resize-icon image-size-controls",
          on: { mousedown: _vm.onMouseDown }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }