<template>
  <div class="d-none">
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CORE_TYPES from '@/store/core/types'

export default {
  data() {
    return {
      backTo: null
    }
  },
  computed: {
    ...mapGetters({
      username: CORE_TYPES.GETTERS.GET_USER_FULL_NAME,
    }),
    link() {
      return `https://www.fundsware.de/grundlagenwissen/download?partner_id=e0a43498539d9e04d0efd414c4f3a49f&type=gwmd&filename=grundlagenwissen.pdf&name=${this.username}`
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.backTo = from;
    })
  },
  mounted() {
    window.open(this.link)
    this.$router.push(this.backTo)
  }
}
</script>

<style scoped>

</style>