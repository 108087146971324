var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.findLabelComp(_vm.editableComponent)
      ? _c(
          "div",
          { staticClass: "defaultcard-container defaultcard" },
          [
            _c("span", { staticClass: "box__title" }, [
              _vm._v("Beschreibung der Checkbox")
            ]),
            _c("HtmlEditor", {
              attrs: {
                doNotAppendHtmlBody: "",
                isFontParams: "",
                textPlaceholderOptions: _vm.placeholdersOptions
              },
              on: {
                input: function($event) {
                  _vm.getUpdatedHtml(
                    $event,
                    _vm.findLabelComp(_vm.editableComponent)
                  )
                }
              },
              model: {
                value: _vm.html,
                callback: function($$v) {
                  _vm.html = $$v
                },
                expression: "html"
              }
            })
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "defaultcard-container" },
      [
        _c("span", { staticClass: "box__title" }, [
          _vm._v("Standard Wert der Checkbox")
        ]),
        _c("InputToggleSwitch", {
          attrs: { inLineLabel: "" },
          model: {
            value: _vm.internalValue,
            callback: function($$v) {
              _vm.internalValue = $$v
            },
            expression: "internalValue"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }