import WERTPAPIERORDER_TYPES from './types';

export default {
  [WERTPAPIERORDER_TYPES.GETTERS.LAGERSTELLEN](state) {
    return state.lagerstellen;
  },
  [WERTPAPIERORDER_TYPES.GETTERS.LAGERSTELLEN_CONFIG](state) {
    return state.lagerstellenConfig;
  },
  [WERTPAPIERORDER_TYPES.GETTERS.SELECTED_LAGERSTELLE](state) {
    return state.data?.lagerstelle;
  },
  [WERTPAPIERORDER_TYPES.GETTERS.DEPOTS](state) {
    return state.depots;
  },
  [WERTPAPIERORDER_TYPES.GETTERS.ORDERBOOKS](state) {
    return state.orderbooks;
  },
  [WERTPAPIERORDER_TYPES.GETTERS.VENUES](state) {
    return state.venues;
  },
  [WERTPAPIERORDER_TYPES.GETTERS.DEPOT_NUMBERS](state) {
    return state.depotNumbers;
  },
  [WERTPAPIERORDER_TYPES.GETTERS.COMBOBOX_VALUES](state) {
    return state.comboboxValues;
  },
  [WERTPAPIERORDER_TYPES.GETTERS.POSITIONS](state) {
    return state.positions;
  },
  [WERTPAPIERORDER_TYPES.GETTERS.DATA](state) {
    return state.data;
  },
  [WERTPAPIERORDER_TYPES.GETTERS.WARNINGS](state) {
    return state.warnings;
  },
  
  [WERTPAPIERORDER_TYPES.GETTERS.POSITION_INFO](state) {
    return state.positionInfo;
  },
  [WERTPAPIERORDER_TYPES.GETTERS.CAPITALBANK_COMPONENTS](state) {
    return state.capitalbankComponents;
  },
  [WERTPAPIERORDER_TYPES.GETTERS.CAPITALBANK_KYC_COMPONENTS](state) {
    return state.capitalbankKYCComponents;
  },
  [WERTPAPIERORDER_TYPES.GETTERS.HELLOBANK_COMPONENTS](state) {
    return state.hellobankComponents;
  },
  [WERTPAPIERORDER_TYPES.GETTERS.SEMPERCONSTANTIA_COMPONENTS](state) {
    return state.semperConstantiaComponents;
  },
  [WERTPAPIERORDER_TYPES.GETTERS.HELLOBANK_COMPONENTS_SELBST](state) {
    return state.hellobankSelbstauskunftComponents;
  },
  // [WERTPAPIERORDER_TYPES.GETTERS.ERROR_MESSAGE](state) {
  //   return state.errorMessage;
  // },
  [WERTPAPIERORDER_TYPES.GETTERS.POSITION_DATA](state) {
    return state.positionData;
  },
  [WERTPAPIERORDER_TYPES.GETTERS.POSITION_DATA_COMPONENTS](state) {
    return state.positionDataComponents;
  },
  [WERTPAPIERORDER_TYPES.GETTERS.BERATUNGS_MAPPE_DATA](state) {
    return state.beratungsMappeData;
  },
  [WERTPAPIERORDER_TYPES.GETTERS.HIGHEST_STEP_VISITED](state) {
    return state.highestStepVisited;
  },
  [WERTPAPIERORDER_TYPES.GETTERS.IS_WERTPAPIERORDER_LOADING](state) {
    return state.isWertpapierorderLoading === true;
  },
  [WERTPAPIERORDER_TYPES.GETTERS.GET_MIT_GELDWAESCHEPRUEFUNG](state) {
    return state.mitGeldwaesche;
  },
  [WERTPAPIERORDER_TYPES.GETTERS.ANTRAG](state) {
    return state.antrag;
  },
}