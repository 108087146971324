var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "suitabilityConfirmationModal",
    attrs: {
      size: "md",
      showCancelButton: false,
      labelButtonConfirm: "Überprüfung abschließen",
      confirmDisabled: ""
    },
    on: {
      onConfirmButton: function($event) {
        return _vm.complete()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "modalTitle",
        fn: function() {
          return [
            _c(
              "div",
              { staticClass: "option-menu-edit-modal--title-container" },
              [
                _c("div", { staticClass: "option-menu-edit-modal--title" }, [
                  _vm._v("Überprüfung abschließen")
                ])
              ]
            )
          ]
        },
        proxy: true
      },
      {
        key: "default",
        fn: function() {
          return [
            _c(
              "div",
              [
                _c("InputRadioBoxGroup", {
                  attrs: {
                    title: "Überprüfung und Annahme durch WealthKonzept",
                    validateUntouched: true,
                    values: _vm.pruefungsstatusValues,
                    labelClass: "font-bold"
                  },
                  model: {
                    value: _vm.pruefungsErgebnis,
                    callback: function($$v) {
                      _vm.pruefungsErgebnis = $$v
                    },
                    expression: "pruefungsErgebnis"
                  }
                }),
                _vm.pruefungsErgebnis === "ABGELEHNT"
                  ? _c("InputTextArea", {
                      attrs: {
                        label: "Begründung des negativen Testergebnisses",
                        autoGrow: ""
                      },
                      model: {
                        value: _vm.bemerkungText,
                        callback: function($$v) {
                          _vm.bemerkungText = $$v
                        },
                        expression: "bemerkungText"
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }