<template>
  <div class="layout__negative-margin--8 antrag-action-button__container">
    <BaseButton @click="getFileData()" :disabled="loading">
      {{label}}
      <AnimatedSpinner v-if="loading" />
    </BaseButton>
  </div>
</template>

<script>

import BaseButton from "@/components/core/BaseButton.vue";
import AnimatedSpinner from "@/components/core/AnimatedSpinner.vue";
import {openFileInNewTab, getRedirectionWindow} from "@/components/table/export/utils";
import axios from "axios";

export default {
  props: {
    label: {
      type: String,
    },
    config: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      apiAddress: process.env.VUE_APP_API,
      loading: false,
    };
  },
  components: {
    BaseButton,
    AnimatedSpinner,
  },
  methods: {
    getFileData() {
      this.loading = true;
      axios.get(`${this.apiAddress}/downloadbutton/download?file=${this.config.file}`)
      .then((response) => {
        this.openFile(this.base64ToArrayBuffer(response.data.bytes), response.data.fileName);
      })
      .finally(() => {
        this.loading = false;
      });
    },
    base64ToArrayBuffer(base64) {
      var binary_string = window.atob(base64);
      var len = binary_string.length;
      var bytes = new Uint8Array(len);
      for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
      }
      return bytes.buffer;
    },
    openFile(byteArr, fileName) {
      const out = new Blob([byteArr], { type: "application/pdf" });
      const windowRef = getRedirectionWindow();
      openFileInNewTab(out, windowRef, this.modalViewerService, fileName);
    },
  },
};
</script>

<style scoped>
</style>
