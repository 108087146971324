import E_MAIL_ACCESS_TYPES from './types';
import Vue from 'vue';
import { initialState } from '@/components/persoenliche-daten/einkommen-sozialversicherungen/einkommen-sozialversicherungen-utils';

export default {
  [E_MAIL_ACCESS_TYPES.MUTATIONS.GET_E_MAIL_SERVER](state, payload) {
    Vue.set(state, 'emailServer', payload);
  },

  [E_MAIL_ACCESS_TYPES.MUTATIONS.UPDATE_MS_GRAPH_EMAIL_DATA](state, payload = {}) {
    Vue.set(state, 'ms_graph_config', Object.assign({}, state.ms_graph_config, payload));
  },

  async [E_MAIL_ACCESS_TYPES.MUTATIONS.GET_MS_GRAPH_EMAIL_DATA](state, payload) {
    await Vue.set(state, 'ms_graph_config', payload);
  },

  [E_MAIL_ACCESS_TYPES.MUTATIONS.GET_IGNORED_ADDRESSES_SUCCESS](state, payload) {
    Vue.set(state, 'ignoredAddresses', payload);
  },

  [E_MAIL_ACCESS_TYPES.MUTATIONS.GET_FOLDER_STRUCTURE_SUCCESS](state, payload) {
    Vue.set(state, 'folderstructure', payload);
  },

  [E_MAIL_ACCESS_TYPES.MUTATIONS.RESET_MS_GRAPH](state, payload) {
    Vue.set(state, 'ms_graph_config', {});
  },
}
