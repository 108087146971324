<template>
  <div class="input-forms__container" 
    :class="{'input-forms__container--embedded': isEmbedded}">
    <label class="input-forms__label-container" @mousedown="onMouseDown">
      <div class="input-forms__label-content" v-if="label">{{label}}</div>
      <div class="input-forms__input-container"
        :class="{'slider-input-disabled': disabled, 'forms__input--half-size': this.isComponentHalfSize,}">
        <div class="slider-input-rail" ref="rail" @mousedown="onMouseDown"></div>
        <div class="slider-track" :style="{ width: `${handlerPosition}%`}" @mousedown="onMouseDown"></div>

        <div class="slider-input-stop"
          :style="{'left': stop.stepStyle}"
          :class="'slider-input-stop-' + stopFormat"
          v-for="(stop, index) in stops" :key="stop.value"
          @mousedown="onMouseDown">
          <span v-if="showStopLabel" class="slider-input-stop-label"
            :class="{
              'slider-input-stop-label--left': index === 0,
              'slider-input-stop-label--center': index > 0 && index < values.length - 1,
              'slider-input-stop-label--right': index === values.length - 1
            }">
            {{ stop.label }}
          </span>
        </div>

        <div class="slider-input-handler" 
          tabindex="0" 
          :style="{ 'left': leftHandler }"
          @mousedown="onMouseDown"
          @keyup.right="moveRight"
          @keyup.left="moveLeft">
          <span class="slider-label" v-if="isShowTooltip">{{ selectedStop.label || selectedStop.value }}</span>
        </div>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    label: {
    },
    value: {
    },
    values: {
      type: [Array],
      default: () => [],
    },
    showTooltip: {
      type: String,
      default: 'never'
    },
    stopFormat: {
      type: String,
      default: 'circle'
    },
    isEmbedded: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showStopLabel: {
      type: Boolean,
      default: true,
    },
    isComponentHalfSize: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    value(newValue) {
      this.updateIndexPositionFromValue(newValue)
    }
  },
  mounted() {
    this.updateIndexPositionFromValue(this.value)
  },
  data() {
    return {
      handlerPosition: 0,
      indexPosition: 0,
      isDragging: false,
      stopSize: {
        circle: '3',
        line: '1',
        none: '1'
      }
    }
  },
  computed: {
    leftHandler() {
      return `calc(${this.handlerPosition}% - 7px)`
    },
    isShowTooltip() {
      if (!this.selectedStop || this.selectedStop.label === undefined) {
        return false;
      }

      switch (this.showTooltip) {
        case 'always':
          return true;
        case 'dragging':
          return this.isDragging;
        case 'never':
        default:
          return false;
      }
    },
    stops() {
      if (Array.isArray(this.values)) {
        return this.values.map(v => ({
          label: this.getLabelToStopArray(v),
          value: this.getValueToStopArray(v), 
          stepStyle: `calc(${this.getStepToStopArray(v, this.values)}% - ${this.stopSize[this.stopFormat]}px)`,
          step: this.getStepToStopArray(v, this.values),
        }))
      }
    },
    selectedStop() {
      if (Array.isArray(this.stops)) {
        return this.stops[this.indexPosition]
      }
      return {
        label: '',
        value: '', 
        stepStyle: `calc(0% - ${this.stopSize[this.stopFormat]}px)`,
        step: 0,
      }
    }
  },
  methods: {
    triggerCurrentValue() {
      if (this.selectedStop) {
        this.$emit('input', this.selectedStop.value)
      }
    },
    updateIndexPositionFromValue(value) {
      if (Array.isArray(this.stops)) {
        const index = this.stops.findIndex(s => s.value === value)
        this.indexPosition = index >= 0 ? index : 0
        this.updateHandlerPositionAccordingToIndex()
      }
    },
    getLabelToStopArray(element) {
      if (element && element.label) {
        return element.label;
      } else if (element && element.name !== undefined) {
        return element.name;
      } else if (element) {
        return element.toString();
      }
      return element;
    },
    getValueToStopArray(element) {
      let value = element;
      if (element && element.value) {
        value = element.value;
      } else if (element) {
        value = element.toString();
      }
      return value;
    },
    getStepToStopArray(element, values) {
      return (values.indexOf(element) / (values.length - 1)) * 100;
    },
    getXValueFromEvent(event) {
      return event.x || event.touches?.[0]?.clientX || 0;
    },
    updateHandlerPositionAccordingToIndex() {
      this.handlerPosition = this.stops[this.indexPosition].step
    },
    moveLeft($event) {
      this.indexPosition = this.indexPosition >= 1 ? this.indexPosition - 1 : 0;
      this.updateHandlerPositionAccordingToIndex()
      this.triggerCurrentValue()
    },
    moveRight($event) {
      this.indexPosition = this.indexPosition < this.values.length - 1 ? this.indexPosition + 1 : this.values.length - 1;
      this.updateHandlerPositionAccordingToIndex()
      this.triggerCurrentValue()
    },
    onMouseDown($event) {
      if (this.disabled) {
        return;
      }
      const rect = this.$refs.rail.getBoundingClientRect()
      const xRail = rect.x;
      const widthRail = rect.width;
      this.changeHandlerPositionFromEvent($event, xRail, widthRail)

      const mouseMove = ($event) => {
        this.changeHandlerPositionFromEvent($event, xRail, widthRail)
      }

      const mouseUp = ($event) => {
        document.removeEventListener('mousemove', mouseMove);
        document.removeEventListener('mouseup', mouseUp);
        
        this.updateIndexPositionToTheClosest()
        this.updateHandlerPositionAccordingToIndex()
        this.triggerCurrentValue()
      }

      document.addEventListener('mousemove', mouseMove);
      document.addEventListener('mouseup', mouseUp);
    },
    changeHandlerPositionFromEvent($event, xRail, widthRail) {
      const eventX = this.getXValueFromEvent(event);

      if (eventX < xRail) {
        this.handlerPosition = 0;
      } else if (eventX - xRail > widthRail) {
        this.handlerPosition = 100;
      } else {
        this.handlerPosition = ((eventX - xRail) / widthRail) * 100;
      }
    },
    updateIndexPositionToTheClosest() {
      const closestIndex = Math.round((this.handlerPosition * (this.values.length - 1)) / 100)
      this.indexPosition = closestIndex
    }
  }
}
</script>

<style scoped>
.input-forms__input-container {
  position: relative;
  display: block;
  user-select: none !important;
  white-space: nowrap;
  box-sizing: border-box;
  margin: 0rem 1rem 1.5rem;
  height: 1rem;
  /* margin: 2.5rem 2.7rem 1.5rem; */
  /* height: 2rem; */
  cursor: pointer;
  min-width: 150px;
}

.slider-input-disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.slider-input-stop {
  position: absolute;
  height: 6px;
  background: var(--color-background);
  margin-top: 5px;
  margin-bottom: 5px;
}

.slider-input-stop-circle {
  width: 6px;
  border: solid 1px var(--color-primary);
  border-radius: 50%;
}

.slider-input-stop-line {
  width: 1px;
  border: solid 1px var(--color-primary);
}

.slider-input-stop-none {
  width: 1px;
  visibility: hidden;
}

.slider-label {
  position: absolute;
  background: var(--color-background);
  color: var(--info-text);
  border-radius: 2px;
  bottom: 22px;
  font-size: 0.75rem;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  padding: 0.3rem 0.5rem;
}

.slider-label::before {
  content: '';
  display: inline-block;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  position: absolute;
  bottom: -4px;
  border-bottom: 0;
  border-top: 4px solid var(--color-background);
  left: calc(50% - 4px);
}

.slider-input-handler {
  position: absolute;
  width: 14px;
  height: 14px;
  border: solid 2px var(--color-primary);
  background: var(--color-background);
  border-radius: 50%;
}

.slider-input-handler:hover,
.slider-input-handler:focus,
.slider-input-rail:hover ~ .slider-input-handler,
.slider-track:hover ~ .slider-input-handler,
.slider-input-stop:hover ~ .slider-input-handler {
  box-shadow: 0px 0px 0px 3px var(--color-primary);
  outline: none;
}

.slider-input-stop:hover {
  box-shadow: 0px 0px 0px 1px var(--color-primary);
  outline: none;
}

.slider-input-disabled .slider-input-handler,
.slider-input-disabled .slider-input-stop {
  box-shadow: none !important;
}

.slider-track {
  position: absolute;
  height: 4px;
  margin-top: 7px;
  margin-bottom: 7px;
  background: var(--color-primary);
}

.slider-input-rail {
  position: absolute;
  width: 100%;
  height: 4px;
  background: rgba(0, 0, 0, 0.15);
  margin-top: 7px;
  margin-bottom: 7px;
}

.slider-input-stop-label--center{
  left: 50%;
  transform: translateX(-50%);
  display: none;
}

.slider-input-stop-label--right{
  right: 0;
}

.slider-input-stop-label--left{
  left: 0;
}

.slider-input-stop-label {
  position: absolute;
  top: 15px;
  font-size: 0.75rem;
  visibility: visible;
}

@media (min-width: 576px) {
  .slider-input-stop-label--center {
    display: inherit;
  }
}
</style>