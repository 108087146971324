var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "mailContacts",
          attrs: {
            modalTitle: "Kontakte",
            size: "lg",
            showCancelButton: false,
            labelButtonConfirm: "Auswahl übernehmen"
          },
          on: { onConfirmButton: _vm.confirmContactsToSend }
        },
        [
          _c("InsuranceCompanyMailContacts", {
            attrs: { records: _vm.records },
            on: { selectedRows: _vm.selectedRows }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }