<template>
  <div class="broker-permissions__container">
    <div class="box__container">
      <div class="box__title">Genereller Zugriff</div>

      <template v-if="loading">
        <div class="broker-permissions--field__container forms__input--half-size">
          <GhostLoading type="input" />
        </div>
        <div class="broker-permissions--field__container forms__input--half-size">
          <GhostLoading type="input" />
        </div>
        <div class="broker-permissions--field__container forms__input--half-size">
          <GhostLoading type="input" />
        </div>
      </template>
      <template v-else>
        <div class="broker-permissions--field__container">
          <ComboBox 
            class="p-0" 
            label="Investmentfonds" 
            v-model="formData.zugriffInvestmentFondsParam" 
            :values="comboData.permissionValues" 
            sortComboboxValues 
            isComponentHalfSize 
            @change="setDataChangedCombo('zugriffInvestmentFondsParam', $event)" />

          <div class="text-small">aktuell gültig: <Pill :label="permissions.zugriffInvestmentFondsText" type="primary" /></div>
        </div>

        <div class="broker-permissions--field__container">
          <ComboBox 
            class="p-0"
            label="Beteiligungen" 
            v-model="formData.zugriffGeschlosseneFondsParam" 
            :values="comboData.permissionValues" 
            sortComboboxValues 
            isComponentHalfSize 
            @change="setDataChangedCombo('zugriffGeschlosseneFondsParam', $event)" />

          <div class="text-small">aktuell gültig: <Pill :label="permissions.zugriffGeschlosseneFondsText" type="primary" /></div>
        </div>

        <div class="broker-permissions--field__container">
          <ComboBox 
            class="p-0"
            label="Versicherungen" 
            v-model="formData.zugriffVersicherungenParam" 
            :values="comboData.permissionValues" 
            sortComboboxValues 
            isComponentHalfSize 
            @change="setDataChangedCombo('zugriffVersicherungenParam', $event)" />

          <div class="text-small">aktuell gültig: <Pill :label="permissions.zugriffVersicherungenText" type="primary" /></div>
        </div>

        <div class="broker-permissions--field__container">
          <ComboBox 
            class="p-0"
            label="Darlehen / Kredite" 
            v-model="formData.zugriffKreditedarelehenParam" 
            :values="comboData.permissionValues" 
            sortComboboxValues 
            isComponentHalfSize 
            @change="setDataChangedCombo('zugriffKreditedarelehenParam', $event)" />

          <div class="text-small">aktuell gültig: <Pill :label="permissions.zugriffKreditedarelehenText" type="primary" /></div>
        </div>

        <div class="broker-permissions--field__container">
          <ComboBox 
            class="p-0"
            :label="isFA ? 'Löschen gesp. Dokumente' : 'Löschen Dokumentenarchiv'" 
            v-model="formData.loeschenGespeicherteDokumenteParam" 
            :values="comboData.permissionValues" 
            sortComboboxValues 
            isComponentHalfSize 
            @change="setDataChangedCombo('loeschenGespeicherteDokumenteParam', $event)" />

          <div class="text-small">aktuell gültig: <Pill :label="permissions.loeschenGespeicherteDokumenteText" type="primary" /></div>
        </div>

        <div class="broker-permissions--field__container">
          <ComboBox 
            class="p-0"
            label="Passwort änderbar" 
            v-model="formData.passwortAenderbarParam" 
            :values="comboData.permissionValues" 
            sortComboboxValues 
            isComponentHalfSize 
            @change="setDataChangedCombo('passwortAenderbarParam', $event)" />

          <div class="text-small">aktuell gültig: <Pill :label="permissions.passwortAenderbarText" type="primary" /></div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, } from 'vuex';
import BROKER_PERMISSIONS_TYPES from '@/store/brokerPermissions/types';
import CORE_TYPES from '@/store/core/types';

import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import Pill from '@/components/core/Pill.vue';

export default {
  data() {
    return {
      loading: false,
      formData: {},
    };
  },
  computed: {
    ...mapGetters({
      comboData: BROKER_PERMISSIONS_TYPES.GETTERS.COMBO_DATA,
      permissions: BROKER_PERMISSIONS_TYPES.GETTERS.PERMISSIONS,
      permissionsEdited: BROKER_PERMISSIONS_TYPES.GETTERS.PERMISSIONS_EDITED,
      isFA: CORE_TYPES.GETTERS.IS_FA,
    }),
  },
  watch: {
    permissions() {
      this.setFormData();
    },
    permissionsEdited() {
      this.setFormData();
    },
  },
  methods: {
    async init() {
      try {
        this.loading = true;
        await Promise.all([
          this.$store.dispatch(BROKER_PERMISSIONS_TYPES.ACTIONS.FIND_COMBO_DATA),
          this.$store.dispatch(BROKER_PERMISSIONS_TYPES.ACTIONS.FIND_PERMISSIONS),
        ]);
      } finally {
        this.loading = false;
      }
    },
    setFormData() {
      if(!Object.keys(this.permissions).length) return;

      const permissions = this.permissionsEdited || this.permissions;
      Object.keys(permissions).forEach(key => this.$set(this.formData, key, permissions[key]));
    },
    setDataChanged() {
      this.$store.commit(BROKER_PERMISSIONS_TYPES.MUTATIONS.SET_PERMISSIONS_EDITED, { ...this.formData, });
    },
    setDataChangedCombo(field, value) {
      this.$set(this.formData, field, value);
      this.setDataChanged();
    },
    async saveData() {
      await this.$store.dispatch(BROKER_PERMISSIONS_TYPES.ACTIONS.SAVE_PERMISSIONS);
    },
  },
  mounted() {
    this.init();
  },
  async beforeRouteLeave(to, from, next) {
    await this.saveData();
    next();
  },
  components: {
    GhostLoading,
    ComboBox,
    Pill,
  },
}
</script>
