
function generateOrUpdateMetaTag(propertyName, property, content) {
  let meta = document.querySelector(`meta[${propertyName}='${property}']`)
  
  if (!meta) {
    meta = document.createElement('meta');
    meta.setAttribute(propertyName, property);
  }

  meta.setAttribute('content', content);
  document.getElementsByTagName('head')[0].appendChild(meta);
}

export function generateOrUpdateDefaultHtml5MetaTag(name, content) {
  generateOrUpdateMetaTag('name', name, content)
}

export function generateOrUpdateOpenGraphMetaTag(property, content) {
  generateOrUpdateMetaTag('property', property, content)
}