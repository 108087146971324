<template>
  <div>
    <div class="box__container">
      <div class="box__title">PEP - Politisch exponierte Person</div>

      <div class="row" style="align-items: center;">
        <div class="col-auto">
          <InputToggleSwitch 
            label="Politisch exponierte Person / VIP" 
            :inLineLabel="true"
            v-model="pepActive" 
            @input="addCustomerDataEdited('pepActive', $event)" />
        </div>
          <div class="col-auto">
            <BaseButton isSecondary :disabled="disablePepWisAbgleich" @click="getWISAbleich" class="mt-0">PEP-WIS Abgleich</BaseButton>
          </div>
      </div>

      <div v-if="pepActive" class="forms__input--half-size">
        <div class="row">
          <div class="col-12">
            <InputField 
              label="Funktion" 
              v-model="pepText"
              :disabled="true" />
          </div>
        </div>
      </div>
    </div>

    <Fatca/>

    <template v-if="pepActive">
      <Firma v-if="isFirma" />
      <Privat v-else />
    </template>
  </div>
</template>

<script>
import CUSTOMERDATA_TYPES from '@/store/customerData/types';
import LOG_TYPES from '@/store/log/types';

import { isTrue } from '@/helpers/mapping.js';

import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import InputField from '@/components/core/forms/InputField.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import Privat from './politisch-exponierte-person/Privat.vue';
import Firma from './politisch-exponierte-person/Firma.vue';
import Fatca from './Fatca.vue';
import axios from 'axios';
import { buildMessage } from "@/helpers/log-message-helper";

import persoenlicheDatenMixin from './persoenliche-daten-mixin.js';


export default {
  mixins: [persoenlicheDatenMixin],
  data() {
    return {
      ...this.initialState(),
    };
  },
  computed: {
    pep() {
      return this.customerDataEdited?.pep || this.customerData?.pep;
    },
    pepText() {
      if(isTrue(this.pep?.pep_aktiv) || isTrue(this.pep?.vip_aktiv)) {
        return isTrue(this.pep?.pep_aktiv) ? this.pep?.pep_funktion : this.pep?.vip_funktion;
      } else if(isTrue(this.pep?.pep_ehe_aktiv) || isTrue(this.pep?.vip_ehe_aktiv)) {
        const text = (isTrue(this.pep?.pep_ehe_aktiv) ? this.pep?.pep_ehe_text : this.pep?.vip_ehe_text) || '';
        return `(Ehepartner): ${text}`;
      }
      return "";
    },
    disablePepWisAbgleich() {
      return !this.customerDataConfig?.canPepWisDatavendor
    },
  },
  methods: {
    initialState() {
      return {
        pepActive: false,
      };
    },
    updateGroupsIntoData() {
      this.updateFieldIntoData('pepActive');
    },
    getWISAbleich() {
      axios.get(`${process.env.VUE_APP_API}/customerData/getWISAbgleich?personId=${this.customerData.personId}`, {defaultSpinner: true})
        .then(response => {
          if (response.data) {
            if ( response.data.message) {
              this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.message, 'danger'));
            } else if (response.data.pep) {
              this.customerData.pep = response.data.pep;
              this.addCustomerDataEdited('pep');
              this.pepActive = true;
              this.addCustomerDataEdited('pepActive', true);
              this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Die Person wurde in der PEP-WIS Liste gefunden!', 'info'));
            } else if (response.data.similar) {
              const similar = response.data.similar.map(item => ({id: item.id, pep: JSON.parse(item.pep)}))
              let d = similar[0].pep;
              let mess = `Name / Geburtsdatum / Staatsangehörigkeit<br>
Gesucht<br>
${d.firstNameOrCompanySearched} ${d.lastNameSearched} / ${d.birthDateSearched||''} / ${d.nationalitySearched}<br>
Ähnliche Personen gefunden<br>
${d.firstNameOrCompanyReturned} ${d.lastNameReturned} / ${d.birthDateReturned||''} / ${d.nationalityReturned}`;
              for (let k = 1; k < similar.length; k++) {
                d = similar[k].pep;
                mess += `<br>${d.firstNameOrCompanyReturned} ${d.lastNameReturned} / ${d.birthDateReturned||''} / ${d.nationalityReturned}`
              }
              this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(mess, 'info'));
            } else {
              this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Keine Person in PEP-WIS Liste gefunden', 'info'));
            }
          }
        })
    },
  },
  mounted() {
    this.updateGroupsIntoData();
  },
  components: {
    InputToggleSwitch,
    InputField,
    BaseButton,
    Privat,
    Firma,
    Fatca,
  },
}
</script>
