var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", [_vm._v("TEST TEST TEST")]),
      _c("FotoButton", {
        attrs: { edgeCorrection: true },
        on: { input: _vm.setImage }
      }),
      _c("BaseFileSelect", {
        attrs: { accept: "image/*", buttonText: "Bild bearbeiten" },
        on: { files: _vm.onFileChange }
      }),
      _c("br"),
      _vm.rawImgUrl
        ? _c("EdgeSelectionDialog", {
            attrs: { imageData: _vm.rawImgUrl },
            on: { change: _vm.setImage }
          })
        : _vm.image
        ? _c("img", { attrs: { src: _vm.image } })
        : _c("span", [_vm._v("no image yet")]),
      _c("br"),
      _c(
        "BaseButton",
        { attrs: { disabled: !_vm.image }, on: { click: _vm.upload } },
        [_vm._v("Hochladen")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }