var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.maklerLogo
    ? _c("BaseImage", {
        style: { "text-align": "center" },
        attrs: { fit: "cover-vertically", src: _vm.maklerLogo }
      })
    : _c("UndrawSweetHome")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }