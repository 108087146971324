import SPARTENTYP_EINSTELLUNG_TYPES from './types';
import { getInitialState } from './index'
import Vue from 'vue';

export default {
  [SPARTENTYP_EINSTELLUNG_TYPES.MUTATIONS.LOAD_SPARTENTYP_LIST_SUCCESS](state, payload) {
    state.spartentypList = payload;
  }, 
  [SPARTENTYP_EINSTELLUNG_TYPES.MUTATIONS.LOAD_SPARTENTYP_SUCCESS](state, payload) {
    state.spartenTypen = payload;
  }, 
  [SPARTENTYP_EINSTELLUNG_TYPES.MUTATIONS.RESET_STATE](state, payload) {
    Object.assign(state, getInitialState())
  }, 
  [SPARTENTYP_EINSTELLUNG_TYPES.MUTATIONS.SELECT_SPARTENTYP](state, payload) {
    state.selectedSpartenTyp = payload;
  },

  [SPARTENTYP_EINSTELLUNG_TYPES.MUTATIONS.SET_CONFIG_DEFINITION_EDITED](state, payload) {
    if(!payload) return;

    Vue.set(state, 'configDefinitionEdited', { ...payload, });
  },

  [SPARTENTYP_EINSTELLUNG_TYPES.MUTATIONS.RESET_CONFIG_DEFINITION_EDITED](state) {
    Vue.set(state, 'configDefinitionEdited', null);
  },

  [SPARTENTYP_EINSTELLUNG_TYPES.MUTATIONS.GET_CONFIG_DEFINITION_SUCCESS](state, payload) {
    if(!payload) return;

    Vue.set(state, 'selectedSpartenTyp', { ...payload, });
  },

  [SPARTENTYP_EINSTELLUNG_TYPES.MUTATIONS.REMOVE_SPARTENTYP_SUCCESS](state, payload) {
    if(!payload) return;

    Vue.set(state, 'removedSpartenTyp', { ...payload, });
  },


  

  

  
}