var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("form", { on: { submit: _vm.saveEvent } }, [
      _c(
        "div",
        { staticClass: "modal-body" },
        [
          [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("div", { staticClass: "col col-md-6" }, [
                  _c(
                    "div",
                    [
                      _c("ComboBox", {
                        attrs: {
                          label: "Typ",
                          firstEmpty: true,
                          values:
                            _vm.currentRowType === "goal"
                              ? _vm.zieleTypes
                              : _vm.revenueTypes
                        },
                        on: {
                          input: function($event) {
                            return _vm.changeSelect($event)
                          }
                        },
                        model: {
                          value: _vm.form.type,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "type", $$v)
                          },
                          expression: "form.type"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm.isEventAdvancedFieldsApplicable(_vm.form.type)
                  ? [
                      _c("div", { staticClass: "col col-md-6" }, [
                        _c(
                          "div",
                          {},
                          [
                            _c("InputField", {
                              attrs: {
                                label: "Beschreibung",
                                placeholder: "Name",
                                type: "text"
                              },
                              model: {
                                value: _vm.form.title,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "title", $$v)
                                },
                                expression: "form.title"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm.isRecurringEvent
                        ? _c(
                            "div",
                            { staticClass: "col col-md-6" },
                            [
                              _c("ComboBox", {
                                attrs: {
                                  label: "Zeitraum",
                                  firstEmpty: true,
                                  values: _vm.periodTypes
                                },
                                model: {
                                  value: _vm.form.period,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "period", $$v)
                                  },
                                  expression: "form.period"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("div", { staticClass: "col col-md-6" }, [
                        _c(
                          "label",
                          { staticClass: "input-forms__label-content" },
                          [_vm._v("Start Datum (TT.MM.JJJJ)")]
                        ),
                        _c(
                          "div",
                          [
                            _c("DatePickerField", {
                              attrs: { monthPicker: true },
                              model: {
                                value: _vm.form.age,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "age", $$v)
                                },
                                expression: "form.age"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm.isRecurringEvent || _vm.form.repeat === "Wiederholung"
                        ? _c("div", { staticClass: "col col-md-6" }, [
                            _c(
                              "div",
                              [
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "input-forms__label-content"
                                    },
                                    [_vm._v("Endtermin (TT.MM.JJJJ)")]
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c("DatePickerField", {
                                        attrs: { monthPicker: true },
                                        model: {
                                          value: _vm.form.endDate,
                                          callback: function($$v) {
                                            _vm.$set(_vm.form, "endDate", $$v)
                                          },
                                          expression: "form.endDate"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              ],
                              2
                            )
                          ])
                        : _vm._e()
                    ]
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "col col-md-6" },
                  [
                    _c("InputField", {
                      attrs: { label: "Betrag", type: "currency" },
                      model: {
                        value: _vm.form.amount,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "amount", $$v)
                        },
                        expression: "form.amount"
                      }
                    })
                  ],
                  1
                )
              ],
              2
            )
          ]
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "modal-footer" },
        [
          _c(
            "BaseButton",
            { attrs: { type: "submit" }, on: { click: _vm.saveEvent } },
            [_vm._v("Ok")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }