var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isByPass
    ? _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("div", { staticClass: "box__title" }, [_vm._v("Anschriftfeld")]),
          _vm.isMainPerson
            ? [
                _vm.isEditable
                  ? _c(
                      "div",
                      { staticClass: "mb-3" },
                      [
                        _c(
                          "BaseButton",
                          {
                            attrs: { isSecondary: "" },
                            on: {
                              click: function($event) {
                                return _vm.copy()
                              }
                            }
                          },
                          [_vm._v("Kundendaten als Anschriftfeld übernehmen")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("InputField", {
                        attrs: {
                          label: "Anrede",
                          isComponentHalfSize: true,
                          disabled: !_vm.isEditable
                        },
                        on: {
                          change: function($event) {
                            return _vm.addCustomerDataEdited("anschriftfeld")
                          }
                        },
                        model: {
                          value: _vm.anschriftfeld.title,
                          callback: function($$v) {
                            _vm.$set(_vm.anschriftfeld, "title", $$v)
                          },
                          expression: "anschriftfeld.title"
                        }
                      }),
                      _c("InputField", {
                        attrs: {
                          isComponentHalfSize: true,
                          disabled: !_vm.isEditable
                        },
                        on: {
                          change: function($event) {
                            return _vm.addCustomerDataEdited("anschriftfeld")
                          }
                        },
                        model: {
                          value: _vm.anschriftfeld.fullName,
                          callback: function($$v) {
                            _vm.$set(_vm.anschriftfeld, "fullName", $$v)
                          },
                          expression: "anschriftfeld.fullName"
                        }
                      }),
                      _c("InputField", {
                        attrs: {
                          isComponentHalfSize: true,
                          disabled: !_vm.isEditable
                        },
                        on: {
                          change: function($event) {
                            return _vm.addCustomerDataEdited("anschriftfeld")
                          }
                        },
                        model: {
                          value: _vm.anschriftfeld.co,
                          callback: function($$v) {
                            _vm.$set(_vm.anschriftfeld, "co", $$v)
                          },
                          expression: "anschriftfeld.co"
                        }
                      }),
                      _c("InputField", {
                        attrs: {
                          label: "Straße, HNr",
                          isComponentHalfSize: true,
                          disabled: !_vm.isEditable
                        },
                        on: {
                          change: function($event) {
                            return _vm.addCustomerDataEdited("anschriftfeld")
                          }
                        },
                        model: {
                          value: _vm.anschriftfeld.street,
                          callback: function($$v) {
                            _vm.$set(_vm.anschriftfeld, "street", $$v)
                          },
                          expression: "anschriftfeld.street"
                        }
                      }),
                      _c("InputField", {
                        attrs: {
                          label: "Postleitzahl",
                          isComponentHalfSize: true,
                          disabled: !_vm.isEditable
                        },
                        on: {
                          change: function($event) {
                            return _vm.addCustomerDataEdited("anschriftfeld")
                          }
                        },
                        model: {
                          value: _vm.anschriftfeld.zip,
                          callback: function($$v) {
                            _vm.$set(_vm.anschriftfeld, "zip", $$v)
                          },
                          expression: "anschriftfeld.zip"
                        }
                      }),
                      _c("InputField", {
                        attrs: {
                          label: "Ort",
                          isComponentHalfSize: true,
                          disabled: !_vm.isEditable
                        },
                        on: {
                          change: function($event) {
                            return _vm.addCustomerDataEdited("anschriftfeld")
                          }
                        },
                        model: {
                          value: _vm.anschriftfeld.city,
                          callback: function($$v) {
                            _vm.$set(_vm.anschriftfeld, "city", $$v)
                          },
                          expression: "anschriftfeld.city"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }