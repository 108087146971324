var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.isLoaded
    ? _c(
        "div",
        { staticClass: "event-body" },
        [
          _c(
            "BaseModal",
            {
              ref: "modal",
              attrs: {
                showConfirmButton:
                  this.lines == null && this.linesNumber == null
                    ? true
                    : _vm.tapiData != null && _vm.tapiData.line != null
              },
              on: { onConfirmButton: _vm.save, onCancelButton: _vm.close },
              scopedSlots: _vm._u(
                [
                  {
                    key: "modalTitle",
                    fn: function() {
                      return [_vm._v(" TAPI Service Einstellungen ")]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                180692176
              )
            },
            [
              _c(
                "BoxContainer",
                [
                  _c("InputField", {
                    attrs: { label: "Host mit MSC TAPI Service" },
                    model: {
                      value: _vm.tapiData.host,
                      callback: function($$v) {
                        _vm.$set(_vm.tapiData, "host", $$v)
                      },
                      expression: "tapiData.host"
                    }
                  }),
                  _c("InputField", {
                    attrs: { label: "Username" },
                    model: {
                      value: _vm.tapiData.sessionId,
                      callback: function($$v) {
                        _vm.$set(_vm.tapiData, "sessionId", $$v)
                      },
                      expression: "tapiData.sessionId"
                    }
                  }),
                  _c("InputField", {
                    attrs: { label: "Passwort", type: "password" },
                    model: {
                      value: _vm.tapiData.password,
                      callback: function($$v) {
                        _vm.$set(_vm.tapiData, "password", $$v)
                      },
                      expression: "tapiData.password"
                    }
                  }),
                  _c("InputField", {
                    attrs: { label: "Vorwahl", maxlength: "3" },
                    model: {
                      value: _vm.tapiData.prefix,
                      callback: function($$v) {
                        _vm.$set(_vm.tapiData, "prefix", $$v)
                      },
                      expression: "tapiData.prefix"
                    }
                  }),
                  _c(
                    "div",
                    [
                      _c("InputRadioBoxGroup", {
                        staticClass: "box__container",
                        attrs: {
                          title: "Tapi-Line auswählen",
                          values: [
                            { label: "Tapi-Line fest auswählen", value: false },
                            {
                              label: "flexibel nach Telefonnummer",
                              value: true
                            }
                          ]
                        },
                        on: {
                          input: function($event) {
                            return _vm.updateRadio($event)
                          }
                        },
                        model: {
                          value: _vm.tapiData.selectLineByTelNr,
                          callback: function($$v) {
                            _vm.$set(_vm.tapiData, "selectLineByTelNr", $$v)
                          },
                          expression: "tapiData.selectLineByTelNr"
                        }
                      })
                    ],
                    1
                  ),
                  _c("InputField", {
                    attrs: { label: "Line", disabled: true },
                    model: {
                      value: _vm.tapiData.line,
                      callback: function($$v) {
                        _vm.$set(_vm.tapiData, "line", $$v)
                      },
                      expression: "tapiData.line"
                    }
                  })
                ],
                1
              ),
              _c("ComboBox", {
                attrs: {
                  values: _vm.tapiData.selectLineByTelNr
                    ? _vm.linesNumber
                      ? _vm.linesNumber
                      : []
                    : _vm.lines
                    ? _vm.lines
                    : []
                },
                on: {
                  change: function($event) {
                    return _vm.onChangeInputField($event)
                  }
                },
                model: {
                  value: _vm.tapiData.line,
                  callback: function($$v) {
                    _vm.$set(_vm.tapiData, "line", $$v)
                  },
                  expression: "tapiData.line"
                }
              })
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }