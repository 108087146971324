export const MUTATION_PREFIX = 'RUHESTANDSPLANUNG_MUTATIONS_';
export const ACTIONS_PREFIX = 'RUHESTANDSPLANUNG_ACTIONS_';
export const GETTERS_PREFIX = 'RUHESTANDSPLANUNG_GETTERS_';

export default {
  MUTATIONS: {
    GET_SCENARIOS_SUCCESS: MUTATION_PREFIX + 'GET_SCENARIOS_SUCCESS',
    GET_VERMOEGEN_SUCCESS: MUTATION_PREFIX + 'GET_VERMOEGEN_SUCCESS',
    UPDATE_STORE: MUTATION_PREFIX + 'UPDATE_STORE',
    UPDATE_VERMOEGEN_AUSGABEN: MUTATION_PREFIX + 'UPDATE_VERMOEGEN_AUSGABEN',
    UPDATE_SELECTED_GOALS: MUTATION_PREFIX + 'UPDATE_SELECTED_GOALS',
    SCENARIOS_URSPRUNG: MUTATION_PREFIX + 'SCENARIOS_URSPRUNG',
  },
  ACTIONS: {
    GET_SCENARIOS: ACTIONS_PREFIX + 'GET_SCENARIOS',
    DELETE_SCENARIO: ACTIONS_PREFIX + 'DELETE_SCENARIO',
    SAVE_SCENARIO: ACTIONS_PREFIX + 'SAVE_SCENARIO',
    GET_VERMOEGEN: ACTIONS_PREFIX + 'GET_VERMOEGEN',
    SAVE_GOAL_SCENARIO: ACTIONS_PREFIX + 'SAVE_GOAL_SCENARIO',
    REMOVE_GOAL_SCENARIO: ACTIONS_PREFIX + 'REMOVE_GOAL_SCENARIO',
  },
  GETTERS: {
    SCENARIOS: GETTERS_PREFIX + 'SCENARIOS',
    VERMOEGEN: GETTERS_PREFIX + 'VERMOEGEN',
    AUSGABEN: GETTERS_PREFIX + 'AUSGABEN',
    COMBOBOX_GOAL_TYPES: GETTERS_PREFIX + 'COMBOBOX_GOAL_TYPES',
    SCENARIOS_URSPRUNG: GETTERS_PREFIX + 'SCENARIOS_URSPRUNG',
  }
}