<template>
  <div>
    <div class="row" v-if="!isMobileNativeContext">
      <div class="col-6 border-right">
        <div class="d-flex" style="flex-direction: column;">
          <span class="py-1" style="text-align:right;">Ausgaben</span>
          <ChatHorizontalBullet class="py-1" style="transform: rotate(180deg);" :current="lastMonthExpenses" :average="lastThreeMonthExpensesAverage"/>
          <div class="d-flex py-1 justify-content-end">
            <span class="mx-1">30 Tage:</span>&nbsp;
            <span><CurrencyLabel :value="lastMonthExpenses"/></span>
          </div>
          <div class="d-flex py-1 justify-content-end">
            <span class="mx-1">Ø 90 Tage:</span>&nbsp;
            <span><CurrencyLabel :value="lastThreeMonthExpensesAverage"/></span>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="d-flex" style="flex-direction: column;">
          <span class="py-1">
            Einnahmen
          </span>
          <ChatHorizontalBullet class="py-1" :current="lastMonthIncome" :average="lastThreeMonthIncomeAverage"/>
          <div class="d-flex py-1">
            <span class="mx-1">30 Tage:</span>&nbsp;
            <span><CurrencyLabel :value="lastMonthIncome"/></span>
          </div>
          <div class="d-flex py-1">
            <span class="mx-1">Ø 90 Tage:</span>&nbsp;
            <span><CurrencyLabel :value="lastThreeMonthIncomeAverage"/></span>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <FlexibleList :rows="mobileTable.rows">
        <template #title="row">
          {{ row.title }}
        </template>

        <template #value="row">
          <CurrencyLabel :value="row.value"/>
        </template>
      </FlexibleList>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BRIDGE_TYPES from '@/store/bridge/types';
import CurrencyLabel from '@/components/core/CurrencyLabel.vue'
import ChatHorizontalBullet from '@/components/charts/ChartHorizontalBullet.vue'
import FlexibleList from "@/components/flexibleList/FlexibleList.vue";

export default {
  props: {
    summary: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      isMobileNativeContext: BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT,
    }),
    lastMonthExpenses() {
      return this.summary?.lastMonth?.expenses || 0
    },
    lastThreeMonthExpensesAverage() {
      return this.summary?.lastThreeMonth?.expensesAverage || 0
    },
    lastMonthIncome() {
      return this.summary?.lastMonth?.income || 0
    },
    lastThreeMonthIncomeAverage() {
      return this.summary?.lastThreeMonth?.incomeAverage || 0
    },
    mobileTable() {
      const rows = [];
      rows.push({title: 'Einnahmen letzte 30 Tage', value: this.lastMonthIncome});
      rows.push({title: 'Einnahmen Ø 90 Tage', value: this.lastThreeMonthIncomeAverage});
      rows.push({title: 'Ausgaben letzte 30 Tage', value: this.lastMonthExpenses});
      rows.push({title: 'Ausgaben Ø 90 Tage', value: this.lastThreeMonthExpensesAverage});
      return {rows}
    },
  },
  components: {
    CurrencyLabel,
    ChatHorizontalBullet,
    FlexibleList,
  }
}
</script>

<style scoped>
  .border-right {
    border-right: 1px solid #ececec;
  }
</style>
