import CORE_TYPES from '@/store/core/types';
import COURTAGETABELLE_INVESTMENT_TYPES from './types';
import axios from 'axios';

const config = {
  defaultSpinner: true
};

const DEFAULT_CANCELABLE_REQUEST_TITLE = 'Courtagetabelle Investment';
const MAP_TYPE_TO_CANCELABLE_REQUEST_TITLE = {
  OFFENEFONDS: `Offene Wertpapiere`,
  BETEILIGUNGEN: `Beteiligungen`,
};

export default {
  [COURTAGETABELLE_INVESTMENT_TYPES.ACTIONS.GET_FILTER_FIELDS_DEFINITIONS]({ commit, getters}, payload) {
    return new Promise((resolve, reject) => {
      let url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/courtagetabelleInvestments/getFilterFieldsDefinition`;

      axios.get(url, {config, params:{...payload}}).then(response => {
        commit(COURTAGETABELLE_INVESTMENT_TYPES.MUTATIONS.FILTER_FIELDS_DEFINITIONS_SUCCESS, response?.data || {});
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },
  [COURTAGETABELLE_INVESTMENT_TYPES.ACTIONS.GET_COURTAGETABELLE_INVESTMENT_LIST]({ commit, getters}, payload) {
    return new Promise((resolve, reject) => {
      let url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/courtagetabelleInvestments/filterCourtagetabeleInvestments`;

      let params = {};
      
      if(payload?.page >= 0) {
        params.page = payload.page
      }
      if(payload?.order) {
        params.orderKey = payload.order.key;
        params.orderDirection = payload.order.sortDirection;
      }

      if(payload?.type) {
        params.type = payload.type
      }
      if(payload?.maxCount) {
        params.maxCount = payload.maxCount
      }

      const requestConfig = {
        ...config,
        params,
        cancelableRequest: { title: MAP_TYPE_TO_CANCELABLE_REQUEST_TITLE?.[payload?.type] || DEFAULT_CANCELABLE_REQUEST_TITLE, },
      };

      axios.post(url, payload.filters, requestConfig).then(response => {
        commit(COURTAGETABELLE_INVESTMENT_TYPES.MUTATIONS.COURTAGETABELLE_INVESTMENT_LIST_SUCCESS, response?.data || {});
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },
  [COURTAGETABELLE_INVESTMENT_TYPES.ACTIONS.GET_COURTAGETABLLE_INVESTMENT_FONDSPLATTFORMEN]({ commit, getters}, payload) {
    return new Promise((resolve, reject) => {
      let url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/courtagetabelleInvestments/getCourtagetabeleFondsplattformen`;

      let params = {};
      
      if(payload?.type) {
        params.type = payload.type
      }

      axios.get(url, {params, ...config}).then(response => {
        commit(COURTAGETABELLE_INVESTMENT_TYPES.MUTATIONS.GET_COURTAGETABELLE_INVESTMENT_FONDSPLATTFORMEN_SUCCESS, response?.data || {});
        resolve();
      }).catch(error => {
        reject(error);
      });
    });
  },

  async [COURTAGETABELLE_INVESTMENT_TYPES.ACTIONS.EXPORT_COURTAGETABELLE_INVESTMENT]({ getters}, payload) {
    if(!payload?.fileType) return;

    const params = {};
    if(payload?.type) {
      params.type = payload.type;
    }
    params.fileType = payload.fileType;

    const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/courtagetabelleInvestments/exportCourtagetabeleInvestments`;
    return await axios.post(url, payload.filters, { ...config, params, });
  },

}
