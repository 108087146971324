<template>
    <div>
        <PageHeaderTitleNavigation 
            title="Eingang einer Kundenbeschwerde" 
            :defaultMenu="$appNavigation.currentTabMenu" 
            :actions="headerActions" 
            @action-EINREICHEN="einreichen()"
        />

         <div class="box__container">
            <InputField 
                v-model="form.INPUT_BESCHWERDE_NAME_MITARBEITER"
                label="Name des Mitarbeiters, der die Beschwerde annimmt"
                isComponentHalfSize
                :disabled="disabled"
             />
            <InputField 
                v-model="form.INPUT_BESCHWERDE_NAME_KUNDE"
                label="Name des Kunden (Beschwerdeführer)"
                isComponentHalfSize
                :disabled="disabled"
             />
            <DatePickerField
                label="Datum des Beschwerdeeingangs"
                isValueAsString
                isComponentHalfSize
                v-model="form.INPUT_BESCHWERDE_DATUM"
                :disabled="disabled"
            />
            <InputRadioBoxGroup 
                title="Form des Beschwerdeeingangs" 
                :values="typeValues" 
                v-model="form.INPUT_BESCHWERDE_FORM"
                :disabled="disabled"
            />
            <InputField 
                v-model="form.INPUT_BESCHWERDE_FORM_SONSTIGES_TEXT"
                isComponentHalfSize
                :disabled="form.INPUT_BESCHWERDE_FORM !== 'SONSTIGES' || disabled"
             />

            <InputTextArea
                label="Inhalt der Beschwerde/Beschreibung des Anliegens/Sachverhaltsdarstellung"
                v-model="form.INPUT_BESCHWERDE_TEXT"
                isComponentHalfSize
                :disabled="disabled"
            />
            <InputTextArea
                label="Stellungnahme zum Sachverhalt, falls Sie der Kundenbetreuer sind. Falls Sie nicht der Kundenbetreuer sind holt der Beschwerdemanager die Stellungnahme des Kundenbetreuers zum Sachverhalt ein."
                v-model="form.INPUT_BESCHWERDE_STELLUNGNAHME"
                isComponentHalfSize
                :disabled="disabled"
            />
            <InputCheckBoxGroup
                v-model="form"
                title="Ggf. beigefügte Unterlagen, auf die sich die Beschwerde bezieht (sofern dies zur Sachverhaltsdarstellung erforderlich ist). Falls Sie nicht der Kundenbetreuer sind, holt der Beschwerdemanager die beizufügenden Unterlagen ein."
                :values="[ 
                    { label: 'Kundendokumentation', path: 'INPUT_DOKUMENT_KD' },
                    { label: 'Vermögensverwaltungsvertrag', path: 'INPUT_DOKUMENT_VERTRAG' },
                    { label: 'Quartalsberichte', path: 'INPUT_DOKUMENT_QB' },
                    { label: 'Depotauszüge', path: 'INPUT_DOKUMENT_DA' },
                    { label: 'Sonstige:', path: 'INPUT_DOKUMENT_SONSTIG' },
                ]"
                :disabled="disabled"
            />
            <InputField
                v-model="form.INPUT_DOKUMENT_SONSTIG_TEXT"
                :disabled="!form.INPUT_DOKUMENT_SONSTIG || disabled"
                isComponentHalfSize
             />
        </div>
    </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import VV_TYPES from '@/store/vermoegensverwaltung/types';
import BaseButton from '@/components/core/BaseButton.vue';
import mixin from '@/mixins/vermoegensverwaltung/vv-mixin.js';
import InputField from '@/components/core/forms/InputField.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import DownloadLink from '@/components/core/download/DownloadLink.vue'
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import InputCheckBoxGroup from '@/components/core/forms/checkbox/InputCheckBoxGroup.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import CORE_TYPES from '@/store/core/types'
import { mapGetters } from 'vuex';
  import { makeQueryParam } from '@/helpers/utils-helper';
import axios from 'axios';
import { PageHeaderSimpleAction, PageHeaderDownloadLinkHrefAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {
    mixins: [mixin],
    components: {
        OptionMenu,
        PageHeaderTitleNavigation,
        BaseButton,
        InputField,
        InputTextArea,
        DownloadLink,
        DatePickerField,
        InputCheckBoxGroup,
        InputRadioBoxGroup,
    },
    data() {
        return {
            typeValues: [{label: 'mündlich im persönlichen Gespräch', value: 'GESPRAECH'}, {label: 'mündlich per Telefon', value: 'TELEFON'}, 
                {label: 'schriftlich per E-Mail', value: 'EMAIL'}, 
                {label: 'schriftlich per Fax', value: 'FAX'}, {label: 'schriftlich per Brief', value: 'BRIEF'}, 
                {label: 'Sonstige:', value: 'SONSTIGES'}, ],
            form: {
                INPUT_BESCHWERDE_FORM: '',
                INPUT_BESCHWERDE_NAME_KUNDE: '',
                INPUT_BESCHWERDE_NAME_MITARBEITER: '',
                INPUT_BESCHWERDE_DATUM: '',
                INPUT_BESCHWERDE_FORM_SONSTIGES_TEXT: '',
                INPUT_BESCHWERDE_TEXT: '',
                INPUT_BESCHWERDE_STELLUNGNAHME: '',
                INPUT_DOKUMENT_SONSTIG_TEXT: '',
                INPUT_DOKUMENT_KD: false,
                INPUT_DOKUMENT_VERTRAG: false,
                INPUT_DOKUMENT_QB: false,
                INPUT_DOKUMENT_DA: false,
                INPUT_DOKUMENT_SONSTIG: false,
            },
        }
    },
    watch: {
        beschwerde(newValue) {
            if (newValue && Object.keys(newValue).length) {
                this.form = newValue;
            }
        }
    },
    async mounted() {
        if (!this.$route.query.backAction) {
            this.$store.commit(VV_TYPES.MUTATIONS.RESET_BESCHWERDE);
        }

        if (this.$route.query.antragsdatenId) {
            await axios.get(`${process.env.VUE_APP_API}/vv/loadBeschwerde?param_id=${this.$route.query.antragsdatenId}`, { defaultSpinner: true }).then(response =>
                this.$store.commit(VV_TYPES.MUTATIONS.SAVE_BESCHWERDE_SUCCESS, response?.data))
        } 
        
        if (this.beschwerde && Object.keys(this.beschwerde).length) {
            this.form = this.beschwerde;
        }
    },
    computed: {
        ...mapGetters({
            apiAddress: CORE_TYPES.GETTERS.API_ADDRESS,
            token: CORE_TYPES.GETTERS.GET_TOKEN,
            beschwerde: VV_TYPES.GETTERS.BESCHWERDE,
        }),
        headerActions() {
            return [
                PageHeaderSimpleAction('EINREICHEN', this.disabled ? 'Beschwerde ansehen' : 'Beschwerde einreichen'),
                PageHeaderDownloadLinkHrefAction('DOWNLOAD', 'PDF', 'Kundenbeschwerde.pdf', this.getPDFLink)
            ];
        },
        paramId() {
            return this.form?.PARAM_ID;
        },
        getPDFLink() {
            const payload = {
                data: Object.assign(this.form, {servletName: 'msc.servlets.wealthKonzept.WKKundenbeschwerdeServlet'}),
                action: "pdf",               
                confirmed: true,
            }        

            const apiAddress = this.apiAddress
            const token = this.token
            const input = btoa(JSON.stringify(payload));
            const params = makeQueryParam({ input, token })
            const fileName = 'Kundenbeschwerde.pdf';
            
            return `${apiAddress}/download_service/generate_antrag_pdf/${fileName}?${params}`;
        },
        disabled() {
            return !!this.form.chatId
        }
    },
    methods: {
        async einreichen() {
            await this.$store.dispatch(VV_TYPES.ACTIONS.SAVE_BESCHWERDE, this.form);

            this.$addBreadcrumb({
                label: 'zurück zur Kundenbeschwerde', 
                fullPath: this.$route.fullPath,
                breadcrumb: 'Nachricht',
            });
            this.$router.push({name: 'ticket', params: {chatId: this.form.chatId, channelId: this.form.channelId}});
        },
    }
}
</script>