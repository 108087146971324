<template>
  <div>
    <div>
      <GhostLoading v-if="loading && !scrollLoading" type="table" :config="{ table: { rows: 10 } }"/>
      <PaginatedTable
        v-else
        tableId="15b90920-ebdd-11ec-8ea0-0242ac120002"
        rowId="antragCheckId"
        :title="title"
        :headers="headers"
        :page="selectedPage"
        :pages="pages"
        :pageCount="pageCount"
        :rowCount="rowCount"
        :sorted="searchSort"
        :pageSize="maxRowsPerPage"
        :menuRowsPerPage="menuRowsPerPage"
        :mobileConfig="{title: 'label', headers: ['dateCreated', 'customerNr']}"
        v-on="$listeners"
        @scrollLoading="scrollLoading = $event"
        @click-info="clickInfo"
        @action-SENDMAIL="handleActionSendMail" 
        @action-INFO="clickInfo"
      >
        <template #status="row">
          <ComboBox
            :value="row.status"
            :values="row.statuses || []"
            :disabled="row.statuses.length == 1"
            @change="changePruefStatus($event, row)"
          >
          </ComboBox>
        </template>

        <template #label="row">
          <DownloadLink
            v-if="!row.mobileTableContext"
            target="_blank"
            rel="noopener noreferer"
            :title="row.label"
            downloadServicePath="/antragCheckData"
            :queryParams="{
              antragCheckId: row.antragCheckId,
            }"
          />
          <span v-else>{{row.label}}</span>
        </template>
      </PaginatedTable>
    </div>
    <Info ref="Info" />
  </div>
</template>

<script>
import A_C_TYPES from "@/store/fondsAntragCheck/types";

import { mapGetters } from "vuex";
import PaginatedTable from "@/components/table2/PaginatedTable.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import Info from "@/views/fondsantragcheck/InfoDialog.vue";
import {
  TextColumn,
  HtmlColumn,
  DateTimeColumn,
  IconColumn,
  Icon,
  ActionColumn,
  SlotColumn,
  SimpleAction,
  MENU_ROWS_PER_PAGE,
} from "@/components/table2/table_util.js";
import { PhInfo,PhWarningOctagon } from "phosphor-vue";
import GhostLoading from "@/components/core/loading/GhostLoading.vue";
import DownloadLink from '@/components/core/download/DownloadLink.vue'

export default {
  components: {
    PaginatedTable,
    GhostLoading,
    Info,
    DownloadLink,
    ComboBox,
    PhInfo,
    PhWarningOctagon
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    searchSort: {
      type: Object,
      default: () => {},
    },
    selectedPage: {
      type: Number,
      default: 0,
    },
    maxRowsPerPage: {
      type: Number,
      default: 25,
    },
  },
  data() {
    return {
      scrollLoading: false,
      menuRowsPerPage: MENU_ROWS_PER_PAGE,
    };
  },
  computed: {
    ...mapGetters({
      records: A_C_TYPES.GETTERS.FILTER,
      setup: A_C_TYPES.GETTERS.SETUP,
      refresh: A_C_TYPES.GETTERS.REFRESH,
      rowCount: A_C_TYPES.GETTERS.ROW_COUNT,
    }),
    disabledColumns() {
       if (this.setup && ! this.setup.intern) {
        return ["warn","warnMessage"];
      } else {
        return [];
      }
    },
    headers() {
      return {
        lockedLeft: [
          IconColumn("info", "Info").makeLink(),
          IconColumn("warn", ""),
          SlotColumn("status", "Status", 150, 1).makeSortable(),
        ].filter(
          (column) => !this.disabledColumns.includes(column.key)
        ),
        center:[
          TextColumn("company", "Gesellschaft"),
          DateTimeColumn("dateCreated", "Erstellt"),
          DateTimeColumn("dateCheck", "Prüfdatum"),
          SlotColumn("label", "Bezeichnung").makeSortable(),
          TextColumn("customerNr", "Kundennr"),
          TextColumn("customerName", "Kundenname"),
          HtmlColumn("brockerSender", "Einsender"),
          TextColumn("brockerNr", "Verm. Nr"),
          HtmlColumn("brockerName", "Verm. Name"),
          TextColumn("bemerkung", "Bemerkung"),
          TextColumn("typ", "Typ"),
          HtmlColumn("warnMessage","Warnung").setColorFn((row) => { return  'color-danger' })
        ] .filter((column) => !this.disabledColumns.includes(column.key)),

        lockedRight: [ActionColumn("actions", "Aktionen")] /*.filter(
          (column) => !this.disabledColumns.includes(column.key)
        )*/,
      };
    },
    pages() {
      const result = {};
      Object.entries(this.records).forEach(([page, records]) => {
        result[page] = records.map(record => {
          const row = {
            ...record,
          };
          if (record.infoColumn && record.infoColumn.type) {
            row.info = [
              Icon(
                PhInfo,
                record.infoColumn.label,
                25,
                "fill",
                "color-" + record.infoColumn.type
              ),
            ];
          }


          if (record.warnMessage) {
            row.warn = [
              Icon(
                PhWarningOctagon,
                record.warnMessage,
                25,
                "fill",
                "color-" + 'danger'
              ),
            ];
          }

          if (record.actions) {
            row.actions = record.actions.map((legend) => {
              return SimpleAction(legend.key, legend.icon, legend.label);
            });
          }

          return row;
        })
      })
      return result;
    },
    pageCount() {
      return Math.ceil(this.rowCount / this.maxRowsPerPage);
    },
  },
  methods: {
    clickInfo(tableRow) {
      if (tableRow && tableRow.antragCheckId) {
        this.$refs.Info?.open(tableRow.antragCheckId);
       //this.$router.push(`/communication/mailcomposer-fondsAntragCheck/${tableRow.antragCheckId}`)
      }
    },
    async handleActionSendMail(tableRow){
        this.$router.push(`/communication/mailcomposer-fondsAntragCheck/${tableRow.antragCheckId}`);
    },
    async changePruefStatus(event, row) {
      if (event != row.status) {
        let e = {};
        e.antragCheckId = row.antragCheckId;
        e.status = event;
        await this.$store.dispatch(A_C_TYPES.ACTIONS.SET_STATUS, e);
      }
    },
  },
};
</script>