var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: _vm.dynamicTitle },
        on: {
          "action-PDF_DOCUMENT_UPLOAD": _vm.dropFiles,
          "action-RE_SCAN_ALL_ACTION_VORSCHLAGE": function($event) {
            return _vm.rescannAllAction(true)
          },
          "action-RE_SCAN_ALL_ACTION": function($event) {
            return _vm.rescannAllAction(false)
          }
        }
      }),
      _c(
        "DragnDropArea",
        {
          attrs: { hoverText: "Datei hier ablegen" },
          on: { files: _vm.dropFiles }
        },
        [
          _c("BaseFilter", {
            ref: "mrMoneyFilterRef",
            attrs: {
              filterId: "57ca8ee5-d665-4092-be06-fad7ef0a48b76",
              title: "Liste filtern",
              metadata: _vm.filterMetadata,
              defaultOptions: _vm.defaultOptionsData
            },
            on: {
              onFilter: function($event) {
                return _vm.filterForm($event)
              }
            }
          }),
          _c(
            "div",
            {
              staticClass: "box__container",
              attrs: { tid: "3a71e665-4f85-42f6-949c-2bf3ed6674f8" }
            },
            [
              !_vm.loading && _vm.tableRows.length
                ? _c("big-table", {
                    attrs: {
                      headers: _vm.tableHeadersForBackend,
                      headerActions: _vm.bigTableHeaderActions,
                      tableId: _vm.tableId,
                      title: "Liste",
                      rows: _vm.tableRows,
                      initialPageSize: _vm.minNumRows,
                      page: _vm.page,
                      sortingState: _vm.sortingState
                    },
                    on: {
                      onClickHeader: _vm.onClickHeader,
                      "click-link-dateiName": _vm.fetchPDF,
                      "click-link-interneNr": _vm.openVertragdOverview,
                      action: _vm.executeAction,
                      "headerAction-RESCANN_ALL": function($event) {
                        return _vm.rescannAllAction(true)
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "SHOW_AND_ASSIGN",
                          fn: function(row) {
                            return [
                              row.mrMoneyId
                                ? _c("PhArrowBendUpRight", {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { size: 24 },
                                    on: {
                                      click: function($event) {
                                        return _vm.openAssignInsurance(row)
                                      }
                                    }
                                  })
                                : _vm._e()
                            ]
                          }
                        },
                        {
                          key: "dateiName",
                          fn: function(row) {
                            return [
                              row.mrMoneyId
                                ? _c(
                                    "DownloadLink",
                                    {
                                      attrs: {
                                        downloadServicePath: "/mrmoneypdf",
                                        filename:
                                          row.dateiName || "MrMoneyPDF.pdf",
                                        queryParams: {
                                          mrMoneyId: row.mrMoneyId
                                        }
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(row.dateiName) + " ")]
                                  )
                                : _c("span", [
                                    _vm._v(" " + _vm._s(row.dateiName) + " ")
                                  ])
                            ]
                          }
                        },
                        {
                          key: "comment",
                          fn: function(row) {
                            return [
                              _c("InputField", {
                                attrs: { value: row.comment },
                                on: {
                                  change: function($event) {
                                    return _vm.saveComment(row, $event)
                                  }
                                }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1608254129
                    )
                  })
                : _vm._e(),
              _vm.loading
                ? _c("GhostLoading", {
                    attrs: { type: "table", title: _vm.TABLE_TITLE }
                  })
                : _vm._e(),
              !_vm.loading && !_vm.tableRows.length
                ? _c("NoData", { attrs: { title: _vm.TABLE_TITLE } })
                : _vm._e()
            ],
            1
          ),
          _c(
            "BaseModal",
            {
              ref: "removeItemConfirmation",
              attrs: {
                modalTitle: "Löschen bestätigen",
                labelButtonConfirm: "Löschen"
              },
              on: {
                onConfirmButton: function($event) {
                  return _vm.removeItem()
                }
              }
            },
            [
              _vm._v(
                " Möchten Sie dieses Dokument wirklich von MrMoney PDF-Box löschen? "
              )
            ]
          )
        ],
        1
      ),
      _c("PreviewPDF", {
        ref: "resizablePopup",
        attrs: {
          pdfUrl: _vm.pdfUrl,
          id: "d11dc34a-27d7-4d3a-9c20-69d6601b54e2"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }