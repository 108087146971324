export const MUTATION_PREFIX = 'dbm_';
export const ACTIONS_PREFIX = 'dbm_';
export const GETTERS_PREFIX = 'dbm_';

export default {
  MUTATIONS: {
    DATA: MUTATION_PREFIX + 'DATA',
    START_TIME :MUTATION_PREFIX + 'START_TIME',
    SESSION: MUTATION_PREFIX+'SESSION',
    SELECTED_NODE: MUTATION_PREFIX+'SELECTED_NODE',
    DATA_SEGMENTS:MUTATION_PREFIX+'DATA_SEGMENTS',
    STAT_DATA:MUTATION_PREFIX+'STAT_DATA',
    FORMAT_SWITCH:MUTATION_PREFIX+'FORMAT_SWITCH',
    FULL_SQL:MUTATION_PREFIX+'FULL_SQL',
    RUNNIG:MUTATION_PREFIX+'RUNNIG',
    STAT:MUTATION_PREFIX+'STAT',
    MINUTS:MUTATION_PREFIX+'MINUTS'
    
  },
  ACTIONS: {
    DATA: ACTIONS_PREFIX + 'DATA',
    SEGMENTS:ACTIONS_PREFIX+'SEGMENTS',
    BLOCKERS:ACTIONS_PREFIX+'BLOCKERS',
    SESSION: ACTIONS_PREFIX+'SESSION',
    KILL_SESSION: ACTIONS_PREFIX+'KILL_SESSION',
    STAT_DATA:ACTIONS_PREFIX+'STAT_DATA',
  },
  GETTERS: {
    DATA: GETTERS_PREFIX + 'DATA',
    START_TIME :GETTERS_PREFIX + 'START_TIME',
    SESSION: GETTERS_PREFIX+'SESSION',
    SELECTED_NODE: GETTERS_PREFIX+'SELECTED_NODE',
    DATA_SEGMENTS:GETTERS_PREFIX+'DATA_SEGMENTS',
    STAT_DATA:GETTERS_PREFIX+'STAT_DATA',
    FORMAT_SWITCH:GETTERS_PREFIX+'FORMAT_SWITCH',
    FULL_SQL:GETTERS_PREFIX+'FULL_SQL',
    RUNNIG:GETTERS_PREFIX+'RUNNIG',
    STAT:GETTERS_PREFIX+'STAT',
    MINUTS:GETTERS_PREFIX+'MINUTS'
  }
}