
import LOG_TYPES from '@/store/log/types.js';
import CORE_TYPES from '@/store/core/types.js';
import WEBRTC_TYPES from '@/store/webrtc/types.js';
import BRIDGE_TYPES from '@/store/bridge/types.js';
import { WebRtcCallStatus } from "@/store/webrtc/utils/webrtc_types.js";
import { SignalingConnectionRole } from "@/store/webrtc/utils/signalingService_types.js";
import { 
  WebRtcCallStatusDict,
  readThriftMessageFromSignalerToCLient,
  readThriftWebSocketCloseReasonPhrase,
  translateAndLogHandleSignalMessage,
} from '@/store/webrtc/sockets/helpers.js';
import { handleSignalerSocket } from './signaler-handler.js';
import { STOP_CALL_CAUSE } from '@/store/bridge/index.js';
import { VIEW_ROLES } from '@/router/roles.js';

function onOpen({ callInfo, commit, dispatch }) {
  dispatch(LOG_TYPES.ACTIONS.INFO, { message: 'Signaling socket opened: Sending Backed up messages...' });
  if (callInfo && callInfo.socketBacklog) {
    if (callInfo.closeSocketData) {
      dispatch(LOG_TYPES.ACTIONS.INFO, { message: 'Signaling socket opened: Sending closeSocketData', closeSocketData: callInfo.closeSocketData });
      // the call ended (possibly due to an error) before the socket was fully opened
      commit(WEBRTC_TYPES.MUTATIONS.END_CALL, { 
        callId: callInfo.callId, 
        closeSocketData: callInfo.closeSocketData, 
      });
    } else {
      dispatch(LOG_TYPES.ACTIONS.INFO, { message: 'Signaling socket opened: Sending socketBacklog', socketBacklog: callInfo.socketBacklog });
      callInfo.socketBacklog.forEach(element => {
        callInfo.socket.send(element)
      })
    }
  }
  commit(WEBRTC_TYPES.MUTATIONS.CLEAR_SIGNAL_BACKLOG, callInfo.callId);
}

function onMessage({ event, callInfo, dispatch, getters, commit, state }) {
  let msg = readThriftMessageFromSignalerToCLient(event.data);
  translateAndLogHandleSignalMessage(msg, dispatch);
  handleSignalerSocket({ msg, callId: callInfo.callId, dispatch, getters, commit, state });
}

function onError({ event, dispatch }) {
  dispatch(LOG_TYPES.ACTIONS.WARN, {
    message: `signalerSocket error event`, 
    event
  });
}

function onClose({ event, callInfo, dispatch, state, commit, getters }) {
  let stopCallPayload = { stopCallCause: STOP_CALL_CAUSE.ERROR };

  try {
    dispatch(LOG_TYPES.ACTIONS.INFO, { message: "socket signaling closed", event });
    let alertMsg = null;
    let isMissedCall = false;
    let messageStatus = null;

    if (event.code === 1000 && event.reason.length) {
      // socket was closed in a normal fashion
      if (event.reason.charAt(0) !== "{") {
        // socket was closed from our side
        if (state.callId === callInfo.callId) {
          dispatch(LOG_TYPES.ACTIONS.INFO, "we probably have hung up");
          stopCallPayload = { stopCallCause: STOP_CALL_CAUSE.HANG_UP_LOCAL }
        } else {
          // we didn't accept the call. this is displayed in the rejectCall function
          dispatch(LOG_TYPES.ACTIONS.INFO, "our side canceled the call");
          stopCallPayload = { stopCallCause: STOP_CALL_CAUSE.CANCELED }
        }
      } else {
        // server closed the socket, read reason
        let msg = readThriftWebSocketCloseReasonPhrase(event.reason);

        const displayName = state.beteiligterInfos?.[callInfo.calleeChatBeteiligterId]?.displayName || '';
        const userId = state.beteiligterInfos?.[callInfo.calleeChatBeteiligterId]?.userId || '';

        translateAndLogHandleSignalMessage(msg, dispatch);

        messageStatus = WebRtcCallStatusDict[msg.status];
        switch (msg.status) {
          case WebRtcCallStatus.CANCELED:
            if (callInfo.acceptedButNotEstablished || callInfo.established) {
              if (displayName)
                alertMsg = displayName + ' hat den Anruf abgebrochen.';
              else
                alertMsg = 'Der Anruf wurde abgebrochen';
            } else {
              alertMsg = `Verpasster Anruf von: ${displayName} | ${userId}`
              isMissedCall = true;
            }
            stopCallPayload = { stopCallCause: STOP_CALL_CAUSE.CANCELED }
            break;
          case WebRtcCallStatus.REJECTED:
            if (callInfo.isCaller) {
              alertMsg = 'Ihr Gesprächspartner hat den Anruf nicht angenommen.'
            } else {
              isMissedCall = true;
            }

            stopCallPayload = { stopCallCause: STOP_CALL_CAUSE.REJECTED }
            break
          case WebRtcCallStatus.DROPOUT:
            alertMsg = 'Die Verbindung ist abgebrochen. Ihr Anruf wurde beendet.'
            stopCallPayload = { stopCallCause: STOP_CALL_CAUSE.CANCELED }
            break
          case WebRtcCallStatus.FINISHED:
            alertMsg = ''
            stopCallPayload = { stopCallCause: STOP_CALL_CAUSE.HANG_UP_REMOTE }
            break
          case WebRtcCallStatus.BUSY:
            alertMsg = 'Ihr Gesprächspartner ist derzeit nicht erreichbar. Bitte versuchen Sie es später nochmal.'
            stopCallPayload = { stopCallCause: STOP_CALL_CAUSE.BUSY }
            break
          case WebRtcCallStatus.ERROR:
            alertMsg = 'Es ist ein unerwarteter Fehler aufgetreten. Ihr Anruf wurde beendet.'
            break
          case WebRtcCallStatus.ESTABLISHED:
            stopCallPayload = { stopCallCause: STOP_CALL_CAUSE.HANG_UP_REMOTE }
            break
          default:
            dispatch(LOG_TYPES.ACTIONS.WARN, `Received unexpected WebSocketCloseReasonPhrase status: ${msg.status} => ${messageStatus}`);
        }
      }
    }
    else {
      dispatch(LOG_TYPES.ACTIONS.ERROR, "signal socket closed unexpectedly");
      alertMsg = 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut'
    }
    commit(WEBRTC_TYPES.MUTATIONS.END_CALL, {
      callId: callInfo.callId, 
      alertMsg: alertMsg || '',
      isMissedCall,
      messageStatus,
    });
    
    // Close call modal at the end of a call even if the user had opened manually
    // close when the server closes the call
    if (callInfo.isCallee) {
      commit(WEBRTC_TYPES.MUTATIONS.TOGGLE_CALL_MODAL);
    }
  } catch (error) {
    // empty block
  } finally {
    // Return the beteiligter to the proper Berater after the call is ended
    if (getters[CORE_TYPES.GETTERS.HAS_ROLES](VIEW_ROLES.VIEW_CUSTOMER_ONLY)) {
      const { calleeChatBeteiligterId, calleeChatBeteiligterAvailable } = getters[CORE_TYPES.GETTERS.GET_LOGIN_DATA];
      dispatch(WEBRTC_TYPES.ACTIONS.UPDATE_AVAILABILITY, {
        calleeChatBeteiligterId,
        available: calleeChatBeteiligterAvailable,
      });
    }
    dispatch(BRIDGE_TYPES.ACTIONS.STOP_CALL, stopCallPayload)
  }
}

export async function setupSignalingSocket({ callInfo, commit, dispatch, state, getters }) {
  if (callInfo.socket) return; // ignores when it is already setup

  dispatch(LOG_TYPES.ACTIONS.INFO, { message: "setupSignalingSocket called" });

  callInfo.socket = new WebSocket(callInfo.socketUrl);

  callInfo.socket.onopen = () => onOpen({ callInfo, commit, dispatch });
  callInfo.socket.onmessage = (event) => onMessage({ event, callInfo, dispatch, getters, commit, state });
  callInfo.socket.onerror = (event) => onError({ event, dispatch });
  callInfo.socket.onclose = (event) => onClose({ event, callInfo, dispatch, state, commit, getters }); 

}

export function closeSignalingSocket(callInfo, data) {
  if (callInfo.socket) {
    callInfo.socket.onopen = null;
    callInfo.socket.onmessage = null;
    callInfo.socket.onclose = null;
    callInfo.socket.close(1000, data);
  }
}
