var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [_c("FondsFinderSuchMaske")], 1)
    ]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("FondsFinderPositionsList", {
            attrs: {
              addPositionsOnBack: _vm.addPositionsOnBack,
              goBackEnabled: _vm.goBackEnabled,
              showMobileSelectionAlways: ""
            },
            on: {
              goBack: function($event) {
                return _vm.goBack($event, true)
              }
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }