import STOPPUHR_TYPES from './types';
import { getInitialState } from './index'
import Vue from "vue";

export default {
  [STOPPUHR_TYPES.MUTATIONS.INIT_STOPPUHR_SUCCESS](state, payload) {
    Vue.set(state, 'stoppuhr', payload);
  }, 
  [STOPPUHR_TYPES.MUTATIONS.SET_STOPPUHR_STATE](state, payload) {
    Vue.set(state, 'isStoppuhrRunning', payload?.running);
  }, 
  [STOPPUHR_TYPES.MUTATIONS.SET_STOPPUHR_HOUR](state, payload) {
    state.stoppuhrHour = payload?.hour;
  }, 
  [STOPPUHR_TYPES.MUTATIONS.SET_STOPPUHR_MINUTES](state, payload) {
    state.stoppuhrMinutes = payload?.minutes;
  }, 
  [STOPPUHR_TYPES.MUTATIONS.SET_STOPPUHR_SECONDS](state, payload) {
    Vue.set(state, 'stoppuhSeconds', payload?.seconds);
  }, 
  [STOPPUHR_TYPES.MUTATIONS.STORE_STOPUHR_FORM](state, payload) {
    Vue.set(state, 'stoppuhrForm', payload);
  },
  [STOPPUHR_TYPES.MUTATIONS.RESET_STOPUHR_FORM](state, payload) {
    state.stoppuhrForm = {};
    state.listInterval.length = 0;
    state.currentInterval = {};
  }, 
  [STOPPUHR_TYPES.MUTATIONS.SET_INTERVAL_ID](state, payload) {
    Vue.set(state, 'stoppuhrIntervalId', payload?.intervalId);
  },
  [STOPPUHR_TYPES.MUTATIONS.SET_TIME_LAPSED](state, payload) {
    Vue.set(state, 'timeLapsed', payload);
  },  
  [STOPPUHR_TYPES.MUTATIONS.PROCESS_FORM_CHANGE](state, payload) {
    Vue.set(state.stoppuhrForm, payload?.field, payload?.content);
  },   
  [STOPPUHR_TYPES.MUTATIONS.SET_CURRENT_INTERVAL](state, payload) {
    Vue.set(state, 'currentInterval', payload);
  },
  [STOPPUHR_TYPES.MUTATIONS.UPDATE_LIST_INTERVAL](state, payload) {
    state.listInterval.push(payload);
  },   
  
  
  
  
  
  
}