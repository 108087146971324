var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "autocomplete" },
    [
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.onChange(true)
            }
          }
        },
        [
          _c("InputField", {
            attrs: {
              type: "text",
              label: _vm.label,
              disabled: _vm.disabled,
              placeholder: _vm.placeholder,
              autofocus: ""
            },
            on: {
              input: function($event) {
                return _vm.onChange(false)
              },
              keydown: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.onChange(true)
              }
            },
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          })
        ],
        1
      ),
      _c("SlideInInformation", { ref: "slideInInfo" }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showResults,
              expression: "showResults"
            }
          ],
          staticClass: "results-container"
        },
        [
          _c("AutocompleteResult", {
            attrs: { title: "Formulare", results: _vm.resultsForms },
            on: {
              click: function($event) {
                _vm.navigateToForm($event)
                _vm.closeModal()
              }
            }
          }),
          _c("AutocompleteResult", {
            attrs: {
              title: "Kunden",
              results: _vm.resultsCustomers,
              loading: _vm.isLoading,
              navigationType: "NewWindow",
              showAdvancedSearch: ""
            },
            on: {
              click: function($event) {
                return _vm.setResult($event)
              },
              advancedSearch: function($event) {
                return _vm.setSearch()
              }
            }
          }),
          _vm.isCompaniesEnabled
            ? _c("AutocompleteResult", {
                attrs: {
                  title: "Gesellschaften",
                  results: _vm.resultsCompany,
                  loading: _vm.isLoading
                },
                on: {
                  click: function($event) {
                    _vm.navigateToCompany(
                      $event.gesellschaftId,
                      $event.gesellschaftArt
                    )
                    _vm.closeModal()
                  }
                }
              })
            : _vm._e(),
          _c("AutocompleteResult", {
            attrs: {
              title: "Fonds",
              results: _vm.resultsFonds,
              loading: _vm.isLoading
            },
            on: {
              click: function($event) {
                _vm.openFonds($event)
                _vm.closeModal()
              }
            }
          }),
          _c("AutocompleteResult", {
            attrs: {
              title: "Makler",
              results: _vm.resultsBrokers,
              navigationType: "NewWindow",
              loading: _vm.isLoading
            },
            on: {
              click: function($event) {
                _vm.openBroker($event.brokerId)
                _vm.closeModal()
              }
            }
          }),
          _c("AutocompleteResult", {
            attrs: {
              title: "Versicherungen",
              results: _vm.resultsInsurances,
              loading: _vm.isLoading,
              showAdvancedSearch: ""
            },
            on: {
              click: function($event) {
                _vm.navigateToVersicherungen($event)
                _vm.closeModal()
              },
              advancedSearch: function($event) {
                return _vm.navigateTo("/home/versicherungen/insurance-group")
              }
            }
          }),
          _c("AutocompleteResult", {
            attrs: {
              title: "Depots",
              results: _vm.resultsDepots,
              navigationType: "NewWindow",
              loading: _vm.isLoading
            },
            on: {
              click: function($event) {
                _vm.openDepots($event)
                _vm.closeModal()
              }
            }
          }),
          _c("AutocompleteResult", {
            attrs: {
              title: "Kundendaten",
              results: _vm.resultsCustomerData,
              navigationType: "NewWindow",
              loading: _vm.isLoading
            },
            on: {
              click: function($event) {
                return _vm.selectCustomerData($event.data)
              }
            }
          }),
          _c("AutocompleteResult", {
            attrs: {
              title: "Kundendaten Suchoptionen",
              results: _vm.resultsCustomerSearchCommands,
              navigationType: "NewWindow",
              loading: _vm.isLoading
            },
            on: {
              click: function($event) {
                return _vm.addSearchTermToInputField($event)
              }
            }
          }),
          _c("AutocompleteResult", {
            attrs: {
              title: "Unterdepots",
              results: _vm.resultsUnterdepots,
              navigationType: "NewWindow",
              loading: _vm.isLoading
            },
            on: {
              click: function($event) {
                _vm.openUnterdepots($event)
                _vm.closeModal()
              }
            }
          }),
          _c("AutocompleteResult", {
            attrs: {
              title: "Kalender",
              results: _vm.resultsCalendar,
              loading: _vm.isLoading
            },
            on: {
              click: function($event) {
                _vm.openAppointment($event.appointment.sid)
                _vm.closeModal()
              }
            }
          }),
          _c("AutocompleteResult", {
            attrs: {
              title: "Exception",
              results: _vm.resultsExceptions,
              loading: _vm.isLoading
            },
            on: {
              click: function($event) {
                _vm.openException($event.id)
                _vm.closeModal()
              }
            }
          }),
          _c("AutocompleteResult", {
            attrs: { title: "Navigation", results: _vm.resultsRoutes },
            on: {
              click: function($event) {
                _vm.selectNavigation($event.path)
                _vm.closeModal()
              }
            }
          }),
          _vm.showResults &&
          _vm.isLoading.length === 0 &&
          _vm.noResult &&
          _vm.hasSearch
            ? _c("div", { staticClass: "results-container--no-results" }, [
                _vm._v(" Keine Treffer! ")
              ])
            : _vm._e(),
          _vm.isLoading.length !== 0
            ? _c("div", { staticClass: "results-container--loading" }, [
                _c(
                  "div",
                  { staticClass: "loading" },
                  [_c("AnimatedSpinner"), _vm._v(" Suche läuft... ")],
                  1
                )
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }