var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("BaseModal", {
        ref: "noSignoFilesModal",
        attrs: { labelButtonCancel: "Zurück", showConfirmButton: false },
        on: {
          onCloseButton: function($event) {
            return _vm.closeModal()
          },
          onCancelButton: _vm.closeModal
        },
        scopedSlots: _vm._u([
          {
            key: "modalTitle",
            fn: function() {
              return [_c("b", [_vm._v(_vm._s(_vm.message))])]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _c("p", [
                  _vm._v("Sie können jedoch das Dokument herunterladen")
                ]),
                _c("div", { staticClass: "grid" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col" }, [
                      _c(
                        "a",
                        {
                          on: {
                            click: function($event) {
                              return _vm.openDocument()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.fileName))]
                      )
                    ])
                  ])
                ])
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("PictureAttachmentsModal", {
        ref: "pictureAttachmentsModal",
        attrs: { selectedDoc: _vm.selectedDoc }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }