<template>

  <div>
    <PageHeaderTitleNavigation
      :title="$appNavigation.currentMenu.parent.label"
      :subtitle="$appNavigation.currentMenu.label"
      :defaultMenu="$appNavigation.currentTabMenu"
    />

    <div class="box__container" tid="958f96aa-e0a6-4f21-8f14-1255805511c1">
      <InputRadioBoxGroup 
        title="Typ"
        v-model="listingType"
        value="listingType" 
        :values="listingTypes"
        labelClass="font-bold"
        @input="retrieveData($event)" />    
    </div>

    <div class="box__container" tid="3c197cb4-0331-425e-9a45-454239452fa8">
      <p v-if="listingType=='Versicherungen'">Bitte beachten Sie, dass von der Struktur geerbte Vermittlernummern nur sichtbar sind, wenn Sie den Makler öffnen und seine individuelle Liste anzeigen lassen.</p>

      <Table
        tableId="f075f490-577a-4b51-898d-787c1606a398"
        :title="moduleTitle"
        rowId="rowIndex"
        class="table-container"
        v-if="!loading && tableRows.length"
        :headers="tableHeaders"
        scrollHorizontally
        :rows="tableRows"
        :rowsPerPage="20"
        :count="resultCount"
        :exportConfig="exportConfig"
        @action="executeAction($event)"
      >
        
      </Table>
      <GhostLoading v-else-if="loading" type="table" :title="moduleTitle" />
      <NoData v-else :title="moduleTitle" />
    </div>    

  </div>
    
</template>

<script>
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import VERMITTLERNUMMERN_TYPES from "../../store/vermittlernummern/types";
import { mapGetters } from "vuex";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn} from "@/components/table2/table_util.js";
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';

export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    Table,
    PageHeaderTitleNavigation,
    GhostLoading,
    NoData,
    InputRadioBoxGroup,
    OptionMenu,
  },
  data: function () {
      return {
        resultCount: null,
        loading: true,
        listingType: 'Fondsgesellschaft',
        listingTypes: [
          {
            value: 'Fondsgesellschaft', 
            label: 'Fondsgesellschaften'
          },
          {
            value: 'Geschlossene Fonds', 
            label: 'Beteiligungen'
          },           
          {
            value: 'Bank', 
            label: 'Banken/Plattformen'
          },    
          {
            value: 'Versicherungen', 
            label: 'Versicherungen'
          },                       
        ]
      }
  },
  computed: {
    ...mapGetters({
      alleMitarbeiterData: VERMITTLERNUMMERN_TYPES.GETTERS.ALLE_MITARBEITER_DATA,
    }),
    exportConfig() {
      return {
        roottext: this.moduleTitle,
      }
    },
    tableHeaders() {
      const lockedLeft = [];  
      const center = [];  

      const firstRowValues = this.alleMitarbeiterData[0]?.values;

      lockedLeft.push(TextColumn("gesellschaftsname", "Gesellschaft").makeAlwaysVisible());

      firstRowValues.forEach(fr => {
          center.push(TextColumn(fr.key, fr.key));
      });

      return {
        lockedLeft,
        center,
      };      
    },       
    tableRows () {
      if (!this.alleMitarbeiterData?.length ) {
        return [];
      }

      const result = [];
      this.alleMitarbeiterData.forEach((am, index) => {
        const row = {gesellschaftsname : am.gesellschaftsname};

        am.values.forEach(v => {
            row[v.key] = v.value;
        });

        row.rowIndex = index;

        result.push(row);
      })

      return result;
    },
    moduleTitle() {
        return this.listingTypes.find(lt => lt.value == this.listingType)?.label;

    },
  },
  mounted() {
    this.retrieveData();  
  },
  methods: {
    retrieveData(param) {
      this.loading = true;

      /**
       * Possible values for gesellschaftsArtParam are: 'Fondsgesellschaft', 'Bank', 'Geschlossene Fonds', 'Versicherungen'
       */

      const gesellschaftsArtParam = param || 'Fondsgesellschaft' 

      this.$store.dispatch(
              VERMITTLERNUMMERN_TYPES.ACTIONS.GET_ALLE_MITARBEITER_DATA, {gesellschaftsArtParam}
            ).then(() => {
                this.loading = false;
            });  

    },
  },
  async beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu Vermittlernummern für alle Mitarbeiter Übersicht', 
      fullPath: from.fullPath,
      breadcrumb: to?.meta?.breadcrumb?.label,
    });

    this.$store.commit(VERMITTLERNUMMERN_TYPES.MUTATIONS.RESET_LIST_ALLE_MITARBEITER);

    next();
  },


    
}
</script>