var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cards-organizer" },
    [
      !_vm.localData || !_vm.localData.length
        ? _c(
            "div",
            { staticClass: "column" },
            [_c("GhostLoading", { attrs: { type: "table" } })],
            1
          )
        : _vm._e(),
      !_vm.localData || !_vm.localData.length
        ? _c(
            "div",
            { staticClass: "column" },
            [_c("GhostLoading", { attrs: { type: "table" } })],
            1
          )
        : _vm._e(),
      !_vm.localData || !_vm.localData.length
        ? _c(
            "div",
            { staticClass: "column" },
            [_c("GhostLoading", { attrs: { type: "table" } })],
            1
          )
        : _vm._e(),
      _vm._l(_vm.localData, function(column, columnIndex) {
        return _c(
          "div",
          {
            key: columnIndex,
            staticClass: "column",
            class: { dragging: _vm.isDragging },
            on: {
              dragover: function($event) {
                $event.preventDefault()
              },
              dragenter: function($event) {
                return _vm.dragEnterColumn($event, columnIndex, -1)
              },
              drop: function($event) {
                return _vm.dropCard($event, columnIndex, -1)
              }
            }
          },
          [
            _vm._l(_vm.getVisibleCards(column), function(card, cardIndex) {
              return _c("Card", {
                key: card.id,
                attrs: {
                  columnIndex: columnIndex,
                  cardIndex: _vm.getCardIndex(columnIndex, cardIndex),
                  card: card,
                  actions: card.actions
                },
                on: {
                  dragstart: function($event) {
                    _vm.dragCard(
                      $event,
                      columnIndex,
                      _vm.getCardIndex(columnIndex, cardIndex)
                    )
                  },
                  dragenter: function($event) {
                    $event.preventDefault()
                    _vm.dragEnterCard(
                      $event,
                      columnIndex,
                      _vm.getCardIndex(columnIndex, cardIndex)
                    )
                  },
                  dragover: function($event) {
                    $event.preventDefault()
                    return _vm.allowDrop($event)
                  },
                  drop: function($event) {
                    _vm.dropCard(
                      $event,
                      columnIndex,
                      _vm.getCardIndex(columnIndex, cardIndex)
                    )
                  },
                  executeAction: function($event) {
                    return _vm.$emit("executeAction", $event)
                  },
                  remove: _vm.removeCard
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function() {
                        return [_vm._t("card", null, { card: card })]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  true
                )
              })
            }),
            _vm.placeholder &&
            _vm.placeholder.columnIndex === columnIndex &&
            _vm.placeholder.cardIndex === -1
              ? _c("div", {
                  staticClass: "placeholder",
                  style: _vm.placeholderStyle
                })
              : _vm._e()
          ],
          2
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }