var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("div", { staticClass: "box__title" }, [
          _vm._v("Abrechnungskonto für Einmalanlage")
        ]),
        _c("FormLabel", {
          attrs: {
            label:
              "Das Abrechnungskonto ist das Verrechnungskonto. Sie haben hier die Möglichkeit, eine Überweisung vom KONTO plus auf das Verrechnungskonto anzuweisen."
          }
        }),
        _c("ComboBox", {
          attrs: {
            label: "Verwende Konto plus, IBAN",
            values: _vm.comboboxValues
              ? _vm.comboboxValues.INPUT_CAPITALBANK_COMBO_KONTOPLUS_KAUF
              : [],
            disabled: !_vm.hasKauf
          },
          on: {
            change: function($event) {
              return _vm.updateStoreData({
                componentId: "INPUT_CAPITALBANK_COMBO_KONTOPLUS_KAUF",
                value: $event
              })
            }
          },
          model: {
            value: _vm.INPUT_CAPITALBANK_COMBO_KONTOPLUS_KAUF,
            callback: function($$v) {
              _vm.INPUT_CAPITALBANK_COMBO_KONTOPLUS_KAUF = $$v
            },
            expression: "INPUT_CAPITALBANK_COMBO_KONTOPLUS_KAUF"
          }
        }),
        _c("InputIbanBic", {
          attrs: {
            componentData: _vm.INPUT_CAPITALBANK_TEXT_KONTOPLUS_KAUF,
            validation_path: "INPUT_CAPITALBANK_TEXT_KONTOPLUS_KAUF",
            label: "Freie Eingabe",
            type: "IBAN",
            disabled: !(
              _vm.hasKauf &&
              _vm.INPUT_CAPITALBANK_COMBO_KONTOPLUS_KAUF === _vm.FREIE_EINGABE
            )
          },
          on: {
            change: function($event) {
              return _vm.updateStoreData({
                componentId: "INPUT_CAPITALBANK_TEXT_KONTOPLUS_KAUF",
                value: $event
              })
            }
          }
        }),
        _c("InputField", {
          attrs: { label: "Betrag", type: "currency", disabled: !_vm.hasKauf },
          on: {
            change: function($event) {
              _vm.updateStoreData({
                componentId: "INPUT_CAPITALBANK_BETRAG_KONTOPLUS",
                value: _vm.convertValueToForm($event)
              })
            }
          },
          model: {
            value: _vm.INPUT_CAPITALBANK_BETRAG_KONTOPLUS,
            callback: function($$v) {
              _vm.INPUT_CAPITALBANK_BETRAG_KONTOPLUS = $$v
            },
            expression: "INPUT_CAPITALBANK_BETRAG_KONTOPLUS"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("div", { staticClass: "box__title" }, [
          _vm._v("Abrechnungskonto für Verkauf")
        ]),
        _c("InputRadioBoxGroup", {
          attrs: {
            validateUntouched: true,
            disabled: !_vm.hasVerkauf,
            values: _vm.valuesBank
          },
          on: {
            input: function($event) {
              return _vm.updateStoreData({
                componentId: "INPUT_CB_BANK_VERKAUF",
                value: $event
              })
            }
          },
          model: {
            value: _vm.INPUT_CB_BANK_VERKAUF,
            callback: function($$v) {
              _vm.INPUT_CB_BANK_VERKAUF = $$v
            },
            expression: "INPUT_CB_BANK_VERKAUF"
          }
        }),
        _c("ComboBox", {
          attrs: {
            label: "Verwende Konto plus, IBAN",
            values: _vm.comboboxValues
              ? _vm.comboboxValues.INPUT_CAPITALBANK_COMBO_KONTOPLUS_VERKAUF
              : [],
            disabled: !(
              _vm.hasVerkauf &&
              _vm.data.INPUT_CB_BANK_VERKAUF === "kontoplus_GRAWE"
            )
          },
          on: {
            change: function($event) {
              return _vm.updateStoreData({
                componentId: "INPUT_CAPITALBANK_COMBO_KONTOPLUS_VERKAUF",
                value: $event
              })
            }
          },
          model: {
            value: _vm.INPUT_CAPITALBANK_COMBO_KONTOPLUS_VERKAUF,
            callback: function($$v) {
              _vm.INPUT_CAPITALBANK_COMBO_KONTOPLUS_VERKAUF = $$v
            },
            expression: "INPUT_CAPITALBANK_COMBO_KONTOPLUS_VERKAUF"
          }
        }),
        _c("InputIbanBic", {
          attrs: {
            componentData: _vm.INPUT_CAPITALBANK_TEXT_KONTOPLUS_VERKAUF,
            validation_path: "INPUT_CAPITALBANK_TEXT_KONTOPLUS_VERKAUF",
            label: "Freie Eingabe",
            type: "IBAN",
            disabled: !(
              _vm.hasVerkauf &&
              _vm.data.INPUT_CB_BANK_VERKAUF === "kontoplus_GRAWE" &&
              _vm.data.INPUT_CAPITALBANK_COMBO_KONTOPLUS_VERKAUF ===
                _vm.FREIE_EINGABE
            )
          },
          on: {
            change: function($event) {
              return _vm.updateStoreData({
                componentId: "INPUT_CAPITALBANK_TEXT_KONTOPLUS_VERKAUF",
                value: $event
              })
            }
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("div", { staticClass: "box__title" }, [
          _vm._v("Abrechnungskonto für Vermögensaufbauplan")
        ]),
        _c("InputRadioBoxGroup", {
          attrs: {
            validateUntouched: true,
            disabled: !_vm.hasSparplan,
            values: _vm.valuesBank
          },
          on: {
            input: function($event) {
              return _vm.updateStoreData({
                componentId: "INPUT_CB_BANK_SPARPLAN",
                value: $event
              })
            }
          },
          model: {
            value: _vm.INPUT_CB_BANK_SPARPLAN,
            callback: function($$v) {
              _vm.INPUT_CB_BANK_SPARPLAN = $$v
            },
            expression: "INPUT_CB_BANK_SPARPLAN"
          }
        }),
        _c("ComboBox", {
          attrs: {
            label: "Verwende Konto plus, IBAN",
            values: _vm.comboboxValues
              ? _vm.comboboxValues
                  .INPUT_CAPITALBANK_COMBO_KONTOPLUS_VERKAUF_SPARPLAN
              : [],
            disabled: !(
              _vm.hasSparplan &&
              _vm.data.INPUT_CB_BANK_SPARPLAN === "kontoplus_GRAWE"
            )
          },
          on: {
            change: function($event) {
              return _vm.updateStoreData({
                componentId:
                  "INPUT_CAPITALBANK_COMBO_KONTOPLUS_VERKAUF_SPARPLAN",
                value: $event
              })
            }
          },
          model: {
            value: _vm.INPUT_CAPITALBANK_COMBO_KONTOPLUS_VERKAUF_SPARPLAN,
            callback: function($$v) {
              _vm.INPUT_CAPITALBANK_COMBO_KONTOPLUS_VERKAUF_SPARPLAN = $$v
            },
            expression: "INPUT_CAPITALBANK_COMBO_KONTOPLUS_VERKAUF_SPARPLAN"
          }
        }),
        _c("InputIbanBic", {
          attrs: {
            value: _vm.INPUT_CAPITALBANK_TEXT_KONTOPLUS_SPARPLAN,
            validation_path: "INPUT_CAPITALBANK_TEXT_KONTOPLUS_SPARPLAN",
            label: "Freie Eingabe",
            type: "IBAN",
            componentData: _vm.INPUT_CAPITALBANK_TEXT_KONTOPLUS_SPARPLAN,
            disabled: !(
              _vm.hasSparplan &&
              _vm.data.INPUT_CB_BANK_SPARPLAN === "kontoplus_GRAWE" &&
              _vm.data.INPUT_CAPITALBANK_COMBO_KONTOPLUS_VERKAUF_SPARPLAN ===
                _vm.FREIE_EINGABE
            )
          },
          on: {
            change: function($event) {
              return _vm.updateStoreData({
                componentId: "INPUT_CAPITALBANK_TEXT_KONTOPLUS_SPARPLAN",
                value: $event
              })
            }
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("div", { staticClass: "box__title" }, [
          _vm._v("Optionen für Verkäufe")
        ]),
        _c("FormLabel", {
          attrs: {
            label:
              "Für den Fall, dass ich nur einen Teil meiner Bestände verkaufen möchte und sich diese in einen Bestand, der vor dem 01.01.2011 angeschafft wurde (Altbestand) und einen, der ab 01.01.2011 angeschafft wurde (Neubestand) aufteilen, gebe ich den Auftrag, zuerst den Altbestand zu veräußern."
          }
        }),
        _c("InputToggleSwitch", {
          attrs: {
            label:
              "Abweichend vom Obigen gebe ich den Auftrag, zuerst meinen Neubestand zu verkaufen.",
            inLineLabel: "",
            disabled: !_vm.hasVerkauf
          },
          on: {
            input: function($event) {
              return _vm.updateStoreData({
                componentId: "INPUT_VERKAUF_AUFGETEILT",
                value: $event
              })
            }
          },
          model: {
            value: _vm.INPUT_VERKAUF_AUFGETEILT,
            callback: function($$v) {
              _vm.INPUT_VERKAUF_AUFGETEILT = $$v
            },
            expression: "INPUT_VERKAUF_AUFGETEILT"
          }
        }),
        _c("InputToggleSwitch", {
          attrs: {
            label:
              "Ich ersuche Sie, mein Depot und Verrechnungskonto nach den erfolgten Verkäufen zu schließen. (Unterschrift aller Depotinhaber erforderlich!)",
            inLineLabel: "",
            disabled: !_vm.hasVerkauf
          },
          on: {
            input: function($event) {
              return _vm.updateStoreData({
                componentId: "INPUT_CB_BANK_SCHLIESSEN",
                value: $event
              })
            }
          },
          model: {
            value: _vm.INPUT_CB_BANK_SCHLIESSEN,
            callback: function($$v) {
              _vm.INPUT_CB_BANK_SCHLIESSEN = $$v
            },
            expression: "INPUT_CB_BANK_SCHLIESSEN"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }