var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "makler_info" }, [
    _c("h3", { staticStyle: { "font-size": "1.85em" } }, [_vm._v("Kontakt")]),
    _vm.isIntern
      ? _c("div", { staticClass: "fc-alert fc-alert-warning" }, [
          _vm._v("Hier kommen die Kontaktdaten des Maklers.")
        ])
      : _c("div", [
          _vm.getBrokerImage
            ? _c("div", { staticClass: "makler_image" }, [
                _c("img", {
                  staticClass: "broker_image",
                  attrs: {
                    alt: "Makler Bild",
                    src: _vm.getBrokerImage,
                    id: "image"
                  }
                })
              ])
            : _vm._e(),
          _c("div", [
            _c("div", [
              _c("b", { staticStyle: { "font-size": "1.14em" } }, [
                _vm._v(_vm._s(_vm.maklerInfo.officialName))
              ])
            ]),
            _c("div", { staticClass: "two_items_row" }, [
              _c("div", [_c("ph-map-pin", { attrs: { size: 16 } })], 1),
              _c("div", [_vm._v(_vm._s(_vm.maklerInfo.strasse))])
            ]),
            _c("div", { staticClass: "two_items_row_plz" }, [
              _c("div"),
              _c("div", [_vm._v(_vm._s(_vm.maklerInfo.plzOrt))])
            ]),
            _c("div", { staticClass: "two_items_row" }, [
              _c("div", [_c("ph-phone", { attrs: { size: 16 } })], 1),
              _c("div", [_vm._v(_vm._s(_vm.maklerInfo.telefon))])
            ])
          ])
        ]),
    _c("hr", { staticClass: "separator" }),
    _c(
      "div",
      { staticClass: "two_items_row" },
      [
        _c("div", [_c("ph-envelope-simple", { attrs: { size: 16 } })], 1),
        _c("router-link", { attrs: { to: "kontakt" } }, [_vm._v("Kontakt")])
      ],
      1
    ),
    _c("div", { staticClass: "two_items_row" }, [
      _c("div", [_c("ph-power", { attrs: { size: 16 } })], 1),
      _c("div", { staticClass: "home_login_link" }, [
        _c("a", { attrs: { href: _vm.anmeldenUrl } }, [_vm._v("Anmelden")])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }