<template>
  <div :style="style">
    <v-chart class="chart" :option="option" :theme="themeKey" autoresize />
  </div>
</template>

<script>
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { BarChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from 'echarts/components';
import VChart, { INIT_OPTIONS_KEY } from 'vue-echarts';

import echartsMixin from './echarts-mixin';

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  name: 'BarChart',
  mixins: [echartsMixin],
  components: {
    VChart
  },
  provide: {
    [INIT_OPTIONS_KEY]: { locale: 'DE' }
  },
  props: {
    chartData: {
      type: Array,
      default: () => [],
    },
    chartSeries: {
      type: Array,
      default: () => [],
    },
    format: {
      type: String,
      default: 'currency',
    },
    numberFixed: {
      type: Number,
      default: 2,
    },
    height: {
      type: String,
      default: '200px',
    },
    isSum: {
      type: Boolean,
      default: false,
    },
    labels: {
      type: Array,
      default: () => [],
    },
    gridLeft: {
      type: [Number, String],
      default: '15%',
    },
    chartOptions: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  computed: {
    option() {
      const option = {
        type: 'bar',
        name: this.chartDataName,
      };
      const series = (this.chartSeries?.length ? this.chartSeries.map(cs => ({
        ...option,
        ...cs,
      })) : [
        {
          ...option,
          data: this.chartData || [],
          ...(this.isSum ? {label: {
            show: true,
            position: 'top',
            formatter: v => this.formattedValue(this.chartData[v.dataIndex].value),
          }} : {}),
        }
      ]);
      const chartOptions = this.chartOptions || {};
      const containsXandYLabel = (chartOptions.xAxis?.show === undefined || chartOptions.xAxis?.show) &&  (chartOptions.yAxis?.show === undefined || chartOptions.yAxis?.show)
      return {
        color: this.themeColors,
        backgroundColor: 'transparent',
        grid: {
          left: this.gridLeft,
          containLabel: containsXandYLabel
        },
        tooltip: {
          show: this.isTooltip,
          formatter: this.tooltipFormatter,
          position: { left: 'center', top: 'auto'},
        },
        xAxis: chartOptions.xAxis ? chartOptions.xAxis : {
          type: 'category',
          data: this.labels?.length && this.labels || (this.chartData || []).map(cd => cd.name),
          axisLabel: {
            rotate: 30,
            fontSize: 10,
          },
        },
        yAxis: chartOptions.yAxis ? chartOptions.yAxis :  {
          type: 'value',
          axisLabel: {
            formatter: this.axisLabelFormatter(this.axisLabelFormatterShowOption),
            fontSize: 10,
          },
        },
        legend: chartOptions.legend ? chartOptions.legend : {show: this.isLegend},
        series: chartOptions.series ? chartOptions.series : series,
      }
    }
  },
};
</script>
