var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("PageHeaderTitleNavigation", {
    attrs: {
      defaultMenu: _vm.customOptionMenu,
      isSticky: _vm.isSticky,
      actions: _vm.headerActions
    },
    on: {
      "action-ADD-TASK": function($event) {
        return _vm.$emit("addTask")
      },
      "action-ADD-APPOINTMENT": function($event) {
        return _vm.$emit("addAppointment")
      }
    },
    scopedSlots: _vm._u(
      [
        {
          key: "title",
          fn: function() {
            return [_vm._v("Terminkalender")]
          },
          proxy: true
        },
        {
          key: "subtitle",
          fn: function() {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-center align-items-center"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-center align-items-center"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn-clear clickable",
                          attrs: { type: "button", disabled: _vm.disabled },
                          on: {
                            click: function($event) {
                              return _vm.$emit("prev")
                            }
                          }
                        },
                        [_c("PhCaretLeft", { attrs: { size: "20" } })],
                        1
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn-clear mx-1 clickable",
                          attrs: { type: "button", disabled: _vm.disabled },
                          on: {
                            click: function($event) {
                              return _vm.$emit("current")
                            }
                          }
                        },
                        [_vm._v("heute")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn-clear clickable",
                          attrs: { type: "button", disabled: _vm.disabled },
                          on: {
                            click: function($event) {
                              return _vm.$emit("next")
                            }
                          }
                        },
                        [_c("PhCaretRight", { attrs: { size: "20" } })],
                        1
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-center align-items-center ml-2"
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "calendar-navigation__title" },
                        [_vm._v(_vm._s(_vm.title))]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "calendar-navigation__title-shortening"
                        },
                        [_vm._v(_vm._s(_vm.titleShortening || _vm.title))]
                      ),
                      _vm.$slots.calendarDatePicker
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "calendar-navigation__date-picker-dropdown ml-1"
                            },
                            [_vm._t("calendarDatePicker")],
                            2
                          )
                        : _vm._e()
                    ]
                  )
                ]
              )
            ]
          },
          proxy: true
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }