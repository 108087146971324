<template>
  <BaseButton v-if="showLogout" isClear @click="logout">
    <ph-power :size="size" />
  </BaseButton>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import { mapGetters } from 'vuex';
import { PhPower } from 'phosphor-vue'
import { ROLES } from '@/router/roles';
import BaseButton from '@/components/core/BaseButton.vue';

export default {
  props: {
    size: {
      default: 18
    }
  },
  computed: {
    ...mapGetters({
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
    showLogout() {
      return !this.hasRoles(ROLES.HIDE_LOGOUT_BUTTON)
    },
  },
  methods: {
    logout() {
      this.$store.dispatch(CORE_TYPES.ACTIONS.LOGOUT);
    },
  },
  components: {
    PhPower,
    BaseButton,
  }
}
</script>

<style scoped>
.flex-content {
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.flex-content > div {
  cursor: pointer;
}
.logout__container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.anmelden_link {
  color: var(--color-top-nav-text);
  text-decoration: none;
  padding: 9px 20px;
  outline: var(--color-top-nav-background) solid 1px;
}
.logout__container {
  display: none;
}
@media only screen and (max-width: 640px) {
  .anmelden_link {
    display: none;
  }
  .logout__container {
    display: flex;
  }
}
</style>