export const MUTATION_PREFIX = 'RUNDMAILSTATUS_MUTATIONS_';
export const ACTIONS_PREFIX = 'RUNDMAILSTATUS_ACTIONS_';
export const GETTERS_PREFIX = 'RUNDMAILSTATUS_GETTERS_';

export default {
  MUTATIONS: {
    RETRIEVE_DATA_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_DATA_SUCCESS',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
  },
  ACTIONS: {
    RETRIEVE_DATA: ACTIONS_PREFIX + 'RETRIEVE_DATA',
  },
  GETTERS: {
    RUNDMAIL_STATUS_DATA: GETTERS_PREFIX + 'RUNDMAIL_STATUS_DATA',
  }
}