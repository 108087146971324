var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.form, function(field, fieldId, index) {
      return _c("div", { key: index, staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          field.type === "LABEL"
            ? _c(
                "div",
                [_c("form-label", { attrs: { label: field.label } })],
                1
              )
            : _vm._e(),
          field.type === "SWITCHER"
            ? _c(
                "div",
                [
                  _c("InputToggleSwitch", {
                    attrs: {
                      label: field.label,
                      disabled: _vm.disabled,
                      inLineLabel: "",
                      suppressValidationMessage: ""
                    },
                    on: {
                      input: function($event) {
                        return _vm.onChange({
                          value: $event,
                          field: field
                        })
                      }
                    },
                    model: {
                      value: field.value,
                      callback: function($$v) {
                        _vm.$set(field, "value", $$v)
                      },
                      expression: "field.value"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          field.type === "TEXT_CURRENCY"
            ? _c(
                "div",
                [
                  field.id && !field.hidden
                    ? _c("InputField", {
                        attrs: {
                          disabled: field.disabled || _vm.disabled,
                          label: field.label,
                          isComponentHalfSize: "",
                          type: "currency",
                          precision: 2
                        },
                        on: {
                          change: function($event) {
                            return _vm.onChange({
                              value: $event,
                              field: field
                            })
                          }
                        },
                        model: {
                          value: field.value,
                          callback: function($$v) {
                            _vm.$set(field, "value", $$v)
                          },
                          expression: "field.value"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }