import { render, staticRenderFns } from "./EmailNotRecognized.vue?vue&type=template&id=74dddea0&scoped=true&"
import script from "./EmailNotRecognized.vue?vue&type=script&lang=js&"
export * from "./EmailNotRecognized.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74dddea0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("P:\\workspaces\\fk0\\fc\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('74dddea0')) {
      api.createRecord('74dddea0', component.options)
    } else {
      api.reload('74dddea0', component.options)
    }
    module.hot.accept("./EmailNotRecognized.vue?vue&type=template&id=74dddea0&scoped=true&", function () {
      api.rerender('74dddea0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/communication/EmailNotRecognized.vue"
export default component.exports