var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "mb-3" },
        [
          _c("InputField", {
            staticClass: "p-0 mb-1",
            attrs: { label: "Name", isComponentHalfSize: "" },
            model: {
              value: _vm.filter.name,
              callback: function($$v) {
                _vm.$set(_vm.filter, "name", $$v)
              },
              expression: "filter.name"
            }
          }),
          _c("ComboBox", {
            attrs: {
              label: "Anzeige-Optionen",
              values: _vm.anzeigeValues,
              isComponentHalfSize: ""
            },
            model: {
              value: _vm.filter.anzeige,
              callback: function($$v) {
                _vm.$set(_vm.filter, "anzeige", $$v)
              },
              expression: "filter.anzeige"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mb-2" },
        [
          _c(
            "BaseButton",
            {
              attrs: { disabled: _vm.disabled },
              on: {
                click: function($event) {
                  return _vm.selectAll()
                }
              }
            },
            [_vm._v("alle auswählen")]
          ),
          _c(
            "BaseButton",
            {
              attrs: { isSecondary: "", disabled: _vm.disabled },
              on: {
                click: function($event) {
                  return _vm.unselectAll()
                }
              }
            },
            [_vm._v("alle abwählen")]
          )
        ],
        1
      ),
      _c("Table", {
        key: _vm.tableKey,
        class: { "table--disabled": _vm.disabled },
        attrs: {
          headers: _vm.headers,
          rows: _vm.rows,
          rowsPerPage: _vm.ROWS_PER_PAGE
        },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function(row) {
              return [
                _c(
                  "div",
                  { staticClass: "gesell-selection__container" },
                  [
                    row.erstellungsTyp === "AUTOMATISCH"
                      ? _c("InputCheckBoxItem", {
                          attrs: { disabled: _vm.disabled },
                          on: {
                            input: function($event) {
                              return _vm.dataChanged(row.id, $event)
                            }
                          },
                          model: {
                            value: _vm.form[row.id],
                            callback: function($$v) {
                              _vm.$set(_vm.form, row.id, $$v)
                            },
                            expression: "form[row.id]"
                          }
                        })
                      : row.erstellungsTyp === "UNSICHTBAR"
                      ? _c(
                          "button",
                          {
                            staticClass: "clickable btn-clear",
                            attrs: { type: "button", disabled: _vm.disabled },
                            on: {
                              click: function($event) {
                                return _vm.makeGesellschaftVisible(row)
                              }
                            }
                          },
                          [_c("PhArrowFatRight", { attrs: { size: 18 } })],
                          1
                        )
                      : row.erstellungsTyp === "DURCH_MAKLER"
                      ? _c(
                          "button",
                          {
                            staticClass: "clickable btn-clear",
                            attrs: { type: "button", disabled: _vm.disabled },
                            on: {
                              click: function($event) {
                                return _vm.openDeleteGesellschaftConfirmModal(
                                  row
                                )
                              }
                            }
                          },
                          [_c("PhTrashSimple", { attrs: { size: 18 } })],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }