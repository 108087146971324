import ANTRAG_TYPES from '@/store/antrag/types';
import CUSTOMERDATA_TYPES from '@/store/customerData/types';
import { calculateAntragID } from '@/components/antrag/antrag-utils';
import INVESTMENT_ADVICE from "@/store/investmentAdvice/types";

 // the following list will be requested to the backend if it's present on the current query
export const  knownParams = [
  'id',
  'antragsnrIntern',
  'beratungsMappeId',
  'processId',
  'antragsdatenId',
  'protokollId',
  'bachToPreviousFormLink',
  'backToPreviousFormLabel',
  'beratungsVersId',
  'dynamicFormId',
  'ffbDepoteroeffnungId',
  'reloadTrigger',
  'reloadTriggerValue',
  'bgsNr',
  'action',
];

const mixin = {
  methods: {
    getPositionsÜbertragung(positionData, anzahlFonds = 4) {
        const positionList = [];
        if (positionData) {
            for (let i = 1; i <= anzahlFonds; i++) {
              if (positionData && positionData["isin" + i]) {
                positionList.push({
                isin: positionData["isin" + i],
                name: positionData["fondsname" + i],
                anteile: positionData["anteile" + i],
                alle: positionData["alle" + i],
                });
              }}
        }
        return positionList;
    },
    setPositionsÜbertragung(positionList = [], anzahlFonds = 4) {
      const positionData = {};
      for (let i = 1; i <= anzahlFonds; i++) {
        positionData["isin" + i] = positionList.length >= i ? positionList[i-1].isin : null;
        positionData["fondsname" + i] = positionList.length >= i ? positionList[i-1].name : null;
        positionData["anteile" + i] = positionList.length >= i ? positionList[i-1].anteile : null;
        positionData["alle" + i] = positionList.length >= i ? positionList[i-1].alle : null;
      }
      return positionData;
    },
    deletePosition(antragId, categoryId, pos) {
      if (pos) {
        const deletePayload = {
          id: antragId,
          categoryId: categoryId,
          isin: pos.isin,
          posGrpId: pos.posGrpId,
        }
        this.$store.dispatch(ANTRAG_TYPES.ACTIONS.DELETE_POSITION, deletePayload);
      }
    },
    warningsPositionUebertragung(antrag, categoryId) {
      return categoryId && antrag && antrag.warnings && antrag.warnings.positionWarnings
        && antrag.warnings.positionWarnings.filter(warn => warn.id && warn.id.includes(categoryId));
    },
    warningsPositionUebertragungEditor(warnings, form) {
      if (warnings && warnings.length && form) {
        const errors = [];
        warnings.forEach(warn => {
          if (warn.id) {
            const warnId = warn.id.split('§§').pop();
            if (Object.keys(form).some(key => key === warnId)) {
              errors.push({ id: 'form.' + warnId, message: warn.message });
            }
          }
        })
        return errors;
      }
    },
    getWarningStepKey(warning, components) {
      if (warning && warning.id) {
        const warningId = warning.id.includes('§§') ? warning.id.split('§§')[0] : warning.id;
        for (const key of Object.keys(components)) {
          const componentList = components[key];
          if (componentList.find(comp => comp.id === warningId || comp.config?.validationMessageID === warningId)) {
            return key;
          }

          const flatternComponent = componentList.flatMap(component => ( (component.config && component.config.positionFields) || []))

          if (flatternComponent.find(comp => comp.id === warningId || comp.config?.validationMessageID === warningId)) {
            return key;
          }
        }
      }
      return null;
    },
    getFondsName(pos, positionInfo = {}) {
      if (pos) {
        return positionInfo[pos.isin] && positionInfo[pos.isin].wertpapiername || pos.fondsname || pos.wertpapiername || pos.fondsName || '';
      }
      return '';
    },
    loadAntrag(data = {} ) { //lagerstelle, antragsName, depoteroeffnungInvestmentAdvice) {
      if (!data.lagerstelle || !data.antragsName) {
        return;
      }

      const routeQuery = this.$route.query || {};

      const payload = knownParams.reduce((accu, curr) => {
        routeQuery[curr] ? accu[curr] = routeQuery[curr] : ''
        return accu;
      }, {})

      payload.lagerstelle = data.lagerstelle, 
      payload.antragsName = data.antragsName,
      payload.id = calculateAntragID(payload);
      if (data.forceReload) {
        payload.forceReload = data.forceReload;
      }
      if (this.$route.query.investementAdviceReferrer && data.depoteroeffnungInvestmentAdvice) {
        if (process.env.NODE_ENV === 'development')
          console.log('Antrag loadData ' + data.depoteroeffnungInvestmentAdvice.posBesitzerId + ' ' + this.$route.query.gobacklink);
        payload.importPosBesitzer = data.depoteroeffnungInvestmentAdvice.posBesitzerId;
        payload.importPosBesitzerVerkauf = data.depoteroeffnungInvestmentAdvice.posBesitzerIdVerkauf;
        this.$store.commit(INVESTMENT_ADVICE.MUTATIONS.SET_INVESTMENT_ADVICE_DEPOTEROEFFNUNG_SUCCESS, {});     
      }

      this.$store.dispatch(ANTRAG_TYPES.ACTIONS.GET_ANTRAG, payload)
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.SET_REQUESTED_EBASE_DEPOTNUMMER, {
        id: payload.id,
        data: {},
      });
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.SET_REQUESTED_EBASE_AAB_ANLAGESTRATEGIEN, {
        id: payload.id,
        data: {},
      });
    },
    setBeadcrumbs() {
      const to = this.$route.path
      const from = this.$route.fullPath;
      if (to && from) {
        this.$addBreadcrumb({
          label: 'zurück zum Antrag', 
          to,
          from,
        });
      }
    },
    editPerson(personId) {
      if (personId) {
        this.setBeadcrumbs();

        this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.UPDATE_SELECTED_CUSTOMER_DATA, personId);
        this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GOTO_CUSTOMER_DATA);
      }
  },
  addPositionCBAenderingVermoegensplaene(posInfo,typ){
    let pos = {};
    if(posInfo.label){
      pos = Object.assign({},{
      fondsName: posInfo.label.split(" - ISIN:")[0],
      isin: posInfo.value,
      lagerstelle: "CAPITALBANK",
      aenderungTyp : typ,
    });
    }else{
      pos = Object.assign({},{
      fondsName: posInfo.split(";")[1],
      isin: posInfo.split(";")[0],
      lagerstelle: "CAPITALBANK",
      aenderungTyp : typ,
    });
    }
    return pos;
  }
 
  },
     
}

export default mixin;