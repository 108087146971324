<template>
  <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" width="861.20225" height="633.85668"
    viewBox="0 0 861.20225 633.85668" xmlns:xlink="http://www.w3.org/1999/xlink">
    <rect y="602.01062" width="606.0958" height="2" :fill="secondColor" />
    <path
      d="M357.3271,236.37617h-5.55362l-23.60291,85.61838s-.4628,56.92465,5.09082,84.69277c0,0,9.25605-12.03285,15.73527-12.03285s12.49566-60.62707,12.49566-60.62707S386.94643,247.48342,357.3271,236.37617Z"
      transform="translate(-169.39887 -133.07166)" :fill="secondColor" />
    <path
      d="M479.50684,400.20809s-14.80967,41.65219-.92561,44.429,21.2889-32.39615,17.58648-37.02417S479.50684,400.20809,479.50684,400.20809Z"
      transform="translate(-169.39887 -133.07166)" fill="#feb8b8" />
    <path
      d="M331.75559,391.60278s4.1631,44.01023,17.92121,40.66475,5.61859-38.35568.30823-40.98753S331.75559,391.60278,331.75559,391.60278Z"
      transform="translate(-169.39887 -133.07166)" fill="#feb8b8" />
    <path
      d="M367.50875,649.19561s6.47922,31.47054-4.628,39.801,3.70242,25.91691,3.70242,25.91691l35.173-37.02416s-14.80967-23.1401-13.88407-37.02416Z"
      transform="translate(-169.39887 -133.07166)" fill="#feb8b8" />
    <polygon points="253.646 544.818 249.944 569.809 249.018 587.395 269.381 585.544 272.158 549.446 253.646 544.818"
      fill="#feb8b8" />
    <path
      d="M344.6764,396.01372s-11.41495,4.19437-10.48934,16.22723,3.70241,49.98264,3.70241,49.98264-8.33044,81.45316,0,109.22128,24.06571,83.30437,24.06571,83.30437,24.0657,4.628,29.61933-12.95846c0,0-.92561-60.16426-11.10725-73.12272s-2.77681-16.66087-1.85121-18.51208,3.70242-4.628,3.70242-7.40483.9256-12.95846.9256-16.66088-.9256-2.77681.9256-8.33043,3.70242-7.40483,3.70242-8.33044,15.73527-49.98262,15.73527-49.98262l20.36329,113.8493s-4.628,111.99809-2.77681,111.99809,22.21449,7.40484,29.61933,0c0,0,18.51208-96.26282,14.80966-101.81644s-4.628-6.47923-3.70241-12.03286,2.77681-8.33043.9256-11.10725-4.628-12.03285-1.85121-14.80966,3.70242-15.73527,3.70242-15.73527,15.73527-90.7092,5.55362-103.66765S344.6764,396.01372,344.6764,396.01372Z"
      transform="translate(-169.39887 -133.07166)" fill="#2f2e41" />
    <path
      d="M375.83918,689.92219s-11.10725-13.88406-14.80966-7.40483-9.256,16.66087-9.256,16.66087-41.65219,36.09856-18.51209,37.02417,37.94977-15.73527,37.94977-15.73527,3.70242-18.51208,14.80967-18.51208l1.8512,4.628,20.36329-12.95846s-2.86776-24.576-11.15272-23.85809Z"
      transform="translate(-169.39887 -133.07166)" fill="#2f2e41" />
    <path
      d="M414.71455,717.69032s24.06571-9.256,26.84252,0,2.77681,13.88406,2.77681,13.88406,20.36329,33.32174,0,35.173-29.61933-11.10725-29.61933-11.10725,2.77681-15.73527,0-17.58647S414.71455,717.69032,414.71455,717.69032Z"
      transform="translate(-169.39887 -133.07166)" fill="#2f2e41" />
    <circle cx="261.97654" cy="41.28905" r="32.39614" fill="#feb8b8" />
    <path
      d="M407.30972,181.76555l-6.47923,36.09857,45.3546,11.10725s-10.64445-26.37972.9256-37.94977C450.03773,188.09456,407.30972,181.76555,407.30972,181.76555Z"
      transform="translate(-169.39887 -133.07166)" fill="#feb8b8" />
    <path
      d="M403.59081,202.48523a5.90747,5.90747,0,0,0-4.61153,4.27167,24.64771,24.64771,0,0,1-3.70241,7.40483s-43.50339,20.36329-43.50339,23.1401S369.36,299.31731,369.36,299.31731s.92561,37.02416-6.47923,45.3546-14.80966,38.87537-12.95845,41.65218-12.95846,9.256-7.40483,13.88406,83.30436,49.057,129.58456,28.69373c0,0,.92561-8.33044,0-13.88407s5.55363-24.0657,3.70242-30.54493,0-73.12272,0-73.12272l9.539-78.91388a6.422,6.422,0,0,0-4.50531-6.91912C470.17,222.27483,445.43,209,442.01987,211.84772,437.60427,215.53491,410.05354,213.02329,403.59081,202.48523Z"
      transform="translate(-169.39887 -133.07166)" :fill="secondColor" />
    <path
      d="M476.73,225.26892l.059-.02361a6.41956,6.41956,0,0,1,7.57217,2.16885c5.73554,7.84865,17.80618,25.489,19.21131,36.73014,1.85121,14.80966,7.40484,54.61064,6.47923,60.16426s-1.85121,6.47923,0,9.256.92561,1.85121,0,4.628-12.03285,79.602-12.03285,79.602-7.40483-16.66087-21.2889-11.10725c0,0,1.85121-14.80966,0-19.43768s-3.70241-7.40484-1.8512-11.10725,0-39.801-.92561-43.50339,0-23.14011,0-23.14011l-8.33043-37.94976Z"
      transform="translate(-169.39887 -133.07166)" :fill="secondColor" />
    <path
      d="M425.32476,179.87615l1.09424-7.62808c.25508-1.778,1.29559-4.11571,3.02545-3.63217,1.47128.41125,4.67677,1.67071,5.21347,1.29057,2.00979-1.42376,5.05122,1.06575,7.16473-.199.88549-.5299,1.46315-1.66516,2.49225-1.74093a2.83877,2.83877,0,0,1,1.82957.82886,12.453,12.453,0,0,0,4.7361,2.57284,5.09434,5.09434,0,0,0,4.986-1.359,2.64783,2.64783,0,0,1,.88171-.84518,1.97818,1.97818,0,0,1,1.26779.05831l5.4,1.47026a20.74961,20.74961,0,0,0-2.77613-17.08365,34.36139,34.36139,0,0,0-7.86509-7.70266q-5.01628-3.81612-10.35351-7.17871c-3.89438-2.46016-8.0244-4.78085-12.57864-5.47242-4.55379-.6915-9.65269.54567-12.517,4.15272a8.05951,8.05951,0,0,1-2.89912-3.91646,2.96682,2.96682,0,0,0,.1914,4.54885l-4.56689-2.91639c-1.28389,1.348,1.22451,3.79237.13061,5.29866a2.79072,2.79072,0,0,1-1.22949.80275,20.93594,20.93594,0,0,0-6.69644,4.21477c-2.62485,2.6321-4.05037,6.20489-5.27387,9.715-2.72139,7.80739-4.69932,16.761-.83273,24.06921.43834.82852,6.22456,14.50371,6.99542,15.037,2.60586,1.80263.90956-2.55993,2.77681,0,1.46428,2.00743,1.19017-5.67571,3.36633-3.98925a5.42484,5.42484,0,0,0,5.90469.26554c.90221-.62571.61149-4.10015.12468-7.29081a3.75894,3.75894,0,0,1,4.57333-4.229C422.6006,179.65519,425.27833,180.2,425.32476,179.87615Z"
      transform="translate(-169.39887 -133.07166)" fill="#2f2e41" />
    <path d="M469.92985,253.603l8.18858,92.45728S460.81567,268.5158,469.92985,253.603Z"
      transform="translate(-169.39887 -133.07166)" opacity="0.1" style="isolation:isolate" />
    <path
      d="M317.823,573.68617c-13.25,18.73-16.75,125.33-17.53,159.5-.02.77-.04,1.5-.05,2.19h-33.54c-.41-.73-.79-1.45-1.15-2.19-19.88-39.24-8.85-86.29-8.27-88.67-1.51,7.48,5.32,29.16,13.04,50.16C275.03291,630.10615,315.30293,576.94618,317.823,573.68617Z"
      transform="translate(-169.39887 -133.07166)" :fill="secondColor" />
    <circle cx="745.16867" cy="148.65611" r="22.66667" :fill="color" />
    <path
      d="M919.10088,315.72777h-102a34,34,0,1,1,0-68h102a34,34,0,0,1,0,68Zm-102-63.46667a29.46667,29.46667,0,1,0,0,58.93334h102a29.46667,29.46667,0,1,0,0-58.93334Z"
      transform="translate(-169.39887 -133.07166)" fill="#3f3d56" />
    <circle cx="651.37557" cy="273.43443" r="22.66667" :fill="secondColor" />
    <path
      d="M919.10088,440.50609h-102a34,34,0,0,1,0-68h102a34,34,0,0,1,0,68Zm-102-63.46666a29.46666,29.46666,0,1,0,0,58.93333h102a29.46666,29.46666,0,1,0,0-58.93333Z"
      transform="translate(-169.39887 -133.07166)" :fill="secondColor" />
    <circle cx="651.37557" cy="398.21276" r="22.66667" :fill="secondColor" />
    <path
      d="M919.10088,565.28442h-102a34,34,0,0,1,0-68h102a34,34,0,1,1,0,68Zm-102-63.46667a29.46667,29.46667,0,1,0,0,58.93334h102a29.46667,29.46667,0,1,0,0-58.93334Z"
      transform="translate(-169.39887 -133.07166)" :fill="secondColor" />
    <path
      d="M1025.60113,661.49389H710.60064a5.00589,5.00589,0,0,1-5-5V156.5183a5.00589,5.00589,0,0,1,5-5h315.00049a5.00589,5.00589,0,0,1,5,5V656.49389A5.00589,5.00589,0,0,1,1025.60113,661.49389ZM710.60064,153.5183a3.00328,3.00328,0,0,0-3,3V656.49389a3.00328,3.00328,0,0,0,3,3h315.00049a3.00329,3.00329,0,0,0,3-3V156.5183a3.00328,3.00328,0,0,0-3-3Z"
      transform="translate(-169.39887 -133.07166)" :fill="secondColor" />
  </svg>
</template>

<script>
import undraw from '@/components/icons/undraw/undraw-mixin.js'

export default {
  mixins: [undraw]
}
</script>