import VERMITTLERWECHSEL_STATUS_TYPES from './types';
import { getInitialState } from './index'

export default {
  [VERMITTLERWECHSEL_STATUS_TYPES.MUTATIONS.RETRIEVE_VERMITTLERWECHSEL_STATUS_SUCCESS](state, payload) {
    state.vermittlerwechselStatusData = payload;
  }, 
  [VERMITTLERWECHSEL_STATUS_TYPES.MUTATIONS.RESET_STATE](state, payload) {
    Object.assign(state, getInitialState())
  }, 
  [VERMITTLERWECHSEL_STATUS_TYPES.MUTATIONS.REMOVE_ROW_SUCCESS](state, payload) {
    state.lastRemovedRow = payload;
  },
  [VERMITTLERWECHSEL_STATUS_TYPES.MUTATIONS.PERSIST_ROW_SUCCESS](state, payload) {
    state.persistedData = payload;
  },
  [VERMITTLERWECHSEL_STATUS_TYPES.MUTATIONS.SELECTED_EINTRAG](state, payload) {
    state.selectedEintrag = payload;
  },
  [VERMITTLERWECHSEL_STATUS_TYPES.MUTATIONS.UPDATE_STATUS_SUCCESS](state, payload) {
    state.updateStatusResult = payload;
  },
  [VERMITTLERWECHSEL_STATUS_TYPES.MUTATIONS.STORE_GESELLSCHAFTEN](state, payload) {
    state.gesellschaften = payload;
  },
  [VERMITTLERWECHSEL_STATUS_TYPES.MUTATIONS.RESET_LIST](state, payload) {
    state.vermittlerwechselStatusData.rows = [];
  }, 
  [VERMITTLERWECHSEL_STATUS_TYPES.MUTATIONS.GET_DATA_FOR_EDITING_SUCCESS](state, payload) {
    state.dataForEditing = payload;
  }, 
  [VERMITTLERWECHSEL_STATUS_TYPES.MUTATIONS.GET_BEISPIELKONTONR_SUCCESS](state, payload) {
    state.beispielkontonr = payload;
  }, 
  
  
  
}