import VERMITTLERNUMMERN_TYPES from './types';

export default {
  [VERMITTLERNUMMERN_TYPES.GETTERS.VERSICHERUNGEN_DATA](state) {
    return state.versicherungenData;
  },
  [VERMITTLERNUMMERN_TYPES.GETTERS.SELECTED_FREMDNR](state) {
    return state.selectedFremdnr;
  },
  [VERMITTLERNUMMERN_TYPES.GETTERS.OTHER_TABS_DATA](state) {
    return state.otherTabsData;
  },
  [VERMITTLERNUMMERN_TYPES.GETTERS.ALLE_MITARBEITER_DATA](state) {
    return state.alleMitarbeiterData;
  },


  
  
  
}