var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "antrag-components__container" },
    [
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("FormLabel", {
            attrs: {
              label:
                "Bitte tragen Sie hier die Daten des Depots ein, von dem die Wertpapiere übertragen werden sollen.",
              config: { bold: true }
            }
          }),
          _c("FormDivider"),
          _c("FormLabel", {
            attrs: {
              label: "Daten der bisher depotführenden Gesellschaft",
              config: { bold: true }
            }
          }),
          _c("InputField", {
            attrs: { label: "Name" },
            on: {
              change: function($event) {
                return _vm.$emit("save", { bisherManuellName: $event })
              }
            },
            model: {
              value: _vm.form.bisherManuellName,
              callback: function($$v) {
                _vm.$set(_vm.form, "bisherManuellName", $$v)
              },
              expression: "form.bisherManuellName"
            }
          }),
          _c("InputField", {
            attrs: { label: "Straße" },
            on: {
              change: function($event) {
                return _vm.$emit("save", { bisherManuellStrasse: $event })
              }
            },
            model: {
              value: _vm.form.bisherManuellStrasse,
              callback: function($$v) {
                _vm.$set(_vm.form, "bisherManuellStrasse", $$v)
              },
              expression: "form.bisherManuellStrasse"
            }
          }),
          _c("InputField", {
            attrs: { label: "PLZ" },
            on: {
              change: function($event) {
                return _vm.$emit("save", { bisherManuellPLZ: $event })
              }
            },
            model: {
              value: _vm.form.bisherManuellPLZ,
              callback: function($$v) {
                _vm.$set(_vm.form, "bisherManuellPLZ", $$v)
              },
              expression: "form.bisherManuellPLZ"
            }
          }),
          _c("InputField", {
            attrs: { label: "Ort" },
            on: {
              change: function($event) {
                return _vm.$emit("save", { bisherManuellOrt: $event })
              }
            },
            model: {
              value: _vm.form.bisherManuellOrt,
              callback: function($$v) {
                _vm.$set(_vm.form, "bisherManuellOrt", $$v)
              },
              expression: "form.bisherManuellOrt"
            }
          }),
          _c("FormDivider"),
          _c("FormLabel", {
            attrs: {
              label:
                "Daten des Depots bei der bisher depotführenden Gesellschaft",
              config: { bold: true }
            }
          }),
          _c("InputField", {
            attrs: { label: "Depotnummer" },
            on: {
              change: function($event) {
                return _vm.$emit("save", { bisherDepotnr: $event })
              }
            },
            model: {
              value: _vm.form.bisherDepotnr,
              callback: function($$v) {
                _vm.$set(_vm.form, "bisherDepotnr", $$v)
              },
              expression: "form.bisherDepotnr"
            }
          }),
          _c("FormLabel", {
            attrs: {
              label: "Bisheriger Depotinhaber 1.",
              config: { bold: true }
            }
          }),
          _c("InputField", {
            attrs: { label: "Nachname" },
            on: {
              change: function($event) {
                return _vm.$emit("save", { bisherName: $event })
              }
            },
            model: {
              value: _vm.form.bisherName,
              callback: function($$v) {
                _vm.$set(_vm.form, "bisherName", $$v)
              },
              expression: "form.bisherName"
            }
          }),
          _c("InputField", {
            attrs: { label: "Vorname" },
            on: {
              change: function($event) {
                return _vm.$emit("save", { bisherVorname: $event })
              }
            },
            model: {
              value: _vm.form.bisherVorname,
              callback: function($$v) {
                _vm.$set(_vm.form, "bisherVorname", $$v)
              },
              expression: "form.bisherVorname"
            }
          }),
          _c("InputField", {
            attrs: { label: "Straße, HNr." },
            on: {
              change: function($event) {
                return _vm.$emit("save", { bisherStr: $event })
              }
            },
            model: {
              value: _vm.form.bisherStr,
              callback: function($$v) {
                _vm.$set(_vm.form, "bisherStr", $$v)
              },
              expression: "form.bisherStr"
            }
          }),
          _c("InputField", {
            attrs: { label: "PLZ" },
            on: {
              change: function($event) {
                return _vm.$emit("save", { bisherPlz: $event })
              }
            },
            model: {
              value: _vm.form.bisherPlz,
              callback: function($$v) {
                _vm.$set(_vm.form, "bisherPlz", $$v)
              },
              expression: "form.bisherPlz"
            }
          }),
          _c("InputField", {
            attrs: { label: "Ort" },
            on: {
              change: function($event) {
                return _vm.$emit("save", { bisherOrt: $event })
              }
            },
            model: {
              value: _vm.form.bisherOrt,
              callback: function($$v) {
                _vm.$set(_vm.form, "bisherOrt", $$v)
              },
              expression: "form.bisherOrt"
            }
          }),
          _c("InputField", {
            attrs: { label: "TIN" },
            on: {
              change: function($event) {
                return _vm.$emit("save", { bisherTin: $event })
              }
            },
            model: {
              value: _vm.form.bisherTin,
              callback: function($$v) {
                _vm.$set(_vm.form, "bisherTin", $$v)
              },
              expression: "form.bisherTin"
            }
          }),
          _c("FormDivider"),
          _c("FormLabel", {
            attrs: {
              label: "Bisheriger Depotinhaber 2.",
              config: { bold: true }
            }
          }),
          _c("InputField", {
            attrs: { label: "Nachname" },
            on: {
              change: function($event) {
                return _vm.$emit("save", { bisherName2: $event })
              }
            },
            model: {
              value: _vm.form.bisherName2,
              callback: function($$v) {
                _vm.$set(_vm.form, "bisherName2", $$v)
              },
              expression: "form.bisherName2"
            }
          }),
          _c("InputField", {
            attrs: { label: "Vorname" },
            on: {
              change: function($event) {
                return _vm.$emit("save", { bisherVorname2: $event })
              }
            },
            model: {
              value: _vm.form.bisherVorname2,
              callback: function($$v) {
                _vm.$set(_vm.form, "bisherVorname2", $$v)
              },
              expression: "form.bisherVorname2"
            }
          }),
          _c("InputField", {
            attrs: { label: "Straße, HNr." },
            on: {
              change: function($event) {
                return _vm.$emit("save", { bisherStr2: $event })
              }
            },
            model: {
              value: _vm.form.bisherStr2,
              callback: function($$v) {
                _vm.$set(_vm.form, "bisherStr2", $$v)
              },
              expression: "form.bisherStr2"
            }
          }),
          _c("InputField", {
            attrs: { label: "PLZ" },
            on: {
              change: function($event) {
                return _vm.$emit("save", { bisherPlz2: $event })
              }
            },
            model: {
              value: _vm.form.bisherPlz2,
              callback: function($$v) {
                _vm.$set(_vm.form, "bisherPlz2", $$v)
              },
              expression: "form.bisherPlz2"
            }
          }),
          _c("InputField", {
            attrs: { label: "Ort" },
            on: {
              change: function($event) {
                return _vm.$emit("save", { bisherOrt2: $event })
              }
            },
            model: {
              value: _vm.form.bisherOrt2,
              callback: function($$v) {
                _vm.$set(_vm.form, "bisherOrt2", $$v)
              },
              expression: "form.bisherOrt2"
            }
          }),
          _c("InputField", {
            attrs: { label: "TIN" },
            on: {
              change: function($event) {
                return _vm.$emit("save", { bisherTin2: $event })
              }
            },
            model: {
              value: _vm.form.bisherTin2,
              callback: function($$v) {
                _vm.$set(_vm.form, "bisherTin2", $$v)
              },
              expression: "form.bisherTin2"
            }
          })
        ],
        1
      ),
      _c(
        "BaseButton",
        {
          on: {
            click: function($event) {
              return _vm.$emit("next")
            }
          }
        },
        [_vm._v("Weiter")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }