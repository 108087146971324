<template>
  <div>
    <div class="box__container">
      <div class="box__title">Höchste / relevante Schulbildung *</div>
      <div class="forms__input--half-size">
        <div class="row">
          <div class="col-6">
            <InputField 
              label="Schultyp" 
              v-model="education.schoolType1" 
              :disabled="!isEditable"
              @change="addCustomerDataEdited('education')" />
          </div>
          <div class="col-6">
            <InputField 
              label="von / bis" 
              v-model="education.schoolTime1" 
              :disabled="!isEditable" 
              @change="addCustomerDataEdited('education')" />
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <InputField 
              label="Schultyp" 
              v-model="education.schoolType2" 
              :disabled="!isEditable" 
              @change="addCustomerDataEdited('education')" />
          </div>
          <div class="col-6">
            <InputField 
              label="von / bis" 
              v-model="education.schoolTime2" 
              :disabled="!isEditable" 
              @change="addCustomerDataEdited('education')" />
          </div>
        </div>
      </div>
    </div>

    <div class="box__container">
      <div class="box__title">Berufliche Qualifikationen</div>
      <div class="row">
        <div class="col-12">
          <InputField 
            label="Frühere berufliche Tätigkeiten" 
            :isComponentHalfSize="true"
            v-model="education.pastOccupation" 
            :disabled="!isEditable" 
            @change="addCustomerDataEdited('education')" />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <InputField 
            label="Berufliche Qualifikation / Studium *" 
            :isComponentHalfSize="true"
            v-model="education.qualification" 
            :disabled="!isEditable" 
            @change="addCustomerDataEdited('education')" />
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <InputField 
            label="Kaufmännische / Wertpapierspezifische Ausbildung" 
            :isComponentHalfSize="true"
            v-model="education.training" 
            :disabled="!isEditable" 
            @change="addCustomerDataEdited('education')" />
        </div>
      </div>
    </div>

    <div class="box__container">
      <p>* Die gekennzeichneten Daten werden im Anlegerprofil aufgedruckt.</p>
    </div>
  </div>
</template>

<script>
import InputField from '@/components/core/forms/InputField.vue';

import persoenlicheDatenMixin from './persoenliche-daten-mixin.js';


export default {
  mixins: [persoenlicheDatenMixin],
  data() {
    return {
      ...this.initialState(),
    };
  },
  methods: {
    initialState() {
      return {
        education: {
          schoolType1: '',
          schoolTime1: '',
          schoolType2: '',
          schoolTime2: '',
          pastOccupation: '',
          qualification: '',
          training: '',
        },
      };
    },
    updateGroupsIntoData() {
      this.updateGroupIntoData('education');
    },
  },
  mounted() {
    this.updateGroupsIntoData();
  },
  components: {
    InputField,
  },
}
</script>
