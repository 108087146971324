<template>
  <div>
    <ComboBox
      label="Gesellschaft"
      validateUntouched
      :values="gesellschaftValues"
      v-model="form.gesellschaft"
      @change="dataChangedCombo('gesellschaft', $event);"
    />

    <!-- TODO put alert "Bitte geben Sie die korrekte, vollständige Depotnummer an. Falls Sie die\n" + "Depotnummer nicht kennen, können Sie den nebenstehenden Haken setzen." -->
    <InputField
      label="Depotnummer"
      v-model="form.depotnummer"
      @change="dataChanged();"
      @input="addDepotnummerErrorIfNeeded();"
    />

    <InputToggleSwitch
      label="die Depotnummer ist nicht bekannt"
      inLineLabel
      v-model="form.kontonrUnbekannt"
      @input="dataChanged(); addDepotnummerErrorIfNeeded();"
    />

    <InputField
      label="Beispiel"
      :value="gesellschaftData.beispielKontonr"
      :disabled="true"
      @change="dataChanged();"
    />

    <div class="row mt-0 mb-0">
      <div class="col col-sm-8 col-12">
        <DatePickerField
          label="Stand vom"
          isValueAsString
          v-model="form.standVom"
          @change="dataChanged();"
        />
      </div>
      <div class="col col-sm-4 col-12">
        <TimePicker
          label="Uhrzeit"
          v-model="form.uhrzeit"
          @change="dataChanged();"
        />
      </div>
    </div>
  </div>
</template>

<script>
import validator from '@/mixins/validator';
import { required } from '@/mixins/validator/rules';

import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputField from '@/components/core/forms/InputField.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import TimePicker from '@/components/core/forms/TimePicker.vue';
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import customerDocumentUploadMixin from './customer-document-upload-mixin';

export default {
  mixins: [validator, customerDocumentUploadMixin],
  computed: {
    gesellschaftenCombos() {
      return { ...this.deckblattCombos?.gesellschaftenCombos || {} };
    },
    gesellschaftValues() {
      return this.gesellschaftenCombos[this.deckblattType || ''] || [];
    },
    gesellschaftData() {
      if(!this.form?.gesellschaft) return {};
      const { gesellschaft } = this.form;
      const gesellschaftenData = this.deckblattCombos?.gesellschaftenData?.[this.deckblattType];
      return { ...gesellschaftenData.find(gesell => gesell.id === gesellschaft) };
    },
  },
  methods: {
    addDepotnummerErrorIfNeeded() {
      if(!this.form.kontonrUnbekannt && !this.form.depotnummer?.length) {
        this.$nextTick(() => this.$pushErrors('form.depotnummer', ''));
      } else {
        this.$nextTick(() => this.validation.removeError('form.depotnummer', ''));
      }
    },
  },
  mounted() {
    this.addDepotnummerErrorIfNeeded();
    this.setStandDatumZeit();
  },
  validators: {
    form: {
      gesellschaft: required('Bitte wählen Sie eine Gesellschaft aus.'),
    },
  },
  components: {
    ComboBox,
    InputField,
    InputToggleSwitch,
    TimePicker,
    DatePickerField,
  },
}
</script>
