import { MenuItem, } from '@/menu/menu-utils';

export default MenuItem('/persoenlichedaten/person-data/:personId', 'Person', null, [
  MenuItem('/persoenlichedaten/person-data/:personId/stammdaten', 'Stammdaten'),
  MenuItem('/persoenlichedaten/person-data/:personId/kommunikationsdaten', 'Kommunikations&shy;daten'),
  MenuItem('/persoenlichedaten/person-data/:personId/arbeitgeber', 'Arbeitgeber'),
  MenuItem('/persoenlichedaten/person-data/:personId/dokumente', 'Dokumente'),
  MenuItem('/persoenlichedaten/person-data/:personId/finanzielle-verhaltnisse', 'Finanzielle Verhältnisse'),
  MenuItem('/persoenlichedaten/person-data/:personId/schulbildung-und-qualifikationen', 'Schulbildung und Qualifikationen'),
  MenuItem('/persoenlichedaten/person-data/:personId/pep', 'PEP / FATCA'),
]);
