var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box__container" }, [
    _c("div", { staticClass: "box__title" }, [_vm._v(_vm._s(_vm.title))]),
    _vm.isMainPerson
      ? _c("div", [
          _c(
            "div",
            { staticClass: "mt-16px" },
            [
              _vm.partner
                ? _c("ComboBox", {
                    attrs: {
                      label: "",
                      isComponentHalfSize: true,
                      values: _vm.comboPersons,
                      disabled: !_vm.isEditable
                    },
                    on: {
                      change: function($event) {
                        return _vm.dataChangedCombo("personId", $event)
                      }
                    },
                    model: {
                      value: _vm.internalData.personId,
                      callback: function($$v) {
                        _vm.$set(_vm.internalData, "personId", $$v)
                      },
                      expression: "internalData.personId"
                    }
                  })
                : _c("InputField", {
                    attrs: {
                      label: "",
                      isComponentHalfSize: true,
                      disabled: true
                    },
                    model: {
                      value: _vm.fullName,
                      callback: function($$v) {
                        _vm.fullName = $$v
                      },
                      expression: "fullName"
                    }
                  })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-16px" },
            [
              _c("div", { staticClass: "box__title" }, [
                _vm._v("Bruttoeinkommen")
              ]),
              _c("InputField", {
                attrs: {
                  type: "currency",
                  label: "mtl. Bruttoeinkommen",
                  isComponentHalfSize: true,
                  precision: _vm.precision,
                  disabled:
                    (_vm.partner && !_vm.isPersonIdSelected) || !_vm.isEditable
                },
                on: {
                  input: function($event) {
                    _vm.bruttoeinkommmenpaDataChanged("bruttoeinkommen")
                    _vm.dataChanged()
                  }
                },
                model: {
                  value: _vm.internalData.bruttoeinkommen,
                  callback: function($$v) {
                    _vm.$set(_vm.internalData, "bruttoeinkommen", $$v)
                  },
                  expression: "internalData.bruttoeinkommen"
                }
              }),
              _c("InputField", {
                attrs: {
                  type: "number",
                  label: "Anzahl Monate",
                  isComponentHalfSize: true,
                  precision: 0,
                  disabled:
                    (_vm.partner && !_vm.isPersonIdSelected) || !_vm.isEditable
                },
                on: {
                  input: function($event) {
                    _vm.bruttoeinkommmenpaDataChanged("anzahlmonate")
                    _vm.dataChanged()
                  }
                },
                model: {
                  value: _vm.internalData.anzahlmonate,
                  callback: function($$v) {
                    _vm.$set(_vm.internalData, "anzahlmonate", $$v)
                  },
                  expression: "internalData.anzahlmonate"
                }
              }),
              _c("InputToggleSwitch", {
                attrs: {
                  label: _vm.bruttoeinkommenManuellLabel,
                  inLineLabel: true,
                  precision: _vm.precision,
                  disabled:
                    (_vm.partner && !_vm.isPersonIdSelected) || !_vm.isEditable
                },
                on: {
                  input: function($event) {
                    _vm.bruttoeinkommmenpaDataChanged("bruttoeinkommenManuell")
                    _vm.dataChanged()
                  }
                },
                model: {
                  value: _vm.internalData.bruttoeinkommenManuell,
                  callback: function($$v) {
                    _vm.$set(_vm.internalData, "bruttoeinkommenManuell", $$v)
                  },
                  expression: "internalData.bruttoeinkommenManuell"
                }
              }),
              _c("InputField", {
                attrs: {
                  type: "currency",
                  label: "Bruttoeinkommen p.a.",
                  isComponentHalfSize: true,
                  precision: _vm.precision,
                  disabled:
                    (_vm.partner && !_vm.isPersonIdSelected) ||
                    !_vm.isBruttoeinkommenManuellActive ||
                    !_vm.isEditable
                },
                on: {
                  change: function($event) {
                    return _vm.dataChanged()
                  }
                },
                model: {
                  value: _vm.internalData.bruttoeinkommmenpa,
                  callback: function($$v) {
                    _vm.$set(_vm.internalData, "bruttoeinkommmenpa", $$v)
                  },
                  expression: "internalData.bruttoeinkommmenpa"
                }
              }),
              _c("InputField", {
                attrs: {
                  type: "currency",
                  label: "zu versteuerndes Einkommen p.a.",
                  isComponentHalfSize: true,
                  precision: _vm.precision,
                  disabled:
                    (_vm.partner && !_vm.isPersonIdSelected) ||
                    !_vm.isEditable ||
                    _vm.isLoading
                },
                on: {
                  change: function($event) {
                    _vm.dataChanged()
                    _vm.$emit("taxDataChanged")
                  }
                },
                model: {
                  value: _vm.internalData.versteinkommenpa,
                  callback: function($$v) {
                    _vm.$set(_vm.internalData, "versteinkommenpa", $$v)
                  },
                  expression: "internalData.versteinkommenpa"
                }
              }),
              !_vm.partner
                ? _c("ComboBox", {
                    attrs: {
                      label: "steuerliche Veranlagung",
                      isComponentHalfSize: true,
                      values: _vm.customerDataConfig.veranlagung,
                      disabled: !_vm.isEditable || _vm.isLoading
                    },
                    on: {
                      change: function($event) {
                        _vm.dataChangedCombo("stVeranlagung", $event)
                        _vm.$emit("taxDataChanged")
                      }
                    },
                    model: {
                      value: _vm.internalData.stVeranlagung,
                      callback: function($$v) {
                        _vm.$set(_vm.internalData, "stVeranlagung", $$v)
                      },
                      expression: "internalData.stVeranlagung"
                    }
                  })
                : _vm._e(),
              _c("ComboBox", {
                attrs: {
                  label: "Religionsgemeinschaft",
                  isComponentHalfSize: true,
                  values: _vm.customerDataConfig.religiousCommunities,
                  disabled:
                    (_vm.partner && !_vm.isPersonIdSelected) ||
                    !_vm.isEditable ||
                    _vm.isLoading
                },
                on: {
                  change: function($event) {
                    _vm.dataChangedCombo("religionsname", $event)
                    _vm.$emit("taxDataChanged")
                  }
                },
                model: {
                  value: _vm.internalData.religionsname,
                  callback: function($$v) {
                    _vm.$set(_vm.internalData, "religionsname", $$v)
                  },
                  expression: "internalData.religionsname"
                }
              }),
              _vm.dataTaxResult.kirchensteuerFehler
                ? _c("div", { staticClass: "color-danger" }, [
                    _vm._v(_vm._s(_vm.dataTaxResult.kirchensteuerFehler))
                  ])
                : _vm._e(),
              _c("InputField", {
                attrs: {
                  type: "percent",
                  label: "Kirchensteuersatz in %",
                  isComponentHalfSize: true,
                  precision: _vm.precision,
                  disabled: true
                },
                model: {
                  value: _vm.dataTaxResult.kirchensteuerSatz,
                  callback: function($$v) {
                    _vm.$set(_vm.dataTaxResult, "kirchensteuerSatz", $$v)
                  },
                  expression: "dataTaxResult.kirchensteuerSatz"
                }
              }),
              _c("InputField", {
                attrs: {
                  type: "currency",
                  label: "Kirchensteuer",
                  isComponentHalfSize: true,
                  precision: _vm.precision,
                  disabled: true
                },
                model: {
                  value: _vm.dataTaxResult.kirchensteuerBetrag,
                  callback: function($$v) {
                    _vm.$set(_vm.dataTaxResult, "kirchensteuerBetrag", $$v)
                  },
                  expression: "dataTaxResult.kirchensteuerBetrag"
                }
              }),
              _c("InputField", {
                attrs: {
                  type: "percent",
                  label: "Grenzsteuersatz in %",
                  isComponentHalfSize: true,
                  precision: _vm.precision,
                  disabled: true
                },
                model: {
                  value: _vm.dataTaxResult.grenzsteuerSatz,
                  callback: function($$v) {
                    _vm.$set(_vm.dataTaxResult, "grenzsteuerSatz", $$v)
                  },
                  expression: "dataTaxResult.grenzsteuerSatz"
                }
              }),
              _c("InputField", {
                attrs: {
                  type: "percent",
                  label: "Durchschnittssteuersatz in %",
                  isComponentHalfSize: true,
                  precision: _vm.precision,
                  disabled: true
                },
                model: {
                  value: _vm.dataTaxResult.durchschnsteuerSatz,
                  callback: function($$v) {
                    _vm.$set(_vm.dataTaxResult, "durchschnsteuerSatz", $$v)
                  },
                  expression: "dataTaxResult.durchschnsteuerSatz"
                }
              }),
              _c("InputField", {
                attrs: {
                  type: "currency",
                  label: "Einkommenssteuer",
                  isComponentHalfSize: true,
                  precision: _vm.precision,
                  disabled: true
                },
                model: {
                  value: _vm.dataTaxResult.einkommenssteuerBetrag,
                  callback: function($$v) {
                    _vm.$set(_vm.dataTaxResult, "einkommenssteuerBetrag", $$v)
                  },
                  expression: "dataTaxResult.einkommenssteuerBetrag"
                }
              }),
              _c("InputField", {
                attrs: {
                  type: "currency",
                  label: "Solidaritätszuschlag",
                  isComponentHalfSize: true,
                  precision: _vm.precision,
                  disabled: true
                },
                model: {
                  value: _vm.dataTaxResult.solizuschlagBetrag,
                  callback: function($$v) {
                    _vm.$set(_vm.dataTaxResult, "solizuschlagBetrag", $$v)
                  },
                  expression: "dataTaxResult.solizuschlagBetrag"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-16px" },
            [
              _c("div", { staticClass: "box__title" }, [_vm._v("Rente")]),
              _vm.istFK
                ? _c("InputField", {
                    attrs: {
                      type: "currency",
                      label:
                        "Rente wegen voller Erwerbsminderung (gem. Renteninformation)",
                      isComponentHalfSize: true,
                      precision: 2,
                      disabled:
                        (_vm.partner && !_vm.isPersonIdSelected) ||
                        !_vm.isEditable
                    },
                    on: {
                      change: function($event) {
                        return _vm.dataChanged()
                      }
                    },
                    model: {
                      value: _vm.internalData.erwerbsminderung,
                      callback: function($$v) {
                        _vm.$set(_vm.internalData, "erwerbsminderung", $$v)
                      },
                      expression: "internalData.erwerbsminderung"
                    }
                  })
                : _vm._e(),
              _vm.istFK
                ? _c("InputField", {
                    attrs: {
                      type: "currency",
                      label:
                        "Höhe der bislang erreichten Rentenanwartschaft (gem. Renteninformation)",
                      isComponentHalfSize: true,
                      precision: 2,
                      disabled:
                        (_vm.partner && !_vm.isPersonIdSelected) ||
                        !_vm.isEditable
                    },
                    on: {
                      change: function($event) {
                        return _vm.dataChanged()
                      }
                    },
                    model: {
                      value: _vm.internalData.rentenanwartschaftBislang,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.internalData,
                          "rentenanwartschaftBislang",
                          $$v
                        )
                      },
                      expression: "internalData.rentenanwartschaftBislang"
                    }
                  })
                : _vm._e(),
              _vm.istFK
                ? _c("InputField", {
                    attrs: {
                      type: "currency",
                      label:
                        "Höhe Ihrer künftigen Regelaltersrente (ohne Berücksichtigung von Rentenanpassungen, gem. Renteninformation)",
                      isComponentHalfSize: true,
                      precision: 2,
                      disabled:
                        (_vm.partner && !_vm.isPersonIdSelected) ||
                        !_vm.isEditable
                    },
                    on: {
                      change: function($event) {
                        return _vm.dataChanged()
                      }
                    },
                    model: {
                      value: _vm.internalData.rentenanwartschaftZukunft,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.internalData,
                          "rentenanwartschaftZukunft",
                          $$v
                        )
                      },
                      expression: "internalData.rentenanwartschaftZukunft"
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-16px" },
            [
              _c("div", { staticClass: "box__title" }, [
                _vm._v("Rentenversicherung")
              ]),
              _c("ComboBox", {
                attrs: {
                  label: "gesetzl. Rentenvers./Versorgungswerk",
                  isComponentHalfSize: true,
                  values: _vm.customerDataConfig.pensionInsurance,
                  disabled:
                    (_vm.partner && !_vm.isPersonIdSelected) || !_vm.isEditable
                },
                on: {
                  change: function($event) {
                    return _vm.dataChangedCombo(
                      "gesetzlrentenversicherung",
                      $event
                    )
                  }
                },
                model: {
                  value: _vm.internalData.gesetzlrentenversicherung,
                  callback: function($$v) {
                    _vm.$set(_vm.internalData, "gesetzlrentenversicherung", $$v)
                  },
                  expression: "internalData.gesetzlrentenversicherung"
                }
              }),
              _c("ComboBox", {
                attrs: {
                  label: "Alte/Neue Bundesländer",
                  isComponentHalfSize: true,
                  values: _vm.customerDataConfig.federalStates,
                  disabled:
                    (_vm.partner && !_vm.isPersonIdSelected) || !_vm.isEditable
                },
                on: {
                  change: function($event) {
                    return _vm.dataChangedCombo("altNeuBundeslaender", $event)
                  }
                },
                model: {
                  value: _vm.internalData.altNeuBundeslaender,
                  callback: function($$v) {
                    _vm.$set(_vm.internalData, "altNeuBundeslaender", $$v)
                  },
                  expression: "internalData.altNeuBundeslaender"
                }
              }),
              _c("InputField", {
                attrs: {
                  label: "Entgeltpunkte",
                  isComponentHalfSize: true,
                  precision: 4,
                  disabled:
                    (_vm.partner && !_vm.isPersonIdSelected) || !_vm.isEditable
                },
                on: {
                  change: function($event) {
                    return _vm.dataChanged("entgeltPunkte", 4)
                  }
                },
                model: {
                  value: _vm.internalData.entgeltPunkte,
                  callback: function($$v) {
                    _vm.$set(_vm.internalData, "entgeltPunkte", $$v)
                  },
                  expression: "internalData.entgeltPunkte"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-16px" },
            [
              _c("div", { staticClass: "box__title" }, [
                _vm._v("Krankenversicherung")
              ]),
              _c("ComboBox", {
                attrs: {
                  label: "Krankenversicherungsstatus",
                  isComponentHalfSize: true,
                  values: _vm.customerDataConfig.healthInsuranceStatus,
                  disabled:
                    (_vm.partner && !_vm.isPersonIdSelected) || !_vm.isEditable
                },
                on: {
                  change: function($event) {
                    return _vm.dataChangedCombo(
                      "krankenversicherungsstatus",
                      $event
                    )
                  }
                },
                model: {
                  value: _vm.internalData.krankenversicherungsstatus,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.internalData,
                      "krankenversicherungsstatus",
                      $$v
                    )
                  },
                  expression: "internalData.krankenversicherungsstatus"
                }
              }),
              _c("InputField", {
                attrs: {
                  type: "currency",
                  label: "Beitr. zur Kranken- und Pflegevers.",
                  isComponentHalfSize: true,
                  precision: _vm.precision,
                  disabled:
                    (_vm.partner && !_vm.isPersonIdSelected) || !_vm.isEditable
                },
                on: {
                  change: function($event) {
                    return _vm.dataChanged()
                  }
                },
                model: {
                  value: _vm.internalData.beitragKrankenPflegeversicherung,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.internalData,
                      "beitragKrankenPflegeversicherung",
                      $$v
                    )
                  },
                  expression: "internalData.beitragKrankenPflegeversicherung"
                }
              }),
              _c("InputField", {
                attrs: {
                  type: "percent",
                  label: "Beitragssatz GKV %",
                  isComponentHalfSize: true,
                  precision: _vm.precision,
                  disabled:
                    (_vm.partner && !_vm.isPersonIdSelected) || !_vm.isEditable
                },
                on: {
                  change: function($event) {
                    return _vm.dataChanged()
                  }
                },
                model: {
                  value: _vm.internalData.beitragssatzGKV,
                  callback: function($$v) {
                    _vm.$set(_vm.internalData, "beitragssatzGKV", $$v)
                  },
                  expression: "internalData.beitragssatzGKV"
                }
              }),
              _c("InputField", {
                attrs: {
                  type: "currency",
                  label: "Zuschlag Pflegevers. (ohne Kinder)",
                  isComponentHalfSize: true,
                  precision: _vm.precision,
                  disabled:
                    (_vm.partner && !_vm.isPersonIdSelected) || !_vm.isEditable
                },
                on: {
                  change: function($event) {
                    return _vm.dataChanged()
                  }
                },
                model: {
                  value: _vm.internalData.zuschlagPflegeversicherung,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.internalData,
                      "zuschlagPflegeversicherung",
                      $$v
                    )
                  },
                  expression: "internalData.zuschlagPflegeversicherung"
                }
              })
            ],
            1
          ),
          _c("div", { staticClass: "mt-16px" }, [
            _vm._v(
              " * Bei den berechneten Beträgen handelt es sich um einen Anhalt. Die exakten Beträge können, aufgrund von Freibeträgen etc., von den dargestellten Werten abweichen. "
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }