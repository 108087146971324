<template>
  <div>
    <BoxContainer title="Risko-Kennzahlen">
        <Table v-if="rows.length"
            :headers="headers"
            :rows="rows"
            rowId="key"
            hidePagination
            :mobileConfig="{title: 'key', headers: ['col_2', 'col_3']}"
        />
        <div v-else>Keine Daten</div>
    </BoxContainer>
  </div>
</template>

<script>
import FONDSINFO_TYPES from "@/store/fondsinfo/types";
import BoxContainer from '@/components/core/BoxContainer.vue';
import { mapGetters } from 'vuex';
import Table from "@/components/table2/Table.vue";
import {TextColumn, DateColumn, IconColumn, PillColumn, Icon, numberToSortable} from "@/components/table2/table_util.js";

export default {
  components: {
    BoxContainer,
    Table,
  },
  name: "TabRisiko",
  props:{
    selectedTabLabel: {
      type: String
    }
  },
  computed: {
    ...mapGetters({
        risiko : FONDSINFO_TYPES.GETTERS.FONDSINFO_RISIKO
    }),
    headers() {
        const result = {};
        if(this.risiko && this.risiko.tableHeaders) {
            result.center = this.risiko.tableHeaders
            .map((header, index) => TextColumn(header.key || 'col_'+ index, header.label));
        }
        return result;
    },
    rows() {
        if (!this.risiko?.records || !this.risiko.tableHeaders)
            return [];
        const headersKeys = this.risiko.tableHeaders.map((header, index) => header.key || 'col_'+ index);
        return this.risiko.records.map(tuple => {
            return tuple.reduce((acc, curr, index) => ({...acc, [headersKeys[index]] : curr}), {});
        });
    },
  },
  mounted() {
    if(!this.risiko) {
      this.$store.dispatch(FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_RISIKO, 
      {
        isin: this.$route.params?.isin
      });
    }
  },

}
</script>

<style>

</style>