var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseDropdownMenu", {
    attrs: {
      placement: "bottom-right",
      containerClass: "option-menu-help--dropdown",
      closeWithInnerClick: false
    },
    scopedSlots: _vm._u([
      {
        key: "hook-target",
        fn: function() {
          return [
            _c(
              "button",
              { staticClass: "btn-clear clickable", attrs: { type: "button" } },
              [_c("PhQuestion", { attrs: { size: 20 } })],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "default",
        fn: function() {
          return [
            _c("ul", { staticClass: "option-menu-help--items" }, [
              _c("li", { staticClass: "option-menu-help--item" }, [
                _c(
                  "span",
                  [_c("PhPencilLine", { attrs: { size: 16, weight: "bold" } })],
                  1
                ),
                _c("span", [
                  _vm._v("Filter bearbeiten und als Standard setzen")
                ])
              ]),
              _c("li", { staticClass: "option-menu-help--item" }, [
                _c(
                  "span",
                  [
                    _c("PhFileArrowDown", {
                      attrs: { size: 16, weight: "bold" }
                    })
                  ],
                  1
                ),
                _c("span", [_vm._v("Filter exportieren")])
              ]),
              _c("li", { staticClass: "option-menu-help--item" }, [
                _c(
                  "span",
                  [_c("PhTrash", { attrs: { size: 16, weight: "bold" } })],
                  1
                ),
                _c("span", [_vm._v("Filter löschen")])
              ]),
              _c("li", { staticClass: "option-menu-help--item" }, [
                _c(
                  "span",
                  [_c("PhEye", { attrs: { size: 16, weight: "bold" } })],
                  1
                ),
                _c("span", [_vm._v("Filter anzeigen")])
              ]),
              _c("li", { staticClass: "option-menu-help--item" }, [
                _c(
                  "span",
                  [_c("PhEyeSlash", { attrs: { size: 16, weight: "bold" } })],
                  1
                ),
                _c("span", [_vm._v("Filter verstecken")])
              ]),
              _c("li", { staticClass: "option-menu-help--item" }, [
                _c(
                  "span",
                  [_c("PhList", { attrs: { size: 16, weight: "bold" } })],
                  1
                ),
                _c("span", [_vm._v("an anderer Stelle platzieren")])
              ])
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }