<template>
	<div class="box__container">
		<div class="font-strong">
			Weitere Angaben
		</div>

		<InputRadioBoxGroup 
			@input="changeVtgArt34Modus"
			:title="'Geschäfte im Jahr ' + (form.currentYear || '')"
			v-model="form.vtgArt34Modus"
			:values="vertragWPValues.rgModus34"/>

		<hr/>
		
		<div>
			<span class="font-strong">§ 34 f</span> Im prüfungspflichtigen Zeitraum erfolgte(n)
		</div>
		<DatePickerField
			isComponentHalfSize
			isRangePicker
			id="form.angabeZeitraum34f"
			:disabled="!activeFields.angabeVon34f || !activeFields.angabeBis34f"
			:value="{
				from: form.angabeVon34f,
				to: form.angabeBis34f,
			}"
			@onSelectRangeFrom="form.angabeVon34f = $event"
			@onSelectRangeTo="form.angabeBis34f = $event"
			@change="fieldChanged()"/>

		<InputToggleSwitch
			inLineLabel
			@input="fieldChanged()"
			v-model="form.angabeKeine34f"
			:disabled="!activeFields.angabeKeine34f"
			label="keine Anlageberatung und keine Anlagevermittlung (es erfolgte im Prüfungszeitraum auch keine Anlageberatung bzw. Anlagevermittlung, die 
				<span class='font-strong'>nicht zu einem Geschäftsabschluss</span> führte)."/>

		<InputToggleSwitch
			inLineLabel
			@input="fieldChanged()"
			v-model="form.angabeBeratung34f"
			:disabled="!activeFields.angabeBeratung34f"
			label="Anlageberatung,"/>

		<InputToggleSwitch
			inLineLabel
			@input="fieldChanged()"
			v-model="form.angabeVermittlung34f"
			:disabled="!activeFields.angabeVermittlung34f"
			label="Anlagenvermittlung,"/>
		
		<div>
			zu folgenden Finanzanlagen <span class='font-strong'>(es sollten nur die Punkte angekreuzt werden bei denen auch eine Beratung oder Vermittlung erfolgt ist)</span>
		</div>
		
		<InputToggleSwitch
			inLineLabel
			@input="fieldChanged()"
			v-model="form.angabePara34f1"
			:disabled="!activeFields.angabePara34f1"
			label="Anteilen oder Aktien an inländischen offenen Investmentvermögen, offenen EU-Investmentvermögen oder ausländischen offenen Investmentvermögen, 
				die nach dem Kapitalanlagegesetzbuch vertrieben werden dürfen (§ 34f Abs. 1 Satz 1 Nr. 1 GewO),"/>

		<InputToggleSwitch
			inLineLabel
			@input="fieldChanged()"
			v-model="form.angabePara34f2"
			:disabled="!activeFields.angabePara34f2"
			label="Anteilen oder Aktien an inländischen geschlossenen Investmentvermögen, geschlossenen EU-Investmentvermögen oder ausländischen geschlossenen 
				Investmentvermögen, die nach dem Kapitalanlagegesetzbuch vertrieben werden dürfen (§ 34f Abs. 1 Satz 1 Nr. 2 GewO),"/>

		<InputToggleSwitch
			inLineLabel
			@input="fieldChanged()"
			v-model="form.angabePara34f3"
			:disabled="!activeFields.angabePara34f3"
			label="Vermögensanlagen im Sinne des § 1 Absatz 2 des Vermögensanlagegesetzes (§ 34f Abs. 1 Satz 1 Nr. 3 GewO)."/>

		<hr/>

		<div>
			<span class="font-strong">§ 34 h</span> Im prüfungspflichtigen Zeitraum erfolgte(n)
		</div>
		<DatePickerField
			isComponentHalfSize
			isRangePicker
			id="form.angabeZeitraum34h"
			:disabled="!activeFields.angabeVon34h || !activeFields.angabeBis34h"
			:value="{
				from: form.angabeVon34h,
				to: form.angabeBis34h,
			}"
			@onSelectRangeFrom="form.angabeVon34h = $event"
			@onSelectRangeTo="form.angabeBis34h = $event"
			@change="fieldChanged()"/>

		<InputToggleSwitch
			inLineLabel
			@input="fieldChanged()"
			v-model="form.angabeKeine34h"
			:disabled="!activeFields.angabeKeine34h"
			label="keine Honorar-Finanzanlageberatung (es erfolgte im prüfungspflichtigen Zeitraum auch keine Honorar-Finanzanlageberatung, die 
				<span class='font-strong'>nicht zu einem Geschäftsabschluss</strong> führte)."/>

		<InputToggleSwitch
			inLineLabel
			@input="fieldChanged()"
			v-model="form.angabeBeratung34h"
			:disabled="!activeFields.angabeBeratung34h"
			label="Honorar-Finanzanlageberatung."/>

		<div>
			zu folgenden Finanzanlagen
		</div>
		
		<InputToggleSwitch
			inLineLabel
			@input="fieldChanged()"
			v-model="form.angabePara34h1"
			:disabled="!activeFields.angabePara34h1"
			label="Anteilen oder Aktien an inländischen offenen Investmentvermögen, offenen EU-Investmentvermögen oder ausländischen 
				offenen Investmentvermögen, die nach dem Kapitalanlagegesetzbuch vertrieben werden dürfen 
				(§ 34h Abs. 1 Satz 1 Nr. 1 GewO),"/>
		
		<InputToggleSwitch
			inLineLabel
			@input="fieldChanged()"
			v-model="form.angabePara34h2"
			:disabled="!activeFields.angabePara34h2"
			label="Anteilen oder Aktien an inländischen geschlossenen Investmentvermögen, geschlossenen EUInvestmentvermögen 
				oder ausländischen geschlossenen Investmentvermögen, die nach dem Kapitalanlagegesetzbuch 
				vertrieben werden dürfen (§ 34h Abs. 1 Satz 1 Nr. 2 GewO),"/>

		<InputToggleSwitch
			inLineLabel
			@input="fieldChanged()"
			v-model="form.angabePara34h3"
			:disabled="!activeFields.angabePara34h3"
			label="Vermögensanlagen im Sinne des § 1 Absatz 2 des Vermögensanlagegesetzes (§ 34h Abs. 1 Satz 1 Nr. 3 GewO)."/>


		<hr/>

		<div class="mb-2">
			Die Anforderungen gemäß § 16 FinVermV insbesondere in Bezug auf die Einholung von Informationen über den 
				Anleger sowie die Pflicht zur Empfehlung geeigneter Finanzanlagen – wurden beachtet und dokumentiert.
		</div>

		<InputRadioBoxGroup 
			v-model="form.angabeSchwarmfinanzierung"
			@input="fieldChanged()"
			:values="vertragWPValues.schwarmFinanzierung"/>

	</div>
</template>

<script>
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue'
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue'
import VERTRAG_WP_TYPES from '@/store/vertragWP/types.js'
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue'
import { mapGetters } from 'vuex';
import validator from "@/mixins/validator";

export default {
	mixins: [validator],

	props: {
		formData: {
			type: Object,
			default: {}
		}
	},

	computed: {
		...mapGetters({
			hinweise: VERTRAG_WP_TYPES.GETTERS.VERTRAG_WP_HINWEIS,
			vertragWPValues: VERTRAG_WP_TYPES.GETTERS.VERTRAG_WP_VALUES,
			activeFields: VERTRAG_WP_TYPES.GETTERS.VERTRAG_WP_ACTIVE_FIELDS,
			errors: VERTRAG_WP_TYPES.GETTERS.VERTRAG_WP_ERRORS,
		}),
		errorsCurrentStep() {
			return this.errors?.filter?.(err => err.stepKey === 'contractData2') || []
		}
	},

	watch: {
		formData: {
			handler(newValue) {
				this.loadForm(newValue)
			},
			immediate: true,
		},
		errorsCurrentStep(errors) {
			for (const error of errors) {
				this.$pushErrors('form.' + error.id, error.message)
			}
		}
	},

	data() {
		return {
			form: {}
		}
	},

	validators: {
		form: []
	},

	methods: {
		loadForm(newValue) {
			this.$set(this, 'form', Object.assign({}, newValue))
		},

		changeVtgArt34Modus($event) {
			this.$emit('changeVtgArt34Modus', $event)
		},
		fieldChanged() {
			this.$emit('fieldChanged', this.form)
		}
	},

	components: {
		InputRadioBoxGroup,
		DatePickerField,
		InputToggleSwitch
	}
}
</script>

<style scoped>

</style>