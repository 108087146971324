export const MUTATION_PREFIX = 'MAKLER_ANTRAG:';
export const ACTIONS_PREFIX = 'MAKLER_ANTRAG_ACTIONS_';
export const GETTERS_PREFIX = 'MAKLER_ANTRAG_GETTERS_';

export default{
    MUTATIONS:{
        LOAD_MAKLERANTRAG_SUCCESS: MUTATION_PREFIX + 'LOAD_MAKLERANTRAG_SUCCESS',
        LOAD_AUSWERTUNG_SUCCESS: MUTATION_PREFIX + 'LOAD_AUSWERTUNG_SUCCESS',
        RESET_JAHRESCHECKLISTE:MUTATION_PREFIX + 'RESET_JAHRESCHECKLISTE',
        LOAD_CONFIG_DATA_SUCCESS: MUTATION_PREFIX + 'LOAD_CONFIG_DATA_SUCCESS',
        LOAD_CHECKLISTE_DOCS_SUCCESS: MUTATION_PREFIX + 'LOAD_CHECKLISTE_DOCS_SUCCESS',
        RESET_FORM: MUTATION_PREFIX + 'RESET_FORM'
    },
    GETTERS:{
        GET_MAKLER_ANTRAG: GETTERS_PREFIX + 'GET_MAKLER_ANTRAG',
        GET_AUSWERTUNG: GETTERS_PREFIX + 'GET_AUSWERTUNG',
        GET_MAKLER_ANTRAGDATA: GETTERS_PREFIX + 'GET_MAKLER_ANTRAGDATA',
        GET_CONFIG_DATA: GETTERS_PREFIX + 'GET_CONFIG_DATA',
        GET_JAHRESCHECKLISTE_DOCS: GETTERS_PREFIX + 'GET_JAHRESCHECKLISTE_DOCS'
    },
    ACTIONS:{
        RETRIEVE_MAKLER_ANTRAEGE: ACTIONS_PREFIX + 'RETRIEVE_MAKLER_ANTRAEGE',
        LOAD_MAKLERANTRAG:ACTIONS_PREFIX + 'LOAD_MAKLERANTRAG',
        GET_AUSWERTUNG: ACTIONS_PREFIX + 'GET_AUSWERTUNG',
        GET_AUSWERTUNG_AS_EXCEL: ACTIONS_PREFIX + 'GET_AUSWERTUNG_EXCEL',
        SAVE_JAHRESCHECKLISTE: ACTIONS_PREFIX + 'SAVE_JAHRESCHECKLISTE',
        LOAD_CONFIG_DATA:ACTIONS_PREFIX + 'LOAD_CONFIG_DATA',
        OPEN_PDF:ACTIONS_PREFIX+ 'OPEN_PDF',
        LOAD_CHECKLISTE_DOCS: ACTIONS_PREFIX + 'LOAD_CHECKLISTE_DOCS',
        GET_EXCEL_DATA: ACTIONS_PREFIX + 'GET_EXCEL_DATA'
    }
}