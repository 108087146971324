var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "baseFilterSaveModal",
    attrs: { showDefaultButtons: false, size: "lg" },
    on: {
      onCloseButton: function($event) {
        return _vm.emitCloseModalEvent()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "modalTitle",
        fn: function() {
          return [
            _c(
              "div",
              {
                staticClass:
                  "base-filter-save--title-container d-flex justify-content-between"
              },
              [
                _c("span", { staticClass: "base-filter-save--title" }, [
                  _vm._v("Filterliste")
                ]),
                _c("BaseFilterSaveModalHelper", {
                  staticClass: "base-filter-save--title-dropdown"
                })
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "default",
        fn: function() {
          return [
            _c(
              "div",
              [
                _vm.currentlySelectedRow
                  ? _c(
                      "div",
                      { staticClass: "d-flex" },
                      [
                        _c("InputField", {
                          model: {
                            value:
                              _vm.storedSearchParameter.searchParameters[
                                _vm.currentlyEditingIndex
                              ].searchLabel,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.storedSearchParameter.searchParameters[
                                  _vm.currentlyEditingIndex
                                ],
                                "searchLabel",
                                $$v
                              )
                            },
                            expression:
                              "storedSearchParameter.searchParameters[currentlyEditingIndex].searchLabel"
                          }
                        }),
                        _c(
                          "div",
                          [
                            _c(
                              "BaseButton",
                              {
                                staticClass: "ml-3",
                                attrs: { isSecondary: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.toggleDefaultFilter()
                                  }
                                }
                              },
                              [
                                _vm.currentlySelectedRow &&
                                _vm.currentlySelectedRow.isDefault
                                  ? _c("span", [_vm._v(" Standard löschen ")])
                                  : _c("span", [
                                      _vm._v(" Als Standard setzen ")
                                    ])
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.hasSearchParameters
                  ? _c(
                      "div",
                      { staticClass: "filter__container" },
                      [
                        _c(
                          "SortableList",
                          {
                            attrs: {
                              items: _vm.storedSearchParameter.searchParameters
                            },
                            on: {
                              orderChanged: function($event) {
                                return _vm.onOrderChanged($event)
                              }
                            }
                          },
                          [
                            _c(
                              "ul",
                              {
                                staticClass: "list-bordered",
                                attrs: { "data-sortable-container": "" }
                              },
                              _vm._l(
                                _vm.storedSearchParameter.searchParameters,
                                function(item, index) {
                                  return _c(
                                    "li",
                                    {
                                      key: index + item.searchLabel,
                                      staticClass: "list-bordered-item",
                                      attrs: { "data-sortable-item": "" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex parameter-search__input-fields"
                                        },
                                        [
                                          _c("div", [
                                            _c(
                                              "span",
                                              {
                                                class: {
                                                  "default-filter":
                                                    item.isDefault
                                                }
                                              },
                                              [_vm._v(_vm._s(item.searchLabel))]
                                            ),
                                            item.isDefault
                                              ? _c(
                                                  "span",
                                                  {
                                                    class: {
                                                      "default-filter":
                                                        item.isDefault
                                                    }
                                                  },
                                                  [_vm._v(" (Standard)")]
                                                )
                                              : _vm._e()
                                          ]),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "input-forms__container d-flex align-items-center justify-content-end",
                                              staticStyle: { flex: "1 0 auto" }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "parameter-controls__container clickable"
                                                },
                                                [
                                                  _c("ph-pencil-line", {
                                                    attrs: {
                                                      size: 16,
                                                      weight: "bold"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.editRow(
                                                          index
                                                        )
                                                      }
                                                    }
                                                  }),
                                                  _c("ph-file-arrow-down", {
                                                    attrs: {
                                                      size: 16,
                                                      weight: "bold"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.exportFilter(
                                                          index
                                                        )
                                                      }
                                                    }
                                                  }),
                                                  _c("ph-trash", {
                                                    attrs: {
                                                      size: 16,
                                                      weight: "bold"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.deleteFilterItem(
                                                          index
                                                        )
                                                      }
                                                    }
                                                  }),
                                                  item.visible
                                                    ? _c("ph-eye", {
                                                        attrs: {
                                                          size: 16,
                                                          weight: "bold"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.hideOrShow(
                                                              index
                                                            )
                                                          }
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  !item.visible ||
                                                  item.visible == false
                                                    ? _c("ph-eye-slash", {
                                                        attrs: {
                                                          size: 16,
                                                          weight: "bold"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.hideOrShow(
                                                              index
                                                            )
                                                          }
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  _c(
                                                    "div",
                                                    {
                                                      attrs: {
                                                        "data-sortable-item-handler":
                                                          ""
                                                      }
                                                    },
                                                    [
                                                      _c("ph-list", {
                                                        attrs: {
                                                          size: 16,
                                                          weight: "bold"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _c("NoData", { attrs: { noIcon: "" } })
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "footer",
        fn: function() {
          return [
            _c(
              "BaseFileSelect",
              {
                staticClass: "mr-3",
                attrs: { isSecondary: "", disable: !_vm.filterSaveKey },
                on: { files: _vm.importFilter }
              },
              [_vm._v(" Filter laden ")]
            ),
            _c(
              "BaseButton",
              {
                attrs: { isPrimary: "" },
                on: {
                  click: function($event) {
                    return _vm.emitCloseModalEvent()
                  }
                }
              },
              [_c("span", [_vm._v("Schließen")])]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }