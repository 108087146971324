var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.type === "customer"
        ? _c("CustomerSearch", {
            attrs: {
              customOptionMenu: _vm.customPageHeaderMenu,
              singleSelection: _vm.isSingleSelection,
              isExternalSelect: _vm.isExternalSelect,
              filterType: _vm.filterType
            },
            on: { handleBackAction: _vm.handleBackAction }
          })
        : _vm.type === "broker"
        ? _c("BrokerSearch", {
            attrs: {
              showWithPerson: "",
              customOptionMenu: _vm.customPageHeaderMenu,
              isExternalSelect: _vm.isExternalSelect
            },
            on: { handleBackAction: _vm.handleBackAction }
          })
        : _c("ContactSearch", {
            attrs: {
              customOptionMenu: _vm.customPageHeaderMenu,
              isExternalSelect: _vm.isExternalSelect,
              filterType: _vm.type
            },
            on: { handleBackAction: _vm.handleBackAction }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }