<template>
	<div>
		
	</div>
</template>

<script>

import VERTRAG_WP_TYPES from '@/store/vertragWP/types.js'
import { mapGetters } from 'vuex';

export default {
	computed: {
		...mapGetters({
			hinweise: VERTRAG_WP_TYPES.GETTERS.VERTRAG_WP_HINWEIS,
			errors: VERTRAG_WP_TYPES.GETTERS.VERTRAG_WP_ERRORS,
		}),
	},

	methods: {
		
	},

	components: {
	}
}
</script>

<style scoped>

</style>