<template>
<div>

  <InsuranceOptionMenu
    :insuranceId="versicherungId"
    :headerActions="headerActions"
    @action-NEW-ACTIVITY="newAppointment()"
    @action-OLD-POLICE-NUMBERS="showOldPoliceNumbers()"
    @action-SCHADEN-MELDEN="createSchaden()"
    @action-EMAIL-KUNDE="emailStart(false)"
    @action-EMAIL-GESELLSCHAFT="emailStart(true)"
    @action-BRIEF-ERSTELLEN="briefStart()"
    @action-KUENDIGUNG-VERSICHERUNGEN="emailCancel()"
    @action-AUFGABE-HINZUFUEGEN="handleAddActivity()"
    @action-VERSICHERUNG-KOPIEREN="versicherungKopierenBestaetigung()"
    @action-DOKUMENTE-HOCHLADEN="dateiHochladen()"     
    @action-EXTERNEN-ZUGANG-AUFRUFEN="externenZugangOeffnen()"
   title="Anmerkungen">
      <template #title>
        Anmerkungen
      </template>
      <template #subtitle>
        <template v-if="gesellschaftName">
          <span class="subtitle-part" v-if="isCustomerOnly" >{{ gesellschaftName }}</span>
          <span class="subtitle-part" v-else ><a @click="navigateToGesellschaft()">{{ gesellschaftName }}</a></span>
          <span> | </span>
        </template>
        <template v-if="bezeichnung">
          <span class="subtitle-part">{{ bezeichnung }}</span>
          <span v-if="nummer || statusAktuell || showKennzeichen"> | </span>
        </template>        
        <template v-if="nummer">
          <router-link class="subtitle-part" :to="overviewLink(versicherungId)">{{ nummer }}</router-link>
          <span v-if="statusAktuell || showKennzeichen"> | </span>
        </template>
        <template v-if="statusAktuell">
          <span class="subtitle-part"><Pill :label="statusAktuell" :type="statusToPillType[statusAktuell]" /></span>
          <span v-if="showKennzeichen"> | </span>
        </template>
        <template v-if="showKennzeichen">
          <span class="subtitle-part">{{ kennzeichen }}</span>
        </template>
      </template>     
   </InsuranceOptionMenu>

  <div class="box__container">
    <Anmerkungen/>

  </div>

  <CopyInsuranceConfirmationModal ref="copyInsuranceConfirmationModal" @open-item="openItemSelectedDetail"></CopyInsuranceConfirmationModal>
  <ShowOldPoliceNumbersModal ref="showOldPoliceNumbersModal" @open-item="openItemSelectedDetail"></ShowOldPoliceNumbersModal> 
  <AddDocument ref="addDocumentModal" :nodeId="nodeId" :versicherungId="versicherungId"  @close="goToDownloadArea(versicherungId, true)" />
  <EmailVersand/>

</div>
</template>

<script>
import Anmerkungen from '@/components/versicherungen/Anmerkungen.vue';
import InsuranceOptionMenu from './insurance-group/InsuranceOptionMenu.vue';
import InsuranceDetailMixin from '@/components/versicherungen/InsuranceDetail-mixin.js'
import Pill from '@/components/core/Pill.vue';
import CopyInsuranceConfirmationModal from '@/views/versicherungen/CopyInsuranceConfirmationModal.vue'
import ShowOldPoliceNumbersModal from '@/views/versicherungen/ShowOldPoliceNumbersModal.vue'
import AddDocument from '@/views/versicherungen//AddDocument.vue';
import EmailVersand from "@/components/core/EmailVersand.vue";

export default {
  mixins: [InsuranceDetailMixin],
  components: {
    Anmerkungen,
    InsuranceOptionMenu,
    Pill,
    CopyInsuranceConfirmationModal,
    ShowOldPoliceNumbersModal,
    AddDocument,
    EmailVersand,
  },
  data() {
    return {
      loadingActivities: false,
    }
  },
  computed: {
    versicherungId() {
      return this.$route?.params?.versicherungId;
    },
    customOptionsMenu() {
      return [];
    }
  },
  methods: {
  },
  async mounted() {


  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      to, from
    });  

    next();
  }

}
</script>
<style lang="scss" scoped>
.subtitle-part {
  word-break: break-word;
}
</style>