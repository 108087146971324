<template>
  <div>
    <PageHeaderTitleNavigation 
      title="DBM" 
      :subtitle="$appNavigation.currentMenu.label" 
    />

    <div class="box__container" v-if="this.refreshInterval!=0 || showLoadButton" >
      <BaseButton v-if="this.refreshInterval!=0" @click="run" :disabled="this.syncRun">{{ buttonCol }} </BaseButton>
      <BaseButton v-if="showLoadButton" @click="handleLoad"
        >{{ loadButtonLabel }}
      </BaseButton>
    </div>
  </div>
</template>
<script>
import DB_TYPES from "@/store/dbm/types";

import { mapGetters } from "vuex";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import BaseButton from "@/components/core/BaseButton.vue";

export default {
  props: {
    query: {
      type: Object,
      
    },
    act: {
      type: String,
      default: DB_TYPES.ACTIONS.DATA,
    },
    refreshInterval:{
      type: Number,
      default: 300
    } 
    ,
    showLoadButton: {
      type: Boolean,
      default: false,
    },
    loadButtonLabel: {
      type: String,
      default: "load session",
    },
  },
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    BaseButton,
  },
  watch: {
    startCallTime(next, oldValue) {
      if (next && next != oldValue) {
        this.setTimerUpdater();
      } else {
        this.clearTimerUpdater();
      }
    },
  },
  computed: {
    ...mapGetters({
      startCallTime: DB_TYPES.GETTERS.START_TIME,
      syncRun: DB_TYPES.GETTERS.RUNNIG,
    }),

    buttonCol() {
      let c = this.callDuration;
      if (!c) {
        c = "00:00";
      }
      let res = "Refresh" + " " + c;

      return res;
    },
  },
  data() {
    return {
      apiAddress: process.env.VUE_APP_API,
      
      running: false,
      ch: false,
      confirmWarning: "",
      confirmRow: {},
      selectedRows: [],
      callDuration: "",

      timer: null,
    };
  },

  mounted() {
    this.running = (this.refreshInterval!=0);

    this.do();
  },
  beforeDestroy() {
    this.running = false;
    this.clearTimerUpdater();
  },
  methods: {
    handleLoad() {
      this.$emit("onLoadButton");
    },
    async do() {
      this.$store.commit(DB_TYPES.MUTATIONS.DATA, null);

      await this.run();
    },
    async run() {
      try {
        this.$store.commit(DB_TYPES.MUTATIONS.RUNNIG, true);
        this.clearTimerUpdater();
        this.$store.commit(DB_TYPES.MUTATIONS.START_TIME, null);

        await this.$store.dispatch(this.act, this.query);
        this.$store.commit(DB_TYPES.MUTATIONS.START_TIME, new Date().getTime());
      } finally {
        this.$store.commit(DB_TYPES.MUTATIONS.RUNNIG, false);
      }
    },

    

    async updateTime() {
      if (this.startCallTime) {
        const seconds = Math.floor((Date.now() - this.startCallTime) / 1000);
        const seconds1 = this.refreshInterval - seconds;
        this.callDuration =
          ("" + Math.floor(seconds1 / 60)).padStart(2, "0") +
          ":" +
          ("" + (seconds1 % 60)).padStart(2, "0");
        if (seconds >= this.refreshInterval && this.running) {
          await this.run();
        }
      } else this.callDuration = "";
    },
    setTimerUpdater() {
      this.clearTimerUpdater();
      this.timer = setInterval(() => this.updateTime(), 1000);
    },
    clearTimerUpdater() {
      this.callDuration = "";
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
  },
};
</script>

<style scoped>
.subject {
  display: inline-block;
  max-width: 700px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>