var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.type === "percent"
    ? _c("CurrencyLabel", { attrs: { value: _vm.value, symb: "%" } })
    : _vm.type === "double"
    ? _c("CurrencyLabel", { attrs: { value: _vm.value, symb: "" } })
    : _vm.type === "currency"
    ? _c("CurrencyLabel", { attrs: { value: _vm.value } })
    : _c("div", [_vm._v(_vm._s(_vm.value))])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }