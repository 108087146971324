var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("FlexibleList", {
        attrs: { rows: _vm.rows, showThirdRow: "" },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function(row) {
              return [
                _vm._v(
                  " " + _vm._s(row.WERTPAPIER_NAME || row.LAGERSTELLE) + " "
                )
              ]
            }
          },
          {
            key: "value",
            fn: function(row) {
              return [
                _vm._v(
                  " " + _vm._s(_vm._f("currency")(row.Euro || row.PREIS)) + " "
                )
              ]
            }
          },
          {
            key: "thirdRow",
            fn: function(row) {
              return [_vm._v(" " + _vm._s(row.DEPOTNUMMER || row.ISIN) + " ")]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }