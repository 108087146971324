var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-forms__container" }, [
    _c(
      "label",
      { staticClass: "input-forms__label-container" },
      [
        _vm.label
          ? _c("div", { staticClass: "input-forms__label-content" }, [
              _vm._v(_vm._s(_vm.label))
            ])
          : _vm._e(),
        _vm.isComponentVisible
          ? [
              _c("div", { staticClass: "row no-margin-y" }, [
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c("ComboBox", {
                      attrs: {
                        label: _vm.comboBoxComponent.label,
                        values: _vm.comboBoxComponent.values,
                        value: _vm.antragData[_vm.comboBoxComponent.id],
                        disabled: _vm.disabled
                      },
                      on: { input: _vm.onInputComboBox }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-auto" },
                  [
                    _c("InputField", {
                      attrs: {
                        label: _vm.inputFieldComponent.label,
                        value: _vm.antragData[_vm.inputFieldComponent.id],
                        disabled: _vm.disabled
                      },
                      on: { change: _vm.onChangeInputText }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-auto" },
                  [
                    _c("InputToggleSwitch", {
                      attrs: {
                        label: _vm.inputToggleSwitch.label,
                        value: _vm.antragData[_vm.inputToggleSwitch.id],
                        disabled: _vm.disabled
                      },
                      on: { input: _vm.onInputToggleSwitch }
                    })
                  ],
                  1
                )
              ])
            ]
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }