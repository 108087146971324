<template>
  <div>
    <BaseModal 
      ref="editModal"
      modalTitle="Tabellen-Eingenschaften"
      @onCloseButton="close()"
      @close="close()"
      @onConfirmButton="onConfirmButton">

      <InputField
        v-if="isInsertMode"
        label="Zeile"
        v-model="internTableForm.rows">
      </InputField>

      <InputField
        v-if="isInsertMode"
        label="Spalte"
        v-model="internTableForm.cols">
      </InputField>

      <ComboBox
        label="Rahmengröße"
        :values="borderOptions"
        :sortComboboxValues="false"
        v-model="internTableForm.border">
      </ComboBox>

      <InputToggleSwitch
        label="Kopfzeile"
        v-model="internTableForm.withHeaderRow">
      </InputToggleSwitch>
    </BaseModal>
  </div>
</template>

<script>
import BaseModal from '@/components/core/BaseModal.vue';
import InputField from '@/components/core/forms/InputField.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import { BORDER_OPTIONS } from '../custom-extensions/CustomTable';

function defaultTableForm() {
  return {
    rows: 3,
    cols: 2,
    withHeaderRow: true,
    border: '1',
  }
}

const MODES = ['EDIT', 'INSERT']

export default {
  props: {
    tableForm: {
    },
    mode: {
      type: String,
      default: 'INSERT',
      validator: (value) => MODES.includes(value),
    }
  },

  data() {
    return {
      internTableForm: defaultTableForm(),
      borderOptions: [ ...BORDER_OPTIONS ]
    }
  },

  computed: {
    isInsertMode() {
      return this.mode === 'INSERT';
    },
    isEditMode() {
      return this.mode === 'EDIT';
    }
  },

  methods: {
    close() {
      this.$emit("close")
    },
    onConfirmButton() {
      this.$emit('onConfirmButton', this.internTableForm)
    }
  },
  mounted() {
    if (this.tableForm) {
      Object.assign(this.internTableForm, this.tableForm)
    }

    this.$refs.editModal.open()
  },

  components: {
    BaseModal,
    InputField,
    InputToggleSwitch,
    ComboBox,
  },
}
</script>
