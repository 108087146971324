import Vue from 'vue'
import FinancialCalculator from '@/components/retirementScenario/financialCalculator';

export default class StepAllocation { 
  source; 
  tableData = {
    headers: {
      name:{label: 'Assetallocation'}
      /*
      plz: {
      label: 'PLZ',
      key: 'plz',
      dataType: 'String',
      priority: 'VISIBLE_ALWAYS',
      sortable: false,
      filterable: false,
      sum: false,
      fixed: true,
    },
    adresse:{
      label: 'Adresse',
      key: 'ADRESSE',
      dataType: 'String',
      priority: 'COLLAPSED',
      sortable: true,
      filterable: false,
      sum: false,
      fixed: true,
    }*/},
    records: [/*{
      plz: '89879',
      adresse: 'Teststr. 77'
    }*/]
  };
  quelle;
  purpose;
  modified = false;
  loading = false;

  constructor(quelle, purpose) {
    this.quelle = quelle;
    this.purpose = purpose;
  }

  data() {
    return {
      accordanceCondition: undefined,
    };
  };

  category() {
    return this.quelle ? this.quelle : this.purpose;
  };

  sum() {
    let s = 0.0;
    this.tableData.records.forEach(element => {
        if (element['value']) {
            s += Number(element['value']);
        }
    });
    return s;
}
};
