<template>
    <div>
        <OptionMenu
            :id="$appNavigation.currentOptionMenuId"
            :defaultMenu="$appNavigation.currentOptionMenu" >
        </OptionMenu>

        <PageHeaderTitleNavigation
            :title="$appNavigation.currentMenu.label"
            :subtitle="subtitle"
            :id="$appNavigation.currentOptionMenuId">
        </PageHeaderTitleNavigation>

        <StepperForm
            stepperName="retirement"
            :stepperMediator="stepper"
            :selectedStepKey="stepKey"
            @set-step="setStepByKey"
            >

            <template #contentTemplate>
                <RuhestandsplanungStep />
            </template>
        </StepperForm>
    </div>
</template>

<script>
import StepperForm from '@/components/stepper/StepperForm.vue';
import { StepperMediator } from '@/components/stepper/utils';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import RuhestandsplanungStep from '@/views/ruhestandsplanung/RuhestandsplanungStep.vue';
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';
import mixin from '@/views/ruhestandsplanung/mixin.js';

export default {
    mixins: [mixin],
    components: {
        StepperForm,
        PageHeaderTitleNavigation,
        OptionMenu,
        RuhestandsplanungStep,
    },
    data() {
        return {
            steps: [
                {
                    stepKey: 'settings',
                    label: 'Einstellungen',
                    totalProgress: 1
                }, {
                    stepKey: 'gesamtvermoegen',
                    label: 'Gesamtvermögen',
                    totalProgress: 2
                }, {
                    stepKey: 'scenario',
                    label: 'Szenario',
                    totalProgress: 3
                }, {
                    stepKey: 'overview',
                    label: 'Zusammenfassung',
                    totalProgress: 4
                }],
        }
    },
    mounted() {
    },
    computed: {
        subtitle() {
            return `<b>${this.scenario.title || ''}</b>`;
        },
        stepKey() {
            return this.$route.params.stepKey;
        },
        stepper() {
            return new StepperMediator(JSON.parse(JSON.stringify(this.steps)));
        },
        actions() {
            const actions = [];
            if (this.scenario) {
                actions.push(PageHeaderSimpleAction('NEW_TICKET', 'Nachricht an Betreuer'))
            }
            return actions;
        }
    },
    methods: {
        setStepByKey(stepKey) {
            if (stepKey && this.stepKey !== stepKey) {
                this.saveScenario();
                this.$router.push(`${this.path}/${this.id}/${stepKey}`)
            }
        },
    },
    beforeRouteLeave(to, from, next) {
        this.saveScenario();
        next();
    },
}
</script>

<style>

</style>