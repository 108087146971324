<template>
  <div class="input-forms__container">
    <div class="input-forms__label-container">
      <ComboBox
        isComponentHalfSize
        v-model="antragData['INPUT_DEPOTPOSITION_AUSWAHL']"
        :values="depots"
        :sortComboboxValues="false"
        @input="setDepotpositionVerkauf($event)"
      />

      <div class="col">
        <div
          class="input-forms__label-content input-forms__label-content--bigger"
        >
          <ph-bank :size="24" />&nbsp; Tausch In:
        </div>
      </div>

      <div class="mt-3 pl-2">
        <div class="mt-1">
          <BaseButton
            :isEmbedded="true"
            :disabled="disabled || isinVerkauf === '' || rows.length > 4"
            @click="openFormsFinder"
            >Fonds hinzufügen</BaseButton
          >
        </div>
        <span class="mr-2">oder</span>
        <div class="mt-1">
          <BaseButton
            :isEmbedded="true"
            :disabled="disabled || isinVerkauf === '' || rows.length > 4"
            @click="splitPosition"
            >Neue Depotposition</BaseButton
          >
        </div>
      </div>
    </div>

    <div class="input-forms__input-container mt-3">
      <Table
        v-if="rows && rows.length"
        :headers="headers"
        :rows="rows"
        rowId="index"
        hidePagination
        @action-DELETE="openModalDeleteFond"
      >
        <template v-slot:betrag="row">
          <InputField
            type="currency"
            :precision="2"
            :value="row.betrag"
            :id="'betrag' + (row.posGrpId || row.index)"
            @change="onChangeInputField($event, row, 'betrag')"
          />
        </template>
        <template v-slot:bemerkung="row">
          <InputField
            :value="row.bemerkung"
            :id="'bemerkung' + row.index"
            @change="onChangeInputField($event, row, 'bemerkung')"
          />
        </template>
      </Table>
      <div v-if="config && config.positionFields && positions && positions.length">
        <WertpapierAuswahlPositionFields
          :config="config"
          :categoryId="categoryId"
          :antragId="antragId"
          :disabled="disabled"
        />
      </div>
    </div>
    <BaseModal
      ref="fehlerModal"
      labelButtonCancel="Ok"
      :showConfirmButton="false"
    >
      <template v-slot:modalTitle>
        <ph-warning :size="16" class="color-danger mr-2" />
        <span class="color-danger">
          Fehler: Sie haben die maximal mögliche Anzahl an Fonds erreicht. Es
          sind nur 5 Positionen möglich.</span
        >
      </template>
    </BaseModal>
    <DeletePositionModal
      ref="deleteModal"
      :position="positionToDelete"
      @delete="doDeletePosition()"
    ></DeletePositionModal>
  </div>
</template>

<script>
import ANTRAG_TYPES from "@/store/antrag/types";
import FONDSFINDER_TYPES from "@/store/fondsfinder/types";
import BaseButton from "@/components/core/BaseButton.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputField from "@/components/core/forms/InputField.vue";
import antragNavigationMixin from "@/mixins/antrag/antrag-navigation-mixin.js";
import BaseModal from "@/components/core/BaseModal.vue";
import Table from "@/components/table2/Table.vue";
import {
  TextColumn,
  ActionColumn,
  SlotColumn,
  SimpleAction,
} from "@/components/table2/table_util.js";
import WertpapierAuswahlPositionFields from "@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlPositionFields.vue";
import DeletePositionModal from "@/components/core/forms/antraege/wertpapierAuswahl/DeletePositionModal.vue";
import { mapGetters } from "vuex";
import antragMixin from "@/mixins/antrag/antrag-mixin.js";
import { PhWarning, PhBank } from "phosphor-vue";
import validator from "@/mixins/validator";

export default {
  mixins: [antragMixin, antragNavigationMixin, validator],
  validators: {},
  props: {
    title: {},
    antragData: {},
    config: {},
    antragTyp: {},
    antragId: {},
    comboboxSelections: {},
    suppressFilterLagerstelle: {
      type: Boolean,
      default: false,
    },
    positionWarnings: [],
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      positionToDelete: null,
      headers: {
        lockedLeft: [
          TextColumn("nameVerkauf", "Depotposition"),
          TextColumn("isinVerkauf", "ISIN"),
          SlotColumn("betrag", "Betrag", 100),
        ],
        center: [
          TextColumn("isin", "ISIN").makeHidden,
          TextColumn("isinKauf", "Tausch in"),
          TextColumn("nameKauf", "Fondsname"),
          SlotColumn("bemerkung", "Bemerkung"),
        ],
        lockedRight: [ActionColumn("actions", "")],
      },
      categoryId: 'KAUF', 
      additionalIdentifier: ['isinVerkauf'],
    };
  },
  components: {
    BaseButton,
    BaseModal,
    ComboBox,
    InputField,
    Table,
    DeletePositionModal,
    WertpapierAuswahlPositionFields,
    PhWarning,
    PhBank,
  },
  watch: {
    positionWarnings(newValue) {
      this.updateWarnings();
    }
  },
  mounted() {
    this.updateWarnings();
  },
  computed: {
    ...mapGetters({
      positionInfo: FONDSFINDER_TYPES.GETTERS.POSITION_INFO,
      positionsAll: ANTRAG_TYPES.GETTERS.POSITIONS,
      positionDataAll: ANTRAG_TYPES.GETTERS.POSITION_DATA,
    }),
    depots() {
      return this.antragData
        ? JSON.parse(this.antragData["INPUT_DEPOTPOSTIONEN"])
        : [];
    },
    positions() {
      if (
        this.positionsAll &&
        this.antragId &&
        this.positionsAll[this.antragId]
      ) {
        let result = this.positionsAll[this.antragId]["SPARPLAN"] || [];
        result = result.concat(this.positionsAll[this.antragId]["KAUF"] || []);

        if (result.length) {
          result.map((pos, index) => {
            if (!pos.begleitscheinId) {
              const begleitscheinId = this.depots?.find(depot => depot.value === pos.isinVerkauf)?.bgsNr || 0;
              this.commitUpdatePositionsFromEvent({begleitscheinId: begleitscheinId, isinVerkauf: pos.isinVerkauf}, pos.isin);
            }
            Object.assign(pos, {
              index: index,
              isin: this.getIsin(pos),
              isinKauf: this.getIsin(pos),
              nameKauf: this.getFondsName(pos),
              fondsname: this.getFondsName(pos),
              betrag: pos.betrag,
              isinVerkauf:
                pos.isinVerkauf ||
                this.isinVerkauf ||
                "",
              nameVerkauf: this.getNameVerkauf(
                pos,
                this.isinVerkauf
              ),
              lagerstelle: "EBASE",
            });
          });
        }
        return result;
      }
    },
    rows() {
      const actions = [SimpleAction("DELETE", "PhTrash", "Löschen")];
      return (this.positions || []).map((row) => ({
        ...row,
        actions,
      }));
    },
    isinVerkauf() {
      return this.antragData["INPUT_DEPOTPOSITION_AUSWAHL"] || '';
    },
  },
  methods: {
    openFormsFinder() {
      this.config.SINGLE_SELECTION = true;
      if (this.positions?.length >= 5) {
        this.$refs.fehlerModal.open();
      } else {
        this.goToFormsFinder(
          'TAUSCH',
          Object.assign(this.config || {}, {query: JSON.stringify({
            additionalIdentifier: this.additionalIdentifier, 
            isSparplan: true,
            isinVerkauf: this.isinVerkauf,
          })}),
          this.suppressFilterLagerstelle
        );
      }
    },
    splitPosition() {
      let result = [];

      if (this.positionsAll[this.antragId]) {
        result = result.concat(
          this.positionsAll[this.antragId]["SPARPLAN"] || []
        );
        result = result.concat(this.positionsAll[this.antragId]["KAUF"] || []);
      }

      const isin = this.isinVerkauf; 

      const fondsname =
        (this.positionInfo?.[isin]?.wertpapiername) || this.depots?.find(depot => depot.value === isin)?.fondsname || "";

      const bgsNr = this.depots?.find(depot => depot.value === isin)?.bgsNr || 0;

      const payload = {
        id: this.antragId,
        categoryId: this.categoryId,
        fonds: [
          {
            isin: isin,
            art: this.categoryId,
            isinKauf: isin,
            isinVerkauf: isin,
            istVWL: false,
            lagerstelle: "EBASE",
            nameKauf: fondsname,
            nameVerkauf: fondsname,
            fondsname: fondsname,
            typ: "EBASE_FONDSUMSCHICHTUNG",
            bemerkung: null,
            anlagezielZeile: null,
            begleitscheinId: bgsNr,
            isinVerkauf: isin,
          },
        ],
      };

      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_POSITIONS, payload);
    },
    getFondsName(pos) {
      return (
        pos.nameKauf ||
        (this.positionInfo &&
          this.positionInfo[pos.isin] &&
          this.positionInfo[pos.isin].wertpapiername) ||
        pos.fondsname ||
        pos.wertpapiername
      );
    },
    getIsin(pos) {
      return (
        pos.isinKauf ||
        (pos &&
          this.positionInfo &&
          this.positionInfo[pos.isin] &&
          (this.isWkn ? this.positionInfo[pos.isin].wkn : pos.isin))
      );
    },

    getNameVerkauf(pos, verkaufIsin) {
      if (pos.nameVerkauf) {
        return pos.nameVerkauf;
      } else {
        const found = this.depots.find((x) => x.value == verkaufIsin);
        return found?.label
          ? found?.label.substring(0, found.label.lastIndexOf("-"))
          : "";
      }
    },
    setDepotpositionVerkauf(isin) {
      const payload = {
        id: this.antragId,
        data: {
          INPUT_DEPOTPOSITION_AUSWAHL: isin,
        },
      };
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_ANTRAG_DATA, payload);
    },
    commitUpdatePositionsFromEvent(positionChange, isin) {
      const payload = {
        id: this.antragId,
        categoryId: this.categoryId,
        fonds: [
          {
            ...positionChange,
            isin,
          },
        ],
        additionalIdentifier: this.additionalIdentifier,
      };

      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_POSITIONS, payload);
    },
    onChangeInputField(value, position, field) {
      const positionChange = { [field]: value === "" ? null : value, isinVerkauf: position.isinVerkauf };
      this.commitUpdatePositionsFromEvent(positionChange, position.isinKauf);
    },
    openModalDeleteFond(fond) {
      this.positionToDelete = fond;
      this.$refs.deleteModal.open();
    },
    doDeletePosition() {
      this.deletePosition(
        this.antragId,
        this.categoryId,
        this.positionToDelete
      );
      this.positionToDelete = null;
    },
    updateWarnings() {
      this.positionWarnings?.forEach(warn =>  {
        const position = this.positions.find(pos => warn.posGrpId === pos.posGrpId);       
        if (position?.posGrpId) {            
          const path = `betrag${position.posGrpId}`;
          this.$pushErrors(path, ['Betrag ist zu groß']);
        }
      });
    },
  },
};
</script>
