<template>
<div>
    <BoxContainer :title="selectedTabLabel">
        <Table v-if="rows.length"
            :headers="headers"
            :mobileConfig="{title: 'Steuerliches Ergebnis', headers: ['country', 'year', 'commentary']}"
            :rows="rows"
            rowId="index"
            hidePagination
        />
    </BoxContainer>
</div>
</template>

<script>
import FONDSINFO_TYPES from "@/store/fondsinfo/types";
import BoxContainer from '@/components/core/BoxContainer.vue';
import { mapGetters } from 'vuex';
import Table from "@/components/table2/Table.vue";
import {TextColumn, DateColumn, PercentageColumn} from "@/components/table2/table_util.js";

export default {
  components: {
    BoxContainer,
    Table,
  },
  name: "TabSteuerlichesErgebnis",
  props:{
    selectedTabLabel: {
      type: String
    }
  },
    data() {
        return {
            headers: {
                center: [
                    TextColumn("country", "Land"),
                    TextColumn("year", "Jahr"),
                    PercentageColumn("planPercentage", "Soll"),
                    PercentageColumn("actualPercentage", "Ist"),
                    TextColumn("incomeCategory", "Einkunftsart"),
                    TextColumn("commentary", "Kommentar", undefined, 10),
                ],
            }
        };
    },
  computed: {
    ...mapGetters({steuerlichesErgebnis : FONDSINFO_TYPES.GETTERS.FONDSINFO_STEUERLICHES_ERGEBNIS}),
    rows() {
        return (this.steuerlichesErgebnis || []).map((row, index) => ({
            index,
            ...row,
        }));
    },
  },
  mounted() {
    if(!this.steuerlichesErgebnis) {
      this.$store.dispatch(FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_STEUERLICHES_ERGEBNIS, 
      {
        isin: this.$route.params?.isin,
        bgsNr: this.$route.params?.bgsNr,
      });
    }
  },

}
</script>

<style>

</style>