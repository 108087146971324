import TAPI from './types';

import axios from 'axios';
import CORE_TYPES from "@/store/core/types";


export default {

  [TAPI.ACTIONS.CONNECT]({ commit, getters, dispatch, state, rootState }, e) {
    const config = {
      defaultSpinner: true,
    };

    const li = state.LINE//getters[TAPI.GETTERS.LINE];

    let payload = {};
    payload.line = li;
    payload.type = e.param;
    if (state.PHONE_NUMBER) {
      payload.phone = state.PHONE_NUMBER;
    }
    axios.post(rootState.core.apiAddress + '/Tapi/connect', payload, config).then(response => {
      
      let me = response.data.message;

     // console.log("sendTapiMessage ", me);

      commit(TAPI.MUTATIONS.PHONE_NUMBER, response.data.phoneNumber);
      state.tapiSocket.send(me);
      if(state.TAPI_PARAMS && state.TAPI_PARAMS.debug){
        let pr={};
        pr.message=me;
        pr.send=true;
        
        axios.post(rootState.core.apiAddress + '/Tapi/debug', pr).then(response => {
            
        });
      }
      commit(TAPI.MUTATIONS.CONNECT, response.data);
     
      if (e.param == 'ACCEPTCALLOUT') {
        if (getters[TAPI.GETTERS.CALLER]) {
          let caller = getters[TAPI.GETTERS.CALLER];
          
          if (caller.id && caller.userType == 'Kunde') {
            dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { customerId: caller.id });
          }
          if (caller.id && caller.userType == 'Makler') {
            let brok = caller.id;
            dispatch(CORE_TYPES.ACTIONS.OPEN_BROKER_NEW_TAB, { brokerId: brok });
          }
         
        }
      }
     
    })
  },
  [TAPI.ACTIONS.CLOSE]({ commit, state, dispatch, rootState }) {

    const config = {
      defaultSpinner: true,
    };
    if (state.tapiSocket) {


      state.tapiSocket.close();
      state.tapiSocket = null;
    }


  },
  async [TAPI.ACTIONS.SAVE_TAPI_PARAMS]({ commit, getters, dispatch, state, rootState }, e) {

    const config = {
      defaultSpinner: true,
    };

    await axios.post(rootState.core.apiAddress + '/Tapi/setTapiParams', e, config).then(response => {
      commit(TAPI.MUTATIONS.TAPI_PARAMS, e);
    });


  },

  async [TAPI.ACTIONS.LINES]({ commit, getters, dispatch, state, rootState }, lines) {
    
    const config = {
      defaultSpinner: true,
    };

    await axios.post(rootState.core.apiAddress + '/Tapi/getTapiLine', lines, config).then(response => {
      commit(TAPI.MUTATIONS.LINE, response.data.line);
      commit(TAPI.MUTATIONS.LINES, response.data);
    });

    if (getters[TAPI.GETTERS.HOST]) {
      commit(TAPI.ACTIONS.HOST, false);
      dispatch(TAPI.ACTIONS.HOST, true);
    }

  },
  

  [TAPI.ACTIONS.CAN_TAPI]({ commit, state, dispatch, rootState }) {

    const config = {
      defaultSpinner: true
    };
    // commit(TAPI.MUTATIONS.CAN_TAPI, e.bool);
    axios.get(rootState.core.apiAddress + '/Tapi/canTapi', config).then(response => {
      
      commit(TAPI.MUTATIONS.CAN_TAPI, response?.data?.canTapi);
      commit(TAPI.MUTATIONS.CAN_TAPI_HOST, response?.data?.canTapiHost);
      commit(TAPI.MUTATIONS.CAN_TAPI_BUTTON, response?.data?.canTapiButton);
      commit(TAPI.MUTATIONS.TAPI_PARAMS, response?.data?.TAPI_PARAMS);
      if (response?.data?.canTapi) {
        dispatch(TAPI.ACTIONS.START_WEB_SOCKET, response?.data?.tapiParameters);
      }
      
    })
  },
  [TAPI.ACTIONS.SET_CALLER]({ commit }, callerType, callerId) {
    let caller = {};
    caller.userType = callerType;
    caller.id = callerId;
    commit(TAPI.MUTATIONS.CALLER, caller)
  },
  async [TAPI.ACTIONS.TAPI_PARAMS]({ commit, state, dispatch, rootState }) {
    const config = {
      defaultSpinner: true
    };
    // commit(TAPI.MUTATIONS.CAN_TAPI, e.bool);
    await axios.get(rootState.core.apiAddress + '/Tapi/getTapiParams', config).then
      (response => {
       
        commit(TAPI.MUTATIONS.TAPI_PARAMS, response.data);


      })
  },
  [TAPI.ACTIONS.HOST]({ commit, state, dispatch, rootState }, e) {
   
    if (e) {
      dispatch(TAPI.ACTIONS.CONNECT, { param: "CONNECTLINEOUT" });
    } else {
      dispatch(TAPI.ACTIONS.CONNECT, { param: "DISCONNECTLINEOUT" });
    }
  },

  [TAPI.ACTIONS.CALL_DISCONNECT]({ commit, getters, dispatch, state, rootState }, e) {

    if (getters[TAPI.GETTERS.DIRECTION] == 'IN') {
      dispatch(TAPI.ACTIONS.CONNECT, { param: "HANGUPINCOMINGOUT" });
    } else {
      dispatch(TAPI.ACTIONS.CONNECT, { param: "HANGUPOUTGOINGOUT" });
    }
  },
  [TAPI.ACTIONS.ACTIVE_CALL]({ commit, state, dispatch, rootState }, e) {

    commit(TAPI.MUTATIONS.ACTIVE_CALL, e);
  },
  [TAPI.ACTIONS.START_WEB_SOCKET]({ commit, getters, state, dispatch, rootState }, tapiParams) {
    if (state.tapiSocket != null) {
      state.tapiSocket.close();
      state.tapiSocket= null;
    }
    dispatch(TAPI.START_WEB_SOCKET, tapiParams);
  }, 
  [TAPI.ACTIONS.START_WEB_SOCKET]({ commit, getters, state, dispatch, rootState }, tapiParams) {
    if (process.env.VUE_APP_USER === 'hannes') {
      return;
    }
    if (!tapiParams) {
      tapiParams = getters[TAPI.GETTERS.TAPI_PARAMS];
    }
    if (!tapiParams) {
      return;
    }
    
    
    if (state.tapiSocket != null) {
      //state.tapiSocket.close();
      return;
    }
    if (tapiParams.host == null) {
      tapiParams.host = localhost;
    }
    commit(TAPI.MUTATIONS.TAPI_PARAMS, tapiParams);
    const tapiSocketUrl = "ws://" + tapiParams.host + ":8077";
    const tapiSocket = new WebSocket(tapiSocketUrl/*"ws://localhost:8077"*/);
    state.tapiSocket = tapiSocket;
    commit(TAPI.MUTATIONS.WEB_SOCKET, tapiSocket);



    tapiSocket.onopen = event => {
      commit(TAPI.MUTATIONS.WEB_SOCKET_OPENED, true);  
      dispatch(TAPI.ACTIONS.CONNECT, { param: "GETLINESOUT", socket: tapiSocket });

    }
    tapiSocket.onclose = event => {
      commit(TAPI.MUTATIONS.WEB_SOCKET_OPENED, false); 
   
      state.tapiSocket= null;
    }
    tapiSocket.onmessage = event => {
     // console.log("tapi_debug", state.TAPI_PARAMS);

      let eventType = JSON.parse(event.data).eventType;
     // console.log('EVENT!!!!', eventType);
     // console.log('EVENTDATA!!!!', event.data);
     
      if(state.TAPI_PARAMS && state.TAPI_PARAMS.debug){
        let pr={};
        pr.message=event.data;
        pr.send=false;
        
        axios.post(rootState.core.apiAddress + '/Tapi/debug', pr).then(response => {
            
        });
      }
      if (eventType == 'GETLINESIN') {
        
        let lines = JSON.parse(event.data).avaliableLines;

        dispatch (TAPI.ACTIONS.LINES, lines);
     
       // line = JSON.parse(event.data).avaliableLines[0];
         
        
        //dispatch(TAPI.ACTIONS.CONNECT,{param:"CONNECTLINEOUT", socket : tapiSocket});  
      }
      if (eventType == 'OUTGOINGIN') {
        let numb = JSON.parse(event.data).phoneNumber;
        commit(TAPI.MUTATIONS.MODAL_PANEL_OPEN, true);
        commit(TAPI.MUTATIONS.ACTIVE_CALL, true);

        let inc = JSON.parse(event.data).call;
      
        if (inc.isIncoming) {
          commit(TAPI.MUTATIONS.DIRECTION, 'IN');
        } else {
          commit(TAPI.MUTATIONS.DIRECTION, 'OUT');
        }
      

      }

      if (eventType == 'CALLANSWEREDIN') {
        commit(TAPI.MUTATIONS.START_CALL_DATE, new Date().getTime());
        commit(TAPI.MUTATIONS.RINGING, false);
      }
      if (eventType == 'INCOMINGIN') {
        commit(TAPI.MUTATIONS.RINGING, true);
        let numb = JSON.parse(event.data).phoneNumber;
        let tapiParams = getters[TAPI.GETTERS.TAPI_PARAMS];
        if(numb !=null && tapiParams !=null && tapiParams.prefix!=null &&tapiParams.prefix.trim().length>0){
            numb= numb.trim();
            let prefix = tapiParams.prefix.trim();
            if(numb.length>prefix.length&&numb.substring(0,prefix.length)==prefix ){
                numb=numb.substring(prefix.length,numb.length-prefix.length+1);
            }
      }
        commit(TAPI.MUTATIONS.PHONE_NUMBER, numb);
         
        commit(TAPI.MUTATIONS.OPEN_INCOMMING_DIALOG, numb);
        commit(TAPI.MUTATIONS.MODAL_PANEL_OPEN, true);
        commit(TAPI.MUTATIONS.ACTIVE_CALL, true);
        commit(TAPI.MUTATIONS.DIRECTION, 'IN');
        //commit(TAPI.MUTATIONS.PHONE_NUMBER,numb)
        // dispatch(TAPI.ACTIONS.CONNECT,{param:"ACCEPTCALLOUT", socket : tapiSocket});  
        dispatch(TAPI.ACTIONS.CALLER, { number: numb, socket: tapiSocket });

      }

      if (eventType == 'CALLDISCONNECTEDIN') {
        let numb = JSON.parse(event.data).phoneNumber;
        commit(TAPI.MUTATIONS.PHONE_NUMBER, '');
        commit(TAPI.MUTATIONS.OPEN_INCOMMING_DIALOG, false);
        commit(TAPI.MUTATIONS.MODAL_PANEL_OPEN, false);
        commit(TAPI.MUTATIONS.ACTIVE_CALL, false);
        commit(TAPI.MUTATIONS.CALLER, null);
        commit(TAPI.MUTATIONS.START_CALL_DATE, null);
        commit(TAPI.MUTATIONS.RINGING, false);
        //commit(TAPI.MUTATIONS.PHONE_NUMBER,numb)
        // dispatch(TAPI.ACTIONS.CONNECT,{param:"ACCEPTCALLOUT", socket : tapiSocket});  


      }
      if (eventType == 'LINECONNECTEDIN') {
        commit(TAPI.MUTATIONS.HOST, true);
      }

      if (eventType == 'LINEDISCONNECTEDIN') {
        commit(TAPI.MUTATIONS.HOST, false);
      }
      //CALLDISCONNECTEDIN
    }
  },
  [TAPI.ACTIONS.CALLER]({ commit, state, dispatch, rootState }, e) {
    const config = {
      defaultSpinner: true,
    };

    const li = state.LINE;//getters[TAPI.GETTERS.LINE];


    axios.get(rootState.core.apiAddress + '/Tapi/getCaller?number=' + e.number, config).then(response => {
      commit(TAPI.MUTATIONS.OPEN_INCOMMING_DIALOG, response.data.message);
      commit(TAPI.MUTATIONS.CALLER, response.data);
     // console.log(response.data.message);
    })
  },
  [TAPI.ACTIONS.OPEN_CALL_OUT_DIALOG]({ commit, state, dispatch, rootState }, par) {
    //user_type,user_id,phomne_number
    const config = {
      defaultSpinner: true,
    };

    commit(TAPI.MUTATIONS.CALL_OUT_US_ID, par.userId);
    commit(TAPI.MUTATIONS.CALL_OUT_USER_TYPE, par.userType);
    commit(TAPI.MUTATIONS.CALL_OUT_NUMBER, par.phoneNumber);

  }

}


