var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _vm.zoom
            ? _c("ComboBox", {
                attrs: { value: _vm.years, values: _vm.zoom },
                on: { change: _vm.onChangeYear }
              })
            : _vm._e()
        ],
        1
      ),
      _c("ChartFunds", {
        attrs: { type: "Fondsinfo", chartData: _vm.chartPerf }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }