var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "config-edit-param-label__container" },
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Infofilme / Webinare",
          id: _vm.$appNavigation.currentOptionMenuId
        }
      }),
      _vm.isIntern
        ? _c(
            "DragnDropArea",
            {
              attrs: { hoverText: "Datei hier ablegen" },
              on: {
                files: function($event) {
                  return _vm.addDocument($event)
                }
              }
            },
            [
              _c("div", { staticClass: "box__container" }, [
                _c("div", { staticClass: "d-block justify-content-between" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-between hide-on-large-screen"
                    },
                    [
                      _c("div", { staticClass: "box__title" }, [
                        _vm._v("Neue Infofilme")
                      ]),
                      _c(
                        "BaseContextMenu",
                        [
                          _c(
                            "ContextMenuItem",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.openAddDocument()
                                }
                              }
                            },
                            [
                              _vm._t("default", function() {
                                return [_vm._v("Infofilme hochladen")]
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "hide-on-small-screen" }, [
                    _c("div", { staticClass: "box__title" }, [
                      _vm._v("Neue Infofilme")
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex d-md-block mt-1 layout__negative-margin--8 antrag-action-button__container justify-content-center"
                      },
                      [
                        _c(
                          "BaseButton",
                          {
                            attrs: { isSecondary: "" },
                            on: {
                              click: function($event) {
                                return _vm.openAddDocument()
                              }
                            }
                          },
                          [
                            _vm._t("default", function() {
                              return [_vm._v("Infofilme hochladen")]
                            })
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ])
                ])
              ])
            ]
          )
        : _vm._e(),
      _c("BaseFilter", {
        attrs: {
          title: "Infofilme-Filter",
          filterId: "ff81088d-38cb-46e7-bdfc-11a80b34409d",
          metadata: _vm.filterMetadata,
          configFilter: _vm.configFilter,
          showSaveButton: true,
          defaultOptions: _vm.aktFilter
        },
        on: {
          onFilter: function($event) {
            return _vm.search($event)
          },
          onFilterZurucksetzen: function($event) {
            return _vm.search(null)
          }
        }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("Table", {
            attrs: {
              title: "Infofilme-Liste",
              tableId: "b65a1742-555d-4152-9259-6037d5fba9f6",
              headers: _vm.headers,
              rows: _vm.rows
            },
            on: { "action-EDIT": _vm.editFile },
            scopedSlots: _vm._u([
              {
                key: "BEZEICHNUNG",
                fn: function(row) {
                  return [
                    _c("DownloadLink", {
                      attrs: {
                        title: row.BEZEICHNUNG || row.NAME,
                        filename: row.NAME,
                        downloadServicePath: "/downloadFileById",
                        queryParams: { id: row.FILE_ID }
                      }
                    })
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "addDocument",
          attrs: {
            labelButtonConfirm: _vm.fileNeu ? "Upload starten" : "Speichern",
            showCancelButton: true
          },
          on: {
            onConfirmButton: _vm.saveChanges,
            onCancelButton: _vm.closeAddDocumentModal,
            close: _vm.onClose
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [_vm._v(" Infofilm und Webinare ")]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "div",
            [
              _c(
                "DragnDropArea",
                {
                  attrs: { hoverText: "Datei hier ablegen" },
                  on: {
                    files: function($event) {
                      return _vm.addDocument($event)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    [
                      _vm.isEdit
                        ? [
                            !_vm.fileNeu
                              ? _c("div", [
                                  _vm._v("Dateiname: " + _vm._s(_vm.editName))
                                ])
                              : _vm._e(),
                            _c("InputToggleSwitch", {
                              attrs: { label: "Neue Datei", inLineLabel: "" },
                              model: {
                                value: _vm.fileNeu,
                                callback: function($$v) {
                                  _vm.fileNeu = $$v
                                },
                                expression: "fileNeu"
                              }
                            })
                          ]
                        : _vm._e(),
                      _vm.fileNeu
                        ? _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col" },
                              [
                                _c(
                                  "BaseButton",
                                  {
                                    staticClass: "upload-btn",
                                    attrs: { isSecondary: "" }
                                  },
                                  [
                                    _vm._t("default", function() {
                                      return [_vm._v("Datei hochladen")]
                                    }),
                                    _c("input", {
                                      attrs: {
                                        type: "file",
                                        accept: _vm.acceptFileTypes,
                                        multiple: ""
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.onAddFiles(
                                            $event.target.files
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  2
                                )
                              ],
                              1
                            ),
                            _c("div", { staticClass: "col" }, [
                              _vm._v(_vm._s(_vm.fileName))
                            ])
                          ])
                        : _vm._e(),
                      _c("hr"),
                      _c("InputToggleSwitch", {
                        attrs: {
                          label: "Sichtbar für Vermittlern",
                          inLineLabel: ""
                        },
                        model: {
                          value: _vm.fileSichtbar,
                          callback: function($$v) {
                            _vm.fileSichtbar = $$v
                          },
                          expression: "fileSichtbar"
                        }
                      }),
                      _c("InputField", {
                        attrs: { label: "Bezeichnung" },
                        model: {
                          value: _vm.fileTitle,
                          callback: function($$v) {
                            _vm.fileTitle = $$v
                          },
                          expression: "fileTitle"
                        }
                      }),
                      _c("InputTextArea", {
                        attrs: { label: "Bemerkung" },
                        model: {
                          value: _vm.fileDescription,
                          callback: function($$v) {
                            _vm.fileDescription = $$v
                          },
                          expression: "fileDescription"
                        }
                      }),
                      _c("div", [
                        _c("label", { staticClass: "gridlabel" }, [
                          _vm._v("Stand")
                        ]),
                        _c(
                          "div",
                          [
                            _c("DatePickerField", {
                              attrs: { dateFormat: "DD.MM.YYYY" },
                              model: {
                                value: _vm.fileStand,
                                callback: function($$v) {
                                  _vm.fileStand = $$v
                                },
                                expression: "fileStand"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ],
                    2
                  )
                ]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }