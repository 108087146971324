<template>
	<CoreCard ref="coreCard" :headers="headers" :rows="rows" :showToolbar="showToolbar" :listView="listView">
		<template v-if="showTitle" v-slot:header>
			{{chartName}}
		</template>
		<template v-slot:alternative>
		</template>
    <div class="content">
      <div v-if="isContainerRendered">
        <div class="chart-container">
          <Chart :data="chartData" type="pie" :isDataLabels="false" :isLegend="false" :colors="customColors" :mixedColors="false"></Chart>
        </div>
        <div class="legend-container" v-if="!isEmptyChart()">
          <div class="legend" v-if="chartData && chartData.data">
            <div v-for="(item, index) of chartData.data" class="legend-item" :key="index" :style="{color: getColor(index)}">
              {{item.label}}: {{item.value | currency}}
              <!-- € -->
            </div>
          </div>
        </div>
      </div>
    </div>
	</CoreCard>
</template>

<script>
import CoreCard from '../CoreCard/CoreCard'
import Chart from '../../charts/Chart.vue'
import { getColorsMixedContrastDecreasing } from '../../../helpers/colors-helper';
import cardMixin from '../CoreCard/card-mixin';
import { TextColumn, CurrencyColumn } from '@/components/table2/table_util';


export default {
  mixins: [cardMixin],
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    option: {
      type: String,
      default: [],
    },
  },
  data: function() {
    return {
      isContainerRendered: false,
      colorsApex: getColorsMixedContrastDecreasing(),
    };
  },

  computed: {
    chartName: function() {
      return this.propsData && this.propsData.option || this.option;
    },
    initData: function() {
      return this.propsData && this.propsData.data || this.data;
    },
    customColors: function() {
      return this.propsData.customColors;
    },
    chartData: function() {
      if (!this.initData) {return {name: '', data: []}}
      let resultData = [];
      if (this.initData[this.chartName]) {
        // Top fonds special
        if (this.initData[this.chartName].fonds) {
          this.initData[this.chartName].fonds.map(fond => {
            resultData.push({
              label: `${fond.Name}`, // (${fond.isin}) ${fond.Gesellschaft}
              value: +fond.Bestand
            })
          });
          // resultData.push({
          //   label: 'Rest',
          //   value: +this.initData[this.chartName].rest || 0,
          // })
        } else {
          Object.keys(this.initData[this.chartName]).filter(k => k !== 'Rest')
          .map(key => {
            const value = +this.initData[this.chartName][key];
            resultData.push({
              label: `${key}`,
              value: Array.isArray(value) ? value[0].toFixed(0) : value.toFixed(0)
            })
          });
        }
      }
      let result = {
        name: this.chartName,
        data: resultData.sort((a, b) => b.value - a.value)
      };
      if (result.data.length === 0 || result.data.length === 1 && result.data[0].value == 0) {
        return {
          ...result,
          data: [{
            label: '',
            value: 1
          }]
        };
      }
      return result;
    },
    headers() {
        return {
            lockedLeft: [
                TextColumn("label", ""),
            ],
            lockedRight: [
                CurrencyColumn("value", ""),
            ],
        };
    },
    rows() {
        return this.chartData.data;
    },
  },

  methods: {
    isEmptyChart() {
      return this.chartData.data.length === 0 || this.chartData.data.length === 1 && this.chartData.data[0].label == '';
    },
    getColor(index) {
      return this.colorsApex[index];
    },
  },
  filters: {
    currency(value, numberFixed = 0) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value/1).toFixed(numberFixed).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + '€';
    },
    percent(value, numberFixed = 2) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value/1).toFixed(numberFixed).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + '%';
    },
  },

  mounted: function () {
    this.$nextTick(function () {
      this.isContainerRendered = true;
    });
  },

  components: {
    CoreCard,
    Chart
  },
}
</script>

<style scoped>
/* .content {
  width: 100%;
  position: relative;
} */
.chart-container {
  height: 250px;
}
.legend-container {
  min-height: 45px;
}
.legend-item {
  padding-left: 10px;
}
.legend-item:before {
  content: "•";
  margin-left: -11px;
}
</style>