<template>
  <div>
    <VVSettingsSettings v-if="substepKey === 'settings'" />
    <VVSettingsGebuehren v-else-if="substepKey === 'gebuehren'" />
    <VVSettingsRichtBandbreite v-else-if="substepKey === 'richtBandbreite'" />
    <VVSettingsOthers v-else-if="substepKey === 'others'" />
    <VVSettingsZielmarkt v-else-if="substepKey === 'zielmarkt'" />
  </div>
</template>

<script>
import VVSettingsSettings from "@/views/vermoegensverwaltung/VVSettingsSettings.vue";
import VVSettingsGebuehren from "@/views/vermoegensverwaltung/VVSettingsGebuehren.vue";
import VVSettingsZielmarkt from "@/views/vermoegensverwaltung/VVSettingsZielmarkt.vue";
import VVSettingsOthers from "@/views/vermoegensverwaltung/VVSettingsOthers.vue";
import VVSettingsRichtBandbreite from "@/views/vermoegensverwaltung/VVSettingsRichtBandbreite.vue";

export default {
  components: {
    VVSettingsSettings,
    VVSettingsGebuehren,
    VVSettingsZielmarkt,
    VVSettingsOthers,
    VVSettingsRichtBandbreite,
  },
  computed: {
    substepKey() {
      return this.$route.query.substepKey || "settings";
    },
  },
};
</script>

<style scoped>
</style>