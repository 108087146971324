var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Einstellungen",
          subtitle:
            "Erlaubte Gesellschaften lt. Gewerbeordnung §34f Absatz 1 Satz 1 Nr. 1-3",
          actions: _vm.headerActions
        },
        on: {
          "action-DISCARD": function($event) {
            return _vm.discardChanges()
          },
          "action-ADD": function($event) {
            return _vm.openAddGesellschaftModal()
          }
        }
      }),
      _vm.isSmallOrMediumScreen
        ? [
            _vm.isLoadingData
              ? _c("GhostLoading", {
                  attrs: {
                    type: "block",
                    config: { block: { height: "120px" } },
                    useBoxContainer: ""
                  }
                })
              : _c("ConfigStepper3", {
                  ref: "stepper",
                  attrs: {
                    configStep: _vm.configStep,
                    selectedStep: _vm.selectedStep,
                    configData: _vm.configData
                  },
                  on: { setStep: _vm.setSelectedStep }
                })
          ]
        : _c("ContentWithStepper", {
            attrs: { stepperCollapsed: _vm.stepperCollapsed },
            scopedSlots: _vm._u([
              {
                key: "stepper",
                fn: function() {
                  return [
                    _c("ConfigVerticalStep", {
                      ref: "stepper",
                      attrs: {
                        configStep: _vm.configStep,
                        selectedStep: _vm.selectedStep,
                        configData: _vm.configData
                      },
                      on: {
                        collapsed: function($event) {
                          _vm.stepperCollapsed = $event
                        },
                        setStep: _vm.setSelectedStep
                      }
                    })
                  ]
                },
                proxy: true
              },
              {
                key: "content",
                fn: function() {
                  return [
                    _c(
                      "div",
                      { key: _vm.selectedStep },
                      [_c("Gesellschaft34fTyp")],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ])
          }),
      _c("BaseModal", {
        ref: "editModal",
        attrs: {
          modalTitle: _vm.editModalTitle,
          size: "lg",
          showDefaultButtons: false,
          actions: _vm.modalActions
        },
        on: {
          "action-DISCARD": function($event) {
            return _vm.discardChanges()
          },
          "action-ADD": function($event) {
            return _vm.openAddGesellschaftModal()
          },
          close: _vm.saveChanges
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "div",
                  { key: _vm.selectedStep },
                  [_c("Gesellschaft34fTyp")],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("AddMakler34fGesellschaftModal", {
        ref: "addMakler34fGesellschaftModal",
        attrs: { gesell34fTyp: _vm.gesellschaft34fTyp.id }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }