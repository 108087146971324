var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col" },
      [
        _c("InputField", {
          attrs: { label: _vm.label, value: _vm.value },
          on: {
            input: function($event) {
              return _vm.$emit("input", $event)
            }
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col color-picker" },
      [
        _c("InputColorPickerFull", {
          attrs: { value: _vm.value || _vm.previewValue },
          on: {
            input: function($event) {
              return _vm.$emit("input", $event)
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }