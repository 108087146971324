<template>
  <div>
    <PageHeaderTitleNavigation
      title="Kundensuche"
      :actions="headerActions"
      :defaultMenu="customOptionMenu"
      @action-SELECTION-DONE="handleBackAction"
      @action-NEW-CUSTOMER="addNewCustomer()"
      @action-NEW-CUSTOMER-GRUPPE="addNewCustomer('GRUPPE')"
      @action-NEW-CUSTOMER-AUSWEIS="addNewCustomer('AUSWEIS')"
    />

    <div class="search-filter" v-if="isLoaded">
      <GenericPersonFilter 
        filterId="76512a36-7861-4ff4-b939-88e0625eb8e9"
        title="Kundenfilter" 
        saveKey="customerSearchAdvanced" 
        :metadata="searchMenu" 
        :predefinedFilter="predefinedFilter" 
        @search="handleSearch($event)"
        @comboboxChange="onComboboxChange"
        :showButtons="!isExternalSelect"
        :defaultOptions="defaultOptionsData"
        :configFilter="configFilter"
        :immidiateSearch="immediateSearch"
        :isCache="$route.meta.isCache"
      />

      <div v-if="filtered" class="box__container" tid="f55d8574-902f-4663-9b34-1cbfb47c03e4">
        <GhostLoading v-if="loading && !scrollLoading" type="table" :title="TABLE_TITLE" class="m-0" />

        <NoData v-else-if="hasNoTableData" :title="TABLE_TITLE" content="Es wurden keine Einträge gefunden" />

        <template v-else-if="customerFilterSetup && customerFilterSetup.data && customersData && customersData.customers">
          <PaginatedTable
              class="m-0"
              :tableId="tableId"
              :title="TABLE_TITLE"
              :headers="headers"
              :pages="pages"
              :pageCount="pageCount"
              :rowCount="customersData.count || 0"
              :dragnDropFilesOnRow="isBroker && isTest"
              :selectionAsBlacklist="invertSelection"
              :sorted="customerSearchSort"
              :page="page"
              :pageSize="maxRowsPerPage"
              :menuRowsPerPage="menuRowsPerPage"
              @rowsPerPage="onPageSizeChange"
              :exportConfig="exportConfig"
              :thHoverDisabled="true"
              v-model="selectedRows"
              :headerActions="tableHeaderActions"
              :mobileConfig="{title: 'name', headers: ['active', 'customerID'], selectionActive: isExternalSelect}"
              @page="page = $event"
              @selectionAsBlacklist="invertSelection = $event"
              @files="handleRowFiles($event)"
              @requestPage="loadPage"
              @sort="onCustomerSearchResultSort"
              @localSort="customerSearchSort = $event"
              @click-name="openCustomerNewTab"
              @click-openFunds="row => openPathNewTab(row, '/home/vermogensubersicht/depot')"
              @click-insurances="openInsurancesNewTab"
              @click-closedFunds="row => openPathNewTab(row, '/home/vermogensubersicht/beteiligungen')"
              @click-kredite="row => openPathNewTab(row, '/home/verbindlichkeiten/credit')"
              @click-immobilie="row => openPathNewTab(row, '/home/immobilien/search')"
              @click-weitereVerbindlichkeiten="row => openPathNewTab(row, '/home/verbindlichkeiten/weitere-verbindlichkeiten/search')"
              @click-weitereVermoegen="row => openPathNewTab(row, '/home/vermogensubersicht/weitere-vermoegen/search')"
              @click-bankAccounts="row => openPathNewTab(row, '/home/account')"
              @click-mobil="row => callTapi(row,row.mobil)"
              @click-telefon="row => callTapi(row,row.telefon)"
              @click-maklernr="openMaklerNewTab"
              @selected="validateRowSelection"
              @onScroll="onScroll"
              @scrollLoading="scrollLoading = $event"
              @headerAction-KUNDEN-BEREINIGEN="openKundenBereinigenModal()"
              @headerAction-VERMOEGENSUEBERSICHTEN-BESTELLEN="vermoegensuebersichtenBestellen()"
              @headerAction-QUARTALSBERICHTE-BESTELLEN="quartalsBerichteBestellen()"
              @headerAction-ZUSAETZLICHE-INFORMATIONEN-EINTRAGEN="zusaetzlicheInformationenEintragen()"
              @headerAction-KUNDEN-AKTIV-SETZEN="setKundenInaktiv"
              @headerAction-KUNDEN-INAKTIV-SETZEN="setKundenInaktiv(true)"
              @headerAction-KUNDEN-LOESCHEN="KundenLoeschenConfirmation()"
              @headerAction-KUNDEN-DECKBLAETER="callKundenDeckblaeter"
              @headerAction-SEND-MESSAGE-TO-SELECTED="handleMailAll"
          >
              <template v-slot:name="row">
                <a v-if="row.customerID && !row.mobileTableContext" @click="openCustomerNewTab(row)" :class="{deceased: row.personDeceased}">{{row.name}}</a>
                <span v-else :class="{deceased: row.personDeceased}">{{row.name}}</span>
              </template>
              <template v-slot:customerID="row">
                  {{row.customerID}}<span class="color-text" v-if="row.data.externMaklerStatus">{{row.data.externMaklerStatus}}</span>
              </template>
              <template v-slot:fcAccess="row">
                  <CustomerFCUsage :isAPP="row.data.app" :isKSC="row.data.ksc" :dateAppNutzung="row.data.dateAppNutzung" :dateKSCNutzung="row.data.dateKSCNutzung"/>
              </template>
              <template v-slot:possibleContacts="row">
                  <CustomerApproach :kundenansprache="row.data.kundenansprache" />
              </template>
          </PaginatedTable>
        </template>
        
        <HandleTablePin keyOfPIN="TABELLE_KUNDENLISTE" />
      </div>

    </div>

    <CustomerDocumentUploadModal ref="customerDocumentUploadModal" />

    <KundenBereinigenModal ref="kundenBereinigenModal" :customerList="selectedLoadedRows" @save="purgeCustomers($event)"/>

    <BestellungenModal ref="bestellungenModal" @save="executeOrders($event)"/>

    <BaseModal  ref="executeOrdersResultModal" modalTitle="Info" labelButtonConfirm="Ok" :showCancelButton="false">
      {{ executeOrdersResult.value }}
    </BaseModal>  
    
    <ZusaetzlichenModal ref="zusaetzlichenModal" @save="executeOrders($event)"/>

    <BaseModal  ref="validationModal" modalTitle="Überprüfung" labelButtonConfirm="Ok" :showCancelButton="false" @close="onCloseValidationModal">
      {{ validationMessage }}
    </BaseModal>     

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CUSTOMER_SEARCH_TYPES from '@/store/customerSearch/types';
import QUARTALSBERICHTE_BESTELLUNGEN_TYPES from '@/store/quartalsberichteBestellungen/types';
import ZUSAETZLICHE_INFORMATIONEN_TYPES from '@/store/zusaetzlicheInformationen/types';
import CORE_TYPES from '@/store/core/types';
import BROKERDATA_TYPES from '@/store/brokerData/types'
import FC_CONFIG from '@/configs/fcConfig.js'
import validator from '@/mixins/validator';
import { required, numbers, email } from '@/mixins/validator/rules';
import { searchMetadata } from '@/components/customerSearch/customerSearchConstants.js'
import CustomerApproach from '@/components/customerSearch/CustomerApproach.vue'
import CustomerFCUsage from '@/components/customerSearch/CustomerFCUsage.vue'
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import GenericPersonFilter from '@/components/core/GenericPersonFilter.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import dayjs from "dayjs";
import CustomerDocumentUploadModal from './CustomerDocumentUploadModal.vue';
import PaginatedTable from "@/components/table2/PaginatedTable.vue";
import {CurrencyColumn, TextColumn, DateColumn, SlotColumn, NumberColumn, PillColumn, dateToSortable, MENU_ROWS_PER_PAGE, } from "@/components/table2/table_util.js";

const NOT_AVAILABLE_STRING = '';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import TAPI from '@/store/tapi/types';
import { VIEW_ROLES } from "@/router/roles";
import KundenBereinigenModal from './KundenBereinigenModal.vue';
import BestellungenModal from './BestellungenModal.vue';
import ZusaetzlichenModal from './ZusaetzlichenModal.vue';

import MAILCOMPOSER_TYPES from '@/store/mailcomposer/types';
import BaseModal from "@/components/core/BaseModal.vue";
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import HandleTablePin from '@/components/core/HandleTablePin.vue';

import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import { isPathAllowed, } from '@/router/guards';
import { BROKER_TYPE, KUNDE_TYPE } from '@/views/intern/MSCMarketingCampaignEdit.vue';

const TABLE_TITLE = 'Kundenliste';
const ADD_NEW_CUSTOMER_GRUPPE_PATH = '/customer/kundegruppe-anlegen';
const ADD_NEW_CUSTOMER_AUSWEIS_PATH = '/customer/kunde-anlegen-personalausweis';
const ADD_NEW_CUSTOMER_PATH = '/customer/kunde-anlegen';

export default {
  mixins: [validator, InteractiveHelpCommonsMixin],
  validators: {},
  props: {
    filterType: {
      type: String,
      default: null,
    },
    isExternalSelect: {
      type: Boolean,
      default: false,
    },
    singleSelection: {
      type: Boolean,
      default: false,
    },
    customOptionMenu: {
      type: Array,
    },
  },
  data() {
    return {
      TABLE_TITLE,
      apiAddress: process.env.VUE_APP_API,
      customerSearchSort: null,
      searchValue: '',
      isLoaded: false,
      selectedRows: [],
      invertSelection: false,
      searchParams: {},
      sendParams: {},
      immediateSearch: false,
      defaultOptions: {},
      page: 0,
      // maxRowsPerPage: 25,
      configFilter: {
        placeholderForDefSearchInput: 'Kundennummer oder Name, Vorname',
        defaultSearchInputKeys: ['customerId', 'lastName', 'firstName', ],
        noResetOnDefaultSearchInputExit: true,
        defaultFiltersNum: 2,
        filterType: 'kundensuche',
        keepFiltersStateBeforeRoutingChange: this.isExternalSelect,
      },
      loading: false,
      filtered: false,
      tableId: '3615ec29-a2c2-49e4-95b9-04831b92d2c7',
      menuRowsPerPage: MENU_ROWS_PER_PAGE,
      tablePageSize: 0,
      updateMe: 0,
      validationMessage: null,
      scrollLoading: false,
    }
  },
  watch: {
    customerFilterSetup(next, oldValue) {
      if (next && next.data) {
        if (next.data.showInsurance) {
          this.$store.dispatch(CUSTOMER_SEARCH_TYPES.ACTIONS.GET_SEARCH_CUSTOMER_SETUP_VERS);
        }
        if (next.data.beratungVermittler) {
          this.updateComboBoxOptionMenu(next.data.beratungVermittler, 'allgemein', 'beratungVermittler');
        }
        
        if (next.data.kundenKategorie) {
          this.updateComboBoxOptionMenu(next.data.kundenKategorie, 'pers_daten', 'kundenKategorie');
        }

        if (next.data.anrede) {
          this.updateComboBoxOptionMenu(next.data.anrede, 'pers_daten', 'anrede');
        }

        if (next.data.anredePronomen) {
          this.updateComboBoxOptionMenu(next.data.anredePronomen, 'pers_daten', 'anredePronomen');
        }

        if (next.data.kind) {
          this.updateComboBoxOptionMenu(next.data.kind, 'pers_daten', 'kind');
        }

        if (next.data.sepa) {
          this.updateComboBoxOptionMenu(next.data.sepa, 'pers_daten', 'sepa');
        }

        if (next.data.tin) {
          this.updateComboBoxOptionMenu(next.data.tin, 'pers_daten', 'tin');
        }

        if (next.data.arbeitsverhaeltnis) {
          this.updateComboBoxOptionMenu(next.data.arbeitsverhaeltnis, 'pers_daten', 'arbeitsverhaeltnis');
        }

        if (next.data.familienstand) {
          this.updateComboBoxOptionMenu(next.data.familienstand, 'pers_daten', 'familienstand');
        }

        if (next.data.gueterstand) {
          this.updateComboBoxOptionMenu(next.data.gueterstand, 'pers_daten', 'gueterstand');
        }

        if (next.data.withEmail) {
          this.updateComboBoxOptionMenu(next.data.withEmail, 'Kundenkontakt', 'withEmail');
        }
        if (next.data.withPhone) {
          this.updateComboBoxOptionMenu(next.data.withPhone, 'Kundenkontakt', 'withPhone');
        }
        if (next.data.withMobile) {
          this.updateComboBoxOptionMenu(next.data.withMobile, 'Kundenkontakt', 'withMobilePhone');
        }        
        if(next.data.zusInfosCombo){
          this.updateComboBoxOptionMenu(next.data.zusInfosCombo, 'Zusätzliche_Kriterien', 'zusInfosCombo');
        }
        if(next.data.hobbies){
          this.updateComboBoxOptionMenu(next.data.hobbies, 'Zusätzliche_Kriterien', 'hobbies');
        }
        if(next.data.versandInfobrief){
          this.updateComboBoxOptionMenu(next.data.versandInfobrief, 'Zusätzliche_Kriterien', 'versandInfobrief');
        }
        if(next.data.vorliebe){
          this.updateComboBoxOptionMenu(next.data.vorliebe, 'Zusätzliche_Kriterien', 'vorlieben');
        }
        if(next.data.anlageart){
          this.updateComboBoxOptionMenu(next.data.anlageart, 'Zusätzliche_Kriterien_2', 'anlageart');
        }
        if(next.data.kundenBetreuer){
          this.updateComboBoxOptionMenu(next.data.kundenBetreuer, 'allgemein', 'kundenBetreuer');
        }
        if(next.data.risikoeinstufung){
          this.updateComboBoxOptionMenu(next.data.risikoeinstufung, 'Zusätzliche_Kriterien_2', 'risikoeinstufung');
        }
        if (next.data.verbotenErlaubt) {
          this.updateComboBoxOptionMenu(next.data.verbotenErlaubt, 'Kundenkontakt', 'kontaktErlaubtMail');
          this.updateComboBoxOptionMenu(next.data.verbotenErlaubt, 'Kundenkontakt', 'kontaktErlaubtTel');
          this.updateComboBoxOptionMenu(next.data.verbotenErlaubt, 'Kundenkontakt', 'kontaktErlaubtPost');
          this.updateComboBoxOptionMenu(next.data.verbotenErlaubt, 'Kundenkontakt', 'kontaktErlaubtFax');
        }
        if(next.data.informationsblatt){
          this.updateComboBoxOptionMenu(next.data.informationsblatt, 'allgemein', 'informationsblatt');
        }
        
      }
    },
    customerFilterSetupVers(next, oldValue) {
      if (next && next.data) {
        
        if (next.data.maklerAuftrag) {
          this.updateComboBoxOptionMenu(next.data.maklerAuftrag, 'Versicherungen', 'maklerAuftrag');
        }
        if (next.data.vertragStatus) {
          this.updateComboBoxOptionMenu(next.data.vertragStatus, 'Versicherungen', 'vertragStatus');
        }
        if (next.data.schadenStatus) {
          this.updateComboBoxOptionMenu(next.data.schadenStatus, 'Versicherungen', 'schadenStatus');
        }
        if (next.data.rvStatus) {
          this.updateComboBoxOptionMenu(next.data.rvStatus, 'Versicherungen', 'rvStatus');
        }
        if (next.data.kvStatus) {
          this.updateComboBoxOptionMenu(next.data.kvStatus, 'Versicherungen', 'kvStatus');
        }
        if (next.data.ohneSparte) {
          this.updateComboBoxOptionMenu(next.data.ohneSparte, 'Versicherungen', 'ohneSparte');
        }
        if (next.data.ohneGesell) {
          this.updateComboBoxOptionMenu(next.data.ohneGesell, 'Versicherungen', 'ohneGesell');
        }
        if (next.data.kategorie) {
          this.updateComboBoxOptionMenuItems(next.data.kategorie, next.data.versSparten, 'versSparten');
        }
        if (next.data.fremdGesellschaft) {
          this.updateComboBoxOptionMenu(next.data.fremdGesellschaft, 'Versicherungen', 'fremdGesellschaft');
        }
        if (next.data.vertragGesellschaft) {
          this.updateComboBoxOptionMenu(next.data.vertragGesellschaft, 'Versicherungen', 'vertragGesellschaft');
          
        }
        
      }
    },
    customerFilterSetupDepot(next, oldValue) {
      
      if (next && next.data) {
        if (next.data.gesellschaftDepot) {
          this.updateComboBoxOptionMenu(next.data.gesellschaftDepot, 'Depot', 'gesellschaftDepot');
        }
        if (next.data.wertpapier) {
          this.updateComboBoxOptionMenu(next.data.wertpapier, 'Depot', 'wertpapier');
        }
        if (next.data.lagerstelleDepot) {
          this.updateComboBoxOptionMenu(next.data.lagerstelleDepot, 'Depot', 'lagerstelleDepot');
        }
        if (next.data.freistellungAuftrag) {
          this.updateComboBoxOptionMenu(next.data.freistellungAuftrag, 'Depot', 'freistellungAuftrag');
        }
        if (next.data.mitOhneWertPapier) {
          this.updateComboBoxOptionMenu(next.data.mitOhneWertPapier, 'Depot', 'mitOhneWertPapier');
        }
        if (next.data.sparplan) {
          this.updateComboBoxOptionMenu(next.data.sparplan, 'Depot', 'sparplan');
        }
        if (next.data.entnahmeplan) {
          this.updateComboBoxOptionMenu(next.data.entnahmeplan, 'Depot', 'entnahmeplan');
        }
        if (next.data.depotNummer) {
          this.updateComboBoxOptionMenu(next.data.depotNummer, 'Depot', 'depotNummer');
        }
        if (next.data.region) {
          this.updateComboBoxOptionMenu(next.data.region, 'Depot', 'region');
        }
        if (next.data.schwerpunkt) {
          this.updateComboBoxOptionMenu(next.data.schwerpunkt, 'Depot', 'schwerpunkt');
        }
        if (next.data.vvWk) {
          this.updateComboBoxOptionMenu(next.data.vvWk, 'Depot', 'vvWK');
        }
        if (next.data.zustimmGrund?.length > 0 && next.data.zustimmStatus?.length > 0) {
          this.disablesMenuItem('Depot', 'zustimmung', false);
          const zustimmGrund = [{ value: 'Alle Zustimmungsgründe', key: undefined, }, ...next.data.zustimmGrund];
          const zustimmStatus = [{ value: 'Alle Stati', key: undefined, },  ...next.data.zustimmStatus];
          this.updateDoubleComboValues('Depot', 'zustimmung', zustimmGrund, zustimmStatus);
        } else {
          this.disablesMenuItem('Depot', 'zustimmung', true);
        }
      }

      this.$forceUpdate();
    },
    '$route.params.value': function (value) {
      this.searchValue = value;
      this.immediateSearch = true;
      if (value) {
        this.isLoaded = false;
        this.$nextTick(() => {
          this.isLoaded = true;
        });
      }
    },
    'pageTableCount': {
        handler() {
            if ( this.pageTableCount === -1){
                this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.LOAD_BROKER_PAGE_TABLE_SIZE);
            } else if (this.pageTableCount) {
                this.tablePageSize = this.pageTableCount;
            }
        },
        immediate: true
    },

  },
  computed: {
    ...mapGetters({
      customersData: CUSTOMER_SEARCH_TYPES.GETTERS.SEARCH_CUSTOMER,
      customerFilterSetup: CUSTOMER_SEARCH_TYPES.GETTERS.CUSTOMER_FILTER_SETUP,
      customerFilterSetupDepot: CUSTOMER_SEARCH_TYPES.GETTERS.CUSTOMER_FILTER_SETUP_DEPOT,
      customerFilterSetupVers: CUSTOMER_SEARCH_TYPES.GETTERS.CUSTOMER_FILTER_SETUP_VERS,
      isTest: CORE_TYPES.GETTERS.IS_TEST,
      isIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
      isWebRTCMakler: CORE_TYPES.GETTERS.IS_WEBRTC_MAKLERZUGANG,
      isSuperCustomer: CORE_TYPES.GETTERS.IS_SUPER_CUSTOMER,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
      isFK: CORE_TYPES.GETTERS.IS_FK,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      isDialog: CORE_TYPES.GETTERS.IS_DIALOG,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      bestellungenData: QUARTALSBERICHTE_BESTELLUNGEN_TYPES.GETTERS.BESTELLUNGEN_DATA,
      executeOrdersResult: QUARTALSBERICHTE_BESTELLUNGEN_TYPES.GETTERS.EXECUTE_ORDERS_RESULT,
      zusaetzlicheDaten: ZUSAETZLICHE_INFORMATIONEN_TYPES.GETTERS.ZUSAETZLICHE_DATEN,
      alleStrukturZusaetzlicheInfos: ZUSAETZLICHE_INFORMATIONEN_TYPES.GETTERS.ALLE_STRUKTUR_ZUSAETZLICHE_INFOS,
      pageTableCount: BROKERDATA_TYPES.GETTERS.GET_BROKER_PAGE_TABLE_SIZE,
    }),
    headerActions() {
      return [
        PageHeaderSimpleAction('SELECTION-DONE', 'Auswahl übernehmen')
          .withVisible(() => this.isExternalSelect)
          .withDisabled(() => !this.isSomethingSelected),
        PageHeaderSimpleAction('NEW-CUSTOMER', 'Kunde anlegen')
          .withVisible(() => isPathAllowed(ADD_NEW_CUSTOMER_PATH)),
        PageHeaderSimpleAction('NEW-CUSTOMER-GRUPPE', 'Kundengruppe anlegen')
          .withVisible(() => isPathAllowed(ADD_NEW_CUSTOMER_GRUPPE_PATH)),
        PageHeaderSimpleAction('NEW-CUSTOMER-AUSWEIS', 'Neuer Kunde über Ausweis')
          .withVisible(() => isPathAllowed(ADD_NEW_CUSTOMER_AUSWEIS_PATH)),
      ];
    },
    isSomethingSelected() {
      return !this.invertSelection && !!this.selectedRows.length || this.invertSelection && this.selectedRows.length < this.customersData.count
    },
    exportConfig() { return {
      pdf: true, 
      xls: true, 
      name: 'Kundenliste' + ' ' + dayjs(new Date()).format('DD MMM YYYY HH mm'), 
      title: 'Kundenliste',
      dispatch: this.exportAsFile,
    }},
    tableHeaderActions() {
      return [
        PageHeaderSimpleAction('KUNDEN-BEREINIGEN', 'Kunden bereinigen')
          .withDisabled(() => this.numberSelected !== 2),
        PageHeaderSimpleAction('KUNDEN-DECKBLAETER', 'Deckblatt generieren')
          .withDisabled(() => this.numberSelected !== 1),
        PageHeaderSimpleAction('VERMOEGENSUEBERSICHTEN-BESTELLEN', 'Vermögensübersichten bestellen')
          .withDisabled(() => this.customersData?.count <= 0),
        PageHeaderSimpleAction('QUARTALSBERICHTE-BESTELLEN', 'Quartalsberichte bestellen')
          .withDisabled(() => this.numberSelected <= 0),
        PageHeaderSimpleAction('ZUSAETZLICHE-INFORMATIONEN-EINTRAGEN', 'Zusätzliche Informationen eintragen')
          .withDisabled(() => this.customersData?.count <= 0),
        PageHeaderSimpleAction('KUNDEN-AKTIV-SETZEN', 'Kunde(n) aktiv setzen')
          .withDisabled(() => !this.invertSelection && !this.selectedRows.some(row => !row.data.active)),
        PageHeaderSimpleAction('KUNDEN-INAKTIV-SETZEN', 'Kunde(n) inaktiv setzen')
          .withDisabled(() => !this.invertSelection && !this.selectedRows.some(row => row.data.active)),
        PageHeaderSimpleAction('KUNDEN-LOESCHEN', 'Kunde(n) löschen')
          .withDisabled(() => this.numberSelected == 0),
        PageHeaderSimpleAction('SEND-MESSAGE-TO-SELECTED', 'Rundmail an Kunde(n)')
          .withDisabled(() => !this.isSomethingSelected),
      ];
    },
    headers() {
        let headers = {
            lockedLeft: [
                SlotColumn("name", "Name", 200).makeSortable(),
            ],
            center: [
                PillColumn("active", "Status"),
                PillColumn("customerStatus", "Anlegerprofil"),
                SlotColumn("customerID", "Nummer").makeSortable(),
                TextColumn("telefon", "Telefon").makeLink(this.$store.getters[TAPI.GETTERS.CAN_OUT_CALL]),
                TextColumn("telefonPrivat", "Telefon privat").makeLink(this.$store.getters[TAPI.GETTERS.CAN_OUT_CALL]   ),
                TextColumn("fax", "Fax"),
                TextColumn("mobil", "Mobil").makeLink(this.$store.getters[TAPI.GETTERS.CAN_OUT_CALL]),
                TextColumn("title", "Anrede"),
                TextColumn("adresse", "Adresse", 250),
                CurrencyColumn("openFunds", "Depotwert").makeLink(),
                DateColumn("dayOfBirth", "Geburtsdatum").makeSortable(v => {
                    if (!v) {
                        return 0;
                    }
                    // sort only by month and day of month, ignoring the year. See MSC-18932
                    const date = new Date(dateToSortable(v));
                    return date.getMonth() * 100 + date.getDate();
                }),
                PillColumn("customerType", "Vertrag"),
                TextColumn("superKunde", "Typ"),
                SlotColumn("fcAccess", "Nutzung Web/App", 100, 0),
                SlotColumn("possibleContacts", "Kontakt", 100, 0),
                TextColumn("category", "Kategorie"),
                TextColumn("betreuer", "Betreuer"),
                TextColumn("maklernr", "Maklernr").makeConditionalLink("canOpenMaklerNewTab"),
                TextColumn("maklerName", "Vermittlername"),
                NumberColumn("insurances", "Versicherungen").makeLink(),
                PillColumn("vertragStatus", this.isFA ? "Maklervollmacht/-vertrag" : "Maklervertrag").makeSortable(pill => pill ? pill.label : ""),
                CurrencyColumn("closedFunds", "Beteiligungen").makeLink(),
                CurrencyColumn("bankAccounts", "Bankkonten").makeLink(),
                TextColumn("street", "Strasse"),
                TextColumn("zip", "PLZ"),
                TextColumn("city", "Ort"),
                TextColumn("email", "E-Mail"),
                TextColumn("briefAnrede", "Briefanrede Depot"),
                TextColumn("briefAnredePerson", "Briefanrede Person"),
                CurrencyColumn("kredite", "Kredite").makeLink(),
                TextColumn("tin", "TIN"),
                TextColumn("titel", "Titel"),
                TextColumn("kundenReferenzNummer", "Kundenreferenznr."),
                CurrencyColumn("auftragVerfuegbarAktJ", "verfügbarer Freibetrag im laufenden Jahr"),
                DateColumn("dateOfDeath", "Sterbedatum"),
                DateColumn("dateCreated", "Anlagedatum"),
                NumberColumn("immobilie", "Immobilie").makeLink(),
                CurrencyColumn("weitereVerbindlichkeiten", "Weitere Verbindlichkeiten").makeLink(),
                CurrencyColumn("weitereVermoegen", "Weitere Vermögen").makeLink(),
                
            ],
        };
        if (this.isFA) {
          headers.center.push(PillColumn("informationsblatt", "Informationsblatt").makeSortable(pill => pill ? pill.label : ""))
          headers.center.push(PillColumn("rahmenvereinbarung", "Rahmenvereinbarung").makeSortable(pill => pill ? pill.label : ""))
          headers.center.push(PillColumn("legitimationStatus", "Ausweis").makeSortable(pill => pill ? pill.label : ""))
        }
        if (this.isIntern) {
          headers.center.push(TextColumn("courtageZubringer", "Zubringer"))
        }
        // ensure that headers are only displayed as sortable if the server can sort them 
        Object.values(headers).flat().forEach(header => {
            if (!this.customerFilterSetup.data.customerSearchSort?.includes(header.key)) {
              header.makeSortable(false);
            }
        });
        return headers;
    },
    allPages() {
        return Object.entries(this.customersData?.customers || {})
        .flatMap(([_key, page]) => {
            return page.map(item => ({
                customerID: item.user?.userId,
                id: item.user?.userId,
                email: item.email,
                name: item.fullName || item.email,
                fax: item.fax,
                type: item.user?.type || 'PERSON',
            }));
        });
    },
    pages() {
        if (!this.customersData?.customers || this.page === -1)
            return {};
        return Object.fromEntries(Object.entries(this.customersData.customers).map(([key, page]) => {
            return [key, page?.map(item => {
                const address = item.person && item.person.personalDataAddress
                const birth = item.person?.personalDataBirth

                const tel = item.person && item.person.contacts && item.person.contacts.find(c => !!c.typeId.match(new RegExp(/^(Telefon tagsüber|Telefon){1}$/)))
                const telPrivat = item.person && item.person.contacts && item.person.contacts.find(c => !!c.typeId.match(new RegExp(/Privat$/)))
                const fax = item.person && item.person.contacts && item.person.contacts.find(c => c.typeId.includes('Fax'))
                const mobile = item.person && item.person.contacts && item.person.contacts.find(c => c.typeId.includes('Mobil'))
                const email = item.person && item.person.contacts && item.person.contacts.find(c => c.typeId.includes('E-Mail'))

                const customerName = address && [address.lastName, address.firstName].filter(Boolean).join(', ')
                
                let customerType = null;
                if (item.beratungVermittler) {
                    customerType = {
                        label: '',
                        type: item.beratungVermittler?.value == 'EXECUTION_ONLY' ? 'danger' : 'info',
                    };
                    switch (item.beratungVermittler.value) {
                        case 'VERMITTLUNG':
                            customerType.label = 'Vermittlung';
                            break;
                        case 'BERATUNG':
                            customerType.label = 'Beratung';
                            break;
                        case 'EXECUTION_ONLY':
                            customerType.label = 'Execution Only';
                            break;
                        default:
                            customerType = null;
                    }
                }

                const result = {
                    id: item.customerID,
                    name: customerName,
                    active: {
                        label: item.active ? 'aktiv' : 'inaktiv',
                        type: item.active ? 'info' : 'danger',
                    },
                    customerStatus: {
                        label: item.haveAP ? 'vorhanden' : (item.beratungVermittler?.value == 'EXECUTION_ONLY' ? 'nicht notwendig' : 'nicht vorhanden'),
                        type: item.haveAP ? 'info' : 'danger',
                    },
                    informationsblatt: {
                      label: item.informationsblatt ? 'vorhanden' : 'nicht vorhanden',
                      type: item.informationsblatt ? 'success' : 'danger',
                    },
                    rahmenvereinbarung: {
                      label: item.rahmenvereinbarung ? 'vorhanden' : 'nicht vorhanden',
                      type: item.rahmenvereinbarung ? 'success' : 'danger',
                    },
                    legitimationStatus: {
                      label: item.legitimationStatus === 'VORHANDEN' ? 'vorhanden' : item.legitimationStatus === 'ABGELAUFEN' ? 'abgelaufen' : 'nicht vorhanden',
                      type: item.legitimationStatus === 'VORHANDEN' ? 'success' : 'danger',
                    },
                    customerID: item.customerID,
                    telefon: tel && tel.value || NOT_AVAILABLE_STRING,
                    telefonPrivat: telPrivat?.value || NOT_AVAILABLE_STRING,
                    fax: fax?.value || NOT_AVAILABLE_STRING,
                    mobil: mobile && mobile.value || NOT_AVAILABLE_STRING,
                    email: email && email.value || NOT_AVAILABLE_STRING,
                    title: address && address.title,
                    titel: address && address.titles,
                    adresse: address && [address.street, address.zip, address.city].filter(Boolean).join(', '),
                    openFunds: item.openFunds,
                    dayOfBirth: birth && birth.dayOfBirth,
                    customerType,
                    superKunde: item.superKundeString,
                    category: item.category,
                    maklernr: item.maklernr,
                    maklerName: item.maklerName,
                    betreuer: item.betreuer,
                    insurances: item.insurances,
                    vertragStatus: {
                        label: this.vertragStatusLabel(item.versicherungMaklervertragStatus),
                        type: item.versicherungMaklervertragStatus,
                    },                    
                    closedFunds: item.closedFunds,
                    bankAccounts: item.bankAccounts,
                    street: address && address.street,
                    zip: address && address.zip,
                    city: address && address.city,
                    briefAnrede: item.briefAnrede,
                    briefAnredePerson: item.briefAnredePerson,
                    kredite: item.credits,
                    tin: item.tin,
                    personDeceased: address?.personDeceased,
                    dateOfDeath: address && address.dateOfDeath,
                    kundenReferenzNummer: item.kundenReferenzNummer,
                    externMaklerStatus: item.externMaklerStatus,
                    courtageZubringer: item.courtageZubringer,
                    auftragVerfuegbarAktJ: item.auftragVerfuegbarAktJ,
                    data: item,
                    dateCreated: item?.dateCreated,
                    canOpenMaklerNewTab: !!item.maklernr && this.hasRoles([VIEW_ROLES.VIEW_INTERN]),
                    weitereVermoegen: item.weitereVermoegen,
                    weitereVerbindlichkeiten: item.weitereVerbindlichkeiten,
                    immobilie: item.immobilie,

                }

                const subRows = item.additionalPersons.map((addItem, index) => {
                    const address = addItem.personalDataAddress
                    const birth = addItem.personalDataBirth

                    const tel = addItem.contacts && addItem.contacts.find(c => !!c.typeId.match(new RegExp(/^(Telefon tagsüber|Telefon){1}$/)))
                    const telPrivat = addItem.contacts && addItem.contacts.find(c => !!c.typeId.match(new RegExp(/Privat$/)))
                    const fax = addItem.contacts && addItem.contacts.find(c => c.typeId.includes('Fax'))
                    const mobile = addItem.contacts && addItem.contacts.find(c => c.typeId.includes('Mobil'))
                    const email = addItem.contacts && addItem.contacts.find(c => c.typeId.includes('E-Mail'))

                    return {
                        id: item.customerID + " - " + index,
                        name: address && [address.firstName, address.lastName].filter(Boolean).join(', '),
                        telefon: tel && tel.value || NOT_AVAILABLE_STRING,
                        telefonPrivat: telPrivat?.value || NOT_AVAILABLE_STRING,
                        fax: fax?.value || NOT_AVAILABLE_STRING,
                        mobil: mobile && mobile.value || NOT_AVAILABLE_STRING,
                        email: email && email.value || NOT_AVAILABLE_STRING,
                        title: address && address.title,
                        adresse: address && [address.street, address.zip, address.city].filter(Boolean).join(', '),
                        dayOfBirth: birth && birth.dayOfBirth,
                        street: address && address.street,
                        zip: address && address.zip,
                        city: address && address.city,
                        personDeceased: address?.personDeceased,
                        dateOfDeath: address && address.dateOfDeath,

                        customerRelationship: {
                            customerID: item.customerID,
                            name: customerName,
                        },
                        data: {},
                    };
                });

                if (subRows.length) {
                    result.__sub_rows = subRows;
                }

                return result;
            })];
        }));
    },
    maxRowsPerPage() {
      if (this.tablePageSize > 0) {
        return this.tablePageSize;
      }
      return 25;
    },
    pageCount() {
        return Math.ceil(this.customersData.count / this.maxRowsPerPage);
    },
    currentPage() {
        return this.pages && this.pages[this.page] || [];
    },

    hasNoTableData() {
      return this.customersData.customers && !this.customersData.customers?.[0]?.length;
    },

    defaultOptionsData() {
      return {
        ...this.defaultOptions,
        ...(this.$route.params?.defaultFilter 
          ? this.$route.params?.defaultFilter 
          : {
            inklOhneVertrag: {
              value: true,
            },
            structure: {
              value: true,
            },
          }),
        ...(this.searchValue ? {
          lastName: {
            value: this.searchValue,
          },
        } : {})
      }
    },
    predefinedFilter() {
       return null;
    },

    internUserOrBroker() {
      return this.hasRoles(
        [VIEW_ROLES.VIEW_INTERN, VIEW_ROLES.VIEW_BROKER, VIEW_ROLES.VIEW_BROKER_AS_INTERN],
      );
    },
    searchMenuItems() {
      let showIsinPanels = this.isTest || (this.isFK && this.isBroker && (this.loginData?.loginUserid === '70142' || this.loginData?.loginUserid === '67149'))
      return searchMetadata(this.isFA, showIsinPanels, this.isIntern || this.isDialog);
    },
    searchMenu() {
      let searchMenu = this.searchMenuItems
      if (this.customerFilterSetup && this.customerFilterSetup.data?.showZubringercourtage === false) {
          searchMenu = this.searchMenuItems.map(menuGroup => {
          return {
            ...menuGroup,
            menuItems: menuGroup.menuItems
              .filter(menuItem => menuItem.key !== 'paramZubringercourtage')
          }
        })
      }

      this.updateMe

      if (this.internUserOrBroker) {
          const foundMenuGroup = searchMenu.find(menuGroup => menuGroup.key === 'Versicherungen');

          if (foundMenuGroup) {
            const foundMenuItem = foundMenuGroup.menuItems.find(menuGroup => menuGroup.key === 'interneNummer');

            if (!foundMenuItem) {
              foundMenuGroup.menuItems.push(
                {
                  type: 'integer',
                  label: 'Interne Nummer',
                  key: 'interneNummer',
                  emptyDenied: true,
                }              
              );
            }
          }
      }

      return searchMenu
    },
    selectedLoadedRows() {
      if (this.invertSelection) {
        const rows = Object.values(this.pages).flat(); // only loaded and viewed rows
        if (this.selectedRows?.length) {
          this.selectedRows.forEach(row => {
            const idx = rows.findIndex( r => r.customerID === row.customerID );
            if (idx !== -1) {
              rows.splice(idx, 1);
            }
          })
        }
        return rows;
      }
      return this.selectedRows;
    },
    numberSelected() {
      return this.selectedLoadedRows?.length;
    },
    shouldDisableSelectionButton() {
      if (this.singleSelection && this.selectedRows.length > 1) {
        return true;
      }
      return false;
    },
  },

  mounted: function() {
    this.$store.commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.RESET_STATE_SEARCH,null);
    if ( this.tablePageSize === -1){
        this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.LOAD_BROKER_PAGE_TABLE_SIZE);
    }
    if(this.$route.params?.defaultFilter){
      this.configFilter.ignoreStoredFilter = true
      this.immediateSearch = true
    }
    this.searchValue = this.$route.params.value;
    const validators = {};
    this.searchMenu.map(menuGroup => {
      menuGroup.menuItems.map(menuItem => {
        if (menuItem.emptyDenied) {
          validators[menuItem.key] = Array.isArray(validators[menuItem.key]) ? [...validators[menuItem.key], required()] : [required()]
        }
        if (menuItem.type === 'email') {
          validators[menuItem.key] = Array.isArray(validators[menuItem.key]) ? [...validators[menuItem.key], email()] : [email()]
        }
        if (menuItem.type === 'number') {
          validators[menuItem.key] = Array.isArray(validators[menuItem.key]) ? [...validators[menuItem.key], numbers()] : [numbers()]
        }
      });
    });
    this.validators = validators;

    this.$store.dispatch(CUSTOMER_SEARCH_TYPES.ACTIONS.GET_SEARCH_CUSTOMER_SETUP);
    this.$store.dispatch(CUSTOMER_SEARCH_TYPES.ACTIONS.GET_SEARCH_CUSTOMER_SETUP_DEPOT);
    this.isLoaded = true;
  },
  methods: {
    addNewCustomer(type) {
      switch(type) {
        case 'GRUPPE':
          this.$router.push(ADD_NEW_CUSTOMER_GRUPPE_PATH);
          break;
        case 'AUSWEIS':
          this.$router.push(ADD_NEW_CUSTOMER_AUSWEIS_PATH);
          break;
        default:
          this.$router.push(ADD_NEW_CUSTOMER_PATH);
      }
    },
    onCustomerSearchResultSort(customerSearchSort) {
      this.customerSearchSort = customerSearchSort
      this.loadPage(0, true);
    },
    validateRowSelection(rowSelectionData) {
      if (this.singleSelection && this.selectedRows.length > 1) {
        this.validationMessage = "Es darf nur eine Zeile ausgewählt werden";
        this.$refs.validationModal.open();
      }
    },
    onCloseValidationModal() {
      this.validationMessage = null;
    },
    openCustomerNewTab(customer) {
      this.$store.commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.OPEN_CUSTOMER, customer);
      this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { customerId: customer.data.customerID })
    },
    openInsurancesNewTab(customer) {
      this.$store.commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.OPEN_CUSTOMER, customer);
      this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { customerId: customer.data.customerID, insurances: true })
    },
    callTapi(customer,phoneNumber){
       
        //this.$store.dispatch(TAPI.ACTIONS.OPEN_CALL_OUT_DIALOG,{'Kunde'});
        this.$store.dispatch(TAPI.ACTIONS.OPEN_CALL_OUT_DIALOG, {userType:'Kunde' ,userId:customer.data.customerID,phoneNumber:phoneNumber})     
        //this.$refs.OutcomingCallTapiDialog?.open(phoneNumber);
    },
    
    openPathNewTab(customer, path) {
      this.$store.commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.OPEN_CUSTOMER, customer);
      this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { 
        customerId: customer.data.customerID, 
        path,
      })
    },
    openMaklerNewTab(customer) {
      if(!customer) return;

      this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_BROKER_NEW_TAB, {
        brokerId: customer.maklernr,
      });
    },
    updateComboBoxOptionMenu(setupList, menuGroupKey, menuItemKey) {
      if (setupList) {
        const foundMenuGroup = this.getMenuGroupByKey(menuGroupKey);

        if (foundMenuGroup) {
          const foundMenuItemIndex = foundMenuGroup.menuItems.findIndex(menuItem => menuItem.key === menuItemKey);


          if (foundMenuItemIndex >= 0) {
            const foundMenuItem = foundMenuGroup.menuItems[foundMenuItemIndex];
            // foundMenuItem.comboOptions = this.mapCustomerListSetupToComboValues(setupList);

            this.$set(foundMenuItem, "comboOptions", this.mapCustomerListSetupToComboValues(setupList));
            this.$set(foundMenuGroup.menuItems, foundMenuItemIndex, foundMenuItem);

          }
        }
      }
    },
    updateDoubleComboValues(menuGroupKey, menuItemKey, combo1Values, combo2Values) {
      if (combo1Values || combo2Values) {
        const foundMenuGroup = this.getMenuGroupByKey(menuGroupKey);

        if (foundMenuGroup) {
          const foundMenuItem = foundMenuGroup.menuItems.find(menuItem => menuItem.key === menuItemKey);

          if (foundMenuItem) {
            foundMenuItem.comboOptions1 = this.mapCustomerListSetupToComboValues(combo1Values);
            foundMenuItem.comboOptions2 = this.mapCustomerListSetupToComboValues(combo2Values);
          }
        }
      }
    },
    disablesMenuItem(menuGroupKey, menuItemKey, value) {
      const foundMenuGroup = this.getMenuGroupByKey(menuGroupKey);
      if (!foundMenuGroup) return;

      const foundMenuItem = foundMenuGroup.menuItems.find(menuItem => menuItem.key === menuItemKey);
      if (!foundMenuItem) return;

      this.$set(foundMenuItem, 'disabled', value);
    },
    updateComboBoxOptionMenuItems(setupList, setupOptions, menuGroupKey) {
      if (setupList) {
        const foundMenuGroup = this.getMenuGroupByKey(menuGroupKey);

        if (foundMenuGroup) {
          foundMenuGroup.menuItems = [];
          setupList.map(item => {
            const newMenuItem = {
              type: 'combobox',
              label: item.label,
              key: 'versSparten_' + item.id,
              allowMultipleSearch: true,
              rootArray: true,
              comboOptions: setupOptions[item.id] ? this.mapCustomerListSetupToComboValuesIdLabel(setupOptions[item.id]) : [],
            };
            foundMenuGroup.menuItems.push(newMenuItem);
          });

          this.updateMe++
        }
      }
    },
    updateComboBoxOptionMenuItemsSwitch(setupList, menuGroupKey, key) {
      if (setupList) {
        const foundMenuGroup = this.getMenuGroupByKey(menuGroupKey);

        if (foundMenuGroup) {
          foundMenuGroup.menuItems = this.mapSetupListToMenuItems(setupList);
        }
      }
    },

    mapSetupListToMenuItems(setupList) {
      return setupList && setupList.map(bv => ({type: "default", key: bv.key, label: bv.value})) || [];
    },

    mapCustomerListSetupToComboValuesIdLabel(listSetupCombo) {

      if(listSetupCombo) {
        let resultList = listSetupCombo.map(bv => ({value: bv.id, label: bv.label}));
        resultList.unshift({value: -1, label: 'Alle'});
        return resultList;
      }

      return [];

    },

    mapCustomerListSetupToComboValues(listSetupCombo) {
      return listSetupCombo && listSetupCombo.map(bv => ({value: bv.key, label: bv.value})) || [];
    },

    getMenuGroupByKey(menuGroupKey) {
      return this.searchMenuItems.find(menuGroup => menuGroup.key === menuGroupKey);
    },
    onComboboxChange(row, index, value) {
      if (index == 'gesellschaftDepot') {
        this.$store.dispatch(CUSTOMER_SEARCH_TYPES.ACTIONS.GET_SEARCH_CUSTOMER_SETUP_DEPOT, {gesellschaftDepot: value});
      }
      this.$emit("comboboxChange", row, index, value); 
    },

    async handleSearch(params) {
      this.loading = true;
      this.filtered = true;

      try {
        if (params.brokerId && !Array.isArray(params.brokerId)) {
          params.brokerId = [params.brokerId]
        }

        this.searchParams = params;
        await this.loadPage(0, true);
      } finally {
        this.loading = false;
      }
    },
    async loadPage(pageId, force = false, filterType = null) {
      this.page = pageId;
      if (!force && this.customersData?.customers && this.customersData.customers[pageId])
        return;

      const params = {
        ...this.searchParams,
        checkpin: true,
        section: pageId,
        maxCount: this.maxRowsPerPage,
        otherCustomerInDesktopAllow: this.isSuperCustomer,
        withSequence: true,
        seq: this.customersData.sequenceId,
      };
      if (this.filterType || filterType) {
        params.filterType = filterType || this.filterType;
      }
      if (this.customerSearchSort && this.customerSearchSort.key) {
        params.sort = this.customerSearchSort.key
        params.sortDirection = this.customerSearchSort.sortDirection || 'asc'
      } else if(params?.lastName && !this.customerSearchSort?.key) {
        params.sort = 'name'
        params.sortDirection = 'asc'
      } else {
        delete params.sort
        delete params.sortDirection
      }
      this.loading = true;
      this.sendParams = params;
      await this.$store.dispatch(CUSTOMER_SEARCH_TYPES.ACTIONS.SEARCH_CUSTOMER, params);
      this.loading = false;
    },

    async handleBackAction(setValues = true) {
        if (setValues && this.invertSelection) {
            await this.loadPage(-1, false, 'EmailParticipant');
            // invert the selection
            const selectedRows = Object.values(this.allPages)
                .flat()
                .filter(row => row.customerID && !this.selectedRows.some(selected => selected.customerID == row.customerID));
            this.$emit('handleBackAction', selectedRows);
        } else {
            this.$emit('handleBackAction', setValues ? this.selectedRows : []);
        }
    },
    async handleMailAll() {
      let participants = [];
      if (this.invertSelection) {
        await this.loadPage(-1, false, 'EmailParticipant');
        // invert the selection
        const selectedRows = Object.values(this.allPages)
            .flat()
            .filter(row => row.customerID && !this.selectedRows.some(selected => selected.customerID == row.customerID));
        participants = selectedRows;
      } else {
        participants = this.selectedRows;
      }
      const rows = participants.map(item => ({
          id: item.id,
          customerID: item.id,
          value: item.email,
          name: item.name,
          type: item.type ||
                  (item.customerID) ||
                  (item.user && v.user.type && item.user.type === KUNDE_TYPE)
                    ? KUNDE_TYPE
                    : 'PERSON',
          email: item.email
        }))
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_PARTICIPANTS, null);
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_PARTICIPANTS, rows);
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG, {
        type: 'mailcomposer-bulk',
        action: 'clear',
        receiver: [],
      });
      this.$router.push({path: '/communication/mailcomposer-bulk'});
    },
    handleRowFiles(event) {
      const customer = event?.row?.customerRelationship || event?.row;
      if(!event?.files?.length || !customer?.customerID) {
        return ;
      }

      const customerData = {
        customerID: customer.customerID,
        name: customer.name,
      };
      this.$refs.customerDocumentUploadModal?.open(customerData, event.files);
    },
    vertragStatusLabel(status) {
      if (this.isFA) {
        switch(status){
          case 'success':
            return 'vorhanden';
          case 'danger':
          case 'warning':
            return 'nicht hinterlegt';
          default: 
            return null;
        }              
      } else {
        switch(status){
          case 'success':
            return 'hinterlegt, gescannt';
          case 'warning':
            return 'hinterlegt, nicht gescannt';
          case 'danger':
            return 'nicht hinterlegt';
          default: 
            return null;
        }            
      }
    },
    exportAsFile(fileType) {
      const params = {
        ...this.sendParams,
        section: (fileType == 'pdf' ? 0 : 1),
      };
      if (this.customerSearchSort && this.customerSearchSort.key) {
        params.sort = this.customerSearchSort.key
        params.sortDirection = this.customerSearchSort.sortDirection || 'asc'
      } else {
        delete params.sort
        delete params.sortDirection
      }
      return this.$store.dispatch(CUSTOMER_SEARCH_TYPES.ACTIONS.SEARCH_CUSTOMER_EXPORT, params);
    },
    onPageSizeChange(pageSize) {
      this.tablePageSize = pageSize;
      this.handleSearch(this.searchParams);
    },
    personDeceased(row) {
      return row.personDeceased ;
    },
    openKundenBereinigenModal() {
      this.$refs.kundenBereinigenModal.open();
    },
    purgeCustomers(customers) {
      let target = customers?.toKeep?.customerId;
      let merged = customers?.toRemove?.customerId;

      const params = {
        target,
        merged
      };

      this.$store.dispatch(CUSTOMER_SEARCH_TYPES.ACTIONS.KUNDEN_BEREINIGEN, params).then(() => {
          this.handleSearch(this.searchParams);

      });

    },
    quartalsBerichteBestellen() {

      let customers = [];

      if (!this.customersData.sequenceId) {
        this.customersData?.customers[0]?.forEach(c => { 
          customers.push(c.customerID);
        });
      }

      const payload = {
        bestellungModus: 'QB',
        customers,
        seq: this.customersData.sequenceId,
      }

      this.$store.dispatch(QUARTALSBERICHTE_BESTELLUNGEN_TYPES.ACTIONS.RETRIEVE_BESTELLUNGEN_DATA, payload).then((response) => {
        if (response?.rows) {
          this.$refs.bestellungenModal.open();
        }
          

      });

      
    },
    vermoegensuebersichtenBestellen() {

      let customers = [];

      if (!this.customersData.sequenceId) {
        this.customersData?.customers[0]?.forEach(c => { 
          customers.push(c.customerID);
        });
      }

      const payload = {
        bestellungModus: 'DA',
        customers,
        seq: this.customersData.sequenceId,
      }

      this.$store.dispatch(QUARTALSBERICHTE_BESTELLUNGEN_TYPES.ACTIONS.RETRIEVE_BESTELLUNGEN_DATA, payload).then(() => {
          this.$refs.bestellungenModal.open();

      });

      
    },    
    executeOrders(params) {
      const payload = {
        bestellungModus: params?.bestellungModus,
        customers: params?.customers,
        inputStand: params?.inputStand,
      }

      this.$store.dispatch(QUARTALSBERICHTE_BESTELLUNGEN_TYPES.ACTIONS.EXECUTE_ORDERS, payload).then((response) => {
          this.$refs.executeOrdersResultModal.open();

      });
      
      
    },
    zusaetzlicheInformationenEintragen() {

      let customers = [];

      if (!this.customersData.sequenceId) {
        this.customersData?.customers[0]?.forEach(c => { 
          customers.push(c.customerID);
        });
      }

      const payload = {
        tableType: 'MAIN_TABLE',
        customers,
        seq: this.customersData.sequenceId,
      }

      this.$store.dispatch(ZUSAETZLICHE_INFORMATIONEN_TYPES.ACTIONS.GET_ZUSAETZLICHE_DATEN, payload).then(() => {
          this.$refs.zusaetzlichenModal.open();

      });
    },
    setKundenInaktiv(inaktiv = false) {
      const customerIds = this.selectedRows.filter(row => this.invertSelection || row.data.active == inaktiv).map(row => row.customerID)

      if (customerIds.length) {
        const payload = {
          customerIds,
          inaktiv,
          seqId: this.customersData.sequenceId,
          invertSelection: this.invertSelection,
        }
        this.$store.dispatch(CUSTOMER_SEARCH_TYPES.ACTIONS.SET_KUNDEN_INAKTIV, payload).then(() => {
          this.selectedRows = []
          this.handleSearch(this.searchParams)
        })
      }
    },
    callKundenDeckblaeter() {
      if ( this.selectedLoadedRows?.length == 1 ) {
        this.openPathNewTab(this.selectedLoadedRows[0], 'beratung/formulare/antrag/' + (this.isFK ? 'FK' : 'FA'));
      }
    },
    KundenLoeschenConfirmation() {
      let customers = [];

      this.selectedLoadedRows.forEach(c => { 
        customers.push(c.customerID);
      });

      this.$store.dispatch(CUSTOMER_SEARCH_TYPES.ACTIONS.KUNDEN_LOESCHEN_RESTRICTIONS, customers).then(() => {
        // this.$refs.kundenLoeschenModal.open();
        this.$router.push({path: '/customer/kunden-loeschen'});

      });      
    },
    async onScroll(onScrollEnd) {
      try {
        await this.loadPage(this.page + 1)
      } finally {
        onScrollEnd()
      }
    },
  },
  filters: {
    currency(value) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value/1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €';
    },
    number(value) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value/1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
  components: {
    CustomerApproach,
    CustomerFCUsage,
    OptionMenu,
    GenericPersonFilter,
    BaseButton,
    CustomerDocumentUploadModal,
    PaginatedTable,
    GhostLoading,
    NoData,
    HandleTablePin,
    KundenBereinigenModal,
    BestellungenModal,
    BaseModal,
    ZusaetzlichenModal,
    PageHeaderTitleNavigation,
  },

  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zur Kundenverwaltung', 
      to,
      from,
    });

    next()
  },
};
</script>

<style scoped>

.stored-search__container {
  margin: -8px;
}

.stored-search-badge {
  padding: 4px 8px;
  color: var(--color-text);
  background-color: var(--color-box);
  border: solid 1px var(--color-text);
  border-radius: 24px;
  margin: 8px;
  font-size: 0.85rem;
}
.stored-search-badge__selected {
  background-color: var(--color-primary);
  color: var(--color-primary-text);
}

.parameter-search__input-fields {
  margin: 0 -10px;
}

.parameter-search__input-fields > * {
  margin: 0 10px;
}
.parameter-controls__container {
  margin: 0 -10px;
  color: var(--color-primary);
}

.parameter-controls__container > * {
  margin: 0 10px;
}

.text-right {
  text-align: right;
  justify-content: flex-end;
}
.tool-export {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0;
}
.deceased::after {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 20' fill='%23FFF' stroke='%23888' stroke-width='1' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M7 2H9V6H13V8H9V17H7V8H3V6H7V2z'%3E%3C/path%3E%3C/svg%3E");
  display: inline-block;
  width: 17px;
  height: 20px;
  vertical-align: bottom;
}
</style>
