var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("table", { staticClass: "fc-table fc-table-divider fc-table-small" }, [
      _c("thead", [
        _c("tr", [
          _c("th", [_vm._v("ISIN")]),
          _c("th", [_vm._v("Fondsname")]),
          _vm.config && _vm.config.sparplan
            ? _c("th", [_vm._v("Sparplan Betrag")])
            : _vm._e(),
          _vm.config && _vm.config.entnahme
            ? _c("th", [_vm._v("Auszahlung von")])
            : _vm._e(),
          _vm.config && _vm.config.kauf
            ? _c("th", [_vm._v("Anlage")])
            : _vm._e(),
          _vm.config && _vm.config.kauf ? _c("th") : _vm._e(),
          _c("th")
        ])
      ]),
      _c(
        "tbody",
        _vm._l(_vm.positions, function(position, index) {
          return _c("tr", { key: index }, [
            _c("td", [_vm._v(_vm._s(position.isin))]),
            _c("td", [_vm._v(_vm._s(_vm.getFondsName(position)))]),
            _vm.config.kauf
              ? _c(
                  "td",
                  [
                    _c("ComboBox", {
                      attrs: {
                        isEmbedded: true,
                        values: _vm.comboOptions,
                        value: true,
                        disabled: _vm.disabled
                      },
                      on: {
                        change: function($event) {
                          return _vm.onChangeCombo($event, position, "betrag")
                        }
                      },
                      model: {
                        value: position.betrag,
                        callback: function($$v) {
                          _vm.$set(position, "betrag", $$v)
                        },
                        expression: "position.betrag"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "td",
              [
                position.betrag === true ||
                _vm.config.sparplan ||
                _vm.config.entnahme
                  ? _c("InputField", {
                      attrs: {
                        type: "currency",
                        isEmbedded: true,
                        disabled: _vm.disabled
                      },
                      on: {
                        change: function($event) {
                          return _vm.onChangeInputField(
                            $event,
                            position,
                            "wert"
                          )
                        }
                      },
                      model: {
                        value: position.wert,
                        callback: function($$v) {
                          _vm.$set(position, "wert", $$v)
                        },
                        expression: "position.wert"
                      }
                    })
                  : _vm._e(),
                !(position.betrag === true) && _vm.config.kauf
                  ? _c("InputField", {
                      attrs: {
                        type: "number",
                        isEmbedded: true,
                        disabled: _vm.disabled
                      },
                      on: {
                        change: function($event) {
                          return _vm.onChangeInputField(
                            $event,
                            position,
                            "wert"
                          )
                        }
                      },
                      model: {
                        value: position.wert,
                        callback: function($$v) {
                          _vm.$set(position, "wert", $$v)
                        },
                        expression: "position.wert"
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c(
              "td",
              [
                _c("WertpapierAuswahlPositionsButtons", {
                  attrs: {
                    position: position,
                    positionName: _vm.getFondsName(position),
                    antragId: _vm.antragId,
                    categoryId: _vm.categoryId,
                    disabled: _vm.disabled
                  }
                })
              ],
              1
            )
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }