import V_P from './types';

export default {

  

  [V_P.GETTERS.FILTER](state) {

    return state.FILTER;
  },
  
  



}