import HONORARE_TYPES from './types';
import { getInitialState } from './index'

export default {
  [HONORARE_TYPES.MUTATIONS.CREATE_FEES_SUCCESS](state, payload) {
    state.createFeesResult = payload;
  }, 
  [HONORARE_TYPES.MUTATIONS.RESET_STATE](state, payload) {
    Object.assign(state, getInitialState())
  }, 
  [HONORARE_TYPES.MUTATIONS.GET_FEES_SUCCESS](state, payload) {
    state.fees = payload;
  }, 
  [HONORARE_TYPES.MUTATIONS.CONFIRM_FEES_SUCCESS](state, payload) {
    state.confirmFeesResult = payload;
  }, 
  [HONORARE_TYPES.MUTATIONS.MARK_CONFIRMED_SUCCESS](state, payload) {
    state.markConfirmedResult = payload;
  },
  [HONORARE_TYPES.MUTATIONS.SELECTED_ROW](state, payload) {
    state.selectedRow = payload;
  },
  [HONORARE_TYPES.MUTATIONS.ALLE_LOESCHEN_SUCCESS](state, payload) {
  },
  [HONORARE_TYPES.MUTATIONS.LEERE_LOESCHEN_SUCCESS](state, payload) {
  },
  [HONORARE_TYPES.MUTATIONS.BESTAETIGUNG_VORBEREITEN_SUCCESS](state, payload) {
  },
  [HONORARE_TYPES.MUTATIONS.GET_EDITOR_LAYOUT_DATA_SUCCESS](state, payload) {
    state.editorLayoutData = payload;
  }, 
  [HONORARE_TYPES.MUTATIONS.EDIT_ROW](state, payload) {
    state.editRow = payload;
  }, 
  [HONORARE_TYPES.MUTATIONS.SAVE_EDIT_POSITION_SUCCESS](state, payload) {
  }, 
  [HONORARE_TYPES.MUTATIONS.DELETE_POSITION_SUCCESS](state, payload) {
  },  


  
}