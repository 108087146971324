export const MUTATION_PREFIX = 'BROKERDATA: ';
export const ACTIONS_PREFIX = 'BROKERDATA_';
export const GETTERS_PREFIX = 'BROKERDATA_';


export default {
  MUTATIONS: {
    ADD_BROKER_DATA_EDITED: MUTATION_PREFIX + 'ADD_BROKER_DATA_EDITED',
    RESET_BROKER_DATA_EDITED: MUTATION_PREFIX + 'RESET_BROKER_DATA_EDITED',
    RESET_MAKLER_ANSPRECHPARTNER_DATA_EDITED: MUTATION_PREFIX +'RESET_MAKLER_ANSPRECHPARTNER_DATA_EDITED',
    GET_BROKER_DATA_SUCCESS: MUTATION_PREFIX + 'GET_BROKER_DATA_SUCCESS',
    GET_BROKER_DATA_CONFIG_SUCCESS: MUTATION_PREFIX + 'GET_BROKER_DATA_CONFIG_SUCCESS',
    GET_ANSPRECHPARTNER_BY_ID_SUCCESS: MUTATION_PREFIX + 'GET_ANSPRECHPARTNER_BY_ID_SUCCESS',
    FIND_ANSPRECHPARTNER_SUCCESS: MUTATION_PREFIX + 'FIND_ANSPRECHPARTNER_SUCCESS',
    GET_BROKER_BY_ID_SUCCESS: MUTATION_PREFIX + 'GET_BROKER_BY_ID_SUCCESS',
    ADD_MAKLER_ANSPRECHPARTNER_DATA_EDITED: MUTATION_PREFIX + 'ADD_MAKLER_ANSPRECHPARTNER_DATA_EDITED',
    SAVE_BROKER_DATA_SUCCESS: MUTATION_PREFIX + 'SAVE_BROKER_DATA_SUCCESS',
    SAVE_MAKLER_ANSPRECHPARTNER_SUCCESS: MUTATION_PREFIX + 'SAVE_MAKLER_ANSPRECHPARTNER_SUCCESS',
    FIND_BIRTHDAYS_SUCCESS: MUTATION_PREFIX + 'FIND_BIRTHDAYS_SUCCESS',
    GET_MAKLER_PICTURE_SUCCESS:MUTATION_PREFIX + 'GET_MAKLER_PICTURE_SUCCESS',
    SAVE_MAKLER_PICTURE_SUCCESS:MUTATION_PREFIX +'SAVE_MAKLER_PICTURE_SUCCESS',
    GET_BROKER_PRIMAER_PERSONEN_SUCCESS: MUTATION_PREFIX + 'GET_BROKER_PRIMAER_PERSONEN_SUCCESS',
    SAVE_PRIMAER_PERSONEN_SUCCESS: MUTATION_PREFIX + 'SAVE_PRIMAER_PERSONEN_SUCCESS',

    UPLOAD_FACSIMILE_SUCCESS: MUTATION_PREFIX + 'UPLOAD_FACSIMILE_SUCCESS',
    FETCH_ACTIVITIES_SUCCESS: MUTATION_PREFIX + 'FETCH_ACTIVITIES_SUCCESS',
    FIND_BEMERKUNGEN_SUCCESS: MUTATION_PREFIX + 'FIND_BEMERKUNGEN_SUCCESS', 
    GET_BESUCHSBERICHTE_SUCCESS: MUTATION_PREFIX + 'GET_BESUCHSBERICHTE_SUCCESS',
    PUT_BROKER_PAGE_TABLE_SIZE: MUTATION_PREFIX + 'PUT_BROKER_PAGE_TABLE_SIZE',
    LOAD_FACSIMILE_MITARBEITER: MUTATION_PREFIX + 'LOAD_FACSIMILE_MITARBEITER',
    RESET_FACSIMILE: MUTATION_PREFIX + 'RESET_FACSIMILE',
    SET_STORNORESERVE_VERS: MUTATION_PREFIX + 'SET_STORNORESERVE_VERS',
    SET_SONSTIGES: MUTATION_PREFIX + 'SET_SONSTIGES',
  },
  ACTIONS: {
    FIND_BIRTHDAYS: ACTIONS_PREFIX + 'FIND_BIRTHDAYS',
    GET_BROKER_DATA: ACTIONS_PREFIX + 'GET_BROKER_DATA',
    GET_BROKER_DATA_CONFIG: ACTIONS_PREFIX + 'GET_BROKER_DATA_CONFIG',
    DELETE_PERSON: ACTIONS_PREFIX + 'DELETE_PERSON',
    DELETE_MAKLER_PICTURE: ACTIONS_PREFIX + 'DELETE_MAKLER_PICTURE',
    GET_ANSPRECHPARTNER_BY_ID: ACTIONS_PREFIX + 'GET_ANSPRECHPARTNER_BY_ID',
    GET_ANSPRECHPARTNER_ID: ACTIONS_PREFIX + 'GET_ANSPRECHPARTNER_ID',
    FIND_ANSPRECHPARTNER: ACTIONS_PREFIX + 'FIND_ANSPRECHPARTNER',
    GET_MAKLER_PICTURE: ACTIONS_PREFIX + 'GET_MAKLER_PICTURE',
    APPLY_SETTINGS_VERSANDINFO: ACTIONS_PREFIX + 'APPLY_SETTINGS_VERSANDINFO',
    SAVE_BROKER_DATA: ACTIONS_PREFIX + 'SAVE_BROKER_DATA',
    SAVE_ANSPRECHPARTNER: ACTIONS_PREFIX + 'SAVE_ANSPRECHPARTNER',
    SAVE_MAKLER_PICTURE: ACTIONS_PREFIX + 'SAVE_MAKLER_PICTURE',
    GET_ANSPRECHPARTNER_PICTURE: ACTIONS_PREFIX + 'GET_ANSPRECHPARTNER_PICTURE',
    GET_BROKER_PRIMAER_PERSONEN:ACTIONS_PREFIX + 'GET_BROKER_PRIMAER_PERSONEN',
    SAVE_PRIMAER_PERSONEN: ACTIONS_PREFIX + 'SAVE_PRIMAER_PERSONEN_SUCCESS',
    GENERATE_PDF_FACSIMILE: ACTIONS_PREFIX + 'GENERATE_PDF_FACSIMILE',
    GET_IMG_MAKLER_FACIMILIE: ACTIONS_PREFIX + 'GET_IMG_MAKLER_FACIMILIE',
    UPLOAD_FACSIMILE: ACTIONS_PREFIX + 'UPLOAD_FACSIMILE',
    SAVE_UPDATE_MAKLERKRITERIEN: ACTIONS_PREFIX + 'SAVE_UPDATE_MAKLERKRITERIEN',
    CREATE_FACSIMILE: ACTIONS_PREFIX + 'CREATE_FACSIMILE',
    UPLOAD_FACSIMILE_IMAGE: ACTIONS_PREFIX + 'UPLOAD_FACSIMILE_IMAGE',
    UPLOAD_FACSIMILE_PDF: ACTIONS_PREFIX + 'UPLOAD_FACSIMILE_PDF',
    REMOVE_FACSIMILE: ACTIONS_PREFIX + 'REMOVE_FACSIMILE',
    GET_FACSIMILE_MAKLER: ACTIONS_PREFIX + 'GET_FACSIMILE_MAKLER',
    GET_PIN_LIST: ACTIONS_PREFIX + 'GET_PIN_LIST',
    GET_TEMP_FILE: ACTIONS_PREFIX + 'GET_TEMP_FILE',
    FETCH_ACTIVITIES: ACTIONS_PREFIX + 'FETCH_ACTIVITIES',
    FIND_BEMERKUNGEN: ACTIONS_PREFIX + 'FIND_BEMERKUNGEN',
    GET_BESUCHSBERICHTE: ACTIONS_PREFIX + 'GET_BESUCHSBERICHTE',
    NOTWENDIGE_DOKUMENTE: ACTIONS_PREFIX + 'NOTWENDIGE_DOKUMENTE',
    LOAD_WK_ANLAGEASSITEN_PARAMS: ACTIONS_PREFIX + 'LOAD_WK_ANLAGEASSITEN_PARAMS',
    SAVE_WK_ANLAGEASSITEN_PARAMS: ACTIONS_PREFIX + 'SAVE_WK_ANLAGEASSITEN_PARAMS',
    LOAD_BROKER_PAGE_TABLE_SIZE: ACTIONS_PREFIX + 'LOAD_BROKER_PAGE_TABLE_SIZE',
    SAVE_NEW_BROKER: ACTIONS_PREFIX + 'SAVE_NEW_BROKER',
    FILL_BIC_AND_BANK: ACTIONS_PREFIX + 'FILL_BIC_AND_BANK',
    LOAD_SAVED_FACSIMILE_MITARBEITER: ACTIONS_PREFIX + 'LOAD_SAVED_FACSIMILE_MITARBEITER',
    LOAD_STORNORESERVE_VERS: ACTIONS_PREFIX + 'LOAD_STORNORESERVE_VERS',
    LOAD_SONSTIGES: ACTIONS_PREFIX + 'LOAD_SONSTIGES',
    SAVE_SONSTIGES: ACTIONS_PREFIX + 'SAVE_SONSTIGES',
  },
 
  GETTERS: {
    GET_BROKER_DATA_EDITED: GETTERS_PREFIX + 'GET_BROKER_DATA_EDITED',
    GET_BROKER_DATA: GETTERS_PREFIX + 'GET_BROKER_DATA',
    GET_BROKER_DATA_CONFIG: GETTERS_PREFIX + 'GET_BROKER_DATA_CONFIG',
    GET_ANSPRECHPARTNER_BY_ID: GETTERS_PREFIX + 'GET_ANSPRECHPARTNER_BY_ID',
    ANSPRECHPARTNER_LIST: GETTERS_PREFIX + 'ANSPRECHPARTNER_LIST',
    GET_BROKER_BY_ID: GETTERS_PREFIX + 'GET_BROKER_BY_ID',
    BIRTHDAYS: GETTERS_PREFIX + 'BIRTHDAYS',
    GET_MAKLER_PICTURE: GETTERS_PREFIX + 'GET_MAKLER_PICTURE',
    GET_MAKLER_ANSPRECHPARTNER_DATA_EDITED:GETTERS_PREFIX + 'GET_MAKLER_ANSPRECHPARTNER_DATA_EDITED',
    GET_BROKER_PRIMAER_PERSONEN: GETTERS_PREFIX + 'GET_BROKER_PRIMAER_PERSONEN',
    FACSIMILE: GETTERS_PREFIX + 'FACSIMILE',
    GET_ACTIVITIES: GETTERS_PREFIX + 'GET_ACTIVITIES', 
    BEMERKUNGEN: GETTERS_PREFIX + 'BEMERKUNGEN',
    BESUCHSBERICHTE: GETTERS_PREFIX + 'BESUCHSBERICHTE',
    GET_BROKER_PAGE_TABLE_SIZE: GETTERS_PREFIX + 'GET_BROKER_PAGE_TABLE_SIZE',
    GET_STORNORESERVE_VERS: GETTERS_PREFIX + 'GET_STORNORESERVE_VERS',
    GET_SONSTIGES: GETTERS_PREFIX + 'GET_SONSTIGES',
  },
}
