var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "einnahmen-und-ausgaben-dashboard-card__container" },
    [
      _c("EinnahmenUndAusgabenChart", {
        staticClass: "einnahmen-und-ausgaben-dashboard-card__content",
        attrs: { summary: _vm.summary }
      }),
      !_vm.isMobileNativeContext
        ? _c(
            "div",
            { staticClass: "text-right" },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: "/home/multibanking-account/einnahmen-und-ausgaben"
                  }
                },
                [_vm._v("weitere...")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }