export const MUTATION_PREFIX = 'SESSION_TIME: ';
export const ACTIONS_PREFIX = 'SESSION_TIME_ACTIONS_';
export const GETTERS_PREFIX = 'SESSION_TIME_GETTERS_';

export default {
  MUTATIONS: {
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    ADD_CLICK: MUTATION_PREFIX + 'ADD_CLICK',
    ADD_CLICK_BY_PATH: MUTATION_PREFIX + 'ADD_CLICK_BY_PATH',
    UPDATE_PARALLEL_TIMEOUT_TIME: MUTATION_PREFIX + 'UPDATE_PARALLEL_TIMEOUT_TIME',
    UPDATE_NEXT_SCHEDULED_SAVE_CLICK_BATCH: MUTATION_PREFIX + 'UPDATE_NEXT_SCHEDULED_SAVE_CLICK_BATCH',
    REMOVE_CLICKS_FROM_QUEUE: MUTATION_PREFIX + 'REMOVE_CLICKS_FROM_QUEUE',
  },
  ACTIONS: {
    ADD_CLICK: ACTIONS_PREFIX + 'ADD_CLICK',
    LOAD_CLICKS: ACTIONS_PREFIX + 'LOAD_CLICKS',
    SCHEDULE_NEXT_SAVE_CLICK_BATCH: ACTIONS_PREFIX + 'SCHEDULE_NEXT_SAVE_CLICK_BATCH',
    SEND_NEXT_SAVE_CLICK_BATCH: ACTIONS_PREFIX + 'SEND_NEXT_SAVE_CLICK_BATCH',
  },
  GETTERS: {
    CLICKS: GETTERS_PREFIX + 'CLICKS',
    CLICKS_BY_PATH: GETTERS_PREFIX + 'CLICKS_BY_PATH',
    PARALLEL_TIMEOUT_TIME: GETTERS_PREFIX + 'PARALLEL_TIMEOUT_TIME',
  }
}
