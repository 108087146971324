var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "workspaces-menu__container" },
    [
      _vm.isLoading
        ? [
            _c(
              "GhostLoading",
              {
                staticClass:
                  "workspaces-menu__ghost-loading hide-on-small-screen m-0",
                attrs: { width: 100, repeat: 4, inline: "" }
              },
              [_c("Block", { attrs: { width: 24, height: 24 } }), _c("Block")],
              1
            ),
            _c(
              "GhostLoading",
              {
                staticClass:
                  "workspaces-menu__ghost-loading hide-on-large-screen m-0",
                attrs: { width: 24, repeat: 4, inline: "" }
              },
              [_c("Block", { attrs: { width: 24, height: 24, opacity: 0.5 } })],
              1
            )
          ]
        : _vm._l(_vm.menuOptionsWithClass, function(menu) {
            return [
              !menu.disabled
                ? _c(
                    "span",
                    {
                      key: menu.path,
                      staticClass:
                        "workspaces-menu__item workspaces-menu__icons--spaced"
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "workspaces-menu__item",
                          class: [menu.class],
                          attrs: { tid: _vm.getTid(menu) },
                          on: {
                            click: function($event) {
                              return _vm.navigateTo(menu)
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "workspaces-menu__item--icon" },
                            [
                              _c(menu.component, {
                                tag: "component",
                                class: [menu.iconClass],
                                attrs: { size: 24, weight: menu.iconWeight }
                              }),
                              _c("BaseBadgeCounter", {
                                staticClass: "workspaces-menu__item--counter",
                                attrs: { count: menu.count }
                              })
                            ],
                            1
                          ),
                          _c(
                            "span",
                            { staticClass: "workspaces-menu__item--label" },
                            [_vm._v(_vm._s(menu.label))]
                          )
                        ]
                      )
                    ]
                  )
                : menu.disabled
                ? _c(
                    "span",
                    {
                      key: menu.path,
                      staticClass:
                        "workspaces-menu__item workspaces-menu__icons--spaced workspaces-menu__item-disabled",
                      class: [menu.class]
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "workspaces-menu__item--icon" },
                        [
                          _c(menu.component, {
                            tag: "component",
                            class: [menu.iconClass],
                            attrs: {
                              size: 24,
                              weight: menu.iconWeight,
                              tid: _vm.getTid(menu)
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "span",
                        { staticClass: "workspaces-menu__item--label" },
                        [_vm._v(_vm._s(menu.label))]
                      )
                    ]
                  )
                : _vm._e()
            ]
          })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }