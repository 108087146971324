import sanitizeHtml from 'sanitize-html';

export function findDiff(str1, str2){ 
  if (!str1 || !str2) {
    return null
  }
  let diff= '';
  str2.split('').forEach(function(val, i){
    if (val != str1.charAt(i))
      diff += val ;         
  });
  return diff;
}

const SANITIZE_IGNORE_TYPEOF = ['object', 'undefined', 'function']
export function sanitize(htmlString) {
    if (SANITIZE_IGNORE_TYPEOF.indexOf(typeof htmlString) >= 0) {
      return '';
    }

    let config = {
      allowedAttributes: {
        ...sanitizeHtml.defaults.allowedAttributes,
        '*': ['id', 'class'],
        p: ['style'],
        h1: ['style'],
        h2: ['style'],
        h3: ['style'],
        h4: ['style'],
        h5: ['style'],
        h6: ['style'],
        img: [ 'src', 'style', 'alt', 'width', 'height'],
        span: ['style'],
      },
      allowedTags: [...sanitizeHtml.defaults.allowedTags, 'img', 'nobr'],
      allowedSchemes: [...sanitizeHtml.defaults.allowedSchemes, 'data']
    }
    return sanitizeHtml(htmlString, config);
}

export function sanitizeEmail(htmlString) {
  let config = {
    allowedAttributes: {
      ...sanitizeHtml.defaults.allowedAttributes,
      '*': ['id', 'class'],
      img: [ 'src', 'style', 'alt'],
    },
    allowedTags: [...sanitizeHtml.defaults.allowedTags, 'img'],
    allowedSchemes: [...sanitizeHtml.defaults.allowedSchemes, 'data']
  }
  return sanitizeHtml(htmlString
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;"),
  config);
}

export function prepareFilenameForDownload(filename) {
  const sanitizedFilename = filename?.replaceAll?.('/', '') ?? ''
  const filenameEncoded = encodeURIComponent(sanitizedFilename);
  return filenameEncoded
}

export function formatHTMLTags(html) {
  const tab = '\t';
  let result = '';
  let indent= '';

  html.split(/>\s*</).forEach(function(element) {
    if (element.match( /^\/\w/ )) {
      indent = indent.substring(tab.length);
    }

    result += indent + '<' + element + '>\r\n';

    if (element.match( /^<?\w[^>]*[^\/]$/ ) && !element.startsWith("input")  ) { 
      indent += tab;              
    }
  });

  return result.substring(1, result.length - 3);
}