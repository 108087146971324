<template>
  <div>
  </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue'

export default {
  mixins: [],
  beforeRouteLeave (to, from, next) {
    this.$addBreadcrumb({
      label: `zurück zu den Einstellungen`,
      to,
      from,
    });

    next()
  },
  components: {
    OptionMenu,
  }
}
</script>

<style scoped>

</style>