<template>
  <div :key="configContext">
    <div class="box__container">
      <div class="box__title">{{ configContextLabel }}</div>

      <OptionMenuGhostLoading v-if="isLoading" showTitle />
      <OptionMenuEditPage v-else-if="currentOptionMenuId"
        ref="optionMenuEditPageEl"
        :key="currentPath"
        :configId="currentOptionMenuId"
        :defaultMenu="currentOptionMenu"
        :optionMenu="optionMenu"
        :currentPath="currentPath"
        :configContextLabel="configContextLabel"
        :configContext="configContext"
        :enablePermissionConfig="enablePermissionConfig"
      />
      <NoData v-else />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MENU_CONFIG_TYPES from '@/store/menuConfig/types';

import OptionMenuEditPage from '@/components/core/option-menu/OptionMenuEditPage.vue';
import OptionMenuGhostLoading from '@/components/core/option-menu/OptionMenuGhostLoading.vue';
import NoData from '@/components/core/NoData.vue';

import { MAP_CONFIG_CONTEXT_LABEL, EINSTELLUNGEN_MITARBEITEREBENE_LABEL, } from '@/menu/menu-config-utils';

const DEFAULT_STEP_LABEL = 'Einstellungen';

export default {
  components: {
    OptionMenuEditPage,
    OptionMenuGhostLoading,
    NoData,
  },
  props: {
    configContext: {
      type: String,
      required: true,
    },
    currentPath: {
      type: String,
      required: true,
    },
    enablePermissionConfig: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      appNavigationConfigByPath: MENU_CONFIG_TYPES.GETTERS.APP_NAVIGATION_CONFIG_BY_PATH,
      hasSelectedUnternFn: MENU_CONFIG_TYPES.GETTERS.HAS_SELECTED_UNTERNR,
    }),
    hasSelectedUntern() {
      return this.hasSelectedUnternFn(this.configContext);
    },
    isLoading() {
      return !this.currentPath;
    },
    configContextLabel() {
      if(this.hasSelectedUntern) {
        return EINSTELLUNGEN_MITARBEITEREBENE_LABEL;
      }

      return MAP_CONFIG_CONTEXT_LABEL?.[this.configContext] || DEFAULT_STEP_LABEL;
    },
    currentAppNavigationConfig() {
      return this.currentPath && this.appNavigationConfigByPath(this.configContext, this.currentPath);
    },
    currentOptionMenuId() {
      if(!this.currentPath) return;
      return this.currentAppNavigationConfig?.currentOptionMenuId;
    },
    currentOptionMenu() {
      return this.currentAppNavigationConfig?.currentOptionMenu || [];
    },
    optionMenu() {
      return this.currentAppNavigationConfig?.optionMenu || [];
    },
  },
  watch: {
    configContext(_, previousConfigContext) {
      this.saveData(previousConfigContext); // saves previous selected config context
    },
    currentPath() {
      this.saveData(this.configContext);
    },
  },
  methods: {
    async saveData(configContext) {
      await this.$refs.optionMenuEditPageEl?.onSaveConfigPage?.(configContext);
    },
  },
  async beforeRouteLeave(to, from, next) {
    await this.saveData(this.configContext);
    next();
  },
  async beforeDestroy() {
    await this.saveData(this.configContext);
  },
}
</script>
