import store from '@/store';
import CORE_TYPES from '@/store/core/types';

import { viewDocument } from '@/components/core/download/DownloadLink.vue';
import { downloadLinkMakerLinkResolver } from '@/helpers/utils-helper';

import { WrapType } from './types';

/**
 * Create a link resolver
 * 
 * Note: Avoid to use router/store data in a link resolver - it can lead to issues when opening a new window
 * 
 * @param {*} id 
 * @param {*} fn 
 * @returns 
 */
export function createLinkResolver(id, fn) {
  if (!id || !fn) {
    throw `link-resolvers >>> an 'id' and 'fn' must be provided when creating a new link resolver`;
  }

  return {
    id,
    fn,
  };
}

/**
 * Provider a object mapped by id => function
 * 
 * @param {*} resolvers 
 * @returns 
 */
export function buildLinkResolvers(resolvers) {
  return Object.values(resolvers).reduce((acc, resolver) => {
    if (resolver.id in acc) { // check duplicate
      console.error(`link-resolvers >>> resolverId is duplicated: "${resolver.id}"`);
    }

    return {
      ...acc,
      [resolver.id]: resolver.fn,
    };
  }, {});
}

// Wrappers

/**
 * 
 * @param {string} message 
 * @returns 
 */
export function wrapError(message = '') {
  return {
    type: WrapType.Error,
    message,
  };
}

/**
 * 
 * @param {string} filename 
 * @param {string} contentType 
 * @param {string} data 
 * @returns 
 */
export function wrapViewDocument(filename, contentType, data) {
  return {
    type: WrapType.ViewDocument,
    filename,
    contentType,
    data,
  };
}

/**
 * 
 * @param {string} filename 
 * @param {string} servicePath 
 * @param {object} queryParams 
 * @returns 
 */
export function wrapDownloadLink(filename, servicePath, queryParams) {
  return {
    type: WrapType.DownloadLink,
    filename,
    servicePath,
    queryParams,
  };
}

/**
 * 
 * @param {string} filename 
 * @param {string} href 
 * @returns 
 */
export function wrapDownloadLinkHref(filename, href) {
  return {
    type: WrapType.DownloadLinkHref,
    filename,
    href,
  };
}

/**
 * 
 * @param {string} action
 * @param {object} data - form hidden input : object prop as input name, prop value as input value
 * @returns 
 */
export function wrapFormData(action, data = {}) {
  return {
    type: WrapType.FormData,
    action,
    data,
  };
}

export function wrapUrl(url) {
  return {
    type: WrapType.Url,
    url,
  };
}


// Handlers

const HTTP_SCHEMA_PATTERN = /^(http|https):\/\//;
function validateURLSchema(url) {
  if (!url) {
    throw new Error('Adresse konnte nicht geöffnet werden');
  }

  if (!(HTTP_SCHEMA_PATTERN.test(url))) {
    throw new Error('Ungültige Adresse: ' + url);
  }
}

/**
 * Navigates to a URL
 * 
 * @param {object} response - url wrapped object
 */
export function handleURL(response) {
  validateURLSchema(response.url);

  window.location.href = response.url;
}

/**
 * Submits a form
 * 
 * @param {object} response - form data wrapped object
 */
export function handleFormData(response) {
  validateURLSchema(response.action);

  // form
  const formEl = document.createElement('form');
  formEl.action = response.action;
  formEl.method = 'POST';

  // make it hidden
  formEl.style.cssText = `
    visibility: hidden;
    overflow: hidden;
    width: 0;
    height: 0;
  `;

  // input fields
  Object.entries(response.data)
    .map(([key, value]) => {
        const inputEl = document.createElement('input');
        inputEl.type = 'hidden';
        inputEl.name = key;
        inputEl.value = value;
        return inputEl;
    })
    .forEach(inputEl => formEl.appendChild(inputEl));

  // submit button
  const submitEl = document.createElement('button');
  submitEl.type = 'submit';
  submitEl.textContent = 'Submit';
  formEl.appendChild(submitEl);

  // append to body
  document.body.appendChild(formEl);

  // submit
  formEl.addEventListener('submit', () => requestAnimationFrame(() => document.body.removeChild(formEl)));
  submitEl.click();
}

/**
 * Calls view document from download link component
 * 
 * @param {object} response - view document wrapped object
 */
export function handleViewDocument(response) {
  viewDocument({
    filename: response.filename,
    contentType: response.contentType,
    data: response.data,
  });
}

/**
 * Calls view document from download link component
 * 
 * @param {object} response - download link wrapped object
 */
export function handleDownloadLink(response) {
  const apiAddress = store.getters[CORE_TYPES.GETTERS.API_ADDRESS] || store.state.LinkResolvers__ApiAddress;
  const token = store.getters[CORE_TYPES.GETTERS.GET_TOKEN] || store.state.LinkResolvers__Token;

  const localState = {
    apiAddress,
    token,
  };

  const href = downloadLinkMakerLinkResolver(localState, response.servicePath, response.filename, response.queryParams);

  handleDownloadLinkHref({
    filename: response.filename,
    href,
  });
}

/**
 * Calls view document from download link component
 * 
 * @param {object} response - download link href wrapped object
 */
export function handleDownloadLinkHref(response) {
  viewDocument({
    filename: response.filename,
    href: response.href,
    useRedirect: true,
  });
}
