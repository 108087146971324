<template>
  <div>
    <GesellschaftTitleNavigation
      :headerActions="headerActions"
      @action-CREATE_KENNUNG="openCreateKennungModal"
    />

    <div class="box__container">
      <GhostLoading v-if="loading" type="table" :title="TABLE_TITLE" />
      <Table 
        v-else
        :title="TABLE_TITLE"
        :rows="rows"
        :headers="headers"
        :rowsPerPage="20"
        @action-DELETE="deleteKennung"
      />
    </div>

    <BaseModal 
      ref="createKennungModal"
      modalTitle="Kennung hinzufügen"
      :confirmDisabled="!editRow.provider || !editRow.kennung"
      @onConfirmButton="saveKennung(editRow)"
    > 
      <ComboBox
        label="Datenlieferant" 
        :sortComboboxValues="false"
        :values="providerValues"
        v-model="editRow.provider"
      />
      <InputField 
        label="Kennung"
        v-model="editRow.kennung"
      />
    </BaseModal>
  </div>
</template>

<script>
import GESELLSCHAFT_TYPES from '@/store/gesellschaft/types';

import { mapGetters } from 'vuex';
import axios from 'axios';

import GesellschaftTitleNavigation from '@/views/gesellschaft/intern/GesellschaftTitleNavigation.vue'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Table from "@/components/table2/Table.vue";
import ComboBox from '@/components/core/forms/ComboBox.vue'
import InputField from '@/components/core/forms/InputField.vue'
import BaseModal from '@/components/core/BaseModal.vue'
import { TextColumn, ActionColumn, ConfirmedAction } from "@/components/table2/table_util.js";
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';
import { makeQueryParam } from '@/helpers/utils-helper';

const TABLE_TITLE = 'Kennungen';
const config = {
  defaultSpinner: true
};

export default {
  mixins: [],
  components: {
    GesellschaftTitleNavigation,
    GhostLoading,
    Table,
    BaseModal,
    InputField,
    ComboBox,
  },
  data() {
    return {
      TABLE_TITLE,
      loading: false,
      isEdit: false,
      editRow: {},
    }
  },
  computed: {
    ...mapGetters({
      gesellschaft: GESELLSCHAFT_TYPES.GETTERS.GET_GESELLSCHAFT,
    }),
    headers() {
      return {
        lockedLeft: [
          TextColumn("provider", "Lieferant"),
          TextColumn("kennung", "Kennung"),
        ],
        center: [],
        lockedRight: [
          ActionColumn("actions", "Aktionen"),
        ]
      }
    },
    rows() {
      return (this.gesellschaft.kennungen || []).map(row => ({
          ...row,
          rowId: row.kennung + row.provider,
          actions: this.makeActions(row)
      }))
    },
    headerActions() {
      return [
        PageHeaderSimpleAction('CREATE_KENNUNG', `Neue Kennung`),
      ]
    },
    providerValues() {
      return this.gesellschaft.comboboxSelections?.providerValues || []
    },
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData(isReload = false) {
      this.loading = true

      this.$store.dispatch(GESELLSCHAFT_TYPES.ACTIONS.GET_GESELLSCHAFT_KENNUNGEN, { gesellschaftId: this.$route.params.gesellId, isReload })
      .finally(() => this.loading = false);
    },
    openCreateKennungModal() {
      this.editRow = {}

      this.$refs.createKennungModal.open()
    },
    saveKennung() {
      const params = makeQueryParam({ gesellschaftId: this.$route.params.gesellId }, true)

      axios.post(`${process.env.VUE_APP_API}/gesellschaftenService/kennung?${params}`, this.editRow, config).then(() => {
        this.initData(true)
      })
    },
    deleteKennung({provider, kennung}) {
      const params = makeQueryParam({ provider, kennung })

      axios.delete(`${process.env.VUE_APP_API}/gesellschaftenService/kennung?${params}`, config).then(() => {
        this.gesellschaft.kennungen = this.gesellschaft.kennungen.filter(kennung => kennung.provider !== provider && kennung.kennung !== kennung)
      })
    },
    makeActions(row) {
      return [
        ConfirmedAction("DELETE", 'PhTrash', "Löschen", `Soll die Kennung "${row.kennung}" für "${row.provider}" wirklich gelöscht werden?`, `Kennung löschen`, "Löschen")
      ]
    },
  },
}
</script>