<template>
  <div>
    <PageHeaderTitleNavigation
      :title="label"
      :defaultMenu="customOptionMenu"
      :actions="actions"
      @action="$emit(`action-${$event.key}`, $event.value)"
      @action-BRIEF_VORLAGE="briefVorlage"
      @action-PRODUKTE="openProdukte"
      @action-BUCHUNG_GESELLSCHAFT="doBuchung('buchungGesellschaft')"
      @action-BUCHUNG_LAGERSTELLE="doBuchung('buchungLagerstelle')"
      @action-BUCHUNG_BANKKONTO="doBuchung('buchungBankkonto')"
    />

  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import GESELLSCHAFT_TYPES from '@/store/gesellschaft/types';
import { mapGetters } from 'vuex';
import axios from 'axios';
import { makeQueryParam } from '@/helpers/utils-helper';

import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import { PageHeaderSimpleAction, PageHeaderConfirmedAction } from '@/components/core/header-title-navigation/page-header-utils';
import { TabMenu, } from '@/menu/menu-utils';
// import router, { getRouteChildren } from '@/router';

export default {
  mixins: [],
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
  },
  props: {
    headerActions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      gesellschaft: GESELLSCHAFT_TYPES.GETTERS.GET_GESELLSCHAFT,
    }),
    label() {
      return this.gesellschaft.label || ''
    },
    customOptionMenu() {
      const url = this.$route.path
      return this.gesellschaft.menu?.map(item => TabMenu(`${url.substring(0, url.lastIndexOf('/'))}/${item.key}`, item.label)) || []
    },
    actions() {
      const actions = [
        ...this.headerActions,
      ]

      if (this.isIntern) {
        // actions.push(PageHeaderSimpleAction('BRIEF_VORLAGE', 'Brief erstellen (PDF)'))
        if (this.gesellschaft.isVersicherung) {
          actions.push(PageHeaderSimpleAction('PRODUKTE', 'Produkte'))
        }

        actions.push(PageHeaderConfirmedAction('BUCHUNG_GESELLSCHAFT', 'Korrektur-/ Einbuchung Gesellschaft', 
          `Sollen die Korrektur- / Einbuchungen für die Gesellschaft "${this.label}" durchgeführt werden?`, 'Korrektur- / Einbuchungen erstellen', 'Durchführen'))
        actions.push(PageHeaderConfirmedAction('BUCHUNG_LAGERSTELLE', 'Korrektur-/ Einbuchung Lagerstelle', 
          `Sollen die Korrektur- / Einbuchungen für die Lagerstelle "${this.label}" durchgeführt werden?`, 'Korrektur- / Einbuchungen erstellen', 'Durchführen'))
        actions.push(PageHeaderConfirmedAction('BUCHUNG_BANKKONTO', 'Ausbuchung Bankkonto', 
          `Sollen die Ausbuchung der Bankkonten für die Lagerstelle "${this.label}" durchgeführt werden?`, 'Ausbuchung erstellen', 'Durchführen'))
      }

      return actions
    },
  },
  mounted() {
    this.$store.dispatch(GESELLSCHAFT_TYPES.ACTIONS.GET_GESELLSCHAFT_MENU, {
      gesellschaftId: this.$route.params.gesellId,
      isModusVersDaten: this.$route.meta?.isModusVersDaten,
    })
    // console.log('getRouteChildren', getRouteChildren(`/intern/gesellschaften/:gesellId`))
  },
  methods: {
    briefVorlage() {

    },
    openProdukte() {
      if (this.gesellschaft.isVersicherung) {
        this.$addBreadcrumb({
          fullPath: this.$route.fullPath,
          breadcrumb: 'Versicherungstarife',
        });

        this.$router.push({path: `/intern/versicherungstarife`, query: { gesellschaftId: this.$route.params.gesellId }});
      }
    },
    doBuchung(type) {
      const params = makeQueryParam({ gesellschaftId: this.$route.params.gesellId }, true)

      axios.post(`${process.env.VUE_APP_API}/gesellschaftenService/${type}?${params}`, undefined, config).then(response => {
        if (response && response.data) {
          this.$confirmModal({
            title: response.data,
            labelButtonConfirm: 'Ok',
            showCancelButton: false,
          })
        } 
      })
    },
  },
}
</script>