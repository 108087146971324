import axios from 'axios';

import LOG_TYPES from '@/store/log/types';
import { buildMessage } from '@/helpers/log-message-helper'

export function doUpload(url){
    const config = {
      defaultSpinner: true,
      responseType: 'arraybuffer',
    };
    axios.get(url , config).then(response => {
      const file = new File([response.data], "file" + ".pdf", {type: "application/pdf"})
      window.open(URL.createObjectURL(file))
    }).catch(error => {
      if (error && error.response && error.response.data && error.response.data.message)
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error.response.data.message, 'danger'));
      else {
        dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "PDF_PAGE_SHOW", error})
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage("Beim Aufruf der PDF Datei ist ein unerwarteter Fehler aufgetreten.", 'danger'));
      }
    })
  } 
  export   function bla(){
    const config = {
      defaultSpinner: true,
    };

    let res ='vers981';
    alert(process.env.VUE_APP_API);
    axios.get(process.env.VUE_APP_API+ '/versandProtokolle/menuItem').then(response => {
      
    
      //commit(V_P.MUTATIONS.LABEL, response.data);
      res= response.data;
      return res;  
     
    });
    
    return res;
  }
  export function  isLegi(b){
    return b&&b.kundenname&&b.kundennrIn&&b.deckblattLabel=='LEGITIMATIONSURKUNDE';
  }