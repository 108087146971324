<template>
  <div>
    <BaseButton
      isClear 
      @click="isLayoutVisible = !isLayoutVisible" 
      :disabled="disabled">
      Layout
      <PhCaretDown size="20" v-if="!value" />
      <PhCaretUp size="20" v-if="value" />
    </BaseButton>
    <div v-if="isLayoutVisible && editor">
      <div class="row">
        <div class="col-12 col-sm-4 col-md-2 col-lg-2">
          <
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PhCaretUp, PhCaretDown } from 'phosphor-vue';
import BaseButton from '@/components/core/BaseButton.vue';

export default {
  components: {
    PhCaretUp,
    PhCaretDown,
    BaseButton,
  },
  props: {
    editor: {
    },
    value: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      isLayoutVisible: false,
    }
  },
}

</script>