import store from '@/store'
import WEBRTC_TYPES from './types'
import LOG_TYPES from '@/store/log/types'
import { getInitialState } from './index'
import Vue from 'vue'
import { nextIncomingCallData } from './getters'
import { closePeerConnection } from '@/store/webrtc/sockets/peer-connection';
import { closeSignalingSocket } from '@/store/webrtc/sockets/signaler';

export default {
  [WEBRTC_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState());
  },
  [WEBRTC_TYPES.MUTATIONS.SET_PUSH_SOCKET](state, socket) {
    state.pushSocket = socket;
  },
  [WEBRTC_TYPES.MUTATIONS.SET_CALLEE_CHAT_BETEILIGTER_ID](state, calleeChatBeteiligterId) {
    state.calleeChatBeteiligterId = calleeChatBeteiligterId;
  },
  [WEBRTC_TYPES.MUTATIONS.UPDATE_AVAILABILITY](state, {calleeChatBeteiligterId, available}) {
    // only used for brokers
    if (!state.calleeChatBeteiligterAvailable)
      state.calleeChatBeteiligterAvailable = {};
    if (calleeChatBeteiligterId) {
      if (available)
        Vue.set(state.calleeChatBeteiligterAvailable, calleeChatBeteiligterId, available);
      else
        Vue.delete(state.calleeChatBeteiligterAvailable, calleeChatBeteiligterId);
    }
  },
  [WEBRTC_TYPES.MUTATIONS.TOGGLE_CALL_MODAL](state, calleeChatBeteiligterId) {
    state.callModalForceOpenId = calleeChatBeteiligterId;
  },
  [WEBRTC_TYPES.MUTATIONS.UPDATE_BETEILIGTER_INFO](state, {calleeChatBeteiligterId, displayName, profilBildUrl, kundennr, userId }) {
    Vue.set(state.beteiligterInfos, calleeChatBeteiligterId, {
      displayName,
      profilBildUrl,
      kundennr,
      userId,
    });
  },
  [WEBRTC_TYPES.MUTATIONS.UPDATE_CALLING_ID](state, calleeChatBeteiligterId) {
    state.weAreCallingId = calleeChatBeteiligterId;
  },
  [WEBRTC_TYPES.MUTATIONS.CREATE_CALL_INFO](state, callInfo) {
    state.callInfos.push(callInfo)
  },
  [WEBRTC_TYPES.MUTATIONS.CLEAR_SIGNAL_BACKLOG](state, callId) {
    const index = state.callInfos.findIndex(data => data.callId == callId);
    if (index >= 0) {
      Vue.set(state.callInfos, index, {
        ...state.callInfos[index],
        socketBacklog: null,
      });
    }
  },
  [WEBRTC_TYPES.MUTATIONS.SEND_SIGNAL_MESSAGE](state, { callId, message }) {
    const callInfo = state.callInfos.find(data => data.callId == callId);
    if (callInfo) {
      if (callInfo.socketBacklog) {
        callInfo.socketBacklog.push(message);
      } else {
        callInfo.socket.send(message);
      }
    }
  },
  [WEBRTC_TYPES.MUTATIONS.RECEIVE_STREAMS](state, { callId, streams }) {
    const callInfo = state.callInfos.find(data => data.callId == callId);
    if (callInfo) {
      callInfo.receivedStream = streams[0];
      callInfo.receivedStream.getTracks().forEach(track => {
        if (track && track.kind === 'video') {
          // Vue.set(callInfo, 'receivingVideo', true);
        }
      });
    } else {
      streams.forEach(stream => stream.getTracks().forEach(track => track.stop()));
    }
  },
  [WEBRTC_TYPES.MUTATIONS.END_CALL](state, {callId, alertMsg, closeSocketData, messageStatus, isMissedCall}) {
    const index = state.callInfos.findIndex(data => data.callId == callId);
    if (index >= 0) {
      const callInfo = state.callInfos[index];
      callInfo.closeSocketData = undefined;
      if (callInfo.receivedStream) {
        callInfo.receivedStream.getTracks().forEach(track => track.stop());
      }

      closePeerConnection(callInfo);
      closeSignalingSocket(callInfo, closeSocketData);

      if (callInfo.videoSender && callInfo.videoSender.track) {
        // release video stream
        callInfo.videoSender.track.onended = null;
        callInfo.videoSender.track.stop();
      }
      alertMsg = alertMsg != null ? alertMsg : callInfo.alertMsg;
      Vue.set(callInfo, 'messageStatus', messageStatus);
      if (alertMsg == null) {
        state.callInfos.splice(index, 1);
      } else {
        Vue.set(callInfo, 'alertMsg', alertMsg);
        Vue.set(callInfo, 'isMissedCall', isMissedCall);
      }
    }
    if (state.cameraVideo) {
      state.cameraVideo.getTracks().forEach(track => {
        track.onended = null;
        track.stop();
      })
      Vue.set(state, 'cameraVideo', null);
    }

    if (state.callId == callId) {
      state.callId = null;
      state.callStart = null;
    }

    // release audio stream
    if (state.audioInput) {
      state.audioInput.mediaStream.getTracks().forEach(track => {
        track.onended = null;
        track.stop();
      })
      state.audioInput.disconnect(state.audioDestination);
    }
    Vue.set(state, 'audioInput', null);
    Vue.set(state, 'transmittedVideo', null);
  },
  [WEBRTC_TYPES.MUTATIONS.REMOTE_ICE_CANDIDATE](state, { callId, candidate }) {
    const callInfo = state.callInfos.find(data => data.callId === callId);
    if (callInfo) {
      if (candidate.messageSdpAnswer) {
        callInfo.candidates = [];
        let remoteDesc = new RTCSessionDescription({
          sdp: candidate.messageSdpAnswer.sdpAnswer,
          type: "answer",
        });
        callInfo.peerConnection.setRemoteDescription(remoteDesc)
        .then(() => {
          let savedCandidates = callInfo.candidates
          callInfo.candidates = null
          while(savedCandidates.length > 0)
            callInfo.peerConnection.addIceCandidate(new RTCIceCandidate(savedCandidates.shift()))
        });
      }
      if (callInfo.candidates) {
        callInfo.candidates.push(candidate);
      }
      else {
        callInfo.peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
      }
    }
  },
  [WEBRTC_TYPES.MUTATIONS.CALL_ESTABLISHED](state, callId) {
    const callInfo = state.callInfos.find(data => data.callId == callId);
    if (!callInfo) {
      throw "CALL_ESTABLISHED called but no fitting callInfo found";
    }
    Vue.set(callInfo, 'acceptedButNotEstablished', false);
    Vue.set(callInfo, 'established', true);
    state.callId = callId;
    state.callStart = Date.now();
  },
  [WEBRTC_TYPES.MUTATIONS.REMOTE_VIDEO](state, receivingVideo) {
    const callInfo = state.callInfos.find(data => data.callId == state.callId) || nextIncomingCallData(state);
    if (callInfo)
      Vue.set(callInfo, 'receivingVideo', receivingVideo);
  },
  [WEBRTC_TYPES.MUTATIONS.REMOTE_AUDIO](state, { receivingAudio, callId }) {
    const callInfo = state.callInfos.find(data => data.callId == state.callId || data.callId == callId);
    if (callInfo)
      Vue.set(callInfo, 'receivingAudio', receivingAudio);
  },
  [WEBRTC_TYPES.MUTATIONS.ACCEPT_CALL](state, callId) {
    const callInfo = state.callInfos.find(data => data.callId == callId);
    Vue.set(callInfo, 'acceptedButNotEstablished', true);
  },
  [WEBRTC_TYPES.MUTATIONS.AUDIO_INPUT](state, stream) {
    if (state.audioInput) {
      state.audioInput.mediaStream.getTracks().forEach(track => {
        track.onended = null;
        track.stop();
      })
      state.audioInput.disconnect(state.audioDestination);
    }
    Vue.set(state, 'audioInput', state.audioCtx.createMediaStreamSource(stream));
    state.audioInput.connect(state.audioDestination);
  },
  [WEBRTC_TYPES.MUTATIONS.SET_MUTE_STATE](state, muted) {
    // Vue.set doesn't work with MediaStreamTrack
    state.audioDestination.stream.getAudioTracks()[0].enabled = !muted;
    Vue.set(state, 'muted', muted);
  },
  [WEBRTC_TYPES.MUTATIONS.TRANSMIT_VIDEO](state, stream) {
    const callInfo = state.callInfos.find(data => data.callId == state.callId);
    const oldTrack = callInfo.videoSender.track;

    // switch back to previously transmitted camera stream after ending screen share
    if (!stream && state.cameraVideo && state.transmittedVideo != state.cameraVideo) {
      stream = state.cameraVideo;
    }

    if (stream) {
      callInfo.videoSender.replaceTrack(stream.getVideoTracks()[0]);
    }

    // only end old stream if it's not a camera stream replaced by screen share
    if (!state.cameraVideo || state.transmittedVideo != state.cameraVideo || !stream) {
      // release previous input stream
      oldTrack.onended = null;
      oldTrack.stop();
      if (state.transmittedVideo == state.cameraVideo) {
        Vue.set(state, 'cameraVideo', null);
      }
    }
    Vue.set(state, 'transmittedVideo', stream);
    callInfo.videoSender.track.enabled = !!stream;
  },
  [WEBRTC_TYPES.MUTATIONS.SETUP_INITIAL_STREAM](state, { callId, stream }) {
    const callInfo = state.callInfos.find(data => data.callId === callId)
    callInfo.peerConnection.addTrack(state.audioDestination.stream.getAudioTracks()[0], state.audioDestination.stream);

    stream.getTracks().forEach(track => {
      const videoSender = callInfo.peerConnection.addTrack(track, stream);
      Vue.set(callInfo, 'videoSender', videoSender);
    });

    Vue.set(state, 'transmittedVideo', stream);
    Vue.set(state, 'cameraVideo', stream);
  },
  [WEBRTC_TYPES.MUTATIONS.IS_DUMMY_STREAM](state, isDummyStream) {
    Vue.set(state, 'isDummyStream', isDummyStream);
  },
  [WEBRTC_TYPES.MUTATIONS.UPDATE_REQUESTED_VIDEO](state, newCallInfo) {
    const callInfo = state.callInfos.find(data => data.callId == state.callId) || newCallInfo;
    if (state.transmittedVideo) {
      Vue.set(callInfo, 'requestedVideo', true);
    }
  },
  [WEBRTC_TYPES.MUTATIONS.CAMERA_VIDEO](state, stream) {
    if (state.cameraVideo && state.cameraVideo != stream) {
      state.cameraVideo.getTracks().forEach(track => {
        track.onended = null;
        track.stop();
      })
    }
    Vue.set(state, 'cameraVideo', stream);
  },
  [WEBRTC_TYPES.MUTATIONS.ADD_CHAT_INFO](state, data) {
    if (data.chatId != '0' || data.channelId != '0') {
      const oldChatInfo = state.chatInfos.find(chatInfo => 
          (chatInfo.chatId == data.chatId && chatInfo.channelId == data.channelId) || 
          (chatInfo.beteiligterId && chatInfo.beteiligterId == data.beteiligterId));
          
      if (oldChatInfo)
        state.chatInfos.splice(state.chatInfos.indexOf(oldChatInfo), 1);
    }
    state.chatInfos.push(data);
  },
  [WEBRTC_TYPES.MUTATIONS.SAVE_PUSH_PAYLOAD](state, pushPayload) {
    Vue.set(state, 'payloadOfPushBeforeLogin', pushPayload);
  },
  [WEBRTC_TYPES.MUTATIONS.UPDATE_CHAT_MESSAGES](state, {chatId, channelId, emailId, messageList}) {
    const chatInfo = state.chatInfos.find(chatInfo => chatInfo.chatId == chatId && chatInfo.channelId == channelId && (!emailId || chatInfo.emailId == emailId));
    if (chatInfo) {
      Vue.set(chatInfo, 'messageList', messageList);
    }
  },
  [WEBRTC_TYPES.MUTATIONS.UNSENT_CHAT](state, {beteiligterId, emailId, message}) {
    const chatInfo = state.chatInfos.find(chatInfo => (!emailId || emailId == chatInfo.emailId) && (!beteiligterId || beteiligterId == chatInfo.beteiligterId) && chatInfo.chatId == '0' && chatInfo.channelId == '0');
    if (chatInfo) {
      if (!chatInfo.unsent)
        Vue.set(chatInfo, 'unsent', []);
      if (message)
        chatInfo.unsent.push(message);
    }
  },
  [WEBRTC_TYPES.MUTATIONS.INITIALIZE_CHAT_INFO](state, {beteiligterId, emailId, chatId, channelId}) {
    const chatInfo = state.chatInfos.find(chatInfo => (!emailId || emailId == chatInfo.emailId) || (!beteiligterId || beteiligterId == chatInfo.beteiligterId) && chatInfo.chatId == '0' && chatInfo.channelId == '0' && !chatInfo.emailId);
    if (chatInfo) {
      Vue.set(chatInfo, 'chatId', chatId);
      Vue.set(chatInfo, 'channelId', channelId);
      Vue.set(chatInfo, 'unsent', undefined);
    }
  },
  [WEBRTC_TYPES.MUTATIONS.LOAD_ATTACHMENT](state, {attId, data}) {
    state.chatInfos.forEach(chatInfo => chatInfo.messageList && chatInfo.messageList.forEach(message => message.imgAttachments.forEach(attachment => {
      if (attachment.id == attId) {
        Vue.set(attachment, 'imageData', data);
      }
    })))
  },
  [WEBRTC_TYPES.MUTATIONS.CLOSE_ALERT_MESSAGE_AFTER_CLOSING](state) {
    const index = state.callInfos.findIndex(callInfo => callInfo.alertMsg != null);
    if (index >= 0) {
      state.callInfos.splice(index, 1);
    }
  },
  [WEBRTC_TYPES.MUTATIONS.CLOSED_TOO_EARLY](state, {callId, closeSocketData, alertMsg}) {
    const callInfo = state.callInfos.find(data => data.callId == callId);

    closePeerConnection(callInfo);
    closeSignalingSocket(callInfo, closeSocketData);

    Vue.set(callInfo, 'closeSocketData', closeSocketData);
    Vue.set(callInfo, 'alertMsg', alertMsg);
  },
  [WEBRTC_TYPES.MUTATIONS.UPDATE_RINGING_SYMBOL](state) {
    state.ringSignalState = !state.ringSignalState;
  },
  [WEBRTC_TYPES.MUTATIONS.START_RECORDING](state) {
    const callInfo = state.callInfos.find(data => data.callId == state.callId);
    Vue.set(callInfo, 'recording', true);
  },
  [WEBRTC_TYPES.MUTATIONS.ENUMERATE_DEVICES](state) {
    navigator.mediaDevices.enumerateDevices().then(devices => {
      Vue.set(state, "cameraOptions", [{label: "Standard-Kamera", value: ""}]);
      Vue.set(state, "microphoneOptions", [{label: "Standard-Mikrofon", value: ""}]);
      Vue.set(state, "speakerOptions", [{label: "Standard-Lautsprecher", value: ""}]);
      devices.forEach(element => {
        if (element.kind === 'videoinput') {
          state.cameraOptions.push({
            label: element.label || `Kamera ${state.cameraOptions.length}`,
            value: element.deviceId,
          })
        }
        else if (element.kind === 'audioinput') {
          state.microphoneOptions.push({
            label: element.label || `Mikrofon ${state.microphoneOptions.length}`,
            value: element.deviceId,
          })
        }
        else if (element.kind === 'audiooutput') {
          state.speakerOptions.push({
            label: element.label || `Lautsprecher ${state.speakerOptions.length}`,
            value: element.deviceId,
          })
        }
      });

      state.cameraOptions.sort((a, b) => a.label?.localeCompare(b.label));
      state.microphoneOptions.sort((a, b) => a.label?.localeCompare(b.label));
      state.speakerOptions.sort((a, b) => a.label?.localeCompare(b.label));
    }).catch(e => {
      store.dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "error attempting to enumerate devices", error: e, });
    });
  },
  [WEBRTC_TYPES.MUTATIONS.WEBRTC_PREPARE_CALL_REQUEST_UUID](state, { UID }) {
    Vue.set(state, 'prepareCallRequestUID', UID);
  },
  [WEBRTC_TYPES.MUTATIONS.CREATE_AUDIO_CONTEXT](state) {
    // due to chrome privacy policy changes, this mutation must be called from a user interaction: 
    // e.g. the user starts or answer a call 
    if (!state.audioCtx || !state.audioDestination) {
      const AudioContext = window.AudioContext || window.webkitAudioContext
      const audioCtx = AudioContext && new AudioContext()
      const audioDestination = audioCtx && audioCtx.createMediaStreamDestination()

      Vue.set(state, 'audioCtx', audioCtx)
      Vue.set(state, 'audioDestination', audioDestination)
    }
  },
}
