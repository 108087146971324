<template>
  <Firma v-if="isFirma" ref="stammdaten" />
  <Privat v-else ref="stammdaten" />
</template>

<script>
  import CUSTOMERDATA_TYPES from '@/store/customerData/types'
  import { mapGetters } from 'vuex'
  
  import Firma from '@/components/persoenliche-daten/cards/Firma.vue'
  import Privat from '@/components/persoenliche-daten/cards/Privat.vue'
  
  export default {
    computed: {
      ...mapGetters({
        customerData: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_DATA,
        customerDataEdited: CUSTOMERDATA_TYPES.GETTERS.GET_CUSTOMER_DATA_EDITED,
        customerDataConfig: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_CONFIG,
        personId: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_ID,
        isFirma: CUSTOMERDATA_TYPES.GETTERS.IS_FIRMA,
      }),
      isEditable() {
        console.log(this.isFirma)
        return this.customerData && this.customerData.isEditable
      },
    },
    methods: {
    },
    mounted() {
      
      
    },
    beforeRouteLeave(to, from, next) {
      if(to?.path?.search(/(person\-data|customer\-data)/gi) >= 0 
        && !to?.query?.backAction
        && !this.$refs.stammdaten?.checkData()) {
        return ;
      }
  
      next();
    },
    components: {
      Privat,
      Firma,
    }
  }
  </script>
  
  <style scoped>
  
  </style>