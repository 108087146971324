import store from '@/store';
import MENU_CONFIG_TYPES from '@/store/menuConfig/types';

import { FC_CONFIG_CONTEXT } from '@/configs/fcConfig';
import { normalizeMenu } from '@/components/core/option-menu/option-menu-utils';

const EINSTELLUNGEN_VERMITTLEREBENE_LABEL = 'Einstellungen Vermittlerebene';
const EINSTELLUNGEN_KUNDENEBENE_LABEL = 'Einstellungen Kundenebene';
const EINSTELLUNGEN_KUNDENSICHT_LABEL = 'Einstellungen Kundensicht';

export const EINSTELLUNGEN_MITARBEITEREBENE_LABEL = 'Konfiguration';

export const STEP_KEY_SEPARATOR = ':';
export const SUBSTEP_KEY_SEPARATOR = '@';

export const MAP_CONFIG_CONTEXT_LABEL = {
  [FC_CONFIG_CONTEXT.MAKLER_MAKLER]: EINSTELLUNGEN_VERMITTLEREBENE_LABEL,
  [FC_CONFIG_CONTEXT.MAKLER_KUNDE]: EINSTELLUNGEN_KUNDENEBENE_LABEL,
  [FC_CONFIG_CONTEXT.KUNDE_KUNDE]: EINSTELLUNGEN_KUNDENSICHT_LABEL,
};

export const MAP_CONFIG_CONTEXT_STEP_KEY = {
  [FC_CONFIG_CONTEXT.MAKLER_MAKLER]: 'vermittlerebene',
  [FC_CONFIG_CONTEXT.MAKLER_KUNDE]: 'kundenebene',
  [FC_CONFIG_CONTEXT.KUNDE_KUNDE]: 'kundensicht',
};

export const MAP_STEP_KEY_CONFIG_CONTEXT = Object.keys(MAP_CONFIG_CONTEXT_STEP_KEY).reduce((acc, configContext) => ({
  ...acc,
  [MAP_CONFIG_CONTEXT_STEP_KEY[configContext]]: configContext,
}), {});

const filterNonTabMenu = menu => menu.tab !== true;

const mapNonTabMenu = (menu) => {
  const subMenu = [ ...menu?.subMenu || [], ]
    .filter(filterNonTabMenu)
    .map(mapNonTabMenu);

  return {
    ...menu,
    hasSubMenu: menu.group || subMenu?.length > 0, // TODO check this code, it is also done on the menu/index.js when creating the menus
    subMenu,
  };
}

export const findNonTabMenu = flatMenu => {
  return [ ...flatMenu || [], ]
      .filter(filterNonTabMenu) // filter non tab menus
      .map(mapNonTabMenu)
}

export function findMenuConfigStep(configContext, baseStepKey, label, index) {
  const parentsMenuConfigFn = store.getters[MENU_CONFIG_TYPES.GETTERS.PARENTS_MENU_CONFIG];

  const stepKey = `${baseStepKey}${STEP_KEY_SEPARATOR}${MAP_CONFIG_CONTEXT_STEP_KEY?.[configContext]}`; // subroute:configContext-stepKey = stepKey

  return {
    stepKey,
    label,
    totalProgress: 1,
    index,
    substeps: [
      ...(parentsMenuConfigFn(configContext)?.map((parentMenu) => ({
        substepKey: `${stepKey}${SUBSTEP_KEY_SEPARATOR}${parentMenu.path}`,
        title: [ ...parentMenu.parentsLabel || [], parentMenu.label, ]?.join(' > '),
      })) || []),
    ],
  };
}

export const ApplyConfigStatus = Object.freeze({
  NO_CONFLICT: 'kein Konflikt',
  HAS_CONFLICT: 'mit Konflikt',
  NO_CONFIG: 'keine Konfiguration',
});

const DiffStatus = Object.freeze({
  ADDED: 'added',
  REMOVED: 'removed',
  CHANGED: 'changed',
  NONE: 'none',
});

const DiffCheckDir = Object.freeze({
  SOURCE_DEST: 'source-dest',
  DEST_SOURCE: 'dest-source',
});

export class OptionsMenuDiffHelper {

  static isIdentical(sourceConfig, destConfig) {
    if(sourceConfig.length !== destConfig.length) {
      return false;
    }
  
    return sourceConfig
      .every((menu, index) => menu.path === destConfig[index].path && (!!menu.removed) === (!!destConfig[index].removed));
  }

  static _cloneList(list) {
    return JSON.parse(JSON.stringify([ ...list || [] ]));
  }

  static findDiff(sourceConfig, destConfig) {
    const source = this._cloneList(sourceConfig);
    const dest = this._cloneList(destConfig);

    const isBothRemoved = (item1, item2) => item1.removed === true && item1.removed === item2.removed;
    const isBothNotRemoved = (item1, item2) => item1.removed !== true && (!!item1.removed) === (!!item2.removed);

    const setItemsStatus = (sourceList, destList, direction) => {
      if (!direction) return;

      sourceList.forEach((sourceItem, sourceIndex) => {
        const destIndex = destList.findIndex(destItem => destItem.path === sourceItem.path);
        const destItem = destList?.[destIndex] || {};

        if (destIndex < 0) {
          sourceItem.status = direction == DiffCheckDir.DEST_SOURCE || sourceItem.removed === true ? DiffStatus.REMOVED : DiffStatus.ADDED;
        } else if (destIndex === sourceIndex && isBothNotRemoved(sourceItem, destItem) || isBothRemoved(sourceItem, destItem)) {
          sourceItem.status = DiffStatus.NONE;
        } else {
          sourceItem.status = DiffStatus.CHANGED;
        }
      });
    };

    setItemsStatus(source, dest, DiffCheckDir.SOURCE_DEST);
    setItemsStatus(dest, source, DiffCheckDir.DEST_SOURCE);

    return {
      source: source.filter(menu => menu.removed !== true),
      sourceToSelect: source.filter(menu => menu.removed === true),

      dest: dest.filter(menu => menu.removed !== true),
      destToSelect: dest.filter(menu => menu.removed === true),
    };
  }

  static hasConflict(configContext, configId, userLevel, destRawConfig) {
    if (!configContext || !configId || !userLevel || !destRawConfig) {
      return false;
    }

    const appNavigation = store.getters[MENU_CONFIG_TYPES.GETTERS.APP_NAVIGATION_CONFIG_BY_PATH](configContext, configId);

    const sourceRawConfig = store.getters[MENU_CONFIG_TYPES.GETTERS._CURRENT_OPTIONS_MENU_CONFIG](configContext, userLevel, configId);
    const sourceConfig = normalizeMenu(appNavigation.optionMenu, appNavigation.currentOptionMenu, sourceRawConfig);

    const destConfig = normalizeMenu(appNavigation.optionMenu, appNavigation.currentOptionMenu, destRawConfig);

    return !this.isIdentical(sourceConfig, destConfig);
  }

}
