import BALANCE_SHEET_TYPES from './types';
import axios from 'axios';
import CORE_TYPES from '@/store/core/types'
import { BROKER_STRUCTURE_ROLES } from '@/router/roles'
import LOG_TYPES from '@/store/log/types';
import {buildMessage} from '@/helpers/log-message-helper';

function showError(error, dispatch) {
  if (error && (error.status && error.status != 403 || error.response && error.response.status && error.response.status != 403)) {
    dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Fehler aufgetreten', 'danger'));
  }
}

export default {
  [BALANCE_SHEET_TYPES.ACTIONS.GET_OTHER_ASSETS]({ commit, state, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true,
      disableDefaultLog: true,
      params: {
        depotid: `${payload.id}`
      }
    };
    let url = 'mrsotherassets';
    axios.get(rootState.core.apiAddress + '/../' + url, config).then(response => {
      if (response && response.data) {
        commit(BALANCE_SHEET_TYPES.MUTATIONS.GET_OTHER_ASSETS_SUCCESS, 
          { id: payload.id, bankAccount: response.data, updateTime: Date.now() });
      } else {
        commit(BALANCE_SHEET_TYPES.MUTATIONS.GET_OTHER_ASSETS_SUCCESS, 
          { id: payload.id, bankAccount: {error: 403}, updateTime: Date.now() });
      }
    }).catch(error => {
      showError(error, dispatch)
      commit(BALANCE_SHEET_TYPES.MUTATIONS.GET_OTHER_ASSETS_SUCCESS, 
        { id: payload.id, bankAccount: {error: 403}, updateTime: Date.now() });
    })
  },
  [BALANCE_SHEET_TYPES.ACTIONS.GET_CREDIT]({ commit, state, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true,
      disableDefaultLog: true,
      params: {
        depotid: `${payload.id}`
      }
    };
    let url = 'mrscredit';
    axios.get(rootState.core.apiAddress + '/../' + url, config).then(response => {
      if (response && response.data) {
        commit(BALANCE_SHEET_TYPES.MUTATIONS.GET_CREDIT_SUCCESS, response.data);
      }
    }).catch(error => {
      showError(error, dispatch)
      commit(BALANCE_SHEET_TYPES.MUTATIONS.GET_CREDIT_SUCCESS, {error: 403});
    })
  },
  [BALANCE_SHEET_TYPES.ACTIONS.GET_BANK_ACCOUNT]({ commit, state, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true,
      disableDefaultLog: true,
      params: {
        depotid: `${payload.id}`
      }
    };
    let url = 'mrsbankaccount';
    axios.get(rootState.core.apiAddress + '/../' + url, config).then(response => {
      if (response && response.data) {
        commit(BALANCE_SHEET_TYPES.MUTATIONS.GET_BANK_ACCOUNT_SUCCESS, {id: payload.id, values: response.data});
      }
    }).catch(error => {
      showError(error, dispatch)
      commit(BALANCE_SHEET_TYPES.MUTATIONS.GET_BANK_ACCOUNT_SUCCESS, {id: payload.id, values: {error: 403}});
    })
  },
  [BALANCE_SHEET_TYPES.ACTIONS.GET_CLOSED_FUNDS]({ commit, state, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true,
      disableDefaultLog: true,
      params: {
        depotid: `${payload.id}`
      }
    };
    let url = 'mrsclosedfunds';
    axios.get(rootState.core.apiAddress + '/../' + url, config).then(response => {
      if (response && response.data) {
        commit(BALANCE_SHEET_TYPES.MUTATIONS.GET_CLOSED_FUNDS_SUCCESS, {id: payload.id, values: response.data});
      }
    }).catch(error => {
      commit(BALANCE_SHEET_TYPES.MUTATIONS.GET_CLOSED_FUNDS_SUCCESS, {id: payload.id, values: {error: 403}});
    })
  },
  [BALANCE_SHEET_TYPES.ACTIONS.GET_DEPOSIT_SUM]({ commit, dispatch, state, rootState }, payload) {
    const config = {
      defaultSpinner: true,
      disableDefaultLog: true,
      params: {
        depotid: `${payload.id}`
      }
    };
    let url = 'mrsdepotpositionsum';
    axios.get(rootState.core.apiAddress + '/../' + url, config).then(response => {
      if (response && response.data) {
        commit(BALANCE_SHEET_TYPES.MUTATIONS.GET_DEPOSIT_SUM_SUCCESS, response.data);
      } else {
        commit(BALANCE_SHEET_TYPES.MUTATIONS.GET_DEPOSIT_SUM_SUCCESS, {error: 403});
      }
    }).catch(error => {
      showError(error, dispatch)
      commit(BALANCE_SHEET_TYPES.MUTATIONS.GET_DEPOSIT_SUM_SUCCESS, {error: 403});
    })
  },
  [BALANCE_SHEET_TYPES.ACTIONS.GET_ACCOUNTS]({ commit, dispatch, state, rootState }, payload) {
    const config = {
      defaultSpinner: true,
      disableDefaultLog: true,
    };
    let url = 'mrsbanktrasactionaccount';
    axios.get(rootState.core.apiAddress + '/../' + url, config).then(response => {
      if (response && response.data) {
        commit(BALANCE_SHEET_TYPES.MUTATIONS.GET_ACCOUNTS_SUCCESS, response.data);
      } else {
        commit(BALANCE_SHEET_TYPES.MUTATIONS.GET_ACCOUNTS_SUCCESS, {error: 403});
      }
    }).catch(error => {
      showError(error, dispatch)
      commit(BALANCE_SHEET_TYPES.MUTATIONS.GET_ACCOUNTS_SUCCESS, {error: 403});
    })
  },
  [BALANCE_SHEET_TYPES.ACTIONS.GET_ACCOUNTS_DETAILS]({ commit, dispatch,  state, rootState }, payload) {
    const config = {
      defaultSpinner: true,
      disableDefaultLog: true,
    };
    let url = 'mrsbanktrasactiondepotextload';
    axios.get(rootState.core.apiAddress + '/../' + url, config).then(response => {
      if (response && response.data) {
        commit(BALANCE_SHEET_TYPES.MUTATIONS.GET_ACCOUNTS_DETAILS_SUCCESS, response.data);
      } else {
        commit(BALANCE_SHEET_TYPES.MUTATIONS.GET_ACCOUNTS_DETAILS_SUCCESS, {error: 403});
      }
    }).catch(error => {
      showError(error, dispatch)
      commit(BALANCE_SHEET_TYPES.MUTATIONS.GET_ACCOUNTS_DETAILS_SUCCESS, {error: 403});
    })
  },
  [BALANCE_SHEET_TYPES.ACTIONS.GET_INSURANCE_TABLE]({ commit, dispatch,  state, getters, rootState }, payload) {
    const config = {
      defaultSpinner: true,
      disableDefaultLog: true,
      params: {
        ...(payload.id ? {
          depotid: `${payload.id}`
        } : {
          depotid: 'ALLE_DEPOTS_ANZEIGEN'
        })
      }
    };
    const hasRoles = getters[CORE_TYPES.GETTERS.HAS_ROLES]
    if (hasRoles(BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN)) {
      return new Promise((resolve, reject) => {
        let url = 'mrsinsurance';
        axios.get(rootState.core.apiAddress + '/../' + url, config).then(response => {
          if (!response?.data) {
            commit(BALANCE_SHEET_TYPES.MUTATIONS.GET_INSURANCE_TABLE_SUCCESS, {error: 403});
          }

          const responseData = {
            id: '1',
            data: response.data.outputTable,
            visibleCategories: response.data.visibleCategories,
            error: response.data.error,
          };
          commit(BALANCE_SHEET_TYPES.MUTATIONS.GET_INSURANCE_TABLE_SUCCESS, responseData);
          resolve(responseData);
          
        }).catch(error => {
          showError(error, dispatch)
          commit(BALANCE_SHEET_TYPES.MUTATIONS.GET_INSURANCE_TABLE_SUCCESS, {error: 403});
          reject(error);
        })
      });
    }
  },
  [BALANCE_SHEET_TYPES.ACTIONS.GET_DEPOTPOSITIONS]({ commit, dispatch, state, rootState }, payload) {

    let dateFrom = '';
    let dateTo = '';

    if (params && params.dateFrom) {
      dateFrom = '&fromDate=' + dayjs(params.dateFrom).format('DD.MM.YYYY');
    }
    if (params && params.dateTo) {
      dateTo = '&toDate=' + dayjs(params.dateTo).format('DD.MM.YYYY');
    }

    const config = {
      defaultSpinner: true,
      disableDefaultLog: true,
    };
    let url = `mrsbanktrasactiondepot?${dateFrom}${dateTo}`;
    axios.get(rootState.core.apiAddress + '/../' + url, config).then(response => {
      if (response && response.data) {
        commit(BALANCE_SHEET_TYPES.MUTATIONS.GET_DEPOTPOSITIONS_SUCCESS, {
          data: response.data,
          // filterToApplyAfterSuccess: {...data.filterState, ...data.payload}
        });
      } else {
        commit(BALANCE_SHEET_TYPES.MUTATIONS.GET_DEPOTPOSITIONS_SUCCESS, {error: 403});
      }
    }).catch(error => {
      showError(error, dispatch)
      commit(BALANCE_SHEET_TYPES.MUTATIONS.GET_DEPOTPOSITIONS_SUCCESS, {error: 403});
    })
  },
  [BALANCE_SHEET_TYPES.ACTIONS.GET_TRANSACTIONS]({ commit, dispatch,  state, rootState }, payload) {
    const config = {
      defaultSpinner: true,
      disableDefaultLog: true,
      cancelableRequest: {
        title: 'Transaktionen',
        message: 'Bitte grenzen Sie den Suchfilter ein!'
      },
    };
    let url = 'mrstransactionslist';
    return axios.post(rootState.core.apiAddress + '/../' + url, payload, config).then(response => {
      if (response && response.data) {
        if (typeof response.data.askPIN == 'number') {
          commit(CORE_TYPES.MUTATIONS.CHECK_PIN, {
            key: 'TABELLE_TRANSAKTIONEN', 
            value: response.data.askPIN,
            action: BALANCE_SHEET_TYPES.ACTIONS.GET_TRANSACTIONS,
            payload: JSON.stringify(payload)
          })
        }
        commit(BALANCE_SHEET_TYPES.MUTATIONS.GET_TRANSACTIONS_SUCCESS, response.data);
      } else {
        commit(BALANCE_SHEET_TYPES.MUTATIONS.GET_TRANSACTIONS_SUCCESS, {error: 403});
      }
    }).catch(error => {
      showError(error, dispatch)
      commit(BALANCE_SHEET_TYPES.MUTATIONS.GET_TRANSACTIONS_SUCCESS, {error: 403});
    })
  },
  async [BALANCE_SHEET_TYPES.ACTIONS.GET_SPAR_ENTNAHMEPLANE]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true,
      disableDefaultLog: true,
    };
    let url = 'mrsportfoliosparplanpositions?newOutputFormat=true';
    if (payload.depotid) {
      url += '&depotid=' + payload.depotid;
    }
    await axios.get(rootState.core.apiAddress + '/../' + url, config).then(response => {
      if (response && response.data) {
        commit(BALANCE_SHEET_TYPES.MUTATIONS.GET_SPAR_ENTNAHMEPLANE_SUCCESS, response.data);
      } else {
        commit(BALANCE_SHEET_TYPES.MUTATIONS.GET_SPAR_ENTNAHMEPLANE_SUCCESS, {error: 403});
      }
    }).catch(error => {
      showError(error, dispatch)
      commit(BALANCE_SHEET_TYPES.MUTATIONS.GET_SPAR_ENTNAHMEPLANE_SUCCESS, {error: 403});
    })
  },  
  [BALANCE_SHEET_TYPES.ACTIONS.GET_VERMOGENSUBERSICHT_PDF]({ commit, dispatch,  state, rootState }, payload) {
    const config = {
      defaultSpinner: true,
      disableDefaultLog: true,
      responseType: 'arraybuffer'
    };
    let url = 'mrsdepotpdf';

    return new Promise((resolve, reject) => {
      
      axios.post(rootState.core.apiAddress + '/../' + url, payload, config).then(response => {
        if (response && response.data) {
          commit(BALANCE_SHEET_TYPES.MUTATIONS.GET_VERMOGENSUBERSICHT_PDF_SUCCESS, response);
        } 
        resolve(response);
      })


    })  

    // axios.post(rootState.core.apiAddress + '/../' + url, payload, config).then(response => {
    //   if (response && response.data) {
    //     commit(BALANCE_SHEET_TYPES.MUTATIONS.GET_VERMOGENSUBERSICHT_PDF_SUCCESS, response);
    //   }
    // }).catch(error => {
    //   commit(BALANCE_SHEET_TYPES.MUTATIONS.GET_FAILTURE);
    // })
  },  
}
