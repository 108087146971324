<template>
  <div>
    <BoxContainer :title="selectedTabLabel">
      <Table
        v-if="rows && rows.length"
        :rows="rows"
        :headers="headers"
        :paginationEnabled="false"
        :rowsPerPage="20"
        :mobileConfig="{title: 'Erbschaftssteuerliche Werte', headers: ['jahr', 'soll', 'ist']}"
      ></Table>      
      <div v-if="!tableData.records || !tableData.records.length">Keine Daten</div>
    </BoxContainer>
  </div>
</template>

<script>
import FONDSINFO_TYPES from "@/store/fondsinfo/types";
import BoxContainer from '@/components/core/BoxContainer.vue';
import { mapGetters } from 'vuex';
import Table from "@/components/table2/Table.vue";
import {TextColumn, PercentageColumn} from "@/components/table2/table_util.js";

export default {
  components: {
    BoxContainer,
    Table,
  },
  name: "TabErbschaftssteuerlicheWerte",
  props:{
    selectedTabLabel: {
      type: String
    }
  },
  computed: {
    ...mapGetters({erbschaftssteuerlicheWerte : FONDSINFO_TYPES.GETTERS.FONDSINFO_ERBSCHAFTSSTEUERLICHE_WERTE}),
    tableData() {
      if(this.erbschaftssteuerlicheWerte && this.erbschaftssteuerlicheWerte.tableHeaders) {

        const headers = this.erbschaftssteuerlicheWerte.tableHeaders
        .reduce((acc, curr) => ({...acc, ...({[curr.key] : curr})}), {});

        const headersKeys = Object.keys(headers);
        const records = this.erbschaftssteuerlicheWerte.records.map(tuple => {
          return tuple.reduce((acc, curr, index) => ({...acc, ...({[headersKeys[index]] : curr})}), {});
        });
        return {headers, records};
      }
      return [];
    },
    rows() {
      const transformedData = this.erbschaftssteuerlicheWerte?.records?.map(record => ({
          jahr: record[0],
          soll: parseFloat(record[1]),
          ist: parseFloat(record[2])
      }));

      return transformedData;

    },
    headers() {
        const result =    {
          center: [
                TextColumn("jahr", "Jahr"),
                PercentageColumn("soll", "Soll"),
                PercentageColumn("ist", "Ist"),
            ]
          };

          return result;
    }
        
  },
  mounted() {
    if(!this.erbschaftssteuerlicheWerte) {
      this.$store.dispatch(FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_ERBSCHAFTSSTEUERLICHE_WERTE, 
      {
        isin: this.$route.params?.isin,
        bgsNr: this.$route.params?.bgsNr,
      });
    }
  },

}
</script>

<style>

</style>