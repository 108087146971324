<template>
  <div>
    <Table
      v-if="!loading && rows.length"
      tableId="9ec3921e-1cfd-4027-843a-808f524ffi98"
      :title="title"
      :headers="headers"
      :rows="rows"
      :hidePagination="hasLimit"
      :mobileConfig="mobileConfig"
      @action-DOWNLOAD_EXCEL="handleExcel"
      @action-DOWNLOAD_PDF="handlePDF"
    >
      <template #text="{ text, gelesen }">
        <div>
          <b v-if="!gelesen">{{text}}</b>
          <span v-else>{{text}}</span>
        </div>
      </template>
    </Table>
    <GhostLoading v-else-if="loading" type="table" :title="title" :config="{ table: { rows: limit || 3, }, }" />
    <NoData v-else :noIcon="hasLimit" :title="title" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TRANSAKTION_VERLAUF_TYPES from '@/store/transaktionVerlauf/types';

import Table from "@/components/table2/Table.vue";
import {SlotColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';

export default {
  props: {
    title: {
      type: String,
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
    limit: {
      type: Number,
      default: null,
      validator: (value) => {
        return value > 0;
      },
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      transactions: TRANSAKTION_VERLAUF_TYPES.GETTERS.TRANSACTIONS,
    }),
    hasLimit() {
      return this.limit > 0;
    },
    headers() {
      return {
        lockedLeft: [
            SlotColumn("text", "Beschreibung"),
        ],
        center: [
        ],
        lockedRight: [
            ActionColumn("actions", "Aktionen"),
        ],
      };
    },
    rows() {
      const actions = [
          SimpleAction("DOWNLOAD_PDF", null, "PDF"),
          SimpleAction("DOWNLOAD_EXCEL", null, "Excel"),
      ];
      
      const transactions = this.hasLimit ? this.transactions.slice(0, this.limit) : [...this.transactions];
      return transactions.map(t => ({
        ...t,
        text: `${t.anzahl} Transaktionen vom ${t.dateCreated}`,
        actions,
      }));
    },
    mobileConfig() {
      return {
          title: '',
          headers: ['text' ],
      }
    }
  },
  watch: {
    filters() {
      this.findTransaktionen();
    },
    loading() {
      this.$emit('loading', this.loading);
    },
  },
  methods: {
    async findTransaktionen() {
      try {
        this.loading = true;
        await this.$store.dispatch(TRANSAKTION_VERLAUF_TYPES.ACTIONS.FIND_TRANSACTIONS, this.filters || {});
      } finally {
        this.loading = false;
      }
    },
    handlePDF(item) {
      this.$store.dispatch(TRANSAKTION_VERLAUF_TYPES.ACTIONS.DOWNLOAD_FILE, {
        groupId: item.id,
        type: 'pdf',
      });
    },
    handleExcel(item) {
      this.$store.dispatch(TRANSAKTION_VERLAUF_TYPES.ACTIONS.DOWNLOAD_FILE, {
        groupId: item.id,
        type: 'excel',
      });
    },
  },
  mounted() {
    this.findTransaktionen();
  },
  components: {
    Table,
    GhostLoading,
    NoData,
  },
}
</script>
