export const MUTATION_PREFIX = 'FONDSFAVORITEN_MUTATIONS_';
export const ACTIONS_PREFIX = 'FONDSFAVORITEN_ACTIONS_';
export const GETTERS_PREFIX = 'FONDSFAVORITEN_GETTERS_';

export default {
  MUTATIONS: {
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    LOAD_FAVORITENLISTS_SUCCESS: MUTATION_PREFIX + 'LOAD_FAVORITENLISTS_SUCCESS',
    LOAD_FAVORITENLISTS_OPTIONS_SUCCESS: MUTATION_PREFIX + 'LOAD_FAVORITENLISTS_OPTIONS_SUCCESS',
  },
  ACTIONS: {
    LOAD_FAVORITENLISTS: ACTIONS_PREFIX + 'LOAD_FAVORITENLISTS',
    ADD_FONDS: ACTIONS_PREFIX + 'ADD_FONDS',
  },
  GETTERS: {
    FAVORITENLISTS: GETTERS_PREFIX + 'FAVORITENLISTS',
    OPTIONS: GETTERS_PREFIX + 'OPTIONS',
  }
}