var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "portfolio-selector-button__container" },
    [
      _c(
        "BaseButton",
        {
          on: {
            click: function($event) {
              return _vm.openSelectorModal()
            }
          }
        },
        [
          _vm._t("default", function() {
            return [_vm._v("Depot auswählen")]
          })
        ],
        2
      ),
      _c("BaseModal", {
        ref: "modal",
        attrs: {
          modalTitle: "Depot auswählen",
          showDefaultButtons: true,
          showConfirmButton: false
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "ul",
                  {
                    staticClass:
                      "portfolio-selector-button--list list list-bordered"
                  },
                  _vm._l(_vm.values, function(item) {
                    return _c(
                      "li",
                      {
                        key: item.value,
                        staticClass: "list-bordered-item clickable",
                        class: { "text-bold": _vm.isSelected(item.value) },
                        on: {
                          click: function($event) {
                            _vm.closeSelectorModal()
                            _vm.setSelected(item.value)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("span", { staticClass: "col" }, [
                            _vm._v(_vm._s(item.label))
                          ]),
                          _c(
                            "span",
                            { staticClass: "col-auto" },
                            [
                              _c("CurrencyLabel", {
                                attrs: { value: item.depotGesamt }
                              })
                            ],
                            1
                          )
                        ])
                      ]
                    )
                  }),
                  0
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }