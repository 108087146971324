<template>
  <div class="label reslauszahlungen">
    <div class="result">{{getAuszahlungen}} €</div>
    <div class="truelabel">Auszahlungen</div>
  </div>
</template>

<script>
//import store from "./../../store.js";
export default {
  name: "ResLAuszahlungen",
  computed: {
    getAuszahlungen() {
      return this.$store.getters.getAuszahlungen;
    }
  },
};
</script>

<style scoped>
.reslauszahlungen {
  grid-area: reslauszahlungen;
}

</style>