<template>
  <div>
    <InsuranceOptionMenu title="Buchungsdaten" />

    <div class="box__container">
      <p>Hier können Sie den Vertrag zu einem anderen Kunden verschieben. Bitte beachten Sie, dass bei dem neuen Kunden automatisch alle dafür benötigten Personen (Vertragspersonen, versicherte Personen) angelegt werden.</p>

      <div class="row">
        <div class="col-12 col-sm-6">
          <p>
            Vertrag: {{vertragsverschiebungenData.beschreibung}}
          </p>        
        </div>
        <div class="col-12 col-sm-6">
          <p>Bisheriger Kunde: {{userid}} - {{fullname}}</p>        

        </div>
        <div class="col-12 col-sm-6 form-control">
          <InputField
            class="align-end"
            v-model="form.kundennr"
            label="Neuer Kunde"
            @input="searchCustomer"
          />
          <a class="align-end" style="flex-grow: 0;" @click="handleAddUsers">
            <PhBookOpen :size="24"/>
          </a>        
        </div>   
        <div class="col-12 col-sm-6">
          <p>Neuer Kunde Name:</p>     
          <p>{{form.kundenname}}</p>   

        </div>      
      </div>

      <BaseButton :disabled="!form.kundennr || form.kundennr.length < 7" @click="vertragVerschieben()">Vertrag verschieben</BaseButton>
      <BaseButton isSecondary @click="goBack()">Abbrechen</BaseButton>


    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import InputField from "@/components/core/forms/InputField.vue";
import BaseButton from "@/components/core/BaseButton.vue";
import InsuranceDetailMixin from '@/components/versicherungen/InsuranceDetail-mixin.js';
import VERSICHERUNG_TYPES from '@/store/versicherungen/types';
import CORE_TYPES from '@/store/core/types'
import { PhBookOpen } from 'phosphor-vue';
import CUSTOMER_SEARCH_TYPES from '@/store/customerSearch/types';
import InsuranceOptionMenu from '@/views/versicherungen/insurance-group/InsuranceOptionMenu.vue';

export default {
  mixins: [InsuranceDetailMixin,],
  components: {
    InsuranceOptionMenu,
    InputField,
    BaseButton,
    PhBookOpen,
  },
  data() {
    return {
      form: {
        kundenname: null,
        kundennr: null,
        availablePersons: [],
      }
    }
  },
  computed: {
    ...mapGetters({
      vertragsverschiebungenData: VERSICHERUNG_TYPES.GETTERS.VERTRAGS_VERSCHIEBUNGEN_DATA,
      userid: CORE_TYPES.GETTERS.GET_USER_ID,
      fullname: CORE_TYPES.GETTERS.GET_USER_FULL_NAME,
      unternr: CORE_TYPES.GETTERS.GET_USER_UNTER_NR,
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
      selectedCustomersExternal: CUSTOMER_SEARCH_TYPES.GETTERS.SET_SELECTED_CUSTOMERS,
    }),
  },
  methods: {
    handleAddUsers() {
      this.navigateTo('/communication/mailcomposer-customer-search/allgemeineVertragsdaten?back=' + this.getCurrentRoute())
    },
    navigateTo(event) {
      this.$router.push({
        path: event?.path ? event.path : event,
        query: {
          singleUser: this.$route.query?.singleUser,
          ...(event?.query ? event.query : {})
        },
      });
    },
    getCurrentRoute() {
      return this.$router.currentRoute.path;
    }, 
    updateNeuerKunde() {
      if (this.selectedCustomersExternal?.length) {
        const selectedCustomerId = this.selectedCustomersExternal[0]?.customerID || null;
        const selectedCustomerName = this.selectedCustomersExternal[0]?.name || null;

        this.form.kundennr = selectedCustomerId;
        this.form.kundenname = selectedCustomerName;
        this.$store.commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.SET_SELECTED_CUSTOMERS, []);
      }
    },
    goBack() {
      this.$router.push({ path: '/home/versicherungen/insurance-group' });
    },
    async vertragVerschieben() {
      try {
          let payload = {
            vertragId: this.$route.params.id,
            newCustomer: this.form.kundennr,
          }

          await this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.KUNDENNR_AENDERN, payload);
        } finally {
          this.$router.push({ path: '/home/versicherungen/insurance-group' });

        }
    },
    searchCustomer() {
      if (this.form.kundennr.length >= 7) {
        let payload = {
            kundennr: this.form.kundennr,
          }

          this.form.kundenname = '';

          this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.SEARCH_CUSTOMER, payload).then(response => {
            console.log(response);
            this.form.kundenname = response?.name;
          });

      }
   
 
    },

  },
  mounted() {
    if (this.selectedCustomersExternal && Array.isArray(this.selectedCustomersExternal) && this.selectedCustomersExternal.length) {
      this.updateNeuerKunde();
    }
  },
  beforeRouteLeave(to, from, next) {
    next();
  }

}
</script>
<style scoped>
.align-end {
  flex: 1 1 auto;
  align-self: flex-end;
  padding: 0px 0px 16px 5px;
  color: var(--color-text);
}
.form-control {
  display: flex;
  align-content: stretch;
  align-items: center;
}
</style>