var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isVermittlung
        ? _c("div", [
            _c("div", [
              _vm._v(
                " Ihr Vermittler ist auf Grund gesetzlicher Bestimmungen verpflichtet Ihre Kenntnissen und Erfahrungen mit Kapitalanlagen zu erfragen. Möchten Sie zu oben genannten Punkten ganz oder teilweise keine Angaben machen, kann eine Angemessenheitsprüfung nicht stattfinden. "
              )
            ]),
            _c("div", [
              _vm._v(
                " Sie bestätigen hiermit, dass der Vermittler Ihnen keine Anlage empfohlen hat bzw. empfiehlt und Sie sich für die Anlage selbständig entschieden haben bzw. entscheiden und diese auch tätigen wollen. Sie beauftragen den Vermittler lediglich mit der Durchführung der Kaufaufträge. "
              )
            ])
          ])
        : _c("div", [
            _c("div", [
              _vm._v(
                " Ihr Berater ist auf Grund gesetzlicher Bestimmungen verpflichtet Ihre Kenntnissen und Erfahrungen mit Kapitalanlagen, Ihre finanziellen Verhältnisse, Ihre Anlageziele/Anlagedauer/Zweck der Anlage/Zahlungsweise, Ihre Risikoneigung und Ihre Risikobereitschaft zu erfragen. Möchten Sie zu oben genannten Punkten ganz oder teilweise keine Angaben machen, kann eine Geeignetheitsprüfung und eine Beratung nicht stattfinden. "
              )
            ]),
            _c("div", { staticClass: "mt-2 mb-2" }, [
              _vm._v(
                " Sie bestätigen hiermit, dass der Berater Ihnen keine Anlage empfohlen hat bzw. empfiehlt und Sie sich für die Anlage selbständig entschieden haben bzw. entscheiden und diese auch tätigen wollen. Die von Ihnen gewählte Anlage entspricht im Hinblick auf die zeitliche Abstimmung und der Risikoklasse Ihren Anforderungen an Verfügbarkeit, Rendite und Sicherheit. Sie beauftragen den Berater lediglich mit der Durchführung der Kaufaufträge. "
              )
            ])
          ]),
      _c("InputRadioBoxGroup", {
        attrs: {
          title:
            "Der Kunde beauftragt den " +
            _vm.maklerTyp +
            " nur mit der Durchführung der Kaufaufträge",
          values: [
            { value: "ja", label: "ja" },
            { value: "nein", label: "nein" }
          ],
          inlineLabelValues: false,
          disabled: _vm.isDisabled,
          validateUntouched: ""
        },
        on: {
          input: function($event) {
            return _vm.updateStore("warnhinweise", "frage1", $event)
          }
        },
        model: {
          value: _vm.frage1,
          callback: function($$v) {
            _vm.frage1 = $$v
          },
          expression: "frage1"
        }
      }),
      _c("InputRadioBoxGroup", {
        attrs: {
          title:
            "Hat Sie der " +
            _vm.maklerTyp +
            " dazu veranlasst, Angaben zu verweigern?",
          values: [
            { value: "ja", label: "ja" },
            { value: "nein", label: "nein" }
          ],
          inlineLabelValues: false,
          disabled: _vm.isDisabled,
          validateUntouched: ""
        },
        on: {
          input: function($event) {
            return _vm.updateStore("warnhinweise", "frage2", $event)
          }
        },
        model: {
          value: _vm.frage2,
          callback: function($$v) {
            _vm.frage2 = $$v
          },
          expression: "frage2"
        }
      }),
      _vm.typ === "2"
        ? _c(
            "div",
            [
              _c("div", { staticClass: "font-bold mb-2" }, [
                _vm._v(" Angemessenheitsprüfung ")
              ]),
              _c("div", { staticClass: "mb-2" }, [
                _vm._v(
                  " Es wurde ausdrücklich nach ihren produktspezifischen Kenntnissen und Erfahrungen gefragt. Da Sie keine oder nur unzureichende Angaben zu Ihren Kenntnissen und Erfahrungen gemacht haben, kann der Berater nicht beurteilen, ob das gewählte Produkt für Sie angemessen ist. "
                )
              ]),
              _c("InputRadioBoxGroup", {
                attrs: {
                  title:
                    "Möchten Sie dem Finanzdienstleister einen Auftrag erteilen?",
                  values: [
                    { value: "ja", label: "ja" },
                    { value: "nein", label: "nein" }
                  ],
                  inlineLabelValues: false,
                  disabled: _vm.isDisabled,
                  validateUntouched: ""
                },
                on: {
                  input: function($event) {
                    return _vm.updateStore("warnhinweise", "frage3", $event)
                  }
                },
                model: {
                  value: _vm.frage3,
                  callback: function($$v) {
                    _vm.frage3 = $$v
                  },
                  expression: "frage3"
                }
              }),
              _c("InputRadioBoxGroup", {
                attrs: {
                  title:
                    "Hat Sie der " +
                    _vm.maklerTyp +
                    " dazu veranlasst, Angaben zu verweigern?",
                  values: [
                    { value: "ja", label: "ja" },
                    { value: "nein", label: "nein" }
                  ],
                  inlineLabelValues: false,
                  disabled: _vm.isDisabled,
                  validateUntouched: ""
                },
                on: {
                  input: function($event) {
                    return _vm.updateStore("warnhinweise", "frage4", $event)
                  }
                },
                model: {
                  value: _vm.frage4,
                  callback: function($$v) {
                    _vm.frage4 = $$v
                  },
                  expression: "frage4"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }