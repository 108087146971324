import GDV_TYPES from './types';

export default {
    [GDV_TYPES.GETTERS.SAVED_FILTERS](state) {
        return state.savedFilters;
    },
    [GDV_TYPES.GETTERS.SAVED_SORTING](state) {
        return state.savedSorting;
    },
    [GDV_TYPES.GETTERS.CACHED_ROWS](state) {
        return state.cachedRows;
    },
    [GDV_TYPES.GETTERS.LAST_ROW](state) {
        return state.lastRow;
    },
    [GDV_TYPES.GETTERS.CURRENT_PAGE_SIZE](state) {
        return state.currentPageSize;
    },
}