var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("div", { staticClass: "text-bold" }, [_vm._v("Basispunkte")]),
        _c("InputField", {
          attrs: {
            isComponentHalfSize: "",
            type: "number",
            validateUntouched: "",
            precision: 0,
            label: "damit Basispunkte gesamt",
            disabled: true
          },
          on: {
            change: function($event) {
              _vm.updateStore(
                { basispunkteGesamt: _vm.formatNumber($event) },
                "settings"
              )
            }
          },
          model: {
            value: _vm.form.basispunkteGesamt,
            callback: function($$v) {
              _vm.$set(_vm.form, "basispunkteGesamt", $$v)
            },
            expression: "form.basispunkteGesamt"
          }
        }),
        _c("InputField", {
          attrs: {
            isComponentHalfSize: "",
            id: "form.basispunkteWK",
            type: "number",
            validateUntouched: "",
            precision: 0,
            label: "Basispunkte WealthKonzept",
            disabled: !_vm.isIntern || _vm.isFa
          },
          on: {
            change: function($event) {
              _vm.updateStore(
                { basispunkteWK: _vm.formatNumber($event) },
                "settings"
              )
            }
          },
          model: {
            value: _vm.form.basispunkteWK,
            callback: function($$v) {
              _vm.$set(_vm.form, "basispunkteWK", $$v)
            },
            expression: "form.basispunkteWK"
          }
        }),
        _c("InputField", {
          attrs: {
            isComponentHalfSize: "",
            id: "form.basispunkteAdvisor",
            type: "number",
            validateUntouched: "",
            precision: 0,
            label: "Basispunkte Advisor",
            disabled: _vm.isFa
          },
          on: {
            change: function($event) {
              _vm.updateStore(
                { basispunkteAdvisor: _vm.formatNumber($event) },
                "settings"
              )
            }
          },
          model: {
            value: _vm.form.basispunkteAdvisor,
            callback: function($$v) {
              _vm.$set(_vm.form, "basispunkteAdvisor", $$v)
            },
            expression: "form.basispunkteAdvisor"
          }
        }),
        _c("div", { staticClass: "text-bold" }, [_vm._v("Benchmark")]),
        _c("InputField", {
          attrs: {
            isComponentHalfSize: "",
            id: "form.benchmarkName",
            label: "Name",
            validateUntouched: "",
            disabled: true
          },
          on: {
            change: function($event) {
              return _vm.updateStore({ benchmarkName: $event }, "settings")
            }
          },
          model: {
            value: _vm.form.benchmarkName,
            callback: function($$v) {
              _vm.$set(_vm.form, "benchmarkName", $$v)
            },
            expression: "form.benchmarkName"
          }
        }),
        _c("div", { staticClass: "d-flex fc-form-danger" }, [
          !_vm.form.benchmarkDisabled &&
          !_vm.form.benchmarkIndex1 &&
          !_vm.form.benchmarkIndex2 &&
          !_vm.form.benchmarkIndex3
            ? _c("span", [
                _vm._v(" Bitte machen Sie Angaben zu mindestens einem Index. ")
              ])
            : !_vm.form.benchmarkDisabled &&
              (parseInt(_vm.form.benchmarkAnteil1) || 0) +
                (parseInt(_vm.form.benchmarkAnteil2) || 0) +
                (parseInt(_vm.form.benchmarkAnteil3) || 0) !=
                100
            ? _c("span", [
                _vm._v(" Die Verteilung muss sich zu 100% aufsummieren. ")
              ])
            : _vm._e()
        ]),
        _c("div", { staticClass: "d-flex forms__input--half-size" }, [
          _c(
            "div",
            { staticClass: "index_combo" },
            [
              _c("ComboBox", {
                attrs: {
                  id: "form.benchmarkIndex1",
                  values: _vm.valuesBenchmarkIndex,
                  label: "Index 1",
                  validateUntouched: "",
                  disabled: _vm.form.benchmarkDisabled,
                  firstEmpty: true
                },
                on: {
                  change: function($event) {
                    return _vm.updateStore(
                      { benchmarkIndex1: $event },
                      "settings"
                    )
                  }
                },
                model: {
                  value: _vm.form.benchmarkIndex1,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "benchmarkIndex1", $$v)
                  },
                  expression: "form.benchmarkIndex1"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "index_percent" },
            [
              _c("InputField", {
                attrs: {
                  id: "form.benchmarkAnteil1",
                  type: "percent",
                  precision: 0,
                  validateUntouched: "",
                  disabled: _vm.form.benchmarkDisabled
                },
                on: {
                  change: function($event) {
                    return _vm.updateStore(
                      { benchmarkAnteil1: $event },
                      "settings"
                    )
                  }
                },
                model: {
                  value: _vm.form.benchmarkAnteil1,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "benchmarkAnteil1", $$v)
                  },
                  expression: "form.benchmarkAnteil1"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "d-flex forms__input--half-size" }, [
          _c(
            "div",
            { staticClass: "index_combo" },
            [
              _c("ComboBox", {
                attrs: {
                  id: "form.benchmarkIndex2",
                  values: _vm.valuesBenchmarkIndex,
                  label: "Index 2",
                  validateUntouched: "",
                  disabled: _vm.form.benchmarkDisabled,
                  firstEmpty: true
                },
                on: {
                  change: function($event) {
                    return _vm.updateStore(
                      { benchmarkIndex2: $event },
                      "settings"
                    )
                  }
                },
                model: {
                  value: _vm.form.benchmarkIndex2,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "benchmarkIndex2", $$v)
                  },
                  expression: "form.benchmarkIndex2"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "index_percent" },
            [
              _c("InputField", {
                attrs: {
                  id: "form.benchmarkAnteil2",
                  type: "percent",
                  precision: 0,
                  disabled: _vm.form.benchmarkDisabled,
                  validateUntouched: ""
                },
                on: {
                  change: function($event) {
                    return _vm.updateStore(
                      { benchmarkAnteil2: $event },
                      "settings"
                    )
                  }
                },
                model: {
                  value: _vm.form.benchmarkAnteil2,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "benchmarkAnteil2", $$v)
                  },
                  expression: "form.benchmarkAnteil2"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "d-flex forms__input--half-size" }, [
          _c(
            "div",
            { staticClass: "index_combo" },
            [
              _c("ComboBox", {
                attrs: {
                  id: "form.benchmarkIndex3",
                  values: _vm.valuesBenchmarkIndex,
                  label: "Index 3",
                  validateUntouched: "",
                  disabled: _vm.form.benchmarkDisabled,
                  firstEmpty: true
                },
                on: {
                  change: function($event) {
                    return _vm.updateStore(
                      { benchmarkIndex3: $event },
                      "settings"
                    )
                  }
                },
                model: {
                  value: _vm.form.benchmarkIndex3,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "benchmarkIndex3", $$v)
                  },
                  expression: "form.benchmarkIndex3"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "index_percent" },
            [
              _c("InputField", {
                attrs: {
                  id: "form.benchmarkAnteil3",
                  type: "percent",
                  precision: 0,
                  disabled: _vm.form.benchmarkDisabled,
                  validateUntouched: ""
                },
                on: {
                  change: function($event) {
                    return _vm.updateStore(
                      { benchmarkAnteil3: $event },
                      "settings"
                    )
                  }
                },
                model: {
                  value: _vm.form.benchmarkAnteil3,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "benchmarkAnteil3", $$v)
                  },
                  expression: "form.benchmarkAnteil3"
                }
              })
            ],
            1
          )
        ]),
        _c("InputToggleSwitch", {
          attrs: {
            id: "form.fkVisible",
            label: _vm.getLabelVisible,
            inLineLabel: "",
            validateUntouched: ""
          },
          on: {
            input: function($event) {
              return _vm.updateStore({ fkVisible: $event }, "settings")
            }
          },
          model: {
            value: _vm.form.fkVisible,
            callback: function($$v) {
              _vm.$set(_vm.form, "fkVisible", $$v)
            },
            expression: "form.fkVisible"
          }
        }),
        _c("InputToggleSwitch", {
          attrs: {
            id: "form.immerRebalancing",
            label: "Rebalancing auch bei einfachem Fondstausch",
            inLineLabel: "",
            validateUntouched: ""
          },
          on: {
            input: function($event) {
              return _vm.updateStore({ immerRebalancing: $event }, "settings")
            }
          },
          model: {
            value: _vm.form.immerRebalancing,
            callback: function($$v) {
              _vm.$set(_vm.form, "immerRebalancing", $$v)
            },
            expression: "form.immerRebalancing"
          }
        }),
        _c("InputRadioBoxGroup", {
          attrs: {
            title: "Depotanpassung",
            id: "form.rebalancingArt",
            values: _vm.depotAnpassungValues,
            validateUntouched: ""
          },
          on: {
            input: function($event) {
              return _vm.updateStore({ rebalancingArt: $event }, "settings")
            }
          },
          model: {
            value: _vm.form.rebalancingArt,
            callback: function($$v) {
              _vm.$set(_vm.form, "rebalancingArt", $$v)
            },
            expression: "form.rebalancingArt"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }