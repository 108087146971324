<template>
  <div class="box__container">
    <div v-if="isEmpty">Keine zusatzliche Daten erforderlich</div>
    <div v-else>
      <div class="box__title">
        Die hier getroffenen Einstellung gelten nur für Edelmetall-Positionen
        der ebase/ebase Augsburg
      </div>
      <div class="box__title">Kauf</div>
      <InputField
        label="Herkunft der Anlagesumme"
        v-model="form.INPUT_EBASE_EDELMETALL_KAUF_MITTELHERKUNFT"
        type="text"
        :disabled="readOnly"
        @change="
          updateStoreData({
            componentId: 'INPUT_EBASE_EDELMETALL_KAUF_MITTELHERKUNFT',
            value: $event,
          })
        "
      />
      <InputToggleSwitch
        v-model="form.INPUT_EBASE_EDELMETALL_KAUF_BERATUNG"
        label="Dieser Auftrag erfolgt im Rahmen einer Anlageberatung."
        :disabled="readOnly"
        @input="
          updateStoreData({
            componentId: 'INPUT_EBASE_EDELMETALL_KAUF_BERATUNG',
            value: $event,
          })
        "
      />
      <div class="box__title">Verkauf</div>
      <div class="col-12 mt-3">
        <FormLabel
          label="Sofern eine Depot- und Kontokündigung erfolgt bitte das Guthaben vom Konto flex auf folgende abweichende externe Bankverbindung überweisen."
        />
      </div>
      <ComboBox
        v-model="form.INPUT_EBASE_EDELMETALL_AUSZAHLKONTO"
        label="externe Bankverbindung"
        :disabled="readOnly"
        :values="
          comboboxValues
            ? this.comboboxValues.INPUT_EBASE_EDELMETALL_AUSZAHLKONTO
            : []
        "
        @change="
          updateStoreData({
            componentId: 'INPUT_EBASE_EDELMETALL_AUSZAHLKONTO',
            value: $event,
          })
        "
      />
      <InputField
        label="Verwendungszweck"
        v-model="form.INPUT_EBASE_EDELMETALL_AUSZAHLONTO_VERWENDUNGSZWECK"
        :disabled="readOnly"
        type="text"
        @change="
          updateStoreData({
            componentId: 'INPUT_EBASE_EDELMETALL_AUSZAHLONTO_VERWENDUNGSZWECK',
            value: $event,
          })
        "
      />
      <div class="box__title">physiche Auslieferung</div>
      <div class="col-12 mt-3">
        <FormLabel
          label="Die Auslieferung von Stücken soll an folgende, bei ebase hinterlegte Adresse erfolgen:"
        />
      </div>
      <InputRadioBoxGroup
        v-model="form.INPUT_EBASE_EDELMETALL_PHYSAUSL_ADRESSTYP"
        :disabled="readOnly"
        :values="adressTypValues"
        @input="
          updateStoreData({
            componentId: 'INPUT_EBASE_EDELMETALL_PHYSAUSL_ADRESSTYP',
            value: $event,
          })
        "
      />
      <div class="col-12 mt-3">
        <FormLabel label="Kontaktdaten für die Kontaktierung" />
        <InputField
          label="E-Mail-Adresse"
          :disabled="readOnly"
          v-model="form.INPUT_EBASE_EDELMETALL_PHYAUSL_KONTAKTEMAIL"
          type="text"
          @change="
            updateStoreData({
              componentId: 'INPUT_EBASE_EDELMETALL_PHYAUSL_KONTAKTEMAIL',
              value: $event,
            })
          "
        />
        <InputField
          label="Telefon"
          :disabled="readOnly"
          v-model="form.INPUT_EBASE_EDELMETALL_PHYAUSL_KONTAKTTEL"
          type="text"
          @change="
            updateStoreData({
              componentId: 'INPUT_EBASE_EDELMETALL_PHYAUSL_KONTAKTTEL',
              value: $event,
            })
          "
          inputmode="tel"
        />
      </div>
    </div>
  </div>
</template>
<script>
import ComponentList from "@/components/wertpapierorder/ComponentList.vue";
import WERTPAPIERORDER_TYPES from "@/store/wertpapierorder/types";
import mixin from "@/mixins/wertpapierorder/mixin.js";
import { mapGetters } from "vuex";
import FormLabel from "@/components/core/forms/FormLabel.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import InputIbanBic from "@/components/core/forms/InputIBAN_BIC.vue";
import InputField from "@/components/core/forms/InputField.vue";
import InputRadioBoxGroup from "@/components/core/forms/radiobox/InputRadioBoxGroup.vue";

export default {
  mixins: [mixin],
  components: {
    ComponentList,
    FormLabel,
    ComboBox,
    InputIbanBic,
    InputToggleSwitch,
    InputField,
    InputRadioBoxGroup,
  },
  data() {
    return {
      disabled: false,
      form: {},
      adressTypValues: [
        { label: "Wohnsitzadresse", value: "Wohnsitz" },
        { label: "Versandadresse", value: "Versand" },
        { label: "Geschäfts-/Firmenadresse", value: "Firma" },
      ],
    };
  },
  computed: {
    isEmpty() {
      return !(
        this.positions?.length &&
        (this.data?.hatEbaseEdelmetal ||
          this.positions[0]?.extra == "EBASE_EDELMETALL")
      );
    },
    ...mapGetters({
      comboboxValues: WERTPAPIERORDER_TYPES.GETTERS.COMBOBOX_VALUES || {},
    }),
  },
  methods: {
    initForm() {
      this.form = {
        INPUT_EBASE_EDELMETALL_KAUF_MITTELHERKUNFT:
          this.data["INPUT_EBASE_EDELMETALL_KAUF_MITTELHERKUNFT"],
        INPUT_EBASE_EDELMETALL_KAUF_BERATUNG:
          this.data["INPUT_EBASE_EDELMETALL_KAUF_BERATUNG"],
        INPUT_EBASE_EDELMETALL_AUSZAHLKONTO:
          this.data["INPUT_EBASE_EDELMETALL_AUSZAHLKONTO"],
        INPUT_EBASE_EDELMETALL_AUSZAHLONTO_VERWENDUNGSZWECK:
          this.data["INPUT_EBASE_EDELMETALL_AUSZAHLONTO_VERWENDUNGSZWECK"],
        INPUT_EBASE_EDELMETALL_PHYSAUSL_ADRESSTYP:
          this.data["INPUT_EBASE_EDELMETALL_PHYSAUSL_ADRESSTYP"],
        INPUT_EBASE_EDELMETALL_PHYAUSL_KONTAKTEMAIL:
          this.data["INPUT_EBASE_EDELMETALL_PHYAUSL_KONTAKTEMAIL"],
        INPUT_EBASE_EDELMETALL_PHYAUSL_KONTAKTTEL:
          this.data["INPUT_EBASE_EDELMETALL_PHYAUSL_KONTAKTTEL"],
      };
    },
  },
  mounted() {
    this.initForm();
  },
};
</script>
