import dayjs from 'dayjs';
import { isDate } from '@/helpers/commonfunctions.js';


export function prepareFilters(filters) {
  if(!filters) {
    return {};
  }

  return filters.reduce((result, f) => {
    const key = f.key;
    if(!key) {
      return result;
    }

    switch(key) {
      case 'defaultSearchInput':
        result['query'] = f.value;
        break;
      case 'birthday':
        const start = f.value[0];
        const end = f.value[1];
        result['birthdayFrom'] = isDate(start?.value) && dayjs(start?.value).format('DD.MM.YYYY') || start?.value;
        result['birthdayTo'] = isDate(end?.value) && dayjs(end?.value).format('DD.MM.YYYY') || end?.value;
        break;
      default:
        result[key] = f.value;
        break;
    }

    return result;
  }, {})
}

export function searchMenu(isModusAuswahlArbeitgeber = false) {
  const allgemeinSearchMenu = {
    type: 'group',
    key: 'Allgemein',
    label: 'Allgemein',
    menuItems: [
      {
        type: 'text',
        label: 'Name',
        key: 'lastName',
        emptyDenied: true,
      },
      {
        type: 'text',
        label: 'Vorname',
        key: 'firstName',
        emptyDenied: true,
      },
      {
        type: 'text',
        label: 'Telefon',
        key: 'phone',
        emptyDenied: true,
      },
      {
        type: 'text',
        label: 'E-Mail',
        key: 'email',
        emptyDenied: true,
      },
      {
        type: 'text',
        label: 'Bekannt mit Kundennr.',
        key: 'bekanntKundennr',
        emptyDenied: true,
      },
      {
        type: 'dateRange',
        label: 'Geburtstag',
        key: 'birthday',
        emptyDenied: true,
      },
      {
        type: 'text',
        label: 'Kundennummer',
        key: 'kundennr',
        emptyDenied: true,
      },
    ]
  };

  if(isModusAuswahlArbeitgeber) {
    allgemeinSearchMenu.menuItems.push({
      type: 'default',
      label: 'Nur Personen anzeigen, die bereits als Arbeitgeber verwendet werden',
      key: 'onlyEmployers',
      emptyDenied: true,
    });
  }

  const anschriftSearchMenu = {
    type: 'group',
    key: 'Anschrift',
    label: 'Anschrift',
    menuItems: [
      {
        type: 'text',
        label: 'Straße',
        key: 'street',
        emptyDenied: true,
      },
      {
        type: 'text',
        label: 'Land',
        key: 'country',
        emptyDenied: true,
      },
      {
        type: 'text',
        label: 'PLZ',
        key: 'zip',
        emptyDenied: true,
      },
      {
        type: 'text',
        label: 'Ort',
        key: 'city',
        emptyDenied: true,
      },
    ]
  };

  return [
    allgemeinSearchMenu,
    anschriftSearchMenu,
  ];
}
