import WEBRTC_TYPES from './types';
import { SignalingConnectionRole } from './utils/signalingService_types';
import TAPI from '@/store/tapi/types';

export function nextIncomingCallData(state) {
  if (state.callId || state.callInfos.some(callInfo => callInfo.acceptedButNotEstablished)) {
    return null;
  }

  return state.callInfos.findLast(data =>
    data.callId != state.callId // filter that callInfo isn't the current call
    && !data.closeSocketData // it has been rejected
    && data.alertMsg == null); // it hasn't ended
}

export default {
  [WEBRTC_TYPES.GETTERS.PUSH_SOCKET](state) {
    return state.pushSocket;
  },
  [WEBRTC_TYPES.GETTERS.AVAILABLE](state) {
    if (state.calleeChatBeteiligterAvailable) {
      // logged in as broker
      if (state.calleeChatBeteiligterId) // customer view
        return state.calleeChatBeteiligterAvailable[state.calleeChatBeteiligterId];
      else // broker view
        return state.callModalForceOpenId && state.calleeChatBeteiligterAvailable[state.callModalForceOpenId];
    }
    else {
      // logged in as customer
      return state.calleeChatBeteiligterId != null;
    }
  },
  [WEBRTC_TYPES.GETTERS.OPENED_CHAT_BETEILIGTER_ID](state) {
    return state.callModalForceOpenId;
  },
  [WEBRTC_TYPES.GETTERS.CHAT_BETEILIGTER_ID](state) {
    return state.calleeChatBeteiligterId
  },
  [WEBRTC_TYPES.GETTERS.BETEILIGTER_INFOS](state) {
    return state.beteiligterInfos;
  },
  // returns data about the person the user is in a call with
  // returns null if no call is established
  [WEBRTC_TYPES.GETTERS.IN_CALL_WITH](state) {
    const callInfo = state.callInfos.find(data => data.callId == state.callId || data.acceptedButNotEstablished);
    return callInfo ? callInfo.calleeChatBeteiligterId : null;
  },
  // returns the customer id of the person the user is calling
  [WEBRTC_TYPES.GETTERS.WE_ARE_CALLING_ID](state) {
    const callInfo = state.callInfos.find(data => data.isCaller);
    return callInfo ? callInfo.calleeChatBeteiligterId : state.weAreCallingId;
  },
  [WEBRTC_TYPES.GETTERS.IS_CALLING_ACTIVE](state, getters) {
    return !!getters[WEBRTC_TYPES.GETTERS.IN_CALL_WITH] || !!getters[WEBRTC_TYPES.GETTERS.WE_ARE_CALLING_ID];
  },
  [WEBRTC_TYPES.GETTERS.NEXT_INCOMING_CALL](state) {
    const callInfo = nextIncomingCallData(state);
    return callInfo ? callInfo.calleeChatBeteiligterId : null;
  },
  [WEBRTC_TYPES.GETTERS.NEXT_INCOMING_CALL_UUID](state) {
    const callInfo = nextIncomingCallData(state);
    return callInfo ? callInfo.ownUUID : null;
  },
  [WEBRTC_TYPES.GETTERS.CALL_START_DATE](state) {
    return state.callStart;
  },
  [WEBRTC_TYPES.GETTERS.RECEIVED_VIDEO](state) {
    const callInfo = state.callInfos.find(data => data.callId == state.callId);
    return callInfo && callInfo.receivingVideo && callInfo.receivedStream || null;
  },
  [WEBRTC_TYPES.GETTERS.RECEIVED_AUDIO](state) {
    const callInfo = state.callInfos.find(data => data.callId == state.callId);
    return callInfo && callInfo.receivedStream || null;
  },
  [WEBRTC_TYPES.GETTERS.PARTNER_MUTED](state) {
    const callInfo = state.callInfos.find(data => data.callId == state.callId);
    return callInfo && !callInfo.receivingAudio;
  },
  [WEBRTC_TYPES.GETTERS.MUTED](state) {
    return !state.audioInput || !state.audioInput.mediaStream.active || state.muted;
  },
  [WEBRTC_TYPES.GETTERS.RECORDING](state) {
    const callInfo = state.callInfos.find(data => data.callId == state.callId);
    return callInfo && callInfo.recording;
  },
  [WEBRTC_TYPES.GETTERS.CALL_ID](state) {
    return state.callId;
  },
  [WEBRTC_TYPES.GETTERS.SENT_VIDEO](state) {
    return state.transmittedVideo;
  },
  [WEBRTC_TYPES.GETTERS.SENT_AUDIO](state) {
    if (state.audioInput && state.audioInput.mediaStream && state.audioInput.mediaStream.active)
      return state.audioInput.mediaStream;
    return null;
  },
  [WEBRTC_TYPES.GETTERS.CHAT_INFO](state) {
    return state.chatInfos;
  },
  [WEBRTC_TYPES.GETTERS.SAVED_PUSH_PAYLOAD](state) {
    return state.payloadOfPushBeforeLogin;
  },
  [WEBRTC_TYPES.GETTERS.IS_DUMMY_STREAM](state) {
    return state.isDummyStream;
  },
  [WEBRTC_TYPES.GETTERS.CALL_INFO_AFTER_CLOSING](state) {
    const callInfo = state.callInfos.find(callInfo => callInfo.alertMsg != null && callInfo.callId != state.callId);
    if (callInfo) {
      return callInfo;
    }
    return null;
  },
  [WEBRTC_TYPES.GETTERS.CALL_SYMBOL](state,getters) {
    
    const callInfo = nextIncomingCallData(state);
    if (callInfo) {
      if (state.ringSignalState)
        return "🔴 ";
      else
        return "⭕ ";
    }
    if(getters[TAPI.GETTERS.RINGING]){
      return "⭕ ";
    } else if(getters[TAPI.GETTERS.ACTIVE_CALL]){
      return "🔴 ";
    }
    return '';
  },
  [WEBRTC_TYPES.GETTERS.CAMERA_OPTIONS](state) {
    return state.cameraOptions;
  },
  [WEBRTC_TYPES.GETTERS.MICROPHONE_OPTIONS](state) {
    return state.microphoneOptions;
  },
  [WEBRTC_TYPES.GETTERS.SPEAKER_OPTIONS](state) {
    return state.speakerOptions;
  },
  [WEBRTC_TYPES.GETTERS.AUDIO_CONTEXT](state) {
    return state.audioCtx;
  },
}