<template>
  <div>
    <PageHeaderTitleNavigation 
      :actions="headerActions"
      title="Homepage bearbeiten"
      @action-EDIT_SELECTED="editSelected" />

    <div class="box__container">
      <InputRadioBoxGroup title="Bitte wählen Sie ein Template aus:" v-model="dataSelected" :values="inputRadioValues" :disabled="!loaded"/>
    </div>
  </div>
</template>
<script>
import InputRadioBoxGroup from "@/components/core/forms/radiobox/InputRadioBoxGroup.vue";
import validator from "@/mixins/validator";
import { mapGetters } from "vuex";
import CORE_TYPES from "@/store/core/types";
import CMS_TYPES from "@/store/cms/types";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {
  components: {
    InputRadioBoxGroup,
    OptionMenu,
    PageHeaderTitleNavigation,
  },
  computed: {
    ...mapGetters({
      getCmsTemplate: CMS_TYPES.GETTERS.GET_CMS_TEMPLATE,
      firstPageRoute: CMS_TYPES.GETTERS.GET_CMS_FIRST_PAGE_ROUTE,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      isTestUser: CORE_TYPES.GETTERS.IS_TEST,
      isCmsFondsshopOhneHomeSeite: CORE_TYPES.GETTERS.ALLOW_CMS_FONDSSHOP_OHNE_HOME_SEITE,
    }),

    inputRadioValues() {
      const output = []

      output.push({ label: "Fondsshop mit Home-Seite", value: "5", firstpage: '/home' });
      if (this.isCmsFondsshopOhneHomeSeite) {
        output.push({ label: "Fondsshop ohne Home-Seite", value: "6", firstpage: '/fondsshop'});
      }

      if (this.isTestUser) {
        output.push({ label: "Anlageassistent mit Home-Seite", value: "8", firstpage: '/home8' });
      }

      output.push({ label: "OnePager", value: "7", firstpage: '/home7' });

      return output
    },
    headerActions() {
      return [
        PageHeaderSimpleAction('EDIT_SELECTED', 'Bearbeiten')
          .withDisabled(() => !this.loaded)
      ];
    },
  },
  mixins: [validator],
  validators: {},
  data() {
    return {
      dataSelected: '',
      loaded: false,
    };
  },
  created() {
    this.$store.dispatch(CMS_TYPES.ACTIONS.RETRIEVE_CMS_TEMPLATE).then((template) => {
      this.dataSelected = template
      this.loaded = true
    });
  },
  mounted() {
    
  },
  beforeDestroy() {
    this.saveTemplateSeletion()
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zur Übersicht', 
      to,
      from,
      breadcrumb: "Homepage bearbeiten"
    })

    next();
  },    
  methods: {
    editSelected(){
      const found = this.inputRadioValues.find(input => input.value === this.dataSelected)

      if (found) {
        this.$router.push("/page" + found.firstpage);
      }
    }, 

    saveTemplateSeletion() {
      const found = this.inputRadioValues.find(input => input.value === this.dataSelected)
      if (found) {
        const payload = {
          template: this.dataSelected,
          firstpage: found.firstpage
        }

        this.$store.dispatch(CMS_TYPES.ACTIONS.SAVE_TEMPLATE_SELECTION, payload)
      }
    }
  }
};
</script>