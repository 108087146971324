import * as phosphor from 'phosphor-vue';
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";

export default {
    mixins: [InteractiveHelpCommonsMixin],
    props: {
        actionKey: {
            type: String,
            required: true,
        },
        row: {
            type: Object,
            required: true,
        },
        tid: {
            type: String,
            required: true,
        },
        icon: {
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        expanded: {
            type: Boolean,
            default: false,
        },
        size: {
            type: Number,
            default: 24
        },
    },
    computed: {
        iconComponent() {
            if (typeof this.icon === 'string')
                return phosphor[this.icon];
            else
                return this.icon;
        },
    },
    methods: {
        onClick() {
            this.$emit("action", {
                key: this.actionKey,
                row: this.row,
            });
        },
    },
}
