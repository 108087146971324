<template>
  <div class="row">
    <div class="col">
      <InputField
        :label="label"
        :value="value"
        @input="$emit('input', $event)"
      />
    </div>

    <div class="col color-picker">
      <InputColorPickerFull :value="value || previewValue" @input="$emit('input', $event)" />
    </div>
  </div>
</template>

<script>
import InputField from '@/components/core/forms/InputField.vue';
import InputColorPickerFull from '@/components/core/forms/InputColorPickerFull.vue';

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {},
    previewValue: {},
  },
  components: {
    InputField,
    InputColorPickerFull,
  },
};
</script>

<style scoped>
.color-picker {
  flex-grow: 0;
  align-self: flex-end;
}
</style>
