<template>
  <div>
    <Table v-if="!loading && rows.length"
        tableId="45fc16a5-081b-4cab-b832-04bfe1a86004"
        :headers="headers"
        :rows="rows"
        rowId="name"
        hidePagination
        :mobileConfig="mobileConfig"
        :rowsPerPage="rowPerPage"
        :tableRowsPerPage="[]"
        ignoreTablePageSize
    />
    <GhostLoading v-else-if="loading" type="table" :config="{ table: { rows: rowPerPage, }, }" />
    <NoData v-else noIcon />

    <BaseModal 
      ref="settingsModal" 
      modalTitle="Geburtstage Vermittler (Konfiguration)" 
      size="sm"
      @onConfirmButton="saveSettings()"
    >
      <template #default>
        <ComboBox
          label="Mit der Struktur"
          :values="mitDerStrukturValues"
          v-model="form.mitDerStruktur"
        />
      </template>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BROKERDATA_TYPES from '@/store/brokerData/types';
import DASHBOARD_TYPES from '@/components/dashboard/store/types';

import Table from "@/components/table2/Table.vue";
import {TextColumn, DateColumn} from "@/components/table2/table_util.js";
import BaseModal from '@/components/core/BaseModal.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';

const NEXT_DAYS_DEFAULT = 7;
const ROWS_PER_PAGE = 5;
const MIT_DER_STRUKTUR_VALUES = ['Ja', 'Nein', 'Nur Hauptpersonen'];


export default {
  props: {
    nextDays: {
      type: Number,
      default: NEXT_DAYS_DEFAULT,
    },
  },
  data() {
    return {
        rowPerPage: ROWS_PER_PAGE,
        loading: false,
        mitDerStrukturValues: MIT_DER_STRUKTUR_VALUES,
        form: {},
        headers: {
            lockedLeft: [
                TextColumn("name", "Name").makeAlwaysVisible(), // TODO it is need to add a link to open the Makler page
                DateColumn("birthDate", "Geburtsdatum").makeAlwaysVisible(),
            ],
        },
        mobileConfig: {
          title: '',
          headers: ['name','birthDate'],
        }
    };
  },
  computed: {
    ...mapGetters({
      birthdays: BROKERDATA_TYPES.GETTERS.BIRTHDAYS,
      brokerOverviewConfiguration: DASHBOARD_TYPES.GETTERS.BROKER_OVERVIEW_CONFIGURATION,
    }),
    rows() {
        return this.birthdays.map(row => ({
            ...row,
            name: `${row.lastName}, ${row.firstName}`,
        }));
    },
  },
  methods: {
    async findBirthdays() {
      try {
        this.loading = true;
        await this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.FIND_BIRTHDAYS, { nextDays: this.nextDays, });
      } finally {
        this.loading = false;
      }
    },
    openSettings() {
      this.form = {
        mitDerStruktur: this.brokerOverviewConfiguration.MAKLER_UEBERSICHT_GEB_TAGE_VERMITTLER_ART,
      };
      this.$refs.settingsModal.open();
    },
    async saveSettings() {
      if(!this.form?.mitDerStruktur) return;

      await this.$store.dispatch(DASHBOARD_TYPES.ACTIONS.SAVE_BROKER_OVERVIEW_CONFIGURATION, { 
        parameters: {
          'MAKLER_UEBERSICHT_GEB_TAGE_VERMITTLER_ART': this.form.mitDerStruktur,
        },
      });

      this.findBirthdays();
    },
  },
  mounted() {
    this.findBirthdays();
  },
  components: {
    Table,
    BaseModal,
    ComboBox,
    GhostLoading,
    NoData,
  },
}
</script>
