<template>
    <div>
        <div class="row d-none d-xl-flex justify-content-between header" v-if="first">
            <label class="col-lg-2 px-1">Einmalig</label>
            <label class="col-lg-2 px-1">Ratierlich</label>
            <label class="col-lg-2 px-1">Zeithorizont</label>
            <label class="col-lg-2 px-1">Welche Anlageziele haben Sie?</label>
            <label class="col-lg-2 px-1">Welche speziellen Anlageziele haben Sie?</label>
            <label class="col-lg-2 px-1">Bereitschaft Verluste zu tragen</label>
        </div>

        <div class="row justify-content-between">
            <label class="col-12 header">{{title}}</label>
             <div class="col-12 col-lg-2 px-1" v-for="(field, index) in fields" :key="index">
                 <div v-if="field.type === 'TEXT_CURRENCY'">
                     <InputField v-model="form[field.id]" type="currency" :precision="2" :disabled="disabled" 
                     @change="emitEvent('save', $event, field.id, count)"></InputField> 
                 </div>
                 <div v-if="field.type === 'COMBOBOX'">
                    <ComboBox v-model="form[field.id]" :values="field.values || []" :disabled="disabled" firstEmpty 
                    @change="emitEvent('save', $event, field.id, count)"></ComboBox>
                 </div>
            </div>
        </div>
    </div>  
</template>
    
<script>
import InputField from '@/components/core/forms/InputField.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';

export default {
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        count: {
            type: Number,
            default: 1,
        },
        antragData: {
            type: Object,
        },
        fields : {
            type: Array,
            default: []
        }
    },
    components: {
        ComboBox,
        InputField,
        InputTextArea,
    },
    data() {
        return {
            form: {}
        };
    },
    computed: {
        first() {
            return this.count === 1;
        },
        title() {
            return 'Zeile ' + this.count;
        },
    },
    mounted() {
        this.createForm();
    },
    methods: {
        emitEvent(type, value, fieldId, count) {
            this.$emit(type, {type, fieldId,  count , value });
        },
        createForm() {
            this.form = this.fields.
                reduce((acc, curr) => ({...acc, [curr.id] : curr.type === 'COMBOBOX' ? curr.values[0].value : null}), {});
            this.patchForm();
        },  
        patchForm() {
            if (this.form && this.antragData) {
                Object.keys(this.form).forEach(key => {
                    this.form[key] = this.antragData[key];
                    this.emitEvent('change', this.form[key], key, this.count);
                });
            }
        },
        getValues() {
            const values = Object.assign({}, this.form);
            values[this.einmaligStr] = this.einmalig;
            values[this.ratierlichStr] = this.ratierlich;
            return values;
        }
    }
}
</script>

<style>
    .header {
        font-weight: bold;
    }
</style>