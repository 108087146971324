import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import dayjs from "dayjs";

/**
 * mutations: synchronous operation responsible for changing the state
 * actions: asynchronous operation responsible for calling external resources and committing mutations 
 */

const DEFAULT_FILTER_PERIOD = 'year'

export function getInitialState() {
  return {
    categories: null,
    byAccountExternalId: null,
    availableCategories: {},
    transactions: [],
    transactionSplits: {},
    depots: [],
    parameters: null,
    savingsPotential: null,
    savingsPotentialByAccount: null,
    userSettings: null,
    summary: null,
    accountsSum: null,
    accounts: [],
    dailyAccountsHistory: {},
    dataLoaded: false,
    loaded: {
      accounts: false
    },
    filters: {
      dateFrom: dayjs().startOf(DEFAULT_FILTER_PERIOD).toDate(),
      dateTo: dayjs().endOf(DEFAULT_FILTER_PERIOD).toDate(),
      selectedPeriod: DEFAULT_FILTER_PERIOD
    }
  }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}