var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.isLoading && _vm.rows.length
        ? _c("Table", {
            attrs: {
              tableId: "579b1982-470b-4522-8482-1246152081a3",
              rowId: "label",
              headers: _vm.headers,
              rows: _vm.rows,
              rowsPerPage: _vm.rows.length,
              hidePagination: ""
            },
            on: { "action-ITEM_LOESCHEN": _vm.removeItem }
          })
        : _vm.isLoading
        ? _c("GhostLoading", { attrs: { type: "table" } })
        : _c("NoData", { attrs: { noIcon: "" } }),
      _c(
        "BaseButton",
        {
          attrs: { disabled: _vm.saving || _vm.isLoading },
          on: { click: _vm.openMaklerKriterienAuswaehlenModal }
        },
        [_vm._v("Kriterien auswählen")]
      ),
      _c("FormDivider"),
      _c(
        "div",
        [
          _c("InputField", {
            attrs: {
              label: "",
              isComponentHalfSize: true,
              disabled: _vm.saving || _vm.isLoading
            },
            model: {
              value: _vm.maklerKriteriumNeu,
              callback: function($$v) {
                _vm.maklerKriteriumNeu = $$v
              },
              expression: "maklerKriteriumNeu"
            }
          }),
          _c(
            "BaseButton",
            {
              attrs: {
                disabled:
                  _vm.maklerKriteriumNeu.length == 0 ||
                  _vm.saving ||
                  _vm.isLoading
              },
              on: { click: _vm.addNewMaklerKriterien }
            },
            [_vm._v("Neues Kriterium hinzufügen")]
          )
        ],
        1
      ),
      _c("MaklerKriterienAuswaehlenModal", {
        ref: "maklerKriterienAuswaehlenModal",
        attrs: { alleStrukturKriterien: _vm.alleStrukturKriterien },
        on: { save: _vm.saveMaklerKriterien }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }