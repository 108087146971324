var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "insurance-option-menu__container" },
    [
      _c(
        "PageHeaderTitleNavigation",
        _vm._g(
          {
            attrs: {
              actions: _vm.headerActions,
              defaultMenu: _vm.customOptionsMenu,
              noPrimaryAction: _vm.noPrimaryAction
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "title",
                  fn: function() {
                    return [
                      _vm._t("title", function() {
                        return [_vm._v(_vm._s(_vm.title))]
                      })
                    ]
                  },
                  proxy: true
                },
                _vm.$slots.subtitle
                  ? {
                      key: "subtitle",
                      fn: function() {
                        return [_vm._t("subtitle")]
                      },
                      proxy: true
                    }
                  : null
              ],
              null,
              true
            )
          },
          _vm.$listeners
        )
      ),
      _vm.askOptions
        ? _c("ExterneZugangAskOptions", {
            attrs: {
              askOptions: _vm.askOptions,
              title: _vm.externeZugang.companyLabel
            },
            on: {
              close: function($event) {
                return _vm.resetExterneZugang()
              },
              onConfirmButton: function($event) {
                return _vm.askOptionsConfirmed($event)
              }
            }
          })
        : _vm._e(),
      _c(
        "BaseModal",
        {
          ref: "dokumenteAbholenModal",
          attrs: {
            modalTitle: "Dokumente abholen",
            showDefaultButtons: false,
            size: "lg"
          }
        },
        [_vm._v(" " + _vm._s(_vm.dokumenteAbholenResult.value) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }