var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.fkLoggedIn
    ? _c(
        "div",
        [
          !_vm.readOnly
            ? _c(
                "div",
                { staticClass: "box__container" },
                [
                  _c("div", { staticClass: "box__title" }, [
                    _vm._v(
                      "Einladung zur Registrierung für einen Unterzugang zur " +
                        _vm._s(_vm.userLevel)
                    )
                  ]),
                  _c(
                    "BaseButton",
                    {
                      attrs: { isPrimary: "" },
                      on: {
                        click: function($event) {
                          _vm.inviteModal = true
                        }
                      }
                    },
                    [_vm._v("Einladung versenden")]
                  ),
                  _vm.inviteModal
                    ? _c("InviteToSsoModal", {
                        on: {
                          close: function($event) {
                            _vm.inviteModal = false
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("div", { staticClass: "box__title" }, [
                _vm._v(
                  "Mit " + _vm._s(_vm.userId) + " verknüpfte Single-Sign-Ons"
                )
              ]),
              _vm.ssoAccountsAll && _vm.ssoAccountsAll.length
                ? _c("Table", {
                    attrs: {
                      tableId: "fc5b97e6-e605-4281-9be0-25d5dc9c7df1",
                      headers: _vm.headers,
                      rows: _vm.rows,
                      hidePagination: ""
                    },
                    on: { "action-DELETE": _vm.handleActionDelete },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "permissionDescription",
                          fn: function(row) {
                            return [
                              _c("ComboBox", {
                                attrs: {
                                  values: _vm.comboBerechtigung,
                                  disabled: _vm.readOnly
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.updatePermission(row, $event)
                                  }
                                },
                                model: {
                                  value: row.permissionDescription,
                                  callback: function($$v) {
                                    _vm.$set(row, "permissionDescription", $$v)
                                  },
                                  expression: "row.permissionDescription"
                                }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1681992945
                    )
                  })
                : _c("div", { staticClass: "mb-2" }, [
                    _vm._v(" Keine verknüpfte Single-Sign-Ons. ")
                  ]),
              !_vm.readOnly
                ? _c(
                    "BaseButton",
                    { attrs: { isPrimary: "" }, on: { click: _vm.goToInvite } },
                    [_vm._v("Registrierten Benutzer hinzufügen")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "BaseModal",
            {
              ref: "errorModal",
              attrs: { labelButtonCancel: "Ok", showConfirmButton: false },
              on: { onCancelButton: _vm.closeErrorModal },
              scopedSlots: _vm._u(
                [
                  {
                    key: "modalTitle",
                    fn: function() {
                      return [
                        _c("PhInfo", { staticStyle: { color: "orange" } }),
                        _vm._v(" Fehler ")
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                3456869615
              )
            },
            [_c("div", [_vm._v(" " + _vm._s(_vm.errorStr) + " ")])]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }