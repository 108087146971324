export const MUTATION_PREFIX = 'PROCESS: ';
export const ACTIONS_PREFIX = 'PROCESS_';
export const GETTERS_PREFIX = 'PROCESS_';

export default {
  MUTATIONS: {
    GET_PROCESS_LIST_SUCCESS: GETTERS_PREFIX + 'GET_PROCESS_LIST_SUCCESS',
    GET_PROCESS_SUCCESS: GETTERS_PREFIX + 'GET_PROCESS_SUCCESS',

    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
  },
  ACTIONS: {
    GET_PROCESS_LIST: ACTIONS_PREFIX + 'GET_PROCESS_LIST',
    GET_PROCESS: ACTIONS_PREFIX + 'GET_PROCESS',
    DELETE_PROCESS: ACTIONS_PREFIX + 'DELETE_PROCESS',
    DELETE_PROCESS_ITEM: ACTIONS_PREFIX + 'DELETE_PROCESS_ITEM',
    SAVE_PROCESS: ACTIONS_PREFIX + 'SAVE_PROCESS',
    UPDATE_PROCESS: ACTIONS_PREFIX + 'UPDATE_PROCESS',
  },
  GETTERS: {
    PROCESS_LIST: GETTERS_PREFIX + 'PROCESS_LIST',
    PROCESS: GETTERS_PREFIX + 'PROCESS',
  }
}