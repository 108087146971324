var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "color-picker__container",
      class: { disabled: _vm.disabled },
      on: { mouseup: _vm.dragMainCanvasEnd }
    },
    [
      _vm.label
        ? _c("label", {
            domProps: { innerHTML: _vm._s(_vm.sanitize(_vm.label)) }
          })
        : _vm._e(),
      _c(
        "BaseDropdownMenu",
        {
          staticClass: "color-picker--dropdown",
          attrs: {
            disabled: _vm.disabled,
            containerClass: "color-picker--dropdown-container"
          },
          on: {
            onShow: function($event) {
              return _vm.toggleShowHide()
            },
            onHide: function($event) {
              _vm.showPicker = false
            }
          },
          scopedSlots: _vm._u([
            {
              key: "hook-target",
              fn: function() {
                return [
                  _c("div", {
                    staticClass: "color-picker__sample",
                    style: _vm.sampleStyle
                  })
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm.showPicker
            ? _c(
                "div",
                {
                  staticClass: "color-picker__body",
                  attrs: { "data-dropdown-not-close": "" }
                },
                [
                  _c("div", [
                    _c("canvas", {
                      ref: "saturationCanvas",
                      attrs: {
                        width: "300px",
                        height: "300px",
                        id: "saturation_canvas"
                      },
                      on: {
                        click: function($event) {
                          return _vm.updateValueAndSaturation($event)
                        }
                      }
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "saturation-handler",
                        style: {
                          left:
                            "calc(" +
                            _vm.saturationHorizontalPosition +
                            "px - 6px)",
                          top:
                            "calc(" +
                            _vm.saturationVerticalPosition +
                            "px - 6px)",
                          "background-color": "" + _vm.saturationColorHandler
                        }
                      },
                      [_vm._v("   ")]
                    )
                  ]),
                  _c("div", [
                    _c("canvas", {
                      ref: "hueCanvas",
                      attrs: {
                        width: "32px",
                        height: "300px",
                        id: "hue_canvas"
                      },
                      on: {
                        click: function($event) {
                          return _vm.updateHue($event)
                        }
                      }
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "hue-handler",
                        style: {
                          top:
                            "calc(" +
                            _vm.selectorHorizontalPosition +
                            "px - 300px)"
                        }
                      },
                      [_vm._v("   ")]
                    )
                  ])
                ]
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "d-flex mt-2" },
            _vm._l(_vm.shortcutColors, function(color) {
              return _c("div", { key: color }, [
                _c("div", {
                  staticClass: "color-item",
                  style: { "background-color": color },
                  on: {
                    click: function($event) {
                      return _vm.selectColor(color)
                    }
                  }
                })
              ])
            }),
            0
          ),
          _vm.showAutomaticColorButton
            ? _c(
                "BaseButton",
                {
                  staticClass: "mt-2",
                  attrs: { isSecondary: "" },
                  on: { click: _vm.setAutomatic }
                },
                [_vm._v(" Automatisch ")]
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }