<template>
  <div>
    <PageHeaderTitleNavigation :title="pageTitle" />

    <div class="box__container">
      <InputField label="Bezeichnung" v-model="form.bezeichnung" 
        isComponentHalfSize validateUntouched 
        @change="dataChanged()" 
      />

      <ComboBox label="Gesellschaft" v-model="form.gesellId" 
        :values="gesellschaftValues" isComponentHalfSize validateUntouched 
        @change="dataChangedCombo('gesellId', $event); getGesellschaftBeispielKontonr($event)" 
      />

      <InputField label="Nummer" v-model="form.fremdnr" 
        isComponentHalfSize validateUntouched 
        @input="checkFremdnr($event);" 
        @change="dataChanged(); checkFremdnr($event);" 
      />

      <InputField label="Beispiel" v-model="form.beispielKontonr" 
        isComponentHalfSize disabled 
      />

      <InputField label="Portfolio-Kennung" v-model="form.portfolioFremdId" 
        isComponentHalfSize disabled 
      />

      <template v-if="isFAIntern">
        <InputField label="Depot Zusatz" v-model="form.depotnrZusatz" 
          isComponentHalfSize @change="dataChanged()" 
        />
      </template>

      <InputRadioBoxGroup title="Typ der Nummer" v-model="form.nummerntyp" 
        :values="typNummerValues" @input="dataChanged()" 
      />

      <InputToggleSwitch label="Depot / Konto ist geschlossen" inLineLabel 
        v-model="form.depotGeschlossen" @input="dataChanged()" 
      />

      <InputField label="Depottyp / Beschreibung" v-model="form.typ" 
        isComponentHalfSize @change="dataChanged()" 
      />

      <template v-if="isIntern">
        <InputToggleSwitch label="Stornorisiko nicht berücksichtigen" inLineLabel 
          v-model="form.stornorisikoNichtBeruecksichtigen" @input="dataChanged()" 
        />
      </template>
      <div v-else-if="form.stornorisikoNichtBeruecksichtigen">Stornorisiko wird nicht berücksichtigt.</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import KUNDEN_GESELL_NR_TYPES from '@/store/kundenGesellNr/types';
import CORE_TYPES from '@/store/core/types';

import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputField from '@/components/core/forms/InputField.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';

import validator from '@/mixins/validator/index';
import { required, regex, } from '@/mixins/validator/rules';
import { ROLES, VIEW_ROLES, } from '@/router/roles';

const NEW_ID = 'new';

export default {
  name: 'KundenGesellNrEdit',
  mixins: [validator],
  data() {
    return {
      form: {
        gesellId: null,
        fremdnr: null,
        bezeichnung: null,
        beispielKontonr: null,
        portfolioFremdId: null,
        depotnrZusatz: null,
        nummerntyp: null,
        depotGeschlossen: null,
        typ: null,
        stornorisikoNichtBeruecksichtigen: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      comboData: KUNDEN_GESELL_NR_TYPES.GETTERS.COMBO_DATA,
      data: KUNDEN_GESELL_NR_TYPES.GETTERS.DATA,
      dataEdited: KUNDEN_GESELL_NR_TYPES.GETTERS.DATA_EDITED,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
    isFAIntern() {
      return this.hasRoles([[ROLES.FA, VIEW_ROLES.VIEW_CUSTOMER_AS_INTERN]]);
    },
    isIntern() {
      return this.hasRoles([[VIEW_ROLES.VIEW_CUSTOMER_AS_INTERN]]);
    },
    isFormInvalid() {
      return this.validation?.updated && this.validation?.isInvalid('form');
    },
    hasDataChanged() {
      return Object.keys(this.dataEdited)?.length > 0;
    },
    fremdnr() {
      return this.$route.params?.fremdnr;
    },
    gesellId() {
      return this.$route.params?.gesellId;
    },
    pageTitle() {
      return this.isNew ? 'Neue Kundennummer erstellen' : `Bearbeiten des Kontos ${this.fremdnr} bei ${this.gesellId}`;
    },
    isNew() {
      return this.fremdnr === NEW_ID;
    },
    gesellschaftValues() {
      return [ ...this.comboData?.gesellschaftValues || [], ];
    },
    typNummerValues() {
      return [ ...this.comboData?.typNummerValues || [], ];
    },
  },
  watch: {
    data() {
      Object.keys(this.form).forEach(key => this.$set(this.form, key, key in this.dataEdited ? this.dataEdited[key] : this.data[key]));
    },
  },
  methods: {
    findComboData() {
      this.$store.dispatch(KUNDEN_GESELL_NR_TYPES.ACTIONS.FIND_COMBO_DATA);
    },
    getData() {
      if(this.isNew) return;
      const { fremdnr, gesellId, } = this;
      this.$store.dispatch(KUNDEN_GESELL_NR_TYPES.ACTIONS.GET_DATA, { fremdnr, gesellId, });
    },
    dataChanged() {
      this.$store.commit(KUNDEN_GESELL_NR_TYPES.MUTATIONS.ADD_DATA_EDITED, { ...this.form, });
    },
    dataChangedCombo(field, value) {
      this.$set(this.form, field, value);
      this.dataChanged();
    },
    async getGesellschaftBeispielKontonr(gesellId) {
      const beispielKontonr = await this.$store.dispatch(KUNDEN_GESELL_NR_TYPES.ACTIONS.GET_GESELLSCHAFT_BEISPIEL_KONTONR, { gesellId, });
      this.$set(this.form, 'beispielKontonr', beispielKontonr);
    },
    checkFremdnr(fremdnr) {
      this.$nextTick(() => {
        if(/[ -]+/gi.test(fremdnr)) {
          this.$pushErrors('form.fremdnr', 'Keine Bindestriche oder Leerzeichen erlaubt.');
        }
      });
    },
    async saveData() {
      if(this.isFormInvalid) return;

      const { fremdnr, gesellId, isNew, } = this;
      const payload = !isNew ? { fremdnr, gesellId, } : {};
      await this.$store.dispatch(KUNDEN_GESELL_NR_TYPES.ACTIONS.SAVE_DATA, payload);
    },
  },
  mounted() {
    this.findComboData();
    this.getData();
  },
  validators: {
    form: {
      gesellId: [required()],
      fremdnr: [required()],
    },
  },
  async beforeRouteLeave(to, from, next) {
    if(this.isFormInvalid && this.hasDataChanged) {
      await this.$confirmModal({
        title: 'Fehler', 
        message: 'Die Daten können nicht gespeichert werden. Bitte korrigieren Sie die rot umrandeten Bereiche.', 
        labelButtonConfirm: 'Zurück ohne speichern', 
        showCancelButton: true, 
      }).then(() => next());
    } else {
      await this.saveData();
    }
    this.$store.commit(KUNDEN_GESELL_NR_TYPES.MUTATIONS.RESET_DATA);
    this.$store.commit(KUNDEN_GESELL_NR_TYPES.MUTATIONS.RESET_DATA_EDITED);
    next();
  },
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    ComboBox,
    InputField,
    InputRadioBoxGroup,
    InputToggleSwitch,
  },
}
</script>
