<template>
  <BaseModal ref="modal"
    modalTitle="Honorare bearbeiten"
    :showDefaultButtons="false"
    @close="onClose"
  >
    <template #default>
      <DatePickerField label="Datum" 
        v-model="kundeHonorareForm.datum" isValueAsString 
        validateUntouched />

      <InputField label="Text" type="text" 
        v-model="kundeHonorareForm.text" 
        validateUntouched />

      <InputRadioBoxGroup v-model="kundeHonorareForm.useFixbetrag" :values="useFixbetragValues" 
        @input="resetUseFixbetragValues" />

      <TimePicker label="Zeit (hh:mi)"
        v-model="kundeHonorareForm.stundenDauer" :disabled="isUseFixbetragEnable" 
        validateUntouched 
        ignoreDefaultPopUpTime
        @change="calcKundeHonorare" />

      <InputField label="Rundungstundensatz (Minuten)"
        v-model="kundeHonorareForm.rundungStundensatz" disabled />

      <InputField label="Zeit Berechnet"
        v-model="kundeHonorareForm.stundenDauer" disabled />

      <InputField label="Stundensatz" type="currency" 
        v-model="kundeHonorareForm.stundensatz" :disabled="isUseFixbetragEnable" 
        validateUntouched
        @change="calcKundeHonorare" />

      <InputField label="Betrag" type="currency" 
        v-model="kundeHonorareForm.betrag" :disabled="!isUseFixbetragEnable" 
        validateUntouched />

      <InputRadioBoxGroup v-model="kundeHonorareForm.mwstSatz" :values="mwstSatzValues" />
    </template>

    <template #footer>
      <BaseButton isSecondary @click="close()">Abbrechen</BaseButton>
      <BaseButton isPrimary :disabled="isSaveDisabled" @click="save(); close()">Speichern</BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { mapGetters, } from 'vuex';
import FEE_TYPES from '@/store/fee/types';

import BaseModal from '@/components/core/BaseModal.vue';
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import InputField from '@/components/core/forms/InputField.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import TimePicker from '@/components/core/forms/TimePicker.vue';
import BaseButton from '@/components/core/BaseButton.vue';

import validator from '@/mixins/validator';
import { required, } from '@/mixins/validator/rules';
import { formatNumber, } from '@/helpers/number-formatter';

const MWST_SATZ_DEFAULT = 19.0;

export default {
  mixins: [validator],
  data() {
    return {
      kundeHonorareForm: {},
      lastCalculatedValues: {},
    };
  },
  computed: {
    ...mapGetters({
      feeProperties: FEE_TYPES.GETTERS.FEE_PROPERTIES,
      feePropertiesEdited: FEE_TYPES.GETTERS.FEE_PROPERTIES_EDITED,
      calcKundeHonorareData: FEE_TYPES.GETTERS.CALC_KUNDE_HONORARE_DATA,
    }),
    isSaveDisabled() {
      const invalid = !this.kundeHonorareForm.datum?.trim()
        || !this.kundeHonorareForm.text?.trim();

      if(this.isUseFixbetragEnable) {
        return invalid || !this.kundeHonorareForm.betrag;
      } else {
        return invalid || !this.kundeHonorareForm.stundenDauer || !this.kundeHonorareForm.stundensatz;
      }
    },
    isUseFixbetragEnable() {
      return this.kundeHonorareForm?.useFixbetrag === true;
    },
    mwstSatz() {
      const taxPercentage = this.feePropertiesEdited?.taxPercentage || this.feeProperties?.taxPercentage;
      return taxPercentage || MWST_SATZ_DEFAULT;
    },
    useFixbetragValues() {
      return [
        { label: 'Zeitabhängige Abrechnung', value: false, },
        { label: 'Fixbetrag Abrechnung', value: true, },
      ];
    },
    mwstSatzValues() {
      return [
        { label: 'ohne MwSt.', value: '0', },
        { label: `${formatNumber(this.mwstSatz, 1)} % MwSt.`, value: this.mwstSatz, },
      ];
    },
  },
  watch: {
    calcKundeHonorareData() {
      this.kundeHonorareForm.rundungStundensatz = this.calcKundeHonorareData?.rundungStundensatz;
      this.kundeHonorareForm.betrag = this.calcKundeHonorareData?.betrag;
    },
  },
  methods: {
    open(kundeHonorare) {
      if(kundeHonorare?.id) {
        this.kundeHonorareForm = { ...kundeHonorare, };
      } else {
        this.reset();
        this.setInitialValues();
        this.calcKundeHonorare();
      }
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    onClose() {
      this.reset();
    },
    reset() {
      this.kundeHonorareForm = {
        datum: null,
        text: null,
        useFixbetrag: false,
        stundenDauer: null,
        rundungStundensatz: null,
        stundensatz: null,
        betrag: null,
        mwstSatz: '0',
      };
    },
    setInitialValues() {
      const stundensatz = this.feePropertiesEdited?.stundensatz || this.feeProperties?.stundensatz;
      this.$set(this.kundeHonorareForm, 'stundensatz', stundensatz);
    },
    resetUseFixbetragValues() {
      this.kundeHonorareForm.stunden = null;
      this.kundeHonorareForm.betrag = null;
    },
    calcKundeHonorare() {
      if(this.isUseFixbetragEnable || this.isSameValues(this.kundeHonorareForm, this.lastCalculatedValues)) return;

      this.lastCalculatedValues = { ...this.kundeHonorareForm, };

      this.$store.dispatch(FEE_TYPES.ACTIONS.CALC_KUNDE_HONORARE, {
        stundenDauer: this.kundeHonorareForm?.stundenDauer,
        stundensatz: this.kundeHonorareForm?.stundensatz,
      });
    },
    isSameValues(current, last) {
      return current?.stundenDauer === last?.stundenDauer && current?.stundensatz === last?.stundensatz;
    },
    async save() {
      await this.$store.dispatch(FEE_TYPES.ACTIONS.SAVE_KUNDE_HONORARE, { ...this.kundeHonorareForm, });
    },
  },
  validators: {
    kundeHonorareForm: {
      datum: [required()],
      text: [required()],
      stunden: [required()],
      stundensatz: [required()],
      betrag: [required()],
    },
  },
  components: {
    BaseModal,
    DatePickerField,
    InputField,
    InputRadioBoxGroup,
    TimePicker,
    BaseButton,
  },
}
</script>
