<template>
    <div>
        <div class="mb-5">
            <InputToggleSwitch 
                v-model="djeAktiv" 
                inLineLabel
                label="Anlage in Managed Depot erfassen" 
                :disabled="true"
            />
        </div>
        <InputRadioBoxGroup
            v-model="djeVerlust" 
            title="Welchen Verlust an liquidem Vermögen innerhalb eines Jahres würden Sie höchstens in Kauf nehmen?"
            validateUntouched
            :disabled="isDisabled || !isZusatzDJEAktiv"
            :values="values"
            @input="updateStore('zusatzDJE', 'djeVerlust', $event)"/>
        <InputTextArea
            label="Beschreibung der Verlusttragfähigkeit mit eigenen Worten falls vom Kunden gewünscht und obige Fallgruppen nicht ausreichend sind"
            v-model="annotationVerlust"
            isComponentHalfSize
            :disabled="isDisabled || !isZusatzDJEAktiv"
            @change="updateStore('zusatzDJE', 'annotationVerlust', $event)" />
        <InputTextArea
            label="Beschreibung, falls vom Kunden spezielle Anforderungen für die Finanzanlagen bestehen"
            v-model="annotationSpezAnforderungen"
            isComponentHalfSize
            :disabled="isDisabled || !isZusatzDJEAktiv"
            @change="updateStore('zusatzDJE', 'annotationSpezAnforderungen', $event)" />
        <InputTextArea
            label="Beschreibung des Anlagezieles, der Risikoneigung und der Risikobereitschaft (Risikokennzahl) mit eigenen Worten falls vom Kunden gewünscht"
            v-model="annotationRisiko"
            isComponentHalfSize
            :disabled="isDisabled || !isZusatzDJEAktiv"
            @change="updateStore('zusatzDJE', 'annotationRisiko', $event)" />
    </div>
</template>

<script>
import anlegerprofilMixin from '@/mixins/anlegerprofil/anlegerprofil-mixin.js';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch';

export default {
    mixins: [anlegerprofilMixin],
    components: {
        InputRadioBoxGroup,
        InputTextArea,
        InputToggleSwitch,
    },
    data() {
        return {
            djeAktiv: false,
            djeVerlust: '',
            annotationVerlust: '',
            annotationSpezAnforderungen: '',
            annotationRisiko: '',
        }
    },
    mounted() {
        this.createForm();
    },
    computed: {
        values() {
            return this.data?.zusatzDJE?.verlustValues || [];
        }
    },
    methods: {
        createForm() {
            if (this.data?.zusatzDJE) {
                this.djeAktiv = this.data.zusatzDJE.djeAktiv;
                this.djeVerlust = this.data.zusatzDJE.djeVerlust
                this.annotationVerlust = this.data.zusatzDJE.annotationVerlust
                this.annotationSpezAnforderungen = this.data.zusatzDJE.annotationSpezAnforderungen
                this.annotationRisiko = this.data.zusatzDJE.annotationRisiko
            }
        },
    },
}
</script>

<style>

</style>