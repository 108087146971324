export const MUTATION_PREFIX = 'MSC_NEWS: ';
export const ACTIONS_PREFIX = 'MSC_NEWS_';
export const GETTERS_PREFIX = 'MSC_NEWS_';

export default {
  MUTATIONS: {
    GET_MSC_NEWS_SUCCESS: MUTATION_PREFIX + 'GET_MSC_NEWS_SUCCESS',
    GET_MSC_NEWS_COUNT_SUCCESS: MUTATION_PREFIX + 'GET_MSC_NEWS_COUNT_SUCCESS',
    MARK_AS_READ: MUTATION_PREFIX + 'MARK_AS_READ',
    MARK_AS_UNREAD: MUTATION_PREFIX + 'MARK_AS_UNREAD',
    GET_FK_EVENTS_SUCCESS: MUTATION_PREFIX + 'GET_FK_EVENTS_SUCCESS',
    SET_IS_LOADING: MUTATION_PREFIX + 'SET_IS_LOADING',
    SET_HOME_MSC_LINK: MUTATION_PREFIX + 'SET_HOME_MSC_LINK',

    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
  },
  ACTIONS: {
    GET_MSC_NEWS: ACTIONS_PREFIX + 'GET_MSC_NEWS',
    GET_MSC_NEWS_COUNT: ACTIONS_PREFIX + 'GET_MSC_NEWS_COUNT',
    MARK_AS_READ: ACTIONS_PREFIX + 'MARK_AS_READ',
    MARK_AS_UNREAD: ACTIONS_PREFIX + 'MARK_AS_UNREAD',
    GET_FK_EVENTS: ACTIONS_PREFIX + 'GET_FK_EVENTS',
    GET_MSC_HOME_LINK: GETTERS_PREFIX + 'GET_MSC_HOME_LINK',
    TOGGLE_NEWS_MAJOR: ACTIONS_PREFIX + 'TOGGLE_NEWS_MAJOR',
  },
  GETTERS: {
    MSC_HOME_LINK: GETTERS_PREFIX + 'MSC_HOME_LINK',
    MSC_NEWS: GETTERS_PREFIX + 'MSC_NEWS',
    MSC_NEWS_COUNT: GETTERS_PREFIX + 'MSC_NEWS_COUNT',
    MSC_LAST_NEWS: GETTERS_PREFIX + 'MSC_LAST_NEWS',
    FK_EVENTS: GETTERS_PREFIX + 'FK_EVENTS',
    FK_EVENTS_LOADING: GETTERS_PREFIX + 'FK_EVENTS_LOADING',
  }
}