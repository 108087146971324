<template>
  <div class="person-overview__container" :class="{ 'is-collapsable': isCollapsable }">
    <div>
      <b>
        <PhCaretRight v-if="isCollapsable" :size="16" class="toggle clickable" :class="{ 'collapsed': collapsed }" @click="collapsed = !collapsed" /> 
        <span class="mr-1" v-if="isMainPerson"><router-link to="/persoenlichedaten/customer-data/steps/stammdaten">{{ fullname }}</router-link></span>
        <span class="mr-1" v-else><a @click="openPersonDetails">{{ fullname }}</a></span>
        <span v-if="dateOfDeath" class="mr-1">(† {{ dateOfDeath }})</span> 
        <Pill v-if="category" :label="category" title="Kundenkategorie" />
        <span v-if="anredePronomen" class="ml-1" title="Anredeform"><Pill :label="'('+anredePronomen+')'" title="Anredeform" /></span> 
      </b>
    </div>
    <div class="content" :class="{ 'collapsed': isCollapsable && collapsed }">
      <template v-if="address || dateBirth">
        <div v-if="address">{{ address }}</div>
        <div v-if="dateBirth">*{{ dateBirth }} </div>
      </template>
      <template v-else>Keine Daten</template>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { PhCaretRight } from 'phosphor-vue';
import Pill from '@/components/core/Pill.vue';


export default {
  props: {
    person: {},
    category: {},
    anredePronomen: {},
    isCollapsable: {
      type: Boolean,
      default: false,
    },
    isMainPerson: {
      type: Boolean,
      default: false,
    },    
  },
  data() {
    return {
      collapsed: true,
    };
  },
  computed: {
    fullname() {
      const { titles, firstName, lastName } = this.person.personalDataAddress || {};
      return `${titles || ''} ${firstName || ''} ${lastName || ''}`.trim();
    },
    address() {
      const { street, zip, city } = this.person.personalDataAddress || {};
      const address = [];
      if(street) {
        address.push(street);
      }
      if(zip || city) {
        address.push(`${zip || ''} ${ city || '' }`.trim());
      }
      return address.join(', ');
    },
    dateBirth() {
      const formattedDayOfBirth = this.person.personalDataBirth?.formattedDayOfBirth;
      if(formattedDayOfBirth) {
        const dayOfBirth = dayjs(formattedDayOfBirth,'DD.MM.YYYY');
        const dayEnd = this.person.personalDataAddress?.dateOfDeath
          ? dayjs(this.person.personalDataAddress?.dateOfDeath, 'DD.MM.YYYY')
          : dayjs();

        return `${dayOfBirth.format('DD.MM.YYYY')} (${dayEnd.diff(dayOfBirth, 'year')} Jahre)`;
      }
      return '';
    },
    dateOfDeath() {
      const dateOfDeath = this.person.personalDataAddress?.dateOfDeath;
      return dateOfDeath ? dayjs(dateOfDeath).format('DD.MM.YYYY') : '';
    },
  },
  methods: {
    openPersonDetails () {
      if(this.person.personId) {
        this.$router.push({path: `/persoenlichedaten/person-data/${this.person.personId}`});
      }
    },
  },
  components: {
    PhCaretRight,
    Pill,
  },
}
</script>

<style lang='scss' scoped>
.toggle {
  transform: rotate(90deg);
  transition: transform .3s ease;

  &.collapsed {
    transform: rotate(0deg);
  }
}

.is-collapsable {
  .content {
    margin-left: 20px;
    max-height: 100px;
    overflow: hidden;
    transition: max-height .3s ease;

    &.collapsed {
      height: 0px;
    }
  }
}
</style>
