import CORE_TYPES from '@/store/core/types';
import { mapGetters } from 'vuex';

const mixin = {
    computed: {
        ...mapGetters({
            isBroker: CORE_TYPES.GETTERS.IS_BROKER,
            isFA: CORE_TYPES.GETTERS.IS_FA,
            isIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
        }),
        comboBerechtigung() {
            const combo = [{value: "READ_ONLY", label: "Nur Lesen"}];
            if (!this.isFA || this.isIntern) {
                combo.push({value: "OWNER", label: "Vollzugriff"});
            }
            if (this.isBroker) {
                combo.push({value: "MINI_MAKLER", label: "Minizugang"});
            }
            return combo;
        }
    },
};

export default mixin;