<template>
  <div class="logout__container">
    <div class="d-flex">
      {{timeLeft}}
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import SESSION_TIME_TYPES from '@/store/session-time/types'
import CORE_TYPES from '@/store/core/types'

export default {
  data() {
    return {
      timeLeft: 0,
      intervalId: null,
    }
  },
  computed: {
    ...mapGetters({
      parallelTimeoutTime: SESSION_TIME_TYPES.GETTERS.PARALLEL_TIMEOUT_TIME,
    }),
    timeoutTime() {
      return this.parallelTimeoutTime || 0
    }
  },

  methods: {
    updateTimeLeft() {
      const timer = Math.floor((this.timeoutTime - Date.now()) / 1000);

      if (timer <= 0) {
        this.timeLeft = '0:00:00'
        return
      }

      const hour = parseInt(timer / 3600, 10).toString().padStart(2, "0")
      const minutes = parseInt((timer % 3600) / 60, 10).toString().padStart(2, "0")
      const seconds = parseInt((timer % 3600) % 60, 10).toString().padStart(2, "0")

      this.timeLeft = `${hour}:${minutes}:${seconds}`
    }
  },

  mounted() {
    this.intervalId = setInterval(() => {
      this.updateTimeLeft()
    }, 250)
  },

  beforeDestroy() {
    clearInterval(this.intervalId);
  }
}

</script>

<style scoped>

.logout__container {
  align-items: center;
  display: flex;
}

</style>