var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("InputCheckBoxGroup", {
    attrs: {
      title: _vm.title,
      value: _vm.checkBoxValue,
      values: _vm.checkBoxValues,
      validateUntouched: _vm.validateUntouched,
      suppressValidationMessage: _vm.suppressValidationMessage,
      disabled: _vm.disabled
    },
    on: { checkBoxToggle: _vm.onCheckBoxToggle }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }