var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "stepper-item__container clickable",
      class: { "stepper-item--selected": _vm.isCurrent },
      attrs: { tid: _vm._generateTidFromString(_vm.step.label) },
      on: {
        click: function($event) {
          return _vm.$emit("click", $event)
        },
        mouseover: function($event) {
          return _vm.handleMouseOver($event)
        },
        mouseleave: function($event) {
          return _vm.handleMouseLeave($event)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "stepper-item__icon" },
        [
          _c("VerticalStepIcon", {
            attrs: {
              stepperStatus: _vm.stepperStatus,
              isCurrent: _vm.isCurrent,
              collapsed: _vm.collapsed
            }
          })
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "vertical-stop__label",
          class: { "vertical-stop__label--selected": _vm.isCurrent }
        },
        [
          _c("span", {
            domProps: { innerHTML: _vm._s(_vm.sanitize(_vm.step.label)) }
          }),
          _vm._v(" "),
          _vm.showSaveSpinner ? _c("AnimatedSpinner") : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }