import devtools from '@vue/devtools'
import BRIDGE_TYPES from '@/store/bridge/types';
import Vue from 'vue';

const DEFAULT_DEVTOOLS_PORT = '8098'

const host = process.env.VUE_APP_VUE_DEVTOOLS_HOST
const port = process.env.VUE_APP_VUE_DEVTOOLS_PORT
const isDevelopment = process.env.NODE_ENV === 'development'

if (isDevelopment && host) {
  devtools.connect(host, port || DEFAULT_DEVTOOLS_PORT)
}

if (isDevelopment) {
  window.dev = {
    get vue() {
      return document.querySelector('#app').__vue__
    },
    get __Vue__() {
      return Vue;
    },
    async enableAndroidContext() {
      await this.vue.$store.dispatch(BRIDGE_TYPES.ACTIONS.MOBILE_NATIVE_CONTEXT, 'android');
      await this.vue.$store.dispatch(BRIDGE_TYPES.ACTIONS.MOBILE_NATIVE_SPEC, {
        appInstallationId: '61f2f33e65cb51e3-63a8ab68-42d1-4c45-9ddb-fafdd65da2aa',
        appId: 'de.soscomp.mobilemscandroid.abatus',
        appVersion: '10800130',
        nativeOsVersion: 'SDK33RELEASE13',
        nativeDeviceModel: 'GENERIC-ANDROID-MODEL',
      })
    },
    async enableIOSContext() {
      await this.vue.$store.dispatch(BRIDGE_TYPES.ACTIONS.MOBILE_NATIVE_CONTEXT, 'ios');
      await this.vue.$store.dispatch(BRIDGE_TYPES.ACTIONS.MOBILE_NATIVE_SPEC, {
        appInstallationId: '61f2f33e65cb51e3-63a8ab68-42d1-4c45-9ddb-fafdd65da2aa',
        appId: 'de.soscomp.mobilemscios.abatus',
        appVersion: '10800130',
        nativeOsVersion: 'SDK33RELEASE13',
        nativeDeviceModel: 'GENERIC-IOS-MODEL',
      })
    },

    /**
     * Giving a certain store name (folder name), it should print declared types without function and declared function without types.
     * @param {String} storeName 
     */
    async checkStoreDeclarationIntegrity(storeName) {
      try {
        const storeIndex = await import(`@/store/${storeName}/index.js`);
        const storeTypes = await import(`@/store/${storeName}/types.js`);

        compareDifferences(storeIndex.default.actions, storeTypes.default.ACTIONS, 'actions');
        compareDifferences(storeIndex.default.getters, storeTypes.default.GETTERS, 'getters');
        compareDifferences(storeIndex.default.mutations, storeTypes.default.MUTATIONS, 'mutations');

      } catch (error) {
        console.error(`The '${storeName}' is not a valid store folder`);
      }
    }
  }
}

function compareDifferences(funcObj, typeObj, target) {
  if (funcObj && typeObj) {
    const declaredFunctions = Object.keys(funcObj);
    const declaredTypes = Object.values(typeObj);

    const missingTypes = declaredFunctions.filter(key => !declaredTypes.includes(key));
    const missingFunctions = declaredTypes.filter(key => !declaredFunctions.includes(key));

    if (missingFunctions.length > 0) {
      console.log(`Declared types in ${target} without a proper function`);
      console.log(missingFunctions);
    }

    if (missingTypes.length > 0) {
      console.log(`Declared functions in ${target} without types`);
      console.log(missingTypes);
    }
  }
}
