var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Weitere Verbindlichkeiten",
          defaultMenu: _vm.$appNavigation.currentTabMenu,
          actions: _vm.headerActions
        },
        on: {
          "action-ADD": function($event) {
            return _vm.addNewOtherProduct()
          }
        }
      }),
      _c("OtherProductsSearch", {
        ref: "otherProductsSearch",
        attrs: { type: _vm.WEITERE_VERBINDLICHKEITEN_TYPE, hideNavigation: "" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }