var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: { showDefaultButtons: false },
      on: { close: _vm.close },
      scopedSlots: _vm._u([
        {
          key: "modalTitle",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.title) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        [
          _c("br"),
          _vm.details && _vm.details.journal
            ? _c("Table", {
                attrs: {
                  headers: _vm.journalHeaders,
                  rows: _vm.journalRows,
                  rowId: "index",
                  hidePagination: ""
                }
              })
            : _vm._e(),
          _c("br"),
          _c("br"),
          _vm.details && _vm.details.values
            ? _c("Table", {
                attrs: {
                  headers: _vm.valuesHeaders,
                  rows: _vm.details.values,
                  rowId: "label",
                  hidePagination: ""
                }
              })
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }