var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-forms__container" }, [
    _c("div", { staticClass: "input-forms__label-container" }, [
      _c("div", { staticClass: "input-forms__label-container" }, [
        _c("div", { staticClass: "row mt-0" }, [
          _c("div", { staticClass: "col" }, [
            _vm.label
              ? _c(
                  "div",
                  {
                    staticClass:
                      "\n              input-forms__label-content input-forms__label-content--bigger\n            "
                  },
                  [
                    _c("ph-bank", { attrs: { size: 16 } }),
                    _vm._v(" " + _vm._s(_vm.label) + " ")
                  ],
                  1
                )
              : _vm._e()
          ]),
          _vm.config && _vm.config.buttonText
            ? _c(
                "div",
                { staticClass: "col-auto" },
                [
                  _c("BaseButton", { on: { click: _vm.openFormsFinder } }, [
                    _vm._v(_vm._s(_vm.config.buttonText))
                  ])
                ],
                1
              )
            : _vm._e()
        ])
      ])
    ]),
    _c(
      "div",
      { staticClass: "input-forms__input-container" },
      [
        _vm.tableData && _vm.tableData.records && _vm.tableData.records.length
          ? _c("Table", {
              attrs: {
                tableData: _vm.tableData,
                cardViewEnabled: false,
                filterEnabled: false,
                exportEnabled: false,
                paginationEnabled: false,
                actions: _vm.columnActions,
                rowsPerPage: _vm.tableData.records.length
              },
              on: {
                "execute-action": function($event) {
                  return _vm.handleTableAction($event)
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "betragSparplan",
                    fn: function(props) {
                      return [
                        props.data.row
                          ? _c("InputField", {
                              attrs: { type: "currency" },
                              on: {
                                input: function($event) {
                                  return _vm.onChangeInputField(
                                    $event,
                                    props.data.row,
                                    "betragSparplan"
                                  )
                                }
                              },
                              model: {
                                value: props.data.row.betragSparplan,
                                callback: function($$v) {
                                  _vm.$set(
                                    props.data.row,
                                    "betragSparplan",
                                    $$v
                                  )
                                },
                                expression: "props.data.row.betragSparplan"
                              }
                            })
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "aa",
                    fn: function(props) {
                      return [
                        props.data.row
                          ? _c("InputField", {
                              attrs: {
                                type: "number",
                                precision: 4,
                                placeholder: props.data.row.defaultAA
                              },
                              on: {
                                input: function($event) {
                                  return _vm.onChangeInputField(
                                    $event,
                                    props.data.row,
                                    "aa"
                                  )
                                }
                              },
                              model: {
                                value: props.data.row.aa,
                                callback: function($$v) {
                                  _vm.$set(props.data.row, "aa", $$v)
                                },
                                expression: "props.data.row.aa"
                              }
                            })
                          : _vm._e()
                      ]
                    }
                  }
                ],
                null,
                false,
                2093067289
              )
            })
          : _vm._e(),
        _c("DeletePositionModal", {
          ref: "deleteModal",
          attrs: { position: _vm.positionToDelete },
          on: {
            delete: function($event) {
              return _vm.doDeletePosition()
            }
          }
        }),
        _c("BaseModal", {
          ref: "fehlerModal",
          attrs: { labelButtonCancel: "Ok", showConfirmButton: false },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-warning", {
                    staticClass: "color-danger mr-2",
                    attrs: { size: 16 }
                  }),
                  _c("span", { staticClass: "color-danger" }, [
                    _vm._v(" Sie haben die maximale Anzahl von Fonds erreicht.")
                  ])
                ]
              },
              proxy: true
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }