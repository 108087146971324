<template>
  <span 
    v-if="internCount" 
    class="badge__counter" 
    :class="{ 'more-than-99': internCount === MAX_BADGE_COUNT_TEXT, }"
  >
    {{ internCount }}
  </span>
</template>

<script>
const MAX_BADGE_COUNT = 99;
const MAX_BADGE_COUNT_TEXT = `${MAX_BADGE_COUNT}+`;

export default {
  name: 'BaseBadgeCounter',
  props: {
    count: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      MAX_BADGE_COUNT_TEXT,
    };
  },
  computed: {
    internCount() {
      return this.count <= MAX_BADGE_COUNT ? this.count : MAX_BADGE_COUNT_TEXT;
    },
  },
}
</script>

<style lang="scss" scoped>
.badge__counter {
  background: var(--color-danger);
  border-radius: 16px;
  box-sizing: border-box;
  color: var(--color-box);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  font-size: 10px;
  width: 16px;
  height: 16px;

  &.more-than-99 {
    width: 24px;
  }
}
</style>
