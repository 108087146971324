<template>
<div>
    <div>
        <div class="col-12" v-if="config.tableData">
            <Table v-if="rows.length"
                tableId="701e2bd4-29b4-427e-96da-40ad35e57788"
                :headers="headers"
                :rows="rows"
                :rowsPerPage="20"
            />
        </div>   

    </div>
</div>

</template>

<script>
import Table from "@/components/table2/Table.vue";
import {TextColumn, NumberColumn} from "@/components/table2/table_util.js";

export default {
    props: {
        config: {
            type: Object,
        },
    },
    components: {
        Table
    },
    data() {
        return {
            form: {},
            resultCount: null,
        };
    },
    computed: {
        headers() {
            return {
                lockedLeft: [
                    TextColumn("description", "Wertpapiername"),
                ],
                center: [
                    TextColumn("isin", "ISIN"),
                    TextColumn("wkn", "WKN"),
                    NumberColumn("riskNumber", this.config.riskNumberLabel, 0),
                    NumberColumn("altBestand", "Altbestand", 2).withSumFooter(),
                    NumberColumn("sales", "Verkäufe", 2).withSumFooter(),
                    NumberColumn("neuBestand", "Neubestand", 2).withSumFooter(),
                ],
            };
        },
        rows() {
            return this.config?.tableData || [];
        },
    },
}
</script>