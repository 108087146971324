<template>
  <div>
    <LoginNeeded/>

    <MultiBankingNavigation subtitle="Kategorien" />

    <MultiBankingFilter v-if="showCategories" filterId="1fbe3990-9cae-450a-8eec-eb52f726b326" :accountId="accountId" showType />

    <div v-if="showCategories">
      <div class="box__container" v-if="accounts && accounts.length">
        
        <ChangeCategory v-if="changeCategory" :transaction="changeCategory" @close="changeCategory=null"/>
        <EditCategory v-if="editCategory" :category="editCategory" @close="editCategory=null"/>

        <PieChart v-if="chartData" :chartData="chartData" height="400px" doughnut/>
        <div v-else class="kategorien-nodata">
          Keine Daten für diesen Zeitraum.
        </div>

        <Table v-if="rows.length"
            tableId="39f84dab-e85d-4c0f-b971-4d423bee886b"
            :headers="headers"
            :rows="rows"
            :rowsPerPage="25"
            :mobileConfig="{title: 'bezeichnung', headers: ['umsaetze', 'betrag']}"
            @action-EDIT_CATEGORY="openEditCategoryModal($event.category)"
            @action-GO_TO_TRANSACTIONS="goToTransactions($event.category)"
        >
            <template v-slot:color="row">
                <div class="kategorien__bullet" :style="{'background-color': row.color}"></div>
            </template>
        </Table>
      </div>

      <NoData v-else useBoxContainer />
    </div>
    <div v-else>
      <NoMultibankingWarning/>
    </div>
  </div>
</template>

<script>
import { getColorsMixedContrastDecreasing } from '@/helpers/colors-helper.js'
import { mapGetters } from 'vuex'
import CORE_TYPES from '@/store/core/types'
import MULTIBANKING_TYPES from '@/store/multiBanking/types'
import EditCategory from '@/components/multiBanking/EditCategory'
import ChangeCategory from '@/components/multiBanking/ChangeCategory'
import NoMultibankingWarning from '@/components/multiBanking/NoMultibankingWarning.vue'
import Table from "@/components/table2/Table.vue";
import {TextColumn, NumberColumn, CurrencyColumn, PercentageColumn, ActionColumn, SlotColumn, SimpleAction} from "@/components/table2/table_util.js";
import { formatNumber } from '@/helpers/number-formatter.js'
import { PhMoney, PhArrowsLeftRight, PhCaretDown } from 'phosphor-vue'
import LoginNeeded from '@/components/multiBanking/LoginNeeded.vue'
import PieChart from '@/components/charts/echarts/PieChart.vue'
import NoData from '@/components/core/NoData.vue'
import MultiBankingNavigation from '@/components/multiBanking/MultiBankingNavigation.vue';
import MultiBankingFilter from '@/components/multiBanking/MultiBankingFilter.vue';

export default {
  components: {
    EditCategory,
    ChangeCategory,
    NoData,
    NoMultibankingWarning,
    Table,
    LoginNeeded,
    PieChart,
    MultiBankingNavigation,
    MultiBankingFilter,
  },

  props: {
    accountId: {
      type: String,
      default: null,
    },
  },

  data: function() {
    return {
      categoryTabOptions: ["Einnahmen", "Ausgaben"],
      showTransactions: false,
      transactionsToShow: 10,
      editCategory: null,
      changeCategory: null,
        headers: {
            lockedLeft: [
                SlotColumn("color", "", 100, 0),
                TextColumn("bezeichnung", "Bezeichnung").makeAlwaysVisible(),
            ],
            center: [
                NumberColumn("umsaetze", "Umsätze"),
                PercentageColumn("anteil", "Anteil"),
                CurrencyColumn("budget", "Budget"),
                CurrencyColumn("betrag", "Betrag"),
            ],
            lockedRight: [
                ActionColumn("actions", "Aktionen"),
            ],
        },
    }
  },

  computed: {
    ...mapGetters({
      isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
      isMultibankingAccountUser: CORE_TYPES.GETTERS.IS_MULTIBANKING_ACCOUNT_USER,
      brokerData: CORE_TYPES.GETTERS.GET_BROKER_INFORMATION,
      categoryTree: MULTIBANKING_TYPES.GETTERS.CATEGORY_TREE,
      userSettings: MULTIBANKING_TYPES.GETTERS.USER_SETTINGS,
      transactions: MULTIBANKING_TYPES.GETTERS.TRANSACTIONS,
      accounts: MULTIBANKING_TYPES.GETTERS.ACCOUNTS,
      filters: MULTIBANKING_TYPES.GETTERS.FILTERS,
    }),
    selectedAccountId() {
      return this.filters?.accountId || '';
    },
    selectedAccountIds () {
      return this.selectedAccountId ? [this.selectedAccountId] : null
    },
    tabOption() {
      return this.filters?.type || 'Ausgaben';
    },
    colors() {
      return getColorsMixedContrastDecreasing()
    },
    categoriesList() {
      let categories = this.categoryTree || []
      categories = categories.map(category => {
        let transactions = this.transactionList.filter(transaction => 
              transaction.category.category == category.category || transaction.category.parentCategory == category.category)
        return {
          ...category,
          quantityTransaction: transactions.length,
          amount: transactions.reduce((sum, transaction) => {
            return sum + transaction.amount
          }, 0),
        }
      }).filter(category => category.amount)
      let result = categories.sort((a, b) => Math.abs(b.amount) - Math.abs(a.amount))

      result = result.map((cat, index) => ({...cat, color: this.getColorByIndex(index, this.colors)}))
      return result
      
    },
    rows() {
        if (!this.categoriesList)
            return [];
        const actions = [
            SimpleAction("EDIT_CATEGORY", PhMoney, "Kategorie bearbeiten"),
            SimpleAction("GO_TO_TRANSACTIONS", PhArrowsLeftRight, "zeige Umsätze"),
        ];
        return this.categoriesList.map(category => ({
            bezeichnung: category.displayName,
            umsaetze: category.quantityTransaction,
            anteil: formatNumber(this.getPercentage(category), 2),
            budget: category.budget,
            betrag: category.amount,
            color: category.color,
            category,
            actions,
        }));
    },
    chartData() {
      const data = []
      this.categoriesList.forEach(category => {
        data.push({
          name: category.displayName,
          value: Math.abs(category.amount),
        })
      })
      if (data.length == 0)
        return null
      return data
    },
    transactionList() {
      return this.transactions.filter(transaction => {
        let date = new Date(transaction.valueDate)
        if (this.filters.dateFrom && this.filters.dateFrom > date)
          return false
        if (this.filters.dateTo && this.filters.dateTo < date)
          return false
        if (this.selectedAccountIds && !this.selectedAccountIds.includes(transaction.account.externalId))
          return false
        if (this.tabOption == "Einnahmen")
          return transaction.amount >= 0
        else
          return transaction.amount <= 0
      }).sort((a, b) => b.valueDate - a.valueDate)
    },
    showCategories() {
      return this.isMultibankingAccountUser && (!this.isBrokerOrBypass || this.userSettings?.shareAccounts) 
    },
    showAccounts() {
      return this.$route.meta.showAccounts
    },
  },

  methods: {
    getColorByIndex(index, colors) {
      const colorLength = colors.length
      while (index > colorLength -1) {
        index -= colorLength
      }
      return colors[index]
    },
    goToTransactions(category) { 
      this.$router.push({ name: `balance-per-period`, query: { category: category.category } })
    },
    openEditCategoryModal(category) {
      this.editCategory = category
    },
    openChangeCategoryModal(transaction) {
      if (!this.isBrokerOrBypass)
        this.changeCategory = transaction
    },
    navigateToTransactions(transactionId) {
      this.$router.push({name: `balance-per-period`, query: {transactionId: transactionId}})
    },
    getPercentage(category) {
      return 100 * category.amount / this.categoriesList.reduce((sum, category) => sum + category.amount, 0)
    },
    isBudgetOverrun(category) {
      return Math.abs(category.amount) > Math.abs(this.getBudgetRatio(category.budget))
    },
    getBudgetRatio(budget) {
      switch (this.filters.selectedPeriod) {
        case 'week':
          return budget / 4
        case 'year':
          return budget * 12
        case 'three-months':
          return budget * 3
        default:
          return budget
      }
    },
    loadPeriod (period) {
      if (period && period.dateFrom) {
        this.$store.dispatch(MULTIBANKING_TYPES.ACTIONS.LOAD_TRANSACTIONS, {
          fromDate: period.dateFrom,
          toDate: period.dateTo,
        })
        this.$store.dispatch(MULTIBANKING_TYPES.ACTIONS.LOAD_DEPOTS, {
          fromDate: period.dateFrom,
          toDate: period.dateTo,
        })
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu Kategorien', 
      to,
      from,
    });

    next()
  },
}
</script>

<style scoped>
h3 > a {
  padding-left: 2rem;
}
.color-circle {
  width: 12px;
  height: 12px;
  border-radius: 100px;
  display: inline-block;
}
.clickable {
  cursor: pointer;
}
.text-danger {
  color: var(--color-danger);
}
.kategorien-nodata {
  padding: 8rem 0;
  display: flex;
  justify-content: center;
}
.kategorien__bullet {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}
</style>