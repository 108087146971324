export const MUTATION_PREFIX = 'STOPPUHR_MUTATIONS_';
export const ACTIONS_PREFIX = 'STOPPUHR_ACTIONS_';
export const GETTERS_PREFIX = 'STOPPUHR_GETTERS_';

export default {
  MUTATIONS: {
    INIT_STOPPUHR_SUCCESS: MUTATION_PREFIX + 'INIT_STOPPUHR_SUCCESS',
    SET_STOPPUHR_STATE: MUTATION_PREFIX + 'SET_STOPPUHR_STATE',
    SET_STOPPUHR_HOUR: MUTATION_PREFIX + 'SET_STOPPUHR_HOUR',
    SET_STOPPUHR_MINUTES: MUTATION_PREFIX + 'SET_STOPPUHR_MINUTES',
    SET_STOPPUHR_SECONDS: MUTATION_PREFIX + 'SET_STOPPUHR_SECONDS',
    STORE_STOPUHR_FORM: MUTATION_PREFIX + 'STORE_STOPUHR_FORM',
    RESET_STOPUHR_FORM: MUTATION_PREFIX + 'RESET_STOPUHR_FORM',
    SET_INTERVAL_ID: MUTATION_PREFIX + 'SET_INTERVAL_ID',
    SET_TIME_LAPSED: MUTATION_PREFIX + 'SET_TIME_LAPSED',
    PROCESS_FORM_CHANGE: MUTATION_PREFIX + 'PROCESS_FORM_CHANGE',
    SET_CURRENT_INTERVAL: MUTATION_PREFIX + 'SET_CURRENT_INTERVAL',
    UPDATE_LIST_INTERVAL: MUTATION_PREFIX + 'UPDATE_LIST_INTERVAL',
  },
  ACTIONS: {
    INIT_STOPPUHR: ACTIONS_PREFIX + 'INIT_STOPPUHR',
    PERSIST: ACTIONS_PREFIX + 'PERSIST',
  },
  GETTERS: {
    INIT_STOPPUHR: GETTERS_PREFIX + 'INIT_STOPPUHR',
    IS_STOPPUHR_RUNNING: GETTERS_PREFIX + 'IS_STOPPUHR_RUNNING',
    STOPPUHR_HOUR: GETTERS_PREFIX + 'STOPPUHR_HOUR',
    STOPPUHR_MINUTES: GETTERS_PREFIX + 'STOPPUHR_MINUTES',
    STOPPUHR_SECONDS: GETTERS_PREFIX + 'STOPPUHR_SECONDS',
    STOPUHR_FORM: GETTERS_PREFIX + 'STOPUHR_FORM',
    INTERVAL_ID: GETTERS_PREFIX + 'INTERVAL_ID',
    CURRENT_INTERVAL: GETTERS_PREFIX + 'CURRENT_INTERVAL',
    LIST_INTERVAL: GETTERS_PREFIX + 'LIST_INTERVAL',
  }
}