var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.position.isin,
          actions: _vm.headerActions,
          noPrimaryAction: true,
          showBackButton: false
        },
        on: {
          "action-DELETE": function($event) {
            return _vm.openModalDeleteFond(_vm.position)
          }
        },
        scopedSlots: _vm._u(
          [
            {
              key: "title",
              fn: function() {
                return [
                  _vm._t("title", function() {
                    return [
                      _c("div", { staticClass: "cards__item--header " }, [
                        _c(
                          "a",
                          {
                            on: {
                              click: function($event) {
                                return _vm.gotoFondsinfo(_vm.position)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.position.fondsname))]
                        )
                      ])
                    ]
                  })
                ]
              },
              proxy: true
            },
            {
              key: "subtitle",
              fn: function() {
                return [
                  _vm._t("subtitle", function() {
                    return [
                      _c("div", { staticClass: "font-bold" }, [
                        _c("span", [
                          _vm._v("ISIN: " + _vm._s(_vm.position.isin))
                        ])
                      ])
                    ]
                  })
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      }),
      _c(
        "div",
        { staticClass: "cards__item--inputs" },
        [
          _vm.position.art == "KAUF"
            ? _c("InputField", {
                attrs: {
                  label: "Einmalanlage",
                  type: "currency",
                  precision: "2",
                  id: "einmalBetrag",
                  placeholder: "mind. 100,- EUR",
                  value: _vm.position.einmalBetrag,
                  disabled: _vm.disabled
                },
                on: {
                  input: function($event) {
                    return _vm.onChangeInputField(
                      $event,
                      _vm.position,
                      "einmalBetrag"
                    )
                  }
                }
              })
            : _vm._e(),
          _vm.position.art == "SPARPLAN"
            ? _c("InputField", {
                attrs: {
                  label: "Sparplan",
                  type: "currency",
                  precision: "2",
                  id: "planBetrag",
                  placeholder: "mind. 10,- EUR",
                  value: _vm.position.planBetrag,
                  disabled: _vm.disabled
                },
                on: {
                  input: function($event) {
                    return _vm.onChangeInputField(
                      $event,
                      _vm.position,
                      "planBetrag"
                    )
                  }
                }
              })
            : _vm._e(),
          !_vm.isWebservice
            ? _c("InputField", {
                attrs: {
                  label: "Bemerkung",
                  id: "bemerkung",
                  value: _vm.position.bemerkung
                },
                on: {
                  input: function($event) {
                    return _vm.onChangeInputField(
                      $event,
                      _vm.position,
                      "bemerkung"
                    )
                  }
                }
              })
            : _vm._e(),
          _vm.position.art == "SPARPLAN"
            ? _c("ComboBox", {
                attrs: {
                  label: "zum:",
                  id: "planBeginnTag",
                  value: _vm.position.planBeginnTag,
                  values: [
                    { label: "", value: "" },
                    { label: "1.", value: "1" },
                    { label: "15.", value: "15" }
                  ],
                  disabled: _vm.disabled
                },
                on: {
                  input: function($event) {
                    return _vm.onChangeInputField(
                      $event,
                      _vm.position,
                      "planBeginnTag"
                    )
                  }
                }
              })
            : _vm._e(),
          _vm.position.art == "SPARPLAN"
            ? _c("ComboBox", {
                attrs: {
                  label: "Turnus",
                  sortComboboxValues: false,
                  id: "planFrequenz",
                  value: _vm.position.planFrequenz,
                  values: [
                    { label: "", value: "" },
                    { label: "monatlich", value: "MONATLICH" },
                    { label: "vierteljährlich", value: "VIERTELJ" },
                    { label: "halbjährlich", value: "HALBJ" },
                    { label: "jährlich", value: "JAEHRLICH" }
                  ],
                  disabled: _vm.disabled
                },
                on: {
                  input: function($event) {
                    return _vm.onChangeInputField(
                      $event,
                      _vm.position,
                      "planFrequenz"
                    )
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c("DeletePositionModal", {
        ref: "deleteModal",
        attrs: { position: _vm.position, positionLabel: _vm.position.art },
        on: {
          delete: function($event) {
            return _vm.removePosition()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }