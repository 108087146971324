import D_B from './types';

export default {

  [D_B.GETTERS.DATA](state) {

    return state.DATA;
  },
  [D_B.GETTERS.START_TIME](state) {
    
    return state.START_TIME;
  },

  [D_B.GETTERS.SESSION](state) {
    
    return state.SESSION;
  },
  [D_B.GETTERS.SELECTED_NODE](state) {
    
    return state.SELECTED_NODE;
  },

  [D_B.GETTERS.DATA_SEGMENTS](state) {
    
    return state.DATA_SEGMENTS;
  },
  [D_B.GETTERS.STAT_DATA](state) {
    
    return state.STAT_DATA;
  },
  [D_B.GETTERS.FORMAT_SWITCH](state) {
    return state.FORMAT_SWITCH;
  },
  [D_B.GETTERS.FULL_SQL](state) {
    return state.FULL_SQL;
  },
  [D_B.GETTERS.RUNNIG](state) {
    return state.RUNNIG;
  },
  [D_B.GETTERS.STAT](state) {
    return state.STAT;
  },
  [D_B.GETTERS.MINUTS](state) {
    return state.MINUTS;
  }
}