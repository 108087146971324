import SEARCH_CONFIGS_TYPES from './types';
import FC_CONFIG_TYPES from '@/store/fcConfig/types'
import FC_CONFIG from '@/configs/fcConfig.js'

export default {
  /**
   * Intended to temporarily save sort options during the current user interaction 
   */
  [SEARCH_CONFIGS_TYPES.ACTIONS.SAVE_TABLE_SORT_OPTIONS]({ commit }, params) {
    if (params.tableId) {
      commit(SEARCH_CONFIGS_TYPES.MUTATIONS.SAVE_TABLE_SORT_OPTIONS, params)
    }
  },
  

  /**
   * Intended to temporarily save filter options during the current user interaction 
   */
  [SEARCH_CONFIGS_TYPES.ACTIONS.SAVE_FILTER_OPTIONS]({ commit }, params) {
    if (params.filterId) {
      commit(SEARCH_CONFIGS_TYPES.MUTATIONS.SAVE_FILTER_OPTIONS, params)
    }
  },
  
  /*
   * Intended to save filter options on the server so they can be loaded the next time the user navigates to the same filter
   */
  [SEARCH_CONFIGS_TYPES.ACTIONS.SAVE_FILTER]({ dispatch, getters }, {searchKey, searchParameter}) {

    const userLevel = getters[FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_USER_LEVEL_DEFAULT]
    const current = getters[FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG]?.[FC_CONFIG.STORED_SEARCH_PARAMETER]?.[searchKey] || {}

    let nextContent = current?.content;
    
    try {
      if (typeof nextContent === 'string') {
        nextContent = JSON.parse(nextContent)
      }
    } catch (error) {
      // empty block
    }
    
    if (nextContent && Array.isArray(nextContent)) {
      nextContent.push(searchParameter)
    } else {
      nextContent = [searchParameter]
    }
    

    const payload = {
      userLevel: current?.userLevel || userLevel,
      configId: searchKey,
      configType: FC_CONFIG.STORED_SEARCH_PARAMETER,
      content: JSON.stringify(nextContent)
    }

    dispatch(FC_CONFIG_TYPES.ACTIONS.SAVE_FC_CONFIG, payload)

  },

  [SEARCH_CONFIGS_TYPES.ACTIONS.SAVE_FILTER_INTERNAL]({ dispatch, getters }, {searchKey, searchParameter}) {
    const userLevel = getters[FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_USER_LEVEL_DEFAULT]
    const current = getters[FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG]?.[FC_CONFIG.STORED_SEARCH_PARAMETER]?.[searchKey] || {}
    let nextContent = current?.content;
    try {
      if (typeof nextContent === 'string') {
        nextContent = JSON.parse(nextContent)
      }
    } catch (error) {
      // empty block
    }
    if (nextContent && Array.isArray(nextContent)) {
      nextContent.push(searchParameter)
    } else {
      nextContent = [searchParameter]
    }
    
    const payload = {
      userLevel: current?.userLevel || userLevel,
      configId: searchKey,
      configType: FC_CONFIG.STORED_SEARCH_PARAMETER,
      content: JSON.stringify(nextContent)
    }
    commit(SEARCH_CONFIGS_TYPES.MUTATIONS.SAVE_FILTER_INTERNAL, payload)
  },

  [SEARCH_CONFIGS_TYPES.ACTIONS.SAVE_FILTER_LIST]({ dispatch, getters }, { searchKey, searchParameters }) {
    const userLevel = getters[FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_USER_LEVEL_DEFAULT]
    const payload = {
      userLevel,
      configId: searchKey,
      configType: FC_CONFIG.STORED_SEARCH_PARAMETER,
      content: JSON.stringify(searchParameters)
    }
    dispatch(FC_CONFIG_TYPES.ACTIONS.SAVE_FC_CONFIG, payload)
  },

  async [SEARCH_CONFIGS_TYPES.ACTIONS.LOAD_FILTERS]({ dispatch }, searchKey) {
    await dispatch(FC_CONFIG_TYPES.ACTIONS.LOAD_FC_CONFIG, {
      configId: searchKey,
      configType: FC_CONFIG.STORED_SEARCH_PARAMETER
    });
  },

}