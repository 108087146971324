<template>
  <div class="vertical-stepper__container">
    <div class="vertical-stepper__list">
      <div class="vertical-stepper__item-container" v-for="(step, index) of steps" :key="index">
        <StepperItem :step="step" :currentStep="currentStep" :stepsLoading="stepsLoading"></StepperItem>

        <div class="vertical-stepper__pipe-container" v-if="index !== lastStepIndex">
          <span class="vertical-stepper__pipe"></span>
        </div>
      </div>
    </div>
    <BaseDropdownMenu class="vertical-stepper__dropdown" containerClass="vertical-stepper__dropdown-container" placement="bottom-right">
      <template #hook-target>
        <div class="vertical-stepper__dropdown-toogle">
          <span>{{ current.label }}</span>
          <span><ph-caret-down :size="24" /></span>
        </div>
      </template>
      <template #default>
        <div class="vertical-stepper__item-container" v-for="(step, index) of steps" :key="index">
          <StepperItem :step="step" :currentStep="currentStep" :stepsLoading="stepsLoading"></StepperItem>
        </div>
      </template>
    </BaseDropdownMenu>
  </div>
</template>

<script>
import { PhCaretDown } from 'phosphor-vue';

import StepperItem from './StepperItem.vue';
import BaseDropdownMenu from '@/components/core/BaseDropdownMenu.vue';


export default {
  props: {
    steps: {
      type: Array,
      default: () => [],
    },
    currentStep: {
      type: Object,
      default: () => {},
    },
    stepsLoading: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    current() {
      return this.steps?.find((step) => step?.path === this.currentStep?.path) || {};
    },
    lastStepIndex() {
      return this.steps?.length ? this.steps?.length - 1 : 0;
    },
  },
  components: {
    PhCaretDown,
    StepperItem,
    BaseDropdownMenu,
  },
}
</script>

<style scoped>
.vertical-stepper__item-container {
  display: flex;
  flex-direction: column;
  margin: -8px;
}

.vertical-stepper__item-container > * {
  margin: 8px;
}

.vertical-stepper__pipe-container {
  height: 24px;
  width: 16px;
  display: flex;
  justify-content: center;
  margin-top: 0px;
}
.vertical-stepper__pipe {
  border: 1px solid #cccccc;
}

.vertical-stepper__list {
  display: none;
}
.vertical-stepper__dropdown {
  display: none;
}

@media screen and (min-width: 768px) {
  .vertical-stepper__list {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .vertical-stepper__dropdown {
    display: block;
  }

  .vertical-stepper__dropdown-toogle {
    display: flex;
    justify-content: space-between;
  }
}
</style>

<style>
.vertical-stepper__dropdown-container {
  margin-left: 16px;
  width: calc(100% - 60px);
}
</style>
