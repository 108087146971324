var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.showHeaderCard
      ? _c(
          "div",
          {
            staticClass: "box__container",
            attrs: {
              tid: _vm._generateTidFromString("box__container_header_card")
            }
          },
          [
            _vm.title
              ? _c("div", { staticClass: "d-flex justify-content-between" }, [
                  _c(
                    "div",
                    [
                      _c("span", { staticClass: "box__title" }, [
                        _vm._v(_vm._s(_vm.title))
                      ]),
                      _vm.subtitle ? _c("span", [_vm._v(" | ")]) : _vm._e(),
                      _vm.subtitle
                        ? _c("span", {
                            domProps: {
                              innerHTML: _vm._s(_vm.sanitize(_vm.subtitle))
                            }
                          })
                        : _vm._e(),
                      _vm.editAction
                        ? _c(
                            "span",
                            {
                              staticClass: "ml-1 clickable",
                              attrs: {
                                tid: _vm._generateTidFromString(
                                  "edit_button_title_beratungsmappe"
                                )
                              },
                              on: { click: _vm.editAction }
                            },
                            [_c("ph-pencil-line", { attrs: { size: 16 } })],
                            1
                          )
                        : _vm._e(),
                      _vm.status ? _c("span", [_vm._v(" | ")]) : _vm._e(),
                      _vm.status
                        ? _c("Pill", {
                            attrs: { label: _vm.status, type: "danger" }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ])
              : _vm.$slots.title
              ? _c("div", [_vm._t("title")], 2)
              : _vm._e(),
            _vm.$slots.actionButtons
              ? _c("div", [
                  _c(
                    "div",
                    {
                      class: {
                        "layout__negative-margin--8":
                          _vm.actionButtonNegativeMargin
                      }
                    },
                    [_vm._t("actionButtons")],
                    2
                  )
                ])
              : _vm._e()
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }