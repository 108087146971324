var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", { attrs: { title: _vm.title } }),
      _c("BaseFilter", {
        ref: "filter",
        attrs: {
          title: _vm.title2 + "-Filter",
          filterId: "Abrechnungen",
          configFilter: _vm.filterConfig,
          metadata: _vm.filterOptionen,
          defaultOptions: _vm.defaultOptions,
          showSaveButton: "",
          immidiateSearch: false
        },
        on: { onFilter: _vm.onFilter, changeCombobox: _vm.chgComboFilter }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.rows.length
            ? _c("Table", {
                attrs: {
                  title: "Ausgewählte " + _vm.title2 + " exportieren",
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowsPerPage: 10,
                  headerActions: _vm.headerActions,
                  enableToggleHorizontalScrollingConfig:
                    _vm.item == "buchungspositionen",
                  exportConfig: _vm.exportConfig
                },
                on: {
                  "headerAction-SEND": function($event) {
                    return _vm.doAction("email")
                  },
                  "headerAction-DOWNLOAD": function($event) {
                    return _vm.doAction("download")
                  }
                },
                model: {
                  value: _vm.selected,
                  callback: function($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected"
                }
              })
            : _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", useBoxContainer: "", title: _vm.title2 }
              })
            : _c("NoData", {
                attrs: {
                  noIcon: "",
                  content: "Keine Buchungspositionen vorhanden"
                }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }