<template>
  <div>
    <PageHeaderTitleNavigation 
      :title="pageTitle" 
      :actions="headerActions" 
      @action-SAVE-CUSTOMER="createCustomer"
      @action-SAVE-PERSON="createPerson"
      @action-RESET="reset"
    />

    <div class="box__container" :tid="_generateTidFromString('box__container_Vorderseite_Ruckseite')">
      <div class="scans-container">
        <DragnDropArea @files="uploadImage($event, true)" hoverText="Vorderseite hier ablegen" :disabled="!!frontImage" class="scan-container">
          <span>Vorderseite <PhXCircle v-if="frontImage" @click="clearImage(true)" :size="20"/></span>
          <template v-if="frontImage">
            <img class="scans-image" :src="frontImage" ref="frontImage"/>
            <BaseButton isSecondary @click="openCroppingDialog(true)">
              <PhCrop :size="24"/> Zuschneiden
            </BaseButton>
            <BaseButton isSecondary @click="rotateRight(true)">
              <PhArrowArcRight :size="24"/> Drehen
            </BaseButton>
          </template>
          <div v-else class="scans-image">
            <BaseFileSelect class="scans-image-button" isSecondary  @files="uploadImage($event, true)">
              hochladen
            </BaseFileSelect>
            <FotoButton v-if="cameraAvailable" class="scans-image-button" buttonClass="foto-button" isSecondary edgeCorrection highQuality camera="back" @file="uploadImage([$event], true)">
              Fotografieren
            </FotoButton>
          </div>
        </DragnDropArea>
        <DragnDropArea @files="uploadImage($event, false)" hoverText="Rückseite hier ablegen" :disabled="!!backImage" class="scan-container">
          <span>Rückseite <PhXCircle v-if="backImage" @click="clearImage(false)" :size="20"/></span>
          <template v-if="backImage">
            <img class="scans-image" :src="backImage" ref="backImage"/>
            <BaseButton isSecondary @click="openCroppingDialog(false)">
              <PhCrop :size="24"/> Zuschneiden
            </BaseButton>
            <BaseButton isSecondary @click="rotateRight(false)">
              <PhArrowArcRight :size="24"/> Drehen
            </BaseButton>
          </template>
          <div v-else class="scans-image">
            <BaseFileSelect class="scans-image-button" isSecondary  @files="uploadImage($event, false)">
              hochladen
            </BaseFileSelect>
            <FotoButton v-if="cameraAvailable" class="scans-image-button" buttonClass="foto-button" isSecondary edgeCorrection highQuality camera="back" @file="uploadImage([$event], false)">
              Fotografieren
            </FotoButton>
          </div>
        </DragnDropArea>
      </div>
      <div class="box__title" v-if="frontImage || backImage">Bitte überprüfen Sie die ausgelesenen Daten!</div>
    </div>

    <ImageCroppingDialog v-if="imageToCrop" :src="imageToCrop" :detectedCorners="detectedCorners" @cancel="croppingFront=null" @file="uploadImage([$event], croppingFront)"/>

    <div class="row">
      <div class="col-lg-3 col-md-3 col-sm-12 col-12">
        <div class="box__container"  :tid="_generateTidFromString('box__container_VerticalStepper')">
          <VerticalStepper
            stepType="tab"
            :selectedStepKey="currentStep"
            :stepperService="stepper"
            @setStep="currentStep=$event"
          ></VerticalStepper>
        </div>
      </div>
      <div class="col-lg-9 col-md-9 col-sm-12 col-12">
        <div class="box__container" v-if="currentStep=='Stammdaten'">
          <FormHeader :label="currentStep"/>
          <ComboBox label="Anrede" 
              :values="genderList.length ? genderList : customerDataConfig.genders" 
              v-model="personalDataAddress.title" 
              validateUntouched
              firstEmpty
              tabindex="1" />
          <InputField label="Name" v-model="personalDataAddress.lastName"/>
          <InputField label="Vorname" v-model="personalDataAddress.firstName"/>
        </div>
        <div class="box__container" v-if="currentStep=='Adresse'">
          <FormHeader :label="currentStep"/>
          <InputField label="Straße" v-model="personalDataAddress.street"/>
          <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-12 col-12">
              <InputField label="Postleitzahl" v-model="personalDataAddress.zip"/>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-12 col-12">
              <InputField label="Ort" v-model="personalDataAddress.city"/>
            </div>
          </div>
          <ComboBox label="Land" v-model="personalDataAddress.country" :values="customerDataConfig.countries"/>
        </div>
        <div class="box__container" v-if="currentStep=='Geburtstag und Staatsangehörigkeit'">
          <FormHeader :label="currentStep"/>
          <DatePickerField label="Geburtsdatum" v-model="personalDataBirth.dayOfBirth" />
          <InputField label="Geburtsort" v-model="personalDataBirth.placeOfBirth"/>
          <ComboBox label="Staatsangehörigkeit" v-model="personalDataBirth.nationality" :values="customerDataConfig.nationalities"/>
          <InputField label="Geburtsname" v-model="personalDataBirth.nameAtBirth"/>
        </div>
        <div class="box__container" v-if="currentStep=='Legitimationsurkunde'">
          <FormHeader :label="currentStep"/>
          <ComboBox label="Art der Urkunde" v-model="legitimation.legitimType" :values="customerDataConfig.legitimationTypes"/>
          <InputField label="Ausweisnummer" v-model="legitimation.legitimNumber"/>
          <InputField label="Ausstellungsbehörde" v-model="legitimation.authority"/>
          <DatePickerField label="Ausstellungsdatum" v-model="legitimation.dateOfIssue" isValueAsString/>
          <DatePickerField label="Gültig bis" v-model="legitimation.validTo" isValueAsString/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import stammdatenMixin from '@/components/new-customer/stammdaten/stammdaten-mixin'
import CUSTOMER_SEARCH_TYPES from '@/store/customerSearch/types';
import CORE_TYPES from '@/store/core/types';
import LOG_TYPES from '@/store/log/types';
import CUSTOMERDATA_TYPES from '@/store/customerData/types';
import axios from 'axios';
import { mapGetters } from 'vuex'
import dayjs from 'dayjs';
import { buildMessage, } from '@/helpers/log-message-helper';
import {uploadFileChunked, fileFromUrlData, pdfToImage} from '@/helpers/upload-helper'
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputField from '@/components/core/forms/InputField.vue';
import FormHeader from '@/components/core/forms/FormHeader.vue';
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue'
import BaseFileSelect from '@/components/fileUpload/BaseFileSelect.vue';
import FotoButton from '@/components/fileUpload/FotoButton.vue';
import DragnDropArea from '@/components/fileUpload/DragnDropArea.vue';
import VerticalStepper from '@/components/vertical-stepper/VerticalStepper.vue';
import { PhXCircle, PhCrop, PhArrowArcRight } from 'phosphor-vue';
import { StepperMediator } from '@/components/stepper/utils';
import { PERSOENLICHEDATEN_HOME } from '@/views/customer/customer-utils';
import ImageCroppingDialog from '@/components/fileUpload/ImageCroppingDialog.vue';
import {vsSource, fsSource, loadShader, rotateImage90Clockwise} from '@/helpers/image-operation-helper';
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";

import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

const TYPE_CUSTOMER = 'CUSTOMER';
const TYPE_PERSON = 'PERSON';
const TYPE_VALIDS = [TYPE_CUSTOMER, TYPE_PERSON];


export default {
  mixins: [stammdatenMixin, InteractiveHelpCommonsMixin],
  props: {
    type: {
      type: String,
      default: TYPE_CUSTOMER,
      validator: (value) => {
        return TYPE_VALIDS.indexOf(value) >= 0;
      },
    },
  },
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    BaseButton,
    ComboBox,
    InputField,
    FormHeader,
    DatePickerField,
    BaseFileSelect,
    FotoButton,
    DragnDropArea,
    VerticalStepper,
    PhXCircle,
    PhCrop,
    PhArrowArcRight,
    ImageCroppingDialog,
  },
  data() {
    const stepper = new StepperMediator([
      {stepKey: "Stammdaten", label: "Stammdaten"},
      {stepKey: "Adresse", label: "Adresse"},
      {stepKey: "Geburtstag und Staatsangehörigkeit", label: "Geburtstag und Staatsangehörigkeit"},
      {stepKey: "Legitimationsurkunde", label: "Legitimationsurkunde"},
    ]);
    return {
      apiAddress: process.env.VUE_APP_API,
      frontImage: null,
      backImage: null,
      frontCorners: null,
      backCorners: null,
      vorderseite: null,
      rueckseite: null,
      stepper,
      currentStep: "Stammdaten",
      cameraAvailable: false,
      personalDataAddress: {},
      personalDataBirth: {},
      legitimation: {},
      loading: false,
      croppingFront: null,   // null if not cropping, true if cropping the front image, false if cropping the back image
      genderList: [],
    };
  },
  computed: {
    ...mapGetters({
      isFA: CORE_TYPES.GETTERS.IS_FA,
    }), 
    pageTitle() {
      switch(this.type) {
        case TYPE_CUSTOMER:
          return 'Neuer Kunde';
        case TYPE_PERSON:
          return 'Neuer Person';
      }
      return '';
    },
    imageToCrop() {
      if (this.croppingFront === null)
        return null;
      if (this.croppingFront)
        return this.frontImage;
      else
        return this.backImage;
    },
    detectedCorners() {
        if (this.croppingFront === null)
            return null;
        if (this.croppingFront)
            return this.frontCorners;
        else
            return this.backCorners;
    },
    isInvalid() {
      return this.loading || !this.vorderseite || (!this.rueckseite && !this.isFA);
    },
    isTypeCustomer() {
      return this.type === TYPE_CUSTOMER;
    },
    isTypePerson() {
      return this.type === TYPE_PERSON;
    },
    headerActions() {
      const { isInvalid, isTypeCustomer, isTypePerson, loading, } = this;
      return [
        PageHeaderSimpleAction('SAVE-CUSTOMER', 'Kunde anlegen').withDisabled(() => isInvalid).withVisible(() => isTypeCustomer),
        PageHeaderSimpleAction('SAVE-PERSON', 'Person anlegen').withDisabled(() => isInvalid).withVisible(() => isTypePerson),
        PageHeaderSimpleAction('RESET', 'Neue Erkennung').withDisabled(() => loading),
      ];
    },
  },
  mounted() {
    this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA_CONFIG);
    this.reset();
    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      navigator.mediaDevices.enumerateDevices()
      .then(devices => {
        this.cameraAvailable = devices.some(device => device.kind == 'videoinput');
      });
    }
  },
  methods: {
    reset() {
      this.frontImage = null;
      this.backImage = null;
      this.frontCorners = null;
      this.backCorners = null;
      this.vorderseite = null;
      this.rueckseite = null;
      this.currentStep = "Stammdaten";
      this.personalDataAddress = {
        country: "Deutschland",
      };
      this.personalDataBirth = {};
      this.legitimation = {};
      this.loading = false;
      this.genderList = [];
    },
    clearImage(isFront) {
      if (isFront) {
        this.frontImage = null;
        this.frontCorners = null;
        this.vorderseite = null;
      }
      else {
        this.backImage = null;
        this.backCorners = null;
        this.rueckseite = null;
      }
    },
    uploadImage(files, isFront) {
      this.croppingFront = null;
      if (files.length) {
        this.loading = true
        // display the selected file
        const reader = new FileReader();
        reader.onload = () => {
          if (isFront) {
            this.frontImage = reader.result;
            this.vorderseite = files[0];
          }
          else {
            this.backImage = reader.result;
            this.rueckseite = files[0];
          }
          // upload and scan the file
          uploadFileChunked(files[0]).then(id => {
            if (files[0].name && files[0].name.endsWith(".pdf")) {
              pdfToImage(id, files[0].name.substr(0, files[0].name.length - 4) + ".png")
              .then(file => {
                if (this.$refs[isFront ? 'frontImage' : 'backImage'].src == reader.result) {
                  this.uploadImage([file], isFront);
                }
              });
            }
            else {
              const config = {
                defaultSpinner: true,
                headers: {'Content-Type': 'application/json'},
              };
              axios.get(this.apiAddress + '/idcard/recognizeUploadedFile?tempFileId=' + id + '&front=' + isFront, config).then(response => {
                if (response && response.data) {
                  this.personalDataAddress = {
                    lastName: this.personalDataAddress.lastName || response.data.personalausweis.lastName,
                    firstName: this.personalDataAddress.firstName || response.data.personalausweis.firstName,
                    street: this.personalDataAddress.street || response.data.personalausweis.street,
                    zip: this.personalDataAddress.zip || response.data.personalausweis.zipCode,
                    city: this.personalDataAddress.city || response.data.personalausweis.city,
                    country: this.personalDataAddress.country || response.data.personalausweis.nationality, // TODO: where to get country?
                  }
                  const dateOfBirth = response.data.personalausweis.dateOfBirth;
                  this.personalDataBirth = {
                    dayOfBirth: this.personalDataBirth.dayOfBirth || (this.isTypePerson ? this.dateStringFromResponse(dateOfBirth) : this.dateFromResponse(dateOfBirth)),
                    nationality: this.personalDataBirth.nationality || (response.data.personalausweis.nationality || "").toLowerCase(),
                    nameAtBirth: this.personalDataBirth.nameAtBirth || response.data.personalausweis.birthName,
                    placeOfBirth: this.personalDataBirth.placeOfBirth || response.data.personalausweis.placeOfBirth,
                  }
                  this.legitimation = {
                    legitimType: this.legitimation.legitimType || "Personalausweis",
                    legitimNumber: !isFront && response.data.personalausweis.idNumber ? response.data.personalausweis.idNumber : this.legitimation.legitimNumber || response.data.personalausweis.idNumber,
                    authority: this.legitimation.authority || response.data.personalausweis.authority,
                    dateOfIssue: this.legitimation.dateOfIssue || this.dateStringFromResponse(response.data.personalausweis.date),
                    validTo: this.legitimation.validTo || this.dateStringFromResponse(response.data.personalausweis.validUntil),
                  }
                  if (response.data.rectangle && this.$refs[isFront ? 'frontImage' : 'backImage'].src == reader.result) {
                      const corners = [
                          {id: 0, ...response.data.rectangle.topLeft},
                          {id: 1, ...response.data.rectangle.topRight},
                          {id: 2, ...response.data.rectangle.bottomRight},
                          {id: 3, ...response.data.rectangle.bottomLeft},
                      ];
                      for (let i = 0; i < corners.length; i++) {
                          // corners are returned in pixel coordinates, but they need to be relative to the image's size
                          corners[i].x /= this.$refs[isFront ? 'frontImage' : 'backImage'].naturalWidth;
                          corners[i].y /= this.$refs[isFront ? 'frontImage' : 'backImage'].naturalHeight;
                      }
                      if (isFront)
                          this.frontCorners = corners;
                      else
                          this.backCorners = corners;
                  }
                  if (response.data.genderMap) {
                    this.genderList = Object.values(response.data.genderMap);
                    if (response.data.personalausweis.sex) {
                      const index = Object.keys(response.data.genderMap).indexOf(response.data.personalausweis.sex);
                      this.personalDataAddress.title = Object.values(response.data.genderMap)[index];
                    }
                  }
                }
              }).catch(error => {
                console.log(error);
                this.loading = false
                this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Bei der Analyse des hochgeladenen Bilds ist ein Fehler aufgetreten.', 'danger'));
              }).finally(() => this.loading = false)
            }
          })
        }
        reader.readAsDataURL(files[0]);
      }
    },
    dateStringFromResponse(date) {
      if (!date)
        return null;
      return dayjs(new Date(date.year, date.monthValue - 1, date.dayOfMonth)).format('DD.MM.YYYY')
    },
    dateFromResponse(date) {
      if (!date)
        return null;
      return new Date(date.year, date.monthValue - 1, date.dayOfMonth);
    },
    openCroppingDialog(isFront) {
      this.croppingFront = isFront;
    },
    rotateRight(isFront) {
      const image = this.$refs[isFront ? 'frontImage' : 'backImage'];
      const canvas = rotateImage90Clockwise(image);
      canvas.toBlob(blob => {
        this.uploadImage([blob], isFront);
      })
    },
    createCustomer() {
      this.loading = true;
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.SAVE_NEW_CUSTOMER_DATA, {
        personalDataAddress: this.personalDataAddress,
        personalDataBirth: this.personalDataBirth,
        legitimation: this.legitimation,
      })
      .then(customer => {
        this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.UPLOAD_LEGITIMATION_IMAGES, {
          personId: customer.personID,
          customerId: customer.customerID,
          vorderseite: this.vorderseite,
          rueckseite: this.rueckseite,
        })
        .then(() => {
          this.$store.commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.OPEN_CUSTOMER, {data: customer});
          this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { 
            customerId: customer.customerID,
            path: PERSOENLICHEDATEN_HOME
          });
        })
      })
      .catch((error) => {
        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Beim Speichern des Kunden ist ein unerwarteter Fehler aufgetreten.', 'danger'));
      })
      .finally(() => {
        this.reset();
      });
    },
    async createPerson() {
      this.loading = true;

      try {
        this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.ADD_CUSTOMER_DATA_EDITED, {
          personId: 'neue_person',
          personalDataAddress: Object.assign({}, this.personalDataAddress),
          personalDataBirth: Object.assign({}, this.personalDataBirth),
          legitimation: Object.assign({}, this.legitimation),
        });

        const personData = await this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.SAVE_CUSTOMER_DATA);
        await this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.UPLOAD_LEGITIMATION_IMAGES, {
          personId: personData?.data?.personId,
          vorderseite: this.vorderseite,
          rueckseite: this.rueckseite,
        });

        this.$router.push(`/persoenlichedaten/person-data/${personData?.data?.personId}`);
      } catch(e) {
        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Beim Speichern des Person ist ein unerwarteter Fehler aufgetreten.', 'danger'));
      } finally {
        this.reset();
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if(this.isTypePerson) {
      this.$updateCurrentBreadcrumb({
        breadcrumb: 'Person',
      });
    }

    next();
  },
}
</script>

<style>
.scans-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: center;
}
.scan-container {
  margin: 8px;
  display: flex;
  flex-direction: column;
}
.scans-image {
  width: 300px;
  height: 200px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
}
.scans-image-button {
  flex-grow: 1;
  margin: 0;
}
.foto-button {
  width: 100%;
  height: 100%;
}
</style>