<template>
  <div class="box__container">
    <SimpleAdditionalDocumentsTable ref="documentsTable" :nodeId="nodeId" :fileId="fileId" 
      :customDocuments="customDocuments" :customUpload="addDocument" :uploadInputsConfig="uploadInputsConfig"
      multipleFileUpload :isLoading="loading"
      @showDocument="showDocument"
      @deleteDocument="deleteDocument($event.fileId)"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs';

import { mapGetters } from 'vuex';
import OTHER_PRODUCTS_TYPES from '@/store/otherProducts/types';

import SimpleAdditionalDocumentsTable from '@/components/documents/SimpleAdditionalDocumentsTable.vue';

export default {
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      sprodId: OTHER_PRODUCTS_TYPES.GETTERS.SELECTED_SPROD_ID,
      combos: OTHER_PRODUCTS_TYPES.GETTERS.COMBOS,
      otherProduct: OTHER_PRODUCTS_TYPES.GETTERS.OTHER_PRODUCT,
      documents: OTHER_PRODUCTS_TYPES.GETTERS.DOCUMENTS,
    }),
    customDocuments() {
      return this.documents.map(d => ({
        ...d,
        name: d?.filename,
        bezeichnung: d?.description,
        bemerkung: d?.comment,
        erstellt_am: dayjs(d?.dateStand).format('DD.MM.YYYY'),
      }))
    },
    nodeId() {
      return this.combos?.nodeId || '';
    },
    fileId() {
      return this.combos?.fileId || '';
    },
    uploadInputsConfig() {
      return {
        hidden: ['input_sprod_id'],
      };
    },
  },
  methods: {
    async findDocuments() {
      await this.$store.dispatch(OTHER_PRODUCTS_TYPES.ACTIONS.FIND_DOCUMENTS);
    },
    async addDocument(params) {
      await this.$store.dispatch(OTHER_PRODUCTS_TYPES.ACTIONS.ADD_DOCUMENT, {
        tempFileId: params.id,
        formData: params.formData,
      });

      this.findDocuments();
    },
    showDocument(document) {
          this.$store.dispatch(OTHER_PRODUCTS_TYPES.ACTIONS.DOWNLOAD_DOCUMENT, document);
    },
    async deleteDocument(fileId) {
      await this.$store.dispatch(OTHER_PRODUCTS_TYPES.ACTIONS.DELETE_DOCUMENT, {
        fileId,
      });

      this.findDocuments();
    },
  },
  async mounted() {
    try {
      this.loading = true;
      await this.findDocuments();
    } finally {
      this.loading = false;
    }
  },
  components: {
    SimpleAdditionalDocumentsTable,
  },
}
</script>
