import dayjs from 'dayjs';

function isValidDate(date) {
  return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
}

export class DatePickerUtils {


  /** Get a Date from String */
  static getDateFromString(dayStr) {
    if (dayStr && dayStr.match(/^[0-9]{1,2}\.[0-9]{1,2}\.[0-9]{4}$/)){
      return dayjs(dayStr, "DD.MM.YYYY").toDate();
    } else if (dayjs(dayStr, "MMM D, YYYY hh:mm:ss A").isValid()){
      return dayjs(dayStr, "MMM D, YYYY hh:mm:ss A").toDate();
    } else {
      return dayjs(dayStr).toDate();
    }
  }

  static parseDate(date) {
    try {
      if (date) {
        const splitted = date.split('.');
        const year = Number(splitted[2]);
        const month = Number(splitted[1]) - 1;
        const day = Number(splitted[0]);
        return new Date(year, month, day);
      }
      return null;
    } catch (e) {
      return null;
    }
  }

  static safeDate(date) {
      return isValidDate(date) ? 
        date :
        (typeof date === 'string' && isValidDate(new Date(date))) ? new Date(date) : new Date();
  }

  static toDateStr(val) {
    if (typeof val?.getMonth === 'function') {
      return val.toLocaleDateString('de-DE',  { day: '2-digit', month: '2-digit', year: 'numeric' });
    } else {
      return val || '';
    }
  }

  static subtract(date, type, value) {
    if(!type || !value || !isValidDate(date)) return date;

    return dayjs(date).subtract(value, type).toDate();
  }
}
