import D_B from './types';

import axios from 'axios';
import { convertData } from '@/helpers/dbm';
export default {

  async [D_B.ACTIONS.DATA]({ commit, rootState, state }, e) {
    const config = {
      defaultSpinner: true,
    };
   
    let path=rootState.core.apiAddress + `/DbmService/getData`;
    path=path+'?type='+e.query;

    await axios.get(path).then(response => {

      convertData(response.data,D_B.MUTATIONS.DATA,commit);
      



    })
  },
  async [D_B.ACTIONS.SEGMENTS]({ commit, rootState, state }, e) {
    const config = {
      defaultSpinner: true,
    };
     
    let path=rootState.core.apiAddress + `/DbmService/getSegments`;
    path=path+'?tbs='+e.query;

    await axios.get(path).then(response => {

      convertData(response.data,D_B.MUTATIONS.DATA_SEGMENTS,commit);
      



    })
  },
  async [D_B.ACTIONS.BLOCKERS]({ commit, rootState, state }, e) {
    const config = {
      defaultSpinner: true,
    };


    commit(D_B.MUTATIONS.DATA, null);
    commit(D_B.MUTATIONS.SELECTED_NODE, null);
    await axios.get(rootState.core.apiAddress + '/DbmService/getlockers').then(response => {



      commit(D_B.MUTATIONS.DATA, response.data);
      


    })
  },
  async [D_B.ACTIONS.SESSION]({ commit, rootState, state }, e) {
    
    const config = {
      defaultSpinner: true,
    };


    commit(D_B.MUTATIONS.SESSION, null);
    let l = rootState.core.apiAddress + '/DbmService/getSession?sid=' + e.sid + '&serial=' + e.serial;
   
    await axios.get(l).then(response => {



      commit(D_B.MUTATIONS.SESSION, response.data);


    })
  },


  async [D_B.ACTIONS.KILL_SESSION]({ commit, rootState, state }, e) {
   
    const config = {
      defaultSpinner: true,
    };


    
    let l = rootState.core.apiAddress + '/DbmService/killSession?sid=' + e.sid + '&serial=' + e.serial;
   
    await axios.post(l).then(response => {



      commit(D_B.MUTATIONS.SESSION, response.data);


    })
  },

  async [D_B.ACTIONS.STAT_DATA]({ commit, rootState, state }, e) {
    const config = {
      defaultSpinner: true,
    };
    commit(D_B.MUTATIONS.STAT_DATA, null);
    let path=rootState.core.apiAddress + `/DbmService/getStat`;
    path=path+'?statistic='+e.query+"&minutes="+e.minutes;

    await axios.get(path).then(response => {

    commit(D_B.MUTATIONS.STAT_DATA, response.data);
      



    })
  }

}


