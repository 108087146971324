<template>
    <div>
        <BaseModal
            ref="modalError"
            modalTitle="Hinweis"
            labelButtonConfirm="Ok"
            labelButtonCancel="Änderungen verwerfen"
            @onCancelButton="confirmError"
            @onConfirmButton="resetError"
            @onCloseButton="resetError">

            <div class="mb-3">Diese Fehler müssen korrigiert werden um speichern zu können:</div>
            <span v-html="sanitize(errorText)"></span>
        </BaseModal>
    </div>
</template>

<script>
import {sanitize} from '@/helpers/string-helper.js';
import BaseModal from "@/components/core/BaseModal.vue";

export default {
    props: {
        errorText: {
            type: String,
            default: '',
        }
    },
    components: {
        BaseModal,
    },
    methods: {
        sanitize(htmlString) {
            return sanitize(htmlString);
        },
        open() {
            this.$refs.modalError.open();
        },
        confirmError() {
            this.$emit('closeLimit');
        },
        resetError() {
            this.$emit('resetError');
        },
    },
}
</script>

<style>

</style>