<template>
    <div>
      <div class="top__line" v-for="row in rowsComputed" v-bind:key="row.id">
        <div class="top__line-content" @click="onRowClick($event, row)">

          <div class="firstRow">
            <slot name="title" v-bind="row"></slot>
          </div>
  
          <div class="firstRow">
            <span :class="{ 'highlighted': row.shouldHighlight }">
              <slot name="value" v-bind="row"></slot>
              <button type="button" class="btn-clear d-block" :class="{'d-lg-none': !isMobileNativeContext}"  
                v-if="row.isClickable !== undefined ? row.isClickable && linkInFirstRow : linkInFirstRow">
                <PhCaretRight :size="20" />
              </button>
            </span>
          </div>

        </div>
      
        <div class="subject" v-if="showThirdRow">
          <div class="thirdRow">
            <slot name="thirdRow" v-bind="row"></slot>
          </div>            
        </div>      
      </div>
      <div class="weitere___link" v-if="pageSize">
        <a @click="navigateToCard()">Weitere...</a>
      </div>      
    </div>
</template>


<script>
import { mapGetters } from "vuex";
import CORE_TYPES from "@/store/core/types";
import { PhCaretRight } from 'phosphor-vue';
import BRIDGE_TYPES from '@/store/bridge/types';

export default {
  components: {
    PhCaretRight,
  },
  props: {
    rows: {
      type: Array,
      default: () => [],
      required: true,
    },
    pageSize: {
      type: Number,
      default: 0, // <= 0 means no limit
    },
    title: {
      type: String,
      default: "Übersicht",
    },
    showThirdRow: {
      type: Boolean,
      default: false,
    },    
    linkInFirstRow: {
      type: Boolean,
      default: false,
    },    
  },
  data: function () {
    return {};
  },
  computed: {
    ...mapGetters({
      isFA: CORE_TYPES.GETTERS.IS_FA,
      isFK: CORE_TYPES.GETTERS.IS_FK,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      isSmallScreen: CORE_TYPES.GETTERS.IS_SMALL_SCREEN,
      isMobileNativeContext: BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT,
    }),
    rowsComputed() {
      if (this.pageSize) {
        return this.rows.slice(0,this.pageSize);
      }
      return this.rows;
    },
  },
  methods: {
    navigateToCard(row) {
      this.$emit("onNavigate", row);
    },    
    onRowClick(event, whatRow) {
      if (this.linkInFirstRow) {
        if(whatRow.isClickable !== undefined && whatRow.isClickable) {
          this.$emit('onRowClick', {event, row: whatRow});
        } else {
          this.$emit('onRowClick', whatRow);
        }
      }
    }
  },
  mounted() {},
  validators: {},
};
</script>

<style scoped>
.highlighted {
  font-weight: bold;
}

.top__line {
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  padding: 4px 0 4px 0;
  border-bottom: 1px solid #c4c4c4;
}
.top__line:last-child {
  border-bottom: none;
}

.top__line-content {
  display: flex;
  width: 100%;
}

.top__line-content > div.firstRow {
  display:inline-block;
  justify-content: space-between;
  align-items: center;
}

.top__line-content > div.firstRow:nth-child(1) {
  flex: 1 1 auto;
  text-align: left;
  font-weight: 500;
}

.top__line-content > div.firstRow:nth-child(2) > span {
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  text-align: right;
  align-items: center;
}

.firstRow {
  word-break: keep-all;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.subject {
  overflow: hidden;
}

.subject > .thirdRow {
  display: inline-block;
  text-align: left;
  word-break: keep-all;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.btn-clear svg {
  color: var(--color-text)
}

.weitere___link {
    display: flex;
    justify-content: flex-end;
}

@media only screen and (max-width: 720px) {

/*   .top__line {
    border-bottom: none;
  } */

}
</style>