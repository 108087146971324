var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isContainerRendered
    ? _c(
        "CoreCard",
        {
          ref: "coreCard",
          attrs: {
            headers: _vm.headers,
            rows: _vm.rows,
            showToolbar: _vm.showToolbar,
            listView: _vm.listView
          },
          scopedSlots: _vm._u(
            [
              _vm.showTitle
                ? {
                    key: "header",
                    fn: function() {
                      return [_vm._v(" " + _vm._s(_vm.chartTitle) + " ")]
                    },
                    proxy: true
                  }
                : null,
              {
                key: "alternative",
                fn: function() {
                  return undefined
                },
                proxy: true
              }
            ],
            null,
            true
          )
        },
        [
          _c("div", { staticClass: "content" }, [
            _c(
              "div",
              [
                _c("Chart", {
                  attrs: {
                    data: _vm.chartData,
                    type: "donut",
                    isDataLabels: false,
                    isLegend: false,
                    isTooltip: false,
                    colors: _vm.customColors,
                    mixedColors: false
                  }
                }),
                _vm.chartData &&
                _vm.chartData.data &&
                _vm.chartData.data[0] &&
                _vm.chartData.data[1]
                  ? _c("div", { staticClass: "legend" }, [
                      !_vm.colorsGrey
                        ? _c(
                            "div",
                            {
                              staticClass: "legend-item",
                              style: { color: _vm.getColor(0) }
                            },
                            [
                              _vm._v(
                                " • " +
                                  _vm._s(_vm.chartData.data[0].label) +
                                  ": " +
                                  _vm._s(
                                    _vm.formatNumber(
                                      _vm.chartData.data[0].value
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e(),
                      !_vm.colorsGrey
                        ? _c(
                            "div",
                            {
                              staticClass: "legend-item",
                              style: { color: _vm.getColor(1) }
                            },
                            [
                              _vm._v(
                                " • " +
                                  _vm._s(_vm.chartData.data[1].label) +
                                  ": " +
                                  _vm._s(
                                    _vm.formatNumber(
                                      _vm.chartData.data[1].value
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.colorsGrey
                        ? _c(
                            "div",
                            {
                              staticClass: "legend-item",
                              style: { color: _vm.getColor(0) }
                            },
                            [
                              _vm._v(
                                " • " +
                                  _vm._s(_vm.keys[0]) +
                                  ": " +
                                  _vm._s(
                                    _vm.formatNumber(
                                      _vm.chartData.data[1].value
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e()
                    ])
                  : _vm._e()
              ],
              1
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }