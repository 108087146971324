<template>
  <div @click="del()" class="box spec_button partialeraser">CE</div>
</template>

<script>
export default {
  name: "PartialEraser",
  methods: {
    del: function() {
      var current = this.$store.getters.getCurrent;
      current = current.toString().slice(0, -1);
      this.$store.commit("SET_CURRENT", current);
    }
  }
};
</script>

<style scoped>
.partialeraser {
  grid-area: partialeraser;
}
</style>