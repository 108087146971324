import DYNAMIC_DOCUMENT_TYPES from './types';
import axios from 'axios';
import { makeQueryParam } from '@/helpers/utils-helper';

const config = {
  defaultSpinner: true
};

export const prepareParams = (payload) => Object.entries(payload).map(([key, val]) => `${key}=${val}`).join('&');

export default {
  [DYNAMIC_DOCUMENT_TYPES.ACTIONS.RETRIEVE_DYNAMIC_DOCUMENT]({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      const param = prepareParams(payload);
       
      let serviceUrl = '/dynamicdocuments/initializeDocument';
    
      axios.get(`${rootState.core.apiAddress}/${serviceUrl}?${param}`, config).then(response => {
        if (response.status === 200 && response.data) {
          commit(DYNAMIC_DOCUMENT_TYPES.MUTATIONS.RETRIEVE_DYNAMIC_DOCUMENT_SUCCESS, response.data);
          commit(DYNAMIC_DOCUMENT_TYPES.MUTATIONS.SET_DOCUMENT_HAS_CHANGED, false);
          resolve();
        } else {
          reject();
        }
      });
    });
  },

  [DYNAMIC_DOCUMENT_TYPES.ACTIONS.RETRIEVE_SELECTIONS]({ commit, rootState }) {
    return new Promise((resolve, reject) => {
       
      let serviceUrl = '/dynamicdocuments/initSelections';
    
      axios.get(`${rootState.core.apiAddress}/${serviceUrl}`, config).then(response => {
        if (response.status === 200 && response.data) {
          commit(DYNAMIC_DOCUMENT_TYPES.MUTATIONS.RETRIEVE_SELECTIONS_SUCCESS, {...response.data});
          resolve();
        } else {
          reject();
        }
      });
    });
  },

  [DYNAMIC_DOCUMENT_TYPES.ACTIONS.SAVE_DYNAMIC_DOCUMENT]({ commit, rootState }, payload) {
    let serviceUrl = '/dynamicdocuments/saveDynamicDocument';
  
    return axios.post(`${rootState.core.apiAddress}/${serviceUrl}`, payload, config).then(response => {
      if (response && response.data) {
        commit(DYNAMIC_DOCUMENT_TYPES.MUTATIONS.RETRIEVE_DYNAMIC_DOCUMENT_SUCCESS, response.data);
        commit(DYNAMIC_DOCUMENT_TYPES.MUTATIONS.SET_DOCUMENT_HAS_CHANGED, false);
      }
    })
  },

  [DYNAMIC_DOCUMENT_TYPES.ACTIONS.PDF_PREVIEW]({ rootState }, payload) {
    let serviceUrl = 'dynamicdocuments/generatePreview';

    return axios.post(`${rootState.core.apiAddress}/${serviceUrl}`, payload, config)
  },

  [DYNAMIC_DOCUMENT_TYPES.ACTIONS.RELOAD_DYNAMIC_DOCUMENT]({ rootState, state, commit }) {
    return new Promise((resolve, reject) => {
      const payload = {
        id: state.selectedDynamicDocument.id,
        componentMap: state.selectedDynamicDocument.componentMap,
      }
      let serviceUrl = '/dynamicdocuments/reload';
    
      axios.post(`${rootState.core.apiAddress}/${serviceUrl}`, payload, config).then(response => {
        if (response.status === 200) {
          commit(DYNAMIC_DOCUMENT_TYPES.MUTATIONS.RELOAD_DYNAMIC_DOCUMENT_SUCCESS, {...response.data});
          resolve();
        } else {
          reject();
        }
      });
    });
  },

  [DYNAMIC_DOCUMENT_TYPES.ACTIONS.RETRIEVE_PLACEHOLDERS]({ commit, rootState }, payload) {
    const config = {
        defaultSpinner: true,
        params: payload
    };

    let serviceUrl = '/dynamicdocuments/placeholders';
    axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
        commit(DYNAMIC_DOCUMENT_TYPES.MUTATIONS.RETRIEVE_PLACEHOLDERS_SUCCESS, response.data);
    });
},
}