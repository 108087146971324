import WERTPAPIERINFO_TYPES from './types';
import { isRolesIncludes } from '@/router/guards';
import { VIEW_ROLES } from '@/router/roles';


export default {
  [WERTPAPIERINFO_TYPES.GETTERS.FILTER](state) {
    return state.config.filter;
  },
  [WERTPAPIERINFO_TYPES.GETTERS.ACT]: (state) => (act) => {
    return state.sessions[act];
  },
  [WERTPAPIERINFO_TYPES.GETTERS.BASE_URL]() {
    if(isRolesIncludes([VIEW_ROLES.VIEW_BROKER, VIEW_ROLES.VIEW_CUSTOMER_ONLY])) {
      return '/beratung';
    } else if(isRolesIncludes([VIEW_ROLES.VIEW_CUSTOMER])) {
      return '/service';
    }
    return '';
  },
  [WERTPAPIERINFO_TYPES.GETTERS.GET_WATCHLIST](state) {
    return state.watchliste;
  },
}