var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "editable_link_container" }, [
        _vm.isBroker
          ? _c(
              "div",
              { staticClass: "edit", on: { click: _vm.edit } },
              [
                _c("ph-pencil-line", {
                  attrs: { title: "Bearbeiten", size: 16 }
                })
              ],
              1
            )
          : _vm._e(),
        _vm.isBroker ? _c("div", { staticClass: "innerContent" }) : _vm._e()
      ]),
      _c(
        "BaseModal",
        {
          ref: "EditSocialLinksModal",
          attrs: { modalTitle: "Sozial Links" },
          on: {
            onConfirmButton: function($event) {
              return _vm.onConfirmModal()
            },
            onCancelButton: function($event) {
              return _vm.onCancelModal()
            }
          }
        },
        [
          _c("div", { staticClass: "inner_modal" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(
                "Bitte ergänzen Sie Links Ihrer Sozialen Netzwerke mit kompletter URL."
              )
            ]),
            _c("br"),
            _c("div", { staticClass: "social" }, [
              _c("i", { staticClass: "lab la-facebook-f" }),
              _c("div", { staticClass: "social_title" }, [_vm._v("Facebook:")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.facebookValue,
                    expression: "facebookValue"
                  }
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.facebookValue },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.facebookValue = $event.target.value
                  }
                }
              })
            ]),
            _c("div", { staticClass: "social" }, [
              _c("i", { staticClass: "lab la-twitter" }),
              _c("div", { staticClass: "social_title" }, [_vm._v("Twitter:")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.twitterValue,
                    expression: "twitterValue"
                  }
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.twitterValue },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.twitterValue = $event.target.value
                  }
                }
              })
            ]),
            _c("div", { staticClass: "social" }, [
              _c("i", { staticClass: "lab la-xing" }),
              _c("div", { staticClass: "social_title" }, [_vm._v("Xing:")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.xingValue,
                    expression: "xingValue"
                  }
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.xingValue },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.xingValue = $event.target.value
                  }
                }
              })
            ]),
            _c("div", { staticClass: "social" }, [
              _c("i", { staticClass: "lab la-linkedin-in" }),
              _c("div", { staticClass: "social_title" }, [_vm._v("LinkedIn:")]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.linkedinValue,
                    expression: "linkedinValue"
                  }
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.linkedinValue },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.linkedinValue = $event.target.value
                  }
                }
              })
            ]),
            _c("div", { staticClass: "social" }, [
              _c("i", { staticClass: "lab la-instagram" }),
              _c("div", { staticClass: "social_title" }, [
                _vm._v("Instagram:")
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.instagramValue,
                    expression: "instagramValue"
                  }
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.instagramValue },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.instagramValue = $event.target.value
                  }
                }
              })
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }