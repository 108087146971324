<template>
  <BaseModal
    ref="modal"
    modalTitle="Einstellung Zugriff"
    @onConfirmButton="emitChanged()"
    @close="onClose()"
  >
    <template #default>
      <div>
        <div class="box__title">Kunde</div>

        <InputToggleSwitch label="Angemeldet als Kunde" inLineLabel v-model="configPermissionForm.context['KUNDE:KUNDE']" class="p-0" />
        <InputChips
          v-if="configPermissionForm.context['KUNDE:KUNDE']"
          :label="'Nur Erlaubte Kunden'"
          v-model="configPermissionForm.whitelist['KUNDE']"
          supportCustomValue
        />
        <InputChips
          v-if="configPermissionForm.context['KUNDE:KUNDE']"
          :label="'Nicht erlaubte Kunden'"
          v-model="configPermissionForm.blacklist['KUNDE']"
          supportCustomValue
        />
      </div>

      <hr/>

      <div>
        <div class="box__title">Makler</div>

        <InputToggleSwitch label="Angemeldet als Makler" inLineLabel v-model="configPermissionForm.context['MAKLER:MAKLER']" class="p-0" />
        <InputToggleSwitch label="Kundensicht, angemeldet als Makler" inLineLabel v-model="configPermissionForm.context['MAKLER:KUNDE']" class="p-0" />
        <InputChips
          v-if="configPermissionForm.context['MAKLER:MAKLER'] || configPermissionForm.context['MAKLER:KUNDE']"
          :label="'Nur Erlaubte Makler'"
          v-model="configPermissionForm.whitelist['MAKLER']"
          supportCustomValue
        />

        <InputChips
          v-if="configPermissionForm.context['MAKLER:MAKLER'] || configPermissionForm.context['MAKLER:KUNDE']"
          :label="'Nicht erlaubte Makler'"
          v-model="configPermissionForm.blacklist['MAKLER']"
          supportCustomValue
        />
      </div>

      <hr/>

      <div>
        <div class="box__title">Mitarbeiter</div>

        <InputToggleSwitch label="Angemeldet als Mitarbeiter" inLineLabel v-model="configPermissionForm.context['MAKLER~PERSONEN:MAKLER']" class="p-0" />
        <InputChips
          v-if="configPermissionForm.context['MAKLER~PERSONEN:MAKLER']"
          :label="'Nur Erlaubte Makler'"
          v-model="configPermissionForm.whitelist['MAKLER~PERSONEN']"
          supportCustomValue
        />
        <InputChips
          v-if="configPermissionForm.context['MAKLER~PERSONEN:MAKLER']"
          :label="'Nicht erlaubte Makler'"
          v-model="configPermissionForm.blacklist['MAKLER~PERSONEN']"
          supportCustomValue
        />
      </div>

      <hr/>

      <div v-if="showInternInputs">
        <div class="box__title">Intern</div>

        <InputToggleSwitch label="Angemeldet als Interner" inLineLabel v-model="configPermissionForm.context['INTERN:INTERN']" class="p-0" />
        <InputToggleSwitch label="Maklersicht, angemeldet als Interner" inLineLabel v-model="configPermissionForm.context['INTERN:MAKLER']" class="p-0" />
        <InputToggleSwitch label="Kundensicht, angemeldet als Interner" inLineLabel v-model="configPermissionForm.context['INTERN:KUNDE']" class="p-0" />
      </div>

      <!-- <pre>configPermissionForm={{ configPermissionForm }}</pre> -->
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/core/BaseModal.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import InputChips from '@/components/core/input-chips/InputChips.vue';

import { FC_CONFIG_USER_LEVEL } from '@/configs/fcConfig';
import { mapGetters } from "vuex";
import CORE_TYPES from "@/store/core/types";
import ROLES, { VIEW_ROLES } from '@/router/roles';

const MAP_CONTEXT_BASE_TO_KEY = {
  'MAKLER': 'maklernr',
  'MAKLER~PERSONEN': 'maklernr',
  'KUNDE': 'kundennr',
};

const MAP_LOCAL_CONTEXT_TO_FC_CONFIG_CONTEXT = {
  'MAKLER~PERSONEN:MAKLER': 'MAKLER:MAKLER',
};

const MAP_LOCAL_CONTEXT_TO_FC_CONFIG_USER_LEVEL = {
  'MAKLER~PERSONEN:MAKLER': FC_CONFIG_USER_LEVEL.MAKLER_MASTER_PERSONEN,
  'INTERN:INTERN': FC_CONFIG_USER_LEVEL.GLOBAL,
};

const MAP_LOCAL_USER_LEVEL_TO_FC_CONFIG_USER_LEVEL = {
  'MAKLER~PERSONEN': FC_CONFIG_USER_LEVEL.MAKLER_PERSONEN,
};

const MAP_FC_USER_LEVEL_TO_LOCAL_CONTEXT = {
  [FC_CONFIG_USER_LEVEL.MAKLER_MASTER_PERSONEN]: 'MAKLER~PERSONEN:MAKLER',
  [FC_CONFIG_USER_LEVEL.MAKLER_PERSONEN]: 'MAKLER~PERSONEN:MAKLER',
};

const MAP_FC_USER_LEVEL_TO_LOCAL_USER_LEVEL = {
  [FC_CONFIG_USER_LEVEL.MAKLER_MASTER_PERSONEN]: 'MAKLER~PERSONEN',
  [FC_CONFIG_USER_LEVEL.MAKLER_PERSONEN]: 'MAKLER~PERSONEN',
};

const PERMISSION_TYPE = ['BOTH', 'BLACKLIST', 'WHITELIST']

const MAKLER_MASTER_ID = {
  [ROLES.FK]: ['89000'],
  [ROLES.FA]: ['10000', '18000', '19000', '20000', '25000', '26000', '27000', '40000', '50000',],
}


function getNewConfigPermissionForm() {
  return {
    context: {},
    blacklist: {},
    whitelist: {},
  }
}

export default {
  components: {
    BaseModal,
    InputToggleSwitch,
    InputChips,
  },
  props: {
    allowedFCConfigType: {
      type: String,
    },
    deniedFCConfigType: {
      type: String,
    },
    permissionType: {
      type: String,
      default: 'BOTH',
      validator: (value) => PERMISSION_TYPE.includes(value),
    }
  },
  data() {
    return {
      configPermissionForm: getNewConfigPermissionForm(),
      configPermissionListOriginal: null,
    };
  },
  computed: {
    ...mapGetters({
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      defaultBrokerID: CORE_TYPES.GETTERS.GET_DEFAULT_BROKER_ID,
      isFK: CORE_TYPES.GETTERS.IS_FK,
      isFA: CORE_TYPES.GETTERS.IS_FA,
    }),
    showInternInputs() {
      return this.hasRoles(VIEW_ROLES.VIEW_INTERN)
    },
    isWhitelist() {
      return this.permissionType === 'WHITELIST';
    },
    isBlacklist() {
      return this.permissionType === 'BLACKLIST';
    },
    isBlackAndWhiteList() {
      return this.permissionType === 'BOTH';
    },
    maklerMasterIds() {
      if (this.isFK) {
        return MAKLER_MASTER_ID[ROLES.FK];
      } else if (this.isFA) {
        return MAKLER_MASTER_ID[ROLES.FA];
      } else {
        return [];
      }
    },
  },
  methods: {
    init(configPermissionList) {
      this.configPermissionListOriginal = [ ...configPermissionList, ];

      const hasMaklerStruktur = configPermissionList.findIndex(item => item.userLevel === FC_CONFIG_USER_LEVEL.MAKLER_MASTER_STRUKTUR) > -1;

      this.configPermissionForm = {
        context: {
          ...configPermissionList.reduce((acc, item) => ({
            ...acc,
            [MAP_FC_USER_LEVEL_TO_LOCAL_CONTEXT?.[item.userLevel] || item.configContext]: true,
          }), {}),
        },
        whitelist: {
          ...configPermissionList
            .filter(cp => cp.configType === this.allowedFCConfigType)
            .reduce((acc, item) => {
              const context = item.configContext;
              const base = MAP_FC_USER_LEVEL_TO_LOCAL_USER_LEVEL?.[item.userLevel] || context.split(':')[0];
              const value = item[MAP_CONTEXT_BASE_TO_KEY[base]];

              if (hasMaklerStruktur && value === this.defaultBrokerID && this.isFK) {
                return acc;
              }

              if(value && !acc[base]?.find(i => i.value === value)) {
                acc[base] ??= [];
                acc[base].push({
                  label: value,
                  value,
                });
              }

              return acc;
            }, {}),
        },
        blacklist: {
          ...configPermissionList
            .filter(cp => cp.configType === this.deniedFCConfigType)
            .reduce((acc, item) => {
              const context = item.configContext;
              const base = MAP_FC_USER_LEVEL_TO_LOCAL_USER_LEVEL?.[item.userLevel] || context.split(':')[0];
              const value = item[MAP_CONTEXT_BASE_TO_KEY[base]];

              if(value && !acc[base]?.find(i => i.value === value)) {
                acc[base] ??= [];
                acc[base].push({
                  label: value,
                  value,
                });
              }

              return acc;
            }, {}),
        }
      };
    },
    open(configPermissionList) {
      this.$refs.modal.open();

      this.$nextTick(() => this.init(configPermissionList));
    },
    close() {
      this.$refs.modal.close();
    },
    emitChanged() {
      const configPermissionList = this.generatePermissionList();

      this.$emit('changed', [ ...configPermissionList, ]);
    },
    generatePermissionList() {
      const { context, whitelist, blacklist } = this.configPermissionForm;

      const contexts = Object.keys(context)
        .filter(key => context[key]);

      const configPermissionList = contexts.reduce((permissions, context) => {
        const base = context.split(':')[0];

        this.createGlobalPermission(permissions, context, whitelist, blacklist, base);
        this.createWhitelistPermission(permissions, context, whitelist, base);
        this.createBlacklistPermission(permissions, context, blacklist, base);

        return permissions;
      }, []);

      return configPermissionList;
    },
    createGlobalPermission(permissions, context, whitelist, blacklist, base) {
      if (!whitelist?.[base]?.length) {
        // add global
        permissions.push({
          configType: this.allowedFCConfigType,
          configContext: MAP_LOCAL_CONTEXT_TO_FC_CONFIG_CONTEXT?.[context] || context,
          userLevel: MAP_LOCAL_CONTEXT_TO_FC_CONFIG_USER_LEVEL?.[context] || FC_CONFIG_USER_LEVEL.MAKLER_MASTER_STRUKTUR,
        })

        if (base === 'MAKLER') {
          // add also an entry for the default broker, so it's visible for itself, not only substructure
          const userLevel = context === 'INTERN:MAKLER' ? 'MAKLER' : base;

          permissions.push({
            [MAP_CONTEXT_BASE_TO_KEY['MAKLER']]: this.defaultBrokerID,
            configType: this.allowedFCConfigType,
            configContext: MAP_LOCAL_CONTEXT_TO_FC_CONFIG_CONTEXT?.[context] || context,
            userLevel,
          })
        }
      }
    },
    createWhitelistPermission(permissions, context, whitelist, base) {
      const specificWhitelist = whitelist?.[base]?.reduce((acc, item) => {
        acc.push({
          [MAP_CONTEXT_BASE_TO_KEY[base]]: item.value,
          configType: this.allowedFCConfigType,
          configContext: MAP_LOCAL_CONTEXT_TO_FC_CONFIG_CONTEXT?.[context] || context,
          userLevel: MAP_LOCAL_USER_LEVEL_TO_FC_CONFIG_USER_LEVEL?.[base] || base,
        })

        if (base === 'MAKLER' && this.maklerMasterIds.findIndex(masterId => masterId == item.value) > -1) {
          acc.push({
            [MAP_CONTEXT_BASE_TO_KEY[base]]: item.value,
            configType: this.allowedFCConfigType,
            configContext: MAP_LOCAL_CONTEXT_TO_FC_CONFIG_CONTEXT?.[context] || context,
            userLevel: FC_CONFIG_USER_LEVEL.MAKLER_STRUKTUR,
          })
        }

        return acc;
      }, []) || [];

      permissions.push(...specificWhitelist);
    },
    createBlacklistPermission(permissions, context, blacklist, base) {
      const specificBlacklist = blacklist?.[base]?.map(item => ({
        [MAP_CONTEXT_BASE_TO_KEY[base]]: item.value,
        configType: this.deniedFCConfigType,
        configContext: MAP_LOCAL_CONTEXT_TO_FC_CONFIG_CONTEXT?.[context] || context,
        userLevel: MAP_LOCAL_USER_LEVEL_TO_FC_CONFIG_USER_LEVEL?.[base] || base,
      })) || [];

      permissions.push(...specificBlacklist);
    },
    onClose() {
      this.reset();
    },
    reset() {
      this.configPermissionForm = getNewConfigPermissionForm();
      this.configDefinitionOriginal = null;
    },
  },
}
</script>
