var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("MultiBankingNavigation", { attrs: { subtitle: "Übersicht" } }),
      !_vm.isMobileNativeContext
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("div", { staticClass: "box__title" }, [_vm._v("Bankkonten")]),
              _c("KontoverwaltungActions")
            ],
            1
          )
        : _vm._e(),
      _vm.showErlaeuterung
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("BaseCollapsable", {
                attrs: { showSection: _vm.showExpanded },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function() {
                        return [
                          _c("span", { staticClass: "box__title" }, [
                            _vm._v(" Erläuterung ")
                          ])
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "content",
                      fn: function() {
                        return [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.sanitize(_vm.shareDataHelpText)
                              )
                            }
                          })
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  588147209
                )
              })
            ],
            1
          )
        : _vm._e(),
      _vm.isMultibankingDataAllowed
        ? _c(
            "div",
            [
              _vm.multibankingAccounts
                ? _c("KontoverwaltungAccountList", {
                    attrs: { showGhost: _vm.showGhost }
                  })
                : _vm._e()
            ],
            1
          )
        : _c("div", { staticClass: "box__container" }, [
            _c("div", { staticClass: "box__title" }, [
              _vm._v(" Achtung, Zugriff verweigert! ")
            ]),
            _vm._v(" Sie haben keinen Berechtigung um diese Daten zu sehen. ")
          ]),
      _c(
        "BaseModal",
        {
          ref: "errorModal",
          attrs: {
            showDefaultButtons: false,
            actions: _vm.baseModalActions,
            modalTitle: "Fehler"
          },
          on: {
            close: _vm.removeReentryParameters,
            "action-CLOSE": function($event) {
              return _vm.$refs.errorModal.close()
            }
          }
        },
        [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
      ),
      _c(
        "BaseModal",
        {
          ref: "transferSuccess",
          attrs: {
            showDefaultButtons: false,
            actions: _vm.baseModalActions,
            modalTitle: ""
          },
          on: {
            close: _vm.removeReentryParameters,
            "action-CLOSE": function($event) {
              return _vm.$refs.transferSuccess.close()
            }
          }
        },
        [_vm._v(" Ihre Überweisung wurde erfolgreich übermittelt ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }