var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DragnDropArea",
    {
      staticClass: "table-card",
      attrs: { hoverText: "Datei hier ablegen", disabled: !_vm.acceptsFile },
      on: {
        files: function($event) {
          return _vm.handleFile($event)
        }
      }
    },
    [
      !_vm.infoModal
        ? _c("div", { staticClass: "card-top" }, [
            _c("div", [_vm._t("__SELECT__")], 2),
            _c(
              "div",
              { staticClass: "sortable-handler--cell" },
              [_vm._t("_sortable_handler")],
              2
            ),
            _c("div", [_vm._t(_vm.actionHeaderKey)], 2)
          ])
        : _vm._e(),
      _c(
        "table",
        [
          _vm._l(
            _vm.loadingCollapsed ? _vm.topHeaders : _vm.allHeaders,
            function(ref) {
              var key = ref.key
              var hideOnModalSheet = ref.hideOnModalSheet
              return [
                !hideOnModalSheet && _vm.visibleInCard(key)
                  ? _c(
                      "TableCardRow",
                      { key: key, attrs: { headerKey: key, row: _vm.row } },
                      [
                        _vm._l(_vm.allHeaders, function(ref) {
                          var key = ref.key
                          return _c(
                            "template",
                            { slot: "header_" + key },
                            [_vm._t("header_" + key, null, { cardView: true })],
                            2
                          )
                        }),
                        _vm._l(_vm.allHeaders, function(ref) {
                          var key = ref.key
                          return _c("template", { slot: key }, [_vm._t(key)], 2)
                        })
                      ],
                      2
                    )
                  : _vm._e()
              ]
            }
          ),
          _vm.loadingCollapsed
            ? _c("GhostLoading", {
                attrs: {
                  type: "block",
                  config: {
                    block: { height: _vm.collapsedHeaders.length * 50 }
                  }
                }
              })
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }