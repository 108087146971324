<template>
    <InputField 
        @input="input($event)" 
        @change="$emit('change')" 
        @reset="$emit('reset')" 
        @blur="$emit('blur')"
        @onBlur="$emit('onBlur')" 
        @focus="$emit('focus')" 
        @searchAction="$emit('searchAction')" 
        @contentSelected="$emit('contentSelected')" 
        @ref="$emit('ref')" 
        @inputRawValue="$emit('inputRawValue')"
        :value="internalValue"
        :label="label"
        :labelClass="labelClass"
        :inputClass="inputClass"
        :validateUntouched="validateUntouched"
        :type="type"
        :placeholder="placeholder"
        :suppressValidationMessage="suppressValidationMessage"
        :id="id"
        :isEmbedded="isEmbedded"
        :disabled="disabled"
        :small="small"
        :precision="precision"
        :showConfirmationIcon="showConfirmationIcon"
        :isComponentHalfSize="isComponentHalfSize"
        :foreign_currency="foreign_currency"
        :isNotReactive="isNotReactive"
        :inputmode="inputmode"
        :autocomplete="autocomplete"
        :autofocus="autofocus"
        :isInteger="isInteger"
        :renderDanger="renderDanger"
        :allowPlainPassword="allowPlainPassword"
        :allowPasswordRules="allowPasswordRules"
        :maxLength="maxLength"
        ></InputField>
</template>

<script>
import { mapGetters } from "vuex";
import CORE_TYPES from '@/store/core/types';
import TEMPLATES_EDIT_TYPES from "@/store/templatesEdit/types";
import InputField from "@/components/core/forms/InputField.vue";
import { VIEW_ROLES, ROLES } from '@/router/roles';

export default {
    components: {
        InputField
    },
    props: {
        value: {
            type: [String, Number, Boolean]
        },
        label: {
            type: String
        },
        labelClass: {
            type: String,
        },
        inputClass: {
            type: Object,
            default: () => { },
        },
        validateUntouched: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'text'
        },
        placeholder: {
            type: String
        },
        suppressValidationMessage: {
            type: Boolean,
            default: false
        },
        id: {
            type: String,
            default: () => Math.random().toString(36).substr(2, 10)
        },
        isEmbedded: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false,
        },
        precision: {
            type: [String, Number]
        },
        showConfirmationIcon: {
            type: Boolean,
            default: false
        },
        isComponentHalfSize: {
            type: Boolean,
            default: false
        },
        foreign_currency: {
            type: String,
            default: ''
        },
        isNotReactive: {
            type: Boolean,
            default: false
        },
        inputmode: {
            type: String,
            default: null
        },
        autocomplete: {
            type: String,
            default: undefined,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
        isInteger: {
            type: Boolean,
            default: false,
        },
        renderDanger: {
            type: Boolean,
            default: false
        },
        allowPlainPassword: {
            type: Boolean,
            default: false,
        },
        allowPasswordRules: {
            type: Boolean,
            default: false,
        },
        maxLength: {
            type: Number,
            default: 524288 //html specification default value 
        }
    },
    data() {
        return {
            modifiedValue: undefined
        }
    },
    watch: {
        value(newValue) {
            this.internalValue = newValue
        },
    },
    mounted() {
        if(this.hasRoles([VIEW_ROLES.VIEW_BROKER, VIEW_ROLES.VIEW_INTERN, VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, ROLES.IS_MAKLERZUGANG])) {
            this.$store.dispatch(TEMPLATES_EDIT_TYPES.ACTIONS.GET_HTML_SHORTCUTS);
        }
    },
    computed: {
        ...mapGetters({
            htmlShortcuts: TEMPLATES_EDIT_TYPES.GETTERS.HTML_SHORTCUTS,
            hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
        }),
        internalValue: {
            get: function () {
                return this.isNotReactive ? (this.modifiedValue || this.value || '') : this.value ;
            },
            set: function (modifiedValue) {
                if(this.htmlShortcuts) {
                    this.htmlShortcuts?.forEach((shortcut) => {
                        let reg = new RegExp(`(^|\\s)${shortcut.key}\\s`, 'g')
                        let value = shortcut.value

                        if (value && modifiedValue) {
                            if (value.includes('<html')) { // Replace html content by innerText of html
                                value = this.extractContentFromHtml(value)
                            }
                                    
                            modifiedValue = modifiedValue.replace(reg, value)
                        }
                    })
                }

                this.$emit('input', modifiedValue);
                if (this.isNotReactive) {
                    this.modifiedValue = modifiedValue;
                }
            }
        },
    },
    methods: {
        extractContentFromHtml(html) {
            let span = document.createElement('span');
            span.innerHTML = html;
            let content = span.textContent || span.innerText
            span.remove();
            return content;
        },
        input(event) {
            this.modifiedValue = event
            this.$emit('input', event);
        }
    }
}
</script>