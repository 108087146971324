import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import V_P from './types';

/**
 * mutations: synchronous operation responsible for changing the state
 * actions: asynchronous operation responsible for calling external resources and commiting mutations 
 */

export function getInitialState() {
  return {
    SETUP:null,
    FILTER: {},
    COMMUNICATIONS:null  ,
    VP_BEMERKUNG:null,
    TRACE:null,
    QUELLE:null,
    LABEL:null,
    RELOAD:false,
    PAGES:null,
    EINGANG:null,
    BARCODES_COMBO:null,
    ERROR_EDIT:false,
    EDIT_GESEL_PAGE:null ,
    EDIT_GESEL_PAGE_CONFIG:null, 
    EDIT_KUNDEN_PAGE_CONFIG:null, 
    GESELL_KOMM_COMBO:null,
    PERSONEN_COMBO :null,
    PAGES_ACTIVE:false,
    MAPPE_COMBO:true,
    MAPPE_TABLE:true,
    rowCount: 0,
    lastFilterParams: {},
  }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}