var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("label", [_vm._v("Profilbild")]),
      _c("ImageUpload", {
        staticClass: "user-picture",
        attrs: {
          tid: _vm._generateTidFromString("user-picture"),
          image: _vm.userPicture,
          loading: _vm.loading,
          config: _vm.imageUploadConfig,
          disabled: _vm.loading,
          uploadButtonText: "Profilbild hochladen"
        },
        on: {
          file: function($event) {
            return _vm.uploadImage($event)
          },
          change: function($event) {
            return _vm.uploadImage($event)
          }
        }
      }),
      !_vm.isDefaultImage && _vm.isEditable
        ? _c(
            "div",
            { staticClass: "mt-16px mb-0" },
            [
              _c(
                "BaseButton",
                {
                  attrs: {
                    isSecondary: "",
                    disabled: _vm.loading,
                    tid: _vm._generateTidFromString("Profilbild löschen")
                  },
                  on: {
                    click: function($event) {
                      return _vm.deleteImage()
                    }
                  }
                },
                [_vm._v("Profilbild löschen")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }