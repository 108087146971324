<template>
  <div>
    <!-- if showDokArchiv must be enabled later, it also must be fixed. attachments + text dont work -->
    <MailComposer
      :custom="true"
      :customFunctions="customFunctions"
      :showExtendedActionButtons="false"
      :showDokArchiv="false"
      :additionalSendMailParams="additionalSendMailParams"
      @callbacks="setCallbacks"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MailComposer from "../MailComposer.vue";
import MAILCOMPOSER_TYPES from "@/store/mailcomposer/types";
import VUBLOCK_TYPES from "@/store/vuBlock/types";

export default {
  name: "VersUebertragBlockGesellMailComposer",
  components: {
    MailComposer,
  },

  props: {},

  data() {
    return {
      versUebertragBlockGesellId: null,
      linkedObject: null,

      callbacks: {},

      customFunctions: {
        pageTitle: () => {
          return "Bestandsübertragung E-Mail";
        },
      },
    };
  },

  methods: {
    setCallbacks(value) {
      this.callbacks = value;
    },
  },

  computed: {
    ...mapGetters({
      initVersUebertragBlockGesellMail:
        MAILCOMPOSER_TYPES.GETTERS.INIT_MAIL_VERS_UEBERTRAG_BLOCK_GESELL,
      savedConfiguration: MAILCOMPOSER_TYPES.GETTERS.GET_MAILCOMPOSER_CONFIG,
      sendMail: MAILCOMPOSER_TYPES.GETTERS.SEND_MAIL,
    }),

    additionalSendMailParams() {
      return {
        linkedObject: this.linkedObject,
      }
    },
  },

  watch: {
    initVersUebertragBlockGesellMail(value) {
      if (value) {
        if (!this.savedConfiguration.subject) {
          this.callbacks.setSubject(value.subject);
        }
        if (this.hasPasswort && !this.savedConfiguration.passwort) {
          this.callbacks.setPassword(value.passwort);
        }
        if (!this.savedConfiguration.getComputedText) {
          this.callbacks.setHtmlText(value.html);
        }
        if (value.attachments?.length) {
          this.$store.commit(
            MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS,
            value.attachments
          );
        }
        this.callbacks.setEmailModus(value.emailModus);
        this.linkedObject = value.linkedObject;

        let receiver = value.participants
          .filter((p) => p.sendType != "FROM")
          .map((p) => {
            return {
              value: `${p.email}`,
              label: `${p.email}`,
              email: `${p.email}`,
              type: p?.user?.type,
            };
          });
        this.callbacks.setReceiver(receiver);
      }
    },

    sendMail() {
      if (this.sendMail?.status == "OK" && !this.sendMail?.testMail) {
        this.$store.dispatch(
          VUBLOCK_TYPES.ACTIONS.VERS_UEBERTRAG_BLOCK_GESELL_MAIL_IS_SENT,
          { vubGesellId: this.versUebertragBlockGesellId }
        );
      }
    },
  },

  async mounted() {
    if (this.$route.meta.versUebertragBlockGesell) {
      this.versUebertragBlockGesellId = this.$route.params.id + "";
      this.$store.dispatch(
        MAILCOMPOSER_TYPES.ACTIONS.INIT_MAIL_VERS_UEBERTRAG_BLOCK_GESELL,
        { vubGesellId: this.versUebertragBlockGesellId }
      );
    }
  },

  validators: {},

  beforeRouteLeave(to, from, next) {
    this.callbacks.handleBeforeRouteLeave(to, from, next);
  },
};
</script>

<style scoped>
</style>
