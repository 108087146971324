<template>
  <div class="option-menu-config__container">
    <div v-if="hasLockedMenuItem" class="option-menu-config--locked mb-3">
      <div class="font-bold">fixiert</div>
      <OptionMenuConfigList 
        class="locked-menus from-struktur"
        :configId="configId"
        :menus="configuredMenuFromStruktur" 
        :userLevel="userLevel"
        :configContext="configContext"
        :isPermissionConfigurable="isPermissionConfigurable"
        :configuredMenuIndeterminate="configuredMenuIndeterminate"
        :isExpandedLayout="isExpandedLayout"
      />
      <OptionMenuConfigList 
        class="locked-menus"
        :configId="configId"
        :menus="configuredMenuLocked"
        :userLevel="userLevel"
        :configContext="configContext"
        :isPermissionConfigurable="isPermissionConfigurable"
        :configuredMenuIndeterminate="configuredMenuIndeterminate"
        :isLockable="isLockable"
        isSortable 
        :isExpandedLayout="isExpandedLayout"
        @unlock="onUnlock($event)"
        @orderChanged="onConfiguredMenuLockedOrderChanged($event)"
      />
    </div>

    <div class="option-menu-config--configurable">
      <div v-if="hasLockedMenuItem" class="font-bold">Konfigurierbare</div>
      <OptionMenuConfigList 
        v-if="hasConfigurableMenus"
        :configId="configId"
        :menus="configuredMenuConfigurable"
        :userLevel="userLevel"
        :configContext="configContext"
        :isPermissionConfigurable="isPermissionConfigurable"
        :configuredMenuIndeterminate="configuredMenuIndeterminate"
        isRemoveable
        :isToggleRemoveable="isToggleRemoveable"
        :isLockable="isLockable"
        isSortable 
        :isExpandedLayout="isExpandedLayout"
        @toggleRemoveable="onToggleRemoveable($event)"
        @lock="onLock($event)"
        @remove="onRemove($event)"
        @orderChanged="onConfiguredMenuConfigurableOrderChanged($event)"
      />
      <NoData v-else noIcon content="Keine Daten" />
    </div>

    <div v-if="hasParentsMenu && canViewParentsMenu" class="mt-3 mb-3">
      <ComboBox  v-model="parentMenuPath" :values="parentMenuValues" />
    </div>

    <div v-if="optionMenuIntern && optionMenuIntern.length" class="option-menu-edit-modal__to-add__container">
      <h3 class="box__title">Auswahl</h3>

      <InputField v-model="filterText" placeholder="Suche" :key="parentMenuPath" class="p-0" />

      <template v-if="optionMenuFiltered.length">
        <div v-for="(key, index) in optionMenuGroupedKeysSorted" :key="`${parentMenuPath}-${index}`" class="option-menu-edit-modal__group">
          <div class="option-menu-edit-modal__group--title" v-html="sanitize(key)" />
          <OptionMenuConfigList 
            :configId="configId"
            isAvailable
            :menus="optionMenuGrouped[key]" 
            :userLevel="userLevel" 
            :configContext="configContext"
            :configuredMenuIndeterminate="configuredMenuIndeterminate"
            hideParentLabel
            :isPermissionConfigurable="isPermissionConfigurable"
            :isExpandedLayout="isExpandedLayout"
            @add="onAdd($event)"
          />
        </div>
      </template>
      <NoData v-else noIcon />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CORE_TYPES from '@/store/core/types'
import MENU_CONFIG_TYPES from '@/store/menuConfig/types';
import { FC_CONFIG_USER_LEVEL } from '@/configs/fcConfig';

import OptionMenuConfigList from './optionMenuConfig/OptionMenuConfigList.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputField from '@/components/core/forms/InputField.vue';
import NoData from '@/components/core/NoData.vue';

import {sanitize} from '@/helpers/string-helper.js';
import { 
  sortByName,
  normalizeMenu,
  findConfiguredMenuActives,
  MAKLER_STRUKTUR_LEVELS,
} from './option-menu-utils';

const ALL_VALUE = '';
const CURRENT_MENU_LABEL = '(Aktueller Arbeitsbereich)';
const SPECIAL_CHAR_PATTERN = /&shy;/gi;

export default {
  name: 'OptionMenuConfig',
  props: {
    configId: {
      type: String,
      required: true,
    },
    defaultMenu: {
      type: Array,
      default: () => [],
      required: true,
    },
    optionMenu: {
      type: Array,
      default: () => [],
      required: true,
    },
    userLevel: {
      type: String,
      required: true,
    },
    configContext: {
      type: String,
      default: null,
    },
    currentPath: {
      type: String,
      default: null,
    },
    isPermissionConfigurable: {
      type: Boolean,
      default: false,
    },
    isExpandedLayout: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      configuredMenuLocked: undefined, // locked configured menu
      configuredMenuConfigurable: undefined, // configurable configured menu
      defaultMenuRemoved: [],

      parentMenuPath: '',
      filterText: '',
    };
  },
  computed: {
    ...mapGetters({
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      optionsMenuConfigFn: MENU_CONFIG_TYPES.GETTERS.OPTIONS_MENU_CONFIG,
      optionsMenuConfigEditedFn: MENU_CONFIG_TYPES.GETTERS.OPTIONS_MENU_CONFIG_EDITED,
      configuredMenuFromStrukturFn: MENU_CONFIG_TYPES.GETTERS.CONFIGURED_MENU_FROM_STRUKTUR,
      isConfiguredMenuLockedFn: MENU_CONFIG_TYPES.GETTERS.IS_CONFIGURED_MENU_LOCKED,
      isConfiguredMenuChangeableFn: MENU_CONFIG_TYPES.GETTERS.IS_CONFIGURED_MENU_CHANGEABLE,
      allOptionsMenuPermissionConfigAllMitarbeiter: MENU_CONFIG_TYPES.GETTERS.ALL_OPTIONS_MENU_PERMISSION_CONFIG_ALL_MITARBEITER,
      allOptionsMenuPermissionConfigAllUnterstruktur: MENU_CONFIG_TYPES.GETTERS.ALL_OPTIONS_MENU_PERMISSION_CONFIG_ALL_UNTERSTRUKTUR,
    }),
    optionMenuConfig() {
      return this.optionsMenuConfigFn(this.configContext, this.userLevel, this.configId);
    },
    optionsMenuConfigEdited() {
      return this.optionsMenuConfigEditedFn(this.configContext, this.userLevel, this.configId);
    },
    configuredMenuFromStruktur() {
      const { optionMenu, } = this;
      const configuredMenuFromStruktur = this.configuredMenuFromStrukturFn(this.configContext, this.userLevel, this.configId);
      return normalizeMenu(optionMenu, [], configuredMenuFromStruktur);
    },
    configuredMenu() {
      const { 
        optionMenu, 
        defaultMenu, 
        optionMenuConfig, 
        optionsMenuConfigEdited, 
      } = this;

      const configuredMenu = normalizeMenu(optionMenu, defaultMenu, optionMenuConfig);

      if(optionsMenuConfigEdited) {
        return [ ...optionsMenuConfigEdited, ];
      } else {
        return [ ...configuredMenu, ];
      }
    },
    optionMenuIntern() {
      const isMenuAlreadyAdded = (menus, menu) => menus.some(m => m.id == menu.id && m.path === menu.path && m.parent?.path === menu.parent?.path);
      const isMenuConfigured = (configuredMenu, menu) => configuredMenu?.some(cm => cm.id == menu.id && cm.path === menu.path);
      const isItself = (configId, menu) => configId === menu.id;

      const { parentMenuPath, configId } = this;

      const optionMenu = this.optionMenu
        ?.filter(menu => !!menu.parent) // only items with parent
        ?.filter(menu => parentMenuPath === ALL_VALUE || menu.parent?.path === parentMenuPath);
      const configuredMenuIntern = [ ...this.configuredMenuFromStruktur || [], ...this.configuredMenuLocked || [], ...this.configuredMenuConfigurable || [], ];

      const filteredOptionMenu = optionMenu.reduce((menusResult, menu) => {
        if(!isMenuConfigured(configuredMenuIntern, menu) && !isMenuAlreadyAdded(menusResult, menu) && !isItself(configId, menu)) {
          return [ ...menusResult, menu, ];
        }
        return menusResult;
      }, []);

      return filteredOptionMenu;
    },
    optionMenuFiltered() {
      const filterText = this.filterText?.trim();

      return this.optionMenuIntern
        ?.filter(menu => !filterText || menu?.label?.replace(SPECIAL_CHAR_PATTERN, '')?.toLowerCase()?.includes(filterText.toLowerCase()));
    },
    optionMenuGrouped() {
      const grouped = this.optionMenuFiltered
        ?.filter(menu => !!menu.parent?.label)
        ?.map(menu => {
          return {
            ...menu,
            parentsLabelText: menu?.parentsLabel?.join(' | '),
          };
        })
        ?.groupBy(menu => menu.parentsLabelText || '') || {};

      return { ...grouped, };
    },
    optionMenuGroupedKeysSorted() {
      return Object.keys(this.optionMenuGrouped)
        .map(item => ({ label: item, }))
        .sort(sortByName)
        .map(item => item.label);
    },
    currentParentMenuPath() {
      const { currentPath, } = this;
      return currentPath ? currentPath : ALL_VALUE;
    },
    parentsMenu() {
      return this.optionMenu
        .filter(menu => !!menu.hasSubMenu)
        .sort(sortByName);
    },
    hasParentsMenu() {
      return this.parentsMenu?.length > 0;
    },
    canViewParentsMenu() {
      if(this.hasRoles([this.ROLES.IS_KUNDENZUGANG]) || this.hasRoles([this.ROLES.IS_BYPASS_SLASH])) {
        return true;
      }
      return !this.hasRoles([this.VIEW_ROLES.VIEW_CUSTOMER_ONLY]);
    },
    hasLockedMenuItem() {
      return !!this.configuredMenuFromStruktur?.length || !!this.configuredMenuLocked?.length;
    },
    hasConfigurableMenus() {
      return !!this.configuredMenuConfigurable?.length;
    },
    isToggleRemoveable() {
      return MAKLER_STRUKTUR_LEVELS.includes(this.userLevel);
    },
    isLockable() {
      return MAKLER_STRUKTUR_LEVELS.includes(this.userLevel);
    },
    parentMenuValues() {
      const parentMenuValues = [...this.parentsMenu].map(pm => {
        const labels = [ ...pm?.parentsLabel || [], pm.label, ];
        const isCurrentMenu = this.currentParentMenuPath === pm.path;

        return {
          label: `${labels.join(' > ')} ${isCurrentMenu ? CURRENT_MENU_LABEL : ''}`.trim(),
          value: pm.path,
        };
      });
      return [
        {
          label: 'Alle',
          value: ALL_VALUE,
        },
        ...parentMenuValues,
      ];
    },
    configuredMenuIndeterminate() {
      const menuPermissionMap = {
        [FC_CONFIG_USER_LEVEL.MAKLER_PERSONEN]: this.allOptionsMenuPermissionConfigAllMitarbeiter,
        [FC_CONFIG_USER_LEVEL.MAKLER_STRUKTUR]: this.allOptionsMenuPermissionConfigAllUnterstruktur,
      }

      if (!(this.userLevel in menuPermissionMap)) {
        return {};
      }

      return this.configuredMenu.reduce((acc, menu) => {
        acc[menu.id] = menuPermissionMap[this.userLevel].some(item => item.content?.[menu.id]?.visible === false)
        return acc;
      }, {});
    },
  },
  watch: {
    configuredMenuLocked: {
      handler(_, old) {
        if(old !== undefined) {
          this.configChanged();
        }
      },
      deep: true,
    },
    configuredMenuConfigurable: {
      handler(_, old) {
        if(old !== undefined) {
          this.configChanged();
        }
      },
      deep: true,
    },
  },
  methods: {
    init() {
      if(this.hasParentsMenu) {
        this.parentMenuPath = this.currentParentMenuPath;
      }

      const configuredMenu = [ ...this.configuredMenu || [], ];

      // default menu removed
      const removedMenu = configuredMenu.filter((cm) => cm.removed === true);
      this.defaultMenuRemoved = removedMenu;

      // actives
      const configuredMenuActive = findConfiguredMenuActives(configuredMenu);

      // split configured menu into locked and configurable
      this.configuredMenuLocked = [ ...configuredMenuActive.filter(cm => this.isMenuLocked(cm.path) && this.isMenuChangeable(cm.path)), ];
      this.configuredMenuConfigurable = [ ...configuredMenuActive.filter(cm => !this.isMenuLocked(cm.path)), ];
      this.findAllOptionsMenuPermissionConfigMitarbeiter();
      this.findAllOptionsMenuPermissionConfigUnterstruktur();
    },
    sanitize(htmlString) {
      return sanitize(htmlString);
    },
    async findAllOptionsMenuPermissionConfigMitarbeiter() {
      if (this.userLevel !== FC_CONFIG_USER_LEVEL.MAKLER_PERSONEN) {
        return;
      }

      try {
        this.loading = true;

        const payload = {
          onlyWithConfig: false,
        };

        await this.$store.dispatch(MENU_CONFIG_TYPES.ACTIONS.FIND_ALL_OPTIONS_MENU_PERMISSION_CONFIG_ALL_MITARBEITER, payload);
      } finally {
        this.loading = false;
      }
    },
    async findAllOptionsMenuPermissionConfigUnterstruktur() {
      if (this.userLevel !== FC_CONFIG_USER_LEVEL.MAKLER_STRUKTUR) {
        return;
      }

      try {
        this.loading = true;

        const payload = {
          onlyWithConfig: false,
        };

        await this.$store.dispatch(MENU_CONFIG_TYPES.ACTIONS.FIND_ALL_OPTIONS_MENU_PERMISSION_CONFIG_ALL_UNTERSTRUKTUR, payload);
      } finally {
        this.loading = false;
      }
    },
    isDefault(menu) {
      return this.defaultMenu.some((dm) => dm.id == menu.id && dm.path === menu.path);
    },
    isMenuLocked(path) {
      return this.isConfiguredMenuLockedFn(this.configContext, this.userLevel, this.configId, path);
    },
    isMenuChangeable(path) {
      return this.isConfiguredMenuChangeableFn(this.configContext, this.userLevel, this.configId, path);
    },
    onToggleRemoveable(menu) {
      const checkConfiguredMenuLocked = () => {
        const menuIndex = this.configuredMenuLocked.findIndex((cm) => cm.id == menu.id && cm.path === menu.path);
        if(menuIndex < 0) return;

        const item = this.configuredMenuLocked[menuIndex];
        this.$set(this.configuredMenuLocked, menuIndex, {
          ...item,
          removeable: item.removeable === false ? true : false,
        });
      };

      const checkConfiguredMenuConfigurable = () => {
        const menuIndex = this.configuredMenuConfigurable.findIndex((cm) => cm.id == menu.id && cm.path === menu.path);
        if(menuIndex < 0) return;

        const item = this.configuredMenuConfigurable[menuIndex];
        this.$set(this.configuredMenuConfigurable, menuIndex, {
          ...item,
          removeable: item.removeable === false ? true : false,
        });
      };

      checkConfiguredMenuLocked();
      checkConfiguredMenuConfigurable();
    },
    onLock(menu) {
      const configuredMenuIndex = this.configuredMenuConfigurable.findIndex((cm) => cm.id == menu.id && cm.path === menu.path);
      if(configuredMenuIndex < 0) return;

      const item = this.configuredMenuConfigurable[configuredMenuIndex];
      this.configuredMenuConfigurable.splice(configuredMenuIndex, 1);

      this.configuredMenuLocked.push({
        ...item,
        locked: true,
      });
    },
    onUnlock(menu) {
      const configuredMenuIndex = this.configuredMenuLocked.findIndex((cm) => cm.id == menu.id && cm.path === menu.path);
      if(configuredMenuIndex < 0) return;

      const item = { ...this.configuredMenuLocked[configuredMenuIndex], };
      this.configuredMenuLocked.splice(configuredMenuIndex, 1);

      delete item.locked;
      this.configuredMenuConfigurable.unshift(item);
    },
    onRemove(menu) {
      delete menu.removeable;
      delete menu.locked;

      const configuredMenuConfigurableIndex = this.configuredMenuConfigurable.findIndex((cm) => cm.id == menu.id && cm.path === menu.path);
      if(configuredMenuConfigurableIndex >= 0) {
        this.configuredMenuConfigurable.splice(configuredMenuConfigurableIndex, 1);
      }

      const configuredMenuLockedIndex = this.configuredMenuLocked.findIndex((cm) => cm.id == menu.id && cm.path === menu.path);
      if(configuredMenuLockedIndex >= 0) {
        this.configuredMenuLocked.splice(configuredMenuLockedIndex, 1);
      }

      if(this.isDefault(menu)) {
        this.defaultMenuRemoved.push({
          ...menu,
          removed: true,
        });
      }
    },
    onAdd(menu) {
      if(!menu) return;

      this.configuredMenuConfigurable.push({ ...menu, });

      if(this.isDefault(menu)) {
        const menuIndex = this.defaultMenuRemoved.findIndex((dmr) => dmr.id == menu.id && dmr.path === menu.path);
        this.defaultMenuRemoved.splice(menuIndex, 1);
      }
    },
    onConfiguredMenuLockedOrderChanged(menus) {
      this.configuredMenuLocked = [ ...menus, ];
    },
    onConfiguredMenuConfigurableOrderChanged(menus) {
      this.configuredMenuConfigurable = [ ...menus, ];
    },
    configChanged() {
      const changedMenu = [ ...this.configuredMenuFromStruktur, ...this.configuredMenuLocked, ...this.configuredMenuConfigurable, ...this.defaultMenuRemoved, ];
      this.$store.commit(MENU_CONFIG_TYPES.MUTATIONS.SET_OPTIONS_MENU_CONFIG_EDITED, {
        configContext: this.configContext,
        userLevel: this.userLevel,
        configId: this.configId,
        changedMenu: [ ...changedMenu, ],
      });
    },
  },
  mounted() {
    this.$nextTick(this.init);
  },
  components: {
    OptionMenuConfigList,
    ComboBox,
    InputField,
    NoData,
  }
}
</script>

<style scoped>
.option-menu-edit-modal__group {
  margin: 16px 0 0;
}
.option-menu-edit-modal__group:first-child {
  margin-top: 0;
}
.option-menu-edit-modal__group--title {
  font-weight: bold;
  font-size: 0.75rem;
  margin: 0 0 4px;
}
.locked-menus.from-struktur + .locked-menus {
  margin-top: -1px;
}
.option-menu-edit-modal__to-add__container {
  margin: 16px 0 0;
}
</style>
