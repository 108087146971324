var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.mainHtmlText
    ? _c(
        "div",
        [
          _vm.isLoggedIn
            ? [
                _c("PageHeaderTitleNavigation", {
                  attrs: { title: _vm.pageTitle }
                })
              ]
            : _vm._e(),
          _c("GenericPrivacyTerms", {
            attrs: { info: _vm.info, contacts: _vm.contacts, form: _vm.form },
            on: {
              "open-link": function($event) {
                return _vm.openLink($event)
              },
              "terms-accepted": function($event) {
                return _vm.acceptTerms($event)
              },
              "terms-rejected": function($event) {
                return _vm.logoutAndRejectTerms()
              }
            }
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }