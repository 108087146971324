import CORE_TYPES from '@/store/core/types';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

function getInitialState() {
  return {
    subdepot: {id: 0},
    list: {loading: true},
  }
}

export default {
  state: {
    ...getInitialState()
  },
  getters: {
    ...getters
  },
  mutations: {
    ...mutations,
    [CORE_TYPES.MUTATIONS.RESET_STATE](state) { // logout
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    ...actions
  }
}