export const MUTATION_PREFIX = 'TAN: ';
export const ACTIONS_PREFIX = 'TAN_ACTIONS_';
export const GETTERS_PREFIX = 'TAN_GETTERS_';

export default {
  MUTATIONS: {
    SET_COMDIRECT_SESSION: MUTATION_PREFIX + 'SET_COMDIRECT_SESSION',    
    SET_COMDIRECT_TAN_REQUIRED: MUTATION_PREFIX + 'SET_COMDIRECT_TAN_REQUIRED',
  },
  ACTIONS: {
    GET_COMDIRECT_SESSION: ACTIONS_PREFIX + 'GET_COMDIRECT_SESSION',
    ACTIVATE_COMDIRECT_TAN: ACTIONS_PREFIX + 'ACTIVATE_COMDIRECT_TAN',       
  },
  GETTERS: {
    GET_COMDIRECT_SESSION: GETTERS_PREFIX + 'GET_COMDIRECT_SESSION',
    IS_COMDIRECT_TAN_REQUIRED: GETTERS_PREFIX + 'IS_COMDIRECT_TAN_REQUIRED',
  }
}
