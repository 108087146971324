<template>
  <div v-if="showIOSLink || showAndroidLink" class="download-app-info__container">
    <template v-if="hasErrors">
      <div v-for="err in sanitizedErrors" :key="err" class="mb-2">
        {{ err }}
      </div>
    </template>

    <div v-if="hasAppName" class="mb-0">
      <u>{{appName}} installieren</u>
    </div>

    <div class="app-links__container">
      <div v-if="showIOSLink" class="app-link__container">
        <a :href="urlIOS" title="Download über App Store (iOS)" target="_blank">
          <img src="img/app-store-button.svg" class="app-button"/>
        </a>
        <QRCode v-if="showQRCode" :text="urlIOS" class="hide-on-small-screen" />
      </div>

      <div v-if="showAndroidLink" class="app-link__container">
        <a :href="urlAndroid" title="Download über Google Play Store" target="_blank">
          <img src="img/play-store-button.png" class="app-button"/>
        </a>
        <QRCode v-if="showQRCode" :text="urlAndroid" class="hide-on-small-screen" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BRIDGE_TYPES from '@/store/bridge/types';

import QRCode from '@/components/core/QRCode.vue';

export default {
  components: {
    QRCode,
  },
  props: {
    appName: {
      type: String,
    },
    urlIOS: {
      type: String,
    },
    urlAndroid: {
      type: String,
    },
    errors: {
      type: Array,
      default: () => ([]),
    },
  },
  computed: {
    ...mapGetters({
      isMobileContextIOS: BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT_IOS,
      isMobileContextAndroid: BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT_ANDROID,
    }),
    sanitizedErrors() {
      return this.errors
        ?.map(err => err?.trim())
        ?.filter(err => !!err);
    },
    hasErrors() {
      return !!this.sanitizedErrors?.length;
    },
    hasAppName() {
      return !!this.appName?.trim();
    },
    showIOSLink() {
      return this.urlIOS && (this.isMobileContextIOS || !this.$isMobileNativeContext);
    },
    showAndroidLink() {
      return this.urlAndroid && (this.isMobileContextAndroid || !this.$isMobileNativeContext);
    },
    showQRCode() {
      return !this.$isMobileNativeContext;
    },
  },
}
</script>

<style scoped lang="scss">
.download-app-info__container {
  .app-links__container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .app-link__container {
    display: flex;
    align-items: center;
    flex-flow: column;
  }

  .app-button {
    width: auto;
    height: 48px;
    margin: 1rem .75rem;
  }
}

@media screen and (max-width: 767px) {
  .download-app-info__container {
    .app-links__container {
      flex-flow: column;
    }

    .app-link__container {
      align-items: flex-start;
    }

    .app-button {
      width: 150px;
      height: auto;
      margin: .75rem;
    }
  }
}
</style>
