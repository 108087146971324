var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { actions: _vm.headerActions, title: "Tarifgruppe" },
        on: { "action-CREATE_COURTAGESATZ": _vm.openEditCourtagesatzModal }
      }),
      _vm.loading.init
        ? _c("GhostLoading", { attrs: { useBoxContainer: "", type: "table" } })
        : [
            _c(
              "div",
              { staticClass: "box__container" },
              [
                _c("div", { staticClass: "box__title" }, [
                  _vm._v("Daten Tarifgruppe")
                ]),
                _c("InputField", {
                  attrs: { label: "Bezeichnung", isComponentHalfSize: "" },
                  model: {
                    value: _vm.tarifgruppe.bezeichnung,
                    callback: function($$v) {
                      _vm.$set(_vm.tarifgruppe, "bezeichnung", $$v)
                    },
                    expression: "tarifgruppe.bezeichnung"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "box__container" },
              [
                _c("Table", {
                  attrs: {
                    title:
                      "Hier können Sie pro Provisionsart einen Courtagesatz eintragen.",
                    rowId: "provartId",
                    headers: _vm.headers,
                    rows: _vm.rows,
                    rowsPerPage: 10
                  },
                  on: {
                    "click-provart": _vm.openEditCourtagesatzModal,
                    "action-DELETE": _vm.deleteCourtagesatz
                  }
                })
              ],
              1
            )
          ],
      _c(
        "BaseModal",
        {
          ref: "editCourtagesatzModal",
          attrs: {
            modalTitle: "Neuer Courtagesatz",
            confirmDisabled:
              !_vm.editCourtagesatz.provartId ||
              !_vm.editCourtagesatz.master_provsatz ||
              !_vm.editCourtagesatz.standard_provsatz ||
              !_vm.isProvartValid
          },
          on: { onConfirmButton: _vm.saveCourtagesatz }
        },
        [
          _c("ComboBox", {
            attrs: {
              label: "Provisionsart",
              isComponentHalfSize: "",
              values: _vm.provartValues,
              renderDanger: !_vm.editCourtagesatz.provartId
            },
            model: {
              value: _vm.editCourtagesatz.provartId,
              callback: function($$v) {
                _vm.$set(_vm.editCourtagesatz, "provartId", $$v)
              },
              expression: "editCourtagesatz.provartId"
            }
          }),
          _c("InputField", {
            attrs: {
              label: "Master Provsatz",
              isComponentHalfSize: "",
              type: "number",
              precision: 4,
              renderDanger: !_vm.editCourtagesatz.master_provsatz
            },
            model: {
              value: _vm.editCourtagesatz.master_provsatz,
              callback: function($$v) {
                _vm.$set(_vm.editCourtagesatz, "master_provsatz", $$v)
              },
              expression: "editCourtagesatz.master_provsatz"
            }
          }),
          _c("InputField", {
            attrs: {
              label: "Standard Provsatz",
              isComponentHalfSize: "",
              type: "number",
              precision: 4,
              renderDanger: !_vm.editCourtagesatz.standard_provsatz
            },
            model: {
              value: _vm.editCourtagesatz.standard_provsatz,
              callback: function($$v) {
                _vm.$set(_vm.editCourtagesatz, "standard_provsatz", $$v)
              },
              expression: "editCourtagesatz.standard_provsatz"
            }
          }),
          _c("InputField", {
            attrs: {
              label: "Max Provsatz",
              isComponentHalfSize: "",
              type: "number",
              precision: 4
            },
            model: {
              value: _vm.editCourtagesatz.max_provsatz,
              callback: function($$v) {
                _vm.$set(_vm.editCourtagesatz, "max_provsatz", $$v)
              },
              expression: "editCourtagesatz.max_provsatz"
            }
          }),
          !_vm.isProvartValid
            ? _c("div", { staticClass: "color-danger" }, [
                _vm._v(
                  " Es darf nur einen Courtagesatz pro Provisionsart geben! "
                )
              ])
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }