<template>
  <div class="input-forms__container">
    <div class="input-forms__label-container">
      <div class="input-forms__label-content" v-if="label" v-html="sanitize(label)"></div>
      <div class="input-forms__input-container" :class="{ 'forms__input--half-size': isComponentHalfSize }">
        <HtmlEditor 
          :isFontParams="isFontParams"
          :disabled="disabled"
          :value="value"
          @input="$emit('input', $event)"
        />
      </div>
      <template v-if="isValidationConfigured()">
        <div :key="validation.updated" v-if="!suppressValidationMessage && validation">
          <div class="fc-form-danger" 
            v-for="error in validation.getErrors(this.validationPath, this.validateUntouched)" 
            :key="error">
              {{ error }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import validatorComponentUtils from '@/mixins/validator/validator-component-utils'
import { sanitize } from '@/helpers/string-helper.js'
import HtmlEditor from "@/components/html-editor/HtmlEditor.vue"

export default {
  mixins: [validatorComponentUtils],
  components: {
    HtmlEditor
  },
  props: {
    label: {
    },
    value: {
      type: String,
      default: "",
    },
    isFontParams: {
      type: Boolean,
      default: true,
    },
    isComponentHalfSize: {
      type: Boolean,
      default: false
    },
    suppressValidationMessage: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    validateUntouched: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    sanitize(htmlString) {
      return sanitize(htmlString);
    },
  }
}
</script>
