<template>
    <div>
        <FlexibleList :rows="rows" showThirdRow :linkInFirstRow="isEditable" @onRowClick="onRowClick">
        
            <template #title="row">
                {{ row.bemerkung }}
            </template>

            <template #value="row">
                <CurrencyLabel :value="row.aktWert"/>
            </template>

            <template #thirdRow="row">
                {{ row.datum || "./." }}
            </template>      
        
        </FlexibleList>
    </div>   
</template>

<script>
import { mapGetters } from 'vuex';
import CREDIT_TYPES from '@/store/credit/types';


import FlexibleList from "@/components/flexibleList/FlexibleList.vue";
import CurrencyLabel from '@/components/core/CurrencyLabel.vue';



export default {
  data() {
    return {
      loading: false,
      update: {},
    };
  },
  computed: {
    ...mapGetters({
      updates: CREDIT_TYPES.GETTERS.UPDATES,
    }),
    rows() {
        return (this.updates || []).map(row => ({
            ...row,
        }));
    },
    isEditable() {
        return true;
    },
  },
  methods: {
    async findUpdates() {
      this.loading = true;
      await this.$store.dispatch(CREDIT_TYPES.ACTIONS.FIND_UPDATES);
      this.loading = false;
    },
    onRowClick(whatRow) {
      //alert(JSON.stringify(whatRow));
      // Edit window must be implemented for the broker perspective in the app view
    },  

  },
  mounted() {
    this.findUpdates();
  },
  components: {
    FlexibleList,
    CurrencyLabel,
  },
}
</script>