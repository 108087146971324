<template>
  <div class="stepper-item__container clickable" @click="$emit('click', $event)"
    :class="{ 'stepper-item--selected': isCurrent, 'stepper-item--disabled': disabled, }"
    @mouseover="handleMouseOver($event)" @mouseleave="handleMouseLeave($event)"
    :tid="_generateTidFromString(step.label)">
    <div class="stepper-item__icon">
      <VerticalStepIcon :stepperStatus="stepperStatus" :isCurrent="isCurrent" :collapsed="collapsed" />
    </div>
    <span class="vertical-stop__label" :class="{ 'vertical-stop__label--selected': isCurrent}">
      <InputField v-if="step.editing" :key="step.stepKey" 
        v-model="step.editing.label" class="vertical-stop__label--input p-0 m-0" autofocus 
        :showCleanButtonIcon="false" :showConfirmationIcon="false" 
        @change="$emit('stepEdited', { label: $event, stepKey: step.stepKey, })" 
        @onBlur="$emit('disablesEditStep', step.stepKey)" 
      />
      <template>
        <span v-show="!step.editing" class="vertical-stop__label--content">
          <span v-html="sanitize(step.label)"></span> <AnimatedSpinner v-if="showSaveSpinner"/>
        </span>
        <span v-show="!step.editing" class="vertical-stop__label--actions">
          <button v-if="isEditable" type="button" class="btn-clear clickable" 
            :disabled="disabled" @click.stop="$emit('enablesEditStep', step.stepKey)"><PhPencil :size="16" /></button>

          <button v-if="isRemovable" type="button" class="btn-clear clickable" 
            :disabled="disabled" @click.stop="$emit('removeStep', step)"><PhTrash :size="16" /></button>

          <button v-if="isSortable" type="button" class="btn-clear" 
            :disabled="disabled" data-sortable-item-handler><PhList :size="16" /></button>
        </span>
      </template>
    </span>
  </div>
</template>

<script>
import verticalStepperItemMixin from '@/components/vertical-stepper/vertical-stepper-item-mixin';

import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue'
import VerticalStepIcon from '@/components/vertical-stepper/VerticalStepIcon.vue'
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import {sanitize} from '@/helpers/string-helper.js';

import InputField from '@/components/core/forms/InputField.vue';

import { PhPencil, PhTrash, PhList, } from 'phosphor-vue';

export default {
  mixins: [verticalStepperItemMixin, InteractiveHelpCommonsMixin],
  props: {
    step: {
    },
    showSaveSpinner: {
      type: Boolean,
      default: false,
    },
    isCurrent: {
      type: Boolean,
      default: false,
    },
    stepType: {
      type: String,
      default: 'step',
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * actionsConfig
     * Example:
     * {
     *   '*': { // '*' means to extend to all steps
     *     editable: () => true, // return value
     *     removable: () => true, // return value
     *     sortable: () => true, // return value
     *   },
     *   '<step key>': {
     *     editable: () => true, // return value
     *     removable: () => true, // return value
     *     sortable: () => true, // return value
     *   },
     * }
     */
    actionsConfig: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      editing: false,
    };
  },
  computed: {
    stepperStatus() {
      if(this.stepType === 'tab') {
        if (this.step.warning) {
          return 'warning'
        }

        return 'default'
      }
      
      if (this.step.warning) {
        return 'warning'
      } else if (this.step.success) {
        return 'success'
      } else {
        return 'default'
      }
    },
    actionsConfigMerged() {
      return {
        ...(this.actionsConfig?.['*'] || {}),
        ...(this.actionsConfig?.[this.step.stepKey]) || {},
      };
    },
    isEditable() {
      const { actionsConfigMerged, } = this;
      return 'editable' in actionsConfigMerged ? actionsConfigMerged?.editable?.() : true;
    },
    isRemovable() {
      const { actionsConfigMerged, } = this;
      return 'removable' in actionsConfigMerged ? actionsConfigMerged?.removable?.() : true;
    },
    isSortable() {
      const { actionsConfigMerged, } = this;
      return 'sortable' in actionsConfigMerged ? actionsConfigMerged?.sortable?.() : true;
    },
  },
  methods: {
    sanitize(htmlString) {
        return sanitize(htmlString);
    },
  },
  components: {
    AnimatedSpinner,
    VerticalStepIcon,
    InputField,
    PhPencil,
    PhTrash,
    PhList,
  }
}
</script>

<style src='@/components/vertical-stepper/vertical-stepper.scss' lang='scss' scoped></style>

<style lang="scss" scoped>
.vertical-stop__label {
  display: flex;
  flex: 1 1 auto;

  .vertical-stop__label--input {
    flex: 1 1 auto;
  }

  .vertical-stop__label--content {
    flex: 1 1 auto;
    word-break: break-all;
  }

  .vertical-stop__label--actions {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    margin: 0 0 0 8px;

    button {
      margin: 0 0 0 4px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.stepper-item--disabled {
  cursor: not-allowed;

  .stepper-item__icon {
    opacity: 0.8;
  }

  .vertical-stop__label {
    .vertical-stop__label--content {
      opacity: 0.8;
    }
  }
}
</style>
