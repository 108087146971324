var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    {
      staticClass: "vertical-stepper-item__container clickable",
      attrs: { to: _vm.step.path }
    },
    [
      _c("ph-circle", {
        staticClass: "color-primary vertical-stepper-item__icon",
        attrs: { size: 16 }
      }),
      _c(
        "span",
        {
          staticClass: "vertical-stepper-item__label",
          class: { "font-bold": _vm.isCurrent }
        },
        [
          _vm._v(" " + _vm._s(_vm.step.label) + " "),
          _vm.isLoading ? _c("AnimatedSpinner") : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }