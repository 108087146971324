<template>
  <Tabs 
    v-if="tabList.length > 1" 
    :tabList="tabList" 
    :selected="selected" 
    :disabled="disabled" 
    @tabSelected="$emit('tabSelected', $event)" 
  />
</template>

<script>
import Tabs from '@/components/tabs/Tabs.vue';
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';

import { VIEW_ROLES, ROLES } from '@/router/roles';
import { FC_CONFIG_USER_LEVEL } from '@/configs/fcConfig.js'

export default {
  components: {
    Tabs,
  },
  props: {
    selected: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
    tabList() {
      const { hasRoles, } = this;

      const tabs = [];

      if(hasRoles([[VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS], [ROLES.IS_KUNDENZUGANG], [VIEW_ROLES.VIEW_CUSTOMER, ROLES.IS_BYPASS_SLASH]])) {
        tabs.push({ key: FC_CONFIG_USER_LEVEL.KUNDE, label: 'Kunden' });
        tabs.push({ key: FC_CONFIG_USER_LEVEL.MAKLER, label: 'alle Kunden' });
      }

      return [ ...tabs, ];
    },
  },
}
</script>
