<template>
  <div class="box__container">
    <p>
      Die Definition der "Angaben über die Risikoneigung" im Anlegerprofil können hier, auch für Ihre gesamte Vermittlerstruktur verändert werden. 
      Bei diesen "Angaben über die Risikoneigung" hat der Kunde die Auswahl zwischen fünf verschiedenen Risikostufen (Stufe 1 = niedrigstes Risiko; Stufe 5 = höchstes Risiko). 
      Verändert werden können die Bezeichnung der Risikostufe und die Beschreibung (Vorsicht: die vorgegebene Bezeichnungen und Beschreibungen sind juristisch geprüft. 
      Eine Veränderung kann zu höherer Haftung führen!) sowie die Einschränkungen in der Depotzusammensetzung je nach Risikostufe. 
      Diese wird dann auch durch das System sowohl in der Beratungsmappe als auch laufend überprüft.
    </p>

    <template v-if="isChangeAllowed">
      <InputToggleSwitch label="Eigene Einstellungen treffen. Diese gelten als Vorgabe für Ihre Unterstruktur." 
        inLineLabel v-model="form.hasConfig" @input="dataChanged(); copyVorgabeIntoEinstellung($event);" />

      <InputRadioBoxGroup :values="unterstrukturAenderungValues" v-model="form.unterstrukturDarfAendern" 
        :disabled="!hasConfig" @input="dataChanged()" />
    </template>
  </div>
</template>

<script>
import RISIKOKLASSEN_DEFINITION_TYPES from '@/store/risikoklassenDefinition/types';

import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';

import risikoklassenDefinitionStepMixin from './../risikoklassen-definition-step-mixin';

export default {
  mixins: [risikoklassenDefinitionStepMixin],
  data() {
    return {
      form: {
        hasConfig: false,
        unterstrukturDarfAendern: null,
      },
    };
  },
  computed: {
    unterstrukturAenderungValues() {
      return [
        { label: 'Vermittler Ihrer Unterstruktur dürfen eigene Einstellungen vornehmen', value: 'Ja', },
        { label: 'Vermittler Ihrer Unterstruktur verwenden diese Einstellungen', value: 'Nein', },
      ];
    },
  },
  watch: {
    risikoklassenDefinitionData: {
      handler() {
        Object.keys(this.form).forEach(key => {
          this.$set(this.form, key, this.risikoklassenDefinitionData?.[key]);
        });
      },
      immediate: true,
    },
  },
  methods: {
    copyVorgabeIntoEinstellung(value) {
      if(!value) return false;

      this.$store.commit(RISIKOKLASSEN_DEFINITION_TYPES.MUTATIONS.COPY_VORGABE_INTO_EINSTELLUNG);
    },
  },
  components: {
    InputToggleSwitch,
    InputRadioBoxGroup,
  },
}
</script>
