<template>
  <BaseModal
    ref="modal"
    modalTitle="ExPost manuelle Kosten"
    :showDefaultButtons="false"
    @close="onClose"
  >
    <template #default>
      <div v-if="editableMessage" class="fc-alert fc-alert-danger">
        {{ editableMessage }}
      </div>

      <ComboBox
        label="Jahr"
        v-model="form.jahr"
        v-if="form.isEditable"
        :values="combos.jahr"
      />

      <InputField
        label="Jahr"
        v-model="form.jahr"
        v-if="!form.isEditable"
        :disabled="true"
      />

      <ComboBox
        label="Art"
        v-model="form.art"
        :values="combos.art"
        validateUntouched
      />

      <InputField
        label="Betrag (Euro)"
        type="currency"
        v-model="form.betrag"
        validateUntouched
      />

      <InputField
        label="Anteil Vermittler (Euro)"
        type="currency"
        v-model="form.betragMakler"
        v-if="isAnteilBeraterSichtbar"
      />

      <ComboBox
        label="Depotposition"
        v-model="form.begleitschein"
        :values="combos.bgsDaten"
        @change="updateDataFromDepotposition($event)"
      />

      <ComboBox
        label="Lagerstelle"
        v-model="form.lagerstelleId"
        :values="combos.lagerstellen"
        validateUntouched
        @change="resetDepotnummer()"
      />

      <ComboBox
        label="Depotnummer"
        v-model="form.depotnummer"
        :values="depotnummerValues"
        validateUntouched
      />
      <InputField
        v-show="isShowDepotnummerText"
        label=""
        type="text"
        v-model="form.depotnummerText"
        validateUntouched
      />

      <InputField label="ISIN" type="text" v-model="form.isin" />
    </template>

    <template #footer>
      <BaseButton isSecondary @click="close()">Abbrechen</BaseButton>
      <BaseButton
        isPrimary
        :disabled="isSaveDisabled"
        @click="save().then(() => close())"
        >Speichern</BaseButton
      >
    </template>
  </BaseModal>
</template>

<script>
import { mapGetters } from "vuex";
import EX_POST_MANUELLE_TYPES from "@/store/exPostManuelle/types";

import BaseModal from "@/components/core/BaseModal.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputField from "@/components/core/forms/InputField.vue";
import BaseButton from "@/components/core/BaseButton.vue";

import validator from "@/mixins/validator";
import { required } from "@/mixins/validator/rules";

const FORM_KEY = "form";
const VALUE_FREIE_EINGABE = "freie Eingabe:";

export default {
  mixins: [validator],
  data() {
    return {
      form: {},
    };
  },
  computed: {
    ...mapGetters({
      combos: EX_POST_MANUELLE_TYPES.GETTERS.COMBOS,
    }),
    editableMessage() {
      return this.combos?.editableMessage || null;
    },
    depotnummerValues() {
      return this.combos?.depotnummer?.[this.form.lagerstelleId] || [];
    },
    isShowDepotnummerText() {
      return this.form?.depotnummer === VALUE_FREIE_EINGABE;
    },
    isFormInvalid() {
      return (
        !this.form?.art ||
        !(this.form?.betrag > 0) ||
        !this.form?.lagerstelleId ||
        !this.form?.depotnummer ||
        (this.isShowDepotnummerText && !this.form?.depotnummerText) ||
        (!!this.form?.betragMakler &&
          !!this.form?.betrag &&
          this.form?.betragMakler > this.form.betrag)
      );
    },
    isSaveDisabled() {
      return !!this.editableMessage || this.isFormInvalid;
    },
    isAnteilBeraterSichtbar() {
      if (this.combos?.artenOhneAnteilBerater?.length) {
        return !this.combos.artenOhneAnteilBerater.includes(this.form.art);
      } else {
        return true;
      }
    },
  },
  watch: {
    depotnummerValues: {
      handler() {
        this.configureDepotnummer();
      },
      immediate: true,
    },
  },
  methods: {
    open(exPostManuelle) {
      if (exPostManuelle) {
        this.form = { ...exPostManuelle };
      } else {
        this.reset();
        this.form.isEditable = true;
      }

      this.findCombos();
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    onClose() {
      this.reset();
    },
    reset() {
      this.form = {
        id: null,
        jahr: null,
        art: null,
        betrag: null,
        betragMakler: null,
        begleitschein: null,
        lagerstelleId: null,
        depotnummer: null,
        depotnummerText: null,
        isin: null,
        isEditable: null,
      };

      Object.keys(this.form).forEach((key) =>
        this.validation.reset(`${FORM_KEY}.${key}`)
      );
    },
    findCombos() {
      this.$store.dispatch(EX_POST_MANUELLE_TYPES.ACTIONS.FIND_COMBOS);
    },
    configureDepotnummer() {
      if (!this.depotnummerValues?.length) return;

      const { depotnummer } = this.form;
      if (
        depotnummer &&
        !this.depotnummerValues.some((item) => item.value == depotnummer)
      ) {
        this.form.depotnummerText = this.form.depotnummer;
        this.form.depotnummer = VALUE_FREIE_EINGABE;
      }
    },
    updateDataFromDepotposition(event) {
      this.form.depotnummer = event.depotnr;
      this.form.lagerstelleId = event.lagerstelle;
      this.form.isin = event.isin;
    },
    resetDepotnummer() {
      this.form.depotnummer = null;
      this.form.depotnummerText = null;
    },
    save() {
      return this.$store.dispatch(EX_POST_MANUELLE_TYPES.ACTIONS.SAVE, {
        ...this.form,
      });
    },
  },
  validators: {
    form: {
      art: [required()],
      betrag: [required()],
      lagerstelleId: [required()],
      depotnummer: [required()],
      depotnummerText: [required()],
      isin: [required()],
    },
  },
  components: {
    BaseModal,
    ComboBox,
    InputField,
    BaseButton,
  },
};
</script>
