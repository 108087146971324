export const MUTATION_PREFIX = 'FAHRZEUGDATEN_MUTATIONS_';
export const ACTIONS_PREFIX = 'FAHRZEUGDATEN_ACTIONS_';
export const GETTERS_PREFIX = 'FAHRZEUGDATEN_GETTERS_';

export default {
  MUTATIONS: {
    FAHRZEUGDATEN_EDIT: MUTATION_PREFIX + 'FAHRZEUGDATEN_EDIT',
    CLEAN_FAHRZEUGDATEN_EDIT: MUTATION_PREFIX + 'CLEAN_FAHRZEUGDATEN_EDIT',
  },
  ACTIONS: {
    UPDATE_FAHRZEUGDATEN: ACTIONS_PREFIX + 'UPDATE_FAHRZEUGDATEN',
    CREATE_FAHRZEUGDATEN: ACTIONS_PREFIX + 'CREATE_FAHRZEUGDATEN',
  },
  GETTERS: {
    FAHRZEUGDATEN_EDIT: GETTERS_PREFIX + 'FAHRZEUGDATEN_EDIT',
    FAHRZEUGDATEN_INITIAL_STATE: GETTERS_PREFIX + 'FAHRZEUGDATEN_INITIAL_STATE',
  }
}
