<template>
  <div class="antrag-components__container">
    <div class="box__container">
      <FormLabel label="Bitte tragen Sie hier die Daten des Depots ein, von dem die Wertpapiere übertragen werden sollen."
        :config="{'bold': true}" />
    

      <div class="row">
        <div class="col-12">
          <FormLabel label="Daten der bisher depotführenden Gesellschaft" :config="{'bold': true}"/>
        </div>
        <InputField class="col-12 col-md-6" v-model="form.bisherManuellName" label="Name der Bank"
          @change="$emit('save', { bisherManuellName: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.bisherManuellBIC" label="BIC"
          @change="$emit('save', { bisherManuellBIC: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.bisherManuellStrasse" label="Straße"
          @change="$emit('save', { bisherManuellStrasse: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.bisherManuellPLZ" label="PLZ"
          @change="$emit('save', { bisherManuellPLZ: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.bisherManuellOrt" label="Ort"
          @change="$emit('save', { bisherManuellOrt: $event })"/>

        <div class="col-12">
          <FormLabel label="Daten des Depots bei der bisher depotführenden Gesellschaft" :config="{'bold': true}"/>
        </div>
        <InputField class="col-12" v-model="form.bisherDepotnr" label="Depotnummer"
          @change="$emit('save', { bisherDepotnr: $event })"/>
        <div class="col-12">
          <FormLabel label="1. Inhaber" :config="{'bold': true}"/>
        </div>
        <InputField class="col-12 col-md-6" v-model="form.bisherName1" label="Name"
          @change="$emit('save', { bisherName1: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.bisherVorname1" label="Vorname"
          @change="$emit('save', { bisherVorname1: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.bisherID1" label="TIN (Steuer-ID): Die TIN muss aus 10-11 oder 13 Ziffern bestehen."
          @change="$emit('save', { bisherID1: $event })"/>
        
        
        <div class="col-12">
          <FormLabel label="2. Inhaber" :config="{'bold': true}"/>
        </div>
        <InputField class="col-12 col-md-6" v-model="form.bisherName2" label="Name"
          @change="$emit('save', { bisherName2: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.bisherVorname2" label="Vorname"
          @change="$emit('save', { bisherVorname2: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.bisherID2" 
          label="TIN (Steuer-ID): Die TIN muss aus 10-11 oder 13 Ziffern bestehen."
          @change="$emit('save', { bisherID2: $event })"/>
      </div>

      <BaseButton @click="$emit('next')">Weiter</BaseButton>
    </div>
  </div>
</template>
<script>
import antragNavigationMixin from '@/mixins/antrag/antrag-navigation-mixin.js';
import FormLabel from '@/components/core/forms/FormLabel.vue';
import InputField from '@/components/core/forms/InputField.vue';
import BaseButton from '@/components/core/BaseButton.vue';

export default {
  mixins: [antragNavigationMixin],
  props: {
    positionData: {
    },
    warnings: {
    },
  },
  components: {
    FormLabel,
    InputField,
    BaseButton,
  },
  computed: {
  },
  data() {
    return {
      form: {}
    }
  },
  mounted() {
    this.form = Object.assign({}, this.positionData);
  },
  methods: {
  }
}
</script>
<style scoped>

</style>