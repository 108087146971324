var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: _vm.$appNavigation.currentMenu.label }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("div", { staticClass: "box__title" }, [
            _vm._v("PDF- und Excel-Dateien herunterladen mit PIN")
          ]),
          _vm.isBroker && _vm.pinExcel
            ? _c("InputToggleSwitch", {
                attrs: {
                  label:
                    "PIN-Abfrage bei Mitarbeitern beim Download von PDF- und Excel-Listen",
                  inLineLabel: ""
                },
                model: {
                  value: _vm.pinExcel,
                  callback: function($$v) {
                    _vm.pinExcel = $$v
                  },
                  expression: "pinExcel"
                }
              })
            : _vm._e(),
          _vm.loading
            ? [
                _c(
                  "GhostLoading",
                  [_c("Block", { attrs: { height: "300" } })],
                  1
                )
              ]
            : _vm.pinExcel == "Ja" || _vm.isIntern
            ? [
                _vm.rows.length
                  ? [
                      _c("Table", {
                        attrs: {
                          headers: _vm.headers,
                          rows: _vm.rows,
                          rowId: "pin",
                          rowsPerPage: 10
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "pin",
                              fn: function(ref) {
                                var pin = ref.pin
                                return [
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.viewFile(pin)
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(pin[0]))]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1890755060
                        )
                      })
                    ]
                  : _c("NoData", { attrs: { noIcon: "" } })
              ]
            : _vm._e()
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("div", { staticClass: "box__title" }, [
            _vm._v("Tabellen anzeigen mit PIN")
          ]),
          _vm.isBroker && _vm.pinTable
            ? _c("InputToggleSwitch", {
                attrs: {
                  label:
                    "PIN-Abfrage bei Mitarbeitern beim Anzeigen von Tabellen",
                  inLineLabel: ""
                },
                model: {
                  value: _vm.pinTable,
                  callback: function($$v) {
                    _vm.pinTable = $$v
                  },
                  expression: "pinTable"
                }
              })
            : _vm._e(),
          [
            _vm.isBroker && _vm.sizeTable
              ? _c("ComboBox", {
                  staticStyle: { "max-width": "500px" },
                  attrs: {
                    label: _vm.labelSizeTable,
                    values: _vm.sizeTableOptionen
                  },
                  on: { input: _vm.onChangeSizeTable },
                  model: {
                    value: _vm.sizeTable,
                    callback: function($$v) {
                      _vm.sizeTable = $$v
                    },
                    expression: "sizeTable"
                  }
                })
              : _vm._e()
          ],
          _vm.loading
            ? [
                _c(
                  "GhostLoading",
                  [_c("Block", { attrs: { height: "300" } })],
                  1
                )
              ]
            : _vm.pinTable == "Ja" || _vm.isIntern
            ? [
                _vm.rechte.length
                  ? [
                      _c("Table", {
                        attrs: {
                          headers: _vm.headers2,
                          rows: _vm.rechte,
                          rowId: "pin",
                          rowsPerPage: 10
                        },
                        on: { "action-UNLOCK": _vm.unlock }
                      })
                    ]
                  : _c("NoData", { attrs: { noIcon: "" } })
              ]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }