var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.isLoaded
    ? _c(
        "div",
        { staticClass: "event-body" },
        [
          _c(
            "BaseModal",
            {
              ref: "modal",
              attrs: {
                labelButtonCancel: "Schließen",
                autoClose: false,
                showConfirmButton: _vm.showConf(),
                showCancelButton: !this.saving,
                showCloseButton: !this.saving,
                size: "md"
              },
              on: {
                onCancelButton: _vm.close,
                onConfirmButton: function($event) {
                  return _vm.saveCode()
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "modalTitle",
                    fn: function() {
                      return undefined
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                1569299778
              )
            },
            [
              _c("BoxContainer", [
                _c(
                  "div",
                  [
                    _c("DownloadLink", {
                      attrs: {
                        target: "_blank",
                        rel: "noopener noreferer",
                        title: _vm.linkTitle,
                        downloadServicePath: "/pages_download",
                        queryParams: {
                          eingangId: this.id,
                          pages: this.pages
                        }
                      }
                    }),
                    _c("InputField", {
                      attrs: { disabled: this.saving },
                      model: {
                        value: _vm.code,
                        callback: function($$v) {
                          _vm.code = $$v
                        },
                        expression: "code"
                      }
                    }),
                    _vm.dat && _vm.dat.length > 0
                      ? _c("ComboBox", {
                          attrs: {
                            values: _vm.dat,
                            firstEmpty: true,
                            disabled: this.saving
                          },
                          on: {
                            change: function($event) {
                              return _vm.onChangeInputField($event)
                            }
                          },
                          model: {
                            value: _vm.code,
                            callback: function($$v) {
                              _vm.code = $$v
                            },
                            expression: "code"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }