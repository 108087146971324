<template>
  <div class="config-edit-param-label__container">
    <PageHeaderTitleNavigation
      title="News bearbeiten"
      :actions="headerActions"
      @action-SAVE="saveNews"
    />
    <div class="box__container">
      <div class="input-forms__container">
        <InputField
          label="Titel"
          v-model="title">
        </InputField>

        <div class="input-forms__label-container">
          <div class="input-forms__label-content">Text</div>
          <div class="input-forms__input-container">
            <HtmlEditor
              :value="value"
              @input="onUpdate($event)"
            />
          </div>
        </div>
        
        <div class="mt-2">
          <InputCheckBoxGroup
            title="Anzeigen für"
            :value="forValue"
            :values="forValues"
          />
        </div>
      </div>
    </div>

    <DragnDropArea hoverText="Datei hier ablegen" @files="addDocument($event)">
      <div class="box__container">
        <div class="d-block justify-content-between">
            <div class="d-flex justify-content-between hide-on-large-screen">
              <div class="box__title">Anhänge</div>
              <BaseContextMenu>
                <ContextMenuItem @click="openAddDocument()">
                  <slot>Datei hochladen</slot>
                </ContextMenuItem>

                <ContextMenuItem disabled @click="handleAddDocuments">
                  Infofilme auswählen
                </ContextMenuItem>

              </BaseContextMenu>
            </div>

            <Table
                :headers="headers"
                :rows="rows"
                @action-DELETE="deleteFile"
                @action-EDIT="editFile"
                hidePagination
            >
              <template #name="row">
                <DownloadLink v-if="!row.noPreview"
                  :title="row.dateiname || row.name"
                  :filename="row.bezeichnung || row.fileName || row.name"
                  downloadServicePath="/get_email_attachment"
                  :queryParams="{
                    attId: row.copyFileId, 
                    tempFileId: row.id
                  }"/>
                <span v-else>{{row.dateiname || row.name}}</span>
              </template>
            </Table>
            <div class="hide-on-small-screen">
              <div class="d-flex d-md-block mt-1 layout__negative-margin--8 antrag-action-button__container justify-content-center">
                
                <BaseButton isSecondary @click="openAddDocument()">
                  <slot>Datei hochladen</slot>
                </BaseButton>

                <BaseButton isSecondary disabled @click="handleAddDocuments">
                  Infofilme auswählen
                </BaseButton>
                
                <slot name="maklervollmacht"></slot>
              </div>
            </div>
        </div>
      </div>
      <AddDocument ref="addDocumentModal" :vtgId="$route.params.id" :nodeId="nodeId" />
    </DragnDropArea>

    <BaseModal ref="addDocument"
      labelButtonConfirm="Upload starten"
      :showCancelButton="false"
      @onConfirmButton="uploadFile"
      @onCancelButton="closeAddDocumentModal">
      <template v-slot:modalTitle>
        Datei hochladen
      </template>
      <div>
        <DragnDropArea hoverText="Datei hier ablegen" @files="addDocument($event)">
          <div>
            <div class="row">
              <div class="col">
                <BaseButton isSecondary class="upload-btn">
                  <slot>Datei hochladen</slot>
                  <input type="file" :accept="acceptFileTypes" @change="onAddFiles" multiple />
                </BaseButton>
              </div>
              <div class="col">{{fileName}}</div>
            </div>
            <InputField
              label="Bezeichnung"
              v-model="fileTitle">
            </InputField>
            <InputTextArea
              label="Bemerkung"
              v-model="fileDescription">
            </InputTextArea>
            <div>
              <label class="gridlabel">Stand</label>
              <div>
                <DatePickerField
                  dateFormat="DD.MM.YYYY"
                  v-model="fileDate"
                  disabled
                ></DatePickerField>
              </div>
            </div>
          </div>
        </DragnDropArea>

      </div>
    </BaseModal>

  </div>
</template>

<script>
import BaseButton from '@/components/core/BaseButton.vue';
import HtmlEditor from '@/components/html-editor/HtmlEditor.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import InputCheckBoxGroup from '@/components/core/forms/checkbox/InputCheckBoxGroup.vue';
import { mapGetters } from 'vuex';
import InputField from '@/components/core/forms/InputField.vue';
import MSC_NEWS_TYPES from '@/store/mscnews/types'
import AddDocument from '@/views/versicherungen/AddDocument.vue';
import DragnDropArea from '@/components/fileUpload/DragnDropArea.vue';
import { uploadFileChunked } from '@/helpers/upload-helper';
import Table from "@/components/table2/Table.vue";
import BaseContextMenu from '@/components/core/BaseContextMenu.vue';
import ContextMenuItem from '@/components/core/base-context-menu/ContextMenuItem.vue';
import { SlotColumn, ActionColumn, SimpleAction } from '@/components/table2/table_util';
import DownloadLink from '@/components/core/download/DownloadLink.vue'
import { PhCaretUp, PhCaretDown, PhTrash, PhPencil } from 'phosphor-vue';
import BaseModal from '@/components/core/BaseModal.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';
import dayjs from 'dayjs';
import axios from 'axios';

export default {
  props: {
    param: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BaseButton,
    HtmlEditor,
    PageHeaderTitleNavigation,
    OptionMenu,
    InputCheckBoxGroup,
    InputField,
    DragnDropArea,
    AddDocument,
    Table,
    BaseContextMenu,
    ContextMenuItem,
    PhTrash,
    PhPencil,
    BaseModal,
    DatePickerField,
    InputTextArea,
    DownloadLink,
  },
  data() {
    return {
      currentNews: null,
      value: '',
      title: '',
      changedValue: '',
      forValue: {
        assekuranzkonzept: true,
        beteiligungen: true,
        investmentfond: true
      },
      hasChanged: false,
      forValues: [
        { label: 'Assekuranzkonzept', path: 'assekuranzkonzept'},
        { label: 'Investmentfond', path: 'investmentfond'},
        { label: 'Beteiligungen', path: 'beteiligungen'}
      ],
      files: [],
      nodeId: 'MTAlMjZtc2MuaGliZXJuYXRlLnBlcnNpc3RlbmNlLkRva3VtZW50Tm9kZS5UeXBlcy5BbnRyYWcuRG9rTm9kZVZlcnNpY2hlcnVuZyUyNm1zYy5oaWJlcm5hdGUucGVyc2lzdGVuY2UuR2VzZWxsc2NoYWZ0JTI2QUxMSUFOWiUyQlNBQ0hWRVJTSUNIRVJVTkcmbXNjLmhpYmVybmF0ZS5wZXJzaXN0ZW5jZS5Eb2t1bWVudE5vZGUuVHlwZXMuQW50cmFnLkRva05vZGVWZXJzaWNoZXJ1bmcmbXNjLmhpYmVybmF0ZS5wZXJzaXN0ZW5jZS5WZXJzVmVydHJhZyYxMTY0MjM0',
      acceptFileTypes: '*/*',
      headers: {
        lockedLeft: [
          SlotColumn('bezeichnung', 'Bezeichnung', 200, 1),
          SlotColumn('bemerkung', 'Bemerkung', 200, 1),
        ],
        lockedRight: [
          ActionColumn('actions', ''),
        ]
      },
      selectedFiles: [],
      fileName: '',
      file: null,
      fileTitle: '',
      fileDescription: '',
      fileDate: dayjs(new Date()).format('DD.MM.YYYY'),
    };
  },
  computed: {
    ...mapGetters({
      mscAllNews: MSC_NEWS_TYPES.GETTERS.MSC_NEWS,
      mscLastNews: MSC_NEWS_TYPES.GETTERS.MSC_LAST_NEWS,
    }),
    headerActions() {
      return [PageHeaderSimpleAction('SAVE', 'Veröffentlichen').withDisabled(() => this.disabledConfirm)]
    },
    disabledConfirm() {
      return !this.title || !this.value
    },
    rows() {
      const actions = [
          SimpleAction('EDIT', PhPencil, 'Bearbeiten'),
          SimpleAction('DELETE', PhTrash, 'Löschen'),
      ];
      return this.files
      .map(row => ({
          id: row.id,
          copyFileId: row.copyFileId,
          bezeichnung: row.fileName || row.bezeichnung || row.name,
          bemerkung: row.bemerkung,
          grosse: '',
          noPreview: row.id === true,
          actions,
      }));
    },
  },
  methods: {
    onUpdate(value) {
      this.value = value;
    },
    onConfirm() {
      this.$emit('confirm', this.changedValue);
    },
    onCancel() {
      this.$router.push({ path: `/intern/news-msc` })
    },
    addDocument(files) {
      this.selectedFiles = files;
      this.selectedFiles && this.selectedFiles.map(file => {
        uploadFileChunked(file).then(id => {
          this.files.push({
            tempFileId: id,
            fileId: id,
            name: file.name,
            uploaded: true,
            bezeichnung: this.fileTitle,
            bemerkung: this.fileDescription,
            date: this.fileDate,
          })
        })
      })
      this.fileName = this.selectedFiles.map(f => f.name).join(', ');
    },
    onAddFiles(event) {
      this.selectedFiles = Object.values(event.target.files);
      event.target.value = ''
      this.fileName = this.selectedFiles.map(f => f.name).join(', ');
    },
    handleAddDocuments(type) {
      // this.saveNews();
      // this.navigateTo({ path: '/communication/mailcomposer-dokumentenarchiv/news', query: {back: this.$route.fullPath } })
    },
    saveNews() {
      const requests = []
      if (this.$route.params.id) {
        this.files
          .filter(f => f.tempFileId)
          .map(file => {
            requests.push(axios.post(`${process.env.VUE_APP_API}/mscnews/addFile`, {
              id: this.$route.params.id,//file.tempFileId
              tempFileId: file.tempFileId,
              label: file.bezeichnung,
              bemerkung: file.bemerkung,
              // date: this.fileDate,
            }, {defaultSpinner: true})
            )
          })
        const fileIds = this.files.map(f => f.fileId)
        const removedFiles = this.currentNews.files.filter(f => !fileIds.includes(f.fileId))
        if (removedFiles.length) {
          removedFiles.map(file => {
            requests.push(axios.delete(`${process.env.VUE_APP_API}/mscnews/deleteFile`, {
              defaultSpinner: true,
              params: {
                id: this.$route.params.id,//file.id,
                fileId: file.fileId,
              }
            }))
          })
        }
      }
      requests.push(axios.post(`${process.env.VUE_APP_API}/mscnews/create_or_update`, {
          ...(this.$route.params.id ? {id: this.$route.params.id} : {}),
          // dateCreated: "2023-04-18T20:31:28.100Z",
          title: this.title,
          body: this.value,
          // gelesen: true,
          // files: this.files
          //   .filter(f => !f.tempFileId)
          //   .map(file =>
          //     ({
          //       id: file.fileId || file.id,
          //       bezeichnung: file.label,
          //       bemerkung: file.bemerkung,
          //     })),
          tempFiles: this.files
            .filter(f => f.tempFileId)
            .map(file =>
            ({
              id: file.fileId,
              // dokId: 0,
              name: file.name,
              label: file.bezeichnung,
              // nodeId: this.nodeId,
              bemerkung: file.bemerkung,
              // fileId: file.fileId,
            })),
          ...this.forValue
      }, {defaultSpinner: true}))

      Promise.all(requests)
      .then(response => {
        this.$router.push({ path: `/intern/news-msc` })
      });
    },
    deleteFile(row) {
      const index = this.files.findIndex(a => a.id === row.id && a.copyFileId === row.copyFileId)
      if (index > -1) {
        this.files.splice(index, 1);
      }
    },
    editFile(row) {
      const index = this.files.findIndex(a => a.id === row.id)
      if (index > -1) {
        this.file = this.files[index];
        this.fileTitle = this.files[index].bezeichnung || this.files[index].label;
        this.fileDescription = this.files[index].bemerkung || this.files[index].description;
        this.fileDate = dayjs(this.currentNews?.dateCreated || new Date()).format('DD.MM.YYYY');
        this.openAddDocument(true)
      }
    },
    openAddDocument(keep = false) {
      if (!keep) {
        this.file = null;
        this.fileTitle = '';
        this.fileDescription = '';
        this.fileDate = dayjs(new Date()).format('DD.MM.YYYY');
      }
      this.$refs.addDocument.open();
    },
    closeAddDocumentModal() {
      this.$refs.addDocument.close();
    },
    uploadFile() {
      if (this.file && (!this.selectedFiles || !this.selectedFiles.length)) {
        const index = this.files.findIndex(a => a.id === this.file.id)
        this.files[index] = {
          ...this.files[index],
          bezeichnung: this.fileTitle,
          bemerkung: this.fileDescription,
        }
        this.files = [...this.files]
        return
      }
      this.selectedFiles.map(file => {
        // upload file
        uploadFileChunked(file).then(id => {
          this.files.push({
            tempFileId: id,
            fileId: id,
            name: file.name,
            uploaded: true,
            bezeichnung: this.fileTitle,
            bemerkung: this.fileDescription,
            date: this.fileDate,
          })
        })
      })
      this.closeAddDocumentModal();
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.currentNews = this.mscAllNews.find(n => n.id == this.$route.params.id) || this.mscLastNews.find(n => n.id == this.$route.params.id)
      if (this.currentNews) {
        this.files = [...this.currentNews.files];
        this.value = this.currentNews.body;
        this.title = this.currentNews.title;
        this.forValue = {
          assekuranzkonzept: this.currentNews.assekuranzkonzept,
          beteiligungen: this.currentNews.beteiligungen,
          investmentfond: this.currentNews.investmentfond
        }
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.upload-btn {
  position: relative;
}

.upload-btn input {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

</style>
