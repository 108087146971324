var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      !_vm.isSmallScreen
        ? _c(
            "BaseButton",
            {
              on: {
                click: function($event) {
                  return _vm.openAddModal()
                }
              }
            },
            [_vm._v("Neue Anpassung")]
          )
        : _vm._e(),
      !_vm.loading && _vm.rows.length
        ? _c("Table", {
            attrs: {
              tableId: "55fdd0ca-5612-45dd-901e-293393ce8cbc",
              hidePagination: "",
              headers: _vm.headers,
              rows: _vm.rows,
              mobileConfig: {}
            },
            on: {
              "click-datum": _vm.openEditModal,
              "action-DELETE": _vm.deleteUpdate
            }
          })
        : _vm.loading
        ? _c("GhostLoading", { attrs: { type: "table" } })
        : _c("NoData"),
      _c("BaseModal", {
        ref: "anpassungDataModal",
        attrs: {
          size: "sm",
          modalTitle: "Wert anpassen",
          actions: _vm.baseModalActions,
          showDefaultButtons: false
        },
        on: {
          "action-SPEICHERN": function($event) {
            return _vm.actionSpeichern()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c("DatePickerField", {
                  attrs: {
                    isValueAsString: "",
                    label: "Datum",
                    validateUntouched: true
                  },
                  model: {
                    value: _vm.update.datum,
                    callback: function($$v) {
                      _vm.$set(_vm.update, "datum", $$v)
                    },
                    expression: "update.datum"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    label: "akt. Darlehenssumme",
                    type: "currency",
                    validateUntouched: true
                  },
                  model: {
                    value: _vm.update.aktWert,
                    callback: function($$v) {
                      _vm.$set(_vm.update, "aktWert", $$v)
                    },
                    expression: "update.aktWert"
                  }
                }),
                _c("InputTextArea", {
                  attrs: { label: "Bemerkung", validateUntouched: true },
                  model: {
                    value: _vm.update.bemerkung,
                    callback: function($$v) {
                      _vm.$set(_vm.update, "bemerkung", $$v)
                    },
                    expression: "update.bemerkung"
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c(
                  "BaseButton",
                  {
                    staticClass: "mr-3",
                    attrs: { isSecondary: "" },
                    on: {
                      click: function($event) {
                        return _vm.closeAnpassungDataModal()
                      }
                    }
                  },
                  [_vm._v("Abbrechen")]
                ),
                _c(
                  "BaseButton",
                  {
                    attrs: { disabled: _vm.validation.isInvalid("update") },
                    on: {
                      click: function($event) {
                        _vm.saveUpdate()
                        _vm.closeAnpassungDataModal()
                      }
                    }
                  },
                  [_vm._v("Speichern")]
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }