<template>
  <div @click="pressJahre" class="box spec_button jahre">Jahre</div>
</template>

<script>
import functionMixins from "../../functionMixins.js";
//import this.$store from "./../../this.$store.js";
export default {
  name: "Jahre",
  mixins: [functionMixins],
  methods: {
    pressJahre() {
      this.$store.commit("SET_OPERATORCLICKED", false);
      this.$store.commit("SET_KOMMACLICKED", false);
      if (
        this.$store.getters.getAnzahl !== "0" &&
        this.$store.getters.getRate !== "0" &&
        this.$store.getters.getStart !== "0" &&
        this.$store.getters.getZins !== "0"
      ) {
        var anzahl = this.$store.getters.getAnzahl;
        var rate = this.$store.getters.getRate;
        var start = this.$store.getters.getStart;
        var zins = this.$store.getters.getZins;
        var result = this.calcJahrefromRateStart(zins, rate, start, anzahl);
        result = Math.round(result);
        this.$store.commit("SET_JAHRE", result);
        var ende = Number(result) * Number(anzahl) * Number(rate);
        start = Math.round(start * 100) / 100;
        this.setRsLabels(start, ende);
      }
      if (
        this.$store.getters.getAnzahl !== "0" &&
        this.$store.getters.getRate !== "0" &&
        this.$store.getters.getEnde !== "0" &&
        this.$store.getters.getZins !== "0"
      ) {
        anzahl = this.$store.getters.getAnzahl;
        rate = this.$store.getters.getRate;
        ende = this.$store.getters.getEnde;
        zins = this.$store.getters.getZins;
        result = this.calcJahrefromRateEnde(zins, rate, ende, anzahl);
        result = Math.round(result);
        this.$store.commit("SET_JAHRE", result);
        start = Number(result) * Number(anzahl) * Number(rate);
        start = Math.round(start * 100) / 100;
        this.setRsLabels(start, ende);
      } else if (
        this.$store.getters.getStart !== "0" &&
        this.$store.getters.getEnde !== "0" &&
        this.$store.getters.getZins !== "0"
      ) {
        start = this.$store.getters.getStart;
        ende = this.$store.getters.getEnde;
        zins = this.$store.getters.getZins;
        result = this.calcJahrefromStartEnde(zins, start, ende);
        result = Math.round(result);
        this.$store.commit("SET_JAHRE", result);
        this.setRsLabels(start, ende);
      } else if (this.$store.getters.getCurrent !== "") {
        this.$store.commit("SET_JAHRE", this.$store.getters.getCurrent);
        this.$store.commit("SET_CURRENT", "");
      }
    }
  }
};
</script>

<style scoped>
.minus {
  grid-area: jahre;
}
</style>