<template>
  <div>
    <InsuranceOptionMenu title="Verteilung der Abrechnung auf die Struktur" 
      :headerActions="headerActions"
      @action-NEW-STRUKTUR="openNewStruktur()"
    />

    <div class="box__container">

    <div class="row">
      <div class="col-12">
        <Table v-if="!loading && rows.length"
          :headers="headers"
          :rows="rows"
          :rowsPerPage="10"
          v-model="selectedRows"
          @click-lfdNr="openEditStruktur"
          @action-DELETE="deleteStruktur($event)"
        />
        <GhostLoading v-else-if="loading" type="table" />
        <NoData v-else content="Es wurden keine Einträge gefunden" />
      </div>
    </div>
    </div>

    <BaseModal
      ref="verteilungStrukturModal"
      size="sm"
      modalTitle="Verteilung auf die Struktur"
      :showDefaultButtons="false"
    >
      <template #default>
        <div class="row" v-if="isNewStruktur">
          <div class="col-12">
            <ComboBox
              id="previousLfdNr"
              label="Lfd. Nummer"
              v-model="form.previousLfdNr"
              :values="comboboxValues"
              :validateUntouched="true"
            />
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-12">
            <InputField
              id="lfdNr"
              label="Lfd. Nummer"
              v-model="form.lfdNr"
              disabled
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <InputField
              label="Vermittlernummer"
              v-model="form.maklernr"
              validateUntouched
              @input="findMaklerDataDebounce($event)"
              @onBlur="checkIfMaklerDataIsValid()"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <InputField
              id="provSatz"
              label="Courtagesatz"
              type="percent"
              v-model="form.provSatz"
              :precision="2"
              :validateUntouched="true"
            />
          </div>
        </div>
      </template>
      <template #footer>
        <BaseButton isSecondary @click="closeEditStruktur()" class="mr-3">Abbrechen</BaseButton>
        <BaseButton :disabled="validation.isInvalid('form') || isMaklernrInvalid" @click="saveForm(); closeEditStruktur();">Speichern</BaseButton>
      </template>
    </BaseModal>

    <BaseModal
      ref="deleteModal"
      size="sm"
      modalTitle="Provisionssatz löschen"
      labelButtonConfirm="Löschen"
      @onConfirmButton="deleteStrukturItem()"
    >
      <template #default>
        Soll der Datensatz wirklich gelöscht werden?
      </template>
    </BaseModal>
  </div>
  
</template>

<script>
import { mapGetters } from 'vuex';
import INSURANCE_BUCHUNG_TYPES from "@/store/insuranceBuchung/types";
import CORE_TYPES from '@/store/core/types';
import CUSTOMERDATA_TYPES from '@/store/customerData/types';
import BaseModal from '@/components/core/BaseModal.vue';
import BaseButton from "@/components/core/BaseButton.vue";
import Table from '@/components/table2/Table.vue';
import {TextColumn, PercentageColumn, ActionColumn, LoadingAction, NumberColumn } from "@/components/table2/table_util.js";
import {PhTrash, PhPencilLine } from 'phosphor-vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import InputField from "@/components/core/forms/InputField.vue";
import validator from '@/mixins/validator';
import { required } from '@/mixins/validator/rules';
import { debounce } from '@/helpers/commonfunctions.js';
import ComboBox from "@/components/core/forms/ComboBox.vue";
import InsuranceOptionMenu from '@/views/versicherungen/insurance-group/InsuranceOptionMenu.vue';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import NoData from '@/components/core/NoData.vue';

const PRECISION_4 = 4;
const PRECISION_2 = 2;

export default {
  mixins: [validator],
  components: {
    BaseModal,
    BaseButton,
    Table,
    PhTrash,
    PhPencilLine,
    GhostLoading,
    InputField,
    ComboBox,
    InsuranceOptionMenu,
    NoData
  },
  data() {
    return {
      loading: false,
      strukturItemToDelete: {},
      deleteLoading: false,
      deleteId: null,
      selectedRows: null,
      isMaklernrInvalid: false,
      isNewStruktur: false,
      form: {
        previousLfdNr: null,
        lfdNr: null,
        maklernr: null,
        provSatz: null,
      }
    }
  },
  computed: {
    ...mapGetters({
      verteilungStruktur: INSURANCE_BUCHUNG_TYPES.GETTERS.INSURANCE_BUCHUNG_VERTEILUNG_STRUKTUR,
      selectedBuchungDetail: INSURANCE_BUCHUNG_TYPES.GETTERS.SELECTED_INSURANCE_BUCHUNG,
      isIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
      isFpPlus: CORE_TYPES.GETTERS.IS_FPP_PLUS,
    }),
    headers() {

      const editableCourtageColumns = [
        TextColumn("maklernr", "Vermittlernr"),
        PercentageColumn("provSatz", "Prov. Satz", PRECISION_4),
        NumberColumn("betrag", "Prov. Betrag", PRECISION_2),
        // TextColumn("aenderung", "Änderung"),
        // NumberColumn("monatsbeitrag", "MB", PRECISION_2),
        NumberColumn("prozentsatz", "Prozent", PRECISION_4),
        NumberColumn("mwst", "MwSt.", PRECISION_2),
        NumberColumn("stornoReserve", "Stornoreserve", PRECISION_2),
        NumberColumn("versicherungseinbehalt", "Vers. Einbehalt", PRECISION_2),
      ];

      const centerColumns = [
          TextColumn("maklernr", "Vermittlernr"),
          NumberColumn("betrag", "Prov. Betrag", PRECISION_2),
          // TextColumn("aenderung", "Änderung"),
          // NumberColumn("monatsbeitrag", "MB", PRECISION_2),
          NumberColumn("mwst", "MwSt.", PRECISION_2),
          NumberColumn("stornoReserve", "Stornoreserve", PRECISION_2),
          NumberColumn("versicherungseinbehalt", "Vers. Einbehalt", PRECISION_2),
        ];
      
      if(Object.keys(this.selectedBuchungDetail)?.length) {
        const abrechnungenColumns = [
          TextColumn("zeichnungsdatum", "Z.-Datum"),
          TextColumn("courtageart", "Prov. Art"),
          NumberColumn("bewertungssumme", "Bew.Summe", PRECISION_2),
          TextColumn("rechDatum", "Abr. Datum"),
        ]
        editableCourtageColumns.push(...abrechnungenColumns);
        centerColumns.push(...abrechnungenColumns);
      }

      const columns = {
        lockedLeft: [
          TextColumn("lfdNr", "Lfd. Nr.").makeAlwaysVisible(),
        ],
        center: centerColumns,
        lockedRight: this.isIntern ? [ActionColumn("actions", "Aktionen")] : [] ,
      }

      if(this.editable || this.isIntern){
        columns.lockedLeft = [TextColumn("lfdNr", "Lfd. Nr.").makeLink().makeAlwaysVisible()]
        columns.center = editableCourtageColumns;
      }
      return columns;
    },
    rows() {
      if (!this.verteilungStruktur) {
          return [];
      }
      const disabled = () => !this.isIntern;

      const actions = [
        LoadingAction("DELETE", PhTrash, "Löschen", disabled, (row) => this.deleteLoading && this.deleteId == row.id),
      ];

      return [...this.verteilungStruktur].map(item => {
        const record = {
          ...item,
          actions,
        };
        record.provSatz = (item.provSatz || 0)
        record.betrag = (item.betrag || 0)
        record.monatsbeitrag = (item.monatsbeitrag || 0)
        record.prozentsatz = (item.prozentsatz || 0)
        record.mwst = (item.mwst || 0)
        record.stornoReserve = (item.stornoReserve || 0)
        record.versicherungseinbehalt = (item.versicherungseinbehalt || 0)
        if(Object.keys(this.selectedBuchungDetail)?.length) {
          record.zeichnungsdatum = this.selectedBuchungDetail.zeichnungsdatum
          record.courtageart = this.selectedBuchungDetail.courtageart
          record.bewertungssumme = this.selectedBuchungDetail.bewertungssumme
          record.rechDatum = this.selectedBuchungDetail.rechDatum
        }
        return record;
      });
    },
    comboboxValues() {
      if(this.verteilungStruktur?.length){
        return [...this.verteilungStruktur].map(item => ({label: item.lfdNr, value: item.lfdNr}))
      }
      return [];
    },
    headerActions() {
      return this.isIntern ? [PageHeaderSimpleAction('NEW-STRUKTUR', 'Neue')] : [];
    },
  },
  methods:{
    openNewStruktur() {
      this.isNewStruktur = true;
      this.$refs.verteilungStrukturModal.open();
    },
    openEditStruktur(event) {
      this.isNewStruktur = false;
      this.form = {...event}
      this.$refs.verteilungStrukturModal.open();
    },
    closeEditStruktur() {
      this.isNewStruktur = false;
      this.$refs.verteilungStrukturModal.close();
    },
    deleteStruktur(item){
      if(item){
        this.strukturItemToDelete = {...item};
        this.$refs.deleteModal.open();
      }
    },
    deleteStrukturItem(){
      if(this.strukturItemToDelete?.lfdNr) {
        this.deleteLoading = true;
        this.deleteId = this.strukturItemToDelete?.lfdNr;
        this.$store.dispatch(INSURANCE_BUCHUNG_TYPES.ACTIONS.INSURANCE_BUCHUNG_VERTEILUNG_STRUKTUR_DELETE, {
          lfdNr: this.strukturItemToDelete?.lfdNr,
          buchungsnr: this.$route.params.buchungId,
        }).then(() => {
          this.deleteLoading = false;
          this.deleteId = null;
          this.abrechnungItemToDelete = null;
        });
      }
    },
    findMaklerDataDebounce: debounce(async function findMaklerData(maklernr) {
      const MAKLERNR_LENGTH = 5;
      try {
        if(maklernr?.length === MAKLERNR_LENGTH) {
          const zubringerData = await this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_ZUBRINGER_DATA, { maklernr });
          this.checkIfMaklerDataIsValid(zubringerData);
          if(!this.isMaklernrInvalid){
            this.form.maklernr = zubringerData?.maklernr;
          }
        }
      } catch(e) { }

    }),
    checkIfMaklerDataIsValid(zubringerData) {
      const FORM_MAKLERNR_PATH = 'form.maklernr';
      this.validation.reset(FORM_MAKLERNR_PATH);
      this.isMaklernrInvalid = false;

      if(!zubringerData && !this.form.maklernr) {
        this.isMaklernrInvalid = true;
        this.$pushErrors(FORM_MAKLERNR_PATH);
      }
    },
    saveForm() {
      const form = {
        ...this.form,
        vertragId: this.$route.params.vertragId,
        buchungId: this.$route.params.buchungId,
      };
      if(!this.isNewStruktur || form.lfdNr){
        this.$store.dispatch(INSURANCE_BUCHUNG_TYPES.ACTIONS.INSURANCE_BUCHUNG_VERTEILUNG_STRUKTUR_UPDATE, form);
        return;
      }
      this.$store.dispatch(INSURANCE_BUCHUNG_TYPES.ACTIONS.INSURANCE_BUCHUNG_VERTEILUNG_STRUKTUR_SAVE, form);
    },

  },
  mounted() {
    this.loading = true;
    this.$store.dispatch(INSURANCE_BUCHUNG_TYPES.ACTIONS.INSURANCE_BUCHUNG_VERTEILUNG_STRUKTUR, {buchungId: this.$route.params.buchungId})
    .then(response => {
      this.loading = false;
    })
  },
  unmounted() {
    this.$store.commit(INSURANCE_BUCHUNG_TYPES.MUTATIONS.SELECTED_INSURANCE_BUCHUNG, {});
  },
  validators: {
    form: {
      previousLfdNr: true ? [required('Lfd. Nummer ist erforderlich!')] : [],
      maklernr: [required('Vermittlernummer ist erforderlich!')],
      provSatz: [required('Courtagesatz ist erforderlich!')],
    }
  },

}
</script>
