import FAHRZEUGDATEN_TYPES from './types';
import {getInitialState} from './index'

export default {
  [FAHRZEUGDATEN_TYPES.GETTERS.FAHRZEUGDATEN_EDIT](state) {
    return state.fahrzeugdatenEdit;
  },
  [FAHRZEUGDATEN_TYPES.GETTERS.FAHRZEUGDATEN_INITIAL_STATE](state) {
    return getInitialState();
  },
}