var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chart-container" }, [
    _vm.title ? _c("h3", [_vm._v(_vm._s(_vm.title))]) : _vm._e(),
    _vm.type === "bar"
      ? _c(
          "div",
          { staticClass: "chart-wrap" },
          [
            _c("BarChart", {
              attrs: {
                chartData: _vm.getEChartData(_vm.data),
                format: _vm.format,
                height: _vm.height + "px",
                isSum: true,
                gridLeft: 50
              }
            })
          ],
          1
        )
      : _vm.type === "pie"
      ? _c(
          "div",
          { staticClass: "chart-wrap" },
          [
            _c("PieChart", {
              attrs: {
                chartData: _vm.getEChartData(_vm.data, true),
                format: _vm.format,
                height: _vm.height + "px",
                isTooltip: false
              }
            })
          ],
          1
        )
      : _vm.type === "donut"
      ? _c(
          "div",
          { staticClass: "chart-wrap" },
          [
            _c("PieChart", {
              attrs: {
                chartData: _vm.getEChartData(_vm.data, true),
                format: _vm.format,
                height: _vm.height + "px",
                doughnut: true,
                isTooltip: false
              }
            })
          ],
          1
        )
      : _vm.type === "stacked-bar"
      ? _c(
          "div",
          { staticClass: "chart-wrap" },
          [
            _c("StackedBarChart", {
              attrs: {
                chartData: _vm.getEChartDataMultipleBar(_vm.data),
                labels: _vm.data.labels,
                format: _vm.format,
                height: _vm.height + "px",
                isTooltip: false,
                isSum: true,
                gridLeft: 50
              }
            })
          ],
          1
        )
      : _vm.type === "radialMultiple"
      ? _c(
          "div",
          { staticClass: "chart-wrap" },
          [
            _c("GaugeChartMultiple", {
              attrs: {
                chartData: _vm.getEChartData(_vm.data, true),
                format: _vm.format,
                height: _vm.height + "px",
                isTooltip: false
              }
            })
          ],
          1
        )
      : _vm.type === "area"
      ? _c(
          "div",
          { staticClass: "chart-wrap" },
          [
            _c("AreaChart", {
              attrs: {
                chartData: _vm.getEChartDataMultiple(_vm.data),
                xLabels: _vm.xlabels,
                format: _vm.format,
                height: _vm.height + "px",
                isTooltip: _vm.isTooltip,
                gridLeft: 50
              }
            })
          ],
          1
        )
      : _c("div", { staticClass: "chart-wrap" }, [
          _vm._v(" Chart type is not defined! ")
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }