<template>
  <div class="depotnummer-anfordern-button-component mb-4">
    <div v-if="!requested" class="depotnummer-anfordern-button-component-input-container">
      <InputField 
        v-model="internalValue" 
        @input="handleInputEvent($event)" 
        @change="handleChangeEvent($event);" 
        :label="label" 
        :disabled="loading" 
        :isComponentHalfSize="isComponentHalfSize">
      </InputField>
      <BaseButton @click="requestDepotnummer()" :disabled="loading">
        Depotnummer anfordern <AnimatedSpinner v-if="loading"/>
      </BaseButton>
    </div>

    <div v-if="requested" class="depotnummer-anfordern-button-component-requested-number">Verwende Depotnummer {{ internalValue }}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import InputField from "@/components/core/forms/InputField.vue";
import BaseButton from "@/components/core/BaseButton.vue";
import ANTRAG_TYPES from '@/store/antrag/types';
import validator from '@/mixins/validator';
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';

export default {
  mixins: [validator],
  validators: {
  },
  props: {
    id: {
      type: String
    },
    label: {
      type: String
    },
    value: {
      type: [String, Number]
    },
    antragId: {
      type: String
    },
    additionalValidators: {
      type: Array
    },
    isComponentHalfSize: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      internalValue: this.value ? this.value : '',
      requested: false,
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      requestedEbaseDepotnummer: ANTRAG_TYPES.GETTERS.REQUESTED_EBASE_DEPOTNUMMER,
    }),
  },
  components: {
    InputField,
    BaseButton,
    AnimatedSpinner,
  },
  watch: {
    additionalValidators: {
      immediate: true,
      handler(newValue) {
        if (newValue && this.$configureValidators) {
          this.$configureValidators({ 
            internalValue: newValue,
          });
        }
      },
    }
  },
  methods: {
    handleInputEvent(event) {
      this.$emit('input', event);
    },
    handleChangeEvent(event) {
      this.$emit('change', event);
    },
    requestDepotnummer() {
      this.loading = true;
      this.$store.dispatch(ANTRAG_TYPES.ACTIONS.REQUEST_EBASE_DEPOTNUMMER, { antragId: this.antragId }).then(response => {
        this.internalValue = response.data[this.id]
        this.loading = false;
        this.requested = true;
        this.$emit('requested', response.data);
      });
    }
  },
  mounted() {
    const requestedEbaseDepotnummer = this.requestedEbaseDepotnummer[this.antragId];
    this.requested = requestedEbaseDepotnummer && !!requestedEbaseDepotnummer[this.id];
  }
};
</script>

<style scoped>
</style>
