<template>
  <BaseModal 
    ref="newCustomerModal"
    :autoClose="false"
    :labelButtonConfirm="confirmLabel"
    :confirmDisabled="!isValid || loading"
    @onConfirmButton="saveCustomer()"
    @closed="backToCustomerHome()">
    <template #modalTitle>{{isSuperCustomer ? 'Kundengruppe' : 'Kunde'}} anlegen</template>
    <template #default>
      <Stammdaten></Stammdaten>
    </template>
  </BaseModal>
</template>

<script>
import { mapGetters } from 'vuex';

import CUSTOMERDATA_TYPES from '@/store/customerData/types';
import CUSTOMER_SEARCH_TYPES from '@/store/customerSearch/types';
import CORE_TYPES from '@/store/core/types';

import BaseModal from '@/components/core/BaseModal.vue';
import Stammdaten from '@/components/new-customer/Stammdaten.vue';

import { NEW_CUSTOMER_ID, PERSOENLICHEDATEN_HOME, CUSTOMER_HOME } from './customer-utils';
import { addParamToURL } from '@/helpers/utils-helper';

export default {
  props: {
    isSuperCustomer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      savedCustomerID: CUSTOMERDATA_TYPES.GETTERS.GET_SAVED_CUSTOMER_ID,
      customerDataEdited: CUSTOMERDATA_TYPES.GETTERS.GET_CUSTOMER_DATA_EDITED,
    }),
    isValid() {
      return !!this.customerDataEdited?.personalDataAddress?.lastName?.trim();
    },
    confirmLabel() {
      return `${this.isSuperCustomer ? 'Kundengruppe' : 'Kunde'} anlegen`;
    },
  },
  methods: {
    open() {
      this.$refs.newCustomerModal.open();
      this.init();
    },
    close() {
      this.$refs.newCustomerModal.close();
    },
    init() {
      this.reset();
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA_CONFIG);
    },
    backToCustomerHome() {
      this.reset();
      this.$router.push(addParamToURL(CUSTOMER_HOME, 'backAction=true'));
    },
    reset() {
      this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.RESET_CUSTOMER_DATA_EDITED);
      this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.UPDATE_SELECTED_CUSTOMER_DATA, NEW_CUSTOMER_ID);
    },
    saveCustomer() {
      setTimeout(() => {
        this.save();
      });
    },
    save() {
      this.loading = true;
      const params = {...this.customerDataEdited, superCustomer: this.isSuperCustomer};
      if (params.personalDataBirth?.dayOfBirth?.length == 10) { // nicht Date().toJSON() format
        try {
          const p = params.personalDataBirth.dayOfBirth.split('.').map(p => parseInt(p, 10));
          const d = new Date( Date.UTC(p[2], p[1]-1, p[0]));
          params.personalDataBirth.dayOfBirth = d.toJSON();
        } catch (e) { console.lof(e, params.personalDataBirth.dayOfBirth); }
      }
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.SAVE_NEW_CUSTOMER_DATA, params)
        .then(() => {
          this.close();
          this.openCustomer();
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
        });
    },
    openCustomer() {
      const customer = {
        data: {
          customerID: this.savedCustomerID,
        },
      };

      this.$store.commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.OPEN_CUSTOMER, customer);
      this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { 
        customerId: customer.data.customerID,
        path: PERSOENLICHEDATEN_HOME + "?newCustomer=true"
      });
      this.init();
    },
  },
  mounted() {
    this.open();
  },
  components: {
    BaseModal,
    Stammdaten,
  },
}
</script>
