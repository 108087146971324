import  TAPI from './types';
import Vue from 'vue'
import { getInitialState } from './index'

export default {
  [TAPI.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },
  [TAPI.MUTATIONS.CONNECT](state, payload) {
    state.CONNECT = payload
  },
  [TAPI.MUTATIONS.LINE](state, payload) {
    state.LINE = payload
  },
  [TAPI.MUTATIONS.PHONE_NUMBER](state, payload) {
    state.PHONE_NUMBER = payload
  },
  [TAPI.MUTATIONS.OPEN_INCOMMING_DIALOG](state, payload) {
    state.OPEN_INCOMMING_DIALOG = payload
  },
  [TAPI.MUTATIONS.WEB_SOCKET](state, payload) {
    state.WEB_SOCKET = payload
  },
  [TAPI.MUTATIONS.WEB_SOCKET_OPENED](state, payload) {
    state.WEB_SOCKET_OPENED = payload
  },
  [TAPI.MUTATIONS.MODAL_PANEL_OPEN](state, payload) {
    state.MODAL_PANEL_OPEN = payload
  },

  [TAPI.MUTATIONS.CAN_TAPI](state, payload) {
    state.CAN_TAPI = payload
  },
  [TAPI.MUTATIONS.CAN_TAPI_BUTTON](state, payload) {
    state.CAN_TAPI_BUTTON = payload
  },
  [TAPI.MUTATIONS.CAN_TAPI_HOST](state, payload) {
    state.CAN_TAPI_HOST = payload
  },
  [TAPI.MUTATIONS.ACTIVE_CALL](state, payload) {
    state.ACTIVE_CALL = payload
  },
  [TAPI.MUTATIONS.HOST](state, payload) {
    state.HOST = payload
  },
  [TAPI.MUTATIONS.CALLER](state, payload) {
    state.CALLER = payload
  },
  [TAPI.MUTATIONS.DIRECTION](state, payload) {
    state.DIRECTION = payload
  },
  [TAPI.MUTATIONS.TAPI_PARAMS](state, payload) {
    state.TAPI_PARAMS = payload
  },
  [TAPI.MUTATIONS.START_CALL_DATE](state, payload) {
    state.START_CALL_DATE = payload
  },

  [TAPI.MUTATIONS.CALL_OUT_USER_TYPE](state, payload) {
    state.CALL_OUT_USER_TYPE = payload
  },
  [TAPI.MUTATIONS.CALL_OUT_US_ID](state, payload) {
     
    state.CALL_OUT_US_ID = payload
  },
  [TAPI.MUTATIONS.CALL_OUT_NUMBER](state, payload) {
    state.CALL_OUT_NUMBER = payload
  },
  [TAPI.MUTATIONS.RINGING](state, payload) {
    state.RINGING = payload
  },
  [TAPI.MUTATIONS.LINES](state, payload) {
    state.LINES = payload
  },
  
}