var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("BaseCollapsable", {
        attrs: { showSection: _vm.showExpanded },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _c("div", { staticClass: "box__title" }, [
                  _vm._v("Zielmarktdaten")
                ])
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _vm.rows && _vm.rows.length
                  ? _c(
                      "div",
                      [
                        _c("Table", {
                          attrs: {
                            headers: _vm.headers,
                            rows: _vm.rows,
                            rowsPerPage: 20,
                            rowId: "vvstrategienzielmarkt",
                            hidePagination: ""
                          }
                        })
                      ],
                      1
                    )
                  : _c("div", { staticClass: "my-2" }, [_vm._v("Keine Daten")])
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }