<template>
  <div>
    <GesellschaftTitleNavigation
      :headerActions="headerActions"
      @action-SELECT_ALL="selectAll"
      @action-DESELECT_ALL="deselectAll"
    />

    <GhostLoading v-if="loading" :title="PAGE_TITLE" useBoxContainer type="table" />
    <template v-else>
      <DashboardPanel
        id="f4cfdd24-f99c-4a66-bfaa-94faa9724d22"
        hidePageHeader
        ignoreUserLevelConfig
        isMasonry
        editDisabled
        :data="dashboardData"
      >
        <template v-for="item in versSparten" v-slot:[item.name]>
          <div v-for="sparte in item.values" :key="sparte.id">
            <InputToggleSwitch
              class="mb-2"
              :label="sparte.bezeichnung"
              v-model="sparte.selected"
            />
          </div>
        </template>
      </DashboardPanel>
    </template>


  </div>
</template>

<script>
import GESELLSCHAFT_TYPES from '@/store/gesellschaft/types';
import { mapGetters } from 'vuex';
import axios from 'axios';
import { makeQueryParam } from '@/helpers/utils-helper';

import GesellschaftTitleNavigation from '@/views/gesellschaft/intern/GesellschaftTitleNavigation.vue'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import DashboardPanel from '@/components/dashboard/DashboardPanel.vue';
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';

import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue'

const PAGE_TITLE = 'Versicherungssparten';

const config = {
  defaultSpinner: true
};

export default {
  mixins: [],
  components: {
    GesellschaftTitleNavigation,
    GhostLoading,
    DashboardPanel,
    InputToggleSwitch,
  },
  data() {
    return {
      PAGE_TITLE,
      loading: false,
      versSparten: [],
      originalVersSparten: null,
    }
  },
  watch: {
    gesellschaft: {
      handler(newVal) {
        this.versSparten = newVal.versSparten || []

        if (!this.originalVersSparten && Object.keys(this.versSparten).length) {
          // Create a deep copy and compare it when trying to save. If no changes were made we don't need to save
          this.originalVersSparten = JSON.parse(JSON.stringify(this.versSparten))
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      gesellschaft: GESELLSCHAFT_TYPES.GETTERS.GET_GESELLSCHAFT,
    }),
    dashboardData() {
      return {
        widgets: this.versSparten
        .map(item => {
          return {
            name: item.name, 
            title: item.bezeichnung,
          }
        })
      }
    },
    headerActions() {
      return [
        PageHeaderSimpleAction('SELECT_ALL', 'Alle auswählen'),
        PageHeaderSimpleAction('DESELECT_ALL', 'Alle abwählen'),
      ]
    },
  },
  mounted() {
      this.loading = true

      this.$store.dispatch(GESELLSCHAFT_TYPES.ACTIONS.GET_GESELLSCHAFT_VERS_SPARTEN, { gesellschaftId: this.$route.params.gesellId })
      .finally(() => this.loading = false);
  },
  methods: {
    saveVersSparten() {
      if (JSON.stringify(this.originalVersSparten) === JSON.stringify(this.versSparten)) {
        return
      }

      const params = makeQueryParam({ gesellschaftId: this.$route.params.gesellId }, true)
      const payload = this.versSparten.map(item => item.values.filter(value => value.selected).map(value => value.id)).flat(1)

      axios.post(`${process.env.VUE_APP_API}/gesellschaftenService/versSparten?${params}`, payload, config)
        .then(() => {
          this.originalVersSparten = JSON.parse(JSON.stringify(this.versSparten))
        })
    },
    selectAll() {
      this.versSparten.forEach(item => item.values.forEach(sparte => sparte.selected = true))
    },
    deselectAll() {
      this.versSparten.forEach(item => item.values.forEach(sparte => sparte.selected = false))
    }
  },
  beforeRouteLeave(to, from, next) {
    this.saveVersSparten();
    next();
  },
}
</script>