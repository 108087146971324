import COURTAGETABELLE_VERSICHERUNG_TYPES from './types';

export default {
  [COURTAGETABELLE_VERSICHERUNG_TYPES.MUTATIONS.FILTER_FIELDS_DEFINITIONS_SUCCESS](state, payload) {
    state.filterFieldsDefinition = payload;
  },
  [COURTAGETABELLE_VERSICHERUNG_TYPES.MUTATIONS.COURTAGETABELLE_VERSICHERUNG_LIST_SUCCESS](state, payload) {
    state.courtagetabelleVersicherungList = payload;
  },
  [COURTAGETABELLE_VERSICHERUNG_TYPES.MUTATIONS.CLEAR_COURTAGETABELLE_VERSICHERUNG_LIST](state, payload) {
    state.courtagetabelleVersicherungList.rows = [];
    state.courtagetabelleVersicherungList.count = 0;
  },
  [COURTAGETABELLE_VERSICHERUNG_TYPES.MUTATIONS.GET_MENU_ITEM_ACCESS_RULE_SUCCESS](state, payload) {
    state.menuOptionAccessRule = payload;
  },
  [COURTAGETABELLE_VERSICHERUNG_TYPES.MUTATIONS.SAVE_OR_UPDATE_SUCCESS](state, payload) {
    state.lastUpdatedItem = payload;
  }, 
  [COURTAGETABELLE_VERSICHERUNG_TYPES.MUTATIONS.GET_FORM_METADATA_SUCCESS](state, payload) {
    state.formMetadata = payload;
  },  

  
  
}