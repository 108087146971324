var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.rowSteps.length
    ? _c(
        _vm.short ? "div" : "ContextMenuGroup",
        {
          tag: "component",
          staticClass: "select-row-per-page__container",
          class: [_vm.short ? "d-none d-sm-flex" : ""]
        },
        [
          _vm._l(_vm.rowSteps, function(step) {
            return [
              _c(
                _vm.short ? "a" : "ContextMenuItem",
                {
                  key: step.value,
                  tag: "component",
                  class: _vm.stepClass(step),
                  on: {
                    click: function($event) {
                      return _vm.changeStep(step.value)
                    }
                  }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.short ? step.value : step.label) + " "
                  )
                ]
              )
            ]
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }