<template>
  <div>
    <ComboBox
      label="Bezug"
      validateUntouched
      :values="bezugstelleValues"
      v-model="form.bezug"
      @change="clearGesellschaft(); clearFondsname(); findGesellschaft($event); dataChangedCombo('bezug', $event);"
    />

    <ComboBox
      label="Gesellschaft"
      validateUntouched
      :values="gesellschaftValues"
      v-model="form.gesellschaft"
      @change="clearFondsname(); findFonds($event); dataChangedCombo('gesellschaft', $event);"
    />

    <!-- TODO check "Wertpapier Info anzeigen" button -->
    <ComboBox
      label="Fondsname"
      validateUntouched
      :values="fondsValues"
      v-model="form.fondsname"
      @change="dataChangedCombo('fondsname', $event);"
    />

    <InputField
      label="Zeichnungssumme"
      validateUntouched
      type="currency"
      v-model="form.zeichnungssumme"
      @change="dataChanged();"
    />

    <template v-if="isAVL">
      <InputField
        label="AVL Angebotsnr"
        v-model="form.avlAngebotsnummer"
        @change="dataChanged();"
        @input="addAvlAngebotsnummerErrorIfNeeded();"
      />

      <InputToggleSwitch
        label="Zeichnung mit Direktrabatt"
        inLineLabel
        v-model="form.avlMitDirektRabatt"
        @change="dataChanged();"
      />
    </template>
  </div>
</template>

<script>
import FILEUPLOAD_TYPES from '@/store/fileUpload/types';

import validator from '@/mixins/validator';
import { required } from '@/mixins/validator/rules';

import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputField from '@/components/core/forms/InputField.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import customerDocumentUploadMixin from './customer-document-upload-mixin';

export default {
  mixins: [validator, customerDocumentUploadMixin],
  computed: {
    bezugstelleValues() {
      return [...this.deckblattCombos?.bezugstelle || []];
    },
    gesellschaftValues() {
      const values = this.comboValues({
        customerID: this.customer?.customerID,
        type: 'GESCHLOSSENE_GESELLSCHAFT',
        ids: [this.form?.bezug],
      });
      return [ ...values ];
    },
    fondsValues() {
      const values = this.comboValues({
        customerID: this.customer?.customerID,
        type: 'GESCHLOSSENE_FONDS',
        ids: [this.form?.gesellschaft, this.form?.bezug],
      });
      return [ ...values ];
    },
  },
  methods: {
    clearGesellschaft() {
      this.$set(this.form, 'gesellschaft', undefined);
    },
    async findGesellschaft(bezug) {
      if(!bezug) return;
      await this.$store.dispatch(FILEUPLOAD_TYPES.ACTIONS.GET_COMBO_VALUES, {
        customerID: this.customer.customerID,
        type: 'GESCHLOSSENE_GESELLSCHAFT',
        ids: [bezug],
      });
    },
    clearFondsname() {
      this.$set(this.form, 'fondsname', undefined);
    },
    async findFonds(gesellschaftId) {
      if(!gesellschaftId || !this.form?.bezug) return;
      await this.$store.dispatch(FILEUPLOAD_TYPES.ACTIONS.GET_COMBO_VALUES, {
        customerID: this.customer.customerID,
        type: 'GESCHLOSSENE_FONDS',
        ids: [gesellschaftId, this.form?.bezug || ''],
      });
    },
    addAvlAngebotsnummerErrorIfNeeded() {
      if(!this.isAVL) return ;

      if(!this.form.avlAngebotsnummer) {
        this.$nextTick(() => this.$pushErrors('form.avlAngebotsnummer', ''));
      } else {
        this.$nextTick(() => this.validation.removeError('form.avlAngebotsnummer', ''));
      }
    },
  },
  mounted() {
    this.addAvlAngebotsnummerErrorIfNeeded();
  },
  validators: {
    form: {
      bezug: [required()],
      gesellschaft: [required('Bitte wählen Sie eine Gesellschaft aus.')],
      fondsname: [required('Bitte wählen Sie einen Fondsnamen aus.')],
      zeichnungssumme: [required('Bitte geben Sie eine Zeichnungssumme an.')],
    },
  },
  components: {
    ComboBox,
    InputField,
    InputToggleSwitch,
  },
}
</script>
