var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isDialogFVV
        ? _c("InputRadioBoxGroup", {
            attrs: {
              title:
                "Besitzen Sie Erfahrungen mit kreditfinanzierten Anlagegeschäften?",
              validateUntouched: "",
              inlineLabelValues: false,
              disabled: _vm.isDisabled || _vm.isRefusedPage,
              values: [
                { label: "Nein", value: "no" },
                { label: "Ja", value: "yes" }
              ]
            },
            on: {
              input: function($event) {
                _vm.updateStore(
                  _vm.getCategory(),
                  "kreditAktiv",
                  _vm.getBooleanValue($event)
                )
              }
            },
            model: {
              value: _vm.kreditAktiv,
              callback: function($$v) {
                _vm.kreditAktiv = $$v
              },
              expression: "kreditAktiv"
            }
          })
        : _vm._e(),
      _vm.isDialogFVV && _vm.kreditAktiv === "yes"
        ? _c("InputField", {
            attrs: {
              label: "Höhe Ihres Kredits",
              isComponentHalfSize: "",
              validateUntouched: "",
              type: "currency",
              disabled: _vm.isDisabled || _vm.isRefusedPage
            },
            on: {
              change: function($event) {
                _vm.updateStore(_vm.getCategory(), "kreditText", $event)
              }
            },
            model: {
              value: _vm.kreditText,
              callback: function($$v) {
                _vm.kreditText = $$v
              },
              expression: "kreditText"
            }
          })
        : _vm._e(),
      !_vm.isDialogFVV
        ? _c("InputCheckBoxGroup", {
            attrs: {
              disabled: _vm.isRefusedPage,
              title:
                "In welchem Rahmen konnten Sie Erfahrungen mit Finanzinstrumenten sammeln?",
              values: [
                { label: "Beruflich", path: "isProfExperience" },
                { label: "Privat", path: "isPrivateExperience" }
              ]
            },
            on: {
              checkBoxToggle: function($event) {
                _vm.updateStoreCheckBoxGroup(_vm.getCategory(), $event)
              }
            },
            model: {
              value: _vm.form,
              callback: function($$v) {
                _vm.form = $$v
              },
              expression: "form"
            }
          })
        : _vm._e(),
      _c("InputCheckBoxGroup", {
        attrs: {
          disabled: _vm.isDisabled || _vm.isRefusedPage,
          title:
            "Welche Finanzdienstleistung haben Sie bereits in Anspruch genommen?",
          values: [
            { label: "Vermögensverwaltung", path: "isPropertyManagement" },
            { label: "Anlageberatung", path: "isInvestConsult" },
            { label: "Beratungsfreie Anlagegeschäfte", path: "isFreeInvest" }
          ]
        },
        on: {
          checkBoxToggle: function($event) {
            _vm.updateStoreCheckBoxGroup(_vm.getCategory(), $event)
          }
        },
        model: {
          value: _vm.form,
          callback: function($$v) {
            _vm.form = $$v
          },
          expression: "form"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }