var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.searchingPositions
    ? _c("div", [
        _vm._m(0),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [_c("FondsFinderSuchMaske")], 1)
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c("FondsFinderPositionsList", {
                attrs: {
                  zurueckButtonText: "Abbrechen",
                  addPositionsOnBack: false
                },
                on: {
                  goBack: function($event) {
                    return _vm.addFonds($event)
                  }
                }
              })
            ],
            1
          )
        ])
      ])
    : _c("div", { staticClass: "input-forms__container" }, [
        _c("div", { staticClass: "input-forms__label-container" }, [
          _c("div", { staticClass: "input-forms__label-container" }, [
            _c("div", { staticClass: "row mt-0" }, [
              _c("div", { staticClass: "col" }, [
                _vm.label
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "input-forms__label-content input-forms__label-content--bigger"
                      },
                      [
                        _c("ph-bank", { attrs: { size: 16 } }),
                        _vm._v(" " + _vm._s(_vm.label) + " ")
                      ],
                      1
                    )
                  : _vm._e()
              ]),
              _c(
                "div",
                { staticClass: "col-auto" },
                [
                  _c(
                    "BaseButton",
                    {
                      attrs: { disabled: this.disabled },
                      on: { click: _vm.openFormsFinder }
                    },
                    [_vm._v("Fond hinzufügen")]
                  ),
                  _c(
                    "BaseButton",
                    {
                      attrs: { disabled: this.disabled },
                      on: {
                        click: function($event) {
                          _vm.searchingPositions = true
                        }
                      }
                    },
                    [_vm._v("Wertpapiere hinzufügen")]
                  )
                ],
                1
              )
            ])
          ])
        ]),
        _c(
          "div",
          { staticClass: "input-forms__input-container" },
          [
            _vm.tableData &&
            _vm.tableData.records &&
            _vm.tableData.records.length &&
            !this.disabled
              ? _c("Table", {
                  attrs: {
                    tableData: _vm.tableData,
                    cardViewEnabled: false,
                    filterEnabled: false,
                    exportEnabled: false,
                    paginationEnabled: false,
                    actions: _vm.columnActions,
                    rowsPerPage: _vm.tableData.records.length
                  },
                  on: {
                    "execute-action": function($event) {
                      return _vm.handleTableAction($event)
                    }
                  }
                })
              : _vm._e(),
            _c("DeletePositionModal", {
              ref: "deleteModal",
              attrs: { position: _vm.positionToDelete },
              on: {
                delete: function($event) {
                  return _vm.doDeletePosition()
                }
              }
            })
          ],
          1
        )
      ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("h2", [_vm._v("Wertpapiersuche")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }