var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: "Zusätzliche Dokumente", actions: _vm.headerActions },
        on: {
          "action-UPLOAD_DOCUMENT_MODAL": function($event) {
            _vm.files = []
          },
          "action-UPLOAD_DOCUMENT": _vm.saveChangesAndUpload
        }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("h3", [_vm._v(_vm._s(_vm.title))]),
          _vm.additionalDocumentInputs
            ? _c(
                "div",
                { staticClass: "grid" },
                [
                  _c(
                    "DragnDropArea",
                    {
                      staticClass: "explorer__content",
                      attrs: {
                        hoverText: "Neues Dokument hochladen",
                        disabled: _vm.isReadOnly || !_vm.isValid
                      },
                      on: { files: _vm.dropFile }
                    },
                    [
                      _vm.isIntern
                        ? _c("InputToggleSwitch", {
                            attrs: {
                              label: "Gelöschte Dokumente anzeigen",
                              inLineLabel: ""
                            },
                            on: {
                              input: function($event) {
                                return _vm.retrieveAdditionalDocuments($event)
                              }
                            },
                            model: {
                              value: _vm.showDeleted,
                              callback: function($$v) {
                                _vm.showDeleted = $$v
                              },
                              expression: "showDeleted"
                            }
                          })
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "row mb-4" },
                        [
                          _c("UploadInputList", {
                            attrs: {
                              inputs: _vm.additionalDocumentInputs,
                              disabled: _vm.isReadOnly
                            },
                            model: {
                              value: _vm.formData,
                              callback: function($$v) {
                                _vm.formData = $$v
                              },
                              expression: "formData"
                            }
                          })
                        ],
                        1
                      ),
                      _c("hr"),
                      _c("h3", [_vm._v(_vm._s(_vm.tableTitle))]),
                      _vm.rows.length
                        ? _c("Table", {
                            staticClass: "table-container",
                            attrs: {
                              headers: _vm.headers,
                              rows: _vm.rows,
                              hidePagination: ""
                            },
                            on: {
                              action: function($event) {
                                return _vm.executeAction($event)
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "name",
                                  fn: function(row) {
                                    return [
                                      _vm.listHasAction(
                                        row.actionsObjects,
                                        "DOKUMENT_ANZEIGEN"
                                      )
                                        ? _c(
                                            "span",
                                            [
                                              !row.exists
                                                ? _c("ph-trash", {
                                                    staticClass:
                                                      "color-danger mr-1 icon",
                                                    attrs: { size: 16 }
                                                  })
                                                : _vm._e(),
                                              _c("DownloadLink", {
                                                attrs: {
                                                  target: "_blank",
                                                  rel: "noopener noreferer",
                                                  href: _vm.makeLink(row),
                                                  title:
                                                    row.name ||
                                                    row.bezeichnung ||
                                                    row.fileName ||
                                                    "unbenannt"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm.listHasAction(
                                            row.actionsObjects,
                                            "ZUSATZ_DOKUMENTE_ANZEIGEN"
                                          )
                                        ? _c(
                                            "a",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.openSubviewTable(
                                                    row
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v(_vm._s(row.name))]
                                          )
                                        : _c(
                                            "span",
                                            [
                                              !row.exists
                                                ? _c("ph-trash", {
                                                    staticClass:
                                                      "color-danger mr-1 icon",
                                                    attrs: { size: 16 }
                                                  })
                                                : _vm._e(),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    row.name ||
                                                      row.bezeichnung ||
                                                      row.fileName ||
                                                      "unbenannt"
                                                  ) +
                                                  " "
                                              )
                                            ],
                                            1
                                          )
                                    ]
                                  }
                                },
                                {
                                  key: "vorlagestatus",
                                  fn: function(row) {
                                    return [
                                      _c("WorkflowPill", {
                                        attrs: {
                                          currentStatusIcons: row.statusActions,
                                          statusType: "VORLAGE"
                                        }
                                      })
                                    ]
                                  }
                                },
                                {
                                  key: "signaturstatus",
                                  fn: function(row) {
                                    return [
                                      _c("WorkflowPill", {
                                        attrs: {
                                          currentStatusIcons: row.statusActions,
                                          statusType: "SIGNATUR"
                                        }
                                      })
                                    ]
                                  }
                                },
                                {
                                  key: "aktueller_scan",
                                  fn: function(row) {
                                    return [
                                      row.aktueller_scan != null
                                        ? _c("InputToggleSwitch", {
                                            attrs: {
                                              disabled:
                                                _vm.isReadOnly || !row.exists,
                                              value: row.aktueller_scan
                                            },
                                            on: {
                                              input: function($event) {
                                                return _vm.updateAktuellerScan(
                                                  row,
                                                  $event
                                                )
                                              }
                                            }
                                          })
                                        : _c("span", [
                                            _vm._v(" Ist kein Scan ")
                                          ])
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              1673436944
                            )
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "block", config: { block: { height: 280 } } }
              })
            : _c("NoData", { attrs: { content: "Keine Daten vorhanden" } })
        ],
        1
      ),
      _vm.files && _vm.additionalUploadInputs
        ? _c("DocumentsUploadModal", {
            attrs: {
              files: _vm.files,
              additionalInputs: _vm.additionalUploadInputs,
              parentId: _vm.nodeId,
              uploadType: _vm.uploadType,
              documentString: _vm.fileId,
              multipleFileUpload: false
            },
            on: {
              close: function($event) {
                _vm.files = null
              }
            }
          })
        : _vm._e(),
      _c("EditDocumentModal", {
        ref: "editDocumentModal",
        on: { confirm: _vm.confirmEditDocument },
        model: {
          value: _vm.fileForm,
          callback: function($$v) {
            _vm.fileForm = $$v
          },
          expression: "fileForm"
        }
      }),
      _c("PictureAttachmentsModal", {
        ref: "pictureAttachmentsModal",
        attrs: { selectedDoc: _vm.selectedDoc }
      }),
      _c(
        "BaseModal",
        {
          ref: "subviewModal",
          attrs: {
            labelButtonCancel: "Zurück",
            showConfirmButton: false,
            size: "xl"
          },
          on: { close: _vm.closeSubviewModal },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [_vm._v(" " + _vm._s(_vm.subviewModalTitle) + " ")]
              },
              proxy: true
            }
          ])
        },
        [
          _c("Table", {
            staticClass: "table-container",
            attrs: {
              headers: _vm.headers,
              rows: _vm.subviewTableData.records || [],
              rowsPerPage:
                (_vm.subviewTableData.records &&
                  _vm.subviewTableData.records.length + 1) ||
                1,
              hidePagination: ""
            },
            on: {
              action: function($event) {
                return _vm.executeAction($event)
              }
            },
            scopedSlots: _vm._u([
              {
                key: "name",
                fn: function(row) {
                  return [
                    _vm.listHasAction(row.actionsObjects, "DOKUMENT_ANZEIGEN")
                      ? _c("DownloadLink", {
                          attrs: {
                            href: _vm.makeLink(row),
                            title: row.bezeichnung || row.fileName || row.name
                          }
                        })
                      : _c("span", [
                          _vm._v(
                            _vm._s(
                              row.bezeichnung ||
                                row.fileName ||
                                row.name ||
                                "unbenannt"
                            )
                          )
                        ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("BaseModal", {
        ref: "noSignoFilesModal",
        attrs: { labelButtonCancel: "Zurück", showConfirmButton: false },
        scopedSlots: _vm._u([
          {
            key: "modalTitle",
            fn: function() {
              return [
                _c("b", [
                  _vm._v("Das Dokument ist nicht zur e-Signatur geeignet")
                ])
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _c("p", [
                  _vm._v("Sie können jedoch das Dokument herunterladen")
                ]),
                _c("div", { staticClass: "grid" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col" },
                      [
                        _c("DownloadLink", {
                          attrs: {
                            href: _vm.makeLink(
                              _vm.currentAction && _vm.currentAction.row
                            ),
                            title:
                              (_vm.currentAction &&
                                _vm.currentAction.row &&
                                (_vm.currentAction.row.name ||
                                  _vm.currentAction.row.filename)) ||
                              "unbenannt"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "BaseModal",
        {
          ref: "warningModal",
          attrs: {
            modalTitle: _vm.warningTitle,
            labelButtonConfirm: "Ja",
            labelButtonCancel: "Nein"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.confirmAbschliessen()
            }
          }
        },
        [_c("label", [_vm._v(_vm._s(_vm.warningMessage))])]
      ),
      _c("ProhypDokumenteUpload", {
        ref: "prohypDokumenteUploadModal",
        attrs: { nodeId: _vm.nodeId, documentId: _vm.fileId },
        on: { selectionDone: _vm.retrieveAdditionalDocuments }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }