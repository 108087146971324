export const MUTATION_PREFIX = 'DCS_SYNCHRONISATION: ';
export const ACTIONS_PREFIX = 'DCS_SYNCHRONISATION_ACTIONS_';
export const GETTERS_PREFIX = 'DCS_SYNCHRONISATION_GETTERS_';

export default {
  MUTATIONS: {
   SET_ZUORDUNGSLISTE: MUTATION_PREFIX + 'SET_ZUORDUNGSLISTE',
   SET_PRODUCT: MUTATION_PREFIX +'SET_PRODUCT',
   SET_COMBOBOXVALUES: MUTATION_PREFIX + 'LOAD_COMBOBOXVALUES',
   SAVE_PRODUCT_SUCCESS: MUTATION_PREFIX + 'SAVE_PRODUCT_SUCCESS'
  },
  ACTIONS: {
    SEND_VERTRAEGE: ACTIONS_PREFIX + 'SEND_VERTRAEGE',
    GET_VERTRAEGE: ACTIONS_PREFIX + 'GET_VERTRAEGE',
    GET_PROVISION_ABRECHNUNGEN: ACTIONS_PREFIX + 'GET_PROVISION_ABRECHNUNGEN',
    GET_ZUORDNUNGSLISTE:ACTIONS_PREFIX + 'GET_ZUORDNUNGSLISTE',
    GET_PRODUCT_BY_ID: ACTIONS_PREFIX + 'GET_PRODUCT_BY_ID',
    LOAD_COMBOBOXVALUES: ACTIONS_PREFIX + 'LOAD_COMBOBOXVALUES',
    SAVE_PRODUCT: ACTIONS_PREFIX +'SAVE_PRODUCT',
    DELETE_ZUORDNUNG: ACTIONS_PREFIX + 'DELETE_ZUORDNUNG',
    ZUORDNUNGSLISTE_EINLESEN: ACTIONS_PREFIX + 'ZUORDNUNGSLISTE_EINLESEN'
  },
  GETTERS: {
    ZUORDNUNGSLISTE: GETTERS_PREFIX + 'ZUORDNUNGSLISTE',
    GET_PRODUCT: GETTERS_PREFIX +'GET_PRODUCT',
    GET_COMBOBOXVALUES:GETTERS_PREFIX+ 'GET_COMBOBOXVALUES'
  }
}