import axios from 'axios';
import CORE_TYPES from '@/store/core/types';
import TEMPLATES_EDIT_TYPES from './types';

const SERVICE_PATH = 'TemplatesSetup'

const config = {
  defaultSpinner: true
};

export default {
  async [TEMPLATES_EDIT_TYPES.ACTIONS.GET_TEMPLATES_TREE]({ getters, commit }) {
    try {
      const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/templateNodes`, config);
      commit(TEMPLATES_EDIT_TYPES.MUTATIONS.GET_TEMPLATES_TREE, response?.data || {});
    } catch {
      commit(TEMPLATES_EDIT_TYPES.MUTATIONS.GET_TEMPLATES_TREE, []);
    }
  },

  async [TEMPLATES_EDIT_TYPES.ACTIONS.GET_TEMPLATES]({ getters, commit }, payload) {
    try {
      const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/getTemplates`, {
        params: {
          nodeId: payload
        },
        ...config
      });
      commit(TEMPLATES_EDIT_TYPES.MUTATIONS.GET_TEMPLATES, response?.data || []);
    } catch {
      commit(TEMPLATES_EDIT_TYPES.MUTATIONS.GET_TEMPLATES, null);
    }
  },

  async [TEMPLATES_EDIT_TYPES.ACTIONS.GET_PREVIEW]({ getters, commit }, payload) {
    try {
      const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/mailComposer/preview`, payload,  config);
      commit(TEMPLATES_EDIT_TYPES.MUTATIONS.GET_PREVIEW, response?.data);
    } catch {
      commit(TEMPLATES_EDIT_TYPES.MUTATIONS.GET_PREVIEW, null);
    }
  },

  async [TEMPLATES_EDIT_TYPES.ACTIONS.GET_STANDARD_TEMPLATE]({ getters, commit }, payload) {
    try {
      const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/getStandardTemplate`, {
        params: {
          nodeId: payload.nodeId,
          templateId: payload.templateId
        },
        ...config
      });
      commit(TEMPLATES_EDIT_TYPES.MUTATIONS.GET_STANDARD_TEMPLATE, response?.data);
    } catch {
      commit(TEMPLATES_EDIT_TYPES.MUTATIONS.GET_STANDARD_TEMPLATE, null);
    }
  },

  async [TEMPLATES_EDIT_TYPES.ACTIONS.SAVE_TEMPLATE_SETUP]({ getters, commit }, payload) {
    try {
      const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/setTemplates`, payload, config);
      return response
    } catch {
      commit(TEMPLATES_EDIT_TYPES.MUTATIONS.GET_STANDARD_TEMPLATE, null);
    }
  },

  async [TEMPLATES_EDIT_TYPES.ACTIONS.SAVE_TEMPLATE_GRANT]({ getters, commit }, payload) {
    try {

      const path = payload.isWord ? 'change_grant_word_template' : 'change_grant_html_template';
      delete payload.isWord;

      const address = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/${path}`;
      const response = await axios.post(address, payload, config);

      if (response.data) {
        commit(TEMPLATES_EDIT_TYPES.MUTATIONS.UPDATE_TEMPLATE_GRANT, {
          id: response.data.id,
          grant: response.data.grant,
        });
      }

      return response.data
    } catch {
      // empty block
    }
  },
  
  async [TEMPLATES_EDIT_TYPES.ACTIONS.GET_LETTER_TEMPLATES_HTML]({ getters, commit }, payload) {
    const params = {
      params: {
        id: payload
      },
      ...config
    };
    try {
      const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/getLetterEmailTemplatesHtml`, params)
      if (response?.data?.htmlTemplates) {
        commit(TEMPLATES_EDIT_TYPES.MUTATIONS.GET_LETTER_TEMPLATES_HTML, response.data.htmlTemplates);
      }
    } catch {
      commit(TEMPLATES_EDIT_TYPES.MUTATIONS.GET_LETTER_TEMPLATES_HTML, null)
    }
  },

  async [TEMPLATES_EDIT_TYPES.ACTIONS.GET_LETTER_TEMPLATES_WORD]({ getters, commit }, payload) {
    const params = {
      params: {
        id: payload
      },
      ...config
    };
    try {
      const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/getLetterEmailTemplatesWord`, params)
      if (response?.data?.worldTemplates) {
        commit(TEMPLATES_EDIT_TYPES.MUTATIONS.GET_LETTER_TEMPLATES_WORD, response.data.worldTemplates);
      }

    } catch {
      commit(TEMPLATES_EDIT_TYPES.MUTATIONS.GET_LETTER_TEMPLATES_WORD, null)
    }
  },

  async [TEMPLATES_EDIT_TYPES.ACTIONS.CHANGE_TEMPLATE_VISIBILITY]({ getters, dispatch, commit }, payload) {
    try {

      const path = payload.isWord ? 'change_visibility_word_template' : 'change_visibility_html_template';
      delete payload.isWord;

      const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/${path}`, payload, config);
      if (response.data) {
        commit(TEMPLATES_EDIT_TYPES.MUTATIONS.UPDATE_TEMPLATE_VISIBLE, {
          id: response.data.id,
          visible: response.data.visible,
        })
      }
    } catch {
      dispatch(TEMPLATES_EDIT_TYPES.ACTIONS.GET_LETTER_TEMPLATES_HTML, {})
    } 
  },

  async [TEMPLATES_EDIT_TYPES.ACTIONS.GET_LETTER_CATEGORIES]({ getters, commit }) {
    try {
      const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/categories`, config);
      commit(TEMPLATES_EDIT_TYPES.MUTATIONS.GET_LETTER_CATEGORIES, response?.data);
    } catch {
      commit(TEMPLATES_EDIT_TYPES.MUTATIONS.GET_LETTER_CATEGORIES, null);
    }
  },

  async [TEMPLATES_EDIT_TYPES.ACTIONS.GET_BACKGROUND_TEMPLATE]({ getters, commit }) {
    try {
      const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/background_templates`, config);
      commit(TEMPLATES_EDIT_TYPES.MUTATIONS.GET_BACKGROUND_TEMPLATE, response?.data);
    } catch {
      commit(TEMPLATES_EDIT_TYPES.MUTATIONS.GET_BACKGROUND_TEMPLATE, null);
    }
  },

  async [TEMPLATES_EDIT_TYPES.ACTIONS.DELETE_BACKGROUND_TEMPLATE]({ getters, commit }) {
    try {
      await axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/background_templates`, config);
      commit(TEMPLATES_EDIT_TYPES.MUTATIONS.DELETE_FILE_BACKGROUND_TEMPLATE);
    } catch {
      // empty block
    }
  },

  async [TEMPLATES_EDIT_TYPES.ACTIONS.SAVE_BACKGROUND_TEMPLATE]({ getters, commit }, payload) {
    try {
      const customConfig = { ...config, disableDefaultLog: true };
      const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/background_templates`, payload, customConfig);

      commit(TEMPLATES_EDIT_TYPES.MUTATIONS.GET_BACKGROUND_TEMPLATE, response?.data);
    } catch(error) {
      commit(TEMPLATES_EDIT_TYPES.MUTATIONS.GET_BACKGROUND_TEMPLATE, null);
      throw error;
    }
  },

  async [TEMPLATES_EDIT_TYPES.ACTIONS.COPY_LETTER_TEMPLATE]({ getters, dispatch }, payload) {
    try {
      await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/copyLetterEmailHtmlTemplate/${payload.id}`, {}, config);
    } catch {
      // empty block
    } finally {
      dispatch(TEMPLATES_EDIT_TYPES.ACTIONS.GET_LETTER_TEMPLATES_HTML, {})
    }
  },
  async [TEMPLATES_EDIT_TYPES.ACTIONS.DELETE_WORD_TEMPLATE]({ getters, dispatch }, payload) {
    try {
      await axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/deleteLetterWorldTemplate/${payload.id}`, config);
    } catch {
      // empty block
    } finally {
      dispatch(TEMPLATES_EDIT_TYPES.ACTIONS.GET_LETTER_TEMPLATES_WORD, {})
    }
  },
  async [TEMPLATES_EDIT_TYPES.ACTIONS.DELETE_TEMPLATE]({ getters, dispatch }, payload) {
    try {
      await axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/deleteLetterEmailHtmlTemplate/${payload.id}`, config);
    } catch {
      // empty block
    } finally {
      dispatch(TEMPLATES_EDIT_TYPES.ACTIONS.GET_LETTER_TEMPLATES_HTML, {})
    }
  },
  async [TEMPLATES_EDIT_TYPES.ACTIONS.SAVE_LETTER_TEMPLATE]({ getters, commit }, payload) {
    try {
      await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/setLetterEmailHtmlTemplate`, payload, config);
    } catch {
      // empty block
    } finally {
      commit(TEMPLATES_EDIT_TYPES.MUTATIONS.GET_LETTER_TEMPLATES_HTML, null)
    }
  },
  async [TEMPLATES_EDIT_TYPES.ACTIONS.SAVE_WORD_LETTER_TEMPLATE]({ getters, dispatch }, payload) {
    try {
      await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/${SERVICE_PATH}/setLetterWorldTemplate`, payload, config);
    } catch {
      // empty block
    } finally {
      dispatch(TEMPLATES_EDIT_TYPES.ACTIONS.GET_LETTER_TEMPLATES_WORD, {})
    }
  },
  async [TEMPLATES_EDIT_TYPES.ACTIONS.GET_HTML_SHORTCUTS]({ getters, commit, state }) {
    if (state.htmlShortcuts && state.htmlShortcuts.length) {
      return state.htmlShortcuts;
    }
    try {
      const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/shortcut/getData`, config);
      commit(TEMPLATES_EDIT_TYPES.MUTATIONS.SET_HTML_SHORTCUTS, response.data);
      return response.data
    } catch {
      commit(TEMPLATES_EDIT_TYPES.MUTATIONS.SET_HTML_SHORTCUTS, []);
    }
    return []
  },
  async [TEMPLATES_EDIT_TYPES.ACTIONS.SAVE_HTML_SHORTCUTS]({ getters, dispatch }, payload) {
    return axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/shortcut/createOrUpdate`, payload, config);
  },
  async [TEMPLATES_EDIT_TYPES.ACTIONS.DELETE_HTML_SHORTCUTS]({ getters, dispatch }, key) {
    return axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/shortcut/remove?key=${key}`, config);
  },

  [TEMPLATES_EDIT_TYPES.ACTIONS.RESET]({ commit }) {
    commit(TEMPLATES_EDIT_TYPES.MUTATIONS.GET_STANDARD_TEMPLATE, null);
    commit(TEMPLATES_EDIT_TYPES.MUTATIONS.GET_TEMPLATES, null);
    commit(TEMPLATES_EDIT_TYPES.MUTATIONS.GET_PREVIEW, null);
    commit(TEMPLATES_EDIT_TYPES.MUTATIONS.GET_LETTER_TEMPLATES_HTML, null);
    commit(TEMPLATES_EDIT_TYPES.MUTATIONS.GET_LETTER_TEMPLATES_WORD, null);
    commit(TEMPLATES_EDIT_TYPES.MUTATIONS.GET_LETTER_TEMPLATE_EDIT, null);
    commit(TEMPLATES_EDIT_TYPES.MUTATIONS.GET_WORD_TEMPLATE_EDIT, null);
    commit(TEMPLATES_EDIT_TYPES.MUTATIONS.GET_LETTER_CATEGORIES, null);
  },
}
