<template>
  <div class="savings-potential-dashboard-card__container">
    <div class="savings-potential-dashboard-card__content">
      <div class="row py-2">
        <div class="col-12 d-flex justify-content-center">
          <div class="mr-1">Sie können monatlich</div>
          <CurrencyLabel class="mr-1" :value="avgTotal" colored/>
          <div class="mr-1">sparen.</div>
        </div>
      </div>
      <div class="row py-2 mx-4" v-if="!isMobileNativeContext">
        <div class="col-12">
          <ChartHorizontalBullet class="mb-2"
            :current="maxTotal" 
            :average="avgTotal">
          </ChartHorizontalBullet>
        </div>
      </div>
      <div class="row mx-4" v-if="!isMobileNativeContext">
        <div class="col">
          <CurrencyLabel class="mr-1" :value="minTotal" colored/>
        </div>
        <div class="col text-right">
          <CurrencyLabel class="mr-1" :value="maxTotal" colored/>
        </div>
      </div>
      <div class="row mx-4" v-if="!isMobileNativeContext">
        <div class="col">
          <BaseButton @click="newTicket">Sparplan anlegen</BaseButton>
        </div>
      </div>
    </div>

    <div class="savings-potential-dashboard-card__view-more text-right" v-if="!isMobileNativeContext">
      <router-link to="/home/multibanking-account/monatliches-sparpotential">weitere...</router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MULTIBANKING_TYPES from '@/store/multiBanking/types'
import CurrencyLabel from '@/components/core/CurrencyLabel.vue'
import ChartHorizontalBullet from '@/components/charts/ChartHorizontalBullet.vue'
import BaseButton from '@/components/core/BaseButton.vue'
import BRIDGE_TYPES from '@/store/bridge/types'

export default {

  computed: {
    ...mapGetters({
      savingsPotential: MULTIBANKING_TYPES.GETTERS.SAVINGS_POTENTIAL,
      isMobileNativeContext: BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT,
    }),
    avgTotal() {
      return this.onlyGreaterThanZero(this.savingsPotential?.avgTotal || 0);
    },
    maxTotal() {
      return this.onlyGreaterThanZero(this.savingsPotential?.maxTotal || 0);
    },
    minTotal() {
      return this.onlyGreaterThanZero(this.savingsPotential?.minTotal || 0);
    },
  },
  methods: {
    onlyGreaterThanZero(value) {
      return value > 0 && value || 0;
    },
    newTicket() {
      const textInput = 'Ich habe Interesse an regelmäßigem Sparen. Bitte kontaktieren Sie mich.'
      this.$router.push({ name: 'new-ticket', params: { textInput }})
    }
  },
  components: {
    CurrencyLabel,
    ChartHorizontalBullet,
    BaseButton,
  }
}
</script>

<style scoped>
.savings-potential-dashboard-card__container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.savings-potential-dashboard-card__content {
  flex: 1 0 auto;
}
</style>