<template>
  <router-view :key="websiteId" />
</template>

<script>
export default {
  name: 'VersicherungstoolsRouterView',
  computed: {
    websiteId() {
      return this.$route?.params?.websiteId || '';
    },
  },
}
</script>
