<template>
  <DocumentsUploadModal
    v-if="files && additionalUploadInputs"
    :parentId="nodeId"
    :files="files"
    :additionalInputs="additionalUploadInputs"
    :versicherungId="versicherungId"
    @close="close()"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import DOKUMENTENARCHIV_TYPES from '@/store/dokumentenarchiv/types';

import BaseModal from '@/components/core/BaseModal.vue';
import DocumentsUploadModal from '@/components/fileUpload/DocumentsUploadModal.vue';


export default {
  props: {
    nodeId: {
      type: [String, Number],
      default: null,
    },
    versicherungId: {
      type: [String, Number],
      default: null,
      required: false
    },
  },
  emits: ['close'],
  data() {
    return {
      files: null,
    };
  },
  computed: {
    ...mapGetters({
      additionalUploadInputsData: DOKUMENTENARCHIV_TYPES.GETTERS.ADDITIONAL_UPLOAD_INPUTS,
    }),
    additionalUploadInputs() {
      return this.additionalUploadInputsData?.additionalUploadInputs || null;
    },
  },
  methods: {
    open(files) {
      this.files = [...files];
      this.retrieveAdditionalUploadInputs();
    },
    close() {
      this.files = null;
      this.$emit('close');
    },
    retrieveAdditionalUploadInputs() {
      this.$store.dispatch(DOKUMENTENARCHIV_TYPES.ACTIONS.RETRIEVE_ADDITIONAL_UPLOAD_INPUTS, {
        nodeId: this.nodeId,
      });
    },
  },
  components: {
    BaseModal,
    DocumentsUploadModal,
  },
}
</script>
