var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: "Fax", actions: _vm.headerActions },
        on: { "action-NEW_FAX": _vm.sendNewFax }
      }),
      _vm.defaultOptions
        ? _c("BaseFilter", {
            attrs: {
              title: "Fax Filter",
              filterId: "PostfachFaxFilter",
              configFilter: _vm.filterConfig,
              metadata: _vm.metadata,
              defaultOptions: _vm.defaultOptions,
              showSaveButton: "",
              hasSmartSearch: "",
              immidiateSearch: ""
            },
            on: { onFilter: _vm.onFilter }
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.rows.length
            ? _c("Table", {
                attrs: {
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowsPerPage: 25,
                  rowId: "rowId",
                  exportConfig: { roottext: "Postfach" },
                  mobileConfig: {
                    title: "name",
                    headers: ["subject", "date", "stat"]
                  }
                },
                on: {
                  "click-name": function($event) {
                    return _vm.openCustomerNewTab($event.nummer)
                  },
                  "action-RESEND_FAX": _vm.resendFax
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "subject",
                      fn: function(row) {
                        return [
                          !row.mobileTableContext
                            ? _c("DownloadLink", {
                                attrs: {
                                  title: row.subject,
                                  disabled: !row.commId,
                                  downloadServicePath: "/downloadFax",
                                  filename: row.subject.includes(".pdf")
                                    ? row.subject
                                    : row.subject + ".pdf",
                                  queryParams: {
                                    id: row.commId
                                  }
                                }
                              })
                            : _c("div", [_vm._v(_vm._s(row.subject))])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  903809031
                )
              })
            : _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", config: { table: { rows: 5 } } }
              })
            : _c("NoData")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }