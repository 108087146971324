<template>
  <div>
        <PageHeaderActionCard
            title="Übersicht Steuer- und Verlustverrechnungstöpfe"
            :showHeaderCard="true">
            <template v-slot:actionButtons>
                <BaseCollapsable :showSection="showExpanded">
                    <template v-slot:title>
                        Hinweise:
                    </template>
                    <template v-slot:content>
                        <ul>
                            <div class="row" v-for="(hinweis, index) of hinweise" :key="index">
                                <div class="col-12">
                                <li>
                                    <span class="font-bold">{{ hinweis.label }}: </span> 
                                    <span>{{hinweis.text}}</span>
                                </li>
                                </div>
                            </div>
                        </ul>
                    </template>
                </BaseCollapsable>
            </template>
        </PageHeaderActionCard>

        <BaseFilter v-if="!isCustomer"
            filterId="steuerVerlustSearchAdvanced" 
            :metadata="searchMenu" 
            :configFilter="configFilter"
            hasSmartSearch
            :defaultOptions="defaultOptions"
            @onFilter="handleSearch($event)"
            @onFilterZurucksetzen="$emit('onFilterZurucksetzen')">
            <slot></slot>
        </BaseFilter>

        <div class="box__container">
            <div v-if="rows && rows.length">
                <Table
                    :headers="headers"
                    :rows="rows"
                    :rowsPerPage="20"
                    rowId="steuerVerlust"
                    @click-kundennr="openCustomerNewTab"
                >
                </Table>
            </div>
            <NoData v-else content="Keine Informationen" />
        </div>
    </div>
</template>

<script>
import PageHeaderActionCard from '@/components/core/PageHeaderActionCard.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn } from "@/components/table2/table_util.js";
import { mapGetters } from 'vuex';
import BERICHTE_TYPES from '@/store/berichte/types';
import mixin from '@/mixins/limit/limit-mixin.js';
import CORE_TYPES from '@/store/core/types';
import BaseFilter from '@/components/core/BaseFilter.vue';
import BaseCollapsable from '@/components/core/BaseCollapsable.vue'
import NoData from '@/components/core/NoData.vue';

export default {
    mixins: [mixin],
    components: {
        Table,
        PageHeaderActionCard,
        BaseFilter,
        BaseCollapsable,
        NoData,
    },
     data() {
        return {
            configFilter: { 
                placeholderForDefSearchInput: 'Kundennummer',
                kundenNummerKey: 'kundennr',
                noResetOnDefaultSearchInputExit: true,
            },
            hinweise: [{label: 'Steuertopf Aktien', text: 'Steuern für Kursgewinne aus Aktien.'},
                {label: 'Verlusttopf Aktien', text: 'Kursverluste aus Aktien.'},
                {label: 'Steuertopf Sonstige', text: 'Steuern für Dividenden und Zinsen sowie Kursgewinne aus Fonds, Anleihen, Zertifikaten etc.'},
                {label: 'Verlusttopf Sonstige', text: 'Gezahlte Stückzinsen und Zwischengewinne sowie Kursverluste aus Fonds, Anleihen, Zertifikaten etc.'},
                {label: 'Quellensteuertopf', text: 'Anrechenbare ausländische Quellensteuer.'},],
            showExpanded: true,
            initialFilters: {
                jahr: '',
                gesellschaft: 'Alle',
            },
        }
    },
    mounted() {
        this.$store.dispatch(BERICHTE_TYPES.ACTIONS.GET_STEUER_VERLUST_LIST, this.initialFilters);
        this.$store.dispatch(BERICHTE_TYPES.ACTIONS.GET_GESELLSCHAFTEN_AUSWAHL);
    },
    computed: {
        ...mapGetters({
            list: BERICHTE_TYPES.GETTERS.STEUER_VERLUST_LIST,
            isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
            gesellschaftList: BERICHTE_TYPES.GETTERS.GESELLSCHAFTEN_AUSWAHL,
        }),
        gesellschaftAuswahl() {
            return this.gesellschaftList || [];
        },
        headers() {
            const headers = { 
                lockedLeft: [
                    TextColumn('gesellschaft', 'Gesellschaft'),
                    TextColumn('jahr', 'Jahr'),
                ],
                center: [
                    TextColumn('depotsCount', 'Anzahl der Depots'),
                    TextColumn('steuerSonst', 'Steuertopf Sonstige'),
                    TextColumn('verlustSonst', 'Verlusttopf Sonstige'),
                    TextColumn('steuerAktien', 'Steuertopf Aktien'),
                    TextColumn('verlustAktien', 'Verlusttopf Aktien'),
                    TextColumn('quellenSteuer', 'Quellensteuertopf'),
                    
                ],
            };
            if (!this.isCustomer) {
                headers.lockedLeft.splice(0, 0, 
                    TextColumn('kundennr', 'Kundennr').makeLink(),
                );
            }
            return headers;
        },
        rows() {
            return this.list?.map((elem, index) => {
                return {
                    ...elem, 
                    index: index
                }
            }) || [];
        },
        searchMenu() {
            return [
                {
                    type: 'group',
                    key: 'allgemein',
                    label: 'Allgemein',
                    menuItems: [
                        {
                            type: 'text',
                            label: 'Kundennummer',
                            key: 'kundennr',
                        },
                        {
                            type: 'text',
                            label: 'Jahr',
                            key: 'jahr',
                        },
                        {
                            type: 'combobox',
                            label: 'Gesellschaft',
                            key: 'gesellschaft',
                            comboOptions: this.gesellschaftAuswahl,
                        },
                    ]
                },
            ]
        },
        defaultOptions() {
            const filters = {};
            Object.keys(this.initialFilters).forEach(key => filters[key] = { value: this.initialFilters[key]});
            return filters;
        },
    },
    methods: {
        handleSearch(filterArr = []) {
            const filters = {};
            filterArr.forEach(filter => filters[filter.key] = filter.value);
            this.$store.dispatch(BERICHTE_TYPES.ACTIONS.GET_STEUER_VERLUST_LIST, filters);
        },
    },
}
</script>

<style>

</style>