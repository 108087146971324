var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "risiko-analyse-table-component" }, [
    _vm.hasAnalyseTableHeaders
      ? _c(
          "div",
          { staticClass: "risiko-analyse-table-component-buttons" },
          [
            _c(
              "BaseButton",
              {
                attrs: { disabled: _vm.loadingAntrag },
                on: {
                  click: function($event) {
                    return _vm.handleErneutAnalysieren()
                  }
                }
              },
              [_vm._v("Erneut Analysieren")]
            )
          ],
          1
        )
      : _vm._e(),
    _vm.hasAnalyseTableHeaders ? _c("hr") : _vm._e(),
    _vm.hasAnalyseTableHeaders
      ? _c(
          "table",
          {
            staticClass:
              "risiko-analyse-table-component-table fc-table fc-table-divider"
          },
          [
            _c("thead", [
              _c(
                "tr",
                _vm._l(
                  _vm.config.positionFields.analyse_table_headers,
                  function(header, headerIndex) {
                    return _c(
                      "th",
                      { key: headerIndex, staticClass: "text-right" },
                      [_vm._v(" " + _vm._s(header.label) + " ")]
                    )
                  }
                ),
                0
              )
            ]),
            _c(
              "tbody",
              _vm._l(
                _vm.config.positionFields.analyse_table_left_side_cols,
                function(row, rowIndex) {
                  return _c(
                    "tr",
                    { key: rowIndex },
                    [
                      _c("td", { staticClass: "text-nowrap" }, [
                        _vm._v(" " + _vm._s(row.label) + " ")
                      ]),
                      _vm._l(_vm.tableColumns[rowIndex], function(
                        col,
                        colIndex
                      ) {
                        return _c(
                          "td",
                          { key: colIndex, staticClass: "text-right" },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.textWithoutFormatting(col)) + " "
                            )
                          ]
                        )
                      })
                    ],
                    2
                  )
                }
              ),
              0
            )
          ]
        )
      : _vm._e(),
    _vm.data
      ? _c(
          "h4",
          {
            staticClass: "risiko-analyse-table-component-problems-title",
            class:
              _vm.data.probleme && !_vm.data.probleme.length
                ? "color-success"
                : "color-danger"
          },
          [_vm._v(" " + _vm._s(_vm.data["DISPLAY_HEADER_PROBLEME"]) + " ")]
        )
      : _vm._e(),
    _vm.data.probleme && _vm.data.probleme.length
      ? _c(
          "ul",
          {
            staticClass:
              "risiko-analyse-table-component-problems-container list list-divider mb-0"
          },
          _vm._l(_vm.data.probleme, function(problem, problemIndex) {
            return _c("li", { key: problemIndex }, [
              _c("h5", { staticClass: "mb-1 font-bold" }, [
                _vm._v(_vm._s(problem.LABEL))
              ]),
              _c("p", { staticClass: "mt-0 mb-3" }, [
                _vm._v(_vm._s(problem.ERRORMESSAGE))
              ]),
              problem.buttons && problem.buttons.length > 0
                ? _c(
                    "div",
                    _vm._l(problem.buttons, function(button, buttonIndex) {
                      return _c(
                        "div",
                        {
                          key: buttonIndex,
                          class: buttonIndex > 0 ? "mt-2" : ""
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: { href: "javascript:;" },
                              on: {
                                click: function($event) {
                                  return _vm.handleButtonClick(button, problem)
                                }
                              }
                            },
                            [_vm._v(_vm._s(button.LABEL))]
                          )
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e()
            ])
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }