var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "input-chips__container",
          class: {
            "one-line": _vm.isOneRow,
            "is-dropdown-active": _vm.isShowDropdown
          }
        },
        [
          _c(
            "label",
            { staticClass: "input-forms__label-container flex-strech" },
            [
              _vm.label
                ? _c("div", { staticClass: "label-content" }, [
                    _c("span", { staticClass: "pr-3" }, [
                      _vm._v(_vm._s(_vm.label))
                    ]),
                    _vm.warning
                      ? _c("span", { staticClass: "color-danger" }, [
                          _vm._v(_vm._s(_vm.warning))
                        ])
                      : _vm._e()
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  ref: "inputContainerEl",
                  class: { "forms__input--half-size": this.isComponentHalfSize }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "input-chips__input-container",
                      class: { disabled: _vm.disableInput },
                      on: { click: _vm.onClickChipsContainer }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "input-chips__chips-container" },
                        [
                          _vm._l(_vm.valueFiltered, function(selected, index) {
                            return _c(
                              "span",
                              {
                                key: index,
                                staticClass: "input-chips__chips unselectable",
                                class: {
                                  clickable: _vm.isClickableCheck(selected),
                                  disabled: _vm.disableInput
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.clickItem(selected)
                                  }
                                }
                              },
                              [
                                _vm.isClickableCheck(selected)
                                  ? _c(
                                      "a",
                                      {
                                        class: {
                                          "color-danger": _vm.isRedFlagged(
                                            selected
                                          )
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(selected && selected.label) +
                                            " "
                                        )
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        class: {
                                          "color-danger": _vm.isRedFlagged(
                                            selected
                                          )
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(selected && selected.label) +
                                            " "
                                        )
                                      ]
                                    ),
                                selected &&
                                _vm.showRemoveIcon &&
                                _vm.isRemovable(selected.value)
                                  ? _c("ph-x-circle", {
                                      staticClass: "clickable ml-1 del-icon",
                                      attrs: { size: 16 },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          return _vm.removeItem(selected)
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          }),
                          _c("input", {
                            ref: "inputText",
                            staticClass: "input-chips__input",
                            attrs: { type: "text", disabled: _vm.disableInput },
                            domProps: { value: _vm.inputText },
                            on: {
                              input: _vm.onInputText,
                              focus: _vm.onFocus,
                              blur: _vm.onBlur,
                              keyup: [
                                function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  $event.preventDefault()
                                  return _vm.onEnter.apply(null, arguments)
                                },
                                function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "up",
                                      38,
                                      $event.key,
                                      ["Up", "ArrowUp"]
                                    )
                                  ) {
                                    return null
                                  }
                                  $event.preventDefault()
                                  return _vm.showDropdown.apply(null, arguments)
                                },
                                function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "down",
                                      40,
                                      $event.key,
                                      ["Down", "ArrowDown"]
                                    )
                                  ) {
                                    return null
                                  }
                                  $event.preventDefault()
                                  return _vm.showDropdown.apply(null, arguments)
                                },
                                function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "delete",
                                      [8, 46],
                                      $event.key,
                                      ["Backspace", "Delete", "Del"]
                                    )
                                  ) {
                                    return null
                                  }
                                  $event.preventDefault()
                                  return _vm.onDelete.apply(null, arguments)
                                }
                              ],
                              keydown: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "tab",
                                    9,
                                    $event.key,
                                    "Tab"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.onEnter.apply(null, arguments)
                              }
                            }
                          })
                        ],
                        2
                      ),
                      _vm.showToggle
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "input-chips__toggle-dropdown btn-clear",
                              class: { clickable: !_vm.disableInput },
                              attrs: {
                                type: "button",
                                tabindex: "-1",
                                disabled: _vm.disableInput
                              },
                              on: {
                                blur: _vm.onBlur,
                                click: _vm.toggleDropdown
                              }
                            },
                            [_c("ph-caret-down", { attrs: { size: 16 } })],
                            1
                          )
                        : _vm._e()
                    ]
                  ),
                  _vm.isShowDropdown
                    ? _c(
                        "div",
                        {
                          ref: "dropdownContainerEl",
                          staticClass:
                            "input-chips__dropdown-container-wrapper unselectable"
                        },
                        [
                          _vm.loading
                            ? _c(
                                "GhostLoading",
                                [
                                  _c("Block", {
                                    attrs: { type: "form-input" }
                                  }),
                                  _c("Block", {
                                    attrs: { type: "form-input" }
                                  }),
                                  _c("Block", { attrs: { type: "form-input" } })
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.loading
                            ? _c(
                                "ul",
                                {
                                  staticClass: "input-chips__dropdown-container"
                                },
                                [
                                  _vm._l(_vm.availableOptionsFiltered, function(
                                    available,
                                    index
                                  ) {
                                    return _c(
                                      "li",
                                      {
                                        key: available.value + "-" + index,
                                        staticClass:
                                          "input-chips__dropdown-item",
                                        class: {
                                          clickable: !_vm.disableInput,
                                          "input-chips__dropdown-item--selected":
                                            index === _vm.dropdownSelectedIndex
                                        },
                                        attrs: { tabindex: index + 1 },
                                        on: {
                                          mousemove: function($event) {
                                            return _vm.onHover(index)
                                          },
                                          click: function($event) {
                                            return _vm.onClickDropDownItem(
                                              available
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(available.label) + " "
                                        )
                                      ]
                                    )
                                  }),
                                  _vm.noData && !_vm.supportCustomValue
                                    ? _c(
                                        "li",
                                        {
                                          staticClass:
                                            "input-chips__dropdown-item",
                                          attrs: { tabindex: "0" }
                                        },
                                        [_vm._v(" Keine daten ")]
                                      )
                                    : _vm._e(),
                                  _vm.noData && _vm.supportCustomValue
                                    ? _c(
                                        "li",
                                        {
                                          key: _vm.inputText,
                                          staticClass:
                                            "input-chips__dropdown-item",
                                          class: {
                                            clickable: !_vm.disableInput,
                                            "input-chips__dropdown-item--selected": !_vm.disableInput
                                          },
                                          attrs: { tabindex: "0" },
                                          on: {
                                            mousemove: function($event) {
                                              return _vm.onHover(0)
                                            },
                                            click: function($event) {
                                              return _vm.onClickDropDownItem({
                                                label: _vm.inputText,
                                                value: _vm.inputText
                                              })
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.inputText) + " "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                2
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            ]
          )
        ]
      ),
      _vm.isValidationConfigured()
        ? [
            _vm.validation
              ? _c(
                  "div",
                  { key: _vm.validation.updated },
                  _vm._l(
                    _vm.validation.getErrors(
                      _vm.validationPath,
                      _vm.validateUntouched
                    ),
                    function(error) {
                      return _c(
                        "div",
                        { key: error, staticClass: "fc-form-danger" },
                        [_vm._v(" " + _vm._s(error) + " ")]
                      )
                    }
                  ),
                  0
                )
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }