export const MUTATION_PREFIX = 'WERT:';
export const ACTIONS_PREFIX = 'WERT_ACTIONS_';
export const GETTERS_PREFIX = 'WERT_GETTERS_';

export default {
  MUTATIONS: {
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    SET_PROGRESS_DETAILS_CONFIG: MUTATION_PREFIX + 'SET_PROGRESS_DETAILS_CONFIG',
    SET_PERFORMANCE_DATA: MUTATION_PREFIX + 'SET_PERFORMANCE_DATA',
    RENEW_PERFORMANCE_DATA: MUTATION_PREFIX + 'RENEW_PERFORMANCE_DATA',
    
    SET_HISTORICAL_PERFORMANCE: MUTATION_PREFIX + 'SET_HISTORICAL_PERFORMANCE',
    RENEW_HISTORICAL_PERFORMANCE: MUTATION_PREFIX + 'RENEW_HISTORICAL_PERFORMANCE',
    SET_REALIZED_PROFIT: MUTATION_PREFIX + 'SET_REALIZED_PROFIT',
    SET_YEAR_PERFORMANCE_DATA: MUTATION_PREFIX + 'SET_YEAR_PERFORMANCE_DATA',
    RENEW_YEAR_PERFORMANCE_DATA: MUTATION_PREFIX + 'RENEW_YEAR_PERFORMANCE_DATA',
    SET_TRANSACTIONS: MUTATION_PREFIX + 'SET_TRANSACTIONS',
    RENEW_TRANSACTIONS: MUTATION_PREFIX + 'RENEW_TRANSACTIONS',
    SET_DEPOT_CONFIGS: MUTATION_PREFIX + 'SET_DEPOT_CONFIGS',
    RENEW_REALIZED_PROFIT:MUTATION_PREFIX+ 'RENEW_REALIZED_PROFIT',
    SET_PERFORMANCE_PAYLOAD:MUTATION_PREFIX+ 'SET_PERFORMANCE_PAYLOAD',
    SET_DEPOT_ID:MUTATION_PREFIX+ 'SET_DEPOT_ID',
    
  },

  ACTIONS: {
    RETRIEVE_INITIAL_WERTE: ACTIONS_PREFIX + 'RETRIEVE_INITIAL_WERTE',
    RETRIEVE_PERFORMANCE_SEQUENCE: ACTIONS_PREFIX+ 'RETRIEVE_PERFORMANCE_SEQUENCE',
    RETRIEVE_PERFORMANCE_FILTERED: ACTIONS_PREFIX+ 'RETRIEVE_PERFORMANCE_FILTERED',
    RETRIEVE_PROGRESS_DETAILS_CONFIG: ACTIONS_PREFIX+ 'RETRIEVE_PROGRESS_DETAILS_CONFIG',
    RETRIEVE_PROGRESS_DETAILS_CHART_DATA: ACTIONS_PREFIX+ 'RETRIEVE_PROGRESS_DETAILS_CHART_DATA',
    RETRIEVE_DEPOT_CONFIG: ACTIONS_PREFIX+ 'RETRIEVE_DEPOT_CONFIG',
    RETRIEVE_HISTORICAL_PERFORMANCE: ACTIONS_PREFIX+ 'RETRIEVE_HISTORICAL_PERFORMANCE',
    RETRIEVE_REALIZED_PROFIT: ACTIONS_PREFIX+ 'RETRIEVE_REALIZED_PROFIT',
    RETRIEVE_YEAR_PERFORMANCE_DATA: ACTIONS_PREFIX+ 'RETRIEVE_YEAR_PERFORMANCE_DATA',
    RETRIEVE_TRANSACTIONS: ACTIONS_PREFIX+ 'RETRIEVE_TRANSACTIONS',
    RETRIEVE_DEPOT_CONFIGS: ACTIONS_PREFIX + 'RETRIEVE_DEPOT_CONFIGS',

  },

  GETTERS: {
    GET_PERFORMANCE_DATA: GETTERS_PREFIX+ 'GET_PERFORMANCE_DATA',
    GET_HISTORICAL_DATA: GETTERS_PREFIX+ 'GET_HISTORICAL_DATA',
    GET_REALIZED_PROFIT: GETTERS_PREFIX+ 'GET_REALIZED_PROFIT',  
    GET_YEAR_PERFORMANCE_DATA: GETTERS_PREFIX+ 'GET_YEAR_PERFORMANCE_DATA',  
    GET_TRANSACTIONS: GETTERS_PREFIX + 'GET_TRANSACTIONS',
    GET_DEPOT_CONFIGS: GETTERS_PREFIX + 'GET_DEPOT_CONFIGS',
    GET_PERFORMACE_PAYLOAD: GETTERS_PREFIX + 'GET_PERFORMACE_PAYLOAD',
    
  }
}