var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("div", { staticClass: "text-bold" }, [
          _vm._v("Maximale Richt-Bandbreite")
        ]),
        _c("div", [
          _vm._v(
            ' Die maximale Richt-Bandbreite ist der Wert, den die angegebene Wertpapierkategorie höchstens in der Strategie haben darf. Diese ist verpflichtend und wird auch in den Vermögensverwaltungsvertrag aufgenommen. Um eine hohe Flexibilität innerhalb der Vermögensverwaltung zu bewahren, empfehlen wir Ihnen den hier eingetragenen Wert möglichst hoch zu setzen (mindestens 5% über der gewünschten Allokationsgröße; Beispiel: Sie möchten 30% Aktienquote. Der eingestellte Wert bei "Aktien/Mischfonds aktienlastig" sollte mindestens 35% sein). '
          )
        ]),
        _c("div", { staticClass: "font-bold" }, [
          _vm._v(
            " Die hier eingestellten Werte gelten für die gesamte Lebensdauer der Vermögensverwaltung. "
          )
        ]),
        _c("div", [
          _vm._v(
            " Daher kann bei einer Kategorieeinstellung mit einem maximalem Anteil von 0% auch nie ein Wertpapier der entsprechenden Kategorie hinzugefügt werden. "
          )
        ]),
        _c("div", { staticClass: "font-bold" }, [
          _vm._v(
            " Um stets einen ausreichenden Puffer für Kursänderungen sicherzustellen, müssen alle Werte hier mindestens 5% höher sein als die Verteilung in den Varianten. "
          )
        ]),
        _c("div", [
          _vm._v(
            " Ist diese Bedingung nicht einhalten, können keine Varianten beantragt werden. "
          )
        ]),
        _c("div", [_vm._v("Eingestellter Wert")]),
        _c("InputField", {
          attrs: {
            isComponentHalfSize: "",
            id: "form.maxAnteilGeldmarktfonds",
            type: "percent",
            precision: 0,
            label: "Geldmarktfonds/Liquidität",
            validateUntouched: "",
            disabled: _vm.form.disabled
          },
          on: {
            change: function($event) {
              return _vm.updateStore(
                { maxAnteilGeldmarktfonds: $event },
                "settings"
              )
            }
          },
          model: {
            value: _vm.form.maxAnteilGeldmarktfonds,
            callback: function($$v) {
              _vm.$set(_vm.form, "maxAnteilGeldmarktfonds", $$v)
            },
            expression: "form.maxAnteilGeldmarktfonds"
          }
        }),
        _c("InputField", {
          attrs: {
            isComponentHalfSize: "",
            id: "form.maxAnteilRentenMischfonds",
            type: "percent",
            precision: 0,
            label: "Renten-/Mischfonds rentenlastig",
            validateUntouched: "",
            disabled: _vm.form.disabled
          },
          on: {
            change: function($event) {
              return _vm.updateStore(
                { maxAnteilRentenMischfonds: $event },
                "settings"
              )
            }
          },
          model: {
            value: _vm.form.maxAnteilRentenMischfonds,
            callback: function($$v) {
              _vm.$set(_vm.form, "maxAnteilRentenMischfonds", $$v)
            },
            expression: "form.maxAnteilRentenMischfonds"
          }
        }),
        _c("InputField", {
          attrs: {
            isComponentHalfSize: "",
            id: "form.maxAnteilMischfonds",
            type: "percent",
            precision: 0,
            label: "Mischfonds ausgewogen",
            validateUntouched: "",
            disabled: _vm.form.disabled
          },
          on: {
            change: function($event) {
              return _vm.updateStore(
                { maxAnteilMischfonds: $event },
                "settings"
              )
            }
          },
          model: {
            value: _vm.form.maxAnteilMischfonds,
            callback: function($$v) {
              _vm.$set(_vm.form, "maxAnteilMischfonds", $$v)
            },
            expression: "form.maxAnteilMischfonds"
          }
        }),
        _c("InputField", {
          attrs: {
            isComponentHalfSize: "",
            id: "form.maxAnteilAktienMischfonds",
            type: "percent",
            precision: 0,
            label: "Aktien-/Mischfonds aktienlastig",
            validateUntouched: "",
            disabled: _vm.form.disabled
          },
          on: {
            change: function($event) {
              return _vm.updateStore(
                { maxAnteilAktienMischfonds: $event },
                "settings"
              )
            }
          },
          model: {
            value: _vm.form.maxAnteilAktienMischfonds,
            callback: function($$v) {
              _vm.$set(_vm.form, "maxAnteilAktienMischfonds", $$v)
            },
            expression: "form.maxAnteilAktienMischfonds"
          }
        }),
        _c("InputField", {
          attrs: {
            isComponentHalfSize: "",
            id: "form.maxAnteilRohstofffonds",
            type: "percent",
            precision: 0,
            label: "Rohstofffonds",
            validateUntouched: "",
            disabled: _vm.form.disabled
          },
          on: {
            change: function($event) {
              return _vm.updateStore(
                { maxAnteilRohstofffonds: $event },
                "settings"
              )
            }
          },
          model: {
            value: _vm.form.maxAnteilRohstofffonds,
            callback: function($$v) {
              _vm.$set(_vm.form, "maxAnteilRohstofffonds", $$v)
            },
            expression: "form.maxAnteilRohstofffonds"
          }
        }),
        _c("InputField", {
          attrs: {
            isComponentHalfSize: "",
            id: "form.maxAnteilAlternativeAnlagefonds",
            type: "percent",
            precision: 0,
            label: "Alternative Anlagefonds",
            validateUntouched: "",
            disabled: _vm.form.disabled
          },
          on: {
            change: function($event) {
              return _vm.updateStore(
                { maxAnteilAlternativeAnlagefonds: $event },
                "settings"
              )
            }
          },
          model: {
            value: _vm.form.maxAnteilAlternativeAnlagefonds,
            callback: function($$v) {
              _vm.$set(_vm.form, "maxAnteilAlternativeAnlagefonds", $$v)
            },
            expression: "form.maxAnteilAlternativeAnlagefonds"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }