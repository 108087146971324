var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-forms__container" }, [
    _c(
      "div",
      { staticClass: "input-forms__label-container" },
      [
        _c("FormLabel", { attrs: { label: _vm.label, config: _vm.config } }),
        _vm.config && _vm.config.subtitle
          ? _c("div", [_vm._v(" " + _vm._s(_vm.config.subtitle) + " ")])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "input-forms__input-container mt-4 mb-2" },
          [
            _c(
              "SortableList",
              {
                attrs: { items: _vm.items },
                on: {
                  orderChanged: function($event) {
                    return _vm.onOrderChanged($event)
                  }
                }
              },
              [
                _c(
                  "ul",
                  {
                    staticClass: "list-bordered",
                    attrs: { "data-sortable-container": "" }
                  },
                  _vm._l(_vm.items, function(componentItem) {
                    return _c(
                      "li",
                      {
                        key: componentItem.id + "" + componentItem.position,
                        staticClass: "list-bordered-item",
                        attrs: { "data-sortable-item": "" }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-content-between" },
                          [
                            _c(
                              "div",
                              { staticStyle: { width: "100%" } },
                              [
                                _c("AntragComponent", {
                                  attrs: {
                                    component: componentItem,
                                    data: _vm.antragData,
                                    isComponentHalfSizeEnabled: "",
                                    comboboxSelection: componentItem.values
                                  },
                                  on: {
                                    dataChange: function($event) {
                                      return _vm.onComponentDataChange($event)
                                    }
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { attrs: { "data-sortable-item-handler": "" } },
                              [
                                _c("ph-list", {
                                  attrs: { size: 16, weight: "bold" }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ]
                    )
                  }),
                  0
                )
              ]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }