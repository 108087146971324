export const MUTATION_PREFIX = 'GDV_MUTATIONS_';
export const ACTIONS_PREFIX = 'GDV_ACTIONS_';
export const GETTERS_PREFIX = 'GDV_GETTERS_';

export default {
    MUTATIONS: {
        RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
        SAVE_FILTERS: MUTATION_PREFIX + 'SAVE_FILTERS',
        SAVE_SORTING: MUTATION_PREFIX + 'SAVE_SORTING',
        CACHE_ROWS: MUTATION_PREFIX + 'CACHE_ROWS',
        SAVE_LAST_ROW: MUTATION_PREFIX + 'SAVE_LAST_ROW',
        SAVE_CURRENT_PAGE_SIZE: MUTATION_PREFIX + 'SAVE_CURRENT_PAGE_SIZE',
    },
    ACTIONS: {
    },
    GETTERS: {
        SAVED_FILTERS: GETTERS_PREFIX + 'SAVED_FILTERS',
        SAVED_SORTING: GETTERS_PREFIX + 'SAVE_SORTING',
        CACHED_ROWS: GETTERS_PREFIX + 'CACHED_ROWS',
        LAST_ROW: GETTERS_PREFIX + 'LAST_ROW',
        CURRENT_PAGE_SIZE: GETTERS_PREFIX + 'CURRENT_PAGE_SIZE',
    }
}
