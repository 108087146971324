<template>
  <div v-on:submit.prevent="noop" v-on:keyup.enter="doSearch()">
      <InputField placeholder="(ISIN, WKN, Name)" v-model="searchKey" type="text"></InputField>
      <BaseButton @click="doSearch()" isPrimaray>Suchen</BaseButton>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import WERTPAPIERINFO_TYPES from '@/store/wertpapierinfo/types';

import InputField from "@/components/core/forms/InputField.vue";
import BaseButton from "@/components/core/BaseButton.vue";

export default {
    data() {
      return {
        searchKey: '',
        sectionCSSWidth: 82,
      }
    },
    computed: {
      ...mapGetters({
        wertpapierinfoBaseURL: WERTPAPIERINFO_TYPES.GETTERS.BASE_URL,
      }),
    },
    components: {
        InputField,
        BaseButton
    },
    mounted(){
    },
    methods: {
      doSearch() {
        this.$router.push({path: `${this.wertpapierinfoBaseURL}/wertpapierinfo`, query: {searchParam: this.searchKey } })
        
      }
    }

}
</script>

<style scoped>
.linear-scale {
  position: relative;
}

.sections {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  height: 16px;
}

.section-item {
  flex: 0 1 auto;
  width: 80px;
  margin-right: 2px;
}

.top-cursor {
  position: absolute;
  left: 0px;
  top: -7px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 15px 7.5px 0 7.5px;
  border-color: var(--color-text) transparent transparent transparent;
  -webkit-transform: rotate(360deg);
}
.top-cursor-color {
  color: var(--color-text);
}

.top-cursor:hover {
  transform: scale(1.5);
}

.bottom-cursor {
  position: absolute;
  left: 0px;
  bottom: -7px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 7.5px 15px 7.5px;
  border-color: transparent transparent var(--color-text) transparent;
  -webkit-transform: rotate(360deg);
}

.bottom-cursor-color {
  color: var(--color-text);
}

.bottom-cursor:hover {
  transform: scale(1.5);
}

.scale-label {
  position: absolute;
  bottom: -24px;
  font-size: 12px;
}

.label-right {
  right: 0px
}

.sr1 {
  background-color: #1E7B33;
}
.sr2 {
  background-color: #28A745;
}
.sr3 {
  background-color: #28A745;
}
.sr4 {
  background-color: #FFA823;
}
.sr5 {
  background-color: #E54128;
}
.sr6 {
  background-color: #E54128;
}
.sr7 {
  background-color: #B62B16;
}

.text-right {
  text-align: right;
  justify-content: flex-end;
}

@media screen and (max-width: 768px) {
  .scale-60 {
    transform: scale(0.6);
  }
}
</style>