var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.label
    ? _c(
        "span",
        {
          staticClass: "input-forms__label-content mt-2",
          staticStyle: { "font-weight": "bold" }
        },
        [_vm._v(_vm._s(_vm.label))]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }