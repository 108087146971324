<template>
  <div class="config-edit-param-label__container">
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation
      title="Infofilme / Webinare"
      :id="$appNavigation.currentOptionMenuId"
    />
    

    <DragnDropArea v-if="isIntern" hoverText="Datei hier ablegen" @files="addDocument($event)">
      <div class="box__container">
        <div class="d-block justify-content-between">
            <div class="d-flex justify-content-between hide-on-large-screen">
              <div class="box__title">Neue Infofilme</div>
              <BaseContextMenu>
                <ContextMenuItem @click="openAddDocument()">
                  <slot>Infofilme hochladen</slot>
                </ContextMenuItem>
              </BaseContextMenu>
            </div>

            <div class="hide-on-small-screen">
              <div class="box__title">Neue Infofilme</div>
              <div class="d-flex d-md-block mt-1 layout__negative-margin--8 antrag-action-button__container justify-content-center">
                
                <BaseButton isSecondary @click="openAddDocument()">
                  <slot>Infofilme hochladen</slot>
                </BaseButton>
                
              </div>
            </div>
        </div>
      </div>
    </DragnDropArea>

    <BaseFilter
      title="Infofilme-Filter"
      filterId="ff81088d-38cb-46e7-bdfc-11a80b34409d"
      :metadata="filterMetadata"
      :configFilter="configFilter"
      :showSaveButton="true"
      :defaultOptions="aktFilter"
      @onFilter="search($event)"
      @onFilterZurucksetzen="search(null)"
    />
    <div class="box__container">
      <Table
        title="Infofilme-Liste"
        tableId="b65a1742-555d-4152-9259-6037d5fba9f6"
        :headers="headers"
        :rows="rows"
        @action-EDIT="editFile"
      >
        <template #BEZEICHNUNG="row">
          <DownloadLink 
            :title="row.BEZEICHNUNG || row.NAME"
            :filename="row.NAME"
            downloadServicePath="/downloadFileById"
            :queryParams="{id: row.FILE_ID}"
          />
        </template>
      </Table>
    </div>

    <BaseModal ref="addDocument"
      :labelButtonConfirm="fileNeu ? 'Upload starten':'Speichern'"
      :showCancelButton="true"
      @onConfirmButton="saveChanges"
      @onCancelButton="closeAddDocumentModal"
      @close="onClose"
      >
      <template v-slot:modalTitle>
        Infofilm und Webinare
      </template>
      <div>
        <DragnDropArea hoverText="Datei hier ablegen" @files="addDocument($event)">
          <div>
            <template v-if="isEdit">
              <div v-if="!fileNeu">Dateiname: {{editName}}</div>
              <InputToggleSwitch label="Neue Datei" inLineLabel v-model="fileNeu" />
            </template>
            <div class="row" v-if="fileNeu">
              <div class="col">
                <BaseButton isSecondary class="upload-btn">
                  <slot>Datei hochladen</slot>
                  <input type="file" :accept="acceptFileTypes" @change="onAddFiles($event.target.files)" multiple />
                </BaseButton>
              </div>
              <div class="col">{{fileName}}</div>
            </div>
            <hr>
            <InputToggleSwitch label="Sichtbar für Vermittlern" inLineLabel v-model="fileSichtbar" />
            <InputField label="Bezeichnung" v-model="fileTitle" />
            <InputTextArea label="Bemerkung" v-model="fileDescription" />
            <div>
              <label class="gridlabel">Stand</label>
              <div>
                <DatePickerField dateFormat="DD.MM.YYYY" v-model="fileStand" />
              </div>
            </div>
          </div>
        </DragnDropArea>

      </div>
    </BaseModal>

  </div>
</template>

<script>
import BaseButton from '@/components/core/BaseButton.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { mapGetters } from 'vuex';
import INFOFILME_TYPES from '@/store/infofilme/types'
import CORE_TYPES from '@/store/core/types'
import DragnDropArea from '@/components/fileUpload/DragnDropArea.vue';
import { uploadFileChunked } from '@/helpers/upload-helper';
import Table from "@/components/table2/Table.vue";
import BaseContextMenu from '@/components/core/BaseContextMenu.vue';
import ContextMenuItem from '@/components/core/base-context-menu/ContextMenuItem.vue';
import { SlotColumn, ActionColumn, SimpleAction, TextColumn, DateColumn } from '@/components/table2/table_util';
import DownloadLink from '@/components/core/download/DownloadLink.vue'
import { PhCaretUp, PhCaretDown, PhTrash, PhPencil } from 'phosphor-vue';
import BaseModal from '@/components/core/BaseModal.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import InputField from '@/components/core/forms/InputField.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import BaseFilter from '@/components/core/BaseFilter.vue';
import dayjs from 'dayjs';

export default {
  props: {
  },
  components: {
    PageHeaderTitleNavigation, OptionMenu,
    BaseButton, BaseContextMenu, ContextMenuItem, BaseModal, BaseFilter,
    InputField, InputToggleSwitch, InputTextArea, DatePickerField,
    DragnDropArea, DownloadLink, Table,
    PhTrash, PhPencil,
  },
  data() {
    return {
      currentNews: null,
      value: '',
      title: '',
      changedValue: '',
      hasChanged: false,
      files: [],
      acceptFileTypes: '*/*',
      selectedFiles: [],
      fileName: '',
      file: null,
      fileTitle: '',
      fileDescription: '',
      fileStand: dayjs(new Date()).format('DD.MM.YYYY'),
      fileSichtbar: 'Ja',
      fileNeu: true,
      isEdit: false,
      editRow: null,
      editName: '',
      configFilter: {
        checkDefaultSearchInput: false,
        cbDefSearchInput: null,
        hideFirstColumn: true,
        filterZurucksetzen: null,
      },
    };
  },
  mounted() {
    if (!this.infofilme.status) {
      this.search([])
    }
  },
  watch: {
    aktFilter() {
      console.log('aktFilter', this.aktFilter)
    }
  },
  computed: {
    ...mapGetters({
      infofilme: INFOFILME_TYPES.GETTERS.GET_INFOFILME,
      aktFilter: INFOFILME_TYPES.GETTERS.GET_FILTER,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
    }),
    headers() {
      const headers = {
        lockedLeft: [
          SlotColumn('BEZEICHNUNG', 'Bezeichnung', 200, 1).makeSortable(cell => cell),
        ],
        center: [
          TextColumn('NAME', 'Dateiname'),
          TextColumn('BEMERKUNG', 'Bemerkung'),
          DateColumn('DATE_STAND', 'Stand'),
        ],
        lockedRight: []
      }
      if (this.isIntern) {
        headers.lockedLeft.unshift(TextColumn('SICHTBAR_MAKLER', 'Sichtbar für Vermittlern').alignCenter());
        headers.center.push(
          TextColumn('UPLOAD_USER_ID', 'Hochgeladen von')
        )
         headers.center.push( ActionColumn('actions', '') );
      }
      return headers
    },
    disabledConfirm() {
      return !this.title || !this.value
    },
    rows() {
      const actions = [
          SimpleAction('EDIT', PhPencil, 'Bearbeiten'),
      ];
      return this.infofilme?.rows?.map(row => ({ ...row, id: row.ID, actions })) || [];
    },
    filterMetadata() {
    const menuItems = [
        {type: 'dateRange', key: 'zeitraum',    emptyDenied: true,  label: 'Stand-Zeitraum' },
        {type: 'text',      key: 'name',        emptyDenied: true,  label: 'Dateiname' },
        {type: 'text',      key: 'bezeichnung', emptyDenied: true,  label: 'Bezeichnung' },
      ]
      if (this.isIntern) {
        menuItems.push(
          {type: 'default', key: 'sichtbar', deniable: true,     label: 'Sichtbar für Vermittlern' },
        );
      }
      return [
        {
          type: 'group',
          key: 'allgemein',
          label: 'Allgemein',
          menuItems
        }
      ]
    },
  },
  methods: {
    search(filters) {
      let searchParams = {};
      (filters || []).filter(item => item.value ).forEach(item => {
        if (item.filterNot) {
          searchParams[item.key] = '0'
        } else if (item.value === true) {
          searchParams[item.key] = '1';
        } else if (item.key === 'zeitraum') {
          searchParams.von = '';
          searchParams.bis = '';
          item.value?.forEach(item => {
            if (item.key == 'min') {
              searchParams.von = item.value;
            } else if (item.key == 'max') {
              searchParams.bis = item.value;
            }
          })
        } else if (item.value) {
          searchParams[item.key] = item.value;
        }
      })
      this.$store.dispatch(INFOFILME_TYPES.ACTIONS.LOAD_INFOFILME, searchParams);
    },
    onUpdate(value) {
      this.value = value;
    },
    onConfirm() {
      this.$emit('confirm', this.changedValue);
    },
    onCancel() {
      this.$router.push({ path: `/intern/news-msc` })
    },
    addDocument(files) {
      this.onAddFiles(files);
      this.openAddDocument();
    },
    onAddFiles(files) {
      if (!Array.isArray(files)) {
        this.selectedFiles = Object.values(files);
      } else {
        this.selectedFiles = files;
      }
      this.fileName = this.selectedFiles.map(f => f.name).join(', ');
    },
    deleteFile(row) {
      const index = this.files.findIndex(a => a.id === row.id && a.copyFileId === row.copyFileId)
      if (index > -1) {
        this.files.splice(index, 1);
      }
    },
    editFile(row) {
      this.editRow = row;
      this.isEdit = true;
      this.fileNeu = false;
      this.editName = this.editRow.NAME;
      this.fileSichtbar = this.editRow.SICHTBAR_MAKLER;
      this.fileTitle = this.editRow.BEZEICHNUNG;
      this.fileDescription = this.editRow.BEMERKUNG;
      this.fileStand = dayjs(new Date(this.editRow.DATE_STAND)).format('DD.MM.YYYY');
      this.openAddDocument(true);
    },
    openAddDocument(keep = false) {
      if (!keep) {
        this.file = null;
        this.fileTitle = '';
        this.fileDescription = '';
        this.fileStand = dayjs(new Date()).format('DD.MM.YYYY');
      }
      this.$refs.addDocument.open();
    },
    closeAddDocumentModal() {
      this.$refs.addDocument.close();
    },
    onClose() {
      this.isEdit = false;
      this.fileNeu = true;
      this.editRow = null;
    },
    saveChanges() {
      let stand = this.fileStand;
      if (typeof stand != 'string' && stand.getTime) {
        stand = dayjs(stand).format('DD.MM.YYYY')
      }
      if (!this.fileNeu) {
        if (this.fileTitle != this.editRow.BEZEICHNUNG
          || this.fileDescription != this.editRow.BEMERKUNG
          || this.fileSichtbar != this.editRow.SICHTBAR_MAKLER
          || stand != dayjs(new Date(this.editRow.DATE_STAND)).format('DD.MM.YYYY')
        ) {
          this.$store.dispatch(INFOFILME_TYPES.ACTIONS.UPDATE_INFOFILME, {
            id: this.editRow.ID,
            fileId: this.editRow.FILE_ID,
            bezeichnung: this.fileTitle,
            bemerkung: this.fileDescription,
            sichtbar: this.fileSichtbar,
            stand
          })
        }
        return;
      }
      this.selectedFiles.map(file => {
        // upload file
        uploadFileChunked(file).then(tempFileId => {
          this.$store.dispatch(INFOFILME_TYPES.ACTIONS.UPDATE_INFOFILME, {
            tempFileId,
            id: this.editRow == null ? 0 : this.editRow.ID,
            fileId: this.editRow == null ? 0 : this.editRow.FILE_ID,
            bezeichnung: this.fileTitle,
            bemerkung: this.fileDescription,
            sichtbar: this.fileSichtbar,
            stand
          })
        })
      })
      this.closeAddDocumentModal();
    },
  },
}
</script>

<style lang="scss" scoped>
.upload-btn {
  position: relative;
}

.upload-btn input {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

</style>
