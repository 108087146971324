<template>
  <Table
    tableId="b0273fd2-0ae5-4b5f-9a66-f3c16b77150a"
    cardView
    :headers="headers"
    :rows="rows"
    hidePagination
  />
</template>

<script>
import BERICHTE_TYPES from '@/store/berichte/types';
import { TextColumn, DateColumn } from "@/components/table2/table_util.js";
import Table from "@/components/table2/Table.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Table,
  },
  computed: {
    ...mapGetters({
      altersvorsorge: BERICHTE_TYPES.GETTERS.ALTERSVORSORGE,
    }),
    headers() {
      return {
        center: [
          TextColumn("ubBStatus", "Bearbeitungsstatus"),
          DateColumn("ubBDatum", "Bearbeitungsdatum"),
          TextColumn("ubWeg", "Kapitalübertragungsweg"),
          DateColumn("ubArt", "Kapitalübertragungsart"),
          TextColumn("ubProzent", "Prozent"),
          DateColumn("ubDatum", "Datum der Kapitalübertragung"),
          DateColumn("ubGeldbewegung", "Datum der Bewegung"),
        ]
      };
    },
    rows() {
      return [
        {...(this.altersvorsorge?.zulagen || {})}
      ]
    },
  },
};
</script>