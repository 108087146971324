<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    data-name="Layer 1"
    width="784.39764"
    height="733.6066"
    viewBox="0 0 784.39764 733.6066"
  >
    <title>personal_finance</title>
    <path
      d="M832.54583,604.41452s97.0406,5.017,74.43777,35.56514-81.99491-17.91975-81.99491-17.91975Z"
      transform="translate(-207.80118 -83.1967)"
      fill="#ffb8b8"
    />
    <path
      d="M653.71677,313.44812l19.75208,4.938s11.52205,34.56615,19.75209,65.84028S717.91105,509.323,717.91105,509.323L845.5213,598.75563,830.30118,632.8033l-170-67L622.44263,417.14657Z"
      transform="translate(-207.80118 -83.1967)"
      fill="#575a89"
    />
    <polygon
      points="464.5 291.607 457.138 445.772 472.5 491.607 427.5 458.607 464.5 291.607"
      opacity="0.2"
    />
    <path
      d="M698.982,648.41057s21.3981,57.61025,24.69011,97.11442,6.584,70.77831,6.584,70.77831H629.84966l-19.75208-29.62813V816.3033H493.23107S460.31093,709.31284,473.479,696.14478s138.26461-69.1323,138.26461-69.1323Z"
      transform="translate(-207.80118 -83.1967)"
      fill="#2f2e41"
    />
    <rect x="101.5" y="731.6066" width="581" height="2" fill="#3f3d56" />
    <circle cx="365.26124" cy="105.15487" r="62.54827" fill="#ffb8b8" />
    <path
      d="M525.32821,214.68769l-4.938,72.42431,62.54827,83.94637L614.2126,292.05s-27.98213-18.10608-11.522-55.96424Z"
      transform="translate(-207.80118 -83.1967)"
      fill="#ffb8b8"
    />
    <path
      d="M708.03116,662.4c0,14.82-41.15,37.86005-41.15,37.86005v8.23l-16.46,8.23-36.21-72.42-9.87,79s-72.43,11.53-95.47,0c-23.05-11.52-34.57-6.58-67.49-18.1s13.17-166.25,13.17-166.25l-32.92-238.67,71.92-35.96,3.79-1.9,9.89-1.82,15.16-2.8,50.67,34.25,4.08,21.4,2.51,13.17,13.16-14.82,9.94-32.69,70.72,39.28-19.75,158.01S708.03116,647.59,708.03116,662.4Z"
      transform="translate(-207.80118 -83.1967)"
      fill="#575a89"
    />
    <path
      d="M466.072,713.42785s75.71633,60.90227,39.50417,72.42432-55.96424-62.54827-55.96424-62.54827Z"
      transform="translate(-207.80118 -83.1967)"
      fill="#ffb8b8"
    />
    <path
      d="M438.08983,303.57207l-16.46007-3.292s-24.69011,13.16806-26.33612,39.50417-23.0441,210.68892-23.0441,210.68892l69.1323,185.9988,55.96425-21.39809L443.02785,540.5971,484.178,389.16445Z"
      transform="translate(-207.80118 -83.1967)"
      fill="#575a89"
    />
    <circle cx="372.5" cy="264.6066" r="6" fill="#2f2e41" />
    <circle cx="372.5" cy="303.6066" r="6" fill="#2f2e41" />
    <circle cx="406.5" cy="560.6066" r="6" fill="#2f2e41" />
    <path
      d="M520.2536,124.27371l-11.56241-4.62895S532.86707,93.02848,566.50336,95.343L557.043,84.92787s23.1248-9.25775,44.14733,15.044c11.05115,12.77485,23.83743,27.7911,31.80833,44.70667h12.38253l-5.168,11.37937,18.088,11.37937-18.5656-2.044a63.589,63.589,0,0,1,.50381,18.8748,24.42141,24.42141,0,0,1-8.94287,15.976h0S616.957,170.56276,616.957,165.93382v11.57231s-11.5624-10.415-11.5624-17.35836l-6.3067,8.10061H557.043l6.3067-10.415L539.17377,161.305l9.46041-12.72959-37.431,52.56492S489.771,141.63206,520.2536,124.27371Z"
      transform="translate(-207.80118 -83.1967)"
      fill="#2f2e41"
    />
    <circle cx="684.39764" cy="383.54509" r="100" fill="#3f3d56" />
    <circle cx="684.39764" cy="383.54509" r="86" opacity="0.1" />
    <path
      d="M888.07791,517.48969V504.97263c-6.32734-.12344-12.87306-2.355-16.69109-5.45315l2.618-8.30316a26.68127,26.68127,0,0,0,15.70942,5.3285c7.74578,0,12.98225-5.081,12.98225-12.14493,0-6.81644-4.2548-11.02974-12.32763-14.74807-11.12757-4.957-18.00034-10.6582-18.00034-21.43985,0-10.28667,6.43653-18.09426,16.47324-19.95312V415.74179h6.76357v12.02149a26.54268,26.54268,0,0,1,14.18232,4.33734l-2.7277,8.17972a24.10675,24.10675,0,0,0-13.74554-4.2139c-8.39987,0-11.56381,5.70063-11.56381,10.6582,0,6.4443,4.03641,9.66645,13.52769,14.12845,11.23623,5.205,16.90947,11.64935,16.90947,22.67908,0,9.7905-6.00029,18.962-17.237,21.06772v12.8898Z"
      transform="translate(-207.80118 -83.1967)"
      :fill="color"
    />
    <polygon
      points="250.005 504.87 290 629.803 258 499.803 250.005 504.87"
      opacity="0.2"
    />
    <path
      d="M253.89444,344H228.50772a4.178,4.178,0,0,1-4.17334-4.17334V303.3335a4.178,4.178,0,0,1,4.17334-4.17334h25.38672a4.178,4.178,0,0,1,4.17334,4.17334v36.49316A4.178,4.178,0,0,1,253.89444,344Zm-25.38672-42.83984a2.176,2.176,0,0,0-2.17334,2.17334v36.49316A2.176,2.176,0,0,0,228.50772,342h25.38672a2.176,2.176,0,0,0,2.17334-2.17334V303.3335a2.176,2.176,0,0,0-2.17334-2.17334Z"
      transform="translate(-207.80118 -83.1967)"
      fill="#3f3d56"
    />
    <path
      d="M300.26407,344H277.33829a5.41016,5.41016,0,0,1-5.40381-5.40381V264.897a5.41016,5.41016,0,0,1,5.40381-5.40381h22.92578a5.41016,5.41016,0,0,1,5.40381,5.40381v73.69922A5.41016,5.41016,0,0,1,300.26407,344Zm-22.92578-82.50684a3.40756,3.40756,0,0,0-3.40381,3.40381v73.69922A3.40756,3.40756,0,0,0,277.33829,342h22.92578a3.40756,3.40756,0,0,0,3.40381-3.40381V264.897a3.40756,3.40756,0,0,0-3.40381-3.40381Z"
      transform="translate(-207.80118 -83.1967)"
      fill="#3f3d56"
    />
    <path
      d="M346.97892,344H325.82364a6.29622,6.29622,0,0,1-6.28906-6.28906V229.28906A6.29622,6.29622,0,0,1,325.82364,223h21.15528a6.29622,6.29622,0,0,1,6.28906,6.28906V337.71094A6.29622,6.29622,0,0,1,346.97892,344ZM325.82364,225a4.29393,4.29393,0,0,0-4.28906,4.28906V337.71094A4.29393,4.29393,0,0,0,325.82364,342h21.15528a4.29393,4.29393,0,0,0,4.28906-4.28906V229.28906A4.29393,4.29393,0,0,0,346.97892,225Z"
      transform="translate(-207.80118 -83.1967)"
      fill="#3f3d56"
    />
    <rect x="23.88" y="222.9133" width="19.04" height="30.94" :fill="color" />
    <rect x="71.48" y="183.6433" width="19.04" height="70.21" :fill="color" />
    <rect
      x="119.08"
      y="147.14997"
      width="19.04"
      height="106.70333"
      :fill="color"
    />
    <path
      d="M930.80118,229h-133a.99974.99974,0,0,1-1-1V93a1,1,0,0,1,2,0V227h132a1,1,0,0,1,0,2Z"
      transform="translate(-207.80118 -83.1967)"
      fill="#3f3d56"
    />
    <path
      d="M366.80118,358h-158a.99974.99974,0,0,1-1-1V200a1,1,0,0,1,2,0V356h157a1,1,0,0,1,0,2Z"
      transform="translate(-207.80118 -83.1967)"
      fill="#3f3d56"
    />
    <path
      d="M843.69962,216.46729H818.3129a4.17807,4.17807,0,0,1-4.17383-4.17334V175.80078a4.17806,4.17806,0,0,1,4.17383-4.17334h25.38672a4.17786,4.17786,0,0,1,4.17285,4.17334V212.294A4.17786,4.17786,0,0,1,843.69962,216.46729ZM818.3129,173.62744a2.17641,2.17641,0,0,0-2.17383,2.17334V212.294a2.17641,2.17641,0,0,0,2.17383,2.17334h25.38672a2.17557,2.17557,0,0,0,2.17285-2.17334V175.80078a2.17557,2.17557,0,0,0-2.17285-2.17334Z"
      transform="translate(-207.80118 -83.1967)"
      fill="#3f3d56"
    />
    <path
      d="M895.68888,216.46729H872.32267a5.18969,5.18969,0,0,1-5.1836-5.18409V145.18359A5.18927,5.18927,0,0,1,872.32267,140h23.36621a5.18927,5.18927,0,0,1,5.18359,5.18359V211.2832A5.18969,5.18969,0,0,1,895.68888,216.46729ZM872.32267,142a3.187,3.187,0,0,0-3.1836,3.18359V211.2832a3.18709,3.18709,0,0,0,3.1836,3.18409h23.36621a3.18708,3.18708,0,0,0,3.18359-3.18409V145.18359A3.187,3.187,0,0,0,895.68888,142Z"
      transform="translate(-207.80118 -83.1967)"
      fill="#3f3d56"
    />
    <rect
      x="613.22803"
      y="95.727"
      width="19.04"
      height="30.94"
      :fill="color"
    />
    <rect
      x="666.68472"
      y="65.10507"
      width="19.04"
      height="59.8637"
      :fill="color"
    />
    <circle cx="623" cy="68.8033" r="9" :fill="color" />
    <circle cx="676" cy="34.8033" r="9" :fill="color" />
  </svg>
</template>

<script>
import undraw from "@/components/icons/undraw/undraw-mixin.js";

export default {
  mixins: [undraw],
};
</script>