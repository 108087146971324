var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.depotTitle,
          subtitle: "Kennzahlen",
          actions: _vm.headerActions
        }
      }),
      _c("HinweiseUndFehler", { attrs: { hints: _vm.hints } }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("div", { staticClass: "box__title" }, [_vm._v("Kennzahlen")]),
          _vm.loading
            ? _c(
                "div",
                { staticClass: "text-centered" },
                [
                  _c("GhostLoading", {
                    attrs: { type: "table", config: { table: { rows: 9 } } }
                  }),
                  _c("GhostLoading", {
                    attrs: { type: "table", config: { table: { rows: 7 } } }
                  })
                ],
                1
              )
            : _vm.kennzahlenData.timeoutMessage
            ? _c("div", { staticClass: "text-centered" }, [
                _c("div", [_vm._v(_vm._s(_vm.kennzahlenData.timeoutMessage))]),
                _c(
                  "div",
                  { staticClass: "mt-3" },
                  [
                    _c(
                      "BaseButton",
                      {
                        staticClass: "m-0",
                        on: {
                          click: function($event) {
                            return _vm.refresh()
                          }
                        }
                      },
                      [_vm._v("Aktualisieren")]
                    )
                  ],
                  1
                )
              ])
            : [_c("KennzahlenTableFrst"), _c("KennzahlenTableScnd")]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }