import axios from 'axios';
import BROKER_STATISTICS_TYPES from './types';
import CORE_TYPES from '../core/types';
// import * as utils from '../../helpers/commonfunctions';

const config = {
    defaultSpinner: true
  };

export default{
    [BROKER_STATISTICS_TYPES.ACTIONS.RETRIEVE_TITLES_LIST]({ getters, commit }){
        let url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/brokerStatistics/list`;

        return axios.get(url, config).then(response => {
            commit(BROKER_STATISTICS_TYPES.MUTATIONS.RETRIEVE_TITLES_LIST_SUCCESS,  {data: response.data});
          });
    },

    async [BROKER_STATISTICS_TYPES.ACTIONS.RETRIEVE_TABLE_VIEWER]({commit, rootState }, payload) {
      if ( payload?.id == null ) {
        return;
      }
      const url = rootState.core.apiAddress +'/brokerStatistics/getTableViewer?id='+payload.id
        +(payload?.typ == null ? '' : '&typ='+encodeURIComponent(payload.typ))
        +(payload?.eigenbestand == null ? '' : '&eigenbestand='+payload.eigenbestand)
        +(payload?.fremdbestand == null ? '' : '&fremdbestand='+payload.fremdbestand)
        +(payload?.aktiv == null ? '' : '&aktiv='+payload.aktiv)
        +(payload?.sparte == null ? '' : '&sparte='+payload.sparte)
        +(payload?.struktur == null ? '' : '&struktur='+payload.struktur)
        +(payload?.subStatistikKey == null ? '' : '&subStatistikKey='+encodeURIComponent(payload.subStatistikKey))
        +(payload?.parentStatistikId == null ? '' : '&parentStatistikId='+encodeURIComponent(payload.parentStatistikId))
        +(payload?.title == null ? '' : '&title='+encodeURIComponent(payload.title))
        +(payload?.maklerNummer == null ? '' : '&maklerNummer='+payload.maklerNummer)
        +(payload?.gesellschaftCombo == null ? '' : '&gesellschaftCombo='+encodeURIComponent(payload.gesellschaftCombo))
      ;
      commit(BROKER_STATISTICS_TYPES.MUTATIONS.SET_TABLE_VIEWER, 
        {action: 'increment', loading: true, params: payload} );
      const response = await axios.get(url, { defaultSpinner: true } )
      if (response.status === 200 && response?.data) {
        const data = {...response.data, params: payload } ;
        commit(BROKER_STATISTICS_TYPES.MUTATIONS.SET_TABLE_VIEWER, data ); 
      }
    },
  
    [BROKER_STATISTICS_TYPES.ACTIONS.RETRIEVE_PAGE_VIEWER]({rootState }, payload) {
      if ( payload?.id == null || payload?.page == null ) {
        return;
      }
      const url = rootState.core.apiAddress +'/brokerStatistics/getPage?id='+payload.id + '&page='+payload.page;
      return axios.get(url, { defaultSpinner: true } )
    },
  
    [BROKER_STATISTICS_TYPES.ACTIONS.RETRIEVE_EXPORT_DATA]({rootState }, payload) {
      if ( payload?.file_id == null ) {
        return;
      }
      const url = rootState.core.apiAddress +'/brokerStatistics/create_xls_pdf?id='+payload.file_id;
      return axios.post(url, payload, { defaultSpinner: true } )
    },
  
}