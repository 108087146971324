import INTERNESTATISTIKEN_TYPES from './types';
import CORE_TYPES from '../core/types';
import axios from 'axios';
import { buildMessage, } from '../../helpers/log-message-helper';
import * as utils from '../../helpers/commonfunctions';

export default {
  
  async [INTERNESTATISTIKEN_TYPES.ACTIONS.RETRIEVE_STATISTICGROUPS_LIST]({commit, rootState }) {
    const url = rootState.core.apiAddress +'/interneStatistikenService/getInterneStatistiken';
    const response = await axios.get(url, { defaultSpinner: true } )
    if (response.status === 200 && response?.data) {
      const data = {...response.data } ;
      //console.log(data);
      commit(INTERNESTATISTIKEN_TYPES.MUTATIONS.SET_STATISTICGROUPS_LIST, data.statisticGroups ); 
      commit(INTERNESTATISTIKEN_TYPES.MUTATIONS.SET_GESELLSCHAFTENCOMBO, data.gesellschaftenCombo ); 
    }
  },

}