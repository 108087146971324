var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isByPass
    ? _c("div", { staticClass: "box__container" }, [
        _c("div", { staticClass: "box__title" }, [_vm._v("Versandadresse")]),
        _vm.isEditable
          ? _c(
              "div",
              { staticClass: "mb-3" },
              [
                _c(
                  "BaseButton",
                  {
                    attrs: { isSecondary: "" },
                    on: {
                      click: function($event) {
                        return _vm.copy()
                      }
                    }
                  },
                  [_vm._v("Kundendaten als Versandadresse übernehmen")]
                )
              ],
              1
            )
          : _vm._e(),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-12" },
            [
              _c("InputField", {
                attrs: {
                  label: "c/o",
                  isComponentHalfSize: true,
                  disabled: !_vm.isEditable
                },
                on: {
                  change: function($event) {
                    return _vm.addCustomerDataEdited("shippingAddress")
                  }
                },
                model: {
                  value: _vm.shippingAddress.co,
                  callback: function($$v) {
                    _vm.$set(_vm.shippingAddress, "co", $$v)
                  },
                  expression: "shippingAddress.co"
                }
              }),
              _c("InputField", {
                attrs: {
                  label: "Straße, HNr",
                  isComponentHalfSize: true,
                  disabled: !_vm.isEditable
                },
                on: {
                  change: function($event) {
                    return _vm.addCustomerDataEdited("shippingAddress")
                  }
                },
                model: {
                  value: _vm.shippingAddress.street,
                  callback: function($$v) {
                    _vm.$set(_vm.shippingAddress, "street", $$v)
                  },
                  expression: "shippingAddress.street"
                }
              }),
              _c("InputField", {
                attrs: {
                  label: "Postleitzahl",
                  isComponentHalfSize: true,
                  disabled: !_vm.isEditable
                },
                on: {
                  change: function($event) {
                    return _vm.addCustomerDataEdited("shippingAddress")
                  }
                },
                model: {
                  value: _vm.shippingAddress.zip,
                  callback: function($$v) {
                    _vm.$set(_vm.shippingAddress, "zip", $$v)
                  },
                  expression: "shippingAddress.zip"
                }
              }),
              _c("InputField", {
                attrs: {
                  label: "Ort",
                  isComponentHalfSize: true,
                  disabled: !_vm.isEditable
                },
                on: {
                  change: function($event) {
                    return _vm.addCustomerDataEdited("shippingAddress")
                  }
                },
                model: {
                  value: _vm.shippingAddress.city,
                  callback: function($$v) {
                    _vm.$set(_vm.shippingAddress, "city", $$v)
                  },
                  expression: "shippingAddress.city"
                }
              }),
              _c("ComboBox", {
                attrs: {
                  label: "Land",
                  isComponentHalfSize: true,
                  disabled: !_vm.isEditable,
                  values: _vm.customerDataConfig.countries,
                  firstEmpty: !_vm.shippingAddress.country
                },
                on: {
                  change: function($event) {
                    return _vm.addCustomerDataEditedCombo(
                      "shippingAddress",
                      "country",
                      $event
                    )
                  }
                },
                model: {
                  value: _vm.shippingAddress.country,
                  callback: function($$v) {
                    _vm.$set(_vm.shippingAddress, "country", $$v)
                  },
                  expression: "shippingAddress.country"
                }
              })
            ],
            1
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }