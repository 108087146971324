import CORE_TYPES from '../../store/core/types';
import { mapGetters } from "vuex";
import BaseButton from "@/components/core/BaseButton.vue";
import Vue from 'vue'
import BaseModal from "@/components/core/BaseModal.vue";
import VueRouter from 'vue-router';
import {sanitize} from '@/helpers/string-helper.js';
import store from '@/store'

const { isNavigationFailure } = VueRouter;

var InteractiveHelpMixin = {
    data: function () {
        return {
            elementsIdArray: {
                items: []
            },
            elementsArray: [],
            options: {
                nextLabel: 'Weiter',
                prevLabel: 'Zurück',
                skipLabel: 'Abbrechen',
                doneLabel: 'Fertig',
                noClickLabel: 'nur Erklärung, keine Aktion ausführen',
            },
            slideItems: [],
            _direction: 'forward',
            _currentStep: null,
            _targetElement: null,
            _clickFunction: null,

        }
    },
    computed: {
        ...mapGetters({
            recordingTutorialRoute: CORE_TYPES.GETTERS.RECORDING_TUTORIAL_ROUTE,
            isLoading: CORE_TYPES.GETTERS.GLOBAL_LOADING_STATE_STATUS,
            recordingTutorialTitle: CORE_TYPES.GETTERS.RECORDING_TUTORIAL_TITLE,
        })
    },
    methods: {
        startInteractiveHelp(context) {
            this.$store.commit(CORE_TYPES.MUTATIONS.PLAYING_TUTORIAL_START);


            context = context || this.$root.$el;
            this._targetElement = context;
            const self = this;

            let clickFunction = function (event) {

                // ensures that no elements, but the tutorial elements are clickable
                if (!event.tutorialClickEvent 
                    && (!event.target.parentNode 
                        || (event.target.parentNode.className && typeof event.target.parentNode.className !== 'string')
                        || event.target.parentNode.className.indexOf('interactive-help-tooltipbuttons') == -1)
                        ) {

                            if ((event.target && (typeof event.target.className !== 'string') || (event.target.className.indexOf('interactive-help-overlay')  == -1) )) {
                                event.stopImmediatePropagation();
                                event.preventDefault();

                                // calls the "next" button action
                                self._bbNextTooltipFunction(self);

                            }

                }

            }

            this._clickFunction = clickFunction;

            if (this._addEventListenerTimer) {
                window.clearTimeout(this._addEventListenerTimer);
            }

            this._addEventListenerTimer = window.setTimeout(function () {

                // self._targetElement.addEventListener("mousedown", mousedownFunction, true);
                document.addEventListener("click", clickFunction, true);

            }, 950);            


            this._populateElementsArray();
            if (this._dimWindow(context, 'interactive-help', this._exitInteractiveHelp)) {
                this._addTutorialTitle();
                this._startPresentation(context);
            }
        },
        /**
         * Reads every JSON id to create the elements array
         */
        _populateElementsArray() {

            this.elementsArray = [];

            this.elementsIdArray.items.forEach(item => {
                this.elementsArray.push(item.element);
            });

            if (this.elementsArray.length == 0) {
                return false;
            }

            let nextStep = 0;

            this._iterate(this.elementsArray, function () {


                const tidArray = this.elementsIdArray.items[nextStep].stepKey.split('|');
                const updatedElement = this._targetElement.querySelector(`[tid="${tidArray[0]}"]`);

                this.slideItems[nextStep] = {
                    element: updatedElement,
                    helpMessage: this.elementsIdArray.items[nextStep].message,
                    helpPath: this.elementsIdArray.items[nextStep].id,
                    step: nextStep + 1,
                    stepKey: this.elementsIdArray.items[nextStep].stepKey,
                    tooltipClass: updatedElement ? updatedElement.getAttribute('help-tooltipclass') : null,
                    highlightClass: updatedElement ? updatedElement.getAttribute('help-highlightclass') : null,
                    position: updatedElement ? updatedElement.getAttribute('help-position') || 'bottom' : null,
                    routerPath: this.elementsIdArray.items[nextStep].routerPath,
                    previousRouterPath: null,
                    autoClick: tidArray.length > 1 ? true : false,
                    noClick: this.elementsIdArray.items[nextStep].noClick,
                };

                nextStep++;
            }.bind(this));


        },
		_clickElement(nextStep) {
            if (nextStep && nextStep.element) {
                // For action buttons, like the buttons in the Carousel component
                var clickEvent = new MouseEvent("click", {
                    "view": window,
                    "bubbles": true,
                    "cancelable": false
                });
                
                clickEvent.tutorialClickEvent = true;
                
                nextStep.element.dispatchEvent(clickEvent); 
            }
        },        
        _lightWindow() {

            let overlayLayers = this._targetElement.querySelectorAll('.interactive-help-overlay');
            if (overlayLayers && overlayLayers.length) {
                this._iterate(overlayLayers, function (overlayLayer) {
                    overlayLayer.style.opacity = 0;
                    window.setTimeout(function () {
                        if (this.parentNode) {
                            this.parentNode.removeChild(this);
                        }
                    }.bind(overlayLayer), 500);
                }.bind(this));

                this._removeTutorialTitle();
            }

        },
        _exitInteractiveHelp() {
            let overlayLayers = this._targetElement.querySelectorAll('.interactive-help-overlay');

            if (overlayLayers && overlayLayers.length) {
                this._iterate(overlayLayers, function (overlayLayer) {
                    overlayLayer.style.opacity = 0;
                    window.setTimeout(function () {
                        if (this.parentNode) {
                            this.parentNode.removeChild(this);
                        }
                    }.bind(overlayLayer), 500);
                }.bind(this));
            }

            let helperLayer = this._targetElement.querySelector('.interactive-help-helperLayer');
            if (helperLayer) {
                helperLayer.parentNode.removeChild(helperLayer);
            }

            let helperLayerVector = this._targetElement.querySelector('.interactive-help-helperLayer-vector');
            if (helperLayerVector) {
                helperLayerVector.parentNode.removeChild(helperLayerVector);
            }            

            let referenceLayer = this._targetElement.querySelector('.interactive-help-tooltipReferenceLayer');
            if (referenceLayer) {
                referenceLayer.parentNode.removeChild(referenceLayer);
            }

            this._removeHighlight('interactive-help', /interactive-help-[a-zA-Z]+/g);

            document.removeEventListener("click", this._clickFunction, true);

            this.$store.commit(CORE_TYPES.MUTATIONS.PLAYING_TUTORIAL_STOP);

        },
        _startPresentation() {
            this._direction = 'forward';

            this._currentStep = 0;

            let nextStep = this.slideItems[this._currentStep];

            // nextStep.element = this._targetElement.querySelector(nextStep.helpPath);
            nextStep.element = this._targetElement.querySelector(`[tid="${nextStep.stepKey}"]`);

            let self = this;

            if (this.recordingTutorialRoute && this.recordingTutorialRoute !== this.$route.path) {
                this.$router.push({ path: this.recordingTutorialRoute });
            }

            if (this._showStepTimer) {
                window.clearTimeout(this._showStepTimer);
            }

            this._showStepTimer = window.setTimeout(function () {

                // By changing the route, the original component is somehow changed. This line ensures that the component will always be updated
                // nextStep.element = self._targetElement.querySelector(nextStep.helpPath);
                nextStep.element = self._targetElement.querySelector(`[tid="${nextStep.stepKey}"]`);
                self._showStep(nextStep);
            }, 150);

            if ((this.slideItems.length) <= this._currentStep) {
                this._exitInteractiveHelp();
                return;
            }

        },
        async _bbPreviousTooltipFunction(self) {
            if (self._currentStep !== 0) {

                let routerPath = null
                if (typeof (self._currentStep) !== 'undefined') {
                    routerPath = self.slideItems[self._currentStep - 1].previousRouterPath;
                }

                let redirectResult = null
                if (routerPath && self.$route.path !== routerPath) {
                    redirectResult = await self.$router.push({ path: routerPath });
                }  else {
                    if (!self.slideItems[self._currentStep -1].noClick) {
                        self._clickElement(self.slideItems[self._currentStep -1]);
                    }
                }

                self.$nextTick(() => {
                    const previousStep = this.slideItems[this._currentStep-1];
                    const testPreviousStepIsPresent = this._targetElement.querySelector(`[tid="${previousStep.stepKey}"]`);
                    
                    if (testPreviousStepIsPresent) {
                        self._addTutorialSpinner(self._targetElement);
                        window.setTimeout(function () {
                            self._removeTutorialSpinner(self._targetElement);
                            self._previousStep(); 
                        }, 970)   
                         

                    } else {

                        self._hideCurrentStep();

                        self._addTutorialSpinner(self._targetElement);
                        
                        var timesRun = 0;
                        var interval = setInterval(function(){
                            timesRun ++;
                            if(isNavigationFailure(redirectResult) || timesRun == 7  ){
                                clearInterval(interval);

                                self._removeTutorialSpinner(self._targetElement);
                                self._exitInteractiveHelp();
                                self._showElementNotFoundNotification();
                            }
                            
                            let testPreviousStepIsPresent = self._targetElement.querySelector(`[tid="${previousStep.stepKey}"]`);

                            if (!testPreviousStepIsPresent) {
                                testPreviousStepIsPresent = document.querySelector(`[tid="${previousStep.stepKey}"]`);
                            }

                            if (testPreviousStepIsPresent && !this.isLoading) {
                                // found element after interval

                                self._removeTutorialSpinner(self._targetElement);
                                clearInterval(interval);
                                self._previousStep();    

                            }                                  

                        }, 2000);         
                        
                    }
                });                        

            }
        },
        async _bbNextTooltipFunction(self) {
            if (self.slideItems.length - 1 !== self._currentStep) {

                let routerPath = null
                if (typeof (self._currentStep) !== 'undefined') {
                    routerPath = self.slideItems[self._currentStep].routerPath;
                }

                self.slideItems[self._currentStep].previousRouterPath = self.$route.path;

                let redirectResult = null;
                if (routerPath && self.$route.path !== routerPath) {
                    redirectResult = await self.$router.push({ path: routerPath });
                } else {
                    if (!self.slideItems[self._currentStep].noClick) {
                        self._clickElement(self.slideItems[self._currentStep]);
                    }
                } 

                self.$nextTick(() => {
                    const nextStep = this.slideItems[this._currentStep+1];
                    const testNextStepIsPresent = this._targetElement.querySelector(`[tid="${nextStep.stepKey}"]`);

                    if (testNextStepIsPresent) {

                        // if the current element is not supposed to be clicked, then no need to wait
                        if (nextStep.noClick) {
                            self._nextStep();
                        } else {
                            self._addTutorialSpinner(self._targetElement);
                            window.setTimeout(function () {
                                self._removeTutorialSpinner(self._targetElement);
                                self._nextStep();  
                            }, 970); 

                        }

    
                    } else {
    
                        self._hideCurrentStep();

                        self._addTutorialSpinner(self._targetElement);
    
                        var timesRun = 0;
                        var interval = setInterval(function(){
                            timesRun ++;
                            if(isNavigationFailure(redirectResult) || timesRun == 7  ){
                                clearInterval(interval);
    
                                self._removeTutorialSpinner(self._targetElement);
                                self._exitInteractiveHelp();
                                self._showElementNotFoundNotification();
                            }
                            
                            let testNextStepIsPresent = self._targetElement.querySelector(`[tid="${nextStep.stepKey}"]`);
    
                            if (!testNextStepIsPresent) {
                                testNextStepIsPresent = document.querySelector(`[tid="${nextStep.stepKey}"]`);
                            }
    
                            if (testNextStepIsPresent && !this.isLoading) {
                                // found element after interval
                                self._removeTutorialSpinner(self._targetElement);
                                clearInterval(interval);
                                self._nextStep();    
    
                            }    

    
                        }, 2000);         
                        
                    }
                });



            }
        },
        _showStep(step) {

            const baseButtonConstructor = Vue.extend(BaseButton);
            const bbSkipTooltip = new baseButtonConstructor({ class: 'btn btn-primary' });
            const bbPreviousTooltip = new baseButtonConstructor({ class: 'btn btn-primary' });
            let bbNextTooltip = new baseButtonConstructor({ class: 'btn btn-primary' });

            const vector_elements = [
                'carousel-item__container clickable', 
                'workspaces-menu__item router-link-active',
                'dropdown-menu__hook',
                'dropdown-menu__hook active',
                'btn-clean clickable',
                'workspaces-menu__item',
                'tree__item--content clickable',
                'tree__item--collapse-toggle clickable opened',
            ];

            let highlightClass = vector_elements.includes(step?.element?.className) ? 'interactive-help-helperLayer-vector' : 'interactive-help-helperLayer';

            // if (!step.element.className || step.element.className.length==0) {
            //     highlightClass =  'interactive-help-helperLayer-vector';
            // }

            let self = this,
                existingHelperLayer = document.querySelector('.interactive-help-helperLayer-vector') || document.querySelector('.interactive-help-helperLayer'),
                existingReferenceLayer = document.querySelector('.interactive-help-tooltipReferenceLayer');

            const isDoneLabel = self.slideItems.length - 1 === self._currentStep || self.slideItems.length === 1;


            // If there is any previous tooltip    
            if (existingHelperLayer !== null) {
                let
                    existingTooltipLayer = existingReferenceLayer.querySelector('.interactive-help-tooltiptext'),
                    existingArrowLayer = existingReferenceLayer.querySelector('.interactive-help-arrow'),
                    existingtooltipContainer = existingReferenceLayer.querySelector('.interactive-help-tooltip'),
                    existingButtonLayer = existingReferenceLayer.querySelector('.interactive-help-tooltipbuttons');

                existingHelperLayer.className = highlightClass;

                // Hides tooltip while moving
                existingtooltipContainer.style.opacity = 0;
                existingtooltipContainer.style.display = "none";

                this._setHelperLayerPosition(existingHelperLayer, this.slideItems);
                this._setHelperLayerPosition(existingReferenceLayer, this.slideItems);

                // Hides previous highlighted element
                this._removeHighlight('interactive-help', /interactive-help-[a-zA-Z]+/g);

                if (self._showStepTimer) {
                    window.clearTimeout(self._showStepTimer);
                }
                
                existingButtonLayer.removeChild(existingButtonLayer.children[2]); 
                bbNextTooltip.$destroy();

                let buttonCaption = isDoneLabel ? self.options.doneLabel : self.options.nextLabel; 
                bbNextTooltip = new baseButtonConstructor({ class: 'btn btn-primary' });
                bbNextTooltip.$slots.default = [buttonCaption];
                
                bbNextTooltip.$store = store
                bbNextTooltip.$mount();
                existingButtonLayer.appendChild(bbNextTooltip.$el);      

                bbNextTooltip.$on('click', e => { 
                    if (isDoneLabel) {
                        self._exitInteractiveHelp();
                        self._showEndOfPresentationNotification();
                    } else {
                        // the property "ignoreClosePopup" was manually added to the event
                        // and is present only when displaying the interactive help
                        e.ignoreClosePopup = true;
                        self._bbNextTooltipFunction(self);
                    }
                }); 

                self._showStepTimer = window.setTimeout(function () {
                    existingTooltipLayer.innerHTML = sanitize('<strong>Info:</strong> ' + step.helpMessage);
                    existingtooltipContainer.style.display = "block";

                    self._positionTooltip(document.querySelector(`[tid="${step.stepKey}"]`), existingtooltipContainer, existingArrowLayer, 'interactive-help');

                    existingReferenceLayer.querySelector('.interactive-help-bullets li > span.active').className = '';
                    existingReferenceLayer.querySelector('.interactive-help-bullets li > span[data-stepnumber="' + step.step + '"]').className = 'active';

                    existingtooltipContainer.style.opacity = 1;

                }, 350);

            } else {
                let helperLayer = document.createElement('div'),
                    referenceLayer = document.createElement('div'),
                    arrowLayer = document.createElement('div'),
                    tooltipLayer = document.createElement('div'),
                    tooltipTextLayer = document.createElement('div'),
                    bulletsLayer = document.createElement('div'),
                    buttonsLayer = document.createElement('div');

                helperLayer.className = highlightClass;
                referenceLayer.className = 'interactive-help-tooltipReferenceLayer';

                this._setHelperLayerPosition(helperLayer, this.slideItems);
                this._setHelperLayerPosition(referenceLayer, this.slideItems);

                this._targetElement.appendChild(helperLayer);
                this._targetElement.appendChild(referenceLayer);

                arrowLayer.className = 'interactive-help-arrow';

                tooltipTextLayer.className = 'interactive-help-tooltiptext';
                tooltipTextLayer.innerHTML = sanitize('<strong>Info:</strong> ' + step.helpMessage);

                const self = this;

                bulletsLayer.className = 'interactive-help-bullets';

                let ulContainer = document.createElement('ul');
                ulContainer.setAttribute('role', 'tablist');

                this._iterate(this.slideItems, function (item, i) {
                    let innerLi = document.createElement('li');
                    let bullet = document.createElement('span');

                    innerLi.setAttribute('role', 'presentation');
                    bullet.setAttribute('role', 'tab');

                    if (i === (step.step - 1)) {
                        bullet.className = 'active';
                    }

                    bullet.innerHTML = "&nbsp;";
                    bullet.setAttribute('data-stepnumber', item.step);

                    innerLi.appendChild(bullet);
                    ulContainer.appendChild(innerLi);
                });

                bulletsLayer.appendChild(ulContainer);

                buttonsLayer.className = 'interactive-help-tooltipbuttons';

                tooltipLayer.className = 'interactive-help-tooltip';
                tooltipLayer.appendChild(tooltipTextLayer);
                tooltipLayer.appendChild(bulletsLayer);

                tooltipLayer.appendChild(arrowLayer);
                referenceLayer.appendChild(tooltipLayer);

                //next button
                bbNextTooltip.$slots.default = [ self.slideItems.length - 1 === self._currentStep || self.slideItems.length === 1 ? self.options.doneLabel : self.options.nextLabel];

                bbNextTooltip.$on('click', () => { 
                    if (isDoneLabel) {
                        self._exitInteractiveHelp();
                    } else {
                        self._bbNextTooltipFunction(self);
                    }

                }); 

                //previous button

                bbPreviousTooltip.$slots.default = [ this.options.prevLabel ];

                bbPreviousTooltip.$on('click', () => { 
                    self._bbPreviousTooltipFunction(self);

                }); 

                //skip button
                bbSkipTooltip.$slots.default = [ this.options.skipLabel ];

                bbSkipTooltip.$on('click', () => { 
                    self._exitInteractiveHelp();
                });

                bbSkipTooltip.$store = store
                bbSkipTooltip.$mount();
                buttonsLayer.appendChild(bbSkipTooltip.$el);

                if (this.slideItems.length > 1) {
                    bbPreviousTooltip.$store = store
                    bbPreviousTooltip.$mount();  
                    buttonsLayer.appendChild(bbPreviousTooltip.$el);
                    
                    bbNextTooltip.$store = store
                    bbNextTooltip.$mount();
                    buttonsLayer.appendChild(bbNextTooltip.$el);

                }

                tooltipLayer.appendChild(buttonsLayer);

                this._positionTooltip(document.querySelector(`[tid="${step.stepKey}"]`), tooltipLayer, arrowLayer, 'interactive-help');
            }

            this._addClass(document.querySelector(`[tid="${step.stepKey}"]`), 'interactive-help-showElement');
            this._addClass(document.querySelector(`[tid="${step.stepKey}"]`), 'interactive-help-relativePosition');

            this.$nextTick(() => {
                document.querySelector(`[tid="${step.stepKey}"]`).focus();
                document.querySelector(`[tid="${step.stepKey}"]`).scrollIntoView({behavior: "smooth", block: "end"});                
              });             

           

        },
        _showEndOfPresentationNotification() {
            const message = 'Vielen Dank dass sie unsere interaktive Hilfe verwendet haben. Wir hoffen dass Ihnen dieses Tutorial weitergeholfen hat!';
            const baseModalConstructor = Vue.extend(BaseModal);
            const baseModal = new baseModalConstructor(
                    { propsData: {
                            modalTitle: this.recordingTutorialTitle,
                            showConfirmButton: true,
                            labelButtonConfirm: 'Fertig',
                            showCancelButton: false,
                    }}
                );
            baseModal.$slots.default = [ message ];
            baseModal.$mount();    
            baseModal.open();
        },
        _showElementNotFoundNotification() {
            const message = 'Konnte ein Element in diesem Tutorial nicht finden. Was wollen Sie tun?';
            const baseModalConstructor = Vue.extend(BaseModal);
            const baseModal = new baseModalConstructor(
                    { propsData: {
                            modalTitle: 'Element nicht gefunden',
                            showConfirmButton: true,
                            labelButtonConfirm: 'Weiter',
                    }}
                );
            baseModal.$slots.default = [ message ];
            baseModal.$on("onConfirmButton", () => {
                this._lightWindow();
                if (this._direction == 'forward') {
                    ++this._currentStep;
                    if (this._currentStep < (this.slideItems.length-1)) {
                        this._dimWindow(this.$root.$el, 'interactive-help', this._exitInteractiveHelp); 
                        this._bbNextTooltipFunction(this);
                    } else {
                        this._showEndOfPresentationNotification();
                    }
                } else {
                    if (this._currentStep > 0) {--this._currentStep;}
                    this._bbPreviousTooltipFunction(this);
                }
            });
            baseModal.$mount();    
            baseModal.open();
        },
        _hideCurrentStep() {
            let existingtooltipContainer = this._targetElement.querySelector('.interactive-help-tooltip');

            // Hides tooltip while moving
            if (existingtooltipContainer) {
                existingtooltipContainer.style.display = "none";

                let helperLayer = this._targetElement.querySelector('.interactive-help-helperLayer');
                if (helperLayer) {
                    helperLayer.style.display = "none";
                }
    
                let helperLayerVector = this._targetElement.querySelector('.interactive-help-helperLayer-vector');
                if (helperLayerVector) {
                    helperLayerVector.style.display = "none";
                } 


            }             
        },
        _nextStep() {
            this._direction = 'forward';

            if (typeof (this._currentStep) === 'undefined') {
                this._currentStep = 0;
            } else {
                ++this._currentStep;
            }

            let nextStep = this.slideItems[this._currentStep];

            // By changing the route, the original component is somehow changed. This line ensures that the component will always be updated
            // nextStep.element = this._targetElement.querySelector(nextStep.helpPath);
            nextStep.element = this._targetElement.querySelector(`[tid="${nextStep.stepKey}"]`);

            if (!nextStep.element) {
                nextStep.element = document.querySelector(`[tid="${nextStep.stepKey}"]`);
            }

            let self = this;

            if (nextStep.autoClick) {
                this._clickElement(nextStep);

                window.setTimeout(function () {

                    self.$nextTick(() => {
                        self._nextStep();
                    });                    
    
                }, 1000); 

                // no need to highlight the element - just click it
                return;
            }            
            
            self._showStep(nextStep);

            if ((this.slideItems.length) <= this._currentStep) {
                this._exitInteractiveHelp();
                return;
            }

        },
        _previousStep() {
            this._direction = 'backward';

            if (this._currentStep === 0) {
                return false;
            }

            --this._currentStep;
            let nextStep = this.slideItems[this._currentStep];
            
            // By changing the route, the original component is somehow changed. This line ensures that the component will always be updated
            // nextStep.element = self._targetElement.querySelector(nextStep.helpPath);
            nextStep.element = this._targetElement.querySelector(`[tid="${nextStep.stepKey}"]`);
            
            if (!nextStep.element) {
                nextStep.element = document.querySelector(`[tid="${nextStep.stepKey}"]`);
            }
            
            let self = this;

            if (nextStep.autoClick) {
                this._clickElement(nextStep);

                window.setTimeout(function () {

                    self.$nextTick(() => {
                        self._previousStep();
                    });                    
    
                }, 1000); 

                // no need to highlight the element - just click it
                return;
            }              

            self._showStep(nextStep);

        }
    }
}

export default InteractiveHelpMixin;