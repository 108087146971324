<template>
  <div>
    <div class="container" v-if="renderComponent">
      <template v-if="stepType === 'step' && stepType !== 'tab'">
          <div class="layout__negative-margin--8 color-text">
            <BaseCollapsable class="mt-1 mb-1">
              <template v-slot:title>
                <div class="stepper-liner-sm">
                <div
                  v-for="(step, index) of stepsComputed"
                  :key="'nav' + index + lastUpdated"
                >
                  <div
                    class="stepper-item__icon"
                    @click="onClickStop(step.stepKey)"
                  >
                    <VerticalStepIcon
                      class="clickable"
                      :stepperStatus="stepperStatus(step)"
                      :isCurrent="selectedStepKey == step.stepKey"
                      :collapsed="true"
                    />
                  </div>
                  </div>
                </div>
              </template>
              <template v-slot:content>
                  <div class="antrag-sidetext__container mt-8px">
                    <div class="layout__negative-margin--8 color-text">
              <div v-for="(step, index) of stepsComputed" :key="'nav' + index + lastUpdated">
                <div v-bind:class="['clickable',{ 'font-bold' : selectedStepKey == step.stepKey },  { 'substep-sm': isMobileNativeContext }]" @click="onClickStop(step.stepKey)" v-html="sanitize(step.label)"></div>

                <template v-if="getSubsteps(step)[0].substepKey">
                  <div 
                    v-for="(substep, subIndex) of getSubsteps(step)"
                    :key="'sub' + subIndex + lastUpdated"
                    v-bind:class="['clickable pl-3',{ 'font-bold' : selectedSubstepKey == substep.substepKey  }, { 'substep-sm': isMobileNativeContext }]"
                    v-html="sanitize(substep.title)"
                    v-show="showLastSubstep(index, step) && !substep.hidden"
                    @click="onClickSubstep(step.stepKey, substep.substepKey)"
                  ></div>
                </template>
              </div>
            </div>
                  </div>
              </template>
            </BaseCollapsable>
          </div>
      </template>

      <div
        class="stepper-container stepper-container-tab"
        v-if="stepType === 'tab'"
      >
        <!-- on large screens: horizontal tabs -->
        <ul class="nav nav-tabs tabs-line d-none d-sm-flex pl-0">
          <template v-for="(step, index) of stepsComputed">
            <li
              :class="['nav-item', step.current && 'active']"
              :key="'nav' + index + lastUpdated"
            >
              <a
                class="nav-link"
                data-toggle="tab"
                @click="onClickStop(step.stepKey)"
                :class="[step.current && 'active']"
                >{{ step.label }}</a
              >
            </li>
          </template>
        </ul>

        <!-- on small screens: switch from horizontal tabs to dropdown -->
        <div class="nav nav-tabs tabs-line d-sm-none">
          <template v-for="(step, index) of stepsComputed">
            <li
              :class="['nav-item', step.current && 'active']"
              :key="'nav' + index + lastUpdated"
            >
              <a
                class="nav-link"
                data-toggle="tab"
                @click="onClickStop(step.stepKey)"
                :class="[step.current && 'active']"
                >{{ step.label }}</a
              >
            </li>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseDropdownMenu from "@/components/core/BaseDropdownMenu.vue";
import { PhCaretDown } from "phosphor-vue";
import { getStepVisible } from "../antrag/antrag-utils";
import DoughnutProgress from "@/components/stepper/DoughnutProgress.vue";
import { sanitize } from "@/helpers/string-helper.js";
import VerticalStepIcon from "@/components/vertical-stepper/VerticalStepIcon.vue";
import verticalStepperItemMixin from "@/components/vertical-stepper/vertical-stepper-item-mixin";
import BaseCollapsable from "@/components/core/BaseCollapsable.vue";
import { mapGetters } from 'vuex';
import BRIDGE_TYPES from '@/store/bridge/types';

export default {
  mixins: [verticalStepperItemMixin],
  props: {
    steps: {
      type: Array,
      default: function () {
        return [];
      },
    },
    currentStep: {
      type: Object,
      default: function () {
        return {};
      },
    },
    stepperName: {
      type: String,
      default: "",
    },
    stepType: {
      type: String,
      default: "step", // 'step' | 'tab'
    },
    selectedStepKey: {
      type: String,
      default: "",
    },
    visible: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledUpdate: {
      type: Boolean,
      default: false,
    },
    stepperService: {
      default: () => null,
    },
    antragData: {},
    selectedSubstepKey: {
      type: String,
      default: "",
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
    isCurrent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      subs: [],
      stepsComputed: [],
      renderComponent: true,
      lastUpdated: Date.now(),
    };
  },
  watch: {
    steps() {
      this.updateSteps();
    },
    currentStep: function (newVal) {
      newVal.step && this.progress(newVal.step.stepKey);
      this.updateSteps();
    },
    selectedStepKey: {
      handler(newVal) {
        this.progress(newVal);
        this.updateSteps();
      },
      immediate: true,
    },
    "stepperService.stepListSource": {
      handler() {
        this.updateSteps();
      },
    },
    stepperService() {
      this.updateSteps();
    },
    antragData() {
      this.updateSteps();
    },
  },
  mounted: function () {
    this.updateSteps();
    this.currentStep.step && this.progress(this.currentStep.step.stepKey);
  },
  computed: {
    ...mapGetters({
       isMobileNativeContext: BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT,
    }),
    getSelectedStepLabel() {
      if (!this.stepperService) {
        return "";
      }
      if (this.selectedStepKey) {
        const currentStep = this.stepperService.getCurrentStep(
          this.selectedStepKey
        );

        if (this.selectedSubstepKey) {
          return (
            currentStep?.substeps?.find(
              (st) => st.substepKey === this.selectedSubstepKey
            )?.title || ""
          );
        }

        return currentStep?.label || "";
      }
      return this.stepperService.currentStep &&
        this.stepperService.currentStep.label
        ? this.stepperService.currentStep.label
        : this.stepperService.getFirstStep().label;
    },
    percentage() {
      const currentIndex = this.stepsComputed.findIndex((step) => step.current);
      return ((currentIndex + 1) / this.stepsComputed.length) * 100;
    },
    labelProgress() {
      const currentIndex = this.stepsComputed.findIndex((step) => step.current);
      return `${currentIndex + 1} / ${this.stepsComputed.length}`;
    },
  },
  methods: {
    sanitize(htmlString) {
      return sanitize(htmlString);
    },
    updateSteps() {
      const completeStepList = this.stepperService.getCurrentStepList();
      const resultList = getStepVisible(completeStepList, this.antragData);
      this.stepsComputed = resultList;
      this.lastUpdated = Date.now();
      this.forceRerender();
    },
    forceRerender() {
      this.renderComponent = false;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    },
    isActive(stepKey) {
      return this.currentStep && this.currentStep.step.stepKey === stepKey;
    },
    isLastStep(index) {
      return this.steps.length - 1 === index;
    },
    subscribeToCurrentStepperList() {
      if (!this.stepperService) {
        return "";
      }
      this.stepsComputed = this.stepperService.getCurrentStepList();
    },
    progress(stepKey, index = 0) {
      if (this.stepperService) {
        this.stepperService.progress(stepKey, index, this.disabledUpdate);
        if (this.stepperService.currentStep) {
          if (this.stepperService.currentStep.actionFunc) {
            this.stepperService.currentStep.actionFunc();
          }
          const link = this.stepperService.currentStep.link;
          if (link && link !== this.$route.path) {
            this.$router.push({ path: link });
          }
        }
        this.updateSteps();
      }
    },
    onClickStop(stepKey) {
      if (!this.disabled) {
        this.$emit("setStep", stepKey);
        this.progress(stepKey);
      }
    },
    onClickSubstep(stepKey, substepKey) {
      if (substepKey && !this.disabled) {
        this.$emit("setSubstep", { stepKey, substepKey });
        this.progress(stepKey, substepKey);
        this.stepClicked = stepKey;
      }
    },
    showLastSubstep(index, step) {
      const isLast = index === this.stepsComputed?.length - 1;
      if (!isLast) {
        return true;
      }

      const substepList = this.getSubsteps(step);
      return substepList.length > 0 && substepList[0].title;
    },
    onClickStep(stepKey, substepKey = "", index = 0) {
      if (!this.disabled) {
        this.$emit("setStep", stepKey, substepKey);
        this.progress(stepKey, index);
      }
    },
    getSubsteps(step) {
      if (step?.substeps?.length > 0) {
        return step.substeps;
      }

      return [{}];
    },
    stepperStatus(step) {
      if (step.warning) {
        return "warning";
      } else if (step.success) {
        return "success";
      } else {
        return "default";
      }
    },
  },
  components: {
    BaseDropdownMenu,
    PhCaretDown,
    DoughnutProgress,
    VerticalStepIcon,
    BaseCollapsable,
  },
};
</script>
<style scoped>
.stepper-container {
  display: none;
  align-content: stretch;
  align-items: center;
  padding: 16px 16px 24px;
  /* margin-bottom: 16px; */
}

.stepper-container .rail:last-child {
  display: none;
}

.labels-container {
  display: flex;
  align-content: stretch;
  align-items: center;
}

.stepper-label {
  flex-grow: 1;
  white-space: nowrap;
}

.stop-container {
  flex-grow: 1;
}

.d-none {
  display: none;
}
.stepper-container-sm {
  display: block;
}

.stepper-container-tab {
  display: block;
  padding-bottom: 10px;
  padding-left: 0;
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .stepper-container-sm {
    display: none;
  }
}
li {
  cursor: pointer;
  background: var(--color-box);
  list-style: none;
  border: 1px solid #cccccc;
  padding: 10px;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: 6px;
}
li.active {
  border-bottom: none;
}
.step-sm-wrap {
  width: 80px;
  height: 80px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  margin: 0 12px 0 0;
}
.step-doughnut-chart {
  width: 80px;
  height: 80px;
}
.stepper-liner-sm {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 85%;
}
.step-num {
  position: absolute;
  font-size: 19px;
  padding-top: 8px;
}
.container-sm {
  position: relative;
  display: flex;
  align-items: center;
}
.step-title {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.step-title-arrow {
  flex: 0 0 auto;
  margin-right: 16px;
}
.substep-sm{
  font-size: 12px;
  margin-bottom: 2px;
  margin-top: 2px;
}
.stepper2-dropdown-menu__content {
  min-width: 250px;
}

.stepper2-steps,
.stepper2-steps * {
  box-sizing: border-box;
}

.stepper2-step--name,
.stepper2-substep {
  padding: 6px 0;
}

.app--is-mobile-native-context .stepper2-step--name,
.app--is-mobile-native-context .stepper2-substep {
  display: flex;
  align-items: center;
  padding: 8px 0;
  min-height: 44px;
}
</style>
