import LOG_TYPES from '@/store/log/types';

import {SlotColumn} from './table_util.js';
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";

import InputCheckBoxItem from '@/components/core/forms/checkbox/InputCheckBoxItem.vue';
import PageRowCount from './core/PageRowCount.vue';
import Pagination from './core/Pagination.vue';
import SelectRowPerPage from './core/SelectRowPerPage.vue';
import SortableTable from './core/SortableTable.vue';

const AVOID_MULTIPLE_MISSING_ROW_ID_LOG = [];

function stringify(obj, stringified_objects = []) {
    if (obj === null)
        return 'null';
    else if (obj === undefined)
        return 'undefined';
    switch (typeof obj) {
        case "bigint":
        case "boolean":
        case "number":
        case "symbol":
            return obj.toString();
        case "string":
            return `"${obj}"`;
        case "object":
            const recursionId = stringified_objects.indexOf(obj);
            if (recursionId >= 0) {
                // avoid infinite loops if an object contains a reference to itself
                return `recursion${recursionId}`;
            } else if (obj instanceof Array) {
                stringified_objects.push(obj);
                let result = obj
                    .map(o => stringify(o, stringified_objects))
                    .join(',');
                return `[${result}]`;
            } else if (obj instanceof Object) {
                stringified_objects.push(obj);
                let result = Object.entries(obj)
                    .sort(([key1, v1], [key2, v2]) => key1.localeCompare(key2)) // make the result deterministic
                    .map(([key, value]) => {
                        return stringify(key, stringified_objects) + ':' + stringify(value, stringified_objects);
                    }).join(',');
                return `{${result}}`;
            } else {
                // do other types of objects need to be handled?
                console.log("unknown kind of object", obj);
                return 'unknown';
            }
    }
}

export default {
    mixins: [InteractiveHelpCommonsMixin],
    components: {
        InputCheckBoxItem,
        SortableTable,
        PageRowCount,
        Pagination,
        SelectRowPerPage,
    },
    data() {
        return {
            pageIndex: 0,
            useSelected: false,
        };
    },
    watch: {
        /**
         * reason for useSelected: if we would just use selected
         * we would update the headerMap everytime we select a row
         * and that would rerender the table
         */
        selected: {
            handler(val) {
                this.useSelected = !!val
            },
            immediate: true,
        },
    },
    computed: {
        headerMap() {
            const result = {
                lockedLeft: (this.headers.lockedLeft || []).map(header => {
                    return {
                        ...header,
                        locked: true,
                    }
                }),
                center: this.headers.center || [],
                lockedRight: (this.headers.lockedRight || []).map(header => {
                    return {
                        ...header,
                        locked: true,
                    }
                }),
            };
            if (this.useSelected) {
                const selectColumn = SlotColumn('__SELECT__', null, 100, 0);
                selectColumn.locked = true;
                result.lockedLeft.unshift(selectColumn.makeAlwaysVisible());
            }
            return result;
        },
        headersFlat() {
            // this doesn't guarantee the correct order. but the order doesn't matter where it's used.
            return Object.values(this.headerMap).flat();
        },
        headersFlatLabelNull() {
            return  this.headersFlat
                .filter(h => h.label == null && h.key?.charAt(0) != "_")
                .map(h => ({...h, headerKey: 'header_' + h.key}));
        },
    },
    methods: {
        constructRowId(row) {
            const uniqueTableId = (this.tableId || '') + this.$route.path;
            if ((!this.rowId || row[this.rowId] == undefined) && !AVOID_MULTIPLE_MISSING_ROW_ID_LOG.includes(uniqueTableId)) {
                AVOID_MULTIPLE_MISSING_ROW_ID_LOG.push(uniqueTableId); // eine Meldung pro Tabelle reicht
                this.$store.dispatch(LOG_TYPES.ACTIONS.INFO, {message: "Missing rowId on table '" + this.title + "' (" + this.tableId + ")", rowId: this.rowId, row: {...row}, url: this.$route.path});
            }
            if (this.rowId && row[this.rowId] != undefined) {
                const result = stringify(row[this.rowId]);
                return result;
            } else {
                const result = stringify(row);
                return result;
            }
        },
        handleClick({row, columnKey}) {
            this.$emit("click-" + columnKey, row);
        },
        handleAction({key, row}) {
            this.$emit("action-" + key, row);
            this.$emit("action", {key, row});
        },
        handleFile({row, files}) {
            this.$emit('files', {
                row,
                files,
            });
        },
        handleHeaderAction({ key, value, }) {
            this.$emit('headerAction', { key, value, })
            this.$emit(`headerAction-${key}`, value)
        },
        columnConfigChanged(data) {
            this.$emit('configColumn', data)
        },
    },
}