var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "aa99ff36-6623-4fd3-8334-3402d1c0f727",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        width: "854.63024",
        height: "686",
        viewBox: "0 0 854.63024 686"
      }
    },
    [
      _c("title", [_vm._v("runner_start")]),
      _c("rect", {
        attrs: {
          y: "600",
          width: "821",
          height: "9.05263",
          fill: _vm.secondColor
        }
      }),
      _c("rect", {
        attrs: {
          y: "676.94737",
          width: "821",
          height: "9.05263",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M922.86275,715.32765c-.49067-.80178-12.06007-20.1196-16.071-60.23352-3.679-36.80241-1.31333-98.83576,30.858-185.36715,60.9476-163.928-14.04572-296.19408-14.812-297.51158l3.69987-2.14643c.19418.33408,19.54541,34.05675,30.977,87.75495a382.84576,382.84576,0,0,1-15.856,213.39386C880.81537,634.866,926.049,712.3356,926.5125,713.09979Z",
          transform: "translate(-172.68488 -107)",
          fill: _vm.secondColor
        }
      }),
      _c("circle", {
        attrs: {
          cx: "726.34587",
          cy: "27.79495",
          r: "27.79495",
          fill: _vm.secondColor
        }
      }),
      _c("circle", {
        attrs: {
          cx: "814.00686",
          cy: "130.42244",
          r: "27.79495",
          fill: _vm.secondColor
        }
      }),
      _c("circle", {
        attrs: {
          cx: "754.14082",
          cy: "198.84077",
          r: "27.79495",
          fill: _vm.color
        }
      }),
      _c("circle", {
        attrs: {
          cx: "826.83529",
          cy: "256.56874",
          r: "27.79495",
          fill: _vm.color
        }
      }),
      _c("circle", {
        attrs: {
          cx: "732.76009",
          cy: "346.3678",
          r: "27.79495",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d: "M939.65413,716.35075s-27.79494-68.41833,55.5899-119.73208Z",
          transform: "translate(-172.68488 -107)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d: "M905.47066,715.10967s-12.64973-72.75721-110.55654-72.13362Z",
          transform: "translate(-172.68488 -107)",
          fill: _vm.secondColor
        }
      }),
      _c("circle", {
        attrs: { cx: "136.5", cy: "387.5", r: "41", fill: "#a0616a" }
      }),
      _c("path", {
        attrs: {
          d:
            "M316.61319,443.50393c-7.84095-1.92541-16.27241-2.24666-23.8686.48934-7.82332,2.81781-14.26307,8.70853-18.99966,15.54294s-7.91769,14.6126-10.76728,22.4244a78.44181,78.44181,0,0,0-3.84443,13.13982,44.99218,44.99218,0,0,0,8.16687,34.32543c-1.20064-3.16564,1.82223-6.61689,5.13777-7.30246s6.693.474,9.96284,1.35215a61.559,61.559,0,0,0,17.68,2.07852c2.28419-.06472,4.67886-.2947,6.57841-1.5649,5.95161-3.97978,2.80132-14.26366,7.75373-19.43414,1.76742-1.84524,4.28949-2.70812,6.56745-3.86552,8.20045-4.16651,13.60409-12.57547,16.15533-21.41283,1.599-5.53888,5.56732-21.4494,2.1851-26.598C336.26419,448.026,321.80448,444.77869,316.61319,443.50393Z",
          transform: "translate(-172.68488 -107)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M420.75137,449.29118a24.39569,24.39569,0,0,0-18.67736,1.04125c-10.69477,5.01556-29.6079,17.167-28.88913,40.16757,1,32,12,53,12,53l-2,65-10,118s-49,40-30,50,51-48,51-48l25-67,9-65s15-68,0-98C428.18488,498.5,445.51332,458.32953,420.75137,449.29118Z",
          transform: "translate(-172.68488 -107)",
          fill: "#a0616a"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M540.18488,552.5s-17,72,43,92,139,54,139,54l10,17,23-31-4-24-39-11s-73-56-105-60l4-37Z",
          transform: "translate(-172.68488 -107)",
          fill: "#a0616a"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M699.992,494.717,721.18488,529.5s70,40,88,67,61,56,61,56l-19,45s-54-63-77-69-125-88-125-88Z",
          transform: "translate(-172.68488 -107)",
          fill: "#a0616a"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M511.25319,372.81879c-7.80567,1.22636-15.61761,2.4539-23.32959,4.17376-17.91807,3.99593-35.14787,10.61624-51.96679,17.97482a608.4047,608.4047,0,0,0-68.44723,35.267,132.25462,132.25462,0,0,0-16.96877,11.52223c-3.55981,2.96479-6.911,6.36338-8.82048,10.58428a33.40584,33.40584,0,0,0-2.32964,9.22124c-1.55047,11.11993-1.47826,22.59448,1.53455,33.41019s9.12353,20.95468,18.11834,27.67407a29.8797,29.8797,0,0,0,7.84122,4.34736,45.67529,45.67529,0,0,0,11.67415,1.95731c12.61732,1.0033,25.79808,1.92307,37.47387-2.96358,7.41963-3.10532,13.70126-8.3547,20.52849-12.6071,28.91252-18.00838,65.6459-17.24158,97.26049-29.92044,3.07335-1.23254,6.20327-2.67,8.353-5.18856a22.57757,22.57757,0,0,0,3.61973-7.18764q5.99545-16.59368,11.99092-33.18736c2.53264-7.00959,5.06682-14.02386,7.19258-21.16737,2.2235-7.47193,3.99546-15.0694,5.766-22.66143,1.52358-6.53317,3.0928-13.37591,2.507-20.1298-.864-9.96222-3.16626-10.36663-12.316-8.92459Q536.096,368.92794,511.25319,372.81879Z",
          transform: "translate(-172.68488 -107)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M390.75137,449.29118a24.39569,24.39569,0,0,0-18.67736,1.04125c-10.69477,5.01556-29.6079,17.167-28.88913,40.16757,1,32,12,53,12,53l-2,65-10,118s-49,40-30,50,51-48,51-48l25-67,9-65s15-68,0-98C398.18488,498.5,415.51332,458.32953,390.75137,449.29118Z",
          transform: "translate(-172.68488 -107)",
          fill: "#a0616a"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M564.34468,364.5437s88.8402-8.0437,84.8402,46.9563-34,150-34,150l-77,4s-10-65,4-87Z",
          transform: "translate(-172.68488 -107)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d: "M626.18488,382.5s22,14,34,42,48,83,48,83l-59,42-44-45Z",
          transform: "translate(-172.68488 -107)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M732.18488,694.5s-19,7-6,21a126.6093,126.6093,0,0,1,20,29s-4,26,13,25,24-36,20-48-9-69-9-69-35,3-34,8S742.18488,690.5,732.18488,694.5Z",
          transform: "translate(-172.68488 -107)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M851.18488,674.5s-18,9-13,17,21,31,21,31-6,30,11,33,27-36,24-44-3-39-3-39,1-31-9-31-27,4-27,4S859.18488,673.5,851.18488,674.5Z",
          transform: "translate(-172.68488 -107)",
          fill: "#2f2e41"
        }
      }),
      _c("rect", {
        attrs: {
          x: "96",
          y: "617.87081",
          width: "29",
          height: "50.61244",
          fill: _vm.color
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }