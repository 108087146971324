var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        "data-name": "Layer 1",
        width: "649.67538",
        height: "624.54164",
        viewBox: "0 0 649.67538 624.54164"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M766.19725,469.75715c23.20617-13.57216,54.12956-16.40841,76.48255-1.47333a123.8633,123.8633,0,0,0-67.54444,51.66847c-6.02488,9.53009-11.73369,20.75719-22.43378,24.31124-6.65781,2.21155-14.08868.848-20.40988-2.19449-6.32143-3.04276-11.74128-7.64-17.08431-12.18654l-1.70646-.39388C726.41237,505.90847,742.99108,483.32932,766.19725,469.75715Z",
          transform: "translate(-275.16231 -137.72918)",
          fill: "#f0f0f0"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M842.4325,468.79083a105.86978,105.86978,0,0,0-63.31679,15.29261,45.59114,45.59114,0,0,0-11.02378,9.08434,26.14849,26.14849,0,0,0-5.68256,12.53387c-.77734,4.28487-1.07626,8.75784-2.88165,12.77914a16.01082,16.01082,0,0,1-9.90687,8.562c-5.34359,1.75574-10.99938,1.48673-16.52112,1.03146-6.13084-.5055-12.49085-1.12769-18.361,1.154-.71126.27646-1.09864-.84334-.38845-1.11937,10.21314-3.96971,21.16781.51331,31.65675-1.37539,4.89433-.88131,9.55272-3.24292,11.96652-7.77224,2.11076-3.9607,2.423-8.573,3.17252-12.91834a27.90243,27.90243,0,0,1,5.0997-12.46244,42.14789,42.14789,0,0,1,10.63385-9.47465,102.71264,102.71264,0,0,1,29.35037-13.00424,107.74732,107.74732,0,0,1,36.43212-3.47328c.75837.05776.52386,1.22-.22956,1.16261Z",
          transform: "translate(-275.16231 -137.72918)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M785.00494,479.98065a15.88384,15.88384,0,0,1,5.871-19.71217c.64032-.41374,1.31332.56184.67215.97612a14.7081,14.7081,0,0,0-5.42382,18.3476c.31055.69631-.8106,1.08077-1.11937.38845Z",
          transform: "translate(-275.16231 -137.72918)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M762.51736,503.47713a30.61478,30.61478,0,0,0,22.3107.14756c.71221-.27393,1.0998.84578.38845,1.11937a31.844,31.844,0,0,1-23.216-.20073c-.70872-.28511-.188-1.34975.51684-1.0662Z",
          transform: "translate(-275.16231 -137.72918)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M817.86333,469.42975a8.991,8.991,0,0,0,5.51988,6.50508c.712.276.1905,1.34038-.51685,1.06619a10.07516,10.07516,0,0,1-6.12241-7.18282.61234.61234,0,0,1,.36546-.75391.59544.59544,0,0,1,.75392.36546Z",
          transform: "translate(-275.16231 -137.72918)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M851.9459,558.83346c-.44948-.15968-.899-.31937-1.35667-.47336a118.40826,118.40826,0,0,0-18.58627-4.84469c-.487-.09349-.98192-.18091-1.47058-.26021a124.82707,124.82707,0,0,0-45.94646,1.01148,121.22256,121.22256,0,0,0-17.68017,5.16285c-7.94625,2.99291-16.32388,6.87025-24.55256,7.05779a20.79516,20.79516,0,0,1-2.57286-.07183L713.15278,531.601c-.02081-.10949-.04981-.21332-.07092-.3232l-1.12671-1.34142c.28895-.13248.59148-.26367.88043-.39615.1673-.07755.34052-.14734.50782-.22488.1134-.04924.22716-.09813.32668-.149.03769-.01653.07567-.03267.10546-.04316.09952-.05092.19737-.08764.28895-.13248q2.53359-1.107,5.09156-2.18222c.00795-.00608.00795-.00608.02183-.0044,13.00759-5.42865,26.58266-9.81906,40.39255-12.00946.41551-.06576.83867-.138,1.26635-.18786a115.674,115.674,0,0,1,18.83046-1.32153,102.26086,102.26086,0,0,1,10.32486.67672,85.03677,85.03677,0,0,1,25.58909,7.21883c16.143,7.44427,29.72557,19.96851,35.90491,36.39321C851.64388,557.99422,851.79607,558.4071,851.9459,558.83346Z",
          transform: "translate(-275.16231 -137.72918)",
          fill: "#f0f0f0"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M851.44417,559.09315A105.86973,105.86973,0,0,0,791.682,533.18237a45.59123,45.59123,0,0,0-14.27127.61625,26.14861,26.14861,0,0,0-12.08346,6.5863c-3.20045,2.95322-6.13216,6.34467-9.99476,8.46849a16.0108,16.0108,0,0,1-13.065.87161c-5.32364-1.81535-9.67751-5.43531-13.8122-9.12329-4.59079-4.0948-9.29431-8.42076-15.35505-10.13324-.73434-.20749-.36945-1.33481.36379-1.12764,10.54465,2.97943,16.59226,13.15434,26.10422,17.96138,4.43846,2.24306,9.57977,3.16212,14.234.999,4.06994-1.89158,7.09614-5.38628,10.31081-8.40449a27.90262,27.90262,0,0,1,11.57508-6.8802,42.14809,42.14809,0,0,1,14.19493-1.16266,102.71252,102.71252,0,0,1,31.26407,7.28779,107.74716,107.74716,0,0,1,31.18016,19.16143c.57075.50272-.31624,1.2895-.88325.79007Z",
          transform: "translate(-275.16231 -137.72918)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M798.85443,533.45224a15.88382,15.88382,0,0,1,16.55579-12.20429.59294.59294,0,0,1-.051,1.18406,14.70808,14.70808,0,0,0-15.37713,11.384c-.17127.74294-1.29793.3749-1.12763-.36378Z",
          transform: "translate(-275.16231 -137.72918)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M766.75285,538.6738a30.61479,30.61479,0,0,0,17.725,13.55038c.73358.21009.36891,1.33747-.36378,1.12764a31.844,31.844,0,0,1-18.41581-14.1379c-.39422-.65434.66251-1.19091,1.05459-.54012Z",
          transform: "translate(-275.16231 -137.72918)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M831.44238,544.811a8.991,8.991,0,0,0,.49081,8.51729c.40232.649-.6549,1.18491-1.05459.54012a10.07515,10.07515,0,0,1-.56385-9.4212.61233.61233,0,0,1,.74571-.38192.59543.59543,0,0,1,.38192.74571Z",
          transform: "translate(-275.16231 -137.72918)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M314.34981,682.19966c2.7226-26.74542,18.66661-53.39291,43.90977-62.63932a123.86328,123.86328,0,0,0,1.9005,85.01924c4.1442,10.48558,9.84534,21.71657,6.40619,32.45414-2.13979,6.68122-7.62287,11.87862-13.80752,15.18984-6.185,3.31123-13.09388,4.97768-19.91651,6.61194l-1.32437,1.146C320.08707,735.64905,311.62722,708.94507,314.34981,682.19966Z",
          transform: "translate(-275.16231 -137.72918)",
          fill: "#f0f0f0"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M358.52323,620.059A105.86972,105.86972,0,0,0,333.538,680.214a45.59107,45.59107,0,0,0,.83646,14.26006,26.14849,26.14849,0,0,0,6.772,11.98035c3.00227,3.15448,6.43857,6.0335,8.62175,9.86286a16.01084,16.01084,0,0,1,1.07318,13.04995c-1.733,5.351-5.28529,9.76025-8.909,13.95138-4.02345,4.65344-8.27628,9.42317-9.895,15.50963-.19613.73746-1.329.39-1.13312-.34634,2.81632-10.58939,12.89666-16.79333,17.55631-26.37836,2.17428-4.47255,3.01387-9.62744.77915-14.24774-1.95417-4.04025-5.49517-7.01215-8.56264-10.17986a27.90247,27.90247,0,0,1-7.05805-11.46749,42.148,42.148,0,0,1-1.38163-14.17529,102.71249,102.71249,0,0,1,6.80435-31.37284,107.747,107.747,0,0,1,18.67786-31.47222c.49384-.57844,1.29423.2963.80361.87095Z",
          transform: "translate(-275.16231 -137.72918)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M333.69712,673.03829a15.88382,15.88382,0,0,1-12.45838-16.36543.593.593,0,0,1,1.18471.03273,14.70809,14.70809,0,0,0,11.62,15.19959c.74549.15978.39489,1.292-.34634,1.13311Z",
          transform: "translate(-275.16231 -137.72918)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M339.41356,705.05545a30.61481,30.61481,0,0,0,13.27518-17.93205c.19874-.73674,1.33161-.38951,1.13312.34634a31.844,31.844,0,0,1-13.852,18.63184c-.64818.40427-1.201-.64406-.55634-1.04613Z",
          transform: "translate(-275.16231 -137.72918)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M344.55148,640.27889a8.991,8.991,0,0,0,8.50869-.62222c.64274-.41228,1.19489.63653.55634,1.04613a10.07515,10.07515,0,0,1-9.41137.70921.61234.61234,0,0,1-.39339-.73973.59544.59544,0,0,1,.73973-.39339Z",
          transform: "translate(-275.16231 -137.72918)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M436.85525,665.47132c-.394.26885-.788.53771-1.1823.81658a118.40724,118.40724,0,0,0-14.87259,12.15426c-.36267.33818-.72512.68637-1.07732,1.03427a124.827,124.827,0,0,0-26.27647,37.70474,121.22316,121.22316,0,0,0-6.25579,17.32364c-2.26848,8.18257-4.077,17.23506-8.7778,23.99147a20.7948,20.7948,0,0,1-1.57516,2.03559l-43.8192.97637c-.1007-.04776-.20167-.08556-.30285-.1333l-1.74779.119c.06339-.31149.13583-.63319.19922-.94468.036-.18084.08181-.3619.11783-.54274.0271-.12062.05469-.24132.07226-.35173.00887-.04018.01823-.08038.02732-.11062.01757-.1104.04561-.21109.06339-.31149q.59994-2.699,1.23991-5.399c-.00023-.01-.00023-.01.00931-.02023,3.28582-13.70661,7.74479-27.25931,14.119-39.70438.1919-.37436.3831-.75872.595-1.13353A115.67383,115.67383,0,0,1,357.44777,696.988a102.26084,102.26084,0,0,1,6.63484-7.93975A85.03658,85.03658,0,0,1,385.002,672.6382c15.53143-8.64812,33.65578-12.23274,50.56484-7.53824C435.99935,665.22037,436.42255,665.34091,436.85525,665.47132Z",
          transform: "translate(-275.16231 -137.72918)",
          fill: "#f0f0f0"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M436.76913,666.02967a105.8698,105.8698,0,0,0-56.16675,32.98752,45.59158,45.59158,0,0,0-7.91767,11.88947,26.14862,26.14862,0,0,0-1.80591,13.64288c.49793,4.32625,1.50825,8.69387.94586,13.06582a16.01083,16.01083,0,0,1-7.0001,11.06579c-4.60536,3.22913-10.09635,4.6109-15.513,5.77555-6.01419,1.29312-12.28155,2.541-17.23848,6.42609-.6006.47073-1.29591-.48872-.69621-.95875,8.62421-6.75941,20.408-5.64385,29.89932-10.49153,4.42882-2.262,8.20279-5.87241,9.20023-10.90693.87221-4.40246-.16579-8.90728-.70781-13.28334a27.90236,27.90236,0,0,1,1.26877-13.40558,42.14776,42.14776,0,0,1,7.43135-12.15,102.71255,102.71255,0,0,1,24.3215-20.95278,107.7473,107.7473,0,0,1,33.86168-13.88344c.74256-.16452.855,1.0158.11726,1.17924Z",
          transform: "translate(-275.16231 -137.72918)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M385.0497,693.38361a15.88384,15.88384,0,0,1-.0942-20.56769c.49292-.58156,1.41979.15708.92622.73941a14.70808,14.70808,0,0,0,.12672,19.13207c.499.57642-.46257,1.26933-.95874.69621Z",
          transform: "translate(-275.16231 -137.72918)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M370.33742,722.38921a30.61483,30.61483,0,0,0,21.39582-6.32516c.60225-.46859,1.29773.49071.69621.95874a31.844,31.844,0,0,1-22.27767,6.53665c-.76093-.06746-.57116-1.23733.18564-1.17023Z",
          transform: "translate(-275.16231 -137.72918)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M413.43972,673.76213a8.991,8.991,0,0,0,7.16833,4.626c.76141.05778.57082,1.22763-.18564,1.17023a10.07518,10.07518,0,0,1-7.94144-5.1.61233.61233,0,0,1,.13127-.82747.59543.59543,0,0,1,.82748.13126Z",
          transform: "translate(-275.16231 -137.72918)",
          fill: _vm.secondColor
        }
      }),
      _c(
        "g",
        {
          attrs: {
            id: "a319387a-1074-4617-a9d7-8c11a1aba957",
            "data-name": "Group 1"
          }
        },
        [
          _c("path", {
            attrs: {
              id: "a64f6941-3d46-45f6-b9a5-e3f4c7441463-285",
              "data-name": "Path 1",
              d:
                "M652.06754,211.2392l-235.885-23.202a19.3559,19.3559,0,0,1-7.747-2.529,23.475,23.475,0,0,1-6.407-5.48,26.432,26.432,0,0,1-5.97-16.648,24.49392,24.49392,0,0,1,.393-4.409,22.07142,22.07142,0,0,1,1.153-4.079,20.066,20.066,0,0,1,1.872-3.648,18.69279,18.69279,0,0,1,2.549-3.12,17.388,17.388,0,0,1,3.084-2.421,16.34494,16.34494,0,0,1,7.171-2.371,17.58147,17.58147,0,0,1,3.9.1l235.887,31.908a10.9,10.9,0,0,1,4.959,2.077,16.39086,16.39086,0,0,1,4.017,4.352,22.49311,22.49311,0,0,1,2.692,5.987,25.37834,25.37834,0,0,1,.984,7.011,24.54784,24.54784,0,0,1-.24,3.454,21.70315,21.70315,0,0,1-.706,3.222,18.66649,18.66649,0,0,1-1.151,2.92,15.78432,15.78432,0,0,1-1.577,2.547,12.85834,12.85834,0,0,1-1.925,2.037,10.412,10.412,0,0,1-2.173,1.42,9.054,9.054,0,0,1-4.881.871Z",
              transform: "translate(-275.16231 -137.72918)",
              fill: "#e4e4e4"
            }
          }),
          _c("path", {
            attrs: {
              id: "f573f903-41a4-475b-90ae-b1e8d0598757-286",
              "data-name": "Path 2",
              d:
                "M547.22353,200.92719l-131.041-12.89a19.3559,19.3559,0,0,1-7.747-2.529,23.475,23.475,0,0,1-6.407-5.48,26.432,26.432,0,0,1-5.97-16.648,24.49392,24.49392,0,0,1,.393-4.409,22.07142,22.07142,0,0,1,1.153-4.079,20.066,20.066,0,0,1,1.872-3.648,18.69279,18.69279,0,0,1,2.549-3.12,17.388,17.388,0,0,1,3.084-2.421,16.34494,16.34494,0,0,1,7.171-2.371,17.58147,17.58147,0,0,1,3.9.1l131.04,17.729Z",
              transform: "translate(-275.16231 -137.72918)",
              fill: _vm.color
            }
          }),
          _c("path", {
            attrs: {
              id: "b9dc6a73-2ff5-489e-a572-426ec7a03266-287",
              "data-name": "Path 3",
              d:
                "M546.88853,203.85619a3.86587,3.86587,0,0,1-1.7-.6,5.177,5.177,0,0,1-1.386-1.313,6.526,6.526,0,0,1-.94-1.847,7.057,7.057,0,0,1-.346-2.182v-34.846a6.3231,6.3231,0,0,1,.345-2.1,4.88,4.88,0,0,1,.941-1.625,3.693,3.693,0,0,1,1.39-1,3.32307,3.32307,0,0,1,1.7-.2,4.007,4.007,0,0,1,1.69.666,5.458,5.458,0,0,1,1.374,1.37,6.80028,6.80028,0,0,1,.928,1.87,7.1632,7.1632,0,0,1,.34,2.177v34.565a6.42481,6.42481,0,0,1-.34,2.1,5.09988,5.09988,0,0,1-.928,1.652,3.87889,3.87889,0,0,1-1.378,1.045A3.365,3.365,0,0,1,546.88853,203.85619Z",
              transform: "translate(-275.16231 -137.72918)",
              fill: "#3f3d56"
            }
          }),
          _c("path", {
            attrs: {
              id: "b44bc342-b83f-44d8-9fe9-a8f7383873d1-288",
              "data-name": "Path 4",
              d:
                "M337.26854,175.25319a5.17536,5.17536,0,0,1-.693-.119,5.47518,5.47518,0,0,1-.687-.216,5.83748,5.83748,0,0,1-.672-.311,6.24936,6.24936,0,0,1-.65-.406,6.47,6.47,0,0,1-2.526-3.715,6.35345,6.35345,0,0,1-.185-1.525v-26.166a5.8347,5.8347,0,0,1,.185-1.479,5.07666,5.07666,0,0,1,.535-1.3,4.6,4.6,0,0,1,1.992-1.825,4.70838,4.70838,0,0,1,1.321-.407,4.90017,4.90017,0,0,1,1.38-.012,5.42839,5.42839,0,0,1,1.377.374,6.20047,6.20047,0,0,1,1.312.75l20.898,15.525a6.391,6.391,0,0,1,1.02.948,6.46325,6.46325,0,0,1,1.311,2.4,6.38473,6.38473,0,0,1,.245,1.361.86967.86967,0,0,0,.007.11.43451.43451,0,0,1,0,.11v.221a5.85729,5.85729,0,0,1-.176,1.445,5.1104,5.1104,0,0,1-.51,1.273,4.64708,4.64708,0,0,1-.815,1.05,4.54207,4.54207,0,0,1-1.082.776l-20.9,10.641a4.82027,4.82027,0,0,1-.645.271,4.77062,4.77062,0,0,1-.668.173,4.82031,4.82031,0,0,1-.684.075A4.94777,4.94777,0,0,1,337.26854,175.25319Z",
              transform: "translate(-275.16231 -137.72918)",
              fill: "#cacaca"
            }
          })
        ]
      ),
      _c(
        "g",
        {
          attrs: {
            id: "ed5e4939-ca06-4842-8bf9-38ff7399ab22",
            "data-name": "Group 2"
          }
        },
        [
          _c("path", {
            attrs: {
              id: "ad4c3588-958a-44e3-8958-86383ba49959-289",
              "data-name": "Path 5",
              d:
                "M652.11353,325.17419l-235.86,4.448a17.751,17.751,0,0,1-7.746-1.621,19.823,19.823,0,0,1-6.407-4.729,22.65406,22.65406,0,0,1-4.361-7.141,24.918,24.918,0,0,1,0-17.613,22.67616,22.67616,0,0,1,4.361-7.143,19.845,19.845,0,0,1,6.407-4.735,17.7598,17.7598,0,0,1,7.746-1.626l235.86,4.263a9.7709,9.7709,0,0,1,4.959,1.5,13.77868,13.77868,0,0,1,4.016,3.873,19.69239,19.69239,0,0,1,2.691,5.671,24.66307,24.66307,0,0,1,0,13.792,19.71128,19.71128,0,0,1-2.691,5.674,13.80034,13.80034,0,0,1-4.016,3.885A9.776,9.776,0,0,1,652.11353,325.17419Z",
              transform: "translate(-275.16231 -137.72918)",
              fill: "#e4e4e4"
            }
          }),
          _c("path", {
            attrs: {
              id: "b33bc1e4-6ad7-49be-b233-6aeb4dd08a7d-290",
              "data-name": "Path 6",
              d:
                "M508.44053,327.88419l-92.187,1.738a17.751,17.751,0,0,1-7.746-1.621,19.823,19.823,0,0,1-6.407-4.729,22.65406,22.65406,0,0,1-4.361-7.141,24.61323,24.61323,0,0,1-1.608-8.806,25.26544,25.26544,0,0,1,.393-4.455,24.16391,24.16391,0,0,1,1.153-4.213,23.08043,23.08043,0,0,1,1.872-3.867,22.15279,22.15279,0,0,1,2.548-3.419,20.65914,20.65914,0,0,1,3.084-2.787,18.9058,18.9058,0,0,1,3.45-2.01,17.8,17.8,0,0,1,7.622-1.56l92.187,1.666Z",
              transform: "translate(-275.16231 -137.72918)",
              fill: _vm.color
            }
          }),
          _c("path", {
            attrs: {
              id: "f48897ce-ac32-418c-8d3c-67ca4382e4e0-291",
              "data-name": "Path 7",
              d:
                "M509.51753,330.49119a3.87789,3.87789,0,0,1-1.816-.41,4.63395,4.63395,0,0,1-1.488-1.194,5.79206,5.79206,0,0,1-1.007-1.8,6.70007,6.70007,0,0,1-.37-2.214v-36.06a6.69994,6.69994,0,0,1,.37-2.216,5.78989,5.78989,0,0,1,1.007-1.8,4.63291,4.63291,0,0,1,1.488-1.19,3.87789,3.87789,0,0,1,1.816-.41,3.974,3.974,0,0,1,1.81.486,4.87506,4.87506,0,0,1,1.475,1.247,6.044,6.044,0,0,1,.993,1.822,6.81015,6.81015,0,0,1,.364,2.211v35.76a6.81211,6.81211,0,0,1-.364,2.211,6.046,6.046,0,0,1-.993,1.823,4.87486,4.87486,0,0,1-1.475,1.249A3.974,3.974,0,0,1,509.51753,330.49119Z",
              transform: "translate(-275.16231 -137.72918)",
              fill: "#3f3d56"
            }
          }),
          _c("path", {
            attrs: {
              id: "b8c06ef9-eeb4-4e99-947d-47e1efa9800c-292",
              "data-name": "Path 8",
              d:
                "M337.34854,326.08819a5.00725,5.00725,0,0,1-1.38-.173,5.13927,5.13927,0,0,1-.672-.232,5.3469,5.3469,0,0,1-.649-.33,5.44372,5.44372,0,0,1-1.138-.9,5.5,5.5,0,0,1-.853-1.16,5.7266,5.7266,0,0,1-.535-1.36,6.08457,6.08457,0,0,1-.185-1.5v-26.166a6.08738,6.08738,0,0,1,.185-1.5,5.73138,5.73138,0,0,1,.535-1.358,5.41716,5.41716,0,0,1,1.992-2.059,5.25608,5.25608,0,0,1,1.321-.567,5.013,5.013,0,0,1,2.756.039,5.386,5.386,0,0,1,1.311.597l20.9,13.074.075.048.075.049.074.05.073.051a5.379,5.379,0,0,1,.913.836,5.50612,5.50612,0,0,1,.7,1.017,5.73671,5.73671,0,0,1,.463,1.161,6.03836,6.03836,0,0,1,.21,1.268v.365a6.1004,6.1004,0,0,1-.176,1.465,5.75431,5.75431,0,0,1-.51,1.332,5.50022,5.50022,0,0,1-.814,1.145,5.37657,5.37657,0,0,1-1.091.9l-20.892,13.097a5.4864,5.4864,0,0,1-.644.347,5.25459,5.25459,0,0,1-.668.251,5.10067,5.10067,0,0,1-.684.155A5.01481,5.01481,0,0,1,337.34854,326.08819Z",
              transform: "translate(-275.16231 -137.72918)",
              fill: "#cacaca"
            }
          })
        ]
      ),
      _c("path", {
        attrs: {
          id: "b462dce5-9b01-4b3d-8b91-2a1db41ed251-293",
          "data-name": "Path 9",
          d:
            "M652.06754,438.7282l-235.885,32.007a16.668,16.668,0,0,1-14.155-4.7,19.37108,19.37108,0,0,1-4.362-6.631,23.12613,23.12613,0,0,1-1.607-8.615,26.439,26.439,0,0,1,5.97-16.65,23.489,23.489,0,0,1,6.407-5.483,19.36414,19.36414,0,0,1,7.747-2.532l235.885-23.294a9.03791,9.03791,0,0,1,4.959.916,11.607,11.607,0,0,1,4.017,3.412,17.1627,17.1627,0,0,1,2.692,5.357,23.00048,23.00048,0,0,1,.984,6.781,25.38166,25.38166,0,0,1-.984,7.011,22.5,22.5,0,0,1-2.692,5.988,16.40006,16.40006,0,0,1-4.017,4.354A10.9,10.9,0,0,1,652.06754,438.7282Z",
          transform: "translate(-275.16231 -137.72918)",
          fill: "#e4e4e4"
        }
      }),
      _c("path", {
        attrs: {
          id: "aa3c64d8-5563-4d05-b78c-2abe85ac0fb1-294",
          "data-name": "Path 10",
          d:
            "M614.97754,443.76021l-198.795,26.974a16.668,16.668,0,0,1-14.155-4.7,19.37,19.37,0,0,1-4.362-6.631,23.126,23.126,0,0,1-1.607-8.611,26.04652,26.04652,0,0,1,.393-4.5,26.3319,26.3319,0,0,1,5.574-12.157,24.59917,24.59917,0,0,1,3.084-3.143,22.15236,22.15236,0,0,1,3.449-2.417,20.13139,20.13139,0,0,1,3.721-1.636,18.72338,18.72338,0,0,1,3.9-.815l198.798-19.632Z",
          transform: "translate(-275.16231 -137.72918)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          id: "b26b52bd-87f8-4a7b-abf9-07c8514958b4-295",
          "data-name": "Path 12",
          d:
            "M337.26754,476.42021a4.99964,4.99964,0,0,1-.693.043,4.80626,4.80626,0,0,1-.686-.054,4.69989,4.69989,0,0,1-1.321-.407,4.6,4.6,0,0,1-1.992-1.829,5.07494,5.07494,0,0,1-.535-1.3,5.83333,5.83333,0,0,1-.185-1.473v-26.17a6.3533,6.3533,0,0,1,.185-1.522,6.477,6.477,0,0,1,2.527-3.716,6.03437,6.03437,0,0,1,1.321-.717,5.33236,5.33236,0,0,1,1.38-.335,4.88788,4.88788,0,0,1,1.377.052,4.773,4.773,0,0,1,1.31.443l20.9,10.633.076.039.075.041.074.042.073.043a4.52324,4.52324,0,0,1,.87.686,4.64617,4.64617,0,0,1,.678.872,5.01207,5.01207,0,0,1,.469,1.029,5.57623,5.57623,0,0,1,.244,1.157q.008.078.014.156t.01.158q0,.079.006.159a1.08153,1.08153,0,0,1,0,.161,6.36265,6.36265,0,0,1-.176,1.486,6.46713,6.46713,0,0,1-1.313,2.634,6.38738,6.38738,0,0,1-1.1,1.031l-20.9,15.533a6.43013,6.43013,0,0,1-.645.422,5.98123,5.98123,0,0,1-.668.329,5.59432,5.59432,0,0,1-.684.235A5.27057,5.27057,0,0,1,337.26754,476.42021Z",
          transform: "translate(-275.16231 -137.72918)",
          fill: "#cacaca"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M613.54357,416.98936a10.05578,10.05578,0,0,1,11.48425,10.28921l33.3496,12.83679-15.10948,10.79525L613.915,437.06555a10.11027,10.11027,0,0,1-.37147-20.07619Z",
          transform: "translate(-275.16231 -137.72918)",
          fill: "#ffb6b6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M740.17206,410.68783l5.64194,10.25772a29.45576,29.45576,0,0,1-9.99855,16.60937c-8.63806,7.28517-53.804,34.418-53.804,34.418l-57.01976-28.62531,9.97484-13.255L679.7325,445.0951l25.25384-23.84726Z",
          transform: "translate(-275.16231 -137.72918)",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "757.1811",
          y: "570.43245",
          width: "6",
          height: "192",
          transform: "translate(-385.41833 14.55667) rotate(-10.67092)",
          fill: "#cacaca"
        }
      }),
      _c("rect", {
        attrs: {
          x: "704.39039",
          y: "570.43245",
          width: "6",
          height: "192",
          transform: "translate(1003.98357 1314.59704) rotate(-169.32908)",
          fill: "#cacaca"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "454.51879",
          cy: "428.19741",
          rx: "44.5",
          ry: "14.5",
          fill: "#cacaca"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "360.004 611.715 347.744 611.714 341.912 564.426 360.006 564.427 360.004 611.715",
          fill: "#ffb6b6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M638.29257,761.32781l-39.53076-.00146v-.5a15.3873,15.3873,0,0,1,15.38648-15.38623h.001l24.144.001Z",
          transform: "translate(-275.16231 -137.72918)",
          fill: "#2f2e41"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "419.518 547.373 408.809 553.342 380.689 514.878 396.494 506.068 419.518 547.373",
          fill: "#ffb6b6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M703.19746,693.95945l-34.52864,19.24719-.24346-.43672a15.38729,15.38729,0,0,1,5.94731-20.93106l.00085-.00048,21.089-11.75546Z",
          transform: "translate(-275.16231 -137.72918)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M764.3136,530.47559s5.94429,18.41721-30.52785,41.7086L686.8136,618.99,638.00694,735.73937,609.03118,722.7489l44.55956-118.94151,26.0575-45.6243.75591-24.7565,6.40945-17.451,46.5-16.5Z",
          transform: "translate(-275.16231 -137.72918)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M668.94282,684.33675l-48.47974-73.09131a17.36012,17.36012,0,0,1,2.325-21.98047l49.08642-48.01562,8.32276-8.32276h18.62426l-8.73315,32.22119-30.29688,34.34278,35.02417,61.978Z",
          transform: "translate(-275.16231 -137.72918)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M704.573,430.33261l14.60815-20.906,33.37879-2.68536s28.69539,27.03569,23.47455,62.635-10.22084,70.59934-10.22084,70.59934-52-37-79-24Z",
          transform: "translate(-275.16231 -137.72918)",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M694.65578,539.39976a10.05576,10.05576,0,0,1,13.38046-7.66284l22.67946-27.6156,5.492,17.739L713.82888,545.365a10.11028,10.11028,0,0,1-19.1731-5.96522Z",
          transform: "translate(-275.16231 -137.72918)",
          fill: "#ffb6b6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M723.20747,537.6053l-9.98877-14.42822L741.583,485.35823l-14.61182-31.311,1.064-37.24414,12.07446-2.23047.1499.07519a29.676,29.676,0,0,1,12.85938,14.97852c3.0122,7.53027,9.73632,35.79883,13.47656,51.97851a18.86066,18.86066,0,0,1-5.07153,17.68409Z",
          transform: "translate(-275.16231 -137.72918)",
          fill: "#3f3d56"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "728.87166",
          cy: "379.35965",
          r: "23.03371",
          transform: "translate(-244.82331 674.31946) rotate(-59.27099)",
          fill: "#ffb6b6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M743.59791,371.08929c-.24354,3.34464,2.89346,15.67759,2.89346,15.67759,2.299-3.86749-1.31247,8.98548-1.31247,8.98548,37.7304-26.3005,7.91786-52.72347-3.744-50.37464.24347-3.34466-9.26765-2.01966-9.26765-2.01966-3.624-5.64368-11.56663,1.8478-11.56663,1.8478l1.53267-2.57833c-6.16648,1.56849-12.26129,20.62658-12.26129,20.62658C711.96328,371.47606,743.84145,367.74464,743.59791,371.08929Z",
          transform: "translate(-275.16231 -137.72918)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          id: "ac880d58-80e8-403c-adcc-ec282a435c95-296",
          "data-name": "Path 11",
          d:
            "M614.09353,446.26221a2.762,2.762,0,0,1-1.491-.2,3.3,3.3,0,0,1-1.221-.942,4.70515,4.70515,0,0,1-.83-1.533,6.3112,6.3112,0,0,1-.3-1.973v-32.663a7.00018,7.00018,0,0,1,.3-2.044,6.23177,6.23177,0,0,1,.83-1.726,4.646,4.646,0,0,1,1.222-1.227,3.24287,3.24287,0,0,1,1.49-.549,2.79988,2.79988,0,0,1,1.486.263,3.47489,3.47489,0,0,1,1.212.988,4.91571,4.91571,0,0,1,.816,1.555,6.40878,6.40878,0,0,1,.3,1.969v32.416a7.10007,7.10007,0,0,1-.3,2.039,6.4801,6.4801,0,0,1-.816,1.746,4.89979,4.89979,0,0,1-1.212,1.27A3.36812,3.36812,0,0,1,614.09353,446.26221Z",
          transform: "translate(-275.16231 -137.72918)",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M923.647,762.27082H276.353a1.19069,1.19069,0,0,1,0-2.38137H923.647a1.19068,1.19068,0,0,1,0,2.38137Z",
          transform: "translate(-275.16231 -137.72918)",
          fill: "#cacaca"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }