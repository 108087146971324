<template>
  <div class="input-forms__container">
    <label class="input-forms__label-container">
      <div class="input-forms__label-content" v-if="label">{{label}}</div>
      <template v-if="isComponentVisible">
        <div class="row no-margin-y">
          <div class="col">
            <ComboBox 
              :label="comboBoxComponent.label" 
              :values="comboBoxComponent.values" 
              :value="antragData[comboBoxComponent.id]"
              :disabled="disabled"
              @input="onInputComboBox"/>
          </div>
          <div class="col-auto">
            <InputField 
              :label="inputFieldComponent.label" 
              :value="antragData[inputFieldComponent.id]"
              :disabled="disabled"
              @change="onChangeInputText">
            </InputField>
          </div>
        </div>
        <div class="row">
          <div class="col-auto">
            <InputToggleSwitch 
              :label="inputToggleSwitch.label" 
              :value="antragData[inputToggleSwitch.id]" 
              :disabled="disabled"
              @input="onInputToggleSwitch">
            </InputToggleSwitch>
          </div>
        </div>
      </template>
    </label>
  </div>
</template>

<script>
import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';

export default {
  props: {
    label: {
    },
    antragData: {
    },
    config: {
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    componentFields() {
      return this.config && this.config.componentFields
    },
    comboBoxComponent() {
      return Array.isArray(this.componentFields) && this.componentFields.find(cf => cf.type === 'COMBOBOX')
    },
    inputFieldComponent() {
      return Array.isArray(this.componentFields) && this.componentFields.find(cf => cf.type === 'TEXT')
    },
    inputToggleSwitch() {
      return Array.isArray(this.componentFields) && this.componentFields.find(cf => cf.type === 'SWITCHER')
    },
    isComponentVisible() {
      return this.comboBoxComponent && this.inputFieldComponent && this.inputToggleSwitch
    },
    funktionList() {
      const config = this.inputFieldComponent && this.inputFieldComponent.config;
      const list = config && config.funktionList;
      return list;
    }
  },
  methods: {
    onInputComboBox($event) {
      if (this.comboBoxComponent) {
        const keyValuePair = this.funktionList && this.funktionList.find(fl => fl.value == $event);
        this.$emit('change', 
        { 
          [this.inputFieldComponent.id]: keyValuePair?.label || '',
          [this.comboBoxComponent.id]: $event
        }
        )
      }
    },
    onChangeInputText($event) {
      if (this.inputFieldComponent) {
        this.$emit('change', { [this.inputFieldComponent.id]: $event})
      }
    },
    onInputToggleSwitch($event) {
      if (this.inputToggleSwitch) {
        this.$emit('change', { [this.inputToggleSwitch.id]: $event ? "1" : "0"})
      }
    },
  },
  components: {
    InputField,
    InputToggleSwitch,
    ComboBox
  }
}
</script>

<style scoped>

</style>