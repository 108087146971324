<template   >
    <div class="event-body" v-if="this.isLoaded">
        <BaseModal ref="modal" labelButtonCancel="Schließen" @onCancelButton="close" :showConfirmButton="false"  size="full">
            <template v-slot:modalTitle>
                Log
            </template>
            <BoxContainer>
                

                <InputTextArea  :rows="12" :hideScrollBar="true" :autoGrow ="true" v-model="dat"  />

            </BoxContainer>





        </BaseModal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import InputField from "@/components/core/forms/InputField.vue"
import BaseModal from '@/components/core/BaseModal.vue';
import V_P_TYPES from "@/store/versandProtokolle/types";
import BoxContainer from '@/components/core/BoxContainer.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue'
import LOG_TYPES from '@/store/log/types';
import { buildMessage } from '@/helpers/log-message-helper';
export default {
    data() {

        return {
           
            isLoaded: false,
            id:null,
            dat:null
           
        };
    },
    computed: {

        ...mapGetters({

         
            


        }),


    },
    mounted() {

    },
    
    methods: {
       
        

        async open(i) {
             this.id=i; 
             await this.loadData();
           
            this.$refs.modal?.open();
        },
        close() {

            this.$refs.modal.close();
        },

        
        async loadData() {
             
            await this.$store.dispatch(V_P_TYPES.ACTIONS.TRACE,this.id);
            this.dat = this.$store.getters[V_P_TYPES.GETTERS.TRACE];
            this.isLoaded = true;
        }
    },
    components: {
        BaseModal,
       
        InputField,
        InputTextArea,
        
        BoxContainer
    },

}
</script>

<style scoped>
