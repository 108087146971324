<template>
    <BaseModal 
        ref="modal"
        modalTitle="Datei einlesen"
        :showDefaultButtons="false"
        @close="close"
    >
        <div>
            <div v-if="bFilterVisible">
                <ComboBox label="Gesellschaft" v-model="gesellschaftId" @input="reloadComboboxes(); updateInterface();" :values="gesellschaftOptions"/>
                <ComboBox label="Interface" v-model="interfaceName" @input="updateInterface" :values="interfaceOptions"/>
                <InputToggleSwitch v-model="withInactiveInterfaces" @input="reloadComboboxes" label="Mit nicht aktiven Interfaces" inLineLabel/>
                <InputField label="Interface Id" :value="interfaceId" disabled/>
                <InputTextArea label="Beschreibung" :value="description" autoGrow disabled/>
            </div>
            <BaseFileSelect accept=".csv, .txt, .xml" isSecondary @files="onFilesChange" :disabled="loading">
                Durchsuchen ...
            </BaseFileSelect>
            {{file ? file.name : ""}}
            <br><br>
            <div style="display: flex">
                <BaseButton :disabled="!file || loading" @click="upload">
                    Datei verarbeiten
                </BaseButton>
                <progress :value="progress" max="100" style="flex-grow: 1">
                    {{progress}}%
                </progress>
            </div>
            {{status}}
        </div>
    </BaseModal>
</template>

<script>
import CORE_TYPES from '@/store/core/types'
import LOG_TYPES from '@/store/log/types';

import BaseModal from '@/components/core/BaseModal.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import InputField from '@/components/core/forms/InputField.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import BaseFileSelect from '@/components/fileUpload/BaseFileSelect.vue';
import BaseButton from '@/components/core/BaseButton.vue';

import { mapGetters } from 'vuex';
import { BROKER_LEVEL_ROLES } from '@/router/roles'
import { buildMessage } from "@/helpers/log-message-helper";
import { uploadFileChunked } from '@/helpers/upload-helper';
import axios from 'axios';
const config = {
    defaultSpinner: true,
};

export default {
    components: {
        BaseModal,
        ComboBox,
        InputToggleSwitch,
        InputField,
        InputTextArea,
        BaseFileSelect,
        BaseButton,
    },
    data() {
        return {
            withInactiveInterfaces: false,
            gesellschaftOptions: [],
            gesellschaftId: undefined,
            interfaceOptions: [],
            interfaceName: undefined,
            interfaceId: "",
            description: "",

            file: null,
            loading: false,
            status: "",
            id: null,
            interval: null,
            progress: 0,
            redirect: null,
        };
    },
    computed: {
        ...mapGetters({
            isFK: CORE_TYPES.GETTERS.IS_FK,
            isFPPlus: CORE_TYPES.GETTERS.IS_FPP_PLUS,
            isIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
            hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
        }),
        bFilterVisible() {
            return this.isIntern || this.hasRoles(BROKER_LEVEL_ROLES.RIGHT_DATENIMPORT);
        },
    },
    methods: {
        open(files = null) {
            const [file] = files || [];
            this.file = file || null;
            this.loading = false;
            this.status = "";
            this.id = null;
            this.progress = 0;
            this.$refs.modal.open();
            if (this.bFilterVisible) {
                this.reloadComboboxes();
            }
        },
        reloadComboboxes() {
            const gesellschaftId = this.gesellschaftId ? encodeURIComponent(this.gesellschaftId) : '';
            axios.get(`${process.env.VUE_APP_API}/bipro_import/upload_comboboxes?withInactiveInterfaces=${this.withInactiveInterfaces || false}&gesellschaft=${gesellschaftId}`, config)
            .then(response => {
                this.gesellschaftOptions = response.data.gesellschaften || [];
                this.interfaceOptions = response.data.interfaces || [];
            })
        },
        updateInterface() {
            const gesellschaftId = this.gesellschaftId ? encodeURIComponent(this.gesellschaftId) : '';
            axios.get(`${process.env.VUE_APP_API}/gdv_import_monitor/get_interface_description?gesellschaft=${gesellschaftId}&interface=${this.interfaceName || ''}`, config)
            .then(response => {
                this.interfaceId = response.data.interfaceId;
                this.description = response.data.description;
            })
        },
        onFilesChange(files) {
            if (files.length > 0)
                this.file = files[0];
        },
        upload() {
            this.loading = true;
            const filename = this.file.name;
            uploadFileChunked(this.file).then(id => {
                const params = {
                    tempId: id,
                    gesellschaftId: this.gesellschaftId ? encodeURIComponent(this.gesellschaftId) : '',
                    interfaceName: this.interfaceName,
                };
                axios.post(`${process.env.VUE_APP_API}/bipro_import/upload`, params, config)
                .then(response => {
                    this.status = "Verarbeitung gestartet...";
                    this.progress = 0;
                    this.id = response.data.id;
                    this.interval = setInterval(() => this.checkStatus(filename), 5000);
                }).catch(error => {
                    this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error.response.data, 'danger'));
                    this.loading = false;
                })
            })
        },
        checkStatus(filename) {
            axios.get(`${process.env.VUE_APP_API}/bipro_import/upload?id=${this.id}`, config)
            .then(response => {
                this.status = response.data.status;
                this.progress = response.data.progress;
                if (response.data.removeTimer) {
                    clearInterval(this.interval);
                    this.interval = null;
                    this.loading = false;
                    this.redirect = {
                        filename
                    };
                }
            });
        },
        close() {
            if (this.interval) {
                clearInterval(this.interval);
                this.interval = null;
            }
            if (this.redirect) {
                this.$router.push({path: this.$route.path, query: this.redirect});
            }
        },
    }
}
</script>