<template>
  <div>
    <div class="box__title">Bankverbindung</div>
    <div class="box__container">
      <InputField
        label="Kontoinhaber"
        :isComponentHalfSize="true"
        v-model="brokerData.konto.kontoinhaber"
        @change="addBrokerDataEdited('brokerData')"
        :disabled="!brokerData.isKontoEditable"
      />
    </div>
    <div class="box__container">
      <InputField
        label="IBAN"
        :isComponentHalfSize="true"
        v-model="brokerData.konto.iban"
        @input="calulateBicAndBank($event, '0')"
        @change="addBrokerDataEdited('brokerData')"
        :disabled="!brokerData.isKontoEditable"
      />
    </div>
    <div class="box__container">
      <InputField
        label="SWIFT-BIC"
        :isComponentHalfSize="true"
        v-model="brokerData.konto.bic"
        @change="addBrokerDataEdited('brokerData')"
        :disabled="!brokerData.isKontoEditable"
      />
    </div>
    <div class="box__container">
      <InputField
        label="Bank"
        :isComponentHalfSize="true"
        v-model="brokerData.konto.bank"
        @change="addBrokerDataEdited('brokerData')"
        :disabled="!brokerData.isKontoEditable"
      />
    </div>
    <div class="box__container">
      <InputField
        label="Kontonummer"
        :isComponentHalfSize="true"
        v-model="brokerData.konto.kontonummer"
        @change="addBrokerDataEdited('brokerData')"
        :disabled="!brokerData.isKontoEditable"
      />
    </div>
    <div class="box__container">
      <InputField
        label="Bankleitzahl"
        :isComponentHalfSize="true"
        v-model="brokerData.konto.bankleitzahl"
        @change="addBrokerDataEdited('brokerData')"
        :disabled="!brokerData.isKontoEditable"
      />
    </div>
    
    <div class="box__container">
      <div class="row">
        <div class="col-12 col-sm-6 form-control">
          <InputField
            class="align-end"
            label="Gläubiger-ID"
            v-model="brokerData.konto.glaeubigerdId"
            @change="addBrokerDataEdited('brokerData')"
          />
          <a
            href="https://www.glaeubiger-id.bundesbank.de"
            target="_newtab"
            class="align-end"
            style="flex-grow: 0"
          >
            <BaseButton>beantragen</BaseButton>
          </a>
        </div>
      </div>
    </div>
    <div class="box__container">
      <InputField
        label="Gut beraten ID"
        :isComponentHalfSize="true"
        v-model="brokerData.konto.gutBeratenId"
        @change="addBrokerDataEdited('brokerData')"
      />
    </div>
    <div class="box__container">
      <InputField
        label="Bemerkung"
        :isComponentHalfSize="true"
        v-model="brokerData.konto.bankBemerkung"
        @change="addBrokerDataEdited('brokerData')"
      />
    </div>
    <div class="box__title" v-if="brokerData.hatVersAccess">
      Bankverbindung für Versicherung (falls abweichend vom Standard)
    </div>
    <div class="box__container" v-if="brokerData.hatVersAccess">
      <InputField
        label="Kontoinhaber"
        v-if="brokerData.hatVersAccess"
        :isComponentHalfSize="true"
        v-model="brokerData.konto.versKontoinhaber"
        @change="addBrokerDataEdited('brokerData')"
        :disabled="!brokerData.isKontoEditable"
      />
    </div>
    <div class="box__container" v-if="brokerData.hatVersAccess">
      <InputField
        label="IBAN"
        v-if="brokerData.hatVersAccess"
        :isComponentHalfSize="true"
        v-model="brokerData.konto.versIban"
        @input="calulateBicAndBank($event, '1')"
        @change="addBrokerDataEdited('brokerData')"
        :disabled="!brokerData.isKontoEditable"
      />
    </div>
    <div class="box__container" v-if="brokerData.hatVersAccess">
      <InputField
        label="Kontonummer"
        v-if="brokerData.hatVersAccess"
        :isComponentHalfSize="true"
        v-model="brokerData.konto.versKontonummer"
        @change="addBrokerDataEdited('brokerData')"
        :disabled="!brokerData.isKontoEditable"
      />
    </div>
    <div class="box__container" v-if="brokerData.hatVersAccess">
      <InputField
        label="Bank"
        v-if="brokerData.hatVersAccess"
        :isComponentHalfSize="true"
        v-model="brokerData.konto.versBank"
        @change="addBrokerDataEdited('brokerData')"
        :disabled="!brokerData.isKontoEditable"
      />
    </div>
    <div class="box__container" v-if="brokerData.hatVersAccess">
      <InputField
        label="Bankleitzahl"
        v-if="brokerData.hatVersAccess"
        :isComponentHalfSize="true"
        v-model="brokerData.konto.versBankleitzahl"
        @change="addBrokerDataEdited('brokerData')"
        :disabled="!brokerData.isKontoEditable"
      />
    </div>
    <div class="box__container" v-if="brokerData.hatVersAccess">
      <InputField
        label="SWIFT-BIC"
        v-if="brokerData.hatVersAccess"
        :isComponentHalfSize="true"
        v-model="brokerData.konto.versBic"
        @change="addBrokerDataEdited('brokerData')"
        :disabled="!brokerData.isKontoEditable"
      />
    </div>
  </div>
</template>
<script>
import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputField from "@/components/core/forms/InputField.vue";
import BROKERDATA_TYPES from "@/store/brokerData/types";
import { mapGetters } from "vuex";
import InputCheckboxItem from "@/components/core/forms/checkbox/InputCheckBoxItem.vue";
import BaseButton from "@/components/core/BaseButton.vue";
import CORE_TYPES from "@/store/core/types";
import BaseModal from "@/components/core/BaseModal.vue";
import InputRadioBoxGroup from "@/components/core/forms/radiobox/InputRadioBoxGroup.vue";
import vermittlerDatenMixin from "@/views/intern/vermittler-daten-mixin";
import validator from "@/mixins/validator";
import { iban } from "@/mixins/validator/rules";
export default {
  mixins: [vermittlerDatenMixin, validator],

  data() {
    return {
      abrechnungServiceGebuehrValues: [
        { value: "1", label: "prozentual analog Abschlussfolge" },
        { value: "2", label: "prozentual gem. Vorgabe Struktur" },
        { value: "0", label: "fixer Einbehalt" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      brokerData: BROKERDATA_TYPES.GETTERS.GET_BROKER_DATA,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
    }),
  },
  watch: {},

  components: {
    ComboBox,
    InputCheckboxItem,
    BaseButton,
    BaseModal,
    InputRadioBoxGroup,
    InputField,
  },
  methods: {
    async calulateBicAndBank(iban, isVersBankVerb) {
      let flag = isVersBankVerb ? "1" : "0";
      const payload = {
        iban: iban,
        flag: isVersBankVerb,
      };
      const response = await this.$store.dispatch(
        BROKERDATA_TYPES.ACTIONS.FILL_BIC_AND_BANK,
        payload
      );
      if (response && response.data) {
        if (response?.data?.bank) {
          this.brokerData.konto.bank = response.data.bank;
        }
        if (response?.data?.bic) {
          this.brokerData.konto.bic = response.data.bic;
        }
        if (response?.data.versBank) {
          this.brokerData.konto.versBank = response.data.versBank;
        }
        if (response?.data?.versBic) {
          this.brokerData.konto.versBic = response.data.versBic;
        }
      }
    },
  },
  validators: {
    brokerData: {
      konto: {
        iban: [iban("Iban ist ungültig")],
        versIban: [iban("Iban ist ungültig")],
      },
    },
  },
  async beforeRouteLeave(to, from, next) {
    await this.saveChanges().then(() => next());
  },
};
</script>
<style scoped>
.align-end {
  flex: 1 1 auto;
  align-self: flex-end;
  padding: 0px 0px 16px 5px;
  color: var(--color-text);
}
.form-control {
  display: flex;
  align-content: stretch;
  align-items: center;
}
</style>