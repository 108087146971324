var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.editComment
        ? _c("EditTransactionComment", {
            ref: "editTransactionCommentModal",
            attrs: { transaction: _vm.editComment },
            on: {
              close: function($event) {
                _vm.editComment = null
              }
            }
          })
        : _vm._e(),
      _vm.changeCategory
        ? _c("ChangeCategory", {
            attrs: { transaction: _vm.changeCategory },
            on: {
              close: function($event) {
                _vm.changeCategory = null
              }
            }
          })
        : _vm._e(),
      _vm.splitTransaction && _vm.splitTransactionSplit
        ? _c("EditTransactionSplits", {
            attrs: {
              transaction: _vm.splitTransaction,
              originalSplit: _vm.splitTransactionSplit
            },
            on: {
              close: function($event) {
                _vm.splitTransaction = null
              }
            }
          })
        : _vm._e(),
      !_vm.transactions
        ? _c("div", [_c("span", [_vm._v("Keine Daten")])])
        : _c(
            "div",
            [
              _c("Table", {
                attrs: {
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowsPerPage: 10,
                  mobileConfig: {
                    title: "accountDisplayName",
                    headers: ["categoryDisplayName", "amount"]
                  }
                },
                on: {
                  "action-ADD_COMENT": function($event) {
                    return _vm.openAddComment($event.transaction)
                  },
                  "action-CHANGE_CATEGORY": function($event) {
                    return _vm.openChangeCategoryModal($event.transaction)
                  },
                  "action-SPLIT_TRANSACTION": function($event) {
                    return _vm.openSplitTransaction($event.transaction)
                  }
                }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }