import store from '@/store';
import CORE_TYPES from '@/store/core/types';
import LOG_TYPES from '@/store/log/types';
import axios from 'axios';
import HTTP_REQ_TYPES from '@/store/http-requests/types'
import { MESSAGE_CANCEL_REPEATED_REQUESTS, MESSAGE_REPLACE_SAME_URL, MESSAGE_SESSION_TIMEOUT } from '@/store/http-requests/actions';

/**
 * This interceptor responsible for ignore repeated requests 
 * Requests with the same url cannot run together at the same time
 * 
 */


/**
 * list of urls to ignore and allow it to run multiple times
 */
const IGNORE_URL = [
  '/mrsputexception',
  '/cms/cmsGetBrokerLayout',
  '/cms/cmsGetBrokerInfo',
  '/upload_chunk',
  '/dokumentenarchiv/uploadDocument',
  '/dokumentenarchiv/getDokTree',
  '/dokumentenarchiv/getDocuments',
  '/investmentadvice/positions/add',
  '/mrsbanktrasactionaccount',
  '/mrsbanktrasactiondepotextload',
  '/mrsmygoals',
  '/mailComposer',
  '/mrschatupload',
  '/mrsinvestmentprofilecheck',
  '/mrsdepotpdf',
  '/insurances/updateInsurance',
  '/insurances/getInsuranceById',
  '/insurances/getAnbindungValues/',
  '/process',
  '/mrscustomerdata',
  '/virtualsubdepot',
  '/fcconfig',
  '/mrsinsurance',
  '/mrsnews',
  '/mrsprogressdetailsdata',
  '/mrsdepotpositionsum',
  '/mrsfundschartdata',
  '/depotpositions/list',
  '/mrsretirementscenario',
  '/customerData/birthdays',
  '/kill_session',
  '/navigation_log',
  'setBeteiligte',
  'getInsuranceGesellschaftList',
  'ticketsnachrichten/closeTicket',
  '/calendarService/getCalendar',
  '/antragforms/list',
  '/postfachOverview/list/',
  '/insurances/policeNummerValidation',
  '/config_definition/configs_definition',
  '/brokerPermissions/additionalPermissions',
  '/shortcut/createOrUpdate',
  '/shortcut/remove',
  '/mrschataddfile',
  '/mrschat',
  '/mrsbankaccount',
]

/**
 * list of urls to be canceled and replaced with a newer request
 */
const REPLACEABLE_SAME_URL = [
  'postfach/list',
  'customerFilters/getCustomersFiltered',
  '/ticketsnachrichten/getTickets',
  '/ticketsnachrichten/ticketOptions',
  '/ticketsnachrichten/getTicket',
  '/ticketsnachrichten/getBewertung',
  'brokerSearch/getBrokers',
  '/consultation/wertpapierinfo?ask=get_wp',
  '/consultation/wertpapierinfo?ask=get_opt',
  '/TemplatesSetup/getTemplates',
  'mrsprogressdetailsdata',
  'depotpositions/search',
  '/fee/calcKundeHonorare',
  '/postfach/unread',
  '/signandvideoident/countVideoIdent',
  '/offene_unterschriften/getOpenSignsCountOnly',
  '/mscnews/get_msc_news_count',
]

axios.interceptors.request.use(
  config => {
    const httpRequestUrl = store.getters[HTTP_REQ_TYPES.GETTERS.HTTP_REQUEST_URL]

    if (REPLACEABLE_SAME_URL.some(url => config?.url?.includes(url))) {
      store.getters[HTTP_REQ_TYPES.GETTERS.HTTP_REQUEST_URL_LIST].map(request => {
        if (config?.url?.includes(request.url)) {
          store.dispatch(LOG_TYPES.ACTIONS.WARN, `Cancel same request: '${config?.url}'`);
          if (config?.defaultSpinner) {
            store.dispatch(CORE_TYPES.ACTIONS.GLOBAL_LOADING_STATE_STOP);
          }
          request.config?.abort?.(MESSAGE_REPLACE_SAME_URL);
          store.commit(HTTP_REQ_TYPES.MUTATIONS.REMOVE_HTTP_REQUEST_URL, request);
        }
      })
    } else if (!IGNORE_URL.some(ignore => config?.url?.includes(ignore)) && httpRequestUrl[config?.url]) {
      if (config?.defaultSpinner) {
        store.dispatch(CORE_TYPES.ACTIONS.GLOBAL_LOADING_STATE_STOP);
      }

      config.abort?.(MESSAGE_CANCEL_REPEATED_REQUESTS);
      return config;
    } else if (store.getters[HTTP_REQ_TYPES.GETTERS.IS_TOKEN_EXPIRED](config?.headers?.token)) {
      config.abort?.(MESSAGE_SESSION_TIMEOUT);
      return config;
    }
    const payload = {
      config: config,
      url: config?.url,
      requestId: config?.headers?.UID,
      cancelableRequest: config?.cancelableRequest,
    }

    store.commit(HTTP_REQ_TYPES.MUTATIONS.ADD_HTTP_REQUEST_URL, payload)

    return config;
  },
  error => {
    return Promise.reject(error)
  }
);

axios.interceptors.response.use(
  response => {
    store.commit(HTTP_REQ_TYPES.MUTATIONS.REMOVE_HTTP_REQUEST_URL, { requestId: response?.config?.headers?.UID, url: response.config?.url })
    return response;
  },
  error => {
    if (error?.config?.url) {
      store.commit(HTTP_REQ_TYPES.MUTATIONS.REMOVE_HTTP_REQUEST_URL, { requestId: error?.config?.headers?.UID, url: error.config?.url })
    }
    return Promise.reject(error);
  }
);
