var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.balanceSum
    ? _c("div", [
        !_vm.isMobileNativeContext
          ? _c("div", [
              _c(
                "div",
                {
                  staticClass: "row align-items-center justify-content-center"
                },
                [
                  !_vm.isSelectableView
                    ? _c(
                        "div",
                        { staticClass: "col-4 hide-on-small-screen" },
                        [
                          _vm.balanceSum.isLoaded &&
                          _vm.balanceSum.assetChartData
                            ? _c("PieChart", {
                                attrs: {
                                  chartData: _vm.getEChartData(
                                    _vm.balanceSum.assetChartData
                                  ),
                                  format: "percent",
                                  height: "250px"
                                },
                                on: {
                                  clickchart: function($event) {
                                    return _vm.handleChartClick(
                                      $event,
                                      "profits"
                                    )
                                  }
                                }
                              })
                            : !_vm.balanceSum.isLoaded
                            ? _c("GhostLoading", {
                                attrs: {
                                  type: "block",
                                  config: { block: { height: 250 } }
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-4" },
                    [
                      _vm.balanceSum.isLoaded
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "box__container-wrap justify-items-center align-items-center"
                            },
                            [
                              _c("span", { staticClass: "text-truncate" }, [
                                _vm._v("Gesamtvermögen:")
                              ]),
                              !_vm.isSelectableView
                                ? [
                                    _vm.balanceSum.isLoaded ||
                                    _vm.balanceSum.totalAssets !== null
                                      ? _c(
                                          "span",
                                          [
                                            _c("CurrencyLabel", {
                                              attrs: {
                                                value:
                                                  _vm.balanceSum.totalAssets
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                : [
                                    _c("CurrencyLabel", {
                                      attrs: { value: _vm.allBalanceSum }
                                    })
                                  ]
                            ],
                            2
                          )
                        : !_vm.balanceSum.isLoaded
                        ? _c(
                            "GhostLoading",
                            [
                              _c("Block", { attrs: { type: "paragraph" } }),
                              _c("Block", { attrs: { type: "paragraph" } })
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  !_vm.isSelectableView
                    ? _c(
                        "div",
                        { staticClass: "col-4 hide-on-small-screen" },
                        [
                          _vm.balanceSum.isLoaded &&
                          _vm.balanceSum.liabilityChartData
                            ? _c("PieChart", {
                                attrs: {
                                  chartData: _vm.getEChartData(
                                    _vm.balanceSum.liabilityChartData
                                  ),
                                  format: "percent",
                                  height: "250px"
                                },
                                on: {
                                  clickchart: function($event) {
                                    return _vm.handleChartClick($event, "loss")
                                  }
                                }
                              })
                            : !_vm.balanceSum.isLoaded
                            ? _c("GhostLoading", {
                                attrs: {
                                  type: "block",
                                  config: { block: { height: 250 } }
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ]
              ),
              _c("div", { staticClass: "row mb-0" }, [
                !_vm.isSelectableView
                  ? _c(
                      "div",
                      { staticClass: "col-12 hide-on-large-screen" },
                      [
                        _vm.balanceSum.isLoaded && _vm.balanceSum.assetChartData
                          ? _c("PieChart", {
                              attrs: {
                                chartData: _vm.getEChartData(
                                  _vm.balanceSum.assetChartData
                                ),
                                format: "percent",
                                height: "250px"
                              },
                              on: {
                                clickchart: function($event) {
                                  return _vm.handleChartClick($event, "profits")
                                }
                              }
                            })
                          : !_vm.balanceSum.isLoaded
                          ? _c("GhostLoading", {
                              attrs: {
                                type: "block",
                                config: { block: { height: 250 } }
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "col-12 col-md-6" },
                  [
                    _vm.balanceSum.isLoaded
                      ? [
                          _c("div", { staticClass: "row" }, [
                            _vm.balanceSum.isLoaded &&
                            _vm.balanceSum.portfolios.length &&
                            _vm.getSumWertpapiere(_vm.balanceSum.portfolios)
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-12 d-flex justify-content-between"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        class: {
                                          clickable: _vm.isClickable(
                                            "Wertpapiere"
                                          )
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.navigateToPage(
                                              "Wertpapiere"
                                            )
                                          }
                                        }
                                      },
                                      [
                                        !_vm.isSelectableView
                                          ? _c("PhCircle", {
                                              attrs: {
                                                size: 16,
                                                weight: "fill",
                                                color: _vm.getColor(
                                                  _vm.getLeftIndex(
                                                    "Wertpapiere"
                                                  )
                                                )
                                              }
                                            })
                                          : _vm._e(),
                                        _vm.isSelectableView
                                          ? _c("InputCheckBoxItem", {
                                              staticClass: "col-left",
                                              on: {
                                                input: function($event) {
                                                  return _vm.onSelectedBalance(
                                                    $event,
                                                    0,
                                                    1
                                                  )
                                                }
                                              },
                                              model: {
                                                value:
                                                  _vm.selectedBalanceValue[0],
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.selectedBalanceValue,
                                                    0,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "selectedBalanceValue[0]"
                                              }
                                            })
                                          : _vm._e(),
                                        _c("span", [_vm._v(" Wertpapiere")]),
                                        _vm.balanceSum.isLoaded ||
                                        _vm.balanceSum.portfolios.length
                                          ? _c("span", [
                                              _vm._v(
                                                " (" +
                                                  _vm._s(
                                                    _vm.getPercentage(
                                                      "Wertpapiere",
                                                      _vm.balanceSum,
                                                      1
                                                    )
                                                  ) +
                                                  "%)"
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(": ")
                                      ],
                                      1
                                    ),
                                    _vm.balanceSum.isLoaded ||
                                    _vm.balanceSum.portfolios.length
                                      ? _c(
                                          "div",
                                          { staticClass: "text-right" },
                                          [
                                            _vm.balanceSum.isLoaded &&
                                            !_vm.balanceSum.portfoliosError &&
                                            _vm.balanceSum.portfolios &&
                                            _vm.balanceSum.portfolios.length
                                              ? _c(
                                                  "span",
                                                  [
                                                    _c("CurrencyLabel", {
                                                      attrs: {
                                                        value: _vm.getSumWertpapiere(
                                                          _vm.balanceSum
                                                            .portfolios
                                                        )
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _c("CurrencyLabel", {
                                                  attrs: { value: 0 }
                                                }),
                                            _vm.balanceSum.portfolios.isError
                                              ? _c("app-data-loading-failed")
                                              : _vm._e(),
                                            _c("span", [
                                              _c("div", {
                                                staticClass: "ghost sm",
                                                attrs: {
                                                  isLoading:
                                                    !_vm.balanceSum.isLoaded &&
                                                    !_vm.balanceSum.portfolios
                                                      .length
                                                }
                                              })
                                            ])
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                )
                              : _vm._e()
                          ]),
                          _vm.showVertpapiereVorsorgeSeparated &&
                          _vm.balanceSum.isLoaded &&
                          _vm.balanceSum.portfolios.length &&
                          _vm.getSumWertpapiereVorsorge(
                            _vm.balanceSum.portfolios
                          )
                            ? _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-12 d-flex justify-content-between"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        class: {
                                          clickable: _vm.isClickable(
                                            "Wertpapiere Vorsorge"
                                          )
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.navigateToPage(
                                              "Wertpapiere Vorsorge"
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("PhCircle", {
                                          attrs: {
                                            size: 16,
                                            weight: "fill",
                                            color: _vm.getColor(
                                              _vm.getLeftIndex(
                                                "Wertpapiere Vorsorge"
                                              )
                                            )
                                          }
                                        }),
                                        _c("span", [
                                          _vm._v(" Wertpapiere Vorsorge")
                                        ]),
                                        _vm.balanceSum.isLoaded ||
                                        _vm.balanceSum.portfolios.length
                                          ? _c("span", [
                                              _vm._v(
                                                " (" +
                                                  _vm._s(
                                                    _vm.getPercentage(
                                                      "Wertpapiere Vorsorge",
                                                      _vm.balanceSum,
                                                      1
                                                    )
                                                  ) +
                                                  "%)"
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(": ")
                                      ],
                                      1
                                    ),
                                    _vm.balanceSum.isLoaded ||
                                    _vm.balanceSum.portfolios.length
                                      ? _c(
                                          "div",
                                          { staticClass: "text-right" },
                                          [
                                            !_vm.balanceSum.portfolios
                                              .isError &&
                                            _vm.balanceSum.isLoaded
                                              ? _c(
                                                  "span",
                                                  [
                                                    _c("CurrencyLabel", {
                                                      attrs: {
                                                        value: _vm.getSumWertpapiereVorsorge(
                                                          _vm.balanceSum
                                                            .portfolios
                                                        )
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              : _c("CurrencyLabel", {
                                                  attrs: { value: 0 }
                                                }),
                                            _vm.balanceSum.portfolios.isError
                                              ? _c("app-data-loading-failed")
                                              : _vm._e(),
                                            _c("span", [
                                              _c("div", {
                                                staticClass: "ghost sm",
                                                attrs: {
                                                  isLoading:
                                                    !_vm.balanceSum.isLoaded &&
                                                    !_vm.balanceSum.portfolios
                                                      .length
                                                }
                                              })
                                            ])
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ])
                            : _vm._e(),
                          _vm._l(_vm.balanceSum.profits, function(l, li) {
                            return [
                              _vm.showCategory(l)
                                ? [
                                    _c("div", { key: li, staticClass: "row" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-12 d-flex justify-content-between"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              class: {
                                                clickable: _vm.isClickable(
                                                  l.header
                                                )
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.navigateToPage(
                                                    l.header,
                                                    "profits"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              !_vm.isSelectableView
                                                ? _c("PhCircle", {
                                                    attrs: {
                                                      size: 16,
                                                      weight: "fill",
                                                      color: _vm.getColor(
                                                        _vm.getLeftIndex(
                                                          l.header
                                                        )
                                                      )
                                                    }
                                                  })
                                                : _vm._e(),
                                              _vm.isSelectableView
                                                ? _c("InputCheckBoxItem", {
                                                    staticClass: "col-left",
                                                    on: {
                                                      input: function($event) {
                                                        _vm.onSelectedBalance(
                                                          $event,
                                                          li +
                                                            (_vm.showVertpapiereVorsorgeSeparated
                                                              ? 2
                                                              : 1),
                                                          1
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm
                                                          .selectedBalanceValue[
                                                          li +
                                                            (_vm.showVertpapiereVorsorgeSeparated
                                                              ? 2
                                                              : 1)
                                                        ],
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectedBalanceValue,
                                                          li +
                                                            (_vm.showVertpapiereVorsorgeSeparated
                                                              ? 2
                                                              : 1),
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectedBalanceValue[li+(showVertpapiereVorsorgeSeparated ? 2 : 1)]"
                                                    }
                                                  })
                                                : _vm._e(),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "text-truncate"
                                                },
                                                [
                                                  _vm._v(
                                                    " " + _vm._s(l.header) + " "
                                                  ),
                                                  _vm.balanceSum.isLoaded ||
                                                  l.isLoaded
                                                    ? _c("span", [
                                                        _vm._v(
                                                          " (" +
                                                            _vm._s(
                                                              _vm.getPercentage(
                                                                l.header,
                                                                _vm.balanceSum,
                                                                1
                                                              )
                                                            ) +
                                                            "%)"
                                                        )
                                                      ])
                                                    : _vm._e(),
                                                  _vm._v(": "),
                                                  _c("span", [
                                                    _c("div", {
                                                      staticClass: "ghost sm",
                                                      attrs: {
                                                        isLoading:
                                                          !_vm.balanceSum
                                                            .isLoaded &&
                                                          !l.isLoaded
                                                      }
                                                    })
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _vm.balanceSum.isLoaded || l.isLoaded
                                            ? _c(
                                                "div",
                                                { staticClass: "text-right" },
                                                [
                                                  !l.isError &&
                                                  (_vm.balanceSum.isLoaded ||
                                                    l.isLoaded)
                                                    ? _c(
                                                        "span",
                                                        [
                                                          _c("CurrencyLabel", {
                                                            attrs: {
                                                              value: _vm.getSum(
                                                                l
                                                              )
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    : _c("CurrencyLabel", {
                                                        attrs: { value: 0 }
                                                      }),
                                                  _c("span", [
                                                    _c("div", {
                                                      staticClass: "ghost sm",
                                                      attrs: {
                                                        isLoading:
                                                          !_vm.balanceSum
                                                            .isLoaded &&
                                                          !l.isLoaded
                                                      }
                                                    })
                                                  ])
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      )
                                    ])
                                  ]
                                : _vm._e()
                            ]
                          })
                        ]
                      : !_vm.balanceSum.isLoaded
                      ? _c(
                          "GhostLoading",
                          [
                            _c("Block", {
                              attrs: { type: "title", width: "100%" }
                            }),
                            _c("Block", {
                              attrs: { type: "title", width: "100%" }
                            }),
                            _c("Block", {
                              attrs: { type: "title", width: "100%" }
                            }),
                            _c("Block", {
                              attrs: { type: "title", width: "100%" }
                            }),
                            _c("Block", {
                              attrs: { type: "title", width: "100%" }
                            }),
                            _c("Block", {
                              attrs: { type: "title", width: "100%" }
                            }),
                            _c("Block", {
                              attrs: { type: "title", width: "100%" }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "row mt-3 sum-row hide-on-large-screen" },
                      [
                        _c("hr", { staticClass: "col-12" }),
                        _vm._m(0),
                        _c(
                          "div",
                          { staticClass: "col-5 text-right " },
                          [
                            !_vm.balanceSum.portfolios.isError &&
                            (_vm.balanceSum.isLoaded || _vm.balanceSum.wealth)
                              ? _c(
                                  "span",
                                  [
                                    _c("CurrencyLabel", {
                                      attrs: { value: _vm.balanceSum.wealth }
                                    })
                                  ],
                                  1
                                )
                              : !_vm.balanceSum.isLoaded
                              ? _c(
                                  "GhostLoading",
                                  [
                                    _c("Block", {
                                      attrs: { type: "title", width: "100%" }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.balanceSum.portfolios.isError
                              ? _c("app-data-loading-failed")
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  2
                ),
                !_vm.isSelectableView
                  ? _c(
                      "div",
                      { staticClass: "col-12 hide-on-large-screen" },
                      [
                        _vm.balanceSum.isLoaded &&
                        _vm.balanceSum.liabilityChartData
                          ? _c("PieChart", {
                              attrs: {
                                chartData: _vm.getEChartData(
                                  _vm.balanceSum.liabilityChartData
                                ),
                                format: "percent",
                                height: "250px"
                              },
                              on: {
                                clickchart: function($event) {
                                  return _vm.handleChartClick($event, "loss")
                                }
                              }
                            })
                          : !_vm.balanceSum.isLoaded
                          ? _c("GhostLoading", {
                              attrs: {
                                type: "block",
                                config: { block: { height: 250 } }
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "col-12 col-md-6" },
                  [
                    _vm.balanceSum.isLoaded
                      ? [
                          _vm._l(_vm.balanceSum.loss, function(r, ri) {
                            return [
                              _c("div", { key: "ri" + ri }, [
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-12 d-flex justify-content-between"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "m-0",
                                          class: {
                                            clickable: _vm.isClickable(r.header)
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.navigateToPage(
                                                r.header,
                                                "loss"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "text-truncate" },
                                            [
                                              !_vm.isSelectableView
                                                ? _c("PhCircle", {
                                                    attrs: {
                                                      size: 16,
                                                      weight: "fill",
                                                      color: _vm.getColor(ri)
                                                    }
                                                  })
                                                : _vm._e(),
                                              _vm.isSelectableView
                                                ? _c("InputCheckBoxItem", {
                                                    staticClass: "col-left",
                                                    on: {
                                                      input: function($event) {
                                                        return _vm.onSelectedBalance(
                                                          $event,
                                                          ri,
                                                          -1
                                                        )
                                                      }
                                                    },
                                                    model: {
                                                      value:
                                                        _vm
                                                          .selectedBalanceLossValue[
                                                          ri
                                                        ],
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.selectedBalanceLossValue,
                                                          ri,
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectedBalanceLossValue[ri]"
                                                    }
                                                  })
                                                : _vm._e(),
                                              _vm._v(
                                                " " + _vm._s(r.header) + " "
                                              ),
                                              _vm.balanceSum.isLoaded ||
                                              r.isLoaded
                                                ? _c("span", [
                                                    _vm._v(
                                                      " (" +
                                                        _vm._s(
                                                          _vm.getPercentage(
                                                            r.header,
                                                            _vm.balanceSum,
                                                            -1
                                                          )
                                                        ) +
                                                        "%) "
                                                    )
                                                  ])
                                                : _vm._e(),
                                              _vm._v(": "),
                                              r.empty
                                                ? [
                                                    _vm._v(
                                                      _vm._s(r.header) + ":"
                                                    )
                                                  ]
                                                : _vm._e()
                                            ],
                                            2
                                          )
                                        ]
                                      ),
                                      _vm.balanceSum.isLoaded || r.isLoaded
                                        ? _c(
                                            "div",
                                            { staticClass: " text-right" },
                                            [
                                              !r.isError &&
                                              (_vm.balanceSum.isLoaded ||
                                                r.isLoaded)
                                                ? _c(
                                                    "span",
                                                    [
                                                      _c("CurrencyLabel", {
                                                        attrs: {
                                                          value: _vm.getSum(r)
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _c("CurrencyLabel", {
                                                    attrs: { value: 0 }
                                                  }),
                                              _c("span", [
                                                _c("div", {
                                                  staticClass: "ghost sm",
                                                  attrs: {
                                                    isLoading:
                                                      !_vm.balanceSum
                                                        .isLoaded && !r.isLoaded
                                                  }
                                                })
                                              ])
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                ])
                              ])
                            ]
                          })
                        ]
                      : !_vm.balanceSum.isLoaded
                      ? _c(
                          "GhostLoading",
                          [
                            _c("Block", {
                              attrs: { type: "title", width: "100%" }
                            }),
                            _c("Block", {
                              attrs: { type: "title", width: "100%" }
                            }),
                            _c("Block", {
                              attrs: { type: "title", width: "100%" }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass:
                          "row mt-3 mb-0 sum-row hide-on-large-screen"
                      },
                      [
                        _c("hr", { staticClass: "col-12" }),
                        _vm._m(1),
                        _c(
                          "div",
                          { staticClass: "col-5 text-right " },
                          [
                            _vm.balanceSum.isLoaded ||
                            _vm.balanceSum.liability !== null
                              ? _c(
                                  "span",
                                  [
                                    _c("CurrencyLabel", {
                                      attrs: { value: _vm.balanceSum.liability }
                                    })
                                  ],
                                  1
                                )
                              : !_vm.balanceSum.isLoaded
                              ? _c(
                                  "GhostLoading",
                                  [
                                    _c("Block", {
                                      attrs: { type: "title", width: "100%" }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.balanceSum.portfolios.isError
                              ? _c("app-data-loading-failed")
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  2
                )
              ]),
              _c("div", { staticClass: "row mt-3 hide-on-small-screen" }, [
                _c("hr", { staticClass: "col-12" }),
                _c("div", { staticClass: "col-6" }, [
                  _c("div", { staticClass: "row sum-row" }, [
                    _vm._m(2),
                    _c(
                      "div",
                      { staticClass: "col-5 text-right " },
                      [
                        !_vm.balanceSum.portfolios.isError &&
                        (_vm.balanceSum.isLoaded || _vm.balanceSum.wealth)
                          ? _c(
                              "span",
                              [
                                _c("CurrencyLabel", {
                                  attrs: { value: _vm.balanceSum.wealth }
                                })
                              ],
                              1
                            )
                          : !_vm.balanceSum.isLoaded
                          ? _c(
                              "GhostLoading",
                              [
                                _c("Block", {
                                  attrs: { type: "title", width: "100%" }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.balanceSum.portfolios.isError
                          ? _c("app-data-loading-failed")
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ]),
                _c("div", { staticClass: "col-6" }, [
                  _c("div", { staticClass: "row sum-row" }, [
                    _vm._m(3),
                    _c(
                      "div",
                      { staticClass: "col-5 text-right " },
                      [
                        _vm.balanceSum.isLoaded ||
                        _vm.balanceSum.liability !== null
                          ? _c(
                              "span",
                              [
                                _c("CurrencyLabel", {
                                  attrs: { value: _vm.balanceSum.liability }
                                })
                              ],
                              1
                            )
                          : !_vm.balanceSum.isLoaded
                          ? _c(
                              "GhostLoading",
                              [
                                _c("Block", {
                                  attrs: { type: "title", width: "100%" }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.balanceSum.portfolios.isError
                          ? _c("app-data-loading-failed")
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ])
              ])
            ])
          : _c("div", [
              _c(
                "div",
                { staticClass: "box__container" },
                [
                  _c("Table", {
                    ref: "vermoegenTable",
                    attrs: {
                      tableId: "dac58861-7e21-4f32-a469-f55f487e86a4",
                      rowId: "id",
                      cardView: "",
                      hidePagination: "",
                      headers: _vm.vermoegenSumaryMobileTable.headers,
                      rows: _vm.vermoegenSumaryMobileTable.rows
                    }
                  })
                ],
                1
              ),
              _vm.profitsMobileTable && _vm.profitsMobileTable.rows.length > 0
                ? _c(
                    "div",
                    { staticClass: "box__container" },
                    [
                      _c("FlexibleList", {
                        attrs: {
                          rows: _vm.profitsMobileTable.rows,
                          linkInFirstRow: ""
                        },
                        on: {
                          onRowClick: function($event) {
                            return _vm.navigateToPage($event.title)
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function(row) {
                                return [_vm._v(" " + _vm._s(row.title) + " ")]
                              }
                            },
                            {
                              key: "value",
                              fn: function(row) {
                                return [
                                  _c("CurrencyLabel", {
                                    attrs: { value: row.value }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2178590999
                        )
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.lossesMobileTable && _vm.lossesMobileTable.rows > 0
                ? _c(
                    "div",
                    { staticClass: "box__container" },
                    [
                      _c("FlexibleList", {
                        attrs: {
                          rows: _vm.lossesMobileTable.rows,
                          linkInFirstRow: ""
                        },
                        on: {
                          onRowClick: function($event) {
                            return _vm.navigateToPage($event.title)
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function(row) {
                                return [_vm._v(" " + _vm._s(row.title) + " ")]
                              }
                            },
                            {
                              key: "value",
                              fn: function(row) {
                                return [
                                  _c("CurrencyLabel", {
                                    attrs: { value: row.value }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2178590999
                        )
                      })
                    ],
                    1
                  )
                : _vm._e()
            ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-7" }, [
      _c("span", [_vm._v("Vermögen:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-7" }, [
      _c("span", [_vm._v("Verbindlichkeiten:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-7" }, [
      _c("span", [_vm._v("Vermögen:")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-7" }, [
      _c("span", [_vm._v("Verbindlichkeiten:")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }