import LOG_TYPES from '@/store/log/types.js';
import WEBRTC_TYPES from '@/store/webrtc/types.js';

import { getSavedCameraId, getSavedMicrophoneId } from "@/helpers/webrtc-settings-helper";

const audioConstraints = {
  echoCancellation: true,
  autoGainControl: false,
  noiseSuppression: false,
  googEchoCancellation: true,
  googAutoGainControl: false,
  googNoiseSuppression: false,
};

const videoConstraints = {
  facingMode: 'user',
  width: {
    min: 640,
    ideal: 1280,
  },
  height: {
    min: 480,
    ideal: 720,
  },
};

Object.freeze(audioConstraints);
Object.freeze(videoConstraints);

async function isDeviceAvailable(deviceId) {
  const exact = deviceId;

  try {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const isAvailable = devices.some((device) => device.deviceId === exact);

    if (isAvailable) {
      return deviceId;
    }

    return undefined;
  } catch (error) {
    return undefined;
  }
}

async function getAudioVideoConstraint(addDeviceConstraint, withAudio, withVideo) {
  const constraint = {
    audio: withAudio ? { ...audioConstraints } : false,
    video: withVideo ? { ...videoConstraints } : false,
  }

  if (addDeviceConstraint) {
    if (withVideo) {
      constraint.video.deviceId = await isDeviceAvailable(getSavedCameraId());
    }
    if (withAudio) {
      constraint.audio.deviceId = await isDeviceAvailable(getSavedMicrophoneId());
    }
  }

  return constraint;
}

async function getMediaStream(dispatch, withAudio, withVideo) {
  try {
    const constraint = await getAudioVideoConstraint(true, withAudio, withVideo);
    dispatch(LOG_TYPES.ACTIONS.INFO, { message: `getMediaStream: constraint to request getUserMedia:`, constraint: JSON.stringify(constraint)});
    return await navigator.mediaDevices.getUserMedia(constraint);
  } catch (error) {
    const constraint = await getAudioVideoConstraint(false, withAudio, withVideo);
    dispatch(LOG_TYPES.ACTIONS.INFO, { message: `catch getMediaStream: constraint to request getUserMedia:`, constraint: JSON.stringify(constraint), error});
    return await navigator.mediaDevices.getUserMedia(constraint);
  }
}

export async function getAudioMediaStream(dispatch) {
  let stream = null;
  let error = null;
  try {
    stream = await getMediaStream(dispatch, true, false);
  } catch (err) {
    error = err;
  }

  dispatch(LOG_TYPES.ACTIONS.INFO, { message: `getUserMedia got stream, error: :`, stream, error });

  return [ stream, error ];
}

export async function getVideoMediaStream(dispatch) {
  let stream = null;
  let error = null;
  try {
    stream = await getMediaStream(dispatch, false, true);
  } catch (err) {
    error = err;
  }

  dispatch(LOG_TYPES.ACTIONS.INFO, { message: `getUserMedia got stream, error: :`, stream, error });

  return [ stream, error ];
}

async function getDummyCanvas() {

  /**
     * @type HTMLCanvasElement
     */
  const canvas = document.createElement('canvas');
  canvas.width = 400;
  canvas.height = 300;
  const ctx = canvas.getContext('2d');

  let startAngle = 0;
  const deltaAngle = 0.05;
  let direction = true;
  let lastFrame = 0;

  // it doesn't really matters what kind of animation
  const animateCanvas = () => {
    const now = performance.now();
    const deltaTime = now - lastFrame;
  
    if (deltaTime >= 100) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      ctx.beginPath();
      ctx.arc(150, 150, 75, startAngle, startAngle + deltaAngle, direction);
      ctx.fillStyle = 'black';
      ctx.fill();
  
      if (direction) {
        startAngle += deltaAngle;
      } else {
        startAngle -= deltaAngle;
      }
  
      if (startAngle > 2 * Math.PI || startAngle < 0) {
        direction = !direction;
      }
  
      lastFrame = now;
    }
  
    requestAnimationFrame(animateCanvas);
  }

  animateCanvas();

  return canvas.captureStream();

}

export async function createMediaStreams({ dispatch, commit }) {
  let [ stream, error ] = await getVideoMediaStream(dispatch);
  const [ audioStream, audioError ] = await getAudioMediaStream(dispatch);

  const createReturn = (stream, audioStream) => ({
    stream,
    audioStream,
  });

  if (audioError) {
    if (stream?.getTracks) {
      stream.getTracks().forEach(track => track.stop());
    }
    dispatch(LOG_TYPES.ACTIONS.ERROR, {
      message: "It wasn't possible to get an audio/video stream",
      userAgent: navigator.userAgent,
      event: audioError,
    });

    return createReturn(null, null);
  }

  if (error) {
    stream = await getDummyCanvas();
    commit(WEBRTC_TYPES.MUTATIONS.IS_DUMMY_STREAM, true);
  }

  return createReturn(stream, audioStream);

}
