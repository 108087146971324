import RUNDMAIL_STATUS_TYPES from './types';
import { getInitialState } from './index'

export default {
  [RUNDMAIL_STATUS_TYPES.MUTATIONS.RETRIEVE_DATA_SUCCESS](state, payload) {
    state.rundmailStatusData = payload;
  }, 
  [RUNDMAIL_STATUS_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  }, 

}