var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "form-input-dok-node-permission-item__container checkbox-input__container"
    },
    _vm._l(_vm.values, function(val, index) {
      return _c(
        "div",
        { key: val.path + "-" + index },
        [
          _c("InputCheckBoxItem", {
            attrs: {
              id: val.path,
              label: val.label,
              value: _vm.value[val.path],
              disabled: _vm.disabled || val.disabled
            },
            on: {
              input: function($event) {
                return _vm.onInput($event, val)
              }
            }
          }),
          _vm.isValidationConfigured()
            ? [
                !_vm.suppressValidationMessage && _vm.validation
                  ? _c(
                      "div",
                      { key: _vm.validation.updated },
                      _vm._l(
                        _vm.validation.getErrors(
                          _vm.getCheckboxErrorPath(val.path),
                          _vm.validateUntouched
                        ),
                        function(error) {
                          return _c(
                            "div",
                            {
                              key: error,
                              staticClass: "input-forms__error-message"
                            },
                            [_vm._v(" " + _vm._s(error) + " ")]
                          )
                        }
                      ),
                      0
                    )
                  : _vm._e()
              ]
            : _vm._e(),
          _c("FormInputDokNodePermissionItem", {
            ref: "children_" + val.path,
            refInFor: true,
            staticClass: "form-input-dok-node-permission-item--children",
            attrs: {
              values: val.children,
              value: _vm.value,
              disabled: _vm.disabled || _vm.isChildrenDisabled(val)
            },
            on: {
              input: function($event) {
                return _vm.onChildrenInput($event)
              }
            }
          })
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }