import CORE_TYPES from './types';
import BRIDGE_TYPES from '@/store/bridge/types'
import MENU_TYPES from '@/store/menu/types';

import { getViewRoles, VIEW_ROLES, ROLES, BROKER_LEVEL_ROLES } from '@/router/roles';
import { isPathAllowed, } from '@/router/guards';

const RESTFUL_ENDPOINT = '/restful';

function includesRole(state, roles) {
  const userRoles = state.loginData.roles
  if (!userRoles) {
    return false
  }
  if (typeof roles === 'string') {
    return userRoles.includes(roles)
  }
  if (Array.isArray(roles)) {
    return roles.some(r => Array.isArray(r) ? r.every(roleAnd => userRoles.includes(roleAnd)) : userRoles.includes(r))
  }
  return false
}

const DEFAULT_BROKER_ID = {
  [ROLES.FA]: '10000',
  [ROLES.FK]: '89000'
}

export default {
  [CORE_TYPES.GETTERS.IS_INTERNET_ACTIVE](state) {
    return state.internetActive === true;
  },
  [CORE_TYPES.GETTERS.HAS_LOGIN_IN_PROGRESS](_, getters) {
    return getters[CORE_TYPES.GETTERS.IS_LOGGING_IN] 
      || getters[CORE_TYPES.GETTERS.IS_LOADING_TOKEN] 
      || getters[CORE_TYPES.GETTERS.IS_MANAGING_LOGIN_RESPONSE];
  },
  [CORE_TYPES.GETTERS.IS_LOGGING_IN](state) {
    return state.loggingIn === true;
  },
  [CORE_TYPES.GETTERS.IS_CONFIRMING_2FA](state) {
    return state.confirm2FA === true;
  },
  [CORE_TYPES.GETTERS.IS_LOGGING_OUT](state) {
    return state.loggingOut === true;
  },
  [CORE_TYPES.GETTERS.READ_ONLY](state) {
    return state.loginData?.readOnly || state.loginData?.rights?.readOnly;
  },
  [CORE_TYPES.GETTERS.PENDING_ACTIONS](state) {
    return state.baseGlobalState.pendingActions;
  },
  [CORE_TYPES.GETTERS.IS_PENDING_ACTION_ACTIVE](state) {
    const isPendingActionActive = key => {
      const pendingAction = state?.baseGlobalState?.pendingActions?.[key] || {};
      return pendingAction?.pending === true && pendingAction?.skipped !== true && pendingAction?.done !== true;
    };
    return (...keys) => keys?.some(isPendingActionActive);
  },
  [CORE_TYPES.GETTERS.GET_USER_ROLES](state) {
    return state.loginData.roles
  },
  [CORE_TYPES.GETTERS.IS_LOGGED_IN](state) {
    return !!(state.loginData.token)
  },
  [CORE_TYPES.GETTERS.GET_TOKEN](state) {
    return state.loginData.token
  },
  [CORE_TYPES.GETTERS.IS_LOADING_TOKEN](state) {
    return state.loadingToken === true;
  },
  [CORE_TYPES.GETTERS.IS_MANAGING_LOGIN_RESPONSE](state) {
    return state.managingLoginResponse === true;
  },
  [CORE_TYPES.GETTERS.API_ADDRESS](state) {
    return state.apiAddress
  },
  [CORE_TYPES.GETTERS.API_ADDRESS_LEGACY](state) {
    return state.apiAddress ? state.apiAddress.replace(RESTFUL_ENDPOINT, '') : state.apiAddress;
  },
  [CORE_TYPES.GETTERS.HAS_ROLES](state) {
    return (roles) => {
      return includesRole(state, roles)
    }
  },
  [CORE_TYPES.GETTERS.HAS_ACCESS](state, getters) {
    return (path) => {
      return isPathAllowed(path) && getters[MENU_TYPES.GETTERS.IS_OPTION_MENU_PATH_VISIBLE](path)
    }
  },
  [CORE_TYPES.GETTERS.STORE_SESSION_INFORMATION](state){
    return state.storeSessionInformation
  },
  [CORE_TYPES.GETTERS.IS_CUSTOMER](state) {
    return includesRole(state, VIEW_ROLES.VIEW_CUSTOMER)
  },
  [CORE_TYPES.GETTERS.IS_CUSTOMER_ONLY](state) {
    return includesRole(state, VIEW_ROLES.VIEW_CUSTOMER_ONLY);
  },
  [CORE_TYPES.GETTERS.IS_SUPER_CUSTOMER](state) {
    return includesRole(state, ROLES.SUPER_CUSTOMER)
  },
  [CORE_TYPES.GETTERS.IS_BROKER](state) {
    return includesRole(state, VIEW_ROLES.VIEW_BROKER)
  },
  [CORE_TYPES.GETTERS.IS_GESELLSCHAFT](state) {
    return includesRole(state, VIEW_ROLES.VIEW_GESELLSCHAFT);
  },
  [CORE_TYPES.GETTERS.IS_MEHRFACHAGENT](state) {
    return includesRole(state, ROLES.IS_MEHRFACHAGENT)
  },
  [CORE_TYPES.GETTERS.IS_FK](state, getters) {
    return includesRole(state, ROLES.FK) || !!getters[CORE_TYPES.GETTERS.GET_BROKER_INFORMATION].isFK
  },
  [CORE_TYPES.GETTERS.IS_FA](state, getters) {
    return includesRole(state, ROLES.FA) || (getters[CORE_TYPES.GETTERS.GET_BROKER_INFORMATION].isFK === undefined ? false : !getters[CORE_TYPES.GETTERS.GET_BROKER_INFORMATION].isFK) //this is a quick (and dirty solution) and will be removed once i investigated why isFk flag is not being sent sometimes
  },
  [CORE_TYPES.GETTERS.GET_DB_PREFIX](state, getters) {
    if (getters[CORE_TYPES.GETTERS.IS_FK]) {
      return ROLES.FK;
    } else if (getters[CORE_TYPES.GETTERS.IS_FA]) {
      return ROLES.FA;
    } else if (getters[BRIDGE_TYPES.GETTERS.RECEIVED_DB_PREFIX]) {
      return getters[BRIDGE_TYPES.GETTERS.RECEIVED_DB_PREFIX];
    } else {
      return 'FK';
    }
  },
  [CORE_TYPES.GETTERS.IS_FPP_PLUS_MASTER](state) {
    return includesRole(state, ROLES.IS_FPP_PLUS_MASTER)
  },
  [CORE_TYPES.GETTERS.IS_INVERSE](state) {
    return includesRole(state, ROLES.IS_INVERSE)
  },
  [CORE_TYPES.GETTERS.IS_RIGHT_SET_MAKLER_INAKTIV](state) {
    return includesRole(state, BROKER_LEVEL_ROLES.RIGHT_SET_MAKLER_INAKTIV)
  },
  [CORE_TYPES.GETTERS.IS_DGFRP_MASTER](state) {
    if(state.loginData?.rights?.istDeutscheRuhestandsplanungMaster == true){
      return true
    }else{
      return false
    }
  },
  [CORE_TYPES.GETTERS.IS_10000_FA](state) {
    if(state.loginData?.rights?.istFAWertpapierdiensleistungen == true){
      return true
    }else{
      return false
    }
  },
  [CORE_TYPES.GETTERS.IS_FPP_PLUS](state) {
    return includesRole(state, ROLES.IS_FPP_PLUS)
  },
  [CORE_TYPES.GETTERS.IS_AVL](state) {
    return includesRole(state, ROLES.IS_AVL)
  },
  [CORE_TYPES.GETTERS.CUSTOMERS_USE_KSC2](state) {
    return includesRole(state, ROLES.CUSTOMERS_USE_KSC2)
  },
  [CORE_TYPES.GETTERS.IS_DIALOG](state) {
    return includesRole(state, ROLES.IS_DIALOG)
  },
  [CORE_TYPES.GETTERS.ORIGINAL_USER_IS_BROKER](state, getters) {
    const originalUser = getters[CORE_TYPES.GETTERS.ORIGINAL_USER]
    if (originalUser?.rights)
      return originalUser.rights.isBroker;
    else
      return includesRole(state, VIEW_ROLES.VIEW_BROKER);
  },
  [CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN](state, getters) {
    const originalUser = getters[CORE_TYPES.GETTERS.ORIGINAL_USER]
    if (originalUser?.rights)
      return originalUser.rights.isIntern;
    else
      return includesRole(state, VIEW_ROLES.VIEW_INTERN);
  },
  [CORE_TYPES.GETTERS.ORIGINAL_TOKEN](state, getters) {
    const originalUser = getters[CORE_TYPES.GETTERS.ORIGINAL_USER]
    
    if (originalUser?.token)
      return originalUser.token;
    else if (state.loginData)
      return state.loginData.token;
    else
      return null;
  },
  [CORE_TYPES.GETTERS.ORIGINAL_USER](state) {
    if (!state.originalUserStack?.length) {
      return {}
    }
    return state.originalUserStack[state.originalUserStack.length - 1];
  },
  [CORE_TYPES.GETTERS.ORIGINAL_VIEW_ROLES](state) {
    if (state.loginData?.rights)
      return getViewRoles(state.loginData?.rights);
  },
  [CORE_TYPES.GETTERS.IS_TEST_BROKER](state) {
    return includesRole(state, [[VIEW_ROLES.VIEW_BROKER, ROLES.TEST_USER]])
  },
  [CORE_TYPES.GETTERS.IS_TEST_CUSTOMER](state) {
    return includesRole(state, [[VIEW_ROLES.VIEW_CUSTOMER, ROLES.TEST_USER]])
  },
  [CORE_TYPES.GETTERS.IS_INTERN](state) {
    return includesRole(state, VIEW_ROLES.VIEW_INTERN)
  },
  [CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS](state) {
    return includesRole(state, VIEW_ROLES.VIEW_BROKER) || includesRole(state, VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS)
  },
  [CORE_TYPES.GETTERS.IS_BYPASS](state) {
    return includesRole(state, VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS)
  },
  [CORE_TYPES.GETTERS.IS_MAKLER_KPI](state) {
    return includesRole(state, ROLES.IS_MAKLER_KPI)
  },
  [CORE_TYPES.GETTERS.ALLOW_CMS_FONDSSHOP_OHNE_HOME_SEITE](state) {
    return includesRole(state, ROLES.CMS_FONDSSHOP_OHNE_HOME_SEITE)
  },
  [CORE_TYPES.GETTERS.IS_MULTIBANKING_ACCOUNT_USER](state) {
    return includesRole(state, ROLES.MULTIBANKING)
  },
  [CORE_TYPES.GETTERS.IS_ALLOWED_TO_GET_MULTIBANKING_DATA](state) {
    return includesRole(state, ROLES.MULTIBANKING_DATA_ALLOWED)
  },
  [CORE_TYPES.GETTERS.IS_TEST](state) {
    return includesRole(state, ROLES.TEST_USER)
  },
  [CORE_TYPES.GETTERS.IS_MAKLER_EARLY_ACCESS](state) {
    return includesRole(state, ROLES.IS_MAKLER_EARLY_ACCESS)
  },
  [CORE_TYPES.GETTERS.ALLOW_WEBRTC_CALL_KUNDEN_FREIGABE](state) {
    return includesRole(state, ROLES.ALLOW_WEBRTC_CALL_KUNDEN_FREIGABE)
  },
  [CORE_TYPES.GETTERS.SHOW_WEBRTC_CALL_RECORDING_BUTTON](state) {
    return includesRole(state, ROLES.SHOW_WEBRTC_CALL_RECORDING_BUTTON)
  },
  [CORE_TYPES.GETTERS.IS_WEBRTC_KUNDENZUGANG](state) {
    return includesRole(state, ROLES.IS_WEBRTC_KUNDENZUGANG)
  },
  [CORE_TYPES.GETTERS.IS_WEBRTC_MAKLERZUGANG](state) {
    return includesRole(state, ROLES.IS_WEBRTC_MAKLERZUGANG)
  },
  [CORE_TYPES.GETTERS.SHOW_BROKER_NAME_ON_TITLE_PAGE](state) {
    return includesRole(state, ROLES.SHOW_BROKER_NAME_ON_TITLE_PAGE)
  },
  [CORE_TYPES.GETTERS.GET_USER_FULL_NAME](state) {
    return state.loginData.fullname;
  },
  [CORE_TYPES.GETTERS.GET_USER_DASHBOARD](state) {
    return state.loginData.dashboardCards;
  },
  [CORE_TYPES.GETTERS.GET_USER_PICTURE_URL](state) {
    return state.loginData.userPicture && state.loginData.userPicture.url;
  },
  [CORE_TYPES.GETTERS.GET_USER_PICTURE_DATA](state) {
    return state?.loginData?.userPicture || {};
  },
  [CORE_TYPES.GETTERS.GLOBAL_LOADING_STATE_STATUS](state) {
    return state.baseGlobalState.loading
  },
  [CORE_TYPES.GETTERS.GET_LOGIN_USER_ID](state) {
    return state.loginData.loginUserid ?? state.loginData.userid;
  },
  [CORE_TYPES.GETTERS.GET_USER_ID](state) {
    return state.loginData.userid;
  },
  [CORE_TYPES.GETTERS.GET_USER_UNTER_NR](state) {
    return state.loginData.unternr;
  },
  [CORE_TYPES.GETTERS.IS_SAME_USER_LOGGED_IN](_, getters) {
    return (userId, unternr = null) => {
      const currentUserId = getters[CORE_TYPES.GETTERS.GET_USER_ID];
      const currentUnterNr = getters[CORE_TYPES.GETTERS.GET_USER_UNTER_NR] || null;
      return currentUserId === userId && currentUnterNr === unternr;
    };
  },
  [CORE_TYPES.GETTERS.GET_LOGIN_DATA](state) {
    return state.loginData;
  },
  [CORE_TYPES.GETTERS.CAPTCHA_DATA](state) {
    return state.captchaData;
  },
  [CORE_TYPES.GETTERS.RECORDING_TUTORIAL](state) {
    return state.baseGlobalState.recordingTutorial;
  },
  [CORE_TYPES.GETTERS.PLAYING_TUTORIAL](state) {
    return state.baseGlobalState.playingTutorial;
  },  
  [CORE_TYPES.GETTERS.TUTORIAL_RECORDED_ITEMS](state) {
    return state.baseGlobalState.tutorialRecordedItems;
  },
  [CORE_TYPES.GETTERS.MFA_CHALLENGE_TYPE](state) {
    return state.mfaChallengeType;
  },
  [CORE_TYPES.GETTERS.GET_USER_LOGO_URL](state) {
    if(state.maklerLogo) {
      return state.maklerLogo;
    } 
    // return `${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/../images/etc/layout/Logo-smartMSC.png`;
    return ''
  },
  [CORE_TYPES.GETTERS.GET_DEFAULT_BROKER_ID](state) {
    if (includesRole(state, ROLES.FK)) {
      return DEFAULT_BROKER_ID[ROLES.FK];
    } else if (includesRole(state, ROLES.FA)) {
      return DEFAULT_BROKER_ID[ROLES.FA];
    } else {
      return '';
    }
  },
  [CORE_TYPES.GETTERS.IS_DEFAULT_BROKER_ID]() {
    return (maklernr) => {
      return Object.values(DEFAULT_BROKER_ID).includes(maklernr);
    }
  },
  [CORE_TYPES.GETTERS.GET_BROKER_INFORMATION](state) {
    return state.brokerData
  },
  [CORE_TYPES.GETTERS.GET_ANMELDEN_URL](state) {
    return state.brokerData?.maklernr ? `/login?maklernr=${state.brokerData.maklernr}` : '/login'
  },
  [CORE_TYPES.GETTERS.GET_BROKER_LOGO](state){
    return state.maklerLogo;
  },
  [CORE_TYPES.GETTERS.GET_COLOR_SCHEMA](state) {
    return state.baseGlobalState.colorSchema;
  },
  [CORE_TYPES.GETTERS.CONFIGURED_COLOR_SCHEME](state) {
    return state.baseGlobalState.configuredColorScheme;
  },
  [CORE_TYPES.GETTERS.PREFERRED_COLOR_SCHEMA](state) {
    return state.baseGlobalState.preferredColorSchema;
  },
  [CORE_TYPES.GETTERS.RECORDING_TUTORIAL_TITLE](state) {
    return state.baseGlobalState.tutorialTitle;
  },
  [CORE_TYPES.GETTERS.RECORDING_TUTORIAL_ROUTE](state) {
    return state.baseGlobalState.tutorialRoute;
  },
  [CORE_TYPES.GETTERS.RECORDING_TUTORIAL_USER_TYPE](state) {
    return state.baseGlobalState.tutorialUserType;
  },
  [CORE_TYPES.GETTERS.RECORDED_TUTORIALS](state) {
    return state.baseGlobalState.recordedTutorials;
  },
  [CORE_TYPES.GETTERS.RECORDING_FEATURE](state) {
    return state.baseGlobalState.recordingFeature;
  },
  [CORE_TYPES.GETTERS.RECORDING_TUTORIAL_LAST_ID](state) {
    return state.baseGlobalState.tutorialLastId;
  },
  [CORE_TYPES.GETTERS.APP_NAME_DEFAULT](state) {
    return state.appNameDefault
  },
  [CORE_TYPES.GETTERS.PAGE_TITLE](state, getters) {
    let baseTitle = state.appNameDefault

    // get title page from meta tags
    if (state.brokerData?.htmlMetaTags?.title) {
      baseTitle = state.brokerData.htmlMetaTags?.title
    }

    if (!state.loginData.token) {
      return baseTitle
    }

    const { fullname, userid, } = state?.loginData || {};

    const hasRoles = getters[CORE_TYPES.GETTERS.HAS_ROLES];
    if (hasRoles([VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS]) || hasRoles([ROLES.IS_KUNDENZUGANG])) {
      return `${fullname} | ${userid}`;
    }

    if (hasRoles([VIEW_ROLES.VIEW_BROKER])) {
      return hasRoles([ROLES.SHOW_BROKER_NAME_ON_TITLE_PAGE]) ? `${userid} | ${fullname}` : baseTitle;
    }

    return baseTitle;
  },
  [CORE_TYPES.GETTERS.PROXY_CONTENT](state) {
    return (url) => {
      return state.apiAddress + '/proxy/content?url=' + encodeURIComponent(url);
    }
  },
  [CORE_TYPES.GETTERS.SYSTEM_DATA](state) {
    return state.systemData;
  },
  [CORE_TYPES.GETTERS.GET_APP_LINKS](state) {
    return state.appLinks
  },
  [CORE_TYPES.GETTERS.IS_ADD_BREADCRUMB_LOCKED](state) {
    return state.addBreadcrumbLocked === true;
  },
  [CORE_TYPES.GETTERS.GET_SAVE_BACK_TO_PREVIOUS_PAGE](state) {
    return state.backToPreviousData
  },
  [CORE_TYPES.GETTERS.GET_BACK_TO_PREVIOUS_PAGE_TRACK](state) {
    return state.backToPreviousDataTrack
  },
  [CORE_TYPES.GETTERS.CURRENT_BACK_TO_PREVIOUS_PAGE](state) {
    if(!state.backToPreviousData?.length) return null;
    return state.backToPreviousData[state.backToPreviousData.length - 1];
  },
  [CORE_TYPES.GETTERS.GET_BROKER_IMAGE_BACKGROUND_LOGIN_URL](state) {
    return state.imageBackgroundLoginUrl;
  },
  [CORE_TYPES.GETTERS.HAS_COLOR_SCHEMA](state) {
    return Object.keys(state.baseGlobalState.colorSchema).length
  },
  [CORE_TYPES.GETTERS.GET_PRIVACY_DATA](state) {
    return state.privacyInfo
  },
  [CORE_TYPES.GETTERS.GET_USER_PRIVACY_CONTACTS](state) {
    return state.loginData?.privacy?.contacts
  },
  [CORE_TYPES.GETTERS.REGISTRATION_DATA](state) {
    return state.registrationData
  },
  [CORE_TYPES.GETTERS.PRIVACY_REGISTRATION_DATA](state) {
    return state.privacyRegistrationData
  },
  [CORE_TYPES.GETTERS.IS_ANLAGE_BEISPIEL](state) {
    return includesRole(state, ROLES.IS_ANLAGE_BEISPIEL);
  },
  [CORE_TYPES.GETTERS.IS_BROKER_MASTER](state) {
    return includesRole(state, ROLES.BROKER_MASTER);
  },
  [CORE_TYPES.GETTERS.GET_DEFAULT_LAYOUT](state) {
    return state.defaultLayoutFc;
  },

  [CORE_TYPES.GETTERS.GET_LAYOUT](state) {
    return state.layoutFc;
  },
  [CORE_TYPES.GETTERS.IS_KUNDENZUGANG](state) {
    return includesRole(state, ROLES.IS_KUNDENZUGANG);
  },
  [CORE_TYPES.GETTERS.IS_MAKLERZUGANG](state) {
    return includesRole(state, ROLES.IS_MAKLERZUGANG);
  },
  [CORE_TYPES.GETTERS.IS_MITARBEITERZUGANG](state) {
    return includesRole(state, [[ROLES.IS_MAKLERZUGANG, ROLES.IS_USER_UNTERNR]]);
  },

  [CORE_TYPES.GETTERS.GET_CMS_META_TAGS](state) {
    return state.cmsMetaTags
  },

  [CORE_TYPES.GETTERS.GET_LOGIN_WELCOME_TITLE](state) {
    return state.loginWelcomeTitle
  },

  [CORE_TYPES.GETTERS.IS_AT](state) {
    return includesRole(state, ROLES.IS_AT);
  },
  
  [CORE_TYPES.GETTERS.VERSADMIN_A](state) {
    return includesRole(state, ROLES.VERSADMIN_A);
  },   
  
  [CORE_TYPES.GETTERS.VERSADMIN_M](state) {
    return includesRole(state, ROLES.VERSADMIN_M);
  },

  [CORE_TYPES.GETTERS.SUPPOSED_TIMEOUT_TIME](state) {
    return state.baseGlobalState.supposedTimeoutTime
  },

  [CORE_TYPES.GETTERS.TIMEOUT_SECONDS](state) {
    return state.loginData?.timeoutSeconds || 0
  },
  
  [CORE_TYPES.GETTERS.APP_VERSION]() {
    const buildTimestamp = document?.documentElement?.dataset?.buildTimestamp || '';
    return buildTimestamp ? `v${buildTimestamp}` : '';
  },
  
  [CORE_TYPES.GETTERS.IS_BAVARIA](state) {
    return includesRole(state, ROLES.IS_BAVARIA);
  },
  
  [CORE_TYPES.GETTERS.IS_INVERSE](state) {
    return includesRole(state, ROLES.IS_INVERSE);
  },

  [CORE_TYPES.GETTERS.IS_SVM](state) {
    return includesRole(state, ROLES.IS_SVM);
  },

  [CORE_TYPES.GETTERS.PENDING_CONFIRM_MODAL](state) {
    const { pendingConfirmModal } = state;

    return {
      ...Object.keys(pendingConfirmModal).reduce((acc, key) => ({ 
        ...acc, 
        [key]: {
          _uuid: pendingConfirmModal[key]._uuid,
          options: pendingConfirmModal[key].options,
        },
      }), {}),
    };
  },

  [CORE_TYPES.GETTERS.WAIT_PENDING_CONFIRM_MODAL_RESPONSE](state) {
    return (_uuid) => state.pendingConfirmModal[_uuid]._deferred._promise;
  },
  
  [CORE_TYPES.GETTERS.IS_HAUPTSTRUKTURLEITENR](state, getters) {
    return includesRole(state, ROLES.IS_HAUPTSTRUKTURLEITENR);
  },

  [CORE_TYPES.GETTERS.IS_OPEN_SIGNATURES_READ_ONLY](state) {
    return includesRole(state, ROLES.IS_OPEN_SIGNATURES_READ_ONLY);
  },

  [CORE_TYPES.GETTERS.HAT_VERTRAG](state) {
    return includesRole(state, ROLES.HAT_VERTRAG);
  },

  [CORE_TYPES.GETTERS.SCREEN_WIDTH](state) {
    return state.screenWidth;
  },

  [CORE_TYPES.GETTERS.IS_SMALL_SCREEN](state) {
    const { screenWidth, } = state;
    return screenWidth === 'sm' || screenWidth === 'xs';
  },

  [CORE_TYPES.GETTERS.ASK_FOR_PIN](state) {
    return state.askPIN;
  },

  [CORE_TYPES.GETTERS.RESET_EVENT_BASE_FILTER](state) {
    return state.resetEventBaseFilter;
  },
  [CORE_TYPES.GETTERS.MSC_BUILD_INFO](state) {
    return state.mscBuildInfo;
  },
  [CORE_TYPES.GETTERS.IS_INITIAL_APP_STATE](state) {
    return state.isInitialAppState;
  },
  [CORE_TYPES.GETTERS.IS_INAKTIV](state) {
    return state.brokerData?.inaktiv || false
  }, 
  [CORE_TYPES.GETTERS.IS_SSO_VISIBLE](state) {
    return state.originalUserStack?.length == 0 || false
  }, 
  [CORE_TYPES.GETTERS.IS_ALLOWED_MERGE_SCHADEN](state) {
     return includesRole(state, ROLES.ALLOW_SCHADEN_MERGE)
  },

  [CORE_TYPES.GETTERS.GENERIC_SELECTION_FN](state) {
    return (uuid) => state.genericSelection?.[uuid] ?? [];
  },

  [CORE_TYPES.GETTERS.CHAT_EMPFAENGER_KEY](state) {
    return state.CHAT_EMPFAENGER_KEY
 },
 [CORE_TYPES.GETTERS.EXCEPTION_LOG](state) {
  return state.exceptionLog;
}

  
}