<template>
  <div class="box__container">
    <div>
      <FormLabel
        :config="{ bold: true }"
        label="Referenzbankverbindung (nur wenn Kunde mit Kontoinhaber identisch ist)"
      />
      <InputToggleSwitch
        label="Bitte merken Sie die auf diesem Auftrag angegebene Bankverbindung als Referenzbankverbindung vor."
        v-model="form.INPUT_CHK_REFERENZBV"
        :disabled="readOnly"
        @input="
          updateStoreData({
            componentId: 'INPUT_CHK_REFERENZBV',
            value: $event,
          })
        "
      />
    </div>
    <div>
    <FormLabel
        :config="{ bold: true }"
        label="Neuerteilung Auftrag wegen Ablehnung aufgrund Angemessenheitsprüfung"
      />
        <InputToggleSwitch
        label="Ich habe den Warnhinweis für meinen Auftrag erhalten, dass das Institut keine Angemessenheitsprüfung mangels fehlender/unvollständiger Angaben durchführen konnte oder dass auf Basis meiner Angaben das von mir gewünschte Geschäft als nicht angemessen beurteilt worden ist. Ich habe dies zur Kenntnis genommen und beauftrage das Institut dennoch ausdrücklich mit der Ausführung dieses Auftrages."
        v-model="form.INPUT_CHK_ANGEMESSENHEITSPRUEFUNG"
          :disabled="readOnly"
        @input="
          updateStoreData({
            componentId: 'INPUT_CHK_ANGEMESSENHEITSPRUEFUNG',
            value: $event,
          })
        "
      />
       <FormLabel
        :config="{ bold: true, small: true}"
        label="Wichtiger Hinweis :"
      />
      <p>Sofern Ihr zuvor erteilter Auftrag aus einem der vorgenannten Gründe im Rahmen der Angemessenheitsprüfung abgelehnt wurde und Sie dennoch die Ausführung des Auftrages wünschen, kreuzen Sie bitte dieses Ankreuzfeld an. Nur so ist ersichtlich, dass dies kein Erstauftrag ist, der aus dem gleichen Grund erneut zu einer Ablehnung des Auftrages führt.</p>
    </div>
  </div>
</template>
<script>
import WERTPAPIERORDER_TYPES from "@/store/wertpapierorder/types";
import mixin from "@/mixins/wertpapierorder/mixin.js";
import { mapGetters } from "vuex";
import FormLabel from "@/components/core/forms/FormLabel.vue";
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import InputRadioBoxGroup from "@/components/core/forms/radiobox/InputRadioBoxGroup.vue";

export default {
  data() {
    return {
      form: {},
    };
  },
  mixins: [mixin],
  components: {
    FormLabel,
    InputToggleSwitch,
    InputRadioBoxGroup,
  },
  computed: {
    ...mapGetters({
      data: WERTPAPIERORDER_TYPES.GETTERS.DATA,
    }),
  },
  methods: {
    initForm() {
      if (this.data) {
        this.form = {
          INPUT_CHK_REFERENZBV:
            this.data["INPUT_CHK_REFERENZBV"] == 'true' ||  this.data["INPUT_CHK_REFERENZBV"] == '1' ? "1" : "0",
          INPUT_RG_AUFTRAG_GUELTIG: this.data["INPUT_RG_AUFTRAG_GUELTIG"] ,
          INPUT_CHK_ANGEMESSENHEITSPRUEFUNG: this.data["INPUT_CHK_ANGEMESSENHEITSPRUEFUNG"] == 'true' ||  this.data["INPUT_CHK_ANGEMESSENHEITSPRUEFUNG"] == '1' ? "1" : "0"
        };
      }
    },
  },
  mounted() {
    this.initForm();
  },
};
</script>

