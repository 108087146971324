var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c(
            "GhostLoading",
            { staticClass: "box__container" },
            [_c("Block", { attrs: { height: "100" } })],
            1
          )
        : _vm._e(),
      _vm.params && !_vm.loading
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("input-field", {
                attrs: {
                  label: "Berechnete Wertsteigerung p.a.",
                  isComponentHalfSize: true
                },
                on: {
                  change: function($event) {
                    return _vm.triggerReload($event, true)
                  }
                },
                model: {
                  value: _vm.params.wertsteigerung,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "wertsteigerung", $$v)
                  },
                  expression: "params.wertsteigerung"
                }
              }),
              _c("InputToggleSwitch", {
                attrs: {
                  inLineLabel: "",
                  label: "jeweilige Jahrescourtagen anzeigen (Jc)"
                },
                on: { input: _vm.onChangeIncl },
                model: {
                  value: _vm.inclJahrescourtage,
                  callback: function($$v) {
                    _vm.inclJahrescourtage = $$v
                  },
                  expression: "inclJahrescourtage"
                }
              }),
              _c("ComboBox", {
                attrs: {
                  isComponentHalfSize: true,
                  sortComboboxValues: false,
                  value: _vm.jahre,
                  values: _vm.modes
                },
                on: { change: _vm.onChangeMode },
                model: {
                  value: _vm.jahre,
                  callback: function($$v) {
                    _vm.jahre = $$v
                  },
                  expression: "jahre"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.loading
            ? _c("GhostLoading", [_c("Block", { attrs: { height: "100" } })], 1)
            : _vm.rows.length
            ? _c("Table", {
                attrs: {
                  title: "Erwartete Courtage",
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowId: "Wertpapier",
                  rowsPerPage: 25,
                  headerActions: _vm.headerActions
                },
                on: { "headerAction-PLATTFORM": _vm.selectPlattform }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "showPlattform",
          attrs: {
            labelButtonConfirm: "Speichern",
            labelButtonCancel: "Abbrechen"
          },
          on: { onConfirmButton: _vm.onChangePlattform },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [_vm._v(" Plattformen ")]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "div",
            [
              _c("Table", {
                attrs: {
                  title: "",
                  headers: _vm.hdrPlatt,
                  rows: _vm.plattformen,
                  rowId: "lagerstelle",
                  rowsPerPage: 25
                },
                model: {
                  value: _vm.lagerstellen,
                  callback: function($$v) {
                    _vm.lagerstellen = $$v
                  },
                  expression: "lagerstellen"
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }