<template>
  <div>
    <PageHeaderTitleNavigation title="Versandprotokolle" />

    <div class="box__container" v-if="requestedData">
      <VpCommTable
        title="Versandprotokolle"
        :records="records"
        :loading="loading"
        @selected="onSelect"
      />
    </div>
   
  </div>
</template>

<script>
import CORE_TYPES from "@/store/core/types";
import V_P_TYPES from "@/store/versandProtokolle/types";

import { mapGetters } from "vuex";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import VpCommTable from "@/views/communication/versandProtokolle/VpCommTable.vue";

export default {
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    VpCommTable,
  },
  computed: {
    ...mapGetters({
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      isBroker: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_BROKER,
      records: V_P_TYPES.GETTERS.COMMUNICATIONS,
     
      isTestCustomer: CORE_TYPES.GETTERS.IS_TEST_CUSTOMER,
    }),
    metadata() {
      
    },
  },
  data() {
    return {
      apiAddress: process.env.VUE_APP_API,
      defaultOptions: null,
    
      isTerminModalActivity: false,
      requestedData: false,
      loading: false,
      selectedRows: [],
      eingangId: this.$route.params.eingangId,
    };
  },
  watch: {
    setup(next, oldValue) {
      
  
     
    },
  },
  mounted() {
     this.filter();
     this.requestedData = true; 
      this.loading = true; 
    //alert(this.eingangId);
    
  },
  methods: {
   
   
    async filter(){
      this.$store.commit(V_P_TYPES.MUTATIONS.COMMUNICATIONS,null);
      await this.$store.dispatch(V_P_TYPES.ACTIONS.COMMUNICATIONS,this.eingangId);
   
    },
    onSelect(rows) {
      this.selectedRows = rows;
    },
   

    
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: `zurück zu den Versandprotokolle`,
      to,
      from,
    });

    next();
  },
};
</script>

<style scoped>
.subject {
  display: inline-block;
  max-width: 700px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>