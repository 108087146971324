<template>
	<div>
    <div v-if="isContainerRendered">
      <div class="large">
        <CoreCard>
          <template v-slot:alternative>
          </template>
          <div class="content">
            <div>
              <div class="text-lg">{{largeCard.data}}</div>
              <div class="text-sm">{{largeCard.name}}</div>
            </div>
          </div>
        </CoreCard>
      </div>
      <div class="small grid-container">
        <div v-for="(card, index) of smallCards" :key="index">
          <CoreCard>
            <div :class="['content', `bottom-${index+1}`]">
              <div>
                <div class="text-lg">{{card.data}}</div>
                <div class="text-sm">{{card.name}}</div>
              </div>
            </div>
          </CoreCard>
        </div>
      </div>
    </div>
	</div>
</template>

<script>
import CoreCard from '../CoreCard/CoreCard';
import BrokerNumberCard from './BrokerNumberCard';
import cardMixin from '../CoreCard/card-mixin';


export default {
  mixins: [cardMixin],

  data: function() {
    return {
      isContainerRendered: false,
    };
  },

  computed: {
    chartName: function() {
      return this.propsData.option;
    },
    data: function() {
      return this.propsData.data[this.chartName];
    },
    customColors: function() {
      return this.propsData.customColors;
    },
    largeCard() {
      return Object.keys(this.data).length && {
          name: Object.keys(this.data)[0],
          data: this.data[Object.keys(this.data)[0]]
        };
    },
    smallCards() {
      return Object.keys(this.data).length && Object.keys(this.data).filter(c => c !== Object.keys(this.data)[0])
        .map(c => ({
          name: c,
          data: this.data[c]
        }));
    }
  },

  mounted: function () {
    this.$nextTick(function () {
      this.isContainerRendered = true;
    });
  },

  components: {
    CoreCard,
    BrokerNumberCard,
  },
}
</script>

<style scoped>
.grid-container {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-areas:
  "bottom-1 bottom-2"
  "bottom-3 bottom-4";
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  grid-gap: 30px;
}

.grid-container > div {
  position: relative;
}

.large {
  margin-bottom: 30px;
}

.grid-container .top {
  grid-area: top;
}
.grid-container .bottom-1 {
  grid-area: bottom-1;
}
.grid-container .bottom-2 {
  grid-area: bottom-2;
}
.grid-container .bottom-3 {
  grid-area: bottom-3;
}
.grid-container .bottom-4 {
  grid-area: bottom-4;
}
/* .content {
  width: 100%;
  height: 100%;
  position: relative;
  margin-top: -12px;
  text-align: center;
} */
.text-lg {
  font-size: 3rem;
}
.text-sm {
  font-size: 1rem;
}
@media screen and (max-width: 767px) {
  .grid-container {
    display: block;
    width: 100%;
    height: 100%;
    margin-bottom: 30px;
  }
  .grid-container > div {
    margin-bottom: 30px;
  }
}
</style>
