var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "antrag-components__container" }, [
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("FormLabel", {
          attrs: { label: "Pflichtangaben zum Depotübertrag" }
        }),
        _c("InputRadioBoxGroup", {
          attrs: {
            title: "Es handelt sich um einen",
            values: _vm.uebertragArtValues
          },
          on: {
            input: function($event) {
              return _vm.$emit("save", { uebertragArt: $event })
            }
          },
          model: {
            value: _vm.form.uebertragArt,
            callback: function($$v) {
              _vm.$set(_vm.form, "uebertragArt", $$v)
            },
            expression: "form.uebertragArt"
          }
        }),
        _c("FormLabel", {
          attrs: {
            label:
              "Übertrag der Verlustverrechnungssalden (Nur bei Gesamtübertrag ohne Eigentümerwechsel des Depots möglich.)"
          }
        }),
        _c("InputRadioBoxGroup", {
          attrs: {
            title:
              "Übertrag des Verlustverrechnungssaldos/der Verlustverrechnungssalden wird gewünscht.",
            values: _vm.verrechnungssaldenValues
          },
          on: {
            input: function($event) {
              return _vm.$emit("save", { verrechnungssalden: $event })
            }
          },
          model: {
            value: _vm.form.verrechnungssalden,
            callback: function($$v) {
              _vm.$set(_vm.form, "verrechnungssalden", $$v)
            },
            expression: "form.verrechnungssalden"
          }
        }),
        _vm.form.verrechnungssalden === "Auswahl"
          ? _c("InputToggleSwitch", {
              attrs: {
                value: _vm.form.verrechnungssaldo_aktien,
                label: "Verrechnungssalden Aktien"
              },
              on: {
                input: function($event) {
                  return _vm.$emit("save", { verrechnungssaldo_aktien: $event })
                }
              }
            })
          : _vm._e(),
        _vm.form.verrechnungssalden === "Auswahl"
          ? _c("InputToggleSwitch", {
              attrs: {
                value: _vm.form.verrechnungssaldo_sonstige,
                label: "Verrechnungssalden Sonstige"
              },
              on: {
                input: function($event) {
                  return _vm.$emit("save", {
                    verrechnungssaldo_sonstige: $event
                  })
                }
              }
            })
          : _vm._e(),
        _vm.form.verrechnungssalden === "Auswahl"
          ? _c("InputToggleSwitch", {
              attrs: {
                value: _vm.form.verrechnungssaldo_auslq,
                label: "ausl. Quellensteuer"
              },
              on: {
                input: function($event) {
                  return _vm.$emit("save", { verrechnungssaldo_auslq: $event })
                }
              }
            })
          : _vm._e(),
        _c(
          "BaseButton",
          {
            staticClass: "col-12",
            on: {
              click: function($event) {
                return _vm.$emit("back")
              }
            }
          },
          [_vm._v("Zurück")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }