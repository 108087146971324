var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "mb-5" },
        [
          _c("InputToggleSwitch", {
            attrs: {
              inLineLabel: "",
              label: "Anlage in Managed Depot erfassen",
              disabled: _vm.isDisabled
            },
            on: {
              input: function($event) {
                return _vm.updateStore("zusatzDJE", "djeAktiv", $event)
              }
            },
            model: {
              value: _vm.djeAktiv,
              callback: function($$v) {
                _vm.djeAktiv = $$v
              },
              expression: "djeAktiv"
            }
          })
        ],
        1
      ),
      _c("Table", {
        attrs: {
          headers: _vm.headers,
          rows: _vm.rows,
          rowId: "zeithorizont",
          hidePagination: ""
        },
        scopedSlots: _vm._u([
          {
            key: "betraege",
            fn: function(row) {
              return [
                _c("InputField", {
                  attrs: {
                    label: "einmalig",
                    type: "currency",
                    precision: 2,
                    value: row.einmalig,
                    disabled: _vm.isDisabled || !_vm.isZusatzDJEAktiv
                  },
                  on: {
                    change: function($event) {
                      return _vm.updateTableValue(row, "einmalig", $event)
                    }
                  }
                }),
                _c("InputField", {
                  attrs: {
                    label: "ratierlich",
                    type: "currency",
                    precision: 2,
                    value: row.ratierlich,
                    disabled: _vm.isDisabled || !_vm.isZusatzDJEAktiv
                  },
                  on: {
                    change: function($event) {
                      return _vm.updateTableValue(row, "ratierlich", $event)
                    }
                  }
                })
              ]
            }
          },
          {
            key: "anlageziel",
            fn: function(row) {
              return [
                _c("InputRadioBoxGroup", {
                  attrs: {
                    value: row.anlageziel,
                    disabled: _vm.isDisabled || !_vm.isZusatzDJEAktiv,
                    values: _vm.anlagezielValues
                  },
                  on: {
                    input: function($event) {
                      return _vm.updateTableValue(row, "anlageziel", $event)
                    }
                  }
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }