import OPEN_SIGNS_TYPES from '@/store/openSigns/types';
import COMMUNICATION_TYPES from '@/store/communication/types';
import MSC_NEWS_TYPES from '@/store/mscnews/types';
import STECKBRIEF_TYPES from '@/components/steckbrief/store/types';

/**
 * adds a badge when greater than 0 to a menu with the configured path.
 * any menu path can be configured as badge.
 */
const badgeConfigs = [
  // Postfach badge
  createBadgeConfig('POSTFACH', '/communication/postfach/chats')
    .showOnParents()
    .withCount(COMMUNICATION_TYPES.GETTERS.COMMUNICATIONS_UNREAD)
    .withLoadFn(({ dispatch }) => dispatch(COMMUNICATION_TYPES.ACTIONS.UNREAD_COMMUNICATIONS)),

  // To-Do Liste badge
  createBadgeConfig('TODO_LIST', '/communication/todo-list')
    .hideOnMenu()
    .showOnParents()
    .withCount(
      OPEN_SIGNS_TYPES.GETTERS.OPEN_SIGNS_COUNT, 
      STECKBRIEF_TYPES.GETTERS.TODO_VIDEO_INDENT_COUNT,
    )
    .withLoadFn(({ dispatch }) => {
      dispatch(OPEN_SIGNS_TYPES.ACTIONS.GET_OPEN_SIGNS_COUNT);
      dispatch(STECKBRIEF_TYPES.ACTIONS.GET_TODO_VIDEO_INDENT_COUNT);
    }),

  // MscNews badge
  createBadgeConfig('NEWS_MSC_COUNT', '/intern/news-msc')
    .withCount(MSC_NEWS_TYPES.GETTERS.MSC_NEWS_COUNT)
    .withLoadFn(({ dispatch }) => dispatch(MSC_NEWS_TYPES.ACTIONS.GET_MSC_NEWS_COUNT)),
];

function createBadgeConfig(name, path) {
  return {
    name,
    path,
    count: [],
    loadFn: null,
    visibleOnMenu: true,
    visibleOnParents: false,
    /**
     * @param  {...string} count - store getter
     * @returns object
     */
    withCount(...count) {
      this.count = count;
      return this;
    },
    withLoadFn(loadFn) {
      this.loadFn = loadFn;
      return this;
    },
    /**
     * hides menu badge on menu with the path
     * @returns object
     */
    hideOnMenu() {
      this.visibleOnMenu = false;
      return this;
    },
    /**
     * shows menu badge on parents (primary/group menu)
     * @returns object
     */
    showOnParents() {
      this.visibleOnParents = true;
      return this;
    },
  };
}

const mapConfigsByPath = (acc, badgeConfig) => ({
  ...acc,
  [badgeConfig.path]: badgeConfig,
});

export const parentsMenuBadgeConfig = badgeConfigs
  .filter(badgeConfig => badgeConfig.visibleOnParents)
  .reduce(mapConfigsByPath, {});

export const optionsMenuBadgeConfig = badgeConfigs
  .filter(badgeConfig => badgeConfig.visibleOnMenu)
  .reduce(mapConfigsByPath, {});
