<template>
  <transition name="slide-fade">
    <div v-if="showInfoBoxData" class="info-box">
      {{ textToDisplay }}
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      showInfoBoxData: false,
      textToDisplay: '',
    }
  },
  methods: {
    showInfoBox(timout, text){
      this.showInfoBoxData = true;
      this.textToDisplay = text;
      setTimeout(() => {
        this.showInfoBoxData = false;
      }, timout);
    }
  }
};
</script>

<style scoped>
.info-box {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: #333;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>