<template>
  <BaseButton :isClear="isClear" :isPrimary="isPrimary" :isSecondary="isSecondary" :isBlock="isBlock" :disabled="disabled" :animated="animated" class="upload-btn">
    <slot>Dokument auswählen</slot>
    <label class="upload-btn--label" :class="{'clickable': !disabled }">
      <input type="file" :accept="accept" @change="onFileChange" :multiple="multiple" :disabled="disabled"/>
    </label>
  </BaseButton>
</template>
<script>
import BaseButton from '@/components/core/BaseButton.vue';

export default {
  props: {
    accept: {
      type: String,
      default: '*',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    isPrimary: {
      type: Boolean,
      default: true
    },
    isSecondary: {
      type: Boolean,
      default: false
    },
    isBlock: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    animated: {
      type: Boolean,
      default: false,
    },
    isClear: {
      type: Boolean,
      default: false,
    },    
  },
  methods: {
    onFileChange(event) {
      this.$emit("files", [...event.target.files])
      event.target.value = ''
    },
  },
  components: {
    BaseButton,
  },
}
</script>
<style scoped>
.upload-btn {
  position: relative;
}
.upload-btn--label {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.upload-btn--label input {
  display: none;
}
</style>
