export const MUTATION_PREFIX = 'FEE: ';
export const ACTIONS_PREFIX = 'FEE_ACTIONS_';
export const GETTERS_PREFIX = 'FEE_GETTERS_';

export default {
  MUTATIONS: {
    SET_FEE_PROPERTIES_COMBOS: MUTATION_PREFIX + 'SET_FEE_PROPERTIES_COMBOS',
    SET_FEE_PROPERTIES: MUTATION_PREFIX + 'SET_FEE_PROPERTIES',
    SET_FEE_PROPERTIES_EDITED: MUTATION_PREFIX + 'SET_FEE_PROPERTIES_EDITED',
    RESET_FEE_PROPERTIES_EDITED: MUTATION_PREFIX + 'RESET_FEE_PROPERTIES_EDITED',

    SET_ABRECHNUNGEN: MUTATION_PREFIX + 'SET_ABRECHNUNGEN',

    SET_KUNDE_HONORARE: MUTATION_PREFIX + 'SET_KUNDE_HONORARE',
    SET_CALC_KUNDE_HONORARE_DATA: MUTATION_PREFIX + 'SET_CALC_KUNDE_HONORARE_DATA',

    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    SET_FEHLERHAFT: MUTATION_PREFIX + 'SET_FEHLERHAFT',
  },
  ACTIONS: {
    FIND_FEE_PROPERTIES_COMBOS: ACTIONS_PREFIX + 'FIND_FEE_PROPERTIES_COMBOS',
    GET_FEE_PROPERTIES: ACTIONS_PREFIX + 'GET_FEE_PROPERTIES',
    SAVE_FEE_PROPERTIES: ACTIONS_PREFIX + 'SAVE_FEE_PROPERTIES',
    COPY_FEE_PROPERTIES: ACTIONS_PREFIX + 'COPY_FEE_PROPERTIES',
    DELETE_FEE_PROPERTIES: ACTIONS_PREFIX + 'DELETE_FEE_PROPERTIES',

    FIND_ABRECHNUNGEN: ACTIONS_PREFIX + 'FIND_ABRECHNUNGEN',

    FIND_KUNDE_HONORARE: ACTIONS_PREFIX + 'FIND_KUNDE_HONORARE',
    CALC_KUNDE_HONORARE: ACTIONS_PREFIX + 'CALC_KUNDE_HONORARE',
    SAVE_KUNDE_HONORARE: ACTIONS_PREFIX + 'SAVE_KUNDE_HONORARE',
    DELETE_KUNDE_HONORARE: ACTIONS_PREFIX + 'DELETE_KUNDE_HONORARE',

    FIND_KUNDE_BANKVERBINDUNG_DATA: ACTIONS_PREFIX + 'FIND_KUNDE_BANKVERBINDUNG_DATA',
  },
  GETTERS: {
    FEE_PROPERTIES_COMBOS: GETTERS_PREFIX + 'FEE_PROPERTIES_COMBOS',
    FEE_PROPERTIES: GETTERS_PREFIX + 'FEE_PROPERTIES',
    FEE_PROPERTIES_EDITED: GETTERS_PREFIX + 'FEE_PROPERTIES_EDITED',

    ABRECHNUNGEN: GETTERS_PREFIX + 'ABRECHNUNGEN',
    IS_ABRECHNUNGEN_LOADED: GETTERS_PREFIX + 'IS_ABRECHNUNGEN_LOADED',

    KUNDE_HONORARE: GETTERS_PREFIX + 'KUNDE_HONORARE',
    IS_KUNDE_HONORARE_LOADED: GETTERS_PREFIX + 'IS_KUNDE_HONORARE_LOADED',
    CALC_KUNDE_HONORARE_DATA: GETTERS_PREFIX + 'CALC_KUNDE_HONORARE_DATA',
    FEHLERHAFT: GETTERS_PREFIX + 'FEHLERHAFT',
  }
}