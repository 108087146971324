var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: "Postfach", subtitle: "SMS" }
      }),
      _vm.sms
        ? _c("div", { staticClass: "box__container" }, [
            _c(
              "span",
              {
                staticClass: "box__title",
                staticStyle: { "margin-right": "60px" }
              },
              [
                _c(
                  "span",
                  { staticClass: "text-right", staticStyle: { width: "5rem" } },
                  [_vm._v("Betreff")]
                ),
                _vm._v(" " + _vm._s(_vm.sms.subject) + " ")
              ]
            ),
            _c("span", [_vm._v(_vm._s(_vm.sms.dateSend))]),
            _c("br"),
            _c("div", { staticClass: "header" }, [
              _c("div", [
                _c("div", [
                  _c(
                    "span",
                    {
                      staticClass: "text-right",
                      staticStyle: { width: "5rem" }
                    },
                    [_vm._v("Von")]
                  ),
                  _vm._v(" " + _vm._s(_vm.sms.fromAddress) + " ")
                ]),
                _c("div", [
                  _c(
                    "span",
                    {
                      staticClass: "text-right",
                      staticStyle: { width: "5rem" }
                    },
                    [_vm._v("An")]
                  ),
                  _vm._v(" " + _vm._s(_vm.sms.toAddress) + " ")
                ])
              ])
            ])
          ])
        : _vm._e(),
      _vm.sms
        ? _c("div", { staticClass: "box__container" }, [
            _c("div", { staticClass: "sms-body" }, [
              _vm._v(" " + _vm._s(_vm.sms.text) + " ")
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }