import Vue from 'vue';
import MAKLER_ANTRAG_TYPES from './types'; 

export default {
    
    [MAKLER_ANTRAG_TYPES.MUTATIONS.LOAD_MAKLERANTRAG_SUCCESS](state,payload){
        Vue.set(state, 'maklerAntrag', Object.assign({}, payload.data));                   
    },    
    [MAKLER_ANTRAG_TYPES.MUTATIONS.LOAD_AUSWERTUNG_SUCCESS](state,payload){
        Vue.set(state, 'maklerAntragAuswertung', payload.data || []);                   
    },
    [MAKLER_ANTRAG_TYPES.MUTATIONS.RESET_JAHRESCHECKLISTE](state,payload){
        Vue.set(state, 'maklerAntrag', null)
    },
    [MAKLER_ANTRAG_TYPES.MUTATIONS.LOAD_CONFIG_DATA_SUCCESS](state,payload){
        Vue.set(state, 'maklerAntragConfigData',payload.data || {})
    },
    [MAKLER_ANTRAG_TYPES.MUTATIONS.LOAD_CHECKLISTE_DOCS_SUCCESS](state,payload){
        Vue.set(state, 'attachments',payload.data || [])
    },
    [MAKLER_ANTRAG_TYPES.MUTATIONS.RESET_FORM](state){
        Vue.set(state, 'MaklerAntrag',null)
        Vue.set(state, 'attachments',[])
    }
}