<template>
  <div>
    <InvestmentAdvice ref="investmentAdvice" :id="id" :routeStep="1" :mappeId="mappeId"></InvestmentAdvice>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import INVESTMENT_ADVICE_TYPES from "@/store/investmentAdvice/types";
import InvestmentAdvice from "@/components/investmentAdvice/InvestmentAdvice.vue";

export default {
  components: {
    InvestmentAdvice,
  },
  data() {
    return {
      mappeId: '',
    };
  },
  computed: {
    ...mapGetters({
      id: INVESTMENT_ADVICE_TYPES.GETTERS.ID,
    }),
  },
  created() {
    if (this.$route.query?.adviceId) {
      this.$store.commit(INVESTMENT_ADVICE_TYPES.MUTATIONS.SET_INVESTMENT_ADVICE_SUCCESS, this.$route.query.adviceId);
    }
  },
  mounted() {
    if (this.$route.query?.mappeId) {
      this.mappeId = this.$route.query.mappeId;
    }
  },
  async beforeRouteLeave (to, from, next) {
    await this.$refs.investmentAdvice.submitChanges();

    this.$addBreadcrumb({
        label: 'Zurück zur Anlageempfehlung', 
        to,
        from,
    });
    next()
  },
};
</script>