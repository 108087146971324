<template>
  <Table
    tableId="cab9b82f-9f1c-41ca-aa11-c1e4e3e1d92d"
    cardView
    :headers="headers"
    :rows="rows"
    hidePagination
  />
</template>


<script>
import BERICHTE_TYPES from '@/store/berichte/types';
import { TextColumn, DateColumn } from "@/components/table2/table_util.js";
import Table from "@/components/table2/Table.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Table,
  },
  computed: {
    ...mapGetters({
      altersvorsorge: BERICHTE_TYPES.GETTERS.ALTERSVORSORGE,
    }),
    headers() {
      return {
        center: [
          TextColumn("svBStatus", "Bearbeitungsstatus"),
          DateColumn("svBDatum", "Bearbeitungsdatum"),
          TextColumn("svMerkmal", "Merkmal"),
          DateColumn("svDatum", "Datum"),
        ]
      };
    },
    rows() {
      return [
        {...(this.altersvorsorge?.zulagen || {})}
      ]
    },
  },
};
</script>