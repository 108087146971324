<template>
  <div @click="pressStart" class="box spec_button start">Start €</div>
</template>

<script>
import functionMixins from "../../functionMixins.js";
//import this.$store from "./../../this.$store.js";
export default {
  name: "Start",
  mixins: [functionMixins],
  methods: {  
    pressStart() {
      this.$store.commit("SET_OPERATORCLICKED", false);
      this.$store.commit("SET_KOMMACLICKED", false);
       if (
        this.$store.getters.getAnzahl !== "0" &&
        this.$store.getters.getJahre !== "0" &&
        this.$store.getters.getRate !== "0" &&
        this.$store.getters.getZins !== "0"
      ) {
        var anzahl = this.$store.getters.getAnzahl;
        var jahre = this.$store.getters.getJahre;
        var rate = this.$store.getters.getRate;
        var zins = this.$store.getters.getZins;
        var result = this.calcStartfromRate(zins, rate, jahre, anzahl);
        result = Math.round(result * 100)/100;
        this.$store.commit("SET_START", this.formatNumber(result));
        var ende = Number(rate) * Number(anzahl) * Number(jahre);
        this.setRsLabels(result, ende);
      }
      if (
        this.$store.getters.getJahre !== "0" &&
        this.$store.getters.getEnde !== "0" &&
        this.$store.getters.getZins !== "0"
      ) {
        jahre = this.$store.getters.getJahre;
        ende = this.$store.getters.getEnde;
        zins = this.$store.getters.getZins;
        result = this.calcStartfromEnde(zins, ende, jahre);
        this.$store.commit("SET_START", this.formatNumber(result));
        this.setRsLabels(result, ende);
      } else {
        if (this.$store.getters.getCurrent !== "") {
          this.$store.commit("SET_START", this.$store.getters.getCurrent);
          this.$store.commit("SET_CURRENT", "");
        }
      }
    }
  }
};
</script>

<style scoped>
.minus {
  grid-area: start;
}
</style>