import INVESTMENT_ADVICE from './types';
import axios from 'axios';
import { buildMessage} from '../../helpers/log-message-helper';
import LOG_TYPES from '@/store/log/types';

export default {

  [INVESTMENT_ADVICE.ACTIONS.TO_ORDER]({ commit, state, rootState },  payload) {
    const config = {
      defaultSpinner: true,
    };
    return axios.post(`${rootState.core.apiAddress}/investmentadvice/step/order?adviceId=${payload.adviceId}&beratung=${payload.beratung}`, payload.parameters, config)
    .then(response => {
      if (process.env.VUE_APP_USER === 'hannes') console.log('respOrder', JSON.parse(JSON.stringify(response.data)) );
      if (response) {
        if(response.data?.anlageempfehlungId && state.investmentAdvice !== response.data.anlageempfehlungId) {
          commit(INVESTMENT_ADVICE.MUTATIONS.SET_INVESTMENT_ADVICE_SUCCESS, response.data.anlageempfehlungId);
        }
        commit(INVESTMENT_ADVICE.MUTATIONS.TO_ORDER_SUCCESS, {data: response.data});
      }
      return response.data;
    }).catch(error => {
      commit(INVESTMENT_ADVICE.MUTATIONS.TO_ORDER_FAILURE, {data: {}});
      console.log(error);
    })
  },

  [INVESTMENT_ADVICE.ACTIONS.FETCH_INFO]({ commit, state, rootState },  payload) {
    if (state.info && state.info.some(item=>item.key === payload.adviceId)) {
      let foundInState = state.info.find(item=>item.key === payload.adviceId);
      commit(INVESTMENT_ADVICE.MUTATIONS.FETCH_INFO_SUCCESS, {info: foundInState.info, key:payload.adviceId});
      return;
    }
     const config = {
       defaultSpinner: true,
       adviceId: payload.adviceId
     };
     axios.get(`${rootState.core.apiAddress}/investmentadvice/info?adviceId=${payload.adviceId}`, config)
    .then(response => {
      if (response && response.data) {
        commit(INVESTMENT_ADVICE.MUTATIONS.FETCH_INFO_SUCCESS, {info: response.data.info, key: payload.adviceId});
      }
    }).catch(error => {
      console.log(error);
    })
  },
  async [INVESTMENT_ADVICE.ACTIONS.SET_INVESTMENT_ADVICE]({ commit, dispatch, state, rootState }, payload) {
    const config = {
      defaultSpinner: true,
      adviceId: payload.adviceId
    };
    // if (state.investmentAdvice !== undefined && state.investmentAdvice !== '-1') {
    //     await axios.delete(`${rootState.core.apiAddress}/investmentadvice/delete?adviceId=${state.investmentAdvice}&temponly=${true}`, config)
    //     .then(response => {
    //         commit(INVESTMENT_ADVICE.MUTATIONS.RESET_STATE);
    //         commit(INVESTMENT_ADVICE.MUTATIONS.SET_INVESTMENT_ADVICE_SUCCESS, payload.adviceId);
    //         dispatch(INVESTMENT_ADVICE.ACTIONS.FETCH_PARAMETERS, {
    //             adviceId: payload.adviceId,
    //             step: "MAIN",
    //         });
    //     }).catch(error => {
    //         console.log(error);
    //     })
    // } else {
        commit(INVESTMENT_ADVICE.MUTATIONS.RESET_STATE);
        commit(INVESTMENT_ADVICE.MUTATIONS.SET_INVESTMENT_ADVICE_SUCCESS, payload.adviceId);
    // }
  },
  [INVESTMENT_ADVICE.ACTIONS.SET_INVESTMENT_ADVICE_ORDER]({ commit},  payload) {
    commit(INVESTMENT_ADVICE.MUTATIONS.SET_INVESTMENT_ADVICE_ORDER_SUCCESS, payload);   
  },
  [INVESTMENT_ADVICE.ACTIONS.SET_INVESTMENT_ADVICE_DEPOTEROEFFNUNG]({ commit},  payload) {
    commit(INVESTMENT_ADVICE.MUTATIONS.SET_INVESTMENT_ADVICE_DEPOTEROEFFNUNG_SUCCESS, payload);   
  },
  [INVESTMENT_ADVICE.ACTIONS.DELETE_INVESTMENT_ADVICE]({ commit, state, rootState, dispatch },  payload) {
    const config = {
      defaultSpinner: true,
      adviceId: payload.adviceId
    };
    return new Promise((resolve, reject) => {
      if (state.investmentAdvice !== undefined) {
          axios.delete(`${rootState.core.apiAddress}/investmentadvice/delete?adviceId=${payload.adviceId}&temponly=${false}`, config)
          .then(response => {
            dispatch(INVESTMENT_ADVICE.ACTIONS.REFRESH_LIST);
            resolve();
          }).catch(error => {
            commit(INVESTMENT_ADVICE.MUTATIONS.FETCH_LIST_FAILURE, {data: {}, delete: true});
            console.log(error);
            reject(error);
          })
      } else {
        reject();
      }  
    });
  },

  [INVESTMENT_ADVICE.ACTIONS.DELETE_INVESTMENT_ADVICE_LIST]({ commit, state, rootState, dispatch },  payload) {
    const config = {
      defaultSpinner: true,
      //params: {id:payload}
    };
    const params = Object.entries(payload).map(([key, val]) => `${'id'}=${val}`).join('&');
    //console.log('DELETE_INVESTMENT_ADVICE_LIST ' + params);
    return new Promise((resolve, reject) => {
     {
          axios.delete(`${rootState.core.apiAddress}/investmentadvice/deleteList?${params}`, config)
          .then(response => {
            dispatch(INVESTMENT_ADVICE.ACTIONS.REFRESH_LIST);
            resolve();
          }).catch(error => {
            commit(INVESTMENT_ADVICE.MUTATIONS.FETCH_LIST_FAILURE, {data: {}, delete: true});
            console.log(error);
            reject(error);
          })
      }
    });
  },

  [INVESTMENT_ADVICE.ACTIONS.FETCH_LIST]({ commit, rootState, },  { adviceId, filters }) { 
    commit(INVESTMENT_ADVICE.MUTATIONS.SET_LIST_FILTERS, filters);

    const config = {
      defaultSpinner: true,
      adviceId: adviceId,
      params: filters || {},
      cancelableRequest: {
        title: 'Anlageempfehlung Filter'
      }
    };
    return new Promise((resolve, reject) => {
      axios.get(`${rootState.core.apiAddress}/investmentadvice/list`, config)
        .then(response => {
          if (response && response.data) {
            commit(INVESTMENT_ADVICE.MUTATIONS.FETCH_LIST_SUCCESS, {data: response.data});
          }
          resolve();
        }).catch(error => {
          commit(INVESTMENT_ADVICE.MUTATIONS.FETCH_LIST_FAILURE, {data: {}, delete: false});
          reject(error);
        });
    });
  },

  [INVESTMENT_ADVICE.ACTIONS.REFRESH_LIST]({ getters, dispatch }) {  
    const filters = getters[INVESTMENT_ADVICE.GETTERS.LIST_FILTERS] || {};
    dispatch(INVESTMENT_ADVICE.ACTIONS.FETCH_LIST, { filters, })
  },

  [INVESTMENT_ADVICE.ACTIONS.FETCH_PARAMETERS]({ commit, state, rootState, dispatch },  payload) {
    if (state.parameters && state.parameters[payload.step] && (state.parameters[payload.step].loaded || state.parameters[payload.step].neither)) {
      commit(INVESTMENT_ADVICE.MUTATIONS.FETCH_PARAMETERS_SUCCESS, {data: state.parameters[payload.step].data, key:payload.step});
      return Promise.resolve();
    }
    const config = {
      defaultSpinner: true,
      adviceId: payload.adviceId
    };
    return axios.get(`${rootState.core.apiAddress}/investmentadvice/parameters?adviceId=${payload.adviceId}&step=${payload.step}`, config)
    .then(response => {
      if (response && response.data) {
        if(response.data?.anlageempfehlungId && state.investmentAdvice !== response.data.anlageempfehlungId) {
          commit(INVESTMENT_ADVICE.MUTATIONS.SET_INVESTMENT_ADVICE_SUCCESS, response.data.anlageempfehlungId);
        }
        commit(INVESTMENT_ADVICE.MUTATIONS.FETCH_PARAMETERS_SUCCESS, {data: response.data, key: payload.step});
      }
    }).catch(error => {
      commit(INVESTMENT_ADVICE.MUTATIONS.FETCH_PARAMETERS_FAILURE, {data: {}, key: payload.step});
    })
  },

  [INVESTMENT_ADVICE.ACTIONS.SUBMIT_DIALOG_ANSWER]({ commit, state, rootState },  payload) {
    const config = {
      defaultSpinner: true,
    };
    axios.post(`${rootState.core.apiAddress}/investmentadvice/${payload.url}`, {}, config)
    .then(response => {
      if(response.data?.anlageempfehlungId && state.investmentAdvice !== response.data.anlageempfehlungId) {
        commit(INVESTMENT_ADVICE.MUTATIONS.SET_INVESTMENT_ADVICE_SUCCESS, response.data.anlageempfehlungId);
      }
      commit(INVESTMENT_ADVICE.MUTATIONS.RESET_POSITIONS, {key: payload.step});
      commit(INVESTMENT_ADVICE.MUTATIONS.FETCH_PARAMETERS_SUCCESS, {data: response.data, key: payload.step});
    }).catch(error => {
      console.log(error);
    })      
},
  [INVESTMENT_ADVICE.ACTIONS.SAVE_PARAMETERS]({ commit, state, rootState, dispatch }, payload) {  
    if (payload.parameters.tableData) {
      commit(INVESTMENT_ADVICE.MUTATIONS.RESET_POSITIONS, {key:payload.parameters.step});
    } 

    commit(INVESTMENT_ADVICE.MUTATIONS.RESET_PARAMETERS_LOADED, {key: payload.parameters.step});

    const config = {
       defaultSpinner: true,
       adviceId: payload.adviceId
    };
    return axios.post(`${rootState.core.apiAddress}/investmentadvice/step?adviceId=${payload.adviceId}`, payload.parameters, config)
    .then(response => {
      if (response.data) {
        if(response.data.anlageempfehlungId && state.investmentAdvice !== response.data.anlageempfehlungId) {
          commit(INVESTMENT_ADVICE.MUTATIONS.SET_INVESTMENT_ADVICE_SUCCESS, response.data.anlageempfehlungId);
        }
        commit(INVESTMENT_ADVICE.MUTATIONS.FETCH_PARAMETERS_SUCCESS, {data: response.data, key: payload.parameters.step});
      }
    })
  },

 [INVESTMENT_ADVICE.ACTIONS.DELETE_POSITIONS]({ commit, state, rootState },  payload) {   
    return new Promise((resolve, reject) => {
      const config = {
        defaultSpinner: true,
        adviceId: payload.adviceId
      };
      if (state.investmentAdvice !== undefined) {
        const key = payload.key;
        commit(INVESTMENT_ADVICE.MUTATIONS.RESET_POSITIONS, {key});
        axios.delete(`${rootState.core.apiAddress}/investmentadvice/positions/delete?adviceId=${payload.adviceId}`, {data: payload.delete}, config)
        .then(response => {
          commit(INVESTMENT_ADVICE.MUTATIONS.FETCH_POSITIONS_SUCCESS,  {data: response.data, key});
          resolve();
        }).catch(error => {
          console.log(error);
          reject(error);
        })
      } else {
        reject();
      }
    });
  },
  [INVESTMENT_ADVICE.ACTIONS.ADD_POSITIONS]({ commit, state, rootState },  payload) {
    const config = {
      defaultSpinner: true,
      adviceId: payload.adviceId
    };
    if (state.investmentAdvice !== undefined) {
      commit(INVESTMENT_ADVICE.MUTATIONS.RESET_POSITIONS, {key: payload.add.quelle});
      axios.post(`${rootState.core.apiAddress}/investmentadvice/positions/add?adviceId=${payload.adviceId}`, payload.add, config)
        .then(response => {
          if(response.data?.anlageempfehlungId && state.investmentAdvice !== response.data.anlageempfehlungId) {
            commit(INVESTMENT_ADVICE.MUTATIONS.SET_INVESTMENT_ADVICE_SUCCESS, response.data.anlageempfehlungId);
          }
          commit(INVESTMENT_ADVICE.MUTATIONS.FETCH_POSITIONS_SUCCESS,  {data: response.data, key: payload.add.quelle});
          
        }).catch(error => {
          console.log(error);})
    }  
  },
  [INVESTMENT_ADVICE.ACTIONS.UPLOAD_ALLOCATION_FILE]({ commit, state, rootState, dispatch },  payload) {
    const config = {
      defaultSpinner: true,
      adviceId: payload.adviceId,
      headers: {'Content-Type': 'multipart/form-data'},
    };
    if (state.investmentAdvice !== undefined) {     
        axios.post(`${rootState.core.apiAddress}/investmentadvice/positions/import?adviceId=${payload.adviceId}&asStep=true`, payload.file, config)
        .then(response => {
          if(response.data?.anlageempfehlungId && state.investmentAdvice !== response.data.anlageempfehlungId) {
            commit(INVESTMENT_ADVICE.MUTATIONS.SET_INVESTMENT_ADVICE_SUCCESS, response.data.anlageempfehlungId);
          }
          commit(INVESTMENT_ADVICE.MUTATIONS.FETCH_PARAMETERS_SUCCESS, {data: response.data, key: INVESTMENT_ADVICE.STEPS.MAIN});
        }).catch(error => {
          console.log(error);
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Beim importieren des Filters ist ein Fehler aufgetreten.', 'danger'));
        })
    }  
  },
  [INVESTMENT_ADVICE.ACTIONS.APPLY_OPTIMIZATION]({ commit, state, rootState },  payload) {
    const config = {
      defaultSpinner: true
    };
    commit(INVESTMENT_ADVICE.MUTATIONS.RESET_OUTCOME, {data: []});
    axios.post(`${rootState.core.apiAddress}/investmentadvice/optimization/apply?adviceId=${payload.adviceId}`, payload.applydata, config)
    .then(response => {
      if(response.data?.anlageempfehlungId && state.investmentAdvice !== response.data.anlageempfehlungId) {
        commit(INVESTMENT_ADVICE.MUTATIONS.SET_INVESTMENT_ADVICE_SUCCESS, response.data.anlageempfehlungId);
      }
      commit(INVESTMENT_ADVICE.MUTATIONS.FETCH_OUTCOME_SUCCESS, {data: response.data, key: INVESTMENT_ADVICE.OUTCOME.OPTIMIZATION});
      
    }).catch(error => {
      payload.onError();
      console.log(error);
    })
  },
  [INVESTMENT_ADVICE.ACTIONS.FETCH_OUTCOME]({ commit, state, rootState, dispatch },  payload) { 
    if (process.env.NODE_ENV === 'development') {
      console.log('FETCH_OUTCOME: refresh ' + payload.refresh + ', type ' + payload.type + ', id ' + payload.adviceId);
    }     
    if (!payload.refresh)  {
      switch (payload.type) {
        case INVESTMENT_ADVICE.OUTCOME.BACKTEST_ADVICE:
          if (state.ergebnisBacktestAdvice && state.ergebnisBacktestAdvice.loaded) {
            commit(INVESTMENT_ADVICE.MUTATIONS.FETCH_OUTCOME_SUCCESS, {data: state.ergebnisBacktestAdvice.data, key:payload.type});
            return;
          }
          break;
        case  INVESTMENT_ADVICE.OUTCOME.BACKTEST_DETAILS:
          if (state.ergebnisBacktestDetails && state.ergebnisBacktestDetails.loaded) {
            commit(INVESTMENT_ADVICE.MUTATIONS.FETCH_OUTCOME_SUCCESS, {data: state.ergebnisBacktestDetails.data, key:payload.type});
            return;
          }
          payload.absolut = true;
          break;
        case  INVESTMENT_ADVICE.OUTCOME.BACKTEST_ACTUAL:
          if (state.ergebnisBacktestActual && state.ergebnisBacktestActual.loaded) {
            commit(INVESTMENT_ADVICE.MUTATIONS.FETCH_OUTCOME_SUCCESS, {data: state.ergebnisBacktestActual.data, key: payload.type});
            return;
          }
          break;
        case  INVESTMENT_ADVICE.OUTCOME.OPTIMIZATION:
          if (state.ergebnisDepotoptimierung && state.ergebnisDepotoptimierung.loaded && !payload.refresh) {
            commit(INVESTMENT_ADVICE.MUTATIONS.FETCH_OUTCOME_SUCCESS, {data: state.ergebnisDepotoptimierung.data, key: payload.type});
            return;
          }
          break;
      }  
    }
    
    const config = {
      defaultSpinner: true,
      id: payload.id
    };
    let refresh = payload.refresh && (INVESTMENT_ADVICE.OUTCOME.BACKTEST_ADVICE === payload.type || INVESTMENT_ADVICE.OUTCOME.OPTIMIZATION === payload.type);
    if (refresh) {
      commit(INVESTMENT_ADVICE.MUTATIONS.RESET_OUTCOME, {data: []});
    }
    const ignore = payload.ignoreHistory != undefined ? '&ignoreHistory=' + payload.ignoreHistory : '';
    const absolut = payload.absolut != undefined ? '&absolut=' + payload.absolut : '';
    const retPromise = axios.get(`${rootState.core.apiAddress}/investmentadvice/outcome?adviceId=${payload.adviceId}&type=${payload.type}&refresh=${refresh}&sendDetails=${payload.sendDetails}${ignore}${absolut}`, config).then(response => {
      if (response && response.data) {
        commit(INVESTMENT_ADVICE.MUTATIONS.FETCH_OUTCOME_SUCCESS, {data: response.data, key:payload.type});
        // if (response.data && response.data.needsUpdate) {
        //   commit(INVESTMENT_ADVICE.MUTATIONS.RESET_POSITIONS_OUTPUT, 'needsUpdate');
        // }
      }
    }).catch(error => {
      commit(INVESTMENT_ADVICE.MUTATIONS.FETCH_OUTCOME_FAILURE, {data: {}, key:payload.type});
    })
    return retPromise;
  },
  [INVESTMENT_ADVICE.ACTIONS.FETCH_DIAGRAMME]({ commit, state, rootState },  payload) {
    const config = {
      defaultSpinner: true
    };
    axios.get(`${rootState.core.apiAddress}/investmentadvice/diagramme?adviceId=${payload.adviceId}`, config)
    .then(response => {
      if(response.data?.anlageempfehlungId && state.investmentAdvice !== response.data.anlageempfehlungId) {
        commit(INVESTMENT_ADVICE.MUTATIONS.SET_INVESTMENT_ADVICE_SUCCESS, response.data.anlageempfehlungId);
      }
      commit(INVESTMENT_ADVICE.MUTATIONS.FETCH_DIAGRAMME,  response.data);
      
    }).catch(error => {
      payload.onError();
      console.log(error);
    })
  },
  [INVESTMENT_ADVICE.ACTIONS.CALC_ASSET_ALLOCATION]({ rootState }, payload) {
    const config = {
      defaultSpinner: true,
    };

    const params = Object.keys(payload)
      .map(key => `${key}=${payload[key]}`)
      .join('&');

    return new Promise((resolve, reject) => {
      axios.get(`${rootState.core.apiAddress}/investmentadvice/calcAssetAllocation?${params}`, config).then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
    });
  },
  
  [INVESTMENT_ADVICE.ACTIONS.CREATE_PDF]({rootState },  payload) {
    const config = {
      defaultSpinner: true,
    };
    return axios.post(`${rootState.core.apiAddress}/investmentadvice/investmentAdvicePdf?adviceId=${payload.adviceId}`, payload.params, config);
  },

  [INVESTMENT_ADVICE.ACTIONS.LOAD_LAST_DOC_INFO]({rootState },  payload) {
    const config = {
      defaultSpinner: true,
    };
    return axios.get(`${rootState.core.apiAddress}/investmentadvice/lastpdfinfo?adviceId=${payload}`, config);
  },

  [INVESTMENT_ADVICE.ACTIONS.LOAD_ISIN_WKN]({rootState },  payload) {
    const config = {
      defaultSpinner: true,
    };
    return axios.get(`${rootState.core.apiAddress}/investmentadvice/fondsData?isinWkn=${payload}`, config);
  },

  [INVESTMENT_ADVICE.ACTIONS.COPY_INVESTMENT_ADVICE]({rootState },  payload) {
    const config = {
      defaultSpinner: true,
    };
    const name = encodeURIComponent(payload.name);
    return axios.get(`${rootState.core.apiAddress}/investmentadvice/copy?adviceId=${payload.id}&name=${name}&changezweck=${payload.changeZweck}`, config);
  },

  [INVESTMENT_ADVICE.ACTIONS.GET_WERTSTEIGERUNG]({rootState },  payload) {
    const config = {
      defaultSpinner: true,
    };
    const name = encodeURIComponent(payload.name);
    return axios.get(`${rootState.core.apiAddress}/investmentadvice/wertsteigerung?adviceId=${payload.id}&wert=${payload.wertsteigerung}`, config);
  },

  [INVESTMENT_ADVICE.ACTIONS.SAVE_PLATTFORMEN]({rootState },  payload) {
    const config = {
      defaultSpinner: true,
    };
    return axios.post(`${rootState.core.apiAddress}/investmentadvice/plattformen?adviceId=${payload.id}`, payload.params, config);
  },

  [INVESTMENT_ADVICE.ACTIONS.MAPPE_INVESTMENT_ADVICE]({rootState },  payload) {
    const config = {
      defaultSpinner: true,
    };
    return axios.get(`${rootState.core.apiAddress}/investmentadvice/settomappe?adviceId=${payload.adviceId}&mappeId=${payload.mappeId}`, config);
  },

}