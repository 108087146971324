import MR_MONEY_TYPES from './types';
import { getInitialState } from './index'

export default {
  [MR_MONEY_TYPES.MUTATIONS.GET_LISTING_SUCCESS](state, payload) {
    state.listing = payload;
  }, 
  [MR_MONEY_TYPES.MUTATIONS.RESET_STATE](state, payload) {
    Object.assign(state, getInitialState())
  }, 
  [MR_MONEY_TYPES.MUTATIONS.SELECTED_ROW](state, payload) {
    Object.assign(state.selectedRow, payload)
  },  

  
  
}