var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasMenus
    ? _c(
        "SortableList",
        {
          attrs: { items: _vm.menus },
          on: {
            orderChanged: function($event) {
              return _vm.$emit("orderChanged", $event)
            }
          }
        },
        [
          _c(
            "ul",
            {
              staticClass: "option-menu-edit-modal__items list-bordered",
              attrs: { "data-sortable-container": _vm.isSortable }
            },
            _vm._l(_vm.menus, function(menu) {
              return _c("OptionMenuConfigItem", {
                key: (menu.id || "") + "-" + (menu.path || ""),
                staticClass: "list-bordered-item",
                attrs: {
                  configId: _vm.configId,
                  menu: menu,
                  userLevel: _vm.userLevel,
                  configContext: _vm.configContext,
                  hideParentLabel: _vm.hideParentLabel,
                  isAvailable: _vm.isAvailable,
                  isRemoveable: _vm.isRemoveable,
                  isToggleRemoveable: _vm.isToggleRemoveable,
                  isLockable: _vm.isLockable,
                  isSortable: _vm.isSortable,
                  isPermissionConfigurable: _vm.isPermissionConfigurable,
                  isExpandedLayout: _vm.isExpandedLayout,
                  isIndeterminate: _vm.isIndeterminate(menu)
                },
                on: {
                  add: function($event) {
                    return _vm.$emit("add", $event)
                  },
                  remove: function($event) {
                    return _vm.$emit("remove", $event)
                  },
                  lock: function($event) {
                    return _vm.$emit("lock", $event)
                  },
                  unlock: function($event) {
                    return _vm.$emit("unlock", $event)
                  },
                  toggleRemoveable: function($event) {
                    return _vm.$emit("toggleRemoveable", $event)
                  }
                }
              })
            }),
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }