var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.customerDataEdited
    ? _c(
        "div",
        [
          _vm.rows.length
            ? _c("Table", {
                ref: "table",
                attrs: {
                  tableId: _vm.tableId,
                  cardView: "",
                  headers: _vm.headers,
                  rows: _vm.rows,
                  headersSize: _vm.isCustomerOnly ? 0 : 5,
                  showWeiterLink: "",
                  rowsPerPage: _vm.isCustomerOnly ? 0 : 1,
                  hidePagination: ""
                },
                on: { onNavigate: _vm.navigateToCard }
              })
            : _c("NoData", { attrs: { noIcon: "" } })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }