var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(_vm.icon, {
        tag: "component",
        attrs: { size: _vm.iconSize },
        on: {
          click: function($event) {
            _vm.show = true
          }
        }
      }),
      _c("transition", { attrs: { name: "fade-fullscreen-panel" } }, [
        _vm.show
          ? _c(
              "div",
              {
                staticClass: "base-fullscreen-menu__content",
                on: { click: _vm.closeModal }
              },
              [
                _c(
                  "div",
                  { staticClass: "base-fullscreen-menu__list-contaner" },
                  [_vm._t("default")],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "base-fullscreen-menu__close" },
                  [
                    _c("ph-x", {
                      attrs: { size: 32 },
                      on: { click: _vm.closeModal }
                    })
                  ],
                  1
                )
              ]
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }