import PERS_GESCHAEFT_TYPES from './types';
import axios from 'axios';

const config = {
  defaultSpinner: true
};

export default {
  [PERS_GESCHAEFT_TYPES.ACTIONS.GET_FRAGEBOGEN]({ commit }, id) {
    axios.get(`${process.env.VUE_APP_API}/persoenlichesGeschaeft/getFragebogen/${id}`, config).then(response => {
      if (response.status === 200 && response.data) {
        commit(PERS_GESCHAEFT_TYPES.MUTATIONS.GET_FRAGEBOGEN_SUCCESS, response.data)
      }
    })
    .catch(error => {
    })
  },
  
  [PERS_GESCHAEFT_TYPES.ACTIONS.SAVE_FRAGEBOGEN]({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${process.env.VUE_APP_API}/persoenlichesGeschaeft/saveFragebogen`, payload, config).then(response => {
        if (response.status === 200 && response.data) {
          commit(PERS_GESCHAEFT_TYPES.MUTATIONS.GET_FRAGEBOGEN_SUCCESS, response.data);
          resolve(response.data);
        }
      })
      .catch(error => {
        reject();
      })
    });
  },

  [PERS_GESCHAEFT_TYPES.ACTIONS.REMOVE_FRAGEBOGEN_DOCUMENT]({ commit }, { id, fileType }) {
    if (id && fileType) {
      axios.delete(`${process.env.VUE_APP_API}/persoenlichesGeschaeft/removeFragebogenDocument`, config).then(response => {
        if (response.status === 200 && response.data) {
          commit(PERS_GESCHAEFT_TYPES.MUTATIONS.GET_FRAGEBOGEN_SUCCESS, response.data)
        }
      })
      .catch(error => {
      })
    }
  },

  [PERS_GESCHAEFT_TYPES.ACTIONS.GET_GESPEICHERTE_FRAGEBOGEN_LIST]({ commit }) {
    axios.get(`${process.env.VUE_APP_API}/persoenlichesGeschaeft/getSavedFragebogenList`, config).then(response => {
      if (response.status === 200 && response.data) {
        commit(PERS_GESCHAEFT_TYPES.MUTATIONS.GET_GESPEICHERTE_FRAGEBOGEN_LIST_SUCCESS, response.data)
      }
    })
    .catch(error => {
    })
  },

  [PERS_GESCHAEFT_TYPES.ACTIONS.REMOVE_FRAGEBOGEN]({ commit }, id) {
    if (id) {
      axios.delete(`${process.env.VUE_APP_API}/persoenlichesGeschaeft/removeFragebogen`, config).then(response => {
        if (response.status === 200 && response.data) {
          commit(PERS_GESCHAEFT_TYPES.MUTATIONS.GET_GESPEICHERTE_FRAGEBOGEN_LIST_SUCCESS, response.data)
        }
      })
      .catch(error => {
      })
    }
  },

  [PERS_GESCHAEFT_TYPES.ACTIONS.GET_OVERVIEW_LIST]({ commit, getters, state }, {pageIndex, limit}) {
    const filters = state.overviewFilters //getters[PERS_GESCHAEFT_TYPES.GETTERS.OVERVIEW_FILTERS] || null;
    axios.post(`${process.env.VUE_APP_API}/persoenlichesGeschaeft/getOverviewList`, { filters: filters, pageIndex: pageIndex || 0, limit: limit }, config).then(response => {
      if (response.status === 200 && response.data) {
        commit(PERS_GESCHAEFT_TYPES.MUTATIONS.GET_OVERVIEW_LIST_SUCCESS, response.data)
      }
    })
    .catch(error => {
    })
  },
  
  [PERS_GESCHAEFT_TYPES.ACTIONS.SAVE_BEMERKUNGEN]({ commit }, payload) {
    axios.post(`${process.env.VUE_APP_API}/persoenlichesGeschaeft/saveBemerkungen`, payload, config);
  },

  [PERS_GESCHAEFT_TYPES.ACTIONS.CONFIRM_SELECTION]({ commit, getters }, payload) {
    const filters = getters[PERS_GESCHAEFT_TYPES.GETTERS.OVERVIEW_FILTERS] || null;
    axios.post(`${process.env.VUE_APP_API}/persoenlichesGeschaeft/confirmSelection`, {selection: payload, filters: filters }, config).then(response => {
      if (response.status === 200 && response.data) {
        commit(PERS_GESCHAEFT_TYPES.MUTATIONS.GET_OVERVIEW_LIST_SUCCESS, response.data)
      }
    })
    .catch(error => {
    })
  },

}