<template>
  <div v-if="hasItems" class="autocomplete-result__container" :data-title="title">
    <div v-if="title" class="autocomplete-result--header fc-heading-small">
      <span class="autocomplete-result--title">{{ title }}</span>
      <Pill v-if="loading.length === 0" class="autocomplete-result--count" :label="total" type="default" />
    </div>
    <template>
      <ul class="autocomplete-result--items">
        <li 
          v-for="(item, index) in slicedResults" 
          :key="index" 
          class="autocomplete-result--item"
          @click="$emit('click', item.value)"
        >
          <div class="autocomplete-result--item-wrap">
            <div v-if="item.icon" class="autocomplete-result--item-icon">
              <component :is="item.icon.component" v-bind="item.icon.props" />
            </div>
            <div class="autocomplete-result--item-content">
              <div v-if="item.title || item.subtitle" class="autocomplete-result--item-title">
                <span v-if="item.title" v-html="sanitize(item.title)" />
                <template v-if="item.subtitle">
                  (<span v-html="sanitize(item.subtitle)" class="text-small" />)
                </template>
              </div>
              <div v-if="item.description" class="autocomplete-result--item-description text-small" v-html="sanitize(item.description)" />
            </div>
          </div>
          <PhArrowSquareOut v-if="navigationType === NavigationType.NewWindow" class="autocomplete-result--item-navigation-icon" :size="18" />
          <PhCaretRight v-else-if="navigationType === NavigationType.CurrentWindow" class="autocomplete-result--item-navigation-icon" :size="18" />
        </li>
      </ul>
      <div v-if="hasMoreItems" class="autocomplete-result--actions">
        <div class="autocomplete-result--show-more" :class="{ 'active': isShowingMore, }">
          <BaseButton 
            isClear 
            class="text-small" 
            @click="toggleShowMore()" 
          >
            <PhCaretDown class="autocomplete-result--show-more-icon" :size="16" /> {{ isShowingMore ? 'Weniger' : 'Mehr' }} sehen
          </BaseButton>
        </div>

        <div v-if="showAdvancedSearch"  class="autocomplete-result--advanced-search">
          <BaseButton 
            isLink 
            class="text-small" 
            @click="$emit('advancedSearch')" 
          >Erweiterte Suche <PhCaretRight :size="16" /></BaseButton>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { PhCaretRight, PhCaretUp, PhCaretDown, PhArrowSquareOut } from 'phosphor-vue';
import Pill from '@/components/core/Pill.vue';
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';
import BaseButton from '@/components/core/BaseButton.vue';

import { sanitize } from '@/helpers/string-helper';

const DEFAULT_LIMIT = 5;

const NavigationType = {
  NewWindow: 'NewWindow',
  CurrentWindow: 'CurrentWindow',
};

export function ResultItemBuilder(result) {
  const item = {
    titles: [],
    subtitles: [],
    descriptions: [],
  };

  const add = (prop, value, color = 'inherit') => {
    const trimmed = `${(value ?? '')}`.trim();
    if (prop in item && trimmed) {
      item[prop].push({
        value: trimmed,
        color,
      });
    }
  };

  const join = (prop, separator = ' ') => {
    if (item?.[prop]?.length > 0) {
      return item[prop]
        .map(propValue => `<span style="color:${propValue.color}">${propValue.value}</span>`)
        .join(separator);
    }
    return undefined;
  };

  return {
    withIcon(component, props = {}) {
      item.icon = { component, props };
      return this;
    },
    addTitle(value, color) {
      add('titles', value, color);
      return this;
    },
    addSubtitle(value, color) {
      add('subtitles', value, color);
      return this;
    },
    addDescription(value, color) {
      add('descriptions', value, color);
      return this;
    },
    build() {
      return {
        value: result,
        icon: item?.icon || undefined,
        title: join('titles'),
        subtitle: join('subtitles'),
        description: join('descriptions', ', '),
      };
    },
  };
}

export default {
  name: 'AutocompleteResult',
  components: {
    PhCaretRight, 
    PhCaretUp, 
    PhCaretDown, 
    PhArrowSquareOut, 
    Pill, 
    AnimatedSpinner, 
    BaseButton, 
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    results: {
      type: Array,
      default: () => [],
    },
    limit: {
      type: Number,
      default: DEFAULT_LIMIT,
    },
    navigationType: {
      type: String,
      default: '',
    },
    showAdvancedSearch: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      NavigationType,
      showMoreLimit: null,
    };
  },
  computed: {
    hasItems() {
      return this.total > 0;
    },
    total() {
      return this.results?.length || 0;
    },
    hasMoreItems() {
      return this.total > this.limit;
    },
    slicedResults() {
      return [ ...this.results.slice(0, this.showMoreLimit ?? this.limit) ];
    },
    isShowingMore() {
      return this.showMoreLimit === this.total;
    },
  },
  watch: {
    results() {
      this.$set(this, 'showMoreLimit', null);
    },
  },
  methods: {
    sanitize(html) {
      return sanitize(html);
    },
    toggleShowMore() {
      this.$set(this, 'showMoreLimit', this.isShowingMore ? null : this.total);
    },
  },
}
</script>

<style lang="scss">
.autocomplete-result__container {
  border-top: 1px solid #ccc;
  padding: 0.5rem 0 0;
  margin: 0.5rem 0 0.325rem;

  &:last-child {
    margin-bottom: 0;
  }

  .autocomplete-result--header {
    .autocomplete-result--title {
      font-weight: bold;
      font-size: 0.7rem;
      line-height: 1.2em;
      text-transform: uppercase;
    }
  
    .autocomplete-result--count {
      border-color: #999;
      color: #999;
      display: inline-block;
      font-size: 0.7rem;
      line-height: 1rem;
      margin: 0 0 0 0.25rem;
      padding: 0 0.25rem;
    }
  }

  .autocomplete-result--items {
    list-style: none;
    margin: 0.325rem 0 0.125rem;
    padding: 0;

    .autocomplete-result--item {
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      margin: 0 -0.25rem;
      padding: 0.25rem;
      transition: background-color .3s ease;

      &:hover {
        background-color: var(--color-box-neutral);
      }

      .autocomplete-result--item-wrap {
        display: flex;

        .autocomplete-result--item-icon {
          border-radius: 4px;
          flex: 0 0 auto;
          margin: 0 0.5rem 0 0;
          width: 40px;
          height: 40px;

          > *,
          img {
            border-radius: 4px;
            max-width: 100%;
            max-height: 100%;
          }
        }

        .autocomplete-result--item-title {
          font-weight: 600;
          line-height: 1.2em;
          margin: 0 0 0.125rem;
        }

        .autocomplete-result--item-description {
          color: #666;
          line-height: 1.2em;
        }
      }

      .autocomplete-result--item-navigation-icon {
        flex: 0 0 auto;
        margin: 0 0 0 0.5rem;
      }
    }
  }

  .autocomplete-result--actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .button {
      margin: 0;
      padding-right: 0;
      padding-left: 0;
    }

    > div {
      display: flex;
      flex: 1 0 auto;

      svg {
        margin: -2px 0 0;
      }
    }

    .autocomplete-result--show-more {
      margin: 0 16px 0 0;

      .autocomplete-result--show-more-icon {
        transition: transform .3s ease;
      }
  
      &.active {
        .autocomplete-result--show-more-icon {
          transform: rotate(-180deg);
        }
      }
    }

    .autocomplete-result--advanced-search {
      justify-content: end;
    }
  }
}
</style>
