var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: "Erwartete Courtage", actions: _vm.headerActions },
        on: {
          "action-BERECHNEN": function($event) {
            return _vm.berechnen()
          },
          "action-PLATTFORM": function($event) {
            return _vm.selectPlattform()
          }
        }
      }),
      _c("HinweiseUndFehler", { attrs: { hints: _vm.hints } }),
      !_vm.loading
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("InputField", {
                attrs: {
                  label: "Berechnete Wertsteigerung p.a.",
                  isComponentHalfSize: true
                },
                model: {
                  value: _vm.wertsteigerung,
                  callback: function($$v) {
                    _vm.wertsteigerung = $$v
                  },
                  expression: "wertsteigerung"
                }
              }),
              _c("InputToggleSwitch", {
                attrs: {
                  inLineLabel: "",
                  label: "jeweilige Jahrescourtagen anzeigen (Jc)"
                },
                model: {
                  value: _vm.inclJahrescourtage,
                  callback: function($$v) {
                    _vm.inclJahrescourtage = $$v
                  },
                  expression: "inclJahrescourtage"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.loading
            ? _c("GhostLoading", [_c("Block", { attrs: { height: "100" } })], 1)
            : _vm.rows.length
            ? _c("Table", {
                attrs: {
                  title: _vm.title,
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowId: "Wertpapier",
                  rowsPerPage: 25,
                  headerActions: _vm.tblActions
                },
                on: { headerAction: _vm.onChangeJahr },
                scopedSlots: _vm._u([
                  {
                    key: "JAHR-AUSWAHL",
                    fn: function() {
                      return _vm._l(_vm.years, function(year) {
                        return _c(
                          "div",
                          { key: year.key, staticClass: "mt-2 mb-2" },
                          [
                            _c("BaseButton", {
                              class: { "text-bold": _vm.jahre == year.key },
                              attrs: { label: year.label, isClear: true },
                              on: {
                                click: function($event) {
                                  _vm.jahre = year.key
                                }
                              }
                            })
                          ],
                          1
                        )
                      })
                    },
                    proxy: true
                  }
                ])
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "showPlattform",
          attrs: {
            labelButtonConfirm: "Speichern",
            labelButtonCancel: "Abbrechen"
          },
          on: { onConfirmButton: _vm.onChangePlattform },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [_vm._v(" Plattformen ")]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "div",
            _vm._l(_vm.plattformen, function(item) {
              return _c(
                "div",
                { key: item[0] },
                [
                  _c("InputToggleSwitch", {
                    staticClass: "plattform",
                    attrs: { inLineLabel: "", label: item[0] },
                    model: {
                      value: item[1],
                      callback: function($$v) {
                        _vm.$set(item, 1, $$v)
                      },
                      expression: "item[1]"
                    }
                  })
                ],
                1
              )
            }),
            0
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }