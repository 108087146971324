var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "modal",
    attrs: {
      size: "sm",
      modalTitle: "Einstellung Details",
      confirmDisabled: _vm.isFormInvalid
    },
    on: {
      onConfirmButton: function($event) {
        return _vm.emitChanged()
      },
      close: function($event) {
        return _vm.onClose()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function() {
          return [
            _c("InputField", {
              attrs: { label: "Titel", autofocus: "", validateUntouched: "" },
              model: {
                value: _vm.configDefinitionForm.label,
                callback: function($$v) {
                  _vm.$set(_vm.configDefinitionForm, "label", $$v)
                },
                expression: "configDefinitionForm.label"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }