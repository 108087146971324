var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("HeaderLogin"),
      _c(
        "div",
        {
          staticClass: "register-terms-content-page p-24px",
          attrs: { "data-app-content": "" }
        },
        [
          _c("TermsContent", {
            staticClass: "m-24px",
            attrs: { registerCode: _vm.registerCode, hideMenu: "" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }