var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _c("div", { staticClass: "box__title" }, [
        _vm._v("Jahrescheckliste Status")
      ]),
      _c("FormDivider"),
      _c("InputToggleSwitch", {
        attrs: {
          label: "Status OK",
          inLineLabel: true,
          isComponentHalfSize: "",
          value: _vm.form.INPUT_STATUS_OK,
          disabled: _vm.form.INPUT_STATUS_FEHLER
        },
        on: {
          input: function($event) {
            return _vm.fieldChanged($event, "INPUT_STATUS_OK")
          }
        }
      }),
      _c("DatePickerField", {
        attrs: {
          label: "Datum",
          value: _vm.form.INPUT_STATUS_OK_DATUM,
          isValueAsString: "",
          isComponentHalfSize: "",
          disabled: !_vm.form.INPUT_STATUS_OK
        },
        on: {
          change: function($event) {
            return _vm.fieldChanged($event, "INPUT_STATUS_OK_DATUM")
          }
        }
      }),
      _c("InputToggleSwitch", {
        attrs: {
          label: "Status fehlerhaft",
          inLineLabel: true,
          isComponentHalfSize: "",
          value: _vm.form.INPUT_STATUS_FEHLER,
          disabled: _vm.form.INPUT_STATUS_OK
        },
        on: {
          input: function($event) {
            return _vm.fieldChanged($event, "INPUT_STATUS_FEHLER")
          }
        }
      }),
      _c("InputField", {
        attrs: {
          value: _vm.form.INPUT_STATUS_FEHLER_TEXT,
          label: "Notizen:",
          isComponentHalfSize: ""
        },
        on: {
          change: function($event) {
            return _vm.fieldChanged($event, "INPUT_STATUS_FEHLER_TEXT")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }