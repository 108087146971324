export const MUTATION_PREFIX = 'PORTFOLIO_MUTATIONS_';
export const ACTIONS_PREFIX = 'PORTFOLIO_ACTIONS_';
export const GETTERS_PREFIX = 'PORTFOLIO_GETTERS_';

export default {
  MUTATIONS: {
    GET_FAILTURE: MUTATION_PREFIX + 'GET_FAILTURE',
    GET_DEPOT_CONFIG_SUCCESS: MUTATION_PREFIX + 'GET_DEPOT_CONFIG_SUCCESS',
    GET_REALISED_PROFIT_SUCCESS: MUTATION_PREFIX + 'GET_REALISED_PROFIT_SUCCESS',
    GET_HISTORICAL_PERFORMANCE_SUCCESS: MUTATION_PREFIX + 'GET_HISTORICAL_PERFORMANCE_SUCCESS',
    GET_PERFORMANCE_FILTERED_SUCCESS: MUTATION_PREFIX + 'GET_PERFORMANCE_FILTERED_SUCCESS',
    GET_HISTORICAL_PERFORMANCE_DETAILS_SUCCESS: MUTATION_PREFIX + 'GET_HISTORICAL_PERFORMANCE_DETAILS_SUCCESS',
    SET_IS_LOADED: MUTATION_PREFIX + 'SET_IS_LOADED',
  },
  ACTIONS: {
    GET_DEPOT_CONFIG: ACTIONS_PREFIX + 'GET_DEPOT_CONFIG',
    GET_REALISED_PROFIT: ACTIONS_PREFIX + 'GET_REALISED_PROFIT',
    GET_HISTORICAL_PERFORMANCE: ACTIONS_PREFIX + 'GET_HISTORICAL_PERFORMANCE',
    GET_PERFORMANCE_FILTERED: ACTIONS_PREFIX + 'GET_PERFORMANCE_FILTERED',
    GET_HISTORICAL_PERFORMANCE_DETAILS: ACTIONS_PREFIX + 'GET_HISTORICAL_PERFORMANCE_DETAILS',
  },
  GETTERS: {
    DEPOT_CONFIG: GETTERS_PREFIX + 'DEPOT_CONFIG',
    REALISED_PROFIT: GETTERS_PREFIX + 'REALISED_PROFIT',
    HISTORICAL_PERFORMANCE: GETTERS_PREFIX + 'HISTORICAL_PERFORMANCE',
    PERFORMANCE_FILTERED: GETTERS_PREFIX + 'PERFORMANCE_FILTERED',
    HISTORICAL_PERFORMANCE_DETAILS: GETTERS_PREFIX + 'HISTORICAL_PERFORMANCE_DETAILS',
    IS_LOADED: GETTERS_PREFIX + 'IS_LOADED',
  }
}