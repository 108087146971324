<template>
  <BaseButton 
    isClear
    class="nav-item clickable" 
    :class="{ 'nav-item-bold': navItem.textBold, }"
    @click="handleNavigationAction(navItem)"
  >
    <span v-if="navItem.iconComponent" class="nav-item--icon"><component :is="navItem.iconComponent" :size="16" /></span>
    <span class="nav-item--label" v-html="sanitize(navItem.label)"></span>
    <span class="nav-item--arrow-icon"><PhCaretRight :size="18" /></span>
  </BaseButton>
</template>

<script>
import BaseButton from '@/components/core/BaseButton.vue';
import { PhCaretRight } from "phosphor-vue";

import { sanitize } from '@/helpers/string-helper';

export default {
  components: {
    BaseButton,
    PhCaretRight,
  },
  props: {
    navItem: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    sanitize(html) {
      return sanitize(html);
    },
    handleNavigationAction(item) {
      if (item.action && !item.disabled) {
        item.action();
      }
    },
  },
}
</script>

<style scoped>
.nav-item {
  display: flex;
  align-items: center;
}
.nav-item-bold {
  text-shadow: 0 0 1px var(--color-text);
}
.nav-item--label {
  flex: 10 1 auto;
}
.nav-item--icon {
  display: flex;
  margin: 2px 4px 0 0;
}
.nav-item--arrow-icon {
  display: none;
  flex: 0 0 auto;
}

@media screen and (max-width: 768px) {
  .nav-item--arrow-icon {
    display: flex;
  }
}
</style>
