function setGroups(components) {
  let componentGroups = [];
  if (components !== undefined && components !== null){
    let distinctGenuses = [...new Set(components.map(component => component.genus))];
    for (let genusName of distinctGenuses) {
      if (genusName !== null) {
        let allSpeciesOfGenus = components.filter(component => component.genus === genusName);

        let distinctSpeciess = [...new Set(allSpeciesOfGenus.map(genComponent => genComponent.species))];
        let genus = {};
        genus.name = genusName;
        genus.species = [];
        for (let speciesName of distinctSpeciess) {
          if (speciesName !== null) {
            let speciesArr = allSpeciesOfGenus.filter(specComponent => specComponent.species === speciesName);
            let species = {};
            species.name = speciesName;
            species.types = speciesArr;
            genus.species.push(species);
          }
        }
        componentGroups.push(genus);
      }
    }
  }
  return componentGroups;
}



import CMS_TYPES from './types';
import { getInitialState } from './index';
import Vue from 'vue';

const DEFAULT_FIRST_PAGE_ROUTE = '/home';

export default {
  [CMS_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },

  [CMS_TYPES.MUTATIONS.SET_CMS_CONTENT](state, payload) {
    state.template = payload.maklerCmsTemplateString;
    state.components = payload.maklerCmsComponentsList;
    state.componentsReady = true;
    state.portfolios = payload.cmsPortfolioList;
    Vue.set(state, 'firstPageRoute', payload.hasOwnProperty('firstPageRoute') ? payload.firstPageRoute : DEFAULT_FIRST_PAGE_ROUTE);

    state.componentGroups = setGroups(payload.maklerCmsComponentsList);
  },
  [CMS_TYPES.MUTATIONS.SET_CMS_COMPONENT_CURRENT_CONTENT](state, payload) {
    state.componentCurrentContent = payload;
  },
  [CMS_TYPES.MUTATIONS.SET_CMS_COMPONENT_CONTENT_UPDATED](state, payload) {
    state.componentContentUpdated = payload;
  },
  [CMS_TYPES.MUTATIONS.SET_CMS_UPDATE_COMPONENT_CONTENT](state, payload) {
    state.components.find(x => x.type == payload.type).inhalt = payload.inhalt
  },
  [CMS_TYPES.MUTATIONS.SET_USER_ID](state, payload) {
    state.userID = payload;
  },
  [CMS_TYPES.MUTATIONS.SET_CMS_NEWS](state, payload) {
    state.cmsNews = payload;
  },
  [CMS_TYPES.MUTATIONS.SET_PRIVACY_LINK](state, payload) {
    state.privacyLink = payload;
  },
  /* [CMS_TYPES.MUTATIONS.SET_SELECTED_FOND](state, payload) {
    let thisgenus = state.componentGroups.find(cg => cg.name == payload.genus);
    let thisspecies = thisgenus.species.find(sp => sp.name == payload.species);
    let type = thisspecies.types.find(typ => typ.type.includes("link"));
    state.selectedFond = type.inhalt;
  }, */
  [CMS_TYPES.MUTATIONS.SET_SELECTED_FOND](state, payload) {
    state.selectedFond = payload;
  },
  [CMS_TYPES.MUTATIONS.SET_SELECTED_TEMPLATE](state, payload) {
    state.template = payload.template;
    state.firstPageRoute = payload.firstPage;
  },

  


  [CMS_TYPES.MUTATIONS.SET_EXISTING_INSURANCES](state, payload) {
    state.existingInsurances = payload;
  },
  [CMS_TYPES.MUTATIONS.SET_TABLE_INSURANCES](state, payload) {
    state.tablerecords = payload;
  },
  [CMS_TYPES.MUTATIONS.SET_RECORDS_READY](state, payload) {
    state.areRecordsReady = payload;
  },
  [CMS_TYPES.MUTATIONS.SET_TARIFF_DETAILS](state, payload) {
    state.tariffDetails = payload;
  },
  [CMS_TYPES.MUTATIONS.SET_TARIFF_DETAILS_READY](state, payload) {
    state.areTariffDetailsReady = payload;
  },

  [CMS_TYPES.MUTATIONS.SET_FONDSSHOP_PARAMS](state, payload) {
    state.fondsshopParams = payload;
  },


}