export const MUTATION_PREFIX = 'GESELLSCHAFT: ';
export const ACTIONS_PREFIX = 'GESELLSCHAFT_ACTIONS_';
export const GETTERS_PREFIX = 'GESELLSCHAFT_GETTERS_';

export default {
  MUTATIONS: {
    SET_GESELLSCHAFT: MUTATION_PREFIX + 'SET_GESELLSCHAFT',
    GET_EIGENE_DOKUMENTE_SUCCESS: MUTATION_PREFIX + 'GET_EIGENE_DOKUMENTE_SUCCESS',
    GET_GESELLSCHAFT_DATEN_SUCCESS: MUTATION_PREFIX + 'GET_GESELLSCHAFT_DATEN_SUCCESS',
    GET_GESELLSCHAFT_LESEZEICHEN_SUCCESS: MUTATION_PREFIX + 'GET_GESELLSCHAFT_LESEZEICHEN_SUCCESS',
    GET_GESELLSCHAFT_EINSTELLUNGEN_SUCCESS: MUTATION_PREFIX + 'GET_GESELLSCHAFT_EINSTELLUNGEN_SUCCESS',
    GET_GESELLSCHAFT_MENU_SUCCESS: MUTATION_PREFIX + 'GET_GESELLSCHAFT_MENU_SUCCESS',
    GET_GESELLSCHAFT_AENDERUNGSLOG_SUCCESS: MUTATION_PREFIX + 'GET_GESELLSCHAFT_AENDERUNGSLOG_SUCCESS',
    GET_GESELLSCHAFT_NUMMERN_SUCCESS: MUTATION_PREFIX + 'GET_GESELLSCHAFT_NUMMERN_SUCCESS',
    GET_GESELLSCHAFT_MEHRFACHPOSITIONEN_SUCCESS: MUTATION_PREFIX + 'GET_GESELLSCHAFT_MEHRFACHPOSITIONEN_SUCCESS',
    GET_GESELLSCHAFT_VERS_SPARTEN_SUCCESS: MUTATION_PREFIX + 'GET_GESELLSCHAFT_VERS_SPARTEN_SUCCESS',
    GET_GESELLSCHAFT_PROVISION_VORGABEN_SUCCESS: MUTATION_PREFIX + 'GET_GESELLSCHAFT_PROVISION_VORGABEN_SUCCESS',
    GET_GESELLSCHAFT_FONDSAUFTRAG_SUCCESS: MUTATION_PREFIX + 'GET_GESELLSCHAFT_FONDSAUFTRAG_SUCCESS',
    GET_GESELLSCHAFT_AKTIVITAETEN_SUCCESS: MUTATION_PREFIX + 'GET_GESELLSCHAFT_AKTIVITAETEN_SUCCESS',
    GET_GESELLSCHAFT_KENNUNGEN_SUCCESS: MUTATION_PREFIX + 'GET_GESELLSCHAFT_KENNUNGEN_SUCCESS',
    GET_GESELLSCHAFT_DOKUMENTE_SUCCESS: MUTATION_PREFIX + 'GET_GESELLSCHAFT_DOKUMENTE_SUCCESS',
    GET_GESELLSCHAFT_ANTRAGSBEARBEITUNG_SUCCESS: MUTATION_PREFIX + 'GET_GESELLSCHAFT_ANTRAGSBEARBEITUNG_SUCCESS',
    GET_GESELLSCHAFT_BEMERKUNGEN_SUCCESS: MUTATION_PREFIX + 'GET_GESELLSCHAFT_BEMERKUNGEN_SUCCESS',
    GET_GESELLSCHAFTS_INFOS_SUCCESS: MUTATION_PREFIX + 'GET_GESELLSCHAFTS_INFOS_SUCCESS',

    GET_GESELLSCHAFT_ID_LOGGED_USER_SUCCESS: MUTATION_PREFIX + 'GET_GESELLSCHAFT_ID_LOGGED_USER_SUCCESS',

    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
  },
  ACTIONS: {
    SAVE_USER_PICTURE: ACTIONS_PREFIX + 'SAVE_USER_PICTURE',
    GET_EIGENE_DOKUMENTE: ACTIONS_PREFIX + 'GET_EIGENE_DOKUMENTE',

    GET_GESELLSCHAFT_ID_LOGGED_USER: ACTIONS_PREFIX + 'GET_GESELLSCHAFT_ID_LOGGED_USER',
    GET_GESELLSCHAFT_DATEN: ACTIONS_PREFIX + 'GET_GESELLSCHAFT_DATEN',
    GET_GESELLSCHAFT_LESEZEICHEN: ACTIONS_PREFIX + 'GET_GESELLSCHAFT_LESEZEICHEN',
    GET_GESELLSCHAFT_EINSTELLUNGEN: ACTIONS_PREFIX + 'GET_GESELLSCHAFT_EINSTELLUNGEN',
    GET_GESELLSCHAFT_MENU: ACTIONS_PREFIX + 'GET_GESELLSCHAFT_MENU',
    GET_GESELLSCHAFT_AENDERUNGSLOG: ACTIONS_PREFIX + 'GET_GESELLSCHAFT_AENDERUNGSLOG',
    GET_GESELLSCHAFT_NUMMERN: ACTIONS_PREFIX + 'GET_GESELLSCHAFT_NUMMERN',
    GET_GESELLSCHAFT_MEHRFACHPOSITIONEN: ACTIONS_PREFIX + 'GET_GESELLSCHAFT_MEHRFACHPOSITIONEN',
    GET_GESELLSCHAFT_VERS_SPARTEN: ACTIONS_PREFIX + 'GET_GESELLSCHAFT_VERS_SPARTEN',
    GET_GESELLSCHAFT_PROVISION_VORGABEN: ACTIONS_PREFIX + 'GET_GESELLSCHAFT_PROVISION_VORGABEN',
    GET_GESELLSCHAFT_FONDSAUFTRAG: ACTIONS_PREFIX + 'GET_GESELLSCHAFT_FONDSAUFTRAG',
    GET_GESELLSCHAFT_AKTIVITAETEN: ACTIONS_PREFIX + 'GET_GESELLSCHAFT_AKTIVITAETEN',
    GET_GESELLSCHAFT_KENNUNGEN: ACTIONS_PREFIX + 'GET_GESELLSCHAFT_KENNUNGEN',
    GET_GESELLSCHAFT_DOKUMENTE: ACTIONS_PREFIX + 'GET_GESELLSCHAFT_DOKUMENTE',
    GET_GESELLSCHAFT_ANTRAGSBEARBEITUNG: ACTIONS_PREFIX + 'GET_GESELLSCHAFT_ANTRAGSBEARBEITUNG',
    GET_GESELLSCHAFT_BEMERKUNGEN: ACTIONS_PREFIX + 'GET_GESELLSCHAFT_BEMERKUNGEN',
    GET_GESELLSCHAFTS_INFOS: ACTIONS_PREFIX + 'GET_GESELLSCHAFTS_INFOS',
  },
  GETTERS: {
    GET_GESELLSCHAFT: GETTERS_PREFIX + 'GET_GESELLSCHAFT',

    GET_GESELLSCHAFT_ID_LOGGED_USER: GETTERS_PREFIX + 'GET_GESELLSCHAFT_ID_LOGGED_USER',
    GESELLSCHAFTS_INFOS: GETTERS_PREFIX + 'GESELLSCHAFTS_INFOS',
  }
}
