var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    [
      _vm.isCollapsed
        ? _c(
            "span",
            {
              staticClass: "mr-3",
              on: {
                click: function($event) {
                  return _vm.toggleCollapsed()
                }
              }
            },
            [_c("ph-plus", { attrs: { size: 16 } })],
            1
          )
        : _vm._e(),
      !_vm.isCollapsed
        ? _c(
            "span",
            {
              staticClass: "mr-3",
              on: {
                click: function($event) {
                  return _vm.toggleCollapsed()
                }
              }
            },
            [_c("ph-minus", { attrs: { size: 16 } })],
            1
          )
        : _vm._e(),
      _c("span", { staticClass: "router-node__label" }, [
        _vm._v(_vm._s(_vm.nodePath))
      ]),
      _c("span", { staticClass: "router-node__label font-strong" }, [
        _vm._v(_vm._s(_vm.nodeMenuLabel))
      ]),
      !_vm.menuHasIcon
        ? _c("span", { staticClass: "router-node__label color-danger" }, [
            _vm._v(" Missing Icon ")
          ])
        : _vm._e(),
      !_vm.isCollapsed
        ? [
            _c("div", { staticClass: "router-node__roles--allowed" }, [
              _vm._v(_vm._s(_vm.rolesAllowed))
            ]),
            _c("div", { staticClass: "router-node__roles--denied" }, [
              _vm._v(_vm._s(_vm.rolesDenied))
            ]),
            _vm.node.subMenu && _vm.node.subMenu.length
              ? _c(
                  "ul",
                  _vm._l(_vm.node.subMenu, function(child, index) {
                    return _c("RouterNode", {
                      key: index,
                      attrs: { allCollapsed: _vm.allCollapsed, node: child }
                    })
                  }),
                  1
                )
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }