var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: "Gesellschaften", defaultMenu: _vm.customOptionMenu }
      }),
      _vm.gesellschaftenList && _vm.gesellschaftenList.length
        ? _c(
            "div",
            {
              staticClass: "box__container",
              attrs: { tid: _vm._generateTidFromString("box__container") }
            },
            [
              _c("div", { staticClass: "box__title" }, [
                _vm._v(_vm._s(_vm.currentMenu.label))
              ]),
              _c("ComboBox", {
                attrs: {
                  isComponentHalfSize: "",
                  firstEmpty: "",
                  value: _vm.gesellschaft,
                  values: _vm.gesellschaftenList
                },
                on: {
                  change: function($event) {
                    return _vm.onChange($event)
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.ansprechpartnerList && _vm.ansprechpartnerList.length
        ? _c(
            "div",
            {
              staticClass: "box__container",
              attrs: {
                tid: _vm._generateTidFromString(
                  "cards__container layout__negative-margin--8"
                )
              }
            },
            [
              _c("Table", {
                attrs: {
                  tableId: "0eca709b-022c-4078-871f-633007348bae",
                  tableData: _vm.tableData,
                  cardViewEnabled: false,
                  exportEnabled: false,
                  rowsPerPage: 20,
                  paginationEnabled: true,
                  showCheckBoxBulkAction: ""
                },
                on: { selectedRows: _vm.onSelectedRows }
              }),
              _c(
                "BaseButton",
                {
                  attrs: {
                    disabled:
                      _vm.selectedAnsprechpartner &&
                      !_vm.selectedAnsprechpartner.length
                  },
                  on: {
                    click: function($event) {
                      return _vm.addAnsprechpartner()
                    }
                  }
                },
                [_vm._v(" Markierte hinzufügen ")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }