<template>
  <div class="besprochene-item-component">
    <template v-for="(field, index) in item">
      <InputField :key="index"
        v-if="field.type === 'TEXT' && field.hidden && !field.hidden.IMMER"
        :label="field.label || '...'"
        :value="data[field.id]"
        :disabled="disabled[field.id]"
        @change="handleInputChange(field, $event)" />

      <InputTextArea :key="index"
        v-if="field.type === 'TEXT_AREA'"
        :label="field.label"
        v-model="data[field.id]"
        :disabled="disabled[field.id]"
        @change="handleInputChange(field, $event)" />
     
      <InputToggleSwitch :key="index"
        v-if="field.type === 'SWITCHER'"
        :label="field.label"
        v-model="data[field.id]"
        inLineLabel
        :disabled="disabled[field.id]"
        @change="handleInputChange(field, $event)" />

      <InputRadioBoxGroup :key="index"
        v-if="field.type === 'SINGLE_SELECTION'"
        :title="field.label"
        :value="data[field.id]"
        :values="field.values"
        :disabled="disabled[field.id]"
        @input="handleInputChange(field, $event)" />

      <FormWertpapierAuswahl :key="index"
        v-if="field.type === 'WERTPAPIER_AUSWAHL'"
        :title="field.label"
        :antragId="antragId"
        :antragTyp="antragTyp"
        :config="field.config"
        :categoryId="field.config && field.config.INPUT_ISIN_CHOOSER"
        :suppressFilterLagerstelle="true"
        :disabled="isDataReadOnly"
      />
      
      <WertpapierAuswahlUnique :key="index"
        v-if="field.type === 'WERTPAPIER_AUSWAHL_UNIQUE_WITHOUT_POSITION'"
        :id="field.id"
        :label="field.label"
        :data="data" :antragId="antragId" :config="field.config"
        :disabled="isDataReadOnly"
        @openFondsfinder="openFondsfinder(field)"
        @removePosition="removePosition(field, $event)"
        @updateIsin="$emit('updateIsin', $event)" />

      <BaseButton :key="index"
        v-if="field.type === 'BUTTON'"
        isSecondary
        class="mr-3"
        :disabled="disabled[field.id]"
        v-fc-tooltip="$fcOptionalChain(field, 'config.tooltip')"
        @click="handleAction(field)">
        {{ field.label }}
      </BaseButton>
    </template>
    <FormDivider />
  </div>
</template>

<script>
import InputField from '@/components/core/forms/InputField.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import FormWertpapierAuswahl from '@/components/core/forms/antraege/wertpapierAuswahl/FormWertpapierAuswahl.vue';
import WertpapierAuswahlUnique from '@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlUnique.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import FormDivider from '@/components/core/forms/FormDivider.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import {wpListeMixin} from '@/mixins/wp-suche.js';
import antragNavigationMixin from '@/mixins/antrag/antrag-navigation-mixin.js';

export default {
  mixins: [antragNavigationMixin, wpListeMixin],
  props: {
    antragId: {
      type: String
    },
    antragTyp: {
      type: String,
      default: 'UNBEKANNT'
    },
    item: {
      type: Array,
      default: []
    },
    data: {
      type: Object,
      default: {}
    },
    isDataReadOnly: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change', 'save'],
  data() {
    return {
      disabled: {},
    };
  },
  methods: {
    prepareItem() {
      this.item.forEach((field) => {
        // Disabled
        this.disabled[field.id] = this.isDisabled(field) || this.isDataReadOnly;
      });
    },
    isDisabled(field) {
      return Object.keys(field.disabled)
        .some((key) => key === 'IMMER' || field.disabled[key].some((value) => value === this.data[key]));
    },
    isFieldIdIsinChoosen(id) {
      return id && (/INPUT_WPB_FONDS_WKN_ISIN_CHOOSER/gmi).test(id);
    },
    isValidIsin(value) {
      return value && (/^[A-Za-z]{2}[0-9]{10}$/).test(value);
    },
    handleInputChange(field, value) {
      if(!field) {
        return ;
      }

      if(this.isFieldIdIsinChoosen(field.id)) {
        this.handleIsinChange(field, value);
      } else {
        this.$emit('change', {
          [field.id]: value,
        });
      }
    },
    handleIsinChange(field, value) {
      if(!field || !value) {
        return ;
      }

      if(this.isFieldIdIsinChoosen(field.id) && this.isValidIsin(value)) {
        const values = {
          INTERNAL_ATTRIBUTE_ACTION: field.config['INTERNAL_ATTRIBUTE_ACTION'],
          [field.config['INTERNAL_ATTRIBUTE_ACTION']]: value
        };

        this.$emit('change', values);
        this.$emit('save', false);
      }
    },
    handleAction(field) {
      if(field.type === 'BUTTON' && field.config && !this.isDataReadOnly) {
        const values = {
          INTERNAL_ATTRIBUTE_ACTION: field.config['INTERNAL_ATTRIBUTE_ACTION'],
        };

        if(field.config['DELETE_SICHER']) {
          values['DELETE_SICHER'] = field.config['DELETE_SICHER'];
        }

        this.$emit('change', values);
        this.$emit('save', true);
      }
    },
    removePosition(field = {}, isin) {
      this.$emit("removePosition", field);
    },
    openFondsfinder(field = {}) {
      let query = this.config?.query && JSON.parse(this.config?.query) || {};
      query = Object.assign(query, { singleSelection: true, saveType: 'isin', isVWL: this.config && (this.config.isVwl || this.config.categoryId === 'VWL')});
      this.navigateToFormsFinder(field.id, query);
    },
  },
  created() {
    if(this.item) {
      this.prepareItem();
    }
  },
  components: {
    InputField,
    InputTextArea,
    InputRadioBoxGroup,
    FormWertpapierAuswahl,
    BaseButton,
    FormDivider,
    WertpapierAuswahlUnique,
    InputToggleSwitch,
  }
}
</script>
