import EX_POST_MANUELLE_TYPES from './types';

export default {

  [EX_POST_MANUELLE_TYPES.GETTERS.LIST](state) {
    return [ ...state.list || [], ];
  },

  [EX_POST_MANUELLE_TYPES.GETTERS.COMBOS](state) {
    return { ...state.combos || {}, };
  },

  [EX_POST_MANUELLE_TYPES.GETTERS.IS_COMBOS_LOADED](state) {
    return Object.keys(state.combos || {}).length > 0;
  },

}