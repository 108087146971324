var render, staticRenderFns
import script from "./VStyle.vue?vue&type=script&lang=js&"
export * from "./VStyle.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("P:\\workspaces\\fk0\\fc\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('65d5fc0b')) {
      api.createRecord('65d5fc0b', component.options)
    } else {
      api.reload('65d5fc0b', component.options)
    }
    
  }
}
component.options.__file = "src/components/core/VStyle.vue"
export default component.exports