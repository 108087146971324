var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("InputToggleSwitch", {
        attrs: {
          id: _vm.erlaubtGf,
          label: "Geschlossene Investmentvermögen nach KAGB (AIF)",
          inLineLabel: "",
          disabled: _vm.isDisabled,
          isComponentHalfSize: ""
        },
        on: {
          input: function($event) {
            return _vm.updateStore("closedProperty", "erlaubtGf", $event, true)
          }
        },
        model: {
          value: _vm.erlaubtGf,
          callback: function($$v) {
            _vm.erlaubtGf = $$v
          },
          expression: "erlaubtGf"
        }
      }),
      _c("InputToggleSwitch", {
        attrs: {
          label: "Sonstige Vermögensanlagen (Vermögensanlagen)",
          inLineLabel: "",
          disabled: _vm.isDisabled,
          isComponentHalfSize: ""
        },
        on: {
          input: function($event) {
            return _vm.updateStore(
              "closedProperty",
              "erlaubtSonstige",
              $event,
              true
            )
          }
        },
        model: {
          value: _vm.erlaubtSonstige,
          callback: function($$v) {
            _vm.erlaubtSonstige = $$v
          },
          expression: "erlaubtSonstige"
        }
      }),
      _c("InputToggleSwitch", {
        attrs: {
          label: "Direktinvestments (Vermögensanlagen)",
          inLineLabel: "",
          disabled: _vm.isDisabled,
          isComponentHalfSize: ""
        },
        on: {
          input: function($event) {
            return _vm.updateStore(
              "closedProperty",
              "erlaubtDirekt",
              $event,
              true
            )
          }
        },
        model: {
          value: _vm.erlaubtDirekt,
          callback: function($$v) {
            _vm.erlaubtDirekt = $$v
          },
          expression: "erlaubtDirekt"
        }
      }),
      _c("InputRadioBoxGroup", {
        attrs: {
          title: "Übertragbarkeit / Kündbarkeit",
          disabled: _vm.isDisabled || !_vm.isGfZusatzAktiv,
          validateUntouched: true,
          values: [
            {
              value: "KURZFRISTIG",
              label:
                "kurzfristig kündbar oder unbeschränkte Übertragbarkeit bei erwartetem liquidem Zweitmarkt"
            },
            {
              value: "MITTELFRISTIG",
              label:
                "mittelfristig kündbar oder unbeschränkte Übertragbarkeit, jedoch kein liquider Zweitmarkt"
            },
            {
              value: "LANGFRISTIG",
              label:
                "langfristig oder nicht kündbar und eingeschränkte Übertragbarkeit bzw. keine Übertragbarkeit"
            }
          ]
        },
        on: {
          input: function($event) {
            return _vm.updateStore("closedProperty", "kuendbarkeit", $event)
          }
        },
        model: {
          value: _vm.kuendbarkeit,
          callback: function($$v) {
            _vm.kuendbarkeit = $$v
          },
          expression: "kuendbarkeit"
        }
      }),
      _c("InputRadioBoxGroup", {
        attrs: {
          title: "Geschäftsmodell",
          disabled: _vm.isDisabled || !_vm.isGfZusatzAktiv,
          validateUntouched: true,
          values: [
            {
              value: "NORMAL",
              label:
                "keine gesteigerten Risiken aus Geschäftsmodell; theoretisches Totalverlustrisiko"
            },
            {
              value: "GESTEIGERT",
              label:
                "gesteigerte Risiken aus Geschäftsmodell; nicht nur theoretisches Totalverlustrisiko"
            },
            {
              value: "HOHE",
              label:
                "hohe Risiken aus Geschäftsmodell, z. B. – Venture Capital – Projektentwicklung – Abhängigkeit von Schlüsselpersonen; nicht nur theoretisches Totalverlustrisiko"
            }
          ]
        },
        on: {
          input: function($event) {
            return _vm.updateStore("closedProperty", "geschaeftsmodell", $event)
          }
        },
        model: {
          value: _vm.geschaeftsmodell,
          callback: function($$v) {
            _vm.geschaeftsmodell = $$v
          },
          expression: "geschaeftsmodell"
        }
      }),
      _c("InputRadioBoxGroup", {
        attrs: {
          title: "Haftung",
          disabled: _vm.isDisabled || !_vm.isGfZusatzAktiv,
          validateUntouched: true,
          values: [
            {
              value: "NORMAL",
              label:
                "keine über die zu leistende Einlage hinausgehende persönliche Haftung, jedoch Aufleben der persönlichen Haftung bis zur Höhe der Einlage möglich (z. B. im Falle von Ausschüttungen, wenn hierdurch das Kapitalkonto des Anlegers unter den Betrag der Hafteinlage absinkt)"
            },
            {
              value: "BEGRENZT",
              label:
                "begrenzte über die zu leistende Einlage hinausgehende persönliche Haftung bzw. begrenzte Nachschusspflichten"
            },
            {
              value: "UNBEGRENZT",
              label:
                "unbegrenzte persönliche Haftung bzw. unbegrenzte Nachschusspflichten"
            }
          ]
        },
        on: {
          input: function($event) {
            return _vm.updateStore("closedProperty", "haftung", $event)
          }
        },
        model: {
          value: _vm.haftung,
          callback: function($$v) {
            _vm.haftung = $$v
          },
          expression: "haftung"
        }
      }),
      _c("InputRadioBoxGroup", {
        attrs: {
          title:
            "Fremdfinanzierung (auf Investmentvermögens- und Anlegerebene)",
          disabled: _vm.isDisabled || !_vm.isGfZusatzAktiv,
          validateUntouched: true,
          values: [
            {
              value: "MAX_25",
              label:
                "kein Fremdkapitaleinsatz oder Fremdkapitalquote bis. max. 25 %"
            },
            { value: "MAX_50", label: "Fremdkapitalquote bis max. 50 %" },
            { value: "MAX_100", label: "Fremdkapitalquote über 50 %" }
          ]
        },
        on: {
          input: function($event) {
            return _vm.updateStore(
              "closedProperty",
              "fremdfinanzierung",
              $event
            )
          }
        },
        model: {
          value: _vm.fremdfinanzierung,
          callback: function($$v) {
            _vm.fremdfinanzierung = $$v
          },
          expression: "fremdfinanzierung"
        }
      }),
      _c("InputRadioBoxGroup", {
        attrs: {
          title: "Währungsrisiko",
          disabled: _vm.isDisabled || !_vm.isGfZusatzAktiv,
          validateUntouched: true,
          values: [
            {
              value: "GERING",
              label:
                "Anlage in Euro; Verwendung der Gelder auf Ebene des geschlossenen Investmentvermögen bzw. der Vermögensanlage: Investitionen erfolgen in Euro und es besteht kein oder geringes Währungsrisiko bei Fremdfinanzierung."
            },
            {
              value: "MODERAT",
              label:
                "Anlage in Euro; Verwendung der Gelder auf Ebene des geschlossenen Investmentvermögen bzw. der Vermögensanlage: Investitionen erfolgen teilweise in Fremdwährung und/oder moderates Währungsrisiko bei Fremdfinanzierung."
            },
            {
              value: "HOCH",
              label:
                "Anlage in Euro oder in Fremdwährung; Verwendung der Gelder auf Ebene des geschlossenen Investmentvermögen bzw. der Vermögensanlage: Investitionen erfolgen überwiegend oder ausschließlich in Fremdwährung und/oder hohes Währungsrisiko bei Fremdfinanzierung."
            }
          ]
        },
        on: {
          input: function($event) {
            return _vm.updateStore("closedProperty", "waehrungsrisiko", $event)
          }
        },
        model: {
          value: _vm.waehrungsrisiko,
          callback: function($$v) {
            _vm.waehrungsrisiko = $$v
          },
          expression: "waehrungsrisiko"
        }
      }),
      _c("InputToggleSwitch", {
        attrs: {
          label: _vm.checkBest,
          disabled: _vm.isDisabled || !_vm.isGfZusatzAktiv,
          inLineLabel: "",
          suppressValidationMessage: "",
          isComponentHalfSize: ""
        },
        on: {
          input: function($event) {
            return _vm.updateStore(
              "closedProperty",
              "bestaetigungRichtigkeit",
              $event
            )
          }
        },
        model: {
          value: _vm.bestaetigungRichtigkeit,
          callback: function($$v) {
            _vm.bestaetigungRichtigkeit = $$v
          },
          expression: "bestaetigungRichtigkeit"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }