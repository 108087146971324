export const MUTATION_PREFIX = 'FONDS_FINDER: ';
export const ACTIONS_PREFIX = 'FONDS_FINDER_';
export const GETTERS_PREFIX = 'FONDS_FINDER_';

export default {
  MUTATIONS: {
    GET_FONDS_V2_SUCCESS: MUTATION_PREFIX + 'GET_FONDS_V2_SUCCESS',
    GET_COMBOBOX_SELECTIONS_V2_SUCCESS: MUTATION_PREFIX + 'GET_COMBOBOX_SELECTIONS_V2',
    ADD_FONDS_INFO: MUTATION_PREFIX + 'ADD_FONDS_INFO',
    CLEAR_FONDS: MUTATION_PREFIX + 'CLEAR_FONDS', 
    DEPOTPOSITION: MUTATION_PREFIX + 'DEPOTPOSITION', 
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',

    GET_MARKTUBERSICHT_CHART_DATA_SUCCESS: MUTATION_PREFIX + 'GET_MARKTUBERSICHT_CHART_DATA_SUCCESS',
    SET_MARKTUBERSICHT_CHART_SELECTED_FOND: MUTATION_PREFIX + 'SET_MARKTUBERSICHT_CHART_SELECTED_FOND',
  },
  ACTIONS: {
    GET_FONDS_V2: ACTIONS_PREFIX + 'GET_FONDS_V2',
    GET_COMBOBOX_SELECTIONS_V2: ACTIONS_PREFIX + 'GET_COMBOBOX_SELECTIONS_V2',
    TAKE_SELECTED_ISINS: ACTIONS_PREFIX + 'TAKE_SELECTED_ISINS',

    GET_FUNDS_CHART_DATA: ACTIONS_PREFIX + 'GET_FUNDS_CHART_DATA',
    GET_MARKTUBERSICHT_CHART_DATA: ACTIONS_PREFIX + 'GET_MARKTUBERSICHT_CHART_DATA',
  },
  GETTERS: {
    FONDS: GETTERS_PREFIX + 'FONDS',
    POSITION_INFO: GETTERS_PREFIX + 'POSITION_INFO',
    COMBOBOX_SELECTIONS: GETTERS_PREFIX + 'COMBOBOX_SELECTIONS',

    MARKTUBERSICHT_CHART_DATA: GETTERS_PREFIX + 'MARKTUBERSICHT_CHART_DATA',
    MARKTUBERSICHT_CHART_SELECTED_FOND: GETTERS_PREFIX + 'MARKTUBERSICHT_CHART_SELECTED_FOND',
  }
}