
import LOG_TYPES from '@/store/log/types.js';
import WEBRTC_TYPES from '@/store/webrtc/types.js';
import BRIDGE_TYPES from '@/store/bridge/types.js';
import { SignalingMessageId } from "@/store/webrtc/utils/signalingService_types.js";
import { SignalingMessageIdDict, createMessageToSignaler, writeThrift } from '@/store/webrtc/sockets/helpers.js';
import { buildMessage } from '@/helpers/log-message-helper.js';

const HANDLER = {
  [SignalingMessageId.ICE_CANDIDATE]: iceCandidateHandler,
  [SignalingMessageId.ACCEPTED_BY_CALLEE]: acceptedByCalleeHandler,
  [SignalingMessageId.REMOTE_VIDEO_ON]: remoteVideoOnHandler,
  [SignalingMessageId.REMOTE_VIDEO_OFF]: remoteVideoOffHandler,
  [SignalingMessageId.REMOTE_AUDIO_ON]: remoteAudioOnHandler,
  [SignalingMessageId.REMOTE_AUDIO_OFF]: remoteAudioOffHandler,
  [SignalingMessageId.CONFIRM_VIDEO_UPGRADE]: confirmVideoUpgradeHandler,
  [SignalingMessageId.ACCEPT_VIDEO_UPGRADE]: acceptVideoUpgradeHandler,
  [SignalingMessageId.SHOW_TEXT]: showTextHandler,
}

async function iceCandidateHandler({ msg, callId, dispatch, commit }) {
  dispatch(LOG_TYPES.ACTIONS.INFO, { message: 'signaler socket: iceCandidate received' });
  commit(WEBRTC_TYPES.MUTATIONS.REMOTE_ICE_CANDIDATE, { callId, candidate: msg.messageIceCandidate });
}

async function acceptedByCalleeHandler({ callId, dispatch }) {
  await dispatch(WEBRTC_TYPES.ACTIONS.CALL_ESTABLISHED, callId);
  await dispatch(BRIDGE_TYPES.ACTIONS.OUTGOING_CALL_ACCEPTED);
}

async function remoteVideoOnHandler({ commit }) {
  commit(WEBRTC_TYPES.MUTATIONS.REMOTE_VIDEO, true);
}

async function remoteVideoOffHandler({ commit }) {
  commit(WEBRTC_TYPES.MUTATIONS.REMOTE_VIDEO, false);
}

async function remoteAudioOnHandler({ callId, commit }) {
  commit(WEBRTC_TYPES.MUTATIONS.REMOTE_AUDIO, { receivingAudio: true, callId });
}

async function remoteAudioOffHandler({ callId, commit }) {
  commit(WEBRTC_TYPES.MUTATIONS.REMOTE_AUDIO, { receivingAudio: false, callId });
}

async function confirmVideoUpgradeHandler({ callId, commit, state }) {
  const callInfo = state.callInfos.find(data => data.callId == callId);
  commit(WEBRTC_TYPES.MUTATIONS.REMOTE_VIDEO, true);
  let tmsg = createMessageToSignaler(callInfo, SignalingMessageId.ACCEPT_VIDEO_UPGRADE);
  commit(WEBRTC_TYPES.MUTATIONS.SEND_SIGNAL_MESSAGE, { callId: callInfo.callId, message: writeThrift(tmsg) });
}

async function acceptVideoUpgradeHandler({ dispatch }) {
  // we shared screen and the other side accepted. No need to react
  dispatch(LOG_TYPES.ACTIONS.INFO, "Received ACCEPT_VIDEO_UPGRADE");
}

async function showTextHandler({ msg, dispatch }) {
  if (msg.showText) {
    dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(msg.showText.messagePlainText, 'primary'));
  }
}

async function defaultHandler({ msg, dispatch }) {
  dispatch(LOG_TYPES.ACTIONS.WARN, `Unhandled signal socket message: ${msg.messageId} : ${SignalingMessageIdDict[msg.messageId]}`);
}


export async function handleSignalerSocket({ msg, callId, dispatch, commit, getters, state }) {
  const nextHandler = HANDLER[msg.messageId] || defaultHandler;

  await nextHandler({ msg, callId, dispatch, commit, getters, state });
}

