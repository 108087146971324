

import LOG_TYPES from '@/store/log/types.js';
import WEBRTC_TYPES from '@/store/webrtc/types.js';
import {
  MessageFromClientToSignaler,
  SignalingMessageIceCandidate,
  SignalingMessageId
} from "@/store/webrtc/utils/signalingService_types.js";
import { createMessageToSignaler, sendSignalingRegistrationMessage, writeThrift } from '@/store/webrtc/sockets/helpers.js';

function onSelectedCandidatePairChange(event, dispatch) {
  dispatch(LOG_TYPES.ACTIONS.INFO, { message: 'peerConnection: onSelectedCandidatePairChange: ', event });
}

function onIceCandidate(event, callInfo, commit) {
  if (event.candidate) {
    const candidateSplit = event.candidate.candidate.toLowerCase().split(" ")
    if (candidateSplit.length >= 5 && candidateSplit[2] == "udp" && candidateSplit[4].split(".").length == 4) {
      const tmsg = new MessageFromClientToSignaler({
        messageId: SignalingMessageId.ICE_CANDIDATE,
        messageIceCandidate: new SignalingMessageIceCandidate(event.candidate),
        connectionRole:callInfo.connectionRole,
        dbPrefix: callInfo.dbPrefix,
        webRtcCallId: callInfo.callId,
        senderRtcUserId: callInfo.ownUUID,
      })
      commit(WEBRTC_TYPES.MUTATIONS.SEND_SIGNAL_MESSAGE, {callId: callInfo.callId, message: writeThrift(tmsg)})
    }
  }
}

function onTrack(callInfo, event, dispatch, commit) {
  dispatch(LOG_TYPES.ACTIONS.INFO, { message: 'peerConnection: onTrack. Receiving remote stream', stream: event.stream });
  
  commit(WEBRTC_TYPES.MUTATIONS.RECEIVE_STREAMS, { callId: callInfo.callId, streams: event.streams })
}

function onNegotiationNeeded(callInfo, dispatch, commit) {
  dispatch(LOG_TYPES.ACTIONS.INFO, { message: 'peerConnection: onNegotiationNeeded. Start creating Offer' });
  callInfo.peerConnection.createOffer({offerToReceiveAudio: true, offerToReceiveVideo: true}).then(offer => {
    return callInfo.peerConnection.setLocalDescription(offer)
  }).then(() => {
    // Send the second registration message with sdpOffer when the peer connection is established
    sendSignalingRegistrationMessage(callInfo, commit, dispatch, { withSdpOffer: true });
  })
}

function onIceConnectionStateChange(event, callInfo, dispatch, commit, state) {
  dispatch(LOG_TYPES.ACTIONS.INFO, `peerConnection: onIceConnectionStateChange => ${callInfo.peerConnection.iceConnectionState}`);
  const callData = state.callInfos.find(data => data.callId == callInfo.callId)
  switch (callInfo.peerConnection.iceConnectionState) {
    case "failed":
    case "closed":
      dispatch(WEBRTC_TYPES.ACTIONS.STOP_CALL_ON_ERROR, {
        callId: callInfo.callId,
        event,
      });
      break;
    case "disconnected":
      break;
    case "connected":
    case "completed":
      if (callData && callData.acceptedButNotEstablished) {
        let tmsg = createMessageToSignaler(callData, SignalingMessageId.ACCEPTED_BY_CALLEE);
        commit(WEBRTC_TYPES.MUTATIONS.SEND_SIGNAL_MESSAGE, { callId: callData.callId, message: writeThrift(tmsg) });
        dispatch(WEBRTC_TYPES.ACTIONS.CALL_ESTABLISHED, callData.callId);
      }
      break;
  }
}

export async function setupPeerConnection({ callInfo, commit, dispatch, state }) {
  dispatch(LOG_TYPES.ACTIONS.INFO, { message: "setupPeerConnection called" });

  callInfo.peerConnection = new RTCPeerConnection({
    iceTransportPolicy: 'all',
    iceServers: [{
      urls: callInfo.iceUrls,
      username: callInfo.iceUsername,
      credential: callInfo.iceCredential,
    }],
  });

  callInfo.peerConnection.onselectedcandidatepairchange = (event) => onSelectedCandidatePairChange(event, dispatch);
  callInfo.peerConnection.onicecandidate = (event) => onIceCandidate(event, callInfo, commit);
  callInfo.peerConnection.ontrack = (event) => onTrack(callInfo, event, dispatch, commit);
  callInfo.peerConnection.onnegotiationneeded = () => onNegotiationNeeded(callInfo, dispatch, commit);
  callInfo.peerConnection.oniceconnectionstatechange = (event) => onIceConnectionStateChange(event, callInfo, dispatch, commit, state);
}

export function closePeerConnection(callInfo) {
  if (callInfo.peerConnection) {
    callInfo.peerConnection.ontrack = null;
    callInfo.peerConnection.onicecandidate = null;
    callInfo.peerConnection.oniceconnectionstatechange = null;
    callInfo.peerConnection.onsignalingstatechange = null;
    callInfo.peerConnection.onicegatheringstatechange = null;
    callInfo.peerConnection.onnegotiationneeded = null;
    callInfo.peerConnection.close()
  }
}
