<template>
  <div class="antrag-components__container">
    <div class="box__container">

      <InputRadioBoxGroup :value="form.steuerklasse" title="Soweit bekannt, können Sie das persönliche Verhältnis zwischen Übertragendem und Empfänger angeben." 
        :values="steuerklasseValues"
        @input="$emit('save', { steuerklasse: $event })"/>

      <BaseButton class="col-12" @click="$emit('back')">Zurück</BaseButton>
    </div>
  </div>
</template>
<script>

import antragNavigationMixin from '@/mixins/antrag/antrag-navigation-mixin.js';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import BaseButton from '@/components/core/BaseButton.vue';

export default {
  mixins: [antragNavigationMixin],
  components: {
    InputRadioBoxGroup,
    BaseButton,
  },
  props: {
    positionData: {
    },
    anzahlFonds: {
      type: Number,
      default: 4,
    },
    warnings: {
    },
  },
  data() {
    return {
      form: {},
      steuerklasseValues: [
        {value: "I", label: "<b>Steuerklasse I :</b></br>- der Ehegatte und gleichgeschlechtliche Lebenspartner</br>- die Kinder und Stiefkinder</br>- die Abkömmlinge der Kinder und Stiefkinder"},
        {value: "II", label: "<b>Steuerklasse II :</b>- die Eltern und Voreltern</br>- die Geschwister</br>- die Abkömmlinge ersten Grades von Geschwistern</br>- die Schwiegerkinder</br>- die Schwiegereltern</br>- der geschiedene Ehegatte und der Lebenspartner einer aufgehobenen Lebenspartnerschaft"},
        {value: "III", label: "<b>Steuerklasse III :</b></br>- alle übrigen Empfänger und Zweckzuwendungen"},
      ],
    }
  },
  mounted() {
    this.form = Object.assign({}, this.positionData);
  },
  methods: {
  },
}
</script>
<style scoped>

</style>