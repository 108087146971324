<template>
  <BaseAntragView />
</template>

<script>
import BaseAntragView from '@/views/antrag/BaseAntragView.vue';

export default {
  provide() {
    return {
      antragCustomNavigation: {
        headerOptionMenu: this.$appNavigation.currentTabMenu,
      },
    };
  },
  components: {
    BaseAntragView,
  },
}
</script>
