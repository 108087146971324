<template>
  <BaseButton isClear @click="showModal()" class="hide-on-small-screen">
    <ph-record :size="size" class="clickable"/>

    <button class="Recbutton Rec" v-if="recordingTutorial"></button>

    <BaseModal modalTitle="Titel des Tutorials" ref="createTutorialModal" :showDefaultButtons="false">
        <InputField label="Title" id="tutorialTitle" v-model="title" />
        <template v-slot:footer>
          <BaseButton isSecondary @click="closeModal()">Abbrechen</BaseButton>
          <BaseButton @click="startRecording()">Aufzeichnung starten</BaseButton>
        </template>        
    </BaseModal>    

  </BaseButton>
  
</template>

<script>
import { PhRecord } from 'phosphor-vue'
import CORE_TYPES from "@/store/core/types";
import BaseModal from "@/components/core/BaseModal.vue";
import InteractiveHelpCommonFunctions from "@/assets/mixins/interactivehelpcommonfunctions.js";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import InteractiveHelpMixin from "@/assets/mixins/interactivehelpmixins.js";
import HelpRecordingMixin from "@/assets/mixins/helprecordingmixin.js";
import { mapGetters } from "vuex";
import BaseButton from "@/components/core/BaseButton.vue";
import InputField from "@/components/core/forms/InputField.vue";

export default {
  mixins: [
    InteractiveHelpCommonsMixin,
    InteractiveHelpMixin,
    HelpRecordingMixin,
    InteractiveHelpCommonFunctions
  ],
  props: {
    size: {
      default: 18
    }
  },
  data() {
    return {
      title: '',
      userType: 'K',
      userTypes: [
        {label:'Makler', value: 'M'}, 
        {label:'Kunde', value: 'K'}, 
        ],      
    };
  },
  components: {
    BaseModal,
    PhRecord,
    BaseButton,
    InputField
  },
  computed: {
    ...mapGetters({
      tutorialRecordedItems: CORE_TYPES.GETTERS.TUTORIAL_RECORDED_ITEMS,
      recordingTutorial: CORE_TYPES.GETTERS.RECORDING_TUTORIAL,
      recordingTutorialTitle: CORE_TYPES.GETTERS.RECORDING_TUTORIAL_TITLE,
      recordedTutorials: CORE_TYPES.GETTERS.RECORDED_TUTORIALS,
      userid: CORE_TYPES.GETTERS.GET_USER_ID
    })
  },  
  mounted: function() {
  },
  methods: {
     closeModal() {
      this.$refs.createTutorialModal.close();
     },
     showModal() {
        this.title = '';
        this.$refs.createTutorialModal.open();
     },
     startRecording() {
      this.$refs.createTutorialModal.close();
      this.$store.commit(CORE_TYPES.MUTATIONS.RECORDING_TUTORIAL_TITLE, {tutorialTitle: this.title});
      this.$store.commit(CORE_TYPES.MUTATIONS.RECORDING_TUTORIAL_ROUTE, {tutorialRoute: this.$route.path});
      this.$store.commit(CORE_TYPES.MUTATIONS.RECORDING_TUTORIAL_USER_TYPE, {tutorialUserType: 'K'});
      this.startTutorialRecording();
    },    
  }
}
</script>

<style scoped>
  .middle {
    display: contents;
  }
  .button {
    margin-right: 8px;
  }
</style>