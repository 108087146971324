var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        "data-name": "Layer 1",
        width: "810.80372",
        height: "685.85664",
        viewBox: "0 0 810.80372 685.85664"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M716.777,519.50866H498.244a22.11531,22.11531,0,0,1-22.09021-22.09033V208.05576A22.1153,22.1153,0,0,1,498.244,185.96543H716.777a22.11533,22.11533,0,0,1,22.09033,22.09033V497.41833A22.11533,22.11533,0,0,1,716.777,519.50866Z",
          transform: "translate(-194.59814 -107.07168)",
          fill: "#e6e6e6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M701.41593,501.09753H513.60511a19.63607,19.63607,0,0,1-19.61377-19.61377V223.99033a19.63607,19.63607,0,0,1,19.61377-19.61377H701.41593a19.636,19.636,0,0,1,19.61377,19.61377V481.48376A19.636,19.636,0,0,1,701.41593,501.09753Z",
          transform: "translate(-194.59814 -107.07168)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M682.68044,253.31056H532.34059a4.90308,4.90308,0,1,1,0-9.80615H682.68044a4.90308,4.90308,0,1,1,0,9.80615Z",
          transform: "translate(-194.59814 -107.07168)",
          fill: "#e4e4e4"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M682.68044,272.68507H532.34059a4.90332,4.90332,0,0,1,0-9.80664H682.68044a4.90332,4.90332,0,0,1,0,9.80664Z",
          transform: "translate(-194.59814 -107.07168)",
          fill: "#e4e4e4"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M597.5093,292.05958H532.34059a4.90332,4.90332,0,0,1,0-9.80664H597.5093a4.90332,4.90332,0,0,1,0,9.80664Z",
          transform: "translate(-194.59814 -107.07168)",
          fill: "#e4e4e4"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M682.68044,332.637H532.34059a4.90308,4.90308,0,1,1,0-9.80615H682.68044a4.90308,4.90308,0,1,1,0,9.80615Z",
          transform: "translate(-194.59814 -107.07168)",
          fill: "#e4e4e4"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M682.68044,352.01152H532.34059a4.90332,4.90332,0,0,1,0-9.80664H682.68044a4.90332,4.90332,0,0,1,0,9.80664Z",
          transform: "translate(-194.59814 -107.07168)",
          fill: "#e4e4e4"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M597.5093,371.386H532.34059a4.90333,4.90333,0,0,1,0-9.80665H597.5093a4.90332,4.90332,0,0,1,0,9.80665Z",
          transform: "translate(-194.59814 -107.07168)",
          fill: "#e4e4e4"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M682.68044,411.96346H532.34059a4.90308,4.90308,0,1,1,0-9.80615H682.68044a4.90308,4.90308,0,1,1,0,9.80615Z",
          transform: "translate(-194.59814 -107.07168)",
          fill: "#e4e4e4"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M682.68044,431.338H532.34059a4.90332,4.90332,0,1,1,0-9.80664H682.68044a4.90332,4.90332,0,1,1,0,9.80664Z",
          transform: "translate(-194.59814 -107.07168)",
          fill: "#e4e4e4"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M597.5093,450.71249H532.34059a4.90332,4.90332,0,0,1,0-9.80664H597.5093a4.90332,4.90332,0,0,1,0,9.80664Z",
          transform: "translate(-194.59814 -107.07168)",
          fill: "#e4e4e4"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "369.608 244.215 206.272 104.506 96.539 104.506 96.539 102.448 207.032 102.448 370.946 242.651 369.608 244.215",
          fill: "#2f2e41"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "96.53869",
          cy: "103.47712",
          r: "16.46545",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M349.84131,265.93187H199.50146a4.90308,4.90308,0,1,1,0-9.80616H349.84131a4.90308,4.90308,0,1,1,0,9.80616Z",
          transform: "translate(-194.59814 -107.07168)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M349.84131,285.30638H199.50146a4.90332,4.90332,0,0,1,0-9.80664H349.84131a4.90332,4.90332,0,0,1,0,9.80664Z",
          transform: "translate(-194.59814 -107.07168)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M264.67016,304.68089h-65.1687a4.90332,4.90332,0,0,1,0-9.80664h65.1687a4.90332,4.90332,0,0,1,0,9.80664Z",
          transform: "translate(-194.59814 -107.07168)",
          fill: _vm.color
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "412.912 155.639 576.826 15.436 687.319 15.436 687.319 17.494 577.586 17.494 414.25 157.203 412.912 155.639",
          fill: "#2f2e41"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "687.31936",
          cy: "16.46545",
          r: "16.46545",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M993.8731,178.34287H843.53325a4.90308,4.90308,0,1,1,0-9.80615H993.8731a4.90308,4.90308,0,1,1,0,9.80615Z",
          transform: "translate(-194.59814 -107.07168)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M993.8731,197.71738H843.53325a4.90332,4.90332,0,0,1,0-9.80664H993.8731a4.90332,4.90332,0,1,1,0,9.80664Z",
          transform: "translate(-194.59814 -107.07168)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M908.702,217.0919h-65.1687a4.90332,4.90332,0,0,1,0-9.80665H908.702a4.90332,4.90332,0,1,1,0,9.80665Z",
          transform: "translate(-194.59814 -107.07168)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M876.00641,572.04947a8.56625,8.56625,0,0,1,1.70826-13.02377l-7.02037-29.621,14.85847,5.42843,4.38869,27.29637a8.61267,8.61267,0,0,1-13.93505,9.92Z",
          transform: "translate(-194.59814 -107.07168)",
          fill: "#9e616a"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M840.27524,447.922l5.96312,15.33373s-2.55563,15.33372,1.70374,18.74122,2.55562,3.40749,2.55562,5.96311a13.10383,13.10383,0,0,0,5.11125,9.37061c3.40749,2.55562,18.74121,54.51991,18.74121,54.51991h15.33373a48.04248,48.04248,0,0,1,0-11.92623c.85187-5.96311-2.55562-21.29684-4.25937-23.85246s0-5.96311,0-11.07435-3.40749-7.66687-3.40749-16.1856-10.22249-74.96488-9.37061-82.63174-8.51874-14.48185-8.51874-14.48185H855.609Z",
          transform: "translate(-194.59814 -107.07168)",
          fill: "#e6e7e8"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "609.598 373.374 595.425 436.624 686.247 430.974 661.551 368.856 609.598 373.374",
          fill: "#9e616a"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1002.16716,718.16831a96.90107,96.90107,0,0,1-12.51,27.53c-12.48,18.87-30.34,33.3-49.97,44.85-1.36.81-2.74,1.6-4.12,2.38h-13.77l-.48-1.1v-.01l-.55993-1.27-.07007-.15-.03.15-.55005,2.38h-6.75989c-.55993-.79-1.11-1.58-1.65-2.38-.79-1.17-1.55-2.35-2.27-3.55a57.63468,57.63468,0,0,1-3.3999-6.52c-.12012-.25-.2201-.5-.32007-.75l.45-.49-1.86987-8.18,3.75988-.62-4.12-12.71c.15-.47.32007-.94.5-1.41,2.31006-5.85,6.65-10.22,11.8-13.89,5.30005-3.79,11.47-6.83,17.13989-9.99,2.66016-1.49,5.28-3.06,7.83008-4.72v-.01q4.89-3.16506,9.49-6.77a1.42584,1.42584,0,0,0,.15-.11,1.06926,1.06926,0,0,0,.12-.1,140.90236,140.90236,0,0,0,34.31006-38.93.0098.0098,0,0,0,.01-.01c.29992-.48.57983-.96.85986-1.45v-.01a136.25417,136.25417,0,0,0,9.41-19.65v-.01c.20008-.5.38-1,.57-1.5q.39.66.75,1.32a.0098.0098,0,0,1,.01.01C1006.57719,677.84831,1007.49724,698.74833,1002.16716,718.16831Z",
          transform: "translate(-194.59814 -107.07168)",
          fill: "#f0f0f0"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M996.87712,660.49833a121.36474,121.36474,0,0,1-2.86987,27.54,118.54084,118.54084,0,0,1-3.36011,12.24,121.34564,121.34564,0,0,1-10.12988,22.6c-.23.39-.45008.77-.69007,1.15v.01a102.347,102.347,0,0,1-5.75,8.66,47.72179,47.72179,0,0,1-11.4,11.46,30.08866,30.08866,0,0,1-11.98,4.63c-.8.15-1.62.26-2.44995.36-3.31006.39-6.73.54-10.01,1.24a21.849,21.849,0,0,0-4.82007,1.55c-5.28,2.45-8.25,7.58-9.55,13.06-.23.96-.40991,1.93-.54993,2.9-1.09,7.45.11,15.19-.51,22.65-.06994.79-.16,1.59-.26989,2.38h-.74012l-.48-1.1v-.01c.04993-.42.09-.84.13-1.27a96.29458,96.29458,0,0,0,.09009-11.01c-.04-1.65-.07007-3.31-.05005-4.97.02-1.33.06994-2.66.16992-3.98v-.01c.07007-.82.15-1.63.26-2.44a31.586,31.586,0,0,1,1.82007-7.25,18.15175,18.15175,0,0,1,10.31994-10.68,26.9927,26.9927,0,0,1,4.4701-1.25c3.34985-.64,6.80993-.8,10.17993-1.22.36-.04.72-.09,1.07007-.15.44995-.07.88989-.15,1.32983-.23005a28.66625,28.66625,0,0,0,12.16016-5.26995,51.90374,51.90374,0,0,0,10.98-11.92c1.63989-2.35,3.19995-4.75,4.6499-7.22a.0098.0098,0,0,1,.01-.01c.07007-.12.14-.23.21-.35l.01-.02a119.57155,119.57155,0,0,0,13.46008-34.17c.09-.44.19007-.88.28992-1.32a120.88726,120.88726,0,0,0,2.67-26.4v-.01c0-.42-.00988-.83-.01989-1.25-.02-.86,1.30993-1.05,1.32983-.19.01.42.02.84.01,1.26A.0098.0098,0,0,1,996.87712,660.49833Z",
          transform: "translate(-194.59814 -107.07168)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M979.25633,723.75725a18.0181,18.0181,0,0,0,22.6943-5.41582c.50869-.69934-.55415-1.52268-1.06352-.82241a16.68437,16.68437,0,0,1-21.12074,4.9947c-.76923-.39533-1.27487.85047-.51,1.24353Z",
          transform: "translate(-194.59814 -107.07168)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M951.23558,747.75671a34.72845,34.72845,0,0,1,1.2295-25.27921c.35484-.78953-.88912-1.29862-1.24354-.51a36.12283,36.12283,0,0,0-1.22593,26.30791c.27855.82057,1.517.29746,1.24-.51865Z",
          transform: "translate(-194.59814 -107.07168)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M993.26359,687.201a10.1991,10.1991,0,0,1-7.02237-6.65924c-.268-.8237-1.50624-.29968-1.24.51865a11.429,11.429,0,0,0,7.75229,7.38413.69462.69462,0,0,0,.87679-.36675.67543.67543,0,0,0-.36674-.87679Z",
          transform: "translate(-194.59814 -107.07168)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M941.19719,727.70835v.01a131.24751,131.24751,0,0,1-2.66992,21.24v.01c-.08008.48-.19007.95-.29,1.42a184.49666,184.49666,0,0,1-10.11011,31.61q-1.78491,4.32-3.74,8.55c-.35986.8-.73,1.59-1.10986,2.38h-1.48011l-.48-1.1v-.01l-.55993-1.27-.07007-.15-.03.15-.55005,2.38H905.80717l-2.7799-2.38-4.05005-3.47,5.65991-6.18.93005-1.02.14-.15.45-.49-1.86987-8.18,3.75988-.62-4.12-12.71-1.95-6.01-.49-1.51-2.8501-8.79-8.59985,1.61005-1.40015-4.9a135.98836,135.98836,0,0,0,6.42-18.41c.01-.05.03-.09.04-.13995a141.58279,141.58279,0,0,0,4.0199-51.96v-.01c-.05994-.56-.12988-1.13-.21-1.68v-.02a133.63121,133.63121,0,0,0-4.31995-21.34c-.14-.53-.29992-1.05-.45007-1.57.46008.2.91.39,1.37012.59l.01.01h.02c.73.32,1.46.66,2.16992,1.02,17.06006,8.29,29.61,23.85,36.74,41.79.02.05.04.09.06006.14a96.39338,96.39338,0,0,1,6.56994,29.43A114.8706,114.8706,0,0,1,941.19719,727.70835Z",
          transform: "translate(-194.59814 -107.07168)",
          fill: "#f0f0f0"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M915.24724,792.92832h-1.58008c-.5-.81-1.04-1.6-1.58-2.38q-1.29-1.81494-2.66-3.55c-.79-1-1.58-1.99-2.37-2.98-.81994-1.03-1.63-2.06-2.42-3.12l.93005-1.02c.15.2.29993.4.46009.6,2.55993,3.37,5.32983,6.61005,7.69995,10.07C914.25725,791.32835,914.76726,792.11832,915.24724,792.92832Z",
          transform: "translate(-194.59814 -107.07168)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M893.80526,643.55713c.26.33.53.67.78,1.01q2.925,3.795,5.53,7.83a117.993,117.993,0,0,1,8.22,14.85c.19.4.38.81.56,1.22a119.26357,119.26357,0,0,1,9.84,35.44c.02.12.03.23.04.35q.49494,4.29.64,8.61a51.7824,51.7824,0,0,1-1.59,16.12c-.2.69-.42,1.37-.66,2.03a27.34442,27.34442,0,0,1-2.25,4.65,27.9227,27.9227,0,0,1-2.14,3.09c-.47.6-.96,1.19-1.48,1.76-.3.33-.61.66-.92.99-.26.25-.51.51-.77.76-2.7,2.62-5.68,5.05-8.12,7.94l.49,1.51c2.35-2.98,5.38-5.45,8.11-8.05a37.89683,37.89683,0,0,0,4.66-5.28c.18-.26.37-.52.54-.79a26.749,26.749,0,0,0,2.38995-4.29c.34-.75.65-1.52.93-2.32a47.89241,47.89241,0,0,0,2.2-16,103.41482,103.41482,0,0,0-.62-10.39c-.04-.45-.09-.88995-.15-1.34a121.6376,121.6376,0,0,0-5.52-24.14,122.65932,122.65932,0,0,0-13.76-28.18q-2.475-3.765-5.25-7.35c-.24-.33-.49-.65-.75-.98C894.2253,641.92713,893.27529,642.87714,893.80526,643.55713Z",
          transform: "translate(-194.59814 -107.07168)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M919.55925,704.72351a18.01812,18.01812,0,0,0,14.85944-17.98777.67262.67262,0,0,0-1.34431-.01633,16.68438,16.68438,0,0,1-13.85658,16.70412c-.8522.14748-.50587,1.44662.34145,1.3Z",
          transform: "translate(-194.59814 -107.07168)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M911.63558,740.75615a34.72842,34.72842,0,0,1-14.23814-20.92427c-.192-.844-1.49177-.50157-1.3.34145a36.12279,36.12279,0,0,0,14.86033,21.74349c.71646.48747,1.39035-.67584.67778-1.16067Z",
          transform: "translate(-194.59814 -107.07168)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M908.73389,667.10206a10.19913,10.19913,0,0,1-9.6163-1.08909c-.70992-.49631-1.38308.66759-.67778,1.16067a11.429,11.429,0,0,0,10.63553,1.22839.69462.69462,0,0,0,.47926-.82071.67543.67543,0,0,0-.82071-.47926Z",
          transform: "translate(-194.59814 -107.07168)",
          fill: _vm.secondColor
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "624.722 665.295 614.519 665.295 609.666 625.943 624.723 625.944 624.722 665.295",
          fill: "#9e616a"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M824.80822,791.73832h-7.23935l-1.29219-6.83481-3.3095,6.83481H793.76669a4.3161,4.3161,0,0,1-2.45255-7.86784L806.64708,773.281v-6.90976l16.12758.9626Z",
          transform: "translate(-194.59814 -107.07168)",
          fill: "#2f2e41"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "721.147 660.219 711.176 662.382 698.09 624.954 712.806 621.762 721.147 660.219",
          fill: "#9e616a"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M925.21517,785.05877l-7.0748,1.53477-2.71182-6.4055-1.78527,7.38107-18.764,4.07057a4.31609,4.31609,0,0,1-4.06481-7.169l12.7394-13.59941-1.46489-6.7527,15.96506-2.47838Z",
          transform: "translate(-194.59814 -107.07168)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M987.59721,791.73832a1.18647,1.18647,0,0,1-1.19006,1.19h-280.29a1.19,1.19,0,0,1,0-2.38h280.29A1.18651,1.18651,0,0,1,987.59721,791.73832Z",
          transform: "translate(-194.59814 -107.07168)",
          fill: "#ccc"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M787.70408,596.5854c7.16,39.35,8.95,56.34,8.95,56.34s-5.37,6.26-1.79,9.84,0,10.73,0,10.73l8.05,86.75,6.48-1.05005,15.61-2.51,5.63-.91-3.58-81.38s3.58-7.16,1.79-8.95c-1.79-1.79,1.79-12.52,1.79-12.52l4.47-84.96,27.73,93.01s-2.68994,3.58,0,6.26c2.68,2.68,8.04,16.1,8.04,16.1l16.49,57.7,1.4,4.9,8.6-1.61,9.36-1.76,1.72-.31995,3.06-.58,1.97-.37.67-.12,3.24-.61-.98-4.35-.51-2.29-13.95-61.96-.32-1.43-.34-1.51s1.79-4.47-.88995-7.16a6.98826,6.98826,0,0,1-1.79-6.26,2.44073,2.44073,0,0,0,.24-.3,3.71263,3.71263,0,0,0,.64-1.46c.24-1.12.03-2.69-1.78-4.5a7.63559,7.63559,0,0,1-1.27-1.82c-2.72-5.15-4.09-16.06-4.09-16.06s-2.42-86.41-10.34-99.61c-.42-.69-8.82779-13.9365-9.26779-14.20652-8.94-5.36-82.71358,2.04342-82.71358,2.04342s-4.21864,11.33309-4.40864,12.51308C784.584,538.8354,781.63407,563.20539,787.70408,596.5854Z",
          transform: "translate(-194.59814 -107.07168)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M788.12371,571.939a8.56628,8.56628,0,0,0-1.70826-13.02376l7.02037-29.621-14.85847,5.42843-4.3887,27.29637a8.61268,8.61268,0,0,0,13.93506,9.92Z",
          transform: "translate(-194.59814 -107.07168)",
          fill: "#9e616a"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M800.00241,391.58788s7.35831-10.96385,21.84016-25.4457c7.66687-2.55562,20.95087-1.06612,25.55621,0,21.29684,28.11183,22.63717,26.24376,28.18079,41.95076,1.43754,4.073,2.15631,7.98631,1.5174,11.18084-2.55562,12.7781,1.54095,77.34049,2.39283,80.748s6.815,7.66686.85187,7.66686-96.26172-3.40749-94.558-5.96311S800.00241,391.58788,800.00241,391.58788Z",
          transform: "translate(-194.59814 -107.07168)",
          fill: "#e6e7e8"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M808.52115,391.58788h-8.51874s-9.37061,6.815-8.51873,14.48186-9.37061,74.113-9.37061,82.63173-3.4075,11.07436-3.4075,16.1856,1.70375,8.51874,0,11.07436-5.11124,17.88935-4.25936,23.85246a48.04344,48.04344,0,0,1,0,11.92623h15.33372s15.33372-51.96429,18.74122-54.51991a13.10385,13.10385,0,0,0,5.11124-9.37061c0-2.55562-1.70375-2.55562,2.55562-5.96311s1.70375-18.74122,1.70375-18.74122l5.96311-15.33373Z",
          transform: "translate(-194.59814 -107.07168)",
          fill: "#e6e7e8"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "631.42006",
          cy: "223.49989",
          r: "24.66737",
          fill: "#9e616a"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M808.74091,376.79914l-2.31458-10.14193a26.06778,26.06778,0,0,1,3.597-19.93079,18.11866,18.11866,0,0,0,.557-18.48717c-2.68451-4.886-7.31547-7.80329-12.38794-7.80329h-.61594V319.82c0-8.84509,6.89321-15.08809,13.30222-15.446.23158-1.8406,1.53624-3.49052,3.80811-4.80059,5.42616-3.12841,15.91756-3.80691,23.84476.36271a7.5157,7.5157,0,0,1,4.63939-6.14916,7.41279,7.41279,0,0,1,4.05112-.23278,9.17526,9.17526,0,0,1,2.57744-5.91157c2.18826-2.1209,5.69923-2.92691,9.63366-2.21593,5.40269.98044,10.72779,4.42224,14.99485,9.69261,3.78285,4.67247,6.409,10.17922,8.94915,15.50432,2.49744,5.23728,5.07969,10.65261,8.82344,15.23786,4.101,5.02315,9.18254,8.27788,14.30854,9.16449,5.97833,1.03458,12.11366-1.42676,14.92147-5.98375l.55759-.90465.50767.93353c3.15428,5.79607,1.86406,13.76057-3.06887,18.9371-4.42344,4.643-11.36538,7.0081-18.56356,6.32118-6.09442-.57924-12.24118-3.16931-18.27-7.69682-5.98855-4.49683-9.692-9.46765-11.00749-14.7747a40.035,40.035,0,0,1-.791-4.981c-.40782-3.50917-.79218-6.824-2.95158-9.26374-1.93984-2.19368-4.94254-3.144-7.60479-3.86225l-.52271-.14015a6.82673,6.82673,0,0,1-2.91007,4.95819,4.92492,4.92492,0,0,1-1.58977.61232c3.08691,12.424,1.81835,21.9362-3.77563,28.27783-9.44178,10.70615-27.58429,8.50405-28.35241,8.406l-.51068-.06616-.02586-.51429a28.6329,28.6329,0,0,0-.50466-4.10946c-2.59849,6.261-11.34013,22.81739-12.50945,24.48716Z",
          transform: "translate(-194.59814 -107.07168)",
          fill: "#2f2e41"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }