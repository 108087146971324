var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showHeatmap
    ? _c("canvas", {
        staticClass: "heatmap__canvas",
        style: {
          width: _vm.width + "px",
          height: _vm.height + "px"
        },
        attrs: { id: "my-canvas" }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }