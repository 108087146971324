<template>
  <div class="label reslzinsen">
    <div class="result">{{getZinsen}} €</div>
    <div class="truelabel">Zinsen</div>
  </div>
</template>

<script>
//import store from "./../../store.js";
export default {
  name: "ResLZinsen",
  computed: {
    getZinsen() {
      return this.$store.getters.getZinsen;
    }
  },
};
</script>

<style scoped>
.reslzinsen {
  grid-area: reslzinsen;
}
</style>