var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "table",
      { staticClass: "fc-table fc-table-divider fc-table-small" },
      [
        _vm._m(0),
        _vm._l(_vm.positions, function(position, index) {
          return _c("tbody", { key: index }, [
            _c("tr", { staticClass: "noBorder" }, [
              _c("td", [_vm._v(_vm._s(_vm.getTauschVonDepotsName(position)))]),
              _c("td", [_vm._v(_vm._s(_vm.getFondsName(position)))]),
              _c("td", [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-6 px-1" },
                    [
                      _c("InputField", {
                        attrs: {
                          type: _vm.getType(position),
                          isEmbedded: true,
                          showCleanButtonIcon: false,
                          disabled: _vm.disabled(position)
                        },
                        on: {
                          change: function($event) {
                            return _vm.onChangeInputField(
                              $event,
                              position,
                              "hoehe"
                            )
                          }
                        },
                        model: {
                          value: position.hoehe,
                          callback: function($$v) {
                            _vm.$set(position, "hoehe", $$v)
                          },
                          expression: "position.hoehe"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6 px-1" },
                    [
                      _c("ComboBox", {
                        attrs: {
                          isEmbedded: true,
                          values: _vm.comboboxValuesModus
                        },
                        on: {
                          change: function($event) {
                            return _vm.onChangeInputField(
                              $event,
                              position,
                              "modus"
                            )
                          }
                        },
                        model: {
                          value: position["modus"],
                          callback: function($$v) {
                            _vm.$set(position, "modus", $$v)
                          },
                          expression: "position['modus']"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _c(
                "td",
                [
                  _c("WertpapierAuswahlPositionsButtons", {
                    attrs: {
                      position: position,
                      positionName: _vm.getFondsName(position),
                      antragId: _vm.antragId,
                      categoryId: _vm.categoryId
                    }
                  })
                ],
                1
              )
            ]),
            _c("tr", [
              _c(
                "td",
                [
                  _c("InputField", {
                    attrs: { label: "Bemerkung", isEmbsedded: true },
                    on: {
                      change: function($event) {
                        return _vm.onChangeInputField(
                          $event,
                          position,
                          "bemerkung"
                        )
                      }
                    },
                    model: {
                      value: position.bemerkung,
                      callback: function($$v) {
                        _vm.$set(position, "bemerkung", $$v)
                      },
                      expression: "position.bemerkung"
                    }
                  })
                ],
                1
              ),
              _c(
                "td",
                { attrs: { colspan: "3" } },
                [
                  _c("ComboBox", {
                    attrs: {
                      label: "Bestehende Spar-/Auszahlpläne",
                      isEmbedded: true,
                      values: _vm.comboboxValuesPlaene
                    },
                    on: {
                      change: function($event) {
                        return _vm.onChangeInputField(
                          $event,
                          position,
                          "plaene"
                        )
                      }
                    },
                    model: {
                      value: position.plaene,
                      callback: function($$v) {
                        _vm.$set(position, "plaene", $$v)
                      },
                      expression: "position.plaene"
                    }
                  })
                ],
                1
              )
            ])
          ])
        })
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "30%" } }, [_vm._v("Tausch Von")]),
        _c("th", { staticStyle: { width: "30%" } }, [_vm._v("Tausch zu")]),
        _c("th", { staticStyle: { width: "30%" } }, [_vm._v("Höhe")]),
        _c("th", { staticStyle: { width: "10%" } })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }