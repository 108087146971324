export const MUTATION_PREFIX = 'COURTAGELISTE_MUTATIONS_';
export const ACTIONS_PREFIX = 'COURTAGELISTE_ACTIONS_';
export const GETTERS_PREFIX = 'COURTAGELISTE_GETTERS_';

export default {
  MUTATIONS: {
    RETRIEVE_FONDSWERTE_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_FONDSWERTE_SUCCESS',
    RETRIEVE_LAGERSTELLEN_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_LAGERSTELLEN_SUCCESS',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    RESET_LIST: MUTATION_PREFIX + 'RESET_LIST',
  },
  ACTIONS: {
    RETRIEVE_FONDSWERTE: ACTIONS_PREFIX + 'RETRIEVE_FONDSWERTE',
    RETRIEVE_LAGERSTELLEN: ACTIONS_PREFIX + 'RETRIEVE_LAGERSTELLEN',
  },
  GETTERS: {
    FONDSWERTE: GETTERS_PREFIX + 'FONDSWERTE',
    LAGERSTELLEN: GETTERS_PREFIX + 'LAGERSTELLEN',
  }
}