var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("InputField", {
        attrs: {
          label: "Betrachtungszeitraum in Jahren (mindestens 3 Jahre)",
          id: "years",
          disabled: _vm.disabled,
          isComponentHalfSize: ""
        },
        on: {
          change: function($event) {
            _vm.updateStore(_vm.getCategory(), "years", $event)
          }
        },
        model: {
          value: _vm.years,
          callback: function($$v) {
            _vm.years = $$v
          },
          expression: "years"
        }
      }),
      _c("InputCheckBoxGroup", {
        staticClass: "mt-3",
        attrs: {
          value: _vm.formCategories,
          title:
            "Angaben über Kenntnisse und Erfahrungen mit Geschlossenen Investmentvermögen",
          disabled: _vm.disabled,
          values: _vm.categoriesGF
        },
        on: {
          checkBoxToggle: function($event) {
            return _vm.onSelection($event)
          }
        }
      }),
      _c("InputCheckBoxGroup", {
        staticClass: "mt-3",
        attrs: {
          value: _vm.form,
          disabled: _vm.disabled,
          title:
            "In welchen Wirtschaftsräumen investieren die von Ihnen erworbenen geschlossenen Investmentvermögen/Vermögensanlagen?",
          values: [
            { label: "Euroraum", path: "euroraum" },
            { label: "Sonstiges Europa", path: "eurorest" },
            { label: "Nordamerika", path: "nordamerika" },
            { label: "Südamerika", path: "suedamerika" },
            { label: "Asien", path: "asien" },
            { label: "Sonstiges", path: "sonstiges" }
          ]
        },
        on: {
          checkBoxToggle: function($event) {
            _vm.updateStoreCheckBoxGroup(_vm.getCategory(), $event)
          }
        }
      }),
      _c("InputField", {
        attrs: { disabled: _vm.disabled, isComponentHalfSize: "" },
        on: {
          change: function($event) {
            _vm.updateStore(_vm.getCategory(), "sonstigesLand", $event)
          }
        },
        model: {
          value: _vm.sonstigesLand,
          callback: function($$v) {
            _vm.sonstigesLand = $$v
          },
          expression: "sonstigesLand"
        }
      }),
      _c("InputRadioBoxGroup", {
        attrs: {
          title:
            "Haben Sie Käufe für geschlossenes Investmentvermögen auf Kreditbasis?",
          validateUntouched: true,
          disabled: _vm.disabled,
          inlineLabelValues: false,
          values: [
            { value: "no", label: "nein" },
            { value: "yes", label: "ja" }
          ]
        },
        on: {
          input: function($event) {
            _vm.updateStore(
              _vm.getCategory(),
              "investOnCredit",
              _vm.getBooleanValue($event)
            )
          }
        },
        model: {
          value: _vm.investOnCredit,
          callback: function($$v) {
            _vm.investOnCredit = $$v
          },
          expression: "investOnCredit"
        }
      }),
      _vm.investOnCredit === "yes"
        ? _c("InputField", {
            attrs: {
              label: "In welcher Höhe bewegte sich Ihr Kredit?",
              type: "currency",
              disabled: _vm.disabled,
              isComponentHalfSize: ""
            },
            on: {
              change: function($event) {
                _vm.updateStore(_vm.getCategory(), "creditSum", $event)
              }
            },
            model: {
              value: _vm.creditSum,
              callback: function($$v) {
                _vm.creditSum = $$v
              },
              expression: "creditSum"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }