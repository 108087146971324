<template>
  <PhSpinnerGap :size="size" weight="bold" class="animated-spinner__element spin-animation"/>
</template>

<script>
import { PhSpinnerGap } from 'phosphor-vue'

export default {
  props: {
    size: {
      type: Number,
      default: 16,
    },
  },
  components: {
    PhSpinnerGap
  }
}
</script>

<style scoped>

</style>