import VERSICHERUNGSANTRAEGE_TYPES from './types';
import axios from 'axios';

export default {
  async [VERSICHERUNGSANTRAEGE_TYPES.ACTIONS.GET_FILE_DATA]({ rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    let serviceUrl = '/antragCheckVersicherungService/getFileData';   


    if (payload) {
      const id = payload.id;

      if (id) {
        serviceUrl = `${serviceUrl}?id=${id}`;
      } else {
        return; 
      }

    }     

    const response = await axios.get(rootState.core.apiAddress + serviceUrl, config);

    if (response && response.data) {
      return response.data;
    }
  },    
}