export const MUTATION_PREFIX = 'BROKER_SEARCH_MUTATIONS_';
export const ACTIONS_PREFIX = 'BROKER_SEARCH_ACTIONS_';
export const GETTERS_PREFIX = 'BROKER_SEARCH_GETTERS_';

export default {
  MUTATIONS: {
    OPEN_BROKER: MUTATION_PREFIX + 'OPEN_BROKER',
    UPDATE_LAST_OPENED_BROKER_DATA_ONLY: MUTATION_PREFIX + 'UPDATE_LAST_OPENED_BROKER_DATA_ONLY',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
  },
  ACTIONS: {
    TRACK_OPEN_BROKER: ACTIONS_PREFIX + 'TRACK_OPEN_BROKER',
    GET_LAST_BROKERS_OPENED: ACTIONS_PREFIX + 'GET_LAST_BROKERS_OPENED',
  },
  GETTERS: {
    OPEN_BROKERS: GETTERS_PREFIX + 'OPEN_BROKERS',
  }
}