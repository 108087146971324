import MSC_NEWS_TYPES from './types';
import Vue from 'vue'
import { getInitialState } from './index';

export default {
  [MSC_NEWS_TYPES.MUTATIONS.GET_MSC_NEWS_SUCCESS](state, data) {
    Vue.set(state, 'mscNews', data.mscNews)
  },
  [MSC_NEWS_TYPES.MUTATIONS.GET_MSC_NEWS_COUNT_SUCCESS](state, data) {
    Vue.set(state, 'mscNewsCount', data.mscNewsCount)
  },
  [MSC_NEWS_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },
  [MSC_NEWS_TYPES.MUTATIONS.MARK_AS_READ](state, messageIds) {
    for (const messageId of messageIds) {
      const found = state.mscNews?.find(news => news.id === messageId)
      if (found) {
        found.gelesen = true
      }
    }
  },
  [MSC_NEWS_TYPES.MUTATIONS.MARK_AS_UNREAD](state, messageIds) {
    for (const messageId of messageIds) {
      const found = state.mscNews?.find(news => news.id === messageId)
      if (found) {
        found.gelesen = false
      }
    }
  },

  [MSC_NEWS_TYPES.MUTATIONS.GET_FK_EVENTS_SUCCESS](state, payload) {
    const events = payload?.map(event => {
      const startdate = event?.startdate && new Date(event.startdate) || null;
      const enddate = event?.enddate && new Date(event.enddate) || null;
      return {
          ...event,
          startdate: startdate,
          enddate: enddate,
          // startdateFormatted: startdate && `${startdate.toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' })} ${startdateTime?.substring(0, startdateTime.length > 5 ? 5 : startdateTime.length)}` || '',
          startdateFormatted: startdate && `${startdate.toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })}` || '',
          enddateFormatted: enddate && `${enddate.toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' })}` || '',
      }
    }) || [];

    Vue.set(state, 'fkEvents', events || [])
  },

  [MSC_NEWS_TYPES.MUTATIONS.SET_HOME_MSC_LINK](state, mscHomeLink) {
    Vue.set(state, 'mscHomeLink', mscHomeLink)
  },
  
  [MSC_NEWS_TYPES.MUTATIONS.SET_IS_LOADING](state, isLoading) {
    Vue.set(state, 'fkEventsLoading', isLoading)
  },
}