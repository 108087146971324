import TRANSAKTION_VERLAUF_TYPES from './types';


export default {

  [TRANSAKTION_VERLAUF_TYPES.GETTERS.TRANSACTIONS](state) {
    return [...state.transactions];
  },

}
