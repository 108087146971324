var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "draggable--container", style: _vm.draggableContainerStyle },
    [
      _c(
        "div",
        { ref: "draggableItem", staticClass: "draggable--item" },
        [_vm._t("default")],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }