var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "option-menu-config__container" }, [
    _vm.hasLockedMenuItem
      ? _c(
          "div",
          { staticClass: "option-menu-config--locked mb-3" },
          [
            _c("div", { staticClass: "font-bold" }, [_vm._v("fixiert")]),
            _c("OptionMenuConfigList", {
              staticClass: "locked-menus from-struktur",
              attrs: {
                configId: _vm.configId,
                menus: _vm.configuredMenuFromStruktur,
                userLevel: _vm.userLevel,
                configContext: _vm.configContext,
                isPermissionConfigurable: _vm.isPermissionConfigurable,
                configuredMenuIndeterminate: _vm.configuredMenuIndeterminate,
                isExpandedLayout: _vm.isExpandedLayout
              }
            }),
            _c("OptionMenuConfigList", {
              staticClass: "locked-menus",
              attrs: {
                configId: _vm.configId,
                menus: _vm.configuredMenuLocked,
                userLevel: _vm.userLevel,
                configContext: _vm.configContext,
                isPermissionConfigurable: _vm.isPermissionConfigurable,
                configuredMenuIndeterminate: _vm.configuredMenuIndeterminate,
                isLockable: _vm.isLockable,
                isSortable: "",
                isExpandedLayout: _vm.isExpandedLayout
              },
              on: {
                unlock: function($event) {
                  return _vm.onUnlock($event)
                },
                orderChanged: function($event) {
                  return _vm.onConfiguredMenuLockedOrderChanged($event)
                }
              }
            })
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "option-menu-config--configurable" },
      [
        _vm.hasLockedMenuItem
          ? _c("div", { staticClass: "font-bold" }, [_vm._v("Konfigurierbare")])
          : _vm._e(),
        _vm.hasConfigurableMenus
          ? _c("OptionMenuConfigList", {
              attrs: {
                configId: _vm.configId,
                menus: _vm.configuredMenuConfigurable,
                userLevel: _vm.userLevel,
                configContext: _vm.configContext,
                isPermissionConfigurable: _vm.isPermissionConfigurable,
                configuredMenuIndeterminate: _vm.configuredMenuIndeterminate,
                isRemoveable: "",
                isToggleRemoveable: _vm.isToggleRemoveable,
                isLockable: _vm.isLockable,
                isSortable: "",
                isExpandedLayout: _vm.isExpandedLayout
              },
              on: {
                toggleRemoveable: function($event) {
                  return _vm.onToggleRemoveable($event)
                },
                lock: function($event) {
                  return _vm.onLock($event)
                },
                remove: function($event) {
                  return _vm.onRemove($event)
                },
                orderChanged: function($event) {
                  return _vm.onConfiguredMenuConfigurableOrderChanged($event)
                }
              }
            })
          : _c("NoData", { attrs: { noIcon: "", content: "Keine Daten" } })
      ],
      1
    ),
    _vm.hasParentsMenu && _vm.canViewParentsMenu
      ? _c(
          "div",
          { staticClass: "mt-3 mb-3" },
          [
            _c("ComboBox", {
              attrs: { values: _vm.parentMenuValues },
              model: {
                value: _vm.parentMenuPath,
                callback: function($$v) {
                  _vm.parentMenuPath = $$v
                },
                expression: "parentMenuPath"
              }
            })
          ],
          1
        )
      : _vm._e(),
    _vm.optionMenuIntern && _vm.optionMenuIntern.length
      ? _c(
          "div",
          { staticClass: "option-menu-edit-modal__to-add__container" },
          [
            _c("h3", { staticClass: "box__title" }, [_vm._v("Auswahl")]),
            _c("InputField", {
              key: _vm.parentMenuPath,
              staticClass: "p-0",
              attrs: { placeholder: "Suche" },
              model: {
                value: _vm.filterText,
                callback: function($$v) {
                  _vm.filterText = $$v
                },
                expression: "filterText"
              }
            }),
            _vm.optionMenuFiltered.length
              ? _vm._l(_vm.optionMenuGroupedKeysSorted, function(key, index) {
                  return _c(
                    "div",
                    {
                      key: _vm.parentMenuPath + "-" + index,
                      staticClass: "option-menu-edit-modal__group"
                    },
                    [
                      _c("div", {
                        staticClass: "option-menu-edit-modal__group--title",
                        domProps: { innerHTML: _vm._s(_vm.sanitize(key)) }
                      }),
                      _c("OptionMenuConfigList", {
                        attrs: {
                          configId: _vm.configId,
                          isAvailable: "",
                          menus: _vm.optionMenuGrouped[key],
                          userLevel: _vm.userLevel,
                          configContext: _vm.configContext,
                          configuredMenuIndeterminate:
                            _vm.configuredMenuIndeterminate,
                          hideParentLabel: "",
                          isPermissionConfigurable:
                            _vm.isPermissionConfigurable,
                          isExpandedLayout: _vm.isExpandedLayout
                        },
                        on: {
                          add: function($event) {
                            return _vm.onAdd($event)
                          }
                        }
                      })
                    ],
                    1
                  )
                })
              : _c("NoData", { attrs: { noIcon: "" } })
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }