var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.$appNavigation.currentMenu.label,
          subtitle: _vm.subtitle,
          id: _vm.$appNavigation.currentOptionMenuId,
          actions: _vm.actions
        },
        on: {
          "action-DELETE": function($event) {
            return _vm.deleteGoal()
          },
          "action-NEW_TICKET": _vm.newTicket,
          "action-EDIT_TITLE": function($event) {
            return _vm.editTitle()
          },
          "action-BACK_TO_SCENARIO": function($event) {
            return _vm.navigateTo("ruhestandsplanung/view/" + _vm.scenarioId)
          }
        }
      }),
      _c("BaseModal", {
        ref: "errorModal",
        attrs: {
          showDefaultButtons: false,
          modalTitle: "Das Datum darf nicht zurück liegen!"
        }
      }),
      _c(
        "BaseModal",
        {
          ref: "titleModal",
          attrs: {
            modalTitle: "Bezeichnung anpassen",
            confirmDisabled: !_vm.canSaveTitle
          },
          on: {
            onConfirmButton: function($event) {
              _vm.title && _vm.doChanges("title", _vm.title)
            }
          }
        },
        [
          _c("InputField", {
            attrs: { type: "text" },
            model: {
              value: _vm.title,
              callback: function($$v) {
                _vm.title = $$v
              },
              expression: "title"
            }
          })
        ],
        1
      ),
      _c("StepperForm", {
        attrs: {
          stepperName: "myGoals",
          stepperMediator: _vm.stepper,
          hasFertig: false,
          selectedStepKey: _vm.selectedStepKey
        },
        on: { "set-step": _vm.setStepByKey },
        scopedSlots: _vm._u([
          {
            key: "contentTemplate",
            fn: function() {
              return [_c("router-view")]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }