var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "cards__item--header-title" }, [
      _vm.pos.positionTyp !== "NEU_KAUF" &&
      _vm.pos.positionTyp !== "NEU_SPARPLAN"
        ? _c("span", { staticClass: "font-bold" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.isMain && _vm.showRest && _vm.isTest
                    ? "Ursprüngliche "
                    : ""
                ) +
                "Anteile: " +
                _vm._s(_vm.formatNumber(_vm.pos.anteile, 6)) +
                " "
            ),
            _c("span", { staticClass: "text-divider mr-1" }, [_vm._v("|")])
          ])
        : _vm._e(),
      _c("span", { staticClass: "font-bold" }, [
        _vm._v(
          "Anteilspreis: " +
            _vm._s(_vm.formatNumber(_vm.pos.anteilspreis, 2)) +
            " "
        ),
        !_vm.pos.waehrung || _vm.pos.waehrung === "EUR"
          ? _c("span", [_c("PhCurrencyEur", { attrs: { size: 14 } })], 1)
          : _c("span", [_vm._v(_vm._s(_vm.pos.waehrung))])
      ]),
      _vm.pos.waehrung && _vm.pos.waehrung !== "EUR"
        ? _c(
            "span",
            { staticClass: "font-bold" },
            [
              _c("span", { staticClass: "text-divider mr-1" }, [_vm._v("|")]),
              _vm._v(
                " Anteilspreis in EUR: " +
                  _vm._s(_vm.formatNumber(_vm.pos.anteilspreisEuro, 2))
              ),
              _c("PhCurrencyEur", { attrs: { size: 14 } })
            ],
            1
          )
        : _vm._e(),
      _vm.pos.positionTyp !== "NEU_KAUF" &&
      _vm.pos.positionTyp !== "NEU_SPARPLAN"
        ? _c(
            "span",
            { staticClass: "font-bold" },
            [
              _c("span", { staticClass: "text-divider mr-1" }, [_vm._v("|")]),
              _vm._v(
                " Gesamter Wert: " +
                  _vm._s(_vm.formatNumber(_vm.pos.gesamterWert, 2))
              ),
              _c("PhCurrencyEur", { attrs: { size: 14 } })
            ],
            1
          )
        : _vm._e(),
      _vm.pos.sparplaeneBetrag
        ? _c(
            "span",
            { staticClass: "font-bold" },
            [
              _c("span", { staticClass: "text-divider mr-1" }, [_vm._v("|")]),
              _vm._v(
                " Sparpläne Betrag: " +
                  _vm._s(_vm.formatNumber(_vm.pos.sparplaeneBetrag, 2))
              ),
              _c("PhCurrencyEur", { attrs: { size: 14 } })
            ],
            1
          )
        : _vm._e(),
      _vm.pos.entnahmeplaeneBetrag
        ? _c(
            "span",
            { staticClass: "font-bold" },
            [
              _c("span", { staticClass: "text-divider mr-1" }, [_vm._v("|")]),
              _vm._v(
                " Entnahmepläne Betrag: " +
                  _vm._s(_vm.formatNumber(_vm.pos.entnahmeplaeneBetrag, 2))
              ),
              _c("PhCurrencyEur", { attrs: { size: 14 } })
            ],
            1
          )
        : _vm._e()
    ]),
    (_vm.isMain || _vm.isTausch) && _vm.showRest && _vm.isTest
      ? _c("div", { staticClass: "cards__item--header-title font-bold" }, [
          _vm._v(
            " Noch verfügbare Anteile: " +
              _vm._s(_vm.getVerfuegbareAnteile) +
              " "
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }