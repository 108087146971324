import KUNDEN_GESELL_NR_TYPES from './types';

export default {

  [KUNDEN_GESELL_NR_TYPES.GETTERS.LIST](state) {
    return [ ...state.list, ];
  },

  [KUNDEN_GESELL_NR_TYPES.GETTERS.COMBO_DATA](state) {
    return { ...state.comboData || {}, };
  },

  [KUNDEN_GESELL_NR_TYPES.GETTERS.DATA](state) {
    return { ...state.data || {}, };
  },

  [KUNDEN_GESELL_NR_TYPES.GETTERS.DATA_EDITED](state) {
    return { ...state.edited || {}, };
  },

}
