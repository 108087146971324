<template>
  <div>
    <BaseModal ref="deleteModal" 
      modalTitle="Eintrag löschen"
      labelButtonConfirm="Eintrag entfernen"
      @onConfirmButton="onConfirmModal()"
      @onCancelButton="onCancelModal()">
      
      <div>
        {{getMessage}}
      </div>
    </BaseModal>
  </div>
</template>

<script>
import BaseModal from '@/components/core/BaseModal.vue';

export default {
    components: {
      BaseModal,
    },
    data() {
      return {
        vertragStatus: {status: '', gueltigab: ''}
      }
    },
    computed: {
      getMessage() {
        return `Soll der Eintrag ${this.vertragStatus.status} ab ${this.vertragStatus.gueltigab} wirklich gelöscht werden?`;
      },
    },
    methods: {
      open(vetragStatus) {
        this.vertragStatus = vetragStatus;
        this.$refs.deleteModal.open();
      },
      onConfirmModal() {
        this.$emit('delete');
        this.$refs.deleteModal.close();
      },
      onCancelModal() {
          this.$refs.deleteModal.close();
      },
    }
}
</script>
