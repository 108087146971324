import V_P from './types';

import axios from 'axios';
import CORE_TYPES from "@/store/core/types";
import { doUpload } from '@/helpers/versandProtokolle';
import COMMUNICATION_TYPES from "@/store/communication/types";
import router from '../../router';
export default {


   
  
  
  async [V_P.ACTIONS.FILTER]({ commit, getters, dispatch, state, rootState }, e) {
   let par ={};
   
 
    const config = {
      defaultSpinner: true,
      cancelableRequest: {
        title: 'SWipgate'
      }
    };
    
    await axios.get(rootState.core.apiAddress + '/Sipgate/getList',config).then(response => {
      
      state.FILTER=response.data;
      commit(V_P.MUTATIONS.FILTER, response.data);
    
     
    })
  },

  async [V_P.ACTIONS.DELETE]({ commit, getters, dispatch, state, rootState }, e) {
    let par ={};
    
  
     const config = {
       defaultSpinner: true,
       cancelableRequest: {
         title: 'SWipgate'
       }
     };
     
     await axios.delete(rootState.core.apiAddress + '/Sipgate/delete?email='+e,config).then(response => {
       
       state.FILTER=response.data;
       commit(V_P.MUTATIONS.FILTER, response.data);
     
      
     })
   },
    
   async [V_P.ACTIONS.INSERT]({ commit, getters, dispatch, state, rootState }, e) {
    let par ={};
    
  
     const config = {
       defaultSpinner: true,
       cancelableRequest: {
         title: 'SWipgate'
       }
     };
     let ur ='/Sipgate/add?email='+e.email+"&personalAccessId="+e.personalAccessId+"&personalAccesToken="+e.personalAccesToken;
     console.log(ur);
     await axios.post(rootState.core.apiAddress + ur,config) .then(response => {
       
       state.FILTER=response.data;
       commit(V_P.MUTATIONS.FILTER, response.data);
     
      
     })
   },

}


