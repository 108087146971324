import axios from 'axios';

import CORE_TYPES from '@/store/core/types';
import OTHER_PRODUCTS_TYPES from './types';
import LOG_TYPES from '@/store/log/types';
import BRIDGE_TYPES from '@/store/bridge/types';

import { buildMessage, } from '@/helpers/log-message-helper';
import { viewDocument } from '@/components/core/download/DownloadLink.vue';
import { getRedirectionWindow, } from '@/components/table/export/utils';

import { SPROD_ID_NEW, } from '@/components/otherProducts/otherProductsUtils.js';

const config = {
  defaultSpinner: true
};


export default {

  [OTHER_PRODUCTS_TYPES.ACTIONS.GET_FILTER_COMBOS]({ getters, commit }) {
    const type = getters[OTHER_PRODUCTS_TYPES.GETTERS.CURRENT_TYPE];
    if(!type) return;

    axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/sprod/filterCombos?sprodTyp=${type}`, config)
      .then(response => {
        const data = response?.data || {};
        commit(OTHER_PRODUCTS_TYPES.MUTATIONS.GET_FILTER_COMBOS_SUCCESS, { type, data, });
      })
      .catch(error => console.log(error));
  },

  async [OTHER_PRODUCTS_TYPES.ACTIONS.FIND_OTHER_PRODUCTS]({ getters, commit }, { filters = {}, type }) {
    const typeName = type || getters[OTHER_PRODUCTS_TYPES.GETTERS.CURRENT_TYPE];
    if(!typeName) return;
    const pinName = getters[OTHER_PRODUCTS_TYPES.GETTERS.CURRENT_PIN_NAME];

    await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/sprod/filtersp?type=${typeName}`, filters, config)
      .then(response => {
        const data = response?.data || [];
        if (pinName && typeof data.askPIN == 'number') {
          commit(CORE_TYPES.MUTATIONS.CHECK_PIN, {
            key: pinName, 
            value: data.askPIN,
            action: OTHER_PRODUCTS_TYPES.ACTIONS.FIND_OTHER_PRODUCTS,
            payload: JSON.stringify({ filters, type: typeName })
          })
        }
        commit(OTHER_PRODUCTS_TYPES.MUTATIONS.FIND_OTHER_PRODUCTS_SUCCESS, { type: typeName, data: data.liste, });
      })
      .catch(error => console.log(error));
  },

  [OTHER_PRODUCTS_TYPES.ACTIONS.GET_COMBOS]({ getters, commit }) {
    const type = getters[OTHER_PRODUCTS_TYPES.GETTERS.CURRENT_TYPE];
    if(!type || Object.keys(getters[OTHER_PRODUCTS_TYPES.GETTERS.COMBOS]).length > 0) return;

    axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/sprod/combos?type=${type}`, config)
      .then(response => {
        const data = response?.data || {};
        commit(OTHER_PRODUCTS_TYPES.MUTATIONS.GET_COMBOS_SUCCESS, { type, data, });
      })
      .catch(error => console.log(error));
  },

  [OTHER_PRODUCTS_TYPES.ACTIONS.GET_OTHER_PRODUCT]({ getters, commit }) {
    const sprodId = getters[OTHER_PRODUCTS_TYPES.GETTERS.SELECTED_SPROD_ID];
    if(!sprodId || getters[OTHER_PRODUCTS_TYPES.GETTERS.IS_NEW]) return;

    axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/sprod/get${sprodId}`, config)
      .then(response => {
        const data = response?.data || {};
        const type = data?.sprod?.produktTyp;
        commit(OTHER_PRODUCTS_TYPES.MUTATIONS.GET_OTHER_PRODUCT_SUCCESS, data);
        commit(OTHER_PRODUCTS_TYPES.MUTATIONS.GET_COMBOS_SUCCESS, { type, data });
      })
      .catch(error => console.log(error));
  },

  async [OTHER_PRODUCTS_TYPES.ACTIONS.SAVE_OTHER_PRODUCT]({ getters, commit }) {
    const edited = getters[OTHER_PRODUCTS_TYPES.GETTERS.OTHER_PRODUCT_EDITED];
    if(!edited?.id) return;

    if(edited.id === SPROD_ID_NEW) {
      edited.id = null;
    }

    await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/sprod/createOrUpdate`, edited, config)
      .then(response => {
        const data = response.data || {};
        commit(OTHER_PRODUCTS_TYPES.MUTATIONS.RESET_OTHER_PRODUCT_EDITED);
        if(getters[OTHER_PRODUCTS_TYPES.GETTERS.SELECTED_SPROD_ID] === SPROD_ID_NEW) {
          commit(OTHER_PRODUCTS_TYPES.MUTATIONS.SET_SELECTED_SPROD_ID, data.sprod?.id);
        }
        commit(OTHER_PRODUCTS_TYPES.MUTATIONS.GET_OTHER_PRODUCT_SUCCESS, data);
      })
      .catch(error => console.log(error));
  },

  async [OTHER_PRODUCTS_TYPES.ACTIONS.DELETE_OTHER_PRODUCT]({ getters }, { sprodId }) {
    if(!sprodId) return;

    await axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/sprod/delete${sprodId}`, config)
      .catch(error => console.log(error));
  },

  async [OTHER_PRODUCTS_TYPES.ACTIONS.FIND_UPDATES]({ getters, commit }) {
    const sprodId = getters[OTHER_PRODUCTS_TYPES.GETTERS.SELECTED_SPROD_ID];
    if(!sprodId || getters[OTHER_PRODUCTS_TYPES.GETTERS.IS_NEW]) return;

    await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/sprod/ListUpdates${sprodId}`, config)
      .then(response => {
        commit(OTHER_PRODUCTS_TYPES.MUTATIONS.FIND_UPDATES_SUCCESS, response?.data || []);
      })
      .catch(error => console.log(error));
  },

  async [OTHER_PRODUCTS_TYPES.ACTIONS.SAVE_UPDATE]({ getters, dispatch }, payload) {
    const sprodId = getters[OTHER_PRODUCTS_TYPES.GETTERS.SELECTED_SPROD_ID];
    if(!sprodId || getters[OTHER_PRODUCTS_TYPES.GETTERS.IS_NEW]) return;
    if(!payload?.reason || !payload?.date) return;

    const update = {
      ...payload,
      sonstProdId: sprodId,
    };
    delete update.action
    await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/sprod/setUpdate`, update, config)
      .then(_ => {
        dispatch(OTHER_PRODUCTS_TYPES.ACTIONS.FIND_UPDATES);
        dispatch(OTHER_PRODUCTS_TYPES.ACTIONS.GET_OTHER_PRODUCT);
      })
      .catch(error => console.log(error));
  },

  [OTHER_PRODUCTS_TYPES.ACTIONS.DELETE_UPDATE]({ getters, dispatch }, { updateId }) {
    if(!updateId) return;

    axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/sprod/deleteUpdate${updateId}`, config)
      .then(_ => {
        dispatch(OTHER_PRODUCTS_TYPES.ACTIONS.FIND_UPDATES);
        dispatch(OTHER_PRODUCTS_TYPES.ACTIONS.GET_OTHER_PRODUCT);
      })
      .catch(error => console.log(error));
  },

  async [OTHER_PRODUCTS_TYPES.ACTIONS.FIND_DOCUMENTS]({ getters, commit }) {
    const sprodId = getters[OTHER_PRODUCTS_TYPES.GETTERS.SELECTED_SPROD_ID];
    if(!sprodId || getters[OTHER_PRODUCTS_TYPES.GETTERS.IS_NEW]) return;

    await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/sprod/dokumente${sprodId}`, config)
      .then(response => {
        const documents = response.data || [];
        commit(OTHER_PRODUCTS_TYPES.MUTATIONS.FIND_DOCUMENTS_SUCCESS, [...documents]);
      })
      .catch(error => console.log(error));
  },

  async [OTHER_PRODUCTS_TYPES.ACTIONS.ADD_DOCUMENT]({ getters, commit }, payload) {
    const sprodId = getters[OTHER_PRODUCTS_TYPES.GETTERS.SELECTED_SPROD_ID];
    if(!sprodId || getters[OTHER_PRODUCTS_TYPES.GETTERS.IS_NEW]) return;
    if(!payload?.tempFileId) return;

    const tempFileId = payload.tempFileId;
    const formData = payload?.formData || {};
    await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/sprod/addDokument${sprodId}?tempFileId=${tempFileId}`, formData, config)
      .then(response => {
        const fileId = response.data || '';
        commit(OTHER_PRODUCTS_TYPES.MUTATIONS.ADD_DOCUMENT_SUCCESS, { fileId, });
      })
      .catch(error => console.log(error));
  },

  async [OTHER_PRODUCTS_TYPES.ACTIONS.DOWNLOAD_DOCUMENT]({ getters, }, { fileId }) {
    const sprodId = getters[OTHER_PRODUCTS_TYPES.GETTERS.SELECTED_SPROD_ID];
    if(!sprodId || getters[OTHER_PRODUCTS_TYPES.GETTERS.IS_NEW]) return;

    const windowRef = !getters[BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT] ? getRedirectionWindow() : null;

    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/sprod/downloadDokument${sprodId}?fileId=${fileId}`, config)
      .catch(error => {
        console.log(error);
      });

    const data = response?.data || {};
    if (data?.bytes) {
      const { bytes, fileName } = data;
      const contentType = data?.responseContentType?.mimeType || 'application/pdf';
      viewDocument({
        data: bytes,
        filename: fileName,
        contentType,
        windowRef,
      });
    }
  },

  async [OTHER_PRODUCTS_TYPES.ACTIONS.DELETE_DOCUMENT]({ getters }, { fileId }) {
    const sprodId = getters[OTHER_PRODUCTS_TYPES.GETTERS.SELECTED_SPROD_ID];
    if(!sprodId || getters[OTHER_PRODUCTS_TYPES.GETTERS.IS_NEW]) return;
    if(!fileId) return;

    await axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/sprod/deleteDokument${sprodId}?fileId=${fileId}`, config)
      .catch(error => console.log(error));
  },

  async [OTHER_PRODUCTS_TYPES.ACTIONS.FIND_APPOINTMENTS]({ getters, commit }) {
    const sprodId = getters[OTHER_PRODUCTS_TYPES.GETTERS.SELECTED_SPROD_ID];
    if(!sprodId || getters[OTHER_PRODUCTS_TYPES.GETTERS.IS_NEW]) return;

    await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/sprod/appointments${sprodId}`, config)
      .then(response => {
        commit(OTHER_PRODUCTS_TYPES.MUTATIONS.FIND_APPOINTMENTS_SUCCESS, response?.data || []);
      })
      .catch(error => console.log(error));
  },

  async [OTHER_PRODUCTS_TYPES.ACTIONS.CREATE_APPOINTMENT]({ getters, dispatch }) {
    const sprodId = getters[OTHER_PRODUCTS_TYPES.GETTERS.SELECTED_SPROD_ID];
    if(!sprodId || getters[OTHER_PRODUCTS_TYPES.GETTERS.IS_NEW]) return;

    await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/sprod/createAppointment${sprodId}`, {}, config)
      .catch(error => {
        console.log(error);
        if(error?.response?.data?.message) {
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error?.response?.data?.message, 'danger'));
        }
      });
  },

  async [OTHER_PRODUCTS_TYPES.ACTIONS.FIND_BUCHUNGEN]({ getters, commit }) {
    const sprodId = getters[OTHER_PRODUCTS_TYPES.GETTERS.SELECTED_SPROD_ID];
    if(!sprodId || getters[OTHER_PRODUCTS_TYPES.GETTERS.IS_NEW]) return;

    await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/sprod/buchungen${sprodId}`, config)
      .then(response => {
        commit(OTHER_PRODUCTS_TYPES.MUTATIONS.FIND_BUCHUNGEN_SUCCESS, response?.data || []);
      })
      .catch(error => console.log(error));
  },

  async [OTHER_PRODUCTS_TYPES.ACTIONS.FIND_BUCHUNG_PROV_STRUKTUREN]({ getters, commit }) {
    const buchung = getters[OTHER_PRODUCTS_TYPES.GETTERS.SELECTED_BUCHUNG];
    if(!buchung || !buchung?.id) return;

    await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/sprod/buchungProvStrukturen${buchung.id}`, config)
      .then(response => {
        commit(OTHER_PRODUCTS_TYPES.MUTATIONS.FIND_BUCHUNG_PROV_STRUKTUREN_SUCCESS, response?.data || []);
      })
      .catch(error => console.log(error));
  },

}
