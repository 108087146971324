import Vue from 'vue';

import CALENDAR_SETTINGS_TYPES from './types';
import { getInitialState, } from './index';

export default {

  [CALENDAR_SETTINGS_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState());
  },

  [CALENDAR_SETTINGS_TYPES.MUTATIONS.SET_COMBO_DATA](state, payload) {
    Vue.set(state, 'comboData', { ...payload || {}, });
  },

  [CALENDAR_SETTINGS_TYPES.MUTATIONS.SET_INTERN_PERSONEN](state, payload) {
    Vue.set(state, 'internPersonen', [ ...payload || [], ]);
  },

  [CALENDAR_SETTINGS_TYPES.MUTATIONS.SET_SETTINGS_DATA](state, payload) {
    Vue.set(state, 'settingsData', { ...payload || {}, });
  },

  [CALENDAR_SETTINGS_TYPES.MUTATIONS.ADD_SETTINGS_DATA_EDITED](state, payload) {
    Vue.set(state, 'settingsDataEdited', {
      ...state.settingsData || {},
      ...state.settingsDataEdited || {},
      ...payload,
    });
  },

  [CALENDAR_SETTINGS_TYPES.MUTATIONS.RESET_SETTINGS_DATA_EDITED](state) {
    Vue.set(state, 'settingsDataEdited', null);
  },

  [CALENDAR_SETTINGS_TYPES.MUTATIONS.SET_FEIERTAGE_GRUPPEN_SPEZIAL](state, payload) {
    Vue.set(state, 'feiertageGruppenSpezial', [ ...payload || [], ]);
  },

  [CALENDAR_SETTINGS_TYPES.MUTATIONS.SET_FEIERTAGE_SPEZIAL](state, payload) {
    Vue.set(state, 'feiertageSpezial', [ ...payload || [], ]);
  },

}
