var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { attrs: { id: "wrapper" } },
      [
        _c("LineChart", {
          attrs: {
            xAxisType: "category",
            chartSeries: _vm.series(),
            xLabels: _vm.xLabels,
            markAreaData: _vm.markAreaData,
            markPointData: _vm.markPointData,
            isTooltip: false,
            isArea: false,
            height: "370px"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }