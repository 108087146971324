<template>
  <div>
      <BoxContainer title="Kennnummern und Klassifizierung">
          <dl class="row ml-0" v-for="(idNumbersClassifications, index) of fundsDetailList" :key="'idNumbersClassifications'+index">
              <dt class="pl-0 col-md-3">{{getDetailLabel(idNumbersClassifications)}}</dt>
              <dd class="pl-0 col-md-9">
                  <div v-if="idNumbersClassifications.dataType === 'Double'" >
                      <span>{{idNumbersClassifications.value + ' €'}}</span>
                  </div>
                  <div v-else-if="idNumbersClassifications.dataType === 'Procent'" >
                      <span>{{idNumbersClassifications.value + ' %'}}</span>
                  </div>
                  <span v-else>{{idNumbersClassifications.value}}</span>
              </dd>
          </dl>
      </BoxContainer>

      <BoxContainer :title="schwerpunktLabel">
        <dl class="row" v-for="(schwerpunkt, index) of schwerpunktRecords" :key="'schwerpunkt' + index">
            <dt class="col-md-1">{{schwerpunkt.type + ':'}}</dt>
            <dd class="col-md-11">{{schwerpunkt.value}}</dd>
        </dl>
      </BoxContainer>

      <BoxContainer :title="kostenLabel">
        <dl class="row" v-for="(kosten, index) of kostenRecords" :key="'kosten'+index">
            <dt class="col-sm-3 col-md-5 col-lg-3">{{kosten.type + ':'}}</dt>
            <dd class="col-sm-9 col-md-7 col-lg-9">{{kosten.value}}</dd>
        </dl>
      </BoxContainer>

      <div class="box__container">
        <div class="box__title">{{kostenLabel}}</div>
          <div class="text_wrap" v-html="sanitize(allgemeinDescription)"></div>
      </div>
      
  </div>
</template>

<script>
import FONDSINFO_TYPES from "@/store/fondsinfo/types";
import BoxContainer from '@/components/core/BoxContainer.vue';
import { mapGetters } from 'vuex';
import {sanitize} from '@/helpers/string-helper.js';

export default {
  components: {
    BoxContainer,
  },
  name: "TabAllgemein",
  props:{
    selectedTabLabel: {
      type: String
    }
  },
  computed: {
    ...mapGetters({fundsDetail : FONDSINFO_TYPES.GETTERS.FONDSINFO_DETAILS,
                  allgemeinDescription: FONDSINFO_TYPES.GETTERS.FONDSINFO_ALGEMEIN}),
    safeDescriptionHtml() {
      return this.santinized.bypassSecurityTrustHtml(this.allgemeinDescription);
    },
    schwerpunktLabel () {
      return this.fundsDetail?.schwerpunkt?.label || ''
    },
    schwerpunktRecords () {
      return this.fundsDetail?.schwerpunkt?.records || [];
    },
    kostenLabel() {
      return this.fundsDetail?.kosten?.label || '';
    },
    kostenRecords() {
      return this.fundsDetail?.kosten?.records || []
    },
    fundsDetailList(){
      if(this.fundsDetail) {
        return this.fundsDetail?.kennnummernUndKlassifizierung;
      }
    },
  },
  methods: {
    sanitize(htmlString) {
        return sanitize(htmlString);
    },
    getDetailLabel(idNumbersClassifications){
      return idNumbersClassifications.label;
    },
  },
  mounted() {
    if(!this.fundsDetail || !this.allgemeinDescription){
      this.$store.dispatch(FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_ALGEMEIN, 
      {
        isin: this.$route.params?.isin,
        bgsNr: this.$route.params?.bgsNr,
      });
    }
  },

}
</script>

<style lang="scss" scope>
.text_wrap {
  width: 100%;
  word-break: break-word;

  table tbody tr:first-child {
    word-break: keep-all;
  }

}

</style>