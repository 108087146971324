var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: "Vermittlerwechsel Status Bearbeiten" }
      }),
      _vm.formData && _vm.dataForEditing && _vm.dataForEditing.depotnrInputs
        ? _c(
            "div",
            {
              staticClass: "box__container",
              attrs: { tid: "787c911e-ad46-44ae-bfcb-7bdff06ebc05" }
            },
            [
              _c("ComboBox", {
                attrs: { label: "Gesellschaft", values: _vm.gesellschaften },
                on: {
                  change: function($event) {
                    return _vm.getBeispielKontonr($event)
                  }
                },
                model: {
                  value: _vm.dataForEditing.selectedGesellschaft,
                  callback: function($$v) {
                    _vm.$set(_vm.dataForEditing, "selectedGesellschaft", $$v)
                  },
                  expression: "dataForEditing.selectedGesellschaft"
                }
              }),
              _c("InputField", {
                attrs: { label: "Depotnummer" },
                model: {
                  value: _vm.dataForEditing.depotnrInputs[0].value,
                  callback: function($$v) {
                    _vm.$set(_vm.dataForEditing.depotnrInputs[0], "value", $$v)
                  },
                  expression: "dataForEditing.depotnrInputs[0].value"
                }
              }),
              _vm._l(
                _vm.dataForEditing.depotnrInputs.slice(
                  1,
                  _vm.dataForEditing.depotnrInputs.length
                ),
                function(input, count) {
                  return _c(
                    "div",
                    { key: count },
                    [
                      _c("InputField", {
                        attrs: {
                          label: "weitere",
                          "v-model": "formData.depotnummer_" + count
                        }
                      })
                    ],
                    1
                  )
                }
              ),
              _c("InputToggleSwitch", {
                attrs: {
                  label: "die Depotnummer ist nicht bekannt",
                  inLineLabel: "",
                  suppressValidationMessage: ""
                },
                model: {
                  value: _vm.formData.mitDepotnrUnbekannt,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "mitDepotnrUnbekannt", $$v)
                  },
                  expression: "formData.mitDepotnrUnbekannt"
                }
              }),
              _c("InputField", {
                attrs: { label: "Beispiel", disabled: true },
                model: {
                  value: _vm.beispielkontonr.value,
                  callback: function($$v) {
                    _vm.$set(_vm.beispielkontonr, "value", $$v)
                  },
                  expression: "beispielkontonr.value"
                }
              })
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }