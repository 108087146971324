<template>
  <div v-if="position">
    <div class="row">
      <div class="col-12 font-bold">{{ position.fondsname}}</div>
      <div class="col-12" v-if="!position.hasError">  
        <div class="row">
          <div class="col"><span class="subtitle">Depotposition über:</span> <span>{{ betrag(position.betrag)}}&#8364;</span></div>
          <div class="col"><span class="subtitle">Anlagehorizont:</span> {{ position.horizont}}</div>
          <div class="col"><span class="subtitle">Verlusttragfähigkeit:</span> {{ position.verlust}}</div>
          <div class="col"><span class="subtitle">Anlageziel:</span> {{ position.anlageziel}}</div>
          <div class="col"><span class="subtitle">Anlageziel speziell:</span> {{ position.anlagezielSpeziell}}</div>
          <div class="col-12 col-md-6">
            <ComboBox
              label="Zuordnen zu Anlageziel"
              v-model="zeileCombo" 
              :values="zeileComboValues"
              @change="doChange($event)">
            </ComboBox>
          </div>
        </div>
      </div>
      <div class="col-12" v-else>
        <div class="row">
          <div class="col"><span class="subtitle">Depotposition über:</span> <span>{{ betrag(position.betrag)}}<PhCurrencyEur :size="16" /></span></div>
          <div class="col color-danger">Es sind keine Zielmarkdaten vorhanden</div>
          <div class="col col-md-6">
            <ComboBox
              label="Kunde möchte halten in"
              v-model="zeileCombo" 
              :values="zeileComboValues"
              @change="doChange($event)">
            </ComboBox>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ComboBox from '@/components/core/forms/ComboBox.vue';
export const ANLAGEZIELE_ZEILE_COMBO = [ {value: '', label: 'bitte auswählen'}, {value: 'zeile1', label: 'Zeile 1'}, {value: 'zeile2', label: 'Zeile 2'}, {value: 'zeile3', label: 'Zeile 3'}, {value: 'zeile4', label: 'Zeile 4'}, {value: 'zeile5', label: 'Zeile 5'}];
import { formatNumber } from '@/helpers/number-formatter.js'
import { PhCurrencyEur } from "phosphor-vue";

export default {
  components: {
    ComboBox,
    PhCurrencyEur,
  },
  props: {
    position: {},
  },
  watch: {
    position(value) {
      this.zeileCombo = this.position?.zeile;
    }
  },
  data() {
    return {
      zeileCombo: '',
      zeileComboValues: ANLAGEZIELE_ZEILE_COMBO,
    }
  },
  mounted() {
    this.zeileCombo = this.position?.zeile;
  },
  methods: {
    betrag(betrag) {
      return formatNumber(betrag, 2);
    },
    doChange(event) {
      this.$emit('zeileChanged', event);
    }
  }
}
</script>

<style scoped>
  .subtitle {
    font-size: smaller;
    font-style: italic;
  }
</style>
