<template>
  <div>
    <PageHeaderTitleNavigation 
      title="Einstellungen" 
      :subtitle="configGroupLabel" 
      :actions="headerActions" 
      @action-DISCARD="discardChanges()" 
    />

    <HinweiseUndFehler v-if="configHints.length || configErrors.length" :hints="configHints" :errors="configErrors" />

    <div v-if="!isLoading && !hasComponentsDef" class="box__container">
      <NoData />
    </div>
    <template v-else-if="isSmallOrMediumScreen">
      <div v-if="isLoading" class="box__container">
        <GhostLoading type="block" :config="{ block: { height: '250px', }, }" />
      </div>
      <ConfigStepper3 
        v-else
        :configStep="componentsDef"
        :configData="data"
        :selectedStep="selectedStep"
        :selectedSubstep="selectedSubstep"
        @setStep="onSetStep"
        @setSubstep="onSetSubstep"
      />
    </template>
    <ContentWithStepper v-else :stepperCollapsed="stepperCollapsed">
      <template #stepper>
        <ConfigVerticalStep
          :configStep="componentsDef"
          :configData="data"
          :selectedStep="selectedStep"
          :selectedSubstep="selectedSubstep"
          :disabled="isLoading"
          @collapsed="stepperCollapsed = $event"
          @setStep="onSetStep"
          @setSubstep="onSetSubstep"
        />
      </template>

      <template #content>
        <div class="antrag-components__container">
          <div v-if="isLoading" class="box__container">
            <GhostLoading type="block" :config="{ block: { height: '120px', }, }" />
          </div>
          <template v-else>
            <div class="box__container substep-container" v-for="(componentList, index) in componentsDefByDivider" 
              :key="index" :data-position="`${componentsDefByStep.stepKey}-${index}`">
              <template v-for="(component, index) in componentList">
                <div v-if="!isComponentHidden(component)" :key="index + component.id" class="antrag-component" 
                  :class="{'indented' : component.config && component.config.indented, }"
                >
                  <AntragComponent 
                    :component="component"
                    :data="allData"
                    :disabled="component.config && component.config.disabled"
                    :comboboxSelection="getComboboxSelection(component)"
                    isComponentHalfSizeEnabled
                    @dataChange="onDataChange($event)"
                    @executeConfigButton="$emit('executeConfigButton', $event)"
                  />
                </div>
              </template>
            </div>
          </template>
        </div>
      </template>
    </ContentWithStepper>

    <BaseModal
      ref="editModal"
      :modalTitle="editModalTitle"
      size="lg"
      :showDefaultButtons="false"
      :actions="modalActions"
      @action-DISCARD="discardChanges()"
      @opened="scrollToSelectedSubstep(false)"
      @close="saveChanges"
    >
      <template #default>
        <div class="antrag-components__container">
          <div v-if="isLoading" class="box__container">
            <GhostLoading type="block" :config="{ block: { height: '120px', }, }" />
          </div>
          <template v-else>
            <div class="box__container substep-container" v-for="(componentList, index) in componentsDefByDivider" 
              :key="index" :data-position="`${componentsDefByStep.stepKey}-${index}`">
              <template v-for="(component, index) in componentList">
                <div v-if="!isComponentHidden(component)" :key="index + component.id" class="antrag-component" 
                  :class="{'indented' : component.config && component.config.indented, }"
                >
                  <AntragComponent 
                    :component="component"
                    :data="allData"
                    :disabled="component.config && component.config.disabled"
                    :comboboxSelection="getComboboxSelection(component)"
                    isComponentHalfSizeEnabled
                    @dataChange="onDataChange($event)"
                    @executeConfigButton="$emit('executeConfigButton', $event)"
                  />
                </div>
              </template>
            </div>
          </template>
        </div>
      </template>
    </BaseModal>

    <!-- <pre>data={{ data }}</pre> -->
    <!-- <pre>allData={{ allData }}</pre> -->
    <!-- <pre>componentsDefByStep={{ componentsDefByStep }}</pre> -->
  </div>

</template>

<script>
import { mapGetters } from 'vuex';
import CONFIG_DEFINITION_TYPES from '@/store/configDefinition/types';

import AntragComponent from '@/components/antrag/AntragComponent.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import HinweiseUndFehler from '@/components/core/HinweiseUndFehler.vue';
import ConfigVerticalStep from '@/components/core/configs/ConfigVerticalStep.vue'
import ConfigStepper3 from '@/components/core/configs/ConfigStepper3.vue';
import ContentWithStepper from '@/components/core/ContentWithStepper.vue'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import { sanitize } from '@/helpers/string-helper.js';
import { componentValidatorToRule, isHiddenDisabled } from '@/components/antrag/antrag-utils';

import { findConfigComponentsGroupedByDivider, } from '@/views/core/configs/configs-utils';

import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import { BaseModalSimpleAction, } from '@/components/core/base-modal-actions/base-modal-actions-utils';

import validator from '@/mixins/validator';

export default {
  mixins: [validator],
  props: {
    componentsDef: {
      type: Array,
      default: () => []
    },
    data: {
      type: Object,
      default: () => {}
    },
    changedData: {
      type: Object,
      default: () => {}
    },
    defaultData: {
      type: Object,
      default: () => {}
    },
    configGroupLabel: {
      type: String,
      default: ''
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      stepperCollapsed: false,
      selectedStep: null,
      selectedSubstep: null,
    }
  },
  computed: {
    ...mapGetters({
      hasDataChanged: CONFIG_DEFINITION_TYPES.GETTERS.HAS_CHANGED_CONFIGS,
    }),
    isSmallOrMediumScreen() {
      return this.$isSmallScreen || this.$isMediumScreen;
    },
    editModalTitle() {
      return this.componentsDefByStep?.label || 'Einstellung';
    },
    headerActions() {
      const { hasDataChanged, isLoading, isSmallOrMediumScreen } = this;

      if (isSmallOrMediumScreen) return [];

      return [
        PageHeaderSimpleAction('DISCARD', 'Änderungen verwerfen').withDisabled(() => !hasDataChanged || isLoading),
      ];
    },
    modalActions() {
      const { hasDataChanged, isLoading, } = this;
      return [
        BaseModalSimpleAction('DISCARD', 'Änderungen verwerfen').withDisabled(() => !hasDataChanged || isLoading),
      ];
    },
    hasComponentsDef() {
      return !!this.componentsDef?.length;
    },
    dynamicValidators() {
      const allComponents = this.componentsDef.flatMap(step => step?.configComponents || []);

      const dynamicValidators = allComponents
        .filter(component => component.id && component.validators?.length)
        .map(component => ({
          componentId: component.id,
          validators: component.validators.map(componentValidatorToRule),
        }))

      return dynamicValidators;
    },
    allData() {
      return {
        ...this.data || {},
        ...this.changedData || {},
      };
    },
    configHints() {
      return []; // TODO return the correctly value from backend
    },
    configErrors() {
      return []; // TODO return the correctly value from backend
    },
    componentsDefByStep() {
      let resultStep = this.componentsDef?.[0];
      if (this.selectedStep) {
        resultStep = this.componentsDef.find(step => step.stepKey === this.selectedStep)
      }

      if (resultStep?.configComponents) {
        resultStep.configComponents = resultStep?.configComponents?.map(this.applyConfigsIntoComponents)
      }

      return resultStep
    },
    componentsDefByDivider() {
      return findConfigComponentsGroupedByDivider(this.componentsDefByStep)
        .filter(group => group.length > 0);
    },
  },
  watch: {
    componentsDef: {
      handler() {
        if (!this.isSmallOrMediumScreen) {
          this.setSelectedStepByIndex(0);
        }
      },
      immediate: true,
    },
    dynamicValidators: {
      immediate: true,
      handler(newDynamicValidators) {
        for (const dynamicValidator of newDynamicValidators) {
          if (dynamicValidator && this.$configureValidators) {
            this.$configureValidators({ 
              [dynamicValidator.componentId]: dynamicValidator.validators,
            });
          }
        }
      },
    },
  },
  methods: {
    sanitize(htmlString) {
      return sanitize(htmlString);
    },
    discardChanges() {
      this.$emit('discardChanges')
    },
    saveChanges() {
      this.$emit('saveChanges')
    },
    applyConfigsIntoComponents(component) {
      if (component?.config?.demarkDefaultOption) {
        this.$set(component.config, 'boldValues', [this.defaultData[component.id]]);
        this.$set(component.config, 'defaultData', this.defaultData[component.id]);        
      }

      if (component?.config?.componentFields) {
        component?.config?.componentFields?.map(this.applyConfigsIntoComponents)
      }
      return component
    },
    getComboboxSelection(component) {
      return component.values ?? []
    },
    onDataChange(data) {
      this.$emit('change', { ...data, })
    },
    onSetStep(stepKey) {
      this.$emit('setStep', stepKey)
      this.setSelectedStep(stepKey)
    },
    setSelectedStep(stepKey, scrollToFirstSubstep = true) {
      this.selectedStep = stepKey;

      const firstSubstepKey = `[data-position="${stepKey}-0"]`;
      this.selectedSubstep = firstSubstepKey;

      if (this.isSmallOrMediumScreen) {
        this.$refs.editModal?.open?.();
      }

      if(scrollToFirstSubstep) {
        this.scrollToSelectedSubstep(true);
      }
    },
    setSelectedStepByIndex(index) {
      if(index >= 0) {
        this.setSelectedStep(this.componentsDef?.[index]?.stepKey || '');
      }
    },
    onSetSubstep(event) {
      this.$emit('setSubstep', event)
      this.setSelectedSubstep(event)
    },
    setSelectedSubstep(event) {
      const { stepKey, substepKey } = event;
      this.setSelectedStep(stepKey, false);

      if(substepKey) {
        this.selectedSubstep = substepKey;
        this.scrollToSelectedSubstep(true);
      }
    },
    scrollToSubstep(substepKey) {
      const targetEl = document.querySelector(substepKey);
      if(!targetEl) return;

      this.$nextTick(() => this.$scrollIntoView(targetEl, { 
        ignoresWhenTopIsVisible: false,
        offsetY: 8,
        animate: true,
      }));
    },
    isComponentHidden(component) {
      const result = component && component.hidden && isHiddenDisabled(component.hidden, this.allData);
      if (!result) {
        return result;
      }
  
      return result;
    },
    scrollToSelectedSubstep(ignoreOnSmallOrMediumScreen = false) {
      const ignore = this.isSmallOrMediumScreen && ignoreOnSmallOrMediumScreen;
      if (!ignore) {
        const { selectedSubstep } = this;
        setTimeout(() => this.scrollToSubstep(selectedSubstep), 500);
      }
    },
  },
  components: {
    AntragComponent,
    OptionMenu,
    PageHeaderTitleNavigation,
    HinweiseUndFehler,
    ConfigVerticalStep,
    ConfigStepper3,
    ContentWithStepper,
    GhostLoading,
    NoData,
    BaseModal,
  },
  validators: {},
}
</script>

<style lang="scss" scoped>
.configs--actions-list {
  min-width: 80px;

  li {
    button {
      display: block;
      line-height: 1.4em;
      text-align: left;
      width: 100%;
    }
  }
}

.antrag-component {
  &.indented {
    margin-top: -12px;
    margin-left: 32px;
  }
}
</style>

<!-- GLOBAL STYLE -->
<style lang="scss">
.antrag-component {
  em {
    color: inherit;
  }

  .input-forms__label-content {
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
