<template>
  <ComboBox 
    v-if="allowConfigGlobal || hasRoles([VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, ROLES.IS_KUNDENZUGANG])"
    v-model="internalValue"
    :values="userLevelValues"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import CORE_TYPES from '@/store/core/types'
import FC_CONFIG_TYPES from '@/store/fcConfig/types';

import ComboBox from '@/components/core/forms/ComboBox.vue';

export default {
  props: {
    value: {},
    current: {},
  },
  data() {
    return {
      internalValue: null,
    };
  },
  computed: {
    ...mapGetters({
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      allowConfigGlobal: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_ALLOW_CONFIG_GLOBAL,
      userLevelValues: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_USER_LEVEL_VALUES,
      userLevelDefault: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_USER_LEVEL_DEFAULT,
    }),
    initialUserLevel() {
      return this.current || this.userLevelDefault;
    },
  },
  methods: {
    init() {
      this.internalValue = this.initialUserLevel;
      this.$emit('input', this.internalValue);
    },
  },
  mounted() {
    this.init();
  },
  components: {
    ComboBox,
  },
}
</script>
