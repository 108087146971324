<template>
  <PhGitFork class="icon" v-bind="$attrs" v-on="$listeners" />
</template>

<script>
import { PhGitFork, } from 'phosphor-vue';

export default {
  components: {
    PhGitFork,
  },
}
</script>

<style scoped>
.icon {
  transform: rotate(180deg);
}
</style>
