<template>
  <div v-if="customerDataEdited">
    <FlexibleList 
    :rows="rows" 
    showThirdRow 
    :linkInFirstRow="isEditable" 
    @onRowClick="onRowClick" 
    v-if="rows && rows.length"  
    @onNavigate="navigateToCard"
    :pageSize="isCustomerOnly ? 0 : 5"
    >
      
      <template #title="row">
        {{ row.personName }}
      </template>

      <template #value="row">
        {{ row.jobStartDate || "./." }}
      </template>

      <template #thirdRow="row">
        {{ row.relation }}
      </template>      
    
    </FlexibleList>
    <NoData v-else noIcon />

  </div>
</template>


<script>
import BoxContainer from "@/components/core/BoxContainer.vue";
import { PhPencilLine, PhBookOpen } from "phosphor-vue";
import { TextColumn, CurrencyColumn } from "@/components/table2/table_util.js";
import Table from "@/components/table2/Table.vue";
import { mapGetters } from "vuex";
import CUSTOMERDATA_TYPES from "@/store/customerData/types";
import CORE_TYPES from "@/store/core/types";
import persoenlicheDatenMixin from "../persoenliche-daten-mixin";
import stammdatenMixin from "@/components/persoenliche-daten/stammdaten/stammdaten-mixin";
import FlexibleList from "@/components/flexibleList/FlexibleList.vue";
import NoData from '@/components/core/NoData.vue';

export default {
  components: {
    BoxContainer,
    PhPencilLine,
    PhBookOpen,
    Table,
    FlexibleList,
    NoData,
  },
  mixins: [persoenlicheDatenMixin, stammdatenMixin],
  data: function () {
    return {
      title: "Arbeitgeber",
    };
  },
  computed: {
    ...mapGetters({
      customerDataEdited: CUSTOMERDATA_TYPES.GETTERS.GET_CUSTOMER_DATA_EDITED,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      isFK: CORE_TYPES.GETTERS.IS_FK,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      personId: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_ID,
      customerDataConfig: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_CONFIG,
      customerData: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_DATA,
      isCustomerOnly: CORE_TYPES.GETTERS.IS_CUSTOMER_ONLY,
    }),
    employees() {
      return this.customerData?.employment?.employees || []
    },
    headers() {
      const result = {
        center: [],
      };

      result.center.push(TextColumn("personName", "Arbeitgeber"));
      result.center.push(TextColumn("jobStartDate", "Arbeitnehmereintritt"));
      result.center.push(TextColumn("personalNr", "Personalnummer"));
      result.center.push(TextColumn("department", "Abteilung"));
      result.center.push(TextColumn("manager", "Geschäftsführung"));

      return result;
    },
    rows() {
      return this.employees.map(emp => ({
            personName: emp.person && emp.person.display || '-',
            jobStartDate: emp.jobStartDate || './.',
            personalNr: emp.personalNr || '-',
            department: emp.department || '-',
            manager: emp.manager || '-',
            personId: emp.person.value || '-',
        }));
    },
    tableId() {
      const uniqueUUID = "32566829-f4bb-4fff-80bc-116244669b72";
      return uniqueUUID;
    },
  },
  methods: {
    navigateToCard() {
      this.$router.push(`/persoenlichedaten/customer-data/steps/arbeitgeber`);
    }, 
    onRowClick(whatRow) {
      //alert(JSON.stringify(whatRow));
      // Edit window must be implemented for the broker perspective in the app view
    },
  },
  mounted() {
    // this.updateGroupsIntoData();
  },
  validators: {},
};
</script>

<style scoped>
.align-end {
  flex: 1 1 auto;
  align-self: flex-end;
  padding: 0px 0px 16px 5px;
  color: var(--color-text);
}
.form-control {
  display: flex;
  align-content: stretch;
  align-items: center;
}
</style>