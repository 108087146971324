<template>
  <BaseModal ref="modal"
    :modalTitle="externeZugangTitle"
    :showDefaultButtons="false"
    @close="onClose"
  >
    <template #default>
      <GhostLoading v-if="loading">
        <Block type="title" width="100%" />
        <Block type="title" width="100%" />
        <Block type="title" width="100%" class="mb-3" />
        <Block width="50%" />
        <Block type="form-input" />
      </GhostLoading>
      <form v-else autocomplete="off" @submit.prevent>
        <div class="box__title">{{ subtitle }}</div>

        <p class="mt-0 mb-2">Id {{ externeZugangId }}</p>

        <template v-for="(component, index) in components">
          <div :key="index" class="antrag-component__container">
            <AntragComponent 
              v-if="!isComponentHidden(component)"
              class="antrag-component"
              :component="component" 
              :data="allData" 
              @change="onChange(component, $event)" />
          </div>
        </template>
      </form>
    </template>

    <template #footer>
      <BaseButton isSecondary @click="close()">Abbrechen</BaseButton>
      <BaseButton :disabled="loading || saving || isFormInvalid" @click="onSave()">Speichern</BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { mapGetters, } from 'vuex';
import EXTERNE_ZUGANG_CONFIG_TYPES from '@/store/externeZugangConfig/types';

import BaseModal from '@/components/core/BaseModal.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import AntragComponent from '@/components/antrag/AntragComponent.vue';
import BaseButton from '@/components/core/BaseButton.vue';

import { componentValidatorToRule, isHiddenDisabled } from '@/components/antrag/antrag-utils';

import validator from '@/mixins/validator';

export default {
  mixins: [validator],
  data() {
    return {
      externeZugang: {},
      dataEdited: {},
      loading: false,
      saving: false,
    };
  },
  computed: {
    ...mapGetters({
      definition: EXTERNE_ZUGANG_CONFIG_TYPES.GETTERS.DEFINITION,
    }),
    currentDefinition() {
      const { bezeichnung, } = this.externeZugang;
      if(!bezeichnung) return {};
      return {
        ...this.definition?.[bezeichnung] || {},
      };
    },
    components() {
      return ([ ...this.currentDefinition?.components || [], ]).map(component => ({
        ...component,
        config: {
          ...component?.config || {},
          validateUntouched: true,
          autocomplete: 'new-password',
        },
      }));
    },
    data() {
      return { ...this.currentDefinition?.data || {}, };
    },
    allData() {
      return {
        ...this.data,
        ...this.dataEdited,
      };
    },
    externeZugangTitle() {
      return this.externeZugang?.title || '';
    },
    externeZugangId() {
      return this.externeZugang?.id || 'n/a';
    },
    subtitle() {
      const subtitle = this.externeZugang?.valid ? 'Ändern der Zugangsdaten' : 'Neue Zugangsdaten für';
      return `${subtitle} ${this.externeZugangTitle}`;
    },
    dynamicValidators() {
      const { components, } = this;

      const dynamicValidators = components
        .filter(component => component.id && component.validators?.length)
        .map(component => ({
          componentId: component.id,
          validators: component.validators.map(componentValidatorToRule),
        }))

      return dynamicValidators;
    },
    isFormInvalid() {
      return this.validation.updated 
        && this.components?.some(component => component?.id && this.validation?.[component.id]?.invalid);
    },
  },
  watch: {
    dynamicValidators: {
      immediate: true,
      handler(newDynamicValidators) {
        for (const dynamicValidator of newDynamicValidators) {
          if (dynamicValidator && this.$configureValidators) {
            this.$configureValidators({ 
              [dynamicValidator.componentId]: dynamicValidator.validators,
            });
          }
        }
      },
    },
  },
  methods: {
    open(externeZugang) {
      this.externeZugang = { ...externeZugang, };
      this.dataEdited = {};
      this.findDefinition();
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    onClose() {
    },
    async findDefinition() {
      try {
        this.loading = true;
        await this.$store.dispatch(EXTERNE_ZUGANG_CONFIG_TYPES.ACTIONS.FIND_EXTERNE_ZUGANG_DEFINITION, this.externeZugang);
      } finally {
        this.loading = false;
      }
    },
    onChange(component, value) {
      switch(component.type) {
        case 'BUTTON':
        case 'CONFIRM_BUTTON':
          this.executeAction(component?.config?.action, component?.config?.addLocationHref);
          break;
        case 'SELECT_FILE_BUTTON':
          this.dataChanged(component, value?.data);
          break;
        default: 
          this.dataChanged(component, value);
          break;
      }
    },
    executeAction(action, addLocationHref = false) {
      if(!action) return;

      this.$store.dispatch(EXTERNE_ZUGANG_CONFIG_TYPES.ACTIONS.EXECUTE_ACTION, {
        ...this.externeZugang,
        action,
        data: {
          ...this.allData,
        },
        addLocationHref,
      });
    },
    dataChanged(component, value) {
      if (component.id) {
        this.dataEdited = {
          ...this.dataEdited,
          [component.id]: value,
        };
      } else if (Object.keys(value)[0]) {
        this.dataEdited = {
          ...this.dataEdited,
          ...value,
        };
      }
    },
    isComponentHidden(component) {
      const result = component && component.hidden && isHiddenDisabled(component.hidden, this.allData);
      if (!result) {
        return result;
      }

      return result;
    },
    async onSave() {
      try {
        this.saving = true;
        await this.save();
      } finally {
        this.saving = false;
        this.close();
      }
    },
    async save() {
      if(!Object.keys(this.dataEdited).length) return;

      await this.$store.dispatch(EXTERNE_ZUGANG_CONFIG_TYPES.ACTIONS.SAVE_EXTERNE_ZUGANG_DATA, {
        ...this.externeZugang,
        data: {
          ...this.allData,
        },
      });
    },
  },
  validators: {},
  components: {
    BaseModal,
    GhostLoading,
    Block,
    AntragComponent,
    BaseButton,
  },
}
</script>

<style lang="scss" scoped>
.antrag-component__container {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
