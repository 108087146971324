<template>
<div class="scroll-container" ref="container" @scroll="onScroll">
<!-- if you change this table component, you may also need to change PrimitiveFlexTable.vue -->
    <table>
        <colgroup>
            <col v-for="{key, width} in leftHeaders" :key="key" :style="{width: width + 'px'}"/>
            <col v-if="centerHeaders.length" :style="{width: scrollButtonWidth + 'px'}"/>
            <col v-for="{key, width} in centerHeaders" :key="key" :style="{width: width + 'px'}"/>
            <col v-if="centerHeaders.length" :style="{width: scrollButtonWidth + 'px'}"/>
            <col v-for="{key, width} in rightHeaders" :key="key" :style="{width: width + 'px'}"/>
        </colgroup>
        <thead>
            <th v-for="{key, left} in leftHeaders" :key="key" :style="{left: left + 'px'}" class="header header-cell text-left">
                <slot :name="'header_' + key" :collapsed="false"/>
            </th>
            <th v-if="centerHeaders.length" :style="{left: leftWidth + 'px'}" style="background: none" class="header header-cell">
            </th>
            <td v-for="{key} in centerHeaders" :key="key" class="header header-cell text-left">
                <slot :name="'header_' + key" :collapsed="false"/>
            </td>
            <th v-if="centerHeaders.length" :style="{right: rightWidth + 'px'}" style="background: none" class="header header-cell">
            </th>
            <th v-for="{key, right} in rightHeaders" :key="key" :style="{right: right + 'px'}" class="th-right header header-cell text-left">
                <slot :name="'header_' + key" :collapsed="false"/>
            </th>
        </thead>
        <DragnDropArea v-for="({acceptsFile, id, row, customRowStyle}, index) in rows"
            :key="id"
            element="tbody"
            hoverText="Datei hier ablegen"
            :disabled="!acceptsFile"
            @files="handleFile(row, $event)"
        >

            <tr>
                <th v-for="{key, left, classes, align} in leftHeaders" :key="key" :style="{...customRowStyle, left: left + 'px'}"
                    :valign="valign"
                    class="table-cell"
                    :class="[classes, 'text-' + align]"
                >
                    <slot :name="key" :row="row" :id="id">
                        {{row[key]}}
                    </slot>
                </th>
                <th v-if="centerHeaders.length"
                    :style="{left: leftWidth + 'px'}"
                    :class="['table-cell', canScrollLeft ? 'scroll-left' : 'border-left']"
                    @click="scrollLeft()"
                >
                    <PhCaretCircleLeft v-if="Math.floor(rows.length / 2) == index && canScrollLeft" :size="24" weight="duotone"/>
                </th>
                <td v-for="{key, classes, align} in centerHeaders" :key="key" :style="customRowStyle"
                    :valign="valign"
                    class="table-cell"
                    :class="[classes, 'text-' + align]"
                >
                    <slot :name="key" :row="row" :id="id">
                        {{row[key]}}
                    </slot>
                </td>
                <th v-if="centerHeaders.length"
                    :style="{right: rightWidth + 'px'}"
                    :class="['table-cell', canScrollRight ? 'scroll-right' : 'border-right']"
                    @click="scrollRight()"
                >
                    <PhCaretCircleRight  v-if="Math.floor(rows.length / 2) == index && canScrollRight" :size="24" weight="duotone" />
                </th>
                <th v-for="{key, right, classes, align} in rightHeaders" :key="key" :style="{...customRowStyle, right: right + 'px'}"
                    :valign="valign"
                    class="th-right table-cell"
                    :class="[classes, 'text-' + align]"
                >
                    <slot :name="key" :row="row" :id="id">
                        {{row[key]}}
                    </slot>
                </th>
            </tr>
        </DragnDropArea>
    </table>
</div>
</template>
<script>

import DragnDropArea from '@/components/fileUpload/DragnDropArea.vue';
import { PhCaretRight, PhCaretLeft, PhCaretCircleLeft, PhCaretCircleRight, } from 'phosphor-vue'
import BaseButton from '@/components/core/BaseButton.vue';

export default {
    components: {
        DragnDropArea,
        PhCaretRight,
        PhCaretLeft,
        BaseButton,
        PhCaretCircleLeft,
        PhCaretCircleRight
    },
    props: {
        headers: { 
            type: Object,
        },
        rows: { 
            type: Array,
            default: () => [],
        },
        valign: {
            type: String,
            default: 'center'
        },
        scrollButtonWidth: {
            type: Number,
            default: 64,
        }
    },
    data() {
        return {
            canScrollLeft: true,
            canScrollRight: true,
            intervalId: null,
            isDown: false,
            startX: null,
            scrollContainerLeft: null,
        }
    },
    computed: {
        leftHeaders() {
            const result = [];
            let left = 0;
            this.headers.lockedLeft.forEach(header => {
                result.push({
                    ...header,
                    left,
                });
                left += header.width;
            });
            return result;
        },
        centerHeaders() {
            return this.headers.center;
        },
        rightHeaders() {
            const result = [];
            let right = 0;
            this.headers.lockedRight.forEach(header => right += header.width);
            for (let i = 0; i < this.headers.lockedRight.length; i++) {
                const header = this.headers.lockedRight[i];
                right -= header.width;
                result.push({
                    ...header,
                    right,
                });
            }
            return result;
        },
        leftWidth() {
            return this.headers.lockedLeft.reduce((sum, header) => sum + header.width, 0);
        },
        rightWidth() {
            return this.headers.lockedRight.reduce((sum, header) => sum + header.width, 0);
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.updateScrollState();
        })
        this.intervalId = setInterval(() => {
            // in case the screen gets resized, or columns get resized, making the previous state invalid
            this.updateScrollState();
        }, 1000);

        this.addEvents();
    },
    beforeDestroy() {
        if (this.intervalId != null) {
            clearInterval(this.intervalId);
            this.intervalId = null;
        }
    },
    methods: {
        handleFile(row, files) {
            this.$emit('files', {
                row,
                files,
            });
        },
        onScroll() {
            this.updateScrollState();
        },
        updateScrollState() {
            this.canScrollLeft = this.$refs.container.scrollLeft > 0;
            this.canScrollRight = this.$refs.container.scrollLeft < this.$refs.container.scrollWidth - this.$refs.container.clientWidth;
        },
        scrollLeft() {
            const centerWidth = this.$refs.container.clientWidth - this.leftWidth - this.rightWidth;
            this.$refs.container.scrollBy({left: -centerWidth / 2, behavior: "smooth"});
            this.updateScrollState();
        },
        scrollRight() {
            const centerWidth = this.$refs.container.clientWidth - this.leftWidth - this.rightWidth;
            this.$refs.container.scrollBy({left: centerWidth / 2, behavior: "smooth"});
            this.updateScrollState();
        },
        addEvents() {
            const slider = this.$refs.container;

            slider.addEventListener('mousedown', (e) => {
                this.isDown = true;
                slider.classList.add('grabbing-active');
                this.startX = e.pageX - slider.offsetLeft;
                this.scrollContainerLeft = slider.scrollLeft;
            });
            slider.addEventListener('mouseleave', () => {
                this.isDown = false;
                slider.classList.remove('grabbing-active');
            });
            slider.addEventListener('mouseup', () => {
                this.isDown = false;
                slider.classList.remove('grabbing-active');
                slider.classList.remove('noSelect');
            });
            slider.addEventListener('mousemove', (e) => {
                if(!this.isDown) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = (x - this.startX) * 3; //scroll-fast
                slider.scrollLeft = this.scrollContainerLeft - walk;
                slider.classList.add('noSelect');
            });

        }
    },
}
</script>
<style scoped>
.scroll-container {
    width: 100%;
    overflow-y: auto;
}
.scroll-container::-webkit-scrollbar {
    display: none;
}
table {
    table-layout: fixed;
    border-spacing: 0;
    width: 100%;
    font-size: 0.9em;
}
thead .header {
    box-sizing: border-box;
}
th {
    position: sticky;
    position: -webkit-sticky;
    background: var(--color-background);
    z-index: 2;
    font-weight: normal;
    background-clip: padding-box !important;
}
.scroll-header {
    background-color: var(--color-primary);
    color: var(--color-primary-text);
    cursor: pointer;
    border-radius: 4px;
}
.scroll-left {
    z-index: 2;
    position: sticky;
    position: -webkit-sticky;
    background: var(--color-background);
    background: linear-gradient(90deg, rgba(90, 90, 90, 0.1) 10%, rgba(255,255,255,0) 100%) !important;
    border-left: 1px solid #C4C4C4;
}
.border-left {
    border-left: 1px solid #C4C4C4;
}
.scroll-right {
    z-index: 2;
    position: sticky;
    position: -webkit-sticky;
    background: var(--color-background);
    background: linear-gradient(90deg, rgba(255,255,255,0) 10%, rgba(90, 90, 90, 0.1) 100%) !important;
    border-right: 1px solid #C4C4C4;
    margin-left: 10px;
}
.border-right {
    border-right: 1px solid #C4C4C4;
}

.expanded-row {
    margin: 0 -12px;
    padding: 0 14px 25px 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-between;
}
.collapsed {
    display: flex;
    flex-direction: column;
    padding: 6px;
}
.collapsed-cell {
    word-break: break-all;
    white-space: normal;
}
.scroll-container.grabbing-active {
    background: rgba(255,255,255,0.3);
    cursor: grabbing;
    cursor: -webkit-grabbing;
  }
.noSelect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox*/
}
</style>
