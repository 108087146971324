var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: _vm.$appNavigation.currentMenu.label }
      }),
      _vm.defaultOptions
        ? _c("BaseFilter", {
            ref: "refFilter",
            attrs: {
              title: "Filter",
              filterId: "FonsAntragCheck",
              configFilter: _vm.filterConfig,
              metadata: _vm.metadata,
              defaultOptions: _vm.defaultOptions,
              showSaveButton: "",
              showAlwaysPrimaryselection: false
            },
            on: { onFilter: _vm.onFilter }
          })
        : _vm._e(),
      _vm.requestedData
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("AntragCheckTable", {
                attrs: {
                  title: "Fondsantragcheck",
                  loading: _vm.loading,
                  selectedPage: _vm.selectedPage,
                  maxRowsPerPage: _vm.maxRowsPerPage,
                  searchSort: _vm.searchSort
                },
                on: {
                  page: function($event) {
                    _vm.selectedPage = $event
                  },
                  sort: _vm.onSearchSort,
                  onScroll: _vm.onScroll,
                  localSort: function($event) {
                    _vm.searchSort = $event
                  },
                  requestPage: _vm.loadPage,
                  rowsPerPage: _vm.onPageSizeChange
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }