var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    staticClass: "person-checks-component",
    attrs: {
      "data-person-id": _vm.selectedPersonId,
      "data-is-firma": _vm.isFirmaSelected,
      "data-olden-than": _vm.olderThanSelected
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }