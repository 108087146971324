
export function initialState() {
  return {
    personId: '',
    bruttoeinkommen: null,
    anzahlmonate: null,
    bruttoeinkommmenpa: null,
    versteinkommenpa: null,
    stVeranlagung: '',
    kirchensteuer: null,
    grenzsteuersatz: null,
    gesetzlrentenversicherung: '',
    entgeltPunkte: null,
    altNeuBundeslaender: '',
    krankenversicherungsstatus: '',
    beitragKrankenPflegeversicherung: null,
    beitragssatzGKV: null,
    zuschlagPflegeversicherung: null,
    religionsname: '',
    bruttoeinkommenManuell: false,
    erwerbsminderung: null,
    rentenanwartschaftBislang: null,
    rentenanwartschaftZukunft: null
  };
}

export function getBruttoeinkommmenpa(bruttoeinkommen, anzahlmonate) {
  if (bruttoeinkommen == null || anzahlmonate == null || isNaN(bruttoeinkommen) || isNaN(anzahlmonate)) {
    return null;
  }
  return bruttoeinkommen * anzahlmonate ;
}
