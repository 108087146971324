var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderActionCard", { attrs: { title: "Import Config" } }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("InputTextArea", {
            attrs: { label: "JSON Content" },
            model: {
              value: _vm.content,
              callback: function($$v) {
                _vm.content = $$v
              },
              expression: "content"
            }
          }),
          _c(
            "BaseButton",
            {
              on: {
                click: function($event) {
                  return _vm.importData()
                }
              }
            },
            [_vm._v(" Import ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }