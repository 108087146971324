var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        "data-name": "Layer 1",
        width: "598.38259",
        height: "519.36575",
        viewBox: "0 0 598.38259 519.36575"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M854.37384,671.40435l16.11882-5.113a54.56124,54.56124,0,0,0-.38831-26.249c-6.47575,14.3987-25.47463,17.92647-36.29963,29.419a32.81871,32.81871,0,0,0-8.3953,27.17926l-3.39124,11.504a54.99381,54.99381,0,0,0,40.024-22.76717,53.12122,53.12122,0,0,0,6.22435-11.78347C861.39249,672.90577,854.37384,671.40435,854.37384,671.40435Z",
          transform: "translate(-300.34479 -190.05939)",
          fill: "#f2f2f2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M837.14986,650.13183l12.14673-11.765A54.56128,54.56128,0,0,0,837.24247,615.046c.62547,15.7755-14.806,27.40615-19.36941,42.52016a32.81867,32.81867,0,0,0,4.60727,28.07073l2.09524,11.809A54.99378,54.99378,0,0,0,850.245,659.2183a53.12068,53.12068,0,0,0,.31586-13.32264C844.10146,648.34548,837.14986,650.13183,837.14986,650.13183Z",
          transform: "translate(-300.34479 -190.05939)",
          fill: "#f2f2f2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M756.405,224.54359H302.112a1.807,1.807,0,0,1,0-3.61319H756.405a1.807,1.807,0,0,1,0,3.61319Z",
          transform: "translate(-300.34479 -190.05939)",
          fill: "#3f3d56"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "32.69152",
          cy: "10.82345",
          rx: "10.58751",
          ry: "10.82345",
          fill: "#3f3d56"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "69.26654",
          cy: "10.82345",
          rx: "10.58751",
          ry: "10.82345",
          fill: "#3f3d56"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "105.84156",
          cy: "10.82345",
          rx: "10.58751",
          ry: "10.82345",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M734.38374,192.74209h-25.981a1.96762,1.96762,0,0,0,0,3.93446h25.981a1.96762,1.96762,0,0,0,0-3.93446Z",
          transform: "translate(-300.34479 -190.05939)",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M734.38374,200.12647h-25.981a1.96761,1.96761,0,0,0,0,3.93445h25.981a1.96761,1.96761,0,0,0,0-3.93445Z",
          transform: "translate(-300.34479 -190.05939)",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M734.38374,207.50114h-25.981a1.96761,1.96761,0,0,0,0,3.93445h25.981a1.96761,1.96761,0,0,0,0-3.93445Z",
          transform: "translate(-300.34479 -190.05939)",
          fill: "#3f3d56"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "35.19338",
          cy: "94.79521",
          r: "13.08937",
          fill: "#e6e7e8"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M668.86084,313.839H326.34674a3.89775,3.89775,0,1,1,0-7.7955h342.5141a3.89775,3.89775,0,1,1,0,7.7955Z",
          transform: "translate(-300.34479 -190.05939)",
          fill: "#e6e7e8"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M579.03041,329.241H326.34674a3.89794,3.89794,0,0,1,0-7.79588H579.03041a3.89794,3.89794,0,1,1,0,7.79588Z",
          transform: "translate(-300.34479 -190.05939)",
          fill: "#e6e7e8"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "35.19338",
          cy: "299.09983",
          r: "13.08937",
          fill: "#e6e7e8"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M668.86084,518.14363H326.34674a3.89775,3.89775,0,1,1,0-7.79549h342.5141a4.35939,4.35939,0,0,1,4.11419,4.19844A3.84941,3.84941,0,0,1,668.86084,518.14363Z",
          transform: "translate(-300.34479 -190.05939)",
          fill: "#e6e7e8"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M579.03041,533.54558H326.34674a3.89794,3.89794,0,0,1,0-7.79588H579.03041a3.89794,3.89794,0,1,1,0,7.79588Z",
          transform: "translate(-300.34479 -190.05939)",
          fill: "#e6e7e8"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "35.19338",
          cy: "189.24655",
          r: "13.08937",
          fill: "#e6e7e8"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M668.86084,408.29035H326.34674a3.89775,3.89775,0,1,1,0-7.7955h342.5141a3.89775,3.89775,0,1,1,0,7.7955Z",
          transform: "translate(-300.34479 -190.05939)",
          fill: "#e6e7e8"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M621.16327,423.6923H326.34674a3.89794,3.89794,0,0,1,0-7.79588H621.16327a3.89794,3.89794,0,0,1,0,7.79588Z",
          transform: "translate(-300.34479 -190.05939)",
          fill: "#e6e7e8"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M601.15322,439.09425H326.34674a3.89794,3.89794,0,0,1,0-7.79588H601.15322a3.89794,3.89794,0,1,1,0,7.79588Z",
          transform: "translate(-300.34479 -190.05939)",
          fill: "#e6e7e8"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "306.49461",
          cy: "324.42079",
          r: "96.9336",
          fill: _vm.color
        }
      }),
      _c("circle", {
        attrs: {
          cx: "306.36146",
          cy: "344.39338",
          r: "13.84766",
          fill: "#3f3d56"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "306.36146",
          cy: "344.39338",
          r: "6.39123",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M632.41156,565.278h-51.144a19.12258,19.12258,0,0,1-19.10157-19.10059V522.72822a19.12342,19.12342,0,0,1,19.10157-19.10156h51.144a19.12331,19.12331,0,0,1,19.10107,19.10156v23.44922A19.12247,19.12247,0,0,1,632.41156,565.278Zm-51.144-59.65137a17.12113,17.12113,0,0,0-17.10157,17.10156v23.44922A17.12029,17.12029,0,0,0,581.26752,563.278h51.144a17.11986,17.11986,0,0,0,17.10107-17.10059V522.72822a17.1207,17.1207,0,0,0-17.10107-17.10156Z",
          transform: "translate(-300.34479 -190.05939)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M578.23849,552.5493h45.39544a17.94254,17.94254,0,0,0,17.94256-17.94256V514.98319a17.86278,17.86278,0,0,0-1.18355-6.36529,17.895,17.895,0,0,1,7.13091,14.2951v19.62355a17.94254,17.94254,0,0,1-17.94257,17.94256H584.18585a17.93276,17.93276,0,0,1-16.759-11.57727A17.83961,17.83961,0,0,0,578.23849,552.5493Z",
          transform: "translate(-300.34479 -190.05939)",
          fill: "#3f3d56"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "416.909 506.542 405.755 506.541 400.448 463.514 416.912 463.515 416.909 506.542",
          fill: "#a0616a"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M717.64736,708.23783l-34.30291-.0013v-.43381a13.35238,13.35238,0,0,1,13.35164-13.35144h.00083l6.26586-4.75361,11.69073,4.75434,2.99442.0001Z",
          transform: "translate(-300.34479 -190.05939)",
          fill: "#2f2e41"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "495.838 506.542 506.993 506.541 512.3 463.514 495.836 463.515 495.838 506.542",
          fill: "#a0616a"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M795.79,708.23783l34.3029-.0013v-.43381a13.35238,13.35238,0,0,0-13.35164-13.35144h-.00083l-6.26586-4.75361L798.78385,694.452l-2.99442.0001Z",
          transform: "translate(-300.34479 -190.05939)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M700.302,621.95549c.16474-2.45511,1.47464-16.03273,1.47464-16.03273l3.13752-28.79462,5.52988-76.32805.36083-4.9965,28.96716-5.56128,19.79775-9.491,23.53141,7.52223,18.32311,6.87118s.03136,2.94142.17254,6.49469c.18825,4.65142.51767,10.35383,1.30205,11.83632,1.30991,2.61985.98048,16.68379.98048,16.68379s1.30205,28.61417,2.94143,31.7517,1.96875,9.67142,1.47464,10.98132,1.63937,35.6736,1.63937,35.6736,5.4758,60.811,2.04021,69.98042-16.41791,3.163-16.41791,3.163.62749-2.44726-3.13753-3.60033-15.2327-87.38-15.2327-87.38-2.13349-17.68-4.09444-22.747-3.765-19.30358-3.765-19.30358-12.11084-23.56282-12.26772-28.80248c0,0-1.58447-4.90237-3.08261.49417s-5.91421,18.32311-5.91421,18.32311l-4.30624,15.8758L738.68954,578.289l-3.2787,19.97034s-4.91023,26.50419-5.69461,27.6494,0,25.359,0,25.359-3.79638,20.62135-9.16156,24.22167-19.57964.05718-19.57964.05718S700.13728,624.41061,700.302,621.95549Z",
          transform: "translate(-300.34479 -190.05939)",
          fill: "#2f2e41"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "412.315 236.13 418.755 255.528 410.809 264.987 414.308 268.588 411.727 273.678 404.997 327.032 441.894 341.025 454.319 325.447 464.116 341.025 506.81 330.593 495.922 262.556 495.922 258.108 492.848 249.519 492.848 231.337 500.966 177.215 476.736 168.728 465.151 159.559 461.402 172.454 445.44 169.615 445.024 159.927 432.811 171.466 422.527 174.399 410.213 177.215 408.644 181.137 411.781 180.353 411.272 231.377 412.315 236.13",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M701.63112,497.16362l6.19682-59.98181,1.63562-24.68654L692.11883,409.359s.68726,17.65162-1.58492,27.61085c-2.24226,9.82765-.29746,58.87688-.15891,60.18842a8.4387,8.4387,0,1,0,11.25612.00535Z",
          transform: "translate(-300.34479 -190.05939)",
          fill: "#a0616a"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M684.71257,449.61094l4.82391.99618,16.55046,3.42774.91771.18824,1.9531-7.76533,2.26687-2.41591,4.31409-22.60584.22745-1.17657,6.5182-13.93061,4.50971-41.87022-16.77006,3.012c-.08627.08627-.16474.18039-.24315.27451-2.40806,2.72963-4.12585,6.52605-6.93395,10.346-9.138,12.43246-16.14988,42.6389-15.87532,59.18936.00785.26666-.26671.55688-.59614.855A9.18008,9.18008,0,0,0,684.71257,449.61094Z",
          transform: "translate(-300.34479 -190.05939)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M809.45309,497.16362l-6.19682-59.98181-1.63562-24.68654,17.34473-3.13627s-.68726,17.65162,1.58492,27.61085c2.24226,9.82765.29746,58.87688.15891,60.18842a8.43869,8.43869,0,1,1-11.25612.00535Z",
          transform: "translate(-300.34479 -190.05939)",
          fill: "#a0616a"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M826.37164,449.61094l-4.82391.99618-16.55045,3.42774-.91772.18824-1.95309-7.76533-2.26688-2.41591L795.5455,421.436l-.22745-1.17657-6.5182-13.93061,1.4903-41.87022,10.77,3.012c.08627.08627.16474.18039.24316.27451,2.40805,2.72963,4.12584,6.52605,6.93394,10.346,9.138,12.43246,16.14988,42.6389,15.87532,59.18936-.00785.26666.26671.55688.59614.855A9.18008,9.18008,0,0,1,826.37164,449.61094Z",
          transform: "translate(-300.34479 -190.05939)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M737.489,370.65369l7.87971-20.6669,20.12717-.36864,6.67878,21.03554,24.0059,134.1036s-73.54408,20.48067-72.79806-4.13807c.78438-25.88455,4.32336-60.26788,4.32336-60.26788Z",
          transform: "translate(-300.34479 -190.05939)",
          fill: _vm.color
        }
      }),
      _c("circle", {
        attrs: {
          cx: "454.63748",
          cy: "130.43677",
          r: "23.70998",
          fill: "#a0616a"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M729.87412,303.296c3.86011-4.2014,8.68826-11.892,23.31228-15.12147,18.07034-3.99055,26.60919,12.33362,22.05218,12.6012,13.32515,4.03514,4.76529,31.6106.94512,29.29792A1.12494,1.12494,0,0,1,776.08928,330,18.77,18.77,0,0,1,768.592,317.1934l-.725-6.52543c-.10351,5.30926-5.53035,6.01158-11.07409,4.05623a6.95535,6.95535,0,0,1,4.91385-4.83821,23.78593,23.78593,0,0,1-13.97147,6.07912,9.51087,9.51087,0,0,1,4.62511-6.36419l-.2188-.215a22.84961,22.84961,0,0,1-7.33,4.1504c-3.056.87594-6.71782.40207-8.81164-1.99015a6.50041,6.50041,0,0,1-1.25249-2.25409c.45268,4.2047-2.36752,9.00438-4.55825,8.18032C728.36675,317.11823,727.30441,306.0929,729.87412,303.296Z",
          transform: "translate(-300.34479 -190.05939)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M898.72738,708.23514a1.18647,1.18647,0,0,1-1.19006,1.19h-280.29a1.19,1.19,0,0,1,0-2.38h280.29A1.18651,1.18651,0,0,1,898.72738,708.23514Z",
          transform: "translate(-300.34479 -190.05939)",
          fill: "#ccc"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M607.62666,461.92192c-9.48085,0-17.81459,4.29206-22.5331,10.73464a29.12131,29.12131,0,0,1,17.37286-5.5744c14.68157,0,26.626,10.28436,26.626,22.92543v10.50777h5.16024v-15.668C634.25262,472.20628,622.30823,461.92192,607.62666,461.92192Z",
          transform: "translate(-300.34479 -190.05939)",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M637.79828,505.89326h-61.918V487.67549c0-14.69824,13.88818-26.65625,30.959-26.65625s30.959,11.958,30.959,26.65625Zm-59.918-2h57.918V487.67549c0-13.5957-12.99073-24.65625-28.959-24.65625s-28.959,11.06055-28.959,24.65625Z",
          transform: "translate(-300.34479 -190.05939)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M622.69671,498.93818H590.98187a5.38755,5.38755,0,0,1-5.38135-5.38183v-7.25c0-10.1084,9.52783-18.332,21.23877-18.332s21.23877,8.22363,21.23877,18.332v7.25A5.38754,5.38754,0,0,1,622.69671,498.93818Zm-15.85742-28.96386c-10.6084,0-19.23877,7.32617-19.23877,16.332v7.25a3.38525,3.38525,0,0,0,3.38135,3.38183h31.71484a3.38525,3.38525,0,0,0,3.38135-3.38183v-7.25C626.07806,477.30049,617.44769,469.97432,606.83929,469.97432Z",
          transform: "translate(-300.34479 -190.05939)",
          fill: _vm.secondColor
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }