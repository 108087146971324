var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu,
          tid: "3c886523-0f75-4a62-bb04-2bd422c441b8"
        }
      }),
      _c("DashboardPanel", {
        attrs: {
          id: "f4cfdd24-f99c-4a66-bfc0-94faa9724d22",
          title: "Kunden-Überblick",
          data: _vm.dashboardData,
          ignoreUserLevelConfig: ""
        },
        on: { executeAction: _vm.handleAction },
        scopedSlots: _vm._u([
          {
            key: "Investment",
            fn: function() {
              return [
                _c("div", { staticClass: "box__container-wrap" }, [
                  _c(
                    "div",
                    { staticClass: "box__container-rows" },
                    [
                      _vm.isInvestment
                        ? [
                            !_vm.$isSmallScreen
                              ? _c(
                                  "div",
                                  { staticClass: "label-value--list" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "label-value--item" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "label-value--item-label text-left"
                                          },
                                          [_vm._v("Bestand")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "label-value--item-value text-right"
                                          },
                                          [
                                            _c("CurrencyLabel", {
                                              attrs: {
                                                value: _vm.info.openFunds
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    ),
                                    !_vm.isFA
                                      ? _c(
                                          "div",
                                          { staticClass: "label-value--item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "label-value--item-label text-left"
                                              },
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    attrs: {
                                                      to:
                                                        "/home/freistellungsauftrag"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Freistellungsauftrag"
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "label-value--item-value text-right"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "d-flex" },
                                                  [
                                                    _c("CurrencyLabel", {
                                                      attrs: {
                                                        value:
                                                          _vm.info
                                                            .steckBreifZusatz
                                                            .freistellungAuftrag
                                                      }
                                                    }),
                                                    _vm._v(" / "),
                                                    _c("CurrencyLabel", {
                                                      attrs: {
                                                        value:
                                                          _vm.info
                                                            .steckBreifZusatz
                                                            .zinsen
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "label-value--item align-items-end"
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "tbl tbl-full" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "tbl-row" },
                                              [
                                                _c("div", {
                                                  staticClass:
                                                    "tbl-cell tbl-cell-max text-left text-small"
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "tbl-cell text-right text-small"
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "hide-on-small-screen"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "ø Beiträge / Monat"
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "hide-on-large-screen"
                                                      },
                                                      [_vm._v("ø Beiträge / M")]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "tbl-cell text-right text-small"
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "hide-on-small-screen"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Beiträge / Jahr"
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "hide-on-large-screen"
                                                      },
                                                      [_vm._v("Beiträge / J")]
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "tbl-row" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "tbl-cell break-word text-left"
                                                  },
                                                  [_vm._v("Sparpläne")]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "tbl-cell text-right"
                                                  },
                                                  [
                                                    _c("CurrencyLabel", {
                                                      attrs: {
                                                        value:
                                                          _vm.info
                                                            .steckBreifZusatz
                                                            .sparplanJahrlich
                                                            .Sparplan / 12
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "tbl-cell text-right"
                                                  },
                                                  [
                                                    _c("CurrencyLabel", {
                                                      attrs: {
                                                        value:
                                                          _vm.info
                                                            .steckBreifZusatz
                                                            .sparplanJahrlich
                                                            .Sparplan
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "tbl-row" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "tbl-cell break-word text-left"
                                                  },
                                                  [_vm._v("Entnahmepläne")]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "tbl-cell text-right"
                                                  },
                                                  [
                                                    _c("CurrencyLabel", {
                                                      attrs: {
                                                        value:
                                                          _vm.info
                                                            .steckBreifZusatz
                                                            .sparplanJahrlich
                                                            .Entnahmeplan / 12
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "tbl-cell text-right"
                                                  },
                                                  [
                                                    _c("CurrencyLabel", {
                                                      attrs: {
                                                        value:
                                                          _vm.info
                                                            .steckBreifZusatz
                                                            .sparplanJahrlich
                                                            .Entnahmeplan
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              : _c(
                                  "div",
                                  [
                                    _c("FlexibleList", {
                                      attrs: {
                                        rows: _vm.mobileInvestmentFlexibleList,
                                        linkInFirstRow: ""
                                      },
                                      on: {
                                        onRowClick:
                                          _vm.handleMobileInvestmentFlexibleListAction
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "title",
                                            fn: function(row) {
                                              return [_vm._v(_vm._s(row.title))]
                                            }
                                          },
                                          {
                                            key: "value",
                                            fn: function(row) {
                                              return [
                                                row.title == "Bestand"
                                                  ? _c("CurrencyLabel", {
                                                      staticClass: "mr-4",
                                                      attrs: {
                                                        value: row.value
                                                      }
                                                    })
                                                  : _c(
                                                      "div",
                                                      { staticClass: "d-flex" },
                                                      [
                                                        _c("CurrencyLabel", {
                                                          attrs: {
                                                            value:
                                                              row.value
                                                                .freistellungAuftrag
                                                          }
                                                        }),
                                                        _vm._v(" / "),
                                                        _c("CurrencyLabel", {
                                                          attrs: {
                                                            value:
                                                              row.value.zinsen
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        3018774213
                                      )
                                    }),
                                    _c("br"),
                                    _c("Table", {
                                      attrs: {
                                        tableId:
                                          "1b95d8b8-0bf6-48f5-808d-3aa528e46297",
                                        hidePagination: "",
                                        mobileConfig:
                                          _vm.mobileInvestmentTable
                                            .mobileConfig,
                                        headers:
                                          _vm.mobileInvestmentTable.headers,
                                        rows: _vm.mobileInvestmentTable.rows
                                      }
                                    })
                                  ],
                                  1
                                )
                          ]
                        : _c("NoData", {
                            attrs: {
                              content: "Keine Anlagen vorhanden",
                              noIcon: ""
                            }
                          })
                    ],
                    2
                  ),
                  !_vm.$isSmallScreen
                    ? _c("div", { staticClass: "bottom-row" }, [
                        _c(
                          "div",
                          { staticClass: "text-right" },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: { to: "/home/vermogensubersicht/depot" }
                              },
                              [_vm._v("Investment anzeigen")]
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ])
              ]
            },
            proxy: true
          },
          {
            key: "Versicherungen",
            fn: function() {
              return [
                _c("div", { staticClass: "box__container-wrap" }, [
                  _c(
                    "div",
                    { staticClass: "box__container-rows" },
                    [
                      _vm.isInsurances
                        ? [
                            !_vm.$isSmallScreen
                              ? _c(
                                  "div",
                                  { staticClass: "tbl tbl-full" },
                                  [
                                    !_vm.loading
                                      ? _c("div", { staticClass: "tbl-row" }, [
                                          _c("div", {
                                            staticClass:
                                              "tbl-cell tbl-cell-max text-left text-small"
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "tbl-cell text-right text-small"
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "hide-on-small-screen"
                                                },
                                                [_vm._v("ø Beiträge / Monat")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "hide-on-large-screen"
                                                },
                                                [_vm._v("ø Beiträge / M")]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "tbl-cell text-right text-small"
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "hide-on-small-screen"
                                                },
                                                [_vm._v("Beiträge / Jahr")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "hide-on-large-screen"
                                                },
                                                [_vm._v("Beiträge / J")]
                                              )
                                            ]
                                          )
                                        ])
                                      : _vm._e(),
                                    !_vm.loading
                                      ? [
                                          _vm._l(_vm.insurances, function(
                                            item,
                                            i
                                          ) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  key: "in1" + i,
                                                  staticClass: "tbl-row"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "tbl-cell text-left break-word"
                                                    },
                                                    [_vm._v(_vm._s(item.label))]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "tbl-cell text-right"
                                                    },
                                                    [
                                                      _c("CurrencyLabel", {
                                                        attrs: {
                                                          value:
                                                            item.jahrBeitrag /
                                                            12
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "tbl-cell text-right"
                                                    },
                                                    [
                                                      _c("CurrencyLabel", {
                                                        attrs: {
                                                          value:
                                                            item.jahrBeitrag
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              ),
                                              item.rueckKaufsWert
                                                ? _c(
                                                    "div",
                                                    {
                                                      key: "in2" + i,
                                                      staticClass: "tbl-row"
                                                    },
                                                    [
                                                      item.rueckKaufsWert
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-left text-small sub-category-spacer break-word"
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "pl-sm-3"
                                                                },
                                                                [
                                                                  _c(
                                                                    "router-link",
                                                                    {
                                                                      attrs: {
                                                                        to:
                                                                          "/home/vermogensubersicht/versicherungsvermoegen"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Rückkaufswerte"
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c("div", {
                                                        staticClass:
                                                          "tbl-cell text-right text-small sub-category-spacer"
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "tbl-cell text-right text-small  sub-category-spacer"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.rueckkaufsWert
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e(),
                                              item.fondsWert
                                                ? _c(
                                                    "div",
                                                    {
                                                      key: "in3" + i,
                                                      staticClass: "tbl-row"
                                                    },
                                                    [
                                                      item.fondsWert
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "text-left text-small break-word"
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "pl-sm-3"
                                                                },
                                                                [
                                                                  _c(
                                                                    "router-link",
                                                                    {
                                                                      attrs: {
                                                                        to:
                                                                          "/home/vermogensubersicht/versicherungsvermoegen"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "Fondswerte"
                                                                      )
                                                                    ]
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      _c("div", {
                                                        staticClass:
                                                          "tbl-cell text-right text-small"
                                                      }),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "tbl-cell text-right text-small"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.fondsWert
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          })
                                        ]
                                      : _vm.loading
                                      ? _c("GhostLoading", {
                                          style: { width: "100%" },
                                          attrs: {
                                            width: "100%",
                                            type: "table"
                                          }
                                        })
                                      : _c("NoData")
                                  ],
                                  2
                                )
                              : _c(
                                  "div",
                                  [
                                    _c("Table", {
                                      attrs: {
                                        tableId:
                                          "56a905a8-ae66-4b14-97e4-fbc0154ff975",
                                        hidePagination: "",
                                        mobileConfig:
                                          _vm.mobileInsuranceTable.mobileConfig,
                                        headers:
                                          _vm.mobileInsuranceTable.headers,
                                        rows: _vm.mobileInsuranceTable.rows
                                      },
                                      on: {
                                        "click-rueckKaufsWert": function(
                                          $event
                                        ) {
                                          return _vm.navigateTo(
                                            "/home/vermogensubersicht/versicherungsvermoegen"
                                          )
                                        },
                                        "click-fondsWert": function($event) {
                                          return _vm.navigateTo(
                                            "/home/vermogensubersicht/versicherungsvermoegen"
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                          ]
                        : _c("NoData", {
                            attrs: {
                              content: "Keine Versicherungen vorhanden",
                              noIcon: ""
                            }
                          })
                    ],
                    2
                  ),
                  !_vm.$isSmallScreen
                    ? _c("div", { staticClass: "bottom-row" }, [
                        _c(
                          "div",
                          { staticClass: "text-right" },
                          [
                            _c(
                              "router-link",
                              { attrs: { to: "/home/versicherungen" } },
                              [_vm._v("Versicherungen anzeigen")]
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ])
              ]
            },
            proxy: true
          },
          {
            key: "Beteiligungen",
            fn: function() {
              return [
                _c("div", { staticClass: "box__container-wrap" }, [
                  _c(
                    "div",
                    { staticClass: "box__container-rows" },
                    [
                      _vm.isBeteiligungen
                        ? [
                            _c("div", { staticClass: "label-value--list" }, [
                              _c("div", { staticClass: "label-value--item" }, [
                                _c(
                                  "div",
                                  { staticClass: "label-value--item-label" },
                                  [_vm._v("Bestand")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "label-value--item-value" },
                                  [
                                    _c("CurrencyLabel", {
                                      attrs: { value: _vm.info.closedFunds }
                                    })
                                  ],
                                  1
                                )
                              ])
                            ])
                          ]
                        : _c("NoData", {
                            attrs: {
                              content: "Keine Anlagen vorhanden",
                              noIcon: ""
                            }
                          })
                    ],
                    2
                  ),
                  !_vm.$isSmallScreen
                    ? _c("div", { staticClass: "bottom-row" }, [
                        _c(
                          "div",
                          { staticClass: "text-right" },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: "/home/vermogensubersicht/beteiligungen"
                                }
                              },
                              [_vm._v("Beteiligungen anzeigen")]
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ])
              ]
            },
            proxy: true
          },
          {
            key: "Finanzierungen",
            fn: function() {
              return [
                _c("div", { staticClass: "box__container-wrap" }, [
                  _c(
                    "div",
                    { staticClass: "box__container-rows" },
                    [
                      _vm.isFinanzierungen
                        ? [
                            !_vm.$isSmallScreen
                              ? _c(
                                  "div",
                                  { staticClass: "tbl tbl-full" },
                                  [
                                    _c("div", { staticClass: "tbl-row" }, [
                                      _c("div", {
                                        staticClass:
                                          "tbl-cell tbl-cell-max text-left text-small"
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "tbl-cell text-right text-small"
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "hide-on-small-screen"
                                            },
                                            [_vm._v("Beiträge / Jahr")]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "hide-on-large-screen"
                                            },
                                            [_vm._v("Beiträge / J")]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "tbl-cell text-right text-small"
                                        },
                                        [_vm._v("Summe")]
                                      )
                                    ]),
                                    _vm._l(
                                      _vm.info.steckBreifZusatz.kredite,
                                      function(item, i) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              key: "fin" + i,
                                              staticClass: "tbl-row"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "tbl-cell text-left break-word"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.kreditform)
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "tbl-cell text-right"
                                                },
                                                [
                                                  item.yearRate
                                                    ? _c("CurrencyLabel", {
                                                        attrs: {
                                                          value: item.yearRate
                                                        }
                                                      })
                                                    : _vm._e()
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "tbl-cell text-right"
                                                },
                                                [
                                                  _c("CurrencyLabel", {
                                                    attrs: {
                                                      value: item.aktWert
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    )
                                  ],
                                  2
                                )
                              : _c("Table", {
                                  attrs: {
                                    tableId:
                                      "8530795e-c312-4451-942e-83d08585c55e",
                                    hidePagination: "",
                                    mobileConfig:
                                      _vm.mobileFinanzierungenTable
                                        .mobileConfig,
                                    headers:
                                      _vm.mobileFinanzierungenTable.headers,
                                    rows: _vm.mobileFinanzierungenTable.rows
                                  }
                                })
                          ]
                        : _c("NoData", {
                            attrs: {
                              content: "Keine Finanzierungen vorhanden",
                              noIcon: ""
                            }
                          })
                    ],
                    2
                  ),
                  !_vm.$isSmallScreen
                    ? _c("div", { staticClass: "bottom-row" }, [
                        _c(
                          "div",
                          { staticClass: "text-right" },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: { to: "/home/verbindlichkeiten/credit" }
                              },
                              [_vm._v("Finanzierungen anzeigen")]
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ])
              ]
            },
            proxy: true
          },
          {
            key: "WeitereVerbindlichkeiten",
            fn: function() {
              return [
                _c("div", { staticClass: "box__container-wrap" }, [
                  _c(
                    "div",
                    { staticClass: "box__container-rows" },
                    [
                      _vm.isWeitereVerbindlichkeiten
                        ? [
                            !_vm.$isSmallScreen
                              ? _c(
                                  "div",
                                  { staticClass: "tbl tbl-full" },
                                  [
                                    _c("div", { staticClass: "tbl-row" }, [
                                      _c("div", {
                                        staticClass:
                                          "tbl-cell tbl-cell-max text-left text-small"
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "tbl-cell text-right text-small"
                                        },
                                        [_vm._v("Ende")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "tbl-cell text-right text-small"
                                        },
                                        [_vm._v("Summe")]
                                      )
                                    ]),
                                    _vm._l(
                                      _vm.info.steckBreifZusatz
                                        .weitereVerbindlichkeitenOutput,
                                      function(item, i) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              key: "fin" + i,
                                              staticClass: "tbl-row"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "tbl-cell text-left break-word"
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(item.produktart) +
                                                        " "
                                                    )
                                                  ]),
                                                  item.produktbez
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "- " +
                                                            _vm._s(
                                                              item.produktbez
                                                            )
                                                        )
                                                      ])
                                                    : _vm._e()
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "tbl-cell text-right"
                                                },
                                                [_vm._v(_vm._s(item.ende))]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "tbl-cell text-right"
                                                },
                                                [
                                                  _c("CurrencyLabel", {
                                                    attrs: {
                                                      value: item.aktWert
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    )
                                  ],
                                  2
                                )
                              : _c("Table", {
                                  attrs: {
                                    tableId:
                                      "7934bc38-d9f8-4909-b779-b9a93719e2aa",
                                    hidePagination: "",
                                    mobileConfig:
                                      _vm.mobileFinanzierungenTable
                                        .mobileConfig,
                                    headers:
                                      _vm.mobileFinanzierungenTable.headers,
                                    rows: _vm.mobileFinanzierungenTable.rows
                                  }
                                })
                          ]
                        : _c("NoData", {
                            attrs: {
                              content: "Keine Daten vorhanden",
                              noIcon: ""
                            }
                          })
                    ],
                    2
                  ),
                  !_vm.$isSmallScreen
                    ? _c("div", { staticClass: "bottom-row" }, [
                        _c(
                          "div",
                          { staticClass: "text-right" },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to:
                                    "/home/verbindlichkeiten/weitere-verbindlichkeiten/search"
                                }
                              },
                              [_vm._v("Weitere Verbindlichkeiten")]
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ])
              ]
            },
            proxy: true
          },
          {
            key: "WeiteresVermögen",
            fn: function() {
              return [
                _c("div", { staticClass: "box__container-wrap" }, [
                  _c(
                    "div",
                    { staticClass: "box__container-rows" },
                    [
                      _vm.isWeiteresVermoegen
                        ? [
                            !_vm.$isSmallScreen
                              ? _c(
                                  "div",
                                  { staticClass: "tbl tbl-full" },
                                  [
                                    _c("div", { staticClass: "tbl-row" }, [
                                      _c("div", {
                                        staticClass:
                                          "tbl-cell tbl-cell-max text-left text-small"
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "tbl-cell tbl-cell-max text-left text-small"
                                        },
                                        [_vm._v("Gesellschaft")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "tbl-cell text-right text-small"
                                        },
                                        [_vm._v("Aktueller Wert")]
                                      )
                                    ]),
                                    _vm._l(
                                      _vm.info.steckBreifZusatz
                                        .weitereVermoegenOutput,
                                      function(item, i) {
                                        return [
                                          _c(
                                            "div",
                                            {
                                              key: "fin" + i,
                                              staticClass: "tbl-row"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "tbl-cell text-left break-word"
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(item.produktart) +
                                                        " "
                                                    )
                                                  ]),
                                                  item.produktbez
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "( " +
                                                            _vm._s(
                                                              item.produktbez
                                                            ) +
                                                            " )"
                                                        )
                                                      ])
                                                    : _vm._e()
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "tbl-cell text-left"
                                                },
                                                [
                                                  _vm._v(
                                                    " " + _vm._s(item.company)
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "tbl-cell text-right"
                                                },
                                                [
                                                  _c("CurrencyLabel", {
                                                    attrs: {
                                                      value: item.aktWert
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    )
                                  ],
                                  2
                                )
                              : _c("Table", {
                                  attrs: {
                                    tableId:
                                      "5023e615-0515-4363-a3c4-06e857ecaf99",
                                    hidePagination: "",
                                    mobileConfig:
                                      _vm.mobileFinanzierungenTable
                                        .mobileConfig,
                                    headers:
                                      _vm.mobileFinanzierungenTable.headers,
                                    rows: _vm.mobileFinanzierungenTable.rows
                                  }
                                })
                          ]
                        : _c("NoData", {
                            attrs: {
                              content: "Keine Daten vorhanden",
                              noIcon: ""
                            }
                          })
                    ],
                    2
                  ),
                  !_vm.$isSmallScreen
                    ? _c("div", { staticClass: "bottom-row" }, [
                        _c(
                          "div",
                          { staticClass: "text-right" },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to:
                                    "/home/vermogensubersicht/weitere-vermoegen/search"
                                }
                              },
                              [_vm._v("Weitere Vermögen")]
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ])
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }