var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "modal",
    attrs: { modalTitle: "neue Gesellschaft anlegen", size: "sm" },
    on: {
      onConfirmButton: function($event) {
        return _vm.save()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function() {
          return [
            _c("InputField", {
              attrs: { label: "Name", validateUntouched: "", autofocus: "" },
              model: {
                value: _vm.form.gesellName,
                callback: function($$v) {
                  _vm.$set(_vm.form, "gesellName", $$v)
                },
                expression: "form.gesellName"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }