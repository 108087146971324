export const IMMOBILIE_TYPE = 'Immobilie';
export const WEITERE_VERBINDLICHKEITEN_TYPE = 'WeitereVerbindlichkeiten';
export const WEITERE_VERMOEGEN_TYPE = 'WeitereVermoegen';

export const MAP_TYPE_TO_HOME_PATH = {
  Immobilie: '/home/immobilien',
  WeitereVerbindlichkeiten: '/home/verbindlichkeiten/weitere-verbindlichkeiten',
  WeitereVermoegen: '/home/vermogensubersicht/weitere-vermoegen',
};

export const MAP_TYPE_TO_TITLE = {
  Immobilie: 'Immobilien',
  WeitereVerbindlichkeiten: 'Weitere Verbindlichkeiten',
  WeitereVermoegen: 'Weiteres Vermögen',
};

export const MAP_TYPE_TO_TITLE_SINGULAR = {
  Immobilie: 'Immobilie',
  WeitereVerbindlichkeiten: 'Weitere Verbindlichkeiten',
  WeitereVermoegen: 'Weitere Vermögen',
};

export const SPROD_ID_NEW = 'neu';
