<template>
  <div>
    <InputTextArea label="Schadennotizen Vermittler" :autoGrow="true" v-model="form.maklerNotizen" @change="dataChanged()" ></InputTextArea>

  </div>

</template>

<script>
import { mapGetters } from 'vuex';
import SCHADENSMELDUNG_TYPES from "@/store/schadensmeldung/types";

import validator from "@/mixins/validator";
import { maxLength, } from "@/mixins/validator/rules";
import InputTextArea from '@/components/core/forms/InputTextArea.vue';

import mainFormMixin from './main-form-mixin';

export default {
  mixins: [mainFormMixin, validator],
  validators: {
    form: {
      maklerNotizen: [
      maxLength(4001, "Der Maximalwert beträgt 4000.", { forceTouch: true }),
      ],  
    }
  },
  props: {
    schadenId: {
      type: [Number, String],
    },
  },
  data() {
    
    return {
      form: {
        maklerNotizen: '',
        
      },
    };
  },
  computed: {
    ...mapGetters({
      schadensmeldung: SCHADENSMELDUNG_TYPES.GETTERS.SCHADENSMELDUNG,
    }),
    isNewSchaden() {
      return this.$route.params?.schadenId === "neuesSchaden";
    }
  },
  watch: {
    schadensmeldung: {
      handler(newValue) {
        this.setForm(newValue);
      },
      immediate: true,
    },
  },
  methods: {
    setForm(schadensmeldung) {
      if(!schadensmeldung) {
        return ;
      }
      
      this.$set(this, 'form', schadensmeldung);
      this.$set(this.form, 'maklerNotizen', schadensmeldung.maklerNotizen);
    },
  },
  mounted() {
    if(Object.keys(this.schadensmeldung)?.length) {
      this.setForm(this.schadensmeldung);
    } else {
      this.setForm({});
    }

  },
 
  components: {
    InputTextArea,
   
  },
}
</script>
