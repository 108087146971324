<template>
  <div :style="style">
    <v-chart ref="chart" class="chart" :option="option" :theme="themeKey" autoresize v-on="$listeners" />
  </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { ScatterChart } from "echarts/charts";
import {
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
  ToolboxComponent,
  GridComponent,
  MarkLineComponent,
} from "echarts/components";
import VChart, { INIT_OPTIONS_KEY } from "vue-echarts";

import echartsMixin from './echarts-mixin';

use([
  CanvasRenderer,
  ScatterChart,
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
  ToolboxComponent,
  GridComponent,
  MarkLineComponent,
]);

function getDefaultSeriesOption() {
  return {
    type: 'scatter',
    symbolSize: 12,
    symbol: 'circle',
    itemStyle: {
      borderColor: '#fff'
    }
  }
}

export default {
  mixins: [echartsMixin],
  components: {
    VChart
  },
  provide: {
    [INIT_OPTIONS_KEY]: { locale: 'DE' }
  },
  props: {
    chartData: {
      type: Array,
      default: () => []
    },
    gridHeight: {
      type: Number,
      default: null,
    },
    showDataZoom: {
      type: Boolean,
      default: false,
    },
    xAxisName: {
      type: String,
      default: '',
    },
    yAxisName: {
      type: String,
      default: ''
    },
    series: {
      type: Array,
      default: () => []
    },
    tooltip: {
      type: Object,
      default: null,
    },
    customColors: {
      type: Array,
      default: null,
    },
    hasLegend: {
      type: Boolean,
      default: null,
    },
    changeOption: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    option() {
      const options = {
        ...(this.tooltip && {
          tooltip: { 
            ...this.tooltip,
            position: { left: 'center', top: 'auto'},
          }
        }),
        legend: {
          show: this.hasLegend != null ? this.hasLegend : (this.series?.length || 0) < 14,
          bottom: 0,
        },
        color: this.customColors || this.themeColors,
        backgroundColor: 'transparent',
        grid: {
          top: '10%',
          bottom: '20%',
          left: '15%',
          right: '12.5%',
        },
        xAxis: {
          show: true,
          type: 'value',
          name: this.xAxisName,
          nameLocation: 'middle',
          boundaryGap: false,
          nameGap: 25,
          nameTextStyle: {
            fontWeight: 'bold'
          },
          min: function (value) {
            return value.min < 0 ? null : Math.round(value.min - (value.min * .1));
          },
        },
        yAxis: {
          show: true,
          type: 'value',
          name: this.yAxisName,
          nameLocation: 'middle',
          boundaryGap: false,
          nameGap: 25,
          nameTextStyle: {
            fontWeight: 'bold'
          },
          min: function (value) {
            return Math.round(value.min - (value.min * .1));
          },
        },
        ...(this.showDataZoom && {dataZoom: [
          {
            type: 'inside',
            start: 0,
            end: 100
          },
          {
            start: 0,
            end: 100
          }
        ]}),
        series: this.series.map(serie => ({...getDefaultSeriesOption(), ...serie}))
      }
      const finalOptions = this.changeOption ? this.changeOption(options) : options;
      return {
        ...finalOptions || {},
          backgroundColor: 'transparent',
      };
    }
  }
};
</script>

<style scoped>

</style>