<template>
  <div class="depotnummer-anfordern-button-component mb-4">
    <div class="depotnummer-anfordern-button-component-input-container">
      <BaseButton @click="requestAnlagestrategien()" :disabled="loading">
        Anlagestrategien anfordern <AnimatedSpinner v-if="loading"/>
      </BaseButton>
      <br>
      <br>
      <InputRadioBoxGroup 
        v-if="anlagestrategienOptions && anlagestrategienOptions.length"
        :title="label"
        :value="value"
        :validateUntouched="true"
        :disabled="loading"
        :values="anlagestrategienOptions"
        labelClass="font-bold"
        @input="handleInputEvent($event)" />
    <div v-else>
      {{ responsetext }}
    </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import InputField from "@/components/core/forms/InputField.vue";
import InputRadioBoxGroup from "@/components/core/forms//radiobox/InputRadioBoxGroup.vue";
import BaseButton from "@/components/core/BaseButton.vue";
import ANTRAG_TYPES from '@/store/antrag/types';
import validator from '@/mixins/validator';
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';
import axios from 'axios';

const config = {
  defaultSpinner: true
}

export default {
  mixins: [validator],
  components: {
    InputField,
    BaseButton,
    AnimatedSpinner,
    InputRadioBoxGroup,
  },
  validators: {
  },
  props: {
    id: {
      type: String
    },
    config: {
    },
    label: {
      type: String
    },
    value: {
      type: [String, Number]
    },
    antragId: {
      type: String
    },
    additionalValidators: {
      type: Array
    },
    isComponentHalfSize: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      anlagestrategienOptions: [],
      loading: false,
      responsetext: 'Keine Anlagestrategien vorhanden',
    }
  },
  computed: {
    ...mapGetters({
      requestedAnlagestrategie: ANTRAG_TYPES.GETTERS.REQUESTED_EBASE_AAB_ANLAGESTRATEGIEN,
      antraegeData: ANTRAG_TYPES.GETTERS.ANTRAEGE_DATA,
    }),
  },
  methods: {
    handleInputEvent(event) {
      this.$emit('input', event);
    },
    async requestAnlagestrategien() {
      this.loading = true;
      await this.saveAntrag(true)
      
      if (this.antraegeData[this.antragId]?.antragsdatenId) {

        let serviceUrl = '/antraegeService/retrieveAnlagestrategien';

        axios.post(`${process.env.VUE_APP_API}` + serviceUrl, this.antraegeData[this.antragId], config).then(response => {
          if (response && response.data) {
            this.$store.commit(ANTRAG_TYPES.MUTATIONS.SET_REQUESTED_EBASE_AAB_ANLAGESTRATEGIEN, {id: this.antragId, data: response.data});
            this.anlagestrategienOptions = response.data
            this.responsetext = 'Keine Anlagestrategien unter dieser Konfiguration'
          }

        }).finally(() => this.loading = false)
      } else {
        this.responsetext = 'Anlagestrategien können nicht abgerufen werden, da noch Fehler im Antrag bestehen'
        this.loading = false
      }
    },
    saveAntrag(forceSave) {
      if (this.antragId && forceSave) {
        const payload = {
          id: this.antragId,
          data: {}
        }
        this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_ANTRAG_DATA, payload)
      }
      return this.$store.dispatch(ANTRAG_TYPES.ACTIONS.SAVE_ANTRAG, {
        id: this.antragId,
        lagerstelle: this.$route.params.lagerstelle,
      })
    },
  },
  mounted() {
    if (this.config && this.config.strategienTable && this.config.strategienTable.length) {
      this.anlagestrategienOptions = this.config.strategienTable
    }else  if (this.requestedAnlagestrategie) {
      this.anlagestrategienOptions = this.requestedAnlagestrategie[this.antragId]
    }
  }
};
</script>

<style scoped>
</style>
