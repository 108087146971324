<template>
	<CoreCard ref="coreCard" :showToolbar="showToolbar" :listView="listView">
		<template v-if="showTitle" v-slot:header>
			{{chartName}}
		</template>
		<template v-slot:alternative>
		</template>
    <div class="content">
      <div v-if="isContainerRendered && data[`${chartName}`]" class="w-100">
        <div class="table w-100">
          <div class="row">
            <div class="table-cell">Verträge insgesamt</div>
            <div class="table-cell right">{{formatNumber(data[`${chartName}`].insgesmat)}}</div>
          </div>
          <div class="row">
            <div class="table-cell">Ø Verträge pro Kunde</div>
            <div class="table-cell right">{{formatNumber(data[`${chartName}`].VertageKunde.toFixed(2))}}</div>
          </div>
          <div class="row">
            <div class="table-cell">Ø Courtage pro Kunde</div>
            <div class="table-cell right">{{formatNumber(data[`${chartName}`].UmsatzKunde.toFixed(2))}}€</div>
          </div>
        </div>
      </div>
    </div>
	</CoreCard>
</template>

<script>
import CoreCard from '../CoreCard/CoreCard';
import { formatNumber } from '@/helpers/number-formatter.js';
import cardMixin from '../CoreCard/card-mixin';


export default {
  mixins: [cardMixin],

  data: function() {
    return {
      isContainerRendered: false,
    };
  },

  computed: {
    chartName: function() {
      return this.propsData.option;
    },
    data: function() {
      return this.propsData.data;
    },
    customColors: function() {
      return this.propsData.customColors;
    },
  },

  mounted: function () {
    this.$nextTick(function () {
      this.isContainerRendered = true;
    });
  },
  methods: {
    formatNumber(v) {
      return formatNumber(v);
    }
  },

  components: {
    CoreCard
  },
}
</script>

<style scoped>
.content {
  width: 100%;
  height: 250px;
  position: relative;
  margin-top: -12px;
  /* text-align: center; */
  min-height: auto;


  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}
.text-lg {
  /* font-size: 3rem; */
  line-height: 3em;
  color: var(--color-primary);
}
.text-sm {
  font-size: 1rem;
}
.table {
  display: table;
  padding: 30px;
  box-sizing: border-box;
}
.table-cell {
  display: table-cell;
}
.row {
  display: table-row;
}
.right {
  text-align: right;
}
.w-100 {
  width: 100%;
}
</style>
