
export function removeInvalidWidget(configuredWidgets) {
  return configuredWidgets.filter(cw => cw !== null);
}

function isRemovable(widget = {}) {
  return 'removable' in widget ? widget.removable() : true;
}

function isRemoved(configuredWidget, externalConfigWidget) {
  if(externalConfigWidget) {
    return externalConfigWidget.removed;
  }
  return configuredWidget.removed;
}

export function assignWidget(widgets, configuredWidgets, externalConfig) {
  return [...configuredWidgets || widgets]?.map(configuredWidget => {
    const widget = widgets.find(w => w.name === configuredWidget.name);
    if(!widget) return null;

    const externalConfigWidget = externalConfig?.find(w => w.name === configuredWidget.name);
    return {
      ...configuredWidget, 
      ...widget,
      size: configuredWidget.size,
      removed: isRemovable(widget) ? isRemoved(configuredWidget, externalConfigWidget) : false,
    };
  });
}

export function contentToSave(widgets) {
  if(!widgets) {
    return '';
  }

  const widgetsToSave = [...widgets].map((widget) => ({
    name: widget.name,
    size: widget.size,
    removed: widget.removed,
  }));
  return JSON.stringify(widgetsToSave);
}
