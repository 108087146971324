import MSC_NEWS_TYPES from './types';
import CORE_TYPES from '../core/types';
import axios from 'axios';
import { VIEW_ROLES } from '@/router/roles';

const config = {
  defaultSpinner: true
};

export default {
  async [MSC_NEWS_TYPES.ACTIONS.GET_MSC_NEWS]({ getters, commit}, { lastNews, filter }) {
    const lastNewsCount = 5

    let params = {};

    let url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/mscnews/get_msc_news`;
    if (lastNews) {
      params.newsCount = lastNewsCount;
    }
    if(filter) {
      params = {...params, ...filter}
    }

    await axios.get(url, {params, config}).then(response => {
      commit(MSC_NEWS_TYPES.MUTATIONS.GET_MSC_NEWS_SUCCESS, { mscNews: response.data });
    });
  },

  async [MSC_NEWS_TYPES.ACTIONS.GET_MSC_NEWS_COUNT]({ getters, commit}) {

    let url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/mscnews/get_msc_news_count`;

    await axios.get(url, config).then(response => {
      commit(MSC_NEWS_TYPES.MUTATIONS.GET_MSC_NEWS_COUNT_SUCCESS, { mscNewsCount: response.data });
    });
  },

  async [MSC_NEWS_TYPES.ACTIONS.MARK_AS_READ]({ getters, commit }, payload) {
    if (!payload) {
      return
    }

    const messageIds = Array.isArray(payload) ? payload : [payload]

    await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/mscnews/mark_msc_news_as_read`, messageIds, config).then(() => {
      commit(MSC_NEWS_TYPES.MUTATIONS.MARK_AS_READ, messageIds)
    })
  },

  async [MSC_NEWS_TYPES.ACTIONS.MARK_AS_UNREAD]({ getters, commit }, payload) {
    if (!payload) {
      return
    }

    const messageIds = Array.isArray(payload) ? payload : [payload]

    await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/mscnews/mark_msc_news_as_unread`, messageIds, config).then(() => {
      commit(MSC_NEWS_TYPES.MUTATIONS.MARK_AS_UNREAD, messageIds)
    })
  },

  async [MSC_NEWS_TYPES.ACTIONS.TOGGLE_NEWS_MAJOR]({ getters, commit }, payload) {
    if (!payload?.ids || payload?.set == null) {
      return
    }

    const messageIds = Array.isArray(payload.ids) ? payload.ids : [payload.ids]
    const not = payload.set ? '' : '_not'

    await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/mscnews/mark_msc_news_as${not}_major`, messageIds, config);
  },

  async [MSC_NEWS_TYPES.ACTIONS.GET_FK_EVENTS]({ getters, commit, state}) {
    if (!state.fkEventsLoading) {
      commit(MSC_NEWS_TYPES.MUTATIONS.SET_IS_LOADING, true);
      let url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/mscnews/get_fk_events`;

      await axios.get(url, config).then(response => {
        commit(MSC_NEWS_TYPES.MUTATIONS.GET_FK_EVENTS_SUCCESS, response.data);
        commit(MSC_NEWS_TYPES.MUTATIONS.SET_IS_LOADING, false);
      })
      .catch(() => {
        commit(MSC_NEWS_TYPES.MUTATIONS.SET_IS_LOADING, false);
      })
      
    }
  },

  async [MSC_NEWS_TYPES.ACTIONS.GET_MSC_HOME_LINK]({ getters, commit }) {
    if (!getters[CORE_TYPES.GETTERS.IS_LOGGED_IN] || !getters[CORE_TYPES.GETTERS.HAS_ROLES]([VIEW_ROLES.VIEW_BROKER, VIEW_ROLES.VIEW_GESELLSCHAFT])) {
      return
    }

    const result = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/mscnews/home_msc_link`, config)
    const data = result.data || {}

    commit(MSC_NEWS_TYPES.MUTATIONS.SET_HOME_MSC_LINK, data)
  },

}