import { PrimaryMenu, MenuItem, } from './../menu-utils';

import { PhDatabase, } from 'phosphor-vue';
import UndrawDiary from '@/components/icons/undraw/UndrawDiary.vue';
import UndrawInvest from '@/components/icons/undraw/UndrawInvest.vue'

export default PrimaryMenu('/dbm', 'Dbm', PhDatabase, [
  MenuItem('/dbm/dbm_tree', 'blockers', UndrawDiary),
  MenuItem('/dbm/dbm_table_active', 'active sessions', UndrawDiary),
  MenuItem('/dbm/dbm_all_sessions', 'all sessions', UndrawDiary),
  MenuItem('/dbm/dbm_runned_interfaces', 'runned interfaces', UndrawDiary),
  MenuItem('/dbm/dbm_transactions', 'transactions', UndrawDiary),
  MenuItem('/dbm/dbm_standby', 'Standby', UndrawDiary),
  MenuItem('/dbm/dbm_cache', 'Objects in cache', UndrawDiary),
  MenuItem('/dbm/dbm_freespace', 'Free space', UndrawDiary),
  MenuItem('/dbm/dbm_undo', 'Undo', UndrawDiary),
  MenuItem('/dbm/dbm_unused_space', 'unused space', UndrawDiary),
  MenuItem('/dbm/dbm_longops', 'Long operations', UndrawDiary),
  MenuItem('/dbm/dbm_rman_backups', 'Backups', UndrawDiary),
  MenuItem('/dbm/dbm_g', 'Diagram', UndrawInvest),
  MenuItem('/dbm/avg_response_time', 'Avg Response Time', UndrawInvest),
  MenuItem('/dbm/avg_response_time_per_endpoint', 'Avg Response Time Endpoint', UndrawInvest),
  /*MenuItem('/dbm/dbm_g_rio', 'Diagram Read IO', UndrawInvest),
  MenuItem('/dbm/dbm_g_wio', 'Diagram Write IO', UndrawInvest),
  MenuItem('/dbm/dbm_g_cb', 'Diagram changed blocks', UndrawInvest),
  MenuItem('/dbm/dbm_g_pr', 'Diagram CPU', UndrawInvest),*/
]);
