import EXTERNE_ZUGANG_CONFIG_TYPES from './types';

export default {

  [EXTERNE_ZUGANG_CONFIG_TYPES.GETTERS.CONFIG_DATA](state) {
    return { ...state.configData || {}, };
  },

  [EXTERNE_ZUGANG_CONFIG_TYPES.GETTERS.SELECTED_ZUGANGSART](state) {
    const { selectedZugangsArt, configData, } = state;
    const { zugangsArt, } = configData;
    if(selectedZugangsArt && zugangsArt?.length) {
      const selected = zugangsArt.find(art => art.id === selectedZugangsArt);
      return selected && { ...selected, } || null;
    }
    return null;
  },

  [EXTERNE_ZUGANG_CONFIG_TYPES.GETTERS.LIST](state) {
    return [ ...state.list || [] ];
  },

  [EXTERNE_ZUGANG_CONFIG_TYPES.GETTERS.DEFINITION](state) {
    return { ...state.definition || {}, };
  },

  [EXTERNE_ZUGANG_CONFIG_TYPES.GETTERS.BIPRO_SIBLING_GESELLSCHAFTEN](state) {
    return { ...state.biproSiblingGesellschaften || {}, };
  },

}
