import MARKTBERICHTUPLOADEN_TYPES from './types';
import Vue from 'vue';

export default {
  [MARKTBERICHTUPLOADEN_TYPES.MUTATIONS.FIELDS_DEFINITION_SUCCESS](state, payload) {
    Vue.set(state, 'fieldsDefinition', payload);
  },
  [MARKTBERICHTUPLOADEN_TYPES.MUTATIONS.GET_PDF_FILE_SUCCESS](state, payload) {
    console.log(payload)
  },
  [MARKTBERICHTUPLOADEN_TYPES.MUTATIONS.FIND_DOCUMENTS_SUCCESS](state, documents) {
    Vue.set(state, 'documents', documents);
  },
}