import STORNO_WARNUNG_TYPES from './types';

export default {
  [STORNO_WARNUNG_TYPES.GETTERS.STORNO_WARNUNG_OVERVIEW](state) {
    return state.stornoWarnungOverview;
  },
  [STORNO_WARNUNG_TYPES.GETTERS.STORNO_WARNUNG](state) {
    return state.stornoWarnungList;
  },
  [STORNO_WARNUNG_TYPES.GETTERS.PAGE_INDEX](state) {
    return state.pageIndex;
  },
  [STORNO_WARNUNG_TYPES.GETTERS.TOTAL_ROWS](state) {
    return state.totalRows;
  },
  [STORNO_WARNUNG_TYPES.GETTERS.DEFAULT_FILTERS](state) {
    return state.defaultFilters;
  },
  [STORNO_WARNUNG_TYPES.GETTERS.IS_FILTER_SAVED](state) {
    return state.isFilterSaved;
  },
  [STORNO_WARNUNG_TYPES.GETTERS.GET_STORNO_ARTS](state) {
    return state.stornoArts;
  },
  [STORNO_WARNUNG_TYPES.GETTERS.IS_LOADING](state) {
    return state.isLoading;
  },
  [STORNO_WARNUNG_TYPES.GETTERS.STORNO_SELECTED](state) {
    return state.stornoSelected;
  },
}