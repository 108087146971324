<template>
  <BaseButton isClear @click="$emit('click', true)" :disabled="disabled">
    <template v-if="icon">
      <component :is="icon"
        :size="size"
        :title="title"
        :class="{ 'html-toolbar__button--active': active }"/>
    </template>
    <template v-if="showLabel">
      {{ title }}
    </template>
    <slot></slot>
  </BaseButton>
</template>

<script>
import BaseButton from '@/components/core/BaseButton.vue';

export default {
  components: {
    BaseButton
  },
  props: {
    icon: {
    },
    title: {
      type: String,
    },
    size: {
      type: Number,
      default: 22,
    },
    active: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
  }

}
</script>
