<template>
    <div>
            <InputField label="Anrede" v-model="form.anwenderAnrede" isComponentHalfSize @change="updateNafiStore('anwenderAnrede', $event)" />
            <InputField label="Vorname" v-model="form.anwenderVorname" isComponentHalfSize @change="updateNafiStore('anwenderVorname', $event)" />
            <InputField label="Nachname" v-model="form.anwenderNachname" isComponentHalfSize @change="updateNafiStore('anwenderNachname', $event)" />
            <InputField label="Straße" v-model="form.anwenderStrasse" isComponentHalfSize @change="updateNafiStore('anwenderStrasse', $event)" />
            <InputField label="PLZ" v-model="form.anwenderPlz" isComponentHalfSize @change="updateNafiStore('anwenderPlz', $event)" />
            <InputField label="Ort" v-model="form.anwenderOrt" isComponentHalfSize @change="updateNafiStore('anwenderOrt', $event)" />
            <InputField label="Telefon 1" v-model="form.anwenderTelefon1" isComponentHalfSize @change="updateNafiStore('anwenderTelefon1', $event)" inputmode="tel"/>
            <InputField label="Telefon 2" v-model="form.anwenderTelefon2" isComponentHalfSize @change="updateNafiStore('anwenderTelefon2', $event)" inputmode="tel"/>
            <InputField label="Telefax" v-model="form.anwenderTelefax" isComponentHalfSize @change="updateNafiStore('anwenderTelefax', $event)" inputmode="tel"/>
            <InputField label="E-Mail" v-model="form.anwenderEmail" isComponentHalfSize @change="updateNafiStore('anwenderEmail', $event)" />
    </div>
</template>

<script>
import InputField from '@/components/core/forms/InputField.vue';
import { email, formatPLZ, formatPhone } from "@/mixins/validator/rules";
import validator from "@/mixins/validator";
import mixin from '@/mixins/nafi/nafi-mixin.js';

export default {
    mixins: [validator, mixin],
    components: {
        InputField,
    },
    validators: {
        form: {
            anwenderPlz: formatPLZ('DE'),
            anwenderTelefon1: formatPhone(),
            anwenderTelefon2: formatPhone(),
            anwenderTelefax: formatPhone('Bitte geben Sie eine gültige Faxnummer ein'),
            anwenderEmail: email(),
        }
    },
    data() {
        return {
            form: {
                anwenderAnrede: '',
                anwenderVorname: '',
                anwenderNachname: '',
                anwenderStrasse: '',
                anwenderPlz: '',
                anwenderOrt: '',
                anwenderTelefon1: '',
                anwenderTelefon2: '',
                anwenderTelefax: '',
                anwenderEmail: '',
            },
        }
    },
    mounted() {
        this.initForm();
    },

}
</script>

<style>

</style>