<template>
  <div>
    <table class="fc-table fc-table-divider fc-table-small">
      <thead>
        <tr>
          <th>ISIN</th>
          <th>Fondsname</th>
          <th>Sparrate in EUR</th>
          <th>Vertragslaufzeit (in Jahren)</th>
          <!--<th>Anlagetermin</th>-->
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(position, index) in positions" :key="index">
          <td>{{position.isin}}</td>
          <td>{{getFondsName(position)}}</td>
          <td>
            <InputField 
              v-model="position.betrag"
              type="currency" 
              :isEmbedded="true" 
              :disabled="disabled"
              @change="onChangeInputField($event, position, 'betrag')"
              />
          </td>
          <td>
            <InputField 
              v-model="position.vertragsLaufZeit"
              type="number"
              :isEmbedded="true" 
              :disabled="disabled"
              @change="onChangeInputField($event, position, 'vertragsLaufZeit')"
            />
          </td>
          <td>
            <WertpapierAuswahlPositionsButtons 
              :position="position" 
              :positionName="getFondsName(position)"
              :antragId="antragId"
              :categoryId="categoryId"
              :disabled="disabled"
              />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ANTRAG_TYPES from '@/store/antrag/types';
import FONDSFINDER_TYPES from '@/store/fondsfinder/types';
import InputField from "@/components/core/forms/InputField.vue";
import WertpapierAuswahlPositionsButtons from '@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlPositionsButtons.vue'

export default {
  props: {
    positions: {
    },
    config: {
    },
    antragId: {
    },
    categoryId: {
    },
    betrag: {
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      positionInfo: FONDSFINDER_TYPES.GETTERS.POSITION_INFO
    }),
  },
  methods: {
    getFondsName(pos) {
      return this.positionInfo && this.positionInfo[pos.isin] && this.positionInfo[pos.isin].wertpapiername || pos.fondsname || pos.wertpapiername;
    },
    commitUpdatePositionsFromEvent(positionChange, isin) {
      const payload = {
        id: this.antragId,
        categoryId: this.categoryId,
        fonds: [
          { 
            ...positionChange,
            isin,
          }
        ]
      }
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_POSITIONS, payload)
    },
    onChangeInputField($event, position, field) { 
      const positionChange = { [field]: $event }
      this.commitUpdatePositionsFromEvent(positionChange, position.isin)
    }
  },
  components: {
    InputField,
    WertpapierAuswahlPositionsButtons,
  }
}
</script>

<style scoped>
td {
  vertical-align: middle;
}
</style>