var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "editDocumentModal",
          attrs: { modalTitle: "Datei bearbeiten" },
          on: { onConfirmButton: _vm.confirmButton }
        },
        [
          _vm.additionalInputs && _vm.additionalInputs.length
            ? _c("UploadInputList", {
                attrs: { inputs: _vm.additionalInputs },
                model: {
                  value: _vm.formData,
                  callback: function($$v) {
                    _vm.formData = $$v
                  },
                  expression: "formData"
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }