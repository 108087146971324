import { render, staticRenderFns } from "./PerformanceDetails.vue?vue&type=template&id=437b05e1&scoped=true&"
import script from "./PerformanceDetails.vue?vue&type=script&lang=js&"
export * from "./PerformanceDetails.vue?vue&type=script&lang=js&"
import style0 from "./PerformanceDetails.vue?vue&type=style&index=0&id=437b05e1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "437b05e1",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("P:\\workspaces\\fk0\\fc\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('437b05e1')) {
      api.createRecord('437b05e1', component.options)
    } else {
      api.reload('437b05e1', component.options)
    }
    module.hot.accept("./PerformanceDetails.vue?vue&type=template&id=437b05e1&scoped=true&", function () {
      api.rerender('437b05e1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/performance/PerformanceDetails.vue"
export default component.exports