var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-forms__container" },
    [
      _c("div", { staticClass: "input-forms__label-container" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col" }, [
            _vm.title
              ? _c(
                  "div",
                  {
                    staticClass:
                      "input-forms__label-content input-forms__label-content--bigger"
                  },
                  [
                    _c("PhBank", { attrs: { size: 16 } }),
                    _vm._v(" " + _vm._s(_vm.title) + " ")
                  ],
                  1
                )
              : _vm._e()
          ])
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col" },
            [
              _c("ComboBox", {
                attrs: {
                  value: _vm.portfolioCombo,
                  values: _vm.values,
                  isEmbedded: ""
                },
                on: {
                  input: function($event) {
                    return _vm.getPortfolioStruktur($event)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-auto" },
            [
              _c(
                "BaseButton",
                {
                  attrs: { disabled: _vm.portfolioCombo !== "freie Eingabe:" },
                  on: { click: _vm.openFormsFinder }
                },
                [_vm._v("Wertpapier hinzufügen")]
              )
            ],
            1
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "input-forms__input-container" },
        [
          _vm.rows.length
            ? _c("Table", {
                attrs: {
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowId: "index",
                  hidePagination: ""
                },
                on: { "action-DELETE": _vm.openModalDeleteFond },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "prozent",
                      fn: function(row) {
                        return [
                          _c("InputField", {
                            attrs: {
                              type: "percent",
                              precission: 6,
                              value: row.prozent,
                              disabled: _vm.isMusterDepot
                            },
                            on: {
                              input: function($event) {
                                return _vm.onChangeProzent($event, row)
                              }
                            }
                          })
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  975045869
                )
              })
            : _vm.errorMessage
            ? _c("div", [_vm._v(_vm._s(_vm.errorMessage))])
            : _c("div", [_vm._v("Wertpapierauswahl ist leer")])
        ],
        1
      ),
      _c("DeletePositionModal", {
        ref: "deleteModal",
        attrs: { position: _vm.positionToDelete },
        on: {
          delete: function($event) {
            return _vm.deletePosition()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }