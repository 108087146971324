<template>
  <div>
    <div class="box__container">
      <div class="box__title">Einstellungen</div>

      <ComboBox
        isComponentHalfSize
        v-model="form.verwahrstelle"
        :values="valuesVerwahrstelle"
        label="Verwahrstelle"
        validateUntouched
        :disabled="form.disabled"
        @change="updateVerwahrstelle($event)"
      />
      <ComboBox
        isComponentHalfSize
        v-model="form.risikoneigung"
        :values="valuesRisikoneigung"
        label="Risiko der Strategie"
        validateUntouched
        :sortComboboxValues="false"
        :disabled="form.disabled"
        @change="updateStore({ risikoneigung: $event }, 'settings')"
      />
      <ComboBox
        isComponentHalfSize
        v-model="form.autoRebalancing"
        :values="valuesAutoRebalancing"
        label="Automatisches Rebalancing"
        validateUntouched
        @change="updateStore({ autoRebalancing: $event }, 'settings')"
      />
      <InputField
        isComponentHalfSize
        v-model="form.portfolioKennung"
        label="Strategie-Kennung"
        validateUntouched
        :disabled="!!schemaId || form.portfolioKennungDisabled"
        @change="updateStore({ portfolioKennung: $event }, 'settings')"
      />
      <InputField
        isComponentHalfSize
        v-model="form.minEinmalanlageSumme"
        type="currency"
        validateUntouched
        :precision="2"
        label="Minimum Einmalanlage"
        @change="
          updateStore(
            { minEinmalanlageSumme: formatNumber($event) },
            'settings'
          )
        "
      />
      <InputField
        isComponentHalfSize
        v-model="form.minSparplanSumme"
        type="currency"
        validateUntouched
        :precision="2"
        :label="getLabelSparplan"
        @change="
          updateStore({ minSparplanSumme: formatNumber($event) }, 'settings')
        "
      />
      <InputField
        isComponentHalfSize
        v-model="form.haltedauer"
        type="number"
        validateUntouched
        :precision="0"
        label="Haltedauer in Jahren"
        @change="updateStore({ haltedauer: $event }, 'settings')"
      />
    </div>
  </div>
</template>

<script>
import VV_TYPES from "@/store/vermoegensverwaltung/types";

import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputField from "@/components/core/forms/InputField.vue";
import mixin from "@/mixins/vermoegensverwaltung/vv-mixin.js";

export default {
  mixins: [mixin],
  components: {
    InputField,
    ComboBox,
  },
  data() {
    return {
      form: {
        verwahrstelle: "",
        risikoneigung: "",
        autoRebalancing: "",
        portfolioKennung: "",
        minEinmalanlageSumme: "",
        minSparplanSumme: "",
        haltedauer: "",
      },
    };
  },
  mounted() {
    this.initValuesSettings();
  },
  watch: {
    schemaData() {
      this.initValuesSettings();
      this.updateWarnings(true);
    },
  },
  methods: {
    updateVerwahrstelle(value) {
      this.updateStore({ verwahrstelle: value }, "settings");
      this.$store.dispatch(VV_TYPES.ACTIONS.UPDATE_VERWAHRSTELLE_SETTINGS, {
        verwahrstelle: value,
      });      
    },
  },
  computed: {
    valuesVerwahrstelle() {
      return this.dataSettings?.valuesVerwahrstelle || [];
    },
    valuesRisikoneigung() {
      return this.dataSettings?.valuesRisikoneigung || [];
    },
    valuesAutoRebalancing() {
      return this.dataSettings?.valuesAutoRebalancing || [];
    },
    getLabelSparplan() {
      return this.isFa
        ? "Minimum ratierlicher Nachkauf"
        : "Minimum Sparplansumme";
    },
  },
};
</script>

<style scoped>
</style>