<template>
  <div class="box__container-wrap">
    <div class="box__container-rows">
      <div class="bemerkung-content" v-if="isSichtbar!=null">
        <span class="bemerkung-text" v-html="BemerkungText"></span> 
        <button v-if="!isCustomerOnly" type="button" class="btn-clear clickable ml-2" @click="edit()"><PhPencilLine :size="16" /></button>
        <div v-if="!isCustomerOnly" class="mt-2">
          <InputToggleSwitch  v-model="isBemSichtbar" label="Sichtbar für Kunde" />
        </div>
      </div>
      <GhostLoading v-else type="block" />
    </div>

    <BaseModal 
      ref="BemerkungEditModal"
      modalTitle="Bemerkung bearbeiten"
      :confirmDisabled="!edited"
      @onConfirmButton="save()"
    >
      <template #default>
        <InputTextArea label="" v-model="BemerkungEdit" :rows="5" :maxlength="4000" />
      </template>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CUSTOMERDATA_TYPES from '@/store/customerData/types';
import CORE_TYPES from "@/store/core/types";

import { PhPencilLine } from 'phosphor-vue';
import BaseModal from '@/components/core/BaseModal.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import {sanitize} from '@/helpers/string-helper.js';
import GhostLoading from "@/components/core/loading/GhostLoading.vue";


export default {
  data() {
    return {
      BemerkungEdit: null,
    };
  },
  computed: {
    ...mapGetters({
      Bemerkung: CUSTOMERDATA_TYPES.GETTERS.BEMERKUNG_KUNDE,
      isSichtbar: CUSTOMERDATA_TYPES.GETTERS.BEMERKUNG_KUNDE_SICHTBAR,
      isCustomerOnly: CORE_TYPES.GETTERS.IS_CUSTOMER_ONLY,
    }),
    BemerkungText() {
      const Bemerkung = this.Bemerkung || 'Keine Daten';
      return sanitize(`${Bemerkung?.replace(/\n/gi, '<br>')}`);
    },
    edited() {
      return this.Bemerkung !== this.BemerkungEdit;
    },
    isBemSichtbar: {
      get() {
        return this.isSichtbar;
      },
      set(value) {
        this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.PUT_BEMERKUNG_KUNDE_SICHTBAR, value );
      }
    }
  },
  methods: {
    edit() {
      this.BemerkungEdit = this.Bemerkung;
      this.$refs.BemerkungEditModal.open();
    },
    save() {
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.PUT_BEMERKUNG_KUNDE, this.BemerkungEdit);
    },
  },
  mounted() {
    if (this.Bemerkung == null) {
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_BEMERKUNG_KUNDE);
    }
  },
  components: {
    PhPencilLine,
    BaseModal,
    InputTextArea,
    InputToggleSwitch,
    GhostLoading,
  },
}
</script>

<style src='../steckbrief.scss' lang='scss' scoped></style>

<style scoped>
.bemerkung-text {
  word-break: break-word;
}
</style>
