<template>
  <div>
    <div v-if="accounts && accounts.length">
      <div class="box__container">
        <LineChart 
          height="450px" 
          axisLabelFormatterShowOption="decimal-short"
          :chartData="chartData" 
          :showDataZoom="false" 
          :isTooltip="false" 
          :gridLeft="80" />
        
        <KontoverlaufFilter v-model="selectedFilter" showTransactions/>
      </div>
      
      <div class="box__container">
        <TransactionList :transactions="filteredTransactions"/>
      </div>
    </div>

    <div v-else class="box__container">
      <NoData/>
    </div>
  </div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex'
import CORE_TYPES from '../../store/core/types'
import MULTIBANKING_TYPES from '../../store/multiBanking/types'
import TransactionList from './TransactionList.vue'
import dayjs from 'dayjs'
import LineChart from '@/components/charts/echarts/LineChart.vue'
import KontoverlaufFilter from '@/components/multiBanking/KontoverlaufFilter.vue'
import NoData from '@/components/core/NoData.vue'

export default {
  components: {
    LineChart,
    KontoverlaufFilter,
    TransactionList,
    NoData
  },

  props: {
    accountId: {
      type: String,
      default: null,
    },
    hideDepotPositions: {
      type: Boolean,
      default: false,
    },
    hideTransactions: {
      type: Boolean,
      default: false,
    },
    category: {
      type: String,
    },
    currentBalance: {
      type: Number,
      default: 0
    }
  },

  data: function() {
    return {
      selectedFilter: {},
    }
  },

  computed: {
    ...mapGetters({
      isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
      brokerData: CORE_TYPES.GETTERS.GET_BROKER_INFORMATION,
      transactions: MULTIBANKING_TYPES.GETTERS.TRANSACTIONS,
      depotList: MULTIBANKING_TYPES.GETTERS.DEPOTS,
      accounts: MULTIBANKING_TYPES.GETTERS.ACCOUNTS,
      accountsSum: MULTIBANKING_TYPES.GETTERS.ACCOUNTS_SUM,
      dailyAccountsHistory: MULTIBANKING_TYPES.GETTERS.DAILY_ACCOUNT_HISTORY,
      categoriesByKey: MULTIBANKING_TYPES.GETTERS.CATEGORIES_BY_KEY,
      filters: MULTIBANKING_TYPES.GETTERS.FILTERS
    }),
    selectedAccountIds () {
      return this.accountId ? [this.accountId] : null
    },
    selectedAccounts () {
      if (this.accounts && this.selectedAccountIds != null) {
        const result = this.accounts.filter(acc => this.selectedAccountIds.indexOf(acc.externalId) >= 0)
        if (result.length > 0)
          return result
      }
      return null
    },
    filteredTransactions() {
      let transactions = this.transactions
      if (!transactions)
        return null
      if (this.selectedAccounts) {
        transactions = transactions.filter(transaction => {
          return this.selectedAccountIds.includes(transaction.account.externalId)
        })
      }
      if (this.category) {
        let children = this.categoriesByKey[this.category] && this.categoriesByKey[this.category].children
        children = children && children.map(cat => cat.category) || null
        
        transactions = transactions.filter(transaction => {
          return transaction.category.category === this.category || (children && children.includes(transaction.category.category))
        })
      }
      if (this.selectedFilter.text) {
        transactions = transactions.filter(transaction => {
          return transaction.purpose.toLowerCase().indexOf(this.selectedFilter.text) >= 0 ||
              transaction.name && transaction.name.toLowerCase().indexOf(this.selectedFilter.text) >= 0 ||
              transaction.account.displayName.toLowerCase().indexOf(this.selectedFilter.text) >= 0 ||
              transaction.category.displayName.toLowerCase().indexOf(this.selectedFilter.text) >= 0
        })
      }
      if (this.selectedFilter.minAmount != null) {
        transactions = transactions.filter(transaction => transaction.amount >= this.selectedFilter.minAmount)
      }
      if (this.selectedFilter.maxAmount != null) {
        transactions = transactions.filter(transaction => transaction.amount <= this.selectedFilter.maxAmount)
      }
      if (this.selectedFilter.category) {
        let children = this.categoriesByKey[this.selectedFilter.category] && this.categoriesByKey[this.selectedFilter.category].children
        children = children && children.map(cat => cat.category) || null
        
        transactions = transactions.filter(transaction => {
          return transaction.category.category === this.selectedFilter.category || (children && children.includes(transaction.category.category))
        })

      }
      if (this.filters.dateFrom) {
        transactions = transactions.filter(transaction => new Date(transaction.valueDate) >= this.filters.dateFrom)
      }
      if (this.filters.dateTo) {
        transactions = transactions.filter(transaction => new Date(transaction.valueDate) <= this.filters.dateTo)
      }
      return transactions
    },
    filteredDepots() {
      let depotList = this.depotList
      if (!depotList)
        return null
      if (this.selectedAccounts) {
        depotList = depotList.filter(depot => {
          return this.selectedAccountIds.includes(depot.account.externalId)
        })
      }
      if (this.selectedFilter.text) {
        depotList = depotList.filter(depot => {
          return depot.name.toLowerCase().indexOf(this.selectedFilter.text) >= 0 ||
              depot.account.displayName.toLowerCase().indexOf(this.selectedFilter.text) >= 0
        })
      }
      if (this.selectedFilter.minAmount != null) {
        depotList = depotList.filter(depot => depot.amount >= this.selectedFilter.minAmount)
      }
      if (this.selectedFilter.maxAmount != null) {
        depotList = depotList.filter(depot => depot.amount <= this.selectedFilter.maxAmount)
      }
      if (this.filters.dateFrom) {
        depotList = depotList.filter(depot => new Date(depot.rateDate) >= this.filters.dateFrom)
      }
      if (this.filters.dateTo) {
        depotList = depotList.filter(depot => new Date(depot.rateDate) <= this.filters.dateTo)
      }
      return depotList
    },
    chartData () {
      let transactions = this.hideTransactions ? [] : this.filteredTransactions
      let depotList = this.hideDepotPositions ? [] : this.filteredDepots
      if (!transactions || !depotList || !this.dailyAccountsHistory)
        return null

      const byDate = new Map()
      transactions.forEach(transaction => {
        if (!byDate.has(transaction.valueDate))
          byDate.set(transaction.valueDate, [])
        byDate.get(transaction.valueDate).push({
          amount: transaction.amount
        })
      })
      depotList.forEach(depot => {
        if (!byDate.has(depot.rateDate))
          byDate.set(depot.rateDate, [])
        byDate.get(depot.rateDate).push({
          amount: depot.amount
        })
      })

      const uniqueDates = Array.from(byDate.keys()).sort((a, b) => b - a)
      let balanceTo = this.calculateBalanceOnDate(this.filters.dateTo)
      let balanceFrom = balanceTo
      const graphPoints = []

      uniqueDates.forEach((dateMS) => {
        const date = new Date(dateMS)
        if (this.filters.dateFrom && date < this.filters.dateFrom)
          return
        const total = byDate.get(dateMS).reduce((sum, item) => sum+item.amount, 0)
        balanceFrom -= total
        if (this.filters.dateTo && date > this.filters.dateTo) {
          balanceTo -= total
        } else {
          graphPoints.unshift(this.makeGraphPoint(date, balanceFrom))
        }
      })

      if (this.filters.dateFrom && (graphPoints.length === 0 || this.getDateOfFirstItem(graphPoints) > this.filters.dateFrom.getTime())) {
        graphPoints.unshift(this.makeGraphPoint(this.filters.dateFrom, balanceFrom) )
      }
      if (this.filters.dateTo && (graphPoints.length === 0 || this.getDateOfLastItem(graphPoints) < this.filters.dateTo.getTime())) {
        const pointDate = this.filters.dateTo < new Date() ? this.filters.dateTo : new Date()
        graphPoints.push(this.makeGraphPoint(pointDate, balanceTo))
      }

      return graphPoints
    },
  },

  methods: {
    getDateOfFirstItem(graphPoints) {
      return graphPoints[0][0]
    },
    getDateOfLastItem(graphPoints) {
      return graphPoints[graphPoints.length - 1][0]
    },
    makeGraphPoint(date, value) {
      return [dayjs(date).toDate().getTime(), Math.round(value * 100) / 100]
    },
    loadPeriod (period) {
      if (period && period.dateFrom) {
        this.$store.dispatch(MULTIBANKING_TYPES.ACTIONS.LOAD_TRANSACTIONS, {
          fromDate: period.dateFrom,
          toDate: period.dateTo,
        });
        this.$store.dispatch(MULTIBANKING_TYPES.ACTIONS.LOAD_DEPOTS, {
          fromDate: period.dateFrom,
          toDate: period.dateTo,
        });
      }
    },
    calculateBalanceOnDate(date) {
      let result = this.currentBalance
      if (date) {
        let transactions = this.hideTransactions ? [] : this.transactions
        let depotList = this.hideDepotPositions ? [] : this.transactions
        if (this.filters.dateTo) {
          transactions = transactions.filter(transaction => new Date(transaction.valueDate) > this.filters.dateTo)
          depotList = depotList.filter(depot => new Date(depot.rateDate) > this.filters.dateTo)
        }
        if (this.selectedAccounts) {
          transactions = transactions.filter(transaction => {
            return this.selectedAccountIds.includes(transaction.account.externalId)
          })
          depotList = depotList.filter(depot => {
            return this.selectedAccountIds.includes(depot.account.externalId)
          })
        }
        result -= transactions.reduce((sum, transaction) => sum + (transaction.amount || 0), 0)
        result -= depotList.reduce((sum, depot) => sum + (depot.amount || 0), 0)
      }
      return result
    },
  },
}
</script>

<style scoped>

</style>