import { render, staticRenderFns } from "./FormWertpapierAuswahlEbaseDepotIntern.vue?vue&type=template&id=22a8f937&scoped=true&"
import script from "./FormWertpapierAuswahlEbaseDepotIntern.vue?vue&type=script&lang=js&"
export * from "./FormWertpapierAuswahlEbaseDepotIntern.vue?vue&type=script&lang=js&"
import style0 from "./FormWertpapierAuswahlEbaseDepotIntern.vue?vue&type=style&index=0&id=22a8f937&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22a8f937",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("P:\\workspaces\\fk0\\fc\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('22a8f937')) {
      api.createRecord('22a8f937', component.options)
    } else {
      api.reload('22a8f937', component.options)
    }
    module.hot.accept("./FormWertpapierAuswahlEbaseDepotIntern.vue?vue&type=template&id=22a8f937&scoped=true&", function () {
      api.rerender('22a8f937', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/core/forms/antraege/wertpapierAuswahl/FormWertpapierAuswahlEbaseDepotIntern.vue"
export default component.exports