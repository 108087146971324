var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CoreCard",
    {
      ref: "coreCard",
      attrs: { showToolbar: _vm.showToolbar },
      scopedSlots: _vm._u(
        [
          _vm.showTitle
            ? {
                key: "header",
                fn: function() {
                  return [_vm._v(" " + _vm._s(_vm.chartName) + " ")]
                },
                proxy: true
              }
            : null,
          {
            key: "alternative",
            fn: function() {
              return undefined
            },
            proxy: true
          }
        ],
        null,
        true
      )
    },
    [
      _c("div", { staticClass: "content" }, [
        _vm.isContainerRendered
          ? _c("div", [
              _c("div", { staticClass: "text-lg" }, [
                _vm._v(_vm._s(_vm.data["" + _vm.chartName]))
              ])
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }