export const MUTATION_PREFIX = 'CALENDAR_SETTINGS:';
export const ACTIONS_PREFIX = 'CALENDAR_SETTINGS_ACTIONS_';
export const GETTERS_PREFIX = 'CALENDAR_SETTINGS_GETTERS_';

export default {
  MUTATIONS: {
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    SET_COMBO_DATA: MUTATION_PREFIX + 'SET_COMBO_DATA',
    SET_INTERN_PERSONEN: MUTATION_PREFIX + 'SET_INTERN_PERSONEN',
    SET_SETTINGS_DATA: MUTATION_PREFIX + 'SET_SETTINGS_DATA',
    ADD_SETTINGS_DATA_EDITED: MUTATION_PREFIX + 'ADD_SETTINGS_DATA_EDITED',
    RESET_SETTINGS_DATA_EDITED: MUTATION_PREFIX + 'RESET_SETTINGS_DATA_EDITED',
    SET_FEIERTAGE_GRUPPEN_SPEZIAL: MUTATION_PREFIX + 'SET_FEIERTAGE_GRUPPEN_SPEZIAL',
    SET_FEIERTAGE_SPEZIAL: MUTATION_PREFIX + 'SET_FEIERTAGE_SPEZIAL',
  },

  ACTIONS: {
    FIND_COMBO_DATA: ACTIONS_PREFIX + 'FIND_COMBO_DATA',
    FIND_INTERN_PERSONEN: ACTIONS_PREFIX + 'FIND_INTERN_PERSONEN',
    FIND_SETTINGS_DATA: ACTIONS_PREFIX + 'FIND_SETTINGS_DATA',
    SAVE_SETTINGS_DATA: ACTIONS_PREFIX + 'SAVE_SETTINGS_DATA',
    FIND_FEIERTAGE_GRUPPEN_SPEZIAL: ACTIONS_PREFIX + 'FIND_FEIERTAGE_GRUPPEN_SPEZIAL',
    SAVE_FEIERTAGE_GRUPPEN_SPEZIAL: ACTIONS_PREFIX + 'SAVE_FEIERTAGE_GRUPPEN_SPEZIAL',
    DELETE_FEIERTAGE_GRUPPEN_SPEZIAL: ACTIONS_PREFIX + 'DELETE_FEIERTAGE_GRUPPEN_SPEZIAL',
    FIND_FEIERTAGE_SPEZIAL: ACTIONS_PREFIX + 'FIND_FEIERTAGE_SPEZIAL',
    SAVE_FEIERTAGE_SPEZIAL: ACTIONS_PREFIX + 'SAVE_FEIERTAGE_SPEZIAL',
    DELETE_FEIERTAGE_SPEZIAL: ACTIONS_PREFIX + 'DELETE_FEIERTAGE_SPEZIAL',
  },

  GETTERS: {
    COMBO_DATA: GETTERS_PREFIX + 'COMBO_DATA',
    INTERN_PERSONEN: GETTERS_PREFIX + 'INTERN_PERSONEN',
    SETTINGS_DATA: GETTERS_PREFIX + 'SETTINGS_DATA',
    SETTINGS_DATA_EDITED: GETTERS_PREFIX + 'SETTINGS_DATA_EDITED',
    FEIERTAGE_GRUPPEN_SPEZIAL: GETTERS_PREFIX + 'FEIERTAGE_GRUPPEN_SPEZIAL',
    FEIERTAGE_SPEZIAL: GETTERS_PREFIX + 'FEIERTAGE_SPEZIAL',
  },
}
