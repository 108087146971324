var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "depotpositions--navigation__container" },
    [
      _c(
        "PageHeaderTitleNavigation",
        _vm._g(
          {
            attrs: {
              defaultMenu: _vm.$appNavigation.currentTabMenu,
              title: _vm.title,
              subtitle: _vm.subtitle,
              noPrimaryAction: !_vm.headerActions.length,
              actions: _vm.actions
            },
            on: {
              "action-ADD-ACTIVITY": function($event) {
                return _vm.handleAddActivity()
              },
              "action-ADD-TICKET": function($event) {
                return _vm.navigateToNewTicket()
              },
              "action-OPEN-PDF": function($event) {
                return _vm.navigateToSendVermogensubersichtPdf()
              },
              "action-OPEN-SEND-PDF": function($event) {
                return _vm.navigateToSendVermogensubersichtPdfByEmail()
              },
              "action-ANTEILE-NULL": function($event) {
                return _vm.$emit("ANTEILE-NULL")
              },
              "action-OPEN-ERW_COURTAGE": function($event) {
                return _vm.openErwarteteCourtage()
              }
            },
            scopedSlots: _vm._u(
              [
                _vm.$slots.title
                  ? {
                      key: "title",
                      fn: function() {
                        return [_vm._t("title")]
                      },
                      proxy: true
                    }
                  : null,
                _vm.$slots.subtitle
                  ? {
                      key: "subtitle",
                      fn: function() {
                        return [_vm._t("subtitle")]
                      },
                      proxy: true
                    }
                  : null
              ],
              null,
              true
            )
          },
          _vm.$listeners
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }