<template>
  <div class="box__container-wrap">
    <div class="box__container-rows">
      <template v-if="rows.length">
        <OverviewTable
          v-if="!$isSmallScreen"
          :records="rows"
          @title="openCustomerNewTab"
          @subject="clickBetreff"
        />
        <Table 
          v-else
          :headers="headers"
          :rows="rows"
          rowId="commId"
          :mobileConfig="{title: 'title', headers: ['subject', 'date']}"
          @clickRow="clickBetreff"
        >
          <template #title="row"> 
            <div class="ellipsis d-flex" style="align-items: center">
              <ph-paperclip v-if="row.hasAttachment" class="mr-1" style="flex-shrink: 0" :size="16"/>
              <div class="ellipsis">{{ row.title }}</div>
            </div>

          </template>
        </Table>
      </template>
      <GhostLoading v-else-if="loading" type="table" :config="{ table: { rows: 5 } }"/>
      <NoData :noIcon="$isSmallScreen" v-else />
    </div>

    <div class="bottom-row text-right mt-3">
      <router-link :to="`/communication/postfach/${routeStep}`">
        weitere...
      </router-link>
    </div>
  </div>
</template>

<script>
import postfachMixin from '@/views/communication/postfach/widgets/postfach-mixin.js';
import Table from "@/components/table2/Table.vue";
import { TextColumn, DateColumn, SlotColumn } from "@/components/table2/table_util.js";
import { PhPaperclip } from 'phosphor-vue'

const VALUE_TYP = 'postfach';

export default {
  mixins: [postfachMixin],
  components: {
    Table,
    PhPaperclip,
  },
  data() {
    return {
      routeStep: 'bemerkungen',
      VALUE_TYP,
    }
  },
  computed: {
    headers() {            
      return {
        lockedLeft: [
          SlotColumn("title", "Name"),
          DateColumn("date", "Datum"),
          TextColumn("subject", "Betreff"),
        ],
        center: [],
        lockedRight: []
      }
    },
    rows() {
      return this.records.map(record => {
        return {
          ...record,
          title: record.subject || "[kein Thema]",
          subject: this.getCleanText(record.text || '')?.trim() || "[Kein Text]",
          isSubjectLink: !!record.commId,
        }
      })
    },
  },
  methods: {
    getCleanText(text) {
      var div = document.createElement("div");
      div.innerHTML = text;
      return div.textContent || div.innerText || "";
    },
    clickBetreff(row) {
      if (row.commId) {
        this.$router.push({path: `/communication/postfach/bemerkungen/bemerkung/${row.commId}`, query: {typ: VALUE_TYP}});
      }
    },
  },
}
</script>