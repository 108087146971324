import PENDING_ACTIONS_TYPES from './types';
import { getInitialState } from './index'

export default {
  [PENDING_ACTIONS_TYPES.MUTATIONS.SIMPLE_ACTION_KUNDENZUGANG_SUCCESS](state, payload) {
    state.simpleActionResult = payload;
  }, 
  [PENDING_ACTIONS_TYPES.MUTATIONS.GET_ACTION_ID_SUCCESS](state, payload) {
    state.actionId = payload;
  }, 
  [PENDING_ACTIONS_TYPES.MUTATIONS.GET_SIMPLE_ACTION_LISTE_SUCCESS](state, payload) {
    state.simpleActionListe = payload;
  },   
  [PENDING_ACTIONS_TYPES.MUTATIONS.EXECUTE_SIMPLE_ACTION_SUCCESS](state, payload) {
    state.simpleActionResult = payload;
  },   
  [PENDING_ACTIONS_TYPES.MUTATIONS.GET_SIMPLE_ACTION_FONDSSHOP_DATA_SUCCESS](state, payload) {
    state.simpleActionFondsshopData = payload;
  }, 
}