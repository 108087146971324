<template>
    <div>
        <div v-for="(zeile, count) in zeilen" :key="count">
            <AnlagezieleZeile 
                :count="count + 1" 
                :suffix="data && data.suffix"
                :data="zeile || {}" 
                :hideSpezielleZiele="hideSpezielleZiele"
                :comboboxValues="comboboxValues" 
                :maxRows="maxRows"
                :disabled="disabled"
                :warnings="getWarningsProZeile(count + 1)" 
                @change="doChange($event)" 
                @updateSum="updateSum($event)" 
                @addZeile="addZeile($event)"
                @removeZeile="removeZeile($event)"
                ref="zeileComp">
            </AnlagezieleZeile>
        </div>
        <hr>
        <div class="row justify-content-between sums">
            <div class="col-12 col-lg-6 px-1">
                <span class="mr-2">Summe einmalig:</span><span>{{summeEinmalig}}</span>
            </div>
            <div class="col-12 col-lg-6 px-1">
                <span class="mr-2">Summe ratierlich (monatlich):</span><span>{{summeRatierlich}}</span>
            </div>
        </div>

        <div v-if="hasExtraFields">
            <hr>
            <InputToggleSwitch v-model="checkCredit" label="Geschäft auf Kreditbasis" inLineLabel suppressValidationMessage/>
            <InputField v-if="checkCredit"
                label="Höhe"
                v-model="creditBetrag"
                type="currency"
                :disabled="disabled"
                >
            </InputField> 
             <InputTextArea
                label="Freitextfeld zur persönlichen Situation, falls vom Kunden gewünscht und obige Fallgruppen nicht ausreichend sind:"
                v-model="bemerkung">
            </InputTextArea>
        </div>
  </div>
</template>

<script>
import AnlagezieleZeile from '@/components/anlageziele/AnlagezieleZeile.vue';
import InputField from '@/components/core/forms/InputField.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import { mapGetters } from 'vuex';
import ANLAGEZIELE_TYPES from '@/store/anlageziele/types';
import validator from "@/mixins/validator";
import antragMixin from '@/mixins/antrag/antrag-navigation-mixin.js';
import FinancialCalculator from '../retirementScenario/financialCalculator';

export default {
    mixins: [antragMixin, validator],
    validators: {},
    props: {
        data: {
            type: Object,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        hasExtraFields: {
            type: Boolean,
            default: true,
        },
        warnings: {
            type: Array,
            default: null,
        },
    },
    components: {
        AnlagezieleZeile,
        InputField,
        InputTextArea,
        InputToggleSwitch,
    },
    mounted() {
        this.$store.dispatch(ANLAGEZIELE_TYPES.ACTIONS.GET_ANLAGEZIELE, {});
        this.createForm();
    },
    data() {
        return {
            checkCredit: false,
            creditBetrag: '',
            bemerkung: '',
            sumsEinmal: {
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
            },
            sumsRatierlich: {
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
            },
        };
    },
    computed: {
        ...mapGetters({
            comboboxValues: ANLAGEZIELE_TYPES.GETTERS.COMBOBOX_VALUES,
        }),
        summeEinmalig() {
            const sum =  this.sumsEinmal && (this.sumsEinmal['1'] + this.sumsEinmal['2'] + this.sumsEinmal['3']
            + this.sumsEinmal['4'] + this.sumsEinmal['5']) || 0;
            return FinancialCalculator.toGermanFormat(sum);
        },
        summeRatierlich() {
            const sum = this.sumsRatierlich && (this.sumsRatierlich['1'] + this.sumsRatierlich['2'] + this.sumsRatierlich['3']
            + this.sumsRatierlich['4'] + this.sumsRatierlich['5']) || 0;
            return FinancialCalculator.toGermanFormat(sum);
        },
        maxRows() {
            return this.data?.schiene === 'WEALTH_KONZEPT' ? 3 : 5; 
        },
        zeilen() {
            return this.data?.zeilen && (Object.keys(this.data?.zeilen) || []).sort((a,b) => a.localeCompare(b)).map(key => this.data?.zeilen[key]) || [];
        },
        hideSpezielleZiele() {
            return this.data?.schiene === 'WEALTH_KONZEPT' || this.data?.hatNachhaltigkeit;
        }
    },
    methods: {
        addZeile(event) {
            if (event && Object.keys(event).length && this.data && this.zeilen?.length < this.maxRows) {
                this.$emit('addZeile', { suffix: this.data.suffix + (this.zeilen?.length + 1), zeile: event});
            }
        }, 
        removeZeile(event) {
            if (event && this.zeilen?.length > 1) {
                this.$emit('removeZeile', event);
            }
        },
        createForm() {
            if (this.data) {
                this.checkCredit = this.data.checkCredit;
                this.creditBetrag = this.data.creditBetrag;
                this.bemerkung = this.data.bemerkung;
            }
        },
        getWarningsProZeile(count) {
            if (this.warnings && this.warnings.length) {
                const warnings = this.warnings.filter(warn => warn.id && warn.id.includes(count + ''));
                return warnings;
            }
            return [];
        },
        getValues() {
            let values = {
                checkCredit: this.checkCredit,
                creditBetrag: this.creditBetrag,
                bemerkung: this.bemerkung
            };
            if (this.$refs.zeileComp && this.$refs.zeileComp.length) {
                this.$refs.zeileComp.forEach(zeile => {
                    values = Object.assign(values, zeile.getValues())
                });
            }
            return values;
        },
        updateSum(event) {
            if (event && event.type) {
                switch (event.type) {
                    case 'einmalig':
                        this.sumsEinmal[event.count] = event.value || 0;
                        break;
                    case 'ratierlich':
                        this.sumsRatierlich[event.count] = event.value || 0;
                        break;
                    default:
                        break;
                }
            }
        },
        doChange(event) {
            this.updateSum(event);
            this.$emit('change', event);
        },
    },
}
</script>

<style>
    .sums {
        font-weight: bold;
    }
</style>