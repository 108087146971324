var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.disabled &&
      _vm.selectedRows &&
      _vm.selectedRows.length &&
      _vm.rows.length
        ? _c(
            "BaseButton",
            {
              attrs: {
                disabled: _vm.disabled || _vm.antragLoading,
                primary: ""
              },
              on: {
                click: function($event) {
                  return _vm.openZuordnenModal()
                }
              }
            },
            [_vm._v("Markierte Zuordnen")]
          )
        : _vm._e(),
      _c("div", { staticClass: "row mb-3" }, [
        _c("div", { staticClass: "col text-bold" }, [
          _c(
            "span",
            {
              class: {
                "color-danger": _vm.zeilenlabels.zeilendescription.includes(
                  "Positionen ohne Zuordnung"
                )
              }
            },
            [_vm._v(" " + _vm._s(_vm.zeilenlabels.zeilendescription) + " ")]
          ),
          _c(
            "span",
            {
              style:
                _vm.zeilenlabels.zeilendescriptionCheckFehler &&
                _vm.zeilenlabels.zeilendescriptionCheckFehler.isEmpty
                  ? { color: "var(--color-danger)" }
                  : {}
            },
            [_vm._v(" " + _vm._s(_vm.zeilenlabels.zeilendescriptionPre) + " ")]
          ),
          _c(
            "span",
            {
              style:
                _vm.zeilenlabels.zeilendescriptionCheckFehler &&
                _vm.zeilenlabels.zeilendescriptionCheckFehler.einmalFehler
                  ? { color: "var(--color-danger)" }
                  : {}
            },
            [
              _vm._v(
                " " + _vm._s(_vm.zeilenlabels.zeilendescriptionEinmal) + " "
              )
            ]
          ),
          _c("span", [
            _vm._v(
              " " + _vm._s(_vm.zeilenlabels.zeilendescriptionIsAnother) + " "
            )
          ]),
          _c(
            "span",
            {
              style:
                _vm.zeilenlabels.zeilendescriptionCheckFehler &&
                _vm.zeilenlabels.zeilendescriptionCheckFehler.ratierlichFehler
                  ? { color: "var(--color-danger)" }
                  : {}
            },
            [
              _vm._v(
                " " + _vm._s(_vm.zeilenlabels.zeilendescriptionRatierlich) + " "
              )
            ]
          )
        ]),
        _vm.zeilenlabels.zeilenzeithorizont
          ? _c("div", { staticClass: "col-12" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.zeilenlabels.zeilenzeithorizont) +
                  " | " +
                  _vm._s(_vm.zeilenlabels.zeilenanlageziel) +
                  " | " +
                  _vm._s(_vm.zeilenlabels.zeilenanlagezielspeziell) +
                  " | " +
                  _vm._s(_vm.zeilenlabels.zeilenverlusttf) +
                  " "
              )
            ])
          : _vm._e()
      ]),
      _vm.rows.length
        ? _c("Table", {
            attrs: { rowId: "index", rows: _vm.rows, headers: _vm.headers },
            on: {
              action: function($event) {
                return _vm.handleTableAction($event)
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "anlageziel",
                  fn: function(row) {
                    return [
                      row.anlagezielList && row.anlagezielList.length
                        ? _c(
                            "div",
                            _vm._l(row.anlagezielList, function(yam) {
                              return _c(
                                "div",
                                {
                                  key: yam,
                                  class: {
                                    "pill__status color-danger":
                                      row.zmStatus.azFehler,
                                    "pill__status color-success":
                                      row.zmStatus.azSuccess
                                  }
                                },
                                [_vm._v(" " + _vm._s(yam || "") + " ")]
                              )
                            }),
                            0
                          )
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: "anlagezielspeziell",
                  fn: function(row) {
                    return [
                      row.anlagezielspez && row.anlagezielspez.length
                        ? _c(
                            "div",
                            _vm._l(row.anlagezielspez, function(yam) {
                              return _c(
                                "div",
                                {
                                  key: yam,
                                  class: {
                                    "pill__status color-danger":
                                      row.zmStatus.azsFehler,
                                    "pill__status color-success":
                                      row.zmStatus.azsSuccess
                                  }
                                },
                                [_vm._v(" " + _vm._s(yam || "") + " ")]
                              )
                            }),
                            0
                          )
                        : _vm._e()
                    ]
                  }
                }
              ],
              null,
              false,
              1559744391
            ),
            model: {
              value: _vm.selectedRows,
              callback: function($$v) {
                _vm.selectedRows = $$v
              },
              expression: "selectedRows"
            }
          })
        : _c("NoData", { attrs: { content: "Keine Positionen", noIcon: "" } }),
      _c(
        "BaseModal",
        {
          ref: "zuordnenModal",
          attrs: {
            modalTitle: "Zuordnen",
            showDefaultButtons: false,
            size: "lg"
          }
        },
        [
          _c("p", [
            _vm._v("Bitte weisen Sie die markierten Positionen einer Zeile zu.")
          ]),
          _c("InputRadioBoxGroup", {
            attrs: { values: _vm.zeileOptions },
            model: {
              value: _vm.form.selectedZeile,
              callback: function($$v) {
                _vm.$set(_vm.form, "selectedZeile", $$v)
              },
              expression: "form.selectedZeile"
            }
          }),
          _c(
            "BaseButton",
            {
              attrs: { disabled: !_vm.form.selectedZeile },
              on: {
                click: function($event) {
                  return _vm.movetoSelectedLine()
                }
              }
            },
            [_vm._v("Zuordnen")]
          ),
          _c(
            "BaseButton",
            {
              attrs: { isSecondary: "" },
              on: {
                click: function($event) {
                  return _vm.$refs.zuordnenModal.close()
                }
              }
            },
            [_vm._v("Abbrechnen")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }