var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.$isSmallScreen,
              expression: "!$isSmallScreen"
            }
          ]
        },
        [_vm._t("default")],
        2
      ),
      _c(
        "BaseModal",
        {
          ref: "modal",
          attrs: {
            modalTitle: _vm.modalTitle,
            closeButtonLabel: _vm.closeButtonLabel,
            showDefaultButtons: false,
            actions: _vm.modalActions
          },
          on: {
            action: _vm.handleModalAction,
            onCloseButton: _vm.goToLastAction
          }
        },
        [_vm._t("default")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }