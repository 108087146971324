<template>
  <div>
    <PageHeaderTitleNavigation :title="$appNavigation.currentMenu.label" />

    <BaseFilter
      v-if="defaultOptions"
      title="Filter"
      filterId="FonsAntragCheck"
      :configFilter="filterConfig"
      :metadata="metadata"
      :defaultOptions="defaultOptions"
      showSaveButton
      :showAlwaysPrimaryselection="false"
      @onFilter="onFilter"
      ref="refFilter"
    >
    </BaseFilter>
    <div class="box__container" v-if="requestedData">
      <AntragCheckTable
        title="Fondsantragcheck"
        :loading="loading"
        :selectedPage="selectedPage"
        :maxRowsPerPage="maxRowsPerPage"
        :searchSort="searchSort"
        @page="selectedPage = $event"
        @sort="onSearchSort"
        @onScroll="onScroll"
        @localSort="searchSort = $event"
        @requestPage="loadPage"
        @rowsPerPage="onPageSizeChange"
      />
    </div>
  </div>
</template>

<script>
import A_C_TYPES from "@/store/fondsAntragCheck/types";

import { mapGetters } from "vuex";
import AntragCheckTable from "@/views/fondsantragcheck/AntragCheckTable.vue";
import BaseFilter from "@/components/core/BaseFilter.vue";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { DatePickerUtils } from "@/components/core/forms/DatePicker/date-picker-utils";

export default {
  components: {
    BaseFilter,
    OptionMenu,
    PageHeaderTitleNavigation,
    AntragCheckTable,
  },
  computed: {
    ...mapGetters({
      setup: A_C_TYPES.GETTERS.SETUP,
    }),

    metadata() {
      let result = [
        {
          type: "group",
          key: "allgemein",
          label: "",
          menuItems: [
            {
              type: "dateRange",
              label: "Zeitraum",
              key: "zeitraum",
              emptyDenied: false,
            },

            {
              type: "default",
              label: "Nur zu bearbeitende Anträge",
              key: "workedOnly",
              emptyDenied: false,
            },

            {
              type: "default",
              label: "Anträge seit Beginn",
              key: "noDate",
              emptyDenied: false,
            },
          ],
        },
      ];
      if (this.setup && this.setup.admin) {
        result[0].menuItems.push({
          type: "default",
          label: "zeige versendete",
          key: "inklVersendeteAntraege",
          emptyDenied: false,
        },
        
        );
        result[0].menuItems.push({
          type: "default",
          label: "zeige veraltete",
          key: "inklusiveHistorische",
          emptyDenied: false,
        },
        
        );
        result[0].menuItems.push({
          type: "text",
          label: "Kundennr",
          key: "kundennr",
          emptyDenied: false,
        },
        
        );
        result[0].menuItems.push({
          type: "text",
          label: "Vermittlernummer",
          key: "maklernr",
          emptyDenied: false,
        },
        
        );

      }
      return result;
    },
    maxRowsPerPage() {
      if (this.tablePageSize > 0) {
        return this.tablePageSize;
      }
      return 25;
    },
  },
  data() {
    return {
      apiAddress: process.env.VUE_APP_API,
      defaultOptions: null,
      filterConfig: {
        noResetOnDefaultSearchInputExit: true,
        checkDefaultSearchInput: false,
        cbDefSearchInput: null,
        // hideFirstColumn: true,
        filterZurucksetzen: null, // () => {},
      },
      requestedData: false,
      loading: false,
      filterParams: {},
      selectedPage: 0,
      searchSort: {},
      onScrollEnd: () => {},
      tablePageSize: 0,
    };
  },
  watch: {
    setup(next, oldValue) {
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 7);
      //this.doGesell();

      this.defaultOptions = {
        zeitraum: {
          value: [
            {
              key: "min",
              value: startDate,
            },
            {
              key: "max",
              value: new Date(),
            },
          ],
        },

        workedOnly: true,
      };
      if(next && next.admin){
         this.defaultOptions.inklVersendeteAntraege=true;
      }
    },
  },
  mounted() {
    this.setupRun();
  },
  methods: {
    async setupRun() {
      await this.$store.dispatch(A_C_TYPES.ACTIONS.SETUP);
    },
    loadPage(event){
      this.selectedPage = event;
      this.doSearch()
    },
    onScroll(onScrollEnd) {
      this.onScrollEnd = onScrollEnd
      this.loadPage(this.selectedPage + 1)
    },
    onSearchSort(searchSort) {
      this.searchSort = searchSort || {}
      this.$store.commit(A_C_TYPES.MUTATIONS.RESET_FILTER)
      this.loadPage(0);
    },
    async onFilter(filterConfig) {
      let params = {};
      let noDate = false;
      params.startDate = null;
      params.endDate = null;
      params.workedOnly = false;
      params.inklVersendeteAntraege=true;

      if(this.setup  && this.setup.admin){
          params.inklVersendeteAntraege=false;
          params.kundennr=null;
          params.maklernr=null;
          params.inklusiveHistorische=false;
      }
     
      filterConfig.forEach((fc) => {
        if (fc.key == "noDate") {
          noDate = true;
        }
      });

      filterConfig.forEach((fc) => {
        let value = fc.value;
        if (fc.key == "zeitraum" && !noDate) {
          params.startDate = DatePickerUtils.toDateStr(
            value.find((v) => v.key == "min").value
          );
          params.endDate = DatePickerUtils.toDateStr(
            value.find((v) => v.key == "max").value
          );
        } else if (fc.key != noDate) {
          params[fc.key] = value;
        }
      });

      this.filterParams = params
      this.$store.commit(A_C_TYPES.MUTATIONS.RESET_FILTER)
      this.loadPage(0);
    },
    doSearch(urlParams = this.filterParams, page = this.selectedPage) {
      this.loading = true;
      const params = { limit: this.maxRowsPerPage, sortKey: this.searchSort.key, sortAscending: this.searchSort.sortDirection === 'asc', page }

      this.$store.dispatch(A_C_TYPES.ACTIONS.FILTER, {...params, ...urlParams})
      .finally(() => {
        this.requestedData = true;
        this.loading = false
        this.onScrollEnd()
      })
    },
    onPageSizeChange(pageSize) {
      this.tablePageSize = pageSize;
      this.loadPage(0);
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({ to, from, });
    next();
  },
};
</script>