var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { display: "inline-block" } }, [
    !_vm.rendered
      ? _c(
          "div",
          {
            staticClass: "placeholder",
            style: { width: _vm.resolutions[_vm.resolutionId].width },
            on: { click: _vm.startRendering }
          },
          [_vm._v(" click me! ")]
        )
      : _c("iframe", {
          ref: "frame",
          attrs: {
            src: _vm.baseUrl + _vm.path,
            width: _vm.resolutions[_vm.resolutionId].width,
            height: _vm.frameHeight
          }
        }),
    _c("br"),
    _c("label", [
      _vm._v(" Width "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.resolutionId,
              expression: "resolutionId"
            }
          ],
          attrs: { disabled: !_vm.rendered },
          on: {
            change: function($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function(o) {
                  return o.selected
                })
                .map(function(o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.resolutionId = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            }
          }
        },
        _vm._l(_vm.resolutions, function(resolution, index) {
          return _c(
            "option",
            { key: resolution.title, domProps: { value: index } },
            [
              _vm._v(
                " " +
                  _vm._s(resolution.title) +
                  " (" +
                  _vm._s(resolution.width) +
                  ") "
              )
            ]
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }