<template>
<div>
  <div class="bar" v-if="hasYears || hasPerAnno" v-show="eChartSeries.length || message">
    <!-- <span class="bar-item">Rendite / Risiko Chart (auf EUR-Basis) </span> -->
    <span class="bar-item" v-if="hasYears">
      <ComboBox :value="year" :values="years" @change="changeYear" />
    </span>
    <span class="bar-item" v-show="year!==1">
      <span v-if="hasPerAnno" class="per-anno">
        <InputToggleSwitch
          v-model="perAnno"
          @input="togglePerAnno($event)"
          label="Rendite p.a."
          inLineLabel
          suppressValidationMessage />
      </span>
    </span>
  </div>
  <div v-if="message" class="m-3 box__title">{{message}}</div>
  <div v-else id="chart-yield-risk-funds" >
    <ScatterChart ref="scatterChart" 
      :customColors="getColors(eChartSeries)"
      yAxisName="Performance (%)"
      xAxisName="Volatilität (%)"
      height="500px"
      :hasLegend = "chartParam.hasLegend"
      :series="eChartSeries" 
      :changeOption="changeOption"
      :tooltip="eChartRisicoTooltip"/>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex'
import DEPOTPOSITIONS_TYPES from "@/store/depotpositions/types";
// import FONDSINFO_TYPES from '@store/fondsinfo/types'
import ComboBox from '@/components/core/forms/ComboBox.vue';
import {preformancePerAnno} from "@/helpers/commonfunctions"
import {formatNumber} from '@/helpers/number-formatter';
import ScatterChart from '@/components/charts/echarts/ScatterChart.vue'
import { PhCaretDown } from 'phosphor-vue';
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import {COLOR_STANDARD} from '@/components/charts/chartFondsUtil.js';

export default {
  props: {
    chartHeight: {
      type: String,
      default: '400px',
    },
    chartParam: {
      type: Object,
      required: true,
    },
    chartSeries: {
      type: Array,
      default: null,
    },
    customColors: {
      type: Array,
      default: null,
    },
    customColorsOrder: {
      default: () => ({}),
    },
    setCustomColorsOrder: {
      default: null,
    },
  },
  data() {
    return {
      perAnno: false,
      year: 3,
      message: '',
      riskData: [],
      cnt: 0,
      colors: null,
      limits: null,
      scatter: null,
    }
  },
  computed: {
    hasYears() {
      return this.chartParam && this.chartParam.hasYears !== undefined ? this.chartParam.hasYears : true;
    },
    hasPerAnno() {
      return this.chartParam && this.chartParam.hasPerAnno !== undefined ? this.chartParam.hasPerAnno : true;
    },
    years() {
      return this.chartParam && this.chartParam.years !== undefined 
            ? this.chartParam.years
            : [{label: '1 Jahr', value: 1}, {label: '3 Jahre', value: 3}, {label: '5 Jahre', value: 5}];
    },
    ...mapGetters({
      fondsInfo: DEPOTPOSITIONS_TYPES.GETTERS.DEPOTPOSITIONS_FONDSINFO,
    }),
    chartData() {
      if ( this.chartParam && this.chartSeries ) {
        return this.chartSeries;
      }
      
      if (this.riskData.length) {
        return this.riskData;
      }
      return null;
    },
    eChartSeries() {
      if ( this.chartData?.length ) {
        if ( this.chartSeries?.length ) {
          const serie = this.chartSeries.find(s => s.minmax);
          if ( serie ) {
            const mm = serie.minmax;
            this.limits = {
              minX: Math.floor(mm[0] - (mm[1] - mm[0])/10),
              maxX: Math.ceil(mm[1] + (mm[1] - mm[0])/10),
              minY: Math.floor(mm[2] - (mm[3] - mm[2])/10),
              maxY: Math.ceil(mm[3] + (mm[3] - mm[2])/10)
            }
            return this.chartSeries.sort((a, b) => a.name.localeCompare(b.name));
          }
        } else  {
          const group = this.fondsInfo.schwerpunkt;
          let data1 = this.chartData[0];
          let data2 = this.chartData.slice(1);
          if ( this.perAnno ) {
            data1 = [data1[0], preformancePerAnno(this.year, data1[1]), data1[2]]
            data2 = data2.map( d => [d[0], preformancePerAnno(this.year, d[1]), d[2]]);
          }
          const average = data2.reduce( (pv, pc) => [pv[0] + pc[0], pv[1] + pc[1]], [0, 0]);
          average[0] = average[0] / data2.length;
          average[1] = average[1] / data2.length;
          average.push( 'Durchschnitt<br>' + group);
          this.colors = COLOR_STANDARD;
          return [
            {
              name: 'Durchschnitt',
              data: [average],
              symbolSize: 0,
              markLine: {
                symbol:['none', 'none'],
                lineStyle: {
                  type: 'solid'
                },
                data: [{ yAxis: average[1] }, { xAxis: average[0] }]
              }
            },{
              name: group,
              data: data2,
              symbolSize: 8,
            },
            {
              name: data1[2][0],
              data: [data1],
              symbol: 'rect',
              symbolSize: 10,
            },
          ];
        }
      }
      return [];
    },
    eChartRisicoTooltip() {
      return {
        trigger: 'item',
        formatter: function (params) {
          if (Array.isArray(params.value?.[2])) {
            const nichtHandelbar = params.value[2].length > 2 && params.value[2][2] == '1' ? ' class="color-danger"' : '';
            return `
              <div>
                <div style="white-space: normal;"><b>${params.value[2][0]}</b></div>
                <div class="tooltip-series-label">
                  <div>
                    <div${nichtHandelbar}>${params.value[2][1]}</div>
                    <div style="white-space: nowrap;">Performance: ${formatNumber(params.value[1], 2)}</div>
                    <div style="white-space: nowrap;">Volatilität: ${formatNumber(params.value[0], 2)}</div>
                  </div>
                </div>
              </div>`;
          }
          return '';
        },
      }
    }
  },
  methods: {
    togglePerAnno(value) {
      this.perAnno = value;
    },
    changeYear(year) {
      this.year = year;
    },
    getColors(data) {
      const colors = this.customColors || this.colors
      if (Array.isArray(data) && this.setCustomColorsOrder) {
        return data.map(v => {
          const name = v.name.replace('\n', ' *').split(/ \*/)
          if (name && name[0]) {
            if (!this.customColorsOrder[name[0]]) {
              this.setCustomColorsOrder(name[0])
            }
            return this.customColorsOrder[name[0]] || colors[Object.keys(this.customColorsOrder).length];
          }
        }).concat(colors)
      }
      return colors
    },
    getRiskData() {
      if ( this.chartParam && this.chartSeries ) {
        return ;
      }
      const data = this.fondsInfo.FUNDS_RISK_CHART;
      if ( data && data.isin === this.chartParam.isin && data['data' + this.year] ) {
        this.message = data['data' + this.year].errorMesage || '';
        if (this.message) {
          return null;
        }
        this.riskData = data['data' + this.year].records;
      } else if (!data || data.isin !== this.chartParam.isin || data.year !== this.year){
        this.riskData = [];
        this.message = '';
        this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.RETRIEVE_DEPOTPOSITIONS_FONDSINFO_FUNDS_RISK_CHART, {
          isin: this.chartParam.isin,
          year: this.year
        });
      } else {
        this.message = '';
      }
    },
    changeOption(option) {
      if (option.legend) {
        option.legend.itemWidth = 15; 
        option.legend.itemHeight = 15;
      }
      if (this.limits) {
        option.xAxis.min = this.limits.minX;
        option.xAxis.max = this.limits.maxX;
        option.yAxis.min = this.limits.minY;
        option.yAxis.max = this.limits.maxY;
      }
      return option;
    },
  },
  watch: {
    fondsInfo: {
      handler: function() {
        const data = this.fondsInfo.FUNDS_RISK_CHART;
        if ( data && data.isin === this.chartParam.isin && data['data' + this.year] ) {
          this.message = data['data' + this.year].errorMesage || '';
          if (!this.message) {
            this.riskData = data['data' + this.year].records;
          }
        }
      },
      deep: true,
    },
    year: function() {
      this.getRiskData();
    },
    riskData() {
      if (this.chartParam.chartComp) {
        const scatter = this.$refs.scatterChart?.$refs?.chart;
        if (scatter?._uid && (!this.scatter || this.scatter._uid != scatter?._uid) && this.eChartSeries?.length) {
          this.$nextTick(() => {
            if (scatter.chart?.on) {
              this.scatter = scatter;
              this.chartParam.chartComp(scatter.chart, this.eChartSeries);
            }
          })
        }
      }
    }
  },
  mounted() {
    if (this.chartParam?.year && this.year != this.chartParam.year) {
      this.year = this.chartParam.year;
    } else {
      this.getRiskData();
    }
  },
  components: {
    ComboBox,
    ScatterChart,
    PhCaretDown,
    InputToggleSwitch,
  }
}
</script>

<style scoped>
#chart-yield-risk-funds {
  background-color: var(--color-box);
  margin: 0;
  padding: 0;
}
.bar {
  display: flex;
  justify-content: center;
  border-bottom: 2px solid var(--color-background);
}
.bar-item {
  flex: 0 0 auto;
  cursor: pointer;
}
.bar-item + .bar-item{
  margin-left: 2rem;
}
.per-anno {
  position: relative;
  width: 1.3rem;
  height: 1rem;
  box-shadow: -1px -1px 0 #000;
}
.per-anno i {
  position: absolute;
}
.years-choices span {
  white-space: nowrap;
  cursor: pointer;
}
.years-choices .active {
  color: var(--color-workspaces-nav-active);
  background-color: var(--color-workspaces-nav-background);
}
</style>

