<template>
  <div>
    <PageHeaderTitleNavigation 
      title="Jahresperformance Details"
      :subtitle="depotTitle"
      :actions="headerActions"
    />
    
    <div class="box__container">
      <div class="ibox-body">

        <div class="w300">
          <ComboBox 
            v-model="year"
            label="Auswahl Jahr"
            :values="valuesYear"
            @change="retrieveDetails"
          ></ComboBox>
        </div>

        <div>
          <Table v-if="rows.length && !loading"
              :headers="headers"
              :rows="rows"
              rowId="Wertpapier"
              :rowsPerPage="0"
              :exportConfig="exportConfig"
              :mobileConfig="{title: 'Wertpapier', headers: ['g_v', 'Rendite', 'Aktueller_Bestand']}"
              @action-TRANSAKTIONEN="navigateToTransactions"
          />
          <GhostLoading v-else-if="loading" type="table" />
          <NoData v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import PORTFOLIO_TYPES from '@/store/portfolio/types';
import DEPOTPOSITIONS_TYPES from "@/store/depotpositions/types";
import { mapGetters } from 'vuex';
import Table from '@/components/table2/Table.vue';
import {TextColumn, CurrencyColumn, DateColumn, PercentageColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import ComboBox from '@/components/core/forms/ComboBox.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue'
import { PhArrowsLeftRight} from 'phosphor-vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { PageHeaderPortfolioSelectorAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {
	data() {
		return {
      year: '',
      valuesYear: [],
    }
  },
  watch: {
    details(value) {
      this.initValues(value);
    },
    depotId() {
      this.retrieveDetails(this.year)
    }
  },
  mounted() {
    this.initValues(this.details);
  },
  computed: {
    ...mapGetters({
      details: PORTFOLIO_TYPES.GETTERS.HISTORICAL_PERFORMANCE_DETAILS,
      screenWidth: CORE_TYPES.GETTERS.SCREEN_WIDTH,
      fullname: CORE_TYPES.GETTERS.GET_USER_FULL_NAME,
      depotId: DEPOTPOSITIONS_TYPES.GETTERS.DEPOT_ID,
      depotTitle: DEPOTPOSITIONS_TYPES.GETTERS.SELECTED_DEPOT_TITLE,
    }),
    headers() {
      const headers = { 
        center: [
          TextColumn('Wertpapier', 'Wertpapier').makeAlwaysVisible(),
          CurrencyColumn('Anfangsbestand', 'Anfangbestand'),
          CurrencyColumn('Einzahlungen', 'Einzahlungen'),
          CurrencyColumn('Auszahlungen', 'Auszahlungen'),
          CurrencyColumn('Wechsel', 'Wechsel'),
          CurrencyColumn('Endbestand', 'Endbestand'),
          CurrencyColumn('g_v', 'G&V'),
          PercentageColumn('Rendite', 'Rendite'),
          CurrencyColumn('Aktueller_Bestand', 'Aktueller Bestand'),
          CurrencyColumn('Aktuelle_Anteile', 'Aktuelle Anteile'),
          CurrencyColumn('Kurs', 'Kurs'),
          DateColumn('vom', 'vom'),
        ],
      };
      if (this.rows?.some(row => row?.actions?.length)) {
        headers.lockedRight = [
          ActionColumn("actions", "Aktionen"),
        ];
      }
      return headers;
    },
    exportConfig(){ 
      return {
        roottext: 'Jahresperformance ' + this.year,
      }
    },
    loading() {
      return !this.details?.isLoaded;
    },
    rows() {
      return this.details?.rows?.map(row => {
        return {
          ... row,
          actions: row.action ? [SimpleAction('TRANSAKTIONEN', PhArrowsLeftRight, 'Transaktionen')] : []
        }
      }).sort((a,b) => a?.Wertpapier === 'Summe' ? 1 : (b?.Wertpapier === 'Summe' ? -1 : a?.Wertpapier?.localeCompare(b?.Wertpapier) || 0)) || [];
    },
    headerActions() {
      return [
        PageHeaderPortfolioSelectorAction('SELECT-DEPOT'),
      ];
    },
  },
  methods: {
    initValues(details) {
      if (details?.year && !this.year) {
        this.year = details.year;
      }
      this.valuesYear = details.valuesYear || [];
    },
    retrieveDetails(year) {
      this.$store.dispatch(PORTFOLIO_TYPES.ACTIONS.GET_HISTORICAL_PERFORMANCE_DETAILS, {year, depotId: this.depotId});
    },
    navigateToTransactions(row) {
      if (row) {
        this.$addBreadcrumb({
          label: `zurück zur Jaresperformance`,
          fullPath: this.$route.fullPath,
          breadcrumb: 'Transaktionen',
        });
        const bodyParams = JSON.stringify(JSON.parse(`{"bgsNr": "${row.param_bgsnr}", "dateBegin": "01.01.${this.year}", "dateEnd": "31.12.${this.year}"}`));
        const params = encodeURIComponent(bodyParams);
        this.$router.push({ path: `/home/berichte/transaktionen?params=${params}` });
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({to, from});
    next();
  },
	components: {
    Table,
    OptionMenu,
    ComboBox,
    GhostLoading,
    NoData,
    PageHeaderTitleNavigation,
	}
}
</script>

<style scoped>
  label {
    height: 1.5rem;
    display: block;
  }
  .ml-1 {
    margin-left: 1rem;
  }
  .historical-performance-chart {
    /* height: 550px; */
    width: 100%;
  }
  .app-vertical-bar.historical-performance-chart {
    /* height: 550px; */
  }
  /* @media (min-width: 1025px) {
    .historical-performance-chart {
      width: 80%;
    }
  } */
  .w-30 {
    max-width: 30%;
  }
  @media (max-width: 767px) {
    .w-30 {
      max-width: 100%;
    }
  }
  
  .filter-zeit {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
  .w300 {
    width: 300px;
    display: inline-block;
  }
  .w120 {
    width: 120px;
    display: inline-block;
  }
</style>
