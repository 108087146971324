import CUSTOMER_SEARCH_TYPES from './types';
import { getInitialState } from './index';
import Vue from 'vue';
import { looksLikeKundennr } from '@/views/customer/customer-utils';
import { deepAssign, } from '@/helpers/commonfunctions';
import { buildMessage } from '@/helpers/log-message-helper';
import LOG_TYPES from '@/store/log/types';
import store from '@/store'


export default {
  [CUSTOMER_SEARCH_TYPES.MUTATIONS.SEARCH_CUSTOMER_SUCCESS](state, {responseData, section}) {
    // if we were to load a page before page 0, the server won't return 'count'
    if (responseData.askPIN) {
      state.searchCustomer = {
        askPIN: responseData.askPIN,
      };
      return;
    }
    const outputs = responseData.outputs?.length && responseData.outputs || responseData.emailParticipants;
    if (section > 0 && !state.searchCustomer)
      throw "Attempted to load page " + section + " without first loading page 0";
    if (section > 0) {
      Vue.set(state.searchCustomer.customers, section, outputs);
    }
    else {
      if (responseData.rows) {
        state.searchCustomer = {
          customers: responseData.rows,
          count: responseData.rows.length,
          personen: responseData.personen,
        };
      } else if (responseData.PIN) {
        state.searchCustomer = responseData;
      } else {
        state.searchCustomer = {
          customers: {
            [section]: outputs,
          },
          sequenceId: responseData.seq,
          count: responseData.count,
        };
      }
    }
  },
  [CUSTOMER_SEARCH_TYPES.MUTATIONS.GET_SEARCH_CUSTOMER_SETUP_SUCCESS](state, payload) {
    state.customerFilterSetup = { data: payload.data, status: 'success', };
  },
  [CUSTOMER_SEARCH_TYPES.MUTATIONS.GET_SEARCH_CUSTOMER_SETUP_DEPOT_SUCCESS](state, payload) {
    // state.customerFilterSetupDepot = { data: payload.data, status: 'success', };
    Vue.set(state, "customerFilterSetupDepot", { data: payload.data, status: 'success', });
  },
  [CUSTOMER_SEARCH_TYPES.MUTATIONS.GET_SEARCH_BROKER_SETUP_SUCCESS](state, payload) {
    state.brokerFilterSetup = payload;
  },
  [CUSTOMER_SEARCH_TYPES.MUTATIONS.GET_SEARCH_CONTACTS_SETUP_SUCCESS](state, payload) {
    state.contactFilterSetup = payload;
  },
  [CUSTOMER_SEARCH_TYPES.MUTATIONS.GET_SEARCH_CUSTOMER_SETUP_VERS_SUCCESS](state, payload) {
    state.customerFilterSetupVers = { data: payload.data, status: 'success', };
  },
  [CUSTOMER_SEARCH_TYPES.MUTATIONS.SEARCH_BROKER_SUCCESS](state, {brokers, emailParticipants, count, hasNext, pageId}) {
    const output = brokers && brokers.length && brokers || emailParticipants;
    if (pageId > 0 && !state.brokers)
        throw "Attempted to load page " + pageId + " without first loading page 0";
    if (pageId > 0) {
        Vue.set(state.brokers.pages, pageId, output);
    } else {
        state.brokers = {
            count,
            pages: {
                "0": output,
            },
        };
    }
  },
  [CUSTOMER_SEARCH_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },
  [CUSTOMER_SEARCH_TYPES.MUTATIONS.RESET_STATE_SEARCH](state) {
    Object.assign(state, getInitialState({
      lastOpenedCustomers: state.lastOpenedCustomers
    }))
  },
  [CUSTOMER_SEARCH_TYPES.MUTATIONS.GET_BROKER_START_PANELS_SUCCESS](state, payload) {
    state.panels = payload && payload.length && payload.map(v => v.data.objects);
  },
  [CUSTOMER_SEARCH_TYPES.MUTATIONS.OPEN_CUSTOMER](state, payload) {
    if(!payload || !looksLikeKundennr(payload.data.customerID)) {
      return;
    }

    const { lastOpenedCustomers = [], } = state;
    let payloadCopy = { ...payload };

    const openedCustomerIndex = lastOpenedCustomers.findIndex(v => v.data.customerID == payload.data.customerID);
    if(openedCustomerIndex >= 0) { // removes the last customer opened from the list to keep it at the top
      payloadCopy = deepAssign({}, lastOpenedCustomers[openedCustomerIndex], payloadCopy);
      lastOpenedCustomers.splice(openedCustomerIndex, 1);
    }

    Vue.set(state, 'lastOpenedCustomers', [
      payloadCopy, // keeps the last customer opened at the top
      ...lastOpenedCustomers,
    ]);
  },
  [CUSTOMER_SEARCH_TYPES.MUTATIONS.UPDATE_LAST_OPENED_CUSTOMER_DATA_ONLY](state, payload) {
    if(!payload || !looksLikeKundennr(payload.data.customerID)) {
      return;
    }

    const { lastOpenedCustomers = [], } = state;
    const lastOpenedCustomer = { ...payload, };
    const openedCustomerIndex = lastOpenedCustomers.findIndex(v => v.data.customerID == payload.data.customerID);
    if(openedCustomerIndex >= 0) {
      lastOpenedCustomers[openedCustomerIndex] = lastOpenedCustomer;
      Vue.set(state, 'lastOpenedCustomers', [ ...lastOpenedCustomers, ]);
    }
  },
  [CUSTOMER_SEARCH_TYPES.MUTATIONS.SET_SELECTED_CUSTOMERS](state, payload) {
    state.selectedCustomers = payload;
  },
  [CUSTOMER_SEARCH_TYPES.MUTATIONS.KUNDEN_BEREINIGEN_SUCCESS](state, payload) {
    store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Die Kunden wurden erfolgreich bereinigt.', 'success'));
  },
  [CUSTOMER_SEARCH_TYPES.MUTATIONS.KUNDEN_LOESCHEN_RESTRICTIONS_SUCCESS](state, payload) {
    state.kundenLoeschenRestrictions = payload;
  },
  [CUSTOMER_SEARCH_TYPES.MUTATIONS.KUNDEN_LOESCHEN_SUCCESS](state, payload) {
    state.removedCustomers = payload;
  },
  [CUSTOMER_SEARCH_TYPES.MUTATIONS.RESET_KUNDEN_LOESCHEN_DATA](state, payload) {
    state.kundenLoeschenRestrictions.length = 0;
    state.removedCustomers.length = 0;
  },
  [CUSTOMER_SEARCH_TYPES.MUTATIONS.SEARCH_CONTACT_SUCCESS](state, {responseData, section}) {
    const {count, emailParticipants} = responseData
    const output = emailParticipants;
    if (section > 0 && !state.contacts)
        throw 'Attempted to load page ' + section + ' without first loading page 0';
    if (section > 0) {
        Vue.set(state.contacts.pages, section, output);
    } else {
        state.contacts = {
            count,
            pages: {
                '0': output,
            },
        };
    }
  },

}