var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "modalError",
          attrs: {
            modalTitle: "Hinweis",
            labelButtonConfirm: "Ok",
            labelButtonCancel: "Änderungen verwerfen"
          },
          on: {
            onCancelButton: _vm.confirmError,
            onConfirmButton: _vm.resetError,
            onCloseButton: _vm.resetError
          }
        },
        [
          _c("div", { staticClass: "mb-3" }, [
            _vm._v(
              "Diese Fehler müssen korrigiert werden um speichern zu können:"
            )
          ]),
          _c("span", {
            domProps: { innerHTML: _vm._s(_vm.sanitize(_vm.errorText)) }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }