var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "modal",
    attrs: {
      modalTitle: "Fotografieren",
      showDefaultButtons: false,
      actions: _vm.modalActions
    },
    on: {
      close: _vm.cancel,
      "action-TAKE-PHOTO": _vm.takePhoto,
      "action-CANCEL": _vm.cancel,
      "action-ACCEPT-PHOTO": _vm.acceptPhoto,
      "action-DISCARD-PHOTO": _vm.discardPhoto,
      "action-HIDE-EDGE-SELECTION": function($event) {
        _vm.hideEdgeSelection = true
      },
      "action-SHOW-EDGE-SELECTION": function($event) {
        _vm.hideEdgeSelection = false
      }
    },
    scopedSlots: _vm._u(
      [
        _vm.isVideoVisible
          ? {
              key: "modalHeaderBottom",
              fn: function() {
                return [
                  _vm.$isSmallScreen
                    ? _c(
                        "div",
                        { staticClass: "toggle-camera-button__container" },
                        [
                          _c(
                            "BaseButton",
                            {
                              attrs: {
                                isClear: "",
                                disabled: _vm.cameraList.length <= 1
                              },
                              on: {
                                click: function($event) {
                                  return _vm.toggleCamera()
                                }
                              }
                            },
                            [_c("PhCameraRotate", { attrs: { size: 32 } })],
                            1
                          )
                        ],
                        1
                      )
                    : _c("Combobox", {
                        attrs: {
                          label: "Kamera:",
                          inlineContainer: "",
                          value: _vm.deviceId,
                          values: _vm.cameraList,
                          disabled: _vm.cameraList.length <= 1
                        },
                        on: {
                          change: function($event) {
                            return _vm.changeCamera($event)
                          }
                        }
                      })
                ]
              },
              proxy: true
            }
          : null,
        {
          key: "default",
          fn: function() {
            return [
              _vm.isVideoVisible
                ? _c("div", { staticClass: "input-container" }, [
                    _c("div", { staticClass: "center" }, [
                      _c("video", {
                        ref: "videoElement",
                        attrs: { autoplay: "", playsinline: "" }
                      })
                    ]),
                    _vm.edgeCorrection && _vm.inputVideo
                      ? _c(
                          "div",
                          {
                            staticClass: "center",
                            staticStyle: { position: "absolute" }
                          },
                          [
                            _c("EdgeDetection", {
                              attrs: {
                                videoInput: _vm.inputVideo,
                                debugStage: _vm.debugStep
                              },
                              on: { change: _vm.updateCorners }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                : _vm.edgeCorrection
                ? _c("div", { staticClass: "input-container" }, [
                    _c(
                      "div",
                      { staticClass: "center" },
                      [
                        _c("EdgeSelectionDialog", {
                          attrs: {
                            imageData: _vm.rawImgUrl,
                            corners: _vm.corners,
                            hideSelection: _vm.hideEdgeSelection,
                            hideControls: !_vm.hideEdgeSelection
                          },
                          on: { change: _vm.updateImg }
                        })
                      ],
                      1
                    )
                  ])
                : _c("div", { staticClass: "input-container" }, [
                    _c("div", { staticClass: "center" }, [
                      _c("img", {
                        staticClass: "preview",
                        attrs: { src: _vm.improvedImgUrl }
                      })
                    ])
                  ])
            ]
          },
          proxy: true
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }