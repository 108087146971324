var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "container" }, [
      _vm.logoUrl
        ? _c("div", { staticClass: "logo" }, [
            _c("a", { attrs: { href: "#" } }, [
              _c("img", {
                attrs: {
                  alt: "Makler Logo",
                  src: _vm.logoURL,
                  id: "logo",
                  width: "50%"
                }
              })
            ])
          ])
        : _vm._e(),
      _vm._m(0)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "navi" }, [
      _c("div", { staticClass: "home" }, [
        _c("a", { attrs: { href: "#home" } }, [_vm._v("Home")])
      ]),
      _c("div", { staticClass: "ueber-uns" }, [
        _c("a", { attrs: { href: "#ueberuns" } }, [_vm._v("Über Uns")])
      ]),
      _c("div", { staticClass: "streitbeilegung" }, [
        _c("a", { attrs: { href: "#streitbeilegung" } }, [
          _vm._v("Streitbeilegung")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }