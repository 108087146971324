var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _c("SimpleAdditionalDocumentsTable", {
        ref: "documentsTable",
        attrs: {
          nodeId: _vm.nodeId,
          fileId: _vm.fileId,
          customDocuments: _vm.customDocuments,
          customUpload: _vm.addDocument,
          uploadInputsConfig: _vm.uploadInputsConfig,
          multipleFileUpload: "",
          isLoading: _vm.loading
        },
        on: {
          showDocument: _vm.showDocument,
          deleteDocument: function($event) {
            return _vm.deleteDocument($event.fileId)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }