import BERATUNGSMAPPE_TYPES from './types';
import CORE_TYPES from '../core/types';
import LOG_TYPES from '@/store/log/types';
import axios from 'axios';
import { buildMessage, } from '../../helpers/log-message-helper';
import { makeQueryParam } from '@/helpers/utils-helper';
import * as utils from '../../helpers/commonfunctions';

export default {
  async [BERATUNGSMAPPE_TYPES.ACTIONS.RETRIEVE_BERATUNG_VERSICHERUNG_LIST]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    let serviceUrl = '/beratungVersicherung/listBeratungsmappen';


    if (payload) {
      const filterDateVon = payload.filterDateVon;
      const filterModusDatum = payload.filterModusDatum;
      const filterDateBis = payload.filterDateBis;
      const filterStatus = payload.filterStatus;
      const filterFehler = payload.filterFehler;
      const filterMitScan = payload.filterMitScan;
      const filterDateJahr = payload.filterDateJahr;
      const filterOrt = payload.filterOrt;

      if (filterDateVon) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'filterDateVon', filterDateVon);
      }

      if (filterModusDatum) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'filterModusDatum', filterModusDatum);
      }

      if (filterDateBis) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'filterDateBis', filterDateBis);
      }

      if (filterStatus) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'filterStatus', filterStatus);
      }

      if (filterFehler) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'filterFehler', filterFehler);
      }

      if (filterMitScan) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'filterMitScan', filterMitScan);
      } else  {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'filterMitScan', false);
      }

      if (filterDateJahr) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'filterDateJahr', filterDateJahr);
      }

      if (filterOrt) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'filterOrt', filterOrt);
      }        

    }    
   

    await axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
      if (response && response.data) {

        commit(BERATUNGSMAPPE_TYPES.MUTATIONS.RETRIEVE_BERATUNG_VERSICHERUNG_LIST_SUCCESS, response.data);

      }      

    })
  },
  [BERATUNGSMAPPE_TYPES.ACTIONS.EDIT_BERATUNG_VERSICHERUNG]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    let serviceUrl = '/mrsberatungversicherung';


    if (payload) {
      const beratungsVersId = payload.beratungsVersId;


      if (!beratungsVersId) {
        return;
      } 

      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'beratungsVersId', beratungsVersId);

      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'neuesOrderpositionslayout', 'Ja');
      

    }    
   

    axios.get(rootState.core.apiAddress + '/../' + serviceUrl, config).then(response => {
      if (response && response.data) {

        commit(BERATUNGSMAPPE_TYPES.MUTATIONS.EDIT_BERATUNG_VERSICHERUNG_SUCCESS, response.data);

      }      

    })
  }, 
  [BERATUNGSMAPPE_TYPES.ACTIONS.SAVE_BERATUNG_VERSICHERUNG]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    let serviceUrl = '/mrsberatungversicherung';


    if (payload) {
      const beratungsVersId = payload.beratungsVersId;


      if (!beratungsVersId) {
        return;
      } 

      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'beratungsVersId', beratungsVersId);

      const erlaubnisPara34 = payload.erlaubnisPara34;

      if (erlaubnisPara34) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'erlaubnisPara34', erlaubnisPara34);
      }

      const bezeichnung = payload.bezeichnung;

      if (bezeichnung) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'bezeichnung', bezeichnung);
      }      

      const action = payload.action;

      if (action) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'action', action);
      }    

      const beratungsMappeTyp = payload.beratungsMappeTyp;

      if (beratungsMappeTyp) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'beratungsMappeTyp', beratungsMappeTyp);
      }    

      const lagerstelle = payload.lagerstelle;

      if (lagerstelle) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'lagerstelle', lagerstelle);
      }  

      const bemerkung = payload.bemerkung;

      if (bemerkung) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'bemerkung', bemerkung);
      } 
    }    

    axios.post(rootState.core.apiAddress + '/../'  + serviceUrl, config).then(response => {
      if (response && response.data) {

        dispatch(BERATUNGSMAPPE_TYPES.ACTIONS.EDIT_BERATUNG_VERSICHERUNG, {beratungsVersId: payload.beratungsVersId})

      }      

    })
  }, 
  [BERATUNGSMAPPE_TYPES.ACTIONS.RETRIEVE_BERATUNG_VERSICHERUNG_ACTIONS]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    let serviceUrl = '/mrsbvactions';


    if (payload) {
      const beratungsVersId = payload.beratungsVersId;


      if (!beratungsVersId) {
        return;
      } 

      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'beratungsVersId', beratungsVersId);

      const PARAMETER_NAME_MODUS = payload.PARAMETER_NAME_MODUS;

      if (PARAMETER_NAME_MODUS) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'PARAMETER_NAME_MODUS', PARAMETER_NAME_MODUS);
      }

    }    

    axios.get(rootState.core.apiAddress + '/../' + serviceUrl, config).then(response => {
      if (response && response.data) {

        commit(BERATUNGSMAPPE_TYPES.MUTATIONS.RETRIEVE_BERATUNG_VERSICHERUNG_ACTIONS_SUCCESS, response.data);

      }      

    })
  }, 
  [BERATUNGSMAPPE_TYPES.ACTIONS.EXECUTE_BERATUNG_VERSICHERUNG_ACTION]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    let serviceUrl = '/mrsbvactions';


    if (payload) {
      const beratungsVersId = payload.beratungsVersId;


      if (!beratungsVersId) {
        return;
      } 

      Object.keys(payload).forEach(e => 
        {
          // console.log(`key=${e}  value=${obj[e]}`);
          serviceUrl = utils.updateQueryStringParameter(serviceUrl, `${e}`, `${payload[e]}`);
        
        }
      );


    }    

    axios.post(rootState.core.apiAddress + '/../' + serviceUrl, config).then(response => {
      if (response && response.data) {

        commit(BERATUNGSMAPPE_TYPES.MUTATIONS.EXECUTE_BERATUNGSMAPPEACTION_SUCCESS, response.data);

      }      

    }).catch(error => {
      let message = 'Es war nicht möglich, die Aktion auszuführen.';

      if (error && error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message;
      }

      console.log(error);
      dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(message, 'danger'));

    })
  }, 
  [BERATUNGSMAPPE_TYPES.ACTIONS.DELETE_BERATUNG_VERSICHERUNG]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    let serviceUrl = '/beratungVersicherung/remove';

    if (payload) {
      const beratungsVersId = payload.beratungsVersId;


      if (!beratungsVersId) {
        return;
      } 

      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'beratungsVersId', beratungsVersId);

    } 


    axios.delete(rootState.core.apiAddress + serviceUrl, config).then(response => {
      if (response && response.data) {

        dispatch(
          BERATUNGSMAPPE_TYPES.ACTIONS.RETRIEVE_BERATUNG_VERSICHERUNG_LIST,
          {
            filterDateVon : null,
            filterModusDatum : null,
            filterDateBis : null,
            filterStatus : null,
            filterFehler : null,
            filterMitScan : null,
          }
        );

      }      

    })
  },  
  [BERATUNGSMAPPE_TYPES.ACTIONS.CREATE_BERATUNG_VERSICHERUNG]({ dispatch, getters }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {

      const param = Object.entries(payload)
          .map(([key, val]) => `${key}=${val}`)
          .join('&');

      const serviceUrl = `/beratungVersicherung/create?${param}`;

      axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${serviceUrl}`, config).then(response => {
        if (response && response.data) {
          
          dispatch(
            BERATUNGSMAPPE_TYPES.ACTIONS.RETRIEVE_BERATUNG_VERSICHERUNG_LIST,
            {
              filterDateVon : null,
              filterModusDatum : null,
              filterDateBis : null,
              filterStatus : null,
              filterFehler : null,
              filterMitScan : null,
            }
          );
          resolve(response.data)
        }      

      })
    });
  },
  [BERATUNGSMAPPE_TYPES.ACTIONS.DO_DOWNLOAD_DOKUMENT_SMART_MSC]({ commit, dispatch, getters }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {

      const serviceUrl = `/beratungVersicherung/doDownloadDokumentSmartMSC`;

      axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${serviceUrl}`, config).then(response => {
        if (response && response.data) {
          
          commit(BERATUNGSMAPPE_TYPES.MUTATIONS.DO_DOWNLOAD_DOKUMENT_SMART_MSC_SUCCESS, response.data);
          resolve(response.data)
        }      

      })
    });
  },  
  
  [BERATUNGSMAPPE_TYPES.ACTIONS.DELETE_ANTRAG]({ commit, dispatch, rootState }, payload) {

    const config = {
      defaultSpinner: true
    };

    let serviceUrl = '/beratungVersicherung/removeAntrag';

    const beratungsVersId = payload && payload.beratungsVersId;

    if (!payload || !payload.antragId || !beratungsVersId) {
      return;
    }
    
    serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'action', payload.action);
    serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'beratungsVersId', beratungsVersId);
    serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'antragId', payload.antragId);

    return axios.delete(rootState.core.apiAddress + serviceUrl, {}, config)
    .then(response => {
      response.data.antragId = payload.antragId;
      commit(BERATUNGSMAPPE_TYPES.MUTATIONS.DELETE_ANTRAG_SUCCESS, response.data);
    })
  }, 
  [BERATUNGSMAPPE_TYPES.ACTIONS.ZUR_E_SIGNATUR_VORLEGEN]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    let serviceUrl = '/mrsberatungversicherung';


    if (payload) {
      const beratungsVersId = payload.beratungsVersId;


      if (!beratungsVersId) {
        return;
      } 

      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'beratungsVersId', beratungsVersId);

      const erlaubnisPara34 = payload.erlaubnisPara34;

      if (erlaubnisPara34) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'erlaubnisPara34', erlaubnisPara34);
      }

      const bezeichnung = payload.bezeichnung;

      if (bezeichnung) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'bezeichnung', bezeichnung);
      }      

      const action = payload.action;

      if (action) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'action', action);
      }    

      const beratungsMappeTyp = payload.beratungsMappeTyp;

      if (beratungsMappeTyp) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'beratungsMappeTyp', beratungsMappeTyp);
      }    

    }    

    axios.post(rootState.core.apiAddress + '/../'  + serviceUrl, config).then(response => {
      if (response && response.data) {

        dispatch(BERATUNGSMAPPE_TYPES.ACTIONS.EDIT_BERATUNGSMAPPE, {beratungsVersId: payload.beratungsVersId})

      }      

    })
  }, 
  [BERATUNGSMAPPE_TYPES.ACTIONS.BERATUNG_VERSICHERUNG_ABSCHLIESSEN]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    let serviceUrl = '/mrsberatungversicherung';


    if (payload) {
      const beratungsVersId = payload.beratungsVersId;


      if (!beratungsVersId) {
        return;
      } 

      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'beratungsVersId', beratungsVersId);

      const erlaubnisPara34 = payload.erlaubnisPara34;

      if (erlaubnisPara34) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'erlaubnisPara34', erlaubnisPara34);
      }

      const bezeichnung = payload.bezeichnung;

      if (bezeichnung) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'bezeichnung', bezeichnung);
      }      

      const action = payload.action;

      if (action) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'action', action);
      }    

      const beratungsMappeTyp = payload.beratungsMappeTyp;

      if (beratungsMappeTyp) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'beratungsMappeTyp', beratungsMappeTyp);
      }    

    }    

    axios.post(rootState.core.apiAddress + '/../'  + serviceUrl, config).then(response => {
      if (response && response.data) {

        dispatch(BERATUNGSMAPPE_TYPES.ACTIONS.EDIT_BERATUNGSMAPPE, {beratungsVersId: payload.beratungsVersId})

      }      

    })
  }, 
  [BERATUNGSMAPPE_TYPES.ACTIONS.COPY_BERATUNG_VERSICHERUNG]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    let serviceUrl = '/beratungsmappe/copy';

    if (payload) {
      const beratungsVersId = payload.beratungsVersId;


      if (!beratungsVersId) {
        return;
      } 

      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'beratungsVersId', beratungsVersId);

    } 


    axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
      if (response && response.data) {

        dispatch(
          BERATUNGSMAPPE_TYPES.ACTIONS.RETRIEVE_BERATUNG_VERSICHERUNG_LIST,
          {
            filterDateVon : null,
            filterModusDatum : null,
            filterDateBis : null,
            filterStatus : null,
            filterFehler : null,
            filterMitScan : null,
          }
        );

      }      

    })
  },  
  [BERATUNGSMAPPE_TYPES.ACTIONS.RETRIEVE_INSURANCES_CUSTOMER]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    let serviceUrl = '/beratungVersicherung/getInsurancesCustomer';

    const websiteId = payload?.websiteId
    const insuranceType = payload?.insuranceType

    const params = makeQueryParam({ websiteId, insuranceType })

    return axios.get(rootState.core.apiAddress + serviceUrl + `?${params}`, config).then(response => {
      if (response && response.data) {

        commit(BERATUNGSMAPPE_TYPES.MUTATIONS.RETRIEVE_INSURANCES_CUSTOMER_SUCCESS, response.data);

      }      

    })
  },  
  [BERATUNGSMAPPE_TYPES.ACTIONS.FINANZPORTAL24_ONLINE_INSURANCELINK]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };


    return new Promise((resolve, reject) => {

      let serviceUrl = '/beratungVersicherung/finanzportal24OnlineInsuranceLink?';

      if (payload) {
        const idList = payload.idList;
  
  
        if (!idList?.length) {
          return;
        } 
  
        let parameters = '';
        idList.forEach(item => {
          parameters += `idList=${item.vertragId}&`;
        });

        serviceUrl += parameters.substr(0,parameters.length-1);
      } 
     
      axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
        if (response && response.data) {
  
          commit(BERATUNGSMAPPE_TYPES.MUTATIONS.FINANZPORTAL24_ONLINE_INSURANCELINK, response.data);

          resolve(response.data);
  
        }      
  
      })

    });


  }, 
  [BERATUNGSMAPPE_TYPES.ACTIONS.FINANZPORTAL24_ONLINE_FILELINK]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {

      let serviceUrl = '/beratungVersicherung/finanzportal24OnlineFileLink';
  
      if (!payload) {
        return;
  
      } 
     
  
      axios.post(rootState.core.apiAddress + serviceUrl, payload, config).then(response => {
        if (response && response.data) {
  
          commit(BERATUNGSMAPPE_TYPES.MUTATIONS.FINANZPORTAL24_ONLINE_FILELINK, response.data);
          resolve(response.data);
  
        }      
  
      })
    });
    
  }, 
  [BERATUNGSMAPPE_TYPES.ACTIONS.FINANZPORTAL24_OFFLINE_INSURANCEFPXFILE]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };


    return new Promise((resolve, reject) => {
      let serviceUrl = '/beratungVersicherung/finanzportal24OfflineInsuranceFPXFile?';

      if (payload) {
        const idList = payload.idList;
  
  
        if (!idList?.length) {
          return;
        } 
  
        let parameters = '';
        idList.forEach(item => {
          parameters += `idList=${item.id}&`;
        });

        serviceUrl += parameters.substr(0,parameters.length-1);
      } 
  
      axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
        if (response && response.data) {
  
          commit(BERATUNGSMAPPE_TYPES.MUTATIONS.FINANZPORTAL24_OFFLINE_INSURANCEFPXFILE, response.data);
          resolve(response.data);
  
        }      
  
      })

    });

  },
  [BERATUNGSMAPPE_TYPES.ACTIONS.SAVE_INSURANCE_CUSTOMER]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    let serviceUrl = '/beratungVersicherung/updatePersonalData';

    return axios.post(rootState.core.apiAddress + serviceUrl, payload, config).then(response => {
      if (response && response.data) {

        commit(BERATUNGSMAPPE_TYPES.MUTATIONS.SAVE_INSURANCE_CUSTOMER, response.data);
      }      
    })
  },
 
}