<template>
<div>
  <div class="box__container" v-if="summary">
    <div class="box__title">Schadenort und -zeit</div>
    <div class="row">
      <div class="col-6">Wann wurde der Schaden verursacht ?</div>
      <div class="col-6">{{summary.ereignisdatum}}</div>
    </div>

    <div class="row">
      <div class="col-6">Um wieviel Uhr?</div>
      <div class="col-6">{{summary.ereignisUhrzeit}}</div>
    </div>

    <div class="row">
      <div class="col-6">Straße, Hausnummer</div>
      <div class="col-6">{{summary.adresseStrasse}}</div>
    </div>

    <div class="row">
      <div class="col-6">PLZ</div>
      <div class="col-6">{{summary.adressePlz}}</div>
    </div>

    <div class="row">
      <div class="col-6">Ort</div>
      <div class="col-6">{{summary.adresseOrt}}</div>
    </div>
  </div>

  <div class="box__container">
    <div class="box__title">Schadensverlauf</div>
    <div class="row">
      <div class="col-6">Wessen Verschulden war der Schaden ?</div>
      <div class="col-6">{{summary.verschulden}}</div>
    </div>

    <div class="row">
      <div class="col-6">Ursache</div>
      <div class="col-6">{{summary.schadensursacheText}}</div>
    </div>

    <div class="row">
      <div class="col-6">Kommentar</div>
      <div class="col-6">{{summary.schadenKommentar}}</div>
    </div>
  </div>

  <div v-if="summary.beteiligte" class="box__container">
    <div class="box__title">Beteiligten</div>
    <BeteiligtePersonen :editMode="false" />
  </div>

  <div v-if="summary.beschaedigt" class="box__container">
    <div class="box__title">Beschädigte Objekte</div>
    <GeschaedigteObjekte :editMode="false" />
  </div>

  <div v-if="summary.zahlungen" class="box__container">
    <div class="box__title">Zahlungen</div>
    <ZahlungenGeschaedigte :editMode="false" />
  </div>

  <div v-if="summary.files" class="box__container">
    <div class="box__title">Fotos und Anhänge</div>
    <FotosAnhaenge :editMode="false" :schadenId="schadenId" :vertragId="vertragId" />
  </div>
</div>
</template>

<script>
import { mapGetters } from "vuex";
import BaseButton from "@/components/core/BaseButton.vue";
import BeteiligtePersonen from './steps/BeteiligtePersonen.vue';
import ZahlungenGeschaedigte from './ZahlungenGeschaedigte.vue';
import GeschaedigteObjekte from './steps/GeschaedigteObjekte.vue';
import FotosAnhaenge from './steps/FotosAnhaenge.vue';
import SCHADENSMELDUNG_TYPES from "@/store/schadensmeldung/types";
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";

export default {
  name: "zusammenfassung",
  props: {
    summary: {
      type: Object,
      default: null
    },
   
  },
  data: function() {
    return {};
  },
  components: {
    BaseButton,
    BeteiligtePersonen,
    GeschaedigteObjekte,
    ZahlungenGeschaedigte,
    FotosAnhaenge,
  },
  computed: {
    ...mapGetters({
      schadensmeldung: SCHADENSMELDUNG_TYPES.GETTERS.SCHADENSMELDUNG,
      detailsData: VERSICHERUNG_TYPES.GETTERS.INSURANCE_DETAILS,
    }),
    vertragId() {
      return this.$route.params.vertragId;
    },
    schadenId() {
      return this.$route.params.schadenId;
    },
    editing() {
      return this.bearbeitenMode;
    }
  },
  methods: {
    zuruck() {
      this.$emit("on-zuruck");
    },
    bearbeiten() {
      this.$emit("on-bearbeiten");
    },
    handleEmail(){
      this.$router.push(`/communication/mailcomposer-schadensmeldung/${this.schadenId}`)
    }
  }
};
</script>
