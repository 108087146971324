var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "box__title" }, [_vm._v("Abrechnungen")]),
      !_vm.loading && _vm.rows.length
        ? _c("Table", {
            attrs: {
              tableId: "2e6961b2-7f82-4d53-809c-b67ecc15f500",
              headers: _vm.headers,
              rows: _vm.rows,
              rowsPerPage: 10
            },
            on: { "action-VIEW_PROV_STRUKTUR": _vm.selectBuchung }
          })
        : _vm.loading
        ? _c(
            "div",
            { staticClass: "text-centered" },
            [_c("AnimatedSpinner")],
            1
          )
        : _c("div", [_vm._v("Keine Daten")])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }