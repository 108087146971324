<template>
  <div>
    <AnlagezieleExpandable 
      :hasExtraFields="false" 
      :disabled="isDisabled || isRefusedPage" 
      :data="anlageziele" 
      :warnings="warningsProStep"
      ref="anlagezieleComp"
      @change="setChanged($event)" 
      @addZeile="addZeile($event)"
      @removeZeile="removeZeile($event)"
    />

    <div v-if="!isFA && data && data.anlageziele && data.anlageziele.zeigeZusatzESG">
      <InputRadioBoxGroup class="mt-4"
        title="Zu welchem Anteil sollen nachhaltige Finanzinstrumente (ESG) mindestens in Ihrem Portfolio enthalten sein?"
        v-model="esgAnteil" 
        :disabled="isDisabled || isRefusedPage" 
        :validateUntouched="true"
        :values="[
            {value: 'KEINER', label: 'Keine Vorgabe'},
            {value: 'MIND_20', label: '20%'},
            {value: 'MIND_40', label: '40%'},
            {value: 'MIND_60', label: '60%'},
            {value: 'MIND_80', label: '80%'}
        ]"
        @input="setChanged({ compId: 'INPUT_ANTEIL_ESG', value: $event })"/>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import ANLEGERPROFIL_TYPES from '@/store/anlegerprofil/types';
import anlegerprofilMixin from '@/mixins/anlegerprofil/anlegerprofil-mixin.js';
import AnlagezieleExpandable from '@/components/anlageziele/AnlagezieleExpandable.vue'
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';

export default {
  mixins: [anlegerprofilMixin],
  components: {
    AnlagezieleExpandable,
    InputRadioBoxGroup,
  },
  data() {
    return {
      rights: this.$store.state.core.loginData.rights,
      // warnings: [],
      esgAnteil: '',
    };
  },
   computed: {
    ...mapGetters({
      data: ANLEGERPROFIL_TYPES.GETTERS.ANLEGERPROFIL,
    }),
    isRefusedPage() {
      return this._isRefusedPage(this.data, 'anlageziele');
    },
    anlageziele() {
      return this.data?.anlageziele;
    },
  },
  mounted() {
    // this.warnings = this.getWarnings(true) || [];
    this.esgAnteil = this.data?.anlageziele && this.data?.anlageziele['INPUT_ANTEIL_ESG'];
  },
  methods: {
    setChanged(event) {
      this.$store.commit(ANLEGERPROFIL_TYPES.MUTATIONS.ANLAGEZIELE_UPDATE_DATA, event);
    },
    addZeile(event) {
      if (event && !this.isDisabled) {
        this.$store.commit(ANLEGERPROFIL_TYPES.MUTATIONS.ANLAGEZIELE_ADD_ZEILE, event);
      }
    }, 
    removeZeile(event) {
      if (event && !this.isDisabled) {
        this.doSave();
      }
    },
  },
}
</script>

<style scoped>
  .errorMessage {
    color: var(--color-danger);
    margin-left: 2px;
  }
  .successText {
    color: var(--color-success);
  }
</style>