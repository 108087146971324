<template>
<div>
    <Table v-if="!loading && rows.length"
        tableId="df281da6-df02-43ec-a3f1-146f4f93993f"
        ignoreTablePageSize
        rowId="index"
        :headers="headers"
        :rows="rows"
        :rowsPerPage="rowsPerPage"
        :tableRowsPerPage="[]"
        :mobileConfig="mobileConfig"
        @click-name="openCustomer"
    />
    <GhostLoading v-else-if="loading" type="table" :config="{ table: { rows: rowsPerPage, }, }" />
    <NoData v-else noIcon />
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import DEPOTPOSITIONS_TYPES from '@/store/depotpositions/types';
import CUSTOMER_SEARCH_TYPES from '@/store/customerSearch/types';
import CORE_TYPES from '@/store/core/types';

import Table from "@/components/table2/Table.vue";
import {TextColumn, DateColumn} from "@/components/table2/table_util.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';

const LAST_DAYS = 30;
const ROWS_PER_PAGE = 5;

export default {
    data() {
        return {
            loading: false,
            rowsPerPage: ROWS_PER_PAGE,
            headers: {
                center: [
                    DateColumn("date", "Datum"),
                    TextColumn("name", "Name").makeLink(),
                    TextColumn("lagerstelle", "Lagerstelle"),
                    TextColumn("depotnummer", "Depotnummer"),
                ],
            },
            mobileConfig: {
              title: '',
              headers: ["date","name","lagerstelle","depotnummer"],
            }
        };
    },
    computed: {
        ...mapGetters({
            brokerChanges: DEPOTPOSITIONS_TYPES.GETTERS.BROKER_CHANGES,
        }),
        rows() {
            return this.brokerChanges.map((brokerChange, index) => ({
                index,
                ...brokerChange,
                name: `${brokerChange.lastName || ''}, ${brokerChange.firstName || ''}`.trim(),
            }));
        },
    },
  methods: {
    async findBrokerChanges() {
      try {
        this.loading = true;
        await this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.RETRIEVE_BROKER_CHANGES, {
          lastDays: LAST_DAYS,
        })
      } finally {
        this.loading = false;
      }
    },
    openCustomer(brokerChange) {
      const customer = {
        customerID: brokerChange.customerID,
      };
      this.$store.commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.OPEN_CUSTOMER, { data: customer, });
      this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { 
        customerId: customer.customerID,
        path: '/home/vermogensubersicht/depot',
      });
    },
  },
  mounted() {
    this.findBrokerChanges();
  },
  components: {
    Table,
    GhostLoading,
    NoData,
  },
}
</script>
