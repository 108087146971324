<template>
  <div class="risiko-analyse-table-component">
    <div class="risiko-analyse-table-component-buttons" v-if="hasAnalyseTableHeaders">
      <BaseButton @click="handleErneutAnalysieren()" :disabled="loadingAntrag">Erneut Analysieren</BaseButton>
    </div>

    <hr v-if="hasAnalyseTableHeaders">

    <table class="risiko-analyse-table-component-table fc-table fc-table-divider" v-if="hasAnalyseTableHeaders">
      <thead>
        <tr>
          <th v-for="(header, headerIndex) in config.positionFields.analyse_table_headers" :key="headerIndex" class="text-right">
            {{ header.label }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, rowIndex) in config.positionFields.analyse_table_left_side_cols" :key="rowIndex">
          <td class="text-nowrap">
            {{ row.label }}
          </td>
          <td v-for="(col, colIndex) in tableColumns[rowIndex]" :key="colIndex" class="text-right">
            {{ textWithoutFormatting(col) }}
          </td>
        </tr>
      </tbody>
    </table>

    <h4 v-if="data" class="risiko-analyse-table-component-problems-title" 
      :class="data.probleme && !data.probleme.length ? 'color-success' : 'color-danger'">
      {{ data['DISPLAY_HEADER_PROBLEME'] }}
    </h4>

    <ul v-if="data.probleme && data.probleme.length" 
      class="risiko-analyse-table-component-problems-container list list-divider mb-0">
      <li v-for="(problem, problemIndex) in data.probleme" :key="problemIndex">
        <h5 class="mb-1 font-bold">{{ problem.LABEL }}</h5>
        <p class="mt-0 mb-3">{{ problem.ERRORMESSAGE }}</p>
        <div v-if="problem.buttons && problem.buttons.length > 0">
          <div v-for="(button, buttonIndex) in problem.buttons" 
            :class="buttonIndex > 0 ? 'mt-2' : ''" :key="buttonIndex">

            <a href="javascript:;" @click="handleButtonClick(button, problem)">{{ button.LABEL }}</a>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import ANTRAG_TYPES from "@/store/antrag/types";
import CORE_TYPES from '@/store/core/types';
import BaseButton from '@/components/core/BaseButton.vue';
import { mapGetters } from "vuex";
import axios from 'axios';
import FormUtil from '@/components/beratung/formsMenu/forms-menu.js';

export default {
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
      required: true,
    },
    antragId: {
      type: String
    },
    antragName: {
      type: String
    },
    antragTyp: {
      type: String,
      default: 'UNBEKANNT',
    },
    config: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      default: {},
    },
  },
  mounted() {
    if (this.$route.query?.backAction) {
      setTimeout(() => {
        this.handleErneutAnalysieren();
      }, 500);
      this.$route.query.backAction = false;
    }
  },
  data() {
    return {
      loadingAntrag: false,
    }
  },
  computed: {
    ...mapGetters({
      antragList: ANTRAG_TYPES.GETTERS.ANTRAG_LIST,
      isFA: CORE_TYPES.GETTERS.IS_FA,
    }),    
    antragLagerstelle() {
      return this.isFA ? 'FA' : 'FK';
    },
    hasAnalyseTableHeaders() {
      return this.config && this.config.positionFields && this.config.positionFields.analyse_table_headers;
    },
    lagerstelle() {
      return this.$route.params.lagerstelle;
    },
    antragsName() {
      return this.$route.params.antragsName;
    },
    columnsRow1() {
      const columns = [];

      columns.push(this.data['DISPLAY_BEST_WERT_EINMAL']);
      columns.push(this.data['DISPLAY_NEU_VERK_WERT_EINMAL']);
      if(this.data['DISPLAY_CASH_WERT_EINMAL']) {
        columns.push(this.data['DISPLAY_CASH_WERT_EINMAL']);
      }
      columns.push(this.data['DISPLAY_NEU_WERT_EINMAL']);
      columns.push(this.data['DISPLAY_GESAMT_WERT_EINMAL']);
      columns.push(this.data['DISPLAY_NEU_MAX_WERT_EINMAL']);
      columns.push(this.data['DISPLAY_GESAMT_MAX_WERT_EINMAL']);

      return columns;
    },
    columnsRow2() {
      const columns = [];

      columns.push(' ');
      columns.push(this.data['DISPLAY_NEU_VERK_WERT_SPARPLAN']);
      columns.push(this.data['DISPLAY_NEU_WERT_SPARPLAN']);
      columns.push(this.data['DISPLAY_GESAMT_WERT_SPARPLAN']);
      columns.push(this.data['DISPLAY_NEU_MAX_WERT_SPARPLAN']);
      columns.push(this.data['DISPLAY_GESAMT_MAX_WERT_SPARPLAN']);

      return columns;
    },
    columnsRow3() {
      if(!this.data['mitRisikokennzahl']) {
        return [];
      }

      const columns = [];

      columns.push(this.data['DISPLAY_BEST_AVG_SRI']);
      columns.push(' ');
      columns.push(' ');
      columns.push(this.data['DISPLAY_GESAMT_AVG_SRI']);
      columns.push(' ');
      columns.push(this.data['DISPLAY_GESAMT_MAX_RKZ']);

      return columns;
    },
    tableColumns() {
      return [this.columnsRow1, this.columnsRow2, this.columnsRow3];
    },
    beratungsMappeId() {
      return this.data && this.data['beratungsMappeId'] || '';
    },
    currentFormLink() {
      return `/beratung/formulare/antrag/${this.lagerstelle}/${this.antragsName}`;
    }
  },
  methods: {
    textWithoutFormatting(text) {
      const textParts = /\](.+)(?=\[)/.exec(text);
      if (textParts && textParts.length) {
        return textParts[1];
      } else {
        return text;
      }
    },
    openLink(link, params) {
      const defaultParams = {
        beratungsMappeId: this.beratungsMappeId,
      };
      
      this.$router.push({ 
        path: link, 
        query: Object.assign(defaultParams, params),
      });
    },
    handleErneutAnalysieren() {
      const payload = {
        antragsName: this.antragsName,
        lagerstelle: this.lagerstelle,
        id: this.antragId,
        beratungsMappeId: this.beratungsMappeId,
      };

      this.loadingAntrag = true;
      this.$store.dispatch(ANTRAG_TYPES.ACTIONS.GET_ANTRAG, payload).then(() => {
        this.loadingAntrag = false;
      });
    },
    handleButtonClick(button, problem) {
      const { ACTION, POSITIONTYP, STEP } = button;

      if (ACTION == 'ACTION_OPEN_ANTRAG' && (POSITIONTYP == 'FK_ERSTINFORMATION' || POSITIONTYP == 'FA_INFORMATIONSBLATT')) {
        this.openErstinformation(button);

      } else if (ACTION == 'ACTION_OPEN_ANTRAG') {
        this.openAntrag(button, problem.LABEL);

      } else if (ACTION == 'ACTION_OPEN_PROTO_BERATUNG') {
        this.openDokumentationGrundlage(STEP);

      } else if(ACTION == 'ACTION_OPEN_PROTO_DOKU') {
        this.openDokumentationErgebnis(STEP);

      } else if(ACTION == 'ACTION_OPEN_AP') {
        this.openAnlegerProfil(STEP);

      } else if(ACTION == 'ACTION_ALLOCATE_SUMS') {
        this.allocateBeratunsgrundlageSums();
        
      } else if(ACTION == 'ACTION_SET_NACHHALTIGKEIT_OK') {
        this.openDokumentationErgebnis(STEP, {setCheckNachhaltigkeit: '1'})

      } else if (ACTION === 'ACTION_OPEN_ALLOKATION') {
        const antraege = this.$store.getters.BERATUNGSMAPPE_GETTERS_BERATUNGSMAPPE_CARDS?.antraege?.antraegeList;
        const positions = this.$store.getters.BERATUNGSMAPPE_GETTERS_BERATUNGSMAPPE_CARDS?.antraege?.orderPositionen;

        // we choose the antrag with the highest number of positions
        if (antraege?.length && positions?.length) {
          const result = antraege.reduce((max, antrag) => {
            const currentCount = positions.filter(pos => pos.antragBezeichnung === antrag.Antrag && pos.antragGesellschaft === antrag.GesellschaftShort).length

            if (max) {
              const currentMaxCount = positions.filter(pos => pos.antragBezeichnung === max.Antrag && pos.antragGesellschaft === max.GesellschaftShort).length
              return currentMaxCount > currentCount ? max : antrag
            } else {
              return currentCount ? antrag : null
            }
          });

          if (result) {
            this.openAntrag({ GESELLSCHAFT: result.GesellschaftShort, ANTRAGDATENID: result.antragsDatenId, selectStep: 'wertpapier' }, result.Antrag);
          } else {
            this.$router.push(`/beratung/beratung/bearbeiten?id=${this.beratungsMappeId}`)
          }
        } else if (positions?.length) {
          this.$router.push(`/beratung/beratung/bearbeiten?id=${this.beratungsMappeId}`)
        }
      } else {
        this.actionButton(button);
      }
    },
    actionButton(buttonData) {
      // this action requiring calling that "big send email form" which cannot be implemented right now
      if (buttonData.ACTION === 'ACTION_MAIL_DOKUMENTE') {
        return;
      }

      const actionData = {
        ACTION: buttonData.ACTION,
      };

      if (buttonData.PARAMETER_SET_DOKUMENTE) {
        actionData['PARAMETER_SET_DOKUMENTE'] = buttonData.PARAMETER_SET_DOKUMENTE;
      }

      if (buttonData.PARAMETER_SET_ERKLAERUNG) {
        actionData['PARAMETER_SET_ERKLAERUNG'] = buttonData.PARAMETER_SET_ERKLAERUNG;
      }

      if (buttonData.PARAMETER_SET_KEUER_OK) {
        actionData['PARAMETER_SET_KEUER_OK'] = buttonData.PARAMETER_SET_KEUER_OK;
        actionData['PARAMETER_SET_KEUER_OK_DI1'] = buttonData.PARAMETER_SET_KEUER_OK_DI1;
        actionData['PARAMETER_SET_KEUER_OK_DI2'] = buttonData.PARAMETER_SET_KEUER_OK_DI2;
        actionData['PARAMETER_SET_KEUER_OK_VM1'] = buttonData.PARAMETER_SET_KEUER_OK_VM1;
        actionData['PARAMETER_SET_KEUER_OK_VM2'] = buttonData.PARAMETER_SET_KEUER_OK_VM2;

        if (buttonData.PARAMETER_SET_KEUER_STUFE_NOTWENDIG) {
          actionData['PARAMETER_SET_KEUER_STUFE_NOTWENDIG'] = buttonData.PARAMETER_SET_KEUER_STUFE_NOTWENDIG;
        }
      }

      actionData['beratungsMappeId'] = this.beratungsMappeId;

      this.$store.dispatch(ANTRAG_TYPES.ACTIONS.PATCH_ANTRAG, { 
        data: actionData,
        id: this.antragId, 
        lagerstelle: this.lagerstelle, 
        antragTyp: this.antragTyp, 
        beratungsMappeId: this.beratungsMappeId, 
        positions: null, 
        positionClass: null
      }).then(r => this.handleErneutAnalysieren());


    },
    openErstinformation(buttonData) {
      this.openLink(`/beratung/formulare/antrag/${this.antragLagerstelle}/kundenerstinformation`, {
        antragsnrIntern: buttonData['ANTRAGDATENID']
      });
    },
    openAntrag(buttonData, problemLabel) {

     const antrag = {
        beratungsMappeId: this.beratungsMappeId,
        bezeichnung: problemLabel,
        gesellschaft: buttonData['GESELLSCHAFT'],
        antragsdatenId: buttonData['ANTRAGDATENID'],
        antragModus: buttonData['antragModus'],
        selectStep: buttonData['selectStep'],
      }

      const link = FormUtil.getLoadURL(antrag, this.antragList.formGroups);

      if (link) {
        this.$router.push({path: link}).catch(() => {});
      } else {
        this.$router.push({path: '/role-forbidden'});
      }
    },
    openAnlegerProfil(path) {
      if(this.$route.query.isWk === "true") {
        this.openLink(`/beratung/anlegerprofilWK/${path ? path : 'person1/auswahl/personenauswahl'}`, {schiene: 'WEALTH_KONZEPT'});
      }
      else {
        this.openLink(`/beratung/anlegerprofil/${path ? path : 'person1/auswahl/personenauswahl'}`);
      }
    },
    allocateBeratunsgrundlageSums() {
      let serviceUrl = `/antragButton/allocateSums?beratungsMappeId=${this.beratungsMappeId}`

      this.$confirmModal({
            title: 'Bestätigung', 
            message: 'Sollen die erlaubten Beträge in der Gesprächsdokumentation dem Zufluss automatisch angeglichen werden?', 
            labelButtonConfirm: 'Ja',
            labelButtonCancel: 'Abbrechen',
            })
            .then(() => {
              axios.post(`${process.env.VUE_APP_API}` + serviceUrl, {}, { defaultSpinner: true }).then(() =>{
                this.reloadAntrag()
              })
            })


    },
    reloadAntrag() {
      return this.$store.dispatch(ANTRAG_TYPES.ACTIONS.RELOAD_ANTRAG, {
        id: this.antragId,
      });
    },
    openDokumentationGrundlage(stepKey) {
      this.openLink(`/beratung/formulare/antrag/${this.antragLagerstelle}/dokumentationgrundlage/${stepKey ? stepKey : 'aktionen'}`);
    },
    openDokumentationErgebnis(stepKey, params) {
      this.openLink(`/beratung/formulare/antrag/${this.antragLagerstelle}/dokumentationergebnis${stepKey ? ('/' + stepKey) : ''}`, params);
    },
    retrieveAntragList() {
      this.$store.dispatch(ANTRAG_TYPES.ACTIONS.RETRIEVE_ANTRAG_LIST);
    },        
  },
  components: {
    BaseButton,
  }
}
</script>

<style scoped>
.risiko-analyse-table-component-table td,
.risiko-analyse-table-component-table th {
  padding: 6px;
}
</style>
