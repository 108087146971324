<template>
  <div>
    <template v-if="isPageAvailableOnSmallScreen">
      <slot></slot>
    </template>
    <template v-else>
      <div class="box__container">
        <div>
          <p>
            Diese Seite ist auf mobilen Geräten nicht verfügbar. Bitte nutzen Sie diese Funktion auf einem großen Tablet oder Desktop Computer.
          </p>
          <p>
            Vielen Dank!
          </p>
          <UndrawInTheOffice />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import UndrawInTheOffice from '@/components/icons/undraw/UndrawInTheOffice.vue'

export default {
  components: {
    UndrawInTheOffice,
  },
  computed: {
    isPageAvailableOnSmallScreen() {
      if (!this.$isSmallScreen) {
        return true;
      }

      return !this.$route.matched.some(({ meta }) => meta && meta.requiresLargeScreen);
    }
  }
} 
</script>

