<template>
  <div class="input-forms__container">
    <label v-if="label" class="input-forms__label-container">
      <div class="input-forms__label-content" v-html="sanitize(label)"></div>
    </label>

    <FormInputDokNodePermissionItem :values="checkBoxValues" :value="value" :disabled="disabled" @input="onInput($event)" />
  </div>
</template>

<script>
import FormInputDokNodePermissionItem from './FormInputDokNodePermissionItem.vue';
import validatorComponentUtils from '@/mixins/validator/validator-component-utils'
import {sanitize} from '@/helpers/string-helper.js';
import { isHiddenDisabled } from '@/components/antrag/antrag-utils.js';

export default {
  mixins: [validatorComponentUtils],
  props: {
    label: {
      type: String,
      default: ''
    },
    componentFields: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    values() {
      return this.componentFields.map(field => ({
        id: field.id,
        label: field.label,
        dokNodeId: field?.config?.dokNodeId,
        parentId: field?.config?.parentId,
        disabled: isHiddenDisabled(field?.disabled, this.value),
      }));
    },
    checkBoxValues() {
      const mapIdToPath = (val) => ({
        ...val,
        path: val.id,
      });

      const { values } = this;
      const parents = values.filter(v => values.findIndex(v2 => v2.dokNodeId === v.parentId) < 0).map(mapIdToPath);

      const addChildren = (parent) => {
        parent.children = values.filter(v => v.parentId === parent.dokNodeId).map(mapIdToPath);
        parent.children.forEach(child => addChildren(child));
      };

      parents.forEach(parent => addChildren(parent))

      return parents;
    },
  },
  methods: {
    sanitize(htmlString) {
      return sanitize(htmlString);
    },
    onInput(value) {
      this.$emit('input', value);
    },
  },
  components: {
    FormInputDokNodePermissionItem,
  },
}
</script>
