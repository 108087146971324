<template>
  <div class="box__container">
    <div class="box__title">Stammdaten</div>
    <div class="row">
      <div class="col-12">
        <template v-if="!isMainPerson">
          <PersonBeziehungFields 
            :relationship="relationship" 
            isComponentHalfSize 
            @change="addRelationshipDataChanged($event)" 
          />
          
          <InputField v-if="relationship.relation && relationship.relation.value==='WIRTSCHAFTLICHER_EIGENTUEMER'"
            label="%-Angabe der wirtschaftlichen Beteiligung" 
            :isComponentHalfSize="true"
            v-model="relationship.relation.beteiligung"
            :disabled="!isEditable"
            @change="addRelationshipDataChanged(relationship)" />
          <hr/>
        </template>

        <InputToggleSwitch label="Synchronisation" 
            :inLineLabel="true"
            v-model="personalDataAddress.synchronisation"
            v-if="hatSyncUser" 
            @input="addCustomerDataEditedCombo('personalDataAddress');" />        

        <InputToggleSwitch v-if="isMainPerson && isByPass"
          label="Kundendaten werden durch automatische Updates angepasst / überschrieben" 
          :inLineLabel="true"
          v-model="personalDataAddress.automaticUpdated"
          :disabled="!isEditable"
          @input="addCustomerDataEditedCombo('personalDataAddress');" />

        <InputToggleSwitch v-if="isMainPerson && isByPass"
                           label="Person darf nicht vereinigt werden."
                           :inLineLabel="true"
                           v-model="disableUnitPerson"
                           :disabled="!isEditable"
                           v-fc-tooltip="'Wenn diese Person bei einer anderen Kundennummer nochmal existiert,\n dann darf diese Person mit der anderen Person NICHT vereinigt werden!'"
        />


        <InputToggleSwitch v-if="isIntern && showRisikolevel"
          label="Risikoeinstufung: manuelle Zuweisung" 
          :inLineLabel="true"
          v-model="personalDataAddress.isRisikolevelManuel"
          @input="addCustomerDataEditedCombo('personalDataAddress')" />
        <InputRadioBoxGroup v-if="showRisikolevel"
          title="Risikoeinstufung (GWG)"
          v-model="personalDataAddress.risikolevel" 
          isComponentHalfSize
          :disabled="!isRisikolevelEditable"
          :vertical="false"
          :values="[
              {value: '1', label: '1'},
              {value: '2', label: '2'},
              {value: '3', label: '3'}
          ]"
          @input="addCustomerDataEdited('personalDataAddress')" />

        <InputField v-if="isInvers && isByPass"
          label="Risikokennzahl" 
          v-model="personalDataAddress.risikokennzahl"
          :isComponentHalfSize="true"
          :disabled="!isEditable"
          type="number"
          :precision="1"
          @change="addCustomerDataEdited('personalDataAddress')"/>

        <ComboBox v-if="isByPass"
          label="Anredeform" 
          :isComponentHalfSize="true"
          :values="anredePronomen" 
          v-model="personalDataAddress.anredePronomen"
          :disabled="!isEditable"
          :firstEmpty="!isNewPerson && !personalDataAddress.anredePronomen"
          @change="addCustomerDataEditedCombo('personalDataAddress', 'anredePronomen', $event)"/>

        <ComboBox label="Anrede" 
          :labelClass="depoteroeffnungenLabelClass"
          :isComponentHalfSize="true"
          :values="customerDataConfig.genders" 
          v-model="personalDataAddress.title"
          :disabled="!isEditable"
          :firstEmpty="!isNewPerson && !personalDataAddress.title"
          @change="addCustomerDataEditedCombo('personalDataAddress', 'title', $event)"/>
        <InputField label="Titel" 
          :isComponentHalfSize="true"
          v-model="personalDataAddress.titles"
          :disabled="!isEditable"
          @change="addCustomerDataEdited('personalDataAddress')"/>
        <InputField label="Vorname" 
          :isComponentHalfSize="true"
          v-model="personalDataAddress.firstName"
          :disabled="!isEditable"
          @change="addCustomerDataEdited('personalDataAddress')"/>
        <InputField data-field-target="lastNameField"
          label="Name" 
          :labelClass="depoteroeffnungenLabelClass"
          :isComponentHalfSize="true"
          v-model="personalDataAddress.lastName"
          :disabled="!isEditable"
          validateUntouched
          @change="addCustomerDataEdited('personalDataAddress')"/>
        <InputField label="c/o" 
          :isComponentHalfSize="true"
          v-model="personalDataAddress.co"
          :disabled="!isEditable"
          @change="addCustomerDataEdited('personalDataAddress')"/>
        <ComboBox label="Familienstand" 
          :labelClass="depoteroeffnungenLabelClass"
          :isComponentHalfSize="true"
          :values="customerDataConfig.personalStates" 
          v-model="personalDataAddress.personalStatus"
          :disabled="!isEditable"
          @change="addCustomerDataEditedCombo('personalDataAddress', 'personalStatus', $event)"/>

        <template v-if="isByPass">
          <InputField v-if="isMainPerson" 
            label="Briefanrede Depot"
            :isComponentHalfSize="true" 
            v-model="personalDataAddress.briefanredeDepot"
            :disabled="!isEditable"
            @change="addCustomerDataEditedCombo('personalDataAddress')" />

          <InputField label="Briefanrede Person"
            :isComponentHalfSize="true" 
            v-model="personalDataAddress.briefanredePerson"
            :disabled="!isEditable"
            @change="addCustomerDataEditedCombo('personalDataAddress')" />

          <InputToggleSwitch
            label="Person verstorben" 
            :inLineLabel="true"
            v-model="personalDataAddress.personDeceased"
            :disabled="!isEditable"
            @input="addCustomerDataEditedCombo('personalDataAddress'); handlePersonDeceased($event)" />
          
          <DatePickerField v-if="personalDataAddress.personDeceased"
            label="Sterbedatum"
            isValueAsString
            :isComponentHalfSize="true"
            v-model="personalDataAddress.dateOfDeath"
            :disabled="!isEditable"
            @input="addCustomerDataEdited('personalDataAddress'); handleDateOfDeath($event)"/>
          
          <InputField label="Kundenreferenznr."
            :isComponentHalfSize="true" 
            v-model="kundenReferenzNummer"
            :disabled="!isEditable"
            @change="addKundenReferenzNummerDataChanged($event)" />

          <InputField label="Gruppenmitglied von."
            :isComponentHalfSize="true" 
            v-model="gruppenMitglied"
            disabled
            @change="addKundenReferenzNummerDataChanged($event)" />


          <template v-if="isMainPerson">
            <ComboBox 
              label="Kundenkategorie"
              :isComponentHalfSize="true"
              :values="customerDataConfig.customerCategories" 
              v-model="personalDataAddress.category"
              :disabled="!isEditable"
              @change="addCustomerDataEditedCombo('personalDataAddress', 'category', $event)"/>

            <DatePickerField
              v-if="personalDataAddress.dateCreated"
              label="Anlagedatum des Kunden"
              isValueAsString
              :isComponentHalfSize="true"
              v-model="personalDataAddress.dateCreated"
              disabled/>  

            <InputToggleSwitch 
              label="Interessent" 
              :inLineLabel="true"
              v-model="personalDataAddress.interested"
              :disabled="!isEditable"
              @input="addCustomerDataEditedCombo('personalDataAddress');" />

            <InputToggleSwitch 
              label="automatische Anpassung sperren" 
              :inLineLabel="true"
              v-model="personalDataAddress.categoryAutoLock"
              :disabled="!isEditable"
              @input="addCustomerDataEditedCombo('personalDataAddress');" />

              <ComboBox 
              label="Betreuer"
              :isComponentHalfSize="true"
              :values="customerBetreuer" 
              v-model="personalDataAddress.betreuer"
              :disabled="!isEditable"
              @change="addCustomerDataEditedCombo('personalDataAddress', 'betreuer', $event)"/>

            <InputTextArea 
              label="Anmerkungen (für den Kunden nicht sichtbar)"
              :isComponentHalfSize="true"
              v-model="personalDataAddress.interneBemerkung"
              :disabled="!isEditable"
              :rows="5"
              :maxlength="4000"
              @change="addCustomerDataEdited('personalDataAddress')"/>
          </template>
        </template>
        
        <UserPicture v-if="isMainPerson" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CUSTOMERDATA_TYPES from '@/store/customerData/types';

import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import UserPicture from './UserPicture.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import PersonBeziehungFields from '../zusaetzliche-personen/PersonBeziehungFields.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';

import persoenlicheDatenMixin from '../persoenliche-daten-mixin';
import validator from '@/mixins/validator';
import { range } from '@/mixins/validator/rules';
import axios from "axios";

const HINT_GROUP = 'PRIVAT_STAMMDATEN';

export default {
  mixins: [persoenlicheDatenMixin, validator],
  validators: {
    personalDataAddress : {
      risikokennzahl: [range(0,5)],
    },
  },
  data() {
    return {
      ...this.initialState(),
      disableUnitPerson: false,
    };
  },
  computed: {
    ...mapGetters({
      personId: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_ID,
    }),
  },
  watch: {
    disableUnitPerson(newValue){
      console.log(this.apiAddress);
      let url = process.env.VUE_APP_API + "/KundenService/setDisableUnit";
      axios({
        method: "post",
        url: url,
        data: {
          value: newValue,
        }
      })
        .then((response) => {
          console.log(response);
        }, (error) => {
          console.log(error)
        });
    }
  },
  methods: {
    initialState() {
      return {
        personalDataAddress: {
          anredePronomen: '',
          title: '',
          titles: '',
          firstName: '',
          lastName: '',
          street: '',
          zip: '',
          city: '',
          country: '',
          personalStatus: '',
          co: '',
          state: '',
          briefanredeDepot: '',
          briefanredePerson: '',
          personDeceased: false,
          dateOfDeath: null,
          interneBemerkung: '',
          category: '',
          betreuer: '',
          interested: '',
          categoryAutoLock: '',
          automaticUpdated: true,
          dateCreated: null,
          synchronisation: false,
          risikolevel: '',
          isRisikolevelManuel: false,
          hintRisikolevel: '',
          risikokennzahl: null,
        },
        relationship: {
          relation: null,
          isLegalAgent: false,
          isExtraDepotOwner: false,
          isMandatory: false,
        },
        kundenReferenzNummer: '',
        gruppenMitglied: '',
      };
    },
    updateGroupsIntoData() {
      this.updateGroupIntoData('personalDataAddress');
      this.updateGroupIntoData('relationship');
      this.updateFieldIntoData('kundenReferenzNummer');
      this.updateFieldIntoData('gruppenMitglied');
    },
    handlePersonDeceased(value) {
      if(!value) {
        this.personalDataAddress.dateOfDeath = null;
        this.addCustomerDataEditedCombo('personalDataAddress');
      }
    },
    handleDateOfDeath(value) {
      if(value) {
        this.personalDataAddress.personDeceased = true;
        this.addCustomerDataEditedCombo('personalDataAddress');
      }
    },
    addKundenReferenzNummerDataChanged(value) {
      this.addCustomerDataEdited('kundenReferenzNummer', value);
    },
    addHints() {
      if (this.isMainPerson && this.isByPass) {
        this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.ADD_HINT, {
          group: HINT_GROUP,
          title: 'Kundendaten werden durch automatische Updates angepasst / überschrieben',
          message: ''
                  + "Diese Option muss <b>abgewählt</b> sein, falls Sie die persönlichen Daten Ihrer Kunden ändern wollen, da diese sonst mit der täglichen Datenlieferung der Lagerstellen wieder überschrieben werden."
        });
      }
      if (this.showRisikolevel) {
        if (this.isIntern) {
          this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.ADD_HINT, {
            group: HINT_GROUP,
            title: 'Manuelle Zuweisung',
            message: 'Die automatische Einstufung wird deaktiviert, der Kunde muss manuell verwaltet werden.'
          });
        }
        this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.ADD_HINT, {
          group: HINT_GROUP,
          title: 'Risikoeinstufung (GWG)',
          message: ''
                    + "Laut Geldwäschegesetz (GwG)  muss jeder Verpflichtete seine Kunden gemäß dem Risiko der Geldwäsche einstufen."
                    + " Folgende Einstufungen werden automatisch gesetzt:<br>"
                    + "<b>Risiko 1</b> für alle Kunden mit Wohnsitz EWR / EU</b>;<br>"
                    + "<b>Risiko 2</b> für alle Kunden die nicht unter Risiko 3 fallen und einen Depotinhaber haben, der in einem Land wohnt das nicht EWR / EU ist;<br>"
                    + "<b>Risiko 3</b> für alle Kunden die einen PEP-Status (siehe Kundendaten/Sonstiges 2) haben und alle Kunden mit einem Depotinhaber oder Bankkonto in den Ländern: "
                    + this.hintRisikolevel
        });
      }
      if (this.isByPass && this.isMainPerson) {
        this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.ADD_HINT, {
          group: HINT_GROUP,
          title: 'Briefanrede Depot',
          message: ''
            + 'Dieses Feld ersetzt die automatisch generierte Anrede incl. Herr/Frau und dem Titel. ' 
            + '<b>Daher müssen Sie hier auch den Namen einfügen, falls Sie dieses Feld verändern möchten.</b>'
        });
      }
      if (this.isByPass) {
        this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.ADD_HINT, {
          group: HINT_GROUP,
          title: 'Briefanrede Person',
          message: ''
            + 'Dieses Feld wird verwendet, wenn die Hauptperson des Kunden angesprochen wird (z.B. bei Geburtstagsglückwünschen). ' 
            + 'Es ersetzt die automatisch generierte Anrede incl. Herr/Frau und dem Titel. '
            + '<b>Daher müssen Sie hier auch den Namen einfügen, falls Sie dieses Feld verändern möchten.</b>'
        });

      }

      if(this.isMainPerson && this.isByPass) {
        this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.ADD_HINT, {
          group: HINT_GROUP,
          title: 'Kundenkategorie',
          message: ''
            + "Hier können Sie ihre Kunden in die Gruppen A-D und I einteilen und später in der Kundensuche danach sortieren. Die Gruppe I steht für Interessent.<br>"
            + "Bitte beachten Sie dazu auch die Einstellungen unter <b><i>Daten/Einstellungen/Kundenkategorie</i></b> im Hauptmenu (MSC).<br>"
            + "Sollte die dynamische Anpassung aktiviert sein und 'automatische Anpassung sperren' nicht angewählt sein, so wird die Kategorie täglich überprüft und ggf. aktualisiert."
        });
      }
      if (this.isByPass) {
        this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.ADD_HINT, {
          group: HINT_GROUP,
          title: 'Person verstorben und Sterbedatum',
          message: ''
            + 'Ist dieser Punkt gesetzt, werden folgende Mails <b>nicht</b> an den Kunden verschickt:<br>' 
            + ' - Geburtstagsemail<br>'
            + ' - Geburtstagsliste aller Kunden (an Vermittler)<br>'
            + ' - Erinnerungen bei abgelaufenen Legitimationsurkunden<br>'
        });

        this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.ADD_HINT, {
          group: HINT_GROUP,
          title: 'Kundenreferenznummer',
          message: ''
                    + "Hier können Sie eine evtl. vorhandene, eigene Kundennummer hinterlegen.<br>"
                    + "Diese erscheinen dann u.a. auf den Bestands- und Kundenlisten."
        });
      }
    },
    getDisableUnitPerson(){
      let url = process.env.VUE_APP_API + "/KundenService/getDisableUnit";
      axios({
        method: "get",
        url: url,
      })
      .then((response) => {
        this.disableUnitPerson = response.data;
      }, (error) => {
        console.log(error)
      });
    }
  },
  mounted() {
    this.updateGroupsIntoData();
    this.addHints();
  },
  beforeDestroy() {
    this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.REMOVE_HINTS, { group: HINT_GROUP, });
  },
  components: {
    InputField,
    ComboBox,
    DatePickerField,
    InputToggleSwitch,
    UserPicture,
    InputTextArea,
    PersonBeziehungFields,
    InputRadioBoxGroup,
  },
}
</script>
