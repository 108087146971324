var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "statusModal",
          attrs: {
            modalTitle: "Vertragsstatus",
            confirmDisabled: _vm.isFormInvalid,
            labelButtonConfirm: "Status speichern",
            autoClose: false
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.onConfirmModal()
            },
            onCancelButton: function($event) {
              return _vm.onCancelModal()
            }
          }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("InputField", {
                  attrs: { label: "Zugeh. Vertrag", disabled: true },
                  model: {
                    value: _vm.statusForm.beschreibung,
                    callback: function($$v) {
                      _vm.$set(_vm.statusForm, "beschreibung", $$v)
                    },
                    expression: "statusForm.beschreibung"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("InputField", {
                  attrs: { label: "Datum eingelesen", disabled: true },
                  model: {
                    value: _vm.vertragStatus.datumEingelesen,
                    callback: function($$v) {
                      _vm.$set(_vm.vertragStatus, "datumEingelesen", $$v)
                    },
                    expression: "vertragStatus.datumEingelesen"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("ComboBox", {
                  attrs: {
                    label: "Status",
                    values: _vm.statusForm.statusComboboxValues,
                    firstEmpty: true,
                    validateUntouched: true
                  },
                  model: {
                    value: _vm.vertragStatus.status,
                    callback: function($$v) {
                      _vm.$set(_vm.vertragStatus, "status", $$v)
                    },
                    expression: "vertragStatus.status"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("DatePickerField", {
                  attrs: { label: "Gültig ab", validateUntouched: true },
                  model: {
                    value: _vm.vertragStatus.gueltigab,
                    callback: function($$v) {
                      _vm.$set(_vm.vertragStatus, "gueltigab", $$v)
                    },
                    expression: "vertragStatus.gueltigab"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("ComboBox", {
                  attrs: {
                    label: "Änderungsgrund",
                    values: _vm.statusForm.aenderungsgrundComboboxValues,
                    firstEmpty: true,
                    validateUntouched: true
                  },
                  model: {
                    value: _vm.vertragStatus.aendergrund,
                    callback: function($$v) {
                      _vm.$set(_vm.vertragStatus, "aendergrund", $$v)
                    },
                    expression: "vertragStatus.aendergrund"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("InputTextArea", {
                  attrs: { label: "Änderungsdetails" },
                  model: {
                    value: _vm.vertragStatus.aenderdetails,
                    callback: function($$v) {
                      _vm.$set(_vm.vertragStatus, "aenderdetails", $$v)
                    },
                    expression: "vertragStatus.aenderdetails"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("DatePickerField", {
                  attrs: { label: "Durchführungsdatum" },
                  model: {
                    value: _vm.vertragStatus.durchgef,
                    callback: function($$v) {
                      _vm.$set(_vm.vertragStatus, "durchgef", $$v)
                    },
                    expression: "vertragStatus.durchgef"
                  }
                })
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }