var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("InputField", {
        attrs: {
          label: "Miete",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.isDisabled || _vm.isRefusedPage
        },
        on: {
          input: function($event) {
            return _vm.updateStore("finance", "rent", $event || 0)
          }
        },
        model: {
          value: _vm.rent,
          callback: function($$v) {
            _vm.rent = $$v
          },
          expression: "rent"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Leasing",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.isDisabled || _vm.isRefusedPage
        },
        on: {
          input: function($event) {
            return _vm.updateStore("finance", "leasing", $event || 0)
          }
        },
        model: {
          value: _vm.leasing,
          callback: function($$v) {
            _vm.leasing = $$v
          },
          expression: "leasing"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Kreditrückzahlung",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.isDisabled || _vm.isRefusedPage
        },
        on: {
          input: function($event) {
            return _vm.updateStore("finance", "creditPayment", $event || 0)
          }
        },
        model: {
          value: _vm.creditPayment,
          callback: function($$v) {
            _vm.creditPayment = $$v
          },
          expression: "creditPayment"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Haushalt",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.isDisabled || _vm.isRefusedPage
        },
        on: {
          input: function($event) {
            return _vm.updateStore("finance", "household", $event || 0)
          }
        },
        model: {
          value: _vm.household,
          callback: function($$v) {
            _vm.household = $$v
          },
          expression: "household"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Unterhalt",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.isDisabled || _vm.isRefusedPage
        },
        on: {
          input: function($event) {
            return _vm.updateStore("finance", "maintainance", $event || 0)
          }
        },
        model: {
          value: _vm.maintainance,
          callback: function($$v) {
            _vm.maintainance = $$v
          },
          expression: "maintainance"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Versicherungsbeiträge",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.isDisabled || _vm.isRefusedPage
        },
        on: {
          input: function($event) {
            return _vm.updateStore("finance", "insurancePayment", $event || 0)
          }
        },
        model: {
          value: _vm.insurancePayment,
          callback: function($$v) {
            _vm.insurancePayment = $$v
          },
          expression: "insurancePayment"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Sonstiges",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.isDisabled || _vm.isRefusedPage
        },
        on: {
          input: function($event) {
            return _vm.updateStore("finance", "otherPayment", $event || 0)
          }
        },
        model: {
          value: _vm.otherPayment,
          callback: function($$v) {
            _vm.otherPayment = $$v
          },
          expression: "otherPayment"
        }
      }),
      _vm.showHinweisBetragNiedrig
        ? _c("InputTextArea", {
            attrs: {
              label: _vm.labelHinweisBetragNiedrig,
              disabled: _vm.isDisabled || _vm.isRefusedPage
            },
            on: {
              change: function($event) {
                return _vm.updateStore(
                  "finance",
                  "textHinweisBetragNiedrig",
                  $event
                )
              }
            },
            model: {
              value: _vm.textHinweisBetragNiedrig,
              callback: function($$v) {
                _vm.textHinweisBetragNiedrig = $$v
              },
              expression: "textHinweisBetragNiedrig"
            }
          })
        : _vm._e(),
      _c("div", { staticClass: "sum d-flex justify-content-between" }, [
        _c("div", { staticClass: "my-2" }, [
          _vm._v("regelmäßige finanzielle Verpflichtungen")
        ]),
        _c(
          "div",
          { staticClass: "my-2 pr-3" },
          [_c("CurrencyLabel", { attrs: { value: _vm.summeAusgaben } })],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }