<template>
	<CoreCard ref="coreCard" :showToolbar="showToolbar" :listView="listView">
		<template v-if="showTitle" v-slot:header>
			{{chartName}}
		</template>
		<template v-slot:alternative>
		</template>
    <div v-if="details.length">
      <div class="row my-0 mx-3" v-for="(item, i) in details" :key="i">
        <div class="col">{{item.key}}</div>
        <div class="col-auto right">{{item.value}}</div>
      </div>
    </div>
	</CoreCard>
</template>

<script>
import CoreCard from '../CoreCard/CoreCard';
import { formatNumber } from '@/helpers/number-formatter.js';
import cardMixin from '../CoreCard/card-mixin';


export default {
  mixins: [cardMixin],

  data: function() {
    return {
      isContainerRendered: false,
    };
  },

  computed: {
    chartName: function() {
      return this.propsData.option;
    },
    data: function() {
      return this.propsData.data;
    },
    customColors: function() {
      return this.propsData.customColors;
    },
    details() {
      const key = this.chartName === 'Bestandsübersicht' ? 'inventory' : 'products'
      const details = this.data.data.find(v => v.id === key)
      return details && details?.data?.data || []
    }
  },

  mounted: function () {
    this.$nextTick(function () {
      this.isContainerRendered = true;
    });
  },
  methods: {
    formatNumber(v, isFixed = false) {
      const x = (v % 1 === 0) ? (v + '.00') : (v.toString());
      return formatNumber(parseFloat(x));
    },
  },
  filters: {
    currency(value) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value/1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '';
    },
    number(value) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value/1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },

  components: {
    CoreCard
  },
}
</script>

<style scoped>
.right {
  text-align: right;
  white-space: nowrap;
}
</style>
