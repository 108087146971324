var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "modal",
    attrs: {
      modalTitle: "Person Beziehungen Bearbeiten",
      showDefaultButtons: false
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function() {
          return [
            _c("div", { staticClass: "box__title" }, [
              _vm._v(_vm._s(_vm.personFullName))
            ]),
            _vm.person
              ? _c("PersonBeziehungFields", {
                  attrs: { relationship: _vm.person.relationship },
                  on: {
                    change: function($event) {
                      return _vm.dataChanged($event)
                    }
                  }
                })
              : _vm._e()
          ]
        },
        proxy: true
      },
      {
        key: "footer",
        fn: function() {
          return [
            _c(
              "BaseButton",
              {
                staticClass: "mr-3",
                attrs: { isSecondary: "" },
                on: {
                  click: function($event) {
                    return _vm.close()
                  }
                }
              },
              [_vm._v("Abbrechen")]
            ),
            _c(
              "BaseButton",
              {
                attrs: { disabled: !_vm.hasChanged || _vm.loading },
                on: {
                  click: function($event) {
                    return _vm.save()
                  }
                }
              },
              [
                _vm.loading ? _c("AnimatedSpinner") : _vm._e(),
                _vm._v(" Speichern ")
              ],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }