var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "box__container" }, [
      _c("div", { staticClass: "box__title" }, [_vm._v(_vm._s(_vm.headLabel))]),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("InputToggleSwitch", {
            attrs: {
              label: _vm.targetIsinLabel,
              config: { bold: true },
              inLineLabel: "",
              suppressValidationMessage: ""
            },
            on: {
              input: function($event) {
                return _vm.changeType("TARGET_ISIN", $event)
              }
            },
            model: {
              value: _vm.TARGET_ISIN,
              callback: function($$v) {
                _vm.TARGET_ISIN = $$v
              },
              expression: "TARGET_ISIN"
            }
          }),
          _c("ComboBox", {
            attrs: {
              label: "Lagerstelle",
              values: _vm.lagerstellen,
              isComponentHalfSize: "",
              firstEmpty: false,
              disabled: _vm.TARGET_KATEGORIE,
              sortComboboxValues: false
            },
            on: {
              change: function($event) {
                _vm.updateStore({ lagerstelle: $event })
                _vm.searchWertpapier()
              }
            },
            model: {
              value: _vm.lagerstelle,
              callback: function($$v) {
                _vm.lagerstelle = $$v
              },
              expression: "lagerstelle"
            }
          }),
          _c("ComboBox", {
            attrs: {
              label: "Gesellschaft",
              values: _vm.gesellschaften,
              isComponentHalfSize: "",
              firstEmpty: false,
              disabled: _vm.TARGET_KATEGORIE,
              sortComboboxValues: false
            },
            on: {
              change: function($event) {
                _vm.updateStore({ gesellschaft: $event })
                _vm.searchWertpapier()
              }
            },
            model: {
              value: _vm.gesellschaft,
              callback: function($$v) {
                _vm.gesellschaft = $$v
              },
              expression: "gesellschaft"
            }
          }),
          _c("InputField", {
            attrs: {
              label: "Zusätzliche Einschränkungen",
              isComponentHalfSize: "",
              placeholder: "ISIN, WKN, Wertpapiername, Kategorie",
              disabled: _vm.TARGET_KATEGORIE
            },
            on: {
              input: function($event) {
                return _vm.updateValue("zusatz", $event)
              }
            },
            model: {
              value: _vm.zusatz,
              callback: function($$v) {
                _vm.zusatz = $$v
              },
              expression: "zusatz"
            }
          }),
          _c(
            "BaseButton",
            {
              staticClass: "mb-3",
              attrs: { disabled: _vm.searchWertpapierDisabled },
              on: {
                click: function($event) {
                  return _vm.searchWertpapier()
                }
              }
            },
            [
              _c("span", [_vm._v("Suche Wertpapier")]),
              _vm.loading ? _c("AnimatedSpinner") : _vm._e()
            ],
            1
          ),
          _c("ComboBox", {
            attrs: {
              label: "Wertpapierauswahl",
              values: _vm.wertpapiere,
              isComponentHalfSize: "",
              firstEmpty: false,
              disabled: _vm.TARGET_KATEGORIE,
              sortComboboxValues: false
            },
            on: {
              change: function($event) {
                return _vm.updateStore({ wertpapier: $event })
              }
            },
            model: {
              value: _vm.wertpapier,
              callback: function($$v) {
                _vm.wertpapier = $$v
              },
              expression: "wertpapier"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("InputToggleSwitch", {
            attrs: {
              label: _vm.targetKategorieLabel,
              config: { bold: true },
              inLineLabel: "",
              suppressValidationMessage: ""
            },
            on: {
              input: function($event) {
                return _vm.changeType("TARGET_KATEGORIE", $event)
              }
            },
            model: {
              value: _vm.TARGET_KATEGORIE,
              callback: function($$v) {
                _vm.TARGET_KATEGORIE = $$v
              },
              expression: "TARGET_KATEGORIE"
            }
          }),
          _c("ComboBox", {
            attrs: {
              label: "Kategorie",
              values: _vm.kategorien,
              isComponentHalfSize: "",
              firstEmpty: false,
              disabled: _vm.TARGET_ISIN,
              sortComboboxValues: false
            },
            on: {
              change: function($event) {
                return _vm.updateStore({ kategorie: $event })
              }
            },
            model: {
              value: _vm.kategorie,
              callback: function($$v) {
                _vm.kategorie = $$v
              },
              expression: "kategorie"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("div", { staticClass: "box__title" }, [_vm._v("Suchoptionen")]),
          _c("InputField", {
            attrs: {
              label: "Mindestwert",
              isComponentHalfSize: "",
              type: "currency"
            },
            on: {
              change: function($event) {
                return _vm.updateValue("minvalue", $event)
              }
            },
            model: {
              value: _vm.minvalue,
              callback: function($$v) {
                _vm.minvalue = $$v
              },
              expression: "minvalue"
            }
          }),
          _c("InputField", {
            attrs: {
              label: "Höchstwert",
              isComponentHalfSize: "",
              type: "currency"
            },
            on: {
              change: function($event) {
                return _vm.updateValue("maxvalue", $event)
              }
            },
            model: {
              value: _vm.maxvalue,
              callback: function($$v) {
                _vm.maxvalue = $$v
              },
              expression: "maxvalue"
            }
          }),
          _c("InputField", {
            attrs: {
              label: "Mindestanteil",
              isComponentHalfSize: "",
              type: "percent"
            },
            on: {
              change: function($event) {
                return _vm.updateValue("minanteil", $event)
              }
            },
            model: {
              value: _vm.minanteil,
              callback: function($$v) {
                _vm.minanteil = $$v
              },
              expression: "minanteil"
            }
          }),
          _c("InputField", {
            attrs: {
              label: "Maximaler Anteil",
              isComponentHalfSize: "",
              type: "percent"
            },
            on: {
              change: function($event) {
                return _vm.updateValue("maxanteil", $event)
              }
            },
            model: {
              value: _vm.maxanteil,
              callback: function($$v) {
                _vm.maxanteil = $$v
              },
              expression: "maxanteil"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("div", { staticClass: "box__title" }, [
            _vm._v(_vm._s(_vm.standardwerteLabel))
          ]),
          _c("InputRadioBoxGroup", {
            attrs: { values: _vm.betragModusValues },
            on: {
              input: function($event) {
                return _vm.updateValue("betragModus", $event)
              }
            },
            model: {
              value: _vm.betragModus,
              callback: function($$v) {
                _vm.betragModus = $$v
              },
              expression: "betragModus"
            }
          }),
          _vm.betragModus === "PROZENT"
            ? _c("InputField", {
                attrs: {
                  label: "Auftragshöhe",
                  isComponentHalfSize: "",
                  type: "percent",
                  disabled:
                    _vm.betragModus === "VALUE_BETRAG_MODUS_ALLE_ANTEILE"
                },
                on: { change: _vm.updateBetrag },
                model: {
                  value: _vm.betrag,
                  callback: function($$v) {
                    _vm.betrag = $$v
                  },
                  expression: "betrag"
                }
              })
            : _c("InputField", {
                attrs: {
                  label: "Auftragshöhe",
                  isComponentHalfSize: "",
                  type: "currency",
                  disabled:
                    _vm.betragModus === "VALUE_BETRAG_MODUS_ALLE_ANTEILE"
                },
                on: { change: _vm.updateBetrag },
                model: {
                  value: _vm.betrag,
                  callback: function($$v) {
                    _vm.betrag = $$v
                  },
                  expression: "betrag"
                }
              }),
          _c("DatePickerField", {
            attrs: {
              label: "Ausführungstermin",
              isValueAsString: "",
              isComponentHalfSize: "",
              showSofortOption: ""
            },
            on: {
              change: function($event) {
                return _vm.updateValue("termin", $event)
              }
            },
            model: {
              value: _vm.termin,
              callback: function($$v) {
                _vm.termin = $$v
              },
              expression: "termin"
            }
          }),
          !_vm.isVerkauf
            ? _c("InputField", {
                attrs: {
                  label: "Ausgabeaufschlag",
                  type: "percent",
                  isComponentHalfSize: ""
                },
                on: {
                  change: function($event) {
                    return _vm.updateValue("inputAA", $event)
                  }
                },
                model: {
                  value: _vm.inputAA,
                  callback: function($$v) {
                    _vm.inputAA = $$v
                  },
                  expression: "inputAA"
                }
              })
            : _vm._e(),
          _c("InputField", {
            attrs: { label: "Bemerkung", isComponentHalfSize: "" },
            on: {
              change: function($event) {
                return _vm.updateValue("bemerkung", $event)
              }
            },
            model: {
              value: _vm.bemerkung,
              callback: function($$v) {
                _vm.bemerkung = $$v
              },
              expression: "bemerkung"
            }
          })
        ],
        1
      ),
      !_vm.isVerkauf
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("div", { staticClass: "box__title" }, [
                _vm._v(_vm._s(_vm.selectedPositionLabel))
              ]),
              _c("WertpapierAuswahlUnique", {
                attrs: {
                  data: { tauschInIsin: _vm.tauschInIsin },
                  hasFactsheet: true,
                  id: "tauschInIsin",
                  label:
                    "Achtung: der hier als Umschichtungsziel gewählte Fonds ist eventuell nicht bei allen Lagerstellen beziehbar.",
                  config: { isEmbedded: true },
                  disabled: _vm.isVerkauf
                },
                on: {
                  openFondsfinder: _vm.openFondsfinder,
                  removePosition: function($event) {
                    return _vm.removePosition($event)
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }