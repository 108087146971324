var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "antrag-components__container" }, [
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("FormLabel", {
          attrs: {
            label:
              "Bitte tragen Sie hier die Daten des Depots ein, von dem die Wertpapiere übertragen werden sollen.",
            config: { bold: true }
          }
        }),
        _c("FormDivider"),
        _c(
          "div",
          { staticClass: "row" },
          [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("FormLabel", {
                  attrs: {
                    label:
                      "Daten der bisher depotführenden Gesellschaft (abgebende Bank)",
                    config: { bold: true }
                  }
                })
              ],
              1
            ),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Name" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherManuellName: $event })
                }
              },
              model: {
                value: _vm.form.bisherManuellName,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherManuellName", $$v)
                },
                expression: "form.bisherManuellName"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Straße HNr." },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherManuellStrasse: $event })
                }
              },
              model: {
                value: _vm.form.bisherManuellStrasse,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherManuellStrasse", $$v)
                },
                expression: "form.bisherManuellStrasse"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "PLZ" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherManuellPLZ: $event })
                }
              },
              model: {
                value: _vm.form.bisherManuellPLZ,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherManuellPLZ", $$v)
                },
                expression: "form.bisherManuellPLZ"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Ort" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherManuellOrt: $event })
                }
              },
              model: {
                value: _vm.form.bisherManuellOrt,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherManuellOrt", $$v)
                },
                expression: "form.bisherManuellOrt"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Land" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherManuellLand: $event })
                }
              },
              model: {
                value: _vm.form.bisherManuellLand,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherManuellLand", $$v)
                },
                expression: "form.bisherManuellLand"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "BLZ der bisherigen Bank/KAG" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherBLZ: $event })
                }
              },
              model: {
                value: _vm.form.bisherBLZ,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherBLZ", $$v)
                },
                expression: "form.bisherBLZ"
              }
            }),
            _c("InputIbanBic", {
              staticClass: "col-12 col-md-6",
              attrs: {
                label: "BIC der bisherigen Bank/KAG",
                type: "BIC",
                componentData: _vm.form.bisherBIC
              },
              on: {
                input: function($event) {
                  return _vm.$emit("save", { bisherBIC: $event })
                }
              }
            }),
            _c("InputIbanBic", {
              staticClass: "col-12 col-md-6",
              attrs: {
                label: "IBAN bei bisheriger Bank",
                type: "IBAN",
                componentData: _vm.form.bisherKontonr
              },
              on: {
                input: function($event) {
                  return _vm.$emit("save", { bisherKontonr: $event })
                }
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Depotnummer bei bisheriger Bank" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherDepotnr: $event })
                }
              },
              model: {
                value: _vm.form.bisherDepotnr,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherDepotnr", $$v)
                },
                expression: "form.bisherDepotnr"
              }
            }),
            _c("FormDivider"),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("FormLabel", {
                  attrs: {
                    label:
                      "Daten des Depots bei der bisher depotführenden Gesellschaft",
                    config: { bold: true }
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("FormLabel", {
                  attrs: { label: "1. Inhaber", config: { bold: true } }
                })
              ],
              1
            ),
            _c("InputRadioBoxGroup", {
              staticClass: "col-12 col-md-6",
              attrs: {
                value: _vm.form.anrede,
                values: [
                  { value: "Herr", label: "Herr" },
                  { value: "Frau", label: "Frau" }
                ]
              },
              on: {
                input: function($event) {
                  return _vm.$emit("save", { anrede: $event })
                }
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Name" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherName: $event })
                }
              },
              model: {
                value: _vm.form.bisherName,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherName", $$v)
                },
                expression: "form.bisherName"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Vorname" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherVorname: $event })
                }
              },
              model: {
                value: _vm.form.bisherVorname,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherVorname", $$v)
                },
                expression: "form.bisherVorname"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Straße HNr." },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherStrasse: $event })
                }
              },
              model: {
                value: _vm.form.bisherStrasse,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherStrasse", $$v)
                },
                expression: "form.bisherStrasse"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "PLZ" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherPLZ: $event })
                }
              },
              model: {
                value: _vm.form.bisherPLZ,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherPLZ", $$v)
                },
                expression: "form.bisherPLZ"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Ort" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherOrt: $event })
                }
              },
              model: {
                value: _vm.form.bisherOrt,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherOrt", $$v)
                },
                expression: "form.bisherOrt"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Land" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherLand: $event })
                }
              },
              model: {
                value: _vm.form.bisherLand,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherLand", $$v)
                },
                expression: "form.bisherLand"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "TIN" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { tin1: $event })
                }
              },
              model: {
                value: _vm.form.tin1,
                callback: function($$v) {
                  _vm.$set(_vm.form, "tin1", $$v)
                },
                expression: "form.tin1"
              }
            }),
            _c("FormDivider"),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("FormLabel", {
                  attrs: { label: "2. Inhaber", config: { bold: true } }
                })
              ],
              1
            ),
            _c("InputRadioBoxGroup", {
              staticClass: "col-12 col-md-6",
              attrs: {
                value: _vm.form.anrede2,
                values: [
                  { value: "Herr", label: "Herr" },
                  { value: "Frau", label: "Frau" }
                ]
              },
              on: {
                input: function($event) {
                  return _vm.$emit("save", { anrede2: $event })
                }
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Name" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherName2: $event })
                }
              },
              model: {
                value: _vm.form.bisherName2,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherName2", $$v)
                },
                expression: "form.bisherName2"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Vorname" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherVorname2: $event })
                }
              },
              model: {
                value: _vm.form.bisherVorname2,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherVorname2", $$v)
                },
                expression: "form.bisherVorname2"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Straße HNr." },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherStrasse2: $event })
                }
              },
              model: {
                value: _vm.form.bisherStrasse2,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherStrasse2", $$v)
                },
                expression: "form.bisherStrasse2"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "PLZ" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherPLZ2: $event })
                }
              },
              model: {
                value: _vm.form.bisherPLZ2,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherPLZ2", $$v)
                },
                expression: "form.bisherPLZ2"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Ort" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherOrt2: $event })
                }
              },
              model: {
                value: _vm.form.bisherOrt2,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherOrt2", $$v)
                },
                expression: "form.bisherOrt2"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Land" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherLand2: $event })
                }
              },
              model: {
                value: _vm.form.bisherLand2,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherLand2", $$v)
                },
                expression: "form.bisherLand2"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "TIN" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { tin2: $event })
                }
              },
              model: {
                value: _vm.form.tin2,
                callback: function($$v) {
                  _vm.$set(_vm.form, "tin2", $$v)
                },
                expression: "form.tin2"
              }
            })
          ],
          1
        ),
        _c(
          "BaseButton",
          {
            on: {
              click: function($event) {
                return _vm.$emit("next")
              }
            }
          },
          [_vm._v("Weiter")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }