import BALANCE_SHEET_TYPES from './types';

export default {
  [BALANCE_SHEET_TYPES.GETTERS.OTHER_ASSETS](state) {
    return state.otherAssets;
  },
  [BALANCE_SHEET_TYPES.GETTERS.CREDIT](state) {
    return state.credit;
  },
  [BALANCE_SHEET_TYPES.GETTERS.BANK_ACCOUNT](state) {
    return state.bankAccount;
  },
  [BALANCE_SHEET_TYPES.GETTERS.CLOSED_FUNDS](state) {
    return state.closedFunds;
  },
  [BALANCE_SHEET_TYPES.GETTERS.DEPOSIT_SUM](state) {
    return state.depositSum;
  },
  [BALANCE_SHEET_TYPES.GETTERS.ACCOUNTS](state) {
    return state.accounts;
  },
  [BALANCE_SHEET_TYPES.GETTERS.ACCOUNTS_DETAILS](state) {
    return state.accountsDetails;
  },
  [BALANCE_SHEET_TYPES.GETTERS.INSURANCE_TABLE](state) {
    return state.insuranceTable;
  },
  [BALANCE_SHEET_TYPES.GETTERS.DEPOTPOSITIONS](state) {
    return state.depotpositions;
  },
  [BALANCE_SHEET_TYPES.GETTERS.TRANSACTIONS](state) {
    return state.transactions;
  },
  [BALANCE_SHEET_TYPES.GETTERS.SPAR_ENTNAHMEPLANE](state) {
    return state.spar_entnahmeplane;
  },  
  [BALANCE_SHEET_TYPES.GETTERS.VERMOGENSUBERSICHT_PDF](state) {
    return state.vermogensubersichtPdf;
  },    
}