<template>
  <div class="box__container">
    <AppointmentTable bereich="Kredit" :bereichId="creditId" :bereichText="bereichText"
      :appointments="appointments" :appointmentCombos="appointmentCombos" :loading="loading" 
      @renew="findAppointments()" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CREDIT_TYPES from '@/store/credit/types';

import AppointmentTable from '@/components/calendar/appointmentFiles/AppointmentTable.vue';


export default {
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      creditId: CREDIT_TYPES.GETTERS.SELECTED_CREDIT_ID,
      credit: CREDIT_TYPES.GETTERS.CREDIT,
      appointments: CREDIT_TYPES.GETTERS.APPOINTMENTS,
      appointmentCombos: CREDIT_TYPES.GETTERS.APPOINTMENT_COMBOS,
    }),
  },
  watch: {
    creditId: {
      handler() {
        this.findAppointments();
      },
      immediate: true,
    },
  },
  methods: {
    async findAppointments() {
      this.loading = true;
      await this.$store.dispatch(CREDIT_TYPES.ACTIONS.FIND_APPOINTMENTS);
      this.loading = false;
    },
    bereichText(appointment) {
      const bereichText = this.appointmentCombos?.bereichCombo?.[appointment.bereich] || '';

      const description = [];
      if(this.credit?.kreditform) {
        description.push(this.credit.kreditform);
      }

      const descriptionExtra = [];
      if(this.credit?.bezeichnung) {
        descriptionExtra.push(this.credit.bezeichnung);
      }
      if(this.credit?.kreditnummer) {
        descriptionExtra.push(this.credit.kreditnummer);
      }

      return `${bereichText}: ${description.join(', ')}(${descriptionExtra.join(', ')})`;
    },
  },
  components: {
    AppointmentTable,
  },
}
</script>
