<template>
  <div @click="addExpresion('-')" class="box norm_button minus">-</div>
</template>

<script>
import functionMixins from "../../functionMixins.js";
export default {
  name: "Minus",  
  mixins: [functionMixins]
};
</script>

<style scoped>
.minus {
  grid-area: minus;
}
</style>