var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "month-calendar__container" },
    [
      _vm.compgetMonthCalendarDataReady
        ? _c(
            "div",
            { attrs: { id: "monthcalendarapp" } },
            [
              _c(
                "div",
                { staticClass: "monthweekdays" },
                _vm._l(_vm.weekdayLabels, function(weekday, wkdidx) {
                  return _c(
                    "div",
                    { key: wkdidx, staticClass: "monthweekday" },
                    [
                      _c("span", { staticClass: "monthweekday-label" }, [
                        _vm._v(_vm._s(weekday.label))
                      ]),
                      _c(
                        "span",
                        { staticClass: "monthweekday-label-shorter" },
                        [_vm._v(_vm._s(weekday.labelShorter))]
                      )
                    ]
                  )
                }),
                0
              ),
              _c(
                "div",
                {
                  ref: "weeksEl",
                  staticClass: "weeks",
                  style: { maxHeight: "var(--monthWeeksHeight)" },
                  attrs: { "data-scroll-container": "" }
                },
                _vm._l(_vm.getShownDays, function(week, widx) {
                  return _c(
                    "div",
                    { key: widx, staticClass: "week" },
                    _vm._l(week, function(day, didx) {
                      return _c("CalendarMonthDayCell", {
                        key: didx,
                        class: {
                          selected: day.isSelected,
                          notselected: !day.isSelected,
                          weekend: didx > 4
                        },
                        attrs: {
                          daylabel: day.label,
                          istoday: day.isToday,
                          inmonth: day.inMonth,
                          verticalLimitCount: _vm.verticalLimitCount
                        },
                        on: {
                          editEvent: _vm.editEvent,
                          chooseDay: function($event) {
                            return _vm.chooseMonthDay(week, day, didx)
                          }
                        }
                      })
                    }),
                    1
                  )
                }),
                0
              ),
              _c("BaseModal", { ref: "modalRef" }, [_c("Day")], 1)
            ],
            1
          )
        : _c("CalendarGhostLoading", {
            style: {
              maxHeight: "var(--monthContainerHeight)",
              overflow: "hidden"
            },
            attrs: { type: "month" }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }