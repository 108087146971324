<template>
  <GenericEinkommenSozialversicherungen 
    title="Einkommen und Sozialversicherungen (Partner)" 
    :data="incomeSocialSecurityPartner"
    :dataTaxResult="taxCalcResult"
    :partner="true"
    @dataChanged="dataChanged($event)"
    @taxDataChanged="saveTaxData('incomeSocialSecurityPartner')"
    @bruttoeinkommmenpaDataChanged="calcBruttoeinkommmenpa()" 
  />
</template>

<script>
import CUSTOMERDATA_TYPES from '@/store/customerData/types';
import LOG_TYPES from '@/store/log/types';

import persoenlicheDatenMixin from '@/components/persoenliche-daten/persoenliche-daten-mixin';

import GenericEinkommenSozialversicherungen from './einkommen-sozialversicherungen/GenericEinkommenSozialversicherungen.vue';
import { initialState, getBruttoeinkommmenpa } from './einkommen-sozialversicherungen/einkommen-sozialversicherungen-utils.js';


export default {
  mixins: [persoenlicheDatenMixin],
  data() {
    return {
      incomeSocialSecurityPartner: {
        ...initialState()
      },
      taxCalcResult: {},
    };
  },
  computed: {
    isBruttoeinkommenManuellActive() {
      return this.incomeSocialSecurityPartner?.bruttoeinkommenManuell;
    },
  },
  watch: {
    customerData: {
      handler() {
        this.updateGroupIntoData('incomeSocialSecurityPartner');
        this.calcBruttoeinkommmenpa();
        this.calcTax();
      },
      immediate: true,
    },
    customerDataEdited(data) {
      if(!Object.keys(data).length) {
        this.discardChanges();
      }
    },
  },
  methods: {
    dataChanged(data) {
      this.incomeSocialSecurityPartner = data;
      this.addCustomerDataEdited('incomeSocialSecurityPartner',data);
    },
    calcBruttoeinkommmenpa() {
      if(this.isBruttoeinkommenManuellActive) {
        return;
      }

      const bruttoeinkommen = this.incomeSocialSecurityPartner?.bruttoeinkommen;
      const anzahlmonate = this.incomeSocialSecurityPartner?.anzahlmonate;
      const bruttoeinkommmenpa = getBruttoeinkommmenpa(bruttoeinkommen, anzahlmonate);
      this.incomeSocialSecurityPartner.bruttoeinkommmenpa = bruttoeinkommmenpa;
    },
    calcTax() {
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.CUSTOMER_CALC_TAX).then((response) => {
        this.taxCalcResult = response[1] || {};
      })
      .catch(error => {
        this.$store.dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "calcTax", error});
      });
    },
    discardChanges() {
      this.incomeSocialSecurityPartner = {
        ...initialState(),
      };
      this.taxCalcResult = {};
      this.updateGroupIntoData('incomeSocialSecurityPartner');
      this.calcBruttoeinkommmenpa();
      this.calcTax();
    },
  },
  components: {
    GenericEinkommenSozialversicherungen,
  },
}
</script>
