<template>
  <div class="form-input-index-kombination__container">
    <InputToggleSwitch class="pb-2" :label="label" inLineLabel v-model="form.active" 
      :disabled="disabled" :config="{ forceValueAsString: true }" @input="onActiveInput($event)" />

    <div class="form-input-index-kombination--indexes">
      <InputField class="pb-0" label="Bezeichnung" v-model="form.label" 
        :disabled="disabled || !isActive" isComponentHalfSize trimValue @change="onLabelChange($event)" />

      <div v-for="(_, index) in INDEXES_SIZE" :key="index" class="forms__input--half-size">
        <div  class="row my-0">
          <ComboBox class="col pb-2" :label="`Index ${index+1}`" v-model="form.indexes[index]" 
            firstEmpty :values="indexValues" :disabled="disabled || !isActive" @change="onIndexChange(index, $event)" />
          <InputField class="col" label="Gewichtung" v-model="form.gewichtung[index]" type="percent" 
            :disabled="disabled || !isActive" @change="onGewichtungChange(index, $event)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputField from '@/components/core/forms/InputField.vue';

const OBJECT_SEPARATOR = '&';
const KEY_VALUE_SEPARATOR = '=';
const INDEXES_SIZE = 3;
const TRUE_VALUE = 'Ja';
const FALSE_VALUE = 'Nein';
const EMPTY_INDEXES = ['', '', ''];
const EMPTY_WEIGHTS = [0, 0, 0];

function legacyEncode(value) {
  return encodeURIComponent(value).replace(/(%20|\s)/g, '+');
}

function legacyDecode(value) {
  return decodeURIComponent(value).replace(/\+/g, ' ');
}

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    indexValues: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      INDEXES_SIZE,
      form: {
        active: FALSE_VALUE,
        label: null,
        indexes: [ ...EMPTY_INDEXES ],
        gewichtung: [ ...EMPTY_WEIGHTS ],
      },
    };
  },
  computed: {
    isActive() {
      return this.form.active === TRUE_VALUE;
    },
  },
  watch: {
    value: {
      handler() {
        const form = this.parse(this.value);
        // applies only when it is active
        if (form.active === TRUE_VALUE) {
          this.form = form;
        }
      },
      immediate: true,
    },
  },
  methods: {
    parse(value) {
      const parsed = value
        .split(OBJECT_SEPARATOR)
        .filter(v => !!v)
        .reduce((acc, v) => {
          const [paramName, paramValue] = v.split(KEY_VALUE_SEPARATOR);
          switch(paramName) {
              case '0':
                  acc['active'] = paramValue || FALSE_VALUE;
              break;
              case '2':
                  acc['label'] = decodeURIComponent(paramValue);
              break;
              case 'i1':
                  acc.indexes[0] = legacyDecode(paramValue);
              break;
              case 'i2':
                  acc.indexes[1] = legacyDecode(paramValue);
              break;
              case 'i3':
                  acc.indexes[2] = legacyDecode(paramValue);
              break;
              case 'g1':
                  acc.gewichtung[0] = paramValue
              break;
              case 'g2':
                  acc.gewichtung[1] = paramValue
              break;
              case 'g3':
                  acc.gewichtung[2] = paramValue
              break;
          }
          return acc;
        }, { indexes: [ ...EMPTY_INDEXES ], gewichtung: [ ...EMPTY_WEIGHTS ], });

      return parsed;
    },
    stringify(form) {
      const params = [];
      params.push(['0', form.active || FALSE_VALUE].join(KEY_VALUE_SEPARATOR));
      params.push(['1', legacyEncode(this.name)].join(KEY_VALUE_SEPARATOR));
      if (!!form.label) {
        params.push(['2', encodeURIComponent(form.label)].join(KEY_VALUE_SEPARATOR));
      }
      params.push(...form.indexes.map((v, i) => ['i' + (i + 1), legacyEncode(v)].join(KEY_VALUE_SEPARATOR)));
      params.push(...form.gewichtung.map((v, i) => ['g' + (i + 1), v].join(KEY_VALUE_SEPARATOR)));
      return params.join(OBJECT_SEPARATOR);
    },
    onInput() {
      this.$emit('input', this.stringify(this.form));
    },
    onActiveInput(value) {
      this.$set(this.form, 'active', value);
      this.resetValues();

      this.onInput();
    },
    onLabelChange(value) {
      this.$set(this.form, 'label', value);
      this.onInput();
    },
    onIndexChange(index, value) {
      this.$set(this.form.indexes, index, value);
      this.onInput();
    },
    onGewichtungChange(index, value) {
      this.$set(this.form.gewichtung, index, value);
      this.onInput();
    },
    resetValues() {
      this.$set(this.form, 'label', null);
      this.$set(this.form, 'indexes', [ ...EMPTY_INDEXES ]);
      this.$set(this.form, 'gewichtung', [ ...EMPTY_WEIGHTS ]);
    },
  },
  components: {
    InputToggleSwitch,
    ComboBox,
    InputField,
  },
}
</script>

<style lang="scss" scoped>
.form-input-index-kombination--indexes {
  > * {
    margin-top: .5rem;

    &:first-child {
      margin-top: 0;
    }
  }
}
</style>
