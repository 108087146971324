var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DragnDropArea",
    {
      attrs: {
        hoverText: "Datei hier ablegen",
        disabled: _vm.isSomethingLoading
      },
      on: {
        files: function($event) {
          return _vm.dropFiles($event)
        }
      }
    },
    [
      _c("GesellschaftTitleNavigation", {
        attrs: { headerActions: _vm.headerActions },
        on: { "action-UPLOAD_DOCUMENT": _vm.uploadNewDocument }
      }),
      _c("StepperForm", {
        ref: "stepperForm",
        attrs: {
          stepType: "tab",
          stepperName: "customer-data",
          stepperMediator: _vm.tabsStepperMediator,
          hasFertig: false,
          selectedStepKey: _vm.currentStep
        },
        on: {
          "set-step": function($event) {
            return _vm.setStep($event)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "contentTemplate",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "box__container" },
                  [
                    _vm.loading.init
                      ? _c("GhostLoading", { attrs: { type: "table" } })
                      : _vm.currentStep === "SCHRIFTVERKEHR"
                      ? [
                          _c("Table", {
                            attrs: {
                              title: "Dokumente Schriftverkehr",
                              headers: _vm.schriftverkehrHeaders,
                              rows: _vm.schriftverkehrRows,
                              rowsPerPage: 10
                            },
                            on: {
                              "action-EDIT": _vm.editDocument,
                              "action-DELETE": _vm.deleteDocument
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "bezeichnung",
                                fn: function(row) {
                                  return [
                                    _c("DownloadLink", {
                                      attrs: {
                                        title: row.bezeichnung,
                                        filename: row.bezeichnung,
                                        downloadServicePath: "/get_simple_file",
                                        queryParams: {
                                          fileId: row.id
                                        }
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ]
                      : _vm.currentStep === "VERTRAEGE"
                      ? [
                          _c("Table", {
                            attrs: {
                              title: "Dokumente Verträge",
                              headers: _vm.vertraegeHeaders,
                              rows: _vm.vertraegeRows,
                              rowsPerPage: 10
                            },
                            on: {
                              "action-EDIT": _vm.editDocument,
                              "action-DELETE": _vm.deleteDocument
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "bezeichnung",
                                fn: function(row) {
                                  return [
                                    _c("DownloadLink", {
                                      attrs: {
                                        title: row.bezeichnung,
                                        filename: row.bezeichnung,
                                        downloadServicePath: "/get_simple_file",
                                        queryParams: {
                                          fileId: row.id
                                        }
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "DokumentUploadModal",
        {
          ref: "uploadModal",
          attrs: {
            disableMultifile: "",
            disableFotoUpload: "",
            confirmDisabled: !_vm.fileData.bezeichnung || !_vm.fileData.typ
          },
          on: { upload: _vm.uploadDocuments },
          model: {
            value: _vm.files,
            callback: function($$v) {
              _vm.files = $$v
            },
            expression: "files"
          }
        },
        [
          _c("InputField", {
            attrs: {
              label: "Bezeichnung",
              renderDanger: !_vm.fileData.bezeichnung
            },
            model: {
              value: _vm.fileData.bezeichnung,
              callback: function($$v) {
                _vm.$set(_vm.fileData, "bezeichnung", $$v)
              },
              expression: "fileData.bezeichnung"
            }
          }),
          _c("ComboBox", {
            attrs: {
              label: "Typ",
              sortComboboxValues: false,
              values: _vm.typValues,
              disabled: !!_vm.fileData.id,
              renderDanger: !_vm.fileData.typ
            },
            model: {
              value: _vm.fileData.typ,
              callback: function($$v) {
                _vm.$set(_vm.fileData, "typ", $$v)
              },
              expression: "fileData.typ"
            }
          }),
          _c("InputTextArea", {
            attrs: { label: "Bemerkung" },
            model: {
              value: _vm.fileData.bemerkung,
              callback: function($$v) {
                _vm.$set(_vm.fileData, "bemerkung", $$v)
              },
              expression: "fileData.bemerkung"
            }
          }),
          _c("DatePickerField", {
            attrs: { label: "Stand", isValueAsString: "" },
            model: {
              value: _vm.fileData.dateStand,
              callback: function($$v) {
                _vm.$set(_vm.fileData, "dateStand", $$v)
              },
              expression: "fileData.dateStand"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }