/**
 * This mixin is intended to help the developer to create internal components that needs to be validated.
 * It's only a helper and is intended to be imported in the base components, 
 *    e.g.: InputField, InputRadioBoxGroup
 * 
 * 
 * It's NOT intended to be used into pages like CustomerData or CustomerSearch and so on.
 * 
 * For the final component you should use the 'validator' mixin
 */
const validatorComponentUtils = {
  watch: {
    valueForValidator: {
      immediate: false,
      handler(newValue) {
        try {
          if (this.validationPath) {
            const currentValidator = this.validation.getValidator(this.validationPath)
            if (currentValidator?.watchCallback) {
              currentValidator.watchCallback(newValue)
            }
          }
        } catch (error) {
          // empty
        }
      },
    }
  },
  props: {
    id: {
    },
    validation_path: {
      type: String,
      default: undefined
    },
  },
  computed: {
    validationPath() {
      if (this.validation_path) {
        return this.validation_path;
      }
      const validationPath = this.$vnode.data && this.$vnode.data.model && this.$vnode.data.model.expression
      if (validationPath) {
        return validationPath
      }
      if (this.id) {
        return this.id
      }
      throw new Error('in order to use the validation you should declare the v-model="myField" that the validator framework can check teh value and watch for changes')
    },
    validation() {
      if (!this.validationPath) {
        throw new Error(`File: ${this.$parent.$options.__file}. The ${this.$parent.$options.__file} component is trying to get validation, but there is no 'validationPath' property. Please check the v-model attribute`)
      }

      if (!this.$parent) {
        throw new Error('The $parent object is not found')
      }

      let validation = null;
      let parent = this.$parent
      const howDeep = 14;

      for (let index = 0; index < howDeep; index++) {
        validation = parent['validation']
        if (!validation) {
          parent = parent.$parent
        }
      }

      if (!validation || !validation.configurationDone) {
        throw new Error('The validation is not configured yet, please check the errors log or check isValidationConfigured() before get the validation data')
      }
      return validation
    },
    isValidationConfigDone() {
      try {
        return this.validation && this.validation.configurationDone && (this.validation.updated || true)
      } catch (error) {
        return false;
      }
    },
  },
  methods: {
    isValidationConfigured() {
      try {
        return this.validation && this.validation.configurationDone && (this.validation.updated || true)
      } catch (error) {
        return false;
      }
    },
    $setDirty() {
      try {
        if (this.validationPath) {
          const currentValidator = this.validation.getValidator(this.validationPath)
          
          if (currentValidator?.setDirty) {
            currentValidator.setDirty(this.validationPath)
            currentValidator.setTouched(this.validationPath)
          }
        }
      } catch (error) {
        // empty
      }
    },
    $setTouched() {
      try {
        if (this.validationPath) {
          const currentValidator = this.validation.getValidator(this.validationPath)
          if (currentValidator?.setDirty) {
            currentValidator.setTouched(this.validationPath)
          }
        }
      } catch (error) {
        // empty
      }
    },
    $runValidator(newValue, initialRun = false) {
      try {
        if (this.validationPath) {
          const currentValidator = this.validation.getValidator(this.validationPath)
          if (currentValidator?.watchCallback) {
            currentValidator.watchCallback(newValue, initialRun)
          }
        }
      } catch (error) {
        // empty
      }
    },
    $reset() {
      try {
        if (this.validationPath) {
          this.validation.reset(this.validationPath)
        }
      } catch (error) {
        // empty
      }
    }
  },
}

export default validatorComponentUtils; 