<template>
  <div class="d-flex">

      <div class="stoppuhr__timer">
        <span>{{stoppuhrHour}}</span>
        <span class="divider">:</span>
        <span>{{stoppuhrMinutes}}</span>
        <span class="divider">:</span>
        <span>{{stoppuhSeconds}}</span>
      </div>

      <PhPlay  @click="startTimer()" v-if="!isStoppuhrRunning" :size="20" weight="fill"  class="clickable"/>

      <PhPause  @click="stopTimer(); saveForm()" :size="20" weight="fill" v-if="isStoppuhrRunning"  class="clickable" />
      <PhPause  :size="20" v-if="!isStoppuhrRunning" weight="regular" />

      <PhPencil :size="20" weight="fill" @click="openStoppuhrForm()" class="clickable" v-if="!displayingStoppuhrForm" />

    </div>
</template>

<script>
import { mapGetters } from "vuex";
import STOPPUHR_TYPES from "@/store/stoppuhr/types";
import { PhPlay, PhPause, PhPencil } from 'phosphor-vue'
import stoppurShared from "@/views/communication/stoppuhr/stoppuhr-mixing.js";

export default {
  mixins: [stoppurShared],
  components: {
    PhPlay,
    PhPause,
    PhPencil,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      stoppuhr: STOPPUHR_TYPES.GETTERS.INIT_STOPPUHR,
      stoppuhrHour: STOPPUHR_TYPES.GETTERS.STOPPUHR_HOUR,
      stoppuhrMinutes: STOPPUHR_TYPES.GETTERS.STOPPUHR_MINUTES,
      stoppuhSeconds: STOPPUHR_TYPES.GETTERS.STOPPUHR_SECONDS,
      isStoppuhrRunning: STOPPUHR_TYPES.GETTERS.IS_STOPPUHR_RUNNING,
    }),
    headerActions() {
      return [];
    },

  },
  mounted: function () {
  },
  beforeDestroy() {
  },
  methods: {
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu Stoppuhr', 
      to,
      from,
      breadcrumb: "Stoppuhr"
    });

    next();
  },
}
</script>

<style scoped>
.stoppuhr__timer {
  /* background-color: var(--color-background); */
  /* background: rgba(0, 0, 0, 0.7); */
}
.stoppuhr__timer span {
    border-radius: 4px;
    /* background-color: var(--color-primary); */
    /* background: rgba(0, 0, 0, 0.7); */
    /* color: var(--color-primary-text); */
    /* color: var(--color-text); */
    padding: 4px;
    margin-right: 2px;
    font-size: 20px
}

span.divider {
    /* background-color: var(--color-background); */
    /* background: rgba(0, 0, 0, 0.7); */
    /* color: var(--color-text); */
    padding: 4px;
    margin-right: 2px;
    font-size: 20px
}
</style>