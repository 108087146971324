<template>
  <svg id="Ebene_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
    <path class="cls-2"
      d="m221.05,156.6c-.87-.02-1.73-.06-2.61-.06-69.24,0-125.37,56.13-125.37,125.37s56.13,125.38,125.37,125.38,125.38-56.13,125.38-125.38c0-1.1-.05-2.19-.09-3.29h-122.68v-122.01h0Z" />
    <path class="cls-1" d="m406.94,92.72h-185.89v63.88c66.93,1.37,120.95,55.17,122.68,122.01h63.21V92.72h0Z" />
  </svg>
</template>

<script>
import undraw from "@/components/icons/undraw/undraw-mixin.js";

export default {
  mixins: [undraw],
};
</script>

<style scoped>
    .cls-1 {
      fill: #0369b1;
    }

    .cls-2 {
      fill: #9f2b2f;
    }
</style>