<template>
  <div class="open-link__container">
    <div v-if="errorMessage" class="open-link--message open-link--error-message">
      <PhWarningCircle class="open-link--message-icon" :size="DEFAULT_ICON_SIZE" />
      <div class="open-link--message-text" v-html="sanitize(errorMessage)" />
      <div class="open-link--message-actions">
        <button type="button" @click="closeWindow()">
          <PhX class="open-link--message-action-icon" :size="16" /> {{ DEFAULT_CLOSE_BUTTON_LABEL }}
        </button>
      </div>
    </div>
    <div v-else class="open-link--message open-link--wait-message">
      <PhHourglassSimple class="open-link--message-icon" :size="DEFAULT_ICON_SIZE" />
      <div class="open-link--message-text">{{ DEFAULT_LOADING_MESSAGE }}</div>
    </div>
  </div>
</template>

<script>
import { PhWarningCircle, PhHourglassSimple, PhX } from 'phosphor-vue';

import { _handleOpenLinkRequest } from '@/link-resolvers';
import { sanitize } from '@/helpers/string-helper';

const DEFAULT_ICON_SIZE = 50;
const DEFAULT_LOADING_MESSAGE = 'Bitte warten. Ihre Anfrage wird verarbeitet...';
const DEFAULT_CLOSE_BUTTON_LABEL = 'Schließen';

export default {
  components: {
    PhWarningCircle,
    PhHourglassSimple,
    PhX,
  },
  data() {
    return {
      DEFAULT_ICON_SIZE,
      DEFAULT_LOADING_MESSAGE,
      DEFAULT_CLOSE_BUTTON_LABEL,
      errorMessage: null,
    };
  },
  methods: {
    sanitize(html) {
      return sanitize(html);
    },
    closeWindow() {
      requestAnimationFrame(() => window.close());
    },
    async handleRequest(data) {
      try {
        await _handleOpenLinkRequest(data);
      } catch (e) {
        this.errorMessage = e.message;
      }
    },
  },
  async created() {
    const { data } = this.$route.query;

    // handle request
    this.handleRequest(data);
  },
}
</script>

<style lang="scss" scoped>
.open-link__container {
  color: #333;
  padding: 2.75rem 3.25rem;
  text-align: center;
  margin: 0 auto;
  max-width: 580px;

  .open-link--message {
    font-size: 1.5rem;
    line-height: 1.2em;
    font-weight: bold;

    &.open-link--error-message {
      color: var(--color-danger);
    }

    &.open-link--wait-message {
      .open-link--message-icon {
        animation: wait-animation 1s ease infinite both;
      }
    }

    .open-link--message-icon {
      margin: 0 auto;
      width: 50px;
      height: auto;
    }

    .open-link--message-text {
      margin: 1.5rem 0 1.75rem;
    }

    .open-link--message-actions {
      font-size: 1rem;
      line-height: 1.2em;
    }

    .open-link--message-actions {
      button {
        appearance: none;
        background: none;
        border: none;
        border-radius: 4px;
        line-height: 1.2em;
        padding: 0.5rem 1rem;
        transition: background .3s ease;

        &:hover {
          background: #eee;
        }

        .open-link--message-action-icon {
          margin: -1px 0 0;
        }
      }
    }
  }
}

@keyframes wait-animation {
  0%,
  75% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
</style>
