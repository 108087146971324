var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-container" },
    _vm._l(_vm.routes, function(item, index) {
      return _c("div", { key: index }, [
        index != 0 ? _c("hr") : _vm._e(),
        item.externalLink
          ? _c(
              "div",
              {
                staticClass: "list-item",
                on: {
                  click: function($event) {
                    return _vm.navigateToExternalLink(item.externalLink)
                  }
                }
              },
              [
                _c("div", [_vm._v(_vm._s(item.label))]),
                item.externalLink
                  ? _c("PhCaretRight", { attrs: { size: 18 } })
                  : _vm._e()
              ],
              1
            )
          : _c(
              "div",
              {
                staticClass: "list-item",
                on: {
                  click: function($event) {
                    return _vm.navigateTo(item.route)
                  }
                }
              },
              [
                _c("div", [_vm._v(_vm._s(item.label))]),
                item.route
                  ? _c("PhCaretRight", { attrs: { size: 18 } })
                  : _vm._e()
              ],
              1
            )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }