<template>
    <div>
        <p>Die Dokumente können nicht zu einer Datei zusammengefasst werden, da sie z.T. digital signiert wurden. Sie können die Dateien hier einzeln abrufen:</p>

        <p><b>Nicht digital signierte Dokumente / Scans</b></p>
        <div v-for="ua in unsignedAttachments" v-bind:key="ua.label">
            <DownloadLink 
                :href="linkGenerator(ua.fileName || ua.bezeichnung, ua.id, ua.tempFileId || 0)"
                :title="ua.bezeichnung || ua.fileName"
                />
        </div>        

        <p><b>Digital signierte Dokument</b></p>

        <div v-for="sa in signedAttachments" v-bind:key="sa.label">
            <DownloadLink 
                :href="linkGenerator(sa.fileName || sa.bezeichnung, sa.id, sa.tempFileId || 0)"
                :title="sa.bezeichnung || sa.fileName"
                />
        </div>
        <div class="color-danger mt-2" v-if="isEmptyFile">Dokument kann nicht geöffnet werden</div>
    </div>
</template>

<script>

import DOKUMENTENARCHIV_TYPES from '@/store/dokumentenarchiv/types';
import { openFile } from '../table/export/utils';
import { mapGetters } from "vuex";
import DownloadLink from '@/components/core/download/DownloadLink.vue'

export default {
    components: {
        DownloadLink
    },
    data() {
        return {
            isEmptyFile: false,
        }
    },
    props: {
        unsignedAttachments: {
            type: Array,
            default: () => [],
        },
        signedAttachments: {
            type: Array,
            default: () => [],
        },
    },
  computed: {
    ...mapGetters({
      linkGenerator: DOKUMENTENARCHIV_TYPES.GETTERS.GET_ATTACHMENT_LINK_BY_ID,
    }),
  },
    methods: {
        downloadDocument(paramDisplayPDFData) {
            this.isEmptyFile = false;
            this.$store.dispatch(DOKUMENTENARCHIV_TYPES.ACTIONS.DOWNLOAD_ATTACHMENT, { paramDisplayPDFData }).then(response => {
                if (response?.bytes) {
                    this.$emit('close')
                    openFile(response.bytes, response?.contentType?.mimeType, response?.fileName)
                } else {
                    this.isEmptyFile = true;
                }
            })
        },
    }

}
</script>

<style>

</style>