<template>
  <div>
    <AntragSidetext 
      :antragId="antragId" 
      :antragData="getAntragData"
      :warnings="warnings"
      :steps="stepper.getCurrentStepList()"
      :highestStepVisited="stepper.getCurrentStepList() && stepper.getCurrentStepList().length"
      showMessageErrorMustBeFixed
      showExpanded
      @executeAction="onExecuteAction"
      @navigateToWarning="$emit('navigateToWarning', $event)"
      @setSubstep="setSubstepByKey($event)">
    </AntragSidetext>
  </div>
</template>

<script>
import AntragSidetext from '@/components/antrag/AntragSidetext.vue'; 
import WERTPAPIERORDER_TYPES from '@/store/wertpapierorder/types';
import { mapGetters } from 'vuex';
import mixin from '@/mixins/wertpapierorder/mixin.js';
import antragNavigationMixin from '@/mixins/antrag/antrag-navigation-mixin.js';

import EVENT_BUS, { ANTRAG_SIGN_ACTION, ANTRAG_E_SIGNATUR, } from '@/event-bus';

export default {
  mixins: [mixin, antragNavigationMixin],
  components: {
      AntragSidetext,
  },
  data() {
    return {
      antragId: null,
    };
  },
  mounted() {
    this.antragId = 'wertpapierorder'
  },
  computed: {
    ...mapGetters({
      warnings: WERTPAPIERORDER_TYPES.GETTERS.WARNINGS,
    }),
    getAntragData() {
      return this.data || {};
    },
  },
  methods: {
    setSubstepByKey(event) {
      this.navigateToSubstep(event);
    },
    onExecuteAction(action) {
      if(!action?.key) return;

      switch(action.key) {
        case 'SIGN':
          EVENT_BUS.$emit(ANTRAG_SIGN_ACTION, true, false);
          break;
        case 'TOGGLE-E-SIGNATUR':
          EVENT_BUS.$emit(ANTRAG_E_SIGNATUR, this.isVorgelegt, true, false);
          break;
      }
    }
  }
}
</script>

<style>

</style>