<template>

  <div>
    <PageHeaderTitleNavigation title="Rundmail Status" />

    <div class="box__container" tid="1c773416-898a-43e9-905c-d9df1b7836e3">

      <Table
        tableId="4bae5e03-6d98-4c7a-8e9b-ff8b36152463"
        :title="TABLE_TITLE"
        class="table-container"
        v-if="!loading && tableRows.length"
        rowId="index"
        :headers="tableHeaders"
        :rows="tableRows"
        :rowsPerPage="20"
        :mobileConfig="{title: 'subject', headers: ['dateCreated', 'dateSend', 'anzahlEmpf']}"
      />
      <GhostLoading v-else-if="loading" type="table" :title="TABLE_TITLE" />
      <NoData v-else :title="TABLE_TITLE" />
    </div>    


  </div>
    
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import CORE_TYPES from "@/store/core/types";
import { mapGetters } from "vuex";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, NumberColumn} from "@/components/table2/table_util.js";
import { VIEW_ROLES } from '@/router/roles';
import RUNDMAIL_STATUS_TYPES from "@/store/rundmailstatus/types";

const TABLE_TITLE = 'Rundmail Status Liste';

export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    Table,
    OptionMenu,
    PageHeaderTitleNavigation,
    GhostLoading,
    NoData,
  },
  data: function () {
      return {
        TABLE_TITLE,
        resultCount: null,
        loading: true,
      }
  },
  computed: {
    ...mapGetters({
      rundmailStatusData: RUNDMAIL_STATUS_TYPES.GETTERS.RUNDMAIL_STATUS_DATA,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      isFA: CORE_TYPES.GETTERS.IS_FA,
    }),
    canSeeSpecialColumns() {
      return this.hasRoles(VIEW_ROLES.VIEW_BROKER_AS_INTERN);
    },
    tableHeaders() {
      return {
        lockedLeft: [
            TextColumn("dateCreated", "Erstellt").makeAlwaysVisible(),
            TextColumn("dateSend", "Versendet"),
            TextColumn("subject", "Betreff").addCellProps({lineClamp: 4}),
            NumberColumn("anzahlEmpf", "Anz. Empfänger", 0).alignCenter(),
            NumberColumn("anzahlVers", "davon versendet", 0).alignCenter(),
            NumberColumn("appAnz", "Anz. App", 0).alignCenter(),
            NumberColumn("appAnzVers", "App versendet", 0).alignCenter(),
        ],
        lockedRight: [],   
      };      
    },       
    tableRows () {
      if (!this.rundmailStatusData?.length ) {
        return [];
      }

      return this.rundmailStatusData.map((data, index) => ({
          index,
          dateCreated: data.dateCreated,
          dateSend: data.dateSend,
          subject: data.subject,
          eingestelltVon: data.eingestelltVon,
          anzahlEmpf: data.anzahlEmpf,
          anzahlVers: data.anzahlVers,
          appAnz: data.appAnz,
          appAnzVers: data.appAnzVers,
      }));




    },
  },
  mounted() {
    this.retrieveData();  
  },
  methods: {
    retrieveData() {
      this.loading = true;

      this.$store.dispatch(
              RUNDMAIL_STATUS_TYPES.ACTIONS.RETRIEVE_DATA, {}
            ).then(() => {
                this.loading = false;
            });  

    },
  },
  async beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu Rundmail Status', 
      fullPath: from.fullPath,
      breadcrumb: to?.meta?.breadcrumb?.label,
    });

    this.$store.commit(RUNDMAIL_STATUS_TYPES.MUTATIONS.RESET_STATE);

    next();
  },


    
}
</script>