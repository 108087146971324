<template>
  <BaseButton v-if="hasRoles([VIEW_ROLES.VIEW_BROKER_AS_BYPASS])" isClear title="Maklersicht" @click="openMaklerzugang()">
    <PhUserFocus :size="size" />
  </BaseButton>
</template>

<script>
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types'

import { PhUserFocus } from 'phosphor-vue';
import BaseButton from '@/components/core/BaseButton.vue';

export default {
  props: {
    size: {
      type: Number,
      default: 18,
    },
  },
  computed: {
    ...mapGetters({
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
  },
  methods: {
    openMaklerzugang() {
      this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_MAKLERZUGANG);
    },
  },
  components: {
    PhUserFocus,
    BaseButton,
  },
}
</script>
