var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseButton",
    {
      staticClass: "nav-item clickable",
      class: { "nav-item-bold": _vm.navItem.textBold },
      attrs: { isClear: "" },
      on: {
        click: function($event) {
          return _vm.handleNavigationAction(_vm.navItem)
        }
      }
    },
    [
      _vm.navItem.iconComponent
        ? _c(
            "span",
            { staticClass: "nav-item--icon" },
            [
              _c(_vm.navItem.iconComponent, {
                tag: "component",
                attrs: { size: 16 }
              })
            ],
            1
          )
        : _vm._e(),
      _c("span", {
        staticClass: "nav-item--label",
        domProps: { innerHTML: _vm._s(_vm.sanitize(_vm.navItem.label)) }
      }),
      _c(
        "span",
        { staticClass: "nav-item--arrow-icon" },
        [_c("PhCaretRight", { attrs: { size: 18 } })],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }