export const MUTATION_PREFIX = 'NAVIGATION_LOG: ';
export const ACTIONS_PREFIX = 'NAVIGATION_LOG_';
export const GETTERS_PREFIX = 'NAVIGATION_LOG_';

export default {
  MUTATIONS: {
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    ADD_ROUTE_CHANGE_TO_QUEUE: MUTATION_PREFIX + 'ADD_ROUTE_CHANGE_TO_QUEUE',
    SAVE_SCHEDULED_SEND_ROUTE_CHANGE: MUTATION_PREFIX + 'SAVE_SCHEDULED_SEND_ROUTE_CHANGE',
    TAKE_NAVIGATION_LOG_FROM_QUEUE: MUTATION_PREFIX + 'TAKE_NAVIGATION_LOG_FROM_QUEUE',
  },
  ACTIONS: {
    ROUTE_CHANGE: ACTIONS_PREFIX + 'ROUTE_CHANGE',
    GLOBAL_SEARCH: ACTIONS_PREFIX + 'GLOBAL_SEARCH',
    SCHEDULE_SAVE_ROUTE_CHANGE: ACTIONS_PREFIX + 'SCHEDULE_SAVE_ROUTE_CHANGE',
  },
  GETTERS: {

  },
}
