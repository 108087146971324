export const MUTATION_PREFIX = 'RISIKOKLASSEN_DEFINITION: ';
export const ACTIONS_PREFIX = 'RISIKOKLASSEN_DEFINITION_ACTIONS_';
export const GETTERS_PREFIX = 'RISIKOKLASSEN_DEFINITION_GETTERS_';

export default {
  MUTATIONS: {
    RESET: MUTATION_PREFIX + 'RESET',

    SET_RISIKOKLASSEN_DEFINITION: MUTATION_PREFIX + 'SET_RISIKOKLASSEN_DEFINITION',
    SET_RISIKOKLASSEN_DEFINITION_EDITED: MUTATION_PREFIX + 'SET_RISIKOKLASSEN_DEFINITION_EDITED',
    RESET_RISIKOKLASSEN_DEFINITION_EDITED: MUTATION_PREFIX + 'RESET_RISIKOKLASSEN_DEFINITION_EDITED',
    COPY_VORGABE_INTO_EINSTELLUNG: MUTATION_PREFIX + 'COPY_VORGABE_INTO_EINSTELLUNG',
  },
  ACTIONS: {
    GET_RISIKOKLASSEN_DEFINITION: ACTIONS_PREFIX + 'GET_RISIKOKLASSEN_DEFINITION',
    SAVE_RISIKOKLASSEN_DEFINITION: ACTIONS_PREFIX + 'SAVE_RISIKOKLASSEN_DEFINITION',
    DELETE_RISIKOKLASSEN_DEFINITION: ACTIONS_PREFIX + 'DELETE_RISIKOKLASSEN_DEFINITION',
  },
  GETTERS: {
    RISIKOKLASSEN_DEFINITION: GETTERS_PREFIX + 'RISIKOKLASSEN_DEFINITION',
    RISIKOKLASSEN_DEFINITION_EDITED: GETTERS_PREFIX + 'RISIKOKLASSEN_DEFINITION_EDITED',
    HAS_CONFIG: GETTERS_PREFIX + 'HAS_CONFIG',
    IS_CHANGE_ALLOWED: GETTERS_PREFIX + 'IS_CHANGE_ALLOWED',
    HAS_RISIKOKLASSE_ERRORS: GETTERS_PREFIX + 'HAS_RISIKOKLASSE_ERRORS',
  },
}
