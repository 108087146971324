import ACCOUNT_TYPES from './types';
import Vue from 'vue';

export default {
  [ACCOUNT_TYPES.MUTATIONS.GET_ACCOUNT_SUCCESS](state, payload) {
    Vue.set(state, 'accounts', Object.assign({}, payload));
  },
  [ACCOUNT_TYPES.MUTATIONS.GET_ACCOUNT_DEPOTSTAMMDATEN_SUCCESS](state, payload) {
    Vue.set(state, 'depotstammdaten', Object.assign({}, payload));
  },
  [ACCOUNT_TYPES.MUTATIONS.GET_FAILTURE](state, payload) {
    console.log(payload);
  },

}
