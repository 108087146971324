var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("InsuranceOptionMenu", {
        attrs: { title: "Vertragsstatus", headerActions: _vm.headerActions },
        on: {
          "action-ADD": function($event) {
            return _vm.createStatus()
          }
        }
      }),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("BoxContainer", [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c("InputField", {
                      attrs: { label: "Zugeh. Vertrag", disabled: true },
                      model: {
                        value: _vm.zugehVertrag,
                        callback: function($$v) {
                          _vm.zugehVertrag = $$v
                        },
                        expression: "zugehVertrag"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _vm.rows.length
                      ? _c("Table", {
                          attrs: {
                            headers: _vm.headers,
                            rows: _vm.rows,
                            rowsPerPage: 10
                          },
                          on: {
                            "action-delete": function($event) {
                              return _vm.confirmDeleteStatus($event)
                            },
                            "click-status": function($event) {
                              return _vm.editStatus($event)
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ])
            ])
          ],
          1
        )
      ]),
      _c("DeleteVertragStatusModal", {
        ref: "deleteModal",
        on: {
          delete: function($event) {
            return _vm.removeVertragStatus()
          }
        }
      }),
      _c("VertragStatusModal", {
        ref: "statusModal",
        on: {
          save: function($event) {
            return _vm.saveVertragStatus($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }