export const MUTATION_PREFIX = 'MSC_MARKETING: ';
export const ACTIONS_PREFIX = 'MSC_MARKETING_';
export const GETTERS_PREFIX = 'MSC_MARKETING_';

export default {
  MUTATIONS: {
    GET_MARKETING_CAMPAIGN_SUCCESS: MUTATION_PREFIX + 'GET_MARKETING_CAMPAIGN_SUCCESS',
    GET_PENDING_MARKETING_CAMPAIGNS_SUCCESS: MUTATION_PREFIX + 'GET_PENDING_MARKETING_CAMPAIGNS_SUCCESS',
    SET_USER_INTO_TARGET_LIST: MUTATION_PREFIX + 'SET_USER_INTO_TARGET_LIST',
    ADD_USER_INTO_TARGET_LIST: MUTATION_PREFIX + 'ADD_USER_INTO_TARGET_LIST',
    RESET_USER_INTO_TARGET_LIST: MUTATION_PREFIX + 'RESET_USER_INTO_TARGET_LIST',
    POSTPONE_MARKETING_CAMPAIGN: MUTATION_PREFIX + 'POSTPONE_MARKETING_CAMPAIGN',
    REMOVE_USER_FROM_TARGET_LIST: MUTATION_PREFIX + 'REMOVE_USER_FROM_TARGET_LIST',
    SET_EDITING_MARKETING_CAMPAIGN: MUTATION_PREFIX + 'SET_EDITING_MARKETING_CAMPAIGN',
    MARKETING_CAMPAIGN_CHANGED: MUTATION_PREFIX + 'MARKETING_CAMPAIGN_CHANGED',

    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
  },
  ACTIONS: {
    GET_MARKETING_CAMPAIGN_LIST: ACTIONS_PREFIX + 'GET_MARKETING_CAMPAIGN_LIST',
    GET_MARKETING_CAMPAIGN: ACTIONS_PREFIX + 'GET_MARKETING_CAMPAIGN',
    GET_MARKETING_CAMPAIGN_TARGET_LIST: ACTIONS_PREFIX + 'GET_MARKETING_CAMPAIGN_TARGET_LIST',
    MARK_AS_READ: ACTIONS_PREFIX + 'MARK_AS_READ',
    GET_PENDING_MARKETING_CAMPAIGNS: ACTIONS_PREFIX + 'GET_PENDING_MARKETING_CAMPAIGNS',
    SAVE_MARKETING_CAMPAIGN: ACTIONS_PREFIX + 'SAVE_MARKETING_CAMPAIGN',
    SAVE_TARGET_LIST: ACTIONS_PREFIX + 'SAVE_TARGET_LIST',
    DELETE_MARKETING_CAMPAIGN: ACTIONS_PREFIX + 'DELETE_MARKETING_CAMPAIGN',
    POSTPONE_MARKETING_CAMPAIGN: ACTIONS_PREFIX + 'POSTPONE_MARKETING_CAMPAIGN',
    ACKNOWLEDGE_MARKETING_CAMPAIGN: ACTIONS_PREFIX + 'ACKNOWLEDGE_MARKETING_CAMPAIGN',
    COPY_MARKETING_CAMPAIGN: ACTIONS_PREFIX + 'COPY_MARKETING_CAMPAIGN',
    LOAD_USER_ID_AS_TARGET: ACTIONS_PREFIX + 'LOAD_USER_ID_AS_TARGET',
  },
  GETTERS: {
    MARKETING_CAMPAIGNS: GETTERS_PREFIX + 'MARKETING_CAMPAIGNS',
    MARKETING_CAMPAIGNS_COUNT: GETTERS_PREFIX + 'MARKETING_CAMPAIGNS_COUNT',
    PENDING_MARKETING_CAMPAIGNS: GETTERS_PREFIX + 'PENDING_MARKETING_CAMPAIGNS',
    TARGET_LIST: GETTERS_PREFIX + 'TARGET_LIST',
    TARGET_LIST_INCLUDED: GETTERS_PREFIX + 'TARGET_LIST_INCLUDED',
    TARGET_LIST_EXCLUDED: GETTERS_PREFIX + 'TARGET_LIST_EXCLUDED',
    EDITING_MARKETING_CAMPAIGN: GETTERS_PREFIX + 'EDITING_MARKETING_CAMPAIGN',
    MARKETING_CAMPAIGN_CHANGED: GETTERS_PREFIX + 'MARKETING_CAMPAIGN_CHANGED',
  }
}