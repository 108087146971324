<template>
  <div class="stepper-item__container" @click="$emit('click',  $event)"
    :class="{ 'stepper-item--selected': isCurrent, 'clickable': substep && substep.title}"
    @mouseover="handleMouseOver($event)" @mouseleave="handleMouseLeave($event)"
    :tid="_generateTidFromString(substep.title)">
    <div class="stepper-item__icon">
      <div class="vertical-substep__icon-container">
        <span :class="substepIconClass"></span>
        <PhWarningCircle 
          class="color-danger vertical-stop__icon" 
          v-if="!collapsed && hasWarning"
          :size="16" />
      </div>
    </div>
    <span class="vertical-substep__label" :class="{ 'vertical-substep__label--selected': isCurrent}">
      <span v-html="sanitize(substep.title)"></span>
    </span>
  </div>
</template>

<script>
import verticalStepperItemMixin from './vertical-stepper-item-mixin';
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import {sanitize} from '@/helpers/string-helper.js';
import { PhWarningCircle } from 'phosphor-vue'

export default {
  mixins: [verticalStepperItemMixin, InteractiveHelpCommonsMixin],
  props: {
    substep: {
    },
    isCurrent: {
      type: Boolean,
      default: false
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PhWarningCircle,
  },
  computed: {
    /* If a substep has no title, there is probably only one substep in the step, no need to mark it with warning. In this case only the step is marked with warning. */
    hasWarning() {
      return this.substep?.title && this.substep?.warning || false;
    },
    substepIconClass() {
      return this.collapsed && this.hasWarning ? 'vertical-stop__substep__warning-pipe' : 'vertical-stop__substep-pipe';
    }
  },
  methods: {
    sanitize(htmlString) {
      return sanitize(htmlString);
    },
  },
}
</script>

<style src='./vertical-stepper.scss' lang='scss' scoped></style>