<template>
  <div class="antrag-step__container box__container">
    <VerticalStepper
      ref="stepper"
      :stepType="stepType"
      :visible="showStepper"
      :stepperName="stepperName"
      :selectedStepKey="selectedStep"
      :selectedSubstepKey="selectedSubstep"
      :stepperService="getStepperMediator"
      :antragData="configData"
      :showSaveSpinner="showSaveSpinner"
      :showCollapse="true"
      :disabled="disabled"
      @setStep="$emit('setStep', $event)"
      @setSubstep="$emit('setSubstep', $event)"
      @collapsed="$emit('collapsed', $event)"
      @zusammenfassung="$emit('zusammenfassung', $event)"
    ></VerticalStepper>
  </div>
</template>

<script>
import { StepperMediator } from '@/components/stepper/utils';
import VerticalStepper from '@/components/vertical-stepper/VerticalStepper.vue';

export default {
  props: {
    configStep: {
    },
    configData: {
    },
    selectedStep: {
    },
    selectedSubstep: {
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      stepType: 'tab',
      showStepper: true,
      stepperName: 'name?',
      showSaveSpinner: false,
    }
  },
  computed: {
    getStepperMediator() {
      if (this.configStep && this.configData) {
        return new StepperMediator(JSON.parse(JSON.stringify(this.configStep)));
      }
      return null;
    },
  },
  components: {
    VerticalStepper,
  },
  methods: {
    setStepHidden(stepKey, hidden = true) {
      this.getStepperMediator?.setStepHidden(stepKey, hidden);
      this.updateStepper();
    },
    updateStepper() {
      this.$refs.stepper?.updateStepList();
    },
  },
}
</script>

<style scoped>
  .antrag-step__container {
    border: none;
  }
</style>