import CUSTOMER_CATEGORY_TYPES from './types';


export default {

  [CUSTOMER_CATEGORY_TYPES.GETTERS.CUSTOMER_CATEGORY](state) {
    return { ...(state.customerCategory || {}), };
  },

  [CUSTOMER_CATEGORY_TYPES.GETTERS.CUSTOMER_CATEGORY_EDITED](state) {
    return { ...(state.customerCategoryEdited || {}), };
  },

  [CUSTOMER_CATEGORY_TYPES.GETTERS.HAS_CUSTOMER_CATEGORY_EDITED](state) {
    return Object.keys(state.customerCategoryEdited || {}).length > 0;
  },

}
