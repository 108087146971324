var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "showDeeplinksModal",
      attrs: {
        modalTitle: "Deeplinks",
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false
      }
    },
    [_c("InsuranceDeeplinksList", { attrs: { vertragId: _vm.vertragId } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }