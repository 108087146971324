import actions from './actions';
import mutations from './mutations';
import getters from './getters';

/**
 * mutations: synchronous operation responsible for change the state
 * actions: asynchronous operation responsible for call external resources and commit mutations 
 */
export function getInitialState() {
  return {
    credits: [],
    selectedCreditId: null,
    combos: {},
    credit: {},
    creditEdited: {},
    updates: [],
    status: [],
    appointments: [],
    prohypCombos: {},
    prohyp: {},
    prohypEdited: null,
    rights: {},
    buchungen: [],
    selectedBuchung: {},
    buchungProvStrukturen: {}, // indexed by buchungsnr
    hints: [],
    documents: [], // TODO remove after migrate Kredit Dokumente to Dokumentenarchiv
  }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}