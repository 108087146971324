/*
* Map old table structure to new one
*/
export function mapNewTableStructure(tableData, priority = {}, sum = {}, isActionAvailable = false, autoSum = {}, ) {
    return {
        ...tableData,
        records: tableData && tableData.records && tableData.records.map(record => 
            record
                .filter((v, i) => isActionAvailable || tableData.tableHeaders[i].dataType !== 'Action')
                .reduce((acc, row, index) => ({
                ...acc,
                [tableData.tableHeaders[index].key]: row
            }), {})
        ) || [],
        headers: tableData.tableHeaders
            .filter(v => isActionAvailable || v.dataType !== 'Action')
            .reduce((acc, curr) => ({
            ...acc,
            [curr.key]: {
                dataType: curr.dataType,
                filterable: false,
                label: curr.label,
                priority: priority[curr.key] || curr.priority ? 'VISIBLE_ALWAYS' : 'HIDDEN',
                visible: true,
                key: curr.key,
                sortable: false,
                sum: sum[curr.key] || false,
                autoSum: autoSum[curr.key]
            }
        }), {})
    }
}
