var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.editMode
        ? _c(
            "BaseButton",
            {
              staticClass: "mb-16px",
              on: {
                click: function($event) {
                  return _vm.neueZahlung()
                }
              }
            },
            [_vm._v("Neue Zahlung hinzufügen")]
          )
        : _vm._e(),
      _c(
        "div",
        [
          _vm.tableData.records.length
            ? _c("Table", {
                attrs: {
                  tableId: "3cd44c85-12b2-4625-b32a-520e4bf6564a",
                  tableData: _vm.tableData,
                  actions: _vm.tableActions,
                  cardViewEnabled: false,
                  filterEnabled: false,
                  exportEnabled: false,
                  rowsPerPage: _vm.tableData.records.length
                },
                on: {
                  "execute-action": function($event) {
                    return _vm.handleTableAction($event)
                  }
                }
              })
            : _c("div", [_vm._v("Keine Daten")])
        ],
        1
      ),
      _c("BaseModal", {
        ref: "zahlungModal",
        attrs: { showDefaultButtons: false },
        on: {
          close: function($event) {
            return _vm.cancelZahlungEditing()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "modalTitle",
            fn: function() {
              return [
                _vm._v(
                  _vm._s(
                    _vm.zahlungInserting === true
                      ? "Neue Zahlung"
                      : "Zahlung Bearbeiten"
                  )
                )
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _c("InputField", {
                  attrs: {
                    label: "Zugehöriger Schadensfall",
                    type: "text",
                    disabled: true,
                    value: _vm.sparte
                  }
                }),
                _c("ComboBox", {
                  attrs: {
                    label: "Zugehöriger Beteiligter",
                    values: _vm.driversValues,
                    firstEmpty: true,
                    validateUntouched: true
                  },
                  model: {
                    value: _vm.zahlung_form.schaden_beteiligter_id,
                    callback: function($$v) {
                      _vm.$set(_vm.zahlung_form, "schaden_beteiligter_id", $$v)
                    },
                    expression: "zahlung_form.schaden_beteiligter_id"
                  }
                }),
                _c("ComboBox", {
                  attrs: {
                    label: "Zahlgrund",
                    values: _vm.zahlungsGrundValues,
                    firstEmpty: true
                  },
                  model: {
                    value: _vm.zahlung_form.zahlgrund,
                    callback: function($$v) {
                      _vm.$set(_vm.zahlung_form, "zahlgrund", $$v)
                    },
                    expression: "zahlung_form.zahlgrund"
                  }
                }),
                _c("InputField", {
                  attrs: { label: "Betrag (in EUR)" },
                  model: {
                    value: _vm.zahlung_form.zahlbetrag,
                    callback: function($$v) {
                      _vm.$set(_vm.zahlung_form, "zahlbetrag", $$v)
                    },
                    expression: "zahlung_form.zahlbetrag"
                  }
                }),
                _c("DatePickerField", {
                  attrs: { label: "Datum" },
                  model: {
                    value: _vm.zahlung_form.zahldat,
                    callback: function($$v) {
                      _vm.$set(_vm.zahlung_form, "zahldat", $$v)
                    },
                    expression: "zahlung_form.zahldat"
                  }
                }),
                _c("ComboBox", {
                  attrs: {
                    label: "Zahlungsart",
                    values: _vm.zahlungsWebSelectionValues,
                    firstEmpty: true
                  },
                  model: {
                    value: _vm.zahlung_form.zahlweg,
                    callback: function($$v) {
                      _vm.$set(_vm.zahlung_form, "zahlweg", $$v)
                    },
                    expression: "zahlung_form.zahlweg"
                  }
                }),
                _c("InputField", {
                  attrs: { label: "Bemerkung" },
                  model: {
                    value: _vm.zahlung_form.bemerkung,
                    callback: function($$v) {
                      _vm.$set(_vm.zahlung_form, "bemerkung", $$v)
                    },
                    expression: "zahlung_form.bemerkung"
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c(
                  "BaseButton",
                  {
                    staticClass: "mr-16px",
                    attrs: { isSecondary: "", disabled: _vm.loading },
                    on: {
                      click: function($event) {
                        return _vm.closeZahlungModal()
                      }
                    }
                  },
                  [_vm._v("Abbrechen")]
                ),
                _c(
                  "BaseButton",
                  {
                    attrs: { disabled: _vm.loading },
                    on: {
                      click: function($event) {
                        return _vm.zahlungSpeichern()
                      }
                    }
                  },
                  [
                    _vm._v(" Zahlung speichern "),
                    _vm.loading ? _c("AnimatedSpinner") : _vm._e()
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "BaseModal",
        {
          ref: "zahlungDeleteModal",
          attrs: { modalTitle: "Eintrag löschen" },
          on: {
            onConfirmButton: function($event) {
              return _vm.deleteZahlung(_vm.zahlungToDelete)
            },
            close: function($event) {
              return _vm.clearZahlungToDelete()
            }
          }
        },
        [
          _vm.zahlungToDelete
            ? _c("div", [
                _vm._v(
                  ' "' +
                    _vm._s(_vm.zahlungToDelete.zahldat) +
                    " - " +
                    _vm._s(_vm.zahlungToDelete.zahlbetrag) +
                    " " +
                    _vm._s(_vm.zahlungToDelete.waehrung) +
                    '" wirklich löschen? '
                )
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }