var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ergebnis-risk-return-chart" }, [
    _c("div", [
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c(
            "BaseButton",
            {
              staticClass: "m-1",
              attrs: { disabled: _vm.loading },
              on: { click: _vm.aktualisieren }
            },
            [_vm._v(" Aktualisieren ")]
          ),
          _c(
            "BaseButton",
            {
              staticClass: "m-1",
              attrs: { disabled: !_vm.applicable || _vm.loading },
              on: { click: _vm.applyAllocation }
            },
            [_vm._v(" Verteilung übernehmen ")]
          )
        ],
        1
      ),
      _vm.loading
        ? _c(
            "div",
            [
              _c(
                "GhostLoading",
                { staticClass: "box__container" },
                [_c("Block", { attrs: { height: "150" } })],
                1
              ),
              _c(
                "GhostLoading",
                { staticClass: "box__container" },
                [_c("Block", { attrs: { height: "350" } })],
                1
              )
            ],
            1
          )
        : _c(
            "div",
            { ref: "optimierung" },
            [
              _vm.selected.name
                ? _c("Table", {
                    staticClass: "box__container",
                    attrs: {
                      title: _vm.selected.name,
                      headers: _vm.headers,
                      rows: _vm.rows,
                      rowId: "name",
                      hidePagination: ""
                    }
                  })
                : _vm._e(),
              _c("ScatterChart", {
                staticClass: "box__container",
                attrs: {
                  yAxisName: "Historische Rendite %",
                  xAxisName: "Historisches Risiko %",
                  height: "500px",
                  series: _vm.eChartSeries,
                  tooltip: _vm.eChartTooltip,
                  changeOption: _vm.changeOption
                },
                on: {
                  click: function($event) {
                    return _vm.eChartOnMarkerClick($event)
                  }
                }
              })
            ],
            1
          )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }