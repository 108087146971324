var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    _vm._l(_vm.newsList, function(news, index) {
      return _c("div", { key: index, staticClass: "col-12" }, [
        _c("h4", [
          _c("a", { attrs: { href: news.link, target: "_blank" } }, [
            _vm._v(_vm._s(news.title))
          ])
        ]),
        _c("h6", [_vm._v(_vm._s(news.pubDate))]),
        _c("p", [_vm._v(_vm._s(news.desc))]),
        _c("hr")
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }