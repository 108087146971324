import ZUORDNUNG_GESELLSCHAFTEN_BIPRO_TYPES from './types';
import CORE_TYPES from '@/store/core/types'
import axios from 'axios';
import { buildMessage, } from '@/helpers/log-message-helper';
import LOG_TYPES from '@/store/log/types';

const config = {
    defaultSpinner: true
}

export default {
    async [ZUORDNUNG_GESELLSCHAFTEN_BIPRO_TYPES.ACTIONS.GET_GESELLSCHAFT_KENNUNGEN]({ dispatch, commit, getters }, { extZugangBezeichnung }) {
        const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/biProGesellschaftZuordnen/getGesellschaftKennungen`;
        
        let params = {};
    
        if (extZugangBezeichnung) {
            params.extZugangBezeichnung = extZugangBezeichnung;
        }
    
        try {
            const response = await axios.get(url, { params, config });
            const data = response.data;
    
            commit(ZUORDNUNG_GESELLSCHAFTEN_BIPRO_TYPES.MUTATIONS.GET_GESELLSCHAFT_KENNUNGEN_SUCCESS, data);
            return data;
        } catch (error) {
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error?.response?.data, 'danger'));
            throw error;
        }
    },
    async [ZUORDNUNG_GESELLSCHAFTEN_BIPRO_TYPES.ACTIONS.SAVE]({ dispatch, getters }, payload) {
        const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/biProGesellschaftZuordnen/save`;
    
        try {
            const response = await axios.post(url, payload, config);
            return response.data;
        } catch (error) {
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error?.response?.data, 'danger'));
            throw error;
        }
    },
    async [ZUORDNUNG_GESELLSCHAFTEN_BIPRO_TYPES.ACTIONS.SEARCH]({ dispatch, commit, getters }, payload) {
        const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/biProGesellschaftZuordnen/startSearch`;

        if (!payload?.id && !payload.name) {
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Stellen Sie "Name" und/oder "Id" ein', 'danger'));
            return;           
        }


        let params = {};
    
        if (payload?.id) {
            params.id = payload?.id;
        }

        if (payload?.name) {
            params.name = payload?.name;
        }
    
        try {
            const { data } = await axios.get(url, {  params, config });
    
            commit(ZUORDNUNG_GESELLSCHAFTEN_BIPRO_TYPES.MUTATIONS.SEARCH_SUCCESS, data);
            return data;
        } catch (error) {
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error?.response?.data, 'danger'));
            throw error;
        }
    },
    async [ZUORDNUNG_GESELLSCHAFTEN_BIPRO_TYPES.ACTIONS.REMOVE_ROW]({ dispatch, getters }, payload) {
        const url = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/biProGesellschaftZuordnen/delete`;
    
        try {
            const response = await axios.delete(url, { data: payload, ...config });
            return response.data;
        } catch (error) {
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Es gab einen Fehler beim Speichern der Daten', 'danger'));
            throw error;
        }
    },

}