<template>
  <div class="event-full-width">
    <template v-for="(event, index) in eventsGhost">
      <HourEventVerticalDiv
        class="hour-event-vertical__container"
        :key="index"
        :dimensions="getDimensions"
        :verticalcontent="getVerticalcontent(event)"
        :index="index"
        :contentarray="contentarray"
        :ghost="event.ghost"
        @editEvent="editEvent"
      />
    </template>
  </div>
</template>
<script>
import dayjs from "dayjs";

import CALENDAR_TYPES from "@/store/calendar/types";
import HourEventVerticalDiv from "@/components/calendar/HourEventVerticalDiv.vue";

const EVENT_HEIGHT = 4;
const EVENT_SIZE_UNIT = 'em';
const MINUTES = 60;

export default {
  components: {
    HourEventVerticalDiv
  },
  props: {
    contentarray: {
      type: Array,
      default: () => [],
      required: true
    },
    dimensions: {
      type: Object,
      default: () => {},
      required: true
    },
    width: {
      type: String,
      default: () => "",
      required: true
    },
    events: {
      type: Array,
      default: () => [],
      required: true
    },
  },

  computed: {
    getWidth() {
      return this.width;
    },
    getInitialHeight() {
      return this.dimensions.initialHeight;
    },
    isContentEmpty() {
      return this.contentarray.length === 0;
    },

    getDimensions() {
      const dimensionsArray = [];

      for (let inx = 0; inx < this.events.length; inx++) {
        const event = this.events[inx];
        const eventStart = dayjs(event.startTime, 'HH:mm');
        const eventEnd = dayjs(event.endTime, 'HH:mm');
        const hours = eventEnd.diff(eventStart, 'hour', true);
        dimensionsArray.push({
          height: `${EVENT_HEIGHT * hours}${EVENT_SIZE_UNIT}`,
          margin: `${EVENT_HEIGHT * (eventStart.format('m') / MINUTES)}${EVENT_SIZE_UNIT}`,
        });
      }

      return dimensionsArray;
    },
    eventsGhost() {
      return this.events
    },
  },
  methods: {
    getVerticalcontent(event) {
      return !event.ghost ? this.contentarray.find(a => a.event.id == event.id) || {event, isStart: true, isEnd: true} : {event, isStart: true, isEnd: true};
    },
    contentArrayIndex(event) {
      return this.contentarray.findIndex(a => a.event.id == event.id)
    },
    showEvent() {
      alert(
        this.contentitem.event.label +
          "\n" +
          this.contentitem.event.startTime +
          "\n" +
          this.contentitem.event.endTime
      );
    },
    setSelectedEvent() {
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_SELECTED_EVENT, {
        appointment: this.contentitem.event,
        day: null
      });
    },
    editEvent(e) {
      this.$emit('editEvent', e);
    },
  },
};
</script>
<style scoped>
.event-full-width {
  display: flex;
}
.hour-event-vertical__container {
  flex: 1;
}
.eventsnum-ghost {
  width: 0;
}
</style>
