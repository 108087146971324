var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("GesellschaftTitleNavigation"),
      _c("BaseFilter", {
        attrs: {
          filterId: "790ca065-681f-4a9f-952f-ec8c993ca818",
          title: "News filtern",
          metadata: _vm.filterMetadata,
          configFilter: _vm.configFilter,
          hasSmartSearch: ""
        },
        on: {
          onFilter: function($event) {
            return _vm.getNews($event)
          }
        }
      }),
      _c(
        "div",
        {
          staticClass: "box__container",
          attrs: {
            tid: _vm._generateTidFromString(
              "cards__container layout__negative-margin--8"
            )
          }
        },
        [
          _c("div", { staticClass: "box__title" }, [_vm._v("News")]),
          _vm.newsRecords && _vm.newsRecords.length
            ? _c("Table", {
                attrs: {
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowsPerPage: 20,
                  hidePagination: ""
                },
                on: {
                  "click-title": function($event) {
                    return _vm.openNews($event)
                  }
                }
              })
            : _vm.loading
            ? _c("GhostLoading", { attrs: { type: "table" } })
            : _c("NoData", { attrs: { content: "keine News vorhanden" } })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "newsModal",
          attrs: {
            modalTitle: "Gesellschafts Nachricht",
            showConfirmButton: false,
            labelButtonCancel: "Schließen",
            size: "lg"
          }
        },
        [
          _c("div", {
            domProps: { innerHTML: _vm._s(_vm.sanitize(_vm.newsPanelTitle)) }
          }),
          _c("br"),
          _c("div", {
            domProps: { innerHTML: _vm._s(_vm.sanitize(_vm.newsPanelBody)) }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }