var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "risiko_hinweise_container" }, [
      _c("div", { staticClass: "banner" }, [_vm._v(" RISIKOHINWEISE ")]),
      _c("hr"),
      _c("div", { staticClass: "zwei_spalte_wrapper" }, [
        _c("div", { staticClass: "zwei_spalte" }, [
          _c("div", [
            _c("div", { staticClass: "inner_zwei_spalte_header" }, [
              _vm._v("Risikohinweise bei der Anlage in Investmentfonds")
            ]),
            _c("div", { staticClass: "inner_zwei_spalte_text" }, [
              _c("p", [
                _vm._v(
                  " Keine Anlage ist ohne Risiko. Der Wert eines Investments kann während der Laufzeit einer Vielzahl von Marktrisiken, wie dem Zinsrisiko, dem Kursrisiko, dem Schwankungsrisiko, dem Währungsrisiko, dem Bonitätsrisiko anderer Marktteilnehmer usw. unterliegen. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Aufgrund ungünstiger Entwicklungen dieser Faktoren kann das eingesetzte Kapital zuzüglich der entstandenen Kosten vollständig verloren sein. Bei der Investition in Investmentfonds ist das Totalverlustrisiko durch die breite Streuung sehr unwahrscheinlich. Selbst wenn einzelne Wertpapiere einen Totalverlust erleiden sollten, ist ein Gesamtverlust durch die breite Diversifikation unwahrscheinlich. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Ein Investmentfonds ist ein Sondervermögen, das nach dem Grundsatz der Risikostreuung in verschiedenen Einzelanlagen (z.B.in Aktien, Anleihen, wertpapierähnlichen Anlagen oder Immobilien) investiert ist. Ein Depotinhaber kann mit dem Kauf von Investmentanteilen an der Wertentwicklung sowie den Erträgen des Fondsvermögens teilhaben und trägt anteilig das volle Risiko der durch den Anteilschein repräsentierten Anlagen. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Bei Investmentfonds, die auch in auf fremde Währung lautende Wertpapiere investieren bzw. in Fremdwährung geführt werden, muss berücksichtigt werden, dass sich neben der normalen Kursentwicklung auch die Währungsentwicklung negativ im Anteilspreis niederschlagen kann und Länderrisiken auftreten können, auch wenn die Wertpapiere, in die der Investmentfonds investiert, an einer deutschen Börse gehandelt werden. Durch die Aufwertung des Euro (Abwertung der Auslandswährung) verlieren die ausländischen Vermögenspositionen – am Maßstab des Euro betrachtet – an Wert. Zum Kursrisiko ausländischer Wertpapiere kommt damit das Währungsrisiko hinzu. Die Währungsentwicklung kann einen Gewinn aufzehren und die erzielte Rendite so stark beeinträchtigen, dass eine Anlage in Euro oder in Vermögenspositionen der Eurozone unter Umständen vorteilhafter gewesen wäre. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Die Angaben über die bisherige Wertentwicklung stellen keine Prognose für die Zukunft dar. Zukünftige Ergebnisse der Investmentanlage sind insbesondere von den Entwicklungen der Kapitalmärkte abhängig. Die Kurse an der Börse können steigen und fallen. Investmentfonds unterliegen dem Risiko sinkender Anteilspreise, da sich Kursrückgänge der im Fonds enthaltenen Wertpapiere im Anteilspreis widerspiegeln "
                )
              ])
            ])
          ]),
          _c("div", [
            _c("div", { staticClass: "inner_zwei_spalte_header" }, [
              _vm._v("Rechtliche Hinweise zur Nutzung des Fondsshops")
            ]),
            _c("div", { staticClass: "inner_zwei_spalte_text" }, [
              _c("p", [
                _vm._v(
                  " Aufträge innerhalb des Fondsshops werden für Sie als beratungsfreies Geschäft ausgeführt. Auf Empfehlungen und Beratungen für den Kauf, Verkauf oder das Halten von Wertpapieren verzichten wir bei diesem Angebot, um Ihnen attraktive Konditionen anbieten zu können. Wir erbringen hierbei keine Anlageberatung (execution only). "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Zur Verfügung gestellte Informationen innerhalb des „Fondsshops“ zu den angebotenen Einzelfonds und den Modellportfolios, wie auch deren zugrundeliegenden Investmentfonds stellen keine Empfehlung zum Kauf oder Verkauf und auch keine Anlageberatung dar. Eine individuelle Beratung oder die Abgabe von Empfehlungen wird hierbei vom Vertragspartner nicht erbracht und ist vom/von den Kunden ausdrücklich nicht gewünscht. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Jegliches Angebot innerhalb des Fondsshops richtet sich an gut informierte, risikobewusste und erfahrene Anleger und beschränkt sich auf die Weiterleitung des vom Kunden selbständig erteilten Auftrages an die FFB (FIL Fondsbank GmbH). Aufträge innerhalb des Fondsshops tätigt der Kunde selbständig im Rahmen seiner eigenen Kenntnisse und Markterfahrungen. Die Anlageentscheidung für ein Modellportfolio und die zugrundeliegenden Investmentfonds oder der individuellen Auswahl von Einzelfonds innerhalb des „Fondsshops“ wird vom Kunde allein auf Grundlage der ihm vorliegenden Informationen getroffen. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Der Finanzanlagenvermittler und assoziierte Unternehmen prüfen nicht, ob das vom Anleger ausgewählte Modellportfolio oder ausgewählte Einzelfonds innerhalb des Fondsshops mit eventuellen Anlagezielen des Kunden übereinstimmen. Ebenfalls prüft der Finanzanlagenvermittler und assoziierte Unternehmen nicht, ob der Kunde über die erforderlichen Kenntnisse und Erfahrungen verfügt, um die Risiken seiner eigenverantwortlich getroffenen Anlageentscheidung abzuschätzen. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Der Finanzanlagenvermittler leitet die Aufträge des Kunden lediglich zur Ausführung an die depotführende Bank FFB (FIL Fondsbank GmbH) weiter („Execution Only“). Es handelt sich hierbei um ein sogenanntes reines Ausführungsgeschäft. "
                )
              ]),
              _c("p", [
                _vm._v(
                  " Trotz sorgfältiger Auswahl der Quellen und Prüfung der Inhalte übernimmt weder der Finanzanlagenvermittler noch eines seiner assoziierten Unternehmen irgendeine Art von Haftung für die Verwendung des Fondsshops. Es kann keine Gewähr für die Richtigkeit oder Vollständigkeit der in dieser Publikation gemachten Angaben übernommen werden, und keine Aussage in dieser Ausarbeitung ist als solche Garantie zu verstehen. Der Wert jedes Investments und der Ertrag daraus können sowohl sinken als auch steigen, und Sie erhalten möglicherweise nicht den investierten Gesamtbetrag zurück. In der Vergangenheit erzielte Performance ist kein verlässlicher Indikator für zukünftige Entwicklungen. Die gewählten Betrachtungszeiträume dienen ausschließlich dem Zweck, über die Wertentwicklung der genannten Wertpapiere oder Modellportfolien zu informieren. Darüber hinaus dürfen aus diesen Informationen keine Aussagen zu Eigenschaften der genannten Wertpapiere oder Modellportfolien abgeleitet werden, ebenso nicht zu deren künftigen Entwicklung. Bei Modellportfolien handelt es sich um artifiziell geführte Portfolien. Diese werden parallel zu den reellen Mandaten geführt, aber nicht konkret investiert. Ihre Wertentwicklung kann von der Wertentwicklung eines reellen Portfolios abweichen. "
                )
              ]),
              _c("p", [
                _vm._v(
                  "Datenquellen: Fondsdaten FWW GmbH: Disclaimer (http://fww.de/disclaimer/)"
                )
              ]),
              _c("p", [
                _vm._v(
                  " Für Inhalte und Richtigkeit der Angaben wird keine Haftung übernommen. Hinweis: Die angezeigten Kurse können vom Kurs zum Zeitpunkt des Kaufes abweichen. "
                )
              ])
            ])
          ]),
          _c("div")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }