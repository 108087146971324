<template>
	<CoreCard>
		<template v-slot:header>
			test header BrokerOverviewDataCard
		</template>
		<template v-slot:alternative>
			test alternative
		</template>
		test content BrokerOverviewDataCard
	</CoreCard>
</template>

<script>
import CoreCard from '../CoreCard/CoreCard'

export default {
  components: {
	CoreCard
  },
}
</script>

<style scoped>
</style>