<template>
    <div v-if="vertragsdatenFieldsDefinition">

        <div class="bottom-line" v-for="row in limitedRows" v-bind:key="row.id">

            <div class="bottom-line-content">
                <div class="container">
                    <span class="title">{{row.courtageart}}</span>
                    <span class="chapter" v-if="editable">{{row.provBetrag | currency}}</span>
                    <span class="chapter" v-else>{{row.rechDatum || row.buchungsnr}}</span>
                </div>

            </div>
            
           

            <div class="bottom-line" v-for="subRow in row.courtageDesVermittlers" v-bind:key="subRow.lfdNr">

                <div class="bottom-line-content">
                    <div class="container" >
                        <div >
                            <span v-if="subRow.maklernr">Vermittlernr: {{subRow.maklernr}}</span>
                        </div>

                        <div class="bottom-line-side-content">
                            <div class="overflow">Prov. Betrag: {{subRow.betrag | currency}}</div>
                        </div>

                    </div>
                </div>

            
            </div>

        </div>


    </div>
</template>


<script>
import BoxContainer from "@/components/core/BoxContainer.vue";
import { PhPencilLine, PhBookOpen } from 'phosphor-vue';
import { mapGetters } from 'vuex'
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";
import CORE_TYPES from '@/store/core/types';
import SCHADENSMELDUNG_TYPES from "@/store/schadensmeldung/types";
import {toDateString, toDateTimeString} from '@/filters.js';



const PRECISION = 2;


export default {
//   mixins: [InsuranceDetailMixin],
    components: {
        BoxContainer,
        PhPencilLine,
        PhBookOpen,
    },
    data: function () {
            return {
                title: "Allgemeine Vertragsdaten"
            }
    },
    computed: {
        ...mapGetters({
        abrechnungen: VERSICHERUNG_TYPES.GETTERS.INSURANCE_ABRECHNUNGEN,
        versicherungenDetails: VERSICHERUNG_TYPES.GETTERS.INSURANCE_DETAILS,
        isFA: CORE_TYPES.GETTERS.IS_FA,
        isFK: CORE_TYPES.GETTERS.IS_FK,
        hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
        vertragsdatenFieldsDefinition: VERSICHERUNG_TYPES.GETTERS.INSURANCE_VERTRAGSDATEN_FIELDS_DEFINITION,
        schadensmeldungen: SCHADENSMELDUNG_TYPES.GETTERS.SCHADENSMELDUNG,
        }),
        schadenInfos() {
            return this.versicherungenDetails?.schadenInfos || [];
        },
        limitedRows() {
            return this.rows.slice(0, 10);
        },        
        rows() {
            if (!this.abrechnungen) {
                return [];
             }
            return [...this.abrechnungen].map(item => {
                const record = {
                ...item,
                };
                record.bewertungssumme = (item.bewertungssumme || 0).toFixed(PRECISION);
                record.courtagesumme = (item.courtagesumme || 0).toFixed(PRECISION);
                record.istOverhead = item.istOverhead === true ? 'Ja' : 'Nein';
                return record;
            });
        },

        betreungsstufeComputedValues() {
            let betreungsstufeValues = this.vertragsdatenFieldsDefinition.betreuungsstufe.value;
            if('FPPLUS_GEWERBE' === this.vertragsdatenFieldsDefinition.anbindung.value[0].value || 'FPPLUS_GEWERBE' === this.versicherung.anbindung ) {
                //removing 'Ohne' value for FP+ Gewerbe GmbH
                betreungsstufeValues = this.vertragsdatenFieldsDefinition.betreuungsstufe.value.filter(item => item.value !== 'Ohne');
                return betreungsstufeValues;
            }
            return betreungsstufeValues;
        },
        editable() {
            return this.versicherungenDetails.insurance.allowNewBuchung;
        },
    },  
    methods: {
        currency(value) {
            if (isNaN(value)) {
                return value;
            }
            let val = (value / 1).toFixed(2).replace('.', ',');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' €';
        },
        dateToString(date) {
            return toDateString(date)
        }, 
         dateTimeToString(date) {
            return toDateTimeString(date)
        },
    },
    filters: {
        currency(value) {
            if (isNaN(value)) {
                return value;
            }
            let val = (value/1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €';
        },
    },    
    mounted() {

    },
    validators: {},
};
</script>

<style scoped>

.align-end {
  flex: 1 1 auto;
  align-self: flex-end;
  padding: 0px 0px 16px 5px;
  color: var(--color-text);
}
.form-control {
  display: flex;
  align-content: stretch;
  align-items: center;
}
.container {
  display: flex;
  justify-content: space-between;
}

.overflow {
  overflow: hidden;
}

.bottom-line {
  padding-bottom: 4px;
}
.bottom-line:last-child { border-bottom: none; }
.bottom-line-content {
    flex: 1 1 auto;
    max-width: calc(100% - 4px);
}

.bottom-line-side-content {
  display: flex;
  font-size: 0.9rem;
  max-width: 50%;
  min-inline-size: fit-content;
}

.subject {
  max-width: 95%;
  font-size: 0.9rem;
}
</style>