var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DragnDropArea",
        {
          staticClass: "simple-additional-documents-table__dropzone",
          attrs: {
            hoverText: "Neues Dokument hochladen",
            disabled: !_vm.canUpdateDocumentArchive || _vm.listViewOnly
          },
          on: {
            files: function($event) {
              return _vm.openDocumentUploadModal($event)
            }
          }
        },
        [
          _c(
            "div",
            [
              _vm.isLoadingInternal
                ? _c("GhostLoading", {
                    attrs: { title: _vm.title, type: "table" }
                  })
                : _c("Table", {
                    attrs: {
                      tableId: "b363a7c6-0140-4607-b86e-b630ba53c9f8",
                      title: _vm.title,
                      headers: _vm.headers,
                      rows: _vm.rows,
                      rowsPerPage: 10,
                      rowId: _vm.customDocuments ? "fileId" : "id",
                      headerActions: _vm.tableHeaderActions
                    },
                    on: {
                      "click-name": _vm.showDocument,
                      "action-DELETE": _vm.deleteDocument,
                      "action-DOKUMENT_LOSCHEN": _vm.deleteDocument,
                      "action-DOKUMENT_BEARBEITEN":
                        _vm.openExternesDokumentModal,
                      "action-KREDIT_PROHYP_UPLOAD": _vm.uploadProhypDocument,
                      "headerAction-DOCUMENT-UPLOAD": function($event) {
                        return _vm.openDocumentUploadModal([])
                      }
                    }
                  })
            ],
            1
          ),
          _vm.files && _vm.additionalUploadInputs
            ? _c("DocumentsUploadModal", {
                attrs: {
                  files: _vm.files,
                  additionalInputs: _vm.additionalUploadInputs,
                  parentId: _vm.nodeId,
                  uploadType: _vm.uploadType,
                  documentString: _vm.fileIdEncoded,
                  multipleFileUpload: _vm.multipleFileUpload,
                  customUpload: _vm.customUpload
                },
                on: {
                  close: function($event) {
                    _vm.files = null
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "externesDokumenteModal",
          attrs: {
            modalTitle: "",
            labelButtonConfirm: "Speichern",
            showCancelButton: true
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.saveExternesDokument()
            }
          }
        },
        [
          _vm.exfile
            ? _c(
                "div",
                [
                  _c("InputField", {
                    attrs: { label: "Datei", disabled: true },
                    model: {
                      value: _vm.exfile.filename,
                      callback: function($$v) {
                        _vm.$set(_vm.exfile, "filename", $$v)
                      },
                      expression: "exfile.filename"
                    }
                  }),
                  _c("InputField", {
                    attrs: { label: "Bezeichnung" },
                    model: {
                      value: _vm.exfile.bezeichnung,
                      callback: function($$v) {
                        _vm.$set(_vm.exfile, "bezeichnung", $$v)
                      },
                      expression: "exfile.bezeichnung"
                    }
                  }),
                  _c("InputField", {
                    attrs: { label: "Bemerkung" },
                    model: {
                      value: _vm.exfile.bemerkung,
                      callback: function($$v) {
                        _vm.$set(_vm.exfile, "bemerkung", $$v)
                      },
                      expression: "exfile.bemerkung"
                    }
                  }),
                  _c("DatePickerField", {
                    attrs: { label: "Stand", dateFormat: "DD.MM.YYYY" },
                    model: {
                      value: _vm.exfile.stand,
                      callback: function($$v) {
                        _vm.$set(_vm.exfile, "stand", $$v)
                      },
                      expression: "exfile.stand"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c("ProhypDokumenteUpload", {
        ref: "prohypDokumenteUploadModal",
        attrs: { nodeId: _vm.nodeId, documentId: _vm.fileIdEncoded },
        on: { selectionDone: _vm.retrieveAdditionalDocuments }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }