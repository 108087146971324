var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", { attrs: { title: "Mustervorlagen" } }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.liste
            ? _c("Table", {
                attrs: {
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowId: "label",
                  title: _vm.isSubItems
                    ? _vm.TABLE_TITLE_SUBITEMS
                    : _vm.TABLE_TITLE
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "label",
                      fn: function(row) {
                        return [
                          row.dateiName && !row.subitem
                            ? _c("DownloadLink", {
                                attrs: {
                                  downloadServicePath: "/musterVorlage",
                                  title: row.label,
                                  filename: row.dateiName,
                                  queryParams: {}
                                }
                              })
                            : _c(
                                "a",
                                {
                                  attrs: {
                                    tid: _vm._generateTidFromString(
                                      "mustervorlage" + row.label
                                    )
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.open(row)
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s(row.label) + " ")]
                              )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2439874481
                )
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }