import ZUORDNUNG_GESELLSCHAFTEN_BIPRO_TYPES from './types';
import { getInitialState } from './index';
import Vue from 'vue';

export default {
    [ZUORDNUNG_GESELLSCHAFTEN_BIPRO_TYPES.MUTATIONS.RESET_STATE](state) {
        Object.assign(state, getInitialState())
    },
    [ZUORDNUNG_GESELLSCHAFTEN_BIPRO_TYPES.MUTATIONS.GET_GESELLSCHAFT_KENNUNGEN_SUCCESS](state, payload) {
        Vue.set(state, 'gesellschaftKennungen', payload);
    },
    [ZUORDNUNG_GESELLSCHAFTEN_BIPRO_TYPES.MUTATIONS.SEARCH_SUCCESS](state, payload) {
        Vue.set(state, 'searchResult', payload);
    },           
    [ZUORDNUNG_GESELLSCHAFTEN_BIPRO_TYPES.MUTATIONS.SELECT_ROW](state, payload) {
        Vue.set(state, 'selectedRow', payload);
    },
       
}