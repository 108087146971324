var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "addNewTagModal",
          attrs: { modalTitle: "Tag Liste", autoClose: false },
          on: {
            onConfirmButton: function($event) {
              return _vm.submit()
            },
            close: function($event) {
              return _vm.close()
            }
          }
        },
        [
          _c("Table", {
            attrs: {
              headers: _vm.tagHeaders,
              rows: _vm.tagsRows,
              rowsPerPage: 20
            },
            on: { "action-DELETE": _vm.deleteTag }
          }),
          _c("hr"),
          _c("div", { staticClass: "font-strong" }, [
            _vm._v(" Neue Tag hinzufügen ")
          ]),
          _c("InputField", {
            attrs: { label: "Tag" },
            model: {
              value: _vm.newTagForm.description,
              callback: function($$v) {
                _vm.$set(_vm.newTagForm, "description", $$v)
              },
              expression: "newTagForm.description"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }