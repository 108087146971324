var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CoreCard",
    {
      ref: "coreCard",
      attrs: { showToolbar: _vm.showToolbar, listView: _vm.listView },
      scopedSlots: _vm._u(
        [
          _vm.showTitle
            ? {
                key: "header",
                fn: function() {
                  return [_vm._v(" " + _vm._s(_vm.chartName) + " ")]
                },
                proxy: true
              }
            : null,
          {
            key: "alternative",
            fn: function() {
              return undefined
            },
            proxy: true
          }
        ],
        null,
        true
      )
    },
    [
      _vm.details.length
        ? _c(
            "div",
            _vm._l(_vm.details, function(item, i) {
              return _c("div", { key: i, staticClass: "row my-0 mx-3" }, [
                _c("div", { staticClass: "col" }, [_vm._v(_vm._s(item.key))]),
                _c("div", { staticClass: "col-auto right" }, [
                  _vm._v(_vm._s(item.value))
                ])
              ])
            }),
            0
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }