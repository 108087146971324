import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default ({
  state: {
    current: "",
    operatorClicked: false,
    kommaClicked: false,

    anzahl: '12',
    ende: '0',
    jahre: '0',
    rate: '0',
    start: '0',
    zins: '0',
    einzahlungen: '0',
    auszahlungen: '0',
    zinsen: '0',

  },
  mutations: {
    SET_CURRENT(state, current) {
      state.current = current
    },
    SET_OPERATORCLICKED(state, operatorClicked) {
      state.operatorClicked = operatorClicked
    },
    SET_KOMMACLICKED(state, kommaClicked) {
      state.kommaClicked = kommaClicked
    },

    SET_ANZAHL(state, e) {
      state.anzahl = e
    },
    SET_ENDE(state, e) {
      state.ende = e
    },
    SET_JAHRE(state, e) {
      state.jahre = e
    },
    SET_RATE(state, e) {
      state.rate = e
    },
    SET_START(state, e) {
      state.start = e
    },
    SET_ZINS(state, e) {
      state.zins = e
    },
    SET_EINZAHLUNGEN(state, e) {
      state.einzahlungen = e
    },
    SET_AUSZAHLUNGEN(state, e) {
      state.auszahlungen = e
    },
    SET_ZINSEN(state, e) {
      state.zinsen = e
    },
  },

  getters: {

    getCurrent: state => {
      return state.current
    },

    getOperatorClicked: state => {
      return state.operatorClicked
    },
    getKommaClicked: state => {
      return state.kommaClicked
    },

    getAnzahl: state => {
      return state.anzahl
    },
    getEnde: state => {
      return state.ende
    },
    getJahre: state => {
      return state.jahre
    },
    getRate: state => {
      return state.rate
    },
    getStart: state => {
      return state.start
    },
    getZins: state => {
      return state.zins
    },
    getEinzahlungen: state => {
      return state.einzahlungen
    },
    getAuszahlungen: state => {
      return state.auszahlungen
    },
    getZinsen: state => {
      return state.zinsen
    },
  },

  actions: {

  }
})