<template>
  <div>
    <PageHeaderTitleNavigation
      :id="$appNavigation.currentOptionMenuId"
      title="Layout bearbeiten"
      :actions="headerActions"
      @action-SAVE="save()"
      @action-META="showMetaTagManager = true"
      @action-DOMAIN="showDomainsManager = true"
      @action-RESET="loadDefault(selectedSchema)"
      @action-TOGGLE-ADVANCED-VIEW="advancedView = !advancedView"
    />

    <div class="row">
      <div class="col-3">
        <div v-if="!loading">
          <div class="box__container">
            <BaseButton
              @click="onChangeCMSHasDifferentLayout(!cmsHasDifferentLayout)"
            >
              <span v-if="cmsHasDifferentLayout">
                weiteres Layout für CMS entfernen
              </span>
              <span v-if="!cmsHasDifferentLayout">
                weiteres Layout für CMS hinzufügen
              </span>
            </BaseButton>
            <div>
              <InputRadioBoxGroup
                @input="selectSchema()"
                v-model="selectedSchema"
                title="Layout zum bearbeiten:"
                :values="availableLayoutSchemas"
              />
            </div>
          </div>
          <div class="box__container">
            <InputField
              :label="loginWelcomeTitle"
              v-model="selectedLocalLayout.loginWelcomeTitle"
            />
            {{ welcomeTitleFieldDescription }}
          </div>
          <div class="box__container">
            <InputTextColorPickerFull
              label="Primärfarbe"
              v-model="selectedLocalLayout.colorPrimary"
              @input="primarySecondaryOrTextChanged"
            />

            <InputTextColorPickerFull
              label="Primärtextfarbe (Ist das Feld leer, wird die Standarfarbe gezeigt)"
              v-model="selectedLocalLayout.colorPrimaryText"
              :previewValue="colorPrimaryTextOrCalculated"
              @input="primarySecondaryOrTextChanged"
            />

            <InputTextColorPickerFull
              label="Sekundärfarbe"
              v-model="selectedLocalLayout.colorSecondary"
              @input="primarySecondaryOrTextChanged"
            />

            <InputTextColorPickerFull
              label="Sekundärtextfarbe (Ist das Feld leer, wird die Standarfarbe gezeigt)"
              v-model="selectedLocalLayout.colorSecondaryText"
              :previewValue="colorSecondaryTextOrCalculated"
              @input="primarySecondaryOrTextChanged"
            />

            <InputTextColorPickerFull
              label="Text"
              v-model="selectedLocalLayout.colorText"
              @input="primarySecondaryOrTextChanged"
            />
          </div>

          <div v-show="advancedView" class="box__container">
            <ComboBox
              label="Schriftgröße"
              v-model="selectedLocalLayout.fontSize"
              :firstEmpty="false"
              :values="fontData"
            >
            </ComboBox>

            <InputTextColorPickerFull
              label="Seiten Hintergrund"
              v-model="selectedLocalLayout.colorBackground"
            />

            <InputTextColorPickerFull
              label="Card Hintergrund"
              v-model="selectedLocalLayout.colorBox"
            />
          </div>
          <div v-show="advancedView" class="box__container">
            <InputTextColorPickerFull
              label="Akzentfarbe Info"
              v-model="selectedLocalLayout.colorInfo"
            />

            <InputTextColorPickerFull
              label="Akzentfarbe Success"
              v-model="selectedLocalLayout.colorSuccess"
            />

            <InputTextColorPickerFull
              label="Akzentfarbe Warning"
              v-model="selectedLocalLayout.colorWarning"
            />

            <InputTextColorPickerFull
              label="Akzentfarbe Danger"
              v-model="selectedLocalLayout.colorDanger"
            />
          </div>
          <div v-show="advancedView" class="box__container">
            <InputTextColorPickerFull
              label="Statusleise Hintergrundfarbe"
              v-model="selectedLocalLayout.colorTopNavBackground"
            />

            <InputTextColorPickerFull
              label="Statusleiste Textfarbe"
              v-model="selectedLocalLayout.colorTopNavText"
            />

            <InputTextColorPickerFull
              label="Link Farbe"
              v-model="selectedLocalLayout.colorLink"
            />

            <InputTextColorPickerFull
              label="Arbeitsbereich Hintergrundfarbe"
              v-model="selectedLocalLayout.colorWorkspacesNavBackground"
            />

            <InputTextColorPickerFull
              label="Arbeitsbereich Textfarbe"
              v-model="selectedLocalLayout.colorWorkspacesNavText"
            />

            <InputTextColorPickerFull
              label="Arbeitsbereich Button Aktiv"
              v-model="selectedLocalLayout.colorWorkspacesNavActive"
            />
          </div>

          <div v-show="advancedView" class="box__container">
            <InputField
              label="Logo URL"
              v-model="selectedLocalLayout.logoUrl"
            />

            <InputField
              label="Hintergrundbild Login URL"
              v-model="selectedLocalLayout.imageBackgroundLoginUrl"
            />
          </div>

          <div v-show="advancedView" class="box__container">
            <div class="row">
              <div class="col">
                <InputChips
                  supportCustomValue
                  :value="generalChartColorWrapper.colorInputChips"
                  label="Standard Chart Farben"
                  @addItem="generalChartColorWrapper.add($event)"
                  @removeItem="generalChartColorWrapper.remove($event)"
                />
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div
                  class="layout__negative-margin--8 edit-layout__color-preview-box"
                >
                  <div
                    class="edit-layout__color-preview"
                    v-for="(colorPreview,
                    index) in generalChartColorWrapper.colors"
                    :style="{ 'background-color': colorPreview }"
                    :key="colorPreview + index"
                  >
                    &nbsp;
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <InputChips
                  supportCustomValue
                  label="Farbverlauf für Charts"
                  :value="gradientChartColorWrapper.colorInputChips"
                  @addItem="gradientChartColorWrapper.add($event)"
                  @removeItem="gradientChartColorWrapper.remove($event)"
                />
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div
                  class="layout__negative-margin--8 edit-layout__color-preview-box"
                >
                  <div
                    class="edit-layout__color-preview"
                    v-for="(colorPreview,
                    index) in gradientChartColorWrapper.colors"
                    :style="{ 'background-color': colorPreview }"
                    :key="colorPreview + index"
                  >
                    &nbsp;
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="box__container">
            <GhostLoading type="input" />
            <GhostLoading type="input" />
          </div>
          <div class="box__container">
            <GhostLoading type="input" />
            <GhostLoading type="input" />
          </div>
          <div class="box__container">
            <GhostLoading type="input" />
            <GhostLoading type="input" />
            <GhostLoading type="input" />
            <GhostLoading type="input" />
          </div>
        </div>
      </div>
      <div class="col-9">
        <LayoutColorsPreview
          :loading="loading"
          :layoutColors="selectedLocalLayout"
        />
      </div>
    </div>
    <MetaTagManager
      v-if="showMetaTagManager"
      @close="showMetaTagManager = false"
    />
    <DomainsManager
      v-if="showDomainsManager"
      @close="showDomainsManager = false"
    />
  </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import MetaTagManager from '@/components/edit-layout/MetaTagManager.vue';
import DomainsManager from '@/components/edit-layout/DomainsManager.vue';
import CORE_TYPES from '@/store/core/types';
import {
  convertColor,
  getMostContrastingColorWhiteBlackByContrast,
  REGEX_COLOR_HEX,
  CM_RGB,
  CM_HEX,
} from '@/helpers/colors-helper.js';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import { mapGetters } from 'vuex';
import { LOGIN_WELCOME_TITLE_DEFAULT } from '@/store/core/index';
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import InputChips from '@/components/core/input-chips/InputChips.vue';
import { ColorListWrapper } from '@/helpers/ColorListWrapper';
import LayoutColorsPreview from '@/components/edit-layout/LayoutColorsPreview.vue';
import InputTextColorPickerFull from '@/components/core/forms/InputTextColorPickerFull.vue'

/* anything outside this list is ignored for the time being */
const LAYOUT_FC_FIELDS = [
  'colorPrimary',
  'colorPrimaryText',
  'colorSecondary',
  'colorSecondaryText',
  'colorText',
  'fontSize',
  'colorBackground',
  'colorInfo',
  'colorSuccess',
  'colorWarning',
  'colorDanger',
  'colorBox',
  'colorTopNavBackground',
  'colorTopNavText',
  'colorLink',
  'colorWorkspacesNavBackground',
  'colorWorkspacesNavText',
  'colorWorkspacesNavActive',
  'colorActive',
  'colorTopNavActive',
  'loginWelcomeTitle',
  'generalChartColorList',
  'gradientChartColorList',
  'logoUrl',
  'imageBackgroundLoginUrl',
];

export default {
  mounted() {
    this.loadLayouts();
  },

  computed: {
    ...mapGetters({
      userId: CORE_TYPES.GETTERS.GET_USER_ID,
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
      currentLayout: CORE_TYPES.GETTERS.GET_LAYOUT,
      defaultLayout: CORE_TYPES.GETTERS.GET_DEFAULT_LAYOUT,
      loginWelcomeTitle: CORE_TYPES.GETTERS.GET_LOGIN_WELCOME_TITLE,
    }),
    headerActions() {
      return [
        PageHeaderSimpleAction('SAVE', 'Speichern').withDisabled(
          () => this.loading
        ),
        PageHeaderSimpleAction('META', 'Meta-Tags ändern')
          .withIcon('PhTag')
          .withDisabled(() => this.loading),
        PageHeaderSimpleAction('DOMAIN', 'Domain ändern')
          .withIcon('PhListDashes')
          .withDisabled(() => this.loading),
        PageHeaderSimpleAction('RESET', 'Standard verwenden')
          .withIcon('PhArrowCounterClockwise')
          .withDisabled(() => this.loading),
        PageHeaderSimpleAction('TOGGLE-ADVANCED-VIEW', 'Erweitertes Layout')
          .withIcon(this.advancedView ? 'PhEyeSlash' : 'PhEye')
          .withDisabled(() => this.loading),
      ];
    },
    selectedLocalLayout() {
      return this.localLayout?.[this.selectedSchema] || {};
    },
    hasCMSDedicatedLayout() {
      return this.localLayout?.cms;
    },
    colorPrimaryTextOrCalculated() {
      return (
        this.selectedLocalLayout.colorPrimaryText ||
        getMostContrastingColorWhiteBlackByContrast(
          this.selectedLocalLayout.colorPrimary
        )
      );
    },
    colorSecondaryTextOrCalculated() {
      return (
        this.selectedLocalLayout.colorSecondaryText ||
        getMostContrastingColorWhiteBlackByContrast(
          this.selectedLocalLayout.colorSecondary
        )
      );
    },
    availableLayoutSchemas() {
      const result = [
        { label: 'Light', value: 'light' },
        { label: 'Dark', value: 'dark' },
      ];

      if (this.cmsHasDifferentLayout) {
        result.push({ label: 'CMS', value: 'cms' });
      }

      return result;
    },
    welcomeTitleFieldDescription() {
      return `Ist das Feld leer, wird der Standard-Titel gezeigt: ${LOGIN_WELCOME_TITLE_DEFAULT}`;
    }
  },
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    BaseButton,
    InputField,
    ComboBox,
    MetaTagManager,
    InputRadioBoxGroup,
    DomainsManager,
    GhostLoading,
    InputChips,
    LayoutColorsPreview,
    InputTextColorPickerFull,
  },
  data() {
    return {
      loading: false,
      localLayout: {},
      advancedView: false,
      fontData: ['14px', '15px', '16px'],

      cmsHasDifferentLayout: false,
      showMetaTagManager: false,
      showDomainsManager: false,
      selectedSchema: 'light',

      generalChartColorWrapper: new ColorListWrapper(),
      gradientChartColorWrapper: new ColorListWrapper(),
    };
  },

  watch: {
    currentLayout(currentLayout) {
      let layout = currentLayout;

      if (!currentLayout) {
        layout = this.defaultLayout;
      }

      if (layout) {
        this.updateColorVisualizer(layout);
        if (layout.cms) {
          this.cmsHasDifferentLayout = true;
        }
      }
    },
    'generalChartColorWrapper.colorString'(value) {
      if (this.selectedLocalLayout) {
        this.selectedLocalLayout.generalChartColorList = value;
      }
    },
    'gradientChartColorWrapper.colorString'(value) {
      if (this.selectedLocalLayout) {
        this.selectedLocalLayout.gradientChartColorList = value;
      }
    },
    'selectedLocalLayout.generalChartColorList'(value) {
      this.generalChartColorWrapper.set(value);
    },
    'selectedLocalLayout.gradientChartColorList'(value) {
      this.gradientChartColorWrapper.set(value);
    },
  },

  methods: {
    async loadLayouts() {
      this.loading = true;
      await this.$store.dispatch(CORE_TYPES.ACTIONS.GET_DEFAULT_LAYOUT);
      await this.$store.dispatch(CORE_TYPES.ACTIONS.GET_LAYOUT, this.userId);
      this.loading = false;
    },
    updateColorVisualizer(layout) {
      for (const [schema, layoutFC] of Object.entries(layout)) {
        const tempLayout = Object.assign({}, this.localLayout?.[schema] || {});
        for (const [prop, value] of Object.entries(layoutFC)) {
          this.$set(
            tempLayout,
            prop,
            value?.includes('rgb') ? convertColor(CM_RGB, CM_HEX, value) : value
          );
        }
        this.$set(this.localLayout, schema, tempLayout);
      }
    },
    updateLayoutObject(layout) {
      if (layout) {
        for (const [schema, layoutFC] of Object.entries(this.localLayout)) {
          for (const [prop, value] of Object.entries(layoutFC)) {
            if (LAYOUT_FC_FIELDS.includes(prop)) {
              if (!layout[schema]) {
                this.$set(layout, schema, {});
              }
              this.$set(layout[schema], prop, value?.trim?.());
            }
          }
        }
      }
    },
    primarySecondaryOrTextChanged(value) {
      if (!this.advancedView && value?.match?.(REGEX_COLOR_HEX)) {
        this.applyBasic();
      }
    },
    async save() {
      this.loading = true;

      try {
        // save only keys present in localLayout
        let layoutToSave = Object.keys(this.localLayout).reduce((acc, cur) => {
          acc[cur] = this.currentLayout?.[cur];
          return acc;
        }, {});

        this.updateLayoutObject(layoutToSave);

        const payload = {
          data: layoutToSave,
        };

        await this.$store.dispatch(CORE_TYPES.ACTIONS.SAVE_LAYOUT, payload);
      } catch (error) {
        // empty block
      }

      this.loading = false;
    },
    applyBasic() {
      // angewandte Farben

      this.fontSize = this.defaultLayout?.light?.fontSize;
      this.colorBackground = this.defaultLayout?.light?.colorBackground;

      this.colorBox = this.defaultLayout?.light?.colorBox;
      this.colorInfo = this.defaultLayout?.light?.colorInfo;

      this.colorSuccess = this.defaultLayout?.light?.colorSuccess;
      this.colorWarning = this.defaultLayout?.light?.colorWarning;
      this.colorDanger = this.defaultLayout?.light?.colorDanger;

      this.colorActive = this.colorPrimary?.trim?.();
      this.colorLink = this.colorSecondary?.trim?.();

      this.colorTopNavBackground = this.colorPrimary?.trim?.();
      this.colorTopNavText = getMostContrastingColorWhiteBlackByContrast(
        this.colorTopNavBackground
      );
      this.colorTopNavActive = this.colorSecondary?.trim?.();

      this.colorWorkspacesNavBackground = this.defaultLayout?.light?.colorBox;
      this.colorWorkspacesNavText = getMostContrastingColorWhiteBlackByContrast(
        this.colorWorkspacesNavBackground
      );
      this.colorWorkspacesNavActive = this.colorPrimary?.trim?.();
    },

    loadDefault(selectedSchema) {
      if (!this.defaultLayout) {
        return;
      }

      const defaultLayout = this.defaultLayout[selectedSchema]
        ? this.defaultLayout[selectedSchema]
        : this.defaultLayout['light'];

      const defaultSelectedScheme = {
        [selectedSchema]: defaultLayout,
      };

      if (defaultSelectedScheme?.[selectedSchema]) {
        delete defaultSelectedScheme?.[selectedSchema]?.imageBackgroundLoginUrl;
        delete defaultSelectedScheme?.[selectedSchema]?.logoUrl;
      }

      this.updateColorVisualizer(defaultSelectedScheme);
    },

    selectSchema() {
      if (!this.localLayout[this.selectedSchema]) {
        let layout = null;
        if (this.localLayout['light']) {
          layout = this.localLayout['light'];
        } else if (this.defaultLayout[this.selectedSchema]) {
          layout = this.defaultLayout[this.selectedSchema];
        } else if (this.defaultLayout['light']) {
          layout = this.defaultLayout['light'];
        }

        if (layout) {
          const missingLayout = {
            [this.selectedSchema]: layout,
          };

          this.updateColorVisualizer(missingLayout);
        }
      }
    },

    onChangeCMSHasDifferentLayout(value) {
      this.cmsHasDifferentLayout = value;
      if (value) {
        this.addCMSLayout();
      } else {
        this.removeCMSLayout();
      }
    },

    addCMSLayout() {
      this.selectedSchema = 'cms';
      this.selectSchema();
    },
    removeCMSLayout() {
      this.selectedSchema = 'light';
      this.$delete(this.localLayout, 'cms');
    },
  },
};
</script>

<style scoped>
.edit-layout__color-preview-box {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.edit-layout__color-preview {
  width: 32px;
  height: 32px;
  border: 1px solid;
}
</style>
