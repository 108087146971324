<template>
  <div></div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';


export default {
  mounted() {
    const sitzungID = this.$route.query.SitzungID;
    const tabsBroadcastId = this.$route.query.tabsBroadcastId;
    const maklerzugangTabsBroadcastId = this.$route.query.maklerzugangTabsBroadcastId;
    const nextUrl = this.$route.query.nextUrl;
    if (sitzungID) {
      const loginPayload = {
        tabsBroadcastId,
        maklerzugangTabsBroadcastId,
        sitzungID,
        isMaklerzugang: true,
        nextUrl,
      };
      this.$store.dispatch(CORE_TYPES.ACTIONS.LOGIN, loginPayload);
    }
  },
}
</script>
