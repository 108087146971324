import FEE_TYPES from './types';

export default {
  [FEE_TYPES.GETTERS.FEE_PROPERTIES_COMBOS](state) {
    return { ...state.feePropertiesCombos, };
  },
  [FEE_TYPES.GETTERS.FEE_PROPERTIES](state) {
    return { ...state.feeProperties, };
  },
  [FEE_TYPES.GETTERS.FEE_PROPERTIES_EDITED](state) {
    return state.feePropertiesEdited && { ...state.feePropertiesEdited, } || null;
  },
  [FEE_TYPES.GETTERS.ABRECHNUNGEN](state) {
    return [ ...state.abrechnungen || [] ];
  },
  [FEE_TYPES.GETTERS.IS_ABRECHNUNGEN_LOADED](state) {
    return state.abrechnungen !== null;
  },
  [FEE_TYPES.GETTERS.KUNDE_HONORARE](state) {
    return [ ...state.kundeHonorare || [] ];
  },
  [FEE_TYPES.GETTERS.IS_KUNDE_HONORARE_LOADED](state) {
    return state.kundeHonorare !== null;
  },
  [FEE_TYPES.GETTERS.CALC_KUNDE_HONORARE_DATA](state) {
    return { ...state.calcKundeHonorareData || {}, };
  },
  [FEE_TYPES.GETTERS.FEHLERHAFT](state) {
    return state?.fehlerhaft;
  },
  
}
