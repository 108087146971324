<template>
  <GhostLoading v-if="isDayType">
    <div class="calendar-ghost-loading--inline">
      <Block height="38" :style="{ maxWidth: '77px' }" />
      <Block width="100%" height="38" />
    </div>
    <div v-for="n in 10" class="calendar-ghost-loading--inline" :key="n">
      <Block height="64" :style="{ maxWidth: '77px' }" />
      <Block width="100%" height="64" />
    </div>
  </GhostLoading>
  <GhostLoading v-else-if="isWeekType">
    <div class="calendar-ghost-loading--inline">
      <Block height="38" :style="{ maxWidth: '77px' }" />
      <Block v-for="n in 7" width="100" height="38" :key="n" />
    </div>
    <div v-for="n in 10" class="calendar-ghost-loading--inline" :key="n">
      <Block height="64" :style="{ maxWidth: '77px' }" />
      <Block v-for="m in 7" width="100" height="64" :key="m" />
    </div>
  </GhostLoading>
  <GhostLoading v-else-if="isWorkWeekType">
    <div class="calendar-ghost-loading--inline">
      <Block height="38" :style="{ maxWidth: '77px' }" />
      <Block v-for="n in 5" width="100" height="38" :key="n" />
    </div>
    <div v-for="n in 10" class="calendar-ghost-loading--inline" :key="n">
      <Block height="64" :style="{ maxWidth: '77px' }" />
      <Block v-for="m in 5" width="100" height="64" :key="m" />
    </div>
  </GhostLoading>
  <GhostLoading v-else-if="isMonthType">
    <div class="calendar-ghost-loading--inline">
      <Block v-for="m in 7" width="150" height="38" :key="m" />
    </div>
    <div v-for="n in 5" class="calendar-ghost-loading--inline" :key="n">
      <Block v-for="m in 7" width="150" height="128" :key="m" />
    </div>
  </GhostLoading>
  <GhostLoading v-else-if="isCalendarType">
    <Block height="120" />
  </GhostLoading>
</template>

<script>
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';

const DAY_TYPE = 'day';
const WEEK_TYPE = 'week';
const WORK_WEEK_TYPE = 'work-week';
const MONTH_TYPE = 'month';
const CALENDAR_TYPE = 'calendar';
const PROP_TYPE_VALIDS = [DAY_TYPE, WORK_WEEK_TYPE, WEEK_TYPE, MONTH_TYPE, CALENDAR_TYPE];

export default {
  props: {
    type: {
      type: String,
      default: null,
      validator: (value) => PROP_TYPE_VALIDS.indexOf(value) >= 0,
    },
  },
  components: {
    GhostLoading,
    Block,
  },
  computed: {
    isDayType() {
      return this.type === DAY_TYPE;
    },
    isWeekType() {
      return this.type === WEEK_TYPE;
    },
    isWorkWeekType() {
      return this.type === WORK_WEEK_TYPE;
    },
    isMonthType() {
      return this.type === MONTH_TYPE;
    },
    isCalendarType() {
      return this.type === CALENDAR_TYPE;
    },
  },
}
</script>

<style lang="scss" scoped>
.calendar-ghost-loading--inline {
  display: flex;
  margin: 0 -1px 2px;

  &:last-child {
    margin-bottom: 0;
  }

  > div {
    margin: 0 1px;
    flex: 1 auto;
  }
}
</style>
