<template>
  <div>
    <router-view></router-view>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";

export default {
  computed: {
    ...mapGetters({
      editingDetails: VERSICHERUNG_TYPES.GETTERS.EDITING_DETAILS
    })
  },
  beforeRouteLeave(to, from, next) {

    if (this.editingDetails==false) {
      this.$store.commit(VERSICHERUNG_TYPES.MUTATIONS.FILTERED_CATEGORY, {
        category: null
      });
    }
    next();
  }
};
</script>

