var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: _vm.$appNavigation.currentMenu.label
        }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("p", [_vm._v("Start und wichtige Hinweise")]),
          _c("p", [
            _vm._v(
              "Die Bearbeitungszeit des Tests beträgt ca. 30 Minuten und der Test kann mehrmals wiederholt werden. Am Ende des Abschlusstests erhalten Sie, wenn mindestens 80% der Fragen richtig beantwortet wurden, ein Zertifikat, welches Sie bitte abspeichern und auf Meine-Weiterbildung.at hochladen. Nach erfolgreichem Generieren des Zertifikates erfolgt eine automatische Meldung an die FinanzAdmin, dass Sie den Test bestanden haben."
            )
          ]),
          _c("p", [
            _vm._v(
              "Um den WAG Test korrekt zu beenden, müssen folgende Voraussetzungen gegeben sein:"
            )
          ]),
          _vm._m(0),
          _c("DownloadLink", {
            attrs: {
              asButton: "",
              title: "WAG-Prüfung starten",
              href: _vm.helpmasterLink
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _vm._v(
          "Bitte verwenden Sie ausschließlich Google Chrome, Mozilla Firefox oder den Safari Browser – andere Browser sind leider nicht verwendbar. Stellen Sie sicher, dass einer dieser oben Genannten als Ihr Standartbrowser eingestellt ist"
        )
      ]),
      _c("li", [
        _vm._v(
          "Cookies zulassen (Extras/ Einstellungen/ Inhalt/ Pop-up-Fenster blockieren Haken raus/ ok)"
        )
      ]),
      _c("li", [_vm._v("Caches im Browser müssen zwingend gelöscht werden")]),
      _c("li", [
        _vm._v(
          "Für Ihre Dokumentation und Nachsorge das Zertifikat ausdrucken und/ oder abspeichern"
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }