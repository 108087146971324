var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gesellschaft-auswahl-component" },
    [
      _c("ComboBox", {
        staticClass: "gesellschaft-auswahl-component-combo-box-container mr-3",
        attrs: { label: _vm.label, values: _vm.values, disabled: _vm.disabled },
        model: {
          value: _vm.internalValue,
          callback: function($$v) {
            _vm.internalValue = $$v
          },
          expression: "internalValue"
        }
      }),
      _c(
        "BaseButton",
        {
          staticClass: "gesellschaft-auswahl-component-button",
          attrs: { disabled: !_vm.hasLink || _vm.disabled },
          on: {
            click: function($event) {
              return _vm.openAntrag()
            }
          }
        },
        [_vm._v("Antrag Erstellen")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }