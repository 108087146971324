<template>
  <div class="feiertage-gruppe-spezial__container">
    <div class="feiertage-gruppe-spezial--header">
      <span class="box__title">Eigene Feiertage</span>

      <BaseContextMenu>
        <ContextMenuItem @click="openFeiertageGruppeSpezialModal">Neue Gruppe hinzufügen</ContextMenuItem>
      </BaseContextMenu>
    </div>

    <div v-if="isBrokerView" class="text-italic mb-2">Achtung: Die angelegten Feiertage und Feiertagsgruppen sind ersichtlich für Ihre Unterstruktur</div>

    <Table
      :headers="headers"
      :rows="rows"
      hidePagination
      @action-EDIT="openFeiertageGruppeSpezialModal"
      @action-CONFIG="openFeiertageSpezialModal"
      @action-DELETE="deleteGruppe"
    >
      <template #active="row">
        <InputToggleSwitch :value="isSelected(row.id)" @input="select(row.id, $event)" />
      </template>
    </Table>

    <FeiertageGruppeSpezialModal ref="feiertageGruppeSpezialModal" />
    <FeiertageSpezialModal ref="feiertageSpezialModal" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CALENDAR_SETTINGS_TYPES from '@/store/calendarSettings/types';
import CORE_TYPES from '@/store/core/types';

import Table from '@/components/table2/Table.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import BaseContextMenu from '@/components/core/BaseContextMenu.vue';
import ContextMenuItem from "@/components/core/base-context-menu/ContextMenuItem.vue";
import FeiertageGruppeSpezialModal from '@/components/calendar/settings/FeiertageGruppeSpezialModal.vue';
import FeiertageSpezialModal from '@/components/calendar/settings/FeiertageSpezialModal.vue';

import { TextColumn, SlotColumn, ActionColumn, SimpleAction, } from '@/components/table2/table_util';
import { VIEW_ROLES, } from '@/router/roles';

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      feiertageGruppenSpezial: CALENDAR_SETTINGS_TYPES.GETTERS.FEIERTAGE_GRUPPEN_SPEZIAL,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
    isBrokerView() {
      return this.hasRoles([VIEW_ROLES.VIEW_BROKER]);
    },
    selected() {
      return [ ...this.value, ];
    },
    headers() {
      return {
        lockedLeft: [
          SlotColumn('active', 'aktiviert'),
          TextColumn('bezeichnung', 'Bezeichnung'),
        ],
        center: [],
        lockedRight: [ActionColumn('actions')],
      };
    },
    rows() {
      return [ ...this.feiertageGruppenSpezial || [], ].map(item => {
        return {
          ...item,
          actions: item.besitzer ? [
            SimpleAction('EDIT', 'PhPencilLine', 'bearbeiten'),
            SimpleAction('CONFIG', 'PhGear', 'Feiertage'),
            SimpleAction('DELETE', 'PhTrash', 'löschen'),
          ] : [
            SimpleAction('CONFIG', 'PhList', 'Feiertage')
          ],
        };
      });
    },
  },
  methods: {
    openFeiertageGruppeSpezialModal(gruppe) {
      this.$refs.feiertageGruppeSpezialModal?.open(gruppe);
    },
    openFeiertageSpezialModal(gruppe) {
      this.$refs.feiertageSpezialModal?.open(gruppe);
    },
    isSelected(id) {
      return this.selected.findIndex(item => item.id == id) >= 0;
    },
    select(id, value) {
      const selected = [ ...this.selected, ];
      if(value) {
        selected.push({ id, })
      } else {
        const index = selected.findIndex(item => item.id == id);
        selected.splice(index, 1);
      }
      this.$emit('input', [ ...selected, ]);
    },
    async deleteGruppe(gruppe) {
      try {
        await this.$confirmModal({
          title: 'Spezialgruppe löschen?',
          message: `Möchten Sie die Spezialgruppe "${gruppe.bezeichnung}" mit allen dazugehörigen Spezialfeiertagen wirklich löschen?`,
        });
        await this.$store.dispatch(CALENDAR_SETTINGS_TYPES.ACTIONS.DELETE_FEIERTAGE_GRUPPEN_SPEZIAL, { ...gruppe, });
      } catch(e) {
        // empty block
      }
    },
  },
  components: {
    Table,
    InputToggleSwitch,
    BaseContextMenu,
    ContextMenuItem,
    FeiertageGruppeSpezialModal,
    FeiertageSpezialModal,
  },
}
</script>

<style lang="scss" scoped>
.feiertage-gruppe-spezial--header {
  display: flex;

  .box__title {
    flex: 1 1 auto;
  }
}
</style>
