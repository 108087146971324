import MENU_CONFIG_TYPES from './types';
import FC_CONFIG_TYPES from '@/store/fcConfig/types';
import CORE_TYPES from '@/store/core/types';
import { FC_CONFIG_USER_LEVEL, FC_CONFIG_CONTEXT, } from '@/configs/fcConfig';
import router from '@/router';
import { VIEW_ROLES, ROLES, ALL_ROLES, getViewRoles, } from '@/router/roles';

import { findNonTabMenu, } from '@/menu/menu-config-utils';

const MAP_PARENT_USER_LEVEL = Object.freeze({
  [FC_CONFIG_USER_LEVEL.MAKLER]: FC_CONFIG_USER_LEVEL.MAKLER_MASTER_STRUKTUR,
  [FC_CONFIG_USER_LEVEL.MAKLER_STRUKTUR]: FC_CONFIG_USER_LEVEL.MAKLER,
  [FC_CONFIG_USER_LEVEL.MAKLER_PERSONEN]: FC_CONFIG_USER_LEVEL.MAKLER,
  [FC_CONFIG_USER_LEVEL.KUNDE]: FC_CONFIG_USER_LEVEL.MAKLER,
});

const DEFAULT_ARRAY_STRINGIFIED = '[]';

export default {

  // Makler context when editing a Mitarbeiter data
  [MENU_CONFIG_TYPES.GETTERS.SELECTED_UNTERNR](state) {
    return (configContext) => state.selectedUnternr?.[configContext];
  },

  [MENU_CONFIG_TYPES.GETTERS.HAS_SELECTED_UNTERNR](_, getters) {
    return (configContext) => {
      if (!configContext) return false;
      const selectedUnternr = getters[MENU_CONFIG_TYPES.GETTERS.SELECTED_UNTERNR](configContext);
      return !!selectedUnternr || selectedUnternr === 0;
    }
  },

  // Common
  [MENU_CONFIG_TYPES.GETTERS.IS_INTERN_GLOBAL_CONFIG_ALLOWED](_, getters) {
    const hasRoles = getters[CORE_TYPES.GETTERS.HAS_ROLES];
    return hasRoles([[VIEW_ROLES.VIEW_INTERN_ONLY, ROLES.ALLOW_CONFIG_FC_CONFIG_GLOBAL]]);
  },

  [MENU_CONFIG_TYPES.GETTERS.FLAT_MENU_CONFIG](state) {
    return (configContext) => {
      if(!configContext) return [];
      return state.flatMenuConfig?.[configContext] || [];
    };
  },
  [MENU_CONFIG_TYPES.GETTERS.FLAT_MENU_CONFIG_BY_PATH](state) {
    return (configContext) => {
      if(!configContext) return [];
      return state.flatMenuConfigByPath?.[configContext] || [];
    };
  },
  [MENU_CONFIG_TYPES.GETTERS.IS_MENU_CONFIGURED](state) {
    return (configContext) => {
      return !!configContext && (configContext in state.flatMenuConfig);
    };
  },
  [MENU_CONFIG_TYPES.GETTERS.OPTION_MENU](_, getters) {
    return (configContext) => findNonTabMenu(getters[MENU_CONFIG_TYPES.GETTERS.FLAT_MENU_CONFIG](configContext)); // options menu are available only for non tab menus
  },
  [MENU_CONFIG_TYPES.GETTERS.PARENTS_MENU_CONFIG](_, getters) {
    return (configContext) => {
      const optionMenu = getters[MENU_CONFIG_TYPES.GETTERS.OPTION_MENU](configContext);
      const parentsMenu = findNonTabMenu(optionMenu).filter(menu => menu.hasSubMenu) // parents only for non tab menus
      return [ ...parentsMenu || [], ];
    };
  },
  [MENU_CONFIG_TYPES.GETTERS.APP_NAVIGATION_CONFIG_BY_PATH](_, getters) {
    return (configContext, path) => {
      if(!configContext || !path) return {};

      const flatMenuConfigByPath = getters[MENU_CONFIG_TYPES.GETTERS.FLAT_MENU_CONFIG_BY_PATH](configContext);

      if(!Object.keys(flatMenuConfigByPath).length) return {};

      const optionMenu = getters[MENU_CONFIG_TYPES.GETTERS.OPTION_MENU](configContext);

      const defaultAppNavigation = {
        currentMenu: {},
        currentOptionMenuId: undefined,
        currentOptionMenu: [],
        optionMenu,
      };

      // Get a menu for the current route
      let currentMenu = flatMenuConfigByPath[path];
      if(!currentMenu) { // if any route was found it return an empty object
        return defaultAppNavigation;
      }

      // check if there is a resolve option linked to the current menu
      const hasResolve = 'resolve' in currentMenu;

      // set current option menu
      const [currentOptionMenu, currentOptionMenuId] = (() => {
        if(currentMenu.hasSubMenu || hasResolve) {
          return [[...currentMenu.subMenu], currentMenu.id];
        }
      })();

      return {
        currentMenu,
        currentOptionMenuId,
        currentOptionMenu,
        optionMenu,
      };
    };
  },

  [MENU_CONFIG_TYPES.GETTERS.OPTIONS_MENU_CONFIG_USER_LEVELS](state) {
    return (configContext) => {
      if (!configContext) return [];
      return [ ...state.userLevels?.[configContext] || [] ];
    };
  },

  [MENU_CONFIG_TYPES.GETTERS.OPTIONS_MENU_CONFIG_CONTEXTS](_, getters) {
    const hasRoles = getters[CORE_TYPES.GETTERS.HAS_ROLES];
    const isInternGlobalConfigAllowed = getters[MENU_CONFIG_TYPES.GETTERS.IS_INTERN_GLOBAL_CONFIG_ALLOWED];

    if(isInternGlobalConfigAllowed) { // Global contexts
      return [
        FC_CONFIG_CONTEXT.MAKLER_MAKLER,
        FC_CONFIG_CONTEXT.MAKLER_KUNDE,
        FC_CONFIG_CONTEXT.KUNDE_KUNDE,
      ];
    } else if(hasRoles([[VIEW_ROLES.VIEW_BROKER_ONLY], [VIEW_ROLES.VIEW_BROKER_AS_BROKER, ROLES.IS_MAKLERZUGANG]])) { 
      // Makler / accessing a Makler context as a Makler and as Maklersicht / Mitarbeiter as Maklersicht
      return [FC_CONFIG_CONTEXT.MAKLER_MAKLER];
    } else if(hasRoles([VIEW_ROLES.VIEW_CUSTOMER_AS_BROKER])) { // Kunde context as a Makler
      return [FC_CONFIG_CONTEXT.MAKLER_KUNDE];
    } else if(hasRoles([VIEW_ROLES.VIEW_CUSTOMER_ONLY])) { // Kunde context
      return [FC_CONFIG_CONTEXT.KUNDE_KUNDE];
    }

    return [];
  },
  [MENU_CONFIG_TYPES.GETTERS.OPTIONS_MENU_CONFIG_ROLES](_, getters) {
    return (configContext, userLevel) => {
      if(!configContext) return [];
      const additionalRoles = [ROLES.SHOW_PIN_LIST];

      const isInternGlobalConfigAllowed = getters[MENU_CONFIG_TYPES.GETTERS.IS_INTERN_GLOBAL_CONFIG_ALLOWED];
      if(!isInternGlobalConfigAllowed) { // when is different of global config on VIEW_INTERN it returns the current user roles
        const roles = [];

        if (getters[CORE_TYPES.GETTERS.GET_USER_ROLES]) {
          roles.push(...getters[CORE_TYPES.GETTERS.GET_USER_ROLES]);
          roles.push(...additionalRoles);
        }

        const hasSelectedUnternr = getters[MENU_CONFIG_TYPES.GETTERS.HAS_SELECTED_UNTERNR](configContext);
        if (userLevel === 'MAKLER_PERSONEN' || hasSelectedUnternr) {
          roles.push(ROLES.IS_USER_UNTERNR);
        }
        return roles;
      }

      const hasRoles = getters[CORE_TYPES.GETTERS.HAS_ROLES];

      const isFK = hasRoles([ROLES.FK]);

      const viewRoles = [];
      if(configContext === FC_CONFIG_CONTEXT.MAKLER_MAKLER) {
        viewRoles.push(...[
          ...getViewRoles({ userType: 'MAKLER', }), // Makler
          ...getViewRoles({ userType: 'MAKLER', userTypeGetToken: 'MAKLER', }), // Makler from a Makler
        ]);
      } else if(configContext === FC_CONFIG_CONTEXT.MAKLER_KUNDE) {
        viewRoles.push(...[
          ...getViewRoles({ userType: 'MAKLER', userTypeGetToken: 'KUNDE', }), // Kunde from a Makler
        ]);
      } else if(configContext === FC_CONFIG_CONTEXT.KUNDE_KUNDE) {
        viewRoles.push(...[
          ...getViewRoles({ userType: 'KUNDE', }), // Kunde
        ]);
      }

      // Ignored roles
      const FIRMA_IGNORED_ROLES = isFK ? [ROLES.FA, ROLES.FA_TEST_USER] : [ROLES.FK, ROLES.FK_TEST_USER];
      const IGNORED_ROLES = [ // TODO it must be refactored, however the roles must be reorganized first. It was added due to denied roles
        'VIEW_ROLES', ...FIRMA_IGNORED_ROLES, ROLES.DBM, ROLES.IS_INVERSE, ROLES.IS_ANLAGE_BEISPIEL,
        ROLES.BLOCKED_ABRECHNUNGEN_STATISTIKEN, ROLES.SUPER_CUSTOMER, ROLES.IS_USER_UNTERNR,
      ];

      const remainingRoles = Object.keys(ALL_ROLES).reduce((roles, roleGroupKey) => {
        if(IGNORED_ROLES.includes(roleGroupKey)) {
          return roles;
        }
  
        return Object.keys(ALL_ROLES[roleGroupKey]).reduce((innerRoles, roleKey) => {
          if(IGNORED_ROLES.includes(roleKey)) {
            return innerRoles;
          }
  
          return [ ...innerRoles, ALL_ROLES[roleGroupKey][roleKey], ];
        }, roles);
      }, []);

      const roles = viewRoles.concat(remainingRoles);

      if (userLevel === 'MAKLER_PERSONEN') {
        roles.push(ROLES.IS_USER_UNTERNR);
      }

      return roles;
    };
  },
  [MENU_CONFIG_TYPES.GETTERS.OPTIONS_MENU_CONFIG_TABS](state, getters) {
    return (configContext) => {
      if (!configContext) return [];

      const userLevels = getters[MENU_CONFIG_TYPES.GETTERS.OPTIONS_MENU_CONFIG_USER_LEVELS](configContext);

      const hasRoles = getters[CORE_TYPES.GETTERS.HAS_ROLES];
  
      const MAP_COMMON_USER_LEVEL_LABEL = {
        [FC_CONFIG_USER_LEVEL.MAKLER_MASTER_STRUKTUR]: 'global',
        [FC_CONFIG_USER_LEVEL.INTERN]: 'Intern',
        [FC_CONFIG_USER_LEVEL.MAKLER]: 'Vermittler',
        [FC_CONFIG_USER_LEVEL.MAKLER_STRUKTUR]: 'Unterstruktur',
        [FC_CONFIG_USER_LEVEL.MAKLER_PERSONEN]: 'Mitarbeiter',
      };
  
      const MAP_MAKLER_SELECTED_UNTERN_CONTEXT_USER_LEVEL_LABEL = {
        [FC_CONFIG_USER_LEVEL.MAKLER]: 'aktueller Mitarbeiter',
      };
  
      const MAP_KUNDE_CONTEXT_USER_LEVEL_LABEL = {
        [FC_CONFIG_USER_LEVEL.MAKLER]: 'alle Kunden',
        [FC_CONFIG_USER_LEVEL.KUNDE]: 'aktueller Kunden',
      };
  
      const isCustomerView = hasRoles([VIEW_ROLES.VIEW_CUSTOMER]);
      const hasSelectedUnternr = getters[MENU_CONFIG_TYPES.GETTERS.HAS_SELECTED_UNTERNR](configContext);
  
      const userLevelLabel = userLevel => {
        if(isCustomerView) {
          return MAP_KUNDE_CONTEXT_USER_LEVEL_LABEL[userLevel];
        } else if(hasSelectedUnternr) {
          return MAP_MAKLER_SELECTED_UNTERN_CONTEXT_USER_LEVEL_LABEL[userLevel];
        }
        return '';
      };
  
      return userLevels.map(userLevel => ({
        key: userLevel,
        label: userLevelLabel(userLevel) || MAP_COMMON_USER_LEVEL_LABEL[userLevel],
      }));
    };
  },
  [MENU_CONFIG_TYPES.GETTERS.OPTIONS_MENU_CONFIG_USER_LEVEL_DEFAULT](_, getters) {
    return (isPermissionConfigurable = false) => {
      if(getters[MENU_CONFIG_TYPES.GETTERS.IS_INTERN_GLOBAL_CONFIG_ALLOWED] && isPermissionConfigurable) { // Global config
        return FC_CONFIG_USER_LEVEL.MAKLER_MASTER_STRUKTUR;
      }

      return getters[FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_USER_LEVEL_DEFAULT];
    };
  },

  [MENU_CONFIG_TYPES.GETTERS.RESOLVE_MENU_PATH](_, getters) {
    return (configContext, path) => {
      if(!path || !configContext) return path;

      const flatMenuConfigByPath = getters[MENU_CONFIG_TYPES.GETTERS.FLAT_MENU_CONFIG_BY_PATH](configContext);

      const matchedRoute = router.match(path);
      if(matchedRoute?.redirectedFrom && !matchedRoute?.matched?.some(match => path.includes(match.path))) { // check if path is from different route config structure
        const matchedMenu = matchedRoute.matched.toReversed().find(route => flatMenuConfigByPath[route.path]);
        return matchedMenu?.path; // only use the matched path, if it is from different route config structure
      }
      return path;
    };
  },
  [MENU_CONFIG_TYPES.GETTERS.IS_MENU_PATH_VISIBLE](_, getters) {
    return (configContext, userLevel, path) => {
      const pathResolved = getters[MENU_CONFIG_TYPES.GETTERS.RESOLVE_MENU_PATH](configContext, path);

      const optionsMenuPermissionConfigFn = getters[MENU_CONFIG_TYPES.GETTERS.OPTIONS_MENU_PERMISSION_CONFIG];
      const optionsMenuPermissionConfigEditedFn = getters[MENU_CONFIG_TYPES.GETTERS.OPTIONS_MENU_PERMISSION_CONFIG_EDITED];

      // menu config in parent user level
      const parentUserLevel = MAP_PARENT_USER_LEVEL?.[userLevel];
      const optionsMenuPermissionConfigByParentUserLevel = optionsMenuPermissionConfigFn(configContext, parentUserLevel);
      const optionsMenuPermissionConfigEditedByParentUserLevel = optionsMenuPermissionConfigEditedFn(configContext, parentUserLevel);
      const configParentUserLevel = optionsMenuPermissionConfigEditedByParentUserLevel?.[pathResolved] || optionsMenuPermissionConfigByParentUserLevel?.[pathResolved];

      // menu config
      const optionsMenuPermissionConfigByUserLevel = optionsMenuPermissionConfigFn(configContext, userLevel);
      const optionsMenuPermissionConfigEditedByUserLevel = optionsMenuPermissionConfigEditedFn(configContext, userLevel);
      const config = optionsMenuPermissionConfigEditedByUserLevel?.[pathResolved] || optionsMenuPermissionConfigByUserLevel?.[pathResolved];

      return configParentUserLevel?.visible !== false && config?.visible !== false;
    };
  },
  [MENU_CONFIG_TYPES.GETTERS.IS_MENU_PATH_DISABLED](_, getters) {
    return (configContext, userLevel, path) => {
      const flatMenuConfigByPath = getters[MENU_CONFIG_TYPES.GETTERS.FLAT_MENU_CONFIG_BY_PATH](configContext);
      const pathResolved = getters[MENU_CONFIG_TYPES.GETTERS.RESOLVE_MENU_PATH](configContext, path);

      const menu = flatMenuConfigByPath[pathResolved];
      if(!menu) return false;

      // Check if parents menus is visible
      const parents = [menu].reduce(function findParent(acc, m) {
        if(!m.parent) return acc;
        return [ m.parent, ...[m.parent].reduce(findParent, acc), ];
      }, []);
      const isMenuPathVisibleFn = getters[MENU_CONFIG_TYPES.GETTERS.IS_MENU_PATH_VISIBLE];
      const isParentsVisible = parents.every(parent => isMenuPathVisibleFn(configContext, userLevel, parent.path));

      // Check if menu is visible in parent user level
      const parentUserLevel = MAP_PARENT_USER_LEVEL?.[userLevel];
      const isMenuVisibleInParentUserLevel = parentUserLevel ? isMenuPathVisibleFn(configContext, parentUserLevel, menu.path) : true;
      return isParentsVisible === false || isMenuVisibleInParentUserLevel === false;
    };
  },

  // Options Menu Config
  [MENU_CONFIG_TYPES.GETTERS._CURRENT_OPTIONS_MENU_CONFIG_EDITED_AND_SAVED_MERGED](_, getters) { // should be used only in inner getters
    return (configContext, userLevel, configId) => {
      if (!configContext || !userLevel || !configId) return [];

      const optionsMenuConfigEditedFn = getters[MENU_CONFIG_TYPES.GETTERS.OPTIONS_MENU_CONFIG_EDITED];
      const optionsMenuConfigFn = getters[MENU_CONFIG_TYPES.GETTERS.OPTIONS_MENU_CONFIG];

      const optionsMenuConfigEdited = optionsMenuConfigEditedFn(configContext, userLevel, configId);
      const optionsMenuConfig = optionsMenuConfigFn(configContext, userLevel, configId)
        .filter(menu => !optionsMenuConfigEdited?.some(menuEdited => menuEdited.path === menu.path)); // remove duplicated

      return [ ...optionsMenuConfigEdited || [], ...optionsMenuConfig || [], ];
    };
  },
  [MENU_CONFIG_TYPES.GETTERS._CURRENT_OPTIONS_MENU_CONFIG](_, getters) {
    return (configContext, userLevel, configId) => {
      if (!configContext || !userLevel || !configId) return [];

      // edited config
      const optionsMenuConfigEditedFn = getters[MENU_CONFIG_TYPES.GETTERS.OPTIONS_MENU_CONFIG_EDITED];
      const optionsMenuConfigEdited = optionsMenuConfigEditedFn(configContext, userLevel, configId);
      if (optionsMenuConfigEdited) {
        return [ ...optionsMenuConfigEdited || [], ];
      }

      // saved config
      const optionsMenuConfigFn = getters[MENU_CONFIG_TYPES.GETTERS.OPTIONS_MENU_CONFIG];
      const optionsMenuConfig = optionsMenuConfigFn(configContext, userLevel, configId);
      return [ ...optionsMenuConfig || [], ];
    };
  },
  [MENU_CONFIG_TYPES.GETTERS.IS_CONFIGURED_MENU_REMOVEABLE](_, getters) {
    return (configContext, userLevel, configId, path) => {
      if (!configContext || !userLevel || !configId || !path) return false;

      const _currentOptionsMenuConfigFn = getters[MENU_CONFIG_TYPES.GETTERS._CURRENT_OPTIONS_MENU_CONFIG_EDITED_AND_SAVED_MERGED];

      // Check menu configured in makler master struktur
      const currentMaklerMasterStrukturConfig = _currentOptionsMenuConfigFn(configContext, FC_CONFIG_USER_LEVEL.MAKLER_MASTER_STRUKTUR, configId);
      const menuMaklerStruktur = currentMaklerMasterStrukturConfig.find(menu => menu.path === path);
      if(userLevel !== FC_CONFIG_USER_LEVEL.MAKLER_MASTER_STRUKTUR && menuMaklerStruktur?.removeable === false) {
        return false;
      }

      // Check menu configured in user level
      const currentConfig = _currentOptionsMenuConfigFn(configContext, userLevel, configId);
      const menu = currentConfig.find(menu => menu.path === path);
      return menu?.removeable !== false;
    };
  },
  [MENU_CONFIG_TYPES.GETTERS.IS_CONFIGURED_MENU_REMOVEABLE_CHANGEABLE](_, getters) {
    return (configContext, userLevel, configId, path) => {
      if (!configContext || !userLevel || !configId || !path) return false;

      if(userLevel === FC_CONFIG_USER_LEVEL.MAKLER_MASTER_STRUKTUR) return true; // always removeable changeable for MAKLER_MASTER_STRUKTUR user level

      const _currentOptionsMenuConfigFn = getters[MENU_CONFIG_TYPES.GETTERS._CURRENT_OPTIONS_MENU_CONFIG_EDITED_AND_SAVED_MERGED];

      // Check menu configured in makler master struktur
      const currentMaklerMasterStrukturConfig = _currentOptionsMenuConfigFn(configContext, FC_CONFIG_USER_LEVEL.MAKLER_MASTER_STRUKTUR, configId);
      const menuMasterStruktur = currentMaklerMasterStrukturConfig.find(menu => menu.path === path);
      if(menuMasterStruktur?.removeable === false) {
        return false;
      }

      // Check menu configured in user level
      const currentConfig = _currentOptionsMenuConfigFn(configContext, userLevel, configId);
      const menu = currentConfig.find(menu => menu.path === path);
      return menu?.removeableChangeable !== false;
    };
  },
  [MENU_CONFIG_TYPES.GETTERS.IS_CONFIGURED_MENU_LOCKED](_, getters) {
    return (configContext, userLevel, configId, path) => {
      if (!configContext || !userLevel || !configId || !path) return false;

      const _currentOptionsMenuConfigFn = getters[MENU_CONFIG_TYPES.GETTERS._CURRENT_OPTIONS_MENU_CONFIG_EDITED_AND_SAVED_MERGED];

      // Check menu configured in makler master struktur
      const currentMaklerMasterStrukturConfig = _currentOptionsMenuConfigFn(configContext, FC_CONFIG_USER_LEVEL.MAKLER_MASTER_STRUKTUR, configId);
      const menuMasterStruktur = currentMaklerMasterStrukturConfig.find(menu => menu.path === path);
      if(userLevel !== FC_CONFIG_USER_LEVEL.MAKLER_MASTER_STRUKTUR && menuMasterStruktur?.locked === true) {
        return true;
      }

      // Check menu configured in user level
      const currentConfig = _currentOptionsMenuConfigFn(configContext, userLevel, configId);
      const menu = currentConfig.find(menu => menu.path === path);
      return menu?.locked === true;
    };
  },
  [MENU_CONFIG_TYPES.GETTERS.IS_CONFIGURED_MENU_CHANGEABLE](_, getters) {
    return (configContext, userLevel, configId, path) => {
      if (!configContext || !userLevel || !configId || !path) return false;

      if(userLevel === FC_CONFIG_USER_LEVEL.MAKLER_MASTER_STRUKTUR) return true; // always changeable for MAKLER_MASTER_STRUKTUR user level

      const _currentOptionsMenuConfigFn = getters[MENU_CONFIG_TYPES.GETTERS._CURRENT_OPTIONS_MENU_CONFIG_EDITED_AND_SAVED_MERGED];

      // Check menu configured in makler master struktur
      const currentMaklerMasterStrukturConfig = _currentOptionsMenuConfigFn(configContext, FC_CONFIG_USER_LEVEL.MAKLER_MASTER_STRUKTUR, configId);
      const menuMasterStruktur = currentMaklerMasterStrukturConfig.find(menu => menu.path === path);
      if(userLevel !== FC_CONFIG_USER_LEVEL.MAKLER_MASTER_STRUKTUR && menuMasterStruktur?.locked === true) {
        return false;
      }

      // Check menu configured in user level
      const currentConfig = _currentOptionsMenuConfigFn(configContext, userLevel, configId);
      const menu = currentConfig.find(menu => menu.path === path);
      return menu?.changeable !== false;
    };
  },
  [MENU_CONFIG_TYPES.GETTERS.CONFIGURED_MENU_FROM_STRUKTUR](_, getters) {
    return (configContext, userLevel, configId) => {
      if (!configContext || !userLevel || !configId) return [];

      if(userLevel === FC_CONFIG_USER_LEVEL.MAKLER_MASTER_STRUKTUR) return []; // always from struktur is empty for MAKLER_MASTER_STRUKTUR user level

      const _currentOptionsMenuConfigFn = getters[MENU_CONFIG_TYPES.GETTERS._CURRENT_OPTIONS_MENU_CONFIG_EDITED_AND_SAVED_MERGED];

      const isConfiguredMenuLockedFn = getters[MENU_CONFIG_TYPES.GETTERS.IS_CONFIGURED_MENU_LOCKED];
      const isConfiguredMenuChangeableFn = getters[MENU_CONFIG_TYPES.GETTERS.IS_CONFIGURED_MENU_CHANGEABLE];
      const isFromStrukturFn = (path) => {
        return isConfiguredMenuLockedFn(configContext, userLevel, configId, path) && !isConfiguredMenuChangeableFn(configContext, userLevel, configId, path);
      };

      const currentConfigMasterStruktur = _currentOptionsMenuConfigFn(configContext, FC_CONFIG_USER_LEVEL.MAKLER_MASTER_STRUKTUR, configId);
      const currentConfig = _currentOptionsMenuConfigFn(configContext, userLevel, configId);

      const menuFromMaklerMasterStruktur = currentConfigMasterStruktur
        .filter(cm => isFromStrukturFn(cm.path));
      const menuFromStruktur = currentConfig
        .filter(cm => isFromStrukturFn(cm.path) && !menuFromMaklerMasterStruktur.some(m => m.path === cm.path)); // remove duplicated

      return [ ...menuFromMaklerMasterStruktur, ...menuFromStruktur, ];
    };
  },
  [MENU_CONFIG_TYPES.GETTERS.OPTIONS_MENU_CONFIG](state, getters) {
    return (configContext, userLevel, configId) => {
      if (!configContext || !userLevel || !configId) return [];

      const hasSelectedUnternr = getters[MENU_CONFIG_TYPES.GETTERS.HAS_SELECTED_UNTERNR](configContext);

      const { optionsMenuConfig, } = state;
      const optionsMenuFCConfig = optionsMenuConfig?.[configContext]?.[configId]?.[userLevel] || {};
      return JSON.parse(optionsMenuFCConfig?.content || DEFAULT_ARRAY_STRINGIFIED).map(menu => ({
        ...menu,
        id: !menu.id ? menu.path : menu.id, // it handles old object structure

        // overrides "removeableChangeable" and "changeable" when there is unternr selected
        // it avoids misbehavior when a Makler is editing a Mitarbeiter menu config
        removeableChangeable: menu.removeable === false && hasSelectedUnternr ? false : menu.removeableChangeable,
        changeable: menu.locked === true && hasSelectedUnternr ? false : menu.changeable,
      }));
    };
  },
  [MENU_CONFIG_TYPES.GETTERS.HAS_OPTIONS_MENU_CONFIG_SAVED](state) {
    return (configContext, userLevel, configId) => {
      if(!configContext || !userLevel || !configId) return false;

      const { optionsMenuConfig, } = state;
      const optionsMenuFCConfig = optionsMenuConfig?.[configContext]?.[configId]?.[userLevel] || {};
      return !!optionsMenuFCConfig?.content;
    };
  },
  [MENU_CONFIG_TYPES.GETTERS.OPTIONS_MENU_CONFIG_EDITED](state, getters) {
    return (configContext, userLevel, configId) => {
      if(!configContext || !userLevel || !configId) return null;

      const hasOptionsMenuConfigSaved = getters[MENU_CONFIG_TYPES.GETTERS.HAS_OPTIONS_MENU_CONFIG_SAVED](configContext, userLevel, configId);
      if(configContext in state.optionsMenuConfigEdited 
        && configId in state.optionsMenuConfigEdited[configContext] 
        && userLevel in state.optionsMenuConfigEdited[configContext][configId]) {
        return state.optionsMenuConfigEdited[configContext][configId][userLevel];
      } else if(!hasOptionsMenuConfigSaved) { // if there is NO config saved for the user level, try to get the current config edited for MAKLER_MASTER_STRUKTUR user level
        return state.optionsMenuConfigEdited?.[configContext]?.[configId]?.[FC_CONFIG_USER_LEVEL.MAKLER_MASTER_STRUKTUR] || null;
      }
      return null;
    };
  },
  [MENU_CONFIG_TYPES.GETTERS.HAS_MENU_CONFIG_EDITED](state) {
    return (configContext) => {
      if (!configContext) return false;

      const { optionsMenuConfigEdited, } = state;
      const configIds = Object.keys(optionsMenuConfigEdited?.[configContext] || {});
      return configIds.length > 0 || configIds.some(configId => Object.keys(optionsMenuConfigEdited?.[configContext]?.[configId] || {}).length > 0);
    }
  },

  // Options Menu Permission Config
  [MENU_CONFIG_TYPES.GETTERS.IS_OPTIONS_MENU_PERMISSION_CONFIGURABLE](state, getters) {
    return (configContext, userLevel) => {
      if (!configContext || !userLevel) return false;

      const hasRoles = getters[CORE_TYPES.GETTERS.HAS_ROLES];

      if(userLevel === FC_CONFIG_USER_LEVEL.MAKLER) { // is MAKLER user level?
        return !hasRoles([VIEW_ROLES.VIEW_BROKER_ONLY]) 
          || hasRoles([ROLES.IS_BYPASS_SLASH]) 
          || getters[MENU_CONFIG_TYPES.GETTERS.HAS_SELECTED_UNTERNR](configContext); // permission only available for non view broker only or bypass slash login or when has a selected unternr
      }

      if(userLevel === FC_CONFIG_USER_LEVEL.KUNDE) { // is KUNDE user level?
        return !hasRoles([VIEW_ROLES.VIEW_CUSTOMER_ONLY]) || hasRoles([ROLES.IS_BYPASS_SLASH]); // permission only available for non view customer only or bypass slash login
      }

      return true;
    };
  },
  [MENU_CONFIG_TYPES.GETTERS.OPTIONS_MENU_PERMISSION_CONFIG](state) {
    return (configContext, userLevel) => state.optionsMenuPermissionConfig?.[configContext]?.[userLevel] || {};
  },
  [MENU_CONFIG_TYPES.GETTERS.OPTIONS_MENU_PERMISSION_CONFIG_EDITED](state) {
    return (configContext, userLevel) => state.optionsMenuPermissionConfigEdited?.[configContext]?.[userLevel] || null;
  },
  [MENU_CONFIG_TYPES.GETTERS.HAS_MENU_PERMISSION_CONFIG_EDITED](state) {
    return (configContext) => {
      if (!configContext) return false;

      const { optionsMenuPermissionConfigEdited } = state;
      const userLevels = Object.keys(optionsMenuPermissionConfigEdited?.[configContext] || {});
      return userLevels.length > 0 || userLevels.some(userLevel => Object.keys(optionsMenuPermissionConfigEdited?.[configContext]?.[userLevel] || {}).length > 0);
    }
  },
  [MENU_CONFIG_TYPES.GETTERS.ALL_OPTIONS_MENU_PERMISSION_CONFIG_ALL_MITARBEITER](state) {
    return state.allOptionsMenuPermissionConfigAllMitarbeiter;
  },
  [MENU_CONFIG_TYPES.GETTERS.ALL_OPTIONS_MENU_PERMISSION_CONFIG_ALL_UNTERSTRUKTUR](state) {
    return state.allOptionsMenuPermissionConfigAllUnterstruktur;
  },
  [MENU_CONFIG_TYPES.GETTERS.IS_OPTIONS_MENU_PERMISSION_CONFIG_VISIBLE](state, getters) {
    return (configContext, path) => {
      if (!configContext || !path) return false;

      const userLevelDefault = getters[MENU_CONFIG_TYPES.GETTERS.OPTIONS_MENU_CONFIG_USER_LEVEL_DEFAULT](true);

      const { optionsMenuPermissionConfig } = state;
      const permissionConfig = optionsMenuPermissionConfig?.[configContext]?.[userLevelDefault] || {};
      const decodedPath = decodeURIComponent(path);

      let allowed = permissionConfig?.[path]?.visible !== false && permissionConfig?.[decodedPath]?.visible !== false;

      if (!allowed) {
        allowed = permissionConfig?.[path]?.changeable !== false;
      }

      return allowed;
    };
  },

}
