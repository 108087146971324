<template>
  <BaseModal
    ref="modal"
    modalTitle="Personenbeziehungen"
    :showDefaultButtons="false"
  >
    <template #default>
      <div>
        <ComboBox
          label="Person"
          v-model="selectedPersonId"
          :values="peopleValues"
        />
        <template v-if="selectedPersonId">
          <div class="box__title">{{ selectedPerson.fullName }} ist...</div>
          <template v-if="selectedPersonRelationships.length > 0">
            <div v-for="(personRelationship, index) in selectedPersonRelationships" :key="`${selectedPerson.personId}${index}`"
              class="row">
              <div class="col-8">
                <ComboBox
                  v-model="personRelationship.relationArt"
                  :values="relationsValues(personRelationship.fullName)"
                  @change="dataChanged(); relationChanged(personRelationship, $event)"
                />
              </div>
              <div class="col-4">
                <InputToggleSwitch
                  class="field-is-legal-agent"
                  label="ges. Vertreter/in"
                  :inLineLabel="true"
                  :disabled="!personRelationship.relationArt"
                  v-model="personRelationship.isLegalAgent"
                  @input="dataChanged(); isLegalAgentChanged(personRelationship, $event)"
                />
              </div>
            </div>
          </template>
          <div v-else>Keine Daten</div>
        </template>
      </div>
    </template>
    <template #footer>
      <BaseButton 
        class="mr-3"
        isSecondary
        @click="close()">Abbrechen</BaseButton>
      <BaseButton 
        @click="save()" 
        :disabled="loading">
        <AnimatedSpinner v-if="loading" /> Speichern
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { mapGetters } from 'vuex';
import CUSTOMERDATA_TYPES from '@/store/customerData/types';

import BaseModal from '@/components/core/BaseModal.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';


export default {
  data() {
    return {
      changed: false,
      loading: false,
      selectedPersonId: null,
      personRelationshipsByPersonId: {},
      inverseIsLegalAgent: {
        'true': false,
      }
    };
  },
  computed: {
    ...mapGetters({
      peopleRelationship: CUSTOMERDATA_TYPES.GETTERS.PEOPLE_RELATIONSHIP,
      customerDataConfig: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_CONFIG,
    }),
    peopleValues() {
      return this.peopleRelationship?.map((person) => ({
        label: person?.fullName,
        value: person?.personId,
      }));
    },
    selectedPerson() {
      return this.peopleRelationship?.find((person) => person?.personId === this.selectedPersonId) || {};
    },
    selectedPersonRelationships() {
      return this.personRelationshipsByPersonId[this.selectedPerson?.personId] || [];
    },
    relations() {
      const relations = this.customerDataConfig?.relations || [];
      return [...relations];
    },
    inverseRelations() {
      const inverseRelations = this.customerDataConfig?.inverseRelations || {};
      return { ...inverseRelations };
    },
  },
  watch: {
    peopleRelationship() {
      this.personRelationshipsByPersonId = this.peopleRelationship?.reduce((acc, person) => {
        acc[person?.personId] = [...person?.personRelationships];
        return acc;
      }, {});
    },
  },
  methods: {
    init() {
      this.changed = false;
      this.loading = false;
      this.selectedPersonId = null;
      this.personRelationshipsByPersonId = {};
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.FIND_PEOPLE_RELATIONSHIP);
    },
    open() {
      this.$refs.modal.open();
      this.init();
    },
    close() {
      this.$refs.modal.close();
    },
    relationsValues(fullName) {
      return this.relations.map((relation) => ({
        label: relation?.display ? `${relation?.display} ${fullName || ''}` : '',
        value: relation?.value,
      }));
    },
    dataChanged() {
      this.changed = true;
    },
    findTargetPerson(changedPersonId, targetPersonId) {
      const changedPersonRelationships = this.personRelationshipsByPersonId[changedPersonId];
      return changedPersonRelationships.find(p => p?.personId === targetPersonId) || {};
    },
    relationChanged(person, value) {
      const targetPerson = this.findTargetPerson(person?.personId, this.selectedPerson?.personId);
      const inverseRelationArt = this.inverseRelations[value];
      targetPerson.relationArt = inverseRelationArt;
    },
    isLegalAgentChanged(person, value) {
      const targetPerson = this.findTargetPerson(person?.personId, this.selectedPerson?.personId);
      const inverseIsLegalAgent = !!this.inverseIsLegalAgent[`${value}`];
      targetPerson.isLegalAgent = inverseIsLegalAgent;
    },
    async save() {
      if(!this.changed) {
        this.close();
        return ;
      }

      const peopleRelationship = Object.keys(this.personRelationshipsByPersonId)
        .map((personId) => {
          const personRelationships = this.personRelationshipsByPersonId[personId];
          return {
            personId,
            personRelationships,
          };
        });
      this.loading = true;
      await this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.SAVE_PEOPLE_RELATIONSHIP, { peopleRelationship });
      this.loading = false;
      this.$emit('saved');
      this.close();
    },
  },
  components: {
    BaseModal,
    ComboBox,
    InputToggleSwitch,
    BaseButton,
    AnimatedSpinner,
  },
}
</script>

<style scoped>
.field-is-legal-agent {
  margin-top: 4px;
}
</style>
