<template>
  <div>
  </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';

export default {
  mixins: [],
  computed: {
  },
  beforeRouteLeave (to, from, next) {

    switch (to.fullPath) {
      case "":
      default:
    }

    this.$addBreadcrumb({
      label: 'zurück zur Beratung', 
      to,
      from,
    });

    next()
  },
  components: {
    OptionMenu,
  }
}
</script>

<style scoped>

</style>