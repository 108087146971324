var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "context-menu-group__container" }, [
    _vm.title
      ? _c("div", { staticClass: "context-menu-group--title box__title" }, [
          _vm._v(_vm._s(_vm.title))
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "context-menu-group--content" },
      [_vm._t("default")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }