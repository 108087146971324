<template>
    <button type="button" @click="onClick" :tid="_generateTidFromString(tid + actionKey)" class="btn-clean clickable">
        <component :is="iconComponent" :size="size"/>
        <span v-if="expanded" v-html="sanitizedValue"></span>
    </button>
</template>
<script>
import { sanitize } from '@/helpers/string-helper.js';
import actionMixin from './action-mixin.js';

export default {
    mixins: [actionMixin],
    computed: {
        sanitizedValue() {
            return sanitize(this.label);
        },
    },
}
</script>
<style src='./action.scss' lang='scss' scoped/>
