var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-forms__container" },
    [
      _c(
        "label",
        {
          staticClass: "input-forms__label-container clickable",
          class: _vm.labelContainerClasses
        },
        [
          _vm.label
            ? _c("div", {
                staticClass: "input-forms__label",
                class: { "font-bold": _vm.config && _vm.config.bold },
                domProps: { innerHTML: _vm._s(_vm.sanitize(_vm.label)) }
              })
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "input-forms__input-container",
              class: {
                "input-forms__input-container--top": _vm.switchHandlerTopAlign
              }
            },
            [
              _c("label", { staticClass: "toggle-switch__container" }, [
                _c(
                  "span",
                  {
                    staticClass: "toggle-switch__body",
                    class: { colored: _vm.colored }
                  },
                  [
                    _c("input", {
                      attrs: { type: "checkbox", disabled: _vm.disabled },
                      domProps: { checked: _vm.checked },
                      on: {
                        input: function($event) {
                          return _vm.onChange($event)
                        }
                      }
                    }),
                    _c("span", {
                      staticClass: "toggle-switch__slider round",
                      class: {
                        "toggle-switch__slider--error": _vm.isComponentInvalid
                      }
                    })
                  ]
                )
              ])
            ]
          )
        ]
      ),
      _vm.isValidationConfigured()
        ? [
            !_vm.suppressValidationMessage && _vm.validation
              ? _c(
                  "div",
                  { key: _vm.validation.updated },
                  _vm._l(
                    _vm.validation.getErrors(
                      _vm.validationPath,
                      _vm.validateUntouched
                    ),
                    function(error) {
                      return _c(
                        "div",
                        {
                          key: error,
                          staticClass: "input-forms__error-message"
                        },
                        [_vm._v(" " + _vm._s(error) + " ")]
                      )
                    }
                  ),
                  0
                )
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }