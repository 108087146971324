<template>
  <BaseModal 
    ref="modal" 
    modalTitle="Menüstruktur" 
    size="sm" 
    :confirmDisabled="loading || isInvalid"
    @onConfirmButton="save()"
  >
    <template #default>
      <InputField label="Beschreibung" v-model="form.label" autofocus validateUntouched />

      <ComboBox label="Ebene" v-model="form.context" 
        :values="contextValues" :sortComboboxValues="false" validateUntouched 
        :disabled="!isNew"
      />
    </template>
  </BaseModal>
</template>

<script>
import MENU_STRUCTURE_TYPES from '@/store/menuStructure/types';

import BaseModal from '@/components/core/BaseModal.vue';
import InputField from '@/components/core/forms/InputField.vue';
import ComboBox, { buildValue } from '@/components/core/forms/ComboBox.vue';

import validator from '@/mixins/validator';
import { required } from '@/mixins/validator/rules';

import { CONTEXTS, MAP_CONFIG_CONTEXT_LABEL } from './menu-structure-utils';

export default {
  mixins: [validator],
  components: {
    BaseModal,
    InputField,
    ComboBox,
  },
  data() {
    return {
      loading: false,
      menuStructure: null,
      form: {
        label: '',
        content: null,
      },
      contextValues: CONTEXTS.map(context => buildValue(context, MAP_CONFIG_CONTEXT_LABEL[context])),
    };
  },
  computed: {
    isNew() {
      return !this.menuStructure?.id;
    },
    isInvalid() {
      return this.validation?.updated && this.validation?.isInvalid('form');
    },
  },
  methods: {
    open(menuStructure) {
      this.reset();
      this.$refs.modal.open();

      this.menuStructure = menuStructure ? { ...menuStructure } : null;
      this.form = {
        label: menuStructure?.label ?? '',
        context: menuStructure?.context,
      };
    },
    close() {
      this.$refs.modal.close();
    },
    reset() {
      this.form = {
        label: '',
      };
    },
    async save() {
      if (this.isInvalid) return;

      try {
        this.loading = true;
        const { menuStructure, form } = this;

        const payload = {
          ...form,
          id: menuStructure?.id,
        };
        const menuStructureResponse = await this.$store.dispatch(MENU_STRUCTURE_TYPES.ACTIONS.SAVE_MENU_STRUCTURE, payload);
        this.$emit('saved', menuStructureResponse);
      } finally {
        this.loading = false;
      }
    },
  },
  validators: {
    form: {
      label: [required('')],
      context: [required('')],
    },
  },
}
</script>
