import GRAPHICAL_COURSE_TYPES from './types';

export default {
  [GRAPHICAL_COURSE_TYPES.GETTERS.SITE]: (state) => (site) => {
    return state.sessions[site];
  },

  [GRAPHICAL_COURSE_TYPES.GETTERS.IS_DEPOT_LOADING]: (state) => (depot) => {
    return state.aggregate?.[depot]?.request !== depot;
  },

  [GRAPHICAL_COURSE_TYPES.GETTERS.DEPOT_HAS_DATA]: (state) => (depot) => {
    return state.aggregate?.[depot]?.data?.options?.len > 0;
  },

  [GRAPHICAL_COURSE_TYPES.GETTERS.CONFIG](state) {
    return state.config;
  },

}