<template>
  <div>
    <Table
        v-if="wpRows.length"
        :title="TABLE_TITLE"
        :tableId="tableId"
        :headers="wpHeaders"
        :rows="wpRows"
        :headerActions="headerActions"
        :mobileConfig="{title: 'fondsname', headers: ['gesellschaft', 'isin', 'old_wkn'], singleSelection, selectionActive: showMobileSelectionAlways}"
        rowId="rowId"
        :rowsPerPage="15"
        :selected="selectedRows"
        :exportConfig="exportConfig"
        @selected="onSelect"
        @click-fondsname="gotoFondsinfo"
        @action="handleAction"
        @action-ADD_FAVORITENLISTE="openAddFondsToListModal($event, 'Favoritenlisten')"
        @action-ADD_MYDEPOT="openAddFondsToListModal($event, 'MyDepot')"
        @action-ADD_FONDSVERGLEICH="openAddFondsToListModal($event, 'Fondsvergleich')"
        @headerAction="handleHeaderAction"
    >
      <template #selected="row">
        <InputRadioBoxItem
          name="selectFonds"
          @change="onSingleSelect(row)"
        />
      </template>
      
      <template #fonds_ampel="{fonds_ampel}">
        <div :class="fonds_ampel"></div>
      </template>
    </Table>
    <GhostLoading v-else-if="loading" type="table" :title="TABLE_TITLE" />
    <NoData v-else :title="TABLE_TITLE" content="Es wurden keine Einträge gefunden" />

    <AddFondsToListModal ref="addFondsToListModal" />
  </div>
</template>

<script>

import InputRadioBoxItem from '@/components/core/forms/radiobox/InputRadioBoxItem';
import Table from "@/components/table2/Table.vue";
import { SlotColumn, LinkAction, DownloadLinkHrefAction, SimpleAction, oldToNewColumn } from "@/components/table2/table_util.js";
import NoData from '@/components/core/NoData.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NumberCell from '@/components/table2/cellTypes/NumberCell.vue'
import AddFondsToListModal from '@/components/fondsinfo/AddFondsToListModal.vue';

const TABLE_TITLE = 'Wertpapierliste';

export default {
  components: {
    InputRadioBoxItem, 
    Table,
    NoData,
    GhostLoading,
    NumberCell,
    AddFondsToListModal,
  },
  model: {
    prop: 'selected',
    event: 'selected',
  },
  props: {
    selected: {
      type: Array,
      default: () => null,
    },
    tableId: {
      type: String,
      default: 'edbcf7d7-6b85-4128-b8bb-22ef17c18c50',
    },
    rows: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    headerActions: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    singleSelection: {
      type: Boolean,
      default: false,
    },
    canAddToFavoritenliste: {
      type: Boolean,
      default: false,
    },
    canAddToMyDepot: {
      type: Boolean,
      default: false,
    },
    canAddToFondsvergleich: {
      type: Boolean,
      default: false,
    },
    exportConfig: {
      type: Object,
      default: () => ({roottext: 'Wertpapierliste'}),
    },
    type: {
      type: String,
      default: 'I',
    },
    showMobileSelectionAlways: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      TABLE_TITLE,
      useSelected: false,
      selectedRow: null,
    }
  },
  watch: {
    selected: {
      handler(val) {
        this.useSelected = !!val
      },
      immediate: true,
    },
  },
  computed: {
    selectedRows() {
      return (!this.singleSelection || this.$isSmallScreen) && this.selected || null
    },
    wpRows() {
      return this.rows.map(row => ({
        ...row,
        rowId: row.isin || row._fondsData?.isin,
        actions: this.makeActions(row)
      }))
    },
    wpHeaders() {
      const headers = {
        lockedLeft: [],
        center: [],
        lockedRight: [],
      }

      if (this.useSelected && this.singleSelection) {
          headers.lockedLeft.push(SlotColumn("selected", " ", 50));
      }

      this.headers.filter(h => h.visible)
        .forEach(header => {
          switch (header.lockedColumn) {
            case 'left':
              headers.lockedLeft.push(oldToNewColumn(header.key, header.label, header.dataType, header.cellProps, header.minWidth, header.clickable, header.sortable || true))
              break;
            case 'right':
              headers.lockedRight.push(oldToNewColumn(header.key, header.label, header.dataType, header.cellProps, header.minWidth, header.clickable, header.sortable || true))
              break;
            default:
              const newHeader = oldToNewColumn(header.key, header.label, header.dataType, header.cellProps, header.minWidth, header.clickable, header.sortable || true)

              newHeader.addCellProps({lineClamp: 4})

              // make negative numbers red
              if (newHeader && newHeader.rowElement == NumberCell) {
                newHeader.setColorFn(row => row[newHeader.key] < 0 ? 'color-danger' : null)
              }
              
              // make fonds ampel sortable
              if (newHeader && newHeader.key === 'fonds_ampel') {
                newHeader.makeSortable(ampel => ampel === 'ampel-gruen' ? 4 : ampel === 'ampel-gelb' ? 3 : ampel === 'ampel-rot' ? 2 : 1)
              }

              headers.center.push(newHeader)
              break;
          }
        });
      return headers;
    },
  },
  methods: {
    makeActions(row) {
      const actions = [];

      row.actions?.forEach(action => {
        const actionKey = action.key || action.actionKey;
        if (action.href) {
          if (action.href.includes('.pdf')) {
            actions.push(DownloadLinkHrefAction(actionKey, action.icon, action.label, `${action.label}.pdf`, action.href))
          } else {
            actions.push(LinkAction(actionKey, action.icon, action.label, action.href))
          }
        } else {
          actions.push(SimpleAction(actionKey, action.icon, action.label))
        }
      })

      if (this.canAddToFavoritenliste) {
        actions.push(SimpleAction('ADD_FAVORITENLISTE', 'PhPlusCircle', 'Zur Favoritenliste hinzufügen'))
      }

      if (this.canAddToMyDepot) {
        actions.push(SimpleAction('ADD_MYDEPOT', 'PhPlusCircle', 'Zum Musterdepot hinzufügen'))
      }

      if (this.canAddToFondsvergleich) {
        actions.push(SimpleAction('ADD_FONDSVERGLEICH', 'PhPlusCircle', 'Zum Fondsvergleich hinzufügen'))
      }

      return actions;
    },
    handleAction({key, row}) {
      this.$emit('action', {key, row});
      this.$emit(`action-${key}`, row);
    },
    handleHeaderAction({key, value}) {
      this.$emit('headerAction', {key, value})
      this.$emit(`headerAction-${key}`, value)
    },
    onSelect(rows) {
      this.$emit("selected", rows);
    },
    onSingleSelect(row) {
      this.$emit("selected", [row]);
    },
    emitSelected() {
      this.$emit('selected-funds', this.selected);
    },
    gotoFondsinfo(row) {
      this.$addBreadcrumb({
        fullPath: this.$route.fullPath,
      })

      // Beteiligungen do not have isin property, instead it's in _fondsData
      const isin = row.isin || row._fondsData?.isin
      const p = this.type === 'B' ? 'beteiligungen' : 'vermogensubersicht' ;
      this.$router.push(`/shared/${p}/fondsinfo/${isin}`);
    },
    openAddFondsToListModal(row, liste) {
      this.selectedRow = row
      this.$refs.addFondsToListModal.open([row], liste)
    },
  },
}
</script>

<style scoped>
.ampel-rot, .ampel-gelb, .ampel-gruen, .ampel-none{
 height: 16px;
 width: 16px;
 border-radius: 50%;
}
.ampel-rot{
 background-color: var(--color-danger);
}
.ampel-gelb{
 background-color: var(--color-warning);
}
.ampel-gruen{
 background-color: var(--color-success);
}
.ampel-none{
 background-color: transparent;
}
</style>
