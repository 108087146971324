<template >
    <div class="event-body" v-if="this.isLoaded">
        <BaseModal ref="modal" labelButtonCancel="Ok" @onCancelButton="close" :showConfirmButton="false">
            <template v-slot:modalTitle>
                Information
            </template>

                 <div v-if="dat && dat.warnInfo" class="font-bold"> 
                  {{dat.warnInfo}}
                 </div>   
                <Table v-if="dat &&dat.records.length" tableId="15b90920-ebdd-11ec-8ea0-0242ac120002" 
                     :headers="headers" :rows="rows" :rowsPerPage="1000" :hidePagination="true">
                    
                </Table>

           





        </BaseModal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import InputField from "@/components/core/forms/InputField.vue"
import BaseModal from '@/components/core/BaseModal.vue';
import A_C_TYPES from "@/store/fondsAntragCheck/types";
import BoxContainer from '@/components/core/BoxContainer.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue'
import LOG_TYPES from '@/store/log/types';
import { buildMessage } from '@/helpers/log-message-helper';
import Table from "@/components/table2/Table.vue";
import {
  TextColumn,
  

} from "@/components/table2/table_util.js";
export default {
    data() {

        return {

            isLoaded: false,
            id: null,
            dat: null

        };
    },
    computed: {

        ...mapGetters({





        }),
        headers() {
            return {
                lockedLeft: [

                    TextColumn("maklernr", "MaklerNr."),
                    TextColumn("maklerName", "Name"),
                    TextColumn("status", "Prüfung"),


                ] /* filter((column) => !this.disabledColumns.includes(column.key))*/,


            };
        },

        rows() {
            if (!this.dat) return [];
            return this.dat.records.map((record) => {
                const row = {
                    ...record,
                };



                return row;
            });
        },

    },
    mounted() {

    },

    methods: {



        async open(i) {
           
            this.id = i;
            await this.loadData();

            this.$refs.modal?.open();
        },
        close() {

            this.$refs.modal.close();
        },


        async loadData() {

            await this.$store.dispatch(A_C_TYPES.ACTIONS.INFO, this.id);
            this.dat = this.$store.getters[A_C_TYPES.GETTERS.INFO];
            this.isLoaded = true;
        }
    },
    components: {
        BaseModal,

     
        Table,

        BoxContainer
    },

}
</script>

<style scoped>
