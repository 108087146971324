import ANTRAG_EFONDS_TYPES from './types';
import ANTRAG_TYPES from "@/store/antrag/types";
import BERATUNGSMAPPE_TYPES from "@/store/beratungsmappe/types";
import LOG_TYPES from '@/store/log/types'
import CORE_TYPES from '@/store/core/types'
import axios from 'axios';
import { buildMessage } from "@/helpers/log-message-helper";
import { viewDocument } from '@/components/core/download/DownloadLink.vue';
import { setSessionObject } from '@/helpers/local-storage-helper'

const config = {
  defaultSpinner: true
};

export const prepareParams = (payload) => Object.entries(payload).map(([key, val]) => `${key}=${val}`).join('&');

export default {  
  
  async [ANTRAG_EFONDS_TYPES.ACTIONS.GET_MINIMAL_INVEST_INFO]({ rootState }, payload) {
    const param = prepareParams(payload);
    return axios.get(`${rootState.core.apiAddress}/efonds/getMinimalInvestInfo?${param}`, config).then(response => {
      return response.data
    });
  },   

  async [ANTRAG_EFONDS_TYPES.ACTIONS.GET_EFONDS_LINK_NEW]({ rootState }, payload) {
    const param = prepareParams(payload);
    return axios.get(`${rootState.core.apiAddress}/efonds/getEFondsEZeichnungLink?${param}`, config).then(response => {
      return response.data
    });
  },   

  async [ANTRAG_EFONDS_TYPES.ACTIONS.GET_EFONDS_LINK_STAMMDATEN]({ rootState }, payload) {
    const param = prepareParams(payload);
    return axios.get(`${rootState.core.apiAddress}/efonds/getEFondsStammdatenLink?${param}`, config).then(response => {
      if (response.data.link) {
        window.open(response.data.link, "_blank");
      }
    });
  },   

   [ANTRAG_EFONDS_TYPES.ACTIONS.GET_EFONDS_LINK_EDIT]({ rootState, getters, dispatch }, payload) {
    let params = prepareParams(payload);
    params += `&callbackUrl=${encodeURIComponent(`${window.location.href}${window.location.href.includes('isDeepLink') ? '' : '&isDeepLink=true'}`)}`;
    // const params = makeQueryParam({ ...this.$route.query, isDeepLink: true })
    axios.get(`${rootState.core.apiAddress}/efonds/editEZeichnung?${params}`, config).then(response => {
      if (response.data.errorStr) {
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.errorStr, 'danger'))
      } else if (response.data.link) {

        const test = getters[CORE_TYPES.GETTERS.GET_LOGIN_DATA];
        setSessionObject('deepLink-loginData', test);

        setTimeout(() => {
          window.location.href = response.data.link;
        }, 1000);
      }
    })
    .catch(error => dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error, 'danger')));
  },   

  [ANTRAG_EFONDS_TYPES.ACTIONS.GET_EFONDS_INFO_LINK]({ rootState }, payload) {
    const param = prepareParams(payload);
    return axios.get(`${rootState.core.apiAddress}/efonds/getEFondsInfoLink?${param}`, config).then(response => {
      if (response.data.link) {
        window.open(response.data.link, "_blank");
      }
    });
  },   
  
  async [ANTRAG_EFONDS_TYPES.ACTIONS.GET_EFONDS_LIST]({ rootState, commit }, payload) {
    await axios.get(`${rootState.core.apiAddress}/efonds/getEFondsUnassigned`, config).then(response => {
      commit(ANTRAG_TYPES.MUTATIONS.GET_ANTRAG_SUCCESS, {data: response.data, id: payload.id });
    });
  },   

  [ANTRAG_EFONDS_TYPES.ACTIONS.GET_EFONDS_EINREICHUNG_STR]({ rootState }, payload) {
    return axios.get(`${rootState.core.apiAddress}/efonds/getEFondsEinreichungStr?isin=${payload?.isin || ''}`, config).then(response => {
      return response.data
    });
  },   
 
  [ANTRAG_EFONDS_TYPES.ACTIONS.ADD_EZEICHNUNG_TO_MAPPE]({ rootState, dispatch, commit }, payload) {
    const param = prepareParams(payload);
    axios.get(`${rootState.core.apiAddress}/efonds/addEZeichnungToMappe?${param}`, config).then(response => {
      commit(ANTRAG_TYPES.MUTATIONS.GET_ANTRAG_SUCCESS, {data: response.data, id: payload.id });
      dispatch(BERATUNGSMAPPE_TYPES.ACTIONS.EDIT_BERATUNGSMAPPE, {beratungsMappeID: payload.beratungsMappeId});
    });
  },   
  
  [ANTRAG_EFONDS_TYPES.ACTIONS.REMOVE_EZEICHNUNG]({ rootState, dispatch, commit }, payload) {
    const param = prepareParams(payload);
    axios.get(`${rootState.core.apiAddress}/efonds/removeEZeichnung?${param}`, config).then(response => {
      commit(ANTRAG_TYPES.MUTATIONS.GET_ANTRAG_SUCCESS, {data: response.data, id: payload.id });
      dispatch(BERATUNGSMAPPE_TYPES.ACTIONS.EDIT_BERATUNGSMAPPE, {beratungsMappeID: payload.beratungsMappeId});
    });
  },   

  async [ANTRAG_EFONDS_TYPES.ACTIONS.GET_STAMMDATEN]({ rootState }, payload) {
    const response = await axios.get(`${rootState.core.apiAddress}/efonds/getStammdaten?personId=${payload?.personId}`, config);
    return response?.data;
  },   

  async [ANTRAG_EFONDS_TYPES.ACTIONS.UPLOAD_STAMMDATEN]({ rootState }, payload) {
    const response = await axios.post(`${rootState.core.apiAddress}/efonds/uploadStammdataData?personId=${payload.personId || ''}&callbackUrl=${payload?.callbackUrl || ''}`, payload, config);
    return response?.data;
  },   
 
  [ANTRAG_EFONDS_TYPES.ACTIONS.GET_EFONDS_PERSONS]({ rootState, commit }, payload) {
    axios.get(`${rootState.core.apiAddress}/efonds/getPersons`, config).then(response => {
      commit(ANTRAG_TYPES.MUTATIONS.GET_EFONDS_PERSONS_SUCCESS, {data: response.data, id: payload.id });
    });
  },   

  async [ANTRAG_EFONDS_TYPES.ACTIONS.SAVE_EZEICHNUNG]({ rootState }, payload) {
    if (payload?.antragsnrIntern || payload?.antragsdatenId) {
      const param = prepareParams(payload);
      const response = await axios.post(`${rootState.core.apiAddress}/efonds/saveEZEichnung?${param}`, payload, config);
      return response;
    }
  },   

  [ANTRAG_EFONDS_TYPES.ACTIONS.GET_KOSTENINFO_PDF]({ dispatch, rootState }, payload) {
    if (payload?.antragsnrIntern || payload?.antragsdatenId) {
      const param = prepareParams(payload);
      axios.get(`${rootState.core.apiAddress}/efonds/getKostenPdf?${param}`, config).then(response => {
        if (response.data?.bytes) {
          viewDocument({
            data: response.data.bytes,
            filename: response.data.fileName,
            contentType: response.data.contentType,
          }, true);
        }
      })
      .catch(error => dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error, 'danger')));
    }
  },

}