var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "mb-5" },
        [
          _c("InputToggleSwitch", {
            attrs: {
              inLineLabel: "",
              label: "Anlage in Managed Depot erfassen",
              disabled: true
            },
            model: {
              value: _vm.djeAktiv,
              callback: function($$v) {
                _vm.djeAktiv = $$v
              },
              expression: "djeAktiv"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "box__title" }, [
        _vm._v("Angaben über die Nachhaltigkeitspräferenzen")
      ]),
      _c("InputToggleSwitch", {
        attrs: {
          inLineLabel: "",
          label:
            "Weitere Informationen habe ich/wir im Dokument Nachhaltigkeit im Investmentprozess zur Kenntnis genommen.",
          disabled: _vm.isDisabled || !_vm.isZusatzDJEAktiv
        },
        on: {
          input: function($event) {
            return _vm.updateStore("zusatzDJE", "djeMifid2Kenntnis", $event)
          }
        },
        model: {
          value: _vm.djeMifid2Kenntnis,
          callback: function($$v) {
            _vm.djeMifid2Kenntnis = $$v
          },
          expression: "djeMifid2Kenntnis"
        }
      }),
      _c("InputRadioBoxGroup", {
        attrs: {
          title:
            "Möchten Sie bei der Vermögensanlage zusätzliche Nachhaltigkeitspräferenzen berücksichtigen?",
          validateUntouched: "",
          disabled: _vm.isDisabled || !_vm.isZusatzDJEAktiv,
          values: [
            { label: "Nein", value: "0" },
            { label: "Ja", value: "1" }
          ]
        },
        on: {
          input: function($event) {
            return _vm.updateStore(
              "zusatzDJE",
              "djeMifid2ZusaetzlichePref",
              $event
            )
          }
        },
        model: {
          value: _vm.djeMifid2ZusaetzlichePref,
          callback: function($$v) {
            _vm.djeMifid2ZusaetzlichePref = $$v
          },
          expression: "djeMifid2ZusaetzlichePref"
        }
      }),
      _c("InputRadioBoxGroup", {
        attrs: {
          title:
            "Falls ja, DJE Managed Depots ist eine standardisierte Vermögensverwaltung mit einer hohen\n\t\t\t\t\tDiversifikation unter Einhaltung der grundsätzlichen Berücksichtigung von Nachhaltigkeitskriterien der DJE Kapital AG. \n\t\t\t\t\tEine individualisierte Berücksichtigung von Nachhaltigkeitspräferenzen ist im Rahmen des DJE Managed Depots nicht möglich. \n\t\t\t\t\tSind Sie dennoch mit den im Rahmen des DJE Investmentprozesses berücksichtigten Nachhaltigkeitsaspekten einverstanden?",
          validateUntouched: "",
          disabled:
            _vm.isDisabled ||
            !_vm.isZusatzDJEAktiv ||
            _vm.djeMifid2ZusaetzlichePref !== "1",
          values: [
            { label: "Ja", value: "1" },
            { label: "Nein", value: "0" }
          ]
        },
        on: {
          input: function($event) {
            return _vm.updateStore("zusatzDJE", "djeMifid2TrotzdemOk", $event)
          }
        },
        model: {
          value: _vm.djeMifid2TrotzdemOk,
          callback: function($$v) {
            _vm.djeMifid2TrotzdemOk = $$v
          },
          expression: "djeMifid2TrotzdemOk"
        }
      }),
      _c("div", [
        _vm._v(
          "Falls nein, eine individualisierte Berücksichtigung von Nachhaltigkeitspräferenzen bieten wir leider nicht an. Das DJE Managed Depot ist demnach nicht für Sie geeignet."
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }