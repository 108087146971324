import MY_GOALS_TYPES from './types';
import { getInitialState } from './index';
import Vue from 'vue';

export default {
  [MY_GOALS_TYPES.MUTATIONS.GET_GOALS_SUCCESS](state, payload) {
    state.goals = payload.goals;
    state.dataHasChanged = false;
  },
  [MY_GOALS_TYPES.MUTATIONS.UPDATE_STORE](state, payload) {
    if (payload.value) {
      let goal;
      const index = state.goals?.findIndex(goal => goal.id === payload.id);
      if (index >= 0) {
        state.goals = Object.assign([], state.goals)
        state.goals[index] = Object.assign(state.goals[index], payload.value);
      } else if (payload.id === 'new') {
        goal = Object.assign({}, payload.value, {id: 'new'});
        if (!state.goals) {
          state.goals = [];
        }
        state.goals.push(goal);
      }
      state.dataHasChanged = true;
    }
  },
  [MY_GOALS_TYPES.MUTATIONS.ADD_GOAL](state, payload) {
    if (payload?.type) {
        state.goals?.push(payload);
        state.dataHasChanged = true;
    }
  },
  [MY_GOALS_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },
}