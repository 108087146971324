import STOPPUHR_TYPES from './types';

export default {
  [STOPPUHR_TYPES.GETTERS.INIT_STOPPUHR](state) {
    return state.stoppuhr;
  },
  [STOPPUHR_TYPES.GETTERS.IS_STOPPUHR_RUNNING](state) {
    return state.isStoppuhrRunning;
  },  
  [STOPPUHR_TYPES.GETTERS.STOPPUHR_HOUR](state) {
    return state.stoppuhrHour;
  },  
  [STOPPUHR_TYPES.GETTERS.STOPPUHR_MINUTES](state) {
    return state.stoppuhrMinutes;
  },  
  [STOPPUHR_TYPES.GETTERS.STOPPUHR_SECONDS](state) {
    return state.stoppuhSeconds;
  },  
  [STOPPUHR_TYPES.GETTERS.STOPUHR_FORM](state) {
    return state.stoppuhrForm;
  },  
  [STOPPUHR_TYPES.GETTERS.INTERVAL_ID](state) {
    return state.stoppuhrIntervalId;
  },      
  [STOPPUHR_TYPES.GETTERS.CURRENT_INTERVAL](state) {
    return state.currentInterval;
  },  
  [STOPPUHR_TYPES.GETTERS.LIST_INTERVAL](state) {
    return state.listInterval;
  },    
  
  
}