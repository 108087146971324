<template>
  <BaseModal 
    ref="modal"
    modalTitle="andere Personen"
    :confirmDisabled="!selected.length"
    @onConfirmButton="select()"
  >
    <template #modalTitle>andere Personen</template>
    <template #modalHeaderBottom>
      <InputField v-model="filterText" placeholder="Suche" autofocus />
    </template>
    <template #default>
      <GhostLoading v-if="loading" type="table" />
      <NoData v-else-if="!rows.length" noIcon />
      <Table
        v-else
        :headers="headers"
        :rows="rows"
        hidePagination
      >
        <template #select="row">
          <InputToggleSwitch v-model="selectedPersonen[row.id]" :disabled="isAlreadySelected(row.id)" />
        </template>
      </Table>
    </template>
  </BaseModal>
</template>

<script>
import { mapGetters, } from 'vuex';
import CALENDAR_SETTINGS_TYPES from '@/store/calendarSettings/types';

import BaseModal from '@/components/core/BaseModal.vue';
import InputField from '@/components/core/forms/InputField.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import Table from '@/components/table2/Table.vue';
import { SlotColumn, TextColumn, } from '@/components/table2/table_util';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';

export default {
  emits: ['selected'],
  data() {
    return {
      loading: false,
      filterText: null,
      alreadySelected: [],
      selectedPersonen: {},
    };
  },
  computed: {
    ...mapGetters({
      internPersonen: CALENDAR_SETTINGS_TYPES.GETTERS.INTERN_PERSONEN,
    }),
    headers() {
      return {
        lockedLeft: [
          SlotColumn('select', 'wählt'),
          TextColumn('name', 'Name'),
        ],
        center: [],
        lockedRight: [],
      };
    },
    rows() {
      const filterText = this.filterText?.toLowerCase();
      return [ ...this.internPersonen || [], ]
        .filter(person => !filterText || person.name?.toLowerCase().includes(filterText));
    },
    selected() {
      const { selectedPersonen, } = this;
      return Object.keys(selectedPersonen)
        .filter(id => !this.isAlreadySelected(id) && !!selectedPersonen[id])
        .map(id => [ ...this.internPersonen || [], ].find(person => person.id === id))
        .filter(person => !!person?.id)
    },
  },
  methods: {
    open(alreadySelected) {
      this.init(alreadySelected);
      this.$refs.modal?.open();
    },
    close() {
      this.$refs.modal?.close();
    },
    init(alreadySelected) {
      this.filterText = null;
      this.alreadySelected = [ ...alreadySelected, ];
      this.selectedPersonen = alreadySelected.reduce((acc, id) => ({ ...acc, [id]: true, }), {});
      this.find();
    },
    async find() {
      try {
        this.loading = true;
        await this.$store.dispatch(CALENDAR_SETTINGS_TYPES.ACTIONS.FIND_INTERN_PERSONEN);
      } finally {
        this.loading = false;
      }
    },
    isAlreadySelected(id) {
      return this.alreadySelected?.indexOf(id) >= 0;
    },
    select() {
      this.$emit('selected', this.selected);
    },
  },
  components: {
    BaseModal,
    InputField,
    GhostLoading,
    NoData,
    Table,
    InputToggleSwitch,
  },
}
</script>
