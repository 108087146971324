var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "workspaces-logo__container" }, [
    _c(
      "a",
      {
        class: { clickable: _vm.canOpenCurrentUserInNewTab },
        on: {
          click: function($event) {
            return _vm.openCurrentUserInNewTab()
          }
        }
      },
      [
        _vm.logo
          ? _c("img", {
              staticClass: "container__img",
              attrs: { src: _vm.logo }
            })
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }