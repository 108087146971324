<template>
  <div class="grid" v-if="inputs" style="width: 100%">
    <template v-for="input in inputs">
      <label v-if="(!input.visible || evaluateUploadExpression(value, input.visible)) && (!input.ignore || !evaluateUploadExpression(value, input.ignore))"
            :class="{'invalid': input.valid && !evaluateUploadExpression(value, input.valid)}" :key="input.key ? input.key : input.label">
        
        <div v-if="input.type=='LABEL'" class="label-item" > 
          <span :class="fixedColor(input.labelFixedColor)" >{{input.label}}</span>
        </div>
        <InputCheckBoxItem v-if="input.type=='BOOLEAN'" :value="value[input.key]" :disabled="isDisabled(value, input)" @input="emitChange(input.key, $event)" :label="input.label" style="width: 100%"/>
        <InputToggleSwitch v-if="input.type=='SWITCHER'" :value="value[input.key]" :disabled="isDisabled(value, input)" @input="emitChange(input.key, $event)" :label="input.label" style="width: 100%"/>
        <InputField v-if="input.type=='STRING'" :value="value[input.key]" :disabled="isDisabled(value, input)" @change="emitChange(input.key, $event)" :label="input.label" style="width: 100%"/>
        <InputField v-if="input.type=='INTEGER'" type="number" :value="value[input.key]" :disabled="isDisabled(value, input)" @change="emitInteger(input.key, $event)" :label="input.label" style="width: 100%"/>
        <DatePickerField v-if="input.type=='DATE'" :value="value[input.key]" isValueAsString :disabled="isDisabled(value, input)" @change="emitChange(input.key, $event)" :label="input.label" style="width: 100%"/>
        <ComboBox v-if="input.type=='COMBOBOX'" :sortComboboxValues="false"  :value="value[input.key]" :renderDanger="input.valid && !evaluateUploadExpression(value, input.valid)"
          :disabled="isDisabled(value, input)" @change="emitChange(input.key, {event: $event, component: input})" :values="input.options" :label="input.label" style="width: 100%" :customizable="true" />
        <InputRadioBoxGroup v-if="input.type=='RADIO'" :value="value[input.key]" :disabled="isDisabled(value, input)" @input="emitChange(input.key, $event)" :values="input.options" :title="input.label" style="width: 100%"/>
        <InputTextArea v-if="input.type=='TEXT'" :value="value[input.key]" :disabled="isDisabled(value, input)" @input="emitChange(input.key, $event)" :label="input.label" style="width: 100%"/>
      </label>
    </template>
  </div>
</template>
<script>
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputField from '@/components/core/forms/InputField.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import expressionEvaluator from '@/mixins/expression/mixin.js';
import InputCheckBoxItem from '@/components/core/forms/checkbox/InputCheckBoxItem.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';

export default {
  mixins: [expressionEvaluator],
  components: {
    DatePickerField,
    ComboBox,
    InputField,
    InputRadioBoxGroup,
    InputCheckBoxItem,
    InputTextArea,
    InputToggleSwitch,
  },
    props: {
    value: {
      required: true,
      type: Object,
    },
    inputs: {
      required: true,
      type: Array,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  data () {
    return {
    }
  },
  methods: {
    fixedColor(labelFixedColor) {
      switch(labelFixedColor) {
        case 'SUCCESS':
          return 'color-success';
        case 'INFO':
          return 'color-info';
        case 'WARNING':
          return 'color-warning';
        case 'DANGER':
          return 'color-danger';
      }

      return 'color-text';
    },
    evaluateUploadExpression(whatValue, expression) {
      return this.evaluateExpression(whatValue, expression);
    },
    emitInteger(key, value) {
      this.$emit("input", {
        ...this.value,
        [key]: parseInt(value) || 0,
      });
    },
    emitChange(key, value) {
      let valueToEmit = {}
      if(key === 'INPUT_KATEGORIE') {
        // if there is style informed means it is blue item kategorie
        const istWeiterleitungMoeglich = !!value.component.options.find(option => option.value === value.event)?.style;
        valueToEmit = {
          ...this.value,
          [key]: value.event,
          istWeiterleitungMoeglich
        };
      } else {
        valueToEmit = {
          ...this.value,
          [key]: value,
        };
      }

      this.setCalculatedValues(valueToEmit, key);
      this.$emit("input", valueToEmit);
      
    },
    setCalculatedValues(inputData, key) {
        this.inputs.forEach(whatInput => {
          if (whatInput.updateTriggers) {
            whatInput.updateTriggers.forEach(expr => {
              if (expr.key === key && this.evaluateUploadExpression(inputData, expr.expression)) {
                inputData[whatInput.key] = expr.value;
              }
            })
          }
        });
    },
    isDisabled(value, input) {
      return this.disabled || (input.disabled && this.evaluateUploadExpression(value, input.disabled))
    }
  },
}
</script>
<style scoped>
.invalid {
  color: var(--color-danger);
}
label {
  display: block;
  margin-top: 8px;
}

.label-item {
  margin-top: 8px;
   margin-bottom: 8px;
}

</style>
