<template>
  <div v-if="customerDataEdited">
    <Table
      v-if="rows.length"
      ref="table"
      :tableId="tableId"
      cardView
      :headers="headers"
      :rows="rows"
      rowId="id"
      hidePagination
      @onNavigate="navigateToCard"
      :headersSize="isCustomerOnly ? 0 : 5"
      showWeiterLink
    >
    </Table>
    <NoData v-else noIcon />
  </div>
</template>


<script>
import BoxContainer from "@/components/core/BoxContainer.vue";
import { PhPencilLine, PhBookOpen } from "phosphor-vue";
import { TextColumn, CurrencyColumn } from "@/components/table2/table_util.js";
import Table from "@/components/table2/Table.vue";
import { mapGetters } from "vuex";
import CUSTOMERDATA_TYPES from "@/store/customerData/types";
import CORE_TYPES from "@/store/core/types";
import persoenlicheDatenMixin from "../persoenliche-daten-mixin";
import stammdatenMixin from "@/components/persoenliche-daten/stammdaten/stammdaten-mixin";
import NoData from '@/components/core/NoData.vue';

export default {
  components: {
    BoxContainer,
    PhPencilLine,
    PhBookOpen,
    Table,
    NoData,
  },
  mixins: [persoenlicheDatenMixin, stammdatenMixin],
  data: function () {
    return {
      title: "Adresse",
      personalDataAddress: {
        anredePronomen: "",
        title: "",
        firstName: "",
        lastName: "",
        street: "",
        zip: "",
        city: "",
        country: "",
        personalStatus: "",
        co: "",
        state: "",
        firmenzusatz: "",
        briefanredeDepot: "",
        briefanredePerson: "",
        personDeceased: false,
        dateOfDeath: null,
        interneBemerkung: "",
        category: "",
        interested: "",
        categoryAutoLock: "",
        automaticUpdated: true,
        dateCreated: null,
        synchronisation: false,
        risikolevel: "",
        isRisikolevelManuel: false,
        hintRisikolevel: "",
      },
      relationship: {
        relation: null,
        isLegalAgent: false,
        isExtraDepotOwner: false,
        isMandatory: false,
      },
      kundenReferenzNummer: "",
    };
  },
  computed: {
    ...mapGetters({
      customerDataEdited: CUSTOMERDATA_TYPES.GETTERS.GET_CUSTOMER_DATA_EDITED,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      isFK: CORE_TYPES.GETTERS.IS_FK,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      personId: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_ID,
      customerDataConfig: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_CONFIG,
      isCustomerOnly: CORE_TYPES.GETTERS.IS_CUSTOMER_ONLY,
    }),
    headers() {
      const result = {
        center: [],
      };

      result.center.push(TextColumn("zip", "Postleitzahl"));
      result.center.push(TextColumn("city", "Ort"));
      result.center.push(TextColumn("street", "Straße"));
      result.center.push(TextColumn("country", "Land"));
      result.center.push(TextColumn("state", "Bundesland"));

      return result;
    },
    rows() {
      const rows = [];

      const row = {};

      row.zip = this.personalDataAddress?.zip || "-";
      row.city = this.personalDataAddress?.city || "-";
      row.street = this.personalDataAddress?.street || "-";
      row.country = this.personalDataAddress?.country || "-";
      row.state =
        this.stateComboValues?.find(
          (cb) => cb.value === this.personalDataAddress?.state
        )?.label || "-";

      row.automaticUpdated = this.personalDataAddress?.automaticUpdated
        ? "Ja"
        : "Nein";

      if (this.isIntern && this.showRisikolevel) {
        row.isRisikolevelManuel = this.personalDataAddress?.isRisikolevelManuel
          ? "Ja"
          : "Nein";
      }

      if (this.showRisikolevel) {
        row.risikolevel = this.personalDataAddress?.risikolevel;
      }

      if (this.isByPass) {
        row.anredePronomen = this.friendlyAnredePronomen;
      }

      row.title = this.personalDataAddress?.title || "-";

      if (this.hasFirstName) {
        row.firstName = this.personalDataAddress?.firstName || "-";
      }

      row.lastName = this.personalDataAddress?.lastName || "-";

      row.co = this.personalDataAddress?.co || "-";

      row.personalStatus = this.personalDataAddress?.personalStatus || "-";

      row.firmenzusatz = this.personalDataAddress?.firmenzusatz || "-";

      row.kundenReferenzNummer = this.kundenReferenzNummer || "-";

      if (this.isByPass) {
        if (this.isMainPerson) {
          row.briefanredeDepot =
            this.personalDataAddress?.briefanredeDepot || "-";
        }

        row.briefanredePerson = this.personalDataAddress?.briefanredePerson;

        row.personDeceased = this.personalDataAddress?.personDeceased
          ? "Ja"
          : "Nein";

        if (this.isMainPerson) {
          row.category = this.personalDataAddress?.category || "-";

          if (this.personalDataAddress?.dateCreated) {
            row.dateCreated = this.personalDataAddress?.dateCreated;
          }

          row.interested =
            this.personalDataAddress?.interested === "1" ? "Ja" : "Nein";
          row.categoryAutoLock =
            this.personalDataAddress?.categoryAutoLock === "1" ? "Ja" : "Nein";

          row.betreuer =
            this.customerDataConfig?.customerBetreuer?.find(
              (cb) => cb.value === this.personalDataAddress?.betreuer
            )?.label || "Kein Betreuer";

          row.interneBemerkung = this.personalDataAddress?.interneBemerkung;
        }
      }

      rows.push(row);
      return rows;
    },
    tableId() {
      const uniqueUUID = "fb82c311-91bb-40fa-b5c6-2afb40bef89c";
      return uniqueUUID;
    },
    hasFirstName() {
      return !!this.customerData?.personalDataAddress?.firstName;
    },
  },
  methods: {
    navigateToCard(row) {
      this.$router.push(
        `/persoenlichedaten/customer-data/steps/stammdaten?substep=adresse`
      );
    },
    currency(value) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €";
    },
    // updateGroupIntoData(groupName) {
    //     Object.keys(this[groupName]).forEach(fieldName => {
    //         if (this.customerDataEdited && this.customerDataEdited[groupName] && fieldName in this.customerDataEdited[groupName]) {
    //         this[groupName][fieldName] = this.customerDataEdited[groupName][fieldName]
    //         } else if (this.customerData && this.customerData[groupName] && fieldName in this.customerData[groupName]) {
    //         this[groupName][fieldName] = this.customerData[groupName][fieldName]
    //         }
    //     })
    // },
    updateGroupsIntoData() {
      this.updateGroupIntoData("personalDataAddress");
      this.updateGroupIntoData("relationship");
      this.updateFieldIntoData("kundenReferenzNummer");
    },
  },
  mounted() {
    this.updateGroupsIntoData();
  },
  validators: {},
};
</script>

<style scoped>
.align-end {
  flex: 1 1 auto;
  align-self: flex-end;
  padding: 0px 0px 16px 5px;
  color: var(--color-text);
}
.form-control {
  display: flex;
  align-content: stretch;
  align-items: center;
}
</style>