var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { actions: _vm.headerActions, title: "Homepage bearbeiten" },
        on: { "action-EDIT_SELECTED": _vm.editSelected }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("InputRadioBoxGroup", {
            attrs: {
              title: "Bitte wählen Sie ein Template aus:",
              values: _vm.inputRadioValues,
              disabled: !_vm.loaded
            },
            model: {
              value: _vm.dataSelected,
              callback: function($$v) {
                _vm.dataSelected = $$v
              },
              expression: "dataSelected"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }