var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BoxContainer",
        [
          _c("Table", {
            attrs: {
              title: _vm.selectedTabLabel,
              headers: _vm.headers,
              rows: _vm.rows,
              headerActions: _vm.headerActions,
              selected: _vm.isBrokerOrBypass ? _vm.selected : undefined,
              rowId: "value",
              hidePagination: ""
            },
            on: {
              selected: function($event) {
                _vm.selected = $event
              },
              "headerAction-SEND_MAIL": _vm.sendEmeil
            },
            scopedSlots: _vm._u([
              {
                key: "Bezeichnung",
                fn: function(row) {
                  return [
                    row.isPDF
                      ? _c("DownloadLink", {
                          attrs: {
                            href: row.value,
                            title: row.Bezeichnung,
                            filename: row.Bezeichnung + ".pdf"
                          }
                        })
                      : _c(
                          "a",
                          { attrs: { href: row.value, target: "_blank" } },
                          [_vm._v(_vm._s(row.Bezeichnung))]
                        )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }