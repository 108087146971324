<template>
  <div v-if="list && list.length" class="mb-16px">
    <div class="font-bold">{{ title }}</div>
    <ul class="list-bordered">
      <li class="list-bordered-item" v-for="fixedItem in list" :key="fixedItem.key">
        <div class="d-flex justify-content-between">
          <div>
            <span>{{fixedItem.label}}</span>
          </div>
          <div>
            <ph-lock 
              weight="bold" 
              :size="16" />
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { PhLock } from 'phosphor-vue'

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    PhLock,
  },
}
</script>
