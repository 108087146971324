<template>
  <div>
    <DynamicDocumentNavigation
      :document="selectedDocument" 
      :versionValues="versionValues"
      :documentHasChanged="documentHasChanged"
      :isDocumentLoading="loading"
    />

    <StepperForm
      stepType="tab"
      stepperName="ansprechpartner"
      :stepperMediator="tabsStepperMediator"
      :selectedStepKey="$route.params && $route.params.step"
      :hasFertig="false"
      :isDynamicDocument="true"
      @set-step="setStepByKey($event)"
      @addStep="addStep()"
      @removeStep="openRemoveStepModal($event)"
      @copyStep="copyStep($event)"
      @orderChanged="orderChanged($event)"
    >
      <template #contentTemplate>
        <div class="row my-0">
          <div class="col-12">
            <DynamicDocumentComponentList 
              v-if="components"
              :documentComponents="components"
              :document="selectedDocument"/>
          </div>
        </div>
      </template>
    </StepperForm>
    <BaseModal ref="beforeLeaveModal"  labelButtonCancel="Zurück" labelButtonConfirm="Ok" @onConfirmButton="confirmBeforeLeaveModal">
      <template v-slot:modalTitle>
        Es wurden Änderungen getätigt
      </template>
        <p>Wenn Sie die Seite verlassen gehen ungespeicherte Änderungen verloren.</p>
        <p>Sind Sie sicher?</p>
    </BaseModal>
    <BaseModal ref="deletePageModal"  labelButtonCancel="Zurück" labelButtonConfirm="Ja" @onConfirmButton="removeStep">
      <template v-slot:modalTitle>
        Seite löschen
      </template>
        <p>Möchten Sie diese Seite löschen?</p>
    </BaseModal>
  </div>
</template>

<script>
import DYNAMIC_DOCUMENT_TYPES from "@/store/dynamicDocument/types";
import { mapGetters } from 'vuex';
import DynamicDocumentComponentList from '@/components/dynamicDocument/DynamicDocumentComponentList.vue'; 
import DynamicDocumentNavigation from '@/components/dynamicDocument/DynamicDocumentNavigation.vue';
import { StepperMediator } from '@/components/stepper/utils';
import StepperForm from '@/components/stepper/StepperForm.vue';
import BaseModal from '@/components/core/BaseModal.vue';

export default {
  mixins: [],
  components: {
    DynamicDocumentComponentList,
    DynamicDocumentNavigation,
    StepperForm,
    BaseModal,
  },
  data() {
    return {
      stepperCollapsed: false,
      destination: null,
      versionValues: [],
      pageToBeDeleted: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      selectedDocument: DYNAMIC_DOCUMENT_TYPES.GETTERS.SELECTED_DYNAMIC_DOCUMENT,
      documentHasChanged: DYNAMIC_DOCUMENT_TYPES.GETTERS.GET_DOCUMENT_HAS_CHANGED,
    }),
    steps() {
      const result = this.selectedDocument && this.selectedDocument.steps && this.selectedDocument.steps.map(step => {
        return {...step}
      });
      return result || []
    },
    tabsStepperMediator() {
      return new StepperMediator(this.steps);
    },
    components() {
      return this.selectedDocument && this.selectedDocument.componentMap
    },
  },
  mounted() {
    this.loadDocument()
  },
  methods: {
    async loadDocument() {
      const version = this.$route.params.version
      const id = this.$route.params.documentId

      if (!version || !id) {
        return
      }

      this.loading = true
      const payload = {
        version,
        id,
      }
      await this.$store.dispatch(DYNAMIC_DOCUMENT_TYPES.ACTIONS.RETRIEVE_SELECTIONS)
      await this.$store.dispatch(DYNAMIC_DOCUMENT_TYPES.ACTIONS.RETRIEVE_DYNAMIC_DOCUMENT, payload)
      this.loading = false
    },
    setStepByKey(stepKey) {
        if (stepKey && this.$route.params && this.$route.params.step !== stepKey) {
          this.$router.push({path: `/intern/maklerauftrag/${this.$route.params.formId}/${this.$route.params.documentId}/${this.$route.params.version}/` + stepKey, query: this.$route.query});
        }
    },
    confirmBeforeLeaveModal() {
      if (this.destination) {
        this.$store.commit(DYNAMIC_DOCUMENT_TYPES.MUTATIONS.SET_DOCUMENT_HAS_CHANGED, false)
        this.$router.push({path: this.destination.path, query: this.destination.query})
      }
    },
    orderChanged(event) {
      let newComponentMap = {}
      let newSteps = []
      event.forEach((step, index) => {
        newSteps.push({
          label: 'Seite ' + (index + 1),
          stepKey: (index + 1).toString()
        })
        if (step.stepKey === this.$route.params.step) {
          this.setStepByKey(index + 1)
        }
        newComponentMap[index + 1] = this.selectedDocument.componentMap[step.stepKey]
      })

      this.selectedDocument.componentMap = newComponentMap

      // we need to trick the sorted list or it won't update
      this.selectedDocument.steps = []
      this.$nextTick(() => {
        this.selectedDocument.steps = newSteps
      });
            
      this.$store.commit(DYNAMIC_DOCUMENT_TYPES.MUTATIONS.SET_DOCUMENT_HAS_CHANGED, true)
    },
    copyStep(stepToBeCopied) {
      let newStepKey = this.selectedDocument?.steps?.length + 1 || 1
      this.selectedDocument.steps.push({stepKey: newStepKey.toString(), label: 'Seite ' + newStepKey.toString()})

      let counter = 1
      let newComponentMap = {}
      this.selectedDocument.steps.forEach(step => {
        if (stepToBeCopied.stepKey !== step.stepKey) {
          newComponentMap[step.stepKey] = this.selectedDocument.componentMap[counter.toString()]
          counter++
        } else {
          newComponentMap[step.stepKey] = JSON.parse(JSON.stringify(this.selectedDocument.componentMap[counter.toString()]))
        }
      })

      this.selectedDocument.componentMap = newComponentMap
      this.$store.commit(DYNAMIC_DOCUMENT_TYPES.MUTATIONS.SET_DOCUMENT_HAS_CHANGED, true)
    },
    addStep(){
      let newStepKey = this.selectedDocument?.steps?.length + 1 || 1
      this.selectedDocument.steps.push({stepKey: newStepKey.toString(), label: 'Seite ' + newStepKey.toString()})
      this.selectedDocument.componentMap[newStepKey.toString()] = []
      this.$store.dispatch(DYNAMIC_DOCUMENT_TYPES.ACTIONS.RELOAD_DYNAMIC_DOCUMENT);
      this.$store.commit(DYNAMIC_DOCUMENT_TYPES.MUTATIONS.SET_DOCUMENT_HAS_CHANGED, true)
    },
    openRemoveStepModal(event) {
      this.pageToBeDeleted = event;
      this.$refs.deletePageModal.open()
    },
    removeStep() {
      let delStep = this.pageToBeDeleted
      if (this.selectedDocument && this.selectedDocument.steps && delStep) {
        let newSteps = []
        this.selectedDocument.steps.filter(x => x.stepKey !== delStep.stepKey).forEach((step, index) => {
          newSteps.push({
            label: 'Seite ' + (index + 1),
            stepKey: (index + 1).toString()
          })
        })
        this.selectedDocument.steps = newSteps

        let newComponentMap = {}
        let counter = 1
        Object.entries(this.selectedDocument.componentMap).forEach(([key, value]) => {
          if (key !== delStep.stepKey) {
            newComponentMap[counter++] = value
          }
        })
        this.selectedDocument.componentMap = newComponentMap
        
        this.$store.dispatch(DYNAMIC_DOCUMENT_TYPES.ACTIONS.RELOAD_DYNAMIC_DOCUMENT);
        this.$store.commit(DYNAMIC_DOCUMENT_TYPES.MUTATIONS.SET_DOCUMENT_HAS_CHANGED, true)
        this.pageToBeDeleted = null
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.documentHasChanged) {
      this.$refs.beforeLeaveModal.open()
      this.destination = to
    } else {
      next()
    }
    
  },
}
</script>

<style scoped>

</style>