<template>
  <div class="customer-search__result-icon">
    <ph-chats 
      :size="16" 
      :class="emailClasses" 
      v-fc-tooltip="emailLabel" 
      :weight="emailLabel ? 'fill' : 'regular'"/>
    <ph-phone 
      :size="16" 
      :class="telefonClasses" 
      v-fc-tooltip="telefonLabel"
      :weight="telefonLabel ? 'fill' : 'regular'"/>
    <ph-file-text 
      :size="16" 
      :class="faxClasses" 
      v-fc-tooltip="faxLabel"
      :weight="faxLabel ? 'fill' : 'regular'"/>
    <ph-envelope 
      :size="16" 
      :class="postClasses" 
      v-fc-tooltip="postLabel"
      :weight="postLabel ? 'fill' : 'regular'"/>
  </div>
</template>

<script>
import { PhChats, PhPhone, PhFileText, PhEnvelope } from 'phosphor-vue'

export default {
  props: {
    kundenansprache: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    emailClasses() {
      return this.getClassByKey('email')
    },
    postClasses() {
      return this.getClassByKey('post')
    },
    telefonClasses() {
      return this.getClassByKey('telefon')
    },
    faxClasses() {
      return this.getClassByKey('fax')
    },

    emailLabel() {
      return this.getLabelByKey('email')
    },
    postLabel() {
      return this.getLabelByKey('post')
    },
    telefonLabel() {
      return this.getLabelByKey('telefon')
    },
    faxLabel() {
      return this.getLabelByKey('fax')
    },

  },
  methods: {
    hasKey(key) {
      return this.kundenansprache?.some(item => item.key === key)
    },
    getClassByKey(key) {
      return [this.hasKey(key)
        ? 'customer-search__result-icon--active' 
        : 'customer-search__result-icon--inactive']
    },
    getLabelByKey(key) {
      const found = this.kundenansprache?.find(item => item.key === key)
      return found ? found.value : ''
    },
  },
  components: {
    PhChats, 
    PhPhone, 
    PhFileText, 
    PhEnvelope 
  }
}
</script>

<style scoped>

</style>