var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Einstellungen",
          subtitle: "Einstellungen Anlegerprofil",
          actions: _vm.headerActions
        },
        on: {
          "action-DISCARD": function($event) {
            return _vm.discardChanges()
          },
          "action-DELETE": function($event) {
            return _vm.deleteRisikoklassenDefinition()
          }
        }
      }),
      _vm.isSmallOrMediumScreen
        ? _c("ConfigStepper3", {
            ref: "stepper",
            attrs: {
              configStep: _vm.configStep,
              selectedStep: _vm.selectedStep,
              configData: _vm.configData
            },
            on: { setStep: _vm.setSelectedStep }
          })
        : _c("ContentWithStepper", {
            attrs: { stepperCollapsed: _vm.stepperCollapsed },
            scopedSlots: _vm._u([
              {
                key: "stepper",
                fn: function() {
                  return [
                    _c("ConfigVerticalStep", {
                      ref: "stepper",
                      attrs: {
                        configStep: _vm.configStep,
                        selectedStep: _vm.selectedStep,
                        configData: _vm.configData
                      },
                      on: {
                        collapsed: function($event) {
                          _vm.stepperCollapsed = $event
                        },
                        setStep: _vm.setSelectedStep
                      }
                    })
                  ]
                },
                proxy: true
              },
              {
                key: "content",
                fn: function() {
                  return [
                    _c(
                      "div",
                      [
                        _vm.selectedStep === "stammdaten"
                          ? _c("Stammdaten")
                          : _vm._e(),
                        _vm._l(_vm.RISIKOSTUFE_TOTAL, function(index) {
                          return [
                            _vm.selectedStep === "risikostufe_" + index
                              ? _c("Risikostufe", {
                                  key: index,
                                  attrs: { index: index }
                                })
                              : _vm._e()
                          ]
                        })
                      ],
                      2
                    )
                  ]
                },
                proxy: true
              }
            ])
          }),
      _c("BaseModal", {
        ref: "editModal",
        attrs: {
          modalTitle: _vm.editModalTitle,
          showDefaultButtons: false,
          actions: _vm.modalActions
        },
        on: {
          "action-DISCARD": function($event) {
            return _vm.discardChanges()
          },
          close: function($event) {
            return _vm.saveChanges()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "div",
                  [
                    _vm.selectedStep === "stammdaten"
                      ? _c("Stammdaten")
                      : _vm._e(),
                    _vm._l(_vm.RISIKOSTUFE_TOTAL, function(index) {
                      return [
                        _vm.selectedStep === "risikostufe_" + index
                          ? _c("Risikostufe", {
                              key: index,
                              attrs: { index: index }
                            })
                          : _vm._e()
                      ]
                    })
                  ],
                  2
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }