import DOMAIN_FC_TYPES from './types';
import CORE_TYPES from '../core/types';
import axios from 'axios';

const config = {
  defaultSpinner: true
};

export default {

  async [DOMAIN_FC_TYPES.ACTIONS.GET_DOMAINS_FC]({ getters, commit}) {

    const endpoint = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/domainsFC/domainsFC`
    const response = await axios.get(endpoint, config);
    if (response.data) {
      commit(DOMAIN_FC_TYPES.MUTATIONS.GET_DOMAINS_FC_SUCCESS, { domainsFC: response.data });
    }
  },

  async [DOMAIN_FC_TYPES.ACTIONS.DELETE_DOMAIN_FC]({ getters, dispatch }, domainUrlID) {
    if (!domainUrlID) {
      return;
    }

    const endpoint = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/domainsFC/domainsFC?domainUrlID=${domainUrlID}`
    await axios.delete(endpoint, config);
    dispatch(DOMAIN_FC_TYPES.ACTIONS.GET_DOMAINS_FC)
  },

  async [DOMAIN_FC_TYPES.ACTIONS.SAVE_DOMAIN_FC]({ getters, dispatch }, domainForm) {
    if (!domainForm?.domainUrl) {
      return;
    }

    const endpoint = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/domainsFC/domainsFC`
    const response = await axios.post(endpoint, domainForm, config);

    if (response.data) {
      dispatch(DOMAIN_FC_TYPES.ACTIONS.GET_DOMAINS_FC)
    }
  }

}