var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: {
        modalTitle: "Versicherungen vereinigen",
        labelButtonConfirm: "Vereinigen",
        labelButtonCancel: "Abbrechen",
        confirmDisabled:
          !_vm.uniteInsurance1 ||
          !_vm.uniteInsurance2 ||
          _vm.uniteInsurance1 == _vm.uniteInsurance2
      },
      on: { onConfirmButton: _vm.uniteInsurances, close: _vm.close },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "BaseButton",
                {
                  attrs: { isSecondary: "" },
                  on: {
                    click: function($event) {
                      return _vm.switchContracts()
                    }
                  }
                },
                [_vm._v(" Verträge wechseln ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        [
          _vm._v(" Hier können Sie zwei Versicherungen vereinigen. "),
          _c("br"),
          _c("span", { staticClass: "color-danger" }, [
            _vm._v(" ACHTUNG!"),
            _c("br"),
            _vm._v(
              " Vereinigte Verträge können im Nachgang nicht mehr „getrennt“ werden."
            ),
            _c("br"),
            _vm._v(
              " Sind Sie sich sicher, dass Sie die Verträge vereinigen möchten? "
            )
          ]),
          _c("InputField", {
            attrs: {
              label: "Versicherung 1, der die Daten zugeordnet werden sollen"
            },
            on: {
              change: function($event) {
                return _vm.loadInsuranceDescription(1)
              }
            },
            model: {
              value: _vm.uniteInsurance1,
              callback: function($$v) {
                _vm.uniteInsurance1 = $$v
              },
              expression: "uniteInsurance1"
            }
          }),
          _vm._v(" " + _vm._s(_vm.insuranceDescription1) + " "),
          _c("hr"),
          _c("InputField", {
            attrs: { label: "Versicherung 2, die gelöscht werden soll" },
            on: {
              change: function($event) {
                return _vm.loadInsuranceDescription(2)
              }
            },
            model: {
              value: _vm.uniteInsurance2,
              callback: function($$v) {
                _vm.uniteInsurance2 = $$v
              },
              expression: "uniteInsurance2"
            }
          }),
          _vm._v(" " + _vm._s(_vm.insuranceDescription2) + " ")
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }