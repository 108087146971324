<template>
  <div>
    <hr v-if="hasNews">
    <EditSourceNewsFeed/> 
    <div class="news_header" v-if="hasNews">Aktueller Marktbericht</div>
    <div class="news-content" v-if="hasNews">
      <div class="news-row" v-for="(news,idx) in getNews" :key="idx">
         <div class="news_date">{{getDate(news.pubDate)}}</div>
        <div class="news_title">
          <a :href="news.link" target="_blank">{{news.title}}</a>
        </div>
        <div class="news_desc">{{news.desc}} [...]</div>
        <hr>
      </div>
    </div>
  </div>
</template>
<script>
import CORE_TYPES from "@/store/cms/types";
import CMS_TYPES from "@/store/cms/types";
import { mapGetters, dispatch } from "vuex";
import dayjs from "dayjs";
import EditSourceNewsFeed from '@/components/cms/EditSourceNewsFeed.vue'

export default {
  components: {
    EditSourceNewsFeed
  },
  computed: {
    ...mapGetters({
      getNews: CMS_TYPES.GETTERS.GET_CMS_NEWS
    }),
    hasNews() {
      return this.getNews && this.getNews.length
    }
  },
  methods: {
    getDate(date) {
      return dayjs(date).format('DD.MM.YY HH:mm:ss');
      // return date.substring(0, date.length - 6);
    }
  },
  mounted() {
    this.$store.dispatch(CMS_TYPES.ACTIONS.RETRIEVE_CMS_NEWS);
  }
};
</script>
<style  scoped>
a {
  text-decoration: none;
  color: var(--color-workspaces-nav-active);
}
.news_header {
  font-size: 26px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  text-align: left;
}
.news_title {
  text-align: left;
}
.news-row {
  /* padding: 1em; */
}
.news_date {
  font-size: 0.8em;
  text-align: left;
}
.news_desc {
  font-size: 1em;
  text-align: left;
}
</style>