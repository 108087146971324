var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-forms__container" }, [
    _c(
      "div",
      { staticClass: "input-forms__container" },
      [
        !_vm.config.aenderung_alt
          ? _c("ComboBox", {
              attrs: {
                label: "Bereits vorhandende Depots",
                values: _vm.vorhandendeDepots
              },
              model: {
                value: _vm.antragData.INPUT_VORHANDENE_KONTEN,
                callback: function($$v) {
                  _vm.$set(_vm.antragData, "INPUT_VORHANDENE_KONTEN", $$v)
                },
                expression: "antragData.INPUT_VORHANDENE_KONTEN"
              }
            })
          : _vm._e()
      ],
      1
    ),
    _c("div", { staticClass: "input-forms__label-container" }, [
      _c("div", { staticClass: "input-forms__label-container" }, [
        _c("div", { staticClass: "row mt-0" }, [
          _c("div", { staticClass: "col" }, [
            _vm.label
              ? _c(
                  "div",
                  {
                    staticClass:
                      "\n              input-forms__label-content input-forms__label-content--bigger\n            "
                  },
                  [
                    _c("ph-bank", { attrs: { size: 16 } }),
                    _vm._v(" " + _vm._s(_vm.label) + " ")
                  ],
                  1
                )
              : _vm._e()
          ]),
          _vm.config && _vm.config.buttonText
            ? _c(
                "div",
                { staticClass: "col-auto" },
                [
                  _c("BaseButton", { on: { click: _vm.openFormsFinder } }, [
                    _vm._v(_vm._s(_vm.config.buttonText))
                  ])
                ],
                1
              )
            : _vm._e()
        ])
      ])
    ]),
    _c(
      "div",
      { staticClass: "input-forms__input-container" },
      [
        _vm.tableData && _vm.tableData.records && _vm.tableData.records.length
          ? _c("Table", {
              attrs: {
                tableData: _vm.tableData,
                cardViewEnabled: false,
                filterEnabled: false,
                exportEnabled: false,
                paginationEnabled: false,
                actions: _vm.columnActions,
                rowsPerPage: _vm.tableData.records.length
              },
              on: {
                "execute-action": function($event) {
                  return _vm.handleTableAction($event)
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "betragAlt",
                    fn: function(props) {
                      return [
                        props.data.row
                          ? _c("InputField", {
                              attrs: { type: "currency" },
                              on: {
                                input: function($event) {
                                  return _vm.onChangeInputField(
                                    $event,
                                    props.data.row,
                                    "betragAlt"
                                  )
                                }
                              },
                              model: {
                                value: props.data.row.betragAlt,
                                callback: function($$v) {
                                  _vm.$set(props.data.row, "betragAlt", $$v)
                                },
                                expression: "props.data.row.betragAlt"
                              }
                            })
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "betrag",
                    fn: function(props) {
                      return [
                        props.data.row
                          ? _c("InputField", {
                              attrs: { type: "currency" },
                              on: {
                                input: function($event) {
                                  return _vm.onChangeInputField(
                                    $event,
                                    props.data.row,
                                    "betrag"
                                  )
                                }
                              },
                              model: {
                                value: props.data.row.betrag,
                                callback: function($$v) {
                                  _vm.$set(props.data.row, "betrag", $$v)
                                },
                                expression: "props.data.row.betrag"
                              }
                            })
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "frequenz",
                    fn: function(props) {
                      return [
                        _c("ComboBox", {
                          attrs: {
                            values: _vm.comboOptions,
                            value: props.data.row.frequenz
                          },
                          on: {
                            input: function($event) {
                              return _vm.onChangeInputField(
                                $event,
                                props.data.row,
                                "frequenz"
                              )
                            }
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "abbuchungsKonto",
                    fn: function(props) {
                      return [
                        props.data.row
                          ? _c("InputRadioBoxGroup", {
                              attrs: {
                                type: "text",
                                values: _vm.abbuchungsKontoValues
                              },
                              on: {
                                input: function($event) {
                                  return _vm.onChangeInputField(
                                    $event,
                                    props.data.row,
                                    "abbuchungsKonto"
                                  )
                                }
                              },
                              model: {
                                value: props.data.row.abbuchungsKonto,
                                callback: function($$v) {
                                  _vm.$set(
                                    props.data.row,
                                    "abbuchungsKonto",
                                    $$v
                                  )
                                },
                                expression: "props.data.row.abbuchungsKonto"
                              }
                            })
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "AA",
                    fn: function(props) {
                      return [
                        _c("InputField", {
                          attrs: {
                            type: "number",
                            precision: 4,
                            value: props.data.row.AA,
                            id: "AA" + props.data.row.index
                          },
                          on: {
                            input: function($event) {
                              return _vm.onChangeInputField(
                                $event,
                                props.data.row,
                                "AA"
                              )
                            }
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "restlaufzeit",
                    fn: function(props) {
                      return [
                        _c("InputField", {
                          attrs: {
                            value: props.data.row.restlaufzeit,
                            id: "restlaufzeit" + props.data.row.index
                          },
                          on: {
                            input: function($event) {
                              return _vm.onChangeInputField(
                                $event,
                                props.data.row,
                                "restlaufzeit"
                              )
                            }
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "dateAb",
                    fn: function(props) {
                      return [
                        _c("DatePickerField", {
                          attrs: { value: props.data.row.dateAb },
                          on: {
                            input: function($event) {
                              return _vm.onChangeInputField(
                                $event,
                                props.data.row,
                                "dateAb"
                              )
                            }
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "dateBis",
                    fn: function(props) {
                      return [
                        _c("DatePickerField", {
                          attrs: { value: props.data.row.dateBis },
                          on: {
                            input: function($event) {
                              return _vm.onChangeInputField(
                                $event,
                                props.data.row,
                                "dateBis"
                              )
                            }
                          }
                        })
                      ]
                    }
                  }
                ],
                null,
                false,
                2418965595
              )
            })
          : _vm._e(),
        _vm.positions &&
        _vm.positions.length &&
        _vm.config &&
        _vm.config.positionFields
          ? _c("WertpapierAuswahlPositionFields", {
              attrs: {
                config: _vm.config,
                categoryId: _vm.categoryId,
                antragId: _vm.antragId
              }
            })
          : _vm._e(),
        _c("BaseModal", {
          ref: "fehlerModal",
          attrs: { labelButtonCancel: "Ok", showConfirmButton: false },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-warning", {
                    staticClass: "color-danger mr-2",
                    attrs: { size: 16 }
                  }),
                  _c("span", { staticClass: "color-danger" }, [
                    _vm._v(" Bitte wählen Sie ein bereits vorhandenes Depot")
                  ])
                ]
              },
              proxy: true
            }
          ])
        }),
        _c("DeletePositionModal", {
          ref: "deleteModal",
          attrs: { position: _vm.positionToDelete },
          on: {
            delete: function($event) {
              return _vm.doDeletePosition()
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }