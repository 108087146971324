var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseButton",
    {
      staticClass: "upload-btn",
      attrs: {
        isClear: _vm.isClear,
        isPrimary: _vm.isPrimary,
        isSecondary: _vm.isSecondary,
        isBlock: _vm.isBlock,
        disabled: _vm.disabled,
        animated: _vm.animated
      }
    },
    [
      _vm._t("default", function() {
        return [_vm._v("Dokument auswählen")]
      }),
      _c(
        "label",
        {
          staticClass: "upload-btn--label",
          class: { clickable: !_vm.disabled }
        },
        [
          _c("input", {
            attrs: {
              type: "file",
              accept: _vm.accept,
              multiple: _vm.multiple,
              disabled: _vm.disabled
            },
            on: { change: _vm.onFileChange }
          })
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }