export const MUTATION_PREFIX = 'COURTAGETABELLE_VERSICHERUNG_MUTATION_';
export const ACTIONS_PREFIX = 'COURTAGETABELLE_VERSICHERUNG_ACTIONS_';
export const GETTERS_PREFIX = 'COURTAGETABELLE_VERSICHERUNG_GETTERS_';

export default {
  MUTATIONS: {
    FILTER_FIELDS_DEFINITIONS_SUCCESS: MUTATION_PREFIX + 'FILTER_FIELDS_DEFINITIONS_SUCCESS',
    COURTAGETABELLE_VERSICHERUNG_LIST_SUCCESS: MUTATION_PREFIX + 'COURTAGETABELLE_VERSICHERUNG_LIST_SUCCESS',
    COURTAGETABELLE_VERSICHERUNG_DISPLAY_INFO_SUCCESS: MUTATION_PREFIX + 'COURTAGETABELLE_VERSICHERUNG_DISPLAY_INFO_SUCCESS',
    CLEAR_COURTAGETABELLE_VERSICHERUNG_LIST: MUTATION_PREFIX + 'CLEAR_COURTAGETABELLE_VERSICHERUNG_LIST',
    CLEAR_COURTAGETABELLE_VERSICHERUNG_DISPLAY_INFO: MUTATION_PREFIX + 'CLEAR_COURTAGETABELLE_VERSICHERUNG_DISPLAY_INFO',
    GET_MENU_ITEM_ACCESS_RULE_SUCCESS: MUTATION_PREFIX + 'GET_MENU_ITEM_ACCESS_RULE_SUCCESS',
    SAVE_OR_UPDATE_SUCCESS: MUTATION_PREFIX + 'SAVE_OR_UPDATE_SUCCESS',
    GET_FORM_METADATA_SUCCESS: MUTATION_PREFIX + 'GET_FORM_METADATA_SUCCESS',
  },
  ACTIONS: {
    GET_FILTER_FIELDS_DEFINITIONS: ACTIONS_PREFIX + 'GET_FILTER_FIELDS_DEFINITIONS',
    GET_COURTAGETABELLE_VERSICHERUNG_LIST: ACTIONS_PREFIX + 'GET_COURTAGETABELLE_VERSICHERUNG_LIST',
    GET_COURTAGETABELLE_VERSICHERUNG_DISPLAY_INFO: ACTIONS_PREFIX + 'GET_COURTAGETABELLE_VERSICHERUNG_DISPLAY_INFO',
    COURTAGETABELLE_VERSICHERUNG_EXPORT: ACTIONS_PREFIX + 'COURTAGETABELLE_VERSICHERUNG_EXPORT',
    GET_MENU_ITEM_ACCESS_RULE: ACTIONS_PREFIX + 'GET_MENU_ITEM_ACCESS_RULE',
    SAVE_OR_UPDATE: ACTIONS_PREFIX + 'SAVE_OR_UPDATE',
    GET_FORM_METADATA: ACTIONS_PREFIX + 'GET_FORM_METADATA',
    DELETE_COURTAGETABELLE_ITEM: ACTIONS_PREFIX + 'DELETE_COURTAGETABELLE_ITEM',
  },
  GETTERS: {
    FILTER_FIELDS_DEFINITIONS: GETTERS_PREFIX + 'FILTER_FIELDS_DEFINITIONS',
    COURTAGETABELLE_VERSICHERUNG_LIST: GETTERS_PREFIX + 'COURTAGETABELLE_VERSICHERUNG_LIST',
    COURTAGETABELLE_VERSICHERUNG_DISPLAY_INFO: GETTERS_PREFIX + 'COURTAGETABELLE_VERSICHERUNG_DISPLAY_INFO',
    GET_MENU_ITEM_ACCESS_RULE: GETTERS_PREFIX + 'GET_MENU_ITEM_ACCESS_RULE',
    LAST_UPDATED_ITEM: GETTERS_PREFIX + 'LAST_UPDATED_ITEM',
    FORM_METADATA: GETTERS_PREFIX + 'FORM_METADATA',
  }
}