var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cards__container" },
    [
      _vm.config.anzahlFonds
        ? _c("FormLabel", {
            attrs: {
              config: { small: true },
              label: "(Maximal " + _vm.config.anzahlFonds + " Positionen)"
            }
          })
        : _vm._e(),
      _c("div", { staticClass: "fondsfinder-button__container" }, [
        _c(
          "div",
          { staticClass: "input-forms__label-content--bigger mb-3" },
          [_c("ph-bank", { attrs: { size: 16 } }), _vm._v(" Kauf ")],
          1
        ),
        _c(
          "div",
          [
            _c(
              "BaseButton",
              {
                attrs: { disabled: _vm.positions.length >= 4 },
                on: {
                  click: function($event) {
                    return _vm.openFondsfinder("KAUF")
                  }
                }
              },
              [_vm._v("Kauf hinzufügen")]
            )
          ],
          1
        )
      ]),
      _vm.hasKaufPositions
        ? _c(
            "div",
            { staticClass: "cards__items" },
            [
              _vm._l(_vm.kaufPositions, function(pos, index) {
                return _c(
                  "div",
                  {
                    key: pos.isin + index,
                    ref: "cards",
                    refInFor: true,
                    staticClass: "box__container cards__item",
                    class: _vm.getPositionClasses(pos)
                  },
                  [
                    _c("FormEbaseWeitereDepotsPositionCards", {
                      key: index,
                      attrs: {
                        position: pos,
                        antragId: _vm.antragId,
                        isWebservice: _vm.config.isWebservice,
                        disabled: _vm.disabled,
                        positionWarnings: _vm.positionWarnings
                      }
                    })
                  ],
                  1
                )
              }),
              _c("div", { staticClass: "box__container cards__item" }, [
                _c(
                  "div",
                  { staticClass: "cards__item--inputs mt-3" },
                  [
                    _c("InputRadioBoxGroup", {
                      staticClass: "cards__item--input-240",
                      attrs: {
                        id: "einmalModusAbbuchung",
                        values: _vm.einmalModusValues,
                        disabled: _vm.disabled,
                        validateUntouched: ""
                      },
                      on: {
                        input: function($event) {
                          return _vm.updatePositionData(
                            "KAUF",
                            $event,
                            "einmalModusAbbuchung"
                          )
                        }
                      },
                      model: {
                        value: _vm.form.einmalModusAbbuchung,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "einmalModusAbbuchung", $$v)
                        },
                        expression: "form.einmalModusAbbuchung"
                      }
                    }),
                    _c("DatePickerField", {
                      staticClass: "cards__item--input-240",
                      attrs: {
                        label: "Abbuchungstermin Einmalanlage",
                        id: "einmalDatumAbbuchung",
                        disabled:
                          _vm.disabled ||
                          !(
                            _vm.form.einmalModusAbbuchung === "2" ||
                            _vm.form.einmalModusAbbuchung === 2
                          )
                      },
                      on: {
                        input: function($event) {
                          return _vm.updatePositionData(
                            "KAUF",
                            $event,
                            "einmalDatumAbbuchung"
                          )
                        }
                      },
                      model: {
                        value: _vm.form.einmalDatumAbbuchung,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "einmalDatumAbbuchung", $$v)
                        },
                        expression: "form.einmalDatumAbbuchung"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            2
          )
        : _vm._e(),
      _c("div", { staticClass: "fondsfinder-button__container mt-3" }, [
        _c(
          "div",
          { staticClass: "input-forms__label-content--bigger mb-3" },
          [_c("ph-bank", { attrs: { size: 16 } }), _vm._v(" Sparplan ")],
          1
        ),
        _c(
          "div",
          [
            _c(
              "BaseButton",
              {
                attrs: { disabled: _vm.positions.length >= 4 },
                on: {
                  click: function($event) {
                    return _vm.openFondsfinder("SPARPLAN")
                  }
                }
              },
              [_vm._v("Sparplan hinzufügen")]
            )
          ],
          1
        )
      ]),
      _vm.hasSparplanPositions
        ? _c(
            "div",
            { staticClass: "cards__items" },
            [
              _vm._l(_vm.sparplanPositions, function(pos, index) {
                return _c(
                  "div",
                  {
                    key: pos.isin + index,
                    ref: "cards",
                    refInFor: true,
                    staticClass: "box__container cards__item",
                    class: _vm.getPositionClasses(pos)
                  },
                  [
                    _c("FormEbaseWeitereDepotsPositionCards", {
                      key: index,
                      attrs: {
                        position: pos,
                        antragId: _vm.antragId,
                        isWebservice: _vm.config.isWebservice,
                        disabled: _vm.disabled,
                        positionWarnings: _vm.positionWarnings
                      }
                    })
                  ],
                  1
                )
              }),
              _c("div", { staticClass: "box__container cards__item" }, [
                _c(
                  "div",
                  { staticClass: "cards__item--inputs mt-3" },
                  [
                    _c("DatePickerField", {
                      staticClass: "cards__item--input-240",
                      attrs: {
                        monthPicker: "",
                        label: "Abbuchungstermin Sparplan",
                        id: "sparplanBeginnDatum",
                        placeholder: "MM.JJJJ",
                        disabled: _vm.disabled
                      },
                      on: {
                        input: function($event) {
                          return _vm.updatePositionData(
                            "SPARPLAN",
                            $event,
                            "sparplanBeginnDatum"
                          )
                        }
                      },
                      model: {
                        value: _vm.form.sparplanBeginnDatum,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "sparplanBeginnDatum", $$v)
                        },
                        expression: "form.sparplanBeginnDatum"
                      }
                    }),
                    _c("DatePickerField", {
                      staticClass: "cards__item--input-240",
                      attrs: {
                        monthPicker: "",
                        label: "Letzte Ausführung Sparplan",
                        id: "sparplanEndDatum",
                        placeholder: "MM.JJJJ",
                        disabled: _vm.disabled
                      },
                      on: {
                        input: function($event) {
                          return _vm.updatePositionData(
                            "SPARPLAN",
                            $event,
                            "sparplanEndDatum"
                          )
                        }
                      },
                      model: {
                        value: _vm.form.sparplanEndDatum,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "sparplanEndDatum", $$v)
                        },
                        expression: "form.sparplanEndDatum"
                      }
                    }),
                    _c("ComboBox", {
                      staticClass: "cards__item--input-295",
                      attrs: {
                        label: "Der Einzug der Sparpläne erfolgt vom:",
                        sortComboboxValues: false,
                        id: "spKontoFlex",
                        values: [
                          { label: "Konto flex", value: true },
                          {
                            label: "externen Konto (siehe Sonstiges)",
                            value: false
                          }
                        ],
                        disabled: _vm.disabled
                      },
                      on: {
                        input: function($event) {
                          return _vm.updatePositionData(
                            "SPARPLAN",
                            $event,
                            "spKontoFlex"
                          )
                        }
                      },
                      model: {
                        value: _vm.form.spKontoFlex,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "spKontoFlex", $$v)
                        },
                        expression: "form.spKontoFlex"
                      }
                    }),
                    _c("ComboBox", {
                      staticClass: "cards__item--input-295",
                      attrs: {
                        label: "Dynamik",
                        sortComboboxValues: false,
                        id: "sparplanDynamik",
                        values: _vm.sparplanDynamikValues,
                        disabled: _vm.disabled
                      },
                      on: {
                        input: function($event) {
                          return _vm.updatePositionData(
                            "SPARPLAN",
                            $event,
                            "sparplanDynamik"
                          )
                        }
                      },
                      model: {
                        value: _vm.form.sparplanDynamik,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "sparplanDynamik", $$v)
                        },
                        expression: "form.sparplanDynamik"
                      }
                    }),
                    _c("InputField", {
                      staticClass: "cards__item--input-240",
                      attrs: {
                        label: "Prozent Dynamik",
                        id: "sparplanDynamikFrei",
                        type: "number",
                        precision: 0,
                        validateUntouched: "",
                        disabled:
                          _vm.disabled ||
                          !(
                            _vm.form.sparplanDynamik != -1 &&
                            _vm.form.sparplanDynamik != 3 &&
                            _vm.form.sparplanDynamik != null
                          )
                      },
                      on: {
                        input: function($event) {
                          return _vm.updatePositionData(
                            "SPARPLAN",
                            $event,
                            "sparplanDynamikFrei"
                          )
                        }
                      },
                      model: {
                        value: _vm.form.sparplanDynamikFrei,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "sparplanDynamikFrei", $$v)
                        },
                        expression: "form.sparplanDynamikFrei"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            2
          )
        : _vm._e(),
      _vm.config.mitSummenbildung && this.positions && this.positions.length
        ? _c(
            "div",
            [
              _c("Summenbildung", {
                attrs: {
                  antragId: _vm.antragId,
                  summe: _vm.summeEinmalig,
                  summeSpar: _vm.summeSparplan,
                  mitSparplan: true
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }