import actions from './actions';
import mutations from './mutations';
import getters from './getters';

/**
 * mutations: synchronous operation responsible for change the state
 * actions: asynchronous operation responsible for call external resources and commit mutations 
 */

export function getInitialState(initial) {
  return {
    searches: {},
    searchCustomer: {},
    searchPIN: {},
    customerFilterSetup: { data: {}, status: '' },
    customerFilterSetupDepot: { data: {}, status: '' },
    customerFilterSetupVers: { data: {}, status: '' },
    customerWertpapierCombo: { data: {}, status: '' },
    brokerFilterSetup: {},
    contactFilterSetup: {},
    brokers: {},
    panels: {},
    lastOpenedCustomers: [],
    selectedCustomers: null,
    kundenLoeschenRestrictions: [],
    removedCustomers: [],
    contacts: {},
    ...initial,
  }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}