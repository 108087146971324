<template>
  <div class="box__container">
    <template v-if="isReady">
      <SimpleAdditionalDocumentsTable
        :nodeId="nodeId"
        :listViewOnly="false"
        :fileId="fileId"
        :uploadType="uploadType"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CREDIT_TYPES from '@/store/credit/types';
import SimpleAdditionalDocumentsTable from '@/components/documents/SimpleAdditionalDocumentsTable.vue';
import CORE_TYPES from '@/store/core/types';

import { UPLOAD_TYPE } from '@/views/documents/AdditionalDocuments.vue';

export default {
  computed: {
    ...mapGetters({
      credit: CREDIT_TYPES.GETTERS.CREDIT,
      isTestCustomer: CORE_TYPES.GETTERS.IS_TEST_CUSTOMER,
    }),
    nodeId() {
      return this.credit?.nodeId || '';
    },
    fileId() {
      return this.credit?.fileId || '';
    },
    isReady() {
      return !!this.nodeId && !!this.fileId;
    },
    uploadType() {
      return UPLOAD_TYPE.DOKUMENTE_HINZUFUGEN;
    },
  },
  components: {
    SimpleAdditionalDocumentsTable,
  },
}
</script>
