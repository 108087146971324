var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        !_vm.rows.length
          ? _c("div", { staticClass: "box__title" }, [
              _vm._v(" " + _vm._s(_vm.title) + " ")
            ])
          : _vm._e(),
        _vm.rows.length
          ? _c("Table", {
              attrs: {
                tableId: "36688150-ebdd-11ec-8ea0-0242ac120002",
                title: _vm.title,
                headers: _vm.headers,
                rows: _vm.rows,
                paginationEnabled: false,
                hidePagination: true,
                mobileConfig: {
                  title: "userId",
                  headers: ["status", "userId", "barcode"]
                }
              },
              on: {
                "action-OPEN": _vm.handleActionOpen,
                "action-REPORT": _vm.handleActionReport
              }
            })
          : _vm.loading
          ? _c("GhostLoading", { attrs: { type: "table" } })
          : _c("NoData")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }