<template>
  <BaseModal ref="modal"
      labelButtonConfirm="Annehmen"
      labelButtonCancel="Ablehnen"
      @onConfirmButton="acceptCall"
      @onCancelButton="rejectCall">
    <template v-slot:modalTitle>
      Eingehender Anruf
    </template>
    <strong>
      {{displayName}} 
  
    </strong> ruft an.
   
  </BaseModal>
</template>

<script>
import { mapGetters } from 'vuex'

import BaseModal from '@/components/core/BaseModal.vue';
import BaseButton from '../core/BaseButton'
import TAPI from '../../store/tapi/types'
export default {
  computed: {
  ...mapGetters({
   
      incomingCallTapi: TAPI.GETTERS.OPEN_INCOMMING_DIALOG,
      lineTapi: TAPI.GETTERS.LINE,

    }),
 
    displayName() {
      return  this.incomingCallTapi;
    },
    visible() {
      
      return this.incomingCallTapi;
    }
  },
  watch: {
    visible(newValue) {
      if (newValue){
        this.$refs.modal.open();
      } else{
        this.$refs.modal.close();
      }
    }
  },
  methods: {
    acceptCall() {
     
    	this.$store.dispatch(TAPI.ACTIONS.CONNECT,{param:"ACCEPTCALLOUT"});
      //this.$store.dispatch(WEBRTC_TYPES.ACTIONS.ACCEPT_CALL);
      this.$refs.modal.close();
    },
    rejectCall() {
       this.$store.dispatch(TAPI.ACTIONS.CALL_DISCONNECT);
     // this.$store.dispatch(WEBRTC_TYPES.ACTIONS.REJECT_CALL);
      this.$refs.modal.close();
    },
  },
  components: {
    BaseModal,
    BaseButton,
  },
}
</script>

<style scoped>
