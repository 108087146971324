import VERSICHERUNGSANTRAEGE_TYPES from './types';
import CORE_TYPES from '@/store/core/types'
import { makeQueryParam } from '@/helpers/utils-helper';
import { prepareFilenameForDownload } from '@/helpers/string-helper';

export default {
  [VERSICHERUNGSANTRAEGE_TYPES.GETTERS.GET_ATTACHMENT_LINK_MAKER](state, getters) {
    return (filename, id) => {
      const apiAddress = getters[CORE_TYPES.GETTERS.API_ADDRESS]
      const token = getters[CORE_TYPES.GETTERS.GET_TOKEN]

      const params = makeQueryParam({ id, token })
      
      return `${apiAddress}/download_service/getVersicherungantraegeFile/${prepareFilenameForDownload(filename)}?${params}`
    }
  },
}