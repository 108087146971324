import HTTP_REQ_TYPES from './types';
import Vue from 'vue';

export default {
  [HTTP_REQ_TYPES.MUTATIONS.ADD_HTTP_REQUEST_URL](state, { config, url, requestId, cancelableRequest }) {
    Vue.set(state.httpRequestUrl, requestId, { url, cancelableRequest, config, })
  },

  /**
   * It is highly recommended pass requestId rather than url to remove the request.
   * 
   * For example:
   * Whether two or more requests with the same URL are sent (e.g. when a post method is called with a different payload).
   * If the last request sent finishes first, and the url is passed to remove the request, it will remove the first request sent instead of the last one.
   * 
   * @param {*} state 
   * @param {*} request
   */
  [HTTP_REQ_TYPES.MUTATIONS.REMOVE_HTTP_REQUEST_URL](state, { url, requestId }) {
    let requestIdToDelete = requestId
    if (url && !requestIdToDelete) {
      for (const [key, value] of Object.entries(state.httpRequestUrl)) {
        if (value.url === url) {
          requestIdToDelete = key
        }
      }
    }

    if (requestIdToDelete && state.httpRequestUrl[requestIdToDelete]) {
      Vue.delete(state.httpRequestUrl, requestIdToDelete)
    }
  },

  [HTTP_REQ_TYPES.MUTATIONS.ADD_INVALID_TOKEN_DUE_TO_SESSION_TIMEOUT](state, { token }) {
    Vue.set(state.timeoutInvalidTokens, token, true)
  }

}
