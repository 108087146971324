import VueJsBridge from '@soscomp/vue-webview-js-bridge'
import Vue from 'vue'
import { mockHandler } from '@/mock-handlers/vuejs-bridge-mock-handler'
import store from '../store';
import BRIDGE_TYPES from '@/store/bridge/types'

const NATIVE_HANDLER_NAME = 'executeInNativeApp';
const WEB_HANDLER_NAME = 'executeInWebApp';

export function registerHandlerWeb() {
  Vue.prototype.$bridge.registerHandler(WEB_HANDLER_NAME, (data, callback) => {
    navigator.locks.request('bridge_execution_lock', async () => {
      let request = JSON.parse(data);
      await store.dispatch(BRIDGE_TYPES.ACTIONS.HANDLE_CALLS_FROM_NATIVE, { 
        data: request, 
        callback
      });
    });
  })
}

Vue.use(VueJsBridge, {
  debug: process.env.VUE_APP_PRODUCTION !== 'true' && process.env.VUE_APP_VUE_JS_BRIDGE_DEBUG === 'true',
  delay: 200,
  nativeHandlerName: NATIVE_HANDLER_NAME,
  mock: process.env.VUE_APP_VUE_JS_BRIDGE_MOCK === 'true',
  mockHandler,
})