<template>
    <div>
        <div v-if="isVermittlung">
            <div>
                Ihr Vermittler ist auf Grund gesetzlicher Bestimmungen verpflichtet Ihre Kenntnissen und Erfahrungen mit Kapitalanlagen zu erfragen. 
				Möchten Sie zu oben genannten Punkten ganz oder teilweise keine Angaben machen, kann eine Angemessenheitsprüfung nicht stattfinden.
            </div>
            <div>
                Sie bestätigen hiermit, dass der Vermittler Ihnen keine Anlage empfohlen hat bzw. empfiehlt und Sie sich für die Anlage selbständig 
				entschieden haben bzw. entscheiden und diese auch tätigen wollen. Sie beauftragen den Vermittler lediglich mit der Durchführung der 
				Kaufaufträge.
            </div>
        </div>
        <div v-else>
            <div>
                Ihr Berater ist auf Grund gesetzlicher Bestimmungen verpflichtet Ihre Kenntnissen und Erfahrungen mit Kapitalanlagen, 
                Ihre finanziellen Verhältnisse, Ihre Anlageziele/Anlagedauer/Zweck der Anlage/Zahlungsweise, Ihre Risikoneigung und Ihre 
                Risikobereitschaft zu erfragen. Möchten Sie zu oben genannten Punkten ganz oder teilweise keine Angaben machen, kann eine 
                Geeignetheitsprüfung und eine Beratung nicht stattfinden.
            </div>
            <div class="mt-2 mb-2">
                Sie bestätigen hiermit, dass der Berater Ihnen keine Anlage empfohlen hat bzw. empfiehlt und Sie sich für die Anlage 
                selbständig entschieden haben bzw. entscheiden und diese auch tätigen wollen. Die von Ihnen gewählte Anlage entspricht 
                im Hinblick auf die zeitliche Abstimmung und der Risikoklasse Ihren Anforderungen an Verfügbarkeit, Rendite und Sicherheit. 
                Sie beauftragen den Berater lediglich mit der Durchführung der Kaufaufträge.
            </div>
        </div>
        <InputRadioBoxGroup 
            :title="'Der Kunde beauftragt den ' + maklerTyp + ' nur mit der Durchführung der Kaufaufträge'"
            v-model="frage1" 
            :values="[
                {value: 'ja', label: 'ja'},
                {value: 'nein', label: 'nein'},
            ]"
            :inlineLabelValues="false"
            :disabled="isDisabled"
            validateUntouched
            @input="updateStore('warnhinweise', 'frage1', $event)" />
        <InputRadioBoxGroup 
            :title="'Hat Sie der ' + maklerTyp + ' dazu veranlasst, Angaben zu verweigern?'"
            v-model="frage2" 
            :values="[
                {value: 'ja', label: 'ja'},
                {value: 'nein', label: 'nein'},
            ]"
            :inlineLabelValues="false"
            :disabled="isDisabled"
            validateUntouched
            @input="updateStore('warnhinweise', 'frage2', $event)" />
        
        <div v-if="typ === '2'">
            <div class="font-bold mb-2"> 
                Angemessenheitsprüfung
            </div>
            <div class="mb-2"> 
                Es wurde ausdrücklich nach ihren produktspezifischen Kenntnissen und Erfahrungen gefragt. Da Sie keine oder 
                nur unzureichende Angaben zu Ihren Kenntnissen und Erfahrungen gemacht haben, kann der Berater nicht 
                beurteilen, ob das gewählte Produkt für Sie angemessen ist.
            </div>
            <InputRadioBoxGroup 
                title="Möchten Sie dem Finanzdienstleister einen Auftrag erteilen?"
                v-model="frage3" 
                :values="[
                    {value: 'ja', label: 'ja'},
                    {value: 'nein', label: 'nein'},
                ]"
                :inlineLabelValues="false"
                :disabled="isDisabled"
                validateUntouched
                @input="updateStore('warnhinweise', 'frage3', $event)" />
            <InputRadioBoxGroup 
                :title="'Hat Sie der ' + maklerTyp + ' dazu veranlasst, Angaben zu verweigern?'"
                v-model="frage4" 
                :values="[
                    {value: 'ja', label: 'ja'},
                    {value: 'nein', label: 'nein'},
                ]"
                :inlineLabelValues="false"
                :disabled="isDisabled"
                validateUntouched
                @input="updateStore('warnhinweise', 'frage4', $event)" />
        </div>
    </div>
</template>

<script>
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import anlegerprofilMixin from '@/mixins/anlegerprofil/anlegerprofil-mixin.js';

export default {
    mixins: [anlegerprofilMixin],
    components: {
        InputRadioBoxGroup,
    },
    data() {
        return {
            frage1: '',
            frage2: '',
            frage3: '',
            frage4: '',
        }
    },
    mounted() {
        this.patchForm();
        this.$nextTick().then(() => {
            this.updateWarnings();
        });
    },
    computed: {
        typ() {
            return this.data?.extraData?.warnhinweise === 'HINWEIS_1_2' ? '2' : '1';
        },
        maklerTyp() {
            return this.isVermittlung ? 'Vermittler' : 'Berater';
        }
    },
    methods: {
        patchForm() {
            if (this.data?.warnhinweise && Object.keys(this.data.warnhinweise).length) {
                Object.keys(this.data.warnhinweise).forEach(key => this[key] = this.data.warnhinweise[key])
            }
        },
    }
}

</script>

<style scoped>
</style>