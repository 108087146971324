<template>
  <div v-if="customerDataEdited">
    <Table
      v-if="rows.length"
      ref="table"
      :tableId="tableId"
      cardView
      :headers="headers"
      :rows="rows"
      rowId="id"
      showWeiterLink
      :headersSize="isCustomerOnly ? 0 : 5"
      hidePagination
      @onNavigate="navigateToCard"
    >
    </Table>
    <NoData v-else noIcon />
  </div>
</template>


<script>
import BoxContainer from "@/components/core/BoxContainer.vue";
import { PhPencilLine, PhBookOpen } from "phosphor-vue";
import { TextColumn, CurrencyColumn } from "@/components/table2/table_util.js";
import Table from "@/components/table2/Table.vue";
import { mapGetters } from "vuex";
import CUSTOMERDATA_TYPES from "@/store/customerData/types";
import CORE_TYPES from "@/store/core/types";
import persoenlicheDatenMixin from "../persoenliche-daten-mixin";
import stammdatenMixin from "@/components/persoenliche-daten/stammdaten/stammdaten-mixin";
import NoData from '@/components/core/NoData.vue';

export default {
  components: {
    BoxContainer,
    PhPencilLine,
    PhBookOpen,
    Table,
    NoData,
  },
  mixins: [persoenlicheDatenMixin, stammdatenMixin],
  data: function () {
    return {
      title: "Anschriftfeld",
      anschriftfeld: {
        title: "",
        fullName: "",
        co: "",
        street: "",
        zip: "",
        city: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      customerDataEdited: CUSTOMERDATA_TYPES.GETTERS.GET_CUSTOMER_DATA_EDITED,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      isFK: CORE_TYPES.GETTERS.IS_FK,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      personId: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_ID,
      customerDataConfig: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_CONFIG,
      isCustomerOnly: CORE_TYPES.GETTERS.IS_CUSTOMER_ONLY,
    }),
    headers() {
      const result = {
        center: [],
      };

      result.center.push(TextColumn("title", "Anrede"));
      result.center.push(TextColumn("fullName", "Name"));
      result.center.push(TextColumn("co", "c/o"));
      result.center.push(TextColumn("street", "Straße, HNr"));
      result.center.push(TextColumn("zip", "Postleitzahl"));
      result.center.push(TextColumn("city", "Ort"));

      return result;
    },
    rows() {
      const rows = [];

      const row = {};

      row.title = this.anschriftfeld?.title || "-";
      row.fullName = this.anschriftfeld?.fullName || "-";
      row.co = this.anschriftfeld?.co || "-";
      row.zip = this.anschriftfeld?.zip || "-";
      row.street = this.anschriftfeld?.street || "-";
      row.city = this.anschriftfeld?.city || "-";

      rows.push(row);
      return rows;
    },
    tableId() {
      const uniqueUUID = "3201f7a7-c6c3-4083-99fd-3fbca1894c55";
      return uniqueUUID;
    },
    hasFirstName() {
      return !!this.customerData?.personalDataAddress?.firstName;
    },
  },
  methods: {
    navigateToCard(row) {
      this.$router.push(
        `/persoenlichedaten/customer-data/steps/stammdaten?substep=anschriftfeld`
      );
    },
    currency(value) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €";
    },
    // updateGroupIntoData(groupName) {
    //     Object.keys(this[groupName]).forEach(fieldName => {
    //         if (this.customerDataEdited && this.customerDataEdited[groupName] && fieldName in this.customerDataEdited[groupName]) {
    //         this[groupName][fieldName] = this.customerDataEdited[groupName][fieldName]
    //         } else if (this.customerData && this.customerData[groupName] && fieldName in this.customerData[groupName]) {
    //         this[groupName][fieldName] = this.customerData[groupName][fieldName]
    //         }
    //     })
    // },
    updateGroupsIntoData() {
      this.updateGroupIntoData("anschriftfeld");
    },
  },
  mounted() {
    this.updateGroupsIntoData();
  },
  validators: {},
};
</script>

<style scoped>
.align-end {
  flex: 1 1 auto;
  align-self: flex-end;
  padding: 0px 0px 16px 5px;
  color: var(--color-text);
}
.form-control {
  display: flex;
  align-content: stretch;
  align-items: center;
}
</style>