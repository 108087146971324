export const MUTATION_PREFIX = 'VERMITTLERNUMMERN_MUTATIONS_';
export const ACTIONS_PREFIX = 'VERMITTLERNUMMERN_ACTIONS_';
export const GETTERS_PREFIX = 'VERMITTLERNUMMERN_GETTERS_';

export default {
  MUTATIONS: {
    RETRIEVE_VERSICHERUNGEN_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_VERSICHERUNGEN_SUCCESS',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    REMOVE_ROW_SUCCESS: MUTATION_PREFIX + 'REMOVE_ROW_SUCCESS',
    SELECTED_FREMDNR: MUTATION_PREFIX + 'SELECTED_FREMDNR',
    PERSIST_ROW_SUCCESS: MUTATION_PREFIX + 'PERSIST_ROW_SUCCESS',
    RESET_LIST: MUTATION_PREFIX + 'RESET_LIST',
    GET_OTHER_TABS_DATA_SUCCESS: MUTATION_PREFIX + 'GET_OTHER_TABS_DATA_SUCCESS',
    RESET_LIST_OTHER_TABS: MUTATION_PREFIX + 'RESET_LIST_OTHER_TABS',
    GET_ALLE_MITARBEITER_DATA_SUCCESS: MUTATION_PREFIX + 'GET_ALLE_MITARBEITER_DATA_SUCCESS',
    RESET_LIST_ALLE_MITARBEITER: MUTATION_PREFIX + 'RESET_LIST_ALLE_MITARBEITER',
  },
  ACTIONS: {
    RETRIEVE_VERSICHERUNGEN_DATA: ACTIONS_PREFIX + 'RETRIEVE_VERSICHERUNGEN_DATA',
    REMOVE_ROW: ACTIONS_PREFIX + 'REMOVE_ROW',
    PERSIST_ROW: ACTIONS_PREFIX + 'PERSIST_ROW',
    GET_OTHER_TABS_DATA: ACTIONS_PREFIX + 'GET_OTHER_TABS_DATA',
    GET_ALLE_MITARBEITER_DATA: ACTIONS_PREFIX + 'GET_ALLE_MITARBEITER_DATA',
  },
  GETTERS: {
    VERSICHERUNGEN_DATA: GETTERS_PREFIX + 'VERSICHERUNGEN_DATA',
    SELECTED_FREMDNR: GETTERS_PREFIX + 'SELECTED_FREMDNR',
    OTHER_TABS_DATA: GETTERS_PREFIX + 'OTHER_TABS_DATA',
    ALLE_MITARBEITER_DATA: GETTERS_PREFIX + 'ALLE_MITARBEITER_DATA',
  }
}