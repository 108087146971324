<template>
  <NoData noIcon useBoxContainer>
    <template #content>
      <div class="mb-3">Bitte einen Fondsvergleich starten</div>
      <div><BaseButton @click="$emit('click')">zu den Einstellungen</BaseButton></div>
    </template>
  </NoData>
</template>

<script>
import NoData from '@/components/core/NoData.vue';
import BaseButton from '@/components/core/BaseButton.vue';

export default {
  components: {
    NoData,
    BaseButton,
  },
}
</script>
