var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container chart-container" },
    [
      _vm.showTitle
        ? _c("div", { staticClass: "box__title" }, [
            _vm._v(" " + _vm._s(_vm.title) + " "),
            _vm.chart.type == "SFDRChart" ||
            _vm.chart.type == "TaxonomieChart" ||
            _vm.chart.type == "PaiChart"
              ? _c(
                  "a",
                  {
                    staticClass: "btn btn-clean clickable",
                    on: {
                      click: function($event) {
                        return _vm.infoModal(_vm.chart.type)
                      }
                    }
                  },
                  [_c("PhInfo", { staticClass: "ml-1", attrs: { size: 20 } })],
                  1
                )
              : _vm._e()
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c(
            "div",
            {
              staticClass: "col-12",
              class: { "col-xl-6": !_vm.chart.barChart }
            },
            [
              _vm.chart.type == "PaiChart"
                ? [
                    _c("GaugeChartMultiple", {
                      attrs: {
                        chartData: _vm.getEChartData(_vm.chart),
                        format: "percent",
                        height: "350px",
                        isTooltip: false
                      }
                    })
                  ]
                : _vm.chart.type == "SFDRChart" ||
                  _vm.chart.type == "TaxonomieChart"
                ? [
                    !_vm.chart.barChart
                      ? _c("PieChart", {
                          attrs: {
                            chartData: _vm.getEChartData(_vm.chart),
                            customColors: _vm.getESGCustomColors(),
                            format: "percent",
                            height: "350px"
                          }
                        })
                      : _vm._e()
                  ]
                : _vm.chart.type !== "ChartDistributionStates"
                ? [
                    !_vm.chart.barChart
                      ? _c("PieChart", {
                          attrs: {
                            chartData: _vm.getEChartData(_vm.chart),
                            format: "percent",
                            height: "350px"
                          }
                        })
                      : _c("BarChart", {
                          attrs: {
                            chartData: _vm.getEChartData(_vm.chart),
                            axisLabelFormatterShowOption: _vm.getAxisLabelFormatterShowOption(
                              _vm.chart
                            ),
                            format: "percent",
                            height: "400px"
                          }
                        })
                  ]
                : [
                    _c("MapChart", {
                      attrs: { chartData: _vm.getEChartData(_vm.chart) }
                    })
                  ]
            ],
            2
          ),
          _vm.chart.type !== "ChartDistributionStates"
            ? [
                !_vm.chart.barChart
                  ? _c(
                      "div",
                      { staticClass: "col-12 col-xl-6 d-flex chart-label" },
                      [
                        _c(
                          "div",
                          { staticClass: "row" },
                          [
                            _vm._l(
                              _vm.getChartLegends(_vm.chart).slice(0, 5),
                              function(label, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass:
                                      "col-12 d-flex chart-label chart-label-dot"
                                  },
                                  [
                                    _c("PhCircle", {
                                      attrs: {
                                        size: "16",
                                        weight: "fill",
                                        color: _vm.getColor(index)
                                      }
                                    }),
                                    _c("span", [_vm._v(_vm._s(label))])
                                  ],
                                  1
                                )
                              }
                            ),
                            _vm.showMore
                              ? _c(
                                  "div",
                                  _vm._l(
                                    _vm.getChartLegends(_vm.chart).slice(5),
                                    function(label, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index + 5,
                                          staticClass:
                                            "col-12 d-flex chart-label chart-label-dot"
                                        },
                                        [
                                          _c("PhCircle", {
                                            attrs: {
                                              size: "16",
                                              weight: "fill",
                                              color: _vm.getColor(index + 5)
                                            }
                                          }),
                                          _c("span", [_vm._v(_vm._s(label))])
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                            _vm.getChartLegends(_vm.chart).length > 5
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-12 d-flex chart-label clickable"
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.toggleMoreLabels()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.showMore ? "Weniger" : "Mehr"
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          2
                        )
                      ]
                    )
                  : _vm._e()
              ]
            : [
                !_vm.chart.barChart
                  ? _c(
                      "div",
                      { staticClass: "col-12 col-xl-6 d-flex chart-label" },
                      [
                        _c(
                          "div",
                          { staticClass: "row" },
                          [
                            _vm._l(
                              _vm.getChartLegends(_vm.chart).slice(0, 5),
                              function(label, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass:
                                      "col-12 d-flex chart-label chart-label-dot"
                                  },
                                  [
                                    _c("PhCircle", {
                                      class: {
                                        "empty-circle":
                                          _vm.getColorMonochrom(index, label) ==
                                          "#ffffff"
                                      },
                                      attrs: {
                                        size: "16",
                                        weight: "fill",
                                        color: _vm.getColorMonochrom(
                                          index,
                                          label
                                        )
                                      }
                                    }),
                                    _c("span", [_vm._v(_vm._s(label))])
                                  ],
                                  1
                                )
                              }
                            ),
                            _vm.showMore
                              ? _c(
                                  "div",
                                  _vm._l(
                                    _vm.getChartLegends(_vm.chart).slice(5),
                                    function(label, index) {
                                      return _c(
                                        "div",
                                        {
                                          key: index + 5,
                                          staticClass:
                                            "col-12 d-flex chart-label chart-label-dot"
                                        },
                                        [
                                          _c("PhCircle", {
                                            class: {
                                              "empty-circle":
                                                _vm.getColorMonochrom(
                                                  index,
                                                  label
                                                ) == "#ffffff"
                                            },
                                            attrs: {
                                              size: "16",
                                              weight: "fill",
                                              color: _vm.getColorMonochrom(
                                                index + 5,
                                                label
                                              )
                                            }
                                          }),
                                          _c("span", [_vm._v(_vm._s(label))])
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _vm._e(),
                            _vm.getChartLegends(_vm.chart).length > 5
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-12 d-flex chart-label clickable"
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.toggleMoreLabels()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.showMore ? "Weniger" : "Mehr"
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          2
                        )
                      ]
                    )
                  : _vm._e()
              ]
        ],
        2
      ),
      _c(
        "BaseModal",
        {
          ref: "infoModalRef",
          attrs: { showCancelButton: false, labelButtonConfirm: "Ok" },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("span", { domProps: { innerHTML: _vm._s(_vm.title) } })
                ]
              },
              proxy: true
            }
          ])
        },
        [_c("span", { domProps: { innerHTML: _vm._s(_vm.infoModalText) } })]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }