<template>
  <span class="input-forms__label-content mt-2" v-if="label" style="font-weight: bold">{{label}}</span>
</template>

<script>
export default {
  props: {
    label: {
      default: ''
    }
  }
}
</script>

<style scoped>

</style>