<template>
    <div>
        <PageHeaderTitleNavigation 
            title="eFonds24"
            subtitle="Stammdaten"
            :actions="headerActions"
            @action-UPLOAD="uploadData()"
            @action-OPEN-E-FONDS="openEFonds()"
        />
        
        <div class="row my-0">
            <div class="col-12">
            <AntragSidetext />
            </div>
        </div>

        <div class="box__container">
            <template v-if="accessRights">
                <EntitySelector
                    v-model="personId"
                    isComponentHalfSize
                    isPersonPicker
                    :values="personsCombo"
                    hideNewButton
                    @input="$emit('input', $event)"
                    @change="getStammdaten($event)"
                    @edit="editPerson(personId)"
                />
				<div class="box__container" v-if="candidates.length">
					<p>Der Kunde ist nicht zugeordnet zu einem Kunden in {{data.efondsName}}, aber im {{data.efondsName}} System wurden eventuell passende Kunden gefunden</p>
					<div >Bitte auswählen</div>
					<InputRadioBoxItem 
						name="candidates"
						:value="-1" 
						:label="getTitel(-1)" 
						:checked="-1 === idxCandidates"
						:config="{bold: (-1 === idxCandidates)}"
						@change="updateGuid(-1)"
					/>

					<div v-for="(item, idx) in candidates" :key="item.guid">
						<InputRadioBoxItem 
							name="candidates"
							:value="idx" 
							:label="getLabel(idx)" 
							:checked="idx === idxCandidates"
							:config="{bold: (idx === idxCandidates)}"
							@change="updateGuid(idx)"/>

						<div class="my-2 ml-4" v-if="item.uriEFonds">
							<DownloadLink
								:title="getLinkText(idx)"  :href="item.uriEFonds" 
							/>
						
						</div>
					</div>
				</div>
                <Table
					:title="getTitel(idxCandidates)"
                    :headers="headers"
                    :rows="rows"
                    hidePagination
                >
                </Table>
            </template>
            <GhostLoading v-else-if="loadingList" type="table" />
            <div v-else>Fehlende Berechtigungen für diesen Bereich</div>

        </div>

    </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import AntragSidetext from '@/components/antrag/AntragSidetext.vue'; 
import EntitySelector from '@/components/core/forms/EntitySelector.vue';
import InputRadioBoxItem from '@/components/core/forms/radiobox/InputRadioBoxItem.vue';
import DownloadLink from '@/components/core/download/DownloadLink.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, PillColumn} from "@/components/table2/table_util.js";
import { mapGetters } from "vuex";
import ANTRAG_TYPES from '@/store/antrag/types';
import ANTRAG_EFONDS_TYPES from '@/store/antragEfonds/types';
import antragMixin from '@/mixins/antrag/antrag-mixin.js';
import LOG_TYPES from '@/store/log/types';
import { buildMessage } from '@/helpers/log-message-helper';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';

import { PageHeaderLoadingAction, PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

export const PILL_TYPES_STATUS = {
    OLD_NULL: 'danger',
    DIFFERENT: 'danger',
    NEW_NULL: 'danger',
    BOTH_EMPTY_OR_NULL: 'danger',
    SAME: 'info',
    BOTH_NULL: 'info',
};

export default {
    mixins: [antragMixin],
    components: {
        OptionMenu,
        PageHeaderTitleNavigation,
        AntragSidetext,
        EntitySelector,
        Table,
        GhostLoading,
		InputRadioBoxItem,
		DownloadLink,
    },
    data() {
        return {
            personId: '',
            loading: false,
            loadingList: false,
            data: null,
			idxCandidates: -1,
			backendData: null,
        }
    },
    mounted() {
        const personId = this.$route.query?.personId || '';
        this.getStammdaten(personId);
    },
    computed: {
        ...mapGetters({
            antraege: ANTRAG_TYPES.GETTERS.ANTRAEGE,
            antraegeData: ANTRAG_TYPES.GETTERS.ANTRAEGE_DATA,
        }),
        headerActions() {
            return [
                PageHeaderLoadingAction('UPLOAD', this.uploadBtnTxt, () => this.loading).withDisabled(() => !this.hasChanges),
                PageHeaderSimpleAction('OPEN-E-FONDS', 'eFonds24 Kundenverwaltung').withDisabled(() => !this.kundeRegistered),
            ];
        },
        personsCombo() {
            return this.data?.persons || [];
        },
        headers() {
            return {
                lockedLeft: [
                    PillColumn("status", "Status", 100, 0.4),
                    TextColumn("feld", "Feld"),
                    this.kundeRegistered ? TextColumn("oldValue", "alter Wert") : null,
                    TextColumn("newValue", "neuer Wert"),
                ],
            };
        },
        rows() {
            return this.data?.stammdaten?.map(row => {
                return {
                    ...row,
                    status: {
                        label: PILL_TYPES_STATUS[row.status] === 'danger' ? 'geändert' : 'ok',
                        type: PILL_TYPES_STATUS[row.status],
                    },
                }
            }) ||  [];
        },
        hasChanges() {
			return this.personId && (this.idxCandidates != -1 || this.rows?.some(row => row.status?.type === 'danger'));
        },
        accessRights() {
            return this.data?.accessRights;
        },
        uploadBtnTxt() {
			return this.kundeRegistered ? 'Daten übermitteln' + (this.idxCandidates == -1 ? '' : ', Kunde verknüpfen') : 'anlegen';
        },
        kundeRegistered() {
            return this.data?.guid;
		},
		candidates() {
			return this.data?.candidates || [];
        }
    },
    methods: {
		updateGuid(idx) {
			this.idxCandidates = idx;
			this.updateValues();
		},
		updateValues() {
			let data = JSON.parse(JSON.stringify(this.backendData)) ;
			this.personId = data?.personId;
			if (this.idxCandidates > -1 && data.candidates?.length > this.idxCandidates) {
				data.stammdaten = data.candidates[this.idxCandidates].stammdaten;
				data.guid = data.candidates[this.idxCandidates].guid;
			}
            this.data = data;
        },
        uploadData() {
            const payload = Object.assign(this.data || {}, { personId: this.personId });
			if (payload.candidates) {
				delete payload.candidates;
			}
			if (this.idxCandidates != -1) {
				payload.neueZuordnung = true;
			}
            this.$store.dispatch(ANTRAG_EFONDS_TYPES.ACTIONS.UPLOAD_STAMMDATEN, payload).then(data => {
                if (data?.errorStr) {
                    this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(data.errorStr, 'danger'));
                } else {
					this.idxCandidates = -1;
					this.backendData = data;
					this.updateValues();
                }
            })
            .catch(error => {
                this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error, 'danger'))
            });
        },
        async openEFonds() {
            if (this.kundeRegistered) {
                this.loading = true;
                await this.$store.dispatch(ANTRAG_EFONDS_TYPES.ACTIONS.GET_EFONDS_LINK_STAMMDATEN, 
                    { guid: this.data?.guid || '',  callbackUrl: window.location.href});
                this.loading = false;
            }
        },
        getStammdaten(personId) {
            this.loadingList = true;
            this.$store.dispatch(ANTRAG_EFONDS_TYPES.ACTIONS.GET_STAMMDATEN, { personId: personId || '' }).then(data => {
                if (data?.errorStr) {
                    this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(data.errorStr, 'danger'));
                } 
				this.idxCandidates = -1;
				this.backendData = data;
				this.updateValues();
            })
            .catch(error => {
                this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error, 'danger'))
            })
            .finally(() => this.loadingList = false);
		},
		getLabel(idx) {
			return `Kunde verknüpfen mit dem Kunden-ID ${this.candidates[idx].guid} in ${this.data.efondsName}`;
		},
		getTitel(idx) {
			if (idx == -1) {
				return this.candidates.length ? `Neu anlegen in ${this.data.efondsName}` : ``;
        }
			return `Vergleich Kundendaten vs. ${this.data.efondsName} Kunden-ID ${this.candidates[idx].guid}`;
		},
		getLinkText(idx) {
			return `Kunde-ID: ${this.candidates[idx].guid} in ${this.data.efondsName} System anzeigen`;
		},
    }
}
</script>

<style>

</style>