<template>
  <BaseModal
    ref="modal"
    modalTitle="neue Gesellschaft anlegen"
    size="sm"
    @onConfirmButton="save()"
  >
    <template #default>
      <InputField label="Name" v-model="form.gesellName" validateUntouched autofocus />
    </template>
  </BaseModal>
</template>

<script>
import MAKLER34F_TYPES from '@/store/makler34f/types';

import BaseModal from '@/components/core/BaseModal.vue';
import InputField from '@/components/core/forms/InputField.vue';

import validator from '@/mixins/validator/index';
import { required, } from '@/mixins/validator/rules';

export default {
  mixins: [validator],
  props: {
    gesell34fTyp: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      form: {
        gesellName: '',
      },
    };
  },
  methods: {
    init() {
      this.form = {
        gesellName: '',
      };
    },
    open() {
      const { gesell34fTyp } = this;
      if(!gesell34fTyp) return;

      this.$refs.modal.open();
      this.$nextTick(() => this.init());
    },
    close() {
      this.$refs.modal.close();
    },
    save() {
      const { gesell34fTyp } = this;
      if(!gesell34fTyp) return;

      this.$store.dispatch(MAKLER34F_TYPES.ACTIONS.SAVE_GESELLSCHAFT, { 
        ...this.form,
        gesell34fTyp,
      });
    },
  },
  validators: {
    form: {
      gesellName: [required()],
    },
  },
  components: {
    BaseModal,
    InputField,
  },
}
</script>
