var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.customerDataEdited
    ? _c(
        "div",
        [
          _vm.rows && _vm.rows.length
            ? _c("FlexibleList", {
                attrs: {
                  rows: _vm.rows,
                  linkInFirstRow: _vm.isEditable,
                  pageSize: _vm.isCustomerOnly ? 0 : 5,
                  showWeiterLink: ""
                },
                on: {
                  onRowClick: _vm.onRowClick,
                  onNavigate: _vm.navigateToCard
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function(row) {
                        return [_vm._v(" " + _vm._s(row.bankName) + " ")]
                      }
                    },
                    {
                      key: "value",
                      fn: function(row) {
                        return [_vm._v(" " + _vm._s(row.iban || "-") + " ")]
                      }
                    }
                  ],
                  null,
                  false,
                  1497872298
                )
              })
            : _c("NoData", { attrs: { noIcon: "" } })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }