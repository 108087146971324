var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.vertragsdatenFieldsDefinition
    ? _c(
        "div",
        [
          _vm.eigebundeneWertpapiere.length
            ? _c("FlexibleList", {
                attrs: { rows: _vm.eigebundeneWertpapiere, showThirdRow: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function(row) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(row.wertpapiername || row.anlageart) +
                              " "
                          )
                        ]
                      }
                    },
                    {
                      key: "value",
                      fn: function(row) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("currency")(
                                  row.fondswertEuro || row.fondswert
                                )
                              ) +
                              " "
                          )
                        ]
                      }
                    },
                    {
                      key: "thirdRow",
                      fn: function(row) {
                        return [
                          _vm._v(" " + _vm._s(row.isin || row.anlageart) + " ")
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2648843894
                )
              })
            : _vm._e(),
          _vm.rueckkaufswerte.length
            ? _c("FlexibleList", {
                attrs: { rows: _vm.rueckkaufswerte },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function(row) {
                        return [_vm._v(" " + _vm._s(row.jahr) + " ")]
                      }
                    },
                    {
                      key: "value",
                      fn: function(row) {
                        return [
                          _vm._v(
                            " " + _vm._s(_vm._f("currency")(row.betrag)) + " "
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2918175434
                )
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }