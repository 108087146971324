<template>
    <div>
        <Nachhaltigkeit :values="anlegerprofil && anlegerprofil.nachhaltigkeit" 
            @updateStore="updateStoreData" :isDisabledProp="isDisabled"/>
    </div>
</template>

<script>
import Nachhaltigkeit from '@/components/anlegerprofil/Nachhaltigkeit.vue';
import anlegerprofilMixin from '@/mixins/anlegerprofil/anlegerprofil-mixin.js';

export default {
    mixins: [anlegerprofilMixin],
    components: {
        Nachhaltigkeit,

    },
    methods: {
        updateStoreData(data) {
            if (data?.componentId) {
                this.updateStore('nachhaltigkeit', data.componentId, data.value);
            }
        }
    }
}
</script>

<style>

</style>