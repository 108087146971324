<template>
  <BaseButton v-bind="$attrs" :disabled="disabled" @click="onClick()">{{ label }}</BaseButton>
</template>

<script>
import BaseButton from '@/components/core/BaseButton.vue';

export default {
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    BaseButton
  },
  methods: {
    onClick() {
      this.$confirmModal({
        title: this.config?.title,
        message: this.config?.message,
        labelButtonCancel: this.config?.labelButtonCancel,
        labelButtonConfirm: this.config?.labelButtonConfirm,
      })
      .then(() => this.$emit('confirmed'));
    },
  },
};
</script>

<style scoped>
</style>
