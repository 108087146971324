var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("label", [_vm._v(_vm._s(_vm.label))]),
      _c("div", { staticClass: "row px-2" }, [
        _c(
          "div",
          {
            staticClass: "col-12 col-md-8 col-lg-10 basisfonds",
            class: { invalid: _vm.isInvalid }
          },
          [_vm._v(" " + _vm._s(_vm.getFondStr) + " ")]
        ),
        _c("div", { staticClass: "col-12 col-md-4 col-lg-2 pt-2" }, [
          _c("div", { staticClass: "d-flex" }, [
            _c(
              "a",
              {
                class: { disabled: _vm.disabled || _vm.disableBtn },
                attrs: { title: _vm.addButtonTitle },
                on: { click: _vm.openFondsfinder }
              },
              [_c("ph-pencil", { staticClass: "pl-2", attrs: { size: 32 } })],
              1
            ),
            _vm.hasDelete
              ? _c(
                  "a",
                  {
                    class: {
                      disabled: _vm.disabled || _vm.disableBtn || !_vm.isin
                    },
                    attrs: { title: "Position löschen" },
                    on: {
                      click: function($event) {
                        return _vm.$refs.removeModal.open()
                      }
                    }
                  },
                  [
                    _c("ph-trash", { staticClass: "pl-2", attrs: { size: 32 } })
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "a",
              {
                class: { disabled: !_vm.isin },
                attrs: { title: "Fondsinfos" },
                on: {
                  click: function($event) {
                    _vm.isin && _vm.gotoFondsinfo(_vm.isin)
                  }
                }
              },
              [_c("ph-info", { staticClass: "pl-2", attrs: { size: 32 } })],
              1
            ),
            _vm.hasFactsheet
              ? _c(
                  "a",
                  {
                    class: { disabled: !_vm.isin },
                    attrs: { title: "Factsheet" },
                    on: {
                      click: function($event) {
                        _vm.isin && _vm.openFactsheet()
                      }
                    }
                  },
                  [_c("ph-file", { staticClass: "pl-2", attrs: { size: 32 } })],
                  1
                )
              : _vm._e()
          ])
        ])
      ]),
      _c(
        "BaseModal",
        {
          ref: "removeModal",
          attrs: {
            labelButtonConfirm: "Position Entfernen",
            labelButtonCancel: "Abbrechen"
          },
          on: { onConfirmButton: _vm.removePosition },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [_vm._v(" Position entfernen? ")]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", [
            _vm._v(" Soll die Position "),
            _c("b", [
              _vm._v(
                _vm._s(_vm.wertpapiername) + " (ISIN " + _vm._s(_vm.isin) + ") "
              )
            ]),
            _vm._v(" wirklich entfernt werden? ")
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }