var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "modal",
    attrs: { modalTitle: "Passwort ändern", showDefaultButtons: false },
    on: { onCloseButton: _vm.close },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function() {
          return [
            _c("InputField", {
              attrs: {
                label: "Aktuelles Passwort",
                type: "password",
                allowPlainPassword: ""
              },
              model: {
                value: _vm.oldPassword,
                callback: function($$v) {
                  _vm.oldPassword = $$v
                },
                expression: "oldPassword"
              }
            }),
            _c("InputField", {
              attrs: {
                label: "Neues Passwort",
                type: "password",
                allowPlainPassword: ""
              },
              model: {
                value: _vm.newPassword,
                callback: function($$v) {
                  _vm.newPassword = $$v
                },
                expression: "newPassword"
              }
            }),
            _c("InputField", {
              attrs: {
                label: "Neues Passwort bestätigen",
                type: "password",
                allowPlainPassword: "",
                validateUntouched: ""
              },
              model: {
                value: _vm.newPasswordRetype,
                callback: function($$v) {
                  _vm.newPasswordRetype = $$v
                },
                expression: "newPasswordRetype"
              }
            }),
            _c("PasswordPolicy"),
            _vm.errorStr
              ? _c("div", { staticClass: "color-danger mt-2" }, [
                  _vm._v(_vm._s(_vm.errorStr))
                ])
              : _vm._e()
          ]
        },
        proxy: true
      },
      {
        key: "footer",
        fn: function() {
          return [
            _c(
              "BaseButton",
              {
                attrs: {
                  disabled: _vm.isFormInvalid || _vm.changing,
                  animated: _vm.changing,
                  isPrimary: ""
                },
                on: { click: _vm.changePassword }
              },
              [_vm._v("Passwort ändern")]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }