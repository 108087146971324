<template>
  <div class="box__container-wrap">
    <div class="box__container-rows">
      <template v-if="rows.length">
        <OverviewTable
          v-if="!$isSmallScreen"
          :records="rows"
          @title="openCustomerNewTab"
        >
          <template #subject="row"> 
            <DownloadLink
              v-if="!row.mobileTableContext"
              :title="row.subject"
              :disabled="!row.commId"
              downloadServicePath="/downloadFax"
              :filename="row.subject.includes('.pdf') ? row.subject : `${row.subject}.pdf`"
              :queryParams="{
                  id: row.commId,
              }"
            />
          </template>
        </OverviewTable>
        <Table 
          v-else
          :headers="headers"
          :rows="rows"
          rowId="rowId"
          :exportConfig="{roottext: 'Postfach'}"
          :mobileConfig="{title: 'title', headers: ['subject', 'date']}"
          @click-name="openCustomerNewTab"
        />
      </template>

      <GhostLoading v-else-if="loading" type="table" :config="{ table: { rows: 5 } }"/>
      <NoData :noIcon="$isSmallScreen" v-else/>
    </div>
    
    <div class="bottom-row text-right mt-3">
      <router-link :to="`/communication/postfach/${routeStep}`">
        weitere...
      </router-link>
    </div>
  </div>
</template>

<script>
import postfachMixin from '@/views/communication/postfach/widgets/postfach-mixin.js';
import Table from "@/components/table2/Table.vue";
import DownloadLink from '@/components/core/download/DownloadLink.vue'
import { TextColumn, DateColumn } from "@/components/table2/table_util.js";

export default {
  mixins: [postfachMixin],
  components: {
    Table,
    DownloadLink,
  },
  data() {
    return {
      routeStep: 'faxes',
    }
  },
  computed: {
    headers() {
        return {
            lockedLeft: [
              TextColumn("title", "Name"),
              DateColumn("date", "Datum"),
              TextColumn("subject", "Betreff"),
            ],
            center: [],
            lockedRight: []
        };
    },
    rows() {
      return this.records.map(record => {
        return {
          ...record,
          title: record.name || "[Unbekannt]",
          subject: record.subject || "[kein Betreff]",
          isTitleLink: this.canOpenCustomer(record),
          isSubjectLink: !!record.commId,
          downloadLinkOptions: {
            downloadServicePath: "/downloadFax",
            filename: record.subject.includes('.pdf') ? record.subject : `${record.subject}.pdf`,
            queryParams: {
              id: record.commId,
            },
          },
        }
      })
    },
  },
}
</script>