<template>
  <div>
    <BaseModal 
      size='xl'
      ref="EditableFrameModal"
      modalTitle="Inhalt bearbeiten"
      :confirmDisabled="!component && !templateComponentList.length"
      @onConfirmButton="onConfirmModal()"
      @close="templateValue = null"
    >
      <div v-if="modus === 'new'">
        <div class="input-forms__label-content">Templates</div>
        <ComboBox
          @change="changeTemplate"
          :value="templateValue"
          :values="templateValues"/>
      </div>
      <div v-for="editableComponent in editableComponentList" :key="editableComponent.componentId">
        <DynamicComponent
          :editableComponent="editableComponent"
        />
      </div>
    </BaseModal>
  </div>
</template>

<script>
import DYNAMIC_DOCUMENT_TYPES from '@/store/dynamicDocument/types';
import LOG_TYPES from '@/store/log/types';
import { mapGetters } from 'vuex'
import BaseModal from "@/components/core/BaseModal.vue";
import { buildMessage } from "@/helpers/log-message-helper";
import DynamicComponent from '@/components/dynamicDocument/DynamicComponent.vue'; 
import ComboBox from '@/components/core/forms/ComboBox.vue';

function replaceSpaceToNbsp(content) {
  if(!content) return content;

  const findExtraTagSpace = /(<\w+)\s+/gi;
  const findSpaceWithinTag = /(\s+?(?=<|\s))/gi;
  return content
    .replace(findExtraTagSpace, '$1 ')
    .replace(findSpaceWithinTag, '&nbsp;');
}

export default {
  mixins: [],
  components: {
    BaseModal,
    DynamicComponent,
    ComboBox,
  },
  data() {
    return {
      modus: null,
      component: null,
      templateValue: null,
      editableComponentList: [],
      editableComponentListToSave: [],
      templateComponentList: [],
    }
  },
  computed: {
    ...mapGetters({
      document: DYNAMIC_DOCUMENT_TYPES.GETTERS.SELECTED_DYNAMIC_DOCUMENT,
    }),
    templateValues() {
      return this.document && this.document.templates && this.document.templates.map(x => ({
          label: x.label || 'Unbenannt',
          value: x.key
      }))
    },
  },
  methods: {
    changeTemplate(event) {
      this.templateValue = event
      this.editableComponentList = []
      this.editableComponentListToSave = []
      this.templateComponentList = []


      var template = JSON.parse(JSON.stringify(this.document.templates.find(x => x.key === event)))

      if (template && template.hasOwnPage && template.htmlComponentList.length) {
        template.htmlComponentList.forEach(component => this.templateComponentList.push(JSON.parse(JSON.stringify(component))))
      } else if (template) {
        this.component = template.htmlComponent
        if (template.htmlComponent.editable) {
          this.findEditableComponents(template.htmlComponent)
        }
      } else {
        // Template not found
        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Template not found', 'primary'))
      }
    },
    onConfirmModal() {
      this.$store.commit(DYNAMIC_DOCUMENT_TYPES.MUTATIONS.SET_DOCUMENT_HAS_CHANGED, true);

      // replace all attributes of the document components with the modified component
      this.editableComponentList.forEach((comp, index) => {
        this.modify(this.editableComponentListToSave[index], {
          ...comp,
          content: replaceSpaceToNbsp(comp.content),
        });
      })

      if (this.modus === 'new') {
        const routeStep = this.$route.params.step;
        if (this.templateComponentList.length) {
          this.templateComponentList.forEach((comp, index) => {
            comp.componentId = Date.now() + index
          }) 
          let newStepKey = this.document?.steps?.length + 1 || 1
          this.document.steps.push({stepKey: newStepKey.toString(), label: 'Seite ' + newStepKey.toString()})
          this.document.componentMap[newStepKey.toString()] = this.templateComponentList
          this.$router.push({path: `/intern/maklerauftrag/${this.$route.params.formId}/${this.$route.params.documentId}/${this.$route.params.version}/` + newStepKey, query: this.$route.query});
        } else if (this.document.componentMap[routeStep]) {
          // generate a new componentId (we need a unique dummy id for the SortableList in DynamicDocumentComponentList)
          this.component.componentId = this.document.componentMap[routeStep].length + Date.now()
          // adds the component to the beginning of the componentList of our document
          this.document.componentMap[routeStep].unshift(this.component)
        }
      }

      // reload is needed to generate the htmlView of the component
      this.$store.dispatch(DYNAMIC_DOCUMENT_TYPES.ACTIONS.RELOAD_DYNAMIC_DOCUMENT);
      
    },

    // if we want to keep the reference to an object we have to replace all of it's attributes instead of the object itself
    modify(obj, newObj) {

      Object.keys(obj).forEach(function(key) {
        delete obj[key];
      });

      Object.keys(newObj).forEach(function(key) {
        obj[key] = newObj[key];
      });
      
    },
    open(component) {
      this.editableComponentList = []
      this.editableComponentListToSave = []
      this.component = component

      this.$store.dispatch(DYNAMIC_DOCUMENT_TYPES.ACTIONS.RETRIEVE_PLACEHOLDERS);
      if (component) {
        this.modus = null;
        this.findEditableComponents(component)
      } else {
        this.modus = 'new'
      }
      this.$refs.EditableFrameModal.open();
    },
    findEditableComponents(component) {
      if (component.htmlCardType || ((component.content || component.content === "") && !component.htmlCardType && !component.label && !component.value) && component.editable) {
        this.editableComponentList.push(JSON.parse(JSON.stringify(component)))
        this.editableComponentListToSave.push(component)
      }
      if (component.htmlComponents && component.htmlComponents.length) {
        for (var comp of component.htmlComponents) {
          this.findEditableComponents(comp)
        }
      }
    },
  },

}
</script>