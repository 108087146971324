var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Einstellungen bearbeiten",
          actions: _vm.headerActions
        },
        on: {
          "action-ADD": function($event) {
            return _vm.$router.push("edit/new")
          }
        }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("div", { staticClass: "box__title" }, [
            _vm._v("Einstellungen bearbeiten")
          ]),
          _vm.loading
            ? _c("GhostLoading", { attrs: { type: "table" } })
            : _c("Table", {
                attrs: { headers: _vm.tableHeaders, rows: _vm.tableRows },
                on: {
                  "click-label": _vm.openEditConfig,
                  "action-DELETE": _vm.openRemoveConfigModal,
                  "action-COPY": _vm.openCopyConfigDefinitionModal
                }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }