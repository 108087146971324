import BERATUNGSMAPPE_TYPES from './types';

export default {
  [BERATUNGSMAPPE_TYPES.GETTERS.BERATUNG_VERSICHERUNG_LIST_DATA](state) {
    return state.beratungVersicherungenList;
  },
  [BERATUNGSMAPPE_TYPES.GETTERS.BERATUNG_VERSICHERUNG_CARDS](state) {
    return state.beratungVersicherungenCards;
  },
  [BERATUNGSMAPPE_TYPES.GETTERS.BERATUNG_VERSICHERUNG_ACTIONS](state) {
    return state.beratungsmappeActions;
  },
  [BERATUNGSMAPPE_TYPES.GETTERS.EXECUTE_ACTION_RESULT](state) {
    return state.executeActionResult;
  },
  [BERATUNGSMAPPE_TYPES.GETTERS.LAST_SAVED_ANTRAGS_POSITION](state) {
    return state.lastSavedAntragsPosition;
  },  
  [BERATUNGSMAPPE_TYPES.GETTERS.LAST_DELETED_ANTRAGS_POSITION](state) {
    return state.lastDeletedAntragsPosition;
  },  
  [BERATUNGSMAPPE_TYPES.GETTERS.INSURANCES_CUSTOMER](state) {
    return state.insurancesCustomer;
  },  
  [BERATUNGSMAPPE_TYPES.GETTERS.FINANZPORTAL24_ONLINE_INSURANCELINK](state) {
    return state.finanzportal24OnlineInsuranceLink;
  },  
  [BERATUNGSMAPPE_TYPES.GETTERS.FINANZPORTAL24_ONLINE_FILELINK](state) {
    return state.finanzportal24OnlineFileLink;
  },  
  [BERATUNGSMAPPE_TYPES.GETTERS.FINANZPORTAL24_OFFLINE_INSURANCEFPXFILE](state) {
    return state.finanzportal24OfflineInsuranceFPXFile;
  },  
  [BERATUNGSMAPPE_TYPES.GETTERS.DOKUMENT_SMART_MSC](state) {
    return state.dokumentSmartMSC;
  },       
  
  
  
  

}