var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "event-body" },
    [
      _c(
        "BaseModal",
        {
          ref: "modal",
          attrs: {
            showCloseButton: true,
            showDefaultButtons: false,
            autoClose: false,
            size: "full"
          },
          on: {
            onCloseButton: function($event) {
              return _vm.close()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [_vm._v(" " + _vm._s(_vm.title) + " ")]
              },
              proxy: true
            },
            {
              key: "footer",
              fn: function() {
                return [
                  _vm.dat && _vm.dat.killable
                    ? _c(
                        "BaseButton",
                        {
                          attrs: {
                            isPrimary: "",
                            disabled:
                              _vm.loading ||
                              !_vm.dat ||
                              !_vm.dat.killable ||
                              !_vm.dat.sid
                          },
                          on: { click: _vm.openCponf }
                        },
                        [_vm._v("Kill Session!!!")]
                      )
                    : _vm._e(),
                  _c(
                    "BaseButton",
                    { attrs: { isSecondary: "" }, on: { click: _vm.close } },
                    [_vm._v("close")]
                  ),
                  _c(
                    "BaseButton",
                    {
                      attrs: {
                        disabled: _vm.loading || !_vm.dat || !_vm.dat.sid,
                        isSecondary: ""
                      },
                      on: { click: _vm.loadData }
                    },
                    [_vm._v("reload")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("BoxContainer", [
            _vm.dat && _vm.dat.sid
              ? _c(
                  "div",
                  [
                    _c("InputField", {
                      attrs: { label: "sid", disabled: true },
                      model: {
                        value: _vm.dat.sid,
                        callback: function($$v) {
                          _vm.$set(_vm.dat, "sid", $$v)
                        },
                        expression: "dat.sid"
                      }
                    }),
                    _c("InputField", {
                      attrs: { label: "serial", disabled: true },
                      model: {
                        value: _vm.dat.serial,
                        callback: function($$v) {
                          _vm.$set(_vm.dat, "serial", $$v)
                        },
                        expression: "dat.serial"
                      }
                    }),
                    _c("InputField", {
                      attrs: { label: "client id", disabled: true },
                      model: {
                        value: _vm.dat.ident,
                        callback: function($$v) {
                          _vm.$set(_vm.dat, "ident", $$v)
                        },
                        expression: "dat.ident"
                      }
                    }),
                    _c("InputField", {
                      attrs: { label: "status", disabled: true },
                      model: {
                        value: _vm.dat.status,
                        callback: function($$v) {
                          _vm.$set(_vm.dat, "status", $$v)
                        },
                        expression: "dat.status"
                      }
                    }),
                    _c("InputField", {
                      attrs: { label: "login", disabled: true },
                      model: {
                        value: _vm.dat.logontime,
                        callback: function($$v) {
                          _vm.$set(_vm.dat, "logontime", $$v)
                        },
                        expression: "dat.logontime"
                      }
                    }),
                    _c("InputField", {
                      attrs: { label: "Machine", disabled: true },
                      model: {
                        value: _vm.dat.machine,
                        callback: function($$v) {
                          _vm.$set(_vm.dat, "machine", $$v)
                        },
                        expression: "dat.machine"
                      }
                    }),
                    _c("InputField", {
                      attrs: { label: "Os User", disabled: true },
                      model: {
                        value: _vm.dat.osuser,
                        callback: function($$v) {
                          _vm.$set(_vm.dat, "osuser", $$v)
                        },
                        expression: "dat.osuser"
                      }
                    }),
                    _c("InputField", {
                      attrs: { label: "Db User", disabled: true },
                      model: {
                        value: _vm.dat.userId,
                        callback: function($$v) {
                          _vm.$set(_vm.dat, "userId", $$v)
                        },
                        expression: "dat.userId"
                      }
                    }),
                    _c("InputField", {
                      attrs: { label: "Programm", disabled: true },
                      model: {
                        value: _vm.dat.programm,
                        callback: function($$v) {
                          _vm.$set(_vm.dat, "programm", $$v)
                        },
                        expression: "dat.programm"
                      }
                    }),
                    _vm._v(" " + _vm._s(_vm.dat.sqlText)),
                    _c("br")
                  ],
                  1
                )
              : _vm._e()
          ])
        ],
        1
      ),
      _c("BaseModal", {
        ref: "conf",
        attrs: { labelButtonConfirm: "yes", labelButtonCancel: "no" },
        on: { onConfirmButton: _vm.doIt },
        scopedSlots: _vm._u([
          {
            key: "modalTitle",
            fn: function() {
              return [
                _vm._v(
                  " Are you sure you want to kill +" + _vm._s(_vm.title) + " ? "
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }