<template>
  <div class="broker-access-management__container">
    <div class="box__container">
      <div>
        <div class="box__title">Account</div>

        <GhostLoading v-if="loading" type="custom" class="my-0">
          <Block type="button" :width="140" />
        </GhostLoading>
        <BaseButton 
          v-else
          :isPrimary="loginStatus.accountLocked" 
          :isSecondary="!loginStatus.accountLocked" 
          :disabled="saving[ACCOUNT_LOCKED]"
          :animated="saving[ACCOUNT_LOCKED]"
          @click="toggleAccountLocked">Account {{ loginStatus.accountLocked ? 'freischalten' : 'sperren' }}</BaseButton>

        <div class="text-small mt-1">Hinweis: der Benutzer kann sich erst anmelden, wenn er freigeschaltet ist</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LOGIN_TYPES from '@/store/login/types';

import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import BaseButton from '@/components/core/BaseButton.vue';

const ACCOUNT_LOCKED = 'ACCOUNT_LOCKED';

export default {
  components: { 
    GhostLoading,
    BaseButton,
    Block,
  },
  data() {
    return {
      ACCOUNT_LOCKED,
      loading: false,
      saving: {},
    };
  },
  computed: {
    ...mapGetters({
      loginStatus: LOGIN_TYPES.GETTERS.LOGIN_STATUS,
    }),
  },
  methods: {
    async findLoginStatus() {
      try {
        this.loading = true;
        await this.$store.dispatch(LOGIN_TYPES.ACTIONS.FIND_LOGIN_STATUS);
      } catch(e) {
        // empty block
      } finally {
        this.loading = false;
      }
    },
    async toggleAccountLocked() {
      try {
        this.$set(this.saving, ACCOUNT_LOCKED, true);
        const { accountLocked } = this.loginStatus;
        await this.$store.dispatch(LOGIN_TYPES.ACTIONS.SAVE_LOGIN_STATUS, { accountLocked });
      } finally {
        this.$set(this.saving, ACCOUNT_LOCKED, false);
      }
    },
  },
  mounted() {
    this.findLoginStatus();
  },
}
</script>
