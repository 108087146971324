<template>
  <div class="box__container">
    <BaseButtonsContainer>
      <BaseButton @click="updateFromProhyp()" :disabled="!hasProhypRequiredField || isEditable || loading">Daten von Prohyp abholen</BaseButton>
      <BaseButton @click="sendToProhyp()" :disabled="!hasProhypRequiredField || !isEditable || loading">Zu Prohyp schicken</BaseButton>
      <BaseButton @click="openProhypPortal()" :disabled="loading">Prohyp Portal</BaseButton>
    </BaseButtonsContainer>

    <div>
      <InputField
        label="Prohyp Antrag Nummer"
        :isComponentHalfSize="true"
        v-model="form.ehypAntragsnr"
        :disabled="!isEditable"
        @change="dataChanged()"
      />
      <ComboBox
        label="Art des Objektes"
        :firstEmpty="true"
        :isComponentHalfSize="true"
        :values="prohypCombos.combitypeCombo"
        v-model="form.combiType"
        :disabled="!isEditable"
        validateUntouched
        @change="dataChangedCombo('combiType', $event)"
      />
      <ComboBox
        label="Variante"
        :firstEmpty="true"
        :isComponentHalfSize="true"
        :values="prohypCombos.subtypeCombo"
        v-model="form.subType"
        :disabled="!isEditable"
        validateUntouched
        @change="dataChangedCombo('subType', $event)"
      />
      <ComboBox
        label="Nutzung der Immobilie"
        :firstEmpty="true"
        :isComponentHalfSize="true"
        :values="prohypCombos.utilizationCombo"
        v-model="form.utilization"
        :disabled="!isEditable"
        validateUntouched
        @change="dataChangedCombo('utilization', $event)"
      />
      <div>Stellt der Kunde die Finanzierungsanfrage zusammen mit einem weiteren Darlehensnehmer, bitte erweitern Sie seine Kundendaten vorher.</div>
      <ComboBox
        label="Weiterer Darlehensnehmer"
        :isComponentHalfSize="true"
        :values="prohypCombos.partnerCombo"
        v-model="form.partner"
        :disabled="!isEditable"
        @change="dataChangedCombo('partner', $event)"
      />
      <InputToggleSwitch
        label="Im Rahmen dieser Finanzierungsanfrage fallen weitere Honorare oder Spesen an"
        :inLineLabel="true"
        v-model="form.feesExtra"
        :disabled="!isEditable"
        @input="dataChanged()"
      />
      <EntitySelectorPersonPicker
        label="1. Antragssteller"
        :isComponentHalfSize="true"
        :values="prohypCombos.person1Combo"
        v-model="form.personId1"
        :disabled="!isEditable"
        @change="dataChangedCombo('personId1', $event)"
      />
      <EntitySelectorPersonPicker
        label="2. Antragssteller"
        :isComponentHalfSize="true"
        :values="prohypCombos.person2Combo"
        v-model="form.personId2"
        :disabled="!isEditable || isPartnerInvalid"
        @change="dataChangedCombo('personId2', $event)"
      />

      <div>
        <div class="box__title">Vertragsinformationen</div>
        <InputField
          label="Eingereicht"
          :isComponentHalfSize="true"
          :disabled="true"
          v-model="form.created"
        />
        <InputField
          label="Status"
          :isComponentHalfSize="true"
          :disabled="true"
          v-model="form.status"
        />
      </div>
    </div>

    <BaseModal
      ref="prohypResponse"
      :modalTitle="prohypResponse && prohypResponse.title"
      labelButtonConfirm="OK"
      :showCancelButton="false"
      @close="prohypResponse = null;"
    >
      <template #default>
        {{ prohypResponse && prohypResponse.message }}
      </template>
    </BaseModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CREDIT_TYPES from '@/store/credit/types';

import BaseButton from '@/components/core/BaseButton.vue';
import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import EntitySelectorPersonPicker from '@/components/core/forms/EntitySelectorPersonPicker.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import BaseButtonsContainer from '@/components/core/BaseButtonsContainer.vue';

import validator from '@/mixins/validator';
import { required } from '@/mixins/validator/rules';

const PARTNER_NEIN = 'Nein';


export default {
  mixins: [validator],
  data() {
    return {
      loading: false,
      form: {
        ehypAntragsnr: null,
        combiType: null,
        subType: null,
        utilization: null,
        partner: null,
        feesExtra: null,
        personId1: null,
        personId2: null,
        created: null,
        status: null,
      },
      prohypResponse: null,
    };
  },
  computed: {
    ...mapGetters({
      creditId: CREDIT_TYPES.GETTERS.SELECTED_CREDIT_ID,
      prohypCombos: CREDIT_TYPES.GETTERS.PROHYP_COMBOS,
      prohyp: CREDIT_TYPES.GETTERS.PROHYP,
      prohypEdited: CREDIT_TYPES.GETTERS.PROHYP_EDITED,
    }),
    isEditable() {
      return !this.prohyp.ehypAntragsnr;
    },
    hasProhypRequiredField() {
      return this.form?.combiType?.length > 0 && this.form?.subType?.length > 0 && this.form?.utilization?.length > 0;
    },
    isPartnerInvalid() {
      return !this.form?.partner || this.form?.partner === PARTNER_NEIN;
    },
  },
  watch: {
    prohyp: {
      handler() {
        this.updateData();
      },
      immediate: true,
    },
  },
  methods: {
    init() {
      this.$store.dispatch(CREDIT_TYPES.ACTIONS.GET_PROHYP_COMBOS);
    },
    async updateFromProhyp() {
      this.loading = true;
      await this.save();
      const response = await this.$store.dispatch(CREDIT_TYPES.ACTIONS.UPDATE_FROM_PROHYP);
      await this.$store.dispatch(CREDIT_TYPES.ACTIONS.GET_CREDIT);
      this.loading = false;

      this.prohypResponse = {
        ...response,
        title: 'Daten von Prohyp abholen',
      };

      this.openProhypResponseModal();
    },
    async sendToProhyp() {
      this.loading = true;
      await this.save();
      const response = await this.$store.dispatch(CREDIT_TYPES.ACTIONS.SEND_TO_PROHYP);
      await this.$store.dispatch(CREDIT_TYPES.ACTIONS.GET_CREDIT);
      this.loading = false;

      this.prohypResponse = response;
      this.openProhypResponseModal();
    },
    async openProhypPortal() {
      this.loading = true;
      const prohypPortalUrl = await this.$store.dispatch(CREDIT_TYPES.ACTIONS.GET_PROHYP_PORTAL_URL);
      this.loading = false;
      window.open(prohypPortalUrl);
    },
    openProhypResponseModal() {
      if(this.prohypResponse?.message) {
        this.$refs.prohypResponse?.open();
      }
    },
    async save() {
      await this.$store.dispatch(CREDIT_TYPES.ACTIONS.SAVE_PROHYP);
    },
    updateData() {
      Object.keys(this.form).forEach(key => {
        if(key in this.prohypEdited) {
          this.form[key] = this.prohypEdited[key];
        } else if(key in this.prohyp) {
          this.form[key] = this.prohyp[key];
        }
      });
    },
    isChanged(group) {
      return Object.keys(group)
        .filter(fieldName => this.prohyp[fieldName] !== group[fieldName])
        .length > 0;
    },
    dataChanged() {
      const groupData = this.isChanged(this.form) ? this.form : null;

      if (groupData !== null || groupData !== undefined) {
        const payload = {
          ...this.prohyp, 
          ...groupData,
          kreditId: this.creditId,
        };

        this.$store.commit(CREDIT_TYPES.MUTATIONS.APPEND_PROHYP_EDITED, payload);
      }
    },
    dataChangedCombo(field, value) {
      this.form[field] = value;
      this.dataChanged();
    },
  },
  mounted() {
    this.init();
  },
  async beforeDestroy() {
    await this.save();
  },
  validators: {
    form: {
      combiType: [required()],
      subType: [required()],
      utilization: [required()],
    },
  },
  components: {
    BaseButton,
    InputField,
    ComboBox,
    InputToggleSwitch,
    EntitySelectorPersonPicker,
    BaseModal,
    BaseButtonsContainer,
  },
}
</script>
