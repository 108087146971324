import actions from './actions';
import mutations from './mutations';
import getters from './getters';

/**
 * mutations: synchronous operation responsible for change the state
 * actions: asynchronous operation responsible for call external resources and commit mutations 
 */
export function getInitialState() {
  return {
    risikopruefungList: [],
    statusFFBDepotList: [],
    filtersStatusFFBDepot: {},
    filtersSteuerlicheDaten: {},
    steuerlicheDaten: [],
    steuerVerlustList: [],
    gesellschaftenAuswahl: [],
    altersvorsorge: {},
    courtageInvest: {anzeige: '', rows: [], loaded: 0},
    courtageVers: {anzeige: '', rows: [], loaded: 0},
  }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}