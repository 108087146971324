var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: "Virtuelle Unterdepots", actions: _vm.headerActions },
        on: {
          "action-ADD": function($event) {
            return _vm.newVD()
          }
        }
      }),
      _vm.list.loading
        ? _c(
            "GhostLoading",
            { attrs: { title: _vm.TABLE_TITLE, useBoxContainer: "" } },
            [_c("Block", { attrs: { height: "200" } })],
            1
          )
        : !_vm.rows.length
        ? _c("NoData", {
            attrs: {
              title: _vm.TABLE_TITLE,
              content: "Es wurden keine virtuellen Depots definiert",
              useBoxContainer: ""
            }
          })
        : _vm.rows.length
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("Table", {
                attrs: {
                  title: _vm.TABLE_TITLE,
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowId: "id",
                  hidePagination: "",
                  mobileConfig: {}
                },
                on: {
                  "action-DELETE": _vm.deleteVD,
                  "action-EDIT": _vm.showVD,
                  "click-vdname": function($event) {
                    return _vm.showVD($event)
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("BaseModal", {
        ref: "modalRef",
        attrs: {
          modalTitle: _vm.deleteText,
          labelButtonConfirm: "Ja",
          labelButtonCancel: "Nein"
        },
        on: { onConfirmButton: _vm.confitmDeleteVD }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }