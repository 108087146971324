var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.isFA
        ? _c("InputCheckBoxGroup", {
            attrs: {
              disabled: _vm.isDisabled || _vm.isRefusedPage,
              title: "Zulagenberechtigung bzw. Interesse an der Nutzung",
              values: [
                {
                  label: "Arbeitnehmersparzulage",
                  path: "employeeSavingAllowance"
                },
                { label: "Riester-Rente", path: "riesterPension" },
                { label: "Rürup-Rente", path: "ruerupPension" },
                { label: "Wohnungsbauprämie", path: "buildingSubsidy" },
                { label: "bAV", path: "fedInsuranceComm" }
              ]
            },
            on: {
              checkBoxToggle: function($event) {
                return _vm.updateStoreCheckBoxGroup("finance", $event)
              }
            },
            model: {
              value: _vm.form,
              callback: function($$v) {
                _vm.form = $$v
              },
              expression: "form"
            }
          })
        : _vm._e(),
      _vm.schiene !== "WEALTH_KONZEPT"
        ? _c("InputField", {
            attrs: {
              label:
                "Wieviele Personen sind während des Anlagezeitraumes auf Ihre finanzielle Unterstützung angewiesen (z.B. nichtarbeitende Ehe-/Lebenspartner, Kinder, zu pflegende Angehörige etc.)?",
              isComponentHalfSize: "",
              disabled: _vm.isDisabled || _vm.isRefusedPage
            },
            on: {
              change: function($event) {
                return _vm.updateStore("finance", "numberPerson", $event)
              }
            },
            model: {
              value: _vm.numberPerson,
              callback: function($$v) {
                _vm.numberPerson = $$v
              },
              expression: "numberPerson"
            }
          })
        : _vm._e(),
      _vm.schiene !== "WEALTH_KONZEPT"
        ? _c("InputField", {
            attrs: {
              label:
                "Bei Minderjährigen: Der Anlagebetrag stammt aus einer Zuwendung / Schenkung von (z.B. Eltern, Großeltern, Tante):",
              isComponentHalfSize: "",
              disabled: _vm.isDisabled || _vm.isRefusedPage
            },
            on: {
              change: function($event) {
                return _vm.updateStore("finance", "carePerson", $event)
              }
            },
            model: {
              value: _vm.carePerson,
              callback: function($$v) {
                _vm.carePerson = $$v
              },
              expression: "carePerson"
            }
          })
        : _vm._e(),
      _c("InputTextArea", {
        attrs: {
          label: "Zusätzliche Angaben zu den finanziellen Verhältnissen",
          isComponentHalfSize: "",
          disabled: _vm.isDisabled || _vm.isRefusedPage
        },
        on: {
          change: function($event) {
            return _vm.updateStore("finance", "annotationFinance", $event)
          }
        },
        model: {
          value: _vm.annotationFinance,
          callback: function($$v) {
            _vm.annotationFinance = $$v
          },
          expression: "annotationFinance"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }