/**
 * Browser Virtual Keyboard Helper
 * - emits an event when the virtual keyboard state changes (open/close)
 */

const VIRTUAL_KEYBOARD_STATE_CHANGED_EVENT_NAME = 'custom.virtualKeyboardStateChanged';
const PADDING_HEIGHT_SIZE = 100; // prevents calculation issues when toggling the address bar size

let widthOnFocus = -1;
let heightOnFocus = -1;
let isVirtualKeyboardOpen = false;

function dispatchStateChangedEvent(isOpen = false) {
  const event = new CustomEvent(VIRTUAL_KEYBOARD_STATE_CHANGED_EVENT_NAME, {
    detail: {
      isOpen,
    },
  });

  window.dispatchEvent(event);
}

function detectOpen() {
  if (isVirtualKeyboardOpen) return;

  const currentHeight = visualViewport.height + PADDING_HEIGHT_SIZE;
  if (currentHeight < heightOnFocus) {
    isVirtualKeyboardOpen = true;
    dispatchStateChangedEvent(isVirtualKeyboardOpen);
  }
}

function detectClose() {
  if (!isVirtualKeyboardOpen) return;

  const currentHeight = visualViewport.height + PADDING_HEIGHT_SIZE;
  if (currentHeight >= heightOnFocus || widthOnFocus !== window.innerWidth) {
    isVirtualKeyboardOpen = false;
    dispatchStateChangedEvent(isVirtualKeyboardOpen);
  }
}

function forceClose() {
  removeDetectOpenListeners();
  removeDetectCloseListeners();

  document.activeElement?.blur?.(); // force to close the virtual keyboard

  widthOnFocus = -1;
  heightOnFocus = -1;

  if (isVirtualKeyboardOpen) {
    isVirtualKeyboardOpen = false;
    dispatchStateChangedEvent(isVirtualKeyboardOpen);
  }
}

function removeDetectOpenListeners() {
  visualViewport.removeEventListener('resize', detectOpen);
  window.removeEventListener('resize', detectOpen);
}

function removeDetectCloseListeners() {
  visualViewport.removeEventListener('resize', detectClose);
  window.removeEventListener('resize', detectClose);
}

function onBlur(event) {
  // remove blur listener
  const blurredEl = event.target;
  if (blurredEl) {
    blurredEl.removeEventListener('blur', onBlur);
  }
}

function onFocusIn(event) {
  removeDetectOpenListeners();
  removeDetectCloseListeners();

  widthOnFocus = window.innerWidth;
  heightOnFocus = heightOnFocus < 0 ? window.innerHeight : heightOnFocus;

  // add detect virtual keyboard listeners
  visualViewport.addEventListener('resize', detectOpen);
  window.addEventListener('resize', detectOpen);

  visualViewport.addEventListener('resize', detectClose);
  window.addEventListener('resize', detectClose);

  // add blur listener
  const focusedEl = event.target;
  if (focusedEl) {
    focusedEl.removeEventListener('focus', onFocusIn);
    focusedEl.removeEventListener('blur', onBlur);

    focusedEl.addEventListener('focus', onFocusIn); // detect refocus
    focusedEl.addEventListener('blur', onBlur);
  }
}

function onOrientationChange() {
  if (widthOnFocus !== window.innerWidth) {
    forceClose();
  }
}

function onVisibilityChange() {
  if(document.visibilityState !== 'visible' && isVirtualKeyboardOpen) {
    forceClose();
  }
}

document.addEventListener('focusin', onFocusIn);

visualViewport.addEventListener('resize', onOrientationChange);
window.addEventListener('resize', onOrientationChange);

window.addEventListener('visibilitychange', onVisibilityChange);
