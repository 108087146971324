import MULTIBANKING_TYPES from './types';
import Vue from 'vue'
import { getInitialState } from './index';

export default {
  [MULTIBANKING_TYPES.MUTATIONS.LOAD_ACCOUNT_DATA_SUCCESS](state, data) {
    if (!data) {
      return
    }
    Vue.set(state, 'accounts', data.accounts)
    Vue.set(state, 'dailyAccountsHistory', data.dailyAccountsHistory)
    Vue.set(state, 'accountsSum', data.accountsSum)
    Vue.set(state, 'userSettings', data.userSettings)
  },

  [MULTIBANKING_TYPES.MUTATIONS.UPDATE_ACCOUNT](state, {accountId, externalId, displayName}) {
    const foundAccount = state.accounts.findIndex(account => account.id === accountId);

    if (foundAccount) {
      Vue.set(foundAccount, 'externalId', externalId);
      Vue.set(foundAccount, 'displayName', displayName?.trim() ? displayName : foundAccount.title);
    }
  },

  [MULTIBANKING_TYPES.MUTATIONS.HIDE_ACCOUNT](state, {externalId, providerId}) {
    state.accounts = state.accounts.filter(account => {
      return account.externalId != externalId || account.providerId != providerId;
    });
  },

  [MULTIBANKING_TYPES.MUTATIONS.LOAD_PARAMS](state, parameters) {
    Vue.set(state, 'parameters', parameters)
  },

  [MULTIBANKING_TYPES.MUTATIONS.SAVE_USER_SETTINGS](state, update) {
    for (let prop in update)
      if (Object.prototype.hasOwnProperty.call(update, prop))
        Vue.set(state.userSettings, prop, update[prop])
  },

  [MULTIBANKING_TYPES.MUTATIONS.LOAD_TRANSACTIONS](state, transactions) {
    Vue.set(state, 'transactions', transactions)
  },

  [MULTIBANKING_TYPES.MUTATIONS.SAVE_TRANSACTION_COMMENT](state, {transactionId, comment}) {
    state.transactions.forEach(transaction => {
      if (transaction.id == transactionId) {
        transaction.customerComment = comment
      }
    })
  },

  [MULTIBANKING_TYPES.MUTATIONS.LOAD_TRANSACTION_CATEGORIES](state, {categories, byAccountExternalId}) {
    Vue.set(state, 'byAccountExternalId', byAccountExternalId)
    Vue.set(state, 'categories', categories)
  },
  
  [MULTIBANKING_TYPES.MUTATIONS.LOAD_TRANSACTION_CATEGORIES_COMBO](state, {categories, byAccountExternalId}) {
    Vue.set(state.availableCategories, 'byAccountExternalId', byAccountExternalId)
    Vue.set(state.availableCategories, 'categories', categories)
  },
  
  [MULTIBANKING_TYPES.MUTATIONS.LOAD_TRANSACTION_SPLIT](state, {transactionId, splits}) {
    if (splits)
      Vue.set(state.transactionSplits, '' + transactionId, splits)
    else
      Vue.delete(state.transactionSplits, '' + transactionId)
  },

  [MULTIBANKING_TYPES.MUTATIONS.SAVE_TRANSACTION_CATEGORY](state, {transactionId, category}) {
    state.transactions.forEach(transaction => {
      if (transaction.id === transactionId) {
        transaction.category = category
      }
    })
  },

  [MULTIBANKING_TYPES.MUTATIONS.UPDATE_CATEGORY](state, {categoryKey, budget, displayName}) {
    state.categories.forEach(category => {
      if (category.category == categoryKey) {
        category.budget = budget
        category.displayName = displayName
      }
    })
  },

  [MULTIBANKING_TYPES.MUTATIONS.LOAD_SAVINGS_POTENTIAL](state, data) {
    Vue.set(state, 'savingsPotential', data.savingsPotential)
    Vue.set(state, 'savingsPotentialByAccount', data.savingsPotentialByAccount)
  },

  [MULTIBANKING_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },

  [MULTIBANKING_TYPES.MUTATIONS.SET_LOADED](state) {
    Vue.set(state, 'dataLoaded', true)
  },

  [MULTIBANKING_TYPES.MUTATIONS.RESET_LOADED](state) {
    Vue.set(state, 'dataLoaded', false)
  },

  [MULTIBANKING_TYPES.MUTATIONS.LOAD_ACCOUNT_SUMMARY](state, payload) {
    Vue.set(state, 'summary', payload)
  },

  [MULTIBANKING_TYPES.MUTATIONS.UPDATE_LOADED_STATUS](state, payload) {
    Vue.set(state, 'loaded', { ...state.loaded, ...payload })
  },

  [MULTIBANKING_TYPES.MUTATIONS.UPDATE_FILTERS](state, payload) {
    Vue.set(state, 'filters', { ...state.filters, ...payload })
  }
}