var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "lower-div-container" }, [
    _c("div", { staticClass: "impr-disc-daten" }, [
      _c(
        "div",
        { staticClass: "impressum" },
        [
          _c("router-link", { attrs: { to: "/cms/impressum" } }, [
            _vm._v("Impressum")
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "disclaimer" },
        [
          _c("router-link", { attrs: { to: "/cms/disclaimer" } }, [
            _vm._v("Disclaimer")
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "datenschutz" },
        [
          _c("router-link", { attrs: { to: "/cms/datenschutz" } }, [
            _vm._v("Datenschutz")
          ])
        ],
        1
      )
    ]),
    _c("div", { staticClass: "makler-name" }, [_vm._v(_vm._s(_vm.content))])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }