var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "editDocumentModal",
      attrs: {
        modalTitle: "Dokument bearbeiten",
        labelButtonConfirm: "Speichern"
      },
      on: {
        onConfirmButton: function($event) {
          return _vm.confirmButton()
        }
      }
    },
    [
      _c("InputField", {
        attrs: { label: "Datei", value: _vm.data.filename, disabled: "" },
        on: {
          input: function($event) {
            return _vm.update("filename", $event)
          }
        }
      }),
      _c("InputField", {
        attrs: { label: "Bezeichnung", value: _vm.data.bezeichnung },
        on: {
          input: function($event) {
            return _vm.update("bezeichnung", $event)
          }
        }
      }),
      _c("InputField", {
        attrs: { label: "Bemerkung", value: _vm.data.bemerkung },
        on: {
          input: function($event) {
            return _vm.update("bemerkung", $event)
          }
        }
      }),
      _c("DatePickerField", {
        attrs: {
          label: "Stand",
          dateFormat: "DD.MM.YYYY",
          value: _vm.data.stand,
          isValueAsString: ""
        },
        on: {
          input: function($event) {
            return _vm.update("stand", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }