<template>
  <div class="cards-organizer">
    <div class="column" v-if="!localData || !localData.length">
      <GhostLoading type="table" />
    </div>

    <div class="column" v-if="!localData || !localData.length">
      <GhostLoading type="table" />
    </div>
    
    <div class="column" v-if="!localData || !localData.length">
      <GhostLoading type="table" />
    </div>  
    
    <div
      class="column"
      v-for="(column, columnIndex) in localData"
      :key="columnIndex"
      :class="{ dragging: isDragging }"
      @dragover.prevent
      @dragenter="dragEnterColumn($event, columnIndex, -1)"
      @drop="dropCard($event, columnIndex, -1)"
    >
      <Card
        v-for="(card, cardIndex) in getVisibleCards(column)"
        :key="card.id"
        :columnIndex="columnIndex"
        :cardIndex="getCardIndex(columnIndex, cardIndex)"
        :card="card"
        :actions="card.actions"
        @dragstart="dragCard($event, columnIndex, getCardIndex(columnIndex, cardIndex))"
        @dragenter.prevent="dragEnterCard($event, columnIndex, getCardIndex(columnIndex, cardIndex))"
        @dragover.prevent="allowDrop($event)"
        @drop="dropCard($event, columnIndex, getCardIndex(columnIndex, cardIndex))"
        @executeAction="$emit('executeAction', $event)"
        @remove="removeCard"

      >
        <template v-slot:default>
          <slot name="card" :card="card"></slot>
        </template>
      </Card>
      <!-- Render placeholder for preview -->
      <div
        v-if="placeholder && placeholder.columnIndex === columnIndex && placeholder.cardIndex === -1"
        class="placeholder"
        :style="placeholderStyle"
      ></div>
    </div>
  </div>
</template>

<script>
import Card from './Card.vue';
import FC_CONFIG_TYPES from '@/store/fcConfig/types';
import FC_CONFIG from '@/configs/fcConfig.js';
import { mapGetters } from 'vuex';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';

export default {
  name: 'CardsOrganizer',
  components: {
    Card,
    GhostLoading
  },
  props: {
    columns: {
      type: Array,
      required: true
    },
    id: {
      type: String,
      required: true,
      validator: (value) => {
        const notEmpty = !!value && !!value.trim();
        return notEmpty;
      },
    },    
  },
  watch: {
    columns(newValue) {
      this.localData = [...newValue];

      if (this.fcConfigCardsOverview) {
        // console.log("fcConfigCardsOverview", this.fcConfigCardsOverview);

        const config = this.fcConfigCardsOverview[this.id];
        const contentToParse = config?.content;
        if (contentToParse) {
          // console.log("contentToParse?.content", contentToParse?.content);
          this.rearrangeColumns(JSON.parse(contentToParse));
        }

      }
    },
    fcConfigCardsOverview: {
      handler(newValue) {
        const config =  newValue[this.id];
        const contentToParse = config?.content;
        if (contentToParse) {
          this.rearrangeColumns(JSON.parse(contentToParse));
        }
      },
      deep: true,
    },       
  },  
  data() {
    return {
      draggedCard: null,
      isDragging: false,
      placeholder: null, // To track the placeholder position
      placeholderSize: { width: '0px', height: '0px' }, // To track the size of the placeholder,
      localData: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      fcConfigCardsOverview: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_CARDS_OVERVIEW,
    }),
    cardsOverviewConfig() {
      return this.id in this.fcConfigCardsOverview && this.fcConfigCardsOverview[this.id] || null;
    },    
    placeholderStyle() {
      return {
        width: this.placeholderSize.width,
        height: this.placeholderSize.height
      };
    },
    savedCardOrder() {
      const contentToParse = this.fcConfigCardsOverview[this.id]?.content;
      if (contentToParse) {
        return JSON.parse(contentToParse);
      }

      return null;
    },
    cardDetails() {
      let details = [];
      this.localData.forEach((column, columnIndex) => {
        column.forEach((card, cardIndex) => {
          if (card) {
            details.push({
              id: card.id,
              cardIndex: cardIndex,
              columnIndex: columnIndex,
              key: card.key,
              removed: card.removed,
              removable: card.removable,
              visible: card.visible,
              title: card.title,
            });

          }
        });
      });
      return details;
    }    
  },
  methods: {
    async removeCard(whatCard) {
      // Flatten the array and find the object with the given key
      const flatData = this.localData.flat();
      const target = flatData.find(item => item.key === whatCard.key);

      // Set the "removed" property to false if the object is found
      if (target) {
        // target.removed = true;
        this.$set(target, 'removed', true);
      }

      await this.saveConfig();

      this.$emit('remove', whatCard);
    },
    getVisibleCards(column) {
      return column.filter(card => card && card.visible && !!card?.removed == false);
    },
    getCardIndex(columnIndex, visibleIndex) {
      const column = this.localData[columnIndex];
      let visibleCount = 0;
      for (let i = 0; i < column.length; i++) {
        if (column[i].visible  && !!column[i].removed == false) {
          if (visibleCount === visibleIndex) {
            return i;
          }
          visibleCount++;
        }
      }
      return -1;
    },
    dragCard(event, columnIndex, cardIndex) {
      this.$emit('draggingCard');

      this.draggedCard = { columnIndex, cardIndex };
      this.isDragging = true;
      this.placeholder = { columnIndex, cardIndex }; // Set initial placeholder
      event.dataTransfer.effectAllowed = 'move';

      // Set the placeholder size to match the dragged card
      const draggedElement = event.target;
      const { width, height } = draggedElement.getBoundingClientRect();
      this.placeholderSize = { width: `${width}px`, height: `${height}px` };
    },
    dragEnterCard(event, columnIndex, cardIndex) {
      this.placeholder = { columnIndex, cardIndex }; // Update placeholder position
    },
    dragEnterColumn(event, columnIndex) {
      this.placeholder = { columnIndex, cardIndex: -1 }; // Update placeholder position for empty column
    },
    allowDrop(event) {
      event.preventDefault();
    },
    async dropCard(event, columnIndex, cardIndex) {
      if (this.draggedCard !== null) {
        // Add the new card to the column, ensuring the structure is correct
        // if (!this.localData[columnIndex]) {
        //     this.localData[columnIndex] = [];  // Initialize column if it does not exist
        // }

        const { columnIndex: fromColumnIndex, cardIndex: fromCardIndex } = this.draggedCard;
        const card = this.localData[fromColumnIndex][fromCardIndex];

        // Determine the target index within the column
        const toCardIndex = cardIndex >= 0 ? cardIndex : this.localData[columnIndex].length;

        this.$emit('update-columns', {
          fromColumnIndex,
          fromCardIndex,
          toColumnIndex: columnIndex,
          toCardIndex,
          card
        });

        this.updateColumns({
          fromColumnIndex,
          fromCardIndex,
          toColumnIndex: columnIndex,
          toCardIndex,
          card
        });

        this.placeholder = null; // Reset placeholder
        this.placeholderSize = { width: '0px', height: '0px' }; // Reset placeholder size
        this.draggedCard = null;
        this.isDragging = false;
        await this.saveConfig();

      }
      this.$emit('droppedCard');
    },
    updateColumns({ fromColumnIndex, fromCardIndex, toColumnIndex, toCardIndex, card }) {

      // console.log("updateColumns -> CardsOrganizer");

      // Remove the card from the original column
      this.localData[fromColumnIndex].splice(fromCardIndex, 1);

      // If dropping within the same column
      if (fromColumnIndex === toColumnIndex) {
        this.localData[toColumnIndex].splice(toCardIndex, 0, card);
      } else {
        // Add the card to the new column
        this.localData[toColumnIndex].push(card);
      }

      // Trigger a reactivity update
      this.localData = [...this.localData];
    },
    async loadConfig(forceReload = false) {
      if (!this.id) {
        return ;
      }

      // console.log("loadConfig called");

      const payload = {
        configId: this.id,
        configType: FC_CONFIG.CARD_OVERVIEW_CONFIG,
        forceReload,
      };

      // console.log("loadConfig payload", payload);

      this.loading = true;
      await this.$store.dispatch(FC_CONFIG_TYPES.ACTIONS.LOAD_FC_CONFIG, payload);
      this.loading = false;
      // console.log("loadConfig finished", this.loading);
    },    
    async saveConfig() {
      if(!this.id) {
        return;
      }

      const payload = {
        configId: this.id,
        configType: FC_CONFIG.CARD_OVERVIEW_CONFIG,
        content: this.contentToSave(this.cardDetails),
        userLevel: null,
      };

      await this.$store.dispatch(FC_CONFIG_TYPES.ACTIONS.SAVE_FC_CONFIG, payload);

      this.$emit('saved', [ ...this.cardDetails ]);
    },
    contentToSave(cardDetails) {
      if(!cardDetails) {
        return '';
      }

      // console.log(JSON.stringify(cardDetails));

      return JSON.stringify(cardDetails);
    },
    rearrangeColumns(cardsOrder) {
      if (!cardsOrder || !this.localData?.length) {
        return;
      }

      // Create an array to store the new structure of columns
      let sortedColumns = [];

      // Initialize the array with empty sub-arrays for each column
      this.localData.forEach(() => sortedColumns.push([]));

      // Iterate over the cardsOrder to place each card in the newColumns structure
      cardsOrder.forEach(cardOrder => {
          const { id, cardIndex, columnIndex, removed } = cardOrder;

          // Find the card in the current columns structure
          const card = this.localData.flat().find(card => card?.id === id);

          if (card) {
            this.$set(card, 'removed', removed);

            // Place the card in the correct position in sortedColumns
            sortedColumns[columnIndex][cardIndex] = card;

          }
      });

      // Remove undefined elements and fill gaps if any due to unassigned indices
      sortedColumns = sortedColumns.map(column => column.filter(card => card));

      // Apply the new cards order
      this.localData = [...sortedColumns];


      // console.log("localData", this.localData);
      // console.log("cardsOrder", cardsOrder);
    }           
  },
  async mounted() {
    // if (!this.cardsOverviewConfig) {
      // console.log("mounted called")
      await this.loadConfig(true);
    // }
    
  },  
};
</script>

<style scoped>
.cards-organizer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.column {
  flex: 1;
  min-width: 500px;
  box-sizing: border-box;
  margin-right: 8px;
}

.column:last-of-type {
  margin-right: 0px;
}

.column.dragging {
  margin: 4px;
  border: 1px solid #ccc;
  padding: 10px;
}
.placeholder {
  max-width: calc(100% - 2px);;
  border: 2px dashed #ccc;
  margin: 10px 0;
  display: inline-block;
}

@media screen and (max-width: 767px) {
  .column {
     min-width: 100%;
  }
}

/* @media screen and (max-width: 1870px) { */
  .card-row-value {
     flex: unset !important;
  }
/* } */

</style>
