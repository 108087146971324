import CONFIG_DEFINITION_TYPES from './types';
import Vue from 'vue';
import { getInitialState } from './index';

export default {
  [CONFIG_DEFINITION_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },

  [CONFIG_DEFINITION_TYPES.MUTATIONS.GET_ALL_CONFIGS_DEFINITION_SUCCESS](state, payload) {
    Vue.set(state, 'allConfigsDefinition', [ ...payload, ]);
  },

  [CONFIG_DEFINITION_TYPES.MUTATIONS.GET_ALL_CONFIGS_AVAILABLE_SUCCESS](state, payload) {
    Vue.set(state, 'allConfigsAvailable', payload)
  },

  [CONFIG_DEFINITION_TYPES.MUTATIONS.GET_CONFIGS_DEFINITION_SUCCESS](state, payload) {
    Vue.set(state, 'configsDefinition', payload)
  },

  [CONFIG_DEFINITION_TYPES.MUTATIONS.GET_CONFIG_DEFINITION_SUCCESS](state, payload) {
    if(!payload) return;

    Vue.set(state, 'configDefinitionSelected', { ...payload, });
  },

  [CONFIG_DEFINITION_TYPES.MUTATIONS.RESET_CONFIG_DEFINITION_SELECTED](state) {
    Vue.set(state, 'configDefinitionSelected', null);
  },

  [CONFIG_DEFINITION_TYPES.MUTATIONS.SET_CONFIG_DEFINITION_EDITED](state, payload) {
    if(!payload) return;

    Vue.set(state, 'configDefinitionEdited', { ...payload, });
  },

  [CONFIG_DEFINITION_TYPES.MUTATIONS.RESET_CONFIG_DEFINITION_EDITED](state) {
    Vue.set(state, 'configDefinitionEdited', null);
  },

  [CONFIG_DEFINITION_TYPES.MUTATIONS.FIND_CONFIG_PERMISSION_SUCCESS](state, payload) {
    Vue.set(state, 'configPermissionList', [ ...payload || [], ]);
  },

  [CONFIG_DEFINITION_TYPES.MUTATIONS.RESET_CONFIG_PERMISSION_LIST](state) {
    Vue.set(state, 'configPermissionList', null);
  },

  [CONFIG_DEFINITION_TYPES.MUTATIONS.SET_CHANGED_CONFIGS](state,payload) {
    Vue.set(state, 'changedConfigs', {
      ...state.changedConfigs,
      ...payload
    });
  },

  [CONFIG_DEFINITION_TYPES.MUTATIONS.RESET_CHANGED_CONFIGS](state) {
    Vue.set(state, 'changedConfigs', {});
  },

  [CONFIG_DEFINITION_TYPES.MUTATIONS.SET_CONFIG_DEFINITION_OPTIONS_MENU](state, payload) {
    Vue.set(state, 'configDefinitionOptionMenu', [ ...payload, ]);
  },

}
