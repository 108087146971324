var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container-wrap account-list-card__container" },
    [
      _c(
        "div",
        { staticClass: "box__container-rows" },
        [
          _c("Table", {
            staticClass: "account-list-card__table",
            attrs: {
              headers: _vm.headers,
              rows: _vm.rows,
              rowId: "displayName",
              hidePagination: ""
            },
            on: { "action-AUTHENTICATE_ACCOUNT": _vm.authenticateAccount },
            scopedSlots: _vm._u([
              {
                key: "displayName",
                fn: function(row) {
                  return [
                    !_vm.isMobileNativeContext
                      ? _c("AccountTypeIcon", {
                          attrs: { accountType: row.accountType }
                        })
                      : _vm._e(),
                    _c(
                      "span",
                      { class: { "mobile-font": _vm.isMobileNativeContext } },
                      [_vm._v(_vm._s(row.displayName))]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      !_vm.isMobileNativeContext
        ? _c(
            "div",
            { staticClass: "bottom-row text-right mt-3" },
            [
              _c(
                "router-link",
                { attrs: { to: "/home/multibanking-account" } },
                [_vm._v(" Übersicht ")]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "BaseModal",
        {
          ref: "dataWillBeUpdated",
          attrs: {
            modalTitle: "Kontoaktualisierung",
            showCancelButton: false,
            labelButtonConfirm: "Schließen"
          }
        },
        [_vm._v(" Daten werden aktualisiert ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }