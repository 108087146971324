import MAKLER_ANTRAG_TYPES from './types';
import axios from 'axios';
import { viewDocument } from '@/components/core/download/DownloadLink.vue';

const config = {
  defaultSpinner: true
};
export const prepareParams = (payload) => Object.entries(payload).map(([key, val]) => `${key}=${val}`).join('&');
export default {

  async [MAKLER_ANTRAG_TYPES.ACTIONS.LOAD_MAKLERANTRAG]({ commit, rootState }, payload) {
    let response;
    if (payload.paramId) {
      response = await axios.get(`${rootState.core.apiAddress}/formulareService/loadChecklisteById?paramId=${payload?.paramId}`, config);
    }
    else {
      response = await axios.get(`${rootState.core.apiAddress}/formulareService/loadCheckliste?jahr=${payload?.jahr}`, config);
    }

    if (response && response.data) {
      commit(MAKLER_ANTRAG_TYPES.MUTATIONS.LOAD_MAKLERANTRAG_SUCCESS, { ...response })
    } else {
      commit(MAKLER_ANTRAG_TYPES.MUTATIONS.RESET_JAHRESCHECKLISTE, payload)
    }

  },
  async [MAKLER_ANTRAG_TYPES.ACTIONS.GET_AUSWERTUNG]({ commit, rootState }, params) {
    let response = await axios.get(`${rootState.core.apiAddress}/formulareService/getAuswertung`,
      { params: params }, config);
    if (response && response.data) {
      commit(MAKLER_ANTRAG_TYPES.MUTATIONS.LOAD_AUSWERTUNG_SUCCESS, { ...response })
    } else {
      //TODO: Error handling
    }
  },
  async [MAKLER_ANTRAG_TYPES.ACTIONS.GET_AUSWERTUNG_AS_EXCEL]({ rootState }, params) {
    const response = await axios.post(rootState.core.apiAddress + '/formulareService/getAuswertungExcel', null, { params: params }, config);
    if (response?.data?.data) {
      const { data, fileName, contentType } = response.data;
      viewDocument({
        data,
        filename: fileName,
        contentType,
      });
    } else if (response?.data?.errorMessage) {
      dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response?.data?.errorMessage, 'danger'));
    }
  },

  async [MAKLER_ANTRAG_TYPES.ACTIONS.SAVE_JAHRESCHECKLISTE]({ commit, rootState }, payload) {
    const response = await axios.post(rootState.core.apiAddress + '/formulareService/saveCheckliste', payload, config);
    if (response && response.status == 200) {
      commit(MAKLER_ANTRAG_TYPES.MUTATIONS.LOAD_MAKLERANTRAG_SUCCESS, { ...response })
    }
  },

  async [MAKLER_ANTRAG_TYPES.ACTIONS.LOAD_CONFIG_DATA]({ commit, rootState }) {
    const response = await axios.get(rootState.core.apiAddress + '/formulareService/getJahreschecklisteConfigData', config);
    if (response && response.status == 200) {
      commit(MAKLER_ANTRAG_TYPES.MUTATIONS.LOAD_CONFIG_DATA_SUCCESS, { ...response })
    }
  },

  [MAKLER_ANTRAG_TYPES.ACTIONS.OPEN_PDF]({ rootState }, payload) {
    return axios.post(rootState.core.apiAddress + '/formulareService/openPDF', payload, config);
  },

  async [MAKLER_ANTRAG_TYPES.ACTIONS.LOAD_CHECKLISTE_DOCS]({ commit, rootState }, payload) {
    const response = await axios.get(rootState.core.apiAddress + '/formulareService/getJahreschecklisteAttachments?paramId=' + payload, config)
    if (response && response.status == 200) {
      commit(MAKLER_ANTRAG_TYPES.MUTATIONS.LOAD_CHECKLISTE_DOCS_SUCCESS, { ...response })
    }
  },

  async [MAKLER_ANTRAG_TYPES.ACTIONS.GET_EXCEL_DATA]({ rootState }, payload) {
    const response = await axios.post(rootState.core.apiAddress + '/formulareService/getExcelData', payload, config);
    if (response?.data?.data) {
      const { data, fileName, contentType } = response.data;
      viewDocument({
        data,
        filename: fileName,
        contentType,
      });
    } else if (response?.data?.errorMessage) {
      dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response?.data?.errorMessage, 'danger'));
    }
  }

}
