import { render, staticRenderFns } from "./WertpapierAuswahlPositionsMaxCount.vue?vue&type=template&id=18cc494c&scoped=true&"
import script from "./WertpapierAuswahlPositionsMaxCount.vue?vue&type=script&lang=js&"
export * from "./WertpapierAuswahlPositionsMaxCount.vue?vue&type=script&lang=js&"
import style0 from "./WertpapierAuswahlPositionsMaxCount.vue?vue&type=style&index=0&id=18cc494c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18cc494c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("P:\\workspaces\\fk0\\fc\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('18cc494c')) {
      api.createRecord('18cc494c', component.options)
    } else {
      api.reload('18cc494c', component.options)
    }
    module.hot.accept("./WertpapierAuswahlPositionsMaxCount.vue?vue&type=template&id=18cc494c&scoped=true&", function () {
      api.rerender('18cc494c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlPositionsMaxCount.vue"
export default component.exports