import { render, staticRenderFns } from "./DABNamensaenderungButton.vue?vue&type=template&id=556c9974&scoped=true&"
import script from "./DABNamensaenderungButton.vue?vue&type=script&lang=js&"
export * from "./DABNamensaenderungButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "556c9974",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("P:\\workspaces\\fk0\\fc\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('556c9974')) {
      api.createRecord('556c9974', component.options)
    } else {
      api.reload('556c9974', component.options)
    }
    module.hot.accept("./DABNamensaenderungButton.vue?vue&type=template&id=556c9974&scoped=true&", function () {
      api.rerender('556c9974', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/core/forms/antraege/DABNamensaenderungButton.vue"
export default component.exports