<template>
    <div>
        <div class="box__title">Bitte geben Sie die gewünschten Vermittlerdaten für den Nafi-Zugang ein</div>
            <InputField label="Firmenname 1" v-model="form.firmenname1" isComponentHalfSize @change="updateNafiStore('firmenname1', $event)" />
            <InputField label="Firmenname 2" v-model="form.firmenname2" isComponentHalfSize @change="updateNafiStore('firmenname2', $event)"/>
            <InputField label="Straße" v-model="form.strasse" isComponentHalfSize @change="updateNafiStore('strasse', $event)" />
            <InputField label="PLZ" v-model="form.plz" isComponentHalfSize @change="updateNafiStore('plz', $event)" />
            <InputField label="Ort" v-model="form.ort" isComponentHalfSize @change="updateNafiStore('ort', $event)" />
            <InputField label="Telefon 1" v-model="form.telefon1" isComponentHalfSize @change="updateNafiStore('telefon1', $event)" inputmode="tel"/>
            <InputField label="Telefon 2" v-model="form.telefon2" isComponentHalfSize @change="updateNafiStore('telefon2', $event)" inputmode="tel"/>
            <InputField label="Telefax" v-model="form.telefax" isComponentHalfSize @change="updateNafiStore('telefax', $event)" inputmode="tel"/>
            <InputField type="email" label="E-Mail" v-model="form.email" isComponentHalfSize @change="updateNafiStore('email', $event)" />
            <InputField label="Homepage" v-model="form.homepage" isComponentHalfSize @change="updateNafiStore('homepage', $event)" />
            <InputField type="number" label="Agenturnummer" v-model="form.agenturnr" isComponentHalfSize @change="updateNafiStore('agenturnr', $event)" />
            <InputField label="IHK-Nummer" v-model="form.ihknr" isComponentHalfSize @change="updateNafiStore('ihknr', $event)" />
    </div>
</template>

<script>
import InputField from '@/components/core/forms/InputField.vue';
import { email, formatPLZ, formatPhone } from "@/mixins/validator/rules";
import validator from "@/mixins/validator";
import mixin from '@/mixins/nafi/nafi-mixin.js';

export default {
    mixins: [validator, mixin],
    components: {
        InputField,
    },
    validators: {
        form: {
            plz: formatPLZ('DE'),
            telefon1: formatPhone(),
            telefon2: formatPhone(),
            telefax: formatPhone('Bitte geben Sie eine gültige Faxnummer ein'),
            email: email(),
        }
    },
    data() {
        return {
            form: {
                firmenname1: '',
                firmenname2: '',
                strasse: '',
                plz: '',
                ort: '',
                telefon1: '',
                telefon2: '',
                telefax: '',
                email: '',
                homepage: '',
                agenturnr: '',
                ihknr: '',
            },
        }
    },
    mounted() {
        this.initForm();
    },
}
</script>

<style>

</style>