<template>
  <div>
    <div>
      <div class="text-small edit" v-if="isBroker || isIntern" @click="edit">
        bearbeiten
        <ph-pencil-line title="Bearbeiten" :size="16" />
      </div>
      <div id="innercontent" >
      </div>
    </div>
    <BaseModal
      ref="EditableImageOnly"
      modalTitle="Bild hochladen"
      @onConfirmButton="onConfirmModal()"
      @onCancelButton="onCancelModal()"
    >
      <div class="markup_class">
        <ImagePicker :type="getType" />
      </div>
    </BaseModal>
  </div>
</template>
<script>
import { buildMessage } from "@/helpers/log-message-helper";
import CORE_TYPES from "@/store/core/types";
import CMS_TYPES from "@/store/cms/types";
import LOG_TYPES from '@/store/log/types';
import { mapGetters } from "vuex";
import BaseModal from "@/components/core/BaseModal.vue";
import ImagePicker from "@/components/cms/ImagePicker.vue";
import { PhPencilLine } from 'phosphor-vue';

export default {

  props: {
    type: {
      type: String,
      default: "",
      required: true
    },
    genus: {
      type: String,
      default: null,
      required: false
    },
    species: {
      type: String,
      default: null,
      required: false
    }
  },
  components: {
    BaseModal,
    ImagePicker,
    PhPencilLine,
  },
  computed: {
    ...mapGetters({
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      getInhalt: CMS_TYPES.GETTERS.GET_CMS_COMPONENTS,
      getComponentContent: CMS_TYPES.GETTERS.GET_CMS_COMPONENT_CURRENT_CONTENT,
      getComponentContentUpdated:
        CMS_TYPES.GETTERS.GET_CMS_COMPONENT_CONTENT_UPDATED
    }),

    getType() {
      return this.type;
    },
    getGenus() {
      return this.genus;
    },
    getSpecies() {
      return this.species;
    },
    getComputedInhalt() {
      if (!this.getInhalt) {
        return;
      }

      if (this.genus == null && this.species == null) {
        const found = this.getInhalt.find(x => x.type == this.getType);
        if (found) {
          return found.inhalt;
        }
      } else {
        const found = this.getInhalt.find(
          x =>
            x.type == this.getType &&
            x.genus == this.getGenus &&
            x.species == this.getSpecies
        );
        if (found) {
          return found.inhalt;
        }
      }
    
      return '';
    }
  },
  mounted() {
    this.setBackgroundImage( this.getComputedInhalt);
  },
  methods: {
    setBackgroundImage(content) {

      document.getElementById("innercontent").style.backgroundImage =
        'url("' + content+ '")';
    },
    edit: function() {
      this.$refs.EditableImageOnly.open();
    },
    onConfirmModal: function() {
      var myself = this;

      if (!this.getComponentContentUpdated) {
        this.cancel();
        this.$store.dispatch(
          LOG_TYPES.ACTIONS.ADD_MESSAGE,
          buildMessage("No change was made.")
        );
      } else {
        if (myself.getComponentContent.length > 20000000) {
          alert(
            "The content is too large. Perhaps you have inserted too many pictures or pictures of large size."
          );
        } else {
                       
          this.$store
            .dispatch(CMS_TYPES.ACTIONS.POST_CMS_COMPONENT_CONTENT, {
              type: myself.getType,
              inhalt: myself.getComponentContent,
              genus: myself.getGenus,
              species: myself.getSpecies
            })
            .then(              this.setBackgroundImage(myself.getComponentContent)
            );
        }
      }
    },
    onCancelModal: function() {}
  }
};
</script>
<style scoped>
#innercontent {
  background-size: 100%;
  width: 100%;
  height: 540px;
  background-repeat: no-repeat;
}
.edit {
  color: var(--color-danger);
  /* font-size:1.5em; */
  cursor: pointer;
}
.buttons_confirm_cancel {
  display: flex;
  justify-content: space-between;
}
</style>