import WERTPAPIERINFO_TYPES from './types';

export default {
  [WERTPAPIERINFO_TYPES.MUTATIONS.CONFIG](state, payload) {
    state.config.filter = payload;
    state.sessions.acts.forEach(act => {
      if ( state.sessions[act].filters === undefined ) {
        state.sessions[act].filters = [];
      }
      if (!state.sessions[act].phase) {
        state.sessions[act].phase = {};
      }
      if (!state.sessions[act].phase.wp_type) {
        state.sessions[act].phase.wp_type = state.config.filter['0'][0];
      }
    });
  },
  [WERTPAPIERINFO_TYPES.MUTATIONS.ADD_ACT](state, act) {
    if ( -1 === state.sessions.acts.indexOf(act)) {
      state.sessions.acts.push(act);
      state.sessions[act] = {
        filters: [],
        options: undefined,
        outcome: undefined,
        phase: {},
      };
      if (state.config.filter) {
        state.sessions[act].phase.wp_type = state.config.filter['0'][0];
      }
    }
  },
  [WERTPAPIERINFO_TYPES.MUTATIONS.RESET_ACT](state, act) {
    const maxCountRows = state.sessions[act]?.maxCountRows;
    state.sessions[act] = {
      filters: [],
      options: undefined,
      outcome: undefined,
      phase: {}
    };
    if (maxCountRows) {
      state.sessions[act].maxCountRows = maxCountRows;
    }
  },
  [WERTPAPIERINFO_TYPES.MUTATIONS.SET_ACT](state, payload) {
    const act = payload.act;
    const maxCountRows = state.sessions[act]?.maxCountRows;

    state.sessions = {
      ...state.sessions,
      [act]: {
        filters: payload.filters !== undefined ? payload.filters : state.sessions[act].filters,
        options: payload.options !== undefined ? payload.options : state.sessions[act].options,
        optionsParam: payload.optionsParam !== undefined ? payload.optionsParam : state.sessions[act].optionsParam,
        outcome: payload.outcome !== undefined ? payload.outcome : state.sessions[act].outcome,
        phase: payload.phase !== undefined ? payload.phase : state.sessions[act].phase,
        keys: payload.keys !== undefined ? payload.keys : state.sessions[act].keys,
      }
    }
    if (maxCountRows) {
      state.sessions[act].maxCountRows = maxCountRows;
    }
  },
  [WERTPAPIERINFO_TYPES.MUTATIONS.SET_WATCHLIST](state, payload) {
    state.watchliste = payload;
  },
}