import NAVIGATION_LOG_TYPES from './types';
import CORE_TYPES from '@/store/core/types';
import axios from 'axios';
import { BASE_AXIOS_CONFIG as config } from '@/configs/http-request-interceptor';
import { tabId } from '@/helpers/tab-id';

const TIMEOUT_SEND_QUEUE = 10_000;

export default {
  [NAVIGATION_LOG_TYPES.ACTIONS.ROUTE_CHANGE]({ dispatch, commit }, { from, to }) {
    if (from?.meta?.doNotLogRouteChange || to?.meta?.doNotLogRouteChange) {
      return;
    }

    const payload = {
      fromPath: from.fullPath,
      toPath: to.fullPath,
      tabId,
      logTimestamp: new Date(),
    };

    commit(NAVIGATION_LOG_TYPES.MUTATIONS.ADD_ROUTE_CHANGE_TO_QUEUE, payload)
    dispatch(NAVIGATION_LOG_TYPES.ACTIONS.SCHEDULE_SAVE_ROUTE_CHANGE);
  },
  [NAVIGATION_LOG_TYPES.ACTIONS.GLOBAL_SEARCH]({ getters, }, { searchTerm, target, type }) {
    const payload = {
      target,
      type,
      searchTerm,
      logTimestamp: new Date(),
    };

    axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/navigation_log/global_search`, payload, config);
  },

  [NAVIGATION_LOG_TYPES.ACTIONS.SCHEDULE_SAVE_ROUTE_CHANGE]({ commit, getters, state }) {
    if (state.nextScheduledSendRouteChange) {
      return;
    }

    const sendAllNavigationChange = () => {
      commit(NAVIGATION_LOG_TYPES.MUTATIONS.SAVE_SCHEDULED_SEND_ROUTE_CHANGE, null);

      const queue = [];
      commit(NAVIGATION_LOG_TYPES.MUTATIONS.TAKE_NAVIGATION_LOG_FROM_QUEUE, (q) => queue.push.apply(queue, q));

      if (queue.length) {
        axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/navigation_log/route_change`, queue, config)
          .catch(() => {
            commit(NAVIGATION_LOG_TYPES.MUTATIONS.ADD_ROUTE_CHANGE_TO_QUEUE, queue);
          });
      }
    }

    const nextScheduledSendRouteChange = setTimeout(sendAllNavigationChange, TIMEOUT_SEND_QUEUE);
    commit(NAVIGATION_LOG_TYPES.MUTATIONS.SAVE_SCHEDULED_SEND_ROUTE_CHANGE, nextScheduledSendRouteChange);
  }
}
