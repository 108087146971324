import HONORARE_TYPES from './types';
import CORE_TYPES from '../core/types';
import axios from 'axios';
import { buildMessage, } from '../../helpers/log-message-helper';
import * as utils from '../../helpers/commonfunctions';

export default {
  [HONORARE_TYPES.ACTIONS.CREATE_FEES]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/feeservice/createFees';
      
      const kundennummer = payload?.kundennummer;
      if (kundennummer) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'kundennummer', kundennummer);
      }

      const kundenname = payload?.kundenname;
      if (kundenname) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'kundenname', kundenname);
      }      

      const kundenvorname = payload?.kundenvorname;
      if (kundenvorname) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'kundenvorname', kundenvorname);
      }     

      const dateType = payload?.dateType;
      if (dateType) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'dateType', dateType);
      }      
      
      const dateFrom = payload?.dateFrom;
      if (dateFrom) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'dateFrom', dateFrom);
      }         

      const dateTo = payload?.dateTo;
      if (dateTo) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'dateTo', dateTo);
      }          
      
      const showSent = payload?.showSent || false;
      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'showSent', showSent);

      const showNoEmailSend = payload?.showNoEmailSend || false;
      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'showNoEmailSend', showNoEmailSend);

      const showManuell = payload?.showManuell || false;
      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'showManuell', showManuell);

      const showConfirmed = payload?.showConfirmed || false;
      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'showConfirmed', showConfirmed);      

      const showUnconfirmed = payload?.showUnconfirmed || false;
      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'showUnconfirmed', showUnconfirmed);      

      const showAutogenerated = payload?.showAutogenerated || false;
      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'showAutogenerated', showAutogenerated);  
     
      axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
        if (response && response.data) {
  
          commit(HONORARE_TYPES.MUTATIONS.CREATE_FEES_SUCCESS, response.data);
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });



  },

  [HONORARE_TYPES.ACTIONS.GET_FEES]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/feeservice/getFees';
      
      const kundennummer = payload?.kundennummer;
      if (kundennummer) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'kundennummer', kundennummer);
      }

      const kundenname = payload?.kundenname;
      if (kundenname) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'kundenname', kundenname);
      }      

      const kundenvorname = payload?.kundenvorname;
      if (kundenvorname) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'kundenvorname', kundenvorname);
      }     

      const dateType = payload?.dateType;
      if (dateType) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'dateType', dateType);
      }      
      
      const dateFrom = payload?.dateFrom;
      if (dateFrom) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'dateFrom', dateFrom);
      }         

      const dateTo = payload?.dateTo;
      if (dateTo) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'dateTo', dateTo);
      }          
      
      const showSent = payload?.showSent || false;
      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'showSent', showSent);

      const showNoEmailSend = payload?.showNoEmailSend || false;
      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'showNoEmailSend', showNoEmailSend);

      const showManuell = payload?.showManuell || false;
      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'showManuell', showManuell);

      const showConfirmed = payload?.showConfirmed || false;
      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'showConfirmed', showConfirmed);      

      const showUnconfirmed = payload?.showUnconfirmed || false;
      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'showUnconfirmed', showUnconfirmed);      

      const showAutogenerated = payload?.showAutogenerated || false;
      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'showAutogenerated', showAutogenerated);  
     
  
      axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
        if (response && response.data) {
  
          commit(HONORARE_TYPES.MUTATIONS.GET_FEES_SUCCESS, response.data);
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });



  },

  [HONORARE_TYPES.ACTIONS.CONFIRM_FEES]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/feeservice/confirmFees';
      
      const kundennummer = payload?.kundennummer;
      if (kundennummer) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'kundennummer', kundennummer);
      }

      const kundenname = payload?.kundenname;
      if (kundenname) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'kundenname', kundenname);
      }      

      const kundenvorname = payload?.kundenvorname;
      if (kundenvorname) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'kundenvorname', kundenvorname);
      }     

      const dateType = payload?.dateType;
      if (dateType) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'dateType', dateType);
      }      
      
      const dateFrom = payload?.dateFrom;
      if (dateFrom) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'dateFrom', dateFrom);
      }         

      const dateTo = payload?.dateTo;
      if (dateTo) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'dateTo', dateTo);
      }          
      
      const showSent = payload?.showSent || false;
      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'showSent', showSent);

      const showNoEmailSend = payload?.showNoEmailSend || false;
      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'showNoEmailSend', showNoEmailSend);

      const showManuell = payload?.showManuell || false;
      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'showManuell', showManuell);

      const showConfirmed = payload?.showConfirmed || false;
      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'showConfirmed', showConfirmed);      

      const showUnconfirmed = payload?.showUnconfirmed || false;
      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'showUnconfirmed', showUnconfirmed);      

      const showAutogenerated = payload?.showAutogenerated || false;
      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'showAutogenerated', showAutogenerated);  

      axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
        if (response && response.data) {
  
          commit(HONORARE_TYPES.MUTATIONS.CONFIRM_FEES_SUCCESS, response.data);
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });



  },  

  [HONORARE_TYPES.ACTIONS.MARK_CONFIRMED]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/feeservice/markConfirmed';
      
      const feeId = payload?.feeId;
      if (feeId) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'feeId', feeId);
      }

      axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
        if (response && response.data) {
  
          commit(HONORARE_TYPES.MUTATIONS.MARK_CONFIRMED_SUCCESS, response.data);
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });



  },   
  
  [HONORARE_TYPES.ACTIONS.ALLE_LOESCHEN]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/feeservice/alleLoeschen';

      const resetInvoiceDate = payload?.resetInvoiceDate;
      if (resetInvoiceDate) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'resetInvoiceDate', resetInvoiceDate);
      }
      
      const kundennummer = payload?.kundennummer;
      if (kundennummer) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'kundennummer', kundennummer);
      }

      const kundenname = payload?.kundenname;
      if (kundenname) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'kundenname', kundenname);
      }      

      const kundenvorname = payload?.kundenvorname;
      if (kundenvorname) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'kundenvorname', kundenvorname);
      }     

      const dateType = payload?.dateType;
      if (dateType) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'dateType', dateType);
      }      
      
      const dateFrom = payload?.dateFrom;
      if (dateFrom) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'dateFrom', dateFrom);
      }         

      const dateTo = payload?.dateTo;
      if (dateTo) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'dateTo', dateTo);
      }          
      
      const showSent = payload?.showSent || false;
      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'showSent', showSent);

      const showNoEmailSend = payload?.showNoEmailSend || false;
      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'showNoEmailSend', showNoEmailSend);

      const showManuell = payload?.showManuell || false;
      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'showManuell', showManuell);

      const showConfirmed = payload?.showConfirmed || false;
      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'showConfirmed', showConfirmed);      

      const showUnconfirmed = payload?.showUnconfirmed || false;
      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'showUnconfirmed', showUnconfirmed);      

      const showAutogenerated = payload?.showAutogenerated || false;
      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'showAutogenerated', showAutogenerated);  

      axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
        if (response && response.data) {
  
          commit(HONORARE_TYPES.MUTATIONS.ALLE_LOESCHEN_SUCCESS, response.data);
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });



  },    

  [HONORARE_TYPES.ACTIONS.LEERE_LOESCHEN]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/feeservice/leereLoeschen';

      const resetInvoiceDate = payload?.resetInvoiceDate;
      if (resetInvoiceDate) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'resetInvoiceDate', resetInvoiceDate);
      }
      
      const kundennummer = payload?.kundennummer;
      if (kundennummer) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'kundennummer', kundennummer);
      }

      const kundenname = payload?.kundenname;
      if (kundenname) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'kundenname', kundenname);
      }      

      const kundenvorname = payload?.kundenvorname;
      if (kundenvorname) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'kundenvorname', kundenvorname);
      }     

      const dateType = payload?.dateType;
      if (dateType) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'dateType', dateType);
      }      
      
      const dateFrom = payload?.dateFrom;
      if (dateFrom) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'dateFrom', dateFrom);
      }         

      const dateTo = payload?.dateTo;
      if (dateTo) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'dateTo', dateTo);
      }          
      
      const showSent = payload?.showSent || false;
      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'showSent', showSent);

      const showNoEmailSend = payload?.showNoEmailSend || false;
      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'showNoEmailSend', showNoEmailSend);

      const showManuell = payload?.showManuell || false;
      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'showManuell', showManuell);

      const showConfirmed = payload?.showConfirmed || false;
      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'showConfirmed', showConfirmed);      

      const showUnconfirmed = payload?.showUnconfirmed || false;
      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'showUnconfirmed', showUnconfirmed);      

      const showAutogenerated = payload?.showAutogenerated || false;
      serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'showAutogenerated', showAutogenerated);  

      axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
        if (response && response.data) {
  
          commit(HONORARE_TYPES.MUTATIONS.LEERE_LOESCHEN_SUCCESS, response.data);
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });



  },  

  [HONORARE_TYPES.ACTIONS.BESTAETIGUNG_VORBEREITEN]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/feeservice/bestaetigungVorbereiten';

      const resetInvoiceDate = payload?.resetInvoiceDate;
      if (resetInvoiceDate) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'resetInvoiceDate', resetInvoiceDate);
      }
      
      const kundennummer = payload?.kundennummer;
      if (kundennummer) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'kundennummer', kundennummer);
      }

      const kundenname = payload?.kundenname;
      if (kundenname) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'kundenname', kundenname);
      }      

      const kundenvorname = payload?.kundenvorname;
      if (kundenvorname) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'kundenvorname', kundenvorname);
      }     

      const dateType = payload?.dateType;
      if (dateType) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'dateType', dateType);
      }      
      
      const dateFrom = payload?.dateFrom;
      if (dateFrom) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'dateFrom', dateFrom);
      }         

      const dateTo = payload?.dateTo;
      if (dateTo) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'dateTo', dateTo);
      }          
      
      axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
        if (response && response.data) {
  
          commit(HONORARE_TYPES.MUTATIONS.BESTAETIGUNG_VORBEREITEN_SUCCESS, response.data);
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });



  },   
  
  [HONORARE_TYPES.ACTIONS.GET_EDITOR_LAYOUT_DATA]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    let params = {};

    return new Promise((resolve, reject) => {
      const serviceUrl = '/feeservice/getEditorLayoutData';
      
      const feeId = payload?.feeId;
      if (feeId) {
        params.feeId = feeId
      }

      axios.get(rootState.core.apiAddress + serviceUrl, {params, config}).then(response => {
        if (response && response.data) {
  
          commit(HONORARE_TYPES.MUTATIONS.GET_EDITOR_LAYOUT_DATA_SUCCESS, response.data);
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });



  },     

  [HONORARE_TYPES.ACTIONS.SAVE_EDIT_POSITION]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    let pos = payload?.params;

    return new Promise((resolve, reject) => {
      let serviceUrl = '/feeservice/saveEditPosition';
      
      const feeId = payload?.feeId;
      if (feeId) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'feeId', feeId);
      }

      axios.post(rootState.core.apiAddress + serviceUrl, pos, config).then(response => {
        if (response && response.data) {
  
          commit(HONORARE_TYPES.MUTATIONS.GET_EDITOR_LAYOUT_DATA_SUCCESS, response.data);
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });



  },     

  [HONORARE_TYPES.ACTIONS.DELETE_POSITION]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    let pos = payload?.params;

    return new Promise((resolve, reject) => {
      let serviceUrl = '/feeservice/rmvEditPos';
      
      const feeId = payload?.feeId;
      if (feeId) {
        serviceUrl = utils.updateQueryStringParameter(serviceUrl, 'feeId', feeId);
      }

      axios.post(rootState.core.apiAddress + serviceUrl, pos, config).then(response => {
        if (response && response.data) {
  
          commit(HONORARE_TYPES.MUTATIONS.DELETE_POSITION_SUCCESS, response.data);
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });



  },  

  async [HONORARE_TYPES.ACTIONS.DELETE_HONORARE]({ getters }, { id, resetInvoiceDate, }) {
    const config = { defaultSpinner: true, };

    await axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/feeservice/deleteFee/${id}?resetInvoiceDate=${resetInvoiceDate}`, config);
  },

}