<template>
  <div>
    <BoxContainer title="Bezugsmöglichkeiten">
      <Table
        v-if="rows && rows.length"
        :headers="headers"
        :rows="rows"
        hidePagination
        :mobileConfig="{title: 'company', headers: ['einmalanlageErlaubt', 'sparplan', 'vwl', 'portfolio']}"
      >
      </Table>
      <div v-if="!rows || !rows && rows.length">Keine Daten</div>
    </BoxContainer>
  </div>
</template>

<script>
import FONDSINFO_TYPES from "@/store/fondsinfo/types";
import BoxContainer from '@/components/core/BoxContainer.vue';
import { mapGetters } from 'vuex';
import Table from "@/components/table2/Table.vue";
import {TextColumn } from "@/components/table2/table_util.js";

export default {
  components: {
    BoxContainer,
    Table,
  },
  name: "TabBezugsmoglichkeiten",
  props:{
    selectedTabLabel: {
      type: String
    }
  },
  data() {
    return {
      headers: {
          lockedLeft: [
            TextColumn("company", "Gesellschaft").makeAlwaysVisible(),
            TextColumn("einmalanlageErlaubt", "Einmalanlage"),
            TextColumn("sparplan", "Sparplan"),
            TextColumn("vwl", "VWL"),
            TextColumn("vv", "VV"),
            TextColumn("ruerup", "Rürup"),
            TextColumn("riester", "Riester"),
            TextColumn("portfolio", "Portfolio"),
            TextColumn("forwardPricing", "Forward Pricing"),
            TextColumn("ausschuettungstermin", "Ausschüttungstermin"),
            TextColumn("preislieferung", "Preislieferung Tage sparplan"),
            TextColumn("vlZulagenberechtigt", "VL-Zulagenberechtigt"),
            TextColumn("cutOffTime", "Cut-Off-Zeiten"),
          ],
          center: [
          ],
          lockedRight: [
          ],
      },
    }
  },
  computed: {
    ...mapGetters({
      purchaseOptions : FONDSINFO_TYPES.GETTERS.FONDSINFO_PURCHASE
    }),
    rows() {
      if(this.purchaseOptions && this.purchaseOptions.length) {
        return this.purchaseOptions.map(row => {
          return Object.entries(row).map(v => {
            if (v[1] && typeof v[1] === 'object') {
              return [v[0], v[1].name]
            }
            if (v[1] === true) {
              return [v[0], 'Ja']
            }
            if (v[1] === false) {
              return [v[0], 'Nein']
            }
            return [v[0], v[1]]
          }).reduce((acc, [k,v])=> ({
            ...acc,
            [k]: v
          }), {});
        })
      }
      return [];
    },
  },
  mounted() {
    if(!this.purchaseOptions) {
      this.$store.dispatch(FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_PURCHASE, 
      {
        isin: this.$route.params?.isin
      });
    }
  },

}
</script>

<style>

</style>