import actions from './actions';
import mutations from './mutations';
import getters from './getters';

/**
 * mutations: synchronous operation responsible for change the state
 * actions: asynchronous operation responsible for call external resources and commit mutations 
 */
export function getInitialState() {
  return {
    antrag: {},
    lagerstellen: null,
    lagerstellenConfig: {},
    depots: null,
    depotNumbers: null,
    data: {},
    positions: [],
    warnings: {},
    positionInfo: {},
    comboboxValues: {},
    // errorMessage: '',
    capitalbankKYCComponents: [],
    hellobankSelbstauskunftComponents: [],
    semperConstantiaComponents: [],
    positionData: {},
    positionDataComponents: {},
    beratungsMappeData: null,
    highestStepVisited: 0,
    isWertpapierorderLoading: false,
    hatPDF: null,
    mitGeldwaesche: false,
    venues: {}
  }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}