<template>
  <div>
    <PageHeaderTitleNavigation :title="$appNavigation.currentMenu.label" :id="$appNavigation.currentOptionMenuId"
      :actions="headerActions" @action-ADD-NEW="addShortcut()" v-on="$listeners" />

    <div class="box__container">
      <Table v-if="!pageLoading && rows.length" ref="tableResults" tableId="aec0b9a7-812c-48f2-9889-6e9b64ca283c"
        :title="TABLE_TITLE" :headers="headers" :rows="rows" :rowsPerPage="20" @action-EDIT="handleEdit"
        @action-DELETE="handleDelete">
        <template #forBroker="shortcut">
          <InputToggleSwitch v-model="shortcut.forBroker" @input="changeShortcut(shortcut, $event, 'forBroker')" />
        </template>
      </Table>

      <GhostLoading v-else-if="pageLoading" type="table" :title="TABLE_TITLE" />

      <NoData v-else :title="TABLE_TITLE" content="Keine Shortcuts vorhanden" />
    </div>

    <EditorShortcutsEditModal v-if="showEditModal" :shortcut="shortcut" @close="closeAddNewPlaceholder"
      @changeShortcutValue="changeShortcutValue" />
  </div>
</template>

<script>
import TEMPLATES_EDIT_TYPES from "@/store/templatesEdit/types";
import TAGS_TYPES from "@/store/tags/types";
import CORE_TYPES from "@/store/core/types";
import NoData from "@/components/core/NoData.vue";
import GhostLoading from "@/components/core/loading/GhostLoading.vue";
import { mapGetters } from "vuex";
import Table from "@/components/table2/Table.vue";
import EditorShortcutsEditModal from "@/views/templatesEdit/EditorShortcutsEditModal.vue";
import {
  TextColumn,
  ActionColumn,
  SimpleAction,
  Icon,
  SlotColumn,
  ConfirmedAction,
  DownloadLinkAction,
} from "@/components/table2/table_util.js";
import { PhFileText, PhEnvelope } from "phosphor-vue";
import MailBriefVorlage from "./MailBriefVorlage.vue";
import FreigabeEditModal from "@/views/templatesEdit/FreigabeEditModal.vue";
import InputChips from "@/components/core/input-chips/InputChips.vue";
import AddVorlagenTagsModal from "@/components/vorlagen-tags/AddVorlagenTagsModal.vue";
import { PageHeaderSimpleAction } from "@/components/core/header-title-navigation/page-header-utils";
import BaseFilter from "@/components/core/BaseFilter.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import PageHeaderTitleNavigation from "@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue";
import InputField from "@/components/core/forms/InputField.vue";
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import axios from "axios";

export function newShortcut() {
  return {
    key: "",
    value: "",
    forBroker: true,
    description: "",
    isNew: true,
  };
}

const TABLE_TITLE = "Editor Shortcuts";

export default {
  components: {
    GhostLoading,
    NoData,
    Table,
    MailBriefVorlage,
    FreigabeEditModal,
    InputChips,
    AddVorlagenTagsModal,
    BaseFilter,
    ComboBox,
    PageHeaderTitleNavigation,
    OptionMenu,
    InputField,
    InputToggleSwitch,
    EditorShortcutsEditModal
  },
  data() {
    return {
      TABLE_TITLE,
      baseFilterSaveKey: "MailBriefVorlage",
      editFreigabeVorlage: null,
      selectedTags: {},

      showEditModal: false,

      loadingItems: {},
      pageLoading: false,

      activeKey: 0,
      shortcut: {},
    };
  },
  watch: {
    templates: {
      handler() { },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      htmlShortcuts: TEMPLATES_EDIT_TYPES.GETTERS.HTML_SHORTCUTS,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
    headers() {
      return {
        lockedLeft: [
          TextColumn("description", "Beschreibung", 200, 3),
          TextColumn("key", "Shortcut", 200, 3),
          //TextColumn("value", "Text", 200, 3),
          SlotColumn("forBroker", "Vermittler Freigabe", 80),
        ],
        lockedRight: [ActionColumn("actions")],
      };
    },
    rows() {
      return (this.htmlShortcuts || []).map((row) => {
        const actions = [];

        actions.push(SimpleAction("EDIT", "PhPencil", "bearbeiten"));
        actions.push(
          ConfirmedAction(
            "DELETE",
            "PhTrash",
            "Löschen",
            "Wollen Sie diesen Eintrag wirklich löschen?",
            "Bestätigung",
            "Löschen"
          )
        );

        return {
          ...row,
          actions,
        };
      });
    },
    headerActions() {
      const actions = [];
      actions.push(PageHeaderSimpleAction("ADD-NEW", "Neuer Shortcut"));
      return actions;
    },
  },
  methods: {
    addShortcut() {
      this.activeKey = "";
      this.shortcut = newShortcut();

      this.openEditModal()
      /*this.$router.push({
        path: `/communication/html-shortcuts/${this.shortcut.key}`,
      });*/
    },
    openEditModal() {
      this.showEditModal = true;
    },
    handleEdit(item) {
      this.activeKey = item.key;
      this.shortcut = item;

      this.$router.push({
        path: `/communication/html-shortcuts/${this.shortcut.key}`,
      });
    },
    closeAddNewPlaceholder() {
      this.showEditModal = false;
    },
    changeShortcut(item, value, field) {
      const shortcut = {
        ...item,
        [field]: value,
      };
      this.activeKey = item.key;
      this.saveShortcut(shortcut);
    },
    async changeShortcutValue(shortcut) {
      await this.saveShortcut(shortcut)

      this.$router.push({
        path: `/communication/html-shortcuts/${shortcut.key}`,
      });
    },
    saveShortcut(item) {
      let removePromise = [];
      if (this.activeKey && this.activeKey !== item.key) {
        removePromise.push(
          this.handleDelete(
            {
              ...item,
              key: this.activeKey,
            },
            false
          )
        );
      }
      const { key, value, forBroker, description } = item;
      return new Promise((resolve) => {
        Promise.all(removePromise).then((r) => {
          return this.$store.dispatch(TEMPLATES_EDIT_TYPES.ACTIONS.SAVE_HTML_SHORTCUTS, { key, value, forBroker, description }).then(() => {
            this.onRefresh();
            resolve()
          })
        });
      })
    },
    async handleDelete(item, refresh = true) {
      return this.$store.dispatch(TEMPLATES_EDIT_TYPES.ACTIONS.DELETE_HTML_SHORTCUTS, item.key).then(() => {
        if (refresh) {
          this.onRefresh();
        }
      })
    },
    async onRefresh() {
      this.pageLoading = true;

      this.$store.commit(TEMPLATES_EDIT_TYPES.MUTATIONS.SET_HTML_SHORTCUTS, []);
      await Promise.all([
        this.$store.dispatch(TEMPLATES_EDIT_TYPES.ACTIONS.GET_HTML_SHORTCUTS),
      ]).finally(() => (this.pageLoading = false));
    },
  },
  mounted() {
    this.onRefresh();
  },
  beforeDestroy() {
    this.$store.commit(TAGS_TYPES.MUTATIONS.RESET_STATE);
    this.$store.dispatch(TEMPLATES_EDIT_TYPES.ACTIONS.RESET);
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: "zurück zu Editor Shortcuts",
      to,
      from,
      breadcrumb: "Editor Shortcuts",
    });
    next();
  },
};
</script>

<style scoped>
.top-buttons {
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
}
</style>
