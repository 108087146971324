<template>
  <div class="details_container">
    <div class="banner">
      <editable-frame type="details_title_page" />
    </div>
    <hr />
    <div class="dreispalten_wrapper">
      <div class="dreispalten">
        <div class="spalte1">
          <div class="drei_spalten_image">
            <img alt="Icon für die Erklärung" src="@/../public/img/cms/selectable_images/Portfolios.svg" />
          </div>
          <div class="drei_spalten_banner">
            <h3>Ihre jährlichen Kosten bei einer Anlage von 10.000 Euro</h3>
          </div>
          <hr />
          <div class="drei_spalten_text">
                    <div class="fc-alert fc-alert-warning" v-if="isIntern">Die Serviceentgelt und Depotführung-Kosten werden automatisch aus dem MSC eingefügt.</div>
            <ul>
              <li>
                0,– Euro Ausgabeaufschlag (100 % Rabatt auf den 
                Ausgabeaufschlag bei Erstanlage, Zuzahlung, Sparplan 
                und Fondswechsel)
              </li>
              <li>
                0,– Euro Transaktionskosten bei Fondswechsel bei Modellportfolio (bei Einzelfonds 2EUR/Transaktion)
              </li>
              <li>
                {{serviceentgeltPortfolioTotal}},– Euro Serviceentgelt p.a. zzgl. gesetzlicher MWST ({{serviceentgeltPortfolioPercent}} % 
                Serviceentgelt p.a. zzgl. gesetzlicher MWST auf den 
                durchschnittlichen Depotbestand bei Modellportfolios.)
              </li>
              <li>
                {{serviceentgeltEinzelTotal}},– Euro Serviceentgelt p.a. zzgl. gesetzlicher MWST ({{serviceentgeltEinzelPercent}} % 
                Serviceentgelt p.a. zzgl. gesetzlicher MWST auf den 
                durchschnittlichen Depotbestand bei Einzelfonds.)
              </li>
              <li>
                {{getDepotfuehrungsentgelt}},– Euro p.a. Depotgebühr der FFB (FIL Fondsbank GmbH), 
                jedes weitere Depot 12,- Euro p.a.
              </li>
              <li>
                Bis zu 0,15% p.a. Verwaltungsentgelt (nur bei Modellportfolio)
              </li>
              <li>
                weitere interne Kosten von Emittenten und Produktanbieter 
                (Details finden Sie im sog. ex-ante Kostenausweis in den Depoteröffnungsunterlagen)
              </li>
              <li>
                persönliche und individuelle Kosten durch Steuern 
                (z.B. Abgeltungssteuer) und weitere Dienstleister (Steuerberater)
              </li>
            </ul>
          </div>
        </div>
        <div class="spalte2">
          <div class="drei_spalten_image">
            <img alt="Icon für die Erklärung" src="@/../public/img/cms/selectable_images/Thumbsup.svg" />
          </div>
          <div class="drei_spalten_banner">
            <h3>Ihre Vorteile und Chancen</h3>
          </div>
          <hr />
          <div class="drei_spalten_text">
            <ul>
              <li>
                einfaches selbständiges Investieren in drei Schritten
              </li>
              <li>
                24/7 Onlinezugang via PC und App
              </li>
              <li>
                Sicherheit Ihrer Daten durch SSL Verschlüsselung
              </li>
              <li>
                freie Fondsauswahl aus Einzelfonds und Modellportfolios
              </li>
              <li>
                kostengünstiges und flexibles Investieren durch Einsparung von Ausgabeaufschläge beim Fondskauf
              </li>
              <li>
                Anlage ab 500 Euro einmalig / 25 Euro monatlich in Einzelfonds / 50 Euro monatlich in Portfolios
              </li>
              <li>
                Flexibilität durch tägliche Verfügbarkeit Ihrer Fondsanteile
              </li>
              <li>
                Transparenz durch regelmäßiges Reporting (Quartalsbericht)
              </li>
              <li>
                Insolvenzschutz Ihrer Investmentfonds, da diese als Sondervermögen gehalten werden
              </li>
              <li>
                Risikostreuung durch Anlage in viele verschiedene Einzeltitel über einen Investmentfonds
              </li>
              <li>
                Ertragschancen durch steigende Anteilspreise und mögliche Ausschüttungen
              </li>
            </ul>
          </div>
        </div>
        <div class="spalte3">
          <div class="drei_spalten_image">
            <img alt="Icon für die Erklärung" src="@/../public/img/cms/selectable_images/Vorsicht.svg" />
          </div>
          <div class="drei_spalten_banner">
            <h3>Ihre Risiken</h3>
          </div>
          <hr />
          <div class="drei_spalten_text">
            <ul>
              <li>Zinsrisiko</li>
              <li>Kursrisiko</li>
              <li>Währungsrisiko</li>
              <li>Bonitätsrisiko</li>
              <li>Emittentenrisiko</li>
              <li>theoretisches Risiko eines Totalverlustes</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="banner">ERLÄUTERUNGEN ZU DEN KOSTEN INNERHALB DES FONDSSHOPS</div>
    <hr />
    <div class="text_only_wrapper">
      <div class="text_only">
        <div class="fc-alert fc-alert-warning" v-if="isIntern">Die Serviceentgelt und Depotführung-Kosten werden automatisch aus dem MSC eingefügt.</div>
        <p>
          <b>Ausgabeaufschläge</b>
        </p>
        <p>
          Bei Nutzung des Fondsshops bezahlen Sie dauerhaft keine Ausgabeaufschläge und sparen sich so bis zu 6 % banküblicher Kaufgebühren. 
          Dies gilt sowohl für Erstanlage, wie auch für Zuzahlungen oder Sparpläne!
        </p>
        <p>
          <b>Serviceentgelt</b>
        </p>
        <p>
          Als Kunde bezahlen Sie für die Dienstleistung innerhalb des Fondsshops ein Serviceentgelt in Höhe 
          von {{serviceentgeltPortfolioPercent}} % p.a. bei Modellportfolien bzw. {{serviceentgeltEinzelPercent}} % p.a. bei Einzelfondsdepots, jeweils zzgl. MWST. 
          Das Serviceentgelt wird prozentual auf den einmal im Kalenderquartal ermittelten tagesgenauen 
          Durchschnittswert des Depotbestandes berechnet und wird automatisch von der FFB (FIL Fondsbank GmbH) 
          durch Veräußerung von Fondsanteilen aus dem Depot abgebucht.
        </p>
        <p>
          <b>Depotgebühren</b>
        </p>
        <p>
          Die Kosten für die Depotführung bei der FFB (FIL Fondsbank GmbH) belaufen sich auf {{getDepotfuehrungsentgelt}},– Euro pro Jahr. 
          Es gilt darüber hinaus das Preis-Leistungs-Verzeichnis der FFB für das FFB Fondsdepot Plus.
        </p>
        <p>
          <b>Interne Kosten von Emittenten und Produktanbietern / Vertriebsfolgeprovision</b>
        </p>
        <p>
          Investmentfonds beinhalten in der Regel interne Kosten, die durch die Verwaltungsgesellschaft der Investmentfonds erhoben werden. 
          Diese Kosten werden nicht direkt dem Kunden in Rechnung gestellt, sondern werden aus dem investierten 
          Vermögen des entsprechenden Investmentfonds entnommen. Die Höhe der laufenden Kosten beträgt in der 
          Regel zwischen 0,00 % bis 1,55 % p.a. bezogen auf das durchschnittliche Fondsvermögen zum jeweiligen Bewertungsstichtag. 
          Details hierzu finden Sie im ex-ante Kostenausweis in den Depoteröffnungsunterlagen. 
        </p>
      </div>
    </div>

    <div class="banner">ALLGEMEINE HINWEISE ZU INVESTMENTFONDS</div>
    <hr />
    <div class="text_only_wrapper">
      <div class="text_only">
        <p>
          <b>Allgemeine Informationen des BVI</b>
        </p>
        <p>
          Der BVI stellt Ihnen als interessiertem Leser umfangreiches Informationsmaterial zur Verfügung. Neben Basisinformationen zur Kapitalanlage in Investmentfonds bietet der BVI
          vertiefende Dokumente in verschiedenen Bereichen.
        </p>
        <p>
          <a
            href="https://www.bvi.de/fileadmin/user_upload/Bestellcenter/BVI_Grundlagenbroschuere_2019_Web.pdf"
            target="_blank"
          >Basisinformationen zu Investmentfonds</a>
        </p>
        <p>
          <a
            href="https://www.bvi.de/uploads/tx_bvibcenter/Der_BVI._Beraten._Vermitteln._Informieren_.pdf"
            target="_blank"
          >Investmentfonds und Altersvorsorge</a>
        </p>

        <p>
          <b>Wesentliche Anlegerinformationen</b>
        </p>
        <p>
          Die wesentlichen Anlegerinformationen soll dazu dienen, Anlegern auf einen Blick die wesentlichen Chancen 
          und Risiken von Bankprodukten übersichtlich darzustellen. Es soll somit dem Verbraucher ermöglichen, 
          die wesentlichen Eigenschaften des Finanzprodukts schnell zu erfassen und verschiedene Anlageprodukte 
          miteinander leichter zu vergleichen. Gerne stellen wir Ihnen innerhalb unseres Fondsshops diese Informationen 
          über den Button Fonds-Details zur Verfügung. Hier erhalten Sie
        </p>
        <ul>
          <li>Factsheets zu Einzelfonds und Modellportfolios</li>
          <li>Halbjahresberichte / Jahresberichte</li>
          <li>Verkaufsprospekte</li>
          <li>PRIIP-BIB (Packaged Retail and Insurance-based Investment Product - Basisinformationsblatt)</li>
        </ul>
      </div>
    </div>

    <div class="banner">KEINE ANLAGE IST OHNE RISIKO!</div>
    <hr />
    <div class="text_only_wrapper">
      <div class="text_only">
        <p>
          Der Wert eines Investments kann während der Laufzeit einer Vielzahl von Marktfaktoren, wie dem Zinsniveau, der Kursentwicklung, der Schwankungsintensität, der Bonität anderer
          Marktteilnehmer usw. unterliegen. Aufgrund ungünstiger Entwicklungen dieser Faktoren kann das eingesetzte Kapital zuzüglich der entstandenen Kosten vollständig verloren sein. Bei der
          Investition in Investmentfonds oder eine Vermögensverwaltung ist das Totalverlustrisiko durch die breite Streuung sehr unwahrscheinlich. Selbst wenn einzelne Wertpapiere einen
          Totalverlust erleiden sollten, ist ein Gesamtverlust durch die breite Diversifikation unwahrscheinlich.
        </p>
        <p>
          <b>Sondervermögen</b>
        </p>
        <p>
          Ein Investmentfonds ist ein Sondervermögen, das nach dem Grundsatz der Risikostreuung in verschiedenen Einzelanlagen (z.B. in Aktien, Anleihen, wertpapierähnlichen Anlagen oder
          Immobilien) investiert ist. Ein Depotinhaber kann mit dem Kauf von Investmentanteilen an der Wertentwicklung sowie den Erträgen des Fondsvermögens teilhaben und trägt anteilig das
          volle Risiko der durch den Anteilschein repräsentierten Anlagen.
        </p>

        <p>
          <b>Währungsrisiken</b>
        </p>
        <p>
          Bei Investmentfonds, die auch in auf fremde Währung lautende Wertpapiere investieren bzw. in Fremdwährung geführt werden, muss berücksichtigt werden, dass sich neben der
          normalen Kursentwicklung auch die Währungsentwicklung negativ im Anteilspreis niederschlagen kann und Länderrisiken auftreten können, auch wenn die Wertpapiere, in die der
          Investmentfonds investiert, an einer deutschen Börse gehandelt werden. Durch die Aufwertung des Euro (Abwertung der Auslandswährung) verlieren die ausländischen
          Vermögenspositionen – am Maßstab des Euro betrachtet – an Wert. Zum Kursrisiko ausländischer Wertpapiere kommt damit das Währungsrisiko hinzu. Die Währungsentwicklung kann
          einen Gewinn aufzehren und die erzielte Rendite so stark beeinträchtigen, dass eine Anlage in Euro oder in Vermögenspositionen der Eurozone unter Umständen vorteilhafter gewesen
          wäre.
        </p>
      </div>
    </div>
    <risiko-hinweise />
  </div>
</template>
<script>
import RisikoHinweise from "@/components/cms/parts/RisikoHinweise.vue";
import EditableFrame from "@/components/cms/EditableFrame.vue";
import CORE_TYPES from "@/store/core/types";
import { mapGetters } from "vuex";
import { formatNumber } from '@/helpers/number-formatter';

export default {
  components: {
    RisikoHinweise,
    EditableFrame
  },
  data() {
    return {
      investmentAmount: 10000,
    }
  },
  computed: {
    ...mapGetters({
      maklerInfo: CORE_TYPES.GETTERS.GET_BROKER_INFORMATION,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,

    }),
    serviceentgeltPortfolioTotal() {
      if (this.isIntern){
        return "[wird aus dem MSC geladen]";
      }
      const percentage = this.maklerInfo?.serviceentgeltPortfolio
      return this.investmentAmount * percentage / 100;
    },
		serviceentgeltEinzelTotal() {
      if (this.isIntern){
        return "[wird aus dem MSC geladen]";
      }
      const percentage = this.maklerInfo?.serviceentgeltEinzel
      return this.investmentAmount * percentage / 100;
    },
    serviceentgeltPortfolioPercent() {
      if (this.isIntern){
        return "[wird aus dem MSC geladen]";
      }
      return formatNumber(this.maklerInfo?.serviceentgeltPortfolio, 2)
    },
		serviceentgeltEinzelPercent() {
      if (this.isIntern){
        return "[wird aus dem MSC geladen]";
      }
      return formatNumber(this.maklerInfo?.serviceentgeltEinzel, 2)
    },
    getDepotfuehrungsentgelt() {
      if (this.isIntern){
        return "[vom Makler bestimmt]";
      }
      if (this.maklerInfo) {
        if (this.maklerInfo.depotfuehrungsentgelt) {
          return this.maklerInfo.depotfuehrungsentgelt.split(",")[0];
        }
      }
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>
<style  scoped>
.banner {
  font-size: 26px;
  text-align: left;
  color: var(--color-text);
}
.details_container {
  margin: 0 auto;
  padding-left: 1em;
  padding-right: 1em;
  max-width: 1200px;
}

.beachten_wrapper {
  padding-bottom: 2em;
  margin-bottom: 2em;
}
.beachten {
  margin: 0 auto;
  text-align: justify;
}

.text_only_wrapper {
  padding-top: 2em;
  padding-bottom: 2em;
}
.text_only {
  margin: 0 auto;
  text-align: justify;
}

.dreispalten_wrapper {
  padding-top: 2em;
  padding-bottom: 2em;
}

.dreispalten {
  margin: 0 auto;
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
  column-gap: 4em;
  grid-template-areas: " spalte1 spalte2 spalte3 ";
  grid-area: dreispalten;
}

.drei_spalten_banner {
  font-weight: bold;
  margin-top: 0.75em;
}

.drei_spalten_text {
  text-align: justify;
}

.spalte1 {
  grid-area: spalte1;
}
.spalte2 {
  grid-area: spalte2;
}
.spalte3 {
  grid-area: spalte3;
}
a {
  color: var(--color-link);
}

@media only screen and (max-width: 1024px) {
  /*   .details_container {
    margin-top: 10em;
  } */
  .dreispalten {
    grid-template-columns: 1fr;
    grid-template-areas:
      "spalte1"
      "spalte2"
      "spalte3";
  }
}
</style>