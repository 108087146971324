var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "depotnummer-anfordern-button-component mb-4" },
    [
      !_vm.requested
        ? _c(
            "div",
            {
              staticClass:
                "depotnummer-anfordern-button-component-input-container"
            },
            [
              _c("InputField", {
                attrs: {
                  label: _vm.label,
                  disabled: _vm.loading,
                  isComponentHalfSize: _vm.isComponentHalfSize
                },
                on: {
                  input: function($event) {
                    return _vm.handleInputEvent($event)
                  },
                  change: function($event) {
                    return _vm.handleChangeEvent($event)
                  }
                },
                model: {
                  value: _vm.internalValue,
                  callback: function($$v) {
                    _vm.internalValue = $$v
                  },
                  expression: "internalValue"
                }
              }),
              _c(
                "BaseButton",
                {
                  attrs: { disabled: _vm.loading },
                  on: {
                    click: function($event) {
                      return _vm.requestDepotnummer()
                    }
                  }
                },
                [
                  _vm._v(" Depotnummer anfordern "),
                  _vm.loading ? _c("AnimatedSpinner") : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.requested
        ? _c(
            "div",
            {
              staticClass:
                "depotnummer-anfordern-button-component-requested-number"
            },
            [_vm._v("Verwende Depotnummer " + _vm._s(_vm.internalValue))]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }