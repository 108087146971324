<template>
    <div>
        <div v-if="isEmpty">Keine zusatzliche Daten erforderlich</div>
        <div v-else>
            <template v-if="components && components.length">
                <div class="box__title">Selbstauskunft</div>
                 <ComponentList :components="components" :data="data" :comboboxValues="comboboxValues"
                    :warnings="warnings" :disabled="readOnly" @change="updateStoreData($event)"></ComponentList>
            </template>
        </div>
    </div>
</template>

<script>
import ComponentList from '@/components/wertpapierorder/ComponentList.vue';
import WERTPAPIERORDER_TYPES from '@/store/wertpapierorder/types';
import mixin from '@/mixins/wertpapierorder/mixin.js';
import { mapGetters } from "vuex";

export default {
    mixins: [mixin],
    components: {
        ComponentList,
    },
    data() {
        return {
            disabled: false,
            form: {},
            changed: false,
        }
    },
    mounted() {
        this.initForm();
    },
    computed: {
        ...mapGetters({
            comboboxValues: WERTPAPIERORDER_TYPES.GETTERS.COMBOBOX_VALUES || {},
            lagerstelle: WERTPAPIERORDER_TYPES.GETTERS.SELECTED_LAGERSTELLE,
            components: WERTPAPIERORDER_TYPES.GETTERS.HELLOBANK_COMPONENTS_SELBST,
        }),
        isEmpty() {
            return !this.components || !Object.keys(this.components).length;
        },
        warnings() {
            return this.getWarnings('hellobank-selbst') || {};
        }
    },
    methods: {
        initForm() {
            if (this.components?.length) {
                this.components.filter(comp => comp.id != null)
                    .forEach(comp => 
                    this.form[comp.id] = this.data[comp.id]);
            }
        },
    },
}
</script>

<style>
    .title-row {
        border-top: 1px solid #f7f3f3;
        background-color: #f7f3f3;
        padding: 1rem;
    }
</style>