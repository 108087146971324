var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box__container" }, [
    _c(
      "div",
      [
        _c("FormLabel", {
          attrs: {
            config: { bold: true },
            label:
              "Referenzbankverbindung (nur wenn Kunde mit Kontoinhaber identisch ist)"
          }
        }),
        _c("InputToggleSwitch", {
          attrs: {
            label:
              "Bitte merken Sie die auf diesem Auftrag angegebene Bankverbindung als Referenzbankverbindung vor.",
            disabled: _vm.readOnly
          },
          on: {
            input: function($event) {
              return _vm.updateStoreData({
                componentId: "INPUT_CHK_REFERENZBV",
                value: $event
              })
            }
          },
          model: {
            value: _vm.form.INPUT_CHK_REFERENZBV,
            callback: function($$v) {
              _vm.$set(_vm.form, "INPUT_CHK_REFERENZBV", $$v)
            },
            expression: "form.INPUT_CHK_REFERENZBV"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      [
        _c("FormLabel", {
          attrs: {
            config: { bold: true },
            label:
              "Neuerteilung Auftrag wegen Ablehnung aufgrund Angemessenheitsprüfung"
          }
        }),
        _c("InputToggleSwitch", {
          attrs: {
            label:
              "Ich habe den Warnhinweis für meinen Auftrag erhalten, dass das Institut keine Angemessenheitsprüfung mangels fehlender/unvollständiger Angaben durchführen konnte oder dass auf Basis meiner Angaben das von mir gewünschte Geschäft als nicht angemessen beurteilt worden ist. Ich habe dies zur Kenntnis genommen und beauftrage das Institut dennoch ausdrücklich mit der Ausführung dieses Auftrages.",
            disabled: _vm.readOnly
          },
          on: {
            input: function($event) {
              return _vm.updateStoreData({
                componentId: "INPUT_CHK_ANGEMESSENHEITSPRUEFUNG",
                value: $event
              })
            }
          },
          model: {
            value: _vm.form.INPUT_CHK_ANGEMESSENHEITSPRUEFUNG,
            callback: function($$v) {
              _vm.$set(_vm.form, "INPUT_CHK_ANGEMESSENHEITSPRUEFUNG", $$v)
            },
            expression: "form.INPUT_CHK_ANGEMESSENHEITSPRUEFUNG"
          }
        }),
        _c("FormLabel", {
          attrs: {
            config: { bold: true, small: true },
            label: "Wichtiger Hinweis :"
          }
        }),
        _c("p", [
          _vm._v(
            "Sofern Ihr zuvor erteilter Auftrag aus einem der vorgenannten Gründe im Rahmen der Angemessenheitsprüfung abgelehnt wurde und Sie dennoch die Ausführung des Auftrages wünschen, kreuzen Sie bitte dieses Ankreuzfeld an. Nur so ist ersichtlich, dass dies kein Erstauftrag ist, der aus dem gleichen Grund erneut zu einer Ablehnung des Auftrages führt."
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }