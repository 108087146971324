var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "dashboard-panel__widgets" }, [
      _c(
        "div",
        { staticClass: "box__container dashboard-panel-widget" },
        [
          _c("div", { staticClass: "box__title" }, [
            _vm._v("Ihre Einstellungen")
          ]),
          _c("Table", {
            ref: "scenarioOverviewSettingsTable",
            attrs: {
              tableId: "dac58861-120a-4f32-0cc6-f55f487e86a4",
              rowId: "name",
              hidePagination: "",
              headers: _vm.headers,
              rows: _vm.rowsSettings
            },
            scopedSlots: _vm._u([
              {
                key: "value",
                fn: function(row) {
                  return [
                    _c("div", { staticClass: "font-bold text-right" }, [
                      _vm._v(_vm._s(_vm.formatValue(row.value, row.type)))
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "box__container dashboard-panel-widget" },
        [
          _c("div", { staticClass: "box__title" }, [_vm._v("Chart")]),
          _c("RetirementChart", {
            attrs: {
              data: {
                data: _vm.apexSeriesCurrentScenario,
                series: _vm.apexSeriesCurrentScenario,
                xaxis: _vm.apexXaxisCurrentScenario,
                annotations: {},
                markers: _vm.apexMarkers,
                dataLabels: { enabled: false },
                stroke: { curve: "smooth" },
                autoUpdateSeries: true
              }
            }
          })
        ],
        1
      ),
      !_vm.isRenteOk
        ? _c("div", { staticClass: "box__container dashboard-panel-widget" }, [
            _c("div", { staticClass: "box__title" }, [
              _vm._v("Aktuelle Situation")
            ]),
            _c("div", [_vm._v(_vm._s(_vm.aktuelleSituationText1))]),
            _c("div", {
              staticClass: "mb-2",
              domProps: { innerHTML: _vm._s(_vm.aktuelleSituationText2) }
            })
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "box__container dashboard-panel-widget" },
        [
          _c("Table", {
            ref: "scenarioOverviewCalcTable",
            attrs: {
              tableId: "dac58861-120a-4f32-0cc6-f55f487e86a4",
              rowId: "name",
              hidePagination: "",
              headers: _vm.headers,
              rows: _vm.rowsCalc
            },
            scopedSlots: _vm._u([
              {
                key: "value",
                fn: function(row) {
                  return [
                    _c("div", { staticClass: "font-bold text-right" }, [
                      _vm._v(_vm._s(_vm.formatValue(row.value, row.type, 0)))
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ]),
    !_vm.isRenteOk
      ? _c("div", { staticClass: "box__container" }, [
          _c("div", { staticClass: "box__title" }, [
            _vm._v("Lösungsmöglichkeit 1: Höhere Sparrate")
          ]),
          _c("div", {
            domProps: { innerHTML: _vm._s(_vm.loesungSparrateText) }
          })
        ])
      : _vm._e(),
    !_vm.isRenteOk
      ? _c("div", { staticClass: "box__container" }, [
          _c("div", { staticClass: "box__title" }, [
            _vm._v("Lösungsmöglichkeit 2: Höhere Rendite")
          ]),
          _c("div", { domProps: { innerHTML: _vm._s(_vm.loesungRenditeText) } })
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }