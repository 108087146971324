import KUNDEN_GESELL_NR_TYPES from './types';
import Vue from 'vue';
import { getInitialState } from './index';

export default {

  [KUNDEN_GESELL_NR_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },

  [KUNDEN_GESELL_NR_TYPES.MUTATIONS.SET_LIST](state, payload) {
    Vue.set(state, 'list', [ ...payload || [], ]);
  },

  [KUNDEN_GESELL_NR_TYPES.MUTATIONS.SET_COMBO_DATA](state, payload) {
    Vue.set(state, 'comboData', { ...payload || {}, });
  },

  [KUNDEN_GESELL_NR_TYPES.MUTATIONS.SET_DATA](state, payload) {
    Vue.set(state, 'data', { ...payload || {}, });
  },

  [KUNDEN_GESELL_NR_TYPES.MUTATIONS.RESET_DATA](state) {
    Vue.set(state, 'data', {});
  },

  [KUNDEN_GESELL_NR_TYPES.MUTATIONS.ADD_DATA_EDITED](state, payload) {
    if(!payload) return;

    Vue.set(state, 'edited', { 
      ...state.edited || {},
      ...payload, 
    });
  },

  [KUNDEN_GESELL_NR_TYPES.MUTATIONS.RESET_DATA_EDITED](state) {
    Vue.set(state, 'edited', {});
  },

}
