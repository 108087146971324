var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Zuordnung Gesellschaften zu BiPRO Zugängen"
        }
      }),
      _c("BaseFilter", {
        attrs: {
          filterId: "8ad723f9-8cca-421e-91b5-05066e59725e",
          title: "Bipro Gesellschaften filtern",
          metadata: _vm.gesellschaftFilterMetadata,
          defaultOptions: _vm.gesellschaftFilterDefaultOptionsData
        },
        on: {
          onFilter: function($event) {
            return _vm.filterGesellschaftForm($event)
          }
        }
      }),
      _c(
        "div",
        {
          staticClass: "box__container",
          attrs: { tid: "13cd1ac7-d05e-4508-971b-1a4e18f0a0b2" }
        },
        [
          !_vm.loadingGesellschaften && _vm.gesellschaftTableRows.length
            ? _c("Table", {
                staticClass: "table-container",
                attrs: {
                  tableId: "354e2f99-56e1-4f4a-a8c1-4f59915d92d5",
                  title: "BiPRO Gesellschaften",
                  headers: _vm.gesellschaftTableHeaders,
                  rows: _vm.gesellschaftTableRows,
                  rowsPerPage: 20
                },
                on: {
                  "click-ICON": _vm.addEntry,
                  action: function($event) {
                    return _vm.executeAction($event)
                  }
                }
              })
            : _vm.loadingGesellschaften
            ? _c("GhostLoading", {
                attrs: { type: "table", title: "BiPRO Gesellschaften" }
              })
            : _c("NoData", { attrs: { title: "BiPRO Gesellschaften" } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }