<template>
	<CoreCard ref="coreCard" :headers="headers" :rows="rows" :showToolbar="showToolbar" :listView="listView">
		<template v-if="showTitle" v-slot:header>
			{{chartName}}
		</template>
		<template v-slot:alternative>
		</template>
    <div class="content">
      <div v-if="isContainerRendered">
		    <Chart :data="chartData" type="bar" :isDataLabels="false" :numberFixed="0" :tooltip="tooltip()" :colors="customColors" :mixedColors="false"></Chart>
      </div>
    </div>
	</CoreCard>
</template>

<script>
import CoreCard from '../CoreCard/CoreCard'
import Chart from '../../charts/Chart.vue'
import cardMixin from '../CoreCard/card-mixin';
import {TextColumn, CurrencyColumn} from "@/components/table2/table_util.js";


export default {
  mixins: [cardMixin],
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    option: {
      type: String,
      default: [],
    },
  },
  data: function() {
    return {
      isContainerRendered: false,
    };
  },

  computed: {
    chartName: function() {
      return this.propsData && this.propsData.option || this.option;
    },
    initData: function() {
      return this.propsData && this.propsData.data || this.data;
    },
    customColors: function() {
      return this.propsData.customColors;
    },
    chartXLabels: function() {
      return [...Array(12).keys()].map(v => v + 1);
	  },
    chartData: function() {
      if (!this.initData) {return {name: '', data: []}}
      let resultData = [];
      const chartData = this.initData[this.chartName] || this.initData[`bar#${this.chartName}`];
      if (chartData) {
        Object.keys(chartData).map(key => {
          resultData.push({
            label: `${key}`,
            value: +chartData[key]
          })
        })
      }
      let result = {
        name: '',
        data: resultData
      };
      return result;
    },
    headers() {
        return {
            lockedLeft: [
                TextColumn("label", ""),
            ],
            lockedRight: [
                CurrencyColumn("value", ""),
            ],
        };
    },
    rows() {
        const chartData = this.initData[this.chartName] || this.initData[`bar#${this.chartName}`];
        const resultData = [];
        if (chartData) {
            Object.keys(chartData).forEach(key => {
                chartData[key].forEach(value => {
                    resultData.push({
                        label: `${key}`,
                        value,
                    });
                });
            });
        }
        return resultData;
    },
  },

  methods: {
    tooltip() {
      return {
        x: {
          show: false,
        }
      }
    },
  },

  mounted: function () {
    this.$nextTick(function () {
      this.isContainerRendered = true;
    });
  },

  components: {
    CoreCard,
    Chart
  },
}
</script>

<style scoped>
/* .content {
  width: 100%;
  min-width: 300px;
  height: 100%;
  min-height: 350px;
  position: relative;
} */
</style>
