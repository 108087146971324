<template>
  <div class="navbar-workspaces__container-bg">
    <div class="fc-container navbar-workspaces__container">
      <div class="navbar-container__logo-container">
        <NavbarWorkspacesLogo />
      </div>
      <div class="navbar-workspaces__menu-container">
        <WorkspacesMenu v-if="roles.length > 0" />
      </div>
    </div>
  </div>
</template>

<script>
import CORE_TYPES from "../../../store/core/types";
import { mapGetters } from "vuex";
import NavbarWorkspacesLogo from "@/components/core/navbar-workspaces/NavbarWorkspacesLogo.vue";
import WorkspacesMenu from "@/components/core/navbar-workspaces/WorkspacesMenu.vue";

export default {
  computed: {
    ...mapGetters({
      isLoggedIn: CORE_TYPES.GETTERS.IS_LOGGED_IN,
      logo: CORE_TYPES.GETTERS.GET_USER_LOGO_URL,
      userid: CORE_TYPES.GETTERS.GET_USER_ID,
      recordingFeature: CORE_TYPES.GETTERS.RECORDING_FEATURE,
      roles: CORE_TYPES.GETTERS.GET_USER_ROLES,
    }),
  },
  components: {
    NavbarWorkspacesLogo,
    WorkspacesMenu,
  },
};
</script>

<style scoped>

.navbar-container__logo-container {
  flex: 1 0 128px;
  display: flex;
  position: relative;
  padding: 0.5em 0;
}

.navbar-workspaces__container-bg {
  background-color: var(--color-workspaces-nav-background);
  box-shadow: 0px 4px 16px rgba(0, 40, 87, 0.1);
}

.navbar-workspaces__container {
  height: 96px;
  color: var(--color-workspaces-nav-text);
  display: flex;
  justify-content: space-between;
}

@media (max-width: 900px) {
  .navbar-container__logo-container {
    max-width: 200px;
  }

  .navbar-workspaces__menu-container {
    margin-left: 12px;
  }
}

@media (max-width: 767px) {

  .navbar-workspaces__menu-container {
    display: block;
  }

  .navbar-workspaces__container-bg {
    display: none;
  }
}
</style>