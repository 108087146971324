var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _vm.loading
        ? _c("GhostLoading", {
            attrs: { type: "table", title: _vm.TABLE_TITLE }
          })
        : _c("Table", {
            attrs: {
              title: _vm.TABLE_TITLE,
              rows: _vm.rows,
              headers: _vm.headers,
              rowsPerPage: 25
            },
            on: {
              "click-subject": _vm.editVorgang,
              "action-DELETE_BERICHT": _vm.deleteBericht,
              "action-COPY_BESUCHSBERICHT": _vm.copyBericht,
              "action-DELETE_BEMERKUNG": _vm.deleteBemerkung,
              "action-EDIT": _vm.editVorgang,
              "action-PDF": _vm.showPDF
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }