<template>
    <BaseModal ref="modal"
        @onCloseButton="close"
        :showDefaultButtons="false">
        <template v-slot:modalTitle>
            <!-- <InputField label="Name" v-model="name"/> -->
            <div class="mb-4">Erfassen Sie eine E-Mailadresse, an die die Registrierungsaufforderung für einen Unterzugang zur {{userLevel}} versendet wird.</div>
            <InputField v-model="email"/>
        </template>
        <template v-slot:footer>
            <BaseButton @click="close" isSecondary>Abbrechen</BaseButton>
            <BaseButton @click="invite" :disabled="!validateEmail(email)" isPrimary>Einladen</BaseButton>
        </template>
    </BaseModal>
</template>
<script>
import CORE_TYPES from '@/store/core/types';
import SSO_TYPES from '@/store/sso/types';
import LOG_TYPES from '@/store/log/types';
import { mapGetters } from 'vuex';

import { buildMessage } from '@/helpers/log-message-helper';

import BaseModal from '@/components/core/BaseModal.vue';
import InputField from '@/components/core/forms/InputField.vue';
import BaseButton from '@/components/core/BaseButton.vue'
import validator from "@/mixins/validator";
import { email } from "@/mixins/validator/rules";

export default {
    mixins: [validator],
    components: {
        BaseModal,
        InputField,
        BaseButton,
    },
    emits: ['close'],
    validators: {
        email: [email(undefined, {emptyAllowed: true})],
    },
    data() {
        return {
            name: "",
            email: "",
        };
    },
    computed: {
        ...mapGetters({
            isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
        }),
        userLevel() {
            return this.isCustomer ? 'Kundennummer' : 'Vermittlernummer';
        }
    },
    mounted() {
        this.$refs.modal.open();
    },
    methods: {
        validateEmail(email) {
            var re = /\S+@\S+\.\S+/;
            return re.test(email);
        },
        invite() {
            this.$store.dispatch(SSO_TYPES.ACTIONS.INVITE_TO_SSO, {
                // loginName: this.name,
                email: this.email,
            }).then(response => {
                this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage("Die Einladung wurde erfolgreich versendet.", 'success'));
                this.close();
            }).catch(error => {
                this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage("Die Einladung konnte nicht versendet werden. Bitte überprüfen Sie die E-Mail Adresse", 'danger'));
            });
        },
        close() {
            this.$refs.modal.close();
            this.$emit('close');
        },
    },
}
</script>
<style scoped>

</style>