var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.bezeichnung || "Beratungstitel",
          actions: _vm.actions
        },
        on: {
          "action-OPEN_ERZEUGEN": function($event) {
            return _vm.openErzeugen()
          },
          "action-OPEN_SIGNIEREN": function($event) {
            return _vm.openSignieren()
          },
          "action-OPEN_MAIL": function($event) {
            return _vm.openMail()
          },
          "action-EDIT_BEMERKUNG": function($event) {
            return _vm.editBemerkung()
          },
          "action-ADD_ACTIVITY": function($event) {
            return _vm.handleAddActivity()
          },
          "action-FORTSETZEN": function($event) {
            return _vm.beratungsmappeFortsetzen()
          },
          "action-ABSCHLIESSEN_CONFIRMATION": function($event) {
            return _vm.doMappeAbschliessenConfirmation()
          },
          "action-CHANGE_DESCRIPTION": function($event) {
            return _vm.openEditBeratungsmappe()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "subtitle",
            fn: function() {
              return [
                _c("span", [_vm._v(_vm._s(_vm.pageHeaderSubtitle))]),
                _vm.statusText ? _c("span", [_vm._v(" | ")]) : _vm._e(),
                _vm.statusText
                  ? _c("Pill", {
                      attrs: { label: _vm.statusText, type: "danger" }
                    })
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "BaseModal",
        {
          ref: "editBeratungsmappe",
          attrs: {
            modalTitle: "Beratungsmappe Einstellungen",
            labelButtonConfirm: "Übernehmen",
            labelButtonCancel: "Abbrechen",
            size: "sm"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.saveBeratungsmappe()
            }
          }
        },
        [
          _c("InputField", {
            attrs: { label: "Titel" },
            model: {
              value: _vm.tempBezeichnung,
              callback: function($$v) {
                _vm.tempBezeichnung = $$v
              },
              expression: "tempBezeichnung"
            }
          })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "deleteAntraegeConfirmation",
          attrs: {
            modalTitle: "Antrag löschen",
            labelButtonConfirm: "Löschen"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.deleteAntraege()
            }
          }
        },
        [_vm._v(" " + _vm._s(_vm.deleteAntraegMessage) + " ")]
      ),
      _c(
        "BaseModal",
        {
          ref: "deletePositionConfirmation",
          attrs: {
            modalTitle: "Position löschen",
            labelButtonConfirm: "Löschen"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.deletePosition()
            }
          }
        },
        [_vm._v(" " + _vm._s(_vm.deletePositionMessage) + " ")]
      ),
      _c(
        "BaseModal",
        {
          ref: "nichtImplementiertModal",
          attrs: {
            modalTitle: "Nicht implementiert!",
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: true
          }
        },
        [_vm._v(" Formular wurde noch nicht implementiert ")]
      ),
      _c(
        "BaseModal",
        {
          ref: "mappeAbschliessenConfirmation",
          attrs: {
            modalTitle: "Beratungsmappe abschließen",
            labelButtonConfirm: "Abschließen"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.beratungsmappeAbschliessen()
            }
          }
        },
        [
          _vm._v(
            " Beratungsmappe abschließen? Wenn die Beratungsmappe abgeschlossen wird, sind keine weiteren Änderungen möglich. "
          )
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "editBemerkung",
          attrs: {
            modalTitle: "Beratungsmappe Bemerkung",
            labelButtonConfirm: "Speichern"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.saveBemerkung()
            }
          }
        },
        [
          _c("InputTextArea", {
            attrs: {
              label:
                "Bemerkung zur Beratung / Vermittlung (für Berater des Kunden sichtbar)"
            },
            model: {
              value: _vm.tempBemerkung,
              callback: function($$v) {
                _vm.tempBemerkung = $$v
              },
              expression: "tempBemerkung"
            }
          })
        ],
        1
      ),
      _vm.erzeugen && _vm.loadingActions == false
        ? _c("div", { staticClass: "box__container" }, [
            _c("p", [_vm._v(_vm._s(_vm.actionsTitle))]),
            !_vm.beratungVersicherungActions.signedAttachments ||
            !_vm.beratungVersicherungActions.signedAttachments.length
              ? _c("div", [
                  _c("div", { staticClass: "row mt-2" }, [
                    _c(
                      "div",
                      { staticClass: "col-lg-6 col-md-12 mb-2" },
                      [
                        _c(
                          "base-button",
                          {
                            on: {
                              click: function($event) {
                                return _vm.beratungsmappeErzeugen()
                              }
                            }
                          },
                          [_vm._v(" Beratungsmappe erzeugen ")]
                        )
                      ],
                      1
                    )
                  ]),
                  _vm.pdfErzeugenCheckboxesGroups &&
                  _vm.pdfErzeugenCheckboxesGroups.length
                    ? _c(
                        "div",
                        [
                          _vm._l(_vm.pdfErzeugenCheckboxesGroups, function(
                            group,
                            index
                          ) {
                            return _c(
                              "div",
                              { key: index, staticClass: "mt-2" },
                              [
                                group
                                  ? _c("InputCheckBoxGroup", {
                                      attrs: {
                                        title: group.label,
                                        values: group.values,
                                        value: _vm.checkboxValues
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          }),
                          _c("p", { staticClass: "font-bold" }, [
                            _vm._v(_vm._s(_vm.beratungsmappeActions.nachricht))
                          ])
                        ],
                        2
                      )
                    : _vm._e()
                ])
              : _vm._e(),
            _vm.beratungVersicherungActions.signedAttachments &&
            _vm.beratungVersicherungActions.signedAttachments.length
              ? _c(
                  "div",
                  [
                    _c("div", { staticClass: "row mt-2" }, [
                      _c(
                        "div",
                        { staticClass: "col-lg-6 col-md-12 mb-2" },
                        [
                          _c(
                            "base-button",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.listeErneutErzeugen()
                                }
                              }
                            },
                            [_vm._v(" Liste erneut erzeugen ")]
                          )
                        ],
                        1
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        "Die Dokumente können nicht zu einer Datei zusammengefasst werden, da sie z.T. digital signiert wurden. Sie können die Dateien hier einzeln abrufen:"
                      )
                    ]),
                    _vm._m(0),
                    _vm._m(1),
                    _vm._l(_vm.signedAttachments, function(sa) {
                      return _c("div", { key: sa.label }, [
                        _c(
                          "div",
                          {
                            staticClass: "clickable",
                            attrs: { "(click)": "showFileContent(sa.bytes)" }
                          },
                          [_vm._v("- " + _vm._s(sa.fileName))]
                        )
                      ])
                    })
                  ],
                  2
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm.signieren && _vm.loadingActions == false
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("p", [_vm._v(_vm._s(_vm.actionsTitle))]),
              _vm._l(
                _vm.beratungVersicherungActions.signableDocuments,
                function(signableDocument, index) {
                  return _c("div", { key: index }, [
                    signableDocument.link.value
                      ? _c(
                          "a",
                          {
                            attrs: {
                              target: "_blank",
                              href: signableDocument.link.value
                            }
                          },
                          [_vm._v(_vm._s(signableDocument.link.label))]
                        )
                      : _vm._e(),
                    !signableDocument.link.value &&
                    !signableDocument.antragsdatenId
                      ? _c("span", { staticClass: "grayed-out" }, [
                          _vm._v(_vm._s(signableDocument.link.label))
                        ])
                      : _vm._e(),
                    !signableDocument.link.value &&
                    signableDocument.antragsdatenId
                      ? _c(
                          "a",
                          {
                            attrs: { target: "_blank" },
                            on: {
                              click: function($event) {
                                return _vm.getSignedFiles(signableDocument)
                              }
                            }
                          },
                          [_vm._v(_vm._s(signableDocument.link.label))]
                        )
                      : _vm._e()
                  ])
                }
              ),
              _c("p", [
                _vm._v(_vm._s(_vm.beratungVersicherungActions.nachricht))
              ])
            ],
            2
          )
        : _vm._e(),
      _vm.versenden && _vm.loadingActions == false
        ? _c("div", { staticClass: "box__container" }, [
            _c("p", [_vm._v(_vm._s(_vm.actionsTitle))]),
            !_vm.beratungVersicherungActions.signedAttachments ||
            !_vm.beratungVersicherungActions.signedAttachments.length
              ? _c("div", [
                  _c("div", { staticClass: "row mt-2" }, [
                    _c(
                      "div",
                      { staticClass: "col-lg-6 col-md-12 mb-2" },
                      [
                        _c(
                          "BaseButton",
                          {
                            staticClass: "btn btn-primary ",
                            on: {
                              click: function($event) {
                                return _vm.beratungsmappeVersenden()
                              }
                            }
                          },
                          [_vm._v(" Beratungsmappe versenden ")]
                        )
                      ],
                      1
                    )
                  ]),
                  _vm.pdfErzeugenCheckboxesGroups &&
                  _vm.pdfErzeugenCheckboxesGroups.length
                    ? _c(
                        "div",
                        [
                          _vm._l(_vm.pdfErzeugenCheckboxesGroups, function(
                            group,
                            index
                          ) {
                            return _c(
                              "div",
                              { key: index, staticClass: "mt-2" },
                              [
                                group
                                  ? _c("InputCheckBoxGroup", {
                                      attrs: {
                                        title: group.label,
                                        values: group.values,
                                        value: _vm.checkboxValues
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          }),
                          _c("p", { staticClass: "font-bold" }, [
                            _vm._v(_vm._s(_vm.beratungsmappeActions.nachricht))
                          ])
                        ],
                        2
                      )
                    : _vm._e()
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm.beratungVersicherungenCards && _vm.cardsData && _vm.cardsData.length
        ? _c("div", { staticClass: "beratung-edit__main" }, [
            _c(
              "div",
              { staticClass: "beratung-edit__sidebar" },
              [
                _c("BoxList", {
                  attrs: { items: _vm.cardsData },
                  on: { clickItem: _vm.handleCardItemClick },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "icon",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            item.key == "unterschriften_info_text"
                              ? _c("ph-info", {
                                  staticClass: "color-info",
                                  attrs: { size: 24 }
                                })
                              : item.key == "antrage_box"
                              ? _c("ph-info", {
                                  staticClass: "color-info",
                                  attrs: { size: 24 }
                                })
                              : item.key == "erfassung_position_papierantrag"
                              ? _c("ph-info", {
                                  staticClass: "color-info",
                                  attrs: { size: 24 }
                                })
                              : item.key == "unterschriften_question_mark_text"
                              ? _c("ph-question", {
                                  staticClass: "color-info",
                                  attrs: { size: 24 }
                                })
                              : item.status == "success"
                              ? _c("ph-check-circle", {
                                  staticClass: "color-success",
                                  attrs: { size: 24 }
                                })
                              : item.status == "warning"
                              ? _c("ph-warning-circle", {
                                  staticClass: "color-warning",
                                  attrs: { size: 24 }
                                })
                              : item.status == "danger"
                              ? _c("ph-warning-circle", {
                                  staticClass: "color-danger",
                                  attrs: { size: 24 }
                                })
                              : _c("ph-circle", {
                                  staticClass: "color-primary",
                                  attrs: { size: 24 }
                                })
                          ]
                        }
                      },
                      {
                        key: "default",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            item.key !== "unterschriften_info_text" &&
                            item.key !== "unterschriften_question_mark_text"
                              ? _c("h3", { staticClass: "mt-0 mb-1" }, [
                                  _vm._v(_vm._s(item.label))
                                ])
                              : _vm._e(),
                            item.key !== "unterschriften_info_text"
                              ? _c(
                                  "p",
                                  {
                                    staticClass: "mt-0 mb-0",
                                    attrs: {
                                      tid:
                                        item.description &&
                                        item.description.length
                                          ? _vm._generateTidFromString(
                                              item.description + item.key
                                            )
                                          : null
                                    }
                                  },
                                  [
                                    _c("small", [
                                      _vm._v(_vm._s(item.description))
                                    ])
                                  ]
                                )
                              : _vm._e(),
                            item.key == "unterschriften_info_text"
                              ? _c("p", { staticClass: "mt-0 mb-0" }, [
                                  _c("small", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.sanitize(_vm.unterschriftenModalMsg)
                                      )
                                    }
                                  })
                                ])
                              : _vm._e()
                          ]
                        }
                      },
                      {
                        key: "link",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            item.clickable && item.key !== "antrage_box"
                              ? _c("a", { attrs: { href: "javascript:;" } }, [
                                  _vm._v(_vm._s(item.linkText))
                                ])
                              : _vm._e(),
                            item.key == "antrage_box" && _vm.isFA == false
                              ? _c(
                                  "a",
                                  {
                                    attrs: {
                                      tid: _vm._generateTidFromString(
                                        item.description + item.linkText
                                      ),
                                      href: "javascript:;"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.showVergleichsrechner()
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(item.linkText))]
                                )
                              : _vm._e(),
                            item.key == "antrage_box" && _vm.isFA == true
                              ? _c(
                                  "a",
                                  {
                                    attrs: {
                                      tid: _vm._generateTidFromString(
                                        item.description + item.linkText
                                      ),
                                      href: "javascript:;"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.dokumenteAbholen()
                                      }
                                    }
                                  },
                                  [_vm._v("Dokumente abholen")]
                                )
                              : _vm._e(),
                            item.key == "antrage_box" && _vm.isFA == true
                              ? _c("span", [_vm._v(" | ")])
                              : _vm._e(),
                            item.key == "antrage_box" && _vm.isFA == true
                              ? _c(
                                  "a",
                                  {
                                    attrs: {
                                      tid: _vm._generateTidFromString(
                                        item.description + item.linkText
                                      )
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.openExterneZugangBank(
                                          _vm.cheggnetData
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("Chegg.net")]
                                )
                              : _vm._e(),
                            item.buttonVorlegenAktiv &&
                            item.buttonVorlegenAktiv == true &&
                            item.key == "unterschriften"
                              ? _c(
                                  "a",
                                  {
                                    attrs: {
                                      tid: _vm._generateTidFromString(
                                        item.description + item.linkText
                                      ),
                                      href: "javascript:;"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.zurESignaturVorlegen(item)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(item.linkText))]
                                )
                              : _vm._e(),
                            item.buttonVorlegenAktiv == false &&
                            item.key == "unterschriften"
                              ? _c("span", [_vm._v(_vm._s(item.linkText))])
                              : _vm._e()
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3499075832
                  )
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "beratung-edit__content" },
              [
                _vm.showForms == true
                  ? _c("OptionMenu", {
                      attrs: {
                        id: "fc3b303c-544b-4528-8aa8-7840fe07c1f1",
                        defaultMenu: _vm.customOptionMenu,
                        showEditButton: false,
                        customOptionMenu: _vm.customOptionMenu,
                        ignoreRender: false
                      },
                      on: {
                        configuredMenu: function($event) {
                          return _vm.setConfiguredLagerstelleList($event)
                        }
                      }
                    })
                  : _vm._e(),
                _vm.showForms == true &&
                _vm.selectedLagerstelle &&
                _vm.selectedLagerstelle.path
                  ? _c(
                      "div",
                      [
                        _c(
                          "div",
                          {
                            staticClass: "box__container",
                            attrs: {
                              tid: _vm._generateTidFromString("box__container")
                            }
                          },
                          [
                            _c("div", { staticClass: "box__title" }, [
                              _vm._v(_vm._s(_vm.selectedLagerstelle.label))
                            ]),
                            _c("InputField", {
                              attrs: {
                                isComponentHalfSize: "",
                                placeholder: "Suchen nach Bezeichnung"
                              },
                              model: {
                                value: _vm.descriptionSearch,
                                callback: function($$v) {
                                  _vm.descriptionSearch = $$v
                                },
                                expression: "descriptionSearch"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._l(_vm.groupedForms, function(value, propName) {
                          return _c(
                            "div",
                            { key: propName, staticClass: "box__container" },
                            [
                              _c("Table", {
                                staticClass: "formulare-list__table",
                                attrs: {
                                  tableId:
                                    "0eca709b-022c-4078-871f-633007348bae",
                                  tableData: _vm.formulareTableData(
                                    value,
                                    propName
                                  ),
                                  cardViewEnabled: false,
                                  filterEnabled: false,
                                  exportEnabled: false,
                                  paginationEnabled: false,
                                  rowsPerPage: value.length,
                                  additionalCollapsed: true
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function(ref) {
                                        var data = ref.data
                                        return [
                                          _c(
                                            "a",
                                            {
                                              on: {
                                                click: function($event) {
                                                  return _vm.handleLabelClick(
                                                    data.row
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(data.row.label) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          )
                        })
                      ],
                      2
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "box__container" },
                  [
                    _c("p", [_vm._v("Anträge")]),
                    _vm.beratungVersicherungenCards.antraege &&
                    _vm.beratungVersicherungenCards.antraege.antraegeList &&
                    _vm.beratungVersicherungenCards.antraege.antraegeList
                      .records.length
                      ? _c("Table", {
                          staticClass: "table-container",
                          attrs: {
                            tableData: _vm.antraegTableData,
                            cardViewEnabled: false,
                            filterEnabled: false,
                            exportEnabled: false,
                            paginationEnabled: false,
                            actions: _vm.antragTableActions
                          },
                          on: { "execute-action": _vm.handleAction },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "status",
                                fn: function(props) {
                                  return [
                                    props.data.row.Status == "success"
                                      ? _c("Pill", {
                                          attrs: {
                                            label: "fehlerfrei",
                                            type: "success"
                                          }
                                        })
                                      : _vm._e(),
                                    props.data.row.Status == "warning"
                                      ? _c("Pill", {
                                          attrs: {
                                            label: "fehlerhaft",
                                            type: "warning"
                                          }
                                        })
                                      : _vm._e(),
                                    props.data.row.Status == "danger"
                                      ? _c("Pill", {
                                          attrs: {
                                            label: "fehlerhaft",
                                            type: "danger"
                                          }
                                        })
                                      : _vm._e()
                                  ]
                                }
                              },
                              {
                                key: "antragTitle",
                                fn: function(props) {
                                  return [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          tid: _vm._generateTidFromString(
                                            "antraegTable" + props.data.index
                                          ),
                                          disabled: !props.data.row.Gesellschaft
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.editAntraeg(
                                              props.data.row
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(props.data.row.Antrag))]
                                    )
                                  ]
                                }
                              },
                              {
                                key: "lastColumn",
                                fn: function(props) {
                                  return [
                                    _c("ph-trash", {
                                      attrs: { size: 16 },
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteAntraegeConfirmation(
                                            props.data.row
                                          )
                                        }
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3720209629
                          )
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _vm.showingVergleichsrechner
                  ? _c(
                      "div",
                      { staticClass: "box__container" },
                      [
                        _c("p", [_vm._v("Externe Rechner")]),
                        _c("Table", {
                          attrs: {
                            tableId: "cbbf3d4b-eec7-469b-9504-2e197d4de462",
                            tableData: _vm.bestehendeVertrageData,
                            cardViewEnabled: false,
                            filterEnabled: false,
                            exportEnabled: false,
                            paginationEnabled: false
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "itemLabel",
                                fn: function(props) {
                                  return [
                                    props.data.row.disabled
                                      ? _c(
                                          "div",
                                          {
                                            attrs: {
                                              tid: _vm._generateTidFromString(
                                                "vergleichsrechnerTable-disabled" +
                                                  props.index
                                              )
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  props.data.row.itemLabel
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      : _c(
                                          "a",
                                          {
                                            attrs: {
                                              tid: _vm._generateTidFromString(
                                                "vergleichsrechnerTable" +
                                                  props.data.index
                                              )
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.askOptionsExterneZugang(
                                                  props.data.row
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(props.data.row.itemLabel)
                                            )
                                          ]
                                        )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3152359576
                          )
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "box__container" },
                  [
                    _c(
                      "BaseButton",
                      {
                        attrs: {
                          isSecondary: "",
                          disabled:
                            !_vm.allSelectedLoaded || _vm.numberSelected !== 2
                        },
                        on: {
                          click: function($event) {
                            return _vm.openVertragVereinigenModal()
                          }
                        }
                      },
                      [_vm._v(" Vertrag vereinigen ")]
                    ),
                    _c("InsuranceGroupTable", {
                      attrs: {
                        tableId: "0cfc1fd4-f01b-4727-bf5d-3207a32fb6f4",
                        headers: _vm.headers,
                        pages: _vm.pages,
                        count: _vm.insurancesCount,
                        pageCount: _vm.pageCount,
                        rowsPerPage: _vm.rowsPerPage,
                        isLoading: _vm.isLoading,
                        selected: _vm.selectedRows,
                        selectionAsBlacklist: _vm.selectionAsBlacklist,
                        dragnDropFilesOnRow: ""
                      },
                      on: {
                        selected: _vm.onSelect,
                        selectionAsBlacklist: function($event) {
                          _vm.selectionAsBlacklist = $event
                        },
                        action: function($event) {
                          return _vm.handleInsuranceAction($event)
                        },
                        requestPage: _vm.requestPage,
                        "item-selected": function($event) {
                          return _vm.openItemSelectedDetail($event)
                        },
                        "row-files": function($event) {
                          return _vm.handleRowFiles($event)
                        },
                        onSort: function($event) {
                          return _vm.sortDataByColumn($event)
                        }
                      }
                    })
                  ],
                  1
                ),
                _vm.askOptions
                  ? _c("ExterneZugangAskOptions", {
                      attrs: {
                        askOptions: _vm.askOptions,
                        title: _vm.externeZugang.companyLabel
                      },
                      on: {
                        close: function($event) {
                          return _vm.resetExterneZugang()
                        },
                        onConfirmButton: function($event) {
                          return _vm.askOptionsConfirmed($event)
                        }
                      }
                    })
                  : _vm._e(),
                _c(
                  "div",
                  [
                    _c("BoxList", {
                      attrs: { items: _vm.rightSideCards },
                      on: { clickItem: _vm.handleCardItemClick },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                _c("div", [
                                  item.key !== "unterschriften_info_text" &&
                                  item.key !==
                                    "unterschriften_question_mark_text"
                                    ? _c(
                                        "h3",
                                        {
                                          staticClass: "mt-0 mb-1",
                                          attrs: {
                                            tid:
                                              item.label && item.label.length
                                                ? _vm._generateTidFromString(
                                                    item.label + item.key
                                                  )
                                                : null
                                          }
                                        },
                                        [_vm._v(_vm._s(item.label))]
                                      )
                                    : _vm._e(),
                                  item.key !== "unterschriften_info_text"
                                    ? _c(
                                        "p",
                                        {
                                          staticClass: "mt-0 mb-0",
                                          attrs: {
                                            tid:
                                              item.description &&
                                              item.label.description
                                                ? _vm._generateTidFromString(
                                                    item.description + item.key
                                                  )
                                                : null
                                          }
                                        },
                                        [
                                          _c("small", [
                                            _vm._v(_vm._s(item.description))
                                          ])
                                        ]
                                      )
                                    : _vm._e(),
                                  item.key == "unterschriften_info_text"
                                    ? _c("p", { staticClass: "mt-0 mb-0" }, [
                                        _c("small", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.sanitize(
                                                _vm.unterschriftenModalMsg
                                              )
                                            )
                                          }
                                        })
                                      ])
                                    : _vm._e()
                                ])
                              ]
                            }
                          },
                          {
                            key: "link",
                            fn: function(ref) {
                              var item = ref.item
                              return [
                                item.clickable
                                  ? _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: "javascript:;",
                                          tid:
                                            item.linkText &&
                                            item.linkText.length
                                              ? _vm._generateTidFromString(
                                                  item.linkText + item.key
                                                )
                                              : null
                                        }
                                      },
                                      [_vm._v(_vm._s(item.linkText))]
                                    )
                                  : _vm._e(),
                                item.buttonVorlegenAktiv &&
                                item.buttonVorlegenAktiv == true &&
                                item.key == "unterschriften"
                                  ? _c(
                                      "a",
                                      {
                                        attrs: { href: "javascript:;" },
                                        on: {
                                          click: function($event) {
                                            return _vm.zurESignaturVorlegen(
                                              item
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(item.linkText))]
                                    )
                                  : _vm._e(),
                                item.buttonVorlegenAktiv == false &&
                                item.key == "unterschriften"
                                  ? _c("span", [_vm._v(_vm._s(item.linkText))])
                                  : _vm._e()
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2999969414
                      )
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _c("VertragVereinigenModalNew", {
        ref: "vertragVereinigenModalNew",
        attrs: { vertragList: _vm.selectedAndLoaded },
        on: {
          save: function($event) {
            return _vm.mergeInsurances($event)
          }
        }
      }),
      _c(
        "BaseModal",
        {
          ref: "unterschriftenModal",
          attrs: {
            labelButtonCancel: "Abbrechen",
            showCancelButton: false,
            showConfirmButton: true,
            labelButtonConfirm: "Ok",
            size: "lg"
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [_vm._v(" Information ")]
              },
              proxy: true
            }
          ])
        },
        [
          _vm.unterschriftenModalMsg
            ? _c("div", {
                staticClass: "mb-1",
                domProps: {
                  innerHTML: _vm._s(_vm.sanitize(_vm.unterschriftenModalMsg))
                }
              })
            : _vm._e()
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "zusatzDok",
          attrs: {
            labelButtonConfirm: "Weiter zur Unterschrift",
            labelButtonCancel: "Abbrechen",
            size: "lg"
          },
          on: {
            onConfirmButton: _vm.sendZusatzDoks,
            onCancelButton: _vm.closeZusatzListModal
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-warning", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v(" Zusätzliche Dokumente ")
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm.pictureAttachMsg
            ? _c("div", { staticClass: "mb-1" }, [
                _vm._v(" " + _vm._s(_vm.pictureAttachMsg) + " ")
              ])
            : _vm._e(),
          _c("div", { staticClass: "font-strong mb-1" }, [
            _vm._v(
              " Wenn Sie möchten können Sie hier Dokumente auswählen, die vor der e-Signatur zum Antrag hinzugefügt werden. "
            )
          ]),
          _c("div", { staticClass: "font-strong mb-1" }, [
            _vm._v(
              " Andernfalls können Sie direkt mit der Unterschrift fortfahren. "
            )
          ]),
          _c("div", { staticClass: "mb-1" }, [
            _vm._v(
              " Es werden alle geeigneten PDF-Dokumente aus den allgemeinen Dokumenten im Dokumentenarchiv angezeigt (ausgenommen sind etwa digital signierte Dateien). "
            )
          ]),
          _c("div", { staticClass: "mb-1" }, [
            _vm._v(
              " Sie können auch ein PDF-Dokument neu hinzufügen, dieses wird dann ebenfalls im Dokumentenarchiv gespeichert. "
            )
          ]),
          _c("div", { staticClass: "mb-1" }, [
            _vm._v(
              " Bitte beachten Sie, dass das Anfügen der Dokumente dauerhaft ist, auch wenn Sie den Antrag doch nicht signieren. "
            )
          ]),
          _vm._l(_vm.zusatzList, function(dok, index) {
            return _c("div", { key: index, staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("InputCheckBoxItem", {
                    attrs: {
                      label: dok.bezeichnung + " (" + dok.herkunft + ")",
                      inLineLabel: ""
                    },
                    model: {
                      value: _vm.zusatzDokForm[dok.id],
                      callback: function($$v) {
                        _vm.$set(_vm.zusatzDokForm, dok.id, $$v)
                      },
                      expression: "zusatzDokForm[dok.id]"
                    }
                  })
                ],
                1
              )
            ])
          })
        ],
        2
      ),
      _c(
        "BaseModal",
        {
          ref: "teilweise",
          attrs: { size: "lg", showDefaultButtons: false },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-warning", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v(" Formular ist bereits teilweise signiert ")
                ]
              },
              proxy: true
            },
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "div",
                    { staticClass: "col-auto" },
                    [
                      _c(
                        "BaseButton",
                        {
                          attrs: { isSecondary: "" },
                          on: {
                            click: function($event) {
                              return _vm.pdfNeuErstellen()
                            }
                          }
                        },
                        [_vm._v("PDF new erstellen")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-auto" },
                    [
                      _c(
                        "BaseButton",
                        {
                          attrs: { isSecondary: "" },
                          on: {
                            click: function($event) {
                              return _vm.showSignoListFromTeilweise()
                            }
                          }
                        },
                        [_vm._v("PDF unverändert lassen")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-auto" },
                    [
                      _c("BaseButton", { attrs: { isSecondary: "" } }, [
                        _vm._v("Abbrechen")
                      ])
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", [
            _vm._v(
              " Das Dokument wurde bereits teilweise elektronisch signiert. "
            ),
            _c("br"),
            _vm._v(
              " Möchten Sie eine neue PDF mit den geänderten Daten erstellen (alle bisherigen Unterschriften gehen verloren) oder die Daten nur speichern ohne sie in die PDF zu übernehmen? "
            )
          ])
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "mailContacts",
          attrs: {
            modalTitle: "Kontakte",
            size: "lg",
            showCancelButton: false,
            labelButtonConfirm: "Auswahl übernehmen"
          },
          on: { onConfirmButton: _vm.confirmContactsToSend }
        },
        [
          _c("InsuranceCompanyMailContacts", {
            attrs: { records: _vm.mailUsers },
            on: { selectedRows: _vm.onSelectedRow }
          })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "dokumenteAbholenModal",
          attrs: {
            modalTitle: "Dokumente abholen",
            showDefaultButtons: false,
            size: "lg"
          }
        },
        [_vm._v(" " + _vm._s(_vm.dokumenteAbholenResult.value) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [_vm._v("Nicht digital signierte Dokumente / Scans")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_vm._v("Digital signierte Dokument")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }