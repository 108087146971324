var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      !_vm.embedded
        ? _c("PageHeaderTitleNavigation", {
            attrs: {
              id: _vm.$appNavigation.currentOptionMenuId,
              title: _vm.title,
              actions: _vm.headerActions
            },
            on: {
              "action-OPEN-CUSTOMER": function($event) {
                return _vm.openCustomerNewTab()
              },
              "action-OPEN-BROKER": function($event) {
                return _vm.openBrokerNewTab()
              },
              "action-OPEN-INTERN": function($event) {
                return _vm.openInternNewTab()
              }
            }
          })
        : _vm._e(),
      _vm.exceptionLog
        ? _c("div", { staticClass: "box__container" }, [
            _c("p", { staticClass: "m-0 font-bold" }, [_vm._v("Message:")]),
            _c("div", { staticClass: "m-0  ml-4" }, [
              _vm._v(" " + _vm._s(_vm.exceptionLog.message) + " ")
            ]),
            _c("p", { staticClass: "m-0 font-bold" }, [_vm._v("Stacktrace:")]),
            _c(
              "div",
              { staticClass: "m-0 ml-4" },
              _vm._l(_vm.stacktrace, function(trace) {
                return _c(
                  "span",
                  {
                    staticClass: "break-line",
                    class: { class: trace.type == "class" }
                  },
                  [
                    trace.type == "break" ? _c("br") : _vm._e(),
                    _vm._v(_vm._s(trace.content) + " ")
                  ]
                )
              }),
              0
            ),
            _c("p", { staticClass: "m-0 font-bold" }, [
              _vm._v("Request Data:")
            ]),
            _c("div", { staticClass: "m-0 ml-4" }, [
              _vm._v(" " + _vm._s(_vm.exceptionLog.requestData) + " ")
            ]),
            _c("p", { staticClass: "m-0 font-bold" }, [_vm._v("App Name:")]),
            _c("div", { staticClass: "m-0 ml-4" }, [
              _vm._v(" " + _vm._s(_vm.exceptionLog.appName) + " ")
            ]),
            _c("p", { staticClass: "m-0 font-bold" }, [
              _vm._v("Servlet Name:")
            ]),
            _c("div", { staticClass: "m-0 ml-4" }, [
              _vm._v(" " + _vm._s(_vm.exceptionLog.servletName) + " ")
            ]),
            _c("p", { staticClass: "m-0 font-bold" }, [_vm._v("Date:")]),
            _c("div", { staticClass: "m-0 ml-4" }, [
              _vm._v(" " + _vm._s(_vm.date) + " ")
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }