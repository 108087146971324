export const MUTATION_PREFIX = 'MAILCOMPOSER:';
export const ACTIONS_PREFIX = 'MAILCOMPOSER_ACTIONS_';
export const GETTERS_PREFIX = 'MAILCOMPOSER_GETTERS_';

export default {
  MUTATIONS: {
    SET_MAILCOMPOSER_CONFIG: MUTATION_PREFIX + 'SET_MAILCOMPOSER_CONFIG',
    GET_SENDERS: MUTATION_PREFIX + 'GET_SENDERS',
    GET_PLACEHOLDERS: MUTATION_PREFIX + 'GET_PLACEHOLDERS',
    SET_UNSET_PLACEHOLDERS: MUTATION_PREFIX + 'SET_UNSET_PLACEHOLDERS',
    GET_HTML_TEMPLATES: MUTATION_PREFIX + 'GET_HTML_TEMPLATES',
    GET_HTML_TEMPLATE: MUTATION_PREFIX + 'GET_HTML_TEMPLATE',
    GET_HTML_TEXT: MUTATION_PREFIX + 'GET_HTML_TEXT',
    GET_PREVIEW: MUTATION_PREFIX + 'GET_PREVIEW',
    SEND_MAIL: MUTATION_PREFIX + 'SEND_MAIL',
    SEND_TEST_MAIL: MUTATION_PREFIX + 'SEND_TEST_MAIL',
    APPZUSENDEN: MUTATION_PREFIX + 'APPZUSENDEN',
    SET_PARTICIPANTS: MUTATION_PREFIX + 'SET_PARTICIPANTS',
    SET_ATTACHMENTS: MUTATION_PREFIX + 'SET_ATTACHMENTS',
    SET_PDF: MUTATION_PREFIX + 'SET_PDF',
    SEND_FAX: MUTATION_PREFIX + 'SEND_FAX',
    SEND_BRIEF: MUTATION_PREFIX + 'SEND_BRIEF',
    WORD_TEMPLATES: MUTATION_PREFIX + 'WORD_TEMPLATES',
    GET_WORD_TEMPLATE: MUTATION_PREFIX + 'GET_WORD_TEMPLATE',
    TEST_SENDERS: MUTATION_PREFIX + 'TEST_SENDERS',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    GET_INIT: MUTATION_PREFIX + 'GET_INIT',
    GET_INIT_MULTIPLE: MUTATION_PREFIX + 'GET_INIT_MULTIPLE',
    SET_MAIL_LINKED_OBJECT: MUTATION_PREFIX + 'SET_MAIL_LINKED_OBJECT',
    INIT_CANCEL_INSURANCE: MUTATION_PREFIX + 'INIT_CANCEL_INSURANCE',
    GET_CONSTANTS: MUTATION_PREFIX + 'GET_CONSTANTS',
    GET_MAIL_MODUS: MUTATION_PREFIX + 'GET_MAIL_MODUS',
    INIT_MAIL_ANTRAG: MUTATION_PREFIX + 'INIT_MAIL_ANTRAG',
    INIT_MAIL_ANSPRECHPARTNER: MUTATION_PREFIX + 'INIT_MAIL_ANSPRECHPARTNER',
    INIT_MAIL_APPOINTMENT: MUTATION_PREFIX + 'INIT_MAIL_APPOINTMENT',
    INIT_MAIL_SCHADENSMELDUNG: MUTATION_PREFIX + 'INIT_MAIL_SCHADENSMELDUNG',
    INIT_MAIL_ANLAGEEMPFELUNG: MUTATION_PREFIX + 'INIT_MAIL_ANLAGEEMPFELUNG',
    INIT_MAIL_BERATUNGSMAPPEN: MUTATION_PREFIX + 'INIT_MAIL_BERATUNGSMAPPEN',
    INIT_MAIL_VERSDOKU: MUTATION_PREFIX + 'INIT_MAIL_VERSDOKU',
    INIT_MAIL_VERS_UEBERTRAG_BLOCK_GESELL: MUTATION_PREFIX + 'INIT_MAIL_VERS_UEBERTRAG_BLOCK_GESELL',
    SET_ERROR: MUTATION_PREFIX + 'SET_ERROR',
    SEND_BERICHT_PDF_SUCCESS: MUTATION_PREFIX + 'SEND_BERICHT_PDF_SUCCESS',
    INIT_MAIL_VERMITTLERNUMMERN: MUTATION_PREFIX + 'INIT_MAIL_VERMITTLERNUMMERN',
    INIT_QUARTALSBERICHTE_BROKER_VIEW_EMAIL: MUTATION_PREFIX + 'INIT_QUARTALSBERICHTE_BROKER_VIEW_EMAIL',
    BESTAETIGTE_ABRECHNUNGEN_VERSCHICKEN: MUTATION_PREFIX + 'BESTAETIGTE_ABRECHNUNGEN_VERSCHICKEN',
    ANTRAG_CHECK_MAIL:MUTATION_PREFIX+'ANTRAG_CHECK_MAIL',
    INIT_MAIL_VERTRAGSSPIEGEL: MUTATION_PREFIX + 'INIT_MAIL_VERTRAGSSPIEGEL',
    INIT_MAIL_FONDSHOP_ACTION: MUTATION_PREFIX + 'INIT_MAIL_FONDSHOP_ACTION',
  },

  ACTIONS: {
    GET_SENDERS: ACTIONS_PREFIX + 'GET_SENDERS',
    GET_PLACEHOLDERS: ACTIONS_PREFIX + 'GET_PLACEHOLDERS',
    GET_HTML_TEMPLATES: ACTIONS_PREFIX + 'GET_HTML_TEMPLATES',
    GET_HTML_TEMPLATE: ACTIONS_PREFIX + 'GET_HTML_TEMPLATE',
    GET_HTML_TEXT: ACTIONS_PREFIX + 'GET_HTML_TEXT',
    GET_PREVIEW: ACTIONS_PREFIX + 'GET_PREVIEW',
    SEND_MAIL: ACTIONS_PREFIX + 'SEND_MAIL',
    SEND_TEST_MAIL: ACTIONS_PREFIX + 'SEND_TEST_MAIL',
    APPZUSENDEN: ACTIONS_PREFIX + 'APPZUSENDEN',
    SET_PARTICIPANTS: ACTIONS_PREFIX + 'SET_PARTICIPANTS',
    SET_ATTACHMENTS: ACTIONS_PREFIX + 'SET_ATTACHMENTS',
    SET_PDF: ACTIONS_PREFIX + 'SET_PDF',
    SEND_FAX: ACTIONS_PREFIX + 'SEND_FAX',
    SEND_BRIEF: ACTIONS_PREFIX + 'SEND_BRIEF',
    REMOVE_UNSET_PLACEHOLDERS: ACTIONS_PREFIX + 'REMOVE_UNSET_PLACEHOLDERS',
    WORD_TEMPLATES: ACTIONS_PREFIX + 'WORD_TEMPLATES',
    GET_WORD_TEMPLATE: ACTIONS_PREFIX + 'GET_WORD_TEMPLATE',
    TEST_SENDERS: ACTIONS_PREFIX + 'TEST_SENDERS',
    GET_INIT: ACTIONS_PREFIX + 'GET_INIT',
    GET_INIT_MULTIPLE: ACTIONS_PREFIX + 'GET_INIT_MULTIPLE',
    GET_MAIL_LINKED_OBJECT: ACTIONS_PREFIX + 'GET_MAIL_LINKED_OBJECT',
    INIT_CANCEL_INSURANCE: ACTIONS_PREFIX + 'INIT_CANCEL_INSURANCE',
    INIT_CANCEL_INSURANCE1: ACTIONS_PREFIX + 'INIT_CANCEL_INSURANCE1',
    GET_CONSTANTS: ACTIONS_PREFIX + 'GET_CONSTANTS',
    GET_MAIL_MODUS: ACTIONS_PREFIX + 'GET_MAIL_MODUS',
    INIT_MAIL_ANTRAG: ACTIONS_PREFIX + 'INIT_MAIL_ANTRAG',
    INIT_MAIL_ANSPRECHPARTNER: ACTIONS_PREFIX + 'INIT_MAIL_ANSPRECHPARTNER',
    INIT_MAIL_APPOINTMENT: ACTIONS_PREFIX + 'INIT_MAIL_APPOINTMENT',
    INIT_MAIL_SCHADENSMELDUNG: ACTIONS_PREFIX + 'INIT_MAIL_SCHADENSMELDUNG',
    INIT_MAIL_ANLAGEEMPFELUNG: ACTIONS_PREFIX + 'INIT_MAIL_ANLAGEEMPFELUNG',
    INIT_MAIL_BERATUNGSMAPPEN: ACTIONS_PREFIX + 'INIT_MAIL_BERATUNGSMAPPEN',
    INIT_MAIL_VERSDOKU: ACTIONS_PREFIX + 'INIT_MAIL_VERSDOKU',
    INIT_MAIL_VERS_UEBERTRAG_BLOCK_GESELL: ACTIONS_PREFIX + 'INIT_MAIL_VERS_UEBERTRAG_BLOCK_GESELL',
    SEND_BERICHT_PDF: ACTIONS_PREFIX + 'SEND_BERICHT_PDF',
    INIT_MAIL_VERMITTLERNUMMERN: ACTIONS_PREFIX + 'INIT_MAIL_VERMITTLERNUMMERN',
    INIT_QUARTALSBERICHTE_BROKER_VIEW_EMAIL: ACTIONS_PREFIX + 'INIT_QUARTALSBERICHTE_BROKER_VIEW_EMAIL',
    BESTAETIGTE_ABRECHNUNGEN_VERSCHICKEN: ACTIONS_PREFIX + 'BESTAETIGTE_ABRECHNUNGEN_VERSCHICKEN',
    ANTRAG_CHECK_MAIL:ACTIONS_PREFIX+'ANTRAG_CHECK_MAIL',
    ADD_VOLLMACHT: ACTIONS_PREFIX + 'ADD_VOLLMACHT',
    INIT_MAIL_VERTRAGSSPIEGEL: ACTIONS_PREFIX + 'INIT_MAIL_VERTRAGSSPIEGEL',
    INIT_FONDSHOP_ACTION_MAIL: ACTIONS_PREFIX + 'INIT_FONDSHOP_ACTION_MAIL',
    CHAT_ATTACHMENT_TO_TEMP_FILE: ACTIONS_PREFIX + 'CHAT_ATTACHMENT_TO_TEMP_FILE'
  },

  GETTERS: {
    GET_MAILCOMPOSER_CONFIG: GETTERS_PREFIX + 'GET_MAILCOMPOSER_CONFIG',
    GET_SENDERS: GETTERS_PREFIX + 'GET_SENDERS',
    GET_PLACEHOLDERS: GETTERS_PREFIX + 'GET_PLACEHOLDERS',
    GET_UNSET_PLACEHOLDERS: GETTERS_PREFIX + 'GET_UNSET_PLACEHOLDERS',
    GET_HTML_TEMPLATES: GETTERS_PREFIX + 'GET_HTML_TEMPLATES',
    GET_HTML_TEMPLATE: GETTERS_PREFIX + 'GET_HTML_TEMPLATE',
    GET_HTML_TEXT: GETTERS_PREFIX + 'GET_HTML_TEXT',
    GET_PREVIEW: GETTERS_PREFIX + 'GET_PREVIEW',
    SEND_MAIL: GETTERS_PREFIX + 'SEND_MAIL',
    SEND_TEST_MAIL: GETTERS_PREFIX + 'SEND_TEST_MAIL',
    APPZUSENDEN: GETTERS_PREFIX + 'APPZUSENDEN',
    SET_PARTICIPANTS: GETTERS_PREFIX + 'SET_PARTICIPANTS',
    SET_ATTACHMENTS: GETTERS_PREFIX + 'SET_ATTACHMENTS',
    GET_ATTACHMENTS_CACHE: MUTATION_PREFIX + 'GET_ATTACHMENTS_CACHE',
    SET_PDF: GETTERS_PREFIX + 'SET_PDF',
    SEND_FAX: GETTERS_PREFIX + 'SEND_FAX',
    SEND_BRIEF: GETTERS_PREFIX + 'SEND_BRIEF',
    WORD_TEMPLATES: GETTERS_PREFIX + 'WORD_TEMPLATES',
    GET_WORD_TEMPLATE: GETTERS_PREFIX + 'GET_WORD_TEMPLATE',
    TEST_SENDERS: GETTERS_PREFIX + 'TEST_SENDERS',
    GET_INIT: GETTERS_PREFIX + 'GET_INIT',
    GET_INIT_MULTIPLE: GETTERS_PREFIX + 'GET_INIT_MULTIPLE',
    GET_MAIL_LINKED_OBJECT: GETTERS_PREFIX + 'GET_MAIL_LINKED_OBJECT',
    INIT_CANCEL_INSURANCE: GETTERS_PREFIX + 'INIT_CANCEL_INSURANCE',
    GET_CONSTANTS: GETTERS_PREFIX + 'GET_CONSTANTS',
    GET_MAIL_MODUS: GETTERS_PREFIX + 'GET_MAIL_MODUS',
    INIT_MAIL_ANTRAG: GETTERS_PREFIX + 'INIT_MAIL_ANTRAG',
    INIT_MAIL_ANSPRECHPARTNER: GETTERS_PREFIX + 'INIT_MAIL_ANSPRECHPARTNER',
    INIT_MAIL_APPOINTMENT: GETTERS_PREFIX + 'INIT_MAIL_APPOINTMENT',
    INIT_MAIL_SCHADENSMELDUNG: GETTERS_PREFIX + 'INIT_MAIL_SCHADENSMELDUNG',
    INIT_MAIL_ANLAGEEMPFELUNG: GETTERS_PREFIX + 'INIT_MAIL_ANLAGEEMPFELUNG',
    INIT_MAIL_BERATUNGSMAPPEN: GETTERS_PREFIX + 'INIT_MAIL_BERATUNGSMAPPEN',
    INIT_MAIL_VERSDOKU: GETTERS_PREFIX + 'INIT_MAIL_VERSDOKU',
    INIT_MAIL_VERS_UEBERTRAG_BLOCK_GESELL: GETTERS_PREFIX + 'INIT_MAIL_VERS_UEBERTRAG_BLOCK_GESELL',
    SET_ERROR: GETTERS_PREFIX + 'SET_ERROR',
    GET_BERICHT_PDF: GETTERS_PREFIX + 'GET_BERICHT_PDF',
    INIT_MAIL_VERMITTLERNUMMERN: GETTERS_PREFIX + 'INIT_MAIL_VERMITTLERNUMMERN',
    INIT_QUARTALSBERICHTE_BROKER_VIEW_EMAIL: GETTERS_PREFIX + 'INIT_QUARTALSBERICHTE_BROKER_VIEW_EMAIL',
    BESTAETIGTE_ABRECHNUNGEN_VERSCHICKEN: GETTERS_PREFIX + 'BESTAETIGTE_ABRECHNUNGEN_VERSCHICKEN',
    ANTRAG_CHECK_MAIL:GETTERS_PREFIX+'ANTRAG_CHECK_MAIL',
    INIT_MAIL_VERTRAGSSPIEGEL: GETTERS_PREFIX + 'INIT_MAIL_VERTRAGSSPIEGEL',
    INIT_MAIL_FONDSHOP_ACTION: GETTERS_PREFIX + 'INIT_MAIL_FONDSHOP_ACTION',
  }
}
