import BROKER_PERMISSIONS_TYPES from './types';

export default {

  [BROKER_PERMISSIONS_TYPES.GETTERS.COMBO_DATA](state) {
    return { ...state.comboData || {}, };
  },

  [BROKER_PERMISSIONS_TYPES.GETTERS.PERMISSIONS](state) {
    return { ...state.permissions || {}, };
  },

  [BROKER_PERMISSIONS_TYPES.GETTERS.PERMISSIONS_EDITED](state) {
    return state.permissionsEdited && { ...state.permissionsEdited || {}, } || null;
  },

  [BROKER_PERMISSIONS_TYPES.GETTERS.HAS_PERMISSIONS_EDITED](state) {
    return Object.keys(state.permissionsEdited || {}).length > 0;
  },

  [BROKER_PERMISSIONS_TYPES.GETTERS.ADDITIONAL_PERMISSIONS](state) {
    return (unternr) => {
      const additionalPermissions = state.additionalPermissions || {};
      return { ...additionalPermissions?.[unternr] || {}, };
    };
  },

  [BROKER_PERMISSIONS_TYPES.GETTERS.ADDITIONAL_PERMISSIONS_EDITED](state) {
    return state.additionalPermissionsEdited && { ...state.additionalPermissionsEdited || {}, } || null;
  },

  [BROKER_PERMISSIONS_TYPES.GETTERS.HAS_ADDITIONAL_PERMISSIONS_EDITED](state) {
    return Object.keys(state.additionalPermissionsEdited || {}).length > 0;
  },

}
