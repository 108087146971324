var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "empty-legend": _vm.refresh || !_vm.isLoaded } },
    [
      !_vm.isLoading
        ? [
            _c(
              "div",
              { attrs: { id: "chart-main" } },
              [
                !_vm.isLoaded
                  ? _c("div", [
                      _c(
                        "div",
                        { staticClass: "chart-meldung" },
                        [
                          _c("PhArrowClockwise", {
                            staticClass: "spin-animation",
                            attrs: { size: 16 }
                          }),
                          _vm._v(" Grafikdaten laden ... ")
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _c("LineChart", {
                  ref: "lineChart",
                  attrs: {
                    xAxisType: "category",
                    height: "500px",
                    chartSeries: _vm.refresh
                      ? [{ name: " ", data: [] }]
                      : _vm.series,
                    customColors: _vm.customColors,
                    format: _vm.chartFormat,
                    xLabels: _vm.xLabelsEcharts,
                    isArea: _vm.sparkline,
                    showDataZoom:
                      !_vm.sparkline && _vm.series.length > 0 && !_vm.refresh,
                    gridLeft: 50,
                    chartOptions: _vm.chartOptions,
                    changeOption: _vm.changeOption
                  }
                })
              ],
              1
            ),
            _vm.showTypeTrigger
              ? _c(
                  "div",
                  { staticClass: "mt-2" },
                  [
                    _c("InputToggleSwitch", {
                      staticClass: "m-2",
                      attrs: {
                        inLineLabel: "",
                        label: "Grafik Detailansicht Wertpapier (Mouseover)"
                      },
                      model: {
                        value: _vm.triggerAxis,
                        callback: function($$v) {
                          _vm.triggerAxis = $$v
                        },
                        expression: "triggerAxis"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.showBtnAuswahl
              ? _c(
                  "div",
                  { staticClass: "mt-2" },
                  [
                    _c("InputToggleSwitch", {
                      staticClass: "m-2",
                      attrs: { inLineLabel: "", label: "mit Bestand" },
                      model: {
                        value: _vm.cbMitBestand,
                        callback: function($$v) {
                          _vm.cbMitBestand = $$v
                        },
                        expression: "cbMitBestand"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.lineChart
              ? _c("ChartLegend", {
                  staticClass: "ml-2",
                  attrs: {
                    showAsTable: _vm.showBtnAuswahl,
                    chartSeries: _vm.series,
                    lineChart: _vm.lineChart,
                    addOnHeaders: _vm.addOnHeaders,
                    addOnRows: _vm.addOnRows
                  },
                  on: { changeVisible: _vm.onChangeVisible }
                })
              : _vm._e()
          ]
        : _c("GhostLoading", {
            attrs: { type: "block", config: { block: { height: 500 } } }
          })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }