var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.loading && _vm.rows.length
        ? _c("Table", {
            attrs: {
              tableId: "df281da6-df02-43ec-a3f1-146f4f93993f",
              ignoreTablePageSize: "",
              rowId: "index",
              headers: _vm.headers,
              rows: _vm.rows,
              rowsPerPage: _vm.rowsPerPage,
              tableRowsPerPage: [],
              mobileConfig: _vm.mobileConfig
            },
            on: { "click-name": _vm.openCustomer }
          })
        : _vm.loading
        ? _c("GhostLoading", {
            attrs: {
              type: "table",
              config: { table: { rows: _vm.rowsPerPage } }
            }
          })
        : _c("NoData", { attrs: { noIcon: "" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }