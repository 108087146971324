var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "abfc9406-9725-4cb2-b46b-8486a730651f",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        width: "1171",
        height: "834.6893",
        viewBox: "0 0 1171 834.6893"
      }
    },
    [
      _c("polygon", {
        attrs: {
          points:
            "1124.08 191.421 652.66 394.045 720.59 581.379 769.139 715.243 781.344 748.911 889.978 790.144 893.906 791.633 1007.385 834.689 1061.859 811.391 1136.069 779.493 1171 764.479 1124.08 191.421",
          fill: _vm.thirdColor
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "1136.436 760.209 1136.339 765.065 1136.069 779.493 1061.859 811.391 1011.745 832.93 1009.63 825.862 1007.385 834.689 893.906 791.633 892.147 750.065 892.147 750.055 891.759 740.817 1114.066 725.893 1118.426 732.573 1119.289 733.9 1136.436 760.209",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M989.81,351.24823s-14.91955,53.71037-17.90346,53.71037,28.34714,41.77473,28.34714,41.77473l46.25059,25.36322,16.4115-68.62991s-14.91954-37.29886-11.93563-56.69427Z",
          transform: "translate(-14.5 -32.65535)",
          fill: "#ffb8b8"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "999.18124",
          cy: "284.27792",
          r: "55.20232",
          fill: "#ffb8b8"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1021.141,431.81378,977.03458,393.51l-26.01543,12.94057-56.69428,35.80691s14.91955,322.2622,7.45977,334.19784c0,0,16.4115,37.29887,113.38856,14.91955s122.34028-25.36323,122.34028-25.36323l-20.88737-196.938,25.36323-123.83224-85.19544-59.57505-26.70116,46.14746Z",
          transform: "translate(-14.5 -32.65535)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M857.026,557.138s-22.37931,70.12187-5.96781,77.58164,29.83909,4.47586,29.83909,4.47586l25.36323-61.17014Z",
          transform: "translate(-14.5 -32.65535)",
          fill: "#ffb8b8"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1167.35257,545.20233l13.42759,62.6621V734.68057s-19.39541,80.56555-34.315,59.67819-14.61143-27.50169-14.61143-27.50169l19.0873-29.19259-8.95173-119.35637-23.78572-56.93648Z",
          transform: "translate(-14.5 -32.65535)",
          fill: "#ffb8b8"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1065.89965,261.731s-17.90345-38.79082-52.21841-29.83909-53.71037,22.37932-55.20232,35.80691.746,33.569.746,33.569,3.72988-27.60116,27.60116-21.63334,61.17014,1.49195,61.17014,1.49195l5.96782,53.71037s6.71379-9.69771,14.17357-3.72989S1089.77093,273.66659,1065.89965,261.731Z",
          transform: "translate(-14.5 -32.65535)",
          fill: "#2f2e41"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "1104.095 524.866 1109.491 552.923 1162.367 538.894 1152.655 512.996 1104.095 524.866",
          fill: "#3f3d56"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "841.544 523.987 833.114 551.286 886.377 563.767 889.983 536.343 841.544 523.987",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1107.67438,442.25746l34.315,2.98391s38.79082,105.92878,34.315,108.91269-58.18623,20.88736-65.646,16.4115S1107.67438,442.25746,1107.67438,442.25746Z",
          transform: "translate(-14.5 -32.65535)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M906.26051,445.24137l-11.93564-2.98391s-22.37932,28.34714-29.83909,61.17014-17.90346,65.646-11.93564,65.646S912.22833,589.961,912.22833,589.961Z",
          transform: "translate(-14.5 -32.65535)",
          fill: _vm.color
        }
      }),
      _c("circle", {
        attrs: {
          cx: "1012.37074",
          cy: "409.40103",
          r: "10.79111",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1110.18567,632.852l-115.2682,36.33839L947.87225,519.95929q66.62277-9.34211,115.26817-36.33838C1069.15114,534.52438,1084.51,584.30686,1110.18567,632.852Z",
          transform: "translate(-14.5 -32.65535)",
          fill: "#e6e6e6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1099.39456,649.03867l-115.2682,36.3384L937.08115,536.146q66.62275-9.34211,115.26816-36.33838C1058.36,550.711,1073.71892,600.49352,1099.39456,649.03867Z",
          transform: "translate(-14.5 -32.65535)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M864.48578,591.45292s-17.90346,32.823-13.42759,43.26669,41.77473,16.4115,46.25059,16.4115S955.495,639.19547,955.495,639.19547s82.05751-38.79082,58.18623-58.18623-68.62991,26.85519-68.62991,26.85519H909.24442L889.849,594.43683Z",
          transform: "translate(-14.5 -32.65535)",
          fill: "#ffb8b8"
        }
      }),
      _c("rect", {
        attrs: {
          x: "1",
          y: "134.83258",
          width: "195",
          height: "87.16742",
          fill: _vm.secondColor
        }
      }),
      _c("rect", {
        attrs: {
          x: "676",
          y: "418",
          width: "34",
          height: "30",
          fill: "#000000"
        }
      }),
      _c("rect", {
        attrs: {
          x: "608",
          y: "418",
          width: "34",
          height: "30",
          fill: "#000000"
        }
      }),
      _c("rect", {
        attrs: {
          x: "744",
          y: "352",
          width: "34",
          height: "34",
          fill: _vm.color
        }
      }),
      _c("rect", {
        attrs: {
          x: "710",
          y: "386",
          width: "34",
          height: "32",
          fill: "#000000"
        }
      }),
      _c("rect", {
        attrs: {
          x: "642",
          y: "386",
          width: "34",
          height: "32",
          fill: "#000000"
        }
      }),
      _c("rect", {
        attrs: {
          x: "239",
          y: "446",
          width: "34",
          height: "30",
          fill: _vm.color
        }
      }),
      _c("rect", {
        attrs: {
          x: "545",
          y: "414",
          width: "34",
          height: "32",
          fill: "#000000"
        }
      }),
      _c("rect", {
        attrs: {
          x: "341",
          y: "414",
          width: "34",
          height: "32",
          fill: "#000000"
        }
      }),
      _c("rect", {
        attrs: {
          x: "307",
          y: "380",
          width: "34",
          height: "34",
          fill: "#000000"
        }
      }),
      _c("rect", {
        attrs: {
          x: "477",
          y: "414",
          width: "34",
          height: "32",
          fill: "#000000"
        }
      }),
      _c("rect", {
        attrs: {
          x: "443",
          y: "380",
          width: "34",
          height: "34",
          fill: "#000000"
        }
      }),
      _c("rect", {
        attrs: {
          x: "409",
          y: "414",
          width: "34",
          height: "32",
          fill: "#000000"
        }
      }),
      _c("rect", {
        attrs: {
          x: "273",
          y: "414",
          width: "34",
          height: "32",
          fill: "#000000"
        }
      }),
      _c("rect", {
        attrs: {
          x: "511",
          y: "380",
          width: "34",
          height: "34",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M679.24121,360.15026l-17.66389-18.93782,18.93783-17.66389L698.179,342.48638Zm-14.84837-18.83983,14.94636,16.02432,16.02431-14.94637-14.94636-16.02431Z",
          transform: "translate(-14.5 -32.65535)",
          fill: "#000000"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M440.16,282.23876H414.263v-25.897H440.16Zm-23.90491-1.99207h21.91284V258.33385H416.25509Z",
          transform: "translate(-14.5 -32.65535)",
          fill: "#000000"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M921.16,232.23876H895.263v-25.897H921.16Zm-23.90491-1.99207h21.91284V208.33385H897.25509Z",
          transform: "translate(-14.5 -32.65535)",
          fill: "#000000"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M268.93131,496.7233l-1.53054-1.494a78.18382,78.18382,0,0,0,15.68031-27.43219c5.20362-15.65995,7.74564-40.3919-9.69759-69.282-17.84687-29.55907-18.65-53.56778-16.18161-68.50345,2.68667-16.25159,9.54041-25.36119,9.83074-25.74082l1.69973,1.29869c-.06841.08929-6.86262,9.15033-9.44067,24.91732-2.38014,14.55656-1.54986,37.98356,15.92261,66.92226,17.88918,29.62905,15.23906,55.04767,9.86625,71.15357C279.25865,486.01319,269.35062,496.29406,268.93131,496.7233Z",
          transform: "translate(-14.5 -32.65535)",
          fill: "#000000"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M538.93131,428.7233l-1.53054-1.494a78.18382,78.18382,0,0,0,15.68031-27.43219c5.20362-15.65995,7.74564-40.3919-9.69759-69.282-17.84687-29.55907-18.65-53.56778-16.18161-68.50345,2.68667-16.25159,9.54041-25.36119,9.83074-25.74082l1.69973,1.29869c-.06841.08929-6.86262,9.15033-9.44067,24.91732-2.38014,14.55656-1.54986,37.98356,15.92261,66.92226,17.88918,29.62905,15.23906,55.04767,9.86625,71.15357C549.25865,418.01319,539.35062,428.29406,538.93131,428.7233Z",
          transform: "translate(-14.5 -32.65535)",
          fill: "#000000"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M773.93131,402.7233l-1.53054-1.494a78.18382,78.18382,0,0,0,15.68031-27.43219c5.20362-15.65995,7.74564-40.3919-9.69759-69.282-17.84687-29.55907-18.65-53.56778-16.18161-68.50345,2.68667-16.25159,9.54041-25.36119,9.83074-25.74082l1.69973,1.29869c-.06841.08929-6.86262,9.15033-9.44067,24.91732-2.38014,14.55656-1.54986,37.98356,15.92261,66.92226,17.88918,29.62905,15.23906,55.04767,9.86625,71.15357C784.25865,392.01319,774.35062,402.29406,773.93131,402.7233Z",
          transform: "translate(-14.5 -32.65535)",
          fill: "#000000"
        }
      }),
      _c("rect", {
        attrs: {
          x: "51.44444",
          y: "162.34685",
          width: "94.11111",
          height: "2.13889",
          fill: "#000000"
        }
      }),
      _c("rect", {
        attrs: {
          x: "51.44444",
          y: "177.34685",
          width: "94.11111",
          height: "2.13889",
          fill: "#000000"
        }
      }),
      _c("rect", {
        attrs: {
          x: "51.44444",
          y: "192.34685",
          width: "94.11111",
          height: "2.13889",
          fill: "#000000"
        }
      }),
      _c("rect", {
        attrs: {
          x: "578.44444",
          y: "19.34685",
          width: "94.11111",
          height: "2.13889",
          fill: "#000000"
        }
      }),
      _c("rect", {
        attrs: {
          x: "578.44444",
          y: "34.34685",
          width: "94.11111",
          height: "2.13889",
          fill: "#000000"
        }
      }),
      _c("rect", {
        attrs: {
          x: "578.44444",
          y: "49.34685",
          width: "94.11111",
          height: "2.13889",
          fill: "#000000"
        }
      }),
      _c("rect", {
        attrs: {
          x: "799.44444",
          y: "351.34685",
          width: "94.11111",
          height: "2.13889",
          fill: "#000000"
        }
      }),
      _c("rect", {
        attrs: {
          x: "799.44444",
          y: "366.34685",
          width: "94.11111",
          height: "2.13889",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "799.44444",
          y: "381.34685",
          width: "94.11111",
          height: "2.13889",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "208.44444",
          y: "501.34685",
          width: "94.11111",
          height: "2.13889",
          fill: "#000000"
        }
      }),
      _c("rect", {
        attrs: {
          x: "208.44444",
          y: "516.34685",
          width: "94.11111",
          height: "2.13889",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "208.44444",
          y: "531.34685",
          width: "94.11111",
          height: "2.13889",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M152.36027,143.27622c16.2598-36.47338,40.76678-60.4658,72.83927-71.31137,23.81285-8.05276,51.35162-8.81373,81.85141-2.26253A218.88869,218.88869,0,0,1,371.00249,94.543c105.8815,61.45019,171.34178,61.99642,207.61168,51.63383,39.769-11.36186,54.323-38.02171,54.46522-38.28984l1.76722.93663c-.14553.2748-15.03078,27.63245-55.60407,39.254-23.39069,6.69972-50.72081,6.9763-81.23068.82211-37.971-7.65958-81.04087-25.36581-128.01354-52.62662A216.90416,216.90416,0,0,0,306.63167,71.6576c-50.02325-10.74467-117.07727-6.9005-152.44552,72.43284Z",
          transform: "translate(-14.5 -32.65535)",
          fill: "#2f2e41"
        }
      }),
      _c("circle", {
        attrs: { cx: "256", cy: "237", r: "36", fill: "#000000" }
      }),
      _c("circle", {
        attrs: { cx: "528", cy: "174", r: "36", fill: "#000000" }
      }),
      _c("circle", {
        attrs: { cx: "763", cy: "142", r: "36", fill: "#000000" }
      }),
      _c("path", {
        attrs: {
          d: "M237.5,255.65535H14.5v-114h223Zm-221-2h219v-110H16.5Z",
          transform: "translate(-14.5 -32.65535)",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "282",
          y: "59.83258",
          width: "195",
          height: "87.16742",
          fill: _vm.secondColor
        }
      }),
      _c("rect", {
        attrs: {
          x: "332.44444",
          y: "87.34685",
          width: "94.11111",
          height: "2.13889",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "332.44444",
          y: "102.34685",
          width: "94.11111",
          height: "2.13889",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "329.44444",
          y: "114.34685",
          width: "94.11111",
          height: "2.13889",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d: "M518.5,180.65535h-223v-114h223Zm-221-2h219v-110h-219Z",
          transform: "translate(-14.5 -32.65535)",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "785",
          y: "25.83258",
          width: "195",
          height: "87.16742",
          fill: _vm.secondColor
        }
      }),
      _c("rect", {
        attrs: {
          x: "835.44444",
          y: "53.34685",
          width: "94.11111",
          height: "2.13889",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "835.44444",
          y: "68.34685",
          width: "94.11111",
          height: "2.13889",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "835.44444",
          y: "83.34685",
          width: "94.11111",
          height: "2.13889",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d: "M1021.5,146.65535h-223v-114h223Zm-221-2h219v-110h-219Z",
          transform: "translate(-14.5 -32.65535)",
          fill: "#3f3d56"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }