<template>
	<div class="box__container">
		<GhostLoading v-if="loading" type="table" :title="TABLE_TITLE" />
		<UnexpectedError v-else-if="error"/>
		<NoData v-else-if="!rowCount" :title="TABLE_TITLE" />

		<div v-else>
			<Table 
				class="mt-2"
				tableId="96302160-4c04-4041-939c-70de177e8596"
				:title="TABLE_TITLE"
				:headers="headers"
				:rows="rows"
				:selected="selectedPeople"
				@selected="selectedPeople = $event"
			/>

			<ComboBox
				label="Zielperson"
				:isComponentHalfSize="true"
				:values="comboBoxZielperson"
				v-model="zielPerson"/>

			<div>
				<BaseButton 
					:disabled="disableMergeButton"
					@click="confirmPeopleMerge">
					Markierte Personen zusammenführen
				</BaseButton>
			</div>
		</div>
	</div>
</template>

<script>
import NoData from '@/components/core/NoData.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import UnexpectedError from '@/components/core/UnexpectedError.vue';
import Table from '@/components/table2/Table.vue';
import { TextColumn } from '@/components/table2/table_util.js';
import MERGE_PEOPLE_TYPES from '@/store/merge-people/types.js';
import { mapGetters } from 'vuex';
import BaseButton from '@/components/core/BaseButton.vue'
import ComboBox from '@/components/core/forms/ComboBox.vue'

const TABLE_TITLE = 'Die gewählte Person kommt in den folgenden Varianten vor:';

export default {
	props: {
		loading: {
			default: false
		},
		error: {
			default: false,
		}
	},

	data() {
		return {
			TABLE_TITLE,
			zielPerson: null,
			selectedPeople: [],
		}
	},
	
	watch: {
		rows() {
			this.resetSelections()
		},
	},

	methods: {
		resetSelections() {
			this.zielPerson = this.unifiablePeople?.[0]?.personId
			this.selectedPeople = []
		},

		confirmPeopleMerge() {
			if (!this.zielPerson || !this.personenSource?.length) {
				return;
			}

			this.$confirmModal({
				message: `Möchten Sie die Personen wirklich zusammenführen?`,
				title: 'Personen zusammenführen',
				labelButtonConfirm: 'Personen zusammenführen'
			}).then(async () => {
					const payload = {
						personDest: this.zielPerson,
						personenSource: this.personenSource,
					}

					await this.$store.dispatch(MERGE_PEOPLE_TYPES.ACTIONS.UNITE_PEOPLE, payload);

					this.$emit('peopleMerged')
				});
		}
	},

	computed: {
		...mapGetters({
			unifiablePeople: MERGE_PEOPLE_TYPES.GETTERS.UNIFIABLE_PEOPLE_LIST,
		}),
		comboBoxZielperson() {
			if (!this.selectedPeople) { 
				return []
			}

			return this.selectedPeople?.map(row => ({label: row.foundUnder, value: row.personId}))
		},

		personenSource() {
			// although the zielPerson must be selected, the personenSource shouldn't contains zielPerson
			return this.selectedPeople
					?.filter(row => row.personId !== this.zielPerson)
					?.map(row => row.personId)
		},

		disableMergeButton() {
			// at least two people must be selected
			if (!this.personenSource?.length) {
				return true;
			}

			//the zielPerson must be selected
			if (!this.zielPerson || !this.selectedPeople?.find(row => row.personId === this.zielPerson)) {
				return true;
			}

			return false;
		},

		rowCount() {
			return this.rows?.length;
		},
		headers() {
			return {
				center: [
					TextColumn('foundUnder', 'Gefunden unter'),
					TextColumn('description', 'Beschreibung'),
				],
			}
		},
		rows() {
			if (!this.unifiablePeople) {
				return []
			}

			return this.unifiablePeople.map((row) => ({
				...row,
				id: row.personId
			}))
		}
	},

	components: {
		NoData,
		Table,
		GhostLoading,
		UnexpectedError,
		BaseButton,
		ComboBox,
	}
}
</script>

<style scoped>

</style>