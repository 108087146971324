var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box__container" }, [
    _c(
      "div",
      [
        _vm.sprod.id && _vm.isIntern
          ? _c("InputField", {
              attrs: {
                label: "Interne Nummer",
                disabled: true,
                isComponentHalfSize: true
              },
              model: {
                value: _vm.sprod.id,
                callback: function($$v) {
                  _vm.$set(_vm.sprod, "id", $$v)
                },
                expression: "sprod.id"
              }
            })
          : _vm._e(),
        !_vm.isImmobilie
          ? [
              _c("InputToggleSwitch", {
                attrs: {
                  label: "Fremdanlage",
                  inLineLabel: true,
                  disabled: !_vm.isIntern
                },
                on: {
                  input: function($event) {
                    return _vm.dataChanged()
                  }
                },
                model: {
                  value: _vm.sprod.fremdanlage,
                  callback: function($$v) {
                    _vm.$set(_vm.sprod, "fremdanlage", $$v)
                  },
                  expression: "sprod.fremdanlage"
                }
              }),
              _c("InputToggleSwitch", {
                attrs: {
                  label: "Automatisch aktualisieren",
                  inLineLabel: true,
                  disabled: !_vm.isIntern
                },
                on: {
                  input: function($event) {
                    return _vm.dataChanged()
                  }
                },
                model: {
                  value: _vm.sprod.autoupdate,
                  callback: function($$v) {
                    _vm.$set(_vm.sprod, "autoupdate", $$v)
                  },
                  expression: "sprod.autoupdate"
                }
              })
            ]
          : _vm._e(),
        _vm.isFA && _vm.isByPass
          ? [
              _c("InputToggleSwitch", {
                attrs: {
                  label: "Betreuerwechsel",
                  inLineLabel: true,
                  disabled: !_vm.isEditable
                },
                on: {
                  input: function($event) {
                    return _vm.dataChanged()
                  }
                },
                model: {
                  value: _vm.sprod.betreuerwechsel,
                  callback: function($$v) {
                    _vm.$set(_vm.sprod, "betreuerwechsel", $$v)
                  },
                  expression: "sprod.betreuerwechsel"
                }
              })
            ]
          : _vm._e(),
        _c("ComboBox", {
          attrs: {
            label: "Produktart",
            isComponentHalfSize: true,
            firstEmpty: !_vm.sprod.produktart,
            values: _vm.combos.prokuktArt,
            validateUntouched: true,
            disabled: !_vm.isEditable
          },
          on: {
            change: function($event) {
              _vm.dataChangedCombo("produktart", $event)
              _vm.clearFreieEingabeProduktartIfNeeded($event)
            }
          },
          model: {
            value: _vm.sprod.produktart,
            callback: function($$v) {
              _vm.$set(_vm.sprod, "produktart", $$v)
            },
            expression: "sprod.produktart"
          }
        }),
        _vm.isProduktartFreieEingabe
          ? [
              _c("InputField", {
                attrs: { isComponentHalfSize: true, disabled: !_vm.isEditable },
                on: {
                  change: function($event) {
                    return _vm.dataChanged()
                  }
                },
                model: {
                  value: _vm.sprod.freieEingabeProduktart,
                  callback: function($$v) {
                    _vm.$set(_vm.sprod, "freieEingabeProduktart", $$v)
                  },
                  expression: "sprod.freieEingabeProduktart"
                }
              })
            ]
          : _vm._e(),
        _vm.isImmobilie
          ? _c(
              "div",
              { staticClass: "mb-3" },
              [
                _c("ComboBox", {
                  attrs: {
                    label: "Nutzung",
                    isComponentHalfSize: true,
                    firstEmpty: true,
                    values: _vm.combos.nutzung,
                    disabled: !_vm.isEditable
                  },
                  on: {
                    change: function($event) {
                      return _vm.dataChangedCombo("nutzung", $event)
                    }
                  },
                  model: {
                    value: _vm.sprod.nutzung,
                    callback: function($$v) {
                      _vm.$set(_vm.sprod, "nutzung", $$v)
                    },
                    expression: "sprod.nutzung"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    label: "QM Wohnfläche gesamt",
                    type: "number",
                    isComponentHalfSize: true,
                    disabled: !_vm.isEditable
                  },
                  on: {
                    change: function($event) {
                      return _vm.dataChanged()
                    }
                  },
                  model: {
                    value: _vm.sprod.wohnflaeche,
                    callback: function($$v) {
                      _vm.$set(_vm.sprod, "wohnflaeche", $$v)
                    },
                    expression: "sprod.wohnflaeche"
                  }
                }),
                _vm.isNutzungMischnutzung
                  ? [
                      _c("InputField", {
                        attrs: {
                          label: "QM Wohnfläche Eigennutzung",
                          type: "number",
                          isComponentHalfSize: true,
                          disabled: !_vm.isEditable
                        },
                        on: {
                          change: function($event) {
                            return _vm.dataChanged()
                          }
                        },
                        model: {
                          value: _vm.sprod.wohnflaecheEigennutzung,
                          callback: function($$v) {
                            _vm.$set(_vm.sprod, "wohnflaecheEigennutzung", $$v)
                          },
                          expression: "sprod.wohnflaecheEigennutzung"
                        }
                      }),
                      _c("InputField", {
                        attrs: {
                          label: "QM Wohnfläche Fremdnutzung",
                          type: "number",
                          isComponentHalfSize: true,
                          disabled: !_vm.isEditable
                        },
                        on: {
                          change: function($event) {
                            return _vm.dataChanged()
                          }
                        },
                        model: {
                          value: _vm.sprod.wohnflaecheFremdnutzung,
                          callback: function($$v) {
                            _vm.$set(_vm.sprod, "wohnflaecheFremdnutzung", $$v)
                          },
                          expression: "sprod.wohnflaecheFremdnutzung"
                        }
                      })
                    ]
                  : _vm._e(),
                _c("ComboBox", {
                  attrs: {
                    label: "Gebäudetyp",
                    isComponentHalfSize: true,
                    values: _vm.combos.gebaudeTyp,
                    disabled: !_vm.isEditable
                  },
                  on: {
                    change: function($event) {
                      return _vm.dataChangedCombo("gebaeudetyp", $event)
                    }
                  },
                  model: {
                    value: _vm.sprod.gebaeudetyp,
                    callback: function($$v) {
                      _vm.$set(_vm.sprod, "gebaeudetyp", $$v)
                    },
                    expression: "sprod.gebaeudetyp"
                  }
                }),
                _c("DatePickerField", {
                  attrs: {
                    isValueAsString: "",
                    label: "Anschaffungsdatum",
                    isComponentHalfSize: true,
                    disabled: !_vm.isEditable
                  },
                  on: {
                    input: function($event) {
                      return _vm.dataChanged()
                    }
                  },
                  model: {
                    value: _vm.sprod.begin,
                    callback: function($$v) {
                      _vm.$set(_vm.sprod, "begin", $$v)
                    },
                    expression: "sprod.begin"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    label: "Kaufpreis",
                    type: "currency",
                    isComponentHalfSize: true,
                    disabled: !_vm.isEditable
                  },
                  on: {
                    change: function($event) {
                      return _vm.dataChanged()
                    }
                  },
                  model: {
                    value: _vm.sprod.bezugssumme,
                    callback: function($$v) {
                      _vm.$set(_vm.sprod, "bezugssumme", $$v)
                    },
                    expression: "sprod.bezugssumme"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    label: "akt. Verkehrswert",
                    type: "currency",
                    isComponentHalfSize: true,
                    disabled: true
                  },
                  on: {
                    change: function($event) {
                      return _vm.dataChanged()
                    }
                  },
                  model: {
                    value: _vm.sprod.aktWert,
                    callback: function($$v) {
                      _vm.$set(_vm.sprod, "aktWert", $$v)
                    },
                    expression: "sprod.aktWert"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    label: "Mietkosten pro Monat",
                    type: "currency",
                    isComponentHalfSize: true,
                    disabled: !_vm.isEditable
                  },
                  on: {
                    change: function($event) {
                      return _vm.dataChanged()
                    }
                  },
                  model: {
                    value: _vm.sprod.miete,
                    callback: function($$v) {
                      _vm.$set(_vm.sprod, "miete", $$v)
                    },
                    expression: "sprod.miete"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    label: "Mieteinnahmen pro Monat",
                    type: "currency",
                    isComponentHalfSize: true,
                    disabled: !_vm.isEditable
                  },
                  on: {
                    change: function($event) {
                      return _vm.dataChanged()
                    }
                  },
                  model: {
                    value: _vm.sprod.einnahmen,
                    callback: function($$v) {
                      _vm.$set(_vm.sprod, "einnahmen", $$v)
                    },
                    expression: "sprod.einnahmen"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    label: "Adresse des Objektes",
                    isComponentHalfSize: true,
                    disabled: !_vm.isEditable
                  },
                  on: {
                    change: function($event) {
                      return _vm.dataChanged()
                    }
                  },
                  model: {
                    value: _vm.sprod.adresse1,
                    callback: function($$v) {
                      _vm.$set(_vm.sprod, "adresse1", $$v)
                    },
                    expression: "sprod.adresse1"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    label: "",
                    isComponentHalfSize: true,
                    disabled: !_vm.isEditable
                  },
                  on: {
                    change: function($event) {
                      return _vm.dataChanged()
                    }
                  },
                  model: {
                    value: _vm.sprod.adresse2,
                    callback: function($$v) {
                      _vm.$set(_vm.sprod, "adresse2", $$v)
                    },
                    expression: "sprod.adresse2"
                  }
                })
              ],
              2
            )
          : _vm.sprod.produktart
          ? _c(
              "div",
              { staticClass: "mb-3" },
              [
                _c("InputField", {
                  attrs: {
                    label: "Produktbezeichnung",
                    isComponentHalfSize: true,
                    disabled: !_vm.isEditable
                  },
                  on: {
                    change: function($event) {
                      return _vm.dataChanged()
                    }
                  },
                  model: {
                    value: _vm.sprod.produktbez,
                    callback: function($$v) {
                      _vm.$set(_vm.sprod, "produktbez", $$v)
                    },
                    expression: "sprod.produktbez"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    label: "Gesellschaft",
                    isComponentHalfSize: true,
                    disabled: !_vm.isEditable
                  },
                  on: {
                    change: function($event) {
                      return _vm.dataChanged()
                    }
                  },
                  model: {
                    value: _vm.sprod.company,
                    callback: function($$v) {
                      _vm.$set(_vm.sprod, "company", $$v)
                    },
                    expression: "sprod.company"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    label: "Tarif",
                    isComponentHalfSize: true,
                    disabled: !_vm.isEditable
                  },
                  on: {
                    change: function($event) {
                      return _vm.dataChanged()
                    }
                  },
                  model: {
                    value: _vm.sprod.tarif,
                    callback: function($$v) {
                      _vm.$set(_vm.sprod, "tarif", $$v)
                    },
                    expression: "sprod.tarif"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    label: "Produktnummer",
                    isComponentHalfSize: true,
                    disabled: !_vm.isEditable
                  },
                  on: {
                    change: function($event) {
                      return _vm.dataChanged()
                    }
                  },
                  model: {
                    value: _vm.sprod.sprodNr,
                    callback: function($$v) {
                      _vm.$set(_vm.sprod, "sprodNr", $$v)
                    },
                    expression: "sprod.sprodNr"
                  }
                }),
                _c("DatePickerField", {
                  attrs: {
                    isValueAsString: "",
                    label: "Beginn",
                    isComponentHalfSize: true,
                    disabled: !_vm.isEditable
                  },
                  on: {
                    input: function($event) {
                      return _vm.dataChanged()
                    }
                  },
                  model: {
                    value: _vm.sprod.begin,
                    callback: function($$v) {
                      _vm.$set(_vm.sprod, "begin", $$v)
                    },
                    expression: "sprod.begin"
                  }
                }),
                _c("DatePickerField", {
                  attrs: {
                    isValueAsString: "",
                    label: "Ende",
                    isComponentHalfSize: true,
                    disabled: !_vm.isEditable
                  },
                  on: {
                    input: function($event) {
                      return _vm.dataChanged()
                    }
                  },
                  model: {
                    value: _vm.sprod.ende,
                    callback: function($$v) {
                      _vm.$set(_vm.sprod, "ende", $$v)
                    },
                    expression: "sprod.ende"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    type: "currency",
                    label: "aktueller Wert",
                    isComponentHalfSize: true,
                    disabled: true
                  },
                  on: {
                    change: function($event) {
                      return _vm.dataChanged()
                    }
                  },
                  model: {
                    value: _vm.sprod.aktWert,
                    callback: function($$v) {
                      _vm.$set(_vm.sprod, "aktWert", $$v)
                    },
                    expression: "sprod.aktWert"
                  }
                }),
                _c("ComboBox", {
                  attrs: {
                    label: "Zahlrhythmus",
                    isComponentHalfSize: true,
                    firstEmpty: true,
                    values: _vm.combos.zahlrhythmus,
                    disabled: !_vm.isEditable
                  },
                  on: {
                    change: function($event) {
                      return _vm.dataChangedCombo("zahlrhythm", $event)
                    }
                  },
                  model: {
                    value: _vm.sprod.zahlrhythm,
                    callback: function($$v) {
                      _vm.$set(_vm.sprod, "zahlrhythm", $$v)
                    },
                    expression: "sprod.zahlrhythm"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    type: "currency",
                    label: "Sparrate pro Periode",
                    isComponentHalfSize: true,
                    disabled: !_vm.isEditable
                  },
                  on: {
                    change: function($event) {
                      return _vm.dataChanged()
                    }
                  },
                  model: {
                    value: _vm.sprod.beitrag,
                    callback: function($$v) {
                      _vm.$set(_vm.sprod, "beitrag", $$v)
                    },
                    expression: "sprod.beitrag"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    type: "currency",
                    label: "Anfangswert/Bezugssumme",
                    isComponentHalfSize: true,
                    disabled: !_vm.isEditable
                  },
                  on: {
                    change: function($event) {
                      return _vm.dataChanged()
                    }
                  },
                  model: {
                    value: _vm.sprod.bezugssumme,
                    callback: function($$v) {
                      _vm.$set(_vm.sprod, "bezugssumme", $$v)
                    },
                    expression: "sprod.bezugssumme"
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm.sprodRights.allowReadSollprovision
          ? _c(
              "div",
              { staticClass: "mb-3" },
              [
                _c("ComboBox", {
                  attrs: {
                    label: "Provision",
                    isComponentHalfSize: true,
                    firstEmpty: true,
                    values: _vm.provisionValues,
                    disabled:
                      !_vm.sprodRights.allowModifySollprovision ||
                      !_vm.isEditable
                  },
                  on: {
                    change: function($event) {
                      return _vm.dataChangedCombo("provVorgabe", $event)
                    }
                  },
                  model: {
                    value: _vm.sprod.provVorgabe,
                    callback: function($$v) {
                      _vm.$set(_vm.sprod, "provVorgabe", $$v)
                    },
                    expression: "sprod.provVorgabe"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    type: "currency",
                    label: "Sollprovision",
                    isComponentHalfSize: true,
                    disabled:
                      _vm.sprod.provVorgabe !== _vm.PROVISION_MANUELL ||
                      !_vm.sprodRights.allowModifySollprovision ||
                      !_vm.isEditable
                  },
                  on: {
                    change: function($event) {
                      return _vm.dataChanged()
                    }
                  },
                  model: {
                    value: _vm.sprod.sollprovision,
                    callback: function($$v) {
                      _vm.$set(_vm.sprod, "sollprovision", $$v)
                    },
                    expression: "sprod.sollprovision"
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _vm.sprodRights.allowReadBearbStatus
          ? _c(
              "div",
              { staticClass: "mb-3" },
              [
                _c("ComboBox", {
                  attrs: {
                    label: "Bearbeitungsstatus",
                    isComponentHalfSize: true,
                    values: _vm.combos.bearbeitungsstatus,
                    disabled: !_vm.isEditable
                  },
                  on: {
                    change: function($event) {
                      return _vm.dataChangedCombo("bearbeitungsstatus", $event)
                    }
                  },
                  model: {
                    value: _vm.sprod.bearbeitungsstatus,
                    callback: function($$v) {
                      _vm.$set(_vm.sprod, "bearbeitungsstatus", $$v)
                    },
                    expression: "sprod.bearbeitungsstatus"
                  }
                }),
                !_vm.isImmobilie
                  ? _c("DatePickerField", {
                      attrs: {
                        isValueAsString: "",
                        label: "Datum Status",
                        isComponentHalfSize: true,
                        disabled: !_vm.isEditable
                      },
                      on: {
                        input: function($event) {
                          return _vm.dataChanged()
                        }
                      },
                      model: {
                        value: _vm.sprod.bearbStatusDate,
                        callback: function($$v) {
                          _vm.$set(_vm.sprod, "bearbStatusDate", $$v)
                        },
                        expression: "sprod.bearbStatusDate"
                      }
                    })
                  : _vm._e(),
                !_vm.isImmobilie
                  ? _c("InputField", {
                      attrs: {
                        label: "Bemerkung Status",
                        isComponentHalfSize: true,
                        disabled: !_vm.isEditable
                      },
                      on: {
                        change: function($event) {
                          return _vm.dataChanged()
                        }
                      },
                      model: {
                        value: _vm.sprod.bearbStatusBem,
                        callback: function($$v) {
                          _vm.$set(_vm.sprod, "bearbStatusBem", $$v)
                        },
                        expression: "sprod.bearbStatusBem"
                      }
                    })
                  : _vm._e(),
                _vm.isIntern
                  ? _c(
                      "BaseButton",
                      {
                        attrs: { disabled: _vm.creatingAppointment },
                        on: {
                          click: function($event) {
                            return _vm.createAppointment()
                          }
                        }
                      },
                      [_vm._v("Neue Aktivität aus Status")]
                    )
                  : _vm._e()
              ],
              1
            )
          : _vm._e(),
        _c("EntitySelector", {
          attrs: {
            label: "Bankverbindung",
            isComponentHalfSize: true,
            values: _vm.combos.bankingAccounts,
            disabled: !_vm.isEditable,
            validateUntouched: true
          },
          on: {
            change: function($event) {
              return _vm.dataChangedCombo("bankingAccount", $event)
            },
            new: function($event) {
              return _vm.newBankverbindung()
            },
            edit: function($event) {
              return _vm.editBankverbindung()
            }
          },
          model: {
            value: _vm.sprod.bankingAccount,
            callback: function($$v) {
              _vm.$set(_vm.sprod, "bankingAccount", $$v)
            },
            expression: "sprod.bankingAccount"
          }
        }),
        _c("InputTextArea", {
          attrs: {
            label: "Beschreibung / Bemerkung",
            isComponentHalfSize: true,
            disabled: !_vm.isEditable
          },
          on: {
            change: function($event) {
              return _vm.dataChanged()
            }
          },
          model: {
            value: _vm.sprod.bemerkung,
            callback: function($$v) {
              _vm.$set(_vm.sprod, "bemerkung", $$v)
            },
            expression: "sprod.bemerkung"
          }
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }