import axios from 'axios';

import { ResolverId } from '../types';
import { createLinkResolver, wrapViewDocument, wrapError } from '../utils';
import { getServletName } from '@/components/antrag/antrag-utils';

export default createLinkResolver(ResolverId.Form, async function({ apiAddressLegacy }, formEntry) {
  if (formEntry?.service && formEntry?.type === 'pdf') {
    const servlet = getServletName(formEntry.lagerstelle);
    const data = {
      antragsName: formEntry.service, 
      documentType: formEntry.documentType,
    };

    const params = `?antragsName=${formEntry.service}&documentType=${formEntry.documentType}&id=-${formEntry.service}-`;

    const response = await axios.post(`${apiAddressLegacy}/${servlet}${params}`, data);
    const file = response?.data?.file;
    if (file) {
      return wrapViewDocument('Antrag', 'application/pdf', file.bytes);
    }
  }

  return wrapError('Keine PDF-Datei vorhanden.');
});
