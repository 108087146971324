<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation :id="$appNavigation.currentOptionMenuId" :title="$appNavigation.currentMenu.label" />

    <div class="box__container">
      <p>Start und wichtige Hinweise</p>
      <p>Die Bearbeitungszeit des Tests beträgt ca. 30 Minuten und der Test kann mehrmals wiederholt werden. Am Ende des
        Abschlusstests erhalten Sie, wenn mindestens 80% der Fragen richtig beantwortet wurden, ein Zertifikat, welches
        Sie bitte abspeichern und auf Meine-Weiterbildung.at hochladen. Nach erfolgreichem Generieren des Zertifikates
        erfolgt eine automatische Meldung an die FinanzAdmin, dass Sie den Test bestanden haben.</p>
      <p>Um den WAG Test korrekt zu beenden, müssen folgende Voraussetzungen gegeben sein:</p>
      <ul>
        <li>Bitte verwenden Sie ausschließlich Google Chrome, Mozilla Firefox oder den Safari Browser – andere Browser
          sind leider nicht verwendbar. Stellen Sie sicher, dass einer dieser oben Genannten als Ihr Standartbrowser
          eingestellt ist</li>
        <li>Cookies zulassen (Extras/ Einstellungen/ Inhalt/ Pop-up-Fenster blockieren Haken raus/ ok)</li>
        <li>Caches im Browser müssen zwingend gelöscht werden</li>
        <li>Für Ihre Dokumentation und Nachsorge das Zertifikat ausdrucken und/ oder abspeichern</li>
        </ul>
      <DownloadLink asButton title="WAG-Prüfung starten" :href="helpmasterLink" />
    </div>
  </div>
</template>
  
<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import GELDWAESCHEBELEHRUNG_TYPES from "@/store/geldwaeschebelehrung/types";
import { mapGetters } from "vuex";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import DownloadLink from '@/components/core/download/DownloadLink.vue'

export default {
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    DownloadLink,
    GhostLoading,
    NoData,
  },
  mounted() {
    this.$store.dispatch(GELDWAESCHEBELEHRUNG_TYPES.ACTIONS.GET_WAG_PRUEFUNG_LINK);
  },
  computed: {
    ...mapGetters({
      helpmasterUrl: GELDWAESCHEBELEHRUNG_TYPES.GETTERS.WAG_PRUEFUNG_LINK,
    }),
    helpmasterLink() {
      return this.helpmasterUrl;
    }
  },
}
</script>