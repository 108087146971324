import BROKER_STATISTICS_TYPES from './types';

export default{
  [BROKER_STATISTICS_TYPES.MUTATIONS.RETRIEVE_TITLES_LIST_SUCCESS](state, payload) {
    state.titlesList = payload;
    state.titlesList.index = -1;
  },
  [BROKER_STATISTICS_TYPES.MUTATIONS.SET_TABLE_VIEWER](state, payload){
    if (payload.action === 'increment') {
      state.tableViewerHist.push(state.tableViewer);
      payload.action = undefined;
      state.tableViewer = payload;
    } else if (payload.action === 'decrement') {
      state.tableViewer = state.tableViewerHist.pop();
    } else if (payload.action === 'reset') {
      state.tableViewer = {empty: true};
    } else {
      state.tableViewer = payload;
    }
  },
  [BROKER_STATISTICS_TYPES.MUTATIONS.SET_DEEP_TITLE](state, payload) {
    state.deepTitle = payload;
  },

}