<template>
    <Pill
        v-if="row[column]"
        :label="row[column].label"
        :type="row[column].type"
        :bordered="row[column].bordered"
        :bold="row[column].bold"
        :style="{'text-wrap': textWrap ? 'wrap' : undefined}"
    />
</template>
<script>
import Pill from '@/components/core/Pill.vue';

export default {
    exportAsString(props) {
        let value = props.row[props.column];
        return value?.label || "";
    },
    components: {
        Pill,
    },
    props: {
        column: {
            type: String,
        },
        textWrap: {
            type: Boolean,
            default: false,
        },
        row: {
            type: Object,
        },
    },
}
</script>
<style scoped>

</style>