var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-forms__container" }, [
    _c("div", { staticClass: "input-forms__label-container" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("InputField", {
              attrs: {
                label: "Aktuelles Depot",
                type: "text",
                isEmbedded: true,
                value: _vm.valueForLabel,
                disabled: true
              }
            }),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12 col-xl-8" },
                [
                  _c("ComboBox", {
                    attrs: {
                      label: "Für Wertpapier",
                      values: _vm.valuesForDepot
                    },
                    model: {
                      value: _vm.depotPosition,
                      callback: function($$v) {
                        _vm.depotPosition = $$v
                      },
                      expression: "depotPosition"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-12 col-xl-4 mt-3 pl-2" },
                [
                  _c(
                    "BaseButton",
                    {
                      attrs: { isEmbedded: true, disabled: !_vm.depotPosition },
                      on: {
                        click: function($event) {
                          return _vm.addPosition()
                        }
                      }
                    },
                    [_vm._v("Fonds hinzufügen")]
                  )
                ],
                1
              )
            ])
          ],
          1
        )
      ])
    ]),
    _c(
      "div",
      { staticClass: "input-forms__input-container" },
      [
        _vm.tableData && _vm.tableData.records && _vm.tableData.records.length
          ? _c("Table", {
              attrs: {
                tableData: _vm.tableData,
                cardViewEnabled: false,
                filterEnabled: false,
                exportEnabled: false,
                paginationEnabled: false,
                actions: _vm.columnActions,
                rowsPerPage: _vm.tableData.records.length
              },
              on: {
                "execute-action": function($event) {
                  return _vm.handleTableAction($event)
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "isWert",
                    fn: function(props) {
                      return [
                        _c("ComboBox", {
                          attrs: {
                            values: _vm.anlageValues,
                            value: props.data.row.isWert,
                            disabled: props.data.row.allesVerkaufen
                          },
                          on: {
                            input: function($event) {
                              return _vm.onChangeInputField(
                                $event,
                                props.data.row,
                                "isWert"
                              )
                            }
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "betrag",
                    fn: function(props) {
                      return [
                        props.data.row.isWert === true
                          ? _c("InputField", {
                              attrs: {
                                type: "currency",
                                value: props.data.row.wert,
                                disabled: props.data.row.allesVerkaufen,
                                id: "wert" + props.data.row.index
                              },
                              on: {
                                input: function($event) {
                                  return _vm.onChangeInputField(
                                    $event,
                                    props.data.row,
                                    "wert",
                                    props.data
                                  )
                                }
                              }
                            })
                          : _vm._e(),
                        !(props.data.row.isWert === true)
                          ? _c("InputField", {
                              attrs: {
                                type: "number",
                                value: props.data.row.anteile,
                                disabled: props.data.row.allesVerkaufen,
                                id: "anteile" + props.data.row.index
                              },
                              on: {
                                input: function($event) {
                                  return _vm.onChangeInputField(
                                    $event,
                                    props.data.row,
                                    "anteile"
                                  )
                                }
                              }
                            })
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "allesVerkaufen",
                    fn: function(props) {
                      return [
                        _c("InputToggleSwitch", {
                          attrs: {
                            value: props.data.row.allesVerkaufen,
                            id: "allesVerkaufen" + props.data.row.index
                          },
                          on: {
                            input: function($event) {
                              return _vm.onChangeInputField(
                                $event,
                                props.data.row,
                                "allesVerkaufen"
                              )
                            }
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "sparplaeneLoeschen",
                    fn: function(props) {
                      return [
                        _c("InputToggleSwitch", {
                          attrs: {
                            id: "sparplaeneLoeschen" + props.data.row.index
                          },
                          on: {
                            input: function($event) {
                              return _vm.onChangeInputField(
                                $event,
                                props.data.row,
                                "sparplaeneLoeschen"
                              )
                            }
                          }
                        })
                      ]
                    }
                  }
                ],
                null,
                false,
                2716127340
              )
            })
          : _vm._e(),
        _c("DeletePositionModal", {
          ref: "deleteModal",
          attrs: { position: _vm.positionToDelete },
          on: {
            delete: function($event) {
              return _vm.doDeletePosition()
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }