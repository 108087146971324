<template>
  <BaseModal 
    ref="modal"
    modalTitle="Privater Ordner"
    size="md"
    :confirmDisabled="isFormInvalid"
    @onConfirmButton="save()"
    @close="onClose()"
  >
    <template #default>
      <p>Unterordner von {{ parentNodeLabel }}</p>

      <InputRadioBoxGroup v-if="showGroupTyp" 
        v-model="form.groupTyp" :values="groupTypValues" :disabled="loading" />

      <InputField label="Name" v-model="form.nodeName" validateUntouched :disabled="loading" autofocus />

      <InputToggleSwitch label="Ordner für Kunden freigeben" v-model="form.showForKunde" inLineLabel :disabled="loading" />
    </template>
  </BaseModal>
</template>

<script>
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';
import DOKUMENTENARCHIV_TYPES from '@/store/dokumentenarchiv/types';

import BaseModal from '@/components/core/BaseModal.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import InputField from '@/components/core/forms/InputField.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';

import validator from '@/mixins/validator/index';
import { required, } from '@/mixins/validator/rules';

export default {
  mixins: [validator],
  data() {
    return {
      loading: false,
      parentNode: {},
      form: {
        groupTyp: '',
        nodeName: '',
        showForKunde: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
    }),
    kundennr() {
      return this.loginData?.userid || '';
    },
    maklernr() {
      return this.loginData?.maklernr || '';
    },
    showGroupTyp() {
      return !!this.parentNode?.subfolderMetadata?.showGroupTyp;
    },
    showForKundeParent() {
      return !!this.parentNode?.subfolderMetadata?.showForKunde;
    },
    parentNodeLabel() {
      return this.parentNode?.label;
    },
    parentNodeId() {
      return this.parentNode?.nodeId;
    },
    isFormInvalid() {
      return !this.form?.nodeName?.trim();
    },
    groupTypValues() {
      return [
        { label: `Für Kunde ${this.kundennr}`, value: 'RADIO_KUNDE', },
        { label: `Für Kunden vom Vermittler ${this.maklernr}`, value: 'RADIO_MAKLER', },
        { label: `Für Kunden von der Vermittlersstruktur ${this.maklernr}`, value: 'RADIO_STRUKT', },
      ];
    },
  },
  methods: {
    async init(parentNode, nodeId) {
      this.parentNode = {
        ...parentNode,
      };

      if(nodeId) {
        this.loading = true;
        const dokNode = await this.$store.dispatch(DOKUMENTENARCHIV_TYPES.ACTIONS.GET_PRIVATE_DOK_NODE, { nodeId, });

        this.form = {
          nodeId: dokNode?.nodeId,
          groupTyp: dokNode?.groupTyp,
          nodeName: dokNode?.nodeName,
          showForKunde: dokNode?.showForKunde,
        };
        this.loading = false;
      } else {
        this.form = {
          groupTyp: 'RADIO_KUNDE',
          nodeName: '',
          showForKunde: this.showForKundeParent,
        };
      }
    },
    open(parentNode, nodeId) {
      if(!parentNode?.nodeId) return;

      this.$refs.modal.open();
      this.$nextTick(() => this.init(parentNode, nodeId));
    },
    close() {
      this.$refs.modal.close();
    },
    onClose() {
      this.reset();
    },
    reset() {
      this.parentNode = {};
      this.form = {
        groupTyp: '',
        nodeName: '',
        showForKunde: null,
      };
    },
    save() {
      this.$store.dispatch(DOKUMENTENARCHIV_TYPES.ACTIONS.SAVE_PRIVATE_DOK_NODE, { 
        ...this.form,
        parentId: this.parentNodeId,
      });
    },
  },
  mounted() {
    this.reset();
  },
  validators: {
    form: {
      nodeName: [required()],
    },
  },
  components: {
    BaseModal,
    InputRadioBoxGroup,
    InputField,
    InputToggleSwitch,
  },
}
</script>
