<template>
  <div :key="configDefinitionId">
    <ConfigRender 
      :componentsDef="componentsDef"
      :configGroupLabel="configGroupLabel"
      :data="data"
      :changedData="changedData"
      :defaultData="defaultData"
      :isLoading="loading"
      @change="onChange"
      @remove="onRemove"
      @discardChanges="onDiscardChanges"
      @saveChanges="saveChanges"
      @executeConfigButton="executeConfigButton"
      @setStep="onChangeStep"
      @setSubstep="onChangeSubstep"
    />

    <!-- <pre>data={{ data }}</pre> -->
    <!-- <pre>defaultData={{ defaultData }}</pre> -->
    <!-- <pre>changedConfigs={{ changedConfigs }}</pre> -->
    <!-- <pre>allConfigs={{ allConfigs }}</pre> -->
    <!-- <pre>allConfigsById={{ allConfigsById }}</pre> -->
    <!-- <pre>componentsDef={{ componentsDef }}</pre> -->
  </div>
</template>

<script>
import ConfigRender from '@/components/core/configs/ConfigRender.vue'
import FC_CONFIG_TYPES from '@/store/fcConfig/types';
import CONFIG_DEFINITION_TYPES from '@/store/configDefinition/types'
import CORE_TYPES from '@/store/core/types';
import { mapGetters } from 'vuex'

import { VIEW_ROLES, } from '@/router/roles';
import { FC_CONFIG_USER_LEVEL, } from '@/configs/fcConfig';

import { findSubsteps, } from './configs-utils';
import axios from 'axios';

const ID_SEPARATOR = ">>"
const VALUE_STANDARD = "Std.*"

const DIVIDER_TYPE = 'DIVIDER';

export default {
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      fcConfig: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG,
      configDefinitionSelected: CONFIG_DEFINITION_TYPES.GETTERS.CONFIG_DEFINITION_SELECTED,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      changedConfigs: CONFIG_DEFINITION_TYPES.GETTERS.CHANGED_CONFIGS,
    }),
    configDefinitionId() {
      return this.$route.params.id;
    },
    hasChangedConfigs() {
      return Object.keys(this.changedConfigs).length;
    },
    configGroupLabel() {
      return this.configDefinitionSelected?.label
    },
    componentsDef() {
      const steps = this.configDefinitionSelected?.steps ?? [];
      return steps.filter(step => {
        const { configComponents } = step;
        return configComponents?.filter(component => component.type !== DIVIDER_TYPE)?.length > 0;
      }).map(step => {
        const substeps = findSubsteps(step);
        return {
          ...step,
          substeps: substeps?.length > 1 ? substeps : [],
        };
      });
    },
    allConfigs() {
      const rootConfigs = this.componentsDef
        .map(step => step.configComponents)
        .flatMap(config => config)
        .filter(config => config.configId && config.configType)

      const subConfigs = this.componentsDef
        .map(step => step.configComponents)
        .flatMap(config => config)
        .filter(config => config?.config?.componentFields)
        .map(config => config.config.componentFields)
        .flatMap(config => config)
        .filter(config => config.configId && config.configType)

      const subConfigsMinMax = this.componentsDef
        .map(step => step.configComponents)
        .flatMap(config => config)
        .filter(config => config?.config?.min && config?.config?.max)
        .map(config => [config.config.min, config.config.max])
        .flatMap(config => config)
        .filter(config => config.configId && config.configType)

      return [...rootConfigs, ...subConfigs, ...subConfigsMinMax]
    },
    allConfigsById() {
      return this.allConfigs
        .reduce((acc, config) => ({ 
          ...acc, 
          [config.id]: config, 
        }), {});
    },
    data() {
      return this.allConfigs.reduce((acc, cur) => {
        const configData = this.fcConfig?.[cur.configType]?.[cur.configId]
        const configComponent = this.allConfigsById?.[cur.id]?.config || {};
        if (configData) {
          const content = this.overrideData(configData.content, cur, false);
          acc[cur.id] = configData.parameterIsDefault && configComponent.demarkDefaultOption ? VALUE_STANDARD : content;
        }
        return acc;
      }, {})
    },
    defaultData() {
      return this.allConfigs.reduce((acc, cur) => {
        const content = this.overrideData(this.fcConfig?.[cur.configType]?.[cur.configId]?.defaultContent, cur, true);
        acc[cur.id] = content;
        return acc;
      }, {})
    },
    changedData() {
      return Object.keys(this.changedConfigs || {}).reduce((acc, key) => ({
        ...acc,
        [key]: this.changedConfigs[key]?.content,
      }), {});
    },
    currentUserLevel() {
      if(this.hasRoles([VIEW_ROLES.VIEW_BROKER])) {
        return FC_CONFIG_USER_LEVEL.MAKLER;
      } else if(this.hasRoles([VIEW_ROLES.VIEW_CUSTOMER])) {
        return FC_CONFIG_USER_LEVEL.KUNDE;
      }

      return null;
    },
  },
  watch: {
    async '$route.params.id'() {
      await this.finalize();
      this.init();
    },
  },
  methods: {
    overrideData(content, component, checkDefaultData) {
      // very specific override
      // when the currentValue is not available and it is a "Ja", "Nein" single selection
      // then override to 'Nein'
      if (component?.type === 'SINGLE_SELECTION') {
        const allAvailableValues = component?.values?.map(item => item.value)
        const valueNotPresent = !allAvailableValues.includes(content);
        let isJaNeinOptions = allAvailableValues.includes('Ja') && allAvailableValues.includes('Nein');

        if (checkDefaultData) {
          isJaNeinOptions = isJaNeinOptions && allAvailableValues.includes(VALUE_STANDARD);
        }

        if (valueNotPresent && isJaNeinOptions) {
          return 'Nein';
        }
      }
      return content;
    },
    async init() {
      try {
        this.loading = true;
        await this.findConfigDefinition();
        await this.loadFCConfig();
      } finally {
        this.loading = false;
      }
    },
    async findConfigDefinition() {
      await this.$store.dispatch(CONFIG_DEFINITION_TYPES.ACTIONS.GET_CONFIG_DEFINITION, { 
        id: this.configDefinitionId,
      });
    },
    async loadFCConfig() {
      const payload = this.allConfigs
        .map(config => ({configId: config.configId, configType: config.configType, forceReload: true}))

      await this.$store.dispatch(FC_CONFIG_TYPES.ACTIONS.LOAD_FC_CONFIG, payload);
    },
    onChange(event) {
      this.$store.commit(CONFIG_DEFINITION_TYPES.MUTATIONS.SET_CHANGED_CONFIGS, this.getValueListFromOnChangeEvent(event));
      this.forceSaveOnChange(event);
    },
    async forceSaveOnChange(event) {
      const forceSaveOnChange = Object.keys(event)
        .map(key => this.allConfigsById[key]?.config?.forceSaveOnChange)
        .filter(force => force)
        .length > 0

      if (forceSaveOnChange) {
        await this.$store.dispatch(CONFIG_DEFINITION_TYPES.ACTIONS.SAVE_CHANGED_CONFIGS)
        this.init()
      }
    },
    getValueListFromOnChangeEvent(data) {
      const result = {}

      if (data) {
        const rows = Object.entries(data);
        if (rows.length == 2 && rows[0][1] == 'Std.*'  && rows[1][1] == '' ) {
          rows[1][1] = 'Std.*';
        }
        for (const [key, fieldValue] of rows) {
          const configIdTypeArray = key?.split(ID_SEPARATOR)
          if (configIdTypeArray?.length === 2) {
            result[key] = {
              configType: configIdTypeArray[0],
              configId: configIdTypeArray[1],
              content: fieldValue
            }
          }
        }
      }

      return result;
    },
    async onRemove() {
      await this.$store.dispatch(CONFIG_DEFINITION_TYPES.ACTIONS.DELETE_CONFIG_DEFINITION, { 
        id: this.configDefinitionId,
      });

      this.$router.replace('/intern/configs-overview?backAction=true');
    },
    onDiscardChanges() {
      this.$store.commit(CONFIG_DEFINITION_TYPES.MUTATIONS.RESET_CHANGED_CONFIGS);
    },
    async finalize() {
      await this.$store.dispatch(CONFIG_DEFINITION_TYPES.ACTIONS.SAVE_CHANGED_CONFIGS);

      this.$nextTick(() => this.$store.commit(CONFIG_DEFINITION_TYPES.MUTATIONS.RESET_CONFIG_DEFINITION_SELECTED));
    },
    async executeConfigButton(data) {

      await this.$store.dispatch(CONFIG_DEFINITION_TYPES.ACTIONS.SAVE_CHANGED_CONFIGS)

      const response = await axios.post(`${process.env.VUE_APP_API}/config_button/${data.buttonId}`, {}, { defaultSpinner: true })
      
      if (response && response.status == 200) {
        this.$confirmModal({
          title: 'Daten gesetzt',
          message: 'Daten wurden erfolgreich angepasst!',
          labelButtonConfirm: 'Ok',
          showCancelButton: false,
        })
      }

      this.init()
    },
    async onChangeSubstep() {
      await this.$store.dispatch(CONFIG_DEFINITION_TYPES.ACTIONS.SAVE_CHANGED_CONFIGS)
    },
    async onChangeStep() {
      await this.$store.dispatch(CONFIG_DEFINITION_TYPES.ACTIONS.SAVE_CHANGED_CONFIGS)
    },
    async saveChanges() {
      await this.$store.dispatch(CONFIG_DEFINITION_TYPES.ACTIONS.SAVE_CHANGED_CONFIGS)
    }
  },
  async beforeRouteLeave(to, from, next) {
    await this.finalize();

    this.$addBreadcrumb({ 
      to,
      from,
      breadcrumb: this.configGroupLabel,
      label: 'zurück zu der Einstellung',
    });

    next();
  },
  mounted() {
    this.init();
  },
  components: {
    ConfigRender
  }
}
</script>

<style scoped>

</style>