var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-forms__container" }, [
    _c("div", { staticClass: "input-forms__label-container" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12 col-xl-8" },
              [
                _c("ComboBox", {
                  attrs: {
                    label: "Wählen Sie die Positionen zum Verkauf aus:",
                    values: _vm.config.valuesForDepot
                  },
                  on: {
                    input: function($event) {
                      return _vm.onChangeInput($event)
                    }
                  },
                  model: {
                    value: _vm.depotPosition,
                    callback: function($$v) {
                      _vm.depotPosition = $$v
                    },
                    expression: "depotPosition"
                  }
                })
              ],
              1
            )
          ])
        ])
      ])
    ]),
    _c(
      "div",
      { staticClass: "input-forms__input-container" },
      [
        _vm.rows.length
          ? _c("Table", {
              attrs: {
                tableId: "46c30c0a-aa2b-4885-b3ef-cfedaeec59tr",
                headers: _vm.headers,
                rows: _vm.rows,
                rowId: "isin",
                hidePagination: ""
              },
              on: { "action-DELETE": _vm.openModalDeleteFond },
              scopedSlots: _vm._u(
                [
                  {
                    key: "betragEntnahmeplan",
                    fn: function(row) {
                      return [
                        _c("InputField", {
                          attrs: {
                            type: "currency",
                            value: row.betragEntnahmeplan,
                            id: "betragEntnahmeplan" + row.index
                          },
                          on: {
                            input: function($event) {
                              return _vm.onChangeInputField(
                                $event,
                                row,
                                "betragEntnahmeplan"
                              )
                            }
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "frequenz",
                    fn: function(row) {
                      return [
                        _c("ComboBox", {
                          attrs: {
                            value: row.frequenz,
                            id: "frequenz" + row.index,
                            values: _vm.frequenzValues
                          },
                          on: {
                            input: function($event) {
                              return _vm.updatePositionsData(
                                $event,
                                row,
                                "frequenz"
                              )
                            }
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "startdatum",
                    fn: function(row) {
                      return [
                        _c("DatePickerField", {
                          attrs: {
                            value: row.startdatum,
                            id: "startdatum" + row.index
                          },
                          on: {
                            input: function($event) {
                              return _vm.onChangeInputField(
                                $event,
                                row,
                                "startdatum"
                              )
                            }
                          }
                        })
                      ]
                    }
                  }
                ],
                null,
                false,
                490928051
              )
            })
          : _vm._e(),
        _c("DeletePositionModal", {
          ref: "deleteModal",
          attrs: { position: _vm.positionToDelete },
          on: {
            delete: function($event) {
              return _vm.doDeletePosition()
            }
          }
        }),
        _c(
          "BaseModal",
          {
            ref: "fehlerModal",
            attrs: { labelButtonCancel: "Ok", showConfirmButton: false },
            scopedSlots: _vm._u([
              {
                key: "modalTitle",
                fn: function() {
                  return [
                    _c("i", {
                      staticClass: "las la-exclamation-triangle mr-2",
                      staticStyle: { color: "orangered" }
                    }),
                    _vm._v(" Fehler ")
                  ]
                },
                proxy: true
              }
            ])
          },
          [
            _c("div", { staticClass: "var(--color-danger) font-bold" }, [
              _c("hr"),
              _vm._v(
                " Es ist möglich nur 1 Position einzutragen. Wenn Sie die Position bearbeiten möchten, löschen Sie bitte zuerst die vorhandene Position. "
              )
            ])
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }