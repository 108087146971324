var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("p", [
        _vm._v(
          "Zur Dokumentation ist jeweils ein Screenshot/Unterlagen beizulegen"
        )
      ]),
      _c(
        "DragnDropArea",
        {
          staticClass: "form-select-file-button__container mb-3",
          attrs: { hoverText: "Hochladen", disabled: _vm.disabledStatusOK },
          on: {
            files: function($event) {
              return _vm.upload($event, _vm.type)
            }
          }
        },
        [
          _c(
            "BaseFileSelect",
            {
              attrs: { disabled: _vm.disabledStatusOK },
              on: {
                files: function($event) {
                  return _vm.upload($event, _vm.type)
                }
              }
            },
            [_vm._v(" Dokument hochladen ")]
          ),
          _vm.attachment
            ? _c(
                "DownloadLink",
                {
                  attrs: {
                    target: "_blank",
                    rel: "noopener noreferer",
                    title: _vm.attachment.bezeichnung,
                    filename: _vm.attachment.filename,
                    downloadServicePath: "/get_simple_file",
                    queryParams: {
                      fileId: _vm.attachment.id
                    }
                  }
                },
                [
                  _c("Pill", {
                    attrs: { label: _vm.attachment.filename, type: "default" }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.attachment
            ? _c("BaseButton", { attrs: { isLink: "" } }, [
                _c(
                  "span",
                  [
                    !_vm.disabledStatusOK
                      ? _c("PhTrash", {
                          staticClass: "ml-2",
                          attrs: { size: 16 },
                          on: {
                            click: function($event) {
                              return _vm.onRemoveFile()
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }