var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "modal",
    attrs: {
      modalTitle: "Privater Ordner",
      size: "md",
      confirmDisabled: _vm.isFormInvalid
    },
    on: {
      onConfirmButton: function($event) {
        return _vm.save()
      },
      close: function($event) {
        return _vm.onClose()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function() {
          return [
            _c("p", [_vm._v("Unterordner von " + _vm._s(_vm.parentNodeLabel))]),
            _vm.showGroupTyp
              ? _c("InputRadioBoxGroup", {
                  attrs: { values: _vm.groupTypValues, disabled: _vm.loading },
                  model: {
                    value: _vm.form.groupTyp,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "groupTyp", $$v)
                    },
                    expression: "form.groupTyp"
                  }
                })
              : _vm._e(),
            _c("InputField", {
              attrs: {
                label: "Name",
                validateUntouched: "",
                disabled: _vm.loading,
                autofocus: ""
              },
              model: {
                value: _vm.form.nodeName,
                callback: function($$v) {
                  _vm.$set(_vm.form, "nodeName", $$v)
                },
                expression: "form.nodeName"
              }
            }),
            _c("InputToggleSwitch", {
              attrs: {
                label: "Ordner für Kunden freigeben",
                inLineLabel: "",
                disabled: _vm.loading
              },
              model: {
                value: _vm.form.showForKunde,
                callback: function($$v) {
                  _vm.$set(_vm.form, "showForKunde", $$v)
                },
                expression: "form.showForKunde"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }