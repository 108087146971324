var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box__container" }, [
    _c("div", { staticClass: "box__title" }, [_vm._v("Vorlieben")]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _vm.isEditable
          ? _c(
              "div",
              { staticClass: "mb-8px" },
              [
                _c("ComboBox", {
                  attrs: {
                    isComponentHalfSize: true,
                    values: _vm.customerDataConfig.vorlieben,
                    sortComboboxValues: false
                  },
                  on: {
                    change: function($event) {
                      return _vm.resetVorliebenField()
                    }
                  },
                  model: {
                    value: _vm.vorliebenCombo,
                    callback: function($$v) {
                      _vm.vorliebenCombo = $$v
                    },
                    expression: "vorliebenCombo"
                  }
                }),
                _c("InputField", {
                  attrs: { isComponentHalfSize: true },
                  on: {
                    input: function($event) {
                      return _vm.resetVorliebenCombo()
                    }
                  },
                  model: {
                    value: _vm.vorliebenField,
                    callback: function($$v) {
                      _vm.vorliebenField = $$v
                    },
                    expression: "vorliebenField"
                  }
                }),
                _c(
                  "BaseButton",
                  {
                    attrs: { disabled: !_vm.isValid },
                    on: {
                      click: function($event) {
                        return _vm.addVorlieben()
                      }
                    }
                  },
                  [_vm._v("Hinzufügen")]
                )
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          [
            _vm.rows.length
              ? _c("Table", {
                  attrs: {
                    tableId: "8d815554-d015-4b7a-bab0-7479d125db2c",
                    headers: _vm.headers,
                    rows: _vm.rows,
                    rowsPerPage: 10,
                    rowId: "vorlieben"
                  },
                  on: {
                    "action-DELETE": function($event) {
                      return _vm.removeVorlieben($event.vorlieben)
                    }
                  }
                })
              : _c("NoData", { attrs: { noIcon: "" } })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }