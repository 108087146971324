import EXTERNE_ZUGANG_CONFIG_TYPES from './types';
import Vue from 'vue';
import { getInitialState } from './index';
import communication from '../communication/index';

export default {

  [EXTERNE_ZUGANG_CONFIG_TYPES.MUTATIONS.SET_CONFIG_DATA](state, payload) {
    Vue.set(state, 'configData', { ...payload || {}, });
  },

  [EXTERNE_ZUGANG_CONFIG_TYPES.MUTATIONS.SET_SELECTED_ZUGANGSART](state, payload) {
    Vue.set(state, 'selectedZugangsArt', payload);
  },

  [EXTERNE_ZUGANG_CONFIG_TYPES.MUTATIONS.SET_LIST](state, payload) {
    Vue.set(state, 'list', [ ...payload || [], ]);
  },

  [EXTERNE_ZUGANG_CONFIG_TYPES.MUTATIONS.ADD_REPLACE_LIST_ITEM](state, { externeZugang, }) {
    const list = [ ...state.list, ];
    const { bezeichnung, zugangsArt, id, } = externeZugang;

    const index = list.findIndex(item => item.bezeichnung === bezeichnung && item.zugangsArt === zugangsArt && (item.id === id || !item.id));
    if(index >= 0) { // replace
      Vue.set(state.list, index, externeZugang);
    } else { // add item
      const lastIndex = list.findLastIndex(item => item.bezeichnung === bezeichnung && item.zugangsArt === zugangsArt);
      list.splice(lastIndex + 1, 0, externeZugang);
      Vue.set(state, 'list', list);
    }
    if (state.definition?.[bezeichnung]?.data) {
      state.definition[bezeichnung].data.id = id;
      Vue.set(state, 'definition', Object.assign({}, state.definition));
    }
  },

  [EXTERNE_ZUGANG_CONFIG_TYPES.MUTATIONS.DELETE_REPLACE_LIST_ITEM](state, { externeZugang, deletedId, }) {
    const list = [ ...state.list, ];
    const { bezeichnung, zugangsArt, } = externeZugang;
    const index = list.findIndex(item => item.bezeichnung === bezeichnung && item.zugangsArt === zugangsArt && item.id === deletedId);
    if(index >= 0) {
      const filteredItems = list.filter(item => item.bezeichnung === bezeichnung && item.zugangsArt === zugangsArt);
      if(filteredItems.length === 1) { // replace item
        Vue.set(state.list, index, externeZugang);
      } else { // delete item
        list.splice(index, 1);
        Vue.set(state, 'list', [ ...list, ]);
      }
    }
  },

  [EXTERNE_ZUGANG_CONFIG_TYPES.MUTATIONS.SET_DEFINITION](state, payload) {
    Vue.set(state, 'definition', {
      ...state.definition,
      ...payload || {},
    });
  },

  [EXTERNE_ZUGANG_CONFIG_TYPES.MUTATIONS.SET_BIPRO_SIBLING_GESELLSCHAFTEN](state, payload) {
    Vue.set(state, 'biproSiblingGesellschaften', {
      ...state.biproSiblingGesellschaften,
      ...payload || {},
    });
  },

  [EXTERNE_ZUGANG_CONFIG_TYPES.MUTATIONS.RESET_STATE](state) {
    // Object.assign(state, getInitialState());
    Vue.set(state, 'configData', {});
    Vue.set(state, 'list', []);
    Vue.set(state, 'definition', {});
    Vue.set(state, 'biproSiblingGesellschaften', {});
  },

}
