import CORE_TYPES from './types';
import Vue from 'vue'
import { getInitialState, ResetStateSource } from './index'
import { VIEW_ROLES } from '@/router/roles'

export default {
  [CORE_TYPES.MUTATIONS.GLOBAL_LOADING_STATE_START](state) {
    const count = state.baseGlobalState.loading + 1;
    Vue.set(state.baseGlobalState, 'loading', count);
  },

  [CORE_TYPES.MUTATIONS.GLOBAL_LOADING_STATE_STOP](state) {
    const count = state.baseGlobalState.loading - 1;
    Vue.set(state.baseGlobalState, 'loading', count >= 0 && count || 0);
  },

  [CORE_TYPES.MUTATIONS.SET_INTERNET_ACTIVE](state, payload) {
    Vue.set(state, 'internetActive', payload === true);
  },

  [CORE_TYPES.MUTATIONS.SET_LOGGING_IN](state, payload) {
    Vue.set(state, 'loggingIn', payload);
  },

  [CORE_TYPES.MUTATIONS.SET_CONFIRM_2FA](state, payload) {
    Vue.set(state, 'confirm2FA', payload);
  },

  [CORE_TYPES.MUTATIONS.SET_LOGGING_OUT](state, payload) {
    Vue.set(state, 'loggingOut', payload);
  },

  [CORE_TYPES.MUTATIONS.LOGIN_SUCCESS](state, payload) {
    Vue.set(state, 'loginData', {
      ...payload,
      referrer: state.referrer ? state.referrer : payload.referrer,
    });
  },

  [CORE_TYPES.MUTATIONS.UPDATE_TIMEOUT_SCHEDULE](state, { timeoutSchedule, nextCheckTimeoutTime, supposedTimeoutTime }) {
    Vue.set(state.baseGlobalState, 'timeoutSchedule', timeoutSchedule);
    Vue.set(state.baseGlobalState, 'nextCheckTimeoutTime', nextCheckTimeoutTime);
    Vue.set(state.baseGlobalState, 'supposedTimeoutTime', supposedTimeoutTime);
    
  },

  [CORE_TYPES.MUTATIONS.CHANGE_FLAG_TIMEOUT_VISIBILITY_CHANGE](state, flag) {
    Vue.set(state.baseGlobalState, 'timeoutFlagVisibilityChange', flag);
  },

  [CORE_TYPES.MUTATIONS.SET_LOGOUT_RUNNING] (state, flag) {
    Vue.set(state.baseGlobalState, 'isLogoutRunning', flag);
  },

  [CORE_TYPES.MUTATIONS.RESET_STATE](state, source) {
    const currentState = state;
    const initialState = getInitialState();

    let newState = {
      ...initialState,
      screenWidth: currentState.screenWidth, // keeps screenWidth
    };

    if (source === ResetStateSource.CloseExtraWebInstance) {
      // keeps pendingActions when closing an extra web instance - it happens on mobile context
      newState.baseGlobalState ??= {};
      newState.baseGlobalState.pendingActions = {
        ...newState.baseGlobalState?.pendingActions || {},
        ...currentState.baseGlobalState?.pendingActions || {},
      };
    }

    Object.assign(state, newState);
  },

  [CORE_TYPES.MUTATIONS.MARK_INITIAL_APP_LOADED](state) {
    Vue.set(state, 'isInitialAppState', false);
  },

  [CORE_TYPES.MUTATIONS.LOGIN_FAIL](state) {
    Vue.set(state, 'loginData', {});
  },

  [CORE_TYPES.MUTATIONS.GET_USER_PICTURE_SUCCESS](state, payload) {
    Vue.set(state.loginData, 'userPicture', {
      url: `${payload.url}&time=${new Date().getTime()}`, // time is to force update
    });
  },

  [CORE_TYPES.MUTATIONS.MERGE_PENDING_ACTIONS](state, payload) {
    for (const [key, value] of Object.entries(payload)) {
      const previousValue = state.baseGlobalState.pendingActions[key] || {}
      const pending = Object.assign(previousValue, typeof value === 'boolean' ? { pending: value } : value)
      Vue.set(state.baseGlobalState.pendingActions, key, pending);
    }
  },
  [CORE_TYPES.MUTATIONS.REMOVE_PENDING_ACTION](state, key) {
    Vue.delete(state.baseGlobalState.pendingActions, key);
  },
  [CORE_TYPES.MUTATIONS.MARK_PENDING_ACTION_AS_DONE](state, key) {
    if (!key) return;

    const { pendingActions = {} } = state?.baseGlobalState || {};
    if (key in pendingActions) {
      // it prevents identifying an action as pending 
      // when the '/login/get_pending_actions' service is called twice 
      // after concluding the action in an active session
      Vue.set(state.baseGlobalState.pendingActions[key], 'done', true);
    }
  },
  [CORE_TYPES.MUTATIONS.MARK_PENDING_ACTION_AS_SKIPPED](state, key) {
    if (!key) return;

    const { pendingActions = {} } = state?.baseGlobalState || {};
    if (key in pendingActions) {
      // it prevents identifying an action as pending 
      // when the '/login/get_pending_actions' service is called twice 
      // after skipping the action in an active session
      Vue.set(state.baseGlobalState.pendingActions[key], 'skipped', true);
    }
  },

  [CORE_TYPES.MUTATIONS.RECORDING_TUTORIAL_START](state) {
    Vue.set(state.baseGlobalState, 'recordingTutorial', true);
  },

  [CORE_TYPES.MUTATIONS.RECORDING_TUTORIAL_STOP](state) {
    Vue.set(state.baseGlobalState, 'recordingTutorial', false);
  },

  [CORE_TYPES.MUTATIONS.PLAYING_TUTORIAL_START](state) {
    Vue.set(state.baseGlobalState, 'playingTutorial', true);
  },  

  [CORE_TYPES.MUTATIONS.PLAYING_TUTORIAL_STOP](state) {
    Vue.set(state.baseGlobalState, 'playingTutorial', false);
  },

  [CORE_TYPES.MUTATIONS.ADD_RECORDED_ITEM](state, payload) {
    state.baseGlobalState.tutorialRecordedItems.push(payload);
  },

  [CORE_TYPES.MUTATIONS.REMOVE_RECORDED_ITEM](state, payload) {
    state.baseGlobalState.tutorialRecordedItems = state.baseGlobalState.tutorialRecordedItems.filter(m => m.stepKey !== payload.stepKey);
  },

  [CORE_TYPES.MUTATIONS.UPDATE_RECORDED_ITEM](state, payload) {
    const item = state.baseGlobalState.tutorialRecordedItems.find(item => item.stepKey === payload.stepKey);
    Object.assign(item, payload);
  },

  [CORE_TYPES.MUTATIONS.SET_SECONDARY_MENU](state, payload) {
    Vue.set(state.baseGlobalState, 'secondaryMenu', payload);
  },

  [CORE_TYPES.MUTATIONS.SET_LOADING_TOKEN](state, payload) {
    Vue.set(state, 'loadingToken', payload);
  },
  [CORE_TYPES.MUTATIONS.SET_MANAGING_LOGIN_RESPONSE](state, payload) {
    Vue.set(state, 'managingLoginResponse', payload);
  },

  [CORE_TYPES.MUTATIONS.GET_BROKER_INFORMATION_SUCCESS]( state, payload) {
    const layoutParamsFC = state.brokerData?.layoutParamsFC
    Vue.set(state, 'brokerData', {
      ...payload.brokerInfo,
      layoutParamsFC,
      isLoaded: !!(state.loginData.token) // TODO check why isLoaded depends on token?
    })
  },

  [CORE_TYPES.MUTATIONS.UPDATE_BROKER_LOGO](state, payload) {
    Vue.set(state, 'maklerLogo', payload)
  },

  [CORE_TYPES.MUTATIONS.UPDATE_BROKER_IMAGE_BACKGROUND_LOGIN_URL](state, payload) {
    Vue.set(state, 'imageBackgroundLoginUrl', payload)
  },

  [CORE_TYPES.MUTATIONS.ADD_COLOR_SCHEMA](state, payload) {
    if (payload && payload.length) {
      for (const colorSchema of payload) {
        Vue.set(state.baseGlobalState.colorSchema, colorSchema.schema, colorSchema);
      }
    }
  },

  [CORE_TYPES.MUTATIONS.SET_CONFIGURED_COLOR_SCHEME](state, payload) {
    Vue.set(state.baseGlobalState, 'configuredColorScheme', payload && payload !== 'device' ? payload : "");
  },

  /*
    Have a look at CORE_TYPES.ACTIONS.LOAD_COLOR_SCHEMA. The dark mode stuffs are commented out.
  */
  [CORE_TYPES.MUTATIONS.SET_PREFERRED_COLOR_SCHEMA](state, payload) {
    Vue.set(state.baseGlobalState, 'preferredColorSchema', payload)
  },

  [CORE_TYPES.MUTATIONS.RECORDING_TUTORIAL_TITLE](state, payload) {
    Vue.set(state.baseGlobalState, 'tutorialTitle', payload.tutorialTitle);
  },

  [CORE_TYPES.MUTATIONS.SEND_TUTORIAL_SUCCESS](state, payload) {
    state.baseGlobalState.tutorialRecordedItems =  payload.lastInsertedRecord.items;
    state.baseGlobalState.tutorialLastId = payload.lastInsertedRecord.id;
    // if (router.path!=='/customer/tutorial-recorded-items') {
    //   router.push({ path: '/customer/tutorial-recorded-items' });
    // }
    
  },

  [CORE_TYPES.MUTATIONS.SEND_TUTORIAL_FAIL](state, payload) {
    // console.log(payload);
  },


  [CORE_TYPES.MUTATIONS.RETRIEVE_TUTORIALS_SUCCESS](state, payload) {
    state.baseGlobalState.recordedTutorials = payload;
  },

  [CORE_TYPES.MUTATIONS.RECORDING_TUTORIAL_ROUTE](state, payload) {
    Vue.set(state.baseGlobalState, 'tutorialRoute', payload.tutorialRoute);
  },

  [CORE_TYPES.MUTATIONS.RECORDING_TUTORIAL_USER_TYPE](state, payload) {
    Vue.set(state.baseGlobalState, 'tutorialUserType', payload.tutorialUserType);
  },  

  [CORE_TYPES.MUTATIONS.RECORDED_TUTORIALS](state, payload) {
    Vue.set(state.baseGlobalState, 'recordedTutorials', payload);
  },

  [CORE_TYPES.MUTATIONS.RECORDING_FEATURE_ENABLE](state) {
    Vue.set(state.baseGlobalState, 'recordingFeature', true);
  },

  [CORE_TYPES.MUTATIONS.RECORDING_FEATURE_DISABLE](state) {
    Vue.set(state.baseGlobalState, 'recordingFeature', false);
  },

  [CORE_TYPES.MUTATIONS.REMOVE_ALL_RECORDED_ITEMS](state) {
    Vue.set(state.baseGlobalState, 'tutorialRecordedItems', [] );
    // state.baseGlobalState.tutorialRecordedItems.length = 0 ;
  
  },

  [CORE_TYPES.MUTATIONS.RECORDING_TUTORIAL_LAST_ID](state, payload) {
    Vue.set(state.baseGlobalState, 'tutorialLastId', payload.id);
  },

  [CORE_TYPES.MUTATIONS.SET_SOCIAL_MEDIA_SRC](state, payload) {
    if (state.brokerData.socialMediaSrc){
      state.brokerData.socialMediaSrc[payload.target] = payload.value;
    }
    else {
      state.brokerData["socialMediaSrc"]={};
      state.brokerData.socialMediaSrc[payload.target];
    }
  },

  [CORE_TYPES.MUTATIONS.GET_BROKER_LAYOUT_SUCCESS](state, payload) {
    Vue.set(state.brokerData, 'layoutParamsFC', payload);
  },
  
  [CORE_TYPES.MUTATIONS.SET_HAS_HOMEPAGE_ROLE](state) {
    if (state.loginData.roles)
    state.loginData.roles.push("HAS_HOMEPAGE");
  },

  [CORE_TYPES.MUTATIONS.PUSH_ORIGINAL_USER](state, payload) {
    state.originalUserStack.push(payload)
  },

  [CORE_TYPES.MUTATIONS.POP_ORIGINAL_USER](state) {
    state.originalUserStack.pop()
  },

  [CORE_TYPES.MUTATIONS.GET_SYSTEM_DATA_SUCCESS](state, payload) {
    if (payload?.comboData) {
      const systemData = {
        comboData: {
          ...state.systemData?.comboData,
          ...payload.comboData
        }
      }
      Vue.set(state, 'systemData', systemData);
    }
  },

  [CORE_TYPES.MUTATIONS.GET_APP_LINKS_SUCCESS](state, payload) {
    Vue.set(state, 'appLinks', { ...state.appLinks, ...payload})
  },

  [CORE_TYPES.MUTATIONS.LOCK_ADD_BREADCRUMB](state, payload) {
    Vue.set(state, 'addBreadcrumbLocked', payload);
  },

  [CORE_TYPES.MUTATIONS.PUSH_SAVE_BACK_TO_PREVIOUS_PAGE](state, payload) {
    if (payload.resetPreviousState && payload.resetPreviousState == true) {
      state.backToPreviousData = [];
    } else if(payload?.popLastPreviousData === true) {
      state.backToPreviousData?.pop();
    } else {
      if(state.backToPreviousData?.length && payload?.replaceLastPreviousData === true) {
        state.backToPreviousData.pop();
      }

      const foundBreadcrumb = state.backToPreviousData.findIndex(bpd => bpd.breadcrumb === payload.breadcrumb && bpd.label === payload.label);

      if (foundBreadcrumb == -1) {
        state.backToPreviousData.push({...payload})
      }
    }
  },

  [CORE_TYPES.MUTATIONS.REPLACE_BACK_TO_PREVIOUS_PAGE](state, payload) {
    Vue.set(state, 'backToPreviousData', payload);
  },

  [CORE_TYPES.MUTATIONS.SET_BACK_TO_PREVIOUS_PAGE_TRACK](state, payload) {
    state.backToPreviousDataTrack = {
      ...state.backToPreviousDataTrack,
      ...payload,
    };
  },

  [CORE_TYPES.MUTATIONS.POP_SAVE_BACK_TO_PREVIOUS_PAGE](state, payload) {
    if (state.backToPreviousData?.length) {
      state.backToPreviousData.pop()
    }
    if (payload?.trackUrl && state.backToPreviousDataTrack[payload?.trackUrl]?.length) {
      state.backToPreviousDataTrack[payload?.trackUrl].pop()
    }
  },

  [CORE_TYPES.MUTATIONS.UPDATE_CURRENT_BACK_TO_PREVIOUS_PAGE](state, payload) {
    if(state.backToPreviousData?.length > 0 && Object.keys(payload).length > 0) {
      const currentBackToPreviousPage = state.backToPreviousData[state.backToPreviousData.length - 1];
      Object.keys(payload).forEach(key => {
        currentBackToPreviousPage[key] = payload[key];
      })
    }
  },
  
  [CORE_TYPES.MUTATIONS.SAVE_BACK_TO_PREVIOUS_PAGE_SHIFT](state, payload) {
    if (state.backToPreviousData?.length) {
      state.backToPreviousData = state.backToPreviousData.filter(br => !br.breadcrumb)
    }
  },

  [CORE_TYPES.MUTATIONS.GET_PRIVACY_DATA_SUCCESS](state, payload) {
    state.privacyInfo = payload
  },

  [CORE_TYPES.MUTATIONS.UPDATE_PRIVACY](state, payload) {
    Vue.set(state.loginData, 'privacy', payload);
  },

  [CORE_TYPES.MUTATIONS.UPDATE_USER_FULL_NAME](state, payload) {
    if(payload) {
      Vue.set(state.loginData, 'fullname', payload);
    }
  },

  [CORE_TYPES.MUTATIONS.SET_REGISTRATION_DATA](state, payload) {
    Vue.set(state, 'registrationData', payload);
  },

  [CORE_TYPES.MUTATIONS.GET_PRIVACY_REGISTRATION_DATA_SUCCESS](state, payload) {
    Vue.set(state, 'privacyRegistrationData', payload);
  },

  [CORE_TYPES.MUTATIONS.RESET_PRIVACY_REGISTRATION_DATA](state) {
    Vue.set(state, 'privacyRegistrationData', {});
  },

  [CORE_TYPES.MUTATIONS.CLEAR_ALL_REGISTRATION_DATA](state) {
    Vue.set(state, 'registrationData', {});
    Vue.set(state, 'privacyRegistrationData', {});
  },

  [CORE_TYPES.MUTATIONS.GET_CAPTCHA_RESULT](state, payload) {
    Vue.set(state, 'captchaData', payload);
  },

  [CORE_TYPES.MUTATIONS.GET_DEFAULT_LAYOUT_SUCCESS](state, payload) {
    Vue.set(state, 'defaultLayoutFc', payload);
  },

  [CORE_TYPES.MUTATIONS.GET_LAYOUT_SUCCESS](state, payload) {
    Vue.set(state, 'layoutFc', payload);
  },

  [CORE_TYPES.MUTATIONS.GET_MFA_CHALLENGE_TYPE_SUCCESS](state, payload) {
    Vue.set(state, 'mfaChallengeType', payload);
  },

  [CORE_TYPES.MUTATIONS.REPLACE_VIEW_ROLES](state, payload) {
    if (Array.isArray(payload.roles)) {
      const rolesWithoutViewRoles = state.loginData?.roles?.filter(e => !Object.values(VIEW_ROLES).includes(e));
      rolesWithoutViewRoles.push(...payload.roles)
      Vue.set(state.loginData, 'roles', rolesWithoutViewRoles)
    }
  },

  /**
   * intended to add roles after login to avoid logout and login again
   */
  [CORE_TYPES.MUTATIONS.ADD_BELATED_ROLE](state, roles) {
    if (Array.isArray(roles)) {
      const newRoles = state.loginData?.roles?.filter(e => !Object.values(roles).includes(e));
      newRoles.push(...roles)
      Vue.set(state.loginData, 'roles', newRoles)
    }
  },

  [CORE_TYPES.MUTATIONS.GET_ALL_CMS_META_TAG_SUCCESS](state, payload) {
    if (Array.isArray(payload)) {
      Vue.set(state, 'cmsMetaTags', payload)
    }
  },

  [CORE_TYPES.MUTATIONS.UPDATE_LOGIN_WELCOME_TITLE](state, loginWelcomeTitle) {
    if (loginWelcomeTitle) {
      Vue.set(state, 'loginWelcomeTitle', loginWelcomeTitle)
    }
  },

  [CORE_TYPES.MUTATIONS.ADD_CONFIRM_MODAL](state, { _uuid, options } = {}) {
    if(!_uuid) return;

    const _deferred = {
      _promise: null,
      _resolve: null,
      _reject: null,
    };
    _deferred._promise = new Promise((resolve, reject) => {
      _deferred._resolve = resolve;
      _deferred._reject = reject;
    });

    const _confirm = (feedback) => feedback ? _deferred._resolve() : _deferred._reject('confirmation canceled!');

    const payload = {
      _uuid,
      _deferred,
      _confirm,
      options,
    };

    Vue.set(state, 'pendingConfirmModal', {
      ...state.pendingConfirmModal,
      [_uuid]: {
        ...payload,
      },
    });
  },

  [CORE_TYPES.MUTATIONS.REPLY_CONFIRM_MODAL](state, { _uuid, feedback, }) {
    const pendingConfirmModal = state.pendingConfirmModal[_uuid];
    pendingConfirmModal?._confirm(feedback);

    delete state.pendingConfirmModal[_uuid];
    Vue.set(state, 'pendingConfirmModal', { ...state.pendingConfirmModal, });
  },

  [CORE_TYPES.MUTATIONS.SCREEN_WIDTH](state, payload) {
    state.screenWidth = payload;
  },

  [CORE_TYPES.MUTATIONS.CHECK_PIN](state, {key, value, action, payload}) {
    if (key && (value == -1 || value == 0 || value == 1 || value == 2 || value == 3)) {
      if (action){
        Vue.set(state.askPIN, key, {value, action, payload, sendPin: false});
      } else {
        Vue.set(state.askPIN, key, Object.assign(state.askPIN[key] || {}, {value, sendPin: true}));
      }
    }
  },

  [CORE_TYPES.MUTATIONS.RESET_EVENT_BASE_FILTER](state) {
    Vue.set(state, 'resetEventBaseFilter', state.resetEventBaseFilter + 1 );
  },

  [CORE_TYPES.MUTATIONS.MSC_BUILD_INFO](state, payload) {
    Vue.set(state, 'mscBuildInfo', payload );
  },

  [CORE_TYPES.MUTATIONS.UPDATE_IS_INAKTIV_SUCCESS](state, isInaktiv) {
    Vue.set(state.brokerData, 'inaktiv', isInaktiv );
  },

  [CORE_TYPES.MUTATIONS.ADD_GENERIC_SELECTION](state, { uuid, list }) {
    if (!uuid) return;
    Vue.set(state.genericSelection, uuid, [ ...list ]);
  },

  [CORE_TYPES.MUTATIONS.REMOVE_GENERIC_SELECTION](state, uuid) {
    if (!uuid) return;
    Vue.delete(state.genericSelection, uuid);
  },

  [CORE_TYPES.MUTATIONS.CHAT_EMPFAENGER_KEY](state, payload) {
    state.CHAT_EMPFAENGER_KEY = payload
  },
  [CORE_TYPES.MUTATIONS.EXCEPTION_LOG](state, payload) {
    Vue.set(state, 'exceptionLog', payload );
  },

}