<template>
  <ph-PhoneDisconnect :size="size" @click="disconnect" class="clickable" v-if="activeTapi" />
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import { mapGetters } from 'vuex';
import { PhPhoneDisconnect } from 'phosphor-vue'
import { ROLES } from '@/router/roles';
import TAPI from '@/store/tapi/types';
import log from '@/store/log';

export default {
  props: {
    size: {
      default: 18
    }
  },
  computed: {
    ...mapGetters({
     
      ACTIVE_TAPI: TAPI.GETTERS.ACTIVE_CALL,
      
    }),
      activeTapi() {
        
     
        return this.ACTIVE_TAPI;
    },
  },
  methods: {
    disconnect() {
          this.$store.dispatch(TAPI.ACTIONS.CALL_DISCONNECT);
     },
  },
  components: {
    PhPhoneDisconnect
  }
}
</script>

<style scoped>
.flex-content {
  display: flex;
  justify-content: center;
  flex-direction: row;
}
.flex-content > div {
  cursor: pointer;
}
.logout__container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.uk-link {
  color: var(--color-primary-text);
  
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  font-size: 1.1rem;
  font-weight: 500;
}
.uk-link:hover {
  color: var(--color-top-nav-text);
}
.anmelden_link {
  color: var(--color-top-nav-text);
  text-decoration: none;
  padding: 9px 20px;
  outline: var(--color-top-nav-background) solid 1px;
}
.logout__container {
  display: none;
}
@media only screen and (max-width: 640px) {
  .anmelden_link {
    display: none;
  }
  .logout__container {
    display: flex;
  }
}
</style>