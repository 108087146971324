<template>
  <div :style="style">
    <v-chart class="chart" :option="option" :theme="themeKey" autoresize />
  </div>
</template>

<script>
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { GaugeChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from 'echarts/components';
import VChart from 'vue-echarts';

import echartsMixin from './echarts-mixin';

use([
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GaugeChart,
]);

export default {
  name: 'GaugeChart',
  mixins: [echartsMixin],
  components: {
    VChart
  },
  props: {
    chartData: {
      type: Array,
      default: () => [],
    },
    format: {
      type: String,
      default: 'currency',
    },
    numberFixed: {
      type: Number,
      default: 2,
    },
    height: {
      type: String,
      default: '200px',
    },
  },
  data() {
    return {
    }
  },
  computed: {
    option() {
      return {
        color: this.themeColors,
        backgroundColor: 'transparent',
        grid: {
          show: false,
          bottom: '10%',
          right: '10%',
          left: '10%',
          top: '10%',
        },
        tooltip: {
          show: this.isTooltip,
          formatter: this.tooltipFormatter,
          position: { left: 'center', top: 'auto'},
        },
        xAxis: {
          show: false,
          type: 'category',
          data: (this.chartData || []).map(cd => cd.name),
          axisLabel: {
            rotate: 45,
            fontSize: 10,
          },
        },
        yAxis: {
          show: false,
          type: 'value',
          axisLabel: {
            formatter: this.axisLabelFormatter(this.axisLabelFormatterShowOption),
            fontSize: 10,
          },
        },
        series: [
          {
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            name: this.chartDataName,
            data: this.chartData || [],
            axisLine: {
              lineStyle: {
                width: 35,
                borderColor: '#ffffff',
                borderWidth: 2,
              },
            },
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
            },
            progress: {
              show: true,
              overlap: false,
              clip: false,
              width: 40,
            },
            pointer: {
              show: false
            },
            detail: {
              show: false,
            },
            title: {
              show: false,
            },
          }
        ],
      }
    }
  },
};
</script>
