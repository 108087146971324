<template>
  <div>
    <div class="row">
      <div class="col-6">
        <PieChart 
          :chartData="eChartData" 
          :customColors="colors"
          height="280px"
          isTooltip
          doughnut/>
      </div>
      <div class="col-6 d-fl-c">
        <div v-for="(val, index) of labels" class="row" :key="index">
          <span class="col-auto p-0" style="align-self: center">
            <div class="legend-item" :style="{'background-color': colors[index]}">
              &nbsp;
            </div>
          </span>
          <span class="col">{{val}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getColorsMixedContrastDecreasing} from '@/helpers/colors-helper';
import PieChart from '@/components/charts/echarts/PieChart.vue'

export default {
  props: {
    data: {
      type: Array,
      default: () => [],
      required: true
    },
    labels: {
      type: Array,
      default: () => ['vorhandenes Kapital', 'angespartes Kapital', 'Finanzierungsbedarf'],
      required: true
    },
  },
  computed: {
    eChartData() {
      return this.data.map((value, i) => ({
        name: this.labels[i] || 'Leer',
        value: value || 0,
      }))
    },
    colors() {
      return getColorsMixedContrastDecreasing()?.slice(0,3)?.concat(['#d4d4d4']);
    },
  },
  components: {
    PieChart,
  },
};
</script>

<style scoped>
.d-fl-c {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.legend-item {
  height: 16px;
  width: 16px;
  border-radius: 50%;
}
</style>