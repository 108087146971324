import SCHADENSMELDUNG_TYPES from './types';
import axios from 'axios';

const config = {
  defaultSpinner: true
};

export default {
  [SCHADENSMELDUNG_TYPES.ACTIONS.RETRIEVE_SCHADENSMELDUNG]({ commit, state, dispatch, rootState }, payload) {
    if (!payload?.schadenId && !payload?.schadennr || payload?.schadenId === 'neuesSchaden') {
      return ;
    }

    let url = '/schadensmeldung/list';
    
    if (payload && payload.vertragId && payload.schadenId) {
      url = `${url}?vertragId=${payload.vertragId}&schadenId=${payload.schadenId}`;
    }
    if (payload && payload.vertragId && payload.schadennr) {
      url = `${url}?vertragId=${payload.vertragId}&schadennr=${payload.schadennr}`;
    }
    axios.get(rootState.core.apiAddress + url, payload, config).then(response => {
      const result = response.data?.filter(val => val.schadenId == payload.schadenId || val.schadennr == payload.schadennr)
      const data = result && result.length && result[0] || {};
      commit(SCHADENSMELDUNG_TYPES.MUTATIONS.RETRIEVE_SCHADENSMELDUNG_SUCCESS, data);
    })
  },

  [SCHADENSMELDUNG_TYPES.ACTIONS.SEND_SCHADENSMELDUNG]({ commit, dispatch, rootState }, payload) {
    return new Promise((resolve, reject) => {
      let url = '/schadensmeldung/create';

     axios.post(rootState.core.apiAddress + url, payload, config).then(response => {
        commit(SCHADENSMELDUNG_TYPES.MUTATIONS.SEND_SCHADENSMELDUNG_SUCCESS, response?.data || {});

        dispatch(SCHADENSMELDUNG_TYPES.ACTIONS.RETRIEVE_SCHADENSMELDUNG, {
          schadenId: response?.data?.id,
          vertragId: payload?.vertragId,
        })

        commit(SCHADENSMELDUNG_TYPES.MUTATIONS.RESET_SCHADENSMELDUNG_EDITED);
        resolve(response.data);
      }).catch(error => {
        reject(error);
      })
    })
  },

  [SCHADENSMELDUNG_TYPES.ACTIONS.SEND_BETEILIGTE]({ commit, dispatch, rootState }, payload) {
    return new Promise((resolve, reject) => {
      const config = {
        defaultSpinner: true
      };

      let url = '/schadensmeldung/beteiligte';   

      axios.post(rootState.core.apiAddress + url, payload, config).then(response => {
        if (response && response.data) {
          commit(SCHADENSMELDUNG_TYPES.MUTATIONS.SEND_BETEILIGTE_SUCCESS, response.data);
        }
        resolve();
      }).catch(error => {
        reject(error);
      })
    });
  },

  [SCHADENSMELDUNG_TYPES.ACTIONS.DELETE_BETEILIGTE]({ commit, dispatch, rootState }, payload) {
    let url = '/schadensmeldung/deleteBeteiligte';   

    if(payload && payload.schadenId && payload.id) {
      url = `${url}?schadenId=${payload.schadenId}&beteiligterId=${payload.id}`;
    }

    axios.delete(rootState.core.apiAddress + url, config).then(response => {
      if (response && response.data) {
        commit(SCHADENSMELDUNG_TYPES.MUTATIONS.DELETE_BETEILIGTE_SUCCESS, response.data);
      }
    })
  },

  [SCHADENSMELDUNG_TYPES.ACTIONS.SEND_ANHANG]({ commit, dispatch, rootState }, payload) {
    let url = '/schadensmeldung/document';   

    if(payload && payload.fileId && payload.schadenId) {
      url = `${url}?fileId=${payload.fileId}&schadenId=${payload.schadenId}`;
    }   

    axios.post(rootState.core.apiAddress + url, payload, config).then(response => {
      if (response && response.status===200) {
        commit(SCHADENSMELDUNG_TYPES.MUTATIONS.SEND_ANHANG_SUCCESS, response.data);
      }      
    })
  },

  [SCHADENSMELDUNG_TYPES.ACTIONS.DELETE_ANHANG]({ commit, state, dispatch, rootState }, payload) {
    let url = '/schadensmeldung/deleteAnhang';   

    if(payload && payload.antragId) {
      url = `${url}?antragId=${payload.antragId}`;
    }   


    axios.delete(rootState.core.apiAddress + url, config).then(response => {
      if (response && response.data) {

        commit(SCHADENSMELDUNG_TYPES.MUTATIONS.DELETE_ANHANG_SUCCESS, response.data);

      }      

    })
  },

  [SCHADENSMELDUNG_TYPES.ACTIONS.SEND_ZAHLUNG]({ commit, dispatch, rootState }, payload) {
    return new Promise((resolve, reject) => {
      const config = {
        defaultSpinner: true
      };

      let url = '/schadensmeldung/zahlung';   

      axios.post(rootState.core.apiAddress + url, payload, config).then(response => {
        if (response && response.data) {
          commit(SCHADENSMELDUNG_TYPES.MUTATIONS.SEND_ZAHLUNG_SUCCESS, response.data);
        }      
        resolve();
      }).catch(error => {
        reject(error);
      })
    });
  },

  [SCHADENSMELDUNG_TYPES.ACTIONS.DELETE_ZAHLUNG]({ commit, dispatch, rootState }, payload) {
    let url = '/schadensmeldung/deleteZahlung';   

    if(payload && payload.schadenId && payload.zahlungId) {
      url = `${url}?schadenId=${payload.schadenId}&zahlungId=${payload.zahlungId}`;
    }   

    axios.delete(rootState.core.apiAddress + url, config).then(response => {
      if (response && response.data) {
        commit(SCHADENSMELDUNG_TYPES.MUTATIONS.DELETE_ZAHLUNG_SUCCESS, response.data);
      }
    })
  },

  [SCHADENSMELDUNG_TYPES.ACTIONS.SEND_BESCHAEDIGT]({ commit, dispatch, rootState }, payload) {
    return new Promise((resolve, reject) => {
      const config = {
        defaultSpinner: true
      };

      let url = '/schadensmeldung/beschaedigt';   

      axios.post(rootState.core.apiAddress + url, payload, config).then(response => {
        if (response && response.data) {
          commit(SCHADENSMELDUNG_TYPES.MUTATIONS.SEND_BESCHAEDIGT_SUCCESS, response.data);
        }      
        resolve();
      }).catch(error => {
        reject(error);
      })
    });
  },

  [SCHADENSMELDUNG_TYPES.ACTIONS.DELETE_BESCHAEDIGT]({ commit, dispatch, rootState }, payload) {
    let url = '/schadensmeldung/deleteBeschaedigt';   

    if(payload && payload.schadenId && payload.beschaedigtId) {
      url = `${url}?schadenId=${payload.schadenId}&beschaedigtId=${payload.beschaedigtId}`;
    }   

    axios.delete(rootState.core.apiAddress + url, config).then(response => {
      if (response && response.data) {
        commit(SCHADENSMELDUNG_TYPES.MUTATIONS.DELETE_BESCHAEDIGT_SUCCESS, response.data);
      }
    })
  },

  [SCHADENSMELDUNG_TYPES.ACTIONS.GET_CONFIG_DATA]({ commit, dispatch, rootState }, payload) {
    let url = '/schadensmeldung/configData';
    if(payload && payload.vertragId) {
      url = `${url}?vertragId=${payload.vertragId}`;
    }  
    axios.get(rootState.core.apiAddress + url, config).then(response => {
      if (response && response.data) {
        commit(SCHADENSMELDUNG_TYPES.MUTATIONS.GET_CONFIG_DATA_SUCCESS, response.data);
      }
    })
  },
  async [SCHADENSMELDUNG_TYPES.ACTIONS.MERGE_SCHADEN]({ commit, rootState, state }, e) {
    const config = {
      defaultSpinner: true,
    };


    await axios.put(rootState.core.apiAddress + '/schadensmeldung/mergeSchaden?schadenToKeep='+e.toKeep.schadenId+'&schadenToRemove=' + e.toRemove.schadenId).then(response => {

      /*let bb = {}
      bb.subject = response.data.subject;
      bb.text = response.data.text;

      commit(V_P.MUTATIONS.VP_BEMERKUNG, bb);*/
    })
  }

}