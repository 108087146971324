var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("BaseListWithSearch", {
        attrs: {
          componentIds: _vm.componentIds,
          pageHeaderActions: _vm.actions,
          pageHeaderActionListeners: _vm.pageHeaderActionListeners,
          baseFilterConfigs: _vm.baseFilterConfigs,
          baseFilterListeners: _vm.baseFilterListeners,
          tableConfigs: _vm.tableConfigs,
          tableListeners: _vm.tableListeners,
          loading: _vm.loading
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }