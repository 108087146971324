var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row mt-4 mb-5 justify-content-center" }, [
      _c("div", { staticClass: "linear-scale" }, [
        _c("div", {
          staticClass: "top-cursor",
          style: { left: _vm.topCursorPosition + "%" }
        }),
        _c("span", { staticClass: "scale-label" }, [_vm._v("geringes Risiko")]),
        _vm._m(0),
        _c("span", { staticClass: "scale-label label-right" }, [
          _vm._v("hohes Risiko")
        ]),
        _c("div", {
          staticClass: "bottom-cursor",
          style: { left: _vm.bottomCursorPosition + "%" }
        })
      ])
    ]),
    _c("div", { staticClass: "row" }, [
      _vm._m(1),
      _c("div", { staticClass: "col-2 col-md-1 text-right" }, [
        _c("span", { staticClass: "top-cursor-color" }, [
          _vm._v(_vm._s(_vm.getLabelValue(_vm.risk.GSRI)))
        ])
      ])
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-10 col-md-11" }, [
        _vm.displayAnlegerprofilLink
          ? _c(
              "span",
              { staticClass: "bottom-cursor-color" },
              [
                _vm._v("Risikoverteilung gemäß "),
                _c(
                  "router-link",
                  {
                    attrs: {
                      to:
                        "/beratung/anlegerprofil/person1/anlageziele/anlageziele"
                    }
                  },
                  [_vm._v("Anlegerprofil")]
                ),
                _vm._v(":")
              ],
              1
            )
          : _c("span", { staticClass: "bottom-cursor-color" }, [
              _vm._v("Risikoverteilung gemäß Anlegerprofil:")
            ])
      ]),
      _c("div", { staticClass: "col-2 col-md-1 text-right" }, [
        _c("span", { staticClass: "bottom-cursor-color" }, [
          _vm._v(_vm._s(_vm.getLabelValue(_vm.risk.SRIInvestorProfile)))
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sections" }, [
      _c("div", { staticClass: "section-item sr1" }),
      _c("div", { staticClass: "section-item sr2" }),
      _c("div", { staticClass: "section-item sr3" }),
      _c("div", { staticClass: "section-item sr4" }),
      _c("div", { staticClass: "section-item sr5" }),
      _c("div", { staticClass: "section-item sr6" }),
      _c("div", { staticClass: "section-item sr7" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-10 col-md-11" }, [
      _c("span", { staticClass: "top-cursor-color" }, [
        _vm._v("Risikoverteilung aktuell:")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }