<template>
  <div>
    <PageHeaderTitleNavigation title="Offene Unterschriften" />

    <OffeneUnterschriften v-if="zugriffKunden"/>
  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import { mapGetters } from 'vuex';
import { BROKER_LEVEL_ROLES, EMPLOYEE_ROLES } from '@/router/roles';
import OffeneUnterschriften from '@/components/communication/OffeneUnterschriften.vue'
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';

export default {
    components: {
        OffeneUnterschriften,
        OptionMenu,
        PageHeaderTitleNavigation,
    },
    computed: {
        ...mapGetters({
            hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
        }),
        zugriffKunden() {
            return this.hasRoles([[EMPLOYEE_ROLES.ZUGRIFF_KUNDEN, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN]])
        },
    },
}
</script>

<style>

</style>