import QUARTALSBERICHTE_BESTELLUNGEN_TYPES from './types';

export default {
  [QUARTALSBERICHTE_BESTELLUNGEN_TYPES.GETTERS.BESTELLUNGEN_DATA](state) {
    return state.bestellungenData;
  },
  [QUARTALSBERICHTE_BESTELLUNGEN_TYPES.GETTERS.EXECUTE_ORDERS_RESULT](state) {
    return state.executeOrdersResult;
  },



  
  
  
}