var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "makler34f-config-loading__container" },
    [
      _c(
        "GhostLoading",
        { staticClass: "mb-4" },
        [
          _c("Block", { staticClass: "mb-3", attrs: { type: "title" } }),
          _c("Block", {
            staticClass: "mb-3",
            attrs: { type: "form-input-switch" }
          }),
          _c("Block", { attrs: { type: "paragraph" } }),
          _c("Block", { attrs: { type: "paragraph" } })
        ],
        1
      ),
      _c("GhostLoading", { attrs: { type: "input", isHalfSize: "" } }),
      _c("GhostLoading", [
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c("Block", {
              staticClass: "mr-2 mb-0",
              attrs: { type: "form-input", width: 128 }
            }),
            _c("Block", { attrs: { type: "form-input", width: 128 } })
          ],
          1
        )
      ]),
      _c("GhostLoading", { attrs: { type: "table" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }