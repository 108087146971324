import VERSICHERUNGSTARIFE_TYPES from './types';

export default {
  [VERSICHERUNGSTARIFE_TYPES.GETTERS.VERSICHERUNGSTARIFE_DATA](state) {
    return state.tarifeData;
  },
  [VERSICHERUNGSTARIFE_TYPES.GETTERS.SELECTED_VERSICHERUNGSTARIF](state) {
    return state.selectedTarif;
  },
  [VERSICHERUNGSTARIFE_TYPES.GETTERS.VERSICHERUNGSTARIFE_GESELLSCHAFTEN](state) {
    return state.gesellschaften;
  },
  [VERSICHERUNGSTARIFE_TYPES.GETTERS.VERSICHERUNGSTARIFE_SPARTENTYPEN](state) {
    return state.spartentypen;
  },
  [VERSICHERUNGSTARIFE_TYPES.GETTERS.TARIF_DOKUMENTE](state) {
    return state.tarifDokumente;
  },
  [VERSICHERUNGSTARIFE_TYPES.GETTERS.SELECTED_DOCUMENT](state) {
    return state.selectedDocument;
  },
  [VERSICHERUNGSTARIFE_TYPES.GETTERS.SPARTE](state) {
    return state.sparte;
  },
  [VERSICHERUNGSTARIFE_TYPES.GETTERS.TARIFDATEN_VORGABE](state) {
    return state.tarifdatenVorgabe;
  },

  

  

  
}