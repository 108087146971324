<template>
  <div>
    <MailComposer
      :custom="true"
      :customFunctions="customFunctions"
      :skipPreInit="$route.meta.beratungsmappen"
      @callbacks="setCallbacks"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MailComposer from '../MailComposer.vue';
import MAILCOMPOSER_TYPES from '@/store/mailcomposer/types';
import BaseButton from '@/components/core/BaseButton.vue';
import {
  MAX_ITEMS_VISIBLE,
  KUNDE_TYPE,
  BROKER_TYPE,
  VERSICHERUNG_TAG_DESCRIPTION,
  GESELLSCHAFT_TAG_DESCRIPTION,
  KUNDE_TAG_DESCRIPTION,
  VERSICHERUNG_KUNDE_TAG_DESCRIPTION,
  VERSICHERUNG_GESELLSCHAFT_TAG_DESCRIPTION,
  VORLAGEN_TYPE_MAIL,
  VORLAGEN_TYPE_BRIEF, validateEmail, getFileName, getFileType
} from '../MailComposer.vue';
import validator from "@/mixins/validator";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import CORE_TYPES from "@/store/core/types";

export default {
  name: 'BeratungsmappenMailComposer',
  components: {
    MailComposer,
  },

  props: {},

  computed: {
    ...mapGetters({
      initBeratungsmappenMail: MAILCOMPOSER_TYPES.GETTERS.INIT_MAIL_BERATUNGSMAPPEN,
      savedConfiguration: MAILCOMPOSER_TYPES.GETTERS.GET_MAILCOMPOSER_CONFIG,
      attachments: MAILCOMPOSER_TYPES.GETTERS.SET_ATTACHMENTS,
    }),
  },

  data() {
    return {
      callbacks: {},
      customFunctions: {
        pageTitle: () => {
          if (this.$route.meta.beratungsmappen) {
            return 'Beratungsmappen E-Mail';
          }
          switch (this.$route.meta.type) {
            case 'ONE_MAIL':
              return 'E-Mail an Kunde';
            case 'MANY_MAIL':
              return 'Rund E-Mail';
            case 'APP':
              return 'Kunden APP zusenden';
            case 'BRIEF':
              return 'Brief an Kunde';
            case 'BRIEF_MAIL':
              return 'Brief an Kunde';
            case 'FAX':
              return 'Fax an Kunde';
            default:
              return 'E-Mail schreiben';
          }
        },
      }
    };
  },
  watch: {
    initBeratungsmappenMail(value) {
      if (value && this.$route.meta.beratungsmappen) {
        if (!this.savedConfiguration.subject) {
          this.callbacks.setSubject(value.subject);
        }
        if (!this.savedConfiguration.getComputedText) {
          this.callbacks.setHtmlText(value.html);
        }


        if (!this.savedConfiguration.receiver || !this.savedConfiguration.receiver.length) {
          let receiver = [];
          receiver = value.participants.filter(p => p.sendType != 'FROM').map(p => {
            return {
              value: p?.user?.userId,
              label: `${p.email}`,
              email: `${p.email}`,
              type: p?.user?.type,
            }
          });
          this.callbacks.setReceiver(receiver)
        }
        this.callbacks.setSendersValue(value.participants.filter(p => p.sendType == 'FROM'))
      }
    },
  },
  async mounted() {
    if (this.$route.meta.beratungsmappen) {
      this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.INIT_MAIL_BERATUNGSMAPPEN, this.$route.params.id)
    }
  },

  methods: {
    setCallbacks(value) {
      this.callbacks = value;
    },
  },
  validators: {},
  beforeRouteLeave(to, from, next) {
    this.callbacks.handleBeforeRouteLeave(to, from, next)
  }
};
</script>

<style scoped>
</style>
