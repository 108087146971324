var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _vm.positions && _vm.positions.length
          ? _c("Table", {
              attrs: {
                title: _vm.selectedPositionLabel,
                headers: _vm.headers,
                rows: _vm.rows,
                rowId: "bgsNr"
              },
              on: {
                "action-EDIT": _vm.edit,
                "click-kundenname": _vm.openCustomerNewTab
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "aktiv",
                    fn: function(row) {
                      return [
                        _c("InputToggleSwitch", {
                          attrs: { value: row.aktiv },
                          on: {
                            input: function($event) {
                              return _vm.updatePosition(row, { aktiv: $event })
                            }
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "mailversand",
                    fn: function(row) {
                      return [
                        row.hasMailversand
                          ? _c("InputToggleSwitch", {
                              attrs: {
                                value: row.mailversand,
                                disabled: !row.aktiv
                              },
                              on: {
                                input: function($event) {
                                  return _vm.updatePosition(row, {
                                    mailversand: $event
                                  })
                                }
                              }
                            })
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "positionName",
                    fn: function(row) {
                      return [
                        _c("div", { staticClass: "font-bold" }, [
                          _vm._v(_vm._s(row.positionName) + " ")
                        ])
                      ]
                    }
                  },
                  {
                    key: "tauschIn",
                    fn: function(row) {
                      return [
                        _c("div", { staticClass: "font-bold" }, [
                          _vm._v(_vm._s(row.tauschIn) + " ")
                        ])
                      ]
                    }
                  }
                ],
                null,
                false,
                392151883
              )
            })
          : _c("NoData", {
              attrs: { title: _vm.selectedPositionLabel, noIcon: "" }
            })
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "BaseModal",
          {
            ref: "editModal",
            on: {
              onCloseButton: _vm.closeEditModal,
              onCancelButton: _vm.closeEditModal,
              onConfirmButton: function($event) {
                _vm.updateEditPosition()
                _vm.closeEditModal()
              }
            },
            scopedSlots: _vm._u([
              {
                key: "modalTitle",
                fn: function() {
                  return [
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.editPosition.kundenname) +
                          ", Kontonr: " +
                          _vm._s(_vm.editPosition.depotNr)
                      )
                    ])
                  ]
                },
                proxy: true
              }
            ])
          },
          [
            _c(
              "div",
              [
                _c("InputToggleSwitch", {
                  attrs: { label: "Aktiv" },
                  model: {
                    value: _vm.editPosition.aktiv,
                    callback: function($$v) {
                      _vm.$set(_vm.editPosition, "aktiv", $$v)
                    },
                    expression: "editPosition.aktiv"
                  }
                }),
                _vm.editPosition.hasMailversand
                  ? _c("InputToggleSwitch", {
                      attrs: { label: "Mailversand" },
                      model: {
                        value: _vm.editPosition.mailversand,
                        callback: function($$v) {
                          _vm.$set(_vm.editPosition, "mailversand", $$v)
                        },
                        expression: "editPosition.mailversand"
                      }
                    })
                  : _vm._e(),
                _c("InputField", {
                  attrs: {
                    type: "number",
                    label: "Betrag",
                    disabled: _vm.betragDisabled(_vm.editPosition)
                  },
                  model: {
                    value: _vm.editPosition.betrag,
                    callback: function($$v) {
                      _vm.$set(_vm.editPosition, "betrag", $$v)
                    },
                    expression: "editPosition.betrag"
                  }
                }),
                _c("ComboBox", {
                  attrs: { values: _vm.betragModusValues },
                  model: {
                    value: _vm.editPosition.betragModus,
                    callback: function($$v) {
                      _vm.$set(_vm.editPosition, "betragModus", $$v)
                    },
                    expression: "editPosition.betragModus"
                  }
                }),
                _vm.isVerkauf
                  ? _c(
                      "div",
                      [
                        _c("DatePickerField", {
                          attrs: {
                            label: "Termin",
                            isValueAsString: "",
                            showSofortOption: ""
                          },
                          model: {
                            value: _vm.editPosition.terminVerkauf,
                            callback: function($$v) {
                              _vm.$set(_vm.editPosition, "terminVerkauf", $$v)
                            },
                            expression: "editPosition.terminVerkauf"
                          }
                        })
                      ],
                      1
                    )
                  : _c(
                      "div",
                      [
                        _c("DatePickerField", {
                          attrs: {
                            label: "Termin",
                            isValueAsString: "",
                            showSofortOption: ""
                          },
                          model: {
                            value: _vm.editPosition.terminTausch,
                            callback: function($$v) {
                              _vm.$set(_vm.editPosition, "terminTausch", $$v)
                            },
                            expression: "editPosition.terminTausch"
                          }
                        }),
                        _vm.editPosition.aaModus === "INPUT_AA" ||
                        _vm.editPosition.aaModus === "INPUT_BONUS"
                          ? _c("InputField", {
                              attrs: {
                                labelClass: "font-bold",
                                type: "percent",
                                label: _vm.editPosition.aaLabel
                              },
                              model: {
                                value: _vm.editPosition.inputAA,
                                callback: function($$v) {
                                  _vm.$set(_vm.editPosition, "inputAA", $$v)
                                },
                                expression: "editPosition.inputAA"
                              }
                            })
                          : _vm.editPosition.aaModus === "COMBO_BONUS" ||
                            _vm.editPosition.aaModus === "COMBO_RABATT"
                          ? _c("ComboBox", {
                              attrs: {
                                label: _vm.editPosition.aaLabel,
                                values: !_vm.editPosition.lagerstelle
                                  ? []
                                  : _vm.aaComboboxValues[
                                      _vm.editPosition.lagerstelle
                                    ],
                                sortComboboxValues: false,
                                labelClass: "font-bold"
                              },
                              model: {
                                value: _vm.editPosition.inputAA,
                                callback: function($$v) {
                                  _vm.$set(_vm.editPosition, "inputAA", $$v)
                                },
                                expression: "editPosition.inputAA"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                _c("InputField", {
                  attrs: { label: "Bemerkung" },
                  model: {
                    value: _vm.editPosition.bemerkung,
                    callback: function($$v) {
                      _vm.$set(_vm.editPosition, "bemerkung", $$v)
                    },
                    expression: "editPosition.bemerkung"
                  }
                }),
                _c("div", [
                  _vm._v(
                    _vm._s(_vm.isVerkauf ? "Verkauf von" : "Umschichtung von") +
                      ": "
                  ),
                  _c("span", { staticClass: "font-bold" }, [
                    _vm._v(_vm._s(_vm.editPosition.wertpapiername))
                  ])
                ]),
                _c("div", [
                  _vm._v(
                    "Anteile: " +
                      _vm._s(_vm.formatNumber(_vm.editPosition.anteile, 4))
                  )
                ]),
                _c("div", [
                  _vm._v(
                    "Wert: " +
                      _vm._s(
                        _vm.formatNumber(_vm.editPosition.gesamterWert, 2)
                      ) +
                      " €"
                  )
                ]),
                !_vm.isVerkauf
                  ? _c(
                      "div",
                      [
                        _c("div", { staticClass: "mb-2" }, [
                          _vm._v("Umschichtung zu:")
                        ]),
                        _c("ComboBox", {
                          attrs: {
                            label: "Gesellschaft",
                            values: !_vm.editPosition.lagerstelle
                              ? []
                              : _vm.tauschInGesellschaftValues[
                                  _vm.editPosition.lagerstelle
                                ] || []
                          },
                          model: {
                            value: _vm.editPosition.tauschInGesellschaft,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.editPosition,
                                "tauschInGesellschaft",
                                $$v
                              )
                            },
                            expression: "editPosition.tauschInGesellschaft"
                          }
                        }),
                        _c("ComboBox", {
                          attrs: {
                            label: "Wertpapier",
                            values: !_vm.editPosition.lagerstelle
                              ? []
                              : _vm.tauschInWertpapierValues[
                                  _vm.editPosition.lagerstelle
                                ] || []
                          },
                          model: {
                            value: _vm.editPosition.tauschInISIN,
                            callback: function($$v) {
                              _vm.$set(_vm.editPosition, "tauschInISIN", $$v)
                            },
                            expression: "editPosition.tauschInISIN"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }