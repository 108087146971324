import GDV_TYPES from './types';
import Vue from 'vue';

export default {
    [GDV_TYPES.MUTATIONS.SAVE_FILTERS](state, payload) {
        Vue.set(state, 'savedFilters', payload);
    },
    [GDV_TYPES.MUTATIONS.SAVE_SORTING](state, payload) {
        Vue.set(state, 'savedSorting', payload);
    },
    [GDV_TYPES.MUTATIONS.CACHE_ROWS](state, payload) {
        Vue.set(state, 'cachedRows', payload);
    },
    [GDV_TYPES.MUTATIONS.SAVE_LAST_ROW](state, payload) {
        Vue.set(state, 'lastRow', payload);
    },
    [GDV_TYPES.MUTATIONS.SAVE_CURRENT_PAGE_SIZE](state, payload) {
        Vue.set(state, 'currentPageSize', payload);
    },    
}
