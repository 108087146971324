import BERATUNGSMAPPE_TYPES from './types';
import { getInitialState } from './index'

export default {
  [BERATUNGSMAPPE_TYPES.MUTATIONS.RETRIEVE_BERATUNG_VERSICHERUNG_LIST_SUCCESS](state, payload) {
    state.beratungVersicherungenList = payload;
  }, 
  [BERATUNGSMAPPE_TYPES.MUTATIONS.RESET_DATA](state) {
    Object.assign(state, getInitialState())
  },  
  [BERATUNGSMAPPE_TYPES.MUTATIONS.EDIT_BERATUNG_VERSICHERUNG_SUCCESS](state, payload) {
    state.beratungVersicherungenCards = payload;
  }, 
  [BERATUNGSMAPPE_TYPES.MUTATIONS.SAVE_BERATUNG_VERSICHERUNG_SUCCESS](state, payload) {
    state.beratungVersicherungenCards = payload;
  }, 
  [BERATUNGSMAPPE_TYPES.MUTATIONS.RETRIEVE_BERATUNG_VERSICHERUNG_ACTIONS_SUCCESS](state, payload) {
    state.beratungVersicherungActions = payload;
  },   
  [BERATUNGSMAPPE_TYPES.MUTATIONS.EXECUTE_BERATUNG_VERSICHERUNG_ACTION_SUCCESS](state, payload) {
    state.executeActionResult = payload;
  }, 
  [BERATUNGSMAPPE_TYPES.MUTATIONS.DELETE_ANTRAG_SUCCESS](state, payload) {

    const tableData = state.beratungVersicherungenCards.antraege.antraegeList;

    tableData.rows = tableData.rows.filter(antrag => antrag.Id !== payload.antragId);
    state.executeActionResult = payload;
  },
  [BERATUNGSMAPPE_TYPES.MUTATIONS.SAVE_ANTRAGS_POSITION_SUCCESS](state, payload) {
    state.lastSavedAntragsPosition = payload;
  },  
  [BERATUNGSMAPPE_TYPES.MUTATIONS.DELETE_POSITION_SUCCESS](state, payload) {
    state.lastDeletedAntragsPosition = payload;
  },  
  [BERATUNGSMAPPE_TYPES.MUTATIONS.RETRIEVE_INSURANCES_CUSTOMER_SUCCESS](state, payload) {
    state.insurancesCustomer = payload;
  },  
  [BERATUNGSMAPPE_TYPES.MUTATIONS.FINANZPORTAL24_ONLINE_INSURANCELINK](state, payload) {
    state.finanzportal24OnlineInsuranceLink = payload;
  },  
  [BERATUNGSMAPPE_TYPES.MUTATIONS.FINANZPORTAL24_ONLINE_FILELINK](state, payload) {
    state.finanzportal24OnlineFileLink = payload;
  },  
  [BERATUNGSMAPPE_TYPES.MUTATIONS.FINANZPORTAL24_ONLINE_FILELINK](state, payload) {
    state.finanzportal24OnlineFileLink = payload;
  },  
  [BERATUNGSMAPPE_TYPES.MUTATIONS.FINANZPORTAL24_OFFLINE_INSURANCEFPXFILE](state, payload) {
    state.finanzportal24OfflineInsuranceFPXFile = payload;
  },    
  [BERATUNGSMAPPE_TYPES.MUTATIONS.DO_DOWNLOAD_DOKUMENT_SMART_MSC_SUCCESS](state, payload) {
    state.dokumentSmartMSC = payload;
  },    
  [BERATUNGSMAPPE_TYPES.MUTATIONS.SAVE_INSURANCE_CUSTOMER](state, payload) {
    state.insurancesCustomer = payload;
  },

}