var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "data-name": "Layer 1",
        width: "722",
        height: "568.2821",
        viewBox: "0 0 722 568.2821",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M682.38177,670.59287l-19.26887,17.7393,15.31342-25.6947c-12.06472-21.87846-31.79644-40.7868-31.79644-40.7868s-40.93644,39.22035-40.93644,70.05011,18.32776,41.59434,40.93644,41.59434c22.60831,0,40.93608-10.76459,40.93608-41.59434C687.566,685.0387,685.535,677.76131,682.38177,670.59287Z",
          transform: "translate(-239 -165.85895)",
          fill: "#f2f2f2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M658.15748,689.29252v1.51962c-.09027,17.664-3.06935,31.44618-8.91474,41.15083-.08274.14294-.173.27834-.2558.42128l-.6545-.39869-.62437-.39121c6.48482-10.47954,8.77933-25.29233,8.86207-40.69943.00753-.49654.01506-1.00057.00753-1.5046-.02259-6.52247-.42129-13.12011-1.06072-19.52973-.04514-.49654-.09781-1.00057-.15047-1.5046-.88771-8.45585-2.16663-16.53554-3.47563-23.55452-.09027-.49654-.18807-.993-.28588-1.482-2.26443-11.87127-4.574-20.47006-5.10061-22.3734-.06015-.23321-.09781-.36109-.10534-.39117l.7147-.20313.00753-.00753.72222-.20314c.00753.03012.13541.45894.346,1.25638.805,2.97907,2.89639,11.0663,4.95015,21.73391.09027.48149.18808.978.27835,1.47453,1.06825,5.69492,2.114,12.05939,2.94152,18.73978q.31593,2.51649.56422,4.96521c.06015.504.11282,1.008.158,1.5046Q658.11993,680.20851,658.15748,689.29252Z",
          transform: "translate(-239 -165.85895)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M653.13965,643.13134c-.504.06773-1.01559.13546-1.53468.18808a41.50155,41.50155,0,0,1-4.243.2182,40.37112,40.37112,0,0,1-17.45337-3.942c-.316.39869-.63191.79744-.95539,1.20366a41.86607,41.86607,0,0,0,18.40876,4.243,42.94076,42.94076,0,0,0,4.52886-.24075c.51156-.05266,1.02312-.12039,1.52715-.1956a41.51385,41.51385,0,0,0,11.9992-3.63362q-.48523-.62064-.94791-1.21873A40.23261,40.23261,0,0,1,653.13965,643.13134Z",
          transform: "translate(-239 -165.85895)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M656.92374,668.31086q-.77864.0451-1.55726.04513c-.158.00753-.32349.00753-.48149.00753a40.5853,40.5853,0,0,1-33.31187-17.40071c-.3009.44384-.60183.88771-.8952,1.33908A42.09237,42.09237,0,0,0,654.885,669.86812c.21067,0,.42129,0,.632-.00753.52657-.00753,1.04566-.02259,1.56475-.04513a41.86987,41.86987,0,0,0,22.30572-7.82394c-.24075-.44384-.48149-.88771-.72976-1.33154A40.30668,40.30668,0,0,1,656.92374,668.31086Z",
          transform: "translate(-239 -165.85895)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M658.15748,689.29252c-.52657.04514-1.05319.07521-1.57981.0978-.56423.02255-1.12845.03761-1.69268.03761a40.69511,40.69511,0,0,1-39.01428-29.27956c-.33854.60941-.67708,1.21124-1.00057,1.8206A42.18536,42.18536,0,0,0,654.885,690.93253c.56423,0,1.12845-.00753,1.68515-.03761.53415-.01506,1.06077-.04513,1.58734-.08278a42.062,42.062,0,0,0,27.7599-13.54894c-.158-.55669-.33855-1.10586-.51909-1.66255A40.56382,40.56382,0,0,1,658.15748,689.29252Z",
          transform: "translate(-239 -165.85895)",
          fill: _vm.secondColor
        }
      }),
      _c("circle", {
        attrs: { cx: "98.7538", cy: "101.77816", r: "98.7538", fill: "#e4e4e4" }
      }),
      _c("circle", {
        attrs: {
          cx: "98.7538",
          cy: "101.77816",
          r: "82.29483",
          fill: _vm.color
        }
      }),
      _c("circle", {
        attrs: { cx: "361", cy: "101.77816", r: "98.75379", fill: "#e4e4e4" }
      }),
      _c("circle", {
        attrs: {
          cx: "361",
          cy: "101.77816",
          r: "82.29483",
          fill: _vm.secondColor
        }
      }),
      _c("circle", {
        attrs: {
          cx: "623.24622",
          cy: "101.77816",
          r: "98.75379",
          fill: "#e4e4e4"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "623.24622",
          cy: "101.77816",
          r: "82.29482",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M330.76087,297.39691q-.22063,0-.44189-.01074a9.03472,9.03472,0,0,1-6.69885-3.48047l-12.62366-16.23047a9.05414,9.05414,0,0,1,1.58594-12.69092l.4541-.35351a9.05549,9.05549,0,0,1,12.69177,1.58593,8.044,8.044,0,0,0,12.18909.59424l25.63244-27.05761a9.04362,9.04362,0,0,1,12.78565-.34522l.41846.39649a9.04347,9.04347,0,0,1,.34509,12.78466l-39.77478,41.9834A9.03339,9.03339,0,0,1,330.76087,297.39691Z",
          transform: "translate(-239 -165.85895)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M556.65748,247.64105h-65V191.1115a13.2249,13.2249,0,0,1,13.437-12.97045h21.6062c16.51831,0,29.95679,12.97186,29.95679,28.91639Z",
          transform: "translate(-239 -165.85895)",
          fill: "#2f2e41"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "341.722 554.819 329.383 554.818 323.512 507.223 341.724 507.224 341.722 554.819",
          fill: "#ffb6b6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M583.86909,732.63911l-39.78794-.00147v-.50325a15.48742,15.48742,0,0,1,15.48658-15.48634h.001l24.30112.001Z",
          transform: "translate(-239 -165.85895)",
          fill: "#2f2e41"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "227.987 554.819 215.647 554.818 209.777 507.223 227.989 507.224 227.987 554.819",
          fill: "#ffb6b6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M470.13393,732.63911l-39.78794-.00147v-.50325a15.48742,15.48742,0,0,1,15.48658-15.48634h.001l24.30112.001Z",
          transform: "translate(-239 -165.85895)",
          fill: "#2f2e41"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "242.886 172.448 234.834 223.78 311.328 224.786 301.263 175.468 242.886 172.448",
          fill: "#ffb6b6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M476.05381,377.10523l-24.36316,64.872-3.01952,268.84008H469.3045l49.31879-215.99853L563.91605,705.1785h20.13012L569.95509,468.64963s4.15532-47.47408-17.1106-75.48793l-5.17631-14.95477Z",
          transform: "translate(-239 -165.85895)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M580.55786,427.67626a15.10008,15.10008,0,0,1-9.67476-21.036L538.34119,300.54574,562.277,295.801l29.76276,103.99478a15.18192,15.18192,0,0,1-11.48193,27.8805Z",
          transform: "translate(-239 -165.85895)",
          fill: "#ffb6b6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M537.49224,366.65567l-52.72953-1.01784a6.8935,6.8935,0,0,1-6.53416-5.193c-2.82676-11.1919-9.04594-40.35947-5.74712-69.41031a42.36529,42.36529,0,0,1,48.15276-37.06012A42.38438,42.38438,0,0,1,555.622,306.69585c-6.63746,25.206-10.38656,43.23868-11.14321,53.59834A6.8785,6.8785,0,0,1,538.969,366.526h0a6.85738,6.85738,0,0,1-1.33013.13126C537.58988,366.65728,537.54119,366.65674,537.49224,366.65567Z",
          transform: "translate(-239 -165.85895)",
          fill: "#e4e4e4"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M377.32224,273.46385a14.79514,14.79514,0,0,1,.77821,2.196L444.10291,298.088l12.75361-11.1416,19.33032,17.04976L455.5259,325.91432a11.24491,11.24491,0,0,1-13.29777,2.30092l-70.62668-36.076a14.75488,14.75488,0,1,1,5.72079-18.67543Z",
          transform: "translate(-239 -165.85895)",
          fill: "#ffb6b6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M444.23968,294.51124a6.31855,6.31855,0,0,1,2.22207-4.77525L470.629,269.15007a17.56656,17.56656,0,0,1,26.52854,23.034l-17.21,26.79926a6.3253,6.3253,0,0,1-9.483,1.34577l-24.06073-21.01521A6.3182,6.3182,0,0,1,444.23968,294.51124Z",
          transform: "translate(-239 -165.85895)",
          fill: "#e4e4e4"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M536.88307,327.41989a6.31848,6.31848,0,0,1-3.21567-4.17133l-7.14526-30.93191a17.56655,17.56655,0,0,1,32.66228-12.94224l15.86431,27.61717a6.32532,6.32532,0,0,1-3.172,9.03753l-29.734,11.68121A6.31818,6.31818,0,0,1,536.88307,327.41989Z",
          transform: "translate(-239 -165.85895)",
          fill: "#e4e4e4"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "548.66423",
          cy: "177.77072",
          rx: "11.25352",
          ry: "15.00469",
          transform: "translate(-69.34548 446.63755) rotate(-66.86956)",
          fill: "#2f2e41"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "511.81881",
          cy: "211.38779",
          r: "30.77462",
          transform: "translate(-158.16328 393.23234) rotate(-61.33681)",
          fill: "#ffb6b6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M528.70082,176.14105H493.5a10.22757,10.22757,0,0,0-10.3209,9.33429,10.00082,10.00082,0,0,0,9.97841,10.66571h0a10,10,0,0,1,10,10v41h55V205.59772A29.45667,29.45667,0,0,0,528.70082,176.14105Z",
          transform: "translate(-239 -165.85895)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d: "M702.22441,734.141h-381a1,1,0,0,1,0-2h381a1,1,0,0,1,0,2Z",
          transform: "translate(-239 -165.85895)",
          fill: "#cacaca"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }