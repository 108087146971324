var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _c("p", [
        _vm._v(
          ' Die Definition der "Angaben über die Risikoneigung" im Anlegerprofil können hier, auch für Ihre gesamte Vermittlerstruktur verändert werden. Bei diesen "Angaben über die Risikoneigung" hat der Kunde die Auswahl zwischen fünf verschiedenen Risikostufen (Stufe 1 = niedrigstes Risiko; Stufe 5 = höchstes Risiko). Verändert werden können die Bezeichnung der Risikostufe und die Beschreibung (Vorsicht: die vorgegebene Bezeichnungen und Beschreibungen sind juristisch geprüft. Eine Veränderung kann zu höherer Haftung führen!) sowie die Einschränkungen in der Depotzusammensetzung je nach Risikostufe. Diese wird dann auch durch das System sowohl in der Beratungsmappe als auch laufend überprüft. '
        )
      ]),
      _vm.isChangeAllowed
        ? [
            _c("InputToggleSwitch", {
              attrs: {
                label:
                  "Eigene Einstellungen treffen. Diese gelten als Vorgabe für Ihre Unterstruktur.",
                inLineLabel: ""
              },
              on: {
                input: function($event) {
                  _vm.dataChanged()
                  _vm.copyVorgabeIntoEinstellung($event)
                }
              },
              model: {
                value: _vm.form.hasConfig,
                callback: function($$v) {
                  _vm.$set(_vm.form, "hasConfig", $$v)
                },
                expression: "form.hasConfig"
              }
            }),
            _c("InputRadioBoxGroup", {
              attrs: {
                values: _vm.unterstrukturAenderungValues,
                disabled: !_vm.hasConfig
              },
              on: {
                input: function($event) {
                  return _vm.dataChanged()
                }
              },
              model: {
                value: _vm.form.unterstrukturDarfAendern,
                callback: function($$v) {
                  _vm.$set(_vm.form, "unterstrukturDarfAendern", $$v)
                },
                expression: "form.unterstrukturDarfAendern"
              }
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }