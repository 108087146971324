var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isStoppuhrRunning
    ? _c(
        "DraggableParentElement",
        {
          style: _vm.dragging ? "height: none;" : "height: auto;",
          on: {
            dragFinished: function($event) {
              return _vm.setDragging()
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "d-flex draggable_div",
              style: _vm.isStoppuhrRunning ? "display: none" : "display: block"
            },
            [_vm._v(" Stoppuhr  "), _c("StoppuhrWatch")],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }