<template>
    <Mustervorlage/>
</template>
<script>
import Mustervorlage from '@/views/intern/Mustervorlage.vue'
export default {
   components:{
    Mustervorlage
   },
    beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: "zurück",
      to,
      from,
    });
    next();
  },
}
</script>