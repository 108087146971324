var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.$appNavigation.currentMenu.parent.label,
          subtitle: _vm.$appNavigation.currentMenu.label,
          defaultMenu: _vm.$appNavigation.currentTabMenu,
          actions: _vm.actions
        },
        on: {
          "action-REMOVE_ALL": function($event) {
            return _vm.confirmRemoveAll()
          },
          "action-RESET": function($event) {
            return _vm.doConfirm("zurücksetzen")
          }
        }
      }),
      _c("div", { staticClass: "box__container" }, [
        _c("div", { staticClass: "box__title" }, [
          _vm._v("Limits " + _vm._s(_vm.title))
        ]),
        _c("div", [
          _vm._v(
            " Bei ausgefülltem Anlegerprofil wird das " +
              _vm._s(_vm.title) +
              "-limit automatisch innerhalb ihrer Grenzen überwacht. Hier haben Sie einen Überblick über alle gesetzten Limits auf die " +
              _vm._s(_vm.title) +
              " und können diese noch weiter einengen oder erweitern. "
          )
        ]),
        _c(
          "div",
          {
            staticClass:
              "layout__negative-margin--8 antrag-action-button__container"
          },
          [
            !_vm.isCustomerView
              ? _c("InputToggleSwitch", {
                  attrs: {
                    label: "Limits der Untermakler anzeigen",
                    inLineLabel: "",
                    suppressValidationMessage: ""
                  },
                  on: {
                    input: function($event) {
                      return _vm.reload()
                    }
                  },
                  model: {
                    value: _vm.includeStruktur,
                    callback: function($$v) {
                      _vm.includeStruktur = $$v
                    },
                    expression: "includeStruktur"
                  }
                })
              : _vm._e()
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          !_vm.loading && _vm.rows && _vm.rows.length
            ? _c(
                "div",
                [
                  _c("Table", {
                    attrs: {
                      tableId: "9a41e68c-9568-484a-beb7-163a77861d75",
                      title: _vm.TABLE_ID,
                      headers: _vm.headers,
                      rows: _vm.rows,
                      rowsPerPage: 20,
                      rowId: "id",
                      hidePagination: "",
                      mobileConfig: {
                        title: "kundenLinkName",
                        headers: [
                          "mittelwert",
                          "abweichungLow",
                          "abweichungHigh"
                        ]
                      }
                    },
                    on: {
                      "action-edit": _vm.editLimit,
                      "action-delete": _vm.removeLimit,
                      "click-kundenLinkName": _vm.openCustomerNewTab
                    }
                  })
                ],
                1
              )
            : _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", title: _vm.TABLE_ID }
              })
            : _c("NoData", { attrs: { title: _vm.TABLE_ID } })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "confirmDelete",
          attrs: { labelButtonConfirm: "Ja", labelButtonCancel: "Nein" },
          on: {
            onConfirmButton: function($event) {
              return _vm.removeAllLimits(true)
            },
            onCancelButton: function($event) {
              return _vm.removeAllLimits(false)
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-warning", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v(" Löschen ")
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", [
            _vm._v(" Wollen Sie wirklich alle überschrittenen Limits löschen? ")
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }