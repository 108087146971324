<template>
  <div>
    <FlexibleList :rows="rows" showThirdRow>
      
      <template #title="row">
        {{row.WERTPAPIER_NAME || row.LAGERSTELLE}}
      </template>

      <template #value="row">
        {{ row.Euro || row.PREIS | currency }}
      </template>

      <template #thirdRow="row">
        {{ row.DEPOTNUMMER || row.ISIN }}
      </template>      
    
    </FlexibleList>
  </div>
</template>
<script>
import {
  TextColumn,
  CurrencyColumn,
  PercentageColumn,
} from "@/components/table2/table_util.js";
import { mapGetters } from "vuex";
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";
import CORE_TYPES from "@/store/core/types";
import FlexibleList from "@/components/flexibleList/FlexibleList.vue";

export default {
  //   mixins: [InsuranceDetailMixin],
  components: {
    FlexibleList,
  },
  data: function () {
    return {};
  },
  computed: {
    ...mapGetters({
      versicherungenDetails: VERSICHERUNG_TYPES.GETTERS.INSURANCE_DETAILS,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      isFK: CORE_TYPES.GETTERS.IS_FK,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      depotPositions: VERSICHERUNG_TYPES.GETTERS.DEPOT_POSITIONS,
    }),
    rows() {
      const headers = this.depotPositions?.headers || [];
      const records = this.depotPositions?.records || [];
      return records.map((record) => {
        const values = record?.values || [];
        return values.reduce((result, value, index) => {
          result[headers[index].key] = value;
          return result;
        }, {});
      });
    },
    hasDepotPositions() {
      return this.depotPositions?.records?.length;
    },
  },
  methods: {

  },
  filters: {
    currency(value) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €";
    },
  },
  mounted() {},
  validators: {},
};
</script>

<style scoped>
.align-end {
  flex: 1 1 auto;
  align-self: flex-end;
  padding: 0px 0px 16px 5px;
  color: var(--color-text);
}
.form-control {
  display: flex;
  align-content: stretch;
  align-items: center;
}
.container {
  display: flex;
  justify-content: space-between;
}

.overflow {
  overflow: hidden;
}

.topline {
  padding: 4px 4px;
  border-bottom: 1px solid #c4c4c4;
}
.topline:last-child {
  border-bottom: none;
}
.topline-content {
  flex: 1 1 auto;
  max-width: calc(100% - 21px);
}

.topline-side-content {
  display: flex;
  font-size: 0.9rem;
  max-width: 50%;
  min-inline-size: fit-content;
}

.subject {
  max-width: 95%;
  font-size: 0.9rem;
}
</style>