
export const COLOR_WHITE = '#ffffff';
export const COLOR_BLACK = '#000000';
export const REGEX_COLOR_HEX = /^#(?:[0-9a-f]{3}){1,2}$/i;
export const REGEX_COLOR_RGB = /rgb[a]?\(\s*([0-9]*\.?[0-9]+)\s*,\s*([0-9]*\.?[0-9]+)\s*,\s*([0-9]*\.?[0-9]+)\s*(?:,\s*(\d*\.?\d+))?\s*\)/i;

export const CM_RGB_OBJECT = 'CM_RGB_OBJECT';
export const CM_RGB = 'CM_RGB';
export const CM_HSL = 'CM_HSL';
export const CM_HSV = 'CM_HSV';
export const CM_HEX = 'CM_HEX';

const toRGB = {
  [CM_RGB_OBJECT]: value => value,
  [CM_RGB]: stringToRGB,
  [CM_HEX]: hexToRGB,
  [CM_HSL]: hslToRGB,
  [CM_HSV]: hsvToRGB
};

const fromRGB = {
  [CM_RGB_OBJECT]: value => value,
  [CM_RGB]: rgbToString,
  [CM_HEX]: rgbToHex,
  [CM_HSL]: rgbToHSL,
  [CM_HSV]: rgbToHSV
};

export function convertColor(fromModel, toModel, value) {
  if (!toRGB[fromModel] || !fromRGB[toModel]) {
    throw new Error('Invalid color model');
  }

  // Converter to RGB
  const rgbObject = toRGB[fromModel](value);

  // Converter from RGB to model destination
  return fromRGB[toModel](rgbObject);
}

export function getMostContrastingColorWhiteBlackByContrast(color) {
  if (typeof color !== 'string') {
    return color;
  }
  const contrastWhite = contrast(color, COLOR_WHITE);
  const contrastBlack = contrast(color, COLOR_BLACK);

  const advantageToWhite = 0.6; // give some advantage to the white color, because it looks better

  if ((contrastWhite + advantageToWhite) > contrastBlack) {
    return COLOR_WHITE;
  } else {
    return COLOR_BLACK;
  }
}
/**
 * @deprecated as this is not being used. Have a look at @see {getColorsMixedContrastDecreasing}
 * @param {*} baseColor 
 */
export function getColorListBasedOnTriadColors(baseColor) {
  const rgb = stringToRGB(baseColor)
  const resultList = [];
    
  for (let turn = 0; turn < 4; turn++) {
    for (let triads = 0; triads < 3; triads++) {
      const hsv = rgbToHSV(rgb);
      hsv.hue = hueShift(hsv.hue, ((triads * 120) + (turn * 30)));
      const newRgb = hsvToRGB(hsv);
  
      resultList.push(rgbToString(newRgb))
    }
  }
  return resultList;
}

/**
 * @deprecated as this is not being used. Have a look at @see {getColorsMixedContrastDecreasing}
 * @param {*} baseColor 
 */
export function getColorListBasedOnLighting(baseColor) {
  const rgb = stringToRGB(baseColor)
  const resultList = [];
  const hsl = rgbToHSL(rgb);
  let l = hsl.l

  // goes from the darkest variation of this color
  while (l > 10) {
    l -= 10
  }

  // up to the brightest variation of this color
  while (l < 90) {
    hsl.l = l
    l += 10
    const newRgb = hslToRGB(hsl);
    resultList.push(rgbToString(newRgb))
  }
  
  return resultList;
}

function getStylePropertyValueAsList(propertyValue) {
  let generalChartColorList = document.documentElement.style.getPropertyValue(propertyValue);
  if (generalChartColorList) {
    try {
      generalChartColorList = JSON.parse(generalChartColorList);
      if (Array.isArray(generalChartColorList) && generalChartColorList.length) {
        return generalChartColorList;
      }
    } catch (e) {
      // empty block
    }
  }

  return null;
}

/**
 * 
 * @param {*} baseColor 
 * @param {*} options.useChartColorPreset The brokers can set a list of colors to be used instead of the generated one
 */
export function getColorLimitedListBasedOnLightingDecreasing(baseColor, { quantity, fromStepsHigher = 2, level = 90, useChartColorPreset = true }) {
  const gradientChartColorList = getStylePropertyValueAsList('--gradient-chart-color-list');
  if (gradientChartColorList && useChartColorPreset) {
    return gradientChartColorList;
  }

  const rgb = stringToRGB(baseColor)
  const resultList = [];
  const hsl = rgbToHSL(rgb);
  let l = (hsl.l + (fromStepsHigher * 10))

  l = level

  while (l > 10 && quantity > resultList.length) {
    hsl.l = l
    l -= 10
    const newRgb = hslToRGB(hsl);
    resultList.push(rgbToString(newRgb))
  }
  
  return resultList;
}

export function hexToRGB(h) {
  let r = 0, g = 0, b = 0;

  // 3 digits
  if (h.length === 4) {
    r = parseInt('0x' + h[1] + h[1], 16);
    g = parseInt('0x' + h[2] + h[2], 16);
    b = parseInt('0x' + h[3] + h[3], 16);

  // 6 digits
  } else if (h.length === 7) {
    r = parseInt('0x' + h[1] + h[2], 16);
    g = parseInt('0x' + h[3] + h[4], 16);
    b = parseInt('0x' + h[5] + h[6], 16);
  } else {
    return h;
  }

  return {r, g, b};
}

/**
 * It returns an Hex color out of rgb
 * 
 * @param rgb string or object
 * @returns hex color 
 */
export function rgbToHex(rgb) {
  if (!rgb) {
    return rgb;
  }

  if (REGEX_COLOR_HEX.test(rgb)) {
    return rgb;
  }

  if(REGEX_COLOR_RGB.test(rgb)) {
    rgb = reformRgbArray(rgb);
  }

  const componentToHex = (c) => {
    var hex = Math.round(c).toString(16);
    return hex.length == 1 ? '0' + hex : hex;
  }

  if(rgb && typeof rgb === 'object' && 'r' in rgb && 'g' in rgb && 'b' in rgb) {
    return '#' + componentToHex(rgb.r) + componentToHex(rgb.g) + componentToHex(rgb.b)
  }

  return rgb;
}

/**
 * Parses an RGB or RGBA color string and converts it to an object with integer values for RGB.
 * If the `useAlpha` flag is true and an alpha (A) value is present in the RGBA format, 
 * the alpha value is included as a float.
 *
 * @param {string} value - The RGB or RGBA color string in the format `rgb(r, g, b)` or `rgba(r, g, b, a)` 
 *                         where `r`, `g`, and `b` can be either integers or floats, and `a` is a float.
 * @param {boolean} [useAlpha=false] - A flag indicating whether to include the alpha value 
 *                                     if an RGBA string is provided. If set to `true` and the color
 *                                     string is in `rgba` format, the alpha value is included.
 * @returns {Object} An object representing the RGB(A) values. 
 *                   - If RGB and `useAlpha` is false: {r: <int>, g: <int>, b: <int>}
 *                   - If RGBA and `useAlpha` is true: {r: <int>, g: <int>, b: <int>, a: <float>}
 *                   The `r`, `g`, and `b` values are rounded to the nearest integer.
 */
function reformRgbArray(value, useAlpha = false) {
  // Match both integer and floating-point numbers using a regex
  let rgb = value.match(/[0-9]*\.?[0-9]+/g);

  // Convert the matched values to integers
  let result = {
      r: Math.round(parseFloat(rgb[0])), 
      g: Math.round(parseFloat(rgb[1])), 
      b: Math.round(parseFloat(rgb[2]))
  };

  // Handle rgba if present
  if (useAlpha && rgb.length > 3) {
      result.a = parseFloat(rgb[3]); // Keep alpha as float
  }

  return result;
}

export function stringToRGB(color) {
  if (typeof color === "string") {
    if (color.match(REGEX_COLOR_HEX)) {
      color = rgbToString(hexToRGB(color))
    }
    const rgbArrayMatch = color.match(REGEX_COLOR_RGB)
    if (rgbArrayMatch) {
      return {
        r: parseInt(rgbArrayMatch[1]), 
        g: parseInt(rgbArrayMatch[2]), 
        b: parseInt(rgbArrayMatch[3]), 
        a: parseFloat(rgbArrayMatch[4] || "1")
      }
    }
  }
  return {};
}

export function rgbToString(rgb) {
  if (rgb.a < 1) {
    return `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})`;
  } else {
    return `rgb(${rgb.r},${rgb.g},${rgb.b})`;
  }
}

/**
 * Given two colors, it calculates if it's possible to read a text.
 * 
 * @return true for readable 
 * @return false for 'fail' classification
 * @see accessibilityClassification()
 */
export function isColorsReadable(rgb1, rgb2) {
  const result = accessibilityClassification(rgb1, rgb2);
  return ['aaa', 'aa', 'aa-large'].some(item => item === result);
}

/**
 * Based on the contrast between two colors (e.g. background and text color), 
 * returns an accessibility classification.
 * 
 * @return a value from the available options: ['aaa', 'aa', 'aa-large', 'fail']
 * @return 'aaa' for the best contrast
 * @return 'fail' for not readable contrast
 */
export function accessibilityClassification(rgb1, rgb2) {
  const contrastValue = contrast(rgb1, rgb2);

  if (contrastValue >= 7) {
    return 'aaa';
  } else if (contrastValue >= 4.5) {
    return 'aa';
  } else if (contrastValue >= 3) {
    return 'aa-large';
  } else {
    return 'fail';
  }
}

/**
 * References: 
 * https://en.wikipedia.org/wiki/Luma_%28video%29
 * https://www.w3.org/TR/WCAG/#dfn-relative-luminance
 */
export function luma(rgb) {
  const colors = ['r', 'g', 'b'];
  const tempRgb = {}

  colors.forEach(color => {
    const value = rgb[color] / 255;
    tempRgb[color] = value <= 0.03928 ? value / 12.92 : Math.pow((value + 0.055) / 1.055, 2.4)
  });

  return tempRgb.r * 0.2126 + tempRgb.g * 0.7152 + tempRgb.b * 0.0722;
}

/**
 * Returns a value between 1 and 21.
 * 
 * @return 1 for the same colors
 * @return 21 for black and white
 * 
 * References: 
 * https://www.w3.org/TR/WCAG/#dfn-contrast-ratio
 * https://colorable.jxnblk.com/
 */
export function contrast(stringColor1, stringColor2) {
  let rgb1 = stringToRGB(stringColor1)
  let rgb2 = stringToRGB(stringColor2)

  if (rgb1.a < 1) {
    rgb1 = overlayColor(rgb1, rgb2)
  }
  if (rgb2.a < 1) {
    rgb2 = overlayColor(rgb2, rgb1)
  }

  const luma1 = luma(rgb1);
  const luma2 = luma(rgb2);

  let ratio = (luma1 + 0.05) / (luma2 + 0.05);

  if (luma2 > luma1) {
    ratio = 1 / ratio
  }

  return ratio;
}

export function overlayColor(rgb1, rgb2) {
  const colors = ['r', 'g', 'b'];

  colors.forEach(color => {
    rgb1[color] = rgb1[color] * rgb1.a + rgb2[color] * rgb2.a * (1 - rgb1.a)
  });

  rgb1.a = rgb1.a + rgb2.a * (1 - rgb1.a);

  return rgb1;
}


export function rgbToHSV(rgb) {
  if (typeof rgb === 'string') {
    rgb = stringToRGB(rgb)
  }
  const hsv = {};
  const maxColor = getMaxColor(rgb);
  const diff = maxColor - getMinColor(rgb);

  hsv.saturation = (maxColor===0.0) ? 0 : (100*diff/maxColor);

  if (hsv.saturation === 0) {
    hsv.hue = 0;
  } else if (rgb.r === maxColor) {
    hsv.hue = 60.0 * (rgb.g - rgb.b) / diff;
  } else if (rgb.g === maxColor) {
    hsv.hue = 120.0 + 60.0 * (rgb.b - rgb.r) / diff;
  } else if (rgb.b === maxColor) {
    hsv.hue = 240.0 + 60.0 * (rgb.r - rgb.g) / diff;
  }

  if (hsv.hue < 0.0) {
    hsv.hue += 360.0;
  }
  
  hsv.value = Math.round(maxColor * 100 / 255);
  hsv.hue = Math.round(hsv.hue);
  hsv.saturation = Math.round(hsv.saturation);
  
  return hsv;
}

export function rgbToHSL(rgb){
  if (typeof rgb === 'string') {
    rgb = stringToRGB(rgb)
  }
  const r1 = rgb.r / 255;
  const g1 = rgb.g / 255;
  const b1 = rgb.b / 255;

  const maxColor = Math.max(r1,g1,b1);
  const minColor = Math.min(r1,g1,b1);

  let l = (maxColor + minColor) / 2 ;
  let s = 0;
  let h = 0;
  if(maxColor != minColor){
    if(l < 0.5){
      s = (maxColor - minColor) / (maxColor + minColor);
    }else{
      s = (maxColor - minColor) / (2.0 - maxColor - minColor);
    }
    if(r1 == maxColor){
      h = (g1-b1) / (maxColor - minColor);
    }else if(g1 == maxColor){
      h = 2.0 + (b1 - r1) / (maxColor - minColor);
    }else{
      h = 4.0 + (r1 - g1) / (maxColor - minColor);
    }
  }

  l = Math.round(l * 100);
  s = Math.round(s * 100);
  h = Math.round(h * 60);
  if(h < 0){
    h += 360;
  }
  const result = {h, s, l};
  return result;
}

export function hslToRGB(hsl) {
  let {h, s, l} = hsl
  l /= 100;
  const a = s * Math.min(l, 1 - l) / 100;
  const f = n => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return (255 * color)
  };
  const result = {r: f(0), g: f(8), b: f(4)}
  return result;
}

export function getRgbDesaturated(rgb) {
  const currentColor = rgbToHSV(rgb)
  currentColor.saturation = 6;
  currentColor.value = 98;

  return rgbToHex(hsvToRGB(currentColor));
}

export function hsvToRGB(hsv) {
  let { hue, saturation, value } = hsv; 
  const rgb = {};
  if (saturation === 0) {
    rgb.r = rgb.g = rgb.b = Math.round(value * 2.55);
  } else {
    hue /= 60;
    saturation /= 100;
    value /= 100;

    const i = Math.floor(hue);
    const f = hue - i;
    const p = value * (1 - saturation);
    const q = value * (1 - saturation * f);
    const t = value * (1 - saturation * (1 - f));

    switch(i) {
    case 0: 
      rgb.r = value; 
      rgb.g = t; 
      rgb.b = p; 
      break;
    case 1: 
      rgb.r = q; 
      rgb.g = value; 
      rgb.b = p; 
      break;
    case 2: 
      rgb.r = p; 
      rgb.g = value;
      rgb.b = t; 
      break;
    case 3: 
      rgb.r = p; 
      rgb.g = q; 
      rgb.b = value; 
      break;
    case 4: 
      rgb.r = t; 
      rgb.g = p; 
      rgb.b = value; 
      break;
    default: 
      rgb.r = value; 
      rgb.g = p; 
      rgb.b = q;
      break;
    }

    rgb.r = (rgb.r * 255);
    rgb.g = (rgb.g * 255);
    rgb.b = (rgb.b * 255);
  }
  return rgb;
}

export function hueShift(h,s) { 
  h += s; 
  while (h >= 360.0) {
    h -= 360.0;
  }

  while (h < 0.0) {
    h += 360.0;
  }       
    
  return h; 
}

export function getMinColor(rgb) { 
  return (rgb.r < rgb.g) ? 
    ((rgb.r < rgb.b) ? rgb.r : rgb.b) : 
    ((rgb.g < rgb.b) ? rgb.g : rgb.b); 
} 
export function getMaxColor(rgb) { 
  return (rgb.r > rgb.g) ? 
    ((rgb.r > rgb.b) ? rgb.r : rgb.b) : 
    ((rgb.g > rgb.b) ? rgb.g : rgb.b); 
}

/**
 * The brokers can preset a list of colors to be used in chart
 * @param {*} quantity 
 */
export function getColorsMixedContrastDecreasing(quantity = 4) {
  const generalChartColorList = getStylePropertyValueAsList('--general-chart-color-list');
  if (generalChartColorList) {
    return generalChartColorList;
  }

  const colorPrimary = document.documentElement.style.getPropertyValue('--color-primary')
  const colorSecondary =document.documentElement.style.getPropertyValue('--color-secondary')
  const colorSuccess = document.documentElement.style.getPropertyValue('--color-success')
  const colorDanger = document.documentElement.style.getPropertyValue('--color-danger')
  const colorWarning = document.documentElement.style.getPropertyValue('--color-warning')

  const primaryVariations = getColorLimitedListBasedOnLightingDecreasing(colorPrimary, { quantity, fromStepsHigher: 3 }).reverse()
  const secondaryVariations = getColorLimitedListBasedOnLightingDecreasing(colorSecondary, { quantity, fromStepsHigher: 3 }).reverse()
  const successVariations = getColorLimitedListBasedOnLightingDecreasing(colorSuccess, { quantity, fromStepsHigher: 3 }).reverse()
  const dangerVariations = getColorLimitedListBasedOnLightingDecreasing(colorDanger, { quantity, fromStepsHigher: 3 }).reverse()
  const warningVariations = getColorLimitedListBasedOnLightingDecreasing(colorWarning, { quantity, fromStepsHigher: 3 }).reverse()

  const result = [];
  const options = [primaryVariations, secondaryVariations, successVariations, dangerVariations, warningVariations]
  for (let index = 0; index < quantity; index++) {
    options.forEach(colors => {
      result.push(colors[index])
    })
  }

  //deduplicate
  const unique = new Set(result);

  return [...unique];
}
