<template>
    <div class="box__container-wrap">
        <div class="box__container-rows">
            <GhostLoading v-if="loading" type="table" :config="{ table: { rows: rowsPerPage, }, }" />
            <Table v-else-if="rows.length"
                rowId="dokId"
                hidePagination
                :headers="headers"
                :rows="rows"
                :rowsPerPage="rowsPerPage"
                :mobileConfig="mobileConfig"
                @click-kundenLinkName="openCustomerNewTab"
                @action-REVERT="openRevertModal"
            >
                <template v-slot:name="row">
                    <DownloadLink v-if="canGenerateSignoViewerLink(row)"
                        :title="row.name || 'Dokument'"
                        downloadServicePath="/get_signo_document_preview"
                        :filename="row.name"
                        :queryParams="{
                            nodeId: encodeURIComponent(row.nodeId), 
                            viewFileId: encodeURIComponent(row.viewFileId),
                        }"
                    />
                    <span v-else>
                        {{row.name || 'Dokument'}}
                    </span>
                </template>
                <template v-slot:status="row">
                    <span class="text-smaller" :class="getStatusClass(row)">{{ row.status }}</span>
                </template>
            </Table>
            <NoData v-else noIcon />
        </div>
        
        <div v-if="!loading && !$isSmallScreen" class="bottom-row text-right mt-3">
            <router-link to="/communication/offene-unterschriften">
                <div v-if="rows.length">weitere...</div>
                <div v-else>Übersicht anzeigen</div>
            </router-link>
        </div>

        <RemoveVorlageModal :selectedElement="selectedElement" :isBroker="true" ref="removeVorlageModal"
            @error="errorStr = $event; $refs.fehlerVorlage.open()"/>
        
        <BaseModal ref="fehlerVorlage" :showConfirmButton="false" labelButtonCancel="Ok">
            <template v-slot:modalTitle>
                <PhWarning :size="16" class="mr-2" style="color: orangered"/>
                {{errorStr}}
            </template>
        </BaseModal>
    </div>
</template>

<script>
import Table from "@/components/table2/Table.vue";
import {TextColumn, PillColumn, SlotColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import DownloadLink from '@/components/core/download/DownloadLink.vue'
import OPEN_SIGNS_TYPES from '@/store/openSigns/types';
import CORE_TYPES from '@/store/core/types';
import NoData from '@/components/core/NoData.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import { mapGetters } from 'vuex';
import { BROKER_LEVEL_ROLES, EMPLOYEE_ROLES } from '@/router/roles';
import { PhNotePencil, PhWarning } from 'phosphor-vue'
import RemoveVorlageModal from '@/components/openSigns/RemoveVorlageModal.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import { getStatusStr, getStatusClass } from '@/components/communication/OffeneUnterschriften.vue';

const ROWS_PER_PAGE = 5;

export default {
    components: {
        Table,
        DownloadLink,
        NoData,
        GhostLoading,
        RemoveVorlageModal,
        BaseModal,
        PhWarning,
    },
    data() {
        return {
            loading: false,
            rowsPerPage: ROWS_PER_PAGE,
            selectedElement: null,
            errorStr: null,
        }
    },
    mounted() {
        this.$store.dispatch(OPEN_SIGNS_TYPES.ACTIONS.GET_OPEN_SIGNS, { rowsPerPage: ROWS_PER_PAGE, page: 0 });
    },
    computed: {
        ...mapGetters({
            openSigns: OPEN_SIGNS_TYPES.GETTERS.OPEN_SIGNS,
            hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
        }),
        zugriffKunden() {
            return this.hasRoles([[EMPLOYEE_ROLES.ZUGRIFF_KUNDEN, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN]])
        },
        headers() {
            const headers = {
                lockedLeft: [
                    TextColumn("kundenLinkName", "Name").makeAlwaysVisible(),
                ],
                center: [
                    SlotColumn("name", "Bezeichnung", 200, 1),
                ],
                lockedRight: [
                    SlotColumn("status", "e-Signatur", 200).makeAlwaysVisible(),
                    ActionColumn("actions"),
                ]
            };
            if (this.zugriffKunden)
                headers.lockedLeft[0].makeLink();
            return headers;
        },
        rows() {
            const actions = [
                SimpleAction("REVERT", PhNotePencil, "Vorlage des Dokuments zurückziehen"),
            ];
            return (this.openSigns?.offeneUnterschriften || []).map(row => ({
                ...row,
                status: getStatusStr(row),
                kundenLinkName: `${row.lastName}, ${row.firstName}`,
                actions,
            })).slice(0, ROWS_PER_PAGE);
        },
        mobileConfig() {
          return {
              title: '',
              headers: ["kundenLinkName","name","status"],
          }
        }
    },
    methods: {
        openRevertModal(row) {
            this.selectedElement = row;
            this.$refs.removeVorlageModal.openVorlageModal();
        },
        openCustomerNewTab(sign) {
            if (sign?.kundennr) {
                this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { customerId: sign.kundennr });
            }
        },
        canGenerateSignoViewerLink(element) {
          return !!(element.nodeId && element.viewFileId)
        },
        getStatusClass(elem, hasColor = true) {
            return getStatusClass(elem, hasColor);
        }
    }

}
</script>

<style>

</style>