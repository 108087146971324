var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _c("div", { staticClass: "box__title" }, [
        _vm._v(" Zusätzliche Einstellungen für Ordern bei der DAB ")
      ]),
      _c("ComboBox", {
        attrs: {
          label: "Ansprechpartner",
          disabled: _vm.readOnly,
          values: _vm.comboboxValues
            ? _vm.comboboxValues.INPUT_DAB_ANSPRECHPARTNER_COMBO
            : []
        },
        on: {
          change: function($event) {
            return _vm.updateStoreData({
              componentId: "INPUT_DAB_ANSPRECHPARTNER_COMBO",
              value: $event
            })
          }
        },
        model: {
          value: _vm.form.INPUT_DAB_ANSPRECHPARTNER_COMBO,
          callback: function($$v) {
            _vm.$set(_vm.form, "INPUT_DAB_ANSPRECHPARTNER_COMBO", $$v)
          },
          expression: "form.INPUT_DAB_ANSPRECHPARTNER_COMBO"
        }
      }),
      _vm.form.INPUT_DAB_ANSPRECHPARTNER_COMBO == "freie Eingabe:"
        ? _c("InputField", {
            attrs: { disabled: _vm.readOnly },
            on: {
              change: function($event) {
                return _vm.updateStoreData({
                  componentId: "INPUT_DAB_ANSPRECHPARTNER_COMBO",
                  value: $event
                })
              }
            },
            model: {
              value: _vm.form.INPUT_DAB_ANSPRECHPARTNER_TEXT,
              callback: function($$v) {
                _vm.$set(_vm.form, "INPUT_DAB_ANSPRECHPARTNER_TEXT", $$v)
              },
              expression: "form.INPUT_DAB_ANSPRECHPARTNER_TEXT"
            }
          })
        : _vm._e(),
      _c("InputField", {
        attrs: {
          label: "Telefon aus Personenauswahl, sonst:",
          disabled: _vm.readOnly,
          inputmode: "tel"
        },
        on: {
          change: function($event) {
            return _vm.updateStoreData({
              componentId: "INPUT_DAB_ANSPRECHPARTNER_TELEFON",
              value: $event
            })
          }
        },
        model: {
          value: _vm.form.INPUT_DAB_ANSPRECHPARTNER_TELEFON,
          callback: function($$v) {
            _vm.$set(_vm.form, "INPUT_DAB_ANSPRECHPARTNER_TELEFON", $$v)
          },
          expression: "form.INPUT_DAB_ANSPRECHPARTNER_TELEFON"
        }
      }),
      _vm._m(0)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 mt-3" }, [
      _c("div", { staticClass: "box__title" }, [
        _vm._v("Hinweis für Sparpläne")
      ]),
      _c("p", [
        _vm._v(
          " Bei Ihrem Sparplan muss das DAB Konto 1 Tag vor Ausführungstermin Deckung aufweisen. Das erstmalige Ausführungsdatum bei Sparplänen mit Lastschrift muss mindestens 3 Arbeitstage in der Zukunft liegen. Der Einzug Ihrer Lastschrift kann nur von einem Konto im europäischen Zahlungsverkehrsraum (SEPA) in Euro ausgeführt werden. Mit der Bestätigung dieses Auftrages versichern Sie, dass Sie nur Ihr eigenes Konto belasten. Der Einzug Ihrer Lastschrift erfolgt taggleich bei Ausführung des Sparplanes von Ihrem angegebenen Konto. Für Lastschriftbeträge > EUR 1.000,00 setzen Sie sich bitte mit unserem Kundenservice unter 089/8895-6000 in Verbindung. Ihre Neuanlage, Änderung oder Löschung von Sparplänen ist bis 3 Arbeitstage vor der nächsten Ausführung möglich. Bitte beachten Sie, dass Sie den Sparplan nur zusammen mit der Lastschrift ändern oder streichen können. Aufträge für Ihren Auszahlplan müssen mind. 2 Tage vor Ausführungstermin bei uns vorliegen. Ich bin damit einverstanden, dass die DAB Spar- und Auszahlpläne gemäß den Ausführungsgrundsätzen auch außerbörslich ausführen kann. "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }