<template>
<BaseModal 
    ref="modal"
    modalTitle="Multiinterface verarbeiten"
    :autoClose="false"
    :confirmDisabled="!form.newItabSid"
    :showConfirmButton="interfaceEditEnable"
    @onConfirmButton="save"
    @close="close()"
>
    <div>
        <InputField :value="interfaceId" disabled label="ID"/>
        <InputField :value="form.lfdno" disabled label="Lfd. Nr."/>
        <ComboBox v-if="interfaceOptions" v-model="form.newItabSid" :values="interfaceOptions" :disabled="!interfaceEditEnable" label="Neues Interface"/>
        <InputTextArea v-model="form.checkStatement" :disabled="!interfaceEditEnable" label="Check Statement" autoGrow/>
        <InputTextArea v-model="form.description" :disabled="!interfaceEditEnable" label="Beschreibung" autoGrow/>
    </div>
</BaseModal>
</template>
<script>
import CORE_TYPES from '@/store/core/types'

import BaseModal from '@/components/core/BaseModal.vue';
import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import BaseButton from '@/components/core/BaseButton.vue';

import { mapGetters } from 'vuex';
import axios from 'axios';

const config = {
    defaultSpinner: true,
};

export default {
    components: {
        BaseModal,
        InputField,
        ComboBox,
        InputTextArea,
        BaseButton,
    },
    props: {
        interfaceId: {
            type: Number,
            required: true,
        },
        multi: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            form: {...this.multi},
            interfaceOptions: null,
        };
    },
    computed: {
        ...mapGetters({
            isIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
        }),
        interfaceEditEnable() {
            return this.isIntern;
        },
    },
    mounted() {
        this.$refs.modal.open();
        axios.get(`${process.env.VUE_APP_API}/gdv_interface/interface_options`, config)
        .then(response => {
            this.interfaceOptions = response.data;
        })
    },
    methods: {
        save() {
            let url = `${process.env.VUE_APP_API}/gdv_interface/save_multi?interface=${this.interfaceId}`;
            if (this.oldName)
                url += `&name=${this.oldName}`;
            axios.post(url, this.form, config)
            .then(_ => {
                this.$refs.modal.close();
                this.$emit("close", true);
            })
        },
        close() {
            this.$emit("close", false);
        },
    }
}
</script>
