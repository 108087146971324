<template>
  <div>
    <div class="box__container">
      <div class="box__title">Personendaten öffentliche Person</div>

      <div class="row">
        <div class="col-12">
          <InputField 
            label="Name"
            v-model="fullName"
            :isComponentHalfSize="true"
            :disabled="true" />
        </div>
      </div>
    </div>

    <div class="box__container">
      <div class="box__title">PEP – Politisch exponierte Person</div>
      <div class="row">
        <div class="col-12">
          <InputToggleSwitch 
            label="PEP – Politisch exponierte Person" 
            :inLineLabel="true"
            v-model="pep.pep_aktiv" 
            :disabled="!isEditable" 
            @input="addCustomerDataEdited('pep')" />
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <InputField 
            label="Folgende Funktion" 
            v-model="pep.pep_funktion" 
            :isComponentHalfSize="true"
            :disabled="!isEditable" 
            @input="updateFieldState('pep_aktiv', $event)"
            @change="addCustomerDataEdited('pep')" />
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <InputToggleSwitch 
            label="Ist der Depotinhaber ein Ehepartner/Lebensgefährte oder verwandt mit einer Person, die diese Funktion ausübt?" 
            :inLineLabel="true"
            v-model="pep.pep_ehe_aktiv" 
            :disabled="!isEditable" 
            @input="addCustomerDataEdited('pep')" />
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <InputTextArea 
            label="Detailierte Angaben" 
            v-model="pep.pep_ehe_text" 
            :isComponentHalfSize="true"
            :disabled="!isEditable" 
            @input="updateFieldState('pep_ehe_aktiv', $event);"
            @change="addCustomerDataEdited('pep')" />
        </div>
      </div>
    </div>

    <div class="box__container">
      <div class="box__title">VIP – Very important Person</div>
      <div class="row">
        <div class="col-12">
          <InputToggleSwitch 
            label="VIP – Very important Person" 
            :inLineLabel="true"
            v-model="pep.vip_aktiv" 
            :disabled="!isEditable" 
            @input="addCustomerDataEdited('pep')" />
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <InputField 
            label="Folgende Funktion" 
            v-model="pep.vip_funktion" 
            :isComponentHalfSize="true"
            :disabled="!isEditable" 
            @input="updateFieldState('vip_aktiv', $event)"
            @change="addCustomerDataEdited('pep')" />
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <InputToggleSwitch 
            label="Ist der Depotinhaber ein Ehepartner/Lebensgefährte oder verwandt mit einer Person, die diese Funktion ausübt?" 
            :inLineLabel="true"
            v-model="pep.vip_ehe_aktiv" 
            :disabled="!isEditable" 
            @input="addCustomerDataEdited('pep')" />
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <InputTextArea 
            label="Detailierte Angaben" 
            v-model="pep.vip_ehe_text" 
            :isComponentHalfSize="true"
            :disabled="!isEditable" 
            @input="updateFieldState('vip_ehe_aktiv', $event)"
            @change="addCustomerDataEdited('pep')" />
        </div>
      </div>
    </div>

    <div class="box__container">
      <div class="box__title">Finanzielle Verhältnisse</div>

      <div class="forms__input--half-size">
        <div class="row">
          <div class="col-md-6 col-12">
            <InputField 
              type="currency"
              label="Einkommen €" 
              v-model="pep.finanzielle_verh_n1" 
              :precision="precision" 
              :disabled="!isEditable" 
              @change="addCustomerDataEdited('pep')" />
          </div>

          <div class="col-md-6 col-12">
            <InputField 
              type="currency"
              label="Vermögen €" 
              v-model="pep.finanzielle_verh_n2" 
              :precision="precision" 
              :disabled="!isEditable" 
              @change="addCustomerDataEdited('pep')" />
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 col-12">
            <InputField 
              type="currency"
              label="Immobilien €" 
              v-model="pep.finanzielle_verh_n3" 
              :precision="precision" 
              :disabled="!isEditable" 
              @change="addCustomerDataEdited('pep')" />
          </div>

          <div class="col-md-6 col-12">
            <InputField 
              type="currency"
              label="Verpflichtungen €" 
              v-model="pep.finanzielle_verh_n4" 
              :precision="precision" 
              :disabled="!isEditable" 
              @change="addCustomerDataEdited('pep')" />
          </div>
        </div>
      </div>
    </div>

    <div class="box__container">
      <div class="box__title">Angaben über die anfänglich eingebrachten Vermögenswerte</div>

      <div class="forms__input--half-size">
        <div class="row">
          <div class="col-md-6 col-12">
            <InputField 
              label="Währung / Betrag" 
              v-model="pep.anfang_betrag" 
              :disabled="!isEditable" 
              @change="addCustomerDataEdited('pep')" />
          </div>

          <div class="col-md-6 col-12">
            <InputField 
              label="Herkunft" 
              v-model="pep.anfang_herkunft" 
              :disabled="!isEditable" 
              @change="addCustomerDataEdited('pep')" />
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 col-12">
            <InputField 
              label="Hausbank" 
              v-model="pep.anfang_bank" 
              :disabled="!isEditable" 
              @change="addCustomerDataEdited('pep')" />
          </div>

          <div class="col-md-6 col-12 mt-md-24px mt-sm-8px">
            <InputToggleSwitch 
              label="Wirtschaftlich Berechtigter" 
              :inLineLabel="true"
              v-model="pep.wirt_ber" 
              :disabled="!isEditable" 
              @input="addCustomerDataEdited('pep')" />
          </div>
        </div>
      </div>
    </div>

    <div class="box__container">Generell keine Verträge bei denen Vertragsinhaber und wirtschaftlich Berechtigter nicht identisch sind!</div>
  </div>
</template>

<script>
import CUSTOMERDATA_TYPES from '@/store/customerData/types';

import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import InputField from '@/components/core/forms/InputField.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';

import validator from '@/mixins/validator';
import persoenlicheDatenMixin from '../persoenliche-daten-mixin.js';
import personendatenOffentlichePersonMixin from './personendaten-offentliche-person-mixin.js';

const PRECISION = 2;


export default {
  mixins: [validator, persoenlicheDatenMixin, personendatenOffentlichePersonMixin],
  data() {
    return {
      ...this.initialState(),
      precision: PRECISION,
    };
  },
  computed: {
  },
  methods: {
    initialState() {
      return {
        pep: {
          pep_aktiv: '0',
          pep_funktion: '',
          pep_ehe_aktiv: '0',
          pep_ehe_text: '',
          vip_aktiv: '0',
          vip_funktion: '',
          vip_ehe_aktiv: '0',
          vip_ehe_text: '',
          finanzielle_verh_n1: null,
          finanzielle_verh_n2: null,
          finanzielle_verh_n3: null,
          finanzielle_verh_n4: null,
          anfang_betrag: '',
          anfang_herkunft: '',
          anfang_bank: '',
          wirt_ber: false,
        },
      };
    },
    updateGroupsIntoData() {
      this.updateGroupIntoData('pep');
    },
  },
  mounted() {
    this.updateGroupsIntoData();
  },
  validators: {},
  components: {
    InputToggleSwitch,
    InputField,
    InputTextArea,
  },
}
</script>
