<template>
  <div>
    <DragnDropArea @files="openAddDocumentModal($event)" hoverText="Neues Dokument hochladen" class="box-container">
      <BaseButton v-if="editMode" @click="openAddDocumentModal($event)" class="mb-16px">
        Neuer Anhang
      </BaseButton>
      
      <Table 
        v-if="rows.length" 
        :rows="rows"
        :headers="headers"
        tableId="3cd44c85-12b2-4625-b32c-520e4bf6564g"
        @click-bezeichnung="openAntrag($event)"
        @action-DELETE="deleteAntrag($event)">
      </Table>
      <div v-else>Keine Daten</div>
    </DragnDropArea>


    <BaseModal ref="addDocumentModal" :showDefaultButtons="false" @close="cancelEditing()">
      <template #modalTitle>{{ inserting == true ? 'Neu Anhang' : 'Anhang Bearbeiten' }}</template>

      <template #default>
        <div class="box__title">Datei hochladen</div>
        <div class="group-buttons">
          <BaseFileSelect @files="sendAnhang($event)" class="mb-16px">
            Datei hinzufügen
          </BaseFileSelect>
          <FotoButton v-if="cameraAvailable" isSecondary edgeCorrection @file="sendAnhang([$event])" class="mb-1" >
            Fotografieren
          </FotoButton>
        </div>
        <div class="row">
          <div class="col-12">
            <span>{{filename}}</span><br>
          </div>
        </div>

        <template>
          <InputField label="Bezeichnung" v-model="fileForm.bezeichnung"  :validateUntouched="true"/>
        </template>
        <template>
          <InputField label="Bemerkung" v-model="fileForm.bemerkung" />
        </template>
        <template>
          <ComboBox label="Kategorie" v-model="fileForm.kategorie" :values="kategories()" :firstEmpty="true"/>
        </template>
        <template>
          <DatePickerField label="Stand" :isComponentHalfSize="true" dateFormat="DD.MM.YYYY"
            v-model="fileForm.dateStand" />
        </template>

      </template>

      <template #footer>
        <BaseButton isSecondary @click="closeAddDocumentModal()" :disabled="loading" class="mr-16px">Abbrechen</BaseButton>
        <BaseButton @click="speichernFileForm()" :disabled="loading">
          Anhang speichern
          <AnimatedSpinner v-if="loading" />
        </BaseButton>
      </template>
    </BaseModal>

  </div>
</template>


<script>
  import { PhTrash, PhFileArrowDown } from 'phosphor-vue';
  import validator from "@/mixins/validator";
  import { required } from "@/mixins/validator/rules";
  import { mapGetters } from 'vuex';
  
  import DragnDropArea from '@/components/fileUpload/DragnDropArea.vue';
  import SCHADENSMELDUNG_TYPES from '@/store/schadensmeldung/types';

  import { uploadFileChunked } from '@/helpers/upload-helper';
  import { buildMessage, } from '@/helpers/log-message-helper';

  import BaseFileSelect from '@/components/fileUpload/BaseFileSelect.vue';
  import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';
  import BaseModal from '@/components/core/BaseModal.vue';
  import BaseButton from '@/components/core/BaseButton.vue';
  import InputField from '@/components/core/forms/InputField.vue';
  import ComboBox from '@/components/core/forms/ComboBox.vue';
  import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
  import Table from "@/components/table2/Table.vue";
  import LOG_TYPES from '@/store/log/types';
  import FotoButton from '@/components/fileUpload/FotoButton.vue';
  import {TextColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
  import { viewDocument } from '@/components/core/download/DownloadLink.vue';

  export default {
    mixins: [validator],
    props: {
      schadenId: {
        type: [String, Number],
      },
      vertragId: {
        type: [String, Number],
      },
      editMode: {
        type: Boolean,
        default: true,
      },
      multipleFileUpload: {
        type: Boolean,
        default: true,    
      },
    },
    data() {
      return {
        attachments: [],
        filename: null,
        loading: false,
        editing: false,
        inserting: false,
        fileId: null,
        fileForm: {
        },
        cameraAvailable: false,
      };
    },
    computed: {
      ...mapGetters({
        schadensmeldung: SCHADENSMELDUNG_TYPES.GETTERS.SCHADENSMELDUNG,
        configData: SCHADENSMELDUNG_TYPES.GETTERS.CONFIG_DATA,
      }),
      files() {
        const files = this.schadensmeldung?.files || [];
        return [...files];
      },
      headers() {
        let headers = {
          lockedLeft: [
            TextColumn("bezeichnung", "Name").makeLink(),
          ],
          center: [
            TextColumn("kategorie", "Kategorie"),
            TextColumn("dateStand", "Stand"),
            TextColumn("bemerkung", "Bemerkung"),
          ],
          lockedRight: [],
        }

        if (this.editMode) {
          headers.lockedRight.push(ActionColumn("actions", "Aktionen"))
        }
        return headers
      },
      rows() {
        let actions = undefined

        if (this.editMode) {
          actions = [
            SimpleAction('DELETE', 'PhTrash', 'Löschen')
          ]
        }

        return this.files?.map(row => {
          return {...row, bezeichnung: row.bezeichnung || 'Unbekannt', actions};
        });
      },
    },
    validators: {
      fileForm:{
       bezeichnung: [required()],
      },
    },
    methods: {
      deleteAntrag(antrag) {
          this.$confirmModal({
            title: 'Eintrag löschen',
            message: `Wollen Sie den Eintrag ${antrag.bezeichnung} wirklich löschen?`,
            labelButtonCancel: 'Abbrechen',
            labelButtonConfirm: 'Löschen',
          }).then(() => {
            this.$store.dispatch(SCHADENSMELDUNG_TYPES.ACTIONS.DELETE_ANHANG, { antragId: antrag.antragId });
          }).catch(() => {})
      },
      openAntrag(data) {
        viewDocument({
          data: data.bytes,
          contentType: data.contentType,
          filename: 'ScadensmeldungDoc',
        })
      },
      retrieveSchadensDetail() {
        this.$store.dispatch(SCHADENSMELDUNG_TYPES.ACTIONS.RETRIEVE_SCHADENSMELDUNG, {
          vertragId: this.vertragId,
          schadenId: this.schadenId
        });
      },
      sendAnhang(files) {

        if (!files || !files.length) {
          return;
        }
        this.filename = files[0].name;
        files.forEach((file) => {
          uploadFileChunked(file).then((fileId) => {
            this.attachments.push(
              {
                fileId,
                schadenId: this.schadenId,
              }
            )
          })
        });

      },
      closeAddDocumentModal() {
        this.$refs.addDocumentModal.close();
        this.filename = null;
      },
      cancelEditing() {
        this.editing = false;
        this.inserting = false;
        this.fileForm = this.getNewFileForm();
      },
      kategories() {
        return this.configData?.kategories || [];
      },
      sendFileForm() {
        this.loading = false;
        const fileForm = this.fileForm;
        this.fileForm.schadenId = this.schadenId;
        
        this.attachments.forEach(file => {
          this.$store.dispatch(SCHADENSMELDUNG_TYPES.ACTIONS.SEND_ANHANG, {
            fileForm,
            fileId: file.fileId,
            schadenId: file.schadenId,
          })
          .then(() => this.closeAddDocumentModal())
          .catch((error) => this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error, 'danger')))
          .finally(() => this.loading = false);
        })
        this.attachments = [];
        
      },
      speichernFileForm() {
        this.editing = false;
        this.inserting = false;
        this.loading = false;
        this.sendFileForm();
      },
      getNewFileForm() {
        return {
          bezeichnung: null,
          kategorie: null,
          dateStand: null,
          bemerkung: null,
          schadenId: null,
        };
      },
      openAddDocumentModal(files) {

        if(!this.schadenId || this.schadenId === 'neuesSchaden'){
          this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Sie müssen zuerst speichern, bevor Sie eine Datei anhängen.', 'danger'));
          return;
        }
        this.loading = false;
        this.sendAnhang(files);
        this.$emit('file', files?.length ? files[0] : null);
        this.filename = files?.length ? files[0].name : null;
        this.$refs.addDocumentModal.open(this.files);

      },
    },
    mounted() {
      this.retrieveSchadensDetail()
      if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
        navigator.mediaDevices.enumerateDevices()
        .then(devices => {
          this.cameraAvailable = devices.some(device => device.kind == 'videoinput');
        });
      }
    },
    components: {
      PhTrash,
      PhFileArrowDown,
      BaseFileSelect,
      BaseModal,
      BaseButton,
      DragnDropArea,
      InputField,
      ComboBox,
      DatePickerField,
      AnimatedSpinner,
      Table,
      FotoButton
    },
  }
</script>

<style scoped>
  .img-to-delete {
    max-width: 250px;
    height: auto;
  }
  .group-buttons {
    display: flex;
    flex-direction: row;
  }
</style>