import VERSICHERUNGSTARIFE_TYPES from './types';
import { getInitialState } from './index'

export default {
  [VERSICHERUNGSTARIFE_TYPES.MUTATIONS.RETRIEVE_VERSICHERUNGSTARIFE_SUCCESS](state, payload) {
    state.tarifeData = payload;
  }, 
  [VERSICHERUNGSTARIFE_TYPES.MUTATIONS.RESET_STATE](state, payload) {
    Object.assign(state, getInitialState())
  }, 
  [VERSICHERUNGSTARIFE_TYPES.MUTATIONS.REMOVE_ROW_SUCCESS](state, payload) {
    state.lastRemovedRow = payload;
  },
  [VERSICHERUNGSTARIFE_TYPES.MUTATIONS.SELECTED_VERSICHERUNGSTARIF](state, payload) {
    state.selectedTarif = payload;
  },
  [VERSICHERUNGSTARIFE_TYPES.MUTATIONS.PERSIST_ROW_SUCCESS](state, payload) {
    state.persistedData = payload;
    state.selectedTarif = payload;
  },
  [VERSICHERUNGSTARIFE_TYPES.MUTATIONS.RESET_LIST](state, payload) {
    state.tarifeData = {};
  },
  [VERSICHERUNGSTARIFE_TYPES.MUTATIONS.VERSICHERUNGSTARIFE_GESELLSCHAFTEN](state, payload) {
    state.gesellschaften = payload;
  },
  [VERSICHERUNGSTARIFE_TYPES.MUTATIONS.VERSICHERUNGSTARIFE_SPARTENTYPEN](state, payload) {
    state.spartentypen = payload;
  },
  [VERSICHERUNGSTARIFE_TYPES.MUTATIONS.RETRIEVE_TARIF_DOKUMENTE_SUCCESS](state, payload) {
    state.tarifDokumente = payload;
  },
  [VERSICHERUNGSTARIFE_TYPES.MUTATIONS.UPLOAD_TARIF_DOKUMENT_SUCCESS](state, payload) {
    state.tarifDokumente = payload;
  },    
  [VERSICHERUNGSTARIFE_TYPES.MUTATIONS.REMOVE_DOCUMENT_SUCCESS](state, payload) {
    state.lastRemovedDocument = payload;
  },    
  [VERSICHERUNGSTARIFE_TYPES.MUTATIONS.SELECTED_DOCUMENT](state, payload) {
    state.selectedDocument = payload;
  },    
  [VERSICHERUNGSTARIFE_TYPES.MUTATIONS.RETRIEVE_SPARTE_SUCCESS](state, payload) {
    state.sparte = payload;
  }, 
  [VERSICHERUNGSTARIFE_TYPES.MUTATIONS.RESET_TARIFE_DOKUMENTE](state, payload) {
    state.tarifDokumente = {};
  },
  [VERSICHERUNGSTARIFE_TYPES.MUTATIONS.VORGABE_TARIFDATEN_SUCCESS](state, payload) {
    state.tarifdatenVorgabe = payload;
  },
  [VERSICHERUNGSTARIFE_TYPES.MUTATIONS.PERSIST_VORGABE_SUCCESS](state, payload) {
    state.persistedVorgabeData = payload;
  },  

  
  
  
  
  
}