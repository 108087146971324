<template>
  <div class="fondsshop_container">
    <div class="banner">
      <editable-frame type="fondsshop_title_page" />
    </div>
    <hr />
    <div class="fondsshop_content">
      <div class="fondsshop_image">
        <EditableImageOnly type="fondsshop_image1" />
      </div>
      <div class="fondsshop_text">
        <editable-frame type="fondsshop_text1" />
        <base-button @click="$router.push('fonds')">JETZT FONDSSHOP ENTDECKEN!</base-button>
      </div>
    </div>
  </div>
</template>
<script>
import EditableFrame from "@/components/cms/EditableFrame.vue";
import EditableImageOnly from "@/components/cms/EditableImageOnly.vue";
import BaseButton from "@/components/core/BaseButton.vue";
export default {
  components: {
    EditableFrame,
    BaseButton,
    EditableImageOnly
  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>
<style  scoped>
.fondsshop_container {
  margin: 0 auto;
  padding-left: 1em;
  padding-right: 1em;
  max-width: 1200px;
}
.banner {
  font-size: 26px;
  text-align: left;
  color: var(--color-text);
}

.fondsshop_content {
  margin: 0 auto;
  padding-top: 2em;
  padding-bottom: 2em;
  display: grid;
  gap: 4em;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "fondsshop_image fondsshop_text";
}
.fondsshop_image {
  grid-area: fondsshop_image;
}
.fondsshop_text {
  grid-area: fondsshop_text;
}
a {
  color: var(--color-link);
}

@media only screen and (max-width: 1024px) {
  .fondsshop_content {
    display: block;
  }
}
</style>