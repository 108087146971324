<template>
  <div class="box__container">
    <div class="box__title">Zusätzliche Informationen</div>
      <div v-if="isEditable" class="mb-8px">
        <ComboBox 
          isComponentHalfSize
          :values="zusaetzlicheInfosValues" 
          v-model="zusaetzlicheInfosCombo" 
          :sortComboboxValues="false" 
          @change="resetZusaetzlicheInfosField()"
        />
        <InputField 
          isComponentHalfSize
          v-model="zusaetzlicheInfosField" 
          @input="resetZusaetzlicheInfosCombo()"
        />
        <BaseButton
          label="Hinzufügen"
          :disabled="!isValid"
          @click="addZusaetzlicheInfos()"
        />
      </div>

      <Table
        tableId="2bab20e1-b1e1-44ad-aad2-ebacbb3eb51c"
        rowId="zusaetzlicheInfos"
        :headers="headers"
        :rows="rows"
        :rowsPerPage="10"
        @action-DELETE="removeZusaetzlicheInfos($event.zusaetzlicheInfos)"
      />
  </div>
</template>

<script>
import Table from "@/components/table2/Table.vue";
import {TextColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import BaseButton from '@/components/core/BaseButton.vue';

export default {
  model: {
      prop: 'zusaetzlicheInfos',
      event: 'zusaetzlicheInfos',
  },
  props: {
    isEditable: {
      type: Boolean,
      default: false,
    },
    zusaetzlicheInfosValues: {
      type: Array,
      default: () => [],
    },
    zusaetzlicheInfos: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Table,
    InputField,
    ComboBox,
    BaseButton,
  },
  data() {
    return {
        zusaetzlicheInfosCombo: '',
        zusaetzlicheInfosField: '',
        headers: {
            lockedLeft: [
                TextColumn("zusaetzlicheInfos", "Zusätzliche Informationen"),
            ],
            lockedRight: [
                ActionColumn("actions", "Aktionen"),
            ]
        },
    };
  },
  computed: {
    rows() {
        const actions = [
            SimpleAction("DELETE", 'PhTrash', "Löschen"),
        ];
        return (this.zusaetzlicheInfos || []).map(zusaetzlicheInfos => ({
            zusaetzlicheInfos,
            actions: this.isEditable ? actions : null,
        }))
    },
    currentValue() {
      return (this.zusaetzlicheInfosCombo || this.zusaetzlicheInfosField || '').trim();
    },
    isValid() {
      return !!this.currentValue;
    },
  },
  methods: {
    resetZusaetzlicheInfosField() {
      this.zusaetzlicheInfosField = '';
    },
    resetZusaetzlicheInfosCombo() {
      this.zusaetzlicheInfosCombo = '';
    },
    addZusaetzlicheInfos(zusaetzlicheInfo = this.currentValue) {
      let zusaetzlicheInfos = this.zusaetzlicheInfos ? [...this.zusaetzlicheInfos] : []

      if (zusaetzlicheInfo && zusaetzlicheInfos.findIndex(v => v.toLowerCase() === zusaetzlicheInfo.toLowerCase()) < 0) {
        zusaetzlicheInfos.unshift(zusaetzlicheInfo)
        this.$emit('zusaetzlicheInfos', zusaetzlicheInfos)
        this.$emit('change', zusaetzlicheInfos)
      }

      this.resetZusaetzlicheInfosField();
      this.resetZusaetzlicheInfosCombo();
    },
    removeZusaetzlicheInfos(zusaetzlicheInfos) {
      const temp = [...this.zusaetzlicheInfos]
      temp.splice(temp.findIndex((v)=> v === zusaetzlicheInfos), 1);
      this.$emit('zusaetzlicheInfos', [...temp])
      this.$emit('change', [...temp])
      
    },
  },
}
</script>
