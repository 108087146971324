<template>
  <BaseDropdownMenu 
    containerClass="menu-structure__dropdown-menu" 
    placement="bottom-left" 
    @onShow="onShow" 
    @onHide="onHide" 
    style="display:inline-block"
  >
    <template #hook-target>
      <slot />
    </template>
    <template #default>
      <div data-dropdown-not-close>
        <InputField ref="filterTextEl" v-model="filterText" placeholder="Zur Suche Text eingeben" class="pb-1" />
        <ComboBox v-if="parent" v-model="filterParent" :values="parents" placeholder="Alle" />
      </div>

      <div v-if="showList" class="menu-structure__dropdown-menu__container my-2">
        <template v-if="menusGroupedKeysSorted.length">
          <div v-for="key in menusGroupedKeysSorted" :key="key">
            <div class="d-flex justify-content-between">
              <span v-html="$sanitize(key)" class="text-small text-bold" />
              <button 
                type="button" 
                class="btn-clear clickable mx-2" 
                @click="addMenus(menusGrouped[key])" 
              >+ alle</button>
            </div>
            <ul v-if="menusGrouped[key].length > 0" class="list my-1">
              <li 
                v-for="(menu, index) in menusGrouped[key]" 
                :key="index" 
                class="menu-structure__dropdown-menu__item clickable" 
                @click="addMenus([menu])" 
              >
                <div v-if="menu.component" class="menu-structure__dropdown-menu__item--icon">
                  <component :is="menu.component" v-bind="menu.componentProps" />
                </div>
                <div class="menu-structure__dropdown-menu__item--content">
                  <span v-html="$sanitize(menu.label)" />
                </div>
              </li>
            </ul>
          </div>
        </template>
        <NoData v-else noIcon />
      </div>

      <div class="d-flex justify-content-end">
        <BaseButton v-if="parent" @click="$emit('addCustom', { parent })" class="m-0">neues Menü hinzufügen</BaseButton>
      </div>
    </template>
  </BaseDropdownMenu>
</template>

<script>
import { mapGetters } from 'vuex';
import MENU_STRUCTURE_TYPES from '@/store/menuStructure/types';

import BaseDropdownMenu from '@/components/core/BaseDropdownMenu.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import NoData from '@/components/core/NoData.vue';

import { sortByName, filterByText } from '@/components/core/option-menu/option-menu-utils';

export default {
  components: {
    BaseDropdownMenu,
    BaseButton,
    InputField,
    ComboBox,
    NoData,
  },
  props: {
    parent: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      filterText: '',
      filterParent: null,
      showList: false, // improves list render performance
    };
  },
  computed: {
    ...mapGetters({
      menuStructureContext: MENU_STRUCTURE_TYPES.GETTERS.MENU_STRUCTURE_CONTEXT,
    }),
    availableMenus() {
      const { parent, menuStructureContext } = this;

      const menus = (() => {
        if (parent) {
          return menuStructureContext.availableOptionsMenu
            .filter(menu => parent.path !== menu.path && !parent?.subMenu?.some(sm => sm.path === menu.path))
            .map(menu => ({
              ...menu,
              parentsLabelText: menu?.parentsLabel?.join(' | '),
            }))
            .sort(sortByName);
        } else {
          return menuStructureContext.availablePrimaryMenu;
        }
      })();

      return menus;
    },
    parents() {
      const { availableMenus } = this;
      return Object.keys(availableMenus.reduce((acc, menu) => ({
        ...acc,
        [menu.parentsLabelText]: true,
      }), {}));
    },
    menusGrouped() {
      const { availableMenus, filterText, filterParent } = this;

      return availableMenus
        .filter(filterByText(filterText))
        .filter(menu => !filterParent || menu.parentsLabelText === filterParent)
        .groupBy(menu => menu.parentsLabelText || '') || {};
    },
    menusGroupedKeysSorted() {
      return Object.keys(this.menusGrouped)
        .map(item => ({ label: item, }))
        .sort(sortByName)
        .map(item => item.label);
    },
  },
  methods: {
    onShow() {
      this.showList = true;
      this.reset();
    },
    onHide() {
      this.showList = false;
    },
    reset() {
      this.filterText = '';
      this.filterParent = null;

      this.$nextTick(() => requestAnimationFrame(() => this.$refs.filterTextEl?.focus()));
    },
    addMenus(menus) {
      const { parent } = this;

      this.$emit('add', { 
        parent, 
        menus: menus.map(m => ({ 
          ...m, 
          subMenu: m.hasTabMenu ? [ ...m.subMenu ] : [],
          parent: null, 
        })), 
      });
    },
  },
}
</script>

<style lang="scss">
.menu-structure__dropdown-menu {
  &.positioned-bottom-left,
  &.positioned-top-left {
    margin-left: -18px;
  }
}

.menu-structure__dropdown-menu__container {
  overflow: auto;
  height: 40vh;
  width: 350px;
}

.menu-structure__dropdown-menu__item {
  display: flex;
  align-items: center;
  margin: .5rem 0;

  .menu-structure__dropdown-menu__item--icon {
    margin: 0 .5rem 0 0;

    > * {
      width: 2rem;
      height: auto;
    }
  }

  .menu-structure__dropdown-menu__item--content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.2em;
  }
}
</style>
