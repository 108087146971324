import CREDIT_TYPES from './types';
import Vue from 'vue';

export default {
  [CREDIT_TYPES.MUTATIONS.FIND_CREDITS_SUCCESS](state, credits) {
    state.credits = credits;
  },

  [CREDIT_TYPES.MUTATIONS.SET_SELECTED_CREDIT_ID](state, payload) {
    Vue.set(state, 'selectedCreditId', payload);
  },

  [CREDIT_TYPES.MUTATIONS.GET_COMBOS_SUCCESS](state, payload) {
    Vue.set(state, 'combos', { ...payload });
  },

  [CREDIT_TYPES.MUTATIONS.GET_CREDIT_SUCCESS](state, payload) {
    Vue.set(state, 'credit', { ...payload });
  },

  [CREDIT_TYPES.MUTATIONS.APPEND_CREDIT_EDITED](state, payload) {
    if(payload.id) {
      Vue.set(state, 'creditEdited', Object.assign({}, state.creditEdited, payload));
    }
  },

  [CREDIT_TYPES.MUTATIONS.RESET_CREDIT_EDITED](state) {
    Vue.set(state, 'creditEdited', null);
  },

  [CREDIT_TYPES.MUTATIONS.RESET_CREDIT_STATE](state) {
    Vue.set(state, 'selectedCreditId', null);
    Vue.set(state, 'credit', {});
    Vue.set(state, 'creditEdited', null);
    Vue.set(state, 'updates', []);
    Vue.set(state, 'status', []);
    Vue.set(state, 'appointments', []);
    Vue.set(state, 'prohypCombos', {});
    Vue.set(state, 'prohyp', {});
    Vue.set(state, 'prohypEdited', null);
    Vue.set(state, 'hints', []);
  },

  [CREDIT_TYPES.MUTATIONS.SET_RIGHTS](state, payload) {
    Vue.set(state, 'rights', {
      ...state.rights,
      prohypMenuItemVisible: payload?.prohypMenuItemVisible, 
      allowReadVertragAbrechnung: payload?.allowReadVertragAbrechnung, 
      allowModifyVertragAbrechnung: payload?.allowModifyVertragAbrechnung,
    });
  },

  [CREDIT_TYPES.MUTATIONS.FIND_UPDATES_SUCCESS](state, payload) {
    Vue.set(state, 'updates', [...payload]);
  },

  [CREDIT_TYPES.MUTATIONS.FIND_STATUS_SUCCESS](state, payload) {
    Vue.set(state, 'status', [...payload]);
  },

  [CREDIT_TYPES.MUTATIONS.FIND_APPOINTMENTS_SUCCESS](state, payload) {
    Vue.set(state, 'appointments', [...payload]);
  },

  [CREDIT_TYPES.MUTATIONS.GET_PROHYP_COMBOS_SUCCESS](state, payload) {
    Vue.set(state, 'prohypCombos', { ...payload });
  },

  [CREDIT_TYPES.MUTATIONS.GET_PROHYP_SUCCESS](state, payload) {
    Vue.set(state, 'prohyp', { ...payload });
  },

  [CREDIT_TYPES.MUTATIONS.APPEND_PROHYP_EDITED](state, payload) {
    if(payload.kreditId) {
      Vue.set(state, 'prohypEdited', Object.assign({}, state.prohypEdited, payload));
    }
  },

  [CREDIT_TYPES.MUTATIONS.RESET_PROHYP_EDITED](state) {
    Vue.set(state, 'prohypEdited', null);
  },

  [CREDIT_TYPES.MUTATIONS.FIND_BUCHUNGEN_SUCCESS](state, payload) {
    Vue.set(state, 'buchungen', [...payload]);
  },

  [CREDIT_TYPES.MUTATIONS.SET_SELECTED_BUCHUNG](state, payload) {
    Vue.set(state, 'selectedBuchung', { ...payload });
  },

  [CREDIT_TYPES.MUTATIONS.FIND_BUCHUNG_PROV_STRUKTUREN_SUCCESS](state, payload) {
    const selectedBuchung = state.selectedBuchung;
    if(selectedBuchung?.id) {
      Vue.set(state, 'buchungProvStrukturen', Object.assign({}, state.buchungProvStrukturen, {
        [selectedBuchung.id]: [...payload]
      }));
    }
  },

  [CREDIT_TYPES.MUTATIONS.ADD_HINT](state, payload) {
    Vue.set(state, 'hints', [...state.hints, payload]);
  },

  [CREDIT_TYPES.MUTATIONS.RESET_HINTS](state) {
    Vue.set(state, 'hints', []);
  },

  [CREDIT_TYPES.MUTATIONS.FIND_DOCUMENTS_SUCCESS](state, payload) { // TODO remove after migrate Kredit Dokumente to Dokumentenarchiv
    Vue.set(state, 'documents', [...payload]);
  },

}
