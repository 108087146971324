import { render, staticRenderFns } from "./PostfachEmail.vue?vue&type=template&id=8b48a78e&scoped=true&"
import script from "./PostfachEmail.vue?vue&type=script&lang=js&"
export * from "./PostfachEmail.vue?vue&type=script&lang=js&"
import style0 from "./PostfachEmail.vue?vue&type=style&index=0&id=8b48a78e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b48a78e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("P:\\workspaces\\fk0\\fc\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8b48a78e')) {
      api.createRecord('8b48a78e', component.options)
    } else {
      api.reload('8b48a78e', component.options)
    }
    module.hot.accept("./PostfachEmail.vue?vue&type=template&id=8b48a78e&scoped=true&", function () {
      api.rerender('8b48a78e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/communication/PostfachEmail.vue"
export default component.exports