import ACCOUNT_TYPES from './types';
import CORE_TYPES from '@/store/core/types'
import axios from 'axios';

export default {
  async [ACCOUNT_TYPES.ACTIONS.GET_ACCOUNTS]({ commit, state, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };
    const params = payload && payload.id && payload.id !== 'ALLE_DEPOTS_ANZEIGEN'
      ? {
        depotid: `${payload.id}`
      } : {};
    const url = '/../mrsbankaccount';
    await axios.get(rootState.core.apiAddress + url, {...config, params}).then(response => {
      if (response && response.data) {
        commit(ACCOUNT_TYPES.MUTATIONS.GET_ACCOUNT_SUCCESS, response.data);
      }
    }).catch(error => {
      commit(ACCOUNT_TYPES.MUTATIONS.GET_FAILTURE);
    })
  },
  [ACCOUNT_TYPES.ACTIONS.GET_ACCOUNT_DEPOTSTAMMDATEN]({ commit, state, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };
    const url = '/../mrsstammdaten';
    axios.get(rootState.core.apiAddress + url, {...config, params: {...({bgsNr: payload?.bgsNr})}
    }).then(response => {
      if (response && response.data) {
        commit(ACCOUNT_TYPES.MUTATIONS.GET_ACCOUNT_DEPOTSTAMMDATEN_SUCCESS, response.data);
      }
    }).catch(error => {
      commit(ACCOUNT_TYPES.MUTATIONS.GET_FAILTURE);
    })
  },

}