<template>
  <div>
    <BoxContainer :title="detail.label" v-for="(detail, index) of details" :key="detail.label + index">
      <dl class="key-value--item" v-for="(record, index) of detail.records" :key="'record_'+ detail.label + index">
        <dt class="value--item-label">{{record.type + ':'}}</dt>
        <div v-if="record.type == 'FWW FundStars'" class="value--item-value">
          <PhStar v-for="(star, index) of getStars(record.value)" :size="16" weight="fill" :key="'star-'+index"/>
        </div>
        <dd v-else class="value--item-value">{{record.value}}</dd>
      </dl>
      <template v-if="detail.footerLabels">
        <div v-for="(text, idx) in detail.footerLabels" :key="idx" class="foot-comment">{{text}}</div>
      </template>
    </BoxContainer>

    <BoxContainer>
      <div class="row">
        <div v-html="sanitize(allgemeinDescription)"></div>
      </div>
    </BoxContainer>
  </div>
</template>

<script>
import FONDSINFO_TYPES from "@/store/fondsinfo/types";
import BoxContainer from '@/components/core/BoxContainer.vue';
import {PhStar} from 'phosphor-vue'
import { mapGetters } from 'vuex';
import {sanitize} from '@/helpers/string-helper.js';

export default {
  components: {
    BoxContainer,
    PhStar,
  },
  name: "TabDetail",
  props:{
    selectedTabLabel: {
      type: String
    }
  },
  computed: {
    ...mapGetters({fundsDetail : FONDSINFO_TYPES.GETTERS.FONDSINFO_DETAILS,
                  allgemeinDescription: FONDSINFO_TYPES.GETTERS.FONDSINFO_ALGEMEIN}),
    details() {
      if(this.fundsDetail){
         const records = Object.values(this.fundsDetail).slice(1);
         return records;
      }
    },
  },
  methods: {
    sanitize(htmlString) {
        return sanitize(htmlString);
    },
    getStars(value){
      const starQuantity = parseInt(value.charAt());
      return isNaN(starQuantity) ? 0 : starQuantity;
    },
    detailLabel(detail) {
      if(detail && detail.label){
        return detail.label;
      }
      return '';
    },
  },
  mounted() {
    if(!this.fundsDetail || !this.allgemeinDescription){
      this.$store.dispatch(FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_ALGEMEIN, 
      {
        isin: this.$route.params?.isin,
        depotid: this.$route.params?.depotid,
      });
    }
  },

}
</script>

<style>
.value--item-label {
  width: 25%;
}
.foot-comment {
  font-size: 80%;
}
</style>