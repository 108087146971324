var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Einstellungen",
          subtitle: "Beratungsdokumentation Versicherungen Textbausteine",
          actions: _vm.headerActions
        },
        on: {
          "action-ADD": function($event) {
            return _vm.openAddMaklerTexteModal()
          },
          "action-STRUKTUR-RELEASE": function($event) {
            return _vm.setSichtbarStrukturForAll(true)
          },
          "action-STRUKTUR-LOCK": function($event) {
            return _vm.setSichtbarStrukturForAll(false)
          }
        }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("div", { staticClass: "box__title" }, [_vm._v("Textbaustein")]),
          _c("ComboBox", {
            attrs: {
              value: _vm.selectedBereich.id,
              values: _vm.bereichValues,
              isComponentHalfSize: ""
            },
            on: {
              change: function($event) {
                return _vm.selectBereich($event)
              }
            }
          })
        ],
        1
      ),
      _vm.selectedBereich.id
        ? _c("MaklerTexteTable", { key: _vm.selectedBereich.id })
        : _vm._e(),
      _c("AddMaklerTexteModal", { ref: "addMaklerTexteModal" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }