var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseButton",
    {
      staticClass: "hide-on-small-screen",
      attrs: { isClear: "" },
      on: {
        click: function($event) {
          return _vm.showModal()
        }
      }
    },
    [
      _c("ph-record", { staticClass: "clickable", attrs: { size: _vm.size } }),
      _vm.recordingTutorial
        ? _c("button", { staticClass: "Recbutton Rec" })
        : _vm._e(),
      _c(
        "BaseModal",
        {
          ref: "createTutorialModal",
          attrs: {
            modalTitle: "Titel des Tutorials",
            showDefaultButtons: false
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "BaseButton",
                    {
                      attrs: { isSecondary: "" },
                      on: {
                        click: function($event) {
                          return _vm.closeModal()
                        }
                      }
                    },
                    [_vm._v("Abbrechen")]
                  ),
                  _c(
                    "BaseButton",
                    {
                      on: {
                        click: function($event) {
                          return _vm.startRecording()
                        }
                      }
                    },
                    [_vm._v("Aufzeichnung starten")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("InputField", {
            attrs: { label: "Title", id: "tutorialTitle" },
            model: {
              value: _vm.title,
              callback: function($$v) {
                _vm.title = $$v
              },
              expression: "title"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }