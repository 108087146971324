var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Kunden-Aktionen",
          subtitle: _vm.selectedSimpleAction && _vm.selectedSimpleAction.title,
          defaultMenu: _vm.customOptionMenu,
          actions: _vm.headerActions
        },
        on: {
          "action-EXECUTE-ACTION": function($event) {
            return _vm.executeAction(_vm.selectedSimpleAction)
          }
        }
      }),
      !_vm.usesKsc2
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("div", { staticClass: "box__title" }, [
                _vm._v("Aktivierungsbrief für Kundenzugang verwenden")
              ]),
              _c("div", [_vm._v(_vm._s(_vm.activationLetterDescription))]),
              _c("div", [
                _vm._v(
                  "Wollen Sie Ihren Kunden neben dem SMS-Freischaltcode auch die Möglichkeit eines solchen Aktivierungsbriefes als zusätzliche Authentisierungsmöglichkeit anbieten?"
                )
              ]),
              _c("InputRadioBoxGroup", {
                staticStyle: { width: "100%" },
                attrs: {
                  values: [
                    { value: true, label: "Ja" },
                    { value: false, label: "Nein" }
                  ],
                  value: _vm.activationLetterValue,
                  isComponentHalfSize: ""
                },
                on: { input: _vm.onActivationLetter }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.selectedSimpleAction
        ? _c(
            "div",
            { key: _vm.selectedSimpleAction.id, staticClass: "box__container" },
            [
              _c("div", { staticClass: "box__title" }, [
                _vm._v(_vm._s(_vm.selectedSimpleAction.titel))
              ]),
              _c("div", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.getBeschreibung(_vm.selectedSimpleAction.type)
                  )
                }
              }),
              _vm.selectedSimpleAction.statusMessage
                ? _c("div", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.getStatusMessage(
                          _vm.selectedSimpleAction.type,
                          _vm.selectedSimpleAction.status
                        )
                      )
                    }
                  })
                : _vm._e(),
              _vm.selectedSimpleAction.rdYesNo
                ? _c("InputRadioBoxGroup", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      values: [
                        { value: "YES", label: "Ja" },
                        { value: "NO", label: "Nein" }
                      ],
                      value: _vm.selectedSimpleAction.status,
                      isComponentHalfSize: ""
                    },
                    on: {
                      input: function($event) {
                        return _vm.onRadioInput(
                          $event,
                          _vm.selectedSimpleAction
                        )
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _c("GhostLoading", {
            attrs: { type: "paragraph", useBoxContainer: "" }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }