import VERMITTLERNUMMERN_TYPES from './types';
import { getInitialState } from './index'

export default {
  [VERMITTLERNUMMERN_TYPES.MUTATIONS.RETRIEVE_VERSICHERUNGEN_SUCCESS](state, payload) {
    state.versicherungenData = payload;
  }, 
  [VERMITTLERNUMMERN_TYPES.MUTATIONS.RESET_STATE](state, payload) {
    Object.assign(state, getInitialState())
  }, 
  [VERMITTLERNUMMERN_TYPES.MUTATIONS.REMOVE_ROW_SUCCESS](state, payload) {
    state.lastRemovedRow = payload;
  },
  [VERMITTLERNUMMERN_TYPES.MUTATIONS.SELECTED_FREMDNR](state, payload) {
    state.selectedFremdnr = payload;
  },
  [VERMITTLERNUMMERN_TYPES.MUTATIONS.PERSIST_ROW_SUCCESS](state, payload) {
    state.persistedData = payload;
  },
  [VERMITTLERNUMMERN_TYPES.MUTATIONS.RESET_LIST](state, payload) {
    state.versicherungenData.rows = {};
  },
  [VERMITTLERNUMMERN_TYPES.MUTATIONS.GET_OTHER_TABS_DATA_SUCCESS](state, payload) {
    state.otherTabsData = payload;
  },
  [VERMITTLERNUMMERN_TYPES.MUTATIONS.RESET_LIST_OTHER_TABS](state, payload) {
    state.otherTabsData.rows = {};
  },
  [VERMITTLERNUMMERN_TYPES.MUTATIONS.GET_ALLE_MITARBEITER_DATA_SUCCESS](state, payload) {
    state.alleMitarbeiterData = payload;
  },
  [VERMITTLERNUMMERN_TYPES.MUTATIONS.RESET_LIST_ALLE_MITARBEITER](state, payload) {
    state.alleMitarbeiterData = {};
  },  
  
}