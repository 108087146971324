<template>
  <div>
    <PageHeaderTitleNavigation :title="$appNavigation.currentMenu.label" />

    <BaseFilter
      ref="filter"
      :filterId="saveKey"
      isCache
      :configFilter="configFilter"
      :metadata="searchMenu"
      :defaultOptions="initialFilters"
      :showSaveButton="isCommingFromPruefstatusChangePage()"
      hasSmartSearch
      :immidiateSearch="isNotCommingFromPruefstautsChangePage()"
      @onFilter="handleSearch"
    />
    <HandleTablePin keyOfPIN="TABELLE_VERSICHER_ANTRAEGE" />

    <div class="box__container">
      <GhostLoading v-if="loading" type="table" :title="TABLE_TITLE" />
      <Table
        v-else
        tableId="antragslisteVersicherung"
        ref="tableResult"
        :title="TABLE_TITLE"
        :headers="headers"
        :rows="rows"
        :rowsPerPage="rowsPerPage"
        :exportConfig="{roottext: 'Versicherungsanträge'}"
        :thHoverDisabled="true"
        :mobileConfig="{title: 'gesellschaftName', headers: ['kundenLinkName', 'pruefstatus', 'vertragsbeginn']}"
        @click-kundenLinkName="openCustomerNewTab"
        @click-kundennr="openCustomerNewTab"
        @click-gesellschaftName="navigateToGesellschaft($event.gesellschaft, $event.gesellschaftArt)"
        @click-sender="openBrokerNewTab({brokerId: $event.sender})"
        @click-maklernr="navigateToVermittlerstruktur"
        @click-policennummer="openInsurancesNewTab"
        @click-vertragId="openInsurancesNewTab"
        @click-courtageErhalten="openInsurancesNewTab($event, 'courtage')"
        @action-INFO="openMaklervertragInfoModal"
        @action-INSURANCE="openInsurancesNewTab"
        @action-PROTOKOLL="showProtokoll"
      >

        <template #pruefstatus="row">
          <div v-if="istBearbeiter && !row.mobileTableContext">
            <ComboBox
              :value="row.pruefstatus.value"
              :values="row.pruefStatusComboboxValues || []"
              @change="changePruefStatus($event, row)"
            >
            </ComboBox>
          </div>
          <div v-else>
            <Pill :type="'info'" :label="row.pruefstatus.label"></Pill>
          </div>
        </template>

        <template #document="row">
          <a v-if="row.fileWithSignedAttachments"
            :tid="_generateTidFromString('versicherungsantraege-table-' + row.index)"
            @click="viewDocument(row.id)"
          >
            <PhFilePdf
              :size="20"
            />
          </a>

          <DownloadLink
            v-else
            :tid="_generateTidFromString('versicherungsantraege-table-'+row.index)"
            downloadServicePath="/getVersicherungantraegeFile"
            :filename="row.fileName || row.fremdnr"
            :queryParams="{
                id: row.id
            }"
          >
            <PhFilePdf
              :size="20"
            />
          </DownloadLink>
        </template>
      
      </Table>
    </div>

    <BaseModal
      ref="hatFehler"
      :showCancelButton="false"
      labelButtonConfirm="Ok"
    >
      <template v-slot:modalTitle>
        <ph-warning :size="16" class="mr-2" style="color: orangered" />
        Fehler
      </template>
      <div>
        <p v-if="!hasMandatoryFilters">Wählen Sie bitte einen Status: geprüft, nicht geprüft, nicht versendet, erledigt, selbst gesendet, abgelehnt.</p>
        <p v-if="!hasZeitraumFilters">Wählen Sie bitte eine Zeitraum: 30 Tage, 14 Tage, 7 Tage oder Zwischen.</p>
      </div>
    </BaseModal>
    <BaseModal
      ref="infoMaklervertrag"
      :showCancelButton="false"
      labelButtonConfirm="Ok"
      @onConfirmButton="closeInfoModal"
    >
      <template v-slot:modalTitle>
        {{ infoTitle }}
      </template>
      <div v-if="infoText && infoText.length">
        <div class="mt-1" v-for="(line, index) of infoText" :key="index">
          {{ line }}
        </div>
      </div>
    </BaseModal>
    <BaseModal
      ref="emptyFile"
      :showCancelButton="false"
      labelButtonConfirm="Ok"
    >
      <template v-slot:modalTitle>
        <ph-warning :size="16" class="mr-2" style="color: orangered" />Fehler
      </template>
      <div>Es liegen keine Dokumente vor.</div>
    </BaseModal>
    <BaseModal
      ref="errorFile"
      :showCancelButton="false"
      labelButtonConfirm="Ok"
    >
      <template v-slot:modalTitle>
        <ph-warning :size="16" class="mr-2" style="color: orangered" />Fehler
      </template>
      <div>Dokument kann nicht geöffnet werden.</div>
    </BaseModal>
    <BaseModal
      ref="multipleFilesModal"
      :showCancelButton="false"
      :showConfirmButton="false"
      :modalTitle="currentFilename"
    >
      <FileAttachmentsModal
        :signedAttachments="signedAttachments"
        :unsignedAttachments="unsignedAttachments"
        @close="$refs.multipleFilesModal.close()"
      />
    </BaseModal>
    <BaseModal
      ref="downloadErrorModal"
      modalTitle="Fehler"
      labelButtonConfirm="Ok"
      :showCancelButton="false"
    >
      <label>{{ downloadErrorMessage }}</label>
    </BaseModal>
  </div>
</template>

<script>
// import GenericPersonFilter from "@/components/core/GenericPersonFilter.vue";
import Table from '@/components/table2/Table.vue';
import {TextColumn, SlotColumn, PillColumn, DateColumn, ActionColumn, SimpleAction } from "@/components/table2/table_util.js";
import Pill from "@/components/core/Pill.vue";
import { PhInfo, PhFileText, PhWarning, PhFilePdf, PhNote} from "phosphor-vue";
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";
import CORE_TYPES from "@/store/core/types";
import { mapGetters } from "vuex";
import BaseModal from "@/components/core/BaseModal.vue";
import dayjs from "dayjs";
import navigationMixin from '@/mixins/navigation-mixin.js';
import { VIEW_ROLES } from "@/router/roles";
import FileAttachmentsModal from "@/components/documents/FileAttachmentsModal.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import VERSICHERUNGSANTRAEGE_TYPES from "../../store/versicherungsantraege/types";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import GhostLoading from "@/components/core/loading/GhostLoading.vue";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import HandleTablePin from '@/components/core/HandleTablePin.vue';
import DownloadLink from '@/components/core/download/DownloadLink.vue';
import BaseFilter from "@/components/core/BaseFilter.vue";

const MANDATORY_STATUS_FILTERS = [
  "CheckWAIT",
  "CheckNOSEND",
  "CheckOK",
  "CheckFAILD",
  "CheckSELBST",
  "CheckBEARB",
  "CheckERL",
  "alle",
];

const MANDATORY_ZEITRAUM_FILTERS = [
  "von7Tage",
  "von14Tage",
  "von30Tage",
  "von60Tage",
  "von90Tage",
  "von180Tage",
  "zwischen",
];

const DATE_FORMAT = 'DD.MM.YYYY';

const TABLE_TITLE = 'Versicherungsanträge';

export default {
  mixins: [InteractiveHelpCommonsMixin, navigationMixin],
  components: {
    HandleTablePin,
    Table,
    Pill,
    BaseModal,
    FileAttachmentsModal,
    ComboBox,
    GhostLoading,
    OptionMenu,
    PageHeaderTitleNavigation,
    DownloadLink,
    PhWarning,
    PhFilePdf,
    BaseFilter,
  },
  data() {
    return {
      TABLE_TITLE,
      saveKey: "antragslisteVersicherungenSearch",
      configFilter: {
        placeholderForDefSearchInput: "Kundennummer, Kundenname",
        noResetOnDefaultSearchInputExit: true,
        defaultSearchInputKeys: ['INPUT_KUNDEN_NR', 'INPUT_KUNDEN_NAME'],
        /**
         * filterType must not be empty or else kundennummer will not work because it's a number 
         * -> see defaultSearchInputExit in BaseFilter
         */
        filterType: 'bla',
        ignoreStoredFilter: this.isNotCommingFromPruefstautsChangePage()
      },
      rowsPerPage: 20,
      infoTitle: null,
      infoText: null,
      currentFilename: null,
      unsignedAttachments: null,
      signedAttachments: null,
      downloadErrorMessage: null,
      loading: false,
      hasMandatoryFilters: false,
      hasZeitraumFilters: false,
    };
  },
  computed: {
    ...mapGetters({
      versData: VERSICHERUNG_TYPES.GETTERS.ANTRAG_CHECK_VERS_DATA,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      userid: CORE_TYPES.GETTERS.GET_USER_ID,
      isFPPlus: CORE_TYPES.GETTERS.IS_FPP_PLUS,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
    }),
    internUser() {
      return this.hasRoles([
        [VIEW_ROLES.VIEW_INTERN],
        [VIEW_ROLES.VIEW_BROKER_AS_INTERN],
      ]);
    },
    comboboxValuesVersStatus() {
      return this.versData?.comboboxVersStatus || [];
    },
    comboboxValuesSparte() {
      return this.versData?.comboboxSparte || [];
    },
    istBearbeiter() {
      return this.versData?.istBearbeiter;
    },
    canOpenAntrag() {
      return this.versData?.canOpenAntrag;
    },
    searchMenu() {
      return [
        {
          type: "group",
          key: "allgemein",
          label: "Allgemein",
          menuItems: this.menuItemsAllgemein,
        },
        {
          type: "group",
          key: "zeitraum",
          label: "Anträge im angegebenen Zeitraum",
          menuItems: [
            {
              type: "default",
              key: "von7Tage",
              label: "7 Tage",
            },            {
              type: "default",
              key: "von14Tage",
              label: "14 Tage",
            },            {
              type: "default",
              key: "von30Tage",
              label: "30 Tage",
            },
            {
              type: "default",
              key: "von60Tage",
              label: "60 Tage",
            },   
            {
              type: "default",
              key: "von90Tage",
              label: "90 Tage",
            },   
            {
              type: "default",
              key: "von180Tage",
              label: "180 Tage",
            },                                
            {
              type: "dateRange",
              key: "zwischen",
              label: "Zwischen",
            },
          ],
        },
        {
          type: "group",
          key: "status",
          label: "Prüfstatus",
          isRequiredFilterGroup: true,
          menuItems: [
            {
              type: "default",
              label: "Alle",
              key: "alle",
            },
            {
              type: "default",
              label: "nicht geprüft",
              key: "CheckWAIT",
            },
            {
              type: "default",
              label: "nicht versendet",
              key: "CheckNOSEND",
            },
            {
              type: "default",
              label: "geprüft",
              key: "CheckOK",
            },
            {
              type: "default",
              label: "abgelehnt",
              key: "CheckFAILD",
            },
            {
              type: "default",
              label: "selbst versendet",
              key: "CheckSELBST",
            },
            {
              type: "default",
              label: "in Bearbeitung",
              key: "CheckBEARB",
            },
            {
              type: "default",
              label: "erledigt",
              key: "CheckERL",
            },
          ],
        },
      ];
    },
    menuItemsAllgemein() {
      const items = [
        {
          type: "text",
          label: "Kundennummer",
          key: "INPUT_KUNDEN_NR",
          dataType: 'number',
          inputmode: 'numeric',
        },
        {
          type: "text",
          label: "Kundenname",
          key: "INPUT_KUNDEN_NAME",
        },
        {
          type: "text",
          label: "Gesellschaft",
          key: "INPUT_GESELLSCHAFT_NAME",
        },
        {
          type: 'combobox',
          label: 'Versicherungsstatus',
          key: 'INPUT_VERS_STATUS',
          comboOptions: this.comboboxValuesVersStatus,
          emptyDenied: true,
        },        
        {
          type: "combobox",
          label: "Versicherungssparte",
          key: "INPUT_SPARTE",
          comboOptions: this.comboboxValuesSparte || [],
        },
        {
          type: "text",
          label: "V-Nummer",
          key: "INPUT_FREMDNR",
        },
       {
         type: "default",
          label: "inkl. Anträge der Struktur",
          key: "INKL_ANTRAEGE_STRUKTUR",
        },
        {
          type: "text",
          label: "Versicherungsnummer",
          key: "INPUT_VERSICHERUNGSNUMMER",
        },
        {
          type: "default",
          label: "OMDS (oder GDV) aktualisiert",
          key: "AUTOMATISCH_AKTUALISIERT",
        },
        {
          type: 'datepicker',
          label: 'Versicherungsbeginn',
          key: 'INPUT_VERSICHERUNGSBEGINN',
          emptyDenied: true,
        },
      ];
      if (this.istBearbeiter || this.internUser) {
        items.push({
          type: "text",
          label: "Vermittlernummer",
          key: "INPUT_MAKLER_NR",
        });
        items.push({
          type: "text",
          label: "Vermittlername",
          key: "INPUT_MAKLER_NAME",
        });
      }
      return items;
    },
    insurances() {
      return [...(this.versData?.insurances || [])].map((insurance) => ({
        ...insurance,
        kundenLinkName: this.kundenLinkName(insurance),
      }));
    },
    headers() {
      const columns = {
        lockedLeft: [
          SlotColumn("document", ""),
          DateColumn("datum", "Datum"),
          SlotColumn("pruefstatus", "Prüfstatus", 200, 1).makeSortable(),
          TextColumn("wurdeEingereichtRV", "Selbsteinreichung an VR"),
        ],
        center: [
          TextColumn("status", "Versicherungsstatus").addCellProps({ lineClamp: 2, }),
          TextColumn("policennummer", this.isFA ? "Polizzennr" : "VSNR").makeLink(),
          TextColumn("fremdnr", "V-Nummer").addCellProps({ lineClamp: 2, }),
          TextColumn("fremdnrBemerkung", "Bemerkung V-Nummer").addCellProps({ lineClamp: 2, }),
          TextColumn("sparte", "Versicherungssparte"),
          TextColumn("gesellschaftName", "Gesellschaft").makeLink(),
          TextColumn("kundenLinkName", "Kundenname").makeLink(this.canOpenCustomerNewTab),
          DateColumn("vertragsbeginn", "Vers.beginn"),
          PillColumn("maklervertrag", "Maklervertrag").makeSortable(),
          TextColumn("vertragId", "Interne Nummer").makeLink(this.canOpenAntrag),
          TextColumn("maklerName", "Maklername"),
          TextColumn("courtageErhalten", "AP erhalten").makeLink(this.canViewCourtageStep),
          TextColumn("bpcourtageErhalten", "BP erhalten"),
          TextColumn("kategorie", "Sparte"),
          TextColumn("zweigstelle", "Zweigstelle"),
          TextColumn("kundennr", "Kundennr.").makeLink(),
          DateColumn("dateUpdatetdGDV", `${this.isFA ? 'OMDS' : 'GDV'} aktualisiert`),
          TextColumn("sender", "Einsender").makeLink(),
          TextColumn("maklernr", "Maklernr.").makeLink(),
          TextColumn("maklerTelefon", "Telefon"),
          TextColumn("maklerStrasse", "Strasse"),
          TextColumn("maklerPLZ", "PLZ"),
          TextColumn("maklerOrt", "Ort"),
          TextColumn("maklerEmail", "Email"),
        ],
        lockedRight: [ActionColumn("actions", "Aktionen")],
      };

      if(this.isFA) {
        columns.center.unshift(TextColumn("hauptnummer", "Hauptnummer"))
      }

      if(this.isFPPlus) {
        columns.center.push(TextColumn("anbindung", "Anbindung"))
      }

      return columns;
    },
    rows() {
      const records = [...(this.insurances || [])];
      if (!records?.length) {
          return [];
      }

      return records.map(row => {
          const record = {...row, actions: this.makeActions(row)};
          record.maklervertrag = {
            label: this.getMaklerVertragLabel(row),
            type: this.getMaklerVertragType(row),
          }
          if(!this.istBearbeiter) {
            record.pruefstatus = {
             type: "info",
             label: row.pruefstatus.label
            }
          }
          record.wurdeEingereichtRV = !!row.antragBereitsGesendet ? 'ja' : 'nein'
          return record;
      })
    },
    initialFilters() {
      const today = new Date();
      var maxDate = dayjs(today).format("DD.MM.YYYY");
      var minDate = dayjs(today)
        .subtract(3, "month")
        .format("DD.MM.YYYY");
      let filters = {
        alle: true,
        von30Tage: [
          { key: "min", value: minDate },
          { key: "max", value: maxDate },
        ],
      };

      if (this.internUser && !this.isBroker) {
          filters.INPUT_MAKLER_NR = '';
      }

      return filters;
    },
    canViewCourtageStep(){
      return this.hasRoles([
        [VIEW_ROLES.VIEW_BROKER],
        [VIEW_ROLES.VIEW_CUSTOMER_AS_BROKER],
        [VIEW_ROLES.VIEW_CUSTOMER_AS_INTERN],
      ]);
    },
  },
  methods: {
    isNotCommingFromPruefstautsChangePage() {
      return !this.isCommingFromPruefstatusChangePage();
    },
    isCommingFromPruefstatusChangePage() {
      return this.$route?.query?.previousRoute?.includes('pruefstatus-change');
    },
    makeActions(row) {
      const actions = [];

      if (this.canOpenAntrag){
        actions.push(SimpleAction("INSURANCE", PhFileText, "Versicherungsvertrag öffnen"))
      }

      if (row.maklervertragInfoTitle) {
        actions.push(SimpleAction("INFO", PhInfo, "Info"))
      }
      if(this.istBearbeiter) {
        actions.push(SimpleAction("PROTOKOLL", PhNote, "Protokoll"))
      }

      return actions
    },
    openModalConfig() {
      this.$refs.tableResult.openColumnsConfig();
    },
    async handleSearch(filterParams) {

      const filterConfig = filterParams.reduce((acc, curr) => ({...acc, [curr.key] : curr.value}), {})

      let dateFromStr = null;
      let dateEndStr = null;

      let zwischen = filterConfig?.zwischen;
      if (zwischen) {
        const parsedFilterVon = dayjs(zwischen.find(v => v.key == 'min').value, DATE_FORMAT).toDate();
        const parsedFilterBis = dayjs(zwischen.find(v => v.key == 'max').value, DATE_FORMAT).toDate();

        dateFromStr = dayjs(parsedFilterVon).format(DATE_FORMAT);
        dateEndStr = dayjs(parsedFilterBis).format(DATE_FORMAT);
      }

      let von30Tage = filterConfig?.von30Tage;
      if (von30Tage) {
        dateFromStr = dayjs().subtract(30, "day").format("DD.MM.YYYY");
        dateEndStr = dayjs().format("DD.MM.YYYY");
      }

      let von60Tage = filterConfig?.von60Tage;
      if (von60Tage) {
        dateFromStr = dayjs().subtract(60, "day").format("DD.MM.YYYY");
        dateEndStr = dayjs().format("DD.MM.YYYY");
      }

      let von90Tage = filterConfig?.von90Tage;
      if (von90Tage) {
        dateFromStr = dayjs().subtract(90, "day").format("DD.MM.YYYY");
        dateEndStr = dayjs().format("DD.MM.YYYY");
      }
      
      let von180Tage = filterConfig?.von180Tage;
      if (von180Tage) {
        dateFromStr = dayjs().subtract(180, "day").format("DD.MM.YYYY");
        dateEndStr = dayjs().format("DD.MM.YYYY");
      }

      let von14Tage = filterConfig?.von14Tage;
      if (von14Tage) {
        dateFromStr = dayjs().subtract(14, "day").format("DD.MM.YYYY");
        dateEndStr = dayjs().format("DD.MM.YYYY");
      }
      
      let von7Tage = filterConfig?.von7Tage;
      if (von7Tage) {
        dateFromStr = dayjs().subtract(7, "day").format("DD.MM.YYYY");
        dateEndStr = dayjs().format("DD.MM.YYYY");
      }
      
      let versicherungsbeginn = null;
      if (filterConfig?.INPUT_VERSICHERUNGSBEGINN) {
        versicherungsbeginn = dayjs(filterConfig?.INPUT_VERSICHERUNGSBEGINN, "DD.MM.YYYY").format("DD.MM.YYYY");
      }

      let kundennr = filterConfig?.INPUT_KUNDEN_NR;
      let maklernr = filterConfig?.INPUT_MAKLER_NR;
      let maklername = filterConfig?.INPUT_MAKLER_NAME;
      let kundenName = filterConfig?.INPUT_KUNDEN_NAME;
      let gesellschaft = filterConfig?.INPUT_GESELLSCHAFT_NAME;
      let alle = filterConfig?.alle;
      let checkWAIT = filterConfig?.CheckWAIT || false;
      let checkOK = filterConfig?.CheckOK || false;
      let checkNOSEND = filterConfig?.CheckNOSEND || false;
      let checkFAILD = filterConfig?.CheckFAILD || false;
      let checkBEARB = filterConfig?.CheckBEARB || false;
      let checkSELBST = filterConfig?.CheckSELBST || false;
      let checkERL = filterConfig?.CheckERL || false;
      let inklStruktur = filterConfig?.INKL_ANTRAEGE_STRUKTUR || false;
      let einlesenUpdaten  = filterConfig?.AUTOMATISCH_AKTUALISIERT
      let versicherungsnummer  = filterConfig?.INPUT_VERSICHERUNGSNUMMER;
      let versicherungSparte = filterConfig?.INPUT_SPARTE;

      this.hasMandatoryFilters = Object.keys(filterConfig).some((filter) => MANDATORY_STATUS_FILTERS.includes(filter));

      this.hasZeitraumFilters = Object.keys(filterConfig).some((filter) => MANDATORY_ZEITRAUM_FILTERS.includes(filter));

      if (!this.hasMandatoryFilters || !this.hasZeitraumFilters) {
        this.$refs.hatFehler.open();
        return;
      }

      const payload = {dateFromStr, dateEndStr, alle, checkWAIT, checkOK, checkNOSEND, checkFAILD, checkBEARB, checkSELBST, checkERL, inklStruktur, kundennr, 
        maklernr, kundenName, gesellschaft, einlesenUpdaten, versicherungsbeginn, maklername, versicherungSparte};

      this.loading = true;
      await this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.GET_ANTRAG_CHECK_VERSICHERUNG_LIST, payload);
      this.loading = false;
    },
    kundenLinkName(row) {
      return !row?.vorname && !row?.nachname
        ? ""
        : (row?.nachname || "") + ", " + (row?.vorname || "");
    },
    getMaklerVertragLabel(row) {
      if (row?.maklervertrag) {
        switch (row.maklervertrag) {
          case "complete":
            return this.isFA ? "vorhanden" : "gescannt ";
          case "noScan":
            return "hinterlegt / nicht gescannt ";
          case "noVertrag":
            return "fehlt";
          default:
            return "";
        }
      }
    },
    getMaklerVertragType(row) {
      if (row?.maklervertrag) {
        switch (row.maklervertrag) {
          case "complete":
            return "success ";
          case "noScan":
            return "warning";
          case "noVertrag":
            return "danger";
          default:
            return "";
        }
      }
    },
    closeInfoModal() {
      this.infoTitle = null;
      this.infoText = null;
    },
    openMaklervertragInfoModal(event){
      this.infoTitle = event.maklervertragInfoTitle;
        if (this.infoTitle) {
          this.$refs.infoMaklervertrag.open();
        }
    },
    showProtokoll(row) {
      this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.GET_ANTRAG_CHECK_PROTOKOLL, row.id)
    },
    changePruefStatus(event, row) {
      if (event && row?.checkVersicherungId) {
        const pruefstatusChanged = row.pruefStatusComboboxValues?.find((combo) => combo.value === event) || {};
        const rowChangedPruefStatus = Object.assign(row, {
          pruefstatusChanged: pruefstatusChanged.value || event,
          pruefstatusChangedStr: pruefstatusChanged.label || event,
        });
        //clear before edit to avoid old data
        this.$store.commit(VERSICHERUNG_TYPES.MUTATIONS.SET_PRUEFSTATUS_CHANGE, null);
        this.$store.commit(VERSICHERUNG_TYPES.MUTATIONS.SET_PRUEFSTATUS_CHANGE,rowChangedPruefStatus);
        this.$router.push({ path: "/home/pruefstatus-change" });
      }
    },
    viewDocument(id) {
      this.$store
        .dispatch(VERSICHERUNGSANTRAEGE_TYPES.ACTIONS.GET_FILE_DATA, {id: id,})
        .then((response) => {
          if (response?.list?.length) {
            this.signedAttachments = response?.list.filter((re) => re.signed === true);
            this.unsignedAttachments = response?.list.filter((re) => re.unsigned === true);
            this.currentFilename = "Datei herunterladen";
            this.$refs.multipleFilesModal.open();
          } else if (response?.errorMessage) {
            this.downloadErrorMessage = response?.errorMessage;
            this.$refs.downloadErrorModal.open();
          }
        });
    },
    navigateToVermittlerstruktur() {
      this.$router.push(`/intern/mitarbeiterstruktur`)
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: "zurück zur Antragsliste Versicherungen",
      to,
      from,
    });

    next();
  },
  mounted() {
    if (this.isCommingFromPruefstatusChangePage()) {
      this.$refs.filter.loadCacheFilterOptions();
      const versDataTemp = this.$store.getters[VERSICHERUNG_TYPES.GETTERS.ANTRAG_CHECK_VERS_DATA];
      if(versDataTemp) {
        const rowChangedPruefStatus = this.$store.getters[VERSICHERUNG_TYPES.GETTERS.PRUEFSTATUS_CHANGE];
        this.$store.commit(VERSICHERUNG_TYPES.MUTATIONS.UPDATE_PRUEFSTATUS_ANTRAG_CHECK_VERSICHERUNG_LIST_SUCCESS, rowChangedPruefStatus);
      }
    } else {
      this.$refs.filter.filterZurucksetzen();
    }
  }
};
</script>

<style>
</style>