var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("LoginNeeded"),
      _c("MultiBankingNavigation", { attrs: { subtitle: "Kategorien" } }),
      _vm.showCategories
        ? _c("MultiBankingFilter", {
            attrs: {
              filterId: "1fbe3990-9cae-450a-8eec-eb52f726b326",
              accountId: _vm.accountId,
              showType: ""
            }
          })
        : _vm._e(),
      _vm.showCategories
        ? _c(
            "div",
            [
              _vm.accounts && _vm.accounts.length
                ? _c(
                    "div",
                    { staticClass: "box__container" },
                    [
                      _vm.changeCategory
                        ? _c("ChangeCategory", {
                            attrs: { transaction: _vm.changeCategory },
                            on: {
                              close: function($event) {
                                _vm.changeCategory = null
                              }
                            }
                          })
                        : _vm._e(),
                      _vm.editCategory
                        ? _c("EditCategory", {
                            attrs: { category: _vm.editCategory },
                            on: {
                              close: function($event) {
                                _vm.editCategory = null
                              }
                            }
                          })
                        : _vm._e(),
                      _vm.chartData
                        ? _c("PieChart", {
                            attrs: {
                              chartData: _vm.chartData,
                              height: "400px",
                              doughnut: ""
                            }
                          })
                        : _c("div", { staticClass: "kategorien-nodata" }, [
                            _vm._v(" Keine Daten für diesen Zeitraum. ")
                          ]),
                      _vm.rows.length
                        ? _c("Table", {
                            attrs: {
                              tableId: "39f84dab-e85d-4c0f-b971-4d423bee886b",
                              headers: _vm.headers,
                              rows: _vm.rows,
                              rowsPerPage: 25,
                              mobileConfig: {
                                title: "bezeichnung",
                                headers: ["umsaetze", "betrag"]
                              }
                            },
                            on: {
                              "action-EDIT_CATEGORY": function($event) {
                                return _vm.openEditCategoryModal(
                                  $event.category
                                )
                              },
                              "action-GO_TO_TRANSACTIONS": function($event) {
                                return _vm.goToTransactions($event.category)
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "color",
                                  fn: function(row) {
                                    return [
                                      _c("div", {
                                        staticClass: "kategorien__bullet",
                                        style: { "background-color": row.color }
                                      })
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2589665776
                            )
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _c("NoData", { attrs: { useBoxContainer: "" } })
            ],
            1
          )
        : _c("div", [_c("NoMultibankingWarning")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }