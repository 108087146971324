<template>
  <div class="input-forms__container">
    <div class="input-forms__label-container">
      <div class="row mt-0">
        <div class="col">
          <div class="input-forms__label-content input-forms__label-content--bigger" v-if="title">
            <ph-bank :size="16" />&nbsp;{{title}}
          </div>
        </div>
            <div class="col-12 col-xl-8" v-if="config.istVerkauf">
              <ComboBox
                label="Für Wertpapier"
                v-model="depotPosition" 
                :values="valuesForDepot"
                >
              </ComboBox>
            </div>
            <div class="col-12 col-xl-4 mt-3 pl-2" v-if="config.istVerkauf">
              <BaseButton :isEmbedded="true" :disabled="disableFondsButton" @click="addPosition()">Fonds hinzufügen</BaseButton>
            </div>
              <div class="col-auto" v-if="config.istKauf">
                <BaseButton @click="openFormsFinder">Fonds hinzufügen</BaseButton>
              </div>
          </div>
    </div>
    <div class="input-forms__input-container">
        <Table v-if="rows.length"
            :headers="headers"
            :rows="rows"
            rowId="index"
            hidePagination
            @action-DELETE="openModalDeleteFond"
        >
            <template v-slot:aufteilung="row" v-if="config.istKauf">
                <InputField type="currency" :precision="2" :value="row.aufteilung"
                    :id="'aufteilung' + row.index"
                    @input="onChangeInputField($event, row, 'aufteilung')"
                />
            </template>

            <template v-slot:aufteilungP="row" v-if="this.modus=='PROZENT'">
                <InputField type="percent" :value="row.aufteilungP"
                    :id="'aufteilungP' + row.index"
                    @input="onChangeInputField($event, row, 'aufteilungP')"
                />
            </template>

            <template v-slot:betrag="row" v-if="config.istVerkauf">
                <InputField type="currency" :precision="2" :value="row.betrag"
                    :id="'betrag' + row.index"
                    @input="onChangeInputField($event, row, 'betrag')"
                />
            </template>
    
            <template v-slot:bemerkung="row" v-if="config.istKauf">
                <InputField type="text" :value="row.bemerkung"
                    :id="'bemerkung' + row.index"
                    @input="onChangeInputField($event, row, 'bemerkung')"
                />
            </template>
        </Table>
      <div v-if="config && config.positionFields">
        <WertpapierAuswahlPositionFields 
          :config="config"
          :categoryId="categoryId"
          :antragId="antragId" />
      </div>

      <InputRadioBoxGroup v-if="config.istKauf" 
      v-model="modus"
      title="Für die Aufteilung der Summe aller Verkäufe % oder € verwenden:"
      :values="[
        {value: 'EUROBETRAG', label: '€'},
        {value: 'PROZENT', label: '%'}
      ]" 
      @input="onChange('INPUT_AUFTEILUNG_VERWENDEN', $event)"/>

  <DeletePositionModal ref="deleteModal" :position="positionToDelete"
    @delete="doDeletePosition()"></DeletePositionModal>

    </div>
  </div>
</template>

<script>
import ANTRAG_TYPES from '@/store/antrag/types';
import FONDSFINDER_TYPES from '@/store/fondsfinder/types';
import BaseButton from '@/components/core/BaseButton.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputField from '@/components/core/forms/InputField.vue';
import antragNavigationMixin from '@/mixins/antrag/antrag-navigation-mixin.js';
import BaseModal from '@/components/core/BaseModal.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, ActionColumn, SlotColumn, SimpleAction} from "@/components/table2/table_util.js";
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import WertpapierAuswahlPositionFields from '@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlPositionFields.vue';
import DeletePositionModal from '@/components/core/forms/antraege/wertpapierAuswahl/DeletePositionModal.vue'
import { mapGetters } from 'vuex';
import antragMixin from '@/mixins/antrag/antrag-mixin.js';
import { PhBank } from 'phosphor-vue';

export default {
  mixins: [antragNavigationMixin, antragMixin],
  props: {
    title: {
    },
    antragData: {
    },
    config: {
    },
    antragTyp: {
    },
    antragId: {
    },
    comboboxSelections: {
    },
    categoryId: {
    },
    suppressFilterLagerstelle: {
      type: Boolean,
      default: false
    },
    warnings: {
    },
    
  },
  components: {
    BaseButton,
    BaseModal,
    ComboBox,
    InputField,
    Table,
    DeletePositionModal,
    WertpapierAuswahlPositionFields,
    InputRadioBoxGroup,
    PhBank,
  },
  data() {
    return {
      depotPosition: this.antragData['INPUT_FONDS_VERKAUF'] || '',
      modus: this.antragData['INPUT_AUFTEILUNG_VERWENDEN'] || 'EUROBETRAG',
      disabled: false,
      positionToDelete : {}
    }
  },
  computed: {
    ...mapGetters({
      positionInfo: FONDSFINDER_TYPES.GETTERS.POSITION_INFO,
      positionsAll: ANTRAG_TYPES.GETTERS.POSITIONS,
      positionDataAll: ANTRAG_TYPES.GETTERS.POSITION_DATA
    }),
    headers() {
        const headers = {
            lockedLeft: [
                TextColumn("isin", "ISIN"),
                TextColumn("fondsname", "Fondsname"),
            ],
            center: [],
            lockedRight: [
                ActionColumn("actions", ""),
            ],
        };
        if (this.config.istVerkauf) {
            headers.center.push(SlotColumn("betrag", "Betrag", 100));
        }
        if (this.modus=='PROZENT' && this.config.istKauf) {
            headers.center.push(SlotColumn("aufteilungP", "Aufteilung der Summe aller Verkäufe in %", 100));
        }else if(this.modus=='EUROBETRAG' && this.config.istKauf){
           headers.center.push(SlotColumn("aufteilung", "Aufteilung der Summe aller Verkäufe in EUR", 100));
        }
        if (this.config.istKauf) {
            headers.center.push(SlotColumn("bemerkung", "Bemerkung", 100));
        }
        return headers;
    },
    rows() {
        const actions = [
            SimpleAction("DELETE", 'PhTrash', "Löschen"),
        ];
        return (this.positions || []).map((row, index) => ({
            ...row,
            index,
            lagerstelle: 'FODB',
            fondsname: this.getFondsName(row, this.positionInfo),
            actions,
        }));
    },
    positions() {
      if (this.positionsAll && this.antragId && this.categoryId 
          && this.positionsAll[this.antragId] 
          && this.positionsAll[this.antragId][this.categoryId]) {
        return this.positionsAll[this.antragId][this.categoryId]
      }
    },
    positionData() {
      if (this.positionDataAll && this.antragId && this.categoryId 
          && this.positionDataAll[this.antragId]
          && this.positionDataAll[this.antragId][this.categoryId]) {
        return this.positionDataAll[this.antragId][this.categoryId]
      }
    },
    positionDataOther() {
      if (this.positionDataAll && this.antragId 
          && this.positionDataAll[this.antragId]
          && this.positionDataAll[this.antragId]["VERKAUF"]) {
        return this.positionDataAll[this.antragId]["VERKAUF"]
      }
    },
    valuesForDepot() {
      if(this.antragData['INPUT_DEPOT_NR'] == "Keine Auswahl")
        return ["Bitte wählen Sie ein Depot aus, um die Verkäufe festlegen zu können"];
      else
        return !this.antragData['INPUT_DEPOT_NR'] ? ["Keine Depots vorhanden"] : this.comboboxSelections.depotPositions[this.antragData['INPUT_DEPOT_NR']];
    },
    disableFondsButton() {
        return !this.depotPosition ||
            this.depotPosition == "Bitte wählen Sie ein Depot aus, um die Verkäufe festlegen zu können" ||
            this.depotPosition == "Bitte auswählen...";
    }
  },
  methods: {
    addPosition() {
      const positionInfo = this.valuesForDepot.filter(info => info.value == this.depotPosition)
      const payload = {
          antragTyp: this.antragTyp || null,
          antragId: this.antragId || null,
          categoryId: this.categoryId,
          saveType: '',
          positionInfo,
          checkPositions: {
            lagerstelle: 'FODB',
            isins: {
              [positionInfo[0].isin]: this.categoryId
            }
          }
        }
      this.$store.dispatch(FONDSFINDER_TYPES.ACTIONS.TAKE_SELECTED_ISINS, payload)
    },
    openFormsFinder() {
      this.goToFormsFinder(this.categoryId, this.config, this.suppressFilterLagerstelle);
    },
    commitUpdatePositionsFromEvent(positionChange, isin) {
      const payload = {
        id: this.antragId,
        categoryId: this.categoryId,
        fonds: [
          { 
            ...positionChange,
            isin,
          }
        ]
      }
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_POSITIONS, payload)
    },
    onChangeInputField($event, position, field) { 
      const positionChange = { [field]: $event };
      this.commitUpdatePositionsFromEvent(positionChange, position.isin)
    },
    openModalDeleteFond(fond) {
      this.positionToDelete = fond;
      this.$refs.deleteModal.open()
    },
    onChange(componentId, value) {
      if (componentId) {
        this.$emit('change', { [componentId]: value });
      }
    },
    doDeletePosition() {
      this.deletePosition(this.antragId, this.categoryId, this.positionToDelete);
      this.positionToDelete = null;
    },
  }
}
</script>

<style scoped>
  .input-forms__label-content--bigger {
    font-size: 1.5rem;
  }
  .input-forms__label-content--bigger > i {
    font-size: 2rem;
  }
</style>