var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-forms__container" }, [
    _c("div", { staticClass: "input-forms__label-container" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12 col-xl-8" },
              [
                _c("ComboBox", {
                  attrs: {
                    label: "Depotposition",
                    values: _vm.valuesForDepot,
                    disabled: _vm.disabled
                  },
                  model: {
                    value: _vm.depotPosition,
                    callback: function($$v) {
                      _vm.depotPosition = $$v
                    },
                    expression: "depotPosition"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-12 col-xl-4 mt-3 pl-2" },
              [
                _c(
                  "BaseButton",
                  {
                    attrs: { isEmbedded: true, disabled: !_vm.depotPosition },
                    on: {
                      click: function($event) {
                        return _vm.addPosition()
                      }
                    }
                  },
                  [_vm._v("Übertragungsposition hinzufügen")]
                )
              ],
              1
            )
          ])
        ])
      ])
    ]),
    _c("hr"),
    _c(
      "div",
      { staticClass: "input-forms__input-container" },
      [
        _vm.rows.length
          ? _c("Table", {
              attrs: {
                tableId: "46c30c0a-aa2b-4885-b3ef-cfedaeec59tr",
                headers: _vm.headers,
                rows: _vm.rows,
                rowId: "isin",
                hidePagination: ""
              },
              on: { "action-DELETE": _vm.deletePosition },
              scopedSlots: _vm._u(
                [
                  {
                    key: "umfang",
                    fn: function(row) {
                      return [
                        _c(
                          "div",
                          [
                            _c("ComboBox", {
                              attrs: {
                                isEmbedded: true,
                                value: row.umfang,
                                values:
                                  (_vm.comboboxSelections &&
                                    _vm.comboboxSelections["umfang"]) ||
                                  []
                              },
                              on: {
                                change: function($event) {
                                  return _vm.onChangeUmfang($event, row)
                                }
                              }
                            }),
                            _vm.positions[row.index].umfang === "3"
                              ? _c("InputField", {
                                  attrs: {
                                    type: "number",
                                    isEmbedded: true,
                                    value: _vm.positions[row.index].anteile,
                                    id: "anteile" + row.index,
                                    validateUntouched: true
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.onChangeAnteile($event, row)
                                    }
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                2884361998
              )
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }