var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "additional-permissions__container" }, [
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("div", { staticClass: "box__title" }, [
          _vm._v("Weitere Zugriffsrechte")
        ]),
        [
          _vm.loadingPermission
            ? _c("GhostLoading", { attrs: { type: "title", repeat: 8 } })
            : !_vm.hasComponents
            ? _c("NoData")
            : [
                _vm._l(_vm.components, function(component, index) {
                  return [
                    _c(
                      "div",
                      {
                        key: index,
                        staticClass: "antrag-component__container"
                      },
                      [
                        !_vm.isComponentHidden(component)
                          ? _c("AntragComponent", {
                              staticClass: "antrag-component",
                              attrs: {
                                component: component,
                                data: _vm.allData,
                                disabled: _vm.savingPermission,
                                isComponentHalfSizeEnabled: ""
                              },
                              on: {
                                change: function($event) {
                                  return _vm.onChange(component, $event)
                                }
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                }),
                _vm.userPermissionTarget === "MAKLER_PERSONEN" && !!_vm.unternr
                  ? _c(
                      "BaseButton",
                      {
                        attrs: { isSecondary: "" },
                        on: {
                          click: function($event) {
                            return _vm.restoreDefault(_vm.unternr)
                          }
                        }
                      },
                      [_vm._v(" Standard wiederherstellen ")]
                    )
                  : _vm._e(),
                _vm.userPermissionTarget === "MAKLER"
                  ? _c(
                      "BaseButton",
                      {
                        attrs: { isSecondary: "" },
                        on: {
                          click: function($event) {
                            return _vm.confirmRestoreDefaultSubBrokers()
                          }
                        }
                      },
                      [_vm._v(" Untervermittler Standard wiederherstellen ")]
                    )
                  : _vm._e()
              ]
        ]
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }