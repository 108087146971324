var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "addVersicherungModal",
          attrs: {
            modalTitle: "Versicherung hinzufügen",
            confirmDisabled: _vm.isFormInvalid,
            autoClose: false
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.saveNewVersicherung()
            },
            close: function($event) {
              return _vm.close()
            }
          }
        },
        [
          _vm.vertragsdatenFieldsDefinition
            ? _c(
                "div",
                [
                  _c("ComboBox", {
                    attrs: {
                      id: "gesellschaftId",
                      label: "Gesellschaft",
                      customizable: true,
                      firstEmpty: true,
                      values: _vm.gesellschaftList,
                      disabled:
                        _vm.vertragsdatenFieldsDefinition.gesellschaft.disabled,
                      validateUntouched: true
                    },
                    on: {
                      blur: function($event) {
                        return _vm.dataChanged(
                          "gesellschaft",
                          _vm.versicherung.gesellschaftId
                        )
                      }
                    },
                    model: {
                      value: _vm.versicherung.gesellschaftId,
                      callback: function($$v) {
                        _vm.$set(_vm.versicherung, "gesellschaftId", $$v)
                      },
                      expression: "versicherung.gesellschaftId"
                    }
                  }),
                  _c("InputToggleSwitch", {
                    attrs: {
                      label: "Zeige Fremdgesellschaften",
                      disabled:
                        _vm.vertragsdatenFieldsDefinition.displayFremdges
                          .disabled
                    },
                    on: {
                      input: function($event) {
                        return _vm.updateGesellschaften($event)
                      }
                    },
                    model: {
                      value: _vm.versicherung.displayFremdges,
                      callback: function($$v) {
                        _vm.$set(_vm.versicherung, "displayFremdges", $$v)
                      },
                      expression: "versicherung.displayFremdges"
                    }
                  }),
                  _vm.vertragsdatenFieldsDefinition.anbindung.visible
                    ? _c("ComboBox", {
                        attrs: {
                          label: "Anbindung",
                          values:
                            _vm.vertragsdatenFieldsDefinition.anbindung.value,
                          disabled:
                            !_vm.versicherung.gesellschaftId ||
                            _vm.vertragsdatenFieldsDefinition.anbindung
                              .disabled ||
                            !_vm.vertragsdatenFieldsDefinition.anbindung
                              .value ||
                            !_vm.vertragsdatenFieldsDefinition.anbindung.value
                              .length ||
                            _vm.isLoading
                        },
                        on: {
                          change: function($event) {
                            return _vm.dataChanged("anbindung", $event)
                          }
                        },
                        model: {
                          value: _vm.versicherung.anbindung,
                          callback: function($$v) {
                            _vm.$set(_vm.versicherung, "anbindung", $$v)
                          },
                          expression: "versicherung.anbindung"
                        }
                      })
                    : _vm._e(),
                  _vm.vertragsdatenFieldsDefinition.anbindung.visible
                    ? _c("ComboBox", {
                        attrs: {
                          label: "Betreuungsstufe",
                          values: _vm.betreungsstufeComputedValues,
                          disabled:
                            !_vm.versicherung.anbindung ||
                            _vm.versicherung.anbindung !== "FPPLUS_GEWERBE"
                        },
                        on: {
                          change: function($event) {
                            return _vm.dataChanged("betreuungsstufe", $event)
                          }
                        },
                        model: {
                          value: _vm.versicherung.betreuungsstufe,
                          callback: function($$v) {
                            _vm.$set(_vm.versicherung, "betreuungsstufe", $$v)
                          },
                          expression: "versicherung.betreuungsstufe"
                        }
                      })
                    : _vm._e(),
                  _c("InputField", {
                    attrs: {
                      id: "nummer",
                      label: _vm.vertragsdatenFieldsDefinition.polNr.value,
                      disabled: !_vm.versicherung.gesellschaftId,
                      validateUntouched: true
                    },
                    on: {
                      input: function($event) {
                        return _vm.dataChanged("nummer", $event)
                      }
                    },
                    model: {
                      value: _vm.versicherung.nummer,
                      callback: function($$v) {
                        _vm.$set(_vm.versicherung, "nummer", $$v)
                      },
                      expression: "versicherung.nummer"
                    }
                  }),
                  _c("DatePickerField", {
                    attrs: {
                      id: "beginn",
                      label: "Vertragsbeginn",
                      disabled:
                        _vm.vertragsdatenFieldsDefinition.vertragsbeginn
                          .disabled,
                      validateUntouched: _vm.isFPPlus
                    },
                    on: {
                      input: function($event) {
                        return _vm.laufzeitChanged("beginn", $event)
                      }
                    },
                    model: {
                      value: _vm.versicherung.beginn,
                      callback: function($$v) {
                        _vm.$set(_vm.versicherung, "beginn", $$v)
                      },
                      expression: "versicherung.beginn"
                    }
                  }),
                  _vm.isFPPlus
                    ? _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _c("InputRadioBoxGroup", {
                            staticClass: "align-end",
                            staticStyle: { "flex-grow": "0" },
                            attrs: {
                              values: [{ value: "ENDE", label: "" }],
                              disabled: !_vm.versicherung.beginn
                            },
                            on: {
                              input: function($event) {
                                _vm.versicherung.laufzeit = ""
                              }
                            },
                            model: {
                              value: _vm.versicherung.anzeigeModusDauer,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.versicherung,
                                  "anzeigeModusDauer",
                                  $$v
                                )
                              },
                              expression: "versicherung.anzeigeModusDauer"
                            }
                          }),
                          _c("DatePickerField", {
                            attrs: {
                              label: "Vertragsende",
                              disabled:
                                !_vm.versicherung.beginn ||
                                _vm.versicherung.anzeigeModusDauer ===
                                  "LAUFZEIT"
                            },
                            on: {
                              input: function($event) {
                                return _vm.laufzeitChanged()
                              }
                            },
                            model: {
                              value: _vm.versicherung.ende,
                              callback: function($$v) {
                                _vm.$set(_vm.versicherung, "ende", $$v)
                              },
                              expression: "versicherung.ende"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isFPPlus
                    ? _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _c("InputRadioBoxGroup", {
                            staticClass: "align-end",
                            staticStyle: { "flex-grow": "0" },
                            attrs: {
                              values: [{ value: "LAUFZEIT", label: "" }],
                              disabled: !_vm.versicherung.beginn
                            },
                            on: {
                              input: function($event) {
                                return _vm.updateEndDate()
                              }
                            },
                            model: {
                              value: _vm.versicherung.anzeigeModusDauer,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.versicherung,
                                  "anzeigeModusDauer",
                                  $$v
                                )
                              },
                              expression: "versicherung.anzeigeModusDauer"
                            }
                          }),
                          _c("InputField", {
                            attrs: {
                              label: "Laufzeit (Jahre)",
                              type: "number",
                              isInteger: "",
                              disabled:
                                !_vm.versicherung.beginn ||
                                _vm.versicherung.anzeigeModusDauer === "ENDE",
                              validateUntouched: _vm.isFPPlus
                            },
                            on: {
                              input: function($event) {
                                return _vm.dataChanged("laufzeit", $event)
                              }
                            },
                            model: {
                              value: _vm.versicherung.laufzeit,
                              callback: function($$v) {
                                _vm.$set(_vm.versicherung, "laufzeit", $$v)
                              },
                              expression: "versicherung.laufzeit"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _c(
                "GhostLoading",
                [
                  _c("Block", { attrs: { height: "40" } }),
                  _c("Block", { attrs: { height: "40" } }),
                  _c("Block", { attrs: { height: "40" } }),
                  _c("Block", { attrs: { height: "40" } })
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }