var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseToolbarButton", {
    directives: [
      {
        name: "tippy",
        rawName: "v-tippy",
        value: { arrow: true },
        expression: "{ arrow: true }"
      }
    ],
    attrs: {
      isClear: "",
      disabled: _vm.disabled,
      active: _vm.value,
      icon: _vm.icon,
      title: "Platzhalter",
      showLabel: false,
      content: "Platzhalter"
    },
    on: {
      click: function($event) {
        return _vm.$emit("input", !_vm.value)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }