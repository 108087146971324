var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vertical-stepper__container" },
    [
      _c(
        "div",
        { staticClass: "vertical-stepper__list" },
        _vm._l(_vm.steps, function(step, index) {
          return _c(
            "div",
            { key: index, staticClass: "vertical-stepper__item-container" },
            [
              _c("StepperItem", {
                attrs: {
                  step: step,
                  currentStep: _vm.currentStep,
                  stepsLoading: _vm.stepsLoading
                }
              }),
              index !== _vm.lastStepIndex
                ? _c(
                    "div",
                    { staticClass: "vertical-stepper__pipe-container" },
                    [_c("span", { staticClass: "vertical-stepper__pipe" })]
                  )
                : _vm._e()
            ],
            1
          )
        }),
        0
      ),
      _c("BaseDropdownMenu", {
        staticClass: "vertical-stepper__dropdown",
        attrs: {
          containerClass: "vertical-stepper__dropdown-container",
          placement: "bottom-right"
        },
        scopedSlots: _vm._u([
          {
            key: "hook-target",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "vertical-stepper__dropdown-toogle" },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.current.label))]),
                    _c(
                      "span",
                      [_c("ph-caret-down", { attrs: { size: 24 } })],
                      1
                    )
                  ]
                )
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return _vm._l(_vm.steps, function(step, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "vertical-stepper__item-container"
                  },
                  [
                    _c("StepperItem", {
                      attrs: {
                        step: step,
                        currentStep: _vm.currentStep,
                        stepsLoading: _vm.stepsLoading
                      }
                    })
                  ],
                  1
                )
              })
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }