<template>
  <div>
    <BaseModal ref="modal" size="sm" :esc="false" 
      :showCloseButton="false" :showDefaultButtons="false" 
      @closed="handleClosedModal()">
      <template #modalTitle>Neue Pin anlegen</template>
      <template #default>
        <InputField label="Internet PIN" 
          v-model="pin.kundenzugangPin"
          :disabled="true">
        </InputField>

        <div>Neue Pin dem Kunden jetzt zuschicken?</div>
      </template>
      <template #footer>
        <BaseButton isSecondary @click="resetPinEmail(false)" class="mr-16px">Nein</BaseButton>
        <BaseButton @click="resetPinEmail(true)">Ja</BaseButton>
      </template>
    </BaseModal>

    <BaseModal ref="resultMessageModal" size="sm"
      :showCancelButton="false" labelButtonConfirm="Ok"
      @close="resultMessage = null">
      <template #modalTitle>Information</template>
      <template #default>{{ resultMessage }}</template>
    </BaseModal>
  </div>
</template>

<script>
import CUSTOMERDATA_TYPES from '@/store/customerData/types.js';

import BaseModal from '@/components/core/BaseModal.vue';
import InputField from '@/components/core/forms/InputField.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';


export default {
  props: {
    pin: {
      type: Object,
    }
  },
  data() {
    return {
      resultMessage: '',
    };
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    handleClosedModal() {
      if(this.resultMessage) {
        this.openResultMessageModal();
      }
    },
    openResultMessageModal() {
      this.$refs.resultMessageModal.open();
    },
    resetPinEmail(sendEmail) {
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.RESET_PIN_EMAIL, { sendEmail, })
        .then((response) => {
          this.resultMessage = response;
          this.close();
        });
    },
  },
  components: {
    BaseModal,
    InputField,
    BaseButton,
    AnimatedSpinner,
  },
}
</script>
