<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation :id="$appNavigation.currentOptionMenuId" title="Einschaten/Auschalten neue Chatsabfrage"
    />
    
    
    <BoxContainer>
        <InputToggleSwitch :value="RESULT" label="Einschaten/Auschalten neue Chatsabfrage "  @change="onChange($event)" inLineLabel />

    
    </BoxContainer>

  </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { mapGetters } from "vuex";
import Table from "@/components/table2/Table.vue";
import TYPES from "@/store/chattestquery/types";
import BaseModal from '@/components/core/BaseModal.vue';
import InputField from "@/components/core/forms/InputField.vue"
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import { uploadFileChunked } from "@/helpers/upload-helper";
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import BaseButton from "@/components/core/BaseButton.vue";
import BoxContainer from '@/components/core/BoxContainer.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import {
  TextColumn,
  ActionColumn,
  SimpleAction,

} from "@/components/table2/table_util.js";

import { PageHeaderSimpleAction, PageHeaderFileSelectAction } from '@/components/core/header-title-navigation/page-header-utils';




export default {
  components: {

    OptionMenu,
    InputField,
    PageHeaderTitleNavigation,
    Table,
    InputField,
    BaseModal,
    GhostLoading,
    NoData,
    ComboBox,
    BaseButton,
    InputTextArea,

    BoxContainer,
    InputToggleSwitch

  },
  data() {
    return {
      newChatOn:false,   
      company: 'ALLGEMEIN',
      FILE: null,
      worked: false,
    };
  },
  computed: {
    ...mapGetters({
      
      RESULT: TYPES.GETTERS.RESULT
    }),



  },

  mounted() {
     this.$store.dispatch(TYPES.ACTIONS.GET_RESULT);
     this.newChatOn=this.$store.getters[TYPES.GETTERS.RESULT];
  },

  methods: {
    async onChange( value){
      //alert(value);
      this.$store.dispatch(TYPES.ACTIONS.SET_RESULT,value);
    },

    async onFileChange(files) {
      try {
        this.worked = true;
        this.FILE = null;
        this.$store.commit(TYPES.MUTATIONS.RESULT, null);
        let file = files[0];

        let idd = await uploadFileChunked(file);
        this.FILE = { id: idd, name: file.name }
      } finally {
        this.worked = false;
      }

    },
    async upload() {
      try {
        this.worked = true;
        let confirm = this.RESULT != null && this.RESULT.warning != null
        let input = { confirmed: confirm, gesellschaft: this.company, tempfileId: this.FILE.id };
        await this.$store.dispatch(TYPES.ACTIONS.UPLOAD, input);
      } finally {
        this.worked = false;
      }
    }
  }
}


</script>
<style scoped>
.positionDivider {
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
}
</style>