export const MUTATION_PREFIX = 'DATENSCHUTZSCHULUNG_MUTATIONS_';
export const ACTIONS_PREFIX = 'DATENSCHUTZSCHULUNG_ACTIONS_';
export const GETTERS_PREFIX = 'DATENSCHUTZSCHULUNG_GETTERS_';

export default {
  MUTATIONS: {
    GET_LAYOUT_METADATA_SUCCESS: MUTATION_PREFIX + 'GET_LAYOUT_METADATA_SUCCESS',
  },
  ACTIONS: {
    GET_LAYOUT_METADATA: ACTIONS_PREFIX + 'GET_LAYOUT_METADATA',
  },
  GETTERS: {
    LAYOUT_METADATA: GETTERS_PREFIX + 'LAYOUT_METADATA',

  }
}