<template>
  <BaseModal ref="modal" 
    modalTitle="Speicher-Optionen" 
    :showDefaultButtons="false"
    @onEscButton="saveConfig()"
    @onCloseButton="saveConfig()"
  >
    <template #default>
      <UserLevelCombo
        :current="userLevelInternal"
        v-model="userLevelInternal"
      />
    </template>
    <template #footer>
      <BaseButton isSecondary @click="restoreDefault(); close()" class="mr-3">Zurücksetzen</BaseButton>
      <BaseButton @click="saveConfig(); close()">Schließen</BaseButton>
    </template>
  </BaseModal>
</template>

<script>
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';
import FC_CONFIG_TYPES from '@/store/fcConfig/types';

import BaseModal from '@/components/core/BaseModal.vue';
import UserLevelCombo from '@/components/core/fcConfig/UserLevelCombo.vue';
import BaseButton from '@/components/core/BaseButton.vue';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    userLevel: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      userLevelInternal: null,
    };
  },
  computed: {
    ...mapGetters({
      fcConfigDashboardPanelConfig: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_DASHBOARD_PANEL,
    }),
    dashboardPanelConfig() {
      return this.id in this.fcConfigDashboardPanelConfig && this.fcConfigDashboardPanelConfig[this.id] || null;
    },
    currentUserLevel() {
      return this.dashboardPanelConfig?.userLevel;
    },
  },
  watch: {
    userLevelInternal: {
      handler() {
        this.$emit('userLevelChanged', this.userLevelInternal);
      },
      immediate: true,
    },
  },
  methods: {
    init() {
      this.userLevelInternal = this.userLevel || this.currentUserLevel;
    },
    open() {
      this.init();
      this.$refs.modal?.open();
    },
    close() {
      this.$refs.modal?.close();
    },
    saveConfig() {
      this.$emit('saveConfig', this.userLevelInternal);
    },
    restoreDefault() {
      this.$emit('onRestoreDefault', this.userLevelInternal);
    },
  },
  components: {
    BaseModal,
    UserLevelCombo,
    BaseButton,
  },
}
</script>
