<template>
  <li v-if="visible" class="sortable-list-item__container list-bordered-item" data-sortable-item>
    <div class="sortable-list-item__actions">
      <button v-if="removable" type="button" @click="confirmRemove()" class="btn-clear clickable btn-action">
        <PhProhibitInset :size="18" weight="fill" class="color-danger rotate-prohibit-inset" />
      </button>
    </div>
    <span class="sortable-list-item__label">{{ title }}</span>
    <div class="sortable-list-item__actions">
      <transition-group name="slide">
        <button type="button" class="btn-clear clickable btn-action" data-sortable-item-handler :key="1">
          <PhList :size="18" />
        </button>
        <button type="button"
          v-if="showConfirmRemove"
          @click="remove()"
          class="ml-2 clickable confirm-delete-button"
          :key="2">
          Löschen
        </button>
      </transition-group>
    </div>
  </li>
</template>

<script>
import { PhList, PhProhibitInset, } from 'phosphor-vue';

export default {
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    removable: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
    showConfirmRemove: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    remove() {
      this.$emit('onRemove');
    },
    confirmRemove() {
      this.$emit('onConfirmRemove');
    }
  },
  components: {
    PhList, 
    PhProhibitInset,
  }
}

</script>

<style scoped>
.btn-action {
  min-width: max-content;
}
.sortable-list-item__container {
  padding: 0;
}
.sortable-list-item__actions,
.sortable-list-item__actions > span {
  margin: 0 8px;
  display: flex;
}
.sortable-list-item__label {
  flex: 1 1 auto;
  word-break: break-word;
  padding: 8px;
}
.sortable-list-item__container {
  display: flex;
  justify-content: space-between;
}

.rotate-prohibit-inset {
  transform: rotate(-45deg);
}

.slide-enter-active, .slide-leave-active {
  transition: transform 0.3s;
}

.slide-enter, .slide-leave-to {
  transform: translateX(100%); /* Começa na direita e desliza para a esquerda */
}

.confirm-delete-button {
  background-color: var(--color-danger);
  color: var(--color-danger-background);
  border: none;
  cursor: pointer;
  height: 100%;
}

@media screen and (max-width: 767px) {
  .sortable-list-item__label {
    max-width: 75%;
  }
}

</style>