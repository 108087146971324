var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.$appNavigation.currentMenu.parent.label,
          subtitle: _vm.$appNavigation.currentMenu.label,
          defaultMenu: _vm.$appNavigation.currentTabMenu
        }
      }),
      _vm.gesellschaften && _vm.gesellschaften.length
        ? _c("BaseFilter", {
            attrs: {
              filterId: "553dc811-be51-4b6a-87d4-dd44a7bb2456",
              title: "Vermittlernummern filtern",
              metadata: _vm.filterMetadata,
              defaultOptions: _vm.defaultOptionsData
            },
            on: {
              onFilter: function($event) {
                return _vm.filterForm($event)
              }
            }
          })
        : !_vm.gesellschaften || !_vm.gesellschaften.length
        ? _c("GhostLoading", {
            attrs: {
              type: "block",
              config: { block: { height: 100 } },
              useBoxContainer: ""
            }
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "box__container",
          attrs: { tid: "3c197cb4-0331-425e-9a45-454239452fa8" }
        },
        [
          !_vm.loading && _vm.tableRows.length
            ? _c("p", [
                _vm._v(
                  "Wir bitten Sie, alle Anträge über unser Haus einzureichen."
                )
              ])
            : _vm._e(),
          !_vm.loading && _vm.tableRows.length
            ? _c("p", [
                _vm._v(
                  "Bei den Gesellschaften, für die noch keine Vermittlernummer vergeben wurde, erhalten Sie diese nach Einreichen des Erstantrags."
                )
              ])
            : _vm._e(),
          !_vm.loading && _vm.tableRows.length
            ? _c("Table", {
                staticClass: "table-container",
                attrs: {
                  tableId: "f075f490-577a-4b51-898d-787c1606a398",
                  title: _vm.$appNavigation.currentMenu.label,
                  headers: _vm.tableHeaders,
                  headerActions: _vm.headerActions,
                  rows: _vm.tableRows,
                  rowsPerPage: 20,
                  count: _vm.resultCount,
                  exportConfig: _vm.exportConfig
                },
                on: {
                  "headerAction-VERMITTLERNUMMER_ERSTELLEN": function($event) {
                    return _vm.erstellen()
                  },
                  action: function($event) {
                    return _vm.executeAction($event)
                  }
                }
              })
            : _vm.loading
            ? _c("GhostLoading", {
                attrs: {
                  type: "table",
                  title: _vm.$appNavigation.currentMenu.label
                }
              })
            : _c("NoData", {
                attrs: { title: _vm.$appNavigation.currentMenu.label }
              })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "removeItemConfirmation",
          attrs: {
            modalTitle: "Maklernummer löschen",
            labelButtonConfirm: "Löschen"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.removeItem()
            }
          }
        },
        [
          _vm._v(
            " Soll die Maklernummer der Gesellschaft " +
              _vm._s(_vm.selectedFremdnr.gesellschaftId) +
              " wirklich gelöscht werden? "
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }