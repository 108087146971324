<template>
  <div>
    <PageHeaderTitleNavigation 
      :title="title" 
      :actions="headerActions"
      @action-HONORAR="speichernHonorargebuehren()"
      @action-ZURUCKSETZEN="zuruecksetzen()"
      v-on="$listeners"
    >
      <template #action-PDF>
        <DownloadLink 
        asButton
        title="PDF"
       downloadServicePath="/getStoppuhrFormular"
        :queryParams="{
            id: stoppuhrForm.id
          }"
          />          

      </template>
    </PageHeaderTitleNavigation>

    <div class="box__container" v-if="stoppuhr && stoppuhr.length">

      <StoppuhrWatch />

      <InputField
        label="Name"
        v-model="name"
        disabled
        isComponentHalfSize
      />

      <ComboBox
        label="Kategorie"
        v-model="form.kategorie"
        isComponentHalfSize
        :values="stoppuhr"
        @input="processFormChange('kategorie', $event)"/>

      <InputField
        label="Thema"
        v-model="form.betreff"
        isComponentHalfSize
        @change="processFormChange('betreff', $event)"/>

      <InputTextArea 
        v-model="form.beschreibung" 
        label="Beschreibung" 
        @change="processFormChange('beschreibung', $event)"
        isComponentHalfSize />

    </div>

  </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import CORE_TYPES from "@/store/core/types";
import { mapGetters } from "vuex";
import STOPPUHR_TYPES from "@/store/stoppuhr/types";
import InputField from "@/components/core/forms/InputField.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import StoppuhrWatch from '@/views/communication/stoppuhr/StoppuhrWatch.vue';
import stoppurShared from "@/views/communication/stoppuhr/stoppuhr-mixing.js";
import { PageHeaderSimpleAction, PageHeaderSlotAction} from '@/components/core/header-title-navigation/page-header-utils';
import DownloadLink from '@/components/core/download/DownloadLink.vue'
import { required } from '@/mixins/validator/rules';
import validator from '@/mixins/validator'



export default {
  mixins: [stoppurShared, validator],
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    InputField,
    ComboBox,
    InputTextArea,
    StoppuhrWatch,
    DownloadLink,
  },
  data() {
    return {
      form: {
          id: null,
          startzeit: null,
          laufzeit: 0,
          name: null,
          kategorie: "Keine Angabe",
          thema: null,
          betreff: null,
          beschreibung: null,
          timeLapsed: null,
        },
    }
  },
  validators: {
    form : {
      kategorie : [required('Bitte Kategorie erfassen', {forceTouch: true})],
      betreff : [required('Bitte Thema erfassen', {forceTouch: true})],
      beschreibung : [required('Bitte Beschreibung erfassen', {forceTouch: true})],
    }
  },  
  computed: {
    ...mapGetters({
      isFA: CORE_TYPES.GETTERS.IS_FA,
      stoppuhrForm: STOPPUHR_TYPES.GETTERS.STOPUHR_FORM,
    }),
    headerActions() {
      const actions = [];

      if (!this.isFA) {
        actions.push(PageHeaderSimpleAction('HONORAR', 'Speichern Honorargebühren').withDisabled(() => this.validation?.invalid));
      }

      if (this.stoppuhrForm?.id) {
        actions.push(PageHeaderSimpleAction('ZURUCKSETZEN', 'Neues Gespräch'));
        actions.push(PageHeaderSlotAction('PDF'));     
      }
      
      return actions;
    },
    title() {
      return this.isBroker ? "Vermittler Stoppuhr" : this.isCustomer ? "Kunden Stoppuhr" : "Stoppuhr";
    }
  },
  mounted: function () {
    this.$store.dispatch(STOPPUHR_TYPES.ACTIONS.INIT_STOPPUHR);  

    if (this.stoppuhrForm?.id) {
      Object.assign(this.form, this.stoppuhrForm);
    }

    
  },
  methods: {
    async zuruecksetzen() {

      this.form = {
              id: null,
              startzeit: null,
              laufzeit: 0,
              kategorie: "Keine Angabe",
              thema: null,
              betreff: null,
              beschreibung: null,
              timeLapsed: null,
              name: this.name,
            };

      this.timer =  {
              startTime: 0,
              timeLapsed: '00:00:00',
              intervalId: null,
              hour: "00",
              minutes: "00",
              seconds: "00",
              running: false,
            };

      this.resetStoppuhr();
    },
    async speichernHonorargebuehren() {
      if(this.validation.valid) {
        await this.saveForm(true);
        await this.zuruecksetzen();
      }

    },
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu Stoppuhr', 
      to,
      from,
      breadcrumb: "Stoppuhr"
    });

    if (this.form?.kategorie && this.form?.betreff && this.form?.beschreibung) {
      this.saveForm();
    }
    

    next();
  },
}
</script>

<style scoped>

</style>