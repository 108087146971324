var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "section", class: { collapsed: !_vm.mutatedSection } },
    [
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showEditButton,
              expression: "showEditButton"
            }
          ],
          staticClass: "btn bearbeiten",
          staticStyle: { "background-color": "transparent" },
          on: {
            click: function($event) {
              return _vm.onEditItem()
            }
          }
        },
        [_c("ph-pencil-line", { attrs: { size: 16 } })],
        1
      ),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showDeleteButton,
              expression: "showDeleteButton"
            }
          ],
          staticClass: "btn bearbeiten",
          staticStyle: { "background-color": "transparent" },
          on: {
            click: function($event) {
              return _vm.onDeleteItem()
            }
          }
        },
        [_c("ph-trash", { attrs: { size: 16 } })],
        1
      ),
      _c("div", { staticClass: "title", on: { click: _vm.toggle } }, [
        _c("div", { staticClass: "collapsable-title" }, [_vm._t("title")], 2),
        _vm._v("   "),
        _c(
          "div",
          { staticClass: "collapsable-toggle-icon" },
          [
            _c("ph-caret-down", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.mutatedSection,
                  expression: "!mutatedSection"
                }
              ],
              attrs: { size: 16, weight: "bold" }
            }),
            _c("ph-caret-up", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.mutatedSection,
                  expression: "mutatedSection"
                }
              ],
              attrs: { size: 16, weight: "bold" }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.mutatedSection,
              expression: "mutatedSection"
            }
          ],
          staticClass: "body"
        },
        [_vm._t("content")],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }