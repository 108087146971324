<template>

  <SignoViewerLink
      :asButton="!isShowAsLink"
      :title="label"
      requestURL
      isDeepLink
      extraParams="noRedirect=true"
      :href="servicePath" 
      :disabled="disabled"
    />  
</template>
<script>
import actionMixin from './header-actions-mixin.js';
import SignoViewerLink from '@/components/core/download/SignoViewerLink.vue'

export default {
  mixins: [actionMixin],
  props: {
    servicePath: {
      type: String,
    },
    label: {
      type: String,
      default: 'Signieren'
    },    
    disabled: {
      type: Boolean,
      default: false
    },   
  },
  components: {
    SignoViewerLink,
  }
}
</script>
