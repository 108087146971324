<template>
  <BaseModal ref="modal"
    :isModalAsFullPage="false"
    labelButtonConfirm="Annehmen"
    labelButtonCancel="Ablehnen"
    @onConfirmButton="acceptCall"
    @onCancelButton="rejectCall"
    @onCloseButton="rejectCall">
    <template v-slot:modalTitle>
      Eingehender Anruf
    </template>
    <strong>
      {{displayName}} 
      <span v-if="kundennr">({{kundennr}})</span>
    </strong> ruft an.
    <div>Der Anruf wird mit aktivierter Kamera gestartet.</div>
    <div>Bitte beachten Sie, dass das Gespräch aufgezeichnet werden kann.</div>
  </BaseModal>
</template>

<script>
import { mapGetters } from 'vuex'
import WEBRTC_TYPES from '../../store/webrtc/types'
import BaseModal from '@/components/core/BaseModal.vue';
import BRIDGE_TYPES from '@/store/bridge/types'

export default {
  computed: {
    ...mapGetters({
      beteiligterInfos: WEBRTC_TYPES.GETTERS.BETEILIGTER_INFOS,
      inCallWith: WEBRTC_TYPES.GETTERS.IN_CALL_WITH,
      calling: WEBRTC_TYPES.GETTERS.WE_ARE_CALLING_ID,
      incomingCall: WEBRTC_TYPES.GETTERS.NEXT_INCOMING_CALL,
      incomingCallUUID: WEBRTC_TYPES.GETTERS.NEXT_INCOMING_CALL_UUID,
      mobileNativeContext: BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT,
      browserSimulation: BRIDGE_TYPES.GETTERS.IS_BROWSER_SIMULATION,
      isMobileNativeContextIOS: BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT_IOS
    }),
    kundennr() {
      return this.beteiligterInfos[this.incomingCall] ? this.beteiligterInfos[this.incomingCall].kundennr : null;
    },
    displayName() {
      return this.beteiligterInfos[this.incomingCall] ? this.beteiligterInfos[this.incomingCall].displayName : "";
    },
    showModalOnWebContext() {
      // MSC-25335 Temporarily enable the push socket on ios
      if (this.isMobileNativeContextIOS) {
        return true;
      }
      return !this.mobileNativeContext || (this.mobileNativeContext && this.browserSimulation)
    },
    visible() {
      // to trigger watch for "visible" each time a call is coming, the 'incomingCallUUID' must be at the last position
      return !this.inCallWith && !this.calling && this.showModalOnWebContext && this.incomingCallUUID;
    }
  },
  watch: {
    visible(newValue) {
      if (newValue) {
        this.$refs.modal.open();
      } else {
        this.$refs.modal.close();
      }
    }
  },
  methods: {
    acceptCall() {
      this.$store.dispatch(WEBRTC_TYPES.ACTIONS.ACCEPT_CALL);
      this.$refs.modal.close();
    },
    rejectCall() {
      this.$store.dispatch(WEBRTC_TYPES.ACTIONS.REJECT_CALL);
      this.$refs.modal.close();
    },
  },
  components: {
    BaseModal,
  },
}
</script>

<style scoped>

</style>
