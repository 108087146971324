var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.$appNavigation.currentMenu.parent.label,
          subtitle: _vm.$appNavigation.currentMenu.label,
          defaultMenu: _vm.$appNavigation.currentTabMenu,
          actions: _vm.actions
        },
        on: {
          "action-NEW": function($event) {
            return _vm.editLimit({ isin: "neu" })
          },
          "action-REMOVE_OVERTAKEN": function($event) {
            return _vm.$refs.confirmDeleteOvertaken.open()
          },
          "action-RESET": function($event) {
            return _vm.doConfirm("zurücksetzen")
          }
        }
      }),
      _c("div", { staticClass: "box__container" }, [
        _c("div", { staticClass: "box__title" }, [_vm._v("Wertpapierlimits")]),
        _vm._m(0),
        _c(
          "div",
          {
            staticClass:
              "layout__negative-margin--8 antrag-action-button__container"
          },
          [
            _c("InputToggleSwitch", {
              attrs: {
                label: "Limits der Untermakler anzeigen",
                inLineLabel: "",
                suppressValidationMessage: ""
              },
              on: {
                input: function($event) {
                  return _vm.reload()
                }
              },
              model: {
                value: _vm.includeStruktur,
                callback: function($$v) {
                  _vm.includeStruktur = $$v
                },
                expression: "includeStruktur"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          !_vm.loading && _vm.rows && _vm.rows.length
            ? _c(
                "div",
                [
                  _c("Table", {
                    attrs: {
                      tableId: "d5fcb6dc-1fd8-493b-86d7-82d1b7d5657c",
                      title: _vm.TABLE_TITLE,
                      headers: _vm.headers,
                      rows: _vm.rows,
                      rowsPerPage: 20,
                      exportConfig: { roottext: "Wertpapierlimits" },
                      mobileConfig: {
                        title: "wertpapierName",
                        headers: [
                          "isin",
                          "aktuellKurs",
                          "unteresLimit",
                          "oberesLimit"
                        ]
                      },
                      rowId: "id"
                    },
                    on: {
                      "action-edit": _vm.editLimit,
                      "action-delete": function($event) {
                        _vm.selectedLimit = $event
                        _vm.$refs.confirmDelete.open()
                      },
                      "action-factsheet": function($event) {
                        return _vm.viewDocument($event, "FACTSHEET")
                      },
                      "action-kag": function($event) {
                        return _vm.viewDocument($event, "MR")
                      },
                      "action-kid": function($event) {
                        return _vm.viewDocument($event, "KIID")
                      },
                      "action-fondsinfo": function($event) {
                        return _vm.gotoFondsinfo($event && $event.isin)
                      }
                    }
                  })
                ],
                1
              )
            : _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", title: _vm.TABLE_TITLE }
              })
            : _c("NoData", { attrs: { title: _vm.TABLE_TITLE } })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "confirmDelete",
          attrs: { labelButtonConfirm: "Ja", labelButtonCancel: "Nein" },
          on: {
            onCancelButton: function($event) {
              _vm.selectedLimit = null
            },
            onCloseButton: function($event) {
              _vm.selectedLimit = null
            },
            onConfirmButton: function($event) {
              return _vm.removeLimit()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("PhWarning", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v("Löschen ")
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", [
            _vm._v(
              "Wollen Sie wirklich das Limit für das Wertpapier " +
                _vm._s(_vm.selectedLimit && _vm.selectedLimit.wertpapierName) +
                " löschen?"
            )
          ])
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "confirmDeleteOvertaken",
          attrs: { labelButtonConfirm: "Ja", labelButtonCancel: "Nein" },
          on: {
            onConfirmButton: function($event) {
              return _vm.removeOvertaken()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("PhWarning", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v("Löschen ")
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", [
            _vm._v("Wollen Sie wirklich alle überschrittene Limits löschen?")
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v(
        " Hier können Sie ein globales Limit für die von Ihnen gewählten Fonds über "
      ),
      _c("b", [_vm._v("alle Kunden")]),
      _vm._v(
        ", die diese Fonds haben, setzen. Wird ein Limit erreicht, so werden Sie und auf Wunsch auch Ihre Kunden, per Mail benachrichtigt. "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }