<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

    <PageHeaderTitleNavigation :title="$appNavigation.currentMenu.label" :id="$appNavigation.currentOptionMenuId"
      v-on="$listeners" :actions="headerActions" @action-SAVE="save()" />

    <div class="box__container">
      <InputToggleSwitch v-model="absence_note.enabled" label="Automatische Antworten aktivieren" inLineLabel
        suppressValidationMessage />
      <div class="flex gap-4 my-4">
        <div class="flex">
          <DatePickerField :disabled="disabled" label="Von" v-model="absence_note.from" isValueAsString emptyValueAsNull
            validateUntouched />
          <TimePicker :disabled="disabled" label="Uhrzeit" v-model="absence_note.from_time" />
        </div>
        <div class="flex">
          <DatePickerField :disabled="disabled" label="Bis" v-model="absence_note.to" isValueAsString emptyValueAsNull
            validateUntouched />
          <TimePicker :disabled="disabled" label="Uhrzeit" v-model="absence_note.to_time" />
        </div>

      </div>
      <div class="my-4">Automatische Antwort</div>
      <HtmlEditor :disabled="disabled" v-model="absence_note.message" />
    </div>
  </div>
</template>

<script>
import NoData from '@/components/core/NoData.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import { mapGetters } from 'vuex'
import Table from "@/components/table2/Table.vue";
import FreigabeEditModal from '@/views/templatesEdit/FreigabeEditModal.vue';
import InputChips from '@/components/core/input-chips/InputChips.vue';
import AddVorlagenTagsModal from '@/components/vorlagen-tags/AddVorlagenTagsModal.vue';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import BaseFilter from '@/components/core/BaseFilter.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import InputField from '@/components/core/forms/InputField.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import TimePicker from '@/components/core/forms/TimePicker.vue';
import HtmlEditor from '@/components/html-editor/HtmlEditor.vue';
import COMMUNICATION_TYPES from '@/store/communication/types';
import LOG_TYPES from '@/store/log/types';
import { buildMessage } from '../../helpers/log-message-helper';


export default {
  components: {
    GhostLoading,
    NoData,
    Table,
    FreigabeEditModal,
    InputChips,
    AddVorlagenTagsModal,
    BaseFilter,
    ComboBox,
    PageHeaderTitleNavigation,
    OptionMenu,
    InputField,
    InputToggleSwitch,
    DatePickerField,
    TimePicker,
    HtmlEditor,
  },
  data() {
    return {
      pageLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      absence_note: COMMUNICATION_TYPES.GETTERS.ABSENCE_NOTE,
    }),
    headerActions() {
      const actions = [];
      actions.push(PageHeaderSimpleAction('SAVE', 'Speichern').withDisabled(() => this.pageLoading));
      return actions;
    },
    disabled() {
      return !this.absence_note?.enabled
    }
  },
  methods: {
    async onRefresh() {
      this.pageLoading = true

      try {
        await this.$store.dispatch(COMMUNICATION_TYPES.ACTIONS.LOAD_ABSENCE_NOTE);
      } catch (error) {
        console.error(error)
      } finally {
        this.pageLoading = false
      }
    },
    async save() {
      this.pageLoading = true

      try {
        await this.$store.dispatch(COMMUNICATION_TYPES.ACTIONS.SAVE_ABSENCE_NOTE);
        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Die Abwesenheitsnotiz wurde erfolgreich gespeichert.', 'info'));
      } catch (error) {
        console.error(error)
      } finally {
        this.pageLoading = false
      }
    }
  },
  mounted() {
    this.onRefresh()
  },
}
</script>

<style scoped>
.top-buttons {
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
}

.flex {
  display: flex;
  gap: 1rem;
}

.gap-4 {
  gap: 4rem;
}

.my-2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.my-4 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
</style>
