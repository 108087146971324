<template>
  <node-view-wrapper as="div" class="node-view-wrapper">
    <div class="image-container">
      <img
        ref="resizableImg"
        v-bind="node.attrs"
        :draggable="isDraggable"
        :data-drag-handle="isDraggable"
      />
      <span class="resize-icon image-size-controls" 
        @mousedown="onMouseDown">
      </span>
    </div>
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2';

export default {
  components: {
    NodeViewWrapper,
  },
  props: nodeViewProps,
  data() {
    return {
      isResizing: false,
      lastMovement: {},
      count: 0,
    }
  },
  computed: {
    isDraggable() {
      return this.node?.attrs?.isDraggable;
    },
  },
  methods: {
    onMouseDown(e) {
      e.preventDefault();
      this.isResizing = true;
      window.addEventListener('mousemove', this.throttle(this.onMouseMove, 30));
      window.addEventListener('mouseup', this.onMouseUp);
    },
    onMouseUp() {
      this.isResizing = false;
      this.lastMovement = {};
      window.removeEventListener('mousemove', this.throttle(this.onMouseMove, 30));
      window.removeEventListener('mouseup', this.onMouseUp);
    },
    throttle(callback, limit) {
      let wait = false;
      return function ($event) {
        if (!wait) {
          wait = true;
          setTimeout(function () {
            callback.call(this, $event);
            wait = false;
          }, limit);
        }
      }
    },
    onMouseMove(e) {
      e.preventDefault();
      if (!this.isResizing) {
        return;
      }
      if (!Object.keys(this.lastMovement).length) {
        this.lastMovement = { x: e.x, y: e.y };
        return;
      }
      if (e.x === this.lastMovement.x && e.y === this.lastMovement.y) {
        return;
      }
      let nextX = e.x - this.lastMovement.x;
      let nextY = e.y - this.lastMovement.y;
      const width = this.$refs.resizableImg.width + nextX;
      const height = this.$refs.resizableImg.height + nextY;
      this.lastMovement = { x: e.x, y: e.y };

      this.updateAttributes({ width, height });
    },
  },
}
</script>

<style scoped>
.image-container:hover > .image-size-controls {
  visibility: visible;
}

.node-view-wrapper{
  display: inline-block;
}

.image-container {
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  padding-right: 10px;
  display: flex;
  align-items: stretch;
  width: fit-content;
}

.image-container > img {
  outline: 1px dashed black;
  margin: 1px;
}

.image-container:hover > img {
  outline: 1px dashed var(--color-primary);
}

.image-size-controls {
  visibility: hidden;
}

.resize-icon {
  cursor: w-resize;
  border: 2px solid var(--color-warning);
  margin-left: 0px;
  border-radius: 2px;
  box-sizing: border-box;
}

.resize-icon:hover {
  border: 2px solid var(--color-primary);
}

.image-size-modal-button {
  position: absolute;
  top: 10px;
  left: 10px;
  background: var(--color-workspaces-nav-background);
  color: var(--color-workspaces-nav-text);
  border-radius: 5px;
  padding: 1px;
  cursor: pointer;
}
</style>