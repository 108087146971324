var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "confirmVorlage",
          attrs: { labelButtonConfirm: "Ja", labelButtonCancel: "Nein" },
          on: {
            onConfirmButton: function($event) {
              return _vm.removeVorlage()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-warning", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v(" Änderung der Vorlage zur Unterschrift ")
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", [
            _c("p", [
              _vm._v(
                ' Soll das Dokument nicht mehr in der "Offene Unterschriften" Liste (weder beim Vermittler noch beim Kunden) angezeigt werden? '
              )
            ]),
            _c("p", [
              _vm._v(
                " Sollten bereits Unterschriften getätigt worden sein, so werden diese im Dokumentenarchiv gespeichert. Der Abschluss des Dokumentes ist erforderlich, wenn eine Weiterleitung erfolgen soll. "
              )
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }