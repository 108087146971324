var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        width: "751.13137",
        height: "401.78485",
        viewBox: "0 0 751.13137 401.78485",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M282.11788,650.08478l-.1333-.71387a83.88193,83.88193,0,0,1,17.75391-68.25586,81.38534,81.38534,0,0,1,14.95459-13.89648l.52881-.38184.23144.60938c2.75977,7.27636,6.24316,14.56347,7.83057,17.78906l1.07129-24.07813.61132-.32422a15.90844,15.90844,0,0,1,17.7334,1.83008,16.18094,16.18094,0,0,1,5.34326,17.32617c-.83252,2.82911-1.61865,5.76368-2.37939,8.60157-2.61279,9.75195-5.31445,19.83593-10.55029,28.32715a50.70223,50.70223,0,0,1-36.89942,23.3125Z",
          transform: "translate(-223.9704 -248.96033)",
          fill: "#f2f2f2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M514.79043,347.35093H275.82022a6.305,6.305,0,0,1-6.29786-6.29785V278.08775a6.305,6.305,0,0,1,6.29786-6.29785H514.79043a6.305,6.305,0,0,1,6.29785,6.29785v62.96533A6.305,6.305,0,0,1,514.79043,347.35093Z",
          transform: "translate(-223.9704 -248.96033)",
          fill: "#f2f2f2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M561.79043,454.35093H322.82022a6.305,6.305,0,0,1-6.29786-6.29785V385.08775a6.305,6.305,0,0,1,6.29786-6.29785H561.79043a6.305,6.305,0,0,1,6.29785,6.29785v62.96533A6.305,6.305,0,0,1,561.79043,454.35093Z",
          transform: "translate(-223.9704 -248.96033)",
          fill: "#f2f2f2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M968.81834,464.70045H902.36045l15.3573-99.05469a3.4998,3.4998,0,1,0-6.917-1.07226L895.2772,464.70045H806.61729l15.3573-99.05469a3.4998,3.4998,0,1,0-6.917-1.07226L799.534,464.70045H743.28758a3.5,3.5,0,0,0,0,7h55.16119l-13.43677,86.667H721.50437a3.5,3.5,0,0,0,0,7h62.42237L771.49168,645.5735a3.4998,3.4998,0,1,0,6.917,1.07226L791.01,565.36744H879.6699L867.23484,645.5735a3.49981,3.49981,0,1,0,6.917,1.07226l12.60131-81.27832h60.282a3.5,3.5,0,0,0,0-7H887.83842l13.43671-86.667h67.54321a3.5,3.5,0,0,0,0-7Zm-88.06317,93.667H792.09526l13.4367-86.667h88.66Z",
          transform: "translate(-223.9704 -248.96033)",
          fill: "#e6e6e6"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "481.414 378.041 469.966 378.041 464.52 333.882 481.416 333.883 481.414 378.041",
          fill: "#9e616a"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M711.54368,648.73961H703.42l-1.45-7.66971-3.71377,7.66971H676.71028a4.84333,4.84333,0,0,1-2.75214-8.82893l17.20593-11.883v-7.75382l18.09765,1.08019Z",
          transform: "translate(-223.9704 -248.96033)",
          fill: "#2f2e41"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "483.721 121.423 482.599 126.768 483.07 150.149 522.07 155.149 522.9 118.467 518.834 111.785 483.721 121.423",
          fill: "#9e616a"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "539.354 378.041 527.905 378.041 522.459 333.882 539.356 333.883 539.354 378.041",
          fill: "#9e616a"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M763.7595,589.02731l-17.38,1.7351-.098-.75346-9.21853-46.76327-2.15267-5.66445L721.4101,473.51392l-7,16s.53554,16.94783-.92492,18.682c0,0-2.31626,16.38644-5.668,17.00391l0,0a120.512,120.512,0,0,1,1.24609,56.9829l-1.87588,8.51359-15.852.22033c-5.17015-.813-3.18222-28.31749-9.96877-53.75114-.8555-3.20612,4.38451-128.31956,25.593-141.02673l39.41762.96463.533.55412c.19343.20019,14.49983,21.8564,13.49983,44.8564l-2,47,.89837,7.94615,3.2991,21.442,2.6425,9.9595,0,0a114.42625,114.42625,0,0,1,1.37277,40.25722Z",
          transform: "translate(-223.9704 -248.96033)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M769.48292,648.73961h-8.12368l-1.45-7.66971-3.71376,7.66971H734.64951a4.84333,4.84333,0,0,1-2.75214-8.82893l17.20594-11.883v-7.75382L767.201,621.354Z",
          transform: "translate(-223.9704 -248.96033)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          id: "a535f923-d253-4cd0-8492-9156d4611884-74",
          "data-name": "Path 9",
          d:
            "M725.498,289.22009l-.0159-.00417a3.2951,3.2951,0,0,1-2.34245-4.02845l4.69331-17.77994a24.57462,24.57462,0,0,1,4.32226-8.77839,24.971,24.971,0,0,1,16.05187-9.40324,24.58883,24.58883,0,0,1,9.84249.53895c13.13579,3.44537,16.8047,7.78981,17.54606,30.02285s-4.70046,17.807-4.70046,17.807a3.30049,3.30049,0,0,1-.58167,1.18081,3.35457,3.35457,0,0,1-2.16068,1.26156,3.31134,3.31134,0,0,1-1.31595-.07244c-16.40382-1.68861-33.01683-3.22624-41.34153-10.74527Z",
          transform: "translate(-223.9704 -248.96033)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          id: "b0ed392d-e28e-41ab-913e-3eea91058667-75",
          "data-name": "Ellipse 1",
          d:
            "M764.06616,270.44924a18.12654,18.12654,0,0,1-26.93033,19.89645,17.91537,17.91537,0,0,1-8.21607-11.09792,18.12654,18.12654,0,0,1,26.91471-19.89351A17.91536,17.91536,0,0,1,764.066,270.4499Z",
          transform: "translate(-223.9704 -248.96033)",
          fill: "#9e616a"
        }
      }),
      _c("path", {
        attrs: {
          id: "f63de286-de7c-4407-a1c4-23ed0a8d5112-76",
          "data-name": "Path 10",
          d:
            "M721.81173,267.36594a19.44009,19.44009,0,0,1,3.41858-6.94261c2.26343-5.64269,5.81392-10.20339,12.69315-7.43453a19.44868,19.44868,0,0,1,7.73511.41627l3.56334.92151.12.03135A19.40713,19.40713,0,0,1,763.13854,278.084l-.094.35613-7.53625-1.95681-.67387-7.849-2.41006,7.0498-3.89286-1.00921-.3393-3.95976-1.21594,3.55418c-7.38118,2.8677-15.93584.0227-25.25538-6.54572Z",
          transform: "translate(-223.9704 -248.96033)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          id: "fb48cedc-59e8-4080-98a2-7877a59d0df4-77",
          "data-name": "Path 13",
          d:
            "M739.10205,292.21178a3.24245,3.24245,0,0,0-.32219,1.75606,3.31623,3.31623,0,0,0,.2118.90048,3.35441,3.35441,0,0,0,.37292.69706,3.28955,3.28955,0,0,0,1.86843,1.29678q.07088.018.14244.03336t.144.02741q.07293.0129.14624.02165t.148.015l2.80014.21968,5.75565-6.89111-1.901,7.20184,12.72526,1.01968,8.06722-30.56154-20.39114-5.278.34912.71763a14.487,14.487,0,0,1,1.352,7.72978,23.61537,23.61537,0,0,1-2.19684,7.728,42.58589,42.58589,0,0,1-4.17068,7.01264,62.76389,62.76389,0,0,1-4.57491,5.59349A3.31125,3.31125,0,0,0,739.10205,292.21178Z",
          transform: "translate(-223.9704 -248.96033)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M651.77537,393.74a7.5677,7.5677,0,0,0,9.34334-6.8817l57.444-20.66568-10.54224-9.174-54.5225,21.70853A7.60869,7.60869,0,0,0,651.77537,393.74Z",
          transform: "translate(-223.9704 -248.96033)",
          fill: "#9e616a"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M734.61,314.99644s9.6749.21319,10.53506,11.25862-20.25032,38.3661-23.52107,42.87149-39.60073,10.05074-39.60073,10.05074l-12.35-8.96572c12.9769-8.40862,24.82-14.856,32.768-14.566Z",
          transform: "translate(-223.9704 -248.96033)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M751.72447,299.60271l-14.89572-.87951a3.024,3.024,0,0,0-2.97374,1.8656l-4.20948,10.20476-17.495,14.45985s-6.92672,8.81455-6.94284,20.19831-2.45755,37.7327-2.45755,37.7327,38.857,2.61348,39.69482,1.73913c1.645-1.71669,3.6406-10.48883,3.6406-10.48883l13.635-50.09793a18.55472,18.55472,0,0,0-2.63847-15.613c-.95256-1.34114-1.95061-2.33889-2.8781-2.50391C752.12154,305.77651,753.22928,301.109,751.72447,299.60271Z",
          transform: "translate(-223.9704 -248.96033)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M691.83223,480.29764a4.71,4.71,0,0,1-3.527-.854L619.36867,429.0899a4.74592,4.74592,0,0,1-1.03233-6.62711l35.20521-48.19715a4.74649,4.74649,0,0,1,6.62759-1.03241L729.10522,423.587a4.74592,4.74592,0,0,1,1.03233,6.62711l-35.20521,48.19715A4.71008,4.71008,0,0,1,691.83223,480.29764Z",
          transform: "translate(-223.9704 -248.96033)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M691.83223,480.29764a4.71,4.71,0,0,1-3.527-.854L619.36867,429.0899a4.74592,4.74592,0,0,1-1.03233-6.62711l35.20521-48.19715a4.74649,4.74649,0,0,1,6.62759-1.03241L729.10522,423.587a4.74592,4.74592,0,0,1,1.03233,6.62711l-35.20521,48.19715A4.71008,4.71008,0,0,1,691.83223,480.29764ZM656.94935,374.35448a2.7195,2.7195,0,0,0-1.79246,1.09065L619.9512,423.64235a2.74665,2.74665,0,0,0,.597,3.83269l68.93657,50.35371a2.81451,2.81451,0,0,0,3.83268-.597l35.20521-48.19716a2.74664,2.74664,0,0,0-.597-3.83268l-68.93657-50.35371A2.72146,2.72146,0,0,0,656.94935,374.35448Z",
          transform: "translate(-223.9704 -248.96033)",
          fill: "#ccc"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M683.89221,413.7432l-6.61068,2.55016-2.40736-11.0582a1.50008,1.50008,0,1,0-2.93136.63886l2.50622,11.512-8.35633,3.22357-2.40753-11.05814a1.50008,1.50008,0,1,0-2.93136.63886l2.50634,11.512-5.72388,2.20807a1.5,1.5,0,0,0,1.07974,2.799l5.29-2.04068,1.87987,8.63446-6.57259,2.53547a1.5,1.5,0,0,0,1.07974,2.799l6.13866-2.36808,1.89923,8.7233a1.50015,1.50015,0,0,0,1.78465,1.14642,1.561,1.561,0,0,0,.22271-.06708,1.5,1.5,0,0,0,.924-1.7182l-1.998-9.17714,8.3561-3.22348,1.89911,8.72334a1.50015,1.50015,0,0,0,1.78465,1.14642,1.56118,1.56118,0,0,0,.22272-.06708,1.5,1.5,0,0,0,.924-1.7182l-1.9979-9.17718,6.19606-2.39022a1.5,1.5,0,1,0-1.07974-2.799l-5.76208,2.22281-1.87976-8.63451,7.0446-2.71755a1.5,1.5,0,1,0-1.07974-2.799Zm-6.91766,15.24372-8.35616,3.2235-1.87987-8.63446,8.35627-3.22355Z",
          transform: "translate(-223.9704 -248.96033)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M729.72837,425.858a7.56766,7.56766,0,0,0,3.38186-11.10039l33.72191-50.88923-13.93479-1.05928-30.756,49.98029a7.60868,7.60868,0,0,0,7.58705,13.06861Z",
          transform: "translate(-223.9704 -248.96033)",
          fill: "#9e616a"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M749.11737,313.225s7.88616-5.60869,15.17437,2.73537,6.67966,42.86508,6.74814,48.43209c.02145,1.74324-2.49581,5.50476-5.964,9.84963-1.557,1.9506-3.30565,4.01878-5.10228,6.07469-1.03968,1.18973-.14384,3.66606-1.18782,4.82242-1.03824,1.15-4.01644.98035-5.004,2.0477-4.68754,5.06629-8.49565,8.92278-8.49565,8.92278l-15.26009.18766c-.17216-11.34406,7.59641-21.46991,17.57679-31.25646Z",
          transform: "translate(-223.9704 -248.96033)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M973.91108,650.43785l-748.75.30733a1.19069,1.19069,0,0,1,0-2.38137l748.75-.30733a1.19069,1.19069,0,1,1,0,2.38137Z",
          transform: "translate(-223.9704 -248.96033)",
          fill: "#cacaca"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M572.7902,387.35088H333.82012a5.304,5.304,0,0,1-5.29794-5.29794v-62.9653a5.30383,5.30383,0,0,1,5.29794-5.29774H572.7902a5.30383,5.30383,0,0,1,5.29794,5.29774v62.9653A5.304,5.304,0,0,1,572.7902,387.35088Z",
          transform: "translate(-223.9704 -248.96033)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M572.79043,388.35093H333.82022a6.305,6.305,0,0,1-6.29786-6.29785V319.08775a6.305,6.305,0,0,1,6.29786-6.29785H572.79043a6.305,6.305,0,0,1,6.29785,6.29785v62.96533A6.305,6.305,0,0,1,572.79043,388.35093Zm-238.97021-73.561a4.303,4.303,0,0,0-4.29786,4.29785v62.96533a4.303,4.303,0,0,0,4.29786,4.29785H572.79043a4.303,4.303,0,0,0,4.29785-4.29785V319.08775a4.303,4.303,0,0,0-4.29785-4.29785Z",
          transform: "translate(-223.9704 -248.96033)",
          fill: "#ccc"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M558.50574,356.70205H348.10362a2.56,2.56,0,1,1,0-5.12H558.50574a2.56,2.56,0,1,1,0,5.12Z",
          transform: "translate(-223.9704 -248.96033)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M537.59969,366.818H348.10362a2.56015,2.56015,0,0,1,0-5.12029H537.59969a2.56014,2.56014,0,1,1,0,5.12029Z",
          transform: "translate(-223.9704 -248.96033)",
          fill: _vm.color
        }
      }),
      _c("circle", {
        attrs: { cx: "135.06962", cy: "84.1493", r: "13", fill: _vm.color }
      }),
      _c("path", {
        attrs: {
          d:
            "M364.28452,327.23113l-3.08157,1.18876-1.1222-5.1548a.69927.69927,0,1,0-1.36646.29781l1.16828,5.36634-3.89531,1.50268-1.12228-5.15477a.69926.69926,0,1,0-1.36645.2978l1.16833,5.36633-2.6682,1.02929a.69923.69923,0,0,0,.50333,1.30474l2.46592-.95126.8763,4.025-3.06382,1.18192a.69922.69922,0,1,0,.50332,1.30473l2.86155-1.10388.88532,4.06638a.6993.6993,0,0,0,.83192.5344.72749.72749,0,0,0,.10382-.03126.69924.69924,0,0,0,.43072-.801l-.93138-4.27793,3.89521-1.50263.88527,4.06639a.69929.69929,0,0,0,.83192.53441.72751.72751,0,0,0,.10382-.03127.69922.69922,0,0,0,.43071-.80094l-.93132-4.278,2.8883-1.1142a.69923.69923,0,1,0-.50332-1.30474l-2.686,1.03617-.87625-4.025,3.28386-1.26679a.69923.69923,0,0,0-.50333-1.30474Zm-3.22467,7.10588-3.89524,1.50264-.8763-4.025,3.89528-1.50266Z",
          transform: "translate(-223.9704 -248.96033)",
          fill: "#f2f2f2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M706.41011,594.51392h-16a7,7,0,0,1-7-7v0a7,7,0,0,1,7-7l20-5c3.866,0,3,8.134,3,12v0A7,7,0,0,1,706.41011,594.51392Z",
          transform: "translate(-223.9704 -248.96033)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M763.41011,594.51392h-16a7,7,0,0,1-7-7v0c0-3.866-.866-12,3-12l20,5a7,7,0,0,1,7,7v0A7,7,0,0,1,763.41011,594.51392Z",
          transform: "translate(-223.9704 -248.96033)",
          fill: "#2f2e41"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }