<template>
  <BaseModal 
    ref="modal" 
    size="sm" 
    :autoClose="false"
    @onConfirmButton="save()"
  >
    <template #modalTitle>
      zum "<b>{{ parent.label }}</b>" hinzufügen
    </template>
    <template #default>
      <ComboBox label="Icon" v-model="form.icon" :values="undrawIconValues">
        <template v-for="undrawIcon in undrawIconValues" v-slot:[undrawIcon.value]>
          <div :key="undrawIcon.value" class="undraw-combobox--content">
            <div class="undraw-combobox--icon"><component :is="undrawIcons[undrawIcon.value]" /></div>
            <div class="undraw-combobox--label">{{ undrawIcon.label }}</div>
          </div>
        </template>
      </ComboBox>
      <InputField label="Label" v-model="form.label" autofocus />
    </template>
  </BaseModal>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { PhPlus, PhTrashSimple, PhList } from 'phosphor-vue';

import * as undrawIcons from '@/components/icons/undraw';
import BaseModal from '@/components/core/BaseModal.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputField from '@/components/core/forms/InputField.vue';
import { CustomGroupMenu } from '@/menu/menu-utils';

export default {
  components: {
    PhPlus,
    PhTrashSimple,
    PhList,
    BaseModal,
    ComboBox,
    InputField,
  },
  data() {
    return {
      parent: {},
      undrawIcons,
      currentMenu: null,
      form: {},
    };
  },
  computed: {
    undrawIconValues() {
      return Object.keys(undrawIcons).map(icon => ({
        label: icon.toString()?.replace(/Undraw/gi, ''),
        value: icon.toString(),
      }))
    },
  },
  methods: {
    open(parent, menu) {
      this.reset();

      this.parent = parent;

      if (menu?.id) {
        this.currentMenu = { ...menu };
        this.form = {
          id: menu.id,
          path: menu.path,
          icon: menu.icon ?? 'UndrawFinance',
          label: menu.label,
        };
      }

      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    reset() {
      this.currentMenu = null;
      this.form = {
        id: null,
        path: uuidv4(),
        icon: 'UndrawFinance',
        label: '',
      };
    },
    save() {
      if (!this.form?.label) return;

      const { parent } = this;
      const menu = CustomGroupMenu(this.form.path, this.form.label, this.form.icon, []);
      this.$emit('add', { 
        parent, 
        menus: [menu], 
      });

      this.close();
    },
  },
}
</script>

<style lang="scss" scoped>
.undraw-combobox--content {
  display: flex;
  align-items: center;

  .undraw-combobox--icon {
    max-width: 32px;

    > svg {
      width: 100%;
    }
  }

  .undraw-combobox--label {
    margin: 0 0 0 .5rem;
  }

}
</style>
