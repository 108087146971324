export const MUTATION_PREFIX = 'FREISTELLUNGSANTRAG_MUTATIONS_';
export const ACTIONS_PREFIX = 'FREISTELLUNGSANTRAG_ACTIONS_';
export const GETTERS_PREFIX = 'FREISTELLUNGSANTRAG_GETTERS_';

export default {
  MUTATIONS: {
    RETRIEVE_FREISTELLUNGSANTRAG_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_FREISTELLUNGSANTRAG_SUCCESS',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    REMOVE_ROW_SUCCESS: MUTATION_PREFIX + 'REMOVE_ROW_SUCCESS',
    SELECTED_ITEM: MUTATION_PREFIX + 'SELECTED_ITEM',
    PERSIST_ROW_SUCCESS: MUTATION_PREFIX + 'PERSIST_ROW_SUCCESS',
    RESET_LIST: MUTATION_PREFIX + 'RESET_LIST',
    FREISTELLUNGSANTRAG_GESELLSCHAFTEN: MUTATION_PREFIX + 'FREISTELLUNGSANTRAG_GESELLSCHAFTEN',
  },
  ACTIONS: {
    RETRIEVE_FREISTELLUNGSANTRAG_DATA: ACTIONS_PREFIX + 'RETRIEVE_FREISTELLUNGSANTRAG_DATA',
    REMOVE_ROW: ACTIONS_PREFIX + 'REMOVE_ROW',
    PERSIST_ROW: ACTIONS_PREFIX + 'PERSIST_ROW',
  },
  GETTERS: {
    FREISTELLUNGSANTRAG_DATA: GETTERS_PREFIX + 'FREISTELLUNGSANTRAG_DATA',
    SELECTED_ITEM: GETTERS_PREFIX + 'SELECTED_ITEM',
    FREISTELLUNGSANTRAG_GESELLSCHAFTEN: GETTERS_PREFIX + 'FREISTELLUNGSANTRAG_GESELLSCHAFTEN',
  }
}