var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "footer_container" }, [
    _c("div", { staticClass: "footer_wrapper" }, [
      _c("div", { staticClass: "footer_inner" }, [
        _c("div", { staticClass: "footer_column broker_info" }, [
          _c("div", { staticClass: "foot_header" }, [
            _vm._v(_vm._s(_vm.maklerinfo.cleanMaklername))
          ]),
          _c("div", { staticClass: "foot_body" }, [
            _c("div", [_vm._v(_vm._s(_vm.maklerinfo.strasse))]),
            _c("div", [_vm._v(_vm._s(_vm.maklerinfo.plzOrt))]),
            _c("div", [_vm._v("Telefon: " + _vm._s(_vm.maklerinfo.telefon))]),
            _c("div", [
              _vm._v("E-Mail: " + _vm._s(_vm.maklerinfo.emailAddress))
            ])
          ])
        ]),
        _c("div", { staticClass: "footer_column links" }, [
          _c("div", { staticClass: "foot_header" }, [_vm._v("Info")]),
          _c(
            "div",
            { staticClass: "foot_body" },
            [
              _c("router-link", { attrs: { to: "kontakt" } }, [
                _vm._v("Kontakt")
              ]),
              _c("div", { staticClass: "login_link" }, [
                _c("a", { attrs: { href: _vm.anmeldenUrl } }, [
                  _vm._v("Anmelden")
                ])
              ]),
              _c(
                "div",
                [
                  _c("router-link", { attrs: { to: "impressum" } }, [
                    _vm._v("Impressum")
                  ])
                ],
                1
              ),
              _c(
                "div",
                [_c("router-link", { attrs: { to: "agb" } }, [_vm._v("AGB")])],
                1
              ),
              _c(
                "div",
                [
                  _c("router-link", { attrs: { to: "datenschutz" } }, [
                    _vm._v("Datenschutzerklärung")
                  ])
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("router-link", { attrs: { to: "esg" } }, [
                    _vm._v("Nachhaltigkeit/ESG")
                  ])
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("router-link", { attrs: { to: "beschwerdemanagement" } }, [
                    _vm._v("Beschwerdemanagement")
                  ])
                ],
                1
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "footer_column unser_angebot" }, [
          _c("div", { staticClass: "foot_header" }, [_vm._v("Unser Angebot")]),
          _c(
            "div",
            { staticClass: "foot_body" },
            [_c("editable-frame", { attrs: { type: "footer_editable" } })],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }