import CUSTOMER_FILTERS_TYPES from './types';
import { getInitialState } from './index'

export default {
  [CUSTOMER_FILTERS_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },
  [CUSTOMER_FILTERS_TYPES.MUTATIONS.SET_CUSTOMER_FILTERS_SETUP](state, payload) {
    state.customerFiltersSetup = payload;
  },

  [CUSTOMER_FILTERS_TYPES.MUTATIONS.ADD_ROW](state, payload) {
    state.cfdata.push({ key: Math.random().toString(), first: "allgemein", second: null, third: null });
  },
  [CUSTOMER_FILTERS_TYPES.MUTATIONS.REMOVE_ROW](state, payload) {
    state.cfdata.splice(payload, 1);
  },
  [CUSTOMER_FILTERS_TYPES.MUTATIONS.SET_FIRST_SELECTED](state, payload) {
    state.cfdata[payload.index].first = payload.changed;
  },
  [CUSTOMER_FILTERS_TYPES.MUTATIONS.SET_SECOND_SELECTED](state, payload) {
    state.cfdata[payload.index].second = payload.changed;
  },
  [CUSTOMER_FILTERS_TYPES.MUTATIONS.SET_THIRD_SELECTED](state, payload) {
    state.cfdata[payload.index].third = payload.changed;
  },
  [CUSTOMER_FILTERS_TYPES.MUTATIONS.ADD_SELECTED_CUSTOMER](state, payload) {
    state.selectedCustomers.push(payload);
  },
  [CUSTOMER_FILTERS_TYPES.MUTATIONS.REMOVE_SELECTED_CUSTOMER](state, payload) {
    let selCustIdx = state.selectedCustomers.indexOf(payload);
    state.selectedCustomers.splice(selCustIdx, 1);
  },

  [CUSTOMER_FILTERS_TYPES.MUTATIONS.RENEW_SELECTED_CUSTOMERS](state) {
    state.selectedCustomers = [];
  },

  [CUSTOMER_FILTERS_TYPES.MUTATIONS.CREATE_ROLLE_PROPERTY](state, payload) {
    let selCustIdx = state.selectedCustomers.indexOf(payload);
    state.selectedCustomers[selCustIdx].rolle = "TEILNEHMER";
  },
  [CUSTOMER_FILTERS_TYPES.MUTATIONS.SET_ROLLE](state, payload) {
    let selCustIdx = state.selectedCustomers.indexOf(payload.beteiligte);
    state.selectedCustomers[selCustIdx].rolle = payload.value;
  },
}