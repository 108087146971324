import OTHER_PRODUCTS_TYPES from './types';

import { mapListToComboBoxValues } from '@/helpers/mapping.js';
import { SPROD_ID_NEW, } from '@/components/otherProducts/otherProductsUtils.js';


export default {

  [OTHER_PRODUCTS_TYPES.GETTERS.CURRENT_TYPE](state) {
    return state.currentType;
  },

  [OTHER_PRODUCTS_TYPES.GETTERS.CURRENT_PIN_NAME](state) {
    
    switch (state.currentType) {
      case 'Immobilie':
        return 'TABELLE_IMMOBILIENLISTE';
      case 'WeitereVerbindlichkeiten':
        return 'TABELLE_WEITERE_VERBINDLICHKEITEN';
      case 'WeitereVermoegen':
        return 'TABELLE_WEITERES_VERMOEGEN';
      default:
        break;
    }
    return '';
  },

  [OTHER_PRODUCTS_TYPES.GETTERS.FILTER_COMBOS](state) {
    const type = state.currentType;
    if(!type) return {};

    const filterCombos = state.filterCombos[type] || {};
    if(!Object.keys(filterCombos).length) return {};

    return { 
      ...filterCombos,
      prokuktArt: mapListToComboBoxValues(filterCombos?.prokuktArt, 'key', 'value'),
      zahlrhythmus: mapListToComboBoxValues(filterCombos?.zahlrhythmus, 'key', 'value'),
      bearbeitungsstatus: mapListToComboBoxValues(filterCombos?.bearbeitungsstatus, 'key', 'value'),
      fremdanlagen: mapListToComboBoxValues(filterCombos?.fremdanlagen, 'key', 'value'),
    };
  },

  [OTHER_PRODUCTS_TYPES.GETTERS.OTHER_PRODUCTS](state) {
    const type = state.currentType;
    return type ? [ ...state.otherProducts[type] ] : [];
  },

  [OTHER_PRODUCTS_TYPES.GETTERS.OTHER_PRODUCTS_ALL](state) {
    return {...state.otherProducts};
  },

  [OTHER_PRODUCTS_TYPES.GETTERS.COMBOS](state) {
    const type = state.currentType;
    if(!type) return {};

    const combos = state.combos[type] || {};
    if(!Object.keys(combos).length) return {};

    return { 
      ...combos,
      prokuktArt: mapListToComboBoxValues(combos?.prokuktArt, 'key', 'value'),
      zahlrhythmus: mapListToComboBoxValues(combos?.zahlrhythmus, 'key', 'value'),
      bearbeitungsstatus: mapListToComboBoxValues(combos?.bearbeitungsstatus, 'key', 'value'),
      bankingAccounts: mapListToComboBoxValues(combos?.bankingAccounts, 'key', 'value'),
      nutzung: mapListToComboBoxValues(combos?.nutzung, 'key', 'value'),
      anlageArt: mapListToComboBoxValues(combos?.anlageArt, 'key', 'value'),
      gebaudeTyp: mapListToComboBoxValues(combos?.gebaudeTyp, 'key', 'value'),
    };
  },

  [OTHER_PRODUCTS_TYPES.GETTERS.OTHER_PRODUCT](state) {
    return { ...state.otherProduct };
  },

  [OTHER_PRODUCTS_TYPES.GETTERS.OTHER_PRODUCT_EDITED](state) {
    return { ...state.otherProductEdited };
  },

  [OTHER_PRODUCTS_TYPES.GETTERS.SELECTED_SPROD_ID](state) {
    return state.selectedSprodId;
  },

  [OTHER_PRODUCTS_TYPES.GETTERS.IS_NEW](state) {
    return state.selectedSprodId === SPROD_ID_NEW;
  },

  [OTHER_PRODUCTS_TYPES.GETTERS.RIGHTS](state, getters) {
    const combos = getters[OTHER_PRODUCTS_TYPES.GETTERS.COMBOS];
    return {
      allowModifyBearbStatus: combos?.allowModifyBearbStatus,
      allowReadBearbStatus: combos?.allowReadBearbStatus,
      allowReadSollprovision: combos?.allowReadSollprovision,
      allowModifySollprovision: combos?.allowModifySollprovision,
      allowReadVertragBemerkung: combos?.allowReadVertragBemerkung,
      allowModifyVertragBemerkung: combos?.allowModifyVertragBemerkung,
      allowReadVertragAbrechnung: combos?.allowReadVertragAbrechnung,
      allowModifyVertragAbrechnung: combos?.allowModifyVertragAbrechnung,
      hasRightManuelleCourtage: combos?.hasRightManuelleCourtage,
    };
  },

  [OTHER_PRODUCTS_TYPES.GETTERS.UPDATES](state) {
    return [...state.updates];
  },

  [OTHER_PRODUCTS_TYPES.GETTERS.DOCUMENTS](state) {
    return [...state.documents];
  },

  [OTHER_PRODUCTS_TYPES.GETTERS.APPOINTMENTS](state) {
    const appointments = state.appointments || [];
    return [...appointments];
  },

  [OTHER_PRODUCTS_TYPES.GETTERS.APPOINTMENT_COMBOS](state) {
    const appointmentCombos = state.appointments?.[0] || {};
    return {
      ...appointmentCombos?.appointmentCombos,
    };
  },

  [OTHER_PRODUCTS_TYPES.GETTERS.BUCHUNGEN](state) {
    return [...state.buchungen];
  },

  [OTHER_PRODUCTS_TYPES.GETTERS.SELECTED_BUCHUNG](state) {
    return { ...state.selectedBuchung };
  },

  [OTHER_PRODUCTS_TYPES.GETTERS.BUCHUNG_PROV_STRUKTUREN](state) {
    const selectedBuchung = state.selectedBuchung;
    if(selectedBuchung?.id) {
      return [...state.buchungProvStrukturen[selectedBuchung.id]];
    }
  },

}
