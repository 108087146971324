<template>
  <div>
    <GhostLoading v-if="filterLoading" useBoxContainer>
      <Block height="200" />
    </GhostLoading>
    <GenericPersonFilter  ref="filter"
      v-if="!filterLoading"
      filterId="1e3eaf98-106b-41f0-adba-7a5d43a4606e"
      :saveKey="saveKeyFilter" 
      title="Wertpapierfilter"
      :metadata="filter" 
      :configFilter="configFilter"
      :predefinedFilter="predefinedFilter" 
      :defaultOptions="defaultFilters"
      :isCustomerSearch="false"
      @search="handleSearch($event)"
    >
    </GenericPersonFilter>
  </div>
</template>

<script>
import WERTPAPIERINFO_TYPES from "@/store/wertpapierinfo/types";
import LOG_TYPES from '@/store/log/types';
import { mapGetters } from 'vuex';
import GenericPersonFilter from '@/components/core/GenericPersonFilter.vue';
import {wpFilterMixin} from '@/mixins/wp-suche.js';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';

export default {
  name: 'FondsFinderSuchMaske',
  components: {
    GenericPersonFilter, GhostLoading, Block
  },
  mixins: [wpFilterMixin],
  props: {
    isBeteiligung: {
      type: Boolean,
      default: false
    },
    isZertifikat: {
      type: Boolean,
      default: false
    },    
    lagerstelleId: {
      type: String,
      default: ''
    },    
    emitFilters: {
      type: Boolean,
      default: false,
    },
    ignoreLagerstelle: {
      type: Boolean,
      default: false,
    },
  },
  data: function() {
    return {
      lagerstelle: '',
      gesellschaft: '',
      zusatz: {},
      categoryId: '',
      doRender: 1, // force rendering
      busy: false,
    }
  },
  watch: {
    '$route': function (value) {
      this.lagerstelle = this.getLagerstelle;
      this.categoryId = this.$route.params.categoryId || this.$route.query.categoryId || '';
      this.act = 'search_'+ this.categoryId +'.'+ this.gesellschaft +'.'+ this.lagerstelle;
      this.$store.commit(WERTPAPIERINFO_TYPES.MUTATIONS.ADD_ACT, this.act);
      this.initFilter();
    },
    config: 'initFilter',
  },
  created() {
    this.lagerstelle = this.getLagerstelle;
    this.categoryId = this.$route.params.categoryId || this.$route.query.categoryId || '';
    this.act = 'search_'+ this.categoryId +'.'+ this.gesellschaft +'.'+ this.lagerstelle;
    this.$store.commit(WERTPAPIERINFO_TYPES.MUTATIONS.ADD_ACT, this.act);
    this.initFilter();
  },
  mounted() {
    this.zusatz = {
      isDimensional: !!this.$route.query.isDimensional,
      isETF: !!this.$route.query.isETF,
      isImmobilien: !!this.$route.query.isImmobilien
    }
  },
  computed: {
    ...mapGetters({
      getWPIFilter: WERTPAPIERINFO_TYPES.GETTERS.FILTER,
      getWPIAct: WERTPAPIERINFO_TYPES.GETTERS.ACT,
    }),
    config() {
      return !this.doRender ? null : this.getWPIFilter;
    },
    instData() {
      const result = this.getWPIAct(this.act);
      return !this.doRender ? null : result;
    },
    getLagerstelle() {
      return !this.ignoreLagerstelle && (this.$route.query.lagerstelle || this.lagerstelleId || this.$route.params.lagerstelle || '') || '';
    },
  },
  methods: {
    async getFilter() {
      try {
        if (!this.getWPIFilter){
          await this.$store.dispatch(WERTPAPIERINFO_TYPES.ACTIONS.CONFIG, {});
        }
        if ( this.isBeteiligung || this.$route.query.isBeteiligung) {
          this.setWPS('3')
        } else if ( this.isZertifikat) {
           this.setWPS("2") 
        } else {
          this.setWPS('1')
        }
      } catch (error) {
        this.$store.dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "getFilter", error});
      }
    },
    setWPS(type) {
      if ( type !== this.phase.wp_type ) {
        // const idx = parseInt(type) - 1;
        // this.saveKeyFilter = ['searchInvestmentfonds','searchZertifikate','searchBeteiligungen'][idx];
        
        const f_state = {item: type + '.1.01'};
        this.$store.commit(WERTPAPIERINFO_TYPES.MUTATIONS.SET_ACT, {
          act: this.act, 
          phase: {wp_type: type, f_state: f_state}, 
          filters: [],
          outcome: null
        });
        this.defValue = '';
        this.predefinedFilter = {};
        const bf = this.getBaseFilter();
        if (bf) {
          bf.filterZurucksetzen();
        }
        this.doRender++;
      }
      this.configFilter.programmaticSearchParameter = this.apTerms;
    },
    setFilter(filters) {
      this.$store.commit(WERTPAPIERINFO_TYPES.MUTATIONS.SET_ACT, {act: this.act, filters: filters, outcome: null} );
    },
    filterOk() {
      this.$store.dispatch(WERTPAPIERINFO_TYPES.ACTIONS.OUTCOME, {
          act: this.act,
          gesellschaft: this.gesellschaft,
          lagerstelle: this.lagerstelle,
          categoryId: this.categoryId,
          zusatz: this.zusatz
        });
    },
  },
}
</script>

<style scoped>
.minus-margin {
  margin: 0 -15px;
}
.action-list{
  display: flex;
  width: 100%;
  gap: 8px;
  padding-top: 6px;
  align-content: center;
}
</style>