import store from '@/store';
import CORE_TYPES from '@/store/core/types';
import LOG_TYPES from '@/store/log/types';
import HTTP_REQ_TYPES from '@/store/http-requests/types';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import './http-request-interceptor-block-multiple'
import { 
  MESSAGE_CANCEL_REPEATED_REQUESTS,
  MESSAGE_CANCEL_REQUEST_DUE_TO_LOGOUT,
  MESSAGE_SESSION_TIMEOUT,
  MESSAGE_TOKEN_DOES_NOT_MATCH,
  HEADER_PARAM_SESSION_EXPIRED,
  HEADER_PARAM_DUPLICATED_REQUEST,
} from '@/store/http-requests/actions';

/**
 * @typedef {Object} ExtendedAxiosRequestConfig
 * @property {boolean} defaultSpinner - Indicates if default spinner should be used
 * @property {boolean} noCheckToken - Indicates if token check should be skipped
 * @property {boolean} disableDefaultLog - Indicates if default log should be disabled
 * @property {boolean} disableDefaultErrorMessage - Indicates if default error message should be disabled
 */

/**
 * @type { AxiosRequestConfig & ExtendedAxiosRequestConfig }
 */
export const BASE_AXIOS_CONFIG = Object.freeze({
  defaultSpinner: true,
  noCheckToken: false,
  disableDefaultLog: false,
  disableDefaultErrorMessage: false,
});

export class CancelRequestDelegator {

  constructor(config) {
    this.url = config.url;
    this.abortController = new AbortController();
    config.signal = this.abortController.signal;
    config.cancelRequestDelegator = this;
    config.abort = this.abort.bind(this);
  }

  abort() {
    const reason = arguments[0];
    const message = reason
      ? `Cancelled request. Reason: '${reason}' URL: '${this.url}'`
      : `Cancelled request. No Reason. URL: '${this.url}'`

    store.dispatch(LOG_TYPES.ACTIONS.WARN, message);

    this.abortController.abort.apply(this.abortController, arguments);
  }
}

/** Inject the token, requestID and other parameters for every request */
axios.interceptors.request.use(fulfillHeadersParameters, genericInterceptorError);

/** Start loading state before the request */
axios.interceptors.request.use(startLoadingInterceptor, genericInterceptorError);

/** Finish the loading state after the response and generate the default log error message */
axios.interceptors.response.use(responseFinishLoadingState, responseFinishLoadingStateError);

/**
 * @param { InternalAxiosRequestConfig & ExtendedAxiosRequestConfig } config
 */
function fulfillHeadersParameters(config) {
  const token = store.state.core.loginData.token || store.state.LinkResolvers__Token;

  if (token && !config.noCheckToken) {
    config.headers['token'] = token;
  }
  if (process.env.NODE_ENV === 'development' && process.env.VUE_APP_DB_PREFIX) {
    config.headers.dbPrefix = process.env.VUE_APP_DB_PREFIX;
  }
  if (!config.headers.UID) {
    config.headers.UID = uuidv4();
  }

  config.headers['app-version'] = store.getters[CORE_TYPES.GETTERS.APP_VERSION];

  // can be used to cancel the frontend request. a backend cancel might be still necessary.
  // check HTTP_REQ_TYPES.ACTIONS.KILL_SESSION for more information
  new CancelRequestDelegator(config);

  return config;
}

function genericInterceptorError(error) {
  return Promise.reject(error);
}

/**
 * @param { InternalAxiosRequestConfig & ExtendedAxiosRequestConfig } config
 */
function startLoadingInterceptor(config) {
  if (config?.defaultSpinner) {
    store.dispatch(CORE_TYPES.ACTIONS.GLOBAL_LOADING_STATE_START);
  }
  return config;
}

function canAddDefaultErrorLog(error) {
  return error.message !== MESSAGE_CANCEL_REQUEST_DUE_TO_LOGOUT 
    && !error.config?.disableDefaultLog 
    && error.response?.status !== 404
}

function canAddDefaultErrorMessage(error) {
  const ignoredErrorCodes = [404]

  if (error.response?.headers?.[HEADER_PARAM_DUPLICATED_REQUEST]) {
    return false;
  }

  let data = error.response?.data;

  try {
    if (data instanceof ArrayBuffer) {
      data = JSON.parse(new TextDecoder("utf-8").decode(error.response.data))
    }
  } catch (error) {
    //empty block
  }

  return error.message !== MESSAGE_CANCEL_REQUEST_DUE_TO_LOGOUT 
    && !error.config?.disableDefaultErrorMessage 
    && !ignoredErrorCodes.some(item => item === error.response?.status)
    && data?.message
}

function canIgnoreErrorHandlingAtAll(error) {
  return axios.isCancel(error)
      || error?.message === MESSAGE_CANCEL_REPEATED_REQUESTS 
      || error?.message === MESSAGE_SESSION_TIMEOUT
}

function checkSessionTimeoutAttribute(error) {
  if (error?.response?.headers?.[HEADER_PARAM_SESSION_EXPIRED] && error?.response?.headers?.token) {
    store.commit(HTTP_REQ_TYPES.MUTATIONS.ADD_INVALID_TOKEN_DUE_TO_SESSION_TIMEOUT, {
      token: error.response.headers.token
    });
  }
}

function responseFinishLoadingState(response) {
  if (response.config?.defaultSpinner) {
    store.dispatch(CORE_TYPES.ACTIONS.GLOBAL_LOADING_STATE_STOP);
  }

  const requestToken = store.state.core.loginData.token;
  const responseToken = response.headers.token

  if (requestToken && responseToken && requestToken !== responseToken) {
    store.dispatch(LOG_TYPES.ACTIONS.WARN, { message: MESSAGE_TOKEN_DOES_NOT_MATCH });
    return Promise.reject(MESSAGE_TOKEN_DOES_NOT_MATCH)
  }
  return response;
}

function responseFinishLoadingStateError(error) {
  if (canIgnoreErrorHandlingAtAll(error)) {
    return error
  }

  checkSessionTimeoutAttribute(error)

  if (error.config?.defaultSpinner) {
    store.dispatch(CORE_TYPES.ACTIONS.GLOBAL_LOADING_STATE_STOP);
  }
  if (canAddDefaultErrorLog(error)) {
    const data = error.response && error.response.data;
    const message = error?.response?.message || error?.message || error?.response?.data?.message
    store.dispatch(LOG_TYPES.ACTIONS.ERROR, {
      message: `Default error handler: "${message}" from http-request-interceptor.js`,
      error,
      data
    });
  }

  if (canAddDefaultErrorMessage(error)) {
    store.dispatch(LOG_TYPES.ACTIONS.ADD_RESPONSE_ERROR_MESSAGE, error);
  }

  return Promise.reject(error);
}
