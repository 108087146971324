var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.depotTitle,
          subtitle: "Bankkonten",
          actions: _vm.headerActions
        }
      }),
      _c(
        "div",
        { key: "account-positive", attrs: { id: "account-positive" } },
        [
          _c("Account", {
            attrs: {
              title: "Konten mit positivem Saldo",
              showAccounts: "positive",
              isLoading: _vm.loading
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { key: "account-negative", attrs: { id: "account-negative" } },
        [
          _c("Account", {
            attrs: {
              title: "Konten mit negativem Saldo",
              showAccounts: "negative",
              isLoading: _vm.loading
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }