<template>
  <div class="ghost-loading__container" 
    :class="{ 
      'ghost-loading__container--inline': inline,
      'ghost-loading__overflow-hidden': overflowHidden,
    }"
  >
    <div v-for="item in repeat" class="ghost-loading" :key="item"
      :style="style"
      :class="{ 'box__container': useBoxContainer }"
    >
      <div v-if="title" class="box__title">{{title}}</div>

      <div 
        class="ghost-loading--wrap"
        :class="{
          'ghost-loading--wrap__half--size': isHalfSize,
        }"
      >
        <template v-if="type === TYPE_TABLE">
          <Block v-for="row in tableConfig.rows" :key="row" type="table-row" />
        </template>

        <template v-else-if="type === TYPE_CARD_VERTICAL">
          <Block type="image" />
          <Block type="title" />
          <Block />
          <Block />
          <Block />
        </template>

        <template v-else-if="type === TYPE_CARD_HORIZONTAL">
          <div class="row my-0">
            <div class="col-2">
              <Block type="image" />
            </div>
            <div class="col-10">
              <Block type="title" />
              <Block />
              <Block />
              <Block />
              <Block />
            </div>
          </div>
        </template>

        <template v-else-if="type === TYPE_PARAGRAPH">
          <Block type="title" />
          <Block />
          <Block />
          <Block />
        </template>

        <template v-else-if="type === TYPE_BLOCK">
          <Block :width="blockConfig.width" :height="blockConfig.height" :opacity="blockConfig.opacity" />
        </template>

        <template v-else-if="type === TYPE_CUSTOM">
          <slot />
        </template>

        <template v-else-if="type === TYPE_INPUT">
          <Block width="50%" />
          <Block type="form-input" />
        </template>
        <template v-else-if="type === TYPE_TITLE">
          <Block type="title" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Block from './ghost-loading/Block.vue';
import { isNumber } from '@/helpers/number-formatter.js';

const TYPE_TABLE = 'table';
const TYPE_CARD_HORIZONTAL = 'card-horizontal';
const TYPE_CARD_VERTICAL = 'card-vertical';
const TYPE_PARAGRAPH = 'paragraph';
const TYPE_BLOCK = 'block';
const TYPE_CUSTOM = 'custom';
const TYPE_INPUT = 'input';
const TYPE_TITLE = 'title';
const PROP_TYPE_VALIDS = [TYPE_TABLE, TYPE_CARD_HORIZONTAL, TYPE_CARD_VERTICAL, TYPE_PARAGRAPH, TYPE_BLOCK, TYPE_CUSTOM, TYPE_INPUT, TYPE_TITLE];

const DEFAULT_CONFIG = {
  table: {
    rows: 3,
  },
  block: {
    width: null,
    height: 72,
  },
};

export default {
  props: {
    title: {
      type: String,
    },
    type: {
      type: String,
      default: TYPE_CUSTOM,
      validator: (value) => PROP_TYPE_VALIDS.indexOf(value) >= 0,
    },
    width: {
      type: [Number, String],
      default: null,
    },
    height: {
      type: [Number, String],
      default: null,
    },
    repeat: {
      type: Number,
      default: 1,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    overflowHidden: {
      type: Boolean,
      default: false,
    },
    useBoxContainer: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: () => (DEFAULT_CONFIG),
    },
    isHalfSize: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      TYPE_TABLE,
      TYPE_CARD_HORIZONTAL,
      TYPE_CARD_VERTICAL,
      TYPE_PARAGRAPH,
      TYPE_CUSTOM,
      TYPE_BLOCK,
      TYPE_INPUT,
      TYPE_TITLE,
    };
  },
  computed: {
    style() {
      return {
        width: isNumber(this.width) ? `${this.width}px` : this.width,
        maxWidth: isNumber(this.width) ? `${this.width}px` : this.width,
        height: isNumber(this.height) ? `${this.height}px` : this.height,
      };
    },
    tableConfig() {
      return this.config?.table || {};
    },
    blockConfig() {
      return this.config?.block || {};
    },
  },
  methods: {
  },
  components: {
    Block,
  },
}
</script>

<style lang="scss" scoped>
@keyframes shimmer {
  0% {
    left: -250px;
  }
  100% {
    left: 100%;
  }
}

@mixin ghostLoadingAnimation {
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, var(--color-box) 50%, rgba(255, 255, 255, 0) 100%);
  background-repeat: no-repeat;
  content: "";
  display: block;
  opacity: 0.75;
  pointer-events: none;
  position: absolute;
  top: -50px;
  left: -300px;
  width: 300px;
  height: calc(100% + 100px);
  transform: rotate(15deg);
  animation: shimmer 2.5s infinite forwards;
}

.ghost-loading__container {
  margin: 0 0 16px;

  &:last-child {
    margin-bottom: 0;
  }

  &.ghost-loading__overflow-hidden {
    overflow: hidden;
  }
}

.ghost-loading {
  margin: 0 0 16px;
  overflow: hidden;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  .ghost-loading--wrap {
    height: 100%;
    overflow: hidden;
    position: relative;

    &::after {
      @include ghostLoadingAnimation;
    }
  }
}

.ghost-loading__container--inline {
  display: flex;
  margin-left: -4px;
  margin-right: -4px;

  .ghost-loading {
    margin: 4px;
    flex: 1 0 auto;
  }
}

@media (min-width: 992px) {
  .ghost-loading {
    .ghost-loading--wrap {
      &.ghost-loading--wrap__half--size {
        max-width: 50%;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .ghost-loading__container {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .ghost-loading {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .ghost-loading__container--inline {
    .ghost-loading {
      margin: 4px;
    }
  }
}
</style>
