<template>
    <BaseModal ref="suitabilityConfirmationModal" size="md" :showCancelButton="false" labelButtonConfirm="Überprüfung abschließen" confirmDisabled
        @onConfirmButton="complete()">
        <template #modalTitle>
            <div class="option-menu-edit-modal--title-container">
                <div class="option-menu-edit-modal--title">Überprüfung abschließen</div>
            </div>
        </template>
        <template #default>
            <div>
                <InputRadioBoxGroup title="Überprüfung und Annahme durch WealthKonzept" v-model="pruefungsErgebnis"
                    :validateUntouched="true" :values="pruefungsstatusValues" labelClass="font-bold" />

                <InputTextArea v-if="pruefungsErgebnis === 'ABGELEHNT'" v-model="bemerkungText"
                    label="Begründung des negativen Testergebnisses" autoGrow />

            </div>
        </template>
    </BaseModal>
</template>

<script>
import BaseModal from '@/components/core/BaseModal.vue';
import InputTextArea from "@/components/core/forms/InputTextArea.vue";
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';

export default {
    components: {
        BaseModal,
        InputTextArea,
        InputRadioBoxGroup
    },
    data() {
        return {
            pruefungsstatusValues: [
                { label: 'Angenommen', value: 'ANGENOMMEN' },
                { label: 'Abgelehnt', value: 'ABGELEHNT' },
                { label: 'Keine Angabe', value: 'AUSSTEHEND' }
            ],
            pruefungsErgebnis: 'AUSSTEHEND',
            bemerkungText: '',
        }
    },
    methods: {
        open(data) {
            this.bemerkungText = data.bemerkungText;
            this.pruefungsErgebnis = data.pruefungsErgebnis;
            this.$refs.suitabilityConfirmationModal.open();
        },
        close() {
            this.$refs.suitabilityConfirmationModal.close();
        },
        complete() {
            this.$emit('completed', { pruefungsErgebnis: this.pruefungsErgebnis, bemerkungText: this.bemerkungText });
            this.close();
        }
    }
}
</script>