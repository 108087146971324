var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseDropdownMenu", {
    attrs: {
      placement: "bottom-right",
      containerClass: "options-menu-config-view-diff-help--dropdown",
      closeWithInnerClick: false
    },
    scopedSlots: _vm._u([
      {
        key: "hook-target",
        fn: function() {
          return [
            _c(
              "button",
              { staticClass: "btn-clear clickable", attrs: { type: "button" } },
              [_c("PhQuestion", { attrs: { size: 20 } })],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "default",
        fn: function() {
          return [
            _c(
              "ul",
              { staticClass: "options-menu-config-view-diff-help--items" },
              [
                _c(
                  "li",
                  {
                    staticClass:
                      "options-menu-config-view-diff-help--item status-changed"
                  },
                  [_c("span", [_vm._v("Konfiguration unterscheidet sich")])]
                ),
                _c(
                  "li",
                  {
                    staticClass:
                      "options-menu-config-view-diff-help--item status-added"
                  },
                  [_c("span", [_vm._v("Konfiguration wird hinzugefügt")])]
                ),
                _c(
                  "li",
                  {
                    staticClass:
                      "options-menu-config-view-diff-help--item status-removed"
                  },
                  [_c("span", [_vm._v("Konfiguration wird entfernt")])]
                )
              ]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }