<template>
  <div>
    <BaseModal 
      ref="editModal"
      modalTitle="Link"
      @onCloseButton="close()"
      @close="close()"
      @onConfirmButton="onConfirmButton">

      <InputField
        v-if="htmlLinkForm.currentSelectionIsEmpty && !htmlLinkForm.previousUrl"
        label="Anzeigentext"
        v-model="htmlLinkForm.linkText"
        placeholder="">
      </InputField>

      <InputField
        label="URL"
        placeholder="https://"
        v-model="htmlLinkForm.url"/>

    </BaseModal>
  </div>
</template>

<script>
import BaseModal from '@/components/core/BaseModal.vue';
import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';

function htmlLinkForm() {
  return {
    url: '',
    linkText: '',
  }
}

const HAS_PROTOCOL = /(https?|mailto):\/\//i

export default {
  props: {
    linkForm: {
    }
  },

  data() {
    return {
      loading: false,
      htmlLinkForm: htmlLinkForm(),
      linkType: 'URL',
    }
  },

  computed: {
    
  },

  methods: {
    close() {
      this.$emit("close")
    },
    onConfirmButton() {
      if (this.linkType !== 'URL' && this.linkTextEmail !== null) {
        this.htmlLinkForm.url = `mailto:${this.linkTextEmail}${this.linkTextSubj || this.linkTextMsg ? `?subject=${this.linkTextSubj || ''}&body=${this.linkTextMsg || ''}` : ''}`
      }

      // if there is no text selected, use the link as text
      if (this.htmlLinkForm.currentSelectionIsEmpty && !this.htmlLinkForm.previousUrl && !this.htmlLinkForm.linkText) {
        this.htmlLinkForm.linkText = this.htmlLinkForm.url;
      }
      
      this.$emit('onConfirmButton', this.htmlLinkForm)
    }
  },
  mounted() {
    if (this.linkForm) {
      Object.assign(this.htmlLinkForm, this.linkForm)
    }

    this.$refs.editModal.open()
  },

  components: {
    BaseModal,
    InputField,
    ComboBox,
  },
}
</script>
