var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _vm.isBrokerOrBypass
        ? _c(
            "div",
            { staticClass: "mb-3" },
            [
              _c(
                "BaseButton",
                {
                  on: {
                    click: function($event) {
                      return _vm.openSubCustomerSearch()
                    }
                  }
                },
                [_vm._v("Gruppenmitglied hinzufügen")]
              )
            ],
            1
          )
        : _vm._e(),
      !_vm.loading && _vm.rows.length
        ? _c("Table", {
            attrs: {
              tableId: "d40cc1ae-8b4e-4fcd-9bc4-c896de80e04b",
              headers: _vm.headers,
              rows: _vm.rows,
              rowsPerPage: 10,
              rowId: "customerID"
            },
            on: {
              "click-name": _vm.openSubCustomerNewTab,
              "action-DELETE": _vm.openDeleteModal
            }
          })
        : _vm.loading
        ? _c(
            "div",
            { staticClass: "text-centered" },
            [_c("AnimatedSpinner")],
            1
          )
        : _c("div", [_vm._v("Keine Daten")]),
      _c("BaseModal", {
        ref: "subCustomerDeleteModal",
        attrs: { size: "sm", modalTitle: "Gruppenmitglied entfernen" },
        on: {
          onConfirmButton: function($event) {
            return _vm.deleteSubCustomer()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.subCustomerToDelete.fullName) +
                    " wirklich aus der Liste entfernen? "
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }