var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-forms__container" },
    [
      _vm.label
        ? _c("label", { staticClass: "input-forms__label-container" }, [
            _c("div", {
              staticClass: "input-forms__label-content",
              domProps: { innerHTML: _vm._s(_vm.sanitize(_vm.label)) }
            })
          ])
        : _vm._e(),
      _c("FormInputDokNodePermissionItem", {
        attrs: {
          values: _vm.checkBoxValues,
          value: _vm.value,
          disabled: _vm.disabled
        },
        on: {
          input: function($event) {
            return _vm.onInput($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }