import { DatePickerUtils } from '@/components/core/forms/DatePicker/date-picker-utils';


export function filterMetadata(hasNurVorlagen) {
  const allgemeinSection = {
      type: 'group',
      key: 'allgemein',
      label: 'Allgemein',
      menuItems: [
          {
              type: 'default',
              label: 'Nur Depotoptimierung',
              key: 'nurDepotoptimierung',
              emptyDenied: true,
          },
          {
              type: 'datepicker',
              label: 'Erstellt frühestens am',
              key: 'erzeugungsdatum',
              emptyDenied: true,
          },
          {
              type: 'default',
              label: 'Einschließlich unvollständige Einträge',
              key: 'inclTemporaer',
              emptyDenied: true,
          },
      ],
  };

  if(hasNurVorlagen) {
      allgemeinSection.menuItems.push({
          type: 'default',
          label: 'Vorlagen und Kunden',
          key: 'inclKunden',
          emptyDenied: true,
      });
  } else {
    allgemeinSection.menuItems.push({
        type: 'default',
        label: 'Vorlagen anzeigen',
        key: 'inclVorlagen',
        emptyDenied: true,
    });
  }

  return [allgemeinSection];
}

export function prepareFilters(filters) {
  if(!filters?.length) return {};

  return filters.reduce((acc, filter) => {
      if(filter.key === 'erzeugungsdatum') {
          acc[filter.key] = DatePickerUtils.toDateStr(filter.value);
      } else {
          acc[filter.key] = filter.value;
      }
      return acc;
  }, {});
}
