import INVESTMENT_ADVICE from "@/store/investmentAdvice/types";
import LOG_TYPES from '@/store/log/types';
import InputRadioBoxGroup from "@/components/core/forms/radiobox/InputRadioBoxGroup.vue";
import InputField from "@/components/core/forms/InputField.vue";
import FormLabel from "@/components/core/forms/FormLabel.vue";
import FormHeader from "@/components/core/forms/FormHeader.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import BaseModal from '@/components/core/BaseModal.vue';
import {PhInfo, PhFile, PhShoppingBag, PhCompass, PhFaders, PhFloppyDisk, PhCheck, PhCheckCircle, PhWarningCircle, PhCircle} from 'phosphor-vue'
import Vue from 'vue'
import { mapGetters } from "vuex";
import { parse } from '@/helpers/number-formatter.js';

const ACTIONS_DATA_TYPE = ['Action', 'Edit'];
const MAP_HEADER_KEY_TO_NEW_LABEL = {
  lockingModeIcon: 'Einschränkungen',
};
const MAP_HEADER_KEY_TO_NEW_DATA_TYPE = {
  name: 'Slot',
};
const PERCENT_HEADERS_TO_PARSE = ['aufteilung', 'dueProc'];
const MAP_HEADER_KEY_TO_EDITABLE = {
  aufteilung: true,
  summe: true,
};
const MAP_HEADER_KEY_TO_CALC_ASSET_ALLOCATION = {
  aufteilung: true,
  summe: true,
};

export default {
  props: {
    id: {},
    fundsSourceId: {},
  },
  components: {
    InputRadioBoxGroup,
    InputField,
    ComboBox,
    FormLabel,
    FormHeader,
    BaseModal,
    PhInfo,
    PhFile,
    PhShoppingBag,
    PhCompass, 
    PhFaders,
    PhFloppyDisk,
    PhCheck,
    PhCheckCircle, PhWarningCircle, PhCircle
  },
  computed: {
    ...mapGetters({
      parameters: INVESTMENT_ADVICE.GETTERS.PARAMETERS,
      positions: INVESTMENT_ADVICE.GETTERS.POSITIONS,
      outcomeBcktestAdvice: INVESTMENT_ADVICE.GETTERS.BACKTEST_ADVICE,
      outcomeBacktestActual: INVESTMENT_ADVICE.GETTERS.BACKTEST_ACTUAL,
      outcomeBacktestDetails: INVESTMENT_ADVICE.GETTERS.BACKTEST_DETAILS,
      expired: INVESTMENT_ADVICE.GETTERS.EXPIRED,
      investmentAdvice: INVESTMENT_ADVICE.GETTERS.ID,
    }),
    expiredValue() {
      return this.expired;
    },
    assetCategory() {
      let category = this.stepAllocation ? this.stepAllocation[this.currentAsset].category() : undefined;
      return category;
    },
    currentAsset() {
      return 0;
    },
    stepParameters() {
      return this.parameters(this.step);
    },
    // stepPositions() {      
    //   return this.positions(this.assetCategory);
    // },
    debug() {
      return false;
    },
    adviceId() {
      return this.id;
    },
    loadingPositions() {
      let loading = this.stepAllocation && this.stepAllocation.length > 0 && this.stepAllocation[this.currentAsset].loading;
      return loading;
    },
    courtageParameters() {
      return {};
    }
  },
  data() {
    return {
      //persist: true, turn persist only through the calculation and the save button, 
      // otherwise temporary - remove after leaving 
      stepWarnings: "test warning",
      step: "",
      stepAllocation: undefined,
      stepBacktestingCharts: [],
      stepOptimierungChart: {},
      loading: false,
      modified: false,
      percentPrecision: 2,
      promiseParameters: null
    };
  },
  methods: {
    triggerSelectSource(event) {
      const found = this.stepAllocation.find(a => a.category() === event.category);      
      this.save(false,  event.selection);
    },

    markModified() {
      this.modified = true;
    },
    updateHints(hints) {
      if (!hints) {
        return;
      }
      if (hints.modalDialog) {
        if (!hints.modalDialog.donotshowagain) {
          hints.modalDialog.donotshowagain = true;
          let dialogData = {...hints.modalDialog, step: this.step};
          this.$emit('display_server_question', dialogData);
        }
      }
      this.$emit('updated', hints);
      this.$emit('init_hinweise', hints.hinweise);
        if (hints.fehlerData)
          this.$emit('init_warnings', hints.fehlerData.data);
    },
    loadPositions() {
    },
    positionsChanged(positions) {},
    outcomeChanged(outcome) {      
      if (outcome.data && outcome.data.fehlerData && outcome.data.fehlerData.data) {
        let message = '';
        outcome.data.fehlerData.data.forEach(fehler => {
          message += '<p>' + fehler.title + '</p>' + fehler.message;
        });
        if (message.length > 0)
          this.$store.commit(INVESTMENT_ADVICE.MUTATIONS.SET_MESSAGE, {
            show: true,
            text: message
          })
      }
    },
    toTab(place){},

    async save(persist, source) {}, 

    submitChanges(persist = false) {
      let needsSave = this.modified || (this.stepAllocation?.length > 0 && this.stepAllocation[this.currentAsset].modified);
      if (needsSave) {
        return this.save(persist, this.stepAllocation?.length > 0 ? this.stepAllocation[this.currentAsset].source : '');
      } else if (this.promiseParameters) {
        return this.promiseParameters;
      } else {
        return Promise.resolve();
      }
    },

    loadStepData() {
      if (this.id && this.step){
        this.loading = true;
        this.promiseParameters = this.$store
          .dispatch(INVESTMENT_ADVICE.ACTIONS.FETCH_PARAMETERS, {
            adviceId: this.id,
            step: this.step,
          }).finally( () => { this.promiseParameters = null });
      }
    },
    setRefreshOutcome() {
      let parent = this.$parent;
      while (parent) {
        if (parent.$data.refreshOutcome !== undefined) {
          parent.$data.refreshOutcome = true;
          break;
        } else {
          parent = parent.$parent;
        }
      }
    },
  },  
  
  watch: {
    stepParameters: function(params) {
      // workaround
      if (params.data) {
        if (this.parametersChanged) {
          this.parametersChanged(params);
        }
        this.updateHints(params.data);
      }
      if (params.data?.zusatz?._changetimeNeeded_changetimeStart) {
        const dialogData = {
          yesText: 'Ja',
          noText: 'Nein',
          yesCb: () => {
            if (this.step == 'MAIN') {
              // this.stepParameters.data.parameterData.backtestStart = params.data.zusatz._changetimeNeeded_changetimeStart;
              // this.stepParameters.data.parameterData.backtestEnd = params.data.zusatz._changetimeNeeded_changetimeEnd;
              this.backtestStart = params.data.zusatz._changetimeNeeded_changetimeStart;
              this.backtestEnd = params.data.zusatz._changetimeNeeded_changetimeEnd;
              this.stepParameters.data.parameterData.zeitIntervall = true;
              this.saveParameters(true, false);
            }
          },
          noCb: () => {
            params.data.zusatz._changetimeNeeded_changetimeStart='';
            params.data.zusatz._changetimeNeeded_changetimeEnd='';
            params.data.zusatz._changetimeNeeded_mess='';
            params.data.zusatz._changetimeNeeded='';
          },
          label: 'Analysephase',
          text: (params.data.zusatz._changetimeNeeded_mess + '<br><br>' + params.data.zusatz._changetimeNeeded).replace(/\\n/, '<br>'),
        }
        this.$emit('display_server_question', dialogData);
      }
      if (params.data?.fehlerData?.data?.length) {
        let title = '';
        let body = ''
        params.data.fehlerData.data.forEach(err => {
          if (err.title) {
            title = err.title
          }
          if (err.message) {
            body += '<li>' + err.message + '</li>'
          }
        })
        const mess = `<div class="box__container"><div class="box__title">${title}</div><ul>${body}</ul></div>`;
        this.$store.commit(INVESTMENT_ADVICE.MUTATIONS.SET_MESSAGE, {
          show: true,
          text: mess
        })
      }
      this.loading = false;
      this.modified = false;
    },

    outcomeBacktestAdvice(outcome) {
      this.outcomeChanged(outcome);
    },
    outcomeBacktestDetails(outcome) {
      this.outcomeChanged(outcome);
    },
    outcomeBacktestActual(outcome) {
      this.outcomeChanged(outcome);
    },
    investmentAdvice(newID) {
      if (newID > 0 && this.id > 0 && this.id != newID) {
        this.id = newID;
      }
          }
  },

  //lifecycle
  beforeMount() {
  },
  mounted() {
    this.loadStepData();
  },
  beforeCreate() {
  }, 
  created() {
  },
  beforeDestroy() {
    if (Object.values(this.$refs).some(ref => ref?.isOpen))
      Object.values(this.$refs).filter(ref => ref?.isOpen).forEach(ref => {
        ref.close();
      });
  },
  destroyed() {
  },
  updated() {
  }, 
}
