<template>
  <div v-if="hasTabs" class="tabs__container">
    <template v-for="(tab, index) of tabList">
      <template v-if="tab.visible !== false">
        <a :key="`tab-item-${index}`" :disabled="tab.disabled" :class="{ 'font-bold': tab.key === selected, 'disabled' : tab.disabled || disabled, }" @click="selectTab(tab)">{{ tab.label || ''}}</a>
        <span :key="`tab-divider-${index}`" v-if="index < tabList.length - 1" class="tab-divider">|</span>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    tabList: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    hasTabs() {
      return !!this.tabList?.length;
    },
  },
  watch: {
    tabList: {
      handler() {
        if(this.hasTabs && !this.selected) {
          this.selectTab(this.tabList[0]);
        }
      },
      immediate: true,
    },
  },
  methods: {
    selectTab(tab) {
      if(this.disabled || !tab?.key || tab?.key === this.selected || tab?.disabled) return;

      this.$emit('tabSelected', tab);
    },
  }
};
</script>

<style scoped lang="scss">
.tabs__container {
  display: flex;
  align-items: center;
  font-weight: normal;
  flex-wrap: wrap;

  a {
    color: var(--color-text);
    text-decoration: none !important;

    &.disabled {
      color: #ccc;
      cursor: not-allowed;
    }
  }

  .tab-divider {
    margin: 0 8px;
  }
}
</style>
