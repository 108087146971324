<template>
    <div class="table-container paginated-table__container">
        <template v-if="!$isSmallScreen || !mobileConfig">
            <SortableTable
                ref="sortableTable"
                :cardView="cardView"
                :tableId="tableId"
                :title="title"
                :headers="headerMap"
                :rows="displayedPage"
                :sortedManually="sortConfig"
                :maxHeaderLines="maxHeaderLines"
                :enableToggleHorizontalScrollingConfig="enableToggleHorizontalScrollingConfig"
                :exportConfig="exportConfig"
                :headerActions="headerActions"
                :loadingCollapsed="loadingCollapsed"
                :sortableRows="sortableRows"
                :showColumnsConfigEvenNoTitle="showColumnsConfigEvenNoTitle"
                :thHoverDisabled="thHoverDisabled"
                :noDataIcon="noDataIcon"
                :noDataContent="noDataContent"
                @sort="handleSort"
                @localSort="$emit('localSort', $event)"
                @clickCell="handleClick"
                @action="handleAction"
                @files="handleFile"
                @headerAction="handleHeaderAction"
                @configColumn="columnConfigChanged"
                @shownHeaders="$emit('shownHeaders', $event)"
                @allHeaders="$emit('allHeaders', $event)"
                @toggleRow="$emit('toggleRow', $event)"
                @isColumnsConfigOpen="$emit('isColumnsConfigOpen', $event)"
                @orderChanged="onOrderChanged"
            >
                <template v-slot:dropdown_menu v-if="menuRowsPerPage && menuRowsPerPage.length">
                    <SelectRowPerPage :pageSize="pageSize" :rowSteps="menuRowsPerPage" @tablePageSize="onPageSizeChange" />
                </template>
                <template v-slot:header___SELECT__>
                    <InputCheckBoxItem v-if="selectionAsBlacklist != null"
                        :value="areAllSelected"
                        :indeterminate="areOnlySomeSelected"
                        :tid="_generateTidFromString(tableId + ':__SELECT__')"
                        @input="onSelectAll"
                        style="margin-left: 3px"
                    /> 
                </template>
                <template v-for="{key, rowElement} in headersFlat" v-slot:[key]="{row, id}">
                    <InputCheckBoxItem 
                        v-if="!rowElement && key == '__SELECT__' && id != null && !row.selectedHidden"
                        :key="key"
                        :value="isCheckmarked(id)"
                        :tid="_generateTidFromString(tableId + ':__SELECT__:' + id)"
                        @input="onSelect(id)"
                    />
                    <slot v-else-if="!rowElement && key != '__SELECT__'" :name="key" v-bind="row"/>
                </template>
            </SortableTable>
            <div v-if="displayedItems > 0" class="table-pagination">
                <div class="table-pagination--left">
                    <PageRowCount :displayedItems="displayedItems" :totalRows="rowCount"/>
                    <SelectRowPerPage short :pageSize="pageSize" :rowSteps="menuRowsPerPage" @tablePageSize="onPageSizeChange" />
                </div>
                <div v-if="displayedItems < rowCount" class="table-pagination--right">
                    <Pagination
                        :totalPages="pageCount"
                        :currentPage="displayedPageIndex"
                        @pagechanged="onPageChange"
                    />
                </div>
            </div>
        </template>

        <template v-else>
            <ScrollLoading
                :hasScroll="rowsWithId.length !== rowCount"
                @loading="$emit('scrollLoading', $event)"
                @scroll="$emit('onScroll', $event)"
            >
                <MobileTable
                    ref="sortableTable"
                    :tableId="tableId"
                    :rowId="rowId"
                    :title="title"
                    :headers="headerMap"
                    :rows="rowsWithId"
                    :titleActions="headerActions"
                    :config="mobileConfig"
                    :selected="selected"
                    :selectionAsBlacklist="selectionAsBlacklist"
                    :noDataIcon="noDataIcon"
                    :noDataContent="noDataContent"
                    v-on="$listeners['clickRow'] ? { clickRow: (event) => $emit('clickRow', event) } : {}"
                    @selected="$emit('selected', $event)"
                    @selectionAsBlacklist="$emit('selectionAsBlacklist', $event)"
                    @clickCell="handleClick"
                    @action="handleAction"
                    @files="handleFile"
                    @headerAction="handleHeaderAction"
                    @configColumn="columnConfigChanged"
                    @orderChanged="onOrderChanged"
                >
                    <template v-for="{key, rowElement} in headersFlat" #[key]="{row, mobileTableContext}">
                        <slot v-if="!rowElement && key !== '__SELECT__'" :name="key" v-bind="row" :mobileTableContext="mobileTableContext"/>
                    </template>
                    <template v-for="header in headersFlatLabelNull" #[header.headerKey]>
                        <slot :name="header.headerKey" v-bind="header"/>
                    </template>
                </MobileTable>
            </ScrollLoading>
        </template>
    </div>
</template>
<script>
import tableMixin from './table-mixin.js';
import BROKERDATA_TYPES from '@/store/brokerData/types';
import MobileTable from "@/components/table2/MobileTable.vue";
import ScrollLoading from '@/views/communication/ScrollLoading.vue';

import { mapGetters } from 'vuex';

export default {
    mixins: [tableMixin],
    components: {
      MobileTable,
      ScrollLoading,
    },
    model: {
        prop: 'selected',
        event: 'selected',
    },
    props: {
        tableId: {
            // tableId must be unique. This Id is being used to save the column config into backend
            // a UUID randomly generated is enough. https://www.uuidgenerator.net/version4 or any other generator.
            type: String,
        },
        title: {
            type: String,
            default: "",
        },
        headers: { 
            type: Object,
            default: () => {},
            required: true,
        },
        // an object containing a list of rows for each already loaded page.
        // if only pages 0 and 12 are loaded it would look like this: {0: [...], 12: [...]}
        pages: { 
            type: Object,
            default: () => {},
            required: true,
        },
        rowId: { 
            type: String,
            default: "id",
        },
        // how many pages exist for this table (even if not loaded from the backend yet)
        pageCount: {
            type: Number,
            required: true,
        },
        // the total number of proper rows that exist on the backend, even if not loaded to the frontend yet
        rowCount: {
            type: Number,
            required: true,
        },
        // (optional) current 0-based page index
        // if not set, the table will handle the page number internally
        page: {
            type: Number,
            default: undefined,
        },
        // if the data is sorted: {key: columnKey, sortDirection: 'asc' | 'desc'}
        sorted: {
            type: Object,
            default: () => {},
        },
        // if set to true, it's possible to drag & drop files into rows.
        // those files will be emitted as list with the "files" event (@files)
        dragnDropFilesOnRow: {
            type: Boolean,
            default: false,
        },
        // if set, checkboxes will be shown next to each proper row contained in the array.
        // when any checkbox is clicked, the new list of selected rows will be emitted
        // using the "selected" event. You can use v-model to model the list of selected rows.
        selected: {
            type: Array,
            default: () => null,
        },
        selectionAsBlacklist: {      // If this is set to true, all rows EXCEPT 'selected' will show a checkmark.
            type: Boolean,
            default: undefined, // If this prop isn't set, there will be no checkbox in the selection column's header
        },
        maxHeaderLines: {
            type: Number,
            default: 1,
        },
        enableToggleHorizontalScrollingConfig: {
            type: Boolean,
            default: false,
        },
        exportConfig: {
            type: Object,
            default: () => null,
        },
        headerActions: {
            type: Array,
            default: () => [],
        },
        menuRowsPerPage: {
            type: Array,
            default: () => []
        },
        pageSize: {
            type: Number,
            default: 25
        },
        loadingCollapsed: { 
            type: Object,
            default: () => {},
        },
        sortableRows: {
            type: Boolean,
            default: false,
        },
        showColumnsConfigEvenNoTitle: {
            type: Boolean,
            default: false,
        },
        // don't set cardView and scrollHorizontally to true at the same time
        cardView: {
            type: Boolean,
            default: false,
        },
        thHoverDisabled: {
            type: Boolean,
            default: false,
        },
        noDataIcon: {
            type: Boolean,
            default: false,
        },
        noDataContent: {
            type: String,
        },
        mobileConfig: {
            type: Object,
            default: () => null,
        },
    },
    data() {
        return {
            scrollHorizontally: false,
            // only used when sorting could happen locally but a sorting config exists from when sorting was manual
            localSortConfig: null,
        };
    },
    computed: {
        ...mapGetters({
            pageTableCount: BROKERDATA_TYPES.GETTERS.GET_BROKER_PAGE_TABLE_SIZE,
        }),
        displayedPageIndex() {
            return this.page != undefined ? this.page : this.pageIndex;
        },
        displayedPage() {
            if (!this.pages[this.displayedPageIndex]) {
                return [];
            }
            return this.pages[this.displayedPageIndex]
            .map(row => {
                return {
                    id: this.constructRowId(row),
                    row,
                    acceptsFile: this.dragnDropFilesOnRow,
                    customRowStyle: row.customRowStyle || {},
                };
            });
        },
        rowsWithId() {
            return Object.values(this.pages).flat()
            .map(row => {
                return {
                    id: this.constructRowId(row),
                    row,
                    acceptsFile: this.dragnDropFilesOnRow,
                    customRowStyle: row.customRowStyle || {},
                };
            });
        },
        displayedItems() {
            return this.displayedPage.length;
        },
        canSortLocally() {
            return this.areAllRowsLoaded;
        },
        sortConfig() {
            if (this.canSortLocally)
                return this.localSortConfig;
            return {
                key: this.sorted?.key,
                ascending: this.sorted?.sortDirection != 'desc',
            };
        },
        isSelected() {
            if (this.selected) {
                const result = {};
                this.selected.forEach(row => {
                    result[this.constructRowId(row)] = true;
                });
                return result;
            }
        },
        areAllSelected() {
            return this.selectionAsBlacklist && !this.selected.length
                || !this.selectionAsBlacklist && this.selected.length == this.rowCount;
        },
        areOnlySomeSelected() {
            return this.selected.length > 0 && !this.areAllSelected;
        },
        areAllRowsLoaded() {
            return this.rowCount == Object.values(this.pages).flat().length;
        },
    },
    watch: {
        canSortLocally(val) {
            if (val && this.sorted?.key) {
                // make sure the table is still sorted the same way as before sorting coule happen locally
                this.localSortConfig = {
                    key: this.sorted?.key,
                    ascending: this.sorted?.sortDirection != 'desc',
                };
            } else {
                this.localSortConfig = undefined;
            }
        },
        'pageTableCount': {
            handler() {
                if ( this.pageTableCount === -1){
                    this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.LOAD_BROKER_PAGE_TABLE_SIZE);
                }
            },
            immediate: true
        },
        $isSmallScreen(newVal) {
            // imagine following scenario: you're on large screen and jump from page 1 to 5
            // meaning you're missing all pages in between
            // if you now switch to small screen it makes sense to completely refresh the table
            this.onPageSizeChange(this.pageSize)
        },
    },
    mounted() {
        if (this.canSortLocally && this.sorted?.key) {
            // make sure the table is still sorted the same way as before sorting coule happen locally
            this.localSortConfig = {
                key: this.sorted?.key,
                ascending: this.sorted?.sortDirection != 'desc',
            };
        }
    },
    methods: {
        onPageChange(index) {
            this.pageIndex = Math.max(0, Math.min(this.pageCount - 1, index));
            this.$emit("page", this.pageIndex);
            if (!this.pages[this.pageIndex])
                this.$emit("requestPage", this.pageIndex);
        },
        onPageSizeChange(pageSize) {
            this.$emit('rowsPerPage', pageSize);
            this.pageIndex = 0;
            this.$emit("page", this.pageIndex);
        },
        handleSort({key, ascending}) {
            if (this.canSortLocally) {
                // all rows are loaded, so sorting can happen locally and doesn't need to emit events
                if (key && this.localSortConfig) {
                    // manual use of sorting configs can only stop when the table isn't sorted
                    this.localSortConfig = {
                        key,
                        ascending,
                    };
                } else {
                    this.localSortConfig = undefined;
                }
                return;
            }
            this.$emit("sort", {key, sortDirection: ascending ? 'asc' : 'desc'});
            // because data has to be loaded again from the start, move to the first page
            this.pageIndex = 0;
            this.$emit("page", this.pageIndex);
        },
        isCheckmarked(id) {
            return !this.isSelected[id] !== !this.selectionAsBlacklist;
        },
        onSelect(id) {
            let isSelected = {...this.isSelected};
            if (isSelected[id])
                delete isSelected[id];
            else
                isSelected[id] = true;
            this.emitSelected(isSelected);
        },
        onSelectAll(value) {
            this.$emit("selectionAsBlacklist", value);
            this.$emit("selected", []);
        },
        emitSelected(isSelected) {
            const rows = Object.values(this.pages)
                .flat();
            const result = rows
                .filter(row => isSelected[this.constructRowId(row)]);
            this.$emit("selected", result);
        },
        onOrderChanged(orderedRows) {
            const { pageIndex, } = this;
            this.$emit('orderChanged', {
                pageIndex,
                rows: [ ...orderedRows, ],
            });
        },
    },
}
</script>
<style src='./table.scss' lang='scss' scoped/>
