var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("DepotpositionsChartsDashboard", {
        attrs: {
          depotid: _vm.depotid,
          subtitle: _vm.depotTitle,
          headerActions: _vm.headerActions,
          headerTitle: "Diagramme"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }