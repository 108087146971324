var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.page
    ? _c(
        "BaseModal",
        {
          ref: "modal",
          attrs: {
            labelButtonCancel: "Schließen",
            confirmDisabled: _vm.saving,
            autoClose: false,
            showConfirmButton: _vm.valid(),
            size: "sm"
          },
          on: {
            onCancelButton: _vm.close,
            onCloseButton: _vm.close,
            onConfirmButton: function($event) {
              return _vm.save()
            }
          }
        },
        [
          _c("DownloadLink", {
            attrs: {
              target: "_blank",
              rel: "noopener noreferer",
              title: this.linkTitle,
              downloadServicePath: "/pages_download",
              queryParams: this.getDownloadsPageParams
            }
          }),
          _c("br"),
          _c("InputField", {
            ref: "input_kun",
            attrs: { label: "Kundennr" },
            on: {
              input: function($event) {
                return _vm.onChangeCustomerNr($event)
              }
            },
            model: {
              value: _vm.page.kundennrIn,
              callback: function($$v) {
                _vm.$set(_vm.page, "kundennrIn", $$v)
              },
              expression: "page.kundennrIn"
            }
          }),
          _vm.page.kundennrIn
            ? _c("InputField", {
                ref: "input_name",
                attrs: { disabled: true, validateUntouched: true },
                model: {
                  value: _vm.page.kundenname,
                  callback: function($$v) {
                    _vm.$set(_vm.page, "kundenname", $$v)
                  },
                  expression: "page.kundenname"
                }
              })
            : _vm._e(),
          _c("InputField", {
            attrs: { label: "Bezeichnung", validateUntouched: true },
            model: {
              value: _vm.page.defaultFileBezeichnung,
              callback: function($$v) {
                _vm.$set(_vm.page, "defaultFileBezeichnung", $$v)
              },
              expression: "page.defaultFileBezeichnung"
            }
          }),
          _c("ComboBox", {
            attrs: {
              label: "Bitte wählen Sie die Dokumentart.",
              firstEmpty: true,
              values: this.config ? this.config.items : null,
              validateUntouched: true
            },
            on: {
              change: function($event) {
                return _vm.onChangeDeck($event)
              }
            },
            model: {
              value: _vm.page.deckblattLabel,
              callback: function($$v) {
                _vm.$set(_vm.page, "deckblattLabel", $$v)
              },
              expression: "page.deckblattLabel"
            }
          }),
          _vm.isLeg(_vm.page)
            ? _c("DatePickerField", {
                attrs: {
                  isComponentHalfSize: true,
                  isValueAsString: true,
                  label: "Ablaufdatum des Ausweises"
                },
                model: {
                  value: _vm.page.ablaufDatum,
                  callback: function($$v) {
                    _vm.$set(_vm.page, "ablaufDatum", $$v)
                  },
                  expression: "page.ablaufDatum"
                }
              })
            : _vm._e(),
          _vm.isLeg(_vm.page)
            ? _c("ComboBox", {
                attrs: {
                  label: "Ausweisinhaber",
                  firstEmpty: false,
                  values: this.persons,
                  validateUntouched: true
                },
                model: {
                  value: _vm.page.legiInhaberPersonId,
                  callback: function($$v) {
                    _vm.$set(_vm.page, "legiInhaberPersonId", $$v)
                  },
                  expression: "page.legiInhaberPersonId"
                }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }