<template>
<div>
	<div class="">
		<div v-if="loading" class="box__container text-centered">
			<AnimatedSpinner/>
		</div>
		<NoData class="box__container" v-else-if="!optionsOptimierungsergebnis.series" />
		<div v-else>
			<div class="box__container">
        <div class="box__title">Optimierungsergebnis</div>
				<BarChart format="percent" height="300px" :chartOptions="optionsOptimierungsergebnis" />
			</div>
			<div class="box__container">
        <div class="box__title">Jahresperformance</div>
				<BarChart format="percent" height="300px" :chartOptions="optionsJahresperformance" />
			</div>
		</div>
	</div>
</div>
</template>

<script>
import INVESTMENT_ADVICE from "@/store/investmentAdvice/types";
import { mapGetters } from "vuex";
import BarChart from "@/components/charts/echarts/BarChart.vue";
import NoData from "@/components/core/NoData.vue";
import AnimatedSpinner from "@/components/core/AnimatedSpinner.vue";
import { formatNumber } from '@/helpers/number-formatter.js';

const BARLABEL = function (params) {
	if (params?.value == null || isNaN(params?.value)) {
		return '';
	}
	return formatNumber(params.value, 2) + '%';
};
const SERIE = function (name, data) { 
	return {
		name: name, 
		type: 'bar',
		label: { show: true, position: 'top', formatter: BARLABEL, distance: 10 },
		data: data, 
		showBackground: false,
		itemStyle: {
			shadowColor: 'rgba(0, 0, 0, 0.3)',
			shadowBlur: 6,
			shadowOffsetX: 2,
			shadowOffsetY: 0,
		}
	};
};
const OPTION = function (xAxis, series)  {
	return {
		legend: {
			show: true,
			bottom: 0,
		},
		yAxis: {
			show: false,
			type: 'value',
		},
		xAxis: {
			type: 'category',
			data: xAxis,
			axisTick: {show: false},
			axisLabel: {interval: 0,  margin: 10, align: 'center', fontSize: 15 }
		},
		series: series,
	};
};

export default {
	components:{
		BarChart,        
		NoData,
		AnimatedSpinner,
	},
	props: {
		adviceId: {
			type: Number,
			default: null,
		},
	},
	data() {
		return {
			loading: false,
			optionsOptimierungsergebnis: {},
			optionsJahresperformance: {},
		}
	},
  
	computed: {
	...mapGetters({
		backtestAdvice: INVESTMENT_ADVICE.GETTERS.BACKTEST_ADVICE,
	}),          
	},
	mounted() {
		if ( !this.backtestAdvice?.loaded || !this.backtestAdvice?.data?.performance) {
			this.loading = true;
			this.$store.dispatch(INVESTMENT_ADVICE.ACTIONS.FETCH_OUTCOME, {
				adviceId: this.adviceId,
				type: INVESTMENT_ADVICE.OUTCOME.BACKTEST_ADVICE,
				refresh: true,
				sendDetails: true,
			});
		} else {
			this.fillCharts();
		}
	},

	methods:{
		fillCharts() {	
			if (this.backtestAdvice?.data?.performance) {
				const data = this.backtestAdvice.data.performance;
				if (data.Aktuelles_Depot || data.Depotvorschlag) {
					const serieO = [];
					const serieJ = [];
					const xAxisO = ['Historische Rendite', 'Historische Rendite p.a.', 'Historisches Risiko'];
					const jahresPerformance = data.Aktuelles_Depot?.jahresPerformance || data.Depotvorschlag?.jahresPerformance;
					const xAxisJ = jahresPerformance.map( p => p[0]);
					if (data.Aktuelles_Depot) {
						serieO.push( SERIE('Aktuelles Depot', [
							Math.round(data.Aktuelles_Depot.wentw * 100)/100, 
							Math.round(data.Aktuelles_Depot.wentwPA * 100)/100, 
							data.Aktuelles_Depot.vola
						]));
						const jahresPerformance = data.Aktuelles_Depot.jahresPerformance;
						serieJ.push( SERIE('Aktuelles Depot', jahresPerformance.map( p => p[1])) );
					}
					if (data.Depotvorschlag) {
						serieO.push( SERIE('Depotvorschlag',[
							Math.round(data.Depotvorschlag.wentw * 100)/100, 
							Math.round(data.Depotvorschlag.wentwPA * 100)/100, 
							data.Depotvorschlag.vola
						]));
						const jahresPerformance = data.Depotvorschlag.jahresPerformance;
						serieJ.push( SERIE('Depotvorschlag', jahresPerformance.map( p => p[1])) );
					}
					this.optionsOptimierungsergebnis = OPTION(xAxisO, serieO);
					this.optionsJahresperformance = OPTION(xAxisJ, serieJ);
				}
			}
		},
	},

	watch: {
		backtestAdvice() {
			this.fillCharts();
			this.loading = !this.backtestAdvice?.loaded;
		},
	},

}
</script>

<style>
</style>