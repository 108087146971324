import GELDWAESCHEBELEHRUNG_TYPES from './types';

export default {
  [GELDWAESCHEBELEHRUNG_TYPES.GETTERS.LAYOUT_METADATA](state) {
    return state.layoutMetadata;
  },
 
  [GELDWAESCHEBELEHRUNG_TYPES.GETTERS.WAG_PRUEFUNG_LINK](state) {
    return state.wagPruefungLink;
  },
  
}