export default class ParameterData {  
  zielsummeberechnung = false;
	anlagesumme = 0.0;
	bezeichnung = 'unbenannt';
	vorgang;
	bestandsdepot = "-4";
	ziellagerstelle;
	backtestStart = new Date();
	backtestEnd = new Date();
	isMusterportfolio = false;
	depotoptimierung = false;
	zielsumme = 0.0;
};
