<template>
  <div>
    <PageHeaderTitleNavigation title="" />

    <div class="box__container">
      <Table v-if="rows.length"
      :title="title"
      :headers="headers"
      :rows="rows"
      >
      </Table>
    </div>
  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import { mapGetters } from 'vuex';
import Table from "@/components/table2/Table.vue";
import {TextColumn} from "@/components/table2/table_util.js";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';

export default {
  props: {
        title: {
            type: String,
            default: 'MSC3 Server Build Info',
        }
    },
    components: {
        Table, OptionMenu, PageHeaderTitleNavigation
    },
  computed: {
    ...mapGetters({
      buildInfo: CORE_TYPES.GETTERS.MSC_BUILD_INFO,
    }),
    mscBuildInfo() {
        return this.buildInfo||new Map()
    },
    headers() {
            return { 
                lockedLeft: [
                  TextColumn("key", "Name"),
                  TextColumn("val", "Wert"),
                ],
                center: [
                ],
                lockedRight: [
                ],
            }
        },
  rows() {
    const rows = []
    for (const [key, val] of Object.entries(this.mscBuildInfo)) {

    rows.push({key, val})
    }
    return rows
  },
 },
  mounted() {
    this.$store.dispatch(CORE_TYPES.ACTIONS.MSC_BUILD_INFO);
  },
    
}
</script>
