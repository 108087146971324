<template >
    <div class="event-body" >
        <BaseModal ref="modal" labelButtonCancel="Nein" @onConfirmButton="save" labelButtonConfirm="Ja" @onCancelButton="close" :showConfirmButton="true" modalTitle="Dokument bestätigen">
            
            <BoxContainer>
                
                    <label >{{tit }}</label> 
                

            </BoxContainer>





        </BaseModal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import InputField from "@/components/core/forms/InputField.vue"
import BaseModal from '@/components/core/BaseModal.vue';
import V_P_TYPES from "@/store/versandProtokolle/types";
import BoxContainer from '@/components/core/BoxContainer.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue'
import LOG_TYPES from '@/store/log/types';
import { buildMessage } from '@/helpers/log-message-helper';
export default {
    data() {

        return {
           
           
            id:null,
            subject:null,
            date:null,
            dat:null
           
        };
    },
    computed: {

        ...mapGetters({

         
            


        }),

        tit(){
            return "  Wollen Sie  "+this.subject+" von "+this.date+" ablehnen ?"
        }


    },
    mounted() {

    },
    
    methods: {
       
         async save() {
            
            if (this.id != null )   {
              
               
                await this.$store.dispatch(V_P_TYPES.ACTIONS.TOERROR, this.id);
                this.$store.commit(V_P_TYPES.MUTATIONS.RELOAD,true);


            } 
        },

        async open(i,sub,dd) {
             this.id=i; 
              
             this.subject=sub;
            
             this.date=dd;
            this.$refs.modal?.open();
        },
        close() {

            this.$refs.modal.close();
        },

       
        
    },
    components: {
        BaseModal,
       
        InputField,
        InputTextArea,
        
        BoxContainer
    },

}
</script>

<style scoped>
