var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _c(
        "BaseButtonsContainer",
        [
          _c(
            "BaseButton",
            {
              attrs: {
                disabled:
                  !_vm.hasProhypRequiredField || _vm.isEditable || _vm.loading
              },
              on: {
                click: function($event) {
                  return _vm.updateFromProhyp()
                }
              }
            },
            [_vm._v("Daten von Prohyp abholen")]
          ),
          _c(
            "BaseButton",
            {
              attrs: {
                disabled:
                  !_vm.hasProhypRequiredField || !_vm.isEditable || _vm.loading
              },
              on: {
                click: function($event) {
                  return _vm.sendToProhyp()
                }
              }
            },
            [_vm._v("Zu Prohyp schicken")]
          ),
          _c(
            "BaseButton",
            {
              attrs: { disabled: _vm.loading },
              on: {
                click: function($event) {
                  return _vm.openProhypPortal()
                }
              }
            },
            [_vm._v("Prohyp Portal")]
          )
        ],
        1
      ),
      _c(
        "div",
        [
          _c("InputField", {
            attrs: {
              label: "Prohyp Antrag Nummer",
              isComponentHalfSize: true,
              disabled: !_vm.isEditable
            },
            on: {
              change: function($event) {
                return _vm.dataChanged()
              }
            },
            model: {
              value: _vm.form.ehypAntragsnr,
              callback: function($$v) {
                _vm.$set(_vm.form, "ehypAntragsnr", $$v)
              },
              expression: "form.ehypAntragsnr"
            }
          }),
          _c("ComboBox", {
            attrs: {
              label: "Art des Objektes",
              firstEmpty: true,
              isComponentHalfSize: true,
              values: _vm.prohypCombos.combitypeCombo,
              disabled: !_vm.isEditable,
              validateUntouched: ""
            },
            on: {
              change: function($event) {
                return _vm.dataChangedCombo("combiType", $event)
              }
            },
            model: {
              value: _vm.form.combiType,
              callback: function($$v) {
                _vm.$set(_vm.form, "combiType", $$v)
              },
              expression: "form.combiType"
            }
          }),
          _c("ComboBox", {
            attrs: {
              label: "Variante",
              firstEmpty: true,
              isComponentHalfSize: true,
              values: _vm.prohypCombos.subtypeCombo,
              disabled: !_vm.isEditable,
              validateUntouched: ""
            },
            on: {
              change: function($event) {
                return _vm.dataChangedCombo("subType", $event)
              }
            },
            model: {
              value: _vm.form.subType,
              callback: function($$v) {
                _vm.$set(_vm.form, "subType", $$v)
              },
              expression: "form.subType"
            }
          }),
          _c("ComboBox", {
            attrs: {
              label: "Nutzung der Immobilie",
              firstEmpty: true,
              isComponentHalfSize: true,
              values: _vm.prohypCombos.utilizationCombo,
              disabled: !_vm.isEditable,
              validateUntouched: ""
            },
            on: {
              change: function($event) {
                return _vm.dataChangedCombo("utilization", $event)
              }
            },
            model: {
              value: _vm.form.utilization,
              callback: function($$v) {
                _vm.$set(_vm.form, "utilization", $$v)
              },
              expression: "form.utilization"
            }
          }),
          _c("div", [
            _vm._v(
              "Stellt der Kunde die Finanzierungsanfrage zusammen mit einem weiteren Darlehensnehmer, bitte erweitern Sie seine Kundendaten vorher."
            )
          ]),
          _c("ComboBox", {
            attrs: {
              label: "Weiterer Darlehensnehmer",
              isComponentHalfSize: true,
              values: _vm.prohypCombos.partnerCombo,
              disabled: !_vm.isEditable
            },
            on: {
              change: function($event) {
                return _vm.dataChangedCombo("partner", $event)
              }
            },
            model: {
              value: _vm.form.partner,
              callback: function($$v) {
                _vm.$set(_vm.form, "partner", $$v)
              },
              expression: "form.partner"
            }
          }),
          _c("InputToggleSwitch", {
            attrs: {
              label:
                "Im Rahmen dieser Finanzierungsanfrage fallen weitere Honorare oder Spesen an",
              inLineLabel: true,
              disabled: !_vm.isEditable
            },
            on: {
              input: function($event) {
                return _vm.dataChanged()
              }
            },
            model: {
              value: _vm.form.feesExtra,
              callback: function($$v) {
                _vm.$set(_vm.form, "feesExtra", $$v)
              },
              expression: "form.feesExtra"
            }
          }),
          _c("EntitySelectorPersonPicker", {
            attrs: {
              label: "1. Antragssteller",
              isComponentHalfSize: true,
              values: _vm.prohypCombos.person1Combo,
              disabled: !_vm.isEditable
            },
            on: {
              change: function($event) {
                return _vm.dataChangedCombo("personId1", $event)
              }
            },
            model: {
              value: _vm.form.personId1,
              callback: function($$v) {
                _vm.$set(_vm.form, "personId1", $$v)
              },
              expression: "form.personId1"
            }
          }),
          _c("EntitySelectorPersonPicker", {
            attrs: {
              label: "2. Antragssteller",
              isComponentHalfSize: true,
              values: _vm.prohypCombos.person2Combo,
              disabled: !_vm.isEditable || _vm.isPartnerInvalid
            },
            on: {
              change: function($event) {
                return _vm.dataChangedCombo("personId2", $event)
              }
            },
            model: {
              value: _vm.form.personId2,
              callback: function($$v) {
                _vm.$set(_vm.form, "personId2", $$v)
              },
              expression: "form.personId2"
            }
          }),
          _c(
            "div",
            [
              _c("div", { staticClass: "box__title" }, [
                _vm._v("Vertragsinformationen")
              ]),
              _c("InputField", {
                attrs: {
                  label: "Eingereicht",
                  isComponentHalfSize: true,
                  disabled: true
                },
                model: {
                  value: _vm.form.created,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "created", $$v)
                  },
                  expression: "form.created"
                }
              }),
              _c("InputField", {
                attrs: {
                  label: "Status",
                  isComponentHalfSize: true,
                  disabled: true
                },
                model: {
                  value: _vm.form.status,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "status", $$v)
                  },
                  expression: "form.status"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("BaseModal", {
        ref: "prohypResponse",
        attrs: {
          modalTitle: _vm.prohypResponse && _vm.prohypResponse.title,
          labelButtonConfirm: "OK",
          showCancelButton: false
        },
        on: {
          close: function($event) {
            _vm.prohypResponse = null
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.prohypResponse && _vm.prohypResponse.message) +
                    " "
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }