var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.hasHauptdaten
      ? _c(
          "div",
          { staticClass: "box__container" },
          [
            _c("Hauptdaten", {
              attrs: {
                disabled: false,
                data: _vm.getSpartenDataHauptdata,
                sparteId: _vm.getSparteId,
                comboboxValues: _vm.comboboxValues,
                stepConfig: _vm.stepConfig,
                layoutModus: _vm.layoutModus
              }
            })
          ],
          1
        )
      : _vm._e(),
    _vm.components && _vm.components.length
      ? _c(
          "div",
          [
            _c("ComponentList", {
              attrs: {
                components: _vm.components,
                data: _vm.getSpartenData,
                comboboxValues: _vm.getComboboxValues,
                disabled: false
              },
              on: {
                change: function($event) {
                  return _vm.inputChanged($event)
                }
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }