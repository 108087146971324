<template>
  <div>
     <PageHeaderTitleNavigation 
      title="Courtageabrechnungen" 
      :actions="headerActions"
      @action-PDF="goto('abrechnungsliste')"
      @action-XLSX="goto('buchungspositionen')"
    />

    <div v-if="init" class="page">
      <div v-if="form.hatVersicherung" class="box__container">
        <h4 class="box__title">Versicherungsbereich</h4>
        <div class="row">
          <ComboBox class="col-12 col-sm-4"
            :label="form.fpcombo1[1]" :values="form.combos[form.fpcombo1[0].toLowerCase()]"
            @change="onChangefp1"
          ></ComboBox>
          <ComboBox class="col-12 col-sm-4"
            :label="form.fpcombo2[1]" :values="fpcombo2"
            v-model="fpdatum"
            :sortComboboxValues="false"
          ></ComboBox>
          <div class="col-12">
            <BaseButton @click="onClickBtn(form.fpbutton[0], false)" :label="form.fpbutton[1]"  :disabled="!fpdatum"/>
          </div>
        </div>
      </div>
      <div class="">
        <div class="box__container">
          <h4 v-if="form.hatVersicherung" class="box__title">Fondsbereich</h4>
          <div class="row">
            <ComboBox  class="col-12 col-sm-6 col-lg-4 item-column"
              :label="form.comboDatum[1]" :values="form.combos[form.comboDatum[0].toLowerCase()]" 
              v-model="datum"
              :sortComboboxValues="false"
            ></ComboBox>
          </div>
        </div>
        <div class="row" v-if="rows.length">
          <div class="col-12 col-sm-6 col-md-4 item-content text-center" v-for="(row,idx) in rows" :key="idx">
            <div class="box__container">
              <div class="item-cell" @click="onClickBtn(row.value, true)">
                <div class="m-1">
                  <PhFlowArrow class="icon" v-if="row.icon==='PhFlowArrow'"></PhFlowArrow>
                  <PhFilePdf class="icon" v-if="row.icon==='PhFilePdf'"></PhFilePdf>
                  <PhFileXls class="icon" v-if="row.icon==='PhFileXls'"></PhFileXls>
                  <PhFileCsv class="icon" v-if="row.icon==='PhFileCsv'"></PhFileCsv>
                </div>
                <div class="">{{row.abrechnung}}</div>
              </div>
              <div v-if="meldung[row.value]" class="display-errors" >{{meldung[row.value]}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ABRECHNUNG_TYPES from '@/store/abrechnungen/types';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import ComboBox from "@/components/core/forms/ComboBox.vue";
import { base64ToArrayBuffer } from '@/helpers/download-helper.js';
import { viewDocument } from '@/components/core/download/DownloadLink.vue';
import { PhFlowArrow, PhFilePdf, PhFileXls, PhFileCsv } from "phosphor-vue";
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';

export default {
  name: 'Abrechnungen',
  components: {
    OptionMenu, PageHeaderTitleNavigation, ComboBox, BaseButton, 
    PhFlowArrow, PhFilePdf, PhFileXls, PhFileCsv
  },
  props: {
  },
  data: function() {
    return {
      init: false,
      fpcombo2: [{label: 'Bitte eine Gesellschaft auswälen', value: ''}],
      datum: '',
      fpdatum: '',
      meldung: {},
      fpcombo1: '',
      rows: [],
      versandMail: null,
      
    }
  },
  mounted() {
    if (!this.form.buttons) {
      this.$store.dispatch(ABRECHNUNG_TYPES.ACTIONS.INIT );
    } else {
      this.initform();
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({to, from});
    next();
  },
  computed: {
    ...mapGetters({
      form: ABRECHNUNG_TYPES.GETTERS.FORM,
    }),
    headerActions() {
      return [
        PageHeaderSimpleAction('PDF', 'Mehrere Abrechnungen exportieren').withDisabled(() =>!(this.form?.combos?.abrechnungsdatum)),
        PageHeaderSimpleAction('XLSX', 'Buchungspositionen exportieren').withDisabled(() => !(this.form?.combos?.abrechnungsdatum)),
      ];
    },
  },
  watch: {
    'form': 'initform'
  },
  methods: {
    initform() {
      if (process.env.VUE_APP_USER === 'hannes') console.log('initform', JSON.parse(JSON.stringify(this.form)) );
      if (this.form.combos[this.form.comboDatum[0].toLowerCase()].length) {
        this.datum = this.form.combos[this.form.comboDatum[0].toLowerCase()][0].value;
      }
      const fonds = [];
      this.form.buttons.forEach(item => {
        let icon = 'PhFilePdf';
        if (-1 !== item[1].indexOf('Versand')) {
          icon = 'PhFlowArrow';
        } else if (-1 !== item[1].indexOf('Excel')) {
          icon = 'PhFileXls';
        } else if (-1 !== item[1].indexOf('CSV')) {
          icon = 'PhFileCsv';
        }
        if ( item[0] === 'BUTTON_EMAIL') {
          this.versandMail = {
            abrechnung: item[1],
            value: item[0],
            icon: icon,
          }
        } else {
          fonds.push({
            abrechnung: item[1],
            value: item[0],
            icon: icon,
          })
        }
      });
      this.rows = fonds
      this.init = true;
    },
    async onChangefp1(value) {
      this.fpcombo1 = value;
      let response = await this.$store.dispatch(ABRECHNUNG_TYPES.ACTIONS.GETDATA, 
          {value: value, name: this.form.fpcombo1[0],  action: this.form.fpcombo1[0], combo: 'ja'} );
      if (response.data.combos) {
        if (response.data.combos[this.form.fpcombo2[0].toLowerCase()]) {
          this.fpcombo2 = response.data.combos[this.form.fpcombo2[0].toLowerCase()];
          if (this.fpcombo2.length) {
            this.fpdatum = this.fpcombo2[0].value;
          }
        }
      }
    },
    goto(arg) {
      this.$router.push({path: '/intern/' + arg});
    },
    async onClickBtn(action, fonds) {
      this.meldung = {};
      const param = fonds ?
        {value: this.datum, name: this.form.comboDatum[0], action: action}
        :
        {value: this.fpdatum, name: this.form.fpcombo2[0], action: action, value2: this.fpcombo1, name2: this.form.fpcombo1[0]}
        ;
      let response = await this.$store.dispatch(ABRECHNUNG_TYPES.ACTIONS.GETDATA, param );
      if (response.data.pdf) {
        const filename = response.data.filename;
        const file = base64ToArrayBuffer(response.data.pdf);
        if (filename.indexOf('.pdf') > 0 ) {
          viewDocument({
            data: file,
            filename,
            contentType: 'application/pdf',
          }, true);
        } else if (filename.indexOf('.xls') > 0 ) {
          viewDocument({
            data: file,
            filename,
            contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            fileExtension: 'xlsx',
          }, true);
        } else {
          viewDocument({
            data: file,
            filename,
            contentType: 'text/plain',
            fileExtension: 'txt',
          }, true);
        } 
      } else if (response.data.meldung) {
        this.meldung = {[action]: response.data.meldung};
      } else if (response.data.error) {
        this.meldung = {[action]: response.data.error};
      }
    },
  },
}
</script>

<style scoped>
.item-content {
  display: flex;
}
.item-content > .box__container {
  flex: 1;
}
.item-column {
  width: 300px;
  padding-bottom: 14px;
  display: inline-block;
}
.item-column2 {
  align-self: end;
  margin-bottom: 14px;
}
.text-center {
  text-align: center;
}
.item-cell {
  cursor: pointer;
}
.icon {
  width: 24px;
}
.display-errors {
  color: var(--color-danger);
}
</style>