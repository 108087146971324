<template>
  <BaseToolbarButton 
    title="Einzug"
    :icon="icon"
    :active="active"
    :disabled="disabled"
    @click="action"
    content="Einzug"
    v-tippy="{ arrow: true }"/>
</template>

<script>
import { PhTextIndent } from 'phosphor-vue';
import BaseToolbarButton from '../../base-components/BaseToolbarButton.vue';

export default {
  components: {
    BaseToolbarButton,
  },

  props: {
    editor: {
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    icon() {
      return PhTextIndent;
    },
    
    active() {
      return this.editor?.isActive('indent')
    }
  },

  methods: {
    action() {
      this.editor?.commands.indent()
    },
  }

}
</script>
