var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container",
      on: {
        click: function($event) {
          return _vm.$emit("click", _vm.step.stepkey)
        }
      }
    },
    [
      _c(
        "span",
        {
          staticClass: "stop-content clickable",
          class: [_vm.step.current && "stop-content__current"]
        },
        [
          _vm.step.loading
            ? [
                _c(
                  "svg",
                  {
                    staticClass: "stop-loading",
                    style: { "animation-delay": _vm.index * 100 + "ms" },
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      viewBox: "0 0 52 52"
                    }
                  },
                  [
                    _c("circle", {
                      staticClass: "stop-loading__circle",
                      style: { "animation-delay": _vm.index * 100 + "ms" },
                      attrs: {
                        cx: "26",
                        cy: "26",
                        r: "18",
                        "stroke-width": "6",
                        "stroke-dasharray": "86",
                        fill: "none"
                      }
                    })
                  ]
                ),
                _c("div", { staticClass: "stop-loading-small" })
              ]
            : [
                _vm.step.success
                  ? [
                      _c(
                        "svg",
                        {
                          staticClass: "stop-checkmark",
                          style: { "animation-delay": _vm.index * 100 + "ms" },
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            viewBox: "0 0 52 52"
                          }
                        },
                        [
                          _c("circle", {
                            staticClass: "stop-checkmark__circle",
                            style: {
                              "animation-delay": _vm.index * 100 + "ms"
                            },
                            attrs: { cx: "26", cy: "26", r: "25", fill: "none" }
                          }),
                          _c("path", {
                            staticClass: "stop-checkmark__content",
                            style: {
                              "animation-delay": _vm.index * 100 + "ms"
                            },
                            attrs: {
                              fill: "none",
                              "stroke-width": "5",
                              d: "M14.1 27.2l7.1 7.2 16.7-16.8"
                            }
                          })
                        ]
                      ),
                      _c("div", { staticClass: "stop-checkmark-small" })
                    ]
                  : _vm._e(),
                _vm.step.warning
                  ? [
                      _c(
                        "svg",
                        {
                          staticClass: "stop-exclamation-mark",
                          style: { "animation-delay": _vm.index * 100 + "ms" },
                          attrs: {
                            viewBox: "0 0 52 52",
                            xmlns: "http://www.w3.org/2000/svg"
                          }
                        },
                        [
                          _c("circle", {
                            staticClass: "stop-exclamation-mark__circle",
                            style: {
                              "animation-delay": _vm.index * 100 + "ms"
                            },
                            attrs: { cx: "26", cy: "26", r: "25", fill: "none" }
                          }),
                          _c("path", {
                            staticClass: "stop-exclamation-mark__content",
                            style: {
                              "animation-delay": _vm.index * 100 + "ms"
                            },
                            attrs: {
                              fill: "none",
                              d:
                                "m28.836 39.219q0 1.1484-0.82031 1.9922-0.79688 0.84375-2.0156 0.84375t-2.0391-0.84375q-0.79688-0.84375-0.79688-1.9922 0-1.1953 0.82031-2.0156 0.82031-0.82031 2.0156-0.82031 1.1953 0 2.0156 0.82031 0.82031 0.82031 0.82031 2.0156zm-5.3906-29.273h5.1094l-1.4531 22.312h-2.2031z"
                            }
                          })
                        ]
                      ),
                      _c("div", { staticClass: "stop-exclamation-mark-small" })
                    ]
                  : _vm._e()
              ]
        ],
        2
      ),
      _c(
        "div",
        {
          class: [
            "stop-label clickable",
            _vm.first && "stop-label--left",
            !_vm.first && !_vm.last && "class.stop-label--center",
            _vm.last && "class.stop-label--right",
            _vm.step.current && "class.stop-label__current"
          ]
        },
        [_vm._v(_vm._s(_vm.step.label))]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }