<template>
    <BaseModal
        ref="editModal"
        :modalTitle="title"
        closeButtonLabel="Übersicht"
        :showDefaultButtons="false"
    >

        <template #modalActions v-if="actionHeader">
            <component
                :is="actionHeader.rowElement"
                :key="actionHeader.key"
                :column="actionHeader.key"
                :row="row"
                :tid="tableId + ':' + actionHeader.key + ':' + row.id + ':'"
                v-bind="actionHeader.props"
                @action="onAction"
                :class="actionHeader.colorFn && actionHeader.colorFn(row)"
            />
        </template>

        <template #default>
            <!-- CONTENT -->
            <template v-for="{ key, hideOnModalSheet, label, rowElement, props, colorFn, isLink, isLinkIf, } in headersFlat">
                <div class="row-container" :key="key" v-if="!hideOnModalSheet && visibleInCard(key, row)">
                    <div class="row-line-label">
                        <slot :name="'header_' + key" v-bind="{ key }">
                            <span>{{ label }}</span>
                        </slot>
                    </div>

                    <div class="row-line-value">
                        <slot v-if="!rowElement" :name="key" :row="row" :id="row.id"/>
                        <a v-else-if="isRowLink(row, isLink, isLinkIf)" :key="key" @click="clickCell(row, key)"
                            :tid="_generateTidFromString(tableId + ':' + key + ':' + row.id)"
                        >
                            <component
                                :is="rowElement"
                                :key="key"
                                :column="key"
                                :row="row"
                                :tid="tableId + ':' + key + ':' + row.id + ':'"
                                v-bind="props"
                            />
                        </a>
                        <component
                            v-else
                            :is="rowElement"
                            :key="key"
                            :column="key"
                            :row="row"
                            :tid="tableId + ':' + key + ':' + row.id + ':'"
                            v-bind="props"
                            @action="onAction"
                            :class="colorFn && colorFn(row)"
                        />
                    </div>
                </div>
            </template>
        </template>
    </BaseModal>
</template>

<script>
import ActionCell from '@/components/table2/cellTypes/ActionCell.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";

export default {
    mixins: [InteractiveHelpCommonsMixin],
    components: {
        BaseModal,
    },
    props: {
        headersFlat: { 
            type: Array,
            default: () => [],
        },
        row: { 
            type: Object,
            required: true,
        },
        title: {
            type: String,
            default: "",
        },
        tableId: {
            // tableId must be unique. This Id is being used to save the column config into backend
            // a UUID randomly generated is enough. https://www.uuidgenerator.net/version4 or any other generator.
            type: String,
        },
    },
    computed: {
        actionHeader() {
            return this.headersFlat.find(header => header.rowElement === ActionCell)
        },
    },
    methods: {
		visibleInCard(headerKey, row) {
			return (
				headerKey !== "_v" &&
				headerKey !== "__SELECT__" &&
				headerKey in row
                // (row[headerKey] !== undefined && row[headerKey] !== null && row[headerKey] !== "")
			)
		},
        open() {
            this.$refs.editModal.open()
        },
        close() {
            this.$refs.editModal.close()
        },        
        onAction(event) {
            this.$emit('onAction', event)
        },
        clickCell(row, key) {
            this.$emit('onClick', row, key)
        },
        isRowLink(row, isLink, isLinkIf) {
            if (isLink) {
                return true
            }

            if (isLinkIf) {
                if (isLinkIf instanceof Function) {
                    return isLinkIf(row)
                } else {
                    return row[isLinkIf]
                }
            }
            
            return false
        },
    },
}
</script>

<style scoped>

.row-container {
    padding: 8px 0;
    display: flex;
	justify-content: space-between;
    border-bottom: 1px solid #c4c4c4;
}

.row-container:first-child {
	padding-top: 0;
}

.row-container:last-child {
	border-bottom: none;
}

.row-line-label {
	margin-right: 8px;
	font-weight: 600;
}

.row-line-value {
    text-align: right;
}
</style>