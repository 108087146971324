var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "menu-structure-edit__container" },
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Menüstruktur",
          subtitle: _vm.menuStructure.label,
          actions: _vm.headerActions
        },
        on: {
          "action-DISCARD": function($event) {
            return _vm.discardChanges()
          },
          "action-EDIT": function($event) {
            return _vm.editMenuStructure()
          }
        }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.loading
            ? _c("GhostLoading", { attrs: { type: "title" } })
            : [
                _c("MenuStructureSortable", {
                  attrs: { menus: _vm.primaryMenu },
                  on: {
                    orderChanged: function($event) {
                      return _vm.orderChanged($event)
                    },
                    add: function($event) {
                      return _vm.addMenu($event)
                    },
                    remove: function($event) {
                      return _vm.removeMenu($event)
                    },
                    addCustom: function($event) {
                      return _vm.openAddCustomMenuModal($event)
                    }
                  }
                })
              ]
        ],
        2
      ),
      _c("AddMenuStructureModal", {
        ref: "addMenuStructureModal",
        on: { saved: _vm.onMenuStructureSaved }
      }),
      _c("OptionMenuAddCustomMenuModal", {
        ref: "optionMenuAddCustomMenuModal",
        on: {
          add: function($event) {
            return _vm.addMenu($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }