export const MUTATION_PREFIX = 'sipgate_';
export const ACTIONS_PREFIX = 'sipgate_';
export const GETTERS_PREFIX = 'sipgate_';

export default {
  MUTATIONS: {
    
    FILTER: MUTATION_PREFIX + 'FILTER',
    
  },
  ACTIONS: {
    FILTER: ACTIONS_PREFIX + 'FILTER',
    DELETE: ACTIONS_PREFIX + 'DELETE',
    INSERT: ACTIONS_PREFIX + 'INSERT',
  },
  GETTERS: {
    
    FILTER: GETTERS_PREFIX + 'FILTER',
    
  }
}