var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: _vm.titleStr + " öffnen" }
      }),
      _c(
        "div",
        {
          staticClass: "box__container",
          attrs: {
            tid: _vm._generateTidFromString(
              "box__container_antrag_beratungsmappe_selector"
            )
          }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _vm._v(
                " Soll für " +
                  _vm._s(_vm.antragStr) +
                  " eine neue Beratungsmappe angelegt werden oder möchten Sie ihn zu einer bestehenden Mappe hinzufügen? "
              )
            ])
          ]),
          _c("div", [
            _c(
              "div",
              { staticClass: "layout__negative-margin--8" },
              [
                _c(
                  "BaseButton",
                  {
                    attrs: { isSecondary: true },
                    on: {
                      click: function($event) {
                        return _vm.existingBeratungsmappe()
                      }
                    }
                  },
                  [_vm._v(" Eine bestehende Mappe öffnen ")]
                ),
                _c(
                  "BaseButton",
                  {
                    attrs: { isSecondary: true },
                    on: {
                      click: function($event) {
                        return _vm.openCreateBeratungsmappeModal()
                      }
                    }
                  },
                  [_vm._v(" Eine neue Mappe anlegen ")]
                ),
                _c(
                  "BaseButton",
                  {
                    on: {
                      click: function($event) {
                        return _vm.withoutBeratung()
                      }
                    }
                  },
                  [_vm._v(" Ohne Beratung ")]
                )
              ],
              1
            )
          ])
        ]
      ),
      _c("EditBeratungsmappeModal", {
        ref: "createBeratungsmappeModal",
        on: { confirm: _vm.createBeratungsmappe }
      }),
      _vm.showBeratungsmappeList
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _vm.rows.length
                ? _c("Table", {
                    attrs: {
                      tableId: "cc8c185e-0263-4401-b654-903010d11f8e",
                      headers: _vm.headers,
                      rows: _vm.rows,
                      rowsPerPage: 20
                    },
                    on: { "click-art": _vm.openExistingBeratungsmappe }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }