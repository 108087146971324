var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "antrag-components__container" }, [
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("FormLabel", {
          attrs: {
            label:
              "Bitte tragen Sie hier die Daten des Depots ein, von dem die Wertpapiere übertragen werden sollen"
          }
        }),
        _c("FormHeader", {
          attrs: { label: "Daten der bisher depotführenden Gesellschaft" }
        }),
        _c("InputField", {
          attrs: { label: "Name der Bank" },
          model: {
            value: _vm.positionData.bisherManuellName,
            callback: function($$v) {
              _vm.$set(_vm.positionData, "bisherManuellName", $$v)
            },
            expression: "positionData.bisherManuellName"
          }
        }),
        _c("InputField", {
          attrs: { label: "Straße" },
          model: {
            value: _vm.positionData.bisherManuellStrasse,
            callback: function($$v) {
              _vm.$set(_vm.positionData, "bisherManuellStrasse", $$v)
            },
            expression: "positionData.bisherManuellStrasse"
          }
        }),
        _c("InputField", {
          attrs: { label: "PLZ" },
          model: {
            value: _vm.positionData.bisherManuellPLZ,
            callback: function($$v) {
              _vm.$set(_vm.positionData, "bisherManuellPLZ", $$v)
            },
            expression: "positionData.bisherManuellPLZ"
          }
        }),
        _c("InputField", {
          attrs: { label: "Ort" },
          model: {
            value: _vm.positionData.bisherManuellOrt,
            callback: function($$v) {
              _vm.$set(_vm.positionData, "bisherManuellOrt", $$v)
            },
            expression: "positionData.bisherManuellOrt"
          }
        }),
        _c("FormHeader", {
          attrs: {
            label: "Daten des Depots bei der bisher depotführenden Gesellschaft"
          }
        }),
        _c("InputField", {
          attrs: { label: "Depotnummer" },
          model: {
            value: _vm.positionData.bisherDepotnr,
            callback: function($$v) {
              _vm.$set(_vm.positionData, "bisherDepotnr", $$v)
            },
            expression: "positionData.bisherDepotnr"
          }
        }),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col" },
            [
              _c(
                "BaseButton",
                {
                  on: {
                    click: function($event) {
                      return _vm.$emit("next")
                    }
                  }
                },
                [_vm._v("Weiter")]
              )
            ],
            1
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }