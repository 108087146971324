var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("AntragHeaderTitleNavigation", {
        attrs: {
          disabled:
            !_vm.data ||
            !_vm.data.antragsdatenId ||
            _vm.data.dataHasChanged ||
            _vm.isDisabled,
          antragId: "wertpapierorder",
          antragTyp: "ORDERPOSITION",
          antragData: _vm.data,
          warnings: _vm.warnings,
          actionConfigs: _vm.actionConfigs,
          hatPDF: _vm.data.hatPDF
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [_vm._v("Wertpapierorder")]
            },
            proxy: true
          },
          {
            key: "subtitle",
            fn: function() {
              return [
                _vm._v(
                  " " +
                    _vm._s(_vm.lagerstelleLabel) +
                    " | " +
                    _vm._s(_vm.depotNrLabel) +
                    " "
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("div", { staticClass: "row my-0" }, [
        _c("div", { staticClass: "d-lg-none col-12" }, [
          _c(
            "div",
            { staticClass: "box__container" },
            [
              _vm.stepper
                ? _c("Stepper2", {
                    ref: "refStepper2",
                    attrs: {
                      stepType: "step",
                      visible: true,
                      stepperName: "wertpapierorderStepper",
                      selectedStepKey: _vm.routeStep,
                      stepperService: _vm.stepper
                    },
                    on: {
                      setStep: function($event) {
                        return _vm.navigateToStep($event)
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "row my-0" }, [
        _vm.getStepKey !== "aktionen"
          ? _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("AntragSidetext", {
                  attrs: {
                    antragId: "wertpapierorder",
                    antragData: _vm.data,
                    warnings: _vm.warnings,
                    steps: _vm.stepper && _vm.stepper.getCurrentStepList(),
                    highestStepVisited: _vm.getHighestStepVisited
                  },
                  on: {
                    navigateToWarning: function($event) {
                      return _vm.goToStep($event)
                    }
                  }
                })
              ],
              1
            )
          : _vm._e()
      ]),
      _c("ContentWithStepper", {
        attrs: { stepperCollapsed: _vm.stepperCollapsed },
        scopedSlots: _vm._u([
          {
            key: "stepper",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "box__container" },
                  [
                    _c("VerticalStepper", {
                      ref: "refStepper",
                      attrs: {
                        selectedStepKey: _vm.routeStep,
                        stepperService: _vm.stepper,
                        showSaveSpinner: _vm.saveAntragState,
                        showCollapse: true
                      },
                      on: {
                        setStep: function($event) {
                          return _vm.navigateToStep($event)
                        },
                        collapsed: function($event) {
                          _vm.stepperCollapsed = $event
                        }
                      }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "div",
                  [
                    _c("WertpapierorderStepContainer", {
                      attrs: { focusOnPosition: _vm.focusOnPosition },
                      on: {
                        ebaseEdelmetalFound: _vm.setEbaseEdelmetalTemplate,
                        navigateToWarning: function($event) {
                          return _vm.goToStep($event)
                        }
                      }
                    }),
                    _c("div", { staticClass: "box__container" }, [
                      _c("div", [
                        _c(
                          "div",
                          { staticClass: "d-flex layout__negative-margin--8" },
                          [
                            _vm.getStepKey !== "main"
                              ? _c(
                                  "BaseButton",
                                  {
                                    attrs: {
                                      isPrimary: false,
                                      isSecondary: true,
                                      disabled: _vm.isLoading
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.prevStep()
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(" Zurück "),
                                    _vm.showPreviousSpinner
                                      ? _c("AnimatedSpinner")
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.getStepKey !== "aktionen"
                              ? _c(
                                  "BaseButton",
                                  {
                                    attrs: {
                                      isPrimary: true,
                                      isSecondary: false,
                                      disabled: _vm.isLoading
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.nextStep()
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(" Weiter "),
                                    _vm.snowNextSpinner
                                      ? _c("AnimatedSpinner")
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.getStepKey === "aktionen" &&
                            !_vm.isCustomerLogin
                              ? _c(
                                  "BaseButton",
                                  {
                                    attrs: {
                                      isPrimary: "",
                                      isSecondary: false,
                                      disabled: _vm.isDisabled
                                    },
                                    on: { click: _vm.fertigstellen }
                                  },
                                  [_vm._v(" Fertigstellen ")]
                                )
                              : _vm._e(),
                            _vm.getStepKey == "aktionen" && _vm.isCustomerLogin
                              ? _c(
                                  "BaseButton",
                                  {
                                    attrs: {
                                      isPrimary: "",
                                      isSecondary: false,
                                      disabled:
                                        _vm.isDisabled || _vm.hasStatusFehler
                                    },
                                    on: { click: _vm.signPdf }
                                  },
                                  [_vm._v(" PDF signieren ")]
                                )
                              : _vm._e(),
                            _vm.getStepKey == "aktionen" && _vm.isCustomerLogin
                              ? _c(
                                  "BaseButton",
                                  {
                                    attrs: {
                                      isSecondary: "",
                                      disabled: _vm.isDisabled
                                    },
                                    on: { click: _vm.abbrechen }
                                  },
                                  [_vm._v(" Abbrechen ")]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("WertpapierorderInitModal", { ref: "initModal" }),
      _c(
        "BaseModal",
        {
          ref: "infoModal",
          attrs: { showDefaultButtons: false },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [_vm._v(" Bitte warten... "), _c("AnimatedSpinner")]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", [
            _c(
              "span",
              [
                _vm._v("Warte auf Antwort vom Webservice... "),
                _c("AnimatedSpinner")
              ],
              1
            )
          ])
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "vorlegenModal",
          attrs: {
            modalTitle: "Dokument zur E-Signatur vorgelegen",
            labelButtonConfirm: "Ja",
            labelButtonCancel: "Nein"
          },
          on: {
            onConfirmButton: _vm.vorlegen,
            onCancelButton: _vm.close,
            onCloseButton: _vm.close
          }
        },
        [
          _c("div", [
            _vm._v(
              ' Soll das Dokument in der "Unterschreiben" Liste (beim Vermittler und beim Kunden) angezeigt werden? '
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }