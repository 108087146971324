<template>
  <div class="draggable--hook" :style="draggableHookStyle">
    <div ref="draggableContainer" class="draggable--container" :style="draggableContainerStyle">
      <div class="draggable--area">
        <PhArrowsOutCardinal :size="20" class="draggable--item" @mousedown="dragMouseDown" @touchstart="dragMouseDown" />
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import BrowserSupport from '@/browser-support';
const MOUSE_EVENT_OPTIONS = BrowserSupport.supportsPassive ? { passive: true, capture: false, } : false;
const MOUSE_EVENT_NO_PASSIVE_OPTIONS = BrowserSupport.supportsPassive ? { passive: false, capture: false, } : false;
import { PhArrowsOutCardinal } from 'phosphor-vue';

export default {
  data() {
    return {
      positions: {
        clientX: null,
        clientY: null,
        movementX: 0,
        movementY: 0,
      },
      draggableContainerStyle: {
        position: 'relative',
      },
      draggableHookStyle: {
        height: 'auto',
      },
      clientArea: {
        x: null,
        y: null,
      }
    }
  },
  methods: {
    dragMouseDown(event) {
      event.preventDefault()
      this.draggableContainerStyle.position = 'absolute';
      this.draggableHookStyle.height = `${this.$refs.draggableContainer.clientHeight}px`;
      this.positions.clientX = event.clientX || event.targetTouches?.[0]?.clientX
      this.positions.clientY = event.clientY || event.targetTouches?.[0]?.clientY

      document.addEventListener('mousemove', this.elementDrag, MOUSE_EVENT_NO_PASSIVE_OPTIONS);
      document.addEventListener('mouseup', this.closeDragElement, MOUSE_EVENT_OPTIONS);

      document.addEventListener('touchmove', this.elementDrag, MOUSE_EVENT_NO_PASSIVE_OPTIONS);
      document.addEventListener('touchend', this.closeDragElement, MOUSE_EVENT_OPTIONS);
    },
    performDraggingContraint() {
      let result = true;

      if (!this.clientArea.x) {
        this.clientArea.x = this.$refs.draggableContainer.clientWidth;
      }

      if (!this.clientArea.y) {
        this.clientArea.y = this.$refs.draggableContainer.clientHeight;
      }
      

      const widthPadding = 4;
      const heightPadding = 4;


      const componentClientHeight = this.clientArea.y;
      
      // top/bottom constraint   
      if (this.positions.clientY < 0) {
        this.positions.clientY = 0;
        this.$refs.draggableContainer.style.top = 0 + 'px'
        result = false;
      } else if (this.positions.clientY + (componentClientHeight + widthPadding) > document.body.clientHeight) {
        this.positions.clientY = document.body.clientHeight - (componentClientHeight + widthPadding);
        this.$refs.draggableContainer.style.top = document.body.clientHeight - (componentClientHeight + widthPadding) + 'px'
        result = false;
      } 

      // left/right constraint   
      const componentClientWidth = this.clientArea.x;
    

      if (this.positions.clientX + (componentClientWidth + heightPadding) > document.body.clientWidth) {
        this.positions.clientX = document.body.clientWidth - (componentClientWidth + heightPadding);
        this.$refs.draggableContainer.style.left = document.body.clientWidth - (componentClientWidth + heightPadding) + 'px'
        result = false;
      } 

      return result;
    },
    elementDrag(event) {
      event.preventDefault()

      if (!this.performDraggingContraint()) {
        return;
      }

      this.positions.movementX = this.positions.clientX - (event.clientX || event.targetTouches?.[0]?.clientX)
      this.positions.movementY = this.positions.clientY - (event.clientY || event.targetTouches?.[0]?.clientY)
      this.positions.clientX = event.clientX || event.targetTouches?.[0]?.clientX
      this.positions.clientY = event.clientY || event.targetTouches?.[0]?.clientY

      this.$refs.draggableContainer.style.top = (this.$refs.draggableContainer.offsetTop - this.positions.movementY) + 'px'
      this.$refs.draggableContainer.style.left = (this.$refs.draggableContainer.offsetLeft - this.positions.movementX) + 'px'
    },
    closeDragElement () {
      document.removeEventListener('mousemove', this.elementDrag, MOUSE_EVENT_NO_PASSIVE_OPTIONS);
      document.removeEventListener('mouseup', this.closeDragElement, MOUSE_EVENT_OPTIONS);

      document.removeEventListener('touchmove', this.elementDrag, MOUSE_EVENT_NO_PASSIVE_OPTIONS);
      document.removeEventListener('touchend', this.closeDragElement, MOUSE_EVENT_OPTIONS);
      this.$emit("dragFinished");

      this.performDraggingContraint();

      this.clientArea.x = null;
      this.clientArea.y = null;
      
    }
  },
  components: {
    PhArrowsOutCardinal,
  },
}
</script>

<style scoped>

.draggable--container {
  position: relative;
}

.draggable--item {
  cursor: move;
}

.draggable--hook{ 
  height: auto;
  z-index: 1000;
  position: relative;
}

.draggable--area {
  display: flex;
  background: rgba(0, 0, 0, 0.7); 
  color: var(--color-box); 

}
</style>