<template >
  <div v-if="finished">
    <TemplateContainer />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import CORE_TYPES from "../../store/core/types";
import CMS_TYPES from "../../store/cms/types";
import TemplateContainer from "@/components/cms/TemplateContainer.vue";
export default {
  components: {
    TemplateContainer,
  }, 
  data() {
    return {
      finished: false,
      brokerIdFromUrl: null,
    };
  },

  computed: {
    ...mapGetters({
      isLoggedIn: CORE_TYPES.GETTERS.IS_LOGGED_IN,
      firstPageRoute: CMS_TYPES.GETTERS.GET_CMS_FIRST_PAGE_ROUTE,
      getComponents: CMS_TYPES.GETTERS.GET_CMS_COMPONENTS,
      getBrokerData: CORE_TYPES.GETTERS.GET_BROKER_INFORMATION
    }),
  },
  watch: {
    firstPageRoute(newValue) {
      if (this.getBrokerData && !this.getBrokerData.hasHomepage) {
        this.$router.push("/login").catch(() => {});
        return;
      }

      const brokerIdFromUrl = this.$route.params.id || this.$route.params.broker_id;
      const isCmsRouteAsRootPath = this.$route.path.match(/\/page[\/]?([\d]{5,})?[\/]?$/); // should match all considered root path like /page ; /page/99999

      if (newValue && isCmsRouteAsRootPath) {
        const nextRoute = newValue.indexOf("/") === 0 ? newValue : `/${newValue}`;
        const brokerIdFromRoutePath = brokerIdFromUrl ? `/${brokerIdFromUrl}` : '';
        this.$router.push(`/page${brokerIdFromRoutePath}${nextRoute}`);
      } else if (isCmsRouteAsRootPath) {
        this.$router.push("/login").catch(() => {});
      }
    },
    getBrokerData: {
      handler(newValue) {
        if (newValue?.hasHomepage) {
          this.getUnloggedCmsContent();
        } else if ('hasHomepage' in newValue) {
          this.$router.push('/login').catch(() => {});
        }
      },
      immediate: true,
    },
    isLoggedIn: {
      handler(newValue) {
        if (newValue) {
          this.getLoggedInCmsContent();
        }
      },
      immediate: true,
    }
  },
  methods: {
    getUnloggedCmsContent() {
      if (this.isLoggedIn) {
        return
      }

      if (this.getBrokerData && this.getBrokerData.maklernr) {
        this.$store.commit(CMS_TYPES.MUTATIONS.SET_USER_ID, this.getBrokerData.maklernr);
      }

      if (this.getBrokerData?.hasHomepage) {
        this.$store.dispatch(CMS_TYPES.ACTIONS.RETRIEVE_UNLOGGED_CMS_CONTENT, {
          calledUserID: this.getBrokerData.maklernr
        }).then(() => {
          this.finished = true;
        });
      }
    },

    getLoggedInCmsContent() {
      if (this.isLoggedIn) {
        if (this.getComponents === undefined) {
          this.$store.dispatch(CMS_TYPES.ACTIONS.RETRIEVE_CMS_CONTENT)
          .then(() => {
            this.finished = true;
          });
        } else {
          this.finished = true;
        }
      }
    }
  }
};
</script>