var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.vertragsdatenFieldsDefinition
    ? _c(
        "div",
        [
          _vm.rows.length
            ? _c("Table", {
                ref: "table",
                attrs: {
                  tableId: _vm.tableId,
                  cardView: "",
                  headers: _vm.headers,
                  rows: _vm.rows,
                  hidePagination: true
                }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }