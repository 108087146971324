var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "PageLayoutModal",
          attrs: {
            size: "md",
            modalTitle: "Seite einrichten",
            showCancelButton: true,
            labelButtonConfirm: "Übernehmen"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.onConfirmModal()
            }
          }
        },
        [
          _c(
            "div",
            [
              _c("div", { staticClass: "box__title" }, [
                _vm._v("Seitenabstände")
              ]),
              _c(
                "div",
                { staticClass: "input-container" },
                [
                  _c("InputField", {
                    attrs: {
                      isEmbedded: "",
                      foreign_currency: "cm",
                      type: "foreign_cy",
                      label: "links"
                    },
                    model: {
                      value: _vm.pageInsets.left,
                      callback: function($$v) {
                        _vm.$set(_vm.pageInsets, "left", $$v)
                      },
                      expression: "pageInsets.left"
                    }
                  }),
                  _c("InputField", {
                    attrs: {
                      foreign_currency: "cm",
                      type: "foreign_cy",
                      label: "rechts"
                    },
                    model: {
                      value: _vm.pageInsets.right,
                      callback: function($$v) {
                        _vm.$set(_vm.pageInsets, "right", $$v)
                      },
                      expression: "pageInsets.right"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "input-container mb-3" },
                [
                  _c("InputField", {
                    attrs: {
                      isEmbedded: "",
                      foreign_currency: "cm",
                      type: "foreign_cy",
                      label: "oben"
                    },
                    model: {
                      value: _vm.pageInsets.up,
                      callback: function($$v) {
                        _vm.$set(_vm.pageInsets, "up", $$v)
                      },
                      expression: "pageInsets.up"
                    }
                  }),
                  _c("InputField", {
                    attrs: {
                      foreign_currency: "cm",
                      type: "foreign_cy",
                      label: "unten"
                    },
                    model: {
                      value: _vm.pageInsets.bottom,
                      callback: function($$v) {
                        _vm.$set(_vm.pageInsets, "bottom", $$v)
                      },
                      expression: "pageInsets.bottom"
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "box__title" }, [
                _vm._v("Position Barcode")
              ]),
              _c("ComboBox", {
                attrs: {
                  isComponentHalfSize: "",
                  values:
                    _vm.comboboxSelections &&
                    _vm.comboboxSelections.barcodePositions
                },
                model: {
                  value: _vm.barcodePosition,
                  callback: function($$v) {
                    _vm.barcodePosition = $$v
                  },
                  expression: "barcodePosition"
                }
              }),
              _c("div", { staticClass: "box__title" }, [
                _vm._v("Hintergrund (.pdf / .jpg / .png)")
              ]),
              _c(
                "DragnDropArea",
                {
                  staticClass: "depotnummer-anfordern-button-component mb-4",
                  attrs: { hoverText: "Hochladen" },
                  on: { files: _vm.onFileChange }
                },
                [
                  _c(
                    "BaseFileSelect",
                    {
                      attrs: { accept: ".pdf, image/png, image/jpeg," },
                      on: { files: _vm.onFileChange }
                    },
                    [_vm._v(" Hintergrund hochladen ")]
                  ),
                  _c("div", [
                    _vm.background
                      ? _c(
                          "div",
                          { staticClass: "input-chip" },
                          [
                            _c("a", { on: { click: _vm.openLink } }, [
                              _vm._v(
                                _vm._s(
                                  _vm.background && _vm.background.fileName
                                )
                              )
                            ]),
                            _c("ph-x-circle", {
                              staticClass: "clickable ml-1 del-icon",
                              attrs: { size: 16 },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  _vm.background = null
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }