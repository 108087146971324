<template>
  <div>
    <PostfachOverviewCards/>
  </div>
</template>

<script>
import PostfachOverviewCards from '@/views/communication/PostfachOverviewCards.vue'

export default {
  mixins: [],
  beforeRouteLeave (to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zur Kommunikationsübersicht',
      to,
      from
    });

    next()
  },
  components: {
    PostfachOverviewCards,
  },
}
</script>

<style scoped>

</style>