<template>
  <div class="contact_container">
    <div class="contact_wrapper">
      <div class="banner">BESCHWERDEMANAGEMENT</div>
      <hr />
      <div class="zweispalte">
        <div>
          <div class="beschwerde_text">
            lhre Zufriedenheit hat für uns höchste Priorität.
            Falls Sie dennoch einmal nicht mit unserer Tätigkeit zufrieden sein sollten, haben Sie
            die Möglichkeit, eine Beschwerde bei uns einzureichen.
            Erhalten wir von Ihnen eine Beschwerde, bestätigen wir Ihnen unverzüglich deren
            Eingang und unterrichten Sie über das Verfahren der Beschwerdebearbeitung sowie
            die ungefähre Bearbeitungszeit.
            Sollten wir feststellen, dass lhre Beschwerde einen Gegenstand betrifft, für den
            wir nicht zuständig sind, informieren wir Sie umgehend hierüber und teilen Ihnen,
            soweit uns dies möglich ist, die zuständige Stelle mit.
            Wir werden lhre Beschwerde umfassend prüfen und uns bemühen, diese schnellstmöglich zu beantworten. Sollte dies einmal nicht möglich sein, unterrichten wir Sie
            über die Gründe der Verzögerung und darüber, wann die Prüfung voraussichtlich
            abgeschlossen sein wird.
            Auf Wunsch erteilen wir Ihnen alle Benachrichtigungen und Informationen schriftlich. Sofern wir lhrem Anliegen nicht oder nicht vollständig nachkommen können,
            erläutern wir Ihnen die Gründe hierfür und weisen Sie auf etwaig bestehende Möglichkeiten hin, wie Sie Ihr Anliegen weiter verfolgen können.
          </div>
          <div class="zweifields">
            <div>
              <InputField v-model="credentials.vorname" label="Vorname: " ref="vornameInp"/>
              <InputField v-model="credentials.email" label="E-Mail: " ref="emailInp"/>
            </div>
            <div>
              <InputField v-model="credentials.nachname" label="Name: " ref="nachnameInp"/>
              <InputField v-model="credentials.telefon" label="Telefon: " ref="telefonInp" inputmode="tel"/>
            </div>
          </div>
          <div>
            <div class="nachricht_label">Nachricht:</div>
            <textarea v-model="content.inhalt" class="inputtext__input" rows="3" />
          </div>
          <div class="checkbox_plus_label">
            <input-check-box-item v-model="makeRead" />
            <span style="display:inline-block; width: .3em;"></span>
            <div>
              Ich stimme den
              <router-link to="datenschutz">Datenschutzbestimmungen</router-link> zu.
            </div>
          </div>
          <div class="to_the_left">
            <BaseButton
              :disabled="validation.isInvalid('credentials') || validation.isInvalid('content')"
              @click="sendKontakt"
            >Absenden</BaseButton>
              <div class="fc-alert fc-alert-warning" v-if="isIntern">Die Sendung ist für die Intern deaktiviert.</div>
          </div>
        </div>
        <div class="makler_info">
          <makler-info />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InputCheckBoxItem from "@/components/core/forms/checkbox/InputCheckBoxItem.vue";
import MaklerInfo from "@/components/cms/parts/MaklerInfo.vue";
import BaseButton from "@/components/core/BaseButton.vue";
import CMS_TYPES from "@/store/cms/types";
import CORE_TYPES from "@/store/core/types";
import validator from "@/mixins/validator";
import InputField from "@/components/core/forms/InputField.vue";
import { mapGetters } from "vuex";

import {
  required,
  email,
  telefon,
  vorname,
  nachname
} from "@/mixins/validator/rules";
export default {
  components: {
    BaseButton,
    InputField,
    MaklerInfo,
    InputCheckBoxItem
  },
  data() {
    return {
      credentials: {
        vorname: null,
        nachname: null,
        email: null,
        telefon: null
      },
      content: {
        inhalt: "",
        readDatenschutz: false
      }
    };
  },
  validators: {
    credentials: {
      vorname: [vorname()],
      nachname: [nachname()],
      email: [email()],
      telefon: [telefon()]
    },
    content: {
      inhalt: [required()],
      readDatenschutz: [required()]
    }
  },
  mixins: [validator],
  computed: {
    ...mapGetters({
      getUserID: CMS_TYPES.GETTERS.GET_USER_ID,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
    }),
    makeRead: {
      get() {
        return this.content.readDatenschutz;
      },
      set(value) {
        this.content.readDatenschutz = !this.content.readDatenschutz;
      }
    },
    buildUnloggedMessage() {
      return {
        messageText:
          this.credentials.nachname +
          " " +
          this.credentials.vorname +
          " hat eine Beschwerde!<br/>Telefon: " +
          this.credentials.telefon +
          "<br/>E-Mail: " +
          this.credentials.email +
          "<br/>Inhalt: <br/>" +
          this.content.inhalt,
        receiverId: this.getUserID,
        thema: "Beschwerde von " + this.credentials.nachname,
        beschwerde: true
      };
    }
  },
  methods: {
    sendKontakt: function() {
        if (!this.isIntern){
        this.$store
          .dispatch(CMS_TYPES.ACTIONS.SEND_KONTAKT, this.buildUnloggedMessage)
          .then(success => {
           this.resetFields();
            this.content.inhalt = "";
          });
      }
    },
    resetFields(){
      this.$refs.nachnameInp.resetField();
      this.$refs.vornameInp.resetField();
      this.$refs.emailInp.resetField();
      this.$refs.telefonInp.resetField();
    }

  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>
<style  scoped>
.contact_container {
  max-width: 1200px;
  margin: 0 auto;
  padding-left: 1em;
  padding-right: 1em;
}
.banner {
  margin-top: 2em;
  font-size: 26px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  text-align: left;
  color: var(--color-text);
}
a {
  color: var(--color-link);
}

.zweispalte {
  padding-top: 2em;
  padding-bottom: 3em;
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 1.5em;
}
.zweifields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2em;
}
.contact_content {
  margin: 0 auto;
  padding-top: 2em;
  padding-bottom: 2em;
  display: grid;
  gap: 4em;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "fondsshop_image fondsshop_text";
}
.beschwerde_text {
  text-align: left;
  padding-bottom: 1em;
}
.nachricht_label {
  text-align: left;
  font-weight: 700;
  padding-top: 1em;
}
.inputtext__input {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  padding: 1.3em;
  width: 95%;
}
.checkbox_plus_label {
  padding-top: 1em;
  display: flex;
}
.to_the_left {
  text-align: left;
  padding-top: 1em;
}


@media only screen and (max-width: 1024px) {
 
  .zweispalte {
    padding-top: 0px;
    display: grid;
    grid-template-columns: 1fr;
  }
  .home_header_image {
    display: none;
  }
  .zweifields {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2em;
  }
  .makler_info {
    justify-self: left;
  }
}
</style>