import axios from 'axios';

import CONFIG_DEFINITION_TYPES from './types';
import CORE_TYPES from '@/store/core/types';
import FC_CONFIG_TYPES from '@/store/fcConfig/types';
import { VIEW_ROLES, } from '@/router/roles';
import { FC_CONFIG_USER_LEVEL, } from '@/configs/fcConfig';

const SERVICE_PATH = '/config_definition';

import { MenuItem, } from '@/menu/menu-utils';
import { configDefinitionBaseViewPath, } from '@/views/core/configs/configs-utils';
import postSaveListeners from '@/store/configDefinition/post-save-listeners';

const axiosConfig = {
  defaultSpinner: true
};

export default {
  async [CONFIG_DEFINITION_TYPES.ACTIONS.GET_ALL_CONFIGS_DEFINITION]({ getters, commit }) {
    const result = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/all_configs_definition`, axiosConfig);

    commit(CONFIG_DEFINITION_TYPES.MUTATIONS.GET_ALL_CONFIGS_DEFINITION_SUCCESS, [ ...result?.data || [], ]);
  },

  async [CONFIG_DEFINITION_TYPES.ACTIONS.GET_CONFIGS_DEFINITION]({ getters, commit }) {
    const result = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/configs_definition`, axiosConfig);

    const data = result?.data || [];
    commit(CONFIG_DEFINITION_TYPES.MUTATIONS.GET_CONFIGS_DEFINITION_SUCCESS, data);
    commit(CONFIG_DEFINITION_TYPES.MUTATIONS.SET_CONFIG_DEFINITION_OPTIONS_MENU, data);
  },

  async [CONFIG_DEFINITION_TYPES.ACTIONS.GET_ALL_CONFIGS_AVAILABLE]({ getters, commit }) {
    if(getters[CONFIG_DEFINITION_TYPES.GETTERS.ALL_CONFIGS_AVAILABLE]?.length > 0) return;

    const result = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/available_configs`, axiosConfig);

    commit(CONFIG_DEFINITION_TYPES.MUTATIONS.GET_ALL_CONFIGS_AVAILABLE_SUCCESS, result?.data || []);
  },

  async [CONFIG_DEFINITION_TYPES.ACTIONS.GET_CONFIG_DEFINITION]({ getters, commit, }, { id, editMode, }) {
    if(!id) return;

    const params = {};
    if(editMode) {
      params['editMode'] = true;
    }

    const configDefinitionSelected = getters[CONFIG_DEFINITION_TYPES.GETTERS.CONFIG_DEFINITION_SELECTED];
    if(configDefinitionSelected?.id == id) return;

    commit(CONFIG_DEFINITION_TYPES.MUTATIONS.RESET_CONFIG_DEFINITION_SELECTED);

    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/configs_definition/${id}`, {
      ...axiosConfig,
      params,
    });

    commit(CONFIG_DEFINITION_TYPES.MUTATIONS.GET_CONFIG_DEFINITION_SUCCESS, response?.data?.[0] || null);
  },

  async [CONFIG_DEFINITION_TYPES.ACTIONS.SAVE_CONFIG_DEFINITION]({ getters, commit, }) {
    const edited = getters[CONFIG_DEFINITION_TYPES.GETTERS.CONFIG_DEFINITION_EDITED];
    if(!edited || !edited?.label) return;

    commit(CONFIG_DEFINITION_TYPES.MUTATIONS.RESET_CONFIG_DEFINITION_EDITED);

    const payload = {
      ...edited,
      steps: edited.steps.map(step => ({
        ...step,
        configComponents: step.configComponents.map(component => {
          const label = component.label !== component.originalLabel ? component.label : undefined;
          if(component.configId && component.configType) {
            return { configId: component.configId, configType: component.configType, label, };
          } else {
            return { id: component.id, label, };
          }
        }),
      })),
    };

    const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/configs_definition`, payload, axiosConfig);
    const configDefinitionSaved = response?.data?.[0] || null;
    const configDefinitionSelected = getters[CONFIG_DEFINITION_TYPES.GETTERS.CONFIG_DEFINITION_SELECTED];
    if(configDefinitionSaved?.id == configDefinitionSelected?.id) {
      commit(CONFIG_DEFINITION_TYPES.MUTATIONS.GET_CONFIG_DEFINITION_SUCCESS, configDefinitionSaved);
    }
  },

  async [CONFIG_DEFINITION_TYPES.ACTIONS.DELETE_CONFIG_DEFINITION]({ getters, }, { id, }) {
    if(!id) return;

    await axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/configs_definition/${id}`, axiosConfig);
  },

  async [CONFIG_DEFINITION_TYPES.ACTIONS.COPY_CONFIG_DEFINITION]({ getters, }, { id, }) {
    if(!id) return;

    await axios.put(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/copy_configs_definition/${id}`, {}, axiosConfig);
  },

  async [CONFIG_DEFINITION_TYPES.ACTIONS.SAVE_CONFIG_PERMISSION]({ dispatch, }, { configDefinitionId, configPermissionList }) {
    if(!configDefinitionId || !configPermissionList?.length) return;

    const promises = configPermissionList.map(configPermission => {
      const maklernr = configPermission.maklernr;
      const kundennr = configPermission.kundennr;

      const payload = {
        maklernr,
        kundennr,
        fcConfigs: [
          {
            configId: configDefinitionId,
            content: configDefinitionId,
            userLevel: configPermission.userLevel,
            configType: configPermission.configType,
            configContext: configPermission.configContext,
          },
        ],
      };

      return dispatch(FC_CONFIG_TYPES.ACTIONS.SAVE_AS_INTERN, payload);
    });

    await Promise.all(promises);
  },

  async [CONFIG_DEFINITION_TYPES.ACTIONS.FIND_CONFIG_PERMISSION]({ getters, commit, }, { configDefinitionId, }) {
    if(!configDefinitionId) return;

    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/fcconfig/config_definition_permission?configId=${configDefinitionId}`, axiosConfig);
    commit(CONFIG_DEFINITION_TYPES.MUTATIONS.FIND_CONFIG_PERMISSION_SUCCESS, response?.data || []);
  },

  async [CONFIG_DEFINITION_TYPES.ACTIONS.DELETE_CONFIG_PERMISSION]({ dispatch, }, { configDefinitionId, configPermissionList }) {
    if(!configDefinitionId || !configPermissionList?.length) return;

    const promises = configPermissionList.map(configPermission => {
      const maklernr = configPermission.maklernr;
      const kundennr = configPermission.kundennr;

      const payload = {
        configId: configDefinitionId,
        content: configDefinitionId,
        userLevel: configPermission.userLevel,
        configContext: configPermission.configContext,
        configType: configPermission.configType,
        maklernr,
        kundennr,
      };

      return dispatch(FC_CONFIG_TYPES.ACTIONS.DELETE_AS_INTERN, payload);
    });

    await Promise.all(promises);
  },

  async [CONFIG_DEFINITION_TYPES.ACTIONS.SAVE_CHANGED_CONFIGS]({ getters, commit, dispatch, ...ctx }) {
    const changedConfigs = getters[CONFIG_DEFINITION_TYPES.GETTERS.CHANGED_CONFIGS];
    if(!Object.keys(changedConfigs).length){
      return
    }
    try{
      const hasRoles = getters[CORE_TYPES.GETTERS.HAS_ROLES];
      let currentUserLevel = null;
      if(hasRoles([VIEW_ROLES.VIEW_BROKER])) {
        currentUserLevel = FC_CONFIG_USER_LEVEL.MAKLER;
      } else if(hasRoles([VIEW_ROLES.VIEW_CUSTOMER])) {
        currentUserLevel =  FC_CONFIG_USER_LEVEL.KUNDE;
      }
      const changedConfigsPayload = Object.values(changedConfigs).map(item => ({
        ...item,
        userLevel: currentUserLevel,
      }))
      await dispatch(FC_CONFIG_TYPES.ACTIONS.SAVE_FC_CONFIG, changedConfigsPayload);

      // call listeners after saving parameters
      Object.keys(changedConfigs).forEach(id => (id in postSaveListeners) && postSaveListeners[id]({ 
        ...ctx, 
        getters, 
        commit, 
        dispatch,
      }, changedConfigs[id]));

      commit(CONFIG_DEFINITION_TYPES.MUTATIONS.RESET_CHANGED_CONFIGS);
    }catch(e){
      // empty block
    }
    
  },

  async [CONFIG_DEFINITION_TYPES.ACTIONS.FIND_CONFIG_DEFINITION_OPTIONS_MENU]({ dispatch, commit, getters, }) {
    await dispatch(CONFIG_DEFINITION_TYPES.ACTIONS.GET_CONFIGS_DEFINITION);

    commit(CONFIG_DEFINITION_TYPES.MUTATIONS.SET_CONFIG_DEFINITION_OPTIONS_MENU, getters[CONFIG_DEFINITION_TYPES.GETTERS.CONFIGS_DEFINITION]);

    const configsDefinition = getters[CONFIG_DEFINITION_TYPES.GETTERS.CONFIG_DEFINITION_OPTIONS_MENU] || [];

    return configsDefinition.map(configDefinition => MenuItem(configDefinitionBaseViewPath(configDefinition.id), configDefinition.label));
  },

  async [CONFIG_DEFINITION_TYPES.ACTIONS.IMPORT_CONFIGS]({ getters }, payload) {
    try {
      const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/import_config`, payload, axiosConfig);
      console.log(response?.data)
    } catch (error) {
      // empty block
    }
  }

}
