export const MUTATION_PREFIX = 'EXTERNE_ZUGANG_CONFIG: ';
export const ACTIONS_PREFIX = 'EXTERNE_ZUGANG_CONFIG_ACTIONS_';
export const GETTERS_PREFIX = 'EXTERNE_ZUGANG_CONFIG_GETTERS_';

export default {
  MUTATIONS: {
    SET_CONFIG_DATA: MUTATION_PREFIX + 'SET_CONFIG_DATA',
    SET_SELECTED_ZUGANGSART: MUTATION_PREFIX + 'SET_SELECTED_ZUGANGSART',
    SET_LIST: MUTATION_PREFIX + 'SET_LIST',
    ADD_REPLACE_LIST_ITEM: MUTATION_PREFIX + 'ADD_REPLACE_LIST_ITEM',
    DELETE_REPLACE_LIST_ITEM: MUTATION_PREFIX + 'DELETE_REPLACE_LIST_ITEM',
    SET_DEFINITION: MUTATION_PREFIX + 'SET_DEFINITION',
    SET_BIPRO_SIBLING_GESELLSCHAFTEN: MUTATION_PREFIX + 'SET_BIPRO_SIBLING_GESELLSCHAFTEN',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
  },
  ACTIONS: {
    FIND_CONFIG_DATA: ACTIONS_PREFIX + 'FIND_CONFIG_DATA',
    FIND_LIST: ACTIONS_PREFIX + 'FIND_LIST',
    FIND_EXTERNE_ZUGANG_DEFINITION: ACTIONS_PREFIX + 'FIND_EXTERNE_ZUGANG_DEFINITION',
    SAVE_EXTERNE_ZUGANG_DATA: ACTIONS_PREFIX + 'SAVE_EXTERNE_ZUGANG_DATA',
    DELETE_EXTERNE_ZUGANG: ACTIONS_PREFIX + 'DELETE_EXTERNE_ZUGANG',
    EXECUTE_ACTION: ACTIONS_PREFIX + 'EXECUTE_ACTION',
    FIND_BIPRO_SIBLING_GESELLSCHAFTEN: ACTIONS_PREFIX + 'FIND_BIPRO_SIBLING_GESELLSCHAFTEN',
    SAVE_BIPRO_GDV_PARAMETER: ACTIONS_PREFIX + 'SAVE_BIPRO_GDV_PARAMETER',
    DELETE_BIPRO_GDV_PARAMETER: ACTIONS_PREFIX + 'DELETE_BIPRO_GDV_PARAMETER',
  },
  GETTERS: {
    CONFIG_DATA: GETTERS_PREFIX + 'CONFIG_DATA',
    SELECTED_ZUGANGSART: GETTERS_PREFIX + 'SELECTED_ZUGANGSART',
    LIST: GETTERS_PREFIX + 'LIST',
    DEFINITION: GETTERS_PREFIX + 'DEFINITION',
    BIPRO_SIBLING_GESELLSCHAFTEN: GETTERS_PREFIX + 'BIPRO_SIBLING_GESELLSCHAFTEN',
  },
}
