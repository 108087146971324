var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "EditableFrameModal",
          attrs: {
            size: "xl",
            modalTitle: "Inhalt bearbeiten",
            confirmDisabled: !_vm.component && !_vm.templateComponentList.length
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.onConfirmModal()
            },
            close: function($event) {
              _vm.templateValue = null
            }
          }
        },
        [
          _vm.modus === "new"
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "input-forms__label-content" }, [
                    _vm._v("Templates")
                  ]),
                  _c("ComboBox", {
                    attrs: {
                      value: _vm.templateValue,
                      values: _vm.templateValues
                    },
                    on: { change: _vm.changeTemplate }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._l(_vm.editableComponentList, function(editableComponent) {
            return _c(
              "div",
              { key: editableComponent.componentId },
              [
                _c("DynamicComponent", {
                  attrs: { editableComponent: editableComponent }
                })
              ],
              1
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }