export const MUTATION_PREFIX = 'COMMUNICATION_MUTATIONS_';
export const ACTIONS_PREFIX = 'COMMUNICATION_ACTIONS_';
export const GETTERS_PREFIX = 'COMMUNICATION_GETTERS_';

export default {
  MUTATIONS: {
    TEMPLATE: MUTATION_PREFIX + 'TEMPLATE',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    UNREAD_COMMUNICATIONS: MUTATION_PREFIX + 'UNREAD_COMMUNICATIONS',
    LOAD_COMMUNICATION_INSURANCE_LISTS: MUTATION_PREFIX + 'LOAD_COMMUNICATION_INSURANCE_LISTS',
    LOAD_EMAIL: MUTATION_PREFIX + 'LOAD_EMAIL',
    LOAD_SMS: MUTATION_PREFIX + 'LOAD_SMS',
    LOAD_TALK: MUTATION_PREFIX + 'LOAD_TALK',
    LOAD_TALK_CATEGORIES: MUTATION_PREFIX + 'LOAD_TALK_CATEGORIES',
    GET_DEFAULT_RECEIVER: MUTATION_PREFIX + 'GET_DEFAULT_RECEIVER',
    LOAD_TICKETS_NACHRICHTEN: MUTATION_PREFIX + 'LOAD_TICKETS_NACHRICHTEN',
    LOAD_TICKETS_NACHRICHTEN_LAZY: MUTATION_PREFIX + 'LOAD_TICKETS_NACHRICHTEN_LAZY',
    UPDATE_TICKETS_NACHRICHTEN_LOADED_TIME: MUTATION_PREFIX + 'UPDATE_TICKETS_NACHRICHTEN_LOADED_TIME',
    LOAD_TICKETS_NACHRICHTEN_COMBOBOXES: MUTATION_PREFIX + 'LOAD_TICKETS_NACHRICHTEN_COMBOBOXES',
    CHAT_BETEILIGTER_EMPF_KEY: MUTATION_PREFIX + 'CHAT_BETEILIGTER_EMPF_KEY',
    LOAD_TICKET_COMBOBOXES: MUTATION_PREFIX + 'LOAD_TICKET_COMBOBOXES',
    LOAD_TICKET_INFO_SUCCESS: MUTATION_PREFIX + 'LOAD_TICKET_INFO_SUCCESS',
    LOAD_BEWERTUNG_INFO_SUCCESS: MUTATION_PREFIX + 'LOAD_BEWERTUNG_INFO_SUCCESS',
    DELETE_FROM_POSTFACH: MUTATION_PREFIX + 'DELETE_FROM_POSTFACH',
    GET_CONFIGURED_CHATPARTNER_SUCCESS: MUTATION_PREFIX + 'GET_CONFIGURED_CHATPARTNER_SUCCESS',
    SAVE_UNSENT_NACHRICHT: MUTATION_PREFIX + 'SAVE_UNSENT_NACHRICHT',
    LOAD_ABSENCE_NOTE: MUTATION_PREFIX + 'LOAD_ABSENCE_NOTE',
  },
  ACTIONS: {
    TEMPLATE: ACTIONS_PREFIX + 'TEMPLATE',
    UNREAD_COMMUNICATIONS: ACTIONS_PREFIX + 'UNREAD_COMMUNICATIONS',
    LOAD_COMMUNICATION_INSURANCE_LISTS: ACTIONS_PREFIX + 'LOAD_COMMUNICATION_INSURANCE_LISTS',
    LOAD_EMAIL: ACTIONS_PREFIX + 'LOAD_EMAIL',
    LOAD_SMS: ACTIONS_PREFIX + 'LOAD_SMS',
    LOAD_TALK: ACTIONS_PREFIX + 'LOAD_TALK',
    SAVE_TALK: ACTIONS_PREFIX + 'SAVE_TALK',
    LOAD_TALK_CATEGORIES: ACTIONS_PREFIX + 'LOAD_TALK_CATEGORIES',
    LOAD_BEMERKUNG: ACTIONS_PREFIX + 'LOAD_BEMERKUNG',
    SAVE_BEMERKUNG: ACTIONS_PREFIX + 'SAVE_BEMERKUNG',
    UPLOAD_BEMERKUNG_ATTACHMENT: ACTIONS_PREFIX + 'UPLOAD_BEMERKUNG_ATTACHMENT',
    DELETE_BEMERKUNG_ATTACHMENT: ACTIONS_PREFIX + 'DELETE_BEMERKUNG_ATTACHMENT',
    GET_DEFAULT_RECEIVER: ACTIONS_PREFIX + 'GET_DEFAULT_RECEIVER',
    LOAD_TICKETS_NACHRICHTEN: ACTIONS_PREFIX + 'LOAD_TICKETS_NACHRICHTEN',
    LOAD_TICKETS_NACHRICHTEN_LAZY: ACTIONS_PREFIX + 'LOAD_TICKETS_NACHRICHTEN_LAZY',
    FIND_CHAT_WITH_LAST_MESSAGE: ACTIONS_PREFIX + 'FIND_CHAT_WITH_LAST_MESSAGE',
    LOAD_TICKETS_NACHRICHTEN_COMBOBOXES: ACTIONS_PREFIX + 'LOAD_TICKETS_NACHRICHTEN_COMBOBOXES',
    LOAD_TICKET_COMBOBOXES: ACTIONS_PREFIX + 'LOAD_TICKET_COMBOBOXES',
    LOAD_TICKET_INFO: ACTIONS_PREFIX + 'LOAD_TICKET_INFO',
    LOAD_BEWERTUNG_INFO: ACTIONS_PREFIX + 'LOAD_BEWERTUNG_INFO',
    DELETE_FROM_POSTFACH: ACTIONS_PREFIX + 'DELETE_FROM_POSTFACH',
    OPEN_ATTACHMENT: ACTIONS_PREFIX + 'OPEN_ATTACHMENT',
    SEND_PIN_VIA_SMS: ACTIONS_PREFIX + 'SEND_PIN_VIA_SMS',
    LOAD_DOCUMENT_OFFENE_UNTERSCHRIFTEN: ACTIONS_PREFIX + 'LOAD_DOCUMENT_OFFENE_UNTERSCHRIFTEN',
    GET_ZUSAETZLICHE_DOCUMENT_OFFENE_UNTERSCHRIFTEN: ACTIONS_PREFIX + 'GET_ZUSAETZLICHE_DOCUMENT_OFFENE_UNTERSCHRIFTEN',
    GET_CONFIGURED_CHATPARTNER: ACTIONS_PREFIX + 'GET_CONFIGURED_CHATPARTNER',
    LOAD_TICKET_PDF: ACTIONS_PREFIX + 'LOAD_TICKET_PDF',
    CHAT_BETEILIGTER_EMPF_KEY: ACTIONS_PREFIX + 'CHAT_BETEILIGTER_EMPF_KEY',
    LOAD_ABSENCE_NOTE: ACTIONS_PREFIX + 'LOAD_ABSENCE_NOTE',
    SAVE_ABSENCE_NOTE: ACTIONS_PREFIX + 'SAVE_ABSENCE_NOTE',
  },
  GETTERS: {
    TEMPLATE: GETTERS_PREFIX + 'TEMPLATE',
    COMMUNICATIONS_UNREAD: GETTERS_PREFIX + 'COMMUNICATIONS_UNREAD',
    COMMUNICATIONS_UNREAD_CHATS: GETTERS_PREFIX + 'COMMUNICATIONS_UNREAD_CHATS',
    COMMUNICATION_INSURANCE_RECORDS: GETTERS_PREFIX + 'COMMUNICATION_INSURANCE_RECORDS',
    EMAILS: GETTERS_PREFIX + 'EMAILS',
    SMSS: GETTERS_PREFIX + 'SMSS',
    TALKS: GETTERS_PREFIX + 'TALKS',
    TALK_CATEGORIES: GETTERS_PREFIX + 'TALK_CATEGORIES',
    SANITIZE_HTML_CONFIG: GETTERS_PREFIX + 'SANITIZE_HTML_CONFIG',
    DEFAULT_RECEIVER: GETTERS_PREFIX + 'DEFAULT_RECEIVER',
    TICKETS_NACHRICHTEN: GETTERS_PREFIX + 'TICKETS_NACHRICHTEN',
    TICKETS_NACHRICHTEN_LAZY: GETTERS_PREFIX + 'TICKETS_NACHRICHTEN_LAZY',
    TICKETS_NACHRICHTEN_CAN_EXECUTE_LAZY_LOAD: GETTERS_PREFIX + 'TICKETS_NACHRICHTEN_CAN_EXECUTE_LAZY_LOAD',
    TICKETS_NACHRICHTEN_BEARBEITER: GETTERS_PREFIX + 'TICKETS_NACHRICHTEN_BEARBEITER',
    TICKETS_NACHRICHTEN_STATUSES: GETTERS_PREFIX + 'TICKETS_NACHRICHTEN_STATUSES',
    TICKET_CHATPARTNERS: GETTERS_PREFIX + 'TICKET_CHATPARTNERS',
    TICKET_BEARBEITERS: GETTERS_PREFIX + 'TICKET_BEARBEITERS',
    TICKET_INFO: GETTERS_PREFIX + 'TICKET_INFO',
    BEWERTUNG_INFO: GETTERS_PREFIX + 'BEWERTUNG_INFO',
    CONFIGURED_CHATPARTNER: GETTERS_PREFIX + 'CONFIGURED_CHATPARTNER',
    POSTFACH_DEFAULT_FILTERS: GETTERS_PREFIX + 'POSTFACH_DEFAULT_FILTERS',
    CHAT_BETEILIGTER_EMPF_KEY: GETTERS_PREFIX + 'CHAT_BETEILIGTER_EMPF_KEY',
    UNSENT_NACHRICHT: GETTERS_PREFIX + 'UNSENT_NACHRICHT',
    ABSENCE_NOTE: GETTERS_PREFIX + 'ABSENCE_NOTE',
  }
}