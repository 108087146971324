var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", { attrs: { title: "Jahrescheckliste" } }),
      _c("BaseFilter", {
        attrs: {
          filterId: "cb88e24e-0838-440c-98b8-30ebe28ef86e",
          metadata: _vm.searchMenu,
          isCustomerSearch: false,
          isCache: "",
          immidiateSearch: "",
          defaultOptions: _vm.defaultOptionsData,
          configFilter: _vm.configFilter
        },
        on: { onFilter: _vm.search }
      }),
      _vm.loadingList
        ? _c("GhostLoading", {
            attrs: { type: "table", config: { table: { rows: 10 } } }
          })
        : _c("div", [
            _c(
              "div",
              { staticClass: "box__container" },
              [
                _c("div", { staticClass: "box__title" }, [
                  _vm._v("Jahreschecklisten aller Makler")
                ]),
                _vm.auswertungList && _vm.auswertungList.length
                  ? _c(
                      "div",
                      [
                        _c("Table", {
                          attrs: {
                            rowId: "maklerNr",
                            headers: _vm.headers,
                            rows: _vm.auswertungList,
                            rowsPerPage: 20,
                            headerActions: _vm.headerActions
                          },
                          on: {
                            "click-maklerNr": function($event) {
                              return _vm.openBroker($event.maklerNr)
                            },
                            "headerAction-EXCEL": function($event) {
                              return _vm.getExcelFile()
                            }
                          }
                        })
                      ],
                      1
                    )
                  : _c("NoData")
              ],
              1
            )
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }