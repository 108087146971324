var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Table", {
    attrs: {
      tableId: "cab9b82f-9f1c-41ca-aa11-c1e4e3e1d92d",
      cardView: "",
      headers: _vm.headers,
      rows: _vm.rows,
      hidePagination: ""
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }