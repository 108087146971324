<template>
  <BaseDropdownMenu placement="bottom-right" containerClass="options-menu-config-view-diff-help--dropdown" :closeWithInnerClick="false">
    <template #hook-target>
      <button type="button" class="btn-clear clickable"><PhQuestion :size="20" /></button>
    </template>
    <template #default>
      <ul class="options-menu-config-view-diff-help--items">
        <li class="options-menu-config-view-diff-help--item status-changed">
          <span>Konfiguration unterscheidet sich</span>
        </li>
        <li class="options-menu-config-view-diff-help--item status-added">
          <span>Konfiguration wird hinzugefügt</span>
        </li>
        <li class="options-menu-config-view-diff-help--item status-removed">
          <span>Konfiguration wird entfernt</span>
        </li>
      </ul>
    </template>
  </BaseDropdownMenu>
</template>

<script>
import BaseDropdownMenu from '@/components/core/BaseDropdownMenu.vue';
import { PhQuestion } from 'phosphor-vue';

export default {
  components: {
    BaseDropdownMenu,
    PhQuestion,
  },
}
</script>

<style lang="scss" scoped>
::v-deep .dropdown-menu--hook-target {
  display: flex;
}

.options-menu-config-view-diff-help--items {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    border-radius: 4px;
    margin: 4px 0;
    padding: 4px 12px 4px 6px;

    &.status-added {
      background-color: var(--color-success-background);
      color: var(--color-success);

      &:before {
        content: "+ ";
      }
    }

    &.status-changed {
      background-color: var(--color-warning-background);
      color: var(--color-warning);

      &:before {
        content: "~ ";
      }
    }

    &.status-removed {
      background-color: var(--color-danger-background);
      color: var(--color-danger);

      &:before {
        content: "- ";
      }
    }

    span {
      line-height: 1.2em;
    }
  }
}
</style>

<!-- GLOBAL STYLE -->
<style>
.dropdown-menu__container.options-menu-config-view-diff-help--dropdown {
  margin-left: 16px;
}
</style>
