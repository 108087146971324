import ANLAGEZIELE_TYPES from './types';

export default {
  [ANLAGEZIELE_TYPES.GETTERS.ZEILEN](state) {
    return state.zeilen;
  },
  [ANLAGEZIELE_TYPES.GETTERS.POSITIONS_ANLAGEZIELE](state) {
    return state.positionsAnlageziele;
  },
  [ANLAGEZIELE_TYPES.GETTERS.COMBOBOX_VALUES](state) {
    return state.comboboxValues;
  },
  [ANLAGEZIELE_TYPES.GETTERS.SCHIENE](state) {
    return state.schiene;
  },
}