<template>
  <BaseButton @click="onclick">{{label}}</BaseButton>
</template>

<script>
import { mapGetters } from 'vuex';
import ANTRAG_TYPES from '@/store/antrag/types';
import BaseButton from '@/components/core/BaseButton.vue';

export default {
  components: {
    BaseButton,
  },
  props: {
    label: {
      default: ''
    },
    config: {
      required: true,
    },
    value: {
    },
    antragId: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      antragData: ANTRAG_TYPES.GETTERS.ANTRAEGE_DATA,
    }),
  },
  data() {
    return {
      
    }
  },
  methods: {
    onclick() {
      if(this.config?.ebase){
         this.$router.push(`/beratung/formulare/antrag/EBASE/unterschriftbestaetigung/depotdaten`)
      }else if(this.config?.ebaseAAB){
         this.$router.push(`/beratung/formulare/antrag/EBASE_AAB/unterschriftbestaetigung/depotdaten`)
      }else{
         this.$router.push(`/beratung/formulare/antrag/DWS/dwslegitimationspruefung`)
      }
    },
  },
}
</script>

<style scoped>

</style>