<template>
    <span v-html="sanitizedValue"></span>
</template>
<script>
import { sanitize } from '@/helpers/string-helper.js';
import sanitizeHtml from 'sanitize-html';

export default {
    exportAsString(props) {
        let value = props.row[props.column];
        return sanitizeHtml(value || "", {allowedTags: [], allowedAttributes: {}});
    },
    props: {
        column: {
            type: String,
        },
        row: {
            type: Object,
        },
    },
    computed: {
        sanitizedValue() {
            return sanitize(this.row[this.column]);
        },
    },
}
</script>
