var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BoxContainer",
        { attrs: { title: _vm.selectedTabLabel } },
        [
          _vm.rows.length
            ? _c("Table", {
                attrs: {
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowId: "index",
                  hidePagination: ""
                }
              })
            : _c("div", [_vm._v("Keine Daten")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }