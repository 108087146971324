<template>
  <div>
    <PageHeaderTitleNavigation 
      title="Einstellungen" subtitle="Terminkalender" 
      :actions="headerActions" 
      @action-DISCARD="resetDataEdited"
    />

    <StepperForm
      ref="stepperForm"
      stepType="tab"
      stepperName="customer-data"
      :stepperMediator="stepperMediator"
      :selectedStepKey="selectedStepKey"
      :hasFertig="false"
      @set-step="setStepByKey"
    >
      <template #contentTemplate>
        <div v-if="loading" class="box__container">
          <GhostLoading type="title" />
          <GhostLoading type="block" />
          <GhostLoading type="title" />
          <GhostLoading type="title" />
          <GhostLoading type="title" />
          <GhostLoading type="title" />
        </div>

        <!-- step Kalender -->
        <div v-else-if="selectedStepKey === 'kalender'" class="box__container">
          <div class="box__title">Kalender</div>

          <InputRadioBoxGroup title="Voreinstellung der Uhrzeit in Tages- / Wochenansicht" :values="tagesansichtUhrzeitValues" 
            v-model="formData.tagesansichtUhrzeitModus" @input="setDataChanged">
            <template v-if="formData.tagesansichtUhrzeitModus === 'FESTE'" #after-FESTE>
              <TimePicker v-model="formData.tagesansichtUhrzeit" isComponentHalfSize @change="setDataChanged" />
            </template>
          </InputRadioBoxGroup>

          <div>
            <div class="box__title">Geburtstage anzeige</div>
            <template v-if="isMakler">
              <InputToggleSwitch label="Geburtstage der Kunden anzeigen" v-model="formData.zeigeGebtagKunden" @input="setDataChanged" />
              <InputToggleSwitch label="Geburtstage der zusätzlichen Depotinhaber anzeigen" v-model="formData.zeigeGebtagKundenZDI" @input="setDataChanged" />
              <InputToggleSwitch label="Geburtstage der Bevollmächtigten anzeigen" v-model="formData.zeigeGebtagKundenVM" @input="setDataChanged" />
            </template>
            <template v-else-if="isIntern">
              <div class="mb-3">
                <span>Geburtstage der Makler der folgenden Kategorien anzeigen</span>
                <InputToggleSwitch label="A+" v-model="formData.zeigeGebtagMaklerAPlus" @input="setDataChanged" />
                <InputToggleSwitch label="A" v-model="formData.zeigeGebtagMaklerA" @input="setDataChanged" />
                <InputToggleSwitch label="B" v-model="formData.zeigeGebtagMaklerB" @input="setDataChanged" />
                <InputToggleSwitch label="C" v-model="formData.zeigeGebtagMaklerC" @input="setDataChanged" />
                <InputToggleSwitch label="D" v-model="formData.zeigeGebtagMaklerD" @input="setDataChanged" />
                <InputToggleSwitch label="E" v-model="formData.zeigeGebtagMaklerE" @input="setDataChanged" />
                <InputToggleSwitch label="F" v-model="formData.zeigeGebtagMaklerF" @input="setDataChanged" />
              </div>
              <InputToggleSwitch label="Geburtstage der Ansprechpartner Gesellschaften anzeigen" v-model="formData.zeigeGebtagGesell" @input="setDataChanged" />
              <InputToggleSwitch label="Geburtstage der Kontakte anzeigen" v-model="formData.zeigeGebtagKontakt" @input="setDataChanged" />
            </template>
          </div>

          <div>
            <div class="box__title">Benachrichtigung</div>
            <div class="mb-3">
              <InputToggleSwitch label="Benachrichtigung per Popup" v-model="formData.popupAlarmAktiv" class="p-0" />
              <div class="forms__input--half-size">
                <div class="row mt-1 mb-0">
                  <div class="col-4 col-sm-2 col-lg-3">
                    <InputField type="number" :precision="0" v-model="formData.popupAlarmWert" :disabled="!formData.popupAlarmAktiv" class="p-0" @change="setDataChanged" />
                  </div>
                  <div class="col-5 col-sm-3 col-lg-4">
                    <ComboBox :values="comboData.alarmModusPopup" v-model="formData.popupAlarmModus" :disabled="!formData.popupAlarmAktiv" class="p-0" @input="setDataChanged" />
                  </div>
                  <div class="col d-flex align-items-center">vorher</div>
                </div>
              </div>
            </div>

            <div class="mb-3">
              <InputToggleSwitch label="Benachrichtigung per E-Mail" v-model="formData.mailAlarmAktiv" @input="setDataChanged" class="p-0" />
              <div class="forms__input--half-size">
                <div class="row mt-1 mb-0">
                  <div class="col-4 col-sm-2 col-lg-3">
                    <InputField type="number" :precision="0" v-model="formData.mailAlarmWert" :disabled="!formData.mailAlarmAktiv" class="p-0" @change="setDataChanged" />
                  </div>
                  <div class="col-5 col-sm-3 col-lg-4">
                    <ComboBox :values="comboData.alarmModusEmail" v-model="formData.mailAlarmModus" :disabled="!formData.mailAlarmAktiv" class="p-0" @input="setDataChanged" />
                  </div>
                  <div class="col d-flex align-items-center">vorher</div>
                </div>
              </div>
            </div>

            <InputToggleSwitch label="Termine des heutigen Tages in der E-Mail aufführen" v-model="formData.mailAlarmTagestermine" @input="setDataChanged" />
          </div>
        </div>

        <!-- step Terminfreigabe -->
        <div v-else-if="isIntern && selectedStepKey === 'terminfreigabe'" class="box__container">
          <TerminFreigaben v-model="formData.freigaben" @input="setDataChanged" />
        </div>

        <!-- step Feiertagseinstellungen -->
        <div v-else-if="selectedStepKey === 'feiertagseinstellungen'" class="box__container">
          <div class="box__title">Feiertagseinstellungen</div>

          <InputChips label="Deutschland" :availableOptions="comboData.feiertagGruppeDE" 
            v-model="formData.feiertagGruppeDE" showToggle uniqueSelection @input="setDataChanged" />

          <InputChips label="Österreich" :availableOptions="comboData.feiertagGruppeAT" 
            v-model="formData.feiertagGruppeAT" showToggle uniqueSelection @input="setDataChanged" />

          <FeiertageGruppeSpezial v-model="formData.eigeneFeiertage" @input="setDataChanged" />
        </div>
      </template>
    </StepperForm>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';
import CALENDAR_SETTINGS_TYPES from '@/store/calendarSettings/types';
import CALENDAR_TYPES from '@/store/calendar/types';

import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import StepperForm from '@/components/stepper/StepperForm.vue';
import { StepperMediator, } from '@/components/stepper/utils';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import TimePicker from '@/components/core/forms/TimePicker.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputChips from '@/components/core/input-chips/InputChips.vue';
import TerminFreigaben from '@/components/calendar/settings/TerminFreigaben.vue';
import FeiertageGruppeSpezial from '@/components/calendar/settings/FeiertageGruppeSpezial.vue';

import { VIEW_ROLES } from '@/router/roles';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {
  data() {
    return {
      loading: false,
      selectedStepKey: 'kalender',
      formData: {},
      tagesansichtUhrzeitValues: [
        { label: 'aktuelle Uhrzeit', value: 'AKTUELL', }, 
        { label: 'feste Uhrzeit', value: 'FESTE', }, 
      ],
    };
  },
  computed: {
    ...mapGetters({
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      comboData: CALENDAR_SETTINGS_TYPES.GETTERS.COMBO_DATA,
      settingsData: CALENDAR_SETTINGS_TYPES.GETTERS.SETTINGS_DATA,
      settingsDataEdited: CALENDAR_SETTINGS_TYPES.GETTERS.SETTINGS_DATA_EDITED,
    }),
    steps() {
      if(this.loading) return [];
      let i = 1;
      return [
        {
          stepKey: 'kalender',
          label: 'Kalender',
          totalProgress: i++,
        },
        {
          stepKey: 'terminfreigabe',
          label: 'Terminfreigabe für andere Personen',
          totalProgress: i++,
          hidden: !this.isIntern,
        },
        {
          stepKey: 'feiertagseinstellungen',
          label: 'Feiertagseinstellungen',
          totalProgress: i++,
        },
      ];
    },
    stepperMediator() {
      return new StepperMediator(JSON.parse(JSON.stringify(this.steps)));
    },
    hasDataEdited() {
      return Object.keys(this.settingsDataEdited || {}).length > 0;
    },
    headerActions() {
      return [
        PageHeaderSimpleAction('DISCARD', 'Änderungen verwerfen').withDisabled(() => !this.hasDataEdited),
      ];
    },
    isMakler() {
      return this.hasRoles([VIEW_ROLES.VIEW_BROKER]);
    },
    isIntern() {
      return this.hasRoles([VIEW_ROLES.VIEW_INTERN]);
    },
  },
  watch: {
    settingsData: {
      handler() {
        this.setFormData();
      },
      immediate: true,
    },
  },
  methods: {
    async init() {
      try {
        this.loading = true;
        await Promise.all([
          this.$store.dispatch(CALENDAR_SETTINGS_TYPES.ACTIONS.FIND_COMBO_DATA),
          this.$store.dispatch(CALENDAR_SETTINGS_TYPES.ACTIONS.FIND_SETTINGS_DATA),
          this.$store.dispatch(CALENDAR_SETTINGS_TYPES.ACTIONS.FIND_FEIERTAGE_GRUPPEN_SPEZIAL),
        ]);
      } finally {
        this.loading = false;
      }
    },
    setStepByKey(stepKey) {
      this.selectedStepKey = stepKey;
    },
    setFormData() {
      const { settingsData, settingsDataEdited, } = this;

      Object.keys(settingsData)
        .forEach(key => this.$set(this.formData, key, settingsDataEdited != null ? settingsDataEdited[key] : settingsData[key]));

      this.$set(this.formData, 'feiertagGruppeDE', settingsData?.feiertagGruppeDE?.map?.(item => ({ label: item.bezeichnung, value: item.id, })));
      this.$set(this.formData, 'feiertagGruppeAT', settingsData?.feiertagGruppeAT?.map?.(item => ({ label: item.bezeichnung, value: item.id, })));
    },
    setDataChanged() {
      const { formData, } = this;
      const payload = {
        ...formData,
        feiertagGruppeDE: formData?.feiertagGruppeDE?.map(item => ({ id: item.value, })),
        feiertagGruppeAT: formData?.feiertagGruppeAT?.map(item => ({ id: item.value, })),
      };
      this.$store.commit(CALENDAR_SETTINGS_TYPES.MUTATIONS.ADD_SETTINGS_DATA_EDITED, payload);
    },
    resetDataEdited() {
      this.$store.commit(CALENDAR_SETTINGS_TYPES.MUTATIONS.RESET_SETTINGS_DATA_EDITED);
      this.setFormData();
    },
    async saveData() {
      await this.$store.dispatch(CALENDAR_SETTINGS_TYPES.ACTIONS.SAVE_SETTINGS_DATA);
    },
  },
  mounted() {
    this.init();
  },
  async beforeRouteLeave(to, from, next) {
    await this.saveData();
    this.$store.commit(CALENDAR_TYPES.MUTATIONS.RESET_STATE);
    this.$store.commit(CALENDAR_SETTINGS_TYPES.MUTATIONS.RESET_STATE);

    next();
  },
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    StepperForm,
    GhostLoading,
    InputRadioBoxGroup,
    TimePicker,
    InputToggleSwitch,
    InputField,
    ComboBox,
    InputChips,
    TerminFreigaben,
    FeiertageGruppeSpezial,
  },
}
</script>

<style scoped lang="scss">
::v-deep .timepicker-container {
  padding-bottom: 0;
}
</style>
