var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "navbar-workspaces__container-bg" }, [
    _c("div", { staticClass: "fc-container navbar-workspaces__container" }, [
      _c(
        "div",
        { staticClass: "navbar-container__logo-container" },
        [_c("NavbarWorkspacesLogo")],
        1
      ),
      _c(
        "div",
        { staticClass: "navbar-workspaces__menu-container" },
        [_vm.roles.length > 0 ? _c("WorkspacesMenu") : _vm._e()],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }