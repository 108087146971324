<template>
  <BaseModal 
    :showDefaultButtons="false"
    size="lg"
    @onCloseButton="emitCloseModalEvent()"
    ref="baseFilterSaveModal">

    <template #modalTitle>
      <div class="base-filter-save--title-container d-flex justify-content-between">
        <span class="base-filter-save--title">Filterliste</span>
        <BaseFilterSaveModalHelper class="base-filter-save--title-dropdown" />
      </div>
    </template>

    <template v-slot:default>
      <div>
        <div class="d-flex" v-if="currentlySelectedRow">
          <InputField 
            v-model="storedSearchParameter.searchParameters[currentlyEditingIndex].searchLabel"/>

          <div>
            <BaseButton 
              isSecondary 
              class="ml-3" 
              @click="toggleDefaultFilter()">
              <span v-if="currentlySelectedRow && currentlySelectedRow.isDefault">
                Standard löschen
              </span>
              <span v-else>
                Als Standard setzen
              </span>
            </BaseButton>
          </div>
        </div>

        <div v-if="hasSearchParameters" class="filter__container">
          <SortableList :items="storedSearchParameter.searchParameters" @orderChanged="onOrderChanged($event)">
            <ul class="list-bordered" data-sortable-container>
              <li v-for="(item, index) in storedSearchParameter.searchParameters" 
                v-bind:key="index + item.searchLabel" 
                class="list-bordered-item" 
                data-sortable-item>
                <div class="d-flex parameter-search__input-fields">
                  <div>
                    <span :class="{'default-filter': item.isDefault}">{{item.searchLabel}}</span>
                    <span v-if="item.isDefault" :class="{'default-filter': item.isDefault}"> (Standard)</span>
                  </div>
                  <div style="flex: 1 0 auto" class="input-forms__container d-flex align-items-center justify-content-end">
                    <div class="parameter-controls__container clickable">
                      <ph-pencil-line
                        :size="16"
                        @click="editRow(index)"
                        weight="bold"
                      />
                      <ph-file-arrow-down 
                        :size="16"
                        @click="exportFilter(index)"
                        weight="bold" />
                      <ph-trash
                        :size="16"
                        @click="deleteFilterItem(index)"
                        weight="bold"
                      />
                      <ph-eye v-if="item.visible"
                        :size="16"
                        @click="hideOrShow(index)"
                        weight="bold"
                      />
                      <ph-eye-slash v-if="!item.visible || item.visible == false"
                        :size="16"
                        @click="hideOrShow(index)"
                        weight="bold"
                      />
                      <div data-sortable-item-handler><ph-list :size="16" weight="bold" /></div>
                    </div>
                  </div>     
                </div>
              </li>
            </ul>
          </SortableList>
        </div>
        <NoData v-else noIcon />
      </div>
    </template>

    <template v-slot:footer>
      <BaseFileSelect
        isSecondary
        class="mr-3"
        :disable="!filterSaveKey"
        @files="importFilter"
      >
        Filter laden
      </BaseFileSelect>
      
      <BaseButton 
        isPrimary
        @click="emitCloseModalEvent()">
        <span>Schließen</span>
      </BaseButton>
    </template>
  </BaseModal>
</template>
<script>
import {
  PhPencilLine,
  PhTrash,
  PhList,
  PhEye,
  PhEyeSlash,
  PhFileArrowDown,
} from "phosphor-vue";
import BaseButton from "@/components/core/BaseButton.vue";
import BaseModal from "@/components/core/BaseModal.vue";
import BaseFileSelect from "@/components/fileUpload/BaseFileSelect.vue";
import InputField from "@/components/core/forms/InputField.vue";
import SortableList from '@/components/core/SortableList.vue';
import axios from 'axios';
import { mapGetters } from 'vuex';
import LOG_TYPES from '@/store/log/types';
import SEARCH_CONFIGS_TYPES from '@/store/searchConfigs/types'
import { buildMessage } from '@/helpers/log-message-helper';
import { downloadFile } from "./GenericPersonFilter.vue";
import CORE_TYPES from '@/store/core/types'
import BaseFilterSaveModalHelper from '@/components/core/BaseFilterSaveModalHelper.vue';
import NoData from '@/components/core/NoData.vue';

export default {
  components: {
    PhPencilLine,
    PhTrash,
    PhList,
    PhFileArrowDown,
    BaseButton,
    BaseModal,
    BaseFileSelect,
    InputField,
    PhEye,
    PhEyeSlash,
    SortableList,
    BaseFilterSaveModalHelper,
    NoData,
  },
  props: {
    filterSaveKey: {
      type: String,
      default: null,
    }
  },
  data: function () {
    return {
      storedSearchParameter: {},
      currentlyEditingIndex: null,
      userLevel: null,
    };
  },
  mounted: function () {
  },
  watch: {
    loadedFilters: {
      handler() {
        this.storedSearchParameter = JSON.parse(JSON.stringify(this.loadedFilters[this.filterSaveKey] || {}))
      },
      immediate: true,
    }
  },
  computed: {
    ...mapGetters({
      apiAddress: CORE_TYPES.GETTERS.API_ADDRESS,
      loadedFilters: SEARCH_CONFIGS_TYPES.GETTERS.LOADED_FILTERS,
    }),

    currentlySelectedRow() {
      return this.storedSearchParameter?.searchParameters?.[this.currentlyEditingIndex]
    },

    hasSearchParameters() {
      return !!this.storedSearchParameter?.searchParameters?.length;
    },
  },
  methods: {
    open() {
      this.$refs.baseFilterSaveModal.open();
    },
    close() {
      this.$refs.baseFilterSaveModal.close();
    },
    exportFilter(index) {
      const search = this.storedSearchParameter?.searchParameters?.[index]
      if (search) {
        const payload = {
          ...search,
          parameters: JSON.stringify(search.parameters)
        }
        axios.post(`${this.apiAddress}/stored_search_parameter/export`, payload).then(result => {
          if (result && result.data) {
            downloadFile('filter_' + search.searchLabel, result.data, 'text/plain');
          }
        });
      }
    },
    importFilter(files) {
      const config = {
        defaultSpinner: true,
        headers: { 'Content-Type': 'multipart/form-data' },
      };
      let payload = new FormData();
      payload.append("file", files[0]);
      const errorMessage = buildMessage('Beim importieren des Filters ist ein Fehler aufgetreten.', 'danger');
      axios.post(`${this.apiAddress}/stored_search_parameter/import`, payload, config).then(response => {
        const fileContent = response?.data?.fileContent
        if (fileContent) {
          if (fileContent.searchKey === this.filterSaveKey) {
            this.$store.dispatch(SEARCH_CONFIGS_TYPES.ACTIONS.SAVE_FILTER, {
              searchKey: this.filterSaveKey,
              searchParameter: {
                ...response.data.fileContent,
                parameters: JSON.parse(response.data.fileContent.parameters)
              },
            })
          } else {
            this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, 
              buildMessage('Der hochgeladene Filter ist falsch', 'danger'));
          }
        } else {
          this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, errorMessage);
        }
      }).catch(() => {
        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, errorMessage);
      })
    },
    
    editRow(index) {
      this.currentlyEditingIndex = index;
    },
    deleteFilterItem(index) {
      this.storedSearchParameter.searchParameters?.splice(index, 1)
      this.saveParametersList()
    },

    toggleDefaultFilter() {
      if (this.currentlySelectedRow) {
        this.storedSearchParameter.searchParameters.forEach((item, index) => {
          if (this.currentlyEditingIndex === index) {
            this.$set(item, 'isDefault', !item.isDefault)
          } else {
            this.$set(item, 'isDefault', false)
          }
        })
      }
    },
    hideOrShow(index) {
      const item = this.storedSearchParameter.searchParameters?.[index]
      this.$set(item, 'visible', !item.visible)
    },
    emitCloseModalEvent() {
      this.currentlyEditingIndex = null
      this.saveParametersList()
      this.close()
    },
    onOrderChanged(sortedList) {
      sortedList.forEach((sl, index) => {
        sl.columnOrder = index;
      })

      this.storedSearchParameter?.searchParameters?.sort((a, b) => a.columnOrder - b.columnOrder)
    },
    saveParametersList() {
      this.$store.dispatch(SEARCH_CONFIGS_TYPES.ACTIONS.SAVE_FILTER_LIST, {
        searchKey: this.filterSaveKey, 
        searchParameters: this.storedSearchParameter.searchParameters
      })
    }
  },
};
</script>
<style scoped>
.base-filter-save--title {
  flex: 1 1 auto;
}
.base-filter-save--title-dropdown {
  flex: 0 0 auto;
}
.default-filter {
    font-weight: bold;
}
.stored-search__container {
  margin: -8px;
}

.stored-search-badge {
  padding: 8px 12px;
  color: var(--color-primary);
  background-color: var(--color-box); 
  border: solid 1px var(--color-primary);
  border-radius: 24px;
  margin: 8px;
  font-size: 0.85rem;
}
.stored-search-badge__selected {
  background-color: var(--color-primary);
  color: var(--color-box); 
}

.parameter-search__input-fields {
  margin: 0 -8px;

}

.parameter-search__input-fields > * {
  margin: 0 8px;
}

.parameter-search__input-fields > div > span {
  display:inline;
}

.parameter-controls__container {
  margin: 0 -8px;
}

.parameter-controls__container > * {
  margin: 0 8px;
  display: inline;
}

.grid__container {
  margin: 8px 0;
}

</style>