var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.files && _vm.additionalUploadInputs
    ? _c("DocumentsUploadModal", {
        attrs: {
          parentId: _vm.nodeId,
          files: _vm.files,
          additionalInputs: _vm.additionalUploadInputs,
          versicherungId: _vm.versicherungId
        },
        on: {
          close: function($event) {
            return _vm.close()
          }
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }