var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "modal",
    attrs: { modalTitle: "Speicher-Optionen", showDefaultButtons: false },
    on: {
      onEscButton: function($event) {
        return _vm.saveConfig()
      },
      onCloseButton: function($event) {
        return _vm.saveConfig()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function() {
          return [
            _c("UserLevelCombo", {
              attrs: { current: _vm.userLevelInternal },
              model: {
                value: _vm.userLevelInternal,
                callback: function($$v) {
                  _vm.userLevelInternal = $$v
                },
                expression: "userLevelInternal"
              }
            })
          ]
        },
        proxy: true
      },
      {
        key: "footer",
        fn: function() {
          return [
            _c(
              "BaseButton",
              {
                staticClass: "mr-3",
                attrs: { isSecondary: "" },
                on: {
                  click: function($event) {
                    _vm.restoreDefault()
                    _vm.close()
                  }
                }
              },
              [_vm._v("Zurücksetzen")]
            ),
            _c(
              "BaseButton",
              {
                on: {
                  click: function($event) {
                    _vm.saveConfig()
                    _vm.close()
                  }
                }
              },
              [_vm._v("Schließen")]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }