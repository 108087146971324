<template id="availableStatusIcons">
  <div class="availableStatusIcons__container">
    <span v-bind:key="action.legend.label + uuidv4()" v-for="action in currentStatusIcons">
      <span v-if="statusType==='VORLAGE'">
        <span v-if="action.legend.key === 'DOKUMENT_VORLEGEN'">
            <Pill v-if="statusType==='VORLAGE'" label="nicht vorgelegt" type="danger"></Pill>
        </span>
        <span v-else-if="action.legend.key === 'DOKUMENT_NICHT_VORLEGEN'">
            <Pill label="vorgelegt" type="info"></Pill>
        </span>  
        <span v-else-if="action.legend.key === 'DOKUMENT_VORLEGEN_TEILWEISE'">
            <Pill label="teilweise vorgelegt" type="warning"></Pill>
        </span>  
        <span v-else-if="action.legend.key === 'DOKUMENT_VERMITTLER_NICHT_VORLEGEN'">
            <Pill label="Makler vorgelegt" type="info"></Pill>
        </span>         
        <span v-else-if="action.legend.key === 'DOKUMENT_KUNDE_NICHT_VORLEGEN'">
            <Pill label="Kunde vorgelegt" type="info"></Pill>
        </span> 
      </span>
      <span v-if="statusType==='SIGNATUR'">
        <span v-if="action.legend.key === 'EINGEREICHT_ABGELEGT'">
            <Pill label="eingereicht" type="success"></Pill>
        </span>     
        <span v-else-if="action.legend.key === 'UNTERSCHRIEBEN'">
            <Pill label="unterschrieben" type="success"></Pill>
        </span>   
         <span v-else-if="action.legend.key === 'TEILWEISE_UNTERSCHRIEBEN'">
          <Pill :label="action.legend.label" type="warning"></Pill>
        </span>
        <span v-else-if="action.legend.key === 'TEILWEISE_EINGEREICHT'">
          <Pill :label="action.legend.label" type="success"></Pill>
        </span>     
      </span>   
    </span>
  </div>
</template>

<script>
import {
  PhMinus,
  PhPlus,
  PhIntersect,
  PhX,
  PhPencilLine,
  PhTrash,
  PhList,
  PhEye,
  PhEyeSlash
} from "phosphor-vue";
import Pill from '@/components/core/Pill.vue'

export default {
  name: "AvailableActionIcons",
  props: {
    currentStatusIcons: {
      default: null
    },
    statusType: {
      default: null
    }
  },
  computed: {},
  components: {
    PhMinus,
    PhPlus,
    PhIntersect,
    PhX,
    PhPencilLine,
    PhTrash,
    PhList,    
    PhEye,
    PhEyeSlash,
    Pill
  },
  data: function () {
    return {
    };
  },
  watch: {
    currentStatusIcons(newValue, oldValue) {
    }
  },
  methods: {
    hasAction(whatAction) {
      return this.currentStatusIcons && this.currentStatusIcons.some(r=> r.legend.key == whatAction);

    },
    uuidv4() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (
        c
      ) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    },
  },
};
</script>

<style scoped>
.availableStatusIcons__container {
  display: inline;
}






</style>
