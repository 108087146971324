<template>
  <div class="box__container">
    <div v-if="isEmpty">Keine zusatzliche Daten erforderlich</div>
    <div v-else>
       <ComboBox
          title="Geschäftstyp"
          v-model="form.INPUT_FODB_MIX_BERATUNGVERMITTLUNG" 
          :values="geschaeftTypValues"
          :disabled="readOnly || (form.INPUT_FODB_MIX_BERATUNGVERMITTLUNG && form.INPUT_FODB_MIX_BERATUNGVERMITTLUNG.length > 0)"
          @change="updateStoreData({componentId: 'INPUT_FODB_MIX_BERATUNGVERMITTLUNG', value: $event })" />
      
      <div class="col-12 mt-3">
        <FormLabel label="Hinweis"/>
      </div>
      <div class="col-12 mt-3">
        <FormLabel :label="hinweisText"/>
      </div>

      <div class="col-12 my-3">
        <FormHeader label="Kauf und Sparplan" />
      </div>
      <InputRadioBoxGroup 
        v-model="form.INPUT_FODB_MIX_KAUF_KEUER" 
        :validateUntouched="true"
        :values="kaufKeuerValues"
        :disabled="readOnly"
        @input="updateStoreData({componentId: 'INPUT_FODB_MIX_KAUF_KEUER', value: $event })" />
      <InputRadioBoxGroup 
        title="Unzureichende Information zur Angemessenheit/Unangemessenheit (nur bei Bedarf auszufüllen)"
        v-model="form.INPUT_FODB_MIX_KAUF_ANGEMESSEN" 
        :validateUntouched="true"
        :values="kaufAngemessenValues"
        :disabled="readOnly"
        @input="updateStoreData({componentId: 'INPUT_FODB_MIX_KAUF_ANGEMESSEN', value: $event })" />
      <div class="col-12 mt-3">
        <FormLabel label="nur Anlageberatung"/>
      </div>
      <InputToggleSwitch v-model="form.INPUT_FODB_MIX_KAUF_BERATUNG" inLineLabel
        :label="labelKaufBeratung" 
        :disabled="readOnly || (data && data.INPUT_FODB_MIX_BERATUNGVERMITTLUNG !== 'VALUE_FODB_MIX_TYP_BERATUNG')"
        @input="updateStoreData({componentId: 'INPUT_FODB_MIX_KAUF_BERATUNG', value: $event })" />

      <div class="col-12 mt-3">
        <FormLabel label="nur Anlagevermittlung"/>
      </div>
       <InputToggleSwitch v-model="form.INPUT_FODB_MIX_KAUF_VERMITTLUNG" 
        :label="labelKaufVerm" inLineLabel
        :disabled="readOnly || (data && data.INPUT_FODB_MIX_BERATUNGVERMITTLUNG !== 'VALUE_FODB_MIX_TYP_VERMITTLUNG')"
        @input="updateStoreData({componentId: 'INPUT_FODB_MIX_KAUF_VERMITTLUNG', value: $event })" />

        
       <div>
         <FormHeader label="Ich/Wir wurde/n über die Möglichkeit informiert, die konstenlose Aushändigung der Basisinformationsblätter in Papierform verlangen zu können. Diese habe/n ich/wir rechtzeitig wie folgt erhalten:" />
        <InputToggleSwitch
        label="Das/Die Basisinformationsblatt/-blätter zu der/zu den erwerbenden ISIN / Fonds wurde/n mir/uns in Papierform übergeben."
        v-model="form.INPUT_CHK_PAPIER"
         :disabled="readOnly"
          @input="updateStoreData({componentId: 'INPUT_CHK_PAPIER', value: $event })"
        />
         <InputToggleSwitch
        label="Das/Die Basisinformationsblatt/-blätter zu der/zu den erwerbenden ISIN / Fonds wurde/n mir/uns auf meine/unsere E-Mail-Addresse zur Verfügung gestellt."
        v-model="form.INPUT_CHK_EMAIL"
         :disabled="readOnly"
          @input="updateStoreData({componentId: 'INPUT_CHK_EMAIL', value: $event })"
        />
         <InputToggleSwitch
        label="Das/Die Basisinformationsblatt/-blätter zu der/zu den erwerbenden ISIN / Fonds wurde/n mir/uns in digitaler Form über eine Webseite zur Verfügung gestellt. Ich/Wir habe/n die Möglichkeit des Einsehens und/oder Downloads dieser Unterlagen/Informationen genutzt."
        v-model="form.INPUT_CHK_WEB"
         :disabled="readOnly"
        @input="updateStoreData({componentId: 'INPUT_CHK_WEB', value: $event })"
        />
      </div>

      <FormHeader label="Verkauf und Entnahmeplan" />
      <div class="col-12 mt-3">
        <FormLabel label="Anlageberatung"/>
      </div>
       <InputToggleSwitch v-model="form.INPUT_FODB_MIX_VERKAUF_BERATUNG" 
        label="Dieser Auftrag basiert auf einer Beratung, die der Berater/Vermittler der Fondsdepot Bank in eigenem Namen und auf eigene Rechnung gegenüber dem Anleger erbringt. Dieser Berater/Vermittler ist für die Durchführung der Geeignetheitsprüfung und -dokumentation verantwortlich." 
        inLineLabel
        :disabled="readOnly || isVerkaufDisabled('VALUE_FODB_MIX_TYP_BERATUNG')"
        @input="updateStoreData({componentId: 'INPUT_FODB_MIX_VERKAUF_BERATUNG', value: $event })" />
      
      <div class="col-12 mt-3">
        <FormLabel label="Anlagevermittlung"/>
      </div>
       <InputToggleSwitch v-model="form.INPUT_FODB_MIX_VERKAUF_VERMITTLUNG" 
        label="Im Rahmen dieser Vermittlung wird weder durch den Berater/Vermittler noch die depotführende Stelle eine Beratungsleistung erbracht." 
        inLineLabel
        :disabled="readOnly || isVerkaufDisabled('VALUE_FODB_MIX_TYP_VERMITTLUNG')"
        @input="updateStoreData({componentId: 'INPUT_FODB_MIX_VERKAUF_VERMITTLUNG', value: $event })" />
    </div>
  </div>
</template>

<script>
import ComboBox from "@/components/core/forms/ComboBox.vue";
import FormHeader from "@/components/core/forms/FormHeader.vue";
import FormLabel from "@/components/core/forms/FormLabel.vue";
import mixin from "@/mixins/wertpapierorder/mixin.js";
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';

export default {
  mixins: [mixin],
  components: {
    ComboBox,
    FormHeader,
    FormLabel,
    InputRadioBoxGroup,
    InputToggleSwitch,
  },
  data() {
    return {
      disabled: false,
      form: {},
      geschaeftTypValues: [{ value: 'VALUE_FODB_MIX_TYP_BERATUNG', label: 'Beratung'},
        { value: 'VALUE_FODB_MIX_TYP_VERMITTLUNG', label: 'Vermittlung' }],
      hinweisText: 'Diese Angaben müssen nur für Mixdepots (und keine anderen Depotarten) ausgefüllt werden. Die einzelnen Punkte werden abhängig vom Kundentyp (Beratung und Vermittlung) und Orderart ein- bzw. ausgeblendet.',
      kaufKeuerValues: [{ value: 'RG_Erfahrung_Unveraendert', label: 'Ich/Wir bestätige/n, dass die bereits von der Bank erhobenen Daten, insbesondere die Angaben zu meinen/unseren Kenntnissen und Erfahrungen, unverändert gelten.' },
        { value: 'RG_Erfahrung_Geaendert', label: 'Meine/Unsere Kenntnisse/Erfahrungen haben sich geändert. Eine aktualisierte Erklärung (Fragen nach Wertpapierhandelsgesetz) ist beigefügt.' }],
      kaufAngemessenValues: [{ value: 'angemessen_pruefung_unmoeglich', label: 'Die erforderlichen Informationen zur Prüfung der Angemessenheit konnten von mir/uns nicht erlangt werden. '
						+ 'Eine Prüfung, ob ich/wir über die erforderlichen Kenntnisse und Erfahrungen verfügen, '
						+ 'um die Risiken im Zusammenhang mit den zu erwerbenden Investmentanteilen angemessen beurteilen zu können, erfolgt daher nicht. '
						+ 'Ich/Wir beauftrage/n die Bank, trotzdem den Auftrag auszuführen.' },
        {value: 'angemessen_pruefung_unangemessen', label: 'Die Prüfung der Angemessenheit der gewünschten Finanzanlage auf Basis der mitgeteilten Kenntnisse und Erfahrungen hat ergeben, dass diese für mich/uns '
						+ 'nicht angemessen ist. Ich/Wir beauftrage/n die Bank, trotzdem den Auftrag auszuführen.' }],
      labelKaufBeratung: 'Dieser Auftrag basiert auf einer Beratung, die der Berater/Vermittler der Fondsdepot Bank in eigenem Namen und auf eigene Rechnung gegenüber dem Anleger erbringt. '
								+ 'Der Berater/Vermittler ist für die Durchführung der Geeignetheitsprüfung und -dokumentation verantwortlich.',
      labelKaufVerm: 'Im Rahmen dieser Vermittlung wird weder durch den Berater/Vermittler noch die depotführende Stelle eine Beratungsleistung erbracht und somit keine Prüfung vorgenommen, '
								+ 'ob die Anlage für die individuelle Situation des Anlegers geeignet ist. Es erfolgt ausschließlich die Prüfung der Angemessenheit der Finanzanlage. '
								+ 'Die Angemessenheit beurteilt sich danach, ob der Anleger über die erforderlichen Kenntnisse und Erfahrungen verfügt, '
								+ 'um die Risiken in Zusammenhang mit der Art der Finanzanlage angemessen beurteilen zu können.',
    };
  },
  mounted() {
    this.initForm();
  },
  updated() {
    this.updateWarnings('fodb-mix', true);
  },
  computed: {
    isEmpty() {
      return !(this.positions?.length && this.data?.hatFODBMix);
    },
  },
  methods: {
    initForm() {
      this.form = {
        INPUT_FODB_MIX_BERATUNGVERMITTLUNG: this.data["INPUT_FODB_MIX_BERATUNGVERMITTLUNG"],
        INPUT_FODB_MIX_KAUF_KEUER: this.data["INPUT_FODB_MIX_KAUF_KEUER"],
        INPUT_FODB_MIX_KAUF_ANGEMESSEN: this.data["INPUT_FODB_MIX_KAUF_ANGEMESSEN"],
        INPUT_FODB_MIX_KAUF_BERATUNG: this.data["INPUT_FODB_MIX_KAUF_BERATUNG"] === "1" || this.data["INPUT_FODB_MIX_KAUF_BERATUNG"] === "true" ? "1" : "0",
        INPUT_FODB_MIX_KAUF_VERMITTLUNG: this.data["INPUT_FODB_MIX_KAUF_VERMITTLUNG"] === "1" || this.data["INPUT_FODB_MIX_KAUF_VERMITTLUNG"] === "true" ? "1" : "0",
        INPUT_FODB_MIX_VERKAUF_BERATUNG: this.data["INPUT_FODB_MIX_VERKAUF_BERATUNG"] === "1" || this.data["INPUT_FODB_MIX_VERKAUF_BERATUNG"] === "true" ? "1" : "0",
        INPUT_FODB_MIX_VERKAUF_VERMITTLUNG: this.data["INPUT_FODB_MIX_VERKAUF_VERMITTLUNG"] === "1" || this.data["INPUT_FODB_MIX_VERKAUF_VERMITTLUNG"] === "true" ? "1" : "0",
        INPUT_CHK_PAPIER: this.data["INPUT_CHK_PAPIER"],
        INPUT_CHK_EMAIL: this.data["INPUT_CHK_EMAIL"],
        INPUT_CHK_WEB: this.data["INPUT_CHK_WEB"],
      };
    },
    isVerkaufDisabled(beratungTyp) {
      return !(this.data?.INPUT_FODB_MIX_BERATUNGVERMITTLUNG === beratungTyp &&
        this.positions?.length && this.positions.some(pos => pos?.positionTyp === 'ALT_VERKAUF' || pos?.positionTyp === 'ALT_ENTNAHMEPLAN'));
    }
  },
};
</script>