var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "besprochene-item-component" },
    [
      _vm._l(_vm.item, function(field, index) {
        return [
          field.type === "TEXT" && field.hidden && !field.hidden.IMMER
            ? _c("InputField", {
                key: index,
                attrs: {
                  label: field.label || "...",
                  value: _vm.data[field.id],
                  disabled: _vm.disabled[field.id]
                },
                on: {
                  change: function($event) {
                    return _vm.handleInputChange(field, $event)
                  }
                }
              })
            : _vm._e(),
          field.type === "TEXT_AREA"
            ? _c("InputTextArea", {
                key: index,
                attrs: { label: field.label, disabled: _vm.disabled[field.id] },
                on: {
                  change: function($event) {
                    return _vm.handleInputChange(field, $event)
                  }
                },
                model: {
                  value: _vm.data[field.id],
                  callback: function($$v) {
                    _vm.$set(_vm.data, field.id, $$v)
                  },
                  expression: "data[field.id]"
                }
              })
            : _vm._e(),
          field.type === "SWITCHER"
            ? _c("InputToggleSwitch", {
                key: index,
                attrs: {
                  label: field.label,
                  inLineLabel: "",
                  disabled: _vm.disabled[field.id]
                },
                on: {
                  change: function($event) {
                    return _vm.handleInputChange(field, $event)
                  }
                },
                model: {
                  value: _vm.data[field.id],
                  callback: function($$v) {
                    _vm.$set(_vm.data, field.id, $$v)
                  },
                  expression: "data[field.id]"
                }
              })
            : _vm._e(),
          field.type === "SINGLE_SELECTION"
            ? _c("InputRadioBoxGroup", {
                key: index,
                attrs: {
                  title: field.label,
                  value: _vm.data[field.id],
                  values: field.values,
                  disabled: _vm.disabled[field.id]
                },
                on: {
                  input: function($event) {
                    return _vm.handleInputChange(field, $event)
                  }
                }
              })
            : _vm._e(),
          field.type === "WERTPAPIER_AUSWAHL"
            ? _c("FormWertpapierAuswahl", {
                key: index,
                attrs: {
                  title: field.label,
                  antragId: _vm.antragId,
                  antragTyp: _vm.antragTyp,
                  config: field.config,
                  categoryId: field.config && field.config.INPUT_ISIN_CHOOSER,
                  suppressFilterLagerstelle: true,
                  disabled: _vm.isDataReadOnly
                }
              })
            : _vm._e(),
          field.type === "WERTPAPIER_AUSWAHL_UNIQUE_WITHOUT_POSITION"
            ? _c("WertpapierAuswahlUnique", {
                key: index,
                attrs: {
                  id: field.id,
                  label: field.label,
                  data: _vm.data,
                  antragId: _vm.antragId,
                  config: field.config,
                  disabled: _vm.isDataReadOnly
                },
                on: {
                  openFondsfinder: function($event) {
                    return _vm.openFondsfinder(field)
                  },
                  removePosition: function($event) {
                    return _vm.removePosition(field, $event)
                  },
                  updateIsin: function($event) {
                    return _vm.$emit("updateIsin", $event)
                  }
                }
              })
            : _vm._e(),
          field.type === "BUTTON"
            ? _c(
                "BaseButton",
                {
                  directives: [
                    {
                      name: "fc-tooltip",
                      rawName: "v-fc-tooltip",
                      value: _vm.$fcOptionalChain(field, "config.tooltip"),
                      expression: "$fcOptionalChain(field, 'config.tooltip')"
                    }
                  ],
                  key: index,
                  staticClass: "mr-3",
                  attrs: { isSecondary: "", disabled: _vm.disabled[field.id] },
                  on: {
                    click: function($event) {
                      return _vm.handleAction(field)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(field.label) + " ")]
              )
            : _vm._e()
        ]
      }),
      _c("FormDivider")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }