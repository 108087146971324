<template>
  <div class="input-forms__container">
    <div class="input-forms__label-container">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-12 col-xl-8">
              <ComboBox
                label="Wählen Sie die Positionen zum Verkauf aus:"
                v-model="depotPosition"
                :values="valuesForDepot"
                :disabled="disabled"
                @input="onChangeInput"
              >
              </ComboBox>
            </div>
            <div class="col-12 col-xl-4 mt-4 pl-2">
              <BaseButton
                :isEmbedded="true"
                :disabled="!depotPosition || disabled"
                @click="addPosition()"
                >Fonds hinzufügen</BaseButton
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="input-forms__input-container">
      <Table
        v-if="rows.length"
        tableId="46c30c0a-aa2b-4885-b3ef-cfedaeec59tr"
        :headers="headers"
        :rows="rows"
        rowId="isin"
        hidePagination
        @action-DELETE="openModalDeleteFond"
      >
        <template v-slot:betraag="row">
          <ComboBox
            :values="anlageValues"
            :value="row.betrag"
            :disabled="row.verkaufBestandGesamt || disabled"
            @input="onChangeInputField($event, row, 'betrag')"
          />
        </template>

        <template v-slot:betrag="row">
          <InputField
            v-if="row.betrag === true"
            type="currency"
            :value="row.wert"
            :disabled="row.verkaufBestandGesamt || disabled"
            :id="'wert' + row.index"
            @input="onChangeInputField($event, row, 'wert')"
          />
          <InputField
            v-if="!(row.betrag === true)"
            type="number"
            :value="row.wert"
            :disabled="row.verkaufBestandGesamt || disabled"
            :id="'wert' + row.index"
            @input="onChangeInputField($event, row, 'wert')"
          />
        </template>

        <template v-slot:verkaufBestandGesamt="row">
          <InputToggleSwitch
            :value="row.verkaufBestandGesamt"
            :id="'verkaufBestandGesamt' + row.index"
            :disabled="disabled"
            @input="onChangeInputField($event, row, 'verkaufBestandGesamt')"
          />
        </template>

        <template v-slot:sparplanLoeschen="row">
          <InputToggleSwitch
            :value="row.sparplanLoeschen"
            :id="'sparplanLoeschen' + row.index"
            :disabled="disabled"
            @input="onChangeInputField($event, row, 'sparplanLoeschen')"
          />
        </template>
        <template v-slot:verkaufDatum="row">
          <DatePickerField
            :value="row.verkaufDatum"
            :id="'verkaufDatum' + row.index"
            isValueAsString
            dateFormat="DD.MM.YYYY"
            @input="onChangeInputField($event, row, 'verkaufDatum')"
          ></DatePickerField>
        </template>
        <template v-slot:bemerkung="row">
          <InputField
            :value="row.bemerkung"
            :id="'bemerkung' + row.index"
            @input="onChangeInputField($event, row, 'bemerkung')"
          />
        </template>
      </Table>

      <DeletePositionModal
        ref="deleteModal"
        :position="positionToDelete"
        @delete="doDeletePosition()"
      ></DeletePositionModal>
    </div>
  </div>
</template>

<script>
import ANTRAG_TYPES from "@/store/antrag/types";
import FONDSFINDER_TYPES from "@/store/fondsfinder/types";
import BaseButton from "@/components/core/BaseButton.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputField from "@/components/core/forms/InputField.vue";
import antragNavigationMixin from "@/mixins/antrag/antrag-navigation-mixin.js";
import BaseModal from "@/components/core/BaseModal.vue";
import Table from "@/components/table2/Table.vue";
import {
  TextColumn,
  ActionColumn,
  SlotColumn,
  SimpleAction,
} from "@/components/table2/table_util.js";
import DeletePositionModal from "@/components/core/forms/antraege/wertpapierAuswahl/DeletePositionModal.vue";
import { mapGetters } from "vuex";
import antragMixin from "@/mixins/antrag/antrag-mixin.js";
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import validator from "@/mixins/validator";
import { required } from "@/mixins/validator/rules";
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";

export default {
  mixins: [antragNavigationMixin, antragMixin, validator],
  props: {
    antragData: {},
    config: {},
    antragTyp: {},
    antragId: {},
    comboboxSelections: {},
    categoryId: {},
    suppressFilterLagerstelle: {
      type: Boolean,
      default: false,
    },
    warnings: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BaseButton,
    BaseModal,
    ComboBox,
    InputField,
    Table,
    DeletePositionModal,
    InputToggleSwitch,
    DatePickerField,
  },
  validators: {
    depotPosition: [
      required("Bitte wählen Sie den Fonds aus", { forceTouch: true }),
    ],
  },
  data() {
    return {
      headers: {
        lockedLeft: [
          TextColumn("isin", "ISIN"),
          TextColumn("fondsname", "Fondsname"),
        ],
        center: [
          SlotColumn("betraag", "Verkauf", 100),
          SlotColumn("betrag", "Betrag/Anzahl", 100),
          SlotColumn("verkaufBestandGesamt", "Gesamtbestand", 30),
          SlotColumn("sparplanLoeschen", "Sparplan löschen", 30),
          SlotColumn("verkaufDatum", "Datum", 80),
          SlotColumn("bemerkung", "Bemerkung", 80),
        ],
        lockedRight: [ActionColumn("actions", "")],
      },
      depotPosition: "",
      positionToDelete: {},
      anlageValues: [
        { label: "in Anteilen", value: false },
        { label: "in EUR", value: true },
      ],
    };
  },
 
  computed: {
    ...mapGetters({
      positionInfo: FONDSFINDER_TYPES.GETTERS.POSITION_INFO,
      positionsAll: ANTRAG_TYPES.GETTERS.POSITIONS,
      positionDataAll: ANTRAG_TYPES.GETTERS.POSITION_DATA,
    }),
    rows() {
      const actions = [SimpleAction("DELETE", "PhTrash", "Löschen")];
      return (this.positions || []).map((row, index) => ({
        ...row,
        index,
        lagerstelle: "FODB",
        fondsname: this.getFondsName(row, this.positionInfo),
        actions,
      }));
    },
    positions() {
      if (
        this.positionsAll &&
        this.antragId &&
        this.categoryId &&
        this.positionsAll[this.antragId] &&
        this.positionsAll[this.antragId][this.categoryId]
      ) {
        console.log(this.positionsAll[this.antragId][this.categoryId])
        return this.positionsAll[this.antragId][this.categoryId];
      }
    },
    positionData() {
      if (
        this.positionDataAll &&
        this.antragId &&
        this.categoryId &&
        this.positionDataAll[this.antragId] &&
        this.positionDataAll[this.antragId][this.categoryId]
      ) {
        return this.positionDataAll[this.antragId][this.categoryId];
      }
    },
    valuesForDepot() {
      if (this.antragData && this.antragData.depotNrCurrent) {
        return this.config.valuesForDepot.filter(
          (d) =>
            d.label.includes(this.antragData.depotNrCurrent) ||
            d.label == "Bitte treffen Sie eine Auswahl"
        );
      } else {
        return this.config.valuesForDepot;
      }
    },
  },
  methods: {
    addPosition() {
      const payload = {
        antragTyp: this.antragTyp || null,
        antragId: this.antragId || null,
        categoryId: this.categoryId,
        positionInfo: this.config.positionInfo.filter(
          (info) => info.begleitscheinId == this.depotPosition
        ),
        saveType: "",
        checkPositions: {
          lagerstelle: "FODB",
          isins: {
            [this.depotPosition]: this.categoryId,
          },
        },
      };
      this.$store.dispatch(
        FONDSFINDER_TYPES.ACTIONS.TAKE_SELECTED_ISINS,
        payload
      );
    },
    onChangeInput() {
      this.addPosition();
    },
    commitUpdatePositionsFromEvent(positionChange, begleitscheinId, isin) {
      const payload = {
        id: this.antragId,
        categoryId: this.categoryId,

        fonds: [
          {
            ...positionChange,
            begleitscheinId,
            isin,
            additionalIdentifier: begleitscheinId
          },
        ],
      };
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_POSITIONS, payload);
    },
    onChangeInputField($event, position, field) {
      const positionChange = { [field]: $event };
      this.commitUpdatePositionsFromEvent(positionChange, position.begleitscheinId, position.isin);
    },
    openModalDeleteFond(fond) {
      if (!this.disabled) {
        this.positionToDelete = fond;
        this.$refs.deleteModal.open();
      }
    },
    doDeletePosition() {
      this.deletePosition(
        this.antragId,
        this.categoryId,
        this.positionToDelete
      );
      this.positionToDelete = null;
    },
  },
};
</script>

<style scoped>
.input-forms__label-content--bigger {
  font-size: 1.5rem;
}
.input-forms__label-content--bigger > i {
  font-size: 2rem;
}
</style>