import Image from '@tiptap/extension-image';
import { VueNodeViewRenderer } from '@tiptap/vue-2';
import ResizableImageTemplate from './ResizableImageTemplate.vue';
import { Plugin, PluginKey, /* NodeSelection, Selection */ } from '@tiptap/pm/state';
import {
  mergeAttributes,
} from '@tiptap/core'

const NODE_NAME = 'image';

export const loadImagePropertiesOfBase64 = (src, filename) => {
  return new Promise((resolve) => {
    const image = document.createElement('img');

    // get the image size from base64
    image.addEventListener('load', () => {
      const imageProps = {
        src,
        height: image.height,
        width: image.width,
        naturalWidth: image.naturalWidth,
        naturalHeight: image.naturalHeight,
        alt: filename,
      };

      resolve(imageProps);
    });

    image.src = src;
  });
}

const CustomImage = Image.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      src: {
        default: '',
        renderHTML: attributes => {
          return {
            src: attributes.src,
          };
        },
      },
      width: {
        renderHTML: ({ width }) => ({ width }),
      },
      height: {
        renderHTML: ({ height }) => ({ height }),
      },
      naturalWidth: {
        renderHTML: ({ naturalWidth }) => ({ naturalWidth }),
      },
      naturalHeight: {
        renderHTML: ({ naturalHeight }) => ({ naturalHeight }),
      },
      isDraggable: {
        default: true,
        renderHTML: () => {
          return {};
        },
      },
    };
  },

  parseHTML() {
    return [{ 
      tag: 'img[src]',
    }]
  },

  renderHTML({ HTMLAttributes }) {
    return ['img', mergeAttributes(HTMLAttributes)]
  },

  addNodeView() {
    return VueNodeViewRenderer(ResizableImageTemplate);
  },

  addCommands() {
    return {
      ...this.parent?.(),
      updateImageSize: (proportion) =>
        ({ commands }) => {
          return commands.updateAttributes(this.name, { ...proportion });
        },
    };
  },

  addProseMirrorPlugins () {
    return [
      new Plugin({
        key: new PluginKey(NODE_NAME),
        props: {
          handleDrop (view, event) {
            event.preventDefault();
            if (event.dataTransfer?.files?.length !== 1) {
              return
            }

            const images = Array.from(event.dataTransfer.files).filter(file => (/image/i).test(file.type))
            if (images.length === 1) {

              const { schema } = view.state
              const coordinates = view.posAtCoords({ left: event.clientX, top: event.clientY })
              const reader = new FileReader()
              reader.onload = readerEvent => {
                loadImagePropertiesOfBase64(readerEvent.target.result, images[0].name).then(imageProps => {
                  const node = schema.nodes[NODE_NAME].create(imageProps);
                  const transaction = view.state.tr.insert(coordinates.pos, node);
                  view.dispatch(transaction);
                })
              }

              reader.readAsDataURL(images[0])
            }
          },
        }
      })
    ]
  }
});

export { CustomImage };
export default CustomImage;
