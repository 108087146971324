import CUSTOMERDATA_TYPES from './types';
import CORE_TYPES from '@/store/core/types';
import { makeQueryParam } from '@/helpers/utils-helper';

export default {
  [CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA](state) {
    return state.customerData
  },
  [CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_CONFIG](state) {
    return state.customerDataConfig
  },
  [CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_ADDITIONAL_PERSONS](state) {
    return state.persons
  },
  [CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_DATA](state) {
    const newPerson = { personId: 'neue_person', isEditable: true }
    const personId = state.selectedPersonForEditing || 'person1'
    if (personId === 'person1' || state.customerData.personId === personId) {
      return state.customerData
    } else if(state.personData?.personId === personId) {
      return { ...state.personData };
    } else {
      const persons = state.persons;
      if (Array.isArray(persons)) {
        const found = persons.find((person) => person.personId === personId);
        return found || newPerson; 
      }
    }
  },
  [CUSTOMERDATA_TYPES.GETTERS.IS_MAIN_PERSON](state, getters) {
    const selectedCustomerData = getters[CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_DATA];
    return state.selectedPersonForEditing === 'person1' || selectedCustomerData?.isMainPerson;
  },
  [CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_ID](state) {
    return state.selectedPersonForEditing
  },
  [CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_EXTRA_DATA](state) {
    return state.selectedPersonForEditingExtraData
  },
  [CUSTOMERDATA_TYPES.GETTERS.GET_CUSTOMER_DATA_EDITED](state) {
    return state.customerDataEdited
  },
  [CUSTOMERDATA_TYPES.GETTERS.GET_ADDITIONAL_VALIDATORS](state) {
    return state.additionalValidators
  },
  [CUSTOMERDATA_TYPES.GETTERS.GET_SAVED_CUSTOMER_ID](state) {
    return state.savedCustomerID
  },
  [CUSTOMERDATA_TYPES.GETTERS.PIN_DATA](state) {
    return state.pinData
  },
  [CUSTOMERDATA_TYPES.GETTERS.PERSON_STATES](state) {
    return state.personStates
  },
  [CUSTOMERDATA_TYPES.GETTERS.SELECTED_PERSON_DATA](state) {
    return { ...state.personData }
  },
  [CUSTOMERDATA_TYPES.GETTERS.IS_NEW_PERSON](state, getters) {
    const selectedCustomerData = getters[CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_DATA];
    return state.selectedPersonForEditing === 'neue_person' || selectedCustomerData?.personId === 'neue_person';
  },
  [CUSTOMERDATA_TYPES.GETTERS.ADDRESS_TO_COPY](state) {
    return { ...state.addressToCopy };
  },
  [CUSTOMERDATA_TYPES.GETTERS.PEOPLE_FOUND](state) {
    return [...state.peopleFound];
  },
  [CUSTOMERDATA_TYPES.GETTERS.PEOPLE_RELATIONSHIP](state) {
    return [...state.peopleRelationship];
  },
  [CUSTOMERDATA_TYPES.GETTERS.HINTS](state) {
    return [...state.hints];
  },
  [CUSTOMERDATA_TYPES.GETTERS.COURTAGE](state) {
    return [...state.courtage];
  },

  [CUSTOMERDATA_TYPES.GETTERS.BEMERKUNGEN](state) {
    return [...state.bemerkungen];
  },

  [CUSTOMERDATA_TYPES.GETTERS.SUB_CUSTOMERS](state) {
    return [...state.subCustomers];
  },

  [CUSTOMERDATA_TYPES.GETTERS.INTERNE_BEMERKUNG](state) {
    return state.interneBemerkung;
  },
 
  [CUSTOMERDATA_TYPES.GETTERS.LAST_SAVED_NEW_PERSON](state) {
    return state.lastSavedNewPerson;
  },

  [CUSTOMERDATA_TYPES.GETTERS.IS_FIRMA](state, getters) {
    const selectedCustomerData = getters[CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_DATA];
    const customerDataEdited = state.customerDataEdited;
    const personalDataAddress = customerDataEdited?.personalDataAddress || selectedCustomerData?.personalDataAddress;
    return personalDataAddress?.title === 'Firma';
  },

  [CUSTOMERDATA_TYPES.GETTERS.BIRTHDAYS](state) {
    return [...state.birthdays || []];
  },

  [CUSTOMERDATA_TYPES.GETTERS.LEGITIMATIONSURKUNDE_CHANGED](state) {
    return state.legitimationsurkundeChanged;
  },

  [CUSTOMERDATA_TYPES.GETTERS.BEMERKUNG_KUNDE](state) {
    return state.bemerkungKunde;
  },
  [CUSTOMERDATA_TYPES.GETTERS.BEMERKUNG_KUNDE_SICHTBAR](state) {
    return state.bemerkungSichtbar;
  },
  [CUSTOMERDATA_TYPES.GETTERS.GET_CUSTOMER_CONTACT_PERSON_DATA](state) {
    return state.contactPerson;
  },
  [CUSTOMERDATA_TYPES.GETTERS.SELECTED_BEMERKUNG](state) {
    return state.selectedBemerkung;
  },
  [CUSTOMERDATA_TYPES.GETTERS.GET_STATE_STAMMDATEN](state) {
    return state.editedState;
  },
  [CUSTOMERDATA_TYPES.GETTERS.GET_FA_CHECK_EMAILS](state) {
    return state.checkEmailsFA;
  },

  [CUSTOMERDATA_TYPES.GETTERS.LEGITIMATION_DOWNLOAD_LINK_FN](_, getters) {
    const apiAddress = getters[CORE_TYPES.GETTERS.API_ADDRESS];
    const token = getters[CORE_TYPES.GETTERS.GET_TOKEN];
    const isFA = getters[CORE_TYPES.GETTERS.IS_FA];

    return (legitimation, personId) => {
      if (isFA && legitimation?.signatureInfo?.fileId) {
        const id = legitimation.signatureInfo.fileId;
        const params = makeQueryParam({ id, token });
        const fileName = legitimation.signatureInfo.fileName;
        return `${apiAddress}/download_service/downloadFileById/${fileName}?${params}`;
      } else {
        const params = makeQueryParam({ personId, token });
        return `${apiAddress}/download_service/generateLegitimationPDF?${params}`;
      }
    };
  },

  [CUSTOMERDATA_TYPES.GETTERS.LEGITIMATION_IMAGES_MOBILE_ACTIONS](state, getters) {
    if (!getters[CORE_TYPES.GETTERS.IS_SMALL_SCREEN]) {
      return [];
    }
    return [ ...state.legitimationImagesMobileActions ];
  },

}