import CORE_TYPES from '@/store/core/types'
import SESSION_TIME_TYPES from '@/store/session-time/types'
import dayjs from 'dayjs'
import { TimeoutWorker } from '@/helpers/workers/timeout-worker';

const SHORTEST_SECONDS_TIME_BETWEEN_REQUESTS = 120;

export function scheduleLogoutTimeout(timeoutSeconds, dispatch, commit) {
  if (timeoutSeconds > 0) {
    let timeoutWithMargin = timeoutSeconds / 2;
    timeoutWithMargin = timeoutWithMargin < SHORTEST_SECONDS_TIME_BETWEEN_REQUESTS ? timeoutSeconds : timeoutWithMargin;

    const timeoutSchedule = new TimeoutWorker();
    timeoutSchedule.run(timeoutWithMargin * 1000).then(() => {
      dispatch(CORE_TYPES.ACTIONS.CHECK_BACKEND_TIMEOUT);
    }).catch((error) => {
      if (error != 'close') {
        throw new Error(error);
      }
    });

    const nextCheckTimeoutTime = dayjs().add(timeoutWithMargin, 'seconds').toDate();
    const supposedTimeoutTime = dayjs().add(timeoutSeconds, 'seconds').toDate();

    commit(CORE_TYPES.MUTATIONS.UPDATE_TIMEOUT_SCHEDULE, {
      timeoutSchedule, 
      nextCheckTimeoutTime,
      supposedTimeoutTime,
    })

    commit(SESSION_TIME_TYPES.MUTATIONS.UPDATE_PARALLEL_TIMEOUT_TIME, supposedTimeoutTime)

    return timeoutSchedule;
  }
}
