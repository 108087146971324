var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.isLoaded
    ? _c(
        "div",
        { staticClass: "event-body" },
        [
          _c(
            "BaseModal",
            {
              ref: "modal",
              attrs: {
                showConfirmButton: _vm.dat != null && _vm.id != null,
                size: "full"
              },
              on: { onConfirmButton: _vm.save, onCancelButton: _vm.close },
              scopedSlots: _vm._u(
                [
                  {
                    key: "modalTitle",
                    fn: function() {
                      return [_vm._v(" Bemerkung ")]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                4049427164
              )
            },
            [
              _c(
                "BoxContainer",
                [
                  _c("InputField", {
                    attrs: { label: "Thema" },
                    model: {
                      value: _vm.dat.subject,
                      callback: function($$v) {
                        _vm.$set(_vm.dat, "subject", $$v)
                      },
                      expression: "dat.subject"
                    }
                  }),
                  _c("InputTextArea", {
                    attrs: { label: "Text", rows: 5 },
                    model: {
                      value: _vm.dat.text,
                      callback: function($$v) {
                        _vm.$set(_vm.dat, "text", $$v)
                      },
                      expression: "dat.text"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }