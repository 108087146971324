import RUHESTANDSPLANUNG_TYPES from './types';

export default {
  [RUHESTANDSPLANUNG_TYPES.GETTERS.SCENARIOS](state) {
    return state.scenarios;
  },
  [RUHESTANDSPLANUNG_TYPES.GETTERS.VERMOEGEN](state) {
    return state.vermoegen;
  },
  [RUHESTANDSPLANUNG_TYPES.GETTERS.AUSGABEN](state) {
    return state.ausgaben;
  },
  [RUHESTANDSPLANUNG_TYPES.GETTERS.COMBOBOX_GOAL_TYPES](state) {
    return state.comboboxGoalTypes;
  },
  [RUHESTANDSPLANUNG_TYPES.GETTERS.SCENARIOS_URSPRUNG](state) {
    return state.scenariosUrsprung;
  },
}