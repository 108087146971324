var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FlexibleList", {
    attrs: { rows: _vm.rows },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function(row) {
          return [
            _vm._v(" " + _vm._s(row.vorname) + " " + _vm._s(row.name) + " ")
          ]
        }
      },
      {
        key: "value",
        fn: function(row) {
          return [_vm._v(" " + _vm._s(row.date) + " ")]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }