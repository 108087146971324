import { Extension, textInputRule, markPasteRule, InputRule, PasteRule } from '@tiptap/core'

import { DOMParser } from 'prosemirror-model'

export const makeOption = (key, text) => (override) => textInputRule({
  find: new RegExp(`(^|\\s)${key}\\s$`, ''),
  replace: override ?? text,
})

export const makePaste = (key, text) => (override) => markPasteRule({
  find: new RegExp(`${key}\\s$`, 'g'),
  replace: override ?? text,
})

export const inputRule = (key, text) => (override) => {
  return new InputRule({
    find: new RegExp(`(^|\\s)${key}\\s$`, 'g'),
    handler: ({ state, range, commands  }) => {
      let start = range.from
      const end = range.to

      state.tr.insertText("", start, end)

      commands.insertContentAt(start, text, {
        updateSelection: true,
        parseOptions: {
          preserveWhitespace: 'full',
        }
      })
    },
  })
}

export const pasteRule = (key, text) => (override) => {
  return new PasteRule({
    find: new RegExp(`${key}\\s$`, 'g'),
    handler: ({ state, range, commands  }) => {
      let start = range.from
      const end = range.to

      state.tr.insertText("", start, end)

      commands.insertContentAt(start, text, {
        updateSelection: true,
        parseOptions: {
          preserveWhitespace: 'full',
        }
      })
    },
  })
}

export const MarkdownShortcuts = (htmlShortcuts = []) => {
  return Extension.create({
    name: 'markdownshortcuts',


    addInputRules() {
      const rules = []
      htmlShortcuts.map(s => {
        if (this.options[s.key] !== false) {
          // rules.push(makeOption(s.key, s.value)(this.options[s.key]))
          rules.push(inputRule(s.key, s.value)(this.options[s.key]))
        }
      })
      return rules
    },
    addPasteRules() {
      const rules = []
      htmlShortcuts.map(s => {
        if (this.options[s.key] !== false) {
          //rules.push(makePaste(s.key, s.value)(this.options[`${s.key}Paste`]))
          rules.push(pasteRule(s.key, s.value)(this.options[`${s.key}Paste`]))
        }
      })
      return rules
    },
  })
}
