<template>
  <div>
        <Table v-if="rows.length"
            ref="table"
            :tableId="tableId"
            cardView
            :headers="headers"
            :rows="rows"
            rowId="id"
            hidePagination
        >


        </Table>    

  </div>
    
</template>

<script>
import { mapGetters } from 'vuex';
import CREDIT_TYPES from '@/store/credit/types';


import { KREDITFORM_BAUFINANZIERUNG } from '../credit-utils';

const FORM_KREDITART_PATH = 'form.kreditart';

import Table from "@/components/table2/Table.vue";
import {TextColumn, CurrencyColumn, PercentageColumn, DateColumn} from "@/components/table2/table_util.js";



export default {
  data() {
    return {
      form: {
        id: null,
        kreditform: null,
        kreditart: null,
        kreditnummer: null,
        bezeichnung: null,
        verwendung: null,
        gesellschaft: null,
        filiale: null,
        bankingAccount: null,
        darlehenssumme: null,
        auszahlungskurs: null,
        bearbeitungskosten: null,
        sollzinsNominal: null,
        sollzinsEffektiv: null,
        sollzinsNachFestschreibung: null,
        sollzinsPeriode: null,
        tilgungsverrechnungPeriode: null,
        zinsabrechnungPeriode: null,
        sonstiges: null,
        begin: null,
        end: null,
        auszahlungDatum: null,
        ersteRateDatum: null,
        ersteTilgungsverrechDatum: null,
        ersteZinsabrechnungDatum: null,
        folgerate: null,
        tilgungssatz: null,
        restschuld: null,
        fruehesterAbloesezeitpunkt: null,
        hatSondertilgung: null,
        sondertilgungProzent: null,
        ablauf: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      creditId: CREDIT_TYPES.GETTERS.SELECTED_CREDIT_ID,
      combos: CREDIT_TYPES.GETTERS.COMBOS,
      credit: CREDIT_TYPES.GETTERS.CREDIT,
      creditEdited: CREDIT_TYPES.GETTERS.CREDIT_EDITED,
      prohyp: CREDIT_TYPES.GETTERS.PROHYP,
    }),
    kreditartCombo() {
      const kreditform = this.form?.kreditform || 'default';
      return this.combos?.kreditArtCombos?.[kreditform];
    },
    hasEhypAntragsnr() {
      return !!this.prohyp.ehypAntragsnr;
    },
    isKreditformBaufinanzierung() {
      return this.form?.kreditform === KREDITFORM_BAUFINANZIERUNG;
    },
    headers() {
        const result = {
        center: [],
        };

        result.center.push(TextColumn("kreditform", "Kredit-/Darlehensform"));
        result.center.push(TextColumn("kreditart", "Kredit-/Darlehensart"));
        result.center.push(TextColumn("bezeichnung", "Bezeichnung"));
        result.center.push(TextColumn("verwendung", "Verwendungszweck"));
        result.center.push(TextColumn("gesellschaft", "Gesellschaft"));
        result.center.push(TextColumn("filiale", "Filiale"));
        result.center.push(TextColumn("bankingAccount", "Bankverbindung"));
        result.center.push(CurrencyColumn("darlehenssumme", "Kredit-/Darlehenssumme"));
        result.center.push(PercentageColumn("auszahlungskurs", "Auszahlungskurs"));
        result.center.push(CurrencyColumn("bearbeitungskosten", "Bearbeitungskosten"));
        result.center.push(PercentageColumn("sollzinsNominal", "Sollzins nominal"));
        result.center.push(PercentageColumn("sollzinsEffektiv", "Sollzins effektiv"));
        result.center.push(PercentageColumn("sollzinsNachFestschreibung", "Sollzins nach Festschreibung"));
        result.center.push(TextColumn("sollzinsPeriode", "Zahlungsperiode Raten"));
        result.center.push(TextColumn("tilgungsverrechnungPeriode", "Tilgungsverrechnung"));
        result.center.push(TextColumn("zinsabrechnungPeriode", "Zinsabrechnung"));
        result.center.push(TextColumn("sonstiges", "Besonderheiten"));
        result.center.push(DateColumn("begin", "Vertragsbeginn"));
        result.center.push(DateColumn("ablauf", "Vertragsablauf"));
        result.center.push(DateColumn("end", "Ende Sollzinsbindung"));
        result.center.push(DateColumn("auszahlungDatum", "Auszahlungstermin"));
        result.center.push(DateColumn("ersteRateDatum", "1. Rate am"));
        result.center.push(DateColumn("ersteTilgungsverrechDatum", "1. Tilgungsverrechnung"));
        result.center.push(DateColumn("ersteZinsabrechnungDatum", "1. Zinsabrechnung"));
        result.center.push(CurrencyColumn("folgerate", "Rate"));
        result.center.push(PercentageColumn("tilgungssatz", "anfänglicher Tilgungssatz"));
        result.center.push(CurrencyColumn("restschuld", "Restschuld nach Festschreibung"));
        result.center.push(DateColumn("fruehesterAbloesezeitpunkt", "Frühester Ablösezeitpunkt"));
        result.center.push(TextColumn("hatSondertilgungText", "Sondertilgung"));

        if (this.form?.hatSondertilgung) {
          result.center.push(PercentageColumn("sondertilgungProzent", "Sondertilgungssatz"));
        }


        return result;
    },   
    rows() {
            const rows = [];

            const row = {};

            Object.keys(this.form).forEach(key => {
                if (key == 'bankingAccount') {
                  row[key] = this.combos?.bankingAccounts?.find(ba => ba.value == this.form[key])?.label;
                } else if (key == 'hatSondertilgung') {
                  row['hatSondertilgungText'] = this.form[key] ? 'Ja' : 'Nein'
                  row[key] = this.form[key];
                } else {
                  row[key] = this.form[key];
                }

            });           


            rows.push(row);
            return rows;
    },    
    tableId() {
        const uniqueUUID = 'ef889158-9779-4cba-b5df-216116b2a2cc';
        return uniqueUUID;
    },  
  },
  watch: {
    credit: {
      handler() {
        this.updateData();
      },
      immediate: true,
    },
  },
  methods: {
    updateData() {
      Object.keys(this.form).forEach(key => {
        if(key in this.creditEdited) {
          this.form[key] = this.creditEdited[key];
        } else if(key in this.credit) {
          this.form[key] = this.credit[key];
        }
      });
    },

  },
  components: {
    Table,
  },
}
</script>