<template>
  <div v-if="isCallModalOpened" class="container" :class="{ 'logged-in__container': isLoggedIn }">
    <div v-if="videoSrc && !collapsedVideo" class="video-container">
      <div class="video-container-inner">
        <video autoplay playsinline :src-object.prop.camel="videoSrc"></video>
      </div>
    </div>
    <audio ref="audioOut" autoplay playsinline :src-object.prop.camel="audioSrc"></audio>
    <div class="header">
      <span class="header-outer">
        <div style="width: max-content;">
          <template v-if="!$isSmallScreen">
            {{displayName}} 
            <span v-if="kundennr">({{kundennr}})</span>
          </template>
          <ph-microphone-slash :size="24" v-if="partnerMuted" weight="fill"/>
        </div>
      </span>
      <div class="icon-button-container" :class="{ 'icon-button-container--tight': $isSmallScreen && !isMobileNativeContext }">
        <ph-phone-disconnect
          v-if="inCallWith || calling"
          :size="iconSize"
          class="icon-button"
          @click="clickPhone"
          color="var(--color-danger)">
          <title>Auflegen</title>
        </ph-phone-disconnect>
        <ph-phone
          v-else-if="available"
          :size="iconSize"
          class="icon-button"
          @click="clickPhone"
          color="var(--color-success)">
          <title>Anruf starten</title>
        </ph-phone>
        <ph-phone
          v-else 
          color="gray"
          :size="iconSize"
          class="icon-button"
          @click="clickPhone">
          <title>Gesprächspartner ist nicht online</title>
        </ph-phone>

        <span class="icon-button" 
          v-if="allowedToRecord" 
          @click="clickRecord"
          :title="recording ? 'Das Gespräch wird aufgezeichnet' : 'Aufzeichnung starten'">
          <div class="record-button"
            :class="[recording ? 'pulsating-record-button' : '']"
            :style="{'background-color': inCallWith ? 'var(--color-danger)' : 'grey'}">
          </div>
        </span>

        <ph-video-camera-slash
          v-if="!previewSrc || videoIsScreen"
          :size="iconSize"
          class="icon-button"
          @click="clickCamera">
          <title>Kameraübertragung starten</title>
        </ph-video-camera-slash>
        <ph-video-camera
          v-else
          :size="iconSize"
          class="icon-button"
          @click="clickCamera">
          <title>Kameraübertragung beenden</title>
        </ph-video-camera>

        <template v-if="!isMobileNativeContext">
          <ph-monitor
            v-if="!previewSrc || !videoIsScreen"
            :size="iconSize"
            class="icon-button"
            @click="clickScreenShare"
            weight="fill">
            <title>Bildschirmübertragung starten</title>
          </ph-monitor>
          <ph-monitor
            v-else
            :size="iconSize"
            class="icon-button"
            @click="clickScreenShare">
            <title>Bildschirmübertragung beenden</title>
          </ph-monitor>
        </template>

        <ph-microphone-slash
          v-if="muted"
          :size="iconSize"
          class="icon-button"
          @click="clickMute">
          <title>Stummschaltung aufheben</title>
        </ph-microphone-slash>
        <ph-microphone
          v-else
          :size="iconSize"
          class="icon-button"
          @click="clickMute">
          <title>Stummschalten</title>
        </ph-microphone>

        <ph-chats
          v-if="!isMobileNativeContext"
          :size="iconSize"
          class="icon-button"
          @click="showChat=!showChat">
          <title>Chat öffnen / schließen</title>
        </ph-chats>
        <ph-arrows-out-simple
          v-if="videoSrc && collapsedVideo"
          :size="iconSize"
          class="icon-button"
          @click="collapsedVideo=false">
          <title>Empfangenes Video maximieren</title>
        </ph-arrows-out-simple>
        <ph-arrows-in-simple
          v-if="videoSrc && !collapsedVideo"
          :size="iconSize"
          class="icon-button"
          @click="collapsedVideo=true">
          <title>Empfangenes Video minimieren</title>
        </ph-arrows-in-simple>
        <ph-gear
          v-if="!isMobileNativeContext"
          :size="iconSize" 
          class="icon-button"
          @click="openSettings">
          <title>Einstellungen</title>
        </ph-gear>
        <ph-speaker-simple-slash
          v-if="showToggleToAudioOutputSpeaker"
          class="icon-button"
          :size="iconSize"
          @click="toggleAudioOutput">
          <title>Speaker</title>
        </ph-speaker-simple-slash>
        <ph-speaker-simple-high
          v-if="showToggleToAudioOutputReceiver"
          class="icon-button"
          :size="iconSize"
          @click="toggleAudioOutput">
          <title>Receiver</title>
        </ph-speaker-simple-high>
      </div>
      <span class="header-outer">
        <span v-if="callStart">{{callDuration}}</span>
        <span v-else-if="!isCallingActive" class="clickable" @click="toggleCallModal">
          Fertig
        </span>
      </span>
    </div>
    <div class="preview-container">
      <DraggableElement v-if="previewSrc && !isDummyStream">
        <div class="preview-window" >
          <div class="screen-title">
            <div class="title-label">
              übertragenes Video
            </div>
            <div @click="collapsedPreviewVideo=!collapsedPreviewVideo">
              <ph-arrows-out-simple
                v-if="collapsedPreviewVideo"
                class="icon-button"
                :size="iconSize">
                <title>übertragenes Video maximieren</title>
              </ph-arrows-out-simple>
              <ph-arrows-in-simple
                v-if="!collapsedPreviewVideo"
                class="icon-button"
                :size="iconSize">
                <title>übertragenes Video minimieren</title>
              </ph-arrows-in-simple>
            </div>
          </div>          
          <video v-if="!collapsedPreviewVideo" autoplay playsinline :src-object.prop.camel="previewSrc"></video>
        </div>
      </DraggableElement>
      <DraggableElement v-if="videoSrc && collapsedVideo">
        <div class="preview-window">
          <div class="screen-title">{{displayName}}</div>
          <video autoplay playsinline :src-object.prop.camel="videoSrc"></video>
        </div>
      </DraggableElement>
    </div>
    <MediaSettingsModal v-if="mediaSettings" @close="mediaSettings=false" style="pointer-events: auto"/>
    <ChatModal v-if="showChat && chatInfo" 
      @close="closeChat" 
      :beteiligterId="beteiligterId" 
      :chatId="chatId" 
      :channelId="channelId"
      :displayName="displayName"
      :kundennr="kundennr"
      style="pointer-events: auto"/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import CORE_TYPES from '@/store/core/types';
import LOG_TYPES from '@/store/log/types';
import BRIDGE_TYPES from '@/store/bridge/types';
import WEBRTC_TYPES from '@/store/webrtc/types';
import ChatModal from './ChatModal.vue';
import DraggableElement from '@/components/core/DraggableElement.vue';
import MediaSettingsModal from './MediaSettingsModal.vue';
import { getSavedSpeakerId } from '@/helpers/webrtc-settings-helper';

import {
  PhArrowsInSimple,
  PhArrowsOutSimple,
  PhChats,
  PhGear,
  PhMicrophone,
  PhMicrophoneSlash,
  PhMonitor,
  PhPhone,
  PhPhoneDisconnect,
  PhSpeakerSimpleHigh,
  PhSpeakerSimpleSlash,
  PhVideoCamera,
  PhVideoCameraSlash,
} from 'phosphor-vue'
import { getVideoMediaStream } from '@/store/webrtc/sockets/media-device-management';

export default {
  components: {
    ChatModal,
    MediaSettingsModal,
    PhPhone,
    PhPhoneDisconnect,
    PhMicrophone,
    PhMicrophoneSlash,
    PhVideoCamera,
    PhVideoCameraSlash,
    PhMonitor,
    PhChats,
    PhArrowsOutSimple,
    PhArrowsInSimple,
    PhGear,
    PhSpeakerSimpleHigh,
    PhSpeakerSimpleSlash,
    DraggableElement,
  },
  props: {
  },
  computed: {
    ...mapGetters({
      beteiligterInfos: WEBRTC_TYPES.GETTERS.BETEILIGTER_INFOS,
      openWith: WEBRTC_TYPES.GETTERS.OPENED_CHAT_BETEILIGTER_ID,
      availableBeteiligterId: WEBRTC_TYPES.GETTERS.CHAT_BETEILIGTER_ID,
      inCallWith: WEBRTC_TYPES.GETTERS.IN_CALL_WITH,
      calling: WEBRTC_TYPES.GETTERS.WE_ARE_CALLING_ID,
      available: WEBRTC_TYPES.GETTERS.AVAILABLE,
      callStart: WEBRTC_TYPES.GETTERS.CALL_START_DATE,
      videoSrc: WEBRTC_TYPES.GETTERS.RECEIVED_VIDEO,
      audioSrc: WEBRTC_TYPES.GETTERS.RECEIVED_AUDIO,
      muted: WEBRTC_TYPES.GETTERS.MUTED,
      partnerMuted: WEBRTC_TYPES.GETTERS.PARTNER_MUTED,
      previewSrc: WEBRTC_TYPES.GETTERS.SENT_VIDEO,
      callId: WEBRTC_TYPES.GETTERS.CALL_ID,
      chatInfos: WEBRTC_TYPES.GETTERS.CHAT_INFO,
      showRecordButton: CORE_TYPES.GETTERS.SHOW_WEBRTC_CALL_RECORDING_BUTTON,
      isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
      recording: WEBRTC_TYPES.GETTERS.RECORDING,
      isMobileNativeContext: BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT,
      isLoggedIn: CORE_TYPES.GETTERS.IS_LOGGED_IN,
      isAudioOutputSpeaker: BRIDGE_TYPES.GETTERS.IS_AUDIO_OUTPUT_SPEAKER,
      isAudioOutputReceiver: BRIDGE_TYPES.GETTERS.IS_AUDIO_OUTPUT_RECEIVER,
      clientCallId: BRIDGE_TYPES.GETTERS.CLIENT_CALL_ID,
      showButtonSwitchAudioOutput: BRIDGE_TYPES.GETTERS.SHOW_BUTTON_SWITCH_AUDIO_OUTPUT,
      isDummyStream: WEBRTC_TYPES.GETTERS.IS_DUMMY_STREAM,
      isCallingActive: WEBRTC_TYPES.GETTERS.IS_CALLING_ACTIVE,
    }),
    showToggleToAudioOutputSpeaker() {
      return this.showButtonSwitchAudioOutput && this.clientCallId && this.isMobileNativeContext && !this.isAudioOutputSpeaker;
    },
    showToggleToAudioOutputReceiver() {
      return this.showButtonSwitchAudioOutput && this.clientCallId && this.isMobileNativeContext && !this.isAudioOutputReceiver;
    },
    isCallModalOpened() {
      return this.inCallWith || this.openWith || null
    },
    beteiligterId() {
      return this.inCallWith || this.availableBeteiligterId || null;
    },
    kundennr() {
      return this.beteiligterInfos[this.beteiligterId] ? this.beteiligterInfos[this.beteiligterId].kundennr : null;
    },
    displayName() {
      return this.beteiligterInfos[this.beteiligterId] ? this.beteiligterInfos[this.beteiligterId].displayName : "";
    },
    chatInfo() {
      return this.chatInfos.find(chatInfo => chatInfo.beteiligterId === this.beteiligterId);
    },
    channelId() {
      const channelId = this.chatInfo?.channelId;
      return channelId && channelId !== '0' ? channelId : null;
    },
    chatId() {
      const chatId = this.chatInfo?.chatId;
      return chatId && chatId !== '0' ? chatId : null;
    },
    allowedToRecord() {
      return this.showRecordButton && this.isBrokerOrBypass;
    },
    iconSize() {
      return 32;
    },
  },
  watch: {
    callStart(callStart) {
      if (callStart) {
        this.setTimerUpdater()
      } else {
        this.clearTimerUpdater()
      }
    }
  },
  data() {
    return {
      callDuration: "",
      timer: null,
      showChat: false,
      collapsedVideo: false,
      collapsedPreviewVideo: false,
      videoIsScreen: false,
      mediaSettings: false,
    }
  },
  methods: {
    async checkAvailableSavedSpeakerId() {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioDevice = devices.find((device) => device.kind === 'audiooutput' && device.deviceId === getSavedSpeakerId());
      return audioDevice?.deviceId;
    },
    toggleCallModal() {
      this.$store.commit(WEBRTC_TYPES.MUTATIONS.TOGGLE_CALL_MODAL);
    },
    updateTime() {
      if (this.callStart) {
        const seconds = Math.floor((Date.now() - this.callStart) / 1000);
        this.callDuration = ("" + Math.floor(seconds / 60)).padStart(2, "0") + ":" + ("" + (seconds % 60)).padStart(2, "0");
      }
      else
        this.callDuration = "";
      if (this.isCallModalOpened && this.audioSrc && this.$refs.audioOut && this.$refs.audioOut.setSinkId) {
        this.checkAvailableSavedSpeakerId()
          .then(deviceId => deviceId && this.$refs.audioOut.setSinkId(deviceId))
          .catch(error => this.$store.dispatch(LOG_TYPES.ACTIONS.ERROR, {message: 'failed to set speaker as sink', error}));
      }
    },
    clickPhone() {
      if (this.inCallWith || this.calling) {
        this.$store.dispatch(WEBRTC_TYPES.ACTIONS.HANG_UP);
      } else if (this.available) {
        this.$confirmModal({
          title: 'Videoanruf', 
          message: 'Der Anruf wird als Videoanruf mit aktivierter Kamera gestartet.<br>Wollen sie fortfahren?', 
          labelButtonConfirm: 'Fortfahren',
          labelButtonCancel: 'Abbrechen',
        }).then(() => {
          this.$store.dispatch(WEBRTC_TYPES.ACTIONS.START_CALL, this.beteiligterId);
        }).catch(() => {});
      }
    },
    async clickCamera() {
      if (!this.callId) {
        return;
      }

      if (this.previewSrc && this.videoIsScreen) {
        this.videoIsScreen = false;
        this.$store.dispatch(WEBRTC_TYPES.ACTIONS.TRANSMIT_VIDEO, { stream: null });
        if (this.previewSrc) {
          return;
        }
      }
      if (this.previewSrc && !this.videoIsScreen) {
        this.videoIsScreen = false;
        this.$store.dispatch(WEBRTC_TYPES.ACTIONS.TRANSMIT_VIDEO, { stream: null });
      } else {
        try {
          const [ stream ] = await getVideoMediaStream(this.$store.dispatch);
          this.$store.dispatch(WEBRTC_TYPES.ACTIONS.TRANSMIT_VIDEO, { stream, isCamera: true });
        } catch (error) {
          this.$store.dispatch(WEBRTC_TYPES.ACTIONS.TRANSMIT_VIDEO, { stream: null });
        }
      }
    },
    async clickScreenShare() {
      if (!this.callId) {
        return;
      }

      if (this.previewSrc && this.videoIsScreen) {
        this.videoIsScreen = false;
        this.$store.dispatch(WEBRTC_TYPES.ACTIONS.TRANSMIT_VIDEO, { stream: null });
      } else {
        try {
          const stream = await navigator.mediaDevices.getDisplayMedia({
            audio: false,
            video: {
              cursor: 'always',
              frameRate: 15,
            },
          });

          this.$store.dispatch(WEBRTC_TYPES.ACTIONS.TRANSMIT_VIDEO, { stream, isCamera: false });
          this.videoIsScreen = true;
        } catch (error) {
          if (this.videoIsScreen) {
            this.$store.dispatch(WEBRTC_TYPES.ACTIONS.TRANSMIT_VIDEO, { stream: null });
          }
        }
      }
    },
    clickMute() {
      if (!this.callId) {
        return;
      }

      if (this.muted) {
        this.$store.dispatch(WEBRTC_TYPES.ACTIONS.UNMUTE);
      } else {
        this.$store.dispatch(WEBRTC_TYPES.ACTIONS.MUTE);
      }
    },
    clickRecord() {
      if (this.inCallWith && !this.recording) {
        this.$store.dispatch(WEBRTC_TYPES.ACTIONS.START_RECORDING, this.callId);
      }
    },
    closeChat() {
      this.showChat = false;
    },
    openSettings() {
      this.mediaSettings = true;
    },
    close() {
      if (this.inCallWith || this.calling) {
        this.$store.dispatch(WEBRTC_TYPES.ACTIONS.HANG_UP);
      }
      if (this.openWith) {
        this.$emit('close');
      }
      this.$store.commit(WEBRTC_TYPES.MUTATIONS.TOGGLE_CALL_MODAL);
    },
    setTimerUpdater() {
      this.clearTimerUpdater()
      this.timer = setInterval(() => this.updateTime(), 1000);
    },
    clearTimerUpdater() {
      this.callDuration = ''
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
    },
    toggleAudioOutput() {
      this.$store.dispatch(BRIDGE_TYPES.ACTIONS.TOGGLE_AUDIO_OUTPUT);
    }
  },
}
</script>

<style scoped>

.button--disabled {
  opacity: 0.3;
  cursor: not-allowed !important;
}
.container {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 2;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  pointer-events: none;
}

.logged-in__container {
  bottom: 50px;
}
@media only screen and (min-width: 768px) {
  .logged-in__container {
    bottom: 0; /* to fit base-footer__content on small screens */
  }
}
.header {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 56px;
  line-height: 56px;
  justify-content: space-between;
  overflow-y: visible;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  pointer-events: auto;
}
.header-outer {
  margin: 0 10px;
  width: 50%;
  flex-shrink: 1;
  display: flex;
  justify-content: center;
}
.video-container {
  bottom: 50px;
  top: 0;
  max-width: 100%;
  flex-shrink: 1;
  flex-grow: 1;
  overflow: hidden;
  width: 100%;
  pointer-events: auto;
}
.video-container-inner {
  height: 100%;
}
.video-container-inner video {
  width: 100%;
  height: 100%;
}
.preview-container > video {
  border: 5px solid black;
  border-top-width: 0;
  border-right-width: 0;
  border-radius: 4px;
}
.preview-container {
  position:absolute;
  display: flex;
  flex-direction: column;
  width: 200px;
  right: 0;
  pointer-events: auto;
  bottom: 70px;
}
.preview-window {
  width: 200px;
  display: flex;
  flex-direction: column;
  user-select: none;
}
.screen-title > .icon-button {
  margin: 0px;
}
.title-label {
  align-self: center;
  padding-left: 1em;
}
.screen-title {
  background: rgba(0, 0, 0, 0.7);
  color: white;
  font-size: small;
  line-height: 32px;
  display: flex;
  justify-content: space-between;
}
video {
  max-height: 100%;
  width: auto;
  object-fit: contain;
}
.preview-window > video {
  max-height: 200px;
  max-width: 200px;
  width: auto;
  height: auto;
  object-fit: contain;
  background: rgba(0, 0, 0, 0.7);
}
.icon-button-container{
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
}
.icon-button {
  cursor: pointer;
  border-radius: 100px;
  background: white;
  margin: 1em;
  color: black;
  border: 4px solid white;
}
.icon-button-container--tight .icon-button {
  margin: 1em 0.5em;
}
.icon-button:hover {
  background: lightgray;
  border-color: lightgray;
}
.record-button {
  width: 18px;
  height: 18px;
  border-radius: 50px;
  margin: 3px;
}
.pulsating-record-button {
  animation-name: pulsate-record-button;
  animation-duration: .5s;
  animation-timing-function: ease-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-play-state: running;
}
@keyframes pulsate-record-button {
  0% {
    transform: scale(.8);
    opacity: .5;
  }
  100% {
    transform: scale(1.1);
  }
}
</style>