export const MUTATION_PREFIX = 'CUSTOMER_CATEGORY: ';
export const ACTIONS_PREFIX = 'CUSTOMER_CATEGORY_';
export const GETTERS_PREFIX = 'CUSTOMER_CATEGORY_';


export default {
  MUTATIONS: {
    GET_CUSTOMER_CATEGORY_SUCCESS: MUTATION_PREFIX + 'GET_CUSTOMER_CATEGORY_SUCCESS',
    ADD_CUSTOMER_CATEGORY_EDITED: MUTATION_PREFIX + 'ADD_CUSTOMER_CATEGORY_EDITED',
    RESET_CUSTOMER_CATEGORY_EDITED: MUTATION_PREFIX + 'RESET_CUSTOMER_CATEGORY_EDITED',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
  },
  ACTIONS: {
    GET_CUSTOMER_CATEGORY: ACTIONS_PREFIX + 'GET_CUSTOMER_CATEGORY',
    SAVE_CUSTOMER_CATEGORY: ACTIONS_PREFIX + 'SAVE_CUSTOMER_CATEGORY',
  },
  GETTERS: {
    CUSTOMER_CATEGORY: GETTERS_PREFIX + 'CUSTOMER_CATEGORY',
    CUSTOMER_CATEGORY_EDITED: GETTERS_PREFIX + 'CUSTOMER_CATEGORY_EDITED',
    HAS_CUSTOMER_CATEGORY_EDITED: GETTERS_PREFIX + 'HAS_CUSTOMER_CATEGORY_EDITED',
  },
}
