import store from '@/store';
import router from '@/router';
import SESSION_TIME_TYPES from '@/store/session-time/types'

let fromPath = null

document.addEventListener('click', function (event) {

  const path = !fromPath ? router.currentRoute.path : fromPath
  fromPath = null

  store.dispatch(SESSION_TIME_TYPES.ACTIONS.ADD_CLICK, {
    x: event.pageX,
    y: event.pageY,
    path
  });

}, false);

// in order to get the proper path in case the navigation occurs before the event has really been consumed
router.beforeEach(async (to, from, next) => {
  fromPath = from.path
  next()
})