<template>
  <div class="box__container">
    <div class="box__title">Arbeitgeber</div>

    <div v-if="isMainPerson">
      <BaseButtonsContainer v-if="isEditable" class="mb-3">
        <BaseButton isSecondary @click="navigateToAddExistentPeople()">
          Weiteren Arbeitgeber auswählen
        </BaseButton>
        <BaseButton @click="openModalNewArbeitgeber()">
          als Arbeitgeber hinzufügen
        </BaseButton>
        <BaseButton @click="newPerson()">
          Neuer Arbeitgeber
        </BaseButton>
      </BaseButtonsContainer>

        <Table v-if="rows.length"
            tableId="0a01baf1-d9e6-4a75-878c-a3fb31231984"
            :headers="headers"
            :rows="rows"
            rowId="personId"
            hidePagination
            :exportConfig="{roottext: 'Arbeitgeber'}"
            @click-personName="editPerson($event.personId)"
            @action-EDIT="openModalEditArbeitgeber($event.personId)"
            @action-DELETE="deleteArbeitgeberConfirmation"
        />
        <div v-else>Keine Daten</div>

      <BaseModal
        @onConfirmButton="saveNewArbeitgeber()"
        ref="editNewArbeitgeber"
        size="sm"
        modalTitle="Arbeitgeber"
        labelButtonConfirm="Als Arbeitgeber hinzufügen"
        :confirmDisabled="validation.arbeitgeber.personId.invalid">

          <ComboBox 
            :values="newPersonList" 
            v-model="arbeitgeber.personId"/>

      </BaseModal>

      <BaseModal
        @onConfirmButton="updateArbeitgeber()"
        ref="editEmployee"
        size="sm"
        modalTitle="Arbeitgeber"
        :confirmDisabled="!isEditable"
      >

          <DatePickerField label="Arbeitnehmereintritt" 
            isValueAsString
            v-model="employee.jobStartDate"
            :disabled="!isEditable"/>

          <InputField label="Personalnummer" 
            v-model="employee.personalNr"
            :disabled="!isEditable"/>

          <InputField label="Abteilung" 
            v-model="employee.department"
            :disabled="!isEditable"/>

          <InputField label="Geschäftsführung" 
            v-model="employee.manager"
            :disabled="!isEditable"/>

      </BaseModal>

      <BaseModal
        @onConfirmButton="deleteArbeitgeber()"
        ref="deleteArbeitgeberConfirmation"
        size="sm"
        modalTitle="Arbeitgeber entfernen">
          {{ deleteMessage }}
      </BaseModal>
    </div>
  </div>
</template>

<script>
const RELATION_ARBEITGEBER = 'ARBEITGEBER';

function arbeitgeberInitialState() {
  return {
    personId: '',
    relation: { 
      value: RELATION_ARBEITGEBER,
    }
  }
}

function employeeInitialState() {
  return {
    jobStartDate: '',
    personalNr: '',
    department: '',
    manager: '',
    person: {
      value: ''
    }
  }
}

import CUSTOMERDATA_TYPES from '@/store/customerData/types'
import { mapGetters } from 'vuex'
import validator from '@/mixins/validator'
import { required } from '@/mixins/validator/rules'

import Table from "@/components/table2/Table.vue";
import {TextColumn, DateColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import BaseModal from '@/components/core/BaseModal.vue'
import BaseButton from '@/components/core/BaseButton.vue'
import ComboBox from '@/components/core/forms/ComboBox.vue'
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue'
import InputField from '@/components/core/forms/InputField.vue'
import BaseButtonsContainer from '@/components/core/BaseButtonsContainer.vue';

export default {
  mixins: [validator],
  data() {
    return {
      arbeitgeber: arbeitgeberInitialState(),
      employee: employeeInitialState(),
      personToDelete: null,
        headers: {
            lockedLeft: [
                TextColumn("personName", "Arbeitgeber").makeLink(),
            ],
            center: [
                DateColumn("jobStartDate", "Arbeitnehmereintritt"),
                TextColumn("personalNr", "Personalnummer"),
                TextColumn("department", "Abteilung"),
                TextColumn("manager", "Geschäftsführung"),
            ],
            lockedRight: [
                ActionColumn("actions", "Aktionen"),
            ],
        }
    }
  },
  computed: {
    ...mapGetters({
      customerData: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_DATA,
      isMainPerson: CUSTOMERDATA_TYPES.GETTERS.IS_MAIN_PERSON,
      persons: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_ADDITIONAL_PERSONS,
      personId: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_ID,
    }),
    isEditable() {
      return this.customerData?.isEditable
    },
    employees() {
      return this.customerData?.employment?.employees || []
    },
    rows() {
        const actions = [
            SimpleAction("EDIT", this.isEditable ? 'PhPencilLine' : 'PhEye', "Bearbeiten"),
        ];
        if (this.isEditable)
            actions.push(SimpleAction("DELETE", "PhTrash", "Löschen"));
        return this.employees.map(emp => ({
            personName: emp.person && emp.person.display,
            jobStartDate: emp.jobStartDate,
            personalNr: emp.personalNr,
            department: emp.department,
            manager: emp.manager,
            personId: emp.person.value,
            actions,
        }));
    },
    newPersonList() {
      const personList = [
        { value: '', label: 'Bitte wählen Sie eine zusätzliche Person aus' },
        ...(this.persons && this.persons.map((person) => ({
          value: person.personId,
          label: (`${person.personalDataAddress.firstName || ''} ${person.personalDataAddress.lastName || ''}`).trim(),
        })) || [])
      ];
      
      return personList
    },

    deleteMessage() {
      return `Soll der Arbeitgeber '${(this.personToDelete?.personName || '').trim()}' wirklich vom Kunden entfernt werden?`;
    },

  },
  methods: {
    openModalNewArbeitgeber() {
      this.arbeitgeber = arbeitgeberInitialState()
      this.$refs.editNewArbeitgeber.open()
    },

    async saveNewArbeitgeber() {
      this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.ADD_CUSTOMER_DATA_EDITED, {personId: this.personId, relationship: this.arbeitgeber})
      await this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.SAVE_CUSTOMER_DATA)
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA_ADDITIONAL_PERSONS, { list: true })
    },

    openModalEditArbeitgeber(personId) {
      const employeeFound = this.employees.find((emp) => emp.person.value === personId)
      this.employee = employeeFound && { ...employeeFound, } || employeeInitialState()
      this.$refs.editEmployee.open()
    },

    updateArbeitgeber() {
      const employment = { 
        ...(this.customerData.employment || {}),
      }

      const employeeEditedIndex = employment?.employees?.findIndex((emp) => emp.person.value === emp.person.value)
      if(employeeEditedIndex >= 0) {
        employment.employees[employeeEditedIndex] = {
          ...employment.employees[employeeEditedIndex],
          ...this.employee,
        }
      }

      const payload = {
        employment,
        personId: this.personId,
      }
      this.$store.commit(CUSTOMERDATA_TYPES.MUTATIONS.ADD_CUSTOMER_DATA_EDITED, payload)
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.SAVE_CUSTOMER_DATA)
    },

    editPerson(personId) {
      this.$router.push({ path: `/persoenlichedaten/person-data/${personId}` })
    },

    newPerson() {
      this.$router.push({ 
        path: '/persoenlichedaten/person-data/neue_person',
        query: {
          relation: RELATION_ARBEITGEBER,
        },
      })
    },

    navigateToAddExistentPeople() {
      this.$router.push({
        path: '/persoenlichedaten/add-existent-people',
        query: {
          relation: RELATION_ARBEITGEBER,
          backUrl: this.$route.path,
        },
      });
    },

    deleteArbeitgeberConfirmation(person) {
      this.personToDelete = person;
      this.$refs.deleteArbeitgeberConfirmation.open();
    },

    deleteArbeitgeber() {
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.DELETE_PERSON, { personId: this.personToDelete.personId });
    },

  },
  validators: {
    arbeitgeber: {
      personId: required('')
    }
  },
  components: {
    Table,
    BaseButton,
    BaseModal,
    ComboBox,
    DatePickerField,
    InputField,
    BaseButtonsContainer,
  }

}
</script>

<style scoped>

</style>