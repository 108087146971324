<template>
  <div class="cards__container">
    <FormLabel v-if="config.anzahlFonds"
      :config="{ small: true }"
      :label="'(Maximal ' + config.anzahlFonds + ' Positionen)'"
    />

    <div class="fondsfinder-button__container">
      <div class="input-forms__label-content--bigger mb-3">
        <ph-bank :size="16" />&nbsp;Kauf
      </div>
      <div>
        <BaseButton @click="openFondsfinder('KAUF')" :disabled="positions.length >= 4">Kauf hinzufügen</BaseButton>
      </div>
    </div>            
      
    <div v-if="hasKaufPositions" class="cards__items">
      <div class="box__container cards__item" v-for="(pos, index) in kaufPositions" :key="pos.isin + index" 
        :class="getPositionClasses(pos)" ref="cards"
      >
        <FormEbaseWeitereDepotsPositionCards
          :key="index"
          :position="pos"
          :antragId="antragId"
          :isWebservice="config.isWebservice"
          :disabled="disabled"
          :positionWarnings="positionWarnings"
        />
      </div>

      <div class="box__container cards__item">
        <div class="cards__item--inputs mt-3"> 

          <InputRadioBoxGroup class="cards__item--input-240"
            id="einmalModusAbbuchung"
            v-model="form.einmalModusAbbuchung"
            :values="einmalModusValues"
            @input="updatePositionData('KAUF', $event, 'einmalModusAbbuchung')"
            :disabled="disabled"
            validateUntouched
          />
          <DatePickerField class="cards__item--input-240"
            label="Abbuchungstermin Einmalanlage"     
            id="einmalDatumAbbuchung"
            v-model="form.einmalDatumAbbuchung"
            @input="updatePositionData('KAUF', $event, 'einmalDatumAbbuchung')"
            :disabled="disabled || !(form.einmalModusAbbuchung === '2' || form.einmalModusAbbuchung === 2)"
          />
        </div>  
      </div>
    </div>
    
    <div class="fondsfinder-button__container mt-3">
      <div class="input-forms__label-content--bigger mb-3">
        <ph-bank :size="16" />&nbsp;Sparplan
      </div>
      <div>
        <BaseButton @click="openFondsfinder('SPARPLAN')" :disabled="positions.length >= 4">Sparplan hinzufügen</BaseButton>
      </div>
    </div>            
      
    <div v-if="hasSparplanPositions" class="cards__items">
      <div class="box__container cards__item" v-for="(pos, index) in sparplanPositions" :key="pos.isin + index" 
        :class="getPositionClasses(pos)" ref="cards"
      >
        <FormEbaseWeitereDepotsPositionCards
          :key="index"
          :position="pos"
          :antragId="antragId"
          :isWebservice="config.isWebservice"
          :disabled="disabled"
          :positionWarnings="positionWarnings"
        />
      </div>

      <div class="box__container cards__item">
        <div class="cards__item--inputs mt-3"> 
          <DatePickerField class="cards__item--input-240"
            monthPicker
            label="Abbuchungstermin Sparplan"
            id="sparplanBeginnDatum"
            v-model="form.sparplanBeginnDatum"
            placeholder="MM.JJJJ"
            @input="updatePositionData('SPARPLAN', $event, 'sparplanBeginnDatum')"
            :disabled="disabled"
          />
          <DatePickerField class="cards__item--input-240"
            monthPicker
            label="Letzte Ausführung Sparplan"
            id="sparplanEndDatum"
            v-model="form.sparplanEndDatum"
            placeholder="MM.JJJJ"
            @input="updatePositionData('SPARPLAN', $event, 'sparplanEndDatum')"
            :disabled="disabled"
          />
          <ComboBox class="cards__item--input-295"
            label="Der Einzug der Sparpläne erfolgt vom:"
            :sortComboboxValues="false"
            id="spKontoFlex"
            v-model="form.spKontoFlex"
            :values="[
              { label: 'Konto flex', value: true },
              {
                label: 'externen Konto (siehe Sonstiges)',
                value: false,
              },
            ]"
            @input="updatePositionData('SPARPLAN', $event, 'spKontoFlex')"
            :disabled="disabled"
          />
          <ComboBox class="cards__item--input-295"
            label="Dynamik"
            :sortComboboxValues="false"
            id="sparplanDynamik"
            v-model="form.sparplanDynamik"
            :values="sparplanDynamikValues"
            @input="updatePositionData('SPARPLAN', $event, 'sparplanDynamik')"
            :disabled="disabled"
          />
          <InputField class="cards__item--input-240"
            label="Prozent Dynamik"
            id="sparplanDynamikFrei"
            v-model="form.sparplanDynamikFrei"
            type="number"
            :precision="0"
            validateUntouched
            @input="updatePositionData('SPARPLAN', $event, 'sparplanDynamikFrei')"
            :disabled="disabled || !(form.sparplanDynamik != -1 && form.sparplanDynamik != 3 && form.sparplanDynamik != null)"
          />
        </div>
      </div>
    </div>

    <div v-if="config.mitSummenbildung && this.positions && this.positions.length">
      <Summenbildung
        :antragId="antragId"
        :summe="summeEinmalig"
        :summeSpar="summeSparplan"
        :mitSparplan="true"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BaseButton from "@/components/core/BaseButton.vue";
import FormEbaseWeitereDepotsPositionCards from "@/components/core/forms/antraege/wertpapierAuswahl/FormEbaseWeitereDepotsPositionCards.vue";
import antragNavigationMixin from "@/mixins/antrag/antrag-navigation-mixin.js";
import ANTRAG_TYPES from "@/store/antrag/types";
import FONDSFINDER_TYPES from "@/store/fondsfinder/types";
import Summenbildung from "@/components/antrag/Summenbildung.vue";
import FormLabel from "@/components/core/forms/FormLabel.vue";
import {  PhBank } from "phosphor-vue";
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import InputField from "@/components/core/forms/InputField.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputRadioBoxGroup from "@/components/core/forms/radiobox/InputRadioBoxGroup.vue";
import validator from "@/mixins/validator";

export default {
  mixins: [antragNavigationMixin, validator],
  validators: {},
  props: {
    title: {},
    antragId: {},
    categoryId: {},
    antragTyp: {},
    config: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    positionWarnings: [],
    antragWarnings: [],
  },
  components: {
    BaseButton,
    FormEbaseWeitereDepotsPositionCards,
    Summenbildung,
    FormLabel,
    PhBank,
    DatePickerField,
    InputField,
    ComboBox,
    InputRadioBoxGroup,
  },
  data() {
    return {
      form: {
        einmalModusAbbuchung: '',
        einmalDatumAbbuchung: '',
        sparplanBeginnDatum: '',
        sparplanEndDatum: '',
        spKontoFlex: '',
        sparplanDynamik: '',
        sparplanDynamikFrei: '',
      },
      sparplanDynamikValues: [
        {
          value: 3.0,
          label:
            "Meine regelmäßigen Einzahlungen erhöhen sich jeweils nach zwölf Monaten um 3 Prozent.",
        },
        {
          value: 0.0,
          label: "Die Dynamik soll davon abweichen (in ganzen Prozentpunkten):",
        },
        { value: -1.0, label: "keine Dynamik." },
      ],
      einmalModusValues: [
        { value: '1', label: "soll sofort vom Referenzkonto abgebucht werden" },
        { value: '2', label: "soll abgebucht werden am" },
        { value: '3', label: "wird von mir überwiesen" },
      ],
    }
  },
  watch: {
    antragWarnings(newValue) {
      this.updateWarnings();
    },
  },
  mounted() {
    this.setPositionData();
    setTimeout(() => this.updateWarnings(), 400);
  },
  computed: {
    ...mapGetters({
      positionsAll: ANTRAG_TYPES.GETTERS.POSITIONS,
      positionDataAll: ANTRAG_TYPES.GETTERS.POSITION_DATA,
      positionInfo: FONDSFINDER_TYPES.GETTERS.POSITION_INFO,
    }),
    positions() {
      return this.antragId 
        ? (this.positionsAll?.[this.antragId]?.["SPARPLAN"] || []).map(pos => ({
          ...pos,
          art: 'SPARPLAN',   
          fondsname: pos.fondsname || this.getFondsName(pos),
        }))
          .concat((this.positionsAll?.[this.antragId]?.["KAUF"] || []).map(pos => ({
            ...pos,
            art: 'KAUF',
            fondsname: pos.fondsname || this.getFondsName(pos),
          })))
        : [];
    },
    kaufPositions() {
      return this.antragId 
        ? (this.positionsAll?.[this.antragId]?.["KAUF"] || []).map(pos => ({
          ...pos,
          art: 'KAUF',   
          fondsname: pos.fondsname || this.getFondsName(pos),
        }))
        : [];
    },
    sparplanPositions() {
      return this.antragId 
        ? (this.positionsAll?.[this.antragId]?.["SPARPLAN"] || []).map(pos => ({
          ...pos,
          art: 'SPARPLAN',   
          fondsname: pos.fondsname || this.getFondsName(pos),
        }))
        : [];
    },
    summeSparplan() {
      return this.positions?.filter(pos => pos?.planBetrag)?.reduce((acc, cur) => acc + +cur.planBetrag, 0);
    },
    summeEinmalig() {
      return this.positions?.filter(pos => pos?.einmalBetrag)?.reduce((acc, cur) => acc + +cur.einmalBetrag, 0);
    },
    hasKaufPositions() {
      return this.kaufPositions?.length;
    },
    hasSparplanPositions() {
      return this.sparplanPositions?.length;
    },
  },
  methods: {
    setPositionData() {
      if (this.antragId) {
        this.form = Object.assign(this.form, this.positionDataAll[this.antragId]?.['KAUF'], this.positionDataAll[this.antragId]?.['SPARPLAN'], this.positionDataAll[this.antragId]?.['VWL'])
      }
    },
    openFondsfinder(category) {
      this.goToFormsFinder(
        category,
        this.config,
        this.suppressFilterLagerstelle
      );
    },
    getFondsName(pos) {
      return (pos && this.positionInfo?.[pos.isin]?.wertpapiername) ||
        pos?.fondsname ||
        pos?.wertpapiername;
    },
    getPositionClasses(pos) {
      return { 'has-warnings': this.hasWarnings(pos)};
    },
    hasWarnings(pos) {
      return pos && this.warnings?.filter(warn => warn.posGrpId === pos.posGrpId)?.length ;
    },
    updatePositionData(category, value, componentId) {
      const payload = {
        id: this.antragId,
        categoryId: category,
        positionData: {[componentId]: value},
      };
        
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_POSITION_DATA, payload);
    },
    updateWarnings() {
      this.antragWarnings?.forEach(warn =>  {
        if (warn?.id && Object.keys(this.form)?.includes(warn?.id)) {   
          this.$pushErrors(`form.${warn.id}`, [warn.message]);
        }
      });
    },
  },
};
</script>

<style src='@/styles/antrag.scss' lang='scss' scoped></style>
