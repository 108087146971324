export const MUTATION_PREFIX = 'INFOBRIEFE_MUTATIONS_';
export const ACTIONS_PREFIX = 'INFOBRIEFE_ACTIONS_';
export const GETTERS_PREFIX = 'INFOBRIEFE_GETTERS_';

export default {
  MUTATIONS: {
    RETRIEVE_INFOBRIEFE_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_INFOBRIEFE_SUCCESS',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    FREIGEBEN_SUCCESS: MUTATION_PREFIX + 'FREIGEBEN_SUCCESS',
  },
  ACTIONS: {
    RETRIEVE_INFOBRIEFE: ACTIONS_PREFIX + 'RETRIEVE_INFOBRIEFE',
    FREIGEBEN: ACTIONS_PREFIX + 'FREIGEBEN',
    VOR_VERSAND_FREIGEBEN: ACTIONS_PREFIX + 'VOR_VERSAND_FREIGEBEN',
  },
  GETTERS: {
    INFOBRIEFE_DATA: GETTERS_PREFIX + 'INFOBRIEFE_DATA',
  }
}