var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _c(
          "BaseButton",
          {
            staticClass: "upload-btn",
            attrs: { isPrimary: _vm.isPrimary, disabled: _vm.disabled }
          },
          [
            _vm._t("default", function() {
              return [_vm._v("Datei hinzufügen")]
            }),
            _c(
              "label",
              {
                staticClass: "upload-btn--label",
                class: { clickable: !_vm.disabled }
              },
              [
                _c("input", {
                  attrs: {
                    type: "file",
                    accept: _vm.accept,
                    multiple: "",
                    disabled: _vm.disabled
                  },
                  on: { change: _vm.onAddFiles }
                })
              ]
            )
          ],
          2
        )
      ],
      1
    ),
    _vm.value && _vm.value.length ? _c("hr") : _vm._e(),
    _c(
      "div",
      { staticClass: "flex-layout" },
      [
        _vm._l(_vm.value, function(file) {
          return [
            _c(
              "div",
              {
                key: file.name + file.size + file.lastModified,
                staticClass: "pill__container"
              },
              [
                _c("Pill", {
                  attrs: {
                    label: file.name,
                    type: "default",
                    showCloseIcon: true
                  },
                  on: {
                    removePill: function($event) {
                      return _vm.onRemoveFile(file)
                    }
                  }
                })
              ],
              1
            )
          ]
        })
      ],
      2
    ),
    _vm.value && _vm.value.length ? _c("hr") : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }