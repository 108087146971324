var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: _vm.$appNavigation.currentMenu.label }
      }),
      _c("BaseFilter", {
        attrs: {
          filterId: _vm.saveKey,
          configFilter: _vm.configFilter,
          metadata: _vm.searchMenu,
          defaultOptions: _vm.defaultOptionsData,
          showSaveButton: "",
          hasSmartSearch: ""
        },
        on: { onFilter: _vm.handleSearch }
      }),
      _c("HandleTablePin", { attrs: { keyOfPIN: "TABELLE_MAKLER_ANTRAEGE" } }),
      _vm.filtered
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              !_vm.loading && _vm.rows && _vm.rows.length
                ? _c("Table", {
                    ref: "tableResult",
                    staticClass: "table-container",
                    attrs: {
                      title: _vm.TABLE_TITLE,
                      tableId: "66be2dca-d420-4d21-a093-1adc48dedee8",
                      configModalTitle: "Vermittlerwechsel Versicherungen",
                      headers: _vm.headers,
                      rows: _vm.rows,
                      rowsPerPage: 25,
                      exportConfig: {
                        roottext: "Vermittlerwechsel Versicherungen"
                      },
                      mobileConfig: {
                        title: "gesellschaft",
                        headers: ["kunde", "datum", "pruefstatus"]
                      }
                    },
                    on: {
                      "click-gesellschaft": function($event) {
                        return _vm.navigateToGesellschaft(
                          $event.gesellschaftId,
                          $event.gesellschaftArt
                        )
                      },
                      "click-polNr": function($event) {
                        return _vm.openInsurance($event, "allgemeine")
                      },
                      "click-document": _vm.downloadFiles,
                      "click-kunde": _vm.openCustomerNewTab,
                      action: function($event) {
                        return _vm.handleTableAction($event)
                      },
                      "click-courtageErhalten": function($event) {
                        return _vm.openInsurancesNewTab($event, "courtage")
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "countMailsSend",
                          fn: function(row) {
                            return [
                              row && row.countMailsSend === "Keine"
                                ? _c("div", [
                                    _vm._v(
                                      " " + _vm._s(row.countMailsSend) + " "
                                    )
                                  ])
                                : _c("div", [
                                    _c(
                                      "a",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.goToEmailsList(row)
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(row.countMailsSend))]
                                    )
                                  ])
                            ]
                          }
                        },
                        {
                          key: "pruefstatus",
                          fn: function(row) {
                            return [
                              _vm.istBearbeiter
                                ? _c(
                                    "div",
                                    [
                                      _c("ComboBox", {
                                        attrs: {
                                          value: row.pruefstatus,
                                          values: _vm.comboboxValuesStatus
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.checkStatus(
                                              "pruefstatus",
                                              $event,
                                              row
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    [
                                      _c("Pill", {
                                        directives: [
                                          {
                                            name: "fc-tooltip",
                                            rawName: "v-fc-tooltip",
                                            value: row.tooltip,
                                            expression: "row.tooltip"
                                          }
                                        ],
                                        attrs: {
                                          type: "info",
                                          label: _vm.getStatusLabel(row)
                                        }
                                      })
                                    ],
                                    1
                                  )
                            ]
                          }
                        },
                        {
                          key: "kd",
                          fn: function(row) {
                            return [
                              _c("InputToggleSwitch", {
                                attrs: {
                                  value: row.kd,
                                  inLineLabel: "",
                                  suppressValidationMessage: "",
                                  disabled: !_vm.istBearbeiter
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.saveData("kd", $event, row)
                                  }
                                }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      6202770
                    )
                  })
                : _vm.loading
                ? _c("GhostLoading", {
                    attrs: { type: "table", title: _vm.TABLE_TITLE }
                  })
                : _c("NoData", { attrs: { title: _vm.TABLE_TITLE } })
            ],
            1
          )
        : _vm._e(),
      _c(
        "BaseModal",
        {
          ref: "hatFehler",
          attrs: { showCancelButton: false, labelButtonConfirm: "Ok" },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-warning", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v(" Fehler ")
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", [
            _vm._v(
              " Wählen Sie bitte einen Status: geprüft, nicht geprüft, nicht versendet, erledigt, selbst gesendet, abgelehnt. "
            )
          ])
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "infoMaklervertrag",
          attrs: { showCancelButton: false, labelButtonConfirm: "Ok" },
          on: { onConfirmButton: _vm.closeInfoModal },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [_vm._v(" " + _vm._s(_vm.infoTitle) + " ")]
              },
              proxy: true
            }
          ])
        },
        [
          _vm.infoText && _vm.infoText.length
            ? _c(
                "div",
                _vm._l(_vm.infoText, function(line, index) {
                  return _c("div", { key: index, staticClass: "mt-1" }, [
                    _vm._v(" " + _vm._s(line) + " ")
                  ])
                }),
                0
              )
            : _vm._e()
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "emptyFile",
          attrs: { showCancelButton: false, labelButtonConfirm: "Ok" },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-warning", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v("Fehler ")
                ]
              },
              proxy: true
            }
          ])
        },
        [_c("div", [_vm._v("Es liegen keine Dokumente vor.")])]
      ),
      _c(
        "BaseModal",
        {
          ref: "errorFile",
          attrs: {
            showCancelButton: false,
            showCloseButton: false,
            labelButtonConfirm: "Schließen"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.closeErrrorModal()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-warning", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v("Fehler ")
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", [
            _vm._v(
              _vm._s(_vm.errorStr || "Dokument kann nicht geöffnet werden.")
            )
          ]),
          _vm.files && _vm.files.length
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "mt-4" }, [_vm._v("Dokumente:")]),
                  _vm._l(_vm.files, function(file, index) {
                    return _c("div", { key: index }, [
                      _c(
                        "a",
                        {
                          on: {
                            click: function($event) {
                              return _vm.openFile(file)
                            }
                          }
                        },
                        [_vm._v(_vm._s(file.fileName))]
                      )
                    ])
                  })
                ],
                2
              )
            : _vm._e()
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "deleteAuftragModal",
          attrs: { labelButtonConfirm: "Ja", labelButtonCancel: "Nein" },
          on: {
            onConfirmButton: function($event) {
              return _vm.removeEintrag(_vm.toDelete.id)
            },
            onCancelButton: function($event) {
              return _vm.closeDeleteModal()
            },
            onCloseButton: function($event) {
              return _vm.closeDeleteModal()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-warning", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v("Eintrag löschen ")
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm.toDelete
            ? _c("div", [
                _vm._v(
                  "Soll der Eintrag für " +
                    _vm._s(_vm.toDelete.gesellschaft) +
                    " von " +
                    _vm._s(_vm.toDelete.kunde) +
                    " (" +
                    _vm._s(_vm.toDelete.kundennr) +
                    ") vom " +
                    _vm._s(_vm.toDelete.datum) +
                    ", Vertrag " +
                    _vm._s(_vm.toDelete.polNr) +
                    " wirklich gelöscht werden?"
                )
              ])
            : _vm._e()
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "resetVersandModal",
          attrs: { labelButtonConfirm: "Ja", labelButtonCancel: "Nein" },
          on: {
            onConfirmButton: function($event) {
              return _vm.resetVersand()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-warning", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v("Makleraufträge erneut versenden ")
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", [
            _vm._v(
              "Es werden alle angezeigten Makleraufträge, die noch nicht abgeschlossen sind so versendet als wären sie neu hochgeladen worden."
            )
          ])
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "emptyModal",
          attrs: { labelButtonCancel: "Ok", showConfirmButton: false },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-warning", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v("Keine Makleraufträge ")
                ]
              },
              proxy: true
            }
          ])
        },
        [_c("div", [_vm._v("Keine Makleraufträge angezeigt.")])]
      ),
      _c(
        "BaseModal",
        {
          ref: "bestandsuebertragungStatusEditModal",
          attrs: { showConfirmButton: true },
          on: {
            onConfirmButton: function($event) {
              return _vm.sendBestandsuebertragungStatusEdit()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [_vm._v(" Bemerkung für Status ")]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "div",
            [
              _c("InputField", {
                attrs: {
                  isComponentHalfSize: "",
                  placeholder: "Bemerkung für Status"
                },
                model: {
                  value: _vm.bemerkungFurStatus,
                  callback: function($$v) {
                    _vm.bemerkungFurStatus = $$v
                  },
                  expression: "bemerkungFurStatus"
                }
              })
            ],
            1
          )
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "auftragStornierenModal",
          attrs: { labelButtonConfirm: "Ja", labelButtonCancel: "Nein" },
          on: { onConfirmButton: _vm.auftragStornieren },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-warning", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v("Auftrag stornieren ")
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", [
            _vm._v(
              ' Durch die manuelle Stornierung wird der Auftrag als "Nicht durchgefürht" markiert. Der Maklerauftrag wird dann nicht weiter an die Gesellfschaft geschickt. Möchten Sie den Auftrag wirklich stornieren? '
            )
          ])
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "auftragBestaetigungModal",
          attrs: { labelButtonConfirm: "Ja", labelButtonCancel: "Nein" },
          on: { onConfirmButton: _vm.auftragBestaetigung },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-warning", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v("Auftrag bestätigen ")
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", [
            _vm._v(
              ' Durch die manuellen Bestätigung wird der Auftrag als "Erfolgreich" markiert. Der Maklerauftrag wird dann nicht weiter an die Gesellschaft geschickt. Möchten Sie den Auftrag wirklich bestätigen? '
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }