var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c(
        "PageHeaderTitleNavigation",
        _vm._g(
          {
            attrs: {
              title: _vm.$appNavigation.currentMenu.label,
              id: _vm.$appNavigation.currentOptionMenuId,
              actions: _vm.headerActions
            },
            on: {
              "action-SAVE": function($event) {
                return _vm.save()
              }
            }
          },
          _vm.$listeners
        )
      ),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("InputToggleSwitch", {
            attrs: {
              label: "Automatische Antworten aktivieren",
              inLineLabel: "",
              suppressValidationMessage: ""
            },
            model: {
              value: _vm.absence_note.enabled,
              callback: function($$v) {
                _vm.$set(_vm.absence_note, "enabled", $$v)
              },
              expression: "absence_note.enabled"
            }
          }),
          _c("div", { staticClass: "flex gap-4 my-4" }, [
            _c(
              "div",
              { staticClass: "flex" },
              [
                _c("DatePickerField", {
                  attrs: {
                    disabled: _vm.disabled,
                    label: "Von",
                    isValueAsString: "",
                    emptyValueAsNull: "",
                    validateUntouched: ""
                  },
                  model: {
                    value: _vm.absence_note.from,
                    callback: function($$v) {
                      _vm.$set(_vm.absence_note, "from", $$v)
                    },
                    expression: "absence_note.from"
                  }
                }),
                _c("TimePicker", {
                  attrs: { disabled: _vm.disabled, label: "Uhrzeit" },
                  model: {
                    value: _vm.absence_note.from_time,
                    callback: function($$v) {
                      _vm.$set(_vm.absence_note, "from_time", $$v)
                    },
                    expression: "absence_note.from_time"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex" },
              [
                _c("DatePickerField", {
                  attrs: {
                    disabled: _vm.disabled,
                    label: "Bis",
                    isValueAsString: "",
                    emptyValueAsNull: "",
                    validateUntouched: ""
                  },
                  model: {
                    value: _vm.absence_note.to,
                    callback: function($$v) {
                      _vm.$set(_vm.absence_note, "to", $$v)
                    },
                    expression: "absence_note.to"
                  }
                }),
                _c("TimePicker", {
                  attrs: { disabled: _vm.disabled, label: "Uhrzeit" },
                  model: {
                    value: _vm.absence_note.to_time,
                    callback: function($$v) {
                      _vm.$set(_vm.absence_note, "to_time", $$v)
                    },
                    expression: "absence_note.to_time"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "my-4" }, [_vm._v("Automatische Antwort")]),
          _c("HtmlEditor", {
            attrs: { disabled: _vm.disabled },
            model: {
              value: _vm.absence_note.message,
              callback: function($$v) {
                _vm.$set(_vm.absence_note, "message", $$v)
              },
              expression: "absence_note.message"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }