import { isRolesIncludes } from '@/router/guards';

import { BROKER_STRUCTURE_ROLES, ROLES, VIEW_ROLES, EMPLOYEE_ROLES, BROKER_LEVEL_ROLES } from '@/router/roles'

export default [
  {
    path: 'versicherungsfilter',
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_BROKER, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN, EMPLOYEE_ROLES.ZUGRIFF_KUNDEN, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN], 
          [VIEW_ROLES.VIEW_INTERN, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN]
        ]
      },
    },
    component: () => import(/* webpackChunkName: "BaseRouterView" */ '@/views/BaseRouterView.vue'),
    children: [
      {
        path: '',
        redirect: 'insurance-group'
      },
      {
        path: 'insurance-group',
        component: () => import(/* webpackChunkName: "versicherungen-broker" */ '@/views/versicherungen/insurance-group/Broker.vue'),
      },
    ]
  },
  {
    path: 'versicherungen',
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_CUSTOMER, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN]]
      },
    },
    component: () => import(/* webpackChunkName: "BaseRouterView" */ '@/views/BaseRouterView.vue'),
    children: [
      {
        path: '',
        redirect: 'insurance-group',
      },
      {
        path: 'insurance-group',
        component: () => import(/* webpackChunkName: "versicherungen_home" */ '@/views/BaseRouterView.vue'),
        children: [
          {
            path: '',
            component: () => {
              if(isRolesIncludes([VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS])) {
                return import(/* webpackChunkName: "versicherungen-broker" */ '@/views/versicherungen/insurance-group/Broker.vue');
              } else {
                return import(/* webpackChunkName: "versicherungen-customer" */ '@/views/versicherungen/insurance-group/Customer.vue');
              }
            },
          },
          {
            path: 'details/:id',
            component: () => import(/* webpackChunkName: "insurance-detail" */ '@/views/versicherungen/InsuranceDetail.vue'),
          },
          {
            path: 'overview/:id',
            component: () => import(/* webpackChunkName: "insurance-detail-overview" */ '@/views/versicherungen/InsuranceOverview.vue'),
          },
          {
            path: 'gesellschaftsInfos/:gesellschaftId',
            component: () => import(/* webpackChunkName: "insurance-detail" */ '@/views/versicherungen/GesellschaftsInfos.vue'),
          },          
          {
            path: 'schadensmeldung/:vertragId/:schadenId',
            name: 'schadensmeldung-home',
            component: () => import(/* webpackChunkName: "schadensmeldungen" */ '@/views/schadensmeldung/SchadenDetail.vue'),
          },
          {
            path: 'schadensmeldung/:vertragId/schadennr/:schadennr',
            name: 'schadensmeldung-home-nr',
            component: () => import(/* webpackChunkName: "schadensmeldungen" */ '@/views/schadensmeldung/SchadenDetail.vue'),
          },
          {
            path: 'schadensmeldung/zahlungen-geschaedigte/:vertragId/:schadenId',
            name: 'zahlungen-geschaedigte',
            component: () => import(/* webpackChunkName: "zahlungenGeschaedigte" */ '@/views/schadensmeldung/SchadensmeldungZahlungenGeschaedigteTab.vue'),
          },
          {
            path: 'vertrag-status/:vertragId',
            component: () => import(/* webpackChunkName: "vertrag-status" */ '@/views/versicherungen/VertragsStatus.vue'),
          },
          {
            path: 'versicherung-buchungs/:vertragId/:buchungId',
            name: 'buchungs-daten',
            component: () => import(/* webpackChunkName: "versicherung-buchungs" */ '@/views/versicherungen/Buchung.vue'),
          },
          {
            path: 'versicherung-verteilung-struktur/:vertragId/:buchungId',
            name: 'verteilung-struktur-daten',
            component: () => import(/* webpackChunkName: "versicherung-verteilung-struktur" */ '@/views/versicherungen/VerteilungStruktur.vue'),
          },
          {
            path: 'versicherung-eingebundene-wertpapiere/:vertragId/:sparteId',
            name: 'versicherung-eingebundene-wertpapiere',
            component: () => import(/* webpackChunkName: "versicherung-eingebundene-wertpapiere" */ '@/components/versicherungen/InsuranceEingebundeneWertpapiere.vue'),
            meta: {
              roles: {
                allowed: [[VIEW_ROLES.VIEW_CUSTOMER]],
              }
            },
          },
          {
            path: 'deckblatt/:vertragId',
            name: 'deckblatt-home',
            component: () => import(/* webpackChunkName: "deckblat" */ '@/views/deckblatt/Deckblatt.vue'),
          },
          {
            path: 'fahrzeugdaten/:versicherungId/:id?',
            component: () => import(/* webpackChunkName: "fahrzeugdaten" */ '@/views/versicherungen/FahrzeugDatenEdit.vue'),
          },
          {
            path: 'insurancePostfach/:versicherungId',
            component: () => import(/* webpackChunkName: "insurancePostfach" */ '@/views/versicherungen/InsurancePostfach.vue'),
          },
          {
            path: 'dokumente/:versicherungId',
            component: () => import(/* webpackChunkName: "dokumente" */ '@/views/versicherungen/VersicherungDokumente.vue'),
          },   
          {
            path: 'anmerkungen/:versicherungId',
            component: () => import(/* webpackChunkName: "Anmerkungen" */ '@/views/versicherungen/InsuranceAnmerkung.vue'),
          },       
          {
            path: 'deeplinks/:versicherungId',
            component: () => import(/* webpackChunkName: "insurance-deeplinks" */ '@/views/versicherungen/InsuranceDeeplinks.vue'),
          },
        ],
      },
      {
        path: 'auswahl-vertragsart',
        component: () => import(/* webpackChunkName: "auswahl-vertragsart" */ '@/views/versicherungen/AuswahlVertragsart.vue'),
      },
      {
        path: 'kundennr-aendern/:id',
        component: () => import(/* webpackChunkName: "kundennr-aendern" */ '@/views/versicherungen/KundennummerAendern.vue'),
        meta: {
          breadcrumb: {
            label: 'Kundennr Ändern',
          },
        },
      },
      // {
      //   path: 'fahrzeugdaten/:versicherungId/:id?',
      //   component: () => import(/* webpackChunkName: "fahrzeugdaten" */ '@/views/versicherungen/FahrzeugDatenEdit.vue'),
      // }, 
    ]
  },
]
