<template>
  <BaseModal ref="deleteModal" 
    modalTitle="Position entfernen?"
    labelButtonConfirm="Position entfernen"
    @onConfirmButton="onConfirmModal()"
    @onCancelButton="onCancelModal()">
    
    <div>
      Soll die Position <span class="font-bold">{{ fondDescription }}</span> wirklich entfernt werden?
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/core/BaseModal.vue';
import antragMixin from '@/mixins/antrag/antrag-mixin.js';

export default {
    mixins: [antragMixin],
    components: {
      BaseModal,
    },
    props: {
      position: {
      },
      positionLabel: {
      }
    },
    computed: {
      fondDescription() {
        return `${this.positionLabel ? (this.positionLabel + ': ') : ''} ${this.position?.fondsname || ''} (ISIN ${this.position?.isin})`;
      },
    },
    methods: {
        open() {
            this.$refs.deleteModal.open();
        },
        onConfirmModal() {
          this.$emit('delete');
          this.$refs.deleteModal.close();
        },
        onCancelModal() {
           this.$refs.deleteModal.close();
        },
    }
}
</script>

<style>

</style>