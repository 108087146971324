<template>
<div class="container" v-on:click="$emit('click', step.stepkey)">
  <span class="stop-content clickable" 
    :class="[step.current && 'stop-content__current']">
    <!-- [appPopover]="step.label" -->
    <template v-if="step.loading">
      <svg
        :style="{'animation-delay': index * 100 + 'ms'}"
        xmlns="http://www.w3.org/2000/svg"
        class="stop-loading"
        viewBox="0 0 52 52">
        <circle
          :style="{'animation-delay': index * 100 + 'ms'}"
          cx="26" 
          cy="26" 
          r="18" 
          class="stop-loading__circle"
          stroke-width="6"
          stroke-dasharray="86"
          fill="none"
        />   
      </svg>
      <div class="stop-loading-small"></div>
    </template>
    <template v-else>
      <template v-if="step.success">
        <svg class="stop-checkmark"
          :style="{'animation-delay': index * 100 + 'ms'}"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 52 52">
          <circle
            :style="{'animation-delay': index * 100 + 'ms'}"
            class="stop-checkmark__circle" cx="26" cy="26" r="25" fill="none"
          />
          <path
            :style="{'animation-delay': index * 100 + 'ms'}"
            class="stop-checkmark__content" fill="none" stroke-width="5"
            d="M14.1 27.2l7.1 7.2 16.7-16.8"
          />
        </svg>
        <div class="stop-checkmark-small"></div>
      </template>
      <template v-if="step.warning">
        <svg class="stop-exclamation-mark"
          :style="{'animation-delay': index * 100 + 'ms'}"
          viewBox="0 0 52 52"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            :style="{'animation-delay': index * 100 + 'ms'}" class="stop-exclamation-mark__circle" cx="26" cy="26" r="25" fill="none"
          />
          <path
          :style="{'animation-delay': index * 100 + 'ms'}"
            class="stop-exclamation-mark__content" fill="none"
            d="m28.836 39.219q0 1.1484-0.82031 1.9922-0.79688 0.84375-2.0156 0.84375t-2.0391-0.84375q-0.79688-0.84375-0.79688-1.9922 0-1.1953 0.82031-2.0156 0.82031-0.82031 2.0156-0.82031 1.1953 0 2.0156 0.82031 0.82031 0.82031 0.82031 2.0156zm-5.3906-29.273h5.1094l-1.4531 22.312h-2.2031z"
          />
        </svg>
        <div class="stop-exclamation-mark-small"></div>
      </template>
    </template>
  </span>
  <div :class="[
      'stop-label clickable',
      first && 'stop-label--left',
      !first && !last && 'class.stop-label--center',
      last && 'class.stop-label--right',
      step.current && 'class.stop-label__current',
    ]"
  >{{ step.label }}</div>
</div>
</template>
<script>
export default {
	props: {
		step: {
			type: Object,
			default: () => {}
    },
    first: {
			type: Boolean,
			default: false
    },
    last: {
			type: Boolean,
			default: false
    },
    index: {
			type: Number,
			default: 0
    },
		currentStep: {
			type: Object,
			default: function() {
				return {};
			}
		},
	},
	mounted: function () {
	},
	computed: {
  },
  methods: {
		isActive(stepKey) {
			return this.currentStep && this.currentStep.step.stepKey === stepKey;
		},

  },
}
</script>
<style scoped>
.container {
  height: 24px;
  width: 24px;
  display: flex;
  position: relative;
}

.stop-content{
  border-radius: 50%;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
  outline: none;
}

.stop-content:hover {
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,1);
}

.stop-label {
  position: absolute;
  top: 16px;
  font-size: 0.75rem;
  display: none;
  color: var(--text-etikett);
  outline: none;
}

.stop-content__current{
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,1);
}

.stop-label__current {
  font-size: 0.93rem;
  display: inherit;
  color: var(--text-primaer);
}

.stop-checkmark-small {
  background-color: rgb(40,167,69);
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.stop-exclamation-mark-small {
  background-color: #dc3545;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.stop-label--center{
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
}

.stop-label--right{
  text-align: right;
  right: 0;
}

.stop-label--left{
  text-align: left;
  left: 0;
}

.stop-checkmark {
  display: none;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  stroke-width: 2;
  stroke: var(--color-box);
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px rgb(40,167,69);
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.stop-checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: rgb(40,167,69);
  fill: none;
  animation: stroke .6s cubic-bezier(0.650, 0.000, 0.450, 1.000) forwards;
}

.stop-checkmark__content {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  stroke: var(--color-box);
  animation: stroke .3s cubic-bezier(0.650, 0.000, 0.450, 1.000) .8s forwards;
}


.stop-exclamation-mark {
  display: none;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  stroke-width: 2;
  stroke: var(--color-box);
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #dc3545;
  animation: fill-warning .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.stop-exclamation-mark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #dc3545;
  fill: none;
  animation: stroke .6s cubic-bezier(0.650, 0.000, 0.450, 1.000) forwards;
}

.stop-exclamation-mark__content {
  transform-origin: 50% 50%;
  fill: var(--color-box);
  animation: shake .3s cubic-bezier(0.650, 0.000, 0.450, 1.000) .8s forwards;
}

.stop-loading {
  display: none;
}

.stop-loading-small {
  border-radius: 50%;
  background: #666666;
  border: 1px solid #666666;
  animation: pulse 0.8s linear infinite;
  width: 100%;
  height: 100%;
}

.stop-loading__circle {
  transform-origin: 50% 50%;
  animation: infinite-rotate 0.8s linear infinite;
  stroke: #666666;
}

svg {
  vertical-align: inherit;
}

@keyframes pulse {  
  0% { 
    opacity: 1; 
  }
  50% {
    opacity: 0.2;
  }
  100% { 
    opacity: 1;
  }
}

@keyframes infinite-rotate {  
  0% { 
    transform: rotate(0deg); 
  }
  100% { 
    transform: rotate(360deg); 
  }
}

@keyframes shake {
  0%, 100% {
    transform: none;
  }

  20%, 60% {
    transform: rotate(7deg);
  }

  40%, 80% {
    transform: rotate(-7deg);
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px rgb(40,167,69);
  }
}

@keyframes fill-warning {
  100% {
    box-shadow: inset 0px 0px 0px 30px #dc3545;
  }
}

@media (min-width: 992px) {
  .stop-label {
    display: inherit;
  }
}

@media (min-width: 768px) {
  :host{
    height: 24px;
    width: 24px;
  }
  .stop-checkmark {
    display: inline;
  }
  .stop-loading {
    display: inline;
  }
  .stop-loading-small {
    display: none;
  }
  .stop-checkmark-small {
    display: none;
  }
  .stop-exclamation-mark {
    display: inline;
  }
  .stop-exclamation-mark-small {
    display: none;
  }
  .stop-label {
    top: 34px;
  }
}
</style>
