import SPARTENTYP_EINSTELLUNG_TYPES from './types';
import CORE_TYPES from '../core/types';
import axios from 'axios';
import { buildMessage, } from '../../helpers/log-message-helper';
import * as utils from '../../helpers/commonfunctions';

const SERVICE_PATH = '/spartentypenService';

const axiosConfig = {
  defaultSpinner: true
};

export default {
  [SPARTENTYP_EINSTELLUNG_TYPES.ACTIONS.LOAD_SPARTEN_TYPEN]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/spartentypenService/loadSpartentyp';
  
      const spartenArt = payload?.spartenArt;
      if (spartenArt) {
        serviceUrl = `${serviceUrl}?spartenArt=${spartenArt}`;
      }

      axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
        if (response && response.data) {
  
          commit(SPARTENTYP_EINSTELLUNG_TYPES.MUTATIONS.LOAD_SPARTENTYP_SUCCESS, response.data);
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });



  },

  [SPARTENTYP_EINSTELLUNG_TYPES.ACTIONS.LOAD_SPARTENTYP_LIST]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/spartentypenService/loadSpartentypList';
  
      axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
        if (response && response.data) {
  
          commit(SPARTENTYP_EINSTELLUNG_TYPES.MUTATIONS.LOAD_SPARTENTYP_LIST_SUCCESS, response.data);
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });



  },

  async [SPARTENTYP_EINSTELLUNG_TYPES.ACTIONS.SAVE_CONFIG_DEFINITION]({ getters, commit, }) {
    const edited = getters[SPARTENTYP_EINSTELLUNG_TYPES.GETTERS.CONFIG_DEFINITION_EDITED];
    if(!edited /* || !edited?.name */) return;

    commit(SPARTENTYP_EINSTELLUNG_TYPES.MUTATIONS.RESET_CONFIG_DEFINITION_EDITED);

    const payload = {
      ...edited,
    };

    const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/saveOrUpdate`, payload, axiosConfig);
    commit(SPARTENTYP_EINSTELLUNG_TYPES.MUTATIONS.GET_CONFIG_DEFINITION_SUCCESS, response?.data?.[0] || null);
  },  

  async [SPARTENTYP_EINSTELLUNG_TYPES.ACTIONS.REMOVE_SPARTENTYP]({ commit, getters }, payload) {
    let endpoint = '/delete';

    const id = payload?.id;

    if (id) {
      endpoint = `${endpoint}?id=${id}`;
    }

    const spartenArt = payload?.spartenArt;

    if (spartenArt) {
      endpoint = `${endpoint}&spartenArt=${spartenArt}`;
    }      

    const response = await axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}${endpoint}`, axiosConfig);
    commit(SPARTENTYP_EINSTELLUNG_TYPES.MUTATIONS.REMOVE_SPARTENTYP_SUCCESS, response?.data?.[0] || null);


  },


  async [SPARTENTYP_EINSTELLUNG_TYPES.ACTIONS.CHANGE_COMPONENT_POSITION]({ getters, commit, }, payload) {
    let serviceUrl = '/changeComponentPosition';
  
    const spartenArt = payload?.spartenArt;
    if (spartenArt) {
      serviceUrl = `${serviceUrl}?spartenArt=${spartenArt}`;
    }

    await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}${serviceUrl}`, payload?.changedComponents, axiosConfig);
  },  


  async [SPARTENTYP_EINSTELLUNG_TYPES.ACTIONS.CHANGE_STEP_POSITION]({ getters, commit, }, payload) {
    let serviceUrl = '/changeStepPosition';
  
    const spartenArt = payload?.spartenArt;
    if (spartenArt) {
      serviceUrl = `${serviceUrl}?spartenArt=${spartenArt}`;
    }

    await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}${serviceUrl}`, payload?.steps, axiosConfig);
  },  


  async [SPARTENTYP_EINSTELLUNG_TYPES.ACTIONS.RENAME_GROUP]({ getters, commit, }, payload) {
    let serviceUrl = '/renameGroup';
  
    const spartenArt = payload?.spartenArt;
    if (spartenArt) {
      serviceUrl = `${serviceUrl}?spartenArt=${spartenArt}`;
    }

    const oldGroupName = payload?.oldGroupName;
    if (oldGroupName) {
      serviceUrl = `${serviceUrl}&oldGroupName=${oldGroupName}`;
    }

    const newGroupName = payload?.newGroupName;
    if (newGroupName) {
      serviceUrl = `${serviceUrl}&newGroupName=${newGroupName}`;
    }

    await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}${serviceUrl}`, axiosConfig);
  },  
  
  

  

}