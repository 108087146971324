export const MUTATION_PREFIX = 'INFOFILME_MUTATIONS_';
export const ACTIONS_PREFIX = 'INFOFILME_ACTIONS_';
export const GETTERS_PREFIX = 'INFOFILME_GETTERS_';

export default {
  MUTATIONS: {
    SET_INFOFILME: MUTATION_PREFIX + 'SET_INFOFILME',
    SET_FILTER: MUTATION_PREFIX + 'SET_FILTER',
  },
  ACTIONS: {
    LOAD_INFOFILME: ACTIONS_PREFIX + 'LOAD_INFOFILME',
    UPDATE_INFOFILME: ACTIONS_PREFIX + 'UPDATE_INFOFILME',
  },
  GETTERS: {
    GET_INFOFILME: GETTERS_PREFIX + 'GET_INFOFILME',
    GET_FILTER: MUTATION_PREFIX + 'GET_FILTER',
  }
}