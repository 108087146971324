<template>
    <div>
        <BaseModal
            ref="initModal"
            modalTitle="Wählen Sie eine Lagerstelle"
            labelButtonConfirm = "Bestätigen"
            :confirmDisabled="!lagerstelleSelectorConfirmAllowed"
            :showCancelButton="false"
            :showCloseButton="false"
            :showConfirmButton="isSelectedDepotnumberAllowed"
            @onConfirmButton="loadData"
            @onCloseButton="$router.go(-1);"
            >

            <div v-if="lagerstellen && lagerstellen.length === 0">
                Sie müssen eine Kundennummer erstellen.
            </div>
            <div v-else>
                <ComboBox
                    label="Lagerstelle"
                    v-model="lagerstelleSelect"
                    :values="lagerstellenSorted"
                    :disabled="isLagerstelleDisabled"
                    @change="lagestelleChanged()"
                />

                <ComboBox
                    label="Depotnummer"
                    v-model="depotSelect"
                    :values="getDepotNumbers"
                    validateUntouched
                />

                <InputField
                    v-if="depotSelect === FREIE_EINGABE"
                    v-model="kontoFreeText"
                    @input="kontoFreeTextChanged()"
                />

                <div v-if="!isSelectedDepotnumberAllowed" class="color-danger" v-html="selectedDepotNumber.warning"></div>
                
                <div v-if="lagerstelleSelectorConfirmAllowed === false" class="color-danger">Depotnummer ist ungültig!</div>

                <div v-if="predefinedFundsList">
                    Es werden nur bei ausgewählter Lagerstelle beziehbare Wertpapiere berücksichtigt.
                </div>
            </div>

            <template v-slot:footer>
                <BaseBackButtonBreadcrumb 
                    class="mr-16px"
                    showAsSecondaryButton 
                    fixedLabel="Abbrechen"/>
            </template>
        </BaseModal>
    </div>
</template>

<script>
import ComboBox from '@/components/core/forms/ComboBox.vue'
import InputField from '@/components/core/forms/InputField.vue'
import BaseBackButtonBreadcrumb from '@/components/core/BaseBackButtonBreadcrumb.vue'
import BaseModal from "@/components/core/BaseModal.vue";
import WERTPAPIERORDER_TYPES from "@/store/wertpapierorder/types";
import TAN_TYPES from "@/store/tan/types";
import validator from "@/mixins/validator";
import { required } from "@/mixins/validator/rules";
import mixin from "@/mixins/wertpapierorder/mixin.js";
import { mapGetters } from "vuex";
import LOG_TYPES from '@/store/log/types'
import { buildMessage } from '../../helpers/log-message-helper';

export default {
    mixins: [mixin, validator],
    validators: { 
        depotSelect: [required('Bitte wählen Sie eine Depotnummer!')],
    },
    components: {
        BaseModal,
        ComboBox,
        InputField,
        BaseBackButtonBreadcrumb,
    },
    data() {
        return {
            lagerstelleSelectorConfirmAllowed: null,
            depotNr: '',
            FREIE_EINGABE: 'freie Eingabe:',
            lagerstelleSelect: '',
            depotSelect: '',
            kontoFreeText: '',
            tanRequired: false,
            tanURL: '',
        };
    },
    watch: {
        depotSelect: {
            handler() {
                if (this.depotSelect) {
                    this.kontoFreeText = '';
                    this.validateDepotnummer();
                    this.updateStoreDataValues(({ depotSelect: this.depotSelect }));
                }
            },
            immediate:true
        },
        lagerstelleSelect: {
            handler() {
                if (this.lagerstelleSelect) {
                    this.$nextTick().then(() => {
                        this.depotSelect = this.getDepotNumbers?.find(depotNr => depotNr?.value !== this.FREIE_EINGABE)?.value || this.FREIE_EINGABE;
                        this.updateStoreDataValues(({ lagerstelleSelect: this.lagerstelleSelect }));
                    });
                }
            },
            immediate:true
        },
    },
    computed: {
        ...mapGetters({
            lagerstellen: WERTPAPIERORDER_TYPES.GETTERS.LAGERSTELLEN || [],
            depotNumbers: WERTPAPIERORDER_TYPES.GETTERS.DEPOT_NUMBERS || [],
        }),
        isOpened() {
            return this.$refs.initModal?.$data?.isOpen;
        },
        kontoForValidating() {
            return this.depotSelect === this.FREIE_EINGABE || this.depotSelect === ''? this.kontoFreeText : this.depotSelect
        },
        lagerstellenSorted() {
            return this.lagerstellen?.sort((a,b) => a?.label?.localeCompare(b?.label));
        },
        getDepotNumbers() {
            if (this.lagerstelleSelect) {
                const depotNumbers = this.depotNumbers?.[this.lagerstelleSelect] || [{label: this.FREIE_EINGABE, value: this.FREIE_EINGABE}];
                return depotNumbers;
            } else {
                return [];
            }
        },
        isLagerstelleDisabled() {
            return !!this.$route.query.lagerstelleFixed;
            // return this.lagerstelleFixed || !!(this.bgsNr && this.$route.query.category) ? true : false;
        },
        predefinedFundsList() {
          return this.$route.query?.kauf != undefined;
        },
        selectedDepotNumber() {
            return this.getDepotNumbers.find(depotnumber => depotnumber.value === this.kontoForValidating)
        },
        isSelectedDepotnumberAllowed() {
          return !this.selectedDepotNumber?.denied;
        },
    },
    methods: {
        async open() {
            this.$refs.initModal?.open();

            if (this.lagerstelleFixed == 'COMDIRECT') {
                await this.$store.dispatch(WERTPAPIERORDER_TYPES.ACTIONS.CHECK_COMDIRECT_LOGIN, this.lagerstelleFixed).catch( error => {
                    if (error?.response?.data?.message == 'Login am comdirect System notwendig'){
                        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Es wird ein Browsertab zur Anmeldung am comdirect System geöffnet', 'danger'));
                        setTimeout(() => this.$store.dispatch(TAN_TYPES.ACTIONS.ACTIVATE_COMDIRECT_TAN), 2500)
                    }
                    throw error;
                });
            }
            this.$store.dispatch(WERTPAPIERORDER_TYPES.ACTIONS.GET_DEPOTS, this.lagerstelleFixed);
            
            await this.$store.dispatch(WERTPAPIERORDER_TYPES.ACTIONS.GET_LAGERSTELLEN, this.lagerstelleFixed);
            this.$nextTick().then(() => {
                if (this.lagerstelleFixed) {
                    this.lagerstelleSelect = this.lagerstelleFixed;
                } else if (this.lagerstellenSorted?.length) {
                    this.lagerstelleSelect = this.lagerstellenSorted[0].value;
                }
            });
        },
        async validateDepotnummer() {
            if (this.lagerstelleSelect) {
                const isValid = await this.$store.dispatch(WERTPAPIERORDER_TYPES.ACTIONS.CHECK_DEPOT_NUMMER, { lagerstelle: this.lagerstelleSelect, depotNr: this.kontoForValidating })
                this.lagerstelleSelectorConfirmAllowed = isValid
            }
        },
        kontoFreeTextChanged() {
            this.validateDepotnummer();
        },
        lagestelleChanged() {
            this.kontoFreeText = '';
        },
        loadData() {
            if (!this.$data.tanRequired && this.lagerstelleSelect && this.kontoForValidating) {
                this.loadWertpapierorder({ lagerstelle: this.lagerstelleSelect, depot: this.kontoForValidating});
            } 
        }
    },
}
</script>