<template>
  <div class="origin-of-funds-component">
    <div class="origin-of-funds-component-input-container" v-for="component in componentFields" :key="component.id">
      <InputCheckBoxItem v-if="component.type === 'SWITCHER'" class="origin-of-funds-component-input-switcher-container" 
        :label="component.label" :value="internalValues[component.id]" @input="handleInputChange($event, component)" 
        :disabled="disabled[component.id]" />

      <InputField v-if="component.type === 'TEXT_CURRENCY'" class="origin-of-funds-component-input-currency-container" 
        type="currency" :value="internalValues[component.id]" @change="handleInputChange($event, component)" :precision="precision" 
        :disabled="disabled[component.id]" isComponentHalfSize />
    </div>
  </div>
</template>

<script>
import InputCheckBoxItem from  '@/components/core/forms/checkbox/InputCheckBoxItem.vue';
import InputField from '@/components/core/forms/InputField.vue';

import { formatNumber, parse } from '@/helpers/number-formatter.js';

const PRECISION = 2;

export default {
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
    values: {
      type: Object,
      default: {},
    },
    disabled: {
      type: Object,
      default: {},
    }
  },
  emits: ['change'],
  data() {
    return {
      precision: PRECISION,
    };
  },
  computed: {
    componentFields() {
      return this.config && this.config.componentFields || [];
    },
    componentFieldsMapIdToType() {
      return this.componentFields.reduce((result, component) => {
        result[component.id] = component.type;
        return result;
      }, {});
    },
    internalValues() {
      return this.values && Object.keys(this.values).reduce((result, componentId) => {
        const value = this.values[componentId];        
        result[componentId] = this.isTextCurrency(this.componentFieldsMapIdToType[componentId]) ? parse(value, PRECISION) : value;
        return result;
      }, {}) || {};
    },
  },
  methods: {
    handleInputChange(value, component) {
      this.$emit('change', {
        [component.id]: this.isTextCurrency(component.type) ? formatNumber(value, PRECISION) : value,
      });
    },
    isTextCurrency(componentType) {
      return componentType === 'TEXT_CURRENCY';
    },
  },
  components: {
    InputCheckBoxItem,
    InputField,
  },
}
</script>

<style scoped>
.origin-of-funds-component-input-switcher-container {
  margin: 0 0 0.4rem;
  line-height: 1em;
}

.origin-of-funds-component-input-currency-container {
  margin: 0 0 0.4rem;
  padding-top: 0;
}

.origin-of-funds-component-input-container:last-child .origin-of-funds-component-input-currency-container {
  margin-bottom: 0;
}
</style>
