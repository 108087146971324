<template>
  <div class="label lzinsen">
    <div class="result">{{getZins}} %</div>
    <div class="truelabel">Zinsen</div>
  </div>
</template>

<script>
//import store from "./../../store.js";
export default {
  name: "LZinsen",
  computed: {
    getZins() {
      return this.$store.getters.getZins;
    }
  },
};
</script>

<style scoped>
.lzinsen {
  grid-area: lzinsen;
}
</style>