<template>
  <div>
    <div>
      <div class="text-small edit" v-if="isBroker || isIntern" @click="edit">bearbeiten 
        <ph-pencil-line title="Bearbeiten" :size="16" />
      </div>
      <div class="innerContent">
        <span v-html="sanitize(getComputedInhalt)" class="computed_inhalt" @click="handleClicks"></span>
      </div>
    </div>
    <BaseModal 
      ref="EditableFrameModal"
      modalTitle="Inhalt bearbeiten"
      @onConfirmButton="onConfirmModal()"
      @onCancelButton="onCancelModal()">
      <div class="markup_class">
        <HtmlEditor v-model="updatedHtml"/>
      </div>
    </BaseModal>
  </div>
</template>
<script>
import { buildMessage } from "@/helpers/log-message-helper";
import CORE_TYPES from "@/store/core/types";
import CMS_TYPES from "@/store/cms/types";
import LOG_TYPES from '@/store/log/types';
import { mapGetters } from "vuex";
import BaseModal from "@/components/core/BaseModal.vue";
import { PhPencilLine } from 'phosphor-vue';
import {sanitize} from '@/helpers/string-helper.js';
import HtmlEditor from "@/components/html-editor/HtmlEditor.vue";

export default {
  props: {
    type:{
      type:String,
      default:"",
      required:true,
    },
    genus:{
      type:String,
      default:null,
      required:false,
    },
    species:{
      type:String,
      default:null,
      required:false,
    },
  },
  components: {
    BaseModal,
    HtmlEditor,
    PhPencilLine,
  },
  data() {
    return {
      updatedHtml: null
    }
  },
  computed: {
    ...mapGetters({
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      getInhalt: CMS_TYPES.GETTERS.GET_CMS_COMPONENTS,
      getComponentContent: CMS_TYPES.GETTERS.GET_CMS_COMPONENT_CURRENT_CONTENT,
      getComponentContentUpdated:
        CMS_TYPES.GETTERS.GET_CMS_COMPONENT_CONTENT_UPDATED
    }),
    getType() {
      return this.type;
    },
    getGenus() {
      return this.genus;
    },
    getSpecies() {
      return this.species;
    },
    getComputedInhalt() {
      if (!this.getInhalt) {
        return ''
      }
      
      if (this.genus == null && this.species == null) {
        const found = this.getInhalt.find(x => x.type == this.getType)
        if (found) {
          return found.inhalt || ''
        }
      } else {
        const found = this.getInhalt.find(x => x.type == this.getType && x.genus == this.getGenus && x.species == this.getSpecies)
        if (found) {
          return found.inhalt || ''
        }
      }

      return ''
    }
  },
  methods: {
    sanitize(htmlString) {
      return sanitize(htmlString);
    },
    getUpdatedHtml(event) {
      this.updatedHtml = event
    },
    handleClicks(event) {
      let { target } = event;
      while (target && target.tagName !== "A") target = target.parentNode;
      if (
        target &&
        target.matches(".computed_inhalt a:not([href*='://'])") &&
        target.href
      ) {
        const {
          altKey,
          ctrlKey,
          metaKey,
          shiftKey,
          button,
          defaultPrevented
        } = event;
        if (metaKey || altKey || ctrlKey || shiftKey) return;
        if (defaultPrevented) return;
        if (button !== undefined && button !== 0) return;
        let windowUrl=window.location.pathname;
        let targetUrl = new URL(target.href).pathname;
        if (windowUrl === targetUrl) event.preventDefault();
        if (target && target.getAttribute) {
          const linkTarget = target.getAttribute("target");
          if (/\b_blank\b/i.test(linkTarget)) return;
        }
        const url = new URL(target.href);
        const to = url.pathname;
        const realto = url.pathname.split("/").pop();
        if (window.location.pathname !== to && event.preventDefault) {
          event.preventDefault();
          this.$router.push(realto);
        }
      }

    },

    edit: function() {
      this.updatedHtml = this.getComputedInhalt
      this.$refs.EditableFrameModal.open();
    },
    onConfirmModal: function() {
      var myself = this;

      if (!this.updatedHtml) {
        this.$store.dispatch(
          LOG_TYPES.ACTIONS.ADD_MESSAGE,
          buildMessage("No change was made.")
        );
      } else {

        if (myself.getComponentContent.length > 20000000) {
          alert(
            "The content is too large. Perhaps you have inserted too many pictures or pictures of large size."
          );
        } else {
          this.$store
            .dispatch(CMS_TYPES.ACTIONS.POST_CMS_COMPONENT_CONTENT, {
              type: myself.getType,
              inhalt: this.updatedHtml,
              genus: myself.getGenus,
              species: myself.getSpecies
            })
            .then(this.onCancelModal());
        }
      }
    },
    onCancelModal: function() {
    }
  }
};
</script>
<style scoped>

.edit {
  color: var(--color-danger);
  /* font-size:1.5em; */
  cursor: pointer;
}
.buttons_confirm_cancel {
  display: flex;
  justify-content: space-between;
}
</style>