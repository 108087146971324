import DATENSCHUTZKUNDENAENDERUNGEN_TYPES from './types';
import axios from 'axios';

const config = {
  defaultSpinner: true
};

export default {
  [DATENSCHUTZKUNDENAENDERUNGEN_TYPES.ACTIONS.GET_MAIN_LISTING]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/datenschutzkundenaenderungen/getMainListing';
     
  
      axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
        if (response && response.data) {
  
          commit(DATENSCHUTZKUNDENAENDERUNGEN_TYPES.MUTATIONS.GET_MAIN_LISTING_SUCCESS, response.data);
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });



  },

  [DATENSCHUTZKUNDENAENDERUNGEN_TYPES.ACTIONS.GET_DETAILS_LISTING]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/datenschutzkundenaenderungen/getDetailsListing';

      if (!payload?.kslId) {
        return;
      }

      serviceUrl=`${serviceUrl}?kslId=${payload?.kslId}`;
     
  
      axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
        if (response && response.data) {
  
          commit(DATENSCHUTZKUNDENAENDERUNGEN_TYPES.MUTATIONS.GET_DETAILS_LISTING_SUCCESS, response.data);
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });
  }, 

  async [DATENSCHUTZKUNDENAENDERUNGEN_TYPES.ACTIONS.MAKE_ENTRY_MANUELL]({ rootState }, payload) {
      await axios.post(rootState.core.apiAddress + '/datenschutzkundenaenderungen/makeEntryManuell', payload, config);
  },   


  

  

}