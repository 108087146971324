import AUFTRAEGE_TYPES from './types';
import Vue from 'vue';

export default {
  [AUFTRAEGE_TYPES.MUTATIONS.GET_AUFTRAEGE_SUCCESS](state, payload) {
    Vue.set(state, 'auftraege', Object.assign([], payload?.auftraege));
    Vue.set(state, 'istBearbeiter', payload?.istBearbeiter);
    Vue.set(state, 'hasEstimatedDone', payload?.hasEstimatedDone);
  },

  [AUFTRAEGE_TYPES.MUTATIONS.SET_DEFAULT_FILTERS](state, payload) {
    Vue.set(state, 'defaultFilters', payload);
  },

  [AUFTRAEGE_TYPES.MUTATIONS.REMOVE_AUFTRAG_SUCCESS](state, id) {
    if (id) {
      const index = state.auftraege?.findIndex(auftrag => auftrag.id === (id + ''));
      if (index >= 0) {
        state.auftraege.splice(index, 1);
      }
    }
  },


  [AUFTRAEGE_TYPES.MUTATIONS.GET_AENDERUNGS_LOG_SUCCESS](state, payload) {
    Vue.set(state, 'aenderungsLog', payload);
  },
  
  
  [AUFTRAEGE_TYPES.MUTATIONS.UPDATE_AUFTRAG_SUCCESS](state, payload) {
    if (payload?.id) {
      const auftrag = state.auftraege?.find(auftrag => auftrag.id === payload.id);
      if (auftrag) {
        if (payload.pruefstatus) {
          auftrag.pruefstatus = payload.pruefstatus
        } else if (Object.keys(payload).some(key => key === 'kd')) {
          auftrag.kd = payload.kd;
        }
      }
    }
  },
  
  [AUFTRAEGE_TYPES.MUTATIONS.GET_EMAILS_SENT_SUCCESS](state, payload) {
    if(!payload) {
      Vue.set(state, 'emailsSent', []);
    } else {
      Vue.set(state, 'emailsSent', payload);
    }
  },


  [AUFTRAEGE_TYPES.MUTATIONS.IST_BEARBEITER_SUCCESS](state, payload) {
    Vue.set(state, 'istBearbeiter', payload);
  },
  
  
}
