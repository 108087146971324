<template>
  <div class="box__container">
    <BaseCollapsable :showSection="showExpanded">
      <template v-slot:title>
        <span class="box__title">Hinweise / Warnungen </span>
        <span v-if="hasHinweis">&nbsp;|&nbsp; ({{hinweise.length}} Hinweis{{hinweise.length > 1 ? 'e' : ''}})</span>
        <span v-if="hasWarnings" class="color-danger">&nbsp;|&nbsp; Das Formular enthält Fehler</span>
      </template>
      <template v-slot:content>
        <div v-if="!hasHinweis && !hasWarnings">
          Keine Hinweise vorhanden.
        </div>
        <div v-else-if="hasWarnings">
          <div class="row">
            <div class="col-12">
              <span class="font-bold">Fehler:</span>
            </div>
          </div>
          <div class="row" v-for="(warning, index) of warnings" :key="index">
            <div class="col-12" 
                :class="{clickable: isWarningNavigable(warning)}"
                @click="navigateToStep(warning.stepKey)">
              {{ getDescription(warning) }}
              <PhLink :size="20"/>
            </div>
          </div>
        </div>
        <div v-else-if="hasHinweis">
          <div class="row">
            <div class="col-12">
              <span class="font-bold">Hinweise:</span>
            </div>
          </div>
          <div class="row" v-for="(hinweis, index) of hinweise" :key="index">
            <div class="col-12" v-html="hinweisHtml(hinweis)">
            </div>
          </div>
        </div>
      </template>
    </BaseCollapsable>
  </div>
</template>
<script>
import {PhLink} from 'phosphor-vue'
import {sanitize} from '@/helpers/string-helper.js';

import BaseCollapsable from '@/components/core/BaseCollapsable.vue'


export default {  
  props: {
    warnings: {},
    hinweise: {},
    showExpanded: {},
  },
  components: {
    PhLink,
    BaseCollapsable,
  },
  computed: {
    hasWarnings() {
      return this.warnings && this.warnings.length
    },
    hasHinweis() {
      return this.hinweise && this.hinweise.length
    },
    
  },
  methods: {    
    hinweisHtml(hinweis) {
      if (hinweis && hinweis.text) {
        return sanitize("<div><b>" + hinweis.label + "</b></div>" + hinweis.text);
      }
      else return '';
    },
    isSimpleWarning(warning) {
      return typeof warning === 'string';
    },
    getDescription(warning) {
      if (warning) {
        return this.isSimpleWarning(warning) ? warning : this.getWarnMessage(warning.title, warning.message);
      }
    },
    getWarnTitle(title) {
      return (title ? `Schritt ${title}: ` : '')
    },
    getWarnMessage(title, message) {
      return (this.getWarnTitle(title) + (message || ''))
    },
    isWarningNavigable(warning) {
      return warning && warning.stepKey
    },
    navigateToStep(stepKey) {
        if (stepKey) {
         this.$emit('navigate_to', stepKey);
        }  
    },
  }
}
</script>

<style scoped>
</style>