var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: "Persönliches Geschäft", actions: _vm.headerActions },
        on: {
          "action-ADD": function($event) {
            return _vm.openFragebogen({ id: "neu" })
          }
        }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("div", { staticClass: "box__title" }, [
            _vm._v("Gespeicherte Fragenbogen")
          ]),
          _vm.fragebogenList.length
            ? _c(
                "div",
                [
                  _c("Table", {
                    attrs: {
                      rowId: "depotnr + lagerstelle + jahr",
                      headers: _vm.headers,
                      rows: _vm.fragebogenList,
                      rowsPerPage: 20
                    },
                    on: {
                      "click-depotnr": _vm.openFragebogen,
                      "action-OPEN": _vm.openFragebogen,
                      "action-DELETE": _vm.removeFragebogen
                    }
                  })
                ],
                1
              )
            : _c("NoData")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }