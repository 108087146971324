var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "box__title" }, [_vm._v("Abrechnungen")]),
      !_vm.loading && _vm.tableData.records.length
        ? _c("Table", {
            attrs: {
              tableId: "efc905f7-29e0-4fce-834b-b05a46a33983",
              tableData: _vm.tableData,
              actions: _vm.tableActions,
              cardViewEnabled: false,
              filterEnabled: false,
              exportEnabled: false
            },
            on: {
              "execute-action": function($event) {
                return _vm.handleTableAction($event)
              }
            }
          })
        : _vm.loading
        ? _c(
            "div",
            { staticClass: "text-centered" },
            [_c("AnimatedSpinner")],
            1
          )
        : _c("div", [_vm._v("Keine Daten")])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }