<template>
  <div class="formulare-list__container">
    <template v-if="hasHeader">
      <PageHeaderTitleNavigation :title="$route.query.lagerstelle" />
    </template>


    <div class="box__container" :tid="_generateTidFromString('box__container')">
      <div class="box__title">{{$route.query.lagerstelle}}</div>
      
      <InputField 
        isComponentHalfSize
        placeholder="Suchen nach Bezeichnung"
        v-model="descriptionSearch"/>
    </div>
      
    <div class="cards__container layout__negative-margin--8" v-if="filteredLinks && filteredLinks.length">

      <div class="box__container cards__item" :tid="_generateTidFromString('box__container cards__item' + linkGroup.groupLabel)" v-for="linkGroup in filteredLinks" :key="linkGroup.groupLabel">

        <div class="box__title">{{linkGroup.groupLabel}}</div>

        <div v-for="linkItem in linkGroup.forms"  :key="linkItem.link">
          <a target="_blank" :href="linkItem.url">{{linkItem.label}}</a>

        </div>

      </div>

    </div>    
    <GhostLoading v-else-if="loading" useBoxContainer type="block" :config="{ block: { height: 280, }, }"/>
    <div v-else class="box__container">
      <NoData content="Keine Formulare vorhanden" />
    </div>
    


  </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import { mapGetters } from "vuex";
import ANTRAG_TYPES from '@/store/antrag/types';
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import InputField from '@/components/core/forms/InputField.vue'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';

export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    InputField,
    GhostLoading,
    NoData,
  },
  props: {
    hasHeader: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      formLinks: [],
      descriptionSearch: '',
      loading: false,
    }
  },
  watch: {
    gesellschaftsFormulareLinks: {
      handler(newValue) {
        this.formLinks = newValue || [];
      },
      immediate: true,
    },      
  },
  computed: {
    ...mapGetters({
      gesellschaftsFormulareLinks: ANTRAG_TYPES.GETTERS.GESELLSCHAFTS_FORMULARE_LINKS,
    }),
    filteredLinks() {

        let self = this;

        let cloneArray = this.formLinks && this.formLinks.length && JSON.parse(JSON.stringify(this.formLinks)) || [];

        let filteredData = cloneArray.map(function(el){
            el.forms = el.forms.filter(function(f){ return f.label.search(new RegExp(self.descriptionSearch, "i")) > -1; });
            return el;
        }).filter(p => p.forms.length > 0);

        return filteredData;

    },
  },
  mounted() {
    this.formLinks = [];
    const gesellschaftId = this.$route.query.gesellschaftId || this.$route.params?.gesellId;
    this.selectFormsByGesellschaftId(gesellschaftId);
    

  },
  methods: {
    selectFormsByGesellschaftId(gesellschaft) {
      this.formLinks = [];
      this.loading = true

      const payload = {
        gesellschaftId: gesellschaft
      };

      this.$store.dispatch(ANTRAG_TYPES.ACTIONS.RETRIEVE_GESELLSCHAFTS_FORMULARE_LINKS, payload).finally(() => this.loading = false)
    },    
  },
}
</script>

<style scoped>
.formulare-list__item-link {
  color: var(--color-text)
}
.cards__container {
  display: flex;
  flex-wrap: wrap;
}

.cards__item { 
  flex: 1 1 100%;
}

@media (min-width: 576px) {
  .cards__item { 
    flex: 1 1 calc(50% - 16px);
  }
}

@media (min-width: 992px) {  
  .cards__item { 
    flex: 1 1 calc(33.3333% - 16px);
  }
}
</style>
