import INSURANCE_BUCHUNG_TYPES from './types';

export default {
  [INSURANCE_BUCHUNG_TYPES.GETTERS.BUCHUNG_FIELDS_DEFINITIONS](state) {
    return state.buchungFieldsDefinition;
  },
  [INSURANCE_BUCHUNG_TYPES.GETTERS.BUCHUNG](state) {
    return state.buchungversicherung;
  },
  [INSURANCE_BUCHUNG_TYPES.GETTERS.INSURANCE_BUCHUNG_VERTEILUNG_STRUKTUR](state) {
    return state.verteilungStruktur;
  },
  [INSURANCE_BUCHUNG_TYPES.GETTERS.SELECTED_INSURANCE_BUCHUNG](state) {
    return state.selectedBuchungDetail;
  },
}