import QUARTALSBERICHTE_BESTELLUNGEN_TYPES from './types';
import { getInitialState } from './index'

export default {
  [QUARTALSBERICHTE_BESTELLUNGEN_TYPES.MUTATIONS.RETRIEVE_BESTELLUNGEN_DATA_SUCCESS](state, payload) {
    state.bestellungenData = payload;
  }, 
  [QUARTALSBERICHTE_BESTELLUNGEN_TYPES.MUTATIONS.RESET_STATE](state, payload) {
    Object.assign(state, getInitialState())
  }, 
  [QUARTALSBERICHTE_BESTELLUNGEN_TYPES.MUTATIONS.EXECUTE_ORDERS_SUCCESS](state, payload) {
    state.executeOrdersResult = payload;
  },
 
  
}