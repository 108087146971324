import FEE_TYPES from './types';
import { getInitialState } from './index';

import Vue from 'vue';

export default {
  [FEE_TYPES.MUTATIONS.SET_FEE_PROPERTIES_COMBOS](state, payload) {
    Vue.set(state, 'feePropertiesCombos', { ...payload || {}, });
  },

  [FEE_TYPES.MUTATIONS.SET_FEE_PROPERTIES](state, payload) {
    Vue.set(state, 'feeProperties', { ...payload || {}, });
  },

  [FEE_TYPES.MUTATIONS.SET_FEHLERHAFT](state, payload) {
    Vue.set(state, 'fehlerhaft', payload);
  },  

  [FEE_TYPES.MUTATIONS.SET_FEE_PROPERTIES_EDITED](state, payload) {
    Vue.set(state, 'feePropertiesEdited', { ...payload || {}, });
  },

  [FEE_TYPES.MUTATIONS.RESET_FEE_PROPERTIES_EDITED](state) {
    Vue.set(state, 'feePropertiesEdited', null);
  },

  [FEE_TYPES.MUTATIONS.SET_ABRECHNUNGEN](state, payload) {
    Vue.set(state, 'abrechnungen', [ ...payload || [], ]);
  },

  [FEE_TYPES.MUTATIONS.SET_KUNDE_HONORARE](state, payload) {
    Vue.set(state, 'kundeHonorare', [ ...payload || [], ]);
  },

  [FEE_TYPES.MUTATIONS.SET_CALC_KUNDE_HONORARE_DATA](state, payload) {
    Vue.set(state, 'calcKundeHonorareData', { ...payload || {}, });
  },

  [FEE_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState());
  },

}