<template>
  <BaseButton isClear @click="onclick">
    <PhArrowsLeftRight :size="size" />
  </BaseButton>
</template>

<script>
import { PhArrowsLeftRight } from 'phosphor-vue'
import BaseButton from '@/components/core/BaseButton.vue';

export default {
  props: {
    size: {
      default: 18
    }
  },
  computed: {
  },
  methods: {
    onclick() {
      this.$router.push({name: 'sso'});
    },
  },
  components: {
    PhArrowsLeftRight,
    BaseButton,
  }
}
</script>

<style scoped>

</style>