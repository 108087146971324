var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "expanded-event-view__container",
      on: {
        mouseenter: function($event) {
          return _vm.show($event)
        },
        mouseleave: function($event) {
          return _vm.hide()
        }
      }
    },
    [
      _vm._t("default"),
      _c(
        "div",
        {
          staticClass: "expanded-view",
          class: { "is-multiple-days": _vm.isMultipleDays },
          attrs: { "data-expanded-event-view": "" }
        },
        [
          _c("div", { staticClass: "expanded-view--wrap" }, [
            _c(
              "div",
              {
                staticClass: "expanded-view--title",
                style: { "background-color": _vm.event.lighterColor }
              },
              [
                _c("div", {
                  staticClass: "event-cell-before",
                  style: { "background-color": _vm.event.color }
                }),
                _c("div", [_c("span", [_vm._v(_vm._s(_vm.event.label))])])
              ]
            ),
            _vm.event.bereichDescription
              ? _c(
                  "div",
                  {
                    staticClass:
                      "expanded-view--info expanded-view--desc text-small"
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "expanded-view--info--icon" },
                      [_c("PhTextAlignLeft", { attrs: { size: "20" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", [_vm._v(_vm._s(_vm.event.bereichDescription))])
                  ]
                )
              : _vm._e(),
            _vm.event.place
              ? _c("div", { staticClass: "expanded-view--info text-small" }, [
                  _c(
                    "span",
                    { staticClass: "expanded-view--info--icon" },
                    [_c("PhMapPinLine", { attrs: { size: "20" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(_vm.sanitize(_vm.event.place))
                    }
                  })
                ])
              : _vm._e(),
            _c(
              "div",
              {
                staticClass:
                  "expanded-view--info expanded-view--time text-small"
              },
              [
                _c(
                  "span",
                  { staticClass: "expanded-view--info--icon" },
                  [_c("PhClock", { attrs: { size: "20" } })],
                  1
                ),
                _vm._v(" "),
                _c("div", [_vm._v(_vm._s(_vm.dateTimeText))])
              ]
            ),
            _vm.event.repetition
              ? _c(
                  "div",
                  {
                    staticClass:
                      "expanded-view--info expanded-view--repetition text-small"
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "expanded-view--info--icon" },
                      [_c("PhArrowsClockwise", { attrs: { size: "20" } })],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _vm.event.repetitionMaxEndDate
                          ? [
                              _vm._v(
                                "bis " +
                                  _vm._s(_vm.event.repetitionMaxEndDate) +
                                  " (" +
                                  _vm._s(_vm.event.repetitionName) +
                                  ")"
                              )
                            ]
                          : [_vm._v(_vm._s(_vm.event.repetitionName))]
                      ],
                      2
                    )
                  ]
                )
              : _vm._e()
          ])
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }