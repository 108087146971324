export const MUTATION_PREFIX = 'SEARCH_CONFIG: ';
export const ACTIONS_PREFIX = 'SEARCH_CONFIG_ACTIONS_';
export const GETTERS_PREFIX = 'SEARCH_CONFIG_GETTERS_';

export default {
  MUTATIONS: {
    SAVE_TABLE_SORT_OPTIONS: MUTATION_PREFIX + 'SAVE_TABLE_SORT_OPTIONS',
    SAVE_FILTER_OPTIONS: MUTATION_PREFIX + 'SAVE_FILTER_OPTIONS',
    DELETE_FILTER: MUTATION_PREFIX + 'DELETE_FILTER',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    SAVE_FILTER_INTERNAL: MUTATION_PREFIX + 'SAVE_FILTER_INTERNAL',
  },
  ACTIONS: {
    SAVE_FILTER_OPTIONS: ACTIONS_PREFIX + 'SAVE_FILTER_OPTIONS',
    SAVE_TABLE_SORT_OPTIONS: ACTIONS_PREFIX + 'SAVE_TABLE_SORT_OPTIONS',
    SAVE_FILTER: ACTIONS_PREFIX + 'SAVE_FILTER',
    SAVE_FILTER_LIST: ACTIONS_PREFIX + 'SAVE_FILTER_LIST',
    SAVE_FILTER_INTERNAL: ACTIONS_PREFIX + 'SAVE_FILTER_INTERNAL',
    LOAD_FILTERS: ACTIONS_PREFIX + 'LOAD_FILTERS',
  },
  GETTERS: {
    TABLE_SORT_OPTIONS: GETTERS_PREFIX + 'TABLE_SORT_OPTIONS',
    FILTER_OPTIONS: GETTERS_PREFIX + 'FILTER_OPTIONS',
    LOADED_FILTERS: GETTERS_PREFIX + 'LOADED_FILTERS',
    GET_FILTER_INTERNAL: GETTERS_PREFIX + 'GET_FILTER_INTERNAL',
  }
}
