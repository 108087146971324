<template>
  <div v-if="finished">
    <TemplateContainer>
      <template v-if="$route.path === '' || $route.path === '/'">
        <template v-if="page == '/fondsshop'">
          <Fondsshop />
        </template>
        <template v-if="page == '/home'">
          <Home />
        </template>
        <template v-if="page == '/home7'">
          <Home7 />
        </template>
      </template>
    </TemplateContainer>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import CORE_TYPES from "../../store/core/types";
import CMS_TYPES from "../../store/cms/types";
import LOG_TYPES from '@/store/log/types';
import TemplateContainer from "@/components/cms/TemplateContainer.vue";
import Fondsshop from "@/views/cms/template5/Fondsshop.vue";
import Home from "@/views/cms/template5/Home.vue";
import Home7 from "@/views/cms/template7/Home.vue";
export default {
  components: {
    Fondsshop,
    Home7,
    Home,
    TemplateContainer,
  }, 
  data() {
    return {
      finished: false,
      brokerIdFromUrl: null,
      page: '/home'
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: CORE_TYPES.GETTERS.IS_LOGGED_IN,
      firstPageRoute: CMS_TYPES.GETTERS.GET_CMS_FIRST_PAGE_ROUTE,
      getComponents: CMS_TYPES.GETTERS.GET_CMS_COMPONENTS,
      getBrokerData: CORE_TYPES.GETTERS.GET_BROKER_INFORMATION
    }),
    isRootUrl() {
      return window.location.pathname === '/' || window.location.pathname === '';
    },
  },
  watch: {
    firstPageRoute(newValue) {
      this.page = newValue;
    },
    getBrokerData() {
      this.$store.dispatch(LOG_TYPES.ACTIONS.LOG, 'getBrokerData');
      this.getUnloggedCmsContent();
    },
    isLoggedIn() {
      this.getLoggedInCmsContent();
    },
  },
  created() {
    this.getUnloggedCmsContent();
    this.getLoggedInCmsContent();
  },

  methods: {
    getUnloggedCmsContent() {
      if (this.getBrokerData && this.getBrokerData.maklernr) {
        this.$store.commit(CMS_TYPES.MUTATIONS.SET_USER_ID, this.getBrokerData.maklernr);
      }
      if (this.getBrokerData && ('hasHomepage' in this.getBrokerData)) {
        if (this.getBrokerData.urlDestination === 'LOGIN' || !this.getBrokerData.hasHomepage) {
          this.goToLogin()
        } else {
          this.goToCMS()
        }        
      }
    },

    goToLogin() {
      this.$router.push({path: '/login', query: this.$route.query}).catch(() => {});
    },

    // actually this is the cms, so stay here, no router.push. Just load the cms content
    goToCMS() {
      if (!this.isLoggedIn) {
        this.$store
          .dispatch(CMS_TYPES.ACTIONS.RETRIEVE_UNLOGGED_CMS_CONTENT, {
            calledUserID: this.getBrokerData.maklernr
          })
          .then(() => {
            this.finished = true;
          });
      } else {
        this.$store.dispatch(CMS_TYPES.ACTIONS.RETRIEVE_CMS_CONTENT)
      }
    },

    getLoggedInCmsContent() {
      if (this.isLoggedIn) {
        if (this.getComponents === undefined) {
          this.$store.dispatch(CMS_TYPES.ACTIONS.RETRIEVE_CMS_CONTENT)
          .then(() => {
            this.finished = true;
          });
        } else {
          this.finished = true;
        }
      }
    }
  }
};
</script>