import CORE_TYPES from '@/store/core/types';
import {resetSession} from './types';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

function getInitialState() {
  return {
    session: resetSession(null, null),
    settings: null,
    filter: {updateMonth: '3'},
  }
}

export default {
  state: {
    ...getInitialState()
  },
  getters: {
    ...getters
  },
  mutations: {
    ...mutations,
    [CORE_TYPES.MUTATIONS.RESET_STATE](state) { // logout
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    ...actions
  }
}