var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mday",
      class: { today: _vm.istoday, not_in_month: !_vm.inmonth }
    },
    [
      _c("div", { staticClass: "innerday" }, [
        _c("div", { staticClass: "title", class: [_vm.istoday && "today"] }, [
          _c(
            "button",
            {
              staticClass: "btn-clear clickable",
              attrs: { type: "button" },
              on: { click: _vm.chooseDay }
            },
            [_vm._v(_vm._s(_vm.displayLabel))]
          )
        ]),
        _c(
          "div",
          { staticClass: "month-events" },
          [
            _vm._l(_vm.getAlldayEventsTrim, function(alldayevent, alldidx) {
              return _c(
                "ExpandedEventView",
                {
                  key: alldidx,
                  attrs: { event: alldayevent, isOneLineContainer: "" }
                },
                [
                  _c(
                    "div",
                    {
                      class: ["allday-events", "event-" + alldayevent.type],
                      style: { "background-color": alldayevent.lighterColor },
                      attrs: { "data-event-view": "" },
                      on: {
                        click: function($event) {
                          return _vm.setSelectedEvent(alldayevent)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "sameline" },
                        [
                          alldayevent.introIcon
                            ? _c(alldayevent.introIcon, { tag: "component" })
                            : alldayevent.startTime !== "00:00" &&
                              alldayevent.startTime !== "23:59"
                            ? _c("span", [
                                _vm._v(
                                  " " + _vm._s(alldayevent.startTime) + " "
                                )
                              ])
                            : _vm._e(),
                          _c("span", { staticClass: "event-label" }, [
                            _vm._v(_vm._s(alldayevent.label))
                          ])
                        ],
                        1
                      )
                    ]
                  )
                ]
              )
            }),
            _vm._l(_vm.getTimedEventsTrim, function(timedevent, timedidx) {
              return _c(
                "ExpandedEventView",
                {
                  key: timedidx + "timedidx",
                  attrs: { event: timedevent, isOneLineContainer: "" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "timed-events",
                      attrs: { "data-event-view": "" },
                      on: {
                        click: function($event) {
                          return _vm.setSelectedEvent(timedevent)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "sameline" }, [
                        _c("div", {
                          staticClass: "dot",
                          style: { background: timedevent.color }
                        }),
                        _c("span", { staticClass: "event-label" }, [
                          _vm._v(_vm._s(timedevent.label))
                        ])
                      ]),
                      timedevent.introIcon
                        ? _c(timedevent.introIcon, { tag: "component" })
                        : timedevent.startTime !== "00:00" &&
                          timedevent.startTime !== "23:59"
                        ? _c("span", [
                            _vm._v(" " + _vm._s(timedevent.startTime) + " ")
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            }),
            _vm.isShowMore
              ? _c("div", { staticClass: "timed-events" }, [
                  _c(
                    "div",
                    {
                      staticClass: "sameline pl-25",
                      on: { click: _vm.chooseDay }
                    },
                    [_vm._v(" " + _vm._s(_vm.moreCount) + " weitere... ")]
                  )
                ])
              : _vm._e()
          ],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }