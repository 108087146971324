var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.showButtonsSelectDeselect
      ? _c("div", { staticClass: "row justify-content-end" }, [
          _c(
            "div",
            { staticClass: "col-auto" },
            [
              _c(
                "BaseButton",
                { attrs: { isSecondary: "" }, on: { click: _vm.unselectAll } },
                [_vm._v("Alle abwählen")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-auto" },
            [
              _c("BaseButton", { on: { click: _vm.selectAll } }, [
                _vm._v("Alle anwählen")
              ])
            ],
            1
          )
        ])
      : _vm._e(),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12" },
        [
          _c("InputCheckBoxGroup", {
            attrs: {
              values: _vm.computedLabelPath,
              value: _vm.computedValue,
              title: _vm.title,
              disabled: _vm.disabled
            },
            on: { checkBoxToggle: _vm.onInput }
          })
        ],
        1
      )
    ]),
    _vm.isValidationConfigured() && !_vm.suppressValidationMessage
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c(
              "div",
              { key: _vm.validation.updated },
              [
                _vm._l(
                  _vm.validation.getErrors(
                    _vm.validationPath,
                    _vm.validateUntouched
                  ),
                  function(error) {
                    return _c(
                      "div",
                      { key: error, staticClass: "fc-form-danger" },
                      [_vm._v(" " + _vm._s(error) + " ")]
                    )
                  }
                ),
                _vm._l(_vm.componentFields, function(component) {
                  return _c(
                    "div",
                    { key: component.id },
                    _vm._l(
                      _vm.validation.getErrors(
                        component.id,
                        _vm.validateUntouched
                      ),
                      function(componentError) {
                        return _c(
                          "div",
                          {
                            key: componentError,
                            staticClass: "fc-form-danger"
                          },
                          [_vm._v(" " + _vm._s(componentError) + " ")]
                        )
                      }
                    ),
                    0
                  )
                })
              ],
              2
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }