var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _c("AppointmentTable", {
        attrs: {
          bereich: "Kredit",
          bereichId: _vm.creditId,
          bereichText: _vm.bereichText,
          appointments: _vm.appointments,
          appointmentCombos: _vm.appointmentCombos,
          loading: _vm.loading
        },
        on: {
          renew: function($event) {
            return _vm.findAppointments()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }