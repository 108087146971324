<template>
<div>
    <h1>Introduction to the table components</h1>
    <h3>Tables</h3>
    <p>We have two table components, "Table" and "PaginatedTable".
        In almost all cases, "Table" should be preferred.
        "PaginatedTable" is only useful in edge-cases where not all data can be loaded in advance.
    </p>

    <p>This is what a table looks like:</p>

    <Table
        :headers="headers"
        :rows="rows"
        hidePagination
    />



    <p>This document will show how to use the table components in detail. Let's start with a simple example:</p>

    <h3>A Simple Table</h3>
    <ComponentViewer path='tables-table?id=table1'/>
    <CodeViewer
        :code='code.table1'
    />

    <p>As you can see in the Html-Code, only three props are set for this basic table.</p>
    <ul>
        <li>title: This header is shown at the top of the table. This prop is optional. If removed, the table would not have a title</li>
        <li>headers: This is an object that configures the Table's columns. Look at the Javascript-Code for details</li>
        <li>rows: This is an array or row-objects. Look at the Javascript-Code for details</li>
    </ul>
    <p>Looking at the Javascript-Code, you can see that the header object contains one array called "center". More about that later.
        This array contains two TextColumns. One with the key "id" and label "ID", the other with key "description" and label "Beschreibung".
    </p>
    <p>As you can see, the TextColumn's label is shown in the table header, at the top of the respective column.
        The key on the other hand indicates which of the row-objects' values is shown in the respective column.
        Since the first column's key is "id" and the second row's value for "id" is 5, the text shown in that cell of the table is "5".
    </p>
    <p>There are other types of Columns, which we will discuss below.
        Most column-types implement sorting for you, which you can test by clicking on the table's headers.
        If you try this, you might notice that the column "ID" is sorted wrong (1 &lt; 12 &lt; 5).
    </p>
    <p>This is because the column is defined as a TextColumn and therefore sorted lexicographically.<br>
        When we have datatypes that shouldn't be treated as text, more fitting column types can be used.<br>
        Information about already implemented column types can be found <a href="#column-types">here</a>
    </p>








    <h1>Table props</h1>

    <h3>headers</h3>
    This is one of the two required props for tables (the other being "rows").
    "headers" requires an object like the following:
    <CodeViewer
        :code='{Javascript: `{
    lockedLeft: [...],
    center: [...],
    lockedRight: [...],
}`}'
    />
    All of the keys "lockedLeft", "center" and "lockedRight" are optional, though at least one of them has to be present.
    In all three of the arrays, columns for the table are configured.
    More information about column types can be found <a href="#column-types">here</a>.

    <h3>rows</h3>
    This is one of the two required props for tables (the other being "headers").
    "rows" has to be an array of objects, one object per row.
    The header-keys are used to read data from these objects, so the objects' keys should be the same as their corresponding columns' keys.

    <h3>rowId</h3>
    Although not required for every table, this prop has to be used in every table where row-data may change while the table is rendered or in cases where two row-objects may be identical.
    "rowId" should be a string that can be used as key to get a unique id from row-objects.
    For example, if our table lists securities papers which can be identified by ISIN, rowId should be the key under which this ISIN can be accessed from row objects.

    <!--By default, this prop is set to "id", so if your rows are uniquely identified by their value for "id", you don't need to explicitly set this prop (subject to change).-->

    <p>If you forget to set this prop, a warning may be logged in the console.</p>

    <CodeViewer
        :code='{Javascript: `rows: [
    ...
    {isin: ...},
    ...
]`, Html: `Table
    ...
    rowId="isin"
    ...
/>`}'
    />

    <h3>title</h3>
    If a "title" is set, it will be displayed above the table as title.
    If both this prop and the "tableId" prop are set, users may rearrange columns and change their visibility.

    <h3>tableId</h3>
    <p>A unique identifier for tables. When both this and the "title" prop are set, users may rearrange columns and change their visibility.
        Changes will be saved and will persist to future logins.
        Of course this persisting also requires that the user be logged in in the first place.</p>

    <p>In the table below, try clicking on the icon next to the title to try rearranging columns.
        You may notice that you can't modify columns that are in the "lockedLeft" or "lockedRight" arrays.
        This is actually the main purpose of having these arrays separate from the "center" array, in which modifications are allowed.</p>
    
    <p>Another thing you may notice is that one column has been hidden by default using the "makeHidden()" method.</p>

    <ComponentViewer path='tables-table?id=table_tableId'/>
    <CodeViewer
        :code='code.table_tableId'
    />

    <h3>rowsPerPage</h3>
    <p>If thousands of rows were rendered in a table, it would be difficult to navigate the page and keep an overview.
    This prop restricts how many rows can be shown at once. If more rows exist, they get grouped in pages:</p>

    <p>By default, this prop is set to "0", which means that all rows are shown on 1 page, no matter how many exist.</p>
    <p>if you set "rowsPerPage", "hidePagination" should be set to "false".</p>

    <ComponentViewer path='tables-table?id=table_rowsPerPage'/>
    <CodeViewer
        :code='code.table_rowsPerPage'
    />

    <h3>hidePagination</h3>
    <p>By default, tables display a line at the bottom, indicating how many rows are displayed.
        If this line superfluous, it can by hidden by setting "hidePagination" to "true".
        You may find that this is often used in this documentation.</p>


    <h3>selected</h3>
    <p>If this prop is set to an array, there will be a column containing checkboxes on the left side of the table.
        With these checkboxes, rows can be selected or deselected. Each of these changes makes the table emit an event called "selected", which contains an array of the selected rows.
        Which checkboxes are selected by default can be controlled by the programmer: Just add all rows that should be selected to the array bound to this prop.
    </p>
    <p>The table's "v-model" is bound to this prop and it's recommended to use "v-model" instead of binding to "selected" directly.</p>
    <p>By default, the header of the checkbox-column also contains a checkbox, through which all rows can be selected / deselected at once.
        This affects the whole table, not just the visible page!
    </p>
    <p>Sometimes we only want to have some rows selectable, while the checkboxes for others should be disabled or hidden.
        To achieve this, you can set row.selectedDisabled or row.selectedHidden to true respectively.
    </p>

    <ComponentViewer path='tables-table?id=table_selected'/>
    <CodeViewer
        :code='code.table_selected'
    />


    <h3>scrollHorizontally</h3>
    <p>The tables' default behaviour when not all columns fit on the screen is to "collapse" columns that aren't "locked".
        Because the columns tend to be roughly ordered from most- to least-important, that's usually the best approach.
        But if, for example, we have a table with too many important columns where rows should be comparable at first glance, this approach doesn't work so well.
    </p>
    <p>For such cases, the prop "scrollHorizontally" is available.
        If set to true, columns will not be collapsed. Instead all columns that aren't locked can be scrolled through horizontally.
    </p>

    <InputToggleSwitch v-model="scrollHorizontally_scroll_horizontally" label="scroll horizontally" inLineLabel/>
    <ComponentViewer path='tables-table?id=table_scroll_horizontally' :scrollHorizontally="scrollHorizontally_scroll_horizontally"/>
    <CodeViewer
        :code='code.table_scroll_horizontally'
    />


    <h3>enableToggleHorizontalScrollingConfig</h3>
    <p>We've seen the "scrollHorizontally" prop toggled by an external toggle-switch above.
        But if we want to allow our users to switch between horizontal scrolling and the normal table behavior, setting this prop to true is the better choice.
        If set to true, the table's context menu (three buttons at the top-right) becomes visible where the user can (de)activate horizontal scrolling.
    </p>

    <ComponentViewer path='tables-table?id=table_toggle_scrolling'/>
    <CodeViewer
        :code='code.table_toggle_scrolling'
    />



    <h3>maxHeaderLines</h3>
    <p>
        Some columns could have very long headers. In such tables we might want to allow multi-line headers.
        Headers can have up to "maxHeaderLines" lines. The default is 1, so normally headers can't be split into multiple lines
    </p>
    <p>The same can be achieved for all cells of a column by calling the function "addCellProps" on the column to set a value for "lineClamp".
        The cells in that column can then take as many lines as the value in "lineClamp". Look at the second column of the following table for an example.
    </p>

    <ComponentViewer path='tables-table?id=table_maxHeaderLines'/>
    <CodeViewer
        :code='code.table_maxHeaderLines'
    />



    <h3>tableRowsPerPage</h3>









    <h1 id="columns">Columns</h1>

    <p>In general, columns can be created with a constructor that has 4 arguments, like so:
    </p>
    <CodeViewer
        :code='{"": "...Column(key, label, minWidth, flexGrow)"}'
    />
    <ul>
        <li>key: the row-elements data will be taken from row[key]</li>
        <li>label: This text will be shown as the header for this column</li>
        <li>minWidth: If there isn't enough screen-space, columns may be shrunk. This value is the minimum width in pixels below which the column won't be compressed artificially. The column may still end up thinner than this value, if all its content fits in a smaller space naturally</li>
        <li>flexGrow: If there is more screen-space than necessary for the table's columns, their width may be increased. With this parameter you can configure how the remaining space is to be split between the columns. The higher this value is in comparison to that of other columns, the more of the free space will be alotted to this column.</li>
    </ul>

    <p>After creating a column, it can by modified with builder-style methods, which will be discussed below.</p>

    <p>Some column types may have additional arguments, which are generally placed between label and minWidth.</p>

    <p>All column types can be found in table_util.js, sorted alphabetically. If necessary you can even add your own there.
        Most useful column types should already be implemented, though.
    </p>

    



    <h2 id="column-types">Column Types</h2>


    <h3>Columns for Numbers</h3>

    <p>For displaying numbers we have the NumberColumn.
        If the number should represent a percentage or a currency value, there are PercentageColumn and CurrencyColumn respectively.
    </p>
    <p>Number Columns have an additional parameter for the precision of the displayed numbers.
        By default, NumberColumns have 0, while Percentage- and CurrencyColumn have 2 digits after the comma.
    </p>
    <p>If you play around with sorting in the example-table below, you will see that if you sort via the TextColumn, the order will be wrong.
        That's because TextColumns sort alphabetically which means you can end up with an order of "1" - "12" - "5".
    </p>
    <p>If the row's value is a String that can't be parsed into a number, that text will be displayed.
        For sorting, non-numbers behave like the number "0".
    </p>
    <p>You can further configure these column types be setting the following cell-props with "addCellProps({...})":</p>
    <ul>
        <li>symbol: A symbol to be displayed after the number. By default it's "", "%" and "€" for NumberColumn, PercentageColumn and CurrencyColumn respectively</li>
        <li>separator: To make big numbers more readable, they are split into groups of 3 digits separated by this character, like this: 1.000.000. The default is "."</li>
    </ul>

    <ComponentViewer path='tables-table?id=table2'/>
    <CodeViewer
        :code='code.table2'
    />


    <h3>DateColumn and DateTimeColumn</h3>

    <p>For Dates and Dates with Time, we have DateColumn and DateTimeColumn.
    </p>
    <p>You may notice that when a String is found as a cell's value, it will be displayed as-is.
        That can be useful for example if some rows don't have that value and you want to show a reason for the missing value instead.
        But be careful if you enter a date as string! It has to be formatted the right way or you might see a problem like in the example below.
    </p>
    <p>For sorting, all dates are converted to numbers (milliseconds since epoch) and those get sorted.
        This conversion is attempted for string values as well.
        But when no timestamp can be extracted, the number will be assumed as "0", causing such values to end up at the beginning or end of the table.
    </p>
    <ComponentViewer path='tables-table?id=table3'/>
    <CodeViewer
        :code='code.table3'
    />


    <h3>HtmlColumn</h3>

    <p>In this column, values are rendered as HTML. XSS is avoided by first sanitizing the Strings using the sanitize-html library.</p>

    <ComponentViewer path='tables-table?id=table_html'/>
    <CodeViewer
        :code='code.table_html'
    />


    <h3>IconColumn</h3>

    <p>This Column can show a list of icons (PhosphorIcons)</p>
    <p>There is a function in table_util called "Icon" that should be used to create the right configurations.
        It has the following parameters:
    </p>
    <ul>
        <li>icon (required): The Phosphor Icon. This can be either a string or a direct reference to the icon</li>
        <li>title (required): This short text will be shown in the icon's stead when the table is exported</li>
        <li>size: How big the icon should be. The default is 20</li>
        <li>weight: PhosphorIcons can have a weight property, modifying their looks</li>
        <li>classString: String of class-names that will be applied to this icon</li>
    </ul>
    <p>Be aware that this column can handle lists of Icon-configurations, so multiple icons may be shown in the same cell.
    </p>

    <ComponentViewer path='tables-table?id=table_icons'/>
    <CodeViewer
        :code='code.table_icons'
    />



    <h3>PhoneColumn</h3>

    <p>As its name implies, the PhoneColumn is intended for phone numbers.
        It doesn't format its data in any way, so pass phone numbers the way they should be displayed.
    </p>
    <p>The advantage over just using a TextColumn instead is that they can be clicked by TAPI-users to
        start a call. For non-TAPI-users the phone numbers aren't rendered as links.
    </p>



    <h3>PillColumn</h3>
    
    <p>PillColumns take row-data of the following format and displays a Pill component in its cells.
        Where the row-data is null or undefined, nothing is displayed.
    </p>
    <CodeViewer
        :code='{"": pillFormat}'
    />

    <p>When sorting, PillColumns are sorted alphabetically by the labels of the pills by default.
    </p>
    <p>On export, only the label is exported.
    </p>

    <ComponentViewer path='tables-table?id=table_pill'/>
    <CodeViewer
        :code='code.table_pill'
    />

    <h3>SlotColumn</h3>

    <p>Sometimes a table needs very specific content in some cells. Something that isn't worth creating a new column-type for.
        In those cases SlotColumn can be used, which allows full customization of the cells' content via &lt;slot>.
    </p>
    <p>When implementing the slot's content, the slot's name is the same as the column's key.
        The template you define for it will be used in every cell of the column.
        The row object is passed as slot prop.
    </p>
    <p>A common use-case is input fields that should be shown in table cells.
    </p>
    <p>If you use SlotColumns to make table rows modifiable, it's especially important to set a rowId pointing to data that can't be modified.
        Otherwise, all the data in a row object is used to create an ID.
        If any data in the row object is then modified, this ID isn't correct anymore, which can lead to wrong behaviour.
        For example, if you have an InputField that modifies the row like below, it would lose focus after every change (because it gets re-rendered every time the id is changed).
    </p>
    <p>Another thing you have to look out for is that SlotColumns can't have reasonable defaults for sorting or exporting.
        If you want your SlotColumn to be sortable, you have to supply your own "toSortable" function.
        If you want it to be exportable, you have to supply your own export-Function with "withExporter(...)". Without it, the SlotColumn will not be exported.
    </p>
    <p>SlotColumns also can't have meaningful default values for width and flexGrow, so you should set them yourself.
    </p>

    <ComponentViewer path='tables-table?id=table_slot'/>
    <CodeViewer
        :code='code.table_slot'
    />





    <h1>Internals</h1>

    <p>This section is for those who are modifying the component. You don't need to know this part to use the table components.
    </p>

    <BaseCollapsable v-model="showInternals">
        <template v-slot:content>

            <h3>Vertical Design</h3>

            <p>In order to keep components readable and potentially reusable, the table components have been split into vertical slices.
                While Table.vue and PaginatedTable.vue offer their API to programmers, they only handle a small amount of table-related logic themselves.
                Whenever a logical separation of responsibilities is possible, sub-components have been created.
                Thus, the vertical stack, where each component uses the one below goes roughly like this:
            </p>
            <ol>
                <li>Table / PaginatedTable (public API)</li>
                <li>SortableTable (ordering rows/columns, exporting)</li>
                <li>ResponsiveTable (resizing, responsive design)</li>
                <li>PrimitiveTable (rendering)</li>
            </ol>


            <h3>Table and PaginatedTable</h3>

            <p>These two components share a lot of code using table-mixin.js.</p>
            <p>They also both use the SortableTable component for their rendering, though the way they hand over data differs slightly.
                While the normal Table has all rows present, it can leave sorting to the SortableTable component.
                The PaginatedTable on the other hand may not have all pages loaded, so sorting has to be handled manually.
                Thus, the PaginatedTable only gives the current page's rows to the SortableTable, while Table hands all rows over.
            </p>
            <p>Table and PaginatedTable also contain the components of the pagination at the bottom, which include:
            </p>
            <ul>
                <li>number of displayed rows</li>
                <li>Settings for how many rows should be shown per page</li>
                <li>page navigation</li>
            </ul>


            <h3>SortableTable</h3>

            <p>As the name implies, the main purpose of this component is to handle sorting-related tasks</p>
            <p>To this end, slots for column-headers are filled here.
                SortableTable checks for which headers a "toSortable" function is available and makes those columns' labels clickable.
                When one of them is clicked, it will update its sorting-config or emit the new value.
                The sorting-config consists of the clicked column's key and a boolean indicating whether the sort-direction is ascending or descending.
            </p>
            <p>Because the rows of a PaginatedTable can't be sorted automatically (the rows may not be loaded),
                SortableTable has the prop "sortedManually".
                If this prop is set to true, changes to the sort-config will be emitted instead of being applied by SortableTable directly.
                PaginatedTable passes this event along.
                The developer using a PaginatedTable has to handle this event and send a request to the server using the new sort-config.
                This is one of the reasons why you should use Table instead of PaginatedTable whenever possible.
            </p>

            <p>SortableTable also contains the table's title and context menu.
                If the table has a tableId, this context menu contains an option to configure the table's columns,
                which is also handled by SortableTable and the ModalColumnConfig.vue it contains.
                Because changes in header-configuration are saved to the server, SortableTable looks for such a configuration
                and, if found, combines it with the headers it received as props.
            </p>

            <p>The footer-row is also generated by SortableTable, because the elements below it may not have access to all of the rows
                necessary for generating some of the footer's values.
            </p>

            <p>Below SortableTable, the table's rendering starts.
                Here, SortableTable already has to decide between rendering a "normal" table (ResponsiveTable.vue) or a card-based view (TableCard.vue).
            </p>

            <p>(While writing this, it occurs to me that SortableTable has grown in scope and it might be due for splitting into smaller components)</p>


            <h3>ResponsiveTable</h3>

            <p>This component's purpose is to make sure the table looks fine regardless of screen size.
                To this end, it observes resizing events and may decide to hide some of the columns.
            </p>
            <p>For rendering it either uses PrimitiveScrollTable if the table should be scrollable horizontally,
                or PrimitiveTable for the "normal" case, where columns may be collapsed if they don't fit on screen.
            </p>
            <p>In order to observe changes in the component's size which may lead to hiding/unhiding columns, the PrimitiveFlexTable is used.
                This component isn't really a table and it shouldn't ever be visible on screen, but it renders all columns in their own divs to see how much space they would naturally take up.
                This information can then be used to correctly size the actual table's column widths.
            </p>
            <p>Why is this necessary at all? While we could use the columns' "minWidth" value to set their sizes, some columns' content may be smaller than that value.
                This will be noticed by PrimitiveFlexTable and the smaller value can be used be the table renderer.
                Because PrimitiveFlexTable will emit a resize-event whenever the columns' "natural" sizes change (for example due to page change),
                ResponsiveTable will always have accurate information about column widths.
            </p>
            <p>Using this information, ResponsiveTable calculates (in topHeadersFlat()) which columns should be visible and which should be collapsed.
                Firstly, it will keep all columns visible for which makeAlwaysVisible() has been called.
                If too many columns are "always visible", the table will look very squished on small screens, so beware.
                Next, it will hide columns from the end of the "center"-list until all columns fit on screen.
                If even that isn't enough, it will hide columns from the end of the "lockedRight"-, then "lockedLeft"-lists.
            </p>
            <p>The information about visible and collapsed headers is then handed to PrimitiveTable for rendering.
            </p>


            <h3>PrimitiveFlexTable</h3>

            This component isn't meant to be displayed, but is used to find out the columns' widths so they can be rendered correctly in the other Primitive*Table components.
            To this end it puts all content of a column into one div per column without restricting its width.
            Using a ResizeObserver it emits the widths of the columns every time there is a change in size.
            This can happen for example when the user changes page, as only the currently visible page is used in the PrimitiveFlexTable.


            <h3>PrimitiveTable</h3>

            <p>This component is used for rendering the standard-view of a table.
                It renders all rows given to it. So, filtering rows has to be done in a parent component (SortableTable).
                There isn't much logic directly in this component, but it emits a lot of events to be handled in its parent components.
            </p>
            <p>It contains components to handle Drag&Drop, manual reordering of rows and collapsing/uncollapsing rows.
                The information about which rows are opened is taken from the parent component, however.
            </p>
            <p>On very small screens where uncollapsing a row due to hidden columns would feel cramped, a modal containing a TableCard is used instead.
                There, the row's content is rendered more like a vertical list than a table.
            </p>


            <h3>PrimitiveScrollTable</h3>

            <p>This component is another renderer for tables. It differs from PrimitiveTable because no columns are collapsed in it.
                Instead, the "center"-list of columns can be scrolled horizontally so everything fits on screen.
                Be aware that the space for the "center" is only what's left after rendering the "lockedLeft"- and "lockedRight"-columns.
                So for this view to work well on small screens, as few rows as possible should be locked.
            </p>


            <h3>TableCard</h3>

            <p>This component is used by PrimitiveTable to show information about a row that wouldn't fit on small screens.
                It is also used by SortableTable when the "cardView" prop is set.
            </p>
            <p>Different from the "normal" table view, it takes only one row object instead of a list.
                It renders column headers on the left side and the values of the row on the right.
                The advantage of this is that all information about the row fits on screen even on small devices.
            </p>
        </template>
    </BaseCollapsable>

    <p></p>
</div>
</template>

<script>
import CodeViewer from '@/components/docs/CodeViewer.vue';
import ComponentViewer from '@/components/docs/ComponentViewer.vue';
import Table from '@/components/table2/Table.vue';
import {CODE} from "./componentViewer/tables/TableView.vue";
import {NumberColumn, DateColumn, DateTimeColumn} from "@/components/table2/table_util.js";
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import BaseCollapsable from '@/components/core/BaseCollapsable.vue';


export default {
    components: {
        CodeViewer,
        ComponentViewer,
        Table,
        InputToggleSwitch,
        BaseCollapsable
    },
    data() {
        const code = {};
        Object.keys(CODE).forEach((key) => {
            code[key] = this.buildCode(key);
        })
        return {
            headers: {
                center: [
                    NumberColumn("id", "ID", 0, 200).alignLeft(),
                    DateColumn("date", "Datum", 200, 1).alignLeft(),
                    DateTimeColumn("dateTime", "Datum + Zeit", 200, 1),
                ],
            },
            rows: [
                {id: 6, date: "12.12.2000 12:31", dateTime: "12.12.2000 12:31"},
                {id: 1, date: new Date(), dateTime: new Date()},
                {id: 5, date: "12.12.2000", dateTime: "12.12.2000"},
                {id: 12, date: Date.parse('04 Dec 1995 00:12:00 GMT'), dateTime: Date.parse('04 Dec 1995 00:12:00 GMT')},
                {id: 8, date: null, dateTime: null},
                {id: "test", date: "test", dateTime: "test"},
            ],
            scrollHorizontally_scroll_horizontally: true,
            code,
            pillFormat: `
            {
                label: String, // the text to be displayed
                type: String, // one of [primary, success, warning, info, danger], see Pill.vue
            }`,
            showInternals: false,
        };
    },
    computed: {
    },
    methods: {
        buildCode(tableId) {
            let html = `&lt;Table
    :headers="headers"
    :rows="rows"\n`;
            if (CODE[tableId].selected) {
                html += `    v-model="selected"\n`;
            }
            Object.keys(CODE[tableId].props || {}).forEach(key => {
                const value = CODE[tableId].props[key];
                if (value === true) {
                    html += `    ${key}\n`;
                } else {
                    html += `    ${key}="${value}"\n`;
                }
            });
            if (CODE[tableId].slot) {
                html += ">";
                html += CODE[tableId].slot;
                html += `&lt;/Table>`;
            } else {
                html += "/>";
            }
            html = (CODE[tableId].html_pre || "") + html + (CODE[tableId].html_post || "");

            let javascript = CODE[tableId].headers + CODE[tableId].rows;
            javascript = (CODE[tableId].javascript_pre || "") + javascript + (CODE[tableId].javascript_post || "");
            javascript = javascript.replaceAll("<", "&lt;");

            return {
                Html: html,
                Javascript: javascript,
            };
        },
    },
}

</script>

<style scoped>
a {
    text-decoration: underline !important;
    color: blue !important;
}
</style>
