import BROKER_SEARCH_TYPES from './types';
import { getInitialState } from './index';
import Vue from 'vue';
import { deepAssign, } from '@/helpers/commonfunctions';

export function looksLikeMaklernr(value) {
  return /^\d{5}$/gi.test(`${value}`);
}


export default {


  [BROKER_SEARCH_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },
  [BROKER_SEARCH_TYPES.MUTATIONS.OPEN_BROKER](state, payload) {
    if(!payload || !looksLikeMaklernr(payload.data.brokerID)) {
      return;
    }

    const { lastOpenedBrokers = [], } = state;
    let payloadCopy = { ...payload };

    const openedBrokerIndex = lastOpenedBrokers.findIndex(v => v.data.brokerID == payload.data.brokerID);
    if(openedBrokerIndex >= 0) { // removes the last broker opened from the list to keep it at the top
      payloadCopy = deepAssign({}, lastOpenedBrokers[openedBrokerIndex], payloadCopy);
      lastOpenedBrokers.splice(openedBrokerIndex, 1);
    }

    Vue.set(state, 'lastOpenedBrokers', [
      payloadCopy, // keeps the last broker opened at the top
      ...lastOpenedBrokers,
    ]);
  },
  [BROKER_SEARCH_TYPES.MUTATIONS.UPDATE_LAST_OPENED_BROKER_DATA_ONLY](state, payload) {
    if(!payload || !looksLikeMaklernr(payload.data.brokerID)) {
      return;
    }

    const { lastOpenedBrokers = [], } = state;
    const lastOpenedBroker = { ...payload, };
    const openedBrokerIndex = lastOpenedBrokers.findIndex(v => v.data.brokerID == payload.data.brokerID);
    if(openedBrokerIndex >= 0) {
      lastOpenedBrokers[openedBrokerIndex] = lastOpenedBroker;
      Vue.set(state, 'lastOpenedBrokers', [ ...lastOpenedBrokers, ]);
    }
  },



}