export function searchMenu(typ, combos, isTerminkalenderSyncAktiv = false) {
  return [
    {
      type: 'group',
      key: 'allgemein',
      label: 'Allgemein',
      menuItems: [
        {
          type: 'text',
          label: 'Name',
          key: 'lastName',
          sortable: true,
          emptyDenied: true,
        },
        {
          type: 'text',
          label: 'Vorname',
          key: 'firstName',
          emptyDenied: true,
        },
        {
          type: 'text',
          label: 'Telefon',
          key: 'phone',
          emptyDenied: true,
        },
        {
          type: 'text',
          label: 'E-Mail',
          key: 'email',
          emptyDenied: true,
        },
        {
          type: 'text',
          label: 'Firma / Gesell.',
          key: 'firma',
          emptyDenied: true,
        },
        {
          type: 'datepicker',
          label: 'Geburtsdatum',
          key: 'birthday',
          emptyDenied: true,
        },
        {
          type: 'default',
          label: 'Interessent',
          key: 'interess',
          emptyDenied: true,
        },
        ... typ === 'company' ? [{
          type: 'combobox',
          label: 'Ansprechpartner Typ',
          key: 'apTyp',
          comboOptions: combos.apTyp || [],
          emptyDenied: true,
        }] : [],
        ... typ === 'contact' ? [
        {
          type: 'combobox',
          label: 'Zus. Informationen',
          key: 'zusInfosCombo',
          comboOptions: combos.zusInfos || [],
          deniable: true,
          emptyDenied: true,
        },
        {
          type: 'text',
          label: 'Zus. Informationen Freitext',
          key: 'zusInfosFreitext',
          deniable: true,
          allowDuplicated: true,
          emptyDenied: true,
          maxOccurences: 2,
        }] : [],
        ...(isTerminkalenderSyncAktiv ? [
          {
            type: 'default',
            label: 'Nur synchronisierte Kontakte',
            key: 'onlySyncedContacts',
          },
        ] : []),
      ]
    },
    {
      type: 'group',
      key: 'adresse',
      label: 'Adresse',
      menuItems: [
        {
          type: 'text',
          key: 'street',
          label: 'Straße',
          emptyDenied: true,
        },
        {
          type: 'text',
          key: 'city',
          label: 'Ort',
          emptyDenied: true,
        },
        {
          type: 'text',
          key: 'zip',
          label: 'PLZ',
          emptyDenied: true,
        },
        {
          type: 'text',
          key: 'country',
          label: 'Land',
          emptyDenied: true,
        },
      ]
    },
  ];
}
