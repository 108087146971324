var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("AssignInsurance", {
        attrs: {
          type: "pdfBox",
          id: this.$route.query.id,
          isMrMoney: true,
          versicherungId: this.$route.query.insuranceId
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }