<template>
	<CoreCard ref="coreCard" :showToolbar="showToolbar">
		<template v-if="showTitle" v-slot:header>
			{{chartName}}: {{data[`${chartName}`]}}
		</template>
		<template v-slot:alternative>
		</template>
    <!-- <div class="content">
      <div v-if="isContainerRendered">
		    <div class="text-lg">{{data[`${chartName}`]}}</div>
      </div>
    </div> -->
	</CoreCard>
</template>

<script>
import CoreCard from '../CoreCard/CoreCard'
import cardMixin from '../CoreCard/card-mixin';


export default {
  mixins: [cardMixin],

  data: function() {
    return {
      isContainerRendered: false,
    };
  },

  computed: {
    chartName: function() {
      return this.propsData.option;
    },
    data: function() {
      return this.propsData.data;
    },
    customColors: function() {
      return this.propsData.customColors;
    },
  },

  mounted: function () {
    this.$nextTick(function () {
      this.isContainerRendered = true;
    });
  },

  components: {
    CoreCard
  },
}
</script>

<style scoped>
.content {
  width: 100%;
  height: 100%;
  position: relative;
  margin-top: -12px;
  /* text-align: center; */
  min-height: auto;
}
.text-lg {
  /* font-size: 3rem; */
  line-height: 3em;
  color: var(--color-primary);
}
.text-sm {
  font-size: 1rem;
}
</style>
