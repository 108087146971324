var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: _vm.selectedPositionLabel }
      }),
      _c(
        "div",
        [
          _c("FondsFinderSuchMaske"),
          _c("FondsFinderPositionsList", {
            attrs: {
              goBackEnabled: true,
              singleSelectionProp: true,
              zurueckButtonText: "Zurück zur Wertpapierumschichtung"
            },
            on: { goBack: _vm.closeFondsfinder }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }