export const MUTATION_PREFIX = 'WINDOW_CONTROL: ';
export const ACTIONS_PREFIX = 'WINDOW_CONTROL_ACTIONS_';
export const GETTERS_PREFIX = 'WINDOW_CONTROL_GETTERS_';

export default {
  MUTATIONS: {
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    ADD_OPENED: MUTATION_PREFIX + 'ADD_OPENED',
    REMOVE_OPENED: MUTATION_PREFIX + 'REMOVE_OPENED',
  },
  ACTIONS: {
    OPEN_WINDOW: ACTIONS_PREFIX + 'OPEN_WINDOW',
    CLOSE_WINDOW: ACTIONS_PREFIX + 'CLOSE_WINDOW',
  },
  GETTERS: {
    OPENED: GETTERS_PREFIX + 'OPENED',
  },
}
