var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          defaultMenu: _vm.$appNavigation.currentTabMenu,
          title: "GDV Datenimport",
          subtitle: "Interface Verwalten",
          actions: _vm.headerActions
        },
        on: { "action-ADD": _vm.newInterface }
      }),
      _c("BaseFilter", {
        attrs: {
          title: "Interface Verwalten",
          filterId: "Interface Verwalten",
          configFilter: _vm.filterConfig,
          metadata: _vm.metadata,
          defaultOptions: _vm.defaultOptions,
          showSaveButton: ""
        },
        on: { onFilter: _vm.onFilter }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.loading || !_vm.rows.length
            ? _c("span", { staticClass: "box__title" }, [
                _vm._v(" Interface Verwalten ")
              ])
            : _vm._e(),
          _vm.loading
            ? _c("GhostLoading", { attrs: { type: "table" } })
            : _vm.rows.length
            ? _c("Table", {
                staticStyle: { clear: "both" },
                attrs: {
                  tableId: "2f188530-c61e-4951-808d-5d1c9196c534",
                  title: "Interface Verwalten",
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowsPerPage: 20
                },
                on: {
                  "action-OPEN": _vm.actionOpen,
                  "action-DELETE": _vm.deleteRow,
                  "action-COPY": _vm.copyRow
                }
              })
            : _c("NoData")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }