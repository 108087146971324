<template>
  <div>
    <div v-html="sanitize(text)"></div>
    
    <div class="base-modal__footer"> 
      <BaseButton class="base-modal__footer--button" isSecondary @click="$emit('close')" :disabled="loading">Abbrechen</BaseButton>
      <BaseButton class="base-modal__footer--button" @click="onConfirm()" :disabled="loading">
        Akzeptieren <AnimatedSpinner v-if="loading" />
      </BaseButton>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapGetters, } from 'vuex';
import CORE_TYPES from '@/store/core/types';
import FC_CONFIG_TYPES from '@/store/fcConfig/types';

import BaseModal from '@/components/core/BaseModal.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';

import { sanitize, } from '@/helpers/string-helper.js';
import { BROKER_STRUCTURE_ROLES, } from '@/router/roles';
import FC_CONFIG, { FC_CONFIG_USER_LEVEL, } from '@/configs/fcConfig.js';
import { addParamToURL } from '@/helpers/utils-helper';

const BROKER_SEARCH_HOME = '/customer/maklersuche';
const MAKLER_HAFTUNGSUEBERNAHME_UNTERMAKLER = 'MAKLER_HAFTUNGSUEBERNAHME_UNTERMAKLER';

export default {
  name: 'HaftungserklaerungsUntervermittler',
  components: {
    BaseModal,
    BaseButton,
    AnimatedSpinner,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
    text() {
      const zugriffFonds = this.hasRoles([BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS, BROKER_STRUCTURE_ROLES.ZUGRIFF_GESCHLOSSENE_FONDS]);
      const zugriffVersicherung = this.hasRoles([BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN]);

      let lizenzen = "dass Ihre Untervermittler ohne eigenen Vertrag die gesetzlich vorgeschriebenen Lizenzen<br> " +
				" - im Versicherungsbereich eine Erlaubnis nach § 34 d/e GewO<br> " +
				"<b>und/oder</b> <br> " +
				" - im Investmentbereich eine Erlaubnis nach § 34 f/h GewO<br> ";

      if (zugriffFonds && !zugriffVersicherung) {
        lizenzen = "dass Ihre Untervermittler ohne eigenen Vertrag die gesetzlich vorgeschriebene Lizenz nach § 34 f/h GewO ";
      } else if (!zugriffFonds && zugriffVersicherung) {
        lizenzen = "dass Ihre Untervermittler ohne eigenen Vertrag die gesetzlich vorgeschriebene Lizenz nach § 34 d/e GewO ";
      }

      const subtext = "Mit Anlegen eines Untervermittlers erklären Sie, ";

      return "" +
				"Bitte beachten Sie, dass alle im MSC unter Ihrer Vermittlernummer bereits angelegten und noch anzulegenden Untervermittler, " +
				"Firmen, Strukturen etc. (kurz: \"UV\") <b>ohne</b> eigenen Vertrag mit der FK, Ihre Erfüllungsgehilfen sind. " +
				"Mit dem Akzeptieren dieser Bedingungen haften Sie für diese UVs gegenüber der FK und <b>allen angeschlossenen Unternehmen</b>, " +
				"wie für eigenes Handeln. Dies gilt insbesondere für Vermittlungs- und Beratungsfehler, Fehler bei der Handhabung des MSC, " +
				"Rückzahlungsansprüche aus Stornos und alle sonstigen Tätigkeiten die mittel- und unmittelbar mit der Vermittlung von Finanzprodukten in Zusammenhang stehen.<br>" +
				subtext +
				lizenzen +
				"und alle weiteren Voraussetzungen zur Erfüllung des <b>zuverlässigen Dritten</b> im Sinne des GwG (z.B. Bonität, GwG Schulung, " +
				"eintragungsfreies Führungszeugnis, Negativerklärung für Strafverfahren) für ein Tätigwerden erfüllen und beauftragen " +
				"ggf. FK die Courtagen Ihrer Untervermittler direkt an Ihre Untervermittler auszuzahlen. " +
				"Sie bestätigen weiterhin, dass die Voraussetzungen zur Auszahlung der Courtagen für Ihre UV gemäß §31d WpHG vorhanden sind. " +
				"Änderungen in den oben genannten Voraussetzungen müssen unverzüglich der FK angezeigt werden.<br><br>" +
				"Zur Einhaltung der Voraussetzungen für die Vermittlung von Finanzprodukten, stellt Ihnen die FK die Geldwäscheschulung " +
				"im MSC und die automatische halbjährliche Bonitätsauskunft Ihrer UV entgeltlich zur Verfügung. " +
				"FK prüft auch regelmäßig selbst die Bonität der UV, die IHK Nummer und die jährliche Geldwäscheschulung. " +
				"Für diese Dienstleistung erhebt die FK eine jährliche Dienstleistungspauschale von<br>" +
				"10€ + MwSt. pro aktiven UV. " +
				"Diese wird direkt mit der Courtageabrechnung erhoben.<br>";
    },
  },
  methods: {
    sanitize(html) {
      return sanitize(html);
    },
    async onConfirm() {
      try {
        this.loading = true;
        const payload = {
          configId: MAKLER_HAFTUNGSUEBERNAHME_UNTERMAKLER,
          configType: FC_CONFIG.ADD_PARAMETER,
          content: dayjs().format('DD.MM.YYYY'),
          userLevel: FC_CONFIG_USER_LEVEL.MAKLER,
        };
        await this.$store.dispatch(FC_CONFIG_TYPES.ACTIONS.SAVE_FC_CONFIG, payload);
        this.$emit('confirmed');
      } finally {
        this.loading = false;
      }
    },
  },
}
</script>

<style scoped>
.base-modal__footer {
  display: flex;
  margin: 16px 0 0;
}
.base-modal__footer--button {
  margin: 0 16px 0 0;
}
.base-modal__footer--button:last-child {
  margin-right: 0;
}
</style>
