<template>
  <div>
    <BaseModal
      ref="SchadenVereinigenModal"
      modalTitle="Schadensmeldungen vereinigen"
      labelButtonConfirm="Vereinigen "
      @onConfirmButton="onConfirmModal()"
      @onCancelButton="onCancelModal()"
      @onCloseButton="onCancelModal()"
    >

      <div class="row">
        <div class="col-12">
          <p>Hier können Sie zwei Schadensmeldungen vereinigen.</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <hr>
          <p><span class="color-danger font-bold">ACHTUNG!</span></p>
          <p><span class="color-danger font-bold">Vereinigte Schadensmeldungen können im Nachgang nicht mehr "getrennt" werden. Sind Sie sich sicher, dass Sie die Verträge vereinigen möchten?</span></p>
          <hr>
        </div>
      </div>    


      <div class="main__container">
        <div class="header" >
          Quell-Schadensmeldung
        </div>
        <div class="control" >
          &nbsp;
        </div>

        <div class="header" >
          Ziel-Schadensmeldungen
        </div>  
      </div>


      <div v-if="vertragList && vertragList.length==2 && !changingPosition" class="main__container">
          <div class="item">
            {{vertragList[0]["schadenId"]}} <br> {{bezechnug(vertragList[0])}}
          </div>
          <div class="clickable control">
            <ph-arrows-clockwise :size="16" weight="bold" @click="changePositions()" />
          </div>
          <div class="item">
            {{vertragList[1]["schadenId"]}}  <br> {{bezechnug(vertragList[1])}}
          </div>          
      </div>


    </BaseModal>
  </div>
</template>

<script>
import BaseModal from "@/components/core/BaseModal.vue";
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";
import { mapGetters } from "vuex";
import {
PhArrowsClockwise
} from "phosphor-vue";

export default {
  props: {
    vertragList: { 
      type: Array,
      default: () => []
    },
  },  
  components: {
    BaseModal,
    PhArrowsClockwise
  },
  data() {
    return {
      schadens: null,
      changingPosition: false,
    };
  },
  computed: {
    ...mapGetters({
      insurancesByCustomer: VERSICHERUNG_TYPES.GETTERS.INSURANCES_BY_CUSTOMER,
    }),    
  },
  methods: {
    bezechnug(row){
      
      let m= row.ereignisdatum;
      if( m==null){
        m=row.meldedatum;
      }
      if( m==null){
        m="xx.xx.xxxx";
      }

       return m +' '+row.schadennr;
    },
    open() {
      this.$refs.SchadenVereinigenModal.open();
    },
    onConfirmModal() {
      console.log("vert0",this.vertragList[0]);  
      console.log("vert0id",this.vertragList[0].id);  
      console.log("vert0shid",this.vertragList[0].schadenId); 
      this.schadens = {
        toRemove : {
          schadenId: this.vertragList[0].schadenId
        },
        toKeep: {
          schadenId: this.vertragList[1].schadenId
        }
      }  

      this.$emit("save", this.schadens);
      this.$refs.SchadenVereinigenModal.close();
      

    },
    onCancelModal() {
      this.$refs.SchadenVereinigenModal.close();
    },
    changePositions() {
      this.changingPosition = true;

      let firstPosition = this.vertragList[0];
      let secondPosition = this.vertragList[1];

      this.vertragList[0] = secondPosition;
      this.vertragList[1] = firstPosition;

      this.schadens = {
        toRemove: {
          schadenId: firstPosition.schadenId
        },
        toKeep : {
          schadenId: secondPosition.schadenId
        }
      } 

      this.changingPosition = false;


    },   
  },
  mounted() {}
};
</script>

<style>

.main__container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 0px;

}

.main__container .header {
  flex: 1 1 0px;
}

.main__container .item {
  flex: 1 1 0px;
  /* align-self: center; */
  border: 1px  solid var(--color-text);
  padding: 4px;
}

.main__container .control {
  flex: 0.2 1 0px;
  align-self: center;
  justify-self: center;
  text-align: center;
}




</style>