<template>
  <div>
    <BaseModal
      ref="askOptionBaseModal"
      :labelButtonConfirm="confirmButtonLabel"
      :size="modalSize"
      :modalTitle="title"
      :showCancelButton="false"
      :showConfirmButton="!isSoftfair"
      :actions="modalActions"
      @onConfirmButton="$emit('onConfirmButton', form)"
      @onCancelButton="$emit('close')"
      @onCloseButton="$emit('close')"
      @onEscButton="$emit('close')"
      @action-DOWNLOAD="closeModal">

      <div class="pb-2" v-for="askOption in askOptions" :key="askOption">
        <template v-if="askOption === 'USER_ANONYMOUS'">
          <InputRadioBoxGroup v-model="form.userAnonymous" :values="[
            { label: 'Kundendaten übertragen', value: false },
            { label: 'Kundendaten anonymisieren (Name, Adresse)', value: true },
          ]"/>
        </template>

        <template v-if="askOption === 'INNOSYSTEMS_BUTTONS'"> 
          <div class="row">
            <div class="col">Vergleichsrechner Sachversicherung</div>
          </div>
          <div class="externezugang__button-container layout__negative-margin--8">
            <BaseButton @click="clickInnosystemsButtom('BUTTON_BAUHERRENHAFTPFLICHT')">Bauherrenhaftpflicht</BaseButton>
            <BaseButton @click="clickInnosystemsButtom('BUTTON_GEWAESSERSCHADEN')">Gewässerschaden</BaseButton>
            <BaseButton @click="clickInnosystemsButtom('BUTTON_HAUSUNDGRUNDBESITZER')">Haus und Grundbesitzer</BaseButton>
            <BaseButton @click="clickInnosystemsButtom('BUTTON_HAUSRAT')">Hausrat</BaseButton>
            <BaseButton @click="clickInnosystemsButtom('BUTTON_HUNDEHAFTPFLICHT')">Hundehaftpflicht</BaseButton>
            <BaseButton @click="clickInnosystemsButtom('BUTTON_PFERDEHAFTPFLICHT')">Pferdehaftpflicht</BaseButton>
            <BaseButton @click="clickInnosystemsButtom('BUTTON_PRIVATHAFTPFLICHT')">Privathaftpflicht</BaseButton>
            <BaseButton @click="clickInnosystemsButtom('BUTTON_RECHTSSCHUTZ')">Rechtschutz</BaseButton>
            <BaseButton @click="clickInnosystemsButtom('BUTTON_UNFALL')">Unfall</BaseButton>
            <BaseButton @click="clickInnosystemsButtom('BUTTON_WOHNGEBAEUDE')">Wohngebäude</BaseButton>
          </div>

          <div class="row">
            <div class="col">Vergleichsrechner Gewerbeversicherung</div>
          </div>
          <div class="externezugang__button-container layout__negative-margin--8">
            <BaseButton @click="clickInnosystemsButtom('BUTTON_RECHTSSCHUTZSELBST')">Rechtsschutz für Selbstständige/ Firmen</BaseButton>
          </div>

          <div class="row">
            <div class="col">Vergleichsrechner Krankenversicherung</div>
          </div>
          <div class="externezugang__button-container layout__negative-margin--8">
            <BaseButton @click="clickInnosystemsButtom('BUTTON_KRANKENHAUSTAGEGELD')">Krankenhaustagegeld</BaseButton>
            <BaseButton @click="clickInnosystemsButtom('BUTTON_KRANKENTAGEGELD')">Krankentagegeld</BaseButton>
            <BaseButton @click="clickInnosystemsButtom('BUTTON_KRANKENVOLLVERSICHERUNG')">Krankenvollversicherung</BaseButton>
            <BaseButton @click="clickInnosystemsButtom('BUTTON_KRANKENZUSATZVERSICHERUNG')">Krankenzusatzversicherung</BaseButton>
          </div>

          <div class="row">
            <div class="col">Vergleichsrechner Krankenzusatzversicherung Zahn</div>
          </div>
          <div class="externezugang__button-container layout__negative-margin--8">
            <BaseButton @click="clickInnosystemsButtom('BUTTON_KRANKENZUSATZVERSICHERUNGZAHN')">Krankenzusatzversicherung Zahn</BaseButton>
          </div>
          
          <div class="row">
            <div class="col">Vergleichsrechner PKW-Versicherung und Motorrad-Versicherung</div>
          </div>
          <div class="externezugang__button-container layout__negative-margin--8">
            <BaseButton @click="clickInnosystemsButtom('BUTTON_PKW')">PKW und Motorrad (zum Auswahlmenu)</BaseButton>
            <BaseButton @click="clickInnosystemsButtom('BUTTON_EVBSERVICE')">EVB-Service</BaseButton>
          </div>
        </template>

        <template v-if="askOption === 'MR_MONEY_BUTTONS'">
          <div class="row">
            <div class="col">Angebots-Center</div>
          </div>
          <div class="externezugang__button-container layout__negative-margin--8">
            <BaseButton @click="clickMrMoneyButton('BUTTON_ANGEBOT')">Angebot</BaseButton>
          </div>

          <div class="row">
            <div class="col">Privat</div>
          </div>
          <div class="externezugang__button-container layout__negative-margin--8">
            <BaseButton @click="clickMrMoneyButton('BUTTON_BAU')">Bau-Versicherung</BaseButton>
            <BaseButton @click="clickMrMoneyButton('BUTTON_FAHRRADVERGLEICH')">E-Bike/Fahrrad-Versicherung</BaseButton>
            <BaseButton @click="clickMrMoneyButton('BUTTON_HAUSUNDGRUNDBESITZER')">Haus- und Grundbesitzerhaft</BaseButton>
            <BaseButton @click="clickMrMoneyButton('BUTTON_HAUSRAT')">Hausrat-Versicherung</BaseButton>
            <BaseButton @click="clickMrMoneyButton('BUTTON_PRIVATHAFTPFLICHT')">Privat-Haftpflicht-Versicherung</BaseButton>
            <BaseButton @click="clickMrMoneyButton('BUTTON_PHOTOVOLTAIK')">Photovoltaik-Versicherung</BaseButton>
            <BaseButton @click="clickMrMoneyButton('BUTTON_RECHTSSCHUTZ')">Rechtsschutz-Versicherung</BaseButton>
            <BaseButton @click="clickMrMoneyButton('BUTTON_RISIKOLEBEN')">Risikolebens-Versicherung</BaseButton>
            <BaseButton @click="clickMrMoneyButton('BUTTON_TIERHALTERHAFTPLICHT')">Tierhalter-Haftpflicht</BaseButton>
            <BaseButton @click="clickMrMoneyButton('BUTTON_UNFALL')">Unfall-Versicherung</BaseButton>
            <BaseButton @click="clickMrMoneyButton('BUTTON_WOHNGEBAEUDE')">Wohngebäude-Versicherung</BaseButton>
            <BaseButton @click="clickMrMoneyButton('BUTTON_OELTANKHAFTPFLICHT')">Öltank-Haftpflicht-Versicherung</BaseButton>
          </div>

          <div class="row">
            <div class="col">Gewerbe</div>
          </div>
          <div class="externezugang__button-container layout__negative-margin--8">
            <BaseButton @click="clickMrMoneyButton('BUTTON_BETRIEBSINHALT')">Betriebs-Inhaltsversicherung</BaseButton>
            <BaseButton @click="clickMrMoneyButton('BUTTON_BETRIEBSHAFTPLICHT')">Betriebs-Haftpflichtversicherung</BaseButton>
            <BaseButton @click="clickMrMoneyButton('BUTTON_BUERO')">Büroversicherung</BaseButton>
            <BaseButton @click="clickMrMoneyButton('BUTTON_ELEKTRONIK')">Elektronikversicherung</BaseButton>
            <BaseButton @click="clickMrMoneyButton('BUTTON_GEWERBERECHTSSCHUTZ')">Gewerbe-Rechtsschutz</BaseButton>
            <BaseButton @click="clickMrMoneyButton('BUTTON_GEWERBEGEBAEUDE')">Gewerbe-Gebäudeversicherung</BaseButton>
            <BaseButton @click="clickMrMoneyButton('BUTTON_MASCHINENABMG')">Maschinenversicherung ABMG</BaseButton>
            <BaseButton @click="clickMrMoneyButton('BUTTON_MASCHINENAMB')">Maschinenversicherung AMB</BaseButton>
          </div>
        </template>

        <template v-if="askOption === 'SOFTFAIR_FORM'">
          <div>Sehr geehrte Damen und Herren,</div>
          <div>Sie haben noch keinen Zugang für den Vergleichsrechner von Softfair. Sehr gerne bieten wir Ihnen einen Zugang zu Softfair an. Hierfür bitten wir Sie das Bestellformular auszufüllen.</div>
          <div class="box__title mt-3">Softfair Registrierung</div>
          <InputField label="Firma" v-model="form.firma" disabled />
          <InputField label="Vorname" v-model="form.vorname" />
          <InputField label="Name" v-model="form.nachname" />
          <InputField label="Straße" v-model="form.strasse" />
          <InputZIP label="PLZ" v-model="form.plz" />
          <InputField label="Ort" v-model="form.ort" />
          <InputField label="Telefon" inputmode="tel" v-model="form.telefon" />
          <div class="font-bold mb-2">Bankdaten</div>
          <InputField label="Bankinstitut" v-model="form.bank" />
          <InputIbanBic validation_path="form.iban" label="IBAN" type="IBAN" :componentData="form.iban" @change="updateIban" />
          <InputIbanBic validation_path="form.bic" label="BIC" type="BIC" :componentData="form.bic" @change="form.bic = $event" />
          <InputField label="Kontoinhaber" v-model="form.kontoinhaber" />
        </template>

        <template v-if="askOption === 'COVOMO'">
          <p><b>Covomo</b> ist das marktführende Vergleichs- und Abschlussportal für <b>Spezial- und Nieschenversicherungen.</b></p>
          <p>Falls Sie noch keinen Zugang besitzen oder Ihr Account noch nicht mit der FondsKonzept verknüpft ist, melden Sie sich bitte bei einem Mitarbeiter des FondsKonzept-Versicherungsbereichs.</p>
          <p><b>Hinweis: </b>Es findet eine automatische Vertragsanlage im smartMSC statt, sofern der Kundendatensatz eindeutig mit den übermittelten Daten übereinstimmt. Bitte stellen Sie lediglich sicher, dass der Kunde mit seiner aktuellen Adresse angelegt ist.</p>
        </template>

        <template v-if="askOption === 'OCC'">
          <p><b>Wichtig:</b> Verträge werden nicht automatisch im smartMSC angelegt! Dies muss beim Abschluss manuell vom Vermittler erfolgen. </p>
        </template>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import BaseModal from '@/components/core/BaseModal.vue'
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue' 
import BaseButton from '@/components/core/BaseButton.vue'
import InputField from '@/components/core/forms/InputField.vue';
import InputZIP from '@/components/core/forms/InputZIP.vue';
import InputIbanBic from '../core/forms/InputIBAN_BIC.vue';
import { BaseModalDownloadLinkAction } from '../core/base-modal-actions/base-modal-actions-utils';

export default {
  components: {
    BaseModal,
    InputRadioBoxGroup,
    BaseButton,
    InputField,
    InputZIP,
    InputIbanBic,
  },
  props: {
    askOptions: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    confirmButtonLabel: {
      type: String,
      default: 'Kunde anlegen'
    },
    data: {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      form: {
        userAnonymous: false,
        innosystemsAction: '',
        firma: '',
        vorname: '',
        nachname: '',
        strasse: '',
        ort: '',
        plz: '',
        telefon: '',
        bank: '',
        iban: '',
        bic: '',
        kontoinhaber: '',
      }
    }
  },
  computed: {
    modalSize() {
      return ['INNOSYSTEMS_BUTTONS', 'MR_MONEY_BUTTONS'].some(item =>  this.askOptions.includes(item)) ? 'lg' : 'md'
    },
    isSoftfair() {
      return this.askOptions?.[0] === 'SOFTFAIR_FORM';
    },
    modalActions() {
      return [
        BaseModalDownloadLinkAction('DOWNLOAD', 'Bestellformular anlegen', 'SoftfairAntrag', '/get_softfair_form', this.form)
          .withVisible(() => this.isSoftfair)
      ]
    }
  },
  methods: {
    clickMrMoneyButton(buttonId) {
      this.form.mrmoneyAction = buttonId  
      this.$emit('onConfirmButton', this.form)
      this.$refs.askOptionBaseModal.close()
    },
    clickInnosystemsButtom(buttonId) {
      this.form.innosystemsAction = buttonId
      this.$emit('onConfirmButton', this.form)
      this.$refs.askOptionBaseModal.close()
    },
    updateIban(value) {
      this.form.iban = value;
    },
    closeModal() {
      this.$refs.askOptionBaseModal.close(); 
      this.$router.go(-1);
    }
  },
  mounted(){
    this.$refs.askOptionBaseModal.open();
    if (this.data) {
      this.form = Object.assign(this.form, this.data);
    }
    console.log('askOptions', this.askOptions && JSON.parse(JSON.stringify(this.askOptions)) );
  }
}
</script>

<style scoped>
.externezugang__button-container {
  display: flex;
  flex-wrap: wrap;
}


</style>