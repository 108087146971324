import store from '@/store';
import BRIDGE_TYPES from '@/store/bridge/types';

/** Get the Window for opening the Redirection Page in a new Tab */
export function getRedirectionWindow(ignoreOnMobileNative) {
    if(ignoreOnMobileNative && store.getters[BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT]) {
        return null;
    }
    return window.open(getRedirectionHtmlString(), "_blank");
}
/** 
 * @deprecated don't use this function. 
 * 
 * Download files should be handled by DownloadLink.vue component due to the native stack.
 * 
 * Use this function for opening a Blob File in a new Tab
 * @param file: Blob file to open
 * @param windowRef: The Reference of the Window to show the file in.
 *  Create a new windowRef e.g. with let windowRef = window.open("about:blank","_blank").
 *  The windowRef MUST be created, before any AJAX is fired, otherwise Safari won't open a new Tab because of security reasons.
 */
export function openFileInNewTab(file, windowRef, modalCallback, filename = "Dokument.pdf") {
    if (!windowRef || !windowRef.location) {
        modalCallback("Bitte Popups erlauben", "Die Datei wird in einem neuen Tab geöffnet. \
        Bitte vergewissern Sie sich, dass kein Addblocker diesen Zugriff verbietet. \
        Bitte erlauben Sie das Öffnen in einem neuen Tab");
        throw new Error("Can not access Tab to write content to.");
    }
    else {
        if (navigator.userAgent.toLocaleLowerCase().indexOf("edge") > -1) {
            windowRef.close();
            navigator.msSaveOrOpenBlob(file, filename);
        }
        else {
            const linkUrl = URL.createObjectURL(new File([file], filename, { type: file.type }));
            windowRef.location.href = linkUrl;
        }
    }
}

function addTypeToFilename(fileName, contentType) {
    const test = fileName.toLocaleLowerCase();
    if (contentType.indexOf('pdf') !== -1) {
        contentType = test.indexOf('.pdf') != -1 ? '' : '.pdf';
    } else if (contentType.indexOf('openxmlformats') !== -1) {
        contentType = test.indexOf('.xlsx') != -1 ? '' : '.xlsx';
    } else if (contentType.indexOf('csv') !== -1) {
        contentType = test.indexOf('.csv') != -1 ? '' : '.csv';
    } else if (contentType.indexOf('xls') !== -1) {
        contentType = test.indexOf('.xls') != -1 ? '' : '.xls';
    } else {
        contentType = '';
    }
    return fileName + contentType;
}
/**
 * @deprecated don't use this function. 
 * 
 * Download files should be handled by DownloadLink.vue component due to the native stack.
 * 
 * @param {*} fileName 
 * @param {*} byteArr 
 * @param {*} contentType 
 */
export function downloadFile(fileName, byteArr, contentType = 'text/csv;charset=utf-8;') {
    fileName = addTypeToFilename(fileName, contentType);
    let response;
    if (typeof byteArr === 'string') {
        response = getByteArrayFromString(byteArr)
    } else {
        response = byteArr
    }
    const options = { type: contentType };
    const blob = new File([response], fileName, options);
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    //if (CommunicationService.getIsIOS() || this.isTestcustomer) {
    //    return CommunicationService.getBlobLink(response, contentType, fileName, this.isTestcustomer);
    //}
    link.setAttribute('href', url);
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
        link.setAttribute('download', fileName);
    }
    else {
        link.target = '_blank';
    }
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    setTimeout(() => document.body.removeChild(link), 0);
}

/**
 * @deprecated don't use this function. 
 * 
 * Download files should be handled by DownloadLink.vue component due to the native stack.
 * 
 * @param {*} fileName 
 * @param {*} byteArr 
 * @param {*} contentType 
 */
export function openFile(byteArr, contentType, filename, windowRef) {
    if (store.getters[BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT]) { // mobile context
        let bytes = byteArr;
        if (typeof byteArr === 'string') {
            bytes = getByteArrayFromString(byteArr)
        }
        store.dispatch(BRIDGE_TYPES.ACTIONS.VIEW_DOCUMENT, {
            contentType,
            filename,
            data: byteArrayToBase64(bytes)
        });
    } else {
        let bytes;
        if (typeof byteArr === 'string') {
            bytes = getByteArrayFromString(byteArr)
        } else {
            bytes = new Uint8Array(byteArr)
        }
        var out = new Blob([bytes], { type: contentType });
        if (contentType === 'application/pdf') {
            if (navigator.userAgent.toLowerCase().indexOf('windows nt') > -1) {
                downloadFile(filename, bytes, contentType)
                windowRef?.close()
            } else {
                openFileInNewTab(out, windowRef || getRedirectionWindow(), 
                    (msg) => {
                    alert(msg);
                }, filename);
            }
        } else {
            downloadFile(filename, bytes, contentType)
            windowRef?.close()
        }
    }
}

export function getByteArrayFromString(byteStr) {
    var byteCharacters = atob(byteStr);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return byteArray;
}

export function byteArrayToBase64( buffer ) {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return window.btoa( binary );
}

export function blobToBase64( blob ) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result.split(',').pop());
        reader.onerror = error => reject(error);
        reader.readAsDataURL(blob);
    })
}

/**
 *  Redirection URL for PDF Files
 *  Use this to show an empty html page with redirection Text
*/
export function getRedirectionHtmlString() {
    return process.env.VUE_APP_API + "/../../images/etc/ksc3/warten.html";
}
