import MERGE_PEOPLE_TYPES from './types';
import { getInitialState } from './index'
import Vue from 'vue';

export default {
	[MERGE_PEOPLE_TYPES.MUTATIONS.LIST_DUPLICATED_PEOPLE_SUCCESS](state, payload) {
		Vue.set(state, 'duplicatedPeople', payload)
	},

	[MERGE_PEOPLE_TYPES.MUTATIONS.LIST_DUPLICATED_PEOPLE_ERROR](state) {
		Vue.set(state, 'duplicatedPeople', [])
	},

	[MERGE_PEOPLE_TYPES.MUTATIONS.LIST_UNIFIABLE_PEOPLE_SUCCESS](state, payload) {
		Vue.set(state, 'unifiablePeople', payload)
	},

	[MERGE_PEOPLE_TYPES.MUTATIONS.LIST_UNIFIABLE_PEOPLE_ERROR](state) {
		Vue.set(state, 'unifiablePeople', [])
	},

	[MERGE_PEOPLE_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },

}
