import SCHADENSMELDUNG_TYPES from './types';
import { getInitialState } from './index';
import Vue from 'vue';

export default {
  [SCHADENSMELDUNG_TYPES.MUTATIONS.RETRIEVE_SCHADENSMELDUNG_SUCCESS](state, payload) {
    state.schadensmeldung = payload;
  },
  [SCHADENSMELDUNG_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },
  [SCHADENSMELDUNG_TYPES.MUTATIONS.SEND_SCHADENSMELDUNG_SUCCESS](state, payload) {
    state.schadensmeldung = Object.assign({}, state.schadensmeldung, payload);
  },
  [SCHADENSMELDUNG_TYPES.MUTATIONS.RESET_SCHADENSMELDUNG](state) {
    state.schadensmeldung = {};
  },
  [SCHADENSMELDUNG_TYPES.MUTATIONS.SEND_BETEILIGTE_SUCCESS](state, payload) {
    state.beteiligte = payload;
  },
  [SCHADENSMELDUNG_TYPES.MUTATIONS.DELETE_BETEILIGTE_SUCCESS](state, payload) {
    state.deletedBeteiligteId = payload;
  },
  [SCHADENSMELDUNG_TYPES.MUTATIONS.SEND_ANHANG_SUCCESS](state, payload) {
    state.lastSentAnhang = payload;
  },  
  [SCHADENSMELDUNG_TYPES.MUTATIONS.DELETE_ANHANG_SUCCESS](state, payload) {
    state.deletedAnhangId = payload;
  },  
  [SCHADENSMELDUNG_TYPES.MUTATIONS.SEND_ZAHLUNG_SUCCESS](state, payload) {
    Vue.set(state.schadensmeldung, 'zahlungen', payload || []);
  },
  [SCHADENSMELDUNG_TYPES.MUTATIONS.DELETE_ZAHLUNG_SUCCESS](state, payload) {
    if(payload && state?.schadensmeldung?.zahlungen?.length) {
      const index = state?.schadensmeldung?.zahlungen?.findIndex(zahlung => payload === zahlung.id);
      if(index > -1) {
        state?.schadensmeldung?.zahlungen?.splice(index, 1);
      }
    }
    state.deletedZahlungId = payload;
  },
  [SCHADENSMELDUNG_TYPES.MUTATIONS.SEND_BESCHAEDIGT_SUCCESS](state, payload) {
    state.lastBeschaedigtId = payload;
  },
  [SCHADENSMELDUNG_TYPES.MUTATIONS.DELETE_BESCHAEDIGT_SUCCESS](state, payload) {
    state.deletedBeschaedigtId = payload;
  },
  [SCHADENSMELDUNG_TYPES.MUTATIONS.GET_CONFIG_DATA_SUCCESS](state, payload) {
    state.configData = payload;
  },
  [SCHADENSMELDUNG_TYPES.MUTATIONS.GET_PDF_DATA_SUCCESS](state, payload) {
    state.schadenPdfData = payload;
  },
  [SCHADENSMELDUNG_TYPES.MUTATIONS.APPEND_SCHADENSMELDUNG_EDITED](state, payload) {
    Vue.set(state, 'schadensmeldungEdited', Object.assign({}, state.schadensmeldungEdited, payload));
  },
  [SCHADENSMELDUNG_TYPES.MUTATIONS.RESET_SCHADENSMELDUNG_EDITED](state) {
    Vue.set(state, 'schadensmeldungEdited', null);
  },
  [SCHADENSMELDUNG_TYPES.MUTATIONS.SET_ATTACHMENTS](state, payload) {
    if (payload?.action === 'clear') {
      state.attachments = []
      return;
    }
    let result = []
    if (payload && state?.attachments?.length) {
      result = state.attachments.concat(payload)
    } else {
      result = payload
    }
    Vue.set(state, 'attachments', result || []);
  },
  [SCHADENSMELDUNG_TYPES.MUTATIONS.BETEILIGTE_EDITED](state, payload) {
    Vue.set(state, 'beteiligteEdited', payload);
  }
}