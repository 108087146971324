<template>
  <div>
    <PageHeaderTitleNavigation :title="pageTitle" />

    <div class="box__container">
      <div class="box__title">Zusatzinformation - Grundsätze über den Umgang mit Interessenskonflikten</div>
      <p>
        Nach § 63 Abs. 2 des Wertpapierhandelsgesetzes (WpHG) und § 13 Abs. 5 Finanzanlagenvermittlungsverordnung (FinVermV) hat sich jedes Wertpapierdienstleistungsunternehmen und Gewerbetreibender nach § 34f Absatz 1 oder § 34 h Absatz 1 der Gewerbeordnung um Vermeidung von Interessenkonflikten zu bemühen und seinen Kunden1) Art und Herkunft möglicher Interessenkonflikte sowie seine Grundsätze zum Umgang mit diesen darzulegen.
      </p>
      <hr>
      <h3 class="my-4">Art und Herkunft möglicher Interessenkonflikte</h3>
      <p>
        <strong>Wo können Interessenkonflikte auftreten?</strong>
        <br>
        Interessenkonflikte im Rahmen der Erbringung unserer Wertpapierdienstleistungen können zwischen dem Finanzanlageberater/-vermittler selber
und seinen Kunden und ggf. zwischen den Mitarbeitern des Finanzanlageberaters/-vermittlers und seinen Kunden und zwischen seinen
Kunden untereinander auftreten
      </p>
      <p>
        <strong>Wodurch können Interessenkonflikte entstehen?</strong>
        <br>
        Wie in jedem gewinnorientiert arbeitenden Unternehmen lassen sich Interessenkonflikte und die daraus resultierende Gefahr einer Beeinträchtigung von Kundeninteressen nicht vollständig ausschließen. Diese können insbesondere folgende Ursachen haben:
      </p>
      <ul>
        <li>eigene unternehmerische Interessen des Finanzanlageberaters/-vermittlers, insbesondere Umsatz- und Gewinnerzielungsbestreben</li>
        <li>die mit dem Kunden ggf. vereinbarte, erfolgsabhängige Vergütung, z.B. durch Eingehung höherer Risiken für das zur Bearbeitung anstehende Vermögen, mit dem Ziel, eine höhere Wertentwicklung und damit ein höheres Gesamthonorar aufgrund der erfolgsabhängigen Komponente zu erzielen</li>
        <li>Annahme von Geld- oder Sachzuwendungen von Seiten Dritter, z.B. Vermittlungs- und Bestandszuwendungen oder Seminarangebote,
          soweit diese nicht an unsere Kunden ausgekehrt werden und nicht von geringem Wert sind</li>
        <li>erfolgsbezogene Vergütung von Geschäftsleitern, Mitarbeitern sowie Gewähr von Geld- oder Sachzuwendungen an diese</li>
        <li>bei der Ausführung von Kundenaufträgen durch das Zusammentreffen von mehreren Kundenaufträgen oder von Kundenaufträgen mit
          eigenen Geschäften des Finanzanlageberaters/-vermittlers (z.B. Eigengeschäften)</li>
        <li>persönliche Geschäfte der Geschäftsleiter, Mitarbeiter und Vermittler des Finanzanlageberaters/-vermittlers oder diesen nahestehenden
          Personen</li>
      </ul>
      <p>
        Ferner könnten Interessenkonflikte im Falle geschäftlicher oder persönlicher Beziehungen des Finanzanlageberaters/-vermittlers, seiner Geschäftsleiter, Mitarbeiter, Vermittler oder verbundener Personen zu Kreditinstituten, Kapitalverwaltungsgesellschaften, Emittenten etc. entstehen.
      </p>
      <p>
        Dies trifft insbesondere bei Kooperationen und Mitwirkung in Aufsichts- oder Beiräten dieser Einrichtung, evtl. Mitwirkung an Emissionen von Finanzinstrumenten und Erlangung von Informationen, die nicht öffentlich bekannt sind (Insiderinformationen) zu.
      </p>
      <p>
        <strong>Interessenkonflikte können dazu führen, dass der Finanzanlageberater/-vermittler nicht im bestmöglichen Interesse des Kunden handelt. Hierdurch besteht die Möglichkeit, dass der Kunde einen finanziellen Nachteil erleiden kann.</strong>
      </p>
      <p>
        Um zu vermeiden, dass sachfremde Interessen zum Beispiel die Beratung oder die Auftragsausführung beeinflussen, haben sich alle Geschäftsleiter, Mitarbeiter sowie andere relevante Personen des Finanzanlageberaters/-vermittlers auf hohe ethische Standards verpflichtet. Der Finanz- anlageberater/-vermittler erwartet jederzeit Sorgfalt und Redlichkeit, rechtmäßiges und professionelles Handeln, die Beachtung von Marktstandards und insbesondere immer die Beachtung des Kundeninteresses.
      </p>
      <hr>
      <h3 class="my-4">Im Einzelnen ergreift der Finanzanlageberater/-vermittler unter anderem folgende Maßnahmen:</h3>
      <p><strong>Allgemeine organisatorische Maßnahmen</strong></p>
      <ul>
        <li>Implementierung organisatorischer Vorkehrungen zum Schutz der Kundeninteressen</li>
        <li>ggf. Offenlegungs- und Zustimmungspflichten für Mitarbeiter bei bestimmten geschäftlichen oder persönlichen Beziehungen</li>
      </ul>
      <p><strong>Konkrete Maßnahmen in Bezug auf die identifizierten Interessenkonflikte</strong></p>
      <ul>
        <li>Einführung eines an die Bedürfnisse der Kunden angepassten Beratungs- und Vermittlungsverfahrens, um Interessenkonflikte infolge
          eigener Umsatzinteressen des Finanzanlageberaters/-vermittlers zu vermeiden und die Vermittlung von Finanzprodukten an Kunden mit
          nicht dazu passenden Anlagezielen und Risikoneigungen zu verhindern</li>
        <li>Unmissverständliche Offenlegung von Existenz, Art und Umfang der Zuwendungen, die der Finanzanlageberater/-vermittler von Dritten erhält, insbesondere von Vermittlungs- und Bestandsprovisionen</li>
        <li>Bearbeitung der Kundenaufträge in der Reihenfolge ihres Eingangs; Bearbeitung von Kundenaufträgen vor den Eigengeschäften</li>
        <li>Regelmäßige Schulungen der Mitarbeiter in Bezug auf mögliche Interessenkonflikte, deren Vermeidung oder Reduzierung</li>
      </ul>
      <p>
        Einige der oben aufgezeigten Maßnahmen zur Bekämpfung der Interessenkonflikte reichen nicht aus, um mit hinreichender Sicherheit zu gewährleisten, dass die Interessen des Kunden nicht geschädigt werden. In diesem Umfang kann das Risiko bestehen, dass der Kunde einen finanziellen Nachteil dadurch erleidet, dass das Institut wegen eines Interessenkonflikts zu dessen Ungunsten handelt.
      </p>
      <p>
        Trotz der vom Finanzanlageberater/-vermittler durchgeführten laufenden Kontrollen seiner Mitarbeiter kann es vorkommen, dass diese Personen aufgrund von persönlichem Fehlverhalten die getroffenen Maßnahmen zur Bewältigung der Interessenkonflikte bewusst umgehen oder fahrlässig nicht beachten und dass diese Vergehen vom Finanzanlageberater/-vermittler unentdeckt bleiben. Es ist beispielsweise denkbar, dass diese Personen bewusst oder unbewusst:
      </p>
      <ul>
        <li>Kundenaufträge nicht in der zeitlichen Reihenfolge des Eingangs bearbeiten, etwa um einen bestimmten Kunden zu bevorzugen</li>
        <li>Mitarbeiter unverhältnismäßig hohe Risiken für einen Kunden eingehen, obwohl dazu wegen der angemessenen Vergütung kein finanzieller
          Anreiz besteht</li>
        <li>Mitarbeiter eine Transaktion in einem Wertpapier tätigen, um sich unter Nutzung der vorhandenen Insiderinformationen einen persönlichen
          Vorteil zu verschaffen</li>
        <li>Mitarbeiter direkt Zuwendungen, die über einen geringen Wert hinausgehen, von Dritten annehmen, obwohl es ihnen untersagt ist</li>
      </ul>
      <p>
        <strong>
          Weitere Interessenkonflikte, die sich nicht vermeiden lassen sollten, werden wir gegenüber den betroffenen Kunden vor einem Geschäftsabschluss oder einer Beratung offenlegen.
        </strong>
      </p>
      <p>
        <small>
          Stand: 15.06.2020_2
        </small>
      </p>
    
    </div>
  </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import CORE_TYPES from '@/store/core/types';
import { mapGetters } from 'vuex';

const DEFAULT_TITLE = 'Zusatzinfo Interessenskonflikt'

export default {
  computed: {
    pageTitle() {
      return this.$appNavigation?.currentMenu?.label || DEFAULT_TITLE
    },
  },
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
  }
}
</script>

<style scoped>

</style>