import MAKLER_TEXTE_TYPES from './types';

export default {

  [MAKLER_TEXTE_TYPES.GETTERS.MAKLER_TEXT_BEREICH](state) {
    return [ ...state.maklerTextBereich || [], ];
  },

  [MAKLER_TEXTE_TYPES.GETTERS.SELECTED_BEREICH](state) {
    const { maklerTextBereich, selectedBereich, } = state;
    const selected = maklerTextBereich.find(bereich => bereich.id === selectedBereich);
    return { ...selected || {}, };
  },

  [MAKLER_TEXTE_TYPES.GETTERS.LIST_BY_BEREICH](state) {
    return (bereich) => [ ...state.listByBereich?.[bereich] || [], ];
  },

}
