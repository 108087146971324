import WINDOW_CONTROL_TYPES from './types';
import Vue from 'vue';

export default {

  [WINDOW_CONTROL_TYPES.MUTATIONS.RESET_STATE](state) {
    const { opened, } = state;
    Object.keys(opened).forEach(id => opened?.[id]?.windowOpened?.close());
    Vue.set(state, 'opened', {});
  },

  [WINDOW_CONTROL_TYPES.MUTATIONS.ADD_OPENED](state, { id, url, windowOpened, }) {
    Vue.set(state, 'opened', {
      ...state.opened || {},
      [id]: { url, windowOpened, },
    });
  },

  [WINDOW_CONTROL_TYPES.MUTATIONS.REMOVE_OPENED](state, { id, }) {
    const { opened, } = state;
    if(id in opened) {
      Vue.delete(state.opened, id);
    }
  },

}
