import KENNZAHLEN_TYPES from './types';
import CORE_TYPES from '@/store/core/types';
import axios from 'axios';

import { ALL_DEPOTS } from '@/components/depotpositions/types';

const config = {
  defaultSpinner: true
};


export default {

  async [KENNZAHLEN_TYPES.ACTIONS.FIND_KENNZAHLEN_DATA]({ getters, commit, }, { depotId }) {
    const kennzahlenData = getters[KENNZAHLEN_TYPES.GETTERS.KENNZAHLEN_DATA];

    if(!depotId || Object.keys(kennzahlenData).length && !kennzahlenData?.timeoutMessage) return ;

    const params = {
      depotId: depotId === ALL_DEPOTS ? -1 : depotId,
    };
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/kennzahlen/get`, { ...config, params, });
    if(response?.data) {
      commit(KENNZAHLEN_TYPES.MUTATIONS.FIND_KENNZAHLEN_DATA_SUCCESS, { ...response?.data, depotId, });
    }
  },

}
