var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.title || _vm.selected || _vm.visibleTitleActions.length
        ? _c(
            "div",
            { staticClass: "container topline" },
            [
              _vm.selected
                ? _c("InputCheckBoxItem", {
                    staticStyle: {
                      "margin-right": "4px",
                      display: "inline-block"
                    },
                    attrs: {
                      value: _vm.areAllSelected,
                      indeterminate: _vm.areOnlySomeSelected
                    },
                    on: { input: _vm.onSelectAll }
                  })
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "box__title ellipsis",
                  staticStyle: { flex: "1 1" }
                },
                [_vm._v(_vm._s(_vm.title))]
              ),
              _vm.visibleTitleActions.length
                ? _c(
                    "transition",
                    { attrs: { name: "header-actions-animation" } },
                    [
                      _c(
                        "BaseContextMenu",
                        {
                          key: _vm.titleActionsKey,
                          staticClass: "table--header-actions",
                          attrs: { tid: "tidOverview" }
                        },
                        _vm._l(_vm.visibleTitleActions, function(action) {
                          return _c(
                            "ContextMenuItem",
                            { key: action.key },
                            [
                              _c(
                                action.component,
                                _vm._b(
                                  {
                                    tag: "component",
                                    attrs: { tid: "tidOverview" + action.key },
                                    on: { action: _vm.onAction }
                                  },
                                  "component",
                                  action,
                                  false
                                )
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._l(_vm.records, function(row) {
        return _c(
          "div",
          {
            key: row.id,
            staticClass: "topline",
            staticStyle: { display: "flex" }
          },
          [
            _vm.selected
              ? _c("InputCheckBoxItem", {
                  staticStyle: {
                    "margin-right": "4px",
                    display: "inline-block"
                  },
                  attrs: { value: _vm.isCheckmarked(row.id) },
                  on: {
                    input: function($event) {
                      return _vm.onSelect(row.id)
                    }
                  }
                })
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "topline-content",
                class: { "checkbox-content": _vm.selected }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "container",
                    class: { "text-bold": row.unread }
                  },
                  [
                    _vm._t(
                      "title",
                      function() {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "ellipsis",
                              class: { bold: !row.unread }
                            },
                            [
                              row.isTitleLink
                                ? _c(
                                    "a",
                                    {
                                      staticStyle: { color: "inherit" },
                                      on: {
                                        click: function($event) {
                                          return _vm.$emit("title", row)
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(row.title))]
                                  )
                                : _c("span", [_vm._v(_vm._s(row.title))])
                            ]
                          )
                        ]
                      },
                      null,
                      row
                    ),
                    _c(
                      "div",
                      { staticClass: "topline-side-content" },
                      [
                        row.hasAttachment
                          ? _c("ph-paperclip", {
                              staticStyle: { "margin-right": "3px" },
                              attrs: { size: 18 }
                            })
                          : _vm._e(),
                        _c("div", { staticClass: "overflow" }, [
                          _vm._v(
                            _vm._s(
                              _vm.dateToString(row.date) ||
                                _vm.dateTimeToString(row.dateTime)
                            )
                          )
                        ]),
                        _vm.someRowHasActions
                          ? [
                              row.actions && row.actions.length
                                ? _c("ActionCell", {
                                    attrs: {
                                      row: row,
                                      column: "actions",
                                      tid: "tidOverview"
                                    },
                                    on: { action: _vm.onAction }
                                  })
                                : [
                                    _c("div", {
                                      staticClass: "hide-on-small-screen",
                                      staticStyle: { width: "20px" }
                                    }),
                                    _c("div", {
                                      staticClass: "hide-on-large-screen",
                                      staticStyle: { width: "24px" }
                                    })
                                  ]
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  ],
                  2
                ),
                _c("div", { staticClass: "container" }, [
                  _c(
                    "div",
                    { staticClass: "subject ellipsis" },
                    [
                      _vm._t(
                        "subject",
                        function() {
                          return [
                            row.isSubjectLink
                              ? _c(
                                  "a",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.$emit("subject", row)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(row.subject))]
                                )
                              : _c("span", [_vm._v(_vm._s(row.subject))])
                          ]
                        },
                        null,
                        row
                      )
                    ],
                    2
                  ),
                  _c(
                    "div",
                    { staticClass: "status ellipsis" },
                    [_vm._t("status", null, null, row)],
                    2
                  )
                ])
              ]
            )
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }