<template>
  <div>
    <div class="text-bold">
      {{ label }}
    </div>

    <div class="besprochene-component-buttons">
      <BaseButton
        isSecondary
        label="Neue Position"
        :disabled="isDataReadOnly"
        @click="handleAction(headerFields.BUTTON_WERTPAPIERE_BESPROCHEN_NEU)"
      />
      <BaseButton
        style="margin: 0px"
        isSecondary
        label="Aktuelle Depotpositionen einfügen"
        :disabled="isDataReadOnly"
        @click="handleAction(headerFields.BUTTON_WERTPAPIERE_BESPROCHEN_UPDATE)"
      />
    </div>
    <GhostLoading v-if="loading" type="table" />
    <div v-if="wertpapierPanels.length">
      <div
        v-for="(item, index) in wertpapierPanels"
        :key="index"
      >
        <BesprocheneItem
          :antragId="antragId"
          :antragTyp="antragTyp"
          :item="item"
          :data="data"
          :isDataReadOnly="isDataReadOnly"
          @change="handleChange($event)"
          @save="saveAntrag"
          @removePosition="removePosition($event)"
          @updateIsin="updateIsin($event)"
        />
      </div>
    </div>
    <NoData v-else-if="!loading"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ANTRAG_TYPES from "@/store/antrag/types";
import BaseButton from "@/components/core/BaseButton.vue";
import BesprocheneItem from "./BesprocheneItem.vue";
import GhostLoading from "@/components/core/loading/GhostLoading.vue";
import NoData from "@/components/core/NoData.vue";

export default {
  mixins: [],
  components: {
    BaseButton,
    BesprocheneItem,
    GhostLoading,
    NoData,
  },
  data() {
    return {
      loading: false,
    };
  },
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
      required: true,
    },
    antragId: {
      type: String,
    },
    antragName: {
      type: String,
    },
    antragTyp: {
      type: String,
      default: "UNBEKANNT",
    },
    config: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      default: {},
    },
    isDataReadOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["change"],
  computed: {
    ...mapGetters({
      positionsAll: ANTRAG_TYPES.GETTERS.POSITIONS,
    }),
    positions() {
      return (this.positionsAll && this.positionsAll[this.antragId]) || {};
    },
    positionFields() {
      return (this.config && this.config.positionFields) || {};
    },
    headerFields() {
      if (!this.positionFields || !this.positionFields.headerFields) {
        return {};
      }
      return this.positionFields.headerFields.reduce((result, headerField) => {
        result[headerField.id] = headerField;
        return result;
      }, {});
    },
    wertpapierPanels() {
      return this.positionFields && this.positionFields.PANEL_WERTPAPIER || []
    },
  },
  methods: {
    handleAction(field) {
      if (field.type === "BUTTON" && field.config && !this.isDataReadOnly) {
        if (field?.id === 'BUTTON_WERTPAPIERE_BESPROCHEN_UPDATE') {
          let payload = {
            id: this.antragId,
            antragsdatenId: this.data?.antragsdatenId,
            beratungsMappeId: this.data?.beratungsMappeId,
            lagerstelle: this.$route.params.lagerstelle,
            antragsName: this.$route.params.antragsName,
            action: 'BUTTON_WERTPAPIERE_BESPROCHEN_UPDATE',
          };
          this.$store.commit(ANTRAG_TYPES.MUTATIONS.SET_ANTRAG_RELOAD_PAYLOAD, payload);
        }
        let values = {
          INTERNAL_ATTRIBUTE_ACTION: field.config["INTERNAL_ATTRIBUTE_ACTION"],
        };
        this.loading = true;
        this.handleChange(values);
        this.saveThenReload()
      }
    },
    handleChange(value) {
      this.$emit("change", value);
    },
    saveThenReload() {
      this.saveAntrag().then(() => this.reloadAntrag())
    },
    saveAntrag() {
      return this.$store.dispatch(ANTRAG_TYPES.ACTIONS.SAVE_ANTRAG, {
        id: this.antragId,
        lagerstelle: this.$route.params.lagerstelle,
      });
    },
    reloadAntrag() {
      this.$store.dispatch(ANTRAG_TYPES.ACTIONS.RELOAD_ANTRAG, {
        id: this.antragId,
      }).finally(() => this.loading = false)
    },
    removePosition(field) {
      if (field?.id) {
        const index = field.id.substring("hidden_wpb_isin".length);
        const deleteAction = "BUTTON_WPB_ENTRY_DELETE" + index;
        const test = this.data[deleteAction];
        const values = {
          INTERNAL_ATTRIBUTE_ACTION: deleteAction,
          DELETE_SICHER: "1",
        };
        this.handleChange(values);
        this.saveThenReload();
      }
    },
    updateIsin(data) {
      if (data?.id) {
        const index = data.id.substring("hidden_wpb_isin".length);
        const values = {
          INTERNAL_ATTRIBUTE_ACTION: "actionloadwertpapier",
          PARAM_REFERRER_DATA: "B" + index,
          paramisin: data.isin,
        };
        this.handleChange(values);
        this.saveAntrag();
      }
    },
  },
};
</script>

<style scoped>
.besprochene-component-buttons {
  display: flex;
  margin-top: 1.5rem !important;
  justify-content: space-between;
  margin-bottom: 1.5rem !important;
  flex-wrap: wrap;
  }
</style>
