<template>
  <transition name="modal-sheet-fade-down" @after-leave="handleClosed()">
    <div v-show="isOpen" class="base-sheet-modal__container is-modal-component" :class="{ 'is-open': isOpen, }">
      <div class="base-sheet-modal__backdrop" @click="handleCloseBackdrop($event)">
        <div class="base-sheet-modal__content-wrap">
          <div class="base-sheet-modal__content">
            <div class="base-sheet-modal__header">
              <div class="base-sheet-modal__header--wrap">
                <div class="base-sheet-modal__header--title">
                  <slot name="modalTitle">{{ modalTitle }}</slot>
                </div>

                <div class="base-sheet-modal__swipe-close" @touchstart="swipeStart($event)" @touchend="swipeEnd($event)">
                  <div class="base-sheet-modal__swipe-close--icon"></div>
                </div>

                <div class="base-sheet-modal__header--toolbar">
                  <slot name="headerToolbar" />
                  <BaseButton isLink class="base-sheet-modal__header--close" @click="close()">Abbrechen</BaseButton>
                </div>
              </div>
            </div>
            <div class="base-sheet-modal__body" :style="modalBodyStyle">
              <slot />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import BaseButton from '@/components/core/BaseButton.vue';

const OPEN_MODAL_CLASS = 'modal-sheet-open';
const BACKDROP_CSS_CLASS = 'base-sheet-modal__backdrop';
const BACKDROP_STATIC = 'static';
const BASE_MODAL_IS_OPEN_SELECTOR = '.base-sheet-modal__container.is-open';
const IS_MODAL_COMPONENT_OPEN_SELECTOR = '.is-modal-component.is-open';
const HEADER_EL_SELECTOR = '.base-sheet-modal__header';
const CONTENT_EL_SELECTOR = '.base-sheet-modal__content';
const CONTENT_WRAP_EL_SELECTOR = '.base-sheet-modal__content-wrap';
const MODAL_MIN_BODY_MAX_HEIGHT = 100;

const MAX_SWIPE_TOP = 0;
const MIN_SWIPE_BOTTOM_TO_CLOSE = -20;

const MODAL_Z_INDEX_INITIAL = 1000;

export default {
  name: 'BaseSheetModal',
  components: {
    BaseButton,
  },
  props: {
    autoOpen: {
      type: Boolean,
      default: false,
    },
    modalTitle: {
      type: String,
      default: 'Modal title',
    },
    backdrop: {
      type: String,
    },
  },
  emits: ['open', 'close', 'closed'],
  data() {
    return {
      isOpen: false,
      maxModalBodyHeight: null,
      touch: {
        active: false,
        startPageY: 0,
      },
    };
  },
  computed: {
    modalBodyStyle() {
      return {
        'max-height': `calc(${this.maxModalBodyHeight}px - 8px)`,
      };
    },
  },
  watch: {
    autoOpen: {
      handler(value) {
        if(value) {
          this.$nextTick(() => this.open());
        } else {
          this.$nextTick(() => this.close());
        }
      },
      immediate: true,
    },
  },
  methods: {
    open() {
      this.$set(this, 'isOpen', true);
      this.$nextTick(() => {
        document.querySelector('html').classList.add(OPEN_MODAL_CLASS);
        this.bringToFront();
      });
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
      this.$emit('open');
    },
    close() {
      if (this.isOpen) {
        this.$set(this, 'isOpen', false);

        const modalContainerEl = this.$el;
        if(modalContainerEl) {
          modalContainerEl.style.zIndex = null;
        }
        window.removeEventListener('resize', this.handleResize);

        this.$emit('close');
      }
    },
    handleClosed() {
      this.removeHtmlClasses();

      this.$emit('closed');
    },
    handleCloseBackdrop(event) {
      if(this.backdrop === BACKDROP_STATIC || !event ||
        !event.target.classList.contains(BACKDROP_CSS_CLASS)) {
        return ;
      }

      this.close();
    },
    removeHtmlClasses() {
      const baseSheetModalOpened = document.querySelectorAll(BASE_MODAL_IS_OPEN_SELECTOR);
      const hasRemainingModalOpen = [ ...baseSheetModalOpened || [] ].filter(el => el !== this.$el).length > 0;
      if(!hasRemainingModalOpen) {
        document.querySelector('html').classList.remove(OPEN_MODAL_CLASS);
      }
    },
    handleResize() {
      this.$nextTick(this.prepareContent);
    },
    prepareContent() {
      if(!this.$el || !this.$el.querySelector) {
        return ;
      }

      const { innerHeight } = window;

      const contentWrapEl = this.$el.querySelector(CONTENT_WRAP_EL_SELECTOR);
      const headerEl = this.$el.querySelector(HEADER_EL_SELECTOR);

      const contentWrapOffsetY = contentWrapEl ? contentWrapEl.getBoundingClientRect().y : 0;
      const headerTotalHeight = headerEl ? headerEl.getBoundingClientRect().height : 0;
      const maxBodyHeight = innerHeight - contentWrapOffsetY - headerTotalHeight;
      this.maxModalBodyHeight = maxBodyHeight > MODAL_MIN_BODY_MAX_HEIGHT ? maxBodyHeight : MODAL_MIN_BODY_MAX_HEIGHT;
    },
    swipeStart(event) {
      this.touch.active = true;
      this.touch.startPageY = event.changedTouches[0].pageY;
      document.addEventListener('touchmove', this.swipeMove);
    },
    swipeMove(event) {
      if(!this.touch.active) return;

      const currentPageY = event.changedTouches[0].pageY;
      const swipeSize = this.touch.startPageY - currentPageY;
      const contentEl = this.$el.querySelector(CONTENT_EL_SELECTOR);
      const swipeSizeChecked = swipeSize > MAX_SWIPE_TOP ? MAX_SWIPE_TOP : swipeSize;
      contentEl.style.cssText = `
        transform: translateY(${swipeSizeChecked * -1}px);
      `;
    },
    swipeEnd(event) {
      const currentPageY = event.changedTouches[0].pageY;
      const swipeSize = this.touch.startPageY - currentPageY;
      const contentEl = this.$el.querySelector(CONTENT_EL_SELECTOR);

      this.touch.active = false;
      this.touch.startPageY = 0;
      document.removeEventListener('touchmove', this.swipeMove);

      if(swipeSize < MIN_SWIPE_BOTTOM_TO_CLOSE) {
        contentEl.style.cssText = `
          transition: transform .1s ease;
          transform: translateY(100%);
        `;
        setTimeout(() => {
          this.close();
          contentEl.style.cssText = null;
        });
      } else {
        contentEl.style.cssText = `
          transition: transform .1s ease;
          transform: translateY(0);
        `;
      }
    },
    bringToFront() {
      const modalContainerEl = this.$el;
      const modalOpenedEls = modalContainerEl?.parentNode?.querySelectorAll(IS_MODAL_COMPONENT_OPEN_SELECTOR);
      const zIndexes = [ ...modalOpenedEls, ].map(el => el.style.zIndex || MODAL_Z_INDEX_INITIAL);
      const maxZIndex = Math.max(...zIndexes);
      modalContainerEl.style.zIndex = maxZIndex + 1;
    },
  },
  mounted() {
    document.body.appendChild(this.$el);
  },
  destroyed() {
    this.removeHtmlClasses();
    if(this.$el && this.$el.parentNode === document.body) {
      document.body.removeChild(this.$el);
    }
  },
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.base-sheet-modal__container {
  color: var(--color-text);
  padding: 0;
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100.1%;

  .base-sheet-modal__backdrop {
    background-color: rgba(0,0,0,0.1);
    position: fixed;
    height: 100%;
    width: 100%;
  }

  .base-sheet-modal__content-wrap {
    margin: 40px 0 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 40px);
    pointer-events: none;
  }

  .base-sheet-modal__content {
    background-color: var(--color-box);
    border-radius: 4px 4px 0 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    max-height: 100%;
    min-height: 33.33%;
    pointer-events: all;

    &::after {
      background: var(--color-box);
      content: "";
      display: block;
      width: 100%;
      height: 60px;
      position: absolute;
      left: 0;
      bottom: -60px;
    }

    .base-sheet-modal__header {
      margin: 0;
      min-height: 16px;
      padding: 8px 12px 4px;

      .base-sheet-modal__header--wrap {
        border-bottom: 1px solid #ccc;
        display: flex;
        justify-content: space-between;
        padding: 4px 0 8px;
      }

      .base-sheet-modal__header--title {
        display: flex;
        align-items: center;
        font-weight: bold;
        padding: 6px 0;
      }

      .base-sheet-modal__swipe-close {
        position: absolute;
        top: -16px;
        left: 0;
        width: calc(100% - 80px);
        margin: 0 40px;
        padding: 16px 0 0;
        user-select: none !important;

        .base-sheet-modal__swipe-close--icon {
          background: var(--color-text);
          border-radius: 4px;
          margin: 6px auto;
          width: 40px;
          height: 6px;
        }
      }

      .base-sheet-modal__header--toolbar {
        flex: 0 0 auto;
        margin: 0 0 0 12px;
      }

      .base-sheet-modal__header--close {
        padding-right: 0;
      }
    }

    .base-sheet-modal__body {
      padding: 8px 12px 16px;
      overflow-y: auto;
      overflow-x: hidden;
      max-height: calc(100vh - 110px);
      max-height: calc(100svh - 110px);
    }
  }
}

/** Mobile Native Context */
.app--is-mobile-native-context {
  .base-sheet-modal__container {
    .base-sheet-modal__header {
      .base-sheet-modal__header--wrap {
        padding-bottom: 4px;
      }
    }
  }
}

/** Animation */
.modal-sheet-fade-down-enter-active,
.modal-sheet-fade-down-leave-active {
  transition: opacity .3s ease;
}
.modal-sheet-fade-down-enter,
.modal-sheet-fade-down-leave-to {
  opacity: 0;
}
.modal-sheet-fade-down-enter-to,
.modal-sheet-fade-down-leave {
  opacity: 1;
}
.modal-sheet-fade-down-enter-active .base-sheet-modal__content,
.modal-sheet-fade-down-leave-active .base-sheet-modal__content {
  transition: transform .3s ease;
}
.modal-sheet-fade-down-enter .base-sheet-modal__content,
.modal-sheet-fade-down-leave-to .base-sheet-modal__content {
  transform: translateY(100%);
}
.modal-sheet-fade-down-enter-to .base-sheet-modal__content,
.modal-sheet-fade-down-leave .base-sheet-modal__content {
  transform: translateY(0);
}
</style>
