<template>
  <div>
    <PageHeaderTitleNavigation title="Menüstruktur Einstellungen" />

    <div 
      v-for="(context, index) in contexts" :key="index" 
      class="box__container" 
      :ref="context.id" 
    >
      <GhostLoading v-if="loading" type="table" :title="context.title" />
      <Table 
        v-else 
        :title="context.title" 
        :headerActions="headerActionsTable"
        :headers="headers" 
        :rows="rowsByContext[context.id]" 
        hidePagination
        @headerAction-ADD="add(context.id)"
        @action-DELETE="remove($event)"
      >
        <template #menuStructure="row">
          <ComboBox 
            :value="row.menuStructure" 
            :values="menuStructureValuesByContext[context.id]" 
            @change="onChange(row, 'menuStructure', $event)" 
          />
        </template>
        <template #substructureMenuStructure="row">
          <ComboBox 
            :value="row.substructureMenuStructure" 
            :values="menuStructureValuesByContext[context.id]" 
            @change="onChange(row, 'substructureMenuStructure', $event)" 
          />
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MENU_STRUCTURE_TYPES from '@/store/menuStructure/types';
import CORE_TYPES from "@/store/core/types";

import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import Table from '@/components/table2/Table.vue';
import { TextColumn, SlotColumn, ActionColumn, ConfirmedAction } from '@/components/table2/table_util';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import InputField from '@/components/core/forms/InputField.vue';
import ComboBox, { buildValue } from '@/components/core/forms/ComboBox.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';

import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';
import { makeQueryParam, addParamToURL } from '@/helpers/utils-helper';

import { CONTEXTS, MAP_CONFIG_CONTEXT_LABEL, SYSTEM_ID } from '@/components/core/menu/menuStructure/menu-structure-utils';

export default {
  components: {
    PageHeaderTitleNavigation, 
    Table, 
    GhostLoading, 
    InputField, 
    ComboBox, 
    InputToggleSwitch, 
  },
  data() {
    return {
      loading: false,
      contexts: CONTEXTS.map(context => ({
        id: context,
        title: MAP_CONFIG_CONTEXT_LABEL[context],
      })),
    };
  },
  computed: {
    ...mapGetters({
      menuStructures: MENU_STRUCTURE_TYPES.GETTERS.MENU_STRUCTURES, 
      menuStructureConfigs: MENU_STRUCTURE_TYPES.GETTERS.MENU_STRUCTURE_CONFIGS, 
      genericSelectionFn: CORE_TYPES.GETTERS.GENERIC_SELECTION_FN, 
    }),
    menuStructureValuesByContext() {
      const { menuStructures } = this;

      const valuesByContext = context => menuStructures
        ?.filter(ms => ms.context === context)
        ?.map(ms => buildValue(ms.id, ms.label));

      return CONTEXTS
        .reduce((acc, context) => ({
          ...acc,
          [context]: [
            buildValue(SYSTEM_ID, 'System'), 
            ...valuesByContext(context), 
          ],
        }), []);
    },
    headerActionsTable() {
      return [
        PageHeaderSimpleAction('ADD', 'hinzufügen').withAsPrimary(() => true),
      ];
    },
    headers() {
      return {
        lockedLeft: [
          TextColumn('maklernr', 'Vermittler'),
        ],
        center: [
          SlotColumn('menuStructure', 'Menüstruktur'),
          SlotColumn('substructureMenuStructure', 'Unterstruktur'),
        ],
        lockedRight: [
          ActionColumn('actions'),
        ],
      };
    },
    rowsByContext() {
      const { menuStructureConfigs } = this;

      const tempStyle = { 
        fontWeight: 'bold', 
      };

      const rowsByContext = context => menuStructureConfigs
        ?.filter(config => config.configContext === context)
        ?.map(row => ({
            ...row, 
            customRowStyle: { 
              ...(row.tempId ? tempStyle : {}), 
            }, 
            actions: [ 
              ConfirmedAction('DELETE', 'PhTrash', 'Löschen', 'Sind Sie sicher, dass Sie diese Einstellung entfernen möchten?'), 
            ], 
          }));

      return CONTEXTS
        .reduce((acc, context) => ({
          ...acc, 
          [context]: [ ...rowsByContext(context) ], 
        }), []);
    },
  },
  methods: {
    async findMenuStructureConfig() {
      try {
        this.loading = true;
        await this.$store.dispatch(MENU_STRUCTURE_TYPES.ACTIONS.FIND_MENU_STRUCTURE_CONFIG);
      } finally {
        this.loading = false;
      }
    },
    async findMenuStructures() {
      await this.$store.dispatch(MENU_STRUCTURE_TYPES.ACTIONS.FIND_MENU_STRUCTURES);
    },
    add(configContext) {
      const { path } = this.$route;
      const back = addParamToURL(path, makeQueryParam({ selectedConfigContext: configContext }));
      this.$router.push({ 
        path: '/communication/mailcomposer-customer-search/broker/broker', 
        query: { 
          back, 
          hideTabs: true, 
          useGenericSelection: true, 
        },
      })
    },
    addSelectedItems() {
      const { 
        selectedConfigContext : configContext, 
        usersselected = '', 
        genericSelectionUUID, 
      } = this.$route.query;

      if (!configContext || !genericSelectionUUID || (usersselected+'') !== 'true') {
        return;
      }

      const { menuStructureConfigs, genericSelectionFn } = this;
      genericSelectionFn(genericSelectionUUID)
        ?.forEach(participant => {
          const maklernr = participant.id;
          const savedConfig = menuStructureConfigs.find(c => c.maklernr === maklernr && c.configContext === configContext);
          const config = savedConfig ?? {
            configContext, 
            maklernr, 
            menuStructure: SYSTEM_ID, 
            substructureMenuStructure: SYSTEM_ID, 
          };
          this.$store.commit(MENU_STRUCTURE_TYPES.MUTATIONS.ADD_MENU_STRUCTURE_CONFIGS_EDITED, config);
        });

      this.$store.commit(CORE_TYPES.MUTATIONS.REMOVE_GENERIC_SELECTION, genericSelectionUUID);

      this.$scrollIntoView(this.$refs[configContext]?.[0]);
    },
    async remove(row) {
      if (row.id) {
        await this.$store.dispatch(MENU_STRUCTURE_TYPES.ACTIONS.REMOVE_MENU_STRUCTURE_CONFIG, row);
      }

      this.$store.commit(MENU_STRUCTURE_TYPES.MUTATIONS.REMOVE_MENU_STRUCTURE_CONFIGS_EDITED, row);
    },
    onChange(config, field, value) {
      const edited = {
        ...config, 
        [field]: value, 
      };
      this.$store.commit(MENU_STRUCTURE_TYPES.MUTATIONS.ADD_MENU_STRUCTURE_CONFIGS_EDITED, edited);
    },
    async save() {
      await this.$store.dispatch(MENU_STRUCTURE_TYPES.ACTIONS.SAVE_MENU_STRUCTURE_CONFIG);
    },
  },
  mounted() {
    this.findMenuStructureConfig()
      .then(() => this.addSelectedItems());
    this.findMenuStructures();
  },
  async beforeRouteLeave(to, from, next) {
    await this.save();
    this.$addBreadcrumb({ to, from, label: 'Menüstruktur Einstellungen' });
    next();
  },
}
</script>
