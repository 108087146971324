var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.maklerAntragConfigData && _vm.maklerAntragConfigData.WPV
      ? _c(
          "div",
          { staticClass: "box__container" },
          [
            _c("div", { staticClass: "box__title" }, [
              _vm._v("Wertpapiervermittler iSd § 1 Z 45 WAG 2018")
            ]),
            _c("FormDivider"),
            _c("FormLabel", {
              attrs: {
                label:
                  "Anforderung: Da Sie als Wertpapiervermittler bei 3 Rechtsträgern gemeldet sein können, bitten wir Sie, uns diese bekanntzugeben."
              }
            }),
            _c("InputRadioBoxGroup", {
              attrs: {
                value: _vm.form.INPUT_WPV_BEST1,
                values: _vm.wpvBestValues,
                labelClass: "font-bold",
                disabled: _vm.disabledStatusOK
              },
              on: {
                input: function($event) {
                  return _vm.fieldChanged($event, "INPUT_WPV_BEST1")
                }
              }
            }),
            _c("InputField", {
              attrs: {
                value: _vm.form.INPUT_WPV_TEXT1,
                label: "Name des Rechtsträgers:",
                isComponentHalfSize: "",
                disabled: _vm.form.INPUT_WPV_BEST1 || _vm.disabledStatusOK
              },
              on: {
                change: function($event) {
                  return _vm.fieldChanged($event, "INPUT_WPV_TEXT1")
                }
              }
            }),
            _c("DatePickerField", {
              attrs: {
                label: "seit",
                value: _vm.form.INPUT_WPV_DATUM1,
                isValueAsString: "",
                isComponentHalfSize: "",
                disabled: _vm.form.INPUT_WPV_BEST1 || _vm.disabledStatusOK
              },
              on: {
                change: function($event) {
                  return _vm.fieldChanged($event, "INPUT_WPV_DATUM1")
                }
              }
            }),
            _c("InputField", {
              attrs: {
                value: _vm.form.INPUT_WPV_TEXT2,
                label: "Name des Rechtsträgers:",
                isComponentHalfSize: "",
                disabled: _vm.form.INPUT_WPV_BEST1 || _vm.disabledStatusOK
              },
              on: {
                change: function($event) {
                  return _vm.fieldChanged($event, "INPUT_WPV_TEXT2")
                }
              }
            }),
            _c("DatePickerField", {
              attrs: {
                label: "seit",
                value: _vm.form.INPUT_WPV_DATUM2,
                isValueAsString: "",
                isComponentHalfSize: "",
                disabled: _vm.form.INPUT_WPV_BEST1 || _vm.disabledStatusOK
              },
              on: {
                change: function($event) {
                  return _vm.fieldChanged($event, "INPUT_WPV_DATUM2")
                }
              }
            }),
            _c("InputToggleSwitch", {
              attrs: {
                label:
                  "Ich bestätige hiermit, die Angaben wahrheitsgetreu getätigt zu haben.",
                inLineLabel: true,
                config: { bold: true },
                isComponentHalfSize: "",
                value: _vm.form.INPUT_BEST_WAHR_3,
                disabled: _vm.disabledStatusOK
              },
              on: {
                input: function($event) {
                  return _vm.fieldChanged($event, "INPUT_BEST_WAHR_3")
                }
              }
            })
          ],
          1
        )
      : _vm.maklerAntragConfigData && _vm.maklerAntragConfigData.vgV
      ? _c(
          "div",
          { staticClass: "box__container" },
          [
            _c("div", { staticClass: "box__title" }, [
              _vm._v(
                "Firmenbuchauszug juristische Personen (vgV) oder eingetragenes Einzelunternehmen"
              )
            ]),
            _vm.maklerAntragConfigData.juristischePerson
              ? _c("FormLabel", {
                  attrs: {
                    label:
                      "Anforderung: Bitte übermitteln Sie uns einen aktuellen Firmenbuchauszug (nicht älter als 3 Monate). Sollte der Auszug nicht innerhalb der gesetzten Frist bei uns einlagen, \r\n      wird dieser von FinanzAdmin abgerufen und die Kosten in Höhe von € 20,00 zuzüglich MwSt. von der nächsten Provisionsabrechnung abgezogen."
                  }
                })
              : _vm._e(),
            _vm.maklerAntragConfigData.juristischePerson
              ? _c("InputToggleSwitch", {
                  attrs: {
                    label:
                      "Der Firmenbuchauszug ist beigelegt (ein aktueller Firmenbuchauszug ist von allen Vertriebspartnern (juristische Personen) verpflichtend beizulegen).",
                    inLineLabel: true,
                    isComponentHalfSize: "",
                    value: _vm.form.INPUT_FIRMENBUCHAUSZUG_BEST,
                    disabled: _vm.disabledStatusOK
                  },
                  on: {
                    input: function($event) {
                      return _vm.fieldChanged(
                        $event,
                        "INPUT_FIRMENBUCHAUSZUG_BEST"
                      )
                    }
                  }
                })
              : _vm._e(),
            _vm.maklerAntragConfigData.juristischePerson
              ? _c("ChecklisteDocsUpload", {
                  attrs: {
                    paramId: this.form.PARAM_ID,
                    topic: "Firmenbuchauszug",
                    form: this.form
                  }
                })
              : _vm._e(),
            _c("FormLabel", {
              attrs: {
                label:
                  "Anforderung: Weiters bitten wir Sie um Bekanntgabe, \r\n      bei welchen auch im WAG 2018 Bereich tätigen Firmen Sie (alle Geschäftsleiter) oder Ihre Angestellten auch tätig sind bzw. Funktionen ausüben."
              }
            }),
            _c("InputRadioBoxGroup", {
              attrs: {
                value: _vm.form.INPUT_WAG_BEST,
                values: _vm.wagBestValues,
                labelClass: "font-bold",
                disabled: _vm.disabledStatusOK
              },
              on: {
                input: function($event) {
                  return _vm.fieldChanged($event, "INPUT_WAG_BEST")
                }
              }
            }),
            _c("InputField", {
              attrs: {
                value: _vm.form.INPUT_FIRMENNAME_TEXT,
                label: "Name:",
                isComponentHalfSize: "",
                disabled: _vm.form.INPUT_WAG_BEST || _vm.disabledStatusOK
              },
              on: {
                input: function($event) {
                  _vm.firma2 = true
                },
                change: function($event) {
                  return _vm.fieldChanged($event, "INPUT_FIRMENNAME_TEXT")
                }
              }
            }),
            _c("InputField", {
              attrs: {
                value: _vm.form.INPUT_FIRMENFUNKTION_TEXT,
                label: "Funktion/Tätigkeit:",
                isComponentHalfSize: "",
                disabled: _vm.form.INPUT_WAG_BEST || _vm.disabledStatusOK
              },
              on: {
                change: function($event) {
                  return _vm.fieldChanged($event, "INPUT_FIRMENFUNKTION_TEXT")
                }
              }
            }),
            _c("InputField", {
              attrs: {
                value: _vm.form.INPUT_GEWERBEBERECHTIGUNG_TEXT,
                label: "Art der Gewerbeberechtigung:",
                isComponentHalfSize: "",
                disabled: _vm.form.INPUT_WAG_BEST || _vm.disabledStatusOK
              },
              on: {
                change: function($event) {
                  return _vm.fieldChanged(
                    $event,
                    "INPUT_GEWERBEBERECHTIGUNG_TEXT"
                  )
                }
              }
            }),
            _vm.firma2 || _vm.form.INPUT_FIRMENNAME_TEXT2 != ""
              ? _c("InputField", {
                  attrs: {
                    value: _vm.form.INPUT_FIRMENNAME_TEXT2,
                    label: "Name:",
                    isComponentHalfSize: "",
                    disabled: _vm.form.INPUT_WAG_BEST || _vm.disabledStatusOK
                  },
                  on: {
                    change: function($event) {
                      return _vm.fieldChanged($event, "INPUT_FIRMENNAME_TEXT2")
                    }
                  }
                })
              : _vm._e(),
            _vm.firma2 || _vm.form.INPUT_FIRMENFUNKTION_TEXT2 != ""
              ? _c("InputField", {
                  attrs: {
                    value: _vm.form.INPUT_FIRMENFUNKTION_TEXT2,
                    label: "Funktion/Tätigkeit:",
                    isComponentHalfSize: "",
                    disabled: _vm.form.INPUT_WAG_BEST || _vm.disabledStatusOK
                  },
                  on: {
                    change: function($event) {
                      return _vm.fieldChanged(
                        $event,
                        "INPUT_FIRMENFUNKTION_TEXT2"
                      )
                    }
                  }
                })
              : _vm._e(),
            _vm.firma2 || _vm.form.INPUT_GEWERBEBERECHTIGUNG_TEXT2 != ""
              ? _c("InputField", {
                  attrs: {
                    value: _vm.form.INPUT_GEWERBEBERECHTIGUNG_TEXT2,
                    label: "Art der Gewerbeberechtigung:",
                    isComponentHalfSize: "",
                    disabled: _vm.form.INPUT_WAG_BEST || _vm.disabledStatusOK
                  },
                  on: {
                    change: function($event) {
                      return _vm.fieldChanged(
                        $event,
                        "INPUT_GEWERBEBERECHTIGUNG_TEXT2"
                      )
                    }
                  }
                })
              : _vm._e(),
            _c("FormLabel", {
              attrs: {
                label:
                  "Anforderung: Sollte ein im Firmenbuch eingetragener Geschäftsführer seitens FinanzAdmin nicht bei der FMA gemeldet sein, da dieser nicht im WAG 2018 Bereich tätig ist,\r\n       ist das Formular „Geschäftsleitung nicht im WAG tätig“ seitens dieses Geschäftsführers unterschrieben zu übermitteln."
              }
            }),
            _c("InputRadioBoxGroup", {
              attrs: {
                value: _vm.form.INPUT_VORGELEGT_BEST,
                values: _vm.vorgelegtBestValues,
                labelClass: "font-bold",
                disabled: _vm.disabledStatusOK
              },
              on: {
                input: function($event) {
                  return _vm.fieldChanged($event, "INPUT_VORGELEGT_BEST")
                }
              }
            }),
            _c("ChecklisteDocsUpload", {
              attrs: {
                paramId: this.form.PARAM_ID,
                topic: "Bestätigung",
                form: this.form
              }
            }),
            _c("FormLabel", {
              attrs: {
                label:
                  "Anforderung: Bitte geben Sie alle in der Anlageberatung tätigen Mitarbeiter an."
              }
            }),
            _c("FormLabel", {
              attrs: {
                label: "Folgende Mitarbeiter sind in der Anlageberatung tätig:"
              }
            }),
            _c("InputField", {
              attrs: {
                value: _vm.form.INPUT_MITARBEITER1,
                label: "Name:",
                isComponentHalfSize: "",
                disabled: _vm.disabledStatusOK
              },
              on: {
                input: function($event) {
                  _vm.mitarbeiter2 = true
                },
                change: function($event) {
                  return _vm.fieldChanged($event, "INPUT_MITARBEITER1")
                }
              }
            }),
            _vm.mitarbeiter2 || _vm.form.INPUT_MITARBEITER2 != ""
              ? _c("InputField", {
                  attrs: {
                    value: _vm.form.INPUT_MITARBEITER2,
                    label: "Name:",
                    isComponentHalfSize: "",
                    disabled: _vm.disabledStatusOK
                  },
                  on: {
                    input: function($event) {
                      _vm.mitarbeiter3 = true
                    },
                    change: function($event) {
                      return _vm.fieldChanged($event, "INPUT_MITARBEITER2")
                    }
                  }
                })
              : _vm._e(),
            _vm.mitarbeiter3 || _vm.form.INPUT_MITARBEITER3 != ""
              ? _c("InputField", {
                  attrs: {
                    value: _vm.form.INPUT_MITARBEITER3,
                    label: "Name:",
                    isComponentHalfSize: "",
                    disabled: _vm.disabledStatusOK
                  },
                  on: {
                    input: function($event) {
                      _vm.mitarbeiter4 = true
                    },
                    change: function($event) {
                      return _vm.fieldChanged($event, "INPUT_MITARBEITER3")
                    }
                  }
                })
              : _vm._e(),
            _vm.mitarbeiter4 || _vm.form.INPUT_MITARBEITER4 != ""
              ? _c("InputField", {
                  attrs: {
                    value: _vm.form.INPUT_MITARBEITER4,
                    label: "Name:",
                    isComponentHalfSize: "",
                    disabled: _vm.disabledStatusOK
                  },
                  on: {
                    input: function($event) {
                      _vm.mitarbeiter5 = true
                    },
                    change: function($event) {
                      return _vm.fieldChanged($event, "INPUT_MITARBEITER4")
                    }
                  }
                })
              : _vm._e(),
            _vm.mitarbeiter5 || _vm.form.INPUT_MITARBEITER5 != ""
              ? _c("InputField", {
                  attrs: {
                    value: _vm.form.INPUT_MITARBEITER5,
                    label: "Name:",
                    isComponentHalfSize: "",
                    disabled: _vm.disabledStatusOK
                  },
                  on: {
                    change: function($event) {
                      return _vm.fieldChanged($event, "INPUT_MITARBEITER5")
                    }
                  }
                })
              : _vm._e(),
            _c("FormDivider"),
            _c("InputToggleSwitch", {
              attrs: {
                label:
                  "Ich bestätige hiermit, die Angaben wahrheitsgetreu getätigt zu haben.",
                inLineLabel: true,
                config: { bold: true },
                isComponentHalfSize: "",
                disabled: _vm.disabledStatusOK,
                value: true
              }
            })
          ],
          1
        )
      : _c(
          "div",
          [_c("NoData", { attrs: { content: "Keine Daten vorhanden" } })],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }