var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "contact_container" }, [
    _c("div", { staticClass: "contact_wrapper" }, [
      _c("div", { staticClass: "banner" }, [_vm._v("BESCHWERDEMANAGEMENT")]),
      _c("hr"),
      _c("div", { staticClass: "zweispalte" }, [
        _c("div", [
          _c("div", { staticClass: "beschwerde_text" }, [
            _vm._v(
              " lhre Zufriedenheit hat für uns höchste Priorität. Falls Sie dennoch einmal nicht mit unserer Tätigkeit zufrieden sein sollten, haben Sie die Möglichkeit, eine Beschwerde bei uns einzureichen. Erhalten wir von Ihnen eine Beschwerde, bestätigen wir Ihnen unverzüglich deren Eingang und unterrichten Sie über das Verfahren der Beschwerdebearbeitung sowie die ungefähre Bearbeitungszeit. Sollten wir feststellen, dass lhre Beschwerde einen Gegenstand betrifft, für den wir nicht zuständig sind, informieren wir Sie umgehend hierüber und teilen Ihnen, soweit uns dies möglich ist, die zuständige Stelle mit. Wir werden lhre Beschwerde umfassend prüfen und uns bemühen, diese schnellstmöglich zu beantworten. Sollte dies einmal nicht möglich sein, unterrichten wir Sie über die Gründe der Verzögerung und darüber, wann die Prüfung voraussichtlich abgeschlossen sein wird. Auf Wunsch erteilen wir Ihnen alle Benachrichtigungen und Informationen schriftlich. Sofern wir lhrem Anliegen nicht oder nicht vollständig nachkommen können, erläutern wir Ihnen die Gründe hierfür und weisen Sie auf etwaig bestehende Möglichkeiten hin, wie Sie Ihr Anliegen weiter verfolgen können. "
            )
          ]),
          _c("div", { staticClass: "zweifields" }, [
            _c(
              "div",
              [
                _c("InputField", {
                  ref: "vornameInp",
                  attrs: { label: "Vorname: " },
                  model: {
                    value: _vm.credentials.vorname,
                    callback: function($$v) {
                      _vm.$set(_vm.credentials, "vorname", $$v)
                    },
                    expression: "credentials.vorname"
                  }
                }),
                _c("InputField", {
                  ref: "emailInp",
                  attrs: { label: "E-Mail: " },
                  model: {
                    value: _vm.credentials.email,
                    callback: function($$v) {
                      _vm.$set(_vm.credentials, "email", $$v)
                    },
                    expression: "credentials.email"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              [
                _c("InputField", {
                  ref: "nachnameInp",
                  attrs: { label: "Name: " },
                  model: {
                    value: _vm.credentials.nachname,
                    callback: function($$v) {
                      _vm.$set(_vm.credentials, "nachname", $$v)
                    },
                    expression: "credentials.nachname"
                  }
                }),
                _c("InputField", {
                  ref: "telefonInp",
                  attrs: { label: "Telefon: ", inputmode: "tel" },
                  model: {
                    value: _vm.credentials.telefon,
                    callback: function($$v) {
                      _vm.$set(_vm.credentials, "telefon", $$v)
                    },
                    expression: "credentials.telefon"
                  }
                })
              ],
              1
            )
          ]),
          _c("div", [
            _c("div", { staticClass: "nachricht_label" }, [
              _vm._v("Nachricht:")
            ]),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.content.inhalt,
                  expression: "content.inhalt"
                }
              ],
              staticClass: "inputtext__input",
              attrs: { rows: "3" },
              domProps: { value: _vm.content.inhalt },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.content, "inhalt", $event.target.value)
                }
              }
            })
          ]),
          _c(
            "div",
            { staticClass: "checkbox_plus_label" },
            [
              _c("input-check-box-item", {
                model: {
                  value: _vm.makeRead,
                  callback: function($$v) {
                    _vm.makeRead = $$v
                  },
                  expression: "makeRead"
                }
              }),
              _c("span", {
                staticStyle: { display: "inline-block", width: ".3em" }
              }),
              _c(
                "div",
                [
                  _vm._v(" Ich stimme den "),
                  _c("router-link", { attrs: { to: "datenschutz" } }, [
                    _vm._v("Datenschutzbestimmungen")
                  ]),
                  _vm._v(" zu. ")
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "to_the_left" },
            [
              _c(
                "BaseButton",
                {
                  attrs: {
                    disabled:
                      _vm.validation.isInvalid("credentials") ||
                      _vm.validation.isInvalid("content")
                  },
                  on: { click: _vm.sendKontakt }
                },
                [_vm._v("Absenden")]
              ),
              _vm.isIntern
                ? _c("div", { staticClass: "fc-alert fc-alert-warning" }, [
                    _vm._v("Die Sendung ist für die Intern deaktiviert.")
                  ])
                : _vm._e()
            ],
            1
          )
        ]),
        _c("div", { staticClass: "makler_info" }, [_c("makler-info")], 1)
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }