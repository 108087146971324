var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "config-edit-vertical-stepper__container box__container" },
    [
      _c("VerticalStepperEditable", {
        ref: "stepper",
        attrs: {
          stepType: _vm.stepType,
          visible: _vm.showStepper,
          stepperName: _vm.stepperName,
          selectedStepKey: _vm.selectedStep,
          stepperService: _vm.getStepperMediator,
          antragData: _vm.configData,
          showSaveSpinner: _vm.showSaveSpinner,
          showCollapse: true,
          disabled: _vm.disabled,
          actionsConfig: _vm.actionsConfig
        },
        on: {
          setStep: function($event) {
            return _vm.$emit("setStep", $event)
          },
          setSubstep: function($event) {
            return _vm.$emit("setSubstep", $event)
          },
          collapsed: function($event) {
            return _vm.$emit("collapsed", $event)
          },
          zusammenfassung: function($event) {
            return _vm.$emit("zusammenfassung", $event)
          },
          stepEdited: function($event) {
            return _vm.$emit("stepEdited", $event)
          },
          enablesEditStep: function($event) {
            return _vm.enablesEditStep($event)
          },
          disablesEditStep: function($event) {
            return _vm.disablesEditStep($event)
          },
          removeStep: function($event) {
            return _vm.openRemoveStepConfirmModal($event)
          },
          orderChanged: function($event) {
            return _vm.$emit("orderChanged", $event)
          },
          addStep: function($event) {
            return _vm.$emit("addStep", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }