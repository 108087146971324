import ZUORDNUNG_GESELLSCHAFTEN_BIPRO_TYPES from './types';

export default {
    [ZUORDNUNG_GESELLSCHAFTEN_BIPRO_TYPES.GETTERS.GESELLSCHAFT_KENNUNGEN](state) {
        return state.gesellschaftKennungen;
    },
    [ZUORDNUNG_GESELLSCHAFTEN_BIPRO_TYPES.GETTERS.SEARCH_RESULT](state) {
        return state.searchResult;
    },    
    [ZUORDNUNG_GESELLSCHAFTEN_BIPRO_TYPES.GETTERS.SELECTED_ROW](state) {
        return state.selectedRow;
    },    
}