<template>
  <div>
    <InputRadioBoxGroup v-if="isDialogFVV"
      title="Besitzen Sie Erfahrungen mit kreditfinanzierten Anlagegeschäften?"
      v-model="kreditAktiv" 
      validateUntouched
      :inlineLabelValues="false"
      :disabled="isDisabled || isRefusedPage"
      :values="[ 
        { label: 'Nein', value: 'no' },
        { label: 'Ja', value: 'yes' },
      ]"
      @input="updateStore(getCategory(), 'kreditAktiv', getBooleanValue($event))" 
    />
    <InputField v-if="isDialogFVV && kreditAktiv === 'yes'"
      v-model="kreditText"
      label="Höhe Ihres Kredits"
      isComponentHalfSize
      validateUntouched
      type="currency"
      :disabled="isDisabled || isRefusedPage"
      @change="updateStore(getCategory(), 'kreditText', $event)"
    />
    <InputCheckBoxGroup 
      v-if="!isDialogFVV"
      v-model="form"
      :disabled="isRefusedPage"
      title="In welchem Rahmen konnten Sie Erfahrungen mit Finanzinstrumenten sammeln?"
      :values="[ 
        { label: 'Beruflich', path: 'isProfExperience' },
        { label: 'Privat', path: 'isPrivateExperience' },
      ]"
       @checkBoxToggle="updateStoreCheckBoxGroup(getCategory(), $event)"/>
    <InputCheckBoxGroup
      v-model="form"
      :disabled="isDisabled || isRefusedPage"
      title="Welche Finanzdienstleistung haben Sie bereits in Anspruch genommen?"
      :values="[
        { label: 'Vermögensverwaltung', path: 'isPropertyManagement' },
        { label: 'Anlageberatung', path: 'isInvestConsult' },
        { label: 'Beratungsfreie Anlagegeschäfte', path: 'isFreeInvest' },
      ]"
        @checkBoxToggle="updateStoreCheckBoxGroup(getCategory(), $event)"/>
  </div>
</template>

<script>

import InputCheckBoxGroup from '@/components/core/forms/checkbox/InputCheckBoxGroup.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import InputField from '@/components/core/forms/InputField.vue';
import { mapGetters } from 'vuex';
import ANLEGERPROFIL_TYPES from '@/store/anlegerprofil/types';
import anlegerprofilMixin from '@/mixins/anlegerprofil/anlegerprofil-mixin.js';

export default {
  mixins: [anlegerprofilMixin],
  components: {
    InputCheckBoxGroup,
    InputRadioBoxGroup,
    InputField,
  },
  data() {
    return {
      form: {
        isProfExperience: '',
        isPrivateExperience: '',
        isPropertyManagement: '',
        isInvestConsult: '',
        isFreeInvest: '',
      },
      kreditAktiv: null,
      kreditText: null,
    };
  },
  computed: {
    ...mapGetters({
      data: ANLEGERPROFIL_TYPES.GETTERS.ANLEGERPROFIL,
    }),
    isRefusedPage() {
      return this._isRefusedPage(this.data, 'personInfo');
    },
  },
  mounted() {
    this.patchForm();
    this.updateWarnings();
  },
  methods: {
    patchForm() {
      const data = this.getPerson(this.data, this.getPersonType);
      if (this.form && data) {
        this.form = {
          isProfExperience: data.isProfExperience,
          isPrivateExperience: data.isPrivateExperience,
          isPropertyManagement: data.isPropertyManagement,
          isInvestConsult: data.isInvestConsult,
          isFreeInvest: data.isFreeInvest,
        };
        this.kreditAktiv = data.kreditAktiv == null ? null : (data.kreditAktiv ? 'yes' : 'no');
        this.kreditText = data.kreditText;
      }
    },
  },
}
</script>

<style scoped>

</style>