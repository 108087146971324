var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ghost-loading__container",
      class: {
        "ghost-loading__container--inline": _vm.inline,
        "ghost-loading__overflow-hidden": _vm.overflowHidden
      }
    },
    _vm._l(_vm.repeat, function(item) {
      return _c(
        "div",
        {
          key: item,
          staticClass: "ghost-loading",
          class: { box__container: _vm.useBoxContainer },
          style: _vm.style
        },
        [
          _vm.title
            ? _c("div", { staticClass: "box__title" }, [
                _vm._v(_vm._s(_vm.title))
              ])
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "ghost-loading--wrap",
              class: {
                "ghost-loading--wrap__half--size": _vm.isHalfSize
              }
            },
            [
              _vm.type === _vm.TYPE_TABLE
                ? _vm._l(_vm.tableConfig.rows, function(row) {
                    return _c("Block", {
                      key: row,
                      attrs: { type: "table-row" }
                    })
                  })
                : _vm.type === _vm.TYPE_CARD_VERTICAL
                ? [
                    _c("Block", { attrs: { type: "image" } }),
                    _c("Block", { attrs: { type: "title" } }),
                    _c("Block"),
                    _c("Block"),
                    _c("Block")
                  ]
                : _vm.type === _vm.TYPE_CARD_HORIZONTAL
                ? [
                    _c("div", { staticClass: "row my-0" }, [
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [_c("Block", { attrs: { type: "image" } })],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-10" },
                        [
                          _c("Block", { attrs: { type: "title" } }),
                          _c("Block"),
                          _c("Block"),
                          _c("Block"),
                          _c("Block")
                        ],
                        1
                      )
                    ])
                  ]
                : _vm.type === _vm.TYPE_PARAGRAPH
                ? [
                    _c("Block", { attrs: { type: "title" } }),
                    _c("Block"),
                    _c("Block"),
                    _c("Block")
                  ]
                : _vm.type === _vm.TYPE_BLOCK
                ? [
                    _c("Block", {
                      attrs: {
                        width: _vm.blockConfig.width,
                        height: _vm.blockConfig.height,
                        opacity: _vm.blockConfig.opacity
                      }
                    })
                  ]
                : _vm.type === _vm.TYPE_CUSTOM
                ? [_vm._t("default")]
                : _vm.type === _vm.TYPE_INPUT
                ? [
                    _c("Block", { attrs: { width: "50%" } }),
                    _c("Block", { attrs: { type: "form-input" } })
                  ]
                : _vm.type === _vm.TYPE_TITLE
                ? [_c("Block", { attrs: { type: "title" } })]
                : _vm._e()
            ],
            2
          )
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }