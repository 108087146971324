import WERTPAPIERE_ALLE_KUNDEN_TYPES from './types';
import { getInitialState } from './index';
import Vue from 'vue';

export default {
  [WERTPAPIERE_ALLE_KUNDEN_TYPES.MUTATIONS.GET_INIT_DATA_SUCCESS](state, payload) {
      Vue.set(state, 'lagerstellen', payload?.lagerstellen || []);
      Vue.set(state, 'gesellschaften', payload?.gesellschaften || []);
      Vue.set(state, 'kategorien', payload?.kategorien || []);
      Vue.set(state, 'wertpapiere', payload?.wertpapiere || []);
  },
 
  [WERTPAPIERE_ALLE_KUNDEN_TYPES.MUTATIONS.SEARCH_WERTPAPIER_SUCCESS](state, payload) {
      Vue.set(state, 'wertpapiere', payload || []);
  },

  [WERTPAPIERE_ALLE_KUNDEN_TYPES.MUTATIONS.GET_EMAIL_DATA_SUCCESS](state, payload) {
      Vue.set(state, 'emailData', payload);
  },

  [WERTPAPIERE_ALLE_KUNDEN_TYPES.MUTATIONS.LIST_POSITIONS_SUCCESS](state, payload) {
      Vue.set(state, 'positions',payload?.positions || []);
      Vue.set(state, 'emailData', payload?.emailData || {});
      Vue.set(state, 'comboboxValues', Object.assign({}, {aaComboboxValues: payload.aaComboboxValues || {}, 
        tauschInGesellschaftValues: payload.tauschInGesellschaftValues || {}, tauschInWertpapierValues: payload.tauschInWertpapierValues || {}}));
      state.data.dataHasChanged = false;
  },

  [WERTPAPIERE_ALLE_KUNDEN_TYPES.MUTATIONS.UPDATE_EMAIL_DATA_STORE](state, payload = {}) {
      Vue.set(state, 'emailData', Object.assign(state.emailData, payload, { dataHasChanged: true}));
  },

  [WERTPAPIERE_ALLE_KUNDEN_TYPES.MUTATIONS.UPDATE_STORE](state, payload = {}) {
      Vue.set(state, 'data', Object.assign({}, state.data, payload, { dataHasChanged: true}));
  },

  [WERTPAPIERE_ALLE_KUNDEN_TYPES.MUTATIONS.RESET_POSITIONS](state) {
      Vue.set(state, 'positions', []);
      Vue.set(state, 'antraegePdf', null);
      Vue.set(state, 'antraegeEmail', null);
      Vue.set(state, 'tempFileId', '');
      Vue.set(state, 'emailData', Object.assign(state.emailData, { dataHasChanged: false}));
      Vue.set(state, 'data', Object.assign(state.data, { positionsChanged: false}));
  },

  [WERTPAPIERE_ALLE_KUNDEN_TYPES.MUTATIONS.RESET_ANTRAEGE](state) {
      Vue.set(state, 'antraegePdf', null);
      Vue.set(state, 'antraegeEmail', null);
      Vue.set(state, 'emailData', Object.assign(state.emailData, { dataHasChanged: false}));
  },

  [WERTPAPIERE_ALLE_KUNDEN_TYPES.MUTATIONS.CREATE_ANTRAEGE_SUCCESS](state, payload) {
      Vue.set(state, 'antraegePdf', payload?.antraegePdf || []);
      Vue.set(state, 'antraegeEmail', payload?.antraegeEmail || []);
      Vue.set(state, 'tempFileId', payload?.tempFileId || '');
      Vue.set(state, 'emailData', Object.assign(state.emailData, { dataHasChanged: false}));
      Vue.set(state, 'data', Object.assign(state.data, { positionsChanged: false, dataHasChanged: false}));
  },

  [WERTPAPIERE_ALLE_KUNDEN_TYPES.MUTATIONS.UPDATE_POSITION_FROM_MODAL](state, payload) {
    if (payload && Object.keys(payload)?.length) {
      state.positions = state.positions?.map(pos => pos.bgsNr === payload.bgsNr ? payload : pos);
      state.data.positionsChanged = true;
    }
  },
  
  [WERTPAPIERE_ALLE_KUNDEN_TYPES.MUTATIONS.UPDATE_POSITION_FROM_TABLE](state, payload) {
    if (payload && Object.keys(payload)?.length) {
      const index = state.positions?.findIndex(pos => pos.bgsNr === payload.bgsNr);
      if (index >= 0) {
        state.positions[index] = payload;
        state.data.positionsChanged = true;
      }
    }
  },
 
  [WERTPAPIERE_ALLE_KUNDEN_TYPES.MUTATIONS.RESET_STATE](state) {
    Vue.set(state, 'positions', []);
    Vue.set(state, 'comboboxValues', {});
    Vue.set(state, 'antraegePdf', null);
    Vue.set(state, 'antraegeEmail', null);
    Vue.set(state, 'tempFileId', '');
    Vue.set(state, 'emailData', {});
    Vue.set(state, 'data', getInitialState()?.data);

  },
  
}
