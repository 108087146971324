import QUARTALSBERICHTE_TYPES from './types';
import { getInitialState } from './index'
import Vue from 'vue'

export default {
  [QUARTALSBERICHTE_TYPES.MUTATIONS.RETRIEVE_QUARTALSBERICHTE_NODE_SUCCESS](state, payload) {
    state.quartalsberichteNode = payload;
  }, 
  [QUARTALSBERICHTE_TYPES.MUTATIONS.QUARTALSBERICHTE_BERECHNEN_SUCCESS](state, payload) {
    state.berechnenErgebnis = payload;
  },   
}