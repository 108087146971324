export const MUTATION_PREFIX = 'VERSICHERUNGSTARIFE_MUTATIONS_';
export const ACTIONS_PREFIX = 'VERSICHERUNGSTARIFE_ACTIONS_';
export const GETTERS_PREFIX = 'VERSICHERUNGSTARIFE_GETTERS_';

export default {
  MUTATIONS: {
    RETRIEVE_VERSICHERUNGSTARIFE_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_VERSICHERUNGSTARIFE_SUCCESS',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    REMOVE_ROW_SUCCESS: MUTATION_PREFIX + 'REMOVE_ROW_SUCCESS',
    SELECTED_VERSICHERUNGSTARIF: MUTATION_PREFIX + 'SELECTED_VERSICHERUNGSTARIF',
    PERSIST_ROW_SUCCESS: MUTATION_PREFIX + 'PERSIST_ROW_SUCCESS',
    RESET_LIST: MUTATION_PREFIX + 'RESET_LIST',
    VERSICHERUNGSTARIFE_GESELLSCHAFTEN: MUTATION_PREFIX + 'VERSICHERUNGSTARIFE_GESELLSCHAFTEN',
    VERSICHERUNGSTARIFE_SPARTENTYPEN: MUTATION_PREFIX + 'VERSICHERUNGSTARIFE_SPARTENTYPEN',
    RETRIEVE_TARIF_DOKUMENTE_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_TARIF_DOKUMENTE_SUCCESS',
    UPLOAD_TARIF_DOKUMENT_SUCCESS: MUTATION_PREFIX + 'UPLOAD_TARIF_DOKUMENT_SUCCESS',
    REMOVE_DOCUMENT_SUCCESS: MUTATION_PREFIX + 'REMOVE_DOCUMENT_SUCCESS',
    SELECTED_DOCUMENT: MUTATION_PREFIX + 'SELECTED_DOCUMENT',
    RETRIEVE_SPARTE_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_SPARTE_SUCCESS',
    RESET_TARIFE_DOKUMENTE: MUTATION_PREFIX + 'RESET_TARIFE_DOKUMENTE',
    VORGABE_TARIFDATEN_SUCCESS: MUTATION_PREFIX + 'VORGABE_TARIFDATEN_SUCCESS',
    PERSIST_VORGABE_SUCCESS: MUTATION_PREFIX + 'PERSIST_VORGABE_SUCCESS',
  },
  ACTIONS: {
    RETRIEVE_VERSICHERUNGSTARIFE_DATA: ACTIONS_PREFIX + 'RETRIEVE_VERSICHERUNGSTARIFE_DATA',
    REMOVE_ROW: ACTIONS_PREFIX + 'REMOVE_ROW',
    PERSIST_ROW: ACTIONS_PREFIX + 'PERSIST_ROW',
    RETRIEVE_TARIF_DOKUMENTE: ACTIONS_PREFIX + 'RETRIEVE_TARIF_DOKUMENTE',
    UPLOAD_TARIF_DOKUMENT: ACTIONS_PREFIX + 'UPLOAD_TARIF_DOKUMENT',
    REMOVE_DOCUMENT: ACTIONS_PREFIX + 'REMOVE_DOCUMENT',
    RETRIEVE_SPARTE: ACTIONS_PREFIX + 'RETRIEVE_SPARTE',
    VORGABE_TARIFDATEN: ACTIONS_PREFIX + 'VORGABE_TARIFDATEN',
    PERSIST_VORGABE: ACTIONS_PREFIX + 'PERSIST_VORGABE',
  },
  GETTERS: {
    VERSICHERUNGSTARIFE_DATA: GETTERS_PREFIX + 'VERSICHERUNGSTARIFE_DATA',
    SELECTED_VERSICHERUNGSTARIF: GETTERS_PREFIX + 'SELECTED_VERSICHERUNGSTARIF',
    VERSICHERUNGSTARIFE_GESELLSCHAFTEN: GETTERS_PREFIX + 'VERSICHERUNGSTARIFE_GESELLSCHAFTEN',
    VERSICHERUNGSTARIFE_SPARTENTYPEN: GETTERS_PREFIX + 'VERSICHERUNGSTARIFE_SPARTENTYPEN',
    TARIF_DOKUMENTE: GETTERS_PREFIX + 'TARIF_DOKUMENTE',
    SELECTED_DOCUMENT: GETTERS_PREFIX + 'SELECTED_DOCUMENT',
    SPARTE: GETTERS_PREFIX + 'SELECTED_DOCUMENT',
    TARIFDATEN_VORGABE: GETTERS_PREFIX + 'TARIFDATEN_VORGABE',
  }
}