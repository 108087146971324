var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "flex-container" }, [
      _c(
        "div",
        { staticClass: "flex-item-table" },
        [
          _c("Table", {
            attrs: {
              tableData: _vm.tableData,
              cardViewEnabled: false,
              filterEnabled: false,
              exportEnabled: false,
              paginationEnabled: false,
              actions: _vm.tableActions
            },
            on: { "execute-action": _vm.handleTableAction }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex-item-textarea" },
        [
          _c("InputTextArea", {
            ref: "target",
            attrs: { placeholder: _vm.placeholder, autoGrow: false }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }