var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", { attrs: { title: "PDF-Kundendaten" } }),
      _c("BaseFilter", {
        ref: "filter",
        staticClass: "mb-0",
        attrs: {
          title: "zusätzliche Optionen",
          filterId: "pdf_kundendaten_filter",
          configFilter: _vm.filterConfig,
          metadata: _vm.filterOptions,
          defaultOptions: _vm.filterDefaultOptions,
          showSaveButton: ""
        },
        on: { getPDF: _vm.getPDF }
      }),
      _c(
        "BaseButtonsContainer",
        { staticClass: "action-button__container" },
        [
          _c(
            "BaseButton",
            {
              attrs: { isSecondary: "" },
              on: {
                click: function($event) {
                  return _vm.filterZurucksetzen()
                }
              }
            },
            [_vm._v(" Filter zurücksetzen ")]
          ),
          _c(
            "BaseButton",
            {
              on: {
                click: function($event) {
                  return _vm.getPDF(true)
                }
              }
            },
            [_vm._v(" Karteikarte anzeigen ")]
          ),
          _c(
            "BaseButton",
            {
              on: {
                click: function($event) {
                  return _vm.getPDF(false)
                }
              }
            },
            [_vm._v(" Formular anzeigen ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }