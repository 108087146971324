import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export function getInitialState() {
  return {
    filterFieldsDefinition: {},
    courtagetabelleVersicherungList: {},
    courtagetabelleVersicherungDisplayInfo: "",
    menuOptionAccessRule: false,
    lastUpdatedItem: null,
    formMetadata: {},
  }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}