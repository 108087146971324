<template>
  <div>
    <GesellschaftTitleNavigation
      :headerActions="headerActions"
      @action-CREATE_ZWEIGSTELLE="openCreateZweigstelle"
      @action-DELETE_ZWEIGSTELLE="deleteZweigstelle(selectedZweigstelle)"
      @action-CREATE_TARIFGRUPPE="openCreateTarifgruppeModal"
    />

    <StepperForm
      stepType="tab"
      :stepperMediator="tabsStepperMediator"
      :hasFertig="false"
      :selectedStepKey="currentStep"
      :selectedSubstepKey="currentSubstep"
      @set-step="setStep($event)"
      @set-substep="setSubstep($event)"
    >
      <template #contentTemplate>
        <GhostLoading useBoxContainer v-if="loading" type="table" />
        <template v-else-if="currentStep === 'DATEN'">
          <div class="box__container">
            <div class="box__title">Gesellschaftsdaten</div>
            <InputField
              label="ID"
              isComponentHalfSize
              :disabled="!isIntern"
              v-model="daten.id"
            />
            <ComboBox
              label="Gesellschaftsart" 
              isComponentHalfSize
              :sortComboboxValues="false"
              :disabled="!isIntern"
              :values="comboboxSelections.arten"
              v-model="daten.art"
            />
            <InputField
              v-if="isIntern"
              label="Nummer" 
              isComponentHalfSize
              v-model="daten.nummer"
            />
            <template v-if="isIntern && isVersicherung">
              <template v-if="isFA">
                <InputField
                  label="Hauptvermittlernummern"
                  disabled
                  isComponentHalfSize
                  :value="(daten.fremdnrnFA || []).join(', ')"
                />
              </template>
              <template v-else>
                <InputField
                  label="Hauptvermittlernummern AK"
                  disabled
                  isComponentHalfSize
                  :value="(daten.fremdnrnAK || []).join(', ')"
                />
                <InputToggleSwitch
                  v-if="isIntern"
                  label="Unternummern möglich"
                  v-model="daten.unternrnMoeglich"
                />
                <InputField
                  label="Hauptvermittlernummern MFA"
                  disabled
                  isComponentHalfSize
                  :value="(daten.fremdnrnMFA || []).join(', ')"
                />
              </template>
            </template>


            <InputToggleSwitch
              v-if="isIntern"
              label="Inaktiv"
              v-model="daten.inaktiv"
            />
            <InputField
              v-if="isIntern"
              label="Name Kurzbeschreibung" 
              isComponentHalfSize
              v-model="daten.nameKurz"
            />
          </div>
          <div class="box__container" v-if="daten.hauptDaten">
            <GesellschaftPersonenDaten
              v-model="daten.hauptDaten"
              :comboboxSelections="comboboxSelections"
              :nameDisabled="!isIntern"
            />
          </div>
          <div class="box__container" v-if="isIntern || isGesellschaft">
            <ImageUpload
              class="logo-container"
              :image="getImgSrc(daten.logo)"
              title="Logo"
              :loading="loading"
              :config="imageUploadConfig"
              :disabled="loading"
              hasDeleteButton
              @file="uploadImage"
              @change="uploadImage"
              @delete="deleteImage"
            />
          </div>
        </template>
        <template v-else-if="currentStep === 'ZWEIGSTELLEN'">
          <div class="box__container" v-if="selectedZweigstelle">
            <GesellschaftPersonenDaten
              v-model="zweigstellen[selectedZweigstelleIndex]"
              :comboboxSelections="comboboxSelections"
              :nameDisabled="!isIntern"
            />
          </div>
        </template>
        <template v-else-if="currentStep === 'FWW_DATENIMPORT'">
          <div class="box__container">
            <div class="box__title">FWW Datenimport</div>
            <InputToggleSwitch
              label="Name und Homepage durch FWW-Daten überschreiben."
              v-model="daten.fwwForbidChange"
            />
            <div class="text-small">"Die Postanschrift und Kontaktdaten werden in einem Ansprechpartner "Allgemein", Typ "Service" gespeichert."</div>
          </div>
          <div class="box__container">
            <div class="box__title">Anschrift</div>
            <span>{{daten.fwwPlz}} {{daten.fwwOrt}}</span><br>
            <span>{{daten.fwwStrasse}}</span>
          </div>
          <div class="box__container">
            <div class="box__title">Kontakt</div>
            <span>Telefon: {{daten.fwwTelefon}}</span><br>
            <span>Fax: {{daten.fwwFax}}</span><br>
            <span>E-Mail: {{daten.fwwEmail}}</span>
          </div>
        </template>
        <template v-else-if="currentStep === 'DATENVERWALTUNG'">
          <div class="box__container">
            <div class="box__title">Datenverwaltung der Gesellschaft</div>
            <InputField
              label="E-Mail-Adresse zur Benachrichtigung bei automatischem Datenimport" 
              isComponentHalfSize
              v-model="daten.emailBenachrichtigung"
            />
            <InputField
              label="Externe ID (Zertifikateprofis)" 
              isComponentHalfSize
              v-model="daten.externeId"
            />
          </div>
        </template>
        <template v-else-if="currentStep === 'COURTAGE'">
          <div class="box__container">
            <div class="box__title">Courtageabrechnung Versicherungen</div>
            <div class="d-flex">
              <ComboBox
                label="Standard Tarifgruppe" 
                :sortComboboxValues="false"
                :values="comboboxSelections.tarifgruppen"
                v-model="daten.versCourtageTarifgruppe"
              />
              <div class="ml-3 clickable" style="align-self: center">
                <ph-pencil-line v-if="daten.versCourtageTarifgruppe !== 'MSC3_VALUE_OHNE_ZUORDNUNG'" @click="editTarifgruppe()" title="Bearbeiten" :size="20" />
              </div>
            </div>

            <InputTextArea
              label="Bemerkung"
              v-model="daten.bemerkung"
            />
            <InputTextArea
              label="Text Courtagetabelle - der Platzhalter <AB_PROVSATZ> wird durch den aktuellen Provisionssatz des Maklers ersetzt"
              v-model="daten.textCourtagetabelle"
            />
            <InputTextArea
              label="Text Courtagetabelle Versicherung"
              v-model="daten.textCourtagetabelleVers"
            />
          </div>
        </template>
      </template>
    </StepperForm>

    <BaseModal 
      ref="createZweigstelleModal"
      modalTitle="Neue Zweigstelle"
      :confirmDisabled="!newZweigstelle.name"
      @onConfirmButton="createZweigstelle"
    > 
      <GesellschaftPersonenDaten
        v-model="newZweigstelle"
        :comboboxSelections="comboboxSelections"
      />
    </BaseModal>

    <BaseModal 
      ref="createTarifgruppeModal"
      modalTitle="Neue Tarifgruppe"
      :confirmDisabled="!newTarifgruppe.bezeichnung"
      @onConfirmButton="createTarifgruppe"
    > 
      <InputField
        label="Bezeichnung" 
        isComponentHalfSize
        v-model="newTarifgruppe.bezeichnung"
      />
    </BaseModal>

  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import GESELLSCHAFT_TYPES from '@/store/gesellschaft/types';

import axios from 'axios';
import { mapGetters } from 'vuex';
import { base64ToArrayBuffer } from '@/helpers/download-helper.js';
import { makeQueryParam } from '@/helpers/utils-helper';
import { PhPencilLine } from 'phosphor-vue'

import { PageHeaderSimpleAction, PageHeaderConfirmedAction } from '@/components/core/header-title-navigation/page-header-utils';
import GesellschaftPersonenDaten from '@/views/gesellschaft/intern/GesellschaftPersonenDaten.vue'
import GesellschaftTitleNavigation from '@/views/gesellschaft/intern/GesellschaftTitleNavigation.vue'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import StepperForm from '@/components/stepper/StepperForm.vue';
import { StepperMediator } from '@/components/stepper/utils';
import ComboBox from '@/components/core/forms/ComboBox.vue'
import InputField from '@/components/core/forms/InputField.vue'
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue'
import ImageUpload from '@/components/fileUpload/ImageUpload.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import BaseModal from '@/components/core/BaseModal.vue'

const config = {
  defaultSpinner: true
};

export default {
  mixins: [],
  components: {
    GhostLoading,
    StepperForm,
    ComboBox,
    InputField,
    InputToggleSwitch,
    ImageUpload,
    InputTextArea,
    BaseModal,
    GesellschaftPersonenDaten,
    GesellschaftTitleNavigation,
    PhPencilLine,
  },
  data() {
    return {
      daten: {},
      originalDaten: null,
      newZweigstelle: {},
      loading: false,
      currentStep: 'DATEN',
      currentSubstep: null,
      imageUploadConfig: {
        height: '212px',
        fit: 'contain',
      },
      newTarifgruppe: {},
    }
  },
  watch: {
    gesellschaft: {
      handler(newVal) {

        this.daten = newVal.daten || {}

        if (!this.originalDaten && Object.keys(this.daten).length) {
          // Create a deep copy and compare it when trying to save. If no changes were made we don't need to save
          this.originalDaten = JSON.parse(JSON.stringify(this.daten))
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      isFA: CORE_TYPES.GETTERS.IS_FA,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      isGesellschaft: CORE_TYPES.GETTERS.IS_GESELLSCHAFT,
      gesellschaft: GESELLSCHAFT_TYPES.GETTERS.GET_GESELLSCHAFT,
    }),
    comboboxSelections() {
      return this.gesellschaft.comboboxSelections || {}
    },
    tabsStepperMediator() {
      return new StepperMediator(this.tabs);
    },
    tabs() {
      let totalProgress = 1

      let tabs = [
        {stepKey: 'DATEN', label:'Gesellschaftsdaten', totalProgress: totalProgress++},
      ]

      if (this.isVersicherung && this.zweigstellen.length) {
        tabs.push({stepKey: 'ZWEIGSTELLEN', label:'Zweigstellen', totalProgress: totalProgress++, 
          substeps: this.zweigstellen.map(zweigstelle => ({
            substepKey: 'ZWEIGSTELLEN' + zweigstelle.id,
            title: zweigstelle.name,
          }))
        })
      }

      if (this.isIntern) {
        tabs = tabs.concat([
          ...(this.isFww ? [{stepKey: 'FWW_DATENIMPORT', label:'FWW Datenimport', totalProgress: totalProgress++}] : []),
          {stepKey: 'DATENVERWALTUNG', label:'Datenverwaltung', totalProgress: totalProgress++},
          {stepKey: 'COURTAGE', label:'Courtageabrechnung Versicherungen', totalProgress: totalProgress++},
        ])
      }

      return tabs
    },
    isVersicherung() {
      return !!this.gesellschaft.isVersicherung
    },
    zweigstellen() {
      return this.gesellschaft.daten?.zweigstellen || []
    },
    selectedZweigstelle() {
      return this.zweigstellen.find(zweig => this.currentSubstep?.includes(zweig.id))
    },
    selectedZweigstelleIndex() {
      return this.zweigstellen.findIndex(zweig => this.currentSubstep?.includes(zweig.id))
    },
    headerActions() {
      const headerActions = []

      if (this.isIntern) {
        headerActions.push(PageHeaderSimpleAction('CREATE_TARIFGRUPPE', 'Neue Tarifgruppe').withVisible(() => this.currentStep === 'COURTAGE'))
        headerActions.push(PageHeaderSimpleAction('CREATE_ZWEIGSTELLE', 'Neue Zweigstelle').withVisible(() => this.isVersicherung))

        if (this.selectedZweigstelle) {
          headerActions.push(PageHeaderConfirmedAction('DELETE_ZWEIGSTELLE', 'Löschen', 
            'Möchten Sie die Zweigstelle \"' + (this.selectedZweigstelle.name || 'Unbenannt') + '\" wirklich löschen?', 'Zweigstelle löschen', 'Löschen'))
        }
      }

      return headerActions
    },
    isFww() {
      return !!this.gesellschaft.isFww
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    setStep(stepKey){
      if (this.currentStep === stepKey) {
        return
      }

      const newStep = this.tabsStepperMediator.getCurrentStep(stepKey)
      if (newStep.substeps?.length) {
        this.setSubstep({
          stepKey: newStep.stepKey,
          substepKey: newStep.substeps[0].substepKey
        })
      } else {
        this.currentStep = stepKey
        this.currentSubstep = null
      }
    },
    setSubstep(params){
      this.currentStep = params.stepKey;
      this.currentSubstep = params.substepKey;
    },  
    initData() {
      this.loading = true

      this.$store.dispatch(GESELLSCHAFT_TYPES.ACTIONS.GET_GESELLSCHAFT_DATEN, { gesellschaftId: this.$route.params.gesellId })
      .finally(() => this.loading = false)
    },
    getImgSrc(image) {
      if (image && image.data) {
        const file = new Blob([base64ToArrayBuffer(image.data)], { type: image.responseContentType?.mimeType });
        return URL.createObjectURL(new File([file], image.fileName, {type: file.type}));
      }

      return '';
    },
    uploadImage(file) {
      const reader = new FileReader();
      reader.onload = () => {
        let base64string = reader.result.split(",").pop();
        this.gesellschaft.daten.logo = {
          filename: file.name,
          data: base64string
        }
      }
      reader.readAsDataURL(file);
    },
    deleteImage() {
      this.$confirmModal({
        title: 'Logo löschen',
        message: `Möchten Sie das Logo wirklich löschen?`,
        labelButtonConfirm: 'Löschen',
      }).then(() => {
        this.gesellschaft.daten.logo = null
      }).catch(() => {})
    },
    openCreateZweigstelle() {
      this.newZweigstelle = {
        land: this.isFA ? 'Österreich' : 'Deutschland'
      }
      this.$refs.createZweigstelleModal.open()
    },
    createZweigstelle() {
      this.loading = true

      const params = makeQueryParam({ gesellschaftId: this.$route.params.gesellId }, true)

      axios.post(`${process.env.VUE_APP_API}/gesellschaftenService/zweigstelle?${params}`, this.newZweigstelle, config).then(response => {
        if (response && response.data) {
          this.gesellschaft.daten.zweigstellen = [...this.gesellschaft.daten.zweigstellen, response.data]
        } 
      }).finally(() => this.loading = false)
    },
    deleteZweigstelle({id}) {
      this.loading = true

      const params = makeQueryParam({ personId: id }, true)

      axios.delete(`${process.env.VUE_APP_API}/gesellschaftenService/zweigstelle?${params}`, config).then(response => {
        if (response && response.status === 200) {
          this.gesellschaft.daten.zweigstellen = this.zweigstellen.filter(zweigstelle => zweigstelle.id !== id)
        } 
      }).finally(() => this.loading = false)
    },
    openCreateTarifgruppeModal() {
      this.newTarifgruppe = {}
      this.$refs.createTarifgruppeModal.open()
    },
    createTarifgruppe() {

      this.loading = true

      const params = makeQueryParam({ bezeichnung: this.newTarifgruppe.bezeichnung }, true)

      axios.get(`${process.env.VUE_APP_API}/tarifgruppen/create?${params}`, config).then(response => {
        if (response && response.data && response.data.id) {
          this.editTarifgruppe(response.data.id)
        } 
      }).finally(() => this.loading = false)
    },
    editTarifgruppe(tarifgruppeId = this.daten.versCourtageTarifgruppe) {
      // is needed to reload the table if something was edited
      this.$store.commit(GESELLSCHAFT_TYPES.MUTATIONS.GET_GESELLSCHAFT_DATEN_SUCCESS, {})

      this.$addBreadcrumb({
        fullPath: this.$route.fullPath,
        breadcrumb: 'Tarifgruppe',
      });

      this.$router.push(this.$route.path + `/tarifgruppe/${tarifgruppeId}`);
      
    },
    async saveGesellschaft() {
      if (JSON.stringify(this.originalDaten) === JSON.stringify(this.daten)) {
        return
      }
      this.loading = true

      const params = makeQueryParam({ gesellschaftId: this.$route.params.gesellId }, true)

      return axios.post(`${process.env.VUE_APP_API}/gesellschaftenService/daten?${params}`, this.daten, config)
        .finally(() => this.loading = false)
    },
  },
  async beforeRouteLeave(to, from, next) {
    await this.saveGesellschaft();
    next();
  }
}
</script>

<style scoped>
  .logo-container {
    max-width: 344px;
  }
</style>