export const MUTATION_PREFIX = 'QUARTALSBERICHTE_BESTELLUNGEN_MUTATIONS_';
export const ACTIONS_PREFIX = 'QUARTALSBERICHTE_BESTELLUNGEN_ACTIONS_';
export const GETTERS_PREFIX = 'QUARTALSBERICHTE_BESTELLUNGEN_GETTERS_';

export default {
  MUTATIONS: {
    RETRIEVE_BESTELLUNGEN_DATA_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_BESTELLUNGEN_DATA_SUCCESS',
    EXECUTE_ORDERS_SUCCESS: MUTATION_PREFIX + 'EXECUTE_ORDERS_SUCCESS',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
  },
  ACTIONS: {
    RETRIEVE_BESTELLUNGEN_DATA: ACTIONS_PREFIX + 'RETRIEVE_BESTELLUNGEN_DATA',
    EXECUTE_ORDERS: ACTIONS_PREFIX + 'EXECUTE_ORDERS',
  },
  GETTERS: {
    BESTELLUNGEN_DATA: GETTERS_PREFIX + 'BESTELLUNGEN_DATA',
    EXECUTE_ORDERS_RESULT: GETTERS_PREFIX + 'EXECUTE_ORDERS_RESULT',
  }
}