<template>
    <BaseModal ref="modal"
        modalTitle="Einladung akzeptieren"
        :autoClose="false"
        @onConfirmButton="addAccount"
        @onCancelButton="close"
        @onCloseButton="close"
        labelButtonConfirm="Konto hinzufügen"
        :showDefaultButtons="true">

        <InputField label="Login Name" v-model="invitation.ssoName" disabled/>
        <InputField label="temporäres Passwort" v-model="password" type="password"/>
        <div class="color-danger mb-2" v-if="errorStr">{{errorStr}}</div>
        <div>Dieses Passwort wird Ihnen vom Hauptnutzer mitgeteilt, es handelt sich hierbei nicht um Ihr Login Kennwort.</div>

    </BaseModal>
</template>
<script>
import SSO_TYPES from '@/store/sso/types';
import LOG_TYPES from '@/store/log/types'

import { buildMessage } from '@/helpers/log-message-helper';

import BaseModal from '@/components/core/BaseModal.vue';
import InputField from '@/components/core/forms/InputField.vue';

export default {
    components: {
        BaseModal,
        InputField,
    },
    props: {
        invitation: {
            required: true,
        }
    },
    data() {
        return {
            password: "",
            errorStr: "",
        }
    },
    mounted() {
        this.$refs.modal.open();
    },
    methods: {
        addAccount() {
            this.$store.dispatch(SSO_TYPES.ACTIONS.ACCEPT_INVITE, {
                id: this.invitation.id,
                password: this.password,
            }).then(response => {
                if (response?.data?.errorStr) {
                    this.errorStr = response.data.errorStr;
                } else {
                    this.errorStr = null;
                    this.$store.dispatch(SSO_TYPES.ACTIONS.GET_SSO_ACCOUNT_INFO);
                    this.close();
                }
            }).catch(error => {
                this.errorStr = null;
                if (error.response && error.response.status == 403)
                    this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage("Das Passwort war falsch. Bitte überprüfen Sie Ihre Daten.", 'danger'));
                else
                    this.$store.dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "SsoInvitationModal.addAccount", error});
            });
        },
        close() {
            this.errorStr = null;
            this.$emit("close");
            this.$refs.modal.close();
        },
    },
}
</script>
<style scoped>

</style>