<template>
	<div class="box__container">
		<div class="font-strong mb-2">
			Aufzeichnung von Telefongesprächen und sonstiger elektronischer Kommunikation
		</div>

		<InputRadioBoxGroup 
			v-model="form.angabeEkommAufzeichnung"
			@input="fieldChanged()"
			:values="vertragWPValues.pflichtEkomm">
			<template #after-OK>
				<InputToggleSwitch
					@input="fieldChanged()"
					inLineLabel
					v-model="form.angabeEkommAufzeichnungMsc"
					label="Dabei habe(n) ich (wir) Softwareanwendung(en) innerhalb des MSC,"/>
				<InputToggleSwitch
					@input="fieldChanged()"
					inLineLabel
					v-model="form.angabeEkommAufzeichnungExtern"
					label="bzw. sonstige anderweitige externe Softwarelösungen verwendet"/>
			</template>
		</InputRadioBoxGroup>

		<div>
			Insbesondere wurden notwendige Maßnahmen ergriffen, um die Aufzeichnungen gegen die nachträgliche 
			Verfälschung und unbefugte Verwendung zu sichern.
		</div>

		<hr/>

		<div class="font-strong mb-2">
			Im Prüfungszeitraum beauftragte(n) Ich (Wir) für die Anlagenvermittlung und –beratung
		</div>

		<InputRadioBoxGroup 
			v-model="form.personenBeschaeftigtAktiv"
			@input="fieldChanged()"
			:values="vertragWPValues.personenBeschaeftigtAktive">
		</InputRadioBoxGroup>

		<InputTextArea
			label="(bitte geben Sie den Vor- und Zunamen sowie das Geburtsdatum dieser Personen an)"
			@change="fieldChanged()"
			v-model="form.personenBeschaeftigt"/>

		<ComboBox 
			isComponentHalfSize
			label="Bekannte Personen hinzufügen:"
			v-model="form.comboPersonenBeschaeftigt"
			:values="vertragWPValues.maklerPeople"/>

		<div class="mb-2">
			<BaseButton 
				@click="addPerson()"
				isSecondary>
				Hinzufügen
			</BaseButton>
		</div>

		<div class="mb-2">
			* Diese Personen verfügen über einen entsprechenden Sachkundennachweis gemäß § 4 Abs. 1 FinVermV und wurden auf die 
			Einhaltung der §§ 12 bis 18 FinVermV verpflichtet. Entsprechende Erklärungen liegen vor.
		</div>
		<div class="font-strong">
			Innerhalb des Prüfungszeitraumes wurden
		</div>
		<InputRadioBoxGroup 
			v-model="form.hasZweigniederlassung"
			@input="fieldChanged()"
			:values="vertragWPValues.angabeZweigniederlassung">
		</InputRadioBoxGroup>
		<InputTextArea
			label="(Bitte geben sie die vollständige Adresse an.)"
			@change="fieldChanged()"
			v-model="form.zweigniederlassung"/>

		<div class="font-strong">
			Haben sich seit dem 1. Januar {{ form.currentYear }} rechtliche und wirtschaftliche Änderungen im Erlaubnisumfang 
			(z. B. Umzug, Änderung im Erlaubnisumfang, Änderungen der eingesetzten Mitarbeiter) ergeben?
		</div>

		<InputRadioBoxGroup 
			v-model="form.erlaubnisumfangAktiv"
			@input="fieldChanged()"
			:values="vertragWPValues.erlaubnisumfangAktiv">
		</InputRadioBoxGroup>

		<InputTextArea
			label="(bitte geben Sie den Vor- und Zunamen sowie das Geburtsdatum dieser Personen an)"
			@change="fieldChanged()"
			v-model="form.erlaubnisumfangText"/>

	</div>
</template>

<script>
import BaseButton from '@/components/core/BaseButton.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue'
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue'
import axios from 'axios'
import CORE_TYPES from '@/store/core/types.js'
import LOG_TYPES from '@/store/log/types.js'
import VERTRAG_WP_TYPES from '@/store/vertragWP/types.js'
import { mapGetters } from 'vuex';
import { makeQueryParam } from '@/helpers/utils-helper';
import { buildMessageWith } from "@/helpers/log-message-helper";
import validator from "@/mixins/validator";

export default {
	mixins: [validator],

	props: {
		formData: {
			type: Object,
			default: {}
		}
	},

	computed: {
		...mapGetters({
			hinweise: VERTRAG_WP_TYPES.GETTERS.VERTRAG_WP_HINWEIS,
			vertragWPValues: VERTRAG_WP_TYPES.GETTERS.VERTRAG_WP_VALUES,
			apiAddress: CORE_TYPES.GETTERS.API_ADDRESS,
			errors: VERTRAG_WP_TYPES.GETTERS.VERTRAG_WP_ERRORS,
		}),
		errorsCurrentStep() {
			return this.errors?.filter?.(err => err.stepKey === 'contractData3') || []
		}
	},

	watch: {
		formData: {
			handler(newValue) {
				this.loadForm(newValue)
			},
			immediate: true,
		},
		errorsCurrentStep(errors) {
			for (const error of errors) {
				this.$pushErrors('form.' + error.id, error.message)
			}
		}
	},

	data() {
		return {
			form: {}
		}
	},

	validators: {
		form: []
	},

	methods: {
		loadForm(newValue) {
			this.$set(this, 'form', Object.assign({}, newValue))
		},

		async addPerson() {
			try {
				const params = {
					type: 'BESCHAEFTIGT',
					personId: this.form?.comboPersonenBeschaeftigt,
					currentPersonen: this.form?.personenBeschaeftigt
				}

				const queryParams = makeQueryParam(params, true)

				const response = await axios.get(`${this.apiAddress}/vertrag_wp/text_add_person?${queryParams}`)
				
				if (response?.data) {
					if (response?.data?.message) {
						this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessageWith({
							message: response?.data?.message
						}));

					} else {
						this.$set(this.form, 'personenBeschaeftigtAktiv', response.data.personenBeschaeftigtAktiv)
						this.$set(this.form, 'personenBeschaeftigt', response.data.personenBeschaeftigt)
						this.fieldChanged()
					}
				}
			} catch (error) {
				// empty
			}
		},

		fieldChanged() {
			this.$emit('fieldChanged', this.form)
		}
	},

	components: {
		BaseButton,
		InputTextArea,
		ComboBox,
		InputToggleSwitch,
		InputRadioBoxGroup,
	}
}
</script>

<style scoped>

</style>