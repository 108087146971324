import TEMPLATES_EDIT_TYPES from './types';


export default {

  [TEMPLATES_EDIT_TYPES.GETTERS.GET_TEMPLATES_TREE](state) {
    return state.templates;
  },
  [TEMPLATES_EDIT_TYPES.GETTERS.GET_TEMPLATES](state) {
    return state.currentNode;
  },
  [TEMPLATES_EDIT_TYPES.GETTERS.GET_PREVIEW](state) {
    return state.preview;
  },
  [TEMPLATES_EDIT_TYPES.GETTERS.GET_STANDARD_TEMPLATE](state) {
    return state.standard;
  },
  [TEMPLATES_EDIT_TYPES.GETTERS.GET_LETTER_TEMPLATES](state) {
    return state.letterTemplates;
  },
  [TEMPLATES_EDIT_TYPES.GETTERS.GET_LETTER_TEMPLATE_EDIT](state) {
    return state.letterTemplate;
  },
  [TEMPLATES_EDIT_TYPES.GETTERS.GET_WORD_TEMPLATE_EDIT](state) {
    return state.wordTemplate;
  },
  [TEMPLATES_EDIT_TYPES.GETTERS.GET_LETTER_CATEGORIES](state) {
    return state.letterCategories;
  },
  [TEMPLATES_EDIT_TYPES.GETTERS.GET_BACKGROUND_TEMPLATE](state) {
    return state.backgroundTemplate;
  },
  [TEMPLATES_EDIT_TYPES.GETTERS.HTML_SHORTCUTS](state) {
    return state.htmlShortcuts;
  },

}
