import ZUSAETZLICHE_INFORMATIONEN_TYPES from './types';
import CORE_TYPES from '../core/types';
import axios from 'axios';
import { buildMessage, } from '../../helpers/log-message-helper';
import * as utils from '../../helpers/commonfunctions';

export default {
  [ZUSAETZLICHE_INFORMATIONEN_TYPES.ACTIONS.GET_ZUSAETZLICHE_DATEN]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/customerFilters/getZusaetzlicheDaten';

      const tableType = payload?.tableType;

      if (tableType) {
        const seqParam = payload?.seq ? '&seq='+payload.seq : '';
        serviceUrl = `${serviceUrl}?tableType=${tableType}${seqParam}`;
      }

      axios.post(rootState.core.apiAddress + serviceUrl, payload.customers, config)
        .then((response) => {
          commit(ZUSAETZLICHE_INFORMATIONEN_TYPES.MUTATIONS.GET_ZUSAETZLICHE_DATEN_SUCCESS, response.data);

          if (tableType == 'MAIN_TABLE') {
            commit(ZUSAETZLICHE_INFORMATIONEN_TYPES.MUTATIONS.UPDATE_MAIN_TABLE_ROWS, response.data?.mainTableRows);
          } else {
            commit(ZUSAETZLICHE_INFORMATIONEN_TYPES.MUTATIONS.UPDATE_SECONDARY_TABLE_ROWS, response.data?.secondaryTableRows);
          }

          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });



  },

  [ZUSAETZLICHE_INFORMATIONEN_TYPES.ACTIONS.GET_ALLE_STRUKTUR_ZUSAETZLICHE_INFOS]({ getters, dispatch, commit, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/customerFilters/getAlleStrukturZusaetzlicheInfos';

      axios.get(rootState.core.apiAddress + serviceUrl, config)
        .then((response) => {
          commit(ZUSAETZLICHE_INFORMATIONEN_TYPES.MUTATIONS.GET_ALLE_STRUKTUR_ZUSAETZLICHE_INFOS_SUCCESS, response.data);
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },  

  [ZUSAETZLICHE_INFORMATIONEN_TYPES.ACTIONS.NEW_ZUSAETZLICH_DATEN]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/customerFilters/newZusaetzlichDaten';

      const inputZusaetzlicheDaten = payload?.inputZusaetzlicheDaten;

      if (inputZusaetzlicheDaten) {
        serviceUrl = `${serviceUrl}?inputZusaetzlicheDaten=${inputZusaetzlicheDaten}`;
      }

      axios.post(rootState.core.apiAddress + serviceUrl, payload.customers, config)
        .then((response) => {
          commit(ZUSAETZLICHE_INFORMATIONEN_TYPES.MUTATIONS.NEW_ZUSAETZLICH_DATEN_SUCCESS, response.data);
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });



  },

  [ZUSAETZLICHE_INFORMATIONEN_TYPES.ACTIONS.UPDATE_OBJECT_ZUSAETZLICHE_TABELLE]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/customerFilters/updateObjectZusaetzlicheTabelle';

      axios.post(rootState.core.apiAddress + serviceUrl, payload, config)
        .then((response) => {
          commit(ZUSAETZLICHE_INFORMATIONEN_TYPES.MUTATIONS.UPDATE_OBJECT_ZUSAETZLICHE_TABELLE_SUCCESS, response.data);
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });



  },  

  [ZUSAETZLICHE_INFORMATIONEN_TYPES.ACTIONS.DELETE_ZUSAETZLICHE_DATEN]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/customerFilters/deleteZusaetzlicheDaten';

      const kundennr = payload?.kundennr;

      if (kundennr) {
        serviceUrl = `${serviceUrl}?kundennr=${kundennr}`;
      }

      const zusaetzlicheInfo = payload?.zusaetzlicheInfo;

      if (zusaetzlicheInfo) {
        serviceUrl = `${serviceUrl}&zusaetzlicheInfo=${zusaetzlicheInfo}`;
      }      

      axios.delete(rootState.core.apiAddress + serviceUrl, config)
        .then((response) => {
          commit(ZUSAETZLICHE_INFORMATIONEN_TYPES.MUTATIONS.DELETE_ZUSAETZLICHE_DATEN_SUCCESS, response.data);
          resolve(response?.data);
        })
        .catch((error) => {
          reject(error);
        });
    });



  },


  

}