var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.editMode
        ? _c(
            "BaseButton",
            {
              staticClass: "mb-16px",
              on: {
                click: function($event) {
                  return _vm.neuObject()
                }
              }
            },
            [_vm._v("Neues Objekt hinzufügen")]
          )
        : _vm._e(),
      _c(
        "div",
        [
          _vm.tableData.records.length
            ? _c("Table", {
                attrs: {
                  tableId: "1bfd6aa4-c4fd-11eb-8529-0242ac130003",
                  tableData: _vm.tableData,
                  actions: _vm.tableActions,
                  cardViewEnabled: false,
                  filterEnabled: false,
                  exportEnabled: false,
                  rowsPerPage: _vm.tableData.records.length,
                  paginationEnabled: false
                },
                on: {
                  "execute-action": function($event) {
                    return _vm.handleTableAction($event)
                  }
                }
              })
            : _c("div", [_vm._v("Keine Daten")])
        ],
        1
      ),
      _c("BaseModal", {
        ref: "beschaedigtModal",
        attrs: { showDefaultButtons: false },
        on: {
          close: function($event) {
            return _vm.cancelBeschaedigtEditing()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "modalTitle",
            fn: function() {
              return [
                _vm._v(
                  _vm._s(
                    _vm.beschaedigtInserting === true
                      ? "Beschädigtes Objekt"
                      : "Beschädigtes Objekt Bearbeiten"
                  )
                )
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _c("InputField", {
                  attrs: {
                    label: "Zugehöriger Schadensfall",
                    disabled: true,
                    type: "text",
                    value: _vm.sparte
                  }
                }),
                _c("InputTextArea", {
                  attrs: { label: "Beschreibung", validateUntouched: true },
                  model: {
                    value: _vm.beschaedigt_form.schadenbeschreibung,
                    callback: function($$v) {
                      _vm.$set(_vm.beschaedigt_form, "schadenbeschreibung", $$v)
                    },
                    expression: "beschaedigt_form.schadenbeschreibung"
                  }
                }),
                _vm.istKFZSparte
                  ? _c("InputField", {
                      attrs: { label: "KFZ Kennzeichen" },
                      model: {
                        value: _vm.beschaedigt_form.kennz_geschkfz,
                        callback: function($$v) {
                          _vm.$set(_vm.beschaedigt_form, "kennz_geschkfz", $$v)
                        },
                        expression: "beschaedigt_form.kennz_geschkfz"
                      }
                    })
                  : _vm._e()
              ]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c(
                  "BaseButton",
                  {
                    staticClass: "mr-16px",
                    attrs: { isSecondary: "", disabled: _vm.loading },
                    on: { click: _vm.closeBeschaedigtModal }
                  },
                  [_vm._v("Abbrechen")]
                ),
                _c(
                  "BaseButton",
                  {
                    attrs: { disabled: _vm.loading },
                    on: {
                      click: function($event) {
                        return _vm.beschaedigtSpeichern()
                      }
                    }
                  },
                  [
                    _vm._v(" Beschädigtes Objekt speichern "),
                    _vm.loading ? _c("AnimatedSpinner") : _vm._e()
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "BaseModal",
        {
          ref: "beschaedigtDeleteModal",
          attrs: { modalTitle: "Eintrag löschen" },
          on: {
            onConfirmButton: function($event) {
              return _vm.deleteBeschaedigt(_vm.beschaedigtToDelete)
            },
            close: function($event) {
              return _vm.clearBeschaedigtToDelete()
            }
          }
        },
        [
          _vm.beschaedigtToDelete
            ? _c("div", [
                _vm._v(
                  ' "' +
                    _vm._s(_vm.beschaedigtToDelete.beschreibung) +
                    '" wirklich löschen? '
                )
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }