import EINGEGANGENE_DOKUMENTE_TYPES from './types';
import CORE_TYPES from '../core/types';
import axios from 'axios';

//FIXME: serialize array to fieldname=array[0]&fieldname=array[1]...
const paramsSerializer = {
  indexes: null
}
export default {
  async [EINGEGANGENE_DOKUMENTE_TYPES.ACTIONS.GET_EINGEGANGENE_DOKUMENTE]({ commit, rootState }, queryParams) {
    const config = {
      defaultSpinner: true
    };

    await axios.get(rootState.core.apiAddress + '/eingegangeneDokumente',  { ...config, params: queryParams }).then(response => {
      if (response && response.data) {
        commit(EINGEGANGENE_DOKUMENTE_TYPES.MUTATIONS.UPDATE_EINGEGANGENE_DOKUMENTE, response.data);
      }      

    })
  },
  async [EINGEGANGENE_DOKUMENTE_TYPES.ACTIONS.GET_SIGNATURES] ({commit, rootState}, queryParams) {
    const config = {
      defaultSpinner: true
    };

    await axios.get(rootState.core.apiAddress + '/eingegangeneDokumente/signatures',  { ...config, params: queryParams, paramsSerializer: paramsSerializer }).then(response => {
      if (response && response.data) {
        commit(EINGEGANGENE_DOKUMENTE_TYPES.MUTATIONS.UPDATE_SIGNATURES, response.data);
      }      
    })
  },
  async [EINGEGANGENE_DOKUMENTE_TYPES.ACTIONS.GET_EINGEGANGENES_DOKUMENT_RESULT] ({commit, rootState}, queryParams) {
    const config = {
      defaultSpinner: true
    };
    await axios.get(rootState.core.apiAddress + '/eingegangeneDokumente/eingegangenesDokument',  { ...config, params: queryParams, paramsSerializer: paramsSerializer }).then(response => {
      if (response && response.data) {
        commit(EINGEGANGENE_DOKUMENTE_TYPES.MUTATIONS.UPDATE_EINGEGANGENES_DOKUMENT_RESULT, response.data);
      }      
    })
  },

  [EINGEGANGENE_DOKUMENTE_TYPES.ACTIONS.POST_EINGEGANGENES_DOKUMENT] ({commit, rootState, getters}, queryParams) {
    const config = {
      defaultSpinner: true
    };
    axios.post(rootState.core.apiAddress + '/eingegangeneDokumente/eingegangenesDokument', null, { ...config, params: queryParams}).then(response => {
      if(response && response.data) {
        let eingegangenesDokument = getters[EINGEGANGENE_DOKUMENTE_TYPES.GETTERS.EINGEGANGENES_DOKUMENT_RESULT]
        eingegangenesDokument.eingegangeneDokumente =  eingegangenesDokument.eingegangeneDokumente.map(dok => dok.id === response.data.id ? response.data : dok);
        commit(EINGEGANGENE_DOKUMENTE_TYPES.MUTATIONS.UPDATE_EINGEGANGENES_DOKUMENT_RESULT, eingegangenesDokument);
      }
    });
  }
}