var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "modal",
    attrs: { modalTitle: "Honorare bearbeiten", showDefaultButtons: false },
    on: { close: _vm.onClose },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function() {
          return [
            _c("DatePickerField", {
              attrs: {
                label: "Datum",
                isValueAsString: "",
                validateUntouched: ""
              },
              model: {
                value: _vm.kundeHonorareForm.datum,
                callback: function($$v) {
                  _vm.$set(_vm.kundeHonorareForm, "datum", $$v)
                },
                expression: "kundeHonorareForm.datum"
              }
            }),
            _c("InputField", {
              attrs: { label: "Text", type: "text", validateUntouched: "" },
              model: {
                value: _vm.kundeHonorareForm.text,
                callback: function($$v) {
                  _vm.$set(_vm.kundeHonorareForm, "text", $$v)
                },
                expression: "kundeHonorareForm.text"
              }
            }),
            _c("InputRadioBoxGroup", {
              attrs: { values: _vm.useFixbetragValues },
              on: { input: _vm.resetUseFixbetragValues },
              model: {
                value: _vm.kundeHonorareForm.useFixbetrag,
                callback: function($$v) {
                  _vm.$set(_vm.kundeHonorareForm, "useFixbetrag", $$v)
                },
                expression: "kundeHonorareForm.useFixbetrag"
              }
            }),
            _c("TimePicker", {
              attrs: {
                label: "Zeit (hh:mi)",
                disabled: _vm.isUseFixbetragEnable,
                validateUntouched: "",
                ignoreDefaultPopUpTime: ""
              },
              on: { change: _vm.calcKundeHonorare },
              model: {
                value: _vm.kundeHonorareForm.stundenDauer,
                callback: function($$v) {
                  _vm.$set(_vm.kundeHonorareForm, "stundenDauer", $$v)
                },
                expression: "kundeHonorareForm.stundenDauer"
              }
            }),
            _c("InputField", {
              attrs: { label: "Rundungstundensatz (Minuten)", disabled: "" },
              model: {
                value: _vm.kundeHonorareForm.rundungStundensatz,
                callback: function($$v) {
                  _vm.$set(_vm.kundeHonorareForm, "rundungStundensatz", $$v)
                },
                expression: "kundeHonorareForm.rundungStundensatz"
              }
            }),
            _c("InputField", {
              attrs: { label: "Zeit Berechnet", disabled: "" },
              model: {
                value: _vm.kundeHonorareForm.stundenDauer,
                callback: function($$v) {
                  _vm.$set(_vm.kundeHonorareForm, "stundenDauer", $$v)
                },
                expression: "kundeHonorareForm.stundenDauer"
              }
            }),
            _c("InputField", {
              attrs: {
                label: "Stundensatz",
                type: "currency",
                disabled: _vm.isUseFixbetragEnable,
                validateUntouched: ""
              },
              on: { change: _vm.calcKundeHonorare },
              model: {
                value: _vm.kundeHonorareForm.stundensatz,
                callback: function($$v) {
                  _vm.$set(_vm.kundeHonorareForm, "stundensatz", $$v)
                },
                expression: "kundeHonorareForm.stundensatz"
              }
            }),
            _c("InputField", {
              attrs: {
                label: "Betrag",
                type: "currency",
                disabled: !_vm.isUseFixbetragEnable,
                validateUntouched: ""
              },
              model: {
                value: _vm.kundeHonorareForm.betrag,
                callback: function($$v) {
                  _vm.$set(_vm.kundeHonorareForm, "betrag", $$v)
                },
                expression: "kundeHonorareForm.betrag"
              }
            }),
            _c("InputRadioBoxGroup", {
              attrs: { values: _vm.mwstSatzValues },
              model: {
                value: _vm.kundeHonorareForm.mwstSatz,
                callback: function($$v) {
                  _vm.$set(_vm.kundeHonorareForm, "mwstSatz", $$v)
                },
                expression: "kundeHonorareForm.mwstSatz"
              }
            })
          ]
        },
        proxy: true
      },
      {
        key: "footer",
        fn: function() {
          return [
            _c(
              "BaseButton",
              {
                attrs: { isSecondary: "" },
                on: {
                  click: function($event) {
                    return _vm.close()
                  }
                }
              },
              [_vm._v("Abbrechen")]
            ),
            _c(
              "BaseButton",
              {
                attrs: { isPrimary: "", disabled: _vm.isSaveDisabled },
                on: {
                  click: function($event) {
                    _vm.save()
                    _vm.close()
                  }
                }
              },
              [_vm._v("Speichern")]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }