<template>
  <div class="impr-container">
    <div class="impr-wrapper">
      <div class="banner">IMPRESSUM</div>
      <hr />
      <div class="impressum">
        <editable-frame type="impressum_editable" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CORE_TYPES from "@/store/core/types";
import EditableFrame from "@/components/cms/EditableFrame.vue";
export default {
  components: {
    EditableFrame
  },
  computed: {
    ...mapGetters({
      maklerInfo: CORE_TYPES.GETTERS.GET_BROKER_INFORMATION
    })
  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>
<style scoped>
.banner {
  font-size: 26px;
  text-align: left;
  color: var(--color-text);
}
.impr-container {
  margin: 0 auto;
  padding-left: 1em;
  padding-right: 1em;
  max-width: 1200px;
}
.impr-wrapper {
  width: 100%;
  padding-bottom: 2em;
}
.impressum {
  text-align: left;
}
</style>