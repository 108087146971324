<template>

  <div>
    <PageHeaderTitleNavigation
      :title="$appNavigation.currentMenu.label"
      :actions="actions"
      @action="handleHeaderAction">
    </PageHeaderTitleNavigation>

    <BaseFilter 
      filterId="4a1a843e-b03b-4a02-bfd4-06462cb6408a"
      title="Honorare filtern" 
      :metadata="filterMetadata" 
      :defaultOptions="defaultOptionsData"
      @onFilter="filterForm($event)">
    </BaseFilter>

    <div class="box__container" tid="cf7b3ede-5436-4497-a3a1-9c5df98235d3" v-if="tableRows.length">

      <Table
        tableId="618e4673-b92d-4b5a-aea7-47836ac4f7b6"
        class="table-container"
        v-if="!loading && tableRows.length"
        :headers="tableHeaders"
        :rows="tableRows"
        :rowsPerPage="20"
        :count="resultCount"
        :exportConfig="exportHonorare"
        @action-EDIT_ITEM="bearbeiten($event)"
        @action-REMOVE_ITEM="openDeleteHonorareConfirmModal($event)"
      >

        <template v-slot:honorarBestaetigen="row">
            <Pill v-if="row.status=='CONFIRMED'" :label="row.friendlyStatusName" type="success"></Pill>
            <Pill v-else-if="row.status=='PENDING'" :label="row.friendlyStatusName" type="warning"></Pill>
            <Pill v-else :label="row.friendlyStatusName" type="primary" @click="openConfirmationModal(row)"></Pill>
        </template>

        <template v-slot:id="row">
            <DownloadLink 
              asLink
              :title="row.invoiceNr"
              v-if="row.invoiceNr"
            :href="getPDFLink(row)"
            /> 

            <span v-else>Keine</span>
        </template>  
        
      </Table>
      <GhostLoading v-else-if="loading" type="table" />
      <NoData v-else />
    </div>    

    <BaseModal @onConfirmButton="markForConfirmation()" ref="markItemConfirmation" modalTitle="Honorar für Bestätigung vormerken" labelButtonConfirm="Ja">
      {{confirmationQuestion}}
    </BaseModal>

    <BaseModal @onConfirmButton="abrechnungenbestaetigen()" ref="abrechnungenbestaetigenConfirmation" modalTitle="Honorar bestätigen" labelButtonConfirm="Ja">
        Wollen Sie die für die Bestätigung vorgemerkten Honorare wirklich bestätigen? Hierbei werden dann die Rechnungsnummern vergeben und können nicht mehr geändert werden
    </BaseModal>  

    <BaseModal  ref="keineBestaetigtenAbrechnungenModal" modalTitle="keine bestätigten Abrechnungen" :showConfirmButton="false">
      Es gibt keine bestätigten Abrechnungen zu versenden.
    </BaseModal>  

    <HonorareLoeschenConfirmModal ref="alleLoeschenConfirmationModal" @confirmed="alleLoeschen($event)" />

    <HonorareLoeschenConfirmModal ref="deleteHonorareConfirmModal" 
      @cancelled="honorareToDelete = null;" @confirmed="deleteHonorare($event)" />

    <BaseModal  @onConfirmButton="getFees(formValues)" ref="bestaetigungVorbereitenModal" modalTitle="Information" :showCancelButton="false" :showCloseButton="true"  labelButtonConfirm="Ok">
      Die Honorare/Serviceleistungen wurden für die Bestätigung vorbereitet.
    </BaseModal>  


  </div>
    
</template>

<script>
import CORE_TYPES from "@/store/core/types";
import LOG_TYPES from '@/store/log/types';
import { buildMessage } from "@/helpers/log-message-helper";
import HONORARE_TYPES from "@/store/honorare/types";
import { mapGetters } from "vuex";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, CurrencyColumn, ActionColumn, SimpleAction, SlotColumn} from "@/components/table2/table_util.js";
import {PhPencil, PhTrash} from "phosphor-vue";
import BaseModal from '@/components/core/BaseModal.vue';
import HonorareLoeschenConfirmModal from '@/views/intern/honorare/HonorareLoeschenConfirmModal.vue';
import { VIEW_ROLES } from '@/router/roles';
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { makeQueryParam } from '@/helpers/utils-helper';
import MAILCOMPOSER_TYPES from "@/store/mailcomposer/types";
import BaseFilter from "@/components/core/BaseFilter.vue";
import {ADDITIONAL_BUTTON_TYPE_ACTION, ADDITIONAL_BUTTON_TYPE_LINK} from "@/components/core/BaseFilter.vue";
import Pill from '@/components/core/Pill.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import { PageHeaderSimpleAction, PageHeaderDownloadLinkAction } from '@/components/core/header-title-navigation/page-header-utils'
import DownloadLink from '@/components/core/download/DownloadLink.vue'

const DATE_FORMAT = 'DD.MM.YYYY';

export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    Table,
    PageHeaderTitleNavigation,
    GhostLoading,
    NoData,
    BaseModal,
    HonorareLoeschenConfirmModal,
    BaseFilter,
    Pill,
    OptionMenu,
    DownloadLink,
  },
  data: function () {
      const today = dayjs().utc();
      const to =  today.clone().format("DD.MM.YYYY");
      const from = today.subtract(28, 'days').format("DD.MM.YYYY");

      return {
        searchParams: {},
        resultCount: null,
        unsignedAttachments: [],
        signedAttachments: [],
        currentFilename: null,
        downloadErrorMessage: null,
        filtered: false,
        loading: false,
        form: {
          kundennumer: null,
          kundenvorname: null,
          kundenname: null,
          abbrechnung: {
            automatisch: true,
            manuell: true
          },
          dateType: "DATE_CREATED",
          status: {
            showConfirmed: true,
            showUnconfirmed: true   
          },
          von: from,
          bis: to,
        },
        exportHonorare: {
          pdf: {
            show: true,
            name: 'Honorare ' + dayjs(new Date()).format('DD-MMM-YYYY HH mm'),
            title: 'Honorare',
          },
          xls: {
            show: true,
            name: 'Honorare ' + dayjs(new Date()).format('DD-MMM-YYYY HH mm'),
            title: 'Honorare',
          }
        },
        formValues: {},
        honorareToDelete: null,
      }
  },
  computed: {
    ...mapGetters({
      fees: HONORARE_TYPES.GETTERS.FEES,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      token: CORE_TYPES.GETTERS.GET_TOKEN,
      apiAddress: CORE_TYPES.GETTERS.API_ADDRESS,
      selectedRow:  HONORARE_TYPES.GETTERS.SELECTED_ROW,
    }),
    downloadAbrechnungenAlsPDFQueryParams() {
      return {
        kundennummer: this.searchParams?.kundennummer,
        kundenvorname: this.searchParams?.kundenvorname,
        kundenname: this.searchParams?.kundenname,
        dateType: this.searchParams?.dateType,
        dateFrom: this.searchParams?.dateFrom,
        dateTo: this.searchParams?.dateTo,
        showUnconfirmed: this.searchParams?.showUnconfirmed,
        showConfirmed: this.searchParams?.showConfirmed,
        showSent: this.searchParams?.showSent,
        showNoEmailSend: this.searchParams?.showNoEmailSend,
        showManuell: this.searchParams?.showManuell,
        showAutogenerated: this.searchParams?.showAutogenerated,
      }
    },
    downloadAbrechnungenAlsXMLQueryParams() {
      return {
        kundennummer: this.searchParams?.kundennummer,
        kundenvorname: this.searchParams?.kundenvorname,
        kundenname: this.searchParams?.kundenname,
        dateType: this.searchParams?.dateType,
        dateFrom: this.searchParams?.dateFrom,
        dateTo: this.searchParams?.dateTo,
      }
    },
    actions() {
      return [
        PageHeaderSimpleAction('ABRECHNUNGEN_GENERIEREN', 'Abrechnungen generieren'),
        PageHeaderSimpleAction('ABRECHNUNGEN_BESTAETIGEN', 'Abrechnungen bestätigen'),
        PageHeaderSimpleAction('BESTAETIGTE_ABRECHNUNGEN_VERSCHICKEN', 'Bestätigte Abrechnungen verschicken'),
        PageHeaderDownloadLinkAction('ABRECHNUNGEN_ALS_PDF_AUSGEBEN', 'Abrechnungen als PDF ausgeben', 
          'abrechnungenAlsPDF.pdf', '/abrechnungenAlsPDF', this.downloadAbrechnungenAlsPDFQueryParams),
        PageHeaderDownloadLinkAction('ABRECHNUNGEN_ALS_XML_LINK', 'SEPA Lastschrift', 
          'abrechnungenAlsXML.xml', '/abrechnungenAlsXML', this.downloadAbrechnungenAlsXMLQueryParams),
        PageHeaderSimpleAction('ALLE_LOESCHEN', 'Alle löschen'),
        PageHeaderSimpleAction('LEERE_LOESCHEN', 'Leere löschen'),
        PageHeaderSimpleAction('BESTAETIGUNG_VORBEREITEN', 'Bestätigung vorbereiten'),
      ]
    },
    additionalButtons() {
      return [
        {
          label: "Abrechnungen generieren",
          key: "abrechnungenGenerieren",
          type: ADDITIONAL_BUTTON_TYPE_ACTION,
        },
        {
          label: "Abrechnungen bestätigen",
          key: "abrechnungenBestaetigen",
          type: ADDITIONAL_BUTTON_TYPE_ACTION,
        },
        {
          label: "Bestätigte Abrechnungen verschicken",
          key: "bestaetigteAbrechnungenVerschicken",
          type: ADDITIONAL_BUTTON_TYPE_ACTION,
        },
        {
          label: "Abrechnungen als PDF ausgeben",
          key: "abrechnungenAlsPDFAusgeben",
          type: ADDITIONAL_BUTTON_TYPE_LINK,
          linkMetadata: {
            downloadMethod: "abrechnungenAlsPDF",
            fileName: "abrechnungenAlsPDF.pdf",
            apiAddress: this.apiAddress,
            token: this.token,
            params: [
              {
                type: 'text',
                key: "kundennummer"
              },
              {
                type: 'text',
                key: "kundenvorname"
              },
              {
                type: 'text',
                key: "kundenname"
              },
              {
                type: 'group',
                key: "dateType"
              },
              {
                type: 'dateRange',
                key: "period",
                fromParam: "dateFrom",
                toParam: "dateTo"
              },
              {
                type: 'default',
                key: "showUnconfirmed",
              },
              {
                type: 'default',
                key: "showConfirmed",
              },
              {
                type: 'default',
                key: "showSent",
              },
              {
                type: 'default',
                key: "showNoEmailSend",
              },
              {
                type: 'default',
                key: "manuell",
                param: "showManuell"
              },
              {
                type: 'default',
                key: "automatisch",
                param: "showAutogenerated"
              },              
            ]
          }
        },
        {
          label: "SEPA Lastschrift",
          key: "abrechnungenAlsXMLLink",
          type: ADDITIONAL_BUTTON_TYPE_LINK,
          linkMetadata: {
            downloadMethod: "abrechnungenAlsXML",
            fileName: "abrechnungenAlsXML.xml",
            apiAddress: this.apiAddress,
            token: this.token,
            params: [
              {
                type: 'text',
                key: "kundennummer"
              },
                {
                  type: 'text',
                  key: "kundenname"
                },
              {
                type: 'text',
                key: "kundenvorname"
              },
              {
                type: 'group',
                key: "dateType"
              },
              {
                type: 'dateRange',
                key: "period",
                fromParam: "dateFrom",
                toParam: "dateTo"
              },            
            ]
          }
        },  
        {
          label: "Alle löschen",
          key: "alleLoeschen",
          type: ADDITIONAL_BUTTON_TYPE_ACTION,
        },  
        {
          label: "Leere löschen",
          key: "leereLoeschen",
          type: ADDITIONAL_BUTTON_TYPE_ACTION,
        },  
        {
          label: "Bestätigung vorbereiten",
          key: "bestaetigungVorbereiten",
          type: ADDITIONAL_BUTTON_TYPE_ACTION,
        },    
      ]
    },
    confirmationQuestion() {
      return this.selectedRow?.confirmActionMetadata?.find(p => p.key == 'confirmationQuestion')?.value || "";
    },
    canSeeSpecialColumns() {
      return this.hasRoles(VIEW_ROLES.VIEW_BROKER_AS_INTERN);
    },
    tableHeaders() {
        return {
          lockedLeft: [
            SlotColumn("honorarBestaetigen", "Status").makeAlwaysVisible(),
            SlotColumn("id", "Rechnungsnr").makeAlwaysVisible().withExporter((cell, row) => row.invoiceNr),
            TextColumn("kundennr", "Kundennr"),
            TextColumn("name", "Name"),
            TextColumn("vorname", "Vorname"),
            TextColumn("beginDateFee", "von"),
            TextColumn("endDateFee", "bis"),
            CurrencyColumn("amountNetto", "Gebühr netto"),
            CurrencyColumn("amountMwstSumme", "MwSt."),
            CurrencyColumn("amountBrutto", "Gebühr brutto"),
            CurrencyColumn("rueckerstattung", "Rückerstattung"),
            CurrencyColumn("abrechnungssumme", "Abrechnungssumme"),
            CurrencyColumn("erstattungPeriode", "erstattet vorherige Periode"),
            TextColumn("iban", "IBAN"),
            TextColumn("bic", "BIC"),
            TextColumn("bank", "Bank"),
            TextColumn("kontoinhaber", "Kontoinhaber"),
            TextColumn("typ", "Typ"),
          ],
          lockedRight: [
              ActionColumn("actions", "Aktionen"),
          ],   
        };
    
    },       
    tableRows () {
      if (!this.fees?.length ) {
        return [];
      }

      const fullActions = [
          SimpleAction("EDIT_ITEM", PhPencil, "Bearbeiten"),
          SimpleAction("REMOVE_ITEM", PhTrash, "Löschen"),
      ];

      return this.fees?.map(data => ({
        id: data.id,
        invoiceNr: data.invoiceNr,
        kundennr: data.kundennr,
        name: data.name,
        vorname: data.vorname,
        beginDateFee: data.beginDateFee,
        endDateFee: data.endDateFee,
        amountNetto: data.amountNetto,
        amountMwstSumme: data.amountMwstSumme,
        amountBrutto: data.amountBrutto,
        rueckerstattung: data.rueckerstattung,
        abrechnungssumme: data.abrechnungssumme,
        erstattungPeriode: data.erstattungPeriode,
        iban: data.iban,
        bic: data.bic,
        bank: data.bank,
        kontoinhaber: data.kontoinhaber,
        typ: data.typ,
        status: data.status,
        friendlyStatusName: data.friendlyStatusName,
        zeitraum: data.zeitraum,
        actions: !data?.confirmed ? fullActions : [],
        pdfMetadata: data.pdfMetadata,
        editActionMetadata: data.editActionMetadata,
        confirmActionMetadata: data.confirmActionMetadata,
        deleteActionMetadata: data.deleteActionMetadata,
      }));
    },
    defaultOptionsData() {
      const today = dayjs().utc();
      const to =  today.clone();
      const from = today.subtract(28, 'days');

      return {
           manuell: {
              value: true,
          },
          automatisch: {
              value: true,
          },   
          showUnconfirmed: {
              value: true,
          },
          showConfirmed: {
              value: true,
          },   
          Erstellungsdatum: {
              value: "DATE_CREATED",
          },
          zeitraum: {
            value: [{
              key: "min",
              value: from,
            },
            {
              key: "max",
              value: to,
            }],
          },                                  
        }; 
    },
    filterMetadata() {
         return [
            {
              type: "group",
              key: "kunde",
              label: "Kunde",
              menuItems: [
                {
                  type: 'text',
                  key: 'kundennummer',
                  label: 'Kundennummer',
                  inputmode: 'numeric',
                },
                {
                  type: 'text',
                  key: 'kundenvorname',
                  label: 'Kundenvorname',
                }, 
                {
                  type: 'text',
                  key: 'kundenname',
                  label: 'kundenname',
                },                                
              ],
            },     
            {
              type: "group",
              key: "abrechnung",
              label: "Abrechnung",
              menuItems: [
                {
                  type: 'default',
                  key: 'manuell',
                  label: 'Manuell',
                },
                {
                  type: 'default',
                  key: 'automatisch',
                  label: 'Automatisch',
                },                                
              ],
            },     
            {
              type: "group",
              key: "status",
              label: "Status",
              menuItems: [
                {
                  type: 'default',
                  key: 'showUnconfirmed',
                  label: 'unbestätigt',
                },
                {
                  type: 'default',
                  key: 'showConfirmed',
                  label: 'bestätigt',
                },
                {
                  type: 'default',
                  key: 'showSent',
                  label: 'versandt',
                },
                {
                  type: 'default',
                  key: 'showNoEmailSend',
                  label: 'Nur ohne E-mail Versand',
                },                                
              ],
            },                      
            {
              type: 'group',
              key: 'dateType',
              label: 'Date type',
              menuItems: [
                  {
                    type: "default",
                    key: "Erstellungsdatum",
                    value: "DATE_CREATED",
                  },
                  {
                    type: "default",
                    key: "Rechnungsdatum",
                    value: "DATE_INVOICE",
                  },        
              ]
            },   
            {
              type: "group",
              key: "period",
              label: "Zeitraum",
              menuItems: [
                {
                  type: "dateRange",
                  label: "Zeitraum",
                  key: "zeitraum",
                },                              
              ],
            },              
          ];  
 
    }
 

  },
  mounted() {
    this.getFees();
  },
  methods: {
    getFees(formValues) {
      this.loading = true;

      this.searchParams = formValues || {
                kundennummer: this.form.kundennummer, 
                kundenname: this.form.kundenname,
                kundenvorname: this.form.kundenvorname,
                dateType: this.form.dateType,
                dateFrom: this.form.von,
                dateTo: this.form.bis,
                showSent: this.form?.status?.showSent,
                showNoEmailSend: this.form?.status?.showNoEmailSend,
                showManuell: this.form.abbrechnung?.manuell,
                showConfirmed: this.form?.status?.showConfirmed,
                showUnconfirmed: this.form?.status?.showUnconfirmed,
                showAutogenerated: this.form.abbrechnung?.automatisch,
              };


      this.$store.dispatch(
              HONORARE_TYPES.ACTIONS.GET_FEES, this.searchParams
            ).then(() => {
                this.loading = false;
            });  

    },
    bearbeiten(row) {

      const feeId = row?.id;

      this.$store.dispatch(
              HONORARE_TYPES.ACTIONS.GET_EDITOR_LAYOUT_DATA, 
              {feeId}
            ).then((response) => {
                
                console.log(response);
                this.$store.commit(HONORARE_TYPES.MUTATIONS.SELECTED_ROW, row);
                this.$router.push({path: `/beratung/honorare/bearbeiten` });

                this.loading = false;
            }); 

    },

    openDeleteHonorareConfirmModal(row) {
      this.honorareToDelete = { ...row, };
      this.$refs.deleteHonorareConfirmModal.open();
    },
    async deleteHonorare(resetInvoiceDate) {
      if(!this.honorareToDelete?.id) return;

      try {
        this.loading = true;
        const id = this.honorareToDelete.id;
        await this.$store.dispatch(HONORARE_TYPES.ACTIONS.DELETE_HONORARE, { id, resetInvoiceDate, });
        this.getFees({ ...this.searchParams, });
      } catch(e) {
        this.loading = false;
      }
    },

    async abrechnungenGenerieren() {
      const result = await this.$store.dispatch(HONORARE_TYPES.ACTIONS.CREATE_FEES, this.formValues);

      if (Array.isArray(result)) {
        const message = result.find(item => item.key === 'displayMessage')
        if (message.value) {
          this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(message.value, 'primary'));
        }
      }

      await this.$store.dispatch(HONORARE_TYPES.ACTIONS.GET_FEES, this.formValues);
      this.loading = false;

    },
    openAbrechnungenbestaetigenConfirmationModal() {
      this.$refs.abrechnungenbestaetigenConfirmation.open();
    },
    async abrechnungenbestaetigen() {
      this.loading = true;

      const result = await this.$store.dispatch(HONORARE_TYPES.ACTIONS.CONFIRM_FEES, this.formValues);
      if (result.key === 'resultMessage') {
        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(result.value, 'primary'));
      }

      this.getFees();
      this.loading = false;
    },
    bestaetigteAbrechnungenVerschicken() {
      this.loading = true;

      this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.BESTAETIGTE_ABRECHNUNGEN_VERSCHICKEN, this.formValues).then(response => {

            if (response?.resultMessage) {
              this.openAbrechnungenbestaetigenConfirmationModal();
            } else {
              const customResponse = {
                ...response,
                attachments: response?.attachments,
                replaceContent: true,
              }
  
              this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.BESTAETIGTE_ABRECHNUNGEN_VERSCHICKEN, customResponse);
              this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, {type: 'mailcomposer-bulk', action: 'clear-cache', replaceContent: true});
              this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, {type: 'mailcomposer-bulk', action: 'clear', replaceContent: true});
              this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG, {
                type: this.composerId,
                action: 'clear',
                replaceContent: true
              });
              this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, response?.attachments);
              this.$router.push({path: '/communication/mailcomposer-bulk', query: {replaceContent: true}});

            }

        })  
    },
    showKeineBestaetigtenAbrechnungenModal() {
      this.$refs.keineBestaetigtenAbrechnungenModal.open();
    },
    SEPALastschrift() {

    },
    getPDFLink(row) {

      const feeId = row?.pdfMetadata?.find(p => p.key == 'param_feeid')?.value;
      const fileName = row?.pdfMetadata?.find(p => p.key == 'fileName')?.value;

      const apiAddress = this.apiAddress
      const token = this.token

      const params = makeQueryParam({ feeId, token })

      return `${apiAddress}/download_service/getHonorarPDF/${fileName}?${params}`;
    },
    getAllPDFSLink() {
      const apiAddress = this.apiAddress
      const token = this.token

      const formValues = {
          kundennummer: this.form.kundennummer, 
          kundenname: this.form.kundenname,
          kundenvorname: this.form.kundenvorname,
          dateType: this.form.dateType,
          dateFrom: this.form.von,
          dateTo: this.form.bis,
          showSent: this.form?.status?.showSent,
          showNoEmailSend: this.form?.status?.showNoEmailSend,
          showManuell: this.form.abbrechnung?.manuell,
          showConfirmed: this.form?.status?.showConfirmed,
          showUnconfirmed: this.form?.status?.showUnconfirmed,
          showAutogenerated: this.form.abbrechnung?.automatisch,
        };

      const params = makeQueryParam({ ...formValues, token })

      const fileName = "allePDFS.pdf";

      return `${apiAddress}/download_service/getHonorarAllPDFS/${fileName}?${params}`;
    },
    getAllExcelFilesLink() {
      const apiAddress = this.apiAddress
      const token = this.token

      const formValues = {
          kundennummer: this.form.kundennummer, 
          kundenname: this.form.kundenname,
          kundenvorname: this.form.kundenvorname,
          dateType: this.form.dateType,
          dateFrom: this.form.von,
          dateTo: this.form.bis,
          showSent: this.form?.status?.showSent,
          showNoEmailSend: this.form?.status?.showNoEmailSend,
          showManuell: this.form.abbrechnung?.manuell,
          showConfirmed: this.form?.status?.showConfirmed,
          showUnconfirmed: this.form?.status?.showUnconfirmed,
          showAutogenerated: this.form.abbrechnung?.automatisch,
        };

      const params = makeQueryParam({ ...formValues, token })

      const fileName = "alleExcel.pdf";

      return `${apiAddress}/download_service/getAllExcelFiles/${fileName}?${params}`;
    },
    openConfirmationModal(row) {
      this.$store.commit(HONORARE_TYPES.MUTATIONS.SELECTED_ROW, row);
      this.$refs.markItemConfirmation.open();
    },
    markForConfirmation() {
      const feeId = this.selectedRow?.pdfMetadata?.find(p => p.key == 'param_feeid')?.value;

      this.$store.dispatch(
              HONORARE_TYPES.ACTIONS.MARK_CONFIRMED, 
              {feeId}
            ).then(() => {
                this.getFees();     
                this.loading = false;
            });

      
    },
    alleLoeschen(resetInvoiceDate) {
      this.loading = true;

      this.closeAlleLoeschenConfirmationModal();

      this.$store.dispatch(
              HONORARE_TYPES.ACTIONS.ALLE_LOESCHEN, 
              {...this.formValues, resetInvoiceDate}
            ).then(() => {
                this.getFees({ ...this.searchParams, });
                this.loading = false;
            });
    },
    leereLoeschen() {
       this.loading = true;

      const formValues = {
        kundennummer: this.form.kundennummer, 
        kundenname: this.form.kundenname,
        kundenvorname: this.form.kundenvorname,
        dateType: this.form.dateType,
        dateFrom: this.form.von,
        dateTo: this.form.bis,
        showSent: this.form?.status?.showSent,
        showNoEmailSend: this.form?.status?.showNoEmailSend,
        showManuell: this.form.abbrechnung?.manuell,
        showConfirmed: this.form?.status?.showConfirmed,
        showUnconfirmed: this.form?.status?.showUnconfirmed,
        showAutogenerated: this.form.abbrechnung?.automatisch,
      };

      this.$store.dispatch(
              HONORARE_TYPES.ACTIONS.LEERE_LOESCHEN, 
              formValues
            ).then(() => {
                this.getFees();     
                this.loading = false;
            });     
    },
    openAlleLoeschenConfirmationModal() {
      this.$refs.alleLoeschenConfirmationModal.open();
    },
    closeAlleLoeschenConfirmationModal() {
      this.$refs.alleLoeschenConfirmationModal.close();
    },
    bestaetigungVorbereiten() {
       this.loading = true;

      this.$store.dispatch(
              HONORARE_TYPES.ACTIONS.BESTAETIGUNG_VORBEREITEN, 
              this.formValues
            ).then(() => {
              
              this.$refs.bestaetigungVorbereitenModal.open();
            })
            .finally(() => this.loading = false);    
    },
    filterForm(filterConfig) {

      let kundennummer = null;
      let kundenname = null;
      let kundenvorname = null;
      let dateFrom = null;
      let dateTo = null;      
      let showSent = false;
      let showNoEmailSend = false;      
      let showManuell = false;
      let showConfirmed = false;
      let showUnconfirmed = false;
      let showAutogenerated = false;

      const kunde = filterConfig.find(fc => fc.group=="kunde");

      if (kunde) {
          kundennummer = filterConfig.find(fc => fc.key=="kundennummer")?.value;
          kundenname = filterConfig.find(fc => fc.key=="kundenname")?.value;
          kundenvorname = filterConfig.find(fc => fc.key=="kundenvorname")?.value;
      }

      const abrechnung = filterConfig.find(fc => fc.group=="abrechnung");

      if (abrechnung) {
          showManuell = filterConfig.find(fc => fc.key=="manuell")?.value;
          showAutogenerated = filterConfig.find(fc => fc.key=="automatisch")?.value;
      }

      const status = filterConfig.find(fc => fc.group=="status");

      if (status) {
        showUnconfirmed = filterConfig.find(fc => fc.key=="showUnconfirmed")?.value;
        showConfirmed = filterConfig.find(fc => fc.key=="showConfirmed")?.value;
        showSent = filterConfig.find(fc => fc.key=="showSent")?.value;
        showNoEmailSend = filterConfig.find(fc => fc.key=="showNoEmailSend")?.value;
      }

      const dateType = filterConfig.find(fc => fc.group=="dateType")?.key;

      const period = filterConfig.find(fc => fc.group=="period");
      if (period) { 

        if (period?.value) {
          const parsedFilterVon = dayjs(period.value.find(v => v.key == 'min').value, DATE_FORMAT).toDate();
          const parsedFilterBis = dayjs(period.value.find(v => v.key == 'max').value, DATE_FORMAT).toDate();

          dateFrom = dayjs(parsedFilterVon).format(DATE_FORMAT);
          dateTo = dayjs(parsedFilterBis).format(DATE_FORMAT);
        }
      }


      this.getFees(
        {kundennummer, 
        kundenname, 
        kundenvorname,
        dateType,
        dateFrom,
        dateTo,
        showSent,
        showNoEmailSend,
        showManuell,
        showConfirmed,
        showUnconfirmed,
        showAutogenerated}
      ) ;
    },
    handleHeaderAction(action) {
      this.formValues = { ...this.searchParams, };
      switch (action?.key) {
        case "ABRECHNUNGEN_GENERIEREN":
          this.abrechnungenGenerieren();
          break;
        case "ABRECHNUNGEN_BESTAETIGEN":
          this.openAbrechnungenbestaetigenConfirmationModal();
          break;  
        case "BESTAETIGTE_ABRECHNUNGEN_VERSCHICKEN":
          this.bestaetigteAbrechnungenVerschicken();
          break;
        case "ALLE_LOESCHEN":
          this.openAlleLoeschenConfirmationModal();
          break;
        case "LEERE_LOESCHEN":
          this.leereLoeschen();
          break;     
        case "BESTAETIGUNG_VORBEREITEN":
          this.bestaetigungVorbereiten();
          break;                 
        default:
          break;
      }
    },
    generateParams(filterConfig) {

      let kundennummer = null;
      let kundenname = null;
      let kundenvorname = null;
      let dateFrom = null;
      let dateTo = null;      
      let showSent = false;
      let showNoEmailSend = false;      
      let showManuell = false;
      let showConfirmed = false;
      let showUnconfirmed = false;
      let showAutogenerated = false;

      const kunde = filterConfig.find(fc => fc.group=="kunde");

      if (kunde) {
          kundennummer = filterConfig.find(fc => fc.key=="kundennummer")?.value;
          kundenname = filterConfig.find(fc => fc.key=="kundenname")?.value;
          kundenvorname = filterConfig.find(fc => fc.key=="kundenvorname")?.value;
      }

      const abrechnung = filterConfig.find(fc => fc.group=="abrechnung");

      if (abrechnung) {
          showManuell = filterConfig.find(fc => fc.key=="manuell")?.value;
          showAutogenerated = filterConfig.find(fc => fc.key=="automatisch")?.value;
      }

      const status = filterConfig.find(fc => fc.group=="status");

      if (status) {
        showUnconfirmed = filterConfig.find(fc => fc.key=="showUnconfirmed")?.value;
        showConfirmed = filterConfig.find(fc => fc.key=="showConfirmed")?.value;
        showSent = filterConfig.find(fc => fc.key=="showSent")?.value;
        showNoEmailSend = filterConfig.find(fc => fc.key=="showNoEmailSend")?.value;
      }

      const dateType = filterConfig.find(fc => fc.group=="dateType")?.key;

      const period = filterConfig.find(fc => fc.group=="period");
      if (period) {

        if (period?.value) {
          const parsedFilterVon = dayjs(period.value.find(v => v.key == 'min').value, DATE_FORMAT).toDate();
          const parsedFilterBis = dayjs(period.value.find(v => v.key == 'max').value, DATE_FORMAT).toDate();

          dateFrom = dayjs(parsedFilterVon).format(DATE_FORMAT);
          dateTo = dayjs(parsedFilterBis).format(DATE_FORMAT);
        }
      }


      return {
        kundennummer, 
        kundenname, 
        kundenvorname,
        dateType,
        dateFrom,
        dateTo,
        showSent,
        showNoEmailSend,
        showManuell,
        showConfirmed,
        showUnconfirmed,
        showAutogenerated
      };
    },

  },
  async beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu Honorare / Serviceleistungen Übersicht', 
      fullPath: from.fullPath,
      breadcrumb: to?.meta?.breadcrumb?.label,
    });

    next();
  },


    
}
</script>
<style lang="scss" scoped>
  .box__container > * {
      margin-bottom: 0px !important; 
  }
</style>