var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: "Eingegangene Transaktionen" }
      }),
      _c("BaseFilter", {
        attrs: {
          title: "Transaktionen suchen",
          filterId: "9cd1544e-0e6f-4813-baec-71aa89728bc4",
          metadata: _vm.searchMetadata,
          configFilter: _vm.configFilter,
          showSaveButton: false
        },
        on: {
          onFilter: function($event) {
            return _vm.setFilters($event)
          }
        }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("Transactions", {
            attrs: { filters: _vm.filters, title: "Transaktionen" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }