var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.zeilen, function(zeile, count) {
        return _c(
          "div",
          { key: count },
          [
            _c("AnlagezieleZeile", {
              ref: "zeileComp",
              refInFor: true,
              attrs: {
                count: count + 1,
                suffix: _vm.data && _vm.data.suffix,
                data: zeile || {},
                hideSpezielleZiele: _vm.hideSpezielleZiele,
                comboboxValues: _vm.comboboxValues,
                maxRows: _vm.maxRows,
                disabled: _vm.disabled,
                warnings: _vm.getWarningsProZeile(count + 1)
              },
              on: {
                change: function($event) {
                  return _vm.doChange($event)
                },
                updateSum: function($event) {
                  return _vm.updateSum($event)
                },
                addZeile: function($event) {
                  return _vm.addZeile($event)
                },
                removeZeile: function($event) {
                  return _vm.removeZeile($event)
                }
              }
            })
          ],
          1
        )
      }),
      _c("hr"),
      _c("div", { staticClass: "row justify-content-between sums" }, [
        _c("div", { staticClass: "col-12 col-lg-6 px-1" }, [
          _c("span", { staticClass: "mr-2" }, [_vm._v("Summe einmalig:")]),
          _c("span", [_vm._v(_vm._s(_vm.summeEinmalig))])
        ]),
        _c("div", { staticClass: "col-12 col-lg-6 px-1" }, [
          _c("span", { staticClass: "mr-2" }, [
            _vm._v("Summe ratierlich (monatlich):")
          ]),
          _c("span", [_vm._v(_vm._s(_vm.summeRatierlich))])
        ])
      ]),
      _vm.hasExtraFields
        ? _c(
            "div",
            [
              _c("hr"),
              _c("InputToggleSwitch", {
                attrs: {
                  label: "Geschäft auf Kreditbasis",
                  inLineLabel: "",
                  suppressValidationMessage: ""
                },
                model: {
                  value: _vm.checkCredit,
                  callback: function($$v) {
                    _vm.checkCredit = $$v
                  },
                  expression: "checkCredit"
                }
              }),
              _vm.checkCredit
                ? _c("InputField", {
                    attrs: {
                      label: "Höhe",
                      type: "currency",
                      disabled: _vm.disabled
                    },
                    model: {
                      value: _vm.creditBetrag,
                      callback: function($$v) {
                        _vm.creditBetrag = $$v
                      },
                      expression: "creditBetrag"
                    }
                  })
                : _vm._e(),
              _c("InputTextArea", {
                attrs: {
                  label:
                    "Freitextfeld zur persönlichen Situation, falls vom Kunden gewünscht und obige Fallgruppen nicht ausreichend sind:"
                },
                model: {
                  value: _vm.bemerkung,
                  callback: function($$v) {
                    _vm.bemerkung = $$v
                  },
                  expression: "bemerkung"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }