<template>
  <router-view :key="$route.path"></router-view>
</template>

<script>
import OTHER_PRODUCTS_TYPES from '@/store/otherProducts/types';


export default {
  beforeRouteLeave(to, from, next) {
    this.$store.commit(OTHER_PRODUCTS_TYPES.MUTATIONS.RESET_DATA);
    next();
  },
}
</script>
