var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.form.fieldDefinitions, function(fieldsRow, index) {
        return _c(
          "div",
          { key: index },
          [
            _c("AnlagezieleZeile", {
              attrs: {
                count: index + 1,
                antragData: _vm.antragData,
                disabled: _vm.disabled,
                fields: fieldsRow
              },
              on: {
                change: function($event) {
                  return _vm.processEvent($event)
                },
                save: function($event) {
                  return _vm.processSave($event)
                }
              }
            })
          ],
          1
        )
      }),
      _c("hr"),
      _c("div", { staticClass: "row justify-content-between sums" }, [
        _c("div", { staticClass: "col-12 col-lg-6 px-1" }, [
          _c("span", { staticClass: "mr-2" }, [
            _vm._v(_vm._s(_vm.config.summeEinmal.label) + ":")
          ]),
          _c("span", [_vm._v(_vm._s(_vm.summeEinmalig))])
        ]),
        _c("div", { staticClass: "col-12 col-lg-6 px-1" }, [
          _c("span", { staticClass: "mr-2" }, [
            _vm._v(_vm._s(_vm.config.summeRatierlich.label) + ":")
          ]),
          _c("span", [_vm._v(_vm._s(_vm.summeRatierlich))])
        ])
      ]),
      _vm.hasExtraFields
        ? _c(
            "div",
            [
              _c("hr"),
              _c("InputToggleSwitch", {
                attrs: {
                  label: _vm.config.checkCredit.label,
                  inLineLabel: "",
                  suppressValidationMessage: ""
                },
                on: {
                  input: function($event) {
                    return _vm.processSave({
                      type: "save",
                      fieldId: _vm.config.checkCredit.id,
                      value: $event
                    })
                  }
                },
                model: {
                  value: _vm.form.checkCredit,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "checkCredit", $$v)
                  },
                  expression: "form.checkCredit"
                }
              }),
              _vm.form.checkCredit
                ? _c("InputField", {
                    attrs: {
                      label: _vm.config.creditBetrag.label,
                      type: "currency",
                      disabled: _vm.disabled
                    },
                    on: {
                      change: function($event) {
                        return _vm.processSave({
                          type: "save",
                          fieldId: _vm.config.creditBetrag.id,
                          value: $event
                        })
                      }
                    },
                    model: {
                      value: _vm.form.creditBetrag,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "creditBetrag", $$v)
                      },
                      expression: "form.creditBetrag"
                    }
                  })
                : _vm._e(),
              _c("InputTextArea", {
                attrs: { label: _vm.config.bemerkung.label },
                on: {
                  change: function($event) {
                    return _vm.processSave({
                      type: "save",
                      fieldId: _vm.config.bemerkung.id,
                      value: $event
                    })
                  }
                },
                model: {
                  value: _vm.form.bemerkung,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "bemerkung", $$v)
                  },
                  expression: "form.bemerkung"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }