<template>
  <BaseModal
    ref="modal"
    modalTitle="Textbausteine bearbeiten"
    @close="onClose()"
    @onConfirmButton="save()"
  >
    <template #default>
      <InputField label="Textbaustein" :value="selectedBereich.beschreibungKurz" disabled />

      <InputField label="Betreff" v-model="form.betreff" autofocus />

      <InputToggleSwitch label="Ihrer Unterstruktur zur Verfügung stellen" inLineLabel v-model="form.sichtbarStruktur" />

      <InputTextArea label="Text" :rows="6" v-model="form.text" />
    </template>
  </BaseModal>
</template>

<script>
import { mapGetters } from 'vuex';
import MAKLER_TEXTE_TYPES from '@/store/maklerTexte/types';

import BaseModal from '@/components/core/BaseModal.vue';
import InputField from '@/components/core/forms/InputField.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';

export default {
  data() {
    return {
      maklerTexte: null,
      form: {
        betreff: '',
        sichtbarStruktur: false,
        text: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      selectedBereich: MAKLER_TEXTE_TYPES.GETTERS.SELECTED_BEREICH,
    }),
  },
  methods: {
    open(maklerTexte) {
      if(maklerTexte?.id) {
        this.maklerTexte = { ...maklerTexte, };

        this.form = {
          betreff: maklerTexte.betreff,
          sichtbarStruktur: maklerTexte.sichtbarStruktur,
          text: maklerTexte.text,
        };
      } else {
        this.reset();
      }
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    onClose() {
      this.reset();
    },
    reset() {
      this.maklerTexte = null;

      this.form = {
        betreff: '',
        sichtbarStruktur: false,
        text: '',
      };
    },
    save() {
      this.$store.dispatch(MAKLER_TEXTE_TYPES.ACTIONS.SAVE_MAKLER_TEXTE, {
        ...this.form,
        id: this.maklerTexte?.id,
        bereich: this.selectedBereich.id,
      });
    },
  },
  components: {
    BaseModal,
    InputField,
    InputToggleSwitch,
    InputTextArea,
  },
}
</script>
