<template>
  <Broker
    v-if="isByPass"
    :vertragId="vertragId"
    :schadenId="schadenId"
    v-bind:value="ortDatum"
    v-on:sendInfo="getInfo($event.target.ortDatum)"
    @data-changed="$emit('data-changed', $event)"
  />
  <Customer
    v-else
    :vertragId="vertragId"
    :schadenId="schadenId"
    @data-changed="$emit('data-changed', $event)"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';

import Broker from './allgemeine-angaben/Broker.vue';
import Customer from './allgemeine-angaben/Customer.vue';


export default {
  data(){
    return{
      ortDatum:""
    }
  },
  props: {
    vertragId: {
      type: [Number, String],
    },
    schadenId: {
      type: [Number, String],
    },
  },
  computed: {
    ...mapGetters({
      isByPass: CORE_TYPES.GETTERS.IS_BYPASS,
    }),
  },
  methods:{
    getInfo(data){
      this.ortDatum = data;
    }
  },
  components: {
    Broker,
    Customer,
  },
}
</script>
