<template>
  <div>
    <ul>
      <RouterNode 
        v-for="(node, index) in treeData" 
        :key="index" 
        :node="node"
        :allCollapsed="allCollapsed"/>
    </ul>
  </div>
</template>

<script>
import RouterNode from '@/components/core/router-viewer/RouterNode.vue'

export default {

  props: {
    treeData: {
      type: Array
    },
    allCollapsed: {
    }
  },
  components: {
    RouterNode
  }
}
</script>

<style scoped>

</style>