<template>
  <div v-if="positionFields">
    <div v-for="(component, index) in positionFields" :key="index">

      <div class="mt-2" v-if="!isComponentHidden(component)"
        :key="index + component.id" 
        :class="{'indented' : component.config && component.config.indented}">

        <AntragComponent
          :component="component"
          :antragId="antragId"
          :data="positionData"
          :comboboxSelection="getComboboxSelection(component)"
          isComponentHalfSizeEnabled
          :disabled="disabled"
          @updateStore="updatePositionData($event, component)">
        </AntragComponent>
      </div>

    </div>
  </div>
</template>

<script>
import ANTRAG_TYPES from '@/store/antrag/types';
import { mapGetters } from 'vuex';
import validator from '@/mixins/validator';
import { componentValidatorToRule, isHiddenDisabled } from '@/components/antrag/antrag-utils';
import AntragComponent from '@/components/antrag/AntragComponent.vue';

export default {
  mixins: [validator],
  validators: {},
  components: {
    AntragComponent,
  },
  props: {
    spec: {
      type: Boolean,
      default: false
    },
    specSelectedFund: {
    },
    config: {
    },
    antragId: {
    },
    categoryId: {
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    dynamicValidators: {
      immediate: true,
      handler(newDynamicValidators) {
        for (const dynamicValidator of newDynamicValidators) {
          if (dynamicValidator && this.$configureValidators) {
            this.$configureValidators({ 
              [dynamicValidator.componentId]: dynamicValidator.validators,
            });
          }
        }
        if (this.antragWarnings) {
          for (const warn of this.antragWarnings) {
            if (warn.id && warn.message) {
              this.$pushErrors(warn.id, warn.message);
            }
          }
        }
      },
    },
    antragWarnings: {
      immediate: true,
      handler(newAntragWarnings) {
        if (newAntragWarnings) {
          for (const warn of newAntragWarnings) {
            if (warn.id && this.component && this.component.id === warn.id) {
              this.$pushErrors(warn.id, warn.message || '');
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      antraege: ANTRAG_TYPES.GETTERS.ANTRAEGE,
      positionDataAll: ANTRAG_TYPES.GETTERS.POSITION_DATA,
    }),
    antrag() {
      if (this.antraege && this.antraege[this.antragId]) {
        return this.antraege[this.antragId]
      }
    },
    positionData() {
      if (this.positionDataAll && this.antragId && this.categoryId 
          && this.positionDataAll[this.antragId]
          && this.positionDataAll[this.antragId][this.categoryId]) {
        return this.positionDataAll[this.antragId][this.categoryId]
      }
    },
    positionFields() {
      if (this.spec) 
        return this.config && this.config.positionSpecFields || []
      else 
        return this.config && this.config.positionFields  || []
    },
    antragWarnings() {
      return this.antrag && this.antrag.warnings?.antragWarnings || []
    },
    dynamicValidators() {
      const dynamicValidators = this.positionFields
        .filter(component => component.id && component.validators?.length)
        .map(component => ({
          componentId: component.id,
          validators: component.validators.map(componentValidatorToRule)
        })
      )
    
      return dynamicValidators
    },
  },
  methods: {
    updatePositionData($event, component = {}) {
      if ($event && $event.data) {
        const positionData = $event.data;
        // if (component && component.id && component.config && component.config.valueFor) {
        //   positionData[component.config.valueFor] = $event.data[component.id];
        // }
        if (this.spec) {
          if (this.specSelectedFund)
            this.updatePosition($event);
        } else {
          const payload = {
            id: this.antragId,
            categoryId: this.categoryId,
            positionData: positionData
          }
          this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_POSITION_DATA, payload)
        }
      }
    },
    updatePosition($event) {
      const positionChange = $event.data;
      let isin = this.specSelectedFund?.isin;
      const payload = {
        id: this.antragId,
        categoryId: this.categoryId,
        fonds: [
          { 
            ...positionChange,
            isin,
          }
        ]
      }
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_POSITIONS, payload)
    },
    getComboboxSelection(component) {
      if (this.antrag && this.antrag.comboboxSelections && component && component.id) {
        return this.antrag.comboboxSelections[component.id]
      }
      return []
    },
    isComponentHidden(component) {
      return component && component.hidden && isHiddenDisabled(component.hidden, this.positionData)
    }
  },
}
</script>

<style style>

</style>