var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.activeTapi
    ? _c("ph-PhoneDisconnect", {
        staticClass: "clickable",
        attrs: { size: _vm.size },
        on: { click: _vm.disconnect }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }