<template>
  <div>
    <div class="box__container">
      <div class="box__title">Nebentätigkeiten und sonstige Funktionen</div>
      <FormDivider />
      <FormLabel
        label="Anforderung: jährliche Meldepflicht sämtlicher Nebentätigkeiten, welche nicht dem WAG 2018 unterliegen"
      />
       <InputRadioBoxGroup
      :value="form.INPUT_SONST_BEST1"
      :values="meldepflichtValues"
      labelClass="font-bold"
      :disabled="disabledStatusOK"
      @input="fieldChanged($event, 'INPUT_SONST_BEST1')"
    />

      <InputToggleSwitch
        label="Vermittlung von Lebensversicherungen"
        :inLineLabel="true"
        isComponentHalfSize
        :disabled="!form.INPUT_SONST_BEST1 || disabledStatusOK"
        :value="form.INPUT_VERMITTLUNG_LEBENSVERS"
        @input="fieldChanged($event, 'INPUT_VERMITTLUNG_LEBENSVERS')"
      />

      <DatePickerField
        label="seit"
        :value="form.INPUT_VERMITTLUNG_LEBENSVERS_DATUM"
        isValueAsString
        isComponentHalfSize
        :disabled="
          !form.INPUT_VERMITTLUNG_LEBENSVERS || !form.INPUT_SONST_BEST1 ||
          disabledStatusOK
        "
        @change="fieldChanged($event, 'INPUT_VERMITTLUNG_LEBENSVERS_DATUM')"
      />

      <InputField
      label="Im Ausmaß von wie vielen Stunden im Jahr"
      :value="form.INPUT_VERMITTLUNG_LEBENSVERS_DAUER"
      isInteger
      inLineLabel
      isComponentHalfSize
      :disabled="
          !form.INPUT_VERMITTLUNG_LEBENSVERS || !form.INPUT_SONST_BEST1 ||
          disabledStatusOK
        "     
      @change="fieldChanged($event, 'INPUT_VERMITTLUNG_LEBENSVERS_DAUER')"
      />
      <InputToggleSwitch
        label="Vermittlung von Sachversicherungen"
        :inLineLabel="true"
        isComponentHalfSize
        :disabled="!form.INPUT_SONST_BEST1 || disabledStatusOK"
        :value="form.INPUT_VERMITTLUNG_SACHVERS"
        @input="fieldChanged($event, 'INPUT_VERMITTLUNG_SACHVERS')"
      />

      <DatePickerField
        label="seit"
        :value="form.INPUT_VERMITTLUNG_SACHVERS_DATUM"
        isValueAsString
        isComponentHalfSize
        :disabled="
          !form.INPUT_VERMITTLUNG_SACHVERS || !form.INPUT_SONST_BEST1 ||
          disabledStatusOK
        "
        @change="fieldChanged($event, 'INPUT_VERMITTLUNG_SACHVERS_DATUM')"
      />      
      
      <InputField
      label="Im Ausmaß von wie vielen Stunden im Jahr"
      :value="form.INPUT_VERMITTLUNG_SACHVERS_DAUER"
      isInteger
      inLineLabel
      isComponentHalfSize
      :disabled="
          !form.INPUT_VERMITTLUNG_SACHVERS || !form.INPUT_SONST_BEST1 ||
          disabledStatusOK
        "     
      @change="fieldChanged($event, 'INPUT_VERMITTLUNG_SACHVERS_DAUER')"
      />
      
      <InputToggleSwitch
        label="Vermittlung von Finanzierung"
        :inLineLabel="true"
        isComponentHalfSize
        :value="form.INPUT_VERMITTLUNG_FINANZ"
        :disabled="!form.INPUT_SONST_BEST1 || disabledStatusOK"
        @input="fieldChanged($event, 'INPUT_VERMITTLUNG_FINANZ')"
      />

      <DatePickerField
        label="seit"
        :value="form.INPUT_VERMITTLUNG_FINANZ_DATUM"
        isValueAsString
        isComponentHalfSize
        :disabled="
          !form.INPUT_VERMITTLUNG_FINANZ || !form.INPUT_SONST_BEST1 ||
          disabledStatusOK
        "
        @change="fieldChanged($event, 'INPUT_VERMITTLUNG_FINANZ_DATUM')"
      />     
      
      <InputField
      label="Im Ausmaß von wie vielen Stunden im Jahr"
      :value="form.INPUT_VERMITTLUNG_FINANZ_DAUER"
      isInteger
      inLineLabel
      isComponentHalfSize
      :disabled="
          !form.INPUT_VERMITTLUNG_FINANZ || !form.INPUT_SONST_BEST1 ||
          disabledStatusOK
        "     
      @change="fieldChanged($event, 'INPUT_VERMITTLUNG_FINANZ_DAUER')"
      />
      
      

      <InputToggleSwitch
        label="Vermittlung von Nachrangdarlehen"
        :inLineLabel="true"
        isComponentHalfSize
        :value="form.INPUT_VERMITTLUNG_DARLEHNEN"
        :disabled="!form.INPUT_SONST_BEST1 || disabledStatusOK"
        @input="fieldChanged($event, 'INPUT_VERMITTLUNG_DARLEHNEN')"
      />

      <DatePickerField
        label="seit"
        :value="form.INPUT_VERMITTLUNG_DARLEHNEN_DATUM"
        isValueAsString
        isComponentHalfSize
        :disabled="
          !form.INPUT_VERMITTLUNG_DARLEHNEN || !form.INPUT_SONST_BEST1 ||
          disabledStatusOK
        "
        @change="fieldChanged($event, 'INPUT_VERMITTLUNG_DARLEHNEN_DATUM')"
      />     
      
      <InputField
      label="Im Ausmaß von wie vielen Stunden im Jahr"
      :value="form.INPUT_VERMITTLUNG_DARLEHNEN_DAUER"
      isInteger
      inLineLabel
      isComponentHalfSize
      :disabled="
          !form.INPUT_VERMITTLUNG_DARLEHNEN || !form.INPUT_SONST_BEST1 ||
          disabledStatusOK
        "     
      @change="fieldChanged($event, 'INPUT_VERMITTLUNG_DARLEHNEN_DAUER')"
      />
      
      

      <InputToggleSwitch
        label="Aktivitäten im Bereich Crowdfunding und/oder Crowdinvesting"
        :inLineLabel="true"
        isComponentHalfSize
        :value="form.INPUT_VERMITTLUNG_CROWDFUNDING"
        :disabled="!form.INPUT_SONST_BEST1 || disabledStatusOK"
        @input="fieldChanged($event, 'INPUT_VERMITTLUNG_CROWDFUNDING')"
      />

      <DatePickerField
        label="seit"
        :value="form.INPUT_VERMITTLUNG_CROWDFUNDING_DATUM"
        isValueAsString
        isComponentHalfSize
        :disabled="
          !form.INPUT_VERMITTLUNG_CROWDFUNDING || !form.INPUT_SONST_BEST1 ||
          disabledStatusOK
        "
        @change="fieldChanged($event, 'INPUT_VERMITTLUNG_CROWDFUNDING_DATUM')"
      />
           
      
      <InputField
      label="Im Ausmaß von wie vielen Stunden im Jahr"
      :value="form.INPUT_VERMITTLUNG_CROWDFUNDING_DAUER"
      isInteger
      inLineLabel
      isComponentHalfSize
      :disabled="
          !form.INPUT_VERMITTLUNG_CROWDFUNDING || !form.INPUT_SONST_BEST1 ||
          disabledStatusOK
        "     
      @change="fieldChanged($event, 'INPUT_VERMITTLUNG_CROWDFUNDING_DAUER')"
      />
      
      

      <InputToggleSwitch
        label="Vermittlung von Kryptowährungen"
        :inLineLabel="true"
        isComponentHalfSize
        :value="form.INPUT_VERMITTLUNG_CRYPTO"
        :disabled="!form.INPUT_SONST_BEST1 || disabledStatusOK"
        @input="fieldChanged($event, 'INPUT_VERMITTLUNG_CRYPTO')"
      />

      <DatePickerField
        label="seit"
        :value="form.INPUT_VERMITTLUNG_CRYPTO_DATUM"
        isValueAsString
        isComponentHalfSize
        :disabled="
          !form.INPUT_VERMITTLUNG_CRYPTO || !form.INPUT_SONST_BEST1 ||
          disabledStatusOK
        "
        @change="fieldChanged($event, 'INPUT_VERMITTLUNG_CRYPTO_DATUM')"
      />     
      
      <InputField
      label="Im Ausmaß von wie vielen Stunden im Jahr"
      :value="form.INPUT_VERMITTLUNG_CRYPTO_DAUER"
      isInteger
      inLineLabel
      isComponentHalfSize
      :disabled="
          !form.INPUT_VERMITTLUNG_CRYPTO || !form.INPUT_SONST_BEST1 ||
          disabledStatusOK
        "     
      @change="fieldChanged($event, 'INPUT_VERMITTLUNG_CRYPTO_DAUER')"
      />
        

      <InputToggleSwitch
        label="Beteiligungen"
        :inLineLabel="true"
        isComponentHalfSize
        :value="form.INPUT_VERMITTLUNG_BETEILIGUNG"
        :disabled="!form.INPUT_SONST_BEST1 || disabledStatusOK"
        @input="fieldChanged($event, 'INPUT_VERMITTLUNG_BETEILIGUNG')"
      />

      <DatePickerField
        label="seit"
        :value="form.INPUT_VERMITTLUNG_BETEILIGUNG_DATUM"
        isValueAsString
        isComponentHalfSize
        :disabled="
          !form.INPUT_VERMITTLUNG_BETEILIGUNG || !form.INPUT_SONST_BEST1 ||
          disabledStatusOK
        "
        @change="fieldChanged($event, 'INPUT_VERMITTLUNG_BETEILIGUNG_DATUM')"
      />     
      
      <InputField
      label="Im Ausmaß von wie vielen Stunden im Jahr"
      :value="form.INPUT_VERMITTLUNG_BETEILIGUNG_DAUER"
      isInteger
      inLineLabel
      isComponentHalfSize
      :disabled="
          !form.INPUT_VERMITTLUNG_BETEILIGUNG || !form.INPUT_SONST_BEST1 ||
          disabledStatusOK
        "     
      @change="fieldChanged($event, 'INPUT_VERMITTLUNG_BETEILIGUNG_DAUER')"
      />
      

      <InputToggleSwitch
        label="Vorsorgewohnungen und/oder Bauherrenmodelle"
        :inLineLabel="true"
        isComponentHalfSize
        :value="form.INPUT_VERMITTLUNG_VORSORGUNGSWOHNUNG"
        :disabled="!form.INPUT_SONST_BEST1 || disabledStatusOK"
        @input="fieldChanged($event, 'INPUT_VERMITTLUNG_VORSORGUNGSWOHNUNG')"
      />

      <DatePickerField
        label="seit"
        :value="form.INPUT_VERMITTLUNG_VORSORGUNGSWOHNUNG_DATUM"
        isValueAsString
        isComponentHalfSize
        :disabled="
          !form.INPUT_VERMITTLUNG_VORSORGUNGSWOHNUNG || !form.INPUT_SONST_BEST1 ||
          disabledStatusOK
        "
        @change="
          fieldChanged($event, 'INPUT_VERMITTLUNG_VORSORGUNGSWOHNUNG_DATUM')
        "
      />     
      
      <InputField
      label="Im Ausmaß von wie vielen Stunden im Jahr"
      :value="form.INPUT_VERMITTLUNG_VORSORGUNGSWOHNUNG_DAUER"
      isInteger
      inLineLabel
      isComponentHalfSize
      :disabled="
          !form.INPUT_VERMITTLUNG_VORSORGUNGSWOHNUNG || !form.INPUT_SONST_BEST1 ||
          disabledStatusOK
        "     
      @change="fieldChanged($event, 'INPUT_VERMITTLUNG_VORSORGUNGSWOHNUNG_DAUER')"
      />
      
      

      <InputToggleSwitch
        label="Sonstige nicht im FDL-Bereich ausgeübte Tätigkeiten und/oder Funktionen"
        :inLineLabel="true"
        isComponentHalfSize
        :value="form.INPUT_NICHT_FDL"
        :disabled="!form.INPUT_SONST_BEST1 || disabledStatusOK"
        @input="fieldChanged($event, 'INPUT_NICHT_FDL')"
      />

      <DatePickerField
        label="seit"
        :value="form.INPUT_NICHT_FDL_DATUM"
        isValueAsString
        isComponentHalfSize
        :disabled="
          !form.INPUT_NICHT_FDL || !form.INPUT_SONST_BEST1 || disabledStatusOK
        "
        @change="fieldChanged($event, 'INPUT_NICHT_FDL_DATUM')"
      />      
      
      <InputField
      label="Im Ausmaß von wie vielen Stunden im Jahr"
      :value="form.INPUT_NICHT_FDL_DAUER"
      isInteger
      inLineLabel
      isComponentHalfSize
      :disabled="
          !form.INPUT_NICHT_FDL || !form.INPUT_SONST_BEST1 ||
          disabledStatusOK
        "     
      @change="fieldChanged($event, 'INPUT_NICHT_FDL_DAUER')"
      />

      <InputField
        :value="form.INPUT_ERKLAERUNG_TEXT"
        label="Erklärung"
        isComponentHalfSize
        :disabled="disabledStatusOK"
        @change="fieldChanged($event, 'INPUT_ERKLAERUNG_TEXT')"
      />

      <FormLabel
        label="Anforderung: Weiters bitten wir Sie um Bekanntgabe, bei welchen auch im WAG 2018 Bereich tätigen Firmen Sie tätig sind bzw. Funktionen ausüben."
      />
      <InputRadioBoxGroup
        :value="form.INPUT_WEITERE_TAETIGKEIT"
        :values="weitereTaetigkeitValues"
        labelClass="font-bold"
        :disabled="disabledStatusOK"
        @input="fieldChanged($event, 'INPUT_WEITERE_TAETIGKEIT')"
      />
      <InputField
        :value="form.INPUT_WEITERE_TAETIGKEIT_TEXT"
        :disabled="!form.INPUT_WEITERE_TAETIGKEIT || disabledStatusOK"
        isComponentHalfSize
        @change="fieldChanged($event, 'INPUT_WEITERE_TAETIGKEIT_TEXT')"
      />

      <FormDivider />
      <InputToggleSwitch
        label="Ich bestätige hiermit, die Angaben wahrheitsgetreu getätigt zu haben."
        :inLineLabel="true"
        :config="{ bold: true }"
        isComponentHalfSize
        :disabled="disabledStatusOK"
        :value="true"
      />
    </div>
  </div>
</template>
<script>
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import FormLabel from "@/components/core/forms/FormLabel.vue";
import InputField from "@/components/core/forms/InputField.vue";
import FormDivider from "@/components/core/forms/FormDivider.vue";
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import InputRadioBoxGroup from "@/components/core/forms/radiobox/InputRadioBoxGroup.vue";

import NoData from "@/components/core/NoData.vue";

export default {
  components: {
    InputField,
    InputToggleSwitch,
    FormLabel,
    FormDivider,
    DatePickerField,
    NoData,
    InputRadioBoxGroup,
  },
  props: {
    form: {},
  },
  data() {
    return {
      weitereTaetigkeitValues: [
        {
          label:
            "Im WAG 2018 Bereich (konzessionspflichtiger Bereich) übe ich bei keinen weiteren Firmen eine Tätigkeit oder Funktion (Geschäftsführer, Angestellter, Gesellschafter oder sonstiges) aus.",
          value: false,
        },
        {
          label: "Ich bin noch für folgende Firmen tätig:",
          value: true,
        },
      ],
      meldepflichtValues: [
        {
          label: "Es werden keine Nebentätigkeiten ausgeübt",
          value: false,
        },
        {
          label: "Es werden folgende Nebentätigkeiten ausgeübt:",
          value: true,
        },
      ],
    };
  },
  computed: {
    disabledStatusOK() {
      return this.form.INPUT_STATUS_OK == "1" || this.form.INPUT_STATUS_OK == "true";
    },
  },
  methods: {
    fieldChanged(newValue, propName) {
      const payload = { name: propName, value: newValue };
      this.$emit("fieldChanged", payload);
      if(propName.endsWith('_DATUM')) {
        const switchPropName = propName.replace('_DATUM', '');
        this.form[switchPropName] = !!newValue;
        const payload = { name: switchPropName, value: this.form[switchPropName]};
        this.$emit("fieldChanged", payload);
      } else if(this.form[propName + '_DATUM'] && !newValue) { //propName_DATUM exists so propName has to be input switch with corresponding Date field and the input switch was set to false
        const payload = { name: propName + '_DATUM', value: ''};
        this.$emit("fieldChanged", payload);
      }
    },
  },
};
</script>