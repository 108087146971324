var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BoxContainer",
        { attrs: { title: _vm.selectedTabLabel } },
        [
          _vm.tableData && _vm.tableData.records && _vm.tableData.records.length
            ? _c("Table", {
                attrs: {
                  tableData: _vm.tableData,
                  cardViewEnabled: false,
                  filterEnabled: false,
                  exportEnabled: false,
                  paginationEnabled: false,
                  rowsPerPage: _vm.tableData.records.length
                },
                on: {
                  "execute-action": function($event) {
                    return _vm.handleTableAction($event)
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "Bezeichnung",
                      fn: function(ref) {
                        var data = ref.data
                        return [
                          _c(
                            "a",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.handleDocumentClick(data)
                                }
                              }
                            },
                            [_vm._v(_vm._s(data.row.Bezeichnung))]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3799918296
                )
              })
            : _vm._e(),
          !_vm.tableData.records || !_vm.tableData.records.length
            ? _c("div", [_vm._v("Keine Daten")])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }