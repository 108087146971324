import store from '@/store';
import CORE_TYPES from '@/store/core/types';
import { VIEW_ROLES, } from '@/router/roles';

import { stripTags, } from '@/helpers/commonfunctions';
import { LABEL_UNTITLED, } from './ConfigEditParamLabel.vue';

export const LABEL_REQUIRED_TEXT = 'Titel ist erforderlich!';

const CONFIGS_HOME_PATH = '/intern';
const CUSTOMER_CONFIGS_HOME_PATH = '/persoenlichedaten';

const DIVIDER_TYPE = 'DIVIDER';
const MAX_SUBSTEP_TITLE_CHARS = 50;

export function configDefinitionBaseViewPath(id) {
  const hasRoles = store.getters[CORE_TYPES.GETTERS.HAS_ROLES];

  const path = `/configs/view/${id}`;
  if(hasRoles([VIEW_ROLES.VIEW_CUSTOMER])) {
    return `${CUSTOMER_CONFIGS_HOME_PATH}${path}`;
  } else {
    return `${CONFIGS_HOME_PATH}${path}`;
  }
}

export function findConfigComponentsGroupedByDivider(step) {
  const configComponents = step?.configComponents
  if (!configComponents?.length) return [];

  let temp = []
  const componentsGroup = [temp]
  for (const component of configComponents) {
    if (component.type === DIVIDER_TYPE) {
      temp = []
      componentsGroup.push(temp)
    } else {
      temp.push(component)
    }
  }

  return componentsGroup;
}

export function findSubsteps(step) {
  if(!step?.stepKey) return [];

  return findConfigComponentsGroupedByDivider(step)
    .filter(group => group.length > 0)
    .map((group, index) => {
      const label = stripTags(group[0].label);
      const title = label?.length > MAX_SUBSTEP_TITLE_CHARS ? `${label.substring(0, MAX_SUBSTEP_TITLE_CHARS - 3)}...` : label;
      return {
        title: title || LABEL_UNTITLED,
        substepKey: `[data-position="${step.stepKey}-${index}"]`,
      };
    });
}
