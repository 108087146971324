var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        "data-name": "Layer 1",
        width: "634.38742",
        height: "584.34971",
        viewBox: "0 0 634.38742 584.34971"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M574.10044,479.32759c-.11329,0-.18457-.00293-.20948-.0044l-13.56445.00147v-2H573.94c.31518.01123,6.74584.19287,12.67089-6.99659,8.82959-10.71484,16.08057-37.23974.48145-102.75586-20.9751-88.0957-3.481-165.92334,5.01392-195.29541a9.75245,9.75245,0,0,0-9.38306-12.45166H380.1478a9.79616,9.79616,0,0,0-7.89966,4.021c-3.59863,4.9502-8.53711,14.46-11.62744,31.65527l-1.96826-.35351c3.15722-17.56885,8.25635-27.3584,11.978-32.47754a11.801,11.801,0,0,1,9.51734-4.84522H582.72324a11.75275,11.75275,0,0,1,11.30444,15.00733c-8.45117,29.21972-25.85473,106.64355-4.9895,194.27685,15.81836,66.4375,8.21387,93.53711-.95068,104.57178C582.04257,478.95893,575.38217,479.32759,574.10044,479.32759Z",
          transform: "translate(-282.80629 -157.82514)",
          fill: "#e6e6e6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M537.60044,515.82759c-.11329,0-.18457-.00293-.20948-.0044l-13.56445.00147v-2H537.44c.31689.00976,6.74584.19287,12.67089-6.99659,8.82959-10.71484,16.08057-37.23974.48145-102.75586-20.9751-88.0957-3.481-165.92334,5.01392-195.29541a9.75245,9.75245,0,0,0-9.38306-12.45166H343.6478a9.79616,9.79616,0,0,0-7.89966,4.021c-3.59863,4.9502-8.53711,14.46-11.62732,31.65527l-1.9685-.35351c3.15734-17.56885,8.25647-27.3584,11.97814-32.47754a11.801,11.801,0,0,1,9.51734-4.84522H546.22324a11.75275,11.75275,0,0,1,11.30444,15.00733c-8.45117,29.21972-25.85473,106.64355-4.9895,194.27685,15.81836,66.4375,8.21387,93.53711-.95068,104.57178C545.54257,515.45893,538.88217,515.82759,537.60044,515.82759Z",
          transform: "translate(-282.80629 -157.82514)",
          fill: "#ccc"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M501.10044,552.32759c-.11329,0-.18457-.00293-.20948-.0044l-199.45312.00147a11.75012,11.75012,0,0,1-10.92835-16.10206c7.23682-18.08056,16.98084-61.459.93152-148.83935-18.95141-103.18018-1.98486-140.46973,6.18933-151.71289a11.80128,11.80128,0,0,1,9.51758-4.84522H509.72324a11.75275,11.75275,0,0,1,11.30444,15.00733c-8.45117,29.21972-25.85473,106.64355-4.9895,194.27685,15.81836,66.4375,8.21411,93.53711-.95068,104.57178C509.04257,551.95893,502.38217,552.32759,501.10044,552.32759ZM307.14792,232.82514a9.79644,9.79644,0,0,0-7.8999,4.021c-8.02258,11.03467-24.65149,47.75732-5.84,150.17578,15.33191,83.47363,7.896,127.61377-1.04187,149.94385a9.58718,9.58718,0,0,0,.97705,9.06445,9.76294,9.76294,0,0,0,8.09461,4.29444H500.94c.31494.01513,6.74584.19335,12.67089-6.99659,8.82959-10.71484,16.08057-37.23974.48145-102.75586-20.9751-88.0957-3.481-165.92334,5.01392-195.29541a9.75245,9.75245,0,0,0-9.38306-12.45166Z",
          transform: "translate(-282.80629 -157.82514)",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d: "M475.26725,320.82514h-159a8,8,0,1,1,0-16h159a8,8,0,0,1,0,16Z",
          transform: "translate(-282.80629 -157.82514)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d: "M475.26725,354.82514h-159a8,8,0,1,1,0-16h159a8,8,0,0,1,0,16Z",
          transform: "translate(-282.80629 -157.82514)",
          fill: "#ccc"
        }
      }),
      _c("path", {
        attrs: {
          d: "M475.26725,388.82514h-159a8,8,0,1,1,0-16h159a8,8,0,0,1,0,16Z",
          transform: "translate(-282.80629 -157.82514)",
          fill: "#ccc"
        }
      }),
      _c("path", {
        attrs: {
          d: "M487.26725,422.82514h-159a8,8,0,1,1,0-16h159a8,8,0,0,1,0,16Z",
          transform: "translate(-282.80629 -157.82514)",
          fill: "#ccc"
        }
      }),
      _c("path", {
        attrs: {
          d: "M487.26725,456.82514h-159a8,8,0,1,1,0-16h159a8,8,0,0,1,0,16Z",
          transform: "translate(-282.80629 -157.82514)",
          fill: "#ccc"
        }
      }),
      _c("path", {
        attrs: {
          d: "M391.26725,286.82514h-75a8,8,0,1,1,0-16h75a8,8,0,0,1,0,16Z",
          transform: "translate(-282.80629 -157.82514)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M381.58294,511.64686c1.6141-6.835,9.19795-9.07539,15.39832-8.82485a65.72829,65.72829,0,0,1,11.6109,1.83727c3.9841.89132,7.92945,1.94677,11.85492,3.06617,7.19849,2.05274,14.32272,4.53559,21.63978,6.14065,6.09146,1.33622,12.71116,2.11988,18.78589.27517,5.83939-1.77324,10.79336-6.12626,12.38213-12.14463,1.49722-5.67157-.55835-11.8728-6.05412-14.46457-5.01175-2.36352-11.34247-1.1616-13.82653,4.18356-2.80075,6.02663-.00233,12.59922,4.43248,16.96033a49.66774,49.66774,0,0,0,8.23778,6.257,30.67633,30.67633,0,0,0,9.86375,4.41791c5.785,1.23563,12.67623-.58511,15.65954-6.112a10.32933,10.32933,0,0,0,1.17184-4.03693c.17661-1.92177-2.8245-1.90975-3,0-.50576,5.50343-6.95071,8.08082-11.77032,7.4771a23.92386,23.92386,0,0,1-9.12538-3.54166,53.47235,53.47235,0,0,1-7.55822-5.33292c-4.08779-3.53755-7.9197-9.359-5.17421-14.87935,2.23555-4.495,8.06322-4.4502,11.60773-1.55128,4.29251,3.51068,3.5271,9.88266.69595,14.05172-3.13485,4.61626-8.81678,6.55143-14.174,6.76014-5.821.22678-11.59339-1.109-17.15667-2.68883-6.91261-1.963-13.75632-4.15989-20.70215-6.00791-3.94328-1.04917-7.9169-1.996-11.93125-2.73275a39.377,39.377,0,0,0-11.56954-.80089c-6.25992.72286-12.65,4.36679-14.19142,10.89406-.44336,1.87745,2.4486,2.6787,2.89284.79752Z",
          transform: "translate(-282.80629 -157.82514)",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M688.0826,681.2653a458.03107,458.03107,0,0,0,4.318,58.85469c.09456.685.19759,1.36993.29256,2.05487l184.40236-1.03035c.54143-.68028,1.07434-1.36872,1.59067-2.06539,1.7216-2.30561,3.33516-4.67675,4.81639-7.12968,7.11952-11.81721,10.37891-25.65281,7.11268-35.19857l-.0585-.14833a16.75017,16.75017,0,0,0-3.26922-5.6805c-7.44693-8.38264-22.50384-6.9853-36.16768-.21914C863.37546,677.28781,873.13408,660.36,873.87556,645.25c.727-14.7382-6.97951-25.26672-15.33816-34.21415-.27427-.29578-.54849-.58335-.82271-.87086-.1331-.14791-.27422-.28757-.40692-.43548-6.52154-6.81857-14.12452-13.54026-25.12937-12.72717-12.06945.89334-25.45087,11.00284-34.83337,22.78314-9.38254,11.772-15.61745,25.14522-21.93723,38.19682-6.32789,13.04338-13.17414,26.42-23.3284,37.56868,13.75326-17.39607,24.842-37.18893,28.93817-56.43894s.49124-37.73839-11.76613-46.80442a28.669,28.669,0,0,0-12.55765-5.067c-.52878-.08788-1.06593-.15923-1.61151-.22227-11.34689-1.291-24.89474,2.63335-36.42155,12.014C705.9665,609.3611,697.1655,624.673,692.78651,639.20865,688.40791,653.74429,687.94128,667.73774,688.0826,681.2653Z",
          transform: "translate(-282.80629 -157.82514)",
          fill: "#f2f2f2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M808.72937,741.5265l3.52612-.0197q1.67943-1.05,3.3747-2.07535c.69964-.44162,1.40743-.86679,2.11558-1.30023q17.54426-10.71507,35.308-20.55589,17.74752-9.849,35.67351-18.79043a1.47784,1.47784,0,0,0,.84954-1.68963.42594.42594,0,0,0-.05876-.12352c-.16669-.31292-.53924-.443-1.083-.17568-1.46583.735-2.94013,1.47-4.40586,2.22156q-17.975,9.09448-35.79561,19.09674-17.80839,9.998-35.41384,20.87854c-.24677.15-.4939.30834-.74067.45838C810.95982,740.14306,809.84863,740.83478,808.72937,741.5265Z",
          transform: "translate(-282.80629 -157.82514)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M763.26442,741.78054l2.18044-.01218c.49178-.68822.97509-1.37643,1.46687-2.0647q17.33163-24.30411,34.656-48.60824,28.37064-39.78961,56.72434-79.57913a1.40436,1.40436,0,0,0,.24533-.48041c.16166-.58731-.26976-.93177-.82271-.87086a1.6564,1.6564,0,0,0-1.15209.73324q-15.90609,22.314-31.80419,44.61144-28.22295,39.5906-56.43787,79.17284c-1.19643,1.675-2.39282,3.35827-3.58925,5.03331C764.23951,740.40411,763.74773,741.09237,763.26442,741.78054Z",
          transform: "translate(-282.80629 -157.82514)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M714.68823,729.11829c.11127,3.67467.35448,7.29079.67212,10.87342q.09225,1.02775.18489,2.05546l2.28771-.01278c-.07-.68508-.13993-1.3702-.20143-2.05537-.48341-5.17575-.8264-10.41-.873-15.78645a220.22989,220.22989,0,0,1,4.33558-44.39187,276.39733,276.39733,0,0,1,13.42783-46.11929,316.72374,316.72374,0,0,1,21.9699-45.63021,1.21872,1.21872,0,0,0,.20194-.8105c-.09438-.652-.9625-.84532-1.61151-.22227a1.89383,1.89383,0,0,0-.328.41479q-1.583,2.72194-3.10817,5.46011a316.50458,316.50458,0,0,0-21.02777,45.79008A275.09009,275.09009,0,0,0,718.09991,684.872,217.271,217.271,0,0,0,714.68823,729.11829Z",
          transform: "translate(-282.80629 -157.82514)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M491.811,512.35768a2.49989,2.49989,0,0,1,1.36211-4.40965,41.28894,41.28894,0,0,1,16.19271,1.6597l53.31537,16.46563a5.5,5.5,0,0,1-3.24591,10.51019l-53.31537-16.46563A41.29214,41.29214,0,0,1,491.811,512.35768Z",
          transform: "translate(-282.80629 -157.82514)",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M710.358,580.75721a10.74268,10.74268,0,0,1-4.868-15.7369l-9.1385-47.79161,23.29142-1.81583,1.40592,46.74693A10.80091,10.80091,0,0,1,710.358,580.75721Z",
          transform: "translate(-282.80629 -157.82514)",
          fill: "#ffb8b8"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M694.91408,556.09185l-23.7229-110.48243a17.49974,17.49974,0,1,1,34.21973-7.34668l23.72314,110.48145a4.50561,4.50561,0,0,1-3.45483,5.34473l-25.42066,5.458a4.4984,4.4984,0,0,1-5.34448-3.45507Z",
          transform: "translate(-282.80629 -157.82514)",
          fill: _vm.color
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "373.17 572.064 360.911 572.064 355.078 524.776 373.173 524.777 373.17 572.064",
          fill: "#ffb8b8"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M352.15353,568.56068H375.7974a0,0,0,0,1,0,0v14.88687a0,0,0,0,1,0,0H337.26667a0,0,0,0,1,0,0v0A14.88686,14.88686,0,0,1,352.15353,568.56068Z",
          fill: "#2f2e41"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "414.17 571.064 401.911 571.064 396.078 523.776 414.173 523.777 414.17 571.064",
          fill: "#ffb8b8"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M393.15353,567.56068H416.7974a0,0,0,0,1,0,0v14.88687a0,0,0,0,1,0,0H378.26667a0,0,0,0,1,0,0v0A14.88686,14.88686,0,0,1,393.15353,567.56068Z",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M641.28786,721.06841a4.46919,4.46919,0,0,1-1.61621-3.0625L625.419,561.22661a4.50075,4.50075,0,0,1,4.313-4.9043l71.47974-2.68066a4.50154,4.50154,0,0,1,4.66577,4.66211l-5.71387,156.17676a4.50841,4.50841,0,0,1-4.0896,4.3164l-14.41284,1.31055a4.49951,4.49951,0,0,1-4.89722-4.18262l-6.6145-99.21679a1.43593,1.43593,0,0,0-1.49585-1.39942h-.0061a1.43661,1.43661,0,0,0-1.49243,1.41114l-5.91919,99.88574a4.4791,4.4791,0,0,1-4.04419,4.21094l-12.59082,1.25976a4.46991,4.46991,0,0,1-3.313-1.00781Z",
          transform: "translate(-282.80629 -157.82514)",
          fill: "#2f2e41"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "385.97692",
          cy: "218.96246",
          r: "24.56103",
          fill: "#ffb8b8"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M621.48513,562.28814a4.46183,4.46183,0,0,1-1.05274-3.67872c4.53442-27.56152,1.97876-68.07421-.96387-97.207a44.20088,44.20088,0,0,1,18.24121-40.30175l12.82691-10.99415a4.56971,4.56971,0,0,1,3.05737-1.082l31.866.91016a4.48315,4.48315,0,0,1,3.05347,1.31738l4.92407,10.9043.05078.03711a44.07016,44.07016,0,0,1,17.915,37.69043l-4.69043,96.93652a4.52415,4.52415,0,0,1-4.34693,4.28028l-77.34741,2.71777c-.05.001-.09961.002-.14941.002A4.48933,4.48933,0,0,1,621.48513,562.28814Z",
          transform: "translate(-282.80629 -157.82514)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M528.70914,521.83713a10.74267,10.74267,0,0,1,12.366-10.8825l39.86511-27.89846,11.18962,20.508-42.07677,20.41562a10.80091,10.80091,0,0,1-21.344-2.1427Z",
          transform: "translate(-282.80629 -157.82514)",
          fill: "#ffb8b8"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M562.25783,526.68657a4.47327,4.47327,0,0,1-2.94531-1.78515l-15.38452-20.96a4.50641,4.50641,0,0,1,.96484-6.291l91.09473-66.86328a17.50025,17.50025,0,0,1,20.71,28.21582L565.6028,525.86626a4.46888,4.46888,0,0,1-2.65283.87305A4.60231,4.60231,0,0,1,562.25783,526.68657Z",
          transform: "translate(-282.80629 -157.82514)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M670.04044,398.652c3.25719-3.99362,2.90059-10.07682.2864-14.518s-7.05192-7.49993-11.61815-9.889-9.4171-4.26785-13.71433-7.11253-4.10985-14.88746-5.25405-19.91231l2.729.909c-1.04887-1.34182-1.38528-5.30676-2.43415-6.64858,3.08365.30468,5.45485,3.23246,8.53849,3.53714l-1.77942-4.77642c10.57439-.65193,17.14878,6.69613,27.72317,6.0442,5.23921-.323,10.929-.52738,15.23606,2.47312,3.74278,2.60741,5.63743,7.12028,6.88058,11.50907,2.66917,9.42319,3.084,19.97876-1.52619,28.61978s-15.23665,14.46378-24.52024,11.34355Z",
          transform: "translate(-282.80629 -157.82514)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d: "M535.19371,741.82514h381a1,1,0,0,0,0-2h-381a1,1,0,0,0,0,2Z",
          transform: "translate(-282.80629 -157.82514)",
          fill: "#3f3d56"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }