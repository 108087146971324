<template>
  <div>
    <PageHeaderTitleNavigation 
      title="Kundenkategorie" 
      :actions="headerActions"
      @action-DISCARD="discardChanges()" 
    />

    <div class="box__container">
      <InputToggleSwitch
        label="Dynamische Anpassung der Kundenkategorie" 
        inLineLabel 
        v-model="form.automatic" 
        @input="dataChanged()" 
      />
      <InputField 
        type="currency" 
        label="Kategorie A ab Depotbestand:" 
        isComponentHalfSize 
        v-model="form.categoryA" 
        @change="dataChanged()" 
      />
      <InputField 
        type="currency" 
        label="Kategorie B ab Depotbestand:" 
        isComponentHalfSize 
        v-model="form.categoryB" 
        @change="dataChanged()" 
      />
      <InputField 
        type="currency" 
        label="Kategorie C ab Depotbestand:" 
        isComponentHalfSize 
        v-model="form.categoryC" 
        @change="dataChanged($event)" 
      />
      <div>Kategorie D darunter</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CUSTOMER_CATEGORY_TYPES from '@/store/customerCategory/types';

import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import InputField from '@/components/core/forms/InputField.vue';

import validatorMixin from '@/mixins/validator/index';
import { required } from '@/mixins/validator/rules';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {
  mixins: [validatorMixin],
  data() {
    return {
      form: {
        automatic: false,
        categoryA: null,
        categoryB: null,
        categoryC: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      customerCategory: CUSTOMER_CATEGORY_TYPES.GETTERS.CUSTOMER_CATEGORY,
      customerCategoryEdited: CUSTOMER_CATEGORY_TYPES.GETTERS.CUSTOMER_CATEGORY_EDITED,
      hasDataChanged: CUSTOMER_CATEGORY_TYPES.GETTERS.HAS_CUSTOMER_CATEGORY_EDITED,
    }),
    headerActions() {
      const { hasDataChanged, } = this;
      return [
        PageHeaderSimpleAction('DISCARD', 'Änderungen verwerfen').withDisabled(() => !hasDataChanged),
      ];
    },
  },
  watch: {
    customerCategory() {
      this.copyCustomerCategoryIntoForm();
    },
  },
  methods: {
    copyCustomerCategoryIntoForm() {
      Object.keys(this.form).forEach(key => {
        if(this.hasDataChanged) {
          this.form[key] = this.customerCategoryEdited[key];
        } else {
          this.form[key] = this.customerCategory[key];
        }
      });
    },
    findCustomerCategory() {
      this.$store.dispatch(CUSTOMER_CATEGORY_TYPES.ACTIONS.GET_CUSTOMER_CATEGORY);
    },
    dataChanged() {
      this.$store.commit(CUSTOMER_CATEGORY_TYPES.MUTATIONS.ADD_CUSTOMER_CATEGORY_EDITED, this.form);
    },
    discardChanges() {
      this.$store.commit(CUSTOMER_CATEGORY_TYPES.MUTATIONS.RESET_CUSTOMER_CATEGORY_EDITED);
      this.copyCustomerCategoryIntoForm();
    },
    async saveChanges() {
      await this.$store.dispatch(CUSTOMER_CATEGORY_TYPES.ACTIONS.SAVE_CUSTOMER_CATEGORY);
    },
  },
  mounted() {
    this.findCustomerCategory();
  },
  async beforeRouteLeave(to, from, next) {
    await this.saveChanges();
    this.$store.commit(CUSTOMER_CATEGORY_TYPES.MUTATIONS.RESET_STATE);
    next();
  },
  validators: {
    form: {
      categoryA: [required()],
      categoryB: [required()],
      categoryC: [required()],
    },
  },
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    BaseButton,
    InputToggleSwitch,
    InputField,
  },
}
</script>
