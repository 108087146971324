<template>
  <div class="lower-div-container">
    <div class="impr-disc-daten">
      <div class="impressum"><router-link to="/cms/impressum">Impressum</router-link></div>
      <div class="disclaimer"><router-link to="/cms/disclaimer">Disclaimer</router-link></div>
      <div class="datenschutz"><router-link to="/cms/datenschutz">Datenschutz</router-link></div>
    </div>
    <div class="makler-name">{{content}}</div>
  </div>
</template>
<script>

export default {
  props: ["content"],
/*   methods:{
goToImpressum(){this.$router.push('impressum');},
goToDisclaimer(){this.$router.push('disclaimer');},
goToDatenschutz(){this.$router.push('datenschutz');},
  } ,*/
};
</script>
<style scoped>
.impr-disc-daten {
  padding: 0.8em;
  display: grid;
  grid-template-columns: 3fr 1fr 3fr;
  background: var(--color-background);
  justify-content: center;
}
.lower-div-container {
  width: 100%;
}
.impressum {
  justify-self: end;
  cursor: pointer;
}
.disclaimer {
  justify-self: center;
  cursor: pointer;
}
.datenschutz {
  justify-self: start;
  cursor: pointer;
}
.makler-name {
  background: var(--color-background);
  padding: 0.4em;
}
</style>