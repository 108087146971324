<template>
    <span v-if="row[column] === '' || isNaN(numberValue)">{{row[column]}}</span>
    <span v-else-if="numberValue != null" style="white-space: nowrap">
        {{leftOfComma}}<span v-if="precision > 0">,</span>
        <span v-if="precision > 0" class="fractional">{{rightOfComma}}</span>
         {{symbol}}
    </span>
</template>
<script>
import FinancialCalculator from "@/components/retirementScenario/financialCalculator";

const DEFAULT_PRECISION = 2;

function valueToNumber(value, precision) {
    value = FinancialCalculator.convertStringToNumber(value);
    if (isNaN(value) || value == null)
        return value;
    value = Math.round(value * Math.pow(10, precision)) / Math.pow(10, precision);
    return value;
}
function leftOfComma(numberValue, separator) {
    let int = Math.floor(Math.abs(numberValue));
    let result = int.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
    if (numberValue < 0)
        result = "-" + result;
    return result;
}
function rightOfComma(numberValue, precision) {
    let result = "" + Math.round(Math.abs(numberValue) % 1 * Math.pow(10, precision));
    return result.padStart(precision, "0");
}

export default {
    exportAsString(props) {
        const precision = props.precision == undefined ? DEFAULT_PRECISION : props.precision;
        const rawValue = props.row[props.column];
        const numberValue = valueToNumber(rawValue, precision);
        if (rawValue === '' || isNaN(numberValue))
            return rawValue;
        let result = leftOfComma(numberValue, props.separator || ".");
        if (precision > 0)
            result += "," + rightOfComma(numberValue, precision);
        const symbol = props.symbol || "";
        return result + symbol;
    },
    props: {
        column: {
            type: String,
        },
        row: {
            type: Object,
        },
        symbol: {
            type: String,
            default: "",
        },
        precision: {
            type: Number,
            default: DEFAULT_PRECISION,
        },
        separator: {
            type: String,
            default: ".",
        }
    },
    computed: {
        numberValue() {
            let value = this.row[this.column];
            return valueToNumber(value, this.precision);
        },
        leftOfComma() {
            return leftOfComma(this.numberValue, this.separator);
        },
        rightOfComma() {
            return rightOfComma(this.numberValue, this.precision);
        },
    },
}
</script>
<style scoped>
.fractional {
    font-size: 0.75em;
}
</style>
