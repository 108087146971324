var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _vm.loading && !_vm.scrollLoading
            ? _c("GhostLoading", {
                attrs: { type: "table", config: { table: { rows: 10 } } }
              })
            : _c(
                "PaginatedTable",
                _vm._g(
                  {
                    attrs: {
                      tableId: "15b90920-ebdd-11ec-8ea0-0242ac120002",
                      rowId: "antragCheckId",
                      title: _vm.title,
                      headers: _vm.headers,
                      page: _vm.selectedPage,
                      pages: _vm.pages,
                      pageCount: _vm.pageCount,
                      rowCount: _vm.rowCount,
                      sorted: _vm.searchSort,
                      pageSize: _vm.maxRowsPerPage,
                      menuRowsPerPage: _vm.menuRowsPerPage,
                      mobileConfig: {
                        title: "label",
                        headers: ["dateCreated", "customerNr"]
                      }
                    },
                    on: {
                      scrollLoading: function($event) {
                        _vm.scrollLoading = $event
                      },
                      "click-info": _vm.clickInfo,
                      "action-SENDMAIL": _vm.handleActionSendMail,
                      "action-INFO": _vm.clickInfo
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "status",
                        fn: function(row) {
                          return [
                            _c("ComboBox", {
                              attrs: {
                                value: row.status,
                                values: row.statuses || [],
                                disabled: row.statuses.length == 1
                              },
                              on: {
                                change: function($event) {
                                  return _vm.changePruefStatus($event, row)
                                }
                              }
                            })
                          ]
                        }
                      },
                      {
                        key: "label",
                        fn: function(row) {
                          return [
                            !row.mobileTableContext
                              ? _c("DownloadLink", {
                                  attrs: {
                                    target: "_blank",
                                    rel: "noopener noreferer",
                                    title: row.label,
                                    downloadServicePath: "/antragCheckData",
                                    queryParams: {
                                      antragCheckId: row.antragCheckId
                                    }
                                  }
                                })
                              : _c("span", [_vm._v(_vm._s(row.label))])
                          ]
                        }
                      }
                    ])
                  },
                  _vm.$listeners
                )
              )
        ],
        1
      ),
      _c("Info", { ref: "Info" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }