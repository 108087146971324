var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {},
      [
        _vm.loading
          ? _c(
              "div",
              { staticClass: "box__container text-centered" },
              [_c("AnimatedSpinner")],
              1
            )
          : !_vm.optionsOptimierungsergebnis.series
          ? _c("NoData", { staticClass: "box__container" })
          : _c("div", [
              _c(
                "div",
                { staticClass: "box__container" },
                [
                  _c("div", { staticClass: "box__title" }, [
                    _vm._v("Optimierungsergebnis")
                  ]),
                  _c("BarChart", {
                    attrs: {
                      format: "percent",
                      height: "300px",
                      chartOptions: _vm.optionsOptimierungsergebnis
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "box__container" },
                [
                  _c("div", { staticClass: "box__title" }, [
                    _vm._v("Jahresperformance")
                  ]),
                  _c("BarChart", {
                    attrs: {
                      format: "percent",
                      height: "300px",
                      chartOptions: _vm.optionsJahresperformance
                    }
                  })
                ],
                1
              )
            ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }