<template>
  <div class="last-brokers-opened__container">
    <div v-if="showTitle" class="last-brokers-opened--title">Zuletzt geöffnete Makler</div>
    <Carousel v-if="lastBrokersOpened.length > 0" class="last-brokers-opened--carousel" :values="brokersOpenedValues" :options="carouselOptions" />
    <NoData v-else noIcon />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BROKER_SEARCH_TYPES from '@/store/brokerSearch/types';
import CORE_TYPES from '@/store/core/types';

import Carousel from '@/components/core/carousel/Carousel.vue';
import NoData from '@/components/core/NoData.vue';

export default {
  props: {
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      carouselOptions: {
        item: {
          width: 100,
          height: 116,
        },
      }
    };
  },
  computed: {
    ...mapGetters({
      lastBrokersOpened: BROKER_SEARCH_TYPES.GETTERS.OPEN_BROKERS,
    }),
    brokersOpenedValues() {
      return this.lastBrokersOpened.map(item => ({
        component: {
          template: `<div class="options-user-img" :style="{'background-image': 'url(${item.data.pictureUrl})'}"></div>`
        },
        label: this.getBrokerLabel(item),
        action: () => {
          this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_BROKER_NEW_TAB, { brokerId: item.data.brokerID });
          this.$emit('opened');
        }
      }));
    },
  },
  methods: {
    getBrokerLabel(item) {
      const personalDataAddress = item?.data?.person?.personalDataAddress || null;
      if(personalDataAddress) {
        if(!personalDataAddress?.firstName) {
          return `${personalDataAddress.lastName || ''}<br>`;
        } else {
          return `${personalDataAddress.firstName}<br>${personalDataAddress.lastName}`;
        }
      } else {
        return (item.name || item.brokerID || '');
      }
    },
  },
  components: {
    Carousel,
    NoData,
  },
}
</script>

<style lang="scss" scoped>
.last-brokers-opened__container {
  .last-brokers-opened--title {
    margin: 0 0 6px;
  }

  .last-brokers-opened--carousel {
    margin: 16px 0;
  }
}
</style>
<style>
.options-user-img {
  border-radius: 50%;
  width: 68px;
  height: 68px;
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
