<template>
  <div>
    <BoxContainer>
        <Table
            :title="selectedTabLabel"
            :headers="headers"
            :rows="rows"
            :headerActions="headerActions"
            :selected="isBrokerOrBypass ? selected : undefined"
            @selected="selected = $event"
            rowId="value"
            hidePagination
            @headerAction-SEND_MAIL="sendEmeil"
        >
            <template #Bezeichnung="row">
                <DownloadLink 
                    v-if="row.isPDF"
                    :href="row.value"
                    :title="row.Bezeichnung"
                    :filename="`${row.Bezeichnung}.pdf`"
                />
                <a v-else :href="row.value" target="_blank">{{ row.Bezeichnung }}</a>
            </template>
        </Table>
    </BoxContainer>
  </div>
</template>

<script>
import FONDSINFO_TYPES from "@/store/fondsinfo/types";
import CORE_TYPES from '@/store/core/types';
import MAILCOMPOSER_TYPES from '@/store/mailcomposer/types';
import BoxContainer from '@/components/core/BoxContainer.vue';
import { mapGetters } from 'vuex';
import Table from "@/components/table2/Table.vue";
import {SlotColumn} from "@/components/table2/table_util.js";
import DownloadLink from '@/components/core/download/DownloadLink.vue';
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';

export default {
  components: {
    BoxContainer,
    Table,
    DownloadLink,
  },
  name: "TabUnterlagen",
  props:{
    selectedTabLabel: {
      type: String
    }
  },
    data() {
        return {
            headers: {
                lockedLeft: [
                    SlotColumn("Bezeichnung", "Bezeichnung"),
                ],
            },
            selected: []
        };
    },
  computed: {
    ...mapGetters({
        unterlagen : FONDSINFO_TYPES.GETTERS.FONDSINFO_UNTERLAGEN_LINKS,
        fundsDetail : FONDSINFO_TYPES.GETTERS.FONDSINFO_DETAILS,
        isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
    }),
    rows() {
        return (this.unterlagen || []).map(item => ({
            Bezeichnung: (item.key == 'KID' ? 'PRIIP-BIB' : item.key),
            value: item.value,
            id: item.id,
            name: item.key + "-" + this.$route.params?.isin,
            isPDF: item.value?.includes('.pdf'),
        }));
    },
    headerActions() {
        return [
            PageHeaderSimpleAction('SEND_MAIL', 'Markierte Dokumente per E-Mail versenden')
            .withDisabled(() => !this.selected.length)
            .withVisible(() => this.isBrokerOrBypass),
        ];
    },
  },
  mounted() {
    if(!this.unterlagen) {
      this.$store.dispatch(FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_UNTERLAGEN_LINKS, 
      {
        isin: this.$route.params?.isin
      });
    }
  },
  methods: {
    async sendEmeil() {
      if (this.selected.length){
        const response = await this.$store.dispatch(FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_UNTERLAGEN_TEMP_ID, 
            this.selected);
        const name = this.fundsDetail.namme + ' (' + this.$route.params.isin + ')';
        const dokumente = this.selected.map(row => row.Bezeichnung).join(', ');
        const mailData = {
          input_subject: 'Fondsberichte aus dem SmartMSC',
          to: [],
          anhang: response.data,
          input_html: `[[Briefanrede]]<br />
<br />
anbei finden Sie die Berichte für den Fonds ${name} zu Ihrer Information.<br />
<br />
Folgende Berichte befinden sich im Anhang:<br />
${dokumente}.<br />
<br />
<br />
[[SIGNATUR]]`
        }
        this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.GET_INIT, mailData)
        this.$router.push({ path: `/communication/mailcomposer-postfach?stateKeys=mailcomposer,init` })
      }
    },
  },

}
</script>
