<template>
  <div>
    <ComboBox
      label="Beziehung"
      :isComponentHalfSize="isComponentHalfSize"
      :values="beziehungArtValues"
      v-model="form.relationArt"
      :disabled="!isEditable"
      @change="dataChangedCombo('relationArt', $event)"
    />
    <InputToggleSwitch
      label="Gesetzlicher Vertreter/in"
      :inLineLabel="true"
      v-model="form.isLegalAgent"
      :disabled="!isEditable"
      @input="dataChanged()"
    />
    <InputToggleSwitch
      label="Zusätzlicher Depotinhaber/in"
      :inLineLabel="true"
      v-model="form.isExtraDepotOwner"
      :disabled="!isEditable"
      @input="dataChanged()"
    />
    <InputToggleSwitch
      :label="vollmachtLabel"
      :inLineLabel="true"
      v-model="form.isMandatory"
      :disabled="!isEditable"
      @input="dataChanged()"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';
import CUSTOMERDATA_TYPES from '@/store/customerData/types';

import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import persoenlicheDatenMixin from '../persoenliche-daten-mixin';

export const RELATION_BEKANNT = 'BEKANNT';

export default {
  mixins: [persoenlicheDatenMixin],
  props: {
    relationship: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    isComponentHalfSize: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
  data() {
    return {
      form: {
        relationArt: null,
        isLegalAgent: false,
        isExtraDepotOwner: false,
        isMandatory: false,
      },
    };
  },
  watch: {
    relationship: {
      handler() {
        this.form = {
          relationArt: this.relationship?.relation?.value || RELATION_BEKANNT,
          isLegalAgent: this.relationship?.isLegalAgent || false,
          isExtraDepotOwner: this.relationship?.isExtraDepotOwner || false,
          isMandatory: this.relationship?.isMandatory || false,
        };
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
      customerDataConfig: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_CONFIG,
      customerData: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA,
    }),
    isFA() {
      return !!this.loginData?.rights?.isFA;
    },
    vollmachtLabel() {
      return this.isFA ? 'Zeichnungsberechtigte Person' : 'Bevollmächtigte Person';
    },
    fullName() {
      const personalDataAddress = this.customerData?.personalDataAddress;
      const titles = personalDataAddress?.titles || '';
      const firstName = personalDataAddress?.firstName || '';
      const lastName = personalDataAddress?.lastName || '';
      return (`${titles} ${lastName} ${firstName}`).trim();
    },
    beziehungArtValues() {
      const values = this.customerDataConfig?.relations || [];
      return [...values].map((item) => ({
        ...item,
        label: item.display ? `${item.display} ${this.fullName}` : '',
      }));
    },
  },
  methods: {
    dataChanged() {
      this.$emit('change', {
        relation: {
          value: this.form?.relationArt,
        },
        isLegalAgent: this.form?.isLegalAgent,
        isExtraDepotOwner: this.form?.isExtraDepotOwner,
        isMandatory: this.form?.isMandatory,
      });
    },
    dataChangedCombo(field, value) {
      this.form[field] = value;
      this.dataChanged();
    },
  },
  components: {
    ComboBox,
    InputToggleSwitch,
  },
}
</script>
