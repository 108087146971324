<template>
	<div v-if="isInputField(header, content)">
		<InputField v-model="content.value" @input="$emit('input', {header, value: $event})"></InputField>
	</div>
	<div v-else-if="header.dataType === 'String' && content && Object.keys(content).some(key => key ==='value')" 
		:class="[header.align ? `text-${header.align}` : 'text-left']">
		{{content.value}}
	</div>
	<div v-else-if="header.dataType === 'String'" :class="[header.align ? `text-${header.align}` : 'text-left']">
		{{content}}
	</div>
	<div v-else-if="header.dataType === 'Link'" class="text-left">
		<a :href="content" target="_blank">{{content}}</a>
	</div>
  <div v-else-if="header.dataType === 'Currency'"
    class="currency-collumn"
    :class="[keepTextLeft ? 'text-left' : 'text-right']"
  >
		<span v-if="isNumber(content || 0)"><CurrencyLabel :value="strToNumber(content)"/></span>
	</div>
	<div v-else-if="header.dataType === 'CurrencyNoLabel'"
    class="currency-collumn"
    :class="[keepTextLeft ? 'text-left' : 'text-right']"
  >
		<span v-if="isNumber(content || 0)"><CurrencyLabel :value="strToNumber(content)" symb=""/></span>
	</div>
  <div
    v-else-if="header.dataType === 'NumberInt' || header.dataType === 'Integer'"
    :class="[keepTextLeft ? 'text-left' : 'text-right']"
  >
		{{content | numberInt}}
	</div>
  <div
    v-else-if="header.dataType === 'Number' || header.dataType === 'Double'"
    :class="[keepTextLeft ? 'text-left' : 'text-right']"
  >
		{{content | number}}
	</div>
  <div
    v-else-if="header.dataType === 'Procent'"
    :class="[keepTextLeft ? 'text-left' : 'text-right']"
  >
    <span v-if="isNumber(content)">
      <CurrencyLabel :value="content" symb="%" />
    </span>
        <span v-else>{{content}}</span>
	</div>
  <div
    v-else-if="/Decimal\d.*/.test(header.dataType)"
    :class="[keepTextLeft ? 'text-left' : 'text-right']"
  >
		{{getDecimal(content, header.dataType)}}
	</div>
  <div
    v-else-if="header.dataType==='DateYMD'"
  >
		{{content | dateYMD }}
	</div>
	<div v-else-if="header.dataType === 'ComboBoxSelection'" :class="[keepTextLeft ? 'text-left' : 'text-right']">
		{{comboBoxLabel(header, content)}}
	</div>
	<div v-else-if="header.dataType === 'Image'" class="text-left">
    <div :style="{ background: content }" class="image" />
	</div>
	<div v-else-if="header.dataType === 'Action'">
		<slot></slot>
	</div>
  <div v-else-if="header.dataType === 'tableAction'">
    <TableActions :element="row" :actions="actions" :rowIndex="rowIndex" @execute-action="clickAction"/>
  </div>
	<div v-else-if="header.dataType === 'Slot'">
		<slot :name="header.key" :data="{tableData, row}"></slot>
	</div>
	<div v-else-if="header.dataType === 'Company'">
		<img
			v-if="content.logoUrl"
			:alt="content.shortName"
			:src="apiAddress + content.logoUrl"
			:title="content.shortName"
			width="40"
		/>
	</div>
	<div v-else-if="header.dataType === 'Boolean'">
		<InputCheckBoxItem :value="content" :checked="content" @input="$emit('selected-cell', $event)" class="my-0" />
	</div>
	<div v-else>
		<div v-if="typeof content === 'string' && content">
			{{content}}
		</div>
		<div v-else>
      <!-- this tag is intended to be empty, don't remove it -->
      <p></p>
		</div>
	</div>
</template>

<script>
import InputCheckBoxItem from "@/components/core/forms/checkbox/InputCheckBoxItem.vue";
import InputField from "@/components/core/forms/InputField";
import CurrencyLabel from "@/components/core/CurrencyLabel.vue";
import FinancialCalculator from "@/components/retirementScenario/financialCalculator";
import TableActions from './TableActions.vue'
import { mapGetters } from 'vuex'
import CORE_TYPES from '@/store/core/types'

export default {
  props: {
    content: {
			default: "",
			required: true,
    },
    header: {
      type: Object,
			default: () => {},
			required: true,
		},
		keepTextLeft: {
			type: Boolean,
      default: false,
		},
		tableData: {
      type: Object,
      default: () => ({
        headers: {},
        records: [],
      }),
    },
    row: {},
	rowIndex: {
		tpy: Number,
		default: -1
	},
    actions: {
      type: Array,
      default: () => []
		}
	},

	computed: {
		...mapGetters({
			apiAddress: CORE_TYPES.GETTERS.API_ADDRESS_LEGACY
		}),
		comboBoxLabel() {
			return (header, content)=>{
				if (content && header['dataType'] === 'ComboBoxSelection') {
					return header.validDataSelection.find(s=>s.value === content).label;
				} else {
					return content;
				}
			}
		},
		isInputField() {
			return (header, content)=> {
				let e = header.dataType === 'String'  && content && Object.keys(content).some(key => key ==='value') 
				&& (content.editable || content.isEditable);
				return e;
			}
		}
	},

  methods: {
    isNumber(val) {
			const newVal = this.strToNumber(val);
      return typeof newVal === 'number' && !isNaN(newVal);
    },
		strToNumber(val) {
			return FinancialCalculator.convertStringToNumber(val);
		},
    clickAction(event){
      this.$emit("execute-action", event);
    },
    getDecimal(value, type) {
      if (value === null || value === undefined || value === '') {
        return '';
      } else {
		if (typeof value === 'string') {
			if(isNaN(value)) {
				if(isNaN(value.replace(',', '.'))) {
					return value;
				} else {
					value = parseFloat(value.replace(',', '.'));
				}
			} else {
				value = parseFloat(value);
			}
		}
        const scale = parseInt(type.substr(7,1), 10);
        return value.toLocaleString('de-DE', {style: 'decimal', maximumFractionDigits: scale, minimumFractionDigits: scale }) + type.substr(8);
      }
    }
  },

  filters: {
    currency(value) {
			if (isNaN(value)) {
				return value;
			}
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + "€";
    },
    number(value) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    numberInt(value) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value / 1).toFixed(0);
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    percent(value) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + "%";
    },
    dateYMD(value) {
      return !value || value.length !== 8 ? value : value.substr(6,2) +'.'+ value.substr(4,2) +'.'+ value.substr(0,4)
    }
  },

  components: {
		InputCheckBoxItem,
		CurrencyLabel,
		InputField,
    TableActions
  },
};
</script>

<style scoped>
.image {
    width: 50px;
    height: 50px;
    border-radius: 25px;
}
.currency-collumn {
  white-space: nowrap;
}
</style>
