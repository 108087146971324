export const MUTATION_PREFIX = 'VUBLOCK_MUTATIONS_';
export const ACTIONS_PREFIX = 'VUBLOCK_ACTIONS_';
export const GETTERS_PREFIX = 'VUBLOCK_GETTERS_';

export default {
  MUTATIONS: {
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',

    UPDATE_VUBLOCK_SELECTED: MUTATION_PREFIX + 'UPDATE_VUBLOCK_SELECTED',
    UPDATE_VUBLOCK_GESELL_SELECTED_ID: MUTATION_PREFIX + 'UPDATE_VUBLOCK_GESELL_SELECTED_ID',

    UPDATE_VUBLOCK_GESELL: MUTATION_PREFIX + 'UPDATE_VUBLOCK_GESELL',
    UPDATE_VUBLOCK_FILE: MUTATION_PREFIX + 'UPDATE_VUBLOCK_FILE',
  },

  ACTIONS: {
    GET_VUBLOCK_ALL: ACTIONS_PREFIX + 'GET_VUBLOCK_ALL',

    GET_VUBLOCK: ACTIONS_PREFIX + 'GET_VUBLOCK',
    DELETE_VUBLOCK: ACTIONS_PREFIX + 'DELETE_VUBLOCK',
    CREATE_VUBLOCK: ACTIONS_PREFIX + 'CREATE_VUBLOCK',
    SAVE_VUBLOCK: ACTIONS_PREFIX + 'SAVE_VUBLOCK',

    GET_VUBLOCK_GESELL: ACTIONS_PREFIX + 'GET_VUBLOCK_GESELL',
    GET_VUBLOCK_GESELL_STATUS_ENTRIES: ACTIONS_PREFIX + 'GET_VUBLOCK_GESELL_STATUS_ENTRIES',

    SAVE_VUBLOCK_GESELL_BEMERKUNG: ACTIONS_PREFIX + 'SAVE_VUBLOCK_GESELL_BEMERKUNG',
    SAVE_VUBLOCK_GESELL_BEMERKUNG_FOR_BROKER: ACTIONS_PREFIX + 'SAVE_VUBLOCK_GESELL_BEMERKUNG_FOR_BROKER',

    SET_VUBLOCK_GESELL_FEHLER_ERLEDIGT: ACTIONS_PREFIX + 'SET_VUBLOCK_GESELL_FEHLER_ERLEDIGT',
    VERS_UEBERTRAG_BLOCK_GESELL_MAIL_IS_SENT: ACTIONS_PREFIX + 'VERS_UEBERTRAG_BLOCK_GESELL_MAIL_IS_SENT',

    UPLOAD_VUBLOCK_FILE: ACTIONS_PREFIX + 'UPLOAD_VUBLOCK_FILE',
    DELETE_VUBLOCK_FILE: ACTIONS_PREFIX + 'DELETE_VUBLOCK_FILE',

    UPLOAD_VUBLOCK_GESELL_FILE: ACTIONS_PREFIX + 'UPLOAD_VUBLOCK_GESELL_FILE',
    DELETE_VUBLOCK_GESELL_FILE: ACTIONS_PREFIX + 'DELETE_VUBLOCK_GESELL_FILE',
  },

  GETTERS: {
    VUBLOCK_SELECTED: GETTERS_PREFIX + 'VUBLOCK_SELECTED',
    VUBLOCK_GESELL_SELECTED: GETTERS_PREFIX + 'VUBLOCK_GESELL_SELECTED',
    VUBLOCK_GESELL_ID_SELECTED: GETTERS_PREFIX + 'VUBLOCK_GESELL_ID_SELECTED',

    VIEW_IS_EDIT_ALLOWED: GETTERS_PREFIX + 'VIEW_IS_EDIT_ALLOWED',
    VIEW_IS_BROKER: GETTERS_PREFIX + 'VIEW_IS_BROKER',
  },

  FILE_AUSKUNFTSVOLLMACHT: 'VERS_UB_AUSKVOLLM',
  FILE_EINVERSTAENDNIS: 'VERS_UB_EINVERSTAENDNIS',
}