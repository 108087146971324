<template>
  <div>
    <GesellschaftTitleNavigation
      :headerActions="headerActions"
      @action-NEW_ANSPRECHPARTNER="editOrCreateAnsprechpartner"
    />

    <div class="box__container" :tid="_generateTidFromString('cards__container layout__negative-margin--8')" >
      <Table 
        v-if="rows.length"
        tableId="fbfd1c1b-3e8c-4c41-9101-5f4ff8265777"
        :title="TABLE_TITLE"
        :rows="rows"
        :headers="headers"
        :rowsPerPage="20"
        @click-name="editOrCreateAnsprechpartner"
        @action-DELETE="deleteAnsprechpartner"
      >
        <template v-slot:telefon="row">
          <a   v-if="isTelefon"  :tid="_generateTidFromString(row.telefon)" @click="tapiCall(row.telefon)" >{{ row.telefon }}</a>
          <label   v-else  :tid="_generateTidFromString(row.telefon)">{{ row.telefon }}</label>        
        </template>
        <template v-slot:mobil="row">
          <a   v-if="isTelefon"  :tid="_generateTidFromString(row.mobil)" @click="tapiCall(row.mobil)" >{{ row.mobil }}</a>
          <label   v-else  :tid="_generateTidFromString(row.mobil)">{{ row.mobil }}</label>        
        </template>
      </Table>
      <GhostLoading v-else-if="loading" type="table" :title="TABLE_TITLE" />
      <NoData v-else :title="TABLE_TITLE" content="Keine Ansprechpartner hinterlegt" />
    </div>

  </div>
</template>

<script>
import GESELLSCHAFT_TYPES from '@/store/gesellschaft/types';
import TAPI from '@/store/tapi/types';
import CORE_TYPES from "@/store/core/types";

import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import Table from "@/components/table2/Table.vue";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import GesellschaftTitleNavigation from '@/views/gesellschaft/intern/GesellschaftTitleNavigation.vue'
import axios from 'axios';
import { mapGetters } from "vuex";
import { TextColumn, ActionColumn, SlotColumn, PillColumn, ConfirmedAction } from "@/components/table2/table_util.js";
import { makeQueryParam } from '@/helpers/utils-helper';
import { PageHeaderSimpleAction, PageHeaderDownloadLinkAction } from '@/components/core/header-title-navigation/page-header-utils';

const TABLE_TITLE = 'Ansprechpartner / Kontakte';

const config = {
  defaultSpinner: true
};

export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    Table,
    GhostLoading,
    NoData,
    GesellschaftTitleNavigation,
  },
  data() {
    return {
      TABLE_TITLE,
      recordsAp: [],
      loading: false,
      canEdit: false,
    }
  },
  computed: {
    ...mapGetters({
      gesellschaft: GESELLSCHAFT_TYPES.GETTERS.GET_GESELLSCHAFT,
      isTelefon: TAPI.GETTERS.CAN_OUT_CALL,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      isGesellschaft: CORE_TYPES.GETTERS.IS_GESELLSCHAFT,
    }),
    headers() {
      let headers = {
          lockedLeft: [
            TextColumn("name", "Name").makeConditionalLink('editable'),
          ],
          center: [
            TextColumn("typ", "Typ"),
            TextColumn("email", "E-Mail"),
            SlotColumn("telefon", "Telefon", undefined, 1).makeSortable(),
            SlotColumn("mobil", "Mobil", undefined, 1).makeSortable(),
            TextColumn("fax", "Fax"),
            ...(this.isIntern || this.isGesellschaft ? [PillColumn("hasGesellschaftzugang", "Zugang")] : []),
            TextColumn("zweigstelle", "Zweigstelle"),
          ],
          lockedRight: [],
      }

      if (this.isBeratung) {
        headers.center.push(PillColumn('sichtbarkeit', 'Sichtbar'))
      }

      if (this.rows.some(row => row.actions?.length)) {
        headers.lockedRight.push(ActionColumn('actions', 'Aktionen'))
      }

      return headers
    },
    rows() {
      return this.recordsAp.map(row => ({
        ...row,
        name: row.name || '[Unbenannt]',
        sichtbarkeit: {
          label: row.sichtbar,
          type: row.sichtbar === 'nein' ? 'danger' : 'success',
        },
        hasGesellschaftzugang: {
          label: row.gesellschaftZugang ? 'aktiv' : null,
          type: row.gesellschaftZugang ? 'success' : null,
        },
        actions: this.makeActions(row)
      }))
    },
    headerActions() {
      return [
        PageHeaderSimpleAction('NEW_ANSPRECHPARTNER', 'Neuen Ansprechpartner anlegen')
          .withVisible(() => this.canEdit),
        PageHeaderDownloadLinkAction('SHOW_PDF', 'Ansprechpartner dieser Gesellschaft', 'Ansprechpartner.pdf', '/getAnsprechpartnerPdf', { 
          gesellschaftId: this.$route.params.gesellId,
          gesellArt: this.$route.query.gesellArt
        }).withVisible(() => this.isBeratung)
      ]
    },
    isBeratung() {
      return this.$route.path.includes('beratung')
    },
  },
  mounted() {
    this.getAnsprechpartner();
  },
  methods: {
    getAnsprechpartner() {
      this.loading = true
      
      const params = makeQueryParam({ gesellschaftId: this.$route.params.gesellId, gesellArt: this.$route.query.gesellArt }, true)

      axios.get(`${process.env.VUE_APP_API}/gesellschaftenService/ansprechpartnerList?${params}`, config).then(response => {
        if (response && response.data) {
          this.recordsAp = response.data.recordsAp
          this.canEdit = response.data.canEdit
        } 
      }).finally(() => this.loading = false)
    },
    editOrCreateAnsprechpartner(ap) {
      this.$addBreadcrumb({
        label: `Zurück`,
        fullPath: this.$route.fullPath,
        breadcrumb: 'Ansprechpartner',
      });

      this.$router.push(this.$route.path + `/ansprechpartner/${ap?.id || 'new'}`);
    },
    deleteAnsprechpartner({id}) {
      this.loading = true

      const params = makeQueryParam({ personId: id })

      axios.delete(`${process.env.VUE_APP_API}/gesellschaftenService/deleteAnsprechpartner?${params}`, config)
      .then(() => {
        const deleteIndex = this.recordsAp.findIndex(row => row.id === id);
        if (deleteIndex >= 0) {
          this.recordsAp.splice(deleteIndex, 1);
        }
      }).finally(() => this.loading = false)
    },
    tapiCall(phoneNumber){
       this.$store.dispatch(TAPI.ACTIONS.OPEN_CALL_OUT_DIALOG, {userType:'Gesellschaft' ,userId:this.$route.params.gesellId,phoneNumber:phoneNumber})
    },
    makeActions(row) {
      const actions = []

      if (row.deletable) {
        actions.push(ConfirmedAction("DELETE", 'PhTrash', "Löschen", `Wollen Sie den Ansprechpartner "${row.name || '[Unbenannt]'}" wirklich löschen?`, 'Ansprechpartner löschen', "Löschen"))
      }

      return actions
    },
  },
}
</script>