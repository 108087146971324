import V_P from './types';
import Vue from 'vue'
import { getInitialState } from './index'

export default {
  [V_P.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },
 
  [V_P.MUTATIONS.FILTER](state, payload) {
    state.FILTER = payload
  },
  
  
}