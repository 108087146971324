<template>
  <div class="einnahmen-und-ausgaben-dashboard-card__container">
    <EinnahmenUndAusgabenChart class="einnahmen-und-ausgaben-dashboard-card__content" :summary="summary" />

    <div class="text-right" v-if="!isMobileNativeContext">
      <router-link to="/home/multibanking-account/einnahmen-und-ausgaben">weitere...</router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MULTIBANKING_TYPES from '@/store/multiBanking/types'
import BRIDGE_TYPES from '@/store/bridge/types'
import EinnahmenUndAusgabenChart from '@/components/multiBanking/EinnahmenUndAusgabenChart.vue'

export default {
  computed: {
    ...mapGetters({
      summary: MULTIBANKING_TYPES.GETTERS.SUMMARY,
      isMobileNativeContext: BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT,
    }),
  },
  mounted() {
    this.$store.dispatch(MULTIBANKING_TYPES.ACTIONS.LOAD_ALL_MULTIBANKING_DATA)
  },
  components: {
    EinnahmenUndAusgabenChart,
  }
}
</script>

<style scoped>
.einnahmen-und-ausgaben-dashboard-card__container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.einnahmen-und-ausgaben-dashboard-card__content {
  flex: 1 0 auto;
}
</style>
