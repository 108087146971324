<template>
  <div>
    <FinanceSummary/>
  </div>
</template>

<script>

import FinanceSummary from '@/components/anlegerprofil/FinanceSummary.vue';

export default {
  components: {
    FinanceSummary,
  },
}
</script>

<style scoped>
</style>