var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: "Fondsdepotbank", subtitle: "Übertragung" }
      }),
      _vm.searchingPositions
        ? _c("div", [
            _vm._m(0),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [_c("FondsFinderSuchMaske")],
                1
              )
            ]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("FondsFinderPositionsList", {
                    attrs: {
                      zurueckButtonText: "Abbrechen",
                      addPositionsOnBack: false
                    },
                    on: {
                      goBack: function($event) {
                        return _vm.addFonds($event)
                      }
                    }
                  })
                ],
                1
              )
            ])
          ])
        : _c("div", { staticClass: "antrag-components__container" }, [
            _c(
              "div",
              { staticClass: "box__container" },
              [
                _c("FormHeader", { attrs: { label: "Wertpapiere" } }),
                _c("p"),
                _c("InputField", {
                  attrs: { label: "bisherige Depotnummer" },
                  model: {
                    value: _vm.positionData.bisherDepotnr,
                    callback: function($$v) {
                      _vm.$set(_vm.positionData, "bisherDepotnr", $$v)
                    },
                    expression: "positionData.bisherDepotnr"
                  }
                }),
                _c("InputToggleSwitch", {
                  attrs: {
                    label:
                      "Investmentanteile des/der folgen den/folgender Investmentvermögen/s:"
                  },
                  model: {
                    value: _vm.show,
                    callback: function($$v) {
                      _vm.show = $$v
                    },
                    expression: "show"
                  }
                }),
                _vm.show == true
                  ? _c("Table", {
                      staticClass: "table-container",
                      attrs: {
                        tableId: "f168f001-d40a-4a9a-ba2b-aa8d3ce74e30",
                        tableData: _vm.tableData,
                        cardViewEnabled: false,
                        filterEnabled: false,
                        exportEnabled: false,
                        paginationEnabled: false
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "action",
                            fn: function(props) {
                              return [
                                props.data.key == "Anteile"
                                  ? _c("InputField", {
                                      model: {
                                        value:
                                          _vm.positionData[
                                            "anteile" + props.data.row.index
                                          ],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.positionData,
                                            "anteile" + props.data.row.index,
                                            $$v
                                          )
                                        },
                                        expression:
                                          "positionData['anteile' + props.data.row.index]"
                                      }
                                    })
                                  : _vm._e(),
                                props.data.key == "Gesamtbestand"
                                  ? _c("InputCheckboxItem", {
                                      model: {
                                        value:
                                          _vm.positionData[
                                            "alle" + props.data.row.index
                                          ],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.positionData,
                                            "alle" + props.data.row.index,
                                            $$v
                                          )
                                        },
                                        expression:
                                          "positionData['alle' + props.data.row.index]"
                                      }
                                    })
                                  : _vm._e()
                              ]
                            }
                          },
                          {
                            key: "columnActions",
                            fn: function(props) {
                              return [
                                _c(
                                  "BaseButton",
                                  {
                                    attrs: { isSecondary: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteFond(
                                          props.data.row.index - 1
                                        )
                                      }
                                    }
                                  },
                                  [_c("ph-trash", { attrs: { size: 16 } })],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1508462300
                      )
                    })
                  : _vm._e(),
                _vm.show == true
                  ? _c(
                      "BaseButton",
                      {
                        on: {
                          click: function($event) {
                            _vm.searchingPositions = true
                          }
                        }
                      },
                      [_vm._v("Wertpapiere hinzufügen")]
                    )
                  : _vm._e(),
                _c("FormDivider"),
                _c("FormHeader", { attrs: { label: "Sonstiges" } }),
                _c("p"),
                _c("FormLabel", {
                  attrs: { label: "Weiterhin bitte/n ich/wir Sie," }
                }),
                _c("InputToggleSwitch", {
                  attrs: {
                    label:
                      "den/die Sparplan/-pläne zu meinem/unserem bestehenden Depot zu übernehmen, sonst"
                  },
                  model: {
                    value: _vm.positionData.sparUebernehmen,
                    callback: function($$v) {
                      _vm.$set(_vm.positionData, "sparUebernehmen", $$v)
                    },
                    expression: "positionData.sparUebernehmen"
                  }
                }),
                !_vm.positionData.sparUebernehmen
                  ? _c("InputRadioBoxGroup", {
                      attrs: { values: _vm.spar },
                      on: {
                        change: function($event) {
                          return _vm.sparChanged()
                        }
                      },
                      model: {
                        value: _vm.positionData.sparLoeschen,
                        callback: function($$v) {
                          _vm.$set(_vm.positionData, "sparLoeschen", $$v)
                        },
                        expression: "positionData.sparLoeschen"
                      }
                    })
                  : _vm._e(),
                _c("InputToggleSwitch", {
                  attrs: {
                    label:
                      "den/die Auszahlplan/-pläne zu meinem/unserem bestehenden Depot zu übernehmen, sonst"
                  },
                  model: {
                    value: _vm.positionData.entnahmeUebernehmen,
                    callback: function($$v) {
                      _vm.$set(_vm.positionData, "entnahmeUebernehmen", $$v)
                    },
                    expression: "positionData.entnahmeUebernehmen"
                  }
                }),
                !_vm.positionData.entnahmeUebernehmen
                  ? _c("InputRadioBoxGroup", {
                      attrs: { values: _vm.entnahme },
                      on: {
                        change: function($event) {
                          return _vm.onchange()
                        }
                      },
                      model: {
                        value: _vm.positionData.entnahmeLoeschen,
                        callback: function($$v) {
                          _vm.$set(_vm.positionData, "entnahmeLoeschen", $$v)
                        },
                        expression: "positionData.entnahmeLoeschen"
                      }
                    })
                  : _vm._e(),
                _c("FormLabel", { attrs: { label: "Hinweis:" } }),
                _c("FormLabel", {
                  attrs: {
                    label:
                      "Bei Übertrag des gesamten Anteilbestandes dieses/dieser Investmentvermögen/s \n          wird/werden der/die diesbezüglich evtl. bestehende Auszahlplan/-pläneautomatisch und ohne zusätzliche \n          Mitteilung an den Depotinhaber gelöscht. Entsprechendes gilt für regelmäßige Tauschaufträge von \n          Investmentvermögen, die ggf. zuLasten des Bestandes von Anteilen dieses/dieser o. g. Investmentvermögen/s \n          vorgemerkt sind. Ggf. ist ein neuer Auftrag einzureichen."
                  }
                }),
                _c("InputToggleSwitch", {
                  attrs: {
                    label:
                      "um Übersendung eines entsprechenden Vordrucks, damit ich/wir meinen/unseren Freistellungsauftrag für Kapitalerträge ändern kann/können."
                  },
                  model: {
                    value: _vm.positionData.uebersendung,
                    callback: function($$v) {
                      _vm.$set(_vm.positionData, "uebersendung", $$v)
                    },
                    expression: "positionData.uebersendung"
                  }
                }),
                _c("FormLabel", {
                  attrs: {
                    label:
                      "falls der gesamte Depotbestand (alle Anteile aller Investmentvermögen im jeweiligen Depot) übertragen werden soll"
                  }
                }),
                _c("InputToggleSwitch", {
                  attrs: {
                    label:
                      "um Löschung des o. g. Depots nach Übertragung des Bestandes, d. h. auch Löschung aller Spar- und Auszahlpläne "
                  },
                  model: {
                    value: _vm.positionData.depotLoeschung,
                    callback: function($$v) {
                      _vm.$set(_vm.positionData, "depotLoeschung", $$v)
                    },
                    expression: "positionData.depotLoeschung"
                  }
                }),
                _c("FormLabel", { attrs: { label: "sowie:" } }),
                _c("InputToggleSwitch", {
                  attrs: {
                    label:
                      "Befristung des Freistellungsauftrages für Kapitalerträge zum Jahresende. (Hierfür sind die Unterschriften aller Depotinhaber erforderlich.)"
                  },
                  model: {
                    value: _vm.positionData.befristung,
                    callback: function($$v) {
                      _vm.$set(_vm.positionData, "befristung", $$v)
                    },
                    expression: "positionData.befristung"
                  }
                }),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c("BaseButton", { on: { click: _vm.onBackButton } }, [
                        _vm._v("Zurück zum Formular")
                      ])
                    ],
                    1
                  )
                ])
              ],
              1
            )
          ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("h2", [_vm._v("Wertpapiersuche")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }