<template>
  <BaseToolbarButton 
    title="Undo"
    :icon="icon"
    :active="active"
    :disabled="disabled"
    @click="action"/>
</template>

<script>
import { PhArrowsCounterClockwise } from 'phosphor-vue';
import BaseToolbarButton from '../base-components/BaseToolbarButton.vue';

export default {
  components: {
    BaseToolbarButton,
  },

  props: {
    editor: {
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    icon() {
      return PhArrowsCounterClockwise;
    },
    active() {
      return false
    }
  },

  methods: {
    action() {
      this.editor?.commands.undoInputRule()
    }
  }

}
</script>
