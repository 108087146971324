var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "editModal",
          attrs: { modalTitle: "Tabellen-Eingenschaften" },
          on: {
            onCloseButton: function($event) {
              return _vm.close()
            },
            close: function($event) {
              return _vm.close()
            },
            onConfirmButton: _vm.onConfirmButton
          }
        },
        [
          _vm.isInsertMode
            ? _c("InputField", {
                attrs: { label: "Zeile" },
                model: {
                  value: _vm.internTableForm.rows,
                  callback: function($$v) {
                    _vm.$set(_vm.internTableForm, "rows", $$v)
                  },
                  expression: "internTableForm.rows"
                }
              })
            : _vm._e(),
          _vm.isInsertMode
            ? _c("InputField", {
                attrs: { label: "Spalte" },
                model: {
                  value: _vm.internTableForm.cols,
                  callback: function($$v) {
                    _vm.$set(_vm.internTableForm, "cols", $$v)
                  },
                  expression: "internTableForm.cols"
                }
              })
            : _vm._e(),
          _c("ComboBox", {
            attrs: {
              label: "Rahmengröße",
              values: _vm.borderOptions,
              sortComboboxValues: false
            },
            model: {
              value: _vm.internTableForm.border,
              callback: function($$v) {
                _vm.$set(_vm.internTableForm, "border", $$v)
              },
              expression: "internTableForm.border"
            }
          }),
          _c("InputToggleSwitch", {
            attrs: { label: "Kopfzeile" },
            model: {
              value: _vm.internTableForm.withHeaderRow,
              callback: function($$v) {
                _vm.$set(_vm.internTableForm, "withHeaderRow", $$v)
              },
              expression: "internTableForm.withHeaderRow"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }