import { render, staticRenderFns } from "./ExPostBerechnung.vue?vue&type=template&id=40170c03&scoped=true&"
import script from "./ExPostBerechnung.vue?vue&type=script&lang=js&"
export * from "./ExPostBerechnung.vue?vue&type=script&lang=js&"
import style0 from "@/components/steckbrief/steckbrief.scss?vue&type=style&index=0&id=40170c03&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40170c03",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("P:\\workspaces\\fk0\\fc\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('40170c03')) {
      api.createRecord('40170c03', component.options)
    } else {
      api.reload('40170c03', component.options)
    }
    module.hot.accept("./ExPostBerechnung.vue?vue&type=template&id=40170c03&scoped=true&", function () {
      api.rerender('40170c03', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/berichte/ExPostBerechnung.vue"
export default component.exports