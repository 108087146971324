<template>
  <div class="base-filter-select__container">
    <div class="base-filter-select__title box__title">
      <span>{{title}}</span>
      <div v-if="showSaveButton && hasSearchParameters || $slots.titleToolbar" class="base-filter-select__toolbar-container ml-2">
        <div v-if="showSaveButton && hasSearchParameters" class="base-filter-select__search-parameters">
          <BaseButtonsContainer :minItemsRequired="1" btnAsLink>
            <template v-for="(searchParam, index) of sortedList">
              <button v-if="searchParam && searchParam.visible" :key="index" type="button" 
                class="base-filter-select__search-parameters-item btn btn-clear clickable" :class="searchParamPillType(searchParam)" 
                @click="selectStoredSearchParameter(searchParam); clearSelectedStoredSearchParameter(); setSelectedStoredSearchParameter(searchParam);"
              >{{ searchParam.searchLabel }}</button>
            </template>

            <template v-for="(searchParam, index) of programmaticSearchParameter">
              <button :key="1000 + index" type="button" 
                class="base-filter-select__search-parameters-item btn btn-clear clickable" :class="programmaticSearchParameterPillType(searchParam)" 
                @click="searchParam.onClick(index); clearSelectedStoredSearchParameter(); setProgrammaticSearchParameter(searchParam)" 
              >{{ searchParam.searchLabel }}</button>
            </template>
          </BaseButtonsContainer>
        </div>

        <div v-if="$slots.titleToolbar" class="base-filter-select__toolbar">
          <slot name="titleToolbar" />
        </div>
      </div>
    </div>
  </div> 
</template>
<script>
import BaseButtonsContainer from '@/components/core/BaseButtonsContainer.vue';

const ignoreProperties = (key, value) => {
  return key === 'isDefault' ? undefined : value;
}

export default {
    components: { 
        BaseButtonsContainer,
    },
    props: {
        title: {
          type: String,
          defaut: null
        },
        storedSearchParameter: {
          type: Object,
          default: function () {
            return {};
          },               
        },
        programmaticSearchParameter: {
          type: Array,
          defaut: () => []
        },
        showSaveButton: {
          type: Boolean,
          default: false
        },
     },
    data: function () {
        return {
            selectedStoredSearchParameter: null,
            selectedProgrammaticSearchParameter: null,
            resultCount: null
        };

    },
    computed: {
      sortedList() {
        return this.storedSearchParameter && 
          this.storedSearchParameter.searchParameters 
            && this.storedSearchParameter.searchParameters.sort((a, b) => a.columnOrder - b.columnOrder);
      },
      hasSearchParameters() {
        return !!this.storedSearchParameter?.searchParameters || !!this.programmaticSearchParameter?.length;
      },
     },
    methods: {
        toggleEditStoredParameter() {
            this.editStoredParameter = !this.editStoredParameter
        },
        selectStoredSearchParameter(selectedStoreParam) {
            this.$emit("onSelect",  selectedStoreParam);
        },  
        emitEditFilterEvent() {
          this.$emit("onEditFilter");
        },
        clearSelectedStoredSearchParameter() {
          this.selectedStoredSearchParameter = null;
          this.selectedProgrammaticSearchParameter = null;
        },
        setSelectedStoredSearchParameter(selectedStoreParam) {
          this.$nextTick(() => this.selectedStoredSearchParameter = selectedStoreParam);
        },
        searchParamPillType(searchParam) {
          if(searchParam && this.selectedStoredSearchParameter && JSON.stringify(searchParam, ignoreProperties) === JSON.stringify(this.selectedStoredSearchParameter, ignoreProperties)) {
            return 'primary';
          } else {
            return 'default';
          }
        },
        setProgrammaticSearchParameter(selectedStoreParam) {
          this.$nextTick(() => this.selectedProgrammaticSearchParameter = selectedStoreParam);
        },
        programmaticSearchParameterPillType(searchParam) {
          if(searchParam && this.selectedProgrammaticSearchParameter && JSON.stringify(searchParam, ignoreProperties) === JSON.stringify(this.selectedProgrammaticSearchParameter, ignoreProperties)) {
            return 'primary';
          } else {
            return 'default';
          }
        },
    }

}
</script>
<style scoped lang="scss">
.base-filter-select__container {

  .base-filter-select__title {
    display: flex;
    justify-content: space-between;
  }

  .base-filter-select__toolbar-container {
    display: flex;
    flex: 0 1 auto;
    align-items: center;
    overflow: hidden;

    .base-filter-select__search-parameters {
      flex: 0 1 auto;
      overflow: hidden;
      max-width: 600px;
    }

    .base-filter-select__toolbar {
      display: flex;
      flex: 0 0 auto;
      max-height: 24px;
      margin: 0 0 0 8px;

      > * {
        margin: 0 0 0 8px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

}

.base-filter-select__search-parameters-item {
  line-height: 1.3em;
  text-align: left;
}

.base-filter-select__search-parameters-item.primary {
  color: var(--color-primary);
}

.base-filter-stored-search__container {
  margin-top: 16px;
}

.stored-search__container {
  margin: -4px;
}

.stored-search__container > * {
  margin: 4px;
}

.stored-search-badge { /*TODO none used code should be removed */
  padding: 4px 8px;
  color: var(--color-text);
  background-color: var(--color-box); 
  border: solid 1px var(--color-text);
  border-radius: 24px;
  margin: 8px;
  font-size: 0.85rem;
}
.stored-search-badge__selected {
  background-color: var(--color-primary);
  color: var(--color-box);
}

.parameter-search__input-fields {
  margin: 0 -8px;
}

.parameter-search__input-fields > * {
  margin: 0 8px;
}
.parameter-controls__container {
  margin: 0 -8px;
  color: var(--color-primary);
}

.parameter-controls__container > * {
  margin: 0 8px;
}

.grid__container {
  margin: 8px 0; 
}

.flex-layout {
  display: flex;
  flex-flow: wrap;
}

.pill__container {
  margin-right: 8px;
}

/** Responsive */
@media screen and (max-width: 767px) {
  .base-filter-select__container {
    .base-filter-stored-search__container {
      margin-top: 8px;
    }
  }
}

</style>

<style scoped lang="scss">
::v-deep .base-buttons__container .base-buttons--content {
  margin: 0;
}

::v-deep .base-buttons__container .base-buttons--content .base-buttons--main > * {
  margin: 0 8px;
}
::v-deep .base-buttons__container .base-buttons--content .base-buttons--dropdown button {
  padding-top: 0;
  padding-bottom: 0;
}
</style>
