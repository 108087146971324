var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isFirma
    ? _c("Firma", {
        ref: "stammdaten",
        attrs: { substepProperty: _vm.substepProperty }
      })
    : _c("Privat", {
        ref: "stammdaten",
        attrs: { substepProperty: _vm.substepProperty }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }