<template>
  <div class="password-policies__container">
    <BaseCollapsable v-if="isCollapsable">
      <template #title>
        <div class="text-bold">{{ TITLE }}</div>
      </template>
      <template #content>
        <ul class="password-policies--list">
          <li v-for="(policyText, index) in POLICIES" :key="index">
            <span v-html="sanitize(policyText)" />
          </li>
        </ul>
      </template>
    </BaseCollapsable>
    <template v-else>
      <div class="text-bold">{{ TITLE }}</div>
      <ul class="password-policies--list">
        <li v-for="(policyText, index) in POLICIES" :key="index">
          <span v-html="sanitize(policyText)" />
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
import store from '@/store';
import LOG_TYPES from '@/store/log/types';

import BaseCollapsable from '@/components/core/BaseCollapsable.vue';
import { minLength, createRule } from '@/mixins/validator/rules';
import { sanitize } from '@/helpers/string-helper';

const MIN_PASSWORD_LENGTH = 10;
const MIN_DENIED_KEYWORD_LENGTH = 2;

const TITLE = 'Passwortrichtlinien';
const POLICIES = [
  `Das Passwort muss mindestens eine Länge von ${MIN_PASSWORD_LENGTH} Zeichen haben`,
  'Es muss zumindest ein Großbuchstabe, ein Kleinbuchstabe, eine Zahl und ein Sonderzeichen enthalten sein',
  'Es darf keine Umlaute und kein Eszett enthalten',
  'Die Nutzerkennung und der eigene Name dürfen nicht Teil des neuen Passworts sein',
];

/**
 * Common new password rules (min length, upper/lower case, and etc.)
 */
export const NEW_PASSWORD_COMMON_RULES = [
  // min password length rule
  minLength(MIN_PASSWORD_LENGTH, `Das neue Passwort muss mindestens ${MIN_PASSWORD_LENGTH} Zeichen lang sein`),

  // no space in the start / end rule
  createRule((value) => !/^\s|\s$/.test(value))
    .withMessage('Leerzeichen am Anfang und/oder Ende des Passworts sind nicht zulässig'),

  // upper
  createRule((value) => value && new RegExp("[A-Z]+").test(value))
    .withMessage('mindestens ein Großbuchstaben'),

  // lower
  createRule((value) => value && new RegExp("[a-z]+").test(value))
    .withMessage('mindestens ein Kleinbuchstaben'),
  
  // number
  createRule((value) => value && new RegExp("[0-9]+").test(value))
    .withMessage('mindestens eine Zahl'),
  
  // special character
  createRule((value) => value && new RegExp("[~`!@#$%^&*()\\-_+={}\\[\\]|;:\"<>,.\\\\/?€]+").test(value))
    .withMessage('mindestens ein Sonderzeichen'),

  // no umlaut allowed rule
  createRule((value) => !new RegExp("[ÄÖÜäöü]+").test(value))
    .withMessage('Es darf keine Umlaute enthalten'),

  // no eszett allowed rule
  createRule((value) => !new RegExp("[ß]+").test(value))
    .withMessage('Es darf kein Eszett enthalten'),
];

/**
 * creates a rule for denied keywords
 * 
 * @param fn - should return an array of denied keywords
 * @returns validation rule
 */
export function deniedKeywordsRule(fn) {
  if (!fn) {
    store.dispatch(LOG_TYPES.ACTIONS.ERROR, "deniedKeywordsRule >>> fn must be passed");
    return;
  }

  return createRule((value, self) => {
    const deniedKeywords = fn(self) ?? [];
    const pipedKeywords = deniedKeywords
      .flatMap(dk => dk.split(' '))
      .filter(dk => dk.length > MIN_DENIED_KEYWORD_LENGTH)
      .map(dk => dk.toLowerCase())
      .join('|');
    return !value || !pipedKeywords || !new RegExp(`(${pipedKeywords})+`).test(value?.toLowerCase());
  }).withMessage("der eigene Name dürfen nicht Teil des neuen Passworts sein");
}

/**
 * creates a rule for password confirmation
 * 
 * @param fn - should return a password
 * @returns validation rule
 */
export function confirmPasswordRule(fn) {
  if (!fn) {
    store.dispatch(LOG_TYPES.ACTIONS.ERROR, "confirmPasswordRule >>> fn must be passed");
    return;
  }

  return createRule((value, self) => {
    const password = fn(self);
    return value === password;
  }).withMessage('Fehler: Passwörter stimmen nicht überein!');
}

export default {
  props: {
    isCollapsable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      TITLE,
      POLICIES,
    };
  },
  methods: {
    sanitize(html) {
      return sanitize(html);
    },
  },
  components: {
    BaseCollapsable,
  },
}
</script>

<style lang="scss" scoped>
.password-policies__container {
  margin: 0 0 1rem;

  &:last-child {
    margin-bottom: 0;
  }

  .password-policies--list {
    line-height: 1.2em;
    margin: .5rem 0 0;

    > li {
      margin-top: .375rem;

      &:first-child {
        margin-top: 0;
      }
    }
  }
}
</style>
