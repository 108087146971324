var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        "data-name": "Layer 1",
        width: "1123",
        height: "672.43077",
        viewBox: "0 0 1123 672.43077"
      }
    },
    [
      _c("title", [_vm._v("city girl")]),
      _c("path", {
        attrs: {
          d:
            "M962.121,190.78847C833.082,91.32418,653.55921,87.26,522.58873,184.17153,453.3542,235.4026,399.67958,313.78159,409.44548,433.122c3.45124,42.2125,13.05755,76.84237,27.07243,105.19117,56.76478,114.92346,289.91181,110.49821,302.86024,110.48138q12.58312-.01256,25.16627.5626a551.94249,551.94249,0,0,1,87.406,11.0507c41.05365,8.5567,93.31754,12.856,140.01415-3.35045a162.301,162.301,0,0,0,23.05855-10.0262c25.66173-13.63706,54.38844-26.66941,71.18275-59.01527,12.621-24.27612,21.81578-54.71582,26.24116-92.629C1128.55269,357.31249,1048.07439,257.04209,962.121,190.78847Z",
          transform: "translate(-38.5 -113.78462)",
          fill: "#f2f2f2"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "726.60828",
          cy: "537.22468",
          rx: "368.98853",
          ry: "34.63685",
          fill: "#3f3d56"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "708.58297",
          cy: "537.22468",
          rx: "343.1876",
          ry: "16.25812",
          opacity: "0.1"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "898.439 73.342 898.439 112.201 847.849 112.201 847.849 535.988 953.429 535.988 953.429 128.332 953.429 112.201 953.429 73.342 898.439 73.342",
          fill: "#575a89"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "619.825 334.359 619.825 267.639 555.304 267.639 555.304 334.359 531.109 334.359 531.109 536.721 643.287 536.721 643.287 334.359 619.825 334.359",
          fill: "#575a89"
        }
      }),
      _c("rect", {
        attrs: {
          x: "543.57288",
          y: "442.87196",
          width: "87.25013",
          height: "94.58207",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "543.57288",
          y: "442.87196",
          width: "13.93069",
          height: "94.58207",
          opacity: "0.1"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "810.456 52.079 810.456 74.808 681.413 74.808 681.413 52.079 643.287 52.079 643.287 537.454 847.849 537.454 847.849 52.079 810.456 52.079",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "810.45563",
          y: "52.07936",
          width: "28.59458",
          height: "22.72903",
          opacity: "0.1"
        }
      }),
      _c("rect", {
        attrs: {
          x: "643.28732",
          y: "52.07936",
          width: "28.59458",
          height: "485.37467",
          opacity: "0.1"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "497.382 190.653 497.382 172.323 478.319 172.323 478.319 190.653 443.858 190.653 443.858 537.454 531.109 537.454 531.109 190.653 497.382 190.653",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "478.31858",
          y: "172.32324",
          width: "10.26472",
          height: "18.32986",
          opacity: "0.1"
        }
      }),
      _c("rect", {
        attrs: {
          x: "443.85845",
          y: "190.6531",
          width: "10.26472",
          height: "346.80094",
          opacity: "0.1"
        }
      }),
      _c("rect", {
        attrs: {
          x: "810.45563",
          y: "239.04393",
          width: "28.59458",
          height: "298.41011",
          opacity: "0.1"
        }
      }),
      _c("rect", {
        attrs: {
          x: "504.71358",
          y: "190.6531",
          width: "26.395",
          height: "63.78791",
          opacity: "0.1"
        }
      }),
      _c("rect", {
        attrs: {
          x: "674.81467",
          y: "113.66769",
          width: "41.05888",
          height: "17.59666",
          fill: "#ff6584"
        }
      }),
      _c("rect", {
        attrs: {
          x: "762.0648",
          y: "294.7667",
          width: "41.05888",
          height: "17.59666",
          fill: "#ff6584"
        }
      }),
      _c("rect", {
        attrs: {
          x: "674.81467",
          y: "421.60933",
          width: "41.05888",
          height: "17.59666",
          fill: "#ff6584"
        }
      }),
      _c("rect", {
        attrs: {
          x: "467.32067",
          y: "269.83809",
          width: "41.05888",
          height: "17.59666",
          fill: "#ff6584"
        }
      }),
      _c("rect", {
        attrs: {
          x: "567.0351",
          y: "453.13668",
          width: "41.05888",
          height: "17.59666",
          fill: "#ff6584"
        }
      }),
      _c("rect", {
        attrs: {
          x: "552.37121",
          y: "349.75628",
          width: "70.38666",
          height: "29.32777",
          opacity: "0.1"
        }
      }),
      _c("rect", {
        attrs: {
          x: "860.31285",
          y: "145.92824",
          width: "70.38666",
          height: "29.32777",
          opacity: "0.1"
        }
      }),
      _c("rect", {
        attrs: {
          x: "860.31285",
          y: "208.24976",
          width: "70.38666",
          height: "29.32777",
          opacity: "0.1"
        }
      }),
      _c("rect", {
        attrs: {
          x: "860.31285",
          y: "270.57129",
          width: "70.38666",
          height: "29.32777",
          opacity: "0.1"
        }
      }),
      _c("rect", {
        attrs: {
          x: "860.31285",
          y: "332.89281",
          width: "70.38666",
          height: "29.32777",
          opacity: "0.1"
        }
      }),
      _c("rect", {
        attrs: {
          x: "860.31285",
          y: "395.21433",
          width: "70.38666",
          height: "29.32777",
          opacity: "0.1"
        }
      }),
      _c("rect", {
        attrs: {
          x: "860.31285",
          y: "457.53585",
          width: "70.38666",
          height: "29.32777",
          opacity: "0.1"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "977.624 278.636 977.624 198.718 926.3 198.718 926.3 278.636 907.97 278.636 907.97 537.454 995.221 537.454 995.221 278.636 977.624 278.636",
          fill: "#3f3d56"
        }
      }),
      _c("rect", {
        attrs: {
          x: "926.30034",
          y: "198.71824",
          width: "16.13028",
          height: "79.91819",
          opacity: "0.1"
        }
      }),
      _c("rect", {
        attrs: {
          x: "907.97048",
          y: "278.63642",
          width: "16.13028",
          height: "258.81761",
          opacity: "0.1"
        }
      }),
      _c("rect", {
        attrs: {
          x: "951.96215",
          y: "408.41183",
          width: "43.25847",
          height: "25.6618",
          opacity: "0.1"
        }
      }),
      _c("rect", {
        attrs: {
          x: "953.42853",
          y: "370.28572",
          width: "41.05888",
          height: "17.59666",
          fill: "#ff6584"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M1161.5,501.83507c0,65.32116-38.83239,88.12887-86.73457,88.12887s-86.73458-22.80771-86.73458-88.12887,86.73458-148.42,86.73458-148.42S1161.5,436.51391,1161.5,501.83507Z",
          transform: "translate(-38.5 -113.78462)",
          fill: _vm.color
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "1033.106 466.187 1033.994 411.519 1070.962 343.887 1034.133 402.943 1034.533 378.362 1060.011 329.431 1034.638 371.858 1034.638 371.858 1035.357 327.648 1062.639 288.692 1035.469 320.696 1035.918 239.63 1033.098 346.946 1033.33 342.519 1005.591 300.06 1032.885 351.018 1030.3 400.393 1030.223 399.082 998.246 354.4 1030.127 403.712 1029.803 409.887 1029.745 409.979 1029.772 410.486 1023.215 535.755 1031.976 535.755 1033.027 471.051 1064.83 421.861 1033.106 466.187",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M525.82736,502.542c0,65.32116-38.83239,88.12886-86.73458,88.12886s-86.73457-22.8077-86.73457-88.12886,86.73457-148.42,86.73457-148.42S525.82736,437.22079,525.82736,502.542Z",
          transform: "translate(-38.5 -113.78462)",
          fill: _vm.color
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "397.433 466.894 398.321 412.226 435.29 344.594 398.461 403.65 398.86 379.069 424.338 330.138 398.966 372.564 398.966 372.565 399.684 328.355 426.967 289.399 399.797 321.403 400.246 240.337 397.425 347.653 397.657 343.226 369.918 300.767 397.212 351.724 394.628 401.099 394.551 399.789 362.573 355.107 394.454 404.419 394.131 410.593 394.073 410.686 394.099 411.193 387.542 536.461 396.303 536.461 397.354 471.758 429.157 422.568 397.433 466.894",
          fill: "#3f3d56"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "207",
          cy: "644.43077",
          rx: "207",
          ry: "28",
          fill: "#3f3d56"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "207",
          cy: "644.43077",
          rx: "162",
          ry: "21.91304",
          opacity: "0.1"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "177.72964",
          cy: "201.80379",
          r: "43.97344",
          fill: "#2f2e41"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "189.7391",
          cy: "216.55407",
          r: "24.61118",
          fill: "#ffb9b9"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M214.47352,343.27s6.25708,15.017-3.33711,19.18838-5.83994,28.78257-5.83994,28.78257L242.839,383.3153s0-10.42847-7.5085-15.017S245.34179,343.27,245.34179,343.27Z",
          transform: "translate(-38.5 -113.78462)",
          fill: "#ffb9b9"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "163.877 340.444 165.128 354.21 221.442 363.804 216.019 338.359 163.877 340.444",
          fill: "#ffb9b9"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M201.54222,564.77064l2.0857,58.39942s-10.01133,23.7769-2.0857,67.57647,2.0857,55.89659,2.0857,55.89659l28.36543,3.75424L230.32479,734.129l-6.67421-14.18271,2.92-80.92491s12.9313-30.034,13.34843-35.87393-.83427-48.38809-.83427-48.38809Z",
          transform: "translate(-38.5 -113.78462)",
          fill: "#ffb9b9"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M201.54222,564.77064l2.0857,58.39942s-10.01133,23.7769-2.0857,67.57647,2.0857,55.89659,2.0857,55.89659l28.36543,3.75424L230.32479,734.129l-6.67421-14.18271,2.92-80.92491s12.9313-30.034,13.34843-35.87393-.83427-48.38809-.83427-48.38809Z",
          transform: "translate(-38.5 -113.78462)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M210.30214,726.62046s0-5.42281-2.50284-5.83994-5.83994-1.66856-5.83994,0-.41714,13.34843-1.25141,15.017-2.50284,7.92563-1.25142,10.42847c0,0-3.75425,9.59419-.83428,10.42846s19.60552.83428,19.60552.83428l.83428-4.58852s37.12534,11.67988,45.051,1.25141c0,0,3.75425-7.50849-2.92-9.17705,0,0-15.017.41714-20.4398-5.83994S224.902,718.27768,224.902,718.27768s-8.34277,1.25142-7.5085,6.67422v1.66856Z",
          transform: "translate(-38.5 -113.78462)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M224.48485,566.02205s4.17139,29.19971,18.77124,50.47379c0,0,7.5085,17.51982,6.25709,22.94263s-1.25142,35.03965,11.26274,61.31939l11.67988,38.37676,5.83995,13.76558,10.01133,4.17138,16.26841,1.25142-9.17706-19.18838-4.58852-10.01133s-8.34278-17.10269-8.34278-32.11968-5.4228-50.47379-5.4228-50.47379,0-80.09063-8.75991-85.09629S224.48485,566.02205,224.48485,566.02205Z",
          transform: "translate(-38.5 -113.78462)",
          fill: "#ffb9b9"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M205.71361,463.82307l-4.17139-.83428V481.76s-1.66855,14.59986-5.4228,17.51983S177.76532,559.765,182.771,563.10208s72.165,20.85694,88.43341,2.92l-11.26275-54.64517s.83428-37.54248,0-37.54248S205.71361,463.82307,205.71361,463.82307Z",
          transform: "translate(-38.5 -113.78462)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M245.34179,379.56105s-20.85694-14.18272-41.71387,4.17139c0,0-7.5085,30.86826-8.75992,38.37676s-9.17705,38.37676-3.33711,41.29673,5.83995-7.09136,20.85694-5.00566,47.13667,1.25141,47.13667-1.25142-7.50849-22.52549-4.58852-25.8626,7.92563-19.18838,2.92-27.114S245.34179,379.56105,245.34179,379.56105Z",
          transform: "translate(-38.5 -113.78462)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M218.22777,463.82307l41.71387,19.60552s16.68555,21.27407,20.4398,9.17705-12.51416-20.85694-12.51416-20.85694L239.919,455.89743l-21.69121,7.09136Z",
          transform: "translate(-38.5 -113.78462)",
          fill: "#ffb9b9"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M221.14774,383.73244s-23.7769,73.41641-2.08569,85.09629,25.02832-10.42846,25.02832-10.42846-14.59985-9.17706-10.01133-20.4398,8.75992-54.228,8.75992-54.228Z",
          transform: "translate(-38.5 -113.78462)",
          opacity: "0.1"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M219.89633,381.2296s-23.77691,73.41642-2.0857,85.0963S242.839,455.89743,242.839,455.89743s-14.59986-9.17705-10.01133-20.43979,8.75991-54.228,8.75991-54.228Z",
          transform: "translate(-38.5 -113.78462)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M277.22547,735.19579s-.41865-5.67973-3.07227-5.92341-6.24545-1.29675-6.11664.45086.59364,14.01309-.15135,15.82511-2.00953,8.49437-.5056,11.01917c0,0-3.19142,10.3386-.0687,10.987s20.59883-.63979,20.59883-.63979l.51956-4.87034s39.786,9.36709,47.28208-2.16736c0,0,3.35244-8.15408-3.76681-9.38642,0,0-15.69629,1.59626-21.85909-4.53863s-18.2125-20.62136-18.2125-20.62136-8.64144,1.9548-7.349,7.57012l.12882,1.74761Z",
          transform: "translate(-38.5 -113.78462)",
          fill: "#2f2e41"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "189.68733",
          cy: "200.64659",
          rx: "29.70136",
          ry: "15.81501",
          fill: "#2f2e41"
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "165.00049",
          cy: "220.70466",
          rx: "4.24305",
          ry: "6.55744",
          fill: "#ffb9b9"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "150.7284",
          cy: "157.83035",
          r: "20.82953",
          fill: "#2f2e41"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }