export const MUTATION_PREFIX = 'ANLAGEZIELE: ';
export const ACTIONS_PREFIX = 'ANLAGEZIELE_ACTIONS_';
export const GETTERS_PREFIX = 'ANLAGEZIELE_GETTERS_';

export default {
  MUTATIONS: {
    GET_ANLAGEZIELE_SUCCESS: MUTATION_PREFIX + 'GET_ANLAGEZIELE_SUCCESS',
    GET_ANLAGEZIELE_PLANEN_SUCCESS: MUTATION_PREFIX + 'GET_ANLAGEZIELE_PLANEN_SUCCESS',
    UPDATE_ZEILE: MUTATION_PREFIX + 'UPDATE_ZEILE',
    UPDATE_POSITION: MUTATION_PREFIX + 'UPDATE_POSITION',
    SET_POSITIONS_TEMP: MUTATION_PREFIX + 'SET_POSITIONS_TEMP',
  },
  ACTIONS: {
    GET_ANLAGEZIELE: ACTIONS_PREFIX + 'GET_ANLAGEZIELE',
    GET_ANLAGEZIELE_PLANEN: ACTIONS_PREFIX + 'GET_ANLAGEZIELE_PLANEN',
    SAVE_ANLAGEZIELE_PLANEN: ACTIONS_PREFIX + 'SAVE_ANLAGEZIELE_PLANEN',
    UPDATE_POSITION: ACTIONS_PREFIX + 'UPDATE_POSITION',
  },
  GETTERS: {
    COMBOBOX_VALUES: GETTERS_PREFIX + 'COMBOBOX_VALUES',
    ZEILEN: GETTERS_PREFIX + 'ZEILEN',
    POSITIONS_ANLAGEZIELE: GETTERS_PREFIX + 'POSITIONS_ANLAGEZIELE',
    SCHIENE: GETTERS_PREFIX + 'SCHIENE',
  }
}
