import actions from './actions';
import mutations from './mutations';
import getters from './getters';

/**
 * mutations: synchronous operation responsible for changing the state
 * actions: asynchronous operation responsible for calling external resources and commiting mutations 
 */

export function getInitialState() {
  return {
   
    CONNECT: null,
    LINE: null, 
    PHONE_NUMBER: null,
  
    WEB_SOCKET:null,
    WEB_SOCKET_OPENED:false,
    OPEN_INCOMMING_DIALOG:null,
    MODAL_PANEL_OPEN:false,
    CAN_TAPI:false,
    CAN_TAPI_HOST:false,
    CAN_TAPI_BUTTON:false,

    ACTIVE_CALL:false,
    HOST:false,
    CALLER:null,
    DIRECTION:null,
    TAPI_PARAMS:null,
    START_CALL_DATE:null,
    
    CALL_OUT_USER_TYPE:null,
    CALL_OUT_US_ID:null,
    CALL_OUT_NUMBER:null,
    RINGING:false,
    LINES:null
  }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}