var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "box norm_button divider",
      on: {
        click: function($event) {
          return _vm.addExpresion("/")
        }
      }
    },
    [_vm._v("/")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }