<template>
  <div class="vertical-stepper" :class="{'vertical-stepper--collapsed': collapsed, 'vertical-stepper--collapsable': showCollapse}">
    <div v-if="showCollapse" class="vertical-stepper--collapse-toggle__container">
      <button type="button" class="vertical-stepper--collapse-toggle btn-clear clickable" 
        @click="toggleCollapse()"><PhCaretLeft :size="24" /></button>
    </div>
    <div class="vertical-stepper--items">
      <div class="vertical-stepper--items__wrap">
        <template v-if=" stepsComputed && stepsComputed.length">
          <div v-for="(step, index) of stepsComputed" :key="index + lastUpdated" class="vertical-stepper--item">
            <VerticalStepItem 
              :step="step"
              :stepType="stepType"
              :isCurrent="selectedStepKey === step.stepKey"
              :showSaveSpinner="showSaveSpinner && stepClicked === step.stepKey"
              :collapsed="collapsed"
              @click="onClickStop(step.stepKey)"/>
            <VerticalSubstepItem 
              v-for="(substep, subIndex) of getSubsteps(step)" 
              v-show="showLastSubstep(index, step) && !substep.hidden"
              :isCurrent="selectedSubstepKey === substep.substepKey"
              :key="subIndex + lastUpdated" 
              :substep="substep"
              :collapsed="collapsed"
              @click="onClickSubstep(step.stepKey, substep.substepKey)"/>
          </div>
        </template>
        <GhostLoading v-else type="block" :config="{ block: { height: 24, }, }" :repeat="10" />
      </div>
    </div>
  </div>
</template>

<script>

import VerticalStepItem from '@/components/vertical-stepper/VerticalStepItem.vue'
import VerticalSubstepItem from '@/components/vertical-stepper/VerticalSubstepItem.vue'
import { PhCaretLeft } from 'phosphor-vue'
import { getStepVisible } from '@/components/antrag/antrag-utils.js'
import GhostLoading from '@/components/core/loading/GhostLoading.vue'
import Block from '@/components/core/loading/ghost-loading/Block.vue'


export default {
  props: {
    currentStep: {
      type: Object,
      default: function() {
        return {};
      }
    },
		stepperName: {
			type: String,
			default: '',
		},
		stepType: {
			type: String,
			default: 'step',
		},
		selectedStepKey: {
			type: String,
			default: '',
		},
    selectedSubstepKey: {
      type: String,
      default: '',
    },
		visible: {
			type: Boolean,
			default: true
    },
    disabled: {
			type: Boolean,
			default: false
		},
		stepperService: {
			default: () => null
		},
    antragData: {
    },
    showSaveSpinner: {
      type: Boolean,
      default: false
    },
    showCollapse: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    selectedStepKey(newVal) {
      this.progress(newVal);
      this.updateStepList()
    },
    selectedSubstepKey(newVal) {
      this.progress(this.selectedStepKey, newVal);
      this.updateStepList()
    },
    showSaveSpinner(currentSaveAntragState) {
      if (!currentSaveAntragState) {
        this.stepClicked = null
      }
    },
    stepperService() {
      const stepKey = this.selectedStepKey
      if (stepKey && !this.eventEmitted) {
        this.progress(stepKey)
      }
      this.updateStepList()
    },
    'stepperService.stepListSource': {
      handler(){
        this.updateStepList()
      },
    },
    collapsed: {
      handler(value) {
        this.$emit('collapsed', value);
      },
      immediate: true,
    },
  },
  data() {
    return {
      stepsComputed: null,
      lastUpdated: Date.now(),
      stepClicked: null,
      collapsed: false,
      eventEmitted:false
    }
  },
  methods: {
    showLastSubstep(index, step) {
      const isLast = index === this.stepsComputed?.length -1
      if (!isLast) {
        return true
      }

      const substepList = this.getSubsteps(step)
      return substepList.length > 0 && substepList[0].title 
    },
    getSubsteps(step) {
      if (step?.substeps?.length > 0) {
        return step.substeps
      }

      return [{}]
    },
    onClickStop(stepKey) {
      if (!this.disabled) {
        this.$emit('setStep', stepKey);
        // this.progress(stepKey); // gets executed in watcher selectedStepKey
        this.stepClicked = stepKey
      }
    },
    onClickSubstep(stepKey, substepKey) {
      if (substepKey && !this.disabled) {
        this.$emit('setSubstep', {stepKey, substepKey});
        // this.progress(stepKey, substepKey); // gets executed in watcher selectedSubstepKey
        this.stepClicked = stepKey;
      }
    },
    progress(stepKey, indexOrSubstepname = 0) {
      if (this.stepperService) {
        this.stepperService.progress(stepKey, indexOrSubstepname, this.stepType === 'fixed');
        if (this.stepperService.currentStep) {
          if (this.stepperService.currentStep.actionFunc) {
            this.stepperService.currentStep.actionFunc();
          }
        if(stepKey == 'aktionen'){
          this.$emit('zusammenfassung', stepKey);
          this.eventEmitted = true;       
        }
          const link = this.stepperService.currentStep.link;
          if (link && link !== this.$route.path) {
            this.$router.push({ path: link });
          }
        }
      }
    },
    updateStepList() {
      const completeStepList = this.stepperService.getCurrentStepList()
      const resultList = getStepVisible(completeStepList, this.antragData)
      this.stepsComputed = resultList
      this.lastUpdated = Date.now()
    },
    toggleCollapse() {
      this.collapsed = !this.collapsed;
    },
  },
  mounted() {
    this.updateStepList();
  },
  beforeDestroy() {
    this.collapsed = false;
  },
  components: {
    VerticalStepItem,
    VerticalSubstepItem,
    PhCaretLeft,
    GhostLoading,
    Block,
  }
}
</script>

<style src='./vertical-stepper.scss' lang='scss' scoped></style>

<!-- Global Style -->
<style>
.vertical-stepper-item--title {
  background: var(--color-box);
  border-radius: 4px;
  box-shadow: -2px 2px 3px rgba(85, 85, 85, 0.1);
  box-sizing: border-box;
  color: var(--color-text);
  margin: -20px 0 0 44px;
  padding: 8px 16px;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
}
.vertical-stepper-item--title.vertical-stepper-item--title--selected {
  font-weight: 510;
}
.vertical-stepper-item--title::after {
  content: "";
  box-sizing: border-box;
  border: 0.5em solid #000;
  border-color: transparent transparent var(--color-box) var(--color-box);
  box-shadow: -1px 1px 3px 0 rgba(85, 85, 85, 0.1);
  margin-top: -0.75rem;
  transform-origin: 0 0;
  transform: rotate(45deg);
  position: absolute;
  left: 0em;
  top: 50%;
  width: 0;
  height: 0;
}
</style>
