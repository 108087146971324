var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", { attrs: { title: "Ordnerstruktur" } }),
      _c(
        "div",
        { staticClass: "box__container private-dok-node-tree__container" },
        [
          _c("ContentWithSidebar", {
            attrs: { mobileContent: _vm.mobileContentActive },
            on: {
              mobileContentChange: function($event) {
                return _vm.handleMobileContentChange()
              }
            },
            scopedSlots: _vm._u([
              {
                key: "sidebar",
                fn: function() {
                  return [
                    _vm.folderStructure
                      ? _c("Tree", {
                          staticClass: "strucktur__tree_nodes",
                          attrs: {
                            items: _vm.items,
                            idKey: "folderName",
                            itemSelectedId: _vm.selectedNodeId,
                            uniqueId: "cea83874-82eb-11ee-b962-0242ac120002",
                            defaultCollapsed: false,
                            showArrowRight: false,
                            isCollapsable: false
                          },
                          on: { itemSelect: _vm.selectNode },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "strucktur__tree_node_content"
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "strucktur__tree_node_label"
                                          },
                                          [_vm._v(_vm._s(item.folderName))]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "strucktur__tree_node_pill"
                                          },
                                          _vm._l(
                                            _vm.explanations(item),
                                            function(expl, index) {
                                              return _c(
                                                "div",
                                                { key: index },
                                                [
                                                  _c("Pill", {
                                                    attrs: {
                                                      label: expl,
                                                      type: "info"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            548706782
                          )
                        })
                      : _c("div", [_vm._v("Keine Daten")])
                  ]
                },
                proxy: true
              },
              {
                key: "content",
                fn: function() {
                  return [
                    _vm.folderStructure &&
                    Object.keys(_vm.folderStructure).length
                      ? _c(
                          "div",
                          { staticClass: "ml-4 mt-4" },
                          [
                            _c("EMailAccesOrdnerstrukturSettings", {
                              ref: "settings",
                              attrs: { data: _vm.node }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }