<template>
    <div>
        <div class="box__title">Bitte geben Sie den Inhalt der E-Mail an, die an die Kunden geschickt wird.</div>
        <InputField
            label="Betreff"    
            v-model="betreff"
            @change="updateEmailDataStore({ betreff: $event })"
        />
        <div class="mb-5">
            <TemplateHtmlEditor
                :placeholders="placeholders || {}"
                v-model="html"
                ignoreSenders
                @change="updateEmailDataStore({ html: $event })"
            />
        </div>
    </div>
</template>

<script>
import WERTPAPIERE_ALLE_KUNDEN_TYPES from '@/store/wertpapiereAlleKunden/types';
import mixin from './wertpapiere-mixin.js';
import TemplateHtmlEditor from '@/views/templatesEdit/TemplateHtmlEditor.vue';
import InputField from '@/components/core/forms/InputField.vue';
import { mapGetters } from 'vuex';

export default {
    mixins: [mixin],
    components: {
        TemplateHtmlEditor,
        InputField,
    },
    data() {
        return {
            html: '',
            betreff: '',
            placeholders: {},
        }
    },
    mounted() {
        this.betreff = this.emailData?.betreff;
        this.html = this.emailData?.html;
    },
    computed: {
        // ...mapGetters({
        //     emailData: WERTPAPIERE_ALLE_KUNDEN_TYPES.GETTERS.EMAIL_DATA,
        // }),
    },
    methods: {
        updateEmailDataStore(data = {}) {
            this.$store.commit(WERTPAPIERE_ALLE_KUNDEN_TYPES.MUTATIONS.UPDATE_EMAIL_DATA_STORE, data)
        }
    }
}

</script>

<style>
</style>