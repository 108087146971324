import CUSTOMER_SEARCH_TYPES from './types';

export default {
  [CUSTOMER_SEARCH_TYPES.GETTERS.SEARCH_CUSTOMER](state) {
    return state.searchCustomer;
  },
  [CUSTOMER_SEARCH_TYPES.GETTERS.CUSTOMER_FILTER_SETUP](state) {
    return state.customerFilterSetup;
  },
  [CUSTOMER_SEARCH_TYPES.GETTERS.CUSTOMER_FILTER_SETUP_DEPOT](state) {
    return state.customerFilterSetupDepot;
  },
  [CUSTOMER_SEARCH_TYPES.GETTERS.BROKER_FILTER_SETUP](state) {
    return state.brokerFilterSetup;
  },
  [CUSTOMER_SEARCH_TYPES.GETTERS.CONTACT_FILTER_SETUP](state) {
    return state.contactFilterSetup;
  },
  [CUSTOMER_SEARCH_TYPES.GETTERS.CUSTOMER_FILTER_SETUP_VERS](state) {
    return state.customerFilterSetupVers;
  },
  [CUSTOMER_SEARCH_TYPES.GETTERS.CUSTOMER_WERTPAPIER_COMBO](state) {
    return state.customerWertpapierCombo;
  },
  [CUSTOMER_SEARCH_TYPES.GETTERS.BROKERS](state) {
    return state.brokers;
  },
  [CUSTOMER_SEARCH_TYPES.GETTERS.BROKER_START_PANELS](state) {
    return state.panels;
  },
  [CUSTOMER_SEARCH_TYPES.GETTERS.OPEN_CUSTOMERS](state) {
    return state.lastOpenedCustomers;
  },
  [CUSTOMER_SEARCH_TYPES.GETTERS.SET_SELECTED_CUSTOMERS](state) {
    return state.selectedCustomers;
  },
  [CUSTOMER_SEARCH_TYPES.GETTERS.KUNDEN_LOESCHEN_RESTRICTIONS](state) {
    return state.kundenLoeschenRestrictions;
  },
  [CUSTOMER_SEARCH_TYPES.GETTERS.KUNDEN_LOESCHEN](state) {
    return state.removedCustomers;
  },
  [CUSTOMER_SEARCH_TYPES.GETTERS.CONTACTS](state) {
    return state.contacts;
  },
}