<template>

  <div>
    <PageHeaderTitleNavigation title="Tarif Bearbeiten" />

    <div class="box__container" tid="a0c925e5-8662-454c-a04b-83abacf4d60b" v-if="formData">
      <InputField
        label="Bezeichnung"
        :validateUntouched="true"
        v-model="formData.bezeichnung">
      </InputField> 

      <ComboBox
          label="Gesellschaft"
          v-model="formData.gesellschaft.id" 
          @change="updateSparte($event)"
          :values="gesellschaften"
          :disabled="formData.gesellschaft.disabled"
      />    

      <ComboBox
          label="Sparte"
          v-model="formData.sparte.id" 
          :values="formData.sparte.values"
      />         

      <InputField
        label="Sichtbar für"
        :disabled="true"
        v-model="formData.besitzer">
      </InputField>       

      <InputToggleSwitch v-model="formData.sehenStruktur" label="inklusive Struktur" inLineLabel suppressValidationMessage/>      

       <BaseButton @click="vorgabeTarifdaten()" v-if="isBroker && selectedTarif && selectedTarif.id">Vorgabe Tarifdaten</BaseButton>

      <InputToggleSwitch v-model="formData.inaktiv" label="Tarif wird nicht mehr vertrieben" inLineLabel suppressValidationMessage/>          

      <p>Dokumente für den jeweiligen Tarif hochladen.</p> 

      <DragnDropArea @files="onFileChange" hoverText="Datei hier ablegen" class="explorer__content" :disabled="!this.selectedTarif || !this.selectedTarif.id">
        <Table
          v-if="tarifDokumente && tarifDokumente.documents && tarifDokumente.documents.length" 
          tableId="2e11d715-9529-4343-9733-4b700e43674b"
          class="table-container"
          :headers="tableHeaders"
          :rows="tableRows"
          :rowsPerPage="20"
          :count="resultCount"
          @action="executeAction($event)"
        >

            <template v-slot:bezeichnung="row"> 
                <DownloadLink 
                  :title="row.fileName || 'unbenannt'"
                  :filename="row.fileName"
                  downloadServicePath="/downloadFileById"
                  :queryParams="{
                      id: row.id
                  }"/>
              </template>    
          
        </Table>
        <GhostLoading v-else-if="loading" type="table" />
        <NoData v-else />
      </DragnDropArea>

      <BaseFileSelect @files="onFileChange" v-if="isFormValid">
        Dokumente hochladen
      </BaseFileSelect>

      <BaseModal @onConfirmButton="removeItem()" ref="removeItemConfirmation" modalTitle="Tarif löschen" labelButtonConfirm="Löschen">
        Soll der Eintrage für {{selectedDocument.fileName}} wirklich gelöscht werden?
      </BaseModal>      
    </div>



  </div>
    
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import CORE_TYPES from "@/store/core/types";
import VERSICHERUNGSTARIFE_TYPES from "../../store/versicherungstarife/types";
import { mapGetters } from "vuex";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import Table from "@/components/table2/Table.vue";
import {ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import { PhTrash} from "phosphor-vue";
import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import { SlotColumn} from "@/components/table2/table_util.js";
import DragnDropArea from '@/components/fileUpload/DragnDropArea.vue';
import {uploadFileChunked} from '@/helpers/upload-helper'
import BaseFileSelect from "@/components/fileUpload/BaseFileSelect.vue";
import BaseModal from '@/components/core/BaseModal.vue';
import DownloadLink from '@/components/core/download/DownloadLink.vue'
import { required } from '@/mixins/validator/rules';
import validator from '@/mixins/validator'

export default {
  mixins: [InteractiveHelpCommonsMixin, validator],
  validators: {},
  components: {
    Table,
    OptionMenu,
    PageHeaderTitleNavigation,
    GhostLoading,
    NoData,
    InputField,
    ComboBox,
    InputToggleSwitch,
    BaseButton,
    DragnDropArea,
    BaseFileSelect,
    BaseModal,
    DownloadLink,
  },
  data: function () {
      return {
        resultCount: null,
        loading: false,
        formData : null,
      }
  },
  computed: {
    ...mapGetters({
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      selectedTarif: VERSICHERUNGSTARIFE_TYPES.GETTERS.SELECTED_VERSICHERUNGSTARIF,
      gesellschaften: VERSICHERUNGSTARIFE_TYPES.GETTERS.VERSICHERUNGSTARIFE_GESELLSCHAFTEN,
      tarifDokumente: VERSICHERUNGSTARIFE_TYPES.GETTERS.TARIF_DOKUMENTE,
      selectedDocument: VERSICHERUNGSTARIFE_TYPES.GETTERS.SELECTED_DOCUMENT,
    }),
    tableHeaders() {
        return {
          lockedLeft: [
            SlotColumn('bezeichnung', 'Bezeichnung', 150, 1).makeAlwaysVisible(),
          ],
          lockedRight: [
              ActionColumn("actions", "Aktionen"),
          ],   
        };
    },    
    tableRows () {
      if (!this.tarifDokumente?.documents?.length ) {
        return [];
      }

      const fullActions = [
          SimpleAction("REMOVE_ITEM", PhTrash, "Löschen"),
      ];

      return this.tarifDokumente?.documents.map(data => ({
          id: data.id,
          fileName: data.fileName,
          actions: fullActions,
      }));
    },
    fieldsValidators() {
      
      let validators = {
        bezeichnung : [required('Bezeichnung ist erforderlich!')],
      }
     
      return {formData: validators};
    },
    isFormValid() {
      return this.validation.valid;
    },
       
  },
  mounted() {
    this.formData = this.selectedTarif;

    if(this.selectedTarif?.id) {
      this.loading = true;

      this.retrieveData(this.selectedTarif?.id);
    }

    this.$configureValidators(this.fieldsValidators)
  },
  methods: {
    retrieveData(tarifId) {
      this.$store.dispatch(
              VERSICHERUNGSTARIFE_TYPES.ACTIONS.RETRIEVE_TARIF_DOKUMENTE, {id: tarifId}
            ).then((response) => {
              this.loading = false;
              }
            ); 
    },
    executeAction(actionData) {
      switch (actionData.key) {
        case 'EDIT_ITEM':
          console.log(actionData);
          break;
        case 'REMOVE_ITEM':
            this.confirmItemRemoval(actionData?.row);
          break;     
        case 'SEND_EMAIL':
          console.log(actionData);
          break;                  
      }
    },   
    async saveBeforeLeave() {
      this.$store.dispatch(
              VERSICHERUNGSTARIFE_TYPES.ACTIONS.PERSIST_ROW, this.formData
            );  
    },
    onFileChange(files) {
      this.$store.dispatch(
              VERSICHERUNGSTARIFE_TYPES.ACTIONS.PERSIST_ROW, this.formData
            ).then((response) => {
              

              if (!this.formData?.id) {
                this.formData.id = response?.id;
              }
              

            files.forEach(file => {
              uploadFileChunked(file).then(fileId => {
                this.$store.dispatch(VERSICHERUNGSTARIFE_TYPES.ACTIONS.UPLOAD_TARIF_DOKUMENT, {fileId, id: this.selectedTarif?.id || this.formData.id})
              })
            })              
            });  

    },   
    confirmItemRemoval(row) {
      this.$store.commit(VERSICHERUNGSTARIFE_TYPES.MUTATIONS.SELECTED_DOCUMENT, row);
      this.$refs.removeItemConfirmation.open();
    },
    removeItem() {
      this.$store.dispatch(VERSICHERUNGSTARIFE_TYPES.ACTIONS.REMOVE_DOCUMENT, {tarifId: this.selectedTarif?.id, documentId: this.selectedDocument?.id}).then((response) => {
        this.retrieveData(this.selectedTarif?.id);  
      }); 
    },
    updateSparte(gesellschaftId) {
      this.$store.dispatch(VERSICHERUNGSTARIFE_TYPES.ACTIONS.RETRIEVE_SPARTE, {gesellschaftId}).then((response) => {
        this.$set(this.formData, "sparte",response);
      }); 
    },
    vorgabeTarifdaten() {
        this.$router.push({path: `/intern/versicherungstarife/vorgabeTarifdaten` });
    },
  },

  async beforeRouteLeave(to, from, next) {
    
    if(this.validation.valid){
      await this.saveBeforeLeave();
    }
    
    next();
    
  }


    
}
</script>