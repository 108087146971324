/*
 * rotates an image by 90° clockwise, (or 180 or 270 if you set times to 2 or 3 respectively)
 * imageRef has to be a loaded image element
 * times has to be an integer but may be negative for counter-clockwise rotation
 */
export function rotateImage90Clockwise(imageRef, times = 1, mimeType = 'image/jpeg') {
  times = ((times % 4) + 4) % 4;
  const canvas = document.createElement("canvas");
  if (times % 2 == 0) {
    canvas.width = imageRef.naturalWidth;
    canvas.height = imageRef.naturalHeight;
  }
  else {
    canvas.height = imageRef.naturalWidth;
    canvas.width = imageRef.naturalHeight;
  }
  const ctx = canvas.getContext("2d");
  ctx.translate(canvas.width / 2, canvas.height / 2);
  ctx.rotate(times * Math.PI / 2);
  ctx.drawImage(imageRef, -imageRef.naturalWidth / 2, -imageRef.naturalHeight / 2);
  return canvas;
}


/*
 * creates a WebGL shader of the given type, uploads the source and
 * compiles it.
 */
export function loadShader(gl, type, source) {
  const shader = gl.createShader(type);
  gl.shaderSource(shader, source);
  gl.compileShader(shader);
  if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
    const infoLog = gl.getShaderInfoLog(shader);
    gl.deleteShader(shader); // since the shader isn't returned, clean it up
    throw 'An error occurred compiling the shader: ' + infoLog;
  }
  return shader;
}
  

/*
 * WEBGL SHADERS
 */

// these shaders render one texture from some coordinates to some other coordinates
export const vsSource = `
precision highp float;
attribute vec2 aVertexPosition;
varying highp vec2 vTextureCoord;
void main() {
  gl_Position = vec4(aVertexPosition.xy, 1., 1.);
  vTextureCoord = aVertexPosition.xy * .5 + .5;
}
`;
export const fsSource = `
precision highp float;
varying highp vec2 vTextureCoord;
uniform sampler2D uSampler;
uniform vec2 uTopLeft;
uniform vec2 uTopRight;
uniform vec2 uBottomLeft;
uniform vec2 uBottomRight;

void main() {
  vec2 pos = mix(mix(uBottomLeft, uBottomRight, vTextureCoord.x), mix(uTopLeft, uTopRight, vTextureCoord.x), vTextureCoord.y);
  gl_FragColor = texture2D(uSampler, pos);
}
`;
