import OPEN_SIGNS_TYPES from './types';
import CORE_TYPES from '@/store/core/types'
import { makeQueryParam } from '@/helpers/utils-helper';

export default {
  [OPEN_SIGNS_TYPES.GETTERS.OPEN_SIGNS](state) {
    return state.openSignatures;
  },

  [OPEN_SIGNS_TYPES.GETTERS.OPEN_SIGNS_META](state) {
    return state.openSignaturesMeta;
  },

  [OPEN_SIGNS_TYPES.GETTERS.OPEN_SIGNS_COUNT](state) {
    return state.openSignatures?.count || 0;
  },

  [OPEN_SIGNS_TYPES.GETTERS.IS_COUNTING](state) {
    return state.isCounting === true;
  },

  [OPEN_SIGNS_TYPES.GETTERS.GET_SIGNO_VIEWER_LINK_MAKER](state, getters) {
    return (rawViewFileId, rawNodeId) => {
      const apiAddress = getters[CORE_TYPES.GETTERS.API_ADDRESS]
      const token = getters[CORE_TYPES.GETTERS.GET_TOKEN]

      const viewFileId = encodeURIComponent(rawViewFileId);
      const nodeId = encodeURIComponent(rawNodeId);

      const params = makeQueryParam({ nodeId, viewFileId, token })
      
      return `${apiAddress}/offene_unterschriften/get_link_to_signo_viewer?${params}`;
    }
  },

  [OPEN_SIGNS_TYPES.GETTERS.HAS_OPEN_SIGNS](state) {
    return state.hasOpenSigns === true;
  },

}