<template>
  <div>
    <PageHeaderTitleNavigation :title="title" />

    <SsoHinweise :isSsoKonto="loginName && true" />

    <div class="cards__container">
      <div :class="getClass">
        <SsoKontoOverview />
      </div>
      <div :class="getClass" v-if="showSsoCustomerOverview">
        <SsoCustomerOverview />
      </div>
    </div>
  </div>
</template>

<script>
import SsoKontoOverview from "@/components/core/sso/SsoKontoOverview.vue";
import SsoCustomerOverview from "@/components/core/sso/SsoCustomerOverview.vue";
import SsoHinweise from "@/components/core/sso/SsoHinweise.vue";
import { mapGetters } from "vuex";
import SSO_TYPES from "@/store/sso/types";
import CORE_TYPES from "@/store/core/types";
import PageHeaderTitleNavigation from "@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue";

const DEFAULT_TEXT = "SSO";

export default {
  components: {
    SsoCustomerOverview,
    SsoKontoOverview,
    SsoHinweise,
    PageHeaderTitleNavigation,
  },
  mounted() {
    this.$store.dispatch(SSO_TYPES.ACTIONS.UPDATE_SHOW_SSO_CUSTOMER_OVERVIEW);
  },
  computed: {
    ...mapGetters({
      loginName: SSO_TYPES.GETTERS.LOGIN_NAME,
      fkLoggedIn: CORE_TYPES.GETTERS.IS_LOGGED_IN,
      showSsoCustomerOverview: SSO_TYPES.GETTERS.GET_SHOW_SSO_CUSTOMER_OVERVIEW,
    }),
    getClass() {
      if (this.showSsoCustomerOverview) {
        return this.loginName && this.fkLoggedIn
          ? "card_snd_column"
          : "card_fst_column";
      } else {
        return "";
      }
    },
    title() {
      return DEFAULT_TEXT;
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.cards__container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
}
.card_snd_column,
.card_fst_column {
  padding: 0 8px;
  max-width: 100%;
}
.card_snd_column {
  width: 50%;
}
.card_fst_column {
  width: 100%;
}
@media (max-width: 767px) {
  .card_snd_column {
    width: 100%;
  }
}
</style>