<template>
  <div v-if="customerDataEdited">
    <Table
      v-if="rows.length"
      ref="table"
      :tableId="tableId"
      cardView
      :headers="headers"
      :rows="rows"
      rowId="id"
      :headersSize="isCustomerOnly ? 0 : 5"
      showWeiterLink
      @onNavigate="navigateToCard"
      hidePagination
    >
    </Table>
    <NoData v-else noIcon />
  </div>
</template>


<script>
import BoxContainer from "@/components/core/BoxContainer.vue";
import { PhPencilLine, PhBookOpen } from "phosphor-vue";
import { TextColumn, CurrencyColumn } from "@/components/table2/table_util.js";
import Table from "@/components/table2/Table.vue";
import { mapGetters } from "vuex";
import CUSTOMERDATA_TYPES from "@/store/customerData/types";
import CORE_TYPES from "@/store/core/types";
import persoenlicheDatenMixin from "../persoenliche-daten-mixin";
import stammdatenMixin from "@/components/persoenliche-daten/stammdaten/stammdaten-mixin";
import NoData from '@/components/core/NoData.vue';

export default {
  components: {
    BoxContainer,
    PhPencilLine,
    PhBookOpen,
    Table,
    NoData,
  },
  mixins: [persoenlicheDatenMixin, stammdatenMixin],
  data: function () {
    return {
      title: "Schulbildung und Qualifikationen",
      education: {
        schoolType1: "",
        schoolTime1: "",
        schoolType2: "",
        schoolTime2: "",
        pastOccupation: "",
        qualification: "",
        training: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      customerDataEdited: CUSTOMERDATA_TYPES.GETTERS.GET_CUSTOMER_DATA_EDITED,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      isFK: CORE_TYPES.GETTERS.IS_FK,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      personId: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_ID,
      customerDataConfig: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_CONFIG,
      isCustomerOnly: CORE_TYPES.GETTERS.IS_CUSTOMER_ONLY,
    }),
    headers() {
      const result = {
        center: [],
      };

      result.center.push(TextColumn("schoolType1", "Schultyp"));
      result.center.push(TextColumn("schoolTime1", "von / bis"));
      result.center.push(TextColumn("schoolType2", "Schultyp"));
      result.center.push(TextColumn("schoolTime2", "von / bis"));

      result.center.push(
        TextColumn("pastOccupation", "Frühere berufliche Tätigkeiten")
      );
      result.center.push(
        TextColumn("qualification", "Berufliche Qualifikation / Studium")
      );
      result.center.push(
        TextColumn(
          "training",
          "Kaufmännische / Wertpapierspezifische Ausbildung"
        )
      );

      return result;
    },
    rows() {
      const rows = [];

      const row = {};

      row.schoolType1 = this.education?.schoolType1 || "-";
      row.schoolTime1 = this.education?.schoolTime1 || "./.";
      row.schoolType2 = this.education?.schoolType2 || "-";
      row.schoolTime2 = this.education?.schoolTime2 || "./.";

      row.pastOccupation = this.education?.pastOccupation || "-";
      row.qualification = this.education?.qualification || "-";
      row.training = this.education?.training || "-";

      rows.push(row);
      return rows;
    },
    tableId() {
      const uniqueUUID = "ddb2e216-6845-444d-b57c-5714bba06736";
      return uniqueUUID;
    },
  },
  methods: {
    navigateToCard(row) {
      this.$router.push(
        `/persoenlichedaten/customer-data/steps/schulbildung-und-qualifikationen`
      );
    },
    currency(value) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €";
    },
    updateGroupsIntoData() {
      this.updateGroupIntoData("education");
    },
  },
  mounted() {
    this.updateGroupsIntoData();
  },
  validators: {},
};
</script>

<style scoped>
.align-end {
  flex: 1 1 auto;
  align-self: flex-end;
  padding: 0px 0px 16px 5px;
  color: var(--color-text);
}
.form-control {
  display: flex;
  align-content: stretch;
  align-items: center;
}
</style>