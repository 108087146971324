export const MUTATION_PREFIX = 'BROKER_PERMISSIONS: ';
export const ACTIONS_PREFIX = 'BROKER_PERMISSIONS_';
export const GETTERS_PREFIX = 'BROKER_PERMISSIONS_';

export const __MAKLER__ = '__MAKLER__';

export default {
  MUTATIONS: {
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    SET_COMBO_DATA: MUTATION_PREFIX + 'SET_COMBO_DATA',
    SET_PERMISSIONS: MUTATION_PREFIX + 'SET_PERMISSIONS',
    SET_PERMISSIONS_EDITED: MUTATION_PREFIX + 'SET_PERMISSIONS_EDITED',
    RESET_PERMISSIONS_EDITED: MUTATION_PREFIX + 'RESET_PERMISSIONS_EDITED',
    SET_ADDITIONAL_PERMISSIONS: MUTATION_PREFIX + 'SET_ADDITIONAL_PERMISSIONS',
    ADD_ADDITIONAL_PERMISSIONS_EDITED: MUTATION_PREFIX + 'ADD_ADDITIONAL_PERMISSIONS_EDITED',
    RESET_ADDITIONAL_PERMISSIONS_EDITED: MUTATION_PREFIX + 'RESET_ADDITIONAL_PERMISSIONS_EDITED',
  },
  ACTIONS: {
    FIND_COMBO_DATA: ACTIONS_PREFIX + 'FIND_COMBO_DATA',
    FIND_PERMISSIONS: ACTIONS_PREFIX + 'FIND_PERMISSIONS',
    SAVE_PERMISSIONS: ACTIONS_PREFIX + 'SAVE_PERMISSIONS',
    FIND_ADDITIONAL_PERMISSIONS: ACTIONS_PREFIX + 'FIND_ADDITIONAL_PERMISSIONS',
    SAVE_ADDITIONAL_PERMISSIONS: ACTIONS_PREFIX + 'SAVE_ADDITIONAL_PERMISSIONS',
    RESTORE_DEFAULT_ADDITIONAL_PERMISSIONS: ACTIONS_PREFIX + 'RESTORE_DEFAULT_ADDITIONAL_PERMISSIONS',
    RESTORE_DEFAULT_SUB_BROKERS_ADDITIONAL_PERMISSIONS: ACTIONS_PREFIX + 'RESTORE_DEFAULT_SUB_BROKERS_ADDITIONAL_PERMISSIONS',
  },
  GETTERS: {
    COMBO_DATA: GETTERS_PREFIX + 'COMBO_DATA',
    PERMISSIONS: GETTERS_PREFIX + 'PERMISSIONS',
    PERMISSIONS_EDITED: GETTERS_PREFIX + 'PERMISSIONS_EDITED',
    HAS_PERMISSIONS_EDITED: GETTERS_PREFIX + 'HAS_PERMISSIONS_EDITED',
    ADDITIONAL_PERMISSIONS: GETTERS_PREFIX + 'ADDITIONAL_PERMISSIONS',
    ADDITIONAL_PERMISSIONS_EDITED: GETTERS_PREFIX + 'ADDITIONAL_PERMISSIONS_EDITED',
    HAS_ADDITIONAL_PERMISSIONS_EDITED: GETTERS_PREFIX + 'HAS_ADDITIONAL_PERMISSIONS_EDITED',
  },
}
