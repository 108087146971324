<template>
  <div class="form-container" :class="{'form-container__stepper-collapsed': stepperCollapsed}">
    <div v-if="$slots.stepper" class="form-container__stepper">
      <slot name="stepper"></slot>
    </div>
    <div v-if="$slots.content" class="form-container__content" :class="{'form-container__content--full': !$slots.stepper}"
      @transitionstart.self="handleTransitionStart($event)" @transitionend.self="handleTransitionEnd($event)">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
const TRANSITION_ACTIVE_CLASS = 'form-container--transition-active';

export default {
  props: {
    stepperCollapsed: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleTransitionStart(event) {
      if(event?.propertyName !== 'width') return ;
      this.$el.classList.add(TRANSITION_ACTIVE_CLASS);
    },
    handleTransitionEnd(event) {
      if(event?.propertyName !== 'width') return ;
      this.$el.classList.remove(TRANSITION_ACTIVE_CLASS);
      dispatchEvent(new Event('resize'));
    },
  },
}
</script>

<style lang="scss" scoped>
/* form-container */
.form-container {
  display: flex;

  .form-container__stepper {
    display: none;
    margin: 0 24px 0 0;
    transition: width .5s ease;
    
    > * {
      flex-grow: 1;
      height: 100%;
    }
  }
  .form-container__content {
    width: 100%;
    transition: width .5s ease;
  }
  &.form-container--transition-active {
    .form-container__content {
      overflow: hidden;
    }
  }
}

@media screen and (min-width: 992px) {
  .form-container {
    .form-container__stepper {
      display: flex;
      width: 350px;
      position: sticky;
      top: 0px;
      align-self: flex-start;
      max-height: 1000px;
      overflow-y: auto;
    }
    .form-container__content {
      width: calc(100% - 324px);

      &.form-container__content--full {
        width: 100%;
      }
    }

    &.form-container__stepper-collapsed {
      .form-container__stepper {
        width: 68px;
      }
  
      .form-container__content {
        width: calc(100% - 92px);
      }
    }
  }
}

/**
 * Extra Large Screen (XXL)
 */
@media screen and (min-width: 3840px) {
  .app--is-test-user-context {
    .form-container {
      .form-container__stepper {
        display: flex;
        width: 400px;
      }
      .form-container__content {
        width: calc(100% - 424px);
      }
    }
  }
}
</style>
