<template>
  <div>
    <BarChart format="percent" height="500px" :chartOptions="chartRendite" />
  </div>
</template>
  
  <script>
  import FONDSINFO_TYPES from "@/store/fondsinfo/types";
  import { mapGetters } from "vuex";
  import {utils} from '@/components/charts/chartFondsUtil.js';
  import BarChart from "@/components/charts/echarts/BarChart.vue";
  
  export default {
    components: {
      BarChart,
    },
    computed: {
      ...mapGetters({rendite: FONDSINFO_TYPES.GETTERS.FONDSINFO_RENDITE_CHART}),
      chartRendite() {
        if (this.rendite) {
          return utils.jaehrlicheTendite(this.rendite);
        }
        return {}
      }
    },
    mounted() {
      if (this.$route.params?.isin) {
        this.$store.dispatch(FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_RENDITE_CHART, {isin: this.$route.params.isin, years: 5} );
      }
    },
  }
  </script>
  
  <style>
  </style>