var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("GesellschaftTitleNavigation"),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("div", { staticClass: "box__title mb-3" }, [
            _vm._v("Einstellungen")
          ]),
          _vm.loading
            ? _c("GhostLoading", { attrs: { type: "table" } })
            : _c(
                "div",
                [
                  _c("InputToggleSwitch", {
                    attrs: { label: "Alte Depotdaten beim Einlesen löschen" },
                    model: {
                      value: _vm.settings.resetDepot,
                      callback: function($$v) {
                        _vm.$set(_vm.settings, "resetDepot", $$v)
                      },
                      expression: "settings.resetDepot"
                    }
                  }),
                  _c("InputToggleSwitch", {
                    attrs: { label: "Anzeige im smartMSC" },
                    model: {
                      value: _vm.settings.zeigeInListe,
                      callback: function($$v) {
                        _vm.$set(_vm.settings, "zeigeInListe", $$v)
                      },
                      expression: "settings.zeigeInListe"
                    }
                  }),
                  _c("InputToggleSwitch", {
                    attrs: { label: "In Courtageliste dieser Firma zeigen" },
                    model: {
                      value: _vm.settings.visibleCourtageliste,
                      callback: function($$v) {
                        _vm.$set(_vm.settings, "visibleCourtageliste", $$v)
                      },
                      expression: "settings.visibleCourtageliste"
                    }
                  }),
                  _c("InputToggleSwitch", {
                    attrs: { label: "Fondsplattform" },
                    model: {
                      value: _vm.settings.multiGesellschaft,
                      callback: function($$v) {
                        _vm.$set(_vm.settings, "multiGesellschaft", $$v)
                      },
                      expression: "settings.multiGesellschaft"
                    }
                  }),
                  _c("InputToggleSwitch", {
                    attrs: {
                      label: "Fonds dieser Gesellschaft übertragsfähig zu AAB"
                    },
                    model: {
                      value: _vm.settings.uebertragsfaehigAab,
                      callback: function($$v) {
                        _vm.$set(_vm.settings, "uebertragsfaehigAab", $$v)
                      },
                      expression: "settings.uebertragsfaehigAab"
                    }
                  }),
                  _c("InputToggleSwitch", {
                    attrs: {
                      label:
                        "Fonds dieser Gesellschaft übertragsfähig zu ADIG/ebase"
                    },
                    model: {
                      value: _vm.settings.uebertragsfaehigAdigEbase,
                      callback: function($$v) {
                        _vm.$set(_vm.settings, "uebertragsfaehigAdigEbase", $$v)
                      },
                      expression: "settings.uebertragsfaehigAdigEbase"
                    }
                  }),
                  _c("InputToggleSwitch", {
                    attrs: {
                      label:
                        "Fonds dieser Gesellschaft übertragsfähig zu DWS Fondsplattform"
                    },
                    model: {
                      value: _vm.settings.uebertragsfaehigDws,
                      callback: function($$v) {
                        _vm.$set(_vm.settings, "uebertragsfaehigDws", $$v)
                      },
                      expression: "settings.uebertragsfaehigDws"
                    }
                  }),
                  _c("InputToggleSwitch", {
                    attrs: {
                      label: "Fonds dieser Gesellschaft übertragsfähig zu FFB"
                    },
                    model: {
                      value: _vm.settings.uebertragsfaehigFfb,
                      callback: function($$v) {
                        _vm.$set(_vm.settings, "uebertragsfaehigFfb", $$v)
                      },
                      expression: "settings.uebertragsfaehigFfb"
                    }
                  }),
                  _c("InputToggleSwitch", {
                    attrs: {
                      label:
                        "Fonds dieser Gesellschaft übertragsfähig zu Fondsdepotbank"
                    },
                    model: {
                      value: _vm.settings.uebertragsfaehigFondsdepotbank,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.settings,
                          "uebertragsfaehigFondsdepotbank",
                          $$v
                        )
                      },
                      expression: "settings.uebertragsfaehigFondsdepotbank"
                    }
                  }),
                  _c("InputToggleSwitch", {
                    attrs: {
                      label: "Bereinigung doppelter Transaktionen durchführen"
                    },
                    model: {
                      value: _vm.settings.mergeTransactions,
                      callback: function($$v) {
                        _vm.$set(_vm.settings, "mergeTransactions", $$v)
                      },
                      expression: "settings.mergeTransactions"
                    }
                  }),
                  _c("InputToggleSwitch", {
                    attrs: { label: "Reporting erstellen" },
                    model: {
                      value: _vm.settings.reporting,
                      callback: function($$v) {
                        _vm.$set(_vm.settings, "reporting", $$v)
                      },
                      expression: "settings.reporting"
                    }
                  }),
                  _c("InputToggleSwitch", {
                    attrs: {
                      label: "Reporting per E-Mail an Gesellschaft schicken"
                    },
                    model: {
                      value: _vm.settings.reportingMail,
                      callback: function($$v) {
                        _vm.$set(_vm.settings, "reportingMail", $$v)
                      },
                      expression: "settings.reportingMail"
                    }
                  }),
                  _c("InputToggleSwitch", {
                    attrs: { label: "Gesellschaft bietet Servicegebühr an" },
                    model: {
                      value: _vm.settings.servicegebuehrAvailable,
                      callback: function($$v) {
                        _vm.$set(_vm.settings, "servicegebuehrAvailable", $$v)
                      },
                      expression: "settings.servicegebuehrAvailable"
                    }
                  }),
                  _c("InputToggleSwitch", {
                    attrs: {
                      label: "Gesellschaft nutzt Bannerwerbung auf der Homepage"
                    },
                    model: {
                      value: _vm.settings.nutztBannerwerbung,
                      callback: function($$v) {
                        _vm.$set(_vm.settings, "nutztBannerwerbung", $$v)
                      },
                      expression: "settings.nutztBannerwerbung"
                    }
                  }),
                  _c("InputToggleSwitch", {
                    attrs: { label: "Gesellschaft nutzt Servicepaket" },
                    model: {
                      value: _vm.settings.nutztServicepaket,
                      callback: function($$v) {
                        _vm.$set(_vm.settings, "nutztServicepaket", $$v)
                      },
                      expression: "settings.nutztServicepaket"
                    }
                  }),
                  _c("InputToggleSwitch", {
                    attrs: { label: "Gesellschaft ist Toppartner" },
                    model: {
                      value: _vm.settings.topPartner,
                      callback: function($$v) {
                        _vm.$set(_vm.settings, "topPartner", $$v)
                      },
                      expression: "settings.topPartner"
                    }
                  }),
                  _c("InputToggleSwitch", {
                    attrs: {
                      label:
                        "Sonstige Vermögenswerte (Genossenschaftsanteile, Genussrechte, etc) nach Gewerbeordnung §34 f Abs. 1 Satz 1 Nr. 3"
                    },
                    model: {
                      value: _vm.settings.sonstigeVermoegensanlagen,
                      callback: function($$v) {
                        _vm.$set(_vm.settings, "sonstigeVermoegensanlagen", $$v)
                      },
                      expression: "settings.sonstigeVermoegensanlagen"
                    }
                  }),
                  _c("InputToggleSwitch", {
                    attrs: { label: "Gesellschaft mit Depotstelle" },
                    model: {
                      value: _vm.settings.mitDepotstelle,
                      callback: function($$v) {
                        _vm.$set(_vm.settings, "mitDepotstelle", $$v)
                      },
                      expression: "settings.mitDepotstelle"
                    }
                  })
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }