<template>
  <BaseModal ref="modal"
    labelButtonCancel="Ok"
    :isModalAsFullPage="false"
    :showConfirmButton="false"
    @onCloseButton="close"
    @onCancelButton="close">
    <template v-slot:modalTitle>
      Der Anruf wurde beendet
    </template>
    <span v-if="callInfo && callInfo.established">Vielen Dank, dass Sie die Telefonie-Funktion verwendet haben.</span>
    <div v-if="callInfo && callInfo.alertMsg">
      {{callInfo.alertMsg}}
    </div>
    <div v-if="(isBroker || allowKundenFreigabe) && callInfo && callInfo.recording" class="kundenFreigabe">
      Die Aufzeichnung dieses Telefonats finden Sie innerhalb der nächsten 24 Stunden im Dokumentenarchiv.
    </div>
  </BaseModal>
</template>

<script>
import { mapGetters } from 'vuex'
import WEBRTC_TYPES from '@/store/webrtc/types'
import CORE_TYPES from '@/store/core/types'
import BaseModal from '@/components/core/BaseModal.vue';
import LOG_TYPES from '@/store/log/types'
import { buildMessage } from '@/helpers/log-message-helper';

export default {
  computed: {
    ...mapGetters({
      callInfo: WEBRTC_TYPES.GETTERS.CALL_INFO_AFTER_CLOSING,
      allowKundenFreigabe: CORE_TYPES.GETTERS.ALLOW_WEBRTC_CALL_KUNDEN_FREIGABE,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      beteiligterInfos: WEBRTC_TYPES.GETTERS.BETEILIGTER_INFOS,
    }),
  },
  watch: {
    callInfo(newValue) {
      if (newValue) {
        if(newValue?.isMissedCall) { // when is a missed call show as a notification alert
          const { alertMsg } = this.callInfo;
          this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(alertMsg, 'danger'));
          this.$store.commit(WEBRTC_TYPES.MUTATIONS.CLOSE_ALERT_MESSAGE_AFTER_CLOSING);
        } else if (newValue?.messageStatus === 'ESTABLISHED') {
          this.$store.dispatch(LOG_TYPES.ACTIONS.INFO, "HungUpCallDialog: The call was established in another device");
          this.$store.commit(WEBRTC_TYPES.MUTATIONS.CLOSE_ALERT_MESSAGE_AFTER_CLOSING);
        } else {
          this.$refs.modal.open();
        }
      }
    }
  },
  methods: {
    close() {
      this.$store.commit(WEBRTC_TYPES.MUTATIONS.CLOSE_ALERT_MESSAGE_AFTER_CLOSING)
      this.$refs.modal.close();
    }
  },
  components: {
    BaseModal,
  },
}
</script>

<style scoped>
.kundenFreigabe {
  font-size: small;
}
</style>