import BERICHTE_TYPES from './types';

export default {
  [BERICHTE_TYPES.GETTERS.RISIKOPRUEFUNG_LIST](state) {
    return state.risikopruefungList;
  },
  [BERICHTE_TYPES.GETTERS.STATUS_FFB_DEPOTS](state) {
    return state.statusFFBDepotList;
  },
  [BERICHTE_TYPES.GETTERS.FILTERS_FFB_STATUS_DEPOT](state) {
    return state.filtersStatusFFBDepot;
  },
  [BERICHTE_TYPES.GETTERS.STEUERLICHE_DATEN](state) {
    return state.steuerlicheDaten;
  },
  [BERICHTE_TYPES.GETTERS.FILTERS_STEUERLICHE_DATEN](state) {
    return state.filtersSteuerlicheDaten;
  },
  [BERICHTE_TYPES.GETTERS.STEUER_VERLUST_LIST](state) {
    return state.steuerVerlustList;
  },

  [BERICHTE_TYPES.GETTERS.GESELLSCHAFTEN_AUSWAHL](state) {
    return state.gesellschaftenAuswahl;
  },
  [BERICHTE_TYPES.GETTERS.GET_COURTAGE_INVEST_KUNDE](state) {
    return state.courtageInvest;
  },
  [BERICHTE_TYPES.GETTERS.GET_COURTAGE_VERS_KUNDE](state) {
    return state.courtageVers;
  },

  [BERICHTE_TYPES.GETTERS.ALTERSVORSORGE](state) {
    return state.altersvorsorge;
  },
}