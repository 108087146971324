<template>
  <div>
    <div class="box__container">
      <div class="box__title">Dienstleistungsvertrag</div>

      <InputField label="Einmaliges Entgelt (inkl. MwSt.)" type="currency" 
        v-model="feePropertiesForm.einmaligesEntgelt" isComponentHalfSize 
        :precision="2" 
        @change="dataChanged()" />

      <div>
        <div class="box__title">Jährliches Entgelt  (inkl. MwSt.)</div>

        <InputField label="Fixes Entgelt" type="currency" 
          v-model="feePropertiesForm.fixesEntgelt" isComponentHalfSize 
          :precision="2" 
          @change="dataChanged()" />

        <InputField label="jährliche Erhöhung des fixen Entgelt" type="percent" 
          v-model="feePropertiesForm.fixesEntgeltSteigerung" isComponentHalfSize 
          validateUntouched :precision="2" 
          @change="dataChanged()" />

        <InputField label="jährliches Entgelt (gem. durchschnittl. Depotwert, inkl. MwSt.)" type="percent" 
          v-model="feePropertiesForm.variablesEntgelt" isComponentHalfSize 
          validateUntouched :precision="2" 
          @change="dataChanged()" />
      </div>

      <div>
        <div class="box__title">zu berücksichtigende Depots und Konten</div>

        <InputToggleSwitch label="inkl. Fremdbestand" inLineLabel
          v-model="feePropertiesForm.inklFremdbestand" 
          @input="dataChanged()" />

        <InputToggleSwitch label="inkl. Geldverrechnungskonten" inLineLabel
          v-model="feePropertiesForm.inklGeldverrechnungskonten" 
          @input="dataChanged()" />

        <InputToggleSwitch label="inkl. CapitalbankkontoPlus" inLineLabel
          v-model="feePropertiesForm.inklCapitalbankkontoPlus" 
          @input="dataChanged()" />
      </div>

      <InputField label="exkl. Depotnummer" 
        v-model="feePropertiesForm.depotnummer1" isComponentHalfSize 
        @change="dataChanged()" />

      <InputField label="exkl. Geldverrechnungskonto" 
        v-model="feePropertiesForm.depotnummer2" isComponentHalfSize 
        @change="dataChanged()" />
    </div>
  </div>
</template>

<script>
import { mapGetters, } from 'vuex';
import FEE_TYPES from '@/store/fee/types';

import InputField from '@/components/core/forms/InputField.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';

import validator from '@/mixins/validator';
import { minValue, maxValue, } from '@/mixins/validator/rules';

import feePropertiesStepMixin from '../fee-properties-step-mixin';

export default {
  mixins: [feePropertiesStepMixin, validator],
  data() {
    return {
      feePropertiesForm: {
        einmaligesEntgelt: null,
        fixesEntgelt: null,
        fixesEntgeltSteigerung: null,
        variablesEntgelt: null,
        inklFremdbestand: false,
        inklGeldverrechnungskonten: false,
        inklCapitalbankkontoPlus: false,
        depotnummer1: null,
        depotnummer2: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      feePropertiesCombos: FEE_TYPES.GETTERS.FEE_PROPERTIES_COMBOS,
    }),
  },
  watch: {
    'feePropertiesCombos.variablesEntgeltMinValue': {
      handler(value) {
        if (value) {
          const formattedValue = value.toString()?.replaceAll(',', '')?.replaceAll('.', ',');

          this.$configureValidators({
            'feePropertiesForm.fixesEntgeltSteigerung': [maxValue(10.1, 'Die jährliche Erhöhung des fixen Entgelt darf 10,0 % nicht überschreiten.')],
            'feePropertiesForm.variablesEntgelt': [minValue(value, `Das jährliche Entgelt muss zwischen ${formattedValue} und 2,4% betragen.`), maxValue(2.41, 'Das jährliche Entgelt muss zwischen 0,6 und 2,4% betragen')],
          });
        }
      },
      immediate: true,
    },
  },
  validators: {
  },
  components: {
    InputField,
    InputToggleSwitch,
  },
}
</script>
