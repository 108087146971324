<template>
  <div @click="addExpresion('+')" class="box norm_button plus">+</div>
</template>

<script>
import functionMixins from "../../functionMixins.js";
export default {
  name: "Plus",
  mixins: [functionMixins]
};
</script>

<style scoped>
.plus {
  grid-area: plus;
}
</style>