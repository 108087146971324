<template>
  <BaseModal 
    ref="modal"
    size="sm"
    modalTitle="Einstellung Details"
    :confirmDisabled="isFormInvalid"
    @onConfirmButton="emitChanged()"
    @close="onClose()"
  >
    <template #default>
      <InputField 
        label="Titel"
        v-model="configDefinitionForm.label"
        autofocus
        validateUntouched
      />
    </template>
  </BaseModal>
</template>

<script>
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';

import BaseModal from '@/components/core/BaseModal.vue';
import InputField from '@/components/core/forms/InputField.vue';

import validator from '@/mixins/validator';
import { required } from '@/mixins/validator/rules';

import { VIEW_ROLES, } from '@/router/roles';

import { LABEL_REQUIRED_TEXT } from './configs-utils';

const NEW_ID = 'new';

export default {
  mixins: [validator],
  components: {
    BaseModal,
    InputField,
  },
  data() {
    return {
      changed: false,
      configDefinitionOriginal: null,
      configDefinitionForm: {
        label: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
    configDefinitionId() {
      return this.$route.params.id;
    },
    isNewConfigDefinition() {
      return this.configDefinitionId === NEW_ID;
    },
    configAvailableValues() {
      const values = [];

      values.push({ label: 'not available', value: '', });
      values.push({ label: 'global', value: 'GLOBAL', });

      if(this.hasRoles([VIEW_ROLES.VIEW_BROKER])) {
        values.push({ label: 'dieser Makler', value: 'MAKLER', });
      } else if(this.hasRoles([VIEW_ROLES.VIEW_CUSTOMER])) {
        values.push({ label: 'für alle Kunden', value: 'MAKLER', });
        values.push({ label: 'für diesen Kunden', value: 'KUNDE', });
      }

      return values;
    },
    isFormInvalid() {
      return !this.configDefinitionForm?.label?.trim();
    },
    hasChanged() {
      return Object.keys(this.configDefinitionForm)
        .some(field => this.configDefinitionForm[field] !== this.configDefinitionOriginal[field]);
    },
  },
  methods: {
    init(configDefinition) {
      this.configDefinitionOriginal = { ...configDefinition, };

      this.configDefinitionForm = {
        label: configDefinition.label,
      };
    },
    open(configDefinition) {
      this.$refs.modal.open();

      this.$nextTick(() => this.init(configDefinition));
    },
    close() {
      this.$refs.modal.close();
    },
    emitChanged() {
      if(!this.hasChanged) return;

      this.$emit('changed', {
        ...this.configDefinitionForm,
      });
    },
    onClose() {
      this.reset();
    },
    reset() {
      this.changed = false,
      this.configDefinitionOriginal = null;
      this.configDefinitionForm = {
        label: '',
      };
    },
  },
  validators: {
    configDefinitionForm: {
      label: [required(LABEL_REQUIRED_TEXT)],
    },
  },
}
</script>
