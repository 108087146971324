var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "modal",
    attrs: { modalTitle: "ExPost manuelle Kosten", showDefaultButtons: false },
    on: { close: _vm.onClose },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function() {
          return [
            _vm.editableMessage
              ? _c("div", { staticClass: "fc-alert fc-alert-danger" }, [
                  _vm._v(" " + _vm._s(_vm.editableMessage) + " ")
                ])
              : _vm._e(),
            _vm.form.isEditable
              ? _c("ComboBox", {
                  attrs: { label: "Jahr", values: _vm.combos.jahr },
                  model: {
                    value: _vm.form.jahr,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "jahr", $$v)
                    },
                    expression: "form.jahr"
                  }
                })
              : _vm._e(),
            !_vm.form.isEditable
              ? _c("InputField", {
                  attrs: { label: "Jahr", disabled: true },
                  model: {
                    value: _vm.form.jahr,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "jahr", $$v)
                    },
                    expression: "form.jahr"
                  }
                })
              : _vm._e(),
            _c("ComboBox", {
              attrs: {
                label: "Art",
                values: _vm.combos.art,
                validateUntouched: ""
              },
              model: {
                value: _vm.form.art,
                callback: function($$v) {
                  _vm.$set(_vm.form, "art", $$v)
                },
                expression: "form.art"
              }
            }),
            _c("InputField", {
              attrs: {
                label: "Betrag (Euro)",
                type: "currency",
                validateUntouched: ""
              },
              model: {
                value: _vm.form.betrag,
                callback: function($$v) {
                  _vm.$set(_vm.form, "betrag", $$v)
                },
                expression: "form.betrag"
              }
            }),
            _vm.isAnteilBeraterSichtbar
              ? _c("InputField", {
                  attrs: {
                    label: "Anteil Vermittler (Euro)",
                    type: "currency"
                  },
                  model: {
                    value: _vm.form.betragMakler,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "betragMakler", $$v)
                    },
                    expression: "form.betragMakler"
                  }
                })
              : _vm._e(),
            _c("ComboBox", {
              attrs: { label: "Depotposition", values: _vm.combos.bgsDaten },
              on: {
                change: function($event) {
                  return _vm.updateDataFromDepotposition($event)
                }
              },
              model: {
                value: _vm.form.begleitschein,
                callback: function($$v) {
                  _vm.$set(_vm.form, "begleitschein", $$v)
                },
                expression: "form.begleitschein"
              }
            }),
            _c("ComboBox", {
              attrs: {
                label: "Lagerstelle",
                values: _vm.combos.lagerstellen,
                validateUntouched: ""
              },
              on: {
                change: function($event) {
                  return _vm.resetDepotnummer()
                }
              },
              model: {
                value: _vm.form.lagerstelleId,
                callback: function($$v) {
                  _vm.$set(_vm.form, "lagerstelleId", $$v)
                },
                expression: "form.lagerstelleId"
              }
            }),
            _c("ComboBox", {
              attrs: {
                label: "Depotnummer",
                values: _vm.depotnummerValues,
                validateUntouched: ""
              },
              model: {
                value: _vm.form.depotnummer,
                callback: function($$v) {
                  _vm.$set(_vm.form, "depotnummer", $$v)
                },
                expression: "form.depotnummer"
              }
            }),
            _c("InputField", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isShowDepotnummerText,
                  expression: "isShowDepotnummerText"
                }
              ],
              attrs: { label: "", type: "text", validateUntouched: "" },
              model: {
                value: _vm.form.depotnummerText,
                callback: function($$v) {
                  _vm.$set(_vm.form, "depotnummerText", $$v)
                },
                expression: "form.depotnummerText"
              }
            }),
            _c("InputField", {
              attrs: { label: "ISIN", type: "text" },
              model: {
                value: _vm.form.isin,
                callback: function($$v) {
                  _vm.$set(_vm.form, "isin", $$v)
                },
                expression: "form.isin"
              }
            })
          ]
        },
        proxy: true
      },
      {
        key: "footer",
        fn: function() {
          return [
            _c(
              "BaseButton",
              {
                attrs: { isSecondary: "" },
                on: {
                  click: function($event) {
                    return _vm.close()
                  }
                }
              },
              [_vm._v("Abbrechen")]
            ),
            _c(
              "BaseButton",
              {
                attrs: { isPrimary: "", disabled: _vm.isSaveDisabled },
                on: {
                  click: function($event) {
                    _vm.save().then(function() {
                      return _vm.close()
                    })
                  }
                }
              },
              [_vm._v("Speichern")]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }