var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _c("BaseCollapsable", {
        attrs: { showSection: _vm.showExpanded },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _c("div", { staticClass: "box__title" }, [_vm._v("Hinweise")])
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "antrag-sidetext__container mt-8px" },
                  [
                    _c("div", [
                      _vm._v(
                        "SSO (single-sign-on) ermöglicht die Verwaltung Ihres Zugangs. Sie können weiteren Personen einen Zugang ermöglichen oder weitere Nutzer zu Ihrem Zugang hinzufügen."
                      )
                    ]),
                    _c("div", { staticClass: "mt-2" }, [
                      _vm._v(
                        "Besteht noch kein SSO-Zugang, können Sie eine Einladung an den Nutzer versenden, der hinzugefügt werden soll. "
                      )
                    ]),
                    _vm.isSsoKonto
                      ? _c("div", { staticClass: "mt-2" }, [
                          _vm._v(
                            "Sie haben erfolgreich einen Zugang eingerichtet. Bitte teilen Sie Ihren Login Namen der Person mit, die Ihnen einen Zugang einrichten möchte. Dies ermöglicht diesem, Ihnen eine Einladung für Ihren Zugang zukommen zu lassen können."
                          )
                        ])
                      : _vm._e()
                  ]
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }