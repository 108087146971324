var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _c("BaseCollapsable", {
        attrs: { showSection: _vm.showExpanded },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _c("span", { staticClass: "box__title" }, [
                  _vm._v("Hinweise / Warnungen ")
                ]),
                _vm.hasHinweis
                  ? _c("span", [
                      _vm._v(
                        " |  (" +
                          _vm._s(_vm.hinweise.length) +
                          " Hinweis" +
                          _vm._s(_vm.hinweise.length > 1 ? "e" : "") +
                          ")"
                      )
                    ])
                  : _vm._e(),
                _vm.hasWarnings
                  ? _c("span", { staticClass: "color-danger" }, [
                      _vm._v(" |  Das Formular enthält Fehler")
                    ])
                  : _vm._e()
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                !_vm.hasHinweis && !_vm.hasWarnings
                  ? _c("div", [_vm._v(" Keine Hinweise vorhanden. ")])
                  : _vm.hasWarnings
                  ? _c(
                      "div",
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c("span", { staticClass: "font-bold" }, [
                              _vm._v("Fehler:")
                            ])
                          ])
                        ]),
                        _vm._l(_vm.warnings, function(warning, index) {
                          return _c("div", { key: index, staticClass: "row" }, [
                            _c(
                              "div",
                              {
                                staticClass: "col-12",
                                class: {
                                  clickable: _vm.isWarningNavigable(warning)
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.navigateToStep(warning.stepKey)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.getDescription(warning)) +
                                    " "
                                ),
                                _c("PhLink", { attrs: { size: 20 } })
                              ],
                              1
                            )
                          ])
                        })
                      ],
                      2
                    )
                  : _vm.hasHinweis
                  ? _c(
                      "div",
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c("span", { staticClass: "font-bold" }, [
                              _vm._v("Hinweise:")
                            ])
                          ])
                        ]),
                        _vm._l(_vm.hinweise, function(hinweis, index) {
                          return _c("div", { key: index, staticClass: "row" }, [
                            _c("div", {
                              staticClass: "col-12",
                              domProps: {
                                innerHTML: _vm._s(_vm.hinweisHtml(hinweis))
                              }
                            })
                          ])
                        })
                      ],
                      2
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }