var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: "News", actions: _vm.headerActions },
        on: {
          "action-MARK-AS-READ": function($event) {
            return _vm.markSelectedAsRead()
          },
          "action-MARK-AS-UNREAD": function($event) {
            return _vm.markSelectedAsUnread()
          },
          "action-MARK-AS-MAJOR": function($event) {
            return _vm.changeMajorForSelected(true)
          },
          "action-MARK-AS-NOT-MAJOR": function($event) {
            return _vm.changeMajorForSelected(false)
          },
          "action-ADD": _vm.addNews
        }
      }),
      _c("MscNewsList", {
        attrs: { enableFilter: "" },
        model: {
          value: _vm.markedNews,
          callback: function($$v) {
            _vm.markedNews = $$v
          },
          expression: "markedNews"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }