<template>
    <div>
        <div class="mb-5">
            <InputToggleSwitch 
                v-model="djeAktiv" 
                inLineLabel
                label="Anlage in Managed Depot erfassen" 
                :disabled="true"
            />
        </div>
        <div class="box__title">Angaben über die Nachhaltigkeitspräferenzen</div>
        <InputToggleSwitch v-model="djeMifid2Kenntnis" inLineLabel
            label="Weitere Informationen habe ich/wir im Dokument Nachhaltigkeit im Investmentprozess zur Kenntnis genommen." 
            :disabled="isDisabled || !isZusatzDJEAktiv"
          @input="updateStore('zusatzDJE', 'djeMifid2Kenntnis', $event)"/>
        <InputRadioBoxGroup 
            v-model="djeMifid2ZusaetzlichePref" 
            title="Möchten Sie bei der Vermögensanlage zusätzliche Nachhaltigkeitspräferenzen berücksichtigen?"
            validateUntouched
            :disabled="isDisabled || !isZusatzDJEAktiv"
            :values="[{label: 'Nein', value: '0'}, {label: 'Ja', value: '1'}]"
            @input="updateStore('zusatzDJE', 'djeMifid2ZusaetzlichePref', $event)"/>
        <InputRadioBoxGroup 
            v-model="djeMifid2TrotzdemOk" 
            title="Falls ja, DJE Managed Depots ist eine standardisierte Vermögensverwaltung mit einer hohen
					Diversifikation unter Einhaltung der grundsätzlichen Berücksichtigung von Nachhaltigkeitskriterien der DJE Kapital AG. 
					Eine individualisierte Berücksichtigung von Nachhaltigkeitspräferenzen ist im Rahmen des DJE Managed Depots nicht möglich. 
					Sind Sie dennoch mit den im Rahmen des DJE Investmentprozesses berücksichtigten Nachhaltigkeitsaspekten einverstanden?"
            validateUntouched
            :disabled="isDisabled || !isZusatzDJEAktiv || djeMifid2ZusaetzlichePref !== '1'"
            :values="[{label: 'Ja', value: '1'}, {label: 'Nein', value: '0'}]"
            @input="updateStore('zusatzDJE', 'djeMifid2TrotzdemOk', $event)"/>
          <div>Falls nein, eine individualisierte Berücksichtigung von Nachhaltigkeitspräferenzen bieten wir leider nicht an. Das DJE Managed Depot ist demnach nicht für Sie geeignet.</div>
    </div>
</template>

<script>
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch';
import anlegerprofilMixin from '@/mixins/anlegerprofil/anlegerprofil-mixin.js';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';

export default {
    mixins: [anlegerprofilMixin],
    components: {
        InputToggleSwitch,
        InputRadioBoxGroup,
    },
    data() {
        return {
            djeAktiv: false,
            djeMifid2Kenntnis: false,
            djeMifid2ZusaetzlichePref: '',
            djeMifid2TrotzdemOk: '',
        }
    },
    mounted() {
        this.createForm();
    },
    methods: {
        createForm() {
            if (this.data?.zusatzDJE) {
                this.djeAktiv = this.data.zusatzDJE.djeAktiv;
                this.djeMifid2ZusaetzlichePref = this.data.zusatzDJE.djeMifid2ZusaetzlichePref
                this.djeMifid2Kenntnis = this.data.zusatzDJE.djeMifid2Kenntnis
                this.djeMifid2TrotzdemOk = this.data.zusatzDJE.djeMifid2TrotzdemOk
            }
        },
    },
}
</script>

<style>

</style>