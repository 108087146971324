import { VIEW_ROLES, ROLES } from '../roles';
import { redirectToFirstAllowedChild, } from '@/router';

export default [
  {
    path: '',
    redirect: (route) => redirectToFirstAllowedChild(route, ['strategien', 'produkte', 'beschwerde']),
  },
  {
    path: 'strategien',
    component: () => import(/* webpackChunkName: "vermoegensverwaltung" */ '@/views/vermoegensverwaltung/VVSchemaListe.vue'),
    meta: {
      breadcrumb: {
        label: "Strategien",
      },
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_INTERN],
          [VIEW_ROLES.VIEW_BROKER, ROLES.VIEW_VV_STRATEGIEN]
        ]
      },
    },
  },
  {
    path: 'produkte',
    component: () => import(/* webpackChunkName: "vermoegensverwaltung" */ '@/views/vermoegensverwaltung/VVSchemaEditorFondsFinder.vue'),
    meta: {
      breadcrumb: {
        label: "Produktliste",
      },
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_INTERN],
          [VIEW_ROLES.VIEW_BROKER, ROLES.VIEW_VV_PRODUKTE]
        ]
      },
    },
  },
  {
    path: 'wertpapier-beantragen/:isin',
    component: () => import(/* webpackChunkName: "vermoegensverwaltung" */ '@/views/vermoegensverwaltung/VVWertpapierBeantragen.vue'),
    meta: {
      breadcrumb: {
        label: "Produktliste",
      },
      roles: {
        allowed:
          [[VIEW_ROLES.VIEW_BROKER]]
      },
    },
  },
  {
    path: 'vv-schema-viewer/:schemaId',
    component: () => import(/* webpackChunkName: "vermoegensverwaltung" */ '@/views/vermoegensverwaltung/VVSchemaViewer.vue'),
    meta: {
      breadcrumb: {
        label: "Vermögensverwaltung",
      },
      roles: {
        allowed:
          [[VIEW_ROLES.VIEW_BROKER]]
      },
    },
  },
  {
    path: 'vv-schema-editor/:schemaId/:varianteId?',
    component: () => import(/* webpackChunkName: "vermoegensverwaltung" */ '@/views/vermoegensverwaltung/VVSchemaEditor.vue'),
    meta: {
      breadcrumb: {
        label: "Vermögensverwaltung",
      },
      roles: {
        allowed:
          [[VIEW_ROLES.VIEW_BROKER]]
      },
    },
  },
  {
    path: 'vv-schema-einstellungen/:schemaId',
    component: () => import(/* webpackChunkName: "vermoegensverwaltung" */ '@/views/vermoegensverwaltung/VVSchemaEinstellungen.vue'),
    meta: {
      breadcrumb: {
        label: "Vermögensverwaltung",
      },
      roles: {
        allowed:
          [[VIEW_ROLES.VIEW_BROKER]]
      },
    },
    children: [
      {
        path: '',
        redirect: 'documents',
      },
      {
        path: 'documents',
        component: () => import(/* webpackChunkName: "vermoegensverwaltung" */ '@/views/vermoegensverwaltung/VVDocumentsStep.vue')
      },
      {
        path: 'settings',
        component: () => import(/* webpackChunkName: "vermoegensverwaltung" */ '@/views/vermoegensverwaltung/VVSettingsStep.vue')
      },
    ]
  },
  {
    path: 'strategien-abonnieren',
    component: () => import(/* webpackChunkName: "vermoegensverwaltung" */ '@/views/vermoegensverwaltung/VVSubscription.vue'),
    meta: {
      breadcrumb: {
        label: "Strategien abonieren",
      },
      roles: {
        allowed:
          [[VIEW_ROLES.VIEW_BROKER, ROLES.VIEW_VV_STRATEGIEN]]
      },
    },
  },
  {
    path: 'vv-factsheet-history/:schemaId/:title',
    component: () => import(/* webpackChunkName: "vermoegensverwaltung" */ '@/views/vermoegensverwaltung/VVFactsheetHistory.vue'),
    meta: {
      breadcrumb: {
        label: "Factsheet History",
      },
      roles: {
        allowed:
          [[VIEW_ROLES.VIEW_BROKER]]
      },
    },
  },
  {
    path: 'archiv-list/:schemaId',
    component: () => import(/* webpackChunkName: "vermoegensverwaltung" */ '@/views/vermoegensverwaltung/VVSchemaArchivList.vue'),
    meta: {
      breadcrumb: {
        label: "Archiv für Schema",
      },
      roles: {
        allowed:
          [[VIEW_ROLES.VIEW_BROKER]]
      },
    },
  },
  {
    path: 'archiv-variante/:varianteId',
    component: () => import(/* webpackChunkName: "vermoegensverwaltung" */ '@/views/vermoegensverwaltung/VVArchivVariante.vue'),
  },
  {
    path: 'fondsfinder/:schemaId/:versionId',
    component: () => import(/* webpackChunkName: "vermoegensverwaltung" */ '@/views/vermoegensverwaltung/VVSchemaEditorFondsFinder.vue'),
    props: {
      noHeaderMenu: true,
    },
    roles: {
      allowed:
        [[VIEW_ROLES.VIEW_BROKER]]
    },
  },
  {
    path: 'fondsfinder-neu/:schemaId/:versionId',
    component: () => import(/* webpackChunkName: "vermoegensverwaltung" */ '@/views/vermoegensverwaltung/VVSchemaEditorFondsFinderNeu.vue'),
    meta: {
      roles: {
        allowed:
          [[VIEW_ROLES.VIEW_BROKER]]
      },
    },
  },
  {
    path: 'beschwerde',
    component: () => import(/* webpackChunkName: "vermoegensverwaltung" */ '@/views/vermoegensverwaltung/VVKundenbeschwerde.vue'),
    meta: {
      roles: {
        allowed:
          [[VIEW_ROLES.VIEW_BROKER, ROLES.FK]]
      },
    },
  },
  {
    path: 'advisor-fragebogen/:lagerstelle/:antragsName',
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_BROKER, ROLES.VIEW_VV_ADVISOR_FRAGEBOGEN]]
      },
    },
    component: () => import(/* webpackChunkName: "vermoegensverwaltung" */ '@/views/BaseRouterView.vue'),
    children: [
      {
        path: '',
        redirect: {
          path: 'fragebogen',
          query: { initData: true, bmOptChosen: true, },
        },
      },
      {
        path: ':step',
        component: () => import(/* webpackChunkName: "vermoegensverwaltung" */ '@/views/vermoegensverwaltung/VVAdvisorFragebogen.vue'),
      }
    ],
  },

]