var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Table", {
    attrs: {
      tableId: "579b1932-470b-4522-8482-1266152082a3",
      tableData: _vm.tableData,
      cardViewEnabled: false,
      filterEnabled: false,
      exportEnabled: false,
      paginationEnabled: false,
      rowsPerPage: _vm.tableData.records.length
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }