var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "E-Mail Postfach",
          actions: _vm.headerActions,
          defaultMenu: _vm.customOptionMenu
        },
        on: {
          "action-NEW_MAIL": function($event) {
            return _vm.handleNewEmail()
          }
        }
      }),
      _c("BaseFilter", {
        attrs: {
          title: "E-Mail Filter",
          filterId: "PostfachMailFilter",
          configFilter: _vm.filterConfig,
          defaultOptions: _vm.defaultOptions,
          metadata: _vm.metadata,
          showSaveButton: "",
          hasSmartSearch: "",
          immidiateSearch: ""
        },
        on: { onFilter: _vm.onFilter }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.rows.length
            ? _c(
                "ScrollLoading",
                {
                  attrs: {
                    hasScroll:
                      _vm.rows.length !== _vm.maxRowCount && !_vm.reachedEnd
                  },
                  on: { scroll: _vm.onScroll }
                },
                [
                  _vm.$isSmallScreen
                    ? _c("Table", {
                        attrs: {
                          title: _vm.isInbox ? "Posteingang" : "Gesendet",
                          rowId: "commId",
                          headers: _vm.headers,
                          rows: _vm.rows,
                          headerActions: _vm.titleActions,
                          exportConfig: { roottext: "Postfach" },
                          mobileConfig: _vm.mobileConfig,
                          selectionAsBlacklist: _vm.invertSelection
                        },
                        on: {
                          clickRow: _vm.clickBetreff,
                          selectionAsBlacklist: function($event) {
                            _vm.invertSelection = $event
                          },
                          "headerAction-BULK_DELETE": _vm.deleteSelected
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function(row) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "ellipsis d-flex",
                                      staticStyle: { "align-items": "center" }
                                    },
                                    [
                                      row.hasAttachment
                                        ? [
                                            _c("ph-paperclip", {
                                              staticStyle: {
                                                "flex-shrink": "0"
                                              },
                                              attrs: { size: 20 }
                                            })
                                          ]
                                        : _vm._e(),
                                      _c("div", { staticClass: "ellipsis" }, [
                                        _vm._v(_vm._s(row.title))
                                      ])
                                    ],
                                    2
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          377402017
                        ),
                        model: {
                          value: _vm.selectedRows,
                          callback: function($$v) {
                            _vm.selectedRows = $$v
                          },
                          expression: "selectedRows"
                        }
                      })
                    : _c("OverviewTable", {
                        attrs: {
                          records: _vm.rows,
                          title: _vm.isInbox ? "Posteingang" : "Gesendet",
                          titleActions: _vm.titleActions,
                          selectionAsBlacklist: _vm.invertSelection
                        },
                        on: {
                          selectionAsBlacklist: function($event) {
                            _vm.invertSelection = $event
                          },
                          subject: _vm.clickBetreff,
                          "action-DELETE": _vm.deleteRow,
                          "action-BULK_DELETE": _vm.deleteSelected,
                          "action-WEITERLEITEN": _vm.handleActionWeiterleiten,
                          "action-WEITERLEITEN_USER":
                            _vm.handleActionWeiterleitenUser,
                          "action-WEITERLEITEN_COMPANY":
                            _vm.handleActionWeiterleitenCompany
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "title",
                              fn: function(row) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "ellipsis bold" },
                                    [
                                      row.isTitleLink
                                        ? _c(
                                            "a",
                                            {
                                              staticStyle: { color: "inherit" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.openCustomerNewTab(
                                                    row.nummer
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v(_vm._s(row.title))]
                                          )
                                        : _c("span", [
                                            _vm._v(_vm._s(row.title))
                                          ]),
                                      row.status !== "success"
                                        ? [
                                            _c(
                                              "span",
                                              { staticClass: "mx-2" },
                                              [_vm._v(" | ")]
                                            ),
                                            _c("ph-warning", {
                                              class: "color-" + row.status,
                                              attrs: { size: 16 }
                                            })
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3375552725
                        ),
                        model: {
                          value: _vm.selectedRows,
                          callback: function($$v) {
                            _vm.selectedRows = $$v
                          },
                          expression: "selectedRows"
                        }
                      })
                ],
                1
              )
            : _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", config: { table: { rows: 10 } } }
              })
            : _c("NoData")
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "mailContacts",
          attrs: {
            modalTitle: "Kontakte",
            size: "lg",
            showCancelButton: false,
            labelButtonConfirm: "Auswahl übernehmen"
          },
          on: { onConfirmButton: _vm.confirmContactsToSend }
        },
        [
          _c("InsuranceCompanyMailContacts", {
            attrs: { records: _vm.mailUsers },
            on: { selectedRows: _vm.selectRowsContacts }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }