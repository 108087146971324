<template>
  <div class="entgelt-component">

    <div class="row">
      <div class="col-12 col-md-4">
        <InputField label="Ausgabeaufschlag" v-model="inputAA" type="percent" :disabled="config.isAAReadOnly"
          @change="emitChangeEvent()"/>
      </div>
      <div class="col-12 col-md-4" v-if="!config.isAAReadOnly">
        <InputField label="normal" v-model="displayAANormal" type="percent" :disabled="true" />
      </div>
      <div class="col-12 col-md-4">
        <InputField label="entspricht Rabatt" v-model="calculateRabatt" type="percent" :disabled="true" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import CORE_TYPES from '@/store/core/types.js';
import InputField from '@/components/core/forms/InputField.vue';
import FinancialCalculator from '@/components/retirementScenario/financialCalculator';
import { parse } from '@/helpers/number-formatter.js';

const PRECISION = 3;

export default {
  props: {
    config: {
      type: Object,
      required: true,
    },
    antragData: {
      type: Object
    },
  },
  emits: ['change'],
  data() {
    return {
      inputAA: '',
      displayAANormal: '',
    }
  },
  computed: {
    ...mapGetters({
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
    }),
    calculateRabatt() {
      return !this.displayAANormal ? '' : Math.round((100 - (this.inputAA / this.displayAANormal) * 100) || 0);
    },
    initValues() {
      this.inputAA = this.antragData && this.antragData['inputAA'] && FinancialCalculator.convertStringToNumber(this.antragData['inputAA']);
      this.displayAANormal = parse(this.antragData && this.antragData['displayAANormal'] || '', 5);
    }
  },
  mounted() {
    this.initValues;
  },
   watch: {
    inputAA: function (val) {
      this.calculateRabatt;
    },
   },
  methods: {
    emitChangeEvent() {
      this.$emit('change', this.inputAA);
    }
  },
  components: {
    InputField,
  },
}
</script>
