<template>
    <div>
        <BaseFilter  ref="filter"
            v-if="stornoArts"
            filterId="stornoFilter"
            :immidiateSearch="!$route.query.backAction"
            hasSmartSearch
            showSaveButton
            title="Filter"
            :metadata="searchMenu" 
            :configFilter="configFilter"
            :defaultOptions="defaultOptions"
            :extraButton="isButtonVisible ? {buttonText: 'Markierte Positionen bestätigen', isExtraButtonPrimary: true} : null"
            @onFilter="handleSearch"
            @extraButtonClick="confirmSelection"
        >
        </BaseFilter>
    </div>
</template>

<script>
import CORE_TYPES from "@/store/core/types";
import STORNO_WARNUNG_TYPES from '@/store/stornoWarnung/types';
import { mapGetters } from 'vuex';
import BaseFilter from '@/components/core/BaseFilter.vue';
import mixin from '@/mixins/stornos/stornos-mixin.js';

export default {
    mixins: [mixin],
    components: {
        BaseFilter,
    },
    props: {
        isButtonVisible: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        if (!this.$route.query.backAction) {
            this.$store.dispatch(STORNO_WARNUNG_TYPES.ACTIONS.GET_STORNO_ARTS);
        }
    },
    computed: {
        ...mapGetters({
            stornoArts: STORNO_WARNUNG_TYPES.GETTERS.GET_STORNO_ARTS,
            isFilterSaved: STORNO_WARNUNG_TYPES.GETTERS.IS_FILTER_SAVED,
            savedFilters: STORNO_WARNUNG_TYPES.GETTERS.DEFAULT_FILTERS,
            isFA: CORE_TYPES.GETTERS.IS_FA,
        }),
        stornoArtsValues() {
            return this.stornoArts;
        },
        searchMenu() {
            return [
                {
                    type: 'group',
                    key: 'allgemein',
                    label: 'Allgemein',
                    menuItems: [
                        {
                            type: 'default',
                            label: 'Alle hochgeladenen Dokumente anzeigen',
                            key: 'CHECK_MIT_ANTRAEGEN',
                        },
                        {
                            type: 'default',
                            label: 'Ausweise anzeigen',
                            key: 'CHECK_MIT_AUSWEISEN',
                        },
                        {
                            type: 'default',
                            label: 'nur die unbestätigten Einträge anzeigen',
                            key: 'CHECK_ALLES_ANZEIGEN',
                        },
                        {
                            type: 'default',
                            label: 'nur Versicherungen anzeigen',
                            key: 'CHECK_NUR_VERSICHERUNG',
                        },
                        {
                            type: 'default',
                            label: 'nur Investment anzeigen',
                            key: 'CHECK_NUR_INVESTMENT',
                        },
                        {
                            type: 'combobox',
                            label: 'Art/Versicherungsstatus',
                            key: 'INPUT_ART',
                            comboOptions: this.stornoArts,
                        },
                        {
                            type: 'combobox',
                            label: 'Status',
                            key: 'status',
                            comboOptions: [
                                {
                                    label: 'Warnung',
                                    value: 'WARNUNG',
                                },
                                {
                                    label: 'Info',
                                    value: 'HINWEIS',
                                }
                            ],
                        },
                        {
                            type: 'text',
                            label: 'Kundennummer',
                            key: 'INPUT_KUNDENNR',
                            dataType: 'number',
                            inputmode: 'numeric',
                        },
                        {
                            type: 'text',
                            label: 'Kundenname',
                            key: 'kundenname',
                        },
                        {
                            type: 'text',
                            label: 'Vermittlernummer',
                            key: 'INPUT_MAKLERNR',
                            dataType: 'number',
                            inputmode: 'numeric',
                        },
                        {
                            type: 'text',
                            label: 'Vermittlername',
                            key: 'INPUT_MAKLER_NAME',
                        },
                        {
                            type: 'switcher',
                            label: 'Inkl. der Struktur',
                            key: 'CHECK_INCLUDE_STRUCTURE',
                        },
                        ...(this.isFA ? [
                            {
                                type: "default",
                                label: "inkl. Struktur",
                                key: "struktur",
                            }
                        ] : []),
                    ]
                },
                {
                    type: 'group',
                    key: 'unbestaetigt',
                    label: 'Unbestätigte Warnungen/Stornos',
                    menuItems: [
                        {
                            type: 'default',
                            label: 'von diesem Jahr',
                            key: 'RADIOVALUE_THISYEAR_UNBESTAETIGT',
                        },
                        {
                            type: 'integer',
                            label: 'der letzten Monate',
                            key: 'INPUT_MONTH_UNBESTAETIGT',
                            emptyDenied: false,
                        },
                        {
                            type: 'dateRange',
                            label: 'zwischen',
                            key: 'RADIOVALUE_FIXEDDATE_UNBESTAETIGT',
                            emptyDenied: false,
                        },
                    ]
                },
                {
                    type: 'group',
                    key: 'bestaetigt',
                    label: 'Bestätigte Warnungen/Stornos',
                    menuItems: [
                        {
                            type: 'default',
                            label: 'von diesem Jahr',
                            key: 'RADIOVALUE_THISYEAR',
                        },
                         {
                            type: 'integer',
                            label: 'der letzten Monate',
                            key: 'INPUT_MONTH',
                        },
                         {
                            type: 'dateRange',
                            label: 'zwischen',
                            key: 'RADIOVALUE_FIXEDDATE',
                        },
                    ]
                 }
            ]
        },
        defaultOptions() {
            const defaultFilters = {};
            Object.keys(this.filters).forEach(key => defaultFilters[key] = { value: this.filters[key]});
            return defaultFilters;
        },
        filters() {
            return this.savedFilters || {};
        },
        configFilter() {
            return {
                placeholderForDefSearchInput: 'Kundennummer, Kundenname',
                defaultSearchInputKeys: ['INPUT_KUNDENNR', 'kundenname'],
                filterType: 'stornoWarnungen',
                noResetOnDefaultSearchInputExit: true,
                ignoreStoredFilter: this.isFilterSaved,
                filterZurucksetzen: () => this.resetFilters()
            }
        }
    },
    methods: {
        openModalConfig() {
            this.$refs.tableResult.openColumnsConfig()
        },
        onSelectedRows(values) {
          this.selectedRows = values;
        },
        handleSearch(filterArr = []) {
            this.saveFilters(filterArr)
            this.$emit('getStornos');
        },
        confirmSelection(filterArr = []) {
            this.saveFilters(filterArr)
            this.$emit('confirm');
        },
        saveFilters(filterArr = []) {
            const filters = {};
            filterArr.forEach(filter => filters[filter.key] = filter.value);
            this.$store.commit(STORNO_WARNUNG_TYPES.MUTATIONS.SET_FILTERS, { filters: filters });
        },
        resetFilters() {
            this.$store.commit(STORNO_WARNUNG_TYPES.MUTATIONS.RESET_FILTERS);
        },
    },
}
</script>

<style>

</style>