<template>
  <BaseFilter
    filterId="cae8986d-61ae-49d1-9eac-62082d86ba0a"
    :metadata="searchMetadata"
    :defaultOptions="defaultOptions"
    :configFilter="configFilter"
    :openFilterOnMount="false"
    showSaveButton
    hasSmartSearch
    @onFilter="search"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';
import DOKUMENTENARCHIV_TYPES from '@/store/dokumentenarchiv/types';
import { DatePickerUtils } from '@/components/core/forms/DatePicker/date-picker-utils';

import BaseFilter from '@/components/core/BaseFilter.vue';

export default {
  components: {
    BaseFilter,
  },
  data() {
    return {
      configFilter: {
        placeholderForDefSearchInput: "Bezeichnung",
        hideFirstColumn: true,
        checkDefaultSearchInput: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      isIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
      showSchaden: DOKUMENTENARCHIV_TYPES.GETTERS.SHOW_SCHADEN_DOCUMENTS,
      showDeleted: DOKUMENTENARCHIV_TYPES.GETTERS.SHOW_DELETED_DOCUMENTS,
      docFilter: DOKUMENTENARCHIV_TYPES.GETTERS.DOCUMENTS_FILTER,
    }),
    searchMetadata() {
      const allgemeinMenuItems = [];

      allgemeinMenuItems.push({
        type: 'default',
        label: 'Schadensdokumente anzeigen',
        key: 'showSchaden',
      });

      if(this.isIntern) {
        allgemeinMenuItems.push({
          type: 'default',
          label: 'Gelöschte Dokumente anzeigen',
          key: 'showDeleted',
        });
      }

      allgemeinMenuItems.push({
        type: 'dateRange',
        label: 'Zeitraum',
        key: 'zeitraum',
      });

      return [
        {
          type: 'group',
          key: 'allgemein',
          label: 'Allgemein',
          menuItems: [ ...allgemeinMenuItems, ],
        },
      ];
    },
    defaultOptions() {
      return {
        ...(this.showSchaden ? { showSchaden: { value: true, }, } : {}),
        ...(this.showDeleted && this.isIntern ? { showDeleted: { value: true, }, } : {}),
        ...(this.docFilter?.fromDate && this.docFilter.toDate ? { zeitraum: {
            value: [{
              key: "min",
              value: this.docFilter.fromDate,
            },
            {
              key: "max",
              value: this.docFilter.toDate,
            }],
          }, } : {})
      };
    },
  },
  methods: {
    search(filters) {
      const documentsFilter = filters.reduce((acc, filter) => {
        if (filter.key === 'zeitraum') {
          return {
            ...acc,
            fromDate: DatePickerUtils.toDateStr(filter.value.find(v => v.key == 'min').value),
            toDate: DatePickerUtils.toDateStr(filter.value.find(v => v.key == 'max').value),
          }
        } else if (filter.key === 'defaultSearchInput') {
          return {
            ...acc,
            docLabel: filter.value
          }
        } else {
          return {
            ...acc,
            [filter.key]: filter.value,
          }
        }
      }, {});
      
      this.$store.commit(DOKUMENTENARCHIV_TYPES.MUTATIONS.SET_DOCUMENTS_FILTER, documentsFilter);
      this.$emit('onFilter');
    },
  },
}
</script>
