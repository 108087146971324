import WERTPAPIERINFO_TYPES from './types';
import axios from 'axios';
import LOG_TYPES from '@/store/log/types';
import { buildMessage } from "@/helpers/log-message-helper";

const wpCancelableRequestConfig = {
  defaultSpinner: true,
  cancelableRequest: {
    title: 'Wertpapierfilter'
  }
};

export default {
  async [WERTPAPIERINFO_TYPES.ACTIONS.CONFIG]({ commit, state, rootState }, payload) {
    if (!state.config.request) {
      state.config.request = 1;
      
      try {
        let response = await axios.post(rootState.core.apiAddress + '/consultation/wertpapierinfo?ask=init_filter', 
        { ask: 'init_filter', 
          params: {
            isCourtage: ('' + !!payload.isCourtage),
          }
        });
        commit(WERTPAPIERINFO_TYPES.MUTATIONS.CONFIG, response.data);
        state.config.request = 0;
      } catch (er) {
        console.log('WERTPAPIERINFO_TYPES.ACTIONS.CONFIG', er);
        state.config.request = 0;
      }
    }
    return state.config;
  },
  async [WERTPAPIERINFO_TYPES.ACTIONS.OPTIONS]({ state, commit, rootState }, payload) {
    const act = payload.act;
    const strPayload = JSON.stringify(payload);
    const mustRead = !state.sessions[act].options && (state.sessions[act].optionsParam || '') != strPayload;
    if ( mustRead ){
      try {
        commit(WERTPAPIERINFO_TYPES.MUTATIONS.SET_ACT, {
          act: act
        });
        let response = await axios.post(rootState.core.apiAddress + '/consultation/wertpapierinfo?ask=get_opt', {
          ask: 'get_opt',
          params: {
            gesellschaft: payload.gesellschaft,
            lagerstelle: payload.lagerstelle,
            isCourtage: ('' + !!payload.isCourtage),
          }
        });
        commit(WERTPAPIERINFO_TYPES.MUTATIONS.SET_ACT, {
          act: act, options: response.data.combos, optionsParam: strPayload, filters: response.data.filters
        });
        return response.data.combos;
      } catch (er) {
        console.log('WERTPAPIERINFO_TYPES.ACTIONS.OPTIONS', er);
      }
    } else {
      return Promise.resolve(state.sessions[act].options);
    }
  },
  async [WERTPAPIERINFO_TYPES.ACTIONS.OUTCOME]({ commit, dispatch, state, rootState }, payload) {
    const act = payload.act;
    const startDatum = act.indexOf("CompareFunds") == 0 ? 'true' : 'false' ;
    try {
      let response = await axios.post(rootState.core.apiAddress + '/consultation/wertpapierinfo?ask=get_wp', {
        ask: 'get_wp',
        params: {
          type: state.sessions[act].phase.wp_type,
          filter: state.sessions[act].filters.map( f => [f[0], f[2]]),
          gesellschaft: payload.gesellschaft,
          lagerstelle: payload.lagerstelle,
          isCourtage: ('' + !!payload.isCourtage),
          categoryId: (payload.categoryId || ''),
          zusatz: payload.zusatz,
          startDatum,
          maxCountRows: state.sessions[act].maxCountRows,
        }
      }, wpCancelableRequestConfig);
      if (response.data?.tabledata?.errorStr) {
        commit(WERTPAPIERINFO_TYPES.MUTATIONS.SET_ACT, {
          act: act, 
          outcome: {}
        });
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.tabledata.errorStr, 'danger'));
        return state.sessions[act].outcome;
      }
      commit(WERTPAPIERINFO_TYPES.MUTATIONS.SET_ACT, {
        act: act, keys: response.data?.tabledata?.keys,
        outcome: response.data?.tabledata || {}
      });
      return state.sessions[act].outcome;
    } catch (er) {
      console.log('WERTPAPIERINFO_TYPES.ACTIONS.OUTCOME', er);
    }
  },
  [WERTPAPIERINFO_TYPES.ACTIONS.LOAD_WATCHLIST]({ commit, rootState }) {

    return axios.get(`${rootState.core.apiAddress}/favoritenlisteKunde/getWatchlist`, {defaultSpinner: true}).then(response => {
      if (response.status === 200 && response.data) {
        commit(WERTPAPIERINFO_TYPES.MUTATIONS.SET_WATCHLIST, response.data)
      }
      return response;
    });
  },
  [WERTPAPIERINFO_TYPES.ACTIONS.ADD_WATCHLIST]({commit, rootState}, payload) {    
    return axios.post(rootState.core.apiAddress + '/favoritenlisteKunde/addWatchlist', {
      isinList: payload.map(item => item.isin),
    }, {defaultSpinner: true}).then(response => {
      if (response.status === 200 && response.data) {
        commit(WERTPAPIERINFO_TYPES.MUTATIONS.SET_WATCHLIST, response.data)
      }
      return response;
    });
  },
  [WERTPAPIERINFO_TYPES.ACTIONS.UPD_WATCHLIST]({commit, rootState}, payload) {    
    return axios.post(rootState.core.apiAddress + '/favoritenlisteKunde/updateWatchlist', payload, {defaultSpinner: true}).then(response => {
      if (response.status === 200 && response.data) {
        commit(WERTPAPIERINFO_TYPES.MUTATIONS.SET_WATCHLIST, response.data)
      }
      return response;
    });
  },
}