<template>
    <div class="dashboard-panel__widgets">
        <div class="box__container dashboard-panel-widget">
            <InputField
                label="Name"
                v-model="form.title"
                validateUntouched
                @change="doChanges('title', $event)"
            />
            <DatePickerField
                label="Geburtstag"
                placeholder="DD.MM.JJJJ"
                isValueAsString
                v-model="form.geburtsdatum"
                @change="doChanges('geburtsdatum', $event)"
            />
            <InputField
                label="Beginn Ruhestand (Alter)"
                v-model="form.alterRente"
                type="number"
                :precision="0"
                @change="doChanges('alterRente', $event)"
            />
            <InputField
                label="Lebenserwartung in Jahren"
                v-model="form.lebenserwartung"
                type="number"
                :precision="0"
                @change="doChanges('lebenserwartung', $event)"
            />
            <InputField
                label="Inflation"
                v-model="form.inflation"
                type="percent"
                @change="doChanges('inflation', $event)"
            />
        </div>
        <div class="box__container dashboard-panel-widget">
            <InputField
                label="monatlicher Bedarf in Euro in Rente (nach heutigem Geldwert)"
                v-model="form.bedarfMonatlich"
                type="currency"
                @change="doChanges('bedarfMonatlich', $event)"
            />
            <InputField
                label="geschätzte Renteneinnahmen pro Monat"
                v-model="form.renteMonatlichErwartet"
                type="currency"
                @change="doChanges('renteMonatlichErwartet', $event)"
            />
            <InputField
                label="Restkapital"
                v-model="form.restKapital"
                type="currency"
                :precision="0"
                @change="doChanges('restKapital', $event)"
            />
            <InputField
                label="Rendite im Ruhestand"
                v-model="form.renditeRuhestand"
                type="percent"
                @change="doChanges('renditeRuhestand', $event)"
            />
            <InputTextArea
                label="Notizen"
                v-model="form.bemerkung"
                @change="doChanges('bemerkung', $event)"
                autoGrow
            />
        </div>
        
    </div>
</template>

<script>
import InputField from "@/components/core/forms/InputField.vue";
import InputTextArea from "@/components/core/forms/InputTextArea.vue";
import {parse} from '@/helpers/number-formatter.js';
import mixin from '@/views/ruhestandsplanung/mixin.js';
import RUHESTANDSPLANUNG_TYPES from '@/store/ruhestandsplanung/types';
import validator from "@/mixins/validator";
import { required } from "@/mixins/validator/rules";
import Table from '@/components/table2/Table.vue';
import CurrencyLabel from '@/components/core/CurrencyLabel.vue';
import {TextColumn, SlotColumn} from "@/components/table2/table_util.js";
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import { mapGetters } from "vuex";
import CUSTOMERDATA_TYPES from "@/store/customerData/types.js";

export default {
    mixins: [mixin, validator],
    components: {
        InputField,
        InputTextArea,
        Table,
        CurrencyLabel,
        DatePickerField,
    },
    data() {
        return {
            form: {
                startDate: '',
                bemerkung: '',
                renditeRuhestand: '',
                restKapital: '',
                renteMonatlichErwartet: '',
                bedarfMonatlich: '',
                inflation: '',
                lebenserwartung: '',
                geburtsdatum: '',
            },
        }
    },
    validators: {
        form: {
            title: [required('Bitte geben Sie eine Bezeichnung.')],
            geburtsdatum: [required('Bitte geben Sie den Geburtstag.')],
        }
    },
    created() {
        this.initForm();
        if (this.isNewScenario && !this.customerData?.personalDataBirth && !this.form.geburtsdatum) {
            this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA);
        } 
    },
    watch: {
        customerData: {
            handler(data) {
                if (data?.personalDataBirth?.dayOfBirth && !this.scenario.geburtsdatum) {
                    this.doChanges('geburtsdatum', data.personalDataBirth.dayOfBirth);
                }
            },
            immediate: true,
        }
    },
    computed: {
        ...mapGetters({
            customerDataStore: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA,
        }),
        customerData() {
            return this.customerDataStore;
        },
    },
    methods: {
        initForm() {
            this.form = Object.assign(this.form, this.scenario);
            if (!this.form.lebenserwartung) {
                this.form.lebenserwartung = this.lifeLength;
            }
        },
        percentValue(value) {
            return (parse(value) || 0) * 100 || '';
        },
        doChanges(componentId, value) {
            this.$store.commit(RUHESTANDSPLANUNG_TYPES.MUTATIONS.UPDATE_STORE, { value: { [componentId]: value}, id: this.id });
        },
    },
}
</script>

<style lang="scss" scoped>
.dashboard-panel__widgets {
  margin: 0 -8px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.dashboard-panel-widget {
  margin: 0 12px 24px;
  width: calc(50% - 24px);
}
</style>