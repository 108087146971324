import LIMIT_TYPES from './types';

export default {
  [LIMIT_TYPES.GETTERS.DEPOTLIMIT](state) {
    return state.depotlimit;
  },
  [LIMIT_TYPES.GETTERS.DEPOTLIMIT_LIST](state) {
    return state.depotlimitList;
  },
  [LIMIT_TYPES.GETTERS.DEPOTPOSITION_LIMIT_LIST](state) {
    return state.depotpositionLimits;
  },
  [LIMIT_TYPES.GETTERS.DEPOTPOSITION_LIMIT_AUTOMATIC_LIST](state) {
    return state.depotpositionLimitAutomaticList;
  },
  [LIMIT_TYPES.GETTERS.DEPOTPOSITION_LIMIT_AUTOMATIC](state) {
    return state.depotpositionLimitAutomatic;
  },
  [LIMIT_TYPES.GETTERS.RISIKOKLASSEN_LIMIT](state) {
    return state.risikoklassenLimit;
  },
  [LIMIT_TYPES.GETTERS.RISIKOKLASSEN_LIMIT_LIST](state) {
    return state.risikoklassenLimitList;
  },
  [LIMIT_TYPES.GETTERS.WERTPAPIERKATEGORIE_LIMIT_LIST](state) {
    return state.wertpapierkategorieLimitList;
  },
  [LIMIT_TYPES.GETTERS.WERTPAPIERKATEGORIE_LIMIT](state) {
    return state.wertpapierkategorieLimit;
  },
  [LIMIT_TYPES.GETTERS.WERTPAPIER_LIMIT_LIST](state) {
    return state.wertpapierLimitList;
  },
  [LIMIT_TYPES.GETTERS.WERTPAPIER_LIMIT](state) {
    return state.wertpapierLimit;
  },
  [LIMIT_TYPES.GETTERS.GD200_LIMIT_LIST](state) {
    return state.gd200LimitList;
  },
  [LIMIT_TYPES.GETTERS.GD200_LIMIT](state) {
    return state.gd200Limit;
  },

}