var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-multiple__container" }, [
    _c("label", { staticClass: "input-forms__label-container" }, [
      _vm.label
        ? _c("div", { staticClass: "input-forms__label-content" }, [
            _vm._v(_vm._s(_vm.label))
          ])
        : _vm._e(),
      _c("div", { staticClass: "input-forms__input-container" }, [
        _c(
          "div",
          {
            staticClass: "input-multiple__item-container ",
            class: [
              "flex-" + _vm.direction,
              _vm.direction === "row" ? "layout__negative-margin--8" : ""
            ]
          },
          _vm._l(_vm.values, function(item, index) {
            return _c(
              "div",
              {
                key: "" + item.value + index,
                staticClass: "input-multiple__item",
                class: { "input-multiple__item--row": _vm.direction === "row" }
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "clickable",
                    class: {
                      "input-multiple__item--active": _vm.isItemActive(item)
                    },
                    on: {
                      click: function($event) {
                        return _vm.onClickItem(item)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(item.label) + " ")]
                )
              ]
            )
          }),
          0
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }