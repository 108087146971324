var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "modal",
    attrs: { size: "sm", autoClose: false },
    on: {
      onConfirmButton: function($event) {
        return _vm.save()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "modalTitle",
        fn: function() {
          return [
            _vm._v(' zum "'),
            _c("b", [_vm._v(_vm._s(_vm.parent.label))]),
            _vm._v('" hinzufügen ')
          ]
        },
        proxy: true
      },
      {
        key: "default",
        fn: function() {
          return [
            _c("ComboBox", {
              attrs: { label: "Icon", values: _vm.undrawIconValues },
              scopedSlots: _vm._u(
                [
                  _vm._l(_vm.undrawIconValues, function(undrawIcon) {
                    return {
                      key: undrawIcon.value,
                      fn: function() {
                        return [
                          _c(
                            "div",
                            {
                              key: undrawIcon.value,
                              staticClass: "undraw-combobox--content"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "undraw-combobox--icon" },
                                [
                                  _c(_vm.undrawIcons[undrawIcon.value], {
                                    tag: "component"
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "undraw-combobox--label" },
                                [_vm._v(_vm._s(undrawIcon.label))]
                              )
                            ]
                          )
                        ]
                      },
                      proxy: true
                    }
                  })
                ],
                null,
                true
              ),
              model: {
                value: _vm.form.icon,
                callback: function($$v) {
                  _vm.$set(_vm.form, "icon", $$v)
                },
                expression: "form.icon"
              }
            }),
            _c("InputField", {
              attrs: { label: "Label", autofocus: "" },
              model: {
                value: _vm.form.label,
                callback: function($$v) {
                  _vm.$set(_vm.form, "label", $$v)
                },
                expression: "form.label"
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }