var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: _vm.dynamicTitle
        }
      }),
      _c("BaseFilter", {
        ref: "gdvFileImportViewFilter",
        attrs: {
          title: "Daten einlesen suchen",
          filterId: "Daten einlesen suchen",
          metadata: _vm.metadata,
          showSaveButton: ""
        },
        on: { onFilter: _vm.onFilter }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.loading || !_vm.rows.length
            ? _c("div", [
                _c("span", { staticClass: "box__title" }, [
                  _vm._v(" BiPRO Download Zusammenfassungen ")
                ]),
                _c("br")
              ])
            : _vm._e(),
          _vm.jobStartAllowed
            ? _c(
                "BaseButton",
                {
                  staticStyle: { float: "right" },
                  on: { click: _vm.restartJobs }
                },
                [_vm._v(" Re/Starte fehlerhafte Jobs ")]
              )
            : _vm._e(),
          _vm.loading
            ? _c("GhostLoading", { attrs: { type: "table" } })
            : _vm.rows.length
            ? _c("BigTable", {
                staticStyle: { clear: "both" },
                attrs: {
                  headers: _vm.headers,
                  title: "BiPRO Download Zusammenfassungen",
                  tableId: "b898b0fe-2c80-4a19-947e-d3d7d6551092",
                  rows: _vm.rows,
                  initialPageSize: _vm.minNumRows,
                  page: _vm.page,
                  sortingState: _vm.sortingState
                },
                on: {
                  onClickHeader: _vm.onClickHeader,
                  "action-RESTART": _vm.actionRestart,
                  "action-EXTERNE_ZUGAENGE": _vm.openExterneZugangPage
                },
                scopedSlots: _vm._u([
                  {
                    key: "postdokumenteZugeordnet",
                    fn: function(row) {
                      return [
                        row.warningZugeordnet
                          ? _c(
                              "span",
                              {
                                staticClass: "warningClass",
                                attrs: {
                                  title:
                                    "Daten älter als erwartet. Bitte überprüfen."
                                }
                              },
                              [_vm._v(_vm._s(row.postdokumenteZugeordnet))]
                            )
                          : _c("span", [
                              _vm._v(_vm._s(row.postdokumenteZugeordnet))
                            ])
                      ]
                    }
                  },
                  {
                    key: "postdokumenteNichtZugeordnet",
                    fn: function(row) {
                      return [
                        row.warningNichtZugeordnet
                          ? _c(
                              "span",
                              {
                                staticClass: "warningClass",
                                attrs: {
                                  title:
                                    "Daten älter als erwartet. Bitte überprüfen."
                                }
                              },
                              [_vm._v(_vm._s(row.postdokumenteNichtZugeordnet))]
                            )
                          : _c("span", [
                              _vm._v(_vm._s(row.postdokumenteNichtZugeordnet))
                            ])
                      ]
                    }
                  },
                  {
                    key: "gdv",
                    fn: function(row) {
                      return [
                        row.warningGDV
                          ? _c(
                              "span",
                              {
                                staticClass: "warningClass",
                                attrs: {
                                  title:
                                    "Daten älter als erwartet. Bitte überprüfen."
                                }
                              },
                              [_vm._v(_vm._s(row.gdv))]
                            )
                          : _c("span", [_vm._v(_vm._s(row.gdv))])
                      ]
                    }
                  },
                  {
                    key: "abrechnungen",
                    fn: function(row) {
                      return [
                        row.warningAbrechnungen
                          ? _c(
                              "span",
                              {
                                staticClass: "warningClass",
                                attrs: {
                                  title:
                                    "Daten älter als erwartet. Bitte überprüfen."
                                }
                              },
                              [_vm._v(_vm._s(row.abrechnungen))]
                            )
                          : _c("span", [_vm._v(_vm._s(row.abrechnungen))])
                      ]
                    }
                  },
                  {
                    key: "mahnungen",
                    fn: function(row) {
                      return [
                        row.warningMahnungen
                          ? _c(
                              "span",
                              {
                                staticClass: "warningClass",
                                attrs: {
                                  title:
                                    "Daten älter als erwartet. Bitte überprüfen."
                                }
                              },
                              [_vm._v(_vm._s(row.mahnungen))]
                            )
                          : _c("span", [_vm._v(_vm._s(row.mahnungen))])
                      ]
                    }
                  }
                ])
              })
            : _c("NoData")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }