<template>
  <div>
    <PageHeaderTitleNavigation
      :title="$appNavigation.currentMenu.label"
    />

    <div class="box__container">
      <div v-for="(item, index) of goalsTypes" :key="'goal' + index" class="img-card">
        <div
          class="card mb-4 mr-4 img-card"
          @click="chooseGoal(item)"
        >
          <div class="rel">
            <img
              alt="Platzhalter"
              class="card-img-top"
              :src="item.img"
            />
            <div class="card-img-overlay text-white card-overlay-dark">
              <div class="overlay-panel overlay-panel-bottom color-white mb-auto">
                <h3>{{ item.name }}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue'
import mixin from './my-goals-mixin.js';

export default {
  mixins: [mixin],
  props: {
    percent: 0,
    label: '',
  },
  methods: {
    chooseGoal(item) {
      this.$router.push({path: `${this.path}/new/form`, query: {type: item?.key, desc: item?.name}})
    },
  },
  components: {
    PageHeaderTitleNavigation,
    OptionMenu,
  },
};
</script>

<style scoped>
.img-card {
  cursor: pointer;
  width: 346px;
  display: inline-block;
  margin-right: 16px;
}
img {
  width: 100%;
  height: 15vw;
  object-fit: cover;
}
</style>