<template>
  <div class="box__container">
    <div class="box__title">Vermögensschadenhaftpflicht</div>
    <FormDivider />
    <FormLabel
      label="Anforderung: Gesetzlich vorgeschriebene Vermögensschadenhaftpflichtversicherung."
    />
    <InputField
      :value="form.INPUT_VERSICHERT_BEI_TEXT"
      label="Versichert bei:"
      isComponentHalfSize
      :disabled="disabledStatusOK"
      @change="fieldChanged($event, 'INPUT_VERSICHERT_BEI_TEXT')"
    />
    <InputField
      :value="form.INPUT_POLIZZENNR_TEXT"
      label="Polizzen Nummer:"
      isComponentHalfSize
      :disabled="disabledStatusOK"
      @change="fieldChanged($event, 'INPUT_POLIZZENNR_TEXT')"
    />
     <DatePickerField
        label="gültig bis:"
        :value="form.INPUT_GUELTIG_BIS_TEXT"
        isValueAsString
        isComponentHalfSize
         :disabled="disabledStatusOK"
        @change="fieldChanged($event, 'INPUT_GUELTIG_BIS_TEXT')"
      />
    <FormLabel label="Zur Dokumentation lege ich die Polizze bei." />
    <FormLabel
      label="Bitte die letztgültige Polizze anführen und beilegen, sowie gegebenenfalls die Zahlungsbestätigung."
    />

    <ChecklisteDocsUpload
      :paramId="this.form.PARAM_ID"
      :topic="'Vermögensschadenhaftpflicht'"
      :form="this.form"
    />
    <FormDivider />

    <InputToggleSwitch
      label="Ich bestätige hiermit, die Angaben wahrheitsgetreu getätigt zu haben."
      :inLineLabel="true"
      :config="{ bold: true }"
      isComponentHalfSize
      :disabled="disabledStatusOK"
      :value="true"
    />
  </div>
</template>
<script>
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import FormLabel from "@/components/core/forms/FormLabel.vue";
import FormDivider from "@/components/core/forms/FormDivider.vue";
import InputField from "@/components/core/forms/InputField.vue";
import ChecklisteDocsUpload from "./ChecklisteDocsUpload.vue";
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
export default {
  components: {
    InputToggleSwitch,
    FormLabel,
    FormDivider,
    InputField,
    ChecklisteDocsUpload,
    DatePickerField
  },
  props: {
    form: {},
  },
  computed: {
    disabledStatusOK() {
      return (
        this.form.INPUT_STATUS_OK == "1" || this.form.INPUT_STATUS_OK == "true"
      );
    },
  },
  methods: {
    fieldChanged(newValue, propName) {
      const payload = { name: propName, value: newValue };
      this.$emit("fieldChanged", payload);
    },
  },
};
</script>