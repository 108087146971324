var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dropdown-menu__hook",
      class: { active: _vm.show, "is-dropdown-disabled": _vm.disabled },
      on: {
        click: function($event) {
          return _vm.onClickTargetHook($event)
        }
      }
    },
    [
      _c(
        "div",
        { ref: "dropdownMenuHook", staticClass: "dropdown-menu--hook-target" },
        [_vm._t("hook-target")],
        2
      ),
      _c("transition", { attrs: { name: "transition-active" } }, [
        _c(
          "div",
          {
            ref: "dropdownMenuContainer",
            staticClass: "dropdown-menu__container boxes__corner--rounded",
            class: _vm.containerClasses
          },
          [
            _c(
              "div",
              { staticClass: "dropdown-menu__container--inner" },
              [_vm._t("default")],
              2
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }