<template>
  <div>
    <ComboBox
      label="Gesellschaft"
      validateUntouched
      :values="gesellschaftValues"
      v-model="form.gesellschaft"
      @change="clearAntragsart(); findAntragsart($event), dataChangedCombo('gesellschaft', $event);"
    />

    <ComboBox
      v-if="deckblattTypeConfig.hatAntragTypAuswahl"
      label="Antragsart"
      :values="antragsartValues"
      v-model="form.antragsart"
      @change="dataChangedCombo('antragsart', $event);"
    />

    <InputField
      label="Depotnummer (falls vorhanden)"
      v-model="form.depotnummer"
      @change="dataChanged();"
    />

    <div class="row mt-0 mb-0">
      <div class="col col-sm-8 col-12">
        <DatePickerField
          label="Stand vom"
          isValueAsString
          v-model="form.standVom"
          @change="dataChanged();"
        />
      </div>
      <div class="col col-sm-4 col-12">
        <TimePicker
          label="Uhrzeit"
          v-model="form.uhrzeit"
          @change="dataChanged();"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FILEUPLOAD_TYPES from '@/store/fileUpload/types';

import validator from '@/mixins/validator';
import { required } from '@/mixins/validator/rules';

import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputField from '@/components/core/forms/InputField.vue';
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import TimePicker from '@/components/core/forms/TimePicker.vue';
import customerDocumentUploadMixin from './customer-document-upload-mixin';

export default {
  mixins: [validator, customerDocumentUploadMixin],
  computed: {
    gesellschaftenCombos() {
      return { ...this.deckblattCombos?.gesellschaftenCombos || {} };
    },
    gesellschaftValues() {
      return this.gesellschaftenCombos[this.deckblattType || ''] || [];
    },
    deckblattTypeConfig() {
      if(!this.deckblattType) return {};
      return this.deckblattCombos?.deckblattTypesConfig?.[this.deckblattType] || {};
    },
    antragsartValues() {
      const values = this.comboValues({
        customerID: this.customer?.customerID,
        type: 'INVESTMENTFONDS_ANTRAGSART',
        ids: [this.form?.gesellschaft],
      });
      return [ ...values ];
    },
  },
  methods: {
    clearAntragsart() {
      this.$set(this.form, 'antragsart', undefined);
    },
    async findAntragsart(gesellschaftId) {
      if(!this.deckblattTypeConfig.hatAntragTypAuswahl || !gesellschaftId) return ;

      await this.$store.dispatch(FILEUPLOAD_TYPES.ACTIONS.GET_COMBO_VALUES, {
        customerID: this.customer.customerID,
        type: 'INVESTMENTFONDS_ANTRAGSART',
        ids: [gesellschaftId],
      });
    },
  },
  mounted() {
    this.setStandDatumZeit();
  },
  validators: {
    form: {
      gesellschaft: required('Bitte wählen Sie eine Gesellschaft aus.'),
    },
  },
  components: {
    ComboBox,
    InputField,
    DatePickerField,
    TimePicker,
  },
}
</script>
