<template>
  <div @click="sign()" class="box norm_button plusminus">+ -</div>
</template>

<script>
export default {
  name: "PlusMinus",
  methods: {
    sign() {
      if (this.$store.getters.getCurrent != "") {
        var current =(this.$store.getters.getCurrent).toString();
        if (current.charAt(0) === "-") {
          current =current.slice(1);
        }
        else current = '-'+current;
        this.$store.commit('SET_CURRENT', current)
      }
    }
  }
};
</script>

<style scoped>
.plusminus {
  grid-area: plusminus;
}
</style>