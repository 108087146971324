<template>
    <BaseModal ref="modal"
        modalTitle="E-Mail Adresse ändern"
        @onCloseButton="close"
        :showDefaultButtons="false">
        <template v-slot:default>
            <InputField label="Neue E-Mail Adresse" v-model="email" @change="clearErrorStr()" />
            <BaseButton @click="requestCodes" :disabled="isFormInvalid || tokenButtonsDisabled" isSecondary>Sicherheitscode {{codesRequested ? 'erneut ' : ''}}senden</BaseButton>
            <InputField class="mt-4" label="E-Mail Code" v-model="emailCode" v-if="codesRequested"/>
            <div v-if="errorStr" class="color-danger mt-2">{{errorStr}}</div>
        </template>
        <template v-slot:footer>
            <BaseButton @click="changeEmail" :disabled="isFormInvalid || emailCode.length <= 0" isPrimary>E-Mail Adresse ändern</BaseButton>
        </template>
    </BaseModal>
</template>
<script>
import SSO_TYPES from '@/store/sso/types';
import { mapGetters } from 'vuex';

import BaseModal from '@/components/core/BaseModal.vue';
import InputField from '@/components/core/forms/InputField.vue';
import BaseButton from '@/components/core/BaseButton.vue'
import validator from '@/mixins/validator/index.js';
import { email, required, createRule } from "@/mixins/validator/rules";

export default {
    mixins: [validator],
    validators: {
        email: [
            required(), 
            email('Bitte geben Sie eine gültige E-Mail ein.'),
            createRule((value, self) => !value || value !== self.oldEmail)
                .withMessage('gleich der aktuellen E-Mail'),
        ],
    },
    components: {
        BaseModal,
        InputField,
        BaseButton,
    },
    emits: ['close'],
    computed: {
        ...mapGetters({
            oldEmail: SSO_TYPES.GETTERS.EMAIL,
        }),
        isFormInvalid() {
            return this.validation.updated && this.validation.isInvalid('email');
        },
    },
    data() {
        return {
            email: this.oldEmail || "",
            emailCode: "",
            codesRequested: false,
            tokenButtonsDisabled: false,
            errorStr: '',
        };
    },
    mounted() {
        this.$refs.modal.open();
    },
    methods: {
        requestCodes() {
            this.errorStr = null;
            this.emailCode = "";
            this.codesRequested = true;
            this.tokenButtonsDisabled = true;
            this.$store.dispatch(SSO_TYPES.ACTIONS.REQUEST_EMAIL_CODE, this.email);
            setTimeout(() => {
                this.tokenButtonsDisabled = false;
            }, 2000);
        },
        changeEmail() {
            this.$store.dispatch(SSO_TYPES.ACTIONS.CHANGE_EMAIL, {
                newValue: this.email,
                emailCode: this.emailCode,
            }).then(response => {
                if (response?.data?.errorStr) {
                    if (response.data.email) {
                        this.$pushErrors('emailCode', response.data.errorStr);
                    } else {
                        this.errorStr = response.data.errorStr;
                    }
                } else {
                    this.$store.dispatch(SSO_TYPES.ACTIONS.GET_SSO_ACCOUNT_INFO);
                    this.close();
                }
            }).catch(error => {
                this.errorStr = "Die E-Mail Adresse konnte nicht geändert werden. Vielleicht existiert bereits ein SSO-Zugang mit dieser E-Mail.";
            });
        },
        close() {
            this.errorStr = null;
            this.$refs.modal.close();
            this.$emit('close');
        },
        clearErrorStr() {
            if (this.errorStr) {
                this.errorStr = null;
            }
        }
    },
}
</script>
<style scoped>

</style>