<template>
  <div>
    <BaseButton v-if="action"
      :disabled="orderDisabled || loading || orderInitialized"
      @click="toOrder(2)"
    >{{action}}</BaseButton>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import INVESTMENT_ADVICE from '@/store/investmentAdvice/types';
import BERATUNGSMAPPE_TYPES from "@/store/beratungsmappe/types";
import BaseButton from '@/components/core/BaseButton.vue';


export default {
  props: {
    id: {},
  },
  data() {
    return {
      loading: false,
      action: 'öffnen',
    };
  },
  computed: {
    ...mapGetters({
      parameters: INVESTMENT_ADVICE.GETTERS.PARAMETERS,
      order: INVESTMENT_ADVICE.GETTERS.ORDER,
    }),
    stepParameters() {
      return this.parameters(INVESTMENT_ADVICE.STEPS.ORDER);
    },
    params() {
      if (this.stepParameters && this.stepParameters.data && this.stepParameters.data.parameterData) {
        return this.stepParameters.data.parameterData;
      }
      return undefined;
    },
    orderDisabled() { // this.action == 'öffnen'
      if (this.stepParameters?.data?.zusatz?.bmOption === 1) {
        if (!this.stepParameters.data.zusatz.newMappe || !Object.keys(this.stepParameters.data.zusatz.newMappe).length) {
          return true;
        }
      }
      if (this.stepParameters?.data?.zusatz?.bmOption == undefined) {
        return true;
      }
      return !(this.params && this.params.ziellagerstelle && (this.params.kontonummer || this.params.zielkonto !== 'freie Eingabe:'));
    },
    orderInitialized() {
      return false && this.order && this.order.status === 'success';
    },
  },
  watch:{
    order: function(params) {
      this.loading = false;
    },
    stepParameters() {
      if (this.stepParameters?.data?.zusatz?.bmOption != undefined) {
        this.action = this.stepParameters.data.zusatz.bmOption == 2 ? 'Order öffnen' : 'Mappe öffnen';
      }
      
    }
  },
  methods: {
    async toOrder() {
      this.loading = true;
      let beratung = this.stepParameters.data.zusatz.bmOption;
      const parameters = this.params
      let beratungsmappeId = null;
      if (beratung == -1) {
        beratungsmappeId = this.stepParameters.data.zusatz.linked.id;
      } else if (beratung == 0) {
        beratungsmappeId = this.stepParameters.data.zusatz.bmWahl.id;
      }
      if (beratung == 1) {
        if (!this.stepParameters.data.zusatz.newMappe) {
          return;
        }
        const respMappe = await this.$store.dispatch(BERATUNGSMAPPE_TYPES.ACTIONS.CREATE_BERATUNGSMAPPE, {
          mappeData: this.stepParameters.data.zusatz.newMappe,
          adviceId: this.id,
        })
        if (!respMappe.beratungsMappeId) {
          return;
        }
        beratungsmappeId = respMappe.beratungsMappeId;
      }
      const respOrder = await this.$store.dispatch(INVESTMENT_ADVICE.ACTIONS.TO_ORDER, {
        adviceId: this.id,
        parameters,
        beratung: (beratung == -1 ? 0 : beratung),
      });
      let orderLink = '';
      this.$store.commit(INVESTMENT_ADVICE.MUTATIONS.SET_INVESTMENT_ADVICE_DEPOTEROEFFNUNG_SUCCESS, {});
      this.$store.commit(INVESTMENT_ADVICE.MUTATIONS.SET_INVESTMENT_ADVICE_ORDER_SUCCESS, {});
      if (respOrder.depoteroeffnung) {
        orderLink = respOrder.depoteroeffnungLink;
        this.$store.commit(INVESTMENT_ADVICE.MUTATIONS.SET_INVESTMENT_ADVICE_DEPOTEROEFFNUNG_SUCCESS, {
          type: 'depoteroeffnung',
          posBesitzerId: respOrder.posBesitzer,
          posBesitzerIdVerkauf: respOrder.posBesitzerVerkauf,
          mitBeratung: respOrder.mitBeratung,
          antragList: [{
                label: respOrder.depoteroeffnung,
                checked: respOrder.depoteroeffnungChecked,
                link: orderLink + '?bmOptChosen=true&investementAdviceReferrer=true'
            }],
        })
      } else if (respOrder.orderList?.length) {
        orderLink = '/beratung/formulare/antrag/WP/wertpapierorder/main?initData=true';           
        this.$store.commit(INVESTMENT_ADVICE.MUTATIONS.SET_INVESTMENT_ADVICE_ORDER_SUCCESS, {
          posBesitzerId: respOrder.posBesitzer,
          lagerstelle: parameters.ziellagerstelle,
          depotnr: parameters.zielkonto,
          type: 'wpo',
          orderList: respOrder.orderList.map(lagerstelle =>
            ({
              posBesitzerId: respOrder.posBesitzer,
              label: lagerstelle.label,
              value: lagerstelle.value,
              checked: lagerstelle.checked,
              mitBeratung: respOrder.mitBeratung,
              lagerstelle: lagerstelle.value,
              depotnr: parameters.zielkonto,
              link: orderLink + '&bmOptChosen=true&investementAdviceReferrer=true'
            })),
          mitBeratung: respOrder.mitBeratung,
        });
      }
      if (beratung == 2) {
        this.$router.push({path: orderLink, query: {
          bmOptChosen: true,
          investementAdviceReferrer: true,
        }});
      } else {
        this.$router.push({path: '/beratung/beratung/bearbeiten', query: {
          id: beratungsmappeId,
          investementAdviceReferrer: true,
        }});
      }
    },
  },
  components: {
    BaseButton,
  },
}
</script>
