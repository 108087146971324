<template>
<div>
    <BoxContainer :title="selectedTabLabel">
        <Table v-if="rows.length"
            :headers="headers"
            :mobileConfig="{title: 'Einzahlungen', headers: ['year', 'actualDate', 'actualPercentage']}"
            :rows="rows"
            rowId="index"
            hidePagination
        />
        <div v-else>Keine Daten</div>
    </BoxContainer>
</div>
</template>

<script>
import FONDSINFO_TYPES from "@/store/fondsinfo/types";
import BoxContainer from '@/components/core/BoxContainer.vue';
import { mapGetters } from 'vuex';
import Table from "@/components/table2/Table.vue";
import {TextColumn, DateColumn, PercentageColumn} from "@/components/table2/table_util.js";

export default {
  components: {
    BoxContainer,
    Table,
  },
  name: "TabEinzahlungen",
  props:{
    selectedTabLabel: {
      type: String
    }
  },
    data() {
        return {
            headers: {
                center: [
                    TextColumn("year", "Jahr"),
                    DateColumn("actualDate", "Einzahlungstermin"),
                    PercentageColumn("actualPercentage", "Wert"),
                    TextColumn("comment", "Kommentar"),
                    TextColumn("flag", "After Accept"),
                ],
            }
        };
    },
  computed: {
    ...mapGetters({einzahlungen : FONDSINFO_TYPES.GETTERS.FONDSINFO_EINZAHLUNGEN}),
    rows() {
        return (this.einzahlungen || []).map((row, index) => ({
            index,
            ...row,
            flag: row.flag ? "Ja" : "Nein",
        }));
    },
  },
  mounted() {
    if(!this.einzahlungen) {
      this.$store.dispatch(FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_EINZAHLUNGEN, 
      {
        isin: this.$route.params?.isin,
        bgsNr: this.$route.params?.bgsNr,
      });
    }
  },

}
</script>

<style>

</style>