var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isMobileNativeContext
    ? _c(
        "a",
        {
          class: _vm.linkClass,
          attrs: { draggable: "false" },
          on: { click: _vm.callBridgeViewDocument }
        },
        [
          _vm._t("default", function() {
            return [_vm._v(_vm._s(_vm.title))]
          })
        ],
        2
      )
    : _c(
        "a",
        {
          class: _vm.linkClass,
          staticStyle: { "text-overflow": "ellipsis" },
          attrs: {
            target: "_blank",
            rel: "noopener noreferer",
            draggable: "false",
            title: _vm.title,
            href: (!_vm.disabled && _vm.computedLink) || null
          },
          on: {
            click: function($event) {
              return _vm.emitClickEvent()
            }
          }
        },
        [
          _vm._t("default", function() {
            return [_vm._v(_vm._s(_vm.title))]
          })
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }