<template>
  <MaklerAnsprechpartner :personIdParam="personId" />
</template>

<script>
import BROKERDATA_TYPES from '@/store/brokerData/types';

import MaklerAnsprechpartner from '@/components/broker/MaklerAnsprechpartner.vue';

export default {
  components: {
    MaklerAnsprechpartner,
  },
  data() {
    return {
      personId: null,
    };
  },
  methods: {
    async findPersonId() {
      const personId = await this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.GET_ANSPRECHPARTNER_ID);
      this.personId = `${personId}`;
    },
  },
  async mounted() {
    this.findPersonId();
  },
}
</script>
