var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "zahlwrapper" }, [
    _c(
      "div",
      {
        staticClass: "box norm_button z1",
        on: {
          click: function($event) {
            return _vm.addExpresion("1")
          }
        }
      },
      [_vm._v("1")]
    ),
    _c(
      "div",
      {
        staticClass: "box norm_button z2",
        on: {
          click: function($event) {
            return _vm.addExpresion("2")
          }
        }
      },
      [_vm._v("2")]
    ),
    _c(
      "div",
      {
        staticClass: "box norm_button z3",
        on: {
          click: function($event) {
            return _vm.addExpresion("3")
          }
        }
      },
      [_vm._v("3")]
    ),
    _c(
      "div",
      {
        staticClass: "box norm_button z4",
        on: {
          click: function($event) {
            return _vm.addExpresion("4")
          }
        }
      },
      [_vm._v("4")]
    ),
    _c(
      "div",
      {
        staticClass: "box norm_button z5",
        on: {
          click: function($event) {
            return _vm.addExpresion("5")
          }
        }
      },
      [_vm._v("5")]
    ),
    _c(
      "div",
      {
        staticClass: "box norm_button z6",
        on: {
          click: function($event) {
            return _vm.addExpresion("6")
          }
        }
      },
      [_vm._v("6")]
    ),
    _c(
      "div",
      {
        staticClass: "box norm_button z7",
        on: {
          click: function($event) {
            return _vm.addExpresion("7")
          }
        }
      },
      [_vm._v("7")]
    ),
    _c(
      "div",
      {
        staticClass: "box norm_button z8",
        on: {
          click: function($event) {
            return _vm.addExpresion("8")
          }
        }
      },
      [_vm._v("8")]
    ),
    _c(
      "div",
      {
        staticClass: "box norm_button z9",
        on: {
          click: function($event) {
            return _vm.addExpresion("9")
          }
        }
      },
      [_vm._v("9")]
    ),
    _c(
      "div",
      {
        staticClass: "box norm_button z0",
        on: {
          click: function($event) {
            return _vm.addExpresion("0")
          }
        }
      },
      [_vm._v("0")]
    ),
    _c(
      "div",
      {
        staticClass: "box norm_button zkomma",
        on: {
          click: function($event) {
            return _vm.addExpresion(".")
          }
        }
      },
      [_vm._v(".")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }