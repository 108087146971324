<template>
  <div>
    <div class="box__container" v-if="isEmpty">Keine zusatzliche Daten erforderlich</div>
    <div v-else>
      <div v-if="components['main']">
        <div class="box__title">Allgemein</div>
        <ComponentList
          :components="components['main']"
          :data="positionData"
          :comboboxValues="comboboxValues"
          :warnings="warnings"
          :disabled="readOnly"
          @change="change($event)"
        ></ComponentList>
      </div>
      <div v-if="components['einmaligKauf']">
        <div class="box__title">Kauf</div>
        <ComponentList
          :components="components['einmaligKauf']"
          :data="positionData"
          :comboboxValues="comboboxValues"
          :warnings="warnings"
          :disabled="readOnly"
          @change="change($event)"
        ></ComponentList>
      </div>
      <div v-if="components['einmaligVerkauf']">
        <div class="box__title">Verkauf</div>
        <ComponentList
          :components="components['einmaligVerkauf']"
          :data="positionData"
          :comboboxValues="comboboxValues"
          :warnings="warnings"
          :disabled="readOnly"
          @change="change($event)"
        ></ComponentList>
      </div>
      <div v-if="components['einmaligTausch']">
        <div class="box__title">Umschichtung</div>
        <ComponentList
          :components="components['einmaligTausch']"
          :data="positionData"
          :comboboxValues="comboboxValues"
          :warnings="warnings"
          :disabled="readOnly"
          @change="change($event)"
        ></ComponentList>
      </div>
      <div v-if="components['periodisch']">
        <div class="box__title">Sparplan / Entnahmeplan</div>
        <ComponentList
          :components="components['periodisch']"
          :data="positionData"
          :comboboxValues="comboboxValues"
          :warnings="warnings"
          :disabled="readOnly"
          @change="change($event)"
        ></ComponentList>
      </div>
    </div>

    <BaseModal ref="showEmptyDepotModal"> </BaseModal>
  </div>
</template>

<script>
import ComponentList from "@/components/wertpapierorder/ComponentList.vue";
import WERTPAPIERORDER_TYPES from "@/store/wertpapierorder/types";
import BaseModal from "@/components/core/BaseModal.vue";
import mixin from "@/mixins/wertpapierorder/mixin.js";
import validator from "@/mixins/validator";
import { mapGetters } from "vuex";

export default {
  mixins: [mixin, validator],
  validators: {},
  components: {
    BaseModal,
    ComponentList,
  },
  mounted() {
    this.$store.dispatch(WERTPAPIERORDER_TYPES.ACTIONS.GET_POSITION_DATA_COMPONENTS)
  },
  computed: {
    ...mapGetters({
      positionDataComponents: WERTPAPIERORDER_TYPES.GETTERS.POSITION_DATA_COMPONENTS,
      posData: WERTPAPIERORDER_TYPES.GETTERS.POSITION_DATA,
      pdComboboxValues: WERTPAPIERORDER_TYPES.GETTERS.COMBOBOX_VALUES || {},
    }),
    comboboxValues() {
      return this.pdComboboxValues
    },
    components() {
      return this.positionDataComponents
    },
    positionData() {
      return this.posData
    },
    isEmpty() {
      return !Object.keys(this.components).length;
    },
    warnings() {
      return this.getWarnings('positions-data') || {};
    }
  },
  methods: {
    change(event) {
      if (event && event.componentId) {
         this.$store.commit(WERTPAPIERORDER_TYPES.MUTATIONS.UPDATE_DATA, { [event.componentId]: event.value })  
        this.$store.commit(WERTPAPIERORDER_TYPES.MUTATIONS.UPDATE_POSITION_DATA_STORE, { [event.componentId]: event.value })
      }
    },
  },
};
</script>

<style>
.title-row {
  border-top: 1px solid #f7f3f3;
  background-color: #f7f3f3;
  padding: 1rem;
}
</style>