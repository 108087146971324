<template>
  <div>
    <div v-if="getPositions && getPositions.length">
      <div class="small" v-if="config && config.comment">{{config.comment}}</div>
     <Table
        v-if="tableData.records.length"
        :tableData="tableData"
        :cardViewEnabled="false"
        :filterEnabled="false"
        :exportEnabled="false"
        :paginationEnabled="false"
        :actions="columnActions"
        :rowsPerPage="tableData.records.length"
        @execute-action="handleTableAction($event)"
      >
        <template v-slot:betrag="props">
          <InputField :type="betragType" :value="props.data.row.betrag" :id="'betrag' + props.data.row.index" 
            @input="onChangeInputField($event, props.data.row, getBetragId)"/>
        </template>
        <template v-slot:aa="props">
          <InputField type="percent" :value="props.data.row.aa" :id="'aa' + props.data.row.index" :placeholder="props.data.row.defaultAA"
            @input="onChangeInputField($event, props.data.row, 'aa')"/>
        </template>
          <template v-slot:betragEinmal="props">
          <InputField type="currency" :precision="2" :value="calculator(props.data.row.betrag)" :id="'betragEinmal' + props.data.row.index" :disabled="true"/>
        </template>

      </Table>
    </div>

      <DeletePositionModal ref="deleteModal" 
        :position="positionToDelete"
        @delete="doDeletePosition()"
      ></DeletePositionModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FONDSFINDER_TYPES from '@/store/fondsfinder/types';
import InputField from "@/components/core/forms/InputField.vue";
import WertpapierAuswahlPositionsButtons from '@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlPositionsButtons.vue'
import DeletePositionModal from '@/components/core/forms/antraege/wertpapierAuswahl/DeletePositionModal.vue'
import Table from '@/components/table/Table.vue';
import validator from "@/mixins/validator";
import antragMixin from '@/mixins/antrag/antrag-mixin.js';
import { componentValidatorToRule } from '@/components/antrag/antrag-utils.js';
import ANTRAG_TYPES from '@/store/antrag/types';

export default {
  mixins: [validator, antragMixin],
  validators: {},
  props: {
    positions: {
    },
    config: {
    },
    antragId: {
    },
    categoryId: {
    },
    antragData: {
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      columnActions : [
        {
          legend: {
            icon: 'PhTrash',
            index: 1,
            key: 'DELETE',
            label: 'Löschen',
          }
        }
      ],
      positionToDelete : {
      },
    }
  },
  computed: {
    ...mapGetters({
      positionInfo: FONDSFINDER_TYPES.GETTERS.POSITION_INFO,
      antraege: ANTRAG_TYPES.GETTERS.ANTRAEGE,
    }),
    headers() {
      return {
        isin: {
          label: 'ISIN',
          key: 'isin',
          dataType: 'String',
          sortable: false,
          filterable: false,
          sum: false,
          visible: true,
          fixed: true,
        },
        fondsname: {
          label: 'Fondsname',
          key: 'fondsname',
          dataType: 'String',
          sortable: false,
          filterable: false,
          sum: false,
          visible: true,
          fixed: true,
        },
        betrag: {
          label: this.betragHeader,
          key: 'betrag',
          dataType: 'Slot',
          sortable: false,
          filterable: false,
          sum: this.config?.hasSumme,
          visible: true,
          fixed: true,
        },
        betragEinmal: {
          label: 'Anlagebetrag',
          key: 'betragEinmal',
          dataType: 'Slot',
          sortable: false,
          filterable: false,
          sum: false,
          visible: true,
          fixed: true,
        },
        aa: {
          label: 'Effektenprovision',
          key: 'aa',
          dataType: 'Slot',
          sortable: false,
          filterable: false,
          sum: false,
          visible: this.config?.individualAA,
          fixed: true,
        },
        actions: {
          label: "",
          key: "actions",
          priority: 1,
          dataType: "tableAction",
          visible: true,
          sortable: false,
          filterable: false,
          sum: false,
          fixed: true,
        }
      }
    },
    tableData() {
      return {
        headers: this.headers,
        records: this.getPositions?.map((pos, index) => Object.assign(pos, { 
            fondsname: this.getFondsName(pos, this.positionInfo),
            betrag: pos[this.getBetragId] || null,
            index: index,
            aa: pos.aa,
            defaultAA: this.positionInfo[pos.isin]?.defaultAusgabeaufschlag != null ? "" + this.positionInfo[pos.isin].defaultAusgabeaufschlag : "",
          })),
      }
    },
    betragHeader() {
      return this.config && this.config.betrag && this.config.betrag.label || 'Betrag';
    },
    betragType() {
      let type = 'currency';
      if (this.config && this.config.betrag && this.config.betrag.type) {
        switch(this.config.betrag.type) {
          case 'TEXT_PROZENT': 
            type = 'percent';
            break;
          case 'TEXT': 
            type = 'text'
            break;
          case 'TEXT_NUMBER':
            type = 'number';
            break;
        }
      }
      return type;
    },
    antrag() {
      if (this.antraege && this.antraege[this.antragId]) {
        return this.antraege[this.antragId]
      }
      return null
    },
    getPositions() {
      if (this.positions && this.positions.length) {
        this.positions.filter(pos => !pos[this.getBetragId] && pos[this.getBetragId] !== 0).forEach(pos => 
        this.onChangeInputField(0, pos, this.getBetragId));
      }
      return this.positions;
    },
    positionWarnings() {
      if (this.antrag && this.antrag.warnings) {
        this.updateWarnings(this.antrag.warnings && this.antrag.warnings.positionWarnings);
        return this.antrag.warnings && this.antrag.warnings.positionWarnings;
      }
      return null
    },
    dynamicValidators() {
      if (this.config && this.config.betrag && this.config.betrag.validators) {
        const dynamicValidators = this.getPositions
          .map(pos => ({
            componentId: 'betrag' + pos.index,
            validators: this.config.betrag.validators.map(componentValidatorToRule)
          })
        )

        return dynamicValidators
      }
    },
    getBetragId() {
      return this.config && this.config.betrag && this.config.betrag.id
    },
  },
  methods: {
    calculator(betrag) {
      if (betrag && this.antragData) {
       return this.antragData.rg_IP_Laufzeit * betrag;
      }
    },
    updateWarnings(warnings) {
      if (warnings && warnings.length) {
        for (const warn of warnings) {
          if (warn.posGrpId) {
            if (!warn.id || warn.id.includes(this.categoryId)) {
              const index = this.getPositions.findIndex(pos => pos.posGrpId === warn.posGrpId || pos.isin === warn.posGrpId);
              if (index >= 0) {
                this.$pushErrors('betrag' + index, warn.message || '');
              }
            }
          }
        }
      }
    },
    commitUpdatePositionsFromEvent(positionChange, isin) {
      const payload = {
        id: this.antragId,
        categoryId: this.categoryId,
        fonds: [
          { 
            ...positionChange,
            isin,
          }
        ]
      }
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_POSITIONS, payload)
    },
    onChangeInputField($event, position, field) { 
      const positionChange = { [field]: $event }
      this.commitUpdatePositionsFromEvent(positionChange, position.isin)
    },
    handleTableAction(event) {
      if(event.action?.legend?.key === 'DELETE') {
        this.openModalDeleteFond(event.value);
      }
    },
    openModalDeleteFond(fond) {
      this.positionToDelete = fond;
      this.$refs.deleteModal.open()
    },
    doDeletePosition() {
      this.deletePosition(this.antragId, this.categoryId, this.positionToDelete);
      this.positionToDelete = null;
    }
  },
  components: {
    InputField,
    WertpapierAuswahlPositionsButtons,
    DeletePositionModal,
    Table,
  }
}
</script>

<style scoped>
  .small {
    font-size: small;
    color: var(--color-danger);
    margin-bottom: 1rem;
    padding-left: 2rem;
  }
</style>