var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c(
        "PageHeaderTitleNavigation",
        _vm._g(
          {
            attrs: {
              title: _vm.$appNavigation.currentMenu.label,
              id: _vm.$appNavigation.currentOptionMenuId,
              actions: _vm.pageHeaderActions
            }
          },
          _vm.pageHeaderActionListeners
        )
      ),
      _vm.cardsData && _vm.cardsData.length
        ? _c("div", { staticClass: "base-form__main" }, [
            _c("div", { staticClass: "base-form__stages" }, [
              _vm.loading.init
                ? _c(
                    "div",
                    [
                      _c("GhostLoading", {
                        attrs: {
                          useBoxContainer: "",
                          type: "block",
                          config: { block: { height: 100 } }
                        }
                      }),
                      _c("GhostLoading", {
                        attrs: {
                          useBoxContainer: "",
                          type: "block",
                          config: { block: { height: 100 } }
                        }
                      }),
                      _c("GhostLoading", {
                        attrs: {
                          useBoxContainer: "",
                          type: "block",
                          config: { block: { height: 100 } }
                        }
                      }),
                      _c("GhostLoading", {
                        attrs: {
                          useBoxContainer: "",
                          type: "block",
                          config: { block: { height: 100 } }
                        }
                      }),
                      _c("GhostLoading", {
                        attrs: {
                          useBoxContainer: "",
                          type: "block",
                          config: { block: { height: 100 } }
                        }
                      }),
                      _c("GhostLoading", {
                        attrs: {
                          useBoxContainer: "",
                          type: "block",
                          config: { block: { height: 100 } }
                        }
                      })
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _vm._t("beforeCards"),
                      _c("BoxList", {
                        attrs: { items: _vm.cardsData },
                        on: { clickItem: _vm.handleCardItemClick },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "icon",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  item.status == "question"
                                    ? _c("ph-question", {
                                        staticClass: "color-info",
                                        attrs: { size: 24 }
                                      })
                                    : item.status == "success"
                                    ? _c("ph-check-circle", {
                                        staticClass: "color-success",
                                        attrs: { size: 24 }
                                      })
                                    : item.status == "warning"
                                    ? _c("ph-warning-circle", {
                                        staticClass: "color-warning",
                                        attrs: { size: 24 }
                                      })
                                    : item.status == "danger"
                                    ? _c("ph-warning-circle", {
                                        staticClass: "color-danger",
                                        attrs: { size: 24 }
                                      })
                                    : item.status == "info"
                                    ? _c("ph-info", {
                                        staticClass: "color-info",
                                        attrs: { size: 24 }
                                      })
                                    : _c("ph-circle", {
                                        staticClass: "color-primary",
                                        attrs: { size: 24 }
                                      })
                                ]
                              }
                            },
                            {
                              key: "default",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  item.label
                                    ? _c("h3", { staticClass: "mt-0 mb-1" }, [
                                        _vm._v(_vm._s(item.label))
                                      ])
                                    : _vm._e(),
                                  item.description
                                    ? _c("p", { staticClass: "mt-0 mb-0" }, [
                                        _c("small", [
                                          _vm._v(_vm._s(item.description))
                                        ])
                                      ])
                                    : _vm._e(),
                                  _vm._l(item.warnings, function(
                                    warning,
                                    index
                                  ) {
                                    return _c(
                                      "p",
                                      { key: index, staticClass: "mt-0 mb-0" },
                                      [
                                        _c(
                                          "small",
                                          {
                                            class: _vm.getStatusClass(
                                              warning.status
                                            )
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(warning.message)
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  })
                                ]
                              }
                            },
                            {
                              key: "link",
                              fn: function(ref) {
                                var item = ref.item
                                return [
                                  item.onClick && item.link
                                    ? _c("a", { on: { click: item.onClick } }, [
                                        _vm._v(_vm._s(item.linkText))
                                      ])
                                    : _c("span", [
                                        _vm._v(_vm._s(item.linkText))
                                      ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          880938333
                        )
                      }),
                      _vm._t("afterCards")
                    ],
                    2
                  )
            ]),
            _c(
              "div",
              { staticClass: "beratung-edit__content" },
              [
                _vm._t("formContent", function() {
                  return [
                    _c("GhostLoading", {
                      attrs: {
                        useBoxContainer: "",
                        type: "block",
                        config: { block: { height: 100 } }
                      }
                    })
                  ]
                })
              ],
              2
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }