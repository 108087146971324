var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sofunktionierts_container" },
    [
      _c(
        "div",
        { staticClass: "banner" },
        [
          _c("editable-frame", {
            attrs: { type: "aa_sofunktionierts_title_page" }
          })
        ],
        1
      ),
      _c("h2", [
        _vm._v("In 4 Schritten zum Depot mit der gewünschten VV-Strategie")
      ]),
      _c("hr"),
      _vm._m(0),
      _c(
        "div",
        {},
        [
          _c(
            "base-button",
            {
              on: {
                click: function($event) {
                  return _vm.$router.push("assistent")
                }
              }
            },
            [_vm._v("ZUM ANLAGEASSISTENT")]
          ),
          _c("hr")
        ],
        1
      ),
      _vm._m(1),
      _c("risiko-hinweise")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vierspalten_wrapper" }, [
      _c("div", { staticClass: "vierspalten" }, [
        _c("div", [
          _c("div", { staticClass: "spalten_image" }, [
            _c("img", {
              attrs: {
                alt: "Icon für die Erklärung",
                src: require("@/../public/img/cms/anlageas1.png")
              }
            })
          ]),
          _c("div", { staticClass: "spalten_banner" }, [
            _c("h3", [_vm._v("1. Über verfügbare Strategien informieren. ")])
          ]),
          _c("hr"),
          _c("div", { staticClass: "spalten_text" }, [
            _c("p", [
              _vm._v(
                " Unter dem Menüpunkt „VERMÖGENSVERWALTUNGSSTRATEGIEN ANSEHEN“ informieren Sie sich umfassend und selbständig über die zur Verfügung stehenden Vermögensverwaltungsstrategien innerhalb des Anlageassistenten. "
              )
            ]),
            _c("p", [
              _vm._v(
                " Sie können sowohl den geplanten Einmalbeitrag als auch die Sparpläne erfassen und können dann die simulierte Wertentwicklung der Strategie der letzten fünf Jahre vergleichen. "
              )
            ]),
            _c("p", [
              _vm._v(
                " Ausserdem können Sie an dieser Stelle auch das tagesaktuelle Factsheet der Strategie inkl. aller relevanten Daten wie Kosten etc. abrufen. "
              )
            ]),
            _c("p", [
              _vm._v(
                " Bitte beachten Sie hierbei: Die Angaben beziehen sich auf die Vergangenheit und beruhen auf einer simulierten Wertentwicklung, welcher die tatsächliche Wertentwicklung der derzeit in der Strategie vorhandenen Finanzinstrumente zugrunde liegt. Die frühere Wertentwicklung ist kein zuverlässiger Indikator für zukünftige Ergebnisse.. "
              )
            ]),
            _c("p", [
              _vm._v(
                " Ausserdem ist es wichtig für Sie zu wissen, dass unser Anlageassistent keine persönliche Anlageberatung für Sie bietet. Er ist für Kunden konzipiert, die als Selbstentscheider eine eigene Investmententscheidung in die Tat umsetzen wollen. Für technische Fragen zur Nutzung des Anlageassistents oder eine alternative persönliche Beratung stehen wir Ihnen gerne über unser Kontaktformular zur Verfügung. "
              )
            ])
          ]),
          _c("hr")
        ]),
        _c("div", [
          _c("div", { staticClass: "spalten_image" }, [
            _c("img", {
              attrs: {
                alt: "Icon für die Erklärung",
                src: require("@/../public/img/cms/anlageas2.png")
              }
            })
          ]),
          _c("div", { staticClass: "spalten_banner" }, [
            _c("h3", [_vm._v("2.Geeignetheitstest durchführen")])
          ]),
          _c("hr"),
          _c("div", { staticClass: "spalten_text" }, [
            _c("p", [
              _vm._v(
                " Nachdem Sie die Anlagestrategien verglichen haben können Sie nach Rückkehr auf die Startseite mit dem Button „weiter“ starten und herausfinden, welche Anlagestrategie für Sie geeignet ist. "
              )
            ]),
            _c("p", [
              _vm._v(
                " Hierzu benötigen wir Ihre Mailadresse um die rechtlich notwendigen Unterlagen zu versenden. Nach Erfassung Ihrer Mailadresse drücken Sie bitte „PIN generieren“ und erhalten per Mail eine PIN zugesand um den Anlageassistenten zu starten. Vergessen Sie nicht vorher zu bestätigen, dass Sie volljährig und geschäftsfähig sind und das Depot auf eigene Rechnung eröffnen werden sowie in Deutschland einen Wohnsitz haben und steuerpflichtig sind. Ausserdem dürfen die FACTA Bestimmungen nicht auf Sie anwendbar sein, Sie dürfen also z.B. nicht die US-Staatsbürgerschaft besitzen. "
              )
            ]),
            _c("p", [
              _vm._v(
                " Nachdem Sie die PIN aus der versendeten Mail erfasst haben, gelangen Sie in den sogenannten Geeignetheitstest. Hier erfassen Sie Schritt für Schritt alle notwendigen Informationen, Risikobereitschaft, Nachhaltigkeitspräferenzen, Anlagehorizont, Schulabschluss sowie Ihren bisherigen Erfahrungen und Kenntnissen zu Kapitalanlagen. "
              )
            ]),
            _c("p", [
              _vm._v(
                " Im Anschluss erhalten Sie die Broschüre „Grundlagenwissen zu Wertpapieren & Investmentfonods“ per Mail und sollten sich mit dieser umfassend beschäftigen und auseinandersetzen. "
              )
            ]),
            _c("p", [
              _vm._v(
                " Nun bestätigen Sie noch ob Sie beruflich oder privat Erfahrungen mit Finanzinstrumenten gesammelt haben und welche Art der Finanzdienstleistung Sie bisher genutzt haben und erfassen Ihre finanziellen Verhältnisse, welche zu Art und Umfang der geplanten Kapitalanlage passen müssen. "
              )
            ]),
            _c("p", [
              _vm._v(
                " Nach der Angabe über welche Notfallreserven Sie verfügen, erhalten Sie nun eine Auswahl, welche Strategien für Sie geeignet sind und abgeschlossen werden können. "
              )
            ])
          ]),
          _c("hr")
        ]),
        _c("div", [
          _c("div", { staticClass: "spalten_image" }, [
            _c("img", {
              attrs: {
                alt: "Icon für die Erklärung",
                src: require("@/../public/img/cms/anlageas3.png")
              }
            })
          ]),
          _c("div", { staticClass: "spalten_banner" }, [
            _c("h3", [
              _vm._v(
                "3. Eröffnungsunterlagen für Ihre Anlagestrategie erstellen "
              )
            ])
          ]),
          _c("hr"),
          _c("div", { staticClass: "spalten_text" }, [
            _c("p", [
              _vm._v(
                " Nachdem Sie Ihre gewünschte Strategie selbständig ausgewählt haben, können Sie diese nun eröffnen. Dazu benötigen Sie ein Depot. "
              )
            ]),
            _c("p", [
              _vm._v(
                " Sie entscheiden ob Sie ein Einzeldepot, Gemeinschaftsdepot oder Minderjährigendepot eröffnen wollen. "
              )
            ]),
            _c("p", [
              _vm._v(
                " Nun registrieren Sie sich als neuer Kunde oder melden sich mit Ihren bereits vorhandenen Kundendaten (Kundennummer / Passwort) im Anlageassistent an. Dann schließen Sie mit Eingabe Ihrer Stammdaten den Antrag auf Depoteröffnung erfolgreich ab. Hierbei legen wir hohen Wert auf die Einhaltung des Datenschutzes und die Sicherheit Ihrer Daten. Nach Kenntnisnahme Konto- und Depotbezogener Unterlagen und abschließender rechtlicher Hinweise steht Ihrem Investment nichts mehr im Wege. "
              )
            ]),
            _c("p", [
              _vm._v(" Sie erhalten dann folgende Unterlagen: "),
              _c("ul", [
                _c("li", [
                  _vm._v(
                    "Vermögensverwaltungsvertrag (inkl. Kundendokumentation)"
                  )
                ]),
                _c("li", [_vm._v("Factsheet Anlagestrategie")]),
                _c("li", [_vm._v("Kosteninformationen")]),
                _c("li", [
                  _vm._v(
                    "Vorvertragliche Kundeninformationen (inkl. Widerrufsbelehrung)"
                  )
                ]),
                _c("li", [
                  _vm._v(
                    "Grundlagenwissen zu Wertpapieranlagen (Basisinformationen)"
                  )
                ])
              ])
            ]),
            _c("p", [
              _vm._v(
                " Diese Unterlagen enthalten wichtige Informationen zu der Vermögensverwaltung. Sie sollten daher von Ihnen gelesen werden, bevor Sie mit dem Vertragsabschluss fortfahren. Diese Dokumente finden Sie ebenfalls in der FinanceApp bzw. FinanceCloud in Ihrem Dokumentenarchiv. "
              )
            ])
          ]),
          _c("hr")
        ]),
        _c("div", [
          _c("div", { staticClass: "spalten_image" }, [
            _c("img", {
              attrs: {
                alt: "Icon für die Erklärung",
                src: require("@/../public/img/cms/anlageas4.png")
              }
            })
          ]),
          _c("div", { staticClass: "spalten_banner" }, [
            _c("h3", [_vm._v("4. Depot für Anlagestrategie online eröffnen")])
          ]),
          _c("hr"),
          _c("div", { staticClass: "spalten_text" }, [
            _c("p", [
              _vm._v(
                " Führen Sie nun die „Online Depoteröffnung“ zur Verfügung. Hier können Sie sich zwischen dem Post-Ident oder Video-Ident der Deutschen Post AG entscheiden. Bei diesem Verfahren ist keinerlei Unterschrift oder Ausdruck der Unterlagen notwendig. Der Abschluss erfolgt durch Ihre Zustimmungen im Eröffnungsprozess und wird durch die Legitimation finalisiert "
              )
            ]),
            _c("p", [
              _vm._v(
                " Dieser führt an Hand Ihres Personalausweises oder Reisepasses Ihre Legitimation durch und leitet die Unterlagen an die FFB (FIL Fondsbank GmbH) weiter. Dort werden die Unterlagen auf Vollständigkeit und Richtigkeit geprüft und Ihr Depot incl. Verrechnungskonto wird innerhalb weniger Tage eröffnet. "
              )
            ]),
            _c("p", [
              _vm._v(
                " Über die erfolgreiche Depoteröffnung und die Abbuchung werden Sie postalisch informiert. Ebenso über alle weiteren Umsätze und Bewegungen innerhalb Ihres Kontos und Depots. "
              )
            ]),
            _c("p", [
              _vm._v(
                " Mit Ihrem persönlichen Login steht Ihnen nach erfolgreicher Depoteröffnung das komplette Fondsuniversum der FFB (FIL Fondsbank GmbH) zur Verfügung. "
              )
            ])
          ]),
          _c("hr")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", {}, [_c("div", { staticClass: "text_only" }), _c("hr")])
  }
]
render._withStripped = true

export { render, staticRenderFns }