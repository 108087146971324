/* This method uploads a file chunk by chunk
 * the method returns a promise that resolves with the id of the uploaded file when the upload is finished
 * should the upload fail, the promise is rejected with the most recent error object
 * 
 * if the file to be uploaded is smaller than the chunk size, it is uploaded in one go
 * bigger files are split into chunks of size CHUNK_SIZE (defined as constant below).
 * 
 * reasons for chunked upload (instead of just uploading the whole file in one go)
 * if during an upload, the internet cuts out for a moment, an upload will fail and be re-attempted.
 * when uploading big files in one go, this can mean retransmitting a lot of data.
 * for chunked uploads, only one chunk has to be retransmitted, however.
 */
export function uploadFileChunked(file, progressCallback = null) {
  const queueObject = {
    file,
    // config for axios
    config: {
      defaultSpinner: true,
      headers: {'Content-Type': 'multipart/form-data'},
    },
    chunkIds: [],
  }
  if (progressCallback) {
    queueObject.config.onUploadProgress = progressEvent => {
      const expectedChunkSize = Math.min(CHUNK_SIZE, file.size - queueObject.chunkIds.length * CHUNK_SIZE);
      progressCallback((queueObject.chunkIds.length * CHUNK_SIZE + progressEvent.loaded) / (file.size + progressEvent.total - expectedChunkSize))
    }
  }
  uploadQueue.push(queueObject)
  if (uploadQueue.length == 1)
    startUploading()
  return new Promise((resolve, reject) => {
    queueObject.resolve = resolve
    queueObject.reject = reject
  })
}

export function uploadFilesChunked(files, progressCallback = null) {
  const wasLastFileUploaded = (file) => files.indexOf(file) === files.length - 1;
  return new Promise((resolve) => {
    const ids = [];
    files.forEach((file) => {
      uploadFileChunked(file, progressCallback).then((id) => {
        ids.push(id);

        if(wasLastFileUploaded(file)) {
          resolve(ids);
        }
      });
    });
  });
}

import axios from 'axios';

const CHUNK_SIZE = 10000000 // 10 MB chunks
const uploadQueue = []

function startUploading() {
  uploadNextChunk()
}

function uploadNextChunk(pastFailures = 0) {
  // chunked files are uploaded sequentially
  const queueObject = uploadQueue[0]

  // payload for axios
  let payload = new FormData();
  payload.append("fileName", encodeURI(queueObject.file.name));
  payload.append("ids", queueObject.chunkIds.join(" "));
  payload.append("data", queueObject.file.slice(queueObject.chunkIds.length * CHUNK_SIZE, (queueObject.chunkIds.length + 1) * CHUNK_SIZE));
  if (queueObject.file.size <= (queueObject.chunkIds.length + 1) * CHUNK_SIZE)
    payload.append("isLast", true);

  // send the chunk data
  axios.post(process.env.VUE_APP_API + '/upload_chunk', payload, queueObject.config).then(response => {
    // upload succeeded
    if (payload.get("isLast") == "true") {
      // this was the last (or only) chunk, so the promise is resolved
      queueObject.resolve(response.data.id)
      // if another file has been queued, its upload is now started
      uploadQueue.shift()
      if (uploadQueue.length > 0)
        startUploading()
    }
    else {
      queueObject.chunkIds.push(response.data.id)
      uploadNextChunk()
    }
  }).catch(error => {
    // upload failed
    if (pastFailures >= 2) {
      // this chunk's upload has failed 3 times, so the upload is given up and the promise rejected
      queueObject.reject(error)
      // if another file has been queued, its upload is now started
      uploadQueue.shift()
      if (uploadQueue.length > 0)
        startUploading()
    }
    else {
      // the chunk's upload has failed less than 3 times, so it's reattempted
      uploadNextChunk(pastFailures + 1)
    }
  })
}







/*
 * This method creates a File object from urlData
 * the urlData is expected to be of the following form, which you get for example from canvas.toDataURL();
 *    "data:[...];base64,BASE64..."
 */
export function fileFromUrlData(urlData, fileName, fileType) {
  const decodedData = atob(urlData.split(',')[1]);
  const data = new Uint8Array(decodedData.length);
  for (let i = 0; i < decodedData.length; i+=1)
    data[i] = decodedData.charCodeAt(i);
  return new File([data], fileName, {type: fileType});
}




/*
 * This method requests an image version of the PDF-file uploaded with the given tempFileId
 * it returns a PNG file with the given filename
 */
export function pdfToImage(tempFileId, filename) {
  return new Promise((resolve, reject) => {
    const config = {
      defaultSpinner: true,
      responseType: 'arraybuffer'
    };
    axios.get(process.env.VUE_APP_API + '/idcard/pdfToImage?tempFileId=' + tempFileId, config).then(response => {
      resolve(new File([response.data], filename, {type: "image/png"}));
    }).catch(error => {
      reject(error);
    })
  });
}
