export const MUTATION_PREFIX = 'FONDSINFO: ';
export const ACTIONS_PREFIX = 'FONDSINFO_ACTIONS_';
export const GETTERS_PREFIX = 'FONDSINFO_GETTERS_';

export default {
  MUTATIONS: {
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    GET_FONDSINFO_DETAILS_SUCCESS: MUTATION_PREFIX + 'GET_FONDSINFO_DETAILS_SUCCESS',
    GET_FONDSINFO_ALGEMEIN_SUCCESS: MUTATION_PREFIX + 'GET_FONDSINFO_ALGEMEIN_SUCCESS',
    GET_FONDSINFO_AUSSCHUTTUNG_SUCCESS: MUTATION_PREFIX + 'GET_FONDSINFO_AUSSCHUTTUNG_SUCCESS',
    GET_FONDSINFO_STEUERLICHES_ERGEBNIS_SUCCESS: MUTATION_PREFIX + 'GET_FONDSINFO_STEUERLICHES_ERGEBNIS_SUCCESS',
    GET_FONDSINFO_ERBSCHAFTSSTEUERLICHE_WERTE_SUCCESS: MUTATION_PREFIX + 'GET_FONDSINFO_ERBSCHAFTSSTEUERLICHE_WERTE_SUCCESS',
    GET_FONDSINFO_EINZAHLUNGEN_SUCCESS: MUTATION_PREFIX + 'GET_FONDSINFO_EINZAHLUNGEN_SUCCESS',
    GET_FONDSINFO_MITTELHERKUNFT_SUCCESS: MUTATION_PREFIX + 'GET_FONDSINFO_MITTELHERKUNFT_SUCCESS',
    GET_FONDSINFO_INVESTITIONSKOSTEN_SUCCESS: MUTATION_PREFIX + 'GET_FONDSINFO_INVESTITIONSKOSTEN_SUCCESS',
    GET_FONDSINFO_DOKUMENTE_SUCCESS: MUTATION_PREFIX + 'GET_FONDSINFO_DOKUMENTE_SUCCESS',
    GET_FONDSINFO_PERFORMANCE_SUCCESS: MUTATION_PREFIX + 'GET_FONDSINFO_PERFORMANCE_SUCCESS',
    GET_FONDSINFO_RISIKO_SUCCESS: MUTATION_PREFIX + 'GET_FONDSINFO_RISIKO_SUCCESS',
    GET_FONDSINFO_UNTERLAGEN_SUCCESS: MUTATION_PREFIX + 'GET_FONDSINFO_UNTERLAGEN_SUCCESS',
    GET_FONDSINFO_UNTERLAGEN_LINKS_SUCCESS: MUTATION_PREFIX + 'GET_FONDSINFO_UNTERLAGEN_LINKS_SUCCESS',
    GET_FONDSINFO_RISK_CHART_SUCCESS: MUTATION_PREFIX + 'GET_FONDSINFO_RISK_CHART_SUCCESS',
    GET_FONDSINFO_PERFORMANCE_CHART_SUCCESS: MUTATION_PREFIX + 'GET_FONDSINFO_PERFORMANCE_CHART_SUCCESS',
    GET_FONDSINFO_PURCHASE_SUCCESS: MUTATION_PREFIX + 'GET_FONDSINFO_PURCHASE_SUCCESS',
    GET_FAILTURE: MUTATION_PREFIX + 'GET_FAILTURE',
    GET_FONDSINFO_RENDITE_CHART_SUCCESS: MUTATION_PREFIX + 'GET_FONDSINFO_RENDITE_CHART_SUCCESS',
  },
  ACTIONS: {
    RESET_STATE: ACTIONS_PREFIX + 'RESET_STATE',
    GET_FONDSINFO_DETAILS: ACTIONS_PREFIX + 'GET_FONDSINFO_DETAILS',
    GET_FONDSINFO_ALGEMEIN: ACTIONS_PREFIX + 'GET_FONDSINFO_ALGEMEIN',
    GET_FONDSINFO_AUSSCHUTTUNG: ACTIONS_PREFIX + 'GET_FONDSINFO_AUSSCHUTTUNG',
    GET_FONDSINFO_STEUERLICHES_ERGEBNIS: ACTIONS_PREFIX + 'GET_FONDSINFO_STEUERLICHES_ERGEBNIS',
    GET_FONDSINFO_ERBSCHAFTSSTEUERLICHE_WERTE: ACTIONS_PREFIX + 'GET_FONDSINFO_ERBSCHAFTSSTEUERLICHE_WERTE',
    GET_FONDSINFO_EINZAHLUNGEN: ACTIONS_PREFIX + 'GET_FONDSINFO_EINZAHLUNGEN',
    GET_FONDSINFO_MITTELHERKUNFT: ACTIONS_PREFIX + 'GET_FONDSINFO_MITTELHERKUNFT',
    GET_FONDSINFO_INVESTITIONSKOSTEN: ACTIONS_PREFIX + 'GET_FONDSINFO_INVESTITIONSKOSTEN',
    GET_FONDSINFO_DOKUMENTE: ACTIONS_PREFIX + 'GET_FONDSINFO_DOKUMENTE',
    GET_FONDSINFO_PERFORMANCE: ACTIONS_PREFIX + 'GET_FONDSINFO_PERFORMANCE',
    GET_FONDSINFO_RISIKO: ACTIONS_PREFIX + 'GET_FONDSINFO_RISIKO',
    GET_FONDSINFO_UNTERLAGEN: ACTIONS_PREFIX + 'GET_FONDSINFO_UNTERLAGEN',
    GET_FONDSINFO_UNTERLAGEN_LINKS: ACTIONS_PREFIX + 'GET_FONDSINFO_UNTERLAGEN_LINKS',
    GET_FONDSINFO_UNTERLAGEN_TEMP_ID: ACTIONS_PREFIX + 'GET_FONDSINFO_UNTERLAGEN_TEMP_ID',
    GET_FONDSINFO_RISK_CHART: ACTIONS_PREFIX + 'GET_FONDSINFO_RISK_CHART',
    GET_FONDSINFO_PERFORMANCE_CHART: ACTIONS_PREFIX + 'GET_FONDSINFO_PERFORMANCE_CHART',
    GET_FONDSINFO_PURCHASE: ACTIONS_PREFIX + 'GET_FONDSINFO_PURCHASE',
    DOWNLOAD_PDF: ACTIONS_PREFIX + 'DOWNLOAD_PDF',
    GET_FACTSHEET_PDF: ACTIONS_PREFIX + 'GET_FACTSHEET_PDF',
    GET_FONDSINFO_RENDITE_CHART: ACTIONS_PREFIX + 'GET_FONDSINFO_RENDITE_CHART',
  },
  GETTERS: {
    FONDSINFO_DETAILS: GETTERS_PREFIX + 'FONDSINFO_DETAILS',
    FONDSINFO_ALGEMEIN: GETTERS_PREFIX + 'FONDSINFO_ALGEMEIN',
    FONDSINFO_AUSSCHUTTUNG: GETTERS_PREFIX + 'FONDSINFO_AUSSCHUTTUNG',
    FONDSINFO_STEUERLICHES_ERGEBNIS: GETTERS_PREFIX + 'FONDSINFO_STEUERLICHES_ERGEBNIS',
    FONDSINFO_ERBSCHAFTSSTEUERLICHE_WERTE: GETTERS_PREFIX + 'FONDSINFO_ERBSCHAFTSSTEUERLICHE_WERTE',
    FONDSINFO_EINZAHLUNGEN: GETTERS_PREFIX + 'FONDSINFO_EINZAHLUNGEN',
    FONDSINFO_MITTELHERKUNFT: GETTERS_PREFIX + 'FONDSINFO_MITTELHERKUNFT',
    FONDSINFO_INVESTITIONSKOSTEN: GETTERS_PREFIX + 'FONDSINFO_INVESTITIONSKOSTEN',
    FONDSINFO_DOKUMENTE: GETTERS_PREFIX + 'FONDSINFO_DOKUMENTE',
    FONDSINFO_PERFORMANCE: GETTERS_PREFIX + 'FONDSINFO_PERFORMANCE',
    FONDSINFO_RISIKO: GETTERS_PREFIX + 'FONDSINFO_RISIKO',
    FONDSINFO_UNTERLAGEN: GETTERS_PREFIX + 'FONDSINFO_UNTERLAGEN',
    FONDSINFO_UNTERLAGEN_LINKS: GETTERS_PREFIX + 'FONDSINFO_UNTERLAGEN_LINKS',
    FONDSINFO_RISK_CHART: GETTERS_PREFIX + 'FONDSINFO_RISK_CHART',
    FONDSINFO_PERFORMANCE_CHART: GETTERS_PREFIX + 'FONDSINFO_PERFORMANCE_CHART',
    FONDSINFO_PURCHASE: GETTERS_PREFIX + 'GET_FONDSINFO_PURCHASE',
    FONDSINFO_RENDITE_CHART: GETTERS_PREFIX + 'FONDSINFO_RENDITE_CHART',
  }
}
