import CORE_TYPES from '../../store/core/types';
import LOG_TYPES from '@/store/log/types';
import CMS_TYPES from './types';
import axios from 'axios';
import router from '../../router';
import { buildMessage, } from '../../helpers/log-message-helper';
import { VIEW_ROLES } from '@/router/roles';

const config = {
    defaultSpinner: true
};

export default {


    // NEW
    async [CMS_TYPES.ACTIONS.RETRIEVE_CMS_CONTENT]({ commit, getters, dispatch }) {
        const hasRoles = getters[CORE_TYPES.GETTERS.HAS_ROLES]
        
        if (!hasRoles(VIEW_ROLES.VIEW_BROKER, VIEW_ROLES.VIEW_INTERN)) {
            return;
        }

        const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/cms/mrsCmsMaklerTemplate`)

        commit(CMS_TYPES.MUTATIONS.SET_CMS_CONTENT, response.data);
        if (response.data?.maklerCmsTemplateString === "0") {
            dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, 'No content.');
        }
    },

    async [CMS_TYPES.ACTIONS.RETRIEVE_UNLOGGED_CMS_CONTENT]({ commit, getters }, payload) {
        try {
            const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/cmsget/cmsUnlogged?broker=${payload.calledUserID}`);

            if (response.status === 200) {
                commit(CMS_TYPES.MUTATIONS.SET_CMS_CONTENT, response.data)
            } else {
                router.push('/login').catch(() => {});
            }
        } catch (error) {
            router.push('/login').catch(() => {});
        }
    },

    [CMS_TYPES.ACTIONS.POST_CMS_COMPONENT_CONTENT]({ commit, rootState }, payload) {
        axios.post(rootState.core.apiAddress + '/cms/mrsCmsMaklerPostComponent', payload, config).then(response => {
                commit(CMS_TYPES.MUTATIONS.SET_CMS_CONTENT, response.data);
        })
    },
    
    [CMS_TYPES.ACTIONS.SEND_KONTAKT]({ rootState, dispatch }, payload) {
        return axios.post(rootState.core.apiAddress + '/cmsget/emailer', payload, config).then(response => {
            if (response.data.updateSuccess === "ok") {
                dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Vielen Dank für Ihre Nachricht. Wir werden uns bald bei Ihnen melden.',"success"));
                return true;
            }
            else {
                dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Es ist ein Fehler aufgetreten.',"warning"));
                return false;
            }
        })
    },
    
    [CMS_TYPES.ACTIONS.RETRIEVE_CONTENT_FROM_URL]({ commit, rootState }, payload) {
        axios.post(rootState.core.apiAddress + '/cmsget/retrieveBroker', payload,config).then(response => {
            commit(CMS_TYPES.MUTATIONS.SET_CMS_CONTENT, response.data);
            commit(CMS_TYPES.MUTATIONS.SET_USER_ID, response.data.maklernr);
        })
    },
    [CMS_TYPES.ACTIONS.RETRIEVE_CMS_NEWS]({ commit, rootState, state }) {
        const brokerID = state.userID || rootState.core.brokerData.maklernr
        axios.get(rootState.core.apiAddress + '/cmsget/cmsGetNews?brokerid='+ brokerID ,config).then(response => {
            commit(CMS_TYPES.MUTATIONS.SET_CMS_NEWS, response.data.newsList);
        }).catch(error => { 
            commit(CMS_TYPES.MUTATIONS.SET_CMS_NEWS, []);
        })
    },

    [CMS_TYPES.ACTIONS.RETRIEVE_PRIVACY_LINK]({ commit,state, rootState },payload) {
        const brokerID = state.userID || rootState.core.brokerData.maklernr
        axios.get(rootState.core.apiAddress + '/cmsDatenschutz/getDatenschutzCond?brokerid='+brokerID+'&conditions_type='+payload, config).then(response => {
            commit(CMS_TYPES.MUTATIONS.SET_PRIVACY_LINK, response.data);
        })
    },

    [CMS_TYPES.ACTIONS.REMOVE_EDITABLE_GROUP]({ rootState, commit }, payload) {
        axios.post(rootState.core.apiAddress + '/cms/deleteSpecies',payload,config).then(response => {
            commit(CMS_TYPES.MUTATIONS.SET_CMS_CONTENT, response.data);

        })
    },

    [CMS_TYPES.ACTIONS.ADD_EDITABLE_GROUP]({  rootState, commit }, payload) {
        axios.post(rootState.core.apiAddress + '/cms/addSpecies',payload,config).then(response => {
            commit(CMS_TYPES.MUTATIONS.SET_CMS_CONTENT, response.data);

        })
    },
    
    [CMS_TYPES.ACTIONS.RETRIEVE_PORTFOLIOS]({  rootState,state, commit }, payload) {
        const brokerID = state.userID || rootState.core.brokerData.maklernr
        axios.get(rootState.core.apiAddress + '/cms/getPortfolios?broker='+ brokerID,config).then(response => {
            commit(CMS_TYPES.MUTATIONS.SET_CMS_CONTENT, response.data);
        })
    },

    [CMS_TYPES.ACTIONS.UPDATE_SOCIAL_MEDIA_SRC]({ rootState,state, commit }) {
        const brokerID = state.userID || rootState.core.brokerData.maklernr
        axios.post(rootState.core.apiAddress + '/cms/setSocialMediaSrc/' + brokerID,{
            facebookSrc:rootState.core.brokerData.socialMediaSrc.facebookSrc==null?"":rootState.core.brokerData.socialMediaSrc.facebookSrc,
            twitterSrc:rootState.core.brokerData.socialMediaSrc.twitterSrc==null?"":rootState.core.brokerData.socialMediaSrc.twitterSrc,
            xingSrc:rootState.core.brokerData.socialMediaSrc.xingSrc==null?"":rootState.core.brokerData.socialMediaSrc.xingSrc,
            linkedinSrc:rootState.core.brokerData.socialMediaSrc.linkedinSrc==null?"":rootState.core.brokerData.socialMediaSrc.linkedinSrc,
            instagramSrc:rootState.core.brokerData.socialMediaSrc.instagramSrc==null?"":rootState.core.brokerData.socialMediaSrc.instagramSrc,
        }, config).then(response => {
            commit(CORE_TYPES.MUTATIONS.GET_BROKER_INFORMATION_SUCCESS, response.data);
        })
    },

    [CMS_TYPES.ACTIONS.UPDATE_RSS_SRC]({ rootState,state, commit, dispatch }, payload) {
        const brokerID = state.userID || rootState.core.brokerData.maklernr
        axios.post(rootState.core.apiAddress + '/cms/updateRssFeed/' + brokerID, {
            rssSrc: payload
        }, config).then(response => {
            dispatch(CMS_TYPES.ACTIONS.RETRIEVE_CMS_NEWS)
            commit(CORE_TYPES.MUTATIONS.GET_BROKER_INFORMATION_SUCCESS, response.data);
        })
    },

    [CMS_TYPES.ACTIONS.DELETE_RSS_SRC]({ rootState,state, commit, dispatch }) {
        const brokerID = state.userID || rootState.core.brokerData.maklernr
        axios.delete(rootState.core.apiAddress + '/cms/deleteRssFeed/' + brokerID, config).then(response => {
            dispatch(CMS_TYPES.ACTIONS.RETRIEVE_CMS_NEWS)
            commit(CORE_TYPES.MUTATIONS.GET_BROKER_INFORMATION_SUCCESS, response.data);
        })
    },

    [CMS_TYPES.ACTIONS.SAVE_TEMPLATE_SELECTION]({  rootState,state, commit }, payload) {
        return axios.get(rootState.core.apiAddress + '/cms/saveTemplateSelection?template='+payload.template+'&firstpage='+payload.firstpage,config).then(response =>commit(CMS_TYPES.MUTATIONS.SET_CMS_CONTENT, response.data));

        
    },

    [CMS_TYPES.ACTIONS.RETRIEVE_CMS_TEMPLATE]({  rootState, commit }) {
        return new Promise((resolve, reject) => {
            axios.get(rootState.core.apiAddress + '/cms/retrieveMaklerTemplate',config).then(response => {
                    const payload = { 
                        template: response.data.maklerCmsTemplateString,
                        firstPageRoute: response.data.firstPageRoute
                    }

                    commit(CMS_TYPES.MUTATIONS.SET_SELECTED_TEMPLATE, payload)
                    resolve(payload.template)
                }, (error) => reject(error));
        })
    },
    
    [CMS_TYPES.ACTIONS.EXISTING_INSURANCES]({commit, rootState }, payload) {
        axios.post(rootState.core.apiAddress + '/vvergleichservlet/getVertraege', payload, config).then(response => {
            commit(CMS_TYPES.MUTATIONS.SET_EXISTING_INSURANCES, response.data.insurancesList);
        })
    },

    [CMS_TYPES.ACTIONS.RETRIEVE_INSURANCES_COMPARED_LIST]({ commit, rootState }, payload) {
        axios.post(rootState.core.apiAddress + '/vvergleichservlet/getVergleichsKundeId', payload, config).then(response => {
            commit(CMS_TYPES.MUTATIONS.SET_TABLE_INSURANCES, response.data.insurancesComparedList);
            commit(CMS_TYPES.MUTATIONS.SET_RECORDS_READY, true);
        })
    },

    [CMS_TYPES.ACTIONS.RETRIEVE_TARIFF_DETAILS]({ commit, rootState }, payload) {
        axios.post(rootState.core.apiAddress + '/vvergleichservlet/getTariffDetails', payload, config).then(response => {
            var secDetArray = [];
            for (var i=0;i<response.data.tariffDetailsList.length;i++){
                var tarDetObj ={frage: response.data.tariffDetailsList[i].frage, value:response.data.tariffDetailsList[i].value, explanation : response.data.tariffDetailsList[i].explanation};
                secDetArray.push(tarDetObj);
            }
            commit(CMS_TYPES.MUTATIONS.SET_TARIFF_DETAILS, secDetArray);
            commit(CMS_TYPES.MUTATIONS.SET_TARIFF_DETAILS_READY, true);
        })
    },
}