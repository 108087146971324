var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "box__container" }, [
      _c("div", { staticClass: "box__title" }, [
        _vm._v("Personendaten öffentliche Person")
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("InputField", {
              attrs: {
                label: "Name",
                isComponentHalfSize: true,
                disabled: true
              },
              model: {
                value: _vm.fullName,
                callback: function($$v) {
                  _vm.fullName = $$v
                },
                expression: "fullName"
              }
            })
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "box__container" }, [
      _c("div", { staticClass: "box__title" }, [
        _vm._v("PEP – Politisch exponierte Person")
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("InputToggleSwitch", {
              attrs: {
                label: "PEP – Politisch exponierte Person",
                inLineLabel: true,
                disabled: !_vm.isEditable
              },
              on: {
                input: function($event) {
                  return _vm.addCustomerDataEdited("pep")
                }
              },
              model: {
                value: _vm.pep.pep_aktiv,
                callback: function($$v) {
                  _vm.$set(_vm.pep, "pep_aktiv", $$v)
                },
                expression: "pep.pep_aktiv"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("InputField", {
              attrs: {
                label: "Folgende Funktion",
                isComponentHalfSize: true,
                disabled: !_vm.isEditable
              },
              on: {
                input: function($event) {
                  return _vm.updateFieldState("pep_aktiv", $event)
                },
                change: function($event) {
                  return _vm.addCustomerDataEdited("pep")
                }
              },
              model: {
                value: _vm.pep.pep_funktion,
                callback: function($$v) {
                  _vm.$set(_vm.pep, "pep_funktion", $$v)
                },
                expression: "pep.pep_funktion"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("InputToggleSwitch", {
              attrs: {
                label:
                  "Ist der Depotinhaber ein Ehepartner/Lebensgefährte oder verwandt mit einer Person, die diese Funktion ausübt?",
                inLineLabel: true,
                disabled: !_vm.isEditable
              },
              on: {
                input: function($event) {
                  return _vm.addCustomerDataEdited("pep")
                }
              },
              model: {
                value: _vm.pep.pep_ehe_aktiv,
                callback: function($$v) {
                  _vm.$set(_vm.pep, "pep_ehe_aktiv", $$v)
                },
                expression: "pep.pep_ehe_aktiv"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("InputTextArea", {
              attrs: {
                label: "Detailierte Angaben",
                isComponentHalfSize: true,
                disabled: !_vm.isEditable
              },
              on: {
                input: function($event) {
                  return _vm.updateFieldState("pep_ehe_aktiv", $event)
                },
                change: function($event) {
                  return _vm.addCustomerDataEdited("pep")
                }
              },
              model: {
                value: _vm.pep.pep_ehe_text,
                callback: function($$v) {
                  _vm.$set(_vm.pep, "pep_ehe_text", $$v)
                },
                expression: "pep.pep_ehe_text"
              }
            })
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "box__container" }, [
      _c("div", { staticClass: "box__title" }, [
        _vm._v("VIP – Very important Person")
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("InputToggleSwitch", {
              attrs: {
                label: "VIP – Very important Person",
                inLineLabel: true,
                disabled: !_vm.isEditable
              },
              on: {
                input: function($event) {
                  return _vm.addCustomerDataEdited("pep")
                }
              },
              model: {
                value: _vm.pep.vip_aktiv,
                callback: function($$v) {
                  _vm.$set(_vm.pep, "vip_aktiv", $$v)
                },
                expression: "pep.vip_aktiv"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("InputField", {
              attrs: {
                label: "Folgende Funktion",
                isComponentHalfSize: true,
                disabled: !_vm.isEditable
              },
              on: {
                input: function($event) {
                  return _vm.updateFieldState("vip_aktiv", $event)
                },
                change: function($event) {
                  return _vm.addCustomerDataEdited("pep")
                }
              },
              model: {
                value: _vm.pep.vip_funktion,
                callback: function($$v) {
                  _vm.$set(_vm.pep, "vip_funktion", $$v)
                },
                expression: "pep.vip_funktion"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("InputToggleSwitch", {
              attrs: {
                label:
                  "Ist der Depotinhaber ein Ehepartner/Lebensgefährte oder verwandt mit einer Person, die diese Funktion ausübt?",
                inLineLabel: true,
                disabled: !_vm.isEditable
              },
              on: {
                input: function($event) {
                  return _vm.addCustomerDataEdited("pep")
                }
              },
              model: {
                value: _vm.pep.vip_ehe_aktiv,
                callback: function($$v) {
                  _vm.$set(_vm.pep, "vip_ehe_aktiv", $$v)
                },
                expression: "pep.vip_ehe_aktiv"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("InputTextArea", {
              attrs: {
                label: "Detailierte Angaben",
                isComponentHalfSize: true,
                disabled: !_vm.isEditable
              },
              on: {
                input: function($event) {
                  return _vm.updateFieldState("vip_ehe_aktiv", $event)
                },
                change: function($event) {
                  return _vm.addCustomerDataEdited("pep")
                }
              },
              model: {
                value: _vm.pep.vip_ehe_text,
                callback: function($$v) {
                  _vm.$set(_vm.pep, "vip_ehe_text", $$v)
                },
                expression: "pep.vip_ehe_text"
              }
            })
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "box__container" }, [
      _c("div", { staticClass: "box__title" }, [
        _vm._v("Finanzielle Verhältnisse")
      ]),
      _c("div", { staticClass: "forms__input--half-size" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-6 col-12" },
            [
              _c("InputField", {
                attrs: {
                  type: "currency",
                  label: "Einkommen €",
                  precision: _vm.precision,
                  disabled: !_vm.isEditable
                },
                on: {
                  change: function($event) {
                    return _vm.addCustomerDataEdited("pep")
                  }
                },
                model: {
                  value: _vm.pep.finanzielle_verh_n1,
                  callback: function($$v) {
                    _vm.$set(_vm.pep, "finanzielle_verh_n1", $$v)
                  },
                  expression: "pep.finanzielle_verh_n1"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-6 col-12" },
            [
              _c("InputField", {
                attrs: {
                  type: "currency",
                  label: "Vermögen €",
                  precision: _vm.precision,
                  disabled: !_vm.isEditable
                },
                on: {
                  change: function($event) {
                    return _vm.addCustomerDataEdited("pep")
                  }
                },
                model: {
                  value: _vm.pep.finanzielle_verh_n2,
                  callback: function($$v) {
                    _vm.$set(_vm.pep, "finanzielle_verh_n2", $$v)
                  },
                  expression: "pep.finanzielle_verh_n2"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-6 col-12" },
            [
              _c("InputField", {
                attrs: {
                  type: "currency",
                  label: "Immobilien €",
                  precision: _vm.precision,
                  disabled: !_vm.isEditable
                },
                on: {
                  change: function($event) {
                    return _vm.addCustomerDataEdited("pep")
                  }
                },
                model: {
                  value: _vm.pep.finanzielle_verh_n3,
                  callback: function($$v) {
                    _vm.$set(_vm.pep, "finanzielle_verh_n3", $$v)
                  },
                  expression: "pep.finanzielle_verh_n3"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-6 col-12" },
            [
              _c("InputField", {
                attrs: {
                  type: "currency",
                  label: "Verpflichtungen €",
                  precision: _vm.precision,
                  disabled: !_vm.isEditable
                },
                on: {
                  change: function($event) {
                    return _vm.addCustomerDataEdited("pep")
                  }
                },
                model: {
                  value: _vm.pep.finanzielle_verh_n4,
                  callback: function($$v) {
                    _vm.$set(_vm.pep, "finanzielle_verh_n4", $$v)
                  },
                  expression: "pep.finanzielle_verh_n4"
                }
              })
            ],
            1
          )
        ])
      ])
    ]),
    _c("div", { staticClass: "box__container" }, [
      _c("div", { staticClass: "box__title" }, [
        _vm._v("Angaben über die anfänglich eingebrachten Vermögenswerte")
      ]),
      _c("div", { staticClass: "forms__input--half-size" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-6 col-12" },
            [
              _c("InputField", {
                attrs: { label: "Währung / Betrag", disabled: !_vm.isEditable },
                on: {
                  change: function($event) {
                    return _vm.addCustomerDataEdited("pep")
                  }
                },
                model: {
                  value: _vm.pep.anfang_betrag,
                  callback: function($$v) {
                    _vm.$set(_vm.pep, "anfang_betrag", $$v)
                  },
                  expression: "pep.anfang_betrag"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-6 col-12" },
            [
              _c("InputField", {
                attrs: { label: "Herkunft", disabled: !_vm.isEditable },
                on: {
                  change: function($event) {
                    return _vm.addCustomerDataEdited("pep")
                  }
                },
                model: {
                  value: _vm.pep.anfang_herkunft,
                  callback: function($$v) {
                    _vm.$set(_vm.pep, "anfang_herkunft", $$v)
                  },
                  expression: "pep.anfang_herkunft"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-6 col-12" },
            [
              _c("InputField", {
                attrs: { label: "Hausbank", disabled: !_vm.isEditable },
                on: {
                  change: function($event) {
                    return _vm.addCustomerDataEdited("pep")
                  }
                },
                model: {
                  value: _vm.pep.anfang_bank,
                  callback: function($$v) {
                    _vm.$set(_vm.pep, "anfang_bank", $$v)
                  },
                  expression: "pep.anfang_bank"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-6 col-12 mt-md-24px mt-sm-8px" },
            [
              _c("InputToggleSwitch", {
                attrs: {
                  label: "Wirtschaftlich Berechtigter",
                  inLineLabel: true,
                  disabled: !_vm.isEditable
                },
                on: {
                  input: function($event) {
                    return _vm.addCustomerDataEdited("pep")
                  }
                },
                model: {
                  value: _vm.pep.wirt_ber,
                  callback: function($$v) {
                    _vm.$set(_vm.pep, "wirt_ber", $$v)
                  },
                  expression: "pep.wirt_ber"
                }
              })
            ],
            1
          )
        ])
      ])
    ]),
    _c("div", { staticClass: "box__container" }, [
      _vm._v(
        "Generell keine Verträge bei denen Vertragsinhaber und wirtschaftlich Berechtigter nicht identisch sind!"
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }