import EXPOST_TYPES from './types';
import axios from 'axios';
import { viewDocument } from '@/components/core/download/DownloadLink.vue';

export default {
  async [EXPOST_TYPES.ACTIONS.LOAD_CONFIG]({ commit, rootState }) {
    const response = await axios.get(rootState.core.apiAddress + '/expost/init_config');
    if (response && response.data) {
      commit(EXPOST_TYPES.MUTATIONS.SET_CONFIG, response.data);
    }
  },
  async [EXPOST_TYPES.ACTIONS.SAVE_CONFIG]({ commit, rootState }, payload) {    
    const response = await axios.post(rootState.core.apiAddress + '/expost/set_config', payload, {defaultSpinner: true});
    if (response && response.data) {
      commit(EXPOST_TYPES.MUTATIONS.SET_CONFIG, response.data);
    }
  },
  async [EXPOST_TYPES.ACTIONS.LOAD_LIST]({ commit, rootState }, payload) {    
    const response = await axios.post(rootState.core.apiAddress + '/expost/get_list', payload, {defaultSpinner: true});
    if (response && response.data) {
      commit(EXPOST_TYPES.MUTATIONS.SET_LIST, response.data);
    }
  },

  [EXPOST_TYPES.ACTIONS.GET_PDF]({rootState}, payload) {
    axios.post(`${rootState.core.apiAddress}/expost/get_pdf`, payload, {
        defaultSpinner: true
      }).then(response => {
        let filename = response.data?.filename;
        if (filename) {
          if (filename.indexOf('pdf') === -1) {
            filename = filename + (payload.type.charAt(0) === '.' ? 'pdf' : '.pdf')
          }
          viewDocument({
            filename, 
            data: response.data.pdf, 
            contentType: 'application/pdf',
          });
        }
      });
  },
  [EXPOST_TYPES.ACTIONS.GET_BERECHNUNG]({ commit, rootState }) {
    axios.get(rootState.core.apiAddress + '/expost/getExPostBerechnung').then(response => {
      if (response && response.data) {
        commit(EXPOST_TYPES.MUTATIONS.SET_BERECHNUNG, response.data);
      }
    });
  },
}