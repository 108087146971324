<template>
  <div>
    <div class="box__container">
      <div class="box__title">Gebühren</div>

      <div class="box__title">Beratungsgebühr prozentual gemäß Bestand</div>

      <InputField label="Honorar/Serviceleistung" type="percent"
        v-model="feePropertiesForm.percentage" :precision="4" isComponentHalfSize
        validateUntouched 
        @change="dataChanged()" />
      
      <InputRadioBoxGroup title="Berechnungsgrundlage" 
        v-model="feePropertiesForm.berechnungsgrundlageType" :values="feePropertiesCombos.berechnungsgrundlageType"
        @input="dataChanged()" />

      <InputToggleSwitch label="Zusammenfassung der Einzelpositionen" inLineLabel
        v-model="feePropertiesForm.zusammenfassungGebuehren" 
        @input="dataChanged()" />

      <InputToggleSwitch label="Bankkonten mit berücksichtigen" inLineLabel
        v-model="feePropertiesForm.useBankkonten" 
        @input="dataChanged()" />

      <InputToggleSwitch label="Beteiligungen mit berücksichtigen" inLineLabel
        v-model="feePropertiesForm.useBeteiligungen" 
        @input="dataChanged()" />

      <InputField label="Minimalbetrag" type="currency"
        v-model="feePropertiesForm.minAmount" isComponentHalfSize
        @change="dataChanged()" />
      
      <InputField label="Maximalbetrag" type="currency"
        v-model="feePropertiesForm.maxAmount" isComponentHalfSize
        @change="dataChanged()" />

      <p class="text-italic">Minimal- und Maximalbetrag beziehen sich auf den gesamten errechneten prozentualen Honorar-/Serviceleistung betrag pro Abrechnung.</p>
    </div>

    <div class="box__container">
      <div class="box__title">Pauschalen je Abrechnung</div>

      <div class="forms__input--half-size">
        <div class="row">
          <div class="col-12 col-sm-6">Bezeichnung</div>
          <div class="col-12 col-sm-6">Betrag</div>
        </div>

        <div v-for="lfdnr in MAX_PAUSCHALEN" :key="lfdnr" class="row">
          <div class="col-12 col-sm-6 mb-1 mb-sm-0">
            <InputField type="text"
              v-model="feePropertiesForm['pauschaleBezeichnung' + lfdnr]"
              @change="dataChanged()" />
          </div>
          <div class="col-12 col-sm-6">
            <InputField type="currency"
              v-model="feePropertiesForm['pauschaleBetrag' + lfdnr]"
              @change="dataChanged()" />
          </div>
        </div>
      </div>
    </div>

    <div class="box__container">
      <div class="box__title">Stundensatz</div>

      <InputField label="Abrechnungsbetrag je Stunde" type="currency"
        v-model="feePropertiesForm.stundensatz" isComponentHalfSize 
        @change="dataChanged()" />

      <InputField label="Aufrundung Arbeitszeit je angefangene (Minuten)" type="number"
        v-model="feePropertiesForm.rundungStundensatz" isComponentHalfSize 
        @change="dataChanged()" />
    </div>

    <div v-if="isCustomer" class="box__container">
      <KundeHonorareTable title="Zeitabhängige und fixe Honorargebühren/Serviceleistungen" />
    </div>
  </div>
</template>

<script>
import { mapGetters, } from 'vuex';
import FEE_TYPES from '@/store/fee/types';

import InputField from '@/components/core/forms/InputField.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';

import KundeHonorareTable from './../components/KundeHonorareTable.vue';

import validator from '@/mixins/validator';
import { requiredZeroAllowed, } from "@/mixins/validator/rules";

import feePropertiesStepMixin from '../fee-properties-step-mixin';

const MAX_PAUSCHALEN = 5;

export default {
  mixins: [feePropertiesStepMixin, validator],
  data() {
    return {
      MAX_PAUSCHALEN,
      feePropertiesForm: {
        percentage: '',
        berechnungsgrundlageType: null,
        zusammenfassungGebuehren: null,
        useBankkonten: null,
        useBeteiligungen: null,
        minAmount: null,
        maxAmount: null,
        pauschaleBezeichnung1: null,
        pauschaleBetrag1: null,
        pauschaleBezeichnung2: null,
        pauschaleBetrag2: null,
        pauschaleBezeichnung3: null,
        pauschaleBetrag3: null,
        pauschaleBezeichnung4: null,
        pauschaleBetrag4: null,
        pauschaleBezeichnung5: null,
        pauschaleBetrag5: null,
        stundensatz: null,
        rundungStundensatz: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      feePropertiesCombos: FEE_TYPES.GETTERS.FEE_PROPERTIES_COMBOS,
    }),
  },
  validators: {
    feePropertiesForm: {
      percentage: [requiredZeroAllowed('Sie müssen einen Prozentsatz für das Honorar angeben!')],
    },
  },
  components: {
    InputField,
    InputRadioBoxGroup,
    InputToggleSwitch,
    KundeHonorareTable,
  },
}
</script>
