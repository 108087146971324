import actions from './actions';
import mutations from './mutations';
import getters from './getters';


export function initialTableData() {
  return {
    tableHeaders: [],
    records: [],
    headerLabels: [],
    footerLabels: [],
    isLoaded: false,
    isError: false,
  };
}

/**
 * mutations: synchronous operation responsible for change the state
 * actions: asynchronous operation responsible for call external resources and commit mutations 
 */

export function getInitialState() {
  return {
    otherAssets: undefined,
    credit: undefined,
    bankAccount: undefined,
    closedFunds: undefined,
    depositSum: undefined,
    accounts: undefined,
    accountsDetails: undefined,
    insuranceTable: undefined,
    depotpositions: undefined,

    data: {tableData: [initialTableData()]},
    meta: {
      updatedAt: 0
    },
    credit: initialTableData(),
    bankAccount: initialTableData(),
    closedFunds: initialTableData(),
    fundsDetailDistribution: {data: initialTableData()},
    fundsDetailTaxResult: {data: initialTableData()},
    fundsDetailInheritancetax: {data: initialTableData()},
    fundsDetailGeneral: {data: initialTableData()},
    fundsDetailDescription: {data: initialTableData()},
    fundsDetailInPayments: {data: initialTableData()},
    fundsDetailSource: {data: initialTableData()},
    fundsDetailInvestment: {data: initialTableData()},
    fundsDetailReport: {data: initialTableData()},
    fundsDetailFilesList: {data: initialTableData()},
    fundsPerformanceChart: {data: initialTableData()},
    fundsRiskChart: {data: initialTableData()},
    fundsDetailPerformanceKeys: {data: initialTableData()},
    fundsDetailRiskKeys: {data: initialTableData()},
    fundsPerformanceData: {},
    idData: {},
    depositSum: initialTableData(),

    insuranceTable: {
      data: initialTableData(),
    },
    visibleCategories: [],
    isLoaded: false,
    isRestricted: false,
    insuranceCheckOptions: {},
    insuranceCheckState: {},
    insuranceCheckResult: {},

    depotPositions: { data: {}},

    transactions: {},
    spar_entnahmeplane: {},
    vermogensubersichtPdf: {}
  }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}