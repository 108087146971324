<template>
  <div class="d-none">
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CORE_TYPES from '@/store/core/types';

import { ROLES } from '@/router/roles';
export default {
  data() {
    return {
      backTo: null
    }
  },
  computed: {
    ...mapGetters({
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
    link() {
      if (this.hasRoles([ROLES.FA])) {
        return 'https://www.finanzadmin.at/info-datenschutz';
      }
      else {
        return `https://www.finance-home.de/datenschutz/`
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.backTo = from;
    })
  },
  mounted() {
    window.open(this.link)
    this.$router.push(this.backTo)
  }
}
</script>

<style scoped></style>