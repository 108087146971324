var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box__container" }, [
    _c("div", { staticClass: "box__title" }, [_vm._v("Hobbys")]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _vm.isEditable
          ? _c(
              "div",
              { staticClass: "mb-8px" },
              [
                _c("ComboBox", {
                  attrs: {
                    isComponentHalfSize: true,
                    values: _vm.customerDataConfig.hobbies,
                    sortComboboxValues: false
                  },
                  on: {
                    change: function($event) {
                      return _vm.resetHobbyField()
                    }
                  },
                  model: {
                    value: _vm.hobbyCombo,
                    callback: function($$v) {
                      _vm.hobbyCombo = $$v
                    },
                    expression: "hobbyCombo"
                  }
                }),
                _c("InputField", {
                  attrs: { isComponentHalfSize: true },
                  on: {
                    input: function($event) {
                      return _vm.resetHobbyCombo()
                    }
                  },
                  model: {
                    value: _vm.hobbyField,
                    callback: function($$v) {
                      _vm.hobbyField = $$v
                    },
                    expression: "hobbyField"
                  }
                }),
                _c(
                  "BaseButton",
                  {
                    attrs: { disabled: !_vm.isValid },
                    on: {
                      click: function($event) {
                        return _vm.addHobbys()
                      }
                    }
                  },
                  [_vm._v("Hinzufügen")]
                )
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          [
            _vm.rows.length
              ? _c("Table", {
                  attrs: {
                    tableId: "2bab20e1-b1e1-44ad-aad2-ebacbb3eb51c",
                    headers: _vm.headers,
                    rows: _vm.rows,
                    rowsPerPage: 10,
                    rowId: "hobby"
                  },
                  on: {
                    "action-DELETE": function($event) {
                      return _vm.removeHobbys($event.hobby)
                    }
                  }
                })
              : _c("NoData", { attrs: { noIcon: "" } })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }