var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "modal",
    attrs: {
      modalTitle: _vm.title,
      size: "lg",
      labelButtonConfirm: "Anwenden"
    },
    on: {
      close: _vm.reset,
      onConfirmButton: function($event) {
        return _vm.onConfirm()
      }
    },
    scopedSlots: _vm._u(
      [
        _vm.showFilter
          ? {
              key: "modalHeaderBottom",
              fn: function() {
                return [
                  _c("div", { staticClass: "row my-0" }, [
                    _c(
                      "div",
                      { staticClass: "col-6 col-sm-3" },
                      [
                        _c("InputField", {
                          staticClass: "pb-1",
                          attrs: { label: "Nummer" },
                          model: {
                            value: _vm.filterForm[_vm.rowId],
                            callback: function($$v) {
                              _vm.$set(_vm.filterForm, _vm.rowId, $$v)
                            },
                            expression: "filterForm[rowId]"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6 col-sm-3" },
                      [
                        _c("ComboBox", {
                          attrs: {
                            label: "Status",
                            value: _vm.filterForm.status,
                            values: _vm.statusValues
                          },
                          on: {
                            change: function($event) {
                              return _vm.$set(_vm.filterForm, "status", $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              },
              proxy: true
            }
          : null,
        {
          key: "default",
          fn: function() {
            return [
              _vm.hasConflicts
                ? _c("div", { staticClass: "fc-alert fc-alert-warning" }, [
                    _c("div", [_vm._v("Es gibt einige Datenkonflikte.")]),
                    _c("div", [
                      _vm._v(
                        "Unabhängig davon, ob diese Konfiguration auf eine Konfiguration mit Datenkonflikt angewendet wird, verlieren diese Benutzer ihre Konfiguration."
                      )
                    ])
                  ])
                : _vm._e(),
              _c("Table", {
                key: _vm.rows.length,
                attrs: {
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowId: _vm.rowId,
                  rowsPerPage: _vm.TABLE_ROWS_PER_PAGE,
                  ignoreTablePageSize: ""
                },
                on: { "action-VIEW_DIFF": _vm.openViewDiffModal },
                scopedSlots: _vm._u([
                  {
                    key: "header_select",
                    fn: function() {
                      return [
                        _c("InputCheckBoxItem", {
                          staticClass: "checkbox--select-all",
                          attrs: {
                            value: _vm.isAllSelected,
                            indeterminate: _vm.hasSelected
                          },
                          on: {
                            input: function($event) {
                              return _vm.onSelectAll($event)
                            }
                          }
                        })
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "select",
                    fn: function(row) {
                      return [
                        _c("InputCheckBoxItem", {
                          attrs: { value: _vm.isRowSelected[row[_vm.rowId]] },
                          on: {
                            input: function($event) {
                              return _vm.onSelect(row[_vm.rowId], $event)
                            }
                          }
                        })
                      ]
                    }
                  }
                ])
              }),
              _c("OptionsMenuConfigViewDiffModal", { ref: "viewDiffModal" })
            ]
          },
          proxy: true
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }