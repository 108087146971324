<template>
  <div>
    <div class="row">
      <span class="col-6" v-if="position && position.hasEditFields">
        <ph-pencil-line :size="16" class="clickable" />
      </span>
      <span class="col-6" v-if="position && (position.isin ||position.isinZu)">
        <ph-trash :size="16" class="clickable" @click.prevent="openModalDeleteFond()"/>
      </span>
    </div>
    <BaseModal ref="deleteModal" 
      modalTitle="Position entfernen?"
      labelButtonConfirm="Position entfernen"
      @onConfirmButton="onConfirmModal()"
      @onCancelButton="onCancelModal()">
      <div>
        Soll die Position <span class="font-bold">{{ fondDescription }}</span> wirklich entfernt werden?
      </div>
    </BaseModal>
  </div>
</template>

<script>
import BaseModal from '@/components/core/BaseModal.vue';
import antragMixin from '@/mixins/antrag/antrag-mixin.js';
import { PhPencilLine, PhTrash } from 'phosphor-vue';

export default {
  mixins: [antragMixin],
  props: {
    position: {
    },
    positionName: {
    },
    antragId: {
    },
    categoryId: {
    },
    doUpdateStore: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    fondDescription() {
      return `${this.positionName} (ISIN ${this.position.isin})`
    }
  },
  methods: {
    openModalDeleteFond() {
      if (!this.disabled) {
        this.$refs.deleteModal.open()
      }
    },
    onConfirmModal() {
      if (this.doUpdateStore) {
        this.doDeletePosition()
      } else {
        this.$emit('delete', this.position);
      }
    },
    onCancelModal() {

    },
    doDeletePosition() {
      if (!this.disabled) {
        this.deletePosition(this.antragId, this.categoryId, this.position);
        this.positionToDelete = null;
      }
    },
  },
  components: {
    BaseModal,
    PhPencilLine,
    PhTrash,
  }
}
</script>

<style scoped>
  .las {
    font-size: 1.5rem;
  }
</style>