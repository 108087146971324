import FONDSVERGLEICH_TYPES from './types';

export default {
  [FONDSVERGLEICH_TYPES.GETTERS.ACT](state) {
    return state.session;
  },
  [FONDSVERGLEICH_TYPES.GETTERS.SETTINGS](state) {
    return state.settings;
  },
  [FONDSVERGLEICH_TYPES.GETTERS.GET_FUNDS](state) {
    return state.session?.fundsCompare || [];
  },
  [FONDSVERGLEICH_TYPES.GETTERS.GET_UNSEL_FUNDS](state) {
    return state.session?.fundsUnselect || [];
  },
  [FONDSVERGLEICH_TYPES.GETTERS.GET_START_END_ZOOM](state) {
    return state.session?.zoom || {};
  },
  [FONDSVERGLEICH_TYPES.GETTERS.GET_FILTER](state) {
    return state.filter;
  },
}