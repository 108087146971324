var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.isFullColor
        ? _c("InputColorPicker", {
            attrs: {
              size: _vm.size,
              value: _vm.currentColor,
              disabled: _vm.disabled
            },
            on: {
              change: function($event) {
                return _vm.$emit("change", $event)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }