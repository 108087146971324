<template>
  <div @click="clear()" class="box spec_button totaleraser">C</div>
</template>

<script>
export default {
  name: "TotalEraser",
  methods: {
  
    clear: function () {
           this.$store.commit("SET_CURRENT", "");
           this.$store.commit("SET_OPERATORCLICKED", false);
           this.$store.commit("SET_KOMMACLICKED", false);
           this.$store.commit("SET_ANZAHL", "12");
           this.$store.commit("SET_ENDE", "0");
           this.$store.commit("SET_JAHRE", "0");
           this.$store.commit("SET_RATE", "0");
           this.$store.commit("SET_START", "0");
           this.$store.commit("SET_ZINS", "0");
           this.$store.commit("SET_EINZAHLUNGEN", "0");
           this.$store.commit("SET_AUSZAHLUNGEN", "0");
           this.$store.commit("SET_ZINSEN", "0");
        },
  }
};
</script>

<style scoped>
.totaleraser {
  grid-area: totaleraser;
}
</style>