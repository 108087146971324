<template>
    <div>
      <!-- This component is completly empty now because the download of the vcard should
        not have a UI and be triggered from the menu immediatly; it is triggerd in the route - 
        see persoenlicheDaten.js  path: 'vcard-kundendaten' beforeEnter
      -->
    </div>
  </template>
  
  <script>
  export default {
    // Nothing here
  }
  </script>
  
  <style>
  /* Nothing here */
  </style>