var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isByPass
    ? _c("Broker", {
        attrs: {
          vertragId: _vm.vertragId,
          schadenId: _vm.schadenId,
          value: _vm.ortDatum
        },
        on: {
          sendInfo: function($event) {
            return _vm.getInfo($event.target.ortDatum)
          },
          "data-changed": function($event) {
            return _vm.$emit("data-changed", $event)
          }
        }
      })
    : _c("Customer", {
        attrs: { vertragId: _vm.vertragId, schadenId: _vm.schadenId },
        on: {
          "data-changed": function($event) {
            return _vm.$emit("data-changed", $event)
          }
        }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }