<template>
<div>
  <MailBriefVorlage :prependActions="headerActions" 
    @action-SAVE="saveTemplates()"
    @action-REFRESH="onRefresh()"
  />

  <div v-if="loadingTemplate || !id" class="box__container">
    <div>
      <template>
        <div v-if="templateData || !id">
          <div class="row">
            <div class="col col-md-6">
              <ComboBox
                label="Freigabe"
                v-model="grant"
                :values="grantValues">
              </ComboBox>
            </div>
          </div>
          <div class="row">
            <div class="col col-md-6">
              <InputField
                label="Bezeichnung"
                v-model="subject"
              >
              </InputField>
            </div>
            <div class="col col-md-6">
              <InputField
                label="Bemerkung"
                v-model="subjectOverwrite"
              >
              </InputField>
            </div>
          </div>
          <div>
            <div class="col col-md-6" v-if="id && currentFilename">
              <InputField
                label="aktueller Dateiname"
                v-model="currentFilename"
                :disabled="true"
              >
              </InputField>
            </div>
          </div>
          <DragnDropArea hoverText="Datei hier ablegen" @files="addDocument($event)">
            <div class="d-block justify-content-between">
              <div
                class="d-flex d-md-block mt-1 layout__negative-margin--8 antrag-action-button__container justify-content-center">
                <BaseButton isSecondary class="upload-btn">
                  <slot>Datei hochladen *</slot>
                  <input type="file" :accept="acceptFileTypes" @change="onAddFiles" />
                </BaseButton>
                <div class="mt-2">{{fileName}}</div>
                <div class="text-small">
                   * Bitte beachten Sie, dass nur Dateien vom Typ .doc akzeptiert werden.
                </div>
              </div>
            </div>
          </DragnDropArea>
        </div>
      </template>
    </div>
  </div>
  <GhostLoading v-else type="block" />
</div>
</template>

<script>
import TEMPLATES_EDIT_TYPES from '@/store/templatesEdit/types';
import CORE_TYPES from '@/store/core/types';
import { mapGetters } from 'vuex'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import InputField from '@/components/core/forms/InputField.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import MAILCOMPOSER_TYPES from '@/store/mailcomposer/types';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import MailBriefVorlage from './MailBriefVorlage.vue'
import DragnDropArea from '@/components/fileUpload/DragnDropArea.vue';
import { uploadFileChunked } from '@/helpers/upload-helper';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import { required } from '@/mixins/validator/rules';
import validator from '@/mixins/validator';

export default {
  mixins: [validator],
  components: {
    GhostLoading,
    InputField,
    BaseButton,
    ComboBox,
    MailBriefVorlage,
    DragnDropArea,
  },
  props: {
  },
  data() {
    return {
      upLoadTempFileId: null,
      fileName: '',
      currentFilename: '',
      template: {},
      html: '',
      category: '',
      subjectOverwrite: '',
      subject: '',
      type: '',
      typeValues: [
        {value: 'BRIEF', label: 'Brief'},
        {value: 'BRIEF_MIT_DS', label: 'Brief mit Datenschutzerklärung'},
        {value: 'EMAIL', label: 'E-Mail'}
      ],
      grant: '',
      acceptFileTypes: '.doc',
    }
  },
  watch: {
    templateData(val) {
      if (val) {
        this.subject = val.subject;
        this.html = val.html;
        this.grant = val.grant;
        this.type = val.type;
        this.category = val.category;
        this.subjectOverwrite = val.bemerkung;
        this.template = val;
        this.currentFilename = val.fileName;
      }
    },
  },
  computed: {
    ...mapGetters({
      preview: TEMPLATES_EDIT_TYPES.GETTERS.GET_PREVIEW,
      letterTemplate: TEMPLATES_EDIT_TYPES.GETTERS.GET_LETTER_TEMPLATE_EDIT,
      categories: TEMPLATES_EDIT_TYPES.GETTERS.GET_LETTER_CATEGORIES,
      templateData: MAILCOMPOSER_TYPES.GETTERS.GET_WORD_TEMPLATE,
      placeholders: MAILCOMPOSER_TYPES.GETTERS.GET_PLACEHOLDERS,
      isIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
    }),
    grantValues() {
      const grantValues = [
        {value: 'NO', label: 'Keine Freigabe'},
        {value: 'STRUCTURE', label: 'Freigabe für Struktur'},
      ]

      if (this.isIntern) {
        grantValues.push({value: 'ALL', label: 'Freigabe für Alle Vermittler'});
      }

      return grantValues;
    },
    loadingTemplate() {
      return !this.letterTemplate;
    },
    categoryValues() {
      return this.categories && this.categories.map(v => ({
        value: v.key,
        label: v.label
      })) || []
    },
    id() {
      return this.$route.params.id !== 'new' ? this.$route.params.id : null;
    },
    headerActions() {
      const { id, } = this;

      const actions = [];
      actions.push(PageHeaderSimpleAction('SAVE', 'Speichern')
        .withDisabled(() => !this.validation.valid || (!this.upLoadTempFileId && !this.id)));
      if(id) {
        actions.push(PageHeaderSimpleAction('REFRESH', 'Aktualisieren'));
      }
      return actions;
    },
  },
  validators: {
    subject: [required()],
  },
  methods: {
    saveTemplates() {
      this.$store.dispatch(
        TEMPLATES_EDIT_TYPES.ACTIONS.SAVE_WORD_LETTER_TEMPLATE,
        {
          id: this.id,
          bemerkung: this.subjectOverwrite,
          subject: this.subject,
          grant: this.grant,
          fileName: this.fileName,
          upLoadTempFileId: this.upLoadTempFileId,
        }
      ).then(v => {
        this.$router.push({ path: `../../mailvorlage` });
      })
    },
    addDocument(files) {
      files && files.map(file => {
        uploadFileChunked(file).then(id => {
          this.upLoadTempFileId = id;
          this.fileName = file.name || this.id;
        })
      })
      // upload file
    },
    onAddFiles(event) {
      const files = Object.values(event.target.files);
      // upload file
      uploadFileChunked(files[0]).then(id => {
        this.upLoadTempFileId = id;
        this.fileName = files[0].name || this.id;
      })
      event.target.value = ''
    },
    onRefresh() {
      this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.GET_WORD_TEMPLATE, this.id);
    },
    
  },

  mounted() {
    if (this.id) {
      this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.GET_WORD_TEMPLATE, this.id);
    } else {
      this.subject = '';
      this.html = '';
      this.grant = 'NO';
      this.type = '';
      this.category = '';
      this.subjectOverwrite = '';
      this.template = {};
    }
    this.$store.dispatch(TEMPLATES_EDIT_TYPES.ACTIONS.GET_LETTER_CATEGORIES);
    this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.GET_SENDERS);
    this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.GET_PLACEHOLDERS, {});
  },
  beforeDestroy() {
    this.$store.commit(TEMPLATES_EDIT_TYPES.MUTATIONS.GET_LETTER_TEMPLATE_EDIT, null);
    
    this.subject = '';
    this.html = '';
    this.grant = '';
    this.type = '';
    this.category = '';
    this.subjectOverwrite = '';
    this.template = {};
  },
}
</script>

<style scoped>
.top-buttons {
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
}
.upload-btn {
  position: relative;
}

.upload-btn input {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
</style>
