import CONFIG_DEFINITION_TYPES from './types';

export default {
  [CONFIG_DEFINITION_TYPES.GETTERS.ALL_CONFIGS_DEFINITION](state) {
    return [ ...state.allConfigsDefinition || []];
  },
  [CONFIG_DEFINITION_TYPES.GETTERS.CONFIGS_DEFINITION](state) {
    return state.configsDefinition
  },
  [CONFIG_DEFINITION_TYPES.GETTERS.ALL_CONFIGS_AVAILABLE](state) {
    return state.allConfigsAvailable
  },
  [CONFIG_DEFINITION_TYPES.GETTERS.CONFIG_DEFINITION_SELECTED](state) {
    return state.configDefinitionSelected && { ...state.configDefinitionSelected, } || null;
  },
  [CONFIG_DEFINITION_TYPES.GETTERS.CONFIG_DEFINITION_EDITED](state) {
    return state.configDefinitionEdited && { ...state.configDefinitionEdited, } || null;
  },
  [CONFIG_DEFINITION_TYPES.GETTERS.CONFIG_PERMISSION_LIST](state) {
    return [ ...state.configPermissionList || [], ];
  },
  [CONFIG_DEFINITION_TYPES.GETTERS.CHANGED_CONFIGS](state) {
    return {...state.changedConfigs};
  },
  [CONFIG_DEFINITION_TYPES.GETTERS.HAS_CHANGED_CONFIGS](state) {
    return Object.keys(state.changedConfigs).length > 0;
  },
  [CONFIG_DEFINITION_TYPES.GETTERS.CONFIG_DEFINITION_OPTIONS_MENU](state) {
    return [ ...state.configDefinitionOptionMenu || [], ];
  },
}
