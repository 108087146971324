<template>
  <BaseButton isClear @click="openModal()">
    <ph-magnifying-glass :size="size" />

    <BaseModal
      :size="modalSize"
      ref="searchModal"
      :showDefaultButtons="false"
      @close="modalClosed"
    >
      <template #modalTitle>
        <div class="global-search--title-container">
          <div class="global-search--title">Globale Suche</div>
          <GlobalSearchHelpDropdown class="global-search--help-button" />
        </div>
      </template>

      <template #default>
        <GlobalSearch 
          @recentMenuOpened="hideModal" 
          @close="hideModal" 
          @modalSizeChanged="changeModalSize"
        />

        <div class="text-right">
          <span class="text-small">version: {{ currentVersion }}</span>
        </div>
      </template>
    </BaseModal>
  </BaseButton>
</template>

<script>
import BaseButton from "@/components/core/BaseButton.vue";
import { PhMagnifyingGlass } from 'phosphor-vue';
import BaseModal from "@/components/core/BaseModal.vue";
import GlobalSearch from '@/components/core/header/buttons/GlobalSearch.vue';
import GlobalSearchHelpDropdown from '@/components/core/header/buttons/GlobalSearchHelpDropdown.vue';

export default {
  name: 'GlobalSearchModal',
  props: {
    size: {
      default: 18
    }
  },
  data() {
    return {
      lastRequestUrl: '',
      value: '',
      lastValue: '',
      isLoading: false,
      showModal: false,
      modalSize: 'md',
    }
  },
  computed: {
    currentVersion() {
      const buildVersion = document.documentElement.getAttribute('data-build-timestamp');
      return `v${buildVersion}`;
    },
  },
  mounted() {
    document.onkeydown = (e) => {
      const spaceKey = 32;
      if (e.ctrlKey && e.keyCode === spaceKey) {
        this.openModal()
        return false;
      }
    }
  },
  methods: {
    modalClosed() {
      this.lastRequestUrl = '';
      this.value = '';
      this.lastValue = '';
    },
    openModal() {
      this.$refs.searchModal.open();
    },
    hideModal() {
      this.$refs.searchModal.close();
    },
    changeModalSize(size = 'md') {
      this.modalSize = size;
    }
  },
  components: {
    BaseButton,
    PhMagnifyingGlass,
    BaseModal,
    GlobalSearch,
    GlobalSearchHelpDropdown,
  },
}
</script>

<style lang="scss" scoped>
.global-search--title-container {
  display: flex;
  justify-content: space-between;

  .global-search--help-button {
    flex: 0 0 auto;
  }
}
</style>
