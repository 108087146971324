<template>
  <div>
    <div>
      <div class="text-small edit" v-if="isBroker || isIntern" @click="edit">
        bearbeiten
        <ph-pencil-line title="Bearbeiten" :size="16" />
      </div>
      <div class="inner_content">
        <div v-if="getNotOuterError">
          <img :src="getComputedInhalt" @error="handleOuterError()" />
        </div>
      </div>
    </div>
    <BaseModal
      ref="EditableImagesModal"
      modalTitle="Bitte wählen Sie ein Bild aus"
      @onConfirmButton="onConfirmModal()"
      @onCancelButton="onCancelModal()"
    >
      <div class="selector_class">
        <div class="preview">
          <div v-if="getNotInnerError">
            <img :src="this.selectedImage" @error="handleInnerError()" />
          </div>
        </div>
        <div class="image_grid">
          <div v-for="(image,index) in images" :key="index">
            <div class="image_grid_item" @click="selectThis(image,index)">
              <div class="grid_item_image">
                <img
                  :src="image.pathLong"
                  :class="{ image_selected: isSelected(index), image_not_selected: !isSelected(index) }"
                />
              </div>
            </div>
          </div>
        </div>
        <div style="margin: 1rem auto;">
          <BaseButton isSecondary  @click="noImage">Kein Bild</BaseButton>
        </div>
      </div>
    </BaseModal>
  </div>
</template>
<script>

import { buildMessage } from "@/helpers/log-message-helper";
import CORE_TYPES from "@/store/core/types";
import CMS_TYPES from "@/store/cms/types";
import LOG_TYPES from '@/store/log/types';
import { mapGetters } from "vuex";
import BaseModal from "@/components/core/BaseModal.vue";
import BaseButton from "@/components/core/BaseButton.vue";
import { PhPencilLine } from 'phosphor-vue';


export default {
  props: {
    type: {
      type: String,
      default: "",
      required: true
    },
    genus: {
      type: String,
      default: null,
      required: false
    },
    species: {
      type: String,
      default: null,
      required: false
    }
  },
  components: {
    BaseModal,
    BaseButton,
    PhPencilLine,
  },
  data() {
    return {
      images: [],
      selectedImage: "",
      selectedIndex: "",
      notInnerError: true,
      notOuterError: true,
      changed:false,
    };
  },
  computed: {
    ...mapGetters({
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      getInhalt: CMS_TYPES.GETTERS.GET_CMS_COMPONENTS,
      getComponentContent: CMS_TYPES.GETTERS.GET_CMS_COMPONENT_CURRENT_CONTENT,
      getComponentContentUpdated:
        CMS_TYPES.GETTERS.GET_CMS_COMPONENT_CONTENT_UPDATED
    }),
    getNotInnerError() {
      return this.notInnerError;
    },
    getNotOuterError() {
      return this.notOuterError;
    },
    getType() {
      return this.type;
    },
    getGenus() {
      return this.genus;
    },
    getSpecies() {
      return this.species;
    },
    getComputedInhalt() {
      if (!this.getInhalt) {
        return;
      }

      if (this.genus == null && this.species == null) {
        const found = this.getInhalt.find(x => x.type == this.getType);
        if (found) {
          return found.inhalt;
        }
      } else {
        const found = this.getInhalt.find(
          x =>
            x.type == this.getType &&
            x.genus == this.getGenus &&
            x.species == this.getSpecies
        );
        if (found) {
          return found.inhalt;
        }
      }
    }
  },
  methods: {
    noImage(){
      this.selectedImage="";
      this.notInnerError=false;
      this.notOuterError=false;
    
    },
    handleOuterError() {
     if (!this.changed) this.notOuterError = false;
    },
     handleInnerError() {
       this.notInnerError = false;
    },
    isSelected(index) {
      return this.selectedIndex === index ? true : false;
    },
    selectThis(image, index) {
      this.selectedImage = image.pathLong;
      this.selectedIndex = index;
      this.changed=true;
      this.notOuterError = true;
      this.notInnerError = true;
    },
    getImageName(name) {
      let cleanName = name.substring(2, name.length - 4);
      return cleanName;
    },

    edit: function() {
      this.$refs.EditableImagesModal.open();
    },
    onConfirmModal: function() {
      var myself = this;
      if (this.selectedImage == this.getComputedInhalt) {
        this.onCancelModal();
        this.$store.dispatch(
          LOG_TYPES.ACTIONS.ADD_MESSAGE,
          buildMessage("No change was made.")
        );
      } else {
        this.$store
          .dispatch(CMS_TYPES.ACTIONS.POST_CMS_COMPONENT_CONTENT, {
            type: myself.getType,
            inhalt: myself.selectedImage,
            genus: myself.getGenus,
            species: myself.getSpecies
          })
          .then(this.onCancelModal());
      }
    },
    onCancelModal: function() {
     
    },
    importAll(r) {
      r.keys().forEach(key =>
        this.images.push({ pathLong: r(key), pathShort: key })
      );
    }
  },
  mounted() {
    this.importAll(
      require.context("@/../public/img/cms/selectable_images/", true, /\.svg$/)
    );
    this.selectedImage = this.getComputedInhalt;
  }
};
</script>
<style scoped>
.kein_bild{
}
.preview {
  text-align: center;
}
.selector_class {
  margin-bottom: 2em;
  text-align: center;
}
.image_grid {
  margin-top: 2em;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
}
.image_grid::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}
.image_grid_item {
  padding-bottom: 0.5em;
  padding-top: 0.5em;
}
.grid_item_image {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.image_selected {
  width: 60px;
}
.image_not_selected {
  width: 50px;
}
.name_selected {
  font-weight: bold;
}
.edit {
  color: var(--color-danger);
  cursor: pointer;
}
.buttons_confirm_cancel {
  display: flex;
  justify-content: space-between;
}
</style>