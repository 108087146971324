import MITARBEITER_STRUKTUR_TYPES from './types';
import { getInitialState } from './index'

export default {
  [MITARBEITER_STRUKTUR_TYPES.MUTATIONS.RETRIEVE_MITARBEITER_STRUKTUR_SUCCESS](state, payload) {
    state.mitarbeiterStruktur = payload;
  }, 
  [MITARBEITER_STRUKTUR_TYPES.MUTATIONS.RESET_STATE](state, payload) {
    Object.assign(state, getInitialState())
  }, 
}