var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _c("div", { staticClass: "font-strong mb-2" }, [
        _vm._v(
          " Aufzeichnung von Telefongesprächen und sonstiger elektronischer Kommunikation "
        )
      ]),
      _c("InputRadioBoxGroup", {
        attrs: { values: _vm.vertragWPValues.pflichtEkomm },
        on: {
          input: function($event) {
            return _vm.fieldChanged()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "after-OK",
            fn: function() {
              return [
                _c("InputToggleSwitch", {
                  attrs: {
                    inLineLabel: "",
                    label:
                      "Dabei habe(n) ich (wir) Softwareanwendung(en) innerhalb des MSC,"
                  },
                  on: {
                    input: function($event) {
                      return _vm.fieldChanged()
                    }
                  },
                  model: {
                    value: _vm.form.angabeEkommAufzeichnungMsc,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "angabeEkommAufzeichnungMsc", $$v)
                    },
                    expression: "form.angabeEkommAufzeichnungMsc"
                  }
                }),
                _c("InputToggleSwitch", {
                  attrs: {
                    inLineLabel: "",
                    label:
                      "bzw. sonstige anderweitige externe Softwarelösungen verwendet"
                  },
                  on: {
                    input: function($event) {
                      return _vm.fieldChanged()
                    }
                  },
                  model: {
                    value: _vm.form.angabeEkommAufzeichnungExtern,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "angabeEkommAufzeichnungExtern", $$v)
                    },
                    expression: "form.angabeEkommAufzeichnungExtern"
                  }
                })
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.form.angabeEkommAufzeichnung,
          callback: function($$v) {
            _vm.$set(_vm.form, "angabeEkommAufzeichnung", $$v)
          },
          expression: "form.angabeEkommAufzeichnung"
        }
      }),
      _c("div", [
        _vm._v(
          " Insbesondere wurden notwendige Maßnahmen ergriffen, um die Aufzeichnungen gegen die nachträgliche Verfälschung und unbefugte Verwendung zu sichern. "
        )
      ]),
      _c("hr"),
      _c("div", { staticClass: "font-strong mb-2" }, [
        _vm._v(
          " Im Prüfungszeitraum beauftragte(n) Ich (Wir) für die Anlagenvermittlung und –beratung "
        )
      ]),
      _c("InputRadioBoxGroup", {
        attrs: { values: _vm.vertragWPValues.personenBeschaeftigtAktive },
        on: {
          input: function($event) {
            return _vm.fieldChanged()
          }
        },
        model: {
          value: _vm.form.personenBeschaeftigtAktiv,
          callback: function($$v) {
            _vm.$set(_vm.form, "personenBeschaeftigtAktiv", $$v)
          },
          expression: "form.personenBeschaeftigtAktiv"
        }
      }),
      _c("InputTextArea", {
        attrs: {
          label:
            "(bitte geben Sie den Vor- und Zunamen sowie das Geburtsdatum dieser Personen an)"
        },
        on: {
          change: function($event) {
            return _vm.fieldChanged()
          }
        },
        model: {
          value: _vm.form.personenBeschaeftigt,
          callback: function($$v) {
            _vm.$set(_vm.form, "personenBeschaeftigt", $$v)
          },
          expression: "form.personenBeschaeftigt"
        }
      }),
      _c("ComboBox", {
        attrs: {
          isComponentHalfSize: "",
          label: "Bekannte Personen hinzufügen:",
          values: _vm.vertragWPValues.maklerPeople
        },
        model: {
          value: _vm.form.comboPersonenBeschaeftigt,
          callback: function($$v) {
            _vm.$set(_vm.form, "comboPersonenBeschaeftigt", $$v)
          },
          expression: "form.comboPersonenBeschaeftigt"
        }
      }),
      _c(
        "div",
        { staticClass: "mb-2" },
        [
          _c(
            "BaseButton",
            {
              attrs: { isSecondary: "" },
              on: {
                click: function($event) {
                  return _vm.addPerson()
                }
              }
            },
            [_vm._v(" Hinzufügen ")]
          )
        ],
        1
      ),
      _c("div", { staticClass: "mb-2" }, [
        _vm._v(
          " * Diese Personen verfügen über einen entsprechenden Sachkundennachweis gemäß § 4 Abs. 1 FinVermV und wurden auf die Einhaltung der §§ 12 bis 18 FinVermV verpflichtet. Entsprechende Erklärungen liegen vor. "
        )
      ]),
      _c("div", { staticClass: "font-strong" }, [
        _vm._v(" Innerhalb des Prüfungszeitraumes wurden ")
      ]),
      _c("InputRadioBoxGroup", {
        attrs: { values: _vm.vertragWPValues.angabeZweigniederlassung },
        on: {
          input: function($event) {
            return _vm.fieldChanged()
          }
        },
        model: {
          value: _vm.form.hasZweigniederlassung,
          callback: function($$v) {
            _vm.$set(_vm.form, "hasZweigniederlassung", $$v)
          },
          expression: "form.hasZweigniederlassung"
        }
      }),
      _c("InputTextArea", {
        attrs: { label: "(Bitte geben sie die vollständige Adresse an.)" },
        on: {
          change: function($event) {
            return _vm.fieldChanged()
          }
        },
        model: {
          value: _vm.form.zweigniederlassung,
          callback: function($$v) {
            _vm.$set(_vm.form, "zweigniederlassung", $$v)
          },
          expression: "form.zweigniederlassung"
        }
      }),
      _c("div", { staticClass: "font-strong" }, [
        _vm._v(
          " Haben sich seit dem 1. Januar " +
            _vm._s(_vm.form.currentYear) +
            " rechtliche und wirtschaftliche Änderungen im Erlaubnisumfang (z. B. Umzug, Änderung im Erlaubnisumfang, Änderungen der eingesetzten Mitarbeiter) ergeben? "
        )
      ]),
      _c("InputRadioBoxGroup", {
        attrs: { values: _vm.vertragWPValues.erlaubnisumfangAktiv },
        on: {
          input: function($event) {
            return _vm.fieldChanged()
          }
        },
        model: {
          value: _vm.form.erlaubnisumfangAktiv,
          callback: function($$v) {
            _vm.$set(_vm.form, "erlaubnisumfangAktiv", $$v)
          },
          expression: "form.erlaubnisumfangAktiv"
        }
      }),
      _c("InputTextArea", {
        attrs: {
          label:
            "(bitte geben Sie den Vor- und Zunamen sowie das Geburtsdatum dieser Personen an)"
        },
        on: {
          change: function($event) {
            return _vm.fieldChanged()
          }
        },
        model: {
          value: _vm.form.erlaubnisumfangText,
          callback: function($$v) {
            _vm.$set(_vm.form, "erlaubnisumfangText", $$v)
          },
          expression: "form.erlaubnisumfangText"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }