export const MUTATION_PREFIX = 'LOG: ';
export const ACTIONS_PREFIX = 'LOG_ACTIONS_';
export const GETTERS_PREFIX = 'LOG_GETTERS_';

export default {
  MUTATIONS: {
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    ADD_LOG: MUTATION_PREFIX + 'ADD_LOG',
    ADD_MESSAGE: MUTATION_PREFIX + 'ADD_MESSAGE',
    REMOVE_MESSAGE: MUTATION_PREFIX + 'REMOVE_MESSAGE',
  },
  ACTIONS: {
    LOG: ACTIONS_PREFIX + 'LOG',
    INFO: ACTIONS_PREFIX + 'INFO',
    WARN: ACTIONS_PREFIX + 'WARN',
    ERROR: ACTIONS_PREFIX + 'ERROR',
    ADD_LOG: ACTIONS_PREFIX + 'ADD_LOG',
    ADD_MESSAGE: ACTIONS_PREFIX + 'ADD_MESSAGE',
    ADD_RESPONSE_ERROR_MESSAGE: ACTIONS_PREFIX + 'ADD_RESPONSE_ERROR_MESSAGE',
    REMOVE_MESSAGE: ACTIONS_PREFIX + 'REMOVE_MESSAGE',

    LOG_ON_SCREEN: ACTIONS_PREFIX + 'LOG_ON_SCREEN',
  },
  GETTERS: {
    MESSAGES: GETTERS_PREFIX + 'MESSAGES',
  }
}