var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.editMode
        ? _c(
            "BaseButton",
            {
              staticClass: "mb-16px",
              on: {
                click: function($event) {
                  return _vm.neueBeteiligte()
                }
              }
            },
            [_vm._v("Neue Person hinzufügen")]
          )
        : _vm._e(),
      _c(
        "div",
        [
          _vm.tableData.records.length
            ? _c("Table", {
                attrs: {
                  tableId: "d944db51-7e78-45ab-be48-b993d9803675",
                  tableData: _vm.tableData,
                  actions: _vm.tableActions,
                  cardViewEnabled: false,
                  filterEnabled: false,
                  exportEnabled: false,
                  rowsPerPage: _vm.tableData.records.length,
                  paginationEnabled: false
                },
                on: {
                  "execute-action": function($event) {
                    return _vm.handleTableAction($event)
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "rollenFahrer",
                      fn: function(ref) {
                        var data = ref.data
                        return [
                          _vm._v(
                            " " +
                              _vm._s(data.row.rollen.fahrer ? "Ja" : "Nein") +
                              " "
                          )
                        ]
                      }
                    },
                    {
                      key: "rollenGeschadigter",
                      fn: function(ref) {
                        var data = ref.data
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                data.row.rollen.geschadigter ? "Ja" : "Nein"
                              ) +
                              " "
                          )
                        ]
                      }
                    },
                    {
                      key: "rollenVerursacher",
                      fn: function(ref) {
                        var data = ref.data
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                data.row.rollen.verursacher ? "Ja" : "Nein"
                              ) +
                              " "
                          )
                        ]
                      }
                    },
                    {
                      key: "rollenZeuge",
                      fn: function(ref) {
                        var data = ref.data
                        return [
                          _vm._v(
                            " " +
                              _vm._s(data.row.rollen.zeuge ? "Ja" : "Nein") +
                              " "
                          )
                        ]
                      }
                    },
                    {
                      key: "rollenSachverstandiger",
                      fn: function(ref) {
                        var data = ref.data
                        return [
                          _vm._v(
                            " " +
                              _vm._s(
                                data.row.rollen.sachverstandiger ? "Ja" : "Nein"
                              ) +
                              " "
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3446675260
                )
              })
            : _c("div", [_vm._v("Keine Daten")])
        ],
        1
      ),
      _c("BaseModal", {
        ref: "beteiligteModal",
        attrs: { showDefaultButtons: false, actions: _vm.baseModalActions },
        on: {
          "action-SAVE_PERSON_DATA": function($event) {
            return _vm.beteiligteSpeichern()
          },
          "action-CANCEL": function($event) {
            return _vm.closeBeteiligteModal()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "modalTitle",
            fn: function() {
              return [
                _vm._v(
                  _vm._s(
                    _vm.inserting == true
                      ? "Neue Beteiligte"
                      : "Beteiligte bearbeiten"
                  )
                )
              ]
            },
            proxy: true
          },
          {
            key: "default",
            fn: function() {
              return [
                _c(
                  "div",
                  [
                    _c("div", { staticClass: "box__title" }, [
                      _vm._v("Allgemeine Angaben")
                    ]),
                    _c("InputField", {
                      attrs: {
                        label: "Zugehöriger Schadensfall",
                        type: "text",
                        disabled: true,
                        value: _vm.sparte
                      }
                    }),
                    _c("div", { staticClass: "mt-16px" }, [
                      _c("div", { staticClass: "box__title" }, [
                        _vm._v("Rolle(n)")
                      ]),
                      _c(
                        "div",
                        { staticClass: "checkbox-input__container" },
                        [
                          _c("InputCheckBoxItem", {
                            attrs: { label: "Geschädigter" },
                            model: {
                              value: _vm.beteiligte_form.rollen.geschadigter,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.beteiligte_form.rollen,
                                  "geschadigter",
                                  $$v
                                )
                              },
                              expression: "beteiligte_form.rollen.geschadigter"
                            }
                          }),
                          _c("InputCheckBoxItem", {
                            attrs: { label: "Verursacher" },
                            model: {
                              value: _vm.beteiligte_form.rollen.verursacher,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.beteiligte_form.rollen,
                                  "verursacher",
                                  $$v
                                )
                              },
                              expression: "beteiligte_form.rollen.verursacher"
                            }
                          }),
                          _c("InputCheckBoxItem", {
                            attrs: { label: "Fahrer" },
                            model: {
                              value: _vm.beteiligte_form.rollen.fahrer,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.beteiligte_form.rollen,
                                  "fahrer",
                                  $$v
                                )
                              },
                              expression: "beteiligte_form.rollen.fahrer"
                            }
                          }),
                          _c("InputCheckBoxItem", {
                            attrs: { label: "Zeuge" },
                            model: {
                              value: _vm.beteiligte_form.rollen.zeuge,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.beteiligte_form.rollen,
                                  "zeuge",
                                  $$v
                                )
                              },
                              expression: "beteiligte_form.rollen.zeuge"
                            }
                          }),
                          _c("InputCheckBoxItem", {
                            attrs: { label: "Sachverständiger" },
                            model: {
                              value:
                                _vm.beteiligte_form.rollen.sachverstandiger,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.beteiligte_form.rollen,
                                  "sachverstandiger",
                                  $$v
                                )
                              },
                              expression:
                                "beteiligte_form.rollen.sachverstandiger"
                            }
                          }),
                          _c("InputCheckBoxItem", {
                            attrs: { label: "Sonstiges" },
                            on: {
                              input: function($event) {
                                return _vm.whenSonstigesChanged($event)
                              }
                            },
                            model: {
                              value: _vm.beteiligte_form.rollen.sonstiges,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.beteiligte_form.rollen,
                                  "sonstiges",
                                  $$v
                                )
                              },
                              expression: "beteiligte_form.rollen.sonstiges"
                            }
                          }),
                          _vm.beteiligte_form.rollen.sonstiges
                            ? _c("InputField", {
                                attrs: { label: "", validateUntouched: "" },
                                model: {
                                  value: _vm.beteiligte_form.bettext,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.beteiligte_form,
                                      "bettext",
                                      $$v
                                    )
                                  },
                                  expression: "beteiligte_form.bettext"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mt-16px" },
                  [
                    _c("div", { staticClass: "box__title" }, [
                      _c("span", { staticClass: "mr-2" }, [
                        _vm._v("Personendaten")
                      ]),
                      _c(
                        "a",
                        {
                          staticClass: "align-end",
                          staticStyle: { "flex-grow": "0" },
                          on: { click: _vm.handleAddUsers }
                        },
                        [_c("PhBookOpen", { attrs: { size: 24 } })],
                        1
                      )
                    ]),
                    _c("ComboBox", {
                      attrs: {
                        label: "Anrede",
                        values: _vm.anrede,
                        firstEmpty: true
                      },
                      model: {
                        value: _vm.beteiligte_form.anrede,
                        callback: function($$v) {
                          _vm.$set(_vm.beteiligte_form, "anrede", $$v)
                        },
                        expression: "beteiligte_form.anrede"
                      }
                    }),
                    _c("InputField", {
                      attrs: { label: "Titel" },
                      model: {
                        value: _vm.beteiligte_form.titel,
                        callback: function($$v) {
                          _vm.$set(_vm.beteiligte_form, "titel", $$v)
                        },
                        expression: "beteiligte_form.titel"
                      }
                    }),
                    _c("InputField", {
                      attrs: { label: "Nachname", validateUntouched: true },
                      model: {
                        value: _vm.beteiligte_form.nachname,
                        callback: function($$v) {
                          _vm.$set(_vm.beteiligte_form, "nachname", $$v)
                        },
                        expression: "beteiligte_form.nachname"
                      }
                    }),
                    _c("InputField", {
                      attrs: { label: "Vorname" },
                      model: {
                        value: _vm.beteiligte_form.vorname,
                        callback: function($$v) {
                          _vm.$set(_vm.beteiligte_form, "vorname", $$v)
                        },
                        expression: "beteiligte_form.vorname"
                      }
                    }),
                    _c("InputField", {
                      attrs: { label: "Straße/Hausnummer" },
                      model: {
                        value: _vm.beteiligte_form.strasse,
                        callback: function($$v) {
                          _vm.$set(_vm.beteiligte_form, "strasse", $$v)
                        },
                        expression: "beteiligte_form.strasse"
                      }
                    }),
                    _c("ComboBox", {
                      attrs: {
                        label: "Land",
                        values: _vm.lands,
                        firstEmpty: true
                      },
                      model: {
                        value: _vm.beteiligte_form.land,
                        callback: function($$v) {
                          _vm.$set(_vm.beteiligte_form, "land", $$v)
                        },
                        expression: "beteiligte_form.land"
                      }
                    }),
                    _c("InputZIP", {
                      attrs: { label: "PLZ" },
                      on: {
                        city: function($event) {
                          _vm.beteiligte_form.ort = $event
                        }
                      },
                      model: {
                        value: _vm.beteiligte_form.plz,
                        callback: function($$v) {
                          _vm.$set(_vm.beteiligte_form, "plz", $$v)
                        },
                        expression: "beteiligte_form.plz"
                      }
                    }),
                    _c("InputField", {
                      attrs: { label: "Ort" },
                      model: {
                        value: _vm.beteiligte_form.ort,
                        callback: function($$v) {
                          _vm.$set(_vm.beteiligte_form, "ort", $$v)
                        },
                        expression: "beteiligte_form.ort"
                      }
                    }),
                    _c("InputField", {
                      attrs: { label: "Telefon", inputmode: "tel" },
                      model: {
                        value: _vm.beteiligte_form.telefon,
                        callback: function($$v) {
                          _vm.$set(_vm.beteiligte_form, "telefon", $$v)
                        },
                        expression: "beteiligte_form.telefon"
                      }
                    }),
                    _c("InputField", {
                      attrs: { label: "E-Mail" },
                      model: {
                        value: _vm.beteiligte_form.email,
                        callback: function($$v) {
                          _vm.$set(_vm.beteiligte_form, "email", $$v)
                        },
                        expression: "beteiligte_form.email"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mt-16px" },
                  [
                    _c("div", { staticClass: "box__title" }, [
                      _vm._v(
                        "Kontodaten für Zahlungen (nur relevant für Firmen)"
                      )
                    ]),
                    _c("InputCheckBoxItem", {
                      attrs: {
                        label: "Beteiligter ist Vorsteuerabzugsberechtigt"
                      },
                      model: {
                        value: _vm.beteiligte_form.vorsteuerabzugsberechtigt,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.beteiligte_form,
                            "vorsteuerabzugsberechtigt",
                            $$v
                          )
                        },
                        expression: "beteiligte_form.vorsteuerabzugsberechtigt"
                      }
                    }),
                    _c("InputField", {
                      attrs: { label: "Bank" },
                      model: {
                        value: _vm.beteiligte_form.bank,
                        callback: function($$v) {
                          _vm.$set(_vm.beteiligte_form, "bank", $$v)
                        },
                        expression: "beteiligte_form.bank"
                      }
                    }),
                    _c("InputField", {
                      attrs: { label: "IBAN" },
                      model: {
                        value: _vm.beteiligte_form.iban,
                        callback: function($$v) {
                          _vm.$set(_vm.beteiligte_form, "iban", $$v)
                        },
                        expression: "beteiligte_form.iban"
                      }
                    }),
                    _c("InputField", {
                      attrs: { label: "BIC" },
                      model: {
                        value: _vm.beteiligte_form.bic,
                        callback: function($$v) {
                          _vm.$set(_vm.beteiligte_form, "bic", $$v)
                        },
                        expression: "beteiligte_form.bic"
                      }
                    })
                  ],
                  1
                ),
                _vm.istKFZSparte
                  ? _c(
                      "div",
                      { staticClass: "mt-16px" },
                      [
                        _c("div", { staticClass: "box__title" }, [
                          _vm._v("Führerscheindaten des Fahrers")
                        ]),
                        _c("InputField", {
                          attrs: {
                            label: "Führerscheinnummer",
                            disabled: !_vm.beteiligte_form.rollen.fahrer
                          },
                          model: {
                            value: _vm.beteiligte_form.ausweisnr,
                            callback: function($$v) {
                              _vm.$set(_vm.beteiligte_form, "ausweisnr", $$v)
                            },
                            expression: "beteiligte_form.ausweisnr"
                          }
                        }),
                        _c("InputField", {
                          attrs: {
                            label: "Klassen",
                            disabled: !_vm.beteiligte_form.rollen.fahrer
                          },
                          model: {
                            value: _vm.beteiligte_form.zusatzInfos,
                            callback: function($$v) {
                              _vm.$set(_vm.beteiligte_form, "zusatzInfos", $$v)
                            },
                            expression: "beteiligte_form.zusatzInfos"
                          }
                        }),
                        _c("DatePickerField", {
                          attrs: {
                            label: "Ausstellungsdatum",
                            isComponentHalfSize: true,
                            disabled: !_vm.beteiligte_form.rollen.fahrer
                          },
                          model: {
                            value: _vm.beteiligte_form.ausstellungsdatum,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.beteiligte_form,
                                "ausstellungsdatum",
                                $$v
                              )
                            },
                            expression: "beteiligte_form.ausstellungsdatum"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" " + _vm._s(_vm.isFormInvalid) + " ")
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "BaseModal",
        {
          ref: "beteiligteDeleteModal",
          attrs: {
            modalTitle: "Eintrag löschen",
            labelButtonConfirm: "Löschen"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.deleteBeteiligte(_vm.beteiligteToDelete)
            },
            close: function($event) {
              return _vm.clearBeteiligteToDelete()
            }
          }
        },
        [
          _vm.beteiligteToDelete
            ? _c("div", [
                _vm._v(
                  ' Wollen Sie die Eintrag "' +
                    _vm._s(_vm.beteiligteToDelete.vorname) +
                    ", " +
                    _vm._s(_vm.beteiligteToDelete.nachname) +
                    '" wirklich löschen? '
                )
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }