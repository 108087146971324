import DECKBLATT_VERSICHERUNGEN_TYPES from './types';
import CORE_TYPES from '../core/types';
import axios from 'axios';
import { buildMessage, } from '../../helpers/log-message-helper';
import * as utils from '../../helpers/commonfunctions';

export default {
  [DECKBLATT_VERSICHERUNGEN_TYPES.ACTIONS.RETRIEVE_LAYOUT]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/deckblattversicherungen/getLayoutInfo';

      const vertragId = payload?.vertragId;
      if (vertragId) {
        serviceUrl = `${serviceUrl}?vertragId=${vertragId}`;
      }
  
      axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
        if (response && response.data) {
  
          commit(DECKBLATT_VERSICHERUNGEN_TYPES.MUTATIONS.RETRIEVE_LAYOUT_SUCCESS, response.data);
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });



  },

}