var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "registration-page" },
    [
      _c("HeaderLogin", {
        on: {
          backButtonPressed: function($event) {
            return _vm.goToLogin()
          }
        }
      }),
      _c(
        "div",
        {
          staticClass: "registration-page__container",
          attrs: { "data-app-content": "" }
        },
        [
          _c("div", { staticClass: "box__container" }, [
            _c("div", { staticClass: "box__title" }, [
              _vm._v("kostenfrei registrieren")
            ]),
            _c("p", [_vm._v("* Pflichtfelder")]),
            _c(
              "form",
              {
                attrs: { autocomplete: "off" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.checkDataAndGoToPrivacyPage()
                  }
                }
              },
              [
                _c("ComboBox", {
                  attrs: {
                    id: "gender",
                    label: "Anrede*",
                    values: _vm.genderValues
                  },
                  model: {
                    value: _vm.form.gender,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "gender", $$v)
                    },
                    expression: "form.gender"
                  }
                }),
                _c("InputField", {
                  attrs: { id: "firstName", label: "Vorname*" },
                  model: {
                    value: _vm.form.firstName,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "firstName", $$v)
                    },
                    expression: "form.firstName"
                  }
                }),
                _c("InputField", {
                  attrs: { id: "lastName", label: "Nachname*" },
                  model: {
                    value: _vm.form.lastName,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "lastName", $$v)
                    },
                    expression: "form.lastName"
                  }
                }),
                _c("InputField", {
                  attrs: { id: "email", label: "E-Mail*", autocomplete: "off" },
                  model: {
                    value: _vm.form.email,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "email", $$v)
                    },
                    expression: "form.email"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    id: "phone",
                    label: "Mobilnummer*",
                    autocomplete: "off",
                    inputmode: "tel"
                  },
                  on: {
                    change: function($event) {
                      return _vm.checkPhoneFormat($event)
                    }
                  },
                  model: {
                    value: _vm.form.phone,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "phone", $$v)
                    },
                    expression: "form.phone"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    id: "new-password",
                    label: "Passwort*",
                    type: "password",
                    allowPlainPassword: "",
                    allowPasswordRules: "",
                    autocomplete: "new-password"
                  },
                  model: {
                    value: _vm.form.password,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "password", $$v)
                    },
                    expression: "form.password"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    id: "new-password-again",
                    label: "Passwort Bestätigung*",
                    type: "password",
                    allowPlainPassword: "",
                    allowPasswordRules: "",
                    autocomplete: "new-password",
                    validateUntouched: ""
                  },
                  model: {
                    value: _vm.form.passwordConfirm,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "passwordConfirm", $$v)
                    },
                    expression: "form.passwordConfirm"
                  }
                }),
                _c("PasswordPolicy", {
                  staticClass: "change-password--policy is-collapsable",
                  attrs: { isCollapsable: "" }
                }),
                !_vm.hasValidMaklernr
                  ? _c("InputField", {
                      attrs: {
                        id: "registerCode",
                        label: "Registrierungscode*",
                        autocomplete: "one-time-code"
                      },
                      on: { input: _vm.checkCodeDebounce },
                      model: {
                        value: _vm.form.registerCode,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "registerCode", $$v)
                        },
                        expression: "form.registerCode"
                      }
                    })
                  : _vm._e(),
                _c("p", [
                  _vm._v(
                    " Wir senden Ihnen regelmäßig E-Mails mit Angeboten zu unseren Dienstleistungen. Sie können dieser Nutzung zu Werbezwecken jederzeit über den Link in den E-Mails kostenlos widersprechen. "
                  )
                ]),
                _vm.captchaData && _vm.captchaData.captchaImage
                  ? _c(
                      "div",
                      { staticClass: "mb-16px" },
                      [
                        _c("img", {
                          attrs: { src: _vm.captchaData.captchaImage }
                        }),
                        _c("InputField", {
                          attrs: {
                            placeholder: "Geben Sie bitte den Text vom Bild*"
                          },
                          model: {
                            value: _vm.form.captchaStr,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "captchaStr", $$v)
                            },
                            expression: "form.captchaStr"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.datenschutzText
                  ? _c(
                      "div",
                      { staticClass: "mb-4" },
                      [
                        _c("InputCheckBoxItem", {
                          attrs: { label: _vm.datenschutzText },
                          on: { click: _vm.openLink },
                          model: {
                            value: _vm.datenschutzConfirmed,
                            callback: function($$v) {
                              _vm.datenschutzConfirmed = $$v
                            },
                            expression: "datenschutzConfirmed"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "BaseButton",
                  {
                    staticClass: "mr-16px",
                    attrs: { isSecondary: "" },
                    on: {
                      click: function($event) {
                        return _vm.goToLogin()
                      }
                    }
                  },
                  [_vm._v(" Zurück zur Anmeldung ")]
                ),
                _c(
                  "BaseButton",
                  {
                    attrs: {
                      type: "submit",
                      disabled:
                        _vm.isFormInvalid ||
                        _vm.loading ||
                        !_vm.datenschutzText ||
                        !_vm.datenschutzConfirmed
                    }
                  },
                  [
                    _vm._v(" Registrieren "),
                    _vm.loading ? _c("AnimatedSpinner") : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }