export const MUTATION_PREFIX = 'ZUORDNUNG_GESELLSCHAFTEN_BIPRO_MUTATIONS';
export const ACTIONS_PREFIX = 'ZUORDNUNG_GESELLSCHAFTEN_BIPRO_ACTIONS_';
export const GETTERS_PREFIX = 'ZUORDNUNG_GESELLSCHAFTEN_BIPRO_GETTERS_';

export default {
    MUTATIONS: {
        RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
        GET_SELECTION_BIPRO_GESELLSCHAFTEN_SUCCESS: MUTATION_PREFIX + 'GET_SELECTION_BIPRO_GESELLSCHAFTEN_SUCCESS',
        GET_GESELLSCHAFT_KENNUNGEN_SUCCESS: MUTATION_PREFIX + 'GET_GESELLSCHAFT_KENNUNGEN_SUCCESS',
        SELECT_ROW: MUTATION_PREFIX + 'SELECT_ROW',
        SEARCH_SUCCESS: MUTATION_PREFIX + 'SEARCH_SUCCESS',
    },
    ACTIONS: {
        GET_SELECTION_BIPRO_GESELLSCHAFTEN: ACTIONS_PREFIX + 'GET_SELECTION_BIPRO_GESELLSCHAFTEN',
        GET_GESELLSCHAFT_KENNUNGEN: ACTIONS_PREFIX + 'GET_GESELLSCHAFT_KENNUNGEN',
        SAVE: ACTIONS_PREFIX + 'SAVE',
        SEARCH: ACTIONS_PREFIX + 'SEARCH',
        REMOVE_ROW: ACTIONS_PREFIX + 'REMOVE_ROW',
    },
    GETTERS: {
        GESELLSCHAFT_KENNUNGEN: GETTERS_PREFIX + 'GESELLSCHAFT_KENNUNGEN',
        SELECTION_BIPRO_GESELLSCHAFTEN: GETTERS_PREFIX + 'SELECTION_BIPRO_GESELLSCHAFTEN',
        SEARCH_RESULT: GETTERS_PREFIX + 'SEARCH_RESULT',
        SELECTED_ROW: GETTERS_PREFIX + 'SELECTED_ROW',
    }
}