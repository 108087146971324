import BERICHTE_TYPES from './types';
import LOG_TYPES from '@/store/log/types'
import axios from 'axios';
import { buildMessage } from "@/helpers/log-message-helper";
import { viewDocument } from '@/components/core/download/DownloadLink.vue';

const config = {
  defaultSpinner: true
};

const BASE_URL = `${process.env.VUE_APP_API}/berichteInvestment`

export default {
  [BERICHTE_TYPES.ACTIONS.GET_RISIKOPRUEFUNG_LIST]({ dispatch, commit }, payload) {
      axios.get(`${BASE_URL}/getRisikopruefungList?includeStruktur=${payload?.includeStruktur || ''}&includeZielmarkt=${payload?.includeZielmarkt || ''}`, config).then(response => {
        if (response.status === 200 && response.data) {
          commit(BERICHTE_TYPES.MUTATIONS.GET_RISIKOPRUEFUNG_LIST_SUCCESS, response.data)
        }
      })
      .catch(error => {
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error, 'danger'));
      })
    },

    [BERICHTE_TYPES.ACTIONS.SAVE_RISIKOPRUEFUNG_BEMERKUNG]({ dispatch, commit }, payload) {
      if (payload?.fehlerId) {
        axios.post(`${BASE_URL}/saveBemerkung?fehlerId=${payload.fehlerId}`, payload, config).then(response => {
          commit(BERICHTE_TYPES.MUTATIONS.SAVE_RISIKOPRUEFUNG_BEMERKUNG_SUCCESS, response.data);
        })
        .catch(error => {
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error, 'danger'));
        })
      }
    },

    [BERICHTE_TYPES.ACTIONS.OPEN_RISIKOPRUEFUNG_XLS_FILE]({ dispatch }, fehlerId) {
      if (fehlerId) {
        axios.get(`${BASE_URL}/createXLSFile?fehlerId=${fehlerId}`, config).then(response => {
          if (response.status === 200 && response.data?.xls) {
            viewDocument({
              data: response.data.xls,
              filename: response.data.fileName || 'Risikoprüfung.xls',
              contentType: 'application/xls',
            }, true);
          }
        })
        .catch(error => {
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error, 'danger'));
        })
      }
    },

    async [BERICHTE_TYPES.ACTIONS.GET_STATUS_FFB_DEPOTS]({ dispatch, commit, state }) {
      await axios.post(`${BASE_URL}/getStatusFFBDepotList`, state.filtersStatusFFBDepot, config).then(response => {
        if (response.status === 200 && response.data) {
          commit(BERICHTE_TYPES.MUTATIONS.GET_STATUS_FFB_DEPOTS_SUCCESS, response.data)
        }
      })
      .catch(error => {
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error, 'danger'));
      })
    },
    
    [BERICHTE_TYPES.ACTIONS.CONFIRM_SELECTED_FFB_STATUS_ROWS]({ dispatch }, payload) {
      axios.post(`${BASE_URL}/confirmSelectedFFBStatusRows`, payload, config).then(response => {
        if (response.status === 200) {
          dispatch(BERICHTE_TYPES.ACTIONS.GET_STATUS_FFB_DEPOTS);
        }
      })
      .catch(error => {
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error, 'danger'));
      })
    },
   
    [BERICHTE_TYPES.ACTIONS.CONFIRM_SELECTED_FFB_STATUS_ROWS]({ dispatch }, payload) {
      axios.post(`${BASE_URL}/confirmSelectedFFBStatusRows`, payload, config).then(response => {
        if (response.status === 200) {
          dispatch(BERICHTE_TYPES.ACTIONS.GET_STATUS_FFB_DEPOTS);
        }
      })
      .catch(error => {
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error, 'danger'));
      })
    },

    [BERICHTE_TYPES.ACTIONS.GET_STEUERLICHE_DATEN]({ dispatch, commit, state }) {
      axios.post(`${BASE_URL}/getSteuerlicheDaten`, state.filtersSteuerlicheDaten, config).then(response => {
        if (response.status === 200 && response.data) {
          commit(BERICHTE_TYPES.MUTATIONS.GET_STEUERLICHE_DATEN_SUCCESS, response.data)
        }
      })
      .catch(error => {
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error, 'danger'));
      })
    },

    [BERICHTE_TYPES.ACTIONS.GET_STEUER_VERLUST_LIST]({ dispatch, commit }, filters) {
      axios.post(`${BASE_URL}/getSteuerVerlustList`, filters, config).then(response => {
        if (response.status === 200 && response.data) {
          commit(BERICHTE_TYPES.MUTATIONS.GET_STEUER_VERLUST_LIST_SUCCESS, response.data)
        }
      })
      .catch(error => {
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error, 'danger'));
      })
    },
    
    [BERICHTE_TYPES.ACTIONS.GET_GESELLSCHAFTEN_AUSWAHL]({ dispatch, commit }) {
      axios.get(`${BASE_URL}/getGesellschaftenAuswahl`, config).then(response => {
        if (response.status === 200 && response.data) {
          commit(BERICHTE_TYPES.MUTATIONS.GET_GESELLSCHAFTEN_AUSWAHL_SUCCESS, response.data)
        }
      })
      .catch(error => {
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error, 'danger'));
      })
    },
    
    async [BERICHTE_TYPES.ACTIONS.LOAD_COURTAGE_INVEST_KUNDE]({ rootState, commit }, payload) {
      commit(BERICHTE_TYPES.MUTATIONS.SET_COURTAGE_INVEST_KUNDE, {anzeige: payload, rows: [], loaded: -1}  )
      const response = await axios.get(`${rootState.core.apiAddress}/SteckBrief/courtageInvestment?spalten=${payload}`, config);
      commit(BERICHTE_TYPES.MUTATIONS.SET_COURTAGE_INVEST_KUNDE, {anzeige: payload, rows: response.data, loaded: 1}  )
    },
    async [BERICHTE_TYPES.ACTIONS.LOAD_COURTAGE_VERS_KUNDE]({ rootState, commit }, payload) {
      commit(BERICHTE_TYPES.MUTATIONS.SET_COURTAGE_VERS_KUNDE, {anzeige: payload, rows: [], loaded: -1} )
      const response = await axios.get(`${rootState.core.apiAddress}/SteckBrief/courtageVers?spalten=${payload}`, config);
      commit(BERICHTE_TYPES.MUTATIONS.SET_COURTAGE_VERS_KUNDE, {anzeige: payload, rows: response.data, loaded: 1} )
    },

    [BERICHTE_TYPES.ACTIONS.INIT_ALTERSVORSORGE]({ dispatch, commit }) {
      return axios.get(`${process.env.VUE_APP_API}/altersvorsorgeService/init`, config).then(response => {
        if (response.status === 200 && response.data) {
          commit(BERICHTE_TYPES.MUTATIONS.INIT_ALTERSVORSORGE_SUCCESS, response.data)
        }
      })
      .catch(error => {
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error, 'danger'));
      })
    },

    [BERICHTE_TYPES.ACTIONS.GET_ALTERSVORSORGE]({ dispatch, commit }, payload) {
      return axios.get(`${process.env.VUE_APP_API}/altersvorsorgeService/retrieveAltersvorsorge`, {...config, params: {...payload}}).then(response => {
        if (response.status === 200 && response.data) {
          commit(BERICHTE_TYPES.MUTATIONS.GET_ALTERSVORSORGE_SUCCESS, response.data)
        }
      })
      .catch(error => {
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error, 'danger'));
      })
    },
}