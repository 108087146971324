<template>
<div class="login-container">
    <HeaderLogin @backButtonPressed="goToLogin()"/>
    <div data-app-content class="login-content" :style="{ backgroundImage: loginContentBackground }">
        <div class="login-box">
            <div class="login-logo-box">
                <img :src="logoLoginBoxBackground"/>
            </div>
            <hr class="login-logo__bottom--spacer"/>
            <div class="login-logo__box-title">
                Neues Passwort setzen
            </div>

            <div class="login__form">
                <InputField id="username" label="Loginnummer" v-model="form.loginName" autocomplete="username" trimValue />
                <BaseButton @click="requestCodes" :disabled="form.loginName.length == 0 || tokenButtonsDisabled" isSecondary>Sicherheitscodes {{codesRequested ? 'erneut ' : ''}}anfordern</BaseButton>
                <div v-if="codesRequested" class="mt-3">
                    <InputField id="emailCode" label="Sicherheitscode aus E-Mail" inputmode="numeric" 
                        v-model="form.emailCode" autocomplete="one-time-code" trimValue />
                    <InputField id="smsCode" label="Sicherheitscode (aus SMS oder Aktivierungsbrief)" inputmode="numeric" 
                        v-model="form.smsCode" autocomplete="one-time-code" trimValue />

                    <InputField id="new-password" label="Neues Passwort" 
                        v-model="form.newPassword" type="password" allowPlainPassword allowPasswordRules autocomplete="new-password" />
                    <InputField id="new-password-again" label="Neues Passwort bestätigen" v-model="form.newPassword2" type="password" 
                        allowPlainPassword allowPasswordRules validateUntouched autocomplete="new-password" />
                    <PasswordPolicy isCollapsable />

                    <BaseButton @click="submit" :disabled="!validation.valid || !arePasswordsFilled || submitPasswordChangeDisabled">Passwort ändern</BaseButton>
                </div>
            </div>

            <div v-if="!isBackToLoginHidden">
                <BaseButton isLink @click="goToLogin()" class="mr-16px mt-3">Zurück zur Anmeldung</BaseButton>
            </div>
        </div>
    </div>
  
</div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import BaseButton from '@/components/core/BaseButton.vue';
import InputField from '@/components/core/forms/InputField.vue';
import validator from '@/mixins/validator';
import { required, createRule } from '@/mixins/validator/rules';
import { mapGetters } from 'vuex';
import HeaderLogin from '@/components/core/login/HeaderLogin.vue'
import LOG_TYPES from '@/store/log/types';
import { buildMessage } from '@/helpers/log-message-helper';
import PasswordPolicy, { NEW_PASSWORD_COMMON_RULES, confirmPasswordRule } from './change-password/PasswordPolicy.vue';

export default {
    mixins: [validator],
    components: {
        BaseButton,
        InputField,
        HeaderLogin,
        PasswordPolicy,
    },
    data: function() {
        return {
            form: {
                loginName: '',
                emailCode: '',
                smsCode: '',
                newPassword: '',
                newPassword2: '',
            },
            codesRequested: false,
            tokenButtonsDisabled: false,
            submitPasswordChangeDisabled: false,
        }
    },

    validators: {
        form: {
            loginName: [required('Bitte geben Sie Ihre Kundennummer an')],
            emailCode: [required('Bitte geben Sie Ihren E-Mail Sicherheitscode an')],
            smsCode: [required('Bitte geben Sie Ihren SMS Sicherheitscode an')],
            newPassword: [ ...NEW_PASSWORD_COMMON_RULES ],
            newPassword2: [
                confirmPasswordRule((self) => self.form.newPassword),
            ],
        }
    },
    computed: {
        ...mapGetters({
            apiAddressLegacy: CORE_TYPES.GETTERS.API_ADDRESS_LEGACY,
            imageBackgroundLoginUrl: CORE_TYPES.GETTERS.GET_BROKER_IMAGE_BACKGROUND_LOGIN_URL,
            maklerLogo: CORE_TYPES.GETTERS.GET_BROKER_LOGO,
            hasColorSchema: CORE_TYPES.GETTERS.HAS_COLOR_SCHEMA,
        }),
        loginContentBackground() {
            return this.imageBackgroundLoginUrl ? `url(${this.imageBackgroundLoginUrl})` : `url(${this.apiAddressLegacy}/../images/etc/layout/msc_wolken4.png)`
        },
        logoLoginBoxBackground() {
            return this.maklerLogo
        },
        arePasswordsFilled() {
            const { form } = this;
            return !!form.newPassword?.trim() && !!form.newPassword2?.trim();
        },
        userid() {
            return this.$route.query?.userid || this.$route.query?.Userid || this.$route.query?.userId;
        },
        isBackToLoginHidden() {
            return this.$route.query?.resetonly == "true";
        }
    },
    watch: {
        'form.newPassword'() {
            const currentValidator = this.validation.getValidator('form.newPassword2');
            if (currentValidator?.watchCallback) {
                currentValidator.watchCallback(this.form.newPassword2, false);
            }
        },
    },
    methods: {
        requestCodes() {
            this.codesRequested = true;
            this.tokenButtonsDisabled = true;
            this.$store.dispatch(CORE_TYPES.ACTIONS.REQUEST_PASSWORD_RESET_CODES, {
                loginName: this.form.loginName?.trim(),
            }).then(response => {
                if (response.data.message) {
                    this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.message, response.data.type));
                }
                this.handlePasswordResetPrompt(response.data);
            });
            setTimeout(() => {
                this.tokenButtonsDisabled = false;
            }, 2000);
        },
        handlePasswordResetPrompt(data) {
            if (data.prompt) {
                this.$confirmModal({
                    message: data.prompt,
                    labelButtonConfirm: data.confirmLabel,
                    labelButtonCancel: data.cancelLabel,
                }).then(() => {
                    this.$store.dispatch(CORE_TYPES.ACTIONS.REQUEST_PASSWORD_RESET_CODES, {
                        loginName: this.form.loginName?.trim(),
                        temporaryEmailPermissionGranted: true,
                    }).then(response => {
                        if (response.data.message) {
                            this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.message, response.data.type));
                        }
                    });
                }).catch(() => {
                    this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(
                        'Passwortänderung nicht möglich. Sie haben dem Versand der E-Mail zwecks Passwortänderung widersprochen.', 'danger'));
                });
            }
        },
        submit() {
            this.submitPasswordChangeDisabled = true;
            this.$store.dispatch(CORE_TYPES.ACTIONS.PASSWORT_WIEDERHERSTELLEN_WITH_CODE, {
                loginName: this.form.loginName?.trim(),
                emailCode: this.form.emailCode?.trim(),
                smsCode: this.form.smsCode?.trim(),
                newPassword: this.form.newPassword,
            })
            .then(() => {
                this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage("Das Passwort wurde erfolgreich geändert.", 'success'));
                this.goToLogin();
            }).finally(() => {
                this.submitPasswordChangeDisabled = false;
            });
        },
        goToLogin() {
            this.$router.push({
                path: '/login',
                query: {userid: this.form.loginName},
            });
        },
    },
    mounted() {
        if (this.userid) {
            this.form.loginName = this.userid
        }
    }
}
</script>

<style scoped>

  .login-container {
    display: flex;
    align-items: center;
    height: 100vh;
    height: 100svh;

    flex-direction: column;
    justify-content: flex-start;
  }

  .login__form {
    display: flex;
    flex-direction: column;
    padding-top: 16px;
  }

  .login-box {
    width: 480px;
    background-color: var(--color-box);
    border-radius: 8px;
    padding: 32px;
  }

  .login-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center center;
  }

  .login-logo-box, .login-logo-box img {
    max-height: 80px;
  }

  .login-logo__bottom--spacer {
    padding: 8px;
    border-top: solid 1px #C4C4C4;
  }

  .login-logo__box-title {
    font-size: 1.75rem;
    font-weight: 500;
    color: var(--color-primary)
  }

  @media (max-width: 576px) { 
    .login-content {
      background-image: none !important;
    }
  }
</style>