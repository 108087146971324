var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Interne Statistiken",
          subtitle: _vm.selectedGroupTitle,
          defaultMenu: _vm.customOptionMenu
        }
      }),
      _vm.selectedGroup === "table_makler"
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c(
                "BaseButton",
                {
                  on: {
                    click: function($event) {
                      return _vm.filterBroker()
                    }
                  }
                },
                [_vm._v(" Makler filtern ")]
              ),
              _c(
                "BaseButton",
                {
                  attrs: { isSecondary: "", disabled: !_vm.filterBrokerNumber },
                  on: {
                    click: function($event) {
                      return _vm.clearBrokerFilterNumber()
                    }
                  }
                },
                [_vm._v(" Maklerfilter löschen ")]
              )
            ],
            1
          )
        : _vm._e(),
      _vm.selectedGroup === "table_gesellschaft"
        ? _c("BaseFilter", {
            attrs: {
              metadata: _vm.gesellschaftFilterMetadata,
              title: "Gesellschaft",
              showSaveButton: false,
              showSearchButton: false
            },
            on: {
              changeSubFilter: _vm.handleGesellschaftFilterChange,
              onFilterZurucksetzen: _vm.resetGesellschaftFilter
            }
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.unfilteredList.length > 0
            ? _c("Table", {
                attrs: {
                  title: _vm.STATISTICS_TABLE_TITLE,
                  headers: _vm.headers,
                  rows: _vm.statisticRowsForCurrentGroup,
                  rowId: "label"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "label",
                      fn: function(row) {
                        return [
                          row.dateiName && !row.subitem
                            ? _c("DownloadLink", {
                                attrs: {
                                  downloadServicePath:
                                    "/internestatistikendetail",
                                  title: row.label,
                                  filename: row.dateiName,
                                  queryParams: {}
                                }
                              })
                            : _c(
                                "a",
                                {
                                  attrs: {
                                    tid: _vm._generateTidFromString(
                                      "/internestatistikendetail" + row.label
                                    )
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.openSelectedStatistic(row)
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s(row.label) + " ")]
                              )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3396139169
                )
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }