<template>
  <span class="signo-link__container">
    <a v-if="isMobileNativeContext" @click="callBridgeSignoViewDocument" :class="linkClass"> 
      {{title}}
    </a>
    <a v-else-if="isDeepLink"
      @click="openDeepLinkSignoviewer"
      :class="linkClass">
        {{title}}
    </a>
    <a v-else target="_blank" rel="noopener noreferer"
      style="text-overflow: ellipsis" 
      :title="title" 
      :class="linkClass"
      :href="href">
        {{title}}
    </a>
  </span>
</template>

<script>
import BRIDGE_TYPES from '@/store/bridge/types'
import CORE_TYPES from '@/store/core/types';
import { mapGetters } from 'vuex'
import store from '@/store';
import { addParamToURL } from '@/helpers/utils-helper';
import { setSessionObject } from '@/helpers/local-storage-helper'
import { makeQueryParam, parseParamsIntoObject } from '@/helpers/utils-helper';

/**
 * 
 * @param {*} href 
 * @param {*} filename 
 * @param {*} requestURL 
 * @param {*} useSameTab - Use the same tab when the requested action already went from a new tab action
 */
export function openSignoViewDocument(href, filename, requestURL = false, useSameTab = false) {
  
  if(store.getters[BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT]) {


    // if filename is not filled, try to extract it from url
    if(!filename) {
      const params = parseParamsIntoObject(href);
      // console.log("params", filename);
      if(params.fileName) {
        filename = params.fileName;
     } else {
      filename = 'elektronisch Unterscheiben und abschliessen'; // don't let it be null
     }
    }

    store.dispatch(BRIDGE_TYPES.ACTIONS.VIEW_PDF_IN_SIGNO, {
      href,
      filename,
      requestURL,
    })
  } else {
    if (useSameTab) {
      window.location.href = href
    } else {
      window.open(href, '_blank');
    }
  }
}

export default {
  props: {
    title: {
      type: String,
      default:''
    },
    href: {
      type: String,
      default: ''
    },
    requestURL: {
      type: Boolean,
      default: false,
    },
    extraParams: {
      type: String,
      default: '',
    },
    asButton: {
      type: Boolean,
      default: false,
    },
    isSecondary: {
      type: Boolean,
      default: false,
    },
    isDeepLink: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    ...mapGetters({
      isMobileNativeContext: BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT,
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
    }),
    linkClass() {
      return {
        'button': this.asButton,
        'button--primary': this.asButton && !this.isSecondary,
        'button--secondary': this.asButton && this.isSecondary,
        'button--mobile-native-size': this.asButton && this.isMobileNativeContext,
        'disabled': this.disabled
      }
    },
  },
  methods: {
    callBridgeSignoViewDocument() {
      openSignoViewDocument(addParamToURL(this.href, this.extraParams), this.title, this.requestURL);
    },
    openDeepLinkSignoviewer() {
      /*
        TODO: deepLink für isMobileNativeContext
      */
      const params = makeQueryParam({ ...this.$route.query, isDeepLink: true })
      const relativePath = this.$route.path + '?' + params
      const callbackPath = window.location.href.substring(0, window.location.href.indexOf(window.location.pathname)) + relativePath

      let newHref = new URL(this.href);
      newHref.searchParams.append('callbackUrl', encodeURIComponent(callbackPath))

      setSessionObject('deepLink-loginData', this.loginData);

      setTimeout(() => {
        window.location.href = newHref.href;
      }, 1000);
    },
  }
}
</script>

<style scoped>
.signo-link__container {
  display: inline-flex;
  align-items: center;
}

.button {
  text-decoration: none;
  padding: 8px 12px;
  border-radius: 4px;
  line-height: 14px;
  display: inline-block;
}

.button--primary {
  background-color: var(--color-primary);
  color: var(--color-primary-text);
  border: 1px solid;
  border-color: var(--color-primary);
}
.button--secondary {
  border: 1px solid;
  border-color: var(--color-primary);
  color: var(--color-primary);
  background-color: var(--color-box);
}

.button--mobile-native-size {
  min-height: 40px;
  min-width: 40px;
  line-height: 22px;
}

.disabled {
  pointer-events: none;
  cursor: default;
  opacity: .4;
}
</style>
