var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("div", { staticClass: "box__title" }, [
          _vm._v("Gebühren Finanzservicevertrag")
        ]),
        _c("InputField", {
          attrs: {
            label: "Basismodul - SERVICEMODUL 1",
            type: "currency",
            isComponentHalfSize: ""
          },
          on: {
            change: function($event) {
              return _vm.dataChanged()
            }
          },
          model: {
            value: _vm.feePropertiesForm.satzBasismodul1,
            callback: function($$v) {
              _vm.$set(_vm.feePropertiesForm, "satzBasismodul1", $$v)
            },
            expression: "feePropertiesForm.satzBasismodul1"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("div", { staticClass: "box__title" }, [_vm._v("SERVICEMODUL 3")]),
        _c("InputToggleSwitch", {
          attrs: { label: "SERVICEMODUL 3", inLineLabel: "" },
          on: {
            input: function($event) {
              return _vm.dataChanged()
            }
          },
          model: {
            value: _vm.feePropertiesForm.hatModul3,
            callback: function($$v) {
              _vm.$set(_vm.feePropertiesForm, "hatModul3", $$v)
            },
            expression: "feePropertiesForm.hatModul3"
          }
        }),
        _c(
          "div",
          [
            _c("div", { staticClass: "mb-3" }, [
              _vm._v("Beratung und Betreuung auf Provisionsbasis")
            ]),
            _c("InputField", {
              attrs: {
                label: "EUR 1,- bis EUR 49.999,99",
                type: "currency",
                isComponentHalfSize: ""
              },
              on: {
                change: function($event) {
                  return _vm.dataChanged()
                }
              },
              model: {
                value: _vm.feePropertiesForm.betragModul31,
                callback: function($$v) {
                  _vm.$set(_vm.feePropertiesForm, "betragModul31", $$v)
                },
                expression: "feePropertiesForm.betragModul31"
              }
            }),
            _c("InputField", {
              attrs: {
                label: "EUR 50.000,- bis EUR 99.999,99",
                type: "currency",
                isComponentHalfSize: ""
              },
              on: {
                change: function($event) {
                  return _vm.dataChanged()
                }
              },
              model: {
                value: _vm.feePropertiesForm.betragModul32,
                callback: function($$v) {
                  _vm.$set(_vm.feePropertiesForm, "betragModul32", $$v)
                },
                expression: "feePropertiesForm.betragModul32"
              }
            }),
            _c("InputField", {
              attrs: {
                label: "EUR 100.000,- bis EUR 199.999,99",
                type: "currency",
                isComponentHalfSize: ""
              },
              on: {
                change: function($event) {
                  return _vm.dataChanged()
                }
              },
              model: {
                value: _vm.feePropertiesForm.betragModul33,
                callback: function($$v) {
                  _vm.$set(_vm.feePropertiesForm, "betragModul33", $$v)
                },
                expression: "feePropertiesForm.betragModul33"
              }
            }),
            _c("div", [_vm._v("ab EUR 200.000,-")])
          ],
          1
        ),
        _c("div", [
          _c("div", { staticClass: "box__title" }, [
            _vm._v("zu berücksichtigende Depots")
          ]),
          _c(
            "div",
            { staticClass: "forms__input--half-size" },
            [
              _vm._m(0),
              _vm._l(_vm.MAX_DEPOTS, function(lfdnr) {
                return _c("div", { key: lfdnr, staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 col-sm-7" },
                    [
                      _c("ComboBox", {
                        attrs: {
                          values: _vm.feePropertiesCombos.gesellschaftValues,
                          firstEmpty: ""
                        },
                        on: {
                          change: function($event) {
                            return _vm.dataChangedCombo(
                              "depotGesellschaft" + lfdnr,
                              $event
                            )
                          }
                        },
                        model: {
                          value:
                            _vm.feePropertiesForm["depotGesellschaft" + lfdnr],
                          callback: function($$v) {
                            _vm.$set(
                              _vm.feePropertiesForm,
                              "depotGesellschaft" + lfdnr,
                              $$v
                            )
                          },
                          expression:
                            "feePropertiesForm['depotGesellschaft' + lfdnr]"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12 col-sm-5" },
                    [
                      _c("InputField", {
                        attrs: { type: "text" },
                        on: {
                          change: function($event) {
                            return _vm.dataChanged()
                          }
                        },
                        model: {
                          value: _vm.feePropertiesForm["depotnummer" + lfdnr],
                          callback: function($$v) {
                            _vm.$set(
                              _vm.feePropertiesForm,
                              "depotnummer" + lfdnr,
                              $$v
                            )
                          },
                          expression: "feePropertiesForm['depotnummer' + lfdnr]"
                        }
                      })
                    ],
                    1
                  )
                ])
              })
            ],
            2
          )
        ])
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("div", { staticClass: "box__title" }, [_vm._v("SERVICEMODUL 4")]),
        _c("InputToggleSwitch", {
          attrs: { label: "SERVICEMODUL 4", inLineLabel: "" },
          on: {
            input: function($event) {
              return _vm.dataChanged()
            }
          },
          model: {
            value: _vm.feePropertiesForm.hatModul4,
            callback: function($$v) {
              _vm.$set(_vm.feePropertiesForm, "hatModul4", $$v)
            },
            expression: "feePropertiesForm.hatModul4"
          }
        }),
        _c(
          "div",
          [
            _c("div", { staticClass: "mb-3" }, [
              _vm._v("Beratung und Betreuung Servicegebühr (ab 50.000,-)")
            ]),
            _c("InputField", {
              attrs: {
                label: "ab EUR 50.000,-",
                type: "currency",
                isComponentHalfSize: ""
              },
              on: {
                change: function($event) {
                  return _vm.dataChanged()
                }
              },
              model: {
                value: _vm.feePropertiesForm.satzModul41,
                callback: function($$v) {
                  _vm.$set(_vm.feePropertiesForm, "satzModul41", $$v)
                },
                expression: "feePropertiesForm.satzModul41"
              }
            }),
            _c("InputField", {
              attrs: {
                label: "mind.",
                type: "currency",
                isComponentHalfSize: ""
              },
              on: {
                change: function($event) {
                  return _vm.dataChanged()
                }
              },
              model: {
                value: _vm.feePropertiesForm.minBetragModul41,
                callback: function($$v) {
                  _vm.$set(_vm.feePropertiesForm, "minBetragModul41", $$v)
                },
                expression: "feePropertiesForm.minBetragModul41"
              }
            }),
            _c("InputField", {
              attrs: {
                label: "ab EUR 100.000,-",
                type: "currency",
                isComponentHalfSize: ""
              },
              on: {
                change: function($event) {
                  return _vm.dataChanged()
                }
              },
              model: {
                value: _vm.feePropertiesForm.satzModul42,
                callback: function($$v) {
                  _vm.$set(_vm.feePropertiesForm, "satzModul42", $$v)
                },
                expression: "feePropertiesForm.satzModul42"
              }
            }),
            _c("InputField", {
              attrs: {
                label: "ab EUR 250.000,-",
                type: "currency",
                isComponentHalfSize: ""
              },
              on: {
                change: function($event) {
                  return _vm.dataChanged()
                }
              },
              model: {
                value: _vm.feePropertiesForm.satzModul43,
                callback: function($$v) {
                  _vm.$set(_vm.feePropertiesForm, "satzModul43", $$v)
                },
                expression: "feePropertiesForm.satzModul43"
              }
            }),
            _c("InputField", {
              attrs: {
                label: "ab EUR 500.000,-",
                type: "currency",
                isComponentHalfSize: ""
              },
              on: {
                change: function($event) {
                  return _vm.dataChanged()
                }
              },
              model: {
                value: _vm.feePropertiesForm.satzModul44,
                callback: function($$v) {
                  _vm.$set(_vm.feePropertiesForm, "satzModul44", $$v)
                },
                expression: "feePropertiesForm.satzModul44"
              }
            }),
            _c("InputField", {
              attrs: {
                label: "ab EUR 1 Mio",
                type: "currency",
                isComponentHalfSize: ""
              },
              on: {
                change: function($event) {
                  return _vm.dataChanged()
                }
              },
              model: {
                value: _vm.feePropertiesForm.satzModul45,
                callback: function($$v) {
                  _vm.$set(_vm.feePropertiesForm, "satzModul45", $$v)
                },
                expression: "feePropertiesForm.satzModul45"
              }
            })
          ],
          1
        ),
        _c("div", [
          _c("div", { staticClass: "box__title" }, [
            _vm._v("zu berücksichtigende Depots")
          ]),
          _c(
            "div",
            { staticClass: "forms__input--half-size" },
            [
              _vm._m(1),
              _vm._l(_vm.MAX_DEPOTS, function(lfdnr) {
                return _c("div", { key: lfdnr, staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 col-sm-7" },
                    [
                      _c("ComboBox", {
                        attrs: {
                          values: _vm.feePropertiesCombos.gesellschaftValues,
                          firstEmpty: ""
                        },
                        on: {
                          change: function($event) {
                            return _vm.dataChangedCombo(
                              "depotGesellschaft4" + lfdnr,
                              $event
                            )
                          }
                        },
                        model: {
                          value:
                            _vm.feePropertiesForm["depotGesellschaft4" + lfdnr],
                          callback: function($$v) {
                            _vm.$set(
                              _vm.feePropertiesForm,
                              "depotGesellschaft4" + lfdnr,
                              $$v
                            )
                          },
                          expression:
                            "feePropertiesForm['depotGesellschaft4' + lfdnr]"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12 col-sm-5" },
                    [
                      _c("InputField", {
                        attrs: { type: "text" },
                        on: {
                          change: function($event) {
                            return _vm.dataChanged()
                          }
                        },
                        model: {
                          value: _vm.feePropertiesForm["depotnummer4" + lfdnr],
                          callback: function($$v) {
                            _vm.$set(
                              _vm.feePropertiesForm,
                              "depotnummer4" + lfdnr,
                              $$v
                            )
                          },
                          expression:
                            "feePropertiesForm['depotnummer4' + lfdnr]"
                        }
                      })
                    ],
                    1
                  )
                ])
              })
            ],
            2
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 col-sm-7" }, [_vm._v("Gesellschaft")]),
      _c("div", { staticClass: "col-12 col-sm-5" }, [_vm._v("Nummer")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 col-sm-7" }, [_vm._v("Gesellschaft")]),
      _c("div", { staticClass: "col-12 col-sm-5" }, [_vm._v("Nummer")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }