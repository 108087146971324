<template>
  <component :is="element" v-if="disabled">
    <slot></slot>
  </component>
  <component :is="element" class="dropzone" v-else @drag="ondrag" @dragover="ondragover" @dragenter="ondragover" @dragleave="ondragleave" @drop="ondrop">
    <slot></slot>
    <div class="dropzone_overlay" v-if="hoverText && draggedOver">
      {{hoverText}}
    </div>
  </component>
</template>
<script>
const HTML_ELEMENT_DEFAULT = 'div';

import { buildMessage } from '@/helpers/log-message-helper';
import LOG_TYPES from '@/store/log/types';

export default {
  props: {
    hoverText: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    element: {
      type: String,
      default: HTML_ELEMENT_DEFAULT,
    },
    maxFileSizeMB: {
      type: Number,
      default: 0
    },
  },
  data: () => {
    return {
      draggedOver: false,
      draggedLeaveTimeoutId: null,
    }
  },
  methods: {
    ondrag(e) {
      e.preventDefault()
      e.stopPropagation()
    },
    ondragover(e) {
      e.preventDefault()
      e.stopPropagation()
      clearTimeout(this.draggedLeaveTimeoutId)
      this.draggedOver = true
    },
    ondragleave(e) {
      e.preventDefault()
      e.stopPropagation()
      this.draggedLeaveTimeoutId = setTimeout(() => this.draggedOver = false, 100)
      this.$emit("dragleave", e);
    },
    ondrop(e) {
      e.preventDefault()
      e.stopPropagation()
      this.draggedOver = false
      const files = []
      const forbiddenFilesBySize = [];
      // if there is no maxFileSizeMB or informed zero, any size is allowed
      const maxSize = this.maxFileSizeMB * 1024 * 1024; //in MB
      for (const file of e.dataTransfer.files) {
        if(maxSize && file.size > maxSize) {
          forbiddenFilesBySize.push(file);
        } else {
          files.push(file);
        }
      }
      if(forbiddenFilesBySize.length) {
        this.$store.dispatch(
          LOG_TYPES.ACTIONS.ADD_MESSAGE,
          buildMessage(`Diese Dateien überschreiten die Größenbegrenzung von ${this.maxFileSizeMB} MB. Bitte laden Sie erneut eine kleinere Datei hoch.`, 'warning')
        );
        return;
      }
      this.$emit("files", files)
    },
  },
}
</script>
<style scoped>
.dropzone {
  position: relative;
  transform: scale(1); /** hack to work position relative in table tr element */
}
.dropzone_overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  font-size: 1,5em;
  color: var(--color-primary);
  /* opacity: 60%; */
  border: 4px dashed var(--color-primary);
  pointer-events: none;
}
</style>