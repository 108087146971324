var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("EventsFkTable", {
    attrs: {
      events: _vm.rows,
      showTitle: false,
      rowsPerPage: 5,
      isOverview: true
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }