<template>
  <div>
    <DepotpositionsNavigation
      :title="pageMetadata.title"
      :headerActions="headerActions" 
      @action-ADD="addNewOtherProduct()" 
    />

    <OtherProductsSearch ref="otherProductsSearch" :type="WEITERE_VERMOEGEN_TYPE" hideNavigation />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';
import ROLES from '@/router/roles';
import DepotpositionsNavigation from '@/components/depotpositions/DepotpositionsNavigation.vue';
import OtherProductsSearch from '@/components/otherProducts/OtherProductsSearch.vue';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

import { MAP_TYPE_TO_TITLE, MAP_TYPE_TO_TITLE_SINGULAR, WEITERE_VERMOEGEN_TYPE, } from './otherProductsUtils';

/**
 * Wrapper component - it was implemented to handle the specific Weiteres Vermögen navigation
 */
export default {
  data() {
    return {
      WEITERE_VERMOEGEN_TYPE,
    };
  },
  computed: {
    ...mapGetters({
        isIntern: CORE_TYPES.GETTERS.IS_INTERN,
        isBroker: CORE_TYPES.GETTERS.IS_BROKER,
        hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
        isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
    }),
    isSuperCustomer() {
      return this.hasRoles(ROLES.SUPER_CUSTOMER);
    },
    pageMetadata() {
      const title = MAP_TYPE_TO_TITLE[WEITERE_VERMOEGEN_TYPE];
      const titleSingular = MAP_TYPE_TO_TITLE_SINGULAR[WEITERE_VERMOEGEN_TYPE];
      return {
        title,
        newTitle: `${titleSingular} hinzufügen`,
      };
    },
    headerActions() {
      const { isCustomer, pageMetadata, isSuperCustomer} = this;
      if (isCustomer  && !isSuperCustomer) {
        return [
          PageHeaderSimpleAction('ADD', pageMetadata.newTitle)
        ];
      }

      return [];
    },
  },
  methods: {
    addNewOtherProduct() {
      this.$refs.otherProductsSearch?.addNewOtherProduct();
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({ to, from, });
    next();
  },
  components: {
    DepotpositionsNavigation,
    OtherProductsSearch,
  },
}
</script>
