
<template>
  <div v-if="fragments.length" class="mb-3">
    <template v-for="(fragment,k) of fragments" >
      <div :key="k*100" :class="fragment[0].begin">
        <template v-for="(field,idx) of fragment" >
          <InputField v-if="react && field.dataType==='text'" :key="k*100+idx" :disabled="field.disabled"
            :validation_path="field.key" :class="field.class"
            @onBlur="onBlur(field)" :isNotReactive="isNotReactive"
            v-model="values[field.key]" :label="field.label"
            isComponentHalfSize validateUntouched
            @change="$emit('change', { key: field.key, value: $event})"
          />
          <InputField v-else-if="react && field.dataType==='number'" :key="k*100+idx" :disabled="field.disabled"
            :validation_path="field.key" :class="field.class" :ref="field.key" :isNotReactive="isNotReactive"
            :label="field.label" :value="values[field.key]" 
            @change="(value) => setValue(field, value)" @focus="onNumberFocus(field)" @onBlur="onNumberBlur(field)"
            isComponentHalfSize validateUntouched
          />
          <InputField v-else-if="react && field.dataType==='euro'" :key="k*100+idx" :disabled="field.disabled"
            :validation_path="field.key" :class="field.class" :ref="field.key" :isNotReactive="isNotReactive"
            :label="field.label" type="currency" :value="values[field.key]" 
            @change="(value) => setValue(field, value)"  @focus="onNumberFocus(field)" @onBlur="onNumberBlur(field)"
            isComponentHalfSize validateUntouched
          />
          <InputField v-else-if="react && field.dataType==='fremdw'" :key="k*100+idx" :disabled="field.disabled"
            :validation_path="field.key" :class="field.class" :ref="field.key" :isNotReactive="isNotReactive"
            :label="field.label" type="foreign_cy" :foreign_currency="values[field.fremdw]" :value="values[field.key]" 
            @change="(value) => setValue(field, value)"  @focus="onNumberFocus(field)" @onBlur="onNumberBlur(field)"
            isComponentHalfSize validateUntouched
          />
          <InputField v-else-if="react && field.dataType==='proz'" :key="k*100+idx" :disabled="field.disabled"
            :validation_path="field.key" :class="field.class" :ref="field.key" :isNotReactive="isNotReactive"
            :label="field.label" type="percent" :value="values[field.key]" 
            @change="(value) => setValue(field, value)"  @focus="onNumberFocus(field)" @onBlur="onNumberBlur(field)"
            isComponentHalfSize validateUntouched
          />
          <FormLabel v-else-if="react && field.dataType==='label'" :key="k*100+idx" :class="field.class"
            :label="field.label" :config="labelConfig(field)"
            class="mb-2"
          />
          <InputCheckBoxItem v-else-if="react && field.dataType==='check'" :key="k*100+idx" :disabled="field.disabled" 
            :validation_path="field.key" :class="field.class"
            v-model="values[field.key]"  :label="field.label" validateUntouched
            @input="$emit('change', { key: field.key, value: $event})"
          />
          <DatePickerField  v-else-if="react && field.dataType==='date'" :key="k*100+idx" :disabled="field.disabled"
            :validation_path="field.key" :class="field.class" :label="field.label" :isNotReactive="isNotReactive"
            :value="values[field.key]" @input="(datum) => setDatum(field.key, datum)"
            @blur="onBlur(field)"
            isComponentHalfSize validateUntouched
            @change="$emit('change', { key: field.key, value: $event})"
          />
          <InputTextArea v-else-if="react && field.dataType==='area'" :key="k*100+idx" :disabled="field.disabled"
            v-model="values[field.key]"  :label="field.label"  :isNotReactive="isNotReactive"
            isComponentHalfSize validateUntouched
            @change="$emit('change', { key: field.key, value: $event})"
          />
          <ComboBox v-else-if="react && field.dataType==='combo'" :key="k*100+idx" :disabled="field.disabled"
            :validation_path="field.key" :class="field.class"
            @blur="onBlur(field)"
            @change="$emit('change', { key: field.key, value: $event})"
            v-model="values[field.key]" :label="field.label" :values="getValues(field)"
            isComponentHalfSize validateUntouched
          />
          <InputRadioBoxGroup  v-else-if="react && field.dataType==='radio'" :key="k*100+idx" :disabled="field.disabled"
            :validation_path="field.key" :class="field.class"
            v-model="values[field.key]" :title="field.label" :values="getValues(field)" validateUntouched
            @input="$emit('change', { key: field.key, value: $event})"
          />
          <BaseCollapsable v-else-if="react && field.dataType==='info'" :key="k*100+idx" class="mb-3" :class="field.class">
            <template v-slot:title>
              <span class="box__title">
                {{field.key}}
              </span>
            </template>
            <template v-slot:content>
              <div  v-html="sanitize(field.label)"></div>
            </template>
          </BaseCollapsable>
          <BaseButton v-else-if="react && field.dataType==='button'" :key="k*100+idx" class="mb-3" :class="field.class"
            isSecondary @click="$emit('onBtClick', field)">{{field.label}}</BaseButton>
          <BaseButton v-else-if="react && field.dataType==='buttonP'" :key="k*100+idx" class="mb-3" :class="field.class"
              @click="$emit('onBtClick', field)">{{field.label}}</BaseButton>
          
          <div v-else-if="react && field.dataType === 'slot'" :key="k*100+idx" :class="field.class">
            <slot :name="field.key" :data="{field}"></slot>
          </div>
          <div v-else-if="react && field.dataType==='table'" :key="k*100+idx" :class="field.class">
            <div v-if="rowsCount===0">Keine Daten</div>
            <div class="" v-if="rowsCount">
              <Table class="table-container" 
                  ref="tableResult"
                  :title="field.label"
                  :configModalTitle="'Depotposition_'+field.data2"
                  :tableId="field.data4"
                  :additionalCollapsed="true"
                  :tableData="tableData"
                  :cardViewEnabled="false"
                  :filterEnabled="false"
                  :exportEnabled="false"
                  :rowsPerPage="15"
                  :count="rowsCount"
                  >
                    <template v-slot:editierbar="{data}">
                      <a @click="$emit('gotoEdit', field, data.row)">
                        <span class="">{{data.row.editierbar}}</span>
                      </a>
                    </template>
                    <template v-slot:displayCheck="{data}">
                      <span :style="data.row.displayCheck"><PhCheck :size="24" class="m-1" /></span>
                    </template>
                    <template v-slot:actions="{data}">
                      <div class="action-cell">
                        <BaseDropdownMenu placement="bottom-right" style="text-align: right;" v-if="getAktionLink(data.row.actions).length">
                          <template v-slot:hook-target>
                            <ph-dots-three-vertical  class="clickable" :size="24"  />
                          </template>
                          <div class="action-texts">
                            <template v-for="link in getAktionLink(data.row.actions)">                        
                              <a class="btn btn-clean clickable" v-if="link.type"  :key="link.key" target='_blank' 
                              :href="link.type"><PhArrowsLeftRight :size="24" class="mr-1" />{{link.label}}</a>
                              <a class="btn btn-clean clickable" v-else-if="link.key" :key="link.key" target='_blank' 
                                @click="$emit('doAction', link.key, data.row)">
                              <PhTrash v-if="link.key==='delete'" :size="24" class="mr-1" />
                              <PhGauge v-if="link.key==='limit'" :size="24" class="mr-1" />
                              <PhFileText v-else-if="link.key==='courtage'" :size="24" class="mr-1" />
                              <PhArrowsLeftRight v-else-if="link.key==='transakt'" :size="24" class="mr-1" />
                              <PhInfo v-else-if="link.key==='info'" :size="24" class="mr-1" />
                              <PhCopy v-else-if="link.key==='duplicate'" :size="24" class="mr-1" />
                              <PhSwatches v-else-if="link.key==='wiedervorlage'" :size="24" class="mr-1" />
                              {{link.label}}</a>
                            </template>
                          </div>
                        </BaseDropdownMenu>
                      </div>
                    </template>
              </Table>
            </div>

          </div>

          <div style="color:var(--color-danger);" v-else :key="k*100+idx">{{field.key}} {{field.dataType}} {{field.label}}</div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import InputField from '@/components/core/forms/InputField.vue';
import BaseDropdownMenu from "@/components/core/BaseDropdownMenu.vue";
import FormLabel from '@/components/core/forms/FormLabel.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import InputCheckBoxItem from '@/components/core/forms/checkbox/InputCheckBoxItem.vue';
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import BaseCollapsable from '@/components/core/BaseCollapsable.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import Table from '@/components/table/Table.vue';
import Vue from 'vue';
import { PhDotsThreeVertical, PhArrowsLeftRight, PhGauge, PhFileText, PhTrash, PhInfo, PhCheck, PhCopy, PhSwatches } from 'phosphor-vue'
import {sanitize} from '@/helpers/string-helper.js';
import validator from "@/mixins/validator";

export default {
  name: 'Fields',
  components: {
    InputField, InputCheckBoxItem, DatePickerField, InputTextArea, ComboBox, FormLabel,
    BaseCollapsable, BaseButton, Table, BaseDropdownMenu, InputRadioBoxGroup,
    PhTrash, PhDotsThreeVertical, PhGauge, PhArrowsLeftRight, PhFileText, PhInfo, PhCheck, PhCopy, PhSwatches
  },
  validators: {
  },
  mixins: [ validator ],
  props: {
    fields: {
      type: Array
    },
    valuesProp: {
      Type: Object
    },
    combos: {
      Type: Object
    },
    tableData: {
      Type: Object
    },
    rowsCount: {
      Type: Number
    },
    actionKeys: {
      Type: String,
      default: '',
    },
    showFields: {
      Type: Number,
      default: 0
    },
    isNotReactive: {
      type: Boolean,
      default: true,
    },
    validators: {
    }
  },
  data: function() {
    return {
      fragments: [],
      suppressValidationMessage: {},
      react: 1,
    }
  },
  mounted() {
    this.fragmentation();
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({to, from});
    next();
  },
  computed: {
    dynamicValidators() {
      return this.validators || {};
    },
    values() {
      return this.valuesProp || {};
    }
  },
  watch: {
    'fields': 'fragmentation',
    'showFields': 'displayErrorText',
    dynamicValidators: {
      immediate: true,
      handler(newDynamicValidators) {
        for (const key of Object.keys(newDynamicValidators)) {
          if (key && this.$configureValidators) {
            this.$configureValidators({ 
              [key]: newDynamicValidators[key],
            });
          }
        }
      },
    },
    isNotReactive(value) {
      if (value) {
        this.react = 0;
        this.$nextTick(() => {this.react = 1;})
      }
    }
  },
  methods: {
    fragmentation() {
      if (!this.fields?.length) {
        this.fragments = [];
        this.suppressValidationMessage = {};
      } else {
        this.fragments = [];
        this.$nextTick( () => {
          const fragments = [];
          let part;
          this.fields.forEach( (field,idx) => {
            if (idx === 0 || field.begin) {
              part = [];
              fragments.push(part);
            }
            if (!field.hidden) {
              part.push(field);
            }
            this.suppressValidationMessage[field.key]=true;
          });
          this.fragments = fragments;
        });
      }
      this.react = 1;
    },
    displayErrorText(arg) {
      if (arg == -1 || arg == 1) {
        Object.keys(this.suppressValidationMessage).forEach(key => {
          this.suppressValidationMessage[key] = arg == -1;
        })
      }
      this.react++;
    },
    setDatum(key, datum) {
      if ( null != datum) {
        if (typeof datum === 'object') {
          this.values[key] = datum.toLocaleDateString('de-DE',  { day: '2-digit', month: '2-digit', year: 'numeric' });
        } else if (datum === '') {
          this.values[key] = null;
        }
      }
    },
    setValue(field, value) {
      if (value || value === 0) {
        const arg = {
          style: 'decimal',
          maximumFractionDigits: field.precision,
          minimumFractionDigits: field.precision
        }
        if (field.dataType === 'euro' || field.dataType === 'fremdw' || field.dataType === 'proz') {
          arg.maximumFractionDigits = 2;
          arg.minimumFractionDigits = 2;
        }
        if (typeof value === 'string' ) {
          value = value.replace(/[^0-9,.-]/g, '');
          if (value.indexOf('.') > 0 && value.indexOf('.') < value.indexOf(',')) {
            value = value.replace(/[.]/g, '');
          }
          value = parseFloat(value.replace(/[,]/g, '.'));
        }
        if (isNaN(value)) {
          value = '';
        } else {
          value = value.toLocaleString('de-DE', arg);
        }
      }
      this.$refs[field.key][0].changedValue = value + ' ';
      this.$refs[field.key][0].changedValue = value;
      this.values[field.key] = value;
      this.$emit('change', { key: field.key, value: value});
    },
    onNumberFocus(field) {
      if (this.isNotReactive) {
        let value = this.values[field.key] || '';
        if (typeof value === 'string') {
          if (-1 !== value.indexOf('.')) {
            value = value.replace(/\./g, '')
          }
          while (/(,\d*)0|,$/.test(value)) {
            value = value.replace(/(,\d*)0|,$/g, '$1');
          }
        } else if (typeof value === 'number') {
          value = ('' + value).replace('.', ',');
        }
        this.$refs[field.key][0].changedValue = value;
      }
    },
    onNumberBlur(field) {
      if ( this.values[field.key] && this.values[field.key] !== this.$refs[field.key][0].changedValue) {
        this.$refs[field.key][0].changedValue = this.values[field.key];
      }
      this.onBlur(field);
    },
    getValues(field) {
      return field.values || this.combos[field.key] || [];
    },
    onBlur(field) {
      Vue.set(this.suppressValidationMessage, field.key, false);
      this.react++;
    },
    openModalEditCustomerSearchResultTable() {
      this.$refs.tableResult.openColumnsConfig();
    },
    getAktionLink(actions) {
      if (!actions) {
        return [];
      } else {
        const result = [];
        actions.forEach(a => {
          if (a.key === 'delete') {
            a.label = 'Löschen';
            result.push(a);
          } else if (a.key !== 'edit') {
            if (-1 !== this.actionKeys.indexOf(a.key)) {
              result.push(a);
            } else {
              if (process.env.NODE_ENV === 'development') console.log('misssing implementation ' + a.key);
            }
          }
        })
        return result;
      }
    },
    labelConfig(field) {
      const result = {};
      if(field.key){
        if (-1 !== field.key.indexOf('small')) {
          result.small = true;
        }
        if (-1 !== field.key.indexOf('bold')) {
          result.bold = true;
        }
        if (-1 !== field.key.indexOf('italic')) {
          result.italic = true;
        }
      }
      return result;
    },
    sanitize(htmlString) {
        return sanitize(htmlString);
    },
  },
}
</script>

<style scoped>
.btn-clean {
  color: var(--color-text);
}
.action-cell{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
}
.action-texts {
  display: flex;
  flex-direction: column;
  padding: 2px;
  color: var(--color-text);
  justify-content: left;
  font-size: 0.85rem;
}
.action-texts a, .action-cell a {
  color: var(--color-text);
  font-style: normal;
}
.action-texts a:hover {
  color: var(--color-text);
  /* text-decoration: none; */
}
::v-deep .in-line .input-forms__input-container {
  flex-direction: row;
  gap: 15px;
  flex-wrap: wrap;
}
</style>