var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BoxContainer",
        { attrs: { title: _vm.selectedTabLabel } },
        [
          _vm.rows && _vm.rows.length
            ? _c("Table", {
                attrs: {
                  rows: _vm.rows,
                  headers: _vm.headers,
                  paginationEnabled: false,
                  rowsPerPage: 20,
                  mobileConfig: {
                    title: "Erbschaftssteuerliche Werte",
                    headers: ["jahr", "soll", "ist"]
                  }
                }
              })
            : _vm._e(),
          !_vm.tableData.records || !_vm.tableData.records.length
            ? _c("div", [_vm._v("Keine Daten")])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }