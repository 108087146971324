<template>
<DragnDropArea
    class="table-card"
    hoverText="Datei hier ablegen"
    :disabled="!acceptsFile"
    @files="handleFile($event)"
>
    <div v-if="!infoModal" class="card-top">
        <div><slot name="__SELECT__"/></div>
        <div class="sortable-handler--cell"><slot name="_sortable_handler"/></div>
        <div><slot :name="actionHeaderKey"/></div>
    </div>
    <table>
        <template v-for="{key, hideOnModalSheet} in (loadingCollapsed ? topHeaders : allHeaders)">
            <TableCardRow v-if="!hideOnModalSheet && visibleInCard(key)"
                :key="key"
                :headerKey="key"
                :row="row"
            >
                <template v-for="{key} in allHeaders" :slot="'header_' + key">
                    <slot :name="'header_' + key" :cardView="true"/>
                </template>
                <template v-for="{key} in allHeaders" :slot="key">
                    <slot :name="key"/>
                </template>
            </TableCardRow>
        </template>

        <GhostLoading v-if="loadingCollapsed" type="block" :config="{ block: { height: collapsedHeaders.length * 50 } }" />
    </table>
</DragnDropArea>
</template>

<script>
import TableCardRow from './TableCardRow.vue';
import DragnDropArea from '@/components/fileUpload/DragnDropArea.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import ActionCell from '../cellTypes/ActionCell.vue';

export default {
    components: {
        TableCardRow,
        DragnDropArea,
        GhostLoading,
    },
    props: {
        topHeaders: { 
            type: Array,
            default: () => [],
        },
        collapsedHeaders: { 
            type: Array,
            default: () => [],
        },
        loadingCollapsed: {
            type: Boolean,
            default: false,
        },
        row: { 
            type: Object,
            required: true,
        },
        acceptsFile: {
            type: Boolean,
            default: false,
        },
        infoModal: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        allHeaders() {
            return [...this.topHeaders, ...this.collapsedHeaders];
        },
        actionHeaderKey() {
            const actionHeader = this.allHeaders.find(header => header.rowElement == ActionCell);
            return actionHeader ? actionHeader.key : "actions";
        },
    },
    methods: {
        visibleInCard(headerKey) {
            return headerKey !== '_v' && headerKey !== '__SELECT__' && (!this.row.__FOOTER__ || headerKey in this.row);
        },
        handleFile(files) {
            this.$emit('files', {
                row: this.row,
                files,
            });
        },
    },
}
</script>

<style scoped>
::v-deep table {
    table-layout: fixed;
    border-spacing: 0;
    width: 100%;
}

.card-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
}
</style>
