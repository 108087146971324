var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.$appNavigation.currentMenu.label,
          actions: _vm.headerActions
        },
        on: {
          "action-ADD": function($event) {
            return _vm.createItem()
          }
        }
      }),
      _vm.loading
        ? _c("GhostLoading", {
            attrs: { type: "table", title: _vm.TABLE_TITLE }
          })
        : !_vm.loading && _vm.tableRows.length
        ? _c("Table", {
            staticClass: "table-container",
            attrs: {
              tableId: "c53e30f8-2048-4256-b9bc-0e2992645f4e",
              title: _vm.TABLE_TITLE,
              headers: _vm.tableHeaders,
              rows: _vm.tableRows,
              rowsPerPage: 20,
              hidePagination: _vm.tableRows.length < 21,
              exportConfig: { roottext: _vm.TABLE_TITLE },
              dragnDropFilesOnRow: ""
            },
            on: {
              "click-dateCreated": function($event) {
                return _vm.editItem($event)
              },
              action: function($event) {
                return _vm.executeAction($event)
              },
              files: function($event) {
                return _vm.chooseUploadType($event)
              }
            }
          })
        : _c("NoData", { attrs: { title: _vm.TABLE_TITLE } }),
      _c(
        "BaseModal",
        {
          ref: "uploadMOdal",
          attrs: {
            confirmDisabled: _vm.filesAlreadyAdded,
            modalTitle: "Hochladen",
            labelButtonConfirm: "Hochladen",
            showConfirmButton: !_vm.filesAlreadyAdded
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.handleRowFiles(_vm.uploadData)
            }
          }
        },
        [
          _vm.filesAlreadyAdded
            ? _c("span", [
                _vm._v(
                  "Bereits hinzugefügte Dateien. Bitte entfernen Sie diese zuerst."
                )
              ])
            : _c("ComboBox", {
                attrs: {
                  label: "Hochladen Typ",
                  firstEmpty: false,
                  values: _vm.uploadTypeOptions
                },
                model: {
                  value: _vm.uploadType,
                  callback: function($$v) {
                    _vm.uploadType = $$v
                  },
                  expression: "uploadType"
                }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }