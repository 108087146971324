var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Table", {
        attrs: {
          title: "Terminfreigabe für andere Personen",
          headerActions: _vm.tableHeaderActions,
          headers: _vm.headers,
          rows: _vm.rows,
          hidePagination: ""
        },
        on: {
          "headerAction-ADD": _vm.openAndereInternPersonenModal,
          "action-DELETE": _vm.deleteFreigabe
        },
        scopedSlots: _vm._u([
          {
            key: "schreibrecht",
            fn: function(row) {
              return [
                _c("InputToggleSwitch", {
                  attrs: { value: row.schreibrecht },
                  on: {
                    input: function($event) {
                      return _vm.onSchreibrechtChange(row, $event)
                    }
                  }
                })
              ]
            }
          }
        ])
      }),
      _c("AndereInternPersonenModal", {
        ref: "andereInternPersonenModal",
        on: { selected: _vm.onSelectedPersonen }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }