var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {},
      [
        _vm.loading
          ? _c(
              "div",
              { staticClass: "box__container text-centered" },
              [_c("AnimatedSpinner")],
              1
            )
          : !_vm.depot.length && !_vm.empfehlung.length
          ? _c("NoData", { staticClass: "box__container" })
          : _c("div", [
              _vm.depot.length
                ? _c("div", { staticClass: "box__container" }, [
                    _c("div", { staticClass: "box__title" }, [
                      _vm._v("Bestandsdepot")
                    ]),
                    _c(
                      "div",
                      { staticClass: "row" },
                      _vm._l(_vm.depot, function(chart) {
                        return _c(
                          "div",
                          {
                            key: chart.name,
                            staticClass: "col-auto chart-container"
                          },
                          [
                            chart.type == "radialMultiple"
                              ? [
                                  _c("div", { staticClass: "box__title" }, [
                                    _vm._v(_vm._s(chart.titel))
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "charts-composite" },
                                    [
                                      _c("GaugeChartMultiple", {
                                        attrs: {
                                          chartData: _vm.multipleData(
                                            chart.data
                                          ),
                                          customColors: _vm.pieColors(
                                            chart.name
                                          ),
                                          format: "percent",
                                          height: "250px",
                                          isTooltip: false
                                        }
                                      }),
                                      _c(
                                        "div",
                                        _vm._l(chart.data, function(item, k) {
                                          return _c(
                                            "div",
                                            { key: k },
                                            [
                                              _c("PhCircle", {
                                                attrs: {
                                                  size: "16",
                                                  weight: "fill",
                                                  color: _vm.pieSerieColor(
                                                    chart,
                                                    k
                                                  )
                                                }
                                              }),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.proz(item.value)) +
                                                    " " +
                                                    _vm._s(item.name)
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        }),
                                        0
                                      )
                                    ],
                                    1
                                  )
                                ]
                              : chart.type == "bar"
                              ? [
                                  _c("div", { staticClass: "box__title" }, [
                                    _vm._v(_vm._s(chart.titel))
                                  ]),
                                  _c("BarHChart", {
                                    attrs: {
                                      chartData: _vm.barHData(chart.data),
                                      format: "percent",
                                      height: "300px",
                                      isTooltip: false
                                    }
                                  })
                                ]
                              : chart.type == "pie"
                              ? [
                                  _c("div", { staticClass: "box__title" }, [
                                    _vm._v(_vm._s(chart.titel))
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "charts-composite" },
                                    [
                                      _c("PieChart", {
                                        attrs: {
                                          chartData: chart.data,
                                          customColors: _vm.pieColors(
                                            chart.name
                                          ),
                                          format: "percent",
                                          height: "250px"
                                        }
                                      }),
                                      _c(
                                        "div",
                                        _vm._l(chart.data, function(item, k) {
                                          return _c(
                                            "div",
                                            { key: k },
                                            [
                                              _c("PhCircle", {
                                                attrs: {
                                                  size: "16",
                                                  weight: "fill",
                                                  color: _vm.pieSerieColor(
                                                    chart,
                                                    k
                                                  )
                                                }
                                              }),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.proz(item.value)) +
                                                    " " +
                                                    _vm._s(item.name)
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        }),
                                        0
                                      )
                                    ],
                                    1
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      }),
                      0
                    )
                  ])
                : _vm._e(),
              _vm.empfehlung.length
                ? _c("div", { staticClass: "box__container" }, [
                    _c("div", { staticClass: "box__title" }, [
                      _vm._v("Depotvorschlag")
                    ]),
                    _c(
                      "div",
                      { staticClass: "row" },
                      _vm._l(_vm.empfehlung, function(chart) {
                        return _c(
                          "div",
                          {
                            key: chart.name,
                            staticClass: "col-auto chart-container"
                          },
                          [
                            chart.type == "radialMultiple"
                              ? [
                                  _c("div", { staticClass: "box__title" }, [
                                    _vm._v(_vm._s(chart.titel))
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "charts-composite" },
                                    [
                                      _c("GaugeChartMultiple", {
                                        attrs: {
                                          chartData: _vm.multipleData(
                                            chart.data
                                          ),
                                          customColors: _vm.pieColors(
                                            chart.name
                                          ),
                                          format: "percent",
                                          height: "250px",
                                          isTooltip: false
                                        }
                                      }),
                                      _c(
                                        "div",
                                        _vm._l(chart.data, function(item, k) {
                                          return _c(
                                            "div",
                                            { key: k },
                                            [
                                              _c("PhCircle", {
                                                attrs: {
                                                  size: "16",
                                                  weight: "fill",
                                                  color: _vm.pieSerieColor(
                                                    chart,
                                                    k
                                                  )
                                                }
                                              }),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.proz(item.value)) +
                                                    " " +
                                                    _vm._s(item.name)
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        }),
                                        0
                                      )
                                    ],
                                    1
                                  )
                                ]
                              : chart.type == "bar"
                              ? [
                                  _c("div", { staticClass: "box__title" }, [
                                    _vm._v(_vm._s(chart.titel))
                                  ]),
                                  _c("BarHChart", {
                                    attrs: {
                                      chartData: _vm.barHData(chart.data),
                                      format: "percent",
                                      height: "300px",
                                      isTooltip: false
                                    }
                                  })
                                ]
                              : chart.type == "pie"
                              ? [
                                  _c("div", { staticClass: "box__title" }, [
                                    _vm._v(_vm._s(chart.titel))
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "charts-composite" },
                                    [
                                      _c("PieChart", {
                                        attrs: {
                                          chartData: chart.data,
                                          customColors: _vm.pieColors(
                                            chart.name
                                          ),
                                          format: "percent",
                                          height: "250px"
                                        }
                                      }),
                                      _c(
                                        "div",
                                        _vm._l(chart.data, function(item, k) {
                                          return _c(
                                            "div",
                                            { key: k },
                                            [
                                              _c("PhCircle", {
                                                attrs: {
                                                  size: "16",
                                                  weight: "fill",
                                                  color: _vm.pieSerieColor(
                                                    chart,
                                                    k
                                                  )
                                                }
                                              }),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(_vm.proz(item.value)) +
                                                    " " +
                                                    _vm._s(item.name)
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        }),
                                        0
                                      )
                                    ],
                                    1
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      }),
                      0
                    )
                  ])
                : _vm._e()
            ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }