<template>
  <div>
    <PageHeaderTitleNavigation 
      title="Weitere Verbindlichkeiten" 
      :defaultMenu="$appNavigation.currentTabMenu"
      :actions="headerActions" 
      @action-ADD="addNewOtherProduct()" 
    />

    <OtherProductsSearch ref="otherProductsSearch" :type="WEITERE_VERBINDLICHKEITEN_TYPE" hideNavigation />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';
import ROLES from '@/router/roles';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue'
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import OtherProductsSearch from '@/components/otherProducts/OtherProductsSearch.vue';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

import { MAP_TYPE_TO_TITLE, MAP_TYPE_TO_TITLE_SINGULAR, WEITERE_VERBINDLICHKEITEN_TYPE, } from './otherProductsUtils';

/**
 * Wrapper component - it was implemented to handle the specific Weitere Verbindlichkeiten navigation
 */
export default {
  data() {
    return {
      WEITERE_VERBINDLICHKEITEN_TYPE,
    };
  },
  computed: {
    ...mapGetters({
        isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
        hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
    isSuperCustomer() {
      return this.hasRoles(ROLES.SUPER_CUSTOMER);
    },
    pageMetadata() {
      const title = MAP_TYPE_TO_TITLE[WEITERE_VERBINDLICHKEITEN_TYPE];
      const titleSingular = MAP_TYPE_TO_TITLE_SINGULAR[WEITERE_VERBINDLICHKEITEN_TYPE];
      return {
        title,
        newTitle: `${titleSingular} hinzufügen`,
      };
    },
    headerActions() {
      const { isCustomer, pageMetadata, isSuperCustomer} = this;
      if (isCustomer  && !isSuperCustomer) {
        return [
          PageHeaderSimpleAction('ADD', pageMetadata.newTitle)
        ];
      }

      return [];
    },
  },
  methods: {
    addNewOtherProduct() {
      this.$refs.otherProductsSearch?.addNewOtherProduct();
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({ to, from, });
    next();
  },
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    OtherProductsSearch,
  },
}
</script>
