<template>
  <div v-if="customerDataEdited">
    <Table
      v-if="rows.length"
      ref="table"
      :tableId="tableId"
      cardView
      :headers="headers"
      :rows="rows"
      rowId="id"
      :headersSize="isCustomerOnly ? null : 5"
      showWeiterLink
      @onNavigate="navigateToCard"
      hidePagination
    >
    </Table>
    <NoData v-else noIcon />
  </div>
</template>


<script>
import BoxContainer from "@/components/core/BoxContainer.vue";
import { PhPencilLine, PhBookOpen } from "phosphor-vue";
import { TextColumn, CurrencyColumn } from "@/components/table2/table_util.js";
import Table from "@/components/table2/Table.vue";
import { mapGetters } from "vuex";
import CUSTOMERDATA_TYPES from "@/store/customerData/types";
import CORE_TYPES from "@/store/core/types";
import persoenlicheDatenMixin from "../persoenliche-daten-mixin";
import stammdatenMixin from "@/components/persoenliche-daten/stammdaten/stammdaten-mixin";
import NoData from '@/components/core/NoData.vue';

export default {
  components: {
    BoxContainer,
    PhPencilLine,
    PhBookOpen,
    Table,
    NoData,
  },
  mixins: [persoenlicheDatenMixin, stammdatenMixin],
  data: function () {
    return {
      title: "Geburtstag und Staatsangehörigkeit",
      personalDataBirth: {
        nameAtBirth: "",
        nationality: "",
        countryOfBirth: "",
        placeOfBirth: "",
        dayOfBirth: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      customerDataEdited: CUSTOMERDATA_TYPES.GETTERS.GET_CUSTOMER_DATA_EDITED,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      isFK: CORE_TYPES.GETTERS.IS_FK,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      personId: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_ID,
      customerDataConfig: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_CONFIG,
      isCustomerOnly: CORE_TYPES.GETTERS.IS_CUSTOMER_ONLY,
    }),
    headers() {
      const result = {
        center: [],
      };

      result.center.push(TextColumn("dayOfBirth", "Geburtsdatum"));
      result.center.push(TextColumn("placeOfBirth", "Geburtsort"));
      result.center.push(TextColumn("countryOfBirth", "Geburtsland"));
      result.center.push(TextColumn("nationality", "Staatsangehörigkeit"));
      result.center.push(TextColumn("nameAtBirth", "Geburtsname"));

      return result;
    },
    rows() {
      const rows = [];

      const row = {};

      row.dayOfBirth = this.personalDataBirth?.dayOfBirth || "-";
      row.placeOfBirth = this.personalDataBirth?.placeOfBirth || "-";
      row.countryOfBirth = this.personalDataBirth?.countryOfBirth || "-";
      row.nationality = this.personalDataBirth?.nationality || "-";
      row.nameAtBirth = this.personalDataBirth?.nameAtBirth || "-";

      rows.push(row);
      return rows;
    },
    tableId() {
      const uniqueUUID = "9ab530c1-1d66-432c-9691-6da145dc5ab7";
      return uniqueUUID;
    },
  },
  methods: {
    navigateToCard(row) {
      this.$router.push(
        `/persoenlichedaten/customer-data/steps/stammdaten?substep=geburtstag`
      );
    },
    currency(value) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " €";
    },
    updateGroupsIntoData() {
      this.updateGroupIntoData("personalDataBirth");
    },
  },
  mounted() {
    this.updateGroupsIntoData();
  },
  validators: {},
};
</script>

<style scoped>
.align-end {
  flex: 1 1 auto;
  align-self: flex-end;
  padding: 0px 0px 16px 5px;
  color: var(--color-text);
}
.form-control {
  display: flex;
  align-content: stretch;
  align-items: center;
}
</style>