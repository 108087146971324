<template>
  <div class="markup_editor_container">
    <div>Die empfohlene Breite des Bildes ist 1080px.</div>
    <br><br>
    <BaseButton @click="openDialog">Bild auswählen</BaseButton>
    <br />
    <br />
    <div>{{fileName}}</div>
    <br />
    <br />
    <div class="editor">
      <div id="innerspan" v-if="noError">
        <img :src="getContent" @error="hasError"/>
      </div>
    </div>
  </div>
</template>

<script>
import CMS_TYPES from "@/store/cms/types";
import { mapGetters } from "vuex";
import BaseButton from "@/components/core/BaseButton.vue";
export default {
  components: {
    BaseButton
  },
  props: {
    type: {
      type: String,
      required: true,
      default: ""
    }
  },
  data() {
    return {
      html: "",
      json: "",
      linkUrl: null,
      linkMenuIsActive: false,
      fileName: "",
      same: true,
      content: "",
      noError:true,
    };
  },
  computed: {
    ...mapGetters({
      getInhalt: CMS_TYPES.GETTERS.GET_CMS_COMPONENTS
    }),
    getType() {
      return this.type;
    },
    getGenus() {
      return this.genus;
    },
    getSpecies() {
      return this.species;
    },
    getContent() {
      if (this.same) {
        return this.getComputedInhalt;
      } else {
        return this.content;
      }
    },
    getComputedInhalt() {
      if (!this.getInhalt) {
        return;
      }

      if (this.genus == null && this.species == null) {
        const found = this.getInhalt.find(x => x.type == this.getType);
        if (found) {
          return found.inhalt;
        }
      } else {
        const found = this.getInhalt.find(
          x =>
            x.type == this.getType &&
            x.genus == this.getGenus &&
            x.species == this.getSpecies
        );
        if (found) {
          return found.inhalt;
        }
      }

      return '';
    }
  },

  methods: {
    hasError(){
      this.noError = false;
    },
    openDialog() {
      const input = document.createElement("input");
      const acceptedImageTypes = ["image/gif", "image/jpeg", "image/png"];
      input.accept = acceptedImageTypes;
      input.type = "file";
      let myfile;
      input.onchange = e => {
        myfile = e.target.files[0];
        if (myfile) {
          if (!acceptedImageTypes.includes(myfile["type"])) {
            this.fileName = "Nicht unterstützt Bild Typ";
          } else if (myfile.size > 5000000) {
            this.fileName = "Bitte wählen Sie ein kleineres Bild aus";
          } else {
            this.fileName = myfile.name;
            this.makeBase64(myfile);
          }
        }
      };
      /*  if (myfile && acceptedImageTypes.includes(myfile["type"])) {
          this.fileName = myfile.name;
          this.makeBase64(myfile);
        } 
        else {
          this.fileName = "Nicht unterstützt Bild Typ";
        } 
      };*/
      input.click();
    },

    async makeBase64(file) {
      const toBase64 = file =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      let imageBase = await toBase64(file);
      this.content =
        //    '<p style="text-align: left"><img src="' + imageBase + '"></p>';
        imageBase;
      this.$store.commit(
        CMS_TYPES.MUTATIONS.SET_CMS_COMPONENT_CONTENT_UPDATED,
        true
      );
      this.$store.commit(
        CMS_TYPES.MUTATIONS.SET_CMS_COMPONENT_CURRENT_CONTENT,
        this.content
      );
      if (this.content == this.getComputedInhalt) {
        this.same = true;
      } else {
        this.same = false;
      }
    }
  },

  beforeDestroy() {
    this.$store.commit(
      CMS_TYPES.MUTATIONS.SET_CMS_COMPONENT_CONTENT_UPDATED,
      false
    );
  }
};
</script>

<style scoped>
#inp {
  display: none;
}
input[type="text"] {
  width: 95%;
}
.is-active {
  color: var(--color-warning);
}
.markup_editor_container {
  overflow: auto;
  color: var(--color-text);
}
.letter_for_link {
  font-weight: bold;
  display: inline;
  cursor: pointer;
}

::v-deep .editor__content h2 {
  font-size: 26px;
}
::v-deep .editor__content li > p:first-child {
  margin: 0px;
}
::v-deep .editor__content a {
  color: var(--color-workspaces-nav-active);
  font-weight: bold;
}
</style>