<template>
  <div class="list-container">
    <div v-for="(item, index) in routes" :key="index">
      <hr v-if="index !=0">
      <div v-if="item.externalLink" class="list-item" @click="navigateToExternalLink(item.externalLink)">
        <div>{{item.label}}</div>
        <PhCaretRight :size="18" v-if="item.externalLink"/>
      </div>
      <div v-else class="list-item" @click="navigateTo(item.route)">
        <div>{{item.label}}</div>
        <PhCaretRight :size="18" v-if="item.route"/>
      </div>
    </div>
  </div>
</template>

<script>
import {PhCaretRight } from "phosphor-vue";

export default {
  components: {
    PhCaretRight,
  },
  props: {
    routes: {
      default: []
    },
  },
  methods: {
    navigateTo(route) {
      if(route) {
        this.$router.push({path: route});
      }
    },
    navigateToExternalLink(externalLink) {
      if(externalLink) {
        window.open(externalLink, '_blank');
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.list-container {
  display: flex;
  flex-direction: column;
}

.list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  > * {
    &:first-child {
      margin-top: 0 !important;
    }
    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}

hr {
  margin: 0 0 8px 0;
}

* + hr {
  margin-top: 8px;
}

</style>