<template>
    <component :is="currentHomeTemplate" v-if="hasProperTemplate">
      <slot></slot>
    </component>
    <HomeTemplateGhost v-else/>
</template>
<script>
import { mapGetters } from "vuex";
import CMS_TYPES from "../../store/cms/types";
import HomeTemplate0 from "@/views/cms/HomeTemplate0.vue";
import HomeTemplate2 from "@/views/cms/HomeTemplate2.vue";
import HomeTemplateA from "@/views/cms/HomeTemplateA.vue";
import HomeTemplate3 from "@/views/cms/HomeTemplate3.vue";
import HomeTemplate5 from "@/views/cms/HomeTemplate5.vue";
import HomeTemplate6 from "@/views/cms/HomeTemplate6.vue";
import HomeTemplate7 from "@/views/cms/HomeTemplate7.vue";
import HomeTemplate8 from "@/views/cms/HomeTemplate8.vue";
import HomeTemplateGhost from "@/views/cms/HomeTemplateGhost.vue";
export default {
  components: {
    HomeTemplate0,
    HomeTemplate2,
    HomeTemplateA,
    HomeTemplate3,
    HomeTemplate5,
    HomeTemplate6,
    HomeTemplate7,
    HomeTemplate8,
    HomeTemplateGhost,
  },
  computed: {
    ...mapGetters({
      template: CMS_TYPES.GETTERS.GET_CMS_TEMPLATE,
      componentsReady: CMS_TYPES.GETTERS.GET_CMS_COMPONENTS_READY,
    }),
    currentHomeTemplate: function() {
      return "home-template" + this.template;
    },
    hasProperTemplate: function() {
      return this.componentsReady && this.template;
    },
   
  }
};
</script>