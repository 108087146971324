<template>
  <div v-if="vertragsdatenFieldsDefinition">
    <div class="row">
      <div class="col-12 col-sm-6">
        <ComboBox
          label="Art"
          v-model="versicherung.versichertesObjekt.verssache"
          :values="artValues"
          @change="insuranceObjectPropertyDataChanged('versichertesObjekt','verssache', $event)"
        />        
      </div>
    </div>    
    <div class="row">
      <div class="col-12 col-sm-6">
        <InputField
          v-model="versicherung.versichertesObjekt.zusatz"
          label="Zusatz"
          @input="insuranceObjectPropertyDataChanged('versichertesObjekt','zusatz', $event)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <InputField
          v-model="versicherung.versichertesObjekt.strasse"
          label="Straße"
          @input="insuranceObjectPropertyDataChanged('versichertesObjekt','strasse', $event)"
        />
      </div>
    </div>    
    <div class="row">
      <div class="col-12 col-sm-6">
        <InputField
          v-model="versicherung.versichertesObjekt.hausnr"
          label="Hausnummer"
          @input="insuranceObjectPropertyDataChanged('versichertesObjekt','hausnr', $event)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <InputField
          v-model="versicherung.versichertesObjekt.plz"
          label="PLZ"
          @input="insuranceObjectPropertyDataChanged('versichertesObjekt','plz', $event)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <InputField
          v-model="versicherung.versichertesObjekt.ort"
          label="Ort"
          @input="insuranceObjectPropertyDataChanged('versichertesObjekt','ort', $event)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <InputField
          v-model="versicherung.versichertesObjekt.land"
          label="Land"
          @input="insuranceObjectPropertyDataChanged('versichertesObjekt','land', $event)"
        />
      </div>
    </div>

  </div>
</template>


<script>
import CUSTOMERDATA_TYPES from '@/store/customerData/types';
import BoxContainer from "@/components/core/BoxContainer.vue";
import InputCheckBoxItem from "@/components/core/forms/checkbox/InputCheckBoxItem.vue";
import InputRadioBoxItem from "@/components/core/forms/radiobox/InputRadioBoxItem.vue";
import InputRadioBoxGroup from "@/components/core/forms/radiobox/InputRadioBoxGroup.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputField from "@/components/core/forms/InputField.vue";
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import { PhNotePencil, PhCopy, PhInfo, PhNoteBlank } from "phosphor-vue";
import EntitySelector from '@/components/core/forms/EntitySelector.vue';
import InsuranceDetailMixin from './InsuranceDetail-mixin.js';
import CORE_TYPES from '@/store/core/types';
import { mapGetters } from 'vuex';

export default {
  mixins: [InsuranceDetailMixin],
  components: {
    BoxContainer,
    InputCheckBoxItem,
    InputRadioBoxItem,
    InputRadioBoxGroup,
    ComboBox,
    InputField,
    DatePickerField,
    PhNotePencil,
    PhCopy,
    PhInfo,
    PhNoteBlank,
    EntitySelector,
  },
  props: {
    details: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapGetters({
      isFA: CORE_TYPES.GETTERS.IS_FA, 
    }),
    artValues() {
      return this.versicherung?.versichertesObjekt?.verssacheValues?.map(a => {return {
          label: a.value,
          value: a.key,
      }});
    },
  },
  methods: {
  }
};
</script>

<style scoped>
.align-end {
  flex: 1 1 auto;
  align-self: flex-end;
  padding: 0px 0px 16px 5px;
}
.form-control {
  display: flex;
  align-content: stretch;
  align-items: center;
}
</style>