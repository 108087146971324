<template>
  <div>
    <MailComposer
      :custom="true"
      :isGetPlaceholdersOnInit="!$route.meta.appointment"
      @callbacks="setCallbacks"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MailComposer from '../MailComposer.vue';
import MAILCOMPOSER_TYPES from '@/store/mailcomposer/types';
import BaseButton from '@/components/core/BaseButton.vue';
import {
  MAX_ITEMS_VISIBLE,
  KUNDE_TYPE,
  BROKER_TYPE,
  VERSICHERUNG_TAG_DESCRIPTION,
  GESELLSCHAFT_TAG_DESCRIPTION,
  KUNDE_TAG_DESCRIPTION,
  VERSICHERUNG_KUNDE_TAG_DESCRIPTION,
  VERSICHERUNG_GESELLSCHAFT_TAG_DESCRIPTION,
  VORLAGEN_TYPE_MAIL,
  VORLAGEN_TYPE_BRIEF, validateEmail, getFileName, getFileType
} from '../MailComposer.vue';
import validator from "@/mixins/validator";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import CORE_TYPES from "@/store/core/types";

export default {
  name: 'AppointmentMailComposer',
  components: {
    MailComposer,
  },

  props: {},

  computed: {
    ...mapGetters({
      initAppointmentMail: MAILCOMPOSER_TYPES.GETTERS.INIT_MAIL_APPOINTMENT,
      linkedObjectSelected: MAILCOMPOSER_TYPES.GETTERS.GET_MAIL_LINKED_OBJECT,
      savedConfiguration: MAILCOMPOSER_TYPES.GETTERS.GET_MAILCOMPOSER_CONFIG,
      attachments: MAILCOMPOSER_TYPES.GETTERS.SET_ATTACHMENTS,
    }),
  },

  data() {
    return {
      callbacks: {},
      customFunctions: {
      }
    };
  },
  watch: {
    initAppointmentMail(value) {
      if (value) {
        if (!this.savedConfiguration.subject) {
          this.callbacks.setSubject(value?.subject);
        }
        if (!this.savedConfiguration.getComputedText) {
          if(value?.place) {
            value.html = value.html.replace('Termin bestätigen.', `Termin bestätigen. Treffpunkt ist: <br> ${value.place}`)
          }
          this.callbacks.setHtmlText(value.html);
        }
        if (!this.attachments || !this.attachments.length) {
          const attachments = value?.attachments?.map(att => ({id: att.id, label: att.name, fileName: att.name,}))
          if (attachments?.length) {
            this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, attachments);
          }
        }


        if (!this.savedConfiguration.receiver || !this.savedConfiguration.receiver.length) {
          let receiver = [];
          receiver = value?.participants?.map(p => {
            return {
              value: p?.user?.userId,
              label: `${p.email}`,
              email: `${p.email}`,
              type: p?.user?.type,
            }
          });
          this.callbacks.setReceiver(receiver)
        }
        this.callbacks.setEmailModus(value?.emailModus)
      }
    },
  },
  async mounted() {
    if (this.$route.meta.appointment) {
      const appointmentId = this.$route.params.id + '';
      this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.INIT_MAIL_APPOINTMENT, {
        appointmentId,
        data: this.linkedObjectSelected?.data
      });
      this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAIL_LINKED_OBJECT, null);
    }
  },

  methods: {
    setCallbacks(value) {
      this.callbacks = value;
    },
  },
  validators: {},
  beforeRouteLeave(to, from, next) {
    this.callbacks.handleBeforeRouteLeave(to, from, next)
  }
};
</script>

<style scoped>
</style>
