var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: "Versandprotokolle" }
      }),
      _vm.requestedData
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("VpCommTable", {
                attrs: {
                  title: "Versandprotokolle",
                  records: _vm.records,
                  loading: _vm.loading
                },
                on: { selected: _vm.onSelect }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }