<template>
  <div>
      <OptionMenu
        :id="$appNavigation.currentOptionMenuId"
        :defaultMenu="$appNavigation.currentOptionMenu"
      />

      <DashboardPanel
        class="mb-4"
        id="e9561e27-46f1-45b6-8cd9-449a313eccdc"
        :data="dashboardData"
        title="Altersvorsorge"
        canEdit
        is3Columns
        hideTopboxContainer
      >
        <template #topBox>
          <HinweiseUndFehler :hints="hints" />

          <div class="box__container">
            <ComboBox
              label="Depotnummer"
              v-model="fremdnr"
              :values="fremdnrValues"
              isComponentHalfSize
              @change="retrieveAltersvorsorge"
            />

            <ComboBox
              label="Jahr"
              v-model="year" 
              :values="yearValues"
              isComponentHalfSize
              @change="retrieveAltersvorsorge"
            />
          </div>
        </template>
      </DashboardPanel>
  </div>
</template>
  
  <script>
import BERICHTE_TYPES from '@/store/berichte/types';
import { mapGetters } from "vuex";
import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import DashboardPanel from "@/components/dashboard/DashboardPanel.vue";
import ComboBox from '@/components/core/forms/ComboBox.vue';
import HinweiseUndFehler from "@/components/core/HinweiseUndFehler.vue";

export function getPillInfo(value) {
  if (value === true) {
    return {
      label: 'Ja',
      type: 'success',
    }
  } else if (value === false) {
    return {
      label: 'Nein',
      type: 'danger',
    }
  } else {
    return {
      label: 'Unbekannt',
      type: 'info',
    }
  }
}

export default {
  components: {
    OptionMenu,
    DashboardPanel,
    ComboBox,
    HinweiseUndFehler,
  },
  data() {
    return {
      loading: false,
      year: null,
      fremdnr: null,
      hints: [
        {
          title: 'Ablaufstabilisator',
          message: 'Der Ablaufstabilisator reduziert zum Laufzeitende hin das Risiko von Schwankungen des gesparten Fondsvermögens.',
        },
        {
          title: 'Höchststand',
          message: `Die Höchststandsicherung ist ein Sicherungsmechanismus, der ab dem 55. Geburtstag greifen kann. Wenn der Kunde diese 
            Option wählt, wird das Investment so ausgerichtet, dass der jeweilige Depotwert nicht mehr unterschritten wird.`,
        },
        {
          title: 'Tilgungsbetrag',
          message: 'Der Anteil aus den regelmäßigen Beiträgen die dazu verwendet werden, die Vorabprovision sowie die Refinanzierungskosten zu tilgen.',
        },
        {
          title: 'Stornorisiko',
          message: `Die Höhe der noch nicht getilgten Vorabprovision. I. d. R. zahlt ein Kunde monatlich Beiträge, wovon ein Teil dazu verwendet 
            wird, die ausgezahlte Vorabprovision zu tilgen. Somit verringert sich im Normalfall monatlich das Stornorisiko um den Anteil der Tilgung.`,
        },
      ]
    };
  },
  computed: {
    ...mapGetters({
      altersvorsorge: BERICHTE_TYPES.GETTERS.ALTERSVORSORGE,
    }),
    fremdnrValues() {
      return this.altersvorsorge.init?.COMBO_DEPOT || []
    },
    yearValues() {
      return this.altersvorsorge.init?.COMBO_BEITRAGSJAHR || []
    },
    dashboardData() {
      const data = {
        widgets: [
          {
            name: "depotdaten",
            title: "Depotdaten",
            component: () =>
              import("@/views/berichte/altersvorsorgeCards/Depotdaten.vue"),
            loading: () => this.loading,
          },
          {
            name: "zulagen",
            title: "Zulagen",
            component: () =>
              import("@/views/berichte/altersvorsorgeCards/Zulagen.vue"),
            loading: () => this.loading,
          },
          {
            name: "kinder",
            title: "Kinder",
            component: () =>
              import("@/views/berichte/altersvorsorgeCards/Kinder.vue"),
            loading: () => this.loading,
            visible: () => this.altersvorsorge?.zulagen?.vorsorgeZulagenKinder?.length,
          },
          {
            name: "meldungen",
            title: "Meldungen der Zulagenstelle",
            component: () =>
              import("@/views/berichte/altersvorsorgeCards/Meldungen.vue"),
            loading: () => this.loading,
          },
          {
            name: "schad",
            title: "Schädliche Verwendung",
            component: () =>
              import("@/views/berichte/altersvorsorgeCards/SchaedlicheVerwendung.vue"),
            loading: () => this.loading,
          },
          {
            name: "kapital",
            title: "Kapitalübertragung",
            component: () =>
              import("@/views/berichte/altersvorsorgeCards/Kapitaluebertragung.vue"),
            loading: () => this.loading,
          },
          {
            name: "auszahlung",
            title: "Auszahlung",
            component: () =>
              import("@/views/berichte/altersvorsorgeCards/Auszahlung.vue"),
            loading: () => this.loading,
          },
        ],
      }

      return data;
    },
  },
  mounted() {
    this.initSelections().then(() => {
      this.year = this.yearValues[this.yearValues.length - 1]?.value
      this.fremdnr = this.fremdnrValues[this.fremdnrValues.length - 1]?.value
      this.retrieveAltersvorsorge()
    })
    
  },
  methods: {
    navigateTo(event) {
      this.$router.push({ path: `${event}` });
    },
    initSelections() {
      this.loading = true

      return this.$store.dispatch(BERICHTE_TYPES.ACTIONS.INIT_ALTERSVORSORGE).finally(() => this.loading = false);
    },
    async retrieveAltersvorsorge() {

      try {
        this.loading = true

        const payload = {
          year: this.year,
          fremdnr: this.fremdnr,
        }

        await this.$store.dispatch(BERICHTE_TYPES.ACTIONS.GET_ALTERSVORSORGE, payload);
      } finally {
        this.loading = false
      }
    },
  },
};
</script>
  