<template>
  <div class="antrag-components__container">
    <div class="box__container">
      <div class="row">
        <ComboBox
          label="DAB Depotnummer"
          v-model="form.depotnr" 
          :values="comboboxValues && comboboxValues.depotnr ? comboboxValues.depotnr : []"
          @change="$emit('save', { depotnr: $event })"/>

          <InputField class="input-fix" v-if="form.depotnr === 'freie Eingabe:'"
          v-model="form.depotnrText"
          @change="$emit('save', { depotnrText: $event })"/>
        <div class="col-12">
          <FormLabel label="Bitte tragen Sie hier die Daten des Depots ein, von dem die Wertpapiere übertragen werden sollen."
            :config="{'bold': true}" />
        </div>
        <FormDivider class="col-12"/>

        <div class="col-12">
          <FormLabel label="Daten der Empfängerbank" :config="{'bold': true}"/>
        </div>
        <InputField class="col-12 col-md-6" v-model="form.bankName" label="Name"
          @change="$emit('save', { bankName: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.empfangDepotnr" label="Depotnummer"
          @change="$emit('save', { empfangDepotnr: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.bankBLZ" label="BLZ"
          @change="$emit('save', { bankBLZ: $event })"/>
        <InputIbanBic label="BIC (nur Ausland)" type="BIC" class="col-12 col-md-6" 
          :componentData="form.bankBIC"
          @input="$emit('save', { bankBIC: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.bankAnsprech" label="Ansprechpartner (nur Ausland)"
          @change="$emit('save', { bankAnsprech: $event })"/>

        <FormDivider class="col-12"/>

        <div class="col-12">
          <FormLabel label="Daten des Empfängers" :config="{'bold': true}"/>
        </div>
        <div class="col-12">
          <FormLabel label="Empfänger 1" :config="{'bold': true}"/>
        </div>
        <InputRadioBoxGroup class="col-12 col-md-6" 
          :value="form.anrede" 
          :values="[
            {value: 'Herr', label: 'Herr'},
            {value: 'Frau', label: 'Frau'}
          ]"
          @input="$emit('save', { anrede: $event })"/>

        <InputField class="col-12 col-md-6" v-model="form.empfangVorname1" label="Vorname"
          @change="$emit('save', { empfangVorname1: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.empfangName1" label="Name"
          @change="$emit('save', { empfangName1: $event })"/>

        <InputField class="col-12 col-md-6" v-model="form.strasse1" label="Straße, Hausnummer"
          @change="$emit('save', { strasse1: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.plz1" label="PLZ"
          @change="$emit('save', { plz1: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.Ort1" label="Ort"
          @change="$emit('save', { Ort1: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.tin1" label="Steuer-Identifikationsnnummer (TIN)"
          @change="$emit('save', { tin1: $event })"/>
        <DatePickerField 
          v-model="form.geburtsdatum1"
          label="Geburtsdatum" 
          class="col-12 col-md-6" 
          isValueAsString
          @input="$emit('save', { geburtsdatum1: $event })"/>
        <ComboBox class="col-12 col-md-6" 
          label="Verwandschaftsverhältnis zum Auftraggeber"
          v-model="form.verwandschaft1" 
          :firstEmpty="true"
          :values="comboboxValues && comboboxValues.verwandschaft ? comboboxValues.verwandschaft : []"
          @change="$emit('save', { verwandschaft1: $event })">
        </ComboBox>

        <FormDivider class="col-12"/>
        <div class="col-12">
          <FormLabel label="Empfänger 2" :config="{'bold': true}"/>
        </div>
        <InputRadioBoxGroup class="col-12 col-md-6" 
          :value="form.anrede2" 
          :values="[
            {value: 'Herr', label: 'Herr'},
            {value: 'Frau', label: 'Frau'}
          ]"
          @input="$emit('save', { anrede2: $event })"/>

        <InputField class="col-12 col-md-6" v-model="form.empfangVorname2" label="Vorname"
          @change="$emit('save', { empfangVorname2: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.empfangName2" label="Name"
          @change="$emit('save', { empfangName2: $event })"/>

        <InputField class="col-12 col-md-6" v-model="form.strasse2" label="Straße, Hausnummer"
          @change="$emit('save', { strasse2: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.plz2" label="PLZ"
          @change="$emit('save', { plz2: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.Ort2" label="Ort"
          @change="$emit('save', { Ort2: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.tin2" label="Steuer-Identifikationsnnummer (TIN)"
          @change="$emit('save', { tin2: $event })"/>
        <DatePickerField 
          class="col-12 col-md-6" 
          v-model="form.geburtsdatum2"
          label="Geburtsdatum"
          isValueAsString
          @input="$emit('save', { geburtsdatum2: $event })"/>
        <ComboBox class="col-12 col-md-6" 
          label="Verwandschaftsverhältnis zum Auftraggeber"
          v-model="form.verwandschaft2" 
          :firstEmpty="true"
          :values="comboboxValues && comboboxValues.verwandschaft ? comboboxValues.verwandschaft : []"
          @change="$emit('save', { verwandschaft2: $event })">
        </ComboBox>
      </div>

      <BaseButton @click="$emit('next')">Weiter</BaseButton>
    </div>
  </div>
</template>
<script>
import FormLabel from '@/components/core/forms/FormLabel.vue';
import InputField from '@/components/core/forms/InputField.vue';
import InputIbanBic from '@/components/core/forms/InputIBAN_BIC.vue';
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import FormDivider from '@/components/core/forms/FormDivider.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import validator from '@/mixins/validator';
import ComboBox from '@/components/core/forms/ComboBox.vue';

export default {
  mixins: [validator],
  validators: {
  },
  props: {
    positionData: {
    },
    comboboxValues: {
    }
  },
  components: {
    FormLabel,
    InputField,
    DatePickerField,
    BaseButton,
    FormDivider,
    InputRadioBoxGroup,
    InputIbanBic,
    ComboBox,
  },
  data() {
    return {
      form: {}
    }
  },
  mounted() {
    this.form = Object.assign({}, this.positionData || {});
    if(!this.form.empfangDepotnr)
      this.form.empfangDepotnr = this.positionData.bisherDepotnr
    if(!this.form.bankName)
      this.form.bankName = this.positionData.bisherManuellName
    if(!this.form.bankBLZ)
      this.form.bankBIC = this.positionData.bankBIC
  },
  methods: {
  }
}
</script>
<style scoped>

.input-fix {
  padding-top: 24px;
  text-align: left;
}

</style>