var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c("GhostLoading", { attrs: { type: "table" } })
        : !_vm.rows || !_vm.rows.length
        ? _c("NoData")
        : _c("Table", {
            attrs: {
              headers: _vm.headers,
              rows: _vm.rows,
              rowsPerPage: _vm.rows.length,
              hidePagination: true,
              rowId: "isin",
              title: "Wertpapiere",
              tableId: "vvstrategienwp"
            },
            on: {
              "action-FACTSHEET": _vm.openFactsheet,
              "action-comment": _vm.openCommentModal,
              "action-delete": _vm.removeWertpapier
            },
            scopedSlots: _vm._u([
              {
                key: "sonstige",
                fn: function(row) {
                  return [
                    row.sonstige && row.sonstige.length
                      ? _c("div", [
                          _c(
                            "ul",
                            _vm._l(row.sonstige, function(line, index, last) {
                              return _c("div", { key: index }, [
                                _c("li", { class: row.bgColor }, [
                                  _vm._v(_vm._s(line) + " " + _vm._s(last))
                                ])
                              ])
                            }),
                            0
                          )
                        ])
                      : _c("div")
                  ]
                }
              },
              {
                key: "anteile",
                fn: function(row) {
                  return [
                    row.anteilEditable && !_vm.disabled
                      ? _c("InputField", {
                          attrs: {
                            value: row.anteile,
                            id: "anteile" + row.id,
                            type: "percent",
                            precision: 2
                          },
                          on: {
                            change: function($event) {
                              _vm.$emit("updateVarianteStore", {
                                componentId: "anteile",
                                value: _vm.formatNumber($event),
                                positionId: row.id
                              })
                            }
                          }
                        })
                      : _c("div", { class: row.anteileColor }, [
                          _vm._v(" " + _vm._s(row.anteile + "%" || "") + " ")
                        ])
                  ]
                }
              }
            ])
          }),
      _c(
        "BaseModal",
        {
          ref: "commentModal",
          attrs: {
            labelButtonConfirm: "Speichern",
            labelButtonCancel: "Abbrechen",
            showCloseButton: false
          },
          on: {
            onConfirmButton: _vm.saveComment,
            onCancelButton: _vm.closeCommentModal,
            onEscButton: _vm.closeCommentModal
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [_vm._v(" Änderungs-Kommentar ")]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "div",
            [
              _c("div", { staticClass: "mb-2" }, [
                _vm._v(" Änderungskommentar für Position "),
                _c("span", { staticClass: "font-bold" }, [
                  _vm._v(_vm._s(_vm.selectedRow && _vm.selectedRow.name))
                ]),
                _vm._v(
                  " (ISIN: " +
                    _vm._s(_vm.selectedRow && _vm.selectedRow.isin) +
                    ") "
                )
              ]),
              _vm.selectedRow && _vm.selectedRow.altePosAnteil
                ? _c("div", [
                    _c("div", [
                      _vm._v(
                        " alter Anteil: " +
                          _vm._s(
                            _vm.selectedRow && _vm.selectedRow.altePosAnteil
                          ) +
                          "% "
                      )
                    ]),
                    _c("div", [
                      _vm._v(
                        "neuer Anteil: " +
                          _vm._s(_vm.selectedRow && _vm.selectedRow.anteile) +
                          "%"
                      )
                    ])
                  ])
                : _c("div", [
                    _vm._v(
                      "Anteil: " +
                        _vm._s(_vm.selectedRow && _vm.selectedRow.anteile) +
                        "%"
                    )
                  ]),
              _c("div", [
                _vm._v("Bitte verwenden sie kein %-Zeichen im Kommentar.")
              ]),
              _c("InputTextArea", {
                model: {
                  value: _vm.comment,
                  callback: function($$v) {
                    _vm.comment = $$v
                  },
                  expression: "comment"
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }