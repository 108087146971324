import actions from './actions';
import mutations from './mutations';
import getters from './getters';

/**
 * mutations: synchronous operation responsible for change the state
 * actions: asynchronous operation responsible for call external resources and commit mutations 
 */
export function getInitialState() {
  return {
    clicksByPath: {},
    parallelTimeoutTime: null, // similar to baseGlobalState.supposedTimeoutTime, but this one is updated on every click
    nextScheduledSaveClickBatch: null,
    queueClicks: []
  }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}