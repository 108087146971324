/**
 * Gets the value of a specific cookie
 * @param cookieName
 * @returns {string} only the value of the requested cookie name. Returns undefined when cookie does not exist.
 */
export function getCookie(cookieName) {
    var nameEQ = cookieName + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ')
            c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0){
            return c.substring(nameEQ.length,c.length);
        }
    }
    return undefined;
}

/**
 * Sets or overrides a cookie with a given name, value and expiration date
 * @param cookieName
 * @param cookieValue
 * @param expirationDays
 */
export function setCookie(cookieName, cookieValue, expirationDays) {
    if (!cookieValue) return;

    let currentDomain = window.location.hostname;
    if (currentDomain === 'localhost' || currentDomain === '127.0.0.1') {
        currentDomain = 'localhost';
    }
    var expires = "";
    if (expirationDays) {
        var date = new Date();
        date.setTime(date.getTime() + (expirationDays*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    let sameSite = "; SameSite=Lax";
    if (window.location.protocol === "https:") {
        sameSite = "; SameSite=None; Secure";
    }
    document.cookie = cookieName + "=" + (cookieValue || "")  + expires + sameSite + "; path=/; domain=" + currentDomain;
}