var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "modalAdd",
          attrs: {
            modalTitle: "Aufgabe bearbeiten",
            showDefaultButtons: false,
            size: "lg"
          }
        },
        [
          _c("AppointmentEditCreate", {
            attrs: {
              closeModal: _vm.onCancelModal,
              subject:
                _vm.gesellschaftName +
                " " +
                ((_vm.nummer || _vm.bereichId || _vm.bezeichnung) &&
                  "(" + (_vm.nummer || _vm.bereichId || _vm.bezeichnung) + ")"),
              status: "OFFEN",
              isActivity: true,
              bereich: "VersVertrag",
              bereichId: _vm.bereichId,
              bereichText: _vm.bereichText
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }