var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "menu-structure-list__container" },
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: "Menüstrukturen", actions: _vm.headerActions },
        on: {
          "action-ADD": _vm.addMenuStructure,
          "action-CONFIG": _vm.openConfig
        }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.loading
            ? _c("GhostLoading", {
                attrs: { title: "Menüstrukturliste", type: "table" }
              })
            : _c("Table", {
                attrs: {
                  title: "Menüstrukturliste",
                  headers: _vm.headers,
                  rows: _vm.rows
                },
                on: {
                  "click-label": _vm.editMenuStructure,
                  "action-DELETE": _vm.removeMenuStructure
                }
              })
        ],
        1
      ),
      _c("AddMenuStructureModal", {
        ref: "addMenuStructureModal",
        on: { saved: _vm.onMenuStructureSaved }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }