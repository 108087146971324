<template>
  <ul class="box-list__container">
    <li v-for="(item, index) in items" :key="index"
      @click="handleClick(item)" class="box-list__item box__container" :class="{'clickable': item.clickable}">
      <div v-if="hasIcon" class="box-list__item--icon">
        <slot name="icon" :item="item"></slot>
      </div>
      <div v-if="hasContent" class="box-list__item--content">
        <slot :item="item"></slot>
      </div>
      <div v-if="hasLink" class="box-list__item--link">
        <slot name="link" :item="item"></slot>
      </div>
    </li>
  </ul>
</template>

<script>
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";

export default {
  mixins: [InteractiveHelpCommonsMixin],
  props: {
    items: {
      type: Array,
      default: () => [],
    }
  },
  computed: {
    hasIcon() {
      return !!this.$scopedSlots.icon;
    },
    hasContent() {
      return !!this.$scopedSlots.default;
    },
    hasLink() {
      return !!this.$scopedSlots.link;
    }
  },
  methods: {
    handleClick(item) {
      if (item.clickable || item.clickable === undefined) {
        this.$emit('clickItem', item);
      }
    }
  }
}
</script>

<style scoped>
.box-list__container {
  list-style: none;
  margin: 0;
  padding: 0;
}
.box-list__item {
  display: flex;
}
.box-list__item--icon {
  display: flex;
  align-items: center;
  flex: 0 0 28px;
  margin: 0 16px 0 0;
}
.box-list__item--content {
  flex: 1 1 auto;
  margin: 0;
}
.box-list__item--content > * {
  word-break: break-word;
  line-height: 1.2em;
}
.box-list__item--content > h1,
.box-list__item--content > h2,
.box-list__item--content > h3,
.box-list__item--content > h4,
.box-list__item--content > h5,
.box-list__item--content > h6 {
  font-size: 16px;
  font-weight: 500;
}
.box-list__item--link {
  display: flex;
  align-items: center;
  margin: 0 0 0 16px;
}
.box-list__item--link > a {
  color: var(--color-secondary);
}
.box-list__item--link > a:hover {
  text-decoration: underline;
}
</style>
