var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.getPositions && _vm.getPositions.length
        ? _c(
            "div",
            [
              _vm.config && _vm.config.comment
                ? _c("div", { staticClass: "small" }, [
                    _vm._v(_vm._s(_vm.config.comment))
                  ])
                : _vm._e(),
              _vm.tableData.records.length
                ? _c("Table", {
                    attrs: {
                      tableData: _vm.tableData,
                      cardViewEnabled: false,
                      filterEnabled: false,
                      exportEnabled: false,
                      paginationEnabled: false,
                      actions: _vm.columnActions,
                      rowsPerPage: _vm.tableData.records.length
                    },
                    on: {
                      "execute-action": function($event) {
                        return _vm.handleTableAction($event)
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "betrag",
                          fn: function(props) {
                            return [
                              _c("InputField", {
                                attrs: {
                                  type: _vm.betragType,
                                  value: props.data.row.betrag,
                                  id: "betrag" + props.data.row.index
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      props.data.row,
                                      _vm.getBetragId
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "aa",
                          fn: function(props) {
                            return [
                              _c("InputField", {
                                attrs: {
                                  type: "percent",
                                  value: props.data.row.aa,
                                  id: "aa" + props.data.row.index,
                                  placeholder: props.data.row.defaultAA
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.onChangeInputField(
                                      $event,
                                      props.data.row,
                                      "aa"
                                    )
                                  }
                                }
                              })
                            ]
                          }
                        },
                        {
                          key: "betragEinmal",
                          fn: function(props) {
                            return [
                              _c("InputField", {
                                attrs: {
                                  type: "currency",
                                  precision: 2,
                                  value: _vm.calculator(props.data.row.betrag),
                                  id: "betragEinmal" + props.data.row.index,
                                  disabled: true
                                }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      342580989
                    )
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c("DeletePositionModal", {
        ref: "deleteModal",
        attrs: { position: _vm.positionToDelete },
        on: {
          delete: function($event) {
            return _vm.doDeletePosition()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }