import dayjs from 'dayjs'
import { isDate, } from '@/helpers/commonfunctions';

export const MONTH_REGEX = /^(0[1-9]|1[0-2])[\D]*(\d{2,4})$/
export const DATE_REGEX = /^(0[1-9]|[12][0-9]|3[01])[\D]*(0[1-9]|1[0-2])[\D]*(\d{2,4})$/
export const RANGE_REGEX = /^(0[1-9]|[12][0-9]|3[01])[\D]*(0[1-9]|1[0-2])[\D]*(\d{2,4})[\D]*(0[1-9]|[12][0-9]|3[01])[\D]*(0[1-9]|1[0-2])[\D]*(\d{2,4})$/ 


function twoToFourDigitYear(year) {
  if (typeof year === 'number') {
    return year > 0 && year < 100 ? year + 2000 : year

  } else if (typeof year === 'string') {
    return year.length === 2 ? '20' + year : year

  } else {
    return year

  }
}

export function rangeToDayjs(value) {
  if (value?.from || value?.to) {
    const result = {
      from: value?.from && toDayjs(value?.from) || null,
      to: value?.to && toDayjs(value?.to) || null,
    }
    return result
  }

  const rangeResult = RANGE_REGEX.exec(value)
  if (!rangeResult) {
    return {
      from: null,
      to: null,
    }
  }

  const [, dayFrom, monthFrom, yearFrom, dayTo, monthTo, yearTo] = rangeResult;
  return {
    from: dayjs.utc({ year: twoToFourDigitYear(+yearFrom), month: +monthFrom - 1, day: +dayFrom }),
    to: dayjs.utc({ year: twoToFourDigitYear(+yearTo), month: +monthTo - 1, day: +dayTo }),
  }
}

/**
 * can parse any object into dayjs object
 * 
 * accepted types:
 * String, Date and dayjs itself 
 */
export function toDayjs(value) {
  if (value?.isValid?.()) {
    return value.clone()
  }

  if (typeof value !== 'string' && dayjs(value).isValid()) {
    return isDate(value) ? dayjs.utc({ year: value.getFullYear(), month: value.getMonth(), day: value.getDate(), }) : dayjs(value).utc();
  }

  const dateResult = DATE_REGEX.exec(value)
  if (dateResult) {
    const [, day, month, year] = dateResult;
    return dayjs.utc({ year: twoToFourDigitYear(+year), month: +month - 1, day: +day });
  }

  const monthResult = MONTH_REGEX.exec(value)
  if (monthResult) {
    const [, month, year] = monthResult;
    return dayjs.utc({ year: twoToFourDigitYear(+year), month: +month - 1, day: 1 });
  }

  const isoResult = dayjs(value)

  if(isoResult.isValid()) {
    return isoResult;
  }
}

export function isSame(a, b, period) {
  const aDayjs = toDayjs(a)
  const bDayjs = toDayjs(b)

  return aDayjs?.isSame(bDayjs, period)
}
