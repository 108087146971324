var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("FlexibleList", {
        attrs: {
          rows: _vm.rows,
          showThirdRow: "",
          linkInFirstRow: _vm.isEditable
        },
        on: { onRowClick: _vm.onRowClick },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function(row) {
              return [_vm._v(" " + _vm._s(row.reason) + " ")]
            }
          },
          {
            key: "value",
            fn: function(row) {
              return [_c("CurrencyLabel", { attrs: { value: row.aktWert } })]
            }
          },
          {
            key: "thirdRow",
            fn: function(row) {
              return [_vm._v(" " + _vm._s(row.date || "./.") + " ")]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }