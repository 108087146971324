<template>
  <BaseToolbarComboBox 
    label="Rahmengröße"
    :value="currentValue"
    :values="values"
    :disabled="disabled"
    @change="setTableBorder($event)"/>
</template>

<script>
import BaseToolbarComboBox from '../../base-components/BaseToolbarComboBox.vue';
import { BORDER_OPTIONS } from '../../custom-extensions/CustomTable';

export default {
  components: {
    BaseToolbarComboBox,
  },

  props: {
    editor: {
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    values() {
      return [ ...BORDER_OPTIONS ];
    },
    currentValue() {
      return BORDER_OPTIONS
        .map(val => val.value)
        .find(value => this.editor?.isActive('table', { border: value }));
    }
  },

  methods: {
    setTableBorder(border) {
      if (border) {
        this.editor?.chain().focus().setTableBorder(border).run();
      } else {
        this.editor?.chain().focus().unsetTableBorder().run();
      }
    }
  }

}

</script>
