var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vertical-stepper",
      class: {
        "vertical-stepper--collapsed": _vm.collapsed,
        "vertical-stepper--collapsable": _vm.showCollapse
      }
    },
    [
      _vm.showCollapse
        ? _c(
            "div",
            { staticClass: "vertical-stepper--collapse-toggle__container" },
            [
              _c(
                "button",
                {
                  staticClass:
                    "vertical-stepper--collapse-toggle btn-clear clickable",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.toggleCollapse()
                    }
                  }
                },
                [_c("PhCaretLeft", { attrs: { size: 24 } })],
                1
              )
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "vertical-stepper--items" }, [
        _c(
          "div",
          { staticClass: "vertical-stepper--items__wrap" },
          [
            _vm.stepsComputed && _vm.stepsComputed.length
              ? [
                  _c(
                    "SortableList",
                    {
                      attrs: { items: _vm.stepsComputed },
                      on: {
                        orderChanged: function($event) {
                          return _vm.onOrderChanged($event)
                        }
                      }
                    },
                    [
                      _c(
                        "ul",
                        {
                          staticClass: "list-bordered",
                          attrs: { "data-sortable-container": "" }
                        },
                        _vm._l(_vm.stepsComputed, function(step) {
                          return _c(
                            "li",
                            {
                              key: step.stepKey,
                              staticClass: "vertical-stepper--item",
                              attrs: { "data-sortable-item": "" }
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { display: "flex" } },
                                [
                                  _c("DynamicVerticalStepItem", {
                                    staticClass: "mr-2",
                                    attrs: {
                                      step: step,
                                      stepType: _vm.stepType,
                                      isCurrent:
                                        _vm.selectedStepKey === step.stepKey,
                                      showSaveSpinner:
                                        _vm.showSaveSpinner &&
                                        _vm.stepClicked === step.stepKey,
                                      collapsed: _vm.collapsed
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.onClickStop(step.stepKey)
                                      }
                                    }
                                  }),
                                  !_vm.collapsed
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "vertical-step__action-container ml-2"
                                        },
                                        [
                                          _c("ph-copy", {
                                            staticClass:
                                              "color-info vertical-stop__action mr-1 clickable",
                                            attrs: { weight: "fill", size: 22 },
                                            on: {
                                              click: function($event) {
                                                return _vm.copyStep(step)
                                              }
                                            }
                                          }),
                                          _c("ph-x", {
                                            staticClass:
                                              "color-danger vertical-stop__action mr-1 ml-1 clickable",
                                            attrs: { weight: "fill", size: 22 },
                                            on: {
                                              click: function($event) {
                                                return _vm.removeStep(step)
                                              }
                                            }
                                          }),
                                          _c("ph-list", {
                                            staticClass:
                                              "vertical-stop__action ml-1 clickable",
                                            attrs: {
                                              "data-sortable-item-handler": "",
                                              weight: "fill",
                                              size: 22
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm._l(_vm.getSubsteps(step), function(
                                substep,
                                subIndex
                              ) {
                                return _c("VerticalSubstepItem", {
                                  key: subIndex + _vm.lastUpdated,
                                  attrs: {
                                    isCurrent:
                                      _vm.selectedSubstepKey ===
                                      substep.substepKey,
                                    substep: substep,
                                    collapsed: _vm.collapsed
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onClickSubstep(
                                        step.stepKey,
                                        substep.substepKey
                                      )
                                    }
                                  }
                                })
                              })
                            ],
                            2
                          )
                        }),
                        0
                      )
                    ]
                  )
                ]
              : _c("GhostLoading", {
                  attrs: {
                    type: "block",
                    config: { block: { height: 24 } },
                    repeat: 1
                  }
                }),
            !_vm.collapsed
              ? _c(
                  "div",
                  {
                    staticClass: "vertical-step__icon-container clickable",
                    staticStyle: {
                      "font-size": "30px",
                      "background-color": "lightgrey"
                    },
                    on: {
                      click: function($event) {
                        return _vm.addStep()
                      }
                    }
                  },
                  [_c("ph-plus")],
                  1
                )
              : _vm._e()
          ],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }