<template>
  <div class="box__container">
    <div class="box__title">Maklervertrag</div>

    <div class="box__container" v-if="darfVersicherungMaklerauftragSehen">
      <DatePickerField label="Maklervertrag seit"
              isValueAsString
              :isComponentHalfSize="true"
              v-model="sonstiges.maklervertragErstelleDatum"
              disabled/>

      <DatePickerField label="Maklervertrag gekündigt"
              isValueAsString
              :isComponentHalfSize="true"
              v-model="sonstiges.maklervertragGekuendigtDatum"
              validateUntouched
              @input="addCustomerDataEdited('sonstiges')"/>  
    </div>

            

  </div>
</template>

<script>
import CUSTOMERDATA_TYPES from '@/store/customerData/types'
import { mapGetters } from 'vuex'
import CORE_TYPES from '@/store/core/types.js'

import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import persoenlicheDatenMixin from '@/components/persoenliche-daten/persoenliche-daten-mixin';
import { ROLES } from '@/router/roles.js'

export default {
  mixins: [persoenlicheDatenMixin, InteractiveHelpCommonsMixin],
  data() {
    return {
      ...this.initialState(),
    };
  },
  computed: {
    ...mapGetters({
      customerData: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_DATA,
      persons: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_ADDITIONAL_PERSONS,
      isMainPerson: CUSTOMERDATA_TYPES.GETTERS.IS_MAIN_PERSON,
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
      isBrokerOrByPass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
    isEditable() {
      return this.customerData?.isEditable;
    },
    isFA() {
      return !!this.loginData?.rights?.isFA;
    },


  },
  methods: {
    initialState() {
      return {
        sonstiges: {
          maklervertragErstelleDatum: '',
          maklervertragGekuendigtDatum: '',
        },
      };
    },
    updateGroupsIntoData() {
      this.updateGroupIntoData('sonstiges');
    },    
  },
  components: {
    DatePickerField
  },
  mounted() {
    this.updateGroupsIntoData();
  },
}
</script>

<style scoped>

</style>