import MSC_NEWS_TYPES from './types';

export default {
  [MSC_NEWS_TYPES.GETTERS.MSC_NEWS](state) {
    return state.mscNews || [];
  },

  [MSC_NEWS_TYPES.GETTERS.MSC_NEWS_COUNT](state) {
    return state.mscNewsCount || 0;
  },

  [MSC_NEWS_TYPES.GETTERS.MSC_LAST_NEWS](state) {
    const amountOfNews = 5
    return state.mscNews?.slice(0, amountOfNews) || []
  },

  [MSC_NEWS_TYPES.GETTERS.FK_EVENTS](state) {
    return state.fkEvents || [];
  },

  [MSC_NEWS_TYPES.GETTERS.FK_EVENTS_LOADING](state) {
    return state.fkEventsLoading;
  },

  [MSC_NEWS_TYPES.GETTERS.MSC_HOME_LINK](state) {
    return state.mscHomeLink
  }

}