<template>
    <div :class="{ 'box__container-wrap': isOverview, }">
        <div :class="{ 'box__container-rows': isOverview, }">
            <Table
                v-if="rows.length"
                :title="tableTitle"
                tableId="00b54be2-f6be-4d57-a0b7-c4a8b0dfe28b"
                :headers="headers"
                :rows="rows"
                :rowsPerPage="rowsPerPage"
                :hidePagination="isOverview"
                @click-event_title="openLink"
                :mobileConfig="mobileConfig"
            >
                <template #img="row">
                    <BaseImage v-if="row.imgurl"
                        class="logo"
                        :src="proxyContent(row.imgurl)"
                        :placeholder="imagePlaceholder"
                    />
                </template>
            </Table>
            <GhostLoading v-else-if="loading" type="table" :title="tableTitle" :config="{ table: { rows: 5 } }" />
            <NoData v-else :title="tableTitle" :noIcon="isOverview" />
        </div>

        <div class="bottom-row text-right mt-3" v-if="!loading && isOverview && !$isSmallScreen">
            <router-link :to="{name: 'fk-events'}">
                weitere...
            </router-link>
        </div>
    </div>
</template>

<script>
import Table from "@/components/table2/Table.vue";
import {TextColumn, DateColumn, SlotColumn} from "@/components/table2/table_util.js";
import { mapGetters } from 'vuex';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import MSC_NEWS_TYPES from '@/store/mscnews/types.js';
import CORE_TYPES from '@/store/core/types.js'
import BaseImage from '@/components/core/BaseImage.vue';

export default {
    props: {
        events: {
            type: Array,
            default: () => []
        },
        showTitle: {
            type: Boolean,
            default: true,
        },
        isOverview: {
            type: Boolean,
            default: false,
        },
        rowsPerPage: {
            type: Number,
            default: 5,
        },
        title: {
            type: String,
            default: 'FondsKonzept Events',
        }
    },
    components: {
        Table,
        GhostLoading,
        NoData,
        BaseImage,
    },
    data() {
        return {
            defaultFilters: {
                CHECK_ALLES_ANZEIGEN: true,
                INPUT_MONTH_UNBESTAETIGT: '1',
            },
            imagePlaceholder: {
                width: '48px',
                height: '48px',
            },
            MAX_OVERVIEW_COUNT: 5,
        }
    },
    mounted() {
        this.$store.dispatch(MSC_NEWS_TYPES.ACTIONS.GET_FK_EVENTS);
    },
    computed: {
        ...mapGetters({
            isLoading: MSC_NEWS_TYPES.GETTERS.FK_EVENTS_LOADING,
            proxyContent: CORE_TYPES.GETTERS.PROXY_CONTENT,
        }),
        loading() {
            return this.isLoading;
        },
        tableTitle() {
            return (this.showTitle ? this.title : '') || '';
        },
        headers() {
            return { 
                lockedLeft: [
                    SlotColumn('img', ''),
                    TextColumn('event_title', 'Events').makeLink(),
                ],
                center: [
                    DateColumn('startdateFormatted', 'Beginn'),
                    DateColumn('enddateFormatted', 'Ende'),
                    TextColumn('category', 'Kategorie'),
                ],
                lockedRight: [
                ],
            }
        },
        rows() {
            if(this.isOverview) {
                return this.events.slice(0, this.MAX_OVERVIEW_COUNT) || []
            }
            return this.events || [];
        },
        mobileConfig() {
          return {
              title: '',
              headers: ['event_title','startdateFormatted','enddateFormatted','category'],
          }
        }
    },
    methods: {
        openLink(row) {
             if (row?.event_url) {
                const elem = document.createElement('a');
                elem.setAttribute('href', row.event_url);
                elem.target = '_blank';
                elem.style.visibility = 'hidden';
                document.body.appendChild(elem);
                elem.click();
                setTimeout(() => document.body.removeChild(elem), 0);
            }
        },
    }
}
</script>

<style scoped>
.logo {
  margin: 0 8px 0 0;
  max-width: 48px;
  height: auto;
}
</style>