<template>
  <div class="eventsnum event__container" :class="[{'hidden': ghost}]">
    <ExpandedEventView :event="verticalcontent.event">
      <div
        data-event-view
        :class="['smallletters', 'event-cell', 'clickable', { 'selected': isSelected, }]"
        @click="setSelectedEvent"
        @dblclick="ondouble"
        :style="eventStyle"
      >
        <div class="event-cell-before" :style="{ 'background-color': verticalcontent.event.color}"></div>
        <div class="event-label">
          <span>{{verticalcontent.event.label}}</span>
        </div>
        <span v-if="verticalcontent.event.repetition" class="repetition-icon"><PhArrowsClockwise size="16" /></span>
      </div>
    </ExpandedEventView>
  </div>
</template>
<script>
import CALENDAR_TYPES from "@/store/calendar/types";
import CUSTOMER_FILTERS_TYPES from "@/store/customerFilters/types";
import LOG_TYPES from '@/store/log/types';
import { mapGetters } from "vuex";
import { PhArrowsClockwise,  } from 'phosphor-vue';
import ExpandedEventView from '@/components/calendar/ExpandedEventView.vue';

export default {
  props: {
    verticalcontent: {
      type: Object,
      default: () => {},
      required: true
    },
    dimensions: {
      type: Array,
      default: () => {},
      required: true
    },
    index: {
      type: Number,
      default: () => 0,
      required: true
    },
    contentarray: {
      type: Array,
      default: () => [],
      required: true
    },
    ghost: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      colorsArray: [
        "var(--color-active)",
        "var(--color-warning)",
        "var(--color-link)"
      ],
    };
  },
  computed: {
    ...mapGetters({
      getSelectedEvent: CALENDAR_TYPES.GETTERS.GET_SELECTED_APPOINTMENT_DATA,
    }),
    isSelected() {
      return this.getSelectedEvent?.appointment?.sid 
        && this.getSelectedEvent?.appointment?.sid == this.verticalcontent?.event?.id;
    },
    getEventLabel() {
      if (this.verticalcontent.event !== null)
        return (
          this.verticalcontent.event.startTime
        );
      return "";
    },
    getBackground() {
      let checkednum = Number(this.verticalcontent.eventID);
      if (this.verticalcontent.event !== null)
        return this.colorsArray[checkednum % 3];
      return "";
    },
    myDimensions() {
      return this.dimensions[this.index] || {
        height: '1',
        margin: '0'
      };
    },
    isVisibleDescription() {
      const height = this.myDimensions.height && this.myDimensions.height.match(/\d+/) || [0];
      return +height[0] >= 3;
    },
    lineClamp() {
      const height = this.myDimensions.height && this.myDimensions.height.match(/\d+/) || [0];
      const lineClamp = height > 3 ? height - 3 : 1;
      return lineClamp;
    },
    expandedViewTime() {
      const { startTime, endTime, } = this.verticalcontent?.event || {};
      return `${startTime || ''}-${endTime || ''}`;
    },
    eventStyle() {
      const isSelected = this.getSelectedEvent?.appointment?.sid == this.verticalcontent?.event?.id;
      return {
        marginTop: this.myDimensions?.margin,
        height: this.myDimensions?.height,
        'background-color': isSelected ? this.verticalcontent?.event?.color : this.verticalcontent?.event?.lighterColor,
        ...this.lineClampStyle,
      };
    },
    lineClampStyle() {
      return {
        '-webkit-line-clamp': this.lineClamp,
      };
    },
  },
  methods: {
    ondouble() {
      this.$store.dispatch(LOG_TYPES.ACTIONS.LOG, 'ondouble');
    },
    setSelectedEvent(e) {
      e.stopPropagation();
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_BETEILIGTE_FOR_UPDATE, []),
      this.$store.commit(CUSTOMER_FILTERS_TYPES.MUTATIONS.RENEW_SELECTED_CUSTOMERS),

      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_CLICKED_ON_EVENT, true);
      this.$store.dispatch(
        CALENDAR_TYPES.ACTIONS.RETRIEVE_SELECTED_APPOINTMENT,
        this.verticalcontent.event.id
      );
      
      this.$emit('editEvent', this.verticalcontent.event);
    },
  },
  components: {
    ExpandedEventView,
    PhArrowsClockwise,
  },
};
</script>
<style scoped>
.smallletters {
  font-size: 1em;
}
.smallletters,
.smallletters div {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.event-cell {
  color: var(--color-text);
  background-color: var(--color-info-background);
  padding: 4px 0 4px 8px;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  min-height: 1.8rem;
  cursor: pointer;
  line-height: 1.2em;
  transition: opacity .25s ease;
}

@media (prefers-color-scheme: dark) {
  .event-cell {
    color: var(--color-box);
  }
}

.event-cell-before {
  display: block;
  content: " ";
  background: var(--color-primary);
  height: 110%;
  width: 4px;
  position: absolute;
  left: 0;
  top: 0;
}
.event-label {
  padding: 0 0 .25rem;
}
.repetition-icon {
  background: inherit;
  padding: 0 2px 4px;
  position: absolute;
  right: 0;
  bottom: 0;
}
.event-cell.selected {
  background: var(--color-primary);
  color: var(--color-box);
}
.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
}
.eventsnum  {
  min-width: 0;
  position: relative;
}
.hidden {
  pointer-events: none;
}
.hidden div {
  opacity: 0;
  width: 0 !important;
  height: 0 !important;
}
.hidden .event-cell-before,
.hidden .event-cell {
  background: transparent!important;
}
</style>
