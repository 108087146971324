<template>
  <div class="persoenliche-daten-view">
    <PageHeaderTitleNavigation 
        title="Vermittlerdaten" 
        :actions="headerActions"
        @action-NEW_BEMERKUNG="createBemerkung"
        @action-NEW_BESUCHSBERICHT="createBesuchsbericht"
    />

    <StepperForm stepType="tab" stepperName="customer-data" :stepperMediator="tabsStepperMediator" :hasFertig="false"
      :selectedStepKey="step" :selectedSubstepKey="substep"
      @set-step="navigateToPath($event)"
      @set-substep="navigateToSubstepPath($event)"
      @handleOtherActions="handleOtherActions"
      :additionalActionsForMobile="baseModalActions">
      <template #contentTemplate>
        <router-view></router-view>
      </template>
    </StepperForm>
  </div>
</template>

<script>
  import BROKERDATA_TYPES from '@/store/brokerData/types'
  import { mapGetters } from 'vuex'
  import { StepperMediator } from '@/components/stepper/utils';

  import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
  import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
  import StepperForm from '@/components/stepper/StepperForm.vue';
  import HinweiseUndFehler from '@/components/core/HinweiseUndFehler.vue';

  import { MAP_VERMITTLER_ROUTE_PATH_TO_SUBSTEPS } from './vermittler-daten-steps-utils';
  import vermittlerDatenMixin from './vermittler-daten-mixin';
  import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';
  import { BaseModalSimpleAction } from '@/components/core/base-modal-actions/base-modal-actions-utils';
  import CORE_TYPES from '@/store/core/types'
  import EVENT_BUS, { TERMINE_NEUE_AUFGABE, } from '@/event-bus';

  const VALUE_TYP = 'makler';

  export default {
     beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück Vermittlerdaten', 
      to,
      from,
    });

    next();
  },
    mixins: [vermittlerDatenMixin],
    data() {
      return {
        VALUE_TYP,
        tabs: [],
        stepperCollapsed: false,

      };
    },
    computed: {
      ...mapGetters({
        brokerDataConfig: BROKERDATA_TYPES.GETTERS.GET_BROKER_DATA_CONFIG,
        isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
      }),
      tabsStepperMediator() {
        return new StepperMediator(this.tabs);
      },
      headerActions() {
        return [
            PageHeaderSimpleAction('NEW_BESUCHSBERICHT', 'Neuer Besuchsbericht').withVisible(() => this.step.includes('besuchsberichte')),
            PageHeaderSimpleAction('NEW_BEMERKUNG', 'Neue Bemerkung').withVisible(() => (this.step.includes('bemerkungen') || this.step.includes('besuchsberichte'))),
        ];
      },
      baseModalActions() {
        const actions =  [];

        if (/activities-termine$/.test(this.step) && this.isBrokerOrBypass) {
          actions.push(new BaseModalSimpleAction('NEUE_AUFGABE', 'Neue Aufgabe'));
        }

        return actions;
      },
    },
    mounted() {
      Promise.all([this.getBrokerData(), this.getBrokerConfigData()]).then(this.setTabs());
    },

    methods: {
      handleOtherActions(actionData) {
        if (actionData?.key === 'NEUE_AUFGABE') {
          EVENT_BUS.$emit(TERMINE_NEUE_AUFGABE);
        }
      },      
      createBemerkung() {
        this.$router.push({path: `/intern/bemerkung`, query: {typ: VALUE_TYP}});
      },
      createBesuchsbericht(row) {
        this.$addBreadcrumb({
          fullPath: this.$route.fullPath,
          breadcrumb: 'Besuchsbericht',
        });

        this.$router.push(this.$route.path + `/besuchsbericht`)
      },
      getBrokerData() {
        this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.GET_BROKER_DATA)
      },
      async getBrokerConfigData() {
        await this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.GET_BROKER_DATA_CONFIG)
      },
      setTabs() {
        this.tabs = this.findTabs();
      },
      findTabs() {
        const extraKey = `${this.title === 'Firma' ? 'firma' : 'privat'}`;
        const steps = this.$appNavigation.currentTabMenu
          .map((menu, i) => {
            const key = `${menu.path}`;
            return {
              stepKey: menu.path,
              label: menu.label,
              current: false,
              totalProgress: i + 1,
              substeps: MAP_VERMITTLER_ROUTE_PATH_TO_SUBSTEPS[key] || MAP_VERMITTLER_ROUTE_PATH_TO_SUBSTEPS[`${key}:${extraKey}`] || null,
            };
          });

        return steps.map(step => {
          return {
            ...step,
            substeps: step.substeps?.map(substep => ({
              ...substep,
            }))
          };
        });
      },
    },
    watch: {
      title(newTitle, oldTitle) {
        if (newTitle !== oldTitle) {
          this.setTabs();
        }
      }
    },
    
    components: {
      OptionMenu,
      PageHeaderTitleNavigation,
      StepperForm,
      HinweiseUndFehler,
    }
  }
</script>

<style scoped>
  .router-link-active {
    font-weight: bold;
  }

  .uk-link {
    color: var(--color-text)
  }
</style>