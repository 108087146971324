<template>
  <InputField 
    v-model="iban" :validation_path="validation_path" :label="label" 
    :disabled="disabled" :isComponentHalfSize="isComponentHalfSize" 
    @input="checkIbanAndUpdateValue($event)"
    @change="showError"
    @focus="isInputActive = true"
  />
</template>

<script>

import axios from 'axios';
import validator from '@/mixins/validator';
import InputField from '@/components/core/forms/InputField.vue';

export default {
  mixins: [validator],
  validators: {
  },
  components: {
    InputField,
  },
  props: {
      label: {
          type: String,
      },
      type: {
        type: String
      },
      componentData: {
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      validation_path: {
        type: String
      },
      isComponentHalfSize: {
        type: Boolean,
        default: false,
      },
  },
  data() {
    return {
      iban: '',
      errorMessage: null,
      isInputActive: true,
      timeoutRef: null,
    }
  },
  watch: {
    componentData: {
      handler(value) {
        this.iban = value;
      },
      immediate: true,
    },
  },
  methods: {
    checkIbanAndUpdateValue(value) {
      if (value && this.type) {
        this.errorMessage = null

        clearTimeout(this.timeoutRef)

        this.timeoutRef = setTimeout(() => {    
          axios.get(`${process.env.VUE_APP_API}/../mrscheckformat?type=${this.type}&value=${value}`).then(response => {
            if (response.data) {
              this.errorMessage = response.data.text

              if (response.data.text) {
                
                if (!this.isInputActive) {
                  this.$pushErrors(this.validation_path, response.data.text);
                }
                
              } else {
                const errors = this.validation.getErrors(this.validation_path, false)

                if (errors && errors.length) {
                  errors.forEach(error => this.validation.removeError(this.validation_path, error))
                }

                this.$emit('change',  value);
              }
            }
          });
        }, 300);
      } else {
        this.$emit('change',  value);
      }
    },
    showError() {
      this.isInputActive = false
      
      if (this.errorMessage) {
        this.$pushErrors(this.validation_path, this.errorMessage);
      }
    }
  },
}
</script>

<style>

</style>