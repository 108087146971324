<template>
  <div class="recorded-items">
    <PageHeaderTitleNavigation
      title="Gespeicherte Elemente bearbeiten"
    />

    <div class="recorded-items" v-if="tutorialRecordedItems && tutorialRecordedItems.length">
      <div class="box__container">
        <div class="col-12 col-md-6">
          <InputField
            label="Titel"
            v-model="tutorialTitle"
            @change="updateTitle()"
          />
        </div>
      </div>

      <div v-bind:key="item.step" v-for="item in tutorialRecordedItems">
        <div class="box__container list-item items-container">
          <p><BaseButton @click="confirmDeleteItem(item)">Entfernen</BaseButton></p>
          <p>
            <span>Routerpfad:</span>
            {{ item.routerPath }}
          </p>

          <InputTextArea
            label="Meldung"
            v-model="item.helpMessage"
            @change="updateElement(item)"
          >
          </InputTextArea>

          <p>
            <span>Vorschau:</span>
          </p>          
          <img :src="item.canvas" class="recorded-item-img" />
        </div>
      </div>
    </div>
    <div class="recorded-items" v-if="!tutorialRecordedItems || !tutorialRecordedItems.length">
      No items recorded
    </div>

    <BaseModal @onConfirmButton="removeElement()" ref="deleteItemConfirmation" modalTitle="Schritt löschen" labelButtonConfirm="Löschen">
      Soll das Element gelöscht werden?
    </BaseModal>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CORE_TYPES from "../store/core/types";
import InteractiveHelpCommonFunctions from "@/assets/mixins/interactivehelpcommonfunctions.js";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import InteractiveHelpMixin from "@/assets/mixins/interactivehelpmixins.js";
import HelpRecordingMixin from "@/assets/mixins/helprecordingmixin.js";
import BaseButton from "@/components/core/BaseButton.vue";
import InputTextArea from "@/components/core/forms/InputTextArea.vue";
import InputField from "@/components/core/forms/InputField.vue";
import BaseModal from "@/components/core/BaseModal.vue";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';

export default {
  mixins: [
    InteractiveHelpCommonsMixin,
    InteractiveHelpMixin,
    HelpRecordingMixin,
    InteractiveHelpCommonFunctions,
  ],
  components: {
    BaseButton,
    InputTextArea,
    InputField,
    BaseModal,
    OptionMenu,
    PageHeaderTitleNavigation,
  },
  data: function () {
    return {
      tutorialTitle: "",
      selectedItem: null
    };
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu Gespeicherte Elemente bearbeiten', 
      to,
      from,
      breadcrumb: 'Gespeicherte Elemente bearbeiten',
    });

    if (this.tutorialRecordedItems && this.tutorialRecordedItems.length) {
      this.resendTutorial();
    }
    this._resetTutorial();
    next();
  },
  mounted: function () {
    this.tutorialTitle = this.recordingTutorialTitle;
    this.$nextTick(() => {
      window.setTimeout(function () {
          this.highlightElementsWithTidAttribute(false);
      }.bind(this), 1900); 
    });


  },
  computed: {
    ...mapGetters({
      tutorialRecordedItems: CORE_TYPES.GETTERS.TUTORIAL_RECORDED_ITEMS,
      recordingTutorialTitle: CORE_TYPES.GETTERS.RECORDING_TUTORIAL_TITLE,
      userid: CORE_TYPES.GETTERS.GET_USER_ID,
      tutorialLastId: CORE_TYPES.GETTERS.RECORDING_TUTORIAL_LAST_ID,
      recordingTutorialRoute: CORE_TYPES.GETTERS.RECORDING_TUTORIAL_ROUTE,
      tutorialUserType: CORE_TYPES.GETTERS.RECORDING_TUTORIAL_USER_TYPE,
    }),
  },
  methods: {
    confirmDeleteItem(whatItem) {
      this.selectedItem = whatItem;
      this.$refs.deleteItemConfirmation.open();
    },
    removeElement() {
      this.$store.dispatch(CORE_TYPES.ACTIONS.REMOVE_RECORDED_ITEM, this.selectedItem);

      if (
        !this.tutorialRecordedItems.length ||
        this.tutorialRecordedItems.length === 0
      ) {
        this.elementsArray = [];
        this.slideItems = [];
      }
    },
    updateElement: function (item) {
      this.$store.dispatch(CORE_TYPES.ACTIONS.UPDATE_RECORDED_ITEM, item);
    },
    updateTitle: function () {
      this.$store.dispatch(CORE_TYPES.ACTIONS.RECORDING_TUTORIAL_TITLE, {
        tutorialTitle: this.tutorialTitle,
      });
    },
    resendTutorial() {
      let items = this.tutorialRecordedItems.map(function (item) {
        return {
          id: item.id,
          canvas: item.canvas,
          element: null,
          helpMessage: item.helpMessage,
          helpPath: item.helpPath,
          highlightClass: item.highlightClass,
          position: item.position,
          routerPath: item.routerPath,
          stepKey: item.stepKey,
          tooltipClass: item.tooltipClass,
          autoClick: item.autoClick,
          noClick: item.noClick,
        };
      });

      this.$store.dispatch(CORE_TYPES.ACTIONS.SEND_TUTORIAL, {
        id: this.tutorialLastId,
        title: this.recordingTutorialTitle,
        routerPath: this.recordingTutorialRoute,
        items: items,
        userType: this.tutorialUserType,
      });
    },
  },
};
</script>

<style scoped>
.items-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}
.list-item {
  text-align: left;
}

p span {
  font-weight: bold;
}

img.recorded-item-img {
  height: auto;
  max-width: 100%;
}




</style>
