<template>
  <div>
    <GhostLoading v-if="loading" type="table" />
    <NoData v-else-if="!rows || !rows.length" />
    <Table
      v-else
      :headers="headers"
      :rows="rows"
      :rowsPerPage="rows.length"
      :hidePagination="true"
      rowId="isin"
      title="Wertpapiere"
      tableId="vvstrategienwp"
      @action-FACTSHEET="openFactsheet"
      @action-comment="openCommentModal"
      @action-delete="removeWertpapier"
    >
      <template v-slot:sonstige="row">
        <div v-if="row.sonstige && row.sonstige.length">
          <ul>
            <div v-for="(line, index, last) in row.sonstige" :key="index">
              <li :class="row.bgColor">{{ line }} {{ last }}</li>
            </div>
          </ul>
        </div>
        <div v-else></div>
      </template>

      <template v-slot:anteile="row">
        <InputField
          :value="row.anteile"
          :id="'anteile' + row.id"
          type="percent"
          :precision="2"
          v-if="row.anteilEditable && !disabled"
          @change="
            $emit('updateVarianteStore', {
              componentId: 'anteile',
              value: formatNumber($event),
              positionId: row.id,
            })
          "
        />
        <div :class="row.anteileColor" v-else>
          {{ row.anteile + "%" || "" }}
        </div>
      </template>
    </Table>

    <BaseModal
      ref="commentModal"
      labelButtonConfirm="Speichern"
      labelButtonCancel="Abbrechen"
      :showCloseButton="false"
      @onConfirmButton="saveComment"
      @onCancelButton="closeCommentModal"
      @onEscButton="closeCommentModal"
    >
      <template v-slot:modalTitle> Änderungs-Kommentar </template>
      <div>
        <div class="mb-2">
          Änderungskommentar für Position
          <span class="font-bold">{{ selectedRow && selectedRow.name }}</span>
          (ISIN: {{ selectedRow && selectedRow.isin }})
        </div>
        <div v-if="selectedRow && selectedRow.altePosAnteil">
          <div>
            alter Anteil: {{ selectedRow && selectedRow.altePosAnteil }}%
          </div>
          <div>neuer Anteil: {{ selectedRow && selectedRow.anteile }}%</div>
        </div>
        <div v-else>Anteil: {{ selectedRow && selectedRow.anteile }}%</div>
        <div>Bitte verwenden sie kein %-Zeichen im Kommentar.</div>
        <InputTextArea v-model="comment" />
      </div>
    </BaseModal>
  </div>
</template>

<script>
import VV_TYPES from "@/store/vermoegensverwaltung/types";
import { formatNumber } from "@/helpers/number-formatter.js";
import mixin from "@/mixins/vermoegensverwaltung/vv-mixin.js";
import Table from "@/components/table2/Table.vue";
import {
  TextColumn,
  NumberColumn,
  SlotColumn,
  SimpleAction,
  PercentageColumn,
  ActionColumn,
  oldToNewColumn,
} from "@/components/table2/table_util.js";
import NumberCell from "@/components/table2/cellTypes/NumberCell.vue";
import InputTextArea from "@/components/core/forms/InputTextArea.vue";
import InputField from "@/components/core/forms/InputField.vue";
import BaseModal from "@/components/core/BaseModal.vue";
import NoData from "@/components/core/NoData.vue";
import GhostLoading from "@/components/core/loading/GhostLoading.vue";

import { openLink } from "@/link-resolvers";
import { ResolverId } from "@/link-resolvers/types";

export default {
  mixins: [mixin],
  components: {
    Table,
    InputTextArea,
    InputField,
    BaseModal,
    NoData,
    GhostLoading,
    NumberCell,
  },
  props: {
    positions: {
      type: Array,
      default: () => [],
    },
    extraHeaders: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    isEditor: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    versionId: {
      type: String,
      default: null,
    },
    showKennzahlen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      extraHeadersConverted: {
        lockedLeft: [],
        center: [],
        lockedRight: [],
      },
      selectedRow: null,
      comment: "",
    };
  },
  mounted() {
    this.updateWarnings(false);
    this.extraHeaders
      .filter((h) => h.visible)
      .forEach((header) => {
        switch (header.lockedColumn) {
          case "left":
            this.extraHeadersConverted.lockedLeft.push(
              oldToNewColumn(
                header.key,
                header.label,
                header.dataType,
                header.cellProps,
                header.minWidth,
                header.clickable,
                header.sortable || true
              )
            );
            break;
          case "right":
            this.extraHeadersConverted.lockedRight.push(
              oldToNewColumn(
                header.key,
                header.label,
                header.dataType,
                header.cellProps,
                header.minWidth,
                header.clickable,
                header.sortable || true
              )
            );
            break;
          default:
            const newHeader = oldToNewColumn(
              header.key,
              header.label,
              header.dataType,
              header.cellProps,
              header.minWidth,
              header.clickable,
              header.sortable || true
            );

            newHeader.addCellProps({ lineClamp: 4 });

            // make negative numbers red
            if (newHeader && newHeader.rowElement == NumberCell) {
              newHeader.setColorFn((row) =>
                row[newHeader.key] < 0 ? "color-danger" : null
              );
            }

            // make fonds ampel sortable
            if (newHeader && newHeader.key === "fonds_ampel") {
              newHeader.makeSortable((ampel) =>
                ampel === "ampel-gruen"
                  ? 4
                  : ampel === "ampel-gelb"
                  ? 3
                  : ampel === "ampel-rot"
                  ? 2
                  : 1
              );
            }

            this.extraHeadersConverted.center.push(newHeader);
            break;
        }
      });
  },
  computed: {
    hasCommentField() {
      return (
        this.isEditor &&
        !this.disabled &&
        this.positionRows.some((pos) => pos.canComment)
      );
    },
    positionRows() {
      return this.positions || [];
    },
    anteileSum() {
      return this.positionRows
        ?.map((r) => parseInt(r.anteile) || 0)
        .reduce((a, b) => a + b, 0);
    },
    headers() {
      if (this.showKennzahlen) return this.extraHeadersConverted;
      else {
        const headers = {
          lockedLeft: [],
          center: [
            TextColumn("isin", "ISIN")
              .makeAlwaysVisible()
              .setColorFn((row) => row?.bgColor),
            TextColumn("name", "Wertpapiername").setColorFn((row) => row?.bgColor),
            TextColumn("gesellschaft", "Gesellschaft").setColorFn(
              (row) => row?.bgColor
            ),
            TextColumn("ea", "Ertragsart").setColorFn((row) => row?.bgColor),
            TextColumn("art", "Wertpapier-Art").setColorFn(
              (row) => row?.bgColor
            ),
            TextColumn("richtbandbr", "Richtbandbreite-Kategorie").setColorFn(
              (row) => row?.bgColor
            ),
            TextColumn("keuer", "Kenntnisse & Erfahrungen").setColorFn(
              (row) => row?.bgColor
            ),
            TextColumn("zeithorizont", "Zeithorizont").setColorFn(
              (row) => row?.bgColor
            ),
            TextColumn("anlageziele", "Anlageziele").setColorFn(
              (row) => row?.bgColor
            ),
            TextColumn("verlust", "Verlustrisiko").setColorFn(
              (row) => row?.bgColor
            ),
            SlotColumn("sonstige", "Sonstige").setColorFn(
              (row) => row?.bgColor
            ),
            NumberColumn("sri", "SRI", 0).setColorFn((row) => row?.bgColor),
            SlotColumn("anteile", "eingestellte Gewichtung", 100)
              .withConstantFooter((row) =>
                100 - row == 0
                  ? ""
                  : 100 - row < 0
                  ? `<span class="color-danger text-bold">zu viel ${
                      row - 100
                    }&nbsp;%</span>`
                  : `<span class="color-danger text-bold">fehlend ${
                      100 - row
                    }&nbsp;%</span>`
              )
              .withSumFooter("percent")
              .makeAlwaysVisible()
              .setColorFn((row) => row?.anteileColor),
            PercentageColumn(
              "anteileAktuell",
              "Gewichtung aktuell",
              2
            ).setColorFn((row) => row?.bgColor),
          ],
          lockedRight: [ActionColumn("actions", "")],
        };

        if (
          !this.showKennzahlen &&
          this.rows?.some((row) => row.anteileTooltip)
        ) {
          headers.center.splice(
            14,
            0,
            TextColumn("anteileTooltip", "Status Gewichtung").setColorFn(
              (row) => row?.anteileColor
            )
          );
        }

        return headers;
      }
    },
    rows() {
      const result = !this.positionRows
        ? []
        : this.positionRows.map((pos) => {
            const actions = [SimpleAction("FACTSHEET", "PhFile", "Factsheet")];
            if (this.hasCommentField) {
              actions.push(
                SimpleAction("comment", "PhFileText", "Änderungs-Kommentar")
              );
            }
            if (pos.anteilEditable) {
              actions.push(SimpleAction("delete", "PhTrash", "Löschen"));
            }
            return {
              ...pos,
              actions: actions,
            };
          });
      return result;
    },
    loading() {
      return this.isLoading;
    },
    commentModalLabel() {
      return `Änderungskommentar für Position ${this.selectedRow?.name} (ISIN: ${this.selectedRow?.isin})`;
    },
  },
  methods: {
    formatNumber(value) {
      return formatNumber(value);
    },
    openCommentModal(row) {
      if (!this.disabled) {
        this.selectedRow = row;
        this.comment = row.comment;
        this.$refs.commentModal.open();
      }
    },
    closeCommentModal() {
      this.selectedRow = null;
      this.$refs.commentModal.close();
    },
    saveComment() {
      if (this.selectedRow && !this.disabled) {
        this.selectedRow.comment = this.comment;
        this.$store.dispatch(VV_TYPES.ACTIONS.SAVE_COMMENT, {
          id: this.selectedRow.id,
          comment: this.comment,
        });
      }
    },
    openFactsheet(pos) {
      openLink(ResolverId.Factsheet, {
        type: "FACTSHEET",
        isin: pos.isin,
        lagerstelle: pos.gesellschaftId || "",
      });
    },
    removeWertpapier(data) {
      if (data?.id) {
        this.$confirmModal({
          title: "Wertpapier aus der Liste entfernen",
          message: `Soll das Wertpapier "${data?.name}" ISIN: ${data.isin} wirklich entfernt werden?`,
          labelButtonConfirm: "Löschen",
        }).then(() => {
          this.$store.dispatch(VV_TYPES.ACTIONS.DELETE_WERTPAPIER, {
            positionId: data.id,
            versionId: this.versionId,
          });
        });
      }
    },
  },
};
</script>

<style>
</style>