import TYPES from './types';

export default {

  
  [TYPES.GETTERS.RESULT](state) {

    return state.RESULT;
  },

}
