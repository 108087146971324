var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "modal",
    attrs: { modalTitle: "Einstellung Zugriff" },
    on: {
      onConfirmButton: function($event) {
        return _vm.emitChanged()
      },
      close: function($event) {
        return _vm.onClose()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function() {
          return [
            _c(
              "div",
              [
                _c("div", { staticClass: "box__title" }, [_vm._v("Kunde")]),
                _c("InputToggleSwitch", {
                  staticClass: "p-0",
                  attrs: { label: "Angemeldet als Kunde", inLineLabel: "" },
                  model: {
                    value: _vm.configPermissionForm.context["KUNDE:KUNDE"],
                    callback: function($$v) {
                      _vm.$set(
                        _vm.configPermissionForm.context,
                        "KUNDE:KUNDE",
                        $$v
                      )
                    },
                    expression: "configPermissionForm.context['KUNDE:KUNDE']"
                  }
                }),
                _vm.configPermissionForm.context["KUNDE:KUNDE"]
                  ? _c("InputChips", {
                      attrs: {
                        label: "Nur Erlaubte Kunden",
                        supportCustomValue: ""
                      },
                      model: {
                        value: _vm.configPermissionForm.whitelist["KUNDE"],
                        callback: function($$v) {
                          _vm.$set(
                            _vm.configPermissionForm.whitelist,
                            "KUNDE",
                            $$v
                          )
                        },
                        expression: "configPermissionForm.whitelist['KUNDE']"
                      }
                    })
                  : _vm._e(),
                _vm.configPermissionForm.context["KUNDE:KUNDE"]
                  ? _c("InputChips", {
                      attrs: {
                        label: "Nicht erlaubte Kunden",
                        supportCustomValue: ""
                      },
                      model: {
                        value: _vm.configPermissionForm.blacklist["KUNDE"],
                        callback: function($$v) {
                          _vm.$set(
                            _vm.configPermissionForm.blacklist,
                            "KUNDE",
                            $$v
                          )
                        },
                        expression: "configPermissionForm.blacklist['KUNDE']"
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c("hr"),
            _c(
              "div",
              [
                _c("div", { staticClass: "box__title" }, [_vm._v("Makler")]),
                _c("InputToggleSwitch", {
                  staticClass: "p-0",
                  attrs: { label: "Angemeldet als Makler", inLineLabel: "" },
                  model: {
                    value: _vm.configPermissionForm.context["MAKLER:MAKLER"],
                    callback: function($$v) {
                      _vm.$set(
                        _vm.configPermissionForm.context,
                        "MAKLER:MAKLER",
                        $$v
                      )
                    },
                    expression: "configPermissionForm.context['MAKLER:MAKLER']"
                  }
                }),
                _c("InputToggleSwitch", {
                  staticClass: "p-0",
                  attrs: {
                    label: "Kundensicht, angemeldet als Makler",
                    inLineLabel: ""
                  },
                  model: {
                    value: _vm.configPermissionForm.context["MAKLER:KUNDE"],
                    callback: function($$v) {
                      _vm.$set(
                        _vm.configPermissionForm.context,
                        "MAKLER:KUNDE",
                        $$v
                      )
                    },
                    expression: "configPermissionForm.context['MAKLER:KUNDE']"
                  }
                }),
                _vm.configPermissionForm.context["MAKLER:MAKLER"] ||
                _vm.configPermissionForm.context["MAKLER:KUNDE"]
                  ? _c("InputChips", {
                      attrs: {
                        label: "Nur Erlaubte Makler",
                        supportCustomValue: ""
                      },
                      model: {
                        value: _vm.configPermissionForm.whitelist["MAKLER"],
                        callback: function($$v) {
                          _vm.$set(
                            _vm.configPermissionForm.whitelist,
                            "MAKLER",
                            $$v
                          )
                        },
                        expression: "configPermissionForm.whitelist['MAKLER']"
                      }
                    })
                  : _vm._e(),
                _vm.configPermissionForm.context["MAKLER:MAKLER"] ||
                _vm.configPermissionForm.context["MAKLER:KUNDE"]
                  ? _c("InputChips", {
                      attrs: {
                        label: "Nicht erlaubte Makler",
                        supportCustomValue: ""
                      },
                      model: {
                        value: _vm.configPermissionForm.blacklist["MAKLER"],
                        callback: function($$v) {
                          _vm.$set(
                            _vm.configPermissionForm.blacklist,
                            "MAKLER",
                            $$v
                          )
                        },
                        expression: "configPermissionForm.blacklist['MAKLER']"
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c("hr"),
            _c(
              "div",
              [
                _c("div", { staticClass: "box__title" }, [
                  _vm._v("Mitarbeiter")
                ]),
                _c("InputToggleSwitch", {
                  staticClass: "p-0",
                  attrs: {
                    label: "Angemeldet als Mitarbeiter",
                    inLineLabel: ""
                  },
                  model: {
                    value:
                      _vm.configPermissionForm.context[
                        "MAKLER~PERSONEN:MAKLER"
                      ],
                    callback: function($$v) {
                      _vm.$set(
                        _vm.configPermissionForm.context,
                        "MAKLER~PERSONEN:MAKLER",
                        $$v
                      )
                    },
                    expression:
                      "configPermissionForm.context['MAKLER~PERSONEN:MAKLER']"
                  }
                }),
                _vm.configPermissionForm.context["MAKLER~PERSONEN:MAKLER"]
                  ? _c("InputChips", {
                      attrs: {
                        label: "Nur Erlaubte Makler",
                        supportCustomValue: ""
                      },
                      model: {
                        value:
                          _vm.configPermissionForm.whitelist["MAKLER~PERSONEN"],
                        callback: function($$v) {
                          _vm.$set(
                            _vm.configPermissionForm.whitelist,
                            "MAKLER~PERSONEN",
                            $$v
                          )
                        },
                        expression:
                          "configPermissionForm.whitelist['MAKLER~PERSONEN']"
                      }
                    })
                  : _vm._e(),
                _vm.configPermissionForm.context["MAKLER~PERSONEN:MAKLER"]
                  ? _c("InputChips", {
                      attrs: {
                        label: "Nicht erlaubte Makler",
                        supportCustomValue: ""
                      },
                      model: {
                        value:
                          _vm.configPermissionForm.blacklist["MAKLER~PERSONEN"],
                        callback: function($$v) {
                          _vm.$set(
                            _vm.configPermissionForm.blacklist,
                            "MAKLER~PERSONEN",
                            $$v
                          )
                        },
                        expression:
                          "configPermissionForm.blacklist['MAKLER~PERSONEN']"
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c("hr"),
            _vm.showInternInputs
              ? _c(
                  "div",
                  [
                    _c("div", { staticClass: "box__title" }, [
                      _vm._v("Intern")
                    ]),
                    _c("InputToggleSwitch", {
                      staticClass: "p-0",
                      attrs: {
                        label: "Angemeldet als Interner",
                        inLineLabel: ""
                      },
                      model: {
                        value:
                          _vm.configPermissionForm.context["INTERN:INTERN"],
                        callback: function($$v) {
                          _vm.$set(
                            _vm.configPermissionForm.context,
                            "INTERN:INTERN",
                            $$v
                          )
                        },
                        expression:
                          "configPermissionForm.context['INTERN:INTERN']"
                      }
                    }),
                    _c("InputToggleSwitch", {
                      staticClass: "p-0",
                      attrs: {
                        label: "Maklersicht, angemeldet als Interner",
                        inLineLabel: ""
                      },
                      model: {
                        value:
                          _vm.configPermissionForm.context["INTERN:MAKLER"],
                        callback: function($$v) {
                          _vm.$set(
                            _vm.configPermissionForm.context,
                            "INTERN:MAKLER",
                            $$v
                          )
                        },
                        expression:
                          "configPermissionForm.context['INTERN:MAKLER']"
                      }
                    }),
                    _c("InputToggleSwitch", {
                      staticClass: "p-0",
                      attrs: {
                        label: "Kundensicht, angemeldet als Interner",
                        inLineLabel: ""
                      },
                      model: {
                        value: _vm.configPermissionForm.context["INTERN:KUNDE"],
                        callback: function($$v) {
                          _vm.$set(
                            _vm.configPermissionForm.context,
                            "INTERN:KUNDE",
                            $$v
                          )
                        },
                        expression:
                          "configPermissionForm.context['INTERN:KUNDE']"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }