<template>
  <div>
    <InputField
      label="Betrachtungszeitraum in Jahren (mindestens 3 Jahre)"
      v-model="years"
      :disabled="isDisabled || isRefusedPage"
      isComponentHalfSize
       @change="updateStore(getCategory(), 'years', $event)" />

    <InputRadioBoxGroup 
      title="Ich habe Kenntnisse und Erfahrungen in folgenden Anlageformen:"
      v-model="hasErfahrungen" 
      :validateUntouched="true"
      :disabled="isDisabled || isRefusedPage"
      :inlineLabelValues="false"
      :values="[
        {value: 'no', label: 'keine Kenntnisse und Erfahrungen'},
        {value: 'yes', label: 'folgende Kenntnisse und Erfahrungen:'}
      ]"
      @input="setNoExperience($event)"
    />

    <div class="checkbox-input__container mb-3 ml-3">
      <div v-for="(category,index) of categories" :key="index">
        <InputCheckBoxItem :label="category.label" v-model="form[category.path]"
          :disabled="isDisabled || isRefusedPage || hasErfahrungen !== 'yes'" 
          @input="onSelection($event, category.path)"/> 
      </div>
    </div>
    <InputRadioBoxGroup v-if="!isDialogFVV"
      title="Bitte wählen Sie, ob Wertpapiergeschäfte auf Kreditbasis getätigt wurden."
      v-model="investOnCredit" 
      :validateUntouched="true"
      :disabled="isDisabled || isRefusedPage"
      :inlineLabelValues="isMobileNativeContext ? true : false"
      :values="[
        {value: 'no', label: 'nein'},
        {value: 'yes', label: 'ja'}
      ]"
       @input="updateStore(getCategory(), 'investOnCredit', getBooleanValue($event))"/>
    <InputField v-if="!isDialogFVV && investOnCredit === 'yes'"
      label="In welcher Höhe bewegte sich Ihr Kredit (Euro)?"
      v-model="creditSum"
      type="currency"
      :disabled="isDisabled || isRefusedPage"
      isComponentHalfSize
       @change="updateStore(getCategory(), 'creditSum', $event)" />
    <InputTextArea
      label="Zusätzliche Angaben zu den Kenntnissen und Erfahrungen:"
      v-model="annotationKenntnisse"
      :disabled="isDisabled || isRefusedPage"
      isComponentHalfSize
      @change="updateStore(getCategory(), 'annotationKenntnisse', $event)" />
  </div>
</template>

<script>

import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import InputField from '@/components/core/forms/InputField.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import InputCheckBoxItem from '@/components/core/forms/checkbox/InputCheckBoxItem.vue';
import anlegerprofilMixin from '@/mixins/anlegerprofil/anlegerprofil-mixin.js';
import validator from "@/mixins/validator";

export default {
  mixins: [anlegerprofilMixin, validator],
  validators: {},
  components: {
    InputRadioBoxGroup,
    InputTextArea,
    InputField,
    InputCheckBoxItem,
  },
  data() {
    return {
      categories: [],
      form: {},
      years: '',
      investOnCredit: '',
      creditSum: '',
      annotationKenntnisse: '',
      hasErfahrungen: '',
    };
  },
  computed: {
    isRefusedPage() {
      const refused = this._isRefusedPage(this.data, 'kenntnisse');
      if (refused) {
        this.removeSelection();
      }
      return refused;
    },
  },
  mounted() {
    this.patchForm();
    this.updateStoreCheckBoxGroup(this.getCategory(), {});
  },
  watch: {
    $route(to, from) {
      this.patchForm();
    },
  },
  methods: {
    patchForm() {
      const data = this.getExperience(this.getPersonTypeForce());
      const emptyCategories = data?.emptyCategories?.length ? data.emptyCategories : [];
      if (data) {
        if (data.categories?.length) {
          data.categories.forEach(cat => {
            const hasSelection = !emptyCategories.includes(cat.path);
            this.form[cat.path] = hasSelection;
          })
        }
        this.categories = data.categories || [];
        this.years = data.years || '';
        this.investOnCredit = data.investOnCredit;
        this.hasErfahrungen = data.hasErfahrungen;
        this.creditSum = data.creditSum;
        this.annotationKenntnisse = data.annotationKenntnisse || '';
      } else {
        this.years = '3';
        this.updateStore(this.getCategory(), 'years', '3')
      }
      setTimeout(() => {
        this.updateWarnings();
      }, 600);
    },
    setNoExperience(value) {
      if (value === 'no') {
        Object.keys(this.form)?.forEach(key => this.form[key] = false);
        const emptyCategories = this.categories.map(category => category.path);
        this.updateStoreCheckBoxGroup(this.getCategory(), { emptyCategories: emptyCategories });
        const substeps = this.stepper && this.stepper.getCurrentStep('kenntnisse')?.substeps?.filter(sub => sub.substepKey ?.includes('step2-card') && !sub.hidden);
        this.stepper && substeps?.forEach(sub => 
          this.stepper.setSubstepHidden('kenntnisse', sub.substepKey, true));
      }
      this.updateStore(this.getCategory(), 'hasErfahrungen', value)
    },
    onSelection(select, category, updateStore = true) {
      if (this.stepper) {
        this.stepper.setSubstepHidden('kenntnisse', 'step2-card/' + category, !select);
        if (updateStore) {
          const emptyCategories = this.categories.filter(category => !this.form[category.path]).map(category => category.path);
          this.updateStoreCheckBoxGroup(this.getCategory(), { emptyCategories: emptyCategories })
        }
      }
    },
    removeSelection() {
      const emptyCategories = Object.keys(this.form)?.filter(cat => this.form[cat]);
      if (emptyCategories?.length) {
        emptyCategories.forEach(cat => {
          this.form[cat] = false;
        });
      }
    }
  },
}
</script>

<style scoped>

</style>