import router, { getRouteChildren } from '@/router';
import {
  isUserHasRequiredRole,
  isRolesIncludes,
} from '@/router/guards';
import { ROLES, VIEW_ROLES } from '@/router/roles';

export const STAMMDATEN_SUBSTEP = { title: 'Stammdaten', substepKey: 'stammdaten', };
export const ADRESSE_SUBSTEP = { title: 'Adresse', substepKey: 'adresse', };
export const VERSANDADRESSE_SUBSTEP = { title: 'Versandadresse', substepKey: 'versandadresse', };
export const PAKETADRESSE_SUBSTEP = { title: 'Paketadresse', substepKey: 'paketadresse' }
export const MAKLERKRITERIEN_SUBSTEP = { title: 'Makler Kriterien', substepKey: 'maklerkriterien' }
export const FP_PLUS_REFERENZ_SUBSTEP = { title: 'Referenznummer FP+', substepKey: 'fp-plus-referenz' }

export const PERSDATEN_STEP = { title: 'Personaldaten', stepKey: 'persdaten' };
export const KONTAKT_STEP = { title: 'Kontakt', stepKey: 'kontakt' };
export const KONTAKT_POSTANSCHRIFT = { title: 'Postanschrift', stepKey: 'postanschrift' };



export const MAP_SUBSTEP_KEY_TO_TITLE = {
  [STAMMDATEN_SUBSTEP.substepKey]: STAMMDATEN_SUBSTEP.title,
  [ADRESSE_SUBSTEP.substepKey]: ADRESSE_SUBSTEP.title,
  [VERSANDADRESSE_SUBSTEP.substepKey]: VERSANDADRESSE_SUBSTEP.title,
  [PAKETADRESSE_SUBSTEP.substepKey]: PAKETADRESSE_SUBSTEP.title,
  [MAKLERKRITERIEN_SUBSTEP.substepKey]: MAKLERKRITERIEN_SUBSTEP.title,
  [FP_PLUS_REFERENZ_SUBSTEP.substepKey]: FP_PLUS_REFERENZ_SUBSTEP.title,
};

export const MAP_STEP_KEY_TO_TITLE = {
  [PERSDATEN_STEP.stepKey]: PERSDATEN_STEP.title,
  [KONTAKT_STEP.stepKey]: KONTAKT_STEP.title,
  [KONTAKT_POSTANSCHRIFT.stepKey]: KONTAKT_POSTANSCHRIFT.title,

}

export const MAP_VERMITTLER_ROUTE_TO_DEFAULT_SUBSTEP = {
  '/intern/vermittlerdaten/vermittlerdaten': STAMMDATEN_SUBSTEP.substepKey,
};

export const ANSPRECHPARTNER_ROUTE_TO_DEFAULT_SUBSTEP = {
  '/intern/ansprechpartner/:personId': KONTAKT_STEP.stepKey.substepKey,
};

export const MAP_PERSON_ROUTE_TO_DEFAULT_SUBSTEP = {
  '/vermittlerdaten/person-data/:personId/stammdaten': STAMMDATEN_SUBSTEP.substepKey,
};



export const MAP_VERMITTLER_ROUTE_PATH_TO_SUBSTEPS = {
  '/intern/vermittlerdaten/vermittlerdaten': [...vermittlerSubsteps()],
};

export function vermittlerSubsteps() {
  const substeps = [];
  substeps.push(STAMMDATEN_SUBSTEP);
  if (isRolesIncludes([ROLES.IS_FPP_PLUS_MASTER])) {
    substeps.push(FP_PLUS_REFERENZ_SUBSTEP);
  }
  substeps.push(ADRESSE_SUBSTEP);
  substeps.push(VERSANDADRESSE_SUBSTEP);
  substeps.push(PAKETADRESSE_SUBSTEP);
  substeps.push(MAKLERKRITERIEN_SUBSTEP);
  return substeps;
}

export const MAP_PERSON_ROUTE_PATH_TO_STEPS = {
  '/intern/ansprechpartner/:id': [...ansprechpartnerSteps()],
}
export function ansprechpartnerSteps() {
  const steps = [];
  steps.push(PERSDATEN_STEP);
  steps.push(KONTAKT_STEP);
  steps.push(KONTAKT_POSTANSCHRIFT)
  return steps;
}

export function personSteps(personId) {
  if (!personId) return;


  const children = getRouteChildren('/intern/ansprechpartner/:id');

  const steps = [];
  let index = 1;

  children.forEach((childRoute) => {
    if (childRoute?.redirect) return;

    if (!isMainPerson && PERSON_SUB_ROUTES_MAIN_PERSON_ONLY.indexOf(childRoute.path) >= 0) {
      return;
    }
    if (isMainPerson && PERSON_SUB_ROUTES_ADDITIONAL_PERSON_ONLY.indexOf(childRoute.path) >= 0) {
      return;
    }

    const to = router.matcher.match(`/intern/ansprechpartner/${personId}/${childRoute.path}`);
    isUserHasRequiredRole(to, null, (redirect) => {
      if (!redirect) {
        const key = to.matched[to.matched.length - 1].path;

        steps.push({
          stepKey: to?.path,
          label: to?.meta?.menu?.label,
          current: false,
          totalProgress: index++,
          substeps: mapRouteToSubsteps[`${key}`] || mapRouteToSubsteps[`${key}:${extraKey}`] || null,
        });
      }
    });
  });

  return steps;
}
