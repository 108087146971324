<template>
  <BaseButton @click="onclick">{{label}}</BaseButton>
</template>

<script>
import { mapGetters } from 'vuex';
import ANTRAG_TYPES from '@/store/antrag/types';
import BaseButton from '@/components/core/BaseButton.vue';

export default {
  components: {
    BaseButton,
  },
  props: {
    label: {
      default: ''
    },
    config: {
    },
    value: {
    },
  },
  computed: {
    ...mapGetters({
      antragData: ANTRAG_TYPES.GETTERS.ANTRAEGE_DATA,
    }),
  },
  data() {
    return {
      
    }
  },
  methods: {
    onclick() {
      this.$router.push(`/beratung/formulare/antrag/DAB/dabnamensaenderung`)
    },
  },
}
</script>

<style scoped>

</style>