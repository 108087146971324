import { BROKER_LEVEL_ROLES, BROKER_STRUCTURE_ROLES, ROLES, VIEW_ROLES, EMPLOYEE_ROLES } from '@/router/roles'
import vvRoutes from './vv'
import wertpapierorderRoutes from './wertpapierorder'

import { generateGesellschaftRoute } from './gesellschaften/gesellschaften'
import { redirectToFirstAllowedChild, } from '@/router'
import WERTPAPIERINFO_TYPES from "@/store/wertpapierinfo/types";

export default [
  {
    path: '',
    redirect: 'overview'
  },
  {
    path: 'overview',
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_CUSTOMER],
          [VIEW_ROLES.VIEW_BROKER]
        ],
        denied: [
          [ROLES.SUPER_CUSTOMER]
        ]
      },
    },
    component: () => import(/* webpackChunkName: "overview" */ '@/views/beratung/Overview.vue'),
  },
  {
    path: 'beratung',
    component: () => import(/* webpackChunkName: "BaseRouterView" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN, EMPLOYEE_ROLES.ZUGRIFF_KUNDEN], 
          [VIEW_ROLES.VIEW_BROKER, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN, EMPLOYEE_ROLES.ZUGRIFF_KUNDEN],
        ],
        denied: [ROLES.IS_INVERSE]
      },
    },
    children: [
      {
        path: '',
        redirect: 'liste'
      },
      {
        path: 'liste',
        component: () => import(/* webpackChunkName: "Beratungsmappen" */ '@/views/beratung/Beratungsmappen.vue'),
      },
      {
        path: 'bearbeiten',
        component: () => import(/* webpackChunkName: "EditBeratung" */ '@/views/beratung/EditBeratung.vue'),
        meta: {
          roles: {
            allowed: [
              [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS], 
            ],
          },
        },
      },
      {
        path: 'erfassungpositionpapierantrag',
        component: () => import(/* webpackChunkName: "ErfassungPositionPapierantrag" */ '@/views/beratung/ErfassungPositionPapierantrag.vue'),
      },
      {
        path: 'auswahl',
        component: () => import(/* webpackChunkName: "Beratungsmappen" */ '@/views/beratung/Beratungsmappen.vue'),
      },
    ]
  },
  {
    path: 'processes',
    component: () => import(/* webpackChunkName: "BaseRouterView" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN, EMPLOYEE_ROLES.ZUGRIFF_KUNDEN], 
          [VIEW_ROLES.VIEW_BROKER, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN, EMPLOYEE_ROLES.ZUGRIFF_KUNDEN],
        ],
      },
    },
    children: [
      {
        path: '',
        redirect: 'list',
      },
      {
        path: 'list',
        component: () => import(/* webpackChunkName: "processList" */ '@/views/beratung/ProcessList.vue'),
      },
      {
        path: 'form',
        component: () => import(/* webpackChunkName: "processForm" */ '@/views/beratung/ProcessForm.vue'),
      }
    ],
  },
  {
    path: 'beratungsmappe_versicherungen',
    component: () => import(/* webpackChunkName: "BaseRouterView" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN, ROLES.TEST_USER]
        ],
      },
    },
    children: [
      {
        path: '',
        redirect: 'liste'
      },
      {
        path: 'liste',
        component: () => import(/* webpackChunkName: "Beratungsmappen" */ '@/views/beratungVersicherung/Beratungsmappen.vue'),
      },
      {
        path: 'create_beratung',
        component: () => import(/* webpackChunkName: "Beratungsmappen" */ '@/views/beratungVersicherung/Beratungsmappen.vue'),
        meta: {
          action: 'BERATUNG_2018',
        },
      },
      {
        path: 'bearbeiten',
        component: () => import(/* webpackChunkName: "EditBeratung" */ '@/views/beratungVersicherung/EditBeratung.vue'),
      },
    ]
  },
  {
    path: 'anlegerprofil',
    component: () => import(/* webpackChunkName: "anlegerprofil" */ '@/views/anlegerprofil/BaseAnlegerprofilRouterView.vue'),
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_CUSTOMER, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS]],
        denied: [ROLES.IS_INVERSE]
      },
    },
    children: [
      {
        path: '',
        redirect: (to) => ({ path: 'person1/auswahl/personenauswahl', query: {...to.query, schiene: 'INVESTMENT'} }),
      },
      {
        path: ':personType/:step/',
        component: () => import(/* webpackChunkName: "anlegerprofil" */ '@/views/anlegerprofil/AnlegerprofilMain.vue'),
        children: getAnlegerprofilChildren(),
      },
      {
        path: 'anlageziele-planen',
        component: () => import(/* webpackChunkName: "anlegerprofil" */ '@/components/anlageziele/APAnlagezielePlanen.vue'),
      },
      {
        path: 'aktionen',
        component: () => import(/* webpackChunkName: "anlegerprofil" */ '@/views/anlegerprofil/AnlegerprofilMain.vue'),
      },
    ],
  },

  {
    path: 'anlegerprofilWK',
    component: () => import(/* webpackChunkName: "anlegerprofil" */ '@/views/anlegerprofil/BaseAnlegerprofilRouterView.vue'),
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_CUSTOMER, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS, BROKER_LEVEL_ROLES.MAKLER_VV_IS_ADVISOR],
          [VIEW_ROLES.VIEW_CUSTOMER, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS, BROKER_LEVEL_ROLES.MAKLER_VV_DARF_VERKAUFEN],
        ],
      },
    },
    children: [
      {
        path: '',
        redirect: (to) => ({ path: 'person1/auswahl/personenauswahl', query: {...to.query, schiene: 'WEALTH_KONZEPT'} }),
      },
      {
        path: ':personType/:step/',
        component: () => import(/* webpackChunkName: "anlegerprofil" */ '@/views/anlegerprofil/AnlegerprofilMain.vue'),
        // we need for the menu only the first children step to set the schiene = WEALTH_KONZEPT, all the next steps have the pass of
        // the default anlegerprofil: anlegerprofil/:personType/:step 
        children: getAnlegerprofilChildren()
      },
      {
        path: 'aktionen',
        component: () => import(/* webpackChunkName: "anlegerprofil" */ '@/views/anlegerprofil/AnlegerprofilMain.vue'),
      },
    ],
  },
  {
    path: 'confirmAnlegerprofil',
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_CUSTOMER_ONLY], 
        ],     
      },
    },    
    component: () => import(/* webpackChunkName: "anlageempfehlung" */ '@/views/beratung/AnlegerprofilBestaetigung.vue')
  },
  {
    path: 'ffb-video-ident',
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_CUSTOMER_ONLY], 
          [VIEW_ROLES.VIEW_CUSTOMER], 
        ],     
      },
    },    
    component: () => import(/* webpackChunkName: "f-f-b-zustimmung-kunde" */ '@/views/antrag/FFBZustimmungKunde.vue')
  },
  {
    path: 'anlageempfehlung',
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS],
          [VIEW_ROLES.VIEW_BROKER, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS],
        ],
        denied: [ROLES.IS_ANLAGE_BEISPIEL],
      },
    },
    component: () => import(/* webpackChunkName: "BaseRouterView" */ '@/views/BaseRouterView.vue'),
    children: [
      {
        path: '',
        redirect: 'list'
      },
      {
        path: 'list',
        component: () => import(/* webpackChunkName: "anlageempfehlung" */ '@/views/beratung/Anlageempfehlung.vue'),
      },
      {
        path: 'details',
        component: () => import(/* webpackChunkName: "anlageempfehlung-details" */ '@/views/investmentAdvice/InvestmentAdviceView.vue'),
        meta: {
          roles: {
            allowed: [
              [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS], 
              [VIEW_ROLES.VIEW_BROKER]
            ],
          },
        }
      },
    ]
  },
  {
    path: 'anlagebeispiel',
    meta: {
      roles: {
        allowed: [
          [ROLES.IS_ANLAGE_BEISPIEL, VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS],
          [ROLES.IS_ANLAGE_BEISPIEL, VIEW_ROLES.VIEW_BROKER, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS],
        ],
      },
    },
    component: () => import(/* webpackChunkName: "BaseRouterView" */ '@/views/BaseRouterView.vue'),
    children: [
      {
        path: '',
        redirect: 'list'
      },
      {
        path: 'list',
        component: () => import(/* webpackChunkName: "anlageempfehlung" */ '@/views/beratung/Anlageempfehlung.vue'),
      },
      {
        path: 'details',
        component: () => import(/* webpackChunkName: "anlageempfehlung-details" */ '@/views/investmentAdvice/InvestmentAdviceView.vue'),
        meta: {
          roles: {
            allowed: [
              [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS],
              [VIEW_ROLES.VIEW_BROKER]
            ],
          },
        }
      },
    ]
  },
  {
    path: 'wertpapierinfo',
    component: () => import(/* webpackChunkName: "Wertpapierinfo" */ '@/views/beratung/Wertpapierinfo.vue'),
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_CUSTOMER_ONLY, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS],
          [VIEW_ROLES.VIEW_BROKER, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS],
        ]
      }
    }  
  },
  {
    path: 'antrag-beratungsmappe-selector',
    meta: {
      roles: {
        allowed: [VIEW_ROLES.VIEW_CUSTOMER_AS_BROKER, VIEW_ROLES.VIEW_CUSTOMER_AS_INTERN, VIEW_ROLES.VIEW_BROKER]
      },
    },
    component: () => import(/* webpackChunkName: "antragBeratungsmappeSelector" */ '@/views/antrag/AntragBeratungsmappeSelector.vue')
  },
  {
    path: 'formulare',
    component: () => import(/* webpackChunkName: "formulare" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed: [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS]
      }
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "formulare-list" */ '@/views/antraege/Formulare.vue'),
      },
      {
        path: 'antrag/ezeichnung',
        component: () => import(/* webpackChunkName: "antrag" */ '@/views/antrag/EZeichnung.vue'),
        meta: {
          roles: {
            allowed: [[VIEW_ROLES.VIEW_CUSTOMER]],
          }
        },
      },
      {
        path: 'antrag/ezeichnung-list',
        component: () => import(/* webpackChunkName: "antrag" */ '@/views/antrag/EZeichnungList.vue'),
        meta: {
          roles: {
            allowed: [[VIEW_ROLES.VIEW_CUSTOMER]],
          }
        },
      },
      {
        path: 'antrag/efonds24Stammdaten',
        component: () => import(/* webpackChunkName: "antrag" */ '@/views/antrag/EFondsStammdaten.vue'),
        meta: {
          roles: {
            allowed: [[VIEW_ROLES.VIEW_CUSTOMER]],
          },
        },
      },
      {
        path: 'antrag/:lagerstelle',
        component: () => import(/* webpackChunkName: "formulare-list" */ '@/views/BaseRouterView.vue'),
        children: [
          {
            path: '',
            component: () => import(/* webpackChunkName: "formulare-list" */ '@/views/antraege/FormulareList.vue'),
          },
          {
            path: 'wertpapierorder',
            component: () => import(/* webpackChunkName: "wertpapierorder-fondsfinder" */ '@/views/BaseRouterView.vue'),
            meta: {
              roles: {
                allowed: [VIEW_ROLES.VIEW_CUSTOMER]
              },
            },
            children: [
              ...wertpapierorderRoutes,
            ]
          },
          {
            path: ':antragsName/:step/antrag-dokumentenarchiv/:navigationParams?',
            component: () => import(/* webpackChunkName: "documents" */ '@/views/documents/AntragDocuments.vue'),   
          },
          {
            path: ':antragsName/:step?/:substep?',
            component: () => import(/* webpackChunkName: "antrag" */ '@/views/antrag/BaseAntragView.vue'),
          },
          {
            path: ':antragsName/:step/:substep?/fondsfinder/:categoryId',
            component: () => import(/* webpackChunkName: "antrag" */ '@/views/antrag/BaseAntragFormsFinder.vue'),
          },
          {
            path: ':antragsName/:step/:substep?/positionbearbeiten/:categoryId',
            component: () => import(/* webpackChunkName: "antrag" */ '@/views/antrag/ErfassungPosition.vue'),
          },
          {
            path: ':antragsName/:step/depotuebertragung/:uebertragungStep/:categoryId/:fondIndex',
            component: () => import(/* webpackChunkName: "antrag" */ '@/views/antrag/uebertragungsauftrag/DepotUebertragungEditor.vue'),
          },
          {
            path: ':antragsName/:step/fodbdepotuebertragunginn/:categoryId/:fondIndex',
            component: () => import(/* webpackChunkName: "antrag" */ '@/views/antrag/uebertragungsauftrag/FODBDepotUebertragungInnerhalb.vue'),
          },
          {
            path: ':antragsName/:step/ebaseedelmetalldepotuebertraginn/:categoryId/:fondIndex',
            component: () => import(/* webpackChunkName: "antrag" */ '@/views/antrag/uebertragungsauftrag/EbaseEdelmetallUbertragungInnerhalb.vue'),
          },
        ],
      },
    ]
  },
  {
    path: 'vergleichsrechner',
    component: () => import(/* webpackChunkName: "Vergleichsrechner" */ '@/views/versicherungstools/VersicherungstoolsRouterView.vue'),
    meta: {
      roles: {
        allowed: [
          [ROLES.FK, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN, VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS],
          [ROLES.FK, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN, VIEW_ROLES.VIEW_BROKER],
        ]
      },
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "Vergleichsrechner" */ '@/views/versicherungstools/VersicherungstoolsOverview.vue'),
      },
      {
        path: 'open-externe-zugang',
        component: () => import(/* webpackChunkName: "Vergleichsrechner" */ '@/views/versicherungstools/OpenExterneZugang.vue'),
      },
      {
        path: 'ask-options/:websiteId',
        component: () => import(/* webpackChunkName: "Vergleichsrechner" */ '@/views/versicherungstools/ExterneZugangAskOptionsPage.vue'),
      },
      {
        path: 'dokumente-abholen',
        component: () => import(/* webpackChunkName: "Vergleichsrechner" */ '@/views/versicherungstools/ExterneZugangDokumenteAbholen.vue'),
      },
      {
        path: ':websiteId',
        component: () => import(/* webpackChunkName: "Vergleichsrechner" */ '@/views/beratungVersicherung/Versicherungstools.vue'),        
      },
    ],
  },
  {
    path: 'bestandsuebertragung',
    component: () => import(/* webpackChunkName: "BaseRouterView" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN]],
        denied: [ROLES.FA],
      },
    },
    children: [
      {
        path: '',
        redirect: 'list',
      },
      {
        path: ':typ',
        component: () => import(/* webpackChunkName: "bestandsuebertragung" */ '@/components/core/forms/antraege/Bestandsuebertragung.vue'),
      },
    ],
  },
  {
    path: 'abschluss-portale',
    meta: {
      roles: {
        allowed: [[ROLES.FK, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN, VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS]]
      }
    },
    component: () => import(/* webpackChunkName: "AbschlussPortale" */ '@/views/beratung/AbschlussPortale.vue'),
  },
  {
    path: 'externe-zugaenge',
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_BROKER, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN, EMPLOYEE_ROLES.ZUGRIFF_KUNDEN],
        ],
      }
    },
    component: () => import(/* webpackChunkName: "broker-externe-zugang-bank" */ '@/components/externeZugangBank/Broker.vue'),
  },
  {
    path: 'ruhestandsplanung-old',
    component: () => import(/* webpackChunkName: "BaseRouterView" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_CUSTOMER_ONLY, ROLES.TEST_USER], [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, ROLES.TEST_USER]]
      }
    },
    children: [
      {
        path: '',
        redirect: 'list'
      },
      {
        path: 'list',
        component: () => import(/* webpackChunkName: "RetirementScenarioList" */ '@/components/retirementScenario/RetirementScenarioList.vue')
      },
      {
        path: 'view/:id',
        component: () => import(/* webpackChunkName: "RetirementScenario" */ '@/components/retirementScenario/RetirementScenario.vue'),
      },
      {
        path: 'new',
        component: () => import(/* webpackChunkName: "RetirementScenario" */ '@/components/retirementScenario/RetirementScenario.vue'),
      }
    ]
  },
  {
    path: 'ruhestandsplanung',
    component: () => import(/* webpackChunkName: "BaseRouterView" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed: [[ROLES.IS_MAKLER_EARLY_ACCESS], [ROLES.TEST_USER]]
      }
    },
    children: [
      {
        path: '',
        redirect: 'list'
      },
      {
        path: 'list',
        component: () => import(/* webpackChunkName: "ruhestandsplanungList" */ '@/views/ruhestandsplanung/RuhestandsplanungList.vue')
      },
      {
        path: ':id/:stepKey',
        component: () => import(/* webpackChunkName: "ruhestandsplanung" */ '@/views/ruhestandsplanung/Ruhestandsplanung.vue'),
      },
    ]
  },
  {
    path: 'meineziele',
    component: () => import(/* webpackChunkName: "MeineZiele" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_CUSTOMER_ONLY, ROLES.TEST_USER]]
      }
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "meineziele-list" */ '@/components/myGoals/MyGoalsOverview.vue'),
      },
      {
        path: 'select',
        component: () => import(/* webpackChunkName: "meineziele-select" */ '@/components/myGoals/MyGoalSelectType.vue'),
      },
      {
        path: ':id',
        component: () => import(/* webpackChunkName: "meineziele-steps" */ '@/components/myGoals/MyGoalsSteps.vue'),
        meta: {
          step: 'preview',
        },
        children: [
          {
            path: '',
            redirect: 'preview'
          },
          {
            path: 'preview',
            component: () => import(/* webpackChunkName: "meineziele-ziel" */ '@/components/myGoals/MyGoalsView.vue'),
          },
          {
            path: 'form',
            component: () => import(/* webpackChunkName: "meineziele-form" */ '@/components/myGoals/MyGoalsForm.vue'),
          },
        ]
      },
    ]
  },
  {
    path: 'fondsvergleich',
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_BROKER, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS],
          [VIEW_ROLES.VIEW_CUSTOMER, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS],
        ],
      }
    },
    component: () => import(/* webpackChunkName: "BaseRouterView" */ '@/views/BaseRouterView.vue'),
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "FondsVergleichList" */ '@/views/beratung/FondsVergleichList.vue'),
      },
      {
        path: 'fondsvergleicheinstellen',
        component: () => import(/* webpackChunkName: "FondsVergleichEinstell" */ '@/components/beratung/FondsVergleichEinstell.vue'),
      },
      {
        path: ':fvid',
        component: () => import(/* webpackChunkName: "FondsVergleichEdit" */ '@/views/beratung/FondsVergleich.vue'),
      },
    ]
  },
  {
    path: 'watchliste',
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_CUSTOMER, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS],
        ],
      }
    },
    component: () => import(/* webpackChunkName: "BaseRouterView" */ '@/views/BaseRouterView.vue'),
    children: [
      {
        path: '',
        redirect: 'list'
      },
      {
        path: 'list',
        component: () => import(/* webpackChunkName: "WatchlistView" */ '@/views/beratung/WatchlisteFondsKunde.vue'),
      },
      {
        path: 'add',
        component: () => import(/* webpackChunkName: "Wertpapierinfo" */ '@/views/beratung/Wertpapierinfo.vue'),
        meta: {
          dispatchSelected: () => WERTPAPIERINFO_TYPES.ACTIONS.ADD_WATCHLIST,
          filterKey: 'watchliste'
        }
      },
    ]
  },

  generateGesellschaftRoute({
    allowed: [VIEW_ROLES.VIEW_BROKER]
  }),
  {
    path: 'gesellschaft-formulare',
    component: () => import(/* webpackChunkName: "gesellschaft-formulare" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_BROKER]]
      }
    },
    children: [
      {
        path: '',
        redirect: 'mainStructure',
      },
      {
        path: 'mainStructure',
        component: () => import(/* webpackChunkName: "gesellschaft-mainStructure" */ '@/views/gesellschaft/broker/GesellschaftFormulare.vue'),
      },
      {
        path: 'formList',
        component: () => import(/* webpackChunkName: "gesellschaft-formList" */ '@/views/gesellschaft/broker/GesellschaftFormulareLinks.vue'),
      },
    ]
  },

  {
    path: 'gesellschaft-versdaten',
    component: () => import(/* webpackChunkName: "gesellschaften" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed: [[BROKER_LEVEL_ROLES.ZUGRIFF_GESELLSCHAFTDATEN_VERS]],
      },
    },
    children: [
      {
        path: '',
        component:() => import(/* webpackChunkName: "gesellschaft-overview" */ '@/views/gesellschaft/GesellschaftSearch.vue'),
        meta: {
          isModusVersDaten: true,
        },
      },
      {
        path: ':gesellId',
        component: () => import(/* webpackChunkName: "gesellschaft" */ '@/views/BaseRouterView.vue'),
        children: [
          {
            path: '',
            redirect: 'daten',
          },
          {
            path: 'daten',
            component: () => import(/* webpackChunkName: "gesell-daten" */ '@/views/gesellschaft/intern/GesellschaftDaten.vue'),
            meta: {
              isModusVersDaten: true,
            },
          },
          {
            path: 'antragsbearbeitung',
            component: () => import(/* webpackChunkName: "gesell-antragsbearbeitung" */ '@/views/gesellschaft/intern/GesellschaftAntragsbearbeitung.vue'),
            meta: {
              isModusVersDaten: true,
            },
          },
        ]
      },
    ]
  },

  {
    path: 'courtageliste',
    component: () => import(/* webpackChunkName: "BaseRouterView" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_BROKER, ROLES.FA]],
      },
    },
    children: [
      {
        path: '',
        redirect: (route) => redirectToFirstAllowedChild(route, ['konditionen']),
      },
      {
        path: 'konditionen',
        component: () => import(/* webpackChunkName: "konditionen" */ '@/views/intern/UebersichtKonditionen.vue'),
        meta: {
          roles: {
            allowed: [ROLES.ZUGRIFF_COURTAGE_TABELLE_FONDS, ROLES.FA],
          },
        },
      },      

    
    ],
  },

  {
    path: 'vermittlernummern-overview',
    component: () => import(/* webpackChunkName: "BaseRouterView" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_BROKER]],
      },
    },
    children: [
      {
        path: '',
        redirect: (route) => redirectToFirstAllowedChild(route, ['vermittlernummern', 'vermittlernummernfondsgesellschaften', 'vermittlernummerngeschlossenefonds', 'vermittlernummernallemitarbeiter']),
      },
      {
        path: 'vermittlernummern',
        component: () => import(/* webpackChunkName: "vermittlernummern" */ '@/views/intern/Vermittlernummern.vue'),
        meta: {
          roles: {
            allowed: [BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN],
          },
        },
      },
      {
        path: 'vermittlernummernfondsgesellschaften',
        meta: {
          gesellschaftsArtParam: 'Fondsgesellschaft',
          roles: {
            allowed: [BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS],
          },
        },
        component: () => import(/* webpackChunkName: "vermittlernummernfondsgesellschaften" */ '@/views/intern/VermittlernummernOtherTabs.vue'),
      }, 
    
      {
        path: 'vermittlernummernbank',
        meta: {
          gesellschaftsArtParam: 'Bank',
          roles: {
            allowed: [BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS],
          },
        },
        component: () => import(/* webpackChunkName: "vermittlernummernbank" */ '@/views/intern/VermittlernummernOtherTabs.vue'),
      },   
    
      {
        path: 'vermittlernummerngeschlossenefonds',
        meta: {
          gesellschaftsArtParam: 'Geschlossene Fonds',
          roles: {
            allowed: [BROKER_STRUCTURE_ROLES.ZUGRIFF_GESCHLOSSENE_FONDS],
          },
        },
        component: () => import(/* webpackChunkName: "vermittlernummerngeschlossenefonds" */ '@/views/intern/VermittlernummernOtherTabs.vue'),
      },
    
      {
        path: 'vermittlernummerBearbeiten',
        name: 'vermittlernummerBearbeiten',
        component: () => import(/* webpackChunkName: "vermittlernummerBearbeiten" */ '@/views/intern/VermittlernummerBearbeiten.vue'),
      }, 
    
      {
        path: 'vermittlernummernallemitarbeiter',
        component: () => import(/* webpackChunkName: "vermittlernummernallemitarbeiter" */ '@/views/intern/VermittlernummernAlleMitarbeiter.vue'),
      },
      {
        path: 'fremdnrBearbeiten',
        name: 'fremdnrBearbeiten',
        component: () => import(/* webpackChunkName: "fremdnrBearbeiten" */ '@/views/intern/FremdnrBearbeiten.vue'),
      },
    ],
  },
  
  {
    path: 'honorare',
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_BROKER_AS_INTERN], [VIEW_ROLES.VIEW_BROKER, ROLES.FK]],
      }
    },
    component: () => import(/* webpackChunkName: "honorare" */ '@/views/BaseRouterView.vue'),
    children:[
      {
        path: '',
        component: () => import(/* webpackChunkName: "honorare" */ '@/views/intern/Honorare.vue'),
      },
      {
        path: 'bearbeiten',
        component: () => import(/* webpackChunkName: "honorarBearbeiten" */ '@/views/intern/HonorarBearbeiten.vue'),
      },
    ]
  },
  { 
    path: 'tarifBearbeiten',
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN], [VIEW_ROLES.VIEW_BROKER, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN]],
      }
    },   
    component: () => import(/* webpackChunkName: "TarifBearbeiten" */ '@/views/intern/TarifBearbeiten.vue'),
  },
  {
    path: 'vv',
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_BROKER, ROLES.FK, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS, BROKER_LEVEL_ROLES.MAKLER_VV_DARF_VERKAUFEN],
          [VIEW_ROLES.VIEW_BROKER, ROLES.FK, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS, BROKER_LEVEL_ROLES.MAKLER_VV_IS_ADVISOR],
            [VIEW_ROLES.VIEW_BROKER, ROLES.FA_WK_TEST_USER, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS],
          ]
      },
    },
    component: () => import(/* webpackChunkName: "vermoegensverwaltung" */ '@/views/BaseRouterView.vue'),
    children: [...vvRoutes]
  },
  {
    path: 'fp-plus-zugaenge',
    meta: {
      roles: {
        allowed:
          [
            [VIEW_ROLES.VIEW_BROKER, ROLES.IS_FPP_PLUS, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN],
          ]
      },
    },
    component: () => import(/* webpackChunkName: "fp-plus-zugaenge" */ '@/views/BaseRouterView.vue'),
    children: [
      {
        path: '',
        redirect: 'overview',
      },
      {
        path: 'overview',
        component: () => import(/* webpackChunkName: "overview" */ '@/views/fpPlusZugaenge/FPPlusZugaenge.vue'),
      },
      {
        path: ':id',
        component: () => import(/* webpackChunkName: "FPPlusAnmeldung" */ '@/views/fpPlusZugaenge/FPPlusAnmeldung.vue'),
      },
    ]
  },
  {
    path: 'wertpapiere-alle-kunden',
    meta: {
      roles: {
        allowed:
          [
            [VIEW_ROLES.VIEW_BROKER, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS],
          ]
      },
    },
    component: () => import(/* webpackChunkName: "allekunden" */ '@/views/BaseRouterView.vue'),
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "overview" */ '@/views/gesellschaft/GesellschaftOverview.vue'),
      },
      {
        path: 'fondsfinder',
        component: () => import(/* webpackChunkName: "fondsfinder" */ '@/views/wertpapiereAlleKunden/FondsfinderWertpapiere.vue'),
      },
      {
        path: ':type',
        component: () => import(/* webpackChunkName: "allekundenwertpapiere" */ '@/views/wertpapiereAlleKunden/WertpapiereAlleKunden.vue'),
        children: [
          {
            path: '',
            redirect: 'wertpapierauswahl',
          },
          {
            path: 'wertpapierauswahl',
            component: () => import('@/views/wertpapiereAlleKunden/Wertpapierauswahl.vue'),
          },
          {
            path: 'kundenliste',
            component: () => import('@/views/wertpapiereAlleKunden/KundenlisteWertpapiere.vue'),
          },
          {
            path: 'email',
            component: () => import('@/views/wertpapiereAlleKunden/MailComposerWertpapiere.vue'),
          },
          {
            path: 'antraege',
            component: () => import('@/views/wertpapiereAlleKunden/AntraegeWertpapiere.vue'),
          },
        ],
      },
    ]
  },
  {
    path: 'finanzanalyse',
    meta: {
      roles: {
        allowed: [
          [ROLES.FK, VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, ROLES.HAS_FINANZPORTAL24],
          [ROLES.FK, VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, ROLES.HAS_FINANZPORTAL24_ONLINE_BESTELLANLEITUNG],
        ],
      },
    },
    component: () => import(/* webpackChunkName: "finanzanalyse" */ '@/views/BaseRouterView.vue'),
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "finanzanalyse" */ '@/views/beratung/FinanzanalyseOverview.vue'),
      },
      {
        path: 'finanzportal24',
        meta: {
          roles: {
            allowed: [ROLES.HAS_FINANZPORTAL24],
          },
        },
        component: () => import(/* webpackChunkName: "Finanzportal24" */ '@/views/versicherungstools/OpenExterneZugang.vue'),
      },
      {
        path: 'finanzportal24-online-bestellanleitung',
        meta: {
          roles: {
            allowed: [ROLES.HAS_FINANZPORTAL24_ONLINE_BESTELLANLEITUNG],
          },
        },
        component: () => import(/* webpackChunkName: "Finanzportal24" */ '@/views/versicherungstools/OpenExterneZugang.vue'),
      },
    ],
  },
  {
    path: 'wertpapierorder',
    component: () => import(/* webpackChunkName: "wertpapierorder" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_CUSTOMER_ONLY, ROLES.KUNDE_APP_ALLOW_ORDER]],
      },
    },
    children: [
      ...wertpapierorderRoutes,
    ]
  },
]

function getAnlegerprofilChildren() {
  return [
    {
      path: 'auswahl',
      component: () => import('@/views/anlegerprofil/APStepPersonenauswahl.vue'),
    },
    {
      path: 'step1-1',
      component: () => import('@/views/anlegerprofil/APStep11.vue'),
    },
    {
      path: 'step1-2',
      component: () => import('@/views/anlegerprofil/APStep12.vue'),
    },
    {
      path: 'step1-3',
      component: () => import('@/views/anlegerprofil/APStep13.vue'),
    },
    {
      path: 'step2-1',
      component: () => import('@/views/anlegerprofil/APStep21.vue'),
    },
    {
      path: 'step2-card/:category',
      component: () => import('@/views/anlegerprofil/APStep2Card.vue'),
    },
    {
      path: 'stepKenntnisseGF',
      component: () => import('@/views/anlegerprofil/APStepKenntisseGF.vue'),
    },
    {
      path: 'stepGF-card/:category',
      component: () => import('@/views/anlegerprofil/APStepGFCard.vue'),
    },
    {
      path: 'step3-1',
      component: () => import('@/views/anlegerprofil/APStep31.vue'),
    },
    {
      path: 'step3-2',
      component: () => import('@/views/anlegerprofil/APStep32.vue'),
    },    
    {
      path: 'step3-3',
      component: () => import('@/views/anlegerprofil/APStep33.vue'),
    },
    {
      path: 'step3-4',
      component: () => import('@/views/anlegerprofil/APStep34.vue'),
    },
    {
      path: 'step3-5',
      component: () => import('@/views/anlegerprofil/APStep35.vue'),
    },
    {
      path: 'step3-6',
      component: () => import('@/views/anlegerprofil/APStep36.vue'),
    },
    {
      path: 'step3-summe',
      component: () => import('@/views/anlegerprofil/APStep3Summe.vue'),
    },
    {
      path: 'step3-7',
      component: () => import('@/views/anlegerprofil/APStep37.vue'),
    },
    {
      path: 'finanzenKompakt',
      component: () => import('@/views/anlegerprofil/APStepFvKompakt.vue'),
    },
    {
      path: 'step5-1',
      component: () => import('@/views/anlegerprofil/APStep51.vue'),
    },
    {
      path: 'step6-1',
      component: () => import('@/views/anlegerprofil/APStep61.vue'),
    },
    {
      path: 'nachhaltigkeit',
      component: () => import('@/views/anlegerprofil/APStepNachhaltigkeit.vue'),
    },
    {
      path: 'anlageziele',
      component: () => import('@/views/anlegerprofil/APStepAnlageziele.vue'),
    },
    {
      path: 'geschlosseneInvest',
      component: () => import('@/views/anlegerprofil/APStepGF.vue'),
    },
    {
      path: 'zielmarkt-dje',
      component: () => import('@/views/anlegerprofil/APStepZusatzDJEZielmarkt.vue'),
    },
    {
      path: 'verlusttragfaehigkeit-dje',
      component: () => import('@/views/anlegerprofil/APStepZusatzDJEVerlust.vue'),
    },
    {
      path: 'kontakt-dje',
      component: () => import('@/views/anlegerprofil/APStepZusatzDJEKontakt.vue'),
    },
    {
      path: 'nachhaltigkeit-dje',
      component: () => import('@/views/anlegerprofil/APStepZusatzDJENachhaltigkeit.vue'),
    },
    {
      path: 'warnhinweise',
      component: () => import('@/views/anlegerprofil/APStepWarnhinweise.vue'),
    },
    {
      path: ':substepKey',
      component: () => import('@/views/anlegerprofil/APStepPersonenauswahl.vue'),
    },
  ]
}