<template>
  <div>
    <BaseListWithSearch
      :componentIds="componentIds"
      :pageHeaderActions="actions"
      :pageHeaderActionListeners="pageHeaderActionListeners"
      :baseFilterConfigs="baseFilterConfigs"
      :baseFilterListeners="baseFilterListeners"
      :tableConfigs="tableConfigs"
      :tableListeners="tableListeners"
      :loading="loading"/>
  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import PROCESS_TYPES from '@/store/process/types';
import { mapGetters } from 'vuex';
import {
  TextColumn,
  ActionColumn,
  ConfirmedAction,
} from '@/components/table2/table_util.js';
import dayjs from 'dayjs';
import InteractiveHelpCommonsMixin from '@/assets/mixins/interactivehelpcommonsmixins.js';
import { PageHeaderLoadingAction } from '@/components/core/header-title-navigation/page-header-utils';
import BaseListWithSearch from '@/components/base/BaseListWithSearch.vue'

const TABLE_TITLE = 'Vorgänge Liste';

export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    BaseListWithSearch
  },
  data() {
    return {
      TABLE_TITLE,
      defaultOptions: {
        LETZTEN_3_MONATEN: {
          value: true,
        },
      },
      filter: {},
      loading: {
        create: false,
        list: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
      backToPreviousData: CORE_TYPES.GETTERS.GET_SAVE_BACK_TO_PREVIOUS_PAGE,
      processList: PROCESS_TYPES.GETTERS.PROCESS_LIST,
    }),
    pageHeaderActionListeners() {
      return {
        'action-CREATE': this.createProcess
      }
    },
    componentIds() {
      return {
        filterId: '418b46b3-32f0-428a-aae7-28e7836990aa',
        tid: 'd379db51-d390-4c34-8801-616bbe563e4e',
        tableId: '51e939da-3b1e-48ed-8141-9812fe1cae1c',
      }
    },
    baseFilterConfigs() {
      return {
        showSaveButton: this.isBroker,
        title: "Vorgänge filtern",
        saveKey: this.isBroker ? 'processSearchAdvanced' : null,
        metadata: this.filterMetadata,
        hasSmartSearch: this.isBroker,
        immidiateSearch: true,
        defaultOptions: this.isBroker ? this.defaultOptions : {},
      }
    },
    tableConfigs() {
      return {
        title: TABLE_TITLE,
        headers: this.headers,
        rows: this.rows,
        rowsPerPage: 20,
        exportConfig: { roottext: 'Vorgänge' },
      }
    },
    baseFilterListeners() {
      return {
        onFilter: this.filterForm
      }
    },
    tableListeners() {
      return {
        'clickRow': this.editProcess,
        'click-kundennr': this.editProcess,
        'click-description': this.editProcess,
        'action-COPY': this.copyProcess,
        'action-DELETE': this.deleteProcess,
      }
    },
    actions() {
      return [
        PageHeaderLoadingAction('CREATE','Neu Vorgang',)
      ];
    },
    filterMetadata() {
      let result = [
        {
          type: 'group',
          key: 'abDatum',
          label: 'Ab Datum',
          menuItems: [
            {
              type: 'default',
              label: 'Alle',
              key: 'VALUE_ALLE',
            },
            {
              type: 'default',
              label: 'letzte 3 Monate',
              key: 'LETZTEN_3_MONATEN',
            },
            {
              type: 'dateRange',
              label: 'Zeitraum',
              key: 'ZEITRAUM',
            },
            {
              type: 'integer',
              label: 'Jahr',
              key: 'jahr',
            },
          ],
        },
        {
          type: 'group',
          key: 'fehlerstatus',
          label: 'Fehlerstatus',
          menuItems: [
            {
              type: 'default',
              label: 'Alle',
              key: 'VALUE_ALLE',
            },
            {
              type: 'default',
              label: 'nur fehlerfreie',
              key: 'VALUE_FEHLER_OK',
            },
            {
              type: 'default',
              label: 'nur fehlerhafte',
              key: 'VALUE_FEHLER_ROT',
            },
          ],
        },
        
      ];

      if (this.isBroker) {
        result.push({
          type: 'group',
          key: 'allgemein',
          label: 'Kunde',
          menuItems: [
            {
              type: 'text',
              label: 'Name',
              key: 'lastName',
            },
            {
              type: 'text',
              label: 'Kundennummer',
              key: 'customerId',
              inputmode: 'numeric',
            },
            {
              type: 'text',
              label: 'Vorname',
              key: 'firstName',
            },
          ],
        });
      }

      return result;
    },
    rows() {
      if (!this.processList) return [];
      let rows = this.processList.map((item) => {
        const copyMessage = `Möchten Sie die Vorgang kopieren?`
        const deleteMessage = `Soll die Vorgang wirklich gelöscht werden?`
        const actions = [
          ConfirmedAction('COPY','PhCopy','Kopieren',copyMessage,'Vorgang kopieren','Kopieren'),
          ConfirmedAction('DELETE','PhTrash','Löschen',deleteMessage,'Vorgang löschen','Löschen'),
        ];

        return {
          id: item.id,
          kundennr: item.kundennr,
          description: item.description,
          dateCreated: item.dateCreated ? dayjs(item.dateCreated).format('DD.MM.YYYY') : '',
          actions,
        };
      });

      return rows;
    },
    headers() {
      const lockedLeft = [];

      if (this.isBroker) {
        lockedLeft.push(TextColumn('kundennr', 'Kunde').makeLink());
        lockedLeft.push(TextColumn('description', 'Beschreibung'));
      } else {
        lockedLeft.push(TextColumn('description', 'Beschreibung').makeLink());
      }

      lockedLeft.push(TextColumn('dateCreated', 'Datum'));

      return {
        lockedLeft,
        lockedRight: [ActionColumn('actions', 'Aktionen')],
      };
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu den Beratungsmappen',
      to,
      from,
    });

    next();
  },
  methods: {
    async retrieveProcessList() {
      try {
        this.loading.list = true;
        this.$store.commit(PROCESS_TYPES.MUTATIONS.RESET_STATE);
        await this.$store.dispatch(PROCESS_TYPES.ACTIONS.GET_PROCESS_LIST);
      } finally {
        this.loading.list = false;
      }
    },
    editProcess(data) {
      this.$router.push({
        path: '/beratung/processes/form',
        query: {
          id: data.id,
        },
      });
    },
    createProcess() {
      this.$router.push({
        path: '/beratung/processes/form'
      });
    },

    async deleteProcess(row) {
      await this.$store.dispatch(PROCESS_TYPES.ACTIONS.DELETE_PROCESS, row.id);
      await this.retrieveProcessList();
    },

    async copyProcess(row) {
      //TODO
      await this.retrieveProcessList();
    },

    filterForm(filterConfig) {
      const kunde = filterConfig.filter((fc) => fc.group == 'allgemein');
      let kundeKey = null;
      let customerId = null;
      let firstName = null;
      let lastName = null;

      if (kunde) {
        kundeKey = filterConfig.find((fc) => fc.key == 'customerId');
        if (kundeKey) {
          customerId = kundeKey.value;
        }
        kundeKey = filterConfig.find((fc) => fc.key == 'firstName');
        if (kundeKey) {
          firstName = kundeKey.value;
        }
        kundeKey = filterConfig.find((fc) => fc.key == 'lastName');
        if (kundeKey) {
          lastName = kundeKey.value;
        }
      }

      const abDatum = filterConfig.find((fc) => fc.group == 'abDatum');

      let fromDate = null;
      let toDate = null;

      if (abDatum && abDatum.key == 'ZEITRAUM') {
        fromDate = abDatum.value.find((v) => v.key == 'min').value;
        toDate = abDatum.value.find((v) => v.key == 'max').value;
      }

      if (abDatum && abDatum.key === 'LETZTEN_3_MONATEN') {
        fromDate = dayjs()
          .subtract(3, 'month')
          .format('DD.MM.YYYY');
      }

      let filterDateJahr = null;

      if (abDatum && abDatum.key === 'jahr') {
        filterDateJahr = abDatum.value;
      }

      const filterModusDatum = filterConfig.find(
        (fc) => fc.group == 'abDatumIst'
      );
      const filterFehler = filterConfig.find(
        (fc) => fc.group == 'fehlerstatus'
      );
      let filterStatus = filterConfig.find((fc) => fc.group == 'abgeschlossen');
      const filterMitScan = filterConfig.find(
        (fc) => fc.group == 'hinterlegtenOrderdokumenten'
      );
      const ort = filterConfig.find((fc) => fc.group == 'ort');

      if (this.$route.query && this.$route.query.investementAdviceReferrer) {
        filterStatus = 'VALUE_STATUS_OFFEN';
      }

      this.filter = {
        firstName,
        lastName,
        filterKundennr: customerId,
        filterDateVon: fromDate,
        filterDateBis: toDate,
        filterModusDatum: filterModusDatum && filterModusDatum.key,
        filterWithScan: filterMitScan && filterMitScan.value,
        filterFehler: filterFehler && filterFehler.key,
        filterStatus: filterStatus && filterStatus.key,
        filterDateJahr: filterDateJahr,
        filterOrt: ort && ort.key,
      };

      this.retrieveProcessList();
    },
  },
};
</script>

<style scoped></style>
