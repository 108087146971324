<template>
  <div class="box">
    <h1>Ich wünsche eine Beratung</h1>

    <fieldset>
      <select id="select-id" v-model="viewer.anrede">
        <option disabled value>Bitte auswählen</option>
        <option>Frau</option>
        <option>Herr</option>
      </select>
      <InputField v-model="viewer.vorname" label="Vorname: " />
      <InputField v-model="viewer.nachname" label="Nachname: " />
      <InputField v-model="viewer.email" label="E-Mail: " />
      <InputField v-model="viewer.telefon" label="Telefon: " inputmode="tel"/>

      <div>
        <BaseButton :disabled="validation.isInvalid('viewer')" @click="sendBeratungsChat">Send</BaseButton>
      </div>
    </fieldset>

    <!-- <div type="submit" class="button" @click="mrmoneypost">MR MONEY</div>-->
  </div>
</template>
<script>
import CMS_TYPES from "@/store/cms/types";
import validator from "@/mixins/validator";
import InputField from "@/components/core/forms/InputField.vue";
import BaseButton from "@/components/core/BaseButton.vue";
import { mapGetters, dispatch } from "vuex";
import {
  required,
  email,
  minLength,
  maxLength,
  telefon
} from "@/mixins/validator/rules";

export default {
  components: {
    InputField,
    BaseButton
  },
  data() {
    return {
      viewer: {
        anrede: "",
        vorname: null,
        nachname: null,
        email: null,
        telefon: null
      }
    };
  },
  mixins: [validator],
  computed: {
    ...mapGetters({
      getUserID: CMS_TYPES.GETTERS.GET_USER_ID
    })
  },

  validators: {
    viewer: {
      anrede: [required()],
      vorname: [required()],
      nachname: [required()],
      email: [email()],
      telefon: [telefon()]
    }
  },

  methods: {
  

    sendBeratungsChat: function() {
      this.$store.dispatch(CMS_TYPES.ACTIONS.SEND_BERATUNGS_CHAT, {
        messageText:
          this.viewer.anrede +
          " " +
          this.viewer.nachname +
          " " +
          this.viewer.vorname +
          " wünscht eine Beratung!\nTelefon: " +
          this.viewer.telefon +
          "\nE-Mail: " +
          this.viewer.email,
        receiverId: this.getUserID,
        thema:
          "Beratungswunsch von " +
          this.viewer.anrede +
          " " +
          this.viewer.nachname
      });
    },

    mrmoneypost: function() {
      this.$store.dispatch(CMS_TYPES.ACTIONS.MR_MONEY, {
        /*     id:"IhreID",
       laufzeit:"1",
       delikt:"ja",
       sb:"nein",
       IP_USER:"80.242.174.237-93756", */
      });
    }
  }
};
</script>
<style scoped>
.box {
  padding: 1em;
}
</style>