<template>
  <div class="dashboard-customer__container">
    <h1>I'm a customer</h1>
    {{ fullname }}
    <h4>Customer dashboard</h4>
    <!-- <Dashboard type="'customer'" /> -->
    <!-- <div style="width:300px;padding:1rem;border:1px solid rgba(0,0,0,0.16)">
      <ChartGC chartHeight="180" :sparkline="true"/>
    </div>
    <ChartGC chartHeight="500" :sparkline="false"  style="border:1px solid rgba(0,0,0,0.16);margin: 2rem 0;"/> -->

  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import CORE_TYPES from '../../store/core/types';

import Dashboard from '@/components/dashboard/Dashboard.vue'

export default {
  computed: {
    ...mapGetters({
      fullname: CORE_TYPES.GETTERS.GET_USER_FULL_NAME,
    }),
  },

  components: {
    Dashboard,
    
  },
  methods: {
  }
}
</script>

<style scoped>

</style>