<template>
  <div class="box__container">
      <template v-for="zugriffVertraege in zugriffVertraegeValues">
        <InputRadioBoxGroup 
          :key="zugriffVertraege.value"
          :title="zugriffVertraege.label" 
          v-model="zugriffVertraegeForm[zugriffVertraege.value]"
          :values="mapZugriffVertraegeKeyToValues[zugriffVertraege.value] || []"
          @input="onDataChanged()" />
      </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BROKERDATA_TYPES from '@/store/brokerData/types';

import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';

export default {
  components: {
    InputRadioBoxGroup,
  },
  data() {
    return {
      zugriffVertraegeForm: {},
    };
  },
  computed: {
    ...mapGetters({
      brokerDataConfig: BROKERDATA_TYPES.GETTERS.GET_BROKER_DATA_CONFIG,
      ansprechpartner: BROKERDATA_TYPES.GETTERS.GET_ANSPRECHPARTNER_BY_ID,
    }),
    zugriffVertraegeValues() {
      return this.brokerDataConfig?.zugriffVertraegeValues || [];
    },
    mapZugriffVertraegeKeyToValues() {
      return this.brokerDataConfig?.mapZugriffVertraegeKeyToValues || [];
    },
  },
  methods: {
    setInitialData() {
      const { zugriffVertraege, } = this.ansprechpartner || {};
      Object.keys(zugriffVertraege || {}).forEach(key => {
        this.$set(this.zugriffVertraegeForm, key, zugriffVertraege?.[key]);
      });
    },
    onDataChanged() {
      this.$emit('change', { 
        ...this.zugriffVertraegeForm || {},
      });
    },
  },
  mounted() {
    this.setInitialData();
  },
}
</script>
