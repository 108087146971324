import { render, staticRenderFns } from "./ManuelleStruktur.vue?vue&type=template&id=46738488&"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("P:\\workspaces\\fk0\\fc\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('46738488')) {
      api.createRecord('46738488', component.options)
    } else {
      api.reload('46738488', component.options)
    }
    module.hot.accept("./ManuelleStruktur.vue?vue&type=template&id=46738488&", function () {
      api.rerender('46738488', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/otherProducts/cards/ManuelleStruktur.vue"
export default component.exports