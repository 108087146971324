import { render, staticRenderFns } from "./VersicherungDokumente.vue?vue&type=template&id=03ced306&scoped=true&"
import script from "./VersicherungDokumente.vue?vue&type=script&lang=js&"
export * from "./VersicherungDokumente.vue?vue&type=script&lang=js&"
import style0 from "./VersicherungDokumente.vue?vue&type=style&index=0&id=03ced306&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03ced306",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("P:\\workspaces\\fk0\\fc\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('03ced306')) {
      api.createRecord('03ced306', component.options)
    } else {
      api.reload('03ced306', component.options)
    }
    module.hot.accept("./VersicherungDokumente.vue?vue&type=template&id=03ced306&scoped=true&", function () {
      api.rerender('03ced306', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/versicherungen/VersicherungDokumente.vue"
export default component.exports