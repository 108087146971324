var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.summary
      ? _c("div", { staticClass: "box__container" }, [
          _c("div", { staticClass: "box__title" }, [
            _vm._v("Schadenort und -zeit")
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-6" }, [
              _vm._v("Wann wurde der Schaden verursacht ?")
            ]),
            _c("div", { staticClass: "col-6" }, [
              _vm._v(_vm._s(_vm.summary.ereignisdatum))
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-6" }, [_vm._v("Um wieviel Uhr?")]),
            _c("div", { staticClass: "col-6" }, [
              _vm._v(_vm._s(_vm.summary.ereignisUhrzeit))
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-6" }, [_vm._v("Straße, Hausnummer")]),
            _c("div", { staticClass: "col-6" }, [
              _vm._v(_vm._s(_vm.summary.adresseStrasse))
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-6" }, [_vm._v("PLZ")]),
            _c("div", { staticClass: "col-6" }, [
              _vm._v(_vm._s(_vm.summary.adressePlz))
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-6" }, [_vm._v("Ort")]),
            _c("div", { staticClass: "col-6" }, [
              _vm._v(_vm._s(_vm.summary.adresseOrt))
            ])
          ])
        ])
      : _vm._e(),
    _c("div", { staticClass: "box__container" }, [
      _c("div", { staticClass: "box__title" }, [_vm._v("Schadensverlauf")]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-6" }, [
          _vm._v("Wessen Verschulden war der Schaden ?")
        ]),
        _c("div", { staticClass: "col-6" }, [
          _vm._v(_vm._s(_vm.summary.verschulden))
        ])
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-6" }, [_vm._v("Ursache")]),
        _c("div", { staticClass: "col-6" }, [
          _vm._v(_vm._s(_vm.summary.schadensursacheText))
        ])
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-6" }, [_vm._v("Kommentar")]),
        _c("div", { staticClass: "col-6" }, [
          _vm._v(_vm._s(_vm.summary.schadenKommentar))
        ])
      ])
    ]),
    _vm.summary.beteiligte
      ? _c(
          "div",
          { staticClass: "box__container" },
          [
            _c("div", { staticClass: "box__title" }, [_vm._v("Beteiligten")]),
            _c("BeteiligtePersonen", { attrs: { editMode: false } })
          ],
          1
        )
      : _vm._e(),
    _vm.summary.beschaedigt
      ? _c(
          "div",
          { staticClass: "box__container" },
          [
            _c("div", { staticClass: "box__title" }, [
              _vm._v("Beschädigte Objekte")
            ]),
            _c("GeschaedigteObjekte", { attrs: { editMode: false } })
          ],
          1
        )
      : _vm._e(),
    _vm.summary.zahlungen
      ? _c(
          "div",
          { staticClass: "box__container" },
          [
            _c("div", { staticClass: "box__title" }, [_vm._v("Zahlungen")]),
            _c("ZahlungenGeschaedigte", { attrs: { editMode: false } })
          ],
          1
        )
      : _vm._e(),
    _vm.summary.files
      ? _c(
          "div",
          { staticClass: "box__container" },
          [
            _c("div", { staticClass: "box__title" }, [
              _vm._v("Fotos und Anhänge")
            ]),
            _c("FotosAnhaenge", {
              attrs: {
                editMode: false,
                schadenId: _vm.schadenId,
                vertragId: _vm.vertragId
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }