import { mapGetters } from 'vuex';
import LOG_TYPES from '@/store/log/types';
import EXTERNE_ZUGANG_CONFIG_TYPES from '@/store/externeZugangConfig/types';
import { buildMessage } from '@/helpers/log-message-helper';

const mixin = {
    computed: {
        ...mapGetters({
        }),
    },
    methods: {
        deleteExterneZugangConfig(id) {
            this.$store.dispatch(EXTERNE_ZUGANG_CONFIG_TYPES.ACTIONS.DELETE_EXTERNE_ZUGANG, id );
        },
        checkForCallback() {
            const denied = [];
            if (this.$route?.query?.DENIED) {
                this.$route?.query?.DENIED.split(',').forEach(element => {
                if (element == 'EMAIL') {
                  denied.push('E-Mail');
                } else if (element == 'ACCESS_IMAP') {
                  denied.push('E-Mail (IMAP)');
                } else if (element == 'CALENDAR') {
                  denied.push('Termine');
                } else if (element == 'TASKS') {
                  denied.push('Aufgaben');
                } else if (element == 'CONTACTS') {
                  denied.push('Kontakte');
                }
              });
      
              let msg = 'Die Berechtigungen für ' + denied.join(', ') + ' wurden nicht erteilt';
              this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(msg, 'danger', false));
            }
            const success = [];
            if (this.$route?.query?.SUCCESS) {
              this.$route?.query?.SUCCESS.split(',').forEach(element => {
                if (element == 'EMAIL') {
                  success.push('E-Mail');
                } else if (element == 'ACCESS_IMAP') {
                  success.push('E-Mail (IMAP)');
                } else if (element == 'CALENDAR') {
                  success.push('Termine');
                } else if (element == 'TASKS') {
                  success.push('Aufgaben');
                } else if (element == 'CONTACTS') {
                  success.push('Kontakte');
                }
              });
              let msg = 'Die Berechtigungen für ' + success.join(', ') + ' wurden  erteilt';
              this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(msg, 'primary'));
            }
            if (this.$route?.query?.FAILURE) {
              let msg = 'Bei den Berechtigungen für ' + this.$route?.query?.FAILURE + ' gab es Probleme';
              this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(msg, 'danger', false));
            }
            if (this.$route?.query?.errorMsg) {
              this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(this.$route?.query?.errorMsg, 'danger', false));
            }
          },
    },    
}

export default mixin;