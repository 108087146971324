import CALENDAR_TYPES from './types';

export default {
  /*   [CALENDAR_TYPES.GETTERS.GET_IF_ALREADY_PLACED]:(state) => (index) => {
    for (let c = 0; c < state.columnEventArray.length; c++) {
      if (state.columnEventArray[c].eventIndex === index) return true;
      else continue;
    }
    return false;
  },
  [CALENDAR_TYPES.GETTERS.GET_IF_RIGHT_POSITION]:(state) => (index,position) => {
    for (let d = 0; d < state.columnEventArray.length; d++) {
      if (
        state.columnEventArray[d].eventIndex === index &&
        state.columnEventArray[d].position === position
      )
        return true;
      else continue;
    }
    return false;
  }, */
  [CALENDAR_TYPES.GETTERS.GET_SHOWN_DAYS](state) {
    return state.shownDays;
  },

  [CALENDAR_TYPES.GETTERS.GET_SHOWN_HOURS](state) {
    return state.shownHours;
  },

  [CALENDAR_TYPES.GETTERS.GET_CALENDAR_DATA](state) {
    return state.refinedCalendarData;
  },
  [CALENDAR_TYPES.GETTERS.GET_CALENDAR_DATA_READY](state) {
    return state.calendarDataReady;
  },
  [CALENDAR_TYPES.GETTERS.GET_DAY_ELEMENTS](state) {
    return state.dayElements;
  },
  [CALENDAR_TYPES.GETTERS.GET_WEEK_CALENDAR_DATA](state) {
    return state.weekCalendarData;
  },
  [CALENDAR_TYPES.GETTERS.GET_HOURS_START](state) {
    return state.hoursStart;
  },
  [CALENDAR_TYPES.GETTERS.GET_HOURS_STOP](state) {
    return state.hoursStop;
  },
  [CALENDAR_TYPES.GETTERS.GET_COLUMN_EVENT_ARRAY](state) {
    return state.columnEventArray;
  },
  [CALENDAR_TYPES.GETTERS.GET_WEEKS_CONTENT](state) {
    return state.weeksContent;
  },
  [CALENDAR_TYPES.GETTERS.GET_SELECTED_APPOINTMENT_DATA](state) {
    return state.selectedAppointment;
  },
  [CALENDAR_TYPES.GETTERS.GET_CLICKED_ON_EVENT](state) {
    return state.clickedOnEvent;
  },
  [CALENDAR_TYPES.GETTERS.GET_HOURS_CONTENT](state) {
    return state.hoursContent;
  },
  [CALENDAR_TYPES.GETTERS.GET_APPOINTMENT_COMBOS](state) {
    return state.appointmentCombos;
  },
  [CALENDAR_TYPES.GETTERS.GET_APPOINTMENT_BEGIN_DATE](state) {
    return state.appointmentBeginDate;
  },
  [CALENDAR_TYPES.GETTERS.GET_APPOINTMENT_BEGIN_TIME](state) {
    return state.appointmentBeginTime;
  },
  [CALENDAR_TYPES.GETTERS.GET_APPOINTMENT_END_DATE](state) {
    return state.appointmentEndDate;
  },
  [CALENDAR_TYPES.GETTERS.GET_APPOINTMENT_END_TIME](state) {
    return state.appointmentEndTime;
  },
  [CALENDAR_TYPES.GETTERS.GET_APPOINTMENT_DURATION](state) {
    return state.appointmentDuration;
  },
  [CALENDAR_TYPES.GETTERS.GET_APPOINTMENT](state) {
    return state.selectedAppointment;
  },
  [CALENDAR_TYPES.GETTERS.GET_BETEILIGTE](state) {
    return state.selectedAppointment.beteiligteOutput;
  },
  [CALENDAR_TYPES.GETTERS.GET_EMPTY_APPOINTMENT](state) {
    return state.emptyAppointment;
  },
  [CALENDAR_TYPES.GETTERS.GET_APPOINTMENT_SETUP](state) {
    return state.appointmentSetup;
  },
  [CALENDAR_TYPES.GETTERS.GET_CUSTOMER_APPOINTMENTS](state) {
    return state.customerAppointments;
  },
  [CALENDAR_TYPES.GETTERS.PRESAVE_APPOINTMET](state) {
    return state.presaveAppointment;
  },
  [CALENDAR_TYPES.GETTERS.GET_BETEILIGTE_FOR_UPDATE](state) {
    return state.beteiligteForUpdate;
  },
  [CALENDAR_TYPES.GETTERS.GET_ATTACHMENT](state) {
    return state.attachments;
  },
  [CALENDAR_TYPES.GETTERS.LOAD_APPOINTMENT](state) {
    return state.loadAppointment;
  },
  [CALENDAR_TYPES.GETTERS.GET_APPOINTMENT_CONFIG](state) {
    return state.appointmentConfig;
  },
}