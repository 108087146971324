export const MUTATION_PREFIX = 'TAGS: ';
export const ACTIONS_PREFIX = 'TAGS_';
export const GETTERS_PREFIX = 'TAGS_';

export default {
  MUTATIONS: {
    GET_TAGS_SUCCESS: GETTERS_PREFIX + 'GET_TAGS_SUCCESS',

    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
  },
  ACTIONS: {
    ADD_TAG_HTML_VORLAGEN: ACTIONS_PREFIX + 'ADD_TAG_HTML_VORLAGEN',
    REMOVE_TAG_HTML_VORLAGEN: ACTIONS_PREFIX + 'REMOVE_TAG_HTML_VORLAGEN',

    ADD_TAG_WORD_VORLAGEN: ACTIONS_PREFIX + 'ADD_TAG_WORD_VORLAGEN',
    REMOVE_TAG_WORD_VORLAGEN: ACTIONS_PREFIX + 'REMOVE_TAG_WORD_VORLAGEN',

    REMOVE_TAG: ACTIONS_PREFIX + 'REMOVE_TAG',
    ADD_TAG: ACTIONS_PREFIX + 'ADD_TAG',

    GET_TAGS: ACTIONS_PREFIX + 'GET_TAG',
  },
  GETTERS: {
    TAGS: GETTERS_PREFIX + 'TAGS',
  }
}