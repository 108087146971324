<template>
  <div>
    <div class="editable_link_container">
      <div class="edit" v-if="isBroker" @click="edit">
        <ph-pencil-line title="Bearbeiten" :size="16" />
      </div>
      <div class="innerContent" v-if="isBroker"></div>
    </div>

    <BaseModal
      ref="EditSocialLinksModal"
      modalTitle="Sozial Links"
      @onConfirmButton="onConfirmModal()"
      @onCancelButton="onCancelModal()"
    >
      <div class="inner_modal">
        <div
          class="title"
        >Bitte ergänzen Sie Links Ihrer Sozialen Netzwerke mit kompletter URL.</div>
        <br />
        <div class="social">
          <i class="lab la-facebook-f"></i>
          <div class="social_title">Facebook:</div>
          <input type="text" v-model="facebookValue" />
        </div>

        <div class="social">
          <i class="lab la-twitter"></i>
          <div class="social_title">Twitter:</div>
          <input type="text" v-model="twitterValue" />
        </div>

        <div class="social">
          <i class="lab la-xing"></i>
          <div class="social_title">Xing:</div>
          <input type="text" v-model="xingValue" />
        </div>

        <div class="social">
          <i class="lab la-linkedin-in"></i>
          <div class="social_title">LinkedIn:</div>
          <input type="text" v-model="linkedinValue" />
        </div>

        <div class="social">
          <i class="lab la-instagram"></i>
          <div class="social_title">Instagram:</div>
          <input type="text" v-model="instagramValue" />
        </div>
      </div>
    </BaseModal>
  </div>
</template>
<script>
import { buildMessage } from "@/helpers/log-message-helper";
import CORE_TYPES from "@/store/core/types";
import CMS_TYPES from "@/store/cms/types";
import { mapGetters, dispatch, commit } from "vuex";
import BaseModal from "@/components/core/BaseModal.vue";
import BaseButton from "@/components/core/BaseButton.vue";
import { PhPencilLine } from 'phosphor-vue';

export default {
  components: {
    BaseModal,
    BaseButton,
    PhPencilLine,
  },
  data() {
    return {
      facebookValue: "",
      twitterValue: "",
      xingValue: "",
      linkedinValue: "",
      instagramValue: ""
    };
  },
  computed: {
    ...mapGetters({
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      maklerInfo: CORE_TYPES.GETTERS.GET_BROKER_INFORMATION
    })
  },
  mounted() {
    if (this.maklerInfo.socialMediaSrc) {
      this.facebookValue = this.maklerInfo.socialMediaSrc.facebookSrc;
      this.twitterValue = this.maklerInfo.socialMediaSrc.twitterSrc;
      this.xingValue = this.maklerInfo.socialMediaSrc.xingSrc;
      this.linkedinValue = this.maklerInfo.socialMediaSrc.linkedinSrc;
      this.instagramValue = this.maklerInfo.socialMediaSrc.instagramSrc;
    }
  },

  methods: {
    edit: function() {
      this.$refs.EditSocialLinksModal.open();
    },

    onConfirmModal: function() {
      this.$store.commit(CORE_TYPES.MUTATIONS.SET_SOCIAL_MEDIA_SRC, {
        target: "facebookSrc",
        value: this.facebookValue
      });
      this.$store.commit(CORE_TYPES.MUTATIONS.SET_SOCIAL_MEDIA_SRC, {
        target: "twitterSrc",
        value: this.twitterValue
      });
      this.$store.commit(CORE_TYPES.MUTATIONS.SET_SOCIAL_MEDIA_SRC, {
        target: "xingSrc",
        value: this.xingValue
      });
      this.$store.commit(CORE_TYPES.MUTATIONS.SET_SOCIAL_MEDIA_SRC, {
        target: "linkedinSrc",
        value: this.linkedinValue
      });
      this.$store.commit(CORE_TYPES.MUTATIONS.SET_SOCIAL_MEDIA_SRC, {
        target: "instagramSrc",
        value: this.instagramValue
      });
      this.$store
        .dispatch(CMS_TYPES.ACTIONS.UPDATE_SOCIAL_MEDIA_SRC)
        .then(this.onCancelModal());
    },
    onCancelModal: function() {
      this.facebookValue = this.maklerInfo.socialMediaSrc.facebookSrc;
      this.twitterValue = this.maklerInfo.socialMediaSrc.twitterSrc;
      this.xingValue = this.maklerInfo.socialMediaSrc.xingSrc;
      this.linkedinValue = this.maklerInfo.socialMediaSrc.linkedinSrc;
      this.instagramValue = this.maklerInfo.socialMediaSrc.instagramSrc;
    }
  }
};
</script>
<style scoped>
.inner_modal{
  width: 100%;
}
.edit {
  color: var(--color-danger);
  font-size: 1.5em;
  cursor: pointer;
  margin-left: 1em;
}
.title {
  color: var(--color-text);
  font-weight: bold;
}
.input_class {
  justify-self: right;
}
input[type="text"] {
  width: 95%;
}
.social_title {
  justify-self: left;
}
.cancel_button {
  justify-self: end;
}
.social {
  margin-top: 1em;
  margin-bottom: 1em;
  color: var(--color-text);
  display: grid;
  grid-template-columns: 1fr 3fr 15fr;
  align-items: center;
}

@media only screen and (max-width: 1024px) {
  .social {
    margin-top: 1em;
    margin-bottom: 1em;
    color: var(--color-text);
    display: grid;
    gap: 0.3em;
    grid-template-columns: 1fr 4fr 8fr;
    align-items: center;
  }
}
</style>