var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("AntragRender", {
        attrs: {
          antrag: _vm.getAntrag,
          antragData: _vm.getAntragData,
          routeStep: _vm.getRouteStep
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }