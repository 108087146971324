import LIMIT_TYPES from './types';
import { getInitialState } from './index';
import Vue from 'vue';

export default {
  [LIMIT_TYPES.MUTATIONS.GET_DEPOTLIMIT_SUCCESS](state, payload) {
    Vue.set(state, 'depotlimit', Object.assign({}, payload));
  },

  [LIMIT_TYPES.MUTATIONS.GET_DEPOTLIMIT_LIST_SUCCESS](state, payload) {
    Vue.set(state, 'depotlimitList', Object.assign([], payload || []));
  },

  [LIMIT_TYPES.MUTATIONS.GET_DEPOTPOSITION_LIMIT_LIST_SUCCESS](state, payload) {
    Vue.set(state, 'depotpositionLimits', Object.assign([], payload || []));
  },
 
  [LIMIT_TYPES.MUTATIONS.GET_DEPOTPOSITION_LIMIT_AUTOMATIC_LIST_SUCCESS](state, payload) {
    Vue.set(state, 'depotpositionLimitAutomaticList', Object.assign([], payload || []));
  },

  [LIMIT_TYPES.MUTATIONS.GET_DEPOTPOSITION_LIMIT_AUTOMATIC_SUCCESS](state, payload) {
    Vue.set(state, 'depotpositionLimitAutomatic', Object.assign([], payload || []));
  },
  
  [LIMIT_TYPES.MUTATIONS.GET_RISIKOKLASSEN_LIMIT_SUCCESS](state, payload) {
    Vue.set(state, 'risikoklassenLimit', Object.assign({}, payload || {}));
  },
  
  [LIMIT_TYPES.MUTATIONS.GET_RISIKOKLASSEN_LIMIT_LIST_SUCCESS](state, payload) {
    Vue.set(state, 'risikoklassenLimitList', Object.assign({}, payload || {}));
  },

  [LIMIT_TYPES.MUTATIONS.GET_WERTPAPIERKATEGORIE_LIMIT_LIST_SUCCESS](state, payload) {
    Vue.set(state, 'wertpapierkategorieLimitList', Object.assign([], payload || []));
  },

  [LIMIT_TYPES.MUTATIONS.GET_WERTPAPIERKATEGORIE_LIMIT_SUCCESS](state, payload) {
    Vue.set(state, 'wertpapierkategorieLimit', Object.assign({}, payload || {}));
  },

  [LIMIT_TYPES.MUTATIONS.GET_WERTPAPIER_LIMIT_LIST_SUCCESS](state, payload) {
    Vue.set(state, 'wertpapierLimitList', Object.assign([], payload || []));
  },

  [LIMIT_TYPES.MUTATIONS.GET_WERTPAPIER_LIMIT_SUCCESS](state, payload) {
    Vue.set(state, 'wertpapierLimit', Object.assign({}, payload || {}));
  },

  [LIMIT_TYPES.MUTATIONS.GET_GD200_LIMIT_LIST_SUCCESS](state, payload) {
    Vue.set(state, 'gd200LimitList', Object.assign([], payload || []));
  },

  [LIMIT_TYPES.MUTATIONS.GET_GD200_LIMIT_SUCCESS](state, payload) {
    Vue.set(state, 'gd200Limit', Object.assign({}, payload || {}));
  },
  
  [LIMIT_TYPES.MUTATIONS.DELETE_RISIKOKLASSEN_LIMIT_FROM_LIST_SUCCESS](state, kundennr) {
    if (kundennr) {
      const index = state.risikoklassenLimitList?.limits?.findIndex(limit => limit.kundennr === kundennr);
      if (index >= 0) {
        state.risikoklassenLimitList.limits.splice(index, 1);
        state.risikoklassenLimitList.limits = Object.assign([], state.risikoklassenLimitList.limits);
      }
    }
  },
  [LIMIT_TYPES.MUTATIONS.DELETE_WERTPAPIER_LIMIT](state, isin) {
    if (isin) {
      const index = state.wertpapierLimitList?.findIndex(limit => limit.isin === isin);
      if (index >= 0) {
        state.wertpapierLimitList.splice(index, 1);
        state.wertpapierLimitList = Object.assign([], state.wertpapierLimitList);
      }
    }
  },
  
  [LIMIT_TYPES.MUTATIONS.DELETE_GD200_LIMIT](state, id) {
    if (id) {
      const index = state.gd200LimitList?.findIndex(limit => limit.id === id);
      if (index >= 0) {
        state.gd200LimitList.splice(index, 1);
        state.gd200LimitList = Object.assign([], state.gd200LimitList);
      }
    }
  },
  
  [LIMIT_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },
  
  [LIMIT_TYPES.MUTATIONS.SET_MORE_LIMITS](state, payload) {
    Vue.set(state, payload.stateName, payload.limits);
  },
}
