var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isEmpty
      ? _c("div", [_vm._v("Keine zusatzliche Daten erforderlich")])
      : _c(
          "div",
          [
            _vm.components && _vm.components.length
              ? [
                  _c("div", { staticClass: "box__title" }, [
                    _vm._v("Selbstauskunft")
                  ]),
                  _c("ComponentList", {
                    attrs: {
                      components: _vm.components,
                      data: _vm.data,
                      comboboxValues: _vm.comboboxValues,
                      warnings: _vm.warnings,
                      disabled: _vm.readOnly
                    },
                    on: {
                      change: function($event) {
                        return _vm.updateStoreData($event)
                      }
                    }
                  })
                ]
              : _vm._e()
          ],
          2
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }