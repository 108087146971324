<template>
  <div>
    <PageHeaderTitleNavigation 
      title="Courtagestatistik der letzten 12 Monate" 
      :actions="headerActions"
    />

    <BaseFilter
      filterId="10240c9b-ac0b-4e07-8cb5-04d6673b6c11"
      title="Investmentabschlüsse zusätzliche Spalten anzeigen"
      :metadata="metadataFInvest"
      :defaultOptions="defaultFInvest"
      :configFilter="configFInvest"
      :openFilterOnMount="false"
      :showSaveButton="true"
      @onFilter="searchInvest"
    />
    <div class="box__container">
      <div v-if="loadedI > 0 && rowsI.length">
        <Table
            title="Investmentabschlüsse"
            :headers="headersI"
            :rows="rowsI"
            :rowsPerPage="25"
            :exportConfig="{roottext: 'Investmentabschlüsse'}"
        />
      </div>
			<GhostLoading v-if="loadedI <= 0">
				<Block height="100" />
			</GhostLoading>
      <NoData v-else content="Keine Informationen" :noIcon="true" />
    </div>
    
    <BaseFilter
      filterId="b72914f7-e5f4-4bbc-9d73-cc97d1c1ed6d"
      title="Versicherungscourtagen zusätzliche Spalten anzeigen"
      :metadata="metadataFVers"
      :defaultOptions="defaultFVers"
      :configFilter="configFVers"
      :openFilterOnMount="false"
      :showSaveButton="true"
      @onFilter="searchVers"
    />
    <div class="box__container">
      <div v-if="loadedV > 0 && rowsV.length">
        <Table
          title="Versicherungscourtagen"
          :headers="headersV"
          :rows="rowsV"
          :rowsPerPage="25"
          :exportConfig="{roottext: 'Versicherungscourtagen'}"
        />
      </div>
			<GhostLoading v-if="loadedV <= 0">
				<Block height="100" />
			</GhostLoading>
      <NoData v-else content="Keine Informationen" :noIcon="true" />
    </div>
  </div>
</template>

<script>
import BERICHTE_TYPES from '@/store/berichte/types';
import CORE_TYPES from '@/store/core/types';
import { mapGetters } from 'vuex';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import BaseFilter from '@/components/core/BaseFilter.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, DateColumn, CurrencyColumn } from "@/components/table2/table_util.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import NoData from '@/components/core/NoData.vue';
import { PageHeaderDownloadLinkAction} from '@/components/core/header-title-navigation/page-header-utils';

export default {
  components: {
    OptionMenu, PageHeaderTitleNavigation, BaseFilter, Table, GhostLoading, Block, NoData,
  },
  data() {
    return {
      configFInvest: {
        placeholderForDefSearchInput: "",
        hideFirstColumn: false,
        checkDefaultSearchInput: false,
      },
      metadataFInvest: [
        {
          type: 'group',
          key: 'allgemein',
          label: 'Gruppierung nach',
          menuItems: [ 
            { type: 'default', label: this.getLabel('monat'), key: 'monat' },
            { type: 'default', label: this.getLabel('gesellschaft'), key: 'gesellschaft' },
            { type: 'default', label: this.getLabel('lagerstelle'), key: 'lagerstelle' },
            { type: 'default', label: this.getLabel('fondsname'), key: 'fondsname' },
            { type: 'default', label: this.getLabel('depotnummer'), key: 'depotnummer' },
          ],
        },
      ],

      configFVers: {
        placeholderForDefSearchInput: "",
        hideFirstColumn: false,
        checkDefaultSearchInput: false,
      },
      metadataFVers: [
        {
          type: 'group',
          key: 'allgemein',
          label: 'Gruppierung nach',
          menuItems: [ 
            { type: 'default', label: this.getLabel('monat'), key: 'monat' },
            { type: 'default', label: this.getLabel('gesellschaft'), key: 'gesellschaft' },
            { type: 'default', label: this.getLabel('sparte'), key: 'sparte' },
            { type: 'default', label: this.getLabel('polNr'), key: 'polNr' },
          ],
        },
      ],
    };
  },
  mounted() {
    if (!this.loadedI) {
      this.searchInvest('monat');
    }
    if (!this.loadedV) {
      this.searchVers('monat');
    }
    if (process.env.VUE_APP_USER === 'hannes') console.log('loginData', JSON.parse(JSON.stringify(this.loginData)) );
    console.log('investCourtagen', JSON.parse(JSON.stringify(this.investCourtagen)) );
    console.log('versCourtagen', JSON.parse(JSON.stringify(this.versCourtagen)) );
  },
  computed: {
    ...mapGetters({
      investCourtagen: BERICHTE_TYPES.GETTERS.GET_COURTAGE_INVEST_KUNDE,
      versCourtagen: BERICHTE_TYPES.GETTERS.GET_COURTAGE_VERS_KUNDE,
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      isAVL: CORE_TYPES.GETTERS.IS_AVL,
    }),
    headerActions() {
      return [
        PageHeaderDownloadLinkAction('COURTAGEHIST', 'Courtagehistorie', 
        'Courtagehistorie.pdf', '/courtagehistorieKunde', {token: this.loginData.token}),
      ]
    },
    loadedI() {
      return this.investCourtagen.loaded;
    },
    defaultFInvest() {
      const result = {};
      (this.investCourtagen.anzeige || 'monat').split(',').forEach(col => {
        result[col] = {value: true};
      });
      return result;
    },
    headersI() {
      const lockedLeft = [];
      if ( this.investCourtagen.anzeige ) {
        this.investCourtagen.anzeige.split(',').forEach(spalte => {
          lockedLeft.push(TextColumn(spalte, this.getLabel(spalte) ))
        })
      }
      const center = [
          CurrencyColumn('umsatz', 'C. Umsatz').withSumFooter('currency'),
          CurrencyColumn('bestand', 'C. Bestand').withSumFooter('currency'),
          CurrencyColumn('servicegeb', 'C. Serviceentgelt').withSumFooter('currency'),

        ]
        if (!this.isFA) {
          center.push(
            CurrencyColumn('vwl', 'C. Vwl').withSumFooter('currency'),
            CurrencyColumn('management', 'C. Managemententgelt').withSumFooter('currency'),
            CurrencyColumn('verwaltungsgeb', 'C. Verwaltungsgebühr').withSumFooter('currency'),
          )
          if (!this.isAVL) {
            center.push(
              CurrencyColumn('gebuehr', 'C. Gebühr').withSumFooter('currency'),
            )
          }
        }
        center.push(
          // CurrencyColumn('abschluss', 'C. Abschluss').withSumFooter('currency'),
          CurrencyColumn('gesamt', 'C. Gesamt').withSumFooter('currency'),
          CurrencyColumn('summeUmsatz', 'Umsatz').withSumFooter('currency'),
        )
      return { 
        lockedLeft,
        center,
      };
    },
    rowsI() {
      return this.investCourtagen.rows || [];
    },
    
    loadedV() {
      return this.versCourtagen.loaded;
    },
    defaultFVers() {
      const result = {};
      (this.versCourtagen.anzeige || 'monat').split(',').forEach(col => {
        result[col] = {value: true};
      });
      return result;
    },
    headersV() {
      const lockedLeft = [];
      if ( this.versCourtagen.anzeige ) {
        this.versCourtagen.anzeige.split(',').forEach(spalte => {
          lockedLeft.push(TextColumn(spalte, this.getLabel(spalte) ))
        })
      }
      return { 
        lockedLeft,
        center: [
          CurrencyColumn('umsatz', 'C. Umsatz').withSumFooter('currency'),
          CurrencyColumn('bestand', 'C. Bestand').withSumFooter('currency'),
          CurrencyColumn('gesamt', 'C. Gesamt').withSumFooter('currency'),
        ],
      };
    },
    rowsV() {
      return this.versCourtagen.rows || [];
    },
  },
  methods: {
    searchInvest(value) {
      let payload;
      if (typeof value == 'string') {
        payload = value
      } else {
        payload = value.map(v => v.key).join(',');
      }
      this.$store.dispatch(BERICHTE_TYPES.ACTIONS.LOAD_COURTAGE_INVEST_KUNDE, payload);
    },
    searchVers(value) {
      let payload;
      if (typeof value == 'string') {
        payload = value
      } else {
        payload = value.map(v => v.key).join(',');
      }
      this.$store.dispatch(BERICHTE_TYPES.ACTIONS.LOAD_COURTAGE_VERS_KUNDE, payload);
    },
    getLabel(key) {
      switch (key) {
        case 'monat': return 'Monat';
        case 'gesellschaft': return 'Gesellschaft';
        case 'lagerstelle': return 'Lagerstelle';
        case 'fondsname': return 'Fondsname';
        case 'depotnummer': return 'Depotnummer';
        case 'sparte': return 'Vertragsart';
        case 'polNr': return 'Versicherungsnr.';
        default:
          return '';
      }
    },
  },
}
</script>

<style scoped>
::v-deep .no-default-input .parameter-search__input-fields__container > .parameter-search__input-fields .input-forms__container {
  display: none;
}
</style>