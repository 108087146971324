export const MUTATION_PREFIX = 'HTTP_REQUEST: ';
export const ACTIONS_PREFIX = 'HTTP_REQUEST_';
export const GETTERS_PREFIX = 'HTTP_REQUEST_';

export default {
  MUTATIONS: {
    REMOVE_HTTP_REQUEST_URL: MUTATION_PREFIX + 'REMOVE_HTTP_REQUEST_URL',
    ADD_HTTP_REQUEST_URL: MUTATION_PREFIX + 'ADD_HTTP_REQUEST_URL',
    ADD_INVALID_TOKEN_DUE_TO_SESSION_TIMEOUT: MUTATION_PREFIX + 'ADD_INVALID_TOKEN_DUE_TO_SESSION_TIMEOUT',
  },
  ACTIONS: {
    KILL_SESSION: ACTIONS_PREFIX + 'KILL_SESSION',
    KILL_SESSION_BY_URL: ACTIONS_PREFIX + 'KILL_SESSION_BY_URL',
    CANCEL_ALL_CURRENT_FRONTEND_REQUESTS: ACTIONS_PREFIX + 'CANCEL_ALL_CURRENT_FRONTEND_REQUESTS',
  },
  GETTERS: {
    HTTP_REQUEST_URL: GETTERS_PREFIX + 'HTTP_REQUEST_URL',
    HTTP_REQUEST_URL_LIST: GETTERS_PREFIX + 'HTTP_REQUEST_URL_LIST',
    HTTP_CANCELABLE_REQUEST_URL_LIST: GETTERS_PREFIX + 'HTTP_CANCELABLE_REQUEST_URL_LIST',
    IS_TOKEN_EXPIRED: GETTERS_PREFIX + 'IS_TOKEN_EXPIRED',
  }
}
