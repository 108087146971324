<template>
  <div :class="{ 'box__container-wrap': lastMarketing, }">
    <BaseFilter
      filterId="marketingMSC"
      title="Marketing Filter"
      :metadata="searchMetadata"
      :defaultOptions="defaultOptions"
      :configFilter="configFilter"
      @onFilter="handleSearch($event)"
    />
    <div class="box__container">
      <GhostLoading 
        v-if="loading" 
        type="table" 
        title="Marketing Liste"/>
      <NoData v-else-if="rows.length === 0" 
        title="Marketing Liste" />
      <Table v-else
        tableId="13a0d41a-be23-4065-931e-554122d74432"
        title="Marketing Liste"
        :headers="headers"
        :rows="rows"
        @click-title="handleEdit($event.id)"
        @action-VIEW="showMarketing($event)"
        @action-EDIT="handleEdit($event.id)"
        @action-DELETE="deleteEntry($event.id)"
        @action-COPY="copyEntry($event.id)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MSC_MARKETING_TYPES from '@/store/mscmarketing/types'
import Table from '@/components/table2/Table.vue'
import { TextColumn, DateColumn, ConfirmedAction, ActionColumn, SimpleAction, DownloadLinkAction, IconColumn, Icon } from "@/components/table2/table_util.js";
import BaseModal from '@/components/core/BaseModal.vue'
import BaseButton from '@/components/core/BaseButton.vue'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import { sanitize } from '@/helpers/string-helper.js';
import DownloadLink from '../core/download/DownloadLink.vue';
import BaseFilter from "@/components/core/BaseFilter.vue";
import NoData from '@/components/core/NoData.vue';
import { PhCheck, PhX } from "phosphor-vue";

export default {
  model: {
    prop: 'selectedMarketing',
    event: 'selectedMarketing',
  },
  props: {
    lastMarketing: {
      type: Boolean,
      default: false
    },
    selectedMarketing: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      markedMarketing: [],
      loading: false,
      configFilter: {
        noResetOnDefaultSearchInputExit: true,
        filterType: 'marketingMSC',
        hideFirstColumn: true,
        singleFilter: false,
      },
      defaultOptions: {
        thisYear: {
          value: true
        }
      },
      currentFilter: {},
    }
  },
  methods: {
    sanitize(htmlString) {
      return sanitize(htmlString);
    },
    async getMscMarketing(filter) {
      try {
        this.loading = true;
        let params = null;
        if(filter) {
          params = { lastMarketing: null, filter}
        } else {
          params = { lastMarketing: this.lastMarketing}
        }
        await this.$store.dispatch(MSC_MARKETING_TYPES.ACTIONS.GET_MARKETING_CAMPAIGN_LIST, params);
      } finally {
        this.loading = false;
      }
    },
    handleEdit(id) {
      this.$router.push({ path: `/intern/marketing-campaign/marketing-campaign-edit/${id}` })
    },
    async deleteEntry(id) {
      await this.$store.dispatch(MSC_MARKETING_TYPES.ACTIONS.DELETE_MARKETING_CAMPAIGN, id);
      this.getMscMarketing(this.currentFilter);
    },
    async showMarketing(marketing) {
      this.$router.push({ name: 'preview-marketing-campaign', params: { marketing } })
    },
    handleSearch(filter) {
      if(!filter) {
        return
      }

      let mappedFilter = null;

      filter.map(f => {
        const filterObj = f;
        let mappedFilterVal = {}
        if(filterObj.key === 'period') {
          mappedFilterVal = filterObj.value.reduce((acc, curr) => {
            return {...acc, [filterObj.key + '_' + curr.key]: curr.value}
          }, {});
        } else {
          mappedFilterVal = {[filterObj.key]: filterObj.value}
        }
        mappedFilter = (mappedFilter == null) ? {
          ...mappedFilterVal
        } : {
          ...mappedFilter,
          ...mappedFilterVal
        };
      })

      this.currentFilter = mappedFilter;
      this.getMscMarketing(mappedFilter);
    },
    copyEntry(id) {
      this.$store.dispatch(MSC_MARKETING_TYPES.ACTIONS.COPY_MARKETING_CAMPAIGN, id)
    }
  },
  computed: {
    ...mapGetters({
      marketingCampaigns: MSC_MARKETING_TYPES.GETTERS.MARKETING_CAMPAIGNS,
      marketingCampaignCount: MSC_MARKETING_TYPES.GETTERS.MARKETING_CAMPAIGNS_COUNT,
    }),
    headers() {
      return {
        lockedLeft: [
          DateColumn("dateCreated", "Datum"),
          TextColumn("title", "Titel").makeLink(),
          TextColumn("activeIcon", "Ist Aktiv"),
          DateColumn("dateFrom", "Zeitraum von"),
          DateColumn("dateUntil", "Bis"),
        ],
        lockedRight: [
          ActionColumn("actions")
        ],
      }
    },
    rows() {
      return this.marketingCampaigns.map((marketing) => {
        return {
          ...marketing,
          activeIcon: marketing.active ? "Ja" : "Nein",
          actions: [
            SimpleAction('VIEW', 'PhEye', 'Anschauen'),
            SimpleAction("EDIT", 'PhPencil', "Bearbeiten"),
            SimpleAction('COPY', 'PhCopy', 'kopieren'),
            ConfirmedAction("DELETE", 'PhTrash', "Löschen", "Wollen Sie diesen Eintrag wirklich löschen?"),
            DownloadLinkAction(
              "SHOW_CONFIRMED", 
              'PhListChecks', 
              'Bestätigte Makler herunterladen', 
              'Bestaetigung.xlsx', 
              '/marketing_campaign_gelesen', 
              { id: marketing.id }
            ),
          ]
        };
      });
    },
    searchMetadata(){
      const filters =  [
        {
          type: 'group',
          key: 'allgemeine',
          label: 'Allgemeine',
          allowMultipleSearch: true,
          menuItems: [
            {
              type: 'text',
              label: 'Titel',
              key: 'title',
              emptyDenied: true,
            },
            {
              type: 'dateRange',
              label: 'zwischen',
              key: 'period',
              emptyDenied: false,
            },
            {
              type: 'default',
              label: 'von diesem Jahr',
              key: 'thisYear',
            },
            {
              type: 'text',
              label: 'der letzten Monate',
              key: 'lastMonths',
              emptyDenied: true,
            },
          ]
        },
      ];
      return filters;
    },
  },
  mounted() {
    const defaultFilter = {
      group: 'allgemeine',
      key: 'thisYear',
      value: true
    }
    this.handleSearch([defaultFilter])
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu Marketing', 
      to,
      from,
    });
    next();
  },
  components: {
    Table,
    BaseModal,
    BaseButton,
    GhostLoading,
    DownloadLink,
    BaseFilter,
    NoData,
  }
}
</script>

<style scoped>
::v-deep p > img {
  max-width: unset;
}
</style>