var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "open-link__container" }, [
    _vm.errorMessage
      ? _c(
          "div",
          { staticClass: "open-link--message open-link--error-message" },
          [
            _c("PhWarningCircle", {
              staticClass: "open-link--message-icon",
              attrs: { size: _vm.DEFAULT_ICON_SIZE }
            }),
            _c("div", {
              staticClass: "open-link--message-text",
              domProps: { innerHTML: _vm._s(_vm.sanitize(_vm.errorMessage)) }
            }),
            _c("div", { staticClass: "open-link--message-actions" }, [
              _c(
                "button",
                {
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.closeWindow()
                    }
                  }
                },
                [
                  _c("PhX", {
                    staticClass: "open-link--message-action-icon",
                    attrs: { size: 16 }
                  }),
                  _vm._v(" " + _vm._s(_vm.DEFAULT_CLOSE_BUTTON_LABEL) + " ")
                ],
                1
              )
            ])
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "open-link--message open-link--wait-message" },
          [
            _c("PhHourglassSimple", {
              staticClass: "open-link--message-icon",
              attrs: { size: _vm.DEFAULT_ICON_SIZE }
            }),
            _c("div", { staticClass: "open-link--message-text" }, [
              _vm._v(_vm._s(_vm.DEFAULT_LOADING_MESSAGE))
            ])
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }