<template>
    <div>
      <PageHeaderTitleNavigation 
        class="setup-email-texts--page-header-title-navigation"
        title="Einstellungen E-Mail-Texte"
        :actions="headerActions"
        @action-DISCARD="resetChanges()"
      />

    <div :key="selectedNodeId">
        <div class="box__container">
          <ContentWithSidebar :mobileContent="mobileContentActive" @mobileContentChange="handleMobileContentChange()">
              <template #sidebar>
                <Tree
                  v-if="templatesTree && templatesTree.nodes && templatesTree.nodes.length"
                  :items="templatesTree.nodes" childrenKey="nodes" idKey="nodeId" 
                  :itemSelectedId="selectedNodeId" @itemSelect="selectNode($event)"
                  uniqueId="dbac7af8-82eb-11ee-b962-0242ac120002"
                  class="explorer__tree_nodes">
                  <template #default="{item}">
                    <div class="explorer__tree_node_content">
                      <span class="explorer__tree_node_label">{{ item.label }}</span>
                      <span v-if="item.numberOfRecords" class="explorer__tree_node_total">{{ item.numberOfRecords }}</span>
                    </div>
                  </template>
                </Tree>
                <GhostLoading v-else-if="loadingTemplatesTree" class="mt-2" type="block" :config="{ block: { height: 24, }, }" :repeat="10" />
                <div v-else>Keine Daten</div>
              </template>
              <template #content v-if="isLoading">
                <GhostLoading type="input" />
                <GhostLoading type="block" :config="{ block: { height: 250, }, }" />
              </template>
              <template #content v-else>
                <div v-for="(template, i) in templates" :key="i" class="mb-4">
                  <div v-html="template.labelTop"></div>
                  <template v-if="!isStandardChanged[i]">
                    <InputField
                      v-if="template.withSubject"
                      v-model="subjectStandard[i]"
                      @change="dataChanged()"
                    />
                  </template>
                  <template v-else>
                    <InputField
                      v-if="template.withSubject"
                      v-model="subject[i]"
                      @change="dataChanged()"
                    />
                  </template>
                  <InputToggleSwitch
                    v-if="!template.readonly"
                    v-model="isStandardChanged[i]"
                    :label="template.template.templateId !== 'RechtlicherFusstext' ? 'Eigenen Text verwenden' : 'Es sind keine Angaben erforderlich'"
                    inLineLabel
                    @input="getStandardTemplate($event, template.template.templateId, template.template.nodeId, i); dataChanged();"
                  />
                  <div v-if="template.template.templateId === 'RechtlicherFusstext'">
                    <template v-if="!isStandardChanged[i]">
                      <TemplateHtmlEditor
                        v-if="!template.textOnly"
                        :placeholders="template.placeholders || {}"
                        v-model="html[i]"
                        @change="dataChanged()"
                      />
                      <InputTextArea
                        v-else
                        v-model="html[i]"
                        :rows="4"
                        @change="dataChanged()"
                      />
                    </template>
                    <template v-else>
                      <div v-html="htmlStandard[i]" class="fc-textarea" :class="{ 'template-default-text': template.textOnly, }"></div>
                    </template>
                  </div>
                  <template v-else>
                    <div>
                      <template v-if="isStandardChanged[i]">
                        <TemplateHtmlEditor
                          v-if="!template.textOnly"
                          :placeholders="template.placeholders || {}"
                          v-model="html[i]"
                          @change="dataChanged()"
                        />
                        <InputTextArea
                          v-else
                          v-model="html[i]"
                          :rows="4"
                          @change="dataChanged()"
                        />
                      </template>
                      <template v-else>
                        <div v-html="htmlStandard[i]" class="fc-textarea" :class="{ 'template-default-text': template.textOnly, }"></div>
                      </template>
                    </div>
                  </template>
                  <div v-html="template.labelBottom"></div>
                </div>
              </template>
            </ContentWithSidebar>
        </div>
    </div>
  </div>
</template>

<script>
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import TEMPLATES_EDIT_TYPES from '@/store/templatesEdit/types';
import { mapGetters } from 'vuex'
import ContentWithSidebar from '@/components/core/ContentWithSidebar.vue';
import Tree from '@/components/core/Tree.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import InputField from '@/components/core/forms/InputField.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import TemplateHtmlEditor from './TemplateHtmlEditor.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import InteractiveHelpCommonsMixin from '@/assets/mixins/interactivehelpcommonsmixins.js';

import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    ContentWithSidebar,
    Tree,
    GhostLoading,
    InputField,
    InputToggleSwitch,
    TemplateHtmlEditor,
    InputTextArea,
  },
  data() {
    return {
      mobileContentActive: null,
      hasDataChanged: false,
      loading: false,
      saving: false,
      selectedNodeId: null,
      isPreview: false,
      isStandardChanged: {},
      templateId: null,
      html: {},
      subject: {},
      subjectStandard: {},
      htmlStandard: {},
      standardParams: {},
      previousTexts: [],
    }
  },
  watch: {
    standardTemplate(val) {
      if (val) {
        this.subject = {};
        this.subjectStandard[this.standardParams.queue] = val.subject;
        this.htmlStandard[this.standardParams.queue] = val.html;
        this.standardParams = {};
      }
    },
    templates(val) {
      if (val) {
        this.subject = {}
        this.html = {}
        this.setTemplates(val);
        this.previousTexts = this.templates ? this.mapTemplatesToEditor() : [];
      }
    },
  },
  computed: {
    ...mapGetters({
      preview: TEMPLATES_EDIT_TYPES.GETTERS.GET_PREVIEW,
      templatesTree: TEMPLATES_EDIT_TYPES.GETTERS.GET_TEMPLATES_TREE,
      templates: TEMPLATES_EDIT_TYPES.GETTERS.GET_TEMPLATES,
      standardTemplate: TEMPLATES_EDIT_TYPES.GETTERS.GET_STANDARD_TEMPLATE,
    }),
    loadingTemplatesTree() {
      return !this.templatesTree;
    },
    isLoading() {
      return this.loading || this.saving;
    },
    headerActions() {
      return [
        PageHeaderSimpleAction('DISCARD', 'Änderungen verwerfen')
          .withDisabled(() => this.isLoading || !this.hasDataChanged)
          .withVisible(() => !!this.selectedNodeId),
      ];
    },
    isTemplateChanged() {
      const previousTexts = this.previousTexts
      const newTexts = this.mapTemplatesToEditor()
      return this.templates && JSON.stringify(previousTexts) !== JSON.stringify(newTexts)
    },
  },
  methods: {
    handleMobileContentChange(content) {
      this.mobileContentActive = content;
    },
    async selectNode(whatNode) {
      this.$scrollIntoView(document.querySelector('.setup-email-texts--page-header-title-navigation'), { offsetY: 8 });

      if(this.selectedNodeId === whatNode.nodeId) return;

      await this.saveTemplates();
      this.findNode(whatNode);
    },
    mapTemplatesToEditor() {
      return this.templates?.map((t, i) => ({
          nodeId: t.template.nodeId,
          templateId: t.template.templateId,
          html: this.html[i],
          subject: this.subject[i],
          changed: this.isStandardChanged[i]
        }))
    },
    async findNode(whatNode) {
      try {
        this.loading = true;
        this.selectedNodeId = whatNode.nodeId;
        await this.$store.dispatch(TEMPLATES_EDIT_TYPES.ACTIONS.GET_TEMPLATES, whatNode.nodeId);
        this.isStandardChanged = {};
        this.setTemplates(this.templates);
        if (!whatNode?.nodes?.length) {
          this.mobileContentActive = 'content'
        }
      } finally {
        this.loading = false;
      }
    },
    setTemplates(val) {
      val.map((t, i) => {
        this.$set(this.subject, i, t.template?.subject);
        this.$set(this.subjectStandard, i, t.template?.subject);

        this.$set(this.html, i, t.template?.html);
        this.$set(this.htmlStandard, i, t.template?.html);

        this.$set(this.isStandardChanged, i, !!t.template?.changed);
      })
    },
    togglePreview(data) {
      if (this.isPreview) {
        this.$store.dispatch(TEMPLATES_EDIT_TYPES.ACTIONS.GET_PREVIEW, data);
      }
    },
    getStandardTemplate(isStandard, templateId, nodeId, queue) {
      if (!isStandard && templateId !== 'RechtlicherFusstext') {
        this.$store.dispatch(TEMPLATES_EDIT_TYPES.ACTIONS.GET_STANDARD_TEMPLATE, {templateId, nodeId});
        this.standardParams = {templateId, nodeId, queue};
      }
      this.isStandardChanged = {...this.isStandardChanged}
    },
    dataChanged() {
      this.hasDataChanged = true;
    },
    async saveTemplates() {
      if(!this.hasDataChanged) return;

      try {
        this.saving = true;
        await this.$store.dispatch(
          TEMPLATES_EDIT_TYPES.ACTIONS.SAVE_TEMPLATE_SETUP,
          this.mapTemplatesToEditor()
        );
        this.hasDataChanged = false;
      } finally {
        this.saving = false;
      }
    },
    resetChanges() {
      this.hasDataChanged = false;
      this.$set(this, 'isStandardChanged', {});
      this.$set(this, 'subject', {});
      this.$set(this, 'html', {});
      if(this.templates) {
        this.setTemplates(this.templates);
      }
      this.previousTexts = this.templates ? this.mapTemplatesToEditor() : [];
    },
  },
  mounted() {
    this.$store.dispatch(TEMPLATES_EDIT_TYPES.ACTIONS.GET_TEMPLATES_TREE);
  },
  async beforeRouteLeave(to, from, next) {
    await this.saveTemplates();
    next();
  },
  beforeDestroy() {
    this.$store.dispatch(TEMPLATES_EDIT_TYPES.ACTIONS.RESET, null);
    this.$store.commit(TEMPLATES_EDIT_TYPES.MUTATIONS.GET_TEMPLATES, null);
    this.$store.commit(TEMPLATES_EDIT_TYPES.MUTATIONS.RESET_STATE);
  },
}
</script>

<style scoped>
.top-buttons {
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
}
.template-default-text {
  white-space: break-spaces;
}
</style>
