var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("DashboardPanel", {
        staticClass: "mb-4",
        attrs: {
          id: "e9561e27-46f1-45b6-8cd9-449a313eccdc",
          data: _vm.dashboardData,
          title: "Altersvorsorge",
          canEdit: "",
          is3Columns: "",
          hideTopboxContainer: ""
        },
        scopedSlots: _vm._u([
          {
            key: "topBox",
            fn: function() {
              return [
                _c("HinweiseUndFehler", { attrs: { hints: _vm.hints } }),
                _c(
                  "div",
                  { staticClass: "box__container" },
                  [
                    _c("ComboBox", {
                      attrs: {
                        label: "Depotnummer",
                        values: _vm.fremdnrValues,
                        isComponentHalfSize: ""
                      },
                      on: { change: _vm.retrieveAltersvorsorge },
                      model: {
                        value: _vm.fremdnr,
                        callback: function($$v) {
                          _vm.fremdnr = $$v
                        },
                        expression: "fremdnr"
                      }
                    }),
                    _c("ComboBox", {
                      attrs: {
                        label: "Jahr",
                        values: _vm.yearValues,
                        isComponentHalfSize: ""
                      },
                      on: { change: _vm.retrieveAltersvorsorge },
                      model: {
                        value: _vm.year,
                        callback: function($$v) {
                          _vm.year = $$v
                        },
                        expression: "year"
                      }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }