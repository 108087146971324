var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.form.isManagement
        ? _c(
            "div",
            [
              _c(
                "label",
                {
                  staticClass: "mb-0 font-strong",
                  attrs: { for: "knowledge" }
                },
                [_vm._v("Kenntnisse")]
              ),
              _c("InputSlider", {
                attrs: {
                  values: _vm.knowledges,
                  disabled: _vm.isDisabled || _vm.isRefusedPage
                },
                on: {
                  input: function($event) {
                    return _vm.doExperienceCardChange(
                      _vm.form,
                      _vm.category,
                      "knowledge",
                      $event
                    )
                  }
                },
                model: {
                  value: _vm.form.knowledge,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "knowledge", $$v)
                  },
                  expression: "form.knowledge"
                }
              })
            ],
            1
          )
        : _vm._e(),
      !_vm.form.isManagement
        ? _c(
            "div",
            [
              _c(
                "label",
                {
                  staticClass: "mb-0 font-strong",
                  attrs: { for: "experience" }
                },
                [_vm._v("Erfahrungen")]
              ),
              _c("InputSlider", {
                attrs: {
                  values: _vm.knowledges,
                  disabled: _vm.isDisabled || _vm.isRefusedPage
                },
                on: {
                  input: function($event) {
                    return _vm.doExperienceCardChange(
                      _vm.form,
                      _vm.category,
                      "experience",
                      $event
                    )
                  }
                },
                model: {
                  value: _vm.form.experience,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "experience", $$v)
                  },
                  expression: "form.experience"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        [
          _c(
            "label",
            { staticClass: "mb-0 font-strong", attrs: { for: "sum" } },
            [_vm._v("Ungefährer Betrag (Euro)")]
          ),
          _c("InputSlider", {
            attrs: {
              values: _vm.sums,
              disabled: _vm.isDisabled || _vm.isRefusedPage
            },
            on: {
              input: function($event) {
                return _vm.doExperienceCardChange(
                  _vm.form,
                  _vm.category,
                  "sum",
                  $event
                )
              }
            },
            model: {
              value: _vm.form.sum,
              callback: function($$v) {
                _vm.$set(_vm.form, "sum", $$v)
              },
              expression: "form.sum"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "label",
            { staticClass: "mb-0 font-strong", attrs: { for: "transaction" } },
            [_vm._v("Anzahl Transaktionen p.a.")]
          ),
          _c("InputSlider", {
            attrs: {
              values: _vm.transactions,
              disabled: _vm.isDisabled || _vm.isRefusedPage
            },
            on: {
              input: function($event) {
                return _vm.doExperienceCardChange(
                  _vm.form,
                  _vm.category,
                  "transaction",
                  $event
                )
              }
            },
            model: {
              value: _vm.form.transaction,
              callback: function($$v) {
                _vm.$set(_vm.form, "transaction", $$v)
              },
              expression: "form.transaction"
            }
          })
        ],
        1
      ),
      _c("InputField", {
        attrs: {
          label: "Anmerkungen",
          disabled: _vm.isDisabled || _vm.isRefusedPage,
          isComponentHalfSize: ""
        },
        on: {
          change: function($event) {
            return _vm.doExperienceCardChange(
              _vm.form,
              _vm.category,
              "annotationKenntnisseCard",
              $event
            )
          }
        },
        model: {
          value: _vm.form.annotationKenntnisseCard,
          callback: function($$v) {
            _vm.$set(_vm.form, "annotationKenntnisseCard", $$v)
          },
          expression: "form.annotationKenntnisseCard"
        }
      }),
      _vm.isDetailButtonVisible()
        ? _c(
            "BaseButton",
            {
              attrs: {
                disabled: _vm.isDisabled || _vm.isSaving,
                isSecondary: ""
              },
              on: {
                click: function($event) {
                  return _vm.openSaveAllModal()
                }
              }
            },
            [
              _c("span", [
                _vm._v(
                  " Detailangaben für andere Kenntnisse und Erfahrungen übernehmen"
                )
              ])
            ]
          )
        : _vm._e(),
      _c(
        "BaseModal",
        {
          ref: "saveForAll",
          attrs: {
            labelButtonConfirm: "Daten übernehmen",
            labelButtonCancel: "Abbrechen"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.doSaveForAll()
            },
            onCancelButton: function($event) {
              return _vm.closeModal()
            },
            onCloseButton: function($event) {
              return _vm.closeModal()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [_vm._v(" Angaben übernehmen ")]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", [
            _vm._v("Die Angaben von Detailangaben zu "),
            _c("span", { staticStyle: { "font-weight": "bold" } }, [
              _vm._v(_vm._s((_vm.category && _vm.category.label) || ""))
            ]),
            _vm._v(" auch übernehmen für:")
          ]),
          _vm._l(_vm.selectedCategories, function(cat) {
            return _c(
              "div",
              { key: cat.substepKey },
              [
                _c("InputCheckBoxItem", {
                  attrs: { label: cat.title },
                  model: {
                    value: cat.value,
                    callback: function($$v) {
                      _vm.$set(cat, "value", $$v)
                    },
                    expression: "cat.value"
                  }
                })
              ],
              1
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }