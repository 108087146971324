var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _c(
        "BaseButton",
        {
          on: {
            click: function($event) {
              return _vm.openAddModal()
            }
          }
        },
        [_vm._v("Neue")]
      ),
      !_vm.loading && _vm.rows.length
        ? _c("Table", {
            attrs: {
              tableId: "de53de84-95e7-42db-a20a-329c6c57ffe4",
              headers: _vm.headers,
              rows: _vm.rows,
              rowsPerPage: 10
            },
            on: {
              "click-bereich": _vm.openEditModal,
              "action-DELETE": _vm.openDeleteModal
            }
          })
        : _vm.loading
        ? _c("GhostLoading", { attrs: { type: "table" } })
        : _c("NoData"),
      _c("BaseModal", {
        ref: "courtageDataModal",
        attrs: {
          size: "md",
          modalTitle: "Zubringercourtage bearbeiten",
          showDefaultButtons: false
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _c("p", [
                  _vm._v(
                    "Bitte tragen Sie hier den Prozentsatz ein den der Zubringer in Relation zu der Courtage des Vermittlers bekommen soll, dem der Kunde zugeordnet ist."
                  )
                ]),
                _c("ComboBox", {
                  attrs: {
                    label: "Bereich",
                    values: _vm.customerDataConfig.courtageBereich,
                    validateUntouched: ""
                  },
                  model: {
                    value: _vm.form.bereich,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "bereich", $$v)
                    },
                    expression: "form.bereich"
                  }
                }),
                _c("div", { staticClass: "input-forms__container" }, [
                  _c("div", { staticClass: "row mt-0 mb-0" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-3 col-12" },
                      [
                        _c("InputField", {
                          attrs: { label: "Zubringer", validateUntouched: "" },
                          on: {
                            input: function($event) {
                              return _vm.findZubringerDataDebounce($event)
                            },
                            onBlur: function($event) {
                              return _vm.checkIfZubringerDataIsValid()
                            }
                          },
                          model: {
                            value: _vm.form.zubringernr,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "zubringernr", $$v)
                            },
                            expression: "form.zubringernr"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-9 col-12 mt-md-24px mt-3" },
                      [
                        _c("InputField", {
                          attrs: { disabled: true },
                          model: {
                            value: _vm.form.zubringerFullName,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "zubringerFullName", $$v)
                            },
                            expression: "form.zubringerFullName"
                          }
                        })
                      ],
                      1
                    ),
                    _vm.isZubringerInvalid
                      ? _c("div", { staticClass: "col-12 color-danger" }, [
                          _vm._v(
                            " Die Vermittlernummer des Zubringers ist nicht korrekt! "
                          )
                        ])
                      : _vm._e()
                  ])
                ]),
                _vm.isIntern
                  ? [
                      _c("ComboBox", {
                        attrs: { label: "Courtage", values: _vm.absolutValues },
                        model: {
                          value: _vm.form.absolut,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "absolut", $$v)
                          },
                          expression: "form.absolut"
                        }
                      })
                    ]
                  : _vm._e(),
                _c("InputField", {
                  attrs: {
                    label: "Abschluss / Einmalige VV-Strategiegebühr",
                    type: "percent",
                    precision: _vm.PRECISION
                  },
                  model: {
                    value: _vm.form.provAbschluss,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "provAbschluss", $$v)
                    },
                    expression: "form.provAbschluss"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    label: "Bestand",
                    type: "percent",
                    precision: _vm.PRECISION
                  },
                  model: {
                    value: _vm.form.provBestand,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "provBestand", $$v)
                    },
                    expression: "form.provBestand"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    label: "Servicegebühr / VV-Vermittlungsgebühr",
                    type: "percent",
                    precision: _vm.PRECISION
                  },
                  model: {
                    value: _vm.form.provServicegebuehr,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "provServicegebuehr", $$v)
                    },
                    expression: "form.provServicegebuehr"
                  }
                }),
                _c("InputField", {
                  attrs: {
                    label: "MwSt",
                    type: "percent",
                    validateUntouched: "",
                    disabled: !_vm.customerDataConfig.hatZubringerOhneMwSt,
                    precision: 2
                  },
                  model: {
                    value: _vm.form.mwst,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "mwst", $$v)
                    },
                    expression: "form.mwst"
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c(
                  "BaseButton",
                  {
                    staticClass: "mr-3",
                    attrs: { isSecondary: "" },
                    on: {
                      click: function($event) {
                        return _vm.closeCourtageDataModal()
                      }
                    }
                  },
                  [_vm._v("Abbrechen")]
                ),
                _c(
                  "BaseButton",
                  {
                    attrs: {
                      disabled:
                        _vm.validation.isInvalid("form") ||
                        _vm.isZubringerInvalid
                    },
                    on: {
                      click: function($event) {
                        _vm.saveForm()
                        _vm.closeCourtageDataModal()
                      }
                    }
                  },
                  [_vm._v("Speichern")]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("BaseModal", {
        ref: "courtageDeleteModal",
        attrs: { size: "sm", modalTitle: "Courtage Zubringer löschen" },
        on: {
          onConfirmButton: function($event) {
            return _vm.deleteCourtageItem()
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function() {
              return [
                _vm._v(
                  " Soll die Zubringercourtage für den Vermittler " +
                    _vm._s(_vm.courtageItemToDelete.zubringernr) +
                    " wirklich gelöscht werden? "
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }