<template>
  <diV>
    <GesellschaftTitleNavigation />

    <DocumentsFilter />

    <div class="box__container">
      <GhostLoading v-if="loading" :title="TABLE_TITLE" type="table" :config="{ table: { rows: 5, } }" />
      <template v-else>
        <DragnDropArea @files="dropFiles" hoverText="Datei hier ablegen" class="clearfix">
          <DocumentsTable ref="documentsTableEl" :title="TABLE_TITLE" :nodeId="nodeId" :rowsPerPage="rowsPerPage" />
        </DragnDropArea>
      </template>
    </div>
  </diV>
</template>

<script>
import { mapGetters } from 'vuex';
import GESELLSCHAFT_TYPES from '@/store/gesellschaft/types';

import DocumentsFilter from '@/components/documents/DocumentsFilter.vue';
import DocumentsTable from '@/components/documents/DocumentsTable.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import DragnDropArea from '@/components/fileUpload/DragnDropArea.vue';
import GesellschaftTitleNavigation from '@/views/gesellschaft/intern/GesellschaftTitleNavigation.vue'
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';

const TABLE_TITLE = 'Eigene Dokumente';

export default {
  components: {
    DocumentsFilter,
    DocumentsTable,
    GhostLoading,
    DragnDropArea,
    GesellschaftTitleNavigation,
  },
  data() {
    return {
      TABLE_TITLE,
      loading: false,
      rowsPerPage: 20,
    };
  },
  computed: {
    ...mapGetters({
      gesellschaft: GESELLSCHAFT_TYPES.GETTERS.GET_GESELLSCHAFT,
    }),
    nodeId() {
      return this.gesellschaft?.nodeId || null;
    },
  },
  methods: {
    async getEigeneDokumenteData() {
      this.loading = true;

      const payload = {
        gesellschaftId: encodeURIComponent(this.$route.params.gesellId),
      };
      await this.$store.dispatch(GESELLSCHAFT_TYPES.ACTIONS.GET_EIGENE_DOKUMENTE, payload);

      this.loading = false;
    },
    dropFiles(files) {
      if('documentsTableEl' in this.$refs) {
        this.$refs.documentsTableEl.dateiHochladen(files);
      }
    },
  },
  created() {
    this.getEigeneDokumenteData();
  },
}
</script>