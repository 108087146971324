<template>
  <div class="adddelete_container">
    <div class="add_delete_buttons" v-if="isBroker">
      <div class="adder" @click="add">
        <ph-plus-circle title="Hinzufügen" :size="16" />
      </div>
      <div class="remover" @click="remove">
        <ph-trash title="Löschen" :size="16" />
      </div>
    </div>
    <div :class="{adddelete_content:isBroker}">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import CORE_TYPES from "@/store/core/types";
import CMS_TYPES from "@/store/cms/types";
import { mapGetters, dispatch } from "vuex";
import { PhPlusCircle, PhTrash } from 'phosphor-vue';

export default {
  components: {
    PhPlusCircle,
    PhTrash,
  },
  computed: {
    ...mapGetters({
      isBroker: CORE_TYPES.GETTERS.IS_BROKER
    })
  },
  props: {
    genus: {
      type: String,
      default: "",
      required: true
    },
    species: {
      type: String,
      default: "",
      required: true
    }
  },
  methods: {
    add() {
      this.$store.dispatch(CMS_TYPES.ACTIONS.ADD_EDITABLE_GROUP, {
        genus: this.genus,
        species: this.species
      });
    },
    remove() {
      this.$store.dispatch(CMS_TYPES.ACTIONS.REMOVE_EDITABLE_GROUP, {
        genus: this.genus,
        species: this.species
      });
    }
  }
};
</script>
<style scoped>
.adddelete_content {
  border-style: solid dashed dashed dashed;
  border-width: 4px;
  padding: 1em;
  border-color: var(--color-background);
}
.add_delete_buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: var(--color-top-nav-background);
}
.adder,
.remover {
  font-size: 1.5em;
}
.adder {
  color: var(--color-active);
}
.remover {
  color: var(--color-danger);
}
</style>