<template>
  <div>
    <PageHeaderTitleNavigation 
      title="eFonds24"
      subtitle="eZeichnungen ohne Zuweisung" 
    />

    <div class="row my-0">
      <div class="col-12">
        <AntragSidetext 
          :antragId="id"
          :antragData="data"
          :warnings="warnings">
        </AntragSidetext>
      </div>
    </div>

    <div class="box__container">
        <div class="col-12">
            <GhostLoading v-if="loading" type="table" />
            <Table v-else-if="rows.length"
                tableId="701e2bd4-29b4-427e-96da-40ad35e57712"
                :headers="headers"
                :rows="rows"
                rowId="antragsnrIntern"
                :rowsPerPage="20"
                @action-MAPPE="assignEZeichnungToMappe"
                @action-EDIT="actionEdit"
                @action-DELETE="actionDelete"
            />
            <NoData v-else />
      </div>   
    </div>

    <BaseModal ref="removeModal"  labelButtonCancel="Nein" labelButtonConfirm="Ja"
      @onCancelButton="closeRemoveModal" @onCloseButton="closeRemoveModal" @onConfirmButton="removeAntrag($event)">
      <template v-slot:modalTitle>
        Position löschen
      </template>
      <div>Soll die folgende Position <span class="font-bold">{{antragToRemove && antragToRemove.wertpapiername}}</span> 
        ISIN: <span class="font-bold">{{antragToRemove && antragToRemove.isin}}</span> wirklich gelöscht werden?</div>
      <div class="mt-3 font-bold">Hinweis:</div>
      <div>Gelöschte Positionen müssen aktuell noch zusätzlich manuell im eFonds System gelöscht werden, um vollständig entfernt zu werden.</div>
    </BaseModal>

    <BaseModal ref="fehlerModal" labelButtonConfirm="Ok" :showCancelButton="false" :showCloseButton="false">
      <template v-slot:modalTitle>
        <i class="las la-exclamation-triangle mr-2" style="color: orangered"></i>
        Fehler
      </template>
      <div>{{errorStr}}</div>
    </BaseModal>

  </div>
</template>

<script>
import Table from "@/components/table2/Table.vue";
import {TextColumn, DateColumn, NumberColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js";
import { mapGetters } from "vuex";
import { calculateAntragID } from '@/components/antrag/antrag-utils';
import ANTRAG_TYPES from '@/store/antrag/types';
import ANTRAG_EFONDS_TYPES from '@/store/antragEfonds/types';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import AntragSidetext from '@/components/antrag/AntragSidetext.vue'; 
import BaseModal from '@/components/core/BaseModal.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';

export default {
  components: {
    Table,
    OptionMenu,
    PageHeaderTitleNavigation,
    AntragSidetext,
    BaseModal,
    GhostLoading,
    NoData,
  },
  mounted() {
    this.findEFondsList();
  },
  data() {
    return {
      antragsName: 'eZeichnung-list',
      lagerstelle: 'efonds24.de',  
      loading: false,
      antragToRemove: null,
      errorStr: null,
      warnings: [],
      headers: {
        lockedLeft: [
            TextColumn("art", "Art"),
        ],
        center: [
            DateColumn("date", "Datenstand"),
            TextColumn("state", "Status"),
            TextColumn("gesellschaft", "Gesellschaft"),
            TextColumn("wertpapiername", "Wertpapier"),
            NumberColumn("betrag", "Betrag", 2),
            TextColumn("currency", "Währung"),
            TextColumn("isin", "ISIN"),
            NumberColumn("sri", "SRI"),
        ],
        lockedRight: [
            ActionColumn("actions"),
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      antraegeData: ANTRAG_TYPES.GETTERS.ANTRAEGE_DATA,
    }),   
    id() {
      return calculateAntragID({lagerstelle: this.lagerstelle, antragsName: this.antragsName });
    },
    data() {
      return this.antraegeData && this.antraegeData[this.id];
    },
    rows() {
        return (this.data?.records || []).map(row => {
            const actions = [
                SimpleAction("MAPPE", 'PhFileArrowDown', "In Beratungsmappe übernehmen"),
                SimpleAction("DELETE", 'PhTrash', "Löschen"),
            ];
            if (row.state !== 'Eingereicht')
                actions.splice(1, 0, SimpleAction("EDIT", 'PhArrowRight', "Bearbeiten"));
            return {
                ...row,
                actions,
            };
        })
    },
  },
  methods: {
    async findEFondsList() {
      try {
        this.loading = true;
        await this.$store.dispatch(ANTRAG_EFONDS_TYPES.ACTIONS.GET_EFONDS_LIST, {id: this.id});
      } finally {
        this.loading = false;
      }
    },
    actionEdit(row) {
        this.$router.push({path: `/beratung/formulare/antrag/eZeichnung`, 
            query: {antragsdatenId: row.antragsDatenId, antragsnrIntern: row.antragsnrIntern }});
    },
    actionDelete(row) {
        this.antragToRemove = row;
        this.$refs.removeModal?.open();
    },
    assignEZeichnungToMappe(row) {
      if (this.$route.query.beratungsMappeId) {
        this.$store.dispatch(ANTRAG_EFONDS_TYPES.ACTIONS.ADD_EZEICHNUNG_TO_MAPPE, {beratungsMappeId: this.$route.query.beratungsMappeId, antragsnrIntern: row.antragsnrIntern, id: this.id});
      }
    },
    closeRemoveModal() {
      this.antragToRemove = null;
      this.$refs.removeModal?.close();
    },
    removeAntrag() {
      if (this.antragToRemove?.antragsnrIntern) {
        this.$store.dispatch(ANTRAG_EFONDS_TYPES.ACTIONS.REMOVE_EZEICHNUNG, { antragsnrIntern: this.antragToRemove.antragsnrIntern, id: this.id } );
        this.antragToRemove = null;
      }
    }
  },
};


</script>