<template>
  <div class="box__container">
    <div class="box__title">Datenschutz / Sicherheiten</div>
    <InputRadioBoxGroup
      title="Ist Ihr Mobiltelefon mit einer Sicherheitssperre gesichert?"
      :value="form.INPUT_HANDY_GESICHERT"
      :values="values"
      @input="fieldChanged($event, 'INPUT_HANDY_GESICHERT')"
    />
    <InputRadioBoxGroup
      title="Sind Ihre elektronischen Geräte (PC, Laptop, Tablett, etc.) zumindest mit einem Passwort geschützt?"
      :value="form.INPUT_GERAETE_MIT_PASSWORT"
      :values="values"
      @input="fieldChanged($event, 'INPUT_GERAETE_MIT_PASSWORT')"
    />
    <InputRadioBoxGroup
      title="Werden diese Sicherheitsstandards auch von Ihren Assistenten/Mitarbeitern eingehalten?"
      :value="form.INPUT_MITARBEITER_SICHERHEITSSTANDARDS"
      :values="values"
      @input="fieldChanged($event, 'INPUT_MITARBEITER_SICHERHEITSSTANDARDS')"
    />

  </div>
</template>
<script>
import InputRadioBoxGroup from "@/components/core/forms/radiobox/InputRadioBoxGroup.vue";

export default {
  components: {
    InputRadioBoxGroup,
  },
  props: {
    form: {},
  },
  data() {
    return {
      values: [{label: 'Ja', value: 'ja'}, {label: 'Nein', value: 'nein'}]
    };
  },
  methods: {
    fieldChanged(newValue, propName) {
      const payload = { name: propName, value: newValue };
      this.$emit("fieldChanged", payload);
    },
  },
};
</script>