export const MUTATION_PREFIX = 'TRANSAKTION_VERLAUF: ';
export const ACTIONS_PREFIX = 'TRANSAKTION_VERLAUF_';
export const GETTERS_PREFIX = 'TRANSAKTION_VERLAUF_';


export default {
  MUTATIONS: {
    FIND_TRANSACTIONS_SUCCESS: MUTATION_PREFIX + 'FIND_TRANSACTIONS_SUCCESS',
  },
  ACTIONS: {
    FIND_TRANSACTIONS: ACTIONS_PREFIX + 'FIND_TRANSACTIONS',
    DOWNLOAD_FILE: ACTIONS_PREFIX + 'DOWNLOAD_FILE',
  },
  GETTERS: {
    TRANSACTIONS: GETTERS_PREFIX + 'TRANSACTIONS',
  },
}
