var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _c("div", { staticClass: "font-strong" }, [_vm._v(" Weitere Angaben ")]),
      _c("InputRadioBoxGroup", {
        attrs: {
          title: "Geschäfte im Jahr " + (_vm.form.currentYear || ""),
          values: _vm.vertragWPValues.rgModus34
        },
        on: { input: _vm.changeVtgArt34Modus },
        model: {
          value: _vm.form.vtgArt34Modus,
          callback: function($$v) {
            _vm.$set(_vm.form, "vtgArt34Modus", $$v)
          },
          expression: "form.vtgArt34Modus"
        }
      }),
      _c("hr"),
      _vm._m(0),
      _c("DatePickerField", {
        attrs: {
          isComponentHalfSize: "",
          isRangePicker: "",
          id: "form.angabeZeitraum34f",
          disabled:
            !_vm.activeFields.angabeVon34f || !_vm.activeFields.angabeBis34f,
          value: {
            from: _vm.form.angabeVon34f,
            to: _vm.form.angabeBis34f
          }
        },
        on: {
          onSelectRangeFrom: function($event) {
            _vm.form.angabeVon34f = $event
          },
          onSelectRangeTo: function($event) {
            _vm.form.angabeBis34f = $event
          },
          change: function($event) {
            return _vm.fieldChanged()
          }
        }
      }),
      _c("InputToggleSwitch", {
        attrs: {
          inLineLabel: "",
          disabled: !_vm.activeFields.angabeKeine34f,
          label:
            "keine Anlageberatung und keine Anlagevermittlung (es erfolgte im Prüfungszeitraum auch keine Anlageberatung bzw. Anlagevermittlung, die \n\t\t\t<span class='font-strong'>nicht zu einem Geschäftsabschluss</span> führte)."
        },
        on: {
          input: function($event) {
            return _vm.fieldChanged()
          }
        },
        model: {
          value: _vm.form.angabeKeine34f,
          callback: function($$v) {
            _vm.$set(_vm.form, "angabeKeine34f", $$v)
          },
          expression: "form.angabeKeine34f"
        }
      }),
      _c("InputToggleSwitch", {
        attrs: {
          inLineLabel: "",
          disabled: !_vm.activeFields.angabeBeratung34f,
          label: "Anlageberatung,"
        },
        on: {
          input: function($event) {
            return _vm.fieldChanged()
          }
        },
        model: {
          value: _vm.form.angabeBeratung34f,
          callback: function($$v) {
            _vm.$set(_vm.form, "angabeBeratung34f", $$v)
          },
          expression: "form.angabeBeratung34f"
        }
      }),
      _c("InputToggleSwitch", {
        attrs: {
          inLineLabel: "",
          disabled: !_vm.activeFields.angabeVermittlung34f,
          label: "Anlagenvermittlung,"
        },
        on: {
          input: function($event) {
            return _vm.fieldChanged()
          }
        },
        model: {
          value: _vm.form.angabeVermittlung34f,
          callback: function($$v) {
            _vm.$set(_vm.form, "angabeVermittlung34f", $$v)
          },
          expression: "form.angabeVermittlung34f"
        }
      }),
      _vm._m(1),
      _c("InputToggleSwitch", {
        attrs: {
          inLineLabel: "",
          disabled: !_vm.activeFields.angabePara34f1,
          label:
            "Anteilen oder Aktien an inländischen offenen Investmentvermögen, offenen EU-Investmentvermögen oder ausländischen offenen Investmentvermögen, \n\t\t\tdie nach dem Kapitalanlagegesetzbuch vertrieben werden dürfen (§ 34f Abs. 1 Satz 1 Nr. 1 GewO),"
        },
        on: {
          input: function($event) {
            return _vm.fieldChanged()
          }
        },
        model: {
          value: _vm.form.angabePara34f1,
          callback: function($$v) {
            _vm.$set(_vm.form, "angabePara34f1", $$v)
          },
          expression: "form.angabePara34f1"
        }
      }),
      _c("InputToggleSwitch", {
        attrs: {
          inLineLabel: "",
          disabled: !_vm.activeFields.angabePara34f2,
          label:
            "Anteilen oder Aktien an inländischen geschlossenen Investmentvermögen, geschlossenen EU-Investmentvermögen oder ausländischen geschlossenen \n\t\t\tInvestmentvermögen, die nach dem Kapitalanlagegesetzbuch vertrieben werden dürfen (§ 34f Abs. 1 Satz 1 Nr. 2 GewO),"
        },
        on: {
          input: function($event) {
            return _vm.fieldChanged()
          }
        },
        model: {
          value: _vm.form.angabePara34f2,
          callback: function($$v) {
            _vm.$set(_vm.form, "angabePara34f2", $$v)
          },
          expression: "form.angabePara34f2"
        }
      }),
      _c("InputToggleSwitch", {
        attrs: {
          inLineLabel: "",
          disabled: !_vm.activeFields.angabePara34f3,
          label:
            "Vermögensanlagen im Sinne des § 1 Absatz 2 des Vermögensanlagegesetzes (§ 34f Abs. 1 Satz 1 Nr. 3 GewO)."
        },
        on: {
          input: function($event) {
            return _vm.fieldChanged()
          }
        },
        model: {
          value: _vm.form.angabePara34f3,
          callback: function($$v) {
            _vm.$set(_vm.form, "angabePara34f3", $$v)
          },
          expression: "form.angabePara34f3"
        }
      }),
      _c("hr"),
      _vm._m(2),
      _c("DatePickerField", {
        attrs: {
          isComponentHalfSize: "",
          isRangePicker: "",
          id: "form.angabeZeitraum34h",
          disabled:
            !_vm.activeFields.angabeVon34h || !_vm.activeFields.angabeBis34h,
          value: {
            from: _vm.form.angabeVon34h,
            to: _vm.form.angabeBis34h
          }
        },
        on: {
          onSelectRangeFrom: function($event) {
            _vm.form.angabeVon34h = $event
          },
          onSelectRangeTo: function($event) {
            _vm.form.angabeBis34h = $event
          },
          change: function($event) {
            return _vm.fieldChanged()
          }
        }
      }),
      _c("InputToggleSwitch", {
        attrs: {
          inLineLabel: "",
          disabled: !_vm.activeFields.angabeKeine34h,
          label:
            "keine Honorar-Finanzanlageberatung (es erfolgte im prüfungspflichtigen Zeitraum auch keine Honorar-Finanzanlageberatung, die \n\t\t\t<span class='font-strong'>nicht zu einem Geschäftsabschluss</strong> führte)."
        },
        on: {
          input: function($event) {
            return _vm.fieldChanged()
          }
        },
        model: {
          value: _vm.form.angabeKeine34h,
          callback: function($$v) {
            _vm.$set(_vm.form, "angabeKeine34h", $$v)
          },
          expression: "form.angabeKeine34h"
        }
      }),
      _c("InputToggleSwitch", {
        attrs: {
          inLineLabel: "",
          disabled: !_vm.activeFields.angabeBeratung34h,
          label: "Honorar-Finanzanlageberatung."
        },
        on: {
          input: function($event) {
            return _vm.fieldChanged()
          }
        },
        model: {
          value: _vm.form.angabeBeratung34h,
          callback: function($$v) {
            _vm.$set(_vm.form, "angabeBeratung34h", $$v)
          },
          expression: "form.angabeBeratung34h"
        }
      }),
      _c("div", [_vm._v(" zu folgenden Finanzanlagen ")]),
      _c("InputToggleSwitch", {
        attrs: {
          inLineLabel: "",
          disabled: !_vm.activeFields.angabePara34h1,
          label:
            "Anteilen oder Aktien an inländischen offenen Investmentvermögen, offenen EU-Investmentvermögen oder ausländischen \n\t\t\toffenen Investmentvermögen, die nach dem Kapitalanlagegesetzbuch vertrieben werden dürfen \n\t\t\t(§ 34h Abs. 1 Satz 1 Nr. 1 GewO),"
        },
        on: {
          input: function($event) {
            return _vm.fieldChanged()
          }
        },
        model: {
          value: _vm.form.angabePara34h1,
          callback: function($$v) {
            _vm.$set(_vm.form, "angabePara34h1", $$v)
          },
          expression: "form.angabePara34h1"
        }
      }),
      _c("InputToggleSwitch", {
        attrs: {
          inLineLabel: "",
          disabled: !_vm.activeFields.angabePara34h2,
          label:
            "Anteilen oder Aktien an inländischen geschlossenen Investmentvermögen, geschlossenen EUInvestmentvermögen \n\t\t\toder ausländischen geschlossenen Investmentvermögen, die nach dem Kapitalanlagegesetzbuch \n\t\t\tvertrieben werden dürfen (§ 34h Abs. 1 Satz 1 Nr. 2 GewO),"
        },
        on: {
          input: function($event) {
            return _vm.fieldChanged()
          }
        },
        model: {
          value: _vm.form.angabePara34h2,
          callback: function($$v) {
            _vm.$set(_vm.form, "angabePara34h2", $$v)
          },
          expression: "form.angabePara34h2"
        }
      }),
      _c("InputToggleSwitch", {
        attrs: {
          inLineLabel: "",
          disabled: !_vm.activeFields.angabePara34h3,
          label:
            "Vermögensanlagen im Sinne des § 1 Absatz 2 des Vermögensanlagegesetzes (§ 34h Abs. 1 Satz 1 Nr. 3 GewO)."
        },
        on: {
          input: function($event) {
            return _vm.fieldChanged()
          }
        },
        model: {
          value: _vm.form.angabePara34h3,
          callback: function($$v) {
            _vm.$set(_vm.form, "angabePara34h3", $$v)
          },
          expression: "form.angabePara34h3"
        }
      }),
      _c("hr"),
      _c("div", { staticClass: "mb-2" }, [
        _vm._v(
          " Die Anforderungen gemäß § 16 FinVermV insbesondere in Bezug auf die Einholung von Informationen über den Anleger sowie die Pflicht zur Empfehlung geeigneter Finanzanlagen – wurden beachtet und dokumentiert. "
        )
      ]),
      _c("InputRadioBoxGroup", {
        attrs: { values: _vm.vertragWPValues.schwarmFinanzierung },
        on: {
          input: function($event) {
            return _vm.fieldChanged()
          }
        },
        model: {
          value: _vm.form.angabeSchwarmfinanzierung,
          callback: function($$v) {
            _vm.$set(_vm.form, "angabeSchwarmfinanzierung", $$v)
          },
          expression: "form.angabeSchwarmfinanzierung"
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("span", { staticClass: "font-strong" }, [_vm._v("§ 34 f")]),
      _vm._v(" Im prüfungspflichtigen Zeitraum erfolgte(n) ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v(" zu folgenden Finanzanlagen "),
      _c("span", { staticClass: "font-strong" }, [
        _vm._v(
          "(es sollten nur die Punkte angekreuzt werden bei denen auch eine Beratung oder Vermittlung erfolgt ist)"
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("span", { staticClass: "font-strong" }, [_vm._v("§ 34 h")]),
      _vm._v(" Im prüfungspflichtigen Zeitraum erfolgte(n) ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }