var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.vertragsdatenFieldsDefinition
    ? _c(
        "div",
        [
          _c("FlexibleList", {
            attrs: { rows: _vm.rows },
            scopedSlots: _vm._u(
              [
                {
                  key: "title",
                  fn: function(row) {
                    return [
                      _c("router-link", { attrs: { to: _vm.editPath(row) } }, [
                        _vm._v(
                          "Schadensnummer " +
                            _vm._s(row.schadennr || "xxx") +
                            " vom " +
                            _vm._s(row.ereignisdatum || "xx.xx.xxxx")
                        )
                      ])
                    ]
                  }
                },
                {
                  key: "value",
                  fn: function(row) {
                    return [
                      _vm._v(" " + _vm._s(row.meldedatum || "xx.xx.xxxx") + " ")
                    ]
                  }
                },
                {
                  key: "thirdRow",
                  fn: function(row) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            row.bearbstandLabel || row.schadensursacheText
                          ) +
                          " "
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              3742935927
            )
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }