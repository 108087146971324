export const MUTATION_PREFIX = 'DASHBOARD: ';
export const ACTIONS_PREFIX = 'DASHBOARD_ACTIONS_';
export const GETTERS_PREFIX = 'DASHBOARD_GETTERS_';

export default {
  MUTATIONS: {
    SAVE_DASHBOARD: MUTATION_PREFIX + 'SAVE_DASHBOARD',
    GET_DASHBOARD_CHARTS_STRUCTURE: MUTATION_PREFIX + 'GET_DASHBOARD_CHARTS_STRUCTURE',
    GET_DASHBOARD_CHARTS: MUTATION_PREFIX + 'GET_DASHBOARD_CHARTS',
    GET_DASHBOARD_CHARTS_RESET: MUTATION_PREFIX + 'GET_DASHBOARD_CHARTS_RESET',
    GET_DASHBOARD_CONFIGURATION_SUCCESS: MUTATION_PREFIX + 'GET_DASHBOARD_CONFIGURATION_SUCCESS',
    GET_STECKBRIEF_CONFIGURATION_SUCCESS: MUTATION_PREFIX + 'GET_STECKBRIEF_CONFIGURATION_SUCCESS',
    START_CALCULATION_DASHBOARD_CHARTS: MUTATION_PREFIX + 'START_CALCULATION_DASHBOARD_CHARTS',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    SET_ENABLED_CARDS: MUTATION_PREFIX + 'SET_ENABLED_CARDS',
    MARK_CARD_SUCCESS: MUTATION_PREFIX + 'MARK_CARD_SUCCESS',

    GET_BROKER_OVERVIEW_CONFIGURATION_SUCCESS: MUTATION_PREFIX + 'GET_BROKER_OVERVIEW_CONFIGURATION_SUCCESS',
    GET_BROKER_PARAGRAPHS_CONFIGURATION_SUCCESS: MUTATION_PREFIX + 'GET_BROKER_PARAGRAPHS_CONFIGURATION_SUCCESS',
    
    GET_INTERN_OVERVIEW_CONFIGURATION_SUCCESS: MUTATION_PREFIX + 'GET_INTERN_OVERVIEW_CONFIGURATION_SUCCESS',
  },
  ACTIONS: {
    SAVE_DASHBOARD: ACTIONS_PREFIX + 'SAVE_DASHBOARD',
    GET_DASHBOARD_CHARTS: ACTIONS_PREFIX + 'GET_DASHBOARD_CHARTS',
    START_CALCULATION_DASHBOARD_CHARTS: ACTIONS_PREFIX + 'START_CALCULATION_DASHBOARD_CHARTS',
    GET_DASHBOARD_CONFIGURATION: ACTIONS_PREFIX + 'GET_DASHBOARD_CONFIGURATION',
    GET_STECKBRIEF_CONFIGURATION: ACTIONS_PREFIX + 'GET_STECKBRIEF_CONFIGURATION',

    GET_BROKER_OVERVIEW_CONFIGURATION: ACTIONS_PREFIX + 'GET_BROKER_OVERVIEW_CONFIGURATION',
    SAVE_BROKER_OVERVIEW_CONFIGURATION: ACTIONS_PREFIX + 'SAVE_BROKER_OVERVIEW_CONFIGURATION',
    GET_BROKER_PARAGRAPHS_CONFIGURATION: ACTIONS_PREFIX + 'GET_BROKER_PARAGRAPHS_CONFIGURATION',
    
    SHOW_FK_EVENTS_MODAL: ACTIONS_PREFIX + 'SHOW_FK_EVENTS_MODAL',

    GET_INTERN_OVERVIEW_CONFIGURATION: ACTIONS_PREFIX + 'GET_INTERN_OVERVIEW_CONFIGURATION',
    SAVE_INTERN_OVERVIEW_CONFIGURATION: ACTIONS_PREFIX + 'SAVE_INTERN_OVERVIEW_CONFIGURATION',

  },
  GETTERS: {
    SAVE_DASHBOARD: GETTERS_PREFIX + 'SAVE_DASHBOARD',
    GET_DASHBOARD_CHARTS: GETTERS_PREFIX + 'GET_DASHBOARD_CHARTS',
    GET_USER_DASHBOARD: GETTERS_PREFIX + 'GET_USER_DASHBOARD',
    GET_USER_DASHBOARD_HIDE_CARDS: GETTERS_PREFIX + 'GET_USER_DASHBOARD_HIDE_CARDS',
    GET_STECKBRIEF_CONFIGURATION: GETTERS_PREFIX + 'GET_STECKBRIEF_CONFIGURATION',
    GET_DASHBOARD_CHARTS_STRUCTURE: GETTERS_PREFIX + 'GET_DASHBOARD_CHARTS_STRUCTURE',
    GET_ENABLED_CARDS: GETTERS_PREFIX + 'GET_ENABLED_CARDS',

    BROKER_OVERVIEW_CONFIGURATION: GETTERS_PREFIX + 'BROKER_OVERVIEW_CONFIGURATION',
    BROKER_PARAGRAPHS_CONFIGURATION: GETTERS_PREFIX + 'BROKER_PARAGRAPHS_CONFIGURATION',

    INTERN_OVERVIEW_CONFIGURATION: GETTERS_PREFIX + 'INTERN_OVERVIEW_CONFIGURATION',
  }
}
