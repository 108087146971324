<template>
  <div class="input-forms__container">
    <label class="input-forms__label-container">
      <div class="input-forms__label-content" :class="labelClass" v-if="label" v-html="sanitize(label)"></div>
    </label>

    <InputRadioBoxGroup
      class="input-forms__radio-box-group__container"
      :name="name"
      :value="internValue"
      :values="dynamicValues"
      :validateUntouched="validateUntouched"
      :suppressValidationMessage="suppressValidationMessage"
      :disabled="disabled"
      :config="dynamicConfig"
      @input="onInput($event)"
    />

    <div v-if="isDynamicValueVisible" class="input-forms__input-dynamic-value__container">
      <AntragComponent 
        :component="dynamicInputComponent"
        :data="dynamicInputComponentData"
        :comboboxSelection="dynamicInputComponentComboboxSelection"
        isComponentHalfSizeEnabled
        :disabled="disabled || isDynamicValueDisabledWhenNotSelected"
        @change="onComponentChange($event)" />
    </div>
  </div>
</template>

<script>
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import AntragComponent from '@/components/antrag/AntragComponent.vue';
import { sanitize } from '@/helpers/string-helper.js';

const DYNAMIC_VALUE = 'dynamic';

export default {
  components: {
    InputRadioBoxGroup,
    AntragComponent,
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    labelClass: {
      type: String,
    },
    name: {
      type: String,
      default: null,
    },
    value: {
      default: '',
    },
    validateUntouched: {
      type: Boolean,
      default: false,
    },
    suppressValidationMessage: {
      type: Boolean,
      default: false,
    },
    values: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    config: {
    },
  },
  data() {
    return {
      internValue: '',
      inputDynamicValue: '',
    };
  },
  watch: {
    value: {
      handler() {
        this.internValue = this.processValue(this.value);
        if(this.isDynamicValueSelected) {
          this.inputDynamicValue = this.value;
        }
      },
      immediate: true,
    }
  },
  computed: {
    dynamicValues() {
      if(!this.values?.length) return [];

      return [
        ...this.values || [],
        { label: this.dynamicValueLabel, value: DYNAMIC_VALUE, },
      ];
    },
    isDynamicValueSelected() {
      return this.internValue == DYNAMIC_VALUE;
    },
    isDynamicValueVisible() {
      return this.config?.dynamicValueAlwaysVisible || this.isDynamicValueSelected
    },
    isDynamicValueDisabledWhenNotSelected() {
      if (!this.config?.dynamicValueAlwaysVisible || !this.config?.dynamicValueDisabledWhenNotSelected) {
        return false
      }

      return !this.isDynamicValueSelected
    },
    dynamicValueLabel() {
      return this.config?.dynamicValueLabel || '';
    },
    dynamicInputComponent() {
      const dynamicInputAsComponent = this.config?.dynamicInputAsComponent;
      return {
        ...dynamicInputAsComponent,
        id: 'dynamicInputComponent',
      };
    },
    dynamicInputComponentData() {
      return {
        dynamicInputComponent: this.value,
      };
    },
    dynamicInputComponentComboboxSelection() {
      return this.dynamicInputComponent?.values || [];
    },
    dynamicConfig() {
      return {
        ...this.config || {},
        boldValues: this.config?.boldValues
        ?.filter(v => v !== null && v !== undefined)
        ?.map(v => this.isValidValue(v) ? v : DYNAMIC_VALUE),
      };
    },
  },
  methods: {
    sanitize(htmlString) {
      return sanitize(htmlString);
    },
    isValidValue(value) {
      return this.values.some(v => v.value === value);
    },
    processValue(value) {
      if(this.isValidValue(value)) {
        return value;
      }

      return DYNAMIC_VALUE;
    },
    onInput(value) {
      if(value == DYNAMIC_VALUE) {
        this.$emit('input', '');
      } else {
        this.$emit('input', value);
      }
    },
    onComponentChange(value) {
      this.$emit('input', value);
    },
  },
}
</script>

<style scoped>
.input-forms__radio-box-group__container {
  padding: 0 0 4px;
}
</style>
