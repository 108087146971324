import TAPI from './types';

export default {
  [TAPI.GETTERS.CONNECT](state) {
    return state.CONNECT;
  },
  [TAPI.GETTERS.LINE](state) {
    return state.LINE;
  },
  [TAPI.GETTERS.PHONE_NUMBER](state) {
    return state.PHONE_NUMBER;
  },
  [TAPI.GETTERS.WEB_SOCKET](state) {
    return state.WEB_SOCKET;
  },
  [TAPI.GETTERS.WEB_SOCKET_OPENED](state) {
    return state.WEB_SOCKET_OPENED;
  },
  [TAPI.GETTERS.OPEN_INCOMMING_DIALOG](state) {
    return state.OPEN_INCOMMING_DIALOG;
  },
  [TAPI.GETTERS.MODAL_PANEL_OPEN](state) {
    return state.MODAL_PANEL_OPEN;
  },
  [TAPI.GETTERS.ACTIVE_CALL](state) {
    return state.ACTIVE_CALL;
  },

  [TAPI.GETTERS.CAN_TAPI](state) {
 
    return state.CAN_TAPI;
  },
  [TAPI.GETTERS.CAN_TAPI_BUTTON](state) {
 
    return state.CAN_TAPI_BUTTON;
  },
  [TAPI.GETTERS.CAN_TAPI_HOST](state) {
 
    return state.CAN_TAPI_HOST;
  },
  [TAPI.GETTERS.HOST](state) {
 
    return state.HOST;
  },
  [TAPI.GETTERS.CALLER](state) {
 
    return state.CALLER;
  },
  [TAPI.GETTERS.DIRECTION](state) {
 
    return state.DIRECTION;
  },
  [TAPI.GETTERS.TAPI_PARAMS](state) {
    
    return state.TAPI_PARAMS;
  },
  [TAPI.GETTERS.START_CALL_DATE](state) {
    
    return state.START_CALL_DATE;
  },

  [TAPI.GETTERS.CAN_OUT_CALL](state) {
  
    return state.CAN_TAPI && ! state.ACTIVE_CALL && state.WEB_SOCKET_OPENED;
  },
  
  [TAPI.GETTERS.CALL_OUT_USER_TYPE](state) {
    
    return state.CALL_OUT_USER_TYPE;
  },
  [TAPI.GETTERS.CALL_OUT_US_ID](state) {
    
    return state.CALL_OUT_US_ID;
  },
  [TAPI.GETTERS.CALL_OUT_NUMBER](state) {
    
    return state.CALL_OUT_NUMBER;
  },
  [TAPI.GETTERS.RINGING](state) {
    
    return state.RINGING;
  },
  [TAPI.GETTERS.LINES](state) {
  
    return state.LINES;;
  },

  
  [TAPI.GETTERS.CAN_OPEN_CALL_OUT_DIALOG](state,getters) {
  
    return state.CALL_OUT_NUMBER!=null &&  getters[TAPI.GETTERS.CAN_OUT_CALL] ;
  }
}