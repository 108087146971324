<template>
  <div class="customer-settings-view">
    <PageHeaderTitleNavigation :title="parentTitle" />

    <div class="row">
      <div class="col-lg-3 col-md-3 col-sm-12 col-12">
        <div class="box__container">
          <Stepper :steps="$appNavigation.currentTabMenu" :currentStep="$route"></Stepper>
        </div>
      </div>
      <div class="col-lg-9 col-md-9 col-sm-12 col-12">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>

import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import Stepper from '@/components/persoenliche-daten/components/Stepper.vue';


export default {
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    Stepper,
  },
  computed: {
    parentTitle() {
      return this.$appNavigation.currentMenu?.parent?.label;
    }
  },
}
</script>
