var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "eventsnum event__container",
      class: [{ hidden: _vm.ghost }]
    },
    [
      _c("ExpandedEventView", { attrs: { event: _vm.verticalcontent.event } }, [
        _c(
          "div",
          {
            class: [
              "smallletters",
              "event-cell",
              "clickable",
              { selected: _vm.isSelected }
            ],
            style: _vm.eventStyle,
            attrs: { "data-event-view": "" },
            on: { click: _vm.setSelectedEvent, dblclick: _vm.ondouble }
          },
          [
            _c("div", {
              staticClass: "event-cell-before",
              style: { "background-color": _vm.verticalcontent.event.color }
            }),
            _c("div", { staticClass: "event-label" }, [
              _c("span", [_vm._v(_vm._s(_vm.verticalcontent.event.label))])
            ]),
            _vm.verticalcontent.event.repetition
              ? _c(
                  "span",
                  { staticClass: "repetition-icon" },
                  [_c("PhArrowsClockwise", { attrs: { size: "16" } })],
                  1
                )
              : _vm._e()
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }