var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("InputToggleSwitch", {
        attrs: { label: "Show only menu entries", inLineLabel: "" },
        model: {
          value: _vm.showOnlyMenuEntry,
          callback: function($$v) {
            _vm.showOnlyMenuEntry = $$v
          },
          expression: "showOnlyMenuEntry"
        }
      }),
      _c("InputToggleSwitch", {
        attrs: { label: "All Collapsed", inLineLabel: "" },
        model: {
          value: _vm.allCollapsed,
          callback: function($$v) {
            _vm.allCollapsed = $$v
          },
          expression: "allCollapsed"
        }
      }),
      _c("RouterTree", {
        attrs: { treeData: _vm.menuList, allCollapsed: _vm.allCollapsed }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }