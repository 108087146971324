var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.$appNavigation.currentMenu.parent.label,
          subtitle: _vm.$appNavigation.currentMenu.label
        }
      }),
      _c(
        "div",
        {
          staticClass: "box__container",
          attrs: { tid: "e81400d2-282f-43b7-b21b-e26117dcba07" }
        },
        [
          !_vm.loading && _vm.tableRows.length
            ? _c("Table", {
                staticClass: "table-container",
                attrs: {
                  tableId: "47bbba68-fc47-439f-9670-ebda06b3a2fa",
                  title: _vm.TABLE_TITLE,
                  headers: _vm.tableHeaders,
                  rows: _vm.tableRows,
                  rowsPerPage: 20,
                  count: _vm.resultCount
                }
              })
            : _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", title: _vm.TABLE_TITLE }
              })
            : _c("NoData", { attrs: { title: _vm.TABLE_TITLE } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }