var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("MailBriefVorlage", {
        attrs: { appendActions: _vm.headerActions },
        on: {
          "action-REFRESH": function($event) {
            return _vm.onRefresh()
          },
          "action-ADD-NEW-TAG": function($event) {
            return _vm.openAddNewTagModal()
          }
        }
      }),
      _c("BaseFilter", {
        attrs: {
          title: "Vorlagen Briefe / E-Mails Filter",
          filterId: _vm.baseFilterSaveKey,
          metadata: _vm.searchMetadata,
          configFilter: _vm.configFilter,
          showSaveButton: true,
          defaultOptions: _vm.defaultOptions
        },
        on: {
          onFilter: function($event) {
            return _vm.search($event)
          },
          onFilterZurucksetzen: function($event) {
            return _vm.search(null)
          }
        }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          !_vm.pageLoading && _vm.rows.length
            ? _c("Table", {
                ref: "tableResults",
                attrs: {
                  tableId: "cec0b9a7-812c-48f2-9889-6e9b64ca839c",
                  title: _vm.TABLE_TITLE,
                  headers: _vm.headers,
                  rows: _vm.filteredRows,
                  rowsPerPage: 100
                },
                on: {
                  "click-grantLabel": _vm.showFreigabeEditor,
                  "action-EDIT": _vm.handleEdit,
                  "action-DELETE": _vm.handleDelete,
                  "action-COPY": _vm.handleCopy,
                  "action-CHANGE_ACCESS": _vm.showFreigabeEditor
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "description",
                      fn: function(vorlage) {
                        return [
                          _c("DownloadLink", {
                            attrs: {
                              title: vorlage.description,
                              downloadServicePath: vorlage.isWord
                                ? "/get_word_vorlage"
                                : "/vorlage_preview",
                              filename: vorlage.isWord
                                ? "Dokument.doc"
                                : "Dokument.pdf",
                              queryParams: {
                                id: vorlage.id,
                                fileId: vorlage.id
                              }
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "visible",
                      fn: function(vorlage) {
                        return [
                          _c("ComboBox", {
                            attrs: {
                              value:
                                vorlage.visible ||
                                (_vm.isIntern ? "JA" : "NEIN"),
                              values: _vm.sichtbarkeitValues,
                              disabled: _vm.loadingItems[vorlage.id]
                            },
                            on: {
                              input: function($event) {
                                return _vm.changeVisibility(vorlage, $event)
                              }
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "tags",
                      fn: function(vorlage) {
                        return [
                          _c("InputChips", {
                            staticClass: "pb-0",
                            attrs: {
                              showToggle: "",
                              disableInput:
                                !vorlage.editable ||
                                _vm.loadingItems[vorlage.id],
                              availableOptions: _vm.tagsOptionWithoutCurrentTags(
                                vorlage.id
                              )
                            },
                            on: {
                              addItem: function($event) {
                                return _vm.addItem($event, vorlage)
                              },
                              removeItem: function($event) {
                                return _vm.removeItem($event, vorlage)
                              }
                            },
                            model: {
                              value: _vm.selectedTags[vorlage.id],
                              callback: function($$v) {
                                _vm.$set(_vm.selectedTags, vorlage.id, $$v)
                              },
                              expression: "selectedTags[vorlage.id]"
                            }
                          })
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1830732669
                )
              })
            : _vm.pageLoading
            ? _c("GhostLoading", {
                attrs: { type: "table", title: _vm.TABLE_TITLE }
              })
            : _c("NoData", {
                attrs: {
                  title: _vm.TABLE_TITLE,
                  content: "Keine Vorlagen vorhanden"
                }
              })
        ],
        1
      ),
      _vm.editFreigabeVorlage
        ? _c("FreigabeEditModal", {
            attrs: { vorlage: _vm.editFreigabeVorlage },
            on: { close: _vm.closeEditFreigabeVorlage }
          })
        : _vm._e(),
      _vm.showAddNewTag
        ? _c("AddVorlagenTagsModal", { on: { close: _vm.closeAddNewTag } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }