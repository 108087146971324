var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c("div", [
            _c(
              "div",
              { staticClass: "box__container" },
              _vm._l([1, 2, 3, 4, 5], function(item) {
                return _c(
                  "GhostLoading",
                  { key: item },
                  [_c("Block", { attrs: { height: "45" } })],
                  1
                )
              }),
              1
            ),
            _c(
              "div",
              { staticClass: "box__container" },
              [
                _c(
                  "GhostLoading",
                  [_c("Block", { attrs: { height: "100" } })],
                  1
                )
              ],
              1
            )
          ])
        : _vm._e(),
      !_vm.loading
        ? _c("div", { staticClass: "box__container" }, [
            _c("div", { staticClass: "row " }, [
              _c(
                "div",
                { staticClass: "col-12" },
                [
                  _c("InputField", {
                    attrs: {
                      label: "Bezeichnung",
                      isComponentHalfSize: true,
                      validateUntouched: true
                    },
                    on: { change: _vm.markModified },
                    model: {
                      value: _vm.stepData.bezeichnung,
                      callback: function($$v) {
                        _vm.$set(_vm.stepData, "bezeichnung", $$v)
                      },
                      expression: "stepData.bezeichnung"
                    }
                  }),
                  _vm.stepParameters &&
                  _vm.stepParameters.data &&
                  _vm.stepParameters.data.vorgangAuswahl
                    ? [
                        _c("ComboBox", {
                          attrs: {
                            label: "Vorgang",
                            isComponentHalfSize: true,
                            isEmbedded: false,
                            values: _vm.stepParameters.data.vorgangAuswahl
                          },
                          on: { change: _vm.triggerSelectVorgang },
                          model: {
                            value: _vm.stepData.vorgang,
                            callback: function($$v) {
                              _vm.$set(_vm.stepData, "vorgang", $$v)
                            },
                            expression: "stepData.vorgang"
                          }
                        })
                      ]
                    : _vm._e(),
                  _vm.stepParameters &&
                  _vm.stepParameters.data &&
                  _vm.stepParameters.data.ziellagerstelleAuswahl
                    ? [
                        _c("ComboBox", {
                          attrs: {
                            label: "Ziellagerstelle",
                            isComponentHalfSize: true,
                            isEmbedded: false,
                            values:
                              _vm.stepParameters.data.ziellagerstelleAuswahl
                          },
                          on: { change: _vm.triggerSelectLagerstelle },
                          model: {
                            value: _vm.stepData.ziellagerstelle,
                            callback: function($$v) {
                              _vm.$set(_vm.stepData, "ziellagerstelle", $$v)
                            },
                            expression: "stepData.ziellagerstelle"
                          }
                        })
                      ]
                    : _vm._e(),
                  _c("DatePickerField", {
                    attrs: {
                      label: "Backtest-Zeitraum",
                      isRangePicker: "",
                      isComponentHalfSize: "",
                      value: {
                        from: _vm.backtestStart,
                        to: _vm.backtestEnd
                      }
                    },
                    on: {
                      onSelectRangeFrom: function($event) {
                        return _vm.pChanged("backtestStart", $event)
                      },
                      onSelectRangeTo: function($event) {
                        return _vm.pChanged("backtestEnd", $event)
                      },
                      input: _vm.markModified,
                      onBlur: _vm.checkRange
                    }
                  }),
                  _c("InputRadioBoxGroup", {
                    staticClass: "pb-0 pt-3",
                    attrs: {
                      validateUntouched: true,
                      values: _vm.zielsummeberechnungValues
                    },
                    on: { input: _vm.markModified },
                    model: {
                      value: _vm.stepData.zielsummeberechnung,
                      callback: function($$v) {
                        _vm.$set(_vm.stepData, "zielsummeberechnung", $$v)
                      },
                      expression: "stepData.zielsummeberechnung"
                    }
                  }),
                  _vm.stepData.zielsummeberechnung
                    ? _c("InputField", {
                        attrs: {
                          isComponentHalfSize: true,
                          type: "currency",
                          validateUntouched: true
                        },
                        on: { change: _vm.markModified },
                        model: {
                          value: _vm.stepData.zielsumme,
                          callback: function($$v) {
                            _vm.$set(_vm.stepData, "zielsumme", $$v)
                          },
                          expression: "stepData.zielsumme"
                        }
                      })
                    : _vm._e(),
                  !_vm.stepData.zielsummeberechnung
                    ? _c("InputField", {
                        attrs: {
                          isComponentHalfSize: true,
                          type: "currency",
                          label: "Neuanlagesumme ",
                          validateUntouched: true
                        },
                        on: { change: _vm.markModified },
                        model: {
                          value: _vm.stepData.anlagesumme,
                          callback: function($$v) {
                            _vm.$set(_vm.stepData, "anlagesumme", $$v)
                          },
                          expression: "stepData.anlagesumme"
                        }
                      })
                    : _vm._e()
                ],
                2
              )
            ])
          ])
        : _vm._e(),
      !_vm.loading &&
      _vm.stepParameters &&
      _vm.stepParameters.data &&
      _vm.stepParameters.data.parameterData
        ? _c("InvestmentAdviceTable", {
            attrs: { id: _vm.id, stepName: "MAIN" },
            on: {
              modified: _vm.markModified,
              import_table: _vm.importAllocation
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }