var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.inputs
    ? _c(
        "div",
        { staticClass: "grid", staticStyle: { width: "100%" } },
        [
          _vm._l(_vm.inputs, function(input) {
            return [
              (!input.visible ||
                _vm.evaluateUploadExpression(_vm.value, input.visible)) &&
              (!input.ignore ||
                !_vm.evaluateUploadExpression(_vm.value, input.ignore))
                ? _c(
                    "label",
                    {
                      key: input.key ? input.key : input.label,
                      class: {
                        invalid:
                          input.valid &&
                          !_vm.evaluateUploadExpression(_vm.value, input.valid)
                      }
                    },
                    [
                      input.type == "LABEL"
                        ? _c("div", { staticClass: "label-item" }, [
                            _c(
                              "span",
                              { class: _vm.fixedColor(input.labelFixedColor) },
                              [_vm._v(_vm._s(input.label))]
                            )
                          ])
                        : _vm._e(),
                      input.type == "BOOLEAN"
                        ? _c("InputCheckBoxItem", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              value: _vm.value[input.key],
                              disabled: _vm.isDisabled(_vm.value, input),
                              label: input.label
                            },
                            on: {
                              input: function($event) {
                                return _vm.emitChange(input.key, $event)
                              }
                            }
                          })
                        : _vm._e(),
                      input.type == "SWITCHER"
                        ? _c("InputToggleSwitch", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              value: _vm.value[input.key],
                              disabled: _vm.isDisabled(_vm.value, input),
                              label: input.label
                            },
                            on: {
                              input: function($event) {
                                return _vm.emitChange(input.key, $event)
                              }
                            }
                          })
                        : _vm._e(),
                      input.type == "STRING"
                        ? _c("InputField", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              value: _vm.value[input.key],
                              disabled: _vm.isDisabled(_vm.value, input),
                              label: input.label
                            },
                            on: {
                              change: function($event) {
                                return _vm.emitChange(input.key, $event)
                              }
                            }
                          })
                        : _vm._e(),
                      input.type == "INTEGER"
                        ? _c("InputField", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "number",
                              value: _vm.value[input.key],
                              disabled: _vm.isDisabled(_vm.value, input),
                              label: input.label
                            },
                            on: {
                              change: function($event) {
                                return _vm.emitInteger(input.key, $event)
                              }
                            }
                          })
                        : _vm._e(),
                      input.type == "DATE"
                        ? _c("DatePickerField", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              value: _vm.value[input.key],
                              isValueAsString: "",
                              disabled: _vm.isDisabled(_vm.value, input),
                              label: input.label
                            },
                            on: {
                              change: function($event) {
                                return _vm.emitChange(input.key, $event)
                              }
                            }
                          })
                        : _vm._e(),
                      input.type == "COMBOBOX"
                        ? _c("ComboBox", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              sortComboboxValues: false,
                              value: _vm.value[input.key],
                              renderDanger:
                                input.valid &&
                                !_vm.evaluateUploadExpression(
                                  _vm.value,
                                  input.valid
                                ),
                              disabled: _vm.isDisabled(_vm.value, input),
                              values: input.options,
                              label: input.label,
                              customizable: true
                            },
                            on: {
                              change: function($event) {
                                return _vm.emitChange(input.key, {
                                  event: $event,
                                  component: input
                                })
                              }
                            }
                          })
                        : _vm._e(),
                      input.type == "RADIO"
                        ? _c("InputRadioBoxGroup", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              value: _vm.value[input.key],
                              disabled: _vm.isDisabled(_vm.value, input),
                              values: input.options,
                              title: input.label
                            },
                            on: {
                              input: function($event) {
                                return _vm.emitChange(input.key, $event)
                              }
                            }
                          })
                        : _vm._e(),
                      input.type == "TEXT"
                        ? _c("InputTextArea", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              value: _vm.value[input.key],
                              disabled: _vm.isDisabled(_vm.value, input),
                              label: input.label
                            },
                            on: {
                              input: function($event) {
                                return _vm.emitChange(input.key, $event)
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ]
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }