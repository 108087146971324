<template>
  <div v-if="details">
    <Table
      v-if="rows.length"
      ref="table"
      :tableId="tableId"
      cardView
      :headers="headers"
      :rows="rows"
      rowId="isinWkn"
      hidePagination
    >
    </Table>
  </div>
</template>
<script>
import FONDSINFO_TYPES from "@/store/fondsinfo/types";
import BoxContainer from "@/components/core/BoxContainer.vue";
import { PhStar } from "phosphor-vue";
import { mapGetters } from "vuex";
import { sanitize } from "@/helpers/string-helper.js";
import Table from "@/components/table2/Table.vue";
import {
  TextColumn,
  CurrencyColumnm,
  SlotColumn,
} from "@/components/table2/table_util.js";

export default {
  components: {
    BoxContainer,
    PhStar,
    Table,
  },
  name: "TabDetail",
  props: {
    selectedTabLabel: {
      type: String,
    },
  },
  computed: {
    ...mapGetters({
      fundsDetail: FONDSINFO_TYPES.GETTERS.FONDSINFO_DETAILS,
      allgemeinDescription: FONDSINFO_TYPES.GETTERS.FONDSINFO_ALGEMEIN,
    }),
    details() {
      if (this.fundsDetail) {
        const records = Object.values(this.fundsDetail).slice(1);
        return records;
      }
      return [];
    },
    headers() {
      const result = {
        center: [],
      };

      result.center.push(TextColumn("laufendeKostenPA", "Laufende Kosten p.a."));
      result.center.push(TextColumn("transaktionsgebuehr", "Transaktionsgebühr"));

      return result;
    },
    rows() {
      const rows = [];

      const row = {};

      const fieldValue = (whatArray) => (whatLabel) => (whatType) =>
        whatArray
          ?.find((w) => w?.label == whatLabel)
          ?.records?.find((r) => r.type == whatType)?.value || "-";

      row.laufendeKostenPA = fieldValue(this.details)("Kosten ExPost")("Laufende Kosten p.a.");
      row.transaktionsgebuehr = fieldValue(this.details)("Kosten ExPost")("Transaktionsgebühr");


      rows.push(row);

      return rows;
    },
    tableId() {
      const uniqueUUID = "96da4db0-5a8c-45cf-bd6e-adf44a44f8a3";
      return uniqueUUID;
    },
  },
  methods: {
    sanitize(htmlString) {
      return sanitize(htmlString);
    },
    getStars(value) {
      const starQuantity = parseInt(value.charAt());
      return isNaN(starQuantity) ? 0 : starQuantity;
    },
    detailLabel(detail) {
      if (detail && detail.label) {
        return detail.label;
      }
      return "";
    },
  },
  mounted() {
    if (!this.fundsDetail || !this.allgemeinDescription) {
      this.$store.dispatch(FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_ALGEMEIN, {
        isin: this.$route.params?.isin,
        depotid: this.$route.params?.depotid,
      });
    }
  },
};
</script>

<style>
.value--item-label {
  width: 25%;
}
</style>
