<template>
  <div>
    <PageHeaderTitleNavigation title="Depotstammdaten" />

    <div :class="['box__container dashboard-panel-widget', sizeClass]">
      <div class="box__title">Kontonummer</div>
      <div>{{ kontonummer }}</div>
    </div>

    <div :class="['box__container dashboard-panel-widget', sizeClass]">
      <div class="box__container-rows">
        <div class="dashboard-panel-widget__content label-value--list" v-for="(stammdaten,index) of depotstammdatenComputed" :key="index">
          <div class="label-value--item">
            <div class="label-value--item-label text-left font-bold">{{stammdaten.title}}:</div>
            <div class="label-value--item-value text-right">
              <CurrencyLabel v-if="stammdaten.format === 'Currency'" :value="stammdaten.value" symb="€" />
              <CurrencyLabel v-else-if="stammdaten.format === 'Procent'" :value="stammdaten.value" symb="%" />
              <CurrencyLabel v-else-if="stammdaten.format === 'Double'" :value="stammdaten.value" symb="" />
              <span v-else>{{stammdaten.value}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ACCOUNT_TYPES from "@/store/account/types";
import { mapGetters } from "vuex";
import CurrencyLabel from "@/components/core/CurrencyLabel.vue";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';

export default {
  components: {
    CurrencyLabel,
    OptionMenu,
    PageHeaderTitleNavigation,
  },
  computed: {
    ...mapGetters({
      depotstammdaten: ACCOUNT_TYPES.GETTERS.ACCOUNT_GETTERS_DEPOTSTAMMDATEN,
    }),
    kontonummer() {
      return this.$route.params.kontonummer;
    },
    depotstammdatenComputed() {
        const depotstammdaten = this.depotstammdaten;
        if(depotstammdaten){
            return depotstammdaten?.stammDatenHeaders?.map((masterData, index) => {
                const stamndaten = depotstammdaten?.stammdaten[0];
                return ({...masterData, ...{'value' : stamndaten?.[index] || null}})
              }
          ).filter(item => this.labelsAllowed.includes(item.title));
        }
    },
    labelsAllowed() {
        return [
            "Geschäftsart",
            "Depot-Kurzbezeichnung",
            "Depoteröffnungsdatum",
            "Lombardkredit",
            "Partnermodell",
            "Zinssatz",
            "Servicegebühr",
            "Sperre"]
    },
    sizeClass() {
      return `dashboard-panel-widget__size-${this.size}`;
    },
  },
  mounted() {
    this.$store.dispatch(ACCOUNT_TYPES.ACTIONS.GET_ACCOUNT_DEPOTSTAMMDATEN, {
      bgsNr: this.$route.params?.bgsNr,
    });
  },
  methods: {
    goToTransaktionen() {
      this.$router.push({
        path: `/home/berichte/transaktionen/bankkonten?params={"kontonummer":"${this.kontonummer}"}`,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.dashboard-panel-widget {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  
  &.dashboard-panel-widget__size-50 {
    width: calc(50% - 24px);
  }

  &.dashboard-panel-widget__size-100 {
    width: calc(100% - 24px);
  }

  .dashboard-panel-widget__header {
    display: flex;
    justify-content: space-between;
    margin: 0 0 16px;
  }

  .dashboard-panel-widget__content {
    flex: 1 0 auto;
    align-items: stretch;
  }
}
</style>