<template>
    <div>
        <div class="box__container">
            <div class="box__title">{{ headLabel }}</div>

            <div class="box__container">
                <InputToggleSwitch v-model="TARGET_ISIN" :label="targetIsinLabel" :config="{bold: true}" inLineLabel suppressValidationMessage 
                    @input="changeType('TARGET_ISIN', $event)"/>

                <ComboBox
                    label="Lagerstelle"
                    v-model="lagerstelle" 
                    :values="lagerstellen"
                    isComponentHalfSize
                    :firstEmpty="false"
                    :disabled="TARGET_KATEGORIE"
                    :sortComboboxValues="false"
                    @change="updateStore({lagerstelle: $event}); searchWertpapier()"
                />
                <ComboBox
                    label="Gesellschaft"
                    v-model="gesellschaft" 
                    :values="gesellschaften"
                    isComponentHalfSize
                    :firstEmpty="false"
                    :disabled="TARGET_KATEGORIE"
                    :sortComboboxValues="false"
                    @change="updateStore({gesellschaft: $event}); searchWertpapier()"
                />
                <InputField
                    label="Zusätzliche Einschränkungen"    
                    v-model="zusatz"
                    isComponentHalfSize
                    placeholder="ISIN, WKN, Wertpapiername, Kategorie"
                    :disabled="TARGET_KATEGORIE"
                    @input="updateValue('zusatz', $event)"
                />
                <BaseButton class="mb-3" @click="searchWertpapier()" :disabled="searchWertpapierDisabled">
                    <span>Suche Wertpapier</span>
                    <AnimatedSpinner v-if="loading"/>
                </BaseButton>
                <ComboBox
                    label="Wertpapierauswahl"
                    v-model="wertpapier" 
                    :values="wertpapiere"
                    isComponentHalfSize
                    :firstEmpty="false"
                    :disabled="TARGET_KATEGORIE"
                    :sortComboboxValues="false"
                    @change="updateStore({wertpapier: $event})"
                />
            </div>
            <div class="box__container">
                <InputToggleSwitch v-model="TARGET_KATEGORIE" :label="targetKategorieLabel" :config="{bold: true}" inLineLabel suppressValidationMessage
                    @input="changeType('TARGET_KATEGORIE', $event)" />

                <ComboBox
                    label="Kategorie"
                    v-model="kategorie" 
                    :values="kategorien"
                    isComponentHalfSize
                    :firstEmpty="false"
                    :disabled="TARGET_ISIN"
                    :sortComboboxValues="false"
                    @change="updateStore({kategorie: $event})"
                />
            </div>
            <div class="box__container">
                <div class="box__title">Suchoptionen</div>
                <InputField
                    label="Mindestwert"    
                    v-model="minvalue"
                    isComponentHalfSize
                    type="currency"
                    @change="updateValue('minvalue', $event)"
                />
                <InputField
                    label="Höchstwert"    
                    v-model="maxvalue"
                    isComponentHalfSize
                    type="currency"
                    @change="updateValue('maxvalue', $event)"
                />
                <InputField
                    label="Mindestanteil"    
                    v-model="minanteil"
                    isComponentHalfSize
                    type="percent"
                    @change="updateValue('minanteil', $event)"
                />
                <InputField
                    label="Maximaler Anteil"    
                    v-model="maxanteil"
                    isComponentHalfSize
                    type="percent"
                    @change="updateValue('maxanteil', $event)"
                />
            </div>
            <div class="box__container">
                <div class="box__title">{{ standardwerteLabel }}</div>
                <InputRadioBoxGroup 
                    v-model="betragModus" 
                    :values="betragModusValues"
                    @input="updateValue('betragModus', $event)"
                />
                <InputField v-if="betragModus === 'PROZENT'"
                    label="Auftragshöhe"    
                    v-model="betrag"
                    isComponentHalfSize
                    type="percent"
                    :disabled="betragModus === 'VALUE_BETRAG_MODUS_ALLE_ANTEILE'"
                    @change="updateBetrag"
                />
                <InputField v-else
                    label="Auftragshöhe"    
                    v-model="betrag"
                    isComponentHalfSize
                    type="currency"
                    :disabled="betragModus === 'VALUE_BETRAG_MODUS_ALLE_ANTEILE'"
                    @change="updateBetrag"
                />
                <DatePickerField
                    label="Ausführungstermin"
                    isValueAsString
                    isComponentHalfSize
                    showSofortOption
                    v-model="termin"
                    @change="updateValue('termin', $event)"
                />
                <InputField v-if="!isVerkauf"
                    label="Ausgabeaufschlag"    
                    v-model="inputAA"
                    type="percent"
                    isComponentHalfSize
                    @change="updateValue('inputAA', $event)"
                />
                <InputField
                    label="Bemerkung"    
                    v-model="bemerkung"
                    isComponentHalfSize
                    @change="updateValue('bemerkung', $event)"
                />
            </div>
            <div class="box__container" v-if="!isVerkauf">
                <div class="box__title">{{ selectedPositionLabel }}</div>
                <WertpapierAuswahlUnique 
                    :data="{'tauschInIsin': tauschInIsin}" 
                    :hasFactsheet="true"
                    id="tauschInIsin" 
                    label="Achtung: der hier als Umschichtungsziel gewählte Fonds ist eventuell nicht bei allen Lagerstellen beziehbar." 
                    :config="{ isEmbedded: true }"
                    @openFondsfinder="openFondsfinder"
                    @removePosition="removePosition($event)" 
                    :disabled="isVerkauf"
                />
            </div>
        </div>
    </div>
</template>

<script>
import InputField from '@/components/core/forms/InputField.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import WERTPAPIERE_ALLE_KUNDEN_TYPES from '@/store/wertpapiereAlleKunden/types';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import WertpapierAuswahlUnique from'@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlUnique.vue';
import wertpapiereMixin from './wertpapiere-mixin';
import {parse} from '@/helpers/number-formatter.js';

export default {
    mixins: [wertpapiereMixin],
    components: {
        InputRadioBoxGroup,
        InputField,
        ComboBox,
        AnimatedSpinner,
        BaseButton,
        InputToggleSwitch,
        DatePickerField,
        WertpapierAuswahlUnique,
    },
    data() {
        return {
            TARGET_ISIN: false,
            TARGET_KATEGORIE: false,
            lagerstelle: '',
            gesellschaft: '',
            kategorie: '',
            wertpapier: '',
            zusatz: '',
            minvalue: '',
            maxvalue: '',
            minanteil: '',
            maxanteil: '',
            termin: '',
            bemerkung: '',
            betrag: '',
            betragModus: '',
            tauschInIsin: '',
            tauschInIsin_wertpapiername: '',
            inputAA: '',

            loading: false,
            isInit: true,
        }
    },
    mounted() {
        this.updateForm();
    },
    watch: {
        'wertpapiere': 'updateWertpapier',
        'data': 'updateForm',
    },
    computed: {
        headLabel() {
            return `Bitte wählen Sie hier, welches Wertpapier Sie ${this.isVerkauf ? 'verkaufen' : 'umschichten'} möchten`;
        },
        targetIsinLabel() {
            return `${this.isVerkauf ? 'Verkauf' : 'Umschichtung'} eines Wertpapiers`;
        },
        targetKategorieLabel() {
            return `${this.isVerkauf ? 'Verkauf' : 'Umschichtung'} der Wertpapiere einer Kategorie`;
        },
        searchWertpapierDisabled() {
            return !this.data.TARGET_ISIN || !this.data.zusatz?.trim();
        },
        standardwerteLabel() {
            return `Bitte geben Sie hier die Standardwerte für ${this.isVerkauf ? 'Verkauf' : 'Umschichtung'}santräge ein`;
        },
        headerActions() {
            return [
                PageHeaderSimpleAction('LIST', 'Kundenliste anzeigen')
            ];
        },
        fondsfinderDisabled() {
            return this.isVerkauf || (!this.data.wertpapier || this.data.wertpapier === 'ISIN_KEINE')
        },
    },
    methods: {
        updateForm() {
            if (this.isInit) {
                Object.keys(this.data).filter(key => Object.keys(this)?.includes(key)).forEach(key => this[key] = this.data[key]);
                this.isInit = false;
            }
        },
        searchWertpapier() {
            if (this.gesellschaft !== 'GESELLSCHAFT_ALLE' || this.lagerstelle !== 'LAGERSTELLE_ALLE' || !!this.zusatz?.trim()) {
                this.$store.dispatch(WERTPAPIERE_ALLE_KUNDEN_TYPES.ACTIONS.SEARCH_WERTPAPIER);
            }
        },
        changeType(type, selected) {
            if (selected && type === 'TARGET_ISIN') {
                this.TARGET_KATEGORIE = false;
                this.updateStore({TARGET_ISIN: selected, TARGET_KATEGORIE: !selected});
            } else if (selected && type === 'TARGET_KATEGORIE') {
                this.TARGET_ISIN = false;
                this.updateStore({TARGET_KATEGORIE: selected, TARGET_ISIN: !selected});
            }
        },
        updateWertpapier(values) {
            if (values?.length) {
                this.wertpapier = values[0]?.value || '';
                this.updateStore({ wertpapier: this.wertpapier });
            }
        },
        openFondsfinder() {
            if (!this.isVerkauf && !this.canOpenFondsfinder) {
                this.openFondsfinderWarningModal();
            } else {
                this.$router.push({path: '/beratung/wertpapiere-alle-kunden/fondsfinder', query: {
                    lagerstelle: this.lagerstelle === 'LAGERSTELLE_ALLE' ? '' : this.lagerstelle,
                    tauschvon: ''
                }})
            }
        },
        removePosition() {
          
        },
        updateValue(compId, value) {
            if (compId) {
                this.$store.commit(WERTPAPIERE_ALLE_KUNDEN_TYPES.MUTATIONS.UPDATE_STORE, { [compId]: value });
            }
        },
        updateBetrag(value) {
            this.updateValue('betrag', parse(value));
        }
    }
}
</script>

<style>

</style>