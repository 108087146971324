import BaseImage from '@/components/core/BaseImage.vue';
import UndrawFillForms from '@/components/icons/undraw/UndrawFillForms.vue'
import UndrawSecureServer from '@/components/icons/undraw/UndrawSecureServer.vue'
import UndrawInvestorUpdate from '@/components/icons/undraw/UndrawInvestorUpdate.vue'
import { extToMimes } from '@/components/mailcomposer/mime';
import CORE_TYPES from '@/store/core/types'
import { prepareFilenameForDownload } from '@/helpers/string-helper';

export const HOME_URL = `${window.location?.origin || ''}`;

export function isDescendant(parent, child) {
  let node = child.parentNode;
  while (node != null) {
    if (node === parent) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
}

export function checkGettersDeclarationIntegrity(modules) {
  for (const key in modules) {
    if (Object.hasOwnProperty.call(modules, key)) {
      const element = modules[key];
      Object.keys(element.getters).forEach(function (getterKey) { 
        if (getterKey === 'undefined') {
          console.error(`the module '${key}' has a 'undefined' getter. This happens when a getter is created but no type is declared. See: ${element.getters[getterKey]}`)
        }
      });
    }
  }
}

export function componentByLagerstelle(lagerstelle) {
  switch (lagerstelle?.path) {
    case 'FA':
    case 'FK':
      return UndrawFillForms
    case 'VERSICHERUNG':
      return UndrawSecureServer
    case 'WP':
        return UndrawInvestorUpdate
    default:
      return BaseImage
  }
}

export function isDeepEquals(a, b) {
  if (a === b) {
    return true
  }

  if (typeof a != 'object' || typeof b != 'object' || a == null || b == null) {
    return false
  }

  let keysA = Object.keys(a)
  let keysB = Object.keys(b)

  if (keysA.length != keysB.length) {
    return false
  }

  for (let key of keysA) {
    if (!keysB.includes(key)) return false;

    if (typeof a[key] === 'function' || typeof b[key] === 'function') {
      if (a[key].toString() != b[key].toString()) return false;
    } else {
      if (!isDeepEquals(a[key], b[key])) return false;
    }
  }

  return true;
}

function downloadLinkMaklerLocal(apiAddress, token, servicePath, filename, queryParams) {
  let dbPrefix = ''

  // It's not necessary to add this param for production because there the database is selected by the domain
  if (process.env.NODE_ENV === 'development' && process.env.VUE_APP_DB_PREFIX) {
    dbPrefix = process.env.VUE_APP_DB_PREFIX;
  }

  const params = makeQueryParam({...queryParams, token, dbPrefix})

  return `${apiAddress}${servicePath}/${prepareFilenameForDownload(filename)}?${params}`
}

export function downloadLinkMaker(getters, servicePath, filename, queryParams) {
  const apiAddress = getters[CORE_TYPES.GETTERS.API_ADDRESS];
  const token = getters[CORE_TYPES.GETTERS.GET_TOKEN];
  return downloadLinkMaklerLocal(apiAddress, token, servicePath, filename, queryParams);
}

export function downloadLinkMakerLinkResolver(localState, servicePath, filename, queryParams) {
  const { apiAddress, token } = localState;
  return downloadLinkMaklerLocal(apiAddress, token, servicePath, filename, queryParams);
}

export function makeQueryParam(params, useEncodedValues = false) {
  if (!params) {
    return ''
  }
  
  return Object.entries(params)
    .filter(([key, val]) => key && val !== undefined && val !== null)
    .map(([key, val]) => {
      if(val instanceof Array){
        return val.map(value => `${key}=${useEncodedValues ? encodeURIComponent(value) : value}`).join('&');
      }
      return `${key}=${useEncodedValues ? encodeURIComponent(val) : val}`;
    })
    .join('&');
}

export function isSameOrigin(url1, url2) {
  if (!url1 || !url2) return false;

  const url1Obj = new URL(url1, location.origin);
  const url2Obj = new URL(url2, location.origin);
  return url1Obj.origin === url2Obj.origin;
}

export function encodeURIComponentIfNeeded(uri) {
  const isAlreadyEncoded = uri => uri !== decodeURIComponent(uri);

  if (!uri || isAlreadyEncoded(uri)) {
    return uri;
  }
  return encodeURIComponent(uri);
}

export function decodeURIComponentIfNeeded(uri) {
  if (!uri) {
    return uri;
  }
  return decodeURIComponent(uri);
}

export function parseParamsIntoObject(queryString) {
  const params = new URLSearchParams(queryString);
  const obj = {};

  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
        obj[key] = params.getAll(key);
    } else {
        obj[key] = params.get(key);
    }
  }

  return obj;
}

export function parseBoolean(value) {
  return value === 'true';
}

export function isValidHttpURL(url) {
  return (/^(https?:\/\/)/gi).test(url);
}

export function toAbsoluteURL(url) {
  if(!url || isValidHttpURL(url)) {
    return url;
  }

  const separator = url.trim().startsWith('/') ? '' : '/';
  return [HOME_URL, url].join(separator);
}

export function addParamToURL(url, param) {
  if(!url || !param) {
    return url;
  }
  const separator = url.search(/\?/gi) < 0 ? '?' : '&';
  return [url, param].join(separator);
}

export function getFilenameFromHeaders(headers) {
  if(!headers) {
    return '';
  }

  const headerLine = headers['content-disposition'] || headers['Content-Disposition'];
  if(!headerLine || !headerLine.indexOf('filename') < 0) {
    return '';
  }

  const startFilenameIndex = headerLine.indexOf('"') + 1;
  const endFilenameIndex = headerLine.lastIndexOf('"');
  return headerLine.substring(startFilenameIndex, endFilenameIndex);
}

export function addFileExtension(filename, extension) {
  if(!filename || !extension) {
    return filename;
  }

  if(filename.endsWith(extension)) {
    return filename;
  } else {
    return `${filename}.${extension}`
  }
}

export function extractFileExtension(filename) {
  return filename?.split('.')?.pop();
}

export function extractMimeType(filename) {
  return extToMimes?.[extractFileExtension(filename)] || '';
}

export function extractExtensionFromMimeType(contentType) {
  const mimeToExt = {};
  Object.keys(extToMimes).map(k => {
    mimeToExt[extToMimes[k]] = k;
  })
  return mimeToExt?.[contentType] || '';
}

export function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function maxLengthOrEllipsis(s,maxLength) {
  if (s && s.length > maxLength) {
    return s.substring(0, maxLength) + '...';
  }
  return s;
}

export function eventCoord(event, coord) {
  let eventCoords = event;

  if(event.targetTouches && event.targetTouches.length) {
    eventCoords = event.targetTouches[0];
  } else if(event.changedTouches && event.changedTouches.length) {
    eventCoords = event.changedTouches[0];
  }

  return eventCoords[coord];
}
