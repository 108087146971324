import VERTRAGSSPIEGEL_TYPES from './types';
import CORE_TYPES from '@/store/core/types'
import axios from 'axios';
import { makeQueryParam } from '@/helpers/utils-helper';

const config = {
  defaultSpinner: true
};

export default {
  [VERTRAGSSPIEGEL_TYPES.ACTIONS.GET_FIELDS_DEFINITION]({ commit, rootState }) {
    let url = rootState.core.apiAddress + '/vertragsspiegel/vertragsspiegelFieldsDefinition';

    axios.get(url).then(response => {
      if (response && response.data) {
        commit(VERTRAGSSPIEGEL_TYPES.MUTATIONS.FIELDS_DEFINITION_SUCCESS, response.data);
      }
    })
  },
  [VERTRAGSSPIEGEL_TYPES.ACTIONS.GET_PDF_FILE]({ rootState, getters }, payload) {
    let url = rootState.core.apiAddress + `/download_service/vertragsspiegelGeneratePDF/${payload.filename}`;

    const token = getters[CORE_TYPES.GETTERS.GET_TOKEN]
    window.open(url + '?' + makeQueryParam({...payload, token}))
  },

}