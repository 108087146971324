<template>
  <div class="makler34f-config-loading__container">
    <GhostLoading class="mb-4">
      <Block type="title" class="mb-3" />
      <Block type="form-input-switch" class="mb-3" />
      <Block type="paragraph" />
      <Block type="paragraph" />
    </GhostLoading>

    <GhostLoading type="input" isHalfSize></GhostLoading>
    <GhostLoading>
      <div class="d-flex">
        <Block type="form-input" :width="128" class="mr-2 mb-0" />
        <Block type="form-input" :width="128" />
      </div>
    </GhostLoading>
    <GhostLoading type="table"></GhostLoading>
  </div>
</template>

<script>
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';

export default {
  components: {
    GhostLoading,
    Block,
  },
}
</script>