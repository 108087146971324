<template>
  <div>
    <GesellschaftTitleNavigation
      :headerActions="headerActions"
      @action-SET_FREMDNUMMERN="setFremdnummern"
      @action-DELETE_FREMDNUMMERN="deleteFremdnummern"
    />

    <GhostLoading v-if="loading" title="Nummern" useBoxContainer type="table" />

    <template v-else>
      <div class="box__container">
        <div class="box__title">Nummern</div>
        <div>Generierungsvorschrift für Fremdnummer: <b>{{nummern.generierungFremdnr}}</b></div>
        <InputField
          label="Vornummer" 
          isComponentHalfSize
          v-model="nummern.vornummer"
        />
        <Table 
          :title="TABLE_TITLE"
          :rows="rows"
          :headers="headers"
          :headerActions="tableHeaderActions"
          :rowsPerPage="20"
          @click-maklernr="openBroker"
          @headerAction-NEW="openCreateVornummerModal"
          @action-DELETE="deleteVornummer"
        />

        <InputRadioBoxGroup 
          v-model="nummern.artGenerierung" 
          :values="artGenerierungValues"
        />

        <InputField
          label="akt. laufende Nummer" 
          isComponentHalfSize
          v-model="nummern.laufendeNummer"
        />
        <InputField
          label="Stelligkeit der laufenden Nummer" 
          isComponentHalfSize
          v-model="nummern.stellenLfdnr"
        />
        <InputToggleSwitch
          label="Antrag direkt bei Gesellschaft einreichen"
          v-model="nummern.antragDirekt"
        />
      </div>

      <div class="box__container">
        <div class="box__title">Kunden-Kontonummern bei Gesellschaft</div>
        <InputField
          label="Anzahl der Stellen"
          type="number"
          isComponentHalfSize
          v-model="nummern.stellenKontonr"
        />
        <InputField
          :class="{'mb-0': !!beispielKontonrError}"
          label="Beispiel für Kontonummer" 
          isComponentHalfSize
          v-model="nummern.beispielKontonr"
          :renderDanger="!!beispielKontonrError"
          @input="checkNummern"
        />
        <div class="fc-form-danger" v-if="!!beispielKontonrError">{{beispielKontonrError}}</div>
        <InputField
          :class="{'mb-0': !!formatKontonrError}"
          label="Format der Kontonummer" 
          isComponentHalfSize
          v-model="nummern.formatKontonrEinfach"
          :renderDanger="!!formatKontonrError"
          @input="checkNummern"
        />
        <div class="fc-form-danger" v-if="!!formatKontonrError">{{formatKontonrError}}</div>

        <div>
          <b>Legende Format</b><br>
          <small>
            z eine Ziffer 0 bis 9<br>
            b ein Großbuchstabe A bis Z<br>
            (ABC) genau eines der eingeklammerten Zeichen, hier A oder B oder C<br>
            sind mehrere Formate möglich, können einzelne Vorgaben durch Strichpunkt (; ohne Leerzeichen) getrennt werden<br>
            Beispiel: 991X12345678 oder 991Y12345678 wird durch 991(XY)zzzzzzzz erkannt.<br>
            Beispiel für mehrfache Vorgaben: zzz;bbb erkennt 123 und ABC, nicht aber A23<br>
          </small>
        </div>
      </div>
    </template>

    <BaseModal
      ref="createVornummerModal"
      modalTitle="Vornummer hinzufügen"
      labelButtonConfirm="Speichern"
      :confirmDisabled="!editVornummer.vornummer || !editVornummer.maklernr || (editVornummer.maklernr && editVornummer.maklernr.length !== 5)"
      @onConfirmButton="addVornummer()"
    >

        <InputField
            label="Vermittlernr"
            :renderDanger="!editVornummer.maklernr || (editVornummer.maklernr && editVornummer.maklernr.length !== 5)"
            v-model="editVornummer.maklernr"
        />
        <InputField
            label="Vornummer"
            :renderDanger="!editVornummer.vornummer"
            v-model="editVornummer.vornummer"
        />
    </BaseModal>
  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import GESELLSCHAFT_TYPES from '@/store/gesellschaft/types';
import { mapGetters } from 'vuex';
import axios from 'axios';
import { makeQueryParam } from '@/helpers/utils-helper';

import GesellschaftTitleNavigation from '@/views/gesellschaft/intern/GesellschaftTitleNavigation.vue'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Table from "@/components/table2/Table.vue";
import { TextColumn, ActionColumn, SimpleAction, } from "@/components/table2/table_util.js";
import { PageHeaderSimpleAction, PageHeaderConfirmedAction } from '@/components/core/header-title-navigation/page-header-utils';

import InputField from '@/components/core/forms/InputField.vue'
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue'
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import BaseModal from '@/components/core/BaseModal.vue';

const TABLE_TITLE = 'Spezielle Vornummern für Strukturen';

const config = {
  defaultSpinner: true
};

export default {
  mixins: [],
  components: {
    GesellschaftTitleNavigation,
    GhostLoading,
    Table,
    InputField,
    InputToggleSwitch,
    InputRadioBoxGroup,
    BaseModal,
  },
  data() {
    return {
      TABLE_TITLE,
      loading: false,
      nummern: {},
      originalNummern: null,
      editVornummer: {},
      artGenerierungValues: [
        {
          value: 'NONE',
          label: 'keine automatische Nummerngenerierung'
        },
        {
          value: 'MAKLERNR',
          label: 'Vornummer + Maklernummer'
        },
        {
          value: 'LFDNR',
          label: 'Vornummer + lfd. Nummer'
        }
      ],
      formatKontonrError: null,
      beispielKontonrError: null,
      timeoutRef: null,
    }
  },
  watch: {
    gesellschaft: {
      handler(newVal) {
        this.nummern = newVal.nummern || {}

        if (!this.originalNummern && Object.keys(this.nummern).length) {
          // Create a deep copy and compare it when trying to save. If no changes were made we don't need to save
          this.originalNummern = JSON.parse(JSON.stringify(this.nummern))
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      gesellschaft: GESELLSCHAFT_TYPES.GETTERS.GET_GESELLSCHAFT,
    }),
    headers() {
      return {
        lockedLeft: [
          TextColumn("maklernr", "Strukturleiter").makeLink(),
          TextColumn("strukturname", "Name der Struktur"),
          TextColumn("vornummer", "Vornummer"),
        ],
        center: [],
        lockedRight: [
          ActionColumn('actions')
        ],
      }
    },
    rows() {
      const vornummern = this.nummern?.vornummern || []
      return vornummern.map(nr => ({
        ...nr,
        actions: [
          SimpleAction('DELETE', 'PhTrash', 'Löschen'),
        ]
      }))
    },
    tableHeaderActions() {
      return [
        PageHeaderSimpleAction('NEW', 'Hinzufügen'),
      ];
    },
    headerActions() {
      return [
        PageHeaderConfirmedAction('SET_FREMDNUMMERN', 'Fremdnummern setzen', 
            'Sollen die Fremdnummern für alle Makler gesetzt werden?', 'Fremdnummern setzen', 'Ja'),
        PageHeaderConfirmedAction('DELETE_FREMDNUMMERN', 'Fremdnummern löschen', 
        'Sollen die Fremdnummern bei allen Maklern gelöscht werden?', 'Fremdnummern löschen', 'Löschen'),
      ]
    },
  },
  mounted() {
      this.loading = true

      this.$store.dispatch(GESELLSCHAFT_TYPES.ACTIONS.GET_GESELLSCHAFT_NUMMERN, { gesellschaftId: this.$route.params.gesellId })
      .then(() => this.checkNummernGueltigkeit())
      .finally(() => this.loading = false);
  },
  methods: {
    addVornummer() {
      const params = makeQueryParam({ ...this.editVornummer, gesellschaftId: this.$route.params.gesellId }, true)

      axios.get(`${process.env.VUE_APP_API}/gesellschaftenService/vornummer?${params}`, config)
        .then(response => this.nummern.vornummern.push(response.data))
    },
    openCreateVornummerModal() {
      this.editVornummer = {}
      this.$refs.createVornummerModal.open()
    },
    deleteVornummer({maklernr, vornummer}) {
      const params = makeQueryParam({ maklernr, vornummer, gesellschaftId: this.$route.params.gesellId }, true)

      axios.delete(`${process.env.VUE_APP_API}/gesellschaftenService/vornummer?${params}`, config)
        .then(() => {
          const index = this.nummern.vornummern.findIndex(row => row.maklernr === maklernr && row.vornummer === vornummer);

          if (index >= 0) {
            this.nummern.vornummern.splice(index, 1);
          }
        })
    },
    openBroker({maklernr}) {
      this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_BROKER_NEW_TAB, { maklernr })
    },
    async saveNummern() {
      if (JSON.stringify({...this.originalNummern, vornummern: null}) === JSON.stringify({...this.nummern, vornummern: null})) {
        return
      }

      const params = makeQueryParam({ gesellschaftId: this.$route.params.gesellId }, true)

      return axios.post(`${process.env.VUE_APP_API}/gesellschaftenService/nummern?${params}`, this.nummern, config)
        .then(response => {
          this.nummern = response.data
          this.originalNummern = JSON.parse(JSON.stringify(this.nummern))
        })
    },
    async setFremdnummern() {
      await this.saveNummern()

      const params = makeQueryParam({ gesellschaftId: this.$route.params.gesellId }, true)

      axios.post(`${process.env.VUE_APP_API}/gesellschaftenService/fremdnummern?${params}`, undefined, config)
        .then(response => this.$confirmModal({
        title: response.data,
        labelButtonConfirm: 'Ok',
        showCancelButton: false,
      }))
    },
    async deleteFremdnummern() {
      await this.saveNummern()

      const params = makeQueryParam({ gesellschaftId: this.$route.params.gesellId }, true)

      axios.delete(`${process.env.VUE_APP_API}/gesellschaftenService/fremdnummern?${params}`, config)
        .then(response => this.$confirmModal({
        title: response.data,
        labelButtonConfirm: 'Ok',
        showCancelButton: false,
      }))
    },
    checkNummern() {
      clearTimeout(this.timeoutRef)
      this.timeoutRef = setTimeout(() => this.checkNummernGueltigkeit(), 500)
    },
    checkNummernGueltigkeit() {
      const params = makeQueryParam({ 
        gesellschaftId: this.$route.params.gesellId, 
        beispiel: this.nummern.beispielKontonr, 
        format: this.nummern.formatKontonrEinfach,
      }, true)

      axios.post(`${process.env.VUE_APP_API}/gesellschaftenService/checkNummernGueltigkeit?${params}`, undefined, config)
        .then(response => {
          this.beispielKontonrError = response.data?.beispielKontonrError
          this.formatKontonrError = response.data?.formatKontonrError
        })
    },
  },
  beforeRouteLeave(to, from, next) {
    this.saveNummern();
    next();
  },
}
</script>