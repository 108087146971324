var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "editTransactionCommentModal",
          attrs: {
            modalTitle: "Kommentar",
            labelButtonConfirm: "Speichern",
            labelButtonCancel: "Abbrechen"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.saveComment()
            },
            onCancelButton: function($event) {
              return _vm.close()
            }
          }
        },
        [
          _c("InputField", {
            attrs: { type: "text", label: "" },
            model: {
              value: _vm.comment,
              callback: function($$v) {
                _vm.comment = $$v
              },
              expression: "comment"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }