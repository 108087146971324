<template>
<div>
    <BoxContainer :title="title">
        <Table v-if="rows.length"
            :headers="headers"
            :rows="rows"
            rowId="key"
            hidePagination
            :mobileConfig="{title: 'key', headers: ['col_1', 'col_3']}"
        />
        <div v-else>Keine Daten</div>
    </BoxContainer>
</div>
</template>

<script>
import FONDSINFO_TYPES from "@/store/fondsinfo/types";
import BoxContainer from '@/components/core/BoxContainer.vue';
import { mapGetters } from 'vuex';
import Table from "@/components/table2/Table.vue";
import {TextColumn, PercentageColumn} from "@/components/table2/table_util.js";

export default {
  components: {
    BoxContainer,
    Table,
  },
  name: "TabPerformance",
  props:{
    selectedTabLabel: {
      type: String
    }
  },
    data() {
        return {
            headers: {
                center: [
                    TextColumn("key", "Jahr"),
                    PercentageColumn("col_1", ""),
                    PercentageColumn("col_2", "p.a."),
                    PercentageColumn("col_3", "Sektor"),
                    PercentageColumn("col_4", "Sek. p.a."),
                ],
            }
        };
    },
  computed: {
    ...mapGetters({
        performance : FONDSINFO_TYPES.GETTERS.FONDSINFO_PERFORMANCE,
    }),
    rows() {
        return (this.performance?.records || []).map(row => ({
            key: row[0],
            col_1: row[1],
            col_2: row[2],
            col_3: row[3],
            col_4: row[4],
        }));
    },
    title() {
      return "Performance-Kennzahlen" + (this.performance?.date ? ' (Stand: ' + this.performance?.date+')' : '')
    }
  },
  mounted() {
    if(!this.performance) {
      this.$store.dispatch(FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_PERFORMANCE, 
      {
        isin: this.$route.params?.isin
      });
    }
  },

}
</script>

<style>

</style>