var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("PhSpinnerGap", {
    staticClass: "animated-spinner__element spin-animation",
    attrs: { size: _vm.size, weight: "bold" }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }