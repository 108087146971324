var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "data-name": "Layer 1",
        width: "632.67004",
        height: "516.54595",
        viewBox: "0 0 632.67004 516.54595",
        "xmlns:xlink": "http://www.w3.org/1999/xlink"
      }
    },
    [
      _c("polygon", {
        attrs: {
          points:
            "104.115 502.753 114.686 502.752 119.715 461.977 104.113 461.977 104.115 502.753",
          fill: "#9e616a"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M385.08322,691.02818l2.97556-.00012,11.61705-4.72438,6.22638,4.72366h.00084a13.26818,13.26818,0,0,1,13.26749,13.26728v.43114l-34.08668.00126Z",
          transform: "translate(-283.66498 -191.72702)",
          fill: "#2f2e41"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "153.228 502.753 163.799 502.752 168.828 461.977 153.226 461.977 153.228 502.753",
          fill: "#9e616a"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M434.19615,691.02818l2.97556-.00012,11.617-4.72438,6.22638,4.72366h.00085a13.26818,13.26818,0,0,1,13.26748,13.26728v.43114l-34.08668.00126Z",
          transform: "translate(-283.66498 -191.72702)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M445.34294,471.33576l-83.05862,2.16675,1.4445,40.44593s-1.4445,10.83373,1.44449,13.72273,4.3335,2.889,2.889,7.94474,6.18745,13.31326,5.26047,14.24024-3.09372,5.98272-3.09372,5.98272l7.22248,41.16819s5.05575,71.50263,6.50024,72.94713,2.889,0,1.4445,3.61125-2.889,2.16674-1.4445,3.61124a53.19431,53.19431,0,0,1,3.61125,4.33349H405.025s1.31654-7.22249,1.31654-7.94473,1.4445-5.05575,1.4445-5.778-1.27674-1.999-1.27674-1.999a23.76163,23.76163,0,0,1-.89-4.50126c0-1.4445-4.33349-57.05766-4.33349-57.05766l8.667-72.22489L430.898,602.78505s0,62.1134,1.4445,63.5579,1.44449.72225.72224,3.61124-3.61124,2.16675-1.44449,4.33349,2.889-1.44449,2.16674,2.16675l-.72225,3.61125,21.66747.30994s2.889-6.08794,1.4445-8.25468-1.35783-1.5753.40446-5.12114,2.48453-4.2681,1.76228-4.99035-.72224-4.56381-.72224-4.56381l-3.61125-69.10557s-.72225-72.94713-.72225-75.11388a7.20407,7.20407,0,0,1,.6-3.24922v-2.94108l-2.76672-10.42142Z",
          transform: "translate(-283.66498 -191.72702)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M539.33969,313.81141a8.06517,8.06517,0,0,1-11.60958,4.2615L491.67616,348.278l-1.9594-14.76429,35.26391-26.99494a8.1089,8.1089,0,0,1,14.359,7.29261Z",
          transform: "translate(-283.66498 -191.72702)",
          fill: "#9e616a"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "96.69646",
          cy: "112.1608",
          r: "22.38971",
          fill: "#9e616a"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M398.51273,281.60528c-2.08444-4.96819-11.01748-7.918-25.15293-7.47373s-16.61088,17.301-16.61088,17.301c-7.78581,7.25681,4.78422,29.91413,6.965,26.79028s5.84225-.17107,5.84225-.17107l-3.48932-19.62672c3.68483,1.41759,7.64781,2.36942,11.11371.71789l-5.74251-3.07069q7.139.88839,14.27814,1.77675l-5.88835-4.53c.14273-.04712.28548-.09609.4283-.14415a20.92492,20.92492,0,0,0,7.45992,1.6937c3.002-.02239,6.19232-1.42914,7.4503-4.155a6.1387,6.1387,0,0,0,.53276-2.3761c4.13922,1.34388,6.31262,6.19288,6.31262,6.19288A76.404,76.404,0,0,0,398.51273,281.60528Z",
          transform: "translate(-283.66498 -191.72702)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M398.28346,327.279c-5,0-8.22.69-16,2-14.36,2.42-5.76,16.06452-5.76,16.06452s.65-.33-1.11,0-7.76,5.52-7.76,5.52l-10.73,9.53c-16.17,12.69-12.55,32.49-12.55,32.49l23.58,23.73-10.33,47.61s4.39,7.07,2.11994,10.24,20,2.26,20,2.26c15.37,6.85,69.52,1.06,69.52,1.06-.75-1.96-8-17.15-8-17.15,3.15-5.44.15-13.3.15-13.3s-3.13-37.18-5.13-42.32,5.12006-32.99,9.8-32.69,34.91-15.12,34.91-15.12-.76-1.21,9.22-5.29a48.91157,48.91157,0,0,0,15.49-9.77l4.1-8.19c-1.97.76-8.62-11.78-8.62-11.78l-2.9,1.10549-1.68-.4855s-13.11,3.73-15.79,6.45-3.58,1.96-6,2.57-2.72,2.31-3.48,2.12-2.11,2.11-3.17,1.81-3.33.91-4.84,1.21-12.24,4.84-12.24,4.84c-6.64-3.36-24.64-2-24.64-2s-5.89-3.47-7.56,0a5.69,5.69,0,0,1-5.13,3.33l-7.18-3.07C406.15615,341.57662,398.32448,325.70015,398.28346,327.279Z",
          transform: "translate(-283.66498 -191.72702)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M364.00406,513.61215a8.0652,8.0652,0,0,1,1.80006-12.2353L355.6317,461.38841l13.90765,5.32924,7.72924,37.76127a8.1089,8.1089,0,0,1-13.26453,9.13323Z",
          transform: "translate(-283.66498 -191.72702)",
          fill: "#9e616a"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M345.28346,385.279l-1.25621,15.9685s-1.56155,17.54331-1.15267,20.7874S344.04489,445.09,344.04489,445.09l13.23857,26.189,15-4-.89763-12.28549.89763,11.28549,2-10-5-17,3-6L370.7706,413.1378Z",
          transform: "translate(-283.66498 -191.72702)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M809.09841,582.437H744.4319a6.50753,6.50753,0,0,1-6.5-6.5v-56a6.50753,6.50753,0,0,1,6.5-6.5h64.66651a6.50753,6.50753,0,0,1,6.5,6.5v56A6.50753,6.50753,0,0,1,809.09841,582.437Z",
          transform: "translate(-283.66498 -191.72702)",
          fill: "#f2f2f2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M809.09841,511.437H744.4319a6.50753,6.50753,0,0,1-6.5-6.5v-56a6.50753,6.50753,0,0,1,6.5-6.5h64.66651a6.50753,6.50753,0,0,1,6.5,6.5v56A6.50753,6.50753,0,0,1,809.09841,511.437Z",
          transform: "translate(-283.66498 -191.72702)",
          fill: "#f2f2f2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M809.09841,653.437H744.4319a6.50753,6.50753,0,0,1-6.5-6.5v-56a6.50753,6.50753,0,0,1,6.5-6.5h64.66651a6.50753,6.50753,0,0,1,6.5,6.5v56A6.50753,6.50753,0,0,1,809.09841,653.437Z",
          transform: "translate(-283.66498 -191.72702)",
          fill: "#f2f2f2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M729.4319,653.437h-64.667a6.50753,6.50753,0,0,1-6.5-6.5v-56a6.50753,6.50753,0,0,1,6.5-6.5h64.667a6.50753,6.50753,0,0,1,6.5,6.5v56A6.50752,6.50752,0,0,1,729.4319,653.437Z",
          transform: "translate(-283.66498 -191.72702)",
          fill: "#f2f2f2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M905.28346,511.437H824.09841a6.50752,6.50752,0,0,1-6.5-6.5v-56a6.50753,6.50753,0,0,1,6.5-6.5h81.18505a6.50753,6.50753,0,0,1,6.5,6.5v56A6.50752,6.50752,0,0,1,905.28346,511.437Z",
          transform: "translate(-283.66498 -191.72702)",
          fill: "#f2f2f2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M576.09841,439.937v-.21h3v.21h76.6665v-.21h3v.21h76.667v.43555h3V439.937h76.66651v.43555h3V439.937h93.18505V228.33725A36.61023,36.61023,0,0,0,874.67324,191.727H517.89369a36.61024,36.61024,0,0,0-36.61023,36.61023V439.937Z",
          transform: "translate(-283.66498 -191.72702)",
          fill: "#e6e6e6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M905.28346,513.437H824.09841c-3.584,0-6.5,5.92878-6.5,13.2157V646.937a6.51018,6.51018,0,0,0,4.61578,6.21924,3.5645,3.5645,0,0,0,1.88422.5708h81.18505a3.5645,3.5645,0,0,0,1.88422-.5708,6.51017,6.51017,0,0,0,4.61578-6.21924V526.65268C911.78346,519.36576,908.86745,513.437,905.28346,513.437Z",
          transform: "translate(-283.66498 -191.72702)",
          fill: "#f2f2f2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M570.09841,653.437H487.28346a6.50753,6.50753,0,0,1-6.5-6.5v-56a6.50753,6.50753,0,0,1,6.5-6.5h82.81495a6.50753,6.50753,0,0,1,6.5,6.5v56A6.50753,6.50753,0,0,1,570.09841,653.437Z",
          transform: "translate(-283.66498 -191.72702)",
          fill: "#f2f2f2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M570.09841,582.437H487.28346a6.50753,6.50753,0,0,1-6.5-6.5v-56a6.50753,6.50753,0,0,1,6.5-6.5h82.81495a6.50753,6.50753,0,0,1,6.5,6.5v56A6.50753,6.50753,0,0,1,570.09841,582.437Z",
          transform: "translate(-283.66498 -191.72702)",
          fill: "#f2f2f2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M818.09841,656.937V655.7114h-3V656.937H738.4319v.77442h-3V656.937h-76.667v.77442h-3V656.937h-76.6665v.77442h-3V656.937H481.28346v11.17981A36.61028,36.61028,0,0,0,517.89369,704.727H874.67324a36.61027,36.61027,0,0,0,36.61022-36.61023V656.937Z",
          transform: "translate(-283.66498 -191.72702)",
          fill: "#e6e6e6"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M570.09841,511.437H487.28346a6.50753,6.50753,0,0,1-6.5-6.5v-56a6.50753,6.50753,0,0,1,6.5-6.5h82.81495a6.50753,6.50753,0,0,1,6.5,6.5v56A6.50753,6.50753,0,0,1,570.09841,511.437Z",
          transform: "translate(-283.66498 -191.72702)",
          fill: "#f2f2f2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M649.76491,582.437h-64.6665a6.50752,6.50752,0,0,1-6.5-6.5v-56a6.50753,6.50753,0,0,1,6.5-6.5h64.6665a6.50753,6.50753,0,0,1,6.5,6.5v56A6.50753,6.50753,0,0,1,649.76491,582.437Z",
          transform: "translate(-283.66498 -191.72702)",
          fill: "#f2f2f2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M649.76491,653.437h-64.6665a6.50752,6.50752,0,0,1-6.5-6.5v-56a6.50753,6.50753,0,0,1,6.5-6.5h64.6665a6.50753,6.50753,0,0,1,6.5,6.5v56A6.50753,6.50753,0,0,1,649.76491,653.437Z",
          transform: "translate(-283.66498 -191.72702)",
          fill: "#f2f2f2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M729.4319,511.437h-64.667a6.50753,6.50753,0,0,1-6.5-6.5v-56a6.50753,6.50753,0,0,1,6.5-6.5h64.667a6.50753,6.50753,0,0,1,6.5,6.5v56A6.50752,6.50752,0,0,1,729.4319,511.437Z",
          transform: "translate(-283.66498 -191.72702)",
          fill: "#f2f2f2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M729.4319,582.437h-64.667a6.50753,6.50753,0,0,1-6.5-6.5v-56a6.50753,6.50753,0,0,1,6.5-6.5h64.667a6.50753,6.50753,0,0,1,6.5,6.5v56A6.50752,6.50752,0,0,1,729.4319,582.437Z",
          transform: "translate(-283.66498 -191.72702)",
          fill: "#f2f2f2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M649.76491,511.437h-64.6665a6.50752,6.50752,0,0,1-6.5-6.5v-56a6.50753,6.50753,0,0,1,6.5-6.5h64.6665a6.50753,6.50753,0,0,1,6.5,6.5v56A6.50753,6.50753,0,0,1,649.76491,511.437Z",
          transform: "translate(-283.66498 -191.72702)",
          fill: "#f2f2f2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M840.57106,358.227H556.99587a24.24005,24.24005,0,0,1-24.21241-24.2124v-62.5752a24.24006,24.24006,0,0,1,24.21241-24.2124H840.57106a24.24005,24.24005,0,0,1,24.2124,24.2124v62.5752A24.24,24.24,0,0,1,840.57106,358.227Z",
          transform: "translate(-283.66498 -191.72702)",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d: "M745.78346,339.227h-61a5,5,0,0,1,0-10h61a5,5,0,0,1,0,10Z",
          transform: "translate(-283.66498 -191.72702)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d: "M829.78346,339.727h-61a5,5,0,0,1,0-10h61a5,5,0,0,1,0,10Z",
          transform: "translate(-283.66498 -191.72702)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d: "M659.78346,339.727h-61a5,5,0,0,1,0-10h61a5,5,0,0,1,0,10Z",
          transform: "translate(-283.66498 -191.72702)",
          fill: _vm.color
        }
      }),
      _c("circle", {
        attrs: { cx: "512.61849", cy: "304", r: "6", fill: _vm.color }
      }),
      _c("circle", {
        attrs: { cx: "515.61849", cy: "376", r: "6", fill: _vm.color }
      }),
      _c("circle", {
        attrs: { cx: "611.61849", cy: "304", r: "6", fill: _vm.color }
      }),
      _c("path", {
        attrs: {
          d:
            "M340.99621,652.34442l-.99775-22.43416a72.45547,72.45547,0,0,0-33.79563-8.555c16.23147,13.27042,14.203,38.85123,25.20758,56.6968A43.582,43.582,0,0,0,363.36962,698.192l13.58306,8.31649a73.02981,73.02981,0,0,0-15.39284-59.17883,70.54258,70.54258,0,0,0-12.9644-12.04606C345.34226,643.86154,340.99621,652.34442,340.99621,652.34442Z",
          transform: "translate(-283.66498 -191.72702)",
          fill: "#f2f2f2"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M283.665,707.083a1.18647,1.18647,0,0,0,1.19006,1.19h630.29a1.19,1.19,0,0,0,0-2.38H284.855A1.18651,1.18651,0,0,0,283.665,707.083Z",
          transform: "translate(-283.66498 -191.72702)",
          fill: "#ccc"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }