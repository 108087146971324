import MR_MONEY_TYPES from './types';

export default {
  [MR_MONEY_TYPES.GETTERS.LISTING](state) {
    return state.listing;
  },
  [MR_MONEY_TYPES.GETTERS.SELECTED_ROW](state) {
    return state.selectedRow;
  },


  
  
  
}