<template>
    <div>
        <div class="cards__item--header-title">
            <span v-if="pos.positionTyp !== 'NEU_KAUF' && pos.positionTyp !== 'NEU_SPARPLAN'" class="font-bold">
                {{isMain && showRest && isTest  ? 'Ursprüngliche ' : ''}}Anteile: {{formatNumber(pos.anteile, 6)}}
                <span class="text-divider mr-1">|</span>
            </span>
            <span class="font-bold">Anteilspreis: {{formatNumber(pos.anteilspreis, 2)}}
                <span v-if="!pos.waehrung || pos.waehrung === 'EUR'"><PhCurrencyEur :size="14"/></span>
                <span v-else>{{ pos.waehrung }}</span>
            </span>
            <span v-if="pos.waehrung && pos.waehrung !== 'EUR'" class="font-bold">
                <span class="text-divider mr-1">|</span>
                Anteilspreis in EUR: {{formatNumber(pos.anteilspreisEuro, 2)}}<PhCurrencyEur :size="14"/>
            </span>
            <span v-if="pos.positionTyp !== 'NEU_KAUF' && pos.positionTyp !== 'NEU_SPARPLAN'" class="font-bold">
                <span class="text-divider mr-1">|</span>
                Gesamter Wert: {{formatNumber(pos.gesamterWert, 2)}}<PhCurrencyEur :size="14"/>
            </span>
            <span v-if="pos.sparplaeneBetrag" class="font-bold">
                <span class="text-divider mr-1">|</span>
                Sparpläne Betrag: {{formatNumber(pos.sparplaeneBetrag, 2)}}<PhCurrencyEur :size="14"/>
            </span>
            <span v-if="pos.entnahmeplaeneBetrag" class="font-bold">
                <span class="text-divider mr-1">|</span>
                Entnahmepläne Betrag: {{formatNumber(pos.entnahmeplaeneBetrag, 2)}}<PhCurrencyEur :size="14"/>
            </span>
        </div>
        <div class="cards__item--header-title font-bold" v-if="(isMain || isTausch) && showRest && isTest">
            Noch verfügbare Anteile: {{ getVerfuegbareAnteile }}
        </div>
    </div>
</template>

<script>
import { PhCurrencyEur } from 'phosphor-vue';
import { formatNumber, isNumber, parse } from '@/helpers/number-formatter.js';
import mixin from "@/mixins/wertpapierorder/mixin.js";
import { mapGetters } from "vuex";
import CORE_TYPES from '@/store/core/types';

export default {
    mixins: [mixin],
    props: {
        position: {
            type: Object,
            default: () => {},
        },
        isMain: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        PhCurrencyEur,
    },
    computed: {
        ...mapGetters({
            isTest: CORE_TYPES.GETTERS.IS_TEST,
        }),
        pos() {
            return this.position || {};
        },
        getVerfuegbareAnteile() {
            if (this.pos?.anteile) {
                let anteile = this.pos.anteile;
                this.positions?.filter(pos => pos.isin === pos.isin && pos.positionTyp?.includes('ALT'))
                    .forEach(pos => {
                        if (pos.positionTyp === 'ALT_TAUSCH' || pos.positionTyp === 'ALT_VERKAUF') {
                            anteile -= this.getBetragInAnteile(pos);
                        }
                    })
                return formatNumber(anteile, 6);
            }
        },
        showRest() {
            return this.pos?.anteile && formatNumber(this.pos.anteile, 6) !== this.getVerfuegbareAnteile;
        },
        isTausch() {
            return this.pos?.positionTyp === 'ALT_TAUSCH';
        },
    },
    methods: {
        formatNumber(value, precision) {
            return formatNumber(value, precision);
        },
        getBetragInAnteile(pos) {
            if (pos?.positionTyp) {
                let betrag = pos.betrag && isNumber(pos.betrag) ? pos.betrag : 0;
                if (typeof(betrag) === 'string') {
                    betrag = parse(betrag);
                }
                switch (pos.betragModus) {
                    case 'VALUE_BETRAG_MODUS_ALLE_ANTEILE':
                    return pos.anteile || 0;
                    case 'VALUE_BETRAG_MODUS_EURO':
                    return pos.anteilspreisEuro && (betrag / pos.anteilspreisEuro) || 0;
                    case 'VALUE_BETRAG_MODUS_ANTEILE':
                    return betrag;
                    case 'VALUE_BETRAG_MODUS_CHF':
                    return pos.anteilspreis && (betrag / pos.anteilspreis) || 0;
                    case 'VALUE_BETRAG_MODUS_PROZENT':
                    return betrag / 100 * pos.anteile || 0;
                    default:
                    return betrag
                }
            }
            return 0;
        },
    }

}
</script>

<style>

</style>