<template>
  <div>
    <div class="box__container">
      <template v-if="document && document.steps && document.steps.length">
        <div style="text-align:right;font-size:40px"><ph-plus class="clickable" @click="edit()" /></div>
        <SortableList :items="pdfComponentList" @orderChanged="onOrderChanged($event)">
          <ul class="list-bordered" data-sortable-container>
            <li v-for="component of pdfComponentList" :key="component.componentId" class="list-bordered-item" data-sortable-item>
                <div style="display:flex">
                  <div class="uk-text-small edit" v-if="component.editable" @click="edit(component)">bearbeiten <ph-pencil-line title="Bearbeiten" :size="16" /></div>
                  <div class="uk-text-small edit" @click="deleteComponent(component)">löschen <ph-trash/></div>
                  <div data-sortable-item-handler style="margin-left: auto;"><ph-list :size="20" weight="bold" /></div>
                </div>
                <div style="display:flex" class="body_class">
                  <span style="width:100%" :class="{centered: component.htmlCardType === 'EINZELSPARTEN'}" v-html="getViewForComponent(component)"></span>
                  <!-- stuff below is for inline inputs -> maybe in the future -->
                  <!-- <span style="width:100%" :class="{centered: component.htmlCardType === 'EINZELSPARTEN'}">
                    <span  v-for="(subContent, index) of findSubComponents(component)" :key="index">
                      <a v-if="subContent.includes('INLINE_INPUT')" @click="testmethod(component, subContent)" v-html="subContent"></a>
                      <span v-else v-html="subContent"></span>
                    </span>
                  </span> -->
                </div>
            </li>
          </ul>
        </SortableList> 
      </template>
      <NoData noIcon title="" v-else/>
    </div>

    <DynamicComponentModal 
      ref="editComponentModal">
    </DynamicComponentModal>

    <InlineInputModal 
      ref="editInlineInputModal"
    />

    <BaseModal 
      ref="deleteModal"
      modalTitle="Komponente löschen"
      :showCancelButton="true"
      labelButtonConfirm="Bestätigen"
      @onConfirmButton="onConfirmDeleteModal()">
      <template #default>
          Soll die Komponente wirklich gelöscht werden?
      </template>
    </BaseModal>
  </div>
</template>

<script>
import DYNAMIC_DOCUMENT_TYPES from '@/store/dynamicDocument/types';
import { mapGetters } from 'vuex'
import SortableList from '@/components/core/SortableList.vue';
import { PhList, PhPlus, PhPencil, PhTrash, PhPencilLine } from "phosphor-vue";
import DynamicComponentModal from '@/components/dynamicDocument/DynamicComponentModal.vue'; 
import InlineInputModal from '@/components/dynamicDocument/components/InlineInputModal.vue'; 
import BaseModal from "@/components/core/BaseModal.vue";
import NoData from '@/components/core/NoData.vue'

export default {
  mixins: [],
  components: {
    SortableList,
    PhList,
    PhPlus,
    PhPencil,
    PhTrash,
    PhPencilLine,
    DynamicComponentModal,
    BaseModal,
    InlineInputModal,
    NoData,
  },
  props: {
    documentComponents: {
    },
    document: {
    },
  },
  data() {
    return {
      selectedComponent: null,
      subComps: {},
    }
  },
  computed: {
    componentsForCurrentStep() {
      const routeStep = this.$route.params.step;
      if (routeStep && this.documentComponents && this.documentComponents[routeStep]) {
        return this.documentComponents[routeStep]
      }
      const routeSubstep = this.$route.params.substep;
      if (routeSubstep && this.documentComponents && this.documentComponents[routeSubstep]) {
        return this.documentComponents[routeSubstep]
      }
      return []
    },

    pdfComponentList() {
      if (Array.isArray(this.componentsForCurrentStep) && this.componentsForCurrentStep.length) {
        var style = document.createElement('style');
        style.innerHTML = this.document.globalOptions?.replace(/body/g, '.body_class')
        document.head.appendChild(style);
        return this.componentsForCurrentStep//.filter(comp => comp.htmlType !== 'NEW_PAGE')
      }
      return []
    },
  },
  methods: {
    testmethod(component, subContent) {
      this.$refs.editInlineInputModal.open(subContent)
    },
    findSubComponents(component) {
      let htmlView = this.getViewForComponent(component)
      let array = htmlView.split(/(?=<input.*?>)(?=.*INLINE_INPUT)/g);    
      
      if (array && array.length > 1) {
        let newArray = array
        array.forEach((part, index) => {
          if (part.includes('INLINE_INPUT')) {
            let input = part.match(/<input.*?>/g)[0]
            let part2 = part.substring(part.indexOf(input) + input.length);
            if (part2) {
              newArray[index] = input
              newArray.splice(index + 1, 0, part2);
            }
          }
        })
        array = newArray;
      }

      if(array && array.length) {
        let inputComps = []
        array.forEach(part => {
          if (part.includes('INLINE_INPUT')) {
            inputComps.push(part)
          }
        })
        this.subComps[component.componentId] = inputComps
      }

      // let array = htmlView.split(/<input.*?>(?=.*INLINE_INPUT)/g);      
      // console.log('array', array)
      return array
    },
    // compiledData(htmlView) {
    //   let array = htmlView.match(/(?=<input.*?>)(?=.*INLINE_INPUT)/g);    
    //   // let array = htmlView.match(/<input.*?>/g);      
    //   if (array && array.length) {
    //     array.forEach((input, index) => {
    //       if (input.includes('INLINE_INPUT')) {
    //         // htmlView = htmlView.replace(RegExp("^(?:.*?" + input + "){" + 1 + "}"), function(x){return x.replace(RegExp(input + "$"), "HHHH")})
    //         htmlView = htmlView.replace(RegExp(`^(?:.*?${input}){${index + 1}}`), function(x){return x.replace(RegExp(input + "$"), "<a @click='testmethod()'>" + input + "</a>")})
    //       }
    //     }) 
    //   }
    //   return {
    //   	template: htmlView,//`<a @click="testmethod('test?')">A testlink</a>`,
    //     methods:{
    //       testmethod(){
    //         console.log("click", htmlView);
    //       }            
    //     }
    //   }
    // },
    getViewForComponent(component) {
      switch(component.htmlCardType) {
        case 'EINZELSPARTEN':
          return '<div class="spartenPlaceholder">Platzhalter für Einzelsparten</div>'
        default:
          return component.htmlView
      }
    },
    getCalculatedContent(component) {
      let temp = []

      var findAllComponents = function(component, temp) {
        temp.push(component.content)
          if (component.htmlComponents && component.htmlComponents.length) {
            for (var comp of component.htmlComponents) {
              findAllComponents(comp, temp)
            }
          }
        };

        findAllComponents(component, temp)
        return temp.join('  ')
    },
    onOrderChanged(event) {
      const routeStep = this.$route.params.step;
      this.documentComponents[routeStep] = event
      this.$store.commit(DYNAMIC_DOCUMENT_TYPES.MUTATIONS.SET_DOCUMENT_HAS_CHANGED, true)
    },
    edit(component) {
      this.$refs.editComponentModal.open(component);
    },
    deleteComponent(component) {
      this.selectedComponent = component
      this.$refs.deleteModal.open()
    },
    onConfirmDeleteModal() {
      if (Array.isArray(this.componentsForCurrentStep) && this.componentsForCurrentStep.length && this.selectedComponent) {
        const routeStep = this.$route.params.step;

        let index = this.componentsForCurrentStep.findIndex(comp => comp.componentId === this.selectedComponent.componentId)
        this.documentComponents[routeStep].splice(index, 1)
        this.$store.commit(DYNAMIC_DOCUMENT_TYPES.MUTATIONS.SET_DOCUMENT_HAS_CHANGED, true);
      }
    },
  },

}
</script>

<style scoped>
  .edit {
    color: var(--color-danger);
    /* font-size:1.5em; */
    cursor: pointer;
    margin-right: 8px;
  }
  .centered {
    text-align: center;
  }
</style>
<style>
  .body_class * {
    font-family: 'open sans medium', 'Open Sans', sans-serif;
  }
  .body_class p {
    min-height: 1em;
    word-wrap: break-word;
    white-space: pre-wrap;
  }
  .body_class table tr td,
  .body_class table tr th {
    padding: 1px 0;
  }
  .spartenPlaceholder {
    transform: rotate(-40deg);
    display: inline-block;
    font-size: 25px;
    line-height: 9;
    color: rgb(172, 172, 172);
  }
</style>