<template>
  <div class="horizontal-bullet__container d-flex">
    <div class="horizontal-bullet__progress" :class=[activeColorClass] :style="{ width: percentageDone + '%' }"></div>
    <div class="horizontal-bullet__available" :style="{ width: percentageAvailable + '%' }" v-if="percentageDone < 100"></div>
    <div class="horizontal-bullet__average" :style="{ left: percentageAverage + '%' }" ></div>
  </div>
</template>

<script>
export default {
  props: {
    goal: {
      default: 0
    },
    current: {
      default: 0
    },
    average: {
      default: 0
    }
  },
  computed: {
    percentageDone() {
      return this.biggestValue !== 0 && (this.currentAbs / this.biggestValue) * 100 || 0;
    },
    percentageAvailable() {
      const percentage = ((this.goalAbs - this.currentAbs) / this.biggestValue) * 100;
      if (!percentage || percentage < 0) {
        return 0;
      } else if (percentage > 100) {
        return 100;
      } 
      return 100 - this.percentageDone;
    },

    percentageAverage() {
      return this.biggestValue !== 0 && (this.averageAbs / this.biggestValue) * 100 || 0;
    },

    biggestValue() {
      return Math.max(this.goalAbs, this.currentAbs, this.averageAbs);
    },

    currentAbs() {
      return Math.abs(this.current);
    },

    averageAbs() {
      return Math.abs(this.average);
    },

    goalAbs() {
      return Math.abs(this.goal);
    },

    activeColorClass() {
      return this.current > 0 ? 'hb__progress-bar--positive' : 'hb__progress-bar--negative'
    }
  }
}
</script>

<style scoped>
  .horizontal-bullet__container {
    width: 100%;
    position: relative;
  }

  .horizontal-bullet__progress {
    height: 18px;
  }

  .horizontal-bullet__available {
    background-color: #BDBDBD;
    height: 18px;
  }

  .horizontal-bullet__average {
    border: solid 2px; 
    height: 18px;
    box-sizing: border-box;
    position: absolute;
    border-color: var(--color-primary);
  }

  .hb__progress-bar--positive {
    background-color: var(--color-success);
  }

  .hb__progress-bar--negative {
    background-color: var(--color-danger);
  }
</style>