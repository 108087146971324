import axios from 'axios';

import { ResolverId } from '../types';
import { createLinkResolver, wrapUrl, wrapError } from '../utils';

export default createLinkResolver(ResolverId.ExterneZugang, async function({ apiAddress }, { path, params, }) {
  const response = await axios.post(`${apiAddress}/externe_zugang/${path}`, params, { defaultSpinner: true });
  if (response.data?.url) {
    return wrapUrl(response.data.url);
  } else if (response.data?.errorMessage)  {
    return wrapError(response.data?.errorMessage);
  }
});
