<template>
  <div class="box__container-wrap">
    <div class="box__container-rows">
      <template v-if="rows.length">
        <OverviewTable v-if="!$isSmallScreen" :records="rows" @subject="clickBetreff">
          <template #title="row">
            <div class="ellipsis bold">
              <a style="color: inherit" v-if="row.isTitleLink" @click="openCustomerNewTab(row)">{{ row.title }}</a>
              <span v-else>{{ row.title }}</span>

              <template v-if="row.status !== 'success'">
                <span class="mx-2"> | </span>
                <ph-warning :size="16" :class="`color-${row.status}`" />
              </template>
            </div>
          </template>

        </OverviewTable>
        <Table v-else :headers="headers" :rows="rows" rowId="commId"
          :mobileConfig="{ title: 'title', headers: ['subject', 'date'] }" @clickRow="clickBetreff">
          <template #title="row">
            <div class="ellipsis">
              <ph-paperclip v-if="row.hasAttachment" class="mr-1" style="vertical-align: bottom" :size="16" />
              <span>{{ row.title }}</span>

              <template v-if="row.status !== 'success'">
                <span class="mx-2"> | </span>
                <ph-warning :size="16" :class="`color-${row.status}`" style="vertical-align: bottom" />
              </template>
            </div>
          </template>
        </Table>
      </template>
      <GhostLoading v-else-if="loading" type="table" :config="{ table: { rows: 5 } }" />
      <NoData :noIcon="$isSmallScreen" v-else />
    </div>

    <div class="bottom-row text-right mt-3">
      <router-link :to="`/communication/postfach/${routeStep}`">
        weitere...
      </router-link>
    </div>
  </div>
</template>

<script>
import postfachMixin from '@/views/communication/postfach/widgets/postfach-mixin.js';
import { PhWarning } from 'phosphor-vue'
import Table from "@/components/table2/Table.vue";
import { SlotColumn, TextColumn, DateColumn } from "@/components/table2/table_util.js";
import { PhPaperclip } from 'phosphor-vue'

export default {
  mixins: [postfachMixin],
  components: {
    PhWarning,
    PhPaperclip,
    Table,
  },
  data() {
    return {
      routeStep: 'emails',
    }
  },
  computed: {
    headers() {
      return {
        lockedLeft: [
          SlotColumn("title", "Name"),
          DateColumn("date", "Datum"),
          TextColumn("subject", "Betreff"),
        ],
        center: [],
        lockedRight: []
      }
    },
    rows() {
      return this.records.map(record => ({
        ...record,
        title: record.name || "[Unbekannt]",
        subject: record.subject || "[kein Betreff]",
        isTitleLink: this.canOpenCustomer(record),
        isSubjectLink: !!record.commId,
        actions: undefined,
      }))
    },
  },
  methods: {
    clickBetreff(row) {
      if (row.commId) {
        this.$router.push(`/communication/postfach/${this.routeStep}/email/${row.commId}`);
      }
    },
  },
}
</script>