<template>
  <div>
    <PageHeaderTitleNavigation 
      title="Auswahl Vertragsart"
      :subtitle="categorySelected && categorySelected.name"
      :defaultMenu="customOptionMenu" 
      :isLoading="loading"
    />

    <div class="box__container" v-if="categorySelected && categorySelected.groups.length" :tid="_generateTidFromString('box__container-suchen')">
      <div class="box__title">Suchen</div>
      <InputField 
        isComponentHalfSize
        placeholder="Suchen nach Bezeichnung"
        v-model="descriptionSearch"/>
    </div>

    <div class="cards__container layout__negative-margin--8">
      <div class="box__container cards__item" v-for="(group, index) in groups" :key="index">
        <Table
            tableId="fa5bef18-bb0a-423e-8fdc-fb5ca1cd1baf"
            :headers="headers(group)"
            :rows="filteredBezeichnungen(group)"
            hidePagination
            @click-label="handleLabelClick"
        />
      </div>
    </div>

    <AddVersicherungModal v-if="showAddVersicherungModal" @close="showAddVersicherungModal = false"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VERSICHERUNG_TYPES from '@/store/versicherungen/types';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn} from "@/components/table2/table_util.js";
import UndrawRetire from '@/components/icons/undraw/UndrawRetire.vue';
import UndrawHealth from '@/components/icons/undraw/UndrawHealth.vue';
import UndrawAuto from '@/components/icons/undraw/UndrawAuto.vue';
import UndrawSecureServer from '@/components/icons/undraw/UndrawSecureServer.vue'
import InputField from '@/components/core/forms/InputField.vue'
import AddVersicherungModal from '@/views/versicherungen/AddVersicherungModal.vue'
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import CORE_TYPES from '@/store/core/types';

const INITIAL_CUSTOM_OPTION_MENU = [{},{},{},{}];

export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    OptionMenu,
    AddVersicherungModal,
    Table,
    InputField,
    PageHeaderTitleNavigation,
  },
  mounted() {
    this.retrieveAllCategories();
  },
  data() {
    return {
      categorySelected: null,
      descriptionSearch: '',
      showAddVersicherungModal: false,
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      allCategories: VERSICHERUNG_TYPES.GETTERS.ALL_CATEGORIES_LIST,
      isFA: CORE_TYPES.GETTERS.IS_FA,
    }),
    spartenSequencialOrder() {
      return {
        SACHEN: 1,
        VORSORGE: 2,
        GESUNDHEIT: 3,
        BETRIEBLICH: 4,
        SONSTIG: 5,

      }

    },
    customOptionMenu() {
      if(!this.allCategories?.length) {
        return this.loading ? INITIAL_CUSTOM_OPTION_MENU : [];
      }

      const mappedCategories = this.allCategories.map(category => ({
        position: this.spartenSequencialOrder[category?.category] || 0,
        path: category?.category,
        component: this.componentByCategory(category.category),
        label: category.name,
        componentProps: {
          src: null,
          fit: 'contain',
          placeholder: {
            width: '78px',
            height: '48px',
          }
        },
        textBold: (this.categorySelected?.category === category?.category),
        action: () => this.selectCategory(category),
      }))

      if (this.isFA) {
        return mappedCategories.sort((a, b) => (a.position- b.position));
      }

      return mappedCategories;
    },
    groups() {
      if(this.categorySelected?.groups?.length){
        return this.categorySelected.groups.filter(group => this.filteredBezeichnungen(group)?.length);
      }
      return [];
    }
  },
  watch: {
    customOptionMenu() {
      this.selectFirstCategory();
    },
  },
  methods:{
    async retrieveAllCategories() {
      try {
        this.loading = true;
        await this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.RETRIEVE_ALL_CATEGORIES);
      } finally {
        this.loading = false;
      }
    },
    filteredBezeichnungen(group) {
      const filteredBezeichnungen = this.descriptionSearch 
          ? group?.bezeichnungen.filter(value => value?.label?.toLowerCase?.().includes(this.descriptionSearch.toLowerCase())) 
          : group?.bezeichnungen

      return filteredBezeichnungen
    },
    headers(group) {
        return {
            center: [
                TextColumn("label", group.name || "Allgemein").makeLink(),
            ],
        }
    },
    componentByCategory(category) {
      switch (category) {
        case "GESUNDHEIT":
          return UndrawHealth;
        case "SACHEN":
          return UndrawAuto;
        case "BETRIEBLICH":
          return UndrawRetire;
        case "VORSORGE":
        case "SONSTIG":
        default:
          return UndrawSecureServer;
      }
    },
    selectCategory(category){
      this.categorySelected = category
    },
    selectFirstCategory() {
      if(this.allCategories?.length && !this.categorySelected) {
        const firstCategorySorted = this.customOptionMenu[0];

        this.selectCategory(this.allCategories.find(cat => cat.category === firstCategorySorted.path));
      }
    },
    handleLabelClick(item) {
      const payload = {category: this.categorySelected.id, group: item.id, groupLabel: item.label};
      if (this.$route.query.gesellid) {
        payload.versicherung = {gesellschaftId: this.$route.query.gesellid}
      }
      if (this.$route.query.vesichnr) {
        if (payload.versicherung) {
          payload.versicherung.nummer = this.$route.query.vesichnr;
        } else {
          payload.versicherung = {nummer: this.$route.query.vesichnr}
        }
      }
      this.$store.commit(VERSICHERUNG_TYPES.MUTATIONS.INSURANCE_AUSWAHL_VERTRAGSART_SELECTED, payload)
      this.showAddVersicherungModal = true
    }
  }
}
</script>

<style scoped>
.cards__container {
  display: flex;
  flex-wrap: wrap;
}

.cards__item { 
  flex: 1 1 100%;
  max-width: 100%;
}

@media (min-width: 576px) {
  .cards__item { 
    flex: 1 1 calc(50% - 16px);
  }
}

@media (min-width: 992px) {  
  .cards__item { 
    flex: 1 1 calc(33.3333% - 16px);
  }
}

</style>