export const MUTATION_PREFIX = 'ANSPRECHPARTNER: ';
export const ACTIONS_PREFIX = 'ANSPRECHPARTNER_ACTIONS_';
export const GETTERS_PREFIX = 'ANSPRECHPARTNER_GETTERS_';

export default {
  MUTATIONS: {
    SET_ANSPRECHPARTNER: MUTATION_PREFIX + 'SET_ANSPRECHPARTNER',
    GET_USER_PICTURE_SUCCESS: MUTATION_PREFIX + 'GET_USER_PICTURE_SUCCESS',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    UPDATE_ANSPRECHPARTNER_DATA: MUTATION_PREFIX + 'UPDATE_ANSPRECHPARTNER_DATA',
    SET_TEMP_USER_PICTURE: MUTATION_PREFIX + 'SET_TEMP_USER_PICTURE',
    SET_ANSPRECHPARTNER_SELECTIONS: MUTATION_PREFIX + 'SET_ANSPRECHPARTNER_SELECTIONS',
  },
  ACTIONS: {
    SAVE_ANSPRECHPARTNER: ACTIONS_PREFIX + 'SAVE_ANSPRECHPARTNER',
    SAVE_USER_PICTURE: ACTIONS_PREFIX + 'SAVE_USER_PICTURE',
    DELETE_USER_PICTURE: ACTIONS_PREFIX + 'DELETE_USER_PICTURE',
    GET_USER_PICTURE: ACTIONS_PREFIX + 'GET_USER_PICTURE',
  },
  GETTERS: {
    GET_ANSPRECHPARTNER: GETTERS_PREFIX + 'GET_ANSPRECHPARTNER',
    GET_USER_PICTURE_DATA: GETTERS_PREFIX + 'GET_USER_PICTURE_DATA',
    GET_TEMP_USER_PICTURE_DATA: GETTERS_PREFIX + 'GET_TEMP_USER_PICTURE_DATA',
    GET_USER_PICTURE_URL: GETTERS_PREFIX + 'GET_USER_PICTURE_URL',
    GET_ANSPRECHPARTNER_SELECTIONS: GETTERS_PREFIX + 'GET_ANSPRECHPARTNER_SELECTIONS',
  }
}
