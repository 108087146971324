<template>
  <div>
    <PageHeaderTitleNavigation
      title="Gesellschaftsinfos"
      :subtitle="gesellschaftName"
      :defaultMenu="customOptionMenu"
      :actions="actions">
    </PageHeaderTitleNavigation>

    <div class="box__container" v-if="gesellschaftsInfos">
      <div class="row">
        <div class="col-12 col-sm-6">
          <InputField
            label="PLZ"
            v-model="gesellschaftsInfos.plz"
            disabled
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6">
          <InputField
            label="Ort"
            v-model="gesellschaftsInfos.org"
            disabled
          />
        </div>     
      </div>
      <div class="row">        
        <div class="col-12 col-sm-6">
          <InputField
            label="Strasse"
            v-model="gesellschaftsInfos.strasse"
            disabled
          />
        </div>  
      </div>
      <div class="row">            
        <div class="col-12 col-sm-6">
          <InputField
            label="Strasse"
            v-model="gesellschaftsInfos.strasse"
            disabled
          />
        </div>  
      </div>
      <div class="row">             
        <div class="col-12 col-sm-6">
          <InputField
            label="Land"
            v-model="gesellschaftsInfos.land"
            disabled
          />
        </div>   
      </div>
      <div class="row">         
        <div class="col-12 col-sm-6">
          <InputField
            label="Postfach"
            v-model="gesellschaftsInfos.postfach"
            disabled
          />
        </div>      
      </div>
      <div class="row">           
        <div class="col-12 col-sm-6">
          <InputField
            label="PLZ für Postfach"
            v-model="gesellschaftsInfos.plzPostfach"
            disabled
          />
        </div>    
      </div>
      <div class="row">        
        <div class="col-12 col-sm-6">
          <InputField
            label="Beschreibung"
            v-model="gesellschaftsInfos.beschreibung"
            disabled
          />
        </div>  
      </div>
      <div class="row">          
        <div class="col-12 col-sm-6">
          <InputField
            label="Vermittlernummer"
            v-model="gesellschaftsInfos.vermittlerNummer"
            disabled
          />
        </div>  
      </div>
      <div class="row">              
        <div class="col-12 col-sm-6">
          <InputField
            label="Vermittlernr. AK"
            v-model="gesellschaftsInfos.vermittlerNummerAK"
            disabled
          />
        </div>  
      </div>
      <div class="row">              
        <div class="col-12 col-sm-6">
          <InputField
            label="Hauptnummer"
            v-model="gesellschaftsInfos.hauptNummer"
            disabled
          />
        </div>                                  
      </div>




    </div>

    <div class="box__container">
      <Table 
        v-if="rows && rows.length"
        tableId="3ff275df-3245-4ea1-872f-c91731ab6962"
        :title=TABLE_TITLE
        :rows="rows"
        :headers="headers"
        :rowsPerPage="20"
      >
        <template v-slot:telefon="row">
          <a   v-if="isTelefon"  :tid="_generateTidFromString(row.telefon)" @click="tapiCall(row.telefon)" >{{ row.telefon }}</a>
          <label   v-else  :tid="_generateTidFromString(row.telefon)">{{ row.telefon }}</label>        
        </template>
        <template v-slot:mobil="row">
          <a   v-if="isTelefon"  :tid="_generateTidFromString(row.mobil)" @click="tapiCall(row.mobil)" >{{ row.mobil }}</a>
          <label   v-else  :tid="_generateTidFromString(row.mobil)">{{ row.mobil }}</label>        
        </template>
      </Table>
      <GhostLoading v-else-if="loading" type="table" :title="TABLE_TITLE" />
      <NoData v-else :title="TABLE_TITLE" content="Keine Ansprechpartner hinterlegt" />



    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import GESELLSCHAFT_TYPES from '@/store/gesellschaft/types';
import CORE_TYPES from '@/store/core/types'
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';
import Table from "@/components/table2/Table.vue";
import { TextColumn, ActionColumn, SlotColumn, PillColumn, ConfirmedAction } from "@/components/table2/table_util.js";
import NoData from '@/components/core/NoData.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import TAPI from '@/store/tapi/types';
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import InputField from "@/components/core/forms/InputField.vue";
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";

const TABLE_TITLE = 'Ansprechpartner ';

export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    OptionMenu,
    PageHeaderTitleNavigation,
    Table,
    GhostLoading,
    NoData,
    InputField,
  },
  data() {
    return {
      TABLE_TITLE,
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
      isFK: CORE_TYPES.GETTERS.IS_FK,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      gesellschaftsInfos: GESELLSCHAFT_TYPES.GETTERS.GESELLSCHAFTS_INFOS,
      isTelefon: TAPI.GETTERS.CAN_OUT_CALL,
      gesellschaftList: VERSICHERUNG_TYPES.GETTERS.GESELLSCHAFT_LIST,
      versicherungenDetails: VERSICHERUNG_TYPES.GETTERS.INSURANCE_DETAILS,
    }),
    customOptionMenu() {
      return [];
    },
    actions() {
      const actions = []

      if (this.page === 'ansprechpartner') {
        actions.push(PageHeaderSimpleAction('NEW_ANSPRECHPARTNER', 'Neuen Ansprechpartner anlegen').withVisible(() => this.$refs.gesellAp?.canEdit))
        actions.push(PageHeaderSimpleAction('SHOW_PDF', 'Ansprechpartner dieser Gesellschaft'))
      }

      if (this.page === 'eigeneDokumente') {
        actions.push(PageHeaderSimpleAction('DOCUMENT_UPLOAD', 'Dokumente hochladen'))
      }

      return actions;
    },
    rows() {
      return this.gesellschaftsInfos?.rows;
    },
    headers() {
      let headers = {
          lockedLeft: [
            TextColumn("name", "Name").makeConditionalLink('editable'),
          ],
          center: [
            TextColumn("typ", "Typ"),
            TextColumn("email", "E-Mail"),
            SlotColumn("telefon", "Telefon", undefined, 1).makeSortable(),
            SlotColumn("mobil", "Mobil", undefined, 1).makeSortable(),
            TextColumn("fax", "Fax"),
            TextColumn("beschreibung", "Beschreibung"),
          ],
          lockedRight: [],
      }

      return headers
    },
    gesellschaftName() {
      const gesellschaft = this.gesellschaftList?.find(vd => vd.value == this.versicherungenDetails?.insurance?.gesellschaft.id)
      return gesellschaft?.label;
    }
  },
  methods: {

    
  },
  async mounted() {
    if (this.$route.params.gesellschaftId) {
      await this.$store.dispatch(GESELLSCHAFT_TYPES.ACTIONS.GET_GESELLSCHAFTS_INFOS, {gesellschaftId: this.$route.params.gesellschaftId});
    }    
  }

  
};
</script>
