<template>
  <div>
    <BaseModal 
      ref="editModal"
      modalTitle="Freigabe ändern"
      :autoClose="false"
      :showConfirmButton="false"
      @close="close()"
    >
      
      <div class="mb-2">
        <span v-if="vorlage.type === 'EMAIL'">
          E-Mail Vorlage anschauen: 
        </span>
        <span v-else>
          Brief Vorlage anschauen: 
        </span>
        <DownloadLink
          :title="vorlage.description"
          :downloadServicePath="vorlage.isWord ? '/get_word_vorlage' : '/vorlage_preview'"
          :filename="vorlage.isWord ? 'Dokument.doc' : 'Dokument.pdf'"
          :queryParams="{
            id: vorlage.id,
            fileId: vorlage.id,
          }"
        />
      </div>

      <ComboBox
        label="Freigabe"
        v-model="grant"
        :values="grantValues"
        :disabled="!vorlage.editable">
      </ComboBox>

      <template #footer>
        <BaseButton @click="submit()">
          Speichern <AnimatedSpinner v-if="showLoading"/>
        </BaseButton>
      </template>
      
    </BaseModal>
  </div>
</template>

<script>
import TEMPLATES_EDIT_TYPES from '@/store/templatesEdit/types';
import CORE_TYPES from '@/store/core/types';
import BaseModal from '@/components/core/BaseModal.vue'
import ComboBox from '@/components/core/forms/ComboBox.vue'
import DownloadLink from '@/components/core/download/DownloadLink.vue'
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import { mapGetters } from 'vuex';

export default {
  props: {
    vorlage: {
      default: null,
    },
  },

  components: {
    BaseModal,
    ComboBox,
    DownloadLink,
    AnimatedSpinner,
    BaseButton
},

  watch: {
    vorlage: {
      immediate: true,
      handler(newVorlage) {
        this.grant = newVorlage.grant
      },
    }
  },

  data: function () {
    return {
      grant: null,
      showLoading: false,
    }
  },

  computed: {
    ...mapGetters({
      isInternOriginal: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
    }),
    grantValues() {
      const grantValues = [
        {value: 'NO', label: 'Keine Freigabe'},
      ]

      if (!this.isIntern) {
        grantValues.push({value: 'STRUCTURE', label: 'Freigabe für Struktur'});
      }

      if (this.isInternOriginal) {
        grantValues.push({value: 'ALL', label: 'Freigabe für Alle Vermittler'});
      }

      return grantValues;
    },
  },

  methods: {
    async submit() {
      if (this.vorlage.id) {
        this.showLoading = true;
        await this.$store.dispatch(TEMPLATES_EDIT_TYPES.ACTIONS.SAVE_TEMPLATE_GRANT, {
          id: this.vorlage.id,
          grant: this.grant,
          isWord: this.vorlage.isWord,
        })
        this.close()
      }
    },
    close() {
      this.$emit("close")
    },
  },
  mounted() {
    this.$refs.editModal.open()
  }
}
</script>

<style scoped>

</style>