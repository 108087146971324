import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export function getInitialState() {
  return {
    titlesList: [],
    tableViewer: {empty: true},
    tableViewerHist: [],
    deepTitle: [''],
  }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}
