<template>
	<div class="box__container">
		<div class="font-strong mb-2">
			Die Anzeigepflicht gemäß § 21 FinVermV wurde beachtet. Der den zu prüfenden Zeitraum betreffende Schriftverkehr 
			mit der für die Erlaubniserteilung zuständigen Behörde wurde Ihnen vollständig zur Verfügung gestellt. Im Prüfungszeitraum 
			erfolgten
		</div>

		<InputRadioBoxGroup 
			v-model="form.anzeigepflichtAktiv"
			@input="fieldChanged()"
			:values="vertragWPValues.anzeigepflichtAktiv">
		</InputRadioBoxGroup>

		<InputTextArea
			@change="fieldChanged()"
			v-model="form.anzeigepflicht"/>

		<div class="font-strong mb-2">
			Kenntnisse über Kundenbeschwerden oder Rechtsstreitigkeiten aus den unter Ziffer C. 1 genannten Tätigkeiten im Kalenderjahr
		</div>

		<InputRadioBoxGroup 
			v-model="form.angabeBeschwerden"
			@input="fieldChanged()"
			:values="vertragWPValues.angabeBeschwerden">
		</InputRadioBoxGroup>

		<InputField 
			isComponentHalfSize
			@change="fieldChanged()"
			v-model="form.beschwerdenAnlage"/>

		<div class="font-strong">
			Zusätze und Bemerkungen
		</div>

		<InputTextArea
			@change="fieldChanged()"
			v-model="form.bemerkung"/>

		<div class="font-strong">
			Bitte beachten und bestätigen Sie:
		</div>

		<InputToggleSwitch
			inLineLabel
			@input="fieldChanged()"
			v-model="form.bestVtg"
			label='der Vertrag muss, bei händischer Unterschrift, eingescannt und hochgeladen bzw. eingereicht werden. Siehe auch die Hinweise unter "Vertragsdaten".'/>

		<InputToggleSwitch
			inLineLabel
			@input="fieldChanged()"
			v-model="form.bestDok"
			:label="'die, für die Prüfung ' + form.currentYear + ' notwendigen Dokumente (v.a. Beratungsprotokolle, ' +
				'Vermittlungsdokumentation sowie Anlegerprofile und Anträge) sind im MSC hochgeladen und final bearbeitet.'"/>
			
	</div>
</template>

<script>
import InputField from '@/components/core/forms/InputField.vue';
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue'
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue'
import VERTRAG_WP_TYPES from '@/store/vertragWP/types.js'
import { mapGetters } from 'vuex';
import validator from "@/mixins/validator";

export default {
	mixins: [validator],

	props: {
		formData: {
			type: Object,
			default: {}
		}
	},

	computed: {
		...mapGetters({
			hinweise: VERTRAG_WP_TYPES.GETTERS.VERTRAG_WP_HINWEIS,
			vertragWPValues: VERTRAG_WP_TYPES.GETTERS.VERTRAG_WP_VALUES,
			errors: VERTRAG_WP_TYPES.GETTERS.VERTRAG_WP_ERRORS,
		}),
		errorsCurrentStep() {
			return this.errors?.filter?.(err => err.stepKey === 'contractData4') || []
		}
	},

	watch: {
		formData: {
			handler(newValue) {
				this.loadForm(newValue)
			},
			immediate: true,
		},
		errorsCurrentStep(errors) {
			for (const error of errors) {
				this.$pushErrors('form.' + error.id, error.message)
			}
		}
	},

	data() {
		return {
			form: {}
		}
	},

	validators: {
		form: []
	},

	methods: {
		loadForm(newValue) {
			this.$set(this, 'form', Object.assign({}, newValue))
		},
		fieldChanged() {
			this.$emit('fieldChanged', this.form)
		}
	},

	components: {
		InputField,
		InputTextArea,
		InputToggleSwitch,
		InputRadioBoxGroup,
	}
}
</script>

<style scoped>

</style>