<template>
    <div>
        <ChartYieldRisk :chartParam="riskChartParams"></ChartYieldRisk>
    </div>
  </template>
  
  <script>
  import FONDSINFO_TYPES from "@/store/fondsinfo/types";
  import BoxContainer from "@/components/core/BoxContainer.vue";
  import { mapGetters } from "vuex";
  import ChartPerformance from "@/components/charts/ChartPerformance.vue";
  import ChartFunds from '@/components/charts/ChartFunds.vue';
  import {utils} from '@/components/charts/chartFondsUtil.js';
  import ChartYieldRisk from "@/components/charts/ChartYieldRisk.vue";
  import BarChart from "@/components/charts/echarts/BarChart.vue";
  import ComboBox from '@/components/core/forms/ComboBox.vue';
  
  export default {
    components: {
      BoxContainer,
      ChartPerformance, ChartFunds,
      ChartYieldRisk,
      BarChart,
      ComboBox,
    },
    props: {
      selectedTabLabel: {
        type: String,
      },
    },
    data(){
      return {
        riskChartParams: {
          isin: this.$route.params?.isin,
          year: 5
        },
        chartPerf: {},
        chartRendite: {},
        perfData: [],
        perfZoom: [],
        isin: this.$route.params?.isin,
        years: 5,
        zoom: null,
      }
    },
    computed: {
      ...mapGetters({performanceChart: FONDSINFO_TYPES.GETTERS.FONDSINFO_PERFORMANCE_CHART}),
    },
    mounted() {
      this.loadPerformance();
    },
    watch: {
      performanceChart: 'receiveData',
    },
    methods: {
      loadPerformance() {
        this.$store.dispatch(
          FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_PERFORMANCE_CHART,
          {
            isin: this.isin,
            years: this.years
          }
        );
        this.chartPerf = {};
        this.chartRendite = {};
      },
      receiveData() {
        if(this.performanceChart){
          this.perfData = this.performanceChart.records;
          this.chartPerf = utils.convSerieData([{'name': 'Wertentwicklung', 'data': this.performanceChart.records}])[0];
          this.chartRendite = utils.jaehrlichePerformance(this.chartPerf);
          const series = utils.addGD200Series(this.chartPerf);
          utils.jaehrlichePerformance(series[1], this.chartRendite);
          if (this.performanceChart.rows.length) {
            this.perfZoom = this.performanceChart.rows[0].zoom;
          }
          //
          if (this.zoom == null && this.chartPerf?.data?.length){
            let test = new Date();
            test = new Date(test.getFullYear() - 5, test.getMonth(), test.getDate() + 3);
            if (test.getTime() > this.chartPerf.data[0][0]) {
              this.zoom = [{label: '5Jahre', value: 5}, {label: 'Seit Beginn', value: 100}]
            }
          }
        }
      },
      onChangeYear(years) {
        this.years = years;
        this.loadPerformance();
      },
    }
  };
  </script>
  
  <style>
  </style>