var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: _vm.getTitle(_vm.websiteId),
          defaultMenu:
            _vm.websiteId === "Franke & Bornberg fb-tools"
              ? _vm.customOptionMenu
              : []
        }
      }),
      _vm.websiteId === "Nafi-KFZ"
        ? _c("div", { staticClass: "box__container" }, [
            _c("b", [_vm._v("Wichtiger Hinweis: ")]),
            _vm._m(0),
            _vm._m(1),
            _c("p")
          ])
        : _vm._e(),
      _vm.websiteId === "Tarifrechner Nürnberger BT4All"
        ? _c("div", { staticClass: "box__container" }, [
            _vm.hasMaklerHauptnummer && !_vm.isBt4AllLoading
              ? _c(
                  "div",
                  { staticClass: "box__container" },
                  [
                    _c("div", { staticClass: "box__title" }, [
                      _vm._v("Auswahl der Vermittlernummer")
                    ]),
                    _c("ComboBox", {
                      attrs: {
                        isComponentHalfSize: "",
                        disabled: !_vm.fremdnummerComboboxHasData,
                        value: this.fremdnummerComboboxSelectedData,
                        values: this.fremdnummerComboboxData
                      },
                      on: {
                        change: function($event) {
                          return _vm.fremdnummerChange($event)
                        }
                      }
                    }),
                    !_vm.isHauptnummerSelected
                      ? _c("span", { staticClass: "color-danger" }, [
                          _vm._v("Vermittlernummer ist erforderlich!")
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "box__title" }, [
                      _vm._v("Auswahl der Versicherungsart")
                    ]),
                    _c("ComboBox", {
                      attrs: {
                        isComponentHalfSize: "",
                        disabled: _vm.isSomeButtonLoading,
                        placeholder: "Bitte auswählen",
                        value: this.bt4allComboboxSelection,
                        values: this.bt4AllComboboxSelections
                      },
                      on: {
                        change: function($event) {
                          return _vm.bt4AllChange($event)
                        }
                      }
                    }),
                    !_vm.isBt4AllSelected
                      ? _c("span", { staticClass: "color-danger" }, [
                          _vm._v("Versicherungsart ist erforderlich!")
                        ])
                      : _vm._e(),
                    _c("br"),
                    _c("br"),
                    _c(
                      "div",
                      [
                        _c(
                          "BaseButton",
                          {
                            attrs: { disabled: !_vm.isBt4AllButtonEnabled },
                            on: {
                              click: function($event) {
                                return _vm.loginToExternalWebsite()
                              }
                            }
                          },
                          [
                            _vm._v(" Login mit Kundendaten "),
                            _vm.loadingState.external
                              ? _c("AnimatedSpinner")
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.isBt4AllLoading
              ? _c("p", [_vm._v(" Lade... Bitte warten... ")])
              : _vm._e(),
            !_vm.hasMaklerHauptnummer && !_vm.isBt4AllLoading
              ? _c("p", [
                  _vm._v(
                    " Es ist keine Vermittlernummer für die Nürnberger Allgemeine vorhanden. "
                  )
                ])
              : _vm._e()
          ])
        : _vm._e(),
      _vm.websiteId === "Franke & Bornberg fb-tools"
        ? [
            _vm.modus === "aktuell"
              ? _c(
                  "div",
                  { staticClass: "box__container" },
                  [
                    _c("div", { staticClass: "box__title" }, [
                      _vm._v("Auswahl der Versicherungsart")
                    ]),
                    _c("ComboBox", {
                      attrs: {
                        isComponentHalfSize: "",
                        firstEmpty: "",
                        disabled: _vm.isSomeButtonLoading,
                        value:
                          _vm.vnComponentData &&
                          _vm.vnComponentData.COMBO_VERSICHERUNGSART,
                        values: _vm.vnComboboxSelections
                          ? _vm.vnComboboxSelections.COMBO_VERSICHERUNGSART
                          : []
                      },
                      on: {
                        change: function($event) {
                          return _vm.updateInsuranceType({
                            componentId: "COMBO_VERSICHERUNGSART",
                            value: $event
                          })
                        }
                      }
                    }),
                    _vm.vnComponentData &&
                    !_vm.vnComponentData.COMBO_VERSICHERUNGSART
                      ? _c("span", { staticClass: "color-danger" }, [
                          _vm._v("Versicherungsart ist erforderlich!")
                        ])
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            _vm.modus === "archiv"
              ? _c(
                  "div",
                  { staticClass: "box__container" },
                  [
                    _c("div", { staticClass: "box__title" }, [
                      _vm._v("Auswahl der Versicherungsart")
                    ]),
                    _c("ComboBox", {
                      attrs: {
                        isComponentHalfSize: "",
                        disabled: _vm.isSomeButtonLoading,
                        value:
                          _vm.vnComponentData &&
                          _vm.vnComponentData.COMBO_VERSICHERUNGSART_ARCHIV,
                        values: _vm.vnComboboxSelections
                          ? _vm.vnComboboxSelections
                              .COMBO_VERSICHERUNGSART_ARCHIV
                          : []
                      },
                      on: {
                        change: function($event) {
                          return _vm.updateInsuranceTypeArchiv({
                            componentId: "COMBO_VERSICHERUNGSART_ARCHIV",
                            value: $event
                          })
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.modus === "aktuell"
              ? _c("StepperForm", {
                  ref: "Contentstepper",
                  attrs: {
                    stepType: "tab",
                    stepperName: "ansprechpartner",
                    stepperMediator: _vm.tabsStepperMediator,
                    selectedStepKey: _vm.currentStep,
                    hasFertig: false,
                    additionalActionsForMobile: _vm.baseModalActions,
                    ignoreOpenModalOnMount: ""
                  },
                  on: {
                    "set-step": function($event) {
                      return _vm.setStep($event)
                    },
                    handleOtherActions: _vm.handleOtherActions
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "contentTemplate",
                        fn: function() {
                          return [
                            _vm.currentStep === "insurancedata"
                              ? [
                                  _c(
                                    "div",
                                    { staticClass: "box__container" },
                                    [
                                      _vm.loadingState.init
                                        ? _c("GhostLoading", {
                                            attrs: {
                                              type: "table",
                                              title: _vm.uebertragenTitle
                                            }
                                          })
                                        : _c("Table", {
                                            attrs: {
                                              title: _vm.uebertragenTitle,
                                              headers: _vm.headers,
                                              rows: _vm.rows,
                                              rowsPerPage: 10,
                                              rowId: "vertragId"
                                            },
                                            model: {
                                              value: _vm.selectedRows,
                                              callback: function($$v) {
                                                _vm.selectedRows = $$v
                                              },
                                              expression: "selectedRows"
                                            }
                                          })
                                    ],
                                    1
                                  )
                                ]
                              : _vm._e(),
                            _vm.currentStep === "vn-persondata"
                              ? [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-12" },
                                      [
                                        _c("ComponentList", {
                                          attrs: {
                                            components: _vm.vnComponents,
                                            data: _vm.vnComponentData,
                                            comboboxValues:
                                              _vm.vnComboboxSelections
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.onChangePersonVN(
                                                $event
                                              )
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                ]
                              : _vm._e(),
                            _vm.currentStep === "vp-persondata"
                              ? [
                                  _c("div", { staticClass: "row" }, [
                                    _c(
                                      "div",
                                      { staticClass: "col-12" },
                                      [
                                        _c("ComponentList", {
                                          attrs: {
                                            components: _vm.vpComponents,
                                            data: _vm.vpComponentData,
                                            comboboxValues:
                                              _vm.vpComboboxSelections
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.onChangePersonVP(
                                                $event
                                              )
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                ]
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      },
                      _vm.currentStep === "insurancedata"
                        ? {
                            key: "buttonsTemplate",
                            fn: function() {
                              return [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "BaseButton",
                                      {
                                        attrs: { isPrimary: "" },
                                        on: {
                                          click: function($event) {
                                            return _vm.$refs.Contentstepper.nextStep()
                                          }
                                        }
                                      },
                                      [_vm._v(" Weiter ")]
                                    ),
                                    _c(
                                      "BaseButton",
                                      {
                                        attrs: {
                                          disabled:
                                            _vm.isSomeButtonLoading ||
                                            _vm.isLinkDisabled ||
                                            !_vm.hasSelectedRows
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.loginToExternalWebsite()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(" Login mit Vertragsdaten "),
                                        _vm.loadingState.external
                                          ? _c("AnimatedSpinner")
                                          : _vm._e()
                                      ],
                                      1
                                    ),
                                    _c(
                                      "BaseButton",
                                      {
                                        attrs: {
                                          disabled:
                                            _vm.isSomeButtonLoading ||
                                            _vm.isLinkDisabled,
                                          isSecondary: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.loginToExternalWebsite(
                                              false
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(" Login mit Kundendaten "),
                                        _vm.loadingState.externalNoData
                                          ? _c("AnimatedSpinner")
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        : null
                    ],
                    null,
                    true
                  )
                })
              : _vm._e(),
            _vm.modus === "archiv"
              ? _c(
                  "div",
                  { staticClass: "box__container" },
                  [
                    _c("Table", {
                      attrs: {
                        title: "Archivdaten",
                        headers: _vm.headersArchiv,
                        rows: _vm.rowsArchiv,
                        rowsPerPage: 10
                      },
                      on: {
                        "click-label": function($event) {
                          return _vm.goToArchivedSession($event)
                        },
                        "action-DELETE": _vm.deleteArchiveData
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.isSmallOrMediumScreen &&
            this.websiteId !== "Tarifrechner Nürnberger BT4All"
              ? _c(
                  "div",
                  { staticClass: "box__container fb-tools-sm-button-actions" },
                  [
                    _c("div", [
                      _c(
                        "div",
                        { staticClass: "layout__negative-margin--8" },
                        [
                          _c(
                            "BaseButton",
                            {
                              attrs: {
                                disabled:
                                  _vm.isSomeButtonLoading ||
                                  _vm.isLinkDisabled ||
                                  !_vm.hasSelectedRows
                              },
                              on: {
                                click: function($event) {
                                  return _vm.loginToExternalWebsite()
                                }
                              }
                            },
                            [
                              _vm._v(" Login mit Vertragsdaten "),
                              _vm.loadingState.external
                                ? _c("AnimatedSpinner")
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "BaseButton",
                            {
                              attrs: {
                                disabled:
                                  _vm.isSomeButtonLoading || _vm.isLinkDisabled,
                                isSecondary: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.loginToExternalWebsite(false)
                                }
                              }
                            },
                            [
                              _vm._v(" Login mit Kundendaten "),
                              _vm.loadingState.externalNoData
                                ? _c("AnimatedSpinner")
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "box__container" },
              [
                _c("div", { staticClass: "box__title mt-3" }, [
                  _vm._v("Weitere kostenpflichtige Optionen buchen")
                ]),
                _c("DownloadLink", {
                  attrs: {
                    downloadServicePath: "/frankeUndBornbergBestellformular",
                    filename: "Bestellformular_FrankeBornberg.pdf",
                    title:
                      "Bestellformular - Vergleich für alte und neue Tarife (Formular zurücksenden an Assekuranz@fondskonzept.ag)"
                  }
                }),
                _c("div", { staticClass: "mt-3" }, [
                  _vm._v(
                    "Durch das Feature können im Vergleichsrechner alte und neue Tarife hinzugefügt werden und mit den anderen Tarifen verglichen werden. Den Punkt finden Sie unterhalb des Reiters Tarifliste “Tarif hinzufügen”. Das Hinzufügen ist in allen Sparten möglich."
                  )
                ])
              ],
              1
            )
          ]
        : _vm._e(),
      _vm.websiteId !== "Franke & Bornberg fb-tools" &&
      this.websiteId !== "Innosystems"
        ? [
            this.websiteId !== "Tarifrechner Nürnberger BT4All"
              ? _c(
                  "div",
                  { staticClass: "box__container" },
                  [
                    _vm.loadingState.init
                      ? _c("GhostLoading", {
                          attrs: { type: "table", title: _vm.uebertragenTitle }
                        })
                      : _c("Table", {
                          attrs: {
                            title: _vm.uebertragenTitle,
                            headers: _vm.headers,
                            rows: _vm.rows,
                            rowsPerPage: 10,
                            rowId: "vertragId"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "selected",
                                fn: function(row) {
                                  return [
                                    _c("InputRadioBoxItem", {
                                      attrs: { name: "singleSelectRow" },
                                      on: {
                                        change: function($event) {
                                          return _vm.onChange(row)
                                        }
                                      }
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3974687157
                          ),
                          model: {
                            value: _vm.selectedRows,
                            callback: function($$v) {
                              _vm.selectedRows = $$v
                            },
                            expression: "selectedRows"
                          }
                        })
                  ],
                  1
                )
              : _vm._e(),
            this.websiteId !== "Tarifrechner Nürnberger BT4All"
              ? _c("div", { staticClass: "box__container" }, [
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "layout__negative-margin--8" },
                      [
                        _c(
                          "BaseButton",
                          {
                            attrs: {
                              disabled:
                                !_vm.isSomeRowSelected ||
                                _vm.isSomeButtonLoading ||
                                _vm.isLinkDisabled
                            },
                            on: {
                              click: function($event) {
                                return _vm.loginToExternalWebsite()
                              }
                            }
                          },
                          [
                            _vm._v(" Login mit Versicherungsdaten "),
                            _vm.loadingState.external
                              ? _c("AnimatedSpinner")
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm.websiteId == "Nafi-KFZ" ||
                        _vm.websiteId == "Finanzportal24"
                          ? _c(
                              "BaseButton",
                              {
                                attrs: {
                                  disabled:
                                    _vm.isSomeButtonLoading ||
                                    _vm.isLinkDisabled,
                                  isSecondary: ""
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.loginToExternalWebsite(false)
                                  }
                                }
                              },
                              [
                                _vm._v(" Login ohne Versicherungsdaten "),
                                _vm.loadingState.external
                                  ? _c("AnimatedSpinner")
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ])
              : _vm._e()
          ]
        : _vm._e(),
      _vm.websiteId === "Innosystems"
        ? [
            _c("div", [
              _c("div", { staticClass: "box__container" }, [
                _c("div", { staticClass: "box__title" }, [
                  _vm._v(" Vergleichsrechner Sachversicherung ")
                ]),
                _c("div", { staticClass: "innosystem-links__container" }, [
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.clickInnosystemsButtom(
                            "BUTTON_BAUHERRENHAFTPFLICHT"
                          )
                        }
                      }
                    },
                    [_vm._v("Bauherrenhaftpflicht")]
                  ),
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.clickInnosystemsButtom(
                            "BUTTON_GEWAESSERSCHADEN"
                          )
                        }
                      }
                    },
                    [_vm._v("Gewässerschaden")]
                  ),
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.clickInnosystemsButtom(
                            "BUTTON_HAUSUNDGRUNDBESITZER"
                          )
                        }
                      }
                    },
                    [_vm._v("Haus und Grundbesitzer")]
                  ),
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.clickInnosystemsButtom("BUTTON_HAUSRAT")
                        }
                      }
                    },
                    [_vm._v("Hausrat")]
                  ),
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.clickInnosystemsButtom(
                            "BUTTON_HUNDEHAFTPFLICHT"
                          )
                        }
                      }
                    },
                    [_vm._v("Hundehaftpflicht")]
                  ),
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.clickInnosystemsButtom(
                            "BUTTON_PFERDEHAFTPFLICHT"
                          )
                        }
                      }
                    },
                    [_vm._v("Pferdehaftpflicht")]
                  ),
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.clickInnosystemsButtom(
                            "BUTTON_PRIVATHAFTPFLICHT"
                          )
                        }
                      }
                    },
                    [_vm._v("Privathaftpflicht")]
                  ),
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.clickInnosystemsButtom(
                            "BUTTON_RECHTSSCHUTZ"
                          )
                        }
                      }
                    },
                    [_vm._v("Rechtschutz")]
                  ),
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.clickInnosystemsButtom("BUTTON_UNFALL")
                        }
                      }
                    },
                    [_vm._v("Unfall")]
                  ),
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.clickInnosystemsButtom(
                            "BUTTON_WOHNGEBAEUDE"
                          )
                        }
                      }
                    },
                    [_vm._v("Wohngebäude")]
                  )
                ])
              ]),
              _c("div", { staticClass: "box__container" }, [
                _c("div", { staticClass: "box__title" }, [
                  _vm._v(" Vergleichsrechner Gewerbeversicherung ")
                ]),
                _c("div", { staticClass: "innosystem-links__container" }, [
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.clickInnosystemsButtom(
                            "BUTTON_RECHTSSCHUTZSELBST"
                          )
                        }
                      }
                    },
                    [_vm._v("Rechtsschutz für Selbstständige/ Firmen")]
                  )
                ])
              ]),
              _c("div", { staticClass: "box__container" }, [
                _c("div", { staticClass: "box__title" }, [
                  _vm._v(" Vergleichsrechner Krankenversicherung ")
                ]),
                _c("div", { staticClass: "innosystem-links__container" }, [
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.clickInnosystemsButtom(
                            "BUTTON_KRANKENHAUSTAGEGELD"
                          )
                        }
                      }
                    },
                    [_vm._v("Krankenhaustagegeld")]
                  ),
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.clickInnosystemsButtom(
                            "BUTTON_KRANKENTAGEGELD"
                          )
                        }
                      }
                    },
                    [_vm._v("Krankentagegeld")]
                  ),
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.clickInnosystemsButtom(
                            "BUTTON_KRANKENVOLLVERSICHERUNG"
                          )
                        }
                      }
                    },
                    [_vm._v("Krankenvollversicherung")]
                  ),
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.clickInnosystemsButtom(
                            "BUTTON_KRANKENZUSATZVERSICHERUNG"
                          )
                        }
                      }
                    },
                    [_vm._v("Krankenzusatzversicherung")]
                  )
                ])
              ]),
              _c("div", { staticClass: "box__container" }, [
                _c("div", { staticClass: "box__title" }, [
                  _vm._v(" Vergleichsrechner Krankenzusatzversicherung Zahn ")
                ]),
                _c("div", { staticClass: "innosystem-links__container" }, [
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.clickInnosystemsButtom(
                            "BUTTON_KRANKENZUSATZVERSICHERUNGZAHN"
                          )
                        }
                      }
                    },
                    [_vm._v("Krankenzusatzversicherung Zahn")]
                  )
                ])
              ]),
              _c("div", { staticClass: "box__container" }, [
                _c("div", { staticClass: "box__title" }, [
                  _vm._v(
                    " Vergleichsrechner PKW-Versicherung und Motorrad-Versicherung "
                  )
                ]),
                _c("div", { staticClass: "innosystem-links__container" }, [
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.clickInnosystemsButtom("BUTTON_PKW")
                        }
                      }
                    },
                    [_vm._v("PKW und Motorrad (zum Auswahlmenu)")]
                  ),
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.clickInnosystemsButtom("BUTTON_EVBSERVICE")
                        }
                      }
                    },
                    [_vm._v("EVB-Service")]
                  )
                ])
              ])
            ])
          ]
        : _vm._e(),
      _c(
        "BaseModal",
        {
          ref: "errorMessageModal",
          attrs: {
            modalTitle: "Fehler",
            showConfirmButton: true,
            showCancelButton: false,
            labelButtonConfirm: "Fertig"
          }
        },
        [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]
      )
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("Eine Beantragung der "),
      _c("strong", [_vm._v("vorläufigen Deckung im Bereich Kasko")]),
      _vm._v(
        " ist bei den nachfolgend aufgeführten Gesellschaften nicht über NAFI möglich: "
      ),
      _c("ul", [
        _c("li", [_vm._v("DEVK")]),
        _c("li", [_vm._v("HDI")]),
        _c("li", [_vm._v("Nürnberger")]),
        _c("li", [_vm._v("Württembergische")]),
        _c("li", [_vm._v("VKB")])
      ]),
      _vm._v(
        " Bitte gehen Sie direkt auf die Gesellschaft zu, wenn Kaskoschutz innerhalb der vorläufigen Deckung gewünscht ist. "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mt-2" }, [
      _vm._v("Außerdem kann eine EVB bei der "),
      _c("strong", [_vm._v("DEVK")]),
      _vm._v(" nur in Verbindung mit einem Antrag generiert werden.")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }