export const trueFalseOptions = [
  { true: true, false: false },
  { true: 1,    false: 0},
  { true: '1',  false: '0'},
  { true: 'j',  false: 'n'},
  { true: 'J',  false: 'N'},
  { true: 'Ja', false: 'Nein'},
  { true: 'JA', false: 'NEIN'},
];

export const trueFalseMap = {
  true: false, 
  false: true,
  '1': '0',
  '0': '1',
  'j': 'n',
  'n': 'j',
  'J': 'N',
  'N': 'J',
  'Ja': 'Nein',
  'Nein': 'Ja',
  'JA': 'NEIN',
  'NEIN': 'JA',
};

export const TRUE_VALUES = [
  true,
  'true',
  '1',
  'j',
  'J',
  'ja',
  'Ja',
  'JA'
];

export function getInvertedBooleanValue(value) {
  if (value === 1) {
    return 0
  }
  if (value === 0) {
    return 1
  }
  return trueFalseMap[value]
}

export function getBooleanValue(current, reference) {
  for (const trueFalse of trueFalseOptions) {
    if (trueFalse.true === current || trueFalse.false === current) {
      return reference ? trueFalse.true : trueFalse.false
    }
  }

  return reference
}

export function isTrue(value) {
  return TRUE_VALUES.some(trueValue => trueValue === value);
}

export function mapListToComboBoxValues(list, value = '', label = '') {
  if(!list?.length) return [];

  return list.map((item) => ({
    value: item[value],
    label: item[label],
  }));
}

export function mapListToKeyValue(list, key, value) {
  if(!list?.length) return {};

  return list.reduce((result, item) => {
    result[item[key]] = item[value];
    return result;
  }, {});
}
