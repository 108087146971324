<template>
    <div>
        <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" />

        <PageHeaderTitleNavigation
            :id="$appNavigation.currentOptionMenuId"
            title="Termine / Aufgaben"
            :actions="headerActions"
            @action-NEW_TERMIN="newAppointment"
            @action-NEW_AUFGABE="newTask"
        />

        <BaseFilter
            v-if="defaultOptions"
            title="Termine / Aufgaben Filter"
            filterId="PostfachTAAFilter"
            :isCache="hasFilterCache"
            :configFilter="filterConfig"
            :metadata="metadata"
            :defaultOptions="defaultOptions"
            showSaveButton
            hasSmartSearch
            immidiateSearch
            @onFilter="onFilter"
        />

        <div class="box__container">
            <ScrollLoading v-if="rows.length" :hasScroll="rows.length !== maxRowCount && !reachedEnd" @scroll="onScroll">
                <Table
                    v-if="rows.length"
                    v-model="selectedRows"
                    rowId="rowid"
                    :headers="headers"
                    :rows="rows"
                    :headerActions="tableHeaderActions"
                    :exportConfig="{roottext: 'Postfach'}"
                    :mobileConfig="mobileConfig"
                    :count="maxRowCount"
                    hidePagination
                    @click-subject="clickBetreff"
                    @action-DELETE="deleteRow"
                    @headerAction-BULK_DELETE="deleteSelected"
                >
                    <template v-slot:participants="row">
                        <ul style="padding: 0; margin: 0">
                            <li
                                style="display:inline-block;"
                                v-for="(participant, index) in row.participants"
                                :key="participant.id"
                            >
                                <a
                                    v-if="
                                        participant.isKunde && canOpenCustomer(participant) && !row.mobileTableContext
                                    "
                                    @click="openCustomerNewTab(participant.nummer)"
                                    >{{ participant.name }}</a
                                >
                                <span v-else>{{ participant.name }}</span>
                                <span v-if="index < row.participants.length - 1">;&nbsp;</span>
                            </li>
                        </ul>
                    </template>
                </Table>
            </ScrollLoading>
            <GhostLoading v-else-if="loading && !reachedEnd" type="table" :config="{table: {rows: 10}}" />
            <NoData v-else />
        </div>
    </div>
</template>

<script>
import CALENDAR_TYPES from "@/store/calendar/types"
import Table from "@/components/table2/Table.vue"
import {
    TextColumn,
    SlotColumn,
    DateTimeColumn,
    ActionColumn,
    ConfirmedAction,
    PillColumn,
} from "@/components/table2/table_util.js"
import postfachTableMixin from "@/views/communication/postfach/tables/postfach-table-mixin.js"
import axios from "axios"
import dayjs from "dayjs"
import ScrollLoading from "@/views/communication/ScrollLoading.vue"

import COMMUNICATION_TYPES from "@/store/communication/types"

import BaseFilter from "@/components/core/BaseFilter.vue"
import {mapGetters} from "vuex"
import {
    PageHeaderSimpleAction,
    PageHeaderConfirmedAction,
} from "@/components/core/header-title-navigation/page-header-utils"

const ROWS_PER_PAGE_DEFAULT = 25

export default {
    mixins: [postfachTableMixin],
    components: {
        Table,
        BaseFilter,
        ScrollLoading,
    },
    data() {
        return {
            rowsPerPage: ROWS_PER_PAGE_DEFAULT,
            currentMode: "",
            category: "termin",
            mailboxLabel: "Termin",
            defaultOptions: null,
            hasFilterCache: true,
            reachedEnd: false,
            filterConfig: {
                placeholderForDefSearchInput: "Betreff, Besitzer, Teilnehmer",
                noResetOnDefaultSearchInputExit: true,
                checkDefaultSearchInput: false,
                hideFirstColumn: true,
            },
            mobileConfig: {
                title: "name",
                headers: ["subject", "date", "participants", "type", "status"],
            },
            maxRowCount: 0,
            onScrollEnd: () => {},
        }
    },
    computed: {
        ...mapGetters({
            postfachDefaultFilters: COMMUNICATION_TYPES.GETTERS.POSTFACH_DEFAULT_FILTERS,
            getAppointmentCombos: CALENDAR_TYPES.GETTERS.GET_APPOINTMENT_COMBOS,
        }),
        headers() {
            return {
                lockedLeft: [
                    DateTimeColumn("date", "Datum", undefined, 1),
                    TextColumn("subject", "Betreff").makeLink(),
                ],
                center: [
                    TextColumn("name", "Besitzer"),
                    SlotColumn("participants", "Teilnehmer", 80, 1),
                    PillColumn("type", "Kategorie"),
                    TextColumn("status", "Status"),
                ],
                lockedRight: [ActionColumn("actions", "Aktionen")],
            }
        },
        rows() {
          return this.records.map((record) => {
              return {
                  ...record,
                  type: {
                      label: record.category,
                      type: "default",
                  },
                  rowid: record.commId + record.category + record.date,
                  subject: record.subject || "[kein Betreff]",
                  actions: this.makeActions(record),
              }
          })
        },
        metadata() {
            const statuses = this.getAppointmentCombos?.wvStatusFilter || []

            const categories = ["Termin", "Aufgabe"]
            const result = [
                {
                    type: "group",
                    key: "allgemein",
                    label: "Allgemein",
                    menuItems: [
                        {
                            type: "text",
                            label: "Betreff",
                            key: "subject",
                        },
                        {
                            type: "default",
                            label: "nur mich als Besitzer",
                            key: "isOwner",
                        },
                        {
                            type: "default",
                            label: "nur zukünftige Termine",
                            key: "isFuture",
                        },
                        {
                            type: "text",
                            label: "Besitzer",
                            key: "owner",
                        },
                        {
                            type: "text",
                            label: "Teilnehmer",
                            key: "participant",
                        },
                        {
                            type: "dateRange",
                            label: "Zeitraum",
                            key: "zeitraum",
                        },
                        {
                            type: "datepicker",
                            label: "Datum",
                            key: "datum",
                        },
                        {
                            type: "combobox",
                            label: "Kategorie",
                            key: "category",
                            comboOptions: categories.map((category) => ({
                                label: category,
                                value: category,
                            })),
                            secondaryItem: {
                                type: statuses?.length ? "combobox" : "default",
                                emptyDenied: statuses?.length,
                                label: "Status",
                                key: "status",
                                parentValue: ["Aufgabe"],
                                comboOptions: statuses,
                            },
                        },
                    ],
                },
            ]
            return result
        },
        headerActions() {
            return [
                PageHeaderSimpleAction("NEW_TERMIN", "Neuer Termin"),
                PageHeaderSimpleAction("NEW_AUFGABE", "Neue Aufgabe"),
            ]
        },
        tableHeaderActions() {
            return [
                PageHeaderConfirmedAction(
                    "BULK_DELETE",
                    "Markierte löschen",
                    "Sollen die markierten Einträge wirklich gelöscht werden?",
                    "Markierte löschen",
                    "Löschen",
                ).withDisabled(() => !this.selectedRows.length),
            ]
        },
    },
    mounted() {
        this.$store.dispatch(CALENDAR_TYPES.ACTIONS.RETRIEVE_APPOINTMENT_COMBOS)

        const {fromDate, toDate} = this.postfachDefaultFilters
        const query = this.$route.query

        this.defaultOptions = {
            zeitraum: {
                value: [
                    {
                        key: "min",
                        value: fromDate,
                    },
                    {
                        key: "max",
                        value: toDate,
                    },
                ],
            },
        }

        if (query.q) {
            this.defaultOptions.subject = {
                value: query.q,
            }
            this.hasFilterCache = false
        }
    },
    methods: {
        makeActions(row) {
            const article = row.category === "Termin" ? "der" : "die"

            return [
                ConfirmedAction(
                    "DELETE",
                    "PhTrash",
                    "Löschen",
                    `Soll ${article} ${row.category} "${row.subject || "[kein Betreff]"}" wirklich gelöscht werden?`,
                    `${row.category} löschen`,
                    "Löschen",
                ),
            ]
        },
        clickBetreff(row) {
            if (row.commId) {
                this.$store.dispatch(CALENDAR_TYPES.ACTIONS.RETRIEVE_SELECTED_APPOINTMENT, row.commId)
                this.openAppointment(row.category === "Aufgabe")
                if (row.category === "Aufgabe") {
                    this.isActivity = true
                    this.attachCustomer = true
                    this.getCurrentMode("Aufgabe")
                    this.openAppointment()
                } else {
                    this.attachCustomer = true
                    this.isActivity = false
                    this.getCurrentMode("Termin")
                    this.openAppointment()
                }
            }
        },
        openAppointment() {
            this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_CONFIG, {
                title: `${
                    this.currentMode === "Termin" || this.currentMode === "Aufgabe" ? this.currentMode : "Aktivität"
                } bearbeiten`,
                mode: this.currentMode,
                isActivity: this.isActivity,
                attachCustomer: this.attachCustomer,

                back: this.$router.currentRoute.fullPath,
            })
            this.$router.push({path: `/communication/appointment`})
        },
        newTask() {
            this.isActivity = true
            this.attachCustomer = true
            this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT, {activity: false})
            this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_NEW_AUFGABE)
            this.getCurrentMode("Aufgabe")
            this.openAppointment(false)
        },
        newAppointment() {
            this.attachCustomer = true
            this.isActivity = false
            this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT, {activity: false})
            this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_NEW_TERMIN)
            this.getCurrentMode("Termin")
            this.openAppointment(true)
        },
        getCurrentMode(bereich) {
            if (!bereich) {
                bereich = this.getSelectedEvent?.appointment?.bereich
            }
            this.currentMode = ""
            if (bereich) {
                this.currentMode = bereich === "Termin" || bereich === "Aufgabe" ? bereich : "Aktivität"
            }
        },
        onScroll(onScrollEnd) {
            this.onScrollEnd = onScrollEnd;
            this.doSearch(this.filterParams)
        },
        doSearch(urlParams, offset = this.rows.length) {
            if (this.loading) return

            this.loading = true
            this.reachedEnd = false
            console.log("this.loading", this.loading)

            const page = offset / this.rowsPerPage

            if (page == 0) {
                this.selectedRows = []
                this.records = []
            }

            const params = {
                ...urlParams,
            }

            //INFO: This was a fix for the extrem loading times when using no parameters, it was removed becouse it limited the request time. Now infinite loading is used
            /*
            if(urlParams.fromDate || this.postfachDefaultFilters.fromDate) {
              params.fromDate = urlParams.fromDate || dayjs(this.postfachDefaultFilters.fromDate).format('DD.MM.YYYY')
            }
            if(urlParams.toDate || this.postfachDefaultFilters.toDate) {
              params.toDate = urlParams.toDate || dayjs(this.postfachDefaultFilters.toDate).format('DD.MM.YYYY')
            }*/

            axios
                .post(`${process.env.VUE_APP_API}/postfachTermin/list/termine`, params, {
                    defaultSpinner: true,
                    params: {
                        page: page,
                        limit: this.rowsPerPage,
                    },
                })
                .then((response) => {
                    this.records.push(...response.data?.records)

                    this.maxRowCount = response.data?.rowCount

                    this.reachedEnd = this.maxRowCount <= this.records.length

                    console.log("Records", this.records, this.reachedEnd)
                })
                .finally(() => {
                  this.onScrollEnd();
                    this.loading = false
                    console.log("this.loading", this.loading)
                })
        },
    },
}
</script>
