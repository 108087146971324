<template>
  <div>
  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
    }),
  },
  methods: {
    async openCustomerNewTab() {
      const { kundennr:customerId, path } = this.$route?.query || {};

      await this.navigateHome();

      if (customerId && path) {
        await this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, {
          customerId,
          path,
        });
      }
    },
    async navigateHome() {
      await this.$router.push('/home');
    }
  },
  beforeRouteEnter(from, to, next) {
    next(vm => {
      if (vm.isBroker) {
        vm.openCustomerNewTab();
      } else {
        vm.navigateHome();
      }
    });
  },
}

</script>