var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-forms__container" }, [
    _vm.label
      ? _c("div", { staticClass: "input-forms__label-content" }, [
          _vm._v(_vm._s(_vm.label))
        ])
      : _vm._e(),
    _c(
      "div",
      {
        staticClass: "timepicker-container",
        class: { "forms__input--half-size": this.isComponentHalfSize }
      },
      [
        _c(
          "BaseDropdownMenu",
          {
            ref: "dropdown",
            attrs: { placement: "bottom-right", closeWithInnerClick: false },
            on: { onShow: _vm.onShowDropdown },
            scopedSlots: _vm._u([
              {
                key: "hook-target",
                fn: function() {
                  return [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.currentTime,
                          expression: "currentTime"
                        }
                      ],
                      staticClass: "fc-input input-forms__input-field",
                      class: _vm.inputClass,
                      attrs: {
                        type: "text",
                        disabled: _vm.disabled,
                        inputmode: "numeric"
                      },
                      domProps: { value: _vm.currentTime },
                      on: {
                        keydown: _vm.onKeyDown,
                        keyup: function($event) {
                          return _vm.format($event)
                        },
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.currentTime = $event.target.value
                        }
                      }
                    }),
                    _vm.timeUnit && _vm.currentTime
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "inputfield__input-icon inputfield__input-icon--right-side"
                          },
                          [_vm._v(" " + _vm._s(_vm.timeUnit) + " ")]
                        )
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            ])
          },
          [
            !_vm.disabled
              ? _c("div", { staticClass: "tp-selector__container" }, [
                  _c(
                    "div",
                    { ref: "columnHour", staticClass: "tp-column__selector" },
                    _vm._l(_vm.hours, function(hour) {
                      return _c(
                        "div",
                        {
                          key: hour,
                          staticClass: "tp-cell__selector tp-cell__hour",
                          class: {
                            "tp-cell__selector--selected": _vm.isSelected(
                              _vm.currentHour,
                              hour
                            )
                          },
                          on: {
                            click: function($event) {
                              return _vm.selectHour(hour)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(hour) + " ")]
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    { ref: "columnMinute", staticClass: "tp-column__selector" },
                    _vm._l(_vm.minutes, function(minute) {
                      return _c(
                        "div",
                        {
                          key: minute,
                          staticClass: "tp-cell__selector tp-cell__minute",
                          class: {
                            "tp-cell__selector--selected": _vm.isSelected(
                              _vm.currentMinute,
                              minute
                            )
                          },
                          on: {
                            click: function($event) {
                              return _vm.selectMinute(minute)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(minute) + " ")]
                      )
                    }),
                    0
                  ),
                  _vm.withSeconds
                    ? _c(
                        "div",
                        {
                          ref: "columnSecond",
                          staticClass: "tp-column__selector"
                        },
                        _vm._l(_vm.minutes, function(second) {
                          return _c(
                            "div",
                            {
                              key: second,
                              staticClass: "tp-cell__selector tp-cell__second",
                              class: {
                                "tp-cell__selector--selected": _vm.isSelected(
                                  _vm.currentSecond,
                                  second
                                )
                              },
                              on: {
                                click: function($event) {
                                  return _vm.selectSecond(second)
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(second) + " ")]
                          )
                        }),
                        0
                      )
                    : _vm._e()
                ])
              : _vm._e()
          ]
        ),
        _vm.isValidationConfigDone
          ? _c("div", [
              _vm.validation
                ? _c(
                    "div",
                    {
                      key: _vm.validation.updated,
                      staticClass: "input-forms__errors-container"
                    },
                    _vm._l(
                      _vm.validation.getErrors(
                        _vm.validationPath,
                        _vm.validateUntouched
                      ),
                      function(error) {
                        return _c(
                          "div",
                          { key: error, staticClass: "fc-form-danger" },
                          [_vm._v(" " + _vm._s(error) + " ")]
                        )
                      }
                    ),
                    0
                  )
                : _vm._e()
            ])
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }