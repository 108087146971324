export const MUTATION_PREFIX = 'ACCOUNT: ';
export const ACTIONS_PREFIX = 'ACCOUNT_ACTIONS_';
export const GETTERS_PREFIX = 'ACCOUNT_GETTERS_';

export default {
  MUTATIONS: {
    GET_ACCOUNT_SUCCESS: MUTATION_PREFIX + 'GET_ACCOUNT_SUCCESS',
    GET_ACCOUNT_DEPOTSTAMMDATEN_SUCCESS: MUTATION_PREFIX + 'GET_ACCOUNT_DEPOTSTAMMDATEN_SUCCESS',
    GET_FAILTURE: MUTATION_PREFIX + 'GET_FAILTURE',
  },
  ACTIONS: {
    GET_ACCOUNTS: ACTIONS_PREFIX + 'GET_ACCOUNTS',
    GET_ACCOUNT_DEPOTSTAMMDATEN: ACTIONS_PREFIX + 'GET_ACCOUNT_DEPOTSTAMMDATEN',
  },
  GETTERS: {
    ACCOUNTS: GETTERS_PREFIX + 'ACCOUNTS',
    ACCOUNT_GETTERS_DEPOTSTAMMDATEN: GETTERS_PREFIX + 'DEPOTSTAMMDATEN',
  }
}
