import actions from './actions';
import mutations from './mutations';
import getters from './getters';

/**
 * mutations: synchronous operation responsible for change the state
 * actions: asynchronous operation responsible for call external resources and commit mutations 
 */
export function getInitialState() {
  return {
    depotlimit: {},
    depotlimitList: [],
    depotpositionLimits: [],
    depotpositionLimitAutomaticList: [],
    depotpositionLimitAutomatic: null,
    risikoklassenLimit: null,
    risikoklassenLimitList: null,
    wertpapierkategorieLimitList: [],
    wertpapierkategorieLimit: null,
    wertpapierLimitList: [],
    wertpapierLimit: null,
    gd200LimitList: [],
    gd200Limit: null,
  }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}