<template>
  <div style="width: 100%; height: 250px">
    <v-chart ref="chart" class="chart" :option="option" :theme="themeKey" autoresize />
  </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart } from "echarts/charts";
import {
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
  ToolboxComponent,
  GridComponent,
} from "echarts/components";
import VChart from "vue-echarts";

import echartsMixin from './echarts-mixin';

use([
  CanvasRenderer,
  LineChart,
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
  ToolboxComponent,
  GridComponent,
]);

export default {
  name: 'LineChart',
  mixins: [echartsMixin],
  components: {
    VChart
  },
  props: {
    chartData: {
      type: Array,
      default: () => []
    },
    chartSeries: {
      type: Array,
      default: () => [],
    },
    xLabels: {
      default: () => undefined,
    },
    isArea: {
      type: Boolean,
      default: true,
    },
    gridHeight: {
      type: Number,
      default: null,
    },
    showDataZoom: {
      type: Boolean,
      default: false,
    },
    xAxisType: {
      type: String,
      default: 'time',
    },
    isSmallChart: {
      type: Boolean,
      default: false,
    },
    gridLeft: {
      type: Number,
      default: null
    },
    isTooltip: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
    }
  },
  computed: {
    option() {
      const option = {
        type: 'line',
        smooth: true,
        symbol: 'none',
        ...(this.isArea ? { areaStyle: {} } : {}),
        lineStyle: {
          width: 2,
        },
      };
      const series = (this.chartSeries?.length ? this.chartSeries.map(cs => ({
          ...cs,
          ...option,
        })) : [
          {
            data: this.chartData,
            ...option,
          }
        ]);

      return {
        color: this.customColors || this.themeColors,
        backgroundColor: 'transparent',
        grid: {
          // show: false,
          ...(this.isSmallChart ? { top:  0, } : {}),
          height: this.gridHeight || 'auto',
          bottom: '15%',
          right: '5%',
          left: '12%',
          left: this.gridLeft || '5%',
          top: '10%',
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.xLabels || (this.chartData && this.chartData[0] && this.chartData[0].data || []).map((cd, i) => i + 1),
          axisLabel: {
            rotate: 45,
            fontSize: 10,
          },
        },
        yAxis: {
          show: this.showYaxis,
          type: 'value',
          boundaryGap: false,
          axisLabel: {
            formatter: this.axisLabelFormatter(this.axisLabelFormatterShowOption),
            fontSize: 10,
          },
        },
        tooltip: {
          show: this.isTooltip,
          trigger: 'axis',
          formatter: this.tooltipFormatter,
          position: { left: 'center', top: 'auto'},
        },
        ...(this.showDataZoom && {dataZoom: [
          {
            type: 'inside',
            start: 0,
            end: 100
          },
          {
            start: 0,
            end: 100
          }
        ]}),
        series: this.chartData.map((value, i) => ({
          type: 'line',
          lineStyle: {
            width: 2
          },
          smooth: 0.6,
          areaStyle: {},
          name: value.name,
          data: value.data || [],
          itemStyle: {
            opacity: 0.5,
          }
        })),
      }
    }
  },
  watch: {
    chartSeries() {
      this.$refs.chart.clear();
      this.$refs.chart.setOption(this.option, true);
    },
  },
};
</script>

<style scoped>

</style>