<template>
  <div>
    <form v-on:submit="saveEvent">
      <div class="modal-body">
        <template>
          <div class="row">
            <div class="col col-md-6">
              <div>
                <ComboBox
                  label="Typ"
                  v-model="form.type"
                  :firstEmpty="true"
                  :values="(currentRowType === 'goal' ? zieleTypes : revenueTypes)"
                  @input="changeSelect($event)"
                >
                </ComboBox>
              </div>
            </div>
            <template v-if="isEventAdvancedFieldsApplicable(form.type)">
              <div class="col col-md-6">
                <div class>
                  <InputField
                    label="Beschreibung"
                    placeholder="Name"
                    v-model="form.title"
                    type="text"
                  ></InputField>
                </div>
              </div>
              <div
                class="col col-md-6"
                v-if="isRecurringEvent"
              >
                <ComboBox
                  label="Zeitraum"
                  v-model="form.period"
                  :firstEmpty="true"
                  :values="periodTypes"
                >
                </ComboBox>
              </div>
              <div class="col col-md-6">
                <label class="input-forms__label-content">Start Datum (TT.MM.JJJJ)</label>
                <div>
                  <DatePickerField
                    :monthPicker="true"
                    v-model="form.age"
                  ></DatePickerField>
                </div>
              </div>
              <div
                class="col col-md-6"
                v-if="isRecurringEvent || form.repeat === 'Wiederholung'"
              >
                <div>
                  <template>
                    <label class="input-forms__label-content">Endtermin (TT.MM.JJJJ)</label>
                    <div>
                      <DatePickerField
                        :monthPicker="true"
                        v-model="form.endDate"
                      ></DatePickerField>
                    </div>
                  </template>
                </div>
              </div>
            </template>
            <div class="col col-md-6">
              <InputField label="Betrag" v-model="form.amount" type="currency"></InputField>
            </div>
          </div>
        </template>
      </div>
      <div class="modal-footer">
        <BaseButton @click="saveEvent" type="submit">Ok</BaseButton>
      </div>
    </form>
  </div>
</template>

<script>
import {zieleTypes, revenueTypes, SPECIAL_TYPE_REVENUE_PENSION_INCOME, SPECIAL_TYPE_REVENUE_BEFORE_RETIREMENT_INCOME, SPECIAL_TYPE_REVENUE_PRESENT_VALUE_MANUAL, SPECIAL_TYPE_GOAL_COST_OF_LIVING_AFTER_RETIREMENT, periodTypes} from './types'
import BaseButton from '@/components/core/BaseButton.vue';
import InputField from "@/components/core/forms/InputField.vue";
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import validator from "@/mixins/validator";
import {
  required,
} from "@/mixins/validator/rules";
import ComboBox from '@/components/core/forms/ComboBox.vue';

export default {
	props: {
    currentRowType: '',
    emptyItem: {
      type: Object,
      default: () => ({}),
      required: true
    },
    transactionsArray: {
      type: Array,
      default: () => [],
      required: true
    },
    currentRowIndex: 0,
  },
  mixins: [validator],
	data () {
		return {
			form: {
        amount: '',
        period: '',
        title: '',
        type: '',
        age: new Date(),
      },
      zieleTypes: zieleTypes,
      revenueTypes: revenueTypes,

      selected: '',
      periodTypes: periodTypes,
		}
  },
  mounted: function() {
    if (this.currentRowIndex < 0) {
      this.form = {
        ...this.form,
        ...this.emptyItem,
      }
    } else {
      this.form = {
        ...this.form,
        ...this.transactionsArray[this.currentRowIndex],
        age: this.transactionsArray[this.currentRowIndex].age || new Date()
      };
    }
  },
	methods: {
    isEventAdvancedFieldsApplicable(type) {
      return ![
        SPECIAL_TYPE_REVENUE_PENSION_INCOME,
        SPECIAL_TYPE_REVENUE_BEFORE_RETIREMENT_INCOME,
        SPECIAL_TYPE_REVENUE_PRESENT_VALUE_MANUAL,
        SPECIAL_TYPE_GOAL_COST_OF_LIVING_AFTER_RETIREMENT,
      ].includes(type);
    },

    cancelCreation() {
      this.$emit('close');
    },

    saveEvent(e) {
      this.$emit('close');
      this.$emit('handleOk', this.form);
      e.stopPropagation();
      e.preventDefault();
    },

    changeSelect(e) {
      if (this.selected !== this.form.type) {
        this.$forceUpdate();
      }
      this.selected = this.form.type;
    },
	},
  validators: {
    form: {
      amount: [required()],
    }
  },

  computed: {
    isRecurringEvent() {
      return [
        'weiderkehrende Ausgaben',
        'Regelmässite Einnahme'
      ].includes(this.selected);
    },
  },

	filters: {
		currency(value) {
			if (isNaN(value)) {
				return value;
			}
			let val = (value/1).toFixed(2).replace('.', ',')
			return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' €';
		},
		percent(value) {
			if (isNaN(value)) {
				return value;
			}
			let val = (value/1).toFixed(2).replace('.', ',')
			return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' %';
		},
	},

	components: {
    InputField,
    DatePickerField,
    BaseButton,
    ComboBox,
	},
}
</script>

<style scoped>
.datepicker-container {
  padding-bottom: 0;
}
@media only screen and (max-width: 1024px) {
  .row {
    display: block;
  }
}
</style>
