<template>
  <div>
    <PageHeaderTitleNavigation
      :title="$appNavigation.currentMenu.parent.label"
      :subtitle="$appNavigation.currentMenu.label"
      :defaultMenu="$appNavigation.currentTabMenu"
    />

    <BaseFilter 
      filterId="ad59a8c1-0a61-482a-8471-fbe6f092ba5f"
      title="Konditionen filtern" 
      :metadata="filterMetadata" 
      v-if="lagerstellen && lagerstellen.length"
      @onFilter="filterForm($event)">
    </BaseFilter>
    <GhostLoading v-else-if="!lagerstellen || !lagerstellen.length" type="block" :config="{ block: { height: 100, }, }" useBoxContainer />

    <div class="box__container" tid="489796bd-259f-424b-80e5-f83526c042dd">

      <Table
        tableId="ded35895-8a4a-4d56-ad56-45ad956c3a8b"
        :title="TABLE_TITLE"
        class="table-container"
        v-if="!loading && tableRows.length"
        :headers="tableHeaders"
        :rows="tableRows"
        :rowsPerPage="20"
        :count="resultCount"
        :exportConfig="{roottext: TABLE_TITLE}"
        @action="executeAction($event)"
      >
        
      </Table>
      <GhostLoading v-else-if="loading" type="table" :title="TABLE_TITLE" />
      <NoData v-else :title="TABLE_TITLE" />
    </div>    


  </div>
    
</template>

<script>
import CORE_TYPES from "@/store/core/types";
import COURTAGELISTE_TYPES from "../../store/courtageliste/types";
import { mapGetters } from "vuex";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import Table from "@/components/table2/Table.vue";
import {TextColumn, NumberColumn} from "@/components/table2/table_util.js";
import BaseFilter from "@/components/core/BaseFilter.vue";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'

const TABLE_TITLE = 'Konditionen';

export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    Table,
    GhostLoading,
    NoData,
    BaseFilter,
    OptionMenu,
    PageHeaderTitleNavigation,
  },
  data: function () {
      return {
        TABLE_TITLE,
        resultCount: null,
        loading: true,
      }
  },
  computed: {
    ...mapGetters({
      fondswerte: COURTAGELISTE_TYPES.GETTERS.FONDSWERTE,
      lagerstellen: COURTAGELISTE_TYPES.GETTERS.LAGERSTELLEN,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      isFA: CORE_TYPES.GETTERS.IS_FA,
    }),
    tableHeaders() {
        return {
          lockedLeft: [
            TextColumn("isin", "ISIN").makeAlwaysVisible(),
            TextColumn("fondsname", "Fondsname"),            
          ],
          center: [
          NumberColumn("bestand_0", "ab 0 EUR", 4),
          NumberColumn("bestand_0_5", "ab 500.000", 4),
          NumberColumn("bestand_2_5", "ab 2,5 Mio", 4),
          NumberColumn("bestand_5", "ab 5 Mio", 4),
          NumberColumn("bestand_10", "ab 10 Mio", 4),
          NumberColumn("bestand_25", "ab 25 Mio", 4),
          ],   
        };
     
    },
    tableRows () {
      return this.fondswerte || [];
    },

    filterMetadata() {
         return [
            {
              type: 'group',
              key: 'lagerstellen',
              label: 'Lagerstellen',
              menuItems: this.lagerstellen || []
            },               
          ];  
    }
  },
  mounted() {
    this.retrieveLagerstellen();
  },
  methods: {
    retrieveData(suchGesellschaft) {
      this.loading = true;

      this.$store.dispatch(
        COURTAGELISTE_TYPES.ACTIONS.RETRIEVE_FONDSWERTE, {suchGesellschaft}
            ).then(() => {
                this.loading = false;
            });  

    },
    retrieveLagerstellen() {
      this.loading = true;

      this.$store.dispatch(
        COURTAGELISTE_TYPES.ACTIONS.RETRIEVE_LAGERSTELLEN, 
            ).then(() => {
                this.loading = false;
            });  

    },    
    filterForm(filterConfig) {
      const lagerstellen = filterConfig.find(fc => fc.group=="lagerstellen");

      this.retrieveData(lagerstellen?.key) ;
    }
  },
  async beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu Übersicht Konditionen', 
      fullPath: from.fullPath,
      breadcrumb: to?.meta?.breadcrumb?.label,
    });

    this.$store.commit(COURTAGELISTE_TYPES.MUTATIONS.RESET_LIST);

    next();
  },


    
}
</script>