var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "topElement" }, [
    _vm.askCount
      ? _c("div", [
          _c("p", [
            _vm._v(
              "Die Anzahl der Datensätze übersteigt " +
                _vm._s(_vm.isIntern ? 1000 : 25) +
                " Zeilen."
            )
          ]),
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.pinSenden()
                }
              }
            },
            [
              _c(
                "div",
                [
                  _vm._v(
                    "Um die Daten zu sehen, benötigen Sie eine PIN, die gibt Ihnen die Berechtigung für die derzeitige Sitzung, auch größere Listen zu sehen. "
                  ),
                  _c("InputField", {
                    staticClass: "input-pin",
                    attrs: { label: "PIN:", placeholder: "3-stellige Nummer" },
                    model: {
                      value: _vm.pin,
                      callback: function($$v) {
                        _vm.pin = $$v
                      },
                      expression: "pin"
                    }
                  }),
                  _c(
                    "BaseButton",
                    {
                      attrs: { disabled: _vm.disableSend },
                      on: {
                        click: function($event) {
                          return _vm.pinSenden()
                        }
                      }
                    },
                    [_vm._v("PIN senden")]
                  )
                ],
                1
              )
            ]
          ),
          _vm.isFilter
            ? _c("p", [
                _vm._v(
                  "Alternativ können Sie den Filter stärker eingrenzen, um weniger als " +
                    _vm._s(_vm.isIntern ? 1000 : 25) +
                    " passende Einträge zu bekommen."
                )
              ])
            : _vm._e(),
          _c("p", { staticClass: "fc-form-danger" }, [
            _vm.askCount == 1
              ? _c("span", [
                  _vm._v(
                    "PIN Eingabe war Falsch. Sie haben noch zwei Versuche."
                  )
                ])
              : _vm.askCount == 2
              ? _c("span", [
                  _vm._v(
                    "PIN Eingabe war Falsch. Sie haben noch einen Versuch."
                  )
                ])
              : _vm.askCount == 3
              ? _c("span", [
                  _vm._v("PIN Eingabe war Falsch. PIN wurde blockiert.")
                ])
              : _vm._e()
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }