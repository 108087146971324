var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-input-index-kombination__container" },
    [
      _c("InputToggleSwitch", {
        staticClass: "pb-2",
        attrs: {
          label: _vm.label,
          inLineLabel: "",
          disabled: _vm.disabled,
          config: { forceValueAsString: true }
        },
        on: {
          input: function($event) {
            return _vm.onActiveInput($event)
          }
        },
        model: {
          value: _vm.form.active,
          callback: function($$v) {
            _vm.$set(_vm.form, "active", $$v)
          },
          expression: "form.active"
        }
      }),
      _c(
        "div",
        { staticClass: "form-input-index-kombination--indexes" },
        [
          _c("InputField", {
            staticClass: "pb-0",
            attrs: {
              label: "Bezeichnung",
              disabled: _vm.disabled || !_vm.isActive,
              isComponentHalfSize: "",
              trimValue: ""
            },
            on: {
              change: function($event) {
                return _vm.onLabelChange($event)
              }
            },
            model: {
              value: _vm.form.label,
              callback: function($$v) {
                _vm.$set(_vm.form, "label", $$v)
              },
              expression: "form.label"
            }
          }),
          _vm._l(_vm.INDEXES_SIZE, function(_, index) {
            return _c(
              "div",
              { key: index, staticClass: "forms__input--half-size" },
              [
                _c(
                  "div",
                  { staticClass: "row my-0" },
                  [
                    _c("ComboBox", {
                      staticClass: "col pb-2",
                      attrs: {
                        label: "Index " + (index + 1),
                        firstEmpty: "",
                        values: _vm.indexValues,
                        disabled: _vm.disabled || !_vm.isActive
                      },
                      on: {
                        change: function($event) {
                          return _vm.onIndexChange(index, $event)
                        }
                      },
                      model: {
                        value: _vm.form.indexes[index],
                        callback: function($$v) {
                          _vm.$set(_vm.form.indexes, index, $$v)
                        },
                        expression: "form.indexes[index]"
                      }
                    }),
                    _c("InputField", {
                      staticClass: "col",
                      attrs: {
                        label: "Gewichtung",
                        type: "percent",
                        disabled: _vm.disabled || !_vm.isActive
                      },
                      on: {
                        change: function($event) {
                          return _vm.onGewichtungChange(index, $event)
                        }
                      },
                      model: {
                        value: _vm.form.gewichtung[index],
                        callback: function($$v) {
                          _vm.$set(_vm.form.gewichtung, index, $$v)
                        },
                        expression: "form.gewichtung[index]"
                      }
                    })
                  ],
                  1
                )
              ]
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }