<template>
<div class="ergebnis-risk-return-chart">
  <div>
    <div class="box__container">
      <BaseButton class="m-1" @click="aktualisieren" :disabled="loading">
        Aktualisieren
      </BaseButton>
      <BaseButton class="m-1" @click="applyAllocation" :disabled="!applicable || loading">
        Verteilung übernehmen
      </BaseButton>
    </div>

    <div v-if="loading">
      <GhostLoading class="box__container">
        <Block height="150" />
      </GhostLoading>
      <GhostLoading class="box__container">
        <Block height="350" />
      </GhostLoading>
    </div>
    <div v-else ref="optimierung">
      <Table class="box__container" v-if="selected.name"
				:title="selected.name"
        :headers="headers"
        :rows="rows"
        rowId="name"
				hidePagination
      />
      <ScatterChart  class="box__container"
        yAxisName="Historische Rendite %"
        xAxisName="Historisches Risiko %"
        height="500px"
        :series="eChartSeries"
        :tooltip="eChartTooltip"
        :changeOption="changeOption"
        @click="eChartOnMarkerClick($event)"
      />
    </div> 
  </div>
</div>
</template>
<script>
import INVESTMENT_ADVICE from "@/store/investmentAdvice/types";
import BaseButton from "@/components/core/BaseButton.vue";
import ScatterChart from '@/components/charts/echarts/ScatterChart.vue';
import { mapGetters } from "vuex";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import { formatNumber } from '@/helpers/number-formatter.js';
import Table from "@/components/table2/Table.vue";
import {TextColumn, PercentageColumn} from "@/components/table2/table_util.js";
import {COLOR_STANDARD} from '@/components/charts/chartFondsUtil.js';


export default {
  props: {
    adviceId: '',
  },
  data() {
    return {
      loading: false,
      selected: {},
      headers: {
        lockedLeft: [
          TextColumn('name', 'Name'),
        ],
        center: [
          PercentageColumn('proz', 'Anteil'),
        ],
      },
      rows: [],
      applicable: false,
    }
  },
  components:{
    BaseButton, ScatterChart, GhostLoading, Block, Table
  },
   
  computed: {
    ...mapGetters({
      optimization: INVESTMENT_ADVICE.GETTERS.OPTIMIZATION
    }),
    series() {
      const series = this.optimization?.data?.chartData?.series || [];
      return series.map((s) => {
        const serie = Object.assign({}, s);
        delete serie.color;
        return serie;
      });
    },
    eChartSeries() {
      const result = this.series.map(s => ({
        ...s,
        data: s.data.map(d => ({
          name: d.label,
          value: [d.x, d.y],
        })),
        symbol: ( {'Depot-Optimierung':'pin', 'Aktuelles Depot':'rect'})[s.name] || 'circle',
        symbolSize: ( {'Depot-Optimierung': 25, 'Aktuelles Depot':10})[s.name] || 10,
        itemStyle: {
          color: this.getColor(s.name)
        },
      })).filter(s => s.data?.length);
      if (process.env.VUE_APP_USER === 'hannes') console.log({eChartSeries: JSON.parse(JSON.stringify(result))}, {series: JSON.parse(JSON.stringify(this.series))} );
      return result;
    },
    eChartTooltip() {
      return {
        trigger: 'item',
        formatter: (params) => {
          const { seriesName, data } = params;

          return `
            <div><strong>${seriesName}</strong></div>
            <div><span>${data.name}</span></div>
            <div><span>Rendite ${formatNumber(data.value[1], 2)} %</span></div>
            <div><span>Risiko ${formatNumber(data.value[0], 2)} %</span></div>
          `
        },
      };
    },
  },

  methods:{
    getColor(key) {
      switch (key) {
        case 'Effiziente Portfolios':
          return COLOR_STANDARD[0];
        case 'Depotpositionen':
          return COLOR_STANDARD[1];
        case 'Depot-Optimierung':
          return COLOR_STANDARD[2];
        case 'Aktuelles Depot':
          return COLOR_STANDARD[3];
        default:
          return COLOR_STANDARD[4];
      }
    },
    onMarkerClick(e, chart, options) {
    },
    eChartOnMarkerClick(serie) {
      this.parseSelected(serie.seriesName, serie.data.name, serie.data.value[0], serie.data.value[1]);
    },
    aktualisieren() {
      this.chartDataLaden(true);
    },
    applyAllocation() {
      this.loading = true;
      this.$store
      .dispatch(INVESTMENT_ADVICE.ACTIONS.APPLY_OPTIMIZATION, {
        adviceId: this.adviceId,
        applydata: {allocation: this.selected.value},
        onError: this.onError
      });
      this.$emit('apply');
    },
    chartDataLaden(refresh) {
      this.loading = true;
      this.$store
      .dispatch(INVESTMENT_ADVICE.ACTIONS.FETCH_OUTCOME, {
        adviceId: this.adviceId,
        type: INVESTMENT_ADVICE.OUTCOME.OPTIMIZATION,
        refresh: refresh
      });
      
    },
    onError() {
      this.loading = false;
    },
    parseSelected(name, label, risiko, rendite) {
      const rows = [];
      if (name == 'Depotpositionen') {
        rows.push({name: label});
        if (this.headers.center){
          this.headers = {lockedLeft: [TextColumn('name', 'Name')]};
        }
      } else {
        if (!this.headers.center){
          this.headers = {lockedLeft: [TextColumn('name', 'Name')], center: [PercentageColumn('proz', 'Anteil')]};
        }
        const td = label.split('</')
          .filter(item => item.indexOf('<td') != -1)
          .map(item => item.replace(/^.*>/, '') );
        for (let k = 0; k < td.length - 1; k = k + 2) {
          if (td[k+1].indexOf('%') != -1) {
            rows.push({
                name: td[k],
                proz: td[k+1],
              }
            )
          }
        }
      }
      this.applicable = name == 'Effiziente Portfolios';
      this.selected = {
        name: (this.applicable ? 'Effizientes Portfolio' : name) 
        + ' Risiko: ' + formatNumber(risiko, 2) +' / Rendite: ' + formatNumber(rendite, 2),
        applicable: this.applicable,
        value: this.applicable ? label : '',
        rows, risiko, rendite
      };
      
      this.rows = rows;
    },
    changeOption(option) {
      if (process.env.VUE_APP_USER === 'hannes') console.log({changeOption: JSON.parse(JSON.stringify(option))} );
     if (option.legend) {
        option.legend.itemWidth = 15; 
        option.legend.itemHeight = 15;
      }
      if (option.yAxis) {
        option.yAxis.min = (value) => Math.floor(value.min - 1); 
        option.yAxis.max = (value) => Math.ceil(value.max + 1); 
      }
      if (option.tooltip?.position) {
        option.tooltip.position = undefined;
      }
      return option;
    }
  },

  watch: {
    optimization: function(o, n) {
      this.loading = false;
      this.selected = {};
      this.rows = [];
      if ( this.optimization?.data?.chartData?.series?.length) {
        this.optimization.data.chartData.series.forEach(serie => {
          if (serie.selectable && serie.name == 'Depot-Optimierung') {
            this.parseSelected(serie.name, serie.data[0].label, serie.data[0].x, serie.data[0].y);
          }
        })
        if (!this.rows.length) {
          this.optimization.data.chartData.series.forEach(serie => {
            if (serie.selectable && serie.name == 'Effiziente Portfolios') {
              this.parseSelected(serie.name, serie.data[0].label, serie.data[0].x, serie.data[0].y);
            }
          })
        }
      }
    }
  },

  mounted() {
    this.chartDataLaden(false);
  },
}
</script>
<style>
.ergebnis-risk-return-chart .apexcharts-legend {
  max-width: 100%;
}
.ergebnis-risk-return-chart .apexcharts-tooltip-text-value {
  margin: 0;
}
</style>