<template>
  <label class="checkbox__container" 
    :class="{'checkbox__check--disabled': disabled}">
    <span class="checkbox__body">
      <input type="checkbox" 
        :indeterminate="indeterminate"
        :checked="checked"
        :disabled="disabled"
        @input="onChange($event)">
      <span class="checkbox__check"></span>
    </span>
    <span 
      v-if="label" 
      class="checkbox__label" v-html="sanitize(label)" @click="$emit('click', $event)">
    </span>
  </label>
</template>

<script>
import { getBooleanValue } from '@/helpers/mapping'
import validatorComponentUtils from '@/mixins/validator/validator-component-utils'
import {sanitize} from '@/helpers/string-helper.js';

export default {
  mixins: [validatorComponentUtils],
  name: 'InputCheckbox',
  props: {
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Boolean, String, Object, Array],
      default: false
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value: function(newValue) {
      this.internalValue = newValue;
      this.$runValidator(this.internalValue);
    },
    disabled() {
      if (this.disabled) {
        this.$reset()
      } else { 
        this.$runValidator(this.internalValue)
      }
    }
  },
  computed: {
    checked() {
      const value = this.internalValue;
      const isPositiveRegex = /(1|j[a]?)/gi;
      return [1, true].some(available => available === value) || isPositiveRegex.test(value);
    }
  },
  methods: {
    sanitize(htmlString) {
        return sanitize(htmlString);
    },
    onChange(event) {
      const checked = event.target.checked
      const newValue = getBooleanValue(this.internalValue, checked);
      this.internalValue = newValue;
      
      this.$emit('input', this.internalValue);
      this.$runValidator(this.internalValue);
      this.$setDirty()
    },
  },
  data: () => {
    return {
      internalValue: null
    }
  },
  mounted() {
    this.internalValue = this.value
    this.$runValidator(this.internalValue, true)
  }
}
</script>

<style scoped>

.checkbox__container {
  box-sizing: border-box;
  display: flex;
}

.checkbox__body input {
  opacity: 0;
  margin: 0;
  padding: 0;
}

.checkbox__check {
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  border: 1px solid var(--color-text);
  border-radius: 4px;
}

.checkbox__body{
  flex: 0 0 18px;
  height: 18px;
  margin: 0 8px 0 0;
  position: relative;
}

.checkbox__check:before {
  display: none;
  content: "";
  border: solid var(--color-text);
  position: absolute;
}

.checkbox__body input[indeterminate] + .checkbox__check:before {
  width: 8px;
  height: 2px;
  left: 4px;
  bottom: 5px;
  border-width: 2px 0 0 0;
}

.checkbox__body input:checked + .checkbox__check:before {
  width: 3px;
  height: 8px;
  left: 6px;
  bottom: 4px;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkbox__body input:checked + .checkbox__check:before,
.checkbox__body input[indeterminate] + .checkbox__check:before {
  display: block;
}

.checkbox__body input:focus + .checkbox__check,
.checkbox__body input:enabled + .checkbox__check:hover {
  /* box-shadow: 0px 0px 3px var(--color-text); */
  outline: none;
}

.checkbox__check--disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

</style>