var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _c("div", { staticClass: "box__title" }, [
        _vm._v("Vermögensschadenhaftpflicht")
      ]),
      _c("FormDivider"),
      _c("FormLabel", {
        attrs: {
          label:
            "Anforderung: Gesetzlich vorgeschriebene Vermögensschadenhaftpflichtversicherung."
        }
      }),
      _c("InputField", {
        attrs: {
          value: _vm.form.INPUT_VERSICHERT_BEI_TEXT,
          label: "Versichert bei:",
          isComponentHalfSize: "",
          disabled: _vm.disabledStatusOK
        },
        on: {
          change: function($event) {
            return _vm.fieldChanged($event, "INPUT_VERSICHERT_BEI_TEXT")
          }
        }
      }),
      _c("InputField", {
        attrs: {
          value: _vm.form.INPUT_POLIZZENNR_TEXT,
          label: "Polizzen Nummer:",
          isComponentHalfSize: "",
          disabled: _vm.disabledStatusOK
        },
        on: {
          change: function($event) {
            return _vm.fieldChanged($event, "INPUT_POLIZZENNR_TEXT")
          }
        }
      }),
      _c("DatePickerField", {
        attrs: {
          label: "gültig bis:",
          value: _vm.form.INPUT_GUELTIG_BIS_TEXT,
          isValueAsString: "",
          isComponentHalfSize: "",
          disabled: _vm.disabledStatusOK
        },
        on: {
          change: function($event) {
            return _vm.fieldChanged($event, "INPUT_GUELTIG_BIS_TEXT")
          }
        }
      }),
      _c("FormLabel", {
        attrs: { label: "Zur Dokumentation lege ich die Polizze bei." }
      }),
      _c("FormLabel", {
        attrs: {
          label:
            "Bitte die letztgültige Polizze anführen und beilegen, sowie gegebenenfalls die Zahlungsbestätigung."
        }
      }),
      _c("ChecklisteDocsUpload", {
        attrs: {
          paramId: this.form.PARAM_ID,
          topic: "Vermögensschadenhaftpflicht",
          form: this.form
        }
      }),
      _c("FormDivider"),
      _c("InputToggleSwitch", {
        attrs: {
          label:
            "Ich bestätige hiermit, die Angaben wahrheitsgetreu getätigt zu haben.",
          inLineLabel: true,
          config: { bold: true },
          isComponentHalfSize: "",
          disabled: _vm.disabledStatusOK,
          value: true
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }