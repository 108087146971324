var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pagination" }, [
    _c(
      "ul",
      { staticClass: "fc-pagination" },
      [
        _c("li", { class: { "page-disabled": _vm.currentPage <= 0 } }, [
          _c(
            "a",
            { on: { click: _vm.onClickPreviousPage } },
            [
              _c("PhCaretLeft", { attrs: { size: 16 } }),
              _c("span", { staticClass: "hide-on-small-screen ml-2" }, [
                _vm._v("zurück")
              ])
            ],
            1
          )
        ]),
        _vm._l(_vm.bigScreenList, function(pageId) {
          return [
            _c(
              "li",
              {
                key: pageId,
                staticClass: "hide-on-small-screen",
                class: {
                  "page-disabled": _vm.currentPage == pageId,
                  "page-active": _vm.currentPage == pageId
                }
              },
              [
                pageId < 0
                  ? _c("span", { staticClass: "dotdotdot" }, [_vm._v("...")])
                  : _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            return _vm.onClickPage(pageId)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(pageId + 1) + " ")]
                    )
              ]
            )
          ]
        }),
        _vm._l(_vm.smallScreenList, function(pageId) {
          return [
            _c(
              "li",
              {
                key: "small" + pageId,
                staticClass: "hide-on-large-screen",
                class: {
                  "page-disabled": _vm.currentPage == pageId,
                  "page-active": _vm.currentPage == pageId
                }
              },
              [
                pageId < 0
                  ? _c("span", { staticClass: "dotdotdot" }, [_vm._v("...")])
                  : _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            return _vm.onClickPage(pageId)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(pageId + 1) + " ")]
                    )
              ]
            )
          ]
        }),
        _c(
          "li",
          { class: { "page-disabled": _vm.currentPage >= _vm.totalPages - 1 } },
          [
            _c(
              "a",
              { on: { click: _vm.onClickNextPage } },
              [
                _c("span", { staticClass: "hide-on-small-screen mr-2" }, [
                  _vm._v("weiter")
                ]),
                _c("PhCaretRight", { attrs: { size: 16 } })
              ],
              1
            )
          ]
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }