var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "box__container" }, [
      _c("div", { staticClass: "input-forms__label-content" }, [
        _vm._v("Lineare Wertentwicklung (p.a.)")
      ]),
      _vm.initialized
        ? _c(
            "div",
            _vm._l(
              _vm.stepParameters.data.parameterData.lineareWertentwicklung,
              function(wertentwicklung, index) {
                return _c(
                  "div",
                  { key: "LW" + index, class: { "mt-16px": index > 0 } },
                  [
                    _c("input-field", {
                      attrs: {
                        label:
                          index === 0 && false
                            ? "Lineare Wertentwicklung (p.a.)"
                            : "",
                        type: "percent",
                        isComponentHalfSize: true
                      },
                      on: { change: _vm.markModified },
                      model: {
                        value:
                          _vm.stepParameters.data.parameterData
                            .lineareWertentwicklung[index],
                        callback: function($$v) {
                          _vm.$set(
                            _vm.stepParameters.data.parameterData
                              .lineareWertentwicklung,
                            index,
                            $$v
                          )
                        },
                        expression:
                          "stepParameters.data.parameterData.lineareWertentwicklung[index]"
                      }
                    })
                  ],
                  1
                )
              }
            ),
            0
          )
        : _vm._e()
    ]),
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("InputChips", {
          staticClass: "p-0 indices",
          attrs: {
            label: "Indizes",
            availableOptions: _vm.indizesValues,
            showToggle: true
          },
          on: { input: _vm.markModified },
          model: {
            value: _vm.selectedIndizes,
            callback: function($$v) {
              _vm.selectedIndizes = $$v
            },
            expression: "selectedIndizes"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _vm.initialized
          ? _c("ComboBox", {
              attrs: {
                label: "Musterdepot",
                values: _vm.sources,
                isComponentHalfSize: true,
                sortComboboxValues: false
              },
              on: { change: _vm.markModified },
              model: {
                value: _vm.myDepotId,
                callback: function($$v) {
                  _vm.myDepotId = $$v
                },
                expression: "myDepotId"
              }
            })
          : _vm._e()
      ],
      1
    ),
    _c("div", { staticClass: "box__container" }, [
      _c("div", { staticClass: "box__title" }, [_vm._v("Zusammensetzung")]),
      _vm.initialized
        ? _c(
            "div",
            _vm._l(
              _vm.stepParameters.data.parameterData.indexKombinations,
              function(wertentwicklung, index) {
                return _c(
                  "div",
                  { key: "K" + index },
                  [
                    _c(
                      "div",
                      { staticClass: "mt-3 mb-2" },
                      [
                        _c("InputToggleSwitch", {
                          attrs: {
                            label:
                              _vm.stepParameters.data.parameterData
                                .indexKombinations[index].name,
                            inLineLabel: true
                          },
                          on: {
                            input: function($event) {
                              _vm.markModified()
                              _vm.resetIndexKombinationValues(index)
                            }
                          },
                          model: {
                            value:
                              _vm.stepParameters.data.parameterData
                                .indexKombinations[index].active,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.stepParameters.data.parameterData
                                  .indexKombinations[index],
                                "active",
                                $$v
                              )
                            },
                            expression:
                              "stepParameters.data.parameterData.indexKombinations[index].active"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("InputField", {
                          attrs: {
                            label: "Bezeichnung",
                            isComponentHalfSize: "",
                            disabled: !_vm.isIndexKombinationActive(index),
                            trimValue: ""
                          },
                          on: { input: _vm.markModified },
                          model: {
                            value:
                              _vm.stepParameters.data.parameterData
                                .indexKombinations[index].label,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.stepParameters.data.parameterData
                                  .indexKombinations[index],
                                "label",
                                $$v
                              )
                            },
                            expression:
                              "stepParameters.data.parameterData.indexKombinations[index].label"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._l(
                      _vm.stepParameters.data.parameterData.indexKombinations[
                        index
                      ].indizes,
                      function(wertentwicklung, i) {
                        return _c(
                          "div",
                          {
                            key: "KI" + i,
                            staticClass: "forms__input--half-size"
                          },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-12 col-sm-8 mb-2 mb-sm-0" },
                                [
                                  _c("ComboBox", {
                                    attrs: {
                                      label: "Index " + (i + 1),
                                      firstEmpty: true,
                                      values:
                                        _vm.stepParameters.data.indexAuswahl,
                                      disabled: !_vm.isIndexKombinationActive(
                                        index
                                      )
                                    },
                                    on: { change: _vm.markModified },
                                    model: {
                                      value:
                                        _vm.stepParameters.data.parameterData
                                          .indexKombinations[index].indizes[i],
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.stepParameters.data.parameterData
                                            .indexKombinations[index].indizes,
                                          i,
                                          $$v
                                        )
                                      },
                                      expression:
                                        "stepParameters.data.parameterData.indexKombinations[index].indizes[i]"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-12 col-sm-4" },
                                [
                                  _c("InputField", {
                                    attrs: {
                                      label: "Gewichtung",
                                      type: "percent",
                                      disabled: !_vm.isIndexKombinationActive(
                                        index
                                      )
                                    },
                                    on: { change: _vm.markModified },
                                    model: {
                                      value:
                                        _vm.stepParameters.data.parameterData
                                          .indexKombinations[index].gewichtung[
                                          i
                                        ],
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.stepParameters.data.parameterData
                                            .indexKombinations[index]
                                            .gewichtung,
                                          i,
                                          $$v
                                        )
                                      },
                                      expression:
                                        "stepParameters.data.parameterData.indexKombinations[index].gewichtung[i]"
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          ]
                        )
                      }
                    )
                  ],
                  2
                )
              }
            ),
            0
          )
        : _vm._e()
    ]),
    _vm.debug
      ? _c("div", [
          _vm._v(
            "BM " +
              _vm._s(_vm.id) +
              " params: " +
              _vm._s(_vm.stepParameters.data)
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }