var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", { attrs: { title: _vm.getPageTitle } }),
      _vm.tarifdatenVorgabe && _vm.tarifdatenVorgabe.length
        ? _c(
            "div",
            { attrs: { tid: "5414fd93-6e3c-4b11-850c-380d6a5dd64c" } },
            [
              _c("StepperForm", {
                ref: "stepperForm",
                attrs: {
                  stepType: "tab",
                  stepperName: "tarifdaten-vorgabe",
                  stepperMediator: _vm.tabsStepperMediator,
                  hasFertig: false,
                  selectedStepKey: _vm.selectedStepKey
                },
                on: { "set-step": _vm.setStepByKey },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "contentTemplate",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            { staticClass: "box__container" },
                            _vm._l(_vm.getStepComponents, function(component) {
                              return _c("div", { key: component.key }, [
                                _c(
                                  "div",
                                  { staticClass: "col-9 col-sm-4" },
                                  [
                                    component.type == "TEXT"
                                      ? _c("InputField", {
                                          attrs: {
                                            label: component.bezeichnung,
                                            disabled: component.disabled
                                          },
                                          model: {
                                            value: component.value,
                                            callback: function($$v) {
                                              _vm.$set(component, "value", $$v)
                                            },
                                            expression: "component.value"
                                          }
                                        })
                                      : _vm._e(),
                                    component.type == "TEXT_CURRENCY"
                                      ? _c("InputField", {
                                          attrs: {
                                            label: component.bezeichnung,
                                            type: "currency",
                                            disabled: component.disabled
                                          },
                                          model: {
                                            value: component.value,
                                            callback: function($$v) {
                                              _vm.$set(component, "value", $$v)
                                            },
                                            expression: "component.value"
                                          }
                                        })
                                      : _vm._e(),
                                    component.type == "TEXT_NUMBER"
                                      ? _c("InputField", {
                                          attrs: {
                                            label: component.bezeichnung,
                                            type: "number",
                                            disabled: component.disabled
                                          },
                                          model: {
                                            value: component.value,
                                            callback: function($$v) {
                                              _vm.$set(component, "value", $$v)
                                            },
                                            expression: "component.value"
                                          }
                                        })
                                      : _vm._e(),
                                    component.type == "DATE_PICKER"
                                      ? _c("DatePickerField", {
                                          attrs: {
                                            label: component.bezeichnung,
                                            disabled: component.disabled
                                          },
                                          model: {
                                            value: component.value,
                                            callback: function($$v) {
                                              _vm.$set(component, "value", $$v)
                                            },
                                            expression: "component.value"
                                          }
                                        })
                                      : _vm._e(),
                                    component.type == "SINGLE_SELECTION"
                                      ? _c("InputRadioBoxGroup", {
                                          attrs: {
                                            title: component.bezeichnung,
                                            validateUntouched: true,
                                            values: component.possibleValues
                                          },
                                          model: {
                                            value: component.value,
                                            callback: function($$v) {
                                              _vm.$set(component, "value", $$v)
                                            },
                                            expression: "component.value"
                                          }
                                        })
                                      : _vm._e(),
                                    component.type == "SWITCHER"
                                      ? _c("InputToggleSwitch", {
                                          staticClass:
                                            "parameter-search__input-toggle-switch",
                                          attrs: {
                                            label: component.bezeichnung,
                                            disabled: component.disabled
                                          },
                                          model: {
                                            value: component.value,
                                            callback: function($$v) {
                                              _vm.$set(component, "value", $$v)
                                            },
                                            expression: "component.value"
                                          }
                                        })
                                      : _vm._e(),
                                    component.type == "COMBOBOX"
                                      ? _c("ComboBox", {
                                          attrs: {
                                            label: component.bezeichnung,
                                            values: component.possibleValues,
                                            firstEmpty: false,
                                            disabled: component.disabled
                                          },
                                          model: {
                                            value: component.value,
                                            callback: function($$v) {
                                              _vm.$set(component, "value", $$v)
                                            },
                                            expression: "component.value"
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ])
                            }),
                            0
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  1735258687
                )
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }