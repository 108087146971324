import Vue from 'vue'
import {formatNumber} from '@/helpers/number-formatter.js';
import dayjs from 'dayjs';
import currencyMap from './assets/currency-map.json';


export function toDateString(value) {
    if (typeof value === 'number') // handles millisecond timestamp
        value = new Date(value);
    if (typeof value == 'string' && dayjs(value, 'DD.MM.YYYY HH:mm:ss', true).isValid()) // handles timestamps values
        value = dayjs(value, 'DD.MM.YYYY HH:mm:ss', true).toDate();
    if (typeof value?.getMonth === 'function') // handles Date objects
        return value.toLocaleDateString('de-DE',  { day: '2-digit', month: '2-digit', year: 'numeric' });
    if (typeof value == 'string') // handles null values
        return value;
    return '';
}
Vue.filter("date", toDateString)

export function toDateTimeString(value) {
    if (typeof value === 'number') // handles millisecond timestamp
        value = dayjs(new Date(value)).format('DD.MM.YYYY HH:mm');
    if (typeof value?.getMonth === 'function') // handles Date objects
        return dayjs(value).format('DD.MM.YYYY HH:mm');
    if (typeof value == 'string') // handles null values
        return value;
    return '';
}
Vue.filter("datetime", toDateTimeString)

export function toCurrencyString(value, precision = 2, currency = 'EUR') {
    if (value == null || value === "")
        return "";
    if (isNaN(value))
        return value
    return formatNumber(value, precision) + currencyMap[currency].symbol;
}
Vue.filter("currency", toCurrencyString)

export function toPercentageString(value, precision = 2) {
    if (value == null || value === "")
        return "";
    if (isNaN(value))
        return value
    return formatNumber(value, precision) + ' %'
}
Vue.filter("percent", toPercentageString)



export function titleCasing(value) {
    if (value == null)
        return ""
    if (typeof(value) != "string")
        return value
    return value
    .split(" ")
    .map(word => {
        if (word.length < 2)
            return word
        const match = /\p{L}/u.exec(word);
        if (!match)
            return word
        return word.substring(0, match.index + 1).toUpperCase() + word.substring(match.index + 1).toLowerCase()
    }).join(" ");
}
Vue.filter("titleCasing", titleCasing)


