export const MUTATION_PREFIX = 'EX_POST_MANUELLE: ';
export const ACTIONS_PREFIX = 'EX_POST_MANUELLE_ACTIONS_';
export const GETTERS_PREFIX = 'EX_POST_MANUELLE_GETTERS_';

export default {
  MUTATIONS: {
    SET_LIST: MUTATION_PREFIX + 'SET_LIST',
    SET_COMBOS: MUTATION_PREFIX + 'SET_COMBOS',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
  },
  ACTIONS: {
    FIND_LIST: ACTIONS_PREFIX + 'FIND_LIST',
    SAVE: ACTIONS_PREFIX + 'SAVE',
    DELETE: ACTIONS_PREFIX + 'DELETE',
    FIND_COMBOS: ACTIONS_PREFIX + 'FIND_COMBOS',
  },
  GETTERS: {
    LIST: GETTERS_PREFIX + 'LIST',
    COMBOS: GETTERS_PREFIX + 'COMBOS',
    IS_COMBOS_LOADED: GETTERS_PREFIX + 'IS_COMBOS_LOADED',
  },
}
