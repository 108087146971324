<template>
  <div>
    <InputField
      label="Einnahmen aus unselbständiger Arbeit (Gehaltsabrechnung / netto pro Monat)"
      v-model="salary"
      type="currency"
      :disabled="isDisabled || isRefusedPage"
      isComponentHalfSize
      @input="updateStore('finance', 'salary', $event || 0)" />
    <InputField
      label="Einnahmen aus selbständiger Arbeit (laut Einkommensteuerbescheid / netto pro Monat)"
      v-model="freelancerRevenue"
      type="currency"
      :disabled="isDisabled || isRefusedPage"
      isComponentHalfSize
      @input="updateStore('finance', 'freelancerRevenue', $event || 0)" />
    <InputField
      label="Einnahmen aus Kapitalvermögen (z.B. Zinsen, Dividenden, Ausschüttungen pro Monat)"
      v-model="capitalRevenue"
      type="currency"
      :disabled="isDisabled || isRefusedPage"
      isComponentHalfSize
      @input="updateStore('finance', 'capitalRevenue', $event || 0)" />
    <InputField
      label="Sonstiges (z.B. Unterhaltsbeträge, Mieteinnahmen usw. pro Monat)"
      v-model="otherRevenue"
      type="currency"
      isComponentHalfSize
      :disabled="isDisabled || isRefusedPage"
      @input="updateStore('finance', 'otherRevenue', $event || 0)" />
    <div class="sum d-flex justify-content-between">
      <div class="my-2">Summe des derzeit regelmäßigen Einkommens (mtl.)</div>
      <div class="my-2 pr-3"><CurrencyLabel :value="summeEinkommen"/></div>
    </div>
  </div>
</template>

<script>

import InputField from '@/components/core/forms/InputField.vue';
import anlegerprofilMixin from '@/mixins/anlegerprofil/anlegerprofil-mixin.js';
import CurrencyLabel from '@/components/core/CurrencyLabel.vue';

export default {
  components: {
    InputField,
    CurrencyLabel,
  },
  mixins: [anlegerprofilMixin],
  data() {
    return {
      salary: '',
      freelancerRevenue: '',
      capitalRevenue: '',
      otherRevenue: '',
    };
  },
  watch: {
    finance(value) {
      this.updateForm();
    }
  },
  mounted() {
    this.updateForm();
    this.updateWarnings();
  },
  computed: {
    isRefusedPage() {
      return this._isRefusedPage(this.data, 'finanzen');
    },
    finance() {
      return this.data?.finance || {};
    }
  },
  methods: {
    updateForm() {
      this.salary = typeof this.finance.salary === 'number' ? this.finance.salary : ''
      this.freelancerRevenue = typeof this.finance.freelancerRevenue === 'number' ? this.finance.freelancerRevenue : ''
      this.capitalRevenue = typeof this.finance.capitalRevenue === 'number' ? this.finance.capitalRevenue : ''
      this.otherRevenue = typeof this.finance.otherRevenue === 'number' ? this.finance.otherRevenue : ''
    },
  },
}
</script>

<style scoped>
  .sum {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    font-size: 1.25rem;
    font-weight: bold;
  }
</style>