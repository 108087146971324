import MITARBEITER_STRUKTUR_TYPES from './types';
import axios from 'axios';

export default {
  [MITARBEITER_STRUKTUR_TYPES.ACTIONS.RETRIEVE_MITARBEITER_STRUKTUR]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/vermittlerstruktur/getMitarbeiterstruktur';
  
      axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
        if (response && response.data) {
  
          commit(MITARBEITER_STRUKTUR_TYPES.MUTATIONS.RETRIEVE_MITARBEITER_STRUKTUR_SUCCESS, response.data);
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });



  },

 

}