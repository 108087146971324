<template>
  <div>
    <FlexibleList
      :rows="rows"
      showThirdRow
      :linkInFirstRow="isEditable"
      @onRowClick="onRowClick"
    >
      <template #title="row">
        <span>{{ row.name }}</span>
      </template>

      <template #value="row">
        {{ row.erstellt_am || "./." }}
      </template>

      <template #thirdRow="row">
        {{ row.bezeichnung }}
      </template>
    </FlexibleList>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CREDIT_TYPES from "@/store/credit/types";
import CORE_TYPES from "@/store/core/types";

import { UPLOAD_TYPE } from "@/views/documents/AdditionalDocuments.vue";
import FlexibleList from "@/components/flexibleList/FlexibleList.vue";
import DOKUMENTENARCHIV_TYPES from '@/store/dokumentenarchiv/types';

export default {
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      credit: CREDIT_TYPES.GETTERS.CREDIT,
      isTestCustomer: CORE_TYPES.GETTERS.IS_TEST_CUSTOMER,
      additionalDocuments: DOKUMENTENARCHIV_TYPES.GETTERS.ADDITIONAL_DOCUMENTS,
    }),
    nodeId() {
      return this.credit?.nodeId || "";
    },
    fileId() {
      return this.credit?.fileId || "";
    },
    isReady() {
      return !!this.nodeId && !!this.fileId;
    },
    uploadType() {
      return UPLOAD_TYPE.DOKUMENTE_HINZUFUGEN;
    },
    rows() {
        return (this.additionalDocuments?.tableRows || []).map(row => ({
            ...row,
        }));
    },    
    isEditable() {
        return true;
    },
    fileIdEncoded() {
      return encodeURIComponent(this.fileId);
    },
  },
  mounted() {
      this.retrieveAdditionalDocuments();
  },
  methods: {
    async retrieveAdditionalDocuments() {
      if(!this.nodeId)
        return;

      this.loading = true;
      await this.$store.dispatch(DOKUMENTENARCHIV_TYPES.ACTIONS.RETRIEVE_ADDITIONAL_DOCUMENTS, {
        nodeId: this.nodeId,
        fileId: this.fileIdEncoded,
      });
      this.loading = false;
    },
    onRowClick(whatRow) {
      //alert(JSON.stringify(whatRow));
      // Edit window must be implemented for the broker perspective in the app view
    },
  },
  components: {
    FlexibleList,
  },
};
</script>