<template>
  <div class="broker-overview__container">
    <PageHeaderActionCard
      :showHeaderCard="false">
    </PageHeaderActionCard>

    <div class="box__container" tid="ef19d4b5-ec24-4968-bcda-8dcc8a5d8ae0">
      <div class="d-flex justify-content-between">
        <div>
          <span class="box__title">Aktivitäten / Termine</span>
        </div>
      </div>
      <div v-if="!isSmallScreen">
        <div class="layout__negative-margin--8" v-if="isBrokerOrBypass">
          <BaseButton @click="newTask" tid="79c06e76-b793-4a67-93b8-8efe5f89d794"
            isSecondary>
              Neue Aufgabe
          </BaseButton>

        </div>
      </div>
    </div>
   
    <div class="box__container" tid="1a50adc9-d591-4604-931c-d171c1eee47a">
      <div class="box__title">Aktivitäten</div>
        <div v-if="brokerActivities">
            <div v-if="rows.length">
                <Table
                    :headers="headers"
                    :mobileConfig="{title: 'Aktivitäten / Termine', headers: ['status', 'label', 'begin']}"
                    :rows="rows"
                    rowId="index"
                    :rowsPerPage="20"
                    @click-label="openDayView"
                />
            </div>
            <NoData v-else content="Keine Aktivitäten vorhanden" />
      </div>
      <GhostLoading v-else-if="loading" type="table" />
    </div>
  </div>
</template>


<script>
import { mapGetters } from 'vuex';
import Table from "@/components/table2/Table.vue";
import {TextColumn, DateColumn, CurrencyColumn, PillColumn} from "@/components/table2/table_util.js";
import dayjs from 'dayjs'
import CALENDAR_TYPES from '@/store/calendar/types';
import BROKERDATA_TYPES from '@/store/brokerData/types'
import CORE_TYPES from '@/store/core/types'
import PageHeaderActionCard from '@/components/core/PageHeaderActionCard.vue';
import AppointmentEditCreate from '@/components/calendar/appointmentFiles/AppointmentEditCreate.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import {isActivityTypeBereich} from '@/store/calendar/actions'
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import EVENT_BUS, { TERMINE_NEUE_AUFGABE} from '@/event-bus';

export default {
  mixins: [InteractiveHelpCommonsMixin],
  components: {
    PageHeaderActionCard,
    Table,
    AppointmentEditCreate,
    BaseButton,
    BaseModal,
    GhostLoading,
    NoData,
  },
  data() {
    return {
        columnActionsTermine: [],
        isActivity: false,
        headers: {
            lockedLeft: [
                PillColumn("status", "Status").makeAlwaysVisible(),
                TextColumn("label", "Betreff").makeAlwaysVisible().makeLink(),
                DateColumn("begin", "Zeitpunkt").makeAlwaysVisible(),
            ],
            center: [
                TextColumn("bereich", "Bereich"),
                TextColumn("text", "Text"),
                TextColumn("art", "Art"),
                TextColumn("bereichDisplay", "Adressat"),
                TextColumn("zuordnungKundennr", "Beteiligte Kunden"),
                TextColumn("ownerName", "Besitzer"),
                DateColumn("end", "Erstellungsdatum"),
                CurrencyColumn("geschaeftwert", "Geschätzter Geschäftswert"),
                TextColumn("produkt", "Produkt"),
            ],
        },
        loading: false,
        currentMode: 'Makler',
    }
  },
  mounted() {
    this.getActivities();
    this.registerEventBus();

  },
  methods: {
    registerEventBus() {
      EVENT_BUS.$on(TERMINE_NEUE_AUFGABE, () => {
        this.newTask();
      });

    },   
    unregisterEventBus() {
      EVENT_BUS.$off(TERMINE_NEUE_AUFGABE);
    },     
    isActivityType(event) {
      return event && (isActivityTypeBereich(event.bereich) && (!!event.art || !event.end)) && event.bereich !== 'Termin';
    },
    openDayView(data) {
      if (!this.isBrokerOrBypass) {
        return
      }
      const thisday = dayjs(data.begin, 'D.M.YYYY HH:mm');
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_DAY_ELEMENTS, {
        day: {
          label: "",
          day: thisday,
          inMonth: true,
          isToday: false,
          isSelected: true,
        },
        dayIndex: dayjs(thisday).weekday(),
        appointment: data.sid,
      });
      this.$store.dispatch(
        CALENDAR_TYPES.ACTIONS.RETRIEVE_SELECTED_APPOINTMENT,
        data.sid
      );
      this.isActivity = this.isActivityType(data)
      this.openAppointment();
    },
     /* getTermine(){
         this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.FETCH_TERMINE);
    }, */
      getActivities(){
         this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.FETCH_ACTIVITIES);
    },
    async getWeekCalendarData() {
      try {
        this.loading = true;
         await this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.FETCH_ACTIVITIES);
       /*  await this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.FETCH_TERMINE); */
       
      } finally {
        this.loading = false;
      }
    },
    renewView() {
      this.getActivities();
      this.getWeekCalendarData();
    },
    getCurrentMode(bereich) {
      /* if (!bereich){
        bereich = this.getSelectedEvent?.appointment?.bereich;
      }
      this.currentMode = '';
      if (bereich) {
        this.currentMode = (bereich === 'Termin' || bereich === 'Aufgabe' ? bereich : 'Aktivität')
      } */
    },
    newTask() {
      this.isActivity = true;
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT, {activity: true});
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_NEW_AUFGABE);
      this.getCurrentMode('Aufgabe');
      this.openAppointment();
    },
    newAppointment() {
      this.isActivity = false;
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT, {activity: false});
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_NEW_TERMIN);
      this.getCurrentMode('Termin');
      this.openAppointment();
    },
    openAppointment() {
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_CONFIG, {
        title: `${this.isActivity ? 'Aufgabe' : 'Termin'} bearbeiten`,
        mode: this.currentMode,
        isActivity: this.isActivity,
  
        back: this.$router.currentRoute.fullPath,
      })
      this.$router.push({ path: `/communication/appointment` });
    },
    getStatusObj(value) {
      return value.hook || {};
    },
    getStatus(value) {
      switch (this.getStatusObj(value).color) {
        case 'green':
          return 'erledigt';
        case 'blue':
          return 'wird versendet';
        case 'yellow':
          return 'Handlung notwendig';
        default:
          return '';
      }
    },
    getStatusType(value) {
      switch (this.getStatusObj(value).color) {
        case 'green':
          return 'success';
        case 'blue':
          return 'info';
        case 'yellow':
          return 'warning';
        default:
          return '';
      }
    },
  },
  computed: {
    ...mapGetters({
      isTestBroker: CORE_TYPES.GETTERS.IS_TEST_BROKER,
      brokerAppointments: BROKERDATA_TYPES.GETTERS.GET_TERMINE,
      brokerActivities: BROKERDATA_TYPES.GETTERS.GET_ACTIVITIES,
      isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
      getSelectedEvent: CALENDAR_TYPES.GETTERS.GET_SELECTED_APPOINTMENT_DATA,
      isSmallScreen: CORE_TYPES.GETTERS.IS_SMALL_SCREEN,
    }),
    rows() {
        return (this.brokerActivities || []).map((row, index) => {
            let status = null;
            if (row.hook) {
                status = {
                    label: this.getStatus(row),
                    type: this.getStatusType(row),
                };
            }
            return {
                index,
                ...row,
                status
            }
        });
    },
  },
  destroyed() {
    this.unregisterEventBus();
  },  
  beforeRouteLeave (to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu Aktivitäten / Termine', 
      to,
      from,
    });

    next()
  },

}
</script>

<style scoped>
.grid-2 {
  flex: 1 1 100%;
  display: flex;
  align-items: stretch;
  align-content: stretch;
}
.grid-2 > div {
  width: 50%;
  flex: 1;
  flex-grow: 1;
  position: relative;
}
.grid-2 > div:first-child {
  margin-right: 24px;
}
@media screen and (max-width: 1280px) {
  .grid-2 > div:first-child,
  .grid-2 > div {
    margin-right: 0;
    flex: 1;
    width: 100%;
  }
  .grid-2 {
    display: block;
  }
}
</style>