var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "box__container",
        attrs: { tid: "973fc88f-1075-4ce9-8363-667e6301b508" }
      },
      [
        _vm.loading || !_vm.rows.length
          ? _c("div", { staticClass: "box__title" }, [
              _vm._v(" " + _vm._s(_vm.title) + " ")
            ])
          : _vm._e(),
        !_vm.loading && _vm.rows.length
          ? _c("Table", {
              attrs: {
                tableId: _vm.tableConfigId,
                title: _vm.title,
                headers: _vm.headers,
                rows: _vm.rows,
                mobileConfig: {
                  title: "gesellschaft",
                  headers: ["jahr", "nummer", "rw"]
                },
                rowId: "index",
                hidePagination: ""
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "gesellschaft",
                    fn: function(row) {
                      return [
                        !row.mobileTableContext
                          ? _c(
                              "div",
                              { staticClass: "insurance__table--company" },
                              [
                                row.gesellschaft.logoUrl
                                  ? _c("BaseImage", {
                                      staticClass:
                                        "insurance__table--company__logo",
                                      attrs: {
                                        src:
                                          _vm.apiAddressLegacy +
                                          "/" +
                                          row.gesellschaft.logoUrl,
                                        placeholder: _vm.imagePlaceholder
                                      }
                                    })
                                  : _vm._e(),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      row.gesellschaft.zweigstelleName ||
                                        row.gesellschaft.shortName
                                    ) +
                                    " "
                                )
                              ],
                              1
                            )
                          : _c("div", [
                              _vm._v(
                                _vm._s(
                                  row.gesellschaft.zweigstelleName ||
                                    row.gesellschaft.shortName
                                )
                              )
                            ])
                      ]
                    }
                  }
                ],
                null,
                false,
                2598483147
              )
            })
          : _vm.loading
          ? _c("GhostLoading", { attrs: { type: "table" } })
          : _c("NoData")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }