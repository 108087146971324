import FONDSFINDER_TYPES from './types';
import CORE_TYPES from '../core/types';
import ANTRAG_TYPES from '../antrag/types';
import LOG_TYPES from '@/store/log/types';
import axios from 'axios';
import { buildMessage } from '../../helpers/log-message-helper';

const config = {
  defaultSpinner: true
};

export default {
  [FONDSFINDER_TYPES.ACTIONS.GET_FONDS_V2]({ commit, rootState }, payload) {

    axios.post(`${rootState.core.apiAddress}/../mrsfondsfinderantrag`, payload, config).then(response => {
      if (response.status === 200 && response.data.fonds) {
        commit(FONDSFINDER_TYPES.MUTATIONS.GET_FONDS_V2_SUCCESS, { fonds: response.data.fonds })
      }
    });
  },
  
  async [FONDSFINDER_TYPES.ACTIONS.GET_COMBOBOX_SELECTIONS_V2]({ commit, rootState }, payload) {
    try {
      const param = Object.keys(payload)
        .map(key => `${key}=${payload[key]}`)
        .join('&');

      const response = await axios.get(`${rootState.core.apiAddress}/../mrsfondsfinderantrag?${param}`)

      if (response && response.data && response.data.comboboxSelections) {
        commit(FONDSFINDER_TYPES.MUTATIONS.GET_COMBOBOX_SELECTIONS_V2_SUCCESS, {
          comboboxSelections: response.data.comboboxSelections
        });
      }

    } catch (error) {
      
    }
  },

  async [FONDSFINDER_TYPES.ACTIONS.TAKE_SELECTED_ISINS]({ commit, state, getters, rootState, dispatch }, payload) {
    try {
      const result = await axios.post(`${rootState.core.apiAddress}/../mrsantragposition`, payload.checkPositions, config);

      if (result && result.data && result.data.errorMessage) {
        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(result.data.errorMessage, 'danger'));
      } else {
        if (payload?.positionInfo?.length) {
          const addPayload = {
            antragTyp: payload.antragTyp,
            saveType: payload.saveType,
            aenderungType: payload.aenderungType,
            id: payload.antragId,
            categoryId: payload.categoryId,
            fonds: payload.positionInfo,
            singleSelection: payload.singleSelection,
            additionalIdentifier: payload.additionalIdentifier
          }
          if (state.storedDepotPosition.altPositionInfo){
            addPayload.fonds.forEach(element => {
              element.isinAlt = state.storedDepotPosition.altPositionInfo[0].isin;
              element.aenderungTyp = state.storedDepotPosition.aenderungTyp;
            });
          }
          commit(FONDSFINDER_TYPES.MUTATIONS.ADD_FONDS_INFO, { positionInfo: payload.positionInfo })
          if (payload.antragTyp && payload.antragId) {
            await dispatch(ANTRAG_TYPES.ACTIONS.UPDATE_SELECTION, addPayload);
          }
        }
      }
    } catch (error) {
      
    }
  },

  async [FONDSFINDER_TYPES.ACTIONS.GET_FUNDS_CHART_DATA]({ getters }, { isinList, years }) {
    if(!isinList?.length) return {};
    const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}/mrsfundschartdata`, { isin: isinList.join(','), years: (years || 1)}, config);
    return response?.data || {};
  },

  async [FONDSFINDER_TYPES.ACTIONS.GET_MARKTUBERSICHT_CHART_DATA]({ getters, dispatch, commit }, { isinList, years }) {
    const loaded = Object.keys(getters[FONDSFINDER_TYPES.GETTERS.MARKTUBERSICHT_CHART_DATA]).length > 0;
    if(loaded && years <= 1) return;

    const response = await dispatch(FONDSFINDER_TYPES.ACTIONS.GET_FUNDS_CHART_DATA, { isinList, years});
    commit(FONDSFINDER_TYPES.MUTATIONS.GET_MARKTUBERSICHT_CHART_DATA_SUCCESS, {
      chartData: response?.chart?.length && response?.chart[0] || {},
    });
  },

}