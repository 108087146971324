var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.loading && _vm.rows.length
        ? _c("Table", {
            attrs: {
              tableId: "9ec3921e-1cfd-4027-843a-808f524ffi98",
              title: _vm.title,
              headers: _vm.headers,
              rows: _vm.rows,
              hidePagination: _vm.hasLimit,
              mobileConfig: _vm.mobileConfig
            },
            on: {
              "action-DOWNLOAD_EXCEL": _vm.handleExcel,
              "action-DOWNLOAD_PDF": _vm.handlePDF
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "text",
                  fn: function(ref) {
                    var text = ref.text
                    var gelesen = ref.gelesen
                    return [
                      _c("div", [
                        !gelesen
                          ? _c("b", [_vm._v(_vm._s(text))])
                          : _c("span", [_vm._v(_vm._s(text))])
                      ])
                    ]
                  }
                }
              ],
              null,
              false,
              3094269300
            )
          })
        : _vm.loading
        ? _c("GhostLoading", {
            attrs: {
              type: "table",
              title: _vm.title,
              config: { table: { rows: _vm.limit || 3 } }
            }
          })
        : _c("NoData", { attrs: { noIcon: _vm.hasLimit, title: _vm.title } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }