var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "hour-cell",
      attrs: { id: "hour-cell-id", "data-hour": _vm.hour }
    },
    [
      _vm.ifExistsEvent
        ? _c(
            "div",
            { staticClass: "parent" },
            [
              _c("HourEventDiv", {
                attrs: {
                  contentarray: _vm.getCell,
                  dimensions: _vm.dimensions,
                  width: _vm.getNewWidth,
                  events: _vm.allHourEvents[_vm.hour]
                },
                on: { editEvent: _vm.editEvent }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }