var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: _vm.title, subtitle: "Einstellungen" }
      }),
      _c(
        "div",
        { staticClass: "box__container d-lg-none col-12" },
        [
          _c("Stepper2", {
            ref: "pieStepper",
            attrs: {
              stepType: "step",
              visible: true,
              selectedStepKey: _vm.stepKey,
              stepperService: _vm.stepper
            },
            on: { setStep: _vm.setStepByKey, setSubstep: _vm.setSubstepByKey }
          })
        ],
        1
      ),
      _c("AntragSidetext", {
        attrs: {
          warnings: _vm.warnings,
          hinweiseProps: _vm.hinweise,
          steps: _vm.stepper.getCurrentStepList(),
          showMessageErrorMustBeFixed: "",
          showExpanded: _vm.showExpanded,
          highestStepVisited: 4
        },
        on: {
          setStep: function($event) {
            return _vm.setStepByKey($event)
          },
          setSubstep: function($event) {
            return _vm.setSubstepByKey($event)
          }
        }
      }),
      _c("ContentWithStepper", {
        scopedSlots: _vm._u(
          [
            _vm.stepper
              ? {
                  key: "stepper",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        { staticClass: "box__container" },
                        [
                          _c("VerticalStepper", {
                            ref: "verticalStepper",
                            staticClass: "responsive-stepper",
                            attrs: {
                              stepType: "step",
                              visible: true,
                              selectedStepKey: _vm.stepKey,
                              selectedSubstepKey: _vm.substepKey,
                              stepperService: _vm.stepper,
                              showCollapse: true
                            },
                            on: {
                              setStep: _vm.setStepByKey,
                              setSubstep: _vm.setSubstepByKey
                            }
                          })
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              : null,
            {
              key: "content",
              fn: function() {
                return [
                  _c("div", [_c("router-view")], 1),
                  _c("div", { staticClass: "box__container" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-12 col-sm-6 col-md-4 col-xl-3 pr-3 mt-1"
                        },
                        [
                          _vm.hasPrevBtn
                            ? _c(
                                "BaseButton",
                                {
                                  attrs: { isBlock: "", isSecondary: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.prevStep()
                                    }
                                  }
                                },
                                [_c("span", [_vm._v("Zurück")])]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm.hasNextBtn
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "col-12 col-sm-6 col-md-4 col-xl-3 pr-3 mt-1"
                            },
                            [
                              _c(
                                "BaseButton",
                                {
                                  attrs: { isBlock: "", isPrimary: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.nextStep()
                                    }
                                  }
                                },
                                [_c("span", [_vm._v("Weiter")])]
                              )
                            ],
                            1
                          )
                        : _c(
                            "div",
                            {
                              staticClass:
                                "col-12 col-sm-6 col-md-4 col-xl-3 pr-3 mt-1"
                            },
                            [
                              _c(
                                "BaseButton",
                                {
                                  attrs: { isBlock: "", isPrimary: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.goToStrategie()
                                    }
                                  }
                                },
                                [_c("span", [_vm._v("Schließen")])]
                              )
                            ],
                            1
                          )
                    ])
                  ])
                ]
              },
              proxy: true
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }