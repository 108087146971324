var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.erlaubeEsgSchwach
        ? _c("InputToggleSwitch", {
            attrs: {
              label:
                "Die Geeignetheitsprüfung hat ergeben, dass keine Anlagen verfügbar sind, die den ursprünglichen Nachhaltigkeitspräferenzen des Kunden entsprechen. Aus diesem Grunde hat der Kunde für die vorliegende Beratung seine Nachhaltigkeitspräferenzen angepasst.",
              inLineLabel: "",
              disabled: _vm.isDisabledProp
            },
            on: {
              input: function($event) {
                return _vm.updateStore(
                  "check_nachhaltigkeit_abweichend",
                  $event
                )
              }
            },
            model: {
              value: _vm.check_nachhaltigkeit_abweichend,
              callback: function($$v) {
                _vm.check_nachhaltigkeit_abweichend = $$v
              },
              expression: "check_nachhaltigkeit_abweichend"
            }
          })
        : _vm._e(),
      _c("InputRadioBoxGroup", {
        attrs: {
          title: "Der Kunde hat",
          labelClass: "font-bold",
          disabled:
            _vm.isDisabledProp ||
            (_vm.erlaubeEsgSchwach &&
              (_vm.check_nachhaltigkeit_abweichend === false ||
                _vm.check_nachhaltigkeit_abweichend === "0")),
          validateUntouched: true,
          values: [
            { value: "1", label: "die folgenden Nachhaltigkeitspräferenzen" },
            { value: "0", label: "keine Nachhaltigkeitspräferenzen" }
          ]
        },
        on: {
          input: function($event) {
            return _vm.updateStore("input_esg_aktiv", $event)
          }
        },
        model: {
          value: _vm.input_esg_aktiv,
          callback: function($$v) {
            _vm.input_esg_aktiv = $$v
          },
          expression: "input_esg_aktiv"
        }
      }),
      _vm.erlaubeEsgSchwach
        ? _c("div", { staticClass: "mb-3" }, [
            _vm._v(
              "Ausgestaltung der Nachhaltigkeitspräferenzen des Kunden (Kombinationen zwischen A, B und C sind möglich, D schließt hingegen A-C aus)"
            )
          ])
        : _vm._e(),
      !_vm.erlaubeEsgSchwach
        ? _c("div", { staticClass: "mb-3" }, [
            _vm._v(
              "Ausgestaltung der Nachhaltigkeitspräferenzen des Kunden (Kombinationen zwischen A, B und C sind möglich)"
            )
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("InputToggleSwitch", {
            attrs: {
              disabled: _vm.disabled || _vm.inputSchwachAktiv,
              config: { bold: true },
              label: "A Taxonomie",
              inLineLabel: "",
              validateUntouched: ""
            },
            on: {
              input: function($event) {
                return _vm.updateStore("input_esg_taxo_aktiv", $event)
              }
            },
            model: {
              value: _vm.input_esg_taxo_aktiv,
              callback: function($$v) {
                _vm.input_esg_taxo_aktiv = $$v
              },
              expression: "input_esg_taxo_aktiv"
            }
          }),
          _c("div", { staticClass: "mb-3" }, [
            _vm._v(
              "Investitionen in ökologisch nachhaltige Tätigkeiten, die mit messbaren Bewertungskriterien einen wesentlichen Beitrag zu einem der mehreren Umweltziele/n leisten."
            )
          ]),
          _vm.input_esg_taxo_aktiv === true || _vm.input_esg_taxo_aktiv === "1"
            ? _c(
                "div",
                [
                  _c("InputRadioBoxGroup", {
                    attrs: {
                      title: "Mindestanteil",
                      validateUntouched: true,
                      disabled:
                        _vm.disabled ||
                        !_vm.input_esg_taxo_aktiv ||
                        _vm.inputSchwachAktiv,
                      values: _vm.radioValuesTaxo
                    },
                    on: {
                      input: function($event) {
                        return _vm.updateStore("input_esg_taxo_perc", $event)
                      }
                    },
                    model: {
                      value: _vm.input_esg_taxo_perc,
                      callback: function($$v) {
                        _vm.input_esg_taxo_perc = $$v
                      },
                      expression: "input_esg_taxo_perc"
                    }
                  }),
                  _vm.input_esg_taxo_perc === "CUSTOM"
                    ? _c("InputField", {
                        attrs: {
                          type: "percent",
                          isComponentHalfSize: "",
                          disabled:
                            _vm.disabled ||
                            (!_vm.input_esg_taxo_aktiv &&
                              _vm.input_esg_taxo_perc !== "CUSTOM") ||
                            _vm.inputSchwachAktiv
                        },
                        on: {
                          change: function($event) {
                            return _vm.updateStoreNumber(
                              "input_esg_taxo_perc_custom",
                              $event
                            )
                          }
                        },
                        model: {
                          value: _vm.input_esg_taxo_perc_custom,
                          callback: function($$v) {
                            _vm.input_esg_taxo_perc_custom = $$v
                          },
                          expression: "input_esg_taxo_perc_custom"
                        }
                      })
                    : _vm._e(),
                  _c("div", [
                    _vm._v(
                      "Maximal erreichbarer Anteil: " +
                        _vm._s(_vm.label_esg_taxo_max) +
                        "%"
                    )
                  ]),
                  _c("div", [
                    _vm._v(
                      "Anzahl Wertpapiere gewählter Anteil: " +
                        _vm._s(_vm.label_esg_taxo_anzahl)
                    )
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("InputToggleSwitch", {
            attrs: {
              disabled: _vm.disabled || _vm.inputSchwachAktiv,
              config: { bold: true },
              label: "B SFDR",
              inLineLabel: "",
              validateUntouched: ""
            },
            on: {
              input: function($event) {
                return _vm.updateStore("input_esg_sfdr_aktiv", $event)
              }
            },
            model: {
              value: _vm.input_esg_sfdr_aktiv,
              callback: function($$v) {
                _vm.input_esg_sfdr_aktiv = $$v
              },
              expression: "input_esg_sfdr_aktiv"
            }
          }),
          _c("div", { staticClass: "mb-3" }, [
            _vm._v(
              "Investitionen, die einen einfachen Beitrag zur Erreichung von ökologischen und/oder sozialen Nachhaltigkeitszielen leisten."
            )
          ]),
          _vm.input_esg_sfdr_aktiv === true || _vm.input_esg_sfdr_aktiv === "1"
            ? _c(
                "div",
                [
                  _c("InputRadioBoxGroup", {
                    attrs: {
                      title: "Mindestanteil",
                      validateUntouched: true,
                      disabled:
                        _vm.disabled ||
                        !_vm.input_esg_sfdr_aktiv ||
                        _vm.inputSchwachAktiv,
                      values: _vm.radioValuesSfdr
                    },
                    on: {
                      input: function($event) {
                        return _vm.updateStore("input_esg_sfdr_perc", $event)
                      }
                    },
                    model: {
                      value: _vm.input_esg_sfdr_perc,
                      callback: function($$v) {
                        _vm.input_esg_sfdr_perc = $$v
                      },
                      expression: "input_esg_sfdr_perc"
                    }
                  }),
                  _vm.input_esg_sfdr_perc === "CUSTOM"
                    ? _c("InputField", {
                        attrs: {
                          type: "percent",
                          isComponentHalfSize: "",
                          validateUntouched: "",
                          disabled:
                            _vm.disabled ||
                            (!_vm.input_esg_sfdr_aktiv &&
                              _vm.input_esg_sfdr_perc !== "CUSTOM") ||
                            _vm.inputSchwachAktiv
                        },
                        on: {
                          change: function($event) {
                            return _vm.updateStoreNumber(
                              "input_esg_sfdr_perc_custom",
                              $event
                            )
                          }
                        },
                        model: {
                          value: _vm.input_esg_sfdr_perc_custom,
                          callback: function($$v) {
                            _vm.input_esg_sfdr_perc_custom = $$v
                          },
                          expression: "input_esg_sfdr_perc_custom"
                        }
                      })
                    : _vm._e(),
                  _c("div", [
                    _vm._v(
                      "Maximal erreichbarer Anteil: " +
                        _vm._s(_vm.label_esg_sfdr_max) +
                        "%"
                    )
                  ]),
                  _c("div", [
                    _vm._v(
                      "Anzahl Wertpapiere gewählter Anteil: " +
                        _vm._s(_vm.label_esg_sfdr_anzahl)
                    )
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("InputToggleSwitch", {
            attrs: {
              isComponentHalfSize: "",
              disabled: _vm.disabled || _vm.inputSchwachAktiv,
              config: { bold: true },
              label: "C PAIs",
              inLineLabel: "",
              validateUntouched: ""
            },
            on: {
              input: function($event) {
                return _vm.updateStore("input_esg_pai_aktiv", $event)
              }
            },
            model: {
              value: _vm.input_esg_pai_aktiv,
              callback: function($$v) {
                _vm.input_esg_pai_aktiv = $$v
              },
              expression: "input_esg_pai_aktiv"
            }
          }),
          _c("div", { staticClass: "mb-3" }, [
            _vm._v(
              "Investitionen, bei denen zumindest die wichtigsten nachteiligen Auswirkungen auf Nachhaltigkeitsfaktoren berücksichtigt werden."
            )
          ]),
          _vm.input_esg_pai_aktiv === true || _vm.input_esg_pai_aktiv === "1"
            ? _c(
                "div",
                [
                  _c("InputToggleSwitch", {
                    attrs: {
                      isComponentHalfSize: "",
                      disabled:
                        _vm.disabled ||
                        !_vm.input_esg_pai_aktiv ||
                        _vm.inputSchwachAktiv,
                      label: "Treibhausgasemissionen",
                      inLineLabel: "",
                      validateUntouched: ""
                    },
                    on: {
                      input: function($event) {
                        return _vm.updateStore(
                          "input_esg_pai_check_treibhaus",
                          $event
                        )
                      }
                    },
                    model: {
                      value: _vm.input_esg_pai_check_treibhaus,
                      callback: function($$v) {
                        _vm.input_esg_pai_check_treibhaus = $$v
                      },
                      expression: "input_esg_pai_check_treibhaus"
                    }
                  }),
                  _c("InputToggleSwitch", {
                    attrs: {
                      isComponentHalfSize: "",
                      disabled:
                        _vm.disabled ||
                        !_vm.input_esg_pai_aktiv ||
                        _vm.inputSchwachAktiv,
                      label: "Wasserverbrauch",
                      inLineLabel: "",
                      validateUntouched: ""
                    },
                    on: {
                      input: function($event) {
                        return _vm.updateStore(
                          "input_esg_pai_check_wasser",
                          $event
                        )
                      }
                    },
                    model: {
                      value: _vm.input_esg_pai_check_wasser,
                      callback: function($$v) {
                        _vm.input_esg_pai_check_wasser = $$v
                      },
                      expression: "input_esg_pai_check_wasser"
                    }
                  }),
                  _c("InputToggleSwitch", {
                    attrs: {
                      isComponentHalfSize: "",
                      disabled:
                        _vm.disabled ||
                        !_vm.input_esg_pai_aktiv ||
                        _vm.inputSchwachAktiv,
                      label: "Biodiversität",
                      inLineLabel: "",
                      validateUntouched: ""
                    },
                    on: {
                      input: function($event) {
                        return _vm.updateStore(
                          "input_esg_pai_check_biodivers",
                          $event
                        )
                      }
                    },
                    model: {
                      value: _vm.input_esg_pai_check_biodivers,
                      callback: function($$v) {
                        _vm.input_esg_pai_check_biodivers = $$v
                      },
                      expression: "input_esg_pai_check_biodivers"
                    }
                  }),
                  _c("InputToggleSwitch", {
                    attrs: {
                      isComponentHalfSize: "",
                      disabled:
                        _vm.disabled ||
                        !_vm.input_esg_pai_aktiv ||
                        _vm.inputSchwachAktiv,
                      label: "Abfälle",
                      inLineLabel: "",
                      validateUntouched: ""
                    },
                    on: {
                      input: function($event) {
                        return _vm.updateStore(
                          "input_esg_pai_check_abfall",
                          $event
                        )
                      }
                    },
                    model: {
                      value: _vm.input_esg_pai_check_abfall,
                      callback: function($$v) {
                        _vm.input_esg_pai_check_abfall = $$v
                      },
                      expression: "input_esg_pai_check_abfall"
                    }
                  }),
                  _c("InputToggleSwitch", {
                    attrs: {
                      isComponentHalfSize: "",
                      disabled:
                        _vm.disabled ||
                        !_vm.input_esg_pai_aktiv ||
                        _vm.inputSchwachAktiv,
                      label:
                        "Soziales & Arbeitnehmerbelange (inkl. Menschenrechte & Korruption)",
                      inLineLabel: "",
                      validateUntouched: ""
                    },
                    on: {
                      input: function($event) {
                        return _vm.updateStore(
                          "input_esg_pai_check_soziales",
                          $event
                        )
                      }
                    },
                    model: {
                      value: _vm.input_esg_pai_check_soziales,
                      callback: function($$v) {
                        _vm.input_esg_pai_check_soziales = $$v
                      },
                      expression: "input_esg_pai_check_soziales"
                    }
                  }),
                  _c("InputRadioBoxGroup", {
                    attrs: {
                      title: "Mindestanteil",
                      validateUntouched: true,
                      disabled:
                        _vm.disabled ||
                        !_vm.input_esg_pai_aktiv ||
                        _vm.inputSchwachAktiv,
                      values: _vm.DEFAULT_PROMILLE_ARRAY
                    },
                    on: {
                      input: function($event) {
                        return _vm.updateStore("input_esg_pai_perc", $event)
                      }
                    },
                    model: {
                      value: _vm.input_esg_pai_perc,
                      callback: function($$v) {
                        _vm.input_esg_pai_perc = $$v
                      },
                      expression: "input_esg_pai_perc"
                    }
                  }),
                  _vm.input_esg_pai_perc === "CUSTOM"
                    ? _c("InputField", {
                        attrs: {
                          type: "percent",
                          isComponentHalfSize: "",
                          validateUntouched: "",
                          disabled:
                            _vm.disabled ||
                            (!_vm.input_esg_pai_aktiv &&
                              _vm.input_esg_pai_perc !== "CUSTOM") ||
                            _vm.inputSchwachAktiv
                        },
                        on: {
                          change: function($event) {
                            return _vm.updateStoreNumber(
                              "input_esg_pai_perc_custom",
                              $event
                            )
                          }
                        },
                        model: {
                          value: _vm.input_esg_pai_perc_custom,
                          callback: function($$v) {
                            _vm.input_esg_pai_perc_custom = $$v
                          },
                          expression: "input_esg_pai_perc_custom"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.erlaubeEsgSchwach
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("InputToggleSwitch", {
                attrs: {
                  disabled: _vm.disabled,
                  config: { bold: true },
                  label: "D Keine konkrete Gewichtung",
                  inLineLabel: "",
                  validateUntouched: ""
                },
                on: {
                  input: function($event) {
                    return _vm.updateStore("input_esg_schwach_aktiv", $event)
                  }
                },
                model: {
                  value: _vm.input_esg_schwach_aktiv,
                  callback: function($$v) {
                    _vm.input_esg_schwach_aktiv = $$v
                  },
                  expression: "input_esg_schwach_aktiv"
                }
              }),
              _c("div", { staticClass: "mb-3" }, [
                _vm._v(
                  "Keine konkrete Gewichtung in den Kategorien A, B, C. Der Kunde wünscht zumindest eine Berücksichtigung einer der drei Kategorien, macht insoweit aber keine Vorgaben."
                )
              ]),
              _vm.input_esg_schwach_aktiv === true ||
              _vm.input_esg_schwach_aktiv === "1"
                ? _c(
                    "div",
                    { staticClass: "ml-5 mb-3" },
                    [
                      _c("InputRadioBoxGroup", {
                        attrs: {
                          title: "Mindestanteil",
                          validateUntouched: true,
                          disabled:
                            _vm.disabled || !_vm.input_esg_schwach_aktiv,
                          values: _vm.DEFAULT_PROMILLE_ARRAY
                        },
                        on: {
                          input: function($event) {
                            return _vm.updateStore(
                              "input_esg_schwach_perc",
                              $event
                            )
                          }
                        },
                        model: {
                          value: _vm.input_esg_schwach_perc,
                          callback: function($$v) {
                            _vm.input_esg_schwach_perc = $$v
                          },
                          expression: "input_esg_schwach_perc"
                        }
                      }),
                      _vm.input_esg_schwach_perc === "CUSTOM"
                        ? _c("InputField", {
                            attrs: {
                              type: "currency",
                              isComponentHalfSize: "",
                              validateUntouched: "",
                              disabled:
                                _vm.disabled ||
                                (!_vm.input_esg_schwach_aktiv &&
                                  _vm.input_esg_schwach_perc !== "CUSTOM")
                            },
                            on: {
                              change: function($event) {
                                return _vm.updateStoreNumber(
                                  "input_esg_schwach_perc_custom",
                                  $event
                                )
                              }
                            },
                            model: {
                              value: _vm.input_esg_schwach_perc_custom,
                              callback: function($$v) {
                                _vm.input_esg_schwach_perc_custom = $$v
                              },
                              expression: "input_esg_schwach_perc_custom"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }