var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "currency-label__container",
      class: { "currency-label__container--inline": _vm.showInlineLabel }
    },
    [
      _vm.label
        ? _c(
            "div",
            {
              staticClass: "currency-label__label",
              class: { "currency-label__label--regular": _vm.showInlineLabel }
            },
            [_vm._v(" " + _vm._s(_vm.label) + " ")]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "currency-label__number-container",
          class: _vm.containerClasses
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.formattedIntegerValue))]),
          _vm.precision > 0
            ? _c("span", [_vm._v(_vm._s(_vm.decimalSeparator))])
            : _vm._e(),
          _c("span", { staticClass: "currency-label__fractional-value" }, [
            _vm._v(_vm._s(_vm.fractionalValue))
          ]),
          _c("span", [_vm._v(" " + _vm._s(_vm.symb))]),
          _vm.info ? _c("span", [_vm._v(" " + _vm._s(_vm.info))]) : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }