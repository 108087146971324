<template>
  <div>
    <BaseExplorer
      :additionalActions="additionalActions"
      @headerAction-CONFIRM_SELECTION="handleMailComposer"
    />
  </div>
</template>

<script>
import BaseExplorer from "@/components/core/BaseExplorer.vue";
import MAILCOMPOSER_TYPES from "@/store/mailcomposer/types";
import { mapGetters } from 'vuex';
import DOKUMENTENARCHIV_TYPES from '@/store/dokumentenarchiv/types';
import { BaseModalSimpleAction } from '@/components/core/base-modal-actions/base-modal-actions-utils';

export default {
  mixins: [],
  beforeRouteLeave (to, from, next) {
    if (to.fullPath.includes('mailcomposer') && !to.fullPath.includes('dokumentenarchiv')) {
      this.$addBreadcrumb({
        label: 'zurück zur Kommunikationsübersicht',
        fullPath: '/communication/kommunikationsubersicht/',
        breadcrumb: 'E-Mail an Kunde ',
      });
    }
    next()
  },
  components: {
    BaseExplorer,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      selectedRows: DOKUMENTENARCHIV_TYPES.GETTERS.SELECTED_ROWS,
      isSelectedAllInFolder: DOKUMENTENARCHIV_TYPES.GETTERS.IS_SELECTED_ALL_IN_FOLDER,
    }),
    additionalActions() {
      return  [
        BaseModalSimpleAction('CONFIRM_SELECTION', 'Auswahl übernehmen')
          .withDisabled(() => !this.isSelectedAllInFolder && !this.selectedRows.some(row => row.exists)),
      ];
    },
  },
  methods: {
    async handleMailComposer(attachments) {
      if (attachments.length) {
        this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, attachments.map(v => ({...v, preloaded: true})));
      }
      if (this.$route.query && this.$route.query.back) {
        this.$router.push({ path: `${this.$route.query.back}${this.getBackOptions()}` });
      } else {
        this.$router.push({ path: `/communication/${this.$route.params.type}?backAction=true` })
      }
    },
    getBackOptions() {
      if (this.$route.query.back.includes('pruefstatus') || this.$route.query.back.includes('mailcomposer-insurance')) return '?backAction=true'
      if (this.$route.params.type.includes('calendar')) return '?usersselected=true'
      return ''
    },
  }
}
</script>