import { render, staticRenderFns } from "./CardFactory.vue?vue&type=template&id=4dfe7945&scoped=true&"
import script from "./CardFactory.vue?vue&type=script&lang=js&"
export * from "./CardFactory.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dfe7945",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("P:\\workspaces\\fk0\\fc\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4dfe7945')) {
      api.createRecord('4dfe7945', component.options)
    } else {
      api.reload('4dfe7945', component.options)
    }
    module.hot.accept("./CardFactory.vue?vue&type=template&id=4dfe7945&scoped=true&", function () {
      api.rerender('4dfe7945', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/dashboard/CoreCard/CardFactory.vue"
export default component.exports