<template>
  <BaseHeaderContainer ref="baseHeaderContainer" v-if="showBaseHeader">
    <StoppuhrModal/>
    <CallModal/>
    <IncomingCallDialog/>
    <HungUpCallDialog/>

    <div>
      <HeaderAlert />
      <BaseTopNavbar/>
      <BaseTopProgressBar />
      <BaseNavbarWorkspaces v-show="showWorkspaceHeader" />
      <IncomingCallTapiDialog/>
      <OutcomingCallTapiDialog/>
      <CallTapiModal/>
    </div>
  </BaseHeaderContainer>
</template>

<script>
import BaseHeaderContainer from '@/components/core/BaseHeaderContainer.vue';
import BaseNavbarWorkspaces from '@/components/core/navbar-workspaces/BaseNavbarWorkspaces.vue';
import BaseTopNavbar from '@/components/core/top-navbar/BaseTopNavbar.vue';
import CallModal from '@/components/webrtc/CallModal.vue';
import IncomingCallDialog from '@/components/webrtc/IncomingCallDialog.vue';
import HungUpCallDialog from '@/components/webrtc/HungUpCallDialog.vue';
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';
import BaseTopProgressBar from "@/components/core/BaseTopProgressBar.vue";
import HeaderAlert from '@/components/core/header/HeaderAlert.vue';
import TAPI from '@/store/tapi/types';
import IncomingCallTapiDialog from '@/components/tapi/IncomingCallTapiDialog';
import OutcomingCallTapiDialog from '@/components/tapi/OutcomingCallTapiDialog';
import CallTapiModal from '@/components/tapi/CallTapiModal';
import possiblePendingActions  from '@/configs/pending-actions.js'
import StoppuhrModal from '@/components/webrtc/StoppuhrModal'

export default {
  computed: {
    ...mapGetters({
      isLoggedIn: CORE_TYPES.GETTERS.IS_LOGGED_IN,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      brokerInformation: CORE_TYPES.GETTERS.GET_BROKER_INFORMATION,
      isPendingActionActiveFn: CORE_TYPES.GETTERS.IS_PENDING_ACTION_ACTIVE,
      canTapi: TAPI.GETTERS.CAN_TAPI,
    }),
    needsToChangePassword() {
      return this.isPendingActionActiveFn('changePassword') && possiblePendingActions.changePassword.path === this.$route.path;
    },
    showBaseHeader() {
      return this.$route.path !== '/login' && this.isLoggedIn && !this.needsToChangePassword;
    },
    showWorkspaceHeader() {
      return !this.$route.matched.some(({ meta }) => meta?.pageMenuCfg?.showWorkspaceHeader === false)
        && !this.isPendingActionActiveFn('checkPrivacy', 'kundeInteressenkonflikt');
    },
  },
  components: {
    BaseHeaderContainer,
    CallModal,
    IncomingCallDialog,
    HungUpCallDialog,
    BaseNavbarWorkspaces,
    BaseTopNavbar,
    BaseTopProgressBar,
    HeaderAlert,
    IncomingCallTapiDialog,
    OutcomingCallTapiDialog,
    CallTapiModal,
    StoppuhrModal,
    
  },
  watch: {
    showBaseHeader: {
      handler() {
        this.$refs.baseHeaderContainer?.setStyleProperties();
      },
      immediate: true
    }
  }
}
</script>
