<template>
    <div v-if="vertragsdatenFieldsDefinition">

    <FlexibleList :rows="rows">
      
      <template #title="row">
        <router-link :to="editPath(row)">Schadensnummer {{ row.schadennr || 'xxx' }} vom {{row.ereignisdatum || 'xx.xx.xxxx'}}</router-link>
      </template>

      <template #value="row">
        {{row.meldedatum  || 'xx.xx.xxxx'}}
      </template>

      <template #thirdRow="row">
        {{row.bearbstandLabel || row.schadensursacheText}}
      </template>      
    
    </FlexibleList>

    </div>
</template>


<script>
import BoxContainer from "@/components/core/BoxContainer.vue";
import { PhPencilLine, PhBookOpen } from 'phosphor-vue';
import {TextColumn} from "@/components/table2/table_util.js";
import Table from "@/components/table2/Table.vue";
import InsuranceDetailMixin from '@/components/versicherungen/InsuranceDetail-mixin.js'
import { mapGetters } from 'vuex'
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";
import CORE_TYPES from '@/store/core/types';
import SCHADENSMELDUNG_TYPES from "@/store/schadensmeldung/types";
import {toDateString, toDateTimeString} from '@/filters.js';
import FlexibleList from "@/components/flexibleList/FlexibleList.vue";


export default {
//   mixins: [InsuranceDetailMixin],
    components: {
        BoxContainer,
        PhPencilLine,
        PhBookOpen,
        Table,
        FlexibleList,
    },
    data: function () {
            return {
                title: "Allgemeine Vertragsdaten"
            }
    },
    computed: {
        ...mapGetters({
        versicherungenDetails: VERSICHERUNG_TYPES.GETTERS.INSURANCE_DETAILS,
        isFA: CORE_TYPES.GETTERS.IS_FA,
        isFK: CORE_TYPES.GETTERS.IS_FK,
        hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
        vertragsdatenFieldsDefinition: VERSICHERUNG_TYPES.GETTERS.INSURANCE_VERTRAGSDATEN_FIELDS_DEFINITION,
        schadensmeldungen: SCHADENSMELDUNG_TYPES.GETTERS.SCHADENSMELDUNG,
        }),
        schadenInfos() {
            return this.versicherungenDetails?.schadenInfos || [];
        },
        rows() {

            return this.schadenInfos.map(row => ({
            ...row
            }));
        },

        betreungsstufeComputedValues() {
            let betreungsstufeValues = this.vertragsdatenFieldsDefinition.betreuungsstufe.value;
            if('FPPLUS_GEWERBE' === this.vertragsdatenFieldsDefinition.anbindung.value[0].value || 'FPPLUS_GEWERBE' === this.versicherung.anbindung ) {
                //removing 'Ohne' value for FP+ Gewerbe GmbH
                betreungsstufeValues = this.vertragsdatenFieldsDefinition.betreuungsstufe.value.filter(item => item.value !== 'Ohne');
                return betreungsstufeValues;
            }
            return betreungsstufeValues;
        }


    },  
    methods: {
        currency(value) {
            if (isNaN(value)) {
                return value;
            }
            let val = (value / 1).toFixed(2).replace('.', ',');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' €';
        },
        dateToString(date) {
            return toDateString(date)
        }, 
         dateTimeToString(date) {
            return toDateTimeString(date)
        },
        editPath(schaden) {
            return `/home/versicherungen/insurance-group/schadensmeldung/${this.versicherungenDetails?.insurance?.id}/${schaden.schadenId}`;
        } 
    },
    mounted() {

    },
    validators: {},
};
</script>

<style scoped>
.align-end {
  flex: 1 1 auto;
  align-self: flex-end;
  padding: 0px 0px 16px 5px;
  color: var(--color-text);
}
.form-control {
  display: flex;
  align-content: stretch;
  align-items: center;
}



</style>