var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "antrag-step__container" },
      [
        _c("Stepper3", {
          attrs: {
            steps: _vm.steps,
            stepType: _vm.stepType,
            visible: _vm.showStepper,
            stepperName: _vm.stepperName,
            selectedStepKey: _vm.routeStep,
            selectedSubstepKey: _vm.routeSubStep,
            stepperService: _vm.getStepperMediator,
            antragData: _vm.antragData
          },
          on: {
            setStep: _vm.setStepOnMobile,
            setSubstep: _vm.setSubstepOnMobile
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }