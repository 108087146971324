var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: "Postfach", subtitle: "Gespräch" }
      }),
      _vm.talk
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _vm.isNew
                ? void 0
                : [
                    _vm.talk.speaker
                      ? _c("InputField", {
                          attrs: {
                            value: _vm.talk.speaker,
                            disabled: "",
                            label: "Gespräch von"
                          }
                        })
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "flex-direction": "row"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "50%",
                              "padding-right": "7px"
                            }
                          },
                          [
                            _c("DatePickerField", {
                              attrs: { label: "Datum" },
                              model: {
                                value: _vm.form.datum,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "datum", $$v)
                                },
                                expression: "form.datum"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: { width: "50%", "padding-left": "7px" }
                          },
                          [
                            _c("TimePicker", {
                              attrs: { label: "Uhrzeit (h:min)" },
                              model: {
                                value: _vm.form.startTime,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "startTime", $$v)
                                },
                                expression: "form.startTime"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c("TimePicker", {
                      attrs: { label: "Dauer (h:min:sec)", withSeconds: "" },
                      model: {
                        value: _vm.form.duration,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "duration", $$v)
                        },
                        expression: "form.duration"
                      }
                    }),
                    _c("InputField", {
                      attrs: {
                        value: _vm.userId(),
                        disabled: "",
                        label: _vm.nummerLabel()
                      }
                    })
                  ],
              _c("InputField", {
                attrs: {
                  value: _vm.userName(),
                  disabled: "",
                  label: _vm.nameLabel()
                }
              }),
              _c("ComboBox", {
                attrs: { values: _vm.categoryOptions, label: "Kategorie" },
                model: {
                  value: _vm.form.category,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "category", $$v)
                  },
                  expression: "form.category"
                }
              }),
              _c("InputField", {
                attrs: { label: "Thema" },
                model: {
                  value: _vm.form.topic,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "topic", $$v)
                  },
                  expression: "form.topic"
                }
              }),
              _c("InputTextArea", {
                staticClass: "text-input",
                attrs: { label: "Beschreibung", autoGrow: "" },
                model: {
                  value: _vm.form.description,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "description", $$v)
                  },
                  expression: "form.description"
                }
              })
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }