<template>
  <div class="beratung">
    <router-view></router-view>
  </div>
</template>
<script>
import BERATUNGSMAPPE_TYPES from "@/store/beratungsmappe/types";

export default {
  beforeRouteLeave(to, from, next) {
    this.$store.commit(BERATUNGSMAPPE_TYPES.MUTATIONS.RESET_DATA);
    next();
  },
}
</script>
