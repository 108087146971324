var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _vm.position && _vm.position.hasEditFields
          ? _c(
              "span",
              { staticClass: "col-6" },
              [
                _c("ph-pencil-line", {
                  staticClass: "clickable",
                  attrs: { size: 16 }
                })
              ],
              1
            )
          : _vm._e(),
        _vm.position && (_vm.position.isin || _vm.position.isinZu)
          ? _c(
              "span",
              { staticClass: "col-6" },
              [
                _c("ph-trash", {
                  staticClass: "clickable",
                  attrs: { size: 16 },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.openModalDeleteFond()
                    }
                  }
                })
              ],
              1
            )
          : _vm._e()
      ]),
      _c(
        "BaseModal",
        {
          ref: "deleteModal",
          attrs: {
            modalTitle: "Position entfernen?",
            labelButtonConfirm: "Position entfernen"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.onConfirmModal()
            },
            onCancelButton: function($event) {
              return _vm.onCancelModal()
            }
          }
        },
        [
          _c("div", [
            _vm._v(" Soll die Position "),
            _c("span", { staticClass: "font-bold" }, [
              _vm._v(_vm._s(_vm.fondDescription))
            ]),
            _vm._v(" wirklich entfernt werden? ")
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }