<template>
  <div>
    <BaseModal 
      size='xl'
      ref="editableInlineInput"
      modalTitle="Inhalt bearbeiten"
      :showCancelButton="true"
      :confirmDisabled="false"
      @onConfirmButton="onConfirmModal()">
      <div class="defaultcard-container" v-if="internalValue !== undefined">
        <span class="box__title">Inhalt des Eingabefeldes</span>
        <div class="d-flex">
          <div class="grow-1">
            <div class="d-inline-block placeholder-control" @click="showValuePlaceholder = !showValuePlaceholder">
              Platzhalter
              <PhCaretDown size="20" v-if="!showValuePlaceholder" />
              <PhCaretUp size="20" v-if="showValuePlaceholder" />
            </div>
          </div>
        </div>
        <div class="placeholders" v-if="showValuePlaceholder">
          <div class="d-flex justify-content-between">
            <div class="grow-1" :key="'p'+i" v-for="(p, i) in placeholdersOptions">
              <ComboBox :label="p.name" :values="p.data" :firstEmpty="true"
                @change="insertPlaceholder($event)"/>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <InputField
            v-model="internalValue"
            isComponentHalfSize/>
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import DYNAMIC_DOCUMENT_TYPES from '@/store/dynamicDocument/types';
import { mapGetters } from 'vuex'
import InputField from '@/components/core/forms/InputField.vue';
import { PhCaretUp, PhCaretDown } from 'phosphor-vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import BaseModal from "@/components/core/BaseModal.vue";

export default {
  mixins: [],
  components: {
    InputField,
    PhCaretUp,
    PhCaretDown,
    ComboBox,
    BaseModal,
  },
  data() {
    return {
      htmlView: '',
      showLabelPlaceholder: false,
      showValuePlaceholder: false,
      textToInsert: null,
      initialLabel: '',
    }
  },
  computed: {
    ...mapGetters({
      placeholders: DYNAMIC_DOCUMENT_TYPES.GETTERS.PLACEHOLDERS,
    }),
    placeholdersOptions() {
      const keys = this.placeholders && Object.keys(this.placeholders)
      return this.placeholders && Object.values(this.placeholders).map((v, i) => ({
        name: keys[i],
        data: v.map(p => ({
          value: Object.values(p)[0],
          label: Object.keys(p)[0]
        })
        )
      })) || []
    },
    internalValue: {
      get: function() {
        if (this.htmlView) {
          return this.calculateValue();
        }
        return undefined
      },
      set: function(modifiedValue) {
        modifiedValue = modifiedValue === '' ? '&nbsp;' : modifiedValue
      }
    },
  },
  mounted() {
    this.initialLabel = 'tesssst'//this.findLabelComp(this.editableComponent) && this.findLabelComp(this.editableComponent).content || ''
  },
  methods: {
    onConfirmModal() {
      // option1: emit
    },
    open(data) {
      this.htmlView = data;
      this.$refs.editableInlineInput.open()
      // this.internalValue = this.calculateValue()
    },
    replaceEmptyContent(s) {
      let html = s
      html = html.replace(/<br>/g, "<p style=\"text-align: left\"><\/p>");
      html = html.replace(/&nbsp;/g, '')
      return html;
    },
    calculateValue() {
      return this.htmlView.match(/value='(.*?)'/i)[1] || ''
    },
    findValueComp(component) {
      if (component.editable && component.value)
        return component.content
        
      const findItemNested = (arr, nestingKey) => (
        arr.reduce((a, item) => {
          if (a || item.htmlCardType || !item.editable) return a;
          if (item.value) return item;
          if (item[nestingKey]) return findItemNested(item[nestingKey], nestingKey)
        }, null)
      );
      if (component.htmlComponents && component.htmlComponents.length) {
        return findItemNested(component.htmlComponents, "htmlComponents");
      }
    },
    insertPlaceholder(event) {
      this.internalValue += event
    },
    getUpdatedHtml(event, component) {
      let html = event
      if (html === '<p style="text-align: left"></p>') {
        html = '';
      } else {
        html = html.replace(/<\/p><p style="text-align: left">/g, "<br>");
        html = html.replace(/<p style="text-align: left"><\/p>/g, "<br>");
        html = html.replace(/<p style="text-align: left">/g, "");
        html = html.replace(/<\/p>/g, "");
      }

      if (component) {
        component.content = this.htmlEncode(html)
      }
    },


    // special characters need to be converted to their html entity because the htmlConverter cannot use them
    htmlEncode(s){
      return s.replace(/„/g, "&bdquo;").replace(/“/g, "&ldquo;");
    },
    findLabelComp(component) {
      if (!component)
        return
      if (component.editable && component.label || (component.htmlCardType && component.htmlCardType.includes('TEXT')) || ((component.content || component.content === "")&& !component.htmlCardType && !component.label && !component.value))
        return component

      const findItemNested = (arr, nestingKey) => (
        arr.reduce((a, item) => {
          if (a || item.htmlCardType || !item.editable) return a;
          if (item.label || (component.content && !component.label && !component.value)) return item;
          if (item[nestingKey]) return findItemNested(item[nestingKey], nestingKey)
        }, null)
      );
      if (component.htmlComponents && component.htmlComponents.length) {
        return findItemNested(component.htmlComponents, "htmlComponents");
      }
    },
  },

}
</script>

<style scoped>
  .defaultcard-container {
    border: 1px solid;
    border-style: solid none none none;
    padding-top: 10px;
    margin-bottom:12px;
    margin-top: 12px;
  }
  .placeholders {
    display: flex;
    width: 100%;
    padding-top: 0.5rem;
  }
  .placeholder-control {
    padding: 0 0.5rem;
    cursor: pointer;
  }
  .d-flex .grow-1:last-child {
    padding-right: 0;
  }
  .grow-1 {
    flex-grow: 1;
    padding-right: 0.5em;
  }
</style>

<style>
  .defaultcard.hidden-editor .ProseMirror {
    display: none;
  }
</style>