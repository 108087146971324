var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "editModal",
          attrs: {
            modalTitle: "Warnung Deaktivieren (inkl. Kommentar)",
            showCancelButton: false
          },
          on: {
            onCloseButton: function($event) {
              return _vm.close()
            },
            close: function($event) {
              return _vm.close()
            }
          }
        },
        [
          _vm.loading
            ? _c(
                "GhostLoading",
                [
                  _c("Block", { attrs: { type: "title" } }),
                  _c("Block", { attrs: { type: "paragraph", height: "90" } })
                ],
                1
              )
            : _c(
                "div",
                [
                  _c("InputToggleSwitch", {
                    attrs: { label: "Deaktivieren" },
                    model: {
                      value: _vm.dokStornos.disableStornosBestaetigt,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.dokStornos,
                          "disableStornosBestaetigt",
                          $$v
                        )
                      },
                      expression: "dokStornos.disableStornosBestaetigt"
                    }
                  }),
                  _c("InputTextArea", {
                    attrs: {
                      label: "Kommentar",
                      disabled: !_vm.dokStornos.disableStornosBestaetigt
                    },
                    model: {
                      value: _vm.dokStornos.disableComment,
                      callback: function($$v) {
                        _vm.$set(_vm.dokStornos, "disableComment", $$v)
                      },
                      expression: "dokStornos.disableComment"
                    }
                  })
                ],
                1
              )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }