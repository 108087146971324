<template>
  <div @click="pressZins" class="box spec_button zins">Zins %</div>
</template>

<script>
//import this.$store from "./../../this.$store.js";
import functionMixins from "../../functionMixins.js";
export default {
  name: "Zins",
  mixins: [functionMixins],
  methods: {
    pressZins() {
      this.$store.commit("SET_OPERATORCLICKED", false);
      this.$store.commit("SET_KOMMACLICKED", false);
      if (
        this.$store.getters.getAnzahl !== "0" &&
        this.$store.getters.getJahre !== "0" &&
        this.$store.getters.getStart !== "0" &&
        this.$store.getters.getEnde !== "0"
      ) {
        var jahre = this.$store.getters.getJahre;
        var ende = this.$store.getters.getEnde;
        var start = this.$store.getters.getStart;
        var result = this.calcZinsfromStartEnde(start, ende, jahre);
        this.$store.commit("SET_ZINS", this.formatNumber(result));
        this.setRsLabels(start, ende);
      }
      else if (
        this.$store.getters.getAnzahl !== "0" &&
        this.$store.getters.getJahre !== "0" &&
        this.$store.getters.getRate !== "0" &&
        this.$store.getters.getStart !== "0"
      ) {
        jahre = this.$store.getters.getJahre;
        start = this.$store.getters.getStart;
        var rate = this.$store.getters.getRate;
        var anzahl = this.$store.getters.getAnzahl;
        result = this.calcZinsfromRateStart(start, rate, jahre, anzahl);
        result = Math.round(result * 100) / 100;
        this.$store.commit("SET_ZINS", this.formatNumber(result));
        ende = Number(rate) * Number(anzahl) * Number(jahre);
        ende = Math.round(ende * 100) / 100;
        this.setRsLabels(start, ende);
      } else if (
        this.$store.getters.getAnzahl !== "0" &&
        this.$store.getters.getJahre !== "0" &&
        this.$store.getters.getRate !== "0" &&
        this.$store.getters.getEnde !== "0"
      ) {
        jahre = this.$store.getters.getJahre;
        ende = this.$store.getters.getEnde;
        rate = this.$store.getters.getRate;
        anzahl = this.$store.getters.getAnzahl;
        result = this.calcZinsfromRateEnde(ende, rate, jahre, anzahl);
        result = Math.round(result * 100) / 100;
        start = Number(rate) * Number(anzahl) * Number(jahre);
        start = Math.round(start * 100) / 100;
        this.$store.commit("SET_ZINS", this.formatNumber(result));
        this.setRsLabels(start, ende);
      } else if (this.$store.getters.getCurrent !== "") {
        this.$store.commit("SET_ZINS", this.$store.getters.getCurrent);
        this.$store.commit("SET_CURRENT", "");
      }
    }
  }
};
</script>

<style scoped>
.minus {
  grid-area: zins;
}
</style>