var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "datenschutz_container" }, [
    _c(
      "div",
      { staticClass: "datenschutz_wrapper" },
      [
        _c("div", { staticClass: "banner" }, [_vm._v("DATENSCHUTZERKLÄRUNG")]),
        _c("hr"),
        _c("editable-frame", { attrs: { type: "datenschutz_text" } }),
        _c("br"),
        _vm.isIntern
          ? _c("div", { staticClass: "fc-alert fc-alert-warning" }, [
              _vm._v(
                "Hier kommt die für den Makler bestimmte Datenschutzerklärung."
              )
            ])
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }