var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "event-full-width" },
    [
      _vm._l(_vm.eventsGhost, function(event, index) {
        return [
          _c("HourEventVerticalDiv", {
            key: index,
            staticClass: "hour-event-vertical__container",
            attrs: {
              dimensions: _vm.getDimensions,
              verticalcontent: _vm.getVerticalcontent(event),
              index: index,
              contentarray: _vm.contentarray,
              ghost: event.ghost
            },
            on: { editEvent: _vm.editEvent }
          })
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }