import UndrawFinance from '@/components/icons/undraw/UndrawFinance.vue'
import * as undrawIcons from '@/components/icons/undraw';

export const MenuType = Object.freeze({
  PrimaryMenu: 'PrimaryMenu',
  MenuItem: 'MenuItem',
  GroupMenu: 'GroupMenu',
  TabMenu: 'TabMenu',
  AsyncMenu: 'AsyncMenu',
  CustomGroupMenu: 'CustomGroupMenu',
});

function menu(path, label, component, type, subMenu = []) {
  if(!label) {
    throw 'a menu must have a label';
  }

  if (subMenu?.some(sm => sm.type === MenuType.PrimaryMenu)) {
    throw new Error(`"PrimaryMenu" must not be used as a submenu! The "${path}" menu must be changed!`);
  }

  const [componentName, vueComponent] = typeof component === 'string' 
    ? [component, undrawIcons[component]]
    : [null, component];

  return {
    id: path, // by default id is path, but it can be changed
    path,
    label,
    componentName,
    component: vueComponent || UndrawFinance,
    type,
    group: type === MenuType.GroupMenu || type === MenuType.CustomGroupMenu,
    tab: type === MenuType.TabMenu,
    async: type === MenuType.AsyncMenu,
    subMenu,
    hasSubMenu: subMenu?.length > 0, // it must be set again when checking permissions
    withId(id) {
      this.id = id;
      return this;
    },
    withIcon(component, componentProps) {
      this.component = component || UndrawFinance;
      this.componentProps = componentProps;
      return this;
    },
    /**
     * @deprecated it was replaced by the AsyncMenu type
     * @param {function} resolve 
     * @returns
     */
    withResolve(resolve) {
      this.resolve = resolve;
      return this;
    },
    withUnmodifiablePermission() {
      this.unmodifiablePermission = true;
      return this;
    }
  };
}

export function PrimaryMenu(path, label, component, subMenu = []) {
  return menu(path, label, component, MenuType.PrimaryMenu, subMenu);
}

/**
 * A group menu is a menu item with a back button added when is accessed
 *
 * @param {string} path
 * @param {string} label
 * @param {VueComponent} [component]
 * @param {Array} [subMenu]
 * @returns 
 */
export function GroupMenu(path, label, component, subMenu = []) {
  return menu(path, label, component, MenuType.GroupMenu, subMenu);
}

/**
 * A menu item with path
 * 
 * @param {string} label
 * @param {string} path
 * @param {VueComponent} [component]
 * @param {Array} [subMenu]
 * @returns 
 */
export function MenuItem(path, label, component, subMenu = []) {
  return menu(path, label, component, MenuType.MenuItem, subMenu);
}

/**
 * A menu item with path but not available to configure in options menu
 * 
 * @param {String} label 
 * @param {String} path 
 * @param {VueComponent} [component]
 * @returns 
 */
export function TabMenu(path, label, component) { // TODO refactor this item - an item should not defined as a tab but a group of items
  return menu(path, label, component, MenuType.TabMenu);
}

export function AsyncMenu(path, label = 'Asynchrone Menüs') {
  return menu(path, label, null, MenuType.AsyncMenu);
}

export function CustomGroupMenu(path, label, component, subMenu) {
  const checkedPath = path.startsWith('/m/') ? path : `/m/${path}`;
  return menu(checkedPath, label, component, MenuType.CustomGroupMenu, subMenu);
}

export function mapMenuConfigToMenu(menuStructure, systemMenu = []) {
  if (!menuStructure) return [];

  const { optionsMenu = [] } = menuStructure;

  const mapMenu = menu => {
    const sMenu = systemMenu?.find(sm => sm.path === menu.path) || {};
    return {
        ...menu,
        ...sMenu,
        subMenu: optionsMenu.filter(sm => sm.parent === menu.path).map(mapMenu),
    };
  };

  const toMenu = (menu) => {
    const subMenu = menu.subMenu.map(toMenu);

    // changes menu id to "<menu path>@<menu structure id>", example: /home@1
    const id = `${menu.path}@${menuStructure.id}`;

    switch(menu.type) {
      case MenuType.PrimaryMenu:
        return PrimaryMenu(menu.path, menu.label, menu.component, subMenu).withId(id);
      case MenuType.MenuItem:
        return MenuItem(menu.path, menu.label, menu.component, subMenu).withId(id);
      case MenuType.GroupMenu:
        return GroupMenu(menu.path, menu.label, menu.component, subMenu).withId(id);
      case MenuType.TabMenu:
        return TabMenu(menu.path, menu.label, menu.component).withId(id);
      case MenuType.AsyncMenu:
        return AsyncMenu(menu.path, menu.label, menu.component).withId(id);
      case MenuType.CustomGroupMenu:
        return CustomGroupMenu(menu.path, menu.label, menu.icon, subMenu).withId(id);
      default: 
        throw new Error('Could not find a menu type');
    }
  }

  return optionsMenu
    .map(mapMenu)
    .filter(menu => !menu.parent)
    .map(toMenu);
}

/**
 * Creates a menu label object with roles
 * 
 * @param {*} label 
 * @returns 
 */
function Label(label) {
  return {
    label,
    withRoles(roles) {
      this.roles = [ ...roles || [], ];
      return this;
    },
  };
}

/**
 * Creates a menu labels with roles
 * 
 * Note: Can be used when there is conditional labels. In this kind of scenario, order menu labels from specific to generic
 * Example: 
 *  ConditionalLabelsBuild()
 *    .add('Specific', [ROLES1, ROLES2])
 *    .add('Generic')
 *
 * @returns 
 */
export function ConditionalLabelsBuild() {
  return {
    labels: [],
    add(label, roles) {
      this.labels.push(Label(label).withRoles(roles));
      return this;
    },
    build() {
      return [ ...this.labels, ];
    },
  };
}

/**
 * Configures workspace and options menu visibility
 * 
 * @returns 
 */
export function PageMenuCfg() {
  return {
    pageMenuCfg: {
      showOptionsMenu: true,
      showWorkspaceHeader: true,
    },
    withOptionsMenu(value) {
      this.pageMenuCfg.showOptionsMenu = value;
      return this;
    },
    withWorkspaceHeader(value) {
      this.pageMenuCfg.showWorkspaceHeader = value;
      return this;
    },
  };
}
