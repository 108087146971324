import { render, staticRenderFns } from "./BrokerNumberCard.vue?vue&type=template&id=57fd6969&scoped=true&"
import script from "./BrokerNumberCard.vue?vue&type=script&lang=js&"
export * from "./BrokerNumberCard.vue?vue&type=script&lang=js&"
import style0 from "./BrokerNumberCard.vue?vue&type=style&index=0&id=57fd6969&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57fd6969",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("P:\\workspaces\\fk0\\fc\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('57fd6969')) {
      api.createRecord('57fd6969', component.options)
    } else {
      api.reload('57fd6969', component.options)
    }
    module.hot.accept("./BrokerNumberCard.vue?vue&type=template&id=57fd6969&scoped=true&", function () {
      api.rerender('57fd6969', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/dashboard/cards/BrokerNumberCard.vue"
export default component.exports