<template>
  <div @click="addExpresion('*')" class="box norm_button multiplier">*</div>
</template>

<script>
import functionMixins from "../../functionMixins.js";
export default {
  name: "Multiplier",
  mixins: [functionMixins]
};
</script>

<style scoped>
.multiplier {
  grid-area: multiplier;
}
</style>