<template>
  <DragnDropArea 
    hoverText="Datei hier ablegen"
    :disabled="isSomethingLoading"
    @files="dropFiles($event)"
  >

    <GesellschaftTitleNavigation
      :headerActions="headerActions"
      @action-UPLOAD_DOCUMENT="uploadNewDocument"
    />

    <StepperForm
      ref="stepperForm"
      stepType="tab"
      stepperName="customer-data"
      :stepperMediator="tabsStepperMediator"
      :hasFertig="false"
      :selectedStepKey="currentStep"
      @set-step="setStep($event)"
    >
      <template #contentTemplate>
        <div class="box__container">
          <GhostLoading v-if="loading.init" type="table" />
          <template v-else-if="currentStep === 'SCHRIFTVERKEHR'">
            <Table
              title="Dokumente Schriftverkehr"
              :headers="schriftverkehrHeaders"
              :rows="schriftverkehrRows"
              :rowsPerPage="10"
              @action-EDIT="editDocument"
              @action-DELETE="deleteDocument"
            >
              <template v-slot:bezeichnung="row">
                <DownloadLink 
                    :title="row.bezeichnung"
                    :filename="row.bezeichnung"
                    downloadServicePath="/get_simple_file"
                    :queryParams="{
                      fileId: row.id
                    }"
                  />
              </template>
            </Table>
          </template>
          <template v-else-if="currentStep === 'VERTRAEGE'">
            <Table
              title="Dokumente Verträge"
              :headers="vertraegeHeaders"
              :rows="vertraegeRows"
              :rowsPerPage="10"
              @action-EDIT="editDocument"
              @action-DELETE="deleteDocument"
            >
              <template v-slot:bezeichnung="row">
                <DownloadLink 
                    :title="row.bezeichnung"
                    :filename="row.bezeichnung"
                    downloadServicePath="/get_simple_file"
                    :queryParams="{
                      fileId: row.id
                    }"
                  />
              </template>
            </Table>
          </template>
        </div>
      </template>
    </StepperForm>

    <DokumentUploadModal
      ref="uploadModal"
      disableMultifile
      disableFotoUpload
      v-model="files"
      :confirmDisabled="!fileData.bezeichnung || !fileData.typ"
      @upload="uploadDocuments"
    >
      <InputField
        label="Bezeichnung"
        :renderDanger="!fileData.bezeichnung"
        v-model="fileData.bezeichnung"
      />
      <ComboBox
        label="Typ"
        :sortComboboxValues="false"
        :values="typValues"
        :disabled="!!fileData.id"
        :renderDanger="!fileData.typ"
        v-model="fileData.typ"
      />
      <InputTextArea
        label="Bemerkung"
        v-model="fileData.bemerkung"
      />
      <DatePickerField
        label='Stand'
        isValueAsString
        v-model="fileData.dateStand"
      />
    
    </DokumentUploadModal>

  </DragnDropArea>
</template>

<script>
import GESELLSCHAFT_TYPES from '@/store/gesellschaft/types';

import { mapGetters } from 'vuex';

import GesellschaftTitleNavigation from '@/views/gesellschaft/intern/GesellschaftTitleNavigation.vue'
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';

import Table from "@/components/table2/Table.vue";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import { makeQueryParam } from '@/helpers/utils-helper';

import axios from 'axios';
import { TextColumn, SlotColumn, ActionColumn, SimpleAction, ConfirmedAction, DateColumn } from "@/components/table2/table_util.js";
import { StepperMediator } from '@/components/stepper/utils';
import StepperForm from '@/components/stepper/StepperForm.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue'
import InputField from '@/components/core/forms/InputField.vue'
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import BaseModal from '@/components/core/BaseModal.vue'
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import DokumentUploadModal from "@/views/gesellschaft/intern/DokumentUploadModal.vue";
import DownloadLink from '@/components/core/download/DownloadLink.vue'
import DragnDropArea from '@/components/fileUpload/DragnDropArea.vue';
import dayjs from 'dayjs';

const config = {
  defaultSpinner: true
};

export default {
  mixins: [],
  components: {
    GesellschaftTitleNavigation,
    Table,
    GhostLoading,
    StepperForm,
    ComboBox,
    InputField,
    InputTextArea,
    BaseModal,
    DatePickerField,
    DokumentUploadModal,
    DownloadLink,
    DragnDropArea,
  },
  data() {
    return {
      loading: {
        init: false,
        save: false,
      },
      currentStep: 'SCHRIFTVERKEHR',
      fileData: {},
      files: [],
      typValues: [
        {
          label: 'Schriftverkehr',
          value: 'SCHRIFTVERKEHR',
        },
        {
          label: 'Vertrag',
          value: 'VERTRAG',
        },
        {
          label: 'Gesellschaftsantrag',
          value: 'GESELLSCHAFTSANTRAG',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      gesellschaft: GESELLSCHAFT_TYPES.GETTERS.GET_GESELLSCHAFT,
    }),
    tabs() {
      return [
        {stepKey: 'SCHRIFTVERKEHR', label:'Schriftverkehr', totalProgress: 1},
        ...(this.showVertraege ? [{stepKey: 'VERTRAEGE', label:'Verträge', totalProgress: 2}] : []),
      ]
    },
    schriftverkehrHeaders() {
      return {
        lockedLeft: [
          SlotColumn("bezeichnung", "Bezeichnung", 250, 1).makeAlwaysVisible(),
        ],
        center: [
          DateColumn("dateStand", "Datum"),
          TextColumn("bemerkung", "Bemerkung"),
          DateColumn("dateCreated", "Erstellungsdatum"),
          TextColumn("id", "ID"),
        ],
        lockedRight: [
          ActionColumn("actions", "Aktionen"),
        ],
      }
    },
    showVertraege() {
      return this.gesellschaft.dokumente?.showVertraege
    },
    schriftverkehrRows() {
      let rows = this.gesellschaft.dokumente?.schriftverkehr || []

      return rows.map(row => ({
        ...row,
        actions: this.makeActions(row),
      }))
    },
    vertraegeHeaders() {
      return {
        lockedLeft: [
          SlotColumn("bezeichnung", "Bezeichnung", 250, 1).makeAlwaysVisible(),
        ],
        center: [
          DateColumn("dateStand", "Vertragsdatum"),
          TextColumn("bemerkung", "Bemerkung"),
          DateColumn("dateCreated", "Erstellungsdatum"),
          TextColumn("id", "ID"),
        ],
        lockedRight: [
          ActionColumn("actions", "Aktionen"),
        ],
      }
    },
    vertraegeRows() {
      let rows = this.gesellschaft.dokumente?.vertraege || []

      return rows.map(row => ({
        ...row,
        actions: this.makeActions(row),
      }))
    },
    tabsStepperMediator() {
      return new StepperMediator(this.tabs);
    },
    headerActions() {
      return [
        PageHeaderSimpleAction('UPLOAD_DOCUMENT', 'Dokument hochladen'),
      ]
    },
    isSomethingLoading() {
      return Object.values(this.loading).some(action => action) 
    },
  },
  mounted() {
      this.loading.init = true

      this.$store.dispatch(GESELLSCHAFT_TYPES.ACTIONS.GET_GESELLSCHAFT_DOKUMENTE, { gesellschaftId: this.$route.params.gesellId })
      .finally(() => this.loading.init = false);
  },
  methods: {
    setStep(newStep){
      this.currentStep = newStep
    },
    makeActions(row) {
      let actions = [
        SimpleAction("EDIT", 'PhPencilLine', "Bearbeiten")
      ]

      if (row.deletable) {
        actions.push(ConfirmedAction("DELETE", 'PhTrash', "Löschen", `Soll das Dokument "${row.bezeichnung || row.filename}" wirklich gelöscht werden?`, 'Dokument löschen', "Löschen"))
      }

      return actions
    },
    uploadNewDocument() {
      this.fileData = {
        dateStand: dayjs().format("DD.MM.YYYY"),
      }
      this.files = []
      this.$refs.uploadModal.open()
    },
    dropFiles(files) {
      this.fileData = {
        dateStand: dayjs().format("DD.MM.YYYY"),
      }
      this.files = files
      this.$refs.uploadModal.open()
    },
    editDocument(row) {
      this.fileData = JSON.parse(JSON.stringify(row))
      delete this.fileData.actions;
      this.files = [row]
      this.$refs.uploadModal.open()
    },
    deleteDocument({id}, objKey = this.currentStep.toLowerCase()) {
      this.loading.save = true
      const params = makeQueryParam({ id, gesellschaftId: this.$route.params.gesellId }, true)

      axios.delete(`${process.env.VUE_APP_API}/gesellschaftenService/documents?${params}`, config).then(response => {
        if (response && response.status === 200) {
          this.gesellschaft.dokumente[objKey] = this.gesellschaft.dokumente[objKey].filter(row => row.id !== id)
        } 
      }).finally(() => this.loading.save = false)
    },
    uploadDocuments({tempFileId}, objKey = this.currentStep.toLowerCase()) {
      this.loading.save = true

      const params = makeQueryParam({ gesellschaftId: this.$route.params.gesellId }, true)
      
      const payload = {
        ...this.fileData,
        tempFileId,
      }

      axios.post(`${process.env.VUE_APP_API}/gesellschaftenService/documents?${params}`, payload, config).then(response => {
        if (response && response.data) {
          const indx = this.gesellschaft.dokumente[objKey].findIndex(row => row.id === response.data.id)

          if (indx > -1) {
            this.$set(this.gesellschaft.dokumente[objKey], indx, response.data)
          } else {
            this.gesellschaft.dokumente[objKey].push(response.data)
          }
        } 
      }).finally(() => this.loading.save = false)
    },
  },
}
</script>