
<template>
  <div>
    <PageHeaderTitleNavigation
      title="Vermögensverwaltungen"
      subtitle="Strategien abonnieren"
      :defaultMenu="$appNavigation.currentTabMenu"
    />

    <HinweiseUndFehler :hints="hints" />
    <GhostLoading
      v-if="loading"
      type="table"
      :title="TABLE_TITLE"
      useBoxContainer
    />
    <div v-else class="box__container">
      <NoData
        v-if="!rows || rows.length == 0"
        :title="TABLE_TITLE"
        content="Es wurden keine Einträge gefunden"
      />
      <template v-else>
        <InputToggleSwitch
          label="nur ausgewählte Vermögensverwaltung in der Struktur anzeigen"
          inLineLabel
          v-model="hasAboStruktur"
        />

         <InputToggleSwitch
          label="diese Einschränkung nur in der Struktur anwenden"
          v-if="hasAboStruktur"
          inLineLabel
          v-model="hasOnlyInStruktur"
        />

        <Table
          :title="TABLE_TITLE"
          :headers="headers"
          :rows="rows"
          rowId="id"
          :rowsPerPage="25"
        >
          <template v-slot:hasSchema="row">
            <div class="check-box-center">
              <InputCheckBoxItem
                :value="row.hasSchema"
                @input="onChangeSchema($event, row)"
              />
            </div>
          </template>
          <template v-slot:hasRobo="row">
            <div class="check-box-center" v-if="row.hasRobo != null">
              <InputCheckBoxItem
                :value="row.hasRobo"
                @input="onChangeRobo($event, row)"
              />
            </div>
          </template>
        </Table>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VV_TYPES from "@/store/vermoegensverwaltung/types";
import GhostLoading from "@/components/core/loading/GhostLoading.vue";
import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import PageHeaderTitleNavigation from "@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue";
import HinweiseUndFehler from "@/components/core/HinweiseUndFehler.vue";
import NoData from "@/components/core/NoData.vue";
import Table from "@/components/table2/Table.vue";
import {
  CurrencyColumn,
  TextColumn,
  SlotColumn,
  PercentageColumn,
} from "@/components/table2/table_util.js";
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import InputCheckBoxItem from "@/components/core/forms/checkbox/InputCheckBoxItem.vue";

const TABLE_TITLE = "Vermögensverwaltungen";

export default {
  name: "VVSubscription",
  components: {
    GhostLoading,
    OptionMenu,
    PageHeaderTitleNavigation,
    HinweiseUndFehler,
    NoData,
    Table,
    InputToggleSwitch,
    InputCheckBoxItem,
  },
  data: function () {
    return {
      loading: false,

      TABLE_TITLE,
      hints: [
        {
          title:
            'Option "nur ausgewählte Vermögensverwaltung in der Struktur anzeigen"',
          message: `Wenn Sie diese Option aktivieren, können Sie die gewünschte Strategie für die Beratungsmappen auswählen; Ihre Zugangsnummer, soweit die gesamte Unterstruktur sehen dann nur noch die ausgewählte Strategie.<br>
          Werden neue Strategien verfügbar sind diese nicht sichtbar, sie müssen erst explizit hier freigegeben werden.`,
        },
        {
          title: "WK Anlageassitent",
          message: `Wenn Sie WK Anlageassitent verwenden möchten, dann müssen Sie die gewünschten Strategien auswählen.<br>
          Werden neue Strategien verfügbar sind diese nicht sichtbar, sie müssen erst explizit hier freigegeben werden.`,
        },
      ],
      editAboStruktur: null,
      editOnlyInStruktur: null,
    };
  },
  mounted() {
    this.loading = true;
    this.$store
      .dispatch(VV_TYPES.ACTIONS.LOAD_SUBSCRIPTION)      
      .finally(() => (this.loading = false));
  },
  computed: {
    ...mapGetters({
      subscription: VV_TYPES.GETTERS.GET_SUBSCRIPTION,
    }),
    rows() {
      if (this.subscription?.rows) {
        return this.subscription.rows;
      }
      return [];
    },
    hasAboStruktur: {
      get() {
        return this.editAboStruktur != undefined
          ? this.editAboStruktur
          : this.subscription?.hasAboStruktur;
      },
      set(value) {
        this.editAboStruktur = value;
      },
    },
    hasOnlyInStruktur: {
      get() {
        return this.editOnlyInStruktur != undefined
          ? this.editOnlyInStruktur
          : this.subscription?.hasOnlyInStruktur;
      },
      set(value) {
        this.editOnlyInStruktur = value;
      },
    },
    headers() {
      const headers = {
        lockedLeft: [TextColumn("name", "Name")],
        center: [
          TextColumn("ersteller", "Anbieter"),
          TextColumn("sri", "SRI"),
          TextColumn("anlageziel", "Anlageziele"),
          TextColumn("zeithorizont", "Zeithorizont"),
          CurrencyColumn("minEinmalanlagesumme", "Min. Einmalanlage"),
          CurrencyColumn("minSparplansumme", "Min. Sparplan"),
          PercentageColumn("perf_1m", "Perf. 1M"),
          PercentageColumn("perf_3m", "Perf. 3M"),
          PercentageColumn("perf_6m", "Perf. 6M"),
          PercentageColumn("perf_1j", "Perf. 1J"),
          PercentageColumn("perf_2j", "Perf. 2J"),
          PercentageColumn("perf_3j", "Perf. 3J"),
          PercentageColumn("perf_5j", "Perf. 5J"),
          TextColumn("verwahrstelle", "Verwahrstelle"),
          TextColumn("portfolioKennung", "Portfolio-Kennung"),
        ],
      };
      if (this.hasAboStruktur) {
        headers.lockedLeft.push(
          SlotColumn("hasSchema", "WK Mappe", 80, 0).makeSortable()
        );
      }
      headers.lockedLeft.push(
        SlotColumn("hasRobo", "WK Anlageas.", 80, 0).makeSortable()
      );
      return headers;
    },
  },
  beforeDestroy() {
    const params = {};
    
    if (
      this.editAboStruktur != null &&
      this.editAboStruktur !== this.subscription.hasAboStruktur
    ) {
      params.hasAboStruktur = this.editAboStruktur;
    }

    if (
      this.editOnlyInStruktur != null &&
      this.editOnlyInStruktur !== this.subscription.hasOnlyInStruktur
    ) {
      params.hasOnlyInStruktur = this.editOnlyInStruktur;
    }

    if (this?.subscription?.rows) {
      this.subscription.rows.forEach((row) => {
        if (this.hasAboStruktur && row.altSchema != row.hasSchema) {
          if (row.hasSchema) {
            params.jaSchema =
              (params.jaSchema ? params.jaSchema + "," : "") + row.id;
          } else {
            params.neinSchema =
              (params.neinSchema ? params.neinSchema + "," : "") + row.id;
          }
        }
        if (row.altRobo != row.hasRobo) {
          if (row.hasRobo) {
            params.jaRobo = (params.jaRobo ? params.jaRobo + "," : "") + row.id;
          } else {
            params.neinRobo =
              (params.neinRobo ? params.neinRobo + "," : "") + row.id;
          }
        }
      });
    }
    if (Object.keys(params).length) {
      this.$store.dispatch(VV_TYPES.ACTIONS.UPDATE_SUBSCRIPTION, params);
    }
  },
  methods: {
    onChangeSchema(value, row) {
      const storeRow = this.rows.find((r) => r.id === row.id);
      storeRow.hasSchema = value;
    },
    onChangeRobo(value, row) {
      const storeRow = this.rows.find((r) => r.id === row.id);
      storeRow.hasRobo = value;
    },
  },
};
</script>

<style scoped>
.check-box-center {
  padding-left: calc(50% - 10px);
}
</style>