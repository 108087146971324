<template>
  <div>
    <div v-if="depots && depots.length">
      <!-- <label>Tausch von Fonds:</label> -->
      <div v-for="(depot, index) in depots" :key="index">
        <div class="row justify-content-between">
          <label class="col-8">{{depot.label}}</label>
          <div class="col-4">
            <BaseButton :isBlock="true" @click="openFormsFinder(depot)">Position tauschen</BaseButton>
          </div>
        </div>
      </div>
      <hr>

      <div class="input-forms__input-container">
        <div v-if="hasPositions">
          <div>
            <WertpapierAuswahlPositionsTausch
              :positions="positions"
              :config="config"
              :categoryId="categoryId"
              :antragId="antragId"
              :tauschVonName="tauschVonName"
              :tauschZuName="tauschZuName" />
          </div>
        </div>
        <div v-else>
          <div>Wertpapierauswahl ist leer</div>
        </div>
      </div>
    </div>
    <div v-else>
      Sie haben keine Positionen.
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ANTRAG_TYPES from '@/store/antrag/types';
import BaseButton from '@/components/core/BaseButton.vue';
import WertpapierAuswahlPositionsTausch from '@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlPositionsTausch.vue';
import WertpapierAuswahlPositionFields from '@/components/core/forms/antraege/wertpapierAuswahl/WertpapierAuswahlPositionFields.vue';
import antragNavigationMixin from '@/mixins/antrag/antrag-navigation-mixin.js';

export default {
  mixins: [antragNavigationMixin],
  components: {
    BaseButton,
    WertpapierAuswahlPositionsTausch,
    WertpapierAuswahlPositionFields,
  },
  props: {
    title: {
    },
    antragData: {
    },
    config: {
    },
    categoryId: {
    },
    antragId: {
    },
    antragTyp: {
    },
    suppressFilterLagerstelle: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      depots: this.config && this.config.depots || {},
      tauschZuName: '',
      tauschVonName: '',
    }
  },
  mounted() {
    if (this.config && this.config.query) {
      const query = JSON.parse(this.config.query) || {};
      this.tauschVonName = query['tauschVonName'];
      this.tauschZuName = query['tauschZuName'];
    }
  },
  computed: {
     ...mapGetters({
      positionsAll: ANTRAG_TYPES.GETTERS.POSITIONS,
      positionDataAll: ANTRAG_TYPES.GETTERS.POSITION_DATA
    }),
    getDepotIsins() {
      return this.depots && Object.keys(this.depots).length && Object.keys(this.depots) || [];
    },
    positions() {
      if (this.positionsAll && this.antragId && this.categoryId 
          && this.positionsAll[this.antragId] 
          && this.positionsAll[this.antragId][this.categoryId]) {
        this.positionsAll[this.antragId][this.categoryId].forEach(pos => {
          pos.modus = pos.modus || 'ALLE_ANTEILE',
          pos.hoehe = pos.hoehe || '',
          pos.bemerkung = pos.bemerkung || '',
          pos.plaene = pos.plaene || 'PLAENE_UEBERNEHMEN'
        });

        return this.positionsAll[this.antragId][this.categoryId];
      }
    },
    hasPositions() {
      return Array.isArray(this.positions) && this.positions.length > 0
    },
  },
  methods: {
    openFormsFinder(depot) {
      if (this.config && this.config.query) {
        const config = Object.assign({}, this.config);
        const query = JSON.parse(config.query) || {};
        query['tauschVonValue'] = depot.value;
        config.query = JSON.stringify(query);
        this.goToFormsFinder('TAUSCH', config, this.suppressFilterLagerstelle);
      }
    },
  }

}
</script>

<style>

</style>