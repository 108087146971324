import EINGEBUNDENE_WERTPAPIERE_TYPES from './types';
import CORE_TYPES from '../core/types';
import LOG_TYPES from '@/store/log/types';
import { buildMessage } from "@/helpers/log-message-helper";
import axios from 'axios';

const config = {
  defaultSpinner: true
};

export default {
  [EINGEBUNDENE_WERTPAPIERE_TYPES.ACTIONS.SAVE_EINGEBUNDENE_WERTPAPIERE]({ getters, commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {

      const URL =`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/versicherungEingebundeneWertpapier/saveEingebundeneWertpapiere`;

      let params = {};
      
      if(payload?.versicherungId) {
        params.vertragId = payload.versicherungId;
      }
      if(payload?.sparteId) {
        params.sparteId = payload.sparteId;
      }

      axios.post(URL, payload.wertpapierepositionen, {params, config})
        .then((response) => {
          commit(EINGEBUNDENE_WERTPAPIERE_TYPES.MUTATIONS.SAVE_EINGEBUNDENE_WERTPAPIERE_SUCCESS, {...response.data, sparteId: payload?.sparteId})
          resolve(response?.data);
        })
        .catch((error) => {
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error, 'danger'));
          reject(error);
        });
    });
  },
  [EINGEBUNDENE_WERTPAPIERE_TYPES.ACTIONS.VALIDATE_FOND_ID]({ getters, commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      const URL = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/versicherungEingebundeneWertpapier/isFondIDValid`;

      let params = {};
      
      if(payload?.isinWkn) {
        params.isinWkn = payload.isinWkn;
      }

      axios.get(URL, {params, config}).then(response => {
        resolve(response?.data);
      }).catch(error => {
        reject(error);
      });
    });
  },
  [EINGEBUNDENE_WERTPAPIERE_TYPES.ACTIONS.DELETE_VERLAUF_ANTEILE]({ getters, commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {

      const URL =`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/versicherungEingebundeneWertpapier/deleteVerlaufAnteile`;

      let params = {};
      
      if(payload?.versicherungId) {
        params.vertragId = payload.versicherungId;
      }
      if(payload?.verlaufAnteileId) {
        params.verlaufAnteileId = payload.verlaufAnteileId;
      }

      axios.delete(URL, {params, config})
        .then((response) => {
          commit(EINGEBUNDENE_WERTPAPIERE_TYPES.MUTATIONS.DELETE_VERLAUF_ANTEILE_SUCCESS, params)
          resolve(response?.data);
        })
        .catch((error) => {
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error, 'danger'));
          reject(error);
        });
    });
  },

}