import DCS_SYNCHRONISATION from './types';
import Vue from 'vue'

export default{
    [DCS_SYNCHRONISATION.MUTATIONS.SET_ZUORDUNGSLISTE](state,payload){
        state.zuordnungsliste = payload;
    },
    [DCS_SYNCHRONISATION.MUTATIONS.SET_PRODUCT](state, payload){
        state.product = payload;
    },
    [DCS_SYNCHRONISATION.MUTATIONS.SET_COMBOBOXVALUES](state,payload){
        state.comboboxValues = payload;
    },
    [DCS_SYNCHRONISATION.MUTATIONS.SAVE_PRODUCT_SUCCESS](state, payload){
        if(payload.product){
            Vue.set(state,'product', {...payload.product})
        }
    }
}