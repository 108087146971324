<template>
    <div>
        <PageHeaderTitleNavigation
            title="Limits"
            subtitle="Risikoklassenlimit"
            :defaultMenu="hasRoles([VIEW_ROLES.VIEW_BROKER]) ? [] : $appNavigation.currentTabMenu"
            :actions="actions"
            @action-RESET="saveOrReset('reset')"
            @action-REMOVE="remove()"
        />

        <div class="box__container">
            <div  class="box__title">Limit {{title}} {{titleExtra}}</div>
            <div>
                Das {{title}}-Limit gibt Ihnen die Möglichkeit, das gewichtete Gesamtrisiko des 
                Kundendepots ständig zu beobachten. Hat Ihr Kunde beispielsweise laut Anlegerprofil eine 
                persönliche {{title}} von 3 (auf der Skala von 1-{{maxValue}}), so können Sie dies beim Ausgangswert 
                eingeben. Mit dem Setzten eines Werts in den Feldern Abweichung nach oben und unten begrenzen 
                Sie die Risikoschwankungsbreite, die das Gesamtdepot haben sollte. Wird durch Wertentwicklung, 
                Kauf oder Zukauf dieser Bereich verlassen, erhalten Sie über das System eine Warnungs-Mail.
            </div>
        </div>
        <div class="box__container">
            <div class="info-block">
                <div v-if="!isCustomerOrigin">Kundennr: {{limit.kundennr}}</div>
                <div v-if="!isCustomerOrigin">Kunde: {{limit.kundenname}}</div>
                <div>Durchschnittliche {{title}} des Depots: {{limit.mittelwert}}</div>
                <div>Ausgangswert {{title}}: {{limit.ausgangswert}}</div>
            </div>
            <InputField
                label="Abweichung nach unten"
                v-model="form.abweichungLow"
                type="number"
                :precision="1"
                isComponentHalfSize />
            <InputField
                label="Abweichung nach oben"
                v-model="form.abweichungHigh"
                :precision="1"
                type="number"
                isComponentHalfSize />

            <div v-if="limit.dateUpdated">Letzte Änderung: {{limit.dateUpdated}}</div>
            <div v-if="limit.dateOutOfLimit">Datum der Überschreitung: {{limit.dateOutOfLimit}}</div>

            <InputTextArea v-if="!isCustomerOrigin"
                label="Bemerkung (für Kunden nicht sichtbar)"
                v-model="form.bemerkung" 
                isComponentHalfSize />
        </div>

        <LimitErrorModal ref="modalError" :errorText="errorFromService" @closeLimit="confirmNextRoute" />

    </div>
</template>

<script>
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import InputField from '@/components/core/forms/InputField.vue';
import { mapGetters } from 'vuex';
import LIMIT_TYPES from '@/store/limit/types';
import CORE_TYPES from '@/store/core/types';
import mixin from '@/mixins/limit/limit-mixin.js';
import LimitErrorModal from '@/views/limit/LimitErrorModal.vue'
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils'

export default {
    mixins: [mixin],
    components: {
        InputField,
        InputTextArea,
        LimitErrorModal,
        PageHeaderTitleNavigation,
    },
    data() {
        return {
            form: {},
            nextRoute: false,
            kundennr: '',
            errorFromService: null,
        }
    },
    mounted() {
        this.kundennr = this.$route.query.kundennr || '';
        this.$store.dispatch(LIMIT_TYPES.ACTIONS.GET_RISIKOKLASSEN_LIMIT, { kundennr: this.kundennr });
    },
    watch: {
        limit(value) {
            this.$set(this, 'form', Object.assign({}, value));
        }
    },
    computed: {
        ...mapGetters({
            risikolimit: LIMIT_TYPES.GETTERS.RISIKOKLASSEN_LIMIT,
            hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
        }),
        limit() {
            return this.risikolimit || {};
        },
        titleExtra() {
            return this.limit?.notExist ? '(nicht gesetzt)' : '';
        },
        title() {
            return this.limit?.title || 'SRI'
        },
        maxValue() {
            return this.limit?.maxValue || '7'
        },
        canSave() {

            // only saves a limit if: one of the two abweichungen was filled by the user AND the "Ausgangswert SRI" is not zero. (MSC-17666)

            let ausgangswert = this.limit?.ausgangswert;

            if (ausgangswert) {
                ausgangswert = ausgangswert.replace(/\./g, '').replace(/\,/g, '.');
            } else {
                ausgangswert = 0;
            }

            ausgangswert = isNaN(ausgangswert) ? 0 : Number(ausgangswert);

            if (this.form?.abweichungLow || this.form?.abweichungHigh && ausgangswert != 0) {
                return true;
            }

            return false;
        },
        actions() {
            return[
                PageHeaderSimpleAction('REMOVE', 'Löschen').withDisabled(() => this.limit.notExist),
                PageHeaderSimpleAction('RESET', 'Zurücksetzen').withDisabled(() => this.limit.notExist),
            ]
        },
        isChanged() {
            return this.limit && JSON.stringify(this.limit) !== JSON.stringify(this.form)
        },
    },
    methods: {
        saveOrReset(action) {
            this.$store.dispatch(LIMIT_TYPES.ACTIONS.SAVE_RISIKOKLASSEN_LIMIT,  Object.assign(this.form, {action: action, kundennr: this.kundennr })).then(data => {
                if (data?.errorStr) {
                    this.errorFromService = data.errorStr
                    this.$refs.modalError.open();
                } else {
                    this.confirmNextRoute();
                }
            });
        },
        remove() {
            this.$store.dispatch(LIMIT_TYPES.ACTIONS.DELETE_RISIKOKLASSEN_LIMIT, { kundennr: this.kundennr });
        }
    },
    beforeRouteLeave(to, from, next) {
        
        if (this.canSave && this.isChanged) {
            this.nextRoute = next;
            this.saveOrReset('save');
            
        } else {
            next();
        }
    },
}
</script>

<style>

</style>