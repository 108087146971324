<template>  
    <component 
      :is="dynamicTemplate" 
      v-bind="dynamicBindings.props"
      v-on="dynamicBindings.events"
      v-if="dynamicTemplate"
      :class="dynamicBindings.class || ''" />
 
</template>

<script>

export default {
  mixins: [],
  props: {
    editableComponent: {
    },
  },
  data() {
    return {
    }
  },
  methods: {
  },
  computed: {
    dynamicBindings() {
      if (this.editableComponent && this.editableComponent.htmlCardType) {
        switch (this.editableComponent.htmlCardType) {
          // case 'CHECKBOX':
          //   return {
          //     props: {
          //       editableComponent: this.editableComponent,
          //     },
          //   }
          // case 'INPUT':
          //   return {
          //     props: {
          //       editableComponent: this.editableComponent,
          //     },
          //   }
          default:
            return {
              props: {
                editableComponent: this.editableComponent,
              },
            }
        }
      }
      return {
        props: {
          editableComponent: this.editableComponent,
        },
      }
    },
    dynamicTemplate() {
      if (this.editableComponent) {
        if (this.editableComponent.htmlCardType) {
          switch (this.editableComponent.htmlCardType) {
            case 'CHECKBOX':
              return () => import('@/components/dynamicDocument/components/CheckboxComponent.vue')
            case 'INPUT':
              return () => import('@/components/dynamicDocument/components/InputComponent.vue')
            case 'EINZELSPARTEN':
              return () => import('@/components/dynamicDocument/components/Einzelsparten.vue')
            default:
              return () => import('@/components/dynamicDocument/components/BaseComponent.vue')
          }
        } else {
          return () => import('@/components/dynamicDocument/components/BaseComponent.vue');
        }

      }
      return null
    },
  },
}
</script>

<style scoped>
</style>