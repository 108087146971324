<template>
  <BaseModal 
    ref="modal"
    size="lg"
    @onConfirmButton="add()"
    @close="onClose()"
  >
    <template #modalTitle>
      neuer Parameter
    </template>
    <template #default>
      <ComboBox
        label="verfügbare Einstellungen"
        v-model="selectedConfigsAvailableValue"
        :values="configsAvailableValues"
      />

      <template v-if="selectedConfigsAvailable">
        <div class="box__title" v-html="sanitize(selectedConfigsAvailable.label)"></div>

        <InputField v-model="searchKeyword" placeholder="Filter..." />

        <div v-if="selectedConfigsAvailableComponents.length" class="config-components" :key="selectedConfigsAvailableValue">
          <div class="config-component--item" v-for="(component, index) in selectedConfigsAvailableComponents" :key="index">
            <InputToggleSwitch
              :label="component.label"
              v-model="selectedComponentsToAdd[component.id]"
              inLineLabel
              :disabled="isAlreadySelected(component.id)"
              class="config-component--item-input"
            />
            <div class="config-component--item-details">
              <div v-if="component.usage && component.usage.length" class="config-component--item-used">
                <span class="config-component--item-used--label">Used in:</span>
                <div class="config-component--item-used--places">
                  <Pill v-for="(place, index) in component.usage" :key="index" 
                    :label="place.groupLabel + ' > ' + place.stepLabel" type="default" class="config-component--item-used--place" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <NoData v-else noIcon />
      </template>

      <!-- <pre>selectedComponentsToAdd={{ selectedComponentsToAdd }}</pre> -->
      <!-- <pre>selectedConfigsAvailableComponents={{ selectedConfigsAvailableComponents }}</pre> -->
      <!-- <pre>allConfigsAvailable={{ allConfigsAvailable }}</pre> -->
    </template>
  </BaseModal>
</template>

<script>
import { mapGetters } from 'vuex';
import CONFIG_DEFINITION_TYPES from '@/store/configDefinition/types';

import BaseModal from '@/components/core/BaseModal.vue';
import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import Pill from '@/components/core/Pill.vue';
import NoData from '@/components/core/NoData.vue';

import { sanitize } from '@/helpers/string-helper.js';

export default {
  components: {
    BaseModal,
    ComboBox,
    InputField,
    InputToggleSwitch,
    Pill,
    NoData,
  },
  props: {
    alreadySelected: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedConfigsAvailableValue: null,
      searchKeyword: null,
      selectedComponentsToAdd: {},
    };
  },
  computed: {
    ...mapGetters({
      allConfigsAvailable: CONFIG_DEFINITION_TYPES.GETTERS.ALL_CONFIGS_AVAILABLE,
    }),
    selectedConfigsAvailable() {
      return this.allConfigsAvailable?.[this.selectedConfigsAvailableValue] || null;
    },
    configsAvailableValues() {
      return this.allConfigsAvailable?.map((configsAvailable, index) => ({
        label: configsAvailable.label,
        value: index,
      }));
    },
    allConfigsAvailableComponents() {
      return Object.values(this.allConfigsAvailable)
        .flatMap(configDefinition => configDefinition.steps)
        .flatMap(step => step.configComponents);
    },
    selectedConfigsAvailableComponents() {
      return (this.selectedConfigsAvailable?.steps || [])
        .flatMap(step => step?.configComponents || [])
        .filter(component => !!component.id)
        .filter(component => !this.searchKeyword || component?.label?.toUpperCase()?.includes(this.searchKeyword.toUpperCase()));
    },
  },
  methods: {
    init() {
      if(this.alreadySelected?.length > 0) {
        const alreadySelected = this.alreadySelected.reduce((acc, componentId) => ({
          ...acc,
          [componentId]: true,
        }), {});
        this.$set(this, 'selectedComponentsToAdd', { ...alreadySelected, });
      }

      this.findConfigsAvailable();
    },
    open() {
      this.init();

      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    onClose() {
      this.reset();
    },
    reset() {
      this.selectedConfigsAvailableValue = null;
      this.searchKeyword = null;
      this.selectedComponentsToAdd = {};
    },
    add() {
      const { selectedComponentsToAdd } = this;
      const components = Object.keys(selectedComponentsToAdd)
        .filter(componentId => !this.isAlreadySelected(componentId) && selectedComponentsToAdd[componentId] === true)
        .map(componentId => this.allConfigsAvailableComponents.find(component => component.id === componentId));

      this.$emit('add', [ ...components, ]);
    },
    sanitize(htmlString) {
      return sanitize(htmlString);
    },
    isAlreadySelected(componentId) {
      return this.alreadySelected?.some(selected => selected === componentId);
    },
    findConfigsAvailable() {
      this.$store.dispatch(CONFIG_DEFINITION_TYPES.ACTIONS.GET_ALL_CONFIGS_AVAILABLE);
    },
  },
}
</script>

<style lang="scss" scoped>
.config-components {
  .config-component--item {
    margin: 0 0 24px;

    &:last-child {
      margin-bottom: 0;
    }

    .config-component--item-input {
      margin: 0;
      padding: 0;
    }
    .config-component--item-details {
      margin: 4px 0 0 42px;

      > * {
        margin: 4px 0 0;

        &:first-child {
          margin-top: 0;
        }
      }
    }
    .config-component--item-note {
      font-size: small;
    }
    .config-component--item-used {
      display: flex;
      align-items: center;
      font-size: small;

      .config-component--item-used--label {
        flex: 0 0 auto;
        margin: 0 4px 0 0;
      }

      .config-component--item-used--places {
        display: flex;
        margin: 0;
        flex-wrap: wrap;

        .config-component--item-used--place {
          margin: 0 0 0 4px;
          padding: 0px 4px 1px;

          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }
}
</style>
