<template>
  <svg id="b954c258-ebd6-43b8-aaa6-ec167673194d" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
    width="866.33071" height="605.73993" viewBox="0 0 866.33071 605.73993">
    <path
      d="M898.30315,567.7887a9.14581,9.14581,0,0,1,1.93088-13.89051l-7.25159-31.682,15.8187,5.91773,4.46105,29.1785a9.19542,9.19542,0,0,1-14.959,10.47626Z"
      transform="translate(-166.83465 -147.13004)" fill="#ffb8b8" />
    <path
      d="M897.20867,542.64389l-13.906-26.88477,1.88062-67.69726.89355,1.88281c1.041,2.19336,25.44019,53.84619,23.7,71.13819l2.78882,15.80224Z"
      transform="translate(-166.83465 -147.13004)" fill="#3f3d56" />
    <polygon points="721.307 594.201 732.458 594.201 737.762 551.192 721.305 551.193 721.307 594.201" fill="#ffb8b8" />
    <path
      d="M885.29761,737.691l21.95908-.00089h.00155a13.99379,13.99379,0,0,1,13.99352,13.99352v.45476l-35.95371.00177Z"
      transform="translate(-166.83465 -147.13004)" fill="#2f2e41" />
    <polygon points="611.134 585.727 621.877 588.714 638.511 548.699 622.655 544.29 611.134 585.727" fill="#ffb8b8" />
    <path
      d="M776.20352,728.58778,797.36,734.47044l.00149.00042a13.99378,13.99378,0,0,1,9.7326,17.23118l-.12184.43813-34.63966-9.63139Z"
      transform="translate(-166.83465 -147.13004)" fill="#2f2e41" />
    <path
      d="M877.02434,724.5643l-22.50366-161.126L805.91619,723.65317l-38.21692-13.04981,54.77454-214.53271.36035-.02539,57.38061-3.99121,41.45679,219.48779Z"
      transform="translate(-166.83465 -147.13004)" fill="#2f2e41" />
    <path
      d="M871.07293,420.14509s-20.91878-10.91415-49.11366-6.36659c0,0-15.007,36.205,0,50.61758l4.0928,36.24085s38.19952,22.7378,50.93269-3.63805l-1.819-30.01391s9.09512-14.39464,1.819-24.93281A34.82081,34.82081,0,0,1,871.07293,420.14509Z"
      transform="translate(-166.83465 -147.13004)" :fill="color" />
    <path
      d="M873.72307,584.98666l3.67163-76.188-10.39135-90.63379.47705-.07227c13.63086-2.05517,18.00268,21.42774,18.18213,22.42822l23.72583,134.14209Z"
      transform="translate(-166.83465 -147.13004)" fill="#3f3d56" />
    <path
      d="M827.46624,585.78061l-41.94336-4.66016.011-.45849c.14869-6.20948,3.75879-152.2041,13.87452-162.54395,10.17553-10.40137,29.37719-5.90088,30.18969-5.70312l.40479.09765-3.45142,61.77637Z"
      transform="translate(-166.83465 -147.13004)" fill="#3f3d56" />
    <path
      d="M835.19144,498.52321a9.14584,9.14584,0,0,1-11.90719-7.409l-31.89489-6.24893,11.83539-12.04883,28.47343,7.7813a9.19542,9.19542,0,0,1,3.49326,17.92547Z"
      transform="translate(-166.83465 -147.13004)" fill="#ffb8b8" />
    <path
      d="M818.17132,491.20932,807.77874,488.975c-15.34229,4.61425-42.12219-11.62989-52.94593-18.84522-2.03662-1.35742-2.69726-4.04736-1.9635-7.99365a23.30978,23.30978,0,0,1,8.567-13.915l38.11975-30.2251,9.62158-1.60889,2.85791,16.19483L787.74822,458.6683l35.75244,19.46Z"
      transform="translate(-166.83465 -147.13004)" fill="#3f3d56" />
    <path
      d="M814.06094,400.06826v-21a33.5,33.5,0,1,1,67,0v21a4.50508,4.50508,0,0,1-4.5,4.5h-58A4.50507,4.50507,0,0,1,814.06094,400.06826Z"
      transform="translate(-166.83465 -147.13004)" fill="#2f2e41" />
    <circle cx="679.37983" cy="232.67051" r="24.56103" fill="#ffb8b8" />
    <path
      d="M820.97939,379.706a2.50023,2.50023,0,0,1-.5852-1.99317l2.90942-20.25976a2.50362,2.50362,0,0,1,1.41455-1.91895c14.85034-6.95019,29.90967-6.959,44.76-.02637a2.51921,2.51921,0,0,1,1.42871,2.03614L872.849,377.83a2.49954,2.49954,0,0,1-2.48877,2.73828h-4.92553a2.50966,2.50966,0,0,1-2.26539-1.44238l-2.12573-4.55469a1.49989,1.49989,0,0,0-2.84765.44824l-.41993,3.3584a2.50359,2.50359,0,0,1-2.48071,2.19043H822.8688A2.50013,2.50013,0,0,1,820.97939,379.706Z"
      transform="translate(-166.83465 -147.13004)" fill="#2f2e41" />
    <rect y="40.3663" width="551" height="343.11356" fill="#e6e6e6" />
    <rect x="36.94894" y="106.46612" width="100.91574" height="65.59524" :fill="secondColor" />
    <rect x="162.34439" y="106.46612" width="100.91574" height="65.59524" :fill="secondColor" />
    <rect x="287.73987" y="106.46612" width="100.91577" height="65.59524" :fill="secondColor" />
    <rect x="413.13531" y="106.46612" width="100.91577" height="65.59524" :fill="secondColor" />
    <rect x="36.94894" y="199.30861" width="100.91574" height="65.59523" :fill="secondColor" />
    <rect x="162.34439" y="199.30861" width="100.91574" height="65.59523" :fill="secondColor" />
    <rect x="287.73987" y="199.30861" width="100.91577" height="65.59523" :fill="secondColor" />
    <rect x="413.13531" y="199.30861" width="100.91577" height="65.59523" :fill="secondColor" />
    <rect x="36.94894" y="292.15109" width="100.91574" height="65.59525" :fill="secondColor" />
    <rect x="162.34439" y="292.15109" width="100.91574" height="65.59525" :fill="secondColor" />
    <rect x="287.73987" y="292.15109" width="100.91577" height="65.59525" :fill="secondColor" />
    <rect x="413.13531" y="292.15109" width="100.91577" height="65.59525" :fill="secondColor" />
    <circle cx="144.30951" cy="65.59524" r="15.13736" :fill="secondColor" />
    <circle cx="394.58057" cy="65.59524" r="15.13734" :fill="secondColor" />
    <rect x="138.25458" width="12.10989" height="69.63187" fill="#e6e6e6" />
    <rect x="388.52563" width="12.10986" height="69.63187" fill="#e6e6e6" />
    <path d="M653.24859,286.39377a22.75657,22.75657,0,1,1-3.52686-12.19043A22.7566,22.7566,0,0,1,653.24859,286.39377Z"
      transform="translate(-166.83465 -147.13004)" :fill="color" />
    <path
      d="M649.72173,274.20337l-22.46387,22.45975c-1.41186-2.18579-9.27765-12.34394-9.27765-12.34394A31.82748,31.82748,0,0,1,621.2,281.477l6.52259,8.69678,19.36853-19.36856A22.72652,22.72652,0,0,1,649.72173,274.20337Z"
      transform="translate(-166.83465 -147.13004)" :fill="secondColor" />
    <path d="M402.45763,379.23627a22.75655,22.75655,0,1,1-3.52685-12.19043A22.7566,22.7566,0,0,1,402.45763,379.23627Z"
      transform="translate(-166.83465 -147.13004)" :fill="color" />
    <path
      d="M398.93078,367.04584l-22.46387,22.45974c-1.41187-2.18579-9.27765-12.34393-9.27765-12.34393a31.82748,31.82748,0,0,1,3.21979-2.84222l6.52258,8.69677,19.36859-19.36856A22.72613,22.72613,0,0,1,398.93078,367.04584Z"
      transform="translate(-166.83465 -147.13004)" :fill="secondColor" />
    <path d="M277.06218,379.23627a22.75667,22.75667,0,1,1-3.52685-12.19043A22.75667,22.75667,0,0,1,277.06218,379.23627Z"
      transform="translate(-166.83465 -147.13004)" :fill="color" />
    <path
      d="M273.53533,367.04584l-22.46387,22.45974c-1.41186-2.18579-9.27765-12.34393-9.27765-12.34393a31.82748,31.82748,0,0,1,3.21979-2.84222l6.52258,8.69677,19.3686-19.36856A22.72673,22.72673,0,0,1,273.53533,367.04584Z"
      transform="translate(-166.83465 -147.13004)" :fill="secondColor" />
    <path d="M1032.16535,752.87h-381a1,1,0,0,1,0-2h381a1,1,0,0,1,0,2Z" transform="translate(-166.83465 -147.13004)"
      fill="#3f3d56" />
  </svg>
</template>

<script>
import undraw from '@/components/icons/undraw/undraw-mixin.js'

export default {
  mixins: [undraw]
}
</script>
