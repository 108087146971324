var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.loading
      ? _c("div", { staticClass: "box__container edit-layout__preview" }, [
          _c(
            "svg",
            {
              staticStyle: {
                "fill-rule": "evenodd",
                "clip-rule": "evenodd",
                "stroke-linejoin": "round",
                "stroke-miterlimit": "2"
              },
              attrs: {
                width: "100%",
                height: "100%",
                viewBox: "0 0 4010 2558",
                version: "1.1",
                xmlns: "http://www.w3.org/2000/svg",
                "xmlns:xlink": "http://www.w3.org/1999/xlink",
                "xml:space": "preserve",
                "xmlns:serif": "http://www.serif.com/"
              }
            },
            [
              _c(
                "g",
                { attrs: { transform: "matrix(1,0,0,1,-111.331,-180.541)" } },
                [
                  _c("g", [
                    _c("rect", {
                      attrs: {
                        x: "111.331",
                        y: "452.855",
                        width: "4009.92",
                        height: "2285.07",
                        fill: _vm.layoutColors.colorBackground
                      }
                    }),
                    _c(
                      "g",
                      {
                        attrs: {
                          transform:
                            "matrix(1.16341,0,0,1,-18.1929,-4.26326e-14)"
                        }
                      },
                      [
                        _c("rect", {
                          attrs: {
                            x: "111.331",
                            y: "180.541",
                            width: "3446.69",
                            height: "79.894",
                            fill: _vm.layoutColors.colorTopNavBackground
                          }
                        }),
                        _c("rect", {
                          attrs: {
                            x: "111.331",
                            y: "260.434",
                            width: "3446.69",
                            height: "15",
                            fill: _vm.layoutColors.colorSecondary
                          }
                        }),
                        _c(
                          "g",
                          {
                            attrs: {
                              transform:
                                "matrix(1.16341,0,0,1,-18.1929,-4.26326e-14)"
                            }
                          },
                          [
                            _c("rect", {
                              attrs: {
                                x: "2113",
                                y: "190.541",
                                width: "189.912",
                                height: "54.898",
                                fill: _vm.layoutColors.colorTopNavText
                              }
                            })
                          ]
                        )
                      ]
                    ),
                    _c(
                      "g",
                      {
                        attrs: {
                          transform:
                            "matrix(1.39125,0,0,0.85868,-828.828,36.8047)"
                        }
                      },
                      [
                        _c("rect", {
                          attrs: {
                            x: "675.767",
                            y: "275.434",
                            width: "2882.25",
                            height: "224.089",
                            fill: _vm.layoutColors.colorWorkspacesNavBackground
                          }
                        })
                      ]
                    ),
                    _c("path", {
                      staticStyle: { fill: "var(--color-box)" },
                      attrs: {
                        d:
                          "M3558.02,500.523C3558.02,491.687 3550.85,484.523 3542.02,484.523L691.767,484.523C682.931,484.523 675.767,491.687 675.767,500.523L675.767,757.468C675.767,766.304 682.931,773.468 691.767,773.468L3542.02,773.468C3550.85,773.468 3558.02,766.304 3558.02,757.468L3558.02,500.523Z"
                      }
                    }),
                    _c("path", {
                      staticStyle: { fill: "var(--color-box)" },
                      attrs: {
                        d:
                          "M2094.46,836.937C2094.46,828.1 2087.29,820.937 2078.46,820.937C1932.74,820.937 837.487,820.937 691.767,820.937C682.931,820.937 675.767,828.1 675.767,836.937C675.767,936.115 675.767,1465.97 675.767,1565.15C675.767,1573.99 682.931,1581.15 691.767,1581.15C837.487,1581.15 1932.74,1581.15 2078.46,1581.15C2087.29,1581.15 2094.46,1573.99 2094.46,1565.15C2094.46,1465.97 2094.46,936.115 2094.46,836.937Z"
                      }
                    }),
                    _c("path", {
                      staticStyle: { fill: "var(--color-box)" },
                      attrs: {
                        d:
                          "M3558.02,836.937C3558.02,828.1 3550.85,820.937 3542.02,820.937L2156.04,820.937C2147.2,820.937 2140.04,828.1 2140.04,836.937L2140.04,1565.15C2140.04,1573.99 2147.2,1581.15 2156.04,1581.15L3542.02,1581.15C3550.85,1581.15 3558.02,1573.99 3558.02,1565.15C3558.02,1465.97 3558.02,936.115 3558.02,836.937Z"
                      }
                    }),
                    _c("path", {
                      attrs: {
                        d:
                          "M2094.46,1646.28C2094.46,1642.04 2092.77,1637.97 2089.77,1634.97C2086.77,1631.97 2082.7,1630.28 2078.46,1630.28L691.767,1630.28C687.524,1630.28 683.454,1631.97 680.454,1634.97C677.453,1637.97 675.767,1642.04 675.767,1646.28C675.767,1733.73 675.767,2153.16 675.767,2240.61C675.767,2244.85 677.453,2248.92 680.454,2251.92C683.454,2254.92 687.524,2256.61 691.767,2256.61L2078.46,2256.61C2082.7,2256.61 2086.77,2254.92 2089.77,2251.92C2092.77,2248.92 2094.46,2244.85 2094.46,2240.61L2094.46,1646.28Z",
                        fill: _vm.layoutColors.colorBox
                      }
                    }),
                    _c("path", {
                      attrs: {
                        d:
                          "M3558.02,1646.28C3558.02,1637.45 3550.85,1630.28 3542.02,1630.28C3396.34,1630.28 2301.71,1630.28 2156.04,1630.28C2147.2,1630.28 2140.04,1637.45 2140.04,1646.28L2140.04,2240.61C2140.04,2249.44 2147.2,2256.61 2156.04,2256.61L3542.02,2256.61C3550.85,2256.61 3558.02,2249.44 3558.02,2240.61C3558.02,2153.16 3558.02,1733.73 3558.02,1646.28Z",
                        fill: _vm.layoutColors.colorBox
                      }
                    }),
                    _c(
                      "g",
                      { attrs: { transform: "matrix(1,0,0,1,136.925,0)" } },
                      [
                        _c("rect", {
                          attrs: {
                            x: "2327.26",
                            y: "328.289",
                            width: "136.925",
                            height: "78.439",
                            fill: _vm.layoutColors.colorWorkspacesNavActive
                          }
                        })
                      ]
                    ),
                    _c(
                      "g",
                      { attrs: { transform: "matrix(1,0,0,1,376.152,0)" } },
                      [
                        _c("rect", {
                          attrs: {
                            x: "2327.26",
                            y: "328.289",
                            width: "136.925",
                            height: "78.439",
                            fill: _vm.layoutColors.colorWorkspacesNavText
                          }
                        })
                      ]
                    ),
                    _c(
                      "g",
                      { attrs: { transform: "matrix(1,0,0,1,615.379,0)" } },
                      [
                        _c("rect", {
                          attrs: {
                            x: "2327.26",
                            y: "328.289",
                            width: "136.925",
                            height: "78.439",
                            fill: _vm.layoutColors.colorWorkspacesNavText
                          }
                        })
                      ]
                    ),
                    _c(
                      "g",
                      { attrs: { transform: "matrix(1,0,0,1,854.606,0)" } },
                      [
                        _c("rect", {
                          attrs: {
                            x: "2327.26",
                            y: "328.289",
                            width: "136.925",
                            height: "78.439",
                            fill: _vm.layoutColors.colorWorkspacesNavText
                          }
                        })
                      ]
                    ),
                    _c(
                      "g",
                      { attrs: { transform: "matrix(1,0,0,1,1093.83,0)" } },
                      [
                        _c("rect", {
                          attrs: {
                            x: "2327.26",
                            y: "328.289",
                            width: "136.925",
                            height: "78.439",
                            fill: _vm.layoutColors.colorWorkspacesNavText
                          }
                        })
                      ]
                    ),
                    _c(
                      "g",
                      {
                        attrs: { transform: "matrix(0.762138,0,0,1,84.0098,0)" }
                      },
                      [
                        _c("rect", {
                          attrs: {
                            x: "908.857",
                            y: "601.343",
                            width: "173.423",
                            height: "119.367",
                            fill: _vm.layoutColors.colorPrimary
                          }
                        })
                      ]
                    ),
                    _c(
                      "g",
                      {
                        attrs: { transform: "matrix(0.762138,0,0,1,296.869,0)" }
                      },
                      [
                        _c("rect", {
                          attrs: {
                            x: "908.857",
                            y: "601.343",
                            width: "173.423",
                            height: "119.367",
                            fill: _vm.layoutColors.colorPrimary
                          }
                        })
                      ]
                    ),
                    _c(
                      "g",
                      {
                        attrs: { transform: "matrix(0.762138,0,0,1,509.728,0)" }
                      },
                      [
                        _c("rect", {
                          attrs: {
                            x: "908.857",
                            y: "601.343",
                            width: "173.423",
                            height: "119.367",
                            fill: _vm.layoutColors.colorPrimary
                          }
                        })
                      ]
                    ),
                    _c(
                      "g",
                      {
                        attrs: { transform: "matrix(0.762138,0,0,1,722.587,0)" }
                      },
                      [
                        _c("rect", {
                          attrs: {
                            x: "908.857",
                            y: "601.343",
                            width: "173.423",
                            height: "119.367",
                            fill: _vm.layoutColors.colorPrimary
                          }
                        })
                      ]
                    ),
                    _c("rect", {
                      attrs: {
                        x: "718.944",
                        y: "910.145",
                        width: "189.912",
                        height: "54.898",
                        fill: _vm.layoutColors.colorLink
                      }
                    }),
                    _c(
                      "g",
                      {
                        attrs: {
                          transform: "matrix(1.91317,0,0,1,-656.522,107.766)"
                        }
                      },
                      [
                        _c("rect", {
                          attrs: {
                            x: "718.944",
                            y: "910.145",
                            width: "189.912",
                            height: "54.898",
                            fill: _vm.layoutColors.colorText
                          }
                        })
                      ]
                    ),
                    _c(
                      "g",
                      {
                        attrs: {
                          transform: "matrix(1.91317,0,0,1,-180.426,107.766)"
                        }
                      },
                      [
                        _c("rect", {
                          attrs: {
                            x: "718.944",
                            y: "910.145",
                            width: "189.912",
                            height: "54.898",
                            fill: _vm.layoutColors.colorText
                          }
                        })
                      ]
                    ),
                    _c(
                      "g",
                      {
                        attrs: {
                          transform: "matrix(1.09449,0,0,1,918.878,107.766)"
                        }
                      },
                      [
                        _c("rect", {
                          attrs: {
                            x: "718.944",
                            y: "910.145",
                            width: "189.912",
                            height: "54.898",
                            fill: _vm.layoutColors.colorText
                          }
                        })
                      ]
                    ),
                    _c(
                      "g",
                      {
                        attrs: {
                          transform: "matrix(1.09449,0,0,1,918.878,204.966)"
                        }
                      },
                      [
                        _c("rect", {
                          attrs: {
                            x: "718.944",
                            y: "910.145",
                            width: "189.912",
                            height: "54.898",
                            fill: _vm.layoutColors.colorInfo
                          }
                        })
                      ]
                    ),
                    _c(
                      "g",
                      {
                        attrs: {
                          transform: "matrix(1.09449,0,0,1,918.878,303.735)"
                        }
                      },
                      [
                        _c("rect", {
                          attrs: {
                            x: "718.944",
                            y: "910.145",
                            width: "189.912",
                            height: "54.898",
                            fill: _vm.layoutColors.colorSuccess
                          }
                        })
                      ]
                    ),
                    _c(
                      "g",
                      {
                        attrs: {
                          transform: "matrix(1.09449,0,0,1,918.878,405.53)"
                        }
                      },
                      [
                        _c("rect", {
                          attrs: {
                            x: "718.944",
                            y: "910.145",
                            width: "189.912",
                            height: "54.898",
                            fill: _vm.layoutColors.colorWarning
                          }
                        })
                      ]
                    ),
                    _c(
                      "g",
                      {
                        attrs: {
                          transform: "matrix(1.09449,0,0,1,918.878,507.406)"
                        }
                      },
                      [
                        _c("rect", {
                          attrs: {
                            x: "718.944",
                            y: "910.145",
                            width: "189.912",
                            height: "54.898",
                            fill: _vm.layoutColors.colorDanger
                          }
                        })
                      ]
                    ),
                    _c(
                      "g",
                      {
                        attrs: {
                          transform: "matrix(1.91317,0,0,1,-656.522,204.966)"
                        }
                      },
                      [
                        _c("rect", {
                          attrs: {
                            x: "718.944",
                            y: "910.145",
                            width: "189.912",
                            height: "54.898",
                            fill: _vm.layoutColors.colorText
                          }
                        })
                      ]
                    ),
                    _c(
                      "g",
                      {
                        attrs: {
                          transform: "matrix(1.91317,0,0,1,-180.426,204.966)"
                        }
                      },
                      [
                        _c("rect", {
                          attrs: {
                            x: "718.944",
                            y: "910.145",
                            width: "189.912",
                            height: "54.898",
                            fill: _vm.layoutColors.colorText
                          }
                        })
                      ]
                    ),
                    _c(
                      "g",
                      {
                        attrs: {
                          transform: "matrix(1.91317,0,0,1,-656.522,303.735)"
                        }
                      },
                      [
                        _c("rect", {
                          attrs: {
                            x: "718.944",
                            y: "910.145",
                            width: "189.912",
                            height: "54.898",
                            fill: _vm.layoutColors.colorText
                          }
                        })
                      ]
                    ),
                    _c(
                      "g",
                      {
                        attrs: {
                          transform: "matrix(1.91317,0,0,1,-180.426,303.735)"
                        }
                      },
                      [
                        _c("rect", {
                          attrs: {
                            x: "718.944",
                            y: "910.145",
                            width: "189.912",
                            height: "54.898",
                            fill: _vm.layoutColors.colorText
                          }
                        })
                      ]
                    ),
                    _c(
                      "g",
                      {
                        attrs: {
                          transform: "matrix(1.91317,0,0,1,-656.522,405.53)"
                        }
                      },
                      [
                        _c("rect", {
                          attrs: {
                            x: "718.944",
                            y: "910.145",
                            width: "189.912",
                            height: "54.898",
                            fill: _vm.layoutColors.colorText
                          }
                        })
                      ]
                    ),
                    _c(
                      "g",
                      {
                        attrs: {
                          transform: "matrix(1.91317,0,0,1,-180.426,405.53)"
                        }
                      },
                      [
                        _c("rect", {
                          attrs: {
                            x: "718.944",
                            y: "910.145",
                            width: "189.912",
                            height: "54.898",
                            fill: _vm.layoutColors.colorText
                          }
                        })
                      ]
                    ),
                    _c(
                      "g",
                      {
                        attrs: {
                          transform: "matrix(1.91317,0,0,1,-656.522,504.844)"
                        }
                      },
                      [
                        _c("rect", {
                          attrs: {
                            x: "718.944",
                            y: "910.145",
                            width: "189.912",
                            height: "54.898",
                            fill: _vm.layoutColors.colorText
                          }
                        })
                      ]
                    ),
                    _c(
                      "g",
                      {
                        attrs: {
                          transform: "matrix(1.91317,0,0,1,-180.426,504.844)"
                        }
                      },
                      [
                        _c("rect", {
                          attrs: {
                            x: "718.944",
                            y: "910.145",
                            width: "189.912",
                            height: "54.898",
                            fill: _vm.layoutColors.colorText
                          }
                        })
                      ]
                    )
                  ])
                ]
              )
            ]
          )
        ])
      : _c(
          "div",
          { staticClass: "box__container edit-layout__preview" },
          [_c("GhostLoading", { attrs: { type: "card-vertical" } })],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }