var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _vm.loading
        ? _c("GhostLoading", {
            attrs: { type: "table", title: _vm.TABLE_TITLE }
          })
        : _vm.error
        ? _c("UnexpectedError")
        : !_vm.rowCount
        ? _c("NoData", { attrs: { title: _vm.TABLE_TITLE } })
        : _c(
            "div",
            [
              _c("Table", {
                staticClass: "mt-2",
                attrs: {
                  tableId: "d8abcb3f-4adb-4bc1-a013-531aa3a726ca",
                  title: _vm.TABLE_TITLE,
                  rowsPerPage: 10,
                  headers: _vm.headers,
                  rows: _vm.rows
                },
                on: { "action-SHOW_UNIFIABLE_PEOPLE": _vm.showUnifiablePeople }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }