var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.params
        ? _c("div", { staticClass: "box__container" }, [
            _c(
              "div",
              [
                _c(
                  "BaseButton",
                  {
                    attrs: {
                      disabled:
                        _vm.pdfDisabled || _vm.loading || _vm.loadingPDF,
                      animated: _vm.loadingPDF
                    },
                    on: { click: _vm.createPdf }
                  },
                  [_vm._v("PDF anzeigen")]
                )
              ],
              1
            ),
            _vm.params
              ? _c(
                  "div",
                  [
                    _c("HtmlEditor", {
                      attrs: {
                        isFontParams: "",
                        isPreviewButton: "",
                        disabled: _vm.loadingPDF,
                        textPlaceholderOptions: _vm.placeholdersOptions,
                        previewHTML: _vm.previewHtml.text
                      },
                      on: { onTogglePreviewEnabled: _vm.handlePreview },
                      model: {
                        value: _vm.html,
                        callback: function($$v) {
                          _vm.html = $$v
                        },
                        expression: "html"
                      }
                    }),
                    _c(
                      "div",
                      { staticClass: "checkbox-input__container mt-2" },
                      [
                        _c("input-check-box-item", {
                          attrs: {
                            disabled: _vm.loadingPDF,
                            label: "Die Bezeichnung auf dem Deckblatt andrucken"
                          },
                          model: {
                            value: _vm.params.showBezeichnung,
                            callback: function($$v) {
                              _vm.$set(_vm.params, "showBezeichnung", $$v)
                            },
                            expression: "params.showBezeichnung"
                          }
                        }),
                        _c("input-check-box-item", {
                          attrs: {
                            disabled: _vm.loadingPDF,
                            label: "Wertentwicklung einzelner Fonds anzeigen"
                          },
                          model: {
                            value: _vm.params.showDetailsChart,
                            callback: function($$v) {
                              _vm.$set(_vm.params, "showDetailsChart", $$v)
                            },
                            expression: "params.showDetailsChart"
                          }
                        }),
                        _c("input-check-box-item", {
                          attrs: {
                            disabled: _vm.loadingPDF || _vm.hatPlan,
                            label: "Fondsdetails in Relation zur Anlagesumme"
                          },
                          model: {
                            value: _vm.params.detailsRelativ,
                            callback: function($$v) {
                              _vm.$set(_vm.params, "detailsRelativ", $$v)
                            },
                            expression: "params.detailsRelativ"
                          }
                        }),
                        _c("input-check-box-item", {
                          attrs: {
                            disabled: _vm.loadingPDF,
                            label: "Anonymisieren"
                          },
                          model: {
                            value: _vm.params.anonymisieren,
                            callback: function($$v) {
                              _vm.$set(_vm.params, "anonymisieren", $$v)
                            },
                            expression: "params.anonymisieren"
                          }
                        })
                      ],
                      1
                    ),
                    _c("h5", { staticClass: "box__title mt-3" }, [
                      _vm._v("Fondsdokumente")
                    ]),
                    _c(
                      "div",
                      { staticClass: "checkbox-input__container" },
                      [
                        _c("input-check-box-item", {
                          attrs: {
                            disabled: _vm.loadingPDF,
                            label: "Factsheets im Anhang"
                          },
                          model: {
                            value: _vm.params.showFactsheets,
                            callback: function($$v) {
                              _vm.$set(_vm.params, "showFactsheets", $$v)
                            },
                            expression: "params.showFactsheets"
                          }
                        }),
                        _c("input-check-box-item", {
                          attrs: {
                            disabled: _vm.loadingPDF,
                            label:
                              "Basisinformationsblätter (PRIIP-BIBs) im Anhang"
                          },
                          model: {
                            value: _vm.params.showKIDs,
                            callback: function($$v) {
                              _vm.$set(_vm.params, "showKIDs", $$v)
                            },
                            expression: "params.showKIDs"
                          }
                        }),
                        _c("input-check-box-item", {
                          attrs: {
                            disabled: _vm.loadingPDF,
                            label:
                              "Links zu Berichten, Verkaufsprospekten, PRIIP-BIBs und Videospots"
                          },
                          model: {
                            value: _vm.params.showDocuments,
                            callback: function($$v) {
                              _vm.$set(_vm.params, "showDocuments", $$v)
                            },
                            expression: "params.showDocuments"
                          }
                        }),
                        _c("input-check-box-item", {
                          attrs: {
                            label: "zus. Fondsdokumente für bestehendes Depot",
                            disabled:
                              _vm.loadingPDF || !_vm.params.inclactualEnabled
                          },
                          model: {
                            value: _vm.params.inclactual,
                            callback: function($$v) {
                              _vm.$set(_vm.params, "inclactual", $$v)
                            },
                            expression: "params.inclactual"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e(),
      _c(
        "BaseModal",
        {
          ref: "infoModal",
          attrs: { showDefaultButtons: false },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("PhFile", {
                    staticStyle: { color: "var(--color-success)" }
                  }),
                  _vm._v(" Anlageempfehlung PDF ")
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", [
            _c("span", [_vm._v("'Das Dokument konnte nicht geladen werden'")])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }