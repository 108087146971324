var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "modal",
    attrs: { size: "lg", showDefaultButtons: false, autoClose: false },
    on: { close: _vm.onClose },
    scopedSlots: _vm._u([
      {
        key: "modalTitle",
        fn: function() {
          return [
            _c("div", { staticClass: "clearfix" }, [
              _vm._v("Formulare anpassen")
            ])
          ]
        },
        proxy: true
      },
      {
        key: "default",
        fn: function() {
          return [
            !_vm.loading && _vm.internList.length
              ? _c("Table", {
                  attrs: {
                    headers: _vm.headers,
                    rows: _vm.internList,
                    rowId: "label",
                    hidePagination: "",
                    rowsPerPage: _vm.internList.length
                  },
                  scopedSlots: _vm._u(
                    [
                      _vm._l(_vm.userLevels, function(userLevel) {
                        return {
                          key: userLevel,
                          fn: function(row) {
                            return [
                              _c(
                                "div",
                                {
                                  key: userLevel,
                                  staticClass: "table-input--centered"
                                },
                                [
                                  _c("InputToggleSwitch", {
                                    attrs: {
                                      disabled: _vm.isRowConfigDisabled(
                                        userLevel,
                                        row
                                      )
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.setSubLevel(
                                          row,
                                          userLevel,
                                          $event
                                        )
                                      }
                                    },
                                    model: {
                                      value: row.config[userLevel],
                                      callback: function($$v) {
                                        _vm.$set(row.config, userLevel, $$v)
                                      },
                                      expression: "row.config[userLevel]"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        }
                      })
                    ],
                    null,
                    true
                  )
                })
              : _vm.loading
              ? _c("GhostLoading", { attrs: { type: "table" } })
              : _c("NoData", { attrs: { noIcon: "" } })
          ]
        },
        proxy: true
      },
      {
        key: "footer",
        fn: function() {
          return [
            _c(
              "BaseButton",
              {
                staticClass: "mr-3",
                attrs: { isSecondary: "" },
                on: {
                  click: function($event) {
                    return _vm.onRestoreDefault()
                  }
                }
              },
              [_vm._v("Zurücksetzen")]
            ),
            _c(
              "BaseButton",
              {
                on: {
                  click: function($event) {
                    return _vm.onSaveConfig()
                  }
                }
              },
              [_vm._v("Schließen")]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }