<template>
  <BaseFileSelect
    asButton
    :disabled="isDisabled"
    :isSecondary="!isMainAction"
    :accept="accept"
    :multiple="multiple"
    @files="onFileSelection"
  >
    <component v-if="iconComponent" :is="iconComponent" :size="size"/> {{ responsiveLabel }}
  </BaseFileSelect>
</template>
<script>
import actionMixin from './header-actions-mixin.js';
import BaseFileSelect from '@/components/fileUpload/BaseFileSelect.vue';

export default {
  mixins: [actionMixin],
  props: {
    accept: {
      type: String,
    },
    multiple: {
      type: Boolean,
    },
  },
  methods: {
    onFileSelection(files) {
      this.emitAction(files);
    },
  },
  components: {
    BaseFileSelect,
  },
}
</script>
