var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DragnDropArea",
    {
      staticClass: "form-select-file-button__container mb-3",
      attrs: { hoverText: "Hochladen" },
      on: { files: _vm.onFileChange }
    },
    [
      _c("BaseFileSelect", { on: { files: _vm.onFileChange } }, [
        _vm._v(" " + _vm._s(_vm.label) + " ")
      ]),
      _c("span", [_vm._v(_vm._s(_vm.filename))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }