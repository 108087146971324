var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "box__container-wrap": _vm.lastNews } },
    [
      _vm.enableFilter
        ? _c("BaseFilter", {
            attrs: {
              filterId: "newsMSC",
              title: "News Filter",
              metadata: _vm.searchMetadata,
              defaultOptions: _vm.defaultOptions,
              configFilter: _vm.configFilter
            },
            on: {
              onFilter: function($event) {
                return _vm.handleSearch($event)
              }
            }
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "box__container",
          class: { "box__container-rows": _vm.lastNews }
        },
        [
          _vm.loading
            ? _c("GhostLoading", {
                attrs: {
                  type: "table",
                  title: _vm.tableTitle,
                  config: { table: { rows: _vm.lastNews ? 5 : 3 } }
                }
              })
            : _vm.rows.length === 0
            ? _c("NoData", {
                attrs: { noIcon: _vm.lastNews, title: _vm.tableTitle }
              })
            : _c("Table", {
                attrs: {
                  title: _vm.tableTitle,
                  tableId: "8769c462-fa11-4fdf-951d-1eb9c3ba4245",
                  headers: _vm.headers,
                  rows: _vm.rows,
                  selected: _vm.selectedNews,
                  rowsPerPage: _vm.lastNews ? 5 : 10,
                  hidePagination: _vm.lastNews,
                  mobileConfig: _vm.mobileConfig
                },
                on: {
                  selected: function($event) {
                    return _vm.$emit("selectedNews", $event)
                  },
                  "click-title": _vm.showNews,
                  "action-EDIT": function($event) {
                    return _vm.handleEdit($event.id)
                  }
                }
              })
        ],
        1
      ),
      !_vm.loading && _vm.lastNews && !_vm.$isSmallScreen
        ? _c(
            "div",
            { staticClass: "bottom-row text-right mt-3" },
            [
              _c("router-link", { attrs: { to: { name: "news-msc" } } }, [
                _vm._v(" weitere... ")
              ])
            ],
            1
          )
        : _vm._e(),
      _c(
        "BaseModal",
        {
          ref: "showNewsModal",
          attrs: {
            labelButtonConfirm: "Ok",
            showCancelButton: false,
            size: "xl",
            modalTitle: _vm.modalTitle
          }
        },
        [
          _c("div", {
            domProps: {
              innerHTML: _vm._s(_vm.sanitize(_vm.selectedNewsItem.body))
            }
          }),
          _vm.selectedNewsItem.files && _vm.selectedNewsItem.files.length
            ? _c("div", { staticClass: "my-4" }, [
                _c("div", { staticClass: "box__title" }, [_vm._v("Dateien")]),
                _c(
                  "div",
                  { staticClass: "layout__negative-margin--8" },
                  _vm._l(_vm.selectedNewsItem.files, function(file) {
                    return _c("DownloadLink", {
                      key: file.id,
                      attrs: {
                        title: file.bezeichnung,
                        filename: file.bezeichnung,
                        downloadServicePath: "/get_simple_file",
                        queryParams: {
                          fileId: file.id
                        }
                      }
                    })
                  }),
                  1
                )
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }