<template>
  <div class="sofunktionierts_container">
    <div class="banner">
      <editable-frame type="aa_sofunktionierts_title_page" />
    </div>
    <h2>In 4 Schritten zum Depot mit der gewünschten VV-Strategie</h2>
    <hr />
    <div class="vierspalten_wrapper">
      <div class="vierspalten">
        <div>
          <div class="spalten_image">
            <img alt="Icon für die Erklärung" src="@/../public/img/cms/anlageas1.png" />
          </div>
          <div class="spalten_banner">
            <h3>1. Über verfügbare Strategien informieren. </h3>
          </div>
          <hr />
          <div class="spalten_text">
            <p>
              Unter dem Menüpunkt „VERMÖGENSVERWALTUNGSSTRATEGIEN ANSEHEN“ informieren Sie sich umfassend und selbständig 
              über die zur Verfügung stehenden Vermögensverwaltungsstrategien innerhalb des Anlageassistenten.
            </p>
            <p>
              Sie können sowohl den geplanten Einmalbeitrag als auch die Sparpläne erfassen und können dann die simulierte 
              Wertentwicklung der Strategie der letzten fünf Jahre vergleichen. 
            </p>
            <p>
              Ausserdem können Sie an dieser Stelle auch das tagesaktuelle Factsheet der Strategie inkl. aller relevanten Daten 
              wie Kosten etc. abrufen. 
            </p>
            <p>
              Bitte beachten Sie hierbei: Die Angaben beziehen sich auf die Vergangenheit und beruhen auf einer simulierten Wertentwicklung, 
              welcher die tatsächliche Wertentwicklung der derzeit in der Strategie vorhandenen Finanzinstrumente zugrunde liegt. 
              Die frühere Wertentwicklung ist kein zuverlässiger Indikator für zukünftige Ergebnisse.. 
            </p>
            <p>
              Ausserdem ist es wichtig für Sie zu wissen, dass unser Anlageassistent keine persönliche Anlageberatung für Sie bietet. 
              Er ist für Kunden konzipiert, die als Selbstentscheider eine eigene Investmententscheidung in die Tat umsetzen wollen. 
              Für technische Fragen zur Nutzung des Anlageassistents oder eine alternative persönliche Beratung stehen wir Ihnen gerne über 
              unser Kontaktformular zur Verfügung. 
            </p>
          </div>
          <hr />
        </div>
        <div>
          <div class="spalten_image">
            <img alt="Icon für die Erklärung" src="@/../public/img/cms/anlageas2.png" />
          </div>
          <div class="spalten_banner">
            <h3>2.Geeignetheitstest durchführen</h3>
          </div>
          <hr />
          <div class="spalten_text">
            <p>
              Nachdem Sie die Anlagestrategien verglichen haben können Sie nach Rückkehr auf die Startseite mit dem Button „weiter“ 
              starten und herausfinden, welche Anlagestrategie für Sie geeignet ist. 
            </p>
            <p>
              Hierzu benötigen wir Ihre Mailadresse um die rechtlich notwendigen Unterlagen zu versenden. Nach Erfassung Ihrer 
              Mailadresse drücken Sie bitte „PIN generieren“ und erhalten per Mail eine PIN zugesand um den Anlageassistenten zu starten. 
              Vergessen Sie nicht vorher zu bestätigen, dass Sie volljährig und geschäftsfähig sind und das Depot auf eigene Rechnung 
              eröffnen werden sowie in Deutschland einen Wohnsitz haben und steuerpflichtig sind. Ausserdem dürfen die FACTA Bestimmungen 
              nicht auf Sie anwendbar sein, Sie dürfen also z.B. nicht die US-Staatsbürgerschaft besitzen. 
            </p>
            <p>
              Nachdem Sie die PIN aus der versendeten Mail erfasst haben, gelangen Sie in den sogenannten Geeignetheitstest. 
              Hier erfassen Sie Schritt für Schritt alle notwendigen Informationen, Risikobereitschaft, Nachhaltigkeitspräferenzen, 
              Anlagehorizont, Schulabschluss sowie Ihren bisherigen Erfahrungen und Kenntnissen zu Kapitalanlagen. 
            </p>
            <p>
              Im Anschluss erhalten Sie die Broschüre „Grundlagenwissen zu Wertpapieren & Investmentfonods“ per Mail und sollten sich 
              mit dieser umfassend beschäftigen und auseinandersetzen. 
            </p>
            <p>
              Nun bestätigen Sie noch ob Sie beruflich oder privat Erfahrungen mit Finanzinstrumenten gesammelt haben und welche Art 
              der Finanzdienstleistung Sie bisher genutzt haben und erfassen Ihre finanziellen Verhältnisse, welche zu Art und Umfang 
              der geplanten Kapitalanlage passen müssen. 
            </p>
            <p>
              Nach der Angabe über welche Notfallreserven Sie verfügen, erhalten Sie nun eine Auswahl, welche Strategien für Sie geeignet sind 
              und abgeschlossen werden können. 
            </p>
          </div>
          <hr />
        </div>
        <div>
          <div class="spalten_image">
            <img alt="Icon für die Erklärung" src="@/../public/img/cms/anlageas3.png" />
          </div>
          <div class="spalten_banner">
            <h3>3. Eröffnungsunterlagen für Ihre Anlagestrategie erstellen </h3>
          </div>
          <hr />
          <div class="spalten_text">
            <p>
             Nachdem Sie Ihre gewünschte Strategie selbständig ausgewählt haben, können Sie diese nun eröffnen. Dazu benötigen Sie ein Depot. 
            </p>
            <p>
              Sie entscheiden ob Sie ein Einzeldepot, Gemeinschaftsdepot oder Minderjährigendepot eröffnen wollen. 
            </p>
            <p>
             Nun registrieren Sie sich als neuer Kunde oder melden sich mit Ihren bereits vorhandenen Kundendaten (Kundennummer / Passwort) 
             im Anlageassistent an. Dann schließen Sie mit Eingabe Ihrer Stammdaten den Antrag auf Depoteröffnung erfolgreich ab. 
             Hierbei legen wir hohen Wert auf die Einhaltung des Datenschutzes und die Sicherheit Ihrer Daten. 
             Nach Kenntnisnahme Konto- und Depotbezogener Unterlagen und abschließender rechtlicher Hinweise steht Ihrem 
             Investment nichts mehr im Wege. 
            </p>
            <p>
              Sie erhalten dann folgende Unterlagen:
              <ul>
                <li>Vermögensverwaltungsvertrag (inkl. Kundendokumentation)</li>
                <li>Factsheet Anlagestrategie</li>
                <li>Kosteninformationen</li>
                <li>Vorvertragliche Kundeninformationen (inkl. Widerrufsbelehrung)</li>
                <li>Grundlagenwissen zu Wertpapieranlagen (Basisinformationen)</li>
              </ul>
            </p>
            <p>
              Diese Unterlagen enthalten wichtige Informationen zu der Vermögensverwaltung. Sie sollten daher von Ihnen gelesen werden, 
              bevor Sie mit dem Vertragsabschluss fortfahren. Diese Dokumente finden Sie ebenfalls in der FinanceApp bzw. FinanceCloud 
              in Ihrem Dokumentenarchiv. 
            </p>
          </div>
          <hr />
        </div>
        <div>
          <div class="spalten_image">
            <img alt="Icon für die Erklärung" src="@/../public/img/cms/anlageas4.png" />
          </div>
          <div class="spalten_banner">
            <h3>4. Depot für Anlagestrategie online eröffnen</h3>
          </div>
          <hr />
          <div class="spalten_text">
            <p>
              Führen Sie nun die „Online Depoteröffnung“ zur Verfügung. Hier können Sie sich zwischen dem Post-Ident oder Video-Ident 
              der Deutschen Post AG entscheiden. Bei diesem Verfahren ist keinerlei Unterschrift oder Ausdruck der Unterlagen notwendig. 
              Der Abschluss erfolgt durch Ihre Zustimmungen im Eröffnungsprozess und wird durch die Legitimation finalisiert
            </p>
            <!-- <p>
              Wenn Sie die „Klassische Depoteröffnung“ gewählt haben ist es nun notwendig den Antrag zur Depoteröffnung nun an die FFB 
              (FIL Fondsbank GmbH) weiterzuleiten, und somit die Abbuchung der gewünschten Beträge zu veranlassen. Hierzu drucken Sie bitte 
              die Unterlagen komplett aus und bringen Sie zu Ihrem Finanzberater. 
            </p> -->
            <p>
              Dieser führt an Hand Ihres Personalausweises oder Reisepasses Ihre Legitimation durch und leitet die Unterlagen an die FFB 
              (FIL Fondsbank GmbH) weiter. Dort werden die Unterlagen auf Vollständigkeit und Richtigkeit geprüft und Ihr Depot incl. 
              Verrechnungskonto wird innerhalb weniger Tage eröffnet. 
            </p>
            <p>
              Über die erfolgreiche Depoteröffnung und die Abbuchung werden Sie postalisch informiert. 
              Ebenso über alle weiteren Umsätze und Bewegungen innerhalb Ihres Kontos und Depots.
            </p>
            <p>
              Mit Ihrem persönlichen Login steht Ihnen nach erfolgreicher Depoteröffnung das komplette Fondsuniversum der FFB (FIL Fondsbank GmbH) zur Verfügung.
            </p>
          </div>
          <hr />
        </div>
      </div>
    </div>

    <div class>
      <base-button @click="$router.push('assistent')">ZUM ANLAGEASSISTENT</base-button>
      <hr />
    </div>

    <div class>
      <div class="text_only">
        
      </div>
      <hr />
    </div>
    <risiko-hinweise />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CORE_TYPES from "@/store/core/types";
import EditableFrame from "@/components/cms/EditableFrame.vue";
import RisikoHinweise from "@/components/cms/parts/RisikoHinweise.vue";
import BaseButton from "@/components/core/BaseButton.vue";

export default {
  components: {
    EditableFrame,
    BaseButton,
    RisikoHinweise
  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>
<style scoped>
.sofunktionierts_container {
  margin: 0 auto;
  padding-left: 1em;
  padding-right: 1em;
  max-width: 1200px;
}

.spalten_banner {
  margin-top: 0.75em;
  font-weight: bold;
}

.spalten_image {
  max-width: 300px;
}

.banner {
  font-size: 26px;
  text-align: left;
  color: var(--color-text);
}
.text_only_wrapper {
  padding-top: 2em;
  padding-bottom: 2em;
}
.text_only {
  margin: 0 auto;
  text-align: justify;
}

ul {
  text-align: start;
}

.vierspalten_wrapper {
  padding-top: 2em;
  padding-bottom: 2em;
}

.vierspalten {
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 4em;
}

.spalten_text {
  text-align: justify;
}

#fullwidth {
  justify-self: center;
  grid-area: fullwidth;
}

a {
  color: var(--color-link);
}
@media only screen and (max-width: 800px) {
  .vierspalten {
    grid-template-columns: 1fr;
  }
}
</style>