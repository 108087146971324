var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._g(
      { staticClass: "row-content" },
      _vm.isClickable
        ? {
            click: function(event) {
              return _vm.$emit("clickRow", event)
            }
          }
        : {}
    ),
    [
      _vm._t("label", function() {
        return [_vm._v(" " + _vm._s(_vm.label) + " ")]
      }),
      _c(
        "div",
        { class: { "d-flex": _vm.isClickable } },
        [
          _vm._t("value", function() {
            return [_vm._v(" " + _vm._s(_vm.value) + " ")]
          }),
          _vm.isClickable
            ? _c("ph-caret-right", {
                staticClass: "ml-2",
                staticStyle: { "flex-shrink": "0" },
                attrs: { weight: "bold", size: 20 }
              })
            : _vm._e()
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }