import Vue from 'vue';

import CUSTOMER_CATEGORY_TYPES from './types';
import { getInitialState } from './index';


export default {

  [CUSTOMER_CATEGORY_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState());
  },

  [CUSTOMER_CATEGORY_TYPES.MUTATIONS.GET_CUSTOMER_CATEGORY_SUCCESS](state, payload) {
    Vue.set(state, 'customerCategory', { ...payload, });
  },

  [CUSTOMER_CATEGORY_TYPES.MUTATIONS.ADD_CUSTOMER_CATEGORY_EDITED](state, payload) {
    Vue.set(state, 'customerCategoryEdited', { ...payload, });
  },

  [CUSTOMER_CATEGORY_TYPES.MUTATIONS.RESET_CUSTOMER_CATEGORY_EDITED](state) {
    Vue.set(state, 'customerCategoryEdited', {});
  },

}
