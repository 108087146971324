<template>
  <div>
    <ImageUpload
      :tid="_generateTidFromString('user-picture')"
      class="scan-container"
      :image="userPicture"
      title="Profilbild"
      hasDeleteButton
      :loading="loading"
      :config="imageUploadConfig"
      :disabled="loading || disabled"
      @file="uploadImage($event)"
      @change="uploadImage($event)"
      @delete="deleteImage"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ANSPRECHPARTNER_TYPES from '@/store/ansprechpartner/types';
import ImageUpload from '@/components/fileUpload/ImageUpload.vue';
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import { base64ToArrayBuffer } from '@/helpers/download-helper.js';

export default {
  mixins: [InteractiveHelpCommonsMixin],
  props: {
    personId: {
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  components: {
    ImageUpload,
  },
  data() {
    return {
      userPicturePreview: null,
      loading: false,
      imageUploadConfig: {
        height: '212px',
        fit: 'contain',
      },
    };
  },
  computed: {
    ...mapGetters({
      userPictureData: ANSPRECHPARTNER_TYPES.GETTERS.GET_USER_PICTURE_DATA,
      tempUserPictureData: ANSPRECHPARTNER_TYPES.GETTERS.GET_TEMP_USER_PICTURE_DATA,
    }),
    userPicture() {
      if (this.userPicturePreview) {
        return this.userPicturePreview;
      } else {
        return this.getImgSrc(this.userPictureData);
      }
    },
  },
  watch: {
    personId(newVal) {
      if (newVal) {
        this.$store.dispatch(ANSPRECHPARTNER_TYPES.ACTIONS.GET_USER_PICTURE, {personId: String(newVal)})
      }
    }
  },
  mounted() {
    if(this.personId) {
      this.$store.dispatch(ANSPRECHPARTNER_TYPES.ACTIONS.GET_USER_PICTURE, {personId: String(this.personId)})

    // get the temporary saved user picture (used for example when creating new ansprechpartner)
    } else if (this.tempUserPictureData) {
      this.userPicturePreview = URL.createObjectURL(this.tempUserPictureData.file)

    // get default user picture
    } else {
      this.$store.dispatch(ANSPRECHPARTNER_TYPES.ACTIONS.GET_USER_PICTURE, {})
    }
  },
  methods: {
    getImgSrc(image) {
      if (image && image.bytes) {
        const byteArray = base64ToArrayBuffer(image.bytes);
        const file = new Blob([byteArray], { type: image.responseContentType?.mimeType });
        const linkUrl = URL.createObjectURL(new File([file], image.fileName, {type: file.type}));
        return linkUrl;
      }

      return '';
    },
    uploadImage(file) {
      this.resetTempUserPicture()
      this.userPicturePreview = URL.createObjectURL(file);

      if (this.personId) {
        this.loading = true;

        this.$store.dispatch(ANSPRECHPARTNER_TYPES.ACTIONS.SAVE_USER_PICTURE, { file, personId: String(this.personId)})
          .finally(() => this.loading = false);

      } else {
        this.$store.commit(ANSPRECHPARTNER_TYPES.MUTATIONS.SET_TEMP_USER_PICTURE, { file })
      }

    },
    deleteImage() {
      this.resetTempUserPicture()
      this.userPicturePreview = null;

      if (this.personId) {
        this.loading = true;

        this.$store.dispatch(ANSPRECHPARTNER_TYPES.ACTIONS.DELETE_USER_PICTURE, {personId: String(this.personId)})
          .finally(() => this.loading = false)

      } else {
        this.$store.commit(ANSPRECHPARTNER_TYPES.MUTATIONS.GET_USER_PICTURE_SUCCESS, {})
      }

    },
    resetTempUserPicture() {
      this.$store.commit(ANSPRECHPARTNER_TYPES.MUTATIONS.SET_TEMP_USER_PICTURE, null)
    },
  },
}
</script>

<style scoped>
  .scan-container {
    margin: 0;
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }

  .scan-container.no-image {
    max-width: 344px;
  }
</style>
