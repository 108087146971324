<template>
  <div>
    <component @click="show = true" :is="icon" :size="iconSize"/>
    <transition name="fade-fullscreen-panel">
      <div class="base-fullscreen-menu__content" v-if="show" @click="closeModal">
        <div class="base-fullscreen-menu__list-contaner">
          
          <slot/>

        </div>
        <div class="base-fullscreen-menu__close">
          <ph-x @click="closeModal" :size="32" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { PhQuestion, PhX } from "phosphor-vue"

export default {
  data: function() {
    return {
      show: false
    }
  },
  methods: {
    closeModal() {
      this.show = false;
    }
  },
  props: {
    icon: { 
      type: [Object, Function], 
      default: () => PhQuestion 
    },
    iconSize: {
      type: Number,
      default: 32
    }
  },
  components: {
    PhX
  }
}
</script>

<style scoped>

.base-fullscreen-menu__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  height: calc(100vh - 43px);
  width: 100vw;
  background-color: var(--color-top-nav-background);
  color: var(--color-top-nav-text);
  z-index: 1000;
}

.base-fullscreen-menu__list-contaner {
  display: flex;
  flex-direction: column;
  margin: 2em;
  text-align: left;
  line-height: 1em;
}

.base-fullscreen-menu__close {
  margin: 2em;
  line-height: 1em;
  color: var(--color-top-nav-text);
}

</style>