<template>
   <Aenderungslog :typ ="'vermittlerdaten'"/>
</template>
<script>
import Aenderungslog from '@/views/versicherungen/Aenderungslog.vue'
export default {
   components:{
    Aenderungslog
   }
}
</script>
