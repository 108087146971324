<template>
  <hr class="form-divider"/>
</template>

<script>
export default {

}
</script>

<style scoped>
  .form-divider {
    border: 1px solid var(--color-primary);
    margin-top: 2em;
    margin-bottom: 2em;
  }
</style>