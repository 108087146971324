var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "input-forms__container",
      class: { "input-forms__label-value--inline": _vm.isInlineLabelValues }
    },
    [
      _c("label", { staticClass: "input-forms__label-container" }, [
        _vm.title
          ? _c("div", {
              staticClass: "input-forms__label-content",
              class: _vm.labelClass,
              domProps: { innerHTML: _vm._s(_vm.sanitize(_vm.title)) }
            })
          : _vm._e(),
        _vm.config && _vm.config.subtitle
          ? _c("div", {
              staticClass: "text-smaller",
              domProps: { innerHTML: _vm._s(_vm.sanitize(_vm.config.subtitle)) }
            })
          : _vm._e()
      ]),
      _c(
        "div",
        {
          class: {
            "input-forms__input-container radiobox-input__container":
              _vm.vertical,
            "input-forms__input-container__horizontal": !_vm.vertical,
            "input-forms__inline-values": _vm.isInlineValues
          }
        },
        [
          _vm._l(_vm.values, function(val) {
            return _c(
              "div",
              { key: val.value },
              [
                _c("InputRadioBoxItem", {
                  class: !_vm.vertical && "mr-3",
                  attrs: {
                    name: _vm.groupName,
                    value: val.value,
                    label: val.label,
                    checked: val.value === _vm.value,
                    disabled: val.disabled || _vm.disabled,
                    config: _vm.config,
                    itemStyle: val.style
                  },
                  on: {
                    change: function($event) {
                      return _vm.onChangeSelected($event)
                    }
                  }
                }),
                _vm.$slots["after-" + val.value]
                  ? _c(
                      "div",
                      { staticClass: "my-2 ml-4" },
                      [_vm._t("after-" + val.value)],
                      2
                    )
                  : _vm._e()
              ],
              1
            )
          }),
          _vm.isValidationConfigured()
            ? [
                !_vm.suppressValidationMessage && _vm.validation
                  ? _c(
                      "div",
                      { key: _vm.validation.updated },
                      _vm._l(
                        _vm.validation.getErrors(
                          this.validationPath,
                          this.validateUntouched
                        ),
                        function(error) {
                          return _c(
                            "div",
                            {
                              key: error,
                              staticClass: "input-forms__error-message"
                            },
                            [_vm._v(" " + _vm._s(error) + " ")]
                          )
                        }
                      ),
                      0
                    )
                  : _vm._e()
              ]
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }