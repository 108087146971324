<template>
  <div>
    <MailBriefVorlage/>

    <div class="box__container">
      <div class="box__title">Hintergrund Briefvorlage hochgeladen</div>

      <DragnDropArea hoverText="Datei hier ablegen" @files="addDocument($event)">
        <div class="d-block justify-content-between">
          <div
            class="d-flex d-md-block mt-1 layout__negative-margin--8 antrag-action-button__container justify-content-center">

            <div>
              <DownloadLink 
                v-if="backgroundTemplateForm && backgroundTemplateForm.backgroundTemplateFileId"
                :key="backgroundTemplateForm.backgroundTemplateFileId"
                :title="backgroundTemplateForm.backgroundTemplateFilename"
                :filename="backgroundTemplateForm.backgroundTemplateFilename"
                downloadServicePath="/get_simple_file"
                :queryParams="{
                  fileId: backgroundTemplateForm.backgroundTemplateFileId
                }"/>
              <span v-else-if="backgroundTemplateForm">
                {{ backgroundTemplateForm.backgroundTemplateFilename }}
              </span>
            </div>

            <div>
              <div class="layout__negative-margin--8">
                <BaseButton class="upload-btn">
                  <slot>Briefvorlage PDF hochladen</slot>
                  <input type="file" :accept="acceptFileTypes" @change="onAddFiles" />
                </BaseButton>

                <BaseButton
                  label="Löschen"
                  isSecondary
                  :disabled="isDeleteButtonDisabled"
                  @click="deletePDFBackgroundTemplate()"/>
              </div>
            </div>
          </div>
        </div>
      </DragnDropArea>

      
    </div>

    <div class="box__container">
      <div class="box__title">Seitenabstände (in mm):</div>

      <InputToggleSwitch 
        label="Standard verwenden"
        v-model="backgroundTemplateForm.useDefaultMeasurements"
        @input="changeUseDefaultMeasurements($event)"/>

      <InputToggleSwitch
        label="Hintergrundvorlage auf Folgeseiten verwenden"
        v-model="backgroundTemplateForm.useBGTemplateFilesOnSubsequentPages"
        @input="dataChanged()"/>

      <InputField
        :disabled="backgroundTemplateForm.useDefaultMeasurements"
        :precision="1"
        isComponentHalfSize
        label="Links"
        type="number"
        v-model="backgroundTemplateForm.sideDistances.left"
        @change="dataChanged()" />

      <InputField
        :disabled="backgroundTemplateForm.useDefaultMeasurements"
        :precision="1"
        isComponentHalfSize
        label="Rechts"
        type="number"
        v-model="backgroundTemplateForm.sideDistances.right"
        @change="dataChanged()" />

      <InputField
        :disabled="backgroundTemplateForm.useDefaultMeasurements"
        :precision="1"
        isComponentHalfSize
        label="Oben"
        type="number"
        v-model="backgroundTemplateForm.sideDistances.top"
        @change="dataChanged()" />

      <InputField
        :disabled="backgroundTemplateForm.useDefaultMeasurements"
        :precision="1"
        isComponentHalfSize
        label="Unten"
        type="number"
        v-model="backgroundTemplateForm.sideDistances.bottom"
        @change="dataChanged()" />
    </div>
  </div>
</template>

<script>
import MailBriefVorlage from './MailBriefVorlage.vue';
import DragnDropArea from '@/components/fileUpload/DragnDropArea.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import { uploadFileChunked } from '@/helpers/upload-helper';
import InputField from '@/components/core/forms/InputField.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import TEMPLATES_EDIT_TYPES from '@/store/templatesEdit/types';
import { mapGetters } from 'vuex';
import DownloadLink from '@/components/core/download/DownloadLink.vue';

function getBackgroundTemplateForm() {
  return {
    sideDistances: {
      left: null,
      right: null,
      top: null,
      bottom: null,
    },
    defaultSideDistances: {
      left: null,
      right: null,
      top: null,
      bottom: null,
    },
    useDefaultMeasurements: false,
    backgroundTemplateFilename: '',
    backgroundTemplateFileId: null,
  }
}

export default {
  components: {
    MailBriefVorlage,
    DragnDropArea,
    BaseButton,
    InputField,
    InputToggleSwitch,
    DownloadLink,
  },
  data() {
    return {
      acceptFileTypes: '*/*',
      backgroundTemplateForm: getBackgroundTemplateForm(),
      changed: false,
    }
  },
  computed: {
    ...mapGetters({
      backgroundTemplate: TEMPLATES_EDIT_TYPES.GETTERS.GET_BACKGROUND_TEMPLATE,
    }),
    isDeleteButtonDisabled() {
      return !this.backgroundTemplateForm?.backgroundTemplateFileId 
        && !this.backgroundTemplateForm?.tempBackgroundTemplateFileId
    }
  },
  watch: {
    backgroundTemplate(value) {
      this.backgroundTemplateForm = Object.assign({}, this.backgroundTemplateForm, value);
    }
  },
  methods: {
    addDocument(files) {
      files && files.map(file => {
        uploadFileChunked(file).then(id => {
          this.backgroundTemplateForm.backgroundTemplateFileId = null;
          this.backgroundTemplateForm.tempBackgroundTemplateFileId = id;
          this.backgroundTemplateForm.backgroundTemplateFilename = file.name;
          this.dataChanged();
        })
      })
    },
    onAddFiles(event) {
      const files = Object.values(event.target.files);
      uploadFileChunked(files[0]).then(id => {
        this.backgroundTemplateForm.backgroundTemplateFileId = null;
        this.backgroundTemplateForm.tempBackgroundTemplateFileId = id;
        this.backgroundTemplateForm.backgroundTemplateFilename = files[0].name;
        this.dataChanged();
      })
      event.target.value = '';
    },
    changeUseDefaultMeasurements(useDefaultMeasurements) {
      const newValuesField = useDefaultMeasurements ? 'defaultSideDistances' : 'sideDistances';
      this.$set(this.backgroundTemplateForm, 'sideDistances', Object.assign({}, this.backgroundTemplate[newValuesField]));
      this.dataChanged();
    },
    deletePDFBackgroundTemplate() {
      this.$confirmModal({
        title: 'PDF für Hintergrund Briefvorlage löschen',
        message: `Soll der PDF '${this.backgroundTemplateForm?.backgroundTemplateFilename}' wirklich gelöscht werden?`,
        labelButtonCancel: 'Abbrechen',
        labelButtonConfirm: 'Löschen',
      }).then(() => {
        this.$store.dispatch(TEMPLATES_EDIT_TYPES.ACTIONS.DELETE_BACKGROUND_TEMPLATE);
      })
    },
    dataChanged() {
      this.changed = true;
    },
    async saveForm() {
      if (this.changed) {
        await this.$store.dispatch(TEMPLATES_EDIT_TYPES.ACTIONS.SAVE_BACKGROUND_TEMPLATE, this.backgroundTemplateForm);
      }
    }
  },
  async beforeRouteLeave(to, from, next) {
    await this.saveForm();
    this.$store.commit(TEMPLATES_EDIT_TYPES.MUTATIONS.GET_BACKGROUND_TEMPLATE, null);

    next()
  },
  mounted() {
    this.$store.dispatch(TEMPLATES_EDIT_TYPES.ACTIONS.GET_BACKGROUND_TEMPLATE);
  },

}

</script>

<style scoped>
.top-buttons {
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
}
.upload-btn {
  position: relative;
}

.upload-btn input {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
</style>