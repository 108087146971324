<template>
  <div>
    <BaseModal
      ref="modal"
      size="sm"
      modalTitle="PIN per SMS versenden"
      :showDefaultButtons="false"
      @close="handleModalClose()"
    >
      <template #default>
        Wollen Sie jetzt die PIN dem Kunden per SMS zusenden?
      </template>
      <template #footer>
        <BaseButton isSecondary @click="close" class="mr-3">Nein</BaseButton>
        <BaseButton :disabled="loading" @click="sendPin()">
          <AnimatedSpinner v-if="loading" /> Ja
        </BaseButton>
      </template>
    </BaseModal>

    <BaseModal
      ref="modalResponse"
      size="sm"
      modalTitle="PIN per SMS versenden"
      :showCancelButton="false"
      labelButtonConfirm="Ok"
      @close="backToCommunicationHome()"
    >
      <template #default>
        {{ response }}
      </template>
    </BaseModal>
  </div>
</template>

<script>
import COMMUNICATION_TYPES from '@/store/communication/types';

import BaseModal from '@/components/core/BaseModal.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';


export default {
  data() {
    return {
      response: null,
      loading: false,
    };
  },
  methods: {
    init() {
      this.response = null;
      this.loading = false;
    },
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    async sendPin() {
      this.loading = true;
      this.response = await this.$store.dispatch(COMMUNICATION_TYPES.ACTIONS.SEND_PIN_VIA_SMS);
      this.loading = false;
      this.close();
    },
    handleModalClose() {
      if(this.response) {
        this.$refs.modalResponse.open();
      } else {
        this.backToCommunicationHome();
      }
    },
    backToCommunicationHome() {
      this.$router.push('/communication/kommunikationsubersicht');
    },
  },
  mounted() {
    this.init();
    this.open();
  },
  components: {
    BaseModal,
    BaseButton,
    AnimatedSpinner,
  },
}
</script>
