import CORE_TYPES from '../../store/core/types';
import {sanitize} from '@/helpers/string-helper.js';

var InteractiveHelpCommonFunctions = {
    methods: {
        _iterate(elementsObjArray, funcIterate) {
            if (elementsObjArray) {
                for (let i = 0, len = elementsObjArray.length; i < len; i++) {
                    funcIterate(elementsObjArray[i], i);
                }
            }
        },
        _getElementOffset(element) {
            let body = document.body;
            let docEl = document.documentElement;
            let scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop ;
            let scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;
            let x = element.getBoundingClientRect();
            return {
                top: x.top + scrollTop,
                width: x.width,
                height: x.height,
                left: x.left + scrollLeft
            };
        },
        _addClass(element, className) {
            let classes = className.split(' ');
            this._iterate(classes, function (cls) {
                element.classList.add(cls);
            });
        },
        _checkRight(targetOffset, tooltipLayerStyleLeft, tooltipOffset, windowSize, tooltipLayer) {
            if (targetOffset.left + tooltipLayerStyleLeft + tooltipOffset.width > windowSize.width) {
                tooltipLayer.style.left = (windowSize.width - tooltipOffset.width - targetOffset.left) + 'px';
                return false;
            }
            tooltipLayer.style.left = tooltipLayerStyleLeft + 'px';
            return true;
        },
        _calculateTooltipPosition(targetElement, tooltipLayer, desiredTooltipPosition) {

            const positionPrecedence = ["bottom", "top", "right", "left"]

            let possiblePositions = positionPrecedence.slice();

            let windowSize = this._getWinSize();
            let tooltipHeight = this._getElementOffset(tooltipLayer).height + 10;
            let tooltipWidth = this._getElementOffset(tooltipLayer).width + 20;
            let targetElementRect = targetElement.getBoundingClientRect();

            let calculatedPosition = "floating";

            if (targetElementRect.bottom + tooltipHeight > windowSize.height) {
                this._removeEntry(possiblePositions, "bottom");
            }

            if (targetElementRect.top - tooltipHeight < 0) {
                this._removeEntry(possiblePositions, "top");
            }

            if (targetElementRect.right + tooltipWidth > windowSize.width) {
                this._removeEntry(possiblePositions, "right");
            }

            if (targetElementRect.left - tooltipWidth < 0) {
                this._removeEntry(possiblePositions, "left");
            }

            let desiredAlignment = (function (pos) {
                var hyphenIndex = pos.indexOf('-');
                if (hyphenIndex !== -1) {
                    return pos.substr(hyphenIndex);
                }
                return '';
            })(desiredTooltipPosition || '');

            if (desiredTooltipPosition) {
                desiredTooltipPosition = desiredTooltipPosition.split('-')[0];
            }

            if (possiblePositions.length) {
                if (desiredTooltipPosition !== "auto" &&
                    possiblePositions.indexOf(desiredTooltipPosition) > -1) {
                    calculatedPosition = desiredTooltipPosition;
                } else {
                    calculatedPosition = possiblePositions[0];
                }
            }

            if (['top', 'bottom'].indexOf(calculatedPosition) !== -1) {
                calculatedPosition += this._determineAutoAlignment(targetElementRect.left, tooltipWidth, windowSize, desiredAlignment);
            }

            return calculatedPosition;
        },
        _getWinSize() {
            if (window.innerWidth !== undefined) {
                return { width: window.innerWidth, height: window.innerHeight };
            } else {
                let D = document.documentElement;
                return { width: D.clientWidth, height: D.clientHeight };
            }
        },
        _removeEntry(stringArray, stringToRemove) {
            if (stringArray.indexOf(stringToRemove) > -1) {
                stringArray.splice(stringArray.indexOf(stringToRemove), 1);
            }
        },
        _determineAutoAlignment(offsetLeft, tooltipWidth, windowSize, desiredAlignment) {
            let halfTooltipWidth = tooltipWidth / 2,
                winWidth = Math.min(windowSize.width, window.screen.width),
                possibleAlignments = ['-left-aligned', '-middle-aligned', '-right-aligned'],
                calculatedAlignment = '';

            if (winWidth - offsetLeft < tooltipWidth) {
                this._removeEntry(possibleAlignments, '-left-aligned');
            }

            if (offsetLeft < halfTooltipWidth ||
                winWidth - offsetLeft < halfTooltipWidth) {
                this._removeEntry(possibleAlignments, '-middle-aligned');
            }

            if (offsetLeft < tooltipWidth) {
                this._removeEntry(possibleAlignments, '-right-aligned');
            }

            if (possibleAlignments.length) {
                if (possibleAlignments.indexOf(desiredAlignment) !== -1) {
                    calculatedAlignment = desiredAlignment;
                } else {
                    calculatedAlignment = possibleAlignments[0];
                }
            } else {
                calculatedAlignment = '-middle-aligned';
            }

            return calculatedAlignment;
        },
        _checkLeft(targetOffset, tooltipLayerStyleRight, tooltipOffset, tooltipLayer) {
            if (targetOffset.left + targetOffset.width - tooltipLayerStyleRight - tooltipOffset.width < 0) {
                tooltipLayer.style.left = (-targetOffset.left) + 'px';
                return false;
            }
            tooltipLayer.style.right = tooltipLayerStyleRight + 'px';
            return true;
        },
        _positionTooltip(targetElement, tooltipLayer, arrowLayer, cssClassPrefix) {
            let tooltipCssClass = '',
                currentStepObj,
                tooltipOffset,
                targetOffset,
                windowSize,
                currentTooltipPosition;

            tooltipLayer.style.top = null;
            tooltipLayer.style.right = null;
            tooltipLayer.style.bottom = null;
            tooltipLayer.style.left = null;
            tooltipLayer.style.marginLeft = null;
            tooltipLayer.style.marginTop = null;

            arrowLayer.style.display = 'inherit';

            if (!this.slideItems[this._currentStep]) return;

            currentStepObj = this.slideItems[this._currentStep];
            if (typeof (currentStepObj.tooltipClass) === 'string') {
                tooltipCssClass = currentStepObj.tooltipClass;
            } else {
                tooltipCssClass = '';
            }

            tooltipLayer.className = (cssClassPrefix + '-tooltip ' + tooltipCssClass).replace(/^\s+|\s+$/g, '');
            tooltipLayer.setAttribute('role', 'dialog');

            currentTooltipPosition = this.slideItems[this._currentStep].position;

            if (currentTooltipPosition !== "floating") {
                currentTooltipPosition = this._calculateTooltipPosition(targetElement, tooltipLayer, currentTooltipPosition);
            }

            let tooltipLayerStyleLeft;
            targetOffset = this._getElementOffset(targetElement);
            tooltipOffset = this._getElementOffset(tooltipLayer);
            windowSize = this._getWinSize();

            this._addClass(tooltipLayer, cssClassPrefix + '-bottom');

            switch (currentTooltipPosition) {
                case 'top-right-aligned':
                    arrowLayer.className = cssClassPrefix + '-arrow bottom-right';

                    var tooltipLayerStyleRight = 0;
                    this._checkLeft(targetOffset, tooltipLayerStyleRight, tooltipOffset, tooltipLayer);
                    tooltipLayer.style.bottom = (targetOffset.height + 20) + 'px';
                    break;

                case 'top-middle-aligned':
                    arrowLayer.className = cssClassPrefix + '-arrow bottom-middle';

                    var tooltipLayerStyleLeftRight = targetOffset.width / 2 - tooltipOffset.width / 2;

                    if (this._checkLeft(targetOffset, tooltipLayerStyleLeftRight, tooltipOffset, tooltipLayer)) {
                        tooltipLayer.style.right = null;
                        this._checkRight(targetOffset, tooltipLayerStyleLeftRight, tooltipOffset, windowSize, tooltipLayer);
                    }
                    tooltipLayer.style.bottom = (targetOffset.height + 20) + 'px';
                    break;

                case 'top-left-aligned':
                case 'top':
                    arrowLayer.className = cssClassPrefix + '-arrow bottom';

                    tooltipLayerStyleLeft = 15;

                    this._checkRight(targetOffset, tooltipLayerStyleLeft, tooltipOffset, windowSize, tooltipLayer);
                    tooltipLayer.style.bottom = (targetOffset.height + 20) + 'px';
                    break;
                case 'right':
                    tooltipLayer.style.left = (targetOffset.width + 20) + 'px';
                    if (targetOffset.top + tooltipOffset.height > windowSize.height) {
                        arrowLayer.className = cssClassPrefix + '-arrow left-bottom';
                        tooltipLayer.style.top = "-" + (tooltipOffset.height - targetOffset.height - 20) + "px";
                    } else {
                        arrowLayer.className = cssClassPrefix + '-arrow left';
                    }
                    break;
                case 'left':
                    if (targetOffset.top + tooltipOffset.height > windowSize.height) {
                        tooltipLayer.style.top = "-" + (tooltipOffset.height - targetOffset.height - 20) + "px";
                        arrowLayer.className = cssClassPrefix + '-arrow right-bottom';
                    } else {
                        arrowLayer.className = cssClassPrefix + '-arrow right';
                    }
                    tooltipLayer.style.right = (targetOffset.width + 20) + 'px';

                    break;
                case 'floating':
                    arrowLayer.style.display = 'none';

                    tooltipLayer.style.left = '50%';
                    tooltipLayer.style.top = '50%';
                    tooltipLayer.style.marginLeft = '-' + (tooltipOffset.width / 2) + 'px';
                    tooltipLayer.style.marginTop = '-' + (tooltipOffset.height / 2) + 'px';

                    break;
                case 'bottom-right-aligned':
                    arrowLayer.className = cssClassPrefix + '-arrow top-right';

                    tooltipLayerStyleRight = 0;
                    this._checkLeft(targetOffset, tooltipLayerStyleRight, tooltipOffset, tooltipLayer);
                    tooltipLayer.style.top = (targetOffset.height + 20) + 'px';
                    break;

                case 'bottom-middle-aligned':
                    arrowLayer.className = cssClassPrefix + '-arrow top-middle';

                    tooltipLayerStyleLeftRight = targetOffset.width / 2 - tooltipOffset.width / 2;

                    if (this._checkLeft(targetOffset, tooltipLayerStyleLeftRight, tooltipOffset, tooltipLayer)) {
                        tooltipLayer.style.right = null;
                        this._checkRight(targetOffset, tooltipLayerStyleLeftRight, tooltipOffset, windowSize, tooltipLayer);
                    }
                    tooltipLayer.style.top = (targetOffset.height + 20) + 'px';
                    break;

                default:
                    arrowLayer.className = cssClassPrefix + '-arrow top';

                    tooltipLayerStyleLeft = 0;
                    this._checkRight(targetOffset, tooltipLayerStyleLeft, tooltipOffset, windowSize, tooltipLayer);
                    tooltipLayer.style.top = (targetOffset.height + 20) + 'px';
            }


        },
        _removeHighlight(cssClassPrefix, regex) {
            let elements = document.querySelectorAll(`.${cssClassPrefix}-showElement`);
            this._iterate(elements, function (elm) {
                if (typeof elm.className === 'string') {
                    elm.className = elm.className.replace(regex, '').replace(/^\s+|\s+$/g, '');
                }
            });
        },
        _setHelperLayerPosition(helperLayer, itemsArray) {
            if (helperLayer) {

                let currentElement = document.querySelector(`[tid="${itemsArray[this._currentStep].stepKey}"]`), // <-- updating the element reference again because the DOM might have changed
                    elementPosition = this._getElementOffset(currentElement),
                    widthHeightPadding = 10;

                helperLayer.style.cssText = 'width: ' + (elementPosition.width + widthHeightPadding) + 'px; ' +
                    'height:' + (elementPosition.height + widthHeightPadding) + 'px; ' +
                    'top:' + (elementPosition.top - widthHeightPadding / 2) + 'px;' +
                    'left: ' + (elementPosition.left - widthHeightPadding / 2) + 'px;';

            }
        },
        _resetTutorial() {
            this.highlightElementsWithTidAttribute(false);
            this.$store.dispatch(CORE_TYPES.ACTIONS.REMOVE_ALL_RECORDED_ITEMS, {});
            this.elementsIdArray.items.length = 0;
            this.elementsArray.length = 0;
            this.slideItems.length = 0;
            this.recordedItems.length = 0;
        },
        _iterateParent(element,property) {

            if (element[property] || !element.parentNode) {
                return element[property];
            } else {
                return this._iterateParent(element.parentNode,property)
            }

        }, 
        /**
         * Creates an overlay layer to dim window and highlight only the current slide or HTML element
         * @param {*} context 
         */
        _dimWindow(context, cssClass, exitFunction) {
            let helpLayer = document.createElement('div'),
                styleText = '';

            helpLayer.className = `${cssClass}-overlay`;

            let elementPosition = this._getElementOffset(context);
            let height = window.innerHeight * 2;
            if (elementPosition) {
                styleText += 'width: ' + elementPosition.width + 'px; height:' + height + 'px; top:' + elementPosition.top + 'px;left: ' + elementPosition.left + 'px;';
                helpLayer.style.cssText = styleText;
            }

            context.appendChild(helpLayer);

            helpLayer.onclick = exitFunction;

            let self = this;

            window.setTimeout(function () {
                styleText += 'opacity: 0.8;';
                helpLayer.style.cssText = styleText;
                self._addTutorialTitle();
            }, 10);

            return true;
        },
        _addTutorialTitle() {
            
            let currentTitleDiv = null;
            let helpOverlay = this._targetElement.querySelector('.interactive-help-overlay');

            if (helpOverlay) {
                currentTitleDiv = helpOverlay.querySelector('.__interactive-help-title');
            }

            if (!currentTitleDiv) {
                currentTitleDiv = document.createElement('div');
                
                currentTitleDiv.className = '__interactive-help-title';
    
                let strHtml = '<strong>aktuelles Tutorial:</strong> '+this.recordingTutorialTitle;
                currentTitleDiv.innerHTML = sanitize(strHtml);
               
                if (helpOverlay) {
                    helpOverlay.appendChild(currentTitleDiv);               
                }

            }
        },
        _removeTutorialTitle() {
            let helpOverlay = this._targetElement.querySelector('.interactive-help-overlay');

            let currentTitleDiv = null;

            if (helpOverlay) {
                currentTitleDiv = helpOverlay.querySelector('.__interactive-help-title');
            }
            
            if (helpOverlay && currentTitleDiv) {
                helpOverlay.removeChild(currentTitleDiv); 
            }            
        }
    }
}

export default InteractiveHelpCommonFunctions;