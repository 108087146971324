<template>
  <div class="antrag-components__container">
    <div class="box__container">
      <FormLabel label="Bitte tragen Sie hier die Daten des Depots ein, von dem die Wertpapiere übertragen werden sollen."
        :config="{'bold': true}" />
      <FormDivider/>

      <div class="row">
        <div class="col-12">
          <FormLabel label="Daten der bisher depotführenden Gesellschaft (abgebende Bank)" :config="{'bold': true}"/>
        </div>
        <InputField class="col-12 col-md-6" v-model="form.bisherManuellName" label="Name"
          @change="$emit('save', { bisherManuellName: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.bisherManuellStrasse" label="Straße HNr."
          @change="$emit('save', { bisherManuellStrasse: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.bisherManuellPLZ" label="PLZ"
          @change="$emit('save', { bisherManuellPLZ: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.bisherManuellOrt" label="Ort"
          @change="$emit('save', { bisherManuellOrt: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.bisherManuellLand" label="Land"
          @change="$emit('save', { bisherManuellLand: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.bisherBLZ" label="BLZ der bisherigen Bank/KAG"
          @change="$emit('save', { bisherBLZ: $event })"/>
        
        <InputIbanBic class="col-12 col-md-6" label="BIC der bisherigen Bank/KAG" type="BIC" 
          :componentData="form.bisherBIC"
          @input="$emit('save', { bisherBIC: $event })"/>
        
        <InputIbanBic class="col-12 col-md-6" label="IBAN bei bisheriger Bank" type="IBAN" 
          :componentData="form.bisherKontonr"
          @input="$emit('save', { bisherKontonr: $event })"/>
        
        <InputField class="col-12 col-md-6" v-model="form.bisherDepotnr" label="Depotnummer bei bisheriger Bank"
          @change="$emit('save', { bisherDepotnr: $event })"/>

        <FormDivider/>

        <div class="col-12">
          <FormLabel label="Daten des Depots bei der bisher depotführenden Gesellschaft" :config="{'bold': true}"/>
        </div>
        <div class="col-12">
          <FormLabel label="1. Inhaber" :config="{'bold': true}"/>
        </div>
        <InputRadioBoxGroup  class="col-12 col-md-6"
          :value="form.anrede" 
          :values="[
            {value: 'Herr', label: 'Herr'},
            {value: 'Frau', label: 'Frau'}
          ]"
          @input="$emit('save', { anrede: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.bisherName" label="Name"
          @change="$emit('save', { bisherName: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.bisherVorname" label="Vorname"
          @change="$emit('save', { bisherVorname: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.bisherStrasse" label="Straße HNr."
          @change="$emit('save', { bisherStrasse: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.bisherPLZ" label="PLZ"
          @change="$emit('save', { bisherPLZ: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.bisherOrt" label="Ort"
          @change="$emit('save', { bisherOrt: $event })"/>
           <InputField class="col-12 col-md-6" v-model="form.bisherLand" label="Land"
          @change="$emit('save', { bisherLand: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.tin1" label="TIN"
          @change="$emit('save', { tin1: $event })"/>
        
        <FormDivider/>
        <div class="col-12">
          <FormLabel label="2. Inhaber" :config="{'bold': true}"/>
        </div>
        <InputRadioBoxGroup class="col-12 col-md-6"
          :value="form.anrede2" 
          :values="[
            {value: 'Herr', label: 'Herr'},
            {value: 'Frau', label: 'Frau'}
          ]"
          @input="$emit('save', { anrede2: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.bisherName2" label="Name"
          @change="$emit('save', { bisherName2: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.bisherVorname2" label="Vorname"
          @change="$emit('save', { bisherVorname2: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.bisherStrasse2" label="Straße HNr."
          @change="$emit('save', { bisherStrasse2: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.bisherPLZ2" label="PLZ"
          @change="$emit('save', { bisherPLZ2: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.bisherOrt2" label="Ort"
          @change="$emit('save', { bisherOrt2: $event })"/>
           <InputField class="col-12 col-md-6" v-model="form.bisherLand2" label="Land"
          @change="$emit('save', { bisherLand2: $event })"/>
        <InputField class="col-12 col-md-6" v-model="form.tin2" label="TIN"
          @change="$emit('save', { tin2: $event })"/>
      </div>

      <BaseButton @click="$emit('next')">Weiter</BaseButton>
    </div>
  </div>
</template>
<script>
import FormLabel from '@/components/core/forms/FormLabel.vue';
import InputField from '@/components/core/forms/InputField.vue';
import InputIbanBic from '@/components/core/forms/InputIBAN_BIC.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import FormDivider from '@/components/core/forms/FormDivider.vue';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import validator from '@/mixins/validator';

export default {
  mixins: [validator],
  validators: {
  },
  props: {
    positionData: {
    },
    warnings: {
    },
  },
  components: {
    FormLabel,
    InputField,
    BaseButton,
    FormDivider,
    InputRadioBoxGroup,
    InputIbanBic
  },
  data() {
    return {
      form: {}
    }
  },
  mounted() {
    this.form = Object.assign({}, this.positionData || {});
  },
  methods: {
  }
}
</script>
<style scoped>

</style>