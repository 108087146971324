import MY_GOALS_TYPES from './types';
import { isRolesIncludes } from '@/router/guards';
import { VIEW_ROLES } from '@/router/roles';


export default {
  [MY_GOALS_TYPES.GETTERS.GOALS](state) {
    return state.goals;
  },
  [MY_GOALS_TYPES.GETTERS.SELECTED_GOAL_ID](state) {
    return state.goalSelectedId;
  },
  [MY_GOALS_TYPES.GETTERS.BASE_URL]() {
    if(isRolesIncludes([VIEW_ROLES.VIEW_CUSTOMER_ONLY])) {
      return '/beratung';
    } else if(isRolesIncludes([VIEW_ROLES.VIEW_CUSTOMER])) {
      return '/service';
    }
    return '';
  },
}