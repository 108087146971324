import TRANSAKTION_VERLAUF_TYPES from './types';
import Vue from 'vue';


export default {

  [TRANSAKTION_VERLAUF_TYPES.MUTATIONS.FIND_TRANSACTIONS_SUCCESS](state, payload) {
    Vue.set(state, 'transactions', [...payload]);
  },

}
