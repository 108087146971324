const supportsPassive = () => {
  let supportsPassive = false;

  try {
    const opts = Object.defineProperty({}, 'passive', {
      get() {
        supportsPassive = true;
        return true;
      }
    });
    window.addEventListener('test-passive', null, opts);
    window.removeEventListener('test-passive', null, opts);
  } catch (e) {
    // empty block
  }

  return supportsPassive;
};

const supportsTouch = () => {
  let supportsTouch = false;

  try {
    supportsTouch = (window.matchMedia('(pointer: coarse)').matches) || 
      ('ontouchstart' in window) || 
      (navigator.maxTouchPoints > 0) ||  
      (navigator.msMaxTouchPoints > 0);
  } catch (e) {
    // empty block
  }

  return supportsTouch;
}

const BrowserSupport = {};
Object.defineProperties(BrowserSupport, {
  supportsPassive: {
    get() {
      return supportsPassive();
    },
  },
  supportsTouch: {
    get() {
      return supportsTouch();
    },
  },
});
Object.freeze(BrowserSupport);

export default BrowserSupport;
