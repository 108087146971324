var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: { "box__container-wrap": _vm.isOverview } }, [
    _c(
      "div",
      { class: { "box__container-rows": _vm.isOverview } },
      [
        _vm.rows.length
          ? _c("Table", {
              attrs: {
                title: _vm.tableTitle,
                tableId: "00b54be2-f6be-4d57-a0b7-c4a8b0dfe28b",
                headers: _vm.headers,
                rows: _vm.rows,
                rowsPerPage: _vm.rowsPerPage,
                hidePagination: _vm.isOverview,
                mobileConfig: _vm.mobileConfig
              },
              on: { "click-event_title": _vm.openLink },
              scopedSlots: _vm._u(
                [
                  {
                    key: "img",
                    fn: function(row) {
                      return [
                        row.imgurl
                          ? _c("BaseImage", {
                              staticClass: "logo",
                              attrs: {
                                src: _vm.proxyContent(row.imgurl),
                                placeholder: _vm.imagePlaceholder
                              }
                            })
                          : _vm._e()
                      ]
                    }
                  }
                ],
                null,
                false,
                2027671728
              )
            })
          : _vm.loading
          ? _c("GhostLoading", {
              attrs: {
                type: "table",
                title: _vm.tableTitle,
                config: { table: { rows: 5 } }
              }
            })
          : _c("NoData", {
              attrs: { title: _vm.tableTitle, noIcon: _vm.isOverview }
            })
      ],
      1
    ),
    !_vm.loading && _vm.isOverview && !_vm.$isSmallScreen
      ? _c(
          "div",
          { staticClass: "bottom-row text-right mt-3" },
          [
            _c("router-link", { attrs: { to: { name: "fk-events" } } }, [
              _vm._v(" weitere... ")
            ])
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }