export const MUTATION_PREFIX = 'STEPPER_MUTATIONS_';
export const ACTIONS_PREFIX = 'STEPPER_ACTIONS_';
export const GETTERS_PREFIX = 'STEPPER_GETTERS_';

export default {
  MUTATIONS: {
    SET_SHOULD_REOPEN_APP_MODAL: MUTATION_PREFIX + 'SET_SHOULD_REOPEN_APP_MODAL',
  },
  ACTIONS: {
  },
  GETTERS: {
    SHOULD_REOPEN_APP_MODAL: GETTERS_PREFIX + 'SHOULD_REOPEN_APP_MODAL',
  }
}