import { mapGetters } from "vuex";
import CUSTOMERDATA_TYPES from "@/store/customerData/types.js";
import LOG_TYPES from '@/store/log/types';

const expressionEvaluator = {
    computed: {
        ...mapGetters({
            _expression_customerData: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA,
            _expression_additionalPersons: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_ADDITIONAL_PERSONS,
        }),
        _expression_persons() {
            const additionalPersons = (this._expression_additionalPersons || []);
            return [this._expression_customerData, ...additionalPersons];
        },
    },
    methods: {
        evaluateExpression(inputData, expression) {
            /**
             * for easier navigation, case statements are sorted alphabetically. please keep it that way
             * 
             * every case statement implements the reference implementation of the method evaluate(Map<String, Object> inputData) for the respective FormExpression class
             */
            switch (expression.type) {
                case 'AND':
                    return this.evaluateExpression(inputData, expression.left) && this.evaluateExpression(inputData, expression.right);
                case 'CONSTANT_BOOL':
                case 'CONSTANT_DOUBLE':
                case 'CONSTANT_INT':
                case 'CONSTANT_STRING':
                    return expression.value;
                case 'EQUALS':
                    return (this.evaluateExpression(inputData, expression.left) == this.evaluateExpression(inputData, expression.right));
                case 'DOUBLE_TO_STRING':
                    var value = this.evaluateExpression(inputData, expression.parameter);
                    var digits = this.evaluateExpression(inputData, expression.digits);
                    return value.toFixed(digits).replace(".", ",");
                case 'INPUT_BOOLEAN':
                    return inputData[expression.inputName];                       
                case 'INPUT_STRING':
                    return inputData[expression.inputName];
                case 'IS_IN':
                    var value = this.evaluateExpression(inputData, expression.value);
                    return expression.list.some(expression => this.evaluateExpression(inputData, expression) == value);
                case 'MATCHES_REGEX':
                    var value = this.evaluateExpression(inputData, expression.value);
                    return new RegExp(expression.regex).test(value);
                case 'MULTIPLY':
                    return this.evaluateExpression(inputData, expression.left) * this.evaluateExpression(inputData, expression.right);
                case 'PARSE_DOUBLE':
                    var value = this.evaluateExpression(inputData, expression.string);
                    if (!value && value !== 0)
                        value = "0";
                    value = Number(value.replace(",", "."));
                    if (isNaN(value))
                        return this.evaluateExpression(inputData, expression.defaultResult);
                    else
                        return value;
                case 'PERSON_ANREDE':
                    var person = this.evaluateExpression(inputData, expression.personExpression);
                    return person && person.personalDataAddress && person.personalDataAddress.title || "";
                case 'PERSON_AUSWEIS_AUSSTELLUNGSBEHOERDE':
                    var person = this.evaluateExpression(inputData, expression.personExpression);
                    return person && person.legitimation && person.legitimation.authority || "";
                case 'PERSON_AUSWEIS_AUSSTELLUNGSDATUM':
                    var person = this.evaluateExpression(inputData, expression.personExpression);
                    return person && person.legitimation && person.legitimation.dateOfIssue || "";
                case 'PERSON_AUSWEIS_NUMMER':
                    var person = this.evaluateExpression(inputData, expression.personExpression);
                    return person && person.legitimation && person.legitimation.legitimNumber || "";
                case 'PERSON_AUSWEIS_TYP':
                    var person = this.evaluateExpression(inputData, expression.personExpression);
                    return person && person.legitimation && person.legitimation.legitimType || "";
                case 'PERSON_EMAIL':
                    var person = this.evaluateExpression(inputData, expression.personExpression);
                    if (person && person.contacts) {
                        var contact = person.contacts.find(contact => contact.type == 'E-Mail');
                        return contact && contact.value || ''
                    }
                    else
                        return '';
                case 'PERSON_FROM_ID':
                    var id = this.evaluateExpression(inputData, expression.idString);
                    return this._expression_persons.find(person => person.personId == id);
                case 'PERSON_FAMILIENKASSE':
                    var person = this.evaluateExpression(inputData, expression.personExpression);
                    return person && person.personalDataTax && person.personalDataTax.familyBenefitsOffice || "";
                case 'PERSON_GEBURTSLAND':
                    var person = this.evaluateExpression(inputData, expression.personExpression);
                    return person && person.personalDataBirth && person.personalDataBirth.countryOfBirth || "";
                case 'PERSON_GEBURTSNAME':
                    var person = this.evaluateExpression(inputData, expression.personExpression);
                    return person && person.personalDataBirth && person.personalDataBirth.nameAtBirth || "";
                case 'PERSON_GEBURTSORT':
                    var person = this.evaluateExpression(inputData, expression.personExpression);
                    return person && person.personalDataBirth && person.personalDataBirth.placeOfBirth || "";
                case 'PERSON_HAUSNR':
                    var person = this.evaluateExpression(inputData, expression.personExpression);
                    return person && person.personalDataAddress && person.personalDataAddress.streetnumber || "";
                case 'PERSON_KINDERGELD_NR':
                    var person = this.evaluateExpression(inputData, expression.personExpression);
                    return person && person.personalDataTax && person.personalDataTax.childrenBenefitsNr || "";
                case 'PERSON_LAND':
                    var person = this.evaluateExpression(inputData, expression.personExpression);
                    return person && person.personalDataAddress && person.personalDataAddress.country || "";
                case 'PERSON_MOBIL':
                    var person = this.evaluateExpression(inputData, expression.personExpression);
                    if (person && person.contacts) {
                        var contact = person.contacts.find(contact => contact.type == 'Mobil');
                        return contact && contact.value || ''
                    }
                    else
                        return '';
                case 'PERSON_NAME':
                    var person = this.evaluateExpression(inputData, expression.personExpression);
                    return person && person.personalDataAddress && person.personalDataAddress.firstName || "";
                case 'PERSON_ORT':
                    var person = this.evaluateExpression(inputData, expression.personExpression);
                    return person && person.personalDataAddress && person.personalDataAddress.city || "";
                case 'PERSON_PLZ':
                    var person = this.evaluateExpression(inputData, expression.personExpression);
                    return person && person.personalDataAddress && person.personalDataAddress.zip || "";
                case 'PERSON_STAATSANGEHOERIGKEIT':
                    var person = this.evaluateExpression(inputData, expression.personExpression);
                    return person && person.personalDataBirth && person.personalDataBirth.nationality || "";
                case 'PERSON_STRASSEOHNEHNR':
                    var person = this.evaluateExpression(inputData, expression.personExpression);
                    return person && person.personalDataAddress && person.personalDataAddress.streetwithoutnumber || "";
                case 'PERSON_TAX_ID':
                    var person = this.evaluateExpression(inputData, expression.personExpression);
                    return person && person.personalDataTax && person.personalDataTax.taxID || "";
                case 'TO_STRING':
                    return "" + this.evaluateExpression(inputData, expression.parameter);
                case 'PERSON_TITEL':
                    var person = this.evaluateExpression(inputData, expression.personExpression);
                    return person && person.personalDataAddress && person.personalDataAddress.titles || "";
                case 'PERSON_VORNAME':
                    var person = this.evaluateExpression(inputData, expression.personExpression);
                    return person && person.personalDataAddress && person.personalDataAddress.lastName || "";
                default:
                    this.$store.dispatch(LOG_TYPES.ACTIONS.LOG, "unknown expression type: " + expression.type);
                    return null;
            }
        },
    },
}

export default expressionEvaluator;

