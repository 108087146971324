<template>
  <div class="antrag-components__container">
    <div class="box__container">

      <InputRadioBoxGroup 
        :value="form.allePos" 
        :validateUntouched="true"
        :values="[
          {value: 'Gesamtdepot', label: 'Bitte übertragen Sie das gesamte Depot'},
          {value: 'einzelneWP', label: 'Bitte übertragen Sie einzelne Wertpapiere:'}
        ]"
        @input="$emit('save', { allePos: $event })"/>
      <FormDivider/>

      <InputRadioBoxGroup :value="form.uebertragungsArt" title="Art des Übertrags" 
        :values="artValues"
        @input="$emit('save', { uebertragungsArt: $event })"/>
      <FormDivider/>

      <InputCheckBoxGroup 
        v-model="form"
        title="Übertrag der Verlustverrechnungstöpfe"
        :values="[ 
          { label: 'Aktien', path: 'aktienVerlust' },
          { label: 'Sonstige', path: 'sonstigeVerlust' }, 
          { label: 'ausländische Quellensteuer', path: 'auslaendischeVerlust' },
        ]"
        @checkBoxToggle="$emit('save', $event)"/>
      <FormDivider/>

      <div v-if="antragName === 'uebertragungsauftrag'">
         <div class="col-12">
          <FormLabel label="Bitte überweisen Sie den Betrag von" :config="{'bold': true}"/>
        </div>
        <InputField :isComponentHalfSize="true"
          v-model="form.betrag" type="currency" label="Betrag"
          @change="$emit('save', { betrag: $event })"/>
           <ComboBox :isComponentHalfSize="true" label="Währung"
              :values="customerDataConfig.waehrungen"
              v-model="form.waehrung1"  
               @change="$emit('save', { waehrung1: $event })"
            />
           <InputField  :isComponentHalfSize="true" 
          v-model="form.betrag2" type="currency" label="Betrag"
          @change="$emit('save', { betrag2: $event })"/>
           <ComboBox :isComponentHalfSize="true" label="Währung"
              :values="customerDataConfig.waehrungen"
              v-model="form.waehrung2"  
               @change="$emit('save', { waehrung1: $event })"
            />
           <InputField  :isComponentHalfSize="true"
          v-model="form.betrag3" type="currency" label="Betrag"
          @change="$emit('save', { betrag3: $event })"/>
           <ComboBox :isComponentHalfSize="true" label="Währung"
              :values="customerDataConfig.waehrungen"
              v-model="form.waehrung3"  
               @change="$emit('save', { waehrung1: $event })"
            />
           <InputField  :isComponentHalfSize="true"
          v-model="form.betrag4" type="currency" label="Betrag"
          @change="$emit('save', { betrag4: $event })"/>
           <ComboBox :isComponentHalfSize="true" label="Währung"
              :values="customerDataConfig.waehrungen"
              v-model="form.waehrung4"  
               @change="$emit('save', { waehrung1: $event })"
            />
           <InputField  :isComponentHalfSize="true"
          v-model="form.betrag5" type="currency" label="Betrag"
          @change="$emit('save', { betrag5: $event })"/>
           <ComboBox :isComponentHalfSize="true" label="Währung"
              :values="customerDataConfig.waehrungen"
              v-model="form.waehrung5"  
               @change="$emit('save', { waehrung1: $event })"
            />

        <InputRadioBoxGroup 
          title="Löschung von Konto und Depot"
          :value="form.loeschung" 
          :validateUntouched="true"
          :values="[
            {value: 'Gesamtauflösung', label: 'Gesamtlöschung'},
            {value: 'Konto', label: 'nur Konto'},
            {value: 'Depot', label: 'nur Depot'}
          ]"
          @input="$emit('save', { loeschung: $event })"/>

        <InputToggleSwitch 
          v-model="form.aufgrundDerAbloesung" label="Der Übertrag erfolgt aufgrund der Ablösung eines bestehenden Kredites"
          @input="$emit('save', { aufgrundDerAbloesung: $event })"/>
      </div>

      <BaseButton class="col-12 mr-2" :isSecondary="true" @click="$emit('back')">Zurück</BaseButton>
      <BaseButton class="col-12" @click="$emit('next')">Weiter</BaseButton>
    </div>
  </div>
</template>
<script>

import antragNavigationMixin from '@/mixins/antrag/antrag-navigation-mixin.js';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import FormDivider from '@/components/core/forms/FormDivider.vue';
import InputCheckBoxGroup from '@/components/core/forms/checkbox/InputCheckBoxGroup.vue';
import InputField from '@/components/core/forms/InputField.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import FormLabel from '@/components/core/forms/FormLabel.vue';
import CUSTOMERDATA_TYPES from "@/store/customerData/types.js";
import { mapGetters } from 'vuex';

export default {
  mixins: [antragNavigationMixin],
  components: {
    InputRadioBoxGroup,
    BaseButton,
    FormDivider,
    InputCheckBoxGroup,
    InputField,
    ComboBox,
    InputToggleSwitch,
    FormLabel
  },
  props: {
    positionData: {
    },
    config:{},
    anzahlFonds: {
      type: Number,
      default: 4,
    },
    warnings: {
    },
  },
  data() {
    return {
      form: {
        waehrung1: 'EUR',
        waehrung2: 'EUR',
        waehrung3: 'EUR',
        waehrung4: 'EUR',
        waehrung5: 'EUR',
      },
      antragName: '',
      artValues: [
        {value: 'Inhabergleich', label: 'Inhabergleich<br>Überträge auf eigene Depots gelten steuerrechtlich nicht als Inhaberwechsel und sind daher'
					+ ' steuerlich unbeachtlich. Eine Meldung an die Finanzbehörden erfolgt daher nicht. Anschaffungsdaten werden innerhalb von Deutschland'
					+ ' übertragen.'},
        {value: 'unentgeltlich', label: 'Inhaberwechsel - unentgeltlich<br>Überträge auf Depots Dritter gelten als Überträge mit Inhaberwechsel.'
					+ ' Werden ab dem 01.01.2009 angeschaffte Bestände unentgeltlich übertragen, erfolgt grundsätzlich die Meldung "unentgeltlicher Übertrag"'
					+ ' an die Finanzbehörden. Anschaffungsdaten werden innerhalb von Deutschland übertragen.'},
        {value: 'Erbschaft', label: 'Inhaberwechsel - Erbschaft<br>Anschaffungsdaten werden innerhalb von Deutschland übertragen.'},
        {value: 'entgeltlich', label: 'Inhaberwechsel - entgeltlich<br>Für Bestände, die ab dem 01.01.2009 angeschafft wurden, unterstellt'
					+ ' das Einkommenssteuergesetz eine Veräußerung, sofern bei Beauftragung der Übertrag nicht als unentgeltlich deklariert wurde.'},
      ],
    }
  },
    computed:{
 ...mapGetters({
            customerDataConfig: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_CONFIG,
        }),
  },
  mounted() {
    this.form = Object.assign({}, this.positionData);
    this.antragName = this.$route.params.antragsName;
    this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA_CONFIG)
  },
  methods: {
  },
}
</script>
<style scoped>

</style>