import INFOBRIEFE_TYPES from './types';
import CORE_TYPES from '../core/types';
import axios from 'axios';
import { buildMessage, } from '../../helpers/log-message-helper';
import * as utils from '../../helpers/commonfunctions';

export default {
  [INFOBRIEFE_TYPES.ACTIONS.RETRIEVE_INFOBRIEFE]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/infobriefeVerwalten/getListing';
     
  
      axios.post(rootState.core.apiAddress + serviceUrl, payload, config).then(response => {
        if (response && response.data) {
  
          commit(INFOBRIEFE_TYPES.MUTATIONS.RETRIEVE_INFOBRIEFE_SUCCESS, response.data);
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });

  },

  [INFOBRIEFE_TYPES.ACTIONS.FREIGEBEN]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/infobriefeVerwalten/freigeben';

      if(payload && payload.fileName) {
        serviceUrl = `${serviceUrl}?fileName=${payload.fileName}`;
      }
     
      axios.get(rootState.core.apiAddress + serviceUrl, config).then(response => {
        if (response && response.data) {
  
          commit(INFOBRIEFE_TYPES.MUTATIONS.FREIGEBEN_SUCCESS, response.data);
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });

  },


  [INFOBRIEFE_TYPES.ACTIONS.VOR_VERSAND_FREIGEBEN]({ commit, dispatch, rootState }, payload) {
    const config = {
      defaultSpinner: true
    };

    return new Promise((resolve, reject) => {
      let serviceUrl = '/infobriefeVerwalten/vorVersandFreigeben';
     
  
      axios.post(rootState.core.apiAddress + serviceUrl, payload, config).then(response => {
        if (response && response.data) {
  
          // commit(INFOBRIEFE_TYPES.MUTATIONS.RETRIEVE_INFOBRIEFE_SUCCESS, response.data);
          resolve(response?.data);
        }      
  
      }).catch((error) => {
        reject(error);
      });
    });

  },


  

}