import { render, staticRenderFns } from "./SchaedlicheVerwendung.vue?vue&type=template&id=1bf2570e&"
import script from "./SchaedlicheVerwendung.vue?vue&type=script&lang=js&"
export * from "./SchaedlicheVerwendung.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("P:\\workspaces\\fk0\\fc\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1bf2570e')) {
      api.createRecord('1bf2570e', component.options)
    } else {
      api.reload('1bf2570e', component.options)
    }
    module.hot.accept("./SchaedlicheVerwendung.vue?vue&type=template&id=1bf2570e&", function () {
      api.rerender('1bf2570e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/berichte/altersvorsorgeCards/SchaedlicheVerwendung.vue"
export default component.exports