<template>
  <div class="menu-structure-list__container">
    <PageHeaderTitleNavigation 
      title="Menüstrukturen" 
      :actions="headerActions" 
      @action-ADD="addMenuStructure"
      @action-CONFIG="openConfig"
    />

    <div class="box__container">
      <GhostLoading v-if="loading" title="Menüstrukturliste" type="table" />
      <Table
        v-else
        title="Menüstrukturliste"
        :headers="headers"
        :rows="rows"
        @click-label="editMenuStructure"
        @action-DELETE="removeMenuStructure"
      />
    </div>

    <AddMenuStructureModal ref="addMenuStructureModal" @saved="onMenuStructureSaved" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MENU_STRUCTURE_TYPES from '@/store/menuStructure/types';

import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import Table from '@/components/table2/Table.vue';
import { TextColumn, ActionColumn, ConfirmedAction } from '@/components/table2/table_util';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';

import AddMenuStructureModal from '@/components/core/menu/menuStructure/AddMenuStructureModal.vue';
import { VIEW_ROLES } from '@/router/roles';

import { MAP_CONFIG_CONTEXT_LABEL } from '@/components/core/menu/menuStructure/menu-structure-utils';

export default {
  components: {
    PageHeaderTitleNavigation, 
    Table, 
    GhostLoading, 
    AddMenuStructureModal, 
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      list: MENU_STRUCTURE_TYPES.GETTERS.MENU_STRUCTURES,
    }),
    headerActions() {
      return [
        PageHeaderSimpleAction('ADD', 'hinzufügen'),
        PageHeaderSimpleAction('CONFIG', 'Einstellungen'),
      ];
    },
    headers() {
      return {
        center: [
          TextColumn('label', 'Beschreibung').makeLink(),
          ...(this.$hasRoles([VIEW_ROLES.VIEW_INTERN]) ? [ TextColumn('contextLabel', 'Ebene') ] : []),
        ],
        lockedRight: [
          ActionColumn('actions'),
        ],
      };
    },
    rows() {
      return [ ...this.list ]
        .map(ms => ({ 
          ...ms, 
          contextLabel: MAP_CONFIG_CONTEXT_LABEL[ms.context] || ms.context, 
          actions: [ 
            ...(ms.id < 0 ? [] : [ ConfirmedAction('DELETE', 'PhTrash', 'Löschen', 'Sind Sie sicher, dass Sie diese Menüstrukturkonfiguration löschen möchten?') ]), 
            // TODO add an action to create a variation of the current menu
          ], 
        }));
    },
  },
  methods: {
    async findMenuStructures() {
      try {
        this.loading = true;
        await this.$store.dispatch(MENU_STRUCTURE_TYPES.ACTIONS.FIND_MENU_STRUCTURES);
      } finally {
        this.loading = false;
      }
    },
    addMenuStructure() {
      this.$refs.addMenuStructureModal.open();
    },
    editMenuStructure(item) {
      this.$router.push(`/intern/menu-structure/edit/${item.id}`);
    },
    async removeMenuStructure(item) {
      await this.$store.dispatch(MENU_STRUCTURE_TYPES.ACTIONS.REMOVE_MENU_STRUCTURE, item);
      this.findMenuStructures();
    },
    openConfig() {
      this.$router.push('/intern/menu-structure/config');
    },
    onMenuStructureSaved(menuStructure) {
      this.$router.push(`/intern/menu-structure/edit/${menuStructure.id}`);
    },
  },
  mounted() {
    this.findMenuStructures();
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({ to, from, label: 'Menüstrukturen' });
    next();
  },
}
</script>
