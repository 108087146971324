var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "kontoverwaltung-account-table__container" },
    [
      _vm.accountGroups
        ? _vm._l(_vm.accountGroups, function(accountGroup, index) {
            return _c(
              "div",
              {
                key: accountGroup.accountType + index,
                staticClass: "kontoverwaltung-account-table__inner",
                class: { box__container: _vm.useBoxContainer }
              },
              [
                _c(
                  "div",
                  { staticClass: "box__title" },
                  [
                    _c("AccountTypeIcon", {
                      attrs: { accountType: accountGroup.accountType }
                    }),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.accountTypeLabels[accountGroup.accountType]
                        ) +
                        " "
                    )
                  ],
                  1
                ),
                _c("Table", {
                  attrs: {
                    headers: _vm.headers(accountGroup.accountType),
                    rows: _vm.rows(
                      accountGroup.accounts,
                      accountGroup.accountType
                    ),
                    selected: _vm.selected,
                    mobileConfig: {},
                    hidePagination: ""
                  },
                  on: {
                    selected: function($event) {
                      return _vm.updateSelected(
                        accountGroup.accountType,
                        $event
                      )
                    },
                    action: function($event) {
                      return _vm.$emit("action-" + $event.key, $event.row)
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "lastExternalUpdate",
                        fn: function(row) {
                          return [
                            !row.account.authNeeded
                              ? _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(row.account.lastExternalUpdate) +
                                      " "
                                  )
                                ])
                              : _c("Pill", {
                                  attrs: {
                                    label: "Daten nicht aktualisiert",
                                    type: "danger"
                                  }
                                })
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                })
              ],
              1
            )
          })
        : _c("div", { staticClass: "text-centered" }, [_vm._v("Keine Daten")])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }