var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "box__container",
        staticStyle: { display: "flex", "flex-direction": "column" }
      },
      [
        _c(
          "div",
          { staticClass: "row", style: _vm.styleOrder("kennzeichen") },
          [
            _c(
              "div",
              { staticClass: "col-12 col-sm-6" },
              [
                _c("InputField", {
                  ref: "kennzeichenInput",
                  attrs: { label: "Kennzeichen", disabled: _vm.disabled },
                  model: {
                    value: _vm.fahrzeugdaten.kennzeichen,
                    callback: function($$v) {
                      _vm.$set(_vm.fahrzeugdaten, "kennzeichen", $$v)
                    },
                    expression: "fahrzeugdaten.kennzeichen"
                  }
                })
              ],
              1
            )
          ]
        ),
        _c("div", { staticClass: "row", style: _vm.styleOrder("fzgart") }, [
          _c(
            "div",
            { staticClass: "col-12 col-sm-6" },
            [
              _c("ComboBox", {
                attrs: {
                  label: "Fahrzeugart (WKZ)",
                  values:
                    _vm.vertragsdatenFieldsDefinition &&
                    _vm.vertragsdatenFieldsDefinition.fzgart.value,
                  disabled: _vm.disabled
                },
                model: {
                  value: _vm.fahrzeugdaten.fzgart,
                  callback: function($$v) {
                    _vm.$set(_vm.fahrzeugdaten, "fzgart", $$v)
                  },
                  expression: "fahrzeugdaten.fzgart"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row", style: _vm.styleOrder("marke") }, [
          _c(
            "div",
            { staticClass: "col-12 col-sm-6" },
            [
              _c("InputField", {
                attrs: { label: "Marke", disabled: _vm.disabled },
                model: {
                  value: _vm.fahrzeugdaten.marke,
                  callback: function($$v) {
                    _vm.$set(_vm.fahrzeugdaten, "marke", $$v)
                  },
                  expression: "fahrzeugdaten.marke"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row", style: _vm.styleOrder("handelsbez") }, [
          _c(
            "div",
            { staticClass: "col-12 col-sm-6" },
            [
              _c("InputField", {
                attrs: { label: "Handelsbezeichnung", disabled: _vm.disabled },
                model: {
                  value: _vm.fahrzeugdaten.handelsbez,
                  callback: function($$v) {
                    _vm.$set(_vm.fahrzeugdaten, "handelsbez", $$v)
                  },
                  expression: "fahrzeugdaten.handelsbez"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row", style: _vm.styleOrder("leistung") }, [
          _c(
            "div",
            { staticClass: "col-12 col-sm-6" },
            [
              _c("InputField", {
                attrs: {
                  type: "number",
                  isInteger: "",
                  label: "Leistung (kW)",
                  disabled: _vm.disabled
                },
                model: {
                  value: _vm.fahrzeugdaten.leistung,
                  callback: function($$v) {
                    _vm.$set(_vm.fahrzeugdaten, "leistung", $$v)
                  },
                  expression: "fahrzeugdaten.leistung"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row", style: _vm.styleOrder("hubraum") }, [
          _c(
            "div",
            { staticClass: "col-12 col-sm-6" },
            [
              _c("InputField", {
                attrs: {
                  type: "number",
                  isInteger: "",
                  label: "Hubraum (cm³)",
                  disabled: _vm.disabled
                },
                model: {
                  value: _vm.fahrzeugdaten.hubraum,
                  callback: function($$v) {
                    _vm.$set(_vm.fahrzeugdaten, "hubraum", $$v)
                  },
                  expression: "fahrzeugdaten.hubraum"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row", style: _vm.styleOrder("plaetze") }, [
          _c(
            "div",
            { staticClass: "col-12 col-sm-6" },
            [
              _c("InputField", {
                attrs: {
                  type: "number",
                  isInteger: "",
                  label: "Plätze",
                  disabled: _vm.disabled
                },
                model: {
                  value: _vm.fahrzeugdaten.plaetze,
                  callback: function($$v) {
                    _vm.$set(_vm.fahrzeugdaten, "plaetze", $$v)
                  },
                  expression: "fahrzeugdaten.plaetze"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row", style: _vm.styleOrder("nutzlast") }, [
          _c(
            "div",
            { staticClass: "col-12 col-sm-6" },
            [
              _c("InputField", {
                attrs: {
                  type: "number",
                  isInteger: "",
                  label: "Nutzlast in kg",
                  disabled: _vm.disabled
                },
                model: {
                  value: _vm.fahrzeugdaten.nutzlast,
                  callback: function($$v) {
                    _vm.$set(_vm.fahrzeugdaten, "nutzlast", $$v)
                  },
                  expression: "fahrzeugdaten.nutzlast"
                }
              })
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "row", style: _vm.styleOrder("verwendzweck") },
          [
            _c(
              "div",
              { staticClass: "col-12 col-sm-6" },
              [
                _c("InputField", {
                  attrs: { label: "Nutzung", disabled: _vm.disabled },
                  model: {
                    value: _vm.fahrzeugdaten.verwendzweck,
                    callback: function($$v) {
                      _vm.$set(_vm.fahrzeugdaten, "verwendzweck", $$v)
                    },
                    expression: "fahrzeugdaten.verwendzweck"
                  }
                })
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          { staticClass: "row", style: _vm.styleOrder("nutzerkreis") },
          [
            _c(
              "div",
              { staticClass: "col-12 col-sm-6" },
              [
                _c("InputField", {
                  attrs: { label: "Nutzerkreis", disabled: _vm.disabled },
                  model: {
                    value: _vm.fahrzeugdaten.nutzerkreis,
                    callback: function($$v) {
                      _vm.$set(_vm.fahrzeugdaten, "nutzerkreis", $$v)
                    },
                    expression: "fahrzeugdaten.nutzerkreis"
                  }
                })
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          { staticClass: "row", style: _vm.styleOrder("antriebsart") },
          [
            _c(
              "div",
              { staticClass: "col-12 col-sm-6" },
              [
                _c("ComboBox", {
                  attrs: {
                    label: "Antriebsart",
                    values:
                      _vm.vertragsdatenFieldsDefinition &&
                      _vm.vertragsdatenFieldsDefinition.antriebsart.value,
                    disabled: _vm.disabled
                  },
                  model: {
                    value: _vm.fahrzeugdaten.antriebsart,
                    callback: function($$v) {
                      _vm.$set(_vm.fahrzeugdaten, "antriebsart", $$v)
                    },
                    expression: "fahrzeugdaten.antriebsart"
                  }
                })
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          { staticClass: "row", style: _vm.styleOrder("erstzulassdat") },
          [
            _c(
              "div",
              { staticClass: "col-12 col-sm-6" },
              [
                _c("DatePickerField", {
                  attrs: {
                    label: "Erstzulassung",
                    isValueAsString: "",
                    disabled: _vm.disabled
                  },
                  model: {
                    value: _vm.fahrzeugdaten.erstzulassdat,
                    callback: function($$v) {
                      _vm.$set(_vm.fahrzeugdaten, "erstzulassdat", $$v)
                    },
                    expression: "fahrzeugdaten.erstzulassdat"
                  }
                })
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          { staticClass: "row", style: _vm.styleOrder("zulassung_vn_seit") },
          [
            _c(
              "div",
              { staticClass: "col-12 col-sm-6" },
              [
                _c("DatePickerField", {
                  attrs: {
                    label: "Zulassung auf VN seit",
                    isValueAsString: "",
                    disabled: _vm.disabled
                  },
                  model: {
                    value: _vm.fahrzeugdaten.zulassung_vn_seit,
                    callback: function($$v) {
                      _vm.$set(_vm.fahrzeugdaten, "zulassung_vn_seit", $$v)
                    },
                    expression: "fahrzeugdaten.zulassung_vn_seit"
                  }
                })
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          { staticClass: "row", style: _vm.styleOrder("kilometerstand") },
          [
            _c(
              "div",
              { staticClass: "col-12 col-sm-6" },
              [
                _c("InputField", {
                  attrs: {
                    label: "Kilometerstand",
                    disabled: _vm.disabled,
                    type: "number",
                    isInteger: ""
                  },
                  model: {
                    value: _vm.fahrzeugdaten.kilometerstand,
                    callback: function($$v) {
                      _vm.$set(_vm.fahrzeugdaten, "kilometerstand", $$v)
                    },
                    expression: "fahrzeugdaten.kilometerstand"
                  }
                })
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          { staticClass: "row", style: _vm.styleOrder("kilometerstand_am") },
          [
            _c(
              "div",
              { staticClass: "col-12 col-sm-6" },
              [
                _c("DatePickerField", {
                  attrs: {
                    label: "Kilometerstand am",
                    isValueAsString: "",
                    disabled: _vm.disabled
                  },
                  model: {
                    value: _vm.fahrzeugdaten.kilometerstand_am,
                    callback: function($$v) {
                      _vm.$set(_vm.fahrzeugdaten, "kilometerstand_am", $$v)
                    },
                    expression: "fahrzeugdaten.kilometerstand_am"
                  }
                })
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          { staticClass: "row", style: _vm.styleOrder("kilometerleistung") },
          [
            _c(
              "div",
              { staticClass: "col-12 col-sm-6" },
              [
                _c("InputField", {
                  attrs: {
                    label: "Kilometerleistung",
                    disabled: _vm.disabled,
                    type: "number",
                    isInteger: ""
                  },
                  model: {
                    value: _vm.fahrzeugdaten.kilometerleistung,
                    callback: function($$v) {
                      _vm.$set(_vm.fahrzeugdaten, "kilometerleistung", $$v)
                    },
                    expression: "fahrzeugdaten.kilometerleistung"
                  }
                })
              ],
              1
            )
          ]
        ),
        _c("div", { staticClass: "row", style: _vm.styleOrder("leasing") }, [
          _c(
            "div",
            { staticClass: "col-12 col-sm-6" },
            [
              _c("InputToggleSwitch", {
                attrs: { label: "Leasing", disabled: _vm.disabled },
                model: {
                  value: _vm.fahrzeugdaten.leasing,
                  callback: function($$v) {
                    _vm.$set(_vm.fahrzeugdaten, "leasing", $$v)
                  },
                  expression: "fahrzeugdaten.leasing"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row", style: _vm.styleOrder("kat") }, [
          _c(
            "div",
            { staticClass: "col-12 col-sm-6" },
            [
              _c("InputToggleSwitch", {
                attrs: { label: "KAT", disabled: _vm.disabled },
                model: {
                  value: _vm.fahrzeugdaten.kat,
                  callback: function($$v) {
                    _vm.$set(_vm.fahrzeugdaten, "kat", $$v)
                  },
                  expression: "fahrzeugdaten.kat"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row", style: _vm.styleOrder("abs") }, [
          _c(
            "div",
            { staticClass: "col-12 col-sm-6" },
            [
              _c("InputToggleSwitch", {
                attrs: { label: "ABS", disabled: _vm.disabled },
                model: {
                  value: _vm.fahrzeugdaten.abs,
                  callback: function($$v) {
                    _vm.$set(_vm.fahrzeugdaten, "abs", $$v)
                  },
                  expression: "fahrzeugdaten.abs"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row", style: _vm.styleOrder("neuwert") }, [
          _c(
            "div",
            { staticClass: "col-12 col-sm-6" },
            [
              _c("InputField", {
                attrs: {
                  label: "Neuwert",
                  disabled: _vm.disabled,
                  type: "currency"
                },
                model: {
                  value: _vm.fahrzeugdaten.neuwert,
                  callback: function($$v) {
                    _vm.$set(_vm.fahrzeugdaten, "neuwert", $$v)
                  },
                  expression: "fahrzeugdaten.neuwert"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row", style: _vm.styleOrder("zeitwert") }, [
          _c(
            "div",
            { staticClass: "col-12 col-sm-6" },
            [
              _c("InputField", {
                attrs: {
                  label: "Zeitwert",
                  disabled: _vm.disabled,
                  type: "currency"
                },
                model: {
                  value: _vm.fahrzeugdaten.zeitwert,
                  callback: function($$v) {
                    _vm.$set(_vm.fahrzeugdaten, "zeitwert", $$v)
                  },
                  expression: "fahrzeugdaten.zeitwert"
                }
              })
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "row", style: _vm.styleOrder("zeitwert_vom") },
          [
            _c(
              "div",
              { staticClass: "col-12 col-sm-6" },
              [
                _c("DatePickerField", {
                  attrs: {
                    label: "Zeitwert vom",
                    isValueAsString: "",
                    disabled: _vm.disabled
                  },
                  model: {
                    value: _vm.fahrzeugdaten.zeitwert_vom,
                    callback: function($$v) {
                      _vm.$set(_vm.fahrzeugdaten, "zeitwert_vom", $$v)
                    },
                    expression: "fahrzeugdaten.zeitwert_vom"
                  }
                })
              ],
              1
            )
          ]
        ),
        _c("div", { staticClass: "row", style: _vm.styleOrder("motornr") }, [
          _c(
            "div",
            { staticClass: "col-12 col-sm-6" },
            [
              _c("InputField", {
                attrs: { label: "Motornummer", disabled: _vm.disabled },
                model: {
                  value: _vm.fahrzeugdaten.motornr,
                  callback: function($$v) {
                    _vm.$set(_vm.fahrzeugdaten, "motornr", $$v)
                  },
                  expression: "fahrzeugdaten.motornr"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row", style: _vm.styleOrder("stellplatz") }, [
          _c(
            "div",
            { staticClass: "col-12 col-sm-6" },
            [
              _c("InputToggleSwitch", {
                attrs: { label: "Stellplatz", disabled: _vm.disabled },
                model: {
                  value: _vm.fahrzeugdaten.stellplatz,
                  callback: function($$v) {
                    _vm.$set(_vm.fahrzeugdaten, "stellplatz", $$v)
                  },
                  expression: "fahrzeugdaten.stellplatz"
                }
              })
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "row", style: _vm.styleOrder("wegfahrsperre") },
          [
            _c(
              "div",
              { staticClass: "col-12 col-sm-6" },
              [
                _c("InputToggleSwitch", {
                  attrs: { label: "Wegfahrsperre", disabled: _vm.disabled },
                  model: {
                    value: _vm.fahrzeugdaten.wegfahrsperre,
                    callback: function($$v) {
                      _vm.$set(_vm.fahrzeugdaten, "wegfahrsperre", $$v)
                    },
                    expression: "fahrzeugdaten.wegfahrsperre"
                  }
                })
              ],
              1
            )
          ]
        ),
        _c("div", { staticClass: "row", style: _vm.styleOrder("fahrgestnr") }, [
          _c(
            "div",
            { staticClass: "col-12 col-sm-6" },
            [
              _c("InputField", {
                attrs: { label: "Fahrzeug-Ident-Nr", disabled: _vm.disabled },
                model: {
                  value: _vm.fahrzeugdaten.fahrgestnr,
                  callback: function($$v) {
                    _vm.$set(_vm.fahrzeugdaten, "fahrgestnr", $$v)
                  },
                  expression: "fahrzeugdaten.fahrgestnr"
                }
              })
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "row", style: _vm.styleOrder("schluesselnr_fzg") },
          [
            _c(
              "div",
              { staticClass: "col-12 col-sm-6" },
              [
                _c("InputField", {
                  attrs: { label: "Schlüsselnr (1)", disabled: _vm.disabled },
                  model: {
                    value: _vm.fahrzeugdaten.schluesselnr_fzg,
                    callback: function($$v) {
                      _vm.$set(_vm.fahrzeugdaten, "schluesselnr_fzg", $$v)
                    },
                    expression: "fahrzeugdaten.schluesselnr_fzg"
                  }
                })
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          { staticClass: "row", style: _vm.styleOrder("schluesselnr_herst") },
          [
            _c(
              "div",
              { staticClass: "col-12 col-sm-6" },
              [
                _c("InputField", {
                  attrs: {
                    label: "Hersteller-Schlüsselnr(2)",
                    disabled: _vm.disabled
                  },
                  model: {
                    value: _vm.fahrzeugdaten.schluesselnr_herst,
                    callback: function($$v) {
                      _vm.$set(_vm.fahrzeugdaten, "schluesselnr_herst", $$v)
                    },
                    expression: "fahrzeugdaten.schluesselnr_herst"
                  }
                })
              ],
              1
            )
          ]
        ),
        _c(
          "div",
          { staticClass: "row", style: _vm.styleOrder("schluesselnr_typ") },
          [
            _c(
              "div",
              { staticClass: "col-12 col-sm-6" },
              [
                _c("InputField", {
                  attrs: {
                    label: "Typ-Schlüsselnr(3)",
                    disabled: _vm.disabled
                  },
                  model: {
                    value: _vm.fahrzeugdaten.schluesselnr_typ,
                    callback: function($$v) {
                      _vm.$set(_vm.fahrzeugdaten, "schluesselnr_typ", $$v)
                    },
                    expression: "fahrzeugdaten.schluesselnr_typ"
                  }
                })
              ],
              1
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }