var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("label", { staticClass: "bold" }, [_vm._v(_vm._s(_vm.label))]),
      _c("div", { staticClass: "row pl-2" }, [
        _c(
          "div",
          {
            staticClass: "col-12 col-md-8 col-lg-10 basisfonds",
            class: { invalid: _vm.isInvalid }
          },
          [_vm._v(" " + _vm._s(_vm.getFondStr) + " ")]
        ),
        _c("div", { staticClass: "col-12 col-md-4 col-lg-2 pt-2" }, [
          _c("div", { staticClass: "d-flex" }, [
            _c(
              "a",
              {
                class: { disabled: _vm.disabled },
                attrs: { title: "Position hinzufügen" },
                on: { click: _vm.openFondsfinder }
              },
              [_c("ph-pencil", { staticClass: "pl-2", attrs: { size: 26 } })],
              1
            ),
            _c(
              "a",
              {
                class: { disabled: _vm.disabled },
                attrs: { title: "Position löschen" },
                on: {
                  click: function($event) {
                    return _vm.doDeletePosition()
                  }
                }
              },
              [_c("ph-trash", { staticClass: "pl-2", attrs: { size: 26 } })],
              1
            )
          ])
        ])
      ]),
      _vm.config && _vm.config.betragId
        ? _c(
            "div",
            { staticClass: "mt-2" },
            [
              _vm.position
                ? _c("InputField", {
                    attrs: {
                      isComponentHalfSize: true,
                      label: "Betrag",
                      type: "currency"
                    },
                    on: {
                      change: function($event) {
                        return _vm.updateBetrag($event)
                      }
                    },
                    model: {
                      value: _vm.betrag,
                      callback: function($$v) {
                        _vm.betrag = $$v
                      },
                      expression: "betrag"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.position && _vm.config && _vm.config.positionFields
        ? _c(
            "div",
            [
              _c("WertpapierAuswahlPositionFields", {
                attrs: {
                  config: _vm.config,
                  categoryId: _vm.id,
                  antragId: _vm.antragId
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("DeletePositionModal", {
        ref: "deleteModal",
        attrs: { position: _vm.position, positionLabel: _vm.wertpapiername },
        on: {
          delete: function($event) {
            return _vm.deletePosition(_vm.antragId, _vm.id, _vm.position)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }