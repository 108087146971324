var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasRoles([_vm.VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS])
    ? _c(
        "BaseButton",
        {
          attrs: { isClear: "", title: "Kundensicht" },
          on: {
            click: function($event) {
              return _vm.openKundenzugang()
            }
          }
        },
        [_c("PhUserFocus", { attrs: { size: _vm.size } })],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }