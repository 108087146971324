var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "antrag-components__container" }, [
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("FormLabel", {
          attrs: {
            label:
              "Bitte tragen Sie hier die Daten des Depots ein, von dem die Wertpapiere übertragen werden sollen.",
            config: { bold: true }
          }
        }),
        _c(
          "div",
          { staticClass: "row" },
          [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("FormLabel", {
                  attrs: {
                    label: "Daten der bisher depotführenden Gesellschaft",
                    config: { bold: true }
                  }
                })
              ],
              1
            ),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Name der Bank" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherManuellName: $event })
                }
              },
              model: {
                value: _vm.form.bisherManuellName,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherManuellName", $$v)
                },
                expression: "form.bisherManuellName"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "BIC" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherManuellBIC: $event })
                }
              },
              model: {
                value: _vm.form.bisherManuellBIC,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherManuellBIC", $$v)
                },
                expression: "form.bisherManuellBIC"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Straße" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherManuellStrasse: $event })
                }
              },
              model: {
                value: _vm.form.bisherManuellStrasse,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherManuellStrasse", $$v)
                },
                expression: "form.bisherManuellStrasse"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "PLZ" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherManuellPLZ: $event })
                }
              },
              model: {
                value: _vm.form.bisherManuellPLZ,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherManuellPLZ", $$v)
                },
                expression: "form.bisherManuellPLZ"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Ort" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherManuellOrt: $event })
                }
              },
              model: {
                value: _vm.form.bisherManuellOrt,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherManuellOrt", $$v)
                },
                expression: "form.bisherManuellOrt"
              }
            }),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("FormLabel", {
                  attrs: {
                    label:
                      "Daten des Depots bei der bisher depotführenden Gesellschaft",
                    config: { bold: true }
                  }
                })
              ],
              1
            ),
            _c("InputField", {
              staticClass: "col-12",
              attrs: { label: "Depotnummer" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherDepotnr: $event })
                }
              },
              model: {
                value: _vm.form.bisherDepotnr,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherDepotnr", $$v)
                },
                expression: "form.bisherDepotnr"
              }
            }),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("FormLabel", {
                  attrs: { label: "1. Inhaber", config: { bold: true } }
                })
              ],
              1
            ),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Name" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherName1: $event })
                }
              },
              model: {
                value: _vm.form.bisherName1,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherName1", $$v)
                },
                expression: "form.bisherName1"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Vorname" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherVorname1: $event })
                }
              },
              model: {
                value: _vm.form.bisherVorname1,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherVorname1", $$v)
                },
                expression: "form.bisherVorname1"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: {
                label:
                  "TIN (Steuer-ID): Die TIN muss aus 10-11 oder 13 Ziffern bestehen."
              },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherID1: $event })
                }
              },
              model: {
                value: _vm.form.bisherID1,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherID1", $$v)
                },
                expression: "form.bisherID1"
              }
            }),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("FormLabel", {
                  attrs: { label: "2. Inhaber", config: { bold: true } }
                })
              ],
              1
            ),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Name" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherName2: $event })
                }
              },
              model: {
                value: _vm.form.bisherName2,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherName2", $$v)
                },
                expression: "form.bisherName2"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Vorname" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherVorname2: $event })
                }
              },
              model: {
                value: _vm.form.bisherVorname2,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherVorname2", $$v)
                },
                expression: "form.bisherVorname2"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: {
                label:
                  "TIN (Steuer-ID): Die TIN muss aus 10-11 oder 13 Ziffern bestehen."
              },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherID2: $event })
                }
              },
              model: {
                value: _vm.form.bisherID2,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherID2", $$v)
                },
                expression: "form.bisherID2"
              }
            })
          ],
          1
        ),
        _c(
          "BaseButton",
          {
            on: {
              click: function($event) {
                return _vm.$emit("next")
              }
            }
          },
          [_vm._v("Weiter")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }