var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "input-forms__container" }, [
    _c("div", { staticClass: "input-forms__label-container" }, [
      _c(
        "div",
        { staticClass: "mb-3" },
        [
          _c("ComboBox", {
            attrs: {
              isComponentHalfSize: true,
              values: _vm.valuesForDepot,
              label: "Depotpositionnummer"
            },
            on: {
              input: function($event) {
                return _vm.setPositionVerkauf($event)
              }
            },
            model: {
              value: _vm.depotPosition,
              callback: function($$v) {
                _vm.depotPosition = $$v
              },
              expression: "depotPosition"
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "row mt-0" }, [
        _c("div", { staticClass: "col" }, [
          _vm.title
            ? _c(
                "div",
                {
                  staticClass:
                    "input-forms__label-content input-forms__label-content--bigger"
                },
                [
                  _c("ph-bank", { attrs: { size: 16 } }),
                  _vm._v(" " + _vm._s(_vm.title) + " ")
                ],
                1
              )
            : _vm._e()
        ]),
        _c(
          "div",
          { staticClass: "col-12 col-xl-4 mt-3 pl-2" },
          [
            _c(
              "BaseButton",
              {
                attrs: {
                  isEmbedded: true,
                  disabled: _vm.disabled || !_vm.depotPosition
                },
                on: { click: _vm.openFormsFinder }
              },
              [_vm._v("Fonds hinzufügen")]
            )
          ],
          1
        )
      ])
    ]),
    _c(
      "div",
      { staticClass: "input-forms__input-container" },
      [
        _vm.rows.length
          ? _c("Table", {
              attrs: {
                headers: _vm.headers,
                rows: _vm.rows,
                rowId: "index",
                hidePagination: ""
              },
              on: { "action-DELETE": _vm.openModalDeleteFond },
              scopedSlots: _vm._u(
                [
                  {
                    key: "einmalBetrag",
                    fn: function(row) {
                      return [
                        _c("InputField", {
                          attrs: {
                            type: "currency",
                            precision: 2,
                            value: row.einmalBetrag,
                            id: "einmalBetrag" + row.index,
                            disabled:
                              _vm.disabled ||
                              row.einmalIstGesamtbestand ||
                              _vm.einmalBetragDisabled(row)
                          },
                          on: {
                            change: function($event) {
                              return _vm.onChangeInputField(
                                $event,
                                row,
                                "einmalBetrag"
                              )
                            }
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "einmalAnteile",
                    fn: function(row) {
                      return [
                        _c("InputField", {
                          attrs: {
                            type: "number",
                            precision: 4,
                            value: row.einmalAnteile,
                            id: "einmalAnteile" + row.index,
                            disabled: _vm.disabled || row.einmalIstGesamtbestand
                          },
                          on: {
                            change: function($event) {
                              return _vm.onChangeInputField(
                                $event,
                                row,
                                "einmalAnteile"
                              )
                            }
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "einmalIstGesamtbestand",
                    fn: function(row) {
                      return [
                        _c("InputToggleSwitch", {
                          attrs: {
                            value: row.einmalIstGesamtbestand,
                            id: "einmalIstGesamtbestand" + row.index,
                            disabled: _vm.disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.onChangeInputField(
                                $event,
                                row,
                                "einmalIstGesamtbestand"
                              )
                            }
                          }
                        })
                      ]
                    }
                  },
                  {
                    key: "sparplaeneLoeschen",
                    fn: function(row) {
                      return [
                        _c("InputToggleSwitch", {
                          attrs: {
                            value: row.sparplaeneLoeschen,
                            id: "sparplaeneLoeschen" + row.index,
                            disabled: _vm.disabled
                          },
                          on: {
                            input: function($event) {
                              return _vm.onChangeInputField(
                                $event,
                                row,
                                "sparplaeneLoeschen"
                              )
                            }
                          }
                        })
                      ]
                    }
                  }
                ],
                null,
                false,
                1549310901
              )
            })
          : _vm._e(),
        _vm.config && _vm.config.positionFields
          ? _c(
              "div",
              [
                _c("WertpapierAuswahlPositionFields", {
                  attrs: {
                    config: _vm.config,
                    categoryId: _vm.categoryId,
                    antragId: _vm.antragId,
                    disabled: _vm.disabled
                  }
                })
              ],
              1
            )
          : _vm._e(),
        _c("DeletePositionModal", {
          ref: "deleteModal",
          attrs: {
            position: _vm.positionToDelete,
            positionLabel: _vm.labelToDelete
          },
          on: {
            delete: function($event) {
              return _vm.doDeletePosition()
            }
          }
        }),
        _c("BaseModal", {
          ref: "fehlerModal",
          attrs: { labelButtonCancel: "Ok", showConfirmButton: false },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-warning", {
                    staticClass: "color-danger mr-2",
                    attrs: { size: 16 }
                  }),
                  _c("span", { staticClass: "color-danger" }, [
                    _vm._v(
                      " Fehler: Sie haben die maximal mögliche Anzahl an Fonds erreicht. Es sind nur 5 Positionen möglich."
                    )
                  ])
                ]
              },
              proxy: true
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }