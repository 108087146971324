var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("InputField", {
        attrs: {
          label: "Name",
          isComponentHalfSize: "",
          disabled: _vm.nameDisabled,
          value: _vm.daten.name,
          renderDanger: !_vm.daten.name
        },
        on: {
          input: function($event) {
            return _vm.update("name", $event)
          }
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Straße",
          isComponentHalfSize: "",
          value: _vm.daten.strasse
        },
        on: {
          input: function($event) {
            return _vm.update("strasse", $event)
          }
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Postleitzahl",
          isComponentHalfSize: "",
          value: _vm.daten.plz
        },
        on: {
          input: function($event) {
            return _vm.update("plz", $event)
          }
        }
      }),
      _c("InputField", {
        attrs: { label: "Ort", isComponentHalfSize: "", value: _vm.daten.ort },
        on: {
          input: function($event) {
            return _vm.update("ort", $event)
          }
        }
      }),
      _c("ComboBox", {
        attrs: {
          label: "Land",
          isComponentHalfSize: "",
          sortComboboxValues: false,
          values: _vm.comboboxSelections.countries,
          value: _vm.daten.land
        },
        on: {
          input: function($event) {
            return _vm.update("land", $event)
          }
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Postfach",
          isComponentHalfSize: "",
          value: _vm.daten.postfach
        },
        on: {
          input: function($event) {
            return _vm.update("postfach", $event)
          }
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Postleitzahl für Postfach",
          isComponentHalfSize: "",
          value: _vm.daten.plzPostfach
        },
        on: {
          input: function($event) {
            return _vm.update("plzPostfach", $event)
          }
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Beschreibung",
          isComponentHalfSize: "",
          value: _vm.daten.beschreibung
        },
        on: {
          input: function($event) {
            return _vm.update("beschreibung", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }