import MARKTBERICHT_TYPES from './types';
import CORE_TYPES from '@/store/core/types'
import axios from 'axios';

export default {
  async [MARKTBERICHT_TYPES.ACTIONS.GET_MARKTBERICHT]({ commit, getters, state }, payload) {
    // if it's already loaded
    if (state.newsList?.length === payload) {
      return
    }

    const path = '/mrsnews?count=' + payload;
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY]}${path}`);
    if (response.status === 200) {
      commit(MARKTBERICHT_TYPES.MUTATIONS.GET_MARKTBERICHT_SUCCESS, response?.data?.newsList || [])
    }
  },

}