<template>
  <div>
    <GesellschaftTitleNavigation/>

    <GesellschaftFormulareLinks
      :hasHeader="false"
    />

  </div>
</template>

<script>
import GesellschaftFormulareLinks from '@/views/gesellschaft/broker/GesellschaftFormulareLinks.vue'
import GesellschaftTitleNavigation from '@/views/gesellschaft/intern/GesellschaftTitleNavigation.vue'


export default {
  components: {
    GesellschaftFormulareLinks,
    GesellschaftTitleNavigation,
  },
}
</script>