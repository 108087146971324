var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isPageAvailableOnSmallScreen
        ? [_vm._t("default")]
        : [
            _c("div", { staticClass: "box__container" }, [
              _c(
                "div",
                [
                  _c("p", [
                    _vm._v(
                      " Diese Seite ist auf mobilen Geräten nicht verfügbar. Bitte nutzen Sie diese Funktion auf einem großen Tablet oder Desktop Computer. "
                    )
                  ]),
                  _c("p", [_vm._v(" Vielen Dank! ")]),
                  _c("UndrawInTheOffice")
                ],
                1
              )
            ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }