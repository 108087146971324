<template>
  <div v-if="showCourtageHistotie">
    <div class="label-value--list no-margin" v-if="showStatisticsInvestment">
      <div><b>Investmentabschlüsse</b></div>
      <div class="label-value--item">
        <div class="label-value--item-label">Courtage Umsatz</div>
        <div class="label-value--item-value"><CurrencyLabel :value="courtage.umsatz" /></div>
      </div>
      <div class="label-value--item">
        <div class="label-value--item-label">Courtage Bestand</div>
        <div class="label-value--item-value"><CurrencyLabel :value="courtage.bestand" /></div>
      </div>
      <div class="label-value--item">
        <div class="label-value--item-label">Courtage Serviceentgelt</div>
        <div class="label-value--item-value"><CurrencyLabel :value="courtage.serviceGebuer" /></div>
      </div>
      <template v-if="!isFA">
        <div class="label-value--item">
          <div class="label-value--item-label">Courtage VWL</div>
          <div class="label-value--item-value"><CurrencyLabel :value="courtage.vwl" /></div>
        </div>
        <div class="label-value--item">
          <div class="label-value--item-label">Courtage Managemententgelt</div>
          <div class="label-value--item-value"><CurrencyLabel :value="courtage.management" /></div>
        </div>
        <div class="label-value--item">
          <div class="label-value--item-label">Courtage Verwaltungsgebühr</div>
          <div class="label-value--item-value"><CurrencyLabel :value="courtage.verwaltungsgebuehr" /></div>
        </div>
      </template>
      <div class="label-value--item">
        <div class="label-value--item-label">Courtage Gesamt</div>
        <div class="label-value--item-value"><CurrencyLabel :value="courtage.gesamt" /></div>
      </div>
    </div>

    <div class="label-value--list no-margin mt-3" v-if="showStatisticsVersicherung">
      <div><b>Versicherungen</b></div>
      <div class="label-value--item">
        <div class="label-value--item-label">Courtage Umsatz</div>
        <div class="label-value--item-value"><CurrencyLabel :value="courtageVers.umsatz" /></div>
      </div>
      <div class="label-value--item">
        <div class="label-value--item-label">Courtage Bestand</div>
        <div class="label-value--item-value"><CurrencyLabel :value="courtageVers.bestand" /></div>
      </div>
    </div>
    
    <div class="bottom-row" v-if="!$isSmallScreen">
      <div class="text-right">
        <router-link to="/home/courtagestatistik">Courtagen anzeigen</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import STECKBRIEF_TYPES from '../store/types';
import CORE_TYPES from '@/store/core/types';
import CurrencyLabel from '@/components/core/CurrencyLabel.vue';
import { EMPLOYEE_ROLES, BROKER_STRUCTURE_ROLES } from '@/router/roles';

export default {
  computed: {
    ...mapGetters({
      info: STECKBRIEF_TYPES.GETTERS.STECKBRIEF_CUSTOMER_INFO,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
    courtage() {
      return this.info?.steckBreifZusatz?.courtageOutput || {};
    },
    courtageVers() {
      return this.info?.steckBreifZusatz?.courtageVersOutput || {};
    },
    showStatisticsInvestment() {
      return this.hasRoles([
        [EMPLOYEE_ROLES.ZUGRIFF_STATISTIK_INVESTMENT, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS]
      ])
    },
    showStatisticsVersicherung() {
      return this.hasRoles([
        [EMPLOYEE_ROLES.ZUGRIFF_STATISTIK_VERSICHERUNG, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN]
      ])
    },
    showCourtageHistotie() {
      return this.hasRoles([
        [EMPLOYEE_ROLES.ZUGRIFF_COURTAGEHISTORIE]
      ])
    },
  },
  components: {
    CurrencyLabel,
  },
}
</script>

<style src='../steckbrief.scss' lang='scss' scoped></style>
