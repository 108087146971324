<template>
  <div>
    <div class="box__container">
      <div class="box__title">FATCA - Foreign Account Tax Compliance Act</div>
      <div class="row">
        <div class="col-12">
          <InputToggleSwitch 
            label="FATCA - Foreign Account Tax Compliance Act" 
            :inLineLabel="true"
            v-model="fatca.aktiv"
            @input="addCustomerDataEdited('fatca')" />
        </div>
      </div>
      <div class="forms__input--half-size" v-if="fatca.aktiv">
        <div class="row">
          <div class="col-12">
            <InputField
              label="Art des USA-Bezugs"
              v-model="fatca.text"
              @input="addCustomerDataEdited('fatca')" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import InputField from '@/components/core/forms/InputField.vue';

import persoenlicheDatenMixin from './persoenliche-daten-mixin.js';

export default {
  mixins: [persoenlicheDatenMixin],
  components: {
    InputToggleSwitch,
    InputField,
  },
  data() {
    return {
      ...this.initialState(),
    };
  },
  methods: {
    initialState() {
      return {
        fatca: {
          aktiv: false,
          text: null,
        }
      };
    },
  },
  mounted() {
    this.updateGroupIntoData('fatca');
  },
  
}
</script>
