var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "editModal",
          attrs: { modalTitle: "Link" },
          on: {
            onCloseButton: function($event) {
              return _vm.close()
            },
            close: function($event) {
              return _vm.close()
            },
            onConfirmButton: _vm.onConfirmButton
          }
        },
        [
          _vm.htmlLinkForm.currentSelectionIsEmpty &&
          !_vm.htmlLinkForm.previousUrl
            ? _c("InputField", {
                attrs: { label: "Anzeigentext", placeholder: "" },
                model: {
                  value: _vm.htmlLinkForm.linkText,
                  callback: function($$v) {
                    _vm.$set(_vm.htmlLinkForm, "linkText", $$v)
                  },
                  expression: "htmlLinkForm.linkText"
                }
              })
            : _vm._e(),
          _c("InputField", {
            attrs: { label: "URL", placeholder: "https://" },
            model: {
              value: _vm.htmlLinkForm.url,
              callback: function($$v) {
                _vm.$set(_vm.htmlLinkForm, "url", $$v)
              },
              expression: "htmlLinkForm.url"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }