<template>
  <div>
    <DBMT query="dbm_cache"> </DBMT>
  </div>
</template>

<script>
import DBMT from "@/views/dbm/DbmT.vue";

export default {
  components: {
    DBMT,
  },
};
</script>

