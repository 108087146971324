<template>
  <div>
    <div class="row" v-for="(field, fieldId, index) in form" :key="index">
      <div class="col-lg-12">
        <div v-if="field.type === 'LABEL'">
          <form-label :label="field.label" />
        </div>

        <div v-if="field.type === 'SWITCHER'">
          <InputToggleSwitch
            v-model="field.value"
            :label="field.label"
            :disabled="disabled"
            inLineLabel
            suppressValidationMessage
            @input="
              onChange({
                value: $event,
                field,
              })
            "
          />
        </div>

        <div v-if="field.type === 'TEXT_CURRENCY'">
          <InputField
            v-if="field.id && !field.hidden"
            :disabled="field.disabled || disabled"
            :label="field.label"
            v-model="field.value"
            isComponentHalfSize
            type="currency"
            :precision="2"
            @change="
              onChange({
                value: $event,
                field,
              })
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputField from "@/components/core/forms/InputField.vue";
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import FormLabel from "@/components/core/forms/FormLabel";

export default {
  props: {
    id: {
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    label: {
      type: String,
    },
    config: {
      type: Object,
    },
    antragData: {
      type: Object,
    },
  },
  components: {
    InputField,
    InputToggleSwitch,
    FormLabel,
  },
  mounted() {
    this.createForm();
  },
  data() {
    return {
      form: {},
    };
  },
  methods: {
    createForm() {
      this.form = this.config.positionFields
        .map((field) => {
          field.disabled =
            field.id === "display_gesamt_summe_einmal" ||
            field.id === "display_gesamt_summe_ratierlich";
          field.hidden = field.type === "TEXT_CURRENCY" && !field.values;
          field = { ...field, value: null };
          return field;
        })
        .reduce((acc, field) => ({ ...acc, [field.id]: field }), {});

      this.patchForm();
    },
    patchForm() {
      if (this.antragData) {
        for (const [key, field] of Object.entries(this.form)) {
          field.value = this.antragData[key] == null ? "" : this.antragData[key];
          this.changeFieldExibition(field);
        }
      }
    },
    onChange(event) {
      if (event.field.type === "SWITCHER") {
        this.changeFieldExibition(event.field);
      } else {
        this.updateTotals();
      }

      this.emitEventStore()
    },
    changeFieldExibition(field) {
      if (field && field.type === "SWITCHER") {
        switch (field.id) {
          case "check_betrag_einmal":
            this.form["input_betrag_einmal_text"].hidden = !field.value;
            if (!field.value) {
              this.form["input_betrag_einmal_text"].value = "";
            }
            break;
          case "check_betrag_regel":
            this.form["input_betrag_regel_text"].hidden = !field.value;
            if (!field.value) {
              this.form["input_betrag_regel_text"].value = "";
            }
            break;
          case "check_betrag_verkauf":
            this.form["input_betrag_verkauf_text"].hidden = !field.value;
            if (!field.value) {
              this.form["input_betrag_verkauf_text"].value = "";
            }
            break;
          case "check_betrag_entnahme":
            this.form["input_betrag_entnahme_text"].hidden = !field.value;
            if (!field.value) {
              this.form["input_betrag_entnahme_text"].value = "";
            }
            break;
          default:
            break;
        }

        this.updateTotals();
      }
    },
    updateTotals() {
      let summeEinmal = 0;
      let summeRatierlich = 0;
    
      const input_betrag_einmal_text = this.form["input_betrag_einmal_text"].value;
      const input_betrag_verkauf_text = this.form["input_betrag_verkauf_text"]?.value;

      if (input_betrag_einmal_text && input_betrag_verkauf_text) {
        summeEinmal = input_betrag_einmal_text - input_betrag_verkauf_text;
      } else {
        summeEinmal = input_betrag_einmal_text;
      }

      const input_betrag_regel_text = this.form["input_betrag_regel_text"].value;
      const input_betrag_entnahme_text = this.form["input_betrag_entnahme_text"]?.value;

      if (input_betrag_regel_text && input_betrag_entnahme_text) {
        summeRatierlich = input_betrag_regel_text - input_betrag_entnahme_text;
      } else {
        summeRatierlich = input_betrag_regel_text;
      }

      if(this.form["display_gesamt_summe_einmal"])
        this.form["display_gesamt_summe_einmal"].value = summeEinmal;
      if(this.form["display_gesamt_summe_ratierlich"])
      this.form["display_gesamt_summe_ratierlich"].value = summeRatierlich;

    },
    emitEventStore() {
        const form = Object.values(this.form).reduce((acc, field) => ({...acc, [field.id]: field.value}),{});
        this.$emit('change', form);
    }
  },
};
</script>