<template>
  <div>
    <PageHeaderActionCard title="Eingang" :showBreadcrumb="false">
      <template #actionButtons>
        <div>
          Zur Dokumentation bzw. Ablage Ihrer ein- und ausgehenden Kunden-E-Mails im MSC können Sie hier die Daten des Eingangs-Servers Ihres E-Mail Zugangs hinterlegen. Möchten Sie, dass die Mails aus dem MSC an Ihre Kunden von Ihrem E-Mail-Account aus geschickt werden, so müssen Sie auch den Ausgangsserver hinterlegen.
        </div>
        <div>
          Einmal täglich wird der Server auf Adressen überprüft, die im MSC vorhanden sind und eine Kopie der Mails im MSC unter dem Kunden gespeichert.<br>
          <b>Es werden keine Mails auf Ihrem Server gelöscht.</b>
        </div>
      </template>
    </PageHeaderActionCard>

    <div class="box__container">
    <GhostLoading
      v-if="loading"
      type="table"
      :config="{ table: { rows: 3 } }"
    />
    <Table
      v-else
      :headers="headers"
      :rows="rows"
      :rowsPerPage="10"
      :hidePagination="false"
      @click-server="editServer"
      @action-DELETE="deleteKontakt"
      @action-EDIT="editServer"
      @action-FOLDER_STRUCTURE="openOrdnerstruktur"
      @action-IGNORED_LIST="openIgnoredList"
      @action-ERRORS="openErrors"
    />
    </div>

    <BaseModal ref="errorModal" labelButtonCancel="Ok" :showConfirmButton="false">
      <template v-slot:modalTitle>
        <ph-warning :size="16" class="mr-2" style="color: orangered"/>
        Nicht durchsuchte Ordner im Postfach
      </template>
      <div>
        Es konnten nicht alle Ordner eingelesen werden: {{errorCount}}
        Ordner im Postfach konnten nicht durchsucht werden.
        Insbesondere Sonderzeichen oder Umlaute in Ordnernamen können zu diesem Problem führen.
      </div>
    </BaseModal>

  </div>
</template>

<script>
import BaseButton from "@/components/core/BaseButton.vue";
import Table from "@/components/table2/Table.vue";
import PageHeaderActionCard from "@/components/core/PageHeaderActionCard.vue";
import E_MAIL_ACCESS_TYPES from "@/store/eMailAccess/types";
import CORE_TYPES from "@/store/core/types";
import LOG_TYPES from "@/store/log/types";
import EMailAccesOrdnerstrukturModal from "@/components/eMailAccess/EMailAccesOrdnerstrukturSettings.vue";
import { TextColumn, ActionColumn, NumberColumn, SimpleAction } from "@/components/table2/table_util.js";
import { PhPencilLine, PhTrash, PhFolder, PhEnvelope  } from "phosphor-vue";
import { mapGetters } from "vuex";
import BaseModal from '@/components/core/BaseModal.vue';
import { buildMessage } from '@/helpers/log-message-helper';

export default {
  components: {
    Table,
    BaseButton,
    PageHeaderActionCard,
    EMailAccesOrdnerstrukturModal,
    BaseModal,
  },
  data() {
    return {
      headers: {
        lockedLeft: [TextColumn("server", "Server").makeLink().makeAlwaysVisible()],
        center: [
          TextColumn("type", "Type"),
          NumberColumn("port", "Port"),
          TextColumn("name", "Name"),
          TextColumn("freigeschaltet", "Freigeschaltet"),
          TextColumn("aktiv", "Aktiv"),
        ],
        lockedRight: [ActionColumn("actions", "")],
      },
      loading: false,
      errorCount: 0,
      breadcrumbLabel: 'zurück zu E-Mail Zugänge',
    };
  },
   mounted() {
    this.$store.dispatch(E_MAIL_ACCESS_TYPES.ACTIONS.GET_E_MAIL_SERVER);

    if(this.$route.query.errorMsg) {
      this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(this.$route.query.errorMsg, 'danger', false));
    }
    if (this.$route?.query?.SUCCESS) {
      this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage("Die Berechtigungen für IMAP wurden erteilt", 'primary', false));

      let newServerData = {
        'server': this.$route.query.INPUT_EINGANG_SERVER,
        'name': this.$route.query.INPUT_EINGANG_USER,
        'freigeschaltet': this.$route.query.SHARE_CHECKBOX,
        'aktiv': this.$route.query.ACTIVE_CHECKBOX,
        'type': "msgraph",
      };

      this.$store.dispatch(E_MAIL_ACCESS_TYPES.ACTIONS.SET_E_MAIL_SERVER, newServerData);
      
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: CORE_TYPES.GETTERS.IS_LOGGED_IN,
      getEmailAccess: E_MAIL_ACCESS_TYPES.GETTERS.GET_E_MAIL_SERVER,
    }),
    rows() {
      const rows = this.getEmailAccess?.map(row => {
        let newActions = [];
        row.actions.forEach((action) => {
          if (action == "DELETE") {
            newActions.push(SimpleAction('DELETE', PhTrash, "Löschen"));
          } else if (action == "EDIT") {
            newActions.push(SimpleAction("EDIT", PhPencilLine, "Bearbeiten"));
          } 
          else if (action == "FOLDER_STRUCTURE") {
            newActions.push(SimpleAction("FOLDER_STRUCTURE", PhFolder, "Ordnerstruktur"));
          } else if (action == "IGNORED_LIST") {
            newActions.push(SimpleAction("IGNORED_LIST", PhEnvelope, "Nicht berücksichtigte E-Mail-Adressen"));
          } 
          else if (action.startsWith('ERRORS')) {
            newActions.push(SimpleAction("ERRORS", PhFolderDotted, "Nicht durchsuchte Ordner im Postfach"));
          }
        });
      return {
        ...row,
        aktiv: row.aktiv ? 'Ja' : 'Nein',
        freigeschaltet: row.freigeschaltet ? 'Ja' : 'Nein',
        actions: newActions,
        type: row.type === 'msgraph' ? 'microsoft' : row.type,
      }});
      return rows;
    },
  },
  methods: {
    deleteKontakt(server) {
      this.$confirmModal({
        title: 'Kontaktdaten löschen',
        message: `Wollen Sie diese Kontaktdaten <b>${server.name}</b> wirklich löschen?`,
        labelButtonConfirm: 'Ja',
        labelButtonCancel: 'Nein',
        showCancelButton: true,
      })
      .then(() => this.$store.dispatch(E_MAIL_ACCESS_TYPES.ACTIONS.DELETE_E_MAIL_SERVER, server.id));
    },
    openErrors(server) {
      this.getEmailAccessTest.filter( s => s.id == server.id)[0].actions.forEach(action => {
        if(action.startsWith('ERRORS')) {
          this.errorCount = action.slice('ERRORS'.length);
        }
      })
      this.$refs.errorModal.open();
    },
    editServer(data) {
       if (data?.id === 0) {
        this.$store.commit(E_MAIL_ACCESS_TYPES.MUTATIONS.GET_MS_GRAPH_EMAIL_DATA, {});
      }
      this.$addBreadcrumb({
        label: this.breadcrumbLabel, 
        fullPath: this.$route.fullPath,
        breadcrumb: 'E-Mail Eingangsserver bearbeiten',
      });
      this.$router.push({path: `/intern/email-access/email-server-edit/${data?.id || 0}`})
    },
    openIgnoredList(server) {
      this.$addBreadcrumb({
        label: this.breadcrumbLabel, 
        fullPath: this.$route.fullPath,
        breadcrumb: 'Nicht berücksichtigte E-Mail-Adressen',
      });
      this.$router.push({ path: `/intern/email-access/email-ignored-addresses/${server.id}`})
    },
    openOrdnerstruktur(server) {
      this.$addBreadcrumb({
        label: this.breadcrumbLabel, 
        fullPath: this.$route.fullPath,
        breadcrumb: 'E-Mail Ordnerstruktur',
      });
      this.$router.push({ path: `/intern/email-access/email-ordner/${server.id}`})
    },
  },
 
};
</script>
