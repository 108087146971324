import actions from './actions';
import mutations from './mutations';
import getters from './getters';

/**
 */
export function getInitialState() {
  return {
    form: {

    },
    options: [0, {}, {}, {}, {}, {}]
  }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}