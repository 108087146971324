<template>
  <div class="mb-4">
    <DragnDropArea
      hoverText="Datei hier ablegen"
      @files="openAddDocumentModal($event)"
    >
      <DashboardPanel
        id="256017ba-71fb-4530-b3b9-c9b510987cb"
        :data="dashboardData"
        :canEdit="isEditable"
        :defaultOptionsMenu="customOptionsMenu"
        is3Columns
        :headerActions="headerActions"
        :noPrimaryAction="false"
        @executeAction="handleExecuteAction($event)"
        @action-BEARBEITEN="
          navigateTo('/persoenlichedaten/customer-data/steps/stammdaten')
        "
        @action-PDF="navigateTo('/persoenlichedaten/pdf-kundendaten')"
        @action-VCARD="navigateTo('/persoenlichedaten/vcard-kundendaten')"
        @action-COPY="openCopyCustomerModal()"
        @action-COPY-GROUP="openCopyCustomerGroupModal()"
        @action-BRIEF="navigateTo('/communication/mailcomposer-brief')"
        @action-EMAIL="navigateTo('/communication/mailcomposer')"
        @action-TICKET="navigateTo('/communication/tickets/new-ticket')"
        @action-ACTIVITY="navigateTo('/communication/postfach/aktivitaeten')"
        @action-HOBBYS-VORLIEBEN="openStep('hobbys-vorlieben')"
        @action-COURTAGE="openStep('courtage')"
      >
        <template #title>
          {{ fullname }}
        </template>
        <template #subtitle>
          <template v-if="userIdUnterNr">
            <span class="subtitle-part">{{ userIdUnterNr }}</span>
          </template>
        </template>
      </DashboardPanel>
    </DragnDropArea>

    <BaseModal ref="copyCustomerModal" :showDefaultButtons="false">
      <template #modalTitle>Kunde kopieren</template>
      <template #default>
        Soll der Kunde unter einer weiteren Kundennummer angelegt werden?
      </template>
      <template #footer>
        <BaseButton
          class="mr-16px"
          isSecondary
          @click="closeCopyCustomerModal()"
          :disabled="loading"
          >Nein</BaseButton
        >
        <BaseButton @click="copyCustomer()" :disabled="loading">
          Ja <AnimatedSpinner v-if="loading" />
        </BaseButton>
      </template>
    </BaseModal>

    <BaseModal ref="copyCustomerGroupModal" :showDefaultButtons="false">
      <template #modalTitle>Gruppenoberhaupt erzeugen</template>
      <template #default>
        Soll der Kunde unter einer weiteren Kundennummer als Kundengruppe
        nochmal angelegt werden?
      </template>
      <template #footer>
        <BaseButton
          class="mr-16px"
          isSecondary
          @click="closeCopyCustomerGroupModal()"
          :disabled="loading"
          >Nein</BaseButton
        >
        <BaseButton @click="copyCustomerGroup()" :disabled="loading">
          Ja <AnimatedSpinner v-if="loading" />
        </BaseButton>
      </template>
    </BaseModal>

    <BaseModal
      ref="editModal"
      :modalTitle="modalTitle"
      size="lg"
      :actions="baseModalActions"
      :showCancelButton="false"
      @onCloseButton="saveChanges()"
      @action-LEGITIMATION_DELETE_IMAGES="gespeicherteAusweisfotosLoeschen"
      @action-LEGITIMATION_SAVE="saveLegitimation"
      :showConfirmButton="false"
    >
      <Stammdaten
        v-if="editComponent == 'stammdaten'"
        :substepProperty="substepProperty"
      ></Stammdaten>
      <ZusaetzlichePersonen
        v-if="editComponent == 'zusaetzliche-personen'"
      ></ZusaetzlichePersonen>
      <Kommunikationsdaten
        v-if="editComponent == 'kommunikationsdaten'"
      ></Kommunikationsdaten>
      <Sonstiges v-if="editComponent == 'sonstiges'"></Sonstiges>
      <Bankverbindung v-if="editComponent == 'bankverbindung'"></Bankverbindung>
      <Arbeitgeber v-if="editComponent == 'arbeitgeber'"></Arbeitgeber>
      <HobbysVorlieben
        v-if="editComponent == 'hobbys-vorlieben'"
      ></HobbysVorlieben>
      <SchulbildungUndQualifikationen
        v-if="editComponent == 'schulbildung-und-qualifikationen'"
      ></SchulbildungUndQualifikationen>
      <PolitischExponiertePerson
        v-if="editComponent == 'pep'"
      ></PolitischExponiertePerson>
      <Courtage v-if="editComponent == 'courtage'"></Courtage>
    </BaseModal>
  </div>
</template>
  
  <script>
import CORE_TYPES from "@/store/core/types";
import { mapGetters } from "vuex";
import { PhCheck, PhWarning } from "phosphor-vue";
import CurrencyLabel from "@/components/core/CurrencyLabel.vue";
import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import DashboardPanel from "@/components/dashboard/DashboardPanel.vue";
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import { ROLES, VIEW_ROLES } from "@/router/roles.js";
import MailContactsModal from "@/views/versicherungen/MailContactsModal.vue";
import AufgabeBearbeitenModal from "@/views/versicherungen/AufgabeBearbeitenModal.vue";
import CopyInsuranceConfirmationModal from "@/views/versicherungen/CopyInsuranceConfirmationModal.vue";
import AddDocument from "@/views/versicherungen//AddDocument.vue";
import Pill from "@/components/core/Pill.vue";
import CUSTOMERDATA_TYPES from "@/store/customerData/types";
import DragnDropArea from "@/components/fileUpload/DragnDropArea.vue";
import persoenlicheDatenMixin from "./persoenliche-daten-mixin";
import BaseModal from "@/components/core/BaseModal.vue";
import BaseButton from "@/components/core/BaseButton.vue";
import Stammdaten from "@/components/persoenliche-daten/Stammdaten.vue";
import ZusaetzlichePersonen from "@/components/persoenliche-daten/ZusaetzlichePersonen.vue";
import Kommunikationsdaten from "@/components/persoenliche-daten/Kommunikationsdaten.vue";
import Sonstiges from "@/components/persoenliche-daten/Sonstiges.vue";
import Bankverbindung from "@/components/persoenliche-daten/Bankverbindung.vue";
import Arbeitgeber from "@/components/persoenliche-daten/Arbeitgeber.vue";
import HobbysVorlieben from "@/components/persoenliche-daten/HobbysVorlieben.vue";
import SchulbildungUndQualifikationen from "@/components/persoenliche-daten/SchulbildungUndQualifikationen.vue";
import PolitischExponiertePerson from "@/components/persoenliche-daten/PolitischExponiertePerson.vue";
import Courtage from "@/components/persoenliche-daten/Courtage.vue";
import EVENT_BUS, {
  GESPEICHERTE_AUSWEISFOTOS_LOESCHEN,
  SAVE_LEGITIMATION,
} from "@/event-bus";

export default {
  props: {},

  mixins: [persoenlicheDatenMixin, InteractiveHelpCommonsMixin],
  validators: {},

  data: function () {
    return {
      showAdditional: false,
      tableData: {
        headers: {},
        records: [],
      },
      selectedRowsValues: [],
      editComponent: null,
      substepProperty: null,
      modalTitle: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      isFA: CORE_TYPES.GETTERS.IS_FA,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
      isCustomerOnly: CORE_TYPES.GETTERS.IS_CUSTOMER_ONLY,
      customerData: CUSTOMERDATA_TYPES.GETTERS.SELECTED_CUSTOMER_DATA,
      customerDataConfig: CUSTOMERDATA_TYPES.GETTERS.CUSTOMER_DATA_CONFIG,
      fullname: CORE_TYPES.GETTERS.GET_USER_FULL_NAME,
      unternr: CORE_TYPES.GETTERS.GET_USER_UNTER_NR,
      userid: CORE_TYPES.GETTERS.GET_USER_ID,
      isFirma: CUSTOMERDATA_TYPES.GETTERS.IS_FIRMA,
      legitimationsurkundeChanged:
        CUSTOMERDATA_TYPES.GETTERS.LEGITIMATIONSURKUNDE_CHANGED,
    }),
    userIdUnterNr() {
      return this.unternr ? `${this.userid}-${this.unternr}` : this.userid;
    },
    isViewCustomerAsBypass() {
      return this.hasRoles([VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS]);
    },
    steps() {
      const steps = this.getStepsBegin
        .concat(this.additionalSteps)
        .concat(this.dynamicSteps)
        .concat(this.getStepsEnd);
      return this.isDynamicStepsAvailable ? steps : this.getSteps;
    },
    isDynamicStepsAvailable() {
      return this.isOriginalUserBroker || this.isOriginalUserIntern;
    },
    dynamicSteps() {
      return (this.versicherungenDetails?.steps || []).map((step) =>
        Object.assign(step, {
          hidden: !this.hasSparte(this.getSpartenData, step),
        })
      );
    },
    additionalSteps() {
      return this.versicherungenDetails?.additionalSteps || [];
    },
    hasInfo() {
      return !!this.info?.customerID;
    },
    baseModalActions() {
      if (this.substepProperty === "legitimationsurkunde") {
        return this.legitimationImagesMobileActions;
      }
      return [];
    },
    dashboardData() {
      const data = {
        widgets: [
          {
            name: "stammdaten",
            title: "Stammdaten",
            component: () =>
              import("@/components/persoenliche-daten/cards/Stammdaten.vue"),
            loading: () => false,
            actions: [
              {
                tableContainer: "stammdaten",
                legend: {
                  icon: "PhList",
                  key: "EDIT_COLUMNS",
                  label: "Zeilen anpassen",
                },
                visible: () => true,
              },
            ],
            overrideDefaultAction: false,
            isNavigable: this.isEditable,
          },
          {
            name: "adresse",
            title: "Adresse",
            component: () =>
              import("@/components/persoenliche-daten/cards/Adresse.vue"),
            loading: () => false,
            mainStep: "stammdaten",
            subStep: "adresse",
            actions: [
              {
                legend: {
                  icon: "PhList",
                  key: "EDIT_COLUMNS",
                  label: "Zeilen anpassen",
                },
                visible: () => true,
              },
            ],
            overrideDefaultAction: false,
            isNavigable: this.isEditable,
          },
          {
            name: "kommunikationsdaten",
            title: "Kommunikationsdaten",
            component: () =>
              import(
                "@/components/persoenliche-daten/cards/Kommunikationsdaten.vue"
              ),
            loading: () => false,
            overrideDefaultAction: false,
            isNavigable: this.isEditable,
          },
        ],
      };

      if (!this.isFirma) {
        if (this.isByPass) {
          data.widgets.push({
            name: "versandadresse",
            title: "Versandadresse",
            component: () =>
              import(
                "@/components/persoenliche-daten/cards/Versandadresse.vue"
              ),
            loading: () => false,
            mainStep: "stammdaten",
            subStep: "versandadresse",
            actions: [
              {
                legend: {
                  icon: "PhList",
                  key: "EDIT_COLUMNS",
                  label: "Zeilen anpassen",
                },
                visible: () => true,
              },
            ],
            overrideDefaultAction: false,
            isNavigable: this.isEditable,
          });

          data.widgets.push({
            name: "anschriftfeld",
            title: "Anschriftfeld",
            component: () =>
              import("@/components/persoenliche-daten/cards/Anschriftfeld.vue"),
            loading: () => false,
            mainStep: "stammdaten",
            subStep: "anschriftfeld",
            actions: [
              {
                legend: {
                  icon: "PhList",
                  key: "EDIT_COLUMNS",
                  label: "Zeilen anpassen",
                },
                visible: () => true,
              },
            ],
            overrideDefaultAction: false,
            isNavigable: this.isEditable,
          });
        }

        if (this.darfVersicherungMaklerauftragSehen) {
          data.widgets.push({
            name: "sonstiges",
            title: "Maklervertrag",
            component: () =>
              import("@/components/persoenliche-daten/cards/Maklervertrag.vue"),
            loading: () => false,
            actions: [
              {
                legend: {
                  icon: "PhList",
                  key: "EDIT_COLUMNS",
                  label: "Zeilen anpassen",
                },
                visible: () => true,
              },
            ],
            overrideDefaultAction: false,
            isNavigable: this.isEditable,
          });
        }

        data.widgets.push({
          name: "geburtstag",
          title: "Geburtstag",
          component: () =>
            import("@/components/persoenliche-daten/cards/Geburtstag.vue"),
          loading: () => false,
          mainStep: "stammdaten",
          subStep: "geburtstag",
          actions: [
            {
              legend: {
                icon: "PhList",
                key: "EDIT_COLUMNS",
                label: "Zeilen anpassen",
              },
              visible: () => true,
            },
          ],
          overrideDefaultAction: false,
          isNavigable: this.isEditable,
        });

        data.widgets.push({
          name: "beruf",
          title: "Beruf",
          component: () =>
            import("@/components/persoenliche-daten/cards/PrivatBeruf.vue"),
          loading: () => false,
          mainStep: "stammdaten",
          subStep: "beruf",
          actions: [
            {
              legend: {
                icon: "PhList",
                key: "EDIT_COLUMNS",
                label: "Zeilen anpassen",
              },
              visible: () => true,
            },
          ],
          overrideDefaultAction: false,
          isNavigable: this.isEditable,
        });

        data.widgets.push({
          name: "steuerdaten",
          title: "Steuerdaten",
          component: () =>
            import(
              "@/components/persoenliche-daten/cards/PrivatSteuerdaten.vue"
            ),
          loading: () => false,
          mainStep: "stammdaten",
          subStep: "steuerdaten",
          actions: [
            {
              legend: {
                icon: "PhList",
                key: "EDIT_COLUMNS",
                label: "Zeilen anpassen",
              },
              visible: () => true,
            },
          ],
          overrideDefaultAction: false,
          isNavigable: this.isEditable,
        });
      }

      if (this.isFirma) {
        data.widgets.push({
          name: "geburtstag",
          title: "Geburtstag",
          component: () =>
            import("@/components/persoenliche-daten/cards/GeburtstagFirma.vue"),
          loading: () => false,
          mainStep: "stammdaten",
          subStep: "geburtstag",
          actions: [
            {
              legend: {
                icon: "PhList",
                key: "EDIT_COLUMNS",
                label: "Zeilen anpassen",
              },
              visible: () => true,
            },
          ],
          overrideDefaultAction: false,
          isNavigable: this.isEditable,
        });

        data.widgets.push({
          name: "steuerdaten",
          title: "Steuerdaten",
          component: () =>
            import(
              "@/components/persoenliche-daten/cards/FirmaSteuerdaten.vue"
            ),
          loading: () => false,
          mainStep: "stammdaten",
          subStep: "steuerdaten",
          actions: [
            {
              legend: {
                icon: "PhList",
                key: "EDIT_COLUMNS",
                label: "Zeilen anpassen",
              },
              visible: () => true,
            },
          ],
          overrideDefaultAction: false,
          isNavigable: this.isEditable,
        });
      }

      if (
        this.hasRoles([VIEW_ROLES.VIEW_CUSTOMER]) &&
        !this.hasRoles([ROLES.SUPER_CUSTOMER])
      ) {
        data.widgets.push({
          name: "zusaetzliche-personen",
          title: "Zusätzliche Personen",
          component: () =>
            import(
              "@/components/persoenliche-daten/cards/ZusaetzlichePersonen.vue"
            ),
          loading: () => false,
          actions: [
            {
              legend: {
                icon: "PhList",
                key: "EDIT_COLUMNS",
                label: "Zeilen anpassen",
              },
              visible: () => true,
            },
          ],
          overrideDefaultAction: true,
          isNavigable: this.isEditable,
        });

        data.widgets.push({
          name: "bankverbindung",
          title: "Bankverbindung",
          component: () =>
            import("@/components/persoenliche-daten/cards/Bankverbindung.vue"),
          loading: () => false,
          actions: [
            {
              legend: {
                icon: "PhList",
                key: "EDIT_COLUMNS",
                label: "Zeilen anpassen",
              },
              visible: () => true,
            },
          ],
          overrideDefaultAction: true,
          isNavigable: this.isEditable,
        });
      }

      if (
        this.hasRoles([VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS]) &&
        !this.hasRoles([ROLES.SUPER_CUSTOMER])
      ) {
        data.widgets.push({
          name: "arbeitgeber",
          title: "Arbeitgeber",
          component: () =>
            import("@/components/persoenliche-daten/cards/Arbeitgeber.vue"),
          loading: () => false,
          actions: [
            {
              legend: {
                icon: "PhList",
                key: "EDIT_COLUMNS",
                label: "Zeilen anpassen",
              },
              visible: () => true,
            },
          ],
          overrideDefaultAction: false,
          isNavigable: this.isEditable,
        });

        data.widgets.push({
          name: "hobbys-vorlieben_1",
          title: "Hobbys",
          component: () =>
            import("@/components/persoenliche-daten/cards/Hobbys.vue"),
          loading: () => false,
          mainStep: "hobbys-vorlieben",
          actions: [
            {
              legend: {
                icon: "PhList",
                key: "EDIT_COLUMNS",
                label: "Zeilen anpassen",
              },
              visible: () => true,
            },
          ],
          overrideDefaultAction: false,
          isNavigable: this.isEditable,
        });

        data.widgets.push({
          name: "hobbys-vorlieben_2",
          title: "Vorlieben",
          component: () =>
            import("@/components/persoenliche-daten/cards/Vorlieben.vue"),
          loading: () => false,
          mainStep: "hobbys-vorlieben",
          actions: [
            {
              legend: {
                icon: "PhList",
                key: "EDIT_COLUMNS",
                label: "Zeilen anpassen",
              },
              visible: () => true,
            },
          ],
          overrideDefaultAction: false,
          isNavigable: this.isEditable,
        });

        data.widgets.push({
          name: "hobbys-vorlieben_3",
          title: "Zusätzliche Informationen",
          component: () =>
            import(
              "@/components/persoenliche-daten/cards/ZusaetzlicheInfos.vue"
            ),
          loading: () => false,
          mainStep: "hobbys-vorlieben",
          actions: [
            {
              legend: {
                icon: "PhList",
                key: "EDIT_COLUMNS",
                label: "Zeilen anpassen",
              },
              visible: () => true,
            },
          ],
          overrideDefaultAction: false,
          isNavigable: this.isEditable,
        });

        data.widgets.push({
          name: "courtage",
          title: "Courtage",
          component: () =>
            import("@/components/persoenliche-daten/cards/Courtage.vue"),
          loading: () => false,
          actions: [
            {
              legend: {
                icon: "PhList",
                key: "EDIT_COLUMNS",
                label: "Zeilen anpassen",
              },
              visible: () => true,
            },
          ],
          overrideDefaultAction: false,
          isNavigable: this.isEditable,
        });
      }

      data.widgets.push({
        name: "legitimationsurkunde",
        title: "Legitimationsurkunde",
        component: () =>
          import(
            "@/components/persoenliche-daten/cards/Legitimationsurkunde.vue"
          ),
        loading: () => false,
        mainStep: "stammdaten",
        subStep: "legitimationsurkunde",
        actions: [
          {
            legend: {
              icon: "PhList",
              key: "EDIT_COLUMNS",
              label: "Zeilen anpassen",
            },
            visible: () => true,
          },
        ],
        alwaysEditable: true,
      });

      if (this.isViewCustomerAsBypass) {
        data.widgets.push({
          name: "schulbildung-und-qualifikationen",
          title: "Schulbildung und Qualifikationen",
          component: () =>
            import(
              "@/components/persoenliche-daten/cards/SchulbildungUndQualifikationen.vue"
            ),
          loading: () => false,
          actions: [
            {
              legend: {
                icon: "PhList",
                key: "EDIT_COLUMNS",
                label: "Zeilen anpassen",
              },
              visible: () => true,
            },
          ],
        });

        data.widgets.push({
          name: "pep",
          title: "PEP / FATCA",
          component: () =>
            import(
              "@/components/persoenliche-daten/cards/PolitischExponiertePerson.vue"
            ),
          loading: () => false,
          actions: [
            {
              legend: {
                icon: "PhList",
                key: "EDIT_COLUMNS",
                label: "Zeilen anpassen",
              },
              visible: () => true,
            },
          ],
        });
      }

      return data;
    },
    customOptionsMenu() {
      return [];
    },
  },
  mounted: async function () {
    await this.retrieveAllData().then(() => {});
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: "zurück zum Überblick",
      to,
      from,
    });

    next();
  },

  methods: {
    gespeicherteAusweisfotosLoeschen() {
      EVENT_BUS.$emit(GESPEICHERTE_AUSWEISFOTOS_LOESCHEN);
    },
    saveLegitimation() {
      EVENT_BUS.$emit(SAVE_LEGITIMATION);
    },
    navigateTo(event) {
      this.$router.push({ path: `${event}` });
    },
    async retrieveAllData() {
      const promiseToWait = this.$store.dispatch(
        CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA,
        { newCustomer: this.$route.query.newCustomer }
      );
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA_CONFIG),
        this.$store.dispatch(
          CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA_ADDITIONAL_PERSONS,
          { list: true }
        );
      await promiseToWait;
    },
    handleExecuteAction(actionData) {
      if (actionData.action?.legend?.key === "EDIT_COLUMNS") {
        if (actionData?.component?.$refs.table) {
          actionData?.component?.$refs.table.openColumnsConfig();
        } else if (actionData.action?.tableContainer) {
          actionData.component.$refs[
            actionData.action?.tableContainer
          ].$refs.table.openColumnsConfig();
        }
      } else {
        if (actionData.action?.action === "BEARBEITEN_APP") {
          this.editComponent =
            actionData?.widget?.mainStep || actionData?.widget?.name;

          if (actionData?.widget?.mainStep) {
            this.substepProperty = actionData?.widget?.name;
          } else {
            this.substepProperty = null;
          }

          if (this.isEditable || actionData?.widget?.alwaysEditable) {
            this.modalTitle = actionData?.widget?.title;
            this.$refs.editModal.open();
          }
        } else {
          if (actionData.widget?.mainStep) {
            let url = `/persoenlichedaten/customer-data/steps/${actionData?.widget?.mainStep}`;

            if (actionData?.widget?.subStep) {
              url = `${url}?substep=${actionData?.widget?.subStep}`;
            }

            this.$router.push(url);
          } else {
            this.$router.push(
              `/persoenlichedaten/customer-data/steps/${actionData?.widget?.name}`
            );
          }
        }
      }
    },
    confirmContactsToSend(selectedRecipients) {
      this.selectedRowsValues = selectedRecipients;
      this.direktInDieMailSpringen();
    },
  },
  filters: {
    currency(value, numberFixed = 2) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value / 1).toFixed(numberFixed).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " € ";
    },
    number(value, numberFixed = 0) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value / 1).toFixed(numberFixed).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."); // + '%';
    },
    percentage(value, numberFixed = 0) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value / 1).toFixed(numberFixed).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " % ";
    },
  },

  components: {
    PhCheck,
    PhWarning,
    CurrencyLabel,
    OptionMenu,
    DashboardPanel,
    MailContactsModal,
    AufgabeBearbeitenModal,
    CopyInsuranceConfirmationModal,
    AddDocument,
    Pill,
    DragnDropArea,
    BaseModal,
    BaseButton,
    Stammdaten,
    ZusaetzlichePersonen,
    Kommunikationsdaten,
    Sonstiges,
    Bankverbindung,
    Arbeitgeber,
    HobbysVorlieben,
    SchulbildungUndQualifikationen,
    PolitischExponiertePerson,
    Courtage,
  },
};
</script>
  
  <style lang="scss" scoped>
.subtitle-part {
  word-break: break-word;
}
</style>
  
  <!-- <style src='./InsuranceOverview.scss' lang='scss' scoped></style> -->
  