var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.keys.length > 1
        ? _vm._l(_vm.keys, function(k) {
            return _c("label", { key: k }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.key,
                    expression: "key"
                  }
                ],
                attrs: { type: "radio" },
                domProps: { value: k, checked: _vm._q(_vm.key, k) },
                on: {
                  change: function($event) {
                    _vm.key = k
                  }
                }
              }),
              _vm._v(" " + _vm._s(k) + " ")
            ])
          })
        : _vm._e(),
      _c(
        "table",
        _vm._l(_vm.displayedCode, function(code, index) {
          return _c("tr", { key: index }, [
            _c("td", { staticClass: "line-number" }, [_vm._v(_vm._s(index))]),
            _c("td", [_c("pre", { domProps: { innerHTML: _vm._s(code) } })])
          ])
        }),
        0
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }