<template>
  <DownloadLink
    :asButton="!isShowAsLink"
    :disabled="isDisabled"
    :isPrimary="isPrimary"
    :isSecondary="!isPrimary"
    :title="label"
    :filename="filename"
    :href="href"
    :downloadServicePath="downloadServicePath"
    :queryParams="queryParams"
    @clicked="onClick()" />
</template>
<script>
import actionMixin from './header-actions-mixin.js';
import DownloadLink from '@/components/core/download/DownloadLink.vue'

export default {
  mixins: [actionMixin],
  props: {
    filename: {
      type: String,
    },
    href: {
      type: String,
    },
    downloadServicePath: {
      type: String,
    },
    queryParams: {
      type: Object,
    },
  },
  components: {
    DownloadLink,
  }
}
</script>
