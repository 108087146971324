var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("AnlagezieleExpandable", {
        ref: "anlagezieleComp",
        attrs: {
          hasExtraFields: false,
          disabled: _vm.isDisabled || _vm.isRefusedPage,
          data: _vm.anlageziele,
          warnings: _vm.warningsProStep
        },
        on: {
          change: function($event) {
            return _vm.setChanged($event)
          },
          addZeile: function($event) {
            return _vm.addZeile($event)
          },
          removeZeile: function($event) {
            return _vm.removeZeile($event)
          }
        }
      }),
      !_vm.isFA &&
      _vm.data &&
      _vm.data.anlageziele &&
      _vm.data.anlageziele.zeigeZusatzESG
        ? _c(
            "div",
            [
              _c("InputRadioBoxGroup", {
                staticClass: "mt-4",
                attrs: {
                  title:
                    "Zu welchem Anteil sollen nachhaltige Finanzinstrumente (ESG) mindestens in Ihrem Portfolio enthalten sein?",
                  disabled: _vm.isDisabled || _vm.isRefusedPage,
                  validateUntouched: true,
                  values: [
                    { value: "KEINER", label: "Keine Vorgabe" },
                    { value: "MIND_20", label: "20%" },
                    { value: "MIND_40", label: "40%" },
                    { value: "MIND_60", label: "60%" },
                    { value: "MIND_80", label: "80%" }
                  ]
                },
                on: {
                  input: function($event) {
                    return _vm.setChanged({
                      compId: "INPUT_ANTEIL_ESG",
                      value: $event
                    })
                  }
                },
                model: {
                  value: _vm.esgAnteil,
                  callback: function($$v) {
                    _vm.esgAnteil = $$v
                  },
                  expression: "esgAnteil"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }