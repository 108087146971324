var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showEditImage
    ? _c(
        "div",
        [
          _c("BaseDropdownMenu", {
            ref: "dropdown",
            attrs: { closeWithInnerClick: false, disabled: _vm.disabled },
            on: { onShow: _vm.loadSizes },
            scopedSlots: _vm._u(
              [
                {
                  key: "hook-target",
                  fn: function() {
                    return [
                      _c("BaseToolbarButton", {
                        staticClass: "mr-0",
                        attrs: {
                          title: "Edit Image",
                          icon: _vm.icon,
                          disabled: _vm.disabled,
                          active: true
                        }
                      })
                    ]
                  },
                  proxy: true
                },
                {
                  key: "default",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        { staticClass: "tb-buttons__container d-flex" },
                        [
                          _c("InputField", {
                            staticClass: "tb-input-field",
                            attrs: { label: "Breite, px" },
                            on: {
                              change: function($event) {
                                return _vm.updateAspectRatio("width", $event)
                              }
                            },
                            model: {
                              value: _vm.width,
                              callback: function($$v) {
                                _vm.width = $$v
                              },
                              expression: "width"
                            }
                          }),
                          _c("InputField", {
                            staticClass: "tb-input-field",
                            attrs: { label: "Höhe, px" },
                            on: {
                              change: function($event) {
                                return _vm.updateAspectRatio("height", $event)
                              }
                            },
                            model: {
                              value: _vm.height,
                              callback: function($$v) {
                                _vm.height = $$v
                              },
                              expression: "height"
                            }
                          }),
                          _c("div", { staticClass: "mb-3" }, [
                            _vm._v(" Das Seitenverhältnis wird beibehalten ")
                          ]),
                          _c(
                            "div",
                            { staticClass: "layout__negative-margin--8" },
                            [
                              _c(
                                "BaseButton",
                                {
                                  attrs: { isSecondary: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.onRestoreDefault()
                                    }
                                  }
                                },
                                [_vm._v(" Standard wiederherstellen ")]
                              ),
                              _c(
                                "BaseButton",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.updateImageAttributes()
                                    }
                                  }
                                },
                                [_vm._v(" Übernehmen ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              3183824735
            )
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }