import actions from './actions';
import mutations from './mutations';
import getters from './getters';

/**
 * mutations: synchronous operation responsible for change the state
 * actions: asynchronous operation responsible for call external resources and commit mutations 
 */
export function getInitialState() {
  return {
    templates: null,
    currentNode: null,
    preview: null,
    standard: null,
    letterTemplates: null,
    letterCategories: null,
    letterTemplate: null,
    wordTemplate: null,
    backgroundTemplate: null,
    htmlShortcuts: null,
  };
}

export function getLetterTemplates() {
  return {
    htmlTemplates: [],
    worldTemplates: [],
  }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}
