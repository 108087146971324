var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "antrag-components__container" }, [
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("FormLabel", {
          attrs: { label: "Daten der bisher depotführenden Gesellschaft" }
        }),
        _c("InputField", {
          attrs: { label: "Depotnummer" },
          on: {
            change: function($event) {
              return _vm.$emit("save", { bisherDepotnr: $event })
            }
          },
          model: {
            value: _vm.form.bisherDepotnr,
            callback: function($$v) {
              _vm.$set(_vm.form, "bisherDepotnr", $$v)
            },
            expression: "form.bisherDepotnr"
          }
        }),
        _c("InputField", {
          attrs: { label: "Name" },
          on: {
            change: function($event) {
              return _vm.$emit("save", { bisherManuellName: $event })
            }
          },
          model: {
            value: _vm.form.bisherManuellName,
            callback: function($$v) {
              _vm.$set(_vm.form, "bisherManuellName", $$v)
            },
            expression: "form.bisherManuellName"
          }
        }),
        _c("InputField", {
          attrs: { label: "Straße/Nr." },
          on: {
            change: function($event) {
              return _vm.$emit("save", { bisherManuellStrasse: $event })
            }
          },
          model: {
            value: _vm.form.bisherManuellStrasse,
            callback: function($$v) {
              _vm.$set(_vm.form, "bisherManuellStrasse", $$v)
            },
            expression: "form.bisherManuellStrasse"
          }
        }),
        _c("InputField", {
          attrs: { label: "PLZ Ort" },
          on: {
            change: function($event) {
              return _vm.$emit("save", { bisherManuellOrt: $event })
            }
          },
          model: {
            value: _vm.form.bisherManuellOrt,
            callback: function($$v) {
              _vm.$set(_vm.form, "bisherManuellOrt", $$v)
            },
            expression: "form.bisherManuellOrt"
          }
        }),
        _c("InputField", {
          attrs: { label: "Land" },
          on: {
            change: function($event) {
              return _vm.$emit("save", { bisherManuellLand: $event })
            }
          },
          model: {
            value: _vm.form.bisherManuellLand,
            callback: function($$v) {
              _vm.$set(_vm.form, "bisherManuellLand", $$v)
            },
            expression: "form.bisherManuellLand"
          }
        }),
        _c("FormDivider"),
        _c(
          "BaseButton",
          {
            on: {
              click: function($event) {
                return _vm.$emit("next")
              }
            }
          },
          [_vm._v("Weiter")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }