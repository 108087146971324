<template>
  <BaseDropdownMenu placement="bottom-right" containerClass="option-menu-help--dropdown" :closeWithInnerClick="false">
    <template #hook-target>
      <button type="button" class="btn-clear clickable"><PhQuestion :size="20" /></button>
    </template>
    <template #default>
      <ul class="option-menu-help--items">
        <li class="option-menu-help--item">
          <span><PhEye :size="16" /></span>
          <span>Option kann abgewählt werden</span>
        </li>
        <li class="option-menu-help--item">
          <span><PhEye :size="16" weight="fill" /></span>
          <span>Option kann nicht abgewählt werden</span>
        </li>
        <li class="option-menu-help--item">
          <span><PhLock :size="16" /></span>
          <span>Option kann an anderer Stelle platziert oder versteckt werden</span>
        </li>
        <li class="option-menu-help--item">
          <span><PhLock :size="16" weight="fill" /></span>
          <span>Option ist fix und kann nicht bearbeitet werden</span>
        </li>
        <li class="option-menu-help--item">
          <span><PhTrashSimple :size="16" /></span>
          <span>Option kann versteckt werden</span>
        </li>
        <li class="option-menu-help--item">
          <span><PhList :size="16" /></span>
          <span>an anderer Stelle platzieren</span>
        </li>
      </ul>
    </template>
  </BaseDropdownMenu>
</template>

<script>
import BaseDropdownMenu from '@/components/core/BaseDropdownMenu.vue';
import { PhQuestion, PhEye, PhLock, PhTrashSimple, PhList, } from 'phosphor-vue';

export default {
  components: {
    BaseDropdownMenu,
    PhQuestion,
    PhEye,
    PhLock,
    PhTrashSimple,
    PhList,
  },
}
</script>

<style lang="scss" scoped>
::v-deep .dropdown-menu--hook-target {
  display: flex;
}
.option-menu-help--items {
  list-style: none;
  margin: 0;
  padding: 0;
  max-width: 328px;

  .option-menu-help--item {
    display: flex;
    margin: 8px 0;

    &:first-child {
      margin-top: 4px;
    }

    &:last-child {
      margin-bottom: 4px;
    }

    span {
      line-height: 1.2em;

      &:first-child {
        flex: 0 0 16px;
        margin: 0 8px 0 0;
      }
      &:last-child {
        flex: 1 1 auto;
      }
    }
  }
}
</style>

<!-- GLOBAL STYLE -->
<style>
.dropdown-menu__container.option-menu-help--dropdown {
  margin-left: 16px;
}

@media screen and (max-width: 460px) {
  .dropdown-menu__container.option-menu-help--dropdown.active::after {
    display: none;
  }
}
</style>
