<template>
  <div class="box__container">
    <DragnDropArea @files="dropFiles" hoverText="Datei hier ablegen">
      <div class="box__title">Zusätzliche Dokumente</div>
      <div class="font-strong mb-1">
        Wenn Sie möchten können Sie hier Dokumente auswählen, die dem Antrag hinzugefügt werden.
      </div>
      <Table 
        v-if="rows.length"
        :headers="headers"
        :rows="rows"
        :headerActions="tableHeaderActions"
        :rowsPerPage="10"
        :selected="antragAttachments"
        @selected="setSelected"
        @headerAction-UPLOAD="dropFiles([])"
      >
        <template v-slot:bezeichnung="row">
          <DownloadLink
            v-if="row.id" 
            :title="row.bezeichnung"
            downloadServicePath="/get_simple_file"
            :filename="`${row.bezeichnung} (${row.herkunft})`"
            :queryParams="{
              fileId: row.id
            }"
          />
          <span v-else>{{row.bezeichnung}}</span>
        </template>
      </Table>
    </DragnDropArea>

    <DocumentsUploadModal
      ref="uploadModal"
      :openOnMount="false"
      suppressReload
      :additionalInputs="currentAdditionalInputs" 
      :parentId="nodeId"
      @uploadComplete="reloadAttachmentList"
      accept=".pdf"
      :allowFotoUpload="false"
    />
  </div>
</template>

<script>
import ANTRAG_TYPES from '@/store/antrag/types';
import DOKUMENTENARCHIV_TYPES from '@/store/dokumentenarchiv/types';

import DownloadLink from '@/components/core/download/DownloadLink.vue';
import Table from "@/components/table2/Table.vue";
import { TextColumn, SlotColumn } from "@/components/table2/table_util.js";
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';
import { mapGetters } from 'vuex';
import axios from 'axios';

import DragnDropArea from "@/components/fileUpload/DragnDropArea.vue";
import DocumentsUploadModal from '@/components/fileUpload/DocumentsUploadModal.vue';

export default {
  mixins: [],
  components: {
    DownloadLink,
    Table,
    DragnDropArea,
    DocumentsUploadModal,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    steps: {
      type: Array,
      default: () => [],
    },
    antragId:{
      type: String,
    },
    antragData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      nodeId: 'Mg==',
    }
  },
  computed: {
    ...mapGetters({
      additionalUploadInputs: DOKUMENTENARCHIV_TYPES.GETTERS.ADDITIONAL_UPLOAD_INPUTS,
    }),
    isDisabled() {
      return this.disabled || this.isDataReadOnly || this.isAlreadySigned
    },
    isAlreadySigned() {
      return this.antragData && this.antragData['isSigniert'] === '1';
    },
    isDataReadOnly() {
      return !!(this.antragData && this.antragData['READ_ONLY']);
    },
    antragAttachments() {
    if(this.antragData && this.antragData['antragAttachments'] && !Array.isArray(this.antragData['antragAttachments'])){
      return this.antragData && Array.from(this.antragData['antragAttachments']) || []
    }else{
       return this.antragData && (this.antragData['antragAttachments']) || []
    }
    },
    step() {
      return this.steps?.find(step => step.stepKey === 'zusdokumente') || {}
    },
    attachments() {
      return this.step.config?.attachments || []
    },
    headers() {
      return {
        lockedLeft: [
          SlotColumn("bezeichnung", "Bezeichnung", undefined, 1).makeSortable(),
          TextColumn("herkunft", "Herkunft"),
        ],
        center: [],
        lockedRight: []
      };
    },
    rows() {
      return this.attachments.map(record => ({
        ...record,
        selectedDisabled: this.isDisabled,
        bezeichnung: record.bezeichnung || "[Unbenannt]",
      }))
    },
    tableHeaderActions() {
      return [
        PageHeaderSimpleAction('UPLOAD', 'Dokument hochladen')
      ];
    },
    currentAdditionalInputs() {
      return this.additionalUploadInputs?.additionalUploadInputs || [];
    },
  },
  methods: {
    setSelected(selected) {
      if (this.isDisabled) {
        return
      }

      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_ANTRAG_DATA, { 
        id: this.antragId, 
        data: { antragAttachments: selected, antragAttachmentsIds:  selected.map(x => String (x.id))}
      });
    },
    async dropFiles(files) {
      await this.$store.dispatch(
        DOKUMENTENARCHIV_TYPES.ACTIONS.RETRIEVE_ADDITIONAL_UPLOAD_INPUTS,
        {
          nodeId: this.nodeId,
        }
      );

      this.$refs.uploadModal.dropFiles(files)
      this.$refs.uploadModal.open()
    },
    reloadAttachmentList() {
      axios.get(`${process.env.VUE_APP_API}/antraegeService/getAttachmentList`, {defaultSpinner: true})
        .then(response => {
          if (response && response.data) {
            this.$set(this.step.config, 'attachments', response.data)
          }
        })
    },
  },
}
</script>