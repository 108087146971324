var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "scans-container" }, [
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("ImageUpload", {
            staticClass: "scan-container",
            attrs: {
              title:
                "Facsimile " +
                (_vm.suffix === "STEMPEL" ? "mit" : "ohne") +
                " Stempel",
              hoverText: "Facsimile mit Stempel hier ablegen",
              edgeCorrection: "",
              image: _vm.image,
              loading: _vm.loading,
              disabled: _vm.loading,
              config: _vm.imageUploadConfig,
              uploadButtonText: "Transparentes Facsimile hochladen",
              hasEditTool: false,
              hasPhotoButton: false,
              hasUploadButton: false,
              actions: _vm.imageUploadActions
            },
            on: {
              file: _vm.onFileChange,
              change: _vm.onFileChange,
              "action-FORMBLATT_ERSTELLEN": function($event) {
                return _vm.openFacsimile()
              },
              "action-TRANSPARENTES_FACSIMILE_HOCHLADEN": _vm.onFilesChange,
              "action-FACSIMILE_PDF_HOCHLADEN": _vm.onFilesChange,
              "action-FACSIMILE_LOESCHEN": _vm.removeFile
            }
          }),
          _c("div", { staticClass: "mt-4" }, [
            _vm._v(
              " Für die korrekte Verwendung des Facsimile muss der komplette Hintergrund gefärbt werden. "
            )
          ]),
          _vm.uploadStatus
            ? _c(
                "div",
                { staticClass: "font-bold mt-2" },
                [
                  _vm._v(
                    " Bitte warten Sie während die Seiten analysiert werden."
                  ),
                  _c("AnimatedSpinner")
                ],
                1
              )
            : _vm._e(),
          !_vm.isSmallScreen
            ? _c(
                "BaseButtonsContainer",
                { staticClass: "mt-4" },
                [
                  _c(
                    "BaseButton",
                    {
                      on: {
                        click: function($event) {
                          return _vm.openFacsimile()
                        }
                      }
                    },
                    [_vm._v("Formblatt erstellen")]
                  ),
                  _c(
                    "BaseFileSelect",
                    {
                      attrs: {
                        accept: ".gif, .png, .jpg, .jpeg",
                        isSecondary: ""
                      },
                      on: { files: _vm.onFilesChange }
                    },
                    [_vm._v("Transparentes Facsimile hochladen")]
                  ),
                  _c(
                    "BaseFileSelect",
                    {
                      attrs: { accept: ".pdf", isSecondary: "" },
                      on: { files: _vm.onFilesChange }
                    },
                    [_vm._v("Facsimile PDF hochladen")]
                  ),
                  _c(
                    "BaseButton",
                    {
                      attrs: { accept: ".pdf", isSecondary: "" },
                      on: { click: _vm.removeFile }
                    },
                    [_vm._v("Facsimile löschen")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }