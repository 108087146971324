<template>
<div>
    <PageHeaderTitleNavigation 
    noPrimaryAction
    title="BiPRO GDV-Dateien">
    </PageHeaderTitleNavigation>

    <BaseFilter
        title="BiPRO GDV-Dateien"
        filterId="BiPRO GDV-Dateien"
        :configFilter="filterConfig"
        :metadata="metadata"
        :defaultOptions="defaultOptions"
        showSaveButton
        :immidiateSearch="true"
        @onFilter="onFilter"
    />

    <div class="box__container">
        <div v-if="loading || !rows.length">
            <span class="box__title">
                BiPRO GDV-Dateien
            </span>
            <br>
        </div>
        <GhostLoading v-if="loading" type="table"/>
        <BigTable v-else-if="rows.length" style="clear: both;"
            selectable
            tableId="fea3ca15-41a8-430f-85d2-d7dde32b4360"
            title="BiPRO GDV-Dateien"
            :headers="headers"
            :rows="rows"
            :rowsPerPage="10"
            :page="page"
            :sortingState="sortingState"
            :initialPageSize="minNumRows"
            :headerActions="headerActions"
            @onClickHeader="onClickHeader"
            @selectedRow="onRowSelection"
            @selectedRows="onAllRowsSelection"
            @action-INFO="info"
            @action-DELETE="deleteRows([$event])"
            @headerAction-MARKIERTE_POSITIONEN_LOESCHEN="deleteRows(selected)"
        />
        <NoData v-else/>
    </div>
</div>
</template>

<script>
import CORE_TYPES from '@/store/core/types'
import LOG_TYPES from '@/store/log/types';
import BIPRO_TYPES from '@/store/bipro/types'

import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue'
import BaseFilter from "@/components/core/BaseFilter.vue";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';

import axios from 'axios';
import { mapGetters } from 'vuex';
import { DatePickerUtils } from '@/components/core/forms/DatePicker/date-picker-utils';
import {PhFile, PhInfo, PhTrash} from 'phosphor-vue';
import { buildMessage } from "@/helpers/log-message-helper";
import { PageHeaderConfirmedAction} from '@/components/core/header-title-navigation/page-header-utils';

import BigTable, { DATETIME_COLUMN } from '@/components/bigTable/BigTable.vue';
import {STRING_COLUMN, ACTION_COLUMN, FIXED_LEFT, NUMBER_COLUMN} from '@/components/bigTable/BigTable.vue';
import {SimpleAction, ConfirmedAction, DownloadLinkAction} from "@/components/table2/table_util.js";

const PARAMETER_ALLE = "PARAMETER_ALLE";

export default {
    mixins: [],
    components: {
        PageHeaderTitleNavigation,
        OptionMenu,
        BaseFilter,
        GhostLoading,
        NoData,
        BigTable,
    },
    data() {
        const startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);
        return {
            loading: false,
            filterConfig: {
                placeholderForDefSearchInput: 'Dateiname',
                checkDefaultSearchInput: false,
                cbDefSearchInput: null,
                hideFirstColumn: true,
                filterZurucksetzen: null,
                noResetOnDefaultSearchInputExit: true,
            },
            defaultOptions: {
                zeitraum: {
                    value: [{
                        key: "min",
                        value: startDate,
                    },
                    {
                        key: "max",
                        value: new Date(),
                    }],
                },
            },
            filterParams: {},
            files: null,
            headers: [
                { label: "Dateiname", key: "filename", width: 100, type: STRING_COLUMN, position: FIXED_LEFT },
                { label: "Statuslog", key: "statusLog", width: 250, type: STRING_COLUMN, visible: true },
                { label: "Gevo Art", key: "biProGevoArt", width: 250, type: STRING_COLUMN, visible: true },
                { label: "Erstelldatum", key: "creationDate", width: 150, type: DATETIME_COLUMN, visible: true },
                { label: "Gesellschaft", key: "gesellschaft", width: 250, type: STRING_COLUMN, visible: true },
                { label: "Lieferungsnr.", key: "transferId", width: 250, type: STRING_COLUMN, visible: true },
                { label: "Größe (B)", key: "fileSize", width: 250, type: NUMBER_COLUMN, visible: true },
                { label: "Eingespielt", key: "eingespielt", width: 150, type: DATETIME_COLUMN, visible: true },
                { label: "Ausgewertet", key: "ausgewertet", width: 150, type: DATETIME_COLUMN, visible: true },
                { label: "Zugang Id", key: "zugangId", width: 250, type: STRING_COLUMN, visible: true },
                { label: "Fremdnummer", key: "vermittlernr", width: 100, type: STRING_COLUMN, visible: true },
                { label: "Aktionen", key: "actions", width: 120, type: ACTION_COLUMN, visible: true },
            ],              
            selected: [],
            sortingState: { key: "filename", direction: "asc" },
            minNumRows: 100,
            page: 0,
            rows: [],  
        };
    },
    computed: {
        ...mapGetters({
            isFPPlus: CORE_TYPES.GETTERS.IS_FPP_PLUS,
            gesellschaftOptions: BIPRO_TYPES.GETTERS.GESELLSCHAFT_OPTIONS,
        }),
        headerActions() {
            const actions = [PageHeaderConfirmedAction('MARKIERTE_POSITIONEN_LOESCHEN', 'Markierte Positionen löschen', 'Sollen die markierten Einträge wirklich gelöscht werden?', 'Markierte löschen', 'Löschen').withDisabled(() => !this.rows.some(item => item.selected))];
            return actions;
         },        
        metadata() {
            const result = [
                {
                    type: 'group',
                    key: 'allgemein',
                    label: 'Allgemein',
                    menuItems: [
                        {
                            type: 'combobox',
                            label: 'Versicherungsgesellschaft',
                            key: 'gesellschaftId',
                            comboOptions: this.gesellschaftOptions || [],
                        },
                        {
                            type: 'text',
                            label: 'Dateiname',
                            key: 'filenameFilter',
                            emptyDenied: true,
                        },
                        {
                            type: 'dateRange',
                            label: 'Zeitraum',
                            key: 'zeitraum',
                            emptyDenied: true,
                        },
                        {
                            type: 'default',
                            label: 'mit gelöschten Einträgen anzeigen',
                            key: 'showDeleted',
                        },
                    ],
                },
            ];
            if (this.isFPPlus) {
                result[0].menuItems.push({
                    type: 'default',
                    label: 'auch die GDV Dateien der Struktur anzeigen',
                    key: 'showFilesOfStructure',
                    emptyDenied: true,
                });
            }
            return result;
        },

    },
    mounted() {
        if (!this.gesellschaftOptions)
            this.$store.dispatch(BIPRO_TYPES.ACTIONS.LOAD_GESELLSCHAFT_OPTIONS);
    },
    methods: {
        async onFilter(filterConfig) {
            let params = {};

            filterConfig.forEach(fc => {
                let value = fc.value;

                if (fc.key == 'showDeleted') 
                    value = fc.value ? fc.value === true : false;       
                if (fc.key == 'showFilesOfStructure')
                    value = true; // the filters with above keys have no input field and should only be considered as boolean (true if added to the filter)
                if (fc.key == "zeitraum") {
                    params.fromDate = DatePickerUtils.toDateStr(value.find(v => v.key == 'min').value);
                    params.toDate = DatePickerUtils.toDateStr(value.find(v => v.key == 'max').value);
                }
                else {
                    params[fc.key] = value;
                }
            });
            if (!params.gesellschaftId)
                params.gesellschaftId = PARAMETER_ALLE;
            this.filterParams = params;
            this.loadRows();
        },
        async loadRows() {
            const config = {
                defaultSpinner: true,
            };
            this.loading = true;
            try {
                axios.post(`${process.env.VUE_APP_API}/bipro/gdv_files`, this.filterParams, config)
                .then(response => {
                    this.files = response.data;

                    this.rows = (this.files || []).map((row, index) => {
                            const actions = [];
                            actions.push(DownloadLinkAction("DOWNLOAD", PhFile, "Herunterladen", row.filename, "/gdv_file", { id: row.id }));
                            if (row.hasImportResult)
                                actions.push(SimpleAction("INFO", PhInfo, "Datei Einlesen Resultat anzeigen"));
                            if (!row.invisible) {
                                actions.push(ConfirmedAction("DELETE", PhTrash, "Löschen", `Soll das Dokument wirklich gelöscht werden?`, 'Dokument löschen', "Löschen"));
                            }
                            return {
                                ...row,
                                selectedHidden: row.invisible,
                                index,
                                actions,
                            };
                        });
        


                }).catch(error => {
                    this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Bei der Suche ist ein Fehler aufgetreten.', 'danger'));
                })
            } finally {
                this.loading = false;
            }
        },
        onRowSelection(payload) {
            if (payload?.action == "added") {
                // Check if the row is not already selected before adding
                if (!this.selected.some(item => item === payload.row)) {
                    this.selected.push(payload.row);
                }
            } else if (payload?.action == "removed") {
                // Find the index of the row in selected array
                const index = this.selected.findIndex(item => item === payload.row);
                // If found, remove it
                if (index !== -1) {
                    this.selected.splice(index, 1);
                }
            }
        },
        onAllRowsSelection(payload)  {
            this.selected = payload;
        },
        onClickHeader(headerMetadata) {
            this.sortingState = {key: headerMetadata?.header?.key, direction: headerMetadata?.sortingState?.direction };
            // TODO: save the sorting state
        },                         
        info(row) {
            // navigate to "Datenimport" with the row's data prefilled
            this.$router.push({path: "/intern/gdv/import", query: {filename: row.filename}});
        },
        deleteRows(rows) {
            const params = rows.map(row => row.id).join('&id=');
            axios.delete(`${process.env.VUE_APP_API}/bipro/gdv_files?id=${params}`)
            .then(_ => {
                this.loadRows();
            })
        },
    },
    beforeRouteLeave(to, from, next) {
        this.$addBreadcrumb({
            label: 'zurück zu Übersicht', 
            fullPath: from.fullPath,
            breadcrumb: to?.meta?.breadcrumb?.label,
        });

        next();
    },    
}
</script>
