var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c("GhostLoading", { attrs: { type: "table", title: _vm.title } })
        : _c("Table", {
            attrs: { title: _vm.title, headers: _vm.headers, rows: _vm.rows }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }