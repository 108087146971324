export const MUTATION_PREFIX = 'GRAPHICAL_COURSE: ';
export const ACTIONS_PREFIX = 'GRAPHICAL_COURSE_ACTIONS_';
export const GETTERS_PREFIX = 'GRAPHICAL_COURSE_GETTERS_';

export default {
  GETTERS: {
    SITE: GETTERS_PREFIX + 'SITE',
    IS_DEPOT_LOADING: GETTERS_PREFIX + 'IS_DEPOT_LOADING',
    DEPOT_HAS_DATA: GETTERS_PREFIX + 'DEPOT_HAS_DATA',
    CONFIG: GETTERS_PREFIX + 'CONFIG',
  },
  MUTATIONS: {
    CONFIG: MUTATION_PREFIX + 'CONFIG',
    KIND: MUTATION_PREFIX + 'KIND',
    ZOOM: MUTATION_PREFIX + 'ZOOM',
    DEPOTID: MUTATION_PREFIX + 'DEPOTID',
    DEPOT: MUTATION_PREFIX + 'DEPOT',
    SMOOTH: MUTATION_PREFIX + 'SMOOTH',
  },
  ACTIONS: {
    CONFIG: ACTIONS_PREFIX + 'CONFIG',
    DEPOT: ACTIONS_PREFIX + 'DEPOT',
    CACHE_LEEREN: ACTIONS_PREFIX + 'CACHE_LEEREN',
  },
}

