import EMAIL_NOT_RECOGNIZED_TYPES from './types';
import Vue from 'vue'
import { getInitialState } from './index';

export default {
	[EMAIL_NOT_RECOGNIZED_TYPES.MUTATIONS.GET_EMAIL_NOT_RECOGNIZED_SUCCESS](state, data) {
		Vue.set(state, 'emailNotRecognized', data.emailNotRecognized)
	},
	[EMAIL_NOT_RECOGNIZED_TYPES.MUTATIONS.RESET_STATE](state) {
		Object.assign(state, getInitialState())
	},

}