var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        "data-name": "Layer 1",
        width: "835.21315",
        height: "613.09812",
        viewBox: "0 0 835.21315 613.09812"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "M970.80027,754.8404l.95118-1.75879c-12.75342-6.89746-28.62574-15.481-36.916-29.56738-8.17236-13.88623-8.35571-32.74268-.47852-49.21094,6.70362-14.01562,19.01783-26.57226,35.61109-36.313,3.273-1.92138,6.70874-3.74219,10.03149-5.50342,8.0315-4.25634,16.33643-8.6582,23.18433-14.90283,9.43164-8.60107,16.56787-23.03467,11.249-35.89111a23.85569,23.85569,0,0,0-20.67749-14.46729l-.11279,1.99707a21.86061,21.86061,0,0,1,18.94775,13.24805c4.94263,11.94727-1.83862,25.50488-10.75415,33.63574-6.66064,6.07373-14.8518,10.415-22.77319,14.61328-3.34155,1.771-6.79712,3.60254-10.10767,5.5459-16.93847,9.94336-29.52636,22.79785-36.40258,37.17432-8.1604,17.06055-7.94629,36.63672.55908,51.08887C941.68455,739.09479,957.82835,747.82575,970.80027,754.8404Z",
          transform: "translate(-181.36642 -141.74228)",
          fill: _vm.secondColor
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "799.71315",
          cy: "427.52453",
          rx: "17.5",
          ry: "8.5",
          fill: _vm.secondColor
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "785.71315",
          cy: "483.52453",
          rx: "17.5",
          ry: "8.5",
          fill: _vm.secondColor
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "817.71315",
          cy: "492.52453",
          rx: "17.5",
          ry: "8.5",
          fill: _vm.secondColor
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "767.71315",
          cy: "539.52453",
          rx: "17.5",
          ry: "8.5",
          fill: _vm.secondColor
        }
      }),
      _c("ellipse", {
        attrs: {
          cx: "732.71315",
          cy: "539.52453",
          rx: "17.5",
          ry: "8.5",
          fill: _vm.secondColor
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M318.65258,581.22591H256.55783a6.34945,6.34945,0,0,1-6.34238-6.34186V370.28612a6.34945,6.34945,0,0,1,6.34238-6.34186h62.09475a6.34945,6.34945,0,0,1,6.34238,6.34186V574.88405A6.34945,6.34945,0,0,1,318.65258,581.22591Z",
          transform: "translate(-181.36642 -141.74228)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M431.89013,581.22591H369.79538a6.34945,6.34945,0,0,1-6.34238-6.34186V289.09694a6.34946,6.34946,0,0,1,6.34238-6.34187h62.09475a6.34946,6.34946,0,0,1,6.34238,6.34187V574.88405A6.34945,6.34945,0,0,1,431.89013,581.22591Z",
          transform: "translate(-181.36642 -141.74228)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M545.12768,581.22591H483.03293a6.34945,6.34945,0,0,1-6.34238-6.34186V370.28612a6.34945,6.34945,0,0,1,6.34238-6.34186h62.09475a6.34945,6.34945,0,0,1,6.34238,6.34186V574.88405A6.34945,6.34945,0,0,1,545.12768,581.22591Z",
          transform: "translate(-181.36642 -141.74228)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M658.36523,581.22591H596.27048a6.21067,6.21067,0,0,1-6.34238-6.05456V256.76127a6.21067,6.21067,0,0,1,6.34238-6.05456h62.09475a6.21067,6.21067,0,0,1,6.34238,6.05456V575.17135A6.21067,6.21067,0,0,1,658.36523,581.22591Z",
          transform: "translate(-181.36642 -141.74228)",
          fill: _vm.color
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M771.60278,581.22591H709.508a6.34945,6.34945,0,0,1-6.34238-6.34186V199.36152a6.34945,6.34945,0,0,1,6.34238-6.34186h62.09475a6.34945,6.34945,0,0,1,6.34238,6.34186V574.88405A6.34945,6.34945,0,0,1,771.60278,581.22591Z",
          transform: "translate(-181.36642 -141.74228)",
          fill: _vm.color
        }
      }),
      _c("circle", {
        attrs: {
          cx: "106.23879",
          cy: "183.74395",
          r: "12.81935",
          fill: "#3f3d56"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "219.47634",
          cy: "100.4182",
          r: "12.81935",
          fill: "#3f3d56"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "332.71388",
          cy: "183.74395",
          r: "12.81935",
          fill: "#3f3d56"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "445.95143",
          cy: "59.82361",
          r: "12.81935",
          fill: "#3f3d56"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "559.18898",
          cy: "12.81935",
          r: "12.81935",
          fill: "#3f3d56"
        }
      }),
      _c("path", {
        attrs: {
          d: "M844.36642,583.60675h-662a1,1,0,1,1,0-2h662a1,1,0,0,1,0,2Z",
          transform: "translate(-181.36642 -141.74228)",
          fill: "#cbcbcb"
        }
      }),
      _c("path", {
        attrs: {
          d: "M1009.36642,754.60675h-324a1,1,0,0,1,0-2h324a1,1,0,0,1,0,2Z",
          transform: "translate(-181.36642 -141.74228)",
          fill: "#cbcbcb"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M801.30167,567.35289a7.92988,7.92988,0,0,0-1.03443-12.11551l3.9317-17.68959-10.16717-5.01117-5.20146,25.05911a7.9729,7.9729,0,0,0,12.47136,9.75716Z",
          transform: "translate(-181.36642 -141.74228)",
          fill: "#ffb7b7"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M912.48519,567.74064a7.93,7.93,0,0,0-1.9687-11.99917l2.55126-17.94076-10.52441-4.20951-3.247,25.38643a7.9729,7.9729,0,0,0,13.18889,8.763Z",
          transform: "translate(-181.36642 -141.74228)",
          fill: "#ffb7b7"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "651.862 602.078 641.494 602.077 636.562 562.087 651.864 562.088 651.862 602.078",
          fill: "#ffb7b7"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M835.87266,753.87018l-33.43043-.00124v-.42284a13.01275,13.01275,0,0,1,13.01205-13.01185h.00083l20.41817.00083Z",
          transform: "translate(-181.36642 -141.74228)",
          fill: "#2f2e41"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "686.535 602.078 676.167 602.077 671.235 562.087 686.537 562.088 686.535 602.078",
          fill: "#ffb7b7"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M870.54559,753.87018l-33.43043-.00124v-.42284a13.01275,13.01275,0,0,1,13.01206-13.01185h.00082l20.41817.00083Z",
          transform: "translate(-181.36642 -141.74228)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M888.77664,407.37241l11.17961-4.03435s4.74517,1.00766,4.74517,13.876,12.602,132.88228,12.602,132.88228l-22.0543,5.80691L882.78362,442.04Z",
          transform: "translate(-181.36642 -141.74228)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M814.07035,407.37241l-11.17961-4.03435s-4.74517,1.00766-4.74517,13.876-12.602,132.88228-12.602,132.88228l22.0543,5.80691L820.06336,442.04Z",
          transform: "translate(-181.36642 -141.74228)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M814.21351,544.24938,811.15773,634.401l6.76561,99.72381,17.43221.40286,2.537-93.02495,14.37658-46.51247-1.45041,137.09227,19.7391-.23579,12.22576-94.31836s16.14452-78.05673,7.19162-93.279l-41.232-13.71128Z",
          transform: "translate(-181.36642 -141.74228)",
          fill: "#2f2e41"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M814.54979,533.38618l-.995,21.68687s13.91139,6.92282,38.36383,2.70576,40.70648-1.437,40.70648-1.437l-1.87746-27.64561Z",
          transform: "translate(-181.36642 -141.74228)",
          fill: "#e5e5e5"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M802.446,403.65842l32.15376-14.08962,1.91777-7.37953,31.27959.90612,1.35159,7.53885,30.80754,12.70382L886.383,450.53524l1.392,33.20185,16.92637,60.51229s-14.27695,12.12481-48.57686,6.90809L850.2061,539.2319l-3.6203,12.61164s-30.07706,11.06444-38.413-3.33894L814.21351,444.459Z",
          transform: "translate(-181.36642 -141.74228)",
          fill: "#2f2e41"
        }
      }),
      _c("circle", {
        attrs: {
          cx: "670.50655",
          cy: "212.74354",
          r: "20.7708",
          fill: "#ffb7b7"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "M876.55254,346.43738a12.64979,12.64979,0,0,0-2.09872-4.68984,25.33974,25.33974,0,0,0-3.7828-10.91331,11.45012,11.45012,0,0,0-3.389-3.91163,10.00418,10.00418,0,0,0-5.33191-1.32935c-.3385-.00566-.94747.097-1.49558.122-.35063-.10857-.70467-.20551-1.05953-.29664-.20719-.46075-.61769-1.80357-.73213-1.71263l-1.54725,1.22935c-.65413-.11363-1.31172-.20218-1.97169-.27238q-.36048-1.02808-.72092-2.05619a14.08889,14.08889,0,0,0-1.02221,1.92119q-.98538-.04829-1.97211-.03384l-1.315-1.26323a1.85457,1.85457,0,0,1-1.45073,1.421c-.6051.06207-1.20777.14318-1.80843.23981-.04888-.00031-.09848,0-.14644-.00122-3.23851-.08075-6.72317-.226-9.43127,1.55195a9.29343,9.29343,0,0,0-3.00216,3.4075c-.33153.22536-.6642.44906-.98634.688a10.78,10.78,0,0,0-2.77778,2.75391,11.6768,11.6768,0,0,0-1.22914,2.97838,28.55117,28.55117,0,0,0-1.415,9.75368,14.476,14.476,0,0,0,3.55848,9.03419c1.389,1.4923,3.15453,2.59429,4.54533,4.08484,2.86373,3.0691,3.40054,7.34954,5.84994,10.597,2.59077,3.43475,7.31929,5.695,11.472,6.34282a19.16307,19.16307,0,0,0,14.27546-3.56031,8.83167,8.83167,0,0,0,2.43056-2.54833c1.44134-2.49306.83046-5.62653,1.36556-8.45609C872.36687,356.21425,877.42847,351.76348,876.55254,346.43738Z",
          transform: "translate(-181.36642 -141.74228)",
          fill: "#2f2e41"
        }
      }),
      _c("polygon", {
        attrs: {
          points:
            "105.555 184.546 104.361 182.942 219.477 97.239 332.348 181.429 446.764 57.205 446.969 57.121 558.009 11.292 558.772 13.141 447.936 58.885 332.597 184.11 219.476 99.733 105.555 184.546",
          fill: "#3f3d56"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }