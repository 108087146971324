<template>
  <div>
    <InputField
      label="Betrachtungszeitraum in Jahren (mindestens 3 Jahre)"
      v-model="years"
      id="years"
      :disabled="disabled"
      isComponentHalfSize
      @change="updateStore(getCategory(), 'years', $event)" />
    <InputCheckBoxGroup class="mt-3"
      :value="formCategories"
      title="Angaben über Kenntnisse und Erfahrungen mit Geschlossenen Investmentvermögen"
      :disabled="disabled"
      :values="categoriesGF"
      @checkBoxToggle="onSelection($event)"
    />
    <InputCheckBoxGroup class="mt-3"
      :value="form"
      :disabled="disabled"
      title="In welchen Wirtschaftsräumen investieren die von Ihnen erworbenen geschlossenen Investmentvermögen/Vermögensanlagen?"
      :values="[ 
        { label: 'Euroraum', path: 'euroraum' },
        { label: 'Sonstiges Europa', path: 'eurorest' },
        { label: 'Nordamerika', path: 'nordamerika' },
        { label: 'Südamerika', path: 'suedamerika' },
        { label: 'Asien', path: 'asien' },
        { label: 'Sonstiges', path: 'sonstiges' },
      ]"
      @checkBoxToggle="updateStoreCheckBoxGroup(getCategory(), $event)"/>
    <InputField
      v-model="sonstigesLand"
      :disabled="disabled"
      isComponentHalfSize
      @change="updateStore(getCategory(), 'sonstigesLand', $event)" />
    <InputRadioBoxGroup 
      title="Haben Sie Käufe für geschlossenes Investmentvermögen auf Kreditbasis?"
      v-model="investOnCredit" 
      :validateUntouched="true"
      :disabled="disabled"
      :inlineLabelValues="false"
      :values="[
        {value: 'no', label: 'nein'},
        {value: 'yes', label: 'ja'}
      ]"
      @input="updateStore(getCategory(), 'investOnCredit', getBooleanValue($event))" />
    <InputField v-if="investOnCredit === 'yes'"
      label="In welcher Höhe bewegte sich Ihr Kredit?"
      v-model="creditSum"
      type="currency"
      :disabled="disabled"
      isComponentHalfSize
      @change="updateStore(getCategory(), 'creditSum', $event)">
    </InputField>
  </div>
</template>

<script>

import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import InputField from '@/components/core/forms/InputField.vue';
import InputCheckBoxGroup from '@/components/core/forms/checkbox/InputCheckBoxGroup.vue';
import anlegerprofilMixin from '@/mixins/anlegerprofil/anlegerprofil-mixin.js';
import validator from "@/mixins/validator";

export default {
  mixins: [anlegerprofilMixin, validator],
  validators: {},
  components: {
    InputRadioBoxGroup,
    InputField,
    InputCheckBoxGroup,
  },
  data() {
    return {
      form: {},
      investOnCredit: '',
      creditSum: '',
      years: '',
      sonstigesLand: '',
      formCategories: {},
    };
  },
  computed: {
    disabled() {
      return this.isDisabled || this._isRefusedPage(this.data, 'kenntnisse') || !this.isGfZusatzAktiv;
    },
  },
  mounted() {
    this.patchForm();
    this.updateWarnings();
  },
  methods: {
    patchForm() {
      const data = this.getExperienceGF(this.getPersonType);
      if (data) {
        this.initFormCategories();
        this.form = data;
        this.investOnCredit = data.investOnCredit;
        this.creditSum = data.creditSum;
        this.years = data.years;
        this.sonstigesLand = data.sonstigesLand;
      } else {
        this.years = '3';
        this.updateStore(this.getCategory(), 'years', '3')
      }
    },
    onSelection(event) {
      if (event && Object.keys(event).length && this.stepper) {
        Object.keys(event).forEach(key => this.stepper.setSubstepHidden('kenntnisseGF', 'stepGF-card/' + key, !event[key]));
        const emptyCategories = this.categoriesGF.filter(category => !this.formCategories[category.path]).map(category => category.path);
        this.updateStoreCheckBoxGroup(this.getCategory(), { emptyCategories: emptyCategories })
      }
    },
    initFormCategories() {
      this.formCategories = {};
      const data = this.getExperienceGF(this.getPersonType);
      if (data?.categories?.length) {
        const selection = this.getVisibleKenntnisseGFSubsteps(this.stepper);
        data.categories.forEach(cat => {
          const hasSelection = selection.some(sel => sel.substepKey.includes(cat.path)) || this.isKennntnisseGFCardFilled(cat);
          if (hasSelection) {
            this.formCategories[cat.path] = hasSelection || false;
          }
        })
      }
      return this.formCategories;
    }
  },
}
</script>

<style scoped>

</style>