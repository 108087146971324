import actions from './actions';
import mutations from './mutations';
import getters from './getters';

/**
 * mutations: synchronous operation responsible for changing the state
 * actions: asynchronous operation responsible for calling external resources and commiting mutations 
 */

export function getInitialState() {
  return {
    pushSocket: null,
    /**   beteiligterInfos
     * maps calleeChatBeteiligterId of potential call-partners to objects like the following:
     * {
     *    displayName,
     *    profilBildUrl,  (optional)
     *    kundennr,       (only if that person is a customer)
     * }
     */
    beteiligterInfos: {},
    callInfos: [], // list of objects containing data about specific calls (may be incoming, established or ended). objects are created in handlePushSocket
    callId: null,
    prepareCallRequestUID: null,
    callStart: null,
    receivedStream: null,

    // due to chrome privacy policy changes, audioCtx and audioDestination must created from au user interaction: 
    // e.g. the user starts or answer a call 
    // if this variable is null, please call, "WEBRTC_TYPES.MUTATIONS.CREATE_AUDIO_CONTEXT"
    audioCtx: null, 
    audioDestination: null,

    muted: false, // because Vue.set doesn't work with MediaStreamTrack but we want to get information about the muted state to update automagically
    transmittedVideo: null,
    cameraVideo: null,
    chatInfos: [], // list of objects containing data about chats, created in WEBRTC_TYPES.ACTIONS.LOAD_CHAT_COMMUNICATION
    ringSignalState: false,
    weAreCallingId: null, // holds the calleeChatBeteiligterId of the person we call after clicking the call button until the callInfo object can be created or the attempt failed
    /**   calleeChatBeteiligterId
     * logged in as intern
     *    -> null
     * logged in as broker
     *    in customer view
     *      -> calleeChatBeteiligterId of the opened customer
     *    in broker view
     *      -> null
     * logged in as customer
     *    -> calleeChatBeteiligterId of an available broker, null if none are available
     */
    calleeChatBeteiligterId: null,
    /**   calleeChatBeteiligterAvailable
     * if logged in as broker: a map like
     *    {
     *      calleeChatBeteiligterId: true,   // if the customer with that id is available
     *    }
     * null otherwise
     */
    calleeChatBeteiligterAvailable: null,
    /**   callModalForceOpenId
     * calleeChatBeteiligterId of the call partner if the call modal is open, null otherwise
     * ignores availability changes
     */
    callModalForceOpenId: false,

    cameraOptions: [],
    microphoneOptions: [],
    speakerOptions: [],
    isDummyStream: false,
    payloadOfPushBeforeLogin: null, // holds the payload of a push when the user is on the start page but not logged in (only in case of chat pushes)
  }
}

export default {
  state: {
    ...getInitialState()
  },
  mutations: {
    ...mutations
  },
  actions: {
    ...actions
  },
  getters: {
    ...getters
  }
}