export const MUTATION_PREFIX = 'CALENDAR:';
export const ACTIONS_PREFIX = 'CALENDAR_ACTIONS_';
export const GETTERS_PREFIX = 'CALENDAR_GETTERS_';

export default {
  MUTATIONS: {
    SET_SHOWN_DAYS: MUTATION_PREFIX + 'SET_SHOWN_DAYS',
    SET_CALENDAR_DATA: MUTATION_PREFIX + 'SET_CALENDAR_DATA',
    SET_WEEK_CALENDAR_DATA: MUTATION_PREFIX + 'SET_WEEK_CALENDAR_DATA',
    SET_DAY_ELEMENTS: MUTATION_PREFIX + 'SET_DAY_ELEMENTS',
    SET_HOURS_STOP: MUTATION_PREFIX + 'SET_HOURS_STOP',
    SET_HOURS_START: MUTATION_PREFIX + 'SET_HOURS_START',
    SET_SHOWN_HOURS: MUTATION_PREFIX + 'SET_SHOWN_HOURS',
    PUSH_COLUMN_EVENT_ARRAY: MUTATION_PREFIX + 'PUSH_COLUMN_EVENT_ARRAY',
    RENEW_COLUMN_EVENT_ARRAY: MUTATION_PREFIX + 'RENEW_COLUMN_EVENT_ARRAY',
    RENEW_CALENDAR_DATA: MUTATION_PREFIX + 'RENEW_CALENDAR_DATA',
    SET_HOURS_CONTENT: MUTATION_PREFIX + 'SET_HOURS_CONTENT',
    RESET_HOURS_CONTENT: MUTATION_PREFIX + 'RESET_HOURS_CONTENT',
    SET_WEEKS_CONTENT: MUTATION_PREFIX + 'SET_WEEKS_CONTENT',
    SET_SELECTED_APPOINTMENT_DATA: MUTATION_PREFIX + 'SET_SELECTED_APPOINTMENT_DATA',
    SET_CLICKED_ON_EVENT: MUTATION_PREFIX + 'SET_CLICKED_ON_EVENT',
    SET_APPOINTMENT_COMBOS: MUTATION_PREFIX + 'SET_APPOINTMENT_COMBOS',
    SET_APPOINTMENT_LABEL: MUTATION_PREFIX + 'SET_APPOINTMENT_LABEL',
    SET_APPOINTMENT_PLACE: MUTATION_PREFIX + 'SET_APPOINTMENT_PLACE',
    SET_APPOINTMENT_TEXT: MUTATION_PREFIX + 'SET_APPOINTMENT_TEXT',
    SET_APPOINTMENT_FULL_DAY: MUTATION_PREFIX + 'SET_APPOINTMENT_FULL_DAY',
    SET_APPOINTMENT_RETRY: MUTATION_PREFIX + 'SET_APPOINTMENT_RETRY',
    SET_APPOINTMENT_RETRY_COUNT: MUTATION_PREFIX + 'SET_APPOINTMENT_RETRY_COUNT',
    SET_APPOINTMENT_RETRY_MAX_DATE: MUTATION_PREFIX + 'SET_APPOINTMENT_RETRY_MAX_DATE',
    SET_APPOINTMENT_BEGIN_DATE: MUTATION_PREFIX + 'SET_APPOINTMENT_BEGIN_DATE',
    SET_APPOINTMENT_BEGIN_TIME: MUTATION_PREFIX + 'SET_APPOINTMENT_BEGIN_TIME',
    SET_APPOINTMENT_END_DATE: MUTATION_PREFIX + 'SET_APPOINTMENT_END_DATE',
    SET_APPOINTMENT_END_TIME: MUTATION_PREFIX + 'SET_APPOINTMENT_END_TIME',
    SET_APPOINTMENT_BEGIN: MUTATION_PREFIX + 'SET_APPOINTMENT_BEGIN',
    SET_APPOINTMENT_END: MUTATION_PREFIX + 'SET_APPOINTMENT_END',
    SET_APPOINTMENT_DURATION: MUTATION_PREFIX + 'SET_APPOINTMENT_DURATION',
    SET_APPOINTMENT_EMAIL: MUTATION_PREFIX + 'SET_APPOINTMENT_EMAIL',
    SET_APPOINTMENT_POPUP: MUTATION_PREFIX + 'SET_APPOINTMENT_POPUP',
    SET_APPOINTMENT: MUTATION_PREFIX + 'SET_APPOINTMENT',
    SET_NEW_AUFGABE: MUTATION_PREFIX + 'SET_NEW_AUFGABE',
    SET_NEW_ACTIVITY_AUFGABE: MUTATION_PREFIX + 'SET_NEW_ACTIVITY_AUFGABE',
    SET_NEW_ACTIVITY: MUTATION_PREFIX + 'SET_NEW_ACTIVITY',
    SET_NEW_TERMIN: MUTATION_PREFIX + 'SET_NEW_TERMIN',
    UPDATE_BETEILIGTE_ROLLE: MUTATION_PREFIX + 'UPDATE_BETEILIGTE_ROLLE',
    UPDATE_BETEILIGTE: MUTATION_PREFIX + 'UPDATE_BETEILIGTE',
    ADD_BETEILIGTE_FOR_UPDATE: MUTATION_PREFIX + 'ADD_BETEILIGTE_FOR_UPDATE',
    DELETE_BETEILIGTE_SUCCESS: MUTATION_PREFIX + 'DELETE_BETEILIGTE_SUCCESS',
    DELETE_BETEILIGTE_BY_VALUE: MUTATION_PREFIX + 'DELETE_BETEILIGTE_BY_VALUE',
    SET_BETEILIGTE_FOR_UPDATE: MUTATION_PREFIX + 'SET_BETEILIGTE_FOR_UPDATE',
    GET_APPOINTMENT_SETUP: MUTATION_PREFIX + 'GET_APPOINTMENT_SETUP',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    GET_CUSTOMER_APPOINTMENTS: MUTATION_PREFIX + 'GET_CUSTOMER_APPOINTMENTS',
    PRESAVE_APPOINTMET: MUTATION_PREFIX + 'PRESAVE_APPOINTMET',
    SET_ATTACHMENT: MUTATION_PREFIX + 'SET_ATTACHMENT',
    SET_DATES: MUTATION_PREFIX + 'SET_DATES',
    LOAD_APPOINTMENT: MUTATION_PREFIX + 'LOAD_APPOINTMENT',
    SET_APPOINTMENT_CONFIG: MUTATION_PREFIX + 'SET_APPOINTMENT_CONFIG',
  },

  ACTIONS: {
    RETRIEVE_CALENDAR_DATA: ACTIONS_PREFIX + 'RETRIEVE_CALENDAR_DATA',
    CREATE_WEEK_CALENDAR_DATA: ACTIONS_PREFIX + 'CREATE_WEEK_CALENDAR_DATA',
    RETRIEVE_SELECTED_APPOINTMENT: ACTIONS_PREFIX + 'RETRIEVE_SELECTED_APPOINTMENT',
    RETRIEVE_APPOINTMENT_COMBOS: ACTIONS_PREFIX + 'RETRIEVE_APPOINTMENT_COMBOS',
    SAVE_APPOINTMENT: ACTIONS_PREFIX + 'SAVE_APPOINTMENT',
    DELETE_APPOINTMENT: ACTIONS_PREFIX + 'DELETE_APPOINTMENT',
    SET_BETEILIGTE: ACTIONS_PREFIX + 'SET_BETEILIGTE',
    DELETE_BETEILIGTE: ACTIONS_PREFIX + 'DELETE_BETEILIGTE',
    GET_APPOINTMENT_SETUP: ACTIONS_PREFIX + 'GET_APPOINTMENT_SETUP',
    GET_CUSTOMER_APPOINTMENTS: ACTIONS_PREFIX + 'GET_CUSTOMER_APPOINTMENTS',
    GET_ATTACHMENT: ACTIONS_PREFIX + 'GET_ATTACHMENT',
    SET_ATTACHMENT: ACTIONS_PREFIX + 'SET_ATTACHMENT',
    DELETE_ATTACHMENT: ACTIONS_PREFIX + 'DELETE_ATTACHMENT',
    UPDATE_BETEILIGTE: ACTIONS_PREFIX + 'UPDATE_BETEILIGTE',
    GET_EMPFAENGER: ACTIONS_PREFIX + 'GET_EMPFAENGER',
  },

  GETTERS: {
    GET_SHOWN_DAYS: GETTERS_PREFIX + 'GET_SHOWN_DAYS',
    GET_SHOWN_HOURS: GETTERS_PREFIX + 'GET_SHOWN_HOURS',
    GET_CALENDAR_DATA: GETTERS_PREFIX + 'GET_CALENDAR_DATA',
    GET_WEEK_CALENDAR_DATA: GETTERS_PREFIX + 'GET_WEEK_CALENDAR_DATA',
    GET_CALENDAR_DATA_READY: GETTERS_PREFIX + 'GET_CALENDAR_DATA_READY',
    GET_DAY_ELEMENTS: GETTERS_PREFIX + 'GET_DAY_ELEMENTS',
    GET_HOURS_START: GETTERS_PREFIX + 'GET_HOURS_START',
    GET_HOURS_STOP: GETTERS_PREFIX + 'GET_HOURS_STOP',
    GET_COLUMN_EVENT_ARRAY: GETTERS_PREFIX + 'GET_COLUMN_EVENT_ARRAY',

    GET_HOURS_CONTENT: GETTERS_PREFIX + 'GET_HOURS_CONTENT',
    GET_WEEKS_CONTENT: GETTERS_PREFIX + 'GET_WEEKS_CONTENT',
    GET_SELECTED_APPOINTMENT_DATA: GETTERS_PREFIX + 'GET_SELECTED_APPOINTMENT_DATA',
    GET_CLICKED_ON_EVENT: GETTERS_PREFIX + 'GET_CLICKED_ON_EVENT',
    GET_APPOINTMENT_COMBOS: GETTERS_PREFIX + 'GET_APPOINTMENT_COMBOS',
    GET_APPOINTMENT: GETTERS_PREFIX + 'GET_APPOINTMENT',
    GET_APPOINTMENT_BEGIN_DATE: GETTERS_PREFIX + 'GET_APPOINTMENT_BEGIN_DATE',
    GET_APPOINTMENT_BEGIN_TIME: GETTERS_PREFIX + 'GET_APPOINTMENT_BEGIN_TIME',
    GET_APPOINTMENT_END_TIME: GETTERS_PREFIX + 'GET_APPOINTMENT_END_TIME',
    GET_APPOINTMENT_END_DATE: GETTERS_PREFIX + 'GET_APPOINTMENT_END_DATE',
    GET_APPOINTMENT_DURATION: GETTERS_PREFIX + 'GET_APPOINTMENT_DURATION',
    GET_CUSTOMERS: GETTERS_PREFIX + 'GET_CUSTOMERS',
    GET_SELECTED_CUSTOMERS: GETTERS_PREFIX + 'GET_SELECTED_CUSTOMERS',
    GET_BETEILIGTE: GETTERS_PREFIX + 'GET_BETEILIGTE',
    GET_EMPTY_APPOINTMENT: GETTERS_PREFIX + 'GET_EMPTY_APPOINTMENT',
    GET_APPOINTMENT_SETUP: GETTERS_PREFIX + 'GET_APPOINTMENT_SETUP',
    GET_CUSTOMER_APPOINTMENTS: GETTERS_PREFIX + 'GET_CUSTOMER_APPOINTMENTS',
    PRESAVE_APPOINTMET: GETTERS_PREFIX + 'PRESAVE_APPOINTMET',
    GET_BETEILIGTE_FOR_UPDATE: GETTERS_PREFIX + 'GET_BETEILIGTE_FOR_UPDATE',
    GET_ATTACHMENT: GETTERS_PREFIX + 'GET_ATTACHMENT',
    LOAD_APPOINTMENT: GETTERS_PREFIX + 'LOAD_APPOINTMENT',
    GET_APPOINTMENT_CONFIG: MUTATION_PREFIX + 'GET_APPOINTMENT_CONFIG',
  }
}