var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.label && _vm.config && _vm.config.html
    ? _c(
        "div",
        {
          staticClass: "input-forms__label-content",
          class: _vm.classes,
          domProps: { innerHTML: _vm._s(_vm.sanitize(_vm.label)) }
        },
        [_vm._v(" " + _vm._s(_vm.label) + " ")]
      )
    : _vm.label
    ? _c(
        "div",
        { staticClass: "input-forms__label-content", class: _vm.classes },
        [_vm._v(" " + _vm._s(_vm.label) + " ")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }