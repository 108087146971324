var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Einschaten/Auschalten neue Chatsabfrage"
        }
      }),
      _c(
        "BoxContainer",
        [
          _c("InputToggleSwitch", {
            attrs: {
              value: _vm.RESULT,
              label: "Einschaten/Auschalten neue Chatsabfrage ",
              inLineLabel: ""
            },
            on: {
              change: function($event) {
                return _vm.onChange($event)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }