<template>
  <div class="antrag-components__container">
    <div class="box__container">

      <InputToggleSwitch v-model="form.all" label="Übertragung des gesamten Depots"
        @input="$emit('save', { all: $event })"/>

      <WertpapierauswahlDepotUebertragung
        :hasGesamtbestand="false"
        :positions="positionData"
        :anzahlFonds="anzahlFonds"
        @save="$emit('save', $event)"
        @fondsfinder="$emit('fondsfinder', $event)">
      </WertpapierauswahlDepotUebertragung>
      <FormDivider/>

      <InputToggleSwitch v-model="form.kontoBeenden" label="mein/e Konto/Konten abzuschließen und aufzulösen."
        @input="$emit('save', { kontoBeenden: $event })"/>
      <InputToggleSwitch v-model="form.sparplanBeenden" label="mein(e)/unser(e) Depot/Depots abzuschließen und aufzulösen."
        @input="$emit('save', { sparplanBeenden: $event })"/>
      <InputToggleSwitch v-model="form.kontoGuthabenEmpfaenger" label="vorhandene Guthaben auf folgendes Konto überweisen:"
        @input="$emit('save', { kontoGuthabenEmpfaenger: $event })"/>
      <InputField v-model="form.nameVornameGuthabenEmpfaenger" label="Vorname, Name" v-if="form.kontoGuthabenEmpfaenger"
        @input="$emit('save', { nameVornameGuthabenEmpfaenger: $event })"/>
      <InputIbanBic v-model="form.ibanGuthabenEmpfaenger" label="IBAN" type="IBAN" 
        v-if="form.kontoGuthabenEmpfaenger" :componentData="form.ibanGuthabenEmpfaenger"
        validation_path="ibanGuthabenEmpfaenger"
        @change="$emit('save', { ibanGuthabenEmpfaenger: $event })"/>
      <InputIbanBic v-model="form.bicGuthabenEmpfaenger" label="BIC" type="BIC" 
        v-if="form.kontoGuthabenEmpfaenger" :componentData="form.bicGuthabenEmpfaenger"
        validation_path="bicGuthabenEmpfaenger"
        @change="$emit('save', { bicGuthabenEmpfaenger: $event })"/>

      <FormDivider/>

      <BaseButton class="col-12 mr-2" :isSecondary="true" @click="$emit('back')">Zurück</BaseButton>
      <BaseButton class = mr-2 @click="$emit('next')">Weiter</BaseButton>
    </div>
  </div>
</template>
<script>

import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import InputField from '@/components/core/forms/InputField.vue';
import BaseButton from '@/components/core/BaseButton.vue';
import WertpapierauswahlDepotUebertragung from '@/components/core/forms/antraege/wertpapierAuswahl/WertpapierauswahlDepotUebertragung.vue';
import FormDivider from '@/components/core/forms/FormDivider.vue';
import InputIbanBic from '@/components/core/forms/InputIBAN_BIC.vue';

export default {
  validators: {
  },
  components: {
    InputToggleSwitch,
    InputField,
    BaseButton,
    FormDivider,
    WertpapierauswahlDepotUebertragung,
    InputIbanBic,
  },
  props: {
    positionData: {
    },
    bankverbindungSelection: {
    },
    anzahlFonds: {
      type: Number,
      default: 4,
    },
    warnings: {
    },
  },
  data() {
    return {
      form: {},
    }
  },
  mounted() {
    this.form = Object.assign({}, this.positionData);
  },
  methods: {
  },
}
</script>
<style scoped>

</style>