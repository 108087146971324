export const MUTATION_PREFIX = 'MARKTBERICHTUPLOADEN_';
export const ACTIONS_PREFIX = 'MARKTBERICHTUPLOADEN_ACTIONS_';
export const GETTERS_PREFIX = 'MARKTBERICHTUPLOADEN_GETTERS_';

export default {
  MUTATIONS: {
    FIELDS_DEFINITION_SUCCESS: MUTATION_PREFIX + 'FIELDS_DEFINITION_SUCCESS',
    UPLOAD_DOCUMENT_SUCCESS: MUTATION_PREFIX + 'UPLOAD_DOCUMENT_SUCCESS',
    FIND_DOCUMENTS_SUCCESS: MUTATION_PREFIX + 'FIND_DOCUMENTS_SUCCESS',
  },
  ACTIONS: {
    GET_FIELDS_DEFINITION: ACTIONS_PREFIX + 'GET_FIELDS_DEFINITION',
    DOWNLOAD_DOCUMENT: ACTIONS_PREFIX + 'DOWNLOAD_DOCUMENT',
    DELETE_DOCUMENT: ACTIONS_PREFIX + 'DELETE_DOCUMENT',
    UPLOAD_DOCUMENT: ACTIONS_PREFIX + 'UPLOAD_DOCUMENT',
    FIND_DOCUMENTS: ACTIONS_PREFIX + 'FIND_DOCUMENTS',
  },
  GETTERS: {
    FIELDS_DEFINITION: GETTERS_PREFIX + 'FIELDS_DEFINITION',
    FIND_DOCUMENTS: GETTERS_PREFIX + 'FIND_DOCUMENTS',
  }
}
