<template>
  <div class="input-forms__container">
    <label class="input-forms__label-container">
      <div class="input-forms__label-content" v-if="label">{{label}}</div>
      <div class="input-forms__input-container">
        <!-- The component with type {{componentType}} is not yet implemented -->
      </div>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    componentType: {
      type: String
    },
    label: {
    }
  }
}
</script>

<style scoped>

</style>