var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.customerDataEdited
    ? _c(
        "div",
        [
          _vm.rows && _vm.rows.length
            ? _c("FlexibleList", {
                attrs: { rows: _vm.rows, pageSize: _vm.isCustomerOnly ? 0 : 5 },
                on: { onNavigate: _vm.navigateToCard },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function(row) {
                        return [_vm._v(" " + _vm._s(row.title) + " ")]
                      }
                    },
                    {
                      key: "value",
                      fn: function(row) {
                        return [_vm._v(" " + _vm._s(row.value) + " ")]
                      }
                    },
                    {
                      key: "thirdRow",
                      fn: function(row) {
                        return [_vm._v(" " + _vm._s(row.description) + " ")]
                      }
                    }
                  ],
                  null,
                  false,
                  3074737917
                )
              })
            : _c("NoData", { attrs: { noIcon: "" } })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }