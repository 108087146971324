<template>
<div>
  <PageHeaderTitleNavigation
    :title="$appNavigation.currentMenu.label"
    :subtitle="form.name"
  />

  <div>

    <HinweiseUndFehler :errors="warnings" :hints="[]" 
      @set-step="navigateToPath($event)" @set-substep="navigateToSubstepPath($event)" />

    <StepperForm
      stepType="tab"
      stepperName="retirement-plan"
      :stepperMediator="tabsStepperMediator"
      :hasFertig="false"
      :selectedStepKey="selectedStepKey"
      @next-step="nextStep"
      @previous-step="previousStep"
      @set-step="setStepByKey"
    >
      <template #contentTemplate>
        <div :class="{'hidden': selectedStepKey !== 'settings'}">
          <div class="box__container">
            <div class="row">
              <div class="col col-md-6">
                <div class="row">
                  <div class="col col-md-6">
                    <InputField label="Name" v-model="form.name" type="text"></InputField>
                  </div>
                </div>
                <div class="row">
                  <div class="col col-md-6">
                    <InputRadioBoxGroup 
                      title="Betrachtungszeitraum"
                      v-model="form.period"
                      :values="getComboValues(periodTypes)"
                      @input="retirementUpdate" />
                  </div>
                </div>
                <!-- <div class="row">
                  <div class="col col-md-6">
                    <InputField label="Zinssatz Ansparphase" v-model="form.interestBeforeRetirement" type="percent" @input="retirementUpdate"></InputField>
                  </div>
                </div> -->
                <div class="row">
                  <div class="col col-md-6">
                    <InputField label="Zinssatz Rentenphase" v-model="form.interestAfterRetirement" type="percent" @input="retirementUpdate"></InputField>
                  </div>
                </div>
                <div class="row">
                  <div class="col col-md-6">
                    <InputField label="Inflation" v-model="form.inflation" type="percent" @input="retirementUpdate"></InputField>
                  </div>
                </div>
                <div class="row">
                  <div class="col col-md-6">
                    <label class="input-forms__label-content">Geburtsdatum (TT.MM.JJJJ)</label>
                    <div>
                      <DatePickerField
                        v-model="form.dateOfBirth"
                        @input="retirementUpdate"
                        validateUntouched
                      ></DatePickerField>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col col-md-6">
                <InputTextArea
                  label="Notizen"
                  v-model="form.notizen">
                </InputTextArea>
              </div>
            </div>
          </div>
          <div class="box__container">
            <div class="row pl-3">
              <label class="input-forms__label-content">Gesamtvermögen</label>
              <CurrencyLabel :value="form.presentValue"/>
            </div>

            

            <div class="row">
              <div class="col sm-table">
                <label class="input-forms__label-content">Vermögen</label>
                <Table
                  v-if="tableDataBalanceAssets && tableDataBalanceAssets.records"
                  tableId="f0a4b0c9-c682-4e6f-8f42-9ac420b54da1"
                  :headers="headerTableDataBalance"
                  :rows="tableDataBalanceAssets.records"
                  rowId="index"
                  hidePagination
                  @selected="onSelectedAssetsRowsAdapted"
                  :selected="selectedBalanceAssetsAdapted">
                  <template v-slot:interest="row">
                    <InputField
                      type="percent"
                      v-model="assetsInterest[row.name.toLowerCase()]"
                      @change="changeInterest(row.name, $event, +1)"/>
                  </template>
                  <template v-slot:original="row">
                    <InputField
                      v-if="row.isAdjustable"
                      type="currency"
                      v-model="assetsIndividual"
                      @input="changeOriginalValues"
                    />
                    <span v-else>
                      <CurrencyLabel :value="row.original"/>
                    </span>
                  </template>
                </Table>
                <GhostLoading v-else type="table" />
              </div>
            </div>
            <div class="row">
              <div class="col sm-table">
                <label class="input-forms__label-content">Verbindlichkeiten</label>

                <Table
                  v-if="tableDataBalanceLiabilities && tableDataBalanceLiabilities.records"
                  tableId="ce7e545e-a241-45ea-9b78-9c698bc36088"
                  :headers="headerTableDataBalance"
                  :rows="tableDataBalanceLiabilities.records"
                  rowId="index"
                  hidePagination
                  @selected="onSelectedLiabilitiesRowsAdapted"
                  :selected="selectedBalanceLiabilitiesAdapted">
                  <template v-slot:interest="row">
                    <InputField
                      type="percent"
                      v-model="liabilitiesInterest[row.name.toLowerCase()]"
                      @change="changeInterest(row.name, $event, -1)"/>
                  </template>
                  <template v-slot:original="row">
                    <InputField
                      v-if="row.isAdjustable"
                      type="currency"
                      v-model="liabilitiesIndividual"
                      @input="changeOriginalValues"
                    />
                    <span v-else>
                      <CurrencyLabel :value="row.original"/>
                    </span>
                  </template>
                </Table>
                <GhostLoading v-else type="table" />
              </div>
            </div>
          </div>
        </div>

        <div :class="{'hidden': selectedStepKey !== 'preview'}">
          <div class="box__container">
            <div class="row">
              <div class="col" v-if="retirementYearSet">
                <InputField
                  label="Ruhestand ab"
                  placeholder="Ruhestand ab"
                  v-model="form.retirementAge"
                  type="number"
                  @input="retirementUpdate"
                ></InputField>
              </div>
              <div class="col">
                <InputField label="Vermögen am Ende der Rentenphase" v-model="form.moneyNeededAtTheEnd" type="currency" @input="retirementUpdate"></InputField>
              </div>
              <div class="col">
                <InputField
                  label="Vermögen heute"
                  placeholder="Vermögen heute"
                  v-model="form.presentValue"
                  type="currency"
                  :disabled="true"
                ></InputField>
              </div>
            </div>
          </div>

          <div class="box__container">
            <div class="row">
              <div class="col">
                <div class="mt-3 mb-3">
                  <template v-if="isLoading">
                    <AnimatedSpinner />
                  </template>
                  <div :class="{'hidden-chart': isLoading || !allBalance || !allGoals}">
                    <RetirementChart
                      :data="{
                        data: apexSeries,
                        series: apexSeries,
                        xaxis: apexXaxis,
                        annotations: apexAnnotations,
                        markers: apexMarkers,
                        dataLabels: {enabled: false},
                        stroke: {curve: 'smooth'},
                        autoUpdateSeries: true,
                      }"
                    />
                    <InputToggleSwitch
                      v-model="showOriginalWert"
                      label="Aktueller Wert/Urspürnglicher Wert"
                      inLineLabel
                      @input="retirementUpdate"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="box__container">
            <div>
              <div class="layout__negative-margin--8 pb-3">
                <BaseButton isSecondary @click="addNewGoal('addGoalTemplate')">
                  Ausgabe hinzufügen
                </BaseButton>
                <BaseButton isSecondary @click="addNewRevenue('addGoalTemplate')">
                  Einnahme hinzufügen
                </BaseButton>
              </div>
              <Table
                v-if="isLoaded && tableData.records.length"
                tableId="63a11a79-1c5d-42e7-912b-3381c5bb077e"
                :headers="headerTableData"
                :rows="tableDataRows"
                rowId="index"
                hidePagination
                @action-EDIT="editAction($event)"
                @action-DELETE="deleteAction($event)"
                @selected="onSelectedRowsAdapted"
                :selected="selectedRowsAdapted">
                <template #type="row">
                  <div class="type-col">
                    <PhCircle v-if="!isSpecialEventType(row.type)" :size="16" weight="fill" :color="row.color" />
                    <div :class="{'badge': true, 'badge-red': !row.isIncome, 'badge-green': row.isIncome}">
                      {{row.typeName || row.type}}
                    </div>
                  </div>
                </template>
                <template #startDate="row">
                  <template v-if="row.startDate">
                    <span >{{ row.startDate | date }}</span>
                  </template>
                </template>
                <template #endDate="row">
                  <template v-if="row.endDate">
                    <span v-if="row.endDate">{{ row.endDate | date }}</span>
                    <span v-if="row.period"> ({{row.period}})</span>
                  </template>
                </template>
              </Table>
            </div>
          </div>
        </div>

        <div :class="{'hidden': selectedStepKey !== 'view'}">
          <div class="box__container">
            <div class="row">
              <div class="col">
                <div class="mt-3 mb-3">
                  <template v-if="isLoading">
                    <AnimatedSpinner />
                  </template>
                  <div :class="{'hidden-chart': isLoading || !allBalance || !allGoals}">
                    <RetirementChart
                      :data="{
                        data: apexSeries,
                        series: apexSeries,
                        xaxis: apexXaxis,
                        annotations: apexAnnotations,
                        markers: apexMarkers,
                        dataLabels: {enabled: false},
                        stroke: {curve: 'smooth'},
                        autoUpdateSeries: true,
                      }"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="box__container" v-if="!isLoading && moneyNeededForRetirement !== 0">
            <div class="row">
              <div class="col-12">
                Zusätzlich benötigtes Vermögen bis zum Ruhestand:
                <span>{{moneyNeededForRetirement | currency}}</span>
              </div>
              <div class="col-12">
                Das bedeutet ein Investment in Höhe von
                <span>{{moneyNeededForRetirementAdditionalStartDate | currency}}</span> heute, oder eine monatliche Sparrate von
                <span>{{moneyNeededForRetirementAdditionalMonthly | currency}}</span>
              </div>
              <div class="col-12 pt-2" v-if="!isBrokerOrBypass">
                <!-- <router-link :to="{name: 'ticket', params: {textInput: brokerText, richText: true}}">
                  <BaseButton>Nachricht an Betreuer</BaseButton>
                </router-link> -->
                <BaseButton isPrimary @click="$router.push({ name: 'new-ticket', params: { textInput: '' }})">Nachricht senden</BaseButton>
              </div>
            </div>
          </div>
        </div>

      </template>
    </StepperForm>

  </div>

  <!-- Modals-->
  <!-- addGoalTemplate -->
  <BaseModal
    :modalTitle="currentRowType === 'goal' ? 'AUSGABE ERFASSEN' : 'EINNAHMEN ERFASSEN'"
    ref="addGoalTemplate"
    :showDefaultButtons="false"
  >
    <EditForm
      :currentRowType="currentRowType"
      :currentRowIndex="currentRowIndex"
      :transactionsArray="transactionsArray"
      :emptyItem="createZiele({})"
      @close="$refs.addGoalTemplate.close()"
      @handleOk="saveEvent"
    />
  </BaseModal>

  <!-- configBalance -->
  <BaseModal
    ref="configBalance"
    :showDefaultButtons="false"
    modalTitle="">
      <BalanceSheetView @setBalance="setBalance" @init="setBalance" :isSelectableView="true" :selectedItems="selectedBalance"  @selectedItems="onbalanceselectedItems"  />
  </BaseModal>
  <div class="hidden">
    <BalanceSheetView @setBalanceItems="setBalanceItems" @setBalance="setBalance" @init="setBalance" :isSelectableView="true" :selectedItems="selectedBalance"  @selectedItems="onbalanceselectedItems" @balanceSum="setAllBalance" />
  </div>
  
  
  <!-- noDoB -->
  <BaseModal
    ref="noDoB"
    :showDefaultButtons="false"
    modalTitle="Kein Geburtsdatum">
      <div>
          Bitte geben Sie Ihr Geburtsdatum in die persönlichen Daten ein
      </div>
  </BaseModal>
  
  <!-- errorMessage -->
  <BaseModal
    ref="errorMessage"
    modalTitle="Bei der Aktualisierung Ihrer Daten gab es ein Problem. Bitte laden Sie die Seite neu!">
  </BaseModal>
</div>
</template>

<script>
import BaseButton from '@/components/core/BaseButton.vue';
import InputField from "@/components/core/forms/InputField.vue";
import { mapGetters } from 'vuex';
import RETIREMENT_SCENARIO_TYPES from '@/store/retirementScenario/types';
import MY_GOALS_TYPES from '@/store/myGoals/types';
import Table from '@/components/table2/Table.vue';
import CORE_TYPES from '@/store/core/types';
import { SPECIAL_TYPE_GOAL_COST_OF_LIVING_AFTER_RETIREMENT, SPECIAL_TYPE_REVENUE_PENSION_INCOME, SPECIAL_TYPE_REVENUE_BEFORE_RETIREMENT_INCOME, SPECIAL_TYPE_REVENUE_PRESENT_VALUE_MANUAL } from './types';
import { filterSpecialGoals, filterSpecialRevenues, monthsDiff } from './Utils';
import {periodTypes} from './types';
import RetirementChart, { chartColorScheme } from './RetirementChart.vue';
import EditForm from './EditForm.vue';
import FinancialCalculator, { manLifeYears, womanLifeYears } from './financialCalculator.js';
import {DatePickerUtils} from '@/components/core/forms/DatePicker/date-picker-utils.js';
import validator from '@/mixins/validator';
import {
  required
} from '@/mixins/validator/rules';
import DatePickerField from '@/components/core/forms/date-picker2/DatePickerField.vue';
import dayjs from 'dayjs';
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';
import LOG_TYPES from '@/store/log/types';
import {
  PhCircle,
  PhPencilLine,
  PhTrash,
} from 'phosphor-vue';
import BaseModal from '@/components/core/BaseModal.vue';
import BalanceSheetView from '@/components/balance-sheet/BalanceSheetView.vue';
import StepperForm from '@/components/stepper/StepperForm.vue';
import { StepperMediator } from '@/components/stepper/utils';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import CurrencyLabel from '@/components/core/CurrencyLabel.vue';
import HinweiseUndFehler from '@/components/core/HinweiseUndFehler.vue'
import InputTextArea from "@/components/core/forms/InputTextArea.vue";
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import {calculateChart, calculateDataPoints } from './retirementChartCalculations.js'
import {TextColumn, ActionColumn, SlotColumn, CurrencyColumn, SimpleAction} from "@/components/table2/table_util.js";
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue'

export default {
  components: {
    Table,
    BaseButton,
    BaseModal,
    RetirementChart,
    EditForm,
    InputField,
    DatePickerField,
    AnimatedSpinner,
    PhCircle,
    BalanceSheetView,
    StepperForm,
    InputRadioBoxGroup,
    GhostLoading,
    CurrencyLabel,
    HinweiseUndFehler,
    InputTextArea,
    InputToggleSwitch,
    OptionMenu,
    PageHeaderTitleNavigation,
  },
  props: {},
  mixins: [validator],
  validators: {
    form: {
      dateOfBirth: [required()],
    }
  },
  data() {
    return {
      isSettings: false,
      interest: 2,
      currentRowIndex: 0,
      years: [],
      yearsRetire: [],
      age: [],
      zieleRowsEditable: [],
      revenueRowsEditable: [],
      fullAmount: 0,
      dataPoints: [],
      dataPointsPerYear: [],
      dataLinesBalance: [],
      dataLineOriginalBalance: [],
      balance: {},

      lifeLength: 70,
      retirementAge: 67,
      retirementYear: new Date().getFullYear() + this.retirementAge,

      now: new Date(),
      currentYear: new Date().getFullYear(),
      gender: 'm',
      maxY: 0,
      minY: 0,
      isLoadingScenario: true,
      isLoaded: false,
      calcAdvancedSettings: false,
      tableDataDetailedExpanded: false,
      formChangesTimeout: undefined,
      formSaveToServerTimeout: undefined,
      formCalculationTimeout: undefined,
      tableDataDetailed: {
        tableHeaders: [
          { key: 'date', label: 'Datum', dataType: 'String', sortable: false },
          { key: 'amount', label: 'Wert', dataType: 'Currency', sortable: false },
        ],
        headersMeta: [
          { key: 'date', priority: 1, tdStyle: {'width': '200px' } },
          { key: 'amount', priority: 1, tdStyle: {'width': '200px' } },
        ],
      },
      tableDataBalance: {
        headers: {
          name: {
            label: '',
            key: 'name',
            dataType: 'String',
            visible: true,
            sortable: true,
            width: '300px',
          },
          interest: {
            label: 'Zinssatz Ansparphase',
            key: 'interest',
            dataType: 'Slot',
            visible: true,
            sortable: false,
            width: '100px',
          },
          number: {
            label: 'Aktueller Wert',
            key: 'number',
            dataType: 'Currency',
            visible: true,
            sortable: false,
          },
          original: {
            label: 'Urspürnglicher Wert',
            key: 'original',
            dataType: 'Slot',
            visible: true,
            sortable: true,
          },
        },
      },
      tableDataBalanceAssets: null,
      tableDataBalanceLiabilities: null,
      selectedAssetsRows: {},
      selectedLiabilitiesRows: {},
      tableDataBalanceLocked: {
        toLeft: [{key: 'name'}],
        toRight: [{key: 'number'}],
      },
      tableDataDetailedRecords: null,
      prevRetirementScenario: null,
      prevRetirementScenarioLoaded: false,
      allGoals: null,
      expandedWert: false,
      expandedRight: {},
      expandedLeft: {},
      isCalcBalance: true,
      selectedGoals: [],
      selectedIncomes: [],
      selectedTransactions: [],
      selectedTransactionsSpecial: [],
      id: '',
      beingDestroyed: false,
      retirementYearSet: false,

      moneyNeededForRetirement: 0,
      moneyNeededForRetirementAdditionalStartDate: 0,
      moneyNeededForRetirementAdditionalMonthly: 0,

      form: {
        moneyNeededAtTheEnd: 0,

        presentValue: 0,
        interestBeforeRetirement: 2,
        interestAfterRetirement: 2,

        transactionsArray: [],

        period: 'pro Monat',
        name: '',
        dateOfBirth: '',
        retirementAge: 67,
        notizen: '',
        inflation: 0,
      },

      transactionsArray: [],

      periodTypes: periodTypes,

      showModal: {},
      manLifeYears: manLifeYears,
      selectedBalance: {},
      allBalance: [],

      apexXaxis: {},
      apexAnnotations: {},
      apexMarkers: {},
      apexSeries: [{
        data: [],
      }],
      currentRowType: '',

      tableData: {
        headers: {
          type: {
            label: 'Typ',
            key: 'type',
            dataType: 'Slot',
            visible: true,
            sortable: true,
          },
          title: {
            label: 'Bezeichnung',
            key: 'title',
            dataType: 'String',
            visible: true,
            sortable: true,
          },
          startDate: {
            label: 'Start',
            key: 'startDate',
            dataType: 'Slot',
            visible: true,
            sortable: true,
          },
          endDate: {
            label: 'Ende',
            key: 'endDate',
            dataType: 'Slot',
            visible: true,
            sortable: true,
          },
          qty: {
            label: 'Betrag',
            key: 'qty',
            dataType: 'Currency',
            alwaysVisible: true,
            visible: true,
            sortable: true,
          },
          actions: {
            label: 'Aktionen',
            key: 'actions',
            dataType: 'tableAction',
            visible: true,
            sortable: false,
          },
        },
      },
      selectedRows: {},
      tableDetailLockedColumns: {
        toLeft: [{key: 'datum', width: '200px'}],
      },
      tableGoalslockedColumns: {
        toLeft: [
          {key: 'type'},
          {key: 'title', width: '200px'},
        ],
        toRight: [
          {key: 'qty', width: '100px'},
          {key: 'endDate'},
          {key: 'startDate'},
        ]
      },
      balanceselectedItems: {},

      steps: [
        {
          stepKey: 'settings',
          label: 'Einstellungen',
          totalProgress: 1
        },
        {
          stepKey: 'preview',
          label: 'Szenario',
          totalProgress: 2
        },
        {
          stepKey: 'view',
          label: 'Zusammenfassung',
          totalProgress: 3
        },
      ],
      selectedStepKey: 'settings',
      warnings: [],

      liabilitiesInterest: {},
      assetsInterest: {},
      assetsInterestStored: {},
      liabilitiesOriginalValues: {},
      assetsOriginalValues: {},
      assetsIndividual: 0,
      liabilitiesIndividual: 0,
      showOriginalWert: true,
    }
  },
  computed: {
    ...mapGetters({
      scenarios: RETIREMENT_SCENARIO_TYPES.GETTERS.SCENARIOS,
      currentScenario: RETIREMENT_SCENARIO_TYPES.GETTERS.CURRENT_SCENARIO,
      personalData: RETIREMENT_SCENARIO_TYPES.GETTERS.PERSONAL_DATA,
      goals: MY_GOALS_TYPES.GETTERS.GOALS,
      selectedGoalId: MY_GOALS_TYPES.GETTERS.SELECTED_GOAL_ID,
      isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
    }),
    isLoading() {
      return this.isLoadingScenario || !this.allBalance || !this.allGoals || !this.retirementYearSet;
    },
    tabsStepperMediator() {
      return new StepperMediator(this.steps);
    },
    brokerText() {
      return '';
    },
    tableActions() {
      return [
        {
          legend: {
            icon: 'PhPencilLine',
            index: 1,
            key: 'EDIT',
            label: 'Bearbeiten',
          }
        },
        {
          visible: (row) => this.deleteActionEnabled(row),
          legend: {
            icon: 'PhTrash',
            index: 2,
            key: 'DELETE',
            label: 'Löschen',
          }
        },
      ];
    },
    listEnabledCheckBoxBulkAction() {
      const listEnabledCheckBoxBulkAction = {};
      this.transactionsArray?.map(v => {
        listEnabledCheckBoxBulkAction[v.id] = v.id && !this.isSpecialEventType(v.type);
      })
      return listEnabledCheckBoxBulkAction;
    },

    headerTableDataBalance() {
        return {
            lockedLeft: [
                TextColumn("name", ""),
                SlotColumn("interest", "Zinssatz Ansparphase"),
                SlotColumn("original", "Urspürnglicher Wert"),
                CurrencyColumn("number", "Aktueller Wert"),
            ],
        }
    },

    headerTableData() {
        return {
            lockedLeft: [
                SlotColumn("type", "Typ"),
                TextColumn("title", "Bezeichnung"),
                SlotColumn("startDate", "Start"),
                SlotColumn("endDate", "Ende"),
                CurrencyColumn("qty", "Betrag"),
                ActionColumn("actions", "Aktionen"),
            ],
        }
    },

    selectedBalanceAssetsAdapted() {
      return this.tableDataBalanceAssets?.records?.filter((val, index) => this.selectedBalance?.assets?.[index]) || []
    },

    selectedBalanceLiabilitiesAdapted() {
      return this.tableDataBalanceLiabilities?.records?.filter((val, index) => this.selectedBalance?.loss?.[index]) || []
    },

    selectedRowsAdapted() {
      return this.tableData?.records?.filter((val) => this.selectedTransactions?.includes(val.id)) || []
    },

    tableDataRows() {
      const conditionalActionsRow = (row) => {
        const actions = []
        actions.push(SimpleAction("EDIT", PhPencilLine, "Bearbeiten"));

        if (this.deleteActionEnabled(row)) {
          actions.push(SimpleAction("DELETE", PhTrash, "Löschen"));
        }

        return actions
      }

      return this.tableData.records.map(row => ({
        ...row,
        actions: conditionalActionsRow(row)
      }))
    }
    
  },
  watch: {
    '$route.params.id': function (id) {
      this.id = id;
      if (this.id) {
        this.getSavedScenario();
      } else {
        this.prevRetirementScenarioLoaded = true;
        this.setCalculationNext();
      }
    },
    scenarios(data, oldValue) {
      if (!this.prevRetirementScenario || this.prevRetirementScenario && !this.prevRetirementScenario.retirementYear) {
        this.prevRetirementScenario = data && data.retirementScenarioStandalone || {};
        this.prevRetirementScenarioLoaded = true;
        this.assetsInterestStored = data && data.asserts || {};
        this.presetSavedScenario();
      }
      this.isLoadingScenario = false;
    },
    personalData(customer, oldValue) {
      if (customer && customer.personalDataBirth && customer.personalDataAddress) {
        if (!customer.personalDataBirth.dayOfBirth) {
          this.warnings.push({
            stepKey: 'settings',
            title: 'Kein Geburtsdatum',
            message: 'Bitte geben Sie Ihr Geburtsdatum in die persönlichen Daten ein',
          })
          this.retirementYearSet = true;
        } else {
          this.gender = customer.personalDataAddress.title === 'Frau' ? 'f' : 'm';
          const dob = dayjs(customer.personalDataBirth.dayOfBirth, 'DD.MM.YYYY').toDate();
          this.retirementYear = +dob.getFullYear() + this.retirementAge + 1;
          this.form = {
            ...this.form,
            dateOfBirth: `${customer.personalDataBirth.dayOfBirth}`,
            retirementYear: `${this.retirementYear}`,
            retirementAge: `${this.prevRetirementScenario?.retirementYear - +dob.getFullYear()}`,
          };
          this.retirementYearSet = true;

          if (this.retirementYear < this.currentYear) {
            this.yearsRetire = [];
            for (let i = this.retirementYear;
              i <= (this.currentYear + this.lifeLength - this.getAgeTo(this.currentYear));
              i++) {
              this.yearsRetire.push({
                name: '',
                value: `${i}`,
              });
            }
          }
        }
        this.$forceUpdate();
        this.setCalculationNext();
      }
    },
    goals(myGoals, oldValue) {
      if (myGoals && myGoals.length) {
        this.allGoals = myGoals;
        this.transactionsArray = [];
        this.presetSpecialEvents();

        this.allGoals.map(g => {
          if (g.myGoal) {
            const dateVal1 = g.myGoal.startDate && Object.prototype.toString.call(g.myGoal.startDate) === '[object Date]' ? dayjs(g.myGoal.startDate).format('DD.MM.YYYY') : g.myGoal.startDate.split('.');
            const dateVal2 = this.currentYear + Math.round(g.numberOfPayments / g.paymentPeriod);
            const deathDate = this.currentYear + 90; // this.lifeLength - this.getAgeTo(new Date().getFullYear());
            
            if (dateVal1[2] && dateVal1[2] > deathDate || dateVal2 > deathDate) {
              return;
            }
            const startDateVal = g.myGoal.startDate && Object.prototype.toString.call(g.myGoal.startDate) === '[object Date]' ?
              dayjs(g.myGoal.startDate).format('DD.MM.YYYY') : g.myGoal.startDate.split('.');
            const repeat = !!g.myGoal.endDate && g.myGoal.startDate && g.myGoal.endDate.split('.')[2] !== g.myGoal.startDate.split('.')[2];
            const goalDate = new Date(dateVal1[2], dateVal1[1] - 1, dateVal1[0]);
            const num = Math.round(g.numberOfPayments * 12 / g.paymentPeriod);
            goalDate.setMonth(DatePickerUtils.safeDate(goalDate).getMonth() + num);
            const goalAndRevenueData = {
              isIncome: g.myGoal.isIncome,
              type: this.mapGoalAPIToName(g.myGoal.type, g.myGoal.isIncome),
              repeat: repeat ? 'Wiederholung' : 'Keine Wiederholung',
              age: repeat ?
                `${startDateVal[0]}.${startDateVal[1]}.` + Math.round(+startDateVal[2])
                : dayjs(goalDate).format('DD.MM.YYYY'),
              endDate: g.myGoal.endDate || 0,
              priority: 0,
              amount: +g.futureValue,
              period: g.paymentPeriod === 1 ? 'pro Jahr' : 'pro Monat',
              id: g.id,
              myGoalId: g.myGoal?.id,
              data: g,
              title: g.myGoal.title,
              typeName: `${this.mapGoalAPIToName(g.myGoal.type, g.myGoal.isIncome)}`
            };
            this.transactionsArray.push(this.createZiele(goalAndRevenueData));
          }
        });
        
        this.fillTransactionsTableData();
        
        this.isLoaded = true;//!!this.prevRetirementScenario;
        
        this.isCalcBalance = true;
        this.presetSlectedTableRows();
        this.setCalculationNext();
        this.selectedRows = {...this.selectedRows}
      } else {
        this.isLoaded = true;
        this.allGoals = [];
        this.selectedRows = {};
        this.fillTransactionsTableData();
        this.setCalculationNext();
      }
    },
    selectedGoalId(goalSelectedId, oldValue) {
      // add newly created event to goals or revenues
      if (goalSelectedId) {
        const selectedEvent = this.allGoals.find(g => g.id == goalSelectedId);
        if (selectedEvent) {
          this.selectedTransactions.push(goalSelectedId);
        }
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    this.submit();
    next();
  },
  mounted: function() {
    this.id = this.$route.params.id;
    if (this.id) {
      this.getSavedScenario();
    } else {
      this.isLoadingScenario = false;
      this.prevRetirementScenarioLoaded = true;
      this.setCalculationNext();
    }
    this.$store.dispatch(MY_GOALS_TYPES.ACTIONS.GET_GOALS);
    this.$store.dispatch(RETIREMENT_SCENARIO_TYPES.ACTIONS.GET_PERSONAL_DATA);
    
    this.beingDestroyed = false;

    this.years = [];
    for (let i = 1900; i <= new Date().getFullYear(); i++) {
      this.years.push(`${i}`);
    }
    this.yearsRetire = [];
    for (let i = this.currentYear;
      i <= (this.currentYear + this.lifeLength - this.getAgeTo(this.currentYear));
      i++) {
      this.yearsRetire.push({
        name: '',
        value: `${i}`,
      });
    }
    this.age = [];
    for (let i = 1; i <= 100; i++) {
      this.age.push(i);
    }

    this.setCalculationNext();

    this.setAllBalance({
      assetsCurrencyData: [
        {
          string: 'Wertpapiere',
          number: 0,
          original: 0,
        },
        {
          string: 'Bankkonten',
          number: 0,
          original: 0,
        },
        {
          string: 'Multibanking',
          number: 0,
          original: 0,
        },
        {
          string: 'Versicherungen',
          number: 0,
          original: 0,
        },
        {
          string: 'Beteiligungen',
          number: 0,
          original: 0,
        },
        {
          string: 'Immobilien',
          number: 0,
          original: 0,
        },
        {
          string: 'Weitere',
          number: 0,
          original: 0,
        },
        {
          string: 'Individuell',
          number: 0,
          original: 0,
          isAdjustable: true,
        },
      ],
      liabilityCurrencyData: [
        {
          string: 'Kredite',
          number: 0,
          original: 0,
        },
        {
          string: 'Weitere',
          number: 0,
          original: 0,
        },
        {
          string: 'Multibanking',
          number: 0,
          original: 0,
        },
        {
          string: 'Individuell',
          number: 0,
          original: 0,
          isAdjustable: true,
        },
      ]
    })
  },
  methods: {
    onSelectedRowsAdapted(values) {
      const selectedRowOldTable = this.tableData.records.map((val) => values.some(selectedVal => selectedVal.id === val.id) ? val : false )
      this.onSelectedRows(selectedRowOldTable)
    },
    onSelectedAssetsRowsAdapted(values) {
      const selectedRowOldTable = this.tableDataBalanceAssets.records.map((val, index) => values.some(selectedVal => selectedVal.index === index))
      this.onSelectedAssetsRows(selectedRowOldTable)
    },
    onSelectedLiabilitiesRowsAdapted(values) {
      const selectedRowOldTable = this.tableDataBalanceLiabilities.records.map((val, index) => values.some(selectedVal => selectedVal.index === index))
      this.onSelectedLiabilitiesRows(selectedRowOldTable)
    },
    fillTransactionsTableData() {
      this.tableData = {
        ...this.tableData,
        records: this.transactionsArray.map((goal, i) => ({
          isIncome: goal.isIncome,
          type: goal.type,
          title: goal.title,
          typeName: goal.typeName,
          color: this.getColor(i),
          startDate: !this.isSpecialEventType(goal.type) ? this.getDateObj(goal.age) : '',
          endDate: goal.repeat === 'Wiederholung' ? 
            !this.zieleRowsEditable[i] && goal.endDate && this.getDateObj(goal.endDate) || ''
            : '',
          period: goal.endDate ? goal.period : '',
          qty: goal.amount,
          id: goal.id,
          myGoalId: goal.myGoalId,
          index: i,
        })),
      };
    },
    setStepByKey(stepKey) {
      this.selectedStepKey = stepKey;
    },
    nextStep(params) {
      this.setStepByKey(params.stepKey);
    },
    previousStep(params) {
      this.setStepByKey(params.stepKey);
    },
    log(v) {
      console.log(v)
    },
    retirementUpdate() {
      this.form = {...this.form};
      this.setCalculationNext();
    },
    onbalanceselectedItems(values) {
      this.selectedBalance = values;
      this.balanceselectedItems = values;
    },
    setBalance(val) {
      this.form.presentValue = val;
      if (this.selectedBalance && this.selectedBalance.assets) {
        if (true && Object.values(this.selectedBalance.assets).find(b => b instanceof Object && b.name === 'Individuell')) {
          this.form.presentValue += this.assetsIndividual;
        }
      }
      if (this.selectedBalance && this.selectedBalance.loss) {
        if (true && Object.values(this.selectedBalance.loss).find(b => b instanceof Object && b.name === 'Individuell')) {
          this.form.presentValue -= this.liabilitiesIndividual;
        }
      }
      this.setCalculationNext();
    },
    setBalanceItems(val) {
      this.form.presentValues = val
      if (this.selectedBalance && this.selectedBalance.assets) {
        if (Object.values(this.selectedBalance.assets).find(b => b instanceof Object && b.name === 'Individuell')) {
          this.form.presentValues.assets.push({
            key: 'Individuell',
            value: this.assetsIndividual
          });
        }
        if (Object.values(this.selectedBalance.loss).find(b => b instanceof Object && b.name === 'Individuell')) {
          this.form.presentValues.loss.push({
            key: 'Individuell',
            value: -this.liabilitiesIndividual
          });
        }
      }
    },
    setAllBalance(balance) {
      this.balance = balance;
      if (balance.assetsCurrencyData) {
        this.tableDataBalanceAssets = {
          ...this.tableDataBalance,
          records: balance.assetsCurrencyData.map((v, i) => {
            this.assetsOriginalValues[v.string.toLowerCase()] = this.assetsOriginalValues[v.string.toLowerCase()] || v.number;
            return {
              name: v.string,
              number: v.number,
              interest: this.assetsInterest[v.string.toLowerCase()],
              index: i,
              original: this.assetsOriginalValues[v.string.toLowerCase()] || v.number,
            }
          }).concat([{
            name: 'Individuell',
            number: this.assetsIndividual,
            interest: 0,
            index: balance.assetsCurrencyData.length,
            original: 0,
            isAdjustable: true,
          }])
        }
        if (!this.assetsInterest || !Object.keys(this.assetsInterest).length) {
          balance.assetsCurrencyData.map((v, i) => {
            this.assetsInterest[v.string.toLowerCase()] = 2;
          })
          this.assetsInterest['individuell'] = 2;
        }
      }
      if (balance.liabilityCurrencyData) {
        this.tableDataBalanceLiabilities = {
          ...this.tableDataBalance,
          records: balance.liabilityCurrencyData.map((v, i) => {
            this.liabilitiesOriginalValues[v.string.toLowerCase() + 'liability'] = this.liabilitiesOriginalValues[v.string.toLowerCase() + 'liability'] || v.number;
            return {
              name: v.string,
              number: v.number,
              interest: this.liabilitiesInterest[v.string.toLowerCase()],
              index: i,
              original: this.liabilitiesOriginalValues[v.string.toLowerCase() + 'liability'] || v.number,
            }
          }).concat([{
            name: 'Individuell',
            number: this.liabilitiesIndividual,
            interest: 0,
            index: balance.liabilityCurrencyData.length,
            original: 0,
            isAdjustable: true,
          }])
        }
        if (!this.liabilitiesInterest || !Object.keys(this.liabilitiesInterest).length) {
          balance.liabilityCurrencyData.map((v, i) => {
            this.liabilitiesInterest[v.string.toLowerCase()] = 2;
          })
          this.liabilitiesInterest['individuell'] = 2;
        }
      }
    },
    onSelectedLiabilitiesRows(values) {
        if (this.selectedBalance.loss) {
          this.selectedBalance = {
            ...this.selectedBalance,
            loss: values
          }
        }
      this.balanceselectedItems = this.selectedBalance;
      this.setSelectedBalanceToSavedScenario();
      this.setCalculationNext();
    },
    onSelectedAssetsRows(values) {
      if (this.selectedBalance.assets) {
        this.selectedBalance = {
          ...this.selectedBalance,
          assets: values
        }
      }
      this.balanceselectedItems = this.selectedBalance;
      this.setSelectedBalanceToSavedScenario();
      this.setCalculationNext();
    },
    setSelectedBalanceToSavedScenario() {
      if (this.prevRetirementScenario) {
        this.prevRetirementScenario.selectedBalance = Object.keys(this.selectedBalance).map(key => ({
            key: key,
            selectedBalanceItems: Object.keys(this.selectedBalance[key])
              .filter(k => this.selectedBalance[key][k])
              .map(v => ({itemValue: v}))
          }))
      }
    },
    getComboValues(data) {
      return data.map(v => ({
        value: v,
        label: v
      }))
    },
    getTableData(value) {
      const {records, ...rest} = value
      return {
        headers: {
          datum: {
            label: "Datum",
            key: "datum",
            dataType: "String",
            visible: true,
            sortable: false,
            filterable: false,
            sum: false,
            fixed: true,
            alwaysVisible: true,
          },
          Wert: {
            label: 'Wert',
            key: 'Wert',
            dataType: 'Currency',
            visible: true,
            sortable: false,
            filterable: false,
            alwaysVisible: true,
            sum: false,
            fixed: true,
          },
        },
        records: records && records.map(record => ({
          datum: record[0],
          Wert: record[1]//!isNaN(record[1]) ? `${record[1].toFixed(2)} €` : 0,
        })) || [],
      }
    },

    hideModal(type) {
      this.showModal = {
        ...this.showModal,
        [type]: false
      }
    },
      
    getSavedScenario() {
      // patch saved retirement data if not done before
      this.$store.dispatch(RETIREMENT_SCENARIO_TYPES.ACTIONS.GET_SCENARIOS, this.id);
    },

    presetSavedScenario() {
      if (this.form && this.prevRetirementScenario) {
        const dob = this.getDateFromStrOrDate(this.form['dateOfBirth'])
        this.form = {
          ...this.form,
          retirementYear: `${this.prevRetirementScenario.retirementYear}` || this.form['retirementYear'],
          retirementAge: `${this.prevRetirementScenario.retirementYear - +dob.getFullYear()}`,
          interestBeforeRetirement: this.prevRetirementScenario.interestBeforeRetirement
            || this.form['interestBeforeRetirement'],
          interestAfterRetirement: this.prevRetirementScenario.interestAfterRetirement
            || this.form['interestAfterRetirement'],
          period: this.prevRetirementScenario.period || this.form['period'],
          name: this.prevRetirementScenario.name || this.form['name'],
          moneyNeededAtTheEnd: this.prevRetirementScenario.moneyNeededAtTheEnd || this.form['moneyNeededAtTheEnd'],
          notizen: this.prevRetirementScenario.notizen,
          inflation: this.prevRetirementScenario.inflation || 0,
        };
        this.selectedBalance = {};
        if (this.prevRetirementScenario.selectedBalance) {
          this.prevRetirementScenario.selectedBalance.map(sb => {
            this.selectedBalance[sb.key] = sb.selectedBalanceItems.map(v => ({[v.itemValue]: true})).reduce((acc, curr) => ({...acc, ...curr}), {});
          });
          this.balanceselectedItems = this.selectedBalance;
        }

        this.selectedTransactions = (this.prevRetirementScenario.selectedGoals.map(v => +v.selectedValue) || [])
          .concat(this.prevRetirementScenario.selectedIncomes.map(v => +v.selectedValue) || [])

        this.presetSpecialEvents();
        this.presetSlectedTableRows();
        Object.values(this.assetsInterestStored).map(asset => {
          if (asset.type > 0) {
            this.assetsInterest[asset.name.toLowerCase()] = asset.procent;
            this.assetsOriginalValues[asset.name.toLowerCase()] = asset.value;
          } else {
            this.liabilitiesInterest[asset.name.toLowerCase()] = asset.procent;
            this.liabilitiesOriginalValues[asset.name.toLowerCase()] = asset.value;
          }
        })
        const assetIndividual = Object.values(this.assetsInterestStored).find(asset => asset.name.toLowerCase() === 'individuell' && asset.type == 1)
        this.assetsIndividual = assetIndividual.value;
        const liabilityIndividual = Object.values(this.assetsInterestStored).find(asset => asset.name.toLowerCase() === 'individuell' && asset.type == 0)
        this.liabilitiesIndividual = liabilityIndividual.value;
        this.changeOriginalValues();
      }
    },

    presetSlectedTableRows() {
      this.transactionsArray.map((goal, i) => {
        if (this.selectedTransactions.includes(goal.id) || this.selectedTransactionsSpecial.includes(goal.type+goal.amount)) {
          this.selectedRows[i] = goal;
        } else {
          this.selectedRows[i] = false;
        }
      });
    },

    presetSpecialEvents() {
      if (!this.prevRetirementScenario) {
        return;
      }
      if (this.prevRetirementScenario.pensionIncome) {
        this.prevRetirementScenario.pensionIncome.map(v => {
          const index = this.transactionsArray.length;
          const goal = this.createZiele({
            isIncome: true,
            type: SPECIAL_TYPE_REVENUE_PENSION_INCOME,
            amount: +v.selectedValue,
            id: index,
          })
          this.transactionsArray.push(goal);
          // this.selectedTransactions.push(index);
          this.selectedTransactionsSpecial.push(goal.type+goal.amount);
        });
      }
      if (this.prevRetirementScenario.beforeRetirementIncome) {
        this.prevRetirementScenario.beforeRetirementIncome.map(v => {
          const index = this.transactionsArray.length;
          const goal = this.createZiele({
            isIncome: true,
            type: SPECIAL_TYPE_REVENUE_BEFORE_RETIREMENT_INCOME,
            amount: +v.selectedValue,
            id: index,
          });
          this.transactionsArray.push(goal);
          // this.selectedTransactions.push(index);
          this.selectedTransactionsSpecial.push(goal.type+goal.amount);
        });
      }
      if (this.prevRetirementScenario.presentValue) {
        this.prevRetirementScenario.presentValue.map(v => {
          const index = this.transactionsArray.length;
          const goal = this.createZiele({
            isIncome: true,
            type: SPECIAL_TYPE_REVENUE_PRESENT_VALUE_MANUAL,
            amount: +v.selectedValue,
            id: index,
          });
          this.transactionsArray.push(goal);
          // this.selectedTransactions.push(index);
          this.selectedTransactionsSpecial.push(goal.type+goal.amount);
        });
      }
      if (this.prevRetirementScenario.costOfLivingFromRetirement) {
        this.prevRetirementScenario.costOfLivingFromRetirement.map(v => {
          const index = this.transactionsArray.length;
          const goal = this.createZiele({
            isIncome: false,
            type: SPECIAL_TYPE_GOAL_COST_OF_LIVING_AFTER_RETIREMENT,
            amount: +v.selectedValue,
            id: index,
          });
          this.transactionsArray.push(goal);
          // this.selectedTransactions.push(index);
          this.selectedTransactionsSpecial.push(goal.type+goal.amount);
        });
      }
    },

    isSpecialEventType(type) {
      return [
        SPECIAL_TYPE_GOAL_COST_OF_LIVING_AFTER_RETIREMENT,
        SPECIAL_TYPE_REVENUE_PENSION_INCOME,
        SPECIAL_TYPE_REVENUE_BEFORE_RETIREMENT_INCOME,
        SPECIAL_TYPE_REVENUE_PRESENT_VALUE_MANUAL,
      ].includes(type);
    },

    setCalculationNext() {
      clearTimeout(this.formCalculationTimeout);
      this.formCalculationTimeout = setTimeout(() => {
        this.calculate();
      }, 1000);
    },

    getColor(index) {
      return chartColorScheme[index % chartColorScheme.length];
    },

    createZiele(val) {
      return {
        type: val.type || '',
        typeName: val.typeName || '',
        title: val.title || '',
        age: val.age || '01.01.' + this.currentYear,
        repeat: val.repeat || 'Keine Wiederholung',
        endDate: val.endDate || null,
        priority: val.priority || 0,
        amount: val.amount || 0,
        period: val.period || 'pro Monat',
        id: val.id || undefined,
        myGoalId: val.myGoalId || undefined,
        data: val.data || undefined,
        checked: val.checked || false,
        isIncome: val.isIncome || false,
      };
    },

    addNewGoal(templ) {
      this.openModal(templ, -1, 'goal');
    },

    saveZieleRow(index) {
      this.zieleRowsEditable[index] = false;
    },

    addNewRevenue(templ) {
      this.openModal(templ, -1, 'revenue');
    },

    toggleGoal(id) {
      if (this.selectedTransactions.includes(+id)) {
        this.selectedTransactions = this.selectedTransactions.filter(v => +v !== +id);
      } else {
        this.selectedTransactions.push(id);
      }
      this.setCalculationNext();
    },

    saveRevenueRow(index) {
      this.revenueRowsEditable[index] = false;
    },

    onChanges() {
      this.formChangesSub = this.formChanges.subscribe(val => {
        this.setCalculationNext();
      });
    },

    calculate() {
      this.calculateBalance();

      this.dataPoints = [];

      const specialEventsData = this.getSpecialEventsData();
      const dividerFreq = this.form.period === 'pro Jahr' ? 12 : 1;
      // const pv = this.form.presentValue + specialEventsData.presentValue;
      const pv = specialEventsData.presentValue;
      this.retirementAge = +this.form.retirementAge;
      
      const dob = this.getDateFromStrOrDate(this.form['dateOfBirth'])
      const dobYear = dob.getFullYear()
      this.retirementYear = this.retirementAge + dobYear + 1;
      
      const mapLifeYears = this.gender === 'm' ? manLifeYears.map(v => Math.round(v)) : womanLifeYears.map(v => Math.round(v));
      const test = this.getAgeTo(new Date().getFullYear());
      this.lifeLength = mapLifeYears[this.getAgeTo(new Date().getFullYear())] + this.getAgeTo(new Date().getFullYear()) || 70;

      const repeatingIntervals = [];
      let intervals = [{date: this.getMonthsFromCurrent(this.now), amount: pv}];

      if (this.form.presentValues) {
        this.form.presentValues.assets.map(v => {
          intervals.push({
            date: this.getMonthsFromCurrent(this.now),
            amount: v.value,
            interest: this.assetsInterest[v.key.toLowerCase()],
            type: 'assets'
          })
        })
        this.form.presentValues.loss.map(v => {
          intervals.push({
            date: this.getMonthsFromCurrent(this.now),
            amount: v.value,
            interest: this.liabilitiesInterest[v.key.toLowerCase()],
            type: 'assets'
          })
        })
      }
      
      // add goals
      intervals = intervals.concat(this.transactionsArray
        .filter(filterSpecialGoals)
        .filter(g => this.selectedTransactions.includes(+g.id))
        .map(val => {
          // add repeating events
          if (val.repeat !== 'Keine Wiederholung' && val.endDate) {
            const repeatingIntervalDividerFreq = val.period === 'pro Jahr' ? 12 : 1;
            for (let index = 1; index < this.getMonthsFromCurrent(val.endDate) - this.getMonthsFromCurrent(val.age); index++) {
              repeatingIntervals.push({
                date: this.getMonthsFromCurrent(val.age) + index,
                amount: - val.amount / repeatingIntervalDividerFreq
              });
            }
          }
          // add original to intervals array
          return ({date: this.getMonthsFromCurrent(val.age), amount: - val.amount});
        }));
      // add revenues
      intervals = intervals.concat(
        this.transactionsArray
          .filter(filterSpecialRevenues)
          .filter(g => this.selectedTransactions.includes(+g.id))
          .map(val => {
            // add repeating events
            if (val.repeat !== 'Keine Wiederholung' && val.endDate) {
              const repeatingIntervalDividerFreq = val.period === 'pro Jahr' ? 12 : 1;
              for (let index = 1; index < this.getMonthsFromCurrent(val.endDate) - this.getMonthsFromCurrent(val.age); index++) {
                repeatingIntervals.push({
                  date: this.getMonthsFromCurrent(val.age) + index,
                  amount: val.amount / repeatingIntervalDividerFreq
                });
              }
            }
            // add original to intervals array
            return ({date: this.getMonthsFromCurrent(val.age), amount: val.amount});
          }));
      intervals = intervals.filter(v => v.date !== null && v.date !== undefined && v.amount !== null && v.amount !== undefined);
      intervals.push({date: this.getMonthsFromCurrent(`01.01.${this.retirementYear}`), amount: null});
      intervals = intervals.concat(repeatingIntervals);
      intervals = intervals.filter(interval => interval.date >= this.getMonthsFromCurrent(this.now)).sort((a, b) => a.date - b.date);
      const intervalsCombined = [];
      intervals.map(interval => {
        const intervalIndex = intervalsCombined.findIndex(v => v.date === interval.date);
        if (intervalIndex >= 0) {
          intervalsCombined[intervalIndex] = {
            date: +intervalsCombined[intervalIndex].date,
            intervals: [
              ...intervalsCombined[intervalIndex].intervals,
              {
                ...interval
              }
            ],
            amount: +intervalsCombined[intervalIndex].amount + (interval.type !== 'assets' ? +interval.amount : 0),
          };
        } else {
          intervalsCombined.push({
            date: +interval.date,
            intervals: [interval],
            amount: interval.type !== 'assets' ? +interval.amount : 0,
          });
        }
      });

      this.dataPoints = [];

      // special events with no date as a constant and recalculated
      const {pensionIncome, beforeRetirementIncome, costOfLivingFromRetirement, presentValue} = this.getSpecialEventsData();

      let dataLinesBalance = [];
      let dataLines = [];
      
      // calc data for savings by intervals
      intervalsCombined.map((perDate, i) => {
        let incomePerPeriod = perDate.date < this.getMonthsFromCurrent(`01.01.${this.retirementYear}`) ?
          beforeRetirementIncome :
          - costOfLivingFromRetirement + pensionIncome;

        // const interestRate = (
        //   perDate.date < this.getMonthsFromCurrent(`01.01.${this.retirementYear}`) ?
        //   +this.form['interestBeforeRetirement'] :
        //   +this.form['interestAfterRetirement']
        // );
        const interestRate = (
          perDate.date < this.getMonthsFromCurrent(`01.01.${this.retirementYear}`) ?
          0 :
          +this.form['interestAfterRetirement']
        );
        const previousPV = intervalsCombined[i - 1] ? dataLines[dataLines.length - 1] : 0;

        const dobVal = this.getDateFromStrOrDate(this.form['dateOfBirth'])
        const term = intervalsCombined[i + 1] ? intervalsCombined[i + 1].date : +( dobVal.getFullYear() || this.currentYear) + this.lifeLength;
        let amount = perDate.amount ? perDate.amount : 0;
      
        let currentPV = previousPV + amount;

        if (+term - +perDate.date === 1) {
          currentPV += incomePerPeriod;
          incomePerPeriod = 0;
        }

        dataLines = dataLines.concat(FinancialCalculator.compaundFutureValues(
          currentPV,
          incomePerPeriod / dividerFreq,
          interestRate / 12,
          1,
          +term - +perDate.date
        ));

        // balance intervals
        intervalsCombined[0].intervals.map((from, intervalIndex) => {
          if (from.type === 'assets') {
            // assets lines start
            let minusAsset = 0;
            let amountA = from.amount && from.type === 'assets' ? (from.amount || 0) : 0;
            if (amount < 0) {
              const percentageAssets = intervalsCombined[0].intervals.map(v => v.amount || 0)
              const sumAllAssets = percentageAssets.reduce((acc, curr) => acc+curr, 0)
              minusAsset = ((percentageAssets[intervalIndex] / sumAllAssets) * amount)
            }

            const interestRateA = from.interest !== undefined ? (from.interest || 0) : 0;
          
            const previousPVA = intervalsCombined[i - 1] && dataLinesBalance[intervalIndex] && dataLinesBalance[intervalIndex].length
              ? dataLinesBalance[intervalIndex][dataLinesBalance[intervalIndex].length - 1]
              : 0;
            let currentPVA = (previousPVA || amountA) + minusAsset;
            
            const pointsA = FinancialCalculator.compaundFutureValues(
              currentPVA,
              0,
              interestRateA / 12,
              1,
              +term - +from.date
            )
            // dataLines.push(pointsA);
            if (!dataLinesBalance[intervalIndex]) {
              dataLinesBalance[intervalIndex] = []
            }
            dataLinesBalance[intervalIndex] = dataLinesBalance[intervalIndex].concat(pointsA)
            // assets lines end
          }
        })
        const dataPoints = [];
        if (dataLines && dataLines.length) {
          dataLines.map((data, i) => {
            dataLinesBalance.map(line => {
              dataPoints[i] = (dataPoints[i] || 0) + +line[i].toFixed(0) + +dataLines[i].toFixed(0)
            });
          })
        }
        this.dataPoints = dataPoints;
      });

      const deathYearMonths = this.getMonthsFromCurrent(`01.01.${this.currentYear + this.lifeLength - this.getAgeTo(this.currentYear)}`);

      this.tableDataDetailed = {
        ...this.tableDataDetailed,
        records: this.dataPoints
          .map((dp, i) => ([dayjs(this.getDateFromMonth(i)).format('MM.YYYY'), dp]))
          .filter(v => this.getMonthsFromCurrent(`01.${v[0]}`) <= deathYearMonths),
      };

      // line with original balance
      let presentValuesOriginal = {
        assets: [],
        loss: [],
      }
      if (this.form.presentValues, this.balance.assetsCurrencyData) {
        const selectedBalanceAssets = this.prevRetirementScenario?.selectedBalance
          ?.filter(v => v.key === 'assets').length ?
          this.prevRetirementScenario?.selectedBalance
            ?.filter(v => v.key === 'assets')[0]?.selectedBalanceItems
            .map(v => v.itemValue) : [];
        const selectedBalanceLiabilities = this.prevRetirementScenario?.selectedBalance
          ?.filter(v => v.key === 'loss').length ? 
          this.prevRetirementScenario?.selectedBalance
            ?.filter(v => v.key === 'loss')[0]?.selectedBalanceItems
            .map(v => v.itemValue) : [];
        const assetIndexByName = {};
        this.balance?.assetData?.map((v, i) => {
          assetIndexByName[v.string.toLowerCase()] = `${i}`
        })
        const lossIndexByName = {};
        this.balance?.liabilityData?.map((v, i) => {
          lossIndexByName[v.string.toLowerCase()] = `${i}`
        })
        presentValuesOriginal = {
          assets: this.balance.assetsCurrencyData
            ?.filter(v => selectedBalanceAssets.includes(assetIndexByName[v.string.toLowerCase()]))
            ?.map(v => ({key: v.string, value: this.assetsOriginalValues[v.string.toLowerCase()]})),
          loss: this.balance.liabilityCurrencyData
            ?.filter(v => selectedBalanceLiabilities.includes(lossIndexByName[v.string.toLowerCase()]))
            ?.map(v => ({key: v.string, value: this.liabilitiesOriginalValues[v.string.toLowerCase() + 'liability']}))
        }
      }
      const opriginalData = calculateDataPoints({
        transactionsArray: this.transactionsArray,
        selectedTransactions: this.selectedTransactions,
        selectedTransactionsSpecial: this.selectedTransactionsSpecial,
        dateOfBirth: this.form.dateOfBirth,
        retirementAge: this.form.retirementAge,
        beingDestroyed: this.beingDestroyed,
        period: this.period,
        lifeLength: this.lifeLength,
        presentValues: presentValuesOriginal,
        interestAfterRetirement: this.form.interestAfterRetirement,
        assetsInterest: this.assetsInterest,
        liabilitiesInterest: this.liabilitiesInterest,
      })
      const alternativeChart = calculateChart({
        transactionsArray: this.transactionsArray,
        selectedTransactions: this.selectedTransactions,
        dataPointsPerYear: opriginalData.dataPointsPerYear,
        dateOfBirth: this.form.dateOfBirth,
        retirementYear: this.form.retirementYear,
        maxY: opriginalData.maxY,
        minY: opriginalData.minY,
        lifeLength: this.lifeLength,
        moneyNeededAtTheEnd: this.form.moneyNeededAtTheEnd,
      })
      this.dataLineOriginalBalance = alternativeChart.apexSeries[0].data;
      // line with original balance end

      if (!this.beingDestroyed) {
        this.dataPointsPerYear = this.dataPoints
        .filter((v, i) => i % 12 === 0)
        .filter((v, i) => +i <= +this.lifeLength - this.getAgeTo(this.currentYear));

        this.dataLinesBalance = dataLinesBalance
          .filter(b => b && b.length)
          .map(line => {
          return line
            .filter((v, i) => i % 12 === 0)
            .filter((v, i) => +i <= +this.lifeLength - this.getAgeTo(this.currentYear));
        })

        const maxMinPoints = this.dataPointsPerYear.concat().sort((a, b) => a - b);
        this.maxY = maxMinPoints[maxMinPoints.length - 1];
        this.minY = maxMinPoints[0];
        this.updateChart();
      }

      this.tableDataDetailedRecords = this.getTableData(this.tableDataDetailed);
    },

    reset() {
      this.form.patchValue({
      });
    },

    calculateBalance() {},

    updateChart() {
      this.apexMarkers = {
      };

      const existingZielePoints = {};
      const goalsArrayNoSpecials = this.transactionsArray
        .filter(filterSpecialGoals)
        .filter(g => this.selectedTransactions.includes(+g.id));
      const zielePoints = goalsArrayNoSpecials.map((val, i) => {
        const age = val.age && val.age.split('.') || [0,0,this.currentYear];
        const x = age[2] - this.currentYear + 1;
        let y = this.dataPointsPerYear[age[2] - this.currentYear];
        existingZielePoints[x] = existingZielePoints[x] ? existingZielePoints[x] += 1 : 1;
        if (existingZielePoints[x] > 1) {
          y += (this.maxY - this.minY) * (existingZielePoints[x] - 1) * 0.1;
        }
        const colorIndex = this.transactionsArray.findIndex(v => v.id === val.id);
        return ({
          x: x,
          y: y,
          marker: {
            size: 6,
            fillColor: this.getColor(colorIndex),
            strokeColor: '#ea1601',
            radius: 2
          },
        });
      }).concat(goalsArrayNoSpecials
        .filter(goal => {
          if (!goal.endDate) {
            return false;
          }
          const endDateVal = goal.endDate.split('.');
          const ageVal = goal.age.split('.');
          return goal.repeat !== 'Keine Wiederholung'
            && endDateVal[2] !== ageVal[2];
        })
        .map((val, i) => {
          // for repeating events add endDate
          const endDateVal = val.endDate.split('.');
          const x = endDateVal[2] - this.currentYear + 1;
          let y = this.dataPointsPerYear[endDateVal[2] - this.currentYear];
          existingZielePoints[x] = existingZielePoints[x] ? existingZielePoints[x] += 1 : 1;
          if (existingZielePoints[x] > 1) {
            y += (this.maxY - this.minY) * (existingZielePoints[x] - 1) * 0.1;
          }
          const colorIndex = this.transactionsArray.findIndex(v => v.id === val.id);
          return ({
            x: x,
            y: y,
            marker: {
              size: 6,
              fillColor: this.getColor(colorIndex),
              strokeColor: '#2698FF',
              radius: 2
            },
          });
      }));
      const revenueArrayNoSpecials = this.transactionsArray
        .filter(filterSpecialRevenues)
        .filter(g => this.selectedTransactions.includes(+g.id));
      const revenuePoints = revenueArrayNoSpecials.map((val, i) => {
        const ageVal = val.age.split('.');
        const x = ageVal[2] - this.currentYear + 1;
        let y = this.dataPointsPerYear[ageVal[2] - this.currentYear];
        existingZielePoints[x] = existingZielePoints[x] ? existingZielePoints[x] += 1 : 1;
        if (existingZielePoints[x] > 1) {
          y += (this.maxY - this.minY) * (existingZielePoints[x] - 1) * 0.1;
        }
        const colorIndex = this.transactionsArray.findIndex(v => v.id === val.id);
        return ({
          x: x,
          y: y,
          marker: {
            size: 6,
            fillColor: this.getColor(colorIndex),
            strokeColor: '#00e396',
            radius: 2
          },
        });
      }).concat(revenueArrayNoSpecials
        .filter(val => {
          if (!val.endDate) {
            return false;
          }
          const endDateVal = val.endDate.split('.');
          const ageVal = val.age.split('.');
          return val.repeat !== 'Keine Wiederholung'
            && endDateVal[2] !== ageVal[2];
        })
        .map((val, i) => {
          const endDateVal = val.endDate.split('.');
          const x = endDateVal[2] - this.currentYear + 1;
          let y = this.dataPointsPerYear[endDateVal[2] - this.currentYear];
          existingZielePoints[x] = existingZielePoints[x] ? existingZielePoints[x] += 1 : 1;
          if (existingZielePoints[x] > 1) {
            y += (this.maxY - this.minY) * (existingZielePoints[x] - 1) * 0.1;
          }
          const colorIndex = this.transactionsArray.findIndex(v => v.id === val.id);
          return ({
            x: x,
            y: y,
            marker: {
              size: 6,
              fillColor: this.getColor(colorIndex),
              strokeColor: '#00e396',
              radius: 2
            },
          });
      }));

      this.getMoneyNeededForRetirement();
      let moneyNeededForRetirePoints = [
        {
          x: this.lifeLength - (this.getAgeTo(this.currentYear) || 0) + 1,
          y: this.form.moneyNeededAtTheEnd,
          borderColor: '#775DD0',
          marker: {
            size: 6,
            fillColor: '#775DD0',
            strokeColor: '#775DD0',
            radius: 2
          },
        }
      ];

      this.apexSeries = [{data: []}]
      this.apexSeries[0].data = [
        ...this.dataPointsPerYear
      ];

      if (this.showOriginalWert) {
        this.apexSeries.push({
          lineStyle: {color: '#cccccc'},
          data: this.dataLineOriginalBalance
        })
      }

      this.dataLinesBalance.map(line => {
        this.apexSeries.push({
          data: line
        })
      })

      this.apexXaxis = {
        type: 'category',
        categories: Array.from({ length: 90 }, (_, i) => i + this.currentYear ),
        labels: {
          show: true,
          rotate: 90,
          hideOverlappingLabels: true,
          showDuplicates: false,
          offsetX: 0,
          offsetY: 0,
          trim: false,
        }
      };
      this.apexAnnotations = {
        xaxis: [
          {
            x: this.retirementYear,
            strokeDashArray: 0,
            borderColor: '#775DD0',
            label: {
              borderColor: '#775DD0',
              style: {
                color: 'var(--color-box)',
                background: '#775DD0'
              },
              text: 'Renteneintritt'
            }
          },
          {
            x: this.currentYear + this.lifeLength - (this.getAgeTo(this.currentYear) || 0),
            strokeDashArray: 0,
            borderColor: '#775DD0',
            label: {
              borderColor: '#775DD0',
              style: {
                color: 'var(--color-box)',
                background: '#775DD0'
              },
              text: 'Lebenserwartung'
            }
          },
        ],
        points: zielePoints.concat(revenuePoints).concat(moneyNeededForRetirePoints)
      };
  
      this.$forceUpdate();
    },

    getMoneyNeededForRetirement() {
      const moneyNeededAtTheEnd = +(this.form['moneyNeededAtTheEnd'] || 0);

      const retirementIndex = this.retirementYear - this.currentYear + 1;
      const deathIndex = this.lifeLength - this.getAgeTo(this.currentYear);
      if (this.dataPointsPerYear[deathIndex] >= 0) {
        this.moneyNeededForRetirement = 0;
        this.moneyNeededForRetirementAdditionalStartDate = 0;
        this.moneyNeededForRetirementAdditionalMonthly = 0;
        return;
      }
      const coef = Math.abs(this.dataPointsPerYear[retirementIndex] / this.dataPointsPerYear[deathIndex]);

      const maxMinPointsRetirement = this.dataPointsPerYear.slice(retirementIndex, deathIndex).sort((a, b) => a - b);
      const minY = maxMinPointsRetirement[0];
      this.moneyNeededForRetirement = FinancialCalculator.compoundFutureValue(
        Math.abs(minY) - moneyNeededAtTheEnd,
        0,
        +this.form['interestAfterRetirement'],
        1,
        deathIndex - retirementIndex
      );
      this.moneyNeededForRetirement = FinancialCalculator.initialValue(
        Math.abs(minY) + moneyNeededAtTheEnd,
        +this.form['interestBeforeRetirement'],
        deathIndex - retirementIndex
      );
      this.moneyNeededForRetirementAdditionalStartDate = Math.abs(FinancialCalculator.initialValue(
        this.moneyNeededForRetirement,
        +this.form['interestBeforeRetirement'],
        retirementIndex
      ));
      this.moneyNeededForRetirementAdditionalMonthly = Math.abs(FinancialCalculator.monthlyPayment(
        this.moneyNeededForRetirement,
        this.dataPointsPerYear[0],
        +this.form['interestBeforeRetirement'],
        12,
        deathIndex - retirementIndex
      ));
    },

    getSpecialEventsData() {
      const beforeRetirementIncomeVal = this.transactionsArray.filter(v => v.type === SPECIAL_TYPE_REVENUE_BEFORE_RETIREMENT_INCOME && this.selectedTransactionsSpecial.includes(v.type+(v.amount || v.qty))); // && this.selectedTransactions.includes(+v.id)
      const pensionIncomeVal = this.transactionsArray.filter(v => v.type === SPECIAL_TYPE_REVENUE_PENSION_INCOME && this.selectedTransactionsSpecial.includes(v.type+(v.amount || v.qty))); // && this.selectedTransactions.includes(+v.id)
      const costOfLivingFromRetirementVal = this.transactionsArray.filter(v => v.type === SPECIAL_TYPE_GOAL_COST_OF_LIVING_AFTER_RETIREMENT && this.selectedTransactionsSpecial.includes(v.type+(v.amount || v.qty))); // && this.selectedTransactions.includes(+v.id)
      const beforeRetirementIncome = beforeRetirementIncomeVal.reduce((acc, curr) => acc + +curr.amount, 0);
      const pensionIncome = pensionIncomeVal.reduce((acc, curr) => acc + +curr.amount, 0);
      const costOfLivingFromRetirement = costOfLivingFromRetirementVal.reduce((acc, curr) => acc + +curr.amount, 0);
      const presentValueManualVal = this.transactionsArray.filter(v => v.type === SPECIAL_TYPE_REVENUE_PRESENT_VALUE_MANUAL && this.selectedTransactionsSpecial.includes(v.type+(v.amount || v.qty))); // && this.selectedTransactions.includes(+v.id)
      const presentValue = presentValueManualVal.reduce((acc, curr) => acc + +curr.amount, 0);
      return {
        pensionIncome,
        beforeRetirementIncome,
        costOfLivingFromRetirement,
        presentValue,
      };
    },

    getAgeTo(year) {
      const dob = this.getDateFromStrOrDate(this.form['dateOfBirth'])
      if (!dob || !year) {
        return;
      }
      const yearVal = year.split && year.split('.');
      const theYear = yearVal && yearVal[2] ? yearVal[2] : year;
      return theYear - dob.getFullYear() || 0;
    },

    getDateFromStrOrDate(value) {
      let dob = new Date();
      if (value && (value instanceof Date || typeof value === 'string')) {
        dob = value instanceof Date ? value : dayjs(value, 'DD.MM.YYYY').toDate();
      }
      const dobVal = DatePickerUtils.safeDate(dob);
      return dobVal
    },

    getFreqTitle() {
      return this.form['period'] === 'pro Jahr' ? 'pro Jahr' : 'pro Monat';
    },

    openModal(template, index, type, isSmall = false) {
      this.currentRowIndex = index;
      this.currentRowType = type;
      if (this.$refs[template]) {
        this.$refs[template].open();
      }
    },

    saveRevenue() {
      this.modalRef.hide();
    },

    saveToServer(isDispatch = true) {
      if (this.validation && this.validation.isInvalid('form')) {
        return true;
      }
      const dob = dayjs(this.personalData.personalDataBirth.dayOfBirth, 'DD.MM.YYYY').toDate();
      this.retirementYear = +dob.getFullYear() + this.retirementAge;
      let params = {
        startDate: '01.01.' + +this.currentYear,
        ...this.prevRetirementScenario,
        ...this.form,
        retirementYear: this.retirementYear,
        selectedBalance: Object.keys(this.selectedBalance).map(key => ({
            key: key,
            selectedBalanceItems: Object.keys(this.selectedBalance[key])
              .filter(k => this.selectedBalance[key][k])
              .map(v => ({itemValue: v}))
          })),
        selectedGoals: this.selectedTransactions.filter(v => !!v).map(v => ({selectedValue: v})),
        selectedIncomes: [],
        pensionIncome: this.transactionsArray
          .filter(v => v.type === SPECIAL_TYPE_REVENUE_PENSION_INCOME)
          .filter(g => !!this.selectedTransactionsSpecial.includes(g.type+(g.amount || g.qty)))
          .map(v => ({selectedValue: v.amount})),
        beforeRetirementIncome: this.transactionsArray
          .filter(v => v.type === SPECIAL_TYPE_REVENUE_BEFORE_RETIREMENT_INCOME)
          .filter(g => !!this.selectedTransactionsSpecial.includes(g.type+(g.amount || g.qty)))
          .map(v => ({selectedValue: v.amount})),
        presentValue: this.transactionsArray
          .filter(v => v.type === SPECIAL_TYPE_REVENUE_PRESENT_VALUE_MANUAL)
          .filter(g => !!this.selectedTransactionsSpecial.includes(g.type+(g.amount || g.qty)))
          .map(v => ({selectedValue: v.amount})),
        costOfLivingFromRetirement: this.transactionsArray
          .filter(v => v.type === SPECIAL_TYPE_GOAL_COST_OF_LIVING_AFTER_RETIREMENT)
          .filter(g => !!this.selectedTransactionsSpecial.includes(g.type+(g.amount || g.qty)))
          .map(v => ({selectedValue: v.amount})),
      };
      delete params['transactionsArray'];
      delete params['zieleArray'];
      delete params['revenueArray'];
      delete params['dateOfBirth'];
      const d = new Date();

      const asserts = {}
      const assetsInterestStored = Object.keys(this.assetsInterestStored).map(k => {
        return {
          id: k,
          ...this.assetsInterestStored[k]
        }
      })
      let keyId = Object.keys(this.liabilitiesInterest).length - 1;
      Object.keys(this.liabilitiesInterest).map(key => {
        const id = assetsInterestStored.find(v => v.name.toLowerCase() === key && v.type === 0)
        const isIndividual = key.toLowerCase() === 'individuell'
        if (id) {
          asserts[id.id] = {
            // name: key,
            type: 0,
            procent: this.liabilitiesInterest[key.toLowerCase()],
            value: isIndividual ? this.liabilitiesIndividual : this.liabilitiesOriginalValues[key.toLowerCase() + 'liability'],
          }
        }
      })
      Object.keys(this.assetsInterest).map(key => {
        const id = assetsInterestStored.find(v => v.name.toLowerCase() === key && v.type === 1)
        const isIndividual = key.toLowerCase() === 'individuell'
        keyId = keyId + 1;
        if (id) {
          asserts[id.id] = {
            // name: key,
            type: 1,
            procent: this.assetsInterest[key.toLowerCase()],
            value: isIndividual ? this.assetsIndividual : this.assetsOriginalValues[key.toLowerCase()],
          }
        } else {
          // asserts[keyId] = {
          //   type: 1,
          //   procent: this.assetsInterest[key.toLowerCase()],
          //   value: this.assetsOriginalValues[key.toLowerCase()],
          // }
        }
      })


      params = {
        ...params,
        name: params.name || 'Ruhestandsplanung ' + DatePickerUtils.safeDate(d).getFullYear() + `${(DatePickerUtils.safeDate(d).getMonth()+1)}`.padStart(2, '0') + `${DatePickerUtils.safeDate(d).getDate()}`.padStart(2, '0') + `${DatePickerUtils.safeDate(d).getHours()}`.padStart(2, '0') + `${DatePickerUtils.safeDate(d).getMinutes()}`.padStart(2, '0'),
        selectedBalance: params.selectedBalance && params.selectedBalance.length ? params.selectedBalance : null,
        selectedGoals: params.selectedGoals && params.selectedGoals.length ? params.selectedGoals : null,
        selectedIncomes: params.selectedIncomes && params.selectedIncomes.length ? params.selectedIncomes : null,
        // selectedTransactions: params.selectedTransactions && params.selectedTransactions.length ? params.selectedTransactions : null,
        pensionIncome: params.pensionIncome && params.pensionIncome.length ? params.pensionIncome : null,
        beforeRetirementIncome: params.beforeRetirementIncome && params.beforeRetirementIncome.length ? params.beforeRetirementIncome : null,
        presentValue: params.presentValue && params.presentValue.length ? params.presentValue : null,
        costOfLivingFromRetirement: params.costOfLivingFromRetirement && params.costOfLivingFromRetirement.length ? params.costOfLivingFromRetirement : null,
        updatedDate: null,
        asserts,
      };

      if (isDispatch) {
        this.$store.dispatch(RETIREMENT_SCENARIO_TYPES.ACTIONS.SET_SCENARIO, params);
      }
      return params;
    },

    isGoalSelected(id) {
      return this.selectedTransactions.includes(+id);
    },

    isIncomeSelected(id) {
      return this.selectedTransactions.includes(+id);
    },

    editEvent(index, type, templ, id) {
      if (type === 'goal') {
        this.openModal(templ, +index, 'goal');
      } else {
        this.openModal(templ, +index, 'revenue');
      }
    },

    editEventSpecial(event, type, templ) {
      const index = this.transactionsArray.findIndex(v => event.type === v.type && (event.amount === v.amount || event.qty === v.amount));
      if (type === 'goal') {
        this.openModal(templ, +index, 'goal');
      } else {
        this.openModal(templ, +index, 'revenue');
      }
    },

    deleteEvent(id, type) {
      const index = this.transactionsArray.findIndex(v => v.id === id);
      if (index > -1) {
        this.transactionsArray.splice(index, 1);
      }
    },

    deleteSpecialEvent(event) {
      const index = this.transactionsArray.findIndex(v => event.type === v.type && (event.amount === v.amount || event.qty === v.amount));
      if (index > -1) {
        this.transactionsArray.splice(index, 1);
        this.fillTransactionsTableData();
        this.setCalculationNext();
      }
    },

    submit() {
      this.calculate();
      this.saveToServer();
    },

    saveEvent(goal) {
      let goalSaved = {};
      goalSaved = this.transactionsArray[this.currentRowIndex];
      if (goal && this.isSpecialEventType(goal.type)) {
        this.currentRowIndex = goalSaved && this.currentRowIndex > -1 ? this.currentRowIndex : this.transactionsArray.length;
        this.transactionsArray[this.currentRowIndex] = this.createZiele({
          id: this.currentRowIndex,
          typeName: this.mapGoalAPIToName(goal.type),
          ...goal,
          isIncome: this.currentRowType !== 'goal',
        });
        const key = goal.type + goal.amount;
        if (!this.selectedTransactionsSpecial.includes(key)) {
          this.selectedTransactionsSpecial.push(key);
        }
        //update scenario
        this.prevRetirementScenario = {
          ...this.prevRetirementScenario,
          pensionIncome: this.transactionsArray
            .filter(v => v.type === SPECIAL_TYPE_REVENUE_PENSION_INCOME)
            .map(v => ({selectedValue: v.amount})),
          beforeRetirementIncome: this.transactionsArray
            .filter(v => v.type === SPECIAL_TYPE_REVENUE_BEFORE_RETIREMENT_INCOME)
            .map(v => ({selectedValue: v.amount})),
          presentValue: this.transactionsArray
            .filter(v => v.type === SPECIAL_TYPE_REVENUE_PRESENT_VALUE_MANUAL)
            .map(v => ({selectedValue: v.amount})),
          costOfLivingFromRetirement: this.transactionsArray
            .filter(v => v.type === SPECIAL_TYPE_GOAL_COST_OF_LIVING_AFTER_RETIREMENT)
            .map(v => ({selectedValue: v.amount})),
        }

        this.presetSlectedTableRows()
        this.fillTransactionsTableData();
        this.setCalculationNext();
        return;
      }

      if (goal) {
        const paymentPeriod = goal.period === 'pro Jahr' ? 1 : 12;
        let startDate = goal.age && Object.prototype.toString.call(goal.age) === '[object Date]' ? dayjs(goal.age).format('DD.MM.YYYY') : goal.age;

        const endDate = goal.endDate ?
          dayjs(this.getDateFromStrOrDate(goal.endDate)).format('DD.MM.YYYY').split('.')
          : [1, 1, startDate.split('.')[2]];
        const endDateObj = new Date(endDate[2], endDate[1] - 1, endDate[0]);
        let startDateSplit = startDate.split('.');
        let goalStartDate = new Date(startDateSplit[2], startDateSplit[1] - 1, startDateSplit[0]);
        let numberOfPayments = Math.round(Math.abs(DatePickerUtils.safeDate(endDateObj).getMonth() - DatePickerUtils.safeDate(goalStartDate).getMonth() +
          (12 * (DatePickerUtils.safeDate(endDateObj).getFullYear() - DatePickerUtils.safeDate(goalStartDate).getFullYear()))) / (goal.period === 'pro Jahr' ? 12 : 1));
        let paymentEachPeriod = FinancialCalculator.calculatePaymentEachPeriod(
          0, goal.amount, numberOfPayments, 0, paymentPeriod
        );
        if (!goal.endDate) {
          startDate = dayjs(this.now).format('DD.MM.YYYY');
          const goalEventDate = new Date(startDateSplit[2], startDateSplit[1] - 1, 1);
          goalStartDate = new Date(this.now.getTime());
          numberOfPayments = Math.round(Math.abs(monthsDiff(goalEventDate, goalStartDate)) / (goal.period === 'pro Jahr' ? 12 : 1)) + 1;
          paymentEachPeriod = FinancialCalculator.calculatePaymentEachPeriod(
            0, goal.amount, numberOfPayments, 0, paymentPeriod
          );
        }

        const writeStartDate = startDate && dayjs(goalStartDate).format('DD.MM.YYYY');
        const writeEndDate = goal.endDate && Object.prototype.toString.call(endDateObj) === '[object Date]' && dayjs(endDateObj).format('DD.MM.YYYY');

        this.$store.dispatch(MY_GOALS_TYPES.ACTIONS.SAVE_GOAL, {
          goal: {
          ...(goalSaved ? goalSaved?.data : {}),
          myGoal: {
            ...(goalSaved && goalSaved?.data && goalSaved?.data?.myGoal || {}),
            title: goal.title,
            startDate: writeStartDate,
            endDate: writeEndDate,
            type: this.mapGoalNameToAPI(goal.type),
            isIncome: this.currentRowType !== 'goal',
          },
          numberOfPayments: numberOfPayments,
          paymentPeriod: paymentPeriod,
          futureValue: goal.amount || 0,
          paymentEachPeriod: paymentEachPeriod || 0,
          presentValue: 0,
          rate: 0,
          id: goal.id || null,
        }}).then(v => {
          this.$store.dispatch(MY_GOALS_TYPES.ACTIONS.GET_GOALS);

          if (!this.selectedTransactions.includes(+v.goalCalc?.id)) {
            this.selectedTransactions.push(+v.goalCalc?.id);
          }
        });
      }
    },

    mapGoalAPIToName(val, isIncome = false) {
      switch (val) {
        case 'immobilienkauf':
        case 'estate':
          return isIncome ? 'Immobilienverkauf' : 'Immobilienkauf';
        case 'reserve':
          return 'Sonstiges';
        case 'urlaub':
        case 'holiday':
          return 'Urlaub';
        case 'grossereanschaffung':
          return 'größere Anschaffung';
        case 'weiderkehrendeausgaben':
          return 'weiderkehrende Ausgaben';
        case 'einmaligeeinnahme':
          return 'Einmalige Einnahme';
        case 'regelmassiteeinnahme':
          return 'Regelmässite Einnahme';
        case 'sonstiges':
          return 'Sonstiges';
        case 'purchase':
          return 'Sonstiges';
      }
      return val || 'Sonstiges';
    },

    mapGoalNameToAPI(val) {
      switch (val.toLowerCase()) {
        case 'immobilienkauf':
        case 'immobilienverkauf':
        case 'immobilienkauf':
          return 'estate';
        case 'sonstiges':
          return 'reserve';
        case 'urlaub':
          return 'urlaub';
        case 'größere anschaffung':
          return 'grossereanschaffung';
        case 'weiderkehrende ausgaben':
          return 'weiderkehrendeausgaben';
        case 'einmalige einnahme':
          return 'einmaligeeinnahme';
        case 'regelmässite einnahme':
          return 'regelmassiteeinnahme';
        case 'sonstiges':
          return 'sonstiges';
      }
      return 'sonstiges';
    },

    toggleAdvancedSettings() {
      this.calcAdvancedSettings = !this.calcAdvancedSettings;
    },

    getDateObj(theDate) {
      if (theDate === undefined || theDate === null) {
        return new Date();
      }
      if (theDate instanceof Date) {
        return theDate;
      } else {
        const stDate = DatePickerUtils.getDateFromString(theDate);
        return stDate;
      }
    },

    getMonthsFromCurrent(date) {
      if (date === undefined || date === null) {
        return 0;
      }
      if (date instanceof Date) {
        if (date < this.now) {
          return 0;
        }
        return DatePickerUtils.safeDate(date).getMonth() - DatePickerUtils.safeDate(this.now).getMonth() +
          (12 * (DatePickerUtils.safeDate(date).getFullYear() - DatePickerUtils.safeDate(this.now).getFullYear()));
      } else {
        const stDate = DatePickerUtils.getDateFromString(date);
        if (stDate < this.now) {
          return 0;
        }
        return stDate.getMonth() - DatePickerUtils.safeDate(this.now).getMonth() +
          (12 * (stDate.getFullYear() - this.now.getFullYear()));
      }
    },

    getDateFromMonth(num) {
      const d = new Date();
      d.setMonth(d.getMonth() + num);
      return d;
    },

    handleTableAction(event) {
      switch(event.action?.legend?.key) {
        case 'EDIT':
          this.editAction(event.value)
          break;
        case 'DELETE':
          this.deleteAction(event.value)
          break;
        default:
          this.$store.dispatch(LOG_TYPES.ACTIONS.WARN, `action not implemented yet: ${event.action?.legend?.key}`);
          break;
      }
    },
    editAction(value) {
      if (value.id && !this.isSpecialEventType(value.type)) {
        this.editEvent(value.index, value.isIncome ? 'revenue' : 'goal', 'addGoalTemplate', value.id);
      }
      if (this.isSpecialEventType(value.type)) {
        this.editEventSpecial(value, value.isIncome ? 'revenue' : 'goal', 'addGoalTemplate');
      }
    },
    deleteAction(value) {
      if (!this.isSpecialEventType(value.type)) {
        this.deleteEvent(value.id, value.isIncome ? 'revenue' : 'goal');
        this.$store.dispatch(MY_GOALS_TYPES.ACTIONS.DELETE_GOAL, {id: value.myGoalId});
      } else {
        this.deleteSpecialEvent(value);
      }
    },
    onSelectedRows(values) {
      this.selectedTransactionsSpecial = Object.keys(values)
        .filter(k => !!values[k])
        .filter(i => this.isSpecialEventType(this.transactionsArray[i].type))
        .map(i => this.transactionsArray[i].type+this.transactionsArray[i].amount)

      this.selectedTransactions = Object.keys(values)
        .filter(k => !!values[k])
        .filter(v => !this.isSpecialEventType(v.type))
        .map(i => this.transactionsArray[i] && this.transactionsArray[i].id)
      this.setCalculationNext();
    },
    onUnSelectedRow(item) {
    },
    onSelectedRow(item) {
    },

    deleteActionEnabled(row) {
      return true; //!this.isSpecialEventType(row.type);
    },

    navigateToPath(path) {
      this.selectedStepKey = 'settings';
    },

    navigateToSubstepPath(event) {
      this.selectedStepKey = 'settings';
    },

    navigateTo(event) {
      this.$router.push({ path: `${event}` });
    },

    changeInterest(name, value, direction) {
      if (direction > 0) {
        this.assetsInterest[name.toLowerCase()] = value
        this.assetsInterest = {...this.assetsInterest}
      } else {
        this.liabilitiesInterest[name.toLowerCase()] = value
        this.liabilitiesInterest = {...this.liabilitiesInterest}
      }
      this.setCalculationNext();
    },

    changeOriginalValues() {
      this.setBalance(this.form.presentValue)
      this.setBalanceItems(this.form.presentValues)
    }
  },
  filters: {
    currency(value) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value/1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' €';
    },
    number(value) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value/1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    date(value) {
      if (!value) {
        return '';
      }
      return dayjs(DatePickerUtils.safeDate(value)).format('MM.YYYY');
    },
  },
};
</script>

<style scoped>
.hidden-chart {
  opacity: 0;
}
.hidden {
  display: none;
}
.balance-sheet {
	/* height: calc(100vh - 300px); */
    overflow: auto;
    width: 100%;
}
.break {
	flex-basis: 100%;
	height: 0;
}
.modal-container {
  width: 90%;
  background-color: var(--color-box);
}
.modal-content {
  padding: 10px;
  min-width: 700px;
  background-color: var(--color-box);
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.loading-ic {
  font-size: 4rem;
  animation: rotating 2s linear infinite;
}
.row-box__container {
  column-gap: 24px;
}
.row-box__container .box__container {
  flex-basis: calc(50% - 12px);
}
.badge {
  display: inline-block;
  padding: 4px 8px;
  color: var(--color-primary-text);
  background-color: var(--color-background);
  border-radius: 5px;
  margin: 8px;
  font-size: 0.85rem;
}
.badge-red {
  background-color: var(--color-danger);
}
.badge-green {
  background-color: var(--color-success);
}
.type-col {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.m-0 {
  padding: 0;
}
.p-0 {
  padding: 0;
}
</style>