<template>
    <div>
        <PageHeaderTitleNavigation title="Suitability Test Vermögensverwaltung" />

        <BaseFilter
            filterId="c44d470b-fa6d-47ad-b9e6-3e90e866c10b"
            :metadata="searchMenu"
            :defaultOptions="defaultOptionsData"
            :configFilter="configFilter"
            @onFilter="search"
            :isCustomerSearch="false"
            immidiateSearch
            showSaveButton
            isCache
        />
        <div>
            <GhostLoading v-if="loading" type="table" :title="tableTitle" :config="{ table: { rows: 6 } }" />
            <Table v-else-if="rows.length" :title="tableTitle" tableId="7519921b-de14-431a-9fc0-e8c61413c250"
                :headers="headers" :rows="rows" rowId="beratungsmappennr" :rowsPerPage="rowsPerPage"
                @click-beratungsmappenName="openBeratungsmappe($event)" @action-COMMENT="editComment"
                @action-OPENLEGIEXTRA="(row) => openBeratungsmappe(row, { legiSeperate: true })">
            </Table>
            <NoData v-else :title="tableTitle" />
        </div>
        <EditCommentModal ref="EditCommentModal" @completed="saveBemerkungText" />
    </div>
</template>
<script>

import {
    PhCheck,
    PhWarning,
    PhX,
} from "phosphor-vue";
import VV_TYPES from '@/store/vermoegensverwaltung/types';
import EINGEGANGENE_DOKUMENTE_TYPES from '@/store/eingegangeneDokumente/types';
import CORE_TYPES from '@/store/core/types';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import Table from "@/components/table2/Table.vue";
import { DateColumn, TextColumn, ActionColumn, SimpleAction } from "@/components/table2/table_util.js";
import { mapGetters } from 'vuex';
import NoData from "@/components/core/NoData.vue";
import BaseFilter from '@/components/core/BaseFilter.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import EditCommentModal from "./EditCommentModal.vue";

export default {
    components: {
        PhCheck,
        PhWarning,
        PhX,
        Table,
        NoData,
        OptionMenu,
        PageHeaderTitleNavigation,
        BaseFilter,
        GhostLoading,
        EditCommentModal
    },
    data() {
        return {
            loading: false,
            headers: {
                lockedLeft: [               
                    TextColumn('beratungsmappenName', 'Strategie')
                        .makeLink()
                        .makeAlwaysVisible(),
                    TextColumn('pruefungsstatus', 'Prüfungsstatus').setColorFn((row) => this.getIconColor(row.pruefungsstatus)),
                    TextColumn('versandstatus', 'Versandstatus'),
                ],
                center: [
                    TextColumn('kunde', 'Kunde'),
                    TextColumn('makler', 'Makler'),
                    TextColumn('pruefer', 'Prüfer'),
                    DateColumn('dateEinreichung', 'Eingereicht am'),
                    DateColumn('dateCreation', 'Erstellt am'),
                    DateColumn('datePruefung', 'Geprüft am'),
                    TextColumn('bemerkungText', 'Bemerkung')
                ],
                lockedRight: [
                    ActionColumn("actions"),
                ]
            },
            rowsPerPage: 25,
            ICON_SIZE: 24,
        };
    },
    computed: {
        ...mapGetters({
            suitabilityResultList: VV_TYPES.GETTERS.SUITABILITYRESULTLIST, //FondsKonzept data (Wealthkonzept)
        }),
        tableTitle() {
            return "Gefilterte Beratungsmappen"
        },
        rows() {
            return this.suitabilityResultList.map((result) => {
                const actions = [
                    SimpleAction("COMMENT", 'PhChatText', "Bemerkung hinzufügen"),
                    SimpleAction('OPENLEGIEXTRA', 'PhArrowBendUpRight', 'Mappe öffnen (mit Legitimation in neuem Tab)')
                ];

                return {
                    ...result,
                    actions: actions
                }
            });
        },
        searchMenu() {
            return [
                {
                    type: 'group',
                    key: 'allgemein',
                    label: 'Allgemein',
                    menuItems: [
                        {
                            type: 'text',
                            label: 'Kundenname / -nummer',
                            key: 'kunde',
                        },
                        {
                            type: 'text',
                            label: 'Maklername / -nummer',
                            key: 'makler',
                        },
                    ]
                },
                {
                    type: 'group',
                    key: 'dateCreation',
                    label: 'in Zeitraum erstellt',
                    menuItems: [
                        {
                            type: 'text',
                            label: 'Beratungsmappen der letzten Tage',
                            key: 'DATUM_LETZTE_TAGE',
                        },
                        {
                            type: 'dateRange',
                            label: 'Beratungsmappen im angegebenen Zeitraum',
                            key: 'ZEITRAUM',
                        },
                        {
                            type: 'default',
                            label: 'Beratungsmappen seit Beginn',
                            key: 'DATUM_SEITBEGINN',
                        },
                    ]
                },
                {
                    type: 'group',
                    key: 'datePruefung',
                    label: 'in Zeitraum geprüft',
                    menuItems: [
                        {
                            type: 'text',
                            label: 'Beratungsmappen der letzten Tage',
                            key: 'DATUM_LETZTE_TAGE',
                        },
                        {
                            type: 'dateRange',
                            label: 'Beratungsmappen im angegebenen Zeitraum',
                            key: 'ZEITRAUM',
                        },
                        {
                            type: 'default',
                            label: 'Beratungsmappen seit Beginn',
                            key: 'DATUM_SEITBEGINN',
                        },
                    ]
                },
                {
                    type: 'group',
                    key: 'pruefungsstatus',
                    label: 'Prüfungsstatus',
                    menuItems: [
                        {
                            type: 'default',
                            label: 'Ausstehend',
                            key: 'AUSSTEHEND',
                        },
                        {
                            type: 'default',
                            label: 'Abgelehnt',
                            key: 'ABGELEHNT',
                        },
                        {
                            type: 'default',
                            label: 'Angenommen',
                            key: 'ANGENOMMEN',
                        },
                    ]
                },
            ]
        },
        defaultOptionsData() {
            return {
                DATUM_LETZTE_TAGE: {
                    value: "30",
                },
                AUSSTEHEND: {
                    value: 'AUSSTEHEND',
                }

            };
        },
        configFilter() {
            return {
                defaultSearchInputKeys: ['dateCreation', 'pruefungsstatus'],
                defaultFiltersNum: 2,
                noResetOnDefaultSearchInputExit: false,
            }
        },
    },
    beforeRouteLeave(to, from, next) {
        this.$addBreadcrumb({
            label: "zurück zur Suitability Übersicht",
            to,
            from,
        });

        next();
    },
    mounted() {

    },
    methods: {
        search(filterParams) {
            this.loading = true;
            const queryParams = {};
            filterParams.forEach(param => {
                if (param.group === 'pruefungsstatus') {
                    queryParams[param.group] = param.key;
                } else if (param.group === 'dateCreation' || param.group === 'datePruefung') {
                    if (param.key === 'ZEITRAUM') {
                        queryParams[param.group + 'From'] = this.dateToTimestamp(param.value[0].value);
                        queryParams[param.group + 'To'] = this.dateToTimestamp(param.value[1].value);
                    } else if (param.key === 'DATUM_LETZTE_TAGE') {
                        const d = new Date();
                        d.setDate(d.getDate() - param.value);
                        const nDaysAgo = d.getTime();
                        queryParams[param.group + 'From'] = nDaysAgo;
                    } //SEITBEGINN doesnt need a param as backend defaults to having no time filter
                } else {
                    queryParams[param.key] = param.value;
                }
            })
                this.$store.dispatch(VV_TYPES.ACTIONS.GET_SUITABILITYRESULTLIST, queryParams).finally(() => { this.loading = false });
        },
        dateToTimestamp(dateString) {
            var dateParser = /(\d{2}).(\d{2}).(\d{4})/;
            var match = dateString.match(dateParser);
            return new Date(
                match[3],  // year
                match[2] - 1,  // monthIndex
                match[1],  // day
            ).getTime();
        },
        getIconColor(status) {
            if (!status) {
                return 'color-text';
            }
            switch (status.toUpperCase()) {
                case 'AUSSTEHEND':
                case 'NIE':
                    return 'color-warning';
                case 'JA':
                case 'ANGENOMMEN':
                    return 'color-success';
                case 'FEHLER':
                case 'ABGELEHNT':
                    return 'color-danger';

            }
        },
        openBeratungsmappe(row, queryParams) {
            this.$router.push({ path: `/intern/suitability-test/mappe/${row.beratungsmappennr}`, query: queryParams });
        },
        editComment(row) {
            this.$refs.EditCommentModal.open(row);
        },
        saveBemerkungText(params) {
            const result = this.suitabilityResultList.find((result) => {
                return result.beratungsmappennr === params.mappenID;
            });
            result.bemerkungText = params.bemerkungText;
            this.$store.dispatch(VV_TYPES.ACTIONS.POST_SUITABILITYRESULT, params)
        }
    }
}
</script>