<template>
  <div v-if="showIf" class="box__container" ref="box_container">
    <div class="row box__title" v-if="title">
        <div class="col-12">
            {{ title }}
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <slot></slot>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        showIf:{
            type: Boolean,
            default: true
        },
        title: {
            type: String
        },
    },
    methods: {
        getContainerWidth() {
            return this.$refs.box_container.clientWidth;
        },
        getContainerHeight() {
            return this.$refs.box_container.clientHeigth;
        },
        emitEvent() {
            this.$emit('boxContainerSize', {width: this.getContainerWidth(), height: this.getContainerHeight()})
        }
    },
    mounted() {
        this.emitEvent();
    }

}
</script>

<style>

</style>