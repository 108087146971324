export const MUTATION_PREFIX = 'MERGE_PEOPLE: ';
export const ACTIONS_PREFIX = 'MERGE_PEOPLE_';
export const GETTERS_PREFIX = 'MERGE_PEOPLE_';

export default {
	MUTATIONS: {
		LIST_DUPLICATED_PEOPLE_SUCCESS: MUTATION_PREFIX + 'LIST_DUPLICATED_PEOPLE_SUCCESS',
		LIST_UNIFIABLE_PEOPLE_SUCCESS: MUTATION_PREFIX + 'LIST_UNIFIABLE_PEOPLE_SUCCESS',

		LIST_DUPLICATED_PEOPLE_ERROR: MUTATION_PREFIX + 'LIST_DUPLICATED_PEOPLE_ERROR',
		LIST_UNIFIABLE_PEOPLE_ERROR: MUTATION_PREFIX + 'LIST_UNIFIABLE_PEOPLE_ERROR',

		RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
	},
	ACTIONS: {
		LIST_DUPLICATED_PEOPLE: ACTIONS_PREFIX + 'LIST_DUPLICATED_PEOPLE',
		LIST_UNIFIABLE_PEOPLE: ACTIONS_PREFIX + 'LIST_UNIFIABLE_PEOPLE',

		UNITE_PEOPLE: ACTIONS_PREFIX + 'UNITE_PEOPLE',
	},
	GETTERS: {
		DUPLICATED_PEOPLE_LIST: GETTERS_PREFIX + 'DUPLICATED_PEOPLE_LIST',
		UNIFIABLE_PEOPLE_LIST: GETTERS_PREFIX + 'UNIFIABLE_PEOPLE_LIST',
	}
}
