var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "BaseModal",
    {
      ref: "modal",
      attrs: { modalTitle: "Datei einlesen", showDefaultButtons: false },
      on: { close: _vm.close }
    },
    [
      _c(
        "div",
        [
          _vm.bFilterVisible
            ? _c(
                "div",
                [
                  _c("ComboBox", {
                    attrs: {
                      label: "Gesellschaft",
                      values: _vm.gesellschaftOptions
                    },
                    on: {
                      input: function($event) {
                        _vm.reloadComboboxes()
                        _vm.updateInterface()
                      }
                    },
                    model: {
                      value: _vm.gesellschaftId,
                      callback: function($$v) {
                        _vm.gesellschaftId = $$v
                      },
                      expression: "gesellschaftId"
                    }
                  }),
                  _c("ComboBox", {
                    attrs: { label: "Interface", values: _vm.interfaceOptions },
                    on: { input: _vm.updateInterface },
                    model: {
                      value: _vm.interfaceName,
                      callback: function($$v) {
                        _vm.interfaceName = $$v
                      },
                      expression: "interfaceName"
                    }
                  }),
                  _c("InputToggleSwitch", {
                    attrs: {
                      label: "Mit nicht aktiven Interfaces",
                      inLineLabel: ""
                    },
                    on: { input: _vm.reloadComboboxes },
                    model: {
                      value: _vm.withInactiveInterfaces,
                      callback: function($$v) {
                        _vm.withInactiveInterfaces = $$v
                      },
                      expression: "withInactiveInterfaces"
                    }
                  }),
                  _c("InputField", {
                    attrs: {
                      label: "Interface Id",
                      value: _vm.interfaceId,
                      disabled: ""
                    }
                  }),
                  _c("InputTextArea", {
                    attrs: {
                      label: "Beschreibung",
                      value: _vm.description,
                      autoGrow: "",
                      disabled: ""
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "BaseFileSelect",
            {
              attrs: {
                accept: ".csv, .txt, .xml",
                isSecondary: "",
                disabled: _vm.loading
              },
              on: { files: _vm.onFilesChange }
            },
            [_vm._v(" Durchsuchen ... ")]
          ),
          _vm._v(" " + _vm._s(_vm.file ? _vm.file.name : "") + " "),
          _c("br"),
          _c("br"),
          _c(
            "div",
            { staticStyle: { display: "flex" } },
            [
              _c(
                "BaseButton",
                {
                  attrs: { disabled: !_vm.file || _vm.loading },
                  on: { click: _vm.upload }
                },
                [_vm._v(" Datei verarbeiten ")]
              ),
              _c(
                "progress",
                {
                  staticStyle: { "flex-grow": "1" },
                  attrs: { max: "100" },
                  domProps: { value: _vm.progress }
                },
                [_vm._v(" " + _vm._s(_vm.progress) + "% ")]
              )
            ],
            1
          ),
          _vm._v(" " + _vm._s(_vm.status) + " ")
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }