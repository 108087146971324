var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CoreCard",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [_vm._v(" test header BrokerOverviewDataCard ")]
          },
          proxy: true
        },
        {
          key: "alternative",
          fn: function() {
            return [_vm._v(" test alternative ")]
          },
          proxy: true
        }
      ])
    },
    [_vm._v(" test content BrokerOverviewDataCard ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }