import { FC_CONFIG_CONTEXT } from '@/configs/fcConfig';

export const SYSTEM_ID = -1;

export const CONTEXTS = [
  FC_CONFIG_CONTEXT.MAKLER_MAKLER, 
  FC_CONFIG_CONTEXT.MAKLER_KUNDE, 
  FC_CONFIG_CONTEXT.KUNDE_KUNDE, 
];

export const MAP_CONFIG_CONTEXT_LABEL = {
  [FC_CONFIG_CONTEXT.MAKLER_MAKLER]: 'Vermittlerebene',
  [FC_CONFIG_CONTEXT.MAKLER_KUNDE]: 'Kundenebene',
  [FC_CONFIG_CONTEXT.KUNDE_KUNDE]: 'Kundensicht',
};
