import FILEUPLOAD_TYPES from './types';

export default {
  [FILEUPLOAD_TYPES.GETTERS.COLLATED_PDFS](state) {
    return state.collatedPdfs;
  },
  [FILEUPLOAD_TYPES.GETTERS.PDF_UPLOADS](state) {
    return state.pdfUploads;
  },
  [FILEUPLOAD_TYPES.GETTERS.PDF_UPLOAD_STATUS](state) {
    return state.pdfUploadStatus;
  },
  [FILEUPLOAD_TYPES.GETTERS.PDF_UPDATED_DECKBLATT](state) {
    return state.pdfUploadDeckblatt;
  },
  [FILEUPLOAD_TYPES.GETTERS.SEND_RUN](state) {
    return state.SEND_RUN;
  },
  [FILEUPLOAD_TYPES.GETTERS.RESULT_TABLE](state) {
    return state.RESULT_TABLE;
  },
  [FILEUPLOAD_TYPES.GETTERS.DECKBLATT_COMBOS](state) {
    return (kundennr) => ({ ...state.deckblattCombos?.[kundennr] || {}, });
  },
  [FILEUPLOAD_TYPES.GETTERS.COMBO_VALUES](state) {
    return ({ customerID, type, ids }) => {
      const comboValues = { ...state.comboValues || {} };
      const key = `${customerID}_${type}_${ids.join('_')}`;
      return comboValues?.[key] ||[];
    };
  },
}