<template>
    <div>
        <PageHeaderTitleNavigation
            :title="title"
            :actions="headerActions"
            @action-CREATE_ANTRAEGE="createAntraege"
        />

        <div class="row my-0" v-if="stepper">
            <div class="d-lg-none col-12">
                <div class="antrag-step__container box__container">
                <Stepper2
                    stepType="step"
                    :visible="true"
                    :selectedStepKey="stepKey"
                    :stepperService="stepper"
                    @setStep="setStepByKey"
                    ref="pieStepper"
                ></Stepper2> 
                </div>
            </div>
        </div>

        <ContentWithStepper>
            <template #stepper v-if="stepper">
                <div class="box__container">
                <VerticalStepper
                    stepType="step"
                    :visible="true"
                    :selectedStepKey="stepKey"
                    :stepperService="stepper"
                    @setStep="setStepByKey"
                    ref="verticalStepper"
                    class="responsive-stepper"
                ></VerticalStepper>
                </div>
            </template>
            <template #content>
                <router-view />
                <div class="box__container">
                    <BaseButton v-if="stepKey !== 'wertpapierauswahl'" @click="prevStep()" isSecondary>Zurück</BaseButton>
                    <BaseButton v-if="stepKey !== 'antraege'" isPrimary @click="nextStep()">Weiter</BaseButton>
            </div>
            </template>
        </ContentWithStepper>

    </div>
</template>

<script>
import Stepper2 from '@/components/stepper/Stepper2.vue';
import VerticalStepper from '@/components/vertical-stepper/VerticalStepper.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import ContentWithStepper from '@/components/core/ContentWithStepper.vue';
import { StepperMediator } from '@/components/stepper/utils';
import BaseButton from '@/components/core/BaseButton.vue';
import WERTPAPIERE_ALLE_KUNDEN_TYPES from '@/store/wertpapiereAlleKunden/types';
import { PageHeaderSimpleAction, PageHeaderDownloadLinkAction } from '@/components/core/header-title-navigation/page-header-utils';
import mixin from './wertpapiere-mixin.js';

export default {
    mixins: [mixin],
    components: {
        OptionMenu,
        PageHeaderTitleNavigation,
        BaseButton,
        Stepper2,
        VerticalStepper,
        ContentWithStepper,
    },
    mounted() {
        if (!this.$route.query.backAction) {
            this.$store.dispatch(WERTPAPIERE_ALLE_KUNDEN_TYPES.ACTIONS.GET_INIT_DATA);
        }
    },
    computed: {
        title() {
            return this.isVerkauf ? 'Wertpapierverkauf bei allen Kunden' : 'Wertpapierumschichtung bei allen Kunden';
        },
        stepKey() {
            return this.$route.path.substring(this.$route.path.lastIndexOf('/')+1);
        },
        headerActions() {
            return [
                PageHeaderSimpleAction("CREATE_ANTRAEGE", "Anträge erzeugen bzw. versenden")
                    .withDisabled(() => !this.hasActivePositions)
                    .withVisible(() => this.stepKey === 'antraege' && !this.hasAntraege),
                PageHeaderDownloadLinkAction(
                    "PDF",
                    "PDF anzeigen",
                    "",
                    "/wertpapiere_alle_kunden",
                    { tempFileId: this.tempFileId }
                )
                    .withDisabled(() => !this.tempFileId )
                    .withVisible(() => this.stepKey === 'antraege'),
            ]
        },
        stepper()  {
            return new StepperMediator([
                { stepKey: 'wertpapierauswahl', label: 'Wertpapierauswahl', success: true },
                { stepKey: 'kundenliste', label: 'Kundenliste', success: true },
                { stepKey: 'email', label: 'E-Mail Inhalt', hidden: !this.hasEmailVersand, success: true },
                { stepKey: 'antraege', label: 'Anträge', success: true },
            ])
        },
    },
    methods: {
        setStepByKey(stepKey = 'wertpapierauswahl') {
            if (this.stepKey !== stepKey) {
                this.checkAndSetStep(stepKey);
            }
        },
        checkAndSetStep(stepKey) {
            if (!this.positions?.length || this.dataHasChanged) {
                if (this.stepKey == 'wertpapierauswahl') {
                    this.listPositions();
                } else {
                    this.$router.push(`/beratung/wertpapiere-alle-kunden/${this.paramModus}/wertpapierauswahl`);
                }
            } else {
                this.$router.push(`/beratung/wertpapiere-alle-kunden/${this.paramModus}/${stepKey}`);
            }
        },
        prevStep() {
            const prevStep = this.stepper.getPreviousStep(this.stepKey);
            if (prevStep) {
                this.setStepByKey(prevStep);
            }
        },
        nextStep() {
            const nextStep = this.stepper.getNextStep(this.stepKey);
            if (nextStep) {
                this.setStepByKey(nextStep);
            }
        },
        listPositions() {
            if (!this.canListPositions) {
                this.showListPositionsWarnings();
            } else {
                this.$router.push(`/beratung/wertpapiere-alle-kunden/${this.paramModus}/kundenliste`);
            }
        },
        showListPositionsWarnings() {
            if (!this.canOpenFondsfinder) {
                this.openFondsfinderWarningModal();
            } else if (!this.isVerkauf && !this.data.tauschInIsin) {
                this.$confirmModal({
                    title: "Es fehlen Angaben",
                    message: `Bitte wählen Sie ein Wertpapier für die Umschichtung aus`,
                    labelButtonCancel: "Ok",
                    showConfirmButton: false,
                });
            }
        },
        createAntraege() {
            if (this.stepKey === 'antraege') {
                this.$store.dispatch(WERTPAPIERE_ALLE_KUNDEN_TYPES.ACTIONS.CREATE_ANTRAEGE);
            }
        },
    }
}
</script>

<style>

</style>