var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "BaseModal",
        {
          ref: "bestellungenModal",
          attrs: {
            size: "lg",
            modalTitle: "Bestellungen " + _vm.bestellungenData.bestellungName,
            labelButtonConfirm: "Bestellen "
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.onConfirmModal()
            },
            onCancelButton: function($event) {
              return _vm.onCancelModal()
            },
            onCloseButton: function($event) {
              return _vm.onCancelModal()
            }
          }
        },
        [
          _vm.bestellungenData && _vm.bestellungenData.rows
            ? [
                _c("div", [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("p", [
                        _vm._v(
                          "Soll Ihnen eine E-Mail mit einem Link zu der PDF Datei der " +
                            _vm._s(_vm.bestellungenData.bestellungName) +
                            " für folgende Kunden zugesandt werden?"
                        )
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _vm.rows && _vm.rows.length
                          ? _c("Table", {
                              attrs: {
                                tableId: "46fbd016-475e-47c5-8abf-a9e074527bb2",
                                headers: _vm.headers,
                                rows: _vm.rows,
                                rowsPerPage: 20
                              },
                              on: {
                                "action-delete": function($event) {
                                  return _vm.confirmDeleteCustomer($event.data)
                                }
                              }
                            })
                          : _vm.rows && _vm.rows.length === 0
                          ? _c("NoData")
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-12" }, [
                      _c("hr"),
                      _vm.bestellungenData.modus === "QB"
                        ? _c("p", [
                            _vm._v(
                              "Achtung, die Datei kann je nach Anzahl der Kunden sehr groß werden (pro Kunde ca. 150KB). Bei zu großen Dateien werden die PDF Dateien geteilt und Ihnen in der E-Mail mehrere Links zugesandt."
                            )
                          ])
                        : _vm._e(),
                      _vm.bestellungenData.modus === "DA"
                        ? _c("p", [
                            _vm._v(
                              "Achtung, die Datei kann je nach Anzahl der Kunden sehr groß werden (pro Kunde ca. 50KB). Bei zu großen Dateien werden die PDF Dateien geteilt und Ihnen in der E-Mail mehrere Links zugesandt."
                            )
                          ])
                        : _vm._e(),
                      _c("hr")
                    ])
                  ]),
                  _vm.bestellungenData.modus === "DA"
                    ? _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c("DatePickerField", {
                              attrs: {
                                isValueAsString: "",
                                isComponentHalfSize: "",
                                label: "Stand der Depotauszüge",
                                validateUntouched: ""
                              },
                              model: {
                                value: _vm.inputStand,
                                callback: function($$v) {
                                  _vm.inputStand = $$v
                                },
                                expression: "inputStand"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm.bestellungenData.modus === "DA"
                    ? _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _c("hr"),
                          _c("p", [
                            _vm._v(
                              "Wenn Sie ein Datum in der Vergangenheit angeben, dann wird Ihnen anstelle der aktuellen Depotauszüge historische Depotauszüge erstellt. Das Datum darf maximal 3 Jahre in der Vergangenheit liegen."
                            )
                          ]),
                          _c("hr")
                        ])
                      ])
                    : _vm._e()
                ])
              ]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }