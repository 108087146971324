var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _c("div", { staticClass: "box__title" }, [
        _vm._v("Risikostufe " + _vm._s(_vm.index))
      ]),
      _c("RisikostufeFields", {
        attrs: { title: "Vorgabe", data: _vm.defaultData, disabled: "" }
      }),
      _vm.isChangeAllowed
        ? [
            _c("hr"),
            _c("RisikostufeFields", {
              attrs: {
                title: "Eigene Einstellung (wird für Unterstruktur verwendet)",
                data: _vm.configData,
                disabled: !_vm.hasConfig
              },
              on: {
                dataChanged: function($event) {
                  return _vm.risikostufeDataChanged($event)
                }
              }
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }