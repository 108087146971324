var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.goal
      ? _c(
          "div",
          [
            _c("div", { staticClass: "dashboard-panel__widgets" }, [
              _c(
                "div",
                { staticClass: "box__container dashboard-panel-widget" },
                [
                  _c("ImageUpload", {
                    staticClass: "scan-container",
                    attrs: {
                      title: _vm.goal.title || "Mein Ziel",
                      hoverText: "Mein Ziel hier ablegen",
                      edgeCorrection: "",
                      image: _vm.image,
                      loading: _vm.loading,
                      disabled: _vm.loading,
                      config: _vm.imageUploadConfig,
                      hasDeleteButton: "",
                      photoCamera: "back",
                      photoHighQuality: "",
                      uploadButtonText: "Bild hochladen"
                    },
                    on: {
                      file: function($event) {
                        return _vm.uploadMyGoalImage($event)
                      },
                      change: function($event) {
                        return _vm.uploadMyGoalImage($event)
                      },
                      delete: _vm.deleteFile
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "box__container dashboard-panel-widget" },
                [
                  _c("MyGoalMoneyChart", {
                    attrs: { data: _vm.chartData, labels: _vm.chartLabels }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "box__container dashboard-panel-widget" },
                [
                  _c("Table", {
                    ref: "myGoalTable",
                    attrs: {
                      tableId: "dac58861-120a-4f32-a469-f55f487e86a4",
                      rowId: "name",
                      hidePagination: "",
                      headers: _vm.headers,
                      rows: _vm.rows
                    },
                    on: { "click-action": _vm.editForm },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "value",
                          fn: function(row) {
                            return [
                              _c("div", { staticClass: "font-bold" }, [
                                _vm._v(
                                  _vm._s(_vm.formatValue(row.value, row.type))
                                )
                              ])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1285989306
                    )
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "box__container dashboard-panel-widget" },
                [_c("VirtuelleDepots")],
                1
              )
            ]),
            _c(
              "BaseModal",
              {
                ref: "editFieldModal",
                attrs: {
                  modalTitle: "Meine Ziele anpassen",
                  labelButtonConfirm: "Anwenden",
                  labelButtonCancel: "Abbrechen",
                  showDefaultButtons: _vm.editFieldModalHasDefaultButtons,
                  confirmDisabled: !_vm.editFieldValid
                },
                on: {
                  onConfirmButton: function($event) {
                    return _vm.updateCalculation()
                  }
                }
              },
              [
                _vm.editedField === "startkapital"
                  ? _c("InputField", {
                      attrs: { label: "Vorhandenes Kapital", type: "currency" },
                      model: {
                        value: _vm.form.startkapital,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "startkapital", $$v)
                        },
                        expression: "form.startkapital"
                      }
                    })
                  : _vm.editedField === "zielsumme"
                  ? _c(
                      "div",
                      [
                        _c("InputField", {
                          attrs: {
                            label: "Benötigtes Kapital",
                            type: "currency"
                          },
                          model: {
                            value: _vm.form.zielsumme,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "zielsumme", $$v)
                            },
                            expression: "form.zielsumme"
                          }
                        }),
                        !_vm.editFieldValid
                          ? _c("div", { staticClass: "color-danger" }, [
                              _vm._v(
                                " " + _vm._s(_vm.zielsummeInvalidText) + " "
                              )
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm.editedField === "rendite"
                  ? _c("InputField", {
                      attrs: {
                        label: "Wertentwicklung",
                        precision: "2",
                        type: "percent"
                      },
                      model: {
                        value: _vm.form.rendite,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "rendite", $$v)
                        },
                        expression: "form.rendite"
                      }
                    })
                  : _vm.editedField === "endDate"
                  ? _c(
                      "div",
                      [
                        _c("DatePickerField", {
                          attrs: {
                            label: "Zieldatum (Ende)",
                            isValueAsString: "",
                            validateUntouched: "",
                            monthPicker: ""
                          },
                          model: {
                            value: _vm.form.endDate,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "endDate", $$v)
                            },
                            expression: "form.endDate"
                          }
                        }),
                        !_vm.editFieldValid
                          ? _c("div", { staticClass: "color-danger" }, [
                              _vm._v(
                                " Zielsdatum is nicht gültig. Bitte korrigieren. "
                              )
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm.editedField === "startDate"
                  ? _c(
                      "div",
                      [
                        _c("DatePickerField", {
                          attrs: {
                            label: "Startdatum (Beginn)",
                            isValueAsString: "",
                            validateUntouched: "",
                            monthPicker: ""
                          },
                          model: {
                            value: _vm.form.startDate,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "startDate", $$v)
                            },
                            expression: "form.startDate"
                          }
                        }),
                        !_vm.startDateValid()
                          ? _c("div", { staticClass: "color-danger" }, [
                              _vm._v(
                                " Startdatum is nicht gültig. Bitte korrigieren. "
                              )
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm.editedField === "sparrate"
                  ? _c(
                      "div",
                      [
                        _vm.isSparrateEditable
                          ? _c("InputField", {
                              attrs: {
                                label: "Sparrate pro Monat",
                                type: "currency"
                              },
                              model: {
                                value: _vm.form.sparrate,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "sparrate", $$v)
                                },
                                expression: "form.sparrate"
                              }
                            })
                          : _vm._e(),
                        !_vm.isImmobilie
                          ? _c("div", [
                              _vm.isSparrateEditable
                                ? _c("div", [
                                    _c("div", [
                                      _vm._v(
                                        "Möchten Sie, dass sich diese Änderung auf die Höhe des benötigtes Kapital oder auf das Ziel Datum auswirkt?"
                                      )
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "mt-4 layout__negative-margin--8 antrag-action-button__container"
                                      },
                                      [
                                        _c(
                                          "BaseButton",
                                          {
                                            attrs: { isSecondary: "" },
                                            on: {
                                              click: function($event) {
                                                return _vm.$refs.editFieldModal.close()
                                              }
                                            }
                                          },
                                          [_vm._v("Abbrechen")]
                                        ),
                                        _c(
                                          "BaseButton",
                                          {
                                            attrs: {
                                              disabled:
                                                _vm.sparrateButtonDisabled
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.updateCalculation(
                                                  "endDate"
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("Zieldatum (Ende)")]
                                        ),
                                        _c(
                                          "BaseButton",
                                          {
                                            attrs: {
                                              disabled:
                                                _vm.sparrateButtonDisabled
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.updateCalculation(
                                                  "zielsumme"
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("Benötigtes Kapital")]
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                : _c(
                                    "div",
                                    [
                                      _c("div", { staticClass: "mb-3" }, [
                                        _vm._v(
                                          'Sie erhalten das benötigtes Kapital ohne Sparraten mit Kapitalüberschuss. Bitte den Betrag von "benötigtes Kapital" anpassen.'
                                        )
                                      ]),
                                      _c(
                                        "BaseButton",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.$refs.editFieldModal.close()
                                            }
                                          }
                                        },
                                        [_vm._v("Ok")]
                                      )
                                    ],
                                    1
                                  )
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      : !_vm.goal
      ? _c(
          "div",
          [
            _c("GhostLoading", {
              attrs: {
                useBoxContainer: "",
                type: "block",
                config: { block: { height: 280 } }
              }
            }),
            _c("GhostLoading", {
              attrs: {
                useBoxContainer: "",
                type: "table",
                config: { table: { rows: 10 } }
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }