import TAGS_TYPES from './types';
import CORE_TYPES from '../core/types';
import axios from 'axios';

const config = {
  defaultSpinner: true
};

export default {
  async [TAGS_TYPES.ACTIONS.ADD_TAG_HTML_VORLAGEN]({ getters, dispatch }, tagHtmlVorlage) {

    const addUrl = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/tags/add_tag_html_vorlage`
    const response = await axios.post(addUrl, tagHtmlVorlage, config);
    if (response.data) {
      if (!tagHtmlVorlage.tag.id) {
        dispatch(TAGS_TYPES.ACTIONS.GET_TAGS)
      }
    }
  },

  async [TAGS_TYPES.ACTIONS.ADD_TAG_WORD_VORLAGEN]({ getters, dispatch}, tagWordVorlage) {

    const addUrl = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/tags/add_tag_word_vorlage`
    const response = await axios.post(addUrl, tagWordVorlage, config);
    if (response.data) {
      if (!tagWordVorlage.tag.id) {
        dispatch(TAGS_TYPES.ACTIONS.GET_TAGS)
      }
    }
  },

  async [TAGS_TYPES.ACTIONS.ADD_TAG]({ getters, commit }, tagPayload) {

    const addUrl = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/tags/add_tag`
    const response = await axios.post(addUrl, tagPayload, config);
    if (response.data) {
      commit(TAGS_TYPES.MUTATIONS.GET_TAGS_SUCCESS, { tags: response.data });
    }
  },

  async [TAGS_TYPES.ACTIONS.REMOVE_TAG_HTML_VORLAGEN]({ getters, dispatch}, tagHtmlVorlage) {
    const deleteUrl = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/tags/remove_tag_html_vorlage`
    const response = await axios.delete(deleteUrl, { ...config, data: tagHtmlVorlage});
    if (response.data) {
      dispatch(TAGS_TYPES.ACTIONS.GET_TAGS)
    }
  },

  async [TAGS_TYPES.ACTIONS.REMOVE_TAG_WORD_VORLAGEN]({ getters, dispatch}, tagWordVorlage) {
    const deleteUrl = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/tags/remove_tag_word_vorlage`
    const response = await axios.delete(deleteUrl, { ...config, data: tagWordVorlage});
    if (response.data) {
      dispatch(TAGS_TYPES.ACTIONS.GET_TAGS)
    }
  },

  async [TAGS_TYPES.ACTIONS.REMOVE_TAG]({ getters, commit }, tagPayload) {
    const deleteUrl = `${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/tags/remove_tag`
    const response = await axios.delete(deleteUrl, { ...config, data: tagPayload});
    if (response.data) {
      commit(TAGS_TYPES.MUTATIONS.GET_TAGS_SUCCESS, { tags: response.data });
    }
  },

  async [TAGS_TYPES.ACTIONS.GET_TAGS]({ getters, commit}) {
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/tags/get_tags`, config);
    if (response.data) {
      commit(TAGS_TYPES.MUTATIONS.GET_TAGS_SUCCESS, { tags: response.data });
    }
  },

}