export const MUTATION_PREFIX = 'KUNDEN_GESELL_NR: ';
export const ACTIONS_PREFIX = 'KUNDEN_GESELL_NR_ACTIONS_';
export const GETTERS_PREFIX = 'KUNDEN_GESELL_NR_GETTERS_';

export default {
  MUTATIONS: {
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    SET_LIST: MUTATION_PREFIX + 'SET_LIST',
    SET_COMBO_DATA: MUTATION_PREFIX + 'SET_COMBO_DATA',
    SET_DATA: MUTATION_PREFIX + 'SET_DATA',
    RESET_DATA: MUTATION_PREFIX + 'RESET_DATA',
    ADD_DATA_EDITED: MUTATION_PREFIX + 'ADD_DATA_EDITED',
    RESET_DATA_EDITED: MUTATION_PREFIX + 'RESET_DATA_EDITED',
  },
  ACTIONS: {
    FIND: ACTIONS_PREFIX + 'FIND',
    FIND_COMBO_DATA: ACTIONS_PREFIX + 'FIND_COMBO_DATA',
    GET_DATA: ACTIONS_PREFIX + 'GET_DATA',
    GET_GESELLSCHAFT_BEISPIEL_KONTONR: ACTIONS_PREFIX + 'GET_GESELLSCHAFT_BEISPIEL_KONTONR',
    SAVE_DATA: ACTIONS_PREFIX + 'SAVE_DATA',
    DELETE: ACTIONS_PREFIX + 'DELETE',
  },
  GETTERS: {
    LIST: GETTERS_PREFIX + 'LIST',
    COMBO_DATA: GETTERS_PREFIX + 'COMBO_DATA',
    DATA: GETTERS_PREFIX + 'DATA',
    DATA_EDITED: GETTERS_PREFIX + 'DATA_EDITED',
  },
}
