var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "box__title" }, [_vm._v("Kontodaten Fonds")]),
      _vm.brokerData.hatInvestmentAccess
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _vm.brokerData.hatInvestmentAccess
                ? _c("InputField", {
                    attrs: {
                      label: "Stornoreservekonto",
                      isComponentHalfSize: true,
                      disabled: true
                    },
                    on: {
                      change: function($event) {
                        return _vm.addBrokerDataEdited("brokerData")
                      }
                    },
                    model: {
                      value: _vm.brokerData.stornoreservekonto,
                      callback: function($$v) {
                        _vm.$set(_vm.brokerData, "stornoreservekonto", $$v)
                      },
                      expression: "brokerData.stornoreservekonto"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.brokerData.hatInvestmentAccess
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _vm.brokerData.hatInvestmentAccess
                ? _c("InputField", {
                    attrs: {
                      label: "Stornoreservekonto",
                      isComponentHalfSize: true,
                      disabled: true
                    },
                    on: {
                      change: function($event) {
                        return _vm.addBrokerDataEdited("brokerData")
                      }
                    },
                    model: {
                      value: _vm.brokerData.stornoreservekontoStr,
                      callback: function($$v) {
                        _vm.$set(_vm.brokerData, "stornoreservekontoStr", $$v)
                      },
                      expression: "brokerData.stornoreservekontoStr"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.brokerData.hatInvestmentAccess
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _vm.brokerData.hatInvestmentAccess
                ? _c("InputField", {
                    attrs: {
                      label: "Stornorisiko",
                      isComponentHalfSize: true,
                      disabled: true
                    },
                    on: {
                      change: function($event) {
                        return _vm.addBrokerDataEdited("brokerData")
                      }
                    },
                    model: {
                      value: _vm.brokerData.stornorisiko,
                      callback: function($$v) {
                        _vm.$set(_vm.brokerData, "stornorisiko", $$v)
                      },
                      expression: "brokerData.stornorisiko"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.brokerData.hatInvestmentAccess
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _vm.brokerData.hatInvestmentAccess
                ? _c("InputField", {
                    attrs: {
                      label: "Stornorisiko Struktur",
                      isComponentHalfSize: true,
                      disabled: true
                    },
                    on: {
                      change: function($event) {
                        return _vm.addBrokerDataEdited("brokerData")
                      }
                    },
                    model: {
                      value: _vm.brokerData.stornorisikoStr,
                      callback: function($$v) {
                        _vm.$set(_vm.brokerData, "stornorisikoStr", $$v)
                      },
                      expression: "brokerData.stornorisikoStr"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.brokerData.hatVersAccess
        ? _c("div", { staticClass: "box__title" }, [
            _vm._v("Kontodaten Versicherung")
          ])
        : _vm._e(),
      _vm.brokerData.hatVersAccess
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _vm.brokerData.hatVersAccess
                ? _c("InputField", {
                    attrs: {
                      label: "Stornoreservekonto (abgeschlossen)",
                      isComponentHalfSize: true,
                      disabled: true
                    },
                    on: {
                      change: function($event) {
                        return _vm.addBrokerDataEdited("brokerData")
                      }
                    },
                    model: {
                      value: _vm.brokerData.stornoreservekontoVers,
                      callback: function($$v) {
                        _vm.$set(_vm.brokerData, "stornoreservekontoVers", $$v)
                      },
                      expression: "brokerData.stornoreservekontoVers"
                    }
                  })
                : _vm._e(),
              _vm.brokerData.hatVersAccess
                ? _c("InputField", {
                    attrs: {
                      label: "Stornoreservekonto Struktur (abgeschlossen)",
                      isComponentHalfSize: true,
                      disabled: true
                    },
                    on: {
                      change: function($event) {
                        return _vm.addBrokerDataEdited("brokerData")
                      }
                    },
                    model: {
                      value: _vm.brokerData.stornoreservekontoVersStr,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.brokerData,
                          "stornoreservekontoVersStr",
                          $$v
                        )
                      },
                      expression: "brokerData.stornoreservekontoVersStr"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.isFK && _vm.brokerData.hatVersAccess
        ? _c("StornoreserveVersicherungen")
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }