import SSO_TYPES from './types'
import { getInitialState } from './index'
import Vue from 'vue'

export default {
    [SSO_TYPES.MUTATIONS.LOGOUT](state) {
        Object.assign(state, getInitialState())
    },
    [SSO_TYPES.MUTATIONS.LOGIN](state, { sessionId, loginName, activated, validUntil, referrer }) {
        state.sessionId = sessionId;
        state.loginName = loginName;
        state.activated = activated;
        state.sessionValidUntil = validUntil;
        state.referrer = state.referrer ? state.referrer : referrer;
    },
    [SSO_TYPES.MUTATIONS.GET_SSO_ACCOUNT_INFO](state, { fkAccounts, tokens, loginName, email, phoneNumber }) {
        Vue.set(state, 'fkAccounts', fkAccounts);
        Vue.set(state, 'ssoInvitations', tokens);
        Vue.set(state, 'loginName', loginName);
        Vue.set(state, 'email', email);
        Vue.set(state, 'phoneNumber', phoneNumber);
    },
    [SSO_TYPES.MUTATIONS.GET_FK_ACCOUNT_INFO](state, data = {}) {
        Vue.set(state, 'ssoAccounts', data?.ssoAccounts);
        Vue.set(state, 'invitations', data?.invitations);
        Vue.set(state, 'ssoAccountsAll', data?.ssoAccountsAll);
    },
    [SSO_TYPES.MUTATIONS.ACTIVATE_ACCOUNT](state) {
        state.activated = true;
    },
    [SSO_TYPES.MUTATIONS.UPDATE_PERMISSION_SUCCESS]() {
    },
    [SSO_TYPES.MUTATIONS.UPDATE_SHOW_SSO_CUSTOMER_OVERVIEW](state, data) {
        Vue.set(state, 'showSsoCustomerOverview', data?.showSsoCustomerOverview);
    },
}
