var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          title: "Einstellungen",
          actions: _vm.headerActions,
          subtitle: "Externe Zugänge einrichten"
        }
      }),
      _vm.configStep
        ? _c("div", { staticClass: "row my-0" }, [
            _c("div", { staticClass: "d-lg-none col-12" }, [
              _c(
                "div",
                { staticClass: "antrag-step__container box__container" },
                [
                  _c("Stepper2", {
                    ref: "pieStepper",
                    attrs: {
                      stepType: "step",
                      visible: true,
                      selectedStepKey: _vm.selectedStep,
                      stepperService: _vm.getStepperMediator
                    },
                    on: { setStep: _vm.setSelectedStep }
                  })
                ],
                1
              )
            ])
          ])
        : _vm._e(),
      _c("ContentWithStepper", {
        attrs: { stepperCollapsed: _vm.stepperCollapsed },
        scopedSlots: _vm._u([
          {
            key: "stepper",
            fn: function() {
              return [
                _c("ConfigVerticalStep", {
                  ref: "stepper",
                  attrs: {
                    configStep: _vm.configStep,
                    selectedStep: _vm.selectedStep,
                    configData: _vm.configData
                  },
                  on: {
                    collapsed: function($event) {
                      _vm.stepperCollapsed = $event
                    },
                    setStep: _vm.setSelectedStep
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "content",
            fn: function() {
              return [
                _c(
                  "div",
                  { key: _vm.selectedStep },
                  [
                    _c("ExterneZugaengeConfigStep", {
                      attrs: { isLoading: _vm.loading }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }