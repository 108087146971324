var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "last-customers-opened__container" },
    [
      _vm.showTitle
        ? _c("div", { staticClass: "last-customers-opened--title" }, [
            _vm._v("Zuletzt geöffnete Kunden")
          ])
        : _vm._e(),
      _vm.lastCustomersOpened.length > 0
        ? _c("Carousel", {
            staticClass: "last-customers-opened--carousel",
            attrs: {
              values: _vm.customersOpenedValues,
              options: _vm.carouselOptions
            }
          })
        : _c("NoData", { attrs: { noIcon: "" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }