import FC_CONFIG_TYPES from './types';
import CORE_TYPES from '@/store/core/types';

import FC_CONFIG, { FC_CONFIG_USER_LEVEL } from '@/configs/fcConfig.js'
import { VIEW_ROLES, ROLES } from '@/router/roles';

export default {
  [FC_CONFIG_TYPES.GETTERS.IS_FC_CONFIG_LOADING](state) {
    return (configType, configId) => state?.fcConfigLoading?.[configType]?.[configId] === true;
  },
  [FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG](state) {
    return state.fcConfig
  },
  [FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_TABLE_COLUMN](state) {
    return state.fcConfig[FC_CONFIG.TABLE_COLUMN_CONFIG] || {}
  },
  [FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_LAST_VISUALIZED_EVENT_DATE](state) {
    return state.fcConfig[FC_CONFIG.LAST_VISUALIZED_EVENT_DATE] || {}
  },
  [FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_OPTION_MENU](state) {
    return state.fcConfig[FC_CONFIG.OPTION_MENU_CONFIG] || {}
  },
  [FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_ADD_PARAMETER](state) {
    return state.fcConfig[FC_CONFIG.ADD_PARAMETER] || {}
  },
  [FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_ADVANCED_PARAMETER](state) {
    return state.fcConfig[FC_CONFIG.ADVANCED_PARAMETER] || {}
  },
  [FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_DASHBOARD_PANEL](state) {
    return state.fcConfig[FC_CONFIG.DASHBOARD_PANEL_CONFIG] || {}
  },
  [FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_CARDS_OVERVIEW](state) {
    return state.fcConfig[FC_CONFIG.CARD_OVERVIEW_CONFIG] || {}
  },
  
  [FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_BROKER_DASHBOARD](state) {
    return state.fcConfig?.[FC_CONFIG.BROKER_DASHBOARD_CONFIG]?.[FC_CONFIG.BROKER_DASHBOARD_CONFIG] || {}
  },

  [FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_COLLAPSED_TREE](state) {
    return state.fcConfig[FC_CONFIG.COLLAPSED_TREE_CONFIG] || {}
  },

  [FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_BY_USER_LEVEL](state) {
    return (configType, configId, userLevel) => state.fcConfigByUserLevel?.[configType]?.[configId]?.[userLevel] || {};
  },

  [FC_CONFIG_TYPES.GETTERS.IS_CONFIG_BY_USER_LEVEL_LOADED](state) {
    return (config) => {
      const { configType, configId, userLevel} = config
      return configType in state.fcConfigByUserLevel 
        && configId in state.fcConfigByUserLevel[configType] 
        && userLevel in state.fcConfigByUserLevel[configType][configId];
    }
  },

  [FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_ALLOW_CONFIG_GLOBAL](state, getters) {
    const hasRoles = getters[CORE_TYPES.GETTERS.HAS_ROLES];
    return hasRoles([
      [VIEW_ROLES.VIEW_CUSTOMER, ROLES.ALLOW_CONFIG_FC_CONFIG_GLOBAL],
      [VIEW_ROLES.VIEW_BROKER, ROLES.ALLOW_CONFIG_FC_CONFIG_GLOBAL]
    ]);
  },
  [FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_USER_LEVEL_VALUES](state, getters) {
    const hasRoles = getters[CORE_TYPES.GETTERS.HAS_ROLES];

    const allowConfigGlobal = getters[FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_ALLOW_CONFIG_GLOBAL];

    const values = [];

    if(allowConfigGlobal) {
      values.push({ value: FC_CONFIG_USER_LEVEL.MAKLER_MASTER_STRUKTUR, label: 'global' });
    }

    if(hasRoles([[VIEW_ROLES.VIEW_BROKER_AS_INTERN], [VIEW_ROLES.VIEW_BROKER, ROLES.IS_MAKLERZUGANG], [VIEW_ROLES.VIEW_BROKER, ROLES.IS_BYPASS_SLASH]])) {
      values.push({ value: FC_CONFIG_USER_LEVEL.MAKLER, label: 'dieser Makler' });
    } else if(hasRoles([[VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS], [ROLES.IS_KUNDENZUGANG], [VIEW_ROLES.VIEW_CUSTOMER, ROLES.IS_BYPASS_SLASH]])) {
      values.push({ value: FC_CONFIG_USER_LEVEL.KUNDE, label: 'für diesen Kunden' });
      values.push({ value: FC_CONFIG_USER_LEVEL.MAKLER, label: 'für alle Kunden als Standard' });
    }

    return values;
  },
  [FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_USER_LEVEL_DEFAULT](state, getters) {
    const hasRoles = getters[CORE_TYPES.GETTERS.HAS_ROLES];

    if(hasRoles([VIEW_ROLES.VIEW_INTERN])) {
      return FC_CONFIG_USER_LEVEL.INTERN;
    } else if(hasRoles([[VIEW_ROLES.VIEW_BROKER], [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS], [ROLES.IS_KUNDENZUGANG], [VIEW_ROLES.VIEW_CUSTOMER, ROLES.IS_BYPASS_SLASH]])) {
      return FC_CONFIG_USER_LEVEL.MAKLER;
    } else {
      return FC_CONFIG_USER_LEVEL.KUNDE;
    }
  },
  [FC_CONFIG_TYPES.GETTERS.IS_CONFIGURATION_READ_ONLY](state, getters) {
    const hasRoles = getters[CORE_TYPES.GETTERS.HAS_ROLES];
    return hasRoles([ROLES.IS_CONFIGURATION_READ_ONLY]);
  },
  [FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_RESIZABLE_MODAL](state) {
    return state.fcConfig[FC_CONFIG.RESIZABLE_MODAL_CONFIG] || {}
  },  
}
