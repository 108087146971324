<template>
    <div>
        <PageHeaderTitleNavigation title="Eingegangene Dokumente" />
        <BaseFilter filterId="c44d470b-fa6d-47ad-b9e6-3e90e866c10b" :metadata="searchMenu"
            :defaultOptions="defaultOptionsData" :configFilter="configFilter" @onFilter="search" :isCustomerSearch="false"
            immidiateSearch showSaveButton
            isCache/>
        <div>
            <GhostLoading v-if="loading" type="table" :title="tableTitle" :config="{ table: { rows: 6 } }" />
            <Table v-else-if="rows.length" :title="tableTitle" tableId="7519921b-de14-431a-9fc0-e8c61413c250"
                :headers="headers" :rows="rows" rowId="faxEingangID" :rowsPerPage="rowsPerPage"
                @click-bezeichnung="openDokumentPruefung($event)"
                @action-OPENLEGIEXTRA="(row) => openDokumentPruefung(row, true)">
            </Table>
            <NoData v-else :title="tableTitle" />
        </div>
    </div>
</template>
<script>

import {
    PhCheck,
    PhWarning,
    PhX,
} from "phosphor-vue";
import EINGEGANGENE_DOKUMENTE_TYPES from '@/store/eingegangeneDokumente/types';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import Table from "@/components/table2/Table.vue";
import { DateColumn, TextColumn } from "@/components/table2/table_util.js";
import { mapGetters } from 'vuex';
import NoData from "@/components/core/NoData.vue";
import BaseFilter from '@/components/core/BaseFilter.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import EditCommentModal from "./EditCommentModal.vue";
import actions from "@/store/http-requests/actions";

export default {
    components: {
        PhCheck,
        PhWarning,
        PhX,
        Table,
        NoData,
        OptionMenu,
        PageHeaderTitleNavigation,
        BaseFilter,
        GhostLoading,
        EditCommentModal
    },
    data() {
        return {
            loading: false,
            headers: {
                lockedLeft: [
                    TextColumn('bezeichnung', 'Bezeichnung')
                        .makeLink()
                        .makeAlwaysVisible(),
                ],
                center: [
                    TextColumn('kunde', 'Kunde'),
                    TextColumn('makler', 'Makler'),
                    DateColumn('dateEinreichung', 'Empfangen am'),
                    DateColumn('dateGeaendert', 'Geändert am'),
                    TextColumn('bemerkung', 'Bemerkung'),
                ],
            },
            rowsPerPage: 25,
            ICON_SIZE: 24,
        };
    },
    computed: {
        ...mapGetters({
            eingegangeneDokumenteList: EINGEGANGENE_DOKUMENTE_TYPES.GETTERS.EINGEGANGENE_DOKUMENTE, //FinanzAdmin data (Eingegangene Dokumente)
        }),
        tableTitle() {
            return 'Eingegangene Dokumente';
        },
        rows() {
            //handle mappenID -1 seperately below, as this is the catch all case for eingegangene Dokumente without
            let rows = Object.keys(this.eingegangeneDokumenteList).filter(mappenID => mappenID >= 0).map((mappenID) => {
                var actions = [];
                var docs = this.eingegangeneDokumenteList[mappenID];
                return {
                    bezeichnung: `Beratungsmappe ${mappenID}`,
                    kunde: docs[0].kunde,
                    makler: docs[0].makler,
                    dateEinreichung: Math.max(...docs.map(doc => doc.dateEinreichung)), //use latest date of docs within mappe
                    dateGeaendert: Math.max(...docs.map(doc => doc.dateGeaendert)), //use latest date of docs within mappe
                    docs: docs,
                    actions: actions,
                    mappenID: mappenID,
                    bemerkung: docs.map(doc => doc.bemerkung).filter(bemerkung => !!bemerkung).join(' | ')
                }
            });
            if (this.eingegangeneDokumenteList[-1]) {
                rows.push(...this.eingegangeneDokumenteList[-1].map(doc => {
                    return {
                        ...doc,
                        actions: actions,
                        docs: [doc]
                    };
                }
                ));
            }
            return rows;
        },
        searchMenu() {
            return [
                {
                    type: 'group',
                    key: 'allgemein',
                    label: 'Allgemein',
                    menuItems: [
                        {
                            type: 'text',
                            label: 'Kundenname / -nummer',
                            key: 'kunde',
                        },
                        {
                            type: 'text',
                            label: 'Maklername / -nummer',
                            key: 'makler',
                        },
                    ]
                },
                {
                    type: 'group',
                    key: 'dateCreation',
                    label: 'in Zeitraum erstellt',
                    menuItems: [
                        {
                            type: 'text',
                            label: 'Eingegangene Dokumente der letzten Tage',
                            key: 'DATUM_LETZTE_TAGE',
                        },
                        {
                            type: 'dateRange',
                            label: 'Eingegangene Dokumente im angegebenen Zeitraum',
                            key: 'ZEITRAUM',
                        },
                        {
                            type: 'default',
                            label: 'Eingegangene Dokumente seit Beginn',
                            key: 'DATUM_SEITBEGINN',
                        },
                    ]
                },
                {
                    type: 'group',
                    key: 'dateUpdated',
                    label: 'in Zeitraum bearbeitet',
                    menuItems: [
                        {
                            type: 'text',
                            label: 'Eingegangene Dokumente der letzten Tage',
                            key: 'DATUM_LETZTE_TAGE',
                        },
                        {
                            type: 'dateRange',
                            label: 'Eingegangene Dokumente im angegebenen Zeitraum',
                            key: 'ZEITRAUM',
                        },
                        {
                            type: 'default',
                            label: 'Eingegangene Dokumente seit Beginn',
                            key: 'DATUM_SEITBEGINN',
                        },
                    ]
                },
                {
                    type: 'group',
                    key: 'pruefungsstatus',
                    label: 'Prüfungsstatus',
                    menuItems: [
                        {
                            type: 'default',
                            label: 'Gestoppt',
                            key: 'GESTOPPT',
                        },
                        {
                            type: 'default',
                            label: 'Abgelehnt',
                            key: 'ABGELEHNT',
                        },
                        {
                            type: 'default',
                            label: 'Bestätigt',
                            key: 'BESTAETIGT',
                        },
                    ]
                },
            ]
        },
        defaultOptionsData() {
            return {
                DATUM_LETZTE_TAGE: {
                    value: '7',
                },
                GESTOPPT: {
                    value: 'GESTOPPT',
                }

            };
        },
        configFilter() {
            return {
                defaultSearchInputKeys: ['dateCreation', 'pruefungsstatus'],
                keepFiltersStateBeforeRoutingChange: false,
                defaultFiltersNum: 2,
                noResetOnDefaultSearchInputExit: false,
            }
        },
    },
    beforeRouteLeave(to, from, next) {
        this.$addBreadcrumb({
            label: "zurück zu den eingegangenen Dokumenten",
            to,
            from,
        });

        next();
    },
    mounted() {

    },
    methods: {
        search(filterParams) {
            this.loading = true;
            const queryParams = {};
            filterParams.forEach(param => {
                if (param.group === 'pruefungsstatus') {
                    queryParams[param.group] = param.key;
                } else if (param.group === 'dateCreation' || param.group === 'dateUpdated') {
                    if (param.key === 'ZEITRAUM') {
                        queryParams[param.group + 'From'] = this.dateToTimestamp(param.value[0].value);
                        queryParams[param.group + 'To'] = this.dateToTimestamp(param.value[1].value);
                    } else if (param.key === 'DATUM_LETZTE_TAGE') {
                        const d = new Date();
                        d.setDate(d.getDate() - param.value);
                        const nDaysAgo = d.getTime();
                        queryParams[param.group + 'From'] = nDaysAgo;
                    } //SEITBEGINN doesnt need a param as backend defaults to having no time filter
                } else {
                    queryParams[param.key] = param.value;
                }
            })
            this.$store.dispatch(EINGEGANGENE_DOKUMENTE_TYPES.ACTIONS.GET_EINGEGANGENE_DOKUMENTE, queryParams).finally(() => { this.loading = false; });
        },
        dateToTimestamp(dateString) {
            var dateParser = /(\d{2}).(\d{2}).(\d{4})/;
            var match = dateString.match(dateParser);
            return new Date(
                match[3],  // year
                match[2] - 1,  // monthIndex
                match[1],  // day
            ).getTime();
        },
        getIconColor(status) {
            if (!status) {
                return 'color-text';
            }
            switch (status.toUpperCase()) {
                case 'AUSSTEHEND':
                case 'NIE':
                    return 'color-warning';
                case 'JA':
                case 'ANGENOMMEN':
                    return 'color-success';
                case 'FEHLER':
                case 'ABGELEHNT':
                    return 'color-danger';

            }
        },
        openDokumentPruefung(row, isOpenlegiSeperate = false) {
            const queryParams = { legiSeperate: isOpenlegiSeperate, mappenID: row.mappenID };
            const eingangIDs = row.docs.map(doc => doc.faxEingangID);
            this.$router.push({ path: `/intern/eingegangene-dokumente/faxeingang/${eingangIDs}`, query: queryParams });
        },
    }
}
</script>