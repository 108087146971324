<template>
    <div>
        <FlexibleList :rows="customDocuments" showThirdRow :linkInFirstRow="isEditable" @onRowClick="onRowClick">
      
            <template #title="row">
                <span>{{ row.name }}</span>
            </template>

            <template #value="row">
                {{ row.erstellt_am || "./." }}
            </template>

            <template #thirdRow="row">
                {{ row.bezeichnung }}
            </template>      
  
        </FlexibleList>

    </div>
</template>

<script>
import dayjs from 'dayjs';

import { mapGetters } from 'vuex';
import OTHER_PRODUCTS_TYPES from '@/store/otherProducts/types';
import FlexibleList from "@/components/flexibleList/FlexibleList.vue";

export default {
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      sprodId: OTHER_PRODUCTS_TYPES.GETTERS.SELECTED_SPROD_ID,
      combos: OTHER_PRODUCTS_TYPES.GETTERS.COMBOS,
      otherProduct: OTHER_PRODUCTS_TYPES.GETTERS.OTHER_PRODUCT,
      documents: OTHER_PRODUCTS_TYPES.GETTERS.DOCUMENTS,
    }),
    customDocuments() {
      return this.documents.map(d => ({
        ...d,
        name: d?.filename,
        bezeichnung: d?.description,
        bemerkung: d?.comment,
        erstellt_am: dayjs(d?.dateStand).format('DD.MM.YYYY'),
      }))
    },
    nodeId() {
      return this.combos?.nodeId || '';
    },
    fileId() {
      return this.combos?.fileId || '';
    },
    uploadInputsConfig() {
      return {
        hidden: ['input_sprod_id'],
      };
    },
    isEditable() {
        return true;
    },
  },
  methods: {
    onRowClick(whatRow) {
      //alert(JSON.stringify(whatRow));
      // Edit window must be implemented for the broker perspective in the app view
    },
    async findDocuments() {
      await this.$store.dispatch(OTHER_PRODUCTS_TYPES.ACTIONS.FIND_DOCUMENTS);
    },
  },
  async mounted() {
    try {
      this.loading = true;
      await this.findDocuments();
    } finally {
      this.loading = false;
    }
  },
  components: {
    FlexibleList
  },
}
</script>