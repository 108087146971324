var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        staticClass: "setup-email-texts--page-header-title-navigation",
        attrs: {
          title: "Einstellungen E-Mail-Texte",
          actions: _vm.headerActions
        },
        on: {
          "action-DISCARD": function($event) {
            return _vm.resetChanges()
          }
        }
      }),
      _c("div", { key: _vm.selectedNodeId }, [
        _c(
          "div",
          { staticClass: "box__container" },
          [
            _c("ContentWithSidebar", {
              attrs: { mobileContent: _vm.mobileContentActive },
              on: {
                mobileContentChange: function($event) {
                  return _vm.handleMobileContentChange()
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "sidebar",
                    fn: function() {
                      return [
                        _vm.templatesTree &&
                        _vm.templatesTree.nodes &&
                        _vm.templatesTree.nodes.length
                          ? _c("Tree", {
                              staticClass: "explorer__tree_nodes",
                              attrs: {
                                items: _vm.templatesTree.nodes,
                                childrenKey: "nodes",
                                idKey: "nodeId",
                                itemSelectedId: _vm.selectedNodeId,
                                uniqueId: "dbac7af8-82eb-11ee-b962-0242ac120002"
                              },
                              on: {
                                itemSelect: function($event) {
                                  return _vm.selectNode($event)
                                }
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "explorer__tree_node_content"
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "explorer__tree_node_label"
                                              },
                                              [_vm._v(_vm._s(item.label))]
                                            ),
                                            item.numberOfRecords
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "explorer__tree_node_total"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.numberOfRecords
                                                      )
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2868835845
                              )
                            })
                          : _vm.loadingTemplatesTree
                          ? _c("GhostLoading", {
                              staticClass: "mt-2",
                              attrs: {
                                type: "block",
                                config: { block: { height: 24 } },
                                repeat: 10
                              }
                            })
                          : _c("div", [_vm._v("Keine Daten")])
                      ]
                    },
                    proxy: true
                  },
                  _vm.isLoading
                    ? {
                        key: "content",
                        fn: function() {
                          return [
                            _c("GhostLoading", { attrs: { type: "input" } }),
                            _c("GhostLoading", {
                              attrs: {
                                type: "block",
                                config: { block: { height: 250 } }
                              }
                            })
                          ]
                        },
                        proxy: true
                      }
                    : {
                        key: "content",
                        fn: function() {
                          return _vm._l(_vm.templates, function(template, i) {
                            return _c(
                              "div",
                              { key: i, staticClass: "mb-4" },
                              [
                                _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(template.labelTop)
                                  }
                                }),
                                !_vm.isStandardChanged[i]
                                  ? [
                                      template.withSubject
                                        ? _c("InputField", {
                                            on: {
                                              change: function($event) {
                                                return _vm.dataChanged()
                                              }
                                            },
                                            model: {
                                              value: _vm.subjectStandard[i],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.subjectStandard,
                                                  i,
                                                  $$v
                                                )
                                              },
                                              expression: "subjectStandard[i]"
                                            }
                                          })
                                        : _vm._e()
                                    ]
                                  : [
                                      template.withSubject
                                        ? _c("InputField", {
                                            on: {
                                              change: function($event) {
                                                return _vm.dataChanged()
                                              }
                                            },
                                            model: {
                                              value: _vm.subject[i],
                                              callback: function($$v) {
                                                _vm.$set(_vm.subject, i, $$v)
                                              },
                                              expression: "subject[i]"
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                !template.readonly
                                  ? _c("InputToggleSwitch", {
                                      attrs: {
                                        label:
                                          template.template.templateId !==
                                          "RechtlicherFusstext"
                                            ? "Eigenen Text verwenden"
                                            : "Es sind keine Angaben erforderlich",
                                        inLineLabel: ""
                                      },
                                      on: {
                                        input: function($event) {
                                          _vm.getStandardTemplate(
                                            $event,
                                            template.template.templateId,
                                            template.template.nodeId,
                                            i
                                          )
                                          _vm.dataChanged()
                                        }
                                      },
                                      model: {
                                        value: _vm.isStandardChanged[i],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.isStandardChanged,
                                            i,
                                            $$v
                                          )
                                        },
                                        expression: "isStandardChanged[i]"
                                      }
                                    })
                                  : _vm._e(),
                                template.template.templateId ===
                                "RechtlicherFusstext"
                                  ? _c(
                                      "div",
                                      [
                                        !_vm.isStandardChanged[i]
                                          ? [
                                              !template.textOnly
                                                ? _c("TemplateHtmlEditor", {
                                                    attrs: {
                                                      placeholders:
                                                        template.placeholders ||
                                                        {}
                                                    },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.dataChanged()
                                                      }
                                                    },
                                                    model: {
                                                      value: _vm.html[i],
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.html,
                                                          i,
                                                          $$v
                                                        )
                                                      },
                                                      expression: "html[i]"
                                                    }
                                                  })
                                                : _c("InputTextArea", {
                                                    attrs: { rows: 4 },
                                                    on: {
                                                      change: function($event) {
                                                        return _vm.dataChanged()
                                                      }
                                                    },
                                                    model: {
                                                      value: _vm.html[i],
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.html,
                                                          i,
                                                          $$v
                                                        )
                                                      },
                                                      expression: "html[i]"
                                                    }
                                                  })
                                            ]
                                          : [
                                              _c("div", {
                                                staticClass: "fc-textarea",
                                                class: {
                                                  "template-default-text":
                                                    template.textOnly
                                                },
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.htmlStandard[i]
                                                  )
                                                }
                                              })
                                            ]
                                      ],
                                      2
                                    )
                                  : [
                                      _c(
                                        "div",
                                        [
                                          _vm.isStandardChanged[i]
                                            ? [
                                                !template.textOnly
                                                  ? _c("TemplateHtmlEditor", {
                                                      attrs: {
                                                        placeholders:
                                                          template.placeholders ||
                                                          {}
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.dataChanged()
                                                        }
                                                      },
                                                      model: {
                                                        value: _vm.html[i],
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.html,
                                                            i,
                                                            $$v
                                                          )
                                                        },
                                                        expression: "html[i]"
                                                      }
                                                    })
                                                  : _c("InputTextArea", {
                                                      attrs: { rows: 4 },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.dataChanged()
                                                        }
                                                      },
                                                      model: {
                                                        value: _vm.html[i],
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.html,
                                                            i,
                                                            $$v
                                                          )
                                                        },
                                                        expression: "html[i]"
                                                      }
                                                    })
                                              ]
                                            : [
                                                _c("div", {
                                                  staticClass: "fc-textarea",
                                                  class: {
                                                    "template-default-text":
                                                      template.textOnly
                                                  },
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.htmlStandard[i]
                                                    )
                                                  }
                                                })
                                              ]
                                        ],
                                        2
                                      )
                                    ],
                                _c("div", {
                                  domProps: {
                                    innerHTML: _vm._s(template.labelBottom)
                                  }
                                })
                              ],
                              2
                            )
                          })
                        },
                        proxy: true
                      }
                ],
                null,
                true
              )
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }