<template>
    <a v-if="canCall" @click="tapiCall" >{{ phoneNumber }}</a>
    <span v-else>{{ phoneNumber }}</span>        
</template>
<script>
import TAPI from '@/store/tapi/types';
import { mapGetters } from "vuex";

export default {
    exportAsString(props) {
        return props.row[props.column] || ''
    },
    props: {
        column: {
            type: String,
        },
        row: {
            type: Object,
        },
       userIdColumn: {
            type: String,
        },
       userType: {
            type: String,
        },
    },
    computed: {
        ...mapGetters({
            canCall: TAPI.GETTERS.CAN_OUT_CALL,
        }),
        phoneNumber () {
            return this.row[this.column] || ''
        },
        userId() {
            return this.row[this.userIdColumn]
        },
        callTapi(){
            this.$store.dispatch(TAPI.ACTIONS.OPEN_CALL_OUT_DIALOG, {userType: this.userType ,userId: this.userId, phoneNumber: this.phoneNumber})     
        },
    },
    methods: {

    },
}
</script>