<template>
  <div>
    <PageHeaderTitleNavigation :title="title"  />

    <BaseFilter ref="filter"
        :title="title2+'-Filter'"
        filterId="Abrechnungen"
        :configFilter="filterConfig"
        :metadata="filterOptionen"
        :defaultOptions="defaultOptions"
        showSaveButton
        :immidiateSearch="false"
        @onFilter="onFilter"
        @changeCombobox="chgComboFilter"
    />

    <div class="box__container" >
      <Table v-if="rows.length" 
        :title=" 'Ausgewählte '+title2+' exportieren'"
        :headers="headers"
        :rows="rows"
        :rowsPerPage="10"
        :headerActions="headerActions"
        :enableToggleHorizontalScrollingConfig="item=='buchungspositionen'"
        v-model="selected"
        :exportConfig="exportConfig"
        @headerAction-SEND="doAction('email')"
        @headerAction-DOWNLOAD="doAction('download')"
      >
      </Table>
      <GhostLoading v-else-if="loading" type="table" useBoxContainer :title="title2" />
      <NoData v-else noIcon content="Keine Buchungspositionen vorhanden" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ABRECHNUNG_TYPES from '@/store/abrechnungen/types';
import CORE_TYPES from "@/store/core/types";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import BaseFilter from "@/components/core/BaseFilter.vue";
import Table from "@/components/table2/Table.vue";
import {TextColumn, DateColumn, CurrencyColumn, NumberColumn} from "@/components/table2/table_util.js";
import dayjs from 'dayjs';
import NoData from '@/components/core/NoData.vue';
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';
import { viewDocument } from '@/components/core/download/DownloadLink.vue';
import { base64ToArrayBuffer } from '@/helpers/download-helper.js';
import {formatNumber} from '@/helpers/number-formatter.js';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';

export default {
  components: {
    BaseFilter, Table, OptionMenu, PageHeaderTitleNavigation, NoData, GhostLoading,
  },
  props: {
  },
  data: function() {
    return {
      filterConfig: {
        checkDefaultSearchInput: false,
        cbDefSearchInput: null,
        hideFirstColumn: true,
        filterZurucksetzen: null,
        noResetOnDefaultSearchInputExit: true,
      },
      item: ',',
      rows: [],
      selected: [],
      headers: {
        lockedLeft: [TextColumn("rech", "Abrechnungen")],
        center: [TextColumn("typ", "Abrechnungstyp")],
        lockedRight: [],
      },
      buchungsheader: [],
      buchungstitel: '',
      rechtyp: 2,
      loading: false,
    }
  },
  mounted() {
    this.item = this.$route.path.replace(/^.*\//, '');
    this.$store.dispatch(ABRECHNUNG_TYPES.ACTIONS.OPTIONS_BUCHUNGEN )
  },
  computed: {
    ...mapGetters({
      form: ABRECHNUNG_TYPES.GETTERS.FORM,
      options: ABRECHNUNG_TYPES.GETTERS.OPTIONS,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      userID: CORE_TYPES.GETTERS.GET_USER_ID,
    }),
    gesellschaft() {
      if (this.options[0] == 2 && this.rechtyp < this.options.length) {
        return this.options[this.rechtyp].gesellschaft
      }
      return [];
    },
    courtageart() {
      if (this.options[0] == 2 && this.rechtyp < this.options.length) {
        return this.options[this.rechtyp].courtageart
      }
      return [];
    },
    filterOptionen() {
      if (this.item == 'abrechnungsliste' ) {
        return [
          {
            type: 'group',
            key: 'allgemein',
            label: 'Allgemein',
            menuItems: [
              {
                type: 'dateRange',
                label: 'Zeitraum',
                key: 'zeitraum',
                emptyDenied: true,
              },
              {
                type: 'combobox',
                label: 'Rechnungstyp',
                key: 'rechtyp',
                comboOptions: [{label: 'Fonds', value: 'F'}, {label: 'Versicherung', value: 'V'}],
                emptyDenied: true,
              },
            ],
          },
        ]
      }
      const comboOptions = [
        {label: 'Wertpapiere', value: 1}, 
        {label: 'Versicherungen', value: 2}, 
        {label: 'Kredite', value: 3},
        {label: 'Sonstige Produkte', value: 4}, 
        {label: 'zus. Abrechnung', value: 5}
      ]
      const menuItems = [
        {
          type: 'dateRange',
          label: 'Abrechnungszeitraum',
          key: 'zeitraum',
          emptyDenied: true,
        },
        {
          type: 'combobox',
          label: 'Buchungstyp',
          key: 'rechtyp',
          comboOptions,
          emptyDenied: true,
        }
      ];
      if (this.rechtyp !== 5) {
        menuItems.push(
          {
            type: 'text',
            label: 'Kundenname',
            key: 'kname',
          },
          {
            type: 'text',
            label: 'Kundenvorname',
            key: 'kvorname',
          },
          {
            type: 'text',
            label: 'Original Maklernr',
            key: 'orig_mnr',
          },
          {
            type: 'combobox',
            label: (this.rechtyp == 2 ? 'Versicherungsgesellschaft' : 'Gesellschaft'),
            key: 'gesell',
            comboOptions: this.gesellschaft,
            emptyDenied: true,
          },
          {
            type: 'combobox',
            label: 'Courtageart',
            key: 'c_art',
            comboOptions: this.courtageart,
            emptyDenied: true,
          },
        );
        if (this.rechtyp == 2) {
          menuItems.push(
            {
              type: 'text',
              label: 'Pollizzennr',
              key: 'polnr',
            },
            {
              type: 'combobox',
              label: 'Stornoreserve',
              key: 'sto_res',
              comboOptions: [
                {label: 'größer als 0,00 €', value: '>'},
                {label: 'genau 0,00 €', value: '='},
                {label: 'kleiner als 0,00 €', value: '<'}
              ],
              emptyDenied: true,
            },
          );
        }
      }
      return [
        {
          type: 'group',
          key: 'allgemein',
          label: 'Allgemein',
          menuItems,
        },
      ]
    },
    defaultOptions() {
      const end = new Date();
      let start = new Date(end.getFullYear(), 0, 1);
      switch (end.getMonth()) {
        case 0: 
          start = '01.10.' + (end.getFullYear() - 1);
          break;
        case 10: case 11:
          start = '01.10.' + end.getFullYear();
          break;
        case 1: case 2: case 3:
          start = '01.01.' + end.getFullYear();
          break;
        case 4: case 5: case 6:
          start = '01.04.' + end.getFullYear();
          break;
        case 7: case 8: case 9:
          start = '01.07.' + end.getFullYear();
          break;
        default:
          break;
      }
      return {
        zeitraum: {
          value: [{
            key: "min",
            value: start,
          },
          {
            key: "max",
            value: dayjs(end).format('DD.MM.YYYY'),
          }],
        },
        rechtyp: {value: (this.item == 'abrechnungsliste' ? 'F' : this.rechtyp)}
      }
    },
    title() {
      return this.item == 'abrechnungsliste' ? 'Mehrere Abrechnungen versenden oder herunterladen' 
          : 'Buchungspositionen exportieren';
    },
    title2() {
      return this.item == 'abrechnungsliste' ? 'Abrechnungen' : 'Buchungspositionen';
    },
    rechnungen() {
      let result = [];
      const prefix = 'Abrechnung_' + this.userID + '_';
      if (this.form?.combos?.abrechnungsdatum) {
        result = this.form.combos.abrechnungsdatum.map( rd => ({
          rech: prefix + rd.value.slice(6) + '_' +rd.value.slice(3,5) + '_' +rd.value.slice(0,2),
          typ: rd.label.slice(rd.label.length - 1) == 'F' ? 'Fonds' : 'Versicherung',
          time: rd.time,
          datum: rd.value
        }))
      } // Fonds Versicherung
      return result;
    },
    headerActions() {
      if (this.item == 'abrechnungsliste') {
        return [
          PageHeaderSimpleAction('DOWNLOAD', 'Abrechnungen herunterladen').withDisabled(() =>this.selected.length == 0),
          PageHeaderSimpleAction('SEND', 'E-Mail versenden').withDisabled(() =>this.selected.length == 0)
        ];
      } else {
        // return [
        //   PageHeaderSimpleAction('DOWNLOAD', 'Buchungspositionen herunterladen').withDisabled(() =>this.selected.length == 0),
        // ];
        return [];
      }
    },
    exportConfig() { 
      if (this.item == 'abrechnungsliste' || !this.selected.length) {
        return null;
      }
      return {
        pdf: true, 
        xls: true, 
        name: 'Buchungen_' + this.userID,
        title: this.buchungstitel,
        dispatch: this.exportAsFile,
      }
    },
  },
  watch: {
    
  },
  methods: {
    chgComboFilter(row, key, value) {
      if (key == 'rechtyp' && this.rechtyp != value && this.item == 'buchungspositionen') {
        if (this.$refs.filter?.$data?.filterRows) {
          this.$refs.filter.$data.filterRows = this.$refs.filter.$data.filterRows
          .filter(row =>  (row.secondaryKey == 'zeitraum' || row.secondaryKey == 'rechtyp'))
        }
        this.rechtyp = value;
      }
    },
    onFilter(filter) {
      let ix = filter.findIndex( f => f.key == 'zeitraum');
      if (ix == -1) {
        return;
      }
      let von = filter[ix].value[0].value, parts;
      let bis = filter[ix].value[1].value;
      ix = filter.findIndex( f => f.key == 'rechtyp');
      if (ix == -1) {
        return;
      }
      let typ = filter[ix].value;
      if (this.item == 'abrechnungsliste') {
        if (typeof von == 'string') {
          parts = von.split('.').map(p => parseInt(p, 10));
          von = new Date(parts[2], parts[1] - 1, parts[0]);
        }
        if (typeof bis == 'string') {
          parts = bis.split('.').map(p => parseInt(p, 10));
          bis = new Date(parts[2], parts[1] - 1, parts[0]);
        }
        this.rows = this.rechnungen.filter (item => item.time >= von.getTime() && item.time <= bis.getTime() && (!typ || typ==item.typ.charAt(0)))
        this.selected= this.rows;
      } else {
        this.loading = true;
        this.rows = [];
        this.selected= [];

        const params = {
          useStruktur: false,
          typ,
          datumListe: [von, bis],
          action: 'buchungspositionen'
        }
        const keys = ' kname kvorname polnr orig_mnr gesell c_art ' +(this.rechtyp == 2 ? 'polnr sto_res ' : '' )
        filter.forEach(f => {
          if (f.value && (keys.includes(' ' + f.key + ' ') )) {
            params[f.key] = f.value;
          }
        })
        this.$store.dispatch(ABRECHNUNG_TYPES.ACTIONS.BUCHUNGEN, params)
        .then((resp) => {
          if ( resp.data.headers ){
            const headers = {
              lockedLeft: [DateColumn("abrechnungsdatum", "Abrechnungsdatum")],
              center: [],
              lockedRight: [],
            }
            let col;
            this.buchungstitel = 'Buchungspositionen ' + this.userID + ' von '+von+' bis '+bis,
            this.buchungsheader = [resp.data.headers[0]];
            for (let k = 1; k < resp.data.headers.length; k++) {
              col = resp.data.headers[k];
              if (col[0].indexOf('datum') !== -1) {
                headers.center.push(DateColumn(col[0], col[1]));
              } else if (col[2] == 'n') {
                if (col[0].indexOf('summe') !== -1 || col[0].includes('preis') || col[0].includes('mwst') || col[0] == 'stornoreserve' ) {
                  headers.center.push(CurrencyColumn(col[0], col[1]));
                  col[2] = 'e';
                } else {
                  headers.center.push(NumberColumn(col[0], col[1], 2));
                }
              } else {
                headers.center.push(TextColumn(col[0], col[1]));
              }
              this.buchungsheader.push(col);
            }
            this.headers = headers;
            this.rows = resp.data.rows;
            this.selected= this.rows;
          }
          this.loading = false;
        });
      }
    },
    async doAction(arg) {
      if (this.item == 'abrechnungsliste') {
        const params = {
          [arg]: true,
          details: true,
          includeSummarypage: true,
          datumListe: this.selected.map(s => s.datum),
          action: arg
        }
        const response = await this.$store.dispatch(ABRECHNUNG_TYPES.ACTIONS.PDF_LISTE, params);
        if (response.data?.pdf) {
          const file = base64ToArrayBuffer(response.data.pdf);
          viewDocument({
            data: file,
            filename: 'Abrechnungen_' + this.userID + '.pdf',
            contentType: 'application/pdf',
          }, true);
        }
        if (response.data?.versendet) {
        }
      } else {
        // const params = {
        //   useStruktur: false,
        //   datumListe: this.selected.map(s => s.datum),
        //   action: arg
        // }
        // const response = await this.$store.dispatch(ABRECHNUNG_TYPES.ACTIONS.BUCHUNGEN, params);
        // if (response.data?.xlsx) {
        //   const file = base64ToArrayBuffer(response.data.xlsx);
        //   viewDocument({
        //     data: file,
        //     filename: 'Buchungen_' + this.userID + '.xlsx',
        //     contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        //     fileExtension: 'xlsx',
        //   }, true);
        // }
      }
    },
    exportAsFile(fileEnding) {
      const expRows = this.selected.map(row => {
        return this.buchungsheader.map(h => {
          let result = '';
          switch (h[2]) {
            case 'e':
              result = formatNumber(row[h[0]], 2) + '€';
              break;
            case 'n':
              result = formatNumber(row[h[0]], 2);
              break;
            default:
              result = row[h[0]] || '';
              break;
          }
          return result;
        })
      })
      const params = {
          create: fileEnding,
          filename: 'Buchungen_' + this.userID + '.' + fileEnding,
          title: this.buchungstitel,
          columns: this.buchungsheader.map(header => header[1]),
          rows: expRows,
          printMaklerLogo: '0',
      };
      return this.$store.dispatch(CORE_TYPES.ACTIONS.CREATE_XLS_OR_PDF, params);
    },
  },
}
</script>

<style scoped>

</style>