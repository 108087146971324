<template>
    <ContextMenuGroup>
        <ContextMenuItem @click="exportPDF" v-if="config.pdf || config.roottext">PDF</ContextMenuItem>
        <ContextMenuItem @click="exportTableToExcel" v-if="config.xls || config.roottext">Excel</ContextMenuItem>
        <ContextMenuItem @click="exportCSV" v-if="config.csv">CSV</ContextMenuItem>

        <BaseModal 
            ref="modalDownloadPin"
            modalTitle="Download PIN geschützte Datei"
            :showConfirmButton="true"
            labelButtonConfirm="Herunterladen"
            labelButtonCancel="Abbrechen"
            :autoClose="false"
            @onConfirmButton="downloadPinFile"
            @close="onCloseModal" 
        > 
            <form @submit.prevent="downloadPinFile()">
                <InputField 
                label="Bitte geben Sie die PIN ein"
                placeholder="Pin" 
                v-model="tempFilePin" />
            </form>
            <div class="fc-form-danger">
                {{ errorPinFile }}
            </div>
        </BaseModal>
    </ContextMenuGroup>
</template>
<script>
import { mapGetters } from 'vuex'
import dayjs from "dayjs";

import CORE_TYPES from '@/store/core/types'

import { viewDocument } from '@/components/core/download/DownloadLink.vue';

import ContextMenuGroup from '@/components/core/base-context-menu/ContextMenuGroup.vue';
import ContextMenuItem from '@/components/core/base-context-menu/ContextMenuItem.vue';
import BaseModal from "@/components/core/BaseModal.vue";
import InputField from '@/components/core/forms/InputField.vue'

export default {
    components: {
        ContextMenuGroup,
        ContextMenuItem,
        BaseModal,
        InputField,
    },
    props: {
        headers: { 
            type: Array,
            required: false,
        },
        rows: { 
            type: Array,
            required: false,
        },
        config: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            tempFileId: null,
            tempFileEnding: null,
            tempFilePin: '',
            errorPinFile: '',
        };
    },
    computed: {
        ...mapGetters({
            fullname: CORE_TYPES.GETTERS.GET_USER_FULL_NAME,
        }),
        exportableHeaders() {
            return this.headers.filter(header => {
                return header.exportFn != null;
            });
        },
    },
    methods: {
        async exportPDF() {
            this.exportFile('pdf', 'application/pdf')
        },
        exportCSV() {
            this.exportFile('csv', 'text/csv;charset=utf-8;')
        },
        async exportTableToExcel() {
            this.exportFile('xls', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        },
        async exportFile(fileEnding, contentType) {
            let name = (this.config[fileEnding] && this.config[fileEnding].name)
                    || this.config.name
                    || (this.config.roottext && this.config.roottext + ' ' + dayjs(new Date()).format('DD-MMM-YYYY HH mm'))
                    || 'Dokument';
            let title = (this.config[fileEnding] && this.config[fileEnding].title)
                    || this.config.title
                    || (this.config.roottext && this.config.roottext + ' - FULLNAME')
                    || '';
            if (title.indexOf('FULLNAME') !== -1) {
                title = title.replace(/FULLNAME/g, this.fullname);
            }
            const params = {
                create: fileEnding,
                filename: name + '.' + fileEnding,
                title: title,
                columns: this.exportableHeaders.map(header => header.label),
                rows: this.exportData(),
                printMaklerLogo: this.config.printMaklerLogo || '0',
            };
            let response;
            if ( this.config.dispatch ) {
                response = await this.config.dispatch(fileEnding, params);
            } else {
                response = await this.$store.dispatch(CORE_TYPES.ACTIONS.CREATE_XLS_OR_PDF, params);
            }
            if (response?.data?.bytes) {
                if (response?.data?.fileName) {
                    const fileName = response.data.fileName;
                    name = fileName;
                }
                viewDocument({
                    data: response.data.bytes,
                    filename: name,
                    contentType,
                });
            } else if (response?.data?.tempFileId) {
                this.tempFileId = response.data.tempFileId;
                this.tempFileEnding = fileEnding;
                this.$refs.modalDownloadPin.open();
            }
        },
        exportData() {
            const rows = this.rows.map(row => {
                return this.exportableHeaders.map(header => {
                    return this.exportCell(header, row);
                });
            });
            return rows;
        },
        exportCell(header, row) {
            const value = row[header.key];
            const isFooterCell = !!row.__FOOTER__;
            
            // headers without exportFn are filtered out by the computed "exportableHeaders"
            return header.exportFn(value, row, header) || '';
        },
        async downloadPinFile() {
            const type = (this.tempFileEnding == 'xls') ? 'xlsx' : 'pdf';
            const content = (type == 'xlsx') ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'application/pdf';
            if ( /\d\d\d/.test(this.tempFilePin) ) {
                const error = await this.$store.dispatch(CORE_TYPES.ACTIONS.GET_PIN_DATA, {
                    id: this.tempFileId,
                    pin: parseInt(this.tempFilePin),
                    type: type,
                    contenttype: content,
                });
                if (!error) {
                    this.$refs.modalDownloadPin.close();
                    this.onCloseModal();
                }
            } else {
                this.errorPinFile = 'Bitte eine 3-stellige Nummer als Pin eingeben';
            }
        },
        onCloseModal() {
            this.tempFileId = null;
            this.tempFileEnding = null;
            this.tempFilePin = '';
            this.errorPinFile = ''
        },
    }
}
</script>
<style scoped>
</style>
