var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _c("div", { staticClass: "box__title" }, [_vm._v(_vm._s(_vm.title))]),
      _c("div", [
        _vm.isBiPRO
          ? _c("p", { staticClass: "mt-0 mb-2" }, [
              _vm._v(" Über die "),
              _c("b", [_vm._v("BiPRO Zugänge")]),
              _vm._v(
                " werden täglich die Dokumente von den Versicherungsgesellschaften geladen und bei den jeweiligen Versicherungen Ihrer Kunden im Servicecenter abgelegt. Gleichzeitig bekommen Sie eine Meldung unter "
              ),
              _c("b", [_vm._v("Wichtige Warnungen/Stornos")]),
              _vm._v(" angezeigt. ")
            ])
          : _vm._e(),
        _vm._m(0),
        !_vm.isBiPRO
          ? _c("p", { staticClass: "mt-0 mb-2" }, [
              _vm._v("Für die Gesellschaften die unter Neustart ein "),
              _c("b", [_vm._v("'Ja'")]),
              _vm._v(
                " stehen haben ist für die Benutzung der Webseite unter 'Externe Zugänge' ein Neustart des Servicecenter notwendig."
              )
            ])
          : _vm._e()
      ]),
      _vm.isLoading
        ? _c("GhostLoading", { attrs: { type: "table" } })
        : !_vm.rows.length
        ? _c("NoData")
        : _c("Table", {
            attrs: { rowId: "rowId", headers: _vm.headers, rows: _vm.rows },
            on: {
              "action-NEW": function($event) {
                return _vm.openAddExterneZugangConfig($event, true)
              },
              "action-EDIT": function($event) {
                return _vm.openAddExterneZugangConfig($event)
              },
              "action-DELETE": function($event) {
                return _vm.deleteExterneZugangConfig($event && $event.id)
              }
            }
          })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "mt-0 mb-2" }, [
      _c("b", [_vm._v("Achtung")]),
      _vm._v(" bei Gesellschaften die unter Zertifikat ein "),
      _c("b", [_vm._v("'Ja'")]),
      _vm._v(
        " stehen haben benötigen Sie ein Zertifikat. Dieses erhalten Sie von Ihrem Betreuer."
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }