<template>
  <div>
    <div v-if="selectedNav === 'ergebnis-depotoptimierung'">
      <ErgebnisRiskReturnView :adviceId="id" @apply="resetAllocation" @update_hints="updateHints"></ErgebnisRiskReturnView>
    </div>
    <div v-if="selectedNav === 'ergebnis-backtesting'">
      <ErgebnisPerformanceView v-if="subtab==='Performance'" :adviceId="id" @update_hints="updateHints"></ErgebnisPerformanceView>
      <ErgebnisDiagrammeView v-else-if="subtab==='Diagramme'" :adviceId="id"/>
      <ErgebnisBacktestingView v-else :adviceId="id" :stepData="stepData" :tab="subtab" 
          @update_hints="updateHints"
          @display_server_question="$emit('display_server_question', $event)"
      ></ErgebnisBacktestingView>
    </div>
    <div v-if="debug"> Egebnis {{ id }} params: {{ stepParameters }} </div>
  </div>
</template>
<script>
import StepMixin from "./StepMixin";
import INVESTMENT_ADVICE from "@/store/investmentAdvice/types";
import StepAllocation from "./StepAllocation";
import ErgebnisBacktestingView from "./ErgebnisBacktestingView.vue";
import ErgebnisRiskReturnView from "@/components/investmentAdvice/steps/ErgebnisRiskReturnView";
import ErgebnisPerformanceView from "@/components/investmentAdvice/steps/ErgebnisPerformanceView";
import ErgebnisDiagrammeView from "@/components/investmentAdvice/steps/ErgebnisDiagrammeView";


export default {
  props: {
    tab: {
      type: String,
      default: 'ergebnis-backtesting',
    },
    subtab: {
      type: String,
    },
  },
  data() {
    return {
      stepAllocation: [
        new StepAllocation(undefined, null),
      ],
      step: INVESTMENT_ADVICE.STEPS.OUTCOME,
      selectedNav: "ergebnis-backtesting",
      reset: false,
    };
  },
  components: {
    ErgebnisBacktestingView,
    ErgebnisRiskReturnView,
    ErgebnisPerformanceView,
    ErgebnisDiagrammeView,
  },
  mixins: [StepMixin],
  computed: {
    stepData() {
      if (this.stepParameters && this.stepParameters.data) {
        return this.stepParameters.data.parameterData;
      } else {
        return undefined;
      }
    },
  },
  methods: {    
    resetAllocation() {
      this.reset = true;
    },
    toTab(place) {
      this.selectedNav = place;
    },
  },
  watch: {
    tab: {
      handler(tab) {
        this.toTab(tab);
      },
      immediate: true,
    },
  }
}
</script>
<style scoped>
.nav {
  padding-left: 0px;
}
.d-flex > .box__container {
  flex-grow: 1;
}
</style>
