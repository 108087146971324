var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "antrag-components__container" }, [
    _c(
      "div",
      { staticClass: "box__container" },
      [
        _c("FormLabel", {
          attrs: {
            label:
              "Bitte tragen Sie hier die Daten des Depots ein, von dem die Wertpapiere übertragen werden sollen.",
            config: { bold: true }
          }
        }),
        _c("FormDivider"),
        _c(
          "div",
          { staticClass: "row" },
          [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("FormLabel", {
                  attrs: {
                    config: { bold: true },
                    label: "Daten der bisher depotführenden Gesellschaft"
                  }
                })
              ],
              1
            ),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Name" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherManuellName: $event })
                }
              },
              model: {
                value: _vm.form.bisherManuellName,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherManuellName", $$v)
                },
                expression: "form.bisherManuellName"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Straße" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherManuellStrasse: $event })
                }
              },
              model: {
                value: _vm.form.bisherManuellStrasse,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherManuellStrasse", $$v)
                },
                expression: "form.bisherManuellStrasse"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "PLZ" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherManuellPLZ: $event })
                }
              },
              model: {
                value: _vm.form.bisherManuellPLZ,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherManuellPLZ", $$v)
                },
                expression: "form.bisherManuellPLZ"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Ort" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherManuellOrt: $event })
                }
              },
              model: {
                value: _vm.form.bisherManuellOrt,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherManuellOrt", $$v)
                },
                expression: "form.bisherManuellOrt"
              }
            })
          ],
          1
        ),
        _c("FormDivider"),
        _c(
          "div",
          { staticClass: "row" },
          [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("FormLabel", {
                  attrs: {
                    config: { bold: true },
                    label:
                      "Daten des Depots bei der bisher depotführenden Gesellschaft"
                  }
                })
              ],
              1
            ),
            _c("InputField", {
              attrs: { label: "Depotnummer" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherDepotnr: $event })
                }
              },
              model: {
                value: _vm.form.bisherDepotnr,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherDepotnr", $$v)
                },
                expression: "form.bisherDepotnr"
              }
            }),
            _c("InputField", {
              attrs: { label: "Kontonummer" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherKontonr: $event })
                }
              },
              model: {
                value: _vm.form.bisherKontonr,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherKontonr", $$v)
                },
                expression: "form.bisherKontonr"
              }
            }),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("FormLabel", {
                  attrs: { config: { bold: true }, label: "1. Inhaber" }
                })
              ],
              1
            ),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Name", id: "form.bisherName" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherName: $event })
                }
              },
              model: {
                value: _vm.form.bisherName,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherName", $$v)
                },
                expression: "form.bisherName"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Vorname", id: "form.bisherVorname" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherVorname: $event })
                }
              },
              model: {
                value: _vm.form.bisherVorname,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherVorname", $$v)
                },
                expression: "form.bisherVorname"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Straße Haus-Nr", id: "form.bisherStrasse" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherStrasse: $event })
                }
              },
              model: {
                value: _vm.form.bisherStrasse,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherStrasse", $$v)
                },
                expression: "form.bisherStrasse"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "PLZ", id: "form.bisherPlz" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherPlz: $event })
                }
              },
              model: {
                value: _vm.form.bisherPlz,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherPlz", $$v)
                },
                expression: "form.bisherPlz"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Ort", id: "form.bisherOrt" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherOrt: $event })
                }
              },
              model: {
                value: _vm.form.bisherOrt,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherOrt", $$v)
                },
                expression: "form.bisherOrt"
              }
            }),
            _c("DatePickerField", {
              staticClass: "col-12 col-md-6",
              attrs: {
                id: "form.bisherGebdat",
                label: "Geburtsdatum",
                isValueAsString: ""
              },
              on: {
                input: function($event) {
                  return _vm.$emit("save", { bisherGebdat: $event })
                }
              },
              model: {
                value: _vm.form.bisherGebdat,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherGebdat", $$v)
                },
                expression: "form.bisherGebdat"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { id: "form.bisherID", label: "TIN" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherID: $event })
                }
              },
              model: {
                value: _vm.form.bisherID,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherID", $$v)
                },
                expression: "form.bisherID"
              }
            }),
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("FormLabel", {
                  attrs: { config: { bold: true }, label: "2. Inhaber" }
                })
              ],
              1
            ),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Name", id: "form.bisherName2" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherName2: $event })
                }
              },
              model: {
                value: _vm.form.bisherName2,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherName2", $$v)
                },
                expression: "form.bisherName2"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "Vorname", id: "form.bisherVorname2" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherVorname2: $event })
                }
              },
              model: {
                value: _vm.form.bisherVorname2,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherVorname2", $$v)
                },
                expression: "form.bisherVorname2"
              }
            }),
            _c("DatePickerField", {
              staticClass: "col-12 col-md-6",
              attrs: {
                id: "form.bisherGebdat2",
                label: "Geburtsdatum",
                isValueAsString: ""
              },
              on: {
                input: function($event) {
                  return _vm.$emit("save", { bisherGebdat2: $event })
                }
              },
              model: {
                value: _vm.form.bisherGebdat2,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherGebdat2", $$v)
                },
                expression: "form.bisherGebdat2"
              }
            }),
            _c("InputField", {
              staticClass: "col-12 col-md-6",
              attrs: { label: "TIN", id: "form.bisherID2" },
              on: {
                change: function($event) {
                  return _vm.$emit("save", { bisherID2: $event })
                }
              },
              model: {
                value: _vm.form.bisherID2,
                callback: function($$v) {
                  _vm.$set(_vm.form, "bisherID2", $$v)
                },
                expression: "form.bisherID2"
              }
            })
          ],
          1
        ),
        _c(
          "BaseButton",
          {
            on: {
              click: function($event) {
                return _vm.$emit("next")
              }
            }
          },
          [_vm._v("Weiter")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }