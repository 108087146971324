<template>
  <div class="base-cms-view">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CORE_TYPES from '@/store/core/types'
import { loadColorScheme } from '@/configs/color-config.js'

export default {
  watch: {
    cmsSchema: {
      handler(current) {
        loadColorScheme(current, '.base-cms-view')
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters({
      colorSchema: CORE_TYPES.GETTERS.GET_COLOR_SCHEMA
    }),
    cmsSchema() {
      return this.colorSchema?.cms?.layout
    }
  },
}
</script>

<style scoped>

</style>
