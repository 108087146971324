<template>
  <div class="progress-bar__container">
    <div
      v-bind:class="{
        'progress-bar-finish': isFinished, 
        'progress-bar-started': isStarted,
        'progress-bar-keep-animation-active': keepAnimationActive,
      }"
      class="progress-bar"
      @transitionend="onTransitionEnd"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CORE_TYPES from '../../store/core/types';

const ANIMATION_WIDTH_OFFSET = 16;
const STICKY_CLASS = 'sticky';

export default {
  data: function() {
    return {
      isFinished: false,
      isStarted: false,
      keepAnimationActive: false,
      timeoutRef: 0,
      originalTopPosition: null,
    }
  },
  computed: {
    ...mapGetters({
      globalLoadingStateStatus: CORE_TYPES.GETTERS.GLOBAL_LOADING_STATE_STATUS,
      isLoadingToken: CORE_TYPES.GETTERS.IS_LOADING_TOKEN,
      isManagingLoginResponse: CORE_TYPES.GETTERS.IS_MANAGING_LOGIN_RESPONSE,
      isLoggingIn: CORE_TYPES.GETTERS.IS_LOGGING_IN,
      isLoggingOut: CORE_TYPES.GETTERS.IS_LOGGING_OUT,
    }),
    isLoadingRequiredData() {
      return this.isLoadingToken || this.isManagingLoginResponse || this.isLoggingIn || this.isLoggingOut;
    },
    isLoading() {
      return !!this.globalLoadingStateStatus || this.isLoadingRequiredData;
    },
  },
  watch: {
    isLoading(newIsLoading) {
      this.toggleIsLoadingFlag(newIsLoading);
    }
  },
  methods: {
    toggleIsLoadingFlag(isLoading) {
      clearTimeout(this.timeoutRef);
      this.keepAnimationActive = isLoading && this.hasProgressBarElReachedAnimationEnd();
      if(isLoading) {
        this.isStarted = true;
        this.isFinished = false;
      } else {
        this.isStarted = false;
        this.isFinished = true;
        this.timeoutRef = setTimeout(() => {
          this.isFinished = false;
          this.keepAnimationActive = false;
        }, 500);
      }
    },
    onTransitionEnd() {
      this.keepAnimationActive = this.isLoading;
    },
    hasProgressBarElReachedAnimationEnd() {
      const progressBarContainerEl = this.$el;
      const progressBarEl = progressBarContainerEl.querySelector('.progress-bar');
      return progressBarEl.clientWidth === (progressBarContainerEl.clientWidth - ANIMATION_WIDTH_OFFSET);
    },
    trackOriginalTopPosition(force) {
      if(!force && this.originalTopPosition !== null) return;

      const progressBarContainerEl = this.$el;
      progressBarContainerEl.classList.remove(STICKY_CLASS);

      const bounding = progressBarContainerEl.getBoundingClientRect();
      this.originalTopPosition = bounding.top + window.scrollY;
    },
    onScroll() {
      const progressBarContainerEl = this.$el;
      if(!progressBarContainerEl) return;

      const bounding = progressBarContainerEl.getBoundingClientRect();
      if(bounding.top > 0) {
        progressBarContainerEl.classList.remove(STICKY_CLASS);
        return;
      }

      const { scrollY } = window;

      if(this.originalTopPosition <= scrollY) {
        progressBarContainerEl.classList.add(STICKY_CLASS);
      } else {
        progressBarContainerEl.classList.remove(STICKY_CLASS);
      }
    },
    onResize() {
      this.trackOriginalTopPosition(true);
      this.onScroll();
    },
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll);
    window.addEventListener('resize', this.onResize);
    requestAnimationFrame(() => {
      this.trackOriginalTopPosition();
      this.onScroll();
      this.toggleIsLoadingFlag(this.isLoading);
    });
  },
  destroyed() {
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.onResize);
  },
};
</script>


<style lang="scss" scoped>
.progress-bar__container {
  position: relative;

  &.sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.progress-bar {
  position: absolute;
  top: 0px;
  left: 0;
  margin: 0;
  padding: 0;
  border: none;
  z-index: 10000;
  width: 0%;
  height: 0.25em;
  opacity: 0;
  background-color: var(--color-secondary);
  overflow: hidden;
}

.progress-bar-started {
  opacity: 1;
  width: calc(100% - 16px);
  transition: width 20s cubic-bezier(0.1, 0.05, 0, 1);
}

.progress-bar-finish {
  opacity: 0;
  width: 100%;
  transition: width 0.1s ease-out, opacity 0.5s ease 0.2s;
}

.progress-bar-keep-animation-active {
  &:after {
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, .8) 50%, rgba(255, 255, 255, 0) 100%);
    background-repeat: no-repeat;
    content: "";
    display: block;
    opacity: 0.75;
    pointer-events: none;
    position: absolute;
    top: -25px;
    left: -150px;
    width: 150px;
    height: 50px;
    transform: rotate(15deg);
    animation: shimmer 2.5s infinite forwards;
  }
}

@media (prefers-color-scheme: dark) {
  .progress-bar-keep-animation-active {
    &:after {
      background-image: linear-gradient(90deg, rgba(102, 102, 102, 0) 0, rgba(102, 102, 102, .8) 50%, rgba(102, 102, 102, 0) 100%);
    }
  }
}

@keyframes shimmer {
  0% {
    left: -150px;
  }
  100% {
    left: 100%;
  }
}
</style>