import INTERN_TYPES from './types';
import axios from 'axios';
import router from '../../router';

export default {
  [INTERN_TYPES.ACTIONS.TEMPLATE]({ commit, state, rootState }) {
    axios.post(rootState.core.apiAddress + '/template', {}).then(response => {
      commit(INTERN_TYPES.MUTATIONS.TEMPLATE, response.data);
    }).catch(error => {
      commit(INTERN_TYPES.MUTATIONS.TEMPLATE, error.response && error.response.data);
    })
  },
}