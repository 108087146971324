var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("p", [
        _vm._v(
          "Die Dokumente können nicht zu einer Datei zusammengefasst werden, da sie z.T. digital signiert wurden. Sie können die Dateien hier einzeln abrufen:"
        )
      ]),
      _vm._m(0),
      _vm._l(_vm.unsignedAttachments, function(ua) {
        return _c(
          "div",
          { key: ua.label },
          [
            _c("DownloadLink", {
              attrs: {
                href: _vm.linkGenerator(
                  ua.fileName || ua.bezeichnung,
                  ua.id,
                  ua.tempFileId || 0
                ),
                title: ua.bezeichnung || ua.fileName
              }
            })
          ],
          1
        )
      }),
      _vm._m(1),
      _vm._l(_vm.signedAttachments, function(sa) {
        return _c(
          "div",
          { key: sa.label },
          [
            _c("DownloadLink", {
              attrs: {
                href: _vm.linkGenerator(
                  sa.fileName || sa.bezeichnung,
                  sa.id,
                  sa.tempFileId || 0
                ),
                title: sa.bezeichnung || sa.fileName
              }
            })
          ],
          1
        )
      }),
      _vm.isEmptyFile
        ? _c("div", { staticClass: "color-danger mt-2" }, [
            _vm._v("Dokument kann nicht geöffnet werden")
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("b", [_vm._v("Nicht digital signierte Dokumente / Scans")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_vm._v("Digital signierte Dokument")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }