var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.parentOptionMenuId,
          defaultMenu: _vm.$appNavigation.parentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Feststellung der BiPRO Kategorien",
          actions: _vm.headerActions
        },
        on: {
          "action-ADD": function($event) {
            return _vm.addNewCategory()
          }
        }
      }),
      _vm.mscCategories && _vm.mscCategories.length
        ? _c("BaseFilter", {
            attrs: {
              filterId: "d0c9a283-6380-4709-933b-943bd6b7f908",
              title: "Bipro Kategorien filtern",
              metadata: _vm.filterMetadata,
              defaultOptions: _vm.defaultOptionsData
            },
            on: {
              onFilter: function($event) {
                return _vm.filterForm($event)
              }
            }
          })
        : !_vm.mscCategories || !_vm.mscCategories.length
        ? _c("GhostLoading", {
            attrs: {
              type: "block",
              config: { block: { height: 100 } },
              useBoxContainer: ""
            }
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "box__container",
          attrs: { tid: "7bf88b18-1485-4130-a282-2101d77f8608" }
        },
        [
          !_vm.loading && _vm.rows.length
            ? _c("BigTable", {
                attrs: {
                  tableId: "907ebeec-ab09-4154-ac82-a817dfa73d67",
                  title: _vm.TABLE_TITLE,
                  headers: _vm.headers,
                  rows: _vm.rows,
                  page: _vm.page,
                  sortingState: _vm.sortingState,
                  initialPageSize: _vm.minNumRows
                },
                on: {
                  onClickHeader: _vm.onClickHeader,
                  "click-link-gevoArt": _vm.bearbeiten,
                  action: function($event) {
                    return _vm.executeAction($event)
                  }
                }
              })
            : _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", title: _vm.TABLE_TITLE }
              })
            : _c("NoData", { attrs: { title: _vm.TABLE_TITLE } })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "removeItemConfirmation",
          attrs: {
            modalTitle: "Gevo Art löschen",
            labelButtonConfirm: "Löschen"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.removeItem()
            }
          }
        },
        [
          _vm.selectedRow
            ? [
                _vm._v(
                  " Soll die Gevo Art " +
                    _vm._s(_vm.selectedRow.gevoArt) +
                    " wirklich gelöscht werden? "
                )
              ]
            : _vm._e()
        ],
        2
      ),
      _c(
        "BaseModal",
        {
          ref: "editModal",
          attrs: {
            modalTitle: "Feststellung der BiPRO Kategorien",
            confirmDisabled: _vm.isFormInvalid,
            autoClose: false
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.saveOrUpdate()
            }
          }
        },
        [
          _c(
            "div",
            [
              _c("InputField", {
                attrs: {
                  id: "gevoArt",
                  type: "number",
                  isInteger: "",
                  label: "Gevo Art",
                  disabled: _vm.form.editing,
                  validateUntouched: true
                },
                model: {
                  value: _vm.form.gevoArt,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "gevoArt", $$v)
                  },
                  expression: "form.gevoArt"
                }
              }),
              _c("InputField", {
                attrs: {
                  id: "hauptKategorie",
                  label: "Hauptkategorie",
                  validateUntouched: true
                },
                model: {
                  value: _vm.form.hauptKategorie,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "hauptKategorie", $$v)
                  },
                  expression: "form.hauptKategorie"
                }
              }),
              _c("InputField", {
                attrs: {
                  id: "unterKategorie",
                  label: "Unterkategorie",
                  validateUntouched: true
                },
                model: {
                  value: _vm.form.unterKategorie,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "unterKategorie", $$v)
                  },
                  expression: "form.unterKategorie"
                }
              }),
              _c("ComboBox", {
                attrs: {
                  id: "kategorieFK",
                  label: "MSC Dateikategorie",
                  firstEmpty: true,
                  values: _vm.mscCategories,
                  validateUntouched: true
                },
                model: {
                  value: _vm.form.kategorieFK,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "kategorieFK", $$v)
                  },
                  expression: "form.kategorieFK"
                }
              }),
              _c("InputField", {
                attrs: { id: "transferNorm", label: "Transfernorm" },
                model: {
                  value: _vm.form.transferNorm,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "transferNorm", $$v)
                  },
                  expression: "form.transferNorm"
                }
              }),
              _c("InputField", {
                attrs: {
                  id: "zuordnungsAttribute",
                  label: "Zuordnungsattribute"
                },
                model: {
                  value: _vm.form.zuordnungsAttribute,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "zuordnungsAttribute", $$v)
                  },
                  expression: "form.zuordnungsAttribute"
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }