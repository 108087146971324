var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "contact_container" }, [
    _c("div", { staticClass: "contact_wrapper" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "text" }, [
          _c("div", { staticClass: "banner" }, [
            _vm._v(_vm._s(this.homeScreenText))
          ]),
          _c("p", { staticClass: "app_id_display" }, [
            _vm._v(_vm._s(this.app_id_display))
          ]),
          _c("div", { staticClass: "banner" }, [_vm._v("Kontolöschung")])
        ]),
        _c("div", { staticClass: "banner_image" }, [
          _c("img", {
            staticClass: "app_image",
            attrs: { src: this.app_image, alt: "App Icon" }
          })
        ])
      ]),
      _c("hr"),
      _c("div", { staticClass: "deletion_text" }, [
        _c("h3", [_vm._v("Wie kann ich mein Konto löschen lassen?")]),
        _vm._v(
          " Wenn Sie Ihr Konto löschen lassen wollen, schreiben Sie bitte eine eine formlose E-Mail an "
        ),
        _c("a", { attrs: { href: "mailto:" + this.email } }, [
          _vm._v(_vm._s(this.email))
        ]),
        _vm._v(
          " und bitten darin um die Löschung Ihrer Daten. Wir werden uns dann umgehend mit Ihnen in Verbindung setzen, um zu prüfen, dass Sie für diesen Account berechtigt sind und dann Ihre Daten gemäß der gesetzlichen Vorgaben löschen. "
        ),
        _c("h3", [_vm._v("Welche Daten werden gelöscht?")]),
        _vm._v(
          " Sollten noch keine Geschäftsbeziehung zustande gekommen sein, werden wir nach Aufforderung alle Ihre Daten vollständig löschen. Wenn bereits eine Geschäftsbeziehung zustande gekommen ist müssen wir alle Daten der Beratung gemäß gesetzlicher Vorgaben [§199 (3) 1. BGB, Art. 17 (3) e) DSGVO] 10 Jahre nach Beendigung der Geschäftsbeziehung aufbewahren. Vor Ablauf dieser gesetzlichen Frist können keine Daten gelöscht werden, das Konto kann lediglich deaktiviert werden. Nach Ablauf der 10 Jahresfrist werden Ihre Daten ohne weitere Aufforderung automatisch gelöscht. "
        ),
        _c("h3", [_vm._v("Welche Daten werden aufbewahrt?")]),
        _vm._v(
          " Sollten noch keine Geschäftsbeziehung zustande gekommen sein, werden wir keine Daten von Ihnen aufbewahren Wenn bereits eine Geschäftsbeziehung zustande gekommen ist müssen wir alle Daten der Beratung gemäß gesetzlicher Vorgaben [§199 (3) 1. BGB, Art. 17 (3) e) DSGVO] zehn Jahre nach Beendigung der Geschäftsbeziehung aufbewahren. Bis zum Ablauf dieser gesetzlichen Frist werden alle Daten Ihres Kontos aufbewahrt. "
        ),
        _c("h3", [_vm._v("Aufbewahrungsdauer")]),
        _vm._v(
          " Sollte bereits eine Geschäftsbeziehung zustande gekommen sein werden Ihre Daten bis zehn Jahre nach Beendigung der Geschäftsbeziehung aufbewahrt. "
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }