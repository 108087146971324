export const MUTATION_PREFIX = 'RISIKOVERTEILUNG: ';
export const ACTIONS_PREFIX = 'RISIKOVERTEILUNG_ACTIONS_';
export const GETTERS_PREFIX = 'RISIKOVERTEILUNG_GETTERS_';

export default {
  MUTATIONS: {
    GET_RISIKOVERTEILUNG_SUCCESS: MUTATION_PREFIX + 'GET_RISIKOVERTEILUNG_SUCCESS',
  },
  ACTIONS: {
    GET_RISIKOVERTEILUNG: ACTIONS_PREFIX + 'GET_RISIKOVERTEILUNG',
  },
  GETTERS: {
    RISIKOVERTEILUNG: GETTERS_PREFIX + 'RISIKOVERTEILUNG',
  }
}
