<template>
  <router-view :key="routerViewKey"></router-view>
</template>

<script>
export default {
  computed: {
    /**
     * it is to force the router load when change from anlegerprofil and anlegerprofilWK in the option menu
     */
    routerViewKey() {
      return this.$route?.path?.includes('anlegerprofilWK') ? 'anlegerprofilWK' : 'anlegerprofil';
    },
  },
}
</script>
