<template>
  <div class="box__container-content">
    <template>
      <template>
        <div class="d-md-block d-none">
          <div class="btn-group justify-content-center m-0">
            <BaseButton v-for="(fond, index) in fondsValues" :key="index" class="btn-group-item clickable" 
              :isSecondary="fond.isin !== selectedIsin"
              @click="selectFond(fond)">{{ fond.title }}</BaseButton>
          </div>

          <div v-if="showYearFilter" class="btn-group justify-content-center mt-3">
            <BaseButton v-for="(year, index) in yearsValues" :key="index" class="btn-group-item clickable" 
              :isSecondary="year.value !== selectedYear"
              @click="selectYear(year.value)">{{ year.label }}</BaseButton>
          </div>
        </div>
      </template>

      <template>
        <div class="d-md-none">
          <ComboBox
            :value="selectedIsin"
            :values="fondsValues"
            @change="selectFondByISIN($event)"
          />

          <ComboBox
            v-if="showYearFilter"
            :value="selectedYear"
            :values="yearsValues"
            @change="selectYear($event)"
          />
        </div>
      </template>

      <div class="marktubersicht-chart__content" :key="selectedIsin"  v-if="!loading && data">
        <LineChart :chartData="eChartsData" axisLabelFormatterShowOption="full" format="integer" :showDataZoom="showBrushChart" :isTooltip="false" height="450px" :gridLeft="40"/>
      </div>
      <GhostLoading v-else-if="loading" type="block" :config="{ block: { height: 450, }, }" />
      <NoData v-else noIcon />

    </template>


    <template v-if="!loading && data">
      <div v-if="showDetailLink" class="text-right card-bottom">
        <a @click="openMarktubersichtDetails()">Details</a>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FONDSFINDER_TYPES from '@/store/fondsfinder/types';
import dayjs from 'dayjs';

import BaseButton from '@/components/core/BaseButton.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';

import { formatNumber } from '@/helpers/number-formatter';
import LineChart from '@/components/charts/echarts/LineChart.vue'

const DEFAULT_ISIN_LIST = [
  'DE0008469008', // DAX
  'AT0000999982', // ATX (Austrian Traded Index)
  'US26061Q1031', // Dow Jones Industrial Average
  'JP9010C00002', // NIKKEI 225
];
const MAP_ISIN_TO_TITLE = {
  'JP9010C00002': 'Nikkei',
  'US26061Q1031': 'Dow Jones',
  'AT0000999982': 'ATX',
};
const YEARS_VALUES = [
  {
    label: '1 Jahr',
    value: 1,
  },
  {
    label: '2 Jahre',
    value: 2,
  },
  {
    label: '3 Jahre',
    value: 3,
  },
  {
    label: '5 Jahre',
    value: 5,
  },
  {
    label: 'von Anfang an',
    value: null,
  },
];
const DEFAULT_SELECTED_YEAR = 1;
const DATASET_FIRST_INDEX = 1;


export default {
  props: {
    showYearFilter: {
      type: Boolean,
      default: true,
    },
    showBrushChart: {
      type: Boolean,
      default: true,
    },
    showDetailLink: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      yearsValues: [...YEARS_VALUES],
      selectedYear: DEFAULT_SELECTED_YEAR,
      minDateTime: dayjs().subtract(DEFAULT_SELECTED_YEAR, 'year').startOf('date').toDate().getTime(),
      maxDateTime: dayjs().endOf('date').toDate().getTime(),
    };
  },
  computed: {
    ...mapGetters({
      chartData: FONDSFINDER_TYPES.GETTERS.MARKTUBERSICHT_CHART_DATA,
      selectedFond: FONDSFINDER_TYPES.GETTERS.MARKTUBERSICHT_CHART_SELECTED_FOND,
    }),
    selectedIsin() {
      return this.selectedFond?.isin || '';
    },
    series() {
      return this.chartData?.series || [];
    },
    axisXFactor() {
      return this.series?.length && this.series[0].axisXFactor || 0;
    },
    dataset() {
      const dataset = this.chartData?.dataset;
      return dataset?.length && this.fillUntilCurrentDate(dataset, this.axisXFactor) || [];
    },
    fonds() {
      return this.series.map(serie => ({
        ...serie,
        title: MAP_ISIN_TO_TITLE[serie.isin] || serie.title,
      }));
    },
    fondsValues() {
      const fonds = [...this.fonds];
      return fonds.map(fond => ({
        ...fond,
        value: fond?.isin,
        label: fond.title,
      }));
    },
    mapIsinToData() {
      if(!this.dataset?.length) return {};

      return this.fonds.reduce((acc, fond) => {
        if(!fond?.isin) return ;

        const { x, y } = fond?.encode;
        const xDatasetIndex = this.dataset[0].findIndex(v => v === x);
        const yDatasetIndex = this.dataset[0].findIndex(v => v === y);

        acc[fond.isin] = [
          {
            name: fond?.title,
            data: [...this.dataset.slice(DATASET_FIRST_INDEX)].map(dataset => {
              return [dataset[xDatasetIndex] * this.axisXFactor, dataset[yDatasetIndex]];
            }),
          },
        ];

        return acc;
      }, {});
    },
    data() {
      return this.selectedFondData;
    },
    eChartsData() {
      const minDateTime = this.minDateTime
      const maxDateTime = this.maxDateTime
      const data = this.selectedFondData?.[0]?.data

      const filterByDate = ([date, value]) => {
        return date > minDateTime && date < maxDateTime
      }

      return data?.filter(filterByDate);
    },
    selectedFondData() {
      return this.selectedIsin in this.mapIsinToData && this.mapIsinToData[this.selectedIsin] || null;
    },
    options() {
      return {
        chart: {
          id: 'marktubersicht-chart',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          animations: {
            enabled: false,
          },
        },
        xaxis: {
          type: 'datetime',
          min: this.minDateTime,
          max: this.maxDateTime,
          labels: {
            format: 'yyyy',
          },
        },
        yaxis: {
          opposite: true,
          tickAmount: 6,
          labels: {
            align: 'left',
            formatter: (value) => formatNumber(value, 0),
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
          width: 1,
        },
        tooltip: {
          x: {
            format: 'dd.MM.yyyy',
          },
        },
        legend: {
          show: false,
        },
      };
    },
    brushOptions() {
      return {
        chart: {
          brush:{
            enabled: true,
            target: 'marktubersicht-chart',
            autoScaleYaxis: false,
          },
          selection: {
            enabled: true,
            xaxis: {
              min: this.minDateTime,
              max: this.maxDateTime,
            },
          },
          animations: {
            enabled: false,
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            opacityFrom: 0.91,
            opacityTo: 0.1,
          },
        },
        xaxis: {
          type: 'datetime',
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          show: false,
        },
      };
    },
  },
  methods: {
    async findChartData(years=1) {
      this.loading = true;
      await this.$store.dispatch(FONDSFINDER_TYPES.ACTIONS.GET_MARKTUBERSICHT_CHART_DATA, {
        isinList: DEFAULT_ISIN_LIST, years
      });
      this.loading = false;
    },
    selectFond(fond) {
      this.$store.commit(FONDSFINDER_TYPES.MUTATIONS.SET_MARKTUBERSICHT_CHART_SELECTED_FOND, {
        fond,
      });

      this.resetMinAndMaxDateTime();
    },
    selectFondByISIN(isin) {
      const fonds = [...this.fonds];

      const fond = fonds.find(f => f?.isin === isin);
      this.$store.commit(FONDSFINDER_TYPES.MUTATIONS.SET_MARKTUBERSICHT_CHART_SELECTED_FOND, {
        fond,
      });

      this.resetMinAndMaxDateTime();
    },    
    selectFondIfNeeded() {
      if(Object.keys(this.selectedFond).length > 0) return;

      this.selectFond({
        isin: DEFAULT_ISIN_LIST[0],
      });
    },
    selectYear(year) {
      this.selectedYear = year;

      this.resetMinAndMaxDateTime();
    },
    openMarktubersichtDetails() {
      this.$router.push('/home/funds-chart');
    },
    fillUntilCurrentDate(datasetParam) {
      try {
        const dataset = [...datasetParam];
        let lastTime = parseInt(dataset[dataset.length - 1][0], 10) ;
        const currentTime = ((new Date()).getTime() - 3600000 * 24) / this.axisXFactor;
        if ( lastTime < currentTime ) {
          const row = dataset[dataset.length - 1].slice(1) ;
          const step = 3600000 * 24 / this.axisXFactor;
          while ( lastTime < currentTime ) {
            lastTime = lastTime + step;
            dataset.push( [lastTime].concat(row) );
          }
        }
        return dataset;
      } catch (error) {
        console.error('fillUntilCurrentDate', error, datasetParam);
        return datasetParam;
      }
    },
    resetMinAndMaxDateTime() {
      if(this.selectedYear) {
        this.minDateTime = dayjs().subtract(this.selectedYear, 'year').startOf('date').toDate().getTime();
      } else {
        this.minDateTime = dayjs(this.dataset[DATASET_FIRST_INDEX][0] * this.axisXFactor).startOf('date').toDate().getTime();
      }
      this.maxDateTime = dayjs().endOf('date').toDate().getTime();
      
      if (this.data?.length && this.data[0].data?.length && this.minDateTime < this.data[0].data[0][0]) {
        this.findChartData(50);
      }
    },
    toggleSeries(_, seriesIndex, config) {
      const seriesName = config?.config?.series[seriesIndex]?.name;
      if(seriesName) {
        this.$refs.mainChart.toggleSeries(seriesName);
      }
    },
  },
  mounted() {
    this.findChartData();
    this.selectFondIfNeeded();
  },
  components: {
    BaseButton,
    ComboBox,
    LineChart,
    GhostLoading,
    NoData,
  },
}
</script>

<style scoped>
.marktubersicht-chart__content {
  min-height: 250px;
  flex: 1;
}
.box__container-content {
  display: flex;
  flex-direction: column;
}
.container {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  flex-shrink: 0;
  flex-grow: 1;
}
.card-bottom {
  align-self: flex-end;
}
</style>
