var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.personen
        ? _c("div", { staticClass: "box__title" }, [
            _vm._v("Wirtschaftliche Eigentümer")
          ])
        : _vm._e(),
      _vm.data && _vm.data.isEigentuemerTypeVisible
        ? _c("InputRadioBoxGroup", {
            attrs: {
              value: _vm.data.INPUT_RG_EIGENTUEMER_TYPE,
              validateUntouched: true,
              values: _vm.valuesEigentuemerType
            },
            on: {
              input: function($event) {
                return _vm.updateEigentuemerType($event)
              }
            }
          })
        : _vm._e(),
      _c("div", { staticClass: "cards__container" }, [
        _vm.personen.length
          ? _c(
              "div",
              { staticClass: "cards__items" },
              _vm._l(_vm.personen, function(person, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    ref: "cards",
                    refInFor: true,
                    staticClass: "box__container cards__item"
                  },
                  [
                    _c("div", { staticClass: "box__title" }, [
                      _vm._v("Personendaten " + _vm._s(index + 1))
                    ]),
                    _c(
                      "div",
                      { staticClass: "cards__item--inputs" },
                      [
                        _c("InputField", {
                          attrs: {
                            label: "Name und Vorname",
                            value: person.INPUT_PERSON_NAME,
                            id: "INPUT_PERSON_NAME" + person.index
                          },
                          on: {
                            change: function($event) {
                              return _vm.updatePerson(
                                "INPUT_PERSON_NAME",
                                person,
                                $event
                              )
                            }
                          }
                        }),
                        _c("DatePickerField", {
                          attrs: {
                            label: "Geburtsdatum",
                            dateFormat: "DD.MM.YYYY",
                            isValueAsString: "",
                            value: person.INPUT_GEBURTS_DATUM,
                            id: "INPUT_GEBURTS_DATUM" + person.index
                          },
                          on: {
                            change: function($event) {
                              return _vm.updatePerson(
                                "INPUT_GEBURTS_DATUM",
                                person,
                                $event
                              )
                            }
                          }
                        }),
                        _c("InputField", {
                          attrs: {
                            label: "Geburtsort",
                            value: person.INPUT_GEBURTS_ORT,
                            id: "INPUT_GEBURTS_ORT" + person.iindexd
                          },
                          on: {
                            change: function($event) {
                              return _vm.updatePerson(
                                "INPUT_GEBURTS_ORT",
                                person,
                                $event
                              )
                            }
                          }
                        }),
                        _c("ComboBox", {
                          attrs: {
                            label: "Wohnsitzland",
                            value: person.INPUT_LAND,
                            id: "INPUT_LAND" + person.index,
                            values: _vm.comboboxSelection["COMBO_LAND"] || []
                          },
                          on: {
                            change: function($event) {
                              return _vm.updatePerson(
                                "INPUT_LAND",
                                person,
                                $event
                              )
                            }
                          }
                        }),
                        _c("InputField", {
                          attrs: {
                            label: "Ort/PLZ",
                            value: person.INPUT_ORT,
                            id: "INPUT_ORT" + person.index
                          },
                          on: {
                            change: function($event) {
                              return _vm.updatePerson(
                                "INPUT_ORT",
                                person,
                                $event
                              )
                            }
                          }
                        }),
                        _c("InputField", {
                          attrs: {
                            label: "Adresse",
                            value: person.INPUT_ADRESSE,
                            id: "INPUT_ADRESSE" + person.index
                          },
                          on: {
                            change: function($event) {
                              return _vm.updatePerson(
                                "INPUT_ADRESSE",
                                person,
                                $event
                              )
                            }
                          }
                        }),
                        _c("ComboBox", {
                          attrs: {
                            label: "Staatsbürgerschaft",
                            value: person.INPUT_STAATSBUERGERSCHAFT,
                            id: "INPUT_STAATSBUERGERSCHAFT" + person.index,
                            values:
                              _vm.comboboxSelection["COMBO_NATIONALITY"] || []
                          },
                          on: {
                            change: function($event) {
                              return _vm.updatePerson(
                                "INPUT_STAATSBUERGERSCHAFT",
                                person,
                                $event
                              )
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _c("div", { staticClass: "box__title" }, [
                      _vm._v("Art des wirtschaftlichen Eigentümers")
                    ]),
                    _c(
                      "div",
                      { staticClass: "cards__item--inputs" },
                      [
                        _vm.isJuristischePerson
                          ? _c("InputToggleSwitch", {
                              attrs: {
                                label: "direkt",
                                value: person.INPUT_CHK_DIREKT,
                                id: "INPUT_CHK_DIREKT" + person.index
                              },
                              on: {
                                input: function($event) {
                                  return _vm.updatePerson(
                                    "INPUT_CHK_DIREKT",
                                    person,
                                    $event
                                  )
                                }
                              }
                            })
                          : _vm._e(),
                        _vm.isJuristischePerson
                          ? _c("InputToggleSwitch", {
                              attrs: {
                                label: "indirekt",
                                value: person.INPUT_CHK_INDIREKT,
                                id: "INPUT_CHK_INDIREKT" + person.index
                              },
                              on: {
                                input: function($event) {
                                  return _vm.updatePerson(
                                    "INPUT_CHK_INDIREKT",
                                    person,
                                    $event
                                  )
                                }
                              }
                            })
                          : _vm._e(),
                        _vm.isJuristischePerson
                          ? _c("InputToggleSwitch", {
                              attrs: {
                                label: "Kontrolle",
                                value: person.INPUT_CHK_KONTROLLE,
                                id: "INPUT_CHK_KONTROLLE" + person.index
                              },
                              on: {
                                input: function($event) {
                                  return _vm.updatePerson(
                                    "INPUT_CHK_KONTROLLE",
                                    person,
                                    $event
                                  )
                                }
                              }
                            })
                          : _vm._e(),
                        _vm.isJuristischePerson
                          ? _c("InputToggleSwitch", {
                              attrs: {
                                label: "Oberste Führungsebene",
                                value: person.INPUT_CHK_OBERSTE_FUEHRUNG,
                                id: "INPUT_CHK_OBERSTE_FUEHRUNG" + person.index
                              },
                              on: {
                                input: function($event) {
                                  return _vm.updatePerson(
                                    "INPUT_CHK_OBERSTE_FUEHRUNG",
                                    person,
                                    $event
                                  )
                                }
                              }
                            })
                          : _vm._e(),
                        !_vm.isJuristischePerson
                          ? _c("InputToggleSwitch", {
                              attrs: {
                                label: "Stifter / Gründer",
                                value: person.INPUT_CHK_STIFTER,
                                id: "INPUT_CHK_STIFTER" + person.index
                              },
                              on: {
                                input: function($event) {
                                  return _vm.updatePerson(
                                    "INPUT_CHK_STIFTER",
                                    person,
                                    $event
                                  )
                                }
                              }
                            })
                          : _vm._e(),
                        !_vm.isJuristischePerson
                          ? _c("InputToggleSwitch", {
                              attrs: {
                                label: "Begünstigte",
                                value: person.INPUT_CHK_BEGUENSTIGTE,
                                id: "INPUT_CHK_BEGUENSTIGTE" + person.index
                              },
                              on: {
                                input: function($event) {
                                  return _vm.updatePerson(
                                    "INPUT_CHK_BEGUENSTIGTE",
                                    person,
                                    $event
                                  )
                                }
                              }
                            })
                          : _vm._e(),
                        !_vm.isJuristischePerson
                          ? _c("InputToggleSwitch", {
                              attrs: {
                                label: "Stiftungsvorstand",
                                value: person.INPUT_CHK_VORSTAND,
                                id: "INPUT_CHK_VORSTAND" + person.index
                              },
                              on: {
                                input: function($event) {
                                  return _vm.updatePerson(
                                    "INPUT_CHK_VORSTAND",
                                    person,
                                    $event
                                  )
                                }
                              }
                            })
                          : _vm._e(),
                        !_vm.isJuristischePerson
                          ? _c("InputToggleSwitch", {
                              attrs: {
                                label: "Kontrolle",
                                value: person.INPUT_CHK_KONTROLLE,
                                id: "INPUT_CHK_KONTROLLE" + person.index
                              },
                              on: {
                                input: function($event) {
                                  return _vm.updatePerson(
                                    "INPUT_CHK_KONTROLLE",
                                    person,
                                    $event
                                  )
                                }
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                )
              }),
              0
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }