var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "base-buttons__container" }, [
    _c(
      "div",
      { staticClass: "base-buttons--content" },
      [
        _c(
          "div",
          { staticClass: "base-buttons--main" },
          [_vm._t("default")],
          2
        ),
        _c("BaseDropdownMenu", {
          staticClass: "base-buttons--dropdown",
          attrs: { placement: "bottom-right" },
          scopedSlots: _vm._u([
            {
              key: "hook-target",
              fn: function() {
                return [
                  _c(
                    "BaseButton",
                    {
                      attrs: {
                        isSecondary: !_vm.btnAsLink,
                        isLink: _vm.btnAsLink
                      }
                    },
                    [_c("span", [_vm._v("Weitere")]), _c("PhCaretDown")],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "default",
              fn: function() {
                return [
                  _c("div", {
                    ref: "dropdownContainerEl",
                    staticClass: "base-buttons--dropdown-container"
                  })
                ]
              },
              proxy: true
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }