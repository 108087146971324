var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.vertragsdatenFieldsDefinition
    ? _c(
        "div",
        _vm._l(_vm.limitedRows, function(row) {
          return _c(
            "div",
            { key: row.id, staticClass: "bottom-line" },
            [
              _c("div", { staticClass: "bottom-line-content" }, [
                _c("div", { staticClass: "container" }, [
                  _c("span", { staticClass: "title" }, [
                    _vm._v(_vm._s(row.courtageart))
                  ]),
                  _vm.editable
                    ? _c("span", { staticClass: "chapter" }, [
                        _vm._v(_vm._s(_vm._f("currency")(row.provBetrag)))
                      ])
                    : _c("span", { staticClass: "chapter" }, [
                        _vm._v(_vm._s(row.rechDatum || row.buchungsnr))
                      ])
                ])
              ]),
              _vm._l(row.courtageDesVermittlers, function(subRow) {
                return _c(
                  "div",
                  { key: subRow.lfdNr, staticClass: "bottom-line" },
                  [
                    _c("div", { staticClass: "bottom-line-content" }, [
                      _c("div", { staticClass: "container" }, [
                        _c("div", [
                          subRow.maklernr
                            ? _c("span", [
                                _vm._v(
                                  "Vermittlernr: " + _vm._s(subRow.maklernr)
                                )
                              ])
                            : _vm._e()
                        ]),
                        _c("div", { staticClass: "bottom-line-side-content" }, [
                          _c("div", { staticClass: "overflow" }, [
                            _vm._v(
                              "Prov. Betrag: " +
                                _vm._s(_vm._f("currency")(subRow.betrag))
                            )
                          ])
                        ])
                      ])
                    ])
                  ]
                )
              })
            ],
            2
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }