export default {
  props: {
    propsData: {
      type: Object,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    showToolbar: {
      type: Boolean,
      default: true,
    },
    listView: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    openInfoModal() {
      this.$refs?.coreCard?.showInfo();
    },
  },
}
