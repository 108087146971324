export const MUTATION_PREFIX = 'DECKBLATT_VERSICHERUNGEN_MUTATIONS_';
export const ACTIONS_PREFIX = 'DECKBLATT_VERSICHERUNGEN_ACTIONS_';
export const GETTERS_PREFIX = 'DECKBLATT_VERSICHERUNGEN_GETTERS_';

export default {
  MUTATIONS: {
    RETRIEVE_LAYOUT_SUCCESS: MUTATION_PREFIX + 'RETRIEVE_LAYOUT_SUCCESS',
    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    RESET_LIST: MUTATION_PREFIX + 'RESET_LIST',
  },
  ACTIONS: {
    RETRIEVE_LAYOUT: ACTIONS_PREFIX + 'RETRIEVE_LAYOUT',
  },
  GETTERS: {
    LAYOUT_DATA: GETTERS_PREFIX + 'LAYOUT_DATA',
  }
}