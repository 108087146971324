var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DragnDropArea",
        {
          attrs: {
            hoverText: "Datei hier ablegen",
            disabled: _vm.isDisabled || !_vm.hochladen
          },
          on: { files: _vm.dropFiles }
        },
        [
          _c("Table", {
            attrs: {
              title: _vm.title,
              headers: _vm.headers,
              rows: _vm.rows,
              hidePagination: ""
            },
            on: { "action-DELETE": _vm.deleteAttachment },
            scopedSlots: _vm._u([
              {
                key: "name",
                fn: function(row) {
                  return [
                    _c("DownloadLink", {
                      attrs: {
                        title: row.dateiname || row.filename,
                        filename: row.fileName || row.filename,
                        downloadServicePath: "/get_simple_file",
                        queryParams: {
                          fileId: row.id
                        }
                      }
                    })
                  ]
                }
              }
            ])
          }),
          _c(
            "div",
            { staticClass: "mt-3" },
            [
              _vm.hochladen
                ? _c(
                    "BaseFileSelect",
                    {
                      attrs: {
                        disabled: _vm.isDisabled,
                        accept: _vm.acceptFileTypes
                      },
                      on: { files: _vm.dropFiles }
                    },
                    [_vm._v(" Datei hochladen ")]
                  )
                : _vm._e(),
              _vm.dokumentenarchiv
                ? _c("BaseButton", {
                    attrs: {
                      disabled: _vm.isDisabled,
                      label: "Dokumentenarchiv"
                    },
                    on: { click: _vm.handleAddDocuments }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }